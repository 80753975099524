import React, { FC } from 'react';
import { BigidTooltip } from '@bigid-ui/components';
import makeStyles from '@mui/styles/makeStyles';
import BigidHelpIcon from '../../../../../assets/icons/BigidHelpFilled.svg';

interface InfoHelperProps {
  tooltip: string;
}

const useStyles = makeStyles({
  helpIconWrapper: { marginLeft: 6, display: 'flex' },
  helpIcon: { width: 12, height: 12 },
});

export const InfoHelper: FC<InfoHelperProps> = ({ tooltip }) => {
  const classes = useStyles({});

  return (
    <BigidTooltip title={tooltip} placement="top">
      <div className={classes.helpIconWrapper}>
        <BigidHelpIcon className={classes.helpIcon} />
      </div>
    </BigidTooltip>
  );
};
