import './objects.component.scss';
import { convertToReact } from '../../common/services/convertToReact';
import { isPermitted } from '../../react/services/userPermissionsService';
import { INVENTORY_PERMISSIONS } from '@bigid/permissions';
import template from './objects.component.html';

const componentDefinitionObject = {
  template,
  controllerAs: 'objectsModel',
  controller: function (
    objectsService,
    $scope,
    $rootScope,
    notificationService,
    DeleteConfirmation,
    downloadFileService,
  ) {
    'ngInject';
    const objectsModel = this;
    let currFilter = '';

    objectsModel.hasExportPermission = isPermitted(INVENTORY_PERMISSIONS.EXPORT_OBJECTS.name);

    objectsModel.gridError = false;

    const getData = filterValue => {
      objectsModel.loading = true;
      objectsModel.gridError = false;
      objectsService.getObjects(filterValue ? filterValue.replace('?filter=', '') : filterValue).then(
        function (response) {
          // FIXME: hot fix for the situation unfiltered data comes after filtered one
          if (currFilter !== filterValue) return;

          const _data = [];
          for (let i = 0; i < response.objectsList.length; i++) {
            if (response.objectsList[i]['objectName']) {
              _data.push(response.objectsList[i]);
            }
          }
          objectsModel.grid.data = _data;

          objectsModel.loading = false;
        },
        function () {
          objectsModel.loading = false;
          objectsModel.gridError = true;
        },
      );
    };

    const listenerCallFilter = $rootScope.$on('callFilter', (event, filterValue) => {
      if (objectsModel.useBindingsDataFlow) {
        return;
      }
      if (filterValue != currFilter) {
        currFilter = filterValue;
        getData(filterValue);
      }
    });

    const listenerCleanFilter = $rootScope.$on('cleanFilter', event => {
      if (objectsModel.useBindingsDataFlow) {
        return;
      }
      currFilter = '';
      getData('');
    });

    objectsModel.grid = {
      enableSorting: true,
      enablePaginationControls: false,
      columnDefs: [
        {
          name: 'Object Name',
          field: 'objectName',
          cellTemplate:
            '<div tabIndex="0" title="{{row.entity.objectName}}" style="width : 250px; overflow:hidden; display:inline-block;\n' +
            ' text-overflow: ellipsis; white-space: nowrap;">{{row.entity.objectName}}</div>',
          enableHiding: false,
        },
        {
          name: 'Type',
          field: 'type',
          cellTemplate: '<div tabIndex="0">{{row.entity.type}}</div>',
          width: '10%',
          enableHiding: false,
        },
        {
          name: 'Full Object Name',
          field: 'fullObjectName',
          cellTemplate:
            '<div tabIndex="0" title="{{row.entity.fullObjectName}}" style="width : 250px; overflow:hidden; display:inline-block;\n' +
            ' text-overflow: ellipsis; white-space: nowrap;">{{row.entity.fullObjectName}}</div>',
          enableHiding: false,
        },
        {
          name: 'Data Source',
          field: 'source',
          cellTemplate: '<div tabIndex="0">{{row.entity.source}}</div>',
          enableHiding: false,
        },
        {
          name: 'Open Access',
          field: 'open_access',
          cellTemplate: '<div tabIndex="0">{{row.entity.open_access}}</div>',
          enableHiding: false,
        },
      ],
    };

    objectsModel.$onInit = () => {
      currFilter = objectsModel.searchtextbox
        ? objectsModel.searchtextbox
        : objectsModel.querystring
        ? objectsModel.querystring
        : objectsModel.cleanfilter
        ? objectsModel.cleanfilter
        : '';

      getData(currFilter);
    };

    objectsModel.$onChanges = changesObj => {
      // to avoid breaking legacy behavior,
      // this component should ignore the logic in $onChanges method
      // when useBindingsDataFlow is false
      if (!objectsModel.useBindingsDataFlow) {
        return;
      }
      const { querystring } = changesObj;
      if (querystring && !querystring.isFirstChange()) {
        currFilter = objectsModel.querystring;
        getData(currFilter);
      }
    };

    objectsModel.getDataExported = () => {
      const modalOptions = {
        closeButtonText: 'Close',
        actionButtonText: 'Export',
        headerText: 'Files and Tables Export',
        bodyText: 'You are exporting all objects. Do you want to proceed?',
      };

      const filterValue = currFilter ? currFilter.replace('?filter=', '') : currFilter;

      DeleteConfirmation.showModal({}, modalOptions).then(() => {
        objectsService.getObjectsExportFromInventory(filterValue);
      });
    };

    $scope.$on('$destroy', () => {
      listenerCallFilter();
      listenerCleanFilter();
    });
  },
  bindings: {
    styles: '<',
    cleanfilter: '=',
    searchtextbox: '<',
    querystring: '<',
    useBindingsDataFlow: '<',
  },
};

export const ExplorationObjectsWidget = convertToReact('explorationObjectsWidget', componentDefinitionObject);
