import React, { FC, ReactNode } from 'react';
import classnames from 'classnames';
import {
  BigidBody1,
  BigidFieldFilterOperator,
  BigidHeading6,
  BigidIconType,
  BigidLink,
  BigidNotes1,
  BigidPaper,
} from '@bigid-ui/components';
import {
  BigidAnyIcon,
  BigidClassificationIcon,
  BigidDataSourceOnlyIcon,
  BigidGreenArrowUpIcon,
  BigidLampIcon,
  BigidRedArrowDownIcon,
} from '@bigid-ui/icons';
import makeStyles from '@mui/styles/makeStyles';
import { DatasourceScanInsightsInfo, ScanInsightsInfo } from '../ScanInsightTypes';
import { DataSnippet, DataSnippetType } from './DataSnippet';
import { Title } from './Title';
import { ScansUITrackingEvent, trackEventScansView } from '../../ScansEventTrackerUtils';
import { goToCatalogWithChangePreferences } from '../../../Dashboard/goToCatalogWithChangePreferences';
import { sanitizeInsightValues } from '../../ScanUtils';
import { dateTimeService } from '@bigid-ui/i18n';
import { openSystemDialog } from '../../../../services/systemDialogService';
import { AttributesViewDetails } from './AttributesViewDetails';
import { ObjectsDataViewDetails } from './ObjectsDataViewDetails';

enum DsInsightAttributeType {
  attribute = 'attribute',
  classifier = 'classifier',
}

const useStyles = makeStyles({
  contentAlignment: {
    padding: '24px',
    display: 'flex',
    flexDirection: 'column',
    gap: '24px',
  },
  data: {
    display: 'flex',
    gap: '24px',
    justifyContent: 'center',
  },
  dataText: {
    padding: '0 44px',
  },
  attributesText: {
    display: 'flex',
    flexDirection: 'column',
    gap: '20px',
  },
  attributeTotal: {
    display: 'flex',
    minWidth: '60px',
    justifyContent: 'space-between',
    gap: '8px',
    alignContent: 'center',
  },
  flex: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    padding: '0 48px',
  },
  paper: {
    width: '50%',
    paddingBottom: '24px',
    boxShadow: '0px 0px 5px rgba(0, 0, 0, 0.15)',
  },
  titleContent: {
    justifyContent: 'space-between',
    display: 'flex',
    gap: '8px',
  },
  title: {
    display: 'flex',
    alignItems: 'center',
  },
  innerTitle: {
    display: 'flex',
    paddingLeft: '18px',
    alignItems: 'center',
    gap: '8px',
  },
  content: {
    alignContent: 'center',
    alignSelf: 'center',
    display: 'flex',
    width: '100%',
    padding: '10px 10px 8px 10px',
    flexDirection: 'column',
    gap: '16px',
  },
  item: {
    width: '100%',
  },
  attributeText: {
    width: '120px',
  },
  textMargin: {
    marginRight: 8,
  },
  objectDataInfoRightSection: {
    display: 'flex',
    gap: '16px',
  },
});

interface AttributeProps {
  title: string;
  total: number;
  newlyAdded: number;
  falseRemoved: number;
  icon: BigidIconType;
  navigateToCatalogPage?: () => void;
  handleViewDetails?: () => void;
  viewDetailsLinkDataAid?: string;
}

interface ScanResultsDataProps {
  info: DataSnippetType[];
  title: string;
  icon: BigidIconType;
  rightSectionContent?: ReactNode;
}
interface NavigateToCatalogPage extends Pick<ScanInsightsInfo, 'allEnabledDs' | 'dataSourceNames'> {
  attributes?: string[];
}

const navigateToCatalogPage =
  ({ allEnabledDs, dataSourceNames, attributes }: NavigateToCatalogPage) =>
  () => {
    const dsFilter = allEnabledDs
      ? []
      : (dataSourceNames || []).map(dsName => ({
          field: 'system',
          value: dsName,
          operator: 'equal' as BigidFieldFilterOperator,
        }));

    const csFilter = (attributes || []).map(csName => ({
      field: 'field',
      value: csName,
      operator: 'equal' as BigidFieldFilterOperator,
    }));

    goToCatalogWithChangePreferences({
      sideFilterSelection: [...dsFilter, ...csFilter],
      optionalSideFilterSections: [],
      searchQuery: '',
    });
  };

const Attribute: FC<AttributeProps> = ({
  title,
  total,
  newlyAdded,
  falseRemoved,
  icon,
  navigateToCatalogPage,
  handleViewDetails,
  viewDetailsLinkDataAid = 'Attribute-Link',
}) => {
  const classes = useStyles({});
  const onNavigateToCatalogPage = () => {
    trackEventScansView(ScansUITrackingEvent.VIEW_IN_CATALOG_CLICK, { insightDetails: title });
    navigateToCatalogPage();
  };

  return (
    <BigidPaper classes={{ root: classes.paper }}>
      <Title
        title={title}
        icon={icon}
        rightSectionContent={
          <BigidLink dataAid={viewDetailsLinkDataAid} text={'View Details'} onClick={handleViewDetails} />
        }
      />
      <div data-aid={`scanInsightsAttributeData-${title}`} className={classes.flex}>
        <div className={classes.attributesText}>
          <div className={classes.attributeTotal}>
            <BigidHeading6>{`Total Distinct Attributes`}</BigidHeading6>
            <BigidHeading6>{sanitizeInsightValues(total)}</BigidHeading6>
          </div>
          {navigateToCatalogPage && <BigidLink text={'View in Catalog'} onClick={onNavigateToCatalogPage} />}
        </div>
        <div className={classes.attributesText}>
          <div className={classes.attributeTotal}>
            <BigidBody1 className={classes.attributeText}>{`New Findings`}</BigidBody1>
            <BigidHeading6>{sanitizeInsightValues(newlyAdded)}</BigidHeading6>
            <BigidGreenArrowUpIcon />
          </div>
          <div className={classes.attributeTotal}>
            <BigidBody1 className={classes.attributeText}>{`Removed Findings`}</BigidBody1>
            <BigidHeading6>{sanitizeInsightValues(falseRemoved)}</BigidHeading6>
            <BigidRedArrowDownIcon />
          </div>
        </div>
      </div>
    </BigidPaper>
  );
};

const ScanResultsData: FC<ScanResultsDataProps> = ({ info, title, icon, rightSectionContent }) => {
  const classes = useStyles({});
  return (
    <BigidPaper classes={{ root: classes.paper }}>
      <Title title={title} icon={icon} rightSectionContent={rightSectionContent} />
      <div className={classes.dataText}>
        <DataSnippet dataSnippets={info} />
      </div>
    </BigidPaper>
  );
};

export const ScanInsightsInfoComponent: FC<DatasourceScanInsightsInfo> = ({
  scannedDataSources = 0,
  objectsScanned = 0,
  dataSourcesWithPi = 0,
  objectsWithPi = 0,
  attributes = {},
  failed = 0,
  classifications = {},
  changeFromPreviousScan = 0,
  shouldShowAttributes,
  isScanComplete,
  allEnabledDs,
  dataSourceNames,
  csAttributeNames,
  classifierAttributeNames,
  updatedAt,
  scanId,
  scanProfileName,
  isReviewFindingsEnabled,
}) => {
  const classes = useStyles({});
  const dataSourceInfo: DataSnippetType[] = [
    {
      title: 'Data sources scanned',
      data: sanitizeInsightValues(scannedDataSources),
    },
    {
      title: 'with PII',
      data: sanitizeInsightValues(dataSourcesWithPi),
    },
    {
      title: 'Failed',
      data: sanitizeInsightValues(failed),
      color: 'error',
    },
  ];

  const objectDataInfo: DataSnippetType[] = [
    {
      title: 'Objects Scanned',
      data: sanitizeInsightValues(objectsScanned),
    },
    {
      title: 'with PII',
      data: sanitizeInsightValues(objectsWithPi),
    },
    ...(isScanComplete
      ? [
          {
            title: 'Objects Changed',
            data: sanitizeInsightValues(changeFromPreviousScan),
            color: 'error' as const,
          },
        ]
      : []),
  ];

  const handleEventAttributeDetailsClick = (type: string) => {
    if (type === DsInsightAttributeType.attribute) {
      trackEventScansView(ScansUITrackingEvent.VIEW_DETAILS_CORRELATION_SET_ATTRIBUTES_CLICK);
    }
    if (type === DsInsightAttributeType.classifier) {
      trackEventScansView(ScansUITrackingEvent.VIEW_DETAILS_CLASSIFICATION_ATTRIBUTES_CLICK);
    }
  };

  const handleViewDetailsAttributes = (title: string, type: string) => {
    handleEventAttributeDetailsClick(type);
    openSystemDialog({
      title,
      content: AttributesViewDetails,
      contentProps: {
        scanId,
        type,
        title,
        scannedDataSources,
      },
      onClose: () => null,
      maxWidth: 'md',
    });
  };

  const handleViewDetailsObjectsData = () => {
    trackEventScansView(ScansUITrackingEvent.VIEW_DETAILS_DATE_SOURCES_INSIGHTS_CLICK);
    openSystemDialog({
      title: 'Data Source Scan Results',
      content: ObjectsDataViewDetails,
      contentProps: {
        scanId,
        scannedDataSources,
      },
      onClose: () => null,
      maxWidth: 'md',
    });
  };

  return (
    <BigidPaper>
      <div className={classes.contentAlignment}>
        <div className={classes.titleContent}>
          <div className={classnames(classes.title, classes.textMargin)}>
            <BigidLampIcon size={'large'} className={classes.textMargin} />
            <BigidHeading6 color={'secondary'}>Scan Insights</BigidHeading6>
          </div>
          <div data-aid={'update-at'}>
            <BigidNotes1 className={classes.textMargin}>Last Update:</BigidNotes1>
            <BigidNotes1 data-aid={`scanInsight-update-at`} color={'secondary'}>
              {dateTimeService.formatDate(updatedAt)}
            </BigidNotes1>
          </div>
        </div>
        <div data-aid={'scanResultInfo'} className={classes.data}>
          <ScanResultsData
            info={dataSourceInfo}
            title={'Data Source Scan Results'}
            icon={() => <BigidDataSourceOnlyIcon />}
          />
          <ScanResultsData
            info={objectDataInfo}
            title={'Objects'}
            icon={() => <BigidAnyIcon />}
            rightSectionContent={
              <div className={classes.objectDataInfoRightSection}>
                <BigidLink text={'View Details'} dataAid="Objects-Data-Link" onClick={handleViewDetailsObjectsData} />
              </div>
            }
          />
        </div>
        {shouldShowAttributes && (
          <div data-aid={'scanAttributeInfo'} className={classes.data}>
            <Attribute
              title={'Correlation Set Attributes'}
              total={attributes.total}
              newlyAdded={attributes.newlyAdded}
              falseRemoved={attributes.falseRemoved}
              icon={() => <BigidAnyIcon />}
              navigateToCatalogPage={
                isScanComplete && navigateToCatalogPage({ allEnabledDs, dataSourceNames, attributes: csAttributeNames })
              }
              handleViewDetails={() => handleViewDetailsAttributes('Correlations Set Attributes', 'attribute')}
              viewDetailsLinkDataAid="correlations-set-attributes-link"
            />
            <Attribute
              title={'Classification Attributes'}
              total={classifications.total}
              newlyAdded={classifications.newlyAdded}
              falseRemoved={classifications.falseRemoved}
              icon={() => <BigidClassificationIcon />}
              navigateToCatalogPage={
                isScanComplete &&
                navigateToCatalogPage({ allEnabledDs, dataSourceNames, attributes: classifierAttributeNames })
              }
              handleViewDetails={() => handleViewDetailsAttributes('Classifications Attributes', 'classifier')}
              viewDetailsLinkDataAid="classifications-attributes-link"
            />
          </div>
        )}
      </div>
    </BigidPaper>
  );
};
