import {
  GettingStartedItem,
  GettingStartedItems,
  gettingStartedItemsObject,
} from '../GettingStartedItems';

export enum ReducerActions {
  'INIT',
  'SET_CURRENT_STEP',
  'UPDATE_ITEMS',
}

export interface GettingStartedReducerState {
  currentStep: keyof GettingStartedItems;
  currentStepObject: GettingStartedItem;
  items: GettingStartedItems;
}

export interface GettingStartedReducerAction {
  type: ReducerActions;
  payload: Partial<GettingStartedReducerState>;
}

export const GettingStartedReducer: React.Reducer<GettingStartedReducerState, GettingStartedReducerAction> = (
  state,
  { type, payload },
) => {
  switch (type) {
    case ReducerActions.UPDATE_ITEMS: {
      const { items, currentStep, currentStepObject } = payload;
      return { ...state, items, currentStep, currentStepObject };
    }
    case ReducerActions.SET_CURRENT_STEP: {
      const { currentStep } = payload;
      const currentStepObject = state.items[currentStep];
      return { ...state, currentStep, currentStepObject };
    }

    case ReducerActions.INIT: {
      return getInitialReducerState();
    }

    default:
      return state;
  }
};

export const getInitialReducerState = (): GettingStartedReducerState => {
  return {
    items: gettingStartedItemsObject,
    currentStep: null,
    currentStepObject: null,
  };
};
