import React from 'react';
import {
  AccordionSummarySizeEnum,
  BigidAccordion,
  BigidAccordionDetails,
  BigidAccordionSummary,
  BigidBody1,
  BigidHeading5,
  BigidHeading6,
  BigidNotes1,
  BigidTooltip,
} from '@bigid-ui/components';
import { MainContentConnectedStatus } from '../../DataSourceConfiguration/DataSourceNewConnectivityConfiguration/DataSourceConnectionTab/ConnectedStatus/MainContentConnectedStatus';
import { Tab } from '../mappers/styles';
import { BigidExpandIcon, BigidTestIcon } from '@bigid-ui/icons';
import { useLocalTranslation } from '../translations';
import { useTheme } from '@mui/styles';
import { DataSourceTestConnectionRowType } from '../../DataSourcesTestConnectionGrid/DataSourcesTestConnectionGrid';

export type DataSourceConnectionResultsProps = {
  dataAid?: string;
  timestamp?: string;
  rows: any[];
  rowType?: DataSourceTestConnectionRowType;
  onTest?: () => void;
  onShow?: () => void;
};

const imageStyle = {
  display: 'inline-flex',
  gap: '1rem',
  marginRight: '1px',
};

const bodyStyle = { marginLeft: 'auto' };

const wrapperStyle = { marginTop: '0' };

const tabNoContentStyle = { display: 'flex', justifyContent: 'center', textAlign: 'center' as any };

const DataSourceConnectionResultsNoData = (): JSX.Element => {
  const { t } = useLocalTranslation();

  return (
    <Tab.TabNoContent style={tabNoContentStyle}>
      {t('connectionResults.noResults')}
      <br />
      {t('connectionResults.noResultsSub')}
    </Tab.TabNoContent>
  );
};

export const DataSourceConnectionResults = ({
  dataAid = 'DataSourceConnectionResults',
  timestamp,
  rows,
  rowType = DataSourceTestConnectionRowType.STRUCTURED,
  onTest,
  onShow,
}: DataSourceConnectionResultsProps): JSX.Element => {
  const { t } = useLocalTranslation();
  const theme = useTheme();

  const style = {
    backgroundColor: theme.vars.palette.bigid.gray100,
    width: '100%',
    gap: '12px',
    display: 'flex',
    flexDirection: 'column',
  };

  const accordionStyle = {
    gap: '0px',
    boxShadow: 'none',
    borderRadius: '8px !important',
    border: `1px solid ${theme.vars.tokens.bigid.borderDefault}`,
    backgroundColor: `${theme.vars.palette.bigid.gray125}`,
  };

  const accordionDetailsStyle = {
    padding: '12px 20px 20px !important',
    backgroundColor: `${theme.vars.palette.bigid.gray125}`,
  };

  const accordionSummary = {
    backgroundColor: `${theme.vars.palette.bigid.gray125}`,
  };

  return (
    <Tab.Wrapper style={wrapperStyle} showExpandIcon={false}>
      <BigidAccordion expanded dataAid={dataAid} style={{ ...style, ...accordionStyle } as any}>
        <BigidAccordionSummary
          sx={{ '& .MuiAccordionSummary-content': { marginLeft: '4px !important' } }}
          style={accordionSummary}
          size={AccordionSummarySizeEnum.large}
        >
          <BigidHeading6 sx={{ width: '100%' }}>
            <Tab.Title style={{ paddingTop: '16px' }}>
              <BigidHeading5>
                {`${t('connectionResults.header')} `}
                {timestamp && <BigidNotes1>{`(${timestamp})`}</BigidNotes1>}
              </BigidHeading5>
              <BigidBody1 style={bodyStyle}>
                <Tab.Image style={imageStyle}>
                  <BigidTooltip title={t('buttons.showResults')}>
                    <div>
                      <BigidExpandIcon width={16} height={16} cursor="pointer" onClick={onShow} fontSize="inherit" />
                    </div>
                  </BigidTooltip>
                  <BigidTooltip title={t('buttons.testConnection')}>
                    <div>
                      <BigidTestIcon width={16} height={16} cursor="pointer" onClick={onTest} fontSize="inherit" />
                    </div>
                  </BigidTooltip>
                </Tab.Image>
              </BigidBody1>
            </Tab.Title>
          </BigidHeading6>
        </BigidAccordionSummary>
        <BigidAccordionDetails sx={{ ...style, ...accordionDetailsStyle }}>
          {rows?.length ? (
            <MainContentConnectedStatus hideMargin rowType={rowType} rows={rows} hideTitle showAction={false} />
          ) : (
            <DataSourceConnectionResultsNoData />
          )}
        </BigidAccordionDetails>
      </BigidAccordion>
    </Tab.Wrapper>
  );
};
