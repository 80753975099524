import { BigidFilterToolbarProps } from '@bigid-ui/components';

export type GridOverride = {
  enabled: boolean;
  execute: (config: BigidFilterToolbarProps) => BigidFilterToolbarProps;
};

export const getGridWithToolbarFilterOverrides = (
  overrides: GridOverride[],
  config: BigidFilterToolbarProps,
): BigidFilterToolbarProps => {
  return overrides.reduce((acc, { execute, enabled }) => {
    return enabled ? execute(acc as BigidFilterToolbarProps) : { ...acc };
  }, config);
};
