import { WizardFlowViewsData } from './hooks/useWizardFlow';
import { FmsdWelcome, FmsdPreDiscover, FmsdDiscover } from './index';

export enum FmsdViewsIds {
  WELCOME = 'welcome',
  PRE_DISCOVER = 'preDiscover',
  DISCOVER = 'discover',
}

export const FmsdViews: WizardFlowViewsData = [
  {
    id: FmsdViewsIds.WELCOME,
    component: FmsdWelcome,
  },
  {
    id: FmsdViewsIds.PRE_DISCOVER,
    component: FmsdPreDiscover,
  },
  {
    id: FmsdViewsIds.DISCOVER,
    component: FmsdDiscover,
  },
];
