import React, { FC, memo } from 'react';
import {
  BigidTextFieldRenderProps,
  BigidFormFieldSidePassword,
  compareObjectsExceptFunctions,
  PASSWORD_MASK_VIEW_VALUE,
} from '@bigid-ui/components';

export const FormPasswordWithMaskField: FC<BigidTextFieldRenderProps> = memo(({ touched, value, ...restProps }) => {
  const textFieldProps = restProps.textFieldProps || {};
  return (
    <BigidFormFieldSidePassword
      {...restProps}
      touched={touched}
      value={value && !touched ? PASSWORD_MASK_VIEW_VALUE : value}
      textFieldProps={{ ...textFieldProps, lpIgnore: true }}
    />
  );
}, compareObjectsExceptFunctions);

FormPasswordWithMaskField.displayName = 'FormPasswordWithMaskField';
