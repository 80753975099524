import {
  BigidSideFilterSectionBase,
  BigidSideFilterSectionItemBase,
  BigidFieldFilterOperator,
} from '@bigid-ui/components';
import { ReactText } from 'react';
import { UserPreference } from '../../services/userPreferencesService';
import { clusteringService } from '../Clustering/clusteringService';
import {
  getAttachedDsTagsAllPairs,
  getAttachedTagsAllPairs,
  TagApplicationType,
  TagEntity,
} from '../TagsManagement/TagsManagementService';
import {
  getTagFormattedName,
  getTagsAggregatedById,
  getUserDefinedTagsOnly,
} from '../TagsManagement/TagsManagementUtils';
import { FileTypeProps } from './DataCatalog';
import {
  EXCLUDED_TAGS,
  HAS_DUPLICATES,
  OWNER_OPERAND_IM_PREDICTED,
  OWNER_OPERAND_MANUALLY_SET,
  OWNER_OPERAND_NO_OWNER,
} from './DataCatalogConsts';
import {
  CloudEntity,
  DataCatalogPagePreferences,
  FileTypeEntity,
  getApplications,
  getCloudData,
  getDistinctAttributes,
  getDistinctDataSources,
  getDistinctFileTypes,
  getFileTypes,
  getIntegrationSettings,
  getPolicies,
  getSavedQueries,
  AppIntegrationSettings,
  AppIntegrationSettingsFilterConfig,
} from './DataCatalogService';
import { getApplicationPreference } from '../../services/appPreferencesService';
import { scanStatusDisplayName } from './DataCatalogDetails/DataCatalogDetailsService';

export type BaseFilters = {
  filter: unknown;
  preferences: UserPreference<DataCatalogPagePreferences>;
};

export enum SectionName {
  TAGS = 'Tags',
  DS_TAGS = 'Data Source Tags',
  ACCESS_TYPE = 'Access Type',
}

const getIsItemSelected = (
  preferences: UserPreference<DataCatalogPagePreferences>,
  fields: string[], // NOTE: support array in order to provide backward compatibility
  value: ReactText | ReactText[],
): boolean => {
  if (Array.isArray(value)) {
    return !!preferences?.filter?.sideFilterSelection?.find(filter => {
      const experimentalFilterValue = typeof filter.value === 'string' && filter.value.replace(/^"|"$/g, '');
      return (
        (value.includes(filter.value as string) || value.includes(experimentalFilterValue)) &&
        fields.includes(filter.field)
      );
    });
  } else {
    return !!preferences?.filter?.sideFilterSelection?.find(filter => {
      const experimentalFilterValue =
        typeof filter.value === 'string'
          ? filter.value.replace(/^"|"$/g, '')
          : (filter.value as string[]).map(value => value.replace(/^"|"$/g, ''));
      return (filter.value === value || experimentalFilterValue === value) && fields.includes(filter.field);
    });
  }
};

export async function loadAttributes({ filter, preferences }: BaseFilters) {
  const attributes = await getDistinctAttributes();
  const attributesSectionConfig: BigidSideFilterSectionBase = {
    id: 'field',
    name: 'field',
    displayName: 'Attributes',
    showTooltips: true,
    specialItems: [
      {
        id: 'contains_pi',
        name: 'contains_pi',
        displayName: 'Any',
        value: 'true',
        operator: 'equal',
        operand: 'contains_pi',
        isSelected: !filter && getIsItemSelected(preferences, ['contains_pi'], 'true'),
      },
    ],
    items: attributes.map(({ value }) => ({
      id: value,
      name: value,
      value,
      operator: 'in',
      isSelected: !filter && getIsItemSelected(preferences, ['field'], value),
    })),
  };
  return attributesSectionConfig;
}

export async function loadDataSources({ filter, preferences }: BaseFilters) {
  const dataSources = await getDistinctDataSources();
  const dataSourcesSectionConfig: BigidSideFilterSectionBase = {
    id: 'system',
    name: 'system',
    displayName: 'Data Sources',
    showTooltips: true,
    items: dataSources.map(({ value }) => ({
      id: value,
      name: value,
      value,
      operator: 'in',
      isSelected: !filter && getIsItemSelected(preferences, ['system'], value),
    })),
  };
  return dataSourcesSectionConfig;
}

export async function loadPolicies({ filter, preferences }: BaseFilters) {
  const policies = (await getPolicies())?.filter(policy => {
    return policy.is_enabled && policy.findings?.violated;
  });
  const usePolicyDisplayName = getApplicationPreference('USE_DISPLAY_NAME_FOR_POLICY_FF');

  const policiesSectionConfig: BigidSideFilterSectionBase = {
    id: 'policy',
    name: 'policy',
    displayName: 'Policies',
    showTooltips: true,
    items: policies.map(({ name, displayName }) => ({
      id: name,
      name: usePolicyDisplayName ? displayName ?? name : name,
      value: name,
      operator: 'equal',
      isSelected: !filter && getIsItemSelected(preferences, ['policy'], name),
    })),
  };
  return policiesSectionConfig;
}

export async function loadCloudTypes({ filter, preferences }: BaseFilters) {
  const getCloudAggregatedByName = (cloud: CloudEntity[]): CloudEntity[] => {
    return cloud.reduce((cloudAggregated, { cloudName, cloudValue, mongoType }) => {
      const cloudIndex = cloudAggregated.findIndex(cloud => cloud.cloudName === cloudName);

      if (cloudIndex < 0) {
        return [...cloudAggregated, { cloudName, cloudValues: [{ cloudValue, mongoType }] }];
      } else {
        return cloudAggregated.map(cloud =>
          cloud.cloudName === cloudName
            ? { ...cloud, cloudValues: [...cloud.cloudValues, { cloudValue, mongoType }] }
            : cloud,
        );
      }
    }, []);
  };

  const cloud = await getCloudData();
  const sectionName = 'source.type';
  const cloudSectionConfig: BigidSideFilterSectionBase = {
    id: 'cloud',
    name: 'cloud',
    displayName: 'Cloud',
    showTooltips: true,
    selectPlaceholder: 'Show more',
    items: getCloudAggregatedByName(cloud).map(({ cloudName, cloudValues }) => {
      const items = cloudValues.map(({ cloudValue, mongoType }) => {
        return {
          id: cloudValue,
          name: cloudValue,
          value: mongoType,
          operator: 'in',
          isSelected: !filter && getIsItemSelected(preferences, [`${sectionName}`], mongoType),
        };
      }) as BigidSideFilterSectionItemBase[];
      return {
        id: cloudName,
        name: sectionName,
        displayName: cloudName,
        value: null,
        items,
        operator: 'in',
      };
    }),
  };
  return cloudSectionConfig;
}

export async function loadApplications({ filter, preferences }: BaseFilters) {
  const applications = await getApplications();
  const applicationsSectionConfig: BigidSideFilterSectionBase = {
    id: 'application',
    name: 'application',
    displayName: 'Assets',
    showTooltips: true,
    items: applications.map(({ name }) => ({
      id: name,
      name: name,
      value: name,
      operator: 'in',
      isSelected: !filter && getIsItemSelected(preferences, ['application'], name),
    })),
  };
  return applicationsSectionConfig;
}

export async function loadScanStatus({ filter, preferences }: BaseFilters) {
  const scanStatusSectionConfig: BigidSideFilterSectionBase = {
    id: 'scanStatus',
    name: 'scanStatus',
    displayName: 'Scan Status',
    items: Object.entries(scanStatusDisplayName).map(([name, displayName]) => ({
      id: name,
      name: name,
      displayName: displayName,
      value: name,
      operator: 'equal',
      isSelected: !filter && getIsItemSelected(preferences, ['scanStatus'], name),
    })),
  };
  return scanStatusSectionConfig;
}

export async function loadFileTypes({ filter, preferences }: BaseFilters) {
  const fileTypes: FileTypeProps[] = await getDistinctFileTypes();
  const typeToLowerCase = fileTypes.map(fileType => fileType.value?.toLowerCase?.());
  const filteredFileTypes = typeToLowerCase.filter((elem, index, self) => {
    if (index === self.indexOf(elem) && elem?.length > 0) {
      return true;
    }
  });

  const filetypesSectionConfig: BigidSideFilterSectionBase = {
    id: 'fileType',
    name: 'fileType',
    displayName: 'File Types',
    showTooltips: true,
    items: filteredFileTypes.map(value => ({
      id: value,
      name: value.toUpperCase(),
      value,
      operator: 'in',
      isSelected: !filter && getIsItemSelected(preferences, ['fileType'], value),
    })),
  };

  return filetypesSectionConfig;
}

export async function loadExtendedFileTypes({ filter, preferences }: BaseFilters) {
  const fileTypes = await getFileTypes();

  const getFileTypesAggregatedByCategory = (fileType: FileTypeEntity[]): FileTypeEntity[] => {
    return fileType.reduce((fileTypeAggregated, { value, type }) => {
      const fileTypeIndex = fileTypeAggregated.findIndex(fileType => fileType.value === value);

      if (fileTypeIndex < 0) {
        return [...fileTypeAggregated, { value, types: [{ type }] }];
      } else {
        return fileTypeAggregated.map(fileType =>
          fileType.value === value ? { ...fileType, types: [...fileType.types, { type }] } : fileType,
        );
      }
    }, []);
  };

  const sectionName = 'fileType';
  const fileTypeSectionConfig: BigidSideFilterSectionBase = {
    id: sectionName,
    name: sectionName,
    displayName: 'File Types',
    showTooltips: true,
    items: getFileTypesAggregatedByCategory(fileTypes).map(({ value, types }) => ({
      id: value,
      name: value,
      operator: 'in',
      value: types.map(({ type }) => type),
      isSelected:
        !filter &&
        getIsItemSelected(
          preferences,
          [sectionName],
          types.map(({ type }) => type),
        ),
    })),
  };
  return fileTypeSectionConfig;
}

export async function loadTags({ filter, preferences }: BaseFilters, sectionName: SectionName) {
  const entityName = sectionName === SectionName.TAGS ? 'Tags' : 'ds_tags';
  const searchQueryName = sectionName === SectionName.TAGS ? 'catalog_tag' : 'ds_tag';
  const lowerCaseEntityName = entityName.toLowerCase();
  const allTags = await (sectionName === SectionName.TAGS ? getAttachedTagsAllPairs() : getAttachedDsTagsAllPairs());

  const allVisibleTags = allTags?.filter(({ properties }) => !properties?.hidden);
  const userDefinedTags = getUserDefinedTagsOnly(allVisibleTags).filter(
    item => item.tagName != SectionName.ACCESS_TYPE,
  );

  const sensitivityTags = allVisibleTags.filter(
    tag => tag?.properties?.applicationType === TagApplicationType.SENSITIVITY_CLASSIFICATION,
  );
  let riskTags = allVisibleTags.filter(
    tag => tag?.properties?.applicationType === TagApplicationType.RISK && !tag?.properties?.isExplicit,
  );
  if (sectionName === SectionName.TAGS) {
    riskTags = riskTags.filter(({ tagName }) => !tagName.startsWith('system.risk.ds'));
  }

  const tagsSectionConfig: BigidSideFilterSectionBase = {
    id: `${lowerCaseEntityName}`,
    name: `${searchQueryName}`,
    displayName: `${sectionName}`,
    showTooltips: true,
    selectPlaceholder: `More ${lowerCaseEntityName}`,
    items: getTagsAggregatedById(userDefinedTags).map(({ tagName, tagValues }) => {
      const items = tagValues.map(({ tagValue }) => {
        return {
          id: `${tagName}.${tagValue}`,
          name: tagValue,
          value: tagValue,
          operator: 'in',
          isSelected: !filter && getIsItemSelected(preferences, [`${searchQueryName}.${tagName}`], tagValue),
        };
      }) as BigidSideFilterSectionItemBase[];
      return {
        id: tagName,
        name: `${searchQueryName}.${tagName}`,
        displayName: tagName,
        value: null,
        items,
        operator: 'in',
      };
    }),
  };

  const getSystemItems = (tags: TagEntity[]): BigidSideFilterSectionItemBase[] => {
    return getTagsAggregatedById(tags).map(({ tagName, tagValues, displayName }) => {
      const items = tagValues.map(({ tagValue }) => {
        return {
          id: `${tagName}.${tagValue}`,
          name: tagValue,
          value: tagValue,
          operator: 'in',
          isSelected: !filter && getIsItemSelected(preferences, [`${searchQueryName}.${tagName}`], tagValue),
        };
      }) as BigidSideFilterSectionItemBase[];
      return {
        id: tagName,
        name: `${searchQueryName}.${tagName}`,
        displayName: !!displayName ? displayName : getTagFormattedName(tagName),
        value: null,
        items,
        operator: 'in',
      };
    });
  };

  const sensitivityClassificationTagsSectionConfig: BigidSideFilterSectionBase = {
    id: `sensitivity_classification_${lowerCaseEntityName}`,
    name: `sensitivity_classification_${lowerCaseEntityName}`,
    displayName: `${sectionName === SectionName.TAGS ? '' : 'DS '}Sensitivity Classification`,
    selectPlaceholder: `More ${lowerCaseEntityName}`,
    items: getSystemItems(sensitivityTags),
  };

  const riskTagsSectionConfig: BigidSideFilterSectionBase = {
    id: `risk_${lowerCaseEntityName}`,
    name: `${searchQueryName}`,
    displayName: 'Risk',
    selectPlaceholder: `More ${lowerCaseEntityName}`,
    items: getSystemItems(riskTags),
  };

  const sectionsConfig = [tagsSectionConfig, sensitivityClassificationTagsSectionConfig];
  if (!!getApplicationPreference('ENABLE_CATALOG_OBJECT_RISK')) {
    sectionsConfig.push(riskTagsSectionConfig);
  }

  return sectionsConfig;
}

export async function loadAccessType({ filter, preferences }: BaseFilters, sectionName: SectionName) {
  const searchQueryName = `catalog_tag.${SectionName.ACCESS_TYPE}`;
  const lowerCaseEntityName = sectionName.toLowerCase();
  const allTags = await getAttachedTagsAllPairs();

  const accessTypeData = allTags?.filter(item => item.tagName == SectionName.ACCESS_TYPE && !item.properties?.hidden);

  const accessTypeConfig: BigidSideFilterSectionBase = {
    id: `${lowerCaseEntityName}`,
    name: `${searchQueryName}`,
    displayName: `${sectionName}`,
    showTooltips: true,
    selectPlaceholder: `More ${lowerCaseEntityName}`,
    items: accessTypeData.map(({ tagValue, valueId }) => ({
      id: valueId,
      name: tagValue,
      value: tagValue,
      operator: 'in',
      isSelected: !filter && getIsItemSelected(preferences, [`${searchQueryName}`], tagValue),
    })),
  };

  return accessTypeConfig;
}

export async function loadIntegrations({ filter, preferences }: BaseFilters) {
  const [allTags, appIntegrationSettings] = await Promise.all([getAttachedTagsAllPairs(), getIntegrationSettings()]);

  const appIntegrationFilters = appIntegrationSettings
    ? appIntegrationSettings.map(settings => ({
        appName: settings.appFriendlyName,
        filters: settings.filters.filter(Boolean),
      }))
    : null;

  const sectionsConfig: any = [];

  appIntegrationFilters?.forEach(
    ({ appName, filters }: { appName: string; filters: AppIntegrationSettingsFilterConfig[] }) => {
      filters.forEach(appFilter => {
        const tagName = `system.${appName}.${appFilter.tagName}`;
        const name = `catalog_tag.system.${appName}.${appFilter.tagName}`;
        const tags = allTags.filter(tag => {
          return tag.tagName === tagName;
        });
        sectionsConfig.push({
          id: appName + appFilter.tagName,
          name,
          displayName: appFilter.name,
          selectPlaceholder: `More...`,
          items: tags.map(tag => {
            return {
              ...tag,
              id: `${tag.tagName}.${tag.tagValue}`,
              name: tag.tagValue,
              value: tag.tagValue,
              operator: 'in',
              isSelected: !filter && getIsItemSelected(preferences, [name], tag.tagValue),
            };
          }),
        });
      });
    },
  );

  return sectionsConfig;
}

export async function loadClusters({ filter, preferences }: BaseFilters) {
  const clusters = await clusteringService.getClustersData();
  const clustersSectionConfig: BigidSideFilterSectionBase = {
    id: 'cluster_name',
    name: 'cluster_name',
    displayName: 'Similar File Clusters',
    showTooltips: true,
    items: clusters.map(({ cluster_id, name }) => ({
      id: cluster_id,
      name,
      value: name,
      operator: 'in',
      isSelected: !filter && getIsItemSelected(preferences, ['cluster_name'], name),
    })),
  };
  return clustersSectionConfig;
}

export async function loadSavedQueries({ filter, preferences }: BaseFilters) {
  const savedQueries = await getSavedQueries();
  const savedQueriesSectionConfig: BigidSideFilterSectionBase[] = savedQueries
    .filter(({ tag_name }) => !EXCLUDED_TAGS.includes(tag_name))
    .reduce((optionalSections, { tag_name, tag_value, tag_type = 'query' }) => {
      const tagType = tag_type === 'tag' ? 'query' : tag_type;
      const addedSectionIndex = optionalSections.findIndex(({ id }) => id === tag_name);
      if (addedSectionIndex >= 0) {
        optionalSections[addedSectionIndex] = {
          ...optionalSections[addedSectionIndex],
          items: [
            ...optionalSections[addedSectionIndex].items,
            {
              id: tag_value,
              name: tag_value,
              value: tag_value,
              operator: 'equal',
              isSelected:
                !filter &&
                getIsItemSelected(
                  preferences,
                  [`${tagType}.${tag_name.toLowerCase()}`, `tag.${tag_name.toLowerCase()}`],
                  tag_value,
                ),
            },
          ],
        };
        return optionalSections;
      } else {
        return [
          ...optionalSections,
          {
            id: tag_name,
            name: `${tagType}.${tag_name.toLowerCase()}`,
            displayName: tag_name,
            showTooltips: true,
            isOptional: true,
            isEnabled: preferences?.filter?.optionalSideFilterSections?.includes(tag_name),
            items: [
              {
                id: tag_value,
                name: tag_value,
                value: tag_value,
                operator: 'equal',
                isSelected:
                  !filter &&
                  getIsItemSelected(
                    preferences,
                    [`${tagType}.${tag_name.toLowerCase()}`, `tag.${tag_name.toLowerCase()}`],
                    tag_value,
                  ),
              },
            ] as BigidSideFilterSectionItemBase[],
          },
        ];
      }
    }, []);
  return savedQueriesSectionConfig;
}

export function loadQuickFilters({ filter, preferences }: BaseFilters, disableLegacyACL: boolean) {
  const quickFilterSectionConfig: BigidSideFilterSectionBase = {
    id: 'quick_filter',
    name: 'quick_filter',
    displayName: 'Quick Filter',
    showTooltips: true,
    items: [
      {
        id: 'has_duplicates',
        displayName: 'Duplicate Files',
        name: HAS_DUPLICATES,
        value: 'true',
        operator: 'equal',
        operand: 'has_duplicates',
        isSelected: !filter && getIsItemSelected(preferences, ['has_duplicates'], 'true'),
      },
      ...(!disableLegacyACL
        ? [
            {
              id: 'metadata.access_rights',
              name: 'OPEN ACCESS',
              displayName: 'File Open Access',
              value: 'OPEN ACCESS',
              operator: 'in' as BigidFieldFilterOperator,
              operand: 'metadata.access_rights',
              isSelected: !filter && getIsItemSelected(preferences, ['metadata.access_rights'], 'OPEN ACCESS'),
            },
          ]
        : []),
    ],
  };
  return quickFilterSectionConfig;
}
