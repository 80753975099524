import { useState, useCallback } from 'react';
import { SarFieldsSettings, SarFieldsSettingsV2 } from '../ProfileSettingsTypes';
import { EditFieldsDialogProps } from './EditFieldsDialog';
import { EditFieldsDialogProps as EditFieldsDialogPropsV2 } from './EditFieldsDialogV2';
import { BigidFieldFilter } from '@bigid-ui/components';

export interface UseEditFieldsDialog {
  dialogProps: EditFieldsDialogProps;
  openDialog: (
    profileId: string,
    sources: SarFieldsSettings[],
    allSelected: boolean,
    totalRows: number,
    filter: BigidFieldFilter[],
  ) => Promise<boolean>;
}

export const useEditFieldsDialog = (): UseEditFieldsDialog => {
  const [dialogProps, setDialogProps] = useState<EditFieldsDialogProps>(getClearDialogState());

  const openDialog = useCallback<UseEditFieldsDialog['openDialog']>(
    (profileId, sources, allSelected, totalRows, filter) => {
      return new Promise<boolean>(resolve => {
        setDialogProps({
          sources,
          totalRows,
          allSelected,
          filter,
          profileId,
          isOpen: true,
          onSave: onSaveResult => {
            const { status } = onSaveResult;
            if (status.isOk) {
              setDialogProps(getClearDialogState());
              resolve(true);
            }
          },
          onClose: () => setDialogProps(getClearDialogState()),
        });
      });
    },
    [],
  );

  return {
    openDialog,
    dialogProps,
  };

  function getClearDialogState(): EditFieldsDialogProps {
    return {
      profileId: null,
      isOpen: false,
      sources: [],
    };
  }
};

export interface UseEditFieldsDialogV2 {
  dialogProps: EditFieldsDialogPropsV2;
  openDialog: (
    profileId: string,
    sources: SarFieldsSettingsV2[],
    allSelected: boolean,
    totalRows: number,
    filter: BigidFieldFilter[],
  ) => Promise<boolean>;
}

const initialState: EditFieldsDialogPropsV2 = {
  profileId: null,
  isOpen: false,
  sources: [],
};

export const useEditFieldsDialogV2 = (): UseEditFieldsDialogV2 => {
  const [dialogProps, setDialogProps] = useState<EditFieldsDialogPropsV2>(initialState);

  const openDialog = useCallback<UseEditFieldsDialogV2['openDialog']>(
    (profileId, sources, allSelected, totalRows, filter) => {
      return new Promise<boolean>(resolve => {
        setDialogProps({
          sources,
          totalRows,
          allSelected,
          filter,
          profileId,
          isOpen: true,
          onSave: onSaveResult => {
            const { status } = onSaveResult;
            if (status.isOk) {
              setDialogProps(initialState);
              resolve(true);
            }
          },
          onClose: () => setDialogProps(initialState),
        });
      });
    },
    [],
  );

  return {
    openDialog,
    dialogProps,
  };
};
