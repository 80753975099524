import React, { FC, useState } from 'react';
import styled from '@emotion/styled';
import { BigidHeading5 } from '@bigid-ui/components';
import { BigidChevronDownIcon, BigidChevronUpIcon } from '@bigid-ui/icons';
import { ReactElement } from 'react-markdown/lib/react-markdown';

const Main = styled('div')`
  display: flex;
  flex-direction: column;
  row-gap: 16px;
  padding: 16px;
  border: 1px solid ${({ theme }) => theme.vars.palette.bigid.gray200};
  background-color: ${({ theme }) => theme.vars.palette.bigid.white};
  border-radius: 4px;
`;

const MainWrapper = styled('div')`
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
`;

const HeaderSection = styled('div')`
  display: flex;
  align-items: center;
  column-gap: 10px;
  height: 32px;
`;

const ContentSection = styled('div')<{ isShown?: boolean }>`
  display: ${props => (props.isShown ? 'block' : 'none')};
`;

interface CustomAccordeonProps {
  header?: string;
  customButton?: ReactElement;
  children?: ReactElement;
}

export const CustomAccordeon: FC<CustomAccordeonProps> = ({ header, customButton, children }) => {
  const [isOpen, setIsOpen] = useState(true);

  const handleOpen = () => {
    setIsOpen(prev => !prev);
  };

  return (
    <Main>
      <MainWrapper onClick={handleOpen}>
        <BigidHeading5>{header}</BigidHeading5>
        <HeaderSection>
          {customButton}
          {isOpen ? <BigidChevronUpIcon /> : <BigidChevronDownIcon />}
        </HeaderSection>
      </MainWrapper>
      <ContentSection isShown={isOpen}>{children}</ContentSection>
    </Main>
  );
};
