import React, { FC, Fragment, useCallback, useEffect, useState } from 'react';
import {
  BigidColors,
  BigidDialog,
  BigidFieldFilter,
  BigidIcon,
  BigidIconSize,
  BigidIconSourceType,
  BigidPrimaryCheckbox,
  BigidSelect,
  BigidSelectOption,
  PrimaryButton,
  SecondaryButton,
} from '@bigid-ui/components';
import { FormControl, FormHelperText, Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import {
  MaskOptions,
  MaskOptionsValue,
  SarFieldsSettings,
  SarFieldsSettingsPutBatchModel,
} from '../ProfileSettingsTypes';
import { notificationService } from '../../../../services/notificationService';
import { sarConfigService } from '../sarConfigService';
import { GenericField, getPutData, getSelectedOptions, getValueFromClearableSelect } from '../editGridRowsUtils';
import { GlossaryItemType } from '../../../../../administration/generalSettings/businessGlossary/businessGlossary.service';
import { GlossaryItemSelect, GlossaryItemSelectProps } from './GlossaryItemSelect';
import WarningIcon from '@mui/icons-material/Warning';
import { AxiosError } from 'axios';

const useStyles = makeStyles((theme: Theme) => ({
  wrapper: {
    display: 'flex',
    justifyContent: 'center',
    flexFlow: 'column nowrap',
    padding: theme.spacing(0, 3),
  },
}));

export interface EditFieldsDialogProps {
  profileId: string;
  isOpen: boolean;
  onSave?: (outputState: { newState: PartialPutFields; status: { isChanged?: boolean; isOk: boolean } }) => void;
  onClose?: () => void;
  sources: Partial<SarFieldsSettings>[];
  allSelected?: boolean;
  totalRows?: number;
  filter?: BigidFieldFilter[];
}

const TOTAL_ROWS_THRESHOLD = 10e3;
const TOTAL_ROWS_THRESHOLD_TEXT = '10k+';

export const EditFieldsDialog: FC<EditFieldsDialogProps> = ({
  profileId,
  isOpen,
  onSave,
  onClose,
  sources,
  filter,
  allSelected,
  totalRows,
}) => {
  const classes = useStyles({});

  const titleText = `Edit Field Settings`;
  const buttonText = 'Save';
  const subTitleText = (
    <Fragment>
      Editing field settings for{' '}
      {allSelected ? (
        totalRows >= TOTAL_ROWS_THRESHOLD ? (
          <strong>{TOTAL_ROWS_THRESHOLD_TEXT}</strong>
        ) : (
          totalRows
        )
      ) : (
        sources.length
      )}{' '}
      fields
    </Fragment>
  );

  const [isLoading, setIsLoading] = useState(false);

  const [maskOptions] = useState<BigidSelectOption[]>([
    { label: MaskOptionsValue.FULL_MASKING, value: MaskOptions.FULL },
    { label: MaskOptionsValue.PARTIAL_MASKING, value: MaskOptions.PARTIAL },
    { label: MaskOptionsValue.NO_MASKING, value: MaskOptions.NO },
  ]);

  const [includeExcludeOptions] = useState<BigidSelectOption[]>([
    { label: 'Include', value: true },
    { label: 'Exclude', value: false },
  ]);

  const [selectedIsMaskIt, setSelectedIsMaskIt] = useState<BigidSelectOption[]>([]);
  const [selectedIsIncludedInReport, setSelectedisIncludedInReport] = useState<BigidSelectOption[]>([]);
  const [entitySourceCheckboxValue, setEntitySourceCheckboxValue] = useState(false);
  const [isEntitySourceCheckboxDisabled, setIsEntitySourceCheckboxDisabled] = useState(false);

  const shouldShowEntitySourceCheckbox = sources.length === 1 && sources[0].attribute_type === 'Correlation';

  const handleIncludeInReportChange = (value: BigidSelectOption[]): void => {
    if (sources[0].attribute_type === 'Correlation' && !value[0].value) {
      setIsEntitySourceCheckboxDisabled(true);
      setEntitySourceCheckboxValue(false);
    } else {
      setIsEntitySourceCheckboxDisabled(false);
    }
    setSelectedisIncludedInReport(value);
  };

  const [glossarySelectsIsChanged, setGlossarySelectsIsChanged] = useState<Record<GlossaryItemType, boolean>>({
    [GlossaryItemType.PersonalDataItem]: false,
    [GlossaryItemType.PurposeOfProcessing]: false,
    [GlossaryItemType.PersonalDataCategory]: false,
  });

  const [glossarySelectsValues, setGlossarySelectsValues] = useState<
    Record<GlossaryItemType, GlossaryItemSelectProps['value']>
  >({
    [GlossaryItemType.PersonalDataItem]: [],
    [GlossaryItemType.PurposeOfProcessing]: [],
    [GlossaryItemType.PersonalDataCategory]: [],
  });

  const [glossarySelectsProps] = useState<
    (Omit<GlossaryItemSelectProps, 'value'> & {
      fieldName: 'friendly_name_glossary_id' | 'purpose_glossary_id' | 'category_glossary_id';
    })[]
  >([
    {
      title: 'Friendly Name',
      type: GlossaryItemType.PersonalDataItem,
      fieldName: 'friendly_name_glossary_id',
      onSelectionChange: ids => {
        setGlossarySelectsIsChanged(prevState => ({ ...prevState, [GlossaryItemType.PersonalDataItem]: true }));
        setGlossarySelectsValues(prevState => ({ ...prevState, [GlossaryItemType.PersonalDataItem]: ids }));
      },
    },
    {
      title: 'Purpose Of Use',
      type: GlossaryItemType.PurposeOfProcessing,
      fieldName: 'purpose_glossary_id',
      onSelectionChange: ids => {
        setGlossarySelectsIsChanged(prevState => ({ ...prevState, [GlossaryItemType.PurposeOfProcessing]: true }));
        setGlossarySelectsValues(prevState => ({ ...prevState, [GlossaryItemType.PurposeOfProcessing]: ids }));
      },
    },
    {
      title: 'Category Item',
      type: GlossaryItemType.PersonalDataCategory,
      fieldName: 'category_glossary_id',
      onSelectionChange: ids => {
        setGlossarySelectsIsChanged(prevState => ({ ...prevState, [GlossaryItemType.PersonalDataCategory]: true }));
        setGlossarySelectsValues(prevState => ({ ...prevState, [GlossaryItemType.PersonalDataCategory]: ids }));
      },
    },
  ]);

  const setFormDataFromSources = useCallback(
    (sources: Partial<SarFieldsSettings>[]) => {
      const selectedIsMaskItValues = getSelectedOptions(sources, 'mask_operation', maskOptions);
      const isIncludedInReportValues = getSelectedOptions(sources, 'is_included_in_report', includeExcludeOptions);

      setSelectedIsMaskIt(selectedIsMaskItValues);
      setSelectedisIncludedInReport(isIncludedInReportValues);

      const newGlossarySelectedValues = Object.fromEntries(
        glossarySelectsProps.map(({ type, fieldName }) => {
          const selectedValues = sources.map(source => source[fieldName]);
          return [type, selectedValues];
        }),
      ) as Record<GlossaryItemType, GlossaryItemSelectProps['value']>;
      setGlossarySelectsValues(newGlossarySelectedValues);

      setGlossarySelectsIsChanged(
        Object.fromEntries(glossarySelectsProps.map(({ type }) => [type, false])) as Record<GlossaryItemType, boolean>,
      );
    },
    [maskOptions, includeExcludeOptions, glossarySelectsProps],
  );

  useEffect(() => {
    if (isOpen) {
      setFormDataFromSources(sources);
      setEntitySourceCheckboxValue(
        sources.length === 1 && sources[0].attribute_type === 'Correlation'
          ? sources[0].is_included_in_proximity_category
          : undefined,
      );
      setIsEntitySourceCheckboxDisabled(sources.length === 1 && !sources[0].is_included_in_report);
    } else {
      setSelectedIsMaskIt([]);
      setSelectedisIncludedInReport([]);
      glossarySelectsProps.forEach(selectProps => selectProps.onSelectionChange([undefined]));
    }
  }, [glossarySelectsProps, isOpen, setFormDataFromSources, sources]);

  const handleOnSave = () => {
    const newState: PartialPutFields = {
      mask_it: !!selectedIsMaskIt?.[0]?.value,
      mask_operation: selectedIsMaskIt?.[0]?.value,
      is_included_in_report: selectedIsIncludedInReport?.[0]?.value,
      friendly_name_glossary_id: getNewStateFromGlossarySelect(GlossaryItemType.PersonalDataItem),
      purpose_glossary_id: getNewStateFromGlossarySelect(GlossaryItemType.PurposeOfProcessing),
      category_glossary_id: getNewStateFromGlossarySelect(GlossaryItemType.PersonalDataCategory),
      is_included_in_proximity_category: entitySourceCheckboxValue,
    };

    setIsLoading(true);
    updateSources(profileId, newState, sources, allSelected, filter)
      .then(() => {
        onSave({ newState, status: { isChanged: true, isOk: true } });
      })
      .catch(() => {
        onSave({ newState, status: { isChanged: false, isOk: false } });
      })
      .finally(() => setIsLoading(false));

    function getNewStateFromGlossarySelect(type: GlossaryItemType) {
      if (glossarySelectsIsChanged[type]) {
        // TODO: need to support multiple values per field item
        return glossarySelectsValues[type]?.[0];
      }
    }
  };

  return (
    <BigidDialog
      title={titleText}
      isOpen={isOpen}
      onClose={onClose}
      borderTop={true}
      buttons={[
        { component: SecondaryButton, onClick: onClose, text: 'Cancel' },
        {
          component: PrimaryButton,
          onClick: handleOnSave,
          text: buttonText,
        },
      ]}
      isLoading={isLoading}
    >
      <div className={classes.wrapper}>
        <form noValidate autoComplete="off">
          <fieldset>
            <FormControl fullWidth margin="normal">
              <div>{subTitleText}</div>
            </FormControl>
            <FormControl fullWidth margin="normal">
              <div>Include in Consumer Report</div>
              <BigidSelect
                name={'isIncludedInReport'}
                options={includeExcludeOptions}
                onChange={handleIncludeInReportChange}
                value={selectedIsIncludedInReport}
                menuPosition="fixed"
                message={
                  selectedIsIncludedInReport?.[0]?.label === 'Multiple Values' ? 'Select Value to Apply to all' : ''
                }
              />
            </FormControl>
            {shouldShowEntitySourceCheckbox && (
              <BigidPrimaryCheckbox
                checked={entitySourceCheckboxValue}
                disabled={isEntitySourceCheckboxDisabled}
                onChange={(e, val) => setEntitySourceCheckboxValue(val)}
                label={'Use as an entity source context for proximity data'}
              />
            )}
            <FormControl fullWidth margin="normal">
              <div>Mask Value</div>
              <BigidSelect
                name={'isMaskIt'}
                options={maskOptions}
                onChange={setSelectedIsMaskIt}
                value={selectedIsMaskIt}
                menuPosition="fixed"
                message={selectedIsMaskIt?.[0]?.label === 'Multiple Values' ? 'Select Value to Apply to all' : ''}
              />
            </FormControl>

            {glossarySelectsProps.map(selectProps => (
              <GlossaryItemSelect
                key={selectProps.type}
                {...selectProps}
                value={glossarySelectsValues[selectProps.type]}
              />
            ))}
            {allSelected && totalRows >= TOTAL_ROWS_THRESHOLD && (
              <FormControl fullWidth margin="normal">
                <FormHelperText>
                  <BigidIcon
                    type={BigidIconSourceType.MUI}
                    icon={WarningIcon}
                    color={BigidColors.warningYellow}
                    size={BigidIconSize.REGULAR}
                    label={`Updating ${TOTAL_ROWS_THRESHOLD_TEXT} fields might take some time.`}
                  />
                </FormHelperText>
              </FormControl>
            )}
          </fieldset>
        </form>
      </div>
    </BigidDialog>
  );
};

type PartialPutFields = SarFieldsSettingsPutBatchModel;

export async function updateSources(
  profileId: string,
  newState: PartialPutFields,
  sources: Partial<SarFieldsSettings>[],
  allSelected: boolean,
  gridQueryFilter: BigidFieldFilter[],
) {
  const genericFields: GenericField<SarFieldsSettings, PartialPutFields>[] = [
    {
      name: 'mask_it',
    },
    {
      name: 'mask_operation',
    },
    {
      name: 'is_included_in_report',
    },
    {
      name: 'is_included_in_proximity_category',
    },
    {
      name: 'friendly_name_glossary_id',
      getValue: ({ friendly_name_glossary_id: newValue }) => getValueFromClearableSelect(newValue),
    },
    {
      name: 'purpose_glossary_id',
      getValue: ({ purpose_glossary_id: newValue }) => getValueFromClearableSelect(newValue),
    },
    {
      name: 'category_glossary_id',
      getValue: ({ category_glossary_id: newValue }) => getValueFromClearableSelect(newValue),
    },
  ];

  const [updateDoc] = getPutData([], genericFields, newState, sources[0]);

  if (!updateDoc || !Object.keys(updateDoc).length) {
    notificationService.success(`Nothing to update`);
    return;
  }

  const postData = {
    data: updateDoc,
    query: {
      filter: allSelected
        ? gridQueryFilter
        : [
            {
              field: '_id',
              operator: 'in',
              value: sources.map(({ _id }) => _id),
            } as BigidFieldFilter,
          ],
    },
  };
  return sarConfigService
    .updateProfileFields(profileId, postData)
    .then(() => {
      notificationService.success(`Changes saved`);
    })
    .catch((err: AxiosError<any>) => {
      const { data } = err.response;
      const errMsg = data?.message || data?.error || '';
      notificationService.error(`Failed to save changes ${errMsg}`);
      console.error(`Failed to update sar-profile, postData: `, JSON.stringify(postData), errMsg, data);
      throw err;
    });
}
