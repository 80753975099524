import './ammap.component.scss';
import { module } from 'angular';
const app = module('app');
import template from './amMap.component.html';
import { getApplicationPreference } from '../../react/services/appPreferencesService';

// lazy load ammap lib
app.service('lazyLoadAmMapLib', function ($q) {
  'ngInject';
  let AmCharts;

  if (AmCharts) {
    return $q.resolve(AmCharts);
  }

  const importMap = getApplicationPreference('DISPLAY_CHINA_REGULATIONS')
    ? import(/* webpackChunkName: "lib-ammap-china" */ '../../../lib/amMap/ammap-china.all.js')
    : import(/* webpackChunkName: "lib-ammap" */ '../../../lib/amMap/ammap.all.js');

  return $q.resolve(
    importMap
      .then(() => {
        AmCharts = window.AmCharts;
        delete window.AmCharts;
        return AmCharts;
      })
      .catch(error => {
        window.console.log(`An error occurred while lazy loading AmMap lib, error: ${error}`);
        throw error;
      }),
  );
});

app.component('ammap', {
  template,
  controllerAs: 'mapModel',
  controller: function ($scope, $rootScope, $window, $element, lazyLoadAmMapLib) {
    'ngInject';
    const mapModel = this;
    let map;

    mapModel.$onInit = function () {
      mapModel.showMapContainerClass = this.showMapContainerClass;
      $scope.showClearFilter = false;
      clearSessionStorageItem();
    };

    //Build Map
    mapModel.$onChanges = changesObj => {
      $scope.mapStyle = mapModel.mapStyle;
      $scope.mapStyleContainer = mapModel.mapStyleContainer;

      if (changesObj.imagesArray && changesObj.imagesArray.currentValue) {
        lazyLoadAmMapLib.then(AmCharts => {
          const dataProvider = {
            map: getApplicationPreference('DISPLAY_CHINA_REGULATIONS')
              ? 'worldWithChinaRegulations'
              : 'worldUSStatesLow',
            getAreasFromMap: true,
            zoomLevel: mapModel.zoomLevel,
            images: changesObj.imagesArray.currentValue,
            lines: changesObj.linesArray.currentValue,
          };

          if (mapModel.zoomLatitude) {
            dataProvider.zoomLatitude = mapModel.zoomLatitude;
          }

          if (mapModel.zoomLongitude) {
            dataProvider.zoomLongitude = mapModel.zoomLongitude;
          }

          map = new AmCharts.makeChart($element[0].querySelector('#chartdiv'), {
            type: 'map',
            theme: 'none',
            dataProvider,

            //color of the land
            areasSettings: {
              color: '#CDD1E4',
              rollOverOutlineColor: '#FF5E43',
            },

            balloon: {
              adjustBorderColor: true,
              color: 'white',
              cornerRadius: 0,
              borderThickness: 0,
              fillColor: 'black',
              showBullet: true,
            },

            imagesSettings: {
              color: '#CC0000',
              rollOverColor: '#CC0000',
              selectedColor: 'blue',
              alpha: '0.6',
            },

            linesSettings: {
              arc: -0.85, // this makes lines curved. Use value from -1 to 1
              //arrow: "middle",
              color: '#825BF6',
              alpha: 0.4,
              //arrowAlpha: 1,
              //arrowSize: 4
            },

            zoomControl: {
              zoomControlEnabled: false,
              homeButtonEnabled: false,
            },

            backgroundZoomsToTop: true,
            linesAboveImages: true,
            listeners: [
              {
                event: 'rendered',
                method: function (e) {
                  // Let's log initial zoom settings (for home button)
                  const map = e.chart;
                  map.initialZoomLevel = map.zoomLevel();
                  map.initialZoomLatitude = map.zoomLatitude();
                  map.initialZoomLongitude = map.zoomLongitude();
                },
              },
              {
                event: 'zoomCompleted',
                method: function (e) {
                  const map = e.chart;

                  const zoomLevel = map.zoomLevel();
                  const zoomLatitude = map.zoomLatitude();
                  const zoomLongitude = map.zoomLongitude();

                  mapModel.onZoomChanged({
                    data: {
                      zoomLevel,
                      zoomLatitude,
                      zoomLongitude,
                    },
                  });
                },
              },
            ],
          });

          map.addListener('clickMapObject', function (event) {
            const map = event.chart;

            if (mapModel.isClickedOnImageObject) {
              for (let i = 0; i < map.dataProvider.images.length; i++) {
                if (event.mapObject === map.dataProvider.images[i]) {
                  map.dataProvider.images[i].alpha = 1;
                  $scope.$apply(function () {
                    if (event.mapObject.groupId == 'user') {
                      // || event.mapObject.groupId == "application"){
                      map.mapLinesContainer.hide();
                    }

                    $scope.showClearFilter = true;
                    $window.sessionStorage.setItem(
                      event.mapObject.groupId + '.location',
                      event.mapObject.groupId + '.location=' + event.mapObject.country,
                    );

                    const newFilterValue = event.mapObject.groupId + '.location=' + event.mapObject.country;
                    // new mode -> output changes with callback
                    if (mapModel.useBindingsDataFlow && mapModel.onAmmapFilterChange) {
                      mapModel.onAmmapFilterChange(newFilterValue);
                    }
                    // legacy mode -> pub/sub based
                    if (!mapModel.useBindingsDataFlow) {
                      $rootScope.$emit('ammapFilter', newFilterValue);
                    }
                  });
                } else {
                  if (event.mapObject.svgPath) {
                    map.dataProvider.images[i].alpha = 0.1;
                  }
                }
                map.dataProvider.images[i].validate();
              }
            }
          });
        });
      }
    };

    //Zoom In button
    $scope.zoomIn = function () {
      map.zoomIn();
    };

    //Zoom Out button
    $scope.zoomOut = function () {
      map.zoomOut();
    };

    //Center Map button
    $scope.centerMap = function () {
      map.zoomToLongLat(map.initialZoomLevel, map.initialZoomLongitude, map.initialZoomLatitude);
    };

    $scope.toggleGroup = function ($event) {
      const checkApplicationStatus = $scope.checkApplicationStatus;
      const checkSystemStatus = $scope.checkSystemStatus;
      const checkIdentityStatus = $scope.checkIdentityStatus;

      const group = $event.target.value;
      const shown = $event.target.checked;
      if (shown) {
        map.showGroup(group);
        if (checkApplicationStatus && checkSystemStatus) {
          map.mapLinesContainer.show();
          map.showGroup('plane');
        }
      } else {
        map.hideGroup(group);
        if ($event.target.value === 'application' || $event.target.value === 'system') {
          map.mapLinesContainer.hide();
          map.hideGroup('plane');
        }
      }
    };

    //Clear filter Button Event
    $scope.clearMapslectedObject = function () {
      clearFilter();
    };

    function clearSessionStorageItem() {
      $window.sessionStorage.removeItem('user.location');
      $window.sessionStorage.removeItem('system.location');
    }

    function clearFilter() {
      if (mapModel.useBindingsDataFlow && mapModel.onAmmapClearFilter) {
        mapModel.onAmmapClearFilter();
      }

      if (!mapModel.useBindingsDataFlow) {
        $rootScope.$emit('ClearFilterMapClicked');
      }
    }

    const listenerCleanAmmapFilter = $rootScope.$on('cleanAmmapFilter', function () {
      if (mapModel.useBindingsDataFlow) {
        return;
      }
      $scope.showClearFilter = false;
      showImagesAfterCleanFilter();
    });

    function showImagesAfterCleanFilter() {
      for (let i = 0; i < map.dataProvider.images.length; i++) {
        map.dataProvider.images[i].alpha = 1;
        map.dataProvider.images[i].validate();
      }
    }

    //Unregister listeners
    $scope.$on('$destroy', function () {
      listenerCleanAmmapFilter();
    });
  },
  bindings: {
    imagesArray: '<',
    linesArray: '<',
    mapStyle: '<',
    mapStyleContainer: '<',
    isClickedOnImageObject: '<',
    showMapContainerClass: '<',
    zoomLevel: '<',
    zoomLatitude: '<',
    zoomLongitude: '<',
    onZoomChanged: '&',
    useBindingsDataFlow: '<',
    onAmmapFilterChange: '<',
    onAmmapClearFilter: '<',
  },
});
