import { module } from 'angular';
const app = module('app');

app.factory('scanAnalysisService', [
  '$http',
  'appSettings',
  ($http, appSettings) => {
    return {
      getConfidenceThreshold: () =>
        $http
          .get(`${appSettings.serverPath}/${appSettings.version}/confidence-level-config`)
          .then(response => response.data),
      updateConfidenceThreshold: data =>
        $http
          .put(`${appSettings.serverPath}/${appSettings.version}/confidence-level-config`, data)
          .then(response => response.data),
      updateCache: () =>
        $http
          .post(`${appSettings.serverPath}/${appSettings.version}/manualUpdateCache`)
          .then(response => response.data),
      exportAsCsv: () =>
        $http
          .get(`${appSettings.serverPath}/${appSettings.version}/scan-result/paging/csv`)
          .then(response => response.data),
      exportAsCsvStream: () =>
        $http
          .get(`${appSettings.serverPath}/${appSettings.version}/scan-result/stream/csv`)
          .then(response => response.data),
      exportAsCompressedCsvStream: () =>
        $http
          .get(`${appSettings.serverPath}/${appSettings.version}/scan-result/stream/zip`, { responseType: 'blob' })
          .then(response => response.data),
      exportAsJson: () =>
        $http
          .get(`${appSettings.serverPath}/${appSettings.version}/scan-result/paging/json`)
          .then(response => response.data),
      exportAsJsonStream: pageSize =>
        $http
          .get(`${appSettings.serverPath}/${appSettings.version}/scan-result/stream/json?pageSize=${pageSize}`)
          .then(response => response.data),
      updateConfidenceLevel: data =>
        $http
          .post(`${appSettings.serverPath}/${appSettings.version}/confidence-levels`, data)
          .then(response => response.data),
      getColumnsFilterOptions: () =>
        $http
          .get(`${appSettings.serverPath}/${appSettings.version}/scan-result/column-filters`)
          .then(response => response.data),
      updateRowsTags: data =>
        $http
          .put(`${appSettings.serverPath}/${appSettings.version}/scan-result/tags`, data)
          .then(response => response.data),
      updateRowsComment: data =>
        $http
          .put(`${appSettings.serverPath}/${appSettings.version}/scan-result/comment`, data)
          .then(response => response.data),
    };
  },
]);
