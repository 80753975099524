import React, { FC, useMemo, useRef, useState, useCallback } from 'react';
import {
  BigidForm,
  BigidFormProps,
  BigidFormStateAndHandlers,
  BigidFormValues,
  BigidLoader,
  PrimaryButton,
} from '@bigid-ui/components';
import { CollaborationFieldValues } from '../../DataSources/DataSourceConnectionModal/config/collaboration';
import { Collaboration } from '../../DataSources/DataSourceConnectionModal/mappers/styles';
import { createFieldConfig } from './autoDiscoveryCollaborationUtils';
import { useSearchUsers } from '../../DataSources/DataSourceConnectionModal/hooks/useSearchUsers';
import { useUser } from '../../DataSources/DataSourceConnectionModal/hooks/useUser';
import { AutoDiscoveryWizardContextState } from '../AutoDiscoveryWizard/autoDiscoveryWizardContext';
import { inviteAutoDiscoveryCollaborators } from './autoDiscoveryCollaborationService';
import { DataSourceConnectionCollaborationFooter } from '../../DataSources/DataSourceConnectionModal/DataSourceConnectionCollaboration/components/DataSourceConnectionCollaborationFooter';
import {
  AutoDiscoveryCollaborationForm,
  AutoDiscoveryCollaborationPlaceholder,
} from './AutoDiscoverCollaborationStyles';
import { useLocalTranslation } from '../translations';
import { AUTO_DISCOVER_PREREQUISITES_CONTENT_ID, cloudProviderLabel } from '../constants';
import { getInstructionFromElementById } from '../AutoDiscoveryWizard/autoDiscoveryWizardUtils';
import { useCheckAutoDiscoverCollaboration } from '../hooks/useCheckAutoDiscoverCollaboration';
import { notificationService } from '../../../services/notificationService';

export interface AutoDiscoveryCollaborationModalProps {
  discoveryConfigDataRef: React.MutableRefObject<AutoDiscoveryWizardContextState>;
  message?: string;
}

export const AutoDiscoveryCollaborationModal: FC<AutoDiscoveryCollaborationModalProps> = ({
  discoveryConfigDataRef,
  message,
}) => {
  const { t } = useLocalTranslation('AutoDiscovery');
  const [isLoading, setIsLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState('');
  const { email } = useUser();
  const ignoredUsers = useMemo(() => ['bigid-scanner', email], [email]);
  const { data: users } = useSearchUsers(
    searchTerm,
    { filter: null },
    {
      query: {
        select({ data }) {
          // @info remove current and system users from user list
          return data?.users.filter(({ name }) => !ignoredUsers.includes(name));
        },
      },
    },
  );
  const [inviteStatus, setInviteStatus] = useCheckAutoDiscoverCollaboration({
    users,
    discoveryConfigDataRef,
    setIsLoading,
  });
  const ref = useRef<BigidFormStateAndHandlers>();
  const [isReadyToAddCollaborator, setIsReadyToAddCollaborator] = useState(
    () => !!ref.current?.getValues?.()?.collaborators?.length,
  );

  const handleFormOnChange = (values: BigidFormValues) => {
    const { collaborators } = (values as CollaborationFieldValues) ?? {};
    setIsReadyToAddCollaborator(!!collaborators?.length);
    discoveryConfigDataRef.current = {
      ...discoveryConfigDataRef.current,
      collaborators,
    };
  };

  const handleSearchChange = (...props: unknown[]) => {
    const [term] = props as [string];
    setSearchTerm(term);
  };

  const fields = useMemo(
    () => createFieldConfig(discoveryConfigDataRef?.current?.type, users, handleSearchChange),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [users],
  );

  const formProps: BigidFormProps = useMemo(
    () => ({
      fields,
      onChange: handleFormOnChange,
      controlButtons: false,
      stateAndHandlersRef: ref,
      initialValues: {
        collaborators: discoveryConfigDataRef?.current?.collaborators ?? [],
        message:
          message ||
          t('prerequisites.email.body', {
            type: cloudProviderLabel[discoveryConfigDataRef?.current?.type],
            instruction: getInstructionFromElementById(AUTO_DISCOVER_PREREQUISITES_CONTENT_ID),
          }),
      },
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [fields, isLoading],
  );
  const handleInvite = useCallback(async () => {
    try {
      const { collaborators, message } = ref.current?.getValues() ?? {};
      await inviteAutoDiscoveryCollaborators(
        {
          collaborators,
          message,
          email,
          type: discoveryConfigDataRef?.current?.type,
        },
        t,
      );
      setInviteStatus(true);
      notificationService.success(t('collaborateModal.createSuccess'));
    } catch (err) {
      notificationService.error(t('collaborateModal.createError'));
    }
  }, [discoveryConfigDataRef, email, setInviteStatus, t]);

  const inviteDisabled = !isReadyToAddCollaborator || isLoading;

  return (
    <Collaboration.Content data-aid={'AutoDiscoveryCollaborationModal'}>
      <Collaboration.Actions isColumn={true}>
        <PrimaryButton
          dataAid={'AutoDiscoveryCollaborationInviteButton'}
          size="medium"
          text={t(inviteStatus ? 'collaborateModal.reInviteButton' : 'collaborateModal.inviteButton')}
          onClick={handleInvite}
          disabled={inviteDisabled}
        />
      </Collaboration.Actions>
      <AutoDiscoveryCollaborationForm isReInvite={inviteStatus}>
        {isLoading ? <BigidLoader /> : <BigidForm {...formProps} />}
      </AutoDiscoveryCollaborationForm>
      {!isReadyToAddCollaborator && (
        <AutoDiscoveryCollaborationPlaceholder>
          {t('collaborateModal.emailPlaceholder')}:
        </AutoDiscoveryCollaborationPlaceholder>
      )}
      <DataSourceConnectionCollaborationFooter isCompact={true} />
    </Collaboration.Content>
  );
};
