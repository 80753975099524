import styled from '@emotion/styled';

export const UsersGridEmptyStateWrapper = styled('div')({
  height: '100%',
  width: '100%',
  position: 'absolute',
  top: '0',
});

export const UsersContentWarapper = styled('div')(({ theme }) => ({
  flexGrow: '1',
  marginBottom: '24px',
  display: 'flex',
  flexFlow: 'column nowrap',
  border: theme.vars.tokens.bigid.borderDefault,
  boxShadow: theme.vars.tokens.bigid.shadow10,
  borderRadius: '6px',
  overflow: 'hidden',
  background: theme.vars.palette.bigid.white,
}));

export const UserOrigin = styled('span')({
  textTransform: 'capitalize',
});

export const ToolbarWrapper = styled('div')(({ theme }) => ({
  borderTop: theme.vars.tokens.bigid.borderDefault,
  flexShrink: 0,
}));

export const HeadingAndControlsBlock = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  gap: '16px',
});
