import { ComponentType, ReactText } from 'react';
import {
  BigidAttribute2Icon,
  BigidPolicy2Icon,
  IconComponentProps,
  BigidScannedVolumeIcon,
  BigidDataFindingsIllustration,
  BigidDataFileIllustration,
} from '@bigid-ui/icons';
import { BigidAdvancedToolbarFilterUnion, BigidDropdownOption, BigidColorsV2 } from '@bigid-ui/components';
import {
  Aggregation,
  AggregationType,
  AggregationItemLookupParams,
  AggregationItemBase,
  AggregationItemName,
} from '../catalogDiscoveryTypes';
import { PieChartWidgetSeries } from '../widgets/PieChartWidget/PieChartWidget';
import { BarChartWidgetItem } from '../widgets/BarChartWidget/BarChartWidget';
import {
  getSensitivityAggregationValueColor,
  getSensitivityAggregationValueLabel,
  getDataFormatAggregationValueColor,
  getDataFormatAggregationValueLabel,
  getObjectStatusAggregationValueLabel,
  getObjectStatusAggregationValueColor,
  getAggregationTotalReducedBy,
} from './common';
import { formatNumberCompact } from '../../../utilities/numericDataConverter';
import { pieChartItemsOrderMap, barChartItemsOrderMap } from '../config/widgets';

type NumericValueFormatterFunc = (value: number) => string;

export type GetPieChartWidgetUnknownDataPayload = {
  aggregationData: AggregationItemBase[];
  filterType: AggregationType;
  filters?: BigidAdvancedToolbarFilterUnion[];
  groupBy?: 'aggItemName' | 'aggItemGroup';
  colors?: string[];
};

export type GetPieChartWidgetKnownDataPayload = {
  aggregationData: AggregationItemBase[];
  aggregationType: AggregationType;
  associatedFilterType?: AggregationType;
  filters?: BigidAdvancedToolbarFilterUnion[];
  isDefaultOptions?: boolean;
};

export function getSummaryWidgetItem(data: Aggregation, lookupAggItemName: AggregationItemName): AggregationItemBase {
  const aggregationData = data?.aggData ?? [];
  return aggregationData?.find(({ aggItemName }) => aggItemName === lookupAggItemName);
}

export function getSummaryWidgetValue(
  data: Aggregation,
  lookupProps: AggregationItemLookupParams,
  valueFormatter?: NumericValueFormatterFunc,
): ReactText {
  const aggregationData = data?.aggData ?? [];
  const { name, value } = lookupProps;
  const aggregationItem = name ? aggregationData.find(({ aggItemName }) => aggItemName === name) : aggregationData[0];
  const valueComputed = !isNaN(aggregationItem?.[value]) ? Number(aggregationItem?.[value]) : 0;

  return valueFormatter ? valueFormatter(valueComputed) : formatNumberCompact(valueComputed, 1);
}

export function getItemlessSummaryWidgetValue(
  data: Aggregation,
  valueFormatter?: NumericValueFormatterFunc,
): ReactText {
  const aggregationTotal = data?.aggTotal ?? 0;
  const valueComputed = !isNaN(aggregationTotal) ? aggregationTotal : 0;

  return valueFormatter ? valueFormatter(valueComputed) : formatNumberCompact(valueComputed, 1);
}

export function getAggregationKnownValueColor(type: AggregationType, aggregationItemName: string): string {
  switch (type) {
    case AggregationType.OBJECT_STATUS:
      return getObjectStatusAggregationValueColor(aggregationItemName);
    case AggregationType.SENSITIVITY:
      return getSensitivityAggregationValueColor(aggregationItemName);
    case AggregationType.DATA_FORMAT:
      return getDataFormatAggregationValueColor(aggregationItemName);
    default:
      return BigidColorsV2.gray[100];
  }
}

export function getAggregationKnownValueDisplayName(type: AggregationType, aggregationItemName: string): string {
  switch (type) {
    case AggregationType.OBJECT_STATUS:
      return getObjectStatusAggregationValueLabel(aggregationItemName);
    case AggregationType.SENSITIVITY:
      return getSensitivityAggregationValueLabel(aggregationItemName);
    case AggregationType.DATA_FORMAT:
      return getDataFormatAggregationValueLabel(aggregationItemName);
    default:
      return aggregationItemName;
  }
}

/**
 * Returns a widget data based on a predefined set of options
 */
export function getPieChartWidgetKnownData({
  aggregationData,
  aggregationType,
  associatedFilterType,
  filters,
}: GetPieChartWidgetKnownDataPayload): PieChartWidgetSeries[] {
  const aggregationDefaultOptions = pieChartItemsOrderMap.get(aggregationType) ?? [];
  const aggregationDataComputed = aggregationData ?? [];

  return aggregationDefaultOptions.map(defaultOption => {
    const optionName = defaultOption.aggItemName;
    const aggregationItem = aggregationDataComputed.find(({ aggItemName }) => aggItemName === optionName);
    const value = aggregationItem?.docCount ?? 0;
    const filter = filters?.find(({ id }) => id === (associatedFilterType ?? aggregationType));

    const active = Boolean(
      (filter?.options as BigidDropdownOption[])?.find(filterOption => {
        const value = filterOption.value as AggregationItemBase;
        return value.aggItemName === (aggregationItem ?? defaultOption).aggItemName;
      }),
    );

    return {
      value,
      active,
      color: getAggregationKnownValueColor(aggregationType, optionName),
      category: getAggregationKnownValueDisplayName(aggregationType, optionName),
      aggItem: aggregationItem ?? defaultOption,
    };
  });
}

export function getSensitivityPieChartData({
  aggregationData,
  aggregationType,
  associatedFilterType,
  filters,
  isDefaultOptions,
}: GetPieChartWidgetKnownDataPayload): PieChartWidgetSeries[] {
  let aggregationDefaultOptions;

  if (isDefaultOptions) {
    aggregationDefaultOptions = pieChartItemsOrderMap.get(aggregationType) ?? [];
  } else {
    const sortedAggItemsData = aggregationData.sort((a, b) => b.docCount - a.docCount);
    const aggItemNames = sortedAggItemsData.map(item => ({
      aggItemName: item.aggItemName,
    }));
    aggregationDefaultOptions = aggItemNames.slice(0, 6); // We display max of 6 items for sensitivity
    aggregationDefaultOptions = aggregationDefaultOptions.sort((a, b) => a.aggItemName.localeCompare(b.aggItemName));
  }

  const aggregationDataComputed = aggregationData ?? [];

  return aggregationDefaultOptions.map((option, index) => {
    const optionName = option.aggItemName;
    const aggregationItem = aggregationDataComputed.find(({ aggItemName }) => aggItemName === optionName);
    const value = aggregationItem?.docCount ?? 0;
    const filter = filters?.find(({ id }) => id === (associatedFilterType ?? aggregationType));

    const active = Boolean(
      (filter?.options as BigidDropdownOption[])?.find(filterOption => {
        const value = filterOption.value as AggregationItemBase;
        return value.aggItemName === (aggregationItem ?? option).aggItemName;
      }),
    );

    return {
      value,
      active,
      color: getSensitivityAggregationValueColor(optionName, isDefaultOptions, index),
      category: getAggregationKnownValueDisplayName(aggregationType, optionName),
      aggItem: aggregationItem ?? option,
    };
  });
}

/**
 * returns a widget data based on random set of aggregated data
 */
export function getPieChartWidgetUnknownData({
  aggregationData,
  filterType,
  filters = [],
  groupBy = 'aggItemName',
  colors = [],
}: GetPieChartWidgetUnknownDataPayload): PieChartWidgetSeries[] {
  const groupByComputed = groupBy ?? 'aggItemName';

  return aggregationData?.map((aggregationItem, index) => {
    const itemValueComputed = aggregationItem[groupByComputed];
    const filter = filters?.find(({ id }) => id === filterType);
    const active = Boolean(
      (filter?.options as BigidDropdownOption[])?.find(filterOption => {
        const value = filterOption.value as AggregationItemBase;

        return value[groupByComputed] === itemValueComputed;
      }),
    );

    return {
      value: aggregationItem.docCount,
      active,
      color: colors[index] || BigidColorsV2.gray[100],
      category: itemValueComputed,
      aggItem: aggregationItem,
    };
  });
}

export function getPieChartWidgetTotal(data: Partial<Aggregation>, groupBy?: 'docCount' | 'findings'): number {
  if (groupBy) {
    return getAggregationTotalReducedBy(data.aggData ?? [], groupBy);
  }

  return data?.aggTotal ?? 0;
}

export function getAggregationIcon(aggregation: AggregationType): ComponentType<IconComponentProps> {
  switch (aggregation) {
    case AggregationType.HAS_FINDINGS:
      return BigidDataFindingsIllustration;
    case AggregationType.HAS_OPEN_ACCESS:
      return BigidAttribute2Icon;
    case AggregationType.HAS_DUPLICATES:
      return BigidDataFileIllustration;
    case AggregationType.VIOLATED_POLICY_CARDINALITY:
      return BigidPolicy2Icon;
    case AggregationType.SCANNED_VOLUME:
      return BigidScannedVolumeIcon;
  }
}

export type GetBarChartWidgetUnknownDataPayload = {
  aggregationData: AggregationItemBase[];
  groupBy?: 'aggItemName' | 'aggItemGroup';
  type: AggregationType;
  useCount?: 'findings' | 'docCount' | 'groupDocCount';
  colors?: string[];
  filter?: BigidAdvancedToolbarFilterUnion[];
};

export function getBarChartWidgetUnknownData({
  aggregationData,
  groupBy = 'aggItemName',
  useCount = 'docCount',
  colors = [],
  filter = [],
  type,
}: GetBarChartWidgetUnknownDataPayload): BarChartWidgetItem[] {
  return aggregationData?.map((aggregationItem, index) => {
    const widgetFilter = filter?.find(({ id }) => id === type);
    const isActive = Boolean(
      (widgetFilter?.options as BigidDropdownOption[])?.find(filterOption => {
        const value = filterOption.value as AggregationItemBase;
        return value[groupBy] === aggregationItem[groupBy];
      }),
    );

    return {
      name: aggregationItem[groupBy],
      value: aggregationItem[useCount],
      color: colors[index] || BigidColorsV2.gray[100],
      aggItem: aggregationItem,
      isActive,
    };
  });
}

export type GetBarChartWidgetKnownDataPayload = {
  aggregationData: AggregationItemBase[];
  type: AggregationType;
  filter?: BigidAdvancedToolbarFilterUnion[];
  groupBy?: 'aggItemName' | 'aggItemGroup';
};

export function getBarChartWidgetKnownData({
  aggregationData,
  type,
  groupBy = 'aggItemName',
  filter = [],
}: GetBarChartWidgetKnownDataPayload): BarChartWidgetItem[] {
  const aggregationDefaultOptions = barChartItemsOrderMap.get(type) ?? [];

  return aggregationDefaultOptions?.map(defaultOption => {
    const optionName = defaultOption[groupBy];
    const aggregationItem = aggregationData.find(aggItem => aggItem[groupBy] === optionName);
    const value = aggregationItem?.docCount ?? 0;
    const widgetFilter = filter?.find(({ id }) => id === type);
    const isActive = Boolean(
      (widgetFilter?.options as BigidDropdownOption[])?.find(filterOption => {
        const value = filterOption.value as AggregationItemBase;
        return value[groupBy] === (aggregationItem ?? defaultOption)[groupBy];
      }),
    );

    return {
      name: getAggregationKnownValueDisplayName(type, optionName),
      value,
      color: value > 0 ? getAggregationKnownValueColor(type, optionName) : BigidColorsV2.gray[150],
      aggItem: aggregationItem,
      isActive,
    };
  });
}
