import React, { FC, Fragment, memo } from 'react';
import { PrimaryButton, SecondaryButton, BigidLoader } from '@bigid-ui/components';
import { useStyles } from './MetadataSearchFiltersStyles';
import { MetadataSearchFilterComponentBaseProps, MetadataSearchFilterFieldType } from './MetadataSearchFiltersTypes';
import { getFilterFieldComponent } from './MetadataSearchFiltersUtils';
import { MetadataSearchErrorHandler } from '../MetadataSearchErrorHandler';
import { UseMetadataSearchStateResponse } from '../useMetadataSearchState';
import classNames from 'classnames';

export type MetadataSearchFiltersProps = Pick<
  UseMetadataSearchStateResponse,
  | 'query'
  | 'filtersConfig'
  | 'isFiltersFetchingLingers'
  | 'isFiltersFetching'
  | 'filters'
  | 'onFiltersSubmit'
  | 'onFiltersReset'
  | 'error'
  | 'selectedField'
> &
  Omit<MetadataSearchFilterComponentBaseProps, 'value' | 'config'>;

export const MetadataSearchFilters: FC<MetadataSearchFiltersProps> = memo(
  ({
    dataAid,
    query,
    filtersConfig,
    filters,
    error,
    isFiltersFetching,
    isFiltersFetchingLingers,
    onFilterChange,
    onFilterSearch,
    onFiltersReset,
    onFiltersSubmit,
    selectedField,
  }) => {
    const classes = useStyles();

    const isSearchButtonDisabled =
      (filters?.length === 0 && query?.length === 0) || isFiltersFetching || Boolean(error);

    const getFilterWrapperClass = (fieldType: MetadataSearchFilterFieldType): string => {
      switch (fieldType) {
        case MetadataSearchFilterFieldType.BOOLEAN:
          return classes.filter25;
        case MetadataSearchFilterFieldType.DATE:
          return classes.filter100;
        case MetadataSearchFilterFieldType.NUMBER:
          return classes.filter100;
        case MetadataSearchFilterFieldType.OBJECT:
          return classes.filter50;
        case MetadataSearchFilterFieldType.STRING:
          return classes.filter50;
        case MetadataSearchFilterFieldType.USER:
          return classes.filter100;
        case MetadataSearchFilterFieldType.TAGS:
          return classes.filter50;
        case MetadataSearchFilterFieldType.ENTITY_TYPE:
          return classes.filter50;
        case MetadataSearchFilterFieldType.HASH_STRING:
          return classes.filter50;
        default:
          return classes.filter50;
      }
    };

    return (
      <div data-aid={dataAid} className={classNames(classes.root, isFiltersFetchingLingers && classes.busy)}>
        <div className={classes.filters}>
          {error ? (
            <MetadataSearchErrorHandler {...error} />
          ) : (
            <>
              {isFiltersFetchingLingers && <BigidLoader />}
              {filtersConfig.map((filter, index) => {
                const { fieldName, fieldType, specialValueFieldName } = filter;
                const Component = getFilterFieldComponent(fieldType);
                const value = filters.find(filter => filter.field === fieldName);
                const wrapperClass = getFilterWrapperClass(fieldType);
                const focused = (selectedField?.isSpecial ? specialValueFieldName : fieldName) === selectedField?.field;

                return (
                  <Fragment key={`${fieldName}-${index}`}>
                    {wrapperClass === 'filter100' && <div className={classes.flexBreak} />}
                    <div className={classNames(classes.filter, wrapperClass)}>
                      {Component && (
                        <Component
                          dataAid={`${dataAid}-${fieldName}-filter`}
                          config={filter}
                          value={value}
                          isFiltersFetching={isFiltersFetching}
                          onFilterChange={onFilterChange}
                          onFilterSearch={onFilterSearch}
                          focused={focused}
                        />
                      )}
                    </div>
                  </Fragment>
                );
              })}
            </>
          )}
        </div>
        <div className={classes.controls}>
          <SecondaryButton
            dataAid={`${dataAid}-reset-button`}
            size="medium"
            onClick={onFiltersReset}
            disabled={isFiltersFetching}
            text="Reset"
          />

          <PrimaryButton
            dataAid={`${dataAid}-submit-button`}
            size="medium"
            onClick={onFiltersSubmit}
            disabled={isSearchButtonDisabled}
            text="Search"
          />
        </div>
      </div>
    );
  },
);
