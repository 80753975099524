import React, { FC, useMemo, useState } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import {
  BigidPaper,
  BigidIconSize,
  ActionData,
  BigidFilter,
  entityEventsEmitter,
  EntityEvents,
} from '@bigid-ui/components';
import { BigidGridWithToolbar, BigidGridWithToolbarProps, BigidGridColumnTypes } from '@bigid-ui/grid';
import { ActionCenterMetadataContainer } from '../useActionCenterMetadata';
import { queryService } from '../../../services/queryService';
import { notificationService } from '../../../services/notificationService';
import { dateUtils } from '../../../services/angularServices';
import { BigidScheduleIcon, BigidAddIcon, BigidAuditIllustration } from '@bigid-ui/icons';
import { isPermitted } from '../../../services/userPermissionsService';
import { ACTION_CENTER_PERMISSIONS } from '@bigid/permissions';
import { showConfirmationDialog } from '../../../services/confirmationDialogService';
import { deleteConfiguration, getConfigurations } from './configurationManagementService';
import { Configuration, EditConfigurationData } from './configurationManagementTypes';
import { ConfigurationDialog } from './ConfigurationDialog';
import { BigidLayoutEmptyState, BigidLayoutEmptyStateAction } from '@bigid-ui/layout';
import { removeTpasConfigurations } from './configurationManagementUtils';

const useStyles = makeStyles({
  gridWrapper: {
    width: '100%',
    display: 'flex',
    position: 'relative',
    height: '97%',
    maxHeight: '97%',
    overflow: 'hidden',
  },
  noData: {
    width: '100%',
    height: '100%',
    position: 'absolute',
  },
});

export const ConfigurationManagement: FC = ({}) => {
  const classes = useStyles();
  const [editConfigurationData, setEditConfigurationData] = useState<EditConfigurationData>();
  const [externalFilter, setExternalFilter] = useState<BigidFilter>([]);
  const [isFormOpen, setIsFormOpen] = useState(false);
  const { configurationsMetadata } = ActionCenterMetadataContainer.useContainer();

  const handleCloseForm = () => {
    setIsFormOpen(false);
    setEditConfigurationData(null);
  };

  const reloadGridAndClearSelection = () => {
    setExternalFilter([]);
  };

  const emptyStateActions: BigidLayoutEmptyStateAction[] = useMemo(
    () => [
      {
        label: 'Add Configuration',
        execute: async () => {
          setIsFormOpen(true);
          entityEventsEmitter.emit(EntityEvents.RELOAD);
          return { shouldGridReload: true };
        },
        show: () => isPermitted(ACTION_CENTER_PERMISSIONS.CREATE_CONFIGURATIONS.name),
      },
    ],
    [],
  );

  const config: BigidGridWithToolbarProps<Configuration> = useMemo(
    () => ({
      pageSize: 100,
      externalFilter,
      showSortingControls: true,
      defaultSorting: [{ field: 'name', order: 'desc' }],
      showSelectionColumn: true,
      noDataContent: (
        <div className={classes.noData}>
          <BigidLayoutEmptyState
            illustration={BigidAuditIllustration}
            actions={emptyStateActions}
            description="No configurations found"
          />
        </div>
      ),
      filterToolbarConfig: {
        filters: [
          {
            title: 'Type',
            field: 'type',
            operator: 'in',
            options: configurationsMetadata.map(({ type, displayName }) => ({
              label: displayName,
              value: type,
              isSelected: false,
            })),
            disabled: true,
            value: [],
          },
        ],
        searchConfig: {
          searchFilterKeys: ['name'],
          placeholder: 'Name',
          initialValue: '',
          operator: 'contains',
        },
      },
      fetchData: async queryComponents => {
        try {
          const gridConfigQuery = queryService.getGridConfigQuery(queryComponents);
          const { configurations } = await getConfigurations(gridConfigQuery);
          const { configurations: filteredConfigurations, totalCount: filteredTotalCount } =
            removeTpasConfigurations(configurations); // TODO - unite behavior for tpa to be regular AC configurations
          return {
            totalCount: filteredTotalCount,
            data: filteredConfigurations,
          };
        } catch ({ message }) {
          notificationService.error('There was a problem fetching your configurations');
          console.error(`There was a problem fetching your configurations: ${message}`);
          return {
            totalCount: 0,
            data: [],
          };
        }
      },
      columns: [
        {
          name: 'name',
          title: 'Configuration Name',
          getCellValue: row => row.name,
          type: BigidGridColumnTypes.TEXT,
        },
        {
          name: 'type',
          title: 'Configuration Type',
          getCellValue: row => configurationsMetadata.find(({ type }) => type === row.type).displayName,
          type: BigidGridColumnTypes.TEXT,
        },
        {
          name: 'created_at',
          title: 'Created At',
          getCellValue: row => ({
            icon: {
              icon: BigidScheduleIcon,
              label: dateUtils.formatDate(row.created_at),
              size: BigidIconSize.MEDIUM,
            },
          }),
          type: BigidGridColumnTypes.ICON,
        },
        {
          name: 'updated_at',
          title: 'Updated At',
          getCellValue: row => ({
            icon: {
              icon: BigidScheduleIcon,
              label: dateUtils.formatDate(row.updated_at),
              size: BigidIconSize.MEDIUM,
            },
          }),
          type: BigidGridColumnTypes.ICON,
        },
      ],
      toolbarActions: [
        {
          isGlobal: true,
          label: 'New Configuration',
          icon: BigidAddIcon,
          execute: async () => {
            setIsFormOpen(true);
            return {};
          },
          disable: () => false,
          show: () => isPermitted(ACTION_CENTER_PERMISSIONS.CREATE_CONFIGURATIONS.name),
        },
        {
          label: 'Edit',
          show: ({ selectedRowIds }: ActionData) =>
            selectedRowIds.length === 1 && isPermitted(ACTION_CENTER_PERMISSIONS.EDIT_CONFIGURATIONS.name),
          execute: async ({ selectedRows }: ActionData) => {
            const { id, type, name, configuration } = selectedRows[0];
            const editConfigurationData = { id, configuration: { type, name, configuration } };
            setEditConfigurationData(editConfigurationData);
            setIsFormOpen(true);
            return {};
          },
        },
        {
          label: 'Delete',
          show: ({ selectedRowIds }) =>
            selectedRowIds.length === 1 && isPermitted(ACTION_CENTER_PERMISSIONS.DELETE_CONFIGURATIONS.name),
          execute: async actionData => {
            let shouldDelete = false;
            const { selectedRowIds } = actionData;
            try {
              shouldDelete = await showConfirmationDialog({
                entitiesCount: 1,
                entityNameSingular: 'Configuration',
                entityNamePlural: 'Configurations',
              });

              if (shouldDelete) {
                await deleteConfiguration(selectedRowIds[0] as string);
                notificationService.success('Configuration deleted successfully');
              }
            } catch (e) {
              notificationService.error('Could not delete configuration. See logs for more information');
              console.error(e);
            }

            return {
              shouldGridReload: shouldDelete,
              shouldClearSelection: shouldDelete,
            };
          },
        },
      ],
    }),
    [classes.noData, configurationsMetadata, emptyStateActions, externalFilter],
  );

  return (
    <>
      <ConfigurationDialog
        isOpen={isFormOpen}
        handleClose={handleCloseForm}
        editConfigurationData={editConfigurationData}
        onSubmit={reloadGridAndClearSelection}
      />
      <div className={classes.gridWrapper}>
        <BigidPaper>
          <BigidGridWithToolbar {...config} />
        </BigidPaper>
      </div>
    </>
  );
};
