import React, { FC } from 'react';
import { CorrelationSetsRow, CorrelationSetsRowProps } from './components/CorrelationSetsRow';
import styled from '@emotion/styled';
import { ClassifierGridRow } from '../types/ClassifierTypes';
import { BigidCorrelationsIcon, BigidExternalLinkIcon } from '@bigid-ui/icons';
import { $state, $location } from '../../../../react/services/angularServices';
import { BigidButtonIcon } from '@bigid-ui/components';

const CorrelationSetsTabContainer = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

interface CorrelationSetsTabProps {
  classifier: ClassifierGridRow;
}

export const CorrelationSetsTab: FC<CorrelationSetsTabProps> = ({ classifier }) => {
  const externalLinkHandler = (name: string) => {
    $state.go('newEntityConnection', { id: name });
  };

  const sourcesToShow = classifier?.sources?.map(source => ({
    text: source,
    icon: BigidCorrelationsIcon,
    button: () => <BigidButtonIcon icon={BigidExternalLinkIcon} onClick={() => externalLinkHandler(source)} />,
  }));

  return (
    <CorrelationSetsTabContainer>
      {sourcesToShow?.map((correlationSetProp: CorrelationSetsRowProps, index) => (
        <CorrelationSetsRow key={index} {...correlationSetProp} />
      ))}
    </CorrelationSetsTabContainer>
  );
};
