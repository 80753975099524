import PerfectScrollbar from 'perfect-scrollbar';
import { IPromise, module } from 'angular';
const app = module('app');

export interface UiGridScrollbarContext {
  viewport: HTMLElement;
  gridApi: uiGrid.IGridApi;
  scrollToTop: Function; // eslint-disable-line @typescript-eslint/ban-types
  scrollToBottom: Function; // eslint-disable-line @typescript-eslint/ban-types
  scrollToOffset: (offset: number) => void;
}

interface UiGridScrollbarScope extends ng.IScope {
  scrollWheelPropagation?: boolean;
  uiGridApi: uiGrid.IGridApi;
  onContextReady: Function; // eslint-disable-line @typescript-eslint/ban-types
}

/**
 * @ngdoc directive
 * @name uiGridPerfectScroll
 * @restrict E
 * @param uiGridApi: '=',
 * @param scrollWheelPropagation: '<',
 * @param onContextReady: '&',
 */
app.directive('uiGridPerfectScroll', ($timeout: ng.ITimeoutService) => {
  'ngInject';

  const directiveDefinition: ng.IDirective<UiGridScrollbarScope> = {
    restrict: 'E',
    scope: {
      uiGridApi: '=',
      scrollWheelPropagation: '<',
      onContextReady: '&',
    },
    link: ($scope, $elem) => {
      let ps: PerfectScrollbar;
      let uiGridUnregisterHandler: Function; // eslint-disable-line @typescript-eslint/ban-types
      let timeoutId: IPromise<any>;

      const cancelTimeout = () => {
        $timeout.cancel(timeoutId);
        timeoutId = undefined;
      };

      const unregisterUiGridHandlers = () => {
        if (typeof uiGridUnregisterHandler === 'function') {
          uiGridUnregisterHandler();
          uiGridUnregisterHandler = undefined;
        }
      };

      const psConfig: PerfectScrollbar.Options = {
        wheelSpeed: 1,
        wheelPropagation: true,
      };

      if (typeof $scope.scrollWheelPropagation === 'boolean') {
        psConfig.wheelPropagation = $scope.scrollWheelPropagation;
      }

      const scrollToTop = () => {
        if (context.viewport !== null) {
          context.viewport.scrollTop = 0;
        }
      };

      // not used anywhere
      const scrollToBottom = () => {
        if (context.viewport !== null && context.gridApi !== null) {
          // manual assertion because ui-grid @types are outdated or these props not part of public API and reasons...
          const { data, columnDefs } = (context.gridApi.grid as any).options as uiGrid.IGridOptions;
          if (Array.isArray(data) && Array.isArray(columnDefs)) {
            const lastRow = data.slice(-1).pop();
            const lastCol = columnDefs.slice(-1).pop();
            context.gridApi.grid.scrollTo(lastRow, lastCol);
          }
        }
      };

      // not used anywhere
      const scrollToOffset = (offset: number) => {
        if (typeof offset === 'number' && context.viewport) {
          context.viewport.scrollTop = offset;
        }
      };

      const context: UiGridScrollbarContext = {
        viewport: null as HTMLElement,
        gridApi: null as uiGrid.IGridApi,
        scrollToTop,
        scrollToBottom,
        scrollToOffset,
      };

      $scope.$watch<uiGrid.IGridApi>('uiGridApi', api => {
        unregisterUiGridHandlers();

        if (!api) return;

        context.gridApi = api;

        uiGridUnregisterHandler = api.core.on.rowsRendered(null, () => {
          cancelTimeout();
          if (ps) return;
          timeoutId = $timeout(() => {
            timeoutId = undefined;

            context.viewport = $elem[0].parentNode.parentNode.querySelector(
              '.ui-grid-render-container-body .ui-grid-viewport',
            ) as HTMLElement;

            if (!context.viewport) return;

            context.viewport.style.overflow = 'hidden';
            psConfig.minScrollbarLength =
              Math.round(context.viewport.clientHeight / 10) > 50 ? 50 : Math.round(context.viewport.clientHeight / 10);

            ps = new PerfectScrollbar(context.viewport, psConfig);
            $scope.onContextReady({ context });
          }, 500);
          // eslint-disable-next-line @typescript-eslint/ban-types
        }) as unknown as Function; // manual assertion because outdated ui-grid API
      });

      $scope.$on('$destroy', () => {
        cancelTimeout();
        unregisterUiGridHandlers();
      });
    },
  };

  return directiveDefinition;
});
