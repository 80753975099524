import React, { ChangeEvent, useCallback, useEffect, useMemo, useState } from 'react';
import angular from 'angular';
import { Paper, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { BigidColorsV2, BigidLoader, BigidNotes1, BigidSwitch, BigidTooltip, EntityEvents } from '@bigid-ui/components';
import {
  ActionsFormatterAlignment,
  BigidGridColumn,
  BigidGridColumnTypes,
  BigidGridQueryComponents,
  BigidGridWithToolbar,
  BigidGridWithToolbarProps,
  FormatterOnClickHandlerPayloadData,
} from '@bigid-ui/grid';
import { BigidDownloadIcon, BigidHelpIcon, BigidClearIcon, BigidReportIcon } from '@bigid-ui/icons';
import classnames from 'classnames';
import { DSAR_PERMISSIONS } from '@bigid/permissions';
import { isPermitted } from '../../../services/userPermissionsService';
import { convertToAngular } from '../../../../common/services/convertToAngular';
import { dateUtils, $state, $translate } from '../../../services/angularServices';
import { showConfirmationDialog } from '../../../services/confirmationDialogService';
import { openSearchAttributesInfoDialog } from '../../../components/SearchAttributesInfo/searchAttributesInfoService';
import { notificationService } from '../../../services/notificationService';
import { useUserPreferences } from '../../../components/hooks/useUserPrefrences';
import {
  getDeletionValidationResults,
  getDeletionValidationStatus,
  getProfiles,
  sendDeletionValidationStatus,
  stopDeleteValidation,
} from '../dsarService';
import { ActivateDeletionValidationDialog } from './Dialogs/ActivateDeletionValidationDialog';
import { DeletionValidationConfigurationContent, DsarDeletionValidationEntity } from './dsarRequestsTypes';
import { getDownloadReportDialog } from './DsarDownloadReport';
import { handleDownloadDialog } from './DsarRequestsActionsDialogsHandlers';
import { getApplicationPreference } from '../../../services/appPreferencesService';

const useStyles = makeStyles({
  root: {
    width: '100%',
    height: 'inherit',
    backgroundColor: BigidColorsV2.white,
    borderRadius: '4px',
    border: '1px solid #caced4',
    display: 'flex',
    flexDirection: 'column',
  },
  paper: {
    display: 'flex',
    justifyContent: 'space-between',
    flexWrap: 'nowrap',
    boxShadow: 'none',
    padding: '20px 20px 10px',
  },
  toggleSectionWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    gap: 16,
    minWidth: 465,
  },
  toggleContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: 5,

    '& > label': {
      margin: 0,
    },
  },
  tooltipContainer: {
    display: 'flex',
    alignItems: 'center',
    minWidth: 77,
  },
  activeToggle: {
    color: BigidColorsV2.blue[300],
    fontWeight: 700,
  },
  helpIcon: {
    marginLeft: 4,
    display: 'flex',
    alignItems: 'center',
  },
});

export const DsarDeletionValidationGrid: React.FC = () => {
  const classes = useStyles();
  const [deletionValidationToggle, setDeletionValidationToggle] = useState(false);
  const [deletionValidationDaysCount, setDeletionValidationDaysCount] = useState(90);
  const [lastUpdate, setLastUpdate] = useState<string>('');
  const [profiles, setProfiles] = useState<{ _id: string }[]>([]);
  const [isActivateDeletionDialogOpen, setIsActivateDeletionDialogOpen] = useState(false);

  const fetchConfiguration = async () => {
    try {
      const {
        configuration: { days, is_enabled },
      } = await getDeletionValidationStatus();
      setDeletionValidationDaysCount(days);
      setDeletionValidationToggle(is_enabled);
    } catch (err: any) {
      notificationService.error('An error has occurred while fetching configuration');
      console.error(err);
    }
  };

  const fetchProfiles = async () => {
    try {
      const profiles = await getProfiles();
      setProfiles(profiles);
    } catch (err: any) {
      notificationService.error('An error has occurred while fetching profiles');
      console.error(err);
    }
  };

  useEffect(() => {
    const fetchProfilesAndDeletionValidationConfiguration = async () => {
      await Promise.all([fetchConfiguration(), fetchProfiles()]);
    };
    fetchProfilesAndDeletionValidationConfiguration();
  }, []);

  const TOOLTIP_TEXT =
    'Deletion Validation re-runs deletion scripts periodically to verify that BigID removes residual personal data for deleted individuals. By activating this feature you agree that BigID will preserve some encrypted personal information to enable this process.';

  const getRequestsGridColumns = useCallback((profiles: { _id: string }[]) => {
    const columns: BigidGridColumn<DsarDeletionValidationEntity>[] = [
      {
        name: 'displayName',
        title: 'Display name',
        getCellValue: row => row.displayName,
        type: BigidGridColumnTypes.TEXT,
        width: 170,
        filteringEnabled: true,
        sortingEnabled: getApplicationPreference('ENCRYPT_USER_PRIVATE_DATA_FF') === 'false',
      },
      {
        name: 'uniqueId',
        title: 'Unique ID',
        getCellValue: row => row.uniqueId,
        type: BigidGridColumnTypes.TEXT,
        width: 160,
        filteringEnabled: true,
        sortingEnabled: getApplicationPreference('ENCRYPT_USER_PRIVATE_DATA_FF') === 'false',
      },
      {
        name: 'profileName',
        title: 'Profile',
        getCellValue: row => (profiles?.find(profile => profile._id === row.profileId) ? row.profileName : ''),
        type: BigidGridColumnTypes.TEXT,
        width: 133,
        sortingEnabled: true,
        filteringEnabled: true,
      },
      {
        name: 'requestId',
        title: 'Request ID',
        formatter: {
          onClick: async ({ row }) => {
            openSearchAttributesInfoDialog(row.displayName, row.requestId);
            return {};
          },
        },
        getCellValue: ({ requestId }) => ({
          link: {
            text: requestId,
          },
        }),
        type: BigidGridColumnTypes.LINK,
        width: 200,
        sortingEnabled: true,
        filteringEnabled: true,
      },
      {
        name: 'lastScan',
        title: 'Last Validated at',
        getCellValue: row => row.lastScan,
        type: BigidGridColumnTypes.TEXT,
        width: 160,
        sortingEnabled: false,
        filteringEnabled: false,
      },
      {
        name: 'statuses_str',
        title: 'Status',
        getCellValue: row => row.statuses_str,
        type: BigidGridColumnTypes.TEXT,
        width: 160,
        sortingEnabled: true,
        filteringEnabled: false,
      },
      {
        name: 'record_count',
        title: 'Records',
        getCellValue: row => row.record_count,
        type: BigidGridColumnTypes.TEXT,
        width: 100,
        sortingEnabled: true,
        filteringEnabled: false,
      },
    ];
    columns.push({
      name: 'Actions',
      title: 'Actions',
      filteringEnabled: false,
      sortingEnabled: false,
      width: 180,
      getCellValue: row => {
        return {
          alignment: ActionsFormatterAlignment.LEFT,
          actions: [
            {
              name: 'Download',
              iconConfig: {
                icon: BigidDownloadIcon,
                isVisible: isPermitted(DSAR_PERMISSIONS.DOWNLOAD_REQUEST_REPORT.name),
              },
              execute: async ({ row }: FormatterOnClickHandlerPayloadData) => {
                getDownloadReportDialog(row.requestId, handleDownloadDialog, row.pii_record_count);
                return {
                  shouldGridReload: false,
                  row,
                };
              },
            },
            {
              name: 'Stop Validation',
              iconConfig: {
                icon: BigidClearIcon,
                isVisible: isPermitted(DSAR_PERMISSIONS.STOP_DELETION_VALIDATION.name),
              },
              execute: async ({ row }: FormatterOnClickHandlerPayloadData) => {
                const isDisableDeletionValidationConfirmed = await showConfirmationDialog({
                  entityNameSingular: 'Validation',
                  actionName: 'Stop',
                  actionButtonName: 'Stop Validation',
                  customDescription: `Are you sure you wish to disable deletion validation for requestID: ${row.requestId}?`,
                });
                if (isDisableDeletionValidationConfirmed) {
                  try {
                    await stopDeleteValidation(row.requestId);
                    notificationService.success('Deletion validation was disabled for this request');
                    return {
                      row,
                      shouldGridReload: false,
                      entityEventToEmit: EntityEvents.DELETE,
                    };
                  } catch (err: any) {
                    notificationService.error('An error has occurred during disable validation request');
                  }
                }
              },
            },
            {
              name: $translate.instant('SUMMARY_REPORT'),
              iconConfig: {
                icon: BigidReportIcon,
                isVisible: isPermitted(DSAR_PERMISSIONS.READ_PERSONAL_INFO.name),
              },
              execute: async ({ row }: FormatterOnClickHandlerPayloadData) => {
                $state.go('personalInfoReport', {
                  userId: row.requestId,
                  targetTab: 'completed-subject-requests',
                  profileId: row.profileId,
                  profileName: row.profileName,
                });
                return {
                  shouldGridReload: false,
                  row,
                };
              },
            },
          ],
        };
      },
      type: BigidGridColumnTypes.ACTIONS,
    });
    return columns;
  }, []);

  const userPrefStateName = `${$state.$current.name}.DeletionValidation`;
  const columns: BigidGridColumn<DsarDeletionValidationEntity>[] = useMemo(
    () => getRequestsGridColumns(profiles),
    [getRequestsGridColumns, profiles],
  );
  const { isReady, preferences, gridColumns, updatePreferences } = useUserPreferences({
    stateName: userPrefStateName,
    initialGridColumns: columns,
  });

  const mapGridData = (gridData: DsarDeletionValidationEntity[]): DsarDeletionValidationEntity[] => {
    const scheduledForValidationTranslation = $translate.instant(
      'ACCESS_REQUEST_MANAGEMENT:DELETION_VALIDATION:SCHEDULED_FOR_VALIDATION',
    );
    return gridData.map(row => {
      const { created_at, lastScan } = row;
      const updatedRow: DsarDeletionValidationEntity = { ...row };
      updatedRow.isScheduled = false;
      if (created_at === lastScan) {
        updatedRow.lastScan = scheduledForValidationTranslation;
        updatedRow.statuses_str = scheduledForValidationTranslation;
        updatedRow.isScheduled = true;
      } else if (!updatedRow.statuses_str) {
        updatedRow.isDeletionValidationInProgress = true;
      }
      return updatedRow;
    });
  };

  const gridWithToolbarConfig = useMemo<BigidGridWithToolbarProps<DsarDeletionValidationEntity>>(
    () => ({
      columns: gridColumns,
      showSortingControls: true,
      showFilteringControls: true,
      defaultSorting: preferences?.grid?.sort || [{ field: '_id', order: 'desc' }],
      onGridStateChange: nextGridState => updatePreferences({ gridState: { ...nextGridState, filter: undefined } }),
      entityName: 'Requests',
      fetchData: async (queryComponents: BigidGridQueryComponents) => {
        try {
          const { sarDeletionValidation, total } = await getDeletionValidationResults(queryComponents);
          const mappedGridData = mapGridData(sarDeletionValidation);
          setLastUpdate(dateUtils.formatDate(new Date()));
          return {
            data: mappedGridData,
            totalCount: total,
          };
        } catch (err) {
          const errMsg = `an error in fetch the DSAR results`;
          notificationService.error(errMsg);
          console.error(`${errMsg} ${err}`);
        }
      },
      toolbarActions: [],
    }),
    [gridColumns, mapGridData, preferences?.grid?.sort, updatePreferences],
  );

  const onDeletionValidationToggleChange = async (value: boolean) => {
    if (value) {
      setDeletionValidationToggle(true);
      setIsActivateDeletionDialogOpen(true);
    } else {
      try {
        await sendDeletionValidationStatus({ is_enabled: false });
        setIsActivateDeletionDialogOpen(false);
        setDeletionValidationToggle(false);
      } catch (err: any) {
        notificationService.error('An error has occurred while updating changes');
        console.error(err);
      }
    }
  };

  const onDeletionValidationDialogClose = () => {
    setIsActivateDeletionDialogOpen(false);
    setDeletionValidationToggle(false);
  };

  const onDeletionValidationDialogSubmit = async (durationValue: number) => {
    const configuration: DeletionValidationConfigurationContent = {
      days: durationValue,
      is_enabled: true,
    };
    try {
      await sendDeletionValidationStatus(configuration);
      setIsActivateDeletionDialogOpen(false);
      setDeletionValidationToggle(true);
    } catch (err: any) {
      notificationService.error('An error has occurred while updating changes');
      console.error(err);
    }
  };

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <Typography variant={'h6'}>Deletion Validation</Typography>
        <div className={classes.toggleSectionWrapper}>
          <div className={classes.toggleContainer}>
            <BigidSwitch
              checked={deletionValidationToggle}
              label={'Deletion Validation: '}
              onChange={(event: ChangeEvent<HTMLInputElement>, checked: boolean) =>
                onDeletionValidationToggleChange(checked)
              }
              dataAid={'DsarDeletionValidationToggle'}
            />
            <div className={classes.tooltipContainer}>
              <BigidNotes1 className={classnames(deletionValidationToggle && classes.activeToggle)}>
                {deletionValidationToggle ? 'Active' : 'Disabled'}
              </BigidNotes1>
              <BigidTooltip title={TOOLTIP_TEXT}>
                <div className={classes.helpIcon}>
                  <BigidHelpIcon />
                </div>
              </BigidTooltip>
            </div>
          </div>
          {lastUpdate && <Typography variant={'body2'}>Last Update: {lastUpdate}</Typography>}
        </div>
      </Paper>
      {!isReady && <BigidLoader />}
      {isReady && <BigidGridWithToolbar {...gridWithToolbarConfig} />}
      <ActivateDeletionValidationDialog
        isOpen={isActivateDeletionDialogOpen}
        scheduleDurationInitialValue={deletionValidationDaysCount}
        onClose={onDeletionValidationDialogClose}
        onDeletionValidationSubmit={onDeletionValidationDialogSubmit}
      />
    </div>
  );
};

angular.module('app').component('dsarDeletionValidationGrid', convertToAngular(DsarDeletionValidationGrid));
