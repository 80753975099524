import { BigidWorldMapImageSeries } from '@bigid-ui/components';
import { AggregationItemBase } from '../../../catalogDiscoveryTypes';
import { DataSourceLayoutWorldMapCategory } from './DataSourceLayoutWorldMap';
import { getDataSourcesLayoutRecordId } from '../dataSourcesLayoutUtils';
import { getObjectDetailsFromCountriesArray, getLocationName } from '../../../../../../common/amMap/ammap.service';
import { formatNumberCompact } from '../../../../../utilities/numericDataConverter';
import { getFixedT } from '../translations';

function getTooltipContent(dataSourceLocation: AggregationItemBase): string {
  const t = getFixedT('worldMap');

  return `
    <strong>${t('location')}</strong>: ${dataSourceLocation.aggItemName}
    <br/>
    <strong>${t('numOfFindings')}</strong>: ${formatNumberCompact(dataSourceLocation.findings, 1)}
    <br/>
  `;
}

export function getDataSourcesImageData(dataSourceLocations: AggregationItemBase[]): BigidWorldMapImageSeries[] {
  return dataSourceLocations.reduce((images, dataSourceLocation) => {
    if (dataSourceLocation.aggItemName) {
      const country = getObjectDetailsFromCountriesArray(getLocationName(dataSourceLocation.aggItemName));

      if (country) {
        const locationName = country.name;
        const { longitude, latitude } = country.points[0];

        if (images.find(({ id }) => id === locationName)) {
          return images.map(image => {
            if (image.id === locationName) {
              const totalFindings = image.value + dataSourceLocation.findings;

              return {
                ...image,
                value: totalFindings,
                tooltipContent: getTooltipContent(dataSourceLocation),
              };
            }

            return image;
          });
        } else {
          return [
            ...images,
            {
              id: locationName,
              category: DataSourceLayoutWorldMapCategory.DATA_SOURCE,
              name: locationName,
              value: dataSourceLocation.findings,
              data: {
                id: getDataSourcesLayoutRecordId(dataSourceLocation),
                ...dataSourceLocation,
              },
              longitude,
              latitude,
              tooltipContent: getTooltipContent(dataSourceLocation),
            },
          ];
        }
      } else {
        return images;
      }
    } else {
      return images;
    }
  }, []);
}
