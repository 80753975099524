import React, { FC, Fragment, useCallback } from 'react';
import { isUndefined } from 'lodash';
import { BigidColorsV2, BigidTooltip, BigidBody1, BigidSwitch } from '@bigid-ui/components';
import { BigidInfoIcon } from '@bigid-ui/icons';
import { CustomAppParam } from '../../views/EditCustomApp/EditCustomApp';
import { CustomAppValueInput } from './CustomAppValueInput';
import { styled } from '@mui/material';

interface SingleParamRowProps {
  key: number;
  param: CustomAppParam;
  onParamChange: (key: string, value: string) => void;
  onParamToggle?: (paramKey: string, paramValue: string, enabled: boolean) => void;
  values?: Record<string, string>;
  readOnly?: boolean;
  shouldEnableOverriding?: boolean;
}

const WrapperDiv = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  paddingTop: '16px',
});

const MandatoryParamSpan = styled('span')({
  paddingLeft: '2px',
  color: BigidColorsV2.red[600],
});

const ParamInfoWrapper = styled('div')({
  display: 'flex',
  height: '24px',
});

const ParamValueWrapper = styled('div')({
  width: '50%',
  display: 'flex',
  alignItems: 'center',
  position: 'relative',
});

const DescriptionWrapper = styled('div')({
  paddingLeft: '8px',
  display: 'flex',
  alignItems: 'center',
});

const ParamValueSwitcherWrapper = styled('div')({
  position: 'absolute',
  left: '100%',
});

const ParamNameBody = styled(BigidBody1)({
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  maxWidth: '200px',
});

export const SingleParamRow: FC<SingleParamRowProps> = ({
  key,
  param,
  shouldEnableOverriding = false,
  values: valuesBeforeAddingNewParams = {},
  readOnly = false,
  onParamToggle,
  onParamChange,
}: SingleParamRowProps) => {
  const handleInputChange = useCallback(
    (name: string, value: string) => {
      onParamChange(name, value);
    },
    [onParamChange],
  );

  const shouldGrayOutInput = (paramName: string): boolean => {
    return readOnly || (isUndefined(valuesBeforeAddingNewParams[paramName]) && !isUndefined(onParamToggle));
  };

  const onToggle = (paramKey: string, paramValue: string, enabled: boolean) => {
    if (enabled) {
      valuesBeforeAddingNewParams[paramKey] = paramValue;
    } else {
      delete valuesBeforeAddingNewParams[paramKey];
    }
    onParamToggle(paramKey, paramValue, enabled);
  };

  return (
    <Fragment key={key}>
      <WrapperDiv>
        <ParamInfoWrapper>
          <ParamNameBody title={param.friendlyName || param.name}>{param.friendlyName || param.name}</ParamNameBody>
          {param.isMandatory && <MandatoryParamSpan>*</MandatoryParamSpan>}
          <BigidTooltip title={param.description} placement={'top'}>
            <DescriptionWrapper>
              <BigidInfoIcon />
            </DescriptionWrapper>
          </BigidTooltip>
        </ParamInfoWrapper>
        <ParamValueWrapper>
          <CustomAppValueInput
            param={param}
            onChange={handleInputChange}
            paramValue={param.paramValue}
            errorMessage={param.errorMessage}
            readOnly={shouldGrayOutInput(param.name)}
          />

          {shouldEnableOverriding && (
            <ParamValueSwitcherWrapper>
              <BigidSwitch
                checked={!isUndefined(valuesBeforeAddingNewParams[param.name])}
                onChange={() => onToggle(param.name, param.value, isUndefined(valuesBeforeAddingNewParams[param.name]))}
                disabled={readOnly}
                rightLabel="override"
              />
            </ParamValueSwitcherWrapper>
          )}
        </ParamValueWrapper>
      </WrapperDiv>
    </Fragment>
  );
};
