import { BigidButtonIcon, BigidFormField, BigidFormValues } from '@bigid-ui/components';
import makeStyles from '@mui/styles/makeStyles';
import React, { useMemo, useRef } from 'react';
import { FormSection, FormSectionInitialStateProps } from '../FormSection/FormSection';
import { FormRichTextField } from '../../../../views/GeneralSettings/BusinessGlossary/BusinessGlossaryDialog/FormRichTextField';
import { DynamicRichTextSubSection, ReportTemplate } from '../Types';
import { BigidDeleteIcon } from '@bigid-ui/icons';

const useStyles = makeStyles({
  dynamicRichTextContainer: {
    position: 'relative',
  },
  deleteButton: {
    position: 'absolute',
    top: 0,
    left: '90%',
    marginLeft: '24px',
  },
});

interface DynamicRichTextProps {
  sectionWrapperName: keyof ReportTemplate;
  indexInArray: number;
  initialValues: DynamicRichTextSubSection;
  onDeleteSectionHandler?: (index: number) => void;
  sectionWrapperOnChangeHandler: (values: BigidFormValues, subSectionIndex: number) => void;
}

export const DynamicRichText: React.FC<DynamicRichTextProps> = ({
  sectionWrapperName,
  initialValues,
  indexInArray,
  sectionWrapperOnChangeHandler,
  onDeleteSectionHandler,
}) => {
  const classes = useStyles({});
  const isFirstLoad = useRef();
  const formSectionInitProps: FormSectionInitialStateProps = useMemo(() => {
    const fields: BigidFormField[] = [
      {
        render: FormRichTextField,
        name: 'content',
        isRequired: false,
        label: '',
        misc: {
          fullWidth: true,
        },
      },
    ];
    const newInitProps: FormSectionInitialStateProps = {
      fields,
      initialValues,
    };

    return newInitProps;
  }, [initialValues]);

  const dynamicRichTextOnChangeHandler = (values: BigidFormValues) => {
    sectionWrapperOnChangeHandler(values, indexInArray);
  };

  return (
    <div className={classes.dynamicRichTextContainer}>
      <FormSection
        dynamicRichTextOnChangeHandler={dynamicRichTextOnChangeHandler}
        sectionName={sectionWrapperName}
        isFirstDynamicRichTextLoad={isFirstLoad}
        {...formSectionInitProps}
      />
      <div className={classes.deleteButton}>
        <BigidButtonIcon
          icon={BigidDeleteIcon}
          onClick={() => {
            onDeleteSectionHandler(indexInArray);
          }}
          dataAid={`dsar-${sectionWrapperName}-delete-button-${indexInArray}`}
        />
      </div>
    </div>
  );
};
