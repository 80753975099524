import React, { useState, useEffect, useRef, FC } from 'react';
import {
  BigidDropdownOption,
  BigidLoader,
  BigidDropdown,
  BigidBody1,
  BigidButtonIcon,
  SecondaryButton,
  BigidTooltip,
} from '@bigid-ui/components';
import { BigidEditIcon, BigidSendIcon, BigidViewIcon } from '@bigid-ui/icons';
import { useLocalTranslation } from '../translations';
import { mapFieldValuesToCreateDsCollaboratorPayload, mapUserToBigidDropdownOption } from '../mappers/collaboration';
import { DataSourceConnectionCollaborationEmailField } from './components/DataSourceConnectionCollaborationEmailField';
import { generateDataAid } from '@bigid-ui/utils';
import styled from '@emotion/styled';
import { useSearchUsers } from '../hooks/useSearchUsers';
import { useUser } from '../hooks/useUser';
import { DataSourceCollaborator } from '../types';
import { notificationService } from '../../../../services/notificationService';
import { openEmailEditor } from '../../../../components/EmailEditor/emailEditorService';
import { EmailEditor } from '../../../../components/EmailEditor/EmailEditor';
import {
  getBrandsOptions,
  getEmailTemplatesOptions,
  SYSTEM_TEMPLATE_VALUE,
  getDsCollaborationUserPreferences,
  updateDsCollaborationUserPreferences,
  DsCollaborationUserPreferences,
} from './dataSourceCollaborationService';
import { useCreateDsCollaborator } from '../hooks/useCreateDsCollaborator';
import { useDataSourceModalContext } from '../hooks/useDataSourceModalContext';
import { DataSourcesUITrackingEvent, trackEventDataSources } from '../../DataSourcesEventTrackerUtils';
import { DataSourceConnectionCollaborationSaveModal } from './components/DataSourceConnectionCollaborationSaveModal';

export interface DataSourceCollaborationFormProps {
  collaborators: DataSourceCollaborator[];
  onInvitationSuccess: () => void;
  selectedBrand: BigidDropdownOption[];
  selectedEmailTemplate: BigidDropdownOption[];
  updateTemplateSelection: (values: BigidDropdownOption[]) => void;
  updateBrandSelection: (values: BigidDropdownOption[]) => void;
  onSaveDataSource: (dataSourceName: string) => Promise<boolean>;
  dataSourceName: string;
}

export const SelectionFieldContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 8px;
`;

export const EmailDropdownWrapper = styled.div`
  display: flex;
  padding-right: 8px;
  width: 92%;
`;

export const BrandDropdownWrapper = styled.div`
  display: flex;
  width: 100%;
  margin-bottom: 8px;
`;

export const CollaborationFormContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 75px;
`;

export const SendEmailContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  padding-top: 12px;
`;

export const SearchFieldWrapper = styled.div`
  & .MuiPaper-root {
    min-height: 36px;
    & div > div > p {
      padding-top: 6px;
    }
  }
`;

export const InviteButtonText = styled.span`
  padding-left: 4px;
`;

export const DataSourceCollaborationForm: FC<DataSourceCollaborationFormProps> = ({
  collaborators,
  onInvitationSuccess,
  selectedBrand,
  updateTemplateSelection,
  selectedEmailTemplate,
  updateBrandSelection,
  dataSourceName,
  onSaveDataSource,
}) => {
  const { t } = useLocalTranslation();
  const [emailTemplateOptions, setEmailTemplateOptions] = useState<BigidDropdownOption[]>([]);
  const [brandOptions, setBrandOptions] = useState<BigidDropdownOption[]>([]);
  const [newCollaboratorsSelected, setNewCollaboratorsSelected] = useState<BigidDropdownOption[]>([]);
  const [isSystemEmailSelected, setIsSystemEmailSelected] = useState<boolean>(false);
  const userPreferencesRef = useRef<DsCollaborationUserPreferences>({});
  const [isLoading, setIsLoading] = useState(true);
  const [isInvitationInProgress, setIsInvitationInProgress] = useState(false);
  const { dsTypeLabel, sourceId, dsType } = useDataSourceModalContext();
  const [showSaveBeforeCollaborate, setShowSaveBeforeCollaborate] = useState<boolean>(false);
  const { mutateAsync: createCollaborator } = useCreateDsCollaborator({
    mutation: {
      onError: () => notificationService.error(t('collaboration.createError')),
      onSuccess: () => notificationService.success(t('collaboration.createSuccess')),
    },
  });
  const { email } = useUser();
  const { data: users } = useSearchUsers(
    '',
    { filter: null },
    {
      query: {
        select({ data }) {
          const excluded = [email, ...(collaborators?.map(({ email }) => email) ?? [])];
          return data?.users.filter(({ name }) => !excluded.includes(name));
        },
      },
    },
  );
  const options = mapUserToBigidDropdownOption(users);

  const onEditEmailTemplateClick = () => {
    openEmailEditor({
      templateId: selectedEmailTemplate[0]?.id,
      viewOnlyMode: isSystemEmailSelected,
      onClose: async () => {
        const newEmailTemplateOptions = await getEmailTemplatesOptions();
        setEmailTemplateOptions(newEmailTemplateOptions);
      },
    });
  };

  const onUserSelectionChange = (values: BigidDropdownOption[]) => {
    setNewCollaboratorsSelected(values);
  };

  const onInviteClick = async (newDataSourceName: string = null) => {
    try {
      if (!sourceId && !newDataSourceName) {
        setShowSaveBeforeCollaborate(true);
        return;
      }

      setIsInvitationInProgress(true);
      const payload = mapFieldValuesToCreateDsCollaboratorPayload(
        { collaborators: newCollaboratorsSelected },
        dsTypeLabel,
        selectedEmailTemplate[0]?.displayValue,
        selectedBrand[0]?.value,
      );
      await createCollaborator(
        {
          dataSource: sourceId || newDataSourceName,
          data: payload,
        },
        {
          onSuccess: async () => {
            trackEventDataSources(DataSourcesUITrackingEvent.DS_COLLABORATION_INVITE_CLICK, {
              collaborators: newCollaboratorsSelected?.map(({ value }) => value) ?? [],
              dsName: sourceId || newDataSourceName,
              dsType,
              selectedEmailTemplate: selectedEmailTemplate[0]?.displayValue,
              selectedBrand: selectedBrand[0]?.value,
            });
            setNewCollaboratorsSelected([]);
            onInvitationSuccess();
          },
        },
      );
    } finally {
      setIsInvitationInProgress(false);
    }
  };

  const handleSaveDataSource = async (dataSourceName: string, action: string) => {
    try {
      const isSuccess = await onSaveDataSource?.(dataSourceName);

      if (isSuccess) {
        await onInviteClick(dataSourceName);
        setShowSaveBeforeCollaborate(false);
      }
    } catch {
      setShowSaveBeforeCollaborate(false);
    }
  };

  const onEmailTemplateSelection = (values: BigidDropdownOption[]) => {
    if (values[0]?.id) {
      const updatedPref = { ...userPreferencesRef.current, lastTemplateSelected: values[0].id };
      userPreferencesRef.current = updatedPref;
      updateDsCollaborationUserPreferences(updatedPref);
      updateTemplateSelection(values);
      setIsSystemEmailSelected(values[0]?.value === SYSTEM_TEMPLATE_VALUE);
    }
  };

  const onBrandSelection = (values: BigidDropdownOption[]) => {
    if (values[0]?.id) {
      const updatedPref = { ...userPreferencesRef.current, lastBrandSelected: values[0].id };
      userPreferencesRef.current = updatedPref;
      updateDsCollaborationUserPreferences(updatedPref);
      updateBrandSelection(values);
    }
  };

  useEffect(() => {
    const updatePreferedTemplate = async () => {
      if (userPreferencesRef.current?.lastTemplateSelected) {
        const preSelectedTemplate = emailTemplateOptions.find(
          template => template.id === userPreferencesRef.current.lastTemplateSelected,
        );
        if (preSelectedTemplate) {
          setIsSystemEmailSelected(preSelectedTemplate.value === SYSTEM_TEMPLATE_VALUE);
          updateTemplateSelection([preSelectedTemplate]);
        }
      }
    };
    const updatePreferredBrand = async () => {
      if (userPreferencesRef.current?.lastBrandSelected && brandOptions.length > 1) {
        const preSelectedBrand = brandOptions.find(brand => brand.id === userPreferencesRef.current.lastBrandSelected);
        if (preSelectedBrand) {
          updateBrandSelection([preSelectedBrand]);
        }
      }
    };
    if (emailTemplateOptions.length) {
      updatePreferedTemplate();
    }
    if (brandOptions.length > 1) {
      updatePreferredBrand();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [emailTemplateOptions, brandOptions]);

  useEffect(() => {
    const fetchEmailTemplatesData = async () => {
      const { data } = (await getDsCollaborationUserPreferences()) || {};
      userPreferencesRef.current = data;
      const newEmailTemplateOptions = await getEmailTemplatesOptions();
      const newBrandsOptions = await getBrandsOptions();
      setEmailTemplateOptions(newEmailTemplateOptions);
      if (newBrandsOptions.length > 1) {
        setBrandOptions(newBrandsOptions);
      } else {
        updateBrandSelection(newBrandsOptions);
      }
      setIsLoading(false);
    };
    fetchEmailTemplatesData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const inviteButtonIconProp = {
    ...(!isInvitationInProgress && { startIcon: <BigidSendIcon /> }),
    ...(isInvitationInProgress && { disabled: true }),
  };

  const isInvitationEnabled =
    !!selectedEmailTemplate.length && !!newCollaboratorsSelected?.length && !!selectedBrand.length;

  return (
    <CollaborationFormContainer>
      {isLoading ? (
        <BigidLoader position="relative" />
      ) : (
        <>
          <EmailEditor />
          <SelectionFieldContainer>
            <EmailDropdownWrapper>
              <BigidDropdown
                options={emailTemplateOptions}
                onSelect={onEmailTemplateSelection}
                placeholder={t('collaboration.form.fieldLabels.emailTemplatePlaceholder')}
                value={selectedEmailTemplate}
                dataAid={generateDataAid('DataSourceConnectionCollaboration', ['emailTemplates', 'dropdown'])}
              />
            </EmailDropdownWrapper>
            {!!selectedEmailTemplate.length && (
              <BigidButtonIcon
                icon={isSystemEmailSelected ? BigidViewIcon : BigidEditIcon}
                onClick={onEditEmailTemplateClick}
              />
            )}
          </SelectionFieldContainer>
          {brandOptions.length > 1 && (
            <BrandDropdownWrapper>
              <BigidDropdown
                options={brandOptions}
                onSelect={onBrandSelection}
                placeholder={t('collaboration.form.fieldLabels.brandSelectionPlaceholder')}
                value={selectedBrand}
                dataAid={generateDataAid('DataSourceConnectionCollaboration', ['brands', 'dropdown'])}
              />
            </BrandDropdownWrapper>
          )}
          <SearchFieldWrapper>
            <DataSourceConnectionCollaborationEmailField
              dataAid={generateDataAid('DataSourceConnectionCollaboration', ['field', 'collaborators'])}
              setValue={onUserSelectionChange}
              value={newCollaboratorsSelected}
              data={options}
            />
          </SearchFieldWrapper>
        </>
      )}
      <SendEmailContainer>
        <BigidBody1>{t('collaboration.sendEmailInfo')}</BigidBody1>
        <BigidTooltip
          title={
            selectedBrand[0]?.value
              ? t('collaboration.buttons.disabledInviteTooltip')
              : t('collaboration.buttons.disabledInviteTooltipWithBrands')
          }
          isDisabled={isInvitationEnabled}
          placement="top"
        >
          <div>
            <SecondaryButton
              disabled={!isInvitationEnabled}
              size="medium"
              onClick={() => onInviteClick()}
              bi={{ disabled: true }}
              {...inviteButtonIconProp}
              dataAid={generateDataAid('DataSourceConnectionCollaboration', ['action', 'invite', 'button'])}
            >
              {isInvitationInProgress && <BigidLoader color="#B6B6B6" size={20} position="relative" />}
              <InviteButtonText>{t('collaboration.buttons.sendEmail')}</InviteButtonText>
            </SecondaryButton>
          </div>
        </BigidTooltip>
      </SendEmailContainer>
      <DataSourceConnectionCollaborationSaveModal
        source={dataSourceName}
        isOpen={showSaveBeforeCollaborate}
        onClose={() => setShowSaveBeforeCollaborate(false)}
        onSave={handleSaveDataSource}
      />
    </CollaborationFormContainer>
  );
};
