import { BigidGridColumn, BigidGridColumnTypes } from '@bigid-ui/grid';
import { MetadataModel } from '../MetadataConnectionTypes';
import { formatBytes } from '../../../utilities/numericDataConverter';

type ExplorerGridColumn = BigidGridColumn<MetadataModel>[];

const baseColumns: ExplorerGridColumn = [
  {
    name: 'name',
    title: 'Data Source Name',
    getCellValue: ({ name }) => name,
    filteringEnabled: true,
    type: BigidGridColumnTypes.TEXT,
  },
  {
    name: 'created_at',
    title: 'Discovered At',
    getCellValue: ({ created_at }) => created_at,
    filteringEnabled: false,
    type: BigidGridColumnTypes.DATE,
  },
  {
    name: 'region',
    title: 'Region',
    getCellValue: ({ region }) => region,
    filteringEnabled: true,
    type: BigidGridColumnTypes.TEXT,
    width: 150,
  },
  {
    name: 'createdAt',
    title: 'Creation Date',
    getCellValue: ({ createdAt }) => createdAt,
    filteringEnabled: false,
    type: BigidGridColumnTypes.DATE,
  },
];

export const ExplorerGridDsColumns = new Map<string, ExplorerGridColumn>([
  ['base', baseColumns],
  [
    's3',
    [
      ...baseColumns,
      {
        name: 'sizeInBytes',
        title: 'Size',
        getCellValue: ({ sizeInBytes }) => (sizeInBytes || sizeInBytes === 0 ? formatBytes(sizeInBytes) : 'unknown'),
        filteringEnabled: false,
        type: BigidGridColumnTypes.NUMBER,
        width: 150,
      },
      {
        name: 'bucket_name',
        title: 'Bucket Name',
        getCellValue: ({ bucket_name }) => bucket_name,
        filteringEnabled: false,
        type: BigidGridColumnTypes.TEXT,
      },
      {
        name: 'ownerName',
        title: 'Owner',
        getCellValue: ({ ownerName }) => ownerName,
        filteringEnabled: true,
        type: BigidGridColumnTypes.TEXT,
        width: 120,
      },
      {
        name: 'lastWriteTime',
        title: 'Last Write Time',
        getCellValue: ({ lastWriteTime }) => lastWriteTime,
        filteringEnabled: true,
        type: BigidGridColumnTypes.DATE,
      },
    ],
  ],
  [
    'athena',
    [
      ...baseColumns,
      {
        name: 'lastAccessTime',
        title: 'Last Access Time',
        getCellValue: ({ lastAccessTime }) => lastAccessTime,
        filteringEnabled: true,
        type: BigidGridColumnTypes.DATE,
      },
    ],
  ],
  [
    'dynamodb',
    [
      ...baseColumns,
      {
        name: 'sizeInBytes',
        title: 'Size',
        getCellValue: ({ sizeInBytes }) => (sizeInBytes || sizeInBytes === 0 ? formatBytes(sizeInBytes) : 'unknown'),
        filteringEnabled: false,
        type: BigidGridColumnTypes.NUMBER,
        width: 150,
      },
    ],
  ],
]);
