import React, { ChangeEvent, FC, useContext } from 'react';
import { BigidSwitch, BigidTooltip } from '@bigid-ui/components';
import { CreateScanWizardContext } from '../../createScanContext';
import { BigidInfoIcon } from '@bigid-ui/icons';
import styled from '@emotion/styled';
import { generateDataAid } from '@bigid-ui/utils';

const SwitchAllEnabledDataSourcesWrapper = styled('div')`
  display: flex;
  align-items: center;
  gap: 4px;
`;

const BigidInfoIconWrapper = styled('div')`
  display: flex;
  align-items: center;
`;

export const SwitchAllEnabledDataSources: FC = () => {
  const { scanWizardState, setScanWizardState } = useContext(CreateScanWizardContext);

  const onChangeSwitch = (_event: ChangeEvent<HTMLInputElement>, checked: boolean) => {
    setScanWizardState({ ...scanWizardState, allEnabledDs: checked, dataSourcesIds: [] });
  };

  return (
    <SwitchAllEnabledDataSourcesWrapper>
      <span>All enabled data sources</span>
      <BigidTooltip title="Only data sources that fit the scan type set in the selected template (next step) will be scanned.">
        <BigidInfoIconWrapper>
          <BigidInfoIcon />
        </BigidInfoIconWrapper>
      </BigidTooltip>
      <BigidSwitch
        dataAid={generateDataAid('SwitchAllEnabledDataSources', ['switch'])}
        onChange={onChangeSwitch}
        checked={scanWizardState.allEnabledDs}
      />
    </SwitchAllEnabledDataSourcesWrapper>
  );
};
