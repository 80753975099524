import { DsTypesEnum } from '../types';
import { BigidFormRenderProps } from '@bigid-ui/components';

export const FIELD_PREFIX_IF_IT_IS_ROOT_LEVEL_CHECK = '.';

export function getFieldPropsFunc(
  type: DsTypesEnum,
  localValue: any,
  getFieldProps: BigidFormRenderProps['getFieldProps'],
) {
  return (fieldName: string) => {
    if (fieldName && fieldName.startsWith(FIELD_PREFIX_IF_IT_IS_ROOT_LEVEL_CHECK)) {
      return getFieldProps(fieldName.substring(1));
    }

    const nestedValue = { ...(localValue || {}) }[fieldName];
    return { type, value: nestedValue, misc: { type } } as any;
  };
}
