import template from './privacyCompliance.component.html';
import { module } from 'angular';
const app = module('app');

app.component('compliance', {
  template,
  controllerAs: 'complianceModel',
  controller: [
    '$http',
    'appSettings',
    '$stateParams',
    '$rootScope',
    function ($http, appSettings, $stateParams, $rootScope) {
      var complianceModel = this;

      $rootScope.$broadcast('changePage', 'Compliance', true);
    },
  ],
});
