import { BigidFormFieldTypes, ExtraFieldItemData } from '@bigid-ui/components';
import { BigidFormExtraField, ListItem } from './BigidFormExtraField';
import { BigidFormExtraCountriesField } from './BigidFormExtraCountriesField';
import { FormRichTextField } from '../../../../views/GeneralSettings/BusinessGlossary/BusinessGlossaryDialog/FormRichTextField';
import { ApiToFormMapper, FormToApiMapper } from './FormSection';
import { BigidFormFileUpload } from './BigidFormFileUpload';
import { BigidFormPaddedCheckbox } from './BigidFormPaddedCheckbox';

export const fieldsValuesToApiDefaultMapper: FormToApiMapper = ({ sectionName, isEnabled, fields, values }) => {
  const apiData: Record<string, any> = { isEnabled };
  fields.forEach(({ type, name: fieldName, render: FieldCustomRenderComponent }) => {
    if ([BigidFormFieldTypes.TEXT, BigidFormFieldTypes.TEXTAREA, BigidFormFieldTypes.CHECKBOX].includes(type)) {
      apiData[fieldName] = values[fieldName];
    }

    if (FieldCustomRenderComponent === BigidFormExtraField) {
      const fieldValue = values[fieldName];
      apiData[fieldName] = formExtraFieldItemsToApiMapper(fieldValue);
    }

    if (FieldCustomRenderComponent === FormRichTextField) {
      apiData[fieldName] = values[fieldName].markdown;
    }

    if (FieldCustomRenderComponent === BigidFormExtraCountriesField) {
      const fieldValue = values[fieldName];
      apiData[fieldName] = formExtraFieldCountriesOptionsToApiMapper(fieldValue);
    }

    if (FieldCustomRenderComponent === BigidFormFileUpload) {
      apiData[fieldName] = values[fieldName];
    }
    if (FieldCustomRenderComponent === BigidFormPaddedCheckbox) {
      apiData[fieldName] = values[fieldName];
    }
  });

  return { [sectionName]: apiData };
};

export const apiToFieldValuesMapper: ApiToFormMapper = ({ sectionName, fields, initialValues }) => {
  const fieldsValues: Record<string, any> = {};
  fields.forEach(({ type, name: fieldName, render: FieldCustomRenderComponent }) => {
    if ([BigidFormFieldTypes.TEXT, BigidFormFieldTypes.TEXTAREA, BigidFormFieldTypes.CHECKBOX].includes(type)) {
      fieldsValues[fieldName] = initialValues[fieldName];
    }
    if (FieldCustomRenderComponent === BigidFormExtraField) {
      const fieldValue = initialValues[fieldName] || [];
      fieldsValues[fieldName] = apiDataToFormExtraFieldMapper(fieldValue);
    }
    if (FieldCustomRenderComponent === FormRichTextField) {
      fieldsValues[fieldName] = {
        markdown: initialValues[fieldName],
      };
    }
    if (FieldCustomRenderComponent === BigidFormFileUpload) {
      fieldsValues[fieldName] = initialValues[fieldName];
    }

    if (FieldCustomRenderComponent === BigidFormPaddedCheckbox) {
      fieldsValues[fieldName] = initialValues[fieldName];
    }
  });
  return fieldsValues;
};

export const formExtraFieldItemsToApiMapper = (extraFieldItems: ListItem[]): string[] => {
  return extraFieldItems.filter(({ value }) => value.length).map(({ value }) => value);
};

export const apiDataToFormExtraFieldMapper = (list: string[]): ListItem[] => {
  return list.map((value, index) => {
    return { id: index.toString(), value };
  });
};
export const apiDataToFormExtraFieldRecordMapper = (entries: Record<string, string[]>): Record<string, ListItem[]> => {
  return Object.fromEntries(
    Object.entries(entries || {}).map(([key, values]) => [key, apiDataToFormExtraFieldMapper(values)]),
  );
};

export const formExtraFieldCountriesOptionsToApiMapper = (extraFieldItems: ExtraFieldItemData[]): string[] => {
  const filteredList = extraFieldItems.filter(({ value }) => value.length);
  return filteredList.flatMap(({ value }) => value.map((opt: any) => opt.value));
};

export const toBase64 = async (file: File): Promise<string> =>
  new Promise(resolve => {
    const reader = new FileReader();
    reader.onloadend = evt => {
      resolve(evt.target.result as string);
    };

    reader.onerror = () => {
      resolve(undefined);
    };
    reader.readAsDataURL(file);
  });

export const base64ToFile = (base64string: string, filename: string): Promise<File | undefined> =>
  new Promise(resolve => {
    try {
      const arr = base64string.split(',');
      const mime = arr[0].match(/:(.*?);/)[1];
      const bstr = atob(arr[1]);
      let n = bstr.length;
      const u8arr = new Uint8Array(n);

      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }
      const file = new File([u8arr], `${filename}.${extractFileExtensionFromMime(mime)}`, { type: mime });
      resolve(file);
    } catch (err) {
      console.error('base64ToFile error:', err);
      resolve(undefined);
    }
  });

const extractFileExtensionFromMime = (mime: string) => {
  const start = mime.lastIndexOf('/') + 1;
  const end = mime.lastIndexOf('+');
  return mime.substring(start, end > start ? end : mime.length);
};
