import { BigidBody1, BigidColorsV2 } from '@bigid-ui/components';
import { BigidChevronDownIcon, BigidChevronUpIcon } from '@bigid-ui/icons';
import styled from '@emotion/styled';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { generateDataAid } from '@bigid-ui/utils';
import { CaseSidePanelContext } from '../../hooks/CaseSidePanelContext';
import { useLocalTranslation } from '../../../translations';

const Description = styled(BigidBody1)<{ isCollapsed: boolean }>`
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: ${props => (props.isCollapsed ? 3 : 'unset')};
  -webkit-box-orient: vertical;
  margin-bottom: 6px;
`;

const TextButton = styled(BigidBody1)`
  cursor: pointer;
  display: flex;
  align-items: center;
`;

export const PolicyDescription = () => {
  const [isCollapsed, setIsCollapsed] = useState(true);
  const textRef = useRef(null);
  const [isTextOverflowing, setIsTextOverflowing] = useState(false);
  const { t } = useLocalTranslation('CaseReport');
  const { caseSidePanelData } = useContext(CaseSidePanelContext);

  useEffect(() => {
    const textElement = textRef.current;

    setIsTextOverflowing(textElement.scrollHeight > textElement.clientHeight);
  }, [caseSidePanelData.policyDescription]);

  const toggleCollapse = () => {
    setIsCollapsed(!isCollapsed);
  };

  return (
    <div data-aid={generateDataAid('PolicyDescription', ['main-container'])}>
      <BigidBody1 color={BigidColorsV2.gray[500]} marginBottom={0.5} display="block">
        {t('policyDescription')}
      </BigidBody1>
      <Description data-aid={generateDataAid('PolicyDescription', ['content'])} ref={textRef} isCollapsed={isCollapsed}>
        {caseSidePanelData.policyDescription}
      </Description>
      {isTextOverflowing && (
        <>
          <TextButton onClick={toggleCollapse} data-aid={generateDataAid('PolicyDescription', ['toggle-btn'])}>
            {isCollapsed ? (
              <>
                {t('showMore')}
                <BigidChevronDownIcon />
              </>
            ) : (
              <>
                {t('showLess')}
                <BigidChevronUpIcon />
              </>
            )}
          </TextButton>
        </>
      )}
    </div>
  );
};
