import React, { FC, Fragment, ReactNode, Suspense, useEffect, useState } from 'react';
import {
  BigidChip,
  BigidColors,
  BigidLoader,
  BigidSideFilterSectionBase,
  BigidSideFilterSectionItemBase,
  ObjectToFilterSectionItemsMapping,
  objectToQueryString,
  QueryParams,
  entityEventsEmitter,
  EntityEvents,
  BigidFieldFilter,
} from '@bigid-ui/components';
import { BigidGridColumnTypes, BigidGridProps, ChipsFormatterProps, FetchDataFunction } from '@bigid-ui/grid';
import {
  LayoutSideFilterProps,
  BigidMasterDetailsContentProps,
  BigidLayout,
  BigidLayoutConfig,
  parseFieldFiltersToSearchQuery,
  LayoutContentType,
} from '@bigid-ui/layout';
import { Cluster, clusteringService } from './clusteringService';
import { pageHeaderService } from '../../../common/services/pageHeaderService';
import { BubbleChart } from '@mui/icons-material';
import { ClusterDetails } from './ClusterDetails';
import { ClusterObjects } from './ClusterObjects';
import makeStyles from '@mui/styles/makeStyles';
import { isPermitted } from '../../services/userPermissionsService';
import { CLUSTER_ANALYSIS_PERMISSIONS } from '@bigid/permissions';
import { $state, $stateParams } from '../../services/angularServices';

const CONTAINS_PI = 'Contains PI';
const CONTAINS_DUPLICATES = 'Has Duplicates';

export interface ClusterExtended extends Cluster {
  tags?: ReactNode[];
  attributeChips?: ChipsFormatterProps;
}

export interface DataSource {
  id: string;
  name: string;
}

export interface Attribute {
  id: string;
  name: string;
}

const useStyles = makeStyles({
  contentContainer: {
    display: 'flex',
    overflow: 'hidden',
    flexFlow: 'column nowrap',
    flex: '1 1 auto',
  },
});

const MAX_SIZE = '50';

const ClusteringVisualization = React.lazy(
  () => import(/* webpackChunkName: "ClusteringVisualization" */ './ClusteringVisualization'),
);

export const Clustering: FC = () => {
  const classes = useStyles({});
  const { filter } = $stateParams;
  const [selectedTabIndex, setSelectedTabIndex] = useState<number>(
    ['overview', 'objects'].indexOf($state.params.selectedTab) || 0,
  );

  const prepareGridData = (clusters: Cluster[]) => {
    return clusters.map(cluster => {
      const { dataSources, cluster_id } = cluster;
      const tags = [];
      if (cluster.attributes.length > 0) {
        tags.push([<BigidChip bgColor={BigidColors.red[100]} size="small" label={CONTAINS_PI} key={cluster_id} />]);
      }

      if (cluster.duplicates > 0) {
        tags.push([
          <BigidChip bgColor={BigidColors.red[100]} size="small" label={CONTAINS_DUPLICATES} key={cluster_id} />,
        ]);
      }
      const attributeChips: ChipsFormatterProps = {
        chips: {
          value: cluster.attributes
            ? cluster.attributes.map(attr => ({
                label: attr,
              }))
            : [],
          isDisabled: true,
        },
      };
      return {
        ...cluster,
        dataSourcesToDisplay: dataSources ? dataSources.join(', ') : '',
        id: cluster_id,
        tags,
        attributeChips,
      };
    });
  };

  useEffect(() => {
    const handleUpdateClusterName = async (clusterId: string, updatedFields: { [field: string]: any }) => {
      try {
        const { description } = await clusteringService.getClusterById(clusterId);
        await clusteringService.updateCluster({ name: updatedFields.name, description }, clusterId);
        entityEventsEmitter.emit(EntityEvents.UPDATE_BY_ID, clusterId, { name: updatedFields.name });
      } catch (err) {
        window.console.error('An error has occurred while trying to update cluster');
      }
    };

    const unregisterUpdateEvent = entityEventsEmitter.addEventListener(
      EntityEvents.ENTITY_UPDATED,
      handleUpdateClusterName,
    );

    return function cleanup() {
      unregisterUpdateEvent();
    };
  }, []);

  const ClusteringVisualizationComponent = () => (
    <Suspense fallback={<BigidLoader />}>
      <ClusteringVisualization />
    </Suspense>
  );

  const masterDetailsConfig: BigidMasterDetailsContentProps = {
    tabsAndContent: {
      classes: {
        contentContainer: classes.contentContainer,
      },
      tabProps: {
        tabs: [
          {
            label: 'Overview',
            data: { component: ClusterDetails },
          },
          {
            label: 'Objects',
            data: { component: ClusterObjects },
          },
        ],
        onChange: tabIndex => {
          $state.go(
            '.',
            { clusterId: null, selectedTab: ['overview', 'objects'][tabIndex] },
            { reload: false, notify: false, inherit: true },
          );
          setSelectedTabIndex(tabIndex);
        },
        selectedIndex: selectedTabIndex,
      },
    },
    isEditableHeader: isPermitted(CLUSTER_ANALYSIS_PERMISSIONS.EDIT.name),
  };

  const sideFilterConfig: LayoutSideFilterProps = {
    isSideFilterOpen: true,
    sectionsConfig: [
      {
        id: 'Quick Filter',
        name: 'contains_pi',
        displayName: 'Quick Filter',
        items: [
          {
            id: 'contains_pi',
            name: 'Contains PI',
            value: 'true',
            isSelected: filter?.sideFilterSelection?.find(({ field }: BigidFieldFilter) => field === 'contains_pi')
              ?.value,
            operator: 'equal',
          },
        ] as BigidSideFilterSectionItemBase[],
      },
      {
        id: 'Duplicates',
        name: 'contains_duplicate',
        displayName: 'Duplicates',
        items: [
          {
            id: 'contains_duplicate',
            name: 'Has Duplicates',
            value: 'true',
            isSelected: filter?.sideFilterSelection?.find(
              ({ field }: BigidFieldFilter) => field === 'contains_duplicate',
            )?.value,
            operator: 'equal',
          },
        ] as BigidSideFilterSectionItemBase[],
      },
      {
        id: 'Cluster size',
        name: 'size',
        displayName: 'Cluster size',
        items: [
          {
            id: '1',
            name: 'Top 20',
            value: '20',
            isSelected: true,
            operator: 'equal',
          },
          {
            id: '2',
            name: 'Top 50',
            value: MAX_SIZE,
            operator: 'equal',
          },
        ] as BigidSideFilterSectionItemBase[],
      },
      {
        id: 'Data Sources',
        name: 'system',
        displayName: 'Data Sources',
        asyncSource: clusteringService.getDataSources,
        asyncSourceEntityMapping: {
          id: 'id',
          name: 'name',
          value: 'name',
        } as ObjectToFilterSectionItemsMapping,
      },
      {
        id: 'Attributes',
        name: 'field',
        displayName: 'Attributes',
        asyncSource: clusteringService.getClustersAttributes,
        asyncSourceEntityMapping: {
          id: 'id',
          name: 'id',
          value: 'id',
        } as ObjectToFilterSectionItemsMapping,
      },
    ] as BigidSideFilterSectionBase[],
  };

  //NOTE: this function will be deleted while the API will be ready, it's just a quick fix for now
  const getTotalCount = (filter: string, totalClusters: number) => {
    if (filter.includes('size')) {
      const filterArr = filter.split('AND');
      const sizeItem = filterArr.find(f => f.includes('size'));
      const sizeItemVal = sizeItem?.split(' = ')[1];
      //min between added for the case that we have less clusters then the size filter.
      return Math.min(sizeItemVal ? Number(sizeItemVal) : Number(MAX_SIZE), totalClusters);
    }
  };

  const fetchGridData: FetchDataFunction<Cluster> = async queryComponents => {
    const filter = parseFieldFiltersToSearchQuery(queryComponents.filter);
    const query = objectToQueryString({
      ...(queryComponents as QueryParams),
      filter,
    });
    const { clusters: clustersData, estimatedCount } = await clusteringService.getClusters(
      query.length > 0 ? `&${query}` : '',
    );
    const clusters = prepareGridData(clustersData);
    const total = getTotalCount(filter, clusters.length);

    return {
      totalCount: total || estimatedCount,
      data: clusters,
    };
  };

  const gridConfig: BigidGridProps<ClusterExtended> = {
    showSortingControls: false,
    columns: [
      {
        title: 'Name',
        name: 'name',
        width: 400,
        isListColumn: true,
        type: BigidGridColumnTypes.TEXT,
        getCellValue: ({ name }) => name,
      },
      {
        title: 'Number of objects',
        name: 'size',
        width: 185,
        type: BigidGridColumnTypes.TEXT,
        getCellValue: ({ size }) => size,
      },
      {
        title: 'Number of duplicates',
        name: 'duplicates',
        width: 200,
        type: BigidGridColumnTypes.TEXT,
        getCellValue: ({ duplicates }) => duplicates,
      },
      {
        title: 'Keywords',
        name: 'keywords',
        width: 400,
        type: BigidGridColumnTypes.TEXT,
        getCellValue: ({ keywords }) => keywords.join(', '),
      },
      {
        title: 'Data Sources',
        name: 'dataSourcesToDisplay',
        type: BigidGridColumnTypes.TEXT,
        getCellValue: ({ dataSourcesToDisplay }) => dataSourcesToDisplay,
      },
      {
        title: 'Attributes',
        name: 'attributeChips',
        type: BigidGridColumnTypes.CHIPS,
        width: 400,
        getCellValue: ({ attributeChips }) => attributeChips,
        // TODO: find out what's that suppose to do?
        // typeProvider: {
        //   isEditable: false,
        //   providerProps: {
        //     isDisabled: true,
        //   },
        // },
      },
    ],
  };

  const customContentConfig = {
    component: ClusteringVisualizationComponent,
    customViewIcon: BubbleChart,
  };

  const layoutConfig = {
    filter: {
      hasInitialFilter: true,
      sideFilter: sideFilterConfig,
    },
    content: {
      entityName: 'clusters',
      contentTypes: [LayoutContentType.CUSTOM, LayoutContentType.MASTER_DETAILS],
      defaultContentType: LayoutContentType.CUSTOM,
      viewConfig: {
        fetchGridData,
        gridConfig,
        masterDetailsConfig,
        selectedItemPropsMapping: {
          id: 'id',
          name: 'name',
          tags: 'tags',
        },
        customContentConfig,
      },
    },
  } as BigidLayoutConfig;

  useEffect(() => {
    pageHeaderService.setTitle({ pageTitle: 'Cluster Analysis' });
  }, []);

  return (
    <Fragment>
      <BigidLayout config={layoutConfig} />
    </Fragment>
  );
};
