import { httpService } from '../services/httpService';
import { DateISO8601 } from '../types/types';
import { ChecksumValidation } from '../components/ClassifiersChecksumValidation/ClassifiersChecksumValidationDialog/ChecksumValidationTypes';
import {
  ClassifierPerformanceRequestPayload,
  ClassifierPerformanceResponse,
} from '../components/ClassifierTester/ClassifierTesterTypes';

export enum ClassifierVersionState {
  NEW = 'New',
  NEEDS_UPDATE = 'NeedsUpdate',
  UP_TO_DATE = 'UpToDate',
  DOWNGRADE_AVAILABLE = 'DowngradeAvailable',
}

export type ClassifierVersion = string;

export interface Classifier {
  _id: string;
  classification_name: string;
  classification_regex: string;
  description: string;
  enabled: boolean;
  max_length: number;
  min_length: number;
  proximity_after: number;
  proximity_before: number;
  support_term_value: string;
  validation: string;
  selected?: boolean;
  isOutOfTheBox?: boolean;
  version: ClassifierVersion;
  versionState: ClassifierVersionState;
  dateUpdated: DateISO8601;
}

export enum ClassifierAdvancedType {
  DOC = 'DOC',
  NER = 'NER',
}

export interface ClassifierNer {
  _id: string;
  enabled: boolean;
  modelName: string;
  nerEntityMap: string;
  type: ClassifierAdvancedType.NER;
  updated_at: DateISO8601;
}

export interface ClassifierDoc {
  _id: string;
  enabled: boolean;
  modelId: string;
  nerEntityName: string;
  type: ClassifierAdvancedType.DOC;
  updated_at: DateISO8601;
}

export interface ClassifierVersionToUpdateConfig {
  version: ClassifierVersion;
  regex: string;
  term: string;
  type?: ClassifierAdvancedType;
  minLength: number;
  maxLength: number;
  proximityBefore: number;
  proximityAfter: number;
  description: string;
  validation: string;
}

export interface ClassifierUpdateVersion {
  name: string;
  type?: ClassifierAdvancedType;
  version: ClassifierVersion;
}

export type ClassifierUpdateVersionPayload = {
  versionToUpdate: ClassifierVersion;
};

export type ClassifierUpdateVersionResult = {
  version: ClassifierVersion;
  versionState: ClassifierVersionState;
};

export type ClassifierUpdateVersionResponse = { result: ClassifierUpdateVersionResult };

export type ClassifierVersionUpdateResponse = {
  current: ClassifierVersionToUpdateConfig;
  new: ClassifierVersionToUpdateConfig;
};

export type ClassifierUpdateVersionBulkPayload = {
  updates: ClassifierUpdateVersion[];
};

export type ClassifierUpdateVersionBulkStatus = [string, ClassifierUpdateVersionResult] | [string];

export type ClassifierUpdateVersionBulkResponse = ClassifierUpdateVersionBulkStatus[];

export type ClassifierChecksumValidationUpdateResponse = {
  modifiedCount: number;
};

export type ClassifierChecksumValidationCreateResponse = {
  id: number;
};

export const isClassifierBulkVersionUpdateSucceeded = (updateResult: ClassifierUpdateVersionBulkStatus): boolean => {
  return updateResult.length === 2 && 'version' in updateResult[1] && 'versionState' in updateResult[1];
};

export const getClassifiers = () => {
  return httpService.fetch<Classifier[]>(`classifications`).then(({ data }) => data);
};

export const getClassifierVersion = (classifierName: string) => {
  return httpService
    .fetch<ClassifierVersionUpdateResponse>(`classifications/version/${classifierName}`)
    .then(({ data }) => data);
};

export const updateClassifierVersion = (classifierName: string, versionToUpdate: ClassifierUpdateVersionPayload) => {
  return httpService
    .put<ClassifierUpdateVersionResponse, ClassifierUpdateVersionPayload>(
      `classifications/version/${classifierName}`,
      versionToUpdate,
    )
    .then(({ data }) => data.result);
};

export const updateClassifiersVersionBulk = (versionsToUpdate: ClassifierUpdateVersionBulkPayload) => {
  return httpService
    .put<ClassifierUpdateVersionBulkResponse, ClassifierUpdateVersionBulkPayload>(
      `classifications/version`,
      versionsToUpdate,
    )
    .then(({ data }) => data);
};

export const updateClassifiersChecksumValidation = (checksumValidationToUpdate: ChecksumValidation) => {
  return httpService
    .put<ClassifierChecksumValidationUpdateResponse, ChecksumValidation>(
      `checksum-validation`,
      checksumValidationToUpdate,
    )
    .then(({ data }) => data);
};

export const createClassifiersChecksumValidation = (checksumValidationToCreate: ChecksumValidation) => {
  return httpService
    .post<ClassifierChecksumValidationCreateResponse, ChecksumValidation>(
      `checksum-validation`,
      checksumValidationToCreate,
    )
    .then(({ data }) => data);
};

export const getClassifierPerformance = (classifierPerformancePayload: ClassifierPerformanceRequestPayload) => {
  return httpService
    .post<{ data: ClassifierPerformanceResponse[] }, ClassifierPerformanceRequestPayload>(
      `classifications-tester/performance`,
      classifierPerformancePayload,
    )
    .then(({ data }) => data.data[0]);
};
