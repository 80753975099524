import angular from 'angular';
import React, { FC, useState, useCallback, useEffect } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { convertToAngular } from '../../../../common/services/convertToAngular';
import { BigidTabs } from '@bigid-ui/components';
import { AccountLicense } from './AccountLicense/AccountLicense';
import { ApplicationLicenses } from './ApplicationLicenses/ApplicationLicenses';
import { licenseService } from '../../../services/licenseService';
import { useIsSmallIdLicense } from '../../../components/hooks/useIsSmallIdLicense';
import { showQuotaMessagesIfNeededAndReturnSummary } from '../../../services/meteringService';
import { BigidApplicationsIcon, BigidKeyIcon, BigidLockIcon } from '@bigid-ui/icons';

const ROOT_MARGIN = 12;

const useStyles = makeStyles({
  root: {
    margin: ROOT_MARGIN,
    display: 'flex',
    height: `calc(100% - ${ROOT_MARGIN * 2}px)`,
  },
  contentWrapper: {
    marginLeft: 12,
    boxShadow: '0px 0px 6px #00000029',
    flex: '1 1 auto',
    display: 'flex',
    overflow: 'scroll',
    flexFlow: 'column nowrap',
    paddingBottom: 12,
  },
  tabWrapper: {
    width: 175,
  },
});

const tabComponents = [AccountLicense, ApplicationLicenses];

export const Licenses: FC = () => {
  const classes = useStyles({});

  const [selectedTab, setSelectedTab] = useState(0);
  const TabContent = tabComponents[selectedTab];
  const isSmallId = useIsSmallIdLicense();
  const tabs = [
    { label: 'Account license', icon: { Component: BigidKeyIcon } },
    {
      label: 'Application Licenses',
      icon: {
        Component: isSmallId ? BigidLockIcon : BigidApplicationsIcon,
      },
    },
  ];
  useEffect(() => {
    showQuotaMessagesIfNeededAndReturnSummary();
  }, []);

  const onChangeTab = useCallback(
    (tabIndex: number) => {
      if (isSmallId && tabIndex === 1) {
        licenseService.openLicenseUpgradeFlow();
      } else {
        setSelectedTab(tabIndex);
      }
    },
    [isSmallId],
  );

  return (
    <div className={classes.root}>
      <div className={classes.tabWrapper}>
        <BigidTabs tabs={tabs} orientation="vertical" selectedIndex={selectedTab} onChange={onChangeTab} />
      </div>
      <div className={classes.contentWrapper}>
        <TabContent />
      </div>
    </div>
  );
};

angular.module('app').component('licenses', convertToAngular(Licenses));
