import { BigidSelectOption } from '@bigid-ui/components';
import { SystemAttribute } from '../../../../../DataCatalog/DataCatalogService';
import { AdditionalAttribute, CuratedAttributeType } from '../../../../curationService';

type IsValidNewOptionFunctionProps = {
  inputValue: string;
  options: BigidSelectOption[];
};

export function getIsNewAttributeUnique({ inputValue, options }: IsValidNewOptionFunctionProps): boolean {
  return options.filter(({ label }) => label.toLowerCase().trim() === inputValue.toLowerCase().trim()).length <= 0;
}

export function mapSystemAttributeToListItem(systemAttribute: SystemAttribute): BigidSelectOption {
  const { attribute_id, attribute_name, attribute_original_name, attribute_type, attribute_original_type } =
    systemAttribute;

  return {
    id: attribute_id,
    value: systemAttribute,
    label: attribute_name,
    subLabel: `${attribute_original_name} - ${attribute_original_type || attribute_type}`,
  };
}

export function mapSystemAttributeToAdditionalAttribute(systemAttribute: SystemAttribute): AdditionalAttribute {
  const { attribute_id, attribute_name, attribute_original_name, attribute_original_type, attribute_type } =
    systemAttribute;

  return {
    attributeId: String(attribute_id),
    attributeName: attribute_original_name || attribute_name,
    attributeType: (attribute_original_type || attribute_type) as CuratedAttributeType,
    findings: null,
  };
}
