import { generateDataAid } from '@bigid-ui/utils';
import { styled } from '@mui/material';
import React, { FC } from 'react';
import { FollowedItems } from './FollowedItems';
import { QuickLinks } from './QuickLinks';
import { RecentlyDiscovered } from './RecentlyDiscovered';
import { RecentlyViewed } from './RecentlyViewed';

const Container = styled('div')`
  display: flex;
  padding: 24px;
  width: 100%;
  background-color: ${({ theme }) => theme.palette.background.default};
  justify-content: center;
  border-top: 1px solid ${({ theme }) => theme.palette.divider};
`;

const Separator = styled('hr')`
  width: 1px;
  margin: 0;
  height: 100%;
  background-color: ${({ theme }) => theme.palette.divider};
`;

type BottomContentProps = {
  dataAid?: string;
};

export const BottomContent: FC<BottomContentProps> = ({ dataAid = 'BottomContent' }) => {
  return (
    <Container data-aid={generateDataAid(dataAid, ['container'])}>
      <QuickLinks />
      <Separator />
      <RecentlyDiscovered />
      <Separator />
      <FollowedItems />
      <Separator />
      <RecentlyViewed />
    </Container>
  );
};
