import { getFixedT } from './translations';
import {
  BigidGridQueryComponents,
  BigidGridRow,
  BigidGridWithToolbarProps,
  ChipFormatterProps,
  LinkFormatterProps,
} from '@bigid-ui/grid';
import { notificationService } from '../../services/notificationService';
import { httpService } from '../../services/httpService';
import { queryService } from '../../services/queryService';
import {
  BigidFieldFilterOperator,
  BigidFilterType,
  ToolbarAction,
  BigidColorsV2,
  entityEventsEmitter,
  EntityEvents,
  ToolbarActionType,
  ActionData,
  BigidFilter,
  BigidStatusBadgeType,
} from '@bigid-ui/components';
import { getCloudDataSourceLabel } from '../Fmsd/FmsdDiscover/fmsdDiscoverServices';
import { BigidAddUserIcon, BigidApplyIcon, BigidApprovedCaseIcon, BigidHideIcon, BigidViewIcon } from '@bigid-ui/icons';
import {
  ActionsDialogTypes,
  OpenModalWithData,
} from './ActionableInsightsGridViews/CaseActionsModal/hooks/useCaseActions';
import { ActionableInsightsTrackingEvents, trackActionableInsightsEvent } from './actionableInsightsTrackingUtil';
import { updateCaseStatus } from './ActionableInsightsGridViews/CaseActionsModal/caseActionsService';
import { ConfigurationsMetadata } from '../ActionCenter/ConfigurationManagement/configurationManagementTypes';
import { isPermitted } from '../../services/userPermissionsService';
import { ACTION_CENTER_PERMISSIONS } from '@bigid/permissions';
import {
  getConfigurationsMetadata,
  getConfigurations,
} from '../ActionCenter/ConfigurationManagement/configurationManagementService';
import { getSensitivityClassificationsWithNoPagination } from '../SensitivityClassification/SensitivityClassificationService';
import { getApplicationPreference } from '../../services/appPreferencesService';
import { CaseState, CaseSeverityLevel, Filters } from '../../types/actionableInsights';
import { memoize } from 'lodash';
import { ActionableInsightsReducerAction, ReducerActions } from './hooks/ActionableInsightsReducer';

export enum CaseStatus {
  OPEN = 'open',
  ACKNOWLEDGED = 'acknowledged',
  SILENCED = 'silenced',
  REMEDIATED = 'remediated',
}

export enum ViewType {
  FLAT = 'flat',
  GROUPED = 'grouped',
}

export interface ActionableInsightsTab {
  label: string;
  data: CaseState;
}

export type ActionableInsightsTabs = ActionableInsightsTab[];

export enum CasesFilterFields {
  CASE_STATUS = 'caseStatus',
  ID = '_id',
}

export interface JiraConfiguration {
  id: string;
  name: string;
  type?: string;
  isOffline?: boolean;
}

export interface JiraTicketMetadata {
  id: string;
  key: string;
  self: string;
  hostUrl: string;
  statuscategorychangedate: string;
  jiraStatusName: string;
  changelog: Record<string, any>[];
  configurationId: string;
  ticketType?: string;
}

export interface PolicyModel extends BigidGridRow {
  policyName: string;
  policyLastTriggered: string;
  numOfCases?: number;
  severityLevel: CaseSeverityLevel;
  domain?: string;
  compliance?: string;
  policyDescription?: string;
  cases: CaseModel[];
}

export interface CaseModel extends BigidGridRow {
  _id?: string;
  id: string;
  caseStatus?: CaseStatus;
  caseLabel?: string;
  policyLastTriggered?: string;
  caseType?: string;
  dataSourceName?: string;
  dataSourceType?: string;
  dataSourceOwner?: string;
  assignee?: string;
  policyName?: string;
  severityLevel?: CaseSeverityLevel;
  policyOwner?: string;
  policyType?: string;
  compliance?: string;
  numberOfAffectedObjects?: number;
  policyDescription?: string;
  created_at?: string;
  updated_at?: string;
  ticketUrl?: string;
  ticketMetadata?: JiraTicketMetadata;
}

export interface GroupedCasesResponse {
  policies: PolicyModel[];
  totalCount: number;
}

export interface AllCasesResponse {
  cases: CaseModel[];
  totalCount: number;
}

export type CasesMetadata = {
  policyName: CaseMetaDataEntry[];
  compliance: CaseMetaDataEntry[];
  assignee: CaseMetaDataEntry[];
  dataSourceOwner: CaseMetaDataEntry[];
  dataSourceName: CaseMetaDataEntry[];
  dataSourceType: CaseMetaDataEntry[];
  caseStatus: CaseMetaDataEntry[];
};

export interface CaseMetaDataEntry {
  value: string;
  totalCount: number;
}

export type CaseMetadataKey = keyof CasesMetadata;

export interface CasesMetadataResponse {
  data: CasesMetadata;
}

export type CaseActionCallback = () => void;

const METADATA_FILTERS: (keyof CasesMetadata)[] = [
  'assignee',
  'compliance',
  'dataSourceName',
  'dataSourceOwner',
  'dataSourceType',
  'policyName',
];

const enum TicketServiceTypes {
  JIRA = 'Jira',
  SERVICE_NOW = 'ServiceNow',
}

interface SyncJiraTicketProperties {
  configurationId: string;
  ticketId: string;
}

interface SyncServiceNowTicketProperties {
  sys_id: string;
}

type SyncTicketProperties = SyncJiraTicketProperties | SyncServiceNowTicketProperties;

export const STORED_SERVICE_NAME = 'SelectedObject';

export const ACTIONABLE_INSIGHTS_PREFERENCE_IDENTIFIER_PREFIX = 'actionableInsightsCases';
export const JIRA_CONFIGURATION_NAME = 'jira';
export const JIRA_TICKET_CREATION_ERROR_FIELD_VALUE = 'Creation Failed';

const tCaseErrorMessages = getFixedT('Case.common.errors');
const tActionErrorMessages = getFixedT('Action.common.errors');
const tFields = getFixedT('Case.fields');
const tSeverity = getFixedT('Case.severity');
const tTabsLabel = getFixedT('Tabs.label');
const tNotificationMessages = getFixedT('Action.common.notifications');

const delay = (ms: number) => new Promise(res => setTimeout(res, ms));

export const fetchFiltersMetadata = async () => {
  try {
    const {
      data: { data },
    } = await httpService.fetch<CasesMetadataResponse>('actionable-insights/cases-metadata');
    if (!data?.caseStatus) throw new Error(tCaseErrorMessages('fetchingCasesStatus'));
    return data;
  } catch (e) {
    console.error(e);
    notificationService.error(e?.message || tCaseErrorMessages('fetchingCasesData'));
  }
};

export const generateFiltersFromMetadata = (filtersMetadata: CasesMetadata) => {
  const activeFilters = METADATA_FILTERS.filter(
    metadataFilter => filtersMetadata[metadataFilter] && filtersMetadata[metadataFilter].length,
  );
  return [
    ...activeFilters.map(filterName => {
      return {
        title: tFields(filterName),
        field: filterName,
        operator: 'in',
        isSelected: true,
        disabled: true,
        value: [],
        options: filtersMetadata[filterName].map(uniqueOption => {
          return {
            label: filterName === 'dataSourceType' ? getCloudDataSourceLabel(uniqueOption.value) : uniqueOption.value,
            value: uniqueOption.value,
            isSelected: false,
          };
        }),
      };
    }),
    {
      title: tFields('severityLevel'),
      field: 'severityLevel',
      operator: 'in',
      isSelected: true,
      disabled: true,
      value: [],
      options: [
        {
          label: tSeverity(CaseSeverityLevel.CRITICAL),
          value: CaseSeverityLevel.CRITICAL,
          isSelected: false,
        },
        {
          label: tSeverity(CaseSeverityLevel.HIGH),
          value: CaseSeverityLevel.HIGH,
          isSelected: false,
        },
        {
          label: tSeverity(CaseSeverityLevel.MEDIUM),
          value: CaseSeverityLevel.MEDIUM,
          isSelected: false,
        },
        {
          label: tSeverity(CaseSeverityLevel.LOW),
          value: CaseSeverityLevel.LOW,
          isSelected: false,
        },
      ],
    } as BigidFilterType,
  ];
};

export const getFilterConfig = async () => {
  const filtersMetadata = await fetchFiltersMetadata();
  const filters = generateFiltersFromMetadata(filtersMetadata) as BigidFilterType[];
  const filterConfig: BigidGridWithToolbarProps<PolicyModel>['filterToolbarConfig'] = {
    filters,
    searchConfig: {
      searchFilterKeys: ['caseLabel'],
      operator: 'contains',
      placeholder: tFields('searchByPlaceholder'),
    },
  };
  return filterConfig;
};

export const updateQueryWithStatusFilter = (queryComponents: BigidGridQueryComponents, caseStatus: CaseState) => {
  const isFilterQueryHasStatusFilter = queryComponents.filter.filter(
    obj => obj.field === CasesFilterFields.CASE_STATUS,
  ).length;

  if (!isFilterQueryHasStatusFilter) {
    queryComponents.filter.push({ field: CasesFilterFields.CASE_STATUS, value: caseStatus, operator: 'equal' });
  }

  return queryComponents;
};

export const fetchGroupedCasesData = async (queryComponents: BigidGridQueryComponents, status: CaseState) => {
  try {
    queryComponents = updateQueryWithStatusFilter(queryComponents, status);
    const gridConfigQuery = queryService.getGridConfigQuery(queryComponents);
    const {
      data: {
        data: { policies, totalCount },
      },
    } = await httpService.fetch<{ data: GroupedCasesResponse }>(
      `actionable-insights/cases-group-by-policy?${gridConfigQuery}`,
    );
    return {
      data: policies,
      totalCount,
    };
  } catch (e) {
    console.error(e);
    notificationService.error(tCaseErrorMessages('fetchingCasesData'));
  }
};

export const fetchAllCasesData = async (queryComponents: BigidGridQueryComponents, status?: CaseState) => {
  try {
    if (status) {
      queryComponents = updateQueryWithStatusFilter(queryComponents, status);
    }
    const gridConfigQuery = queryService.getGridConfigQuery(queryComponents);
    const {
      data: {
        data: { cases, totalCount },
      },
    } = await httpService.fetch<{ data: AllCasesResponse }>(`actionable-insights/all-cases?${gridConfigQuery}`);
    return {
      data: cases,
      totalCount,
    };
  } catch (e) {
    console.error(e);
    notificationService.error(tCaseErrorMessages('fetchingCasesData'));
  }
};

export const getCaseData = async (caseId: string) => {
  try {
    const queryComp = {
      filter: [{ field: CasesFilterFields.ID, value: caseId, operator: 'equal' as BigidFieldFilterOperator }],
    };
    const { data } = await fetchAllCasesData(queryComp);
    return data;
  } catch (e) {
    console.error(e);
    notificationService.error(tCaseErrorMessages('fetchingCasesData'));
    return null;
  }
};

export const syncTicketStatus = async (
  caseId: string,
  configurationId: string,
  ticketId: string,
  ticketServiceType: string,
) => {
  try {
    const additionalProperties = getFetchTicketActionAdditionalProperties(
      ticketServiceType as TicketServiceTypes,
      configurationId,
      ticketId,
    );
    const reqBody = {
      type: getTicketActionTypeNameForPayload(ticketServiceType as TicketServiceTypes),
      subType: 'fetchTicketAndSync',
      additionalProperties,
    };
    await httpService.post(`actionable-insights/cases/${caseId}:action`, reqBody);
  } catch (e) {
    console.error(e);
    notificationService.error(tCaseErrorMessages('syncCaseStatus'));
  } finally {
    return true;
  }
};

const getCaseStatus = (filter: BigidFilter) => filter.filter(item => item.field === 'caseStatus')[0].value as CaseState;

export const hasEverySelectedRowOpenStatus = ({ selectedRowIds, filter }: ActionData) =>
  selectedRowIds?.length > 0 && getCaseStatus(filter) === CaseState.OPEN;

const openModal =
  (type: ActionsDialogTypes, openModalWithData: OpenModalWithData, viewType: ViewType) =>
  async ({ selectedRows, allSelected, filter }: ActionData) => {
    return new Promise(resolve => {
      let caseIds = null;

      if (allSelected && viewType === ViewType.FLAT) {
        caseIds = [];
      } else if (!allSelected && viewType !== ViewType.GROUPED) {
        caseIds = selectedRows.map(row => row?.id).filter(Boolean);
      }

      openModalWithData(type, selectedRows, {
        caseIds,
        status: getCaseStatus(filter),
        policies: selectedRows,
        callback: resolve,
      });
    }).then(() => {
      return {
        shouldGridReload: false,
        shouldClearSelection: true,
      };
    });
  };

export const getBulkSelectionToolbarActions = memoize(
  (
    openModalWithData: OpenModalWithData,
    selectedStatus: CaseState,
    onCaseAction: CaseActionCallback,
    viewType?: ViewType,
  ): ToolbarAction[] =>
    getApplicationPreference('ACTIONABLE_INSIGHTS_BATCH_SELECTION_ENABLED') && selectedStatus !== CaseState.RESOLVED
      ? [
          {
            label: '',
            icon: BigidApplyIcon,
            type: ToolbarActionType.ACTION_ICON,
            isGlobal: false,
            execute: openModal(ActionsDialogTypes.REMEDIATE, openModalWithData, viewType),
            show: hasEverySelectedRowOpenStatus,
          },
          {
            label: '',
            isGlobal: false,
            type: ToolbarActionType.ACTION_ICON,
            icon: BigidApprovedCaseIcon,
            execute: openModal(ActionsDialogTypes.ACKNOWLEDGE, openModalWithData, viewType),
            show: hasEverySelectedRowOpenStatus,
          },
          {
            label: '',
            isGlobal: false,
            type: ToolbarActionType.ACTION_ICON,
            icon: BigidHideIcon,
            execute: openModal(ActionsDialogTypes.SILENCE, openModalWithData, viewType),
            show: hasEverySelectedRowOpenStatus,
          },
          {
            label: '',
            icon: BigidViewIcon,
            type: ToolbarActionType.ACTION_ICON,
            isGlobal: false,
            execute: ({ selectedRows, filter, allSelected }) => {
              let caseIds = null;

              if (allSelected && viewType === ViewType.FLAT) {
                caseIds = [];
              } else if (!allSelected && viewType !== ViewType.GROUPED) {
                caseIds = selectedRows.map(row => row?.id).filter(Boolean);
              }

              const status = getCaseStatus(filter);
              const policies = allSelected ? [] : selectedRows;

              updateCaseStatus(
                {
                  caseIds,
                  policies,
                  status,
                },
                CaseStatus.OPEN,
                null,
                onCaseAction,
              );
              return Promise.resolve({ shouldGridReload: true, shouldClearSelection: true });
            },
            show: ({ selectedRowIds, filter }) => {
              const currentCaseStatus = filter.filter(item => item.field === 'caseStatus')[0]?.value;

              return (
                selectedRowIds?.length > 0 &&
                currentCaseStatus !== CaseState.OPEN &&
                currentCaseStatus !== CaseState.RESOLVED
              );
            },
          },
          {
            label: '',
            isGlobal: false,
            type: ToolbarActionType.ACTION_ICON,
            icon: BigidAddUserIcon,
            execute: openModal(ActionsDialogTypes.ASSIGN, openModalWithData, viewType),
            show: hasEverySelectedRowOpenStatus,
          },
        ]
      : [],
);

const getCasesAmount = (statusesMetaData: CaseMetaDataEntry[], status: CaseState): number | string => {
  return statusesMetaData.find(entry => entry.value === status)?.totalCount || '0';
};

export const generateTabs = async (): Promise<ActionableInsightsTabs> => {
  const { caseStatus } = await fetchFiltersMetadata();
  return [
    {
      label: `${tTabsLabel('open')} (${getCasesAmount(caseStatus, CaseState.OPEN)})`,
      data: CaseState.OPEN,
    },
    {
      label: `${tTabsLabel('closed')} (${getCasesAmount(caseStatus, CaseState.CLOSED)})`,
      data: CaseState.CLOSED,
    },
    {
      label: `${tTabsLabel('resolved')}`,
      data: CaseState.RESOLVED,
    },
  ];
};

export const eventTracking = (dialogType: ActionsDialogTypes, viewType: ViewType) => {
  let eventType;
  switch (dialogType) {
    case ActionsDialogTypes.ASSIGN:
      eventType = ActionableInsightsTrackingEvents.DATA_RISK_MANAGEMENT_ASSIGN_USER_CLICK;
      break;
    case ActionsDialogTypes.REMEDIATE:
      eventType = ActionableInsightsTrackingEvents.DATA_RISK_MANAGEMENT_REMEDIATE_CASE_CLICK;
      break;
    case ActionsDialogTypes.SILENCE:
      eventType = ActionableInsightsTrackingEvents.DATA_RISK_MANAGEMENT_SILENCE_CASE_CLICK;
      break;
  }
  return trackActionableInsightsEvent(eventType, { VIEW_TYPE: viewType });
};

export const getChipBgColorBySeverity = (severity: string) => {
  switch (severity) {
    case CaseSeverityLevel.CRITICAL:
      return '#FFF2F2';
    case CaseSeverityLevel.HIGH:
      return '#FFF2E5';
    case CaseSeverityLevel.MEDIUM:
      return '#FFFCE0';
    default:
      return '#F0FFF7';
  }
};

export const getChipTextColorBySeverity = (severity: string) => {
  switch (severity) {
    case CaseSeverityLevel.CRITICAL:
      return BigidColorsV2.red[900];
    case CaseSeverityLevel.HIGH:
      return '#BF4000';
    case CaseSeverityLevel.MEDIUM:
      return '#995900';
    default:
      return '#138045';
  }
};

export const caseStatusBadgeType = (status: CaseStatus) => {
  switch (status) {
    case CaseStatus.OPEN:
      return BigidStatusBadgeType.INFO;
    case CaseStatus.ACKNOWLEDGED:
      return BigidStatusBadgeType.DISABLED;
    case CaseStatus.SILENCED:
      return BigidStatusBadgeType.DISABLED;
    case CaseStatus.REMEDIATED:
      return BigidStatusBadgeType.SUCCESS;
    default:
      return BigidStatusBadgeType.INFO;
  }
};

export const getSeverityChipProps = (severity = CaseSeverityLevel.MEDIUM as string): ChipFormatterProps => {
  return {
    chip: {
      label: tSeverity(severity) as string,
      bgColor: getChipBgColorBySeverity(severity),
      color: getChipTextColorBySeverity(severity),
    },
  };
};

export const getJiraConfigurationMetaData = async (): Promise<ConfigurationsMetadata> => {
  try {
    if (!isPermitted(ACTION_CENTER_PERMISSIONS.READ_CONFIGURATIONS.name)) {
      throw new Error(tActionErrorMessages('jiraMissingActionCenterPermissions'));
    }
    const configurationMetaData = await getConfigurationsMetadata();
    return configurationMetaData.filter(config => {
      return config.type === JIRA_CONFIGURATION_NAME;
    });
  } catch (error) {
    notificationService.error(tActionErrorMessages('jiraCouldNotFetchMetadata'));
    trackActionableInsightsEvent(
      ActionableInsightsTrackingEvents.DATA_RISK_MANAGEMENT_JIRA_CONFIGURATION_ERROR_FATCHING_DATA,
    );
    console.error(error);
  }
};

export const getJiraConfigurations = async (): Promise<JiraConfiguration[]> => {
  try {
    const chosenConfigurationQuery = queryService.getGridConfigQuery({
      filter: [
        {
          field: 'type',
          operator: 'equal' as BigidFieldFilterOperator,
          value: JIRA_CONFIGURATION_NAME,
        },
      ],
    });
    const { configurations } = await getConfigurations(chosenConfigurationQuery);
    return configurations.map(config => {
      return {
        name: config.name as string,
        id: config.id as string,
        type: config.type as string,
        isOffline: config?.isOffline,
      };
    });
  } catch (error) {
    notificationService.error(tActionErrorMessages('jiraCouldNotFetchMetadata'));
    trackActionableInsightsEvent(
      ActionableInsightsTrackingEvents.DATA_RISK_MANAGEMENT_JIRA_CONFIGURATION_ERROR_FATCHING_DATA,
    );
    console.error(error);
  }
};

export const isUrl = (str: string) => {
  return str && str.includes('http');
};

export const getJiraTicketNumber = (ticketUrl: string) => {
  return ticketUrl ? ticketUrl.split('/').at(-1) : '';
};

export const generateTicketGridField = (ticketUrl: string, ticketMetadata?: any): LinkFormatterProps => {
  const linkText = ticketUrl && ticketMetadata?.key;
  return {
    link: {
      text: linkText,
      href: ticketUrl?.includes('http') && ticketUrl,
      shouldOpenNewTab: true,
      rel: 'opener',
      disabled: !linkText || linkText === JIRA_TICKET_CREATION_ERROR_FIELD_VALUE,
    },
  };
};

export const getSensitivityClassificationMapping = async (): Promise<Map<string, number>> => {
  try {
    const scMapper = new Map();
    const { scConfigs } = await getSensitivityClassificationsWithNoPagination();
    const sensitivityScConfig = scConfigs.find(config => config.name === 'Sensitivity');
    sensitivityScConfig.classifications.forEach(classification =>
      scMapper.set(classification.name, classification.priority),
    );
    return scMapper;
  } catch (e) {
    console.error(e);
  }
};

export const syncAndGetLatestTicketStatus = async (
  caseId: string,
  configurationId: string,
  ticketName: string,
  ticketServiceType: string,
): Promise<CaseStatus> => {
  try {
    await syncTicketStatus(caseId, configurationId, ticketName, ticketServiceType);
    await delay(2000);
    const data = await getCaseData(caseId);
    if (data?.length) {
      return data[0].caseStatus;
    } else {
      throw new Error('Could not fetch case data');
    }
  } catch (e) {
    console.error(e);
    notificationService.error(tCaseErrorMessages('syncCaseStatus'));
  }
};

export const applyTicketSyncWithNewCaseStatus = async (
  id: string,
  caseStatus: CaseStatus,
  dispatch: React.Dispatch<ActionableInsightsReducerAction>,
) => {
  entityEventsEmitter.emit(EntityEvents.UPDATE_BY_ID, id, { caseStatus });
  entityEventsEmitter.emit(EntityEvents.RELOAD, [{ id }]);
  const tabs = await generateTabs();
  dispatch({
    type: ReducerActions.UPDATE_ACTIONABLE_INSIGHTS_DATA,
    payload: { tabs },
  });
  notificationService.success(tNotificationMessages('jiraSyncNewCaseStatus', { caseStatus }));
};

export const toolbarActions = (
  openModalWithData: OpenModalWithData,
  selectedStatus: CaseState,
  onCaseAction: CaseActionCallback,
  bulkSelection = false,
  viewType?: ViewType,
): ToolbarAction[] => {
  const caseLifeCycleToolbar = bulkSelection
    ? getBulkSelectionToolbarActions(openModalWithData, selectedStatus, onCaseAction, viewType)
    : [];
  return [...caseLifeCycleToolbar];
};

const getFetchTicketActionAdditionalProperties = (
  ticketServiceType: TicketServiceTypes,
  configurationId: string,
  ticketId: string,
): SyncTicketProperties => {
  switch (ticketServiceType) {
    case TicketServiceTypes.JIRA:
      return {
        configurationId,
        ticketId,
      };
    case TicketServiceTypes.SERVICE_NOW:
      return {
        sys_id: configurationId,
      };
  }
};

export const getTicketActionTypeNameForPayload = (ticketServiceType: TicketServiceTypes): string => {
  switch (ticketServiceType) {
    case TicketServiceTypes.JIRA:
      return 'jira';
    case TicketServiceTypes.SERVICE_NOW:
      return 'service_now';
  }
};

export const fetchRemediationSteps = async (policyName: string, dsType: string) => {
  try {
    const {
      data: { data },
    } = await httpService.fetch(
      `actionable-insights/remediation-steps?policyName=${policyName}&dataSourceType=${dsType}`,
    );
    return data;
  } catch (e) {
    console.error(e);
    notificationService.error(e?.message || tCaseErrorMessages('fetchingRemediationSteps'));
  }
};

export const upperFirstLetter = (str: string) => {
  return str.charAt(0).toUpperCase() + str.slice(1);
};
