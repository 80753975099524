import React, { FC, ReactText, ReactElement } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { BigidColors } from '@bigid-ui/components';
import classNames from 'classnames';

export interface ColumnProfilingEntityProps {
  name: string;
  value?: ReactText;
  valuePlaceholder?: string;
  valueColor?: string;
  widget?: ReactElement;
}

const useStyles = makeStyles({
  wrapper: {
    backgroundColor: BigidColors.gray[50],
    border: `1px solid ${BigidColors.gray[50]}`,
    borderRadius: '4px',
    padding: '8px 12px',
    height: '63px',
    display: 'flex',
  },
  leftContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    flex: '1 0 auto',
    maxWidth: 'calc(100% - 55px)',
  },
  leftContainerExtended: {
    maxWidth: '100%',
  },
  rightContainer: {
    display: 'flex',
    alignItems: 'center',
    width: 42,
    marginLeft: 10,
    flex: '0 0 42px',
  },
  entityName: {
    fontSize: '0.8125rem',
    color: BigidColors.gray[600],
    fontWeight: 400,
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  },
  entityValue: ({ valueColor }: Pick<ColumnProfilingEntityProps, 'valueColor'>) => ({
    color: valueColor,
    fontSize: '0.9375rem',
    fontWeight: 400,
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  }),
});

export const ColumnProfilingEntity: FC<ColumnProfilingEntityProps> = ({
  name,
  value,
  valueColor = BigidColors.gray[700],
  valuePlaceholder = '-',
  widget,
}) => {
  const classes = useStyles({ valueColor });

  return (
    <div className={classes.wrapper}>
      <div className={classNames(classes.leftContainer, !widget && classes.leftContainerExtended)}>
        <div className={classes.entityName}>{name}</div>
        <div className={classes.entityValue} title={value && value.toString()}>
          {typeof value === 'number' || typeof value === 'string' ? value : valuePlaceholder}
        </div>
      </div>
      {widget && <div className={classes.rightContainer}>{widget}</div>}
    </div>
  );
};
