import { PolicyModel } from '../views/ActionableInsights/actionableInsightsService';

export enum CaseSeverityLevel {
  LOW = 'low',
  MEDIUM = 'medium',
  HIGH = 'high',
  CRITICAL = 'critical',
}

export interface policyData {
  description: string;
}

export enum CaseState {
  OPEN = 'open',
  CLOSED = 'closed',
  RESOLVED = 'resolved',
}

export type Filters = {
  caseIds?: string[] | null;
  policies?: PolicyModel[];
  status?: CaseState;
  callback?: (value?: unknown) => void;
};
