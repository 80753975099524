import { httpService } from '../../../services/httpService';
import {
  ConfigurationsMetadataResponse,
  Configuration,
  ConfigurationFormFields,
  TestConnectionResponse,
} from './configurationManagementTypes';

export const getConfigurationsMetadata = async () => {
  const {
    data: {
      data: { configurationsMetadata },
    },
  } = await httpService.fetch<{ data: ConfigurationsMetadataResponse }>('action-center/configurations/metadata');

  return configurationsMetadata;
};

export const getConfigurations = async (gridConfigQuery: string) => {
  const {
    data: {
      data: { configurations, totalCount },
    },
  } = await httpService.fetch<{ data: { configurations: Configuration[]; totalCount: number } }>(
    `action-center/configurations?${gridConfigQuery}`,
  );

  return {
    configurations,
    totalCount,
  };
};

export const deleteConfiguration = async (configurationId: string) =>
  httpService.delete<{ data: { id: string } }>(`action-center/configurations/${configurationId}`);

export const createConfiguration = async (configuration: ConfigurationFormFields) =>
  httpService.post<{ data: { id: string } }, ConfigurationFormFields>('action-center/configurations', configuration);

export const updateConfiguration = async (
  configurationId: string,
  configuration: Omit<ConfigurationFormFields, 'type'>,
) =>
  httpService.put<{ data: { id: string } }, Omit<ConfigurationFormFields, 'type'>>(
    `action-center/configurations/${configurationId}`,
    configuration,
  );

export const testConnectionConfiguration = async (
  configuration: ConfigurationFormFields,
): Promise<TestConnectionResponse> => {
  const {
    data: {
      data: { success, error },
    },
  } = await httpService.post<{ data: TestConnectionResponse }, ConfigurationFormFields>(
    `action-center/configurations/test-connection`,
    configuration,
  );

  return { success, error };
};
