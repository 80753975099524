import { httpService } from '../../services/httpService';
import { DateISO8601 } from '../../types/types';

export interface ScannerGroupsResponse {
  status: string;
  statusCode: number;
  data: string[];
  message: string;
}

export interface LabelConfiguration {
  batchId: string;
  displayName: string;
  description?: string;
  type: string;
  fieldId: string;
  groupId: string;
  labelId: string;
  name: string;
}

export interface GetLabelsResponse {
  labels: LabelConfiguration[];
  type: string;
}

export interface GetMipSettingsResponse {
  clientid: string;
  secret: string;
  tenantid: string;
  type: string;
  scannerGroup?: string;
}

export enum LatestScanState {
  FAILED = 'Failed',
  COMPLETED = 'Completed',
}

export const getLabels = () => {
  return httpService.fetch<GetLabelsResponse>(`labels`).then(({ data }) => data);
};

export const getMipSettings = () => {
  return httpService.fetch<GetMipSettingsResponse>(`labeler/mip-settings`).then(({ data }) => data);
};

export interface PostMipSettings {
  clientid: string;
  type: string;
  secret: string;
  tenantid: string;
  isNewSecret?: boolean;
}

export async function postMipSettings(options: PostMipSettings) {
  return httpService.post<void, PostMipSettings>(`labeler/mip-settings`, { ...options }).then(({ data }) => data);
}

export interface GetScanResponse {
  _id: string;
  created_at: string;
  name: string;
  parent_scan_id: string;
  state: string;
  type: string;
  updated_at: DateISO8601;
  errorMessage: string;
}

export async function labelingGetScanById(scanId: string) {
  return httpService.fetch<GetScanResponse[]>(`scans/${scanId}`).then(({ data }) => (data.length ? data[0] : null));
}

export async function getAvailableScannerGroups() {
  return httpService.fetch<ScannerGroupsResponse>(`available-scanner-groups`).then(({ data: { data } }) => data);
}

export const postMipFetch = () => {
  return httpService
    .post<{
      result: string;
    }>(`labeler/mip-fetch`)
    .then(({ data }) => data.result);
};
