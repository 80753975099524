import React, { FC, PropsWithChildren } from 'react';
import { sidebarWidth } from '../../../components/Sidebar/Sidebar';
import makeStyles from '@mui/styles/makeStyles';

const margin = sidebarWidth + 2 * sidebarWidth;
const useStyles = makeStyles({
  container: {
    display: 'flex',
    width: `calc(100% - ${margin}px)`,
    position: 'relative',
    marginLeft: margin,
    alignItems: 'center',
    justifyContent: 'center',
  },
  contentContainer: {
    padding: '21px 0 25px',
    width: 728,
  },
});

export const TopArea: FC<PropsWithChildren> = ({ children }) => {
  const { container, contentContainer } = useStyles({});

  return (
    <div className={container}>
      <div className={contentContainer}>{children}</div>
    </div>
  );
};
