import { styled } from '@mui/material';
import { BigidColorsV2, BigidBody1 } from '@bigid-ui/components';

const ClassifierFormHolder = styled('div')`
  fieldset {
    margin: 0 0 16px;
  }
`;

const ClassifierTypeRadioButtonsBox = styled('div')`
  > fieldset {
    margin: 0;

    fieldset {
      margin: 0;
    }
  }

  label {
    margin-bottom: 0;
  }
`;

const ClassifierTypeDescription = styled(BigidBody1)`
  margin-bottom: 16px;
  color: ${({ theme }) => theme.vars.tokens.bigid.foregroundTertiary};
`;

const CheckboxContainer = styled('div')`
  margin-bottom: 0px;

  fieldset {
    margin: 0;
  }
`;

const StyledFlexWrapper = styled('div')`
  display: flex;
  gap: 24px;

  fieldset {
    flex: 1;
    margin-bottom: 0;
  }
`;

const StyledClassifierRegex = styled('div')`
  background: ${BigidColorsV2.gray[125]};
  padding: 20px;
  margin-bottom: 16px;
  border-radius: 8px;

  h5 {
    margin-top: 0;
  }
`;

const StyledChecksumValidation = styled('div')`
  display: flex;
  gap: 24px;
  align-items: center;
`;

const StyledDivider = styled('div')`
  width: 100%;
  height: 1px;
  background: ${BigidColorsV2.gray[200]};
  margin: 16px 0;
`;

const StyledMandatoryMark = styled('span')`
  color: ${BigidColorsV2.red[600]};
  width: 10px;
  display: inline-block;
  font-size: 16px;
`;

const StyledProximity = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 15px;
  padding-top: 5px;

  fieldset {
    margin: 0;
  }
`;

export {
  ClassifierFormHolder,
  ClassifierTypeRadioButtonsBox,
  ClassifierTypeDescription,
  CheckboxContainer,
  StyledFlexWrapper,
  StyledClassifierRegex,
  StyledChecksumValidation,
  StyledDivider,
  StyledMandatoryMark,
  StyledProximity,
};
