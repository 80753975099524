import { CloudProvider } from './types';
import { DiscoveryAuthTypes, TYPE_TO_AVAILABLE_CHECK_PERMISSIONS_BY_AUTH_FIELDS } from './auth';
import { publicUrls, docsUrls } from '../../../config/publicUrls';
import { BigidLinkProps } from '@bigid-ui/components';
import { httpService } from '../../../services/httpService';

export enum PrerequisitesDataViewType {
  NUMBERED_LIST = 'numberedList',
  NUMBERED_LIST_SPECIAL = 'numberedList',
}

export type PrerequisitesDataItem = (BigidLinkProps | string)[];

export interface PrerequisitesData {
  introduction?: string;
  content?: PrerequisitesDataItem[];
  type: PrerequisitesDataViewType;
}

export const getAwsTemplateLink = async ({ authType, type }: Record<string, any>) => {
  const authValue = typeof authType === 'string' ? authType : authType?.[0]?.value;
  const isCheck =
    TYPE_TO_AVAILABLE_CHECK_PERMISSIONS_BY_AUTH_FIELDS[type as CloudProvider]?.[authValue as DiscoveryAuthTypes];
  let externalId = '';
  try {
    const { data } = await httpService.fetch<{ externalId: string }>(`saas/external-id`);
    externalId = data?.externalId;
  } catch (err) {
    console.warn(err);
  }

  return `${publicUrls.AWS_TEMPLATE_BASE}home?region=us-east-1#/stacks/create/review?${
    externalId ? `param_ExternalID=${externalId}&` : ''
  }${isCheck ? 'param_PermissionsValidation=true&' : ''}templateURL=${publicUrls.AWS_TEMPLATE_LATEST}`;
};

const MULTI_AWS_TEXTS = [
  [`Create a dedicated new AWS account or elect a restricted account for cross-scanning activities`],
  [
    'AWS Config must be enabled in all accounts where you need to discover resources (this is already the case if you use AWS Control Tower).',
  ],
  [
    'An multi-account and multi-region aggregator must exist. We recommend to create a Config aggregator with all regions and all accounts. Please see the : ',
    {
      href: `${publicUrls.AMAZON_BLOG}mt/org-aggregator-delegated-admin/`,
      text: 'following blog post to create one',
    },
  ],
  [
    'An IAM Role or User with the following policy attached for the autodiscovery app:',
    `{ "Version": "2012-10-17",
    "Statement": [{
            "Effect": "Allow",
            "Action": "config:SelectAggregateResourceConfig",
            "Resource": "*"
        }] }`,
  ],
];

const AWS_DEPRECATED =
  'AWS is deprecating this authentication type. Consider authenticating with IAM Role authentication instead.';

const REGISTER_AZURE_APP = [
  `Register your application`,
  {
    href: publicUrls.AZURE_PORTAL,
    text: 'Azure portal',
  },
  ', ',
  {
    href: docsUrls.AZURE_APP_REGISTRATION,
    text: 'Doc',
  },
];
const AZURE_GET_MANAGEMENT_ID = [
  'Get your Management Group ID',
  {
    href:
      publicUrls.AZURE_PORTAL + '#view/Microsoft_Azure_ManagementGroups/ManagementGroupBrowseBlade/~/MGBrowse_overview',
    text: 'Azure portal',
  },
  ', ',
  {
    text: 'Doc',
    href: docsUrls.AZURE_MANAGED_IDENTITY,
  },
];

export const TYPE_TO_PREREQUISITES_CONTENT: Record<
  CloudProvider,
  {
    [key in DiscoveryAuthTypes]?: ((args: any) => Promise<PrerequisitesData>) | PrerequisitesData;
  }
> = {
  [CloudProvider.AWS]: {
    [DiscoveryAuthTypes.IAM_ROLE]: async (data: any) => ({
      type: PrerequisitesDataViewType.NUMBERED_LIST_SPECIAL,
      content: [
        [
          'Login to AWS Cloud Formation Template ',
          'Create the required IAM Role by',
          {
            href: await getAwsTemplateLink(data),
            text: 'deploying the CloudFormation template',
          },
          ' or ',
          {
            href: publicUrls.AWS_TEMPLATE_LATEST,
            text: 'download the CloudFormation template',
          },
          ' and share it with your AWS admin.',
        ],
        ['Connect your account to BigID', 'Copy the ARN created at the ‘Output’ tab'],
      ],
    }),
    [DiscoveryAuthTypes.CREDENTIALS]: {
      introduction: AWS_DEPRECATED,
      type: PrerequisitesDataViewType.NUMBERED_LIST,
      content: [
        [
          `Add access policies with minimum or extended permissions for each data source that you want to include in the auto-discovery.`,
        ],
        [' Associate the access policy with the BigID application'],
      ],
    },
    [DiscoveryAuthTypes.CREDENTIALS_MULTI]: {
      introduction: AWS_DEPRECATED,
      type: PrerequisitesDataViewType.NUMBERED_LIST,
      content: MULTI_AWS_TEXTS,
    },
    [DiscoveryAuthTypes.IAM_ROLE_MULTI]: {
      type: PrerequisitesDataViewType.NUMBERED_LIST,
      content: MULTI_AWS_TEXTS,
    },
  },
  [CloudProvider.AZURE]: {
    [DiscoveryAuthTypes.CREDENTIALS]: {
      type: PrerequisitesDataViewType.NUMBERED_LIST,
      content: [
        REGISTER_AZURE_APP,
        [
          'Get your Principal Name/ Client ID and Tenant ID / Subscription ID',
          {
            href: docsUrls.AZURE_CLIENT_ID,
            text: 'Doc',
          },
        ],
        [
          'Create a Client Secret (for the Principal Secret field)',
          {
            href: docsUrls.AZURE_CLIENT_SECRET,
            text: 'Doc',
          },
        ],
      ],
    },
    [DiscoveryAuthTypes.CREDENTIALS_MULTI]: {
      type: PrerequisitesDataViewType.NUMBERED_LIST,
      content: [
        REGISTER_AZURE_APP,
        [
          'Get your Principal Name/ Client ID and Tenant ID / Subscription ID',
          {
            href: docsUrls.AZURE_CLIENT_ID,
            text: 'Doc',
          },
        ],
        [
          'Create a Client Secret (for the Principal Secret field)',
          {
            href: docsUrls.AZURE_CLIENT_SECRET,
            text: 'Doc',
          },
        ],
        AZURE_GET_MANAGEMENT_ID,
      ],
    },
    [DiscoveryAuthTypes.IAM_ROLE]: {
      type: PrerequisitesDataViewType.NUMBERED_LIST,
      introduction:
        'Azure provides two types of Managed Identity: \n 1. User-Assigned Managed Identity \n 2. System-Assigned Managed Identity',
      content: [
        REGISTER_AZURE_APP,
        [
          'Checks if Managed Identity Client ID is provided. If yes then uses User-Assigned Managed Identity with provided Client ID',
        ],
      ],
    },
    [DiscoveryAuthTypes.IAM_ROLE_MULTI]: {
      type: PrerequisitesDataViewType.NUMBERED_LIST,
      introduction:
        'Azure provides two types of Managed Identity: \n 1. User-Assigned Managed Identity \n 2. System-Assigned Managed Identity',
      content: [
        REGISTER_AZURE_APP,
        [
          'Checks if Managed Identity Client ID is provided. If yes then uses User-Assigned Managed Identity with provided Client ID',
        ],
        AZURE_GET_MANAGEMENT_ID,
      ],
    },
  },
  [CloudProvider.GCP]: {
    [DiscoveryAuthTypes.CREDENTIALS]: {
      type: PrerequisitesDataViewType.NUMBERED_LIST,
      introduction: 'To build and run the application, you need:',
      content: [
        [
          'A GCP service account with access to all relevant GCP projects.',
          {
            href: docsUrls.AUTO_DISCOVERY_GCP_PREREQUISITES,
            text: 'Doc',
          },
        ],
        [
          'API and Services enabled in GCP for each project where you want to use the GCP Auto-Discovery app.',
          {
            href: publicUrls.GOOGLE_CLOUD_CONSOLE,
            text: 'Google Cloud console',
          },
        ],
        ['A service account key that can be used as a parameter for the BigID GCP Auto-Discovery application.'],
      ],
    },
  },
  [CloudProvider.ONTAP]: {
    [DiscoveryAuthTypes.CREDENTIALS]: {
      type: PrerequisitesDataViewType.NUMBERED_LIST,
      content: [
        [
          'Get your ONTAP REST API endpoint:',
          '\n - Cluster management LIF - to find all resources.',
          '\n - SVM management LIF - to find only data sources on a certain server.',
          `\n * The LIF you choose to use must be configured to support the HTTPS management protocol. Also, the firewall configuration in your network must allow HTTPS traffic.`,
        ],
        ['Get credentials for ONTAP Account (name and password) with API access'],
      ],
    },
  },
};
