import { useCallback, useEffect, useState } from 'react';
import {
  useGetDataSourceByNameProps as useGetDataSourceByNamePropsV1,
  useGetDataSourceByName as useGetDataSourceByNameV1,
} from '../../DataSourceConfiguration/hooks/useGetDataSourceByName';
import { usePrevious } from './usePrevious';
import {
  DataSourceConfigurationState as DataSourceConfigurationStateV1,
  getStatePartBasedOnCurrentState,
} from '../../DataSourceConfiguration/hooks/useDataSourceConfigState';
import { DataSourceTestConnectionStatusEnum } from '../../DataSourceConfiguration/hooks/useTestConnection';
import { isFunction } from 'lodash';

type DataSourceConfigurationStateV2 = DataSourceConfigurationStateV1 & {
  sourceTestStatus?: DataSourceTestConnectionStatusEnum;
};

type UpdateDataSourceConfigStateV2 = (
  state: Partial<DataSourceConfigurationStateV2> | getStatePartBasedOnCurrentState,
) => void;

type UseGetDataSourceByNameOpts = {
  forceRefresh?: boolean;
};

type UseGetDataSourceByNamePropsV2 = Omit<useGetDataSourceByNamePropsV1, 'updateState'> & {
  updateState: UpdateDataSourceConfigStateV2;
};

export const useGetDataSourceByName = (
  { updateState, name, ...rest }: UseGetDataSourceByNamePropsV2,
  opts?: UseGetDataSourceByNameOpts,
) => {
  const [shouldRefresh, setShouldRefresh] = useState(false);
  const prevName = usePrevious(name);
  const { forceRefresh } = opts ?? { forceRefresh: true };

  const updateStateOverride = useCallback(
    (propsOrFunction: Partial<DataSourceConfigurationStateV1> | getStatePartBasedOnCurrentState) => {
      const handleStateUpdateFunction = (prevState: DataSourceConfigurationStateV1) =>
        (propsOrFunction as getStatePartBasedOnCurrentState)({
          ...prevState,
          testStatus: DataSourceTestConnectionStatusEnum.notStarted,
          sourceTestStatus: prevState.testStatus,
        } as DataSourceConfigurationStateV2);

      // @info initial connection status needs to be reset for new experience
      updateState(
        isFunction(propsOrFunction)
          ? handleStateUpdateFunction
          : {
              ...propsOrFunction,
              testStatus: DataSourceTestConnectionStatusEnum.notStarted,
              sourceTestStatus: propsOrFunction.testStatus,
            },
      );

      if (isFunction(propsOrFunction)) {
        updateState(prev => ({
          ...prev,
          testStatus: DataSourceTestConnectionStatusEnum.notStarted,
          sourceTestStatus: prev.testStatus,
        }));
      }
    },
    [updateState],
  );

  useEffect(() => {
    const dataSourceCreated = !prevName && name;
    // @info don't refresh the data source if a data source was created
    setShouldRefresh(forceRefresh ?? (dataSourceCreated ? false : name !== prevName));
  }, [name, prevName, forceRefresh]);

  const result = useGetDataSourceByNameV1({
    updateState: updateStateOverride,
    name: shouldRefresh ? name : null,
    ...rest,
  });
  return result;
};
