import { useCallback } from 'react';
import { addTourToQueue, getTourState, removeCurrentTour } from '../utilities/guidedTourStorage';

export const useGuidedTourManager = () => {
  const { currentTour } = getTourState();

  const startTour = useCallback((tourName: string) => {
    addTourToQueue(tourName);
  }, []);

  const endTour = useCallback(() => {
    removeCurrentTour();
  }, []);

  return { currentTour, startTour, endTour };
};
