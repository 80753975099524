import React from 'react';
import { capitalize } from 'lodash';
import {
  BigidFormField,
  BigidFormFieldTypes,
  BigidStatusBadge,
  BigidStatusBadgeType,
  BigidStatusBadgeSize,
} from '@bigid-ui/components';
import { BigidGridColumnTypes, BigidGridProps, useFetch } from '@bigid-ui/grid';
import {
  BigidLayoutMasterDetails,
  BigidLayoutMasterDetailsConfig,
  BigidLayoutMasterDetailsGridRowLayouts as BigidGridRowLayouts,
  BigidLayoutMasterDetailsGridSearchTypes as BigidGridSearchType,
} from '@bigid-ui/layout';
import { SavedQueriesNoData } from '../SavedQueriesNoData';
import { $translate } from '../../../services/angularServices';
import { NEW_SAVED_QUERY_TAG_NAME } from '../constants';
import type { QueryGridRow, QueryItemRequest, QueryItemResponse } from '../types';

type QueryValidationFn = (value: string) => boolean | string;
type TranslationService = typeof $translate.instant;

const FIELD_VALID = false;

const validationMapperFn = (t: TranslationService): Partial<Record<keyof QueryItemResponse, QueryValidationFn>> => ({
  tag_name: (value: string) => (value ? FIELD_VALID : t('message.requiredField')),
  tag_value: (value: string) => (value ? FIELD_VALID : t('message.requiredField')),
});

const fieldMapper: Partial<Record<keyof QueryItemResponse, Partial<BigidFormField>>> = {
  description: {
    type: BigidFormFieldTypes.TEXTAREA,
    misc: {
      fullWidth: true,
      rows: 3,
    },
    fieldProps: {
      multiline: true,
    },
  },
  query: {
    type: BigidFormFieldTypes.TEXTAREA,
    misc: {
      fullWidth: true,
      rows: 5,
    },
    fieldProps: {
      multiline: true,
    },
  },
};

const translationMapper: Partial<Record<keyof QueryItemResponse, string>> = {
  description: 'form.tagDescription',
  query: 'form.tagQuery',
  tag_name: 'form.queryName',
  tag_value: 'form.queryValue',
};

export const mapQueryGridRowKeyToFormField = (key: keyof QueryItemResponse, t: TranslationService): BigidFormField => {
  const validationFunctionMapper = validationMapperFn(t);
  const hasValidationPipeline = !!validationFunctionMapper[key];

  return {
    label: t(translationMapper[key] ?? capitalize(key)),
    labelWidth: '300px',
    isRequired: hasValidationPipeline,
    name: key,
    validate: (value: unknown) => {
      const validationFn = validationFunctionMapper[key];
      return validationFn ? validationFn(value as string) : FIELD_VALID;
    },
    ...(fieldMapper[key] ?? {}),
  };
};

export const mapQueryResponseToQueryRequest = (item: QueryItemResponse): QueryItemRequest => ({
  query: item.query,
  tag_name: item.tag_name,
  tag_value: item.tag_value,
  description: item.description,
});

export const mapRowIdToQueryGridRow = (id: unknown, rows: QueryGridRow[]): QueryGridRow =>
  rows.find(({ id: rowId }) => id === rowId) ?? null;

export const mapQueryResponseToGridRow = (item: QueryItemResponse) => ({
  ...item,
  id: item?.id ?? item._id,
});

export const createGridConfig = (
  id: string,
  config: ReturnType<typeof useFetch<QueryGridRow>>,
  t: TranslationService,
): BigidGridProps<QueryGridRow> => ({
  gridId: id,
  rows: config.rows,
  totalRowsCount: config.totalRowsCount,
  skip: config.skip,
  onPagingChanged: config.onPagingChanged,
  onSortingChanged: config.onSortingChanged,
  onFiltersChange: config.onFiltersChanged,
  defaultSorting: config.defaultSorting,
  loading: config.isLoading,
  pageSize: 1000,
  noDataContent: <SavedQueriesNoData message={t('message.noSavedQueriesHeader')} />,
  columns: [
    {
      name: 'tag_name',
      title: 'tag_name',
      width: 'auto',
      getCellValue: ({ tag_name, isPending }) => (
        <BigidLayoutMasterDetails.Row
          title={isPending ? NEW_SAVED_QUERY_TAG_NAME : tag_name}
          subtitle={
            isPending ? (
              <BigidStatusBadge
                label={t('statusText')}
                type={BigidStatusBadgeType.DARK}
                size={BigidStatusBadgeSize.SMALL}
              />
            ) : null
          }
          layout={BigidGridRowLayouts.TWO_ROW}
        />
      ),
      type: BigidGridColumnTypes.CUSTOM,
    },
    {
      name: 'tag_value',
      title: 'tag_value',
      isHiddenByDefault: true,
      type: BigidGridColumnTypes.CUSTOM,
      getCellValue: () => null,
    },
  ],
});

export const createLayoutConfig = (
  gridConfig: BigidGridProps<QueryGridRow>,
  t: TranslationService,
): BigidLayoutMasterDetailsConfig<QueryGridRow> => ({
  grid: gridConfig,
  search: {
    fields: ['tag_name', 'tag_value'],
    placeholder: t('search.placeholder'),
    type: BigidGridSearchType.INTEGRATED,
  },
});
