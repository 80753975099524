import React, { FC, useContext, useEffect, useMemo } from 'react';
import { styled } from '@mui/material';
import { BigidHeading5, BigidNotes1, SecondaryButton } from '@bigid-ui/components';
import { DataSourceConfigurationContext } from '../../../DataSourceConfigurationContext';
import { mapTestConnectionResultsForNewExperience } from '../../../../DataSourceConnectionModal/mappers/connection';
import {
  DataSourceTestConnectionRowType,
  DataSourcesTestConnectionGrid,
} from '../../../../DataSourcesTestConnectionGrid/DataSourcesTestConnectionGrid';
import { useLocalTranslation } from '../../../../DataSourceConnectionModal/translations';
import { useKey } from '../../../hooks/useKey';
import { generateDataAid } from '@bigid-ui/utils';
import { useTheme } from '@mui/styles';
import { DataSourcesUITrackingEvent } from '../../../../DataSourcesEventTrackerUtils';

interface MainContentConnectedStatusProps {
  onTestAgain?: () => void;
  rows?: any[];
  rowType?: DataSourceTestConnectionRowType;
  showAction?: boolean;
  hideTitle?: boolean;
  hideMargin?: boolean;
}

enum tableResultsCellWidth {
  SMALL = '304',
  MEDIUM = '457',
  LARGE = '914',
}

type GridWrapperStyleProps = {
  noMargin?: boolean;
};

const mapRowTypeToCellWidth: { [key in DataSourceTestConnectionRowType]: tableResultsCellWidth } = {
  [DataSourceTestConnectionRowType.APP]: tableResultsCellWidth.LARGE,
  [DataSourceTestConnectionRowType.PARTITIONED_TABLE]: tableResultsCellWidth.MEDIUM,
  [DataSourceTestConnectionRowType.STRUCTURED]: tableResultsCellWidth.SMALL,
  [DataSourceTestConnectionRowType.STRUCTURED_FILE]: tableResultsCellWidth.MEDIUM,
  [DataSourceTestConnectionRowType.UNSTRUCTURED]: tableResultsCellWidth.MEDIUM,
};

const TableResultHeader = styled('div')`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 16px 20px 16px 20px;
`;

const GridWrapper = styled('div')`
  margin-right: ${({ noMargin }: GridWrapperStyleProps) => (noMargin ? '0' : '20px')};
  margin-left: ${({ noMargin }: GridWrapperStyleProps) => (noMargin ? '0' : '20px')};
`;

const DEFAULT_ROW_TYPE = DataSourceTestConnectionRowType.STRUCTURED;

export const MainContentConnectedStatus: FC<MainContentConnectedStatusProps> = ({
  onTestAgain,
  rows,
  rowType,
  showAction = true,
  hideTitle = false,
  hideMargin = false,
}) => {
  const { tablesResult, lastTestDate } = useContext(DataSourceConfigurationContext);
  const { gridRowType, tablesResult: mappedTableResults } = useMemo(() => {
    return mapTestConnectionResultsForNewExperience(tablesResult || []);
  }, [tablesResult]);
  const { t } = useLocalTranslation();
  const [key, resetKey] = useKey();
  const theme = useTheme();

  const calculatedRowType = rowType ? rowType : gridRowType ?? DEFAULT_ROW_TYPE;

  useEffect(() => {
    resetKey();
  }, [calculatedRowType]);

  return (
    <>
      {!hideTitle && (
        <TableResultHeader>
          <BigidHeading5 flexGrow={1}>{t('connectionTab.testConnectionResults')}</BigidHeading5>
          <BigidNotes1 margin="0px 12px 0px 0" color={theme.vars.palette.bigid.gray500}>
            {lastTestDate}
          </BigidNotes1>
          {showAction && (
            <SecondaryButton
              dataAid={generateDataAid('DataSourceConnectionTab', ['connected', 'test', 'again'])}
              onClick={onTestAgain}
              text={t('buttons.testAgain')}
              size="small"
              bi={{
                eventType: DataSourcesUITrackingEvent.DATA_SOURCES_NEW_CONNECTION_TAB_CONNECTED_TEST_AGAIN_CLICK,
              }}
            />
          )}
        </TableResultHeader>
      )}
      <GridWrapper noMargin={hideMargin}>
        <DataSourcesTestConnectionGrid
          key={key}
          gridRowType={calculatedRowType}
          rows={rows ?? mappedTableResults}
          cellWidth={mapRowTypeToCellWidth[calculatedRowType]}
        />
      </GridWrapper>
    </>
  );
};
