import React, { useState } from 'react';
import { BigidSendIcon } from '@bigid-ui/icons';
import { BigidLoader, BigidTooltip, BigidButtonIcon } from '@bigid-ui/components';
import { useLocalTranslation } from '../../translations';
import { mapFieldValuesToCreateDsCollaboratorPayload } from '../../mappers/collaboration';
import { CollaborationFieldAction } from '../../config/collaboration';
import { generateDataAid } from '@bigid-ui/utils';
import { notificationService } from '../../../../../services/notificationService';
import { DataSourcesUITrackingEvent, trackEventDataSources } from '../../../DataSourcesEventTrackerUtils';
import { useCreateDsCollaborator } from '../../hooks/useCreateDsCollaborator';

type DataSourceConnectionCollaborationInviteExpiredFieldProps = {
  dataAid?: string;
  sourceId: string;
  id: string;
  onResend?: () => void;
  dsTypeLabel: string;
  selectedTemplateName: string;
  selectedBrandName: string;
};

export const DataSourceConnectionCollaborationInviteExpiredField = ({
  dataAid = 'DataSourceConnectionCollaborationInviteExpiredField',
  sourceId,
  id,
  onResend,
  dsTypeLabel,
  selectedTemplateName,
  selectedBrandName,
}: DataSourceConnectionCollaborationInviteExpiredFieldProps) => {
  const { t } = useLocalTranslation();
  const [isReminderSuccessfullySent, setIsReminderSuccessfullySent] = useState(false);
  const { mutateAsync: createCollaborator, isLoading } = useCreateDsCollaborator({
    mutation: {
      onError: () => notificationService.error(t('collaboration.resendError')),
      onSuccess: () => notificationService.success(t('collaboration.resendSuccess')),
    },
  });

  const handleResendInvite = async () => {
    try {
      const payload = mapFieldValuesToCreateDsCollaboratorPayload(
        { collaborators: [{ value: id, id, displayValue: id }] },
        dsTypeLabel,
        selectedTemplateName,
        selectedBrandName,
      );
      await createCollaborator(
        {
          dataSource: sourceId,
          data: payload,
        },
        {
          onSuccess: async () => {
            trackEventDataSources(DataSourcesUITrackingEvent.DS_COLLABORATION_RESEND_INVITATION_CLICK);
            onResend?.();
            setIsReminderSuccessfullySent(true);
            notificationService.success(t('collaboration.tooltip.inviteSent'));
          },
        },
      );
    } catch {}
  };

  const isReminderBtnDisabled = !selectedTemplateName;

  return (
    <>
      {isLoading ? (
        <BigidLoader size={20} position="relative" />
      ) : (
        !isReminderSuccessfullySent && (
          <BigidTooltip
            placement="top"
            dataAid={dataAid}
            title={
              isReminderBtnDisabled
                ? t('collaboration.tooltip.chooseTemplateToResend')
                : t('collaboration.tooltip.body')
            }
          >
            <div>
              <BigidButtonIcon
                disabled={isReminderBtnDisabled}
                icon={BigidSendIcon}
                onClick={handleResendInvite}
                dataAid={generateDataAid(dataAid, ['action', CollaborationFieldAction.RESEND])}
              />
            </div>
          </BigidTooltip>
        )
      )}
    </>
  );
};
