import { module } from 'angular';
import { groupBy, map as lodashMap, uniq } from 'lodash';
import { notificationService } from '../../../react/services/notificationService';
import { EVENTS, REQUEST_TYPES } from './../sarConsts.js';
import './manualRequest.scss';
import template from './manualRequest.html';
import { getApplicationPreference } from '../../../react/services/appPreferencesService';

const isDsarUIGroupAttributeByFn = () => {
  return getApplicationPreference('DSAR_GROUP_ATTRIBUTE_BY_FRIENDLY_NAME');
};
const app = module('app');

const UNIQUE_ID_TOOLTIP = 'Unique ID is mandatory to get a "Personal Info" view';

class SarManualRequestComponent {
  constructor($element, $rootScope, $translate, $uibModal, DeleteConfirmation, subjectAccessRequestService) {
    'ngInject';

    this.$translate = $translate;
    this.$rootScope = $rootScope;
    this.$element = $element;
    this.DeleteConfirmation = DeleteConfirmation;
    this.subjectAccessRequestService = subjectAccessRequestService;

    this.inputDisplayName = undefined;
    this.inputUserId = undefined;
    this.inputAttributes = undefined;

    this.formController = undefined;

    this.isLoading = false;
    this.unregister = [];

    this.singleColumnAttrsAmount = 5;
    this.noAttributesFound = false;

    this.attributes = {
      searchable: [],
      classifications: [],
      other: [],
    };

    this.attributesSectionsConfig = {
      tags: {
        title: 'DSAR:NEW_REQUEST:PERSONAL_INFO:TAGS',
        itToggled: true,
      },
      searchable: {
        title: 'DSAR:NEW_REQUEST:PERSONAL_INFO:SEARCHABLE_ATTRIBUTES',
        isToggled: true,
      },
      other: {
        title: 'DSAR:NEW_REQUEST:PERSONAL_INFO:OTHER_ATTRIBUTES',
        isToggled: true,
      },
      classifications: {
        title: 'DSAR:NEW_REQUEST:PERSONAL_INFO:CLASSIFICATION',
        isToggled: true,
      },
    };

    this.deleteTaggedEntity = () => {
      const taggedEntity = {};
      taggedEntity.tagIds = this.tagsModel.filter(tag => tag._id).map(tag => tag._id);
      taggedEntity.entityId = this.inputUserId;
      taggedEntity.entityName = this.inputDisplayName;
      subjectAccessRequestService
        .createTaggedEntity(taggedEntity)
        .then(() => this.$translate('API:MESSAGE:COMMON_POST_SUCCESS'))
        .then(translation => notificationService.success(translation))
        .catch(err => notificationService.error(err));
    };

    this.getAndPrepareAttributes = () => {
      if (!this.profileSelected?._id) {
        return;
      }
      this.isLoading = true;
      this.subjectAccessRequestService
        .getAndAggregateIDSORAttributes(this.profileSelected._id)
        .then(aggregatedAttributes => {
          // prepare attributes for the view
          // save original attribute data in `data` property to avoid any mutations on it
          // create `ngModel` property per each attribute and use it in `ng-model` attribute
          const [searchable, other, classifications] = [
            aggregatedAttributes.searchable,
            aggregatedAttributes.other,
            aggregatedAttributes.classifications,
          ].map(sectionAttributes =>
            sectionAttributes.map(attr => ({
              data: attr,
              ngModel: undefined,
              uiValue: attr.original_name,
            })),
          );

          this.attributes = {
            searchable,
            classifications,
            other,
          };

          this.noAttributesFound = searchable.length + other.length + classifications.length === 0;
          this.groupAttributesByFriendlyNames();
          this.initNgModelFromInput(this.inputAttributes);
          this.notifyIsSubmitEnabled();
        })
        .catch(err => {
          console.error(err);
          notificationService.error(`Failed fetching attributes for profile: ${this.profileSelected?.name}`);
        })
        .finally(() => {
          this.isLoading = false;
        });
    };

    this.onProfileSelect = profileSelected => {
      this.profileSelected = profileSelected;
      this.getAndPrepareAttributes();
    };

    this.openAddTagsModal = selectedTags => {
      $uibModal
        .open({
          template:
            "<add-tag-modal selected-tags='$ctrl.selectedTags' is-tags-identical-for-all='true'" +
            " $close='$close(selectedTags)' $dismiss='$dismiss()'><add-tag-modal/>",
          windowClass: 'edit-tags',
          controllerAs: '$ctrl',
          controller: [
            '$scope',
            '$uibModalInstance',
            function ($scope, $uibModalInstance) {
              this.selectedTags = selectedTags;
            },
          ],
          resolve: {
            selectedTags: () => {
              return selectedTags;
            },
          },
        })
        .result.then(
          selectedTags => {
            const filterSelectedTags = selectedTags.filter(tag => tag._id);
            const taggedEntity = {};
            taggedEntity.tagIds = filterSelectedTags.map(tag => tag._id);
            taggedEntity.entityId = this.inputUserId;
            taggedEntity.entityName = this.inputDisplayName;
            subjectAccessRequestService.createTaggedEntity(taggedEntity);
            this.tagsModel = filterSelectedTags;
            this.$translate('API:MESSAGE:COMMON_POST_SUCCESS')
              .then(translation => notificationService.success(translation))
              .catch(err => notificationService.error(err));
          },
          () => {
            //dismiss
          },
        );
    };

    this.tagsModel = [];
    /** FormController will validate these fields while they marked as required in the form */
    this.formCustomFields = {
      displayName: {
        value: undefined,
        isInvalid: () => this.formController.displayName.$error.required && this.formController.displayName.$dirty,
      },
      userId: {
        value: undefined,
      },
    };

    this.tooltips = {
      uniqueId: UNIQUE_ID_TOOLTIP,
    };
    this.isTaggedEntitiesSupported = getApplicationPreference('ENTITY_LEVEL_TAGGING_SUPPORTED');
  }

  $onInit() {
    if (this.profiles) {
      this.profileSelected =
        this.newSarProfileSelected || this.profiles.find(profile => !profile.isCustom) || this.profiles[0];
    }
    this.getAndPrepareAttributes();

    this.unregister.push(
      this.$rootScope.$on(EVENTS.callToSubmitSarRequest, (event, { type }) => {
        if (type === REQUEST_TYPES.manual && this.canSubmit()) {
          this.submit();
        }
      }),
    );
  }

  $onChanges(changesObj) {
    if (changesObj.inputAttributes && !changesObj.inputAttributes.isFirstChange()) {
      this.resetAttributesNgModel();
      this.initNgModelFromInput(changesObj.inputAttributes.currentValue);
    }

    this.notifyIsSubmitEnabled();

    const { profiles } = changesObj;
    if (profiles && !profiles.previousValue && profiles.currentValue) {
      this.profileSelected = profiles.find(profile => !profile.isCustom) || profiles[0];
    }
  }

  resetAttributesNgModel() {
    Object.values(this.attributes)
      .flat()
      .forEach(attr => (attr.ngModel = undefined));
  }

  initNgModelFromInput(inputAttributes) {
    if (!Array.isArray(inputAttributes)) {
      return;
    }

    const inputAttributesMap = new Map(
      inputAttributes
        .filter(({ value }) => isValidAttributeValue(value))
        .map(({ originalName, value }) => [originalName, value]),
    );

    if (!isDsarUIGroupAttributeByFn()) {
      Object.values(this.attributes)
        .flat()
        .forEach(attr => {
          if (inputAttributesMap.has(attr.data.original_name)) {
            attr.ngModel = inputAttributesMap.get(attr.data.original_name);
          }
        });
    } else {
      Object.values(this.attributes)
        .flat()
        .forEach(attr => {
          const ngModel = [...inputAttributesMap]
            .filter(([key]) => attr.originalNames.includes(key))
            .map(([, value]) => value);
          attr.ngModel = uniq(ngModel).join('|');
        });
    }
  }

  groupAttributesByFriendlyNames = () => {
    if (isDsarUIGroupAttributeByFn()) {
      // grouped by friendly_name
      Object.keys(this.attributes).forEach(att => {
        const grouped = groupBy(this.attributes[att], 'data.friendly_name');
        this.attributes[att] = lodashMap(grouped, (val, friendly_name) => {
          const { data } = val[0];
          data._id = friendly_name;
          const originalNames = uniq(val.map(x => x.data.original_name));
          const uiValue = originalNames.join(',');
          return { data, uiValue, originalNames };
        });
      });
    }
  };

  removeCachedFromName = displayName => {
    const cached = ' (cached)';
    return displayName.replace(cached, '');
  };

  async submit() {
    if (!this.formController.$valid) {
      return;
    }
    // filter all undefined attributes
    const requestAttributes = this.getRequestAttributes();
    const isDsarUIGroupAttribute = isDsarUIGroupAttributeByFn();
    const jitScanData = {
      userDetails: {
        attributes: requestAttributes
          // convert attributes to { key: value } pairs
          .map(attribute => {
            const key = isDsarUIGroupAttribute ? attribute.data.friendly_name : attribute.data.original_name;
            return { [key]: attribute.ngModel };
          })
          // join all pairs into one object
          .reduce((attributes, keyVal) => Object.assign(attributes, keyVal), {}),
      },
    };

    // handle specific attributes with special care
    if (this.inputUserId) {
      jitScanData.userDetails.userId = this.inputUserId;
      jitScanData.userDetails.displayName = this.removeCachedFromName(this.inputDisplayName || this.inputUserId);
    } else if (this.formCustomFields.displayName.value) {
      jitScanData.userDetails.displayName = this.formCustomFields.displayName.value;
      jitScanData.userDetails.userId = this.formCustomFields.userId.value
        ? this.formCustomFields.userId.value
        : this.formCustomFields.displayName.value;
    }

    this.subjectAccessRequestService.showSubmitSarRequestModalWithProfile(this.profileSelected).then(() => {
      this.onSubmit({
        jitScanData: { ...jitScanData, profileId: this.profileSelected._id },
      });
    });
  }

  $onDestroy() {
    this.unregister.forEach(unreg => unreg());
    this.unregister = null;
  }

  toggleAttributesSection(sectionName) {
    this.attributesSectionsConfig[sectionName].isToggled = !this.attributesSectionsConfig[sectionName].isToggled;
  }

  canSubmit() {
    return (
      !this.isLoading &&
      this.formController &&
      this.formController.$valid &&
      this.profileSelected &&
      (this.attributes.searchable.some(({ ngModel }) => ngModel) ||
        this.attributes.classifications.some(({ ngModel }) => ngModel) ||
        this.attributes.other.filter(({ ngModel }) => ngModel).length > 1 ||
        this.formCustomFields.userId.value)
    );
  }

  getRequestAttributes() {
    return [...this.attributes.searchable, ...this.attributes.other, ...this.attributes.classifications].filter(attr =>
      isValidAttributeValue(attr.ngModel),
    );
  }

  notifyIsSubmitEnabled() {
    this.onSubmitIsEnabled({ event: { isEnabled: this.canSubmit() } });
  }
}

/**
 * Exclude attributes with these values from the jit_scan, otherwise the report may fail !!!
 */
function isValidAttributeValue(value) {
  return value !== undefined && value !== '' && value !== null;
}

app.component('sarManualRequest', {
  template,
  controller: SarManualRequestComponent,
  bindings: {
    inputUserId: '<',
    inputDisplayName: '<',
    inputAttributes: '<',
    showSubmitHeader: '<',
    showUserIdField: '<',
    onSubmit: '&',
    onSubmitIsEnabled: '&',
    profiles: '<',
    newSarProfileSelected: '<',
  },
});
