import { module } from 'angular';
import { httpService } from '../../../react/services/httpService';
const app = module('app');

app.factory('systemUsersService', [
  '$http',
  'appSettings',
  function ($http, appSettings) {
    return {
      getAllSystemUsers: function getAllSystemUsers() {
        return $http.get(`${appSettings.serverPath}/${appSettings.version}/system_users`).then(function (response) {
          return response;
        });
      },
      getAllSystemUsersByQuery: function getAllSystemUsers(query) {
        return $http
          .get(`${appSettings.serverPath}/${appSettings.version}/system-users${query ? `?${query}` : ''}`)
          .then(function (response) {
            return response?.data;
          });
      },
      getSystemUserById: function getSystemUserById(id) {
        return $http
          .get(appSettings.serverPath + '/' + appSettings.version + '/system_users/' + id)
          .then(function (response) {
            return response;
          });
      },
      getAuthUser: function getAuthUser() {
        return $http.get(appSettings.serverPath + '/' + appSettings.version + '/sessions/me').then(function (response) {
          return response;
        });
      },
      updateSystemUserById: function updateSystemUserById(id, data) {
        return $http
          .put(appSettings.serverPath + '/' + appSettings.version + '/system_users/' + id, data)
          .then(function (response) {
            return response;
          });
      },
      createSystemUser: function createSystemUser(data) {
        return $http
          .post(appSettings.serverPath + '/' + appSettings.version + '/system_users', data)
          .then(function (response) {
            return response;
          });
      },
      deleteSystemUserById: function deleteSystemUserById(id) {
        return $http
          .delete(appSettings.serverPath + '/' + appSettings.version + '/system_users/' + id)
          .then(function (response) {
            return response;
          });
      },
      generateRefreshToken: function generateRefreshToken(userId, expiresInDays) {
        return httpService
          .post(`system_users/${userId}/generate-refresh-token/${expiresInDays}`, null)
          .then(function (response) {
            return response;
          });
      },
    };
  },
]);
