import { EndingConditionType, FrequencyType, RecurringSchedule } from '@bigid-ui/components';

export const getDefaultScheduleRecurrence: () => RecurringSchedule = () => ({
  startDate: new Date(),
  frequency: FrequencyType.Daily,
  numberOfRepetitions: 1,
  weeklyRecurrence: [],
  monthlyRecurrence: null,
  monthlyRecurrenceDate: null,
  monthlyRecurrencePatternDay: null,
  monthlyRecurrencePatternCount: null,
  endingCondition: EndingConditionType.None,
  endDate: null,
  endingOccurrencesNumber: 1,
});
