import React, { FC, ReactNode, useEffect, useMemo, useState } from 'react';
import { useMutation } from 'react-query';
import { BigidHeading5, EntityEvents, entityEventsEmitter, TertiaryButton } from '@bigid-ui/components';
import { BigidGridColumn, BigidGridColumnTypes, BigidGridProps, BigidGrid, useFetch } from '@bigid-ui/grid';
import { BigidDeleteIcon, BigidRefreshIcon } from '@bigid-ui/icons';
import { dateTimeService } from '@bigid-ui/i18n';
import { RefreshTokenDialog } from '../../components/RefreshTokenDialog/RefreshTokenDialog';
import { UserToken } from '../../types';
import { generateRefreshToken } from '../../accessManagementService';
import { DATE_FORMAT } from './constants';
import { EditUserFormSection } from './EditUserPanel/styles';
import { useLocalTranslation } from './translations';
import { HeadingAndControlsBlock } from './styles';

interface TokenPickerState {
  isOpen: boolean;
  mode: 'new' | 'preview';
  token?: UserToken;
  expiresInDays: string;
}

interface TokensPreviewProps {
  title: string;
  tokens: UserToken[];
  onDeleteToken: (id: string) => void;
  onGeneratedNewToken: (token: UserToken) => void;
  userId: string;
  isEditable: boolean;
  children?: ReactNode;
  gridId?: string;
}

interface GridUserToken extends UserToken {
  id: string;
}

export const TokenPreview: FC<TokensPreviewProps> = ({
  children,
  tokens,
  title,
  userId,
  isEditable,
  onGeneratedNewToken,
  onDeleteToken,
  gridId = 'tokensPreviewGrid',
}) => {
  const { t } = useLocalTranslation('dialogs.createUser');
  const [tokenPickerState, setTokenPickerState] = useState<TokenPickerState>({
    isOpen: false,
    token: null,
    mode: 'new',
    expiresInDays: '',
  });

  useEffect(() => {
    entityEventsEmitter.emit(EntityEvents.RELOAD, gridId);
  }, [tokens, gridId]);

  const useFetchState = useFetch({
    fetchDataFunction: async () => {
      return Promise.resolve({
        data: tokens.map(token => ({ ...token, id: token.tokenName })),
        totalCount: tokens.length,
      });
    },
  });

  const columns = useMemo<BigidGridColumn<GridUserToken>[]>(
    () => [
      {
        name: 'tokenName',
        title: t('tokenGrid.tokenName'),
        width: 185,
        sortingEnabled: false,
        getCellValue: ({ tokenName }) => tokenName,
        type: BigidGridColumnTypes.TEXT,
      },
      {
        name: 'creationDate',
        title: t('tokenGrid.creationDate'),
        width: 185,
        sortingEnabled: false,
        getCellValue: ({ creationDate }) => dateTimeService.formatDate(creationDate, DATE_FORMAT),
        type: BigidGridColumnTypes.TEXT,
      },
      {
        name: 'expirationDate',
        title: t('tokenGrid.expirationDate'),
        width: 185,
        sortingEnabled: false,
        getCellValue: ({ expirationDate }) => dateTimeService.formatDate(expirationDate, DATE_FORMAT),
        type: BigidGridColumnTypes.TEXT,
      },
    ],
    [t],
  );

  const generateTokenMutation = useMutation({
    mutationFn: generateRefreshToken,
    onSuccess: ({ token, expiresInDays }) => {
      setTokenPickerState({ ...tokenPickerState, mode: 'preview', token, expiresInDays, isOpen: true });
    },
  });

  const onCopyTokenValue = () => {
    navigator.clipboard.writeText(tokenPickerState.token.value);
  };

  const tokensGridConfig: BigidGridProps<GridUserToken> = {
    columns,
    showFilteringControls: false,
    forceSelectAll: false,
    showSelectAll: false,
    showSortingControls: false,
    inlineActions: [
      {
        disable: () => false,
        hideActionInToolBar: true,
        execute: async ({ selectedRows }) => {
          const { tokenName } = selectedRows[0];
          onDeleteToken(tokenName);
          return {
            shouldClearSelection: true,
          };
        },
        icon: BigidDeleteIcon,
        isInline: true,
        label: t('actions.delete'),
        show: () => isEditable,
      },
    ],
    showSelectionColumn: false,
    showResizingControls: true,
    rows: useFetchState.rows,
    skip: useFetchState.skip,
    totalRowsCount: useFetchState.totalRowsCount,
    apiRef: useFetchState.apiRef,
    loading: useFetchState.isLoading,
    onPagingChanged: useFetchState.onPagingChanged,
    onSortingChanged: useFetchState.onSortingChanged,
    onFiltersChange: useFetchState.onFiltersChanged,
    isRowDisabled: () => false,
    showSelectionCheckboxes: false,
    gridId: gridId,
  };

  const onNewToken = () => {
    setTokenPickerState({ token: null, mode: 'new', expiresInDays: '', isOpen: true });
  };

  const onCloseTockenPicker = () => {
    if (tokenPickerState.mode === 'preview') {
      onGeneratedNewToken(tokenPickerState.token);
    }
    setTokenPickerState({
      isOpen: false,
      token: null,
      mode: 'new',
      expiresInDays: '',
    });
  };

  const onRequestToGenerateTokens = (expiresInDays: string) => {
    setTokenPickerState({ ...tokenPickerState, expiresInDays });
    generateTokenMutation.mutate({ userId, expiresInDays });
  };

  const getRefreshTokenData = () => {
    if (tokenPickerState.mode === 'preview') {
      return {
        tokenName: tokenPickerState.token.tokenName,
        value: tokenPickerState.token.value,
        expiresInDays: tokenPickerState.expiresInDays,
      };
    }

    return null;
  };

  return (
    <EditUserFormSection>
      <RefreshTokenDialog
        isOpen={tokenPickerState.isOpen}
        onClose={onCloseTockenPicker}
        onGenerate={onRequestToGenerateTokens}
        onCopy={onCopyTokenValue}
        translations={undefined}
        refreshTokenData={getRefreshTokenData()}
      />
      <HeadingAndControlsBlock>
        <BigidHeading5>{title}</BigidHeading5>
        {isEditable && (
          <TertiaryButton
            onClick={onNewToken}
            size="medium"
            text={t('actions.addToken')}
            startIcon={<BigidRefreshIcon />}
          />
        )}
      </HeadingAndControlsBlock>
      {tokens.length > 0 ? <BigidGrid {...tokensGridConfig} /> : children}
    </EditUserFormSection>
  );
};
