import makeStyles from '@mui/styles/makeStyles';
import { BigidColorsV2 } from '@bigid-ui/components';
import { MetadataSearchBarProps } from './MetadataSearchBar';
import { MetadataSearchVariant } from '../MetadataSearch';

type MetadataSearchBarStyle = Pick<
  MetadataSearchBarProps,
  'isReady' | 'baseBackgroundColor' | 'focusedBorderColor' | 'variant'
> & {
  isFocused: boolean;
};

export const useStyles = makeStyles(theme => ({
  root: ({ isFocused, isReady, baseBackgroundColor, focusedBorderColor, variant }: MetadataSearchBarStyle) => {
    const defaultBackgroundColor = baseBackgroundColor || theme.palette.bigid.gray50;
    const backgroundColor = !isReady
      ? BigidColorsV2.gray[200]
      : isFocused
      ? BigidColorsV2.white
      : defaultBackgroundColor;

    const computedBoxShadow = `0px 0px 3px ${focusedBorderColor ?? `rgba(0, 0, 0, 0.25)`}`;
    const isEnlarged = variant === MetadataSearchVariant.ENLARGED;

    return {
      position: 'relative',
      height: '100%',
      display: 'flex',
      flexWrap: 'nowrap',
      alignItems: 'center',
      backgroundColor,
      boxShadow: isFocused ? computedBoxShadow : 'none',
      padding: isEnlarged ? '0px 16px' : '0px 4px',
      pointerEvents: isReady ? 'all' : 'none',
      opacity: isReady ? 1 : 0.5,
    };
  },
  control: {
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',
    '&:not(:last-child)': {
      marginRight: '4px',
    },
  },
  actionControl: {
    cursor: 'pointer',
  },
  controlInput: {
    flex: 1,
  },
  actionControlIcon: {
    width: '24px',
    height: '24px',
  },
  buttonControl: {
    '&:not(:last-child)': {
      marginRight: '4px',
    },
  },
  activeFilterIndicator: {
    height: '24px',
    width: '24px',
    borderRadius: '3px',
    backgroundColor: BigidColorsV2.gray[700],
  },
  activeFilterIndicatorText: {
    color: BigidColorsV2.white,
  },
  hint: {
    display: 'flex',
    alignItems: 'center',
  },
  hintText: {
    marginLeft: '4px',
  },
  divider: {
    height: 16,
    marginRight: '5px',
  },
}));
