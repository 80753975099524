import React, { ChangeEvent, FC, memo, ReactText, useState } from 'react';
import styled from '@emotion/styled';
import {
  BigidButtonIcon,
  BigidDialog,
  BigidRadioGroup,
  BigidTextField,
  BigidTooltip,
  PrimaryButton,
  SecondaryButton,
} from '@bigid-ui/components';
import { BigidSyncIcon } from '@bigid-ui/icons';
import { CuratedFieldStatus, FindingCurateProps, FindingDetails, MatchType } from '../../curationService';
import { CurationEvents, trackEventCurationView } from '../../curationEventTrackerUtils';
import { useLocalTranslation } from '../../translations';
import { generateDataAid } from '@bigid-ui/utils';

const RadioGroupWrapper = styled('div')`
  margin-bottom: 25px;
`;

const Tooltip = styled(BigidTooltip)`
  position: absolute;
  right: 4px;
  bottom: 4px;
`;

const TextFieldWrapper = styled('div')`
  position: relative;
`;

interface CuratedFieldValuePreviewDialogProps {
  dataAid: string;
  finding: FindingDetails;
  onDialogStateChange: (isOpen: boolean) => void;
  isOpen: boolean;
  excludeSelectedOption: MatchType;
  onExcludeOptionSelected: (option: MatchType) => void;
  fieldId?: ReactText;
  onFindingCurate: (findingCurateProps: FindingCurateProps) => void;
}

export const CuratedFieldValuePreviewExcludeDialog: FC<CuratedFieldValuePreviewDialogProps> = memo(
  ({
    finding,
    onDialogStateChange,
    isOpen,
    excludeSelectedOption,
    onExcludeOptionSelected,
    fieldId,
    onFindingCurate,
    dataAid,
  }) => {
    const [findingValue, setFindingValue] = useState(finding.findingValue);

    const { t } = useLocalTranslation('CuratedFields.CuratedFieldValuePreviewDialog');

    const handleExcludeListChange = () => {
      const updatedFinding = { ...finding, findingValue };
      const findingCurateProps = {
        finding: updatedFinding,
        status: CuratedFieldStatus.REJECTED,
        fieldId,
        matchType: excludeSelectedOption,
      };
      onFindingCurate(findingCurateProps);
      trackEventCurationView(CurationEvents.CURATION_FIELDS_ATTRIBUTE_PREVIEW_EXCLUDE_VALUE);
      handleCloseDialog();
    };

    const handleCloseDialog = () => {
      onDialogStateChange(false);
    };

    const handleRadioButtonChange = ({ target }: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
      onExcludeOptionSelected(target.value as MatchType);
    };

    const handleTextFieldChange = ({ target }: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
      setFindingValue(target.value);
    };

    const handleOnIconClick = () => {
      setFindingValue(finding.findingValue);
    };

    return (
      <BigidDialog
        isOpen={isOpen}
        onClose={handleCloseDialog}
        title={`${t('excludeValue')}`}
        showCloseIcon
        data-aid={generateDataAid(dataAid, ['excludeValueDialog'])}
        buttons={[
          {
            text: `${t('AddToExcludeList')}`,
            component: PrimaryButton,
            onClick: handleExcludeListChange,
            dataAid: generateDataAid(dataAid, ['addToExcludeListDialog']),
          },
          {
            text: `${t('cancel')}`,
            component: SecondaryButton,
            onClick: handleCloseDialog,
            isClose: true,
            dataAid: generateDataAid(dataAid, ['exitExcludeListDialog']),
          },
        ]}
      >
        <RadioGroupWrapper>
          <BigidRadioGroup
            defaultValue={excludeSelectedOption}
            horizontal
            onChange={handleRadioButtonChange}
            options={[
              {
                label: `${t('contains')}`,
                value: MatchType.CONTAINS,
                dataAid: generateDataAid(dataAid, ['contains']),
              },
              {
                label: `${t('startsWith')}`,
                value: MatchType.STARTS_WITH,
                dataAid: generateDataAid(dataAid, ['startsWith']),
              },
              {
                label: `${t('endsWith')}`,
                value: MatchType.ENDS_WITH,
                dataAid: generateDataAid(dataAid, ['endsWith']),
              },
            ]}
          />
        </RadioGroupWrapper>
        <TextFieldWrapper>
          <BigidTextField
            label={`${t('excludeValue')}`}
            value={findingValue}
            onChange={handleTextFieldChange}
            size="medium"
            type="text"
            dataAid={generateDataAid(dataAid, ['excludeValueTextField'])}
          />
          <Tooltip title={t('restoreValue')} dataAid={generateDataAid(dataAid, ['restoreValueTooltip'])}>
            <BigidButtonIcon
              icon={BigidSyncIcon}
              onClick={handleOnIconClick}
              dataAid={generateDataAid(dataAid, ['restoreValueIcon'])}
              size="small"
            />
          </Tooltip>
        </TextFieldWrapper>
      </BigidDialog>
    );
  },
);
