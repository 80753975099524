import React, { ComponentType, FC, SVGProps } from 'react';
import { BigidMenuItemProps } from '@bigid-ui/components';
import { InteractiveDashboardCard } from './InteractiveDashboardCard';
import { BigidListWithActions } from './components/BigidListWithActions/BigidListWithActions';
import { InteractiveDashboardHeadingWithDropdown } from './InteractiveDashboardWidgetHeader';
import { IconComponentProps } from '@bigid-ui/icons';
import { useSelectedRowsWithActions } from './useSelectedRowsWithActions';
import { InteractiveDashboardNoData } from './InteractiveDashboardNoData';

export interface InteractiveDashboardListRow {
  name: string;
  value: number;
  valueLabel?: string;
  color?: string;
  tooltip?: {
    left?: string;
    right?: string;
    bottom?: string;
  };
}

export interface RowWithActions {
  row: InteractiveDashboardListRow;
  actions: BigidMenuItemProps[];
}

export interface InteractiveDashboardListWidgetProps {
  title: string;
  rowsWithActions: RowWithActions[] | { [key: string]: RowWithActions[] };
  titleActions?: BigidMenuItemProps[] | { [key: string]: BigidMenuItemProps[] };
  icon?: ComponentType<IconComponentProps & SVGProps<SVGSVGElement>>;
}

export const InteractiveDashboardListWidget: FC<InteractiveDashboardListWidgetProps> = ({
  title,
  rowsWithActions,
  titleActions,
  icon,
}) => {
  const { selectedTab, setSelectedTab, selectedRowsWithActions } =
    useSelectedRowsWithActions<RowWithActions>(rowsWithActions);

  return (
    <InteractiveDashboardCard>
      <InteractiveDashboardHeadingWithDropdown
        rowsWithActions={rowsWithActions}
        setSelectedTab={setSelectedTab}
        selectedTab={selectedTab}
        titleActions={titleActions}
        title={title}
      />

      {selectedRowsWithActions?.length === 0 && <InteractiveDashboardNoData />}
      {selectedRowsWithActions?.length > 0 && (
        <BigidListWithActions rowsWithActions={selectedRowsWithActions} icon={icon} />
      )}
    </InteractiveDashboardCard>
  );
};
