import { BigidLoader, BigidTextField, PrimaryButton, SecondaryButton } from '@bigid-ui/components';
import { FormControl } from '@mui/material';
import React, { FC, useEffect, useState } from 'react';
import { notificationService } from '../../../../services/notificationService';
import { openSystemDialog, SystemDialogContentProps } from '../../../../services/systemDialogService';
import { DEFAULT_TEMPLATE_NAME } from '../consts';
import { createTemplate, getAllTemplates, getDefaultTemplateModel } from '../reportTemplatesService';

export interface DialogState {
  templateName: string;
  isValid: boolean;
  closeDialog: () => void;
}

export interface CreateReportTemplateDialogProps {
  initialValue: string;
  onStateChange: (state: DialogState) => void;
}

const RESERVED_NAMES = [DEFAULT_TEMPLATE_NAME.toLowerCase()];

export const CreateReportTemplateDialogContent: FC<SystemDialogContentProps<CreateReportTemplateDialogProps>> = ({
  initialValue,
  onStateChange,
  onClose: closeDialog,
}) => {
  const [allNames, setAllNames] = useState<string[]>();
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    const initAllNames = async () => {
      const allNames = (await getAllTemplates(['templateName'])).map(({ templateName }) => templateName.toLowerCase());
      setAllNames(allNames);
    };
    initAllNames();
  }, []);

  const isInvalidName = (name: string) => {
    return !name?.length;
  };

  const isNameTaken = (name: string) => allNames.includes(name);

  const handleNameChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    if (!allNames) {
      return;
    }
    const templateName = event?.target?.value.trim();
    const templateLowerCased = templateName.toLowerCase();

    onStateChange({ templateName: '', isValid: false, closeDialog });
    if (isInvalidName(templateLowerCased)) {
      setErrorMessage(`Name is empty`);
    } else if (isNameTaken(templateLowerCased) || RESERVED_NAMES.includes(templateLowerCased)) {
      setErrorMessage(`Name already exist`);
    } else {
      setErrorMessage('');
      onStateChange({ templateName, isValid: true, closeDialog });
    }
  };

  return !allNames ? (
    <BigidLoader />
  ) : (
    <div>
      <FormControl fullWidth margin="normal" style={{ minHeight: 80 }}>
        <BigidTextField
          autoFocus
          label={'Report Template name'}
          defaultValue={initialValue}
          onChange={handleNameChange}
          errorMessage={errorMessage}
          name={'report-template-name'}
        />
      </FormControl>
    </div>
  );
};

export const openCreateReportTemplateDialog = async (initialValue = '') => {
  return new Promise<{ newTemplateId: string; wasCreated: boolean }>(resolve => {
    let stateRef: DialogState;
    let isCreateInProgress = false;
    openSystemDialog({
      title: 'Create new Template',
      content: CreateReportTemplateDialogContent,
      contentProps: {
        initialValue,
        onStateChange: dialogState => {
          stateRef = dialogState;
        },
      },
      onClose: () => resolve({ newTemplateId: '', wasCreated: false }),
      buttons: [
        {
          text: 'Cancel',
          component: SecondaryButton,
          onClick: () => null,
          isClose: true,
        },
        {
          text: 'Create',
          component: PrimaryButton,
          onClick: async () => {
            if (isCreateInProgress) {
              return;
            }
            const { isValid, templateName, closeDialog } = stateRef;
            if (isValid && templateName && closeDialog) {
              isCreateInProgress = true;
              try {
                const { userDisplayInfo } = getDefaultTemplateModel();
                const { id } = await createTemplate({ templateName, userDisplayInfo });
                resolve({ newTemplateId: id, wasCreated: true });
                closeDialog();
              } catch (err) {
                const errMessage = `Failed to create report template, err: ${err}`;
                console.error(errMessage);
                notificationService.error(errMessage, err);
                isCreateInProgress = false;
              }
            }
          },
          isClose: false,
        },
      ],
    });
  });
};
