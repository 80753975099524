import React, { useState, useCallback } from 'react';
import {
  PrimaryButton,
  SecondaryButton,
  BigidDialog,
  ActionData,
  BigidFieldFilter,
  entityEventsEmitter,
  EntityEvents,
} from '@bigid-ui/components';
import { deleteAttachedFile, deleteAttachedFiles } from './vendorsService';
import { notificationService } from '../../services/notificationService';
import { VendorsTrackingEvents } from './utils/analytics';

export interface VendorAttachedFilesDeleteDialogProps {
  isOpen: boolean;
  vendorProjectId: string;
  actionData: ActionData;
  isInlineAction: boolean;
  onClose?: () => void;
  onDelete?: () => void;
}

export const VendorAttachedFilesDeleteDialog = ({
  isOpen,
  vendorProjectId,
  actionData,
  isInlineAction,
  onClose,
  onDelete,
}: VendorAttachedFilesDeleteDialogProps) => {
  const handleDelete = async () => {
    try {
      const result = isInlineAction
        ? await deleteAttachedFile(vendorProjectId, actionData?.selectedRows[0]?.id)
        : await deleteAttachedFiles(vendorProjectId, getQuery(actionData));
      entityEventsEmitter.emit(EntityEvents.RELOAD);
      const message = isInlineAction
        ? `Deleted attachment successfully.`
        : `Deleted ${result.deletedCount} attachments successfully.`;
      notificationService.info(message);
      onDelete?.();
    } catch (err) {
      notificationService.error(`Deleting attached file failed!`);
      console.error(`Could not delete attached files ${actionData.selectedRowIds.join(', ')}`);
    }
  };

  return (
    <BigidDialog
      isOpen={isOpen}
      title="Delete File"
      onClose={onClose}
      maxWidth="xs"
      borderTop
      buttons={[
        {
          component: props => (
            <SecondaryButton
              {...props}
              bi={{
                eventType: VendorsTrackingEvents.DELETE_ATTACHED_FILE_DIALOG_CANCEL_CLICK,
              }}
            />
          ),
          onClick: onClose,
          text: 'Cancel',
        },
        {
          component: props => (
            <PrimaryButton
              {...props}
              bi={{
                eventType: VendorsTrackingEvents.DELETE_ATTACHED_FILE_DIALOG_DELETE_CLICK,
              }}
            />
          ),
          onClick: handleDelete,
          text: 'Delete',
        },
      ]}
    >
      <p>{getDialogMessage(actionData)}</p>
    </BigidDialog>
  );
};

export interface VendorAttachedFilesDeleteDialog {
  deleteDialogProps: VendorAttachedFilesDeleteDialogProps;
  openDeleteDialog: (vendorProjectId: string, actionData: ActionData, isInlineAction: boolean) => Promise<boolean>;
}

const importInitialState: VendorAttachedFilesDeleteDialogProps = {
  isOpen: false,
  vendorProjectId: null,
  actionData: null,
  isInlineAction: false,
};

export const useVendorAttachedFilesDeleteDialog = (): VendorAttachedFilesDeleteDialog => {
  const [deleteDialogProps, setDeleteDialogProps] = useState<VendorAttachedFilesDeleteDialogProps>(importInitialState);

  const openDeleteDialog = useCallback<VendorAttachedFilesDeleteDialog['openDeleteDialog']>(
    (vendorProjectId: string, actionData: ActionData, isInlineAction) => {
      return new Promise<boolean>(resolve => {
        setDeleteDialogProps({
          isOpen: true,
          vendorProjectId,
          actionData,
          isInlineAction,
          onDelete: () => {
            setDeleteDialogProps(importInitialState);
            resolve(true);
          },
          onClose: () => setDeleteDialogProps(importInitialState),
        });
      });
    },
    [],
  );

  return {
    openDeleteDialog,
    deleteDialogProps,
  };
};

const getDialogMessage = (actionData: ActionData) => {
  const message = 'Are you sure you want to delete';

  const allSelected = actionData?.allSelected;
  if (allSelected) {
    return `${message} all files?`;
  }

  const selectedRowsCount = actionData?.selectedRows?.length;
  if (selectedRowsCount === 1) {
    return `${message} ${actionData?.selectedRows[0]?.fileName}?`;
  }

  return `${message} ${selectedRowsCount} files?`;
};

export const getQuery = ({ selectedRowIds, filter = [], allSelected }: ActionData) => {
  return {
    filter: allSelected
      ? filter
      : [
          ...filter,
          {
            field: '_id',
            operator: 'in',
            value: selectedRowIds,
          } as BigidFieldFilter,
        ],
  };
};
