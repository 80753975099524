import { useEffect, useState } from 'react';
import { $translate } from '../../services/angularServices';

export const useNgTranslate = (toTranslateStrings: string[]) => {
  const [translations, setTranslations] = useState<Record<string, string>>();

  useEffect(() => {
    const lazyTranslate = async () => {
      try {
        const translatedObj = await $translate(toTranslateStrings);
        setTranslations(translatedObj);
      } catch (err) {
        console.error(`useNgTranslate hooks failed with err: ${err}`);
        throw err;
      }
    };
    lazyTranslate();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return translations;
};
