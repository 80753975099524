import React, { FC, MouseEventHandler, SyntheticEvent, useState } from 'react';
import {
  AccordionSummarySizeEnum,
  BigidAccordion,
  BigidAccordionDetails,
  BigidAccordionSummary,
  StyledButtonType,
  BigidBody1,
} from '@bigid-ui/components';
import Markdown from 'react-markdown';
import { Typography } from '@mui/material';
import { ErrorInsights } from './mappers/styles';
import { useLocalTranslation } from './translations';
import { MARKDOWN_SELECTOR } from './constants/constants';
import { generateDataAid } from '@bigid-ui/utils';
import { getMarkdownComponentOverrides } from './config/markdown';
import { isNil } from 'lodash';
import { BigidDislikeFilledIcon, BigidLikeFilledIcon } from '@bigid-ui/icons';

type DataSourceConnectionErrorInsightsOptionProps = {
  dataAid?: string;
  title: string;
  subtitle: string;
  content: string;
  controls: ButtonControls[];
  id: string;
  className?: string;
};

type ButtonControls = StyledButtonType & { component: FC<StyledButtonType> };

type DataSourceConnectionErrorInsightsRating = [isRatedUp: boolean, isRatedDown: boolean];

const isRateSolutionDisabled = false;

const components = getMarkdownComponentOverrides();

const mapControlPositionToRating = (position: number): boolean => !position;

export const DataSourceConnectionErrorInsightsOption = ({
  dataAid = 'DataSourceConnectionErrorInsightsOption',
  title,
  subtitle,
  content,
  controls,
  id,
}: DataSourceConnectionErrorInsightsOptionProps) => {
  const [isExpanded, setExpanded] = useState(false);
  const [rating, setRating] = useState<DataSourceConnectionErrorInsightsRating>([false, false]);
  const [solutionRating, setSolutionRating] = useState<boolean>(null);
  const { t } = useLocalTranslation();

  const handleTabChange = (_: SyntheticEvent, expanded: boolean) => setExpanded(expanded);

  // @info Prevents from rating a solution multiple times
  const handleRateSolution =
    (handler: MouseEventHandler, isUpvoted: boolean): MouseEventHandler =>
    e => {
      const [isRatedUp, isRatedDown] = rating;
      const isSolutionRated = isUpvoted ? isRatedUp : isRatedDown;

      setSolutionRating(isUpvoted);
      !isSolutionRated && handler?.(e);

      setRating(([isUpvote, isDownvote]) => (isUpvoted ? [true, isDownvote] : [isUpvote, true]));
    };

  return (
    <BigidAccordion
      expanded={isExpanded}
      onChange={handleTabChange}
      dataAid={generateDataAid(dataAid, ['option', id])}
      sx={{ borderRadius: '8px' }}
      key={title}
    >
      <BigidAccordionSummary size={AccordionSummarySizeEnum.large}>
        <ErrorInsights.Option>
          <Typography sx={{ overflow: 'visible', lineHeight: 'inherit' }} variant="h6">
            {subtitle}
          </Typography>
          <Typography sx={{ maxWidth: '320px' }} variant="body1">
            {title}
          </Typography>
        </ErrorInsights.Option>
      </BigidAccordionSummary>
      <BigidAccordionDetails style={{ padding: '0px 24px 24px 24px' }}>
        <ErrorInsights.Details>
          <BigidBody1>
            <Markdown className={`${MARKDOWN_SELECTOR}__${id}`} components={components}>
              {content}
            </Markdown>
          </BigidBody1>
        </ErrorInsights.Details>
        {!isRateSolutionDisabled && (
          <ErrorInsights.Footer>
            <Typography variant="body1">{t('insights.footer')}</Typography>
            <ErrorInsights.Controls>
              {controls.map(
                (
                  { component: Button, onClick: defaultHandler, startIcon: defaultStartIcon, ...props },
                  staticIndex,
                ) => {
                  const defaultRating = mapControlPositionToRating(staticIndex);
                  const isAlreadyRated = !isNil(solutionRating) && defaultRating === solutionRating;

                  const startIconOverride = !isAlreadyRated ? (
                    defaultStartIcon
                  ) : defaultRating ? (
                    <BigidLikeFilledIcon />
                  ) : (
                    <BigidDislikeFilledIcon />
                  );

                  return (
                    <ErrorInsights.Rating isRated={isAlreadyRated} key={staticIndex}>
                      <Button
                        startIcon={startIconOverride}
                        onClick={handleRateSolution(defaultHandler, defaultRating)}
                        {...props}
                      />
                    </ErrorInsights.Rating>
                  );
                },
              )}
            </ErrorInsights.Controls>
          </ErrorInsights.Footer>
        )}
      </BigidAccordionDetails>
    </BigidAccordion>
  );
};
