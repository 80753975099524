import { styled } from '@mui/material';
export const AutoDiscoveryConditionalSectionStyled = styled('div')<{ showPrerequisites?: boolean }>`
  gap: 24px;
  padding-top: 6px;

  ${({ theme, showPrerequisites }) =>
    showPrerequisites
      ? `> fieldset { 
        padding-left: 30px; 
        }`
      : `
    background-color: ${theme.vars.palette.bigid.gray150};
    padding: 20px 16px;
    border-radius: 4px;
  `}
`;
