import angular from 'angular';
import './newBreachResponseModal.component.scss';
import template from './newBreachResponseModal.component.html';

angular.module('app').component('breachResponseModal', {
  template,
  controllerAs: '$ctrl',
  controller: [
    'breachResponseService',
    function (breachResponseService) {
      var ctrl = this;
      ctrl.originPassword = '';
      ctrl.showFields = false;

      ctrl.$onInit = () => {
        ctrl.action = this.action;
        ctrl.setHeader(ctrl.action);

        ctrl.dumpFile = this.dumpFile;
        if (this.action === 'Update') {
          ctrl.setFields(ctrl.dumpFile);
        } else {
          ctrl.showFields = true;
        }
      };

      ctrl.setFields = dumpFile => {
        breachResponseService.getBreachResponseById(dumpFile._id).then(function (result) {
          ctrl.originPassword = result.data.dsConnection.password.slice(0, 8);
          ctrl.dumpFile = angular.copy(result.data);
          ctrl.showFields = true;
          ctrl.dumpFile.dsConnection.password = ctrl.dumpFile.dsConnection.password.slice(0, 8);
        });
      };

      ctrl.setHeader = action => {
        if (action === 'Create') {
          ctrl.header = `${action} To Data Source`;
        } else {
          ctrl.header = `${action} Data Source`;
        }
      };

      ctrl.submit = (isValid, dumpFile) => {
        if (ctrl.originPassword === dumpFile.dsConnection.password) {
          delete dumpFile.dsConnection.password;
        }
        if (isValid) {
          ctrl.$close({ dumpFile: dumpFile });
        }
      };
    },
  ],
  bindings: {
    dumpFile: '<',
    action: '<',
    $close: '&',
    $dismiss: '&',
  },
});
