import './consentGovernance.component.scss';
import './consentConnections/consentConnections.component.js';
import './consentConnections/consentConnections.service.js';
import './newConsentConnection/newConsentConnection.component.js';
import './consentSourceConnections/consentSourceConnections.component.js';
import consentGovernanceTemplate from './consentGovernance.component.html';
import { module } from 'angular';
import { isPermitted } from '../react/services/userPermissionsService';
import { CONSENT_GOVERNANCE_PERMISSIONS } from '@bigid/permissions';
const app = module('app');

app.component('consentGovernance', {
  template: consentGovernanceTemplate,
  controller: function ($rootScope, $translate, $scope, $state) {
    'ngInject';

    const TRANSLATION_REQUIRED = [
      'CONSENT_GOVERNANCE',
      'CONSENT_GOVERNANCE:TABS:REPORT',
      'CONSENT_GOVERNANCE:TABS:CONSENT_SOURCES',
      'CONSENT_GOVERNANCE:TABS:AGREEMENTS',
    ];

    const TABS = {
      reports: {
        name: 'reports',
        stateName: 'consentGovernance.reports',
      },
      agreements: {
        name: 'agreements',
        stateName: 'consentGovernance.agreements',
      },
      sources: {
        name: 'sources',
        stateName: 'consentGovernance.list',
      },
    };

    this.tabs = {};
    this.currentTab = '';
    this.isLoading = false;

    const addToTabsIfPermitted = (tab, permission, translation) => {
      if (isPermitted(permission)) {
        this.tabs[tab] = translation;
      }
    };

    $translate(TRANSLATION_REQUIRED).then(translation => {
      // page header
      $rootScope.$broadcast('changePage', translation['CONSENT_GOVERNANCE'], false);
      addToTabsIfPermitted(
        TABS.agreements.name,
        CONSENT_GOVERNANCE_PERMISSIONS.READ_AGREEMENT.name,
        translation['CONSENT_GOVERNANCE:TABS:AGREEMENTS'],
      );
      addToTabsIfPermitted(
        TABS.reports.name,
        CONSENT_GOVERNANCE_PERMISSIONS.READ_REPORT.name,
        translation['CONSENT_GOVERNANCE:TABS:REPORT'],
      );
      addToTabsIfPermitted(
        TABS.sources.name,
        CONSENT_GOVERNANCE_PERMISSIONS.READ_CONSENT_SOURCES.name,
        translation['CONSENT_GOVERNANCE:TABS:CONSENT_SOURCES'],
      );
    });

    this.getActiveTab = () => {
      const tab = Object.values(TABS).find(({ stateName }) => $state.is(stateName));
      if (tab) {
        return tab.name;
      }
      return TABS.agreements.name;
    };

    this.onTabSelected = tabName => {
      const tab = Object.values(TABS).find(({ name }) => name === tabName);
      if (tab) {
        $state.go(tab.stateName);
      }
      this.isLoading = false;
    };

    this.onContentDataLoading = () => {
      this.isLoading = true;
    };

    this.onContentDataLoaded = () => {
      $scope.$evalAsync(() => {
        this.isLoading = false;
      });
    };
  },
});
