import { sessionStorageService } from '../../common/services/sessionStorageService';
import { $state } from './angularServices';
import { licenseService } from './licenseService';
import { differenceInDays } from 'date-fns';
import { notificationService } from './notificationService';
import { httpService } from './httpService';
import { CONFIG } from '../../config/common';
import { getApplicationPreference } from './appPreferencesService';

const APPS_LICENSE_PAGE = 'generalSettings';
export const APP_LICENSE_TAB = 'applicense';

export enum AppStatus {
  PAID = 'Paid',
  EXPIRED = 'Expired',
  FREE = 'Free',
  TRIAL = 'In trial',
  TRIAL_EXPIRED = 'Trial ending',
}

export enum AppLicenseType {
  PAID = 'paid',
  FREE = 'free',
}

const routeToAppNameMapping: any = {
  dataMapping: { name: 'Data Processing and Sharing', id: '2383d397-4442-4c7e-be1a-6550c95fde2a' },
  subjectAccessRequest: { name: 'DSAR', id: '4639b384-5a33-41c1-b973-45424cacc5fa' },
  dataMinimization: { name: 'Data Deletion', id: '4639b384-5a33-41c1-b973-45424cacc5fb' },
  consentGovernance: { name: 'consent', id: 'd49597f4-6a8d-4f2a-9ac3-137fa10381f9' },
  breachResponse: { name: 'Breach Response', id: '394b34dc-6965-4ce7-9420-198f2422b319' },
  accessGovernance: { name: 'Access Intelligence', id: '5b594c0f-2a99-4032-9fbc-ab8d76529a0a' },
  riskConfiguration: { name: 'Risk Configuration', id: '12d9bdf5-c159-4009-8da3-24cd366eae3e' },
  dlpConnections: { name: 'SAP Data Intelligence Datasource Import', id: 'd6a589f-189a-4cdb-9064-04294c7dce4f' },
  cyberArk: { name: 'CyberArk', id: '2dba75cb-34dd-467f-949f-02b0b127a2d2' },
  hashiCorp: { name: 'HashiCorp', id: 'cd07f2cf-dce8-4e35-8e2b-fed4185f1284' },
  thycotic: { name: 'Thycotic', id: '16271545-1c9b-4b00-b79b-d39fc1732dab' },
  labeling: { name: 'File Labeling', id: '34df7f84-625b-4d40-b612-9ac959775050' },
  privacyApp: { name: 'Privacy Portal', id: '147600b3-b7ab-44e7-9a56-8fa97f19ae09' },
  dataInsightsStudio: { name: 'Data Insights Studio', id: '2192539b-f750-4151-b30f-3deda418e7c7' },
  dataPipeline: { name: 'Data Pipeline', id: 'bf3c0bb5-e0b3-4ca4-83dd-37db40ea84d5' },
};

interface AppData {
  id: string;
  name: string;
}

export const internalApps: AppData[] = Object.values(routeToAppNameMapping);

interface TransitionOptions {
  reload?: boolean;
  inherit?: boolean;
  notify?: boolean;
}

export interface AppLicense {
  name: string;
  id: string;
  category: string;
  description: string;
  enable: boolean;
  status?: AppStatus;
  displayName?: string;
  displayCategory?: string;
  vendor?: string;
  actions?: string[];
}

interface AppsLicenseService {
  getAppsLicense: () => AppLicense[];
  setAppsLicense: (appLicenseReceived: AppLicense[]) => void;
  shouldNotifyAppLicenseExpire: (appNameOrId: string) => boolean;
  getAmountOfDaysUntilAppExpired: (appName: string) => number;
  goToAppsLicensePage: (options?: TransitionOptions) => void;
  showAppExpirationNotification: (route: string) => void;
  showAppExpirationNotificationForCustomApp: (id: string, name: string, vendor: string) => void;
  showAppExpirationNotificationForCustomAppAndGoToCustomAppPage: (
    id: string,
    name: string,
    vendor: string,
    manifest_name?: string,
  ) => void;
  fetchAndSetAppLicense: () => void;
  isAppLicenseRegistered: (appName: string) => boolean;
}

const setAppsLicense = (appsLicense: AppLicense[]) => {
  sessionStorageService.set('appsLicense', appsLicense);
};

const getAppsLicense = (): AppLicense[] => {
  return sessionStorageService.get('appsLicense');
};

const getAmountOfDaysUntilAppExpired = (appName: string): number => {
  const app = getAppsLicense().find(oneApp => oneApp.name === appName);
  if (app.enable) {
    const appTrialInDays = 60;
    const bigidLicenseCreationDate = new Date(licenseService.getCreationDate());
    bigidLicenseCreationDate.setDate(bigidLicenseCreationDate.getDate() + appTrialInDays);
    const isAppInBundle = licenseService.getApplicationBundles().indexOf(app.category) !== -1;
    return isAppInBundle
      ? licenseService.getAmountOfDaysUntilExpired()
      : differenceInDays(new Date(bigidLicenseCreationDate), new Date());
  }
  return 0;
};

const shouldNotifyAppLicenseExpire = (appNameOrId: string): boolean => {
  const app = getAppsLicense().find(oneApp => oneApp.name === appNameOrId || oneApp.id === appNameOrId);
  return !app?.enable;
};

const goToAppsLicensePage = (options: TransitionOptions) => {
  $state.go(APPS_LICENSE_PAGE, { currentTab: APP_LICENSE_TAB }, options);
};

const showAppExpirationNotification = (route: string) => {
  const appNameOrId = routeToAppNameMapping[route.split('.')[0]]?.id || route;
  if (shouldNotifyAppLicenseExpire(appNameOrId) && !licenseService.shouldNotifyLicenseExpiration()) {
    notificationService.warning(
      'You are currently using a trial version of this application. To purchase it, please contact your BigID support team.',
      { shouldCloseOnTransition: false, shouldAutoHide: false },
    );
  }
};

const showAppExpirationNotificationForCustomApp = (id: string, name: string, vendor: string) => {
  const uniqId = vendor ? (name ? vendor + '.' + name : vendor) : name ? name : '';
  showAppExpirationNotification(uniqId);
};

const showAppExpirationNotificationForCustomAppAndGoToCustomAppPage = (
  id: string,
  name: string,
  vendor: string,
  manifest_name?: string,
) => {
  const nameToCompare =
    getApplicationPreference('TPA_MULTIPLE_DEPLOYMENTS_ENABLED') && manifest_name ? manifest_name : name;
  const uniqId = vendor ? (nameToCompare ? vendor + '.' + nameToCompare : vendor) : nameToCompare ? nameToCompare : '';
  $state.go(CONFIG.states.CUSTOM_APP, { id, name }, { reload: true }).then(() => {
    showAppExpirationNotification(uniqId);
  });
};

const fetchAndSetAppLicense = async () => {
  const {
    data: { supportedApps },
  } = await httpService.fetch<{ supportedApps: AppLicense[] }>('license');
  setAppsLicense(supportedApps);
};

const isAppLicenseRegistered = (appName: string): boolean => {
  return getAppsLicense().some(
    app => app.name === appName && (app.status === AppStatus.PAID || app.status === AppStatus.TRIAL),
  );
};

export const appsLicenseService: AppsLicenseService = {
  getAppsLicense,
  setAppsLicense,
  shouldNotifyAppLicenseExpire,
  getAmountOfDaysUntilAppExpired,
  goToAppsLicensePage,
  showAppExpirationNotification,
  showAppExpirationNotificationForCustomApp,
  showAppExpirationNotificationForCustomAppAndGoToCustomAppPage,
  fetchAndSetAppLicense,
  isAppLicenseRegistered,
};
