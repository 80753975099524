import { useQuery, UseQueryOptions, QueryFunction, UseQueryResult, QueryKey } from 'react-query';
import { httpRequest, ErrorType } from '../../../../config/request';
import type { DsConnectionDocItemResponse, DsConnectionDocParams, AsyncReturnType } from '../types';

export type GetDsConnectionDocQueryResult = NonNullable<AsyncReturnType<typeof getDsConnectionDocs>>;

export type GetDsConnectionDocQueryError = ErrorType<unknown>;

const API_VERSION = '/api/v1';
const DS_CONNECTION_DOC_ENDPOINT = 'docs/ds-connections';

export const getDsConnectionDocs = async (
  dataSource: string,
  params?: DsConnectionDocParams,
): Promise<DsConnectionDocItemResponse> =>
  httpRequest<DsConnectionDocItemResponse>({
    url: `${DS_CONNECTION_DOC_ENDPOINT}/${dataSource}`,
    method: 'get',
    params,
  });

export const getDsConnectionDocsQueryKey = (dataSource: string, params: DsConnectionDocParams): Array<unknown> => [
  `${API_VERSION}/${DS_CONNECTION_DOC_ENDPOINT}/${dataSource}`,
  ...(params ? [params] : []),
];

export const useGetDsConnectionDocs = <
  TData = AsyncReturnType<typeof getDsConnectionDocs>,
  TError = ErrorType<unknown>,
>(
  dataSource: string,
  params?: DsConnectionDocParams,
  options?: { query?: UseQueryOptions<AsyncReturnType<typeof getDsConnectionDocs>, TError, TData> },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options || {};

  const queryKey = queryOptions?.queryKey ?? getDsConnectionDocsQueryKey(dataSource, params);
  const queryFn: QueryFunction<AsyncReturnType<typeof getDsConnectionDocs>> = async () =>
    getDsConnectionDocs(dataSource, params);
  const query = useQuery<AsyncReturnType<typeof getDsConnectionDocs>, TError, TData>(queryKey, queryFn, {
    enabled: !!dataSource,
    ...queryOptions,
  });

  return {
    queryKey,
    ...query,
  };
};
