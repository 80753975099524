import { useQuery, UseQueryOptions, QueryFunction, UseQueryResult, QueryKey } from 'react-query';
import { getDsConnectionEnrichmentStatus, DS_ENRICHMENT_ENDPOINT } from '../services/enrichment';
import type { AsyncReturnType } from '../../../DataSourceConnectionModal/types';
import type { ErrorType } from '../../../../../config/request';
import type { BigidFieldFilter } from '@bigid-ui/components';

export type GetDsConnectionStatusQueryResult = NonNullable<AsyncReturnType<typeof getDsConnectionEnrichmentStatus>>;

export type GetDsConnectionStatusQueryError = ErrorType<unknown>;

export const getDsConnectionStatusQueryKey = (filter: BigidFieldFilter[]): Array<unknown> => [
  'post',
  `/${DS_ENRICHMENT_ENDPOINT}/grouped-statuses`,
  ...(filter ? [...filter] : []),
];

export const useGetDsConnectionStatus = <
  TData = AsyncReturnType<typeof getDsConnectionEnrichmentStatus>,
  TError = GetDsConnectionStatusQueryError,
>(
  filter: BigidFieldFilter[],
  options?: { query?: UseQueryOptions<AsyncReturnType<typeof getDsConnectionEnrichmentStatus>, TError, TData> },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options || {};

  const queryKey = queryOptions?.queryKey ?? getDsConnectionStatusQueryKey(filter);
  const queryFn: QueryFunction<AsyncReturnType<typeof getDsConnectionEnrichmentStatus>> = async () =>
    getDsConnectionEnrichmentStatus({ query: { filter } });
  const query = useQuery<AsyncReturnType<typeof getDsConnectionEnrichmentStatus>, TError, TData>(queryKey, queryFn, {
    ...queryOptions,
  });

  return {
    queryKey,
    ...query,
  };
};
