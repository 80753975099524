import React, { FunctionComponent, useEffect } from 'react';
import { BigidTabsAndContent, BigidPaper } from '@bigid-ui/components';
import makeStyles from '@mui/styles/makeStyles';
import { pageHeaderService } from '../../../../common/services/pageHeaderService';
import { $state, $stateParams } from '../../../services/angularServices';
import { ClusterDetails } from '../../Clustering/ClusterDetails/ClusterDetails';
import { ClusterObjects } from '../../Clustering/ClusterObjects';
import { sessionStorageService } from '../../../../common/services/sessionStorageService';
import { History } from '../../../components/BigidHeader/HeaderService';
import { CatalogEventsEnum } from '../events';
import { analyticsService } from '../../../services/analyticsService';

const useStyles = makeStyles({
  root: {
    padding: '16px 32px',
  },
  paperWrapper: {
    padding: '20px 24px',
  },
});

export const DataCatalogClusterPreview: FunctionComponent = ({}) => {
  const classes = useStyles({});

  const tabs = [
    {
      label: 'Overview',
      data: { component: ClusterDetails },
    },
    {
      label: 'Objects',
      data: { component: ClusterObjects },
    },
  ];

  useEffect(() => {
    const { clusterName } = $stateParams;
    const history: History[] = sessionStorageService.get('history');

    if (history.length > 0) {
      const target = history.pop();
      const { state, params } = target.to;

      const isFromDataCatalog = state === 'dataCatalog';

      const breadcrumbs = isFromDataCatalog
        ? [{ label: 'Data Catalog', onClick: () => $state.go('dataCatalog') }, { label: clusterName }]
        : [
            { label: 'Cluster Analysis', onClick: () => $state.go('clustering') },
            { label: 'Object Preview', onClick: () => $state.go(state, params) },
            { label: 'Cluster Overview' },
          ];

      pageHeaderService.setTitle({
        showBackButton: true,
        breadcrumbs,
      });
    } else {
      pageHeaderService.setTitle({ pageTitle: clusterName, showBackButton: true });
    }
  }, []);

  useEffect(() => {
    analyticsService.trackManualEvent(CatalogEventsEnum.CATALOG_VIEW_CLUSTER_EVENT);
  }, []);

  return (
    <div className={classes.root}>
      <BigidPaper classes={{ root: classes.paperWrapper }}>
        <BigidTabsAndContent tabProps={{ tabs, selectedIndex: 0 }} />
      </BigidPaper>
    </div>
  );
};
