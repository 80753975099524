import { useMutation } from 'react-query';
import { addFollowedObjects, deleteFollowedObjects } from '../../../../../../DataCatalog/DataCatalogService';
import { getQueryClient } from '../../../../../../../config/query';
import { DataCatalogObjectDetails } from '../../../../../../DataCatalog/DataCatalogDetails/DataCatalogDetailsService';

export const useFollowObjectMutation = (fqn: string, isFollowed: boolean) => {
  const queryClient = getQueryClient();
  const dataKey = ['getObjectDetails', fqn];

  const followObjectMutation = useMutation(
    'followObject',
    async () => {
      try {
        if (!isFollowed) {
          await addFollowedObjects([fqn]);
        } else {
          await deleteFollowedObjects([fqn]);
        }
      } catch ({ message }) {
        console.error(`An error has occurred: ${message}`);
      }
    },
    {
      onMutate: async () => {
        // Snapshot the previous value
        const previousData = queryClient.getQueryData(dataKey);

        // Optimistically update the cache with the new value
        queryClient.setQueryData<{ data: DataCatalogObjectDetails }>(dataKey, old => {
          return {
            ...old,
            data: {
              ...old.data,
              collaborationStatus: {
                ...old.data.collaborationStatus,
                isFollowed: !isFollowed,
              },
            },
          };
        });

        // Return a context object with the snapshotted value to use in case of rollback
        return { previousData };
      },
      // Always refetch after error or success to ensure server state is in sync with client state
      onSettled: () => {
        queryClient.invalidateQueries(dataKey);
      },
    },
  );

  return followObjectMutation;
};
