import { omitBy } from 'lodash';
import { httpService } from '../../../../../services/httpService';
import { CuratedAttribute } from '../../../curationService';
import { CategoryCreationObject } from '../AssignCategoryDialog/assignCategoryService';
import { AttributeAssignmentBase, GlossaryItem } from '../types';

export interface AssignFriendlyNameResponse {
  category: CategoryCreationObject;
  original_name: string;
}

export interface AssignFriendlyNameRequestPayload extends AttributeAssignmentBase {
  description?: string;
  glossary_id: string;
}

interface AssignFriendlyNameCreateObject {
  friendlyName: string;
  description?: string;
}

export const assignFriendlyNameToAttribute = async (
  { attributeName }: CuratedAttribute,
  requestData: AssignFriendlyNameCreateObject,
  newFriendlyNameGlossaryId: string,
) => {
  const assignObject = omitBy(requestData, value => value === undefined);

  try {
    await httpService.post<AssignFriendlyNameResponse, AssignFriendlyNameRequestPayload>('attributes', {
      original_name: attributeName,
      friendly_name: assignObject.friendlyName,
      glossary_id: newFriendlyNameGlossaryId,
      ...assignObject,
    });
  } catch (error) {
    console.error(error);
  }
};

export const getAttributesList = async () => {
  try {
    const { data } = await httpService.fetch<GlossaryItem[]>('attributes');
    return data;
  } catch (error) {
    console.error(error);
  }
};

interface CreateFriendlyNameRequestPayload extends AttributeAssignmentBase {
  type: 'idsor_attributes';
}

interface CreateFriendlyNameProps {
  friendlyName: string;
  originalName: string;
}

export const createFriendlyName = async ({ friendlyName, originalName }: CreateFriendlyNameProps) => {
  const { data } = await httpService.post<GlossaryItem, CreateFriendlyNameRequestPayload>('attributes', {
    friendly_name: friendlyName,
    original_name: originalName,
    type: 'idsor_attributes',
  });
  return data;
};
