import React, { FC, useContext, useEffect, useState } from 'react';
import { CaseSidePanelContext } from '../../hooks/CaseSidePanelContext';
import { ObjectPreviewContext } from '../hooks/ObjectPreviewContext';
import { fetchObjectAttributes } from '../objectPreviewService';
import { BigidLoader } from '@bigid-ui/components';
import { ObjectDetailsWrapper, ObjectTags } from './ObjectTags';
import { ObjectAttributes } from './ObjectAttributes';
import { ObjectInfo } from './ObjectInfo';
import { BigidDsObjectPreview } from '../DsObjectPreview/DsObjectPreview';
import { DataCatalogAttribute } from '../../../../DataCatalog/DataCatalogAttributes';
import { getApplicationPreference } from '../../../../../services/appPreferencesService';

export const DetailsTab: FC<any> = ({ caseDetails }) => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const { caseSidePanelData, objectType } = useContext(CaseSidePanelContext);
  const { objectPreviewData } = useContext(ObjectPreviewContext);
  const [objectAttributes, setObjectAttributes] = useState<DataCatalogAttribute[]>([]);
  const [isPolicyAttrExist, setIsPolicyAttrExist] = useState<boolean>(false);
  const attributesNameList: string[] = objectAttributes.map(attr => attr?.attribute_name);

  const isDSPMObjectPreviewEnabled = getApplicationPreference('DSPM_OBJECT_PREVIEW');

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const { fullObjectAttributes, policyAttributes } = await fetchObjectAttributes(
          objectPreviewData.fullyQualifiedName,
          caseSidePanelData.policyName,
        );
        setObjectAttributes(fullObjectAttributes);
        setIsPolicyAttrExist(policyAttributes?.length > 0 && fullObjectAttributes?.length > 0);
      } catch ({ message }) {
        if (message) {
          console.error(`failed to get object attributes, got error: ${message}`);
        }
      } finally {
        setIsLoading(false);
      }
    };
    if (objectPreviewData.fullyQualifiedName && caseSidePanelData.policyName) {
      fetchData().catch(console.error);
    }
  }, [caseSidePanelData, objectPreviewData]);

  return (
    <div>
      {isLoading ? (
        <BigidLoader />
      ) : (
        <ObjectDetailsWrapper>
          <ObjectTags caseDetails={caseDetails} />
          {isPolicyAttrExist && isDSPMObjectPreviewEnabled ? (
            <BigidDsObjectPreview
              caseId={caseDetails?.id}
              objectPreviewData={objectPreviewData}
              objectAttributes={objectAttributes}
            />
          ) : (
            <ObjectAttributes objectAttributes={attributesNameList} />
          )}
          <ObjectInfo caseDetails={caseDetails} objectType={objectType} />
        </ObjectDetailsWrapper>
      )}
    </div>
  );
};
