import React, { FC, useEffect, useRef, useState } from 'react';
import { pick } from 'lodash';
import makeStyles from '@mui/styles/makeStyles';
import angular from 'angular';
import { convertToAngular } from '../../../common/services/convertToAngular';
import { credentialsService } from '../../services/credentialsService';
import { notificationService } from '../../services/notificationService';
import {
  BigidForm,
  BigidFormStateAndHandlers,
  BigidFormValues,
  BigidLoader,
  BigidSelect,
  BigidSelectOption,
  BigidFormValidateOnTypes,
} from '@bigid-ui/components';
import {
  CredentialsTypesMetadata,
  CredentialType,
  CredentialTypeInitialValue,
  generateFormFieldsFromMetadata,
  generateSelectOptions,
  getFormInitialValues,
  getSubTypeSelectOption,
  hasFormErrors,
} from './credentialsFormUtils';

interface CredentialsTypesFormProps {
  initialValues: CredentialTypeInitialValue;
  setCredentialTypes: (subType: CredentialType, values: BigidFormValues, hasErrors?: boolean) => void;
}

const useStyles = makeStyles({
  formWrapper: { padding: '20px 0', width: '100%' },
  select: { width: '50%', marginBottom: 20 },
});

export const CredentialsTypesForm: FC<CredentialsTypesFormProps> = ({ initialValues, setCredentialTypes }) => {
  const { formWrapper, select } = useStyles();
  const [metadata, setMetadata] = useState<CredentialsTypesMetadata[]>();
  const [selectedType, setSelectedType] = useState<BigidSelectOption>();
  const [initialSubmit, setInitialSubmit] = useState(false);
  const formControls = useRef<BigidFormStateAndHandlers>();

  const fetchMetadata = async () => {
    try {
      const {
        data: { data },
      } = await credentialsService.getCredentialsTypesMetadata();
      setMetadata(data);
      setSelectedType(generateSelectOptions(data)[0]);
    } catch (error) {
      notificationService.error('Error fetching credentials metadata');
    }
  };

  useEffect(() => {
    fetchMetadata();
  }, []);

  useEffect(() => {
    if (initialValues) {
      formControls.current?.clear();
    }
  }, [initialValues]);

  useEffect(() => {
    if (metadata && initialValues) {
      setSelectedType(getSubTypeSelectOption(initialValues.subType, metadata));
    }
  }, [metadata, initialValues]);

  const onSelectionChange = (value: BigidSelectOption[]) => {
    setSelectedType(value[0]);
    formControls.current?.validateAndSubmit();
    formControls.current?.clear();
  };

  const handleFormChange = (values: BigidFormValues) => {
    const fieldsToUpdate = metadata.find(({ type }) => type === selectedType.value).fields.map(({ name }) => name);
    const hasErrors = hasFormErrors(formControls?.current?.errors, formControls?.current?.touchedFields);

    setCredentialTypes(selectedType.value, pick(values, fieldsToUpdate), hasErrors);
    if (!initialSubmit && !!formControls?.current) {
      formControls?.current?.validateAndSubmit();
      setInitialSubmit(true);
    }
  };

  const displayForm = Boolean(metadata && selectedType && initialValues);

  return (
    <div className={formWrapper}>
      {displayForm ? (
        <>
          <BigidSelect
            className={select}
            onChange={onSelectionChange}
            options={generateSelectOptions(metadata)}
            value={[selectedType]}
            menuPosition="fixed"
            dataAid="BigidSelectCredentialsForm"
          />
          <BigidForm
            controlButtons={false}
            initialValues={getFormInitialValues(metadata, initialValues.credentialFields)}
            fields={generateFormFieldsFromMetadata(selectedType.value, metadata)}
            validateOn={BigidFormValidateOnTypes.CHANGE}
            onChange={handleFormChange}
            stateAndHandlersRef={formControls}
          />
        </>
      ) : (
        <BigidLoader position="relative" label="Loading credential fields..." />
      )}
    </div>
  );
};

angular
  .module('app')
  .component('credentialsTypesForm', convertToAngular(CredentialsTypesForm, ['initialValues', 'setCredentialTypes']));
