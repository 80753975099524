import { useEffect } from 'react';
import { updateGridRowsWithScanProgress } from '../../../../FmsdDiscover/fmsdDiscoverServices';

export const useScanProgressUpdater = (dsType: string) => {
  useEffect(() => {
    let timer = window.setTimeout(() => updater(), 5000);

    const updater = async () => {
      await updateGridRowsWithScanProgress(dsType);
      timer = window.setTimeout(() => updater(), 5000);
    };

    return () => {
      return window.clearTimeout(timer);
    };
  }, [dsType]);
};
