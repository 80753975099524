import { module } from 'angular';
import { dateTimeService } from '@bigid-ui/i18n';
const app = module('app');

app.factory('treeMapService', [
  '$http',
  'appSettings',
  function ($http, appSettings) {
    return {
      getTreeMapData: () =>
        $http
          .get(appSettings.serverPath + '/' + appSettings.version + '/collection_metadata/treemap')
          .then(response => response.data),
      exportCSV: () =>
        $http
          .get(appSettings.serverPath + '/' + appSettings.version + '/collection_metadata/export')
          .then(response => response.data),
      exportAsJSON: () =>
        $http
          .get(appSettings.serverPath + '/' + appSettings.version + '/collection_metadata/exportjson')
          .then(response => response.data)
          .then(function (data) {
            data.forEach(row => {
              row.last_scan_at = dateTimeService.formatDate(row.last_scan_at);
            });
            return data;
          }),
      updateConfidenceLevel: data =>
        $http
          .post(appSettings.serverPath + '/' + appSettings.version + '/confidence-levels', data)
          .then(response => response.data),
    };
  },
]);
