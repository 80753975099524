/* eslint-disable react/no-children-prop */
import React from 'react';
import { Box } from '@mui/material';
import {
  BigidLink,
  BigidHeading1,
  BigidHeading3,
  BigidHeading2,
  BigidHeading5,
  BigidHeading6,
  BigidBody1,
} from '@bigid-ui/components';
import type { Components as ReactMarkdownComponents } from 'react-markdown';

const majorHeaderMargin = { margin: '0 0 0.75rem 0' };
const minorHeaderMargin = { margin: '1rem 0 0.75rem 0' };

export const getMarkdownComponentOverrides = (): ReactMarkdownComponents => ({
  h1: ({ children }) => (
    <Box component="h1" sx={majorHeaderMargin}>
      <BigidHeading1 children={children} />
    </Box>
  ),
  h2: ({ children }) => (
    <Box component="h2" sx={majorHeaderMargin}>
      <BigidHeading3 children={children} />
    </Box>
  ),
  h3: ({ children }) => (
    <Box component="h3" sx={majorHeaderMargin}>
      <BigidHeading2 children={children} />
    </Box>
  ),
  h4: ({ children }) => (
    <Box component="h4" sx={majorHeaderMargin}>
      <BigidHeading5 children={children} />
    </Box>
  ),
  h5: ({ children }) => (
    <Box component="h5" sx={{ margin: '1rem 0 0.75rem 0', fontWeight: 'bold' }}>
      {children}
    </Box>
  ),
  h6: ({ children }) => (
    <Box component="h6" sx={minorHeaderMargin}>
      <BigidHeading6 children={children} />
    </Box>
  ),
  p: ({ children }) => (
    <BigidBody1
      sx={{
        '&:last-child': {
          marginBottom: '0.5rem',
        },
      }}
      children={children}
    />
  ),
  a: ({ children, href }) => <BigidLink href={href} text={children as unknown as string} />,
  ol: ({ children }) => (
    <Box
      component="ol"
      sx={{
        paddingLeft: '1rem',
        'li::marker': {
          marginLeft: '0px',
          paddingLeft: '0px',
        },
        '& ol': {
          listStyleType: 'lower-alpha',
        },
      }}
    >
      {children}
    </Box>
  ),
  ul: ({ children }) => (
    <Box
      component="ul"
      sx={{
        paddingLeft: '1rem',
        'li::marker': {
          marginLeft: '0px',
          paddingLeft: '0px',
        },
      }}
    >
      {children}
    </Box>
  ),
  li: ({ children }) => (
    <Box component="li" sx={{ marginBottom: '0.5rem' }}>
      {children}
    </Box>
  ),
});
