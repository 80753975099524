import React, { FC, Fragment, PropsWithChildren, ReactElement, useEffect } from 'react';
import Paper from '@mui/material/Paper';
import makeStyles from '@mui/styles/makeStyles';
import { BigidLoader } from '@bigid-ui/components';
import { CustomAppHeader } from './CustomAppHeader';
import { AppInfo } from './utils/CustomAppTypes';
import { getCustomAppPermissions } from './utils/CustomAppUtils';
import { SystemEvents, systemEventsEmitter } from '../../services/systemEvents';
import { tpaStatusService } from '../../services/tpaStatusService';

const useStyles = makeStyles({
  paper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    overflowY: 'hidden',
    height: 'calc(100% - 8px)',
    position: 'relative',
    padding: '8px 24px 16px 24px',
    flexGrow: 1,
  },
  coverPage: {
    position: 'absolute',
    zIndex: 1,
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    overflow: 'auto',
    padding: '0',
  },
});
type CustomAppPageWrapperProps = PropsWithChildren<{
  appInfo: AppInfo;
  isReady: boolean;
  pageName?: string;
}>;

export const CustomAppPageWrapper: FC<CustomAppPageWrapperProps> = ({ appInfo, pageName, isReady, children }) => {
  const classes = useStyles();

  useEffect(() => {
    if (appInfo) {
      CustomAppHeader({
        appInfo,
        pageName,
        permissions: getCustomAppPermissions(appInfo.name),
      });
    }
  }, [appInfo, pageName]);

  useEffect(() => {
    appInfo &&
      systemEventsEmitter.emit(SystemEvents.SHOULD_SHOW_APPLICATION_BANNER, {
        isExpired: tpaStatusService.isTpaLicenseExpired(appInfo.name),
        isOffline: tpaStatusService.isTpaOffline(appInfo.status),
      });

    return () => {
      systemEventsEmitter.emit(SystemEvents.SHOULD_SHOW_APPLICATION_BANNER, {
        isExpired: false,
        isOffline: false,
      });
    };
  }, [appInfo]);

  return (
    <Fragment>
      <Paper elevation={0} className={classes.paper}>
        <div className={classes.coverPage}>{isReady ? (children as ReactElement) : <BigidLoader />}</div>
      </Paper>
    </Fragment>
  );
};
