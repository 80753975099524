import React, { FC } from 'react';
import { useLocalTranslation } from '../translations';
import { CatalogDiscoveryGuidedTourStep } from '../CatalogDiscoveryGuidedTourStep';

interface HighLevelTotalsProps {
  dataAid: string;
}

export const HighLevelTotals: FC<HighLevelTotalsProps> = ({ dataAid }) => {
  const { t } = useLocalTranslation('steps.HighLevelTotals');

  return <CatalogDiscoveryGuidedTourStep dataAid={dataAid} body={t('body')} />;
};
