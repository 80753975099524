import React, {
  ComponentType,
  Dispatch,
  FC,
  SetStateAction,
  SVGProps,
  useCallback,
  useMemo,
  useRef,
  useState,
} from 'react';
import { IconButton } from '@mui/material';
import { BigidMoreActionIcon, IconComponentProps } from '@bigid-ui/icons';
import { BigidColorsV2, BigidIcon, BigidMenu, BigidMenuProps } from '@bigid-ui/components';
import { RowWithActions } from './BigidListWithActions';
import styled from '@emotion/styled';
import { generateDataAid } from '@bigid-ui/utils';

export interface BigidListWithActionsRowProps {
  rowWithActions: RowWithActions;
  index: number;
  hoveredElement: number;
  setHoveredElement: Dispatch<SetStateAction<number>>;
  icon?: ComponentType<IconComponentProps & SVGProps<SVGSVGElement>>;
}

const IconWrapper = styled('div')({
  position: 'relative',
  display: 'flex',
  alignItems: 'center',
});

const NameWrapper = styled('div')`
  display: flex;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  align-items: center;
  height: 32px;
`;

const Name = styled('div')<{ isHovered: boolean }>`
  color: ${BigidColorsV2.gray[700]};
  font-size: 0.8125rem;
  text-decoration: ${props => (props.isHovered ? 'underline' : undefined)};
  overflow: hidden;
  text-overflow: ellipsis;
`;

const Value = styled('div')`
  color: ${BigidColorsV2.gray[600]};
  font-size: 0.875rem;
  text-align: right;
`;

const ButtonWrapper = styled('div')<{ isVisible: boolean }>`
  display: ${props => (!props.isVisible ? 'none' : undefined)};
`;

const dataAid = 'BigidListWithActionsRow';

export const BigidListWithActionsRow: FC<BigidListWithActionsRowProps> = ({
  rowWithActions: {
    row: { name, value, valueLabel },
    actions,
  },
  setHoveredElement,
  hoveredElement,
  index,
  icon,
}) => {
  const [open, setOpen] = useState(false);
  const anchorRef = useRef();
  const handleClick = useCallback(() => {
    setHoveredElement(-1);
    setOpen(open => !open);
  }, [setHoveredElement]);

  const menuProps: BigidMenuProps = useMemo(() => {
    return {
      open,
      onMenuClose: () => {
        setOpen(false);
        setHoveredElement(-1);
      },
      anchorEl: anchorRef.current,
      anchorOrigin: {
        vertical: 'bottom',
        horizontal: 'right',
      },
      transformOrigin: {
        vertical: 'top',
        horizontal: 'right',
      },
    };
  }, [open, setHoveredElement]);

  const handleMouseOver = useCallback(() => {
    setHoveredElement(index);
  }, [index, setHoveredElement]);

  const handleMouseOut = useCallback(() => {
    setHoveredElement(-1);
  }, [setHoveredElement]);

  return (
    <>
      <IconWrapper title={name}>{icon && <BigidIcon icon={icon} />}</IconWrapper>
      <NameWrapper title={name} onMouseOver={handleMouseOver} onMouseOut={handleMouseOut}>
        <Name
          isHovered={actions?.length > 0 && hoveredElement === index}
          data-aid={generateDataAid(dataAid, ['name', index + ''])}
          ref={anchorRef}
        >
          {name}
        </Name>
        <ButtonWrapper
          isVisible={actions?.length > 0 && hoveredElement === index}
          data-aid={generateDataAid(dataAid, ['menu-button', index + ''])}
        >
          <IconButton onClick={handleClick}>
            <BigidMoreActionIcon />
          </IconButton>
          <BigidMenu {...menuProps} items={actions} />
        </ButtonWrapper>
      </NameWrapper>
      <Value title={name} data-aid={generateDataAid(dataAid, ['value', index + ''])}>
        {valueLabel || value}
      </Value>
    </>
  );
};
