import { MetadataSearchFieldFilter } from '../../MetadataSearchFiltersTypes';

export const MetadataSearchFilterNumberOperatorLabelMap: {
  [operator in MetadataSearchFieldFilter['operator']]?: string;
} = {
  equal: 'Equal',
  greaterThan: 'Greater than',
  greaterThanOrEqual: 'Greater than or equal',
  lessThan: 'Less than',
  lessThanOrEqual: 'Less than or equal',
};

export const getIsValueValidPositiveNumber = (value: string) => {
  const numericValue = Number(value);
  return Number.isInteger(numericValue) && numericValue >= 0;
};
