import { SchedulerModal } from '../../../CustomApp/views/CustomAppActions/SchedulerModal';
import React, { FC, useCallback, useContext, useState } from 'react';
import { BigidButtonIcon } from '@bigid-ui/components';
import { BigidScheduleIcon } from '@bigid-ui/icons';
import { Preset } from '../../../CustomApp/views/CustomAppActions/CustomAppActions';
import { customAppService } from '../../../../services/customAppService';
import { AutoDiscoveryWizardContext, AutoDiscoveryWizardContextState } from '../autoDiscoveryWizardContext';

export const useScheduleConfig = (discoveryConfigDataRef: React.MutableRefObject<AutoDiscoveryWizardContextState>) => {
  const [isScheduleDialogOpen, setIsScheduleDialogOpen] = useState(false);
  const handleOnScheduleButtonClick = useCallback(() => {
    setIsScheduleDialogOpen(true);
  }, []);

  const handleUpdateSchedule = useCallback(async (_preset: Preset, isScheduled: boolean, cronExpression: string) => {
    const { _id, actionId } = discoveryConfigDataRef.current.preset;
    await customAppService.editActionPreset(discoveryConfigDataRef.current.configData.appId, actionId, _id, {
      isScheduled,
      cronExpression,
    });
    setIsScheduleDialogOpen(false);
  }, []);

  const handleScheduleDialogClose = useCallback(() => {
    setIsScheduleDialogOpen(false);
  }, []);

  return {
    isScheduleDialogOpen,
    handleScheduleDialogClose,
    handleUpdateSchedule,
    handleOnScheduleButtonClick,
  };
};

export const SchedulePreset: FC = () => {
  const { discoveryConfigDataRef, discoveryConfigData } = useContext(AutoDiscoveryWizardContext);
  const { isScheduleDialogOpen, handleScheduleDialogClose, handleUpdateSchedule, handleOnScheduleButtonClick } =
    useScheduleConfig(discoveryConfigDataRef);

  return (
    <>
      <BigidButtonIcon
        key="5"
        icon={BigidScheduleIcon}
        onClick={() => {
          return handleOnScheduleButtonClick();
        }}
        dataAid="schedule"
      />
      {isScheduleDialogOpen && (
        <SchedulerModal
          preset={discoveryConfigData.preset as Preset}
          onUpdateSchedule={handleUpdateSchedule}
          onClose={handleScheduleDialogClose}
        />
      )}
    </>
  );
};
