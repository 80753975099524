import { module } from 'angular';
const app = module('app');

app.factory('dlpConnectionsService', [
  '$http',
  'appSettings',
  'notificationService',
  function ($http, appSettings, notificationService) {
    return {
      getDLPConnectionsData: function getDLPConnectionsData() {
        return $http
          .get(appSettings.serverPath + '/' + appSettings.version + '/dlp_connections')
          .then(function (response) {
            return response.data;
          });
      },

      getDLPConnectionsDataByID: function getDLPConnectionsDataByID(name) {
        return $http.get(appSettings.serverPath + '/' + appSettings.version + '/dlp_connections/' + name).then(
          function (response) {
            return response.data;
          },
          function (response) {
            // Handle error here
          },
        );
      },
      deleteDLPConnectionsDataByID: function deleteDLPConnectionsDataByID(name) {
        return $http
          .delete(appSettings.serverPath + '/' + appSettings.version + '/dlp_connections/' + name)
          .then(function (response) {
            return response.data;
          });
      },

      updateDLPConnectionsDataByID: function updateDLPConnectionsDataByID(dlp_connection, id) {
        return $http
          .put(appSettings.serverPath + '/' + appSettings.version + '/dlp_connections/' + id, dlp_connection)
          .then(function (response) {
            notificationService.success('Updated ' + id + ' Successfully! ');
          });
      },

      testDataHubConnection: function (data) {
        return $http
          .post(appSettings.serverPath + '/' + appSettings.version + '/data-hub-connection-test/', data)
          .then(function (res) {
            return res;
          });
      },

      importFromFile: function importFromFile(data) {
        return $http
          .post(appSettings.serverPath + '/' + appSettings.version + '/systems/import_from_file', data, {
            transformRequest: angular.identity,
            headers: {
              'Content-Type': undefined,
            },
          })
          .then(function (response) {
            return response.data;
          });
      },
    };
  },
]);
