import React, { FC } from 'react';
import { ErrorInsights } from './mappers/styles';
import {
  BigidLink,
  BigidColorsV2,
  BigidBody1,
  SecondaryButton,
  StyledButtonType,
  BigidIcon,
  BigidIconLabelPlacement,
  TertiaryButton,
} from '@bigid-ui/components';
import { ADVANCE_TOOLS_PERMISSIONS } from '@bigid/permissions';
import { ExpandMoreRounded } from '@mui/icons-material';
import { BigidLikeIcon, BigidDislikeIcon, BigidAiIcon } from '@bigid-ui/icons';
import { Typography } from '@mui/material';
import { useLocalTranslation } from './translations';
import { useDataSourceModalContext } from './hooks/useDataSourceModalContext';
import { usePublishCustomBiEvent } from '../../../hooks/usePublishCustomBiEvent';
import { MIN_ERROR_OPTIONS_VISIBLE } from './constants/constants';
import { DataSourceConnectionErrorInsightsOption } from './DataSourceConnectionErrorInsightsOption';
import { isPermitted } from '../../../services/userPermissionsService';
import type { TestConnectionErrorResolution } from './hooks/useTestConnection';

type ButtonControls = StyledButtonType & { component: FC<StyledButtonType> };

type DataSourceConnectionErrorInsightsContentProps = {
  dataAid?: string;
  options: { content: string; title: string; id: string }[];
  resolution: TestConnectionErrorResolution;
  authType: string;
  onViewLogs: () => void;
  onShowMore: () => void;
  onSupport: () => void;
  shouldShowMore: boolean;
};

type DataSourceConnectionErrorInsightsOption = DataSourceConnectionErrorInsightsContentProps['options'][0];

const style = {
  color: BigidColorsV2.blue[900],
  display: 'flex',
  alignItems: 'center',
  gap: '0.1rem',
};

const getOptionCount = (index: number) => index + 1;

export const DataSourceConnectionErrorInsightsContent = ({
  dataAid = 'DataSourceConnectionErrorInsightsContent',
  options,
  resolution,
  authType,
  shouldShowMore,
  onViewLogs,
  onShowMore,
  onSupport,
}: DataSourceConnectionErrorInsightsContentProps): JSX.Element => {
  const { t } = useLocalTranslation();
  const { configDataSourceState } = useDataSourceModalContext();
  const { mutateAsync: publishCustomBiEvent } = usePublishCustomBiEvent();

  const isOptionsEmpty = !options.length;

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const handleRateSolution =
    ({ title }: DataSourceConnectionErrorInsightsOption, isSolutionHelpful: boolean) =>
    () => {
      const { detailedErrorMessage, summarizedErrorMessage, errorCode } = resolution;

      const payload = {
        type: 'connectivityExperience',
        subType: 'rateResolutionStep',
        additionalProperties: {
          dataSourceType: configDataSourceState?.selectedType,
          authType: authType ?? 'default',
          stepResolution: title,
          detailedErrorMessage,
          errorMessage: summarizedErrorMessage,
          errorCode,
          helpful: isSolutionHelpful,
        },
      };

      publishCustomBiEvent({ data: payload });
    };

  const createResolutionControls = (option?: DataSourceConnectionErrorInsightsOption): ButtonControls[] => [
    {
      component: TertiaryButton,
      dataAid: `${dataAid}-RateSolutionOk`,
      startIcon: <BigidLikeIcon />,
      size: 'small',
      onClick: handleRateSolution(option, true),
      text: t('buttons.yes'),
    },
    {
      component: TertiaryButton,
      dataAid: `${dataAid}-RateSolutionNotOk`,
      startIcon: <BigidDislikeIcon />,
      size: 'small',
      onClick: handleRateSolution(option, false),
      text: t('buttons.no'),
    },
  ];

  return (
    <ErrorInsights.Content>
      {isPermitted(ADVANCE_TOOLS_PERMISSIONS.EXPORT_SERVICES_LOGS.name) && (
        <ErrorInsights.Logs>
          <BigidLink underline="none" text={t('buttons.logs')} onClick={onViewLogs}></BigidLink>
        </ErrorInsights.Logs>
      )}
      {!isOptionsEmpty && <Typography variant="body1">{t('insights.header')}</Typography>}
      {options
        .map((option, index) => {
          const { content, title, id } = option;
          const controls = createResolutionControls(option);

          return (
            <DataSourceConnectionErrorInsightsOption
              dataAid={dataAid}
              title={title}
              subtitle={t('insights.option', { count: getOptionCount(index) })}
              content={content}
              controls={controls}
              id={id}
              key={id}
            />
          );
        })
        .filter((_, index) => (shouldShowMore ? true : index < MIN_ERROR_OPTIONS_VISIBLE))}
      {!shouldShowMore && (
        <Typography sx={[{ '& a': { color: BigidColorsV2.blue[900] } }]} style={style} variant="h6">
          <ExpandMoreRounded color="inherit" />
          <BigidLink underline="none" onClick={onShowMore} text={t('insights.showMore')} />
        </Typography>
      )}
      <ErrorInsights.BottomSection>
        <ErrorInsights.Support>
          <BigidBody1 paddingRight={'16px'}>{t('insights.support')}</BigidBody1>
          <SecondaryButton size="medium" onClick={onSupport} text={t('buttons.support')} bi={{ disabled: true }} />
        </ErrorInsights.Support>
      </ErrorInsights.BottomSection>
    </ErrorInsights.Content>
  );
};
