import { useState, useCallback, useEffect } from 'react';
import { CatalogRule } from '../../../CatalogRules/catalogRulesService';
import {
  DataCatalogAsyncOperationStatus,
  DataCatalogAsyncOperation,
  DataCatalogAsyncOperationRoutingKey,
  DataCatalogAsyncOperationListenerParams,
  DataCatalogAsyncOperationListenerResponse,
} from '../DataCatalogAsyncOpsTypes';
import { subscribeToRepeatedSSEEventById, SSEDataMessage } from '../../../../services/sseService';
import { AsyncOperation } from '../../../../components/AsyncOperationProcessingWidget/AsyncOperationProcessingWidget';

export type RulesBulkApplyingSseData = CatalogRule['name'][];

export type RulesBulkApplyingSseResults = DataCatalogAsyncOperation<never, any, RulesBulkApplyingSseData>;

export type UseRulesBulkApplyingParams = DataCatalogAsyncOperationListenerParams;
export type UseClusterBusinessAttrAssignmentResponse = DataCatalogAsyncOperationListenerResponse<AsyncOperation>;

export const useRulesBulkApplying = ({
  onOperationRun,
}: UseRulesBulkApplyingParams): UseClusterBusinessAttrAssignmentResponse => {
  const [operations, setOperations] = useState<AsyncOperation[]>([]);
  const [isApplyingInProcess, setIsApplyingInProcess] = useState<boolean>(false);

  useEffect(() => {
    onOperationRun(DataCatalogAsyncOperationRoutingKey.RULES_BULK_APPLYING, isApplyingInProcess);
  }, [isApplyingInProcess, onOperationRun]);

  const handleRulesBulkApplyingBroadcastEventReceived = useCallback(
    ({ results = [] }: SSEDataMessage<RulesBulkApplyingSseResults>) => {
      const operationsUpdated = results.reduce((operationsAggr, operation) => {
        const { status, totalObjects, percentage, sseData = [] } = operation;

        return [
          ...operationsAggr,
          {
            status,
            percentage:
              status === DataCatalogAsyncOperationStatus.RUNNING && typeof percentage === 'number'
                ? Math.round(percentage)
                : undefined,
            description: `${totalObjects} objects are updated with ${sseData.length} rules`,
          },
        ];
      }, []);

      setOperations(operationsUpdated);
      setIsApplyingInProcess(!!results.length);
    },
    [],
  );

  useEffect(() => {
    const unsubscribe = subscribeToRepeatedSSEEventById(
      DataCatalogAsyncOperationRoutingKey.RULES_BULK_APPLYING,
      handleRulesBulkApplyingBroadcastEventReceived,
    );

    return () => {
      unsubscribe();
    };
  }, [handleRulesBulkApplyingBroadcastEventReceived]);

  return { operations };
};
