import './newDataSourceConnection.component.scss';
import { module } from 'angular';
import { get as lodashGet, set as lodashSet } from 'lodash';
import { v4 as uuid } from 'uuid';
import { getCredentialTitle } from '../../../credentials/credentials.component.js';
import { getCopyOfCountries } from '../../../../config/countries';
import { publicUrls } from '../../../../react/config/publicUrls';
import { getCertificates as certificateManagementGetCertificate } from '../../../../react/views/CertificatesManagement/CertificatesManagement';
import template from './newDataSourceConnection.component.html';
import { getSupportedDataSources } from '../../../../react/utilities/dataSourcesUtils';
import { licenseService } from '../../../../react/services/licenseService';
import { notificationService } from '../../../../react/services/notificationService';
import '../../../../react/views/DataSources/DataSourcesTestConnectionGrid/DataSourcesTestConnectionGrid';
import { httpService } from '../../../../react/services/httpService';
import { DATA_SOURCES_PERMISSIONS, CREDENTIALS_PERMISSIONS } from '@bigid/permissions';
import { isPermitted } from '../../../../react/services/userPermissionsService';
import { smallIdLicenseService } from '../../../../react/services/smallIdLicenseService';
import { scanWindowService, NONE } from '../../../../react/views/ScanWindows/scanWindowService';
import { getApplicationPreference } from '../../../../react/services/appPreferencesService';
import { trackCreateNewDataSourceBiEvent } from '../../../../react/views/DataSources/DataSourcesEventTrackerUtils';
const app = module('app');

const NG_FILTER_DATE_FORMAT = 'medium';
const DIFFERENTIAL_DEFAULT_VALUE = false;
const IS_MODIFIELD_X_LAST_DAYS_DEFAULT_VALUE = false;

app.component('newdatasource', {
  template,
  controllerAs: 'newDataSourceConnectionModel',
  controller: function (
    $http,
    appSettings,
    $scope,
    $state,
    $stateParams,
    $appConfig,
    dataSourceConnectionsService,
    newDataSourceConnectionService,
    $rootScope,
    $q,
    $window,
    $timeout,
    DeleteConfirmation,
    credentialsService,
    $uibModal,
    classifiersService,
    $translate,
    $filter,
  ) {
    'ngInject';
    const getAdvancedClassifiers = () => classifiersService.getAdvancedClassifiers();
    const getKafkaConnectPlugins = async () => dataSourceConnectionsService.getAllKafkaConnectPlugins();
    const getDataSourceConnectionByID = dsConnectionId =>
      dataSourceConnectionsService.getDataSourceConnectionsDataByID(dsConnectionId);
    const getSupportedSplitPartDs = async () => dataSourceConnectionsService.getAllDSWithScanPartsSupport();
    const getScanWindows = async () => scanWindowService.getScanWindowsNames();

    const initializeData = async (resolveCallback, rejectCallback, dsConnectionId = null) => {
      try {
        let ds_connection = null;

        if (dsConnectionId !== null) {
          const dataSourceConnectionResponse = await getDataSourceConnectionByID(dsConnectionId);
          ds_connection = dataSourceConnectionResponse.ds_connection;
        }
        const advancedClassifiersResponse = await getAdvancedClassifiers();
        const { classifierAlgorithms: advancedClassifiers } = advancedClassifiersResponse.data;

        const allKafkaConnectPlugins = await getKafkaConnectPlugins();
        const supportedSplitPartType = await getSupportedSplitPartDs();
        const scanWindowsNames = await getScanWindows();
        $rootScope.$applyAsync();
        return resolveCallback({
          ds_connection,
          advancedClassifiers,
          allKafkaConnectPlugins,
          supportedSplitPartType,
          scanWindowsNames,
        });
      } catch (err) {
        return rejectCallback();
      }
    };

    //update time stamps after deletion or test
    $scope.updateTimeStamps = () => {
      if (dsConnectionId) {
        dataSourceConnectionsService.getDataSourceConnectionsDataByID(dsConnectionId).then(function (result) {
          if (result && result.ds_connection) {
            if (result.ds_connection.connectionStatusTest) {
              newDataSourceConnectionModel.fields.test_is_success =
                result.ds_connection.connectionStatusTest.is_success;
              newDataSourceConnectionModel.fields.test_timestamp =
                result.ds_connection.connectionStatusTest.last_connection;
            }
            if (result.ds_connection.connectionStatusDeleteFindings) {
              newDataSourceConnectionModel.fields.deletion_is_success =
                result.ds_connection.connectionStatusDeleteFindings.is_success;
              result.ds_connection.connectionStatusDeleteFindings.is_success;
              newDataSourceConnectionModel.fields.deletion_timestamp =
                result.ds_connection.connectionStatusDeleteFindings.last_connection;
            }
          }
        });
      }
    };

    const NER_CLASSIFIER_TYPE = 'NER';

    const CUSTOM_CONNECTOR_FIELD_TEXT_TYPE = 'clear';
    const CUSTOM_CONNECTOR_FIELD_PASSWORD_TYPE = 'encrypted';

    const CUSTOM_CONNECTOR_FIELD_TYPE_MAPPING = {
      [CUSTOM_CONNECTOR_FIELD_TEXT_TYPE]: 'text',
      [CUSTOM_CONNECTOR_FIELD_PASSWORD_TYPE]: 'password',
    };

    const CUSTOM_CONNECTOR_DEFAULT_FIELD = {
      field_name: '',
      field_value: '',
      field_type: CUSTOM_CONNECTOR_FIELD_TEXT_TYPE,
      input_type: CUSTOM_CONNECTOR_FIELD_TYPE_MAPPING[CUSTOM_CONNECTOR_FIELD_TEXT_TYPE],
      main_class_name: '',
      custom_connector_jar_name: '',
    };

    const getCustomFieldsDuplicateNames = () => {
      return newDataSourceConnectionModel.customConnectorFields
        .reduce((names, field) => {
          if (field['field_name'] !== '') {
            names = [...names, field['field_name']];
          }

          return names;
        }, [])
        .reduce((duplicates, name, index, names) => {
          if (names.indexOf(name) !== index && duplicates.indexOf(name) < 0) {
            duplicates = [...duplicates, name];
          }

          return duplicates;
        }, []);
    };

    const AZURE_DEFAULT_PROTOCOL = 'https';

    const MASKED_PASSWORD_STRING = '********';

    let availableTypeOptions;

    const apiFamily = [
      {
        name: 'Document Or Dossier Name',
        apiFamily: 'documentOrDossierName',
      },
      {
        name: 'Report Name',
        apiFamily: 'reportName',
      },
      {
        name: 'Folder Name',
        apiFamily: 'folderName',
      },
      {
        name: 'Attribute Name',
        apiFamily: 'attributeName',
      },
      {
        name: 'Prompt Name',
        apiFamily: 'promptName',
      },
      {
        name: 'Metric Name',
        apiFamily: 'metricName',
      },
      {
        name: 'Style Name',
        apiFamily: 'styleName',
      },
      {
        name: 'Consolidation Name',
        apiFamily: 'consolidationName',
      },
    ];

    const regionId = [
      {
        name: 'AWS - US West',
        regionId: 'us-west-2',
      },
      {
        name: 'AWS - US East',
        regionId: 'us-east-1',
      },
      {
        name: 'AWS - EU (Dublin)',
        regionId: 'eu-west-1',
      },
      {
        name: 'AWS - EU (Frankfurt)',
        regionId: 'eu-central-1',
      },
      {
        name: 'AWS - Asia Pacific (Sydney)',
        regionId: 'ap-southeast-2',
      },
      {
        name: 'Azure - East US 2',
        regionId: 'east-us-2.azure',
      },
      {
        name: 'Azure - West US 2',
        regionId: 'west-us-2.azure',
      },
      {
        name: 'Azure - US Gov Virginia',
        regionId: 'us-gov-virginia.azure',
      },
      {
        name: 'Azure - Canada Central',
        regionId: 'canada-central.azure',
      },
      {
        name: 'Azure - West Europe',
        regionId: 'west-europe.azure',
      },
      {
        name: 'Azure - Australia East',
        regionId: 'australia-east.azure',
      },
      {
        name: 'Azure - Southeast Asia',
        regionId: 'southeast-asia.azure',
      },
    ];

    const availableSecurityOptions = [
      { id: '1', name: '1' },
      { id: '2', name: '2' },
      { id: '3', name: '3' },
      { id: '4', name: '4' },
      { id: '5', name: '5' },
    ];

    const SerializerTypes = {
      String: 'String',
      Integer: 'Integer',
      Avro: 'Avro',
    };

    const availableSerializerOptions = Object.keys(SerializerTypes).map((key, index) => ({
      id: index + 1,
      name: key,
    }));

    const availableEnableOptions = [
      { id: '1', name: 'Yes' },
      { id: '2', name: 'No' },
    ];

    const availableScannerStrategyOptions = [
      { id: '1', name: 'SCAN_ALL' },
      { id: '2', name: 'SCAN_OWNER' },
      { id: '3', name: 'SCAN_SEARCH' },
    ];

    const availableOcrLanguagesOptions = [
      { id: 'eng', name: 'English' },
      { id: 'chi_sim+chi_tra', name: 'Chinese' },
      { id: 'ind', name: 'Indonesian' },
      { id: 'jpn', name: 'Japanese' },
      { id: 'kor', name: 'Korean' },
      { id: 'tha', name: 'Thai' },
      { id: 'vie', name: 'Vietnamese' },
      { id: 'deu', name: 'German' },
      { id: 'fra', name: 'French' },
      { id: 'bul', name: 'Bulgarian' },
    ];

    const AnonymousAuth = 0b001;
    const IamRoleAuth = 0b010;
    const CredentialsAuth = 0b100;
    const CrossAccountAuth = 0b1000;
    const STSAuth = 0b10000;

    const availableAwsAuthenticationOptions = [
      { id: 0b100, name: 'Credentials Authentication' },
      { id: 0b010, name: 'IAM Role Authentication' },
      { id: 0b001, name: 'Anonymous Authentication' },
      { id: 0b1000, name: 'Cross Account Authentication' },
      { id: 0b10100, name: 'STS Authentication' },
    ];

    const dsConnectionId = $stateParams.id;

    const newDataSourceConnectionModel = this;

    newDataSourceConnectionModel.updatedPasswords = [];

    newDataSourceConnectionModel.dataLoading = false;
    newDataSourceConnectionModel.realPass = '';
    newDataSourceConnectionModel.isTestConnectionSucceeded = false;
    newDataSourceConnectionModel.isNewPassword = false;
    newDataSourceConnectionModel.isNewSessionToken = false;
    newDataSourceConnectionModel.isFormSubmitted = false;
    newDataSourceConnectionModel.isCustomFieldsVisible = false;
    newDataSourceConnectionModel.hadoopCustomProperties = [
      {
        name: '',
        value: '',
      },
    ];
    newDataSourceConnectionModel.customPartitionConditions = [
      {
        tableName: '',
        partitionCondition: '',
      },
    ];
    newDataSourceConnectionModel.apiFamilyProperties = [
      {
        scan: '',
        filter: '',
      },
    ];

    newDataSourceConnectionModel.customConnectorFieldAvailableTypes = [
      { id: CUSTOM_CONNECTOR_FIELD_TEXT_TYPE, name: 'Clear text' },
      { id: CUSTOM_CONNECTOR_FIELD_PASSWORD_TYPE, name: 'Encrypted' },
    ];
    newDataSourceConnectionModel.customConnectorFieldsDuplicateNames = [];
    newDataSourceConnectionModel.customConnectorFields = [Object.assign({}, CUSTOM_CONNECTOR_DEFAULT_FIELD)];

    newDataSourceConnectionModel.credentialWindowEntity = {};
    newDataSourceConnectionModel.MIN_X_DAYS = 1;
    newDataSourceConnectionModel.DEFAULT_X_DAYS = 7;
    newDataSourceConnectionModel.MAX_X_DAYS = 1000;

    newDataSourceConnectionModel.MIN_SECONDS = 10;
    newDataSourceConnectionModel.MAX_SECONDS = 7200;

    newDataSourceConnectionModel.removeTestConnectionListener = null;

    newDataSourceConnectionModel.$onDestroy = () => {
      if (newDataSourceConnectionModel.removeTestConnectionListener) {
        newDataSourceConnectionModel.removeTestConnectionListener();
      }
    };

    $scope.newDataSourceConnectionModelGrid = {
      data: [],
    };

    newDataSourceConnectionModel.country = {};
    newDataSourceConnectionModel.countries = [];
    newDataSourceConnectionModel.credentials = [];
    newDataSourceConnectionModel.certificates = [];

    const countrySearch = search =>
      newDataSourceConnectionModel.countries.find(({ displayName }) => displayName === search) ? search : null;

    const supportsAcl = type => {
      switch (type) {
        case 'smb':
        case 's3':
        case 'googledrive':
        case 'o365-onedrive':
        case 'google-cloud-storage':
        case 'sharepoint-online':
          return true;
        default:
          return false;
      }
    };

    const isFileScanner = type => {
      switch (type) {
        case 'nfs':
        case 'smb':
        case 's3':
        case 'csv':
        case 'azure-blob':
        case 'azure-file':
        case 'onedrive-personal':
        case 'googledrive':
        case 'box':
        case 'sharepoint':
        case 'o365-outlook':
        case 'o365-onedrive':
        case 'gmail':
        case 'sharepoint-online':
        case 'google-cloud-storage':
        case 'documentum':
        case 'generic-rest-api-unstructured':
        case 'gitlab':
        case 'github':
          return true;
        default:
          return false;
      }
    };

    const isHadoopScanner = type => {
      switch (type) {
        case 'hadoop':
        case 'emr':
          return true;
        default:
          return false;
      }
    };

    const isSampleScanOnly = type => {
      switch (type) {
        case 'rdb-mysql':
        case 'rdb-mssql':
        case 'rdb-postgresql':
        case 'rdb-oracle':
        case 'rdb-hana':
        case 'rdb-sybase':
        case 'rdb-db2':
        case 'rdb-db2iseries':
        case 'rdb-activedirectory':
        case 'rdb-teradata':
        case 'cassandra':
        case 'hive':
        case 'hive-jdbc':
        case 'hbase':
        case 'dynamodb':
        case 'mongodb':
        case 'salesforce':
        case 'servicenow':
        case 'servicenow-structured':
        case 'presto':
        case 'generic-rest-api':
        case 'generic-rest-api-unstructured':
        case 'vsam':
        case 'informix':
        case 'workday':
        case 'elasticsearch':
        case 'redis':
        case 'netezza':
        case 'yellowbrick':
        case 'hadoop-hawq':
        case 'jira':
        case 'microsoft-teams':
        case 'vertica':
        case 'databricks':
          return true;
        default:
          return false;
      }
    };

    const isIDSORsupported = type => {
      switch (type) {
        case 'rdb-mysql':
        case 'rdb-mssql':
        case 'rdb-postgresql':
        case 'rdb-hana':
        case 'rdb-sybase':
        case 'rdb-oracle':
        case 'rdb-db2':
        case 'rdb-db2iseries':
        case 'rdb-activedirectory':
        case 'rdb-teradata':
        case 'cassandra':
        case 'hive-jdbc':
        case 'salesforce':
        case 'mongodb':
        case 'snowflake':
        case 'athena':
        case 'csv':
        case 'sap':
        case 'cims':
        case 'zendesk':
        case 'informix':
        case 'workday':
        case 'ldap':
        case 'hbase':
        case 'netezza':
        case 'presto':
        case 'vertica':
        case 'yellowbrick':
        case 'hadoop-hawq':
        case 'gcp-big-query':
        case 'generic-rest-api':
        case 'salesforce-marketing-cloud':
        case 'microstrategy':
        case 'servicenow':
        case 'servicenow-structured':
        case 'sparksql':
          return true;
        default:
          return false;
      }
    };

    const isNativeIndex = type => {
      switch (type) {
        case 'googledrive':
        case 'box':
        case 'elasticsearch':
        case 'sharepoint-online':
        case 'sharepoint':
        case 'o365-onedrive':
        case 'documentum':
        case 'generic-rest-api-unstructured':
        case 'o365-outlook':
          return true;
        default:
          return false;
      }
    };

    const clearAdminUserIfNotificationIsFalse = () => {
      if (!newDataSourceConnectionModel.fields.isSuppressNotification) {
        newDataSourceConnectionModel.fields.adminUser = null;
      }
    };
    let fieldsToNullify;
    const set_ds_connections = isTest => {
      $scope.ds_connection = {};
      if (!angular.isDefined(isTest)) {
        fieldsToNullify = [
          'password',
          'aws_secret_key',
          'aws_session_token',
          'client_secret',
          'activeDirectoryPassword',
          'exchangeServerPassword',
          'es_password',
          'aws_key_secret',
          'apiToken',
        ];
        fieldsToNullify.forEach(nullifyEncryptedFieldIfUnchanged);
        newDataSourceConnectionModel.fields.owners = newDataSourceConnectionModel.fields.owners.filter(
          owner => owner != '',
        );
      }

      const type = newDataSourceConnectionModel.fieldsDDL.selectedTypeOption.name?.toLowerCase();
      fieldsToNullify = ['access_token', 'refresh_token', 'google_service_account_json', 'box_service_account_json'];
      if (
        !(type == 'o365-outlook') &&
        !(type == 'o365-onedrive') &&
        !(type == 'servicenow') &&
        !(type == 'servicenow-structured') &&
        !(type == 'sharepoint-online')
      ) {
        fieldsToNullify.push('client_secret');
      }
      fieldsToNullify.forEach(nullifyEncryptedFieldIfUnchanged);

      if (!newDataSourceConnectionModel.fields.rdb_is_sample_data) {
        delete newDataSourceConnectionModel.fields.rdb_sample_data_max_size;
      }

      function nullifyEncryptedFieldIfUnchanged(fieldName) {
        if (lodashGet(newDataSourceConnectionModel.fields, fieldName) === MASKED_PASSWORD_STRING) {
          lodashSet(newDataSourceConnectionModel.fields, fieldName, null);
        } else if (fieldName !== 'password' && newDataSourceConnectionModel.fields[fieldName] === '') {
          delete newDataSourceConnectionModel.fields[fieldName];
        }
      }

      function nullifyOldFieldsValuesIfNotRelevant(fieldName) {
        if (dsConnectionId) {
          lodashSet(newDataSourceConnectionModel.fields, fieldName, null);
          $scope.ds_connection.ds_connection[fieldName] = '';
        } else {
          delete newDataSourceConnectionModel.fields[fieldName];
          delete $scope.ds_connection.ds_connection[fieldName];
        }
      }

      if (type == 'box') {
        clearAdminUserIfNotificationIsFalse();
      }

      $scope.ds_connection.ds_connection = angular.copy(newDataSourceConnectionModel.fields);
      $scope.ds_connection.ds_connection.updatedPasswords = angular.copy(newDataSourceConnectionModel.updatedPasswords);

      if (type == 's3' || type == 'emr' || type == 'dynamodb' || type == 'athena') {
        if (!newDataSourceConnectionModel.fields.isCredentialsAuth) {
          newDataSourceConnectionModel.realAwsSecretKey = '';
          nullifyOldFieldsValuesIfNotRelevant('aws_access_key');
          nullifyOldFieldsValuesIfNotRelevant('aws_secret_key');
          if (type == 'dynamodb' || type == 'athena') {
            newDataSourceConnectionModel.realAwsSessionToken = '';
            nullifyOldFieldsValuesIfNotRelevant('aws_session_token');
          }
        }
        if (!newDataSourceConnectionModel.fields.isCrossAccountAuth) {
          nullifyOldFieldsValuesIfNotRelevant('aws_role_arn');
          nullifyOldFieldsValuesIfNotRelevant('aws_role_session_name');
        }
        if (!newDataSourceConnectionModel.fields.isSTSAuth) {
          newDataSourceConnectionModel.realAwsSessionToken = '';
          nullifyOldFieldsValuesIfNotRelevant('aws_session_token');
        }
        delete $scope.ds_connection.ds_connection.awsAuthStrategy;
      }

      $scope.ds_connection.ds_connection.type = type;

      if (angular.isDefined(isTest)) {
        $scope.ds_connection.isNewPassword = newDataSourceConnectionModel.isNewPassword;
        $scope.ds_connection.isNewSessionToken = newDataSourceConnectionModel.isNewSessionToken;
        // update
        if (dsConnectionId) {
          if (type == 'dynamodb' || type == 's3' || type == 'athena') {
            $scope.ds_connection.ds_connection.aws_secret_key = !newDataSourceConnectionModel.isNewPassword
              ? newDataSourceConnectionModel.realAwsSecretKey
              : newDataSourceConnectionModel.fields.aws_secret_key;
            $scope.ds_connection.ds_connection.aws_session_token = !newDataSourceConnectionModel.isNewSessionToken
              ? newDataSourceConnectionModel.realAwsSessionToken
              : newDataSourceConnectionModel.fields.aws_session_token;
          } else if (type == 'azure-blob' || type == 'azure-file') {
            $scope.ds_connection.ds_connection.accountKey = !newDataSourceConnectionModel.isNewPassword
              ? newDataSourceConnectionModel.realAzureAccountKey
              : newDataSourceConnectionModel.fields.accountKey;
          } else if (isOauthConnector(type)) {
            $scope.ds_connection.ds_connection.access_token = newDataSourceConnectionModel.realAccessToken;
            $scope.ds_connection.ds_connection.refresh_token = newDataSourceConnectionModel.realRefreshToken;
            $scope.ds_connection.ds_connection.client_secret = newDataSourceConnectionModel.realClientSecret;
            if (newDataSourceConnectionModel.fields.isGoogleServiceAccount) {
              $scope.ds_connection.ds_connection.google_service_account_json =
                !newDataSourceConnectionModel.isNewPassword
                  ? newDataSourceConnectionModel.realGoogleServiceAccountJson
                  : newDataSourceConnectionModel.fields.google_service_account_json;
            }
          } else if (type == 'box') {
            $scope.ds_connection.ds_connection.box_service_account_json = !newDataSourceConnectionModel.isNewPassword
              ? newDataSourceConnectionModel.realBoxServiceAccountJson
              : newDataSourceConnectionModel.fields.box_service_account_json;
          } else if (
            type == 'o365-outlook' ||
            type == 'o365-onedrive' ||
            type == 'servicenow' ||
            type == 'servicenow-structured' ||
            type == 'sharepoint-online' ||
            type == 'salesforce-marketing-cloud'
          ) {
            $scope.ds_connection.ds_connection.client_secret = !newDataSourceConnectionModel.isNewPassword
              ? newDataSourceConnectionModel.realClientSecret
              : newDataSourceConnectionModel.fields.client_secret;

            if (type == 'sharepoint-online') {
              if ($scope.ds_connection.ds_connection.folders_filter_enabled === false) {
                $scope.ds_connection.ds_connection.path_to_scan = null;
                $scope.ds_connection.ds_connection.folder_to_scan_regex = null;
              }
              if ($scope.ds_connection.ds_connection.is_exclude_file_types === false) {
                $scope.ds_connection.ds_connection.fileTypesToExclude = null;
              }
            }
          } else if (type == 'exchange') {
            if (newDataSourceConnectionModel.updatedPasswords.includes('activeDirectoryPassword')) {
              $scope.ds_connection.ds_connection.activeDirectoryPassword =
                newDataSourceConnectionModel.fields.activeDirectoryPassword;
            } else {
              $scope.ds_connection.ds_connection.activeDirectoryPassword =
                newDataSourceConnectionModel.realActiveDirectoryPassword;
            }

            if (newDataSourceConnectionModel.updatedPasswords.includes('exchangeServerPassword')) {
              $scope.ds_connection.ds_connection.exchangeServerPassword =
                newDataSourceConnectionModel.fields.exchangeServerPassword;
            } else {
              $scope.ds_connection.ds_connection.exchangeServerPassword =
                newDataSourceConnectionModel.realExchangeServerPassword;
            }
          } else if (type == 'jira') {
            if (newDataSourceConnectionModel.updatedPasswords.includes('apiToken')) {
              $scope.ds_connection.ds_connection.apiToken = newDataSourceConnectionModel.fields.apiToken;
            } else {
              $scope.ds_connection.ds_connection.apiToken = newDataSourceConnectionModel.realApiToken;
            }
          } else if (type === 'elasticsearch' || type === 'kinesis') {
            if (newDataSourceConnectionModel.updatedPasswords.includes('aws_key_secret')) {
              $scope.ds_connection.ds_connection.aws_key_secret = newDataSourceConnectionModel.fields.aws_key_secret;
            } else {
              $scope.ds_connection.ds_connection.aws_key_secret = newDataSourceConnectionModel.realAwsKeySecret;
            }
            if (newDataSourceConnectionModel.updatedPasswords.includes('es_password')) {
              $scope.ds_connection.ds_connection.es_password = newDataSourceConnectionModel.fields.es_password;
            } else {
              $scope.ds_connection.ds_connection.es_password = newDataSourceConnectionModel.real_es_password;
            }
          } else if (type == 'workday' && newDataSourceConnectionModel.fields.credential_id) {
            $scope.ds_connection.ds_connection.password = '';
            $scope.ds_connection.ds_connection.password = '';
          } else {
            $scope.ds_connection.ds_connection.password = !newDataSourceConnectionModel.isNewPassword
              ? newDataSourceConnectionModel.realPass
              : newDataSourceConnectionModel.fields.password;
          }
        }
        // new connection
        else {
          if (type == 'dynamodb' || type == 's3' || type == 'athena') {
            $scope.ds_connection.ds_connection.aws_secret_key = newDataSourceConnectionModel.fields.aws_secret_key;
            $scope.ds_connection.ds_connection.aws_session_token =
              newDataSourceConnectionModel.fields.aws_session_token;
          } else if (type == 'azure-blob' || type == 'azure-file') {
            $scope.ds_connection.ds_connection.accountKey = newDataSourceConnectionModel.fields.accountKey;
          } else if (
            type == 'o365-outlook' ||
            type == 'o365-onedrive' ||
            type == 'servicenow' ||
            type == 'servicenow-structured' ||
            type == 'sharepoint-online'
          ) {
            $scope.ds_connection.ds_connection.client_secret = newDataSourceConnectionModel.fields.client_secret;
          } else {
            $scope.ds_connection.ds_connection.password = newDataSourceConnectionModel.fields.password;
          }
        }
      }

      if (newDataSourceConnectionModel.country.selected) {
        $scope.ds_connection.ds_connection.location = newDataSourceConnectionModel.country.selected.name;
      }

      $scope.ds_connection.ds_connection.security_tier =
        newDataSourceConnectionModel.fieldsDDL.selectedSecurityTierOption.name;
      $scope.ds_connection.ds_connection.ocr_languages =
        newDataSourceConnectionModel.fieldsDDL.selectedOcrLanguagesOption.id;
      $scope.ds_connection.ds_connection.scanner_strategy =
        newDataSourceConnectionModel.fieldsDDL.selectedScannerStrategyOption.name;

      $scope.ds_connection.ds_connection.enabled =
        newDataSourceConnectionModel.fieldsDDL.selectedEnabledOption.name.toLowerCase();

      $scope.ds_connection.ds_connection.differential = newDataSourceConnectionModel.fields.differential;

      $scope.ds_connection.ds_connection.keyDeserializer = newDataSourceConnectionModel.fieldsDDL.keyDeserializer.name;

      $scope.ds_connection.ds_connection.valueDeserializer =
        newDataSourceConnectionModel.fieldsDDL.valueDeserializer.name;

      if (type === 'kafkaconnect') {
        $scope.ds_connection.ds_connection.kafkaConnectPlugin =
          newDataSourceConnectionModel.fieldsDDL.selectedKafkaConnectPlugin.name;
      }
      if (type === 'sharepoint-online') {
        if ($scope.ds_connection.ds_connection.folders_filter_enabled === false) {
          $scope.ds_connection.ds_connection.path_to_scan = null;
          $scope.ds_connection.ds_connection.folder_to_scan_regex = null;
        }
        if ($scope.ds_connection.ds_connection.is_exclude_file_types === false) {
          $scope.ds_connection.ds_connection.fileTypesToExclude = null;
        }
      }
      // TODO: Not sure where I can put this validation check, so I just put it here
      if (!$scope.ds_connection.ds_connection.differential) {
        // if not defined, remove the following properties
        delete $scope.ds_connection.ds_connection.is_modified_in_x_days;
        delete $scope.ds_connection.ds_connection.x_last_days;
      } else if (typeof $scope.ds_connection.ds_connection.is_modified_in_x_days !== 'boolean') {
        $scope.ds_connection.ds_connection.is_modified_in_x_days = false;
      }

      if (isIDSORsupported(type)) {
        $scope.ds_connection.ds_connection.is_idsor_supported = true;
      }

      if (
        newDataSourceConnectionModel.isHadoop ||
        newDataSourceConnectionModel.isEMR ||
        newDataSourceConnectionModel.isHBase
      ) {
        $scope.ds_connection.ds_connection.custom_properties =
          newDataSourceConnectionModel.hadoopCustomProperties.filter(pair => pair.name != '' && pair.value != '');
      }

      if (type == 'hadoop' || type == 'hbase' || type == 'emr') {
        if ($scope.ds_connection.ds_connection.custom_properties) {
          $scope.ds_connection.ds_connection.custom_properties.forEach(item => delete item.$$hashKey);
        }
      }

      if (newDataSourceConnectionModel.isBigQuery) {
        $scope.ds_connection.ds_connection.custom_partition_conditions =
          newDataSourceConnectionModel.customPartitionConditions.filter(
            pair => pair.tableName !== '' && pair.partitionCondition !== '',
          );
      }

      if ('gcp-big-query' === type) {
        if ($scope.ds_connection.ds_connection.custom_partition_conditions) {
          $scope.ds_connection.ds_connection.custom_partition_conditions.forEach(item => delete item.$$hashKey);
        }
      }

      if (
        typeof newDataSourceConnectionModel.fields.is_credential == 'undefined' ||
        !newDataSourceConnectionModel.fields.is_credential
      ) {
        $scope.ds_connection.ds_connection.credential_id = '';
      }

      if (!newDataSourceConnectionModel.fields.is_certificate) {
        $scope.ds_connection.ds_connection.certificate_id = '';
      }

      if (isNativeIndex(type)) {
        $scope.ds_connection.ds_connection.is_native_index = true;
      }

      $scope.ds_connection.ds_connection.client_secret;

      if (newDataSourceConnectionModel.isCustomConnector) {
        $scope.ds_connection.ds_connection.custom_connector_type =
          newDataSourceConnectionModel.fields.custom_connector_type;
        $scope.ds_connection.ds_connection.main_class_name = newDataSourceConnectionModel.fields.main_class_name;
        $scope.ds_connection.ds_connection.custom_connector_jar_name =
          newDataSourceConnectionModel.fields.custom_connector_jar_name;
      }

      if (newDataSourceConnectionModel.areCustomFieldsSupported()) {
        $scope.ds_connection.ds_connection.custom_fields = newDataSourceConnectionModel.customConnectorFields
          .filter(field => field.field_name != '' && field.field_value != '')
          .reduce((aggregatedFields, field) => {
            const _field = {};

            _field['field_name'] = field['field_name'];
            _field['field_type'] = field['field_type'];

            if (field['field_type'] === CUSTOM_CONNECTOR_FIELD_PASSWORD_TYPE) {
              if (typeof field['is_changed'] == 'undefined') {
                _field['field_value'] = field['field_original_value'];
              } else {
                _field['is_changed'] = field['is_changed'];
                _field['field_value'] = field['field_value'];
              }
            } else {
              _field['field_value'] = field['field_value'];
            }

            aggregatedFields = [...aggregatedFields, _field];

            return aggregatedFields;
          }, []);
      }
    };

    const getSelectedObject = (typeName, availableTypeOptions) => {
      let id;
      let name;
      for (let i = 0; i < availableTypeOptions.length; i++) {
        if (availableTypeOptions[i].name.toLowerCase() === typeName.toLowerCase()) {
          id = availableTypeOptions[i].id;
          name = availableTypeOptions[i].name;
          break;
        }
      }

      return { id: id, name: name };
    };

    const getSelectedLanguage = (typeName, availableTypeOptions) => {
      let id;
      let name;
      for (let i = 0; i < availableTypeOptions.length; i++) {
        if (availableTypeOptions[i].id === typeName) {
          id = availableTypeOptions[i].id;
          name = availableTypeOptions[i].name;
          break;
        }
      }

      return { id: id, name: name };
    };

    const unsetModelTypes = () => {
      return $q((resolve, reject) => {
        for (const i in availableTypeOptions) {
          newDataSourceConnectionModel[availableTypeOptions[i]['modelProp']] = false;
        }

        resolve();
      });
    };

    const setModelType = prop => {
      unsetModelTypes().then(() => {
        newDataSourceConnectionModel[prop] = true;
      });
    };

    $scope.getCountries = sel => {
      if (sel.search && !sel.clickTriggeredSelect) {
        if (!sel.selected || sel.selected.displayName != sel.search) {
          //Search for an existing entry for the given name
          let newOne = countrySearch(sel.search);
          if (newOne === null) {
            //Create a new entry since one does not exist
            newOne = { name: sel.search, displayName: sel.search, 'alpha-2': sel.search };
            newDataSourceConnectionModel.countries.push(newOne);
          }
          //Make the found or created entry the selected one
          sel.selected = newOne;
        }
      }
      sel.search = ''; //optional clearing of search pattern
    };

    newDataSourceConnectionModel.fields = {};
    newDataSourceConnectionModel.fieldsDDL = {};
    newDataSourceConnectionModel.fields.owners = [''];
    newDataSourceConnectionModel.totalOwners = () => {
      return newDataSourceConnectionModel.fields.owners.filter(owner => owner != '').length;
    };

    newDataSourceConnectionModel.isNerClassifierFlagEnabled = false;

    const getNerClassifierFeatureIsOn = initializationData => {
      return (
        initializationData.advancedClassifiers &&
        initializationData.advancedClassifiers.nerClassifiers &&
        initializationData.advancedClassifiers.nerClassifiers.featureFlag
      );
    };

    if (dsConnectionId) {
      newDataSourceConnectionModel.dataLoading = true;

      initializeData(
        result => {
          if (result.ds_connection !== null) {
            newDataSourceConnectionModel.isSmallID = smallIdLicenseService.isSmallIDLicense();
            newDataSourceConnectionModel.isNerClassifierFlagEnabled = getNerClassifierFeatureIsOn(result);
            newDataSourceConnectionModel.realPass = angular.copy(result.ds_connection.password);
            newDataSourceConnectionModel.realAwsSecretKey = angular.copy(result.ds_connection.aws_secret_key);
            newDataSourceConnectionModel.realAwsSessionToken = angular.copy(result.ds_connection.aws_session_token);
            newDataSourceConnectionModel.realAzureAccountKey = angular.copy(result.ds_connection.accountKey);
            newDataSourceConnectionModel.realAccessToken = angular.copy(result.ds_connection.access_token);
            newDataSourceConnectionModel.realRefreshToken = angular.copy(result.ds_connection.refresh_token);
            newDataSourceConnectionModel.realActiveDirectoryPassword = angular.copy(
              result.ds_connection.activeDirectoryPassword,
            );
            newDataSourceConnectionModel.realExchangeServerPassword = angular.copy(
              result.ds_connection.exchangeServerPassword,
            );
            newDataSourceConnectionModel.real_es_password = angular.copy(result.ds_connection.es_password);
            newDataSourceConnectionModel.realApiToken = angular.copy(result.ds_connection.apiToken);
            newDataSourceConnectionModel.realGoogleServiceAccountJson = angular.copy(
              result.ds_connection.google_service_account_json,
            );
            newDataSourceConnectionModel.realClientSecret = angular.copy(result.ds_connection.client_secret);
            newDataSourceConnectionModel.realAwsKeySecret = angular.copy(result.ds_connection.aws_key_secret);

            newDataSourceConnectionModel.fields.origin = result.ds_connection.origin;
            newDataSourceConnectionModel.fields.created_at = result.ds_connection.created_at;
            newDataSourceConnectionModel.fields.updated_at = result.ds_connection.updated_at;

            newDataSourceConnectionModel.fields.name = result.ds_connection.name;
            newDataSourceConnectionModel.fields.archived = result.ds_connection.archived;
            newDataSourceConnectionModel.fields.comment = result.ds_connection.comment;
            newDataSourceConnectionModel.fields.friendly_name = result.ds_connection.friendly_name;
            newDataSourceConnectionModel.fields.scanner_group = result.ds_connection.scanner_group;
            newDataSourceConnectionModel.lastScanDate = getLastScanDate(result.ds_connection.last_scan_at);
            newDataSourceConnectionModel.fields.owners =
              result.ds_connection.owners && result.ds_connection.owners.length > 0
                ? result.ds_connection.owners
                : [''];

            if (newDataSourceConnectionModel.isShowScanWindow()) {
              newDataSourceConnectionModel.scanWindowEnabled =
                result.ds_connection.scanWindowName && result.ds_connection.scanWindowName !== NONE;
              newDataSourceConnectionModel.scanWindowsNamesList = buildScanWindowModel(result.scanWindowsNames);
              newDataSourceConnectionModel.fields.scanWindowName =
                newDataSourceConnectionModel.scanWindowsNamesList.find(
                  sw => sw.name === result.ds_connection.scanWindowName,
                );
              newDataSourceConnectionModel.supportedSplitPartTypeNames = result.supportedSplitPartType;
              newDataSourceConnectionModel.isSplitPartSupported =
                newDataSourceConnectionModel.supportedSplitPartTypeNames?.includes(result.ds_connection.type);
            }

            if (typeof result.ds_connection.adDomain != 'undefined' && result.ds_connection.adDomain !== null) {
              newDataSourceConnectionModel.fields.adDomain = angular.copy(result.ds_connection.adDomain);
            }

            if (result.ds_connection.connectionStatusDeleteFindings) {
              newDataSourceConnectionModel.fields.deletion_is_success =
                result.ds_connection.connectionStatusDeleteFindings.is_success;
              newDataSourceConnectionModel.fields.deletion_timestamp =
                result.ds_connection.connectionStatusDeleteFindings.last_connection;
            }

            if (result.ds_connection.connectionStatusScan) {
              newDataSourceConnectionModel.fields.scan_is_success =
                result.ds_connection.connectionStatusScan.is_success;
              newDataSourceConnectionModel.fields.scan_timestamp =
                result.ds_connection.connectionStatusScan.last_connection;
              newDataSourceConnectionModel.fields.scan_num_of_objects =
                result.ds_connection.connectionStatusScan.num_of_object;
            }

            if (result.ds_connection.connectionStatusTest) {
              newDataSourceConnectionModel.fields.test_is_success =
                result.ds_connection.connectionStatusTest.is_success;
              newDataSourceConnectionModel.fields.test_timestamp =
                result.ds_connection.connectionStatusTest.last_connection;
            }

            if (typeof result.ds_connection.is_credential != 'undefined') {
              newDataSourceConnectionModel.fields.is_credential = result.ds_connection.is_credential;
            }
            if (typeof result.ds_connection.is_credential_deleted != 'undefined') {
              newDataSourceConnectionModel.fields.credential_id = '';
              notificationService.warning(`Credential ${result.ds_connection.credential_id} has been deleted`);
            } else {
              if (typeof result.ds_connection.credential_id != 'undefined') {
                newDataSourceConnectionModel.fields.credential_id = result.ds_connection.credential_id;
              }
            }
            if (typeof result.ds_connection.credential_provider_err != 'undefined') {
              console.error(result.ds_connection.credential_provider_err);
              notificationService.warning(
                `Credential provider ${result.ds_connection.credential_id} error:
                  ${result.ds_connection.credential_provider_err}`,
              );
            }

            if (typeof result.ds_connection.is_certificate === 'boolean') {
              newDataSourceConnectionModel.fields.is_certificate = result.ds_connection.is_certificate;
            }
            if (result.ds_connection.certificate_id) {
              if (result.ds_connection.is_certificate_deleted) {
                newDataSourceConnectionModel.fields.certificate_id = '';
                notificationService.warning(`Certificate ${result.ds_connection.certificate_id} has been deleted`);
              } else {
                newDataSourceConnectionModel.fields.certificate_id = result.ds_connection.certificate_id;
              }
            }
            if (typeof result.ds_connection.is_ssl_self_signed === 'boolean') {
              newDataSourceConnectionModel.fields.is_ssl_self_signed = result.ds_connection.is_ssl_self_signed;
            }

            const location = newDataSourceConnectionModel.countries.find(
              ({ name }) => name === result.ds_connection.location,
            );
            if (location) {
              newDataSourceConnectionModel.country.selected = location;
            } else {
              newDataSourceConnectionModel.country.selected = {};
              newDataSourceConnectionModel.country.selected.displayName = result.ds_connection.location;
              newDataSourceConnectionModel.country.selected.name = result.ds_connection.location;
            }

            newDataSourceConnectionModel.fieldsDDL.selectedTypeOption = getSelectedObject(
              result.ds_connection.type,
              availableTypeOptions,
            );

            newDataSourceConnectionModel.fieldsDDL.selectedScan = getSelectedObject(
              result.ds_connection.security_tier,
              availableSecurityOptions,
            );
            newDataSourceConnectionModel.fieldsDDL.selectedSecurityTierOption = getSelectedObject(
              result.ds_connection.security_tier,
              availableSecurityOptions,
            );
            newDataSourceConnectionModel.fieldsDDL.selectedEnabledOption = getSelectedObject(
              result.ds_connection.enabled,
              availableEnableOptions,
            );

            newDataSourceConnectionModel.fields.differential =
              typeof result.ds_connection.differential === 'boolean'
                ? result.ds_connection.differential
                : DIFFERENTIAL_DEFAULT_VALUE;
            newDataSourceConnectionModel.fields.is_modified_in_x_days =
              typeof result.ds_connection.is_modified_in_x_days === 'boolean'
                ? result.ds_connection.is_modified_in_x_days
                : IS_MODIFIELD_X_LAST_DAYS_DEFAULT_VALUE;
            newDataSourceConnectionModel.fields.x_last_days = getXLastDaysValue(result.ds_connection.x_last_days);

            newDataSourceConnectionModel.fields.classification_is_enabled =
              result.ds_connection.classification_is_enabled;

            newDataSourceConnectionModel.fields.structured_clustering_enabled =
              result.ds_connection.structured_clustering_enabled;

            newDataSourceConnectionModel.fields.row_identifier_expression_is_disabled =
              result.ds_connection.row_identifier_expression_is_disabled;
            newDataSourceConnectionModel.realBoxServiceAccountJson = angular.copy(
              result.ds_connection.box_service_account_json,
            );

            newDataSourceConnectionModel.update(result.ds_connection.type);

            if (isIDSORsupported(result.ds_connection.type)) {
              newDataSourceConnectionModel.fields.is_idsor_supported = true;
            }

            newDataSourceConnectionModel.fields.testConnectionTimeoutInSeconds =
              result.ds_connection.testConnectionTimeoutInSeconds;

            if (isOauthConnector(result.ds_connection.type)) {
              newDataSourceConnectionModel.isOauthConfigured = result.ds_connection.isOauthConfigured;
              if (newDataSourceConnectionModel.isOauthConfigured) {
                newDataSourceConnectionModel.fields.client_id = result.ds_connection.client_id;
                newDataSourceConnectionModel.fields.redirect_uri = result.ds_connection.redirect_uri;
                newDataSourceConnectionModel.fields.instance_url = result.ds_connection.instance_url;
                newDataSourceConnectionModel.fields.client_secret = MASKED_PASSWORD_STRING;
                newDataSourceConnectionModel.fields.access_token = MASKED_PASSWORD_STRING;
                newDataSourceConnectionModel.fields.refresh_token = MASKED_PASSWORD_STRING;
                newDataSourceConnectionModel.showTestBtn = true;
              }
            }

            if (result.ds_connection.type === 'couchbase') {
              newDataSourceConnectionModel.fields.username = result.ds_connection.username;
              newDataSourceConnectionModel.fields.password = MASKED_PASSWORD_STRING;
              newDataSourceConnectionModel.fields.couchbaseServerUrl = result.ds_connection.couchbaseServerUrl;
              newDataSourceConnectionModel.fields.couchbaseServerPort = result.ds_connection.couchbaseServerPort;
              newDataSourceConnectionModel.fields.rdb_bucket = result.ds_connection.rdb_bucket;
              newDataSourceConnectionModel.fields.rdb_is_sample_data = result.ds_connection.rdb_is_sample_data;
              newDataSourceConnectionModel.fields.numberOfParsingThreads = result.ds_connection.numberOfParsingThreads;
              newDataSourceConnectionModel.fields.scanTimeoutInSeconds = result.ds_connection.scanTimeoutInSeconds;
            }

            //Hadoop
            if (result.ds_connection.type === 'hadoop') {
              newDataSourceConnectionModel.fields.yarn_uri = result.ds_connection.yarn_uri;
              newDataSourceConnectionModel.fields.hdfs_uri = result.ds_connection.hdfs_uri;
              newDataSourceConnectionModel.fields.folder_to_scan = result.ds_connection.folder_to_scan;
              newDataSourceConnectionModel.fields.auth_method = result.ds_connection.auth_method;
              newDataSourceConnectionModel.fields.keytab_path = result.ds_connection.keytab_path;
              newDataSourceConnectionModel.fields.principal = result.ds_connection.principal;

              newDataSourceConnectionModel.fields.namenode_principal = result.ds_connection.namenode_principal;
              newDataSourceConnectionModel.fields.yarn_nodemanager_principal =
                result.ds_connection.yarn_nodemanager_principal;

              newDataSourceConnectionModel.fields.yarn_principal = result.ds_connection.yarn_principal;
              newDataSourceConnectionModel.fields.username = result.ds_connection.username;
              newDataSourceConnectionModel.fields.proxy_user = result.ds_connection.proxy_user;
              newDataSourceConnectionModel.fields.yarn_hostname = result.ds_connection.yarn_hostname;
              newDataSourceConnectionModel.fields.yarn_scheduler_address = result.ds_connection.yarn_scheduler_address;
              newDataSourceConnectionModel.fields.mapreduce_framework_path =
                result.ds_connection.mapreduce_framework_path;
              newDataSourceConnectionModel.fields.yarn_classpath = result.ds_connection.yarn_classpath;
              newDataSourceConnectionModel.fields.mapreduce_classpath = result.ds_connection.mapreduce_classpath;
              newDataSourceConnectionModel.fields.mapreduce_job_queuename =
                result.ds_connection.mapreduce_job_queuename;
              newDataSourceConnectionModel.fields.Is_sample_files = result.ds_connection.Is_sample_files;
              newDataSourceConnectionModel.fields.is_redaction_enabled = result.ds_connection.is_redaction_enabled;
              newDataSourceConnectionModel.fields.hadoop_core_site_xml = result.ds_connection.hadoop_core_site_xml;
              newDataSourceConnectionModel.fields.hadoop_hdfs_site_xml = result.ds_connection.hadoop_hdfs_site_xml;
              newDataSourceConnectionModel.fields.hadoop_mapred_site_xml = result.ds_connection.hadoop_mapred_site_xml;
              newDataSourceConnectionModel.fields.hadoop_yarn_site_xml = result.ds_connection.hadoop_yarn_site_xml;
              newDataSourceConnectionModel.fields.include_folders = result.ds_connection.include_folders;
              newDataSourceConnectionModel.fields.folder_filter_regex = result.ds_connection.folder_filter_regex;
              newDataSourceConnectionModel.fields.is_folders_filter_enabled =
                result.ds_connection.is_folders_filter_enabled;
              if (
                result.ds_connection.custom_properties != 'undefined' &&
                result.ds_connection.custom_properties.length > 0
              ) {
                newDataSourceConnectionModel.hadoopCustomProperties = result.ds_connection.custom_properties;
              }
            }

            if (result.ds_connection.type === 'sparksql') {
              newDataSourceConnectionModel.fields.rdb_is_sample_data = result.ds_connection.rdb_is_sample_data;
              if (newDataSourceConnectionModel.fields.rdb_is_sample_data) {
                newDataSourceConnectionModel.fields.rdb_sample_data_max_size =
                  result.ds_connection.rdb_sample_data_max_size;
              } else {
                delete newDataSourceConnectionModel.fields.rdb_sample_data_max_size;
              }
              newDataSourceConnectionModel.fields.hdfs_uri = result.ds_connection.hdfs_uri;
              newDataSourceConnectionModel.fields.folder_to_scan = result.ds_connection.folder_to_scan;
              newDataSourceConnectionModel.fields.sparksql_master = result.ds_connection.sparksql_master;
              newDataSourceConnectionModel.fields.numberOfParsingThreads = result.ds_connection.numberOfParsingThreads;
              newDataSourceConnectionModel.fields.file_types_to_scan = result.ds_connection.file_types_to_scan;
              newDataSourceConnectionModel.fields.xml_properties = result.ds_connection.xml_properties;
            }

            //Mongo
            if (result.ds_connection.type === 'mongodb') {
              newDataSourceConnectionModel.fields.mongo_url = result.ds_connection.mongo_url;
              newDataSourceConnectionModel.fields.mongo_db_name = result.ds_connection.mongo_db_name;
              newDataSourceConnectionModel.fields.mongo_collection_name = result.ds_connection.mongo_collection_name;
              newDataSourceConnectionModel.fields.fields_to_exclude = result.ds_connection.fields_to_exclude;
              newDataSourceConnectionModel.fields.rdb_is_sample_data = result.ds_connection.rdb_is_sample_data;
              if (newDataSourceConnectionModel.fields.rdb_is_sample_data) {
                newDataSourceConnectionModel.fields.rdb_sample_data_max_size =
                  result.ds_connection.rdb_sample_data_max_size;
              } else {
                //don't show in view in case is_sample_data is off
                delete newDataSourceConnectionModel.fields.rdb_sample_data_max_size;
              }
              newDataSourceConnectionModel.fields.rdb_conn_properties = result.ds_connection.rdb_conn_properties;

              newDataSourceConnectionModel.fields.enrichment_is_enabled = result.ds_connection.enrichment_is_enabled;
              newDataSourceConnectionModel.fields.classification_is_enabled =
                result.ds_connection.classification_is_enabled;

              newDataSourceConnectionModel.fields.username = result.ds_connection.username;
              newDataSourceConnectionModel.fields.authentication_db = result.ds_connection.authentication_db;
              newDataSourceConnectionModel.fields.password = MASKED_PASSWORD_STRING;

              newDataSourceConnectionModel.fields.numberOfParsingThreads = result.ds_connection.numberOfParsingThreads;
              newDataSourceConnectionModel.fields.scanTimeoutInSeconds = result.ds_connection.scanTimeoutInSeconds;
              newDataSourceConnectionModel.showTestBtn = true;
            }

            if (result.ds_connection.type === 'cosmosdb') {
              newDataSourceConnectionModel.fields.cosmodb_service_endpoint =
                result.ds_connection.cosmodb_service_endpoint;
              newDataSourceConnectionModel.fields.client_secret = MASKED_PASSWORD_STRING;
              newDataSourceConnectionModel.fields.databases_to_scan = result.ds_connection.databases_to_scan;
              newDataSourceConnectionModel.fields.containers_to_scan = result.ds_connection.containers_to_scan;
              newDataSourceConnectionModel.fields.rdb_is_sample_data = result.ds_connection.rdb_is_sample_data;
              if (newDataSourceConnectionModel.fields.rdb_is_sample_data) {
                newDataSourceConnectionModel.fields.rdb_sample_data_max_size =
                  result.ds_connection.rdb_sample_data_max_size;
              } else {
                //don't show in view in case is_sample_data is off
                delete newDataSourceConnectionModel.fields.rdb_sample_data_max_size;
              }
            }

            if (
              result.ds_connection.type === 'rdb-mysql' ||
              result.ds_connection.type === 'rdb-postgresql' ||
              result.ds_connection.type === 'rdb-mssql' ||
              result.ds_connection.type === 'rdb-oracle' ||
              result.ds_connection.type === 'rdb-hana' ||
              result.ds_connection.type === 'rdb-sybase' ||
              result.ds_connection.type === 'rdb-db2' ||
              result.ds_connection.type === 'rdb-db2iseries' ||
              result.ds_connection.type === 'rdb-redshift' ||
              result.ds_connection.type === 'presto' ||
              result.ds_connection.type === 'rdb-teradata' ||
              result.ds_connection.type === 'rdb-activedirectory' ||
              result.ds_connection.type === 'informix' ||
              result.ds_connection.type === 'netezza' ||
              result.ds_connection.type === 'yellowbrick' ||
              result.ds_connection.type === 'hadoop-hawq'
            ) {
              newDataSourceConnectionModel.fields.domain = result.ds_connection.domain;
              newDataSourceConnectionModel.fields.rdb_url = result.ds_connection.rdb_url;
              newDataSourceConnectionModel.fields.rdb_name = result.ds_connection.rdb_name;
              newDataSourceConnectionModel.fields.schema_filter = result.ds_connection.schema_filter;
              newDataSourceConnectionModel.fields.rdb_conn_properties = result.ds_connection.rdb_conn_properties;
              newDataSourceConnectionModel.fields.rdb_comma_sep_table_list =
                result.ds_connection.rdb_comma_sep_table_list;
              newDataSourceConnectionModel.fields.rdb_is_scan_views = result.ds_connection.rdb_is_scan_views;
              newDataSourceConnectionModel.fields.is_scan_tables_without_index =
                result.ds_connection.is_scan_tables_without_index;
              newDataSourceConnectionModel.fields.rdb_is_only_user_objects =
                result.ds_connection.rdb_is_only_user_objects;
              newDataSourceConnectionModel.fields.rdb_is_sample_data = result.ds_connection.rdb_is_sample_data;
              newDataSourceConnectionModel.fields.numberOfParsingThreads = result.ds_connection.numberOfParsingThreads;
              newDataSourceConnectionModel.fields.scanTimeoutInSeconds = result.ds_connection.scanTimeoutInSeconds;
              newDataSourceConnectionModel.fields.ignore_types = result.ds_connection.ignore_types;

              if (result.ds_connection.type === 'informix') {
                newDataSourceConnectionModel.fields.informixServerName = result.ds_connection.informixServerName;
              }

              if (result.ds_connection.type === 'rdb-teradata') {
                newDataSourceConnectionModel.fields.is_fast_export = result.ds_connection.is_fast_export;
                newDataSourceConnectionModel.fields.rdb_custom_query_condition =
                  result.ds_connection.rdb_custom_query_condition;
              }

              if (result.ds_connection.type === 'rdb-oracle') {
                newDataSourceConnectionModel.fields.rdb_custom_query_condition =
                  result.ds_connection.rdb_custom_query_condition;
              }

              if (result.ds_connection.type === 'presto') {
                newDataSourceConnectionModel.fields.rdb_custom_query_condition =
                  result.ds_connection.rdb_custom_query_condition;
              }

              if (result.ds_connection.type === 'rdb-mysql') {
                newDataSourceConnectionModel.fields.rdb_custom_query_condition =
                  result.ds_connection.rdb_custom_query_condition;
              }

              if (result.ds_connection.type === 'rdb-activedirectory') {
                newDataSourceConnectionModel.fields.adDomain = result.ds_connection.adDomain;
                newDataSourceConnectionModel.fields.certificatePath = result.ds_connection.certificatePath;
              }
              if (newDataSourceConnectionModel.fields.rdb_is_sample_data) {
                newDataSourceConnectionModel.fields.rdb_sample_data_max_size =
                  result.ds_connection.rdb_sample_data_max_size;
              } else {
                //don't show in view in case rdb_is_sample_data is off
                //TODO fix the method in C:\dev_env\bigid\modules\orchestrator\routes\v1\api-router-v1.js so it will update mongo
                delete newDataSourceConnectionModel.fields.rdb_sample_data_max_size;
              }
              newDataSourceConnectionModel.fields.enrichment_is_enabled = result.ds_connection.enrichment_is_enabled;
              newDataSourceConnectionModel.fields.classification_is_enabled =
                result.ds_connection.classification_is_enabled;

              newDataSourceConnectionModel.fields.username = result.ds_connection.username;
              newDataSourceConnectionModel.fields.password = MASKED_PASSWORD_STRING;

              newDataSourceConnectionModel.fields.stop_on_failure = result.ds_connection.stop_on_failure;
              newDataSourceConnectionModel.fields.rdb_partition_condition =
                result.ds_connection.rdb_partition_condition;

              newDataSourceConnectionModel.showTestBtn = true;

              if (result.ds_connection.type === 'rdb-mssql') {
                newDataSourceConnectionModel.fields.isIntegratedAuthentication =
                  result.ds_connection.isIntegratedAuthentication;
                newDataSourceConnectionModel.fields.mssqlServicePrincipalName =
                  result.ds_connection.mssqlServicePrincipalName;
                newDataSourceConnectionModel.fields.rdb_custom_query_condition =
                  result.ds_connection.rdb_custom_query_condition;
              }
            }

            if (result.ds_connection.type === 'nfs') {
              newDataSourceConnectionModel.fields.Is_sample_folders = result.ds_connection.Is_sample_folders;
              newDataSourceConnectionModel.fields.Is_sample_files = result.ds_connection.Is_sample_files;
              newDataSourceConnectionModel.fields.nfsServer = result.ds_connection.nfsServer;
              newDataSourceConnectionModel.fields.sharedFolder = result.ds_connection.sharedFolder;
              newDataSourceConnectionModel.fields.fileTypesToExclude = result.ds_connection.fileTypesToExclude;
              newDataSourceConnectionModel.fields.dataSourceObjectNamesToExcludeRegex =
                result.ds_connection.dataSourceObjectNamesToExcludeRegex;
            }
            if (result.ds_connection.type === 'emr') {
              //exists also on hadoop
              newDataSourceConnectionModel.fields.folder_to_scan = result.ds_connection.folder_to_scan;

              newDataSourceConnectionModel.fields.emr_cluster_id = result.ds_connection.emr_cluster_id;

              newDataSourceConnectionModel.fields.isCredentialsAuth = result.ds_connection.isCredentialsAuth;
              newDataSourceConnectionModel.fields.isIamRoleAuth = result.ds_connection.isIamRoleAuth;
              newDataSourceConnectionModel.fields.isAnonymousAuth = result.ds_connection.isAnonymousAuth;

              newDataSourceConnectionModel.fields.aws_access_key = result.ds_connection.aws_access_key;
              newDataSourceConnectionModel.fields.aws_secret_key = MASKED_PASSWORD_STRING;

              if (result.ds_connection.aws_session_token) {
                newDataSourceConnectionModel.fields.aws_session_token = MASKED_PASSWORD_STRING;
              }

              newDataSourceConnectionModel.fields.aws_temp_s3 = result.ds_connection.aws_temp_s3;
              newDataSourceConnectionModel.fields.aws_region = result.ds_connection.aws_region;
              newDataSourceConnectionModel.fields.Is_sample_files = result.ds_connection.Is_sample_files;
              newDataSourceConnectionModel.fields.is_redaction_enabled = result.ds_connection.is_redaction_enabled;

              if (
                result.ds_connection.custom_properties != 'undefined' &&
                result.ds_connection.custom_properties.length > 0
              ) {
                newDataSourceConnectionModel.hadoopCustomProperties = result.ds_connection.custom_properties;
              }
            }
            if (result.ds_connection.type === 's3') {
              //exists also on hadoop
              newDataSourceConnectionModel.fields.folder_to_scan = result.ds_connection.folder_to_scan;
              newDataSourceConnectionModel.fields.Is_sample_folders = result.ds_connection.Is_sample_folders;
              newDataSourceConnectionModel.fields.Is_sample_files = result.ds_connection.Is_sample_files;
              newDataSourceConnectionModel.fields.parquetFileRegex = result.ds_connection.parquetFileRegex;

              newDataSourceConnectionModel.fields.bucket_name = result.ds_connection.bucket_name;

              newDataSourceConnectionModel.fields.isCredentialsAuth = result.ds_connection.isCredentialsAuth;
              newDataSourceConnectionModel.fields.isIamRoleAuth = result.ds_connection.isIamRoleAuth;
              newDataSourceConnectionModel.fields.isSTSAuth = result.ds_connection.isSTSAuth;
              newDataSourceConnectionModel.fields.isAnonymousAuth = result.ds_connection.isAnonymousAuth;
              newDataSourceConnectionModel.fields.isCrossAccountAuth = result.ds_connection.isCrossAccountAuth;

              newDataSourceConnectionModel.fields.aws_access_key = result.ds_connection.aws_access_key;
              newDataSourceConnectionModel.fields.aws_secret_key = MASKED_PASSWORD_STRING;
              newDataSourceConnectionModel.fields.aws_region = result.ds_connection.aws_region;
              newDataSourceConnectionModel.fields.aws_role_session_name = result.ds_connection.aws_role_session_name;
              newDataSourceConnectionModel.fields.aws_role_arn = result.ds_connection.aws_role_arn;

              if (result.ds_connection.aws_session_token) {
                newDataSourceConnectionModel.fields.aws_session_token = MASKED_PASSWORD_STRING;
              }

              newDataSourceConnectionModel.fields.fileTypesToExclude = result.ds_connection.fileTypesToExclude;
            }
            if (result.ds_connection.type === 'hive' || result.ds_connection.type === 'hive-jdbc') {
              newDataSourceConnectionModel.fields.username = result.ds_connection.username;
              newDataSourceConnectionModel.fields.password = MASKED_PASSWORD_STRING;
              newDataSourceConnectionModel.fields.rdb_comma_sep_table_list =
                result.ds_connection.rdb_comma_sep_table_list;
              newDataSourceConnectionModel.fields.rdb_is_scan_views = result.ds_connection.rdb_is_scan_views;
              newDataSourceConnectionModel.fields.stop_on_failure = result.ds_connection.stop_on_failure;
              newDataSourceConnectionModel.fields.rdb_url = result.ds_connection.rdb_url;
              newDataSourceConnectionModel.fields.rdb_conn_properties = result.ds_connection.rdb_conn_properties;
              newDataSourceConnectionModel.fields.rdb_name = result.ds_connection.rdb_name;
              newDataSourceConnectionModel.fields.schema_filter = result.ds_connection.schema_filter;
              newDataSourceConnectionModel.fields.rdb_is_sample_data = result.ds_connection.rdb_is_sample_data;
              newDataSourceConnectionModel.fields.rdb_partition_condition =
                result.ds_connection.rdb_partition_condition;
              newDataSourceConnectionModel.fields.multiple_partition_condition =
                result.ds_connection.multiple_partition_condition;
              newDataSourceConnectionModel.fields.numberOfParsingThreads = result.ds_connection.numberOfParsingThreads;
              newDataSourceConnectionModel.fields.scanTimeoutInSeconds = result.ds_connection.scanTimeoutInSeconds;
              if (newDataSourceConnectionModel.fields.rdb_is_sample_data) {
                newDataSourceConnectionModel.fields.rdb_sample_data_max_size =
                  result.ds_connection.rdb_sample_data_max_size;
              } else {
                //don't show in view in case rdb_is_sample_data is off
                //TODO fix the method in C:\dev_env\bigid\modules\orchestrator\routes\v1\api-router-v1.js so it will update mongo
                delete newDataSourceConnectionModel.fields.rdb_sample_data_max_size;
              }
              newDataSourceConnectionModel.fields.enrichment_is_enabled = result.ds_connection.enrichment_is_enabled;
              newDataSourceConnectionModel.fields.classification_is_enabled =
                result.ds_connection.classification_is_enabled;

              newDataSourceConnectionModel.fields.auth_method = result.ds_connection.auth_method;
              newDataSourceConnectionModel.fields.keytab_path = result.ds_connection.keytab_path;
              newDataSourceConnectionModel.fields.principal = result.ds_connection.principal;

              if (result.ds_connection.type === 'hive') {
                newDataSourceConnectionModel.fields.hdfs_uri = result.ds_connection.hdfs_uri;
                newDataSourceConnectionModel.fields.namenode_principal = result.ds_connection.namenode_principal;
              }
              if (result.ds_connection.type === 'hive-jdbc') {
                newDataSourceConnectionModel.fields.schema_filter = result.ds_connection.schema_filter;
                newDataSourceConnectionModel.fields.ignore_types = result.ds_connection.ignore_types;
                newDataSourceConnectionModel.fields.rdb_custom_query_condition =
                  result.ds_connection.rdb_custom_query_condition;
              }

              newDataSourceConnectionModel.showTestBtn = true;
            }
            if (result.ds_connection.type === 'hbase') {
              newDataSourceConnectionModel.fields.username = result.ds_connection.username;
              newDataSourceConnectionModel.fields.rdb_comma_sep_table_list =
                result.ds_connection.rdb_comma_sep_table_list;
              newDataSourceConnectionModel.fields.rdb_url = result.ds_connection.rdb_url;
              newDataSourceConnectionModel.fields.stop_on_failure = result.ds_connection.stop_on_failure;
              newDataSourceConnectionModel.fields.rdb_is_sample_data = result.ds_connection.rdb_is_sample_data;
              if (newDataSourceConnectionModel.fields.rdb_is_sample_data) {
                newDataSourceConnectionModel.fields.rdb_sample_data_max_size =
                  result.ds_connection.rdb_sample_data_max_size;
              } else {
                //don't show in view in case rdb_is_sample_data is off
                //TODO fix the method in C:\dev_env\bigid\modules\orchestrator\routes\v1\api-router-v1.js so it will update mongo
                delete newDataSourceConnectionModel.fields.rdb_sample_data_max_size;
              }

              if (
                result.ds_connection.custom_properties != 'undefined' &&
                result.ds_connection.custom_properties.length > 0
              ) {
                newDataSourceConnectionModel.hadoopCustomProperties = result.ds_connection.custom_properties;
              }
              newDataSourceConnectionModel.fields.auth_method = result.ds_connection.auth_method;
              newDataSourceConnectionModel.fields.keytab_path = result.ds_connection.keytab_path;
              newDataSourceConnectionModel.fields.principal = result.ds_connection.principal;
              newDataSourceConnectionModel.fields.master_principal = result.ds_connection.master_principal;
              newDataSourceConnectionModel.fields.region_server_principal =
                result.ds_connection.region_server_principal;

              newDataSourceConnectionModel.fields.numberOfParsingThreads = result.ds_connection.numberOfParsingThreads;
              newDataSourceConnectionModel.fields.scanTimeoutInSeconds = result.ds_connection.scanTimeoutInSeconds;
              newDataSourceConnectionModel.showTestBtn = true;
            }
            if (
              result.ds_connection.type === 'kafka' ||
              result.ds_connection.type === 'kafkaconnect' ||
              result.ds_connection.type === 'scan-api'
            ) {
              if (result.ds_connection.type === 'kafkaconnect') {
                newDataSourceConnectionModel.fields.kafkaConnectPluginParameters =
                  result.ds_connection.kafkaConnectPluginParameters;

                newDataSourceConnectionModel.kafkaConnectPlugins = {
                  availableOptions: result.allKafkaConnectPlugins,
                };

                newDataSourceConnectionModel.fieldsDDL.selectedKafkaConnectPlugin = getSelectedObject(
                  result.ds_connection.kafkaConnectPlugin,
                  result.allKafkaConnectPlugins,
                );
                newDataSourceConnectionModel.updateSelectedKafkaConnectPlugin(false);
              }
              newDataSourceConnectionModel.fields.ner_classification_is_enabled =
                result.ds_connection.ner_classification_is_enabled;
              newDataSourceConnectionModel.fields.kafkaBootstrapServersCommaSeparatedList =
                result.ds_connection.kafkaBootstrapServersCommaSeparatedList;
              newDataSourceConnectionModel.fields.kafkaTopicsCommaSeperatedList =
                result.ds_connection.kafkaTopicsCommaSeperatedList;
              newDataSourceConnectionModel.fields.schemaRegistryUrl = result.ds_connection.schemaRegistryUrl;
              newDataSourceConnectionModel.fields.pollTimeoutInMilliseconds =
                result.ds_connection.pollTimeoutInMilliseconds;
              newDataSourceConnectionModel.fields.numOfMessagesToPullFromQueueInPoll =
                result.ds_connection.numOfMessagesToPullFromQueueInPoll;
              newDataSourceConnectionModel.fields.numOfMessagesToProcessInPoll =
                result.ds_connection.numOfMessagesToProcessInPoll;
              newDataSourceConnectionModel.fields.dimSampleEnabled = Boolean(result.ds_connection.dimSampleEnabled);
              newDataSourceConnectionModel.showTestBtn = true;
              newDataSourceConnectionModel.fieldsDDL.keyDeserializer = getSelectedObject(
                result.ds_connection.keyDeserializer,
                availableSerializerOptions,
              );
              newDataSourceConnectionModel.fieldsDDL.valueDeserializer = getSelectedObject(
                result.ds_connection.valueDeserializer,
                availableSerializerOptions,
              );
            }
            if (result.ds_connection.type === 'kinesis') {
              // scanner related fields
              newDataSourceConnectionModel.fields.isIamRoleAuth = result.ds_connection.isIamRoleAuth;
              newDataSourceConnectionModel.fields.ner_classification_is_enabled =
                result.ds_connection.ner_classification_is_enabled;
              newDataSourceConnectionModel.fields.stop_on_failure = result.ds_connection.stop_on_failure;
              newDataSourceConnectionModel.fields.rdb_is_sample_data = result.ds_connection.rdb_is_sample_data;
              newDataSourceConnectionModel.fields.rdb_sample_data_max_size =
                result.ds_connection.rdb_sample_data_max_size;
              newDataSourceConnectionModel.fields.numberOfParsingThreads = result.ds_connection.numberOfParsingThreads;
              newDataSourceConnectionModel.fields.scanTimeoutInSeconds = result.ds_connection.scanTimeoutInSeconds;
              newDataSourceConnectionModel.fields.numOfMessagesToProcessInPoll =
                result.ds_connection.numOfMessagesToProcessInPoll;
              newDataSourceConnectionModel.fields.dimSampleEnabled = Boolean(result.ds_connection.dimSampleEnabled);

              // connector related fields
              newDataSourceConnectionModel.fields.stream_name = result.ds_connection.stream_name;
              lodashSet(
                newDataSourceConnectionModel.fields,
                'aws_key_id',
                lodashGet(result.ds_connection, 'aws_key_id'),
              );
              lodashSet(newDataSourceConnectionModel.fields, 'aws_key_secret', MASKED_PASSWORD_STRING);

              // aws connection related fields (if server_type is aws)
              newDataSourceConnectionModel.fields.region = result.ds_connection.region;
            }
            if (result.ds_connection.type === 'cassandra') {
              newDataSourceConnectionModel.fields.username = result.ds_connection.username;
              newDataSourceConnectionModel.fields.password = MASKED_PASSWORD_STRING;
              newDataSourceConnectionModel.fields.rdb_comma_sep_table_list =
                result.ds_connection.rdb_comma_sep_table_list;
              newDataSourceConnectionModel.fields.rdb_url = result.ds_connection.rdb_url;
              newDataSourceConnectionModel.fields.rdb_name = result.ds_connection.rdb_name;
              newDataSourceConnectionModel.fields.schema_filter = result.ds_connection.schema_filter;
              newDataSourceConnectionModel.fields.rdb_is_sample_data = result.ds_connection.rdb_is_sample_data;
              newDataSourceConnectionModel.fields.numberOfParsingThreads = result.ds_connection.numberOfParsingThreads;
              newDataSourceConnectionModel.fields.scanTimeoutInSeconds = result.ds_connection.scanTimeoutInSeconds;
              if (newDataSourceConnectionModel.fields.rdb_is_sample_data) {
                newDataSourceConnectionModel.fields.rdb_sample_data_max_size =
                  result.ds_connection.rdb_sample_data_max_size;
              } else {
                //don't show in view in case rdb_is_sample_data is off
                //TODO fix the method in C:\dev_env\bigid\modules\orchestrator\routes\v1\api-router-v1.js so it will update mongo
                delete newDataSourceConnectionModel.fields.rdb_sample_data_max_size;
              }
              newDataSourceConnectionModel.fields.use_ssl = result.ds_connection.use_ssl;
              newDataSourceConnectionModel.fields.enable_compression = result.ds_connection.enable_compression;
              newDataSourceConnectionModel.fields.stop_on_failure = result.ds_connection.stop_on_failure;
              newDataSourceConnectionModel.fields.dsar_efficient_search_only =
                result.ds_connection.dsar_efficient_search_only === true;
              newDataSourceConnectionModel.fields.has_partition_conditions =
                result.ds_connection.has_partition_conditions;
              if (newDataSourceConnectionModel.fields.has_partition_conditions) {
                newDataSourceConnectionModel.fields.partition_conditions = result.ds_connection.partition_conditions;
                newDataSourceConnectionModel.fields.clustering_conditions = result.ds_connection.clustering_conditions;
                newDataSourceConnectionModel.fields.skip_table_that_doesnt_match_partition_condition =
                  result.ds_connection.skip_table_that_doesnt_match_partition_condition;
              } else {
                delete newDataSourceConnectionModel.fields.partition_conditions;
                delete newDataSourceConnectionModel.fields.clustering_conditions;
                delete newDataSourceConnectionModel.fields.skip_table_that_doesnt_match_partition_condition;
              }
              newDataSourceConnectionModel.showTestBtn = true;
            }

            if (result.ds_connection.type === 'smb') {
              newDataSourceConnectionModel.fields.Is_sample_folders = result.ds_connection.Is_sample_folders;
              newDataSourceConnectionModel.fields.Is_sample_files = result.ds_connection.Is_sample_files;

              newDataSourceConnectionModel.fields.smbServer = result.ds_connection.smbServer;
              newDataSourceConnectionModel.fields.multiple_shares_enabled =
                result.ds_connection.multiple_shares_enabled;
              newDataSourceConnectionModel.fields.is_system_shares = result.ds_connection.is_system_shares;
              newDataSourceConnectionModel.fields.domain = result.ds_connection.domain;
              newDataSourceConnectionModel.fields.username = result.ds_connection.username;
              newDataSourceConnectionModel.fields.password = MASKED_PASSWORD_STRING;
              newDataSourceConnectionModel.fields.sharedResource = result.ds_connection.sharedResource;
              newDataSourceConnectionModel.fields.fileTypesToExclude = result.ds_connection.fileTypesToExclude;
              newDataSourceConnectionModel.fields.dataSourceObjectNamesToExcludeRegex =
                result.ds_connection.dataSourceObjectNamesToExcludeRegex;
            }
            if (result.ds_connection.type === 'azure-blob') {
              newDataSourceConnectionModel.fields.accountName = result.ds_connection.accountName;
              newDataSourceConnectionModel.fields.accountKey = MASKED_PASSWORD_STRING;
              newDataSourceConnectionModel.fields.shareName = result.ds_connection.shareName;
              newDataSourceConnectionModel.fields.azure_protocol = result.ds_connection.azure_protocol
                ? result.ds_connection.azure_protocol
                : AZURE_DEFAULT_PROTOCOL;

              newDataSourceConnectionModel.fields.folder_to_scan = result.ds_connection.folder_to_scan;
              newDataSourceConnectionModel.fields.Is_sample_folders = result.ds_connection.Is_sample_folders;
              newDataSourceConnectionModel.fields.Is_sample_files = result.ds_connection.Is_sample_files;
              newDataSourceConnectionModel.fields.fileTypesToExclude = result.ds_connection.fileTypesToExclude;
            }
            if (result.ds_connection.type === 'azure-file') {
              newDataSourceConnectionModel.fields.accountName = result.ds_connection.accountName;
              newDataSourceConnectionModel.fields.accountKey = MASKED_PASSWORD_STRING;
              newDataSourceConnectionModel.fields.shareName = result.ds_connection.shareName;
              newDataSourceConnectionModel.fields.azure_protocol = result.ds_connection.azure_protocol
                ? result.ds_connection.azure_protocol
                : AZURE_DEFAULT_PROTOCOL;

              newDataSourceConnectionModel.fields.folder_to_scan = result.ds_connection.folder_to_scan;
              newDataSourceConnectionModel.fields.Is_sample_folders = result.ds_connection.Is_sample_folders;
              newDataSourceConnectionModel.fields.Is_sample_files = result.ds_connection.Is_sample_files;
              newDataSourceConnectionModel.fields.fileTypesToExclude = result.ds_connection.fileTypesToExclude;
            }

            if (result.ds_connection.type === 'googledrive') {
              newDataSourceConnectionModel.fields.scan_scope = result.ds_connection.scan_scope
                ? result.ds_connection.scan_scope
                : 'myDrive';
              if (newDataSourceConnectionModel.fields.scan_scope === 'sharedDrive') {
                newDataSourceConnectionModel.fields.is_exclusion_drives_enabled = result.ds_connection
                  .is_exclusion_drives_enabled
                  ? result.ds_connection.is_exclusion_drives_enabled
                  : false;
                newDataSourceConnectionModel.fields.drives_to_scan = result.ds_connection.drives_to_scan
                  ? result.ds_connection.drives_to_scan
                  : '';
              }
            }

            if (result.ds_connection.type === 'slack') {
              newDataSourceConnectionModel.fields.slack_access_token = result.ds_connection.slack_access_token;
              newDataSourceConnectionModel.fields.numberOfParsingThreads = result.numberOfParsingThreads;
            }

            if (result.ds_connection.type === 'csv') {
              newDataSourceConnectionModel.fields.domain = result.ds_connection.domain;
              newDataSourceConnectionModel.fields.username = result.ds_connection.username;
              newDataSourceConnectionModel.fields.password = MASKED_PASSWORD_STRING;
              newDataSourceConnectionModel.fields.smbServer = result.ds_connection.smbServer;
              newDataSourceConnectionModel.fields.filePath = result.ds_connection.filePath;
              newDataSourceConnectionModel.fields.sharedResource = result.ds_connection.sharedResource;
            }

            if (result.ds_connection.type === 'salesforce') {
              newDataSourceConnectionModel.fields.rdb_is_sample_data = result.ds_connection.rdb_is_sample_data;
              if (newDataSourceConnectionModel.fields.rdb_is_sample_data) {
                newDataSourceConnectionModel.fields.rdb_sample_data_max_size =
                  result.ds_connection.rdb_sample_data_max_size;
              } else {
                delete newDataSourceConnectionModel.fields.rdb_sample_data_max_size;
              }
              newDataSourceConnectionModel.fields.include_attachments_content =
                result.ds_connection.include_attachments_content;
              newDataSourceConnectionModel.fields.is_salesforce_custom_url =
                result.ds_connection.is_salesforce_custom_url;
              newDataSourceConnectionModel.fields.salesforce_custom_url = result.ds_connection.salesforce_custom_url
                ? result.ds_connection.salesforce_custom_url
                : publicUrls.SALESFORCE_TEST_URL;
              newDataSourceConnectionModel.fields.rdb_comma_sep_table_list =
                result.ds_connection.rdb_comma_sep_table_list;
              newDataSourceConnectionModel.fields.numberOfParsingThreads = result.ds_connection.numberOfParsingThreads;
              newDataSourceConnectionModel.fields.scanTimeoutInSeconds = result.ds_connection.scanTimeoutInSeconds;
              newDataSourceConnectionModel.fields.http_read_timeout_millis =
                result.ds_connection.http_read_timeout_millis;
              newDataSourceConnectionModel.fields.http_max_number_of_retries =
                result.ds_connection.http_max_number_of_retries;
              newDataSourceConnectionModel.fields.max_number_of_fields_per_request =
                result.ds_connection.max_number_of_fields_per_request;
              newDataSourceConnectionModel.fields.dataSourceObjectNamesToExcludeRegex =
                result.ds_connection.dataSourceObjectNamesToExcludeRegex;
              if (result.ds_connection.is_certificate) {
                newDataSourceConnectionModel.showTestBtn = true;
              }
              if (newDataSourceConnectionModel.fields.is_certificate) {
                newDataSourceConnectionModel.showTestBtn = true;
                newDataSourceConnectionModel.fields.aliasName = result.ds_connection.aliasName;
                newDataSourceConnectionModel.fields.aliasPassword = result.ds_connection.aliasPassword;
                newDataSourceConnectionModel.fields.subjectUser = result.ds_connection.subjectUser;
                newDataSourceConnectionModel.fields.client_id = result.ds_connection.client_id;
              } else {
                delete newDataSourceConnectionModel.fields.aliasName;
                delete newDataSourceConnectionModel.fields.aliasPassword;
                delete newDataSourceConnectionModel.fields.subjectUser;
                delete newDataSourceConnectionModel.fields.client_id;
              }
            }

            if (result.ds_connection.type === 'salesforce-marketing-cloud') {
              newDataSourceConnectionModel.fields.authorization = result.ds_connection.authorization;
              newDataSourceConnectionModel.fields.restconnection = result.ds_connection.restconnection;
              newDataSourceConnectionModel.fields.client_id = result.ds_connection.client_id;
              newDataSourceConnectionModel.fields.client_secret = MASKED_PASSWORD_STRING;
              newDataSourceConnectionModel.fields.endpoint = result.ds_connection.endpoint;
              newDataSourceConnectionModel.fields.host = result.ds_connection.host;
            }

            if (result.ds_connection.type === 'servicenow' || result.ds_connection.type === 'servicenow-structured') {
              newDataSourceConnectionModel.fields.rdb_is_sample_data = result.ds_connection.rdb_is_sample_data;
              if (newDataSourceConnectionModel.fields.rdb_is_sample_data) {
                newDataSourceConnectionModel.fields.rdb_sample_data_max_size =
                  result.ds_connection.rdb_sample_data_max_size;
              } else {
                delete newDataSourceConnectionModel.fields.rdb_sample_data_max_size;
              }
              newDataSourceConnectionModel.fields.oauth_custom_url = result.ds_connection.oauth_custom_url
                ? result.ds_connection.oauth_custom_url
                : publicUrls.SERVICENOW_TEST_URL;
              newDataSourceConnectionModel.fields.client_id = result.ds_connection.client_id;
              newDataSourceConnectionModel.fields.client_secret = MASKED_PASSWORD_STRING;
              newDataSourceConnectionModel.fields.rdb_comma_sep_table_list =
                result.ds_connection.rdb_comma_sep_table_list;
              newDataSourceConnectionModel.fields.scan_page_size = result.ds_connection.scan_page_size;
              newDataSourceConnectionModel.fields.numberOfParsingThreads = result.ds_connection.numberOfParsingThreads;
              newDataSourceConnectionModel.fields.scanTimeoutInSeconds = result.ds_connection.scanTimeoutInSeconds;
            }

            if (result.ds_connection.type === 'onedrive-personal' || result.ds_connection.type === 'googledrive') {
              newDataSourceConnectionModel.fields.folder_to_scan = result.ds_connection.folder_to_scan;
              newDataSourceConnectionModel.fields.Is_sample_folders = result.ds_connection.Is_sample_folders;
              newDataSourceConnectionModel.fields.Is_sample_files = result.ds_connection.Is_sample_files;
              newDataSourceConnectionModel.fields.fileTypesToExclude = result.ds_connection.fileTypesToExclude;
            }

            if (result.ds_connection.type === 'google-cloud-storage') {
              newDataSourceConnectionModel.fields.folder_to_scan = result.ds_connection.folder_to_scan;
              newDataSourceConnectionModel.fields.Is_sample_folders = result.ds_connection.Is_sample_folders;
              newDataSourceConnectionModel.fields.fileTypesToExclude = result.ds_connection.fileTypesToExclude;
            }

            if (result.ds_connection.type === 'googledrive' || result.ds_connection.type === 'box') {
              newDataSourceConnectionModel.fields.usersFilterRegex = result.ds_connection.usersFilterRegex;
            }

            if (
              result.ds_connection.type === 'googledrive' ||
              result.ds_connection.type === 'box' ||
              result.ds_connection.type === 'google-cloud-storage'
            ) {
              newDataSourceConnectionModel.fields.include_file_types = result.ds_connection.include_file_types;
            }

            if (result.ds_connection.type === 'box') {
              newDataSourceConnectionModel.fields.is_user_accounts_enabled =
                result.ds_connection.is_user_accounts_enabled;
              newDataSourceConnectionModel.fields.exclude_user_regex = result.ds_connection.exclude_user_regex;
            }

            if (
              result.ds_connection.type === 'googledrive' ||
              result.ds_connection.type === 'gmail' ||
              result.ds_connection.type === 'gcp-big-query' ||
              result.ds_connection.type === 'google-cloud-storage'
            ) {
              const isGoogleServiceAccount = result.ds_connection.isGoogleServiceAccount;
              newDataSourceConnectionModel.fields.isGoogleServiceAccount = isGoogleServiceAccount;
              newDataSourceConnectionModel.fields.google_service_account_json = MASKED_PASSWORD_STRING;
              newDataSourceConnectionModel.fields.google_service_account_user =
                result.ds_connection.google_service_account_user;

              if (isGoogleServiceAccount) {
                newDataSourceConnectionModel.fields.account_to_scan = result.ds_connection.account_to_scan;
                newDataSourceConnectionModel.showTestBtn = true;
              }
            }

            if (result.ds_connection.type === 'gmail') {
              newDataSourceConnectionModel.fields.scan_draft_folder = result.ds_connection.scan_draft_folder;
              newDataSourceConnectionModel.fields.scan_sent_folder = result.ds_connection.scan_sent_folder;
              newDataSourceConnectionModel.fields.scan_from_date = result.ds_connection.scan_from_date;
              newDataSourceConnectionModel.fields.Is_sample_folders = result.ds_connection.Is_sample_folders;
              newDataSourceConnectionModel.fields.Is_sample_files = result.ds_connection.Is_sample_files;
              newDataSourceConnectionModel.fields.test_scan_mail_id = result.ds_connection.test_scan_mail_id;
              newDataSourceConnectionModel.fields.fileTypesToExclude = result.ds_connection.fileTypesToExclude;
            }
            if (result.ds_connection.type === 'box') {
              newDataSourceConnectionModel.fields.box_service_account_json = MASKED_PASSWORD_STRING;
              newDataSourceConnectionModel.fields.box_service_account_user =
                result.ds_connection.box_service_account_user;
              newDataSourceConnectionModel.fields.folder_to_scan = result.ds_connection.folder_to_scan;
              newDataSourceConnectionModel.fields.Is_sample_folders = result.ds_connection.Is_sample_folders;
              newDataSourceConnectionModel.fields.Is_sample_files = result.ds_connection.Is_sample_files;
              newDataSourceConnectionModel.fields.isSuppressNotification = result.ds_connection.isSuppressNotification;
              newDataSourceConnectionModel.fields.adminUser = result.ds_connection.adminUser;
              newDataSourceConnectionModel.fields.fileTypesToExclude = result.ds_connection.fileTypesToExclude;
              newDataSourceConnectionModel.fields.scan_search_query = result.ds_connection.scan_search_query;
              newDataSourceConnectionModel.fieldsDDL.selectedScannerStrategyOption = getSelectedObject(
                result.ds_connection.scanner_strategy,
                availableScannerStrategyOptions,
              );
            }

            if (result.ds_connection.type === 'databricks') {
              newDataSourceConnectionModel.fields.username = result.ds_connection.username;
              newDataSourceConnectionModel.fields.password = MASKED_PASSWORD_STRING;
              newDataSourceConnectionModel.fields.rdb_url = result.ds_connection.rdb_url;
              newDataSourceConnectionModel.fields.rdb_name = result.ds_connection.rdb_name;
              newDataSourceConnectionModel.fields.rdb_comma_sep_table_list =
                result.ds_connection.rdb_comma_sep_table_list;
              newDataSourceConnectionModel.fields.rdb_is_sample_data = result.ds_connection.rdb_is_sample_data;
              newDataSourceConnectionModel.fields.rdb_conn_properties = result.ds_connection.rdb_conn_properties;
              newDataSourceConnectionModel.fields.rdb_is_scan_views = result.ds_connection.rdb_is_scan_views;
              newDataSourceConnectionModel.fields.providers_to_scan = result.ds_connection.providers_to_scan;
              newDataSourceConnectionModel.fields.numberOfParsingThreads = result.ds_connection.numberOfParsingThreads;
              if (newDataSourceConnectionModel.fields.rdb_is_sample_data) {
                newDataSourceConnectionModel.fields.rdb_sample_data_max_size =
                  result.ds_connection.rdb_sample_data_max_size;
              } else {
                delete newDataSourceConnectionModel.fields.rdb_sample_data_max_size;
              }
            }

            if (result.ds_connection.type === 'google-cloud-storage') {
              newDataSourceConnectionModel.fields.project_id = result.ds_connection.project_id;
              newDataSourceConnectionModel.fields.bucket_name = result.ds_connection.bucket_name;
            }

            if (result.ds_connection.type === 'datastore') {
              newDataSourceConnectionModel.fields.rdb_is_sample_data = result.ds_connection.rdb_is_sample_data;
              if (newDataSourceConnectionModel.fields.rdb_is_sample_data) {
                newDataSourceConnectionModel.fields.rdb_sample_data_max_size =
                  result.ds_connection.rdb_sample_data_max_size;
              } else {
                delete newDataSourceConnectionModel.fields.rdb_sample_data_max_size;
              }
            }

            if (result.ds_connection.type === 'spanner') {
              newDataSourceConnectionModel.fields.rdb_is_sample_data = result.ds_connection.rdb_is_sample_data;
              if (newDataSourceConnectionModel.fields.rdb_is_sample_data) {
                newDataSourceConnectionModel.fields.rdb_sample_data_max_size =
                  result.ds_connection.rdb_sample_data_max_size;
              } else {
                delete newDataSourceConnectionModel.fields.rdb_sample_data_max_size;
              }
            }

            if (result.ds_connection.type === 'google-bigtable') {
              newDataSourceConnectionModel.fields.project_id = result.ds_connection.project_id;
              newDataSourceConnectionModel.fields.instance_id = result.ds_connection.instance_id;
              newDataSourceConnectionModel.fields.rdb_comma_sep_table_list =
                result.ds_connection.rdb_comma_sep_table_list;
              newDataSourceConnectionModel.fields.google_service_account_json = MASKED_PASSWORD_STRING;
              newDataSourceConnectionModel.fields.rdb_is_sample_data = result.ds_connection.rdb_is_sample_data;
              if (newDataSourceConnectionModel.fields.rdb_is_sample_data) {
                newDataSourceConnectionModel.fields.rdb_sample_data_max_size =
                  result.ds_connection.rdb_sample_data_max_size;
              } else {
                delete newDataSourceConnectionModel.fields.rdb_sample_data_max_size;
              }
            }

            if (result.ds_connection.type === 'jira') {
              newDataSourceConnectionModel.fields.username = result.ds_connection.username;
              newDataSourceConnectionModel.fields.jiraServiceUri = result.ds_connection.jiraServiceUri;
              newDataSourceConnectionModel.fields.apiToken = MASKED_PASSWORD_STRING;
              newDataSourceConnectionModel.fields.rdb_is_sample_data = result.ds_connection.rdb_is_sample_data;
              newDataSourceConnectionModel.fields.jql = result.ds_connection.jql;
              newDataSourceConnectionModel.fields.projectKey = result.ds_connection.projectKey;
              if (newDataSourceConnectionModel.fields.rdb_is_sample_data) {
                newDataSourceConnectionModel.fields.rdb_sample_data_max_size =
                  result.ds_connection.rdb_sample_data_max_size;
              } else {
                delete newDataSourceConnectionModel.fields.rdb_sample_data_max_size;
              }
            }

            if (result.ds_connection.type === 'microsoft-teams') {
              newDataSourceConnectionModel.fields.client_id = result.ds_connection.client_id;
              newDataSourceConnectionModel.fields.client_secret = MASKED_PASSWORD_STRING;
              newDataSourceConnectionModel.fields.tenant_id = result.ds_connection.tenant_id;
              newDataSourceConnectionModel.fields.teamDisplayName = result.ds_connection.teamDisplayName;
              newDataSourceConnectionModel.fields.rdb_is_sample_data = result.ds_connection.rdb_is_sample_data;
              if (newDataSourceConnectionModel.fields.rdb_is_sample_data) {
                newDataSourceConnectionModel.fields.rdb_sample_data_max_size =
                  result.ds_connection.rdb_sample_data_max_size;
              } else {
                delete newDataSourceConnectionModel.fields.rdb_sample_data_max_size;
              }
            }

            if (result.ds_connection.type === 'vertica') {
              newDataSourceConnectionModel.fields.rdb_is_sample_data = result.ds_connection.rdb_is_sample_data;
              newDataSourceConnectionModel.fields.rdb_sample_data_max_size =
                result.ds_connection.rdb_sample_data_max_size;
              newDataSourceConnectionModel.fields.username = result.ds_connection.username;
              newDataSourceConnectionModel.fields.password = MASKED_PASSWORD_STRING;
              newDataSourceConnectionModel.fields.rdb_url = result.ds_connection.rdb_url;
              newDataSourceConnectionModel.fields.rdb_name = result.ds_connection.rdb_name;
              newDataSourceConnectionModel.fields.rdb_comma_sep_table_list =
                result.ds_connection.rdb_comma_sep_table_list;
            }

            if (result.ds_connection.type === 'microstrategy') {
              newDataSourceConnectionModel.fields.username = result.ds_connection.username;
              newDataSourceConnectionModel.fields.password = MASKED_PASSWORD_STRING;
              newDataSourceConnectionModel.fields.microstrategyBaseUrl = result.ds_connection.microstrategyBaseUrl;
              newDataSourceConnectionModel.fields.projectName = result.ds_connection.projectName;
              newDataSourceConnectionModel.fields.folderName = result.ds_connection.folderName;
              newDataSourceConnectionModel.fields.documentOrDossierName = result.ds_connection.documentOrDossierName;
              newDataSourceConnectionModel.fields.reportName = result.ds_connection.reportName;
              newDataSourceConnectionModel.fields.attributeName = result.ds_connection.attributeName;
              newDataSourceConnectionModel.fields.promptName = result.ds_connection.promptName;
              newDataSourceConnectionModel.fields.metricName = result.ds_connection.metricName;
              newDataSourceConnectionModel.fields.styleName = result.ds_connection.styleName;
              newDataSourceConnectionModel.fields.consolidationName = result.ds_connection.consolidationName;
              newDataSourceConnectionModel.fields.numberOfParsingThreads = result.ds_connection.numberOfParsingThreads;
              const apiFamilyProperties = newDataSourceConnectionModel.apiFamily
                .filter(({ apiFamily }) => result.ds_connection[apiFamily] !== null)
                .map(({ apiFamily }) => {
                  return { scan: apiFamily, filter: result.ds_connection[apiFamily] };
                });
              if (apiFamilyProperties.length > 0) {
                newDataSourceConnectionModel.apiFamilyProperties = apiFamilyProperties;
              }
            }

            if (result.ds_connection.type === 'gcp-big-query') {
              if (
                result.ds_connection.custom_partition_conditions !== 'undefined' &&
                result.ds_connection.custom_partition_conditions.length > 0
              ) {
                newDataSourceConnectionModel.customPartitionConditions =
                  result.ds_connection.custom_partition_conditions;
              }
              newDataSourceConnectionModel.fields.project_id = result.ds_connection.project_id;
              newDataSourceConnectionModel.fields.rdb_is_sample_data = result.ds_connection.rdb_is_sample_data;
              newDataSourceConnectionModel.fields.rdb_sample_data_max_size =
                result.ds_connection.rdb_sample_data_max_size;
              newDataSourceConnectionModel.fields.is_data_filters_enabled =
                result.ds_connection.is_data_filters_enabled;
              newDataSourceConnectionModel.fields.include_schemas = result.ds_connection.include_schemas;
              newDataSourceConnectionModel.fields.schemasToExclude = result.ds_connection.schemasToExclude;
              newDataSourceConnectionModel.fields.include_tables = result.ds_connection.include_tables;
              newDataSourceConnectionModel.fields.tablesToExclude = result.ds_connection.tablesToExclude;
              newDataSourceConnectionModel.fields.project_id_for_job = result.ds_connection.project_id_for_job;

              newDataSourceConnectionModel.fields.rdb_custom_query_condition =
                result.ds_connection.rdb_custom_query_condition;
            }

            if (
              result.ds_connection.type === 'rdb-teradata' ||
              result.ds_connection.type === 'rdb-mssql' ||
              result.ds_connection.type === 'hive-jdbc' ||
              result.ds_connection.type === 'rdb-oracle'
            ) {
              newDataSourceConnectionModel.fields.is_include_schemas = result.ds_connection.is_include_schemas;
              newDataSourceConnectionModel.fields.is_include_tables = result.ds_connection.is_include_tables;
              newDataSourceConnectionModel.fields.table_filter = result.ds_connection.table_filter;
            }

            if (result.ds_connection.type === 'dynamodb') {
              newDataSourceConnectionModel.fields.aws_region = result.ds_connection.aws_region;
              newDataSourceConnectionModel.fields.dynamodbTableNames = result.ds_connection.dynamodbTableNames;

              newDataSourceConnectionModel.fields.isCredentialsAuth = result.ds_connection.isCredentialsAuth;
              newDataSourceConnectionModel.fields.isIamRoleAuth = result.ds_connection.isIamRoleAuth;
              newDataSourceConnectionModel.fields.isAnonymousAuth = result.ds_connection.isAnonymousAuth;
              newDataSourceConnectionModel.fields.isSTSAuth = result.ds_connection.isSTSAuth;
              newDataSourceConnectionModel.fields.isCrossAccountAuth = result.ds_connection.isCrossAccountAuth;

              newDataSourceConnectionModel.fields.aws_access_key = result.ds_connection.aws_access_key;

              newDataSourceConnectionModel.fields.aws_region = result.ds_connection.aws_region;
              newDataSourceConnectionModel.fields.aws_role_session_name = result.ds_connection.aws_role_session_name;
              newDataSourceConnectionModel.fields.aws_role_arn = result.ds_connection.aws_role_arn;

              if (result.ds_connection.aws_secret_key) {
                newDataSourceConnectionModel.fields.aws_secret_key = MASKED_PASSWORD_STRING;
              }

              if (result.ds_connection.aws_session_token) {
                newDataSourceConnectionModel.fields.aws_session_token = MASKED_PASSWORD_STRING;
              }

              newDataSourceConnectionModel.fields.rdb_is_sample_data = result.ds_connection.rdb_is_sample_data;
              if (newDataSourceConnectionModel.fields.rdb_is_sample_data) {
                newDataSourceConnectionModel.fields.rdb_sample_data_max_size =
                  result.ds_connection.rdb_sample_data_max_size;
              } else {
                //don't show in view in case is_sample_data is off
                //TODO fix the method in C:\dev_env\bigid\modules\orchestrator\routes\v1\api-router-v1.js so it will update mongo
                delete newDataSourceConnectionModel.fields.rdb_sample_data_max_size;
              }
              newDataSourceConnectionModel.fields.numberOfParsingThreads = result.ds_connection.numberOfParsingThreads;
              newDataSourceConnectionModel.fields.scanTimeoutInSeconds = result.ds_connection.scanTimeoutInSeconds;
            }

            if (result.ds_connection.type === 'sap') {
              newDataSourceConnectionModel.fields.jcoConnectionType = result.ds_connection.jcoConnectionType;
              newDataSourceConnectionModel.fields.erpApplicationServerHost =
                result.ds_connection.erpApplicationServerHost;
              newDataSourceConnectionModel.fields.jcoAsSysnr = result.ds_connection.jcoAsSysnr;

              if (
                result.ds_connection.jcoConnectionType === 'ashost' ||
                result.ds_connection.jcoConnectionType === 'saprouter'
              ) {
                delete newDataSourceConnectionModel.fields.jcoMSHost;
                delete newDataSourceConnectionModel.fields.jcoMSPort;
                delete newDataSourceConnectionModel.fields.jcoMSR3Name;
                delete newDataSourceConnectionModel.fields.jcoMSGroup;
              }
              if (result.ds_connection.jcoConnectionType === 'ashost') {
                delete newDataSourceConnectionModel.fields.jcoSapRouter;
              }
              if (result.ds_connection.jcoConnectionType === 'saprouter') {
                newDataSourceConnectionModel.fields.jcoSapRouter = result.ds_connection.jcoSapRouter;
              }
              if (result.ds_connection.jcoConnectionType === 'mshost') {
                newDataSourceConnectionModel.fields.jcoMSHost = result.ds_connection.jcoMSHost;
                newDataSourceConnectionModel.fields.jcoMSPort = result.ds_connection.jcoMSPort;
                newDataSourceConnectionModel.fields.jcoMSR3Name = result.ds_connection.jcoMSR3Name;
                newDataSourceConnectionModel.fields.jcoMSGroup = result.ds_connection.jcoMSGroup;
                delete newDataSourceConnectionModel.fields.jcoSapRouter;
                delete newDataSourceConnectionModel.fields.erpApplicationServerHost;
                delete newDataSourceConnectionModel.fields.jcoAsSysnr;
              }

              newDataSourceConnectionModel.fields.rdb_is_sample_data = result.ds_connection.rdb_is_sample_data;
              newDataSourceConnectionModel.fields.rdb_sample_data_max_size =
                result.ds_connection.rdb_sample_data_max_size;
              newDataSourceConnectionModel.fields.username = result.ds_connection.username;
              newDataSourceConnectionModel.fields.password = MASKED_PASSWORD_STRING;
              newDataSourceConnectionModel.fields.rdb_comma_sep_table_list =
                result.ds_connection.rdb_comma_sep_table_list;
              newDataSourceConnectionModel.fields.stop_on_failure = result.ds_connection.stop_on_failure;
              newDataSourceConnectionModel.fields.jcoAsClient = result.ds_connection.jcoAsClient;
              newDataSourceConnectionModel.fields.jcoAsPoolCapacity = result.ds_connection.jcoAsPoolCapacity;
              newDataSourceConnectionModel.fields.numberOfParsingThreads = result.ds_connection.numberOfParsingThreads;
              newDataSourceConnectionModel.fields.scanTimeoutInSeconds = result.ds_connection.scanTimeoutInSeconds;
              newDataSourceConnectionModel.fields.snc_mode = result.ds_connection.snc_mode;
              newDataSourceConnectionModel.fields.override_blacklist = result.ds_connection.override_blacklist;
              newDataSourceConnectionModel.fields.scan_page_size = result.ds_connection.scan_page_size;
              newDataSourceConnectionModel.fields.snc_partner_name = result.ds_connection.snc_partner_name;
              newDataSourceConnectionModel.showTestBtn = true;
            }

            if (isHadoopScanner(result.ds_connection.type)) {
              if (newDataSourceConnectionModel.fields.Is_sample_files == 'true') {
                newDataSourceConnectionModel.fields.file_percent_to_sample =
                  result.ds_connection.file_percent_to_sample;
              } else {
                delete newDataSourceConnectionModel.fields.file_percent_to_sample;
              }

              //Data redaction
              if (newDataSourceConnectionModel.fields.is_redaction_enabled == 'true') {
                newDataSourceConnectionModel.fields.redaction_outputdir = result.ds_connection.redaction_outputdir;
                newDataSourceConnectionModel.fields.redaction_masking_char =
                  result.ds_connection.redaction_masking_char;
              } else {
                delete newDataSourceConnectionModel.fields.redaction_outputdir;
                delete newDataSourceConnectionModel.fields.redaction_masking_char;
              }

              newDataSourceConnectionModel.fields.ner_classification_is_enabled =
                result.ds_connection.ner_classification_is_enabled;
              newDataSourceConnectionModel.fields.doc2vec_is_enabled = result.ds_connection.doc2vec_is_enabled;
            }

            if (isFileScanner(result.ds_connection.type)) {
              newDataSourceConnectionModel.fields.numberOfParsingThreads = result.ds_connection.numberOfParsingThreads;
              if (newDataSourceConnectionModel.fields.Is_sample_folders == 'true') {
                newDataSourceConnectionModel.fields.folder_percent_to_sample =
                  result.ds_connection.folder_percent_to_sample;
              } else {
                //don't show in view in case rdb_is_sample_data is off
                delete newDataSourceConnectionModel.fields.folder_percent_to_sample;
              }

              newDataSourceConnectionModel.fields.ner_classification_is_enabled =
                result.ds_connection.ner_classification_is_enabled;
              newDataSourceConnectionModel.fields.doc2vec_is_enabled = result.ds_connection.doc2vec_is_enabled;
              newDataSourceConnectionModel.fields.is_ocr_enabled = result.ds_connection.is_ocr_enabled;
              if (newDataSourceConnectionModel.fields.is_ocr_enabled == 'true') {
                newDataSourceConnectionModel.fields.ocr_timeout_in_seconds =
                  result.ds_connection.ocr_timeout_in_seconds;
              } else {
                //don't show in view in case ocr is off
                delete newDataSourceConnectionModel.fields.ocr_timeout_in_seconds;
              }
              newDataSourceConnectionModel.fieldsDDL.selectedOcrLanguagesOption = getSelectedLanguage(
                result.ds_connection.ocr_languages,
                availableOcrLanguagesOptions,
              );
            }

            if (result.ds_connection.type === 'generic-rest-api' || result.ds_connection.type === 'vsam') {
              newDataSourceConnectionModel.fields.stop_on_failure = result.ds_connection.stop_on_failure;
              newDataSourceConnectionModel.fields.rdb_is_sample_data = result.ds_connection.rdb_is_sample_data;
              newDataSourceConnectionModel.fields.rdb_sample_data_max_size = result.rdb_sample_data_max_size;
              newDataSourceConnectionModel.fields.username = result.ds_connection.username;
              newDataSourceConnectionModel.fields.password = MASKED_PASSWORD_STRING;
              newDataSourceConnectionModel.fields.generic_server_url = result.ds_connection.generic_server_url;
              newDataSourceConnectionModel.fields.obj_to_scan = result.ds_connection.obj_to_scan;
              newDataSourceConnectionModel.fields.fetch_size = result.ds_connection.fetch_size;
              newDataSourceConnectionModel.fields.numberOfParsingThreads = result.ds_connection.numberOfParsingThreads;
              newDataSourceConnectionModel.fields.scanTimeoutInSeconds = result.ds_connection.scanTimeoutInSeconds;
            }

            if (result.ds_connection.type === 'generic-rest-api-unstructured') {
              newDataSourceConnectionModel.fields.stop_on_failure = result.ds_connection.stop_on_failure;
              newDataSourceConnectionModel.fields.username = result.ds_connection.username;
              newDataSourceConnectionModel.fields.password = MASKED_PASSWORD_STRING;
              newDataSourceConnectionModel.fields.generic_server_url = result.ds_connection.generic_server_url;
              newDataSourceConnectionModel.fields.fetch_size = result.ds_connection.fetch_size;
              newDataSourceConnectionModel.fields.numberOfParsingThreads = result.ds_connection.numberOfParsingThreads;
              newDataSourceConnectionModel.fields.scanTimeoutInSeconds = result.ds_connection.scanTimeoutInSeconds;
              newDataSourceConnectionModel.fields.folder_to_scan = result.ds_connection.folder_to_scan;
              newDataSourceConnectionModel.fields.Is_sample_folders = result.ds_connection.Is_sample_folders;
              newDataSourceConnectionModel.fields.folder_percent_to_sample =
                result.ds_connection.folder_percent_to_sample;
              newDataSourceConnectionModel.fields.bucket_name = result.ds_connection.bucket_name;
              newDataSourceConnectionModel.fields.Is_sample_files = result.ds_connection.Is_sample_files;
              newDataSourceConnectionModel.fields.file_percent_to_sample = result.ds_connection.file_percent_to_sample;
              newDataSourceConnectionModel.fields.path_to_scan = result.ds_connection.path_to_scan;
              newDataSourceConnectionModel.fields.include_file_types = result.ds_connection.include_file_types;
              newDataSourceConnectionModel.fields.fileTypesToExclude = result.ds_connection.fileTypesToExclude;

              newDataSourceConnectionModel.fields.containersFilterRegex = result.ds_connection.containersFilterRegex;
              newDataSourceConnectionModel.fields.exclude_container_regex =
                result.ds_connection.exclude_container_regex;
              newDataSourceConnectionModel.fields.is_containers_filter_enabled =
                result.ds_connection.is_containers_filter_enabled;
              newDataSourceConnectionModel.fields.containersCommaSeparatedFilter =
                result.ds_connection.containersCommaSeparatedFilter;

              newDataSourceConnectionModel.fields.is_folders_filter_enabled =
                result.ds_connection.is_folders_filter_enabled;
              newDataSourceConnectionModel.fields.scan_custom_libs = result.ds_connection.scan_custom_libs;
              newDataSourceConnectionModel.fields.exclude_folders_regex = result.ds_connection.exclude_folders_regex;
              newDataSourceConnectionModel.fields.foldersFilterRegex = result.ds_connection.foldersFilterRegex;
              newDataSourceConnectionModel.fields.foldersCommaSeparatedFilter =
                result.ds_connection.foldersCommaSeparatedFilter;
            }

            if (result.ds_connection.type === 'generic-rest-api') {
              newDataSourceConnectionModel.fields.oauth_custom_url = result.ds_connection.oauth_custom_url;
              newDataSourceConnectionModel.fields.client_id = result.ds_connection.client_id;
              newDataSourceConnectionModel.fields.client_secret = MASKED_PASSWORD_STRING;
            }

            if (result.ds_connection.type === 'workday') {
              newDataSourceConnectionModel.fields.stop_on_failure = result.ds_connection.stop_on_failure;
              newDataSourceConnectionModel.fields.rdb_is_sample_data = result.ds_connection.rdb_is_sample_data;
              newDataSourceConnectionModel.fields.rdb_sample_data_max_size =
                result.ds_connection.rdb_sample_data_max_size;
              newDataSourceConnectionModel.fields.username = result.ds_connection.username;
              newDataSourceConnectionModel.fields.password = MASKED_PASSWORD_STRING;
              newDataSourceConnectionModel.fields.request_url = result.ds_connection.request_url;
              newDataSourceConnectionModel.fields.fetch_size = result.ds_connection.fetch_size;
              newDataSourceConnectionModel.fields.numberOfParsingThreads = result.ds_connection.numberOfParsingThreads;
              newDataSourceConnectionModel.fields.scanTimeoutInSeconds = result.ds_connection.scanTimeoutInSeconds;
            }

            if (result.ds_connection.type === 'sharepoint') {
              newDataSourceConnectionModel.fields.username = result.ds_connection.username;
              newDataSourceConnectionModel.fields.password = MASKED_PASSWORD_STRING;
              newDataSourceConnectionModel.fields.sharepoint_host = result.ds_connection.sharepoint_host;
              newDataSourceConnectionModel.fields.sharepoint_path = result.ds_connection.sharepoint_path;
              newDataSourceConnectionModel.fields.sharepoint_domain = result.ds_connection.sharepoint_domain;
              newDataSourceConnectionModel.fields.client_device_name = result.ds_connection.client_device_name;
              newDataSourceConnectionModel.fields.sharepoint_port = result.ds_connection.sharepoint_port
                ? result.ds_connection.sharepoint_port
                : 80;
              newDataSourceConnectionModel.fields.sharepoint_protocol = result.ds_connection.sharepoint_protocol
                ? result.ds_connection.sharepoint_protocol
                : 'http';
              newDataSourceConnectionModel.fields.folder_to_scan = result.ds_connection.folder_to_scan;
              newDataSourceConnectionModel.fields.fileTypesToExclude = result.ds_connection.fileTypesToExclude;
              newDataSourceConnectionModel.fields.scan_subsites = result.ds_connection.scan_subsites;
              newDataSourceConnectionModel.fields.scan_site_collections = result.ds_connection.scan_site_collections;
              newDataSourceConnectionModel.fields.exclude_sites = result.ds_connection.exclude_sites;
              newDataSourceConnectionModel.fields.exclude_sites_with_list =
                result.ds_connection.exclude_sites_with_list;
              newDataSourceConnectionModel.fields.sites_to_exclude_list = result.ds_connection.sites_to_exclude_list;
              newDataSourceConnectionModel.fields.exclude_sites_with_regex =
                result.ds_connection.exclude_sites_with_regex;
              newDataSourceConnectionModel.fields.sites_to_exclude_regex = result.ds_connection.sites_to_exclude_regex;
              newDataSourceConnectionModel.fields.exclude_folders = result.ds_connection.exclude_folders;
              newDataSourceConnectionModel.fields.is_exclude_file_types = result.ds_connection.is_exclude_file_types;
              newDataSourceConnectionModel.fields.exclude_folders_with_list =
                result.ds_connection.exclude_folders_with_list;
              newDataSourceConnectionModel.fields.folders_to_exclude_list =
                result.ds_connection.folders_to_exclude_list;
              newDataSourceConnectionModel.fields.exclude_folders_with_regex =
                result.ds_connection.exclude_folders_with_regex;
              newDataSourceConnectionModel.fields.folders_to_exclude_regex =
                result.ds_connection.folders_to_exclude_regex;
              newDataSourceConnectionModel.fields.exclude_file_size = result.ds_connection.exclude_file_size;
              newDataSourceConnectionModel.fields.file_size_min = result.ds_connection.file_size_min;
              newDataSourceConnectionModel.fields.file_size_max = result.ds_connection.file_size_max;
            } else {
              delete newDataSourceConnectionModel.fields.file_size_min;
              delete newDataSourceConnectionModel.fields.file_size_max;
              if (newDataSourceConnectionModel.fields.exclude_sites === 'true') {
                newDataSourceConnectionModel.fields.exclude_sites_with_list =
                  result.ds_connection.exclude_sites_with_list;
                newDataSourceConnectionModel.fields.sites_to_exclude_list = result.ds_connection.sites_to_exclude_list;
                newDataSourceConnectionModel.fields.exclude_sites_with_regex =
                  result.ds_connection.exclude_sites_with_regex;
                newDataSourceConnectionModel.fields.sites_to_exclude_regex =
                  result.ds_connection.sites_to_exclude_regex;
              } else {
                delete newDataSourceConnectionModel.fields.exclude_sites_with_list;
                delete newDataSourceConnectionModel.fields.sites_to_exclude_list;
                delete newDataSourceConnectionModel.fields.exclude_sites_with_regex;
                delete newDataSourceConnectionModel.fields.sites_to_exclude_regex;
              }
              if (newDataSourceConnectionModel.fields.exclude_folders === 'true') {
                newDataSourceConnectionModel.fields.exclude_folders_with_list =
                  result.ds_connection.exclude_folders_with_list;
                newDataSourceConnectionModel.fields.folders_to_exclude_list =
                  result.ds_connection.folders_to_exclude_list;
                newDataSourceConnectionModel.fields.exclude_folders_with_regex =
                  result.ds_connection.exclude_folders_with_regex;
                newDataSourceConnectionModel.fields.folders_to_exclude_regex =
                  result.ds_connection.folders_to_exclude_regex;
              } else {
                delete newDataSourceConnectionModel.fields.exclude_folders_with_list;
                delete newDataSourceConnectionModel.fields.folders_to_exclude_list;
                delete newDataSourceConnectionModel.fields.exclude_folders_with_regex;
                delete newDataSourceConnectionModel.fields.folders_to_exclude_regex;
              }
              if (newDataSourceConnectionModel.fields.exclude_file_size === 'true') {
                newDataSourceConnectionModel.fields.file_size_min = result.ds_connection.file_size_min;
                newDataSourceConnectionModel.fields.file_size_max = result.ds_connection.file_size_max;
              } else {
                delete newDataSourceConnectionModel.fields.file_size_min;
                delete newDataSourceConnectionModel.fields.file_size_max;
              }
            }

            if (result.ds_connection.type === 'snowflake') {
              newDataSourceConnectionModel.fields.username = result.ds_connection.username;
              newDataSourceConnectionModel.fields.password = MASKED_PASSWORD_STRING;
              newDataSourceConnectionModel.fields.db_name = result.ds_connection.db_name;
              newDataSourceConnectionModel.fields.schema_name = result.ds_connection.schema_name;
              newDataSourceConnectionModel.fields.warehouse = result.ds_connection.warehouse;
              newDataSourceConnectionModel.fields.privateLink = result.ds_connection.privateLink;
              newDataSourceConnectionModel.fields.privateLinkCustomUrl = result.ds_connection.privateLinkCustomUrl;
              newDataSourceConnectionModel.fields.regionId = result.ds_connection.regionId;
              newDataSourceConnectionModel.fields.rdb_comma_sep_table_list =
                result.ds_connection.rdb_comma_sep_table_list;
              newDataSourceConnectionModel.fields.stop_on_failure = result.ds_connection.stop_on_failure;
              newDataSourceConnectionModel.fields.rdb_is_scan_views = result.ds_connection.rdb_is_scan_views;
              newDataSourceConnectionModel.fields.rdb_is_only_user_objects =
                result.ds_connection.rdb_is_only_user_objects;
              newDataSourceConnectionModel.fields.rdb_is_sample_data = result.ds_connection.rdb_is_sample_data;
              newDataSourceConnectionModel.fields.rdb_sample_data_max_size =
                result.ds_connection.rdb_sample_data_max_size;
              newDataSourceConnectionModel.fields.enrichment_is_enabled = result.ds_connection.enrichment_is_enabled;
              newDataSourceConnectionModel.fields.account = result.ds_connection.account;
              newDataSourceConnectionModel.fields.role = result.ds_connection.role;
              newDataSourceConnectionModel.fields.numberOfParsingThreads = result.ds_connection.numberOfParsingThreads;
              newDataSourceConnectionModel.fields.scanTimeoutInSeconds = result.ds_connection.scanTimeoutInSeconds;
              newDataSourceConnectionModel.fields.ignore_types = result.ds_connection.ignore_types;
            }

            if (result.ds_connection.type === 'athena') {
              newDataSourceConnectionModel.fields.db_name = result.ds_connection.db_name;
              newDataSourceConnectionModel.fields.aws_access_key = result.ds_connection.aws_access_key;
              newDataSourceConnectionModel.fields.aws_secret_key = MASKED_PASSWORD_STRING;
              if (result.ds_connection.aws_session_token) {
                newDataSourceConnectionModel.fields.aws_session_token = MASKED_PASSWORD_STRING;
              }
              newDataSourceConnectionModel.fields.isIamRoleAuth = result.ds_connection.isIamRoleAuth;
              newDataSourceConnectionModel.fields.S3OutputLocation = result.ds_connection.S3OutputLocation;
              newDataSourceConnectionModel.fields.additionalConnProps = result.ds_connection.additionalConnProps;
              newDataSourceConnectionModel.fields.aws_region = result.ds_connection.aws_region;
              newDataSourceConnectionModel.fields.rdb_comma_sep_table_list =
                result.ds_connection.rdb_comma_sep_table_list;
              newDataSourceConnectionModel.fields.stop_on_failure = result.ds_connection.stop_on_failure;
              newDataSourceConnectionModel.fields.rdb_is_scan_views = result.ds_connection.rdb_is_scan_views;
              newDataSourceConnectionModel.fields.rdb_is_sample_data = result.ds_connection.rdb_is_sample_data;
              newDataSourceConnectionModel.fields.enrichment_is_enabled = result.ds_connection.enrichment_is_enabled;
              newDataSourceConnectionModel.fields.numberOfParsingThreads = result.ds_connection.numberOfParsingThreads;
              newDataSourceConnectionModel.fields.scanTimeoutInSeconds = result.ds_connection.scanTimeoutInSeconds;
              newDataSourceConnectionModel.fields.ignore_types = result.ds_connection.ignore_types;
            }

            if (result.ds_connection.type === 'o365-outlook') {
              newDataSourceConnectionModel.fields.domain = result.ds_connection.domain;
              newDataSourceConnectionModel.fields.include_accounts = result.ds_connection.include_accounts;
              newDataSourceConnectionModel.fields.account_to_scan = result.ds_connection.account_to_scan;
              newDataSourceConnectionModel.fields.accounts_filter_enabled =
                result.ds_connection.accounts_filter_enabled;
              newDataSourceConnectionModel.fields.account_to_scan_regex = result.ds_connection.account_to_scan_regex;
              newDataSourceConnectionModel.fields.folder_filter_enabled = result.ds_connection.folder_filter_enabled;
              newDataSourceConnectionModel.fields.include_folders = result.ds_connection.include_folders;
              newDataSourceConnectionModel.fields.folders_to_scan_list = result.ds_connection.folders_to_scan_list;
              newDataSourceConnectionModel.fields.folders_to_scan_regex = result.ds_connection.folders_to_scan_regex;
              newDataSourceConnectionModel.fields.scan_from_date = result.ds_connection.scan_from_date
                ? new Date(result.ds_connection.scan_from_date)
                : result.ds_connection.scan_from_date;
              newDataSourceConnectionModel.fields.client_id = result.ds_connection.client_id;
              newDataSourceConnectionModel.fields.client_secret = MASKED_PASSWORD_STRING;
              newDataSourceConnectionModel.fields.test_scan_mail_id_filter_enabled =
                result.ds_connection.test_scan_mail_id_filter_enabled;
              newDataSourceConnectionModel.fields.test_scan_mail_id = result.ds_connection.test_scan_mail_id;
              newDataSourceConnectionModel.fields.include_file_types = result.ds_connection.include_file_types;
              newDataSourceConnectionModel.fields.fileTypesToExclude = result.ds_connection.fileTypesToExclude;
              newDataSourceConnectionModel.fields.Is_sample_messages_within_folders =
                result.ds_connection.Is_sample_messages_within_folders;
              newDataSourceConnectionModel.fields.folder_percent_to_sample_messages =
                result.ds_connection.folder_percent_to_sample_messages;
              newDataSourceConnectionModel.fields.Is_sample_message_body = result.ds_connection.Is_sample_message_body;
              newDataSourceConnectionModel.fields.message_body_percent_to_sample =
                result.ds_connection.message_body_percent_to_sample;
              newDataSourceConnectionModel.fields.scan_events_enabled = result.ds_connection.scan_events_enabled;
              newDataSourceConnectionModel.fields.scan_contacts_enabled = result.ds_connection.scan_contacts_enabled;
            }

            if (result.ds_connection.type === 'o365-onedrive') {
              newDataSourceConnectionModel.fields.domain = result.ds_connection.domain;
              newDataSourceConnectionModel.fields.include_accounts = result.ds_connection.include_accounts;
              newDataSourceConnectionModel.fields.account_to_scan = result.ds_connection.account_to_scan;
              newDataSourceConnectionModel.fields.accounts_filter_enabled =
                result.ds_connection.accounts_filter_enabled;
              newDataSourceConnectionModel.fields.account_to_scan_regex = result.ds_connection.account_to_scan_regex;
              newDataSourceConnectionModel.fields.path_to_scan = result.ds_connection.path_to_scan;
              newDataSourceConnectionModel.fields.client_id = result.ds_connection.client_id;
              newDataSourceConnectionModel.fields.client_secret = MASKED_PASSWORD_STRING;
              newDataSourceConnectionModel.fields.fileTypesToExclude = result.ds_connection.fileTypesToExclude;
              newDataSourceConnectionModel.fields.folderNamesToExclude = result.ds_connection.folderNamesToExclude;
              newDataSourceConnectionModel.fields.Is_sample_messages_within_folders =
                result.ds_connection.Is_sample_messages_within_folders;
              newDataSourceConnectionModel.fields.folder_percent_to_sample_messages =
                result.ds_connection.folder_percent_to_sample_messages;
              newDataSourceConnectionModel.fields.Is_sample_message_body = result.ds_connection.Is_sample_message_body;
              newDataSourceConnectionModel.fields.message_body_percent_to_sample =
                result.ds_connection.message_body_percent_to_sample;
            }

            if (result.ds_connection.type === 'exchange') {
              newDataSourceConnectionModel.fields.activeDirectoryPort = result.ds_connection.activeDirectoryPort;
              newDataSourceConnectionModel.fields.activeDirectoryHost = result.ds_connection.activeDirectoryHost;
              newDataSourceConnectionModel.fields.activeDirectoryUsername =
                result.ds_connection.activeDirectoryUsername;
              newDataSourceConnectionModel.fields.activeDirectoryDomain = result.ds_connection.activeDirectoryDomain;
              newDataSourceConnectionModel.fields.activeDirectoryPassword = MASKED_PASSWORD_STRING;
              newDataSourceConnectionModel.fields.exchangeServerHost = result.ds_connection.exchangeServerHost;
              newDataSourceConnectionModel.fields.exchangeServerUsername = result.ds_connection.exchangeServerUsername;
              newDataSourceConnectionModel.fields.exchangeServerPassword = MASKED_PASSWORD_STRING;
              newDataSourceConnectionModel.fields.listOfUserMailboxes = result.ds_connection.listOfUserMailboxes;
              newDataSourceConnectionModel.fields.ner_classification_is_enabled =
                result.ds_connection.ner_classification_is_enabled;
              //@todo, dannyw - yevgeni - why is only here? what about (result.ds_connection.type === "smb")?
              newDataSourceConnectionModel.fields.doc2vec_is_enabled = result.ds_connection.doc2vec_is_enabled;
            }

            if (result.ds_connection.type === 'netsuite') {
              newDataSourceConnectionModel.fields.url = result.ds_connection.url;
              newDataSourceConnectionModel.fields.account_id = result.ds_connection.account_id;
              newDataSourceConnectionModel.fields.consumer_key = result.ds_connection.consumer_key;
              newDataSourceConnectionModel.fields.consumer_secret = result.ds_connection.consumer_secret;
              newDataSourceConnectionModel.fields.token_id = result.ds_connection.token_id;
              newDataSourceConnectionModel.fields.token_secret = result.ds_connection.token_secret;
              newDataSourceConnectionModel.fields.objects_to_scan = result.ds_connection.objects_to_scan;
            }

            if (result.ds_connection.type === 'ldap') {
              newDataSourceConnectionModel.fields.host_name = result.ds_connection.host_name;
              newDataSourceConnectionModel.fields.port = result.ds_connection.port;
              newDataSourceConnectionModel.fields.user_dn = result.ds_connection.user_dn;
              newDataSourceConnectionModel.fields.secure_connection = result.ds_connection.secure_connection;
              if (result.ds_connection.password === undefined || result.ds_connection.password === '') {
                newDataSourceConnectionModel.fields.password = '';
              } else {
                newDataSourceConnectionModel.fields.password = MASKED_PASSWORD_STRING;
              }
              if (!newDataSourceConnectionModel.isNewPassword && newDataSourceConnectionModel.realPass !== '') {
                newDataSourceConnectionModel.fields.password = MASKED_PASSWORD_STRING;
              }
              newDataSourceConnectionModel.fields.base_dn = result.ds_connection.base_dn;
              newDataSourceConnectionModel.fields.excluded_dn = result.ds_connection.excluded_dn;
              newDataSourceConnectionModel.fields.paging_enabled = result.ds_connection.paging_enabled;
              newDataSourceConnectionModel.fields.page_size = result.ds_connection.page_size;
              newDataSourceConnectionModel.fields.numberOfParsingThreads = result.ds_connection.numberOfParsingThreads;
              newDataSourceConnectionModel.fields.rdb_is_sample_data = result.ds_connection.rdb_is_sample_data;
              if (newDataSourceConnectionModel.fields.rdb_is_sample_data) {
                newDataSourceConnectionModel.fields.rdb_sample_data_max_size =
                  result.ds_connection.rdb_sample_data_max_size;
              } else {
                //don't show in view in case is_sample_data is off
                delete newDataSourceConnectionModel.fields.rdb_sample_data_max_size;
              }
            }

            if (result.ds_connection.type === 'gitlab') {
              newDataSourceConnectionModel.fields.server_url = result.ds_connection.server_url;
              newDataSourceConnectionModel.fields.username = result.ds_connection.username;
              newDataSourceConnectionModel.fields.password = MASKED_PASSWORD_STRING;
              newDataSourceConnectionModel.fields.include_file_types = result.ds_connection.include_file_types;
              newDataSourceConnectionModel.fields.fileTypesToExclude = result.ds_connection.fileTypesToExclude;
              newDataSourceConnectionModel.fields.numberOfParsingThreads = result.ds_connection.numberOfParsingThreads;
              newDataSourceConnectionModel.fields.isUserCredentials = result.ds_connection.isUserCredentials;
              newDataSourceConnectionModel.fields.isPersonalAccessToken = result.ds_connection.isPersonalAccessToken;
              newDataSourceConnectionModel.fields.personal_access_token = result.ds_connection.personal_access_token;
              newDataSourceConnectionModel.fields.projects_filter_enabled =
                result.ds_connection.projects_filter_enabled;
              newDataSourceConnectionModel.fields.is_exclusion_projects_enabled =
                result.ds_connection.is_exclusion_projects_enabled;
              newDataSourceConnectionModel.fields.project_to_scan_regex = result.ds_connection.project_to_scan_regex;
              newDataSourceConnectionModel.fields.project_to_scan_list = result.ds_connection.project_to_scan_list;
              newDataSourceConnectionModel.fields.branches_filter_enabled =
                result.ds_connection.branches_filter_enabled;
              newDataSourceConnectionModel.fields.is_exclusion_branches_enabled =
                result.ds_connection.is_exclusion_branches_enabled;
              newDataSourceConnectionModel.fields.branch_to_scan_regex = result.ds_connection.branch_to_scan_regex;
              newDataSourceConnectionModel.fields.branch_to_scan_list = result.ds_connection.branch_to_scan_list;
              newDataSourceConnectionModel.fields.folders_filter_enabled = result.ds_connection.folders_filter_enabled;
              newDataSourceConnectionModel.fields.is_exclusion_folders_enabled =
                result.ds_connection.is_exclusion_folders_enabled;
              newDataSourceConnectionModel.fields.path_to_scan_regex = result.ds_connection.path_to_scan_regex;
              newDataSourceConnectionModel.fields.path_to_scan = result.ds_connection.path_to_scan;
              newDataSourceConnectionModel.fields.Is_sample_folders = result.ds_connection.Is_sample_folders;
              newDataSourceConnectionModel.fields.folder_percent_to_sample =
                result.ds_connection.folder_percent_to_sample;
              newDataSourceConnectionModel.fields.Is_sample_files = result.ds_connection.Is_sample_files;
              newDataSourceConnectionModel.fields.file_percent_to_sample = result.ds_connection.file_percent_to_sample;
              newDataSourceConnectionModel.fields.is_ocr_enabled = result.ds_connection.is_ocr_enabled;
              newDataSourceConnectionModel.fields.selectedOcrLanguagesOption =
                result.ds_connection.selectedOcrLanguagesOption;
              newDataSourceConnectionModel.fields.ocr_timeout_in_seconds = result.ds_connection.ocr_timeout_in_seconds;
            }
            if (result.ds_connection.type === 'documentum') {
              newDataSourceConnectionModel.fields.repository_filter = result.ds_connection.repository_filter;
              newDataSourceConnectionModel.fields.cabinet_filter = result.ds_connection.cabinet_filter;
              newDataSourceConnectionModel.fields.folder_filter = result.ds_connection.folder_filter;
              newDataSourceConnectionModel.fields.external_files_host = result.ds_connection.external_files_host;
              newDataSourceConnectionModel.fields.host_address = result.ds_connection.host_address;
              newDataSourceConnectionModel.fields.username = result.ds_connection.username;
              newDataSourceConnectionModel.fields.password = MASKED_PASSWORD_STRING;
              newDataSourceConnectionModel.fields.include_file_types = result.ds_connection.include_file_types;
              newDataSourceConnectionModel.fields.fileTypesToExclude = result.ds_connection.fileTypesToExclude;
              newDataSourceConnectionModel.fields.numberOfParsingThreads = result.ds_connection.numberOfParsingThreads;
            }

            if (supportsAcl(result.ds_connection.type)) {
              const enableAclValue =
                result.ds_connection.metadataAclScanEnabled != undefined &&
                result.ds_connection.dsAclScanEnabled != undefined;
              const aclDisabled =
                result.ds_connection.metadataAclScanEnabled === 'false' &&
                result.ds_connection.dsAclScanEnabled === 'false';
              newDataSourceConnectionModel.fields.enable_acl = enableAclValue ? !aclDisabled : true;
            }

            if (result.ds_connection.type === 'zendesk') {
              newDataSourceConnectionModel.fields.zendeskUrl = result.ds_connection.zendeskUrl;
              newDataSourceConnectionModel.fields.username = result.ds_connection.username;
              newDataSourceConnectionModel.fields.zendeskAuthentication = result.ds_connection.zendeskAuthentication;
              newDataSourceConnectionModel.fields.rdb_is_sample_data = result.ds_connection.rdb_is_sample_data;
              newDataSourceConnectionModel.fields.rdb_comma_sep_table_list =
                result.ds_connection.rdb_comma_sep_table_list;
              if (newDataSourceConnectionModel.fields.zendeskAuthentication == 'password') {
                newDataSourceConnectionModel.fields.password = MASKED_PASSWORD_STRING;
              } else {
                newDataSourceConnectionModel.fields.zendeskApiToken = MASKED_PASSWORD_STRING;
              }
            }

            if (
              typeof result.ds_connection.custom_fields != 'undefined' &&
              result.ds_connection.custom_fields.length > 0
            ) {
              newDataSourceConnectionModel.customConnectorFields = result.ds_connection.custom_fields.map(field => {
                field['input_type'] = CUSTOM_CONNECTOR_FIELD_TYPE_MAPPING[field['field_type']];

                if (field['field_type'] === CUSTOM_CONNECTOR_FIELD_PASSWORD_TYPE) {
                  field['field_original_value'] = angular.copy(field['field_value']);
                }

                return field;
              });

              newDataSourceConnectionModel.isCustomFieldsVisible = true;
            }

            if (result.ds_connection.type === 'custom-connector') {
              newDataSourceConnectionModel.fields.custom_connector_type = result.ds_connection.custom_connector_type;
              newDataSourceConnectionModel.fields.main_class_name = result.ds_connection.main_class_name;
              newDataSourceConnectionModel.fields.custom_connector_jar_name =
                result.ds_connection.custom_connector_jar_name;
              newDataSourceConnectionModel.showTestBtn = true;
            }

            if (result.ds_connection.type === 'cims') {
              newDataSourceConnectionModel.fields.rdb_server_id = result.ds_connection.rdb_server_id;
              newDataSourceConnectionModel.fields.rdb_url = result.ds_connection.rdb_url;
              newDataSourceConnectionModel.fields.rdb_name = result.ds_connection.rdb_name;
              newDataSourceConnectionModel.fields.rdb_conn_properties = result.ds_connection.rdb_conn_properties;
              newDataSourceConnectionModel.fields.stop_on_failure = result.ds_connection.stop_on_failure;
              newDataSourceConnectionModel.fields.username = result.ds_connection.username;
              newDataSourceConnectionModel.fields.password = MASKED_PASSWORD_STRING;
              newDataSourceConnectionModel.fields.enrichment_is_enabled = result.ds_connection.enrichment_is_enabled;
              newDataSourceConnectionModel.fields.numberOfParsingThreads = result.ds_connection.numberOfParsingThreads;
              newDataSourceConnectionModel.fields.scanTimeoutInSeconds = result.ds_connection.scanTimeoutInSeconds;
              newDataSourceConnectionModel.fields.security_tier = result.ds_connection.security_tier;
              newDataSourceConnectionModel.fields.rdb_is_sample_data = result.ds_connection.rdb_is_sample_data;
            }

            if (result.ds_connection.type === 'elasticsearch') {
              // scanner related fields
              newDataSourceConnectionModel.fields.stop_on_failure = result.ds_connection.stop_on_failure;
              newDataSourceConnectionModel.fields.rdb_is_sample_data = result.ds_connection.rdb_is_sample_data;
              newDataSourceConnectionModel.fields.rdb_sample_data_max_size =
                result.ds_connection.rdb_sample_data_max_size;
              newDataSourceConnectionModel.fields.numberOfParsingThreads = result.ds_connection.numberOfParsingThreads;
              newDataSourceConnectionModel.fields.scanTimeoutInSeconds = result.ds_connection.scanTimeoutInSeconds;

              // connector related fields
              newDataSourceConnectionModel.fields.request_url = result.ds_connection.request_url;
              newDataSourceConnectionModel.fields.server_type = result.ds_connection.server_type;
              newDataSourceConnectionModel.fields.list_of_indices = result.ds_connection.list_of_indices;
              newDataSourceConnectionModel.fields.old_api_support_mode = result.ds_connection.old_api_support_mode;
              newDataSourceConnectionModel.fields.basic_auth_support = result.ds_connection.basic_auth_support;
              newDataSourceConnectionModel.fields.elastic_username = result.ds_connection.elastic_username;
              lodashSet(
                newDataSourceConnectionModel.fields,
                'aws_key_id',
                lodashGet(result.ds_connection, 'aws_key_id'),
              );
              lodashSet(newDataSourceConnectionModel.fields, 'aws_key_secret', MASKED_PASSWORD_STRING);
              lodashSet(newDataSourceConnectionModel.fields, 'es_password', MASKED_PASSWORD_STRING);
              newDataSourceConnectionModel.fields.list_of_indices = result.ds_connection.list_of_indices;
              newDataSourceConnectionModel.fields.page_size = result.ds_connection.page_size;

              // aws connection related fields (if server_type is aws)
              newDataSourceConnectionModel.fields.region = result.ds_connection.region;
            }

            if (result.ds_connection.type === 'redis') {
              newDataSourceConnectionModel.fields.rdb_url = result.ds_connection.rdb_url;
              newDataSourceConnectionModel.fields.rdb_name = result.ds_connection.rdb_name;
              newDataSourceConnectionModel.fields.stop_on_failure = result.ds_connection.stop_on_failure;
              newDataSourceConnectionModel.fields.password = MASKED_PASSWORD_STRING;
              newDataSourceConnectionModel.fields.scanTimeoutInSeconds = result.ds_connection.scanTimeoutInSeconds;
              newDataSourceConnectionModel.fields.rdb_is_sample_data = result.ds_connection.rdb_is_sample_data;
              newDataSourceConnectionModel.fields.rdb_sample_data_max_size =
                result.ds_connection.rdb_sample_data_max_size;
              newDataSourceConnectionModel.fields.redisKeysToInclude = result.ds_connection.redisKeysToInclude;
            }

            if (result.ds_connection.type === 'sharepoint-online') {
              newDataSourceConnectionModel.fields.domain = result.ds_connection.domain;
              newDataSourceConnectionModel.fields.scan_subsites_spo = result.ds_connection.scan_subsites_spo;
              newDataSourceConnectionModel.fields.site_to_scan = result.ds_connection.site_to_scan;
              newDataSourceConnectionModel.fields.sites_filter_enabled = result.ds_connection.sites_filter_enabled;
              newDataSourceConnectionModel.fields.folders_filter_enabled = result.ds_connection.folders_filter_enabled;
              newDataSourceConnectionModel.fields.is_exclude_file_types = result.ds_connection.is_exclude_file_types;
              newDataSourceConnectionModel.fields.scan_custom_libs = result.ds_connection.scan_custom_libs;
              if (newDataSourceConnectionModel.fields.folders_filter_enabled === false) {
                newDataSourceConnectionModel.fields.path_to_scan = null;
                newDataSourceConnectionModel.fields.folder_to_scan_regex = null;
              } else {
                newDataSourceConnectionModel.fields.path_to_scan = result.ds_connection.path_to_scan;
                newDataSourceConnectionModel.fields.folder_to_scan_regex = result.ds_connection.folder_to_scan_regex;
              }
              if (newDataSourceConnectionModel.fields.is_exclude_file_types === false) {
                newDataSourceConnectionModel.fields.fileTypesToExclude = null;
                newDataSourceConnectionModel.fields.is_exclusion_folders_enabled = null;
              } else {
                newDataSourceConnectionModel.fields.fileTypesToExclude = result.ds_connection.fileTypesToExclude;
                newDataSourceConnectionModel.fields.include_file_types = result.ds_connection.include_file_types;
              }
              newDataSourceConnectionModel.fields.is_exclusion_sites_enabled =
                result.ds_connection.is_exclusion_sites_enabled;
              newDataSourceConnectionModel.fields.is_exclusion_folders_enabled =
                result.ds_connection.is_exclusion_folders_enabled;
              newDataSourceConnectionModel.fields.site_to_scan_regex = result.ds_connection.site_to_scan_regex;
              newDataSourceConnectionModel.fields.spo_host_name = result.ds_connection.spo_host_name;
              newDataSourceConnectionModel.fields.client_id = result.ds_connection.client_id;
              newDataSourceConnectionModel.fields.client_secret = MASKED_PASSWORD_STRING;
              newDataSourceConnectionModel.fields.fileTypesToExclude = result.ds_connection.fileTypesToExclude;
              newDataSourceConnectionModel.fields.folderNamesToExclude = result.ds_connection.folderNamesToExclude;
              newDataSourceConnectionModel.fields.Is_sample_folders = result.ds_connection.Is_sample_folders;
              newDataSourceConnectionModel.fields.folder_percent_to_sample =
                result.ds_connection.folder_percent_to_sample;
              newDataSourceConnectionModel.fields.Is_sample_files = result.ds_connection.Is_sample_files;
              newDataSourceConnectionModel.fields.file_percent_to_sample = result.ds_connection.file_percent_to_sample;
            }

            if (result.ds_connection.type.includes('-dsar')) {
              newDataSourceConnectionModel.fields.tray = result.ds_connection.tray;
            }
            if (result.ds_connection.type === 'github') {
              newDataSourceConnectionModel.fields.server_url = result.ds_connection.server_url;
              newDataSourceConnectionModel.fields.include_file_types = result.ds_connection.include_file_types;
              newDataSourceConnectionModel.fields.fileTypesToExclude = result.ds_connection.fileTypesToExclude;
              newDataSourceConnectionModel.fields.numberOfParsingThreads = result.ds_connection.numberOfParsingThreads;
              newDataSourceConnectionModel.fields.isUserCredentials = result.ds_connection.isUserCredentials;
              newDataSourceConnectionModel.fields.isPersonalAccessToken = result.ds_connection.isPersonalAccessToken;
              newDataSourceConnectionModel.fields.personal_access_token = result.ds_connection.personal_access_token;
              newDataSourceConnectionModel.fields.projects_filter_enabled =
                result.ds_connection.organizations_filter_enabled;
              newDataSourceConnectionModel.fields.is_exclusion_organizations_enabled =
                result.ds_connection.is_exclusion_organizations_enabled;
              newDataSourceConnectionModel.fields.organizations_to_scan_regex =
                result.ds_connection.organizations_to_scan_regex;
              newDataSourceConnectionModel.fields.organizations_to_scan_list =
                result.ds_connection.organizations_to_scan_list;
              newDataSourceConnectionModel.fields.organizations_filter_enabled =
                result.ds_connection.organizations_filter_enabled;
              newDataSourceConnectionModel.fields.is_exclusion_projects_enabled =
                result.ds_connection.is_exclusion_projects_enabled;
              newDataSourceConnectionModel.fields.project_to_scan_regex = result.ds_connection.project_to_scan_regex;
              newDataSourceConnectionModel.fields.project_to_scan_list = result.ds_connection.project_to_scan_list;
              newDataSourceConnectionModel.fields.branches_filter_enabled =
                result.ds_connection.branches_filter_enabled;
              newDataSourceConnectionModel.fields.is_exclusion_branches_enabled =
                result.ds_connection.is_exclusion_branches_enabled;
              newDataSourceConnectionModel.fields.branch_to_scan_regex = result.ds_connection.branch_to_scan_regex;
              newDataSourceConnectionModel.fields.branch_to_scan_list = result.ds_connection.branch_to_scan_list;
              newDataSourceConnectionModel.fields.folders_filter_enabled = result.ds_connection.folders_filter_enabled;
              newDataSourceConnectionModel.fields.is_exclusion_folders_enabled =
                result.ds_connection.is_exclusion_folders_enabled;
              newDataSourceConnectionModel.fields.path_to_scan_regex = result.ds_connection.path_to_scan_regex;
              newDataSourceConnectionModel.fields.path_to_scan = result.ds_connection.path_to_scan;
              newDataSourceConnectionModel.fields.folder_to_scan = result.ds_connection.folder_to_scan;
              newDataSourceConnectionModel.fields.Is_sample_folders = result.ds_connection.Is_sample_folders;
              newDataSourceConnectionModel.fields.folder_percent_to_sample =
                result.ds_connection.folder_percent_to_sample;
              newDataSourceConnectionModel.fields.Is_sample_files = result.ds_connection.Is_sample_files;
              newDataSourceConnectionModel.fields.file_percent_to_sample = result.ds_connection.file_percent_to_sample;
              newDataSourceConnectionModel.fields.is_ocr_enabled = result.ds_connection.is_ocr_enabled;
              newDataSourceConnectionModel.fields.selectedOcrLanguagesOption =
                result.ds_connection.selectedOcrLanguagesOption;
              newDataSourceConnectionModel.fields.ocr_timeout_in_seconds = result.ds_connection.ocr_timeout_in_seconds;
            }
          }

          newDataSourceConnectionModel.dataLoading = false;
        },
        () => {
          newDataSourceConnectionModel.dataLoading = false;

          $translate('API:MESSAGE:COMMON_ERROR').then(translation => {
            notificationService.error(translation);
          });
        },
        dsConnectionId,
      );
    } else {
      newDataSourceConnectionModel.dataLoading = true;

      initializeData(
        result => {
          newDataSourceConnectionModel.isSmallID = smallIdLicenseService.isSmallIDLicense();
          newDataSourceConnectionModel.isNerClassifierFlagEnabled = getNerClassifierFeatureIsOn(result);
          newDataSourceConnectionModel.fields.password = '';
          newDataSourceConnectionModel.fields.aws_secret_key = '';
          newDataSourceConnectionModel.fields.client_secret = '';
          newDataSourceConnectionModel.fields.access_token = '';
          newDataSourceConnectionModel.fields.refresh_token = '';

          newDataSourceConnectionModel.dataLoading = false;
          newDataSourceConnectionModel.kafkaConnectPlugins = {
            availableOptions: result.allKafkaConnectPlugins,
          };
          newDataSourceConnectionModel.supportedSplitPartTypeNames = result.supportedSplitPartType;
          newDataSourceConnectionModel.scanWindowsNamesList = buildScanWindowModel(result.scanWindowsNames);
        },
        () => {
          newDataSourceConnectionModel.dataLoading = false;

          $translate('API:MESSAGE:COMMON_ERROR').then(translation => {
            notificationService.error(translation);
          });
        },
      );
    }

    getSupportedDataSources().then(data => {
      availableTypeOptions = data;
      newDataSourceConnectionModel.type = { availableOptions: availableTypeOptions };
    });

    newDataSourceConnectionModel.es_server_type = ['public', 'AWS'];

    newDataSourceConnectionModel.regionId = regionId;
    newDataSourceConnectionModel.apiFamily = apiFamily;

    newDataSourceConnectionModel.security = {
      availableOptions: availableSecurityOptions,
    };

    newDataSourceConnectionModel.ocr_languages = {
      availableLanguages: availableOcrLanguagesOptions,
    };

    newDataSourceConnectionModel.aws_auth = {
      availableOptions: availableAwsAuthenticationOptions,
    };

    newDataSourceConnectionModel.fieldsDDL.selectedTypeOption = null;
    newDataSourceConnectionModel.fieldsDDL.prevSelectedTypeOptionName = null;

    newDataSourceConnectionModel.fieldsDDL.selectedSecurityTierOption = {
      id: '1',
      name: '1', //This sets the default value of the select in the ui
    };

    newDataSourceConnectionModel.fieldsDDL.selectedOcrLanguagesOption = {
      id: 'eng',
      name: 'English',
    };
    newDataSourceConnectionModel.scanner_strategy = {
      availableOptions: availableScannerStrategyOptions,
    };

    newDataSourceConnectionModel.keyDeserializer = {
      availableOptions: availableSerializerOptions,
    };

    newDataSourceConnectionModel.valueDeserializer = {
      availableOptions: availableSerializerOptions,
    };

    const getStringSerializerDropDownOption = (availableSerializerOptions, SerializerTypes) => {
      return availableSerializerOptions.filter(
        serialziationOption => serialziationOption.name === SerializerTypes.String,
      )[0];
    };

    newDataSourceConnectionModel.fieldsDDL.valueDeserializer = getStringSerializerDropDownOption(
      availableSerializerOptions,
      SerializerTypes,
    );
    newDataSourceConnectionModel.fieldsDDL.keyDeserializer = getStringSerializerDropDownOption(
      availableSerializerOptions,
      SerializerTypes,
    );

    newDataSourceConnectionModel.fieldsDDL.selectedScannerStrategyOption = {
      id: '1',
      name: 'SCAN_ALL', //This sets the default value of the select in the ui
    };

    newDataSourceConnectionModel.enabled = {
      availableOptions: availableEnableOptions,
    };

    newDataSourceConnectionModel.fieldsDDL.selectedEnabledOption = {
      id: '1',
      name: 'Yes', //This sets the default value of the select in the ui
    };

    newDataSourceConnectionModel.fields.differential = DIFFERENTIAL_DEFAULT_VALUE;
    newDataSourceConnectionModel.fields.is_modified_in_x_days = IS_MODIFIELD_X_LAST_DAYS_DEFAULT_VALUE;
    newDataSourceConnectionModel.fields.x_last_days = newDataSourceConnectionModel.DEFAULT_X_DAYS;

    newDataSourceConnectionModel.addHadoopCustomProp = () => {
      newDataSourceConnectionModel.hadoopCustomProperties.push({
        name: '',
        value: '',
      });
    };

    newDataSourceConnectionModel.removeHadoopCustomProp = index => {
      newDataSourceConnectionModel.hadoopCustomProperties.splice(index, 1);
    };

    newDataSourceConnectionModel.addCustomPartitionCondition = () => {
      newDataSourceConnectionModel.customPartitionConditions.push({
        tableName: '',
        partitionCondition: '',
      });
    };

    newDataSourceConnectionModel.removeCustomPartitionCondition = index => {
      newDataSourceConnectionModel.customPartitionConditions.splice(index, 1);
    };

    newDataSourceConnectionModel.customFieldVisibilityChanged = isEnabled => {
      if (!isEnabled) {
        newDataSourceConnectionModel.customConnectorFieldsDuplicateNames = [];
        newDataSourceConnectionModel.customConnectorFields = [Object.assign({}, CUSTOM_CONNECTOR_DEFAULT_FIELD)];
      }
    };

    newDataSourceConnectionModel.areCustomFieldsSupported = () => {
      return (
        !newDataSourceConnectionModel.isHadoop &&
        !newDataSourceConnectionModel.isEMR &&
        !newDataSourceConnectionModel.isHBase
      );
    };

    newDataSourceConnectionModel.fetchCredentialsByDsType = criteria => item =>
      item.credential_type === criteria.toLowerCase();

    newDataSourceConnectionModel.addOwnerEmail = () => {
      if (newDataSourceConnectionModel.fields.owners) {
        newDataSourceConnectionModel.fields.owners.push('');
      }
    };

    newDataSourceConnectionModel.deleteOwnerEmail = index => {
      if (index >= 0 && newDataSourceConnectionModel.fields.owners) {
        if (newDataSourceConnectionModel.fields.owners.length === 1) {
          newDataSourceConnectionModel.fields.owners[index] = '';
        } else {
          newDataSourceConnectionModel.fields.owners.splice(index, 1);
        }
      }
    };

    newDataSourceConnectionModel.addApiFamilyRow = () => {
      newDataSourceConnectionModel.apiFamilyProperties.push({
        scan: '',
        filter: '',
      });
    };
    newDataSourceConnectionModel.removeApiFamilyRow = index => {
      newDataSourceConnectionModel.apiFamilyProperties.splice(index, 1);
      if (!newDataSourceConnectionModel.apiFamilyProperties.length) {
        newDataSourceConnectionModel.apiFamilyProperties = [
          {
            scan: '',
            filter: '',
          },
        ];
      }
    };
    newDataSourceConnectionModel.handleFormAction = (form, action) => {
      newDataSourceConnectionModel.isFormSubmitted = true;

      // make custom validations here
      if (newDataSourceConnectionModel.fields.listOfUserMailboxes) {
        const emailRegex =
          /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

        const userMailboxes = newDataSourceConnectionModel.fields.listOfUserMailboxes.split(',') || [];

        const invalidMailboxes = userMailboxes.filter(userMailbox => !emailRegex.test(userMailbox));

        form.listOfUserMailboxes.$setValidity('invalidListOfEmails', invalidMailboxes.length === 0);
      }
      newDataSourceConnectionModel.apiFamily.forEach(({ apiFamily }) => {
        const field = newDataSourceConnectionModel.apiFamilyProperties.find(({ scan }) => scan === apiFamily);
        newDataSourceConnectionModel.fields[apiFamily] = field ? field.filter : null;
      });

      if (newDataSourceConnectionModel.isSupportsAcl()) {
        newDataSourceConnectionModel.fields.metadataAclScanEnabled = String(
          Boolean(newDataSourceConnectionModel.fields.enable_acl),
        );
        newDataSourceConnectionModel.fields.dsAclScanEnabled = String(
          Boolean(newDataSourceConnectionModel.fields.enable_acl),
        );
        newDataSourceConnectionModel.fields.enable_acl = Boolean(newDataSourceConnectionModel.fields.enable_acl);
      }
      switch (action) {
        case 'save':
          if (newDataSourceConnectionModel.isWorkday && newDataSourceConnectionModel.fields.credential_id) {
            newDataSourceConnectionModel.fields.password = '';
            newDataSourceConnectionModel.fields.username = '';
          }
          newDataSourceConnectionModel.savingMode = true;
          newDataSourceConnectionModel.testingMode = false;
          handleScanWindowOnSave();
          break;
        case 'test':
          if (newDataSourceConnectionModel.isWorkday && newDataSourceConnectionModel.fields.credential_id) {
            newDataSourceConnectionModel.fields.password = '';
            newDataSourceConnectionModel.fields.username = '';
          }
          newDataSourceConnectionModel.savingMode = false;
          newDataSourceConnectionModel.testingMode = true;
          break;
        case 'oauthOnedrive':
        case 'oauthGoogle':
        case 'oauthSalesforce':
        case 'oauthServicenow':
        case 'oauthTray':
          newDataSourceConnectionModel.savingMode = true;
          newDataSourceConnectionModel.testingMode = false;
          newDataSourceConnectionModel.deletingMode = false;
          break;
        case 'deletePii':
          newDataSourceConnectionModel.savingMode = false;
          newDataSourceConnectionModel.testingMode = false;
          break;
      }

      newDataSourceConnectionModel[action](canSubmit());
    };

    newDataSourceConnectionModel.save = isValid => {
      if (!isValid) return;

      set_ds_connections();

      //case of update
      if ($stateParams.id) {
        httpService
          .put('ds_connections/' + $stateParams.id, $scope.ds_connection)
          .then(() => {
            newDataSourceConnectionModel.isFormSubmitted = false;
            newDataSourceConnectionModel.updatedPasswords = [];
            notificationService.success('Updated ' + $stateParams.id + ' Successfully! ');
            $state.go('dataSourceConnections');
          })
          .catch(handleSaveError);
      }
      //case of insert
      else {
        httpService
          .post('ds_connections', $scope.ds_connection)
          .then(() => {
            newDataSourceConnectionModel.isFormSubmitted = false;
            notificationService.success('Saved Successfully! ');
            $scope.ds_connection.ds_connection['creationSuccess'] = true;
            $state.go('dataSourceConnections');
          })
          .catch(handleSaveError)
          .finally(() => {
            trackCreateNewDataSourceBiEvent($scope.ds_connection.ds_connection);
          });
      }
    };

    const handleSaveError = ({ response }) => {
      const message = `Saved Failed! ${response?.data?.message || ''}`;
      notificationService.error(message);
      newDataSourceConnectionModel.isFormSubmitted = false;
    };

    newDataSourceConnectionModel.oauthOnedrive = isValid => {
      if (!isValid) return;
      oauthConnect('onedrive');
    };

    newDataSourceConnectionModel.oauthGoogle = isValid => {
      if (!isValid) return;
      oauthConnect('google');
    };

    newDataSourceConnectionModel.oauthSalesforce = isValid => {
      if (!isValid) return;
      oauthConnect('salesforce');
    };
    newDataSourceConnectionModel.oauthServicenow = isValid => {
      if (!isValid) return;
      oauthConnect('servicenow');
    };

    newDataSourceConnectionModel.oauthTray = isValid => {
      if (isValid) {
        oauthConnect('iterable-dsar');
      }
    };

    newDataSourceConnectionModel.initCassandraDsarButton = () => {
      newDataSourceConnectionModel.fields.dsar_efficient_search_only =
        newDataSourceConnectionModel.fields.dsar_efficient_search_only !== false;
    };

    newDataSourceConnectionModel.updateGoogleServiceAccount = isGoogleServiceAccount => {
      if (isGoogleServiceAccount || (!isGoogleServiceAccount && newDataSourceConnectionModel.isOauthConfigured)) {
        newDataSourceConnectionModel.showTestBtn = true;
      } else {
        newDataSourceConnectionModel.showTestBtn = false;
      }
    };

    newDataSourceConnectionModel.initElasticType = () => {
      newDataSourceConnectionModel.fields.server_type = newDataSourceConnectionModel.fields.server_type || 'public';
    };

    newDataSourceConnectionModel.updateProtocol = protocol => {
      newDataSourceConnectionModel.sharepoint_protocol = protocol;
    };

    newDataSourceConnectionModel.updateZendeskAuthentication = authenticationType => {
      newDataSourceConnectionModel.fields.zendeskAuthentication = authenticationType;
    };

    newDataSourceConnectionModel.updateJcoConnectionType = jcoConnectionType => {
      newDataSourceConnectionModel.fields.jcoConnectionType = jcoConnectionType;
    };

    newDataSourceConnectionModel.isDifferentialSupported = () => {
      return (
        !newDataSourceConnectionModel.isAnyKindOfKafka() &&
        !newDataSourceConnectionModel.isGenericRestApi &&
        !newDataSourceConnectionModel.isVSAM &&
        !newDataSourceConnectionModel.isDocumentum &&
        !newDataSourceConnectionModel.isSparkSql &&
        !newDataSourceConnectionModel.isCosmosDB &&
        !newDataSourceConnectionModel.isSlack &&
        !newDataSourceConnectionModel.isDynamoDB
      );
    };

    newDataSourceConnectionModel.isDimSampleAllowed = () => {
      return newDataSourceConnectionModel.fields.dimSampleEnabled;
    };

    newDataSourceConnectionModel.isAvroSerializerSelected = () => {
      return (
        newDataSourceConnectionModel.fieldsDDL.keyDeserializer.name === SerializerTypes.Avro ||
        newDataSourceConnectionModel.fieldsDDL.valueDeserializer.name === SerializerTypes.Avro
      );
    };

    newDataSourceConnectionModel.initAwsAuth = () => {
      if (newDataSourceConnectionModel.fields.isSTSAuth === true) {
        newDataSourceConnectionModel.fields.isCredentialsAuth = true;
      }
      newDataSourceConnectionModel.fields.awsAuthStrategy =
        (newDataSourceConnectionModel.fields.isSTSAuth << 4) |
        (newDataSourceConnectionModel.fields.isCrossAccountAuth << 3) |
        (newDataSourceConnectionModel.fields.isCredentialsAuth << 2) |
        (newDataSourceConnectionModel.fields.isIamRoleAuth << 1) |
        newDataSourceConnectionModel.fields.isAnonymousAuth;

      if (!newDataSourceConnectionModel.fields.awsAuthStrategy) {
        newDataSourceConnectionModel.fields.awsAuthStrategy = CredentialsAuth;
        newDataSourceConnectionModel.fields.isCredentialsAuth = true;
      }
      newDataSourceConnectionModel.isAwsAuthDisbale =
        (CredentialsAuth & newDataSourceConnectionModel.fields.awsAuthStrategy) === 0;
      newDataSourceConnectionModel.fieldsDDL.selectedAwsAuthenticationOption = getSelectedLanguage(
        newDataSourceConnectionModel.fields.awsAuthStrategy,
        availableAwsAuthenticationOptions,
      );
    };

    newDataSourceConnectionModel.updateAwsAuth = awsAuth => {
      newDataSourceConnectionModel.fields.isSTSAuth = (STSAuth & awsAuth) > 0;
      newDataSourceConnectionModel.fields.isCrossAccountAuth = (CrossAccountAuth & awsAuth) > 0;
      newDataSourceConnectionModel.fields.isCredentialsAuth = (CredentialsAuth & awsAuth) > 0;
      newDataSourceConnectionModel.fields.isIamRoleAuth = (IamRoleAuth & awsAuth) > 0;
      newDataSourceConnectionModel.fields.isAnonymousAuth = (AnonymousAuth & awsAuth) > 0;
      newDataSourceConnectionModel.isAwsAuthDisbale = (CredentialsAuth & awsAuth) === 0;
    };

    const oauthConnect = oauthConnectionType => {
      set_ds_connections();
      $scope.ds_connection.ds_connection.isOauthConfigured = false;

      const location = window.location;
      const domain = location.protocol + '//' + location.hostname + (location.port ? ':' + location.port : '');

      //case of update
      if ($stateParams.id) {
        $http
          .put(
            appSettings.serverPath + '/' + appSettings.version + '/ds_connections/' + $stateParams.id,
            $scope.ds_connection,
          )
          .then(response => {
            newDataSourceConnectionModel.isFormSubmitted = false;
            let destinationUrl =
              '/api/v1/oauth/' + oauthConnectionType + '/' + $stateParams.id + '?callbackBaseUrl=' + domain;
            if (newDataSourceConnectionModel.fields.is_salesforce_custom_url) {
              destinationUrl += '&oauthTokenHost=' + newDataSourceConnectionModel.fields.salesforce_custom_url;
            }
            if (newDataSourceConnectionModel.isServiceNow) {
              destinationUrl += '&oauthTokenHost=' + newDataSourceConnectionModel.fields.oauth_custom_url;
              destinationUrl += '&clientId=' + newDataSourceConnectionModel.fields.client_id;
              destinationUrl +=
                '&clientSecret=' + encodeURIComponent(newDataSourceConnectionModel.fields.client_secret);
            }
            const type = $scope.ds_connection.ds_connection.type;
            if (type === 'gmail' || type === 'googledrive') {
              destinationUrl += '&type=' + type;
            }
            $window.location = destinationUrl;
          });
      }

      //case of insert
      else {
        $http.post(appSettings.serverPath + '/' + appSettings.version + '/ds_connections', $scope.ds_connection).then(
          response => {
            newDataSourceConnectionModel.isFormSubmitted = false;
            let destinationUrl =
              '/api/v1/oauth/' +
              oauthConnectionType +
              '/' +
              $scope.ds_connection.ds_connection.name +
              '?callbackBaseUrl=' +
              domain;
            if (newDataSourceConnectionModel.fields.is_salesforce_custom_url) {
              destinationUrl += '&oauthTokenHost=' + newDataSourceConnectionModel.fields.salesforce_custom_url;
            }
            if (newDataSourceConnectionModel.isServiceNow) {
              destinationUrl += '&oauthTokenHost=' + newDataSourceConnectionModel.fields.oauth_custom_url;
              destinationUrl += '&clientId=' + newDataSourceConnectionModel.fields.client_id;
              destinationUrl += '&clientSecret=' + newDataSourceConnectionModel.fields.client_secret;
            }
            const type = $scope.ds_connection.ds_connection.type;
            if (type === 'gmail' || type === 'googledrive') {
              destinationUrl += '&type=' + type;
            }
            $window.location = destinationUrl;
          },
          response => {
            notificationService.error('Saved Failed! ');
            newDataSourceConnectionModel.isFormSubmitted = false;
          },
        );
      }
    };

    const getCredentials = newCredentialName => {
      credentialsService.getCredentials().then(result => {
        newDataSourceConnectionModel.credentials = result.data;

        if (typeof newCredentialName != 'undefined') {
          newDataSourceConnectionModel.fields.credential_id = newCredentialName;
        }
      });
    };

    const loadCertificates = () => {
      certificateManagementGetCertificate('').then(({ results }) => {
        newDataSourceConnectionModel.certificates = results || [];
      });
    };

    newDataSourceConnectionModel.clearCertificateSelection = event => {
      event.preventDefault();
      event.stopPropagation();
      event.cancelBubble = true;
      newDataSourceConnectionModel.fields.certificate_id = '';
    };

    newDataSourceConnectionModel.disconnectOAuth = isValid => {
      if (!isValid) return;

      $http
        .get(appSettings.serverPath + '/' + appSettings.version + '/ds_connections/disconnect-oauth/' + $stateParams.id)
        .then(function (response) {
          notificationService.success('Updated ' + $stateParams.id + ' Successfully! ');
          newDataSourceConnectionModel.isOauthConfigured = false;
          newDataSourceConnectionModel.showTestBtn = false;
        });
    };

    const addIndexToTestConnectionData = (data = []) => {
      return data.map((row, index) => ({ ...row, id: index.toString() }));
    };

    const testDSConnectionSync = () =>
      dataSourceConnectionsService
        .testDataSourceConnectionSync($scope.ds_connection)
        .then(response => {
          newDataSourceConnectionModel.testRunning = false;
          newDataSourceConnectionModel.dataLoading = false;
          newDataSourceConnectionModel.isFormSubmitted = false;
          $scope.updateTimeStamps();
          switch (response.data.operationStatus) {
            case 'SUCCESS':
              if (response.data.tablesResult) {
                newDataSourceConnectionModel.isTestConnectionSucceeded = true;
                $scope.newDataSourceConnectionModelGrid.data = addIndexToTestConnectionData(response.data.tablesResult);
                notificationService.success('Test Connection Succeeded! ');
                $rootScope.$emit('$scrollTop');
              }
              break;
            case 'FAILURE':
              newDataSourceConnectionModel.isTestConnectionSucceeded = false;
              notificationService.error(response.data.error, 'Test Connection Failed!');
              break;
          }
        })
        .catch(({ response: { data } } = { data: {} }) => {
          newDataSourceConnectionModel.testRunning = false;
          newDataSourceConnectionModel.isFormSubmitted = false;
          newDataSourceConnectionModel.dataLoading = false;
          newDataSourceConnectionModel.isTestConnectionSucceeded = false;

          const errorMessage = data.errMessage || 'Test Connection Failed!';
          notificationService.error(errorMessage);
        });

    const testDSConnectionSSE = dsConnection =>
      dataSourceConnectionsService
        .testDataSourceConnection(dsConnection, (results, errors) => {
          newDataSourceConnectionModel.testRunning = false;
          newDataSourceConnectionModel.dataLoading = false;
          newDataSourceConnectionModel.isFormSubmitted = false;
          $scope.updateTimeStamps();
          if (errors.length > 0 || results.length === 0) {
            newDataSourceConnectionModel.isTestConnectionSucceeded = false;
            $rootScope.$applyAsync();
            return;
          }

          const testConnectionResponse = results[0];
          switch (testConnectionResponse.operationStatus) {
            case 'SUCCESS':
              if (testConnectionResponse.tablesResult) {
                newDataSourceConnectionModel.isTestConnectionSucceeded = true;
                $scope.newDataSourceConnectionModelGrid.data = addIndexToTestConnectionData(
                  testConnectionResponse.tablesResult,
                );
                $rootScope.$applyAsync();
                $rootScope.$emit('$scrollTop');
              }
              break;
            case 'FAILURE':
              newDataSourceConnectionModel.isTestConnectionSucceeded = false;
              $rootScope.$applyAsync();
              break;
          }
        })
        .then(removeListener => (newDataSourceConnectionModel.removeTestConnectionListener = removeListener));

    newDataSourceConnectionModel.test = isValid => {
      if (!isValid) return;

      set_ds_connections(true);

      newDataSourceConnectionModel.testRunning = true;
      newDataSourceConnectionModel.dataLoading = true;

      const dsConnectionName = $scope.ds_connection.ds_connection.name;
      const dsConnection = {
        ...$scope.ds_connection,
        broadcastEvent: `${dsConnectionName}-test-connection-complete-${uuid()}`,
      };

      if (getApplicationPreference('SSE_TEST_CONNECTION')) {
        testDSConnectionSSE(dsConnection);
      } else {
        testDSConnectionSync();
      }
    };

    newDataSourceConnectionModel.passwordTypeInputChange = fieldName => {
      if (!newDataSourceConnectionModel.updatedPasswords.includes(fieldName)) {
        newDataSourceConnectionModel.updatedPasswords.push(fieldName);
      }
    };

    newDataSourceConnectionModel.showOptionalKafkaConnectParams = () => {
      newDataSourceConnectionModel.fields.kafkaConnectPluginParameters = fetchFormattedKeyValueKafkaPluginParams(
        newDataSourceConnectionModel.isKafkaConnectNonMandatoryFieldsEnabled === false,
      );
    };
    newDataSourceConnectionModel.deletePii = () => {
      set_ds_connections(true);
      const dsConn = $scope.ds_connection;
      const systemName = dsConn.ds_connection.name;

      newDataSourceConnectionModel.deletingRunning = true;

      const modalOptions = {
        closeButtonText: 'Close',
        actionButtonText: 'Delete',
        headerText: 'Delete source findings',
        bodyText: 'Are you sure you want to delete all findings from source ' + systemName + '?',
      };

      DeleteConfirmation.showModal({}, modalOptions).then(
        resolveCallback => {
          newDataSourceConnectionModel.testRunning = false;

          dataSourceConnectionsService.deletePiiDataForDataSource(systemName).then(
            response => {
              newDataSourceConnectionModel.testRunning = false;
              newDataSourceConnectionModel.deletingRunning = false;
              $scope.updateTimeStamps();

              notificationService.success('Delete Findings for data source Succeeded! ');
            },
            response => {
              newDataSourceConnectionModel.testRunning = false;
              newDataSourceConnectionModel.deletingRunning = false;

              if (response.data) {
                notificationService.error(response.data, 'Delete Findings for data source Failed!');
              }
              $scope.updateTimeStamps();
            },
          );
        },
        rejectCallback => {
          newDataSourceConnectionModel.deletingRunning = false;
          newDataSourceConnectionModel.testRunning = false;
        },
      );
    };

    $scope.loadHadoopConf = $event => {
      $event.preventDefault();
      const hadoopConfUrl = newDataSourceConnectionModel.fields.hadoop_conf_url;
      if (hadoopConfUrl) {
        newDataSourceConnectionService.getHadoopConfiguration(hadoopConfUrl).then(result => {
          if (result) {
            newDataSourceConnectionModel.fields.yarn_uri = result['yarn.resourcemanager.address'];
            newDataSourceConnectionModel.fields.hdfs_uri = result['fs.defaultFS'];

            newDataSourceConnectionModel.fields.namenode_principal = result['dfs.namenode.kerberos.principal'];
            newDataSourceConnectionModel.fields.yarn_nodemanager_principal = result['yarn.nodemanager.principal'];
            newDataSourceConnectionModel.fields.yarn_principal = result['yarn.resourcemanager.principal'];

            if (
              newDataSourceConnectionModel.fields.yarn_principal ||
              newDataSourceConnectionModel.fields.yarn_nodemanager_principal ||
              newDataSourceConnectionModel.fields.yarn_principal
            ) {
              newDataSourceConnectionModel.fields.auth_method = 'KERBEROS';
            }

            newDataSourceConnectionModel.fields.yarn_hostname = result['yarn.resourcemanager.hostname'];
            newDataSourceConnectionModel.fields.yarn_scheduler_address =
              result['yarn.resourcemanager.scheduler.address'];
            newDataSourceConnectionModel.fields.mapreduce_framework_path =
              result['mapreduce.application.framework.path'];
            newDataSourceConnectionModel.fields.yarn_classpath = result['yarn.application.classpath'];
            newDataSourceConnectionModel.fields.mapreduce_classpath = result['mapreduce.application.classpath'];
            newDataSourceConnectionModel.fields.mapreduce_job_queuename = result['mapreduce.job.queuename'];
          }
        });
      }
    };

    const handleScanWindowOnSave = () => {
      if (newDataSourceConnectionModel.isShowScanWindow()) {
        newDataSourceConnectionModel.fields.scanWindowName =
          newDataSourceConnectionModel.fields.scanWindowName?.name || NONE;
      }
    };

    const canSubmit = () => {
      let isFormValid = newDataSourceConnectionModel.dataSourceConnection.$valid;

      if (newDataSourceConnectionModel.isCustomConnector || newDataSourceConnectionModel.isCustomFieldsVisible) {
        newDataSourceConnectionModel.customConnectorFieldsDuplicateNames = getCustomFieldsDuplicateNames();
        isFormValid = isFormValid && newDataSourceConnectionModel.customConnectorFieldsDuplicateNames.length === 0;
      }

      return isFormValid;
    };

    newDataSourceConnectionModel.$onInit = () => {
      newDataSourceConnectionModel.isCreatePermitted = isPermitted(DATA_SOURCES_PERMISSIONS.CREATE.name);
      newDataSourceConnectionModel.isDeletePermitted = isPermitted(DATA_SOURCES_PERMISSIONS.DELETE.name);
      newDataSourceConnectionModel.isEditPermitted = isPermitted(DATA_SOURCES_PERMISSIONS.EDIT.name);
      newDataSourceConnectionModel.isSavePermitted = $stateParams.id
        ? newDataSourceConnectionModel.isEditPermitted
        : newDataSourceConnectionModel.isCreatePermitted;
      newDataSourceConnectionModel.isDeleteFindingsPermitted = isPermitted(
        DATA_SOURCES_PERMISSIONS.DELETE_FINDING.name,
      );
      newDataSourceConnectionModel.isTestConnectionPermitted = isPermitted(
        DATA_SOURCES_PERMISSIONS.TEST_CONNECTION.name,
      );
      newDataSourceConnectionModel.isCreateCredentialPermitted = isPermitted(CREDENTIALS_PERMISSIONS.CREATE.name);
      newDataSourceConnectionModel.isEditCredentialPermitted = isPermitted(CREDENTIALS_PERMISSIONS.EDIT.name);

      $translate('DATA_SOURCE_CONNECTIONS').then(breadcrumb => {
        const pageTitle = $stateParams.id ? 'Edit Data Source Connections' : 'New Data Source Connections';

        $rootScope.$broadcast('changePage', pageTitle, false, true, null, [
          { label: breadcrumb, onClick: () => $state.go('dataSourceConnections') },
          { label: pageTitle },
        ]);

        getSupportedDataSources().then(dsTypes => {
          availableTypeOptions = dsTypes;
          if ($stateParams.selectedType) {
            newDataSourceConnectionModel.fieldsDDL.selectedTypeOption = getSelectedObject(
              $stateParams.selectedType,
              availableTypeOptions,
            );

            newDataSourceConnectionModel.update($stateParams.selectedType, true);
          } else {
            setModelType('isMySQLDB');
            newDataSourceConnectionModel.showTestBtn = true;
          }

          getCredentials();
          loadCertificates();
          newDataSourceConnectionModel.countries = getCopyOfCountries(
            getApplicationPreference('DISPLAY_CHINA_REGULATIONS'),
          );
        });
      });
    };
    let allKafkaConnectPluginParameters;

    function getPluginsByRequired(isRequired) {
      return allKafkaConnectPluginParameters.data.configs
        .filter(param => param.definition.required === isRequired)
        .map(param => ({ key: param.value.name, value: param.value.value }));
    }

    function fetchFormattedKeyValueKafkaPluginParams(isRequiredOnly) {
      let objResult = [];
      if (isRequiredOnly) {
        objResult = getPluginsByRequired(true);
      } else {
        objResult = getPluginsByRequired(true).concat(getPluginsByRequired(false));
      }

      const result = {};

      objResult.forEach(keyValue => {
        result[keyValue.key] = keyValue.value;
      });

      return JSON.stringify(result, null, 2);
    }

    newDataSourceConnectionModel.isAllKafkaConnectPluginParametersExist = () => {
      return allKafkaConnectPluginParameters !== undefined || dsConnectionId;
    };

    newDataSourceConnectionModel.isAnyKindOfKafka = () => {
      return (
        newDataSourceConnectionModel.isKafka ||
        newDataSourceConnectionModel.isKafkaScanApi ||
        newDataSourceConnectionModel.isKafkaConnect
      );
    };
    newDataSourceConnectionModel.isSupportsAcl = () => {
      return (
        newDataSourceConnectionModel.isS3 ||
        newDataSourceConnectionModel.isSmb ||
        newDataSourceConnectionModel.isGoogleDrive ||
        newDataSourceConnectionModel.isO365OneDrive ||
        newDataSourceConnectionModel.isGoogleCloudStorage ||
        newDataSourceConnectionModel.isSharePointOnline
      );
    };

    newDataSourceConnectionModel.isAnyKindOfGoogleForHashiCorp = () => {
      return (
        newDataSourceConnectionModel.isGoogleBigTable ||
        newDataSourceConnectionModel.isGoogleDrive ||
        newDataSourceConnectionModel.isGoogleCloudStorage ||
        newDataSourceConnectionModel.isBigQuery
      );
    };

    newDataSourceConnectionModel.isAnyKindOfPostgresql = () => {
      return (
        newDataSourceConnectionModel.isPostgreSQLDB ||
        newDataSourceConnectionModel.isYellowbrick ||
        newDataSourceConnectionModel.isHadoopHawq
      );
    };

    newDataSourceConnectionModel.isDataInMotion = () => {
      return newDataSourceConnectionModel.isAnyKindOfKafka() || newDataSourceConnectionModel.isKinesis;
    };

    newDataSourceConnectionModel.isShowStructuredClustering = () =>
      getApplicationPreference('SHOW_STRUCTURED_CLUSTERING');

    newDataSourceConnectionModel.isDynamicNumberOfParsingThreadsEnabled = () =>
      getApplicationPreference('DYNAMIC_MODIFY_PARSING_THREADS_FF');

    newDataSourceConnectionModel.isUnstructuredDs = () =>
      newDataSourceConnectionModel.isGoogleDrive ||
      newDataSourceConnectionModel.isSharePoint ||
      newDataSourceConnectionModel.isSharePointOnline ||
      newDataSourceConnectionModel.isNFS ||
      newDataSourceConnectionModel.isHadoop ||
      newDataSourceConnectionModel.isAzureBlob ||
      newDataSourceConnectionModel.isEMR ||
      newDataSourceConnectionModel.isO365OneDrive ||
      newDataSourceConnectionModel.isSMB ||
      newDataSourceConnectionModel.isS3 ||
      newDataSourceConnectionModel.isAzureFile ||
      newDataSourceConnectionModel.isOneDrivePersonal ||
      newDataSourceConnectionModel.isBox ||
      newDataSourceConnectionModel.isGenericRestApiUnstructured ||
      newDataSourceConnectionModel.isDocumentum ||
      newDataSourceConnectionModel.isGitLab ||
      newDataSourceConnectionModel.isGitHub;

    newDataSourceConnectionModel.isShowScanWindow = () => getApplicationPreference('SCAN_WINDOW_FF');

    newDataSourceConnectionModel.isSchemaRegistry = () => {
      return newDataSourceConnectionModel.isAvroSerializerSelected() && newDataSourceConnectionModel.isAnyKindOfKafka();
    };

    newDataSourceConnectionModel.updateSelectedKafkaConnectPlugin = overridePluginParameters => {
      allKafkaConnectPluginParameters = undefined;
      //console.log(newDataSourceConnectionModel.fieldsDDL.selectedKafkaConnectPlugin);
      //
      const selectedKafkaConnectPluginClass = newDataSourceConnectionModel.fieldsDDL.selectedKafkaConnectPlugin.id;
      newDataSourceConnectionModel.isKafkaConnectNonMandatoryFieldsEnabled = false;
      $http
        .put(
          appSettings.serverPath +
            '/' +
            appSettings.version +
            '/kafka-connect/connector-plugins/' +
            selectedKafkaConnectPluginClass +
            '/config/validate',
        )
        .then(
          response => {
            allKafkaConnectPluginParameters = response;
            if (overridePluginParameters) {
              newDataSourceConnectionModel.fields.kafkaConnectPluginParameters =
                fetchFormattedKeyValueKafkaPluginParams(true);
            }
          },
          error => {
            notificationService.error('Could not get Kafka Connect plugins configuration!');
          },
        );
    };

    newDataSourceConnectionModel.update = (dataSourceType, isPermitted = true) => {
      const accordance = {
        mongodb: 'isMongoDB',
        hadoop: 'isHadoop',
        'rdb-mysql': 'isMySQLDB',
        'rdb-mssql': 'isMSSQLDB',
        'rdb-postgresql': 'isPostgreSQLDB',
        'rdb-redshift': 'isRedshift',
        'rdb-oracle': 'isOracleDB',
        'rdb-hana': 'isHanaDB',
        'rdb-db2': 'isDb2DB',
        'rdb-db2iseries': 'isDb2DB',
        'rdb-activedirectory': 'isActiveDirectory',
        'rdb-teradata': 'isTeradata',
        nfs: 'isNFS',
        emr: 'isEMR',
        s3: 'isS3',
        hive: 'isHive',
        'hive-jdbc': 'isHiveJdbc',
        hbase: 'isHBase',
        smb: 'isSMB',
        cassandra: 'isCassandra',
        'azure-blob': 'isAzureBlob',
        'azure-file': 'isAzureFile',
        'onedrive-personal': 'isOneDrivePersonal',
        googledrive: 'isGoogleDrive',
        salesforce: 'isSalesforce',
        servicenow: 'isServiceNow',
        'servicenow-structured': 'isServiceNow',
        dynamodb: 'isDynamoDB',
        sap: 'isSAP',
        presto: 'isPresto',
        'generic-rest-api': 'isGenericRestApi',
        'generic-rest-api-unstructured': 'isGenericRestApiUnstructured',
        vsam: 'isVSAM',
        box: 'isBox',
        csv: 'isCsv',
        'gcp-big-query': 'isBigQuery',
        sharepoint: 'isSharePoint',
        snowflake: 'isSnowflake',
        athena: 'isAthena',
        'o365-onedrive': 'isO365OneDrive',
        'o365-outlook': 'isO365Outlook',
        gmail: 'isGmail',
        'rdb-sybase': 'isSybase',
        exchange: 'isExchange',
        slack: 'isSlack',
        netsuite: 'isNetSuite',
        cims: 'isCIMS',
        ldap: 'isLDAP',
        zendesk: 'isZendesk',
        'custom-connector': 'isCustomConnector',
        informix: 'isInformix',
        workday: 'isWorkday',
        couchbase: 'isCouchbase',
        elasticsearch: 'isElasticSearch',
        kafka: 'isKafka',
        kinesis: 'isKinesis',
        kafkaconnect: 'isKafkaConnect',
        'scan-api': 'isKafkaScanApi',
        redis: 'isRedis',
        netezza: 'isNetezza',
        'sharepoint-online': 'isSharePointOnline',
        spanner: 'isSpanner',
        datastore: 'isDataStore',
        'google-bigtable': 'isGoogleBigTable',
        'google-cloud-storage': 'isGoogleCloudStorage',
        yellowbrick: 'isYellowbrick',
        'hadoop-hawq': 'isHadoopHawq',
        documentum: 'isDocumentum',
        jira: 'isJira',
        'microsoft-teams': 'isMsTeams',
        microstrategy: 'isMicroStrategy',
        vertica: 'isVertica',
        databricks: 'isDatabricks',
        'salesforce-marketing-cloud': 'isSalesforceMarketingCloud',
        sparksql: 'isSparkSql',
        cosmosdb: 'isCosmosDB',
        'iterable-dsar': 'isTray',
        gitlab: 'isGitLab',
        github: 'isGitHub',
      };

      if (!isPermitted) {
        newDataSourceConnectionModel.fieldsDDL.selectedTypeOption = null;
        licenseService.openLicenseUpgradeFlow();
        return;
      }

      dataSourceType = dataSourceType.toLowerCase();

      newDataSourceConnectionModel.isFormSubmitted = false;

      if (newDataSourceConnectionModel.fieldsDDL.prevSelectedTypeOptionName !== null) {
        newDataSourceConnectionModel.fields.credential_id = '';
        newDataSourceConnectionModel.fields.is_certificate = undefined;
        newDataSourceConnectionModel.fields.certificate_id = '';
      }

      newDataSourceConnectionModel.fieldsDDL.prevSelectedTypeOptionName = dataSourceType;

      setModelType(accordance[dataSourceType]);

      // FIXME: refactoring required!!!
      if (
        dataSourceType == 'rdb-mysql' ||
        dataSourceType == 'rdb-mssql' ||
        dataSourceType == 'rdb-postgresql' ||
        dataSourceType == 'rdb-redshift' ||
        dataSourceType == 'rdb-oracle' ||
        dataSourceType == 'rdb-hana' ||
        dataSourceType == 'rdb-db2' ||
        dataSourceType == 'rdb-db2iseries' ||
        dataSourceType == 'rdb-activedirectory' ||
        dataSourceType == 'rdb-teradata' ||
        dataSourceType == 'azure-blob' ||
        dataSourceType == 'azure-file' ||
        dataSourceType == 's3' ||
        dataSourceType == 'nfs' ||
        dataSourceType == 'mongodb' ||
        dataSourceType == 'smb' ||
        dataSourceType == 'cassandra' ||
        dataSourceType == 'csv' ||
        dataSourceType == 'hive' ||
        dataSourceType == 'hive-jdbc' ||
        dataSourceType == 'hbase' ||
        dataSourceType == 'dynamodb' ||
        dataSourceType == 'sap' ||
        dataSourceType == 'presto' ||
        dataSourceType == 'generic-rest-api' ||
        dataSourceType == 'generic-rest-api-unstructured' ||
        dataSourceType == 'vsam' ||
        dataSourceType == 'box' ||
        dataSourceType == 'gcp-big-query' ||
        dataSourceType == 'sharepoint' ||
        dataSourceType == 'snowflake' ||
        dataSourceType == 'athena' ||
        dataSourceType == 'o365-outlook' ||
        dataSourceType == 'o365-onedrive' ||
        dataSourceType == 'exchange' ||
        dataSourceType == 'rdb-sybase' ||
        dataSourceType == 'slack' ||
        dataSourceType == 'netsuite' ||
        dataSourceType == 'cims' ||
        dataSourceType == 'ldap' ||
        dataSourceType == 'zendesk' ||
        dataSourceType == 'custom-connector' ||
        dataSourceType == 'informix' ||
        dataSourceType == 'workday' ||
        dataSourceType == 'couchbase' ||
        dataSourceType == 'elasticsearch' ||
        dataSourceType == 'kafka' ||
        dataSourceType == 'kafkaconnect' ||
        dataSourceType == 'scan-api' ||
        dataSourceType == 'kinesis' ||
        dataSourceType == 'redis' ||
        dataSourceType == 'netezza' ||
        dataSourceType == 'sharepoint-online' ||
        dataSourceType == 'spanner' ||
        dataSourceType == 'datastore' ||
        dataSourceType == 'google-bigtable' ||
        dataSourceType == 'yellowbrick' ||
        dataSourceType == 'hadoop-hawq' ||
        dataSourceType == 'documentum' ||
        dataSourceType == 'jira' ||
        dataSourceType == 'microsoft-teams' ||
        dataSourceType == 'vertica' ||
        dataSourceType == 'databricks' ||
        dataSourceType == 'salesforce-marketing-cloud' ||
        dataSourceType == 'microstrategy' ||
        dataSourceType == 'sparksql' ||
        dataSourceType == 'cosmosdb' ||
        dataSourceType == 'gitlab' ||
        dataSourceType == 'github'
      ) {
        newDataSourceConnectionModel.showTestBtn = true;
      } else {
        newDataSourceConnectionModel.showTestBtn = false;
      }

      if (
        dataSourceType == 'googledrive' ||
        dataSourceType == 'gmail' ||
        dataSourceType == 'gcp-big-query' ||
        dataSourceType == 'google-cloud-storage' ||
        dataSourceType == 'google-bigtable'
      ) {
        newDataSourceConnectionModel.fields.isGoogleServiceAccount = true;
        newDataSourceConnectionModel.showTestBtn = true;
      }

      if (dataSourceType == 'azure-blob' || dataSourceType == 'azure-file') {
        newDataSourceConnectionModel.fields.azure_protocol = AZURE_DEFAULT_PROTOCOL;
      }
      if (dataSourceType == 'googledrive') {
        newDataSourceConnectionModel.fields.scan_scope = 'myDrive';
        newDataSourceConnectionModel.fields.is_exclusion_drives_enabled = false;
        newDataSourceConnectionModel.fields.drives_to_scan = '';
      }

      if (dataSourceType == 'salesforce' && newDataSourceConnectionModel.fields.is_certificate) {
        newDataSourceConnectionModel.showTestBtn = true;
      }

      if (isFileScanner(dataSourceType) || dataSourceType == 'ldap') {
        newDataSourceConnectionModel.fields.numberOfParsingThreads = '5';
      }

      if (isSampleScanOnly(dataSourceType)) {
        newDataSourceConnectionModel.fields.rdb_is_sample_data = true;
      } else {
        newDataSourceConnectionModel.fields.rdb_is_sample_data = false;
      }
      if (newDataSourceConnectionModel.isShowScanWindow()) {
        newDataSourceConnectionModel.isSplitPartSupported =
          newDataSourceConnectionModel.supportedSplitPartTypeNames?.includes(dataSourceType);
      }
    };

    function isOauthConnector(connectorType) {
      const oAuthConnectors = [
        'onedrive-personal',
        'googledrive',
        'salesforce',
        'servicenow',
        'servicenow-structured',
        'gmail',
        'gcp-big-query',
        'google-cloud-storage',
        'google-bigtable',
        'iterable-dsar',
      ];
      return oAuthConnectors.includes(connectorType);
    }

    newDataSourceConnectionModel.addCredential = e => {
      newDataSourceConnectionModel.editModalInstance = $uibModal
        .open({
          animation: true,
          template: `<credentials form-only="true"
                          on-form-closed="$ctrl.onFormClosed()"
                          on-form-submitted="$ctrl.onFormSubmitted(isUpdated, credentialName)"></credentials>`,
          controllerAs: '$ctrl',
          controller: [
            '$uibModalInstance',
            function ($uibModalInstance) {
              this.onFormSubmitted = (isUpdated, credentialName) => {
                $uibModalInstance.close({ isUpdated, credentialName });
              };

              this.onFormClosed = () => {
                $uibModalInstance.close();
              };
            },
          ],
          size: 'lg',
          backdrop: 'static',
          keyboard: false,
        })
        .result.then(result => {
          if (typeof result != 'undefined') {
            if (result.isUpdated) getCredentials(result.credentialName);
          }
        });
    };

    newDataSourceConnectionModel.editCredential = (e, credentialId) => {
      if (credentialId == '' || typeof credentialId == 'undefined') return;

      credentialsService.getCredential(credentialId).then(
        result => {
          const credential = result.data;

          newDataSourceConnectionModel.editModalInstance = $uibModal
            .open({
              animation: true,
              template: `<credentials form-only="true" form-data="$ctrl.credential"
                  on-form-closed="$ctrl.onFormClosed()"
                  on-form-submitted="$ctrl.onFormSubmitted(isUpdated, credentialName)"></credentials>`,
              controllerAs: '$ctrl',
              controller: [
                'credential',
                '$uibModalInstance',
                function (classification, $uibModalInstance) {
                  this.credential = credential;

                  this.onFormSubmitted = (isUpdated, credentialName) => {
                    $uibModalInstance.close({ isUpdated, credentialName });
                  };

                  this.onFormClosed = () => {
                    $uibModalInstance.close();
                  };
                },
              ],
              size: 'lg',
              backdrop: 'static',
              keyboard: false,
              resolve: {
                credential: () => {
                  return credential;
                },
              },
            })
            .result.then(result => {
              if (typeof result != 'undefined') {
                if (result.isUpdated) getCredentials(result.credentialName);
              }
            });
        },
        err => {
          notificationService.error('An error has occurred! ' + err.data.message);
        },
      );
    };

    newDataSourceConnectionModel.addCustomField = () => {
      newDataSourceConnectionModel.customConnectorFields = [
        ...newDataSourceConnectionModel.customConnectorFields,
        Object.assign({}, CUSTOM_CONNECTOR_DEFAULT_FIELD),
      ];
    };

    newDataSourceConnectionModel.removeCustomField = index => {
      newDataSourceConnectionModel.customConnectorFields = [
        ...newDataSourceConnectionModel.customConnectorFields.slice(0, index),
        ...newDataSourceConnectionModel.customConnectorFields.slice(index + 1),
      ];
    };

    newDataSourceConnectionModel.onCustomFieldTypeChange = index => {
      newDataSourceConnectionModel.customConnectorFields[index]['input_type'] =
        CUSTOM_CONNECTOR_FIELD_TYPE_MAPPING[newDataSourceConnectionModel.customConnectorFields[index]['field_type']];

      newDataSourceConnectionModel.onCustomFieldValueChange(index);
    };

    newDataSourceConnectionModel.onCustomFieldValueChange = index => {
      if (
        newDataSourceConnectionModel.customConnectorFields[index]['field_type'] === CUSTOM_CONNECTOR_FIELD_PASSWORD_TYPE
      ) {
        newDataSourceConnectionModel.customConnectorFields[index]['is_changed'] = true;
      }
    };

    const getXLastDaysValue = value => {
      const xLastDays = typeof value === 'number' ? value : newDataSourceConnectionModel.DEFAULT_X_DAYS;
      return Math.min(
        newDataSourceConnectionModel.MAX_X_DAYS,
        Math.max(newDataSourceConnectionModel.MIN_X_DAYS, xLastDays),
      );
    };

    this.xLastDaysOnChange = () => {
      newDataSourceConnectionModel.fields.is_modified_in_x_days = true;
    };

    this.ngBlurXDays = () => {
      if (!this.isValidXDays(newDataSourceConnectionModel.fields.x_last_days)) {
        newDataSourceConnectionModel.fields.x_last_days = newDataSourceConnectionModel.DEFAULT_X_DAYS;
      }
    };

    this.isValidXDays = () => {
      const value = newDataSourceConnectionModel.fields.x_last_days;
      return (
        typeof value === 'number' &&
        value <= newDataSourceConnectionModel.MAX_X_DAYS &&
        value >= newDataSourceConnectionModel.MIN_X_DAYS
      );
    };

    this.getCredentialTitle = credential => getCredentialTitle(credential);

    function getLastScanDate(lastScanDate) {
      // check that it is meaningful timestamp before converting to date
      return typeof lastScanDate === 'number' && lastScanDate > 0
        ? $filter('date')(new Date(lastScanDate), NG_FILTER_DATE_FORMAT)
        : '';
    }

    function buildScanWindowModel(scanWindows) {
      return scanWindows.map(sw => getScanWindowModel(sw));
    }

    const getScanWindowModel = name => {
      return {
        id: uuid(),
        name,
      };
    };
  },
});
