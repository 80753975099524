import React, { FC, Fragment, SetStateAction, Dispatch } from 'react';
import classnames from 'classnames';
import { BigidColors, BigidIcon, BigidIconSourceType, BigidTooltip } from '@bigid-ui/components';
import makeStyles from '@mui/styles/makeStyles';
import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import Collapse from '@mui/material/Collapse';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { SidebarMenuGroup } from './Sidebar';
import { $state } from '../../services/angularServices';
import { CustomAppIcon } from '../../views/ApplicationsManagement/Components/CustomAppIcon';

export interface SidebarSectionProps {
  group: SidebarMenuGroup;
  divider: boolean;
  selectedMenuItem: string;
  openMenuItem: string;
  setOpenMenuItem: Dispatch<SetStateAction<string>>;
  isMenuOpen: boolean;
  onItemBlur?: (isLastItem: boolean) => void;
  onItemClick?: () => void;
}

const useStyles = makeStyles({
  divider: {
    background: BigidColors.navDivider,
  },
  itemWrapper: {
    paddingTop: '8px',
    paddingBottom: '8px',
    cursor: 'pointer',
  },
  listItemIcon: {
    display: 'flex',
    minWidth: 'unset',
    paddingRight: 22,
    paddingLeft: 2,
    fontSize: 20,
  },
  listItemText: {
    fontSize: '0.875rem',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    outline: 'unset',
    border: 'unset',
  },
  innerMenuItemText: {
    paddingLeft: 44,
  },
  listItem: {
    height: 40,
    '&:hover,&:focus,&:active': {
      background: BigidColors.gray[700],
    },
  },
  listItemSelected: {
    background: BigidColors.purple[500],
    '&:hover': {
      background: BigidColors.purple[500],
    },
  },
  listItemGutters: {
    paddingLeft: 10,
  },
  innerListItemSelected: {
    background: BigidColors.purple[500],
    '&:hover': {
      background: BigidColors.purple[500],
    },
  },
  listItemAvatar: {
    minWidth: 'unset',
    paddingRight: 12,
    fontSize: 20,
  },
  avatar: {
    width: '35px !important',
    height: '35px !important',
    background: BigidColors.purple[500],
    fontSize: 'unset',
  },
});

export const SidebarSection: FC<SidebarSectionProps> = ({
  group,
  divider,
  selectedMenuItem,
  openMenuItem,
  setOpenMenuItem,
  isMenuOpen,
  onItemBlur,
  onItemClick,
}) => {
  const classes = useStyles({});
  const handleItemClick = (title: string, link: string, onClick: () => void) => {
    onClick && onClick();
    onItemClick?.();
    link && $state.go(link);
  };
  const MAX_TITLE_CHARS = 23;

  return (
    <Fragment>
      <div className={classes.itemWrapper} role="presentation" tabIndex={-1}>
        {group.menuItems.map(({ icon, logo, title, link, onClick, innerMenuItems, selectedStates = [] }, i) => {
          const isLastItem = i + 1 == group.menuItems.length;
          const isAccordionItem = !!innerMenuItems;
          const isOpen = title === openMenuItem;

          const isSelected = Boolean(
            isAccordionItem
              ? innerMenuItems.find(
                  innerMenuItem =>
                    innerMenuItem.selectedStates && innerMenuItem.selectedStates.includes(selectedMenuItem),
                )
              : selectedStates.includes(selectedMenuItem),
          );
          const shouldHighlightRow = isSelected && isMenuOpen && !isAccordionItem;
          const shouldDisplayAvatar = isSelected && (!isMenuOpen || (isMenuOpen && isAccordionItem));
          const dataAidOuterTitle = title.replace(/\s/g, '-').toLowerCase();

          const handleClick = () => {
            setOpenMenuItem(isOpen ? null : title);
            if (!isAccordionItem) {
              handleItemClick(title, link, onClick);
            }
          };

          return (
            <Fragment key={i}>
              <ListItem
                dense
                button
                tabIndex={0}
                classes={{
                  root: classnames(classes.listItem, shouldHighlightRow && classes.listItemSelected),
                  gutters: shouldDisplayAvatar && classes.listItemGutters,
                }}
                onClick={handleClick}
                onBlur={() => onItemBlur?.(isLastItem)}
                data-aid={`sidebar-${dataAidOuterTitle}`}
                {...(isAccordionItem && {
                  'aria-expanded': isOpen,
                })}
                aria-label={title}
              >
                {shouldDisplayAvatar ? (
                  <ListItemAvatar classes={{ root: classes.listItemAvatar }}>
                    <Avatar classes={{ root: classes.avatar }}>
                      {logo ? (
                        <CustomAppIcon logo={logo} isSidebar />
                      ) : (
                        <BigidIcon icon={icon} color={BigidColors.white} type={BigidIconSourceType.CUSTOM} />
                      )}
                    </Avatar>
                  </ListItemAvatar>
                ) : (
                  <div>
                    <ListItemIcon classes={{ root: classes.listItemIcon }}>
                      {logo ? (
                        <CustomAppIcon logo={logo} isSidebar={true}></CustomAppIcon>
                      ) : (
                        <BigidIcon icon={icon} color={BigidColors.gray[100]} type={BigidIconSourceType.CUSTOM} />
                      )}
                    </ListItemIcon>
                  </div>
                )}
                <BigidTooltip isDisabled={title.length <= MAX_TITLE_CHARS} title={title}>
                  <ListItemText
                    role="presentation"
                    tabIndex={-1}
                    primary={title}
                    classes={{ root: classes.listItemText }}
                    disableTypography
                  />
                </BigidTooltip>

                {isAccordionItem && (isOpen ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />)}
              </ListItem>

              {isAccordionItem && (
                <Collapse in={isOpen} timeout="auto" unmountOnExit>
                  <List component="div" disablePadding>
                    {innerMenuItems.map(({ title, link, onClick, selectedStates = [] }, key) => {
                      const shouldShowInnerSelected = selectedStates.includes(selectedMenuItem);
                      const dataAidInner = title.replace(/\s/g, '-').toLowerCase();

                      return (
                        <ListItem
                          button
                          tabIndex={0}
                          dense
                          key={key}
                          onClick={() => handleItemClick(title, link, onClick)}
                          classes={{
                            root: classnames(
                              classes.listItem,
                              shouldShowInnerSelected && classes.innerListItemSelected,
                            ),
                          }}
                          data-aid={`sidebar-${dataAidInner}`}
                        >
                          <ListItemText
                            primary={title}
                            classes={{
                              root: classnames(classes.listItemText, classes.innerMenuItemText),
                            }}
                            disableTypography
                          />
                        </ListItem>
                      );
                    })}
                  </List>
                </Collapse>
              )}
            </Fragment>
          );
        })}
      </div>

      {divider && <Divider aria-hidden={true} classes={{ root: classes.divider }} />}
    </Fragment>
  );
};
