import { QueryCache, QueryClient, QueryClientConfig } from 'react-query';

const queryCache = new QueryCache();

const DEFAULT_QUERY_CLIENT_CONFIG: QueryClientConfig = {
  queryCache: queryCache,
  defaultOptions: {
    queries: {
      cacheTime: 0,
      staleTime: Infinity,
      retry: false,
      refetchOnReconnect: false,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      refetchInterval: false,
    },
  },
};

const queryClient = new QueryClient(DEFAULT_QUERY_CLIENT_CONFIG);

export const getQueryClient = () => queryClient;

export const getQueryCache = () => queryCache;
