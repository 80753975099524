import React, { FC, useCallback } from 'react';
import { styled } from '@mui/material';
import { generateDataAid } from '@bigid-ui/utils';
import { AutoDiscoveryConfigModel } from './AutoDiscoveryGridConfiguration';
import { TYPE_TO_ICON } from '../constants';
import { $state } from '../../../services/angularServices';
import { CONFIG } from '../../../../config/common';
import { BigidLink } from '@bigid-ui/components';

interface AutoDiscoveryConfigNameCellProps {
  dataAid?: string;
  row: AutoDiscoveryConfigModel;
}

const Root = styled('div')`
  width: 100%;
  height: 100%;
`;

const CellContent = styled('div')`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
`;

const IconContainer = styled('div')`
  margin-right: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const DiscoveryConfigName = styled(BigidLink)`
  flex: 1;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const AutoDiscoveryConfigNameCell: FC<AutoDiscoveryConfigNameCellProps> = ({
  dataAid = 'AutoDiscoveryConfigNameCell',
  row: { name, type, _id },
}) => {
  const Icon = TYPE_TO_ICON[type];
  const onClick = useCallback(() => {
    const params = {
      type,
      id: _id,
    };
    $state.go(CONFIG.states.AUTO_DISCOVERY_PRESET_CONFIG, params);
  }, [_id, type]);
  return (
    <Root data-aid={generateDataAid(dataAid, ['discoveryConfigNameCell'])}>
      <CellContent>
        <IconContainer>
          <Icon size={'small'} />
        </IconContainer>
        <DiscoveryConfigName
          text={name}
          onClick={onClick}
          dataAid={generateDataAid(`LinkFormatter`, [])}
          underline={'always'}
        />
      </CellContent>
    </Root>
  );
};
