import React from 'react';
import styled from '@emotion/styled';
import type { Theme } from '@mui/material';

type ComponentStyleProps = { theme: Theme };

export const DataSourceSchedulerContainer = styled.div`
  label,
  label p {
    font-size: ${({ theme }: ComponentStyleProps) => `${theme.typography.h6.fontSize} !important`};
    font-weight: ${({ theme }: ComponentStyleProps) => `${theme.typography.h6.fontWeight}`} !important;
  }
`;

export const DataSourceSchedulerLabel = styled.div`
  margin-bottom: 1rem;
`;

export const DataSourceSchedulerField = styled.div`
  padding: 24px 0 18px 16px;
  font-size: ${({ theme }: ComponentStyleProps) => `${theme.typography.h6.fontSize}`};
  font-weight: ${({ theme }: ComponentStyleProps) => `${theme.typography.h6.fontWeight}`};
`;
