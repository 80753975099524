import React, { FC, useState } from 'react';
import { updateFavoritesPreference } from './scanTemplatesService';
import { BigidStarFilledIcon, BigidStarIcon } from '@bigid-ui/icons';
import { generateDataAid, generateGuidedTourId } from '@bigid-ui/utils';
import { BigidButtonIcon } from '@bigid-ui/components';

export interface ScanTemplateFavoritesIconProps {
  isFavorite: boolean;
  _id: string;
  name: string;
}

export const ScanTemplateFavoritesIcon: FC<ScanTemplateFavoritesIconProps> = ({ isFavorite, _id, name }) => {
  const [isFavoriteTemplate, setIsFavoriteTemplate] = useState(isFavorite);

  const updateTemplatePreference = async (event?: React.MouseEvent<Element, MouseEvent>) => {
    event.stopPropagation();
    const isFavorited = await updateFavoritesPreference(_id, name);
    setIsFavoriteTemplate(isFavorited);
  };
  return (
    <BigidButtonIcon
      icon={isFavoriteTemplate ? BigidStarFilledIcon : BigidStarIcon}
      dataAid={generateDataAid(isFavoriteTemplate ? 'RemoveTemplateFavorite' : 'AddTemplateFavorite', [name])}
      onClick={updateTemplatePreference}
      data-tour-id={generateGuidedTourId('ScanTemplateFavoritesIcon', [name])}
      size="small"
    />
  );
};
