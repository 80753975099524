import { BigidHeading6 } from '@bigid-ui/components';
import { generateDataAid } from '@bigid-ui/utils';
import styled from '@emotion/styled';
import React, { FC, useState } from 'react';
import { getRecentlyViewedCatalogItems } from '../../DataCatalog/recentlyViewedCatalogItemService';
import { DEFAULT_NUMBER_OF_ITEMS_TO_DISPLAY } from '../constants';
import { useLocalTranslation } from '../translations';
import { ListEntityItem } from '../types';
import { mapRecentlyViewedToListItem } from '../dataExplorerService';
import { EntityList } from './components/EntityList';
import { NoData } from './components/NoData';
import { BaseFooterSectionContainer } from './components/shared';
import { useQuery } from 'react-query';

type RecentlyViewedProps = {
  dataAid?: string;
};

const Container = styled(BaseFooterSectionContainer)`
  ${({ theme }) => theme.breakpoints.only('lg')} {
    width: 340px;
  }
  ${({ theme }) => theme.breakpoints.down('lg')} {
    width: 260px;
  }
`;

export const RecentlyViewed: FC<RecentlyViewedProps> = ({ dataAid = 'RecentlyViewed' }) => {
  const [recentlyViewed, setRecentlyViewed] = useState<ListEntityItem[]>([]);
  const { t } = useLocalTranslation('RecentlyViewed');

  const { isLoading } = useQuery('recentlyViewed', getRecentlyViewedCatalogItems, {
    onSuccess: response => {
      const items = response.data?.recentlyViewedItems || [];
      const mappedToListItem = mapRecentlyViewedToListItem(items.slice(0, DEFAULT_NUMBER_OF_ITEMS_TO_DISPLAY));
      setRecentlyViewed(mappedToListItem);
    },
    onError: () => {
      setRecentlyViewed([]);
    },
  });

  const isEmptyState = recentlyViewed.length === 0;

  return (
    <Container data-aid={generateDataAid(dataAid, ['recently-viewed', 'container'])}>
      <BigidHeading6 textAlign={isEmptyState ? 'center' : 'start'}>{t('title')}</BigidHeading6>
      <EntityList
        noDataComponent={
          <NoData dataAid={generateDataAid(dataAid, ['no-data'])} description={t('noDataDescription')} />
        }
        isLoaderShown={isLoading}
        entities={recentlyViewed}
        dataAid={generateDataAid(dataAid, ['list'])}
      />
    </Container>
  );
};
