import React, { FunctionComponent, ReactNode, useEffect, useState } from 'react';
import {
  BigidColors,
  BigidIcon,
  BigidIconSize,
  objectToQueryString,
  BigidChip,
  BigidLink,
  QueryParams,
} from '@bigid-ui/components';
import {
  BigidGridColumn,
  BigidGridColumnTypes,
  BigidGridWithToolbar,
  BigidGridQueryComponents,
  BigidGridWithToolbarProps,
  ChipFormatterProps,
  ChipsFormatterProps,
} from '@bigid-ui/grid';
import { Cluster, clusteringService } from './clusteringService';
import ErrorIcon from '@mui/icons-material/Error';
import { downloadFile } from '../../services/downloadFile';
import { $state, $stateParams } from '../../services/angularServices';
import { getDataCatalogRecords, DataCatalogRecord } from '../DataCatalog/DataCatalogService';
import { isPermitted } from '../../services/userPermissionsService';
import { CLUSTER_ANALYSIS_PERMISSIONS } from '@bigid/permissions';

const YES = 'yes';
const NO = 'no';

const CONTAINS_PI = 'Contains PI';
const TOTAL_DISPLAYED_FILES = 50;

const OPEN_ACCESS_CHIP_TYPE = 'open_access';
const OPEN_ACCESS = 'Open Access';
const NO_OPEN_ACCESS = 'No Open Access';

const HAS_DUPLICATES_CHIP_TYPE = 'has_duplicates';
const HAS_DUPLICATES = 'Has Duplicates';
const NO_DUPLICATES = 'No Duplicates';

interface DataCatalogRecordExtended extends Omit<DataCatalogRecord, 'tags'> {
  tags: ReactNode[];
  attributes: ChipsFormatterProps;
}
export interface ObjectGridRow extends DataCatalogRecordExtended {
  index: number;
  skip: number;
}

const hasIndicatorMapping = new Map([
  [
    OPEN_ACCESS_CHIP_TYPE,
    new Map<string, ChipFormatterProps>([
      [
        YES,
        {
          chip: {
            icon: <BigidIcon color={BigidColors.red[700]} icon={ErrorIcon} size={BigidIconSize.MEDIUM} />,
            bgColor: BigidColors.red[100],
            size: 'small',
            label: OPEN_ACCESS,
          },
        },
      ],
      [
        NO,
        {
          chip: {
            bgColor: BigidColors.gray[50],
            size: 'small',
            label: NO_OPEN_ACCESS,
          },
        },
      ],
    ]),
  ],
  [
    HAS_DUPLICATES_CHIP_TYPE,
    new Map<string, ChipFormatterProps>([
      [
        YES,
        {
          chip: {
            bgColor: BigidColors.red[100],
            size: 'small',
            label: HAS_DUPLICATES,
          },
        },
      ],
      [
        NO,
        {
          chip: {
            bgColor: BigidColors.gray[50],
            size: 'small',
            label: NO_DUPLICATES,
          },
        },
      ],
    ]),
  ],
]);

const getNormalizedObjectIndex = (index: number, skip: number) => {
  if (index > 49) {
    return { index: index - 50, skip: skip + 50 };
  }
  return { index, skip };
};

const previewFile = (basicFileInfo: ObjectGridRow) => {
  const { id: objectId, skip, index, cluster_id: clusterId } = basicFileInfo;
  const { index: currIndex, skip: currSkip } = getNormalizedObjectIndex(index, skip);

  $state.go('clusterObjectsPreview', {
    groupId: clusterId,
    objectId,
    skip: currSkip,
    index: currIndex + 1,
    totalObjects: TOTAL_DISPLAYED_FILES,
    fileOrigin: 'objects',
  });
};

const columns: BigidGridColumn<ObjectGridRow>[] = [
  {
    name: 'objectName',
    title: 'Object name',
    type: BigidGridColumnTypes.TEXT,
    getCellValue: file => {
      return (<BigidLink text={file.objectName} onClick={() => previewFile(file)} />) as ReactNode;
    },
  },
  {
    name: 'dataSourceName',
    title: 'Data source name',
    type: BigidGridColumnTypes.TEXT,
    getCellValue: ({ source }) => source,
  },
  {
    name: 'type',
    title: 'Data source type',
    getCellValue: ({ type }) => type,
    type: BigidGridColumnTypes.TEXT,
  },
  {
    name: 'attributes',
    title: 'Attributes',
    getCellValue: ({ attributes }) => attributes,
    type: BigidGridColumnTypes.CHIPS,
  },
  {
    name: 'open_access',
    title: 'Open access',
    type: BigidGridColumnTypes.CHIP,
    getCellValue: ({ open_access }) => hasIndicatorMapping.get(OPEN_ACCESS_CHIP_TYPE).get(open_access.toLowerCase()),
  },
  {
    name: 'objectType',
    title: 'Object type',
    getCellValue: ({ objectType }) => objectType,
    isHiddenByDefault: true,
    type: BigidGridColumnTypes.TEXT,
  },
  {
    name: 'fullObjectName',
    title: 'Full object name',
    getCellValue: ({ fullObjectName }) => fullObjectName,
    isHiddenByDefault: true,
    type: BigidGridColumnTypes.TEXT,
  },
  {
    name: 'has_duplicates',
    title: 'Has duplicates',
    type: BigidGridColumnTypes.CHIP,
    getCellValue: ({ has_duplicates }) =>
      hasIndicatorMapping.get(HAS_DUPLICATES_CHIP_TYPE).get(has_duplicates.toLowerCase()),
  },
];

export const ClusterObjects: FunctionComponent<Cluster> = ({ id }: Cluster) => {
  const [externalFilter, setExternalFilter] = useState([]);
  const [clusterId, setClusterId] = useState<string>();

  // fixed implicit any during react 18 migration
  const gridWithToolbarConfig: BigidGridWithToolbarProps<ObjectGridRow & any> = {
    externalFilter,
    entityName: 'objects',
    showSortingControls: false,
    fetchData: async (queryComponents?: BigidGridQueryComponents) => {
      const filter = `cluster_id = ${clusterId}`;
      const query = objectToQueryString({
        ...(queryComponents as QueryParams),
        filter,
      });

      let catalogQueryResult;

      if (query.length > 0) {
        catalogQueryResult = await getDataCatalogRecords(`&${query}`);
      } else {
        catalogQueryResult = await getDataCatalogRecords();
      }

      const { results, estimatedCount, total } = catalogQueryResult;

      return {
        totalCount: total || estimatedCount,
        data: results.map((object, index) => {
          const { open_access = NO, total_pii_count = 0, attribute } = object;
          const tags = [];

          if (open_access.toLowerCase() === YES) {
            tags.push(hasIndicatorMapping.get(YES));
          }

          if (total_pii_count > 0) {
            tags.push(<BigidChip bgColor={BigidColors.red[100]} size="small" label={CONTAINS_PI} />);
          }

          const attributes: ChipsFormatterProps = {
            chips: {
              value: attribute.map(attr => ({
                label: attr,
              })),
              isDisabled: true,
              isAutoFit: false,
            },
          };
          const { skip } = queryComponents;
          return {
            ...object,
            index,
            skip,
            tags,
            attributes,
          };
        }),
      };
    },
    columns,
    ...(isPermitted(CLUSTER_ANALYSIS_PERMISSIONS.EXPORT_OBJECTS_DATA.name) && {
      toolbarActions: [
        {
          label: 'Export',
          isGlobal: true,
          execute: async () => {
            try {
              downloadFile(
                await clusteringService.downloadReport(clusterId),
                'attachment/octet-stream',
                `cluster-${new Date().toISOString()}.csv`,
              );
            } catch (err) {
              window.console.error('An error has occurred');
            } finally {
              return Promise.resolve({ shouldGridReload: false });
            }
          },
          show: () => true,
        },
      ],
    }),
  };

  useEffect(() => {
    setExternalFilter([]);
    setClusterId(id);
  }, [id]);

  useEffect(() => {
    const { clusterId } = $stateParams;
    if (clusterId) {
      setExternalFilter([]);
      setClusterId(clusterId);
    }
  }, []);

  return <BigidGridWithToolbar {...gridWithToolbarConfig} />;
};
