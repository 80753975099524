export const BigidChipColors = {
  failed: {
    color: '#C42727',
    bgColor: '#FFF1F0',
  },
  success: {
    color: '#07853C',
    bgColor: '#F0FFF6',
  },
} as const;
