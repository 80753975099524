import { SaveDialogResult, showSaveDialog, showSaveDialogV2 } from '../../../../services/saveDialogService';
import { useCallback, useRef } from 'react';

export interface OnCloseDataSourceConfigOptions {
  onSave: () => void;
  onNotSave: () => void;
  onClose: () => void;
  isError: boolean;
}

export const useOnCloseHandler = (entityName = 'Data Source', isV2Dialog = false) => {
  const isModalOpened = useRef(false);

  return useCallback(
    ({ onSave, onNotSave, onClose, isError }: OnCloseDataSourceConfigOptions) => {
      if (isModalOpened.current) return;

      isModalOpened.current = true;
      (isV2Dialog ? showSaveDialogV2 : showSaveDialog)({
        entityName,
        isError,
        onClose: () => {
          onClose();
          isModalOpened.current = false;
        },
      })
        .then(save => {
          if (save === SaveDialogResult.cancel) return;

          if (save === SaveDialogResult.save) {
            onSave();
          } else {
            onNotSave();
          }
        })
        .finally(() => {
          isModalOpened.current = false;
        });
    },
    [entityName],
  );
};
