import React, { MutableRefObject, useCallback, useMemo, useState } from 'react';
import { BigidForm, BigidFormField, BigidFormRenderProps, BigidFormValues, TertiaryButton } from '@bigid-ui/components';
import { BigidEditIcon } from '@bigid-ui/icons';
import { generateDataAid } from '@bigid-ui/utils';
import { openClassifierChecksumValidationDialog } from '../../../components/ClassifiersChecksumValidation/ClassifiersChecksumValidationDialog/ClassifiersChecksumValidationDialog';
import { OOTBChecksumValidation } from '../../../components/ClassifiersChecksumValidation/ClassifiersChecksumValidationDialog/ChecksumValidationTypes';
import { getClassifierChecksumValidationByName } from '../services/classifiersManagementService';
import { SupportTermRegexForm } from './components/SupportTermRegexForm';
import { getFieldsForSubmit, getInitialData, initialFormValues } from './initialFormConfig';
import { CLASSIFIER_TYPES, RegexFormFields, ClassifierGridRow } from '../types/ClassifierTypes';
import {
  CheckboxContainer,
  StyledFlexWrapper,
  StyledMandatoryMark,
  ClassifierFormHolder,
  StyledClassifierRegex,
  StyledChecksumValidation,
  ClassifierTypeDescription,
  ClassifierTypeRadioButtonsBox,
} from './components/ClassifiersFormStyledComponents';
import {
  isFieldExist,
  getChecksumValueType,
  getMatchedInitialState,
  getDefaultClassifierType,
  getDefaultIsSupportTermFormVisible,
  getDefaultIsNegativeSupportTermFormVisible,
} from './formUtils';
import { useLocalTranslation } from '../translations';
import {
  getIsClassifyFileNamesEnabled,
  isMetadataClassifierEnabled,
  showNegativeSupporTermFields,
} from '../utils/utils';
import { AdvancedOptions } from './components/AdvancedOptions';

interface RenderFormProps extends BigidFormRenderProps {
  fields: BigidFormField[];
  classifierType: CLASSIFIER_TYPES;
  checksumValidations: OOTBChecksumValidation[];
  isOOTB: boolean;
}

const RenderForm = ({ renderField, getValues, checksumValidations, setValue, fields, isOOTB }: RenderFormProps) => {
  const { t } = useLocalTranslation('form');
  const {
    classification_name,
    validation,
    classifierType,
    isSupportTermFormVisible,
    isNegativeSupportTermFormVisible,
    report_as_pii_finding,
  } = getValues();

  const isTypeValuesOpened =
    isFieldExist(fields, RegexFormFields.minLength) &&
    (classifierType === CLASSIFIER_TYPES.CONTENT || classifierType === CLASSIFIER_TYPES.CONTENT_AND_METADATA);

  const onOpenClassifierChecksumValidationDialog = async () => {
    let classifierChecksumValidation = null;
    if (validation) {
      const classifierChecksumValidationResponse = await getClassifierChecksumValidationByName(validation);
      classifierChecksumValidation = classifierChecksumValidationResponse?.data?.data?.[0];
    }

    const initialCheckSumType = getChecksumValueType(classifierChecksumValidation);
    const { checksumName, isCancelOccurred } = await openClassifierChecksumValidationDialog(
      classification_name,
      classifierChecksumValidation,
      checksumValidations,
      initialCheckSumType,
    );

    !isCancelOccurred && setValue(RegexFormFields.validation)(checksumName);
  };

  const getClassifierTypeDescription = (type: CLASSIFIER_TYPES) => {
    switch (type) {
      case CLASSIFIER_TYPES.CONTENT:
        return t('tooltips.dataTypeDescription');
      case CLASSIFIER_TYPES.METADATA:
        return t('tooltips.metadataTypeDescription');
      case CLASSIFIER_TYPES.CONTENT_AND_METADATA:
        return t('tooltips.dataAndMetaTypeDescription');
      default:
        return '';
    }
  };

  return (
    <ClassifierFormHolder>
      {renderField(RegexFormFields.classifierName)}
      {renderField('attribute')}
      {renderField(RegexFormFields.categories)}

      {renderField(RegexFormFields.description)}

      <StyledClassifierRegex>
        <h5>
          {t('classifierTitle')}
          <StyledMandatoryMark>*</StyledMandatoryMark>
        </h5>
        {isMetadataClassifierEnabled() && (
          <>
            <ClassifierTypeRadioButtonsBox>{renderField(RegexFormFields.classifierType)}</ClassifierTypeRadioButtonsBox>
            <ClassifierTypeDescription size="medium">
              {getClassifierTypeDescription(classifierType)}
            </ClassifierTypeDescription>
          </>
        )}

        {renderField(RegexFormFields.classificationRegex)}

        {isTypeValuesOpened && (
          <StyledFlexWrapper>
            {renderField(RegexFormFields.minLength)}
            {renderField(RegexFormFields.maxLength)}
          </StyledFlexWrapper>
        )}
      </StyledClassifierRegex>

      {isTypeValuesOpened && (
        <>
          <StyledChecksumValidation>
            <h5>{t('checksumValidationTitle')}</h5>
            <TertiaryButton
              size="medium"
              disabled={isOOTB}
              startIcon={<BigidEditIcon />}
              text={validation ? validation : t('none')}
              onClick={onOpenClassifierChecksumValidationDialog}
              dataAid={generateDataAid('checksumValidationButton', [])}
            />
          </StyledChecksumValidation>

          <StyledClassifierRegex>
            <CheckboxContainer>{renderField('isSupportTermFormVisible')}</CheckboxContainer>

            {isSupportTermFormVisible && isFieldExist(fields, RegexFormFields.supportTerm) && (
              <SupportTermRegexForm
                fieldNameBefore={RegexFormFields.proximityBefore}
                fieldNameAfter={RegexFormFields.proximityAfter}
                textAreaName={RegexFormFields.supportTerm}
                renderField={renderField}
              />
            )}
          </StyledClassifierRegex>

          {showNegativeSupporTermFields() && (
            <StyledClassifierRegex>
              <CheckboxContainer>{renderField('isNegativeSupportTermFormVisible')}</CheckboxContainer>

              {isNegativeSupportTermFormVisible && isFieldExist(fields, 'nst_proximity_before') && (
                <SupportTermRegexForm
                  fieldNameBefore={RegexFormFields.nstProximityBefore}
                  fieldNameAfter={RegexFormFields.nstProximityAfter}
                  textAreaName={RegexFormFields.negativeSupportTerm}
                  renderField={renderField}
                />
              )}
            </StyledClassifierRegex>
          )}

          {!isOOTB && <AdvancedOptions renderField={renderField} isPiiFindingSelected={report_as_pii_finding} />}
        </>
      )}
    </ClassifierFormHolder>
  );
};

interface RegexClassifierFormProps {
  checksumValidations: OOTBChecksumValidation[];
  formControlsRef: MutableRefObject<any>;
  initialData?: ClassifierGridRow;
  isEdit?: boolean;
  isOOTB?: boolean;
}

const RegexClassifierForm = ({
  isEdit,
  isOOTB,
  initialData,
  formControlsRef,
  checksumValidations,
}: RegexClassifierFormProps) => {
  const transformedInitialData = {
    ...initialData,
    attribute: {
      friendly_name: initialData?.friendly_name || '',
      glossary_id: initialData?.glossary_id || '',
    },
  };
  const isClassifyFileNamesEnabled = getIsClassifyFileNamesEnabled();
  const [classifierType, setClassifierType] = useState(getDefaultClassifierType(initialData));
  const data = useMemo(
    () =>
      getInitialData({
        classifierType,
        isSupportTermFormVisible: getDefaultIsSupportTermFormVisible(initialData),
        isNegativeSupportTermFormVisible: getDefaultIsNegativeSupportTermFormVisible(initialData),
        formData: transformedInitialData,
        isEdit,
        isOOTB,
        isClassifyFileNamesEnabled,
      }),
    [isEdit, isOOTB, initialData],
  );
  const [{ initialValues, fields }, setFormData] = useState(data);

  const onChange = (
    formValues: BigidFormValues,
    { setFieldValue }: { setFieldValue: (fieldname: string, value: any) => void },
  ) => {
    const { classifierType, isSupportTermFormVisible, isNegativeSupportTermFormVisible, report_as_pii_finding } =
      formValues;

    if (
      classifierType !== initialValues?.classifierType ||
      isSupportTermFormVisible !== initialValues.isSupportTermFormVisible ||
      isNegativeSupportTermFormVisible !== initialValues.isNegativeSupportTermFormVisible ||
      report_as_pii_finding !== initialValues.report_as_pii_finding
    ) {
      setClassifierType(classifierType);

      const newFormData = getInitialData({
        classifierType,
        isSupportTermFormVisible,
        isNegativeSupportTermFormVisible,
        formData: formValues,
        isEdit,
        isOOTB,
        isClassifyFileNamesEnabled,
      });

      setFormData(newFormData);

      if (!isSupportTermFormVisible) {
        setFieldValue('support_term_value', '');
        setFieldValue('proximity_after', '');
        setFieldValue('proximity_before', '');
      }

      if (!isNegativeSupportTermFormVisible) {
        setFieldValue('negative_support_term_value', '');
        setFieldValue('nst_proximity_after', '');
        setFieldValue('nst_proximity_before', '');
      }

      if (!report_as_pii_finding) {
        setFieldValue('ignored', false);
      }
    }
  };

  // to avoid additional calls for attributes and categories when component rerender
  const memoizedRenderForm = useCallback(
    (props: BigidFormRenderProps) => (
      <RenderForm
        {...props}
        classifierType={classifierType}
        checksumValidations={checksumValidations}
        fields={fields}
        isOOTB={isOOTB}
      />
    ),
    [classifierType, initialValues.isSupportTermFormVisible, initialValues.isNegativeSupportTermFormVisible],
  );

  return (
    <>
      <BigidForm
        initialValues={initialValues}
        fields={fields}
        key={classifierType}
        renderForm={memoizedRenderForm}
        onChange={onChange}
        controlButtons={false}
        stateAndHandlersRef={formControlsRef}
      />
    </>
  );
};

const getRegexFormSubmitFields = (values: BigidFormValues): Partial<ClassifierGridRow> => {
  const { classifierType } = values;

  if (classifierType !== CLASSIFIER_TYPES.METADATA) {
    const fieldsForSubmit = getFieldsForSubmit();

    return getMatchedInitialState(fieldsForSubmit, values);
  }

  return getMatchedInitialState(initialFormValues, values);
};

export { getRegexFormSubmitFields, RegexClassifierForm };
