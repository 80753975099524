import './rangeSlider.component.scss';
import template from './rangeSlider.component.html';
import { module } from 'angular';
const app = module('app');

app.component('rangeSlider', {
  template,
  controllerAs: '$ctrl',
  controller: function ($timeout) {
    'ngInject';

    const LOW = 'low';
    const MEDIUM = 'medium';
    const HIGH = 'high';
    const MIN = 'min';
    const MAX = 'max';
    const LOW_RANGE_INCREASED_MSG = 'You increase sensitivity at the cost of getting more false result';
    const LOW_RANGE_DECREASED_MSG = 'You increase the chances to miss personal information';

    const SLIDER_CONFIG = {
      step: 0.05,
      options: {
        minLimit: 0,
        maxLimit: 1,
        step: 0.05,
        ceil: 1,
        floor: 0,
        precision: 2,
        enforceStep: false,
        enforceRange: false,
        showTicks: false,
        disabled: false,
        showTicksValues: false,
        onlyBindHandles: true,
        hidePointerLabels: true,
        onChange: (sliderId, modelValue, highValue, pointerType) => {
          this.onSliderValueChange(sliderId, modelValue, highValue, pointerType);
        },
      },
    };

    const validateRangeInput = (value, rangeName, type) => {
      const regExp = /^[0-9.,]+$/;
      const parsedValue = parseFloat(value);

      if (!regExp.test(value)) {
        return false;
      }

      if (parsedValue > this.slider.options.maxLimit || parsedValue < this.slider.options.miLimit) {
        return false;
      }

      switch (true) {
        case rangeName === LOW && type === MAX:
          if (parsedValue > this.ranges.medium.maxValue) {
            return false;
          }
          break;
        case rangeName === MEDIUM && type === MIN:
          if (
            parsedValue > this.ranges.high.maxValue ||
            parsedValue < this.ranges.low.minValue ||
            parsedValue > this.ranges.medium.maxValue
          ) {
            return false;
          }
          break;
        case rangeName === MEDIUM && type === MAX:
          if (
            parsedValue > this.ranges.high.maxValue ||
            parsedValue < this.ranges.low.minValue ||
            parsedValue < this.ranges.medium.minValue
          ) {
            return false;
          }
          break;
        case rangeName === HIGH && type === MIN:
          if (parsedValue < this.ranges.medium.minValue) {
            return false;
          }
          break;
      }

      return true;
    };

    const validateAllInputs = () =>
      Object.keys(this.ranges).every(rangeName => this.ranges[rangeName]['isInvalid'] === false);

    this.defaultRangeData = {};

    this.lowRangeNotification = '';

    this.areAllInputsValid = true;

    this.slider = {};

    this.ranges = {
      low: {
        name: LOW,
        minValue: 0,
        maxValue: 0,
        isActive: false,
        isInvalid: false,
      },
      medium: {
        name: MEDIUM,
        minValue: 0,
        maxValue: 0,
        isActive: false,
        isInvalid: false,
      },
      high: {
        name: HIGH,
        minValue: 0,
        maxValue: 0,
        isActive: false,
        isInvalid: false,
      },
    };

    this.$onChanges = () => {
      if (this.rangeData !== undefined) {
        this.defaultRangeData = angular.copy(this.rangeData);

        this.areAllInputsValid = true;

        $timeout(() => {
          this.slider = Object.assign(
            {
              minValue: 0,
              maxValue: 0,
            },
            SLIDER_CONFIG,
          );

          this.slider.options.disabled = this.isDisabled;

          Object.keys(this.rangeData).forEach(rangeName => {
            const minValue = this.rangeData[rangeName]['minValue'];
            const maxValue = this.rangeData[rangeName]['maxValue'];

            this.ranges[rangeName]['minValue'] = minValue;
            this.ranges[rangeName]['maxValue'] = maxValue;

            if (rangeName === MEDIUM) {
              this.slider.minValue = minValue;
              this.slider.maxValue = maxValue;
            }

            this.ranges[rangeName]['isInvalid'] = false;
          });
        }, 0);
      }

      this.lowRangeNotification = '';
    };

    this.getInputIsDisabled = range => (!range.isInvalid && !this.areAllInputsValid) || this.isDisabled;

    this.onRangeInputFocus = range => {
      range.isActive = true;
    };

    this.onRangeInputBlur = range => {
      range.isActive = false;
    };

    this.onRangeInputChange = (range, rangeName, type, value) => {
      const isValid = validateRangeInput(value, rangeName, type);

      if (isValid) {
        range.isInvalid = false;

        switch (true) {
          case rangeName === LOW && type === MAX:
            this.slider.minValue = value;
            this.ranges.medium.minValue = value;
            break;
          case rangeName === MEDIUM && type === MIN:
            this.slider.minValue = value;
            this.ranges.low.maxValue = value;
            break;
          case rangeName === MEDIUM && type === MAX:
            this.slider.maxValue = value;
            this.ranges.high.minValue = value;
            break;
          case rangeName === HIGH && type === MIN:
            this.slider.maxValue = value;
            this.ranges.medium.maxValue = value;
            break;
        }

        this.onChange({ data: this.ranges });

        this.areAllInputsValid = true;
        this.slider.options.disabled = false;
      } else {
        range.isInvalid = true;

        this.onInvalidValue();

        this.areAllInputsValid = false;
        this.slider.options.disabled = true;
      }
    };

    this.onSliderValueChange = (sliderId, modelValue, highValue, pointerType) => {
      switch (pointerType) {
        case MIN:
          if (this.defaultRangeData.low.maxValue === modelValue) {
            this.lowRangeNotification = '';
          } else {
            if (this.defaultRangeData.low.maxValue > modelValue) {
              this.lowRangeNotification = LOW_RANGE_INCREASED_MSG;
            } else {
              this.lowRangeNotification = LOW_RANGE_DECREASED_MSG;
            }
          }

          this.ranges.low.maxValue = modelValue;
          this.ranges.medium.minValue = modelValue;

          this.ranges.low.isInvalid = false;
          this.ranges.medium.isInvalid = false;
          break;
        case MAX:
          this.ranges.medium.maxValue = highValue;
          this.ranges.high.minValue = highValue;

          this.ranges.medium.isInvalid = false;
          this.ranges.high.isInvalid = false;
          break;
      }

      this.onChange({ data: this.ranges });
    };
  },
  bindings: {
    rangeData: '<',
    isDisabled: '<',
    onChange: '&',
    onInvalidValue: '&',
  },
});
