import './exploreBreachResponse.component.scss';
import template from './exploreBreachResponse.component.html';
import { module } from 'angular';
const app = module('app');

app
  .component('exploreBreachResponse', {
    template,
    controller: [
      '$http',
      'appSettings',
      'breachResponseService',
      '$scope',
      '$state',
      'notificationService',
      '$rootScope',
      'DeleteConfirmation',
      '$translate',
      '$document',
      '$uibModal',
      '$stateParams',
      'explorationBarChartService',
      function (
        $http,
        appSettings,
        breachResponseService,
        $scope,
        $state,
        notificationService,
        $rootScope,
        DeleteConfirmation,
        $translate,
        $document,
        $uibModal,
        $stateParams,
        explorationBarChartService,
      ) {
        var ctrl = this;

        ctrl.editBreachResponseModalInstance = {};
        ctrl.dumpFile = null;
        ctrl.similarities = [];
        ctrl.showBreachResponseData = true;
        ctrl.showData = false;

        ctrl.$onInit = () => {
          if ($stateParams.file) {
            ctrl.setInitializeData($stateParams.file);
          } else if ($stateParams.fileID) {
            breachResponseService.getBreachResponseById($stateParams.fileID).then(result => {
              ctrl.setInitializeData(result.data);
            });
          }
        };

        ctrl.setInitializeData = dumpFile => {
          ctrl.dumpFile = dumpFile;
          ctrl.filter = `?filter=system=${dumpFile.dsConnectionName}`;
          ctrl.dumpFile.createdAt = new Date(dumpFile.createdAt).toLocaleString();
          ctrl.fillIdentities();
          ctrl.fetchSimilaritiesData(dumpFile.dsConnectionName);
        };

        ctrl.fillIdentities = () => {
          explorationBarChartService.getSourceRisks(ctrl.filter).then(result => {
            ctrl.showData = true;
            ctrl.showBreachResponseData = result.totalNumberOfPiiRecords > 0 ? true : false;
          });
        };

        ctrl.fetchSimilaritiesData = dsName => {
          breachResponseService.fetchSimilaritiesData(dsName).then(result => {
            ctrl.similarities = result.data;
            if (result && result.data && result.data.length > 0) {
              ctrl.comparisonFilter = `?filter=system=${result.data[0].ds_name}`;
              ctrl.selectedDSCompared = result.data[0].ds_name;
              ctrl.selectedDSComparedPercentage = result.data[0].similarity;
              ctrl.similarities[0].selected = true;
            }
          });
        };

        ctrl.compareBreachResponse = ds_name => {
          ctrl.similarities.forEach(item => (item.selected = false));
          const similarityData = ctrl.similarities.filter(similarity => similarity.ds_name === ds_name);
          if (similarityData) {
            ctrl.comparisonFilter = `?filter=system=${similarityData[0].ds_name}`;
            ctrl.selectedDSCompared = similarityData[0].ds_name;
            ctrl.selectedDSComparedPercentage = similarityData[0].similarity;
            similarityData[0].selected = true;
          }
        };

        $translate('BREACH_RESPONSE').then(translation => {
          $rootScope.$broadcast('changePage', translation, false);
        });
      },
    ],
  })
  .directive('setClassWhenAtTop', function () {
    return {
      restrict: 'A',
      link: function (scope, element, attrs) {
        var uiView = angular.element(document.querySelector('#uiView'));
        let topClass = attrs.setClassWhenAtTop, // get CSS class from directive's attribute value
          offsetTop = element[0] ? element[0].offsetTop + 160 : 0; // get element's top relative to the document
        uiView.bind('scroll', function () {
          if (uiView[0].scrollTop >= offsetTop) {
            angular
              .element(document.querySelector('.explore_breach_response_comparison_container'))
              .css('margin-top', '-65px');
            element.addClass(topClass);
          } else {
            angular
              .element(document.querySelector('.explore_breach_response_comparison_container'))
              .css('margin-top', '0px');
            element.removeClass(topClass);
          }
        });
      },
    };
  });
