import React, { FunctionComponent, useEffect, useState, ReactNode } from 'react';
import { format } from 'date-fns-tz';
import { BigidPaper, BigidLink } from '@bigid-ui/components';
import { DataCatalogRecord, getDataCatalogRecordsAsCSV } from '../DataCatalogService';
import { BigidGridWithToolbar, BigidGridColumn, BigidGridColumnTypes, BigidGridWithToolbarProps } from '@bigid-ui/grid';
import { getFileDuplicatesByDuplicateId } from './DataCatalogFileDuplicatesService';
import { getDetailsByObjectName } from '../DataCatalogDetails/DataCatalogDetailsService';
import { notificationService } from '../../../services/notificationService';
import { $state } from '../../../services/angularServices';
import makeStyles from '@mui/styles/makeStyles';
import { CatalogEventsEnum } from '../events';
import { analyticsService } from '../../../services/analyticsService';

const TOTAL_DISPLAYED_FILES = 50;

const useStyles = makeStyles({
  wrapper: {
    display: 'flex',
    width: '100%',
    padding: 2,
  },
});

interface DataCatalogDuplicateObjectsGridRow extends DataCatalogRecord {
  index: number;
  skip: number;
  duplicateId: string;
}

const getNormalizedObjectIndex = (index: number, skip: number) => {
  if (index > TOTAL_DISPLAYED_FILES - 1) {
    return { index: index - TOTAL_DISPLAYED_FILES, skip: skip + TOTAL_DISPLAYED_FILES };
  }

  return { index, skip };
};

const previewFileDuplicates = (basicFileInfo: DataCatalogDuplicateObjectsGridRow) => {
  const { id: objectId, skip, index, duplicateId } = basicFileInfo;
  const { index: currIndex, skip: currSkip } = getNormalizedObjectIndex(index, skip);

  $state.go(
    'duplicateObjectsPreview',
    {
      groupId: duplicateId,
      objectId,
      skip: currSkip,
      index: currIndex + 1,
      totalObjects: TOTAL_DISPLAYED_FILES,
    },
    {
      reload: true,
    },
  );
};

const columns: BigidGridColumn<DataCatalogDuplicateObjectsGridRow>[] = [
  {
    name: 'object_name',
    title: 'Object name',
    getCellValue: file => {
      return (<BigidLink text={file.objectName} onClick={() => previewFileDuplicates(file)} />) as ReactNode;
    },
    type: BigidGridColumnTypes.TEXT,
    isListColumn: true,
  },
  {
    name: 'datasource',
    title: 'Data source',
    getCellValue: ({ source }) => source,
    type: BigidGridColumnTypes.TEXT,
    isListColumn: true,
  },
  {
    name: 'fullObjectName',
    title: 'Full object name',
    getCellValue: ({ fullObjectName }) => fullObjectName,
    type: BigidGridColumnTypes.TEXT,
    isListColumn: true,
  },
  {
    name: 'owner',
    title: 'Owner',
    getCellValue: ({ owner }) => owner,
    type: BigidGridColumnTypes.TEXT,
    isListColumn: true,
  },
  {
    name: 'modified_date',
    title: 'Modified date',
    getCellValue: ({ modified_date }) => modified_date && format(new Date(modified_date), 'dd MMM, yyyy hh:mm'),
    type: BigidGridColumnTypes.TEXT,
    isListColumn: true,
  },
];

export const DataCatalogFileDuplicates: FunctionComponent<DataCatalogRecord> = ({
  fullyQualifiedName,
  type,
  source,
  scannerType,
  scanner_type_group,
}: DataCatalogRecord) => {
  const classes = useStyles({});
  const [externalFilter, setExternalFilter] = useState([]);

  useEffect(() => {
    const trackData = {
      fullyQualifiedName,
      dsType: type,
      dsName: source,
      scannerType,
      scanner_type_group,
    };

    analyticsService.trackManualEvent(CatalogEventsEnum.CATALOG_DUPLICATE_TAB, trackData);
  }, [fullyQualifiedName, scannerType, scanner_type_group, source, type]);

  const gridWithToolbarConfig: BigidGridWithToolbarProps<DataCatalogDuplicateObjectsGridRow> = {
    entityName: 'objects',
    columns,
    showSortingControls: false,
    externalFilter,
    fetchData: async queryComponents => {
      if (fullyQualifiedName) {
        try {
          const {
            data: { duplicate_id },
          } = await getDetailsByObjectName(fullyQualifiedName);

          if (duplicate_id) {
            const { results, total, estimatedCount } = await getFileDuplicatesByDuplicateId(
              duplicate_id,
              queryComponents,
            );

            const otherFilesInDuplicateGroup = results.filter(
              (object: DataCatalogRecord) => object.fullyQualifiedName !== fullyQualifiedName,
            );

            const totalDuplicates = (total || estimatedCount) - (results.length - otherFilesInDuplicateGroup.length);

            const data: DataCatalogDuplicateObjectsGridRow[] = otherFilesInDuplicateGroup.map(
              (object: DataCatalogRecord, index: number) => {
                const { skip } = queryComponents;

                return {
                  ...object,
                  index,
                  skip,
                  duplicateId: duplicate_id,
                };
              },
            );

            return {
              totalCount: totalDuplicates,
              data,
            };
          }
        } catch (err) {
          notificationService.error('An error has occurred');
          console.error(`An error has occurred: ${err.message}`);
        }
      }

      return {
        totalCount: 0,
        data: [],
      };
    },
    toolbarActions: [
      {
        label: 'Export',
        isGlobal: true,
        execute: async () => {
          try {
            const {
              data: { duplicate_id },
            } = await getDetailsByObjectName(fullyQualifiedName);
            const filter = `duplicate_id = "${duplicate_id}"`;

            await getDataCatalogRecordsAsCSV(filter);
          } catch (err) {
            const errMsg = `An error has occurred`;
            notificationService.error(errMsg);
            console.error(`${errMsg}, ${err.message}`);
          } finally {
            return { shouldGridReload: false };
          }
        },
        disable: () => false,
        show: ({ totalRows }) => totalRows > 0,
      },
    ],
  };

  useEffect(() => {
    setExternalFilter([]);
  }, [fullyQualifiedName]);

  return (
    <div className={classes.wrapper}>
      <BigidPaper>
        <BigidGridWithToolbar {...gridWithToolbarConfig} />
      </BigidPaper>
    </div>
  );
};
