import styled from '@emotion/styled';
import React, { FC, useCallback } from 'react';
import { IsHovered } from './IsHovered';

interface StyledInteractiveDashboardCardProps {
  minHeight?: number;
  isHovered?: boolean;
}

const StyledInteractiveDashboardCard = styled.div<StyledInteractiveDashboardCardProps>`
  position: relative;
  background: #ffffff;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  overflow-x: auto;
  height: 100%;
  min-height: ${props => (props.minHeight ?? 320) + 'px'};
  transition: box-shadow 0.1s ease-in-out;
  ${props =>
    props.isHovered ? `box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.19);` : 'box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.15);'}
`;

interface InteractiveDashboardCardHoverProps {
  children?: React.ReactNode;
  minHeight?: number;
}

const InteractiveDashboardCardHover: FC<InteractiveDashboardCardHoverProps> = ({ children, minHeight }) => {
  const { isHovered, setIsHovered } = IsHovered.useContainer();

  const handleMouseLeave = useCallback(() => {
    setIsHovered(false);
  }, [setIsHovered]);

  const handleMouseOver = useCallback(() => {
    setIsHovered(true);
  }, [setIsHovered]);

  return (
    <StyledInteractiveDashboardCard
      onMouseLeave={handleMouseLeave}
      onMouseOver={handleMouseOver}
      isHovered={isHovered}
      minHeight={minHeight}
    >
      {children}
    </StyledInteractiveDashboardCard>
  );
};

export interface InteractiveDashboardCardProps {
  children?: React.ReactNode;
  minHeight?: number;
}

export const InteractiveDashboardCard: FC<InteractiveDashboardCardProps> = ({ children, minHeight }) => {
  return (
    <IsHovered.Provider>
      <InteractiveDashboardCardHover minHeight={minHeight}>{children}</InteractiveDashboardCardHover>
    </IsHovered.Provider>
  );
};
