import { uniqBy } from 'lodash';
import type { GetScanTemplatesQueryResult } from '../hooks/useGetScanTemplates';
import { BigidDropdownOption, FrequencyType } from '@bigid-ui/components';

const DELIMITER = ' ';

export const mapGetScanTemplatesResponseToBigidDropdownOptions = ({
  scanTemplates: templates = [],
}: GetScanTemplatesQueryResult) =>
  uniqBy(
    templates.map(
      template =>
        ({
          displayValue: template.name,
          id: template._id,
          value: template._id,
          isSelected: false,
        } as BigidDropdownOption),
    ),
    'value',
  );

export const mapFrequencyTypeToString = (frequency: FrequencyType): string =>
  Object.keys(FrequencyType)[Object.values(FrequencyType).indexOf(frequency)] ?? DELIMITER;
