import React, { useCallback, useEffect, useState, useMemo } from 'react';
import {
  BigidColors,
  BigidDialog,
  BigidFieldFilter,
  BigidIcon,
  BigidIconSize,
  BigidIconSourceType,
  BigidPrimaryCheckbox,
  BigidSelect,
  BigidSelectOption,
  PrimaryButton,
  SecondaryButton,
} from '@bigid-ui/components';
import { FormControl, FormHelperText, Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import {
  MaskOptions,
  MaskOptionsValue,
  SarFieldsSettingsV2 as SarFieldsSettings,
  SarFieldsSettingsBatchUpdate,
} from '../ProfileSettingsTypes';
import { notificationService } from '../../../../services/notificationService';
import { sarConfigService } from '../sarConfigService';
import { GenericField, getPutData, getSelectedOptions, getValueFromClearableSelect } from '../editGridRowsUtils';
import { GlossaryItemType } from '../../../../../administration/generalSettings/businessGlossary/businessGlossary.service';
import { GlossaryItemSelect, GlossaryItemSelectProps } from './GlossaryItemSelect';
import WarningIcon from '@mui/icons-material/Warning';
import { AxiosError } from 'axios';
import { FieldType } from '../SarProfileSettingsTypes';
import { DsarSettingsTrackingEvents } from '../../analyticsUtils';
import { useEditDictionary } from './useEditDictionary';

const useStyles = makeStyles((theme: Theme) => ({
  wrapper: {
    display: 'flex',
    justifyContent: 'center',
    flexFlow: 'column nowrap',
    padding: theme.spacing(0, 1),
  },
  title: {
    marginBottom: theme.spacing(1),
  },
}));

export interface EditFieldsDialogProps {
  profileId: string;
  isOpen: boolean;
  onSave?: (outputState: {
    newState: SarFieldsSettingsBatchUpdate;
    status: { isChanged?: boolean; isOk: boolean };
  }) => void;
  onClose?: () => void;
  sources: Partial<SarFieldsSettings>[];
  allSelected?: boolean;
  totalRows?: number;
  filter?: BigidFieldFilter[];
}

const TOTAL_ROWS_THRESHOLD = 10e3;
const TOTAL_ROWS_THRESHOLD_TEXT = '10k+';
const includeExcludeOptions: BigidSelectOption[] = [
  { label: 'Include', value: true },
  { label: 'Exclude', value: false },
];
const maskOptions: BigidSelectOption[] = [
  { label: MaskOptionsValue.FULL_MASKING, value: MaskOptions.FULL },
  { label: MaskOptionsValue.PARTIAL_MASKING, value: MaskOptions.PARTIAL },
  { label: MaskOptionsValue.NO_MASKING, value: MaskOptions.NO },
];

export const EditFieldsDialog = ({
  profileId,
  isOpen,
  onSave,
  onClose,
  sources,
  filter,
  allSelected,
  totalRows,
}: EditFieldsDialogProps) => {
  const classes = useStyles({});

  const titleText = `Edit Field Settings`;
  const buttonText = 'Save';
  const subTitleText = (
    <>
      {`Editing field settings for ${
        allSelected ? (
          totalRows >= TOTAL_ROWS_THRESHOLD ? (
            <strong>{TOTAL_ROWS_THRESHOLD_TEXT}</strong>
          ) : (
            totalRows
          )
        ) : (
          sources.length
        )
      } fields`}
    </>
  );

  const [isLoading, setIsLoading] = useState(false);
  const dictionaryOptions = useMemo(() => {
    return sources.map(field => ({
      label: field.dictionaryName,
      value: field.dictionaryId,
    }));
  }, [sources]);
  const editDictionaryHook = useEditDictionary(dictionaryOptions, !isOpen);

  const [selectedIsMaskIt, setSelectedIsMaskIt] = useState<BigidSelectOption[]>([]);
  const [selectedIsIncludedInReport, setSelectedIsIncludedInReport] = useState<BigidSelectOption[]>([]);
  const [entitySourceCheckboxValue, setEntitySourceCheckboxValue] = useState(false);
  const [isEntitySourceCheckboxDisabled, setIsEntitySourceCheckboxDisabled] = useState(false);

  const shouldShowEntitySourceCheckbox = sources.length === 1 && sources[0].attributeType === FieldType.CORRELATION;

  const handleIncludeInReportChange = (value: BigidSelectOption[]): void => {
    if (sources[0].attributeType === FieldType.CORRELATION && !value[0].value) {
      setIsEntitySourceCheckboxDisabled(true);
      setEntitySourceCheckboxValue(false);
    } else {
      setIsEntitySourceCheckboxDisabled(false);
    }
    setSelectedIsIncludedInReport(value);
  };

  const [glossarySelectsIsChanged, setGlossarySelectsIsChanged] = useState<Record<GlossaryItemType, boolean>>({
    [GlossaryItemType.PersonalDataItem]: false,
    [GlossaryItemType.PurposeOfProcessing]: false,
    [GlossaryItemType.PersonalDataCategory]: false,
  });

  const [glossarySelectsValues, setGlossarySelectsValues] = useState<
    Record<GlossaryItemType, GlossaryItemSelectProps['value']>
  >({
    [GlossaryItemType.PersonalDataItem]: [],
    [GlossaryItemType.PurposeOfProcessing]: [],
    [GlossaryItemType.PersonalDataCategory]: [],
  });

  const glossarySelectsProps = useMemo<
    (Omit<GlossaryItemSelectProps, 'value'> & {
      fieldName: 'customFriendlyNameId' | 'customPurposeOfUseId' | 'customCategoryId';
    })[]
  >(
    () => [
      {
        title: <div className={classes.title}>{'Friendly Name'}</div>,
        type: GlossaryItemType.PersonalDataItem,
        fieldName: 'customFriendlyNameId',
        onSelectionChange: ids => {
          setGlossarySelectsIsChanged(prevState => ({ ...prevState, [GlossaryItemType.PersonalDataItem]: true }));
          setGlossarySelectsValues(prevState => ({ ...prevState, [GlossaryItemType.PersonalDataItem]: ids }));
        },
      },
      {
        title: <div className={classes.title}>{'Purpose Of Use'}</div>,
        type: GlossaryItemType.PurposeOfProcessing,
        fieldName: 'customPurposeOfUseId',
        onSelectionChange: ids => {
          setGlossarySelectsIsChanged(prevState => ({ ...prevState, [GlossaryItemType.PurposeOfProcessing]: true }));
          setGlossarySelectsValues(prevState => ({ ...prevState, [GlossaryItemType.PurposeOfProcessing]: ids }));
        },
      },
      {
        title: <div className={classes.title}>{'Category Item'}</div>,
        type: GlossaryItemType.PersonalDataCategory,
        fieldName: 'customCategoryId',
        onSelectionChange: ids => {
          setGlossarySelectsIsChanged(prevState => ({ ...prevState, [GlossaryItemType.PersonalDataCategory]: true }));
          setGlossarySelectsValues(prevState => ({ ...prevState, [GlossaryItemType.PersonalDataCategory]: ids }));
        },
      },
    ],
    [classes.title],
  );

  const setFormDataFromSources = useCallback(
    (sources: Partial<SarFieldsSettings>[]) => {
      const selectedIsMaskItValues = getSelectedOptions(sources, 'maskOperation', maskOptions);
      const isIncludedInReportValues = getSelectedOptions(sources, 'isIncludedInReport', includeExcludeOptions);

      setSelectedIsMaskIt(selectedIsMaskItValues);
      setSelectedIsIncludedInReport(isIncludedInReportValues);

      const newGlossarySelectedValues = Object.fromEntries(
        glossarySelectsProps.map(({ type, fieldName }) => {
          const selectedValues = sources.map(source => source[fieldName]);
          return [type, selectedValues];
        }),
      ) as Record<GlossaryItemType, GlossaryItemSelectProps['value']>;
      setGlossarySelectsValues(newGlossarySelectedValues);

      setGlossarySelectsIsChanged(
        Object.fromEntries(glossarySelectsProps.map(({ type }) => [type, false])) as Record<GlossaryItemType, boolean>,
      );
    },
    [glossarySelectsProps],
  );

  useEffect(() => {
    if (isOpen) {
      setFormDataFromSources(sources);
      setEntitySourceCheckboxValue(
        sources.length === 1 && sources[0].attributeType === FieldType.CORRELATION
          ? sources[0].isIncludedInProximityCategory
          : undefined,
      );
      setIsEntitySourceCheckboxDisabled(sources.length === 1 && !sources[0].isIncludedInReport);
    } else {
      setSelectedIsMaskIt([]);
      setSelectedIsIncludedInReport([]);
      glossarySelectsProps.forEach(selectProps => selectProps.onSelectionChange([undefined]));
    }
  }, [glossarySelectsProps, isOpen, setFormDataFromSources, sources]);

  const handleOnSave = async () => {
    let dictionary;
    try {
      setIsLoading(true);
      dictionary = await editDictionaryHook.uploadDictionary();
    } catch (err) {
      console.error(err);
      setIsLoading(false);
      return;
    }
    const maskOperation = selectedIsMaskIt?.[0]?.value;
    const maskIt = maskOperation ? maskOperation !== MaskOptions.NO : undefined;
    const newState: SarFieldsSettingsBatchUpdate = {
      maskIt,
      maskOperation,
      isIncludedInReport: selectedIsIncludedInReport?.[0]?.value,
      customFriendlyNameId: getNewStateFromGlossarySelect(GlossaryItemType.PersonalDataItem),
      customPurposeOfUseId: getNewStateFromGlossarySelect(GlossaryItemType.PurposeOfProcessing),
      customCategoryId: getNewStateFromGlossarySelect(GlossaryItemType.PersonalDataCategory),
      isIncludedInProximityCategory: entitySourceCheckboxValue,
      dictionaryId: dictionary === null ? null : dictionary?.id,
    };

    updateSources(profileId, newState, sources, allSelected, filter)
      .then(() => {
        onSave({ newState, status: { isChanged: true, isOk: true } });
      })
      .catch(() => {
        onSave({ newState, status: { isChanged: false, isOk: false } });
      })
      .finally(() => setIsLoading(false));

    function getNewStateFromGlossarySelect(type: GlossaryItemType) {
      if (glossarySelectsIsChanged[type]) {
        // TODO: need to support multiple values per field item
        return glossarySelectsValues[type]?.[0];
      }
    }
  };

  return (
    <BigidDialog
      title={titleText}
      isOpen={isOpen}
      onClose={onClose}
      borderTop={true}
      buttons={[
        {
          component: props => (
            <SecondaryButton
              {...props}
              bi={{
                eventType: DsarSettingsTrackingEvents.PROFILE_FIELDS_EDIT_CANCEL_ACTION,
              }}
            />
          ),
          onClick: onClose,
          text: 'Cancel',
        },
        {
          component: props => (
            <PrimaryButton
              {...props}
              bi={{
                eventType: DsarSettingsTrackingEvents.PROFILE_FIELDS_EDIT_SAVE_ACTION,
              }}
            />
          ),
          onClick: handleOnSave,
          text: buttonText,
        },
      ]}
      isLoading={isLoading}
    >
      <div className={classes.wrapper}>
        <form noValidate autoComplete="off">
          <fieldset>
            <FormControl fullWidth margin="normal">
              <div>{subTitleText}</div>
            </FormControl>
            <FormControl fullWidth margin="normal">
              <div className={classes.title}>{'Include in DSAR Report'}</div>
              <BigidSelect
                name="isIncludedInReport"
                options={includeExcludeOptions}
                onChange={handleIncludeInReportChange}
                value={selectedIsIncludedInReport}
                menuPosition="fixed"
                message={getDropdownMessage(selectedIsIncludedInReport)}
                size="large"
              />
            </FormControl>
            {shouldShowEntitySourceCheckbox && (
              <BigidPrimaryCheckbox
                checked={entitySourceCheckboxValue}
                disabled={isEntitySourceCheckboxDisabled}
                onChange={(e, val) => setEntitySourceCheckboxValue(val)}
                label={'Use as an entity source context for proximity data'}
              />
            )}
            <FormControl fullWidth margin="normal">
              <div className={classes.title}>{'Mask Value'}</div>
              <BigidSelect
                name={'isMaskIt'}
                options={maskOptions}
                onChange={setSelectedIsMaskIt}
                value={selectedIsMaskIt}
                menuPosition="fixed"
                message={getDropdownMessage(selectedIsMaskIt)}
                size="large"
              />
            </FormControl>

            {glossarySelectsProps.map(selectProps => (
              <GlossaryItemSelect
                key={selectProps.type}
                {...selectProps}
                value={glossarySelectsValues[selectProps.type]}
                size="large"
              />
            ))}
            <FormControl fullWidth margin="normal">
              {editDictionaryHook.checkbox}
            </FormControl>
            {editDictionaryHook.tabs && (
              <FormControl fullWidth margin="normal">
                {editDictionaryHook.tabs}
              </FormControl>
            )}
            {allSelected && totalRows >= TOTAL_ROWS_THRESHOLD && (
              <FormControl fullWidth margin="normal">
                <FormHelperText>
                  <BigidIcon
                    type={BigidIconSourceType.MUI}
                    icon={WarningIcon}
                    color={BigidColors.warningYellow}
                    size={BigidIconSize.REGULAR}
                    label={`Updating ${TOTAL_ROWS_THRESHOLD_TEXT} fields might take some time.`}
                  />
                </FormHelperText>
              </FormControl>
            )}
          </fieldset>
        </form>
      </div>
    </BigidDialog>
  );
};

const getDropdownMessage = (option: BigidSelectOption[]) =>
  option?.[0]?.label === 'Multiple Values' ? 'Select Value to Apply to all' : '';

export async function updateSources(
  profileId: string,
  newState: SarFieldsSettingsBatchUpdate,
  sources: Partial<SarFieldsSettings>[],
  allSelected: boolean,
  gridQueryFilter: BigidFieldFilter[],
) {
  const genericFields: GenericField<SarFieldsSettings, SarFieldsSettingsBatchUpdate>[] = [
    {
      name: 'maskIt',
    },
    {
      name: 'maskOperation',
    },
    {
      name: 'isIncludedInReport',
    },
    {
      name: 'isIncludedInProximityCategory',
    },
    {
      name: 'customFriendlyNameId',
      getValue: ({ customFriendlyNameId: newValue }) => getValueFromClearableSelect(newValue),
    },
    {
      name: 'customPurposeOfUseId',
      getValue: ({ customPurposeOfUseId: newValue }) => getValueFromClearableSelect(newValue),
    },
    {
      name: 'customCategoryId',
      getValue: ({ customCategoryId: newValue }) => getValueFromClearableSelect(newValue),
    },
    {
      name: 'dictionaryId',
    },
  ];

  const [updateData] = getPutData([], genericFields, newState, sources[0]);
  if (!updateData || !Object.keys(updateData).length) {
    notificationService.success(`Nothing to update`);
    return;
  }

  const selectedRowIds = sources.map(({ id }) => id);
  const payload = {
    data: updateData,
    query: {
      filter: getQueryFilter(allSelected, gridQueryFilter, selectedRowIds),
    },
  };
  return sarConfigService
    .updateProfileFieldsV2(profileId, payload)
    .then(() => {
      notificationService.success(`Changes saved`);
    })
    .catch((err: AxiosError<any>) => {
      const { data } = err.response;
      const errMsg = data?.message || data?.error || '';
      notificationService.error(`Failed to save changes ${errMsg}`);
      console.error(`Failed to update sar-profile, postData: `, JSON.stringify(payload), errMsg, data);
      throw err;
    });
}

export const getQueryFilter = (
  allSelected: boolean,
  gridQueryFilter: BigidFieldFilter[],
  selectedRowIds: (string | number)[],
) => {
  return allSelected || !selectedRowIds.length
    ? gridQueryFilter
    : [
        {
          field: '_id',
          operator: 'in',
          value: selectedRowIds,
        } as BigidFieldFilter,
      ];
};
