import React, { FC, useCallback, useEffect, useState } from 'react';
import { ClassifierModelSummary } from './ClassifierModelSummary';
import { getCurrentInputsCounts, getSupervisedLearningProcess } from './classifierModelService';
import {
  getClassifierModelState,
  getRegexAccuracy,
  ModelData,
  RegexData,
  sortModelsByEvaluationTime,
  TuningData,
  getComputedModelData,
} from './classifierModelUtils';
import { ClassifierModelCards } from './Cards/ClassifierModelCards';
import { useLocalTranslation } from './translations';
import { generateDataAid } from '@bigid-ui/utils';
import { styled } from '@mui/material';
import { notificationService } from '../../../../../services/notificationService';
import { BigidBody1, BigidLoader } from '@bigid-ui/components';

const Root = styled('div')`
  background: ${({ theme }) => theme.vars.tokens.bigid.pageBackground};
  height: 100%;
`;

const FooterText = styled(BigidBody1)`
  color: ${({ theme }) => theme.vars.tokens.bigid.foregroundTertiary};
  position: absolute;
  bottom: 24px;
  margin-left: 20px;
`;

export interface ClassifierModelProps {
  classifierName: string;
  dataAid?: string;
}

export const ClassifierModel: FC<ClassifierModelProps> = ({ classifierName, dataAid = 'ClassifierModel' }) => {
  const { t } = useLocalTranslation('main');
  const [tuningData, setTuningData] = useState<TuningData>();
  const [regexData, setRegexData] = useState<RegexData>();
  const [modelData, setModelData] = useState<ModelData[]>();
  const [shouldRecomputeContent, setShouldRecomputeContent] = useState(false);

  const handleRecomputationNeeded = useCallback(() => {
    setShouldRecomputeContent(!shouldRecomputeContent);
  }, [shouldRecomputeContent]);

  useEffect(() => {
    const fetchCount = async () => {
      try {
        const curationData = await getCurrentInputsCounts(classifierName);
        const modelData = await getSupervisedLearningProcess(classifierName);
        const sortedModelData = sortModelsByEvaluationTime(modelData);
        const approveCountFromResponse = curationData.data.attributes[0]?.approvedCount;
        const rejectedCountFromResponse = curationData.data.attributes[0]?.rejectedCount;
        const displayName = curationData.data.attributes[0]?.displayName;

        const regexAccuracy = getRegexAccuracy(approveCountFromResponse, rejectedCountFromResponse);
        const regexAccuracyText = isNaN(regexAccuracy) ? t('regexCard.accuracyWhenNoInputs') : `${regexAccuracy}%`;

        const [newerModel, olderModel] = getComputedModelData(sortedModelData, regexAccuracy);

        const tuningData: TuningData = {
          newApproves: newerModel?.evaluationNumApproved || 0,
          newRejects: newerModel?.evaluationNumRejected || 0,
          totalApproves: approveCountFromResponse,
          totalRejects: rejectedCountFromResponse,
          classifierModelStage: newerModel?.classifierModelStage || getClassifierModelState([], false, false),
          classificationName: classifierName,
          displayName,
        };

        const regexData: RegexData = {
          regexAccuracyText,
        };

        setTuningData(tuningData);
        setRegexData(regexData);
        setModelData([newerModel, olderModel]);
      } catch ({ message }) {
        notificationService.error(t('notifications.failure.fetch'));
        console.error(`An error has occurred: ${message}`);
      }
    };

    fetchCount();
  }, [classifierName, t, shouldRecomputeContent]);

  return (
    <Root>
      {!modelData ? (
        <BigidLoader />
      ) : (
        <>
          <ClassifierModelSummary tuningData={tuningData} />
          <ClassifierModelCards
            tuningData={tuningData}
            regexData={regexData}
            modelData={modelData}
            onRender={handleRecomputationNeeded}
          />
          <FooterText data-aid={generateDataAid(dataAid, ['footer'])}>{t('footer')}</FooterText>
        </>
      )}
    </Root>
  );
};
