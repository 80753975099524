import React, { FC } from 'react';
import { styled } from '@mui/material';
import { BigidBody1 } from '@bigid-ui/components';
import { darken, lighten } from '@bigid-ui/colors';
import { useTheme } from '@mui/styles';
import { useLocalTranslation } from '../../translations';

const PRIMARY300_COLOR_HEX = '#B489FF';

export interface FunnelTooltipProps {
  dsAmount: number;
  dsPercentage: number;
  stepNumber: number;
  category: string;
  isDropOff?: boolean;
}

const Container = styled('div')`
  display: flex;
  flex-direction: column;
  width: 208px;
`;

export const MainInfo = styled('div')<{
  isDropOff: boolean;
}>(({ isDropOff, theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  paddingTop: '6px',
  paddingBottom: isDropOff ? '0px' : '8px',
  borderBottom: isDropOff ? 'none' : `1px solid ${theme.vars.palette.bigid.gray200}`,
}));

const IndicatorInfo = styled('div')`
  display: flex;
  flex-direction: row;
  max-width: 80%;
  overflow: hidden;
  align-items: center;
`;

export const Indicator = styled('div')<{
  isDropOff: boolean;
}>(({ isDropOff }) => ({
  height: '10px',
  width: '10px',
  borderRadius: '2px',
  margin: '0px 4px 1px 0px',
  background: isDropOff
    ? `repeating-linear-gradient(-45deg,  ${lighten(PRIMARY300_COLOR_HEX, 0.1)}, ${lighten(
        PRIMARY300_COLOR_HEX,
        0.1,
      )} 1px, ${lighten(PRIMARY300_COLOR_HEX, 0.2)} 1px, ${lighten(PRIMARY300_COLOR_HEX, 0.2)} 4px)`
    : darken(PRIMARY300_COLOR_HEX, 0.2),
}));

export const FunnelTooltip: FC<FunnelTooltipProps> = ({ dsAmount, dsPercentage, stepNumber, category, isDropOff }) => {
  const theme = useTheme();
  const { t } = useLocalTranslation('OnboardingAssistant.tooltips');
  const dsAmoutLabel = `${dsAmount} (${dsPercentage}%)`;
  return (
    <Container>
      <BigidBody1 color={theme.vars.palette.bigid.gray500} size="small">
        {isDropOff ? t('dropOff') : t('step', { stepNumber })}
      </BigidBody1>
      <MainInfo isDropOff={isDropOff}>
        <IndicatorInfo>
          <Indicator isDropOff={isDropOff} />
          <BigidBody1 color={theme.vars.palette.bigid.gray600}>
            {isDropOff ? t(`dropLabels.${category}`) : t(category)}
          </BigidBody1>
        </IndicatorInfo>
        <BigidBody1 color={theme.vars.palette.bigid.gray500}>{dsAmoutLabel}</BigidBody1>
      </MainInfo>
      {!isDropOff && (
        <BigidBody1 paddingTop={'8px'} color={theme.vars.palette.bigid.gray500} size="small">
          {t(`stepInfo.${category}`)}
        </BigidBody1>
      )}
    </Container>
  );
};
