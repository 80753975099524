import { useState } from 'react';
import { loginService } from '../../../../../authentication/login/login.service';
import { useEventListener } from './useEventListener';

export const useUser = () => {
  const [user, setUser] = useState(() => loginService.getUser());

  // @info monitor storage changes and refresh the user state
  useEventListener('storage', () => setUser(loginService.getUser()));

  return user;
};
