import { module } from 'angular';
const app = module('app');

app.factory('auditService', [
  '$http',
  'appSettings',
  'downloadFileService',
  function ($http, appSettings, downloadFileService) {
    return {
      getAuditData: () =>
        $http.get(appSettings.serverPath + '/' + appSettings.version + '/audit-log').then(response => response.data),
      downloadAuditData: () => {
        return $http.get(appSettings.serverPath + '/' + appSettings.version + '/audit-log/export').then(response => {
          downloadFileService.download(response.data, 'attachment/csv', 'audit.csv');
        });
      },
    };
  },
]);
