import { module } from 'angular';
const app = module('app');

app.factory('commonApi', [
  '$http',
  'appSettings',
  function ($http, appSettings) {
    return {
      getSystemPermissions: () =>
        $http.get(`${appSettings.serverPath}/${appSettings.version}/system-permissions`).then(response => response),
    };
  },
]);
