import { useEffect, useState } from 'react';
import { $stateParams, $transitions } from '../services/angularServices';
import { handleTransitionPayloadReceive } from '../components/MetadataSearch/MetadataSearchUtils';

type Indexable = { [key: string]: string };

export const useUpdatedStateParams = (shouldDecodeParams?: boolean) => {
  const [stateParams, setStateParams] = useState<Indexable>(
    shouldDecodeParams ? handleTransitionPayloadReceive($stateParams) : $stateParams,
  );

  useEffect(() => {
    const deregister = $transitions.onSuccess({}, transition => {
      const params = transition.params('to');
      setStateParams(shouldDecodeParams ? handleTransitionPayloadReceive(params) : params);
    });

    return () => {
      deregister();
    };
  }, [shouldDecodeParams]);

  return stateParams;
};
