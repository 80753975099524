import React, { FC } from 'react';
import { BigidTextField, TextFieldProps, PrimaryButton, SecondaryButton, BigidBody1 } from '@bigid-ui/components';
import { openSystemDialog, SystemDialogContentProps } from '../../../react/services/systemDialogService';
import { notificationService } from '../../../react/services/notificationService';
import styled from '@emotion/styled';
import { useLocalTranslation } from '../../../react/views/ClassifiersManagement/translations';
import { isClassifierNameValid } from '../../../react/views/ClassifiersManagement/RegexForm/validationUtils';

const Description = styled(BigidBody1)`
  font-size: 0.875rem !important;
  margin-bottom: 16px;
`;

export const BigidDuplicateClassifierDialog: FC<
  SystemDialogContentProps<TextFieldProps & { classifierName?: string }>
> = ({ onChange, classifierName }) => {
  const { t } = useLocalTranslation('duplicateDialog');
  return classifierName ? (
    <div>
      <Description>{t('descriptionDialog', { classifierName })}</Description>
      <BigidTextField onChange={onChange} label={'New classifier name'} required />
    </div>
  ) : (
    <BigidTextField onChange={onChange} label={'Classifier name'} required />
  );
};

export const openCreateDuplicateClassifierDialog = async (
  maxWidth: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | false = 'sm',
  classifierName: string,
  isOpenedFromNewClassifiersPage = false,
) => {
  let duplicateClassifierName = '';
  const contentProps = {
    label: 'Classifier Name',
    onChange: (event: any) => {
      duplicateClassifierName = event.target.value;
    },
    isError: false,
    classifierName,
  };
  return new Promise<{ duplicateClassifierName: string }>(resolve => {
    openSystemDialog({
      title: 'Duplicate Classifier',
      content: BigidDuplicateClassifierDialog,
      contentProps,
      maxWidth,
      onClose: () => null,
      buttons: [
        {
          text: 'Cancel',
          component: SecondaryButton,
          onClick: () => null,
          isClose: true,
        },
        {
          text: classifierName ? 'Duplicate' : 'Submit',
          component: PrimaryButton,
          onClick: () => {
            const classifierNameTrim = duplicateClassifierName.trim();
            const isNameValid = isClassifierNameValid(classifierNameTrim);

            if (classifierNameTrim.length === 0) {
              notificationService.error('The classifier name could not be an empty');
              resolve({ duplicateClassifierName: null });
              return;
            }

            if (isOpenedFromNewClassifiersPage && isNameValid) {
              notificationService.error(isNameValid);
              resolve({ duplicateClassifierName: null });
              return;
            }

            resolve({ duplicateClassifierName: classifierNameTrim });
          },
          isClose: true,
        },
      ],
    });
  });
};
