import { useState, useCallback, useEffect } from 'react';
import { TagEntity, TagAssignmentTarget } from '../../../TagsManagement/TagsManagementService';
import {
  DataCatalogAsyncOperationStatus,
  DataCatalogAsyncOperation,
  DataCatalogAsyncOperationNameMapping,
  DataCatalogAsyncOperationRoutingKey,
  DataCatalogAsyncOperationType,
  DataCatalogAsyncOperationListenerParams,
  DataCatalogAsyncOperationListenerResponse,
} from '../DataCatalogAsyncOpsTypes';
import { subscribeToRepeatedSSEEventById, SSEDataMessage } from '../../../../services/sseService';
import { AsyncOperation } from '../../../../components/AsyncOperationProcessingWidget/AsyncOperationProcessingWidget';

export interface TagBulkAssignmentPayloadData {
  type: TagAssignmentTarget;
  tags: TagEntity[];
}

export interface TagBulkAssignmentAsyncOperationPayload {
  type: TagAssignmentTarget;
  tags: TagEntity[];
}

export type TagBulkAssignmentSseResults = DataCatalogAsyncOperation<TagBulkAssignmentAsyncOperationPayload>;

export type TagBulkAssignmentOperationsSorted = Record<DataCatalogAsyncOperationStatus, TagBulkAssignmentSseResults[]>;

export const tagBulkAssignmentNameMapping: DataCatalogAsyncOperationNameMapping = {
  [DataCatalogAsyncOperationType.TAG_BY_QUERY]: 'Assigning',
  [DataCatalogAsyncOperationType.UNTAG_BY_QUERY]: 'Unassigning',
};

export type UseTagBulkAssignmentParams = DataCatalogAsyncOperationListenerParams;
export type UseTagBulkAssignmentResponse = DataCatalogAsyncOperationListenerResponse<AsyncOperation>;

export const useTagBulkAssignment = ({
  onOperationRun,
  onOperationCompleted,
}: UseTagBulkAssignmentParams): UseTagBulkAssignmentResponse => {
  const [operations, setOperations] = useState<AsyncOperation[]>([]);
  const [isAssignmentInProcess, setIsAssignmentInProcess] = useState<boolean>(false);

  useEffect(() => {
    onOperationRun(DataCatalogAsyncOperationRoutingKey.TAG_BULK_ASSIGNMENT, isAssignmentInProcess);
  }, [isAssignmentInProcess, onOperationRun]);

  const handleTagBulkAssignmentBroadcastEventReceived = useCallback(
    ({ results = [] }: SSEDataMessage<TagBulkAssignmentSseResults>) => {
      const operationsSortedSkeleton = Object.keys(DataCatalogAsyncOperationStatus).reduce(
        (operationsSortedBase, status) => ({ ...operationsSortedBase, [status]: [] }),
        {},
      ) as TagBulkAssignmentOperationsSorted;
      const operationsSorted = results.reduce((operationsSortedAggr, operation) => {
        const { status } = operation;
        return { ...operationsSortedAggr, [status]: [...(operationsSortedAggr[status] || []), operation] };
      }, operationsSortedSkeleton);

      const operationsUpdated = [
        ...operationsSorted.ERROR,
        ...operationsSorted.COMPLETED,
        ...operationsSorted.RUNNING,
        ...operationsSorted.NOT_STARTED,
      ].reduce((operationsAggr, serverOperation) => {
        const { name, payload, status, taskStatus, indexingStatus, totalObjects, percentage } = serverOperation;

        if (payload?.tags.length > 0) {
          const hasIndexingPostTask = taskStatus && indexingStatus;
          const { tagName, tagValue } = payload.tags[0];
          const taskSubject = `"${tagName}:${tagValue}"`;
          const taskDescription =
            totalObjects > 0 ? `tag to ${totalObjects} objects` : '(counting number of objects...)';
          const taskOperation = {
            status: hasIndexingPostTask ? taskStatus : status,
            percentage:
              status === DataCatalogAsyncOperationStatus.RUNNING && typeof percentage === 'number'
                ? Math.round(percentage)
                : undefined,
            name: tagBulkAssignmentNameMapping[name],
            description: taskDescription,
            entityName: taskSubject,
          };

          operationsAggr.push(taskOperation);

          if (hasIndexingPostTask && totalObjects > 0) {
            const indexingOperation = {
              status: indexingStatus,
              name: 'Indexing',
              description: `tag assignment to ${totalObjects} objects`,
              entityName: taskSubject,
            };

            operationsAggr.push(indexingOperation);
          }

          return operationsAggr;
        } else {
          return operationsAggr;
        }
      }, []);

      const hasCompletedOperations = operationsUpdated.some(
        ({ status }) => status === DataCatalogAsyncOperationStatus.COMPLETED,
      );

      if (hasCompletedOperations) {
        onOperationCompleted();
      }

      setOperations(operationsUpdated);
      setIsAssignmentInProcess(!!results.length);
    },
    [onOperationCompleted],
  );

  useEffect(() => {
    const unsubscribe = subscribeToRepeatedSSEEventById(
      DataCatalogAsyncOperationRoutingKey.TAG_BULK_ASSIGNMENT,
      handleTagBulkAssignmentBroadcastEventReceived,
    );

    return () => {
      unsubscribe();
    };
  }, [handleTagBulkAssignmentBroadcastEventReceived]);

  return { operations };
};
