import './scanInsights.scss';
import { parseCronExpression } from '../../../common/services/localizationHelper.js';
import { intervalToDuration, formatDuration } from 'date-fns';
import template from './scanInsights.html';
import { dateUtils } from '../../../react/services/angularServices';
import { module } from 'angular';

const app = module('app');

const UI_SELECT_OPTIONS = {
  ALL: 'All',
  NEW: 'New',
  REMOVED: 'Removed',
};

const UI_SELECT_OPTIONS_FILTERS = {
  ALL: item => item,
  NEW: item => item && item.isNew,
  REMOVED: item => item && item.isRemove,
};

const NA_VALUE = 'N/A';

function ScanInsightsReportController() {
  'ngInject';

  class ScanInsightsReportComponent {
    constructor() {
      this.scanId = undefined;

      this.isLoading = false;

      this.sections = {
        scanProfile: {
          name: 'Scan Profile',
        },
        scanSummary: {
          name: 'Data Sources',
        },
        attributes: {
          name: 'Attributes',
          attributes: {
            name: 'Attributes',
            total: 'Attributes',
            newAttributes: 'New',
            remove: 'Removed',
          },
          classifications: {
            name: 'Classifications',
            total: 'Classifications',
            newAttributes: 'New',
            remove: 'Removed',
          },
          uiSelectOptions: [UI_SELECT_OPTIONS.ALL, UI_SELECT_OPTIONS.NEW, UI_SELECT_OPTIONS.REMOVED],
          selectedOption: UI_SELECT_OPTIONS.ALL,
        },
      };
    }

    $onInit() {
      this.scanSummaryGrid = {
        onRegisterApi: gridApi => {
          this.scanSummaryGridApi = gridApi;
        },
      };

      this.initScanSummaryGrid();
      this.updateScanSummaryGridData(this.data);
    }

    $onChanges(changesObj) {
      if (changesObj.data && !changesObj.data.isFirstChange()) {
        this.updateScanSummaryGridData(this.data);
      }
    }

    parseCronExpression(cronExpression) {
      if (cronExpression !== undefined) {
        // for some reason parseCronExpression('') returns 'Every minute' so I need to check if the value is not ''
        return cronExpression ? parseCronExpression(cronExpression) : '---';
      }
    }

    /**
     * There is special case when server returns string, we show it as is
     * when it returns int, means it is seconds
     */
    humanizedDurationOrNA(stringOrSecond) {
      if (typeof stringOrSecond === 'string') {
        return stringOrSecond;
      }
      if (typeof stringOrSecond === 'number') {
        const duration = intervalToDuration({ start: 0, end: stringOrSecond * 1000 });
        if (duration.months > 0) {
          duration.days += 30 * duration.months;
          duration.months = 0;
        }
        if (duration.years > 0) {
          duration.days += 365 * duration.years;
          duration.years = 0;
        }
        return formatDuration(duration);
      }
      return NA_VALUE;
    }

    formatDateOrNA(input) {
      const formattedDate = dateUtils.formatDate(input);
      if (formattedDate) {
        return formattedDate;
      }
      return NA_VALUE;
    }

    initScanSummaryGrid() {
      Object.assign(this.scanSummaryGrid, {
        enablePaginationControls: false,
        enableColumnMenus: false,
        enableSorting: true,
        enableFiltering: true,
        virtualizationThreshold: 20,
        appScopeProvider: this,
        columnDefs: [
          {
            name: 'Data Source Name',
            displayName: 'Data Source Name',
            field: 'name',
            enableFiltering: true,
            filterHeaderTemplate: `<ui-grid-bigid-text-filter/>`,
            filter: {
              placeholder: 'Type Name',
              ariaLabel: 'filter for the name field',
              flags: { caseSensitive: false },
            },
            cellTemplate: `<div title="{{COL_FIELD}}" class="ui-grid-cell-with-label" style="position:relative">
              <div class="cell-value">{{COL_FIELD}}</div> 
              <div ng-if="row.entity.isNew" class="trends__label trends__label--new">New</div>
            </div>`,
          },
          {
            name: 'Objects Scanned',
            field: 'objectScanned',
            maxWidth: 150,
            enableFiltering: false,
            cellTemplate: `<div ng-if="row.entity.isFail" style="color: #fc003b;">Scan failed</div>
               <div title="{{COL_FIELD|number}}" ng-if="!row.entity.isFail">{{COL_FIELD | formatIntOrNA}}</div>`,
          },
          {
            name: 'Objects with PI',
            displayName: 'Objects with PI',
            field: 'objectsWithPI',
            maxWidth: 150,
            enableFiltering: false,
            cellTemplate: `<div class="ui-grid-cell-with-arrows" title="{{COL_FIELD|number}}">{{COL_FIELD | formatIntOrNA}}
              <span ng-if="row.entity.trend === undefined || row.entity.trend === '' ">(${NA_VALUE})</span>
              <span ng-if="row.entity.trend">({{grid.appScope.mathAbs(row.entity.trend)}}<i ng-class="{'fa fa-long-arrow-up': row.entity.trend > 0, 'fa fa-long-arrow-down': row.entity.trend < 0}"></i>)</span>
            </div>`,
          },
        ],
      });
    }

    getAttributesData(data, selectedOptionName) {
      if (Array.isArray(data) && data.length) {
        const filterKey = Object.entries(UI_SELECT_OPTIONS).find(([, value]) => value === selectedOptionName)[0];
        const filterFn = UI_SELECT_OPTIONS_FILTERS[filterKey];
        if (filterFn) {
          return data.filter(filterFn);
        }
      }
      return [];
    }

    updateScanSummaryGridData(data) {
      try {
        this.scanSummaryGrid.data = data.scanSummary.dataSources;
      } catch (e) {
        this.scanSummaryGrid.data = [];
      }
    }

    onCloseClick() {
      this.onClose({ event: { isOnCloseClick: true } });
    }

    showScanSummarySection() {
      return this.data && this.data.scanSummary;
    }

    showAttributesSection() {
      return (
        this.data &&
        ((this.data.attributes && this.data.attributes.attributes) ||
          (this.data.attributes && this.data.attributes.classifications))
      );
    }

    mathAbs(value) {
      return isNaN(value) ? value : Math.abs(value);
    }
  }

  return new ScanInsightsReportComponent();
}

app.filter('undefinedStringToNA', () => {
  return str => {
    if (str === '' || str === undefined) {
      return NA_VALUE;
    }
    return str;
  };
});

app.filter('formatIntOrNA', commonMethods => {
  'ngInject';
  return (number, decPlaces = 2) => {
    if (isNaN(number)) {
      return NA_VALUE;
    }
    return commonMethods.getAbbrNum(parseInt(number, 10), decPlaces);
  };
});

app.component('scanInsightsReport', {
  template,
  controller: ScanInsightsReportController,
  bindings: {
    scanId: '<',
    data: '<scanInsightsData',
    onClose: '&',
  },
});
