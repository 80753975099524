import { PrimaryButton } from '@bigid-ui/components';
import React, { FC, memo } from 'react';
import { $state } from '../../../../../services/angularServices';
import { CONFIG } from '../../../../../../config/common';
import { useLocalTranslation } from './translations';
import { UseCatalogDiscoveryResponse } from '../../../useCatalogDiscovery';
import { BIGID_BI_EVENTS } from '../../../../../config/BigIdBiEvents';

export interface GoToCatalogProps extends Pick<UseCatalogDiscoveryResponse, 'query'> {
  dataAid?: string;
}

export const GoToCatalog: FC<GoToCatalogProps> = memo(
  ({ dataAid = 'GoToCatalog', query }) => {
    const { t } = useLocalTranslation();

    const handleGoToCatalogClick = (): void => {
      $state.go(CONFIG.states.CATALOG, {
        filter: query,
        shouldResetFilter: query.length === 0,
      });
    };

    return (
      <PrimaryButton
        dataAid={dataAid}
        size="medium"
        onClick={handleGoToCatalogClick}
        bi={{
          eventType: BIGID_BI_EVENTS.DATAINSIGHTS_ACTIONS_GO_TO_CATALOG_CLICK,
        }}
        text={t('text')}
      />
    );
  },
  (prevProps, newProps) => prevProps.query === newProps.query,
);
