import React, { FC } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { BigidHeading4, BigidColors } from '@bigid-ui/components';

interface DataSourceEnabledStatusProps {
  enabled?: boolean;
}

const useStyles = makeStyles({
  headerStatusWrapper: {
    display: 'flex',
    alignItems: 'center',
    width: '100vw',
    textAlign: 'left',
    padding: '0 14px',
    margin: '2px 0 0 16px',
    borderLeft: `1px solid ${BigidColors.gray[500]}`,
    height: '28px',
  },
});

export const DataSourceEnabledStatus: FC<DataSourceEnabledStatusProps> = ({ enabled }) => {
  const { headerStatusWrapper } = useStyles({});
  return enabled !== undefined ? (
    <BigidHeading4 className={headerStatusWrapper} data-aid="DataSourceEnabledStatus">
      {enabled ? 'Enabled' : 'Disabled'}
    </BigidHeading4>
  ) : null;
};
