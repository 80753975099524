import React, { FC, useState, Dispatch, SetStateAction, useRef } from 'react';
import { CustomAppParam } from '../../views/EditCustomApp/EditCustomApp';
import { SingleParamRow } from './SingleParamRow';
import { BigidRadioGroup } from '@bigid-ui/components';
import { getApplicationPreference } from '../../../../services/appPreferencesService';

const CREDENTIAL_OPTION = 'Credential';
const CONSTANT_PARAMS_OPTION = 'Constant Parameters';

interface CredentialsParamsGroupProps {
  credentialParam: CustomAppParam;
  relatedParams: CustomAppParam[];
  onParamChange: (key: string, value: string) => void;
  onParamToggle?: (paramKey: string, paramValue: string, enabled: boolean) => void;
  values?: Record<string, string>;
  readOnly?: boolean;
  shouldEnableOverriding?: boolean;
}

export const CredentialsParamsGroup: FC<CredentialsParamsGroupProps> = ({
  credentialParam,
  relatedParams,
  onParamChange,
  onParamToggle,
  values,
  readOnly,
  shouldEnableOverriding,
}: CredentialsParamsGroupProps) => {
  const credentialsInTpaEnabled = getApplicationPreference('ENABLE_CREDENTIALS_IN_TPA_FF');

  const prevCredentialValue = useRef<string>(null);

  const haveSelectedCredential =
    !!credentialParam.paramValue && credentialParam?.inputItems.includes(credentialParam.paramValue);

  const [isUsingCredentialField, setIsUsingCredentialField] = useState<boolean>(
    haveSelectedCredential || !relatedParams || relatedParams.length === 0,
  );

  return (
    <div data-aid="credentialsParamGroup">
      {credentialsInTpaEnabled && relatedParams.length > 0 && (
        <BigidRadioGroup
          defaultValue={haveSelectedCredential ? CREDENTIAL_OPTION : CONSTANT_PARAMS_OPTION}
          horizontal
          onChange={() => {
            const toggledFromCredToFields = isUsingCredentialField;
            if (toggledFromCredToFields) {
              prevCredentialValue.current = credentialParam.paramValue;
              onParamChange(credentialParam.name, null);
            } else {
              onParamChange(credentialParam.name, prevCredentialValue.current);
            }
            setIsUsingCredentialField(useCredentialField => !useCredentialField);
          }}
          options={[
            {
              label: CREDENTIAL_OPTION,
              value: CREDENTIAL_OPTION,
            },
            {
              label: CONSTANT_PARAMS_OPTION,
              value: CONSTANT_PARAMS_OPTION,
            },
          ]}
        />
      )}

      {credentialsInTpaEnabled && (isUsingCredentialField || !relatedParams || relatedParams.length === 0) && (
        <SingleParamRow
          param={credentialParam}
          key={0}
          shouldEnableOverriding={shouldEnableOverriding}
          values={values}
          readOnly={readOnly}
          onParamToggle={onParamToggle}
          onParamChange={onParamChange}
        />
      )}
      {(!isUsingCredentialField || !credentialsInTpaEnabled) &&
        relatedParams.map((param, key) => {
          return (
            <SingleParamRow
              param={param}
              key={key}
              shouldEnableOverriding={shouldEnableOverriding}
              values={values}
              readOnly={readOnly}
              onParamToggle={onParamToggle}
              onParamChange={onParamChange}
            />
          );
        })}
    </div>
  );
};
