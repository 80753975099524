import React, { FC, useContext, useState } from 'react';
import styled from '@emotion/styled';
import { BigidBody1, BigidDialog, BigidDialogProps, SecondaryButton, TertiaryButton } from '@bigid-ui/components';
import { useLocalTranslation } from '../../../translations';
import { AdvanceSettingsDialogContent } from '../../../ScanTemplates/ScanTemplateWizard/Steps/CorrelationStep/AdvancedSettingsDialog';
import { getScanTypeName, getScheduleGridCellValue } from '../../../ScanUtils';
import { HeaderData, LabelManagmentContentProps } from '../../ConvertScanProfileTypes';
import { BoxInfo } from './BoxInfo';
import { ConvertScanProfileContext } from '../convertScanProfileContext';
import { Divider } from '@mui/material';
import { generateDataAid } from '@bigid-ui/utils';
import { ScanTypes } from '../../../ScanTemplates/scanTemplateTypes';

const HeaderTitle = styled(BigidBody1)`
  font-size: 14px !important;
  font-weight: bold;
`;
const SubHeader = styled('div')`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 24px 0;
`;
const ProfileName = styled('div')`
  font-size: 16px;
  font-weight: bold;
`;

const SubHeaderData = styled('div')`
  display: flex;
  align-items: center;
  height: 40px;
  gap: 30px;
`;

const LabelManagmentContentContainer = styled('div')`
  display: flex;
  align-items: center;
  gap: 8px;
  height: 21px;
`;

const LabelManagmentText = styled(BigidBody1)`
  font-size: 14px !important;
  font-weight: 600;
`;

const LabelManagmentContent: FC<LabelManagmentContentProps> = ({ isOverwriteTag, isOverwritePrivilegedLabels }) => {
  const { t } = useLocalTranslation('convertScanProfile.header');
  return (
    <LabelManagmentContentContainer>
      {isOverwriteTag && <LabelManagmentText>{t('overwriteExistingLabels')}</LabelManagmentText>}
      {isOverwritePrivilegedLabels && (
        <>
          <Divider orientation="vertical" />
          <LabelManagmentText>{t('overwritePrivilegedLabels')}</LabelManagmentText>
        </>
      )}
    </LabelManagmentContentContainer>
  );
};

export const ConvertScanProfileOverviewHeader: FC = () => {
  const { scanProfileData, isForReview, scanType } = useContext(ConvertScanProfileContext);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const { t } = useLocalTranslation('convertScanProfile.header');

  const showAdvancedSettingsDialog = () => {
    setIsOpen(true);
  };

  const headerData: HeaderData[] = [
    {
      title: '',
      content: <ProfileName>{scanProfileData.name}</ProfileName>,
      hasDivider: true,
    },
    {
      title: t('scanType'),
      content: getScanTypeName(scanProfileData.scanType) || scanType,
      hasDivider: false,
    },
    {
      title: t('scheduled'),
      content: getScheduleGridCellValue(scanProfileData.schedule),
      hasDivider: false,
    },
    ...(scanProfileData.isOverwritePrivilegedLabels || scanProfileData.isOverwriteTag
      ? [
          {
            title: t('labelManagement'),
            content: (
              <LabelManagmentContent
                isOverwriteTag={scanProfileData.isOverwriteTag}
                isOverwritePrivilegedLabels={scanProfileData.isOverwritePrivilegedLabels}
              />
            ),
          },
        ]
      : []),
  ];

  const isShowAdvancedSettings = () => {
    return scanProfileData.scanType !== ScanTypes.METADATA_SCAN && scanProfileData.scanType !== ScanTypes.LABELING;
  };

  const dialogProps: BigidDialogProps = {
    isOpen,
    onClose: () => setIsOpen(false),
    title: t('advancedSettings'),
    buttons: [{ text: 'Close', onClick: () => setIsOpen(false), component: SecondaryButton }],
  };

  return (
    <>
      <div>
        {!isForReview && (
          <HeaderTitle data-aid={generateDataAid('ConvertScanProfileOverviewHeader', ['title'])}>
            {t('title')}
          </HeaderTitle>
        )}
        <SubHeader>
          <SubHeaderData>
            {headerData.map((headerRow, index) => (
              <BoxInfo key={index} {...headerRow} />
            ))}
          </SubHeaderData>
          {isShowAdvancedSettings() && (
            <TertiaryButton
              text={t('advancedSettings')}
              size="medium"
              onClick={showAdvancedSettingsDialog}
              dataAid={generateDataAid('TertiaryButton', ['advancedSettings'])}
            />
          )}
        </SubHeader>
      </div>
      <BigidDialog {...dialogProps}>
        <AdvanceSettingsDialogContent
          skipIdScan={scanProfileData.skipIdScan}
          isClassificationsAsPiiFindings={scanProfileData.isClassificationsAsPiiFindings}
          isReviewFindingsEnabled={scanProfileData.isReviewFindingsEnabled}
          isClassificationsAsPiiFindingsDisabled
          isClassifierTuningDisabled
          isCorrelationSetDisabled
          isEditMode={false}
        />
      </BigidDialog>
    </>
  );
};
