import React, { ChangeEvent, FC } from 'react';
import styled from '@emotion/styled';
import { BigidSelect, BigidSelectOption, BigidTextField, BigidButtonTypography } from '@bigid-ui/components';

const FormWrapper = styled('div')`
  margin-bottom: 18px;
`;

const LabelWrapper = styled('div')`
  display: flex;
  margin-bottom: 6px;
`;

const RequiredAsterik = styled('span')`
  color: ${({ theme }) => theme.palette.secondary.main};
  width: 10px;
  display: inline-block;
  text-align: center;
  margin-left: 2px;
`;

interface ConfigInputItemProps {
  title: string;
  field: string;
  onChange: (name: string, value: string) => void;
  hasError: boolean;
  value: string;
  isPassword: boolean;
  isRequired: boolean;
  options?: BigidSelectOption[];
  isMultiSelect?: boolean;
  subText?: string;
}
export const ConfigInputItem: FC<ConfigInputItemProps> = ({
  field,
  title,
  onChange,
  hasError,
  value,
  isPassword,
  isRequired,
  options = [],
  isMultiSelect = false,
  subText,
}: ConfigInputItemProps) => {
  const handleSelectChanged = (selected: BigidSelectOption[]) => {
    const { value } = selected[0];
    onChange(field, value);
  };

  const handleInputChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { value } = e.target;
    onChange(field, value);
  };

  return (
    <FormWrapper data-aid={title.replace(' ', '-')}>
      {isMultiSelect ? (
        <>
          <LabelWrapper>
            <BigidButtonTypography>{title}</BigidButtonTypography>
            {isRequired && <RequiredAsterik>*</RequiredAsterik>}
          </LabelWrapper>
          <BigidSelect
            name={field}
            value={options.filter(({ value: optionsValue }) => optionsValue === value)}
            options={options}
            onChange={handleSelectChanged}
          />
        </>
      ) : (
        <BigidTextField
          label={title}
          type={isPassword ? 'password' : 'text'}
          onChange={handleInputChange}
          defaultValue={value}
          autoComplete={'new-password'}
          isError={hasError}
          errorMessage={hasError ? `Please Enter ${title}` : null}
          required={isRequired}
          description={subText}
        />
      )}
    </FormWrapper>
  );
};
