import { httpService } from '../../../services/httpService';
import { HealthCheckGridRow, RestartScannerParams } from './types';
import { notificationService } from '../../../services/notificationService';

export const fetchHealthCheckServices = async () => {
  try {
    const { data } = await httpService.fetch<HealthCheckGridRow[]>(`health-checks/services-status`);
    return data;
  } catch (e) {
    notificationService.error('Failed to fetch health check');
    console.error(e);
  }
};

export const fetchTenantScannerHealthCheckServices = async () => {
  try {
    const { data } = await httpService.fetch<HealthCheckGridRow[]>('/scanners-health-check');
    return data;
  } catch (e) {
    notificationService.error('Failed to fetch health check');
    console.error(e);
  }
};

export const clearTenantScannerHealthCheckServices = async () => {
  try {
    const { data } = await httpService.delete<HealthCheckGridRow[]>(`/scanners-health-check`);
    return data;
  } catch (e) {
    notificationService.error('Failed to clear health check services');
    console.error(e);
  }
};

export const clearHealthCheckServices = async () => {
  try {
    const { data } = await httpService.delete<HealthCheckGridRow[]>(`health-checks/services-status`);
    return data;
  } catch (e) {
    notificationService.error('Failed to clear health check services');
    console.error(e);
  }
};

export const restartService = async ({ id, type }: RestartScannerParams) => {
  try {
    await httpService.post('restart-service', { id, type });
  } catch (e) {
    notificationService.error('Failed to restart service');
    console.error(e);
  }
};
