import React, { FC, useState } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import {
  BigidBody2,
  BigidColors,
  BigidHeading2,
  BigidPaper,
  BigidPasswordField,
  PrimaryButton,
} from '@bigid-ui/components';
import Overlay from '../../assets/images/updatePasswordOverlay.svg';
import Lock from '../../assets/images/updatePasswordLock.svg';
import { FormControl } from '@mui/material';
import {
  updatePassword,
  isPasswordStrengthValid,
  isStrongPasswordSecurityEnabled,
} from '../../services/passwordService';
import { pageHeaderService } from '../../../common/services/pageHeaderService';
import { goToRequestedPage } from '../Login/loginUtils';

const useStyles = makeStyles({
  wrapper: {
    height: '100%',
    width: '100%',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundImage: `url(${Overlay})`,
    backgroundPositionY: 174,
    padding: 70,
  },
  paperRoot: {
    display: 'flex',
    margin: 'auto',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    padding: '32px',
    position: 'relative',
    width: '368px',
    height: '455px',
    background: BigidColors.white,
    boxShadow: '0px 0px 5px rgba(0, 0, 0, 0.15)',
    borderRadius: '8px',
  },
  lock: {
    top: '-15.4%',
    left: '87.2%',
    right: '0%',
    width: 141,
    bottom: '0%',
    height: 171,
    position: 'absolute',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundImage: `url(${Lock})`,
  },
  titleWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignContent: 'flex-start',
  },
  title: {
    marginBottom: 10,
  },
  form: {
    width: '100%',
  },
  separator: {
    marginTop: 10,
    marginBottom: 0,
    width: '100%',
  },
  submitWrapper: {
    marginTop: 20,
    alignSelf: 'flex-end',
  },
});

const passwordsDontMatchErrorMessage = "Passwords don't match";
const passwordsStrengthErrorMessage = 'password does not meet the requirements';
const passwordRequirementsTitle =
  'Password must be at least 8 characters long and contain all of the following: lowercase, uppercase, numeric and special characters.';
const passwordNoRequirementsTitle = 'Choose a strong and secure password';

export const UpdatePassword: FC = () => {
  const { paperRoot, wrapper, titleWrapper, title, submitWrapper, separator, form, lock } = useStyles();
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [oldPassword, setOldPassword] = useState<string>('');
  const [newPassword, setNewPassword] = useState<string>('');
  const [repeatPassword, setRepeatPassword] = useState<string>('');

  const titleText = isStrongPasswordSecurityEnabled() ? passwordRequirementsTitle : passwordNoRequirementsTitle;

  const submitPasswordChange = async () => {
    try {
      setIsSaving(true);
      const isSuccess = await updatePassword({ oldPassword, newPassword });
      isSuccess && goToRequestedPage();
    } finally {
      setIsSaving(false);
    }
  };

  const isPasswordMatch = () => {
    return newPassword === repeatPassword;
  };

  const isNewPasswordValid = () => {
    if (!newPassword) {
      return true;
    }

    return isPasswordStrengthValid(newPassword);
  };

  const isSubmitValid = () => {
    return isPasswordStrengthValid(newPassword) && oldPassword && repeatPassword && isPasswordMatch();
  };

  pageHeaderService.setTitle({
    pageTitle: 'Update Password',
    showBackButton: false,
  });

  return (
    <div className={wrapper}>
      <BigidPaper classes={{ root: paperRoot }}>
        <div className={lock} />
        <div className={titleWrapper}>
          <BigidHeading2 className={title}>Update Password</BigidHeading2>
          <BigidBody2>{titleText}</BigidBody2>
        </div>
        <hr className={separator} />
        <form noValidate autoComplete="off" className={form}>
          <fieldset disabled={isSaving}>
            <FormControl fullWidth margin="normal">
              <BigidPasswordField
                touched={!!oldPassword}
                required
                label="Old Password"
                placeholder={'Type your old password'}
                value={oldPassword}
                onChange={event => setOldPassword(event.target.value)}
                autoComplete="new-password"
              />
            </FormControl>
            <FormControl fullWidth margin="normal">
              <BigidPasswordField
                required
                touched={!!newPassword}
                helperText={!isNewPasswordValid() && passwordsStrengthErrorMessage}
                label="New Password"
                placeholder={'Type your new password'}
                value={newPassword}
                onChange={event => setNewPassword(event.target.value)}
                autoComplete="new-password"
              />
            </FormControl>
            <FormControl fullWidth margin="normal">
              <BigidPasswordField
                required
                touched={!!repeatPassword}
                errorMessage={!isPasswordMatch() && passwordsDontMatchErrorMessage}
                label="Repeat Password"
                placeholder={'Type your new password again'}
                value={repeatPassword}
                onChange={event => setRepeatPassword(event.target.value)}
                autoComplete="new-password"
              />
            </FormControl>
          </fieldset>
        </form>
        <div className={submitWrapper}>
          <PrimaryButton
            size={'medium'}
            disabled={isSaving || !isSubmitValid()}
            onClick={submitPasswordChange}
            text="Done"
          />
        </div>
      </BigidPaper>
    </div>
  );
};
