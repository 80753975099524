import React, { FC, memo } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { BigidHeading3, BigidBody1, BigidColorsV2 } from '@bigid-ui/components';
import { BigidSearchIllustration } from '@bigid-ui/icons';

const useStyles = makeStyles({
  root: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    '&:not(:last-child)': {
      marginBottom: '8px',
    },
  },
  row: {
    '&:not(:last-child)': {
      marginBottom: '8px',
    },
  },
  header: {
    color: BigidColorsV2.purple[400],
  },
});

const dataAid = 'MetadataSearchFullNoResultsHandler';

export const MetadataSearchNoResultsHandler: FC = memo(() => {
  const classes = useStyles();

  return (
    <div className={classes.root} data-aid={dataAid}>
      <div className={classes.row}>
        <BigidSearchIllustration size="medium" />
      </div>
      <div className={classes.row}>
        <BigidHeading3 classes={{ root: classes.header }} data-aid={`${dataAid}-title`}>
          No results found
        </BigidHeading3>
      </div>
      <div className={classes.row}>
        <BigidBody1 data-aid={`${dataAid}-description`}>
          Try another search, or click the filter icon in the search box and use the predefined filters.
        </BigidBody1>
      </div>
    </div>
  );
});

MetadataSearchNoResultsHandler.displayName = 'MetadataSearchNoResultsHandler';
