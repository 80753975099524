import { OwnerFieldNameEnum } from '../../views/DataSources/DataSourceConfiguration/types';
import { ENTITY_TYPE } from '../../types/commentsTypes';
import { ObjectDiffEntity } from '../../views/DataCatalog/DataCatalogFollowedObjects/ObjectDiffTypes';
import { DateISO8601 } from '../../types/types';

export interface DSOwnerPayload {
  from: string;
  dsType: string;
  dsName: string;
  ownerType: OwnerFieldNameEnum;
}

export interface DSOwnerGeneralPayload {
  from: string;
  ownerId: string;
}

export interface MentionPayload {
  from: string;
  entityName: string;
  entityId: string;
  entityType?: ENTITY_TYPE;
}

export interface CollaborationCommentMentionPayload {
  from: string;
  action: string;
  deepLink?: string;
}

export enum NotificationType {
  MENTION = 'mention',
  DS_OWNER = 'dsOwner',
  DS_OWNER_GENERAL = 'dsOwnerGeneral',
  OBJECT_ISSUE = 'OBJECT_ISSUE',
  OBJECT_DIFF = 'OBJECT_DIFF',
  SCAN_SCHEDULED = 'SCAN_SCHEDULED',
  COLLABORATION_COMMENT_MENTION = 'collaborationCommentMention',
}

export interface NotificationResponse<T extends NotificationType = NotificationType, P = Record<string, any>> {
  id: string;
  type: T;
  isRead: boolean;
  from?: string;
  owner: string;
  entityId: string;
  createdAt: DateISO8601;
  payload?: P;
}

export type ObjectDiffNotification = NotificationResponse<NotificationType.OBJECT_DIFF, ObjectDiffEntity>;
