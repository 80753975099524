import {
  BigidClassifierTypeMetadataIcon,
  BigidClassifierTypeRegexIcon,
  BigidClassifierTypeCorrelationIcon,
  BigidAttribute2Icon,
} from '@bigid-ui/icons';
import { ClassifierType, CuratedAttributeType } from '../curationService';
import React from 'react';

export const getAttributeTypeAvatar = (attributeType: CuratedAttributeType | ClassifierType) => {
  const BigidClassificationIcon = () => {
    return <BigidClassifierTypeRegexIcon size="large" />;
  };
  const BigidClassificationMdIcon = () => {
    return <BigidClassifierTypeMetadataIcon size="large" />;
  };
  const BigidCorrelationIcon = () => {
    return <BigidClassifierTypeCorrelationIcon size="large" />;
  };

  switch (attributeType) {
    case CuratedAttributeType.CLASSIFIER:
      return { icon: BigidClassificationIcon, name: CuratedAttributeType.CLASSIFIER };
    case ClassifierType.MD:
      return { icon: BigidClassificationMdIcon, name: CuratedAttributeType.CLASSIFICATION_MD };
    case CuratedAttributeType.CORRELATION:
      return { icon: BigidCorrelationIcon, name: CuratedAttributeType.CORRELATION };
    case ClassifierType.DOC:
      return { icon: BigidAttribute2Icon, name: CuratedAttributeType.DOC_CLASSIFICATION };
    default:
      return { icon: BigidAttribute2Icon, name: 'Attribute' };
  }
};
