import React, { FC } from 'react';
import styled from '@emotion/styled';
import { bypassRegion, TertiaryButton, BigidNotes1 } from '@bigid-ui/components';
import { BigidArrowRightIcon } from '@bigid-ui/icons';
import { useLocalTranslation } from './translations';

const Wrapper = styled('div')(({ theme }) => ({
  padding: '8px',
  display: 'flex',
  flexDirection: 'column',
  gap: '8px',
  boxShadow: theme.vars.tokens.bigid.shadow30,
  background: theme.vars.palette.bigid.white,
  border: theme.vars.tokens.bigid.borderDefault,
  borderRadius: 4,
  position: 'fixed',
  zIndex: theme.vars.zIndex.modal,
  width: 150,
  left: -10000,
  top: 8,
  '&:focus-within': {
    left: 8,
  },
  '& button': {
    justifyContent: 'flex-start',
  },
}));

const Text = styled(BigidNotes1)({
  fontWeight: 700,
});

export const BypassMenu: FC = () => {
  const { t } = useLocalTranslation('BypassMenu');

  return (
    <Wrapper>
      <Text>{t('title')}</Text>
      <TertiaryButton
        size="small"
        text={t('skipButtons.sideNavigation')}
        onClick={() => {
          const isNavigationClosed = !!document.querySelector('[data-aid="BigidNavigationContainer-closed"]');

          if (isNavigationClosed) {
            bypassRegion('openNavigationMenuToggle-closed', 'data-aid');
          } else {
            bypassRegion('bypass-sidebar');
          }
        }}
        startIcon={<BigidArrowRightIcon />}
        dataAid={'skip-side-navigation-button'}
      />
      <TertiaryButton
        size="small"
        text={t('skipButtons.mainContent')}
        onClick={() => bypassRegion('bypass-content-container')}
        startIcon={<BigidArrowRightIcon />}
        dataAid={'skip-main-content-button'}
      />
    </Wrapper>
  );
};
