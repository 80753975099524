import './riskConfiguration.scss';
import { module } from 'angular';
import template from './riskConfiguration.component.html';
import { headerEventEmitter, HeaderEvents } from '../../react/services/eventEmitters/headerEvents';
import { isPermitted } from '../../react/services/userPermissionsService';
import { APPLICATIONS_PERMISSIONS } from '@bigid/permissions';
import { cloneDeep } from 'lodash';
import { openRiskCalculatorDialog } from '../../react/components/Risk/RiskCalculateDialog';
const app = module('app');

app
  .component('riskConfiguration', {
    template,
    controllerAs: 'riskConfigurationModel',
    controller: function (
      $scope,
      $translate,
      $rootScope,
      riskConfigurationService,
      $document,
      notificationService,
      $window,
      $timeout,
      riskTrendService,
    ) {
      'ngInject';

      const listenerLatestRiskScore = $rootScope.$on('LatestRiskScore', (event, latest_risk) => {
        riskConfigurationModel.latestRisk = Math.round(latest_risk);
      });

      const listenerGetQueryRiskScore = $rootScope.$on(
        'getQueryRiskScore',
        function (event, queryRiskScore, queryString, isRiskConfiguration) {
          riskTrendService.getRiskSnapShots(queryString, isRiskConfiguration).then(function (result) {
            $rootScope.$broadcast('LatestRiskScore', result.latest_risk);
          });
        },
      );

      $timeout(() => {
        $rootScope.$emit('getQueryRiskScore', $window.sessionStorage.getItem('globalRiskScore'));
      });

      $translate('RISK_CONFIGURATION').then(translation => {
        $timeout(() => {
          $rootScope.$broadcast('changePage', translation, false);
        });
      });

      const riskConfigurationModel = this;

      riskConfigurationModel.latestRisk = 0;
      riskConfigurationModel.distItemsContainerHeight = 0;
      riskConfigurationModel.distributionItems = {
        items: {
          residency: 0,
          attributes: 0,
          dataSources: 0,
          applications: 0,
        },
      };

      riskConfigurationModel.$onInit = () => {
        riskConfigurationModel.isManagePermitted = isPermitted(APPLICATIONS_PERMISSIONS.MANAGE_RISK_CONFIGURATION.name);
        riskConfigurationModel.showDistributionDiv = false;
        headerEventEmitter.emit(HeaderEvents.UPDATE_UNREAD_TASKS_AND_RISK);
        riskTrendService.clearGetRiskSnapShotsDataRequest();
        riskConfigurationModel.showCalculatedRisk = false;
        riskConfigurationModel.isSelected = false;
        riskConfigurationModel.updatedFields = {};
        riskConfigurationService
          .getRiskProfiles()
          .then(({ data: profiles }) => {
            if (!profiles.length) {
              console.warn('no risk profiles');
              return;
            }
            riskConfigurationModel.activeProfile = profiles.find(({ isActive }) => isActive);
            if (!riskConfigurationModel.activeProfile) {
              console.warn('no active profile');
              return;
            }
            riskConfigurationModel.activeProfile.selected = true;
            riskConfigurationModel.selectedProfile = riskConfigurationModel.activeProfile;
            setDistributionPanelHeight(riskConfigurationModel.activeProfile);
            riskConfigurationModel.riskProfiles = profiles;
            riskConfigurationModel.riskConfigurationName = riskConfigurationModel.activeProfile.name;
            riskConfigurationModel.isActive = true;
            riskConfigurationModel.currentlySelectedRiskConfiguration = riskConfigurationModel.activeProfile.name;
            riskConfigurationModel.currentlySelectedDistributionType = 'residency';
            setSelectedDistributionDiv('residency');
            riskConfigurationModel.CalibrationName = 'Residency';
            riskConfigurationModel.orderCalibrationRiskValue = 'asc';
            riskConfigurationModel.orderCalibrationRiskValueByName = 'asc';
            riskConfigurationModel.riskFieldsMap = {};
          })
          .then(() =>
            Promise.all(
              riskConfigurationModel.riskProfiles.map(({ id }) =>
                Promise.all([
                  riskConfigurationService.getRiskFields(id, 'residency'),
                  riskConfigurationService.getRiskFields(id, 'attributes'),
                  riskConfigurationService.getRiskFields(id, 'dataSources'),
                  riskConfigurationService.getRiskFields(id, 'applications'),
                ]),
              ),
            ),
          )
          .then(riskFieldsByProfile => {
            riskFieldsByProfile.forEach(riskFieldsProfile => {
              const id = riskFieldsProfile[0].data.riskProfileId;
              riskConfigurationModel.riskFieldsMap[id] = {};
              riskFieldsProfile.forEach(({ data: { componentName, fields } }) => {
                riskConfigurationModel.riskFieldsMap[id][componentName] = fields;
              });
            });
          })
          .then(() => {
            riskConfigurationModel.showDistributionDiv = true;
            riskConfigurationModel.calibrationData = getCalibrationFields('residency');
          });
      };

      riskConfigurationModel.selectRiskConfiguration = model => {
        riskConfigurationModel.currentlySelectedRiskConfiguration = model.name;
        setDistributionPanelHeight(model);
        riskConfigurationModel.riskConfigurationName = model.name;
        riskConfigurationModel.isActive = model.isActive;

        riskConfigurationModel.riskProfiles
          .filter(({ selected }) => selected)
          .forEach(profile => delete profile.selected);
        model.selected = true;
        riskConfigurationModel.selectedProfile = model;
        riskConfigurationModel.calibrationData = getCalibrationFields(
          riskConfigurationModel.currentlySelectedDistributionType,
        );
      };

      riskConfigurationModel.selectDistributionObject = distributionType => {
        setSelectedDistributionDiv(distributionType);
        riskConfigurationModel.currentlySelectedDistributionType = distributionType;
        riskConfigurationModel.selected_distribution_div = true;
        riskConfigurationModel.calibrationData = getCalibrationFields(distributionType);
        riskConfigurationModel.orderCalibrationRiskValue = 'asc';
        riskConfigurationModel.orderCalibrationRiskValueByName = 'asc';
        orderCalibrationRiskByName();
        const calibrationTableElem = document.getElementById('calibrationTable');
        if (calibrationTableElem) {
          calibrationTableElem.scrollTop = 0;
        }
      };

      riskConfigurationModel.selectCalibrationCell = (level, model, riskConfigurationModel) => {
        if (!riskConfigurationModel.isManagePermitted) {
          return;
        }

        const riskConfigurationData = getRiskConfigurationData();
        riskConfigurationService.calcRisk(riskConfigurationData).then(result => {
          riskConfigurationModel.showCalculatedRisk = true;
          riskConfigurationModel.calculatedRisk = Math.round(result.risk);
        });

        if (model.weight > level || model.weight < level) {
          riskConfigurationModel.isSelected = true;
        }
        model.weight = level;
        addFieldToUpdatedObject(model);
      };

      riskConfigurationModel.orderCalibrationRiskClicked = () => {
        orderCalibrationRisk();
      };

      riskConfigurationModel.orderCalibrationRiskByNameClicked = () => {
        orderCalibrationRiskByName();
      };

      riskConfigurationModel.openRiskCalculatorDialog = () => openRiskCalculatorDialog();

      riskConfigurationModel.saveRiskConfiguration = () => {
        const selectedProfileId = riskConfigurationModel.selectedProfile.id;
        const components = riskConfigurationModel.selectedProfile.components;
        riskConfigurationService.saveLastSnapshot({ risk_configurations: [getRiskConfigurationData()] });
        riskConfigurationService
          .updateRiskProfile(riskConfigurationModel.selectedProfile.id, {
            name: riskConfigurationModel.selectedProfile.name,
            residencyWeight: components.find(({ name }) => name === 'residency').weight,
            attributesWeight: components.find(({ name }) => name === 'attributes').weight,
            dataSourcesWeight: components.find(({ name }) => name === 'dataSources').weight,
            applicationsWeight: components.find(({ name }) => name === 'applications').weight,
          })
          .then(() => {
            const updateFieldsCall = [];
            for (const [type, fields] of Object.entries(
              riskConfigurationModel.updatedFields[selectedProfileId] ?? {},
            )) {
              for (const [name, field] of Object.entries(fields)) {
                const func = () => {
                  return riskConfigurationService
                    .updateRiskField(selectedProfileId, type, {
                      fieldName: field.name,
                      weight: field.weight,
                    })
                    .then(() => new Promise(resolve => setTimeout(resolve, 100)));
                };
                updateFieldsCall.push(func());
              }
            }
            return Promise.all(updateFieldsCall);
          })
          .then(() => clearFieldsBySelectedId())
          .then(() => {
            riskConfigurationModel.latestRisk = riskConfigurationModel.calculatedRisk;
            riskConfigurationModel.showCalculatedRisk = false;
            riskConfigurationModel.isSelected = false;
            $rootScope.$emit('getQueryRiskScore', riskConfigurationModel.latestRisk, null, true);
            notificationService.success('Updated Successfully! ');
          });
      };

      riskConfigurationModel.onResize = data => {
        Object.keys(data).map(item => {
          const modelProp = item.charAt(0).toUpperCase() + item.slice(1);
          riskConfigurationModel[modelProp + 'Precentage'] = item == 'applications' ? 0 : data[item]['percentage'];
          riskConfigurationModel.selectedProfile.components
            .filter(({ name }) => name === item)
            .forEach(component => (component.weight = data[item]['percentage']));
        });

        if (!riskConfigurationModel.isSelected) {
          riskConfigurationModel.isSelected = true;
        }
      };

      riskConfigurationModel.onResized = () => {
        const riskConfigurationData = getRiskConfigurationData();

        riskConfigurationService.calcRisk(riskConfigurationData).then(result => {
          riskConfigurationModel.showCalculatedRisk = true;
          riskConfigurationModel.calculatedRisk = Math.round(result.risk);
        });
      };

      const orderCalibrationRisk = () => {
        const currentlySelectedDistributionType = riskConfigurationModel.currentlySelectedDistributionType;
        if (riskConfigurationModel.calibrationData && riskConfigurationModel.calibrationData.length > 0) {
          if (riskConfigurationModel.orderCalibrationRiskValue === 'asc') {
            riskConfigurationModel.calibrationData = getCalibrationFields(currentlySelectedDistributionType);
            riskConfigurationModel.calibrationData.sort(compareAsc);
            riskConfigurationModel.orderCalibrationRiskValue = 'desc';
          } else {
            riskConfigurationModel.calibrationData.sort(compareDesc);
            riskConfigurationModel.orderCalibrationRiskValue = 'asc';
          }
        }
      };

      function addFieldToUpdatedObject(field) {
        let updatedFieldsId = riskConfigurationModel.updatedFields[riskConfigurationModel.selectedProfile.id];
        if (!updatedFieldsId) {
          riskConfigurationModel.updatedFields[riskConfigurationModel.selectedProfile.id] = {};
          updatedFieldsId = riskConfigurationModel.updatedFields[riskConfigurationModel.selectedProfile.id];
        }
        let updatedFieldsByType = updatedFieldsId[riskConfigurationModel.currentlySelectedDistributionType];
        if (!updatedFieldsByType) {
          updatedFieldsId[riskConfigurationModel.currentlySelectedDistributionType] = {};
          updatedFieldsByType = updatedFieldsId[riskConfigurationModel.currentlySelectedDistributionType];
        }
        updatedFieldsByType[field.name] = field;
      }

      function clearFieldsBySelectedId() {
        riskConfigurationModel.updatedFields[riskConfigurationModel.selectedProfile.id] = {};
      }

      const getRiskConfigurationData = () => {
        const riskConfigurationObject = cloneDeep(riskConfigurationModel.selectedProfile);
        riskConfigurationObject.active = riskConfigurationModel.selectedProfile.isActive;
        delete riskConfigurationObject.isActive;
        riskConfigurationObject.components.find(({ name }) => name === 'residency').fields =
          riskConfigurationModel.riskFieldsMap[riskConfigurationModel.selectedProfile.id]['residency'];
        riskConfigurationObject.components.find(({ name }) => name === 'dataSources').fields =
          riskConfigurationModel.riskFieldsMap[riskConfigurationModel.selectedProfile.id]['dataSources'];
        riskConfigurationObject.components.find(({ name }) => name === 'dataSources').name = 'systems';
        riskConfigurationObject.components.find(({ name }) => name === 'attributes').fields =
          riskConfigurationModel.riskFieldsMap[riskConfigurationModel.selectedProfile.id]['attributes'];
        riskConfigurationObject.components.find(({ name }) => name === 'applications').fields =
          riskConfigurationModel.riskFieldsMap[riskConfigurationModel.selectedProfile.id]['applications'];
        return riskConfigurationObject;
      };

      const orderCalibrationRiskByName = () => {
        const currentlySelectedDistributionType = riskConfigurationModel.currentlySelectedDistributionType;
        if (riskConfigurationModel.calibrationData && riskConfigurationModel.calibrationData.length > 0) {
          if (riskConfigurationModel.orderCalibrationRiskValueByName === 'asc') {
            riskConfigurationModel.calibrationData = getCalibrationFields(currentlySelectedDistributionType);
            riskConfigurationModel.calibrationData.sort(compareDescByName);
            riskConfigurationModel.orderCalibrationRiskValueByName = 'desc';
          } else {
            riskConfigurationModel.calibrationData.sort(compareAscByName);
            riskConfigurationModel.orderCalibrationRiskValueByName = 'asc';
          }
        }
      };

      const compareDescByName = (a, b) => {
        if (a.name.toLowerCase() < b.name.toLowerCase()) return -1;
        if (a.name.toLowerCase() > b.name.toLowerCase()) return 1;
        return 0;
      };

      const compareAscByName = (a, b) => {
        if (a.name.toLowerCase() > b.name.toLowerCase()) return -1;
        if (a.name.toLowerCase() < b.name.toLowerCase()) return 1;
        return 0;
      };

      const compareDesc = (a, b) => {
        if (a.weight < b.weight) return -1;
        if (a.weight > b.weight) return 1;
        return 0;
      };

      const compareAsc = (a, b) => {
        if (a.weight > b.weight) return -1;
        if (a.weight < b.weight) return 1;
        return 0;
      };

      const setSelectedDistributionDiv = distributionType => {
        switch (distributionType) {
          case 'residency':
            riskConfigurationModel.CalibrationName = 'Residency';
            riskConfigurationModel.selected_residency_div = true;
            riskConfigurationModel.selected_attributes_div = false;
            riskConfigurationModel.selected_dataSources_div = false;
            riskConfigurationModel.selected_applications_div = false;
            break;
          case 'attributes':
            riskConfigurationModel.CalibrationName = 'Attributes';
            riskConfigurationModel.selected_residency_div = false;
            riskConfigurationModel.selected_attributes_div = true;
            riskConfigurationModel.selected_dataSources_div = false;
            riskConfigurationModel.selected_applications_div = false;
            break;
          case 'dataSources':
            riskConfigurationModel.CalibrationName = 'DataSources';
            riskConfigurationModel.selected_residency_div = false;
            riskConfigurationModel.selected_attributes_div = false;
            riskConfigurationModel.selected_dataSources_div = true;
            riskConfigurationModel.selected_applications_div = false;
            break;
          case 'applications':
            riskConfigurationModel.CalibrationName = 'Assets';
            riskConfigurationModel.selected_residency_div = false;
            riskConfigurationModel.selected_attributes_div = false;
            riskConfigurationModel.selected_dataSources_div = false;
            riskConfigurationModel.selected_applications_div = true;
            break;
        }
      };

      const getCalibrationFields = distributionType =>
        riskConfigurationModel.riskFieldsMap[riskConfigurationModel.selectedProfile.id][distributionType];

      const setDistributionPanelHeight = model => {
        // Filter out the appropriate one
        riskConfigurationModel.ResidencyPrecentage = model.components.filter(v => v.name === 'residency')[0].weight;
        riskConfigurationModel.AttributesPrecentage = model.components.filter(v => v.name === 'attributes')[0].weight;
        riskConfigurationModel.DataSourcesPrecentage = model.components.filter(v => v.name === 'dataSources')[0].weight;
        riskConfigurationModel.ApplicationsPrecentage = model.components.filter(
          v => v.name === 'applications',
        )[0].weight;

        const _residencyHeight =
          riskConfigurationModel.ResidencyPrecentage > 10
            ? riskConfigurationModel.ResidencyPrecentage
            : 10 + riskConfigurationModel.ResidencyPrecentage;

        const _attributesHeight =
          riskConfigurationModel.AttributesPrecentage > 10
            ? riskConfigurationModel.AttributesPrecentage
            : 10 + riskConfigurationModel.AttributesPrecentage;

        const _dataSourcesHeight =
          riskConfigurationModel.DataSourcesHeight > 10
            ? riskConfigurationModel.DataSourcesPrecentage
            : 10 + riskConfigurationModel.DataSourcesPrecentage;

        const _applicationsHeight =
          riskConfigurationModel.ApplicationsPrecentage > 10
            ? riskConfigurationModel.ApplicationsPrecentage
            : 10 + riskConfigurationModel.ApplicationsPrecentage;

        const coefficient = 100 / (_applicationsHeight + _dataSourcesHeight + _attributesHeight + _residencyHeight);

        riskConfigurationModel.ResidencyHeight = _residencyHeight * coefficient;
        riskConfigurationModel.AttributesHeight = _attributesHeight * coefficient;
        riskConfigurationModel.DataSourcesHeight = _dataSourcesHeight * coefficient;
        riskConfigurationModel.ApplicationsHeight = _applicationsHeight * coefficient;

        riskConfigurationModel.distributionItems = {
          items: {
            residency: riskConfigurationModel.ResidencyHeight,
            attributes: riskConfigurationModel.AttributesHeight,
            dataSources: riskConfigurationModel.DataSourcesHeight,
            applications: riskConfigurationModel.ApplicationsHeight,
          },
        };
      };

      //Unregister listeners
      $scope.$on('$destroy', function () {
        listenerLatestRiskScore();
        listenerGetQueryRiskScore();
        riskConfigurationModel.riskFieldsMap = undefined;
        riskConfigurationModel.updatedFields = undefined;
      });
    },
    bindings: {},
  })
  .directive('resizable', ($timeout, $q) => {
    'ngInject';
    return {
      restrict: 'A',
      scope: {
        resize: '&onResize',
        resized: '&onResized',
        items: '=items',
      },
      link: (scope, elem, attrs) => {
        $timeout(() => {
          const configItems = scope.items.items,
            maxHeight = elem[0].offsetHeight;

          const items = [];
          const currentHandler = {
            obj: null,
            startY: 0,
            startOffset: 0,
          };

          let itemMaxHeight = 0,
            itemMinHeight = 0;

          const appendHandler = (ref, offsetTop, prev, next) => {
            const el = document.createElement('div');

            // temporarily disabled
            el.className = next == 'applications' ? 'disabled resize-handler' : 'resize-handler';

            el.style.top = offsetTop + 'px';
            el.setAttribute('prev', prev);
            el.setAttribute('next', next);

            return elem[0].insertBefore(el, ref);
          };

          const calculatePercentage = data => {
            return $q(resolve => {
              const prev = currentHandler.obj.getAttribute('prev');
              const next = currentHandler.obj.getAttribute('next');
              let sum = 0;

              Object.keys(data).map((id, index, arr) => {
                const percentage =
                  (100 * (data[id]['obj']['offsetHeight'] - itemMinHeight)) / (maxHeight - arr.length * itemMinHeight);

                data[id]['percentage'] =
                  percentage < 1
                    ? 0
                    : sum + Math.round(percentage) >= 100
                    ? Math.round(100 - sum)
                    : Math.round(percentage);

                sum = sum + data[id]['percentage'];
              });

              if (sum < 100) {
                if (next != 'applications' && data[next]['percentage'] == 0) data[prev]['percentage'] += 100 - sum;
                else if (next != 'applications' && data[prev]['percentage'] == 0) data[next]['percentage'] += 100 - sum;
                else data[prev]['percentage'] += 100 - sum;
              }

              resolve(data);
            });
          };

          const initMovement = e => {
            e.stopPropagation();

            // temporarily disabled
            if (e.target.getAttribute('next') == 'applications') return;

            currentHandler.startY = e.clientY;
            currentHandler.obj = e.target;
            currentHandler.startOffset = e.target.style.top.replace('px', '');

            const itemIndexPrev = currentHandler.obj.getAttribute('prev'),
              itemIndexNext = currentHandler.obj.getAttribute('next');

            items[itemIndexPrev]['startHeight'] = items[itemIndexPrev]['obj']['offsetHeight'];
            items[itemIndexNext]['startHeight'] = items[itemIndexNext]['obj']['offsetHeight'];

            document.documentElement.addEventListener('mousemove', startMovement, false);
            document.documentElement.addEventListener('mouseup', stopMovement, false);

            document.documentElement.removeEventListener('mousedown ', initMovement, false);
          };

          const startMovement = e => {
            const itemIndexPrev = currentHandler.obj.getAttribute('prev'),
              itemIndexNext = currentHandler.obj.getAttribute('next'),
              delta = e.clientY - currentHandler.startY;

            const prevItemNewHeight = items[itemIndexPrev]['startHeight'] + delta,
              nextItemNewHeight = items[itemIndexNext]['startHeight'] - delta;

            if (
              prevItemNewHeight > itemMaxHeight ||
              prevItemNewHeight < itemMinHeight ||
              nextItemNewHeight < itemMinHeight ||
              nextItemNewHeight > itemMaxHeight
            )
              return;

            if (delta > 0) {
              // down
              currentHandler.obj.style.top = Number(currentHandler.startOffset) + Math.abs(delta) + 'px';
            } else if (delta < 0) {
              // up
              currentHandler.obj.style.top = Number(currentHandler.startOffset) - Math.abs(delta) + 'px';
            }

            items[itemIndexPrev]['obj']['style']['height'] = prevItemNewHeight + 'px';
            items[itemIndexNext]['obj']['style']['height'] = nextItemNewHeight + 'px';

            calculatePercentage(items).then(data => {
              scope.resize({ data });
            });
          };

          const stopMovement = () => {
            document.documentElement.removeEventListener('mousemove', startMovement, false);
            document.documentElement.removeEventListener('mouseup', stopMovement, false);

            scope.resized();
          };

          if (elem) {
            const idx = Object.keys(configItems);
            let offset = 0,
              heights = [];

            idx.map((id, index, arr) => {
              const itemObj = elem[0].querySelector('#' + id),
                percentage = configItems[id];

              let handler;

              items[id] = {
                obj: itemObj,
                startHeight: (maxHeight * configItems[id]) / 100,
                percentage,
              };

              heights = [...heights, items[id]['startHeight']];

              if (index > 0) {
                offset += items[idx[index - 1]]['startHeight'];

                handler = appendHandler(itemObj, offset, idx[index - 1], id);

                handler.addEventListener('mousedown', initMovement, false);
              }
            });

            itemMinHeight = Math.min(...heights);
            itemMaxHeight = maxHeight - itemMinHeight;
          }
        }, 0);
      },
    };
  });
