import styled from '@emotion/styled';

export const AutoDiscoveryPresetConfigContainer = styled.div`
    display: flex;
    height: calc(100% - 128px);
'& > form': {
    width: 100%;
}
'& .FormCertificateField > div': {
    width: 100%;
'& label': {
    min-width: 250px;
}
`;

export const AutoDiscoveryPresetConfigFormWrapper = styled.div`
  padding: 0 0 20px 0;
`;

export const DiscoveryTitleIconWrapper = styled.div`
  width: 32px;
  height: 32px;
  border-radius: 6px;
  background: ${({ theme }) => `${theme.vars.palette.bigid.white}`};
  border: ${({ theme }) => theme.vars.tokens.bigid.borderDefault};
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 4px;
`;

export const AutoDiscoveryPresetDetailsTabStyled = styled.div`
  display: ${({ visible }: { visible: boolean }) => (visible ? `grid` : 'none ')};
  gap: 16px 0;
`;

export const AutoDiscoveryPresetDetailsFieldsWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const AutoDiscoveryPresetConfigLogsTabStyledWrapper = styled.div`
  margin: 4px;
  display: flex;
  flex-flow: column;
  height: calc(100vh - 250px);
`;
