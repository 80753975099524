import React, { FC, useRef, useState } from 'react';
import {
  BigidLink,
  BigidPrimaryCheckbox,
  BigidForm,
  BigidFormProps,
  BigidFormValues,
  BigidFormStateAndHandlers,
  BigidFormFieldTypes,
  BigidTooltip,
} from '@bigid-ui/components';
import { BigidInfoIcon } from '@bigid-ui/icons';
import makeStyles from '@mui/styles/makeStyles';
import { AzurePoliciesSelection } from './hooks/useAzurePolicyGenerator';
import { docsUrls } from '../../../../../../../../config/publicUrls';
import { useLocalTranslation } from '../../../../../../translations';
import { trackFmsdEvent, FmsdTrackingEvents } from '../../../../../../fmsdEventsTrackerUtils';
import { CloudProvider } from '../../../../../../../AutoDiscoveryWizard/autoDiscoveryWizardServices';

const useStyles = makeStyles({
  inputLabel: {
    paddingLeft: 0,
    fontSize: 14,
    lineHeight: '16px',
    alignItems: 'center',
    display: 'flex',
  },
  dialogText: {
    paddingLeft: 0,
    fontSize: 14,
  },
  checkboxContainer: {
    paddingTop: 8,
    flexDirection: 'column',
    display: 'flex',
  },
});

export interface AzureInfoDialogProps {
  policiesSelection: AzurePoliciesSelection[];
  onPolicySelection: (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => void;
  updateSubscriptionId: (newId: string) => void;
}

export const AzurePolicyGenerator: FC<AzureInfoDialogProps> = ({
  policiesSelection,
  onPolicySelection,
  updateSubscriptionId,
}) => {
  const { inputLabel, dialogText, checkboxContainer } = useStyles({});
  const [isIdEntered, setIsIdEntered] = useState(false);
  const formControls = useRef<BigidFormStateAndHandlers>();
  const { t } = useLocalTranslation('Connect.Dialog');

  const onChange = (values: BigidFormValues) => {
    const { subscriptionId } = values;
    updateSubscriptionId(subscriptionId);
    setIsIdEntered(!!subscriptionId);
  };

  const formProps: BigidFormProps = {
    initialValues: {
      subscriptionId: '',
    },
    stateAndHandlersRef: formControls,
    onChange,
    controlButtons: false,
    fields: [
      {
        name: 'subscriptionId',
        isRequired: true,
        labelWidth: '150px',
        type: BigidFormFieldTypes.PASSWORD,
        misc: {
          showValueOnlyIfDirty: true,
        },
      },
    ],
  };

  return (
    <div>
      <div className={inputLabel}>
        {t('azure.policyGenerator.idInputLabel')}{' '}
        <BigidTooltip title={t('azure.policyGenerator.tooltipText')} placement="top">
          <span style={{ paddingLeft: 8 }}>
            <BigidInfoIcon />
          </span>
        </BigidTooltip>
      </div>
      <BigidForm {...formProps} />
      {isIdEntered && (
        <>
          <span className={dialogText}>{t('azure.policyGenerator.selectDSText')}</span> <br />
          <div className={checkboxContainer}>
            {policiesSelection.map(({ label, value, isSelected, isDisabled }, index) => {
              return (
                <BigidPrimaryCheckbox
                  key={index}
                  onChange={onPolicySelection}
                  checked={isSelected}
                  label={label}
                  value={value}
                  disabled={isDisabled}
                  dataAid={`fmsdAzureInfoDialog-checkbox-${value}${isSelected ? '-selected' : ''}${
                    isDisabled ? '-disabled' : ''
                  }`}
                />
              );
            })}
          </div>
          <div style={{ paddingTop: 16 }} className={dialogText}>
            {t('azure.policyGenerator.infoText')}{' '}
            <BigidLink
              text={t('learnMoreLinkText')}
              href={docsUrls.INSTALLING_AZURE_INTEGRATION_APPLICATION}
              shouldOpenNewTab={true}
              onClick={() => {
                trackFmsdEvent(FmsdTrackingEvents.FMSD_CONNECT_POLICY_GENERATOR_LEARN_MORE_CLICK, {
                  [FmsdTrackingEvents.DS_TYPE]: CloudProvider.AZURE,
                });
              }}
            />
          </div>
        </>
      )}
    </div>
  );
};
