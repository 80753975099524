import { useQuery, UseQueryOptions, QueryFunction, UseQueryResult, QueryKey } from 'react-query';
import { getLatestErrorResolutionForDs } from '../../../../services/dataSourcesService';
import type { ErrorType } from '../../../../config/request';
import type { AsyncReturnType } from '../types';

export type GetErrorResolutionQueryError = ErrorType<unknown>;

export const getErrorResolutionQueryKey = (dataSourceId: string, isOpen: boolean): Array<unknown> => [
  'dsErrorResolution',
  dataSourceId,
  isOpen,
];

export const useGetErrorResolution = <
  TData = AsyncReturnType<typeof getLatestErrorResolutionForDs>,
  TError = GetErrorResolutionQueryError,
>(
  dataSourceId: string,
  isOpen: boolean,
  options?: { query?: UseQueryOptions<AsyncReturnType<typeof getLatestErrorResolutionForDs>, TError, TData> },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options || {};

  const queryKey = queryOptions?.queryKey ?? getErrorResolutionQueryKey(dataSourceId, isOpen);
  const queryFn: QueryFunction<AsyncReturnType<typeof getLatestErrorResolutionForDs>> = async () =>
  getLatestErrorResolutionForDs(dataSourceId);
  const query = useQuery<AsyncReturnType<typeof getLatestErrorResolutionForDs>, TError, TData>(queryKey, queryFn, {
    ...queryOptions,
  });

  return {
    queryKey,
    ...query,
  };
};
