import { module } from 'angular';
const app = module('app');

app.factory('activeDirectoryService', [
  '$http',
  'appSettings',
  function ($http, appSettings) {
    return {
      getActiveDirectoryData: () =>
        $http.get(appSettings.serverPath + '/' + appSettings.version + '/activeDirectory').then(response => response),
      saveActiveDirectoryData: data =>
        $http
          .post(appSettings.serverPath + '/' + appSettings.version + '/activeDirectory/', data)
          .then(response => response),
      updateActiveDirectoryData: (data, id) =>
        $http
          .put(appSettings.serverPath + '/' + appSettings.version + '/activeDirectory/' + id, data)
          .then(response => response),
      testActiveDirectoryConnection: data =>
        $http
          .post(appSettings.serverPath + '/' + appSettings.version + '/activeDirectory/testConnection', data)
          .then(response => response),
      postObjectOwner: adData =>
        $http
          .post(appSettings.serverPath + '/' + appSettings.version + '/object-owner/', adData)
          .then(response => response)
          .catch(err => err),
    };
  },
]);
