import React, { FC, useCallback, useMemo } from 'react';
import { BigidGuidedTour } from '@bigid-ui/guided-tour';
import { curetionGuidTourSteps } from './CurationGuidedTourSteps';
import { CurationGuidedTourStageId, CurationGuidedTourStageStatus } from '../useCurationState';

export interface CatalogDiscoveryGuidedTourProps {
  dataAid?: string;
  guidedTourStatus: Record<CurationGuidedTourStageId, CurationGuidedTourStageStatus>;
  onGuidedTourStageFinished: (currentGuidedTour: CurationGuidedTourStageId) => void;
  currentGuidedTour: CurationGuidedTourStageId;
}

export const CurationGuidedTour: FC<CatalogDiscoveryGuidedTourProps> = ({
  guidedTourStatus,
  onGuidedTourStageFinished,
  currentGuidedTour,
}) => {
  const shouldTourRun = useMemo(() => {
    return [
      CurationGuidedTourStageStatus.STARTED_AUTOMATICALLY,
      CurationGuidedTourStageStatus.STARTED_MANUALLY,
    ].includes(guidedTourStatus[currentGuidedTour]);
  }, [currentGuidedTour, guidedTourStatus]);

  const joyrideCallback = useCallback(() => {
    onGuidedTourStageFinished(currentGuidedTour);
  }, [currentGuidedTour, onGuidedTourStageFinished]);

  return (
    <BigidGuidedTour
      joyrideCallback={joyrideCallback}
      run={shouldTourRun}
      scrollOffset={350}
      steps={curetionGuidTourSteps(currentGuidedTour)}
      disableCloseOnEsc
      disableOverlayClose
      disableScrollParentFix
    />
  );
};
