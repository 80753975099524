import { httpService } from '../../../services/httpService';
import { BigidGridRow } from '@bigid-ui/grid';

export enum GroupType {
  EMAIL = 'Email',
  PROJECT = 'Project',
  FEDERATED = 'Federated',
  GLOBAL = 'Global',
  OTHER = 'Other',
  SERVICE = 'Service',
  ORGANIZATION = 'Organization',
}

export interface GroupRecord {
  _id: string;
  id: string;
  name: string;
  sharedObjectsCount: number;
  dataSource: string;
  remediationTask: {
    taskId?: string;
    status?: 'open' | 'resolved';
  };
  membersCount?: number;
  external?: boolean;
  email?: string;
  type?: GroupType;
}

interface GroupsResponse {
  data: {
    groups: GroupRecord[];
    totalCount: number;
  };
}

export interface GroupMember extends BigidGridRow {
  name: string;
  email: string;
  external?: boolean;
  dataSource: string;
  entityType?: string;
}

interface GroupMembersResponse {
  data: {
    result: GroupMember[];
    totalCount: number;
  };
}

export interface GroupsRemediationSummary {
  data: {
    created: number;
    updated: number;
    skipped: number;
    dsWithNoOwner: string[];
  };
}

const generateGroupMembersQuery = (groupName: string, groupEmail: string, gridQuery: string) => {
  const encodedName = encodeURIComponent(groupName);
  return `${gridQuery}&name=${encodedName}&email=${groupEmail}`;
};

export const getGroup = (groupId: string) => {
  return httpService.fetch<{ data: GroupRecord }>(`aci/groups/${groupId}`).then(({ data }) => data.data);
};

export const getGroups = (query: string) => {
  return httpService.fetch<GroupsResponse>(`aci/groups/?${query}`).then(({ data }) => data);
};

export const getGroupMembers = (groupName: string, groupEmail: string, gridQuery: string) => {
  const query = generateGroupMembersQuery(groupName, groupEmail, gridQuery);
  return httpService.fetch<GroupMembersResponse>(`aci/ad/groups/members?${query}`).then(({ data }) => data.data);
};

export const remediateParticularGroups = (groupIds: string[]) => {
  return httpService
    .post<GroupsRemediationSummary, { ids: string[] }>(`aci/groups/remediation`, {
      ids: groupIds,
    })
    .then(({ data }) => data.data);
};
