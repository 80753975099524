import React, { FC } from 'react';
import { useLocalTranslation } from '../translations';
import { CatalogDiscoveryGuidedTourStep } from '../CatalogDiscoveryGuidedTourStep';

interface ActionsProps {
  dataAid: string;
}

export const Actions: FC<ActionsProps> = ({ dataAid }) => {
  const { t } = useLocalTranslation('steps.Actions');

  return <CatalogDiscoveryGuidedTourStep dataAid={dataAid} body={t('body')} />;
};
