import React, { FC, ReactNode } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import BackArrowIcon from '../../../assets/icons/BigidBackArrow.svg';
import { BigidBreadcrumbs, Breadcrumb, BigidTooltip } from '@bigid-ui/components';
import { generateDataAid } from '@bigid-ui/utils';
import { Widget, BigidTitleWidget } from './BigidTitleWidget';
import { BigidPageFavoriteToggler } from './BigidPageFavoriteToggler';

export interface BigidPageTitleProps {
  title: string;
  titleHelperComponent?: ReactNode;
  onBackButtonClick?: () => void;
  widgets?: Widget[];
  breadcrumbs: Breadcrumb[];
  rightSideComponentsContainer?: ReactNode;
}

const useStyles = makeStyles(theme => ({
  root: {
    margin: '10px 24px',
    height: '82px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  bar: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: '50px',
    overflow: 'hidden',
  },
  wrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    alignContent: 'center',
    gap: '10px',
  },
  rightSide: {
    display: 'flex',
    alignItems: 'center',
  },
  title: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    fontSize: '1.375rem',
    lineHeight: '1.167',
    fontWeight: 600,
    color: theme.vars.tokens.bigid.foregroundPrimary,
    margin: 0,
    marginRight: '4px',
  },
  backButton: {
    stroke: theme.vars.tokens.bigid.foregroundPrimary,
  },
  backArrowWrapper: {
    cursor: 'pointer',
    '&:hover': {
      borderColor: theme.vars.palette.bigid.gray900,
      '&:hover $backButton': {
        stroke: theme.vars.palette.bigid.gray900,
      },
    },
  },
  breadcrumbsWrapper: {
    maxWidth: '864px',
    overflow: 'hidden',
    display: 'flex',
    alignItems: 'center',
  },
  helperComponentWrapper: {
    display: 'flex',
    alignItems: 'center',
  },
}));

export const BigidPageTitle: FC<BigidPageTitleProps> = ({
  title,
  onBackButtonClick,
  widgets,
  breadcrumbs = [],
  rightSideComponentsContainer,
  titleHelperComponent,
}) => {
  const pageTitleBreadcrumbs: Breadcrumb[] = [...breadcrumbs];
  const classes = useStyles({});

  if (!pageTitleBreadcrumbs.length) {
    pageTitleBreadcrumbs.push({ label: title });
  }

  const lastBreadcrumbsItem = pageTitleBreadcrumbs[pageTitleBreadcrumbs.length - 1];

  lastBreadcrumbsItem.dataAid = 'pageTitle';

  return (
    <div className={classes.root}>
      {pageTitleBreadcrumbs?.length > 1 && <BigidBreadcrumbs breadcrumbs={pageTitleBreadcrumbs} />}
      <div className={classes.bar}>
        <div>
          <div className={classes.wrapper}>
            {onBackButtonClick && (
              <BigidTooltip title="Back">
                <div
                  className={classes.backArrowWrapper}
                  onClick={onBackButtonClick}
                  data-aid="BigidPageTitleBackButton"
                >
                  <BackArrowIcon className={classes.backButton} />
                </div>
              </BigidTooltip>
            )}
            <div className={classes.breadcrumbsWrapper}>
              {!!pageTitleBreadcrumbs?.length && (
                <h1
                  data-aid={generateDataAid(lastBreadcrumbsItem.dataAid, [lastBreadcrumbsItem.label])}
                  className={classes.title}
                >
                  {lastBreadcrumbsItem.label}
                </h1>
              )}
              {Boolean(title) && <BigidPageFavoriteToggler />}
              {titleHelperComponent && <div className={classes.helperComponentWrapper}>{titleHelperComponent}</div>}
            </div>
          </div>
        </div>
        {(widgets || rightSideComponentsContainer) && (
          <div className={classes.rightSide}>
            {widgets && widgets.map((widget, key) => <BigidTitleWidget {...widget} key={key} />)}
            {rightSideComponentsContainer && <div>{rightSideComponentsContainer}</div>}
          </div>
        )}
      </div>
    </div>
  );
};
