import React, { FC, useRef, useContext, useState, useCallback, useLayoutEffect } from 'react';
import { useLocalTranslation } from '../../../../../translations';
import {
  BigidForm,
  BigidFormField,
  BigidFormProps,
  BigidFormValues,
  BigidFormStateAndHandlers,
  PrimaryButton,
  SecondaryButton,
  BigidLoader,
  BigidFieldsetRenderProps,
  BigidFormFieldDropDown,
  BigidFormFieldText,
} from '@bigid-ui/components';
import { ActionableInsightsContext } from '../../../../../hooks/ActionableInsightsContext';
import styled from '@emotion/styled';
import { ContentTypes } from '../TicketModal';
import {
  generateJiraConfigurationFormFields,
  generateTicketDescription,
  createTicket,
  validateTicketCreationAndUpdateGrid,
  JiraValidationErrorType,
  generateTicketConfigurationFormFields,
  generateServiceTicketDataFromFormValues,
} from '../../../caseActionsService';
import { ProjectSelector } from './ProjectSelector';
import { fetchServiceTicketMetadata } from '../../../../../../ActionCenter/ActionWorkflow/actionWorkflowService';
import { OpenServiceTicketMetadata } from '../../../../../../ActionCenter/ActionWorkflow/actionWorkflowTypes';
import { notificationService } from '../../../../../../../services/notificationService';
import { ActionableInsightsTrackingEvents } from '../../../../../actionableInsightsTrackingUtil';
import { generateDataAid } from '@bigid-ui/utils';
import { CaseModel, STORED_SERVICE_NAME } from '../../../../../actionableInsightsService';
import { ReducerActions } from '../../../../../hooks/ActionableInsightsReducer';
import { CaseSidePanelContext } from '../../../../../CaseSidePanel/hooks/CaseSidePanelContext';

const MainContainer = styled.div({
  paddingBottom: 16,
  width: '100%',
  minHeight: 480,
  '& legend:empty': {
    display: 'none',
  },
});

const Container = styled.div({
  display: 'flex',
  justifyContent: 'space-between',
});

const ContainerItem = styled.div({
  width: '49%',
});

const ButtonsContainer = styled.div({
  display: 'flex',
  flexDirection: 'row-reverse',
  paddingTop: 8,
  marginTop: 'auto',
  '& > :first-child': {
    marginLeft: 8,
  },
});

const SERVICE_NOW_TOOLTIP_TEXT = 'Create an Integrated Risk Management triage issue';
export const NO_FIELDS_ERROR_MESSAGE = 'No fields error';

export enum ServiceTicketingType {
  JIRA = 'jira',
  SERVICE_NOW = 'tpa',
}

export interface CreateTicketProps {
  updateModalContent: React.Dispatch<React.SetStateAction<ContentTypes>>;
  closeModal: () => void;
  rowData: CaseModel;
}

export enum JiraTicketFields {
  DUE_DATE = 'duedate',
  PRIORITY = 'priority',
}

export const CreateTicket: FC<CreateTicketProps> = ({ closeModal, updateModalContent, rowData }) => {
  const { t } = useLocalTranslation();
  const formControls = useRef<BigidFormStateAndHandlers>();
  const issueIdRef = useRef('');
  const { jiraConfigurations, viewType, selectedTicketingService, dispatch } = useContext(ActionableInsightsContext);
  const [formFields, setFormFields] = useState<BigidFormField[]>([]);
  const [ticketMetadata, setTicketMetadata] = useState<OpenServiceTicketMetadata>();
  const [isLoading, setIsLoading] = useState(true);
  const { amountOfFindings } = useContext(CaseSidePanelContext);
  const { id: configDataId, type: currentService } = selectedTicketingService;

  const onFormChange = (values: BigidFormValues) => {
    if (values?.issueType?.length) {
      const { id: selectedIssueId } = values.issueType[0];
      if (issueIdRef.current !== selectedIssueId) {
        const issueTypeFields = ticketMetadata.fieldsForIssueTypeMap[selectedIssueId];
        setFormFields(
          generateJiraConfigurationFormFields(ticketMetadata, configDataId, {
            dueDate: issueTypeFields.some(config => config.name === JiraTicketFields.DUE_DATE),
            priority: issueTypeFields.some(config => config.name === JiraTicketFields.PRIORITY),
          }),
        );
        issueIdRef.current = selectedIssueId;
      }
    }
  };

  const jiraDefaultFormValues = {
    dueDate: '',
    description: generateTicketDescription(rowData, amountOfFindings),
    summary: rowData?.caseLabel,
  };

  const serviceNowDefaultFormValues = {
    description: generateTicketDescription(rowData, amountOfFindings),
    short_description: rowData?.caseLabel,
    state: [{ value: '0', displayValue: 'New', id: '0' }],
    priority: [{ value: '2', displayValue: '2 - High', id: '2' }],
  };

  const formProps: BigidFormProps = {
    fields: formFields,
    isFieldsBySelection: false,
    controlButtons: false,
    stateAndHandlersRef: formControls,
    onChange: onFormChange,
    initialValues:
      currentService === ServiceTicketingType.SERVICE_NOW ? serviceNowDefaultFormValues : jiraDefaultFormValues,
    ...(currentService === ServiceTicketingType.SERVICE_NOW && {
      fieldsets: [
        {
          name: 'Selection',
          fields: ['opened_by', 'short_description', 'issue_type', 'state', 'assigned_to', 'priority'],
          label: '',
          renderFieldset: ({ getFieldProps }: BigidFieldsetRenderProps) => {
            const openedByFieldProps = getFieldProps('opened_by');
            const shortDescFieldProps = getFieldProps('short_description');
            const issueFieldProps = getFieldProps('issue_type');
            const stateFieldProps = getFieldProps('state');
            const assignedFieldProps = getFieldProps('assigned_to');
            const prorityFieldProps = getFieldProps('priority');

            return (
              <div>
                <BigidFormFieldDropDown
                  value={openedByFieldProps.value}
                  setValue={openedByFieldProps.setValue}
                  dropDownOptions={openedByFieldProps.dropDownOptions}
                  isRequired={openedByFieldProps.isRequired}
                  fieldProps={{
                    isSearchable: true,
                  }}
                  errorIsShown={openedByFieldProps.errorIsShown}
                  error={openedByFieldProps.error}
                  placeholder="Select user"
                  label="Opened by"
                />
                <BigidFormFieldText
                  value={shortDescFieldProps.value}
                  setValue={shortDescFieldProps.setValue}
                  label="Name"
                  isRequired={shortDescFieldProps.isRequired}
                  errorIsShown={shortDescFieldProps.errorIsShown}
                  error={shortDescFieldProps.error}
                  placeholder="Describe the issue"
                />
                <Container>
                  <ContainerItem>
                    <BigidFormFieldDropDown
                      value={issueFieldProps.value}
                      setValue={issueFieldProps.setValue}
                      dropDownOptions={issueFieldProps.dropDownOptions}
                      isRequired={issueFieldProps.isRequired}
                      fieldProps={{
                        isSearchable: true,
                      }}
                      errorIsShown={issueFieldProps.errorIsShown}
                      error={issueFieldProps.error}
                      placeholder="Select issue type"
                      label="Issue type"
                    />
                  </ContainerItem>
                  <ContainerItem>
                    <BigidFormFieldDropDown
                      value={stateFieldProps.value}
                      setValue={stateFieldProps.setValue}
                      dropDownOptions={stateFieldProps.dropDownOptions}
                      isRequired={stateFieldProps.isRequired}
                      fieldProps={{
                        isSearchable: true,
                      }}
                      errorIsShown={stateFieldProps.errorIsShown}
                      error={stateFieldProps.error}
                      placeholder="Select state"
                      isValidated={false}
                      label="State"
                    />
                  </ContainerItem>
                </Container>
                <Container>
                  <ContainerItem>
                    <BigidFormFieldDropDown
                      value={assignedFieldProps.value}
                      setValue={assignedFieldProps.setValue}
                      dropDownOptions={assignedFieldProps.dropDownOptions}
                      fieldProps={{
                        isSearchable: true,
                      }}
                      isRequired={assignedFieldProps.isRequired}
                      errorIsShown={assignedFieldProps.errorIsShown}
                      error={assignedFieldProps.error}
                      placeholder="Select user"
                      label="Triage owner"
                    />
                  </ContainerItem>
                  <ContainerItem>
                    <BigidFormFieldDropDown
                      value={prorityFieldProps.value}
                      setValue={prorityFieldProps.setValue}
                      dropDownOptions={prorityFieldProps.dropDownOptions}
                      fieldProps={{
                        isSearchable: true,
                      }}
                      isRequired={prorityFieldProps.isRequired}
                      errorIsShown={prorityFieldProps.errorIsShown}
                      error={prorityFieldProps.error}
                      placeholder="Select priority"
                      label="Priority"
                    />
                  </ContainerItem>
                </Container>
              </div>
            );
          },
        },
        {
          name: 'Section',
          fields: ['description'],
          label: '',
        },
      ],
    }),
  };

  const updateSelectedJiraProject = (projectName: string) => {
    if (projectName && projectName !== selectedTicketingService.name) {
      setIsLoading(true);
      const serviceSelected = jiraConfigurations.find(config => config?.name === projectName);
      dispatch({
        type: ReducerActions.UPDATE_LATEST_TICKETING_SERVICE,
        payload: { selectedTicketingService: serviceSelected },
      });
      localStorage.setItem(STORED_SERVICE_NAME, JSON.stringify(serviceSelected));
    }
  };

  const onCreateButtonClick = async () => {
    formControls.current.validateAndSubmit(async (values: BigidFormValues) => {
      setIsLoading(true);
      try {
        const generatedFormValues = generateServiceTicketDataFromFormValues(values, configDataId);
        await createTicket(generatedFormValues, rowData?.id, currentService as ServiceTicketingType);
        const jiraValidation = await validateTicketCreationAndUpdateGrid(rowData);
        if (jiraValidation.errorType === JiraValidationErrorType.CANT_VERIFY) {
          notificationService.warning(t('Action.common.notifications.TicketNotVerified'), {
            duration: 12000,
            title: t('Action.common.notifications.ticketingInProgressTitle'),
          });
          closeModal();
        }
        if (jiraValidation.isValid) {
          closeModal();
        } else {
          setIsLoading(false);
        }
      } catch (e) {
        notificationService.error(e.message, {
          title: t('Action.common.notifications.ticketingFailedTitle'),
          duration: 3000,
        });
        setIsLoading(false);
      }
    });
  };

  const handleFetchError = (error: Error) => {
    const filteredServices = jiraConfigurations.filter(config => config.id !== configDataId);
    dispatch({
      type: ReducerActions.UPDATE_CONFIGURATIONS,
      payload: { jiraConfigurations: filteredServices },
    });
    dispatch({
      type: ReducerActions.UPDATE_LATEST_TICKETING_SERVICE,
      payload: { selectedTicketingService: filteredServices[0] },
    });
    localStorage.removeItem(STORED_SERVICE_NAME);
    const errorMessage =
      error.message === NO_FIELDS_ERROR_MESSAGE
        ? t('Action.common.errors.errorTicketNoFields')
        : t('Action.common.errors.errorFetchingJiraConfig');
    notificationService.error(errorMessage);
    closeModal();
  };

  const fetchTicketMetadata = useCallback(async () => {
    try {
      setIsLoading(true);
      const data: OpenServiceTicketMetadata = await fetchServiceTicketMetadata(configDataId);
      setTicketMetadata(data);
      if (currentService === ServiceTicketingType.SERVICE_NOW) {
        setFormFields(generateTicketConfigurationFormFields(data));
      } else {
        setFormFields(generateJiraConfigurationFormFields(data, configDataId));
      }
      setIsLoading(false);
    } catch (e) {
      handleFetchError(e);
    }
  }, [closeModal, t, selectedTicketingService, configDataId, jiraConfigurations, currentService]);

  useLayoutEffect(() => {
    fetchTicketMetadata();
  }, [configDataId]);

  return (
    <MainContainer data-aid={generateDataAid('CreateJiraTicket', ['main-container'])}>
      {isLoading ? (
        <BigidLoader />
      ) : (
        <>
          <ProjectSelector
            updateModalContent={updateModalContent}
            updateSelectedJiraProject={updateSelectedJiraProject}
            tooltipText={currentService === ServiceTicketingType.SERVICE_NOW && SERVICE_NOW_TOOLTIP_TEXT}
          />
          <BigidForm {...formProps} />
          <ButtonsContainer>
            <PrimaryButton
              size="medium"
              dataAid={generateDataAid('CreateJiraTicket', ['create', 'button'])}
              text={t('Action.button.create')}
              onClick={onCreateButtonClick}
              bi={{
                eventType: ActionableInsightsTrackingEvents.DATA_RISK_MANAGEMENT_JIRA_TICKET_CREATE_CLICK,
                eventData: {
                  VIEW_TYPE: viewType,
                },
              }}
            />
            <SecondaryButton
              size="medium"
              text={t('Action.button.cancel')}
              dataAid={generateDataAid('CreateJiraTicket', ['cancel', 'button'])}
              onClick={closeModal}
              bi={{
                eventType: ActionableInsightsTrackingEvents.DATA_RISK_MANAGEMENT_JIRA_TICKET_CANCEL_CLICK,
                eventData: {
                  VIEW_TYPE: viewType,
                },
              }}
            />
          </ButtonsContainer>
        </>
      )}
    </MainContainer>
  );
};
