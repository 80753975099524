import React, { FC, useContext, useCallback, MouseEvent } from 'react';
import {
  BigidIcon,
  compareObjectsExceptFunctions,
  BigidFormFieldSideLabelWrapper,
  SecondaryButton,
  BigidFieldRenderProps,
  BigidBody1,
  BigidIconSize,
  BigidLink,
} from '@bigid-ui/components';
import { DataSourceConfigurationContext } from '../DataSourceConfigurationContext';
import makeStyles from '@mui/styles/makeStyles';
import { IMAGE_NAME_TO_COMPONENT_MAP, ACTION_SOURCE_TO_FUNCTION } from '../constants';
import { DsFieldActionTypeEnum } from '../types';
import { isNewConnectivityExperienceEnabled } from '../utils/isNewConnectivityExperienceEnabled';

interface FormFieldActionStyleProp {
  shouldApplyNewStyle: boolean;
}

const FIELDS_OVERRIDE_STYLE_BY_SOURCE: string[] = ['connectOauth', 'disconnectOauth'];

const useStyles = makeStyles({
  actionLabelWrapper: ({ shouldApplyNewStyle }: FormFieldActionStyleProp) => ({
    padding: '0 16px',
    ...(shouldApplyNewStyle ? { alignSelf: 'center' } : {}),
  }),
  linklWrapper: ({ shouldApplyNewStyle }: FormFieldActionStyleProp) => ({
    ...(shouldApplyNewStyle ? { alignSelf: 'center' } : {}),
  }),
});

export const FormFieldAction: FC<BigidFieldRenderProps> = React.memo(
  ({
    name,
    value,
    label = '',
    error,
    disabled,
    errorIsShown,
    isValidated,
    misc,
    isRequired,
    labelWidth,
    tooltipText,
    setValue,
  }) => {
    const id = `bigid-form-field-${name}`;
    const state = useContext(DataSourceConfigurationContext);
    const shouldApplyNewStyle =
      isNewConnectivityExperienceEnabled(state?.selectedType) && FIELDS_OVERRIDE_STYLE_BY_SOURCE.includes(misc?.source);
    const { actionLabelWrapper, linklWrapper } = useStyles({ shouldApplyNewStyle: shouldApplyNewStyle });
    labelWidth = shouldApplyNewStyle ? '0px' : labelWidth;
    const actionHandler = useCallback(async () => {
      const runActionFunction = ACTION_SOURCE_TO_FUNCTION[misc?.source];
      if (runActionFunction) {
        const result = await runActionFunction(value, state);
        setValue(result);
      } else {
        console.warn(`Action not implemented for field: "${name}", source: "${misc?.source}"`);
      }
    }, [misc?.source, name, setValue, state, value]);

    return (
      <BigidFormFieldSideLabelWrapper
        id={id}
        name={name}
        isRequired={isRequired}
        isSeparatorAfter={misc?.isSeparatorAfter}
        labelWidth={labelWidth}
        tooltipText={tooltipText}
        isError={error && errorIsShown}
        fullWidth={misc?.fullWidth}
        label={String(label || ' ')}
      >
        {misc?.startIconOrImage ? (
          <BigidIcon size={BigidIconSize.MEDIUM} icon={IMAGE_NAME_TO_COMPONENT_MAP[misc.startIconOrImage]} />
        ) : null}
        {misc?.actionDescription ? (
          <BigidBody1 className={actionLabelWrapper}>{String(misc?.actionDescription)}</BigidBody1>
        ) : null}

        {misc?.actionType === DsFieldActionTypeEnum.link ? (
          <BigidLink
            classNames={linklWrapper}
            text={misc.actionLabel}
            href=""
            onClick={(event?: MouseEvent) => {
              event.preventDefault();
              actionHandler();
            }}
            disabled={disabled || isValidated}
          />
        ) : (
          <SecondaryButton
            size="medium"
            onClick={actionHandler}
            disabled={disabled || isValidated}
            text={misc.actionLabel}
          />
        )}
      </BigidFormFieldSideLabelWrapper>
    );
  },
  compareObjectsExceptFunctions,
);

FormFieldAction.displayName = 'FormFieldAction';
