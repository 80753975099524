import React from 'react';
import { styled } from '@mui/material';
import { BigidHelpIcon } from '@bigid-ui/icons';
import { BigidBody1, BigidTooltip, BigidTooltipProps } from '@bigid-ui/components';

const LabelBox = styled(BigidBody1)`
  margin-bottom: 8px;
  display: flex;
  align-items: center;
  gap: 8px;
`;

const TooltipBox = styled('span')`
  line-height: 1;
`;

interface TitleWithTooltipProps {
  label: string;
  tooltipText?: string;
  tooltipPlacement?: BigidTooltipProps['placement'];
}

export const LabelWithTooltip = ({ label, tooltipText, tooltipPlacement = 'right' }: TitleWithTooltipProps) => {
  return (
    <LabelBox>
      {label}
      {tooltipText && (
        <BigidTooltip fontSize="medium" placement={tooltipPlacement} title={tooltipText}>
          <TooltipBox>
            <BigidHelpIcon />
          </TooltipBox>
        </BigidTooltip>
      )}
    </LabelBox>
  );
};
