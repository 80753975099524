import React, { FC, useContext } from 'react';
import { generateDataAid } from '@bigid-ui/utils';
import { BigidBody1, BigidColorsV2 } from '@bigid-ui/components';
import { BigidApplyFilledIcon } from '@bigid-ui/icons';
import styled from '@emotion/styled';
import { GettingStartedContext } from '../hooks/gettingStartedContext';
import { GETTING_STARTED_STEPS } from '../gettingStartedItems';
import { GettingStartedStepState } from '../utils/gettingStartedUtils';
import { ReducerActions } from '../hooks/gettingStartedReducer';

const MenuItem = styled('div')<{ isCurrent: boolean; isDisabled: boolean }>(({ isCurrent, theme, isDisabled }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  padding: '12px',
  width: '368px',
  height: '56px',
  background: theme.palette.bigid.white,
  borderRadius: '4px',
  flex: 'none',
  alignSelf: 'stretch',
  flexGrow: 0,
  gap: '12px',
  cursor: isDisabled ? 'unset' : 'pointer',
  ...(isCurrent
    ? {
        background: theme.palette.bigid.primary50,
        boxShadow: '0px 0px 5px rgba(0, 0, 0, 0.15)',
      }
    : {}),
}));

const MenuItemCircle = styled('div')<{ isCurrent: boolean; isDisabled: boolean }>(
  ({ isCurrent, theme, isDisabled }) => ({
    display: 'flex',
    width: '24px',
    height: '24px',
    borderRadius: '50%',
    textAlign: 'center',
    alignItems: 'center',
    justifyContent: 'center',
    color: isDisabled ? theme.palette.bigid.gray500 : theme.palette.bigid.gray600,
    fontSize: '12px',
    fontWeight: 700,
    ...(isCurrent
      ? {
          background: isDisabled ? theme.palette.bigid.gray500 : theme.palette.bigid.primary900,
          color: 'white',
        }
      : {
          border: `1px solid ${isDisabled ? theme.palette.bigid.gray500 : theme.palette.bigid.gray600}`,
        }),
  }),
);

export const GettingStartMenuItem: FC<GettingStartedStepState> = ({ id, isComplete, orderNumber, isDisabled }) => {
  const { currentStepId, dispatch } = useContext(GettingStartedContext);
  const { menuTitle } = GETTING_STARTED_STEPS[id];
  const isCurrent = currentStepId === id;

  return (
    <MenuItem
      data-aid={generateDataAid('GettingStartMenuItem', [menuTitle, isCurrent])}
      isCurrent={isCurrent}
      onClick={() =>
        dispatch({
          type: ReducerActions.SET_CURRENT_STEP,
          payload: { currentStepId: id },
        })
      }
      isDisabled={isDisabled}
    >
      {isComplete ? (
        <BigidApplyFilledIcon
          color="positive"
          data-aid={generateDataAid('GettingStartMenuItem-Complete-Icon', [menuTitle])}
        />
      ) : (
        <MenuItemCircle
          data-aid={generateDataAid('GettingStartMenuItem-Circle', [menuTitle, isCurrent])}
          isCurrent={isCurrent}
          isDisabled={isDisabled}
        >
          {orderNumber}
        </MenuItemCircle>
      )}
      <BigidBody1
        color={BigidColorsV2.gray[isDisabled ? 500 : 600]}
        data-aid={generateDataAid('GettingStartMenuItem-Title', [menuTitle])}
        fontWeight={isCurrent ? 700 : 400}
      >
        {menuTitle}
      </BigidBody1>
    </MenuItem>
  );
};
