import { CustomAppParam, InputTypes } from '../views/EditCustomApp/EditCustomApp';
import { BigidSelectOption } from '@bigid-ui/components';
import { WorkspacesDetails } from '../../ApplicationsManagement/applicationManagementService';
import { CredentialType } from '../../Credentials/credentialsFormUtils';

type InstallationDetails = {
  installationType: string;
  appImageName: string;
};

export type FetchItems = {
  api: string;
  pathToArray: string;
  pathToValue?: string;
};

export enum ParamTypes {
  STRING = 'String',
  CREDENTIAL = 'Credential',
  OBJECT = 'Object',
  ARRAY = 'Array',
}

export type ParamResponseType = {
  param_type: ParamTypes;
  param_name: string;
  fetch_items: FetchItems;
  default_value?: string;
  param_friendly_name?: string;
  param_description?: string;
  hint?: string;
  value?: string;
  is_mandatory?: boolean;
  param_category?: string;
  is_cleartext?: boolean;
  input_type?: InputTypes;
  input_items?: string[];
  app_field_identifiers_map?: Record<string, string>;
  sub_type?: CredentialType;
};

export enum CustomAppPagesEnum {
  UI = 'ui',
  ACTIONS = 'actions',
  EDIT_APP = 'editApp',
  ACTIVITY_LOG = 'activityLog',
  EDIT_PRESET = 'editPreset',
}

export type BigidSelectDockerImagesOptions = BigidSelectOption & {
  description: string;
  port: string;
};

export type actionPermission = {
  actionName: string;
  isPremium: boolean;
};

export type CustomAppActionType = {
  actionId: string;
  paramsKeyValuesMap: Record<string, string>;
};

export type DockerhubImageDetails = {
  id: string;
  repositoryName: string;
  fullImageName: string;
  description: string;
  port: string;
};

type AppGeneralSettings = {
  isRemoteApplication: boolean;
  isVerified: boolean;
  scannerGroup?: string;
};

export type GlobalPreset = {
  _id: string;
  tpa_id: string;
  name: string;
  is_default: boolean;
  params_key_value: Record<string, string>;
  description?: string;
};

export type AppInfo = {
  id: string;
  name: string;
  baseUrl: string;
  bigidBaseUrl: string;
  description: string;
  globalParams: CustomAppParam[];
  isInteractive: boolean;
  isUseProxy: boolean;
  canGetDsCredentials: boolean;
  uiUrl: string;
  installationDetails: InstallationDetails;
  vendor: string;
  uiTabName: string;
  version: string;
  status?: string;
  friendlyName?: string;
  actionsPermissions?: actionPermission[];
  settings?: AppGeneralSettings;
  hideUI?: boolean;
  globalPresets?: GlobalPreset[];
  instances?: AppInfo[];
  manifest_name?: string;
  workspaces_details?: WorkspacesDetails;
};
