import React from 'react';
import { getCatalogEntityIconByType } from '../../../utils';
import { styled } from '@mui/material';
import { CatalogSidePanelProps } from './CatalogSidePanel/CatalogSidePanel';
import { getHighlightedElement } from './utils';

type SidePanelTitleProps = CatalogSidePanelProps & {
  highlightValue: string;
};

const Root = styled('div')`
  display: flex;
  gap: 8px;
  align-items: center;
`;

export const SidePanelTitle = ({ selectedItem, highlightValue }: SidePanelTitleProps) => {
  const { data } = selectedItem;
  const { objectName, type } = data;
  const highlightedTitle = getHighlightedElement(objectName, highlightValue);
  const DsIcon = getCatalogEntityIconByType(type);

  return (
    <Root>
      <DsIcon /> {highlightedTitle}
    </Root>
  );
};
