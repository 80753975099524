import './businessGlossary.component.scss';
import { module } from 'angular';
const app = module('app');
import template from './businessGlossary.component.html';
import { GENERAL_SETTINGS_PERMISSIONS } from '@bigid/permissions';
import { isPermitted } from '../../../react/services/userPermissionsService';
import { GlossaryItemType } from './businessGlossary.service';
import { BIGID_BI_EVENTS } from '../../../react/config/BigIdBiEvents';
import { analyticsService } from '../../../react/services/analyticsService';

app.component('businessGlossary', {
  template,
  controller: function (
    notificationService,
    $translate,
    businessGlossaryService,
    uiGridConstants,
    $timeout,
    DeleteConfirmation,
    downloadFileService,
    $uibModal,
  ) {
    'ngInject';

    const TRANSLATION_REQUIRED = [
      'BUTTONS:IMPORT',
      'BUTTONS:EXPORT',
      'BUSINESS_GLOSSARY:FILTER:OPTION:ALL',
      'BUSINESS_GLOSSARY:FILTER:OPTION:PERSONAL_DATA_ITEM',
      'BUSINESS_GLOSSARY:FILTER:OPTION:PERSONAL_DATA_CATEGORY',
      'BUSINESS_GLOSSARY:FILTER:OPTION:PURPOSE_OF_PROCESSING',
      'BUSINESS_GLOSSARY:GRID:COLUMN:GLOSSARY_ID',
      'BUSINESS_GLOSSARY:GRID:COLUMN:DISPLAY_NAME',
      'BUSINESS_GLOSSARY:GRID:COLUMN:DESCRIPTION',
      'BUSINESS_GLOSSARY:GRID:COLUMN:TYPE',
      'BUSINESS_GLOSSARY:GRID:COLUMN:ACTION',
      'BUSINESS_GLOSSARY:GRID:COLUMN:REPORT_ORDER',
    ];

    const COLUMNS_TRANSLATION_MAPPING = {
      glossary_id: 'BUSINESS_GLOSSARY:GRID:COLUMN:GLOSSARY_ID',
      name: 'BUSINESS_GLOSSARY:GRID:COLUMN:DISPLAY_NAME',
      type: 'BUSINESS_GLOSSARY:GRID:COLUMN:TYPE',
      description: 'BUSINESS_GLOSSARY:GRID:COLUMN:DESCRIPTION',
      report_order: 'BUSINESS_GLOSSARY:GRID:COLUMN:REPORT_ORDER',
    };

    const ALL_ITEMS = 'all';
    const PERSONAL_DATA_ITEM = 'personal_data_item';
    const PERSONAL_DATA_CATEGORY = 'personal_data_category';
    const PURPOSE_OF_PROCESSING = 'purpose_of_processing';

    const REQUIRED_FIELDS = {
      glossary_id: 'Glossary ID',
      name: 'Display Name',
      type: 'Type',
    };

    const NEW_ROW_ENTITY = {
      glossary_id: '',
      name: '',
      type: '',
      description: '',
      isNew: true,
      isPinned: true,
      report_order: undefined,
    };

    const ROW_HEIGHT = 40;

    analyticsService.trackPageView(BIGID_BI_EVENTS.GENERAL_SETTINGS_BUSINESS_GLOSSARY);

    const fetchGlossaryItems = () => {
      businessGlossaryService
        .getGlossaryItems()
        .then(
          result => {
            this.businessGlossaryGroupData = groupItemsByType(result);

            if (this.selectedFilterTypeOption['value'] === ALL_ITEMS) {
              this.businessGlossaryGridConfig['data'] = result;
            } else {
              this.businessGlossaryGridConfig['data'] = getGroupData(
                this.selectedFilterTypeOption['value'],
                this.businessGlossaryGroupData,
              );
            }

            this.onDataLoaded();
          },
          () => {
            $translate('API:MESSAGE:COMMON_ERROR').then(translation => {
              notificationService.error(translation);
            });

            this.onDataLoaded();
          },
        )
        .catch(() => {
          $translate('API:MESSAGE:COMMON_ERROR').then(translation => {
            notificationService.error(translation);
          });

          this.onDataLoaded();
        });
    };

    const createGlossaryItem = data => {
      businessGlossaryService
        .createGlossaryItem(data)
        .then(
          () => {
            fetchGlossaryItems();

            $translate('API:MESSAGE:COMMON_POST_SUCCESS').then(translation => {
              notificationService.success(translation);
            });
          },
          () => {
            $translate('API:MESSAGE:COMMON_ERROR').then(translation => {
              notificationService.error(translation);
            });

            this.onDataLoaded();
          },
        )
        .catch(() => {
          $translate('API:MESSAGE:COMMON_ERROR').then(translation => {
            notificationService.error(translation);
          });

          this.onDataLoaded();
        });
    };

    const editGlossaryItem = (id, data) => {
      businessGlossaryService
        .updateGlossaryItem(id, data)
        .then(
          () => {
            fetchGlossaryItems();

            $translate('API:MESSAGE:COMMON_PUT_SUCCESS').then(translation => {
              notificationService.success(translation);
            });
          },
          () => {
            $translate('API:MESSAGE:COMMON_ERROR').then(translation => {
              notificationService.error(translation);
            });

            this.onDataLoaded();
          },
        )
        .catch(() => {
          $translate('API:MESSAGE:COMMON_ERROR').then(translation => {
            notificationService.error(translation);
          });

          this.onDataLoaded();
        });
    };

    const deleteGlossaryItem = id => {
      this.onDataLoading();

      businessGlossaryService.deleteGlossaryItem(id).then(
        () => {
          fetchGlossaryItems();

          $translate('API:MESSAGE:COMMON_DELETE_SUCCESS').then(translation => {
            notificationService.success(translation);
          });
        },
        () => {
          $translate('API:MESSAGE:COMMON_ERROR').then(translation => {
            notificationService.error(translation);
          });

          this.onDataLoaded();
        },
      );
    };

    const exportGlossaryItems = () => {
      this.onDataLoading();

      businessGlossaryService.getGlossaryDataExport().then(
        response => {
          this.onDataLoaded();

          downloadFileService.download(response, 'attachment/csv', 'glossaryData.csv');
        },
        () => {
          $translate('API:MESSAGE:COMMON_ERROR').then(translation => {
            notificationService.error(translation);
          });

          this.onDataLoaded();
        },
      );
    };

    const getGroupData = (groupName, groups) => {
      let result = [];

      if (groupName === ALL_ITEMS) {
        result = Object.keys(groups).reduce((aggregated, group) => [...aggregated, ...groups[group]], []);
      } else {
        const groupData = groups[groupName];

        result = typeof groupData == 'undefined' ? [] : groupData;
      }

      return result;
    };

    const groupItemsByType = data => {
      let groups = {};

      groups = data.reduce((aggregated, item) => {
        const type = item['type'].toLowerCase().replace(/ /g, '_');

        aggregated[type] = [...(aggregated[type] || []), item];

        return aggregated;
      }, {});

      return groups;
    };

    const isReportOrderValid = item => {
      if (typeof item?.report_order === 'number' && !(item.report_order >= 1 && item.report_order <= 10)) {
        notificationService.error(`Report Order should be a number between 1 and 10`);
        return false;
      }
      // null and undefined are valid since it is not mandatory field
      return true;
    };

    const isItemValid = (item, requiredFields = REQUIRED_FIELDS) => {
      return Object.keys(requiredFields).reduce(
        (aggregated, field) => {
          const isValid = typeof item[field] != 'undefined' && item[field] !== '';

          aggregated['isValid'] = isValid && aggregated['isValid'];

          if (!isValid) {
            aggregated['invalidFields'] = [...aggregated['invalidFields'], { [field]: requiredFields[field] }];
          }

          return aggregated;
        },
        { isValid: true, invalidFields: [] },
      );
    };

    const columnSorting = new Intl.Collator(undefined, { numeric: true, sensitivity: 'base' }).compare;

    let scrollGridToBottom = null;
    let scrollGridToTop = null;
    let rowAdded = false;

    this.exportButtonItems = [];

    // currRowIndex is compared with 8 since MAX_TOOLTIP_HEIGHT(300px) / ROW_HEIGHT(40px) ~ 8
    this.businessGlossaryGridConfig = {
      enableSorting: true,
      enableFiltering: true,
      enablePaginationControls: false,
      enableCellEditOnFocus: true,
      appScopeProvider: this,
      rowHeight: ROW_HEIGHT,
      data: [],
      columnDefs: [
        {
          name: 'Alignment',
          field: '',
          cellEditableCondition: false,
          enableFiltering: false,
          headerCellTemplate: '<div></div>',
          width: 10,
          minWidth: 10,
          cellTemplate: '<div></div>',
        },
        {
          name: 'Glossary ID',
          displayName: 'Glossary ID',
          field: 'glossary_id',
          width: 300,
          minWidth: 300,
          cellTemplate: `<div class="ui-grid-cell-contents" ng-disabled="!row.entity.isNew">
                  <div ng-init="currRowIndex = grid.renderContainers.body.visibleRowCache.indexOf(row)" class="editable-cell-container__data">
                    <div ng-if="row.entity.glossary_id" class="editable-data uib-tooltip"  tooltip-class="grid-cell-tooltip"
                      tooltip-append-to-body="true" tooltip-placement="{{currRowIndex <= 8 ? 'bottom' : 'top'}}" uib-tooltip="{{row.entity.glossary_id}}">
                      <span class="editable-data__text">{{ row.entity.glossary_id }}</span>
                    </div>
                    <div ng-if="!row.entity.glossary_id" class="editable-data editable-data--input"
                      ng-class="{'editable-data--new': row.entity.isNew, 'editable-data--invalid': row.entity.invalidFields.indexOf(col.colDef.field) > -1}">
                      <input type="text" class="bigid-input editable-data__input" placeholder="{{ 'BUSINESS_GLOSSARY:GRID:PLACEHOLDER:GLOSSARY_ID' | translate }}" ng-model="row.entity.glossary_id">
                    </div>
                  <div>
                <div>`,
          cellClass: 'input-cell',
          cellEditableCondition: scope => scope.row.entity.isNew,
          filter: {
            condition: uiGridConstants.filter.CONTAINS,
            type: uiGridConstants.filter.INPUT,
            flags: {
              caseSensitive: false,
            },
          },
          sortingAlgorithm: columnSorting,
        },
        {
          name: 'Display Name',
          displayName: 'Display Name',
          field: 'name',
          enableCellEdit: true,
          cellTemplate: `<div class="ui-grid-cell-contents">
                  <div ng-init="currRowIndex = grid.renderContainers.body.visibleRowCache.indexOf(row)" class="editable-cell-container__data">
                    <div ng-if="row.entity.name" class="editable-data uib-tooltip" tooltip-class="grid-cell-tooltip" tooltip-append-to-body="true"
                      tooltip-placement="{{currRowIndex <= 8? 'bottom' : 'top'}}" uib-tooltip="{{row.entity.name}}">
                      <span class="editable-data__text">{{ row.entity.name }}</span>
                    </div>
                    <div ng-if="!row.entity.name" class="editable-data editable-data--input"
                      ng-class="{'editable-data--new': row.entity.isNew, 'editable-data--invalid': row.entity.invalidFields.indexOf(col.colDef.field) > -1}">
                      <input type="text" class="bigid-input editable-data__input" placeholder="{{ 'BUSINESS_GLOSSARY:GRID:PLACEHOLDER:DISPLAY_NAME' | translate }}" ng-model="row.entity.name">
                    </div>
                  <div>
                <div>`,
          cellClass: 'input-cell',
          filter: {
            condition: uiGridConstants.filter.CONTAINS,
            type: uiGridConstants.filter.INPUT,
            flags: {
              caseSensitive: false,
            },
          },
          sortingAlgorithm: columnSorting,
        },
        {
          name: 'Description',
          field: 'description',
          displayName: 'Description',
          enableCellEdit: true,
          cellTemplate: `<div class="ui-grid-cell-contents">
                  <div ng-init="currRowIndex = grid.renderContainers.body.visibleRowCache.indexOf(row)" class="editable-cell-container__data">
                    <div ng-if="row.entity.description" class="editable-data uib-tooltip" tooltip-class="grid-cell-tooltip"
                      tooltip-append-to-body="true" tooltip-placement="{{currRowIndex <= 8 ? 'bottom' : 'top'}}" uib-tooltip="{{row.entity.description}}">
                      <span class="editable-data__text">{{ row.entity.description }}</span>
                    </div>
                    <div ng-if="!row.entity.description" class="editable-data editable-data--input"
                      ng-class="{'editable-data--new': row.entity.isNew, 'editable-data--invalid': row.entity.invalidFields.indexOf(col.colDef.field) > -1}">
                      <input type="text" class="bigid-input editable-data__input" placeholder="{{ 'BUSINESS_GLOSSARY:GRID:PLACEHOLDER:DESCR' | translate }}" ng-model="row.entity.description">
                    </div>
                  <div>
                <div>`,
          cellClass: 'input-cell',
          filter: {
            condition: uiGridConstants.filter.CONTAINS,
            type: uiGridConstants.filter.INPUT,
            flags: {
              caseSensitive: false,
            },
          },
          sortingAlgorithm: columnSorting,
        },
        {
          name: 'Report Order',
          field: 'report_order',
          displayName: 'Report Order',
          enableCellEdit: true,
          editDropdownOptionsArray: [
            { id: null, value: 'None' },
            ...Array.from({ length: 10 }).map((_a, index) => ({
              id: index + 1,
              value: index + 1,
            })),
          ],
          editableCellTemplate: 'ui-grid/dropdownEditor',
          cellEditableCondition: scope => this.isCategoryItem(scope.row.entity),
          cellTemplate: `<div ng-if="grid.appScope.isCategoryItem(row.entity)" class="ui-grid-cell-contents">
            <div class="editable-cell-container__data">
              <div class="editable-data editable-data--input"
                ng-class="{'editable-data--new': row.entity.isNew, 'editable-data--invalid': row.entity.invalidFields.indexOf(col.colDef.field) > -1}">
                {{ row.entity.report_order == null ? ('BUSINESS_GLOSSARY:GRID:PLACEHOLDER:REPORT_ORDER' | translate) : row.entity.report_order }}
                <!--input type="number" max="10" min="0" step="1"
                  class="bigid-input editable-data__input"
                  placeholder="{{ 'BUSINESS_GLOSSARY:GRID:PLACEHOLDER:REPORT_ORDER' | translate }}"
                  ng-model="row.entity.report_order"-->
              </div>
            <div>
          <div>`,
          cellClass: 'input-cell',
          filter: {
            condition: uiGridConstants.filter.CONTAINS,
            type: uiGridConstants.filter.NUMBER,
            flags: {
              caseSensitive: false,
            },
          },
          sortingAlgorithm: columnSorting,
        },
        {
          name: 'Type',
          field: 'type',
          displayName: 'Type',
          width: 200,
          minWidth: 200,
          editDropdownValueLabel: 'type',
          editableCellTemplate: 'ui-grid/dropdownEditor',
          cellTemplate: `<div class="ui-grid-cell-contents" ng-disabled="!row.entity.isNew">
                  <div ng-init="currRowIndex = grid.renderContainers.body.visibleRowCache.indexOf(row)" class="editable-cell-container__data">
                    <div ng-if="row.entity.type" class="editable-data  uib-tooltip" tooltip-class="grid-cell-tooltip" tooltip-append-to-body="true"
                      tooltip-placement="{{currRowIndex <= 8 ? 'bottom' : 'top'}}" uib-tooltip="{{row.entity.type}}">
                      <span class="editable-data__text">{{ row.entity.type }}</span>
                    </div>
                    <div ng-if="!row.entity.type" class="editable-data editable-data--input"
                      ng-class="{\'editable-data--new\': row.entity.isNew, 'editable-data--invalid': row.entity.invalidFields.indexOf(col.colDef.field) > -1}">
                      <input type="text" class="bigid-input editable-data__input" placeholder="{{ 'BUSINESS_GLOSSARY:GRID:PLACEHOLDER:TYPE' | translate }}" ng-model="row.entity.type">
                    </div>
                  <div>
                <div>`,
          cellClass: 'input-cell',
          editDropdownOptionsArray: [],
          cellEditableCondition: scope => scope.row.entity.isNew,
          filter: {
            type: uiGridConstants.filter.SELECT,
            selectOptions: [],
          },
          sortingAlgorithm: columnSorting,
        },
        {
          name: 'Actions',
          field: '',
          cellEditableCondition: false,
          enableFiltering: false,
          headerCellTemplate: '<div></div>',
          cellTemplate: `<div class="ui-grid-cell-contents actions-container" ng-if="grid.appScope.isDeletePermitted">
                  <div ng-init="currRowIndex = grid.renderContainers.body.visibleRowCache.indexOf(row)" class="img-container">
                    <div class="img-button img-button--delete uib-tooltip" ng-show="!row.entity.isNew" tooltip-class="grid-cell-tooltip"
                      tooltip-append-to-body="true" tooltip-placement="{{currRowIndex <= 8 ? 'bottom' : 'top'}}" uib-tooltip="{{ 'BUTTONS:DELETE' | translate}}"
                      ng-click="grid.appScope.onRowDelete(row.entity)">
                    </div>
                  <div>
                <div>`,
          cellClass: 'actions-cell',
          minWidth: 50,
          width: 50,
        },
        {
          field: 'isPinned',
          visible: false,
          sort: { direction: uiGridConstants.DESC, priority: 0 },
          suppressRemoveSort: true,
          sortDirectionCycle: [uiGridConstants.DESC],
        },
      ],
    };

    this.businessGlossaryGridApi = null;
    this.businessGlossaryGroupData = {};

    this.typeFilterOptions = [];
    this.selectedFilterTypeOption = null;

    this.isCategoryItem = item => item?.type === GlossaryItemType.PersonalDataCategory;

    this.businessGlossaryGridConfig.onRegisterApi = gridApi => {
      this.businessGlossaryGridApi = gridApi;

      this.businessGlossaryGridApi.core.on.rowsRendered(null, () => {
        if (rowAdded) {
          rowAdded = false;

          $timeout(() => {
            scrollGridToBottom();
          }, 100);
        }
      });

      this.businessGlossaryGridApi.core.on.filterChanged(null, () => {
        // should be renamed
        if (typeof scrollGridToTop === 'function') {
          scrollGridToTop();
        }
      });

      this.businessGlossaryGridApi.edit.on.afterCellEdit(null, (rowEntity, colDef, newValue, oldValue) => {
        if (newValue !== oldValue) {
          const { glossary_id, _id, name, type, description, report_order, isNew } = rowEntity;

          let glossaryItem = {};

          if (isNew) {
            glossaryItem = { glossary_id, name, type, description, report_order };

            const { isValid } = isItemValid(glossaryItem);

            if (isValid && isReportOrderValid(glossaryItem)) {
              this.onDataLoading();

              createGlossaryItem(glossaryItem);
            }
          } else if (isPermitted(GENERAL_SETTINGS_PERMISSIONS.EDIT_BUSINESS_GLOSSARY.name)) {
            glossaryItem = { glossary_id, _id, name, type, description, report_order };

            const { isValid, invalidFields } = isItemValid(glossaryItem);

            if (isValid && isReportOrderValid(glossaryItem)) {
              this.onDataLoading();

              editGlossaryItem(_id, glossaryItem);
            } else {
              if (!isReportOrderValid(glossaryItem)) {
                return;
              }
              const FIELDS_DEFAULT_AGGREGATOR = {
                fieldDisplayNames: [],
                fieldDisplayKeys: [],
              };

              const { fieldDisplayNames, fieldDisplayKeys } = invalidFields.reduce((aggregator, field) => {
                return {
                  fieldDisplayNames: [...aggregator.fieldDisplayNames, ...Object.values(field)],
                  fieldDisplayKeys: [...aggregator.fieldDisplayKeys, ...Object.keys(field)],
                };
              }, FIELDS_DEFAULT_AGGREGATOR);

              rowEntity.invalidFields = fieldDisplayKeys;

              $translate('BUSINESS_GLOSSARY:GRID:FIELDS:ERROR').then(translation => {
                notificationService.error(`${translation}: ${fieldDisplayNames.join(', ')}`);
              });
            }
          }
        }
      });
    };

    this.$onInit = () => {
      this.isCreatePermitted = isPermitted(GENERAL_SETTINGS_PERMISSIONS.CREATE_BUSINESS_GLOSSARY.name);
      this.isDeletePermitted = isPermitted(GENERAL_SETTINGS_PERMISSIONS.DELETE_BUSINESS_GLOSSARY.name);

      $translate(TRANSLATION_REQUIRED).then(translations => {
        this.exportButtonItems = [];
        if (isPermitted(GENERAL_SETTINGS_PERMISSIONS.IMPORT_BUSINESS_GLOSSARY.name)) {
          this.exportButtonItems.push({
            name: translations['BUTTONS:IMPORT'],
            action: 'onDataImport',
          });
        }
        if (isPermitted(GENERAL_SETTINGS_PERMISSIONS.EXPORT_BUSINESS_GLOSSARY.name)) {
          this.exportButtonItems.push({
            name: translations['BUTTONS:EXPORT'],
            action: 'onDataExport',
          });
        }

        this.typeFilterOptions = [
          {
            value: 'all',
            label: translations['BUSINESS_GLOSSARY:FILTER:OPTION:ALL'],
          },
          {
            value: PERSONAL_DATA_ITEM,
            label: translations['BUSINESS_GLOSSARY:FILTER:OPTION:PERSONAL_DATA_ITEM'],
          },
          {
            value: PERSONAL_DATA_CATEGORY,
            label: translations['BUSINESS_GLOSSARY:FILTER:OPTION:PERSONAL_DATA_CATEGORY'],
          },
          {
            value: PURPOSE_OF_PROCESSING,
            label: translations['BUSINESS_GLOSSARY:FILTER:OPTION:PURPOSE_OF_PROCESSING'],
          },
        ];

        const typeColumnFilterOptions = [
          {
            value: translations['BUSINESS_GLOSSARY:FILTER:OPTION:PERSONAL_DATA_ITEM'],
            label: translations['BUSINESS_GLOSSARY:FILTER:OPTION:PERSONAL_DATA_ITEM'],
          },
          {
            value: translations['BUSINESS_GLOSSARY:FILTER:OPTION:PERSONAL_DATA_CATEGORY'],
            label: translations['BUSINESS_GLOSSARY:FILTER:OPTION:PERSONAL_DATA_CATEGORY'],
          },
          {
            value: translations['BUSINESS_GLOSSARY:FILTER:OPTION:PURPOSE_OF_PROCESSING'],
            label: translations['BUSINESS_GLOSSARY:FILTER:OPTION:PURPOSE_OF_PROCESSING'],
          },
        ];

        const typeColumnDropdownOptions = [
          {
            id: translations['BUSINESS_GLOSSARY:FILTER:OPTION:PERSONAL_DATA_ITEM'],
            type: translations['BUSINESS_GLOSSARY:FILTER:OPTION:PERSONAL_DATA_ITEM'],
          },
          {
            id: translations['BUSINESS_GLOSSARY:FILTER:OPTION:PERSONAL_DATA_CATEGORY'],
            type: translations['BUSINESS_GLOSSARY:FILTER:OPTION:PERSONAL_DATA_CATEGORY'],
          },
          {
            id: translations['BUSINESS_GLOSSARY:FILTER:OPTION:PURPOSE_OF_PROCESSING'],
            type: translations['BUSINESS_GLOSSARY:FILTER:OPTION:PURPOSE_OF_PROCESSING'],
          },
        ];

        this.selectedFilterTypeOption = this.typeFilterOptions[0];

        this.businessGlossaryGridConfig['columnDefs'] = this.businessGlossaryGridConfig['columnDefs'].map(column => {
          if (typeof COLUMNS_TRANSLATION_MAPPING[column['field']] != 'undefined') {
            column['name'] = translations[COLUMNS_TRANSLATION_MAPPING[column['field']]];
            column['displayName'] = translations[COLUMNS_TRANSLATION_MAPPING[column['field']]];
          }

          if (column['field'] === 'type') {
            column['editDropdownOptionsArray'] = typeColumnDropdownOptions;
            column['filter']['selectOptions'] = typeColumnFilterOptions;
          }

          return column;
        });

        this.onDataLoading();

        fetchGlossaryItems();
      });
    };

    this.onDataImport = () => {
      $uibModal
        .open({
          animation: true,
          template: "<import-modal $close='$close(isImported)' $dismiss='$dismiss()'></import-modal>",
          controllerAs: '$ctrl',
          resolve: {},
        })
        .result.then(isImported => {
          if (isImported) {
            this.onDataLoading();

            fetchGlossaryItems();
          }
        });
    };

    this.onDataExport = async () => {
      const closeButtonText = await $translate('BUTTONS:CLOSE');
      const actionButtonText = await $translate('BUTTONS:EXPORT');
      const headerText = await $translate('BUSINESS_GLOSSARY:EXPORT_MODAL:HEADER');
      const bodyText = await $translate('BUSINESS_GLOSSARY:EXPORT_MODAL:BODY');

      const modalOptions = { closeButtonText, actionButtonText, headerText, bodyText };

      DeleteConfirmation.showModal({}, modalOptions).then(() => {
        exportGlossaryItems();
      });
    };

    this.onRowDelete = async row => {
      const closeButtonText = await $translate('BUTTONS:CANCEL');
      const actionButtonText = await $translate('BUTTONS:DELETE');
      const headerText = await $translate('BUSINESS_GLOSSARY:DELETE_ROW_MODAL:HEADER');
      const bodyText = await $translate('BUSINESS_GLOSSARY:DELETE_ROW_MODAL:BODY', { entityValue: row.displayName });

      const modalOptions = { closeButtonText, actionButtonText, headerText, bodyText };

      DeleteConfirmation.showModal({}, modalOptions).then(() => {
        deleteGlossaryItem(row._id);
      });
    };

    this.onRowAdd = () => {
      rowAdded = true;
      this.businessGlossaryGridConfig['data'] = [
        ...this.businessGlossaryGridConfig['data'],
        Object.assign({}, NEW_ROW_ENTITY),
      ];
    };

    this.onTypeFilterChanged = option => {
      this.businessGlossaryGridConfig['data'] = getGroupData(option['value'], this.businessGlossaryGroupData);
      if (typeof scrollGridToTop === 'function') {
        scrollGridToTop();
      }
    };

    this.onExportButtonItemClick = item => {
      if (typeof item.action != 'undefined' && typeof this[item.action] == 'function') {
        this[item.action]();
      }
    };

    this.onGridScrollContextReady = context => {
      if (context && context.viewport) {
        if (typeof context.scrollToBottom == 'function') {
          scrollGridToBottom = context.scrollToBottom;
        }

        if (typeof context.scrollToTop == 'function') {
          scrollGridToTop = context.scrollToTop;
        }
      }
    };
  },
  bindings: {
    onDataLoading: '&',
    onDataLoaded: '&',
  },
});
