import { scansService, $state } from './angularServices';
import { DATA_SOURCES_INIT_VIEWS } from '../views/DataSources/DataSources';
import { DATA_SOURCES_PERMISSIONS } from '@bigid/permissions';
import { isPermitted } from './userPermissionsService';
import { getApplicationPreference } from './appPreferencesService';
import { CONFIG } from '../../config/common';

const openAutoDiscoveryInitPage = () => {
  $state.go('dataSourcesInit', { path: DATA_SOURCES_INIT_VIEWS.smallIdInit.path });
};

interface PageWhereOnboardingCall {
  name: string;
}

const noCustomScanProfiles = (data: any) => {
  if (data?.scanProfiles && data?.scanProfiles?.length) {
    return !data?.scanProfiles?.some(
      (scanProfile: { isCustomScanProfile: boolean; name: string }) =>
        scanProfile.isCustomScanProfile && scanProfile.name !== 'scan-api-default-profile',
    );
  }

  return true;
};

export const smallidOnboardingService = (forcedOrPageWhereOnboardingCall?: boolean | PageWhereOnboardingCall) => {
  const isAlreadyOnOnboardingPage =
    typeof forcedOrPageWhereOnboardingCall !== 'boolean' && forcedOrPageWhereOnboardingCall?.name === 'dataSourcesInit';
  const canBeOpened = !isAlreadyOnOnboardingPage && isPermitted(DATA_SOURCES_PERMISSIONS.CREATE.name);

  if (canBeOpened) {
    scansService.getScanProfilesData().then((data: any) => {
      if (noCustomScanProfiles(data) && !getApplicationPreference('IS_SMALLID_FOR_DEV')) {
        if (getApplicationPreference('FMSD_ENABLED')) {
          $state.go(CONFIG.states.FMSD);
        } else {
          openAutoDiscoveryInitPage();
        }
      }
    });
  }
};
