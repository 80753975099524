import { useMutation, UseMutationResult, MutationFunction, UseMutationOptions } from 'react-query';
import { ErrorType, BodyType } from '../../../../config/request';
import {
  type UpdateDsCollaboratorItemResponse,
  type UpdateDsCollaboratorItemRequest,
  type DataSourceCollaborator,
  type AsyncReturnType,
  CollaborationEventType,
} from '../types';
import { updateDsCollaborator, API_VERSION, DS_COLLABORATOR_ENDPOINT } from '../services/collaboration';

export type UpdateDsCollaboratorQueryResult = NonNullable<AsyncReturnType<typeof updateDsCollaborator>>;

export type UpdateDsCollaboratorQueryError = ErrorType<unknown>;

export const updateDsCollaboratorMutationKey = (
  dataSource: string,
  collaborators: DataSourceCollaborator[],
): Array<unknown> => [
  CollaborationEventType.UPDATE_COLLABORATORS,
  `${API_VERSION}/${DS_COLLABORATOR_ENDPOINT}/${dataSource}`,
  ...(collaborators ? [collaborators] : []),
];

export const useUpdateDsCollaborator = <TError = UpdateDsCollaboratorQueryError, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    AsyncReturnType<typeof updateDsCollaborator>,
    TError,
    { data: BodyType<UpdateDsCollaboratorItemRequest>; dataSource: string },
    TContext
  >;
}): UseMutationResult<
  UpdateDsCollaboratorItemResponse,
  TError,
  { data: BodyType<UpdateDsCollaboratorItemRequest>; dataSource: string },
  TContext
> => {
  const { mutation: mutationOptions } = options || {};
  // eslint-disable-next-line max-len
  const mutationFn: MutationFunction<
    AsyncReturnType<typeof updateDsCollaborator>,
    { data: BodyType<UpdateDsCollaboratorItemRequest>; dataSource: string }
  > = async props => {
    const { data, dataSource } = props || {};

    return updateDsCollaborator(dataSource, data);
  };

  return useMutation<
    AsyncReturnType<typeof updateDsCollaborator>,
    TError,
    { data: BodyType<UpdateDsCollaboratorItemRequest>; dataSource: string },
    TContext
  >(mutationFn, mutationOptions);
};
