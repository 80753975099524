import React from 'react';
import styled from '@emotion/styled';

export type ApplicationNoDataProps = {
  message: string;
};

const Wrapper = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Message = styled.div`
  position: relative;
  top: -30px;
`;

export const ApplicationNoData = ({ message }: ApplicationNoDataProps) => (
  <Wrapper>
    <Message>{message}</Message>
  </Wrapper>
);
