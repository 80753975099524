import { useContext } from 'react';
import {
  DataSourceConnectionModalContext,
  DataSourceConnectionModalContextType,
} from '../DataSourceConnectionModalProvider';

type UseDataSourceModalContextReturnType = DataSourceConnectionModalContextType;

export const useDataSourceModalContext = (): UseDataSourceModalContextReturnType => {
  const result = useContext(DataSourceConnectionModalContext);
  return result;
};
