import React from 'react';
import { BigidBody1, BigidColorsV2 } from '@bigid-ui/components';
import { styled } from '@mui/material';
import { useLocalTranslation } from '../../translations';

const ListContainer = styled('ul')`
  font-size: 0.875rem;
  color: ${BigidColorsV2.gray[700]};
  padding-left: 24px;
  margin-top: 4px;
  margin-bottom: 4px;
`;
const ListItem = styled('li')`
  font-weight: 400;
`;

export const FavoriteTemplateBody = () => {
  const { t } = useLocalTranslation('guidedTour.scanTemplates.favoriteTemplates');

  return (
    <div>
      <BigidBody1>{t('content')}</BigidBody1>
      <ListContainer>
        {(t('list', { returnObjects: true }) as string[]).map((text, index) => (
          <ListItem key={index}>{text}</ListItem>
        ))}
      </ListContainer>
    </div>
  );
};
