import React, { useState, useCallback, useRef } from 'react';
import styled from '@emotion/styled';
import {
  PrimaryButton,
  SecondaryButton,
  BigidDropZone,
  BigidDialog,
  BigidColorsV2,
  BigidForm,
  BigidFormStateAndHandlers,
} from '@bigid-ui/components';
import { uploadVendorFile } from './vendorsService';
import { notificationService } from '../../services/notificationService';
import { VendorsTrackingEvents } from './utils/analytics';
import { useVendorProjectContext } from './VendorProjectContext';

const ContentWrapper = styled.div({
  display: 'flex',
  flexDirection: 'column',
  flexWrap: 'nowrap',
  justifyContent: 'center',
  padding: '8px 8px 0',
  '& .bigidFormFields .Mui-error': {
    height: 'auto',
  },
  '& .bigidFormFields': {
    display: 'flex',
    flexDirection: 'column',
    gap: '16px',
    marginTop: '6px',
  },
  '& .bigidFormFields fieldset': {
    margin: 0,
  },
});

const ErrorTextWrapper = styled.p({
  color: BigidColorsV2.red[600],
});

export interface VendorAttachedFilesImportDialogProps {
  isOpen: boolean;
  vendorProjectId: string;
  onClose?: () => void;
  onUpload?: () => void;
}

export interface VendorAttachedFilesImportDialog {
  importDialogProps: VendorAttachedFilesImportDialogProps;
  openImportDialog: (vendorProjectId: string) => Promise<boolean>;
}

interface Error {
  fieldName: string;
  message: string;
}

const importInitialState: VendorAttachedFilesImportDialogProps = {
  isOpen: false,
  vendorProjectId: null,
};

export const useVendorAttachedFilesImportDialog = (): VendorAttachedFilesImportDialog => {
  const [importDialogProps, setImportDialogProps] = useState<VendorAttachedFilesImportDialogProps>(importInitialState);

  const openImportDialog = useCallback<VendorAttachedFilesImportDialog['openImportDialog']>(
    (vendorProjectId: string) => {
      return new Promise<boolean>(resolve => {
        setImportDialogProps({
          isOpen: true,
          vendorProjectId,
          onUpload: () => {
            setImportDialogProps(importInitialState);
            resolve(true);
          },
          onClose: () => setImportDialogProps(importInitialState),
        });
      });
    },
    [],
  );

  return {
    openImportDialog,
    importDialogProps,
  };
};

export const VendorAttachedFilesImportDialog = ({
  isOpen,
  vendorProjectId,
  onClose,
  onUpload,
}: VendorAttachedFilesImportDialogProps) => {
  const [fileToUpload, setFileToUpload] = useState<File | undefined>();
  const formRef = useRef<BigidFormStateAndHandlers>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [errors, setErrors] = useState<Error[]>([]);
  const { update: updateVendorContext } = useVendorProjectContext();
  const getError = (field: string) => errors.find(error => error.fieldName === field);
  const genericError = getError('generic');

  const handleOnDrop = useCallback((files: File[], isRejectedFilesFound: boolean) => {
    if (isRejectedFilesFound) {
      setFileToUpload(null);
      return;
    }
    const [file] = files;
    setFileToUpload(file);
    if (file?.name) {
      const nameWithoutExtension = file.name.slice(0, file.name.lastIndexOf('.'));
      formRef.current?.setValue('filename')(nameWithoutExtension);
    }
    setErrors([]);
  }, []);

  const resetStates = useCallback(() => {
    setFileToUpload(undefined);
    setErrors([]);
  }, []);

  const onCloseButtonClicked = () => {
    onClose();
    resetStates();
  };

  const onUploadButtonClicked = useCallback(async () => {
    formRef.current?.validateAndSubmit(async () => {
      setIsLoading(true);
      try {
        const formFields = formRef.current?.getValues();
        const vendorsFormData = new FormData();
        vendorsFormData.append('file', fileToUpload);
        vendorsFormData.append('filename', formFields?.filename);
        formFields?.description && vendorsFormData.append('description', formFields?.description);
        await uploadVendorFile(vendorProjectId, vendorsFormData);
        onUpload();
        resetStates();
        updateVendorContext();
      } catch (err) {
        const errorMessage = `Failed to upload ${fileToUpload?.name}`;
        notificationService.error(errorMessage);
        console.error(`${errorMessage}: ${JSON.stringify(err?.response)}`);
        const { errors, message } = err?.response?.data || {};
        if (errors?.length) {
          errors.forEach((e: { instance: string; message: string }) => {
            formRef.current?.setFieldError(e.instance, e.message);
          });
        } else if (message) {
          setErrors([{ fieldName: 'generic', message }]);
        }
      } finally {
        setIsLoading(false);
      }
    });
  }, [fileToUpload, onUpload, resetStates, updateVendorContext, vendorProjectId]);

  return (
    <BigidDialog
      isOpen={isOpen}
      title="Upload a new file"
      onClose={onCloseButtonClicked}
      maxWidth="xs"
      borderTop={true}
      isLoading={isLoading}
      buttons={[
        {
          component: props => (
            <SecondaryButton
              {...props}
              bi={{
                eventType: VendorsTrackingEvents.IMPORT_ATTACHED_FILE_DIALOG_CLOSE_CLICK,
              }}
            />
          ),
          onClick: onCloseButtonClicked,
          text: 'Cancel',
        },
        {
          component: props => (
            <PrimaryButton
              {...props}
              bi={{
                eventType: VendorsTrackingEvents.IMPORT_ATTACHED_FILE_DIALOG_UPLOAD_CLICK,
              }}
            />
          ),
          onClick: onUploadButtonClicked,
          text: 'Upload',
          disabled: !fileToUpload,
        },
      ]}
    >
      <ContentWrapper>
        <BigidDropZone maxSize={15} files={fileToUpload ? [fileToUpload] : []} multiple={false} onDrop={handleOnDrop} />
        <BigidForm
          fields={[
            {
              isRequired: true,
              label: 'File Name',
              name: 'filename',
              fieldProps: {
                size: 'large',
              },
              validate: value => {
                if (!value) {
                  return 'Mandatory field';
                }
                return false;
              },
            },
            {
              label: 'Description',
              name: 'description',
              fieldProps: {
                size: 'large',
              },
            },
          ]}
          stateAndHandlersRef={formRef}
          controlButtons={false}
        />
        {genericError && <ErrorTextWrapper>{genericError.message}</ErrorTextWrapper>}
      </ContentWrapper>
    </BigidDialog>
  );
};
