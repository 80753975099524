import React, { Fragment, FunctionComponent } from 'react';
import { BigidSwitcher as Switcher } from '../../../../../commonComponents/bigidSwitcher/bigidSwitcher.component';
import { AttributeInfo } from '../AttributeInfo/AttributeInfo';
import styles from './ReportRow.scss';
import { BigidWarningFilledIcon } from '@bigid-ui/icons';

const BigidSwitcher = Switcher as React.ComponentClass<any>;

export interface ReportRowProps {
  name: string;
  values?: string[];
  purposes?: string[];
  isEnabled?: boolean;
  isConsentViolated?: boolean;
  isLast?: boolean;
  hidePurpose?: boolean;
  onToggleClick: (isEnabled: boolean) => void;
  onConsentIconClick?: any;
  isEditable: boolean;
}

export const ReportRow: FunctionComponent<ReportRowProps> = ({
  name,
  values,
  purposes,
  isConsentViolated,
  onToggleClick,
  onConsentIconClick,
  isLast,
  isEnabled = true,
  hidePurpose,
  isEditable,
}) => {
  return (
    <Fragment>
      <div className={styles.wrapper}>
        <div className={styles.switcher}>
          {isEditable && <BigidSwitcher isEnabled={isEnabled} onToggledOutput={onToggleClick} />}
        </div>

        <div className={styles.consent}>
          {isConsentViolated && (
            <span onClick={onConsentIconClick}>
              <BigidWarningFilledIcon color="negative" />
            </span>
          )}
        </div>
        <div className={styles.infoWrapper}>
          <AttributeInfo label={name} values={values} />
          {!hidePurpose && <AttributeInfo lighter={true} label={'Purpose'} values={purposes} />}
        </div>
      </div>
      {!isLast && <hr className={`bigid-hr ${styles.bigidHr}`} />}
    </Fragment>
  );
};
