import {
  DataSourceSchedulerContainer,
  DataSourceSchedulerLabel,
  DataSourceSchedulerField,
} from './../DataSourceSchedulerStyles';

export const Scheduler = {
  Container: DataSourceSchedulerContainer,
  Label: DataSourceSchedulerLabel,
  Field: DataSourceSchedulerField,
};
