import { AutoDiscoveryWizardProfile } from './AutoDiscoveryWizard';
import { scansService } from '../../services/angularServices';
import { MetadataConnectionsResponse } from './MetadataConnectionTypes';
import { httpService } from '../../services/httpService';
import { getSupportedDataSources, DataSource } from '../../utilities/dataSourcesUtils';
import { appsInfo } from '../../services/autoDiscoveryService';
import { DataSourcesResponse } from '../DataSources/DataSourceConnections/DataSourceConnectionTypes';
import { getEncodedArray } from '../../services/queryService';
import { CustomAppStatus } from '../CustomApp/views/ActivityLog/ActivityLog';

export enum CloudProvider {
  AWS = 'aws',
  AZURE = 'azure',
}

export enum SaaSProvider {
  SAAS_CONNECTORS = 'saasConnectors',
}

export enum SaasConnectors {
  SALESFORCE = 'salesforce',
}

export interface ScanProfile {
  id: string;
  _id: string;
  name: string;
}

export interface AutoDiscoveryInfoResponse {
  actionId: string;
  tpa_id: string;
  isPreviousRunExisted: boolean;
  lastRunStatus: CustomAppStatus;
}

type AvilableFilterOptionsData = Record<string, string[]> & {
  type: string;
};

type FetchedDatasourceTypes = Pick<DataSource, 'displayName' | 'type'>;

const getScanProfileData = async (name: string) => {
  return scansService
    .getScanProfilesData()
    .then(response => {
      const { scanProfiles }: { scanProfiles: ScanProfile[] } = response;
      if (scanProfiles && scanProfiles.length) {
        const profile = scanProfiles.find(scanProfile => scanProfile.name === name);
        return profile;
      }
    })
    .catch(() => {
      return null;
    });
};

export const createScanProfiles = (profiles: Map<string, AutoDiscoveryWizardProfile>) => {
  return new Promise(async (resolve, reject) => {
    if (profiles.size) {
      for (const [key, { scanType, scanProfileName, selectedDsNames }] of profiles) {
        const scanProfileData: ScanProfile = await getScanProfileData(scanProfileName);
        const formData = new FormData();
        formData.append(
          'data',
          JSON.stringify({
            scanType: scanType,
            allEnabledIdSor: true,
            allEnabledDs: false,
            skipIdScan: true,
            isClassificationsAsPiiFindings: false,
            labelFramework: { id: 'mip', name: 'Labels' },
            name: scanProfileName,
            dataSourceList: selectedDsNames,
            owners: [],
            isCustomScanProfile: true,
          }),
        );
        if (scanProfileData) {
          if (selectedDsNames.length) {
            scansService.updateScanProfile(scanProfileData._id, formData).catch(error => reject(error));
          } else {
            scansService.deleteScanProfile(scanProfileData._id).catch(error => reject(error));
          }
        } else {
          if (selectedDsNames.length) {
            scansService.createScanProfile(formData).catch(error => reject(error));
          }
        }
      }
    }
    resolve(profiles);
  });
};

export const fetchAvailableDsTypes = async (): Promise<FetchedDatasourceTypes[]> => {
  try {
    const supportedDataSources = await getSupportedDataSources();
    const fetchedDsTypes = [];
    const {
      data: {
        data: { availableDsTypes },
      },
    } = await httpService.fetch<{ data: MetadataConnectionsResponse }>('ds-metadata');
    for (const ds of availableDsTypes) {
      const matchedDs = supportedDataSources.find(element => element.type === ds);
      fetchedDsTypes.push({ type: ds, displayName: matchedDs.displayName || matchedDs.name });
    }
    return fetchedDsTypes;
  } catch (e) {
    throw new Error('Error fetching from ds-metadata');
  }
};

export const fetchAvailableFilterOptions = async (fields: string[]): Promise<AvilableFilterOptionsData[]> => {
  try {
    const {
      data: { data },
    } = await httpService.fetch<{ data: AvilableFilterOptionsData[] }>(
      `ds-metadata-filter-values?fields=${JSON.stringify(fields)}`,
    );
    return data;
  } catch (e) {
    throw new Error('Error fetching from ds-metadata-filter-values');
  }
};

export const getAmountOfDiscoveredDs = async (type: CloudProvider) => {
  try {
    const filter = [
      {
        field: 'name',
        operator: 'contains',
        value: appsInfo[type]?.dsPrefix,
      },
    ];
    const {
      data: {
        data: { totalCount },
      },
    } = await httpService.fetch<{ data: DataSourcesResponse }>(
      `ds-connections?limit=1&filter=${getEncodedArray(filter)}`,
    );

    return totalCount;
  } catch (e) {
    throw new Error(e.message);
  }
};
