import React, { FC, useEffect, useState } from 'react';
import { $stateParams } from '../../services/angularServices';
import { CustomAppAction } from './views/CustomAppActions/CustomAppActions';
import { ActivityLog } from './views/ActivityLog/ActivityLog';
import { AppInfo, CustomAppPagesEnum } from './utils/CustomAppTypes';
import { CustomAppPageWrapper } from './CustomAppPageWrapper';
import { getCustomAppActionsDetails, setCustomAppInfo } from './utils/CustomAppUtils';
import { isEmpty, isNull } from 'lodash';

export const CustomAppActivityLogPage: FC = () => {
  const [appInfo, setAppInfo] = useState<AppInfo>(null);
  const [actions, setActions] = useState<CustomAppAction[]>(null);

  useEffect(() => {
    const updateAppInfo = async () => {
      if (appInfo) return;
      setCustomAppInfo($stateParams.appInfo, $stateParams.id, setAppInfo);
      setActions(await getCustomAppActionsDetails($stateParams.id));
    };
    updateAppInfo();
  }, [appInfo]);

  return (
    <CustomAppPageWrapper appInfo={appInfo} pageName={'Activity Log'} isReady={!isEmpty(appInfo) && !isNull(actions)}>
      <ActivityLog appInfo={appInfo} actions={actions} />
    </CustomAppPageWrapper>
  );
};
