import { httpService } from './httpService';
import { ReactText } from 'react';

export interface TagModel {
  _id: string;
  tag_type?: 'system_tag';
  tag_name: string;
  tag_value: string;
  cluster_ids?: ReactText[];
  query?: string;
}

interface Tag {
  name: string;
  value: string;
}

interface CreateTagResponse {
  insertedId: string;
}

type AttachTagToClusterResponse = TagModel;
type DetachTagFromClusterResponse = TagModel;

export type GetTagsResponse = TagModel[];

export const tagsService = {
  getAllTags: async (): Promise<TagModel[]> => {
    const { data } = await httpService.fetch<GetTagsResponse>(`saved-queries`);

    return data.filter(tag => tag.tag_type !== 'system_tag');
  },

  createTag: async (tag: Tag): Promise<string> => {
    return (
      await httpService.post<CreateTagResponse>(`saved-queries`, {
        tag_name: tag.name,
        tag_value: tag.value,
      })
    ).data.insertedId;
  },

  attachClusterToTag: async (tagId: string, clusterId: ReactText): Promise<AttachTagToClusterResponse> => {
    return (await httpService.put(`saved-queries/${tagId}/cluster/${clusterId}`)).data;
  },

  detachClusterFromTag: async (tagId: string, clusterId: ReactText): Promise<DetachTagFromClusterResponse> => {
    return (await httpService.delete<DetachTagFromClusterResponse>(`saved-queries/${tagId}/cluster/${clusterId}`)).data;
  },
};
