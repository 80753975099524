import React, { Dispatch, FC, ReactText, useEffect, useState, SetStateAction } from 'react';
import styled from '@emotion/styled';
import { BigidTooltip } from '@bigid-ui/components';
import { generateDataAid } from '@bigid-ui/utils';
import { FindingDetails, CuratedFieldStatus, MatchType, FindingCurateProps } from '../../curationService';
import { getFindingHighlightColorByStatus, getHoveredLine } from '../../curationUtils';
import { CuratedFieldValuePreviewTooltip } from './CuratedFieldValuePreviewTooltip';
import { CuratedFieldValuePreviewExcludeDialog } from './CuratedFieldValuePreviewExcludeDialog';

export interface CuratedFieldValuePreviewFindingProps {
  dataAid: string;
  finding: FindingDetails;
  isPermittedToExclude?: boolean;
  onFindingCurate: (findingCurateProps: FindingCurateProps) => void;
  fieldId: ReactText;
}

interface StyledMarkProps {
  status: CuratedFieldStatus;
}

const StyledMark = styled('mark')<StyledMarkProps>`
  background-color: ${({ status }) => getFindingHighlightColorByStatus(status)};
  cursor: pointer;
  padding: 0;
`;

export const CuratedFieldValuePreviewFinding: FC<CuratedFieldValuePreviewFindingProps> = ({
  dataAid,
  finding,
  isPermittedToExclude,
  onFindingCurate,
  fieldId,
}) => {
  const popperRef = React.useRef(null);
  const areaRef = React.useRef<HTMLDivElement>(null);
  const positionRef = React.useRef<{ x: number; y: number }>({
    x: 0,
    y: 0,
  });

  const [findingDetails, setFindingDetails] = useState<FindingDetails>(finding);
  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);
  const [radioButtonValue, setRadioButtonValue] = useState<MatchType>();

  const handleFindingCurate = async ({ finding, status }: FindingCurateProps): Promise<void> => {
    const findingCurateProps = { finding, status, fieldId, matchType: radioButtonValue };
    await onFindingCurate(findingCurateProps);
  };

  useEffect(() => {
    setFindingDetails(finding);
  }, [finding]);

  const handleOnDialogStateChange = (isOpen: boolean) => {
    setIsDialogOpen(isOpen);
  };

  const handleExcludeSelectedOption = (option: MatchType) => {
    setRadioButtonValue(option);
  };

  const handleMouseMove = (event: React.MouseEvent) => {
    positionRef.current = { x: event.clientX, y: event.clientY };

    if (popperRef.current != null) {
      popperRef.current.update();
    }
  };

  return (
    <>
      <BigidTooltip
        title={
          <CuratedFieldValuePreviewTooltip
            dataAid={generateDataAid(dataAid, ['tooltip'])}
            finding={findingDetails}
            onFindingCurate={handleFindingCurate}
            onDialogStateChange={handleOnDialogStateChange}
            onExcludeOptionSelected={handleExcludeSelectedOption}
            fieldId={fieldId}
          />
        }
        isDisabled={!isPermittedToExclude}
        placement="top"
        arrow
        PopperProps={{
          popperRef,
          anchorEl: {
            getBoundingClientRect: () => {
              const OFFSET = 2;
              const rect = areaRef.current!.getBoundingClientRect();
              const lineHeight = Number(getComputedStyle(areaRef.current).lineHeight?.replace('px', ''));
              const hoveredLine = getHoveredLine(areaRef.current, positionRef.current.y, lineHeight);
              const topPosition = rect.y + lineHeight * hoveredLine + OFFSET;

              return new DOMRect(positionRef.current.x, topPosition, 0, 0);
            },
          },
        }}
      >
        <StyledMark
          ref={areaRef}
          onMouseMove={handleMouseMove}
          status={findingDetails.reviewStatus}
          data-aid={generateDataAid(dataAid, ['mark'])}
        >
          {findingDetails.findingValue}
        </StyledMark>
      </BigidTooltip>
      <CuratedFieldValuePreviewExcludeDialog
        dataAid={generateDataAid(dataAid, ['dialog'])}
        finding={findingDetails}
        onDialogStateChange={handleOnDialogStateChange}
        isOpen={isDialogOpen}
        excludeSelectedOption={radioButtonValue}
        onExcludeOptionSelected={handleExcludeSelectedOption}
        fieldId={fieldId}
        onFindingCurate={handleFindingCurate}
      />
    </>
  );
};
