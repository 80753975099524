import React, { FC, useContext, useEffect, useState } from 'react';
import { BigidList, BigidListItem } from '@bigid-ui/components';
import { Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { AccessIntelligenceDashboardContext } from './AccessIntelligenceDashboardContext';
import { UserPermission } from '../UsersPermissions/UsersPermissionsService';

const useStyles = makeStyles(() => ({
  wrapper: {
    backgroundColor: '#fff',
    borderRadius: 12,
    padding: '15px 20px',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    height: '100%',
  },
  listContainer: {
    marginTop: 40,
    width: '90%',
  },
  emptyStateText: {
    textAlign: 'center',
  },
}));

const defineTopUsersWithShardObjectsListItems = (topUsersWithSharedObjects: UserPermission[]): BigidListItem[] => {
  return topUsersWithSharedObjects?.map(userInfo => ({
    id: userInfo._id,
    name: userInfo.email,
    value: userInfo.sharedObjectsCount?.toString(),
  }));
};

export const TopUsersWithSharedObjects: FC = () => {
  const classes = useStyles({});
  const [chartData, setChartData] = useState<BigidListItem[]>([]);
  const { dashboardData } = useContext(AccessIntelligenceDashboardContext);
  const { topUsersWithSharedObjects } = dashboardData;

  useEffect(() => {
    if (topUsersWithSharedObjects) {
      const chartData = defineTopUsersWithShardObjectsListItems(topUsersWithSharedObjects);
      setChartData(chartData);
    } else {
      setChartData([]);
    }
  }, [topUsersWithSharedObjects]);

  return (
    <div className={classes.wrapper} data-aid={'topUsersInsight'}>
      <Typography variant={'h4'}>Top Users With Open Access</Typography>
      <div className={classes.listContainer}>
        {chartData?.length === 0 ? (
          <Typography className={classes.emptyStateText} variant={'body1'} data-aid="ImportDataSourcesTitle">
            No Data
          </Typography>
        ) : (
          <BigidList
            nameColumnHeader={'Email'}
            valueColumnHeader={'Open Accesses Count'}
            showHeader={true}
            listItems={chartData}
          />
        )}
      </div>
    </div>
  );
};
