import { useState, useEffect } from 'react';
import { commonMethods, $state, privacyComplianceService } from '../../../services/angularServices';
import { CONFIG } from '../../../../config/common';
import { DashboardUpperSectionItemData } from '../DashboardUpperSection/DashboardUpperSectionCommon';
import { POLICIES_PERMISSIONS } from '@bigid/permissions';
import { isPermitted } from '../../../services/userPermissionsService';
import { loginService } from '../../../../authentication/login/login.service';
import { BigidDataPolicyIllustration } from '@bigid-ui/icons';

interface PolicyComplianceItem {
  id: string;
  count: number;
}

const policyDisplayData = (data: PolicyComplianceItem[]): DashboardUpperSectionItemData => {
  const count = data.reduce((acc: number, { count }: { count: number }) => acc + count, 0);
  const failedCount = data.find(({ id }: PolicyComplianceItem) => id === 'fail')?.count || 0;
  const disabled = !isPermitted(POLICIES_PERMISSIONS.ACCESS.name);
  return {
    value: failedCount ? String(`${failedCount}/${count}`) : count ? 'All Done' : '',
    description: failedCount ? 'Policies Triggered' : count ? 'No Policies Triggered' : 'Configure Policies',
    onClick: () => {
      $state.go(CONFIG.states.POLICIES, {
        filter: failedCount ? commonMethods.encodeUrlParams({ isFailed: true }) : '',
      });
    },
    Icon: BigidDataPolicyIllustration,
    disabled,
    isLoading: true,
    id: 'policy',
  };
};

interface PolicyDataNaiveCacheInterface {
  isNotInitiated: boolean;
  policyData: DashboardUpperSectionItemData;
}

const naiveCacheInitialState: PolicyDataNaiveCacheInterface = {
  isNotInitiated: true,
  policyData: policyDisplayData([]),
};

let naiveCache: PolicyDataNaiveCacheInterface = { ...naiveCacheInitialState };

export const usePolicyData = () => {
  const [policyData, setPolicyData] = useState(naiveCache.policyData);
  const [isLoading, setIsPolicyLoading] = useState(naiveCache.isNotInitiated);

  useEffect(() => {
    privacyComplianceService
      .getComplianceSummaries()
      .then(({ compliance_summaries }: { compliance_summaries: PolicyComplianceItem[] }) => {
        const displayData = policyDisplayData(compliance_summaries);
        naiveCache.policyData = displayData;
        naiveCache.isNotInitiated = false;
        setPolicyData(displayData);
      })
      .finally(() => {
        setIsPolicyLoading(false);
      })
      .catch(() => {
        setPolicyData(current => ({ ...current, disabled: true }));
      });

    return () => {
      if (!loginService.isLoggedIn()) {
        naiveCache = { ...naiveCacheInitialState };
      }
    };
  }, [setPolicyData]);

  return {
    ...policyData,
    isLoading,
  };
};
