import styled from '@emotion/styled';

export const HeaderImage = styled.div`
  display: flex;
  flex-direction: row;
  border-radius: 8px;
  border: 1px ${({ theme }) => `solid ${theme.vars.palette.bigid.gray200}`};
  width: 32px;
  height: 32px;
  justify-content: center;
  align-items: center;
`;

export const HeaderWrapper = styled.div`
  text-wrap: nowrap;
  text-overflow: ellipsis;
  display: flex;
  align-items: center;
  gap: 8px;
`;
