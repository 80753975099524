import React, { FC, useMemo, useState, useEffect, useCallback } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import {
  BigidDatePickerLazy,
  BigidHeading6,
  BigidDropdown,
  BigidDropdownOption,
  BigidDropdownValue,
} from '@bigid-ui/components';
import { MetadataSearchFilterComponentBaseProps } from '../../MetadataSearchFiltersTypes';
import { getMetadataSearchMapKeys } from '../../../MetadataSearchService';
import {
  MetadataSearchFilterDateModifier,
  MetadataSearchFilterDateModifierLabelMap,
  getIsDateValid,
} from './MetadataSearchFilterDateUtils';

export type MetadataSearchFilterDateProps = MetadataSearchFilterComponentBaseProps;

const useStyles = makeStyles({
  root: {
    width: '100%',
    display: 'flex',
    flexWrap: 'wrap',
  },
  label: {
    marginBottom: '8px',
  },
  control: {
    padding: '8px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    width: '50%',
    '& legend': {
      width: 'auto',
    },
  },
  flexBreak: {
    flexBasis: '100%',
    height: 0,
  },
});

type RangedDateValue = {
  toDate: Date;
  fromDate: Date;
};

export const MetadataSearchFilterDate: FC<MetadataSearchFilterDateProps> = ({
  dataAid,
  config,
  value: initialValue,
  onFilterChange,
  isFiltersFetching,
}) => {
  const classes = useStyles();
  const { fieldName, specialValueFieldName, fieldType, displayName, specialValues = [] } = config;
  const label = displayName || fieldName;

  const [genericOptionSelected, setGenericOptionSelected] = useState<BigidDropdownValue>([]);
  const [specificOptionSelected, setSpecificOptionSelected] = useState<RangedDateValue>({
    toDate: null,
    fromDate: null,
  });

  const genericOptions: BigidDropdownOption[] = useMemo(() => {
    const [any, ...rest] = getMetadataSearchMapKeys(MetadataSearchFilterDateModifierLabelMap).map(
      (modifier: MetadataSearchFilterDateModifier) => ({
        id: modifier,
        displayValue: MetadataSearchFilterDateModifierLabelMap[modifier],
        value: modifier,
      }),
    );

    return [any, ...specialValues, ...rest];
  }, [specialValues]);

  useEffect(() => {
    if (Array.isArray(initialValue?.value)) {
      const [fromDateStringified, toDateStringified] = initialValue.value;
      const fromDate = new Date(fromDateStringified?.toString());
      const toDate = new Date(toDateStringified?.toString());

      setGenericOptionSelected([
        genericOptions.find(({ value }) => value === MetadataSearchFilterDateModifier.SPECIFIC),
      ]);
      setSpecificOptionSelected({
        fromDate: getIsDateValid(fromDate) ? fromDate : null,
        toDate: getIsDateValid(toDate) ? toDate : null,
      });
    } else {
      if (initialValue?.isKeyword) {
        setGenericOptionSelected([
          genericOptions.find(({ value }) => value === initialValue?.value) || genericOptions[0],
        ]);
      } else {
        setGenericOptionSelected([genericOptions[0]]);
      }
    }
  }, [genericOptions, initialValue]);

  useEffect(() => {
    const isAnyValue = genericOptionSelected?.[0]?.value === MetadataSearchFilterDateModifier.ANY;

    if (isAnyValue) {
      setSpecificOptionSelected({
        toDate: null,
        fromDate: null,
      });
    }
  }, [genericOptionSelected]);

  const handleOnSelectGenericOption = useCallback(
    (selectedOption: BigidDropdownOption[]): void => {
      const { value, displayValue } = selectedOption[0];
      const isAnyValue = value === MetadataSearchFilterDateModifier.ANY;
      const isSpecificValue = value === MetadataSearchFilterDateModifier.SPECIFIC;

      setGenericOptionSelected(selectedOption);

      if (!isSpecificValue) {
        onFilterChange({
          field: fieldName,
          specialField: !isAnyValue ? specialValueFieldName : null,
          value: isAnyValue ? null : value,
          operator: 'equal',
          fieldType,
          isKeyword: true,
          fieldDisplayValue: displayValue,
          fieldDisplayName: label,
        });
      }
    },
    [fieldName, fieldType, label, onFilterChange, specialValueFieldName],
  );

  const handleFromDateOnChange = useCallback(
    (date: Date): void => {
      if (getIsDateValid(date)) {
        const toDate = specificOptionSelected?.toDate || new Date();
        const isToDateEarlier = date.getTime() > toDate.getTime();
        const newSpecificOptionSelected = {
          fromDate: date,
          toDate: isToDateEarlier ? date : toDate,
        };

        setSpecificOptionSelected(newSpecificOptionSelected);
        onFilterChange({
          field: fieldName,
          value: [newSpecificOptionSelected.fromDate.toString(), newSpecificOptionSelected.toDate.toString()],
          operator: 'equal',
          fieldType,
          fieldDisplayName: label,
        });
      }
    },
    [fieldName, fieldType, label, onFilterChange, specificOptionSelected?.toDate],
  );

  const handleToDateOnChange = useCallback(
    (date: Date): void => {
      if (getIsDateValid(date)) {
        const fromDate = specificOptionSelected?.fromDate || new Date();
        const isToDateEarlier = date.getTime() < fromDate.getTime();
        const newSpecificOptionSelected = {
          fromDate: isToDateEarlier ? date : fromDate,
          toDate: date,
        };

        setSpecificOptionSelected(newSpecificOptionSelected);
        onFilterChange({
          field: fieldName,
          value: [newSpecificOptionSelected.fromDate.toString(), newSpecificOptionSelected.toDate.toString()],
          operator: 'equal',
          fieldType,
          fieldDisplayName: label,
        });
      }
    },
    [fieldName, fieldType, label, onFilterChange, specificOptionSelected],
  );

  return (
    <div className={classes.root}>
      <div className={classes.control}>
        <BigidHeading6 data-aid={`${dataAid}-label`} className={classes.label}>
          {label}
        </BigidHeading6>
        <BigidDropdown
          dataAid={`${dataAid}-generic`}
          options={genericOptions}
          value={genericOptionSelected}
          onSelect={handleOnSelectGenericOption}
          isDisabled={isFiltersFetching}
        />
      </div>
      {genericOptionSelected[0]?.value === MetadataSearchFilterDateModifier.SPECIFIC && (
        <>
          <div className={classes.flexBreak} />
          <div className={classes.control}>
            <BigidHeading6 data-aid={`${dataAid}-label`} className={classes.label}>
              Between
            </BigidHeading6>
            <BigidDatePickerLazy
              dataAid={`${dataAid}-field-specific-from-date`}
              placeholder="Select start date"
              disableFuture
              inputProps={{
                readOnly: true,
                placeholder: 'Select start date',
              }}
              value={specificOptionSelected.fromDate}
              onChange={handleFromDateOnChange}
              disabled={isFiltersFetching}
            />
          </div>
          <div className={classes.control}>
            <BigidDatePickerLazy
              dataAid={`${dataAid}-field-specific-to-date`}
              placeholder="Select end date"
              inputProps={{
                readOnly: true,
                placeholder: 'Select end date',
              }}
              value={specificOptionSelected.toDate}
              onChange={handleToDateOnChange}
              disableFuture
              minDate={specificOptionSelected.fromDate}
              disabled={isFiltersFetching}
            />
          </div>
        </>
      )}
    </div>
  );
};
