import React, { FC } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { BigidColorsV2, BigidIconType } from '@bigid-ui/components';

export interface DataDiscoveryItem {
  Icon: BigidIconType;
  text: string;
}

export interface DataDiscoveryItemsProps {
  items: DataDiscoveryItem[];
}

const useStyles = makeStyles({
  wrapper: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    color: BigidColorsV2.gray[700],
    marginBottom: 58,
  },
  item: {
    width: '33.33%',
    textAlign: 'center',
    padding: '0 16px',
    minWidth: 230,
    marginBottom: 32,
    fontWeight: 700,
  },
  textBlock: {
    display: 'block',
    maxWidth: 150,
    margin: '0 auto',
  },
  iconWrapper: {
    display: 'flex',
    height: 120,
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: 4,
  },
});

export const DataDiscoveryItems: FC<DataDiscoveryItemsProps> = ({ items }) => {
  const { wrapper, item, iconWrapper, textBlock } = useStyles();

  return (
    <div className={wrapper}>
      {items.map(({ Icon, text }) => (
        <div key={text} className={item}>
          <div className={iconWrapper}>
            <Icon />
          </div>
          <span className={textBlock}>{text}</span>
        </div>
      ))}
    </div>
  );
};
