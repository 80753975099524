import React, { Fragment, FunctionComponent, useState, useEffect } from 'react';
import angular from 'angular';
import { convertToAngular } from '../../../common/services/convertToAngular';
import styles from './ThycoticConfig.scss';
import { PrimaryButton, SecondaryButton, BigidLoader } from '@bigid-ui/components';
import { ThycoticData, thycoticService } from '../../services/thycoticService';
import { ConfigInputItem } from './ConfigInputItem';
import { intersection, keys, isEmpty, isEqual, pick } from 'lodash';
import { $rootScope } from 'ngimport';
import { appsLicenseService } from '../../services/appsLicenseService';

import { APPLICATIONS_PERMISSIONS } from '@bigid/permissions';
import { isPermitted } from '../../services/userPermissionsService';
import { BigidTestIcon } from '@bigid-ui/icons';

interface ThycoticComponentProps {
  onCancel: () => void;
}

interface ThycoticState {
  errorItems: Array<string>;
  oldConfiguration: ThycoticData;
  newConfiguration: ThycoticData;
  changedFields: Array<string>;
  shouldFetchConfiguration: boolean;
  [propName: string]: any;
}

enum ThycoticAuthMethods {
  USER_PASSWORD = 'userPassword',
  SDK_CLIENT = 'sdkClient',
}

const THYCOTIC_BASE_ITEMS: ConfigurationFields[] = [
  { key: 'name', title: 'Connection Name' },
  {
    key: 'auth_method',
    title: 'Authentication Method',
    options: [
      { label: 'USER PASSWORD', value: ThycoticAuthMethods.USER_PASSWORD },
      { label: 'SDK CLIENT', value: ThycoticAuthMethods.SDK_CLIENT },
    ],
    isMultiSelect: true,
  },
  { key: 'url', title: 'Vault URL' },
];

const THYCOTIC_USER_PASSWORD_ITEMS: ConfigurationFields[] = [
  { key: 'role_id', title: 'User Name', isPassword: false },
  { key: 'secret_id', title: 'Password', isPassword: true },
];

interface ConfigurationFields {
  key: string;
  title: string;
  options?: { label: string; value: ThycoticAuthMethods }[];
  isPassword?: boolean;
  isNotRequired?: boolean;
  isMultiSelect?: boolean;
}

export function useAsyncEffect(effect: () => Promise<any>) {
  useEffect(() => {
    effect().catch(e => console.warn('useAsyncEffect error', e));
  });
}

export const ThycoticDialogComponent: FunctionComponent<ThycoticComponentProps> = ({
  onCancel,
}: ThycoticComponentProps) => {
  const initialState: ThycoticState = {
    newConfiguration: {
      name: '',
      auth_method: 'userPassword',
      url: '',
      bind_secret_id: false,
      secret_id: '',
      role_id: '',
    },
    errorItems: [],
    oldConfiguration: null,
    changedFields: [],
    shouldFetchConfiguration: true,
  };
  const [state, setState] = useState<ThycoticState>(initialState);
  const [configurationFields, setConfigurationFields] = useState<ConfigurationFields[]>([
    ...THYCOTIC_BASE_ITEMS,
    ...THYCOTIC_USER_PASSWORD_ITEMS,
  ]);

  useAsyncEffect(async () => {
    if (state.shouldFetchConfiguration) {
      await thycoticService.getConfiguration(configuration => {
        setState({
          ...state,
          ...(configuration && { oldConfiguration: configuration, newConfiguration: configuration }),
          shouldFetchConfiguration: false,
        });

        $rootScope.$applyAsync();
      });
    }
  });
  useEffect(() => {
    appsLicenseService.showAppExpirationNotification('thycotic');
  }, []);

  useEffect(() => {
    const { newConfiguration } = state;
    const userPasswordFields = [...THYCOTIC_BASE_ITEMS, ...THYCOTIC_USER_PASSWORD_ITEMS];
    setConfigurationFields(userPasswordFields);
  }, [state]);

  const updateChangedFields = (): void => {
    const changedFields = configurationFields
      .filter(item => {
        return state.oldConfiguration
          ? state.newConfiguration[item.key] !== state.oldConfiguration[item.key]
          : state.newConfiguration[item.key] !== initialState.newConfiguration[item.key];
      })
      .map(({ key }) => key);
    if (changedFields.includes('secret_id')) {
      changedFields.push('bind_secret_id');
    }
    if (!isEqual(changedFields, state.changedFields)) {
      setState({ ...state, changedFields });
    }
  };

  useEffect(updateChangedFields, [configurationFields, initialState.newConfiguration, state]);

  const isFormValid = () => {
    const errorItems = getFieldsWithErrors();
    setState({ ...state, errorItems });
    return errorItems.length === 0;
  };

  const getFieldsWithErrors = () => {
    return configurationFields
      .filter(({ isNotRequired, key }) => !isNotRequired && !state.newConfiguration[key])
      .map(({ key }) => key);
  };

  const saveConfiguration = () => {
    const { newConfiguration, oldConfiguration } = state;
    const confToSave = removeIrrelevantFieldsBeforeSave(newConfiguration);

    if (isFormValid()) {
      if (oldConfiguration) {
        const updatedFields = intersection(state.changedFields, keys(confToSave));
        updatedFields.push('auth_method');
        const configuration = pick(confToSave, updatedFields);
        const configurationToProceed = { ...confToSave, id: newConfiguration._id };
        thycoticService.updateConfiguration({
          configuration,
          id: newConfiguration._id,
        });
        setState({ ...state, errorItems: [], changedFields: [], oldConfiguration: configurationToProceed });
      } else {
        thycoticService.createConfiguration(confToSave, id => {
          const configuration = { ...confToSave, _id: id };
          setState({
            ...state,
            errorItems: [],
            changedFields: [],
            oldConfiguration: configuration,
            newConfiguration: configuration,
          });
        });
      }
    }
  };

  const removeIrrelevantFieldsBeforeSave = (newConfiguration: ThycoticData) => {
    const authConfFields = [...THYCOTIC_USER_PASSWORD_ITEMS, { key: 'bind_secret_id', title: '' }];
    return Object.entries(newConfiguration).reduce((result, [key, value]) => {
      if (isKeyInConfType(THYCOTIC_BASE_ITEMS, key) || isKeyInConfType(authConfFields, key)) {
        result[key] = value;
      }
      return result;
    }, {} as ThycoticData);
  };

  const isKeyInConfType = (conf: ConfigurationFields[], keyToFind: string) => {
    return conf.find(({ key }) => key === keyToFind);
  };

  const testConnection = () => {
    const {
      newConfiguration: { _id, ...configuration },
    } = state;
    if (isFormValid()) {
      thycoticService.testConfiguration({ ...configuration });
      setState({ ...state, errorItems: [] });
    }
  };

  const handleChange = (name: string, value: string) => {
    if (name === 'secret_id') {
      const hasSecretId = value !== '';
      setState({
        ...state,
        newConfiguration: { ...state.newConfiguration, [name]: value, bind_secret_id: hasSecretId },
      });
    } else {
      setState({ ...state, newConfiguration: { ...state.newConfiguration, [name]: value } });
    }
    updateChangedFields();
  };

  const isSavePermitted = state.oldConfiguration
    ? isPermitted(APPLICATIONS_PERMISSIONS.EDIT_THYCOTIC.name)
    : isPermitted(APPLICATIONS_PERMISSIONS.CREATE_THYCOTIC.name);

  return (
    <Fragment>
      <div className="modal-header">
        <span className="modal-header-text">Thycotic Password Vault</span>
        <img
          className="close-image"
          onClick={() => onCancel()}
          src="/images/close_icon.svg"
          data-aid="thycotic-close-dialog"
        />
      </div>
      {state.shouldFetchConfiguration ? (
        <div className={styles.loader}>
          <BigidLoader />
        </div>
      ) : (
        <div className={styles.modalBody}>
          {configurationFields.map(item => (
            <ConfigInputItem
              key={item.key}
              title={item.title}
              field={item.key}
              hasError={state.errorItems.includes(item.key)}
              onChange={handleChange}
              value={state.newConfiguration[item.key]}
              isPassword={item.isPassword}
              isRequired={!item.isNotRequired}
              options={item.options}
              isMultiSelect={item.isMultiSelect}
            />
          ))}
        </div>
      )}
      <div className="modal-footer">
        <div className={styles.buttonGrouping}>
          <SecondaryButton dataAid={'thycotic2-dialog-cancel'} onClick={onCancel} size="medium" text="Cancel" />
          <div className={styles.primaryButtons}>
            {isPermitted(APPLICATIONS_PERMISSIONS.TEST_CONNECTION_THYCOTIC.name) && (
              <div className={styles.testConnectionButton}>
                <PrimaryButton
                  dataAid={'thycotic2-dialog-test-connection'}
                  onClick={testConnection}
                  size="medium"
                  startIcon={<BigidTestIcon color="white" />}
                  text="Test Connection"
                />
              </div>
            )}
            {isSavePermitted && (
              <PrimaryButton
                dataAid={'thycotic2-dialog-save'}
                onClick={saveConfiguration}
                size="medium"
                disabled={isEmpty(state.changedFields) || getFieldsWithErrors().length !== 0}
                text="Save"
              />
            )}
          </div>
        </div>
      </div>
    </Fragment>
  );
};

angular
  .module('app')
  .component('thycoticDialog', convertToAngular<ThycoticComponentProps>(ThycoticDialogComponent, ['onCancel']));
