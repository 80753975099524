import './userAttributesAndSystems.component.css';
import template from './userAttributesAndSystems.component.html';
import { module } from 'angular';
const app = module('app');

app.component('userAttributesAndSystems', {
  template,
  controllerAs: 'userAttributesAndSystemsModel',
  controller: [
    '$http',
    'appSettings',
    'userDetailsService',
    '$scope',
    'notificationService',
    function ($http, appSettings, userDetailsService, $scope, notificationService) {
      const userAttributesAndSystemsModel = this;

      userAttributesAndSystemsModel.$onChanges = function () {
        userAttributesAndSystemsModel.loading = true;

        userDetailsService.getUserDetailsData(userAttributesAndSystemsModel.id).then(function (result) {
          userAttributesAndSystemsModel.loading = false;
          if (!angular.isDefined(result.user)) {
            notificationService.error('User not found!');
          } else {
            userAttributesAndSystemsModel.tabs = [
              {
                title: 'Attributes (' + result.user.all_attributes.length + ')',
              },
              {
                title: 'Data Sources (' + result.user.all_systems.length + ')',
              },
            ];

            result.user.purposes_data_local = [];
            for (let i = 0; i < result.user.all_attributes.length; i++) {
              result.user.purposes_data_local.push({
                name: result.user.all_attributes[i].name,
                purposes: result.user.all_attributes[i].purposes,
              });
            }
            const purposesArr = [];
            for (let i = 0; i < result.user.purposes_data_local.length; i++) {
              //add attribute name
              purposesArr.push({ title: result.user.purposes_data_local[i].name });
              purposesArr[i].systems = result.user.purposes_data_local[i].purposes;
            }
            userAttributesAndSystemsModel.tabs[0].groups = purposesArr;

            const systemsArr = [];
            userAttributesAndSystemsModel.tabs[1].groups = [];

            for (let i = 0; i < result.user.all_systems.length; i++) {
              systemsArr.push({ title: result.user.all_systems[i].name });
              systemsArr[i].systems = [];
              for (let j = 0; j < result.user.all_systems[i].attributes.length; j++) {
                systemsArr[i].systems.push(result.user.all_systems[i].attributes[j]);
              }
            }
            userAttributesAndSystemsModel.tabs[1].groups = systemsArr;
          }
        });
      };
    },
  ],
  bindings: {
    id: '<',
  },
});
