import { createContext } from 'react';
import { DiscoveryErrors, CloudProvider } from '../constants';
import { AutoDiscoveryConfigModel } from '../AutoDiscoveryGrid/AutoDiscoveryGridConfiguration';
import { CredsStatus } from '../../Fmsd/FmsdComponents';
import { BigidFieldRenderProps, BigidSelectOption, ValidateAndSubmitFn } from '@bigid-ui/components';

export interface AutoDiscoveryWizardForm extends Record<string, any> {
  authType?: BigidSelectOption[];
  name?: string;
}

export enum AutoDiscoverWizardAsideExpandedStates {
  MINIMIZED = 'minimized',
  OPENED = 'opened',
  PREREQUISITES = 'prerequisites',
  HOW_TO_CONNECT = 'howToConnect',
  OPENED_ERROR = 'openedError',
  PERMISSIONS_SUMMARY = 'permissionsSummary',
  CONNECTION = 'connection',
  DISCOVERY_CONFIGURATION = 'discoveryConfiguration',
  DS = 'ds',
  DS_STRUCTURED = 'dsStructured',
  DS_UNSTRUCTURED = 'dsUnstructured',
  COLLABORATION = 'collaboration',
}

export enum AutoDiscoveryPresetConfigTabsEnum {
  connection = 'connection',
  discovery = 'discovery',
  audit = 'audit',
}

export interface AutoDiscoveryWizardContextState {
  formData?: AutoDiscoveryWizardForm;
  type?: CloudProvider;
  configData?: AutoDiscoveryConfigModel;
  configDataMultiply?: AutoDiscoveryConfigModel;
  connectionInfo?: {
    credsStatus: CredsStatus;
    permissions: string[];
  };
  isCheck?: boolean;
  isDiscovery?: boolean;
  isScan?: boolean;
  errorMessage?: string;
  errorMessageKey?: DiscoveryErrors;
  fieldsNamesByAuthTypeFields?: string[];
  asideExpandedState?: AutoDiscoverWizardAsideExpandedStates;
  collaborators?: Record<string, any>[];
  collaborationText?: string;
  tab?: AutoDiscoveryPresetConfigTabsEnum;
  id?: string;
  isEdit?: boolean;
  isLoadingPreset?: boolean;
  preset?: Record<string, any>;
  initialValues?: Record<string, any>;
  validateAndSubmit?: ValidateAndSubmitFn;
  setErrors?: (newErrors: Record<string, any>) => void;
  formTouched?: boolean;
  getFieldProps?: (fieldname: string) => BigidFieldRenderProps<any, any>;
  isTypeNotFound?: boolean;
  isIdNotFound?: boolean;
}

export interface AutoDiscoveryWizardContextInterface {
  discoveryConfigData?: AutoDiscoveryWizardContextState;
  setDiscoveryConfigData?: React.Dispatch<React.SetStateAction<AutoDiscoveryWizardContextState>>;
  discoveryConfigDataRef?: React.MutableRefObject<AutoDiscoveryWizardContextState>;
}

export const DEFAULT_AUTO_DISCOVERY_CONFIG_STATE = {
  formData: {},
  asideExpandedState: AutoDiscoverWizardAsideExpandedStates.HOW_TO_CONNECT,
  tab: AutoDiscoveryPresetConfigTabsEnum.connection,
};

export const AutoDiscoveryWizardContext = createContext<AutoDiscoveryWizardContextInterface>({});
