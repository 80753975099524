import { BigidGridQueryComponents } from '@bigid-ui/grid';
import { RecurringSchedule, SchedulerSettingsWithCronExpression } from '@bigid-ui/components';
import { NormalizedClassifier } from '../../Classification/ClassifiersService';
import { MutableRefObject } from 'react';

export enum ScanProfileConversionTypeEnum {
  TEMPLATE = 'template',
  SCAN = 'scan',
  SCAN_WITH_EXISTING_TEMPLATE = 'scanUsingExistingTemplate',
}

export interface GetScanTemplateQueryProps {
  maxTemplates?: number;
  searchString?: string;
  filter?: BigidGridQueryComponents['filter'];
}

export interface ScanProfileConversionFormData {
  name: string;
  templateName?: string;
  templateId: string;
  owners: string[];
  conversionType: ScanProfileConversionTypeEnum;
  scanSchedule?: RecurringSchedule;
}

export interface ScanProfileFields {
  _id: string;
  name: string;
  scanType: string;
  owners?: string[];
  schedule?: string | SchedulerSettingsWithCronExpression;
  active: boolean;
  description?: string;
  allEnabledDs?: boolean;
  dataSourceList?: string[];
  created_at: Date;
  allEnabledIdSor?: boolean;
  idsorList?: string[];
  scanTemplateId?: string;
  labelFramework?: {
    id: string;
    name: string;
  };
  skipIdScan: boolean;
  isClassificationsAsPiiFindings?: boolean;
  isOverwritePrivilegedLabels?: boolean;
  isOverwriteTag?: boolean;
  classifiers: Pick<NormalizedClassifier, 'type' | 'name'>[];
  isReviewFindingsEnabled: boolean;
}

export interface ScanProfileConversionFormProps {
  scanProfileFields: ScanProfileFields;
}

export interface GetConvertProfileToScanButtonsParams {
  setIsSchedule: (isSchedule: boolean) => void;
  scanProfileConversionFormData: ScanProfileConversionFormData;
  scanProfileFields: ScanProfileFields;
  isSchedule: boolean;
  scanProfileConversionBasicHandlersAndRef: MutableRefObject<any>;
}

export type CreateScanParams = Extract<
  GetConvertProfileToScanButtonsParams,
  'scanProfileFields' | 'isSchedule' | 'scanProfileConversionFormData'
>;
