import { BigidDialog, PrimaryButton, SecondaryButton, BigidTextField, objectToQueryString } from '@bigid-ui/components';
import angular from 'angular';
import { convertToAngular } from '../../../common/services/convertToAngular';
import React, { FC, useState, useEffect } from 'react';
import { httpService } from '../../services/httpService';
import { notificationService } from '../../services/notificationService';
import makeStyles from '@mui/styles/makeStyles';

export interface ExportCollectionDialogProps {
  isOpen: boolean;
  onClose: () => void;
}

const useStyles = makeStyles({
  formInputs: {
    '& > :first-child': {
      marginBottom: '10px',
    },
  },
});

export const ExportCollectionDialog: FC<ExportCollectionDialogProps> = ({ isOpen, onClose }) => {
  const [collectionNameValue, setCollectionNameValue] = useState<string>('');
  const [filterValue, setFilterValue] = useState<string>('');
  const [disabled, setDisabled] = useState<boolean>(true);
  const classes = useStyles({});

  const handleAction = async () => {
    const query = objectToQueryString({
      filter: filterValue,
    });

    const pathToDownload = `download-scan-results-files/file-download/${collectionNameValue}${
      query ? '?' + query : ''
    }`;

    try {
      await httpService.downloadFile(pathToDownload);
      onClose();
    } catch (e) {
      notificationService.error('An error has occurred');
      console.error(e.message);
    }
  };

  const collectionNameChange = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    setCollectionNameValue(event.target.value);
  };

  const filterChange = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    setFilterValue(event.target.value);
  };

  useEffect(() => {
    setDisabled(collectionNameValue.length === 0);
  }, [collectionNameValue]);

  return (
    <BigidDialog
      isOpen={isOpen}
      onClose={onClose}
      title="Export Collection"
      buttons={[
        { component: SecondaryButton, onClick: onClose, text: 'Cancel' },
        { component: PrimaryButton, onClick: handleAction, text: 'Download', disabled },
      ]}
    >
      <div className={classes.formInputs}>
        <BigidTextField label="Collection Name" onChange={collectionNameChange} />
        <BigidTextField label="Filter" onChange={filterChange} />
      </div>
    </BigidDialog>
  );
};

angular
  .module('app')
  .component(
    'exportCollectionDialog',
    convertToAngular<ExportCollectionDialogProps>(ExportCollectionDialog, ['isOpen', 'onClose']),
  );
