import React, { FC, useEffect, useMemo, useState, useCallback } from 'react';
import { styled } from '@mui/material';
import { LayoutCustomComponentProps } from '@bigid-ui/layout';
import {
  BigidWorldMap,
  BigidWorldMapImageSeries,
  BigidWorldMapLineSeries,
  BigidWorldMapLegendConfig,
  BigidWorldMapConfig,
  BigidColorsV2,
  BigidLoader,
  BigidAdvancedToolbarFilterUnion,
} from '@bigid-ui/components';
import { uniqueId } from 'lodash';
import { getDataSourcesImageData } from './utils';
import { AggregationType } from '../../../catalogDiscoveryTypes';
import { formatNumberCompact } from '../../../../../utilities/numericDataConverter';
import {
  getAggregatedData,
  GetAggregatedDataPayload,
  GetAggregatedDataResponse,
} from '../../../catalogDiscoveryService';
import { useFetchDataCancelable } from '../../../config/useFetchDataCancelable';
import { parseFilterOutputToQueryString } from '../../../filter/utils';

const Root = styled('div')`
  width: 100%;
  height: 100%;
  padding: 0 16px 16px 16px;
  position: relative;
`;

const MapContainer = styled('div')<{ isBusy: boolean }>`
  width: 100%;
  height: 100%;
  ${({ isBusy }) =>
    isBusy &&
    `
      filter: blur(1.5px);
      opacity: 0.8;
    `};
`;

export enum DataSourceLayoutWorldMapCategory {
  DATA_SOURCE = 'dataSource',
}

export const DataSourceLayoutWorldMap: FC<LayoutCustomComponentProps> = ({ useFetchState }) => {
  const { fetchDataSourceLocationsCancelable } = useFetchDataCancelable();
  const [imageData, setImageData] = useState<BigidWorldMapImageSeries[]>();
  const [lineData] = useState<BigidWorldMapLineSeries[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { chartId, mapConfig, legendConfig } = useMemo(() => {
    const chartId = `DataSourceLayoutWorldMap-${uniqueId()}`;
    const legendConfig: BigidWorldMapLegendConfig = {
      isInteractive: false,
    };
    const mapConfig: BigidWorldMapConfig = {
      defaultZoomLevel: 0,
      categories: [
        {
          displayName: 'Data Sources',
          name: DataSourceLayoutWorldMapCategory.DATA_SOURCE,
          fillColor: BigidColorsV2.blue[400],
          strokeColor: BigidColorsV2.blue[600],
          getClusterTooltipContent: (images: BigidWorldMapImageSeries[]) => {
            const totalComputed = images.reduce((total, image) => total + (image?.value || 0), 0);

            return `<strong># of Findings</strong>: ${formatNumberCompact(totalComputed, 1)}`;
          },
        },
      ],
    };

    return {
      chartId,
      mapConfig,
      legendConfig,
    };
  }, []);

  const fetchDataSourceLocations = useCallback(
    async (query: string) => {
      try {
        setIsLoading(true);

        const payload: GetAggregatedDataPayload = {
          filter: query,
          aggregations: [
            {
              aggName: AggregationType.DATA_SOURCE_LOCATION,
              sorting: [
                {
                  field: 'findings',
                  order: 'DESC',
                },
              ],
            },
          ],
        };
        const { aggregations } = await fetchDataSourceLocationsCancelable(
          getAggregatedData(payload) as Promise<GetAggregatedDataResponse>,
        );

        const dataSourcesImageData = getDataSourcesImageData(aggregations[0]?.aggData || []);
        setImageData(dataSourcesImageData);
      } catch ({ message }) {
        console.error(`An error has occurred: ${message}`);
      } finally {
        setIsLoading(false);
      }
    },
    [fetchDataSourceLocationsCancelable],
  );

  useEffect(() => {
    const query = parseFilterOutputToQueryString(useFetchState.filter as unknown as BigidAdvancedToolbarFilterUnion[]);
    fetchDataSourceLocations(query);
  }, [useFetchState.filter, fetchDataSourceLocations]);

  return (
    <Root>
      {isLoading && <BigidLoader />}
      <MapContainer isBusy={isLoading}>
        <BigidWorldMap
          height={410}
          dataAid="DataSourceLayoutWorldMap"
          chartId={chartId}
          imageData={imageData}
          lineData={lineData}
          mapConfig={mapConfig}
          legendConfig={legendConfig}
        />
      </MapContainer>
    </Root>
  );
};
