import React, { Dispatch, FC, Fragment, SetStateAction, useRef, useState } from 'react';
import { BigidColors, BigidMenu, BigidMenuItemProps } from '@bigid-ui/components';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import makeStyles from '@mui/styles/makeStyles';
import { CategoriesEnum } from '../applicationManagementService';

interface CategoryMenuProps {
  selected: string;
  setSelected: Dispatch<SetStateAction<string>>;
}

const items = [
  { label: CategoriesEnum.ALL },
  { label: CategoriesEnum.PRIVACY },
  { label: CategoriesEnum.PROTECTION },
  { label: CategoriesEnum.PERSPECTIVE },
  { label: CategoriesEnum.UTILITIES },
  { label: CategoriesEnum.TICKETING },
];

const useStyles = makeStyles({
  selectedCategoryWrapper: {
    display: 'flex',
    alignItems: 'center',
    paddingBottom: 8,
    cursor: 'pointer',
  },
  selectedCategoryText: { fontSize: '1.125rem', color: BigidColors.gray[700], marginRight: 8 },
  selectedCategoryIcon: { fill: BigidColors.gray[700], height: 18, width: 18 },
});

export const CategoryMenu: FC<CategoryMenuProps> = ({ selected, setSelected }) => {
  const classes = useStyles({});
  const categoryMenuIconRef = useRef(null);
  const [isCategoryMenuOpen, setIsCategoryMenuOpen] = useState(false);

  const openCategoryMenu = () => setIsCategoryMenuOpen(true);
  const closeCategoryMenu = () => setIsCategoryMenuOpen(false);

  const itemsToDisplay = items.filter(({ label }) => label !== selected);

  return (
    <Fragment>
      <div
        className={classes.selectedCategoryWrapper}
        ref={categoryMenuIconRef}
        onClick={openCategoryMenu}
        data-aid="select-category-button"
      >
        <span className={classes.selectedCategoryText} data-aid="selected-category">
          {selected}
        </span>
        <ExpandMoreIcon className={classes.selectedCategoryIcon} />
      </div>
      <BigidMenu
        open={isCategoryMenuOpen}
        anchorEl={categoryMenuIconRef.current}
        onItemSelect={(item: BigidMenuItemProps) => setSelected(item.label)}
        items={itemsToDisplay}
        onMenuClose={closeCategoryMenu}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      />
    </Fragment>
  );
};
