import React, { FC, useEffect, useState, useRef } from 'react';
import { QuickSearchPopper, QuickSearchPopperProps } from './QuickSearchPopper';
import { QuickSearchResults } from './QuickSearchResults';
import { RecentlyViewedItemsPopper } from './RecentlyViewedResults';

const SHOW_SEARCH_RESULTS_TEXT_LENGTH_THRESHOLD = 2;

type RootResultsProps = Pick<QuickSearchPopperProps, 'anchorEl'> & {
  searchText: string;
};

export const RootResults: FC<RootResultsProps> = ({ anchorEl, searchText }) => {
  const [open, setOpen] = useState(false);
  const inputRef = useRef<HTMLInputElement | null>(null);

  useEffect(() => {
    const handleFocus = () => {
      setOpen(true);
    };

    const inputElement = anchorEl.current?.querySelector('input');
    inputElement?.addEventListener('focus', handleFocus);

    inputRef.current = inputElement;

    return () => {
      inputElement?.removeEventListener('focus', handleFocus);
    };
  }, [anchorEl]);

  const onClickOutsideHandleClose = (event: MouseEvent | TouchEvent) => {
    if (anchorEl.current && anchorEl.current.contains(event.target as Node)) {
      return;
    }
    setOpen(false);
  };

  const handleChildClick = () => {
    setOpen(false);
  };

  const showSearchResults = searchText.length > SHOW_SEARCH_RESULTS_TEXT_LENGTH_THRESHOLD;

  return (
    <QuickSearchPopper anchorEl={anchorEl} open={open} handleClose={onClickOutsideHandleClose}>
      <div onClick={handleChildClick}>
        {showSearchResults ? <QuickSearchResults searchText={searchText} /> : <RecentlyViewedItemsPopper />}
      </div>
    </QuickSearchPopper>
  );
};
