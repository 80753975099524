import { merge } from 'lodash';
import { fillReportTemplateWithDefaults } from '../reportTemplatesService';
import { ReportTemplate } from '../Types';

type PartialReportTemplate = Partial<ReportTemplate>;

export enum ReducerActions {
  'INIT',
  'UPDATE_SECTION',
  'UPDATE_METADATA',
  'UPDATE_IS_ENABLED',
  'START_LOADING',
  'RESET_PENDING_SAVE_DATA',
  'RESET',
  'UPDATE_DYNAMIC_RICH_TEXT_SECTION',
}

export interface ReportTemplateFormReducerAction {
  type: ReducerActions;
  payload: Partial<ReportTemplateFormReducerState & { sectionName: keyof ReportTemplate; isEnabled: boolean }>;
}

export interface ReportTemplateFormReducerState {
  templateId: string;
  hasEditPermission: boolean;
  initialTemplateData: PartialReportTemplate;
  data: PartialReportTemplate;
  dataPendingSave: {
    templateId: string;
    data: PartialReportTemplate;
    timeout?: number;
  };
}

export const reportTemplateFormReducer: React.Reducer<
  ReportTemplateFormReducerState,
  ReportTemplateFormReducerAction
> = (state, { type, payload }) => {
  switch (type) {
    case ReducerActions.UPDATE_IS_ENABLED: {
      const { sectionName, isEnabled } = payload;
      return {
        ...state,
        dataPendingSave: {
          templateId: state.templateId,
          data: mergePartialData(state.dataPendingSave?.data, { [sectionName]: { isEnabled } }),
          timeout: 0,
        },
      };
    }
    case ReducerActions.UPDATE_SECTION: {
      const newData = margeSection(state, payload);
      return {
        ...state,
        data: {
          ...state.data,
          ...newData,
        },
        dataPendingSave: {
          templateId: state.templateId,
          data: mergePartialData(state.dataPendingSave?.data, { ...newData }),
        },
      };
    }

    case ReducerActions.UPDATE_DYNAMIC_RICH_TEXT_SECTION: {
      const { data } = payload;
      return {
        ...state,
        data: {
          ...state.data,
          ...data,
        },
        dataPendingSave: {
          templateId: state.templateId,
          data: mergePartialData(state.dataPendingSave?.data, { ...data }),
        },
      };
    }

    case ReducerActions.RESET_PENDING_SAVE_DATA: {
      return {
        ...state,
        dataPendingSave: null,
      };
    }

    case ReducerActions.INIT: {
      return getInitialReducerState(payload);
    }

    case ReducerActions.RESET: {
      return getInitialReducerState({});
    }

    default:
      return state;
  }
};

export function getInitialReducerState({
  templateId,
  hasEditPermission,
  initialTemplateData,
  dataPendingSave,
}: Partial<ReportTemplateFormReducerState>): ReportTemplateFormReducerState {
  return {
    templateId,
    initialTemplateData,
    hasEditPermission,
    data: fillReportTemplateWithDefaults(initialTemplateData),
    dataPendingSave,
  };
}

function mergePartialData(a: PartialReportTemplate, b: PartialReportTemplate): PartialReportTemplate {
  const mergedObj: PartialReportTemplate = merge(a, b);
  return mergedObj;
}

function margeSection(
  state: ReportTemplateFormReducerState,
  payload: Partial<ReportTemplateFormReducerState & { sectionName: keyof ReportTemplate; isEnabled: boolean }>,
) {
  const { sectionName } = payload;
  return { [sectionName]: { ...(state.data[sectionName] as any), ...(payload.data[sectionName] as any) } };
}
