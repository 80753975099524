import { BigidWizardHorizontalNavStepData, BigidWizardHorizontalStepStatus } from '@bigid-ui/components';
import { FmsdConnect, FmsdCategories, FmsdRegulations } from './index';
import { FmsdCustomize } from './FmsdCustomize/FmsdCustomize';
import { getFixedT } from '../../translations';

export enum FmsdPreDiscoverViewsIds {
  CUSTOMIZE = 'customize',
  CONNECT = 'connect',
  DONE = 'done',
}

export enum FmsdPreDiscoverCustomizeTypes {
  CATEGORIES = 'categories',
  REGULATIONS = 'regulations',
}

export const fmsdCustomizeViews: Record<FmsdPreDiscoverCustomizeTypes, React.FunctionComponent> = {
  [FmsdPreDiscoverCustomizeTypes.CATEGORIES]: FmsdCategories,
  [FmsdPreDiscoverCustomizeTypes.REGULATIONS]: FmsdRegulations,
};

const fixedT = getFixedT('Stepper');

export const fmsdPreDiscoverViews: BigidWizardHorizontalNavStepData[] = [
  {
    id: FmsdPreDiscoverViewsIds.CUSTOMIZE,
    title: fixedT('customize'),
    component: FmsdCustomize,
  },
  {
    id: FmsdPreDiscoverViewsIds.CONNECT,
    title: fixedT('connect'),
    component: FmsdConnect,
  },
  {
    id: FmsdPreDiscoverViewsIds.DONE,
    title: fixedT('scan'),
  },
];

export const getFmsdPreDiscoverViews = (activeViewId: string): BigidWizardHorizontalNavStepData[] => {
  const currentIndex = fmsdPreDiscoverViews.findIndex(({ id }) => id === activeViewId);
  fmsdPreDiscoverViews[currentIndex].status = BigidWizardHorizontalStepStatus.ACTIVE;
  for (let i = currentIndex - 1; i >= 0; i--) {
    fmsdPreDiscoverViews[i].status = BigidWizardHorizontalStepStatus.DONE;
  }
  for (let j = currentIndex + 1; j < fmsdPreDiscoverViews.length; j++) {
    fmsdPreDiscoverViews[j].status = BigidWizardHorizontalStepStatus.DISABLED;
  }

  return fmsdPreDiscoverViews;
};
