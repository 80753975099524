import './add_edit_legend_modal.scss';
import template from './add_edit_legend.component.html';
import angular from 'angular';

angular.module('app').component('legend', {
  template,
  bindings: {
    flow: '<',
    $close: '&',
    $dismiss: '&',
  },
});
