import React, { FC, MutableRefObject, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useQuery } from 'react-query';
import { BigidGridWithToolbar, BigidGridWithToolbarProps, updateFiltersWithParams } from '@bigid-ui/grid';
import { DataSourceModel } from '../DataSourceConnectionTypes';
import { styled } from '@mui/material';
import { Global, css } from '@emotion/react';
import makeStyles from '@mui/styles/makeStyles';
import {
  BigidFilter,
  BigidFieldFilter,
  BigidFilterToolbar,
  BigidFilterToolbarProps,
  BigidPaper,
  BigidPopper,
  useFilterUpdateHandlers,
  ChartType,
  FunnelChartData,
  entityEventsEmitter,
  EntityEvents,
} from '@bigid-ui/components';
import { DsStateFunnel } from './components';
import { useLocalTranslation } from '../translations';
import { DataSourceSuggestedActions } from '../../DataSourceSuggestedActions/DataSourceSuggestedActions';
import {
  GetSuggestedActionsQueryResult,
  getSuggestedActionsQueryKey,
} from '../../DataSourceSuggestedActions/hooks/useGetSuggestedActions';
import { BigidSuggestedActionsIllustration } from '@bigid-ui/icons';
import { useDsOnboardingSse } from './hooks/useDsOnboardingSse';
import { useGetDsConnectionStatus } from './hooks/useGetDsConnectionStatus';
import {
  mapFunnelStatusToBigidFilter,
  mapDsConnectionEnrichmentStatusItemResponseToChartData,
} from './mappers/enrichment';
import {
  DS_ENRICHMENT_INITIATIVE_FIELD,
  DS_ENRICHMENT_INITIATIVE_FIELD_TITLE,
  DS_ENRICHMENT_STATUS_FIELD,
  DS_ENRICHMENT_STATUS_FIELD_TITLE,
} from './constants/enrichment';
import { useKey } from '../../DataSourceConfiguration/hooks/useKey';
import { useThrottle } from '../hooks/useThrottle';
import {
  omitInitiativeFilterFromCurrentFilter,
  omitStatusFilterFromCurrentFilter,
  updateChartSelection,
} from './utils';
import { DataSourcesUITrackingEvent, trackEventDataSources } from '../../DataSourcesEventTrackerUtils';
import { generateDataAid } from '@bigid-ui/utils';
import {
  DataSourceOnBoardingModel,
  DsFunnelStatus,
  Initiative,
  OnboardingEvent,
  UpdateUserPreferencesData,
} from './types';
import { getOnboardingEventEmitter } from './config/assistant';
import { localStorageService } from '../../../../services/angularServices';
import { useGetUserPreferences } from '../../../../hooks/useGetUserPreferences';
import { onboardingLayoutPreferenceKey } from './constants/gridConstants';
import { useUpdateUserPreferences } from '../../../../hooks/useUpdateUserPreferences';
import { SuggestedActionsPreviewPopper } from './SuggestedActionsPreviewPopper';

type OnboardingAssistantStyleProps = {
  disableSelectAll: boolean;
};

export const GlobalOnboardingAssistantStyles = (): JSX.Element => (
  <Global
    styles={css`
      [data-aid='BigidPopper'] > div > div {
        border-radius: 8px;
      }
    `}
  />
);

const useStyles = makeStyles(theme => ({
  funnelPaper: {
    justifyContent: 'center',
    alignItems: 'center',
    border: `1px solid ${theme.vars.palette.bigid.gray200}`,
    boxShadow: '0px 1px 12px 0px rgba(0, 0, 0, 0.05)',
    flexGrow: 1,
    display: 'flex',
    borderRadius: '4px',
    padding: '0',
    position: 'relative',
  },
  filterPaper: {
    width: '100%',
    border: `1px solid ${theme.vars.palette.bigid.gray200}`,
    boxShadow: '0px 1px 12px 0px rgba(0, 0, 0, 0.05)',
    borderRadius: '4px',
    padding: '4px 4px',
  },
}));

const Root = styled('div')`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  position: relative;

  & .MuiPopperUnstyled-root > div > .MuiPaper-root {
    background: none;
    box-shadow: none;
  }

  & [data-aid^='BigidForceSelectAllButton'] {
    display: ${({ disableSelectAll }: OnboardingAssistantStyleProps) => (disableSelectAll ? 'none' : 'initial')};
  }
`;

const Section = styled('div')`
  display: flex;
  width: 100%;
  flex-direction: row;
`;

export interface OnboardingAssitantLayoutProps {
  dataAid?: string;
  dsConnectionsGridConfig: BigidGridWithToolbarProps<DataSourceModel>;
  initiative: Initiative;
  filters: BigidFilter;
  searchTerm: string;
  isPopupOpen: boolean;
  anchor: MutableRefObject<HTMLDivElement>;
  filterConfig: Partial<BigidFilterToolbarProps>;
  isToolbarDisabled: boolean;
  onFilterChange: (filters: BigidFieldFilter[]) => void;
  isSelectAllDisabled: boolean;
  onClosePopup: (event: any) => void;
  fetchedDataSourcesNames: string[];
  emitRowSelectionEvent: boolean;
  onEmitRowSelectionEvent: () => void;
}

const emitter = getOnboardingEventEmitter();

const resetToolbarConfig = (config: Partial<BigidFilterToolbarProps>): Partial<BigidFilterToolbarProps> => {
  return {
    ...config,
    filters: config?.filters?.map(props => ({
      ...props,
      disabled: true,
      value: [],
    })),
  };
};

const getQueryFilters = (filters: BigidFilter): BigidFilter =>
  filters?.filter(({ field }) => field !== DS_ENRICHMENT_INITIATIVE_FIELD).sort() ?? null;

const checkFunnelChartSelection = (filters: BigidFilter) =>
  !filters.some(filter => filter.field === DS_ENRICHMENT_STATUS_FIELD);

const resetSearchConfig = (config: Partial<BigidFilterToolbarProps>): Partial<BigidFilterToolbarProps> => {
  return { ...config, searchConfig: { ...config?.searchConfig, initialValue: '' } };
};

export const OnboardingAssitantLayout: FC<OnboardingAssitantLayoutProps> = ({
  dataAid = 'OnboardingAssitantLayout',
  dsConnectionsGridConfig,
  isToolbarDisabled,
  isPopupOpen,
  isSelectAllDisabled,
  filterConfig: defaultFilterConfig,
  onFilterChange,
  onClosePopup,
  filters,
  initiative,
  anchor,
  fetchedDataSourcesNames,
  emitRowSelectionEvent,
  onEmitRowSelectionEvent,
}) => {
  /**
   * @info we need to differentiate between the initial config we get from user preferences
   * and the current state of filters after the component is mounted
   * hence @param isMounted used
   *  */
  const [isMounted, setMounted] = useState(false);
  const suggestedActionsRef = useRef();
  const [filterKey, updateFilterToolbar] = useKey();
  const [chartSelection, setChartSelection] = useState<[string, ChartType]>();
  const [chartData, setChartData] = useState<FunnelChartData[]>([]);
  const { funnelPaper, filterPaper } = useStyles();
  const queryFilters = useMemo(() => getQueryFilters(filters), [filters]);
  const [showGuidedTour, setShowGuidedTour] = useState(false);

  const shouldSkipGuidedTour = localStorageService.get<boolean>('skipOnboardingAssistantGuidedTour');

  const { data, isLoading: isLoadingSuggestedActions } = useQuery<GetSuggestedActionsQueryResult>(
    getSuggestedActionsQueryKey(queryFilters),
  );
  // @info we use throttling to circumvent an initial delay between reloading the toolbar and it applying values from an old configuration
  const getDsConnectionStatusFilters = useThrottle(filters => omitStatusFilterFromCurrentFilter(filters), 500, {
    trailing: true,
  });

  // @info fix for weird BigidFilterToolbar event trigger behavior
  const resetChartSelection = useThrottle(
    (shouldResetSelection: boolean) => shouldResetSelection && setChartSelection(null),
    500,
    {
      leading: false,
      trailing: true,
    },
  );

  const { t } = useLocalTranslation('OnboardingAssistant.labels');
  const appliedFilters = getDsConnectionStatusFilters(filters) as BigidFilter;
  const shouldShowEmpty = data ? data?.data?.suggestedActionsTotalCount === 0 : false;

  const {
    data: groupedStatusesResponse,
    isSuccess: isSuccessTotalCount,
    refetch: refreshDsStateFunnel,
  } = useGetDsConnectionStatus(appliedFilters);

  const { isLoading: isLoadingPreferences, data: userPreferences } =
    useGetUserPreferences<UpdateUserPreferencesData>(onboardingLayoutPreferenceKey);

  const { mutateAsync: updateUserPreferences } = useUpdateUserPreferences<UpdateUserPreferencesData>();

  const isPlannedGuidedTour = userPreferences?.data?.isPlannedGuidedTour ?? true;
  const shouldRunSuggestedActionsPreview =
    !isLoadingPreferences &&
    isPlannedGuidedTour &&
    !isLoadingSuggestedActions &&
    showGuidedTour &&
    isPopupOpen &&
    !shouldSkipGuidedTour &&
    !shouldShowEmpty;

  const handleFilterChange = useCallback((filters: BigidFieldFilter[]) => {
    return;
  }, []);

  const handleFilterChangeFromToolbar = useCallback(
    (filters: BigidFieldFilter[]) => {
      const shouldResetFunnelChart = checkFunnelChartSelection(filters);
      resetChartSelection(shouldResetFunnelChart);

      defaultFilterConfig && onFilterChange(filters);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [defaultFilterConfig, filterKey],
  );

  const { enabledFilters, disabledFilters, handleFilterApply, handleFilterClose } = useFilterUpdateHandlers(
    defaultFilterConfig?.filters,
    defaultFilterConfig?.searchConfig,
    handleFilterChange,
  );
  // @info after mounting rely on the state taken from filters
  const toolbarFilterConfig = useMemo(
    () =>
      updateFiltersWithParams(
        [...(filters ?? [])],
        !isMounted ? defaultFilterConfig : resetToolbarConfig(defaultFilterConfig),
      )?.filters,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [enabledFilters, disabledFilters],
  );

  const searchConfig = useMemo(
    () =>
      updateFiltersWithParams(
        [...(filters ?? [])],
        !isMounted ? defaultFilterConfig : resetSearchConfig(defaultFilterConfig),
      )?.searchConfig,
    [enabledFilters, disabledFilters],
  );

  useDsOnboardingSse({
    onEvent: () => {
      refreshDsStateFunnel();
    },
  });

  const handleFilterGrid = (filters: BigidFilter) => {
    // @info temporary solution until an appropriate event is included
    const activeInitiativeFilter = filters.find(({ field }) => field === DS_ENRICHMENT_INITIATIVE_FIELD);
    const omitInitiativeFilters = omitInitiativeFilterFromCurrentFilter(filters) ?? [];
    const initiativeFilterType = toolbarFilterConfig?.find(({ field }) => field === DS_ENRICHMENT_INITIATIVE_FIELD);
    const isEmpty = !activeInitiativeFilter;

    handleFilterClose(DS_ENRICHMENT_INITIATIVE_FIELD, DS_ENRICHMENT_INITIATIVE_FIELD_TITLE);
    !isEmpty && handleFilterApply({ ...initiativeFilterType, value: activeInitiativeFilter?.value, operator: 'equal' });

    onFilterChange([...omitInitiativeFilters, ...(isEmpty ? [] : [activeInitiativeFilter])]);
  };

  useEffect(() => {
    if (emitRowSelectionEvent) {
      onEmitRowSelectionEvent();
      setTimeout(() => {
        entityEventsEmitter.emit(EntityEvents.UPDATE_SELECTED_ROWS_IDS, {
          payload: { selectedRowIds: fetchedDataSourcesNames },
        });
      });
    }
  }, [emitRowSelectionEvent, fetchedDataSourcesNames]);

  const handleChartClick = (id: string, chartType: ChartType) => {
    trackEventDataSources(DataSourcesUITrackingEvent.DS_FUNNEL_CHART_CLICK, {
      chartType,
      status: id,
    });

    const statusFilter = mapFunnelStatusToBigidFilter(
      id as unknown as DsFunnelStatus,
      chartType,
      groupedStatusesResponse,
    );
    // @info omit funnel status filters before applying new ones
    const omitStatusFilters = omitStatusFilterFromCurrentFilter(filters) ?? [];
    const statusFilterType = toolbarFilterConfig?.find(({ field }) => field === DS_ENRICHMENT_STATUS_FIELD);
    const [activeStatusFilter] = statusFilter ?? [];
    const isEmpty = Array.isArray(activeStatusFilter?.value) ? activeStatusFilter?.value.length === 0 : false;

    handleFilterClose(DS_ENRICHMENT_STATUS_FIELD, DS_ENRICHMENT_STATUS_FIELD_TITLE);
    !isEmpty && handleFilterApply({ ...statusFilterType, value: activeStatusFilter?.value, operator: 'in' });

    onFilterChange([...omitStatusFilters, ...(isEmpty ? [] : statusFilter)]);
    setChartSelection([id, chartType]);
  };

  const handleCompleteTour = () => {
    updateUserPreferences({
      data: { preference: onboardingLayoutPreferenceKey, data: { isPlannedGuidedTour: false } },
    });
    localStorageService.set<boolean>('skipOnboardingAssistantGuidedTour', true);
    setShowGuidedTour(false);
  };

  useEffect(() => {
    updateFilterToolbar();

    // @info ensure an initial change event is sent on toolbar config change
    onFilterChange(filters);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [toolbarFilterConfig]);

  useEffect(() => {
    trackEventDataSources(DataSourcesUITrackingEvent.ADMINISTRATION_DATA_SOURCES_NEW_LAYOUT_PAGE_VIEW);
    setMounted(true);
  }, []);

  useEffect(() => {
    if (isSuccessTotalCount) {
      const chartData = mapDsConnectionEnrichmentStatusItemResponseToChartData(groupedStatusesResponse, t);
      const chartDataWithSelection = updateChartSelection(chartData, chartSelection);
      setChartData(chartDataWithSelection);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [groupedStatusesResponse]);

  useEffect(() => {
    const chartDataWithSelection = updateChartSelection(chartData, chartSelection);
    setChartData(chartDataWithSelection);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chartSelection]);

  useEffect(() => {
    const unregister = emitter.addEventListener(OnboardingEvent.RELOAD_FUNNEL_CHART, () => refreshDsStateFunnel());
    return () => unregister();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const dsOnboardingGridConfig = useMemo<BigidGridWithToolbarProps<DataSourceOnBoardingModel>>(() => {
    return {
      ...dsConnectionsGridConfig,
      syncButtonProps: {
        isSyncEnabled: true,
      },
    };
  }, [dsConnectionsGridConfig]);

  return (
    <Root disableSelectAll={isSelectAllDisabled}>
      <GlobalOnboardingAssistantStyles />
      <Section style={{ marginBottom: '8px', marginTop: '1px' }}>
        <BigidPaper dataAid={generateDataAid(dataAid, ['toolbar'])} classes={{ root: filterPaper }}>
          {isToolbarDisabled && (
            <BigidFilterToolbar
              key={filterKey}
              filters={toolbarFilterConfig}
              searchConfig={searchConfig}
              getFilterMenuStyles={defaultFilterConfig?.getFilterMenuStyles}
              onFiltersChange={handleFilterChangeFromToolbar}
            />
          )}
        </BigidPaper>
      </Section>
      <Section style={{ marginBottom: '8px' }}>
        <BigidPaper classes={{ root: funnelPaper }}>
          <DsStateFunnel enabled={true} loading={false} chartData={chartData} onClick={handleChartClick} />
        </BigidPaper>
      </Section>
      <Section style={{ flexGrow: 1, overflow: 'hidden' }}>
        <BigidPaper dataAid={generateDataAid(dataAid, ['grid'])}>
          <BigidGridWithToolbar {...dsOnboardingGridConfig} />
        </BigidPaper>
        <BigidPopper
          width={350}
          emptyStateTitle={'   '}
          emptyStateMessage={t('noSuggestedActions')}
          emptyStateIllustration={BigidSuggestedActionsIllustration}
          popperOffset={[0, 0]}
          anchorEl={anchor.current}
          isArrowDisabled
          isLoading={isLoadingSuggestedActions}
          isEmpty={shouldShowEmpty}
          keepMounted
          onClose={onClosePopup}
          open={isPopupOpen}
        >
          <div ref={suggestedActionsRef}>
            <DataSourceSuggestedActions
              title={t('suggestedActions')}
              filters={queryFilters}
              selected={initiative}
              onFilter={handleFilterGrid}
              onMount={() => setShowGuidedTour(true)}
            />
          </div>
        </BigidPopper>
      </Section>
      <SuggestedActionsPreviewPopper
        anchor={suggestedActionsRef}
        isOpen={shouldRunSuggestedActionsPreview}
        onClose={handleCompleteTour}
      />
    </Root>
  );
};
