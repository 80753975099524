import React, { FC, memo } from 'react';
import { BigidAddIcon, BigidImportIcon } from '@bigid-ui/icons';
import { isPermitted } from '../../services/userPermissionsService';
import { DATA_SOURCES_PERMISSIONS } from '@bigid/permissions';
import { DataSourcesUITrackingEvent } from '../../views/DataSources/DataSourcesEventTrackerUtils';
import { BigidButtonIcon, EntityEvents, entityEventsEmitter, PrimaryButton } from '@bigid-ui/components';
import { useLocalTranslation } from '../../views/DataSources/DataSourceConnections/translations';
import { openSelectDataSourceOnboarding } from '../../views/DataSources/SelectDataSourceOnboardingType/selectDataSourceOnboardingTypeService';
import { SSEDataMessage } from '../../services/sseService';
import { importDataSources } from '../../views/DataSources/DataSourceConnections/actions';
import { GRID_ID } from '../../views/DataSources/DataSourceConnections/constants';
import styled from '@emotion/styled';

export const CreateNewDataSourceButtonsStyled = styled('div')`
  display: flex;
  gap: 12px;
  align-items: center;
`;

interface StartDataSourceOnboardingButtonsProps {
  unregisterImportHandler: React.MutableRefObject<any>;
  onBroadCastEventFormImport: ({ results }: SSEDataMessage<any>) => void;
}

export const StartDataSourceOnboardingButtons: FC<StartDataSourceOnboardingButtonsProps> = memo(
  ({ unregisterImportHandler, onBroadCastEventFormImport }) => {
    const { t } = useLocalTranslation();
    return (
      <CreateNewDataSourceButtonsStyled data-aid={'CreateNewDataSourceButtons'}>
        {isPermitted(DATA_SOURCES_PERMISSIONS.IMPORT.name) && (
          <BigidButtonIcon
            onClick={async () => {
              const { shouldGridReload, shouldClearSelection } = await importDataSources(
                unregisterImportHandler,
                onBroadCastEventFormImport,
              );
              if (shouldGridReload) {
                entityEventsEmitter.emit(EntityEvents.RELOAD, GRID_ID);
              }
              if (shouldClearSelection) {
                entityEventsEmitter.emit(EntityEvents.UPDATE_SELECTED_ROWS_IDS, { payload: { selectedRowIds: [] } });
              }
            }}
            dataAid={'open-import-data-sources'}
            icon={BigidImportIcon}
          />
        )}
        {isPermitted(DATA_SOURCES_PERMISSIONS.CREATE.name) && (
          <PrimaryButton
            onClick={openSelectDataSourceOnboarding}
            dataAid={'add-data-source-button'}
            size="large"
            bi={{ eventType: DataSourcesUITrackingEvent.ADMINISTRATION_DATA_SOURCES_ADD_DATA_SOURCE_CLICK }}
            text={t('Header.buttons.createDataSource')}
            startIcon={<BigidAddIcon />}
          />
        )}
      </CreateNewDataSourceButtonsStyled>
    );
  },
);
