import { httpService } from '../../services/httpService';
import { BigidGridQueryComponents } from '@bigid-ui/grid';
import { queryService } from '../../services/queryService';
import { SystemUserResponseData, UsersData, Vendor, VendorAssessment, VendorProject } from './VendorsTypes';
import { getUsersQuery } from '../../utilities/systemUsersUtils';

export async function getVendorProjects(bigidGridQuery: BigidGridQueryComponents) {
  const gridConfigQuery = queryService.getGridConfigQuery(bigidGridQuery);
  const {
    data: { data },
  } = await httpService.fetch(`data-mapping/vendors/projects?checkHasVendorProjects=true&${gridConfigQuery}`);

  return data;
}

export async function getVendorProject(projectId: string): Promise<VendorProject> {
  const {
    data: { data },
  } = await httpService.fetch(`data-mapping/vendors/projects/${projectId}`);

  return data;
}

export async function updateVendorProject(projectId: string, payload: Partial<VendorProject>) {
  const {
    data: { data },
  } = await httpService.patch(`data-mapping/vendors/projects/${projectId}`, payload);

  return data;
}

export async function createVendorProject(payload: Pick<VendorProject, 'status'>) {
  const {
    data: { data },
  } = await httpService.post(`data-mapping/vendors/projects`, payload);

  return data;
}

export async function getVendors(): Promise<{ vendors: Vendor[] }> {
  const {
    data: { data },
  } = await httpService.fetch('data-mapping/vendors');

  return data;
}

export async function createVendor(payload: { name: string }): Promise<{ id: string }> {
  const {
    data: { data },
  } = await httpService.post('data-mapping/vendors', payload);

  return data;
}

export async function getDataActivities() {
  const gridConfigQuery = queryService.getGridConfigQuery({ limit: 1000 });
  const {
    data: { data },
  } = await httpService.fetch(`data-mapping/data-activities?${gridConfigQuery}`);

  return data;
}

export async function createDataActivity(body: { name: string }) {
  const {
    data: { data },
  } = await httpService.post('data-mapping/data-activities', body);

  return data;
}

export async function getVendorLogs(projectId: string, bigidGridQuery: BigidGridQueryComponents) {
  const gridConfigQuery = queryService.getGridConfigQuery(bigidGridQuery);
  const {
    data: { data },
  } = await httpService.fetch(`data-mapping/vendors/projects/${projectId}/audit-logs?${gridConfigQuery}`);

  return data;
}

export function downloadVendorsTemplate() {
  return httpService.downloadFile(`data-mapping/vendors/projects/import/template/file-download`);
}

export async function uploadVendors(broadcastEvent: string, payload: FormData) {
  const {
    data: { data },
  } = await httpService.post(
    `data-mapping/vendors/projects/import?broadcastEvent=${broadcastEvent}&overrideExisting=true`,
    payload,
  );

  return data;
}

export async function createAsset(payload: { name: string }): Promise<{ _id: string }> {
  const {
    data: { _id },
  } = await httpService.post('applications?exposeSystemId=true', payload);

  return { _id };
}

const MAX_USERS_IN_REQUEST = 100;

export async function getSystemUsers(searchString?: string, checkIsValid?: boolean): Promise<UsersData> {
  const queryParams = checkIsValid
    ? {
        filter: [
          {
            field: 'name',
            operator: 'in',
            value: [searchString],
          },
        ] as BigidGridQueryComponents['filter'],
        maxUsers: 1,
      }
    : { searchString, maxUsers: MAX_USERS_IN_REQUEST };

  const query = getUsersQuery(queryParams);
  const {
    data: { data },
  } = await httpService.fetch<SystemUserResponseData>(`system-users?${query}`);

  return data;
}

export async function deleteVendor(projectId: string) {
  const {
    data: { data },
  } = await httpService.delete(`data-mapping/vendors/projects/${projectId}`);

  return data;
}

export async function getVendorAttachedFiles(projectId: string, bigidGridQuery: BigidGridQueryComponents) {
  const gridConfigQuery = queryService.getGridConfigQuery(bigidGridQuery);
  const {
    data: { data },
  } = await httpService.fetch(`data-mapping/vendors/projects/${projectId}/files?${gridConfigQuery}`);

  return data;
}

export async function uploadVendorFile(projectId: string, payload: FormData) {
  const {
    data: { data },
  } = await httpService.post(`data-mapping/vendors/projects/${projectId}/files/import`, payload);

  return data;
}

export async function deleteAttachedFiles(projectId: string, bigidGridQuery: BigidGridQueryComponents) {
  const {
    data: { data },
  } = await httpService.post(`data-mapping/vendors/projects/${projectId}/files:bulk-delete`, {
    query: {
      filter: bigidGridQuery.filter,
    },
  });
  return data;
}

export async function deleteAttachedFile(projectId: string, fileId: string) {
  const {
    data: { data },
  } = await httpService.delete(`data-mapping/vendors/projects/${projectId}/files/${fileId}`);
  return data;
}

export function downloadAttachedFiles(projectId: string, bigidGridQuery: BigidGridQueryComponents) {
  const gridConfigQuery = queryService.getGridConfigQuery(bigidGridQuery);
  return httpService.downloadFile(`data-mapping/vendors/projects/${projectId}/files/file-download?${gridConfigQuery}`);
}

export function downloadAttachedFile(projectId: string, fileId: string) {
  return httpService.downloadFile(`data-mapping/vendors/projects/${projectId}/files/${fileId}/file-download`);
}

export async function getVendorAssessments(projectId: string): Promise<VendorAssessment[]> {
  const {
    data: {
      data: { tpas },
    },
  } = await httpService.fetch(`data-mapping/vendors/projects/${projectId}/assessments`);
  return tpas;
}
