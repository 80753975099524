import { BigidColors, BigidDialog } from '@bigid-ui/components';
import {
  BigidGridColumn,
  BigidGridColumnTypes,
  BigidGridQueryComponents,
  BigidGridWithToolbar,
  BigidGridWithToolbarProps,
} from '@bigid-ui/grid';
import { Paper } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import angular from 'angular';
import classNames from 'classnames';
import React, { FC, ReactNode, useEffect, useState } from 'react';
import { convertToAngular } from '../../../../../common/services/convertToAngular';
import { httpService } from '../../../../services/httpService';
import { scansService, $state } from '../../../../services/angularServices';
import { notificationService } from '../../../../services/notificationService';
import { queryService } from '../../../../services/queryService';
import { DateISO8601 } from '../../../../types/types';
import { isPermitted } from '../../../../services/userPermissionsService';
import { DSAR_PERMISSIONS } from '@bigid/permissions';

interface SarScanObject {
  id: string;
  name: string;
  identity_display_name: string;
  type: string;
  state: string;
  identity_unique_id: string;
  record_count: number;
  auth_user: string;
  profile_id: string;
  profile_name: string;
  data_expired: boolean;
  data_deleted: boolean;
  created_at: DateISO8601;
  started_at: DateISO8601;
  updated_at: DateISO8601;
  expires_on: DateISO8601;
  scan_progress_status: {
    Started: DateISO8601;
    Completed: DateISO8601;
  };
  info?: string;
}

interface SarParentScan extends SarScanObject {
  request_id: string;
  sub_scan_finished: number;
  sub_scans: SarSubScan[];
  totalCount: number;
  bulkId?: string;
}

interface SarSubScan extends SarScanObject {
  parent_scan_id: string;
  sub_scan_id: string;
  ds_connection_name: string;
  connectorType: string;
  scanner_group: string;
  parent_scan_type: string;
  is_report_ready: boolean;
  is_deletion_validation: boolean;
  scannerId: string;
  errorMessage: string;
  totalFailedCollections?: number;
}

export interface SarScansDialogProps {
  isOpen: boolean;
  onClose: () => void;
  parentScanId: string;
  displayName: string;
  autoRefresh?: boolean;
  gridId?: string;
}

interface RetryObject {
  subScanIds?: string[];
}

const useStyles = makeStyles({
  gridWrapper: {
    boxShadow: '0 0 6px rgba(0, 0, 0, 0.16)',
    display: 'flex',
    overflow: 'hidden',
    borderRadius: '4px 4px 0px 0px',
  },
  failed: {
    color: BigidColors.red[700],
  },
});

export const SarScansDialog: FC<SarScansDialogProps> = ({
  displayName,
  parentScanId,
  onClose,
  isOpen = false,
  gridId,
}) => {
  const classes = useStyles({});

  const [dialogTitle, setDialogTitle] = useState<string>(`Scan Progress`);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [hasFailureObject, setHasFailureObject] = useState<boolean>(false);
  const [selectedIds, setSelectedIds] = useState<(string | number)[]>([]);
  const [hasBulkId, setHasBulkId] = useState<boolean>(false);

  const handleDownloadFailedObjectReport = () => {
    setIsLoading(true);
    return scansService.downloadJitScanFailedObjectReport(parentScanId).finally(() => setIsLoading(false));
  };

  const handleRetryFailedObject = (subScanIds: string[]) => {
    setIsLoading(true);
    return retryFailedObject(parentScanId, subScanIds)
      .then(() => notificationService.success(`Rescan of failed objects for ${parentScanId} now in progress.`))
      .then(() => $state.go('subjectAccessRequest', { path: 'pending-requests' }))
      .catch(() => notificationService.error(`Error - could not rescan ${parentScanId} failed objects.`))
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleUpdateReport = () => {
    updateReport(parentScanId);
    notificationService.success(`Updating report for request ${parentScanId}`);
  };

  const handlePause = async (subScanIds: string[]) => {
    const {
      data: {
        data: { pausedPartsNumber },
      },
    } = await pause(parentScanId, subScanIds);
    notificationService.success(
      `Paused ${pausedPartsNumber} objects from the following sub scan IDs ${subScanIds.join(', ')}`,
    );
  };

  const handleResume = async (subScanIds: string[]) => {
    const {
      data: {
        data: { resumePartsNumber },
      },
    } = await resume(parentScanId, subScanIds);
    notificationService.success(
      `Resume ${resumePartsNumber} objects from the following sub scan IDs ${subScanIds.join(', ')}`,
    );
  };

  const gridWithToolbarConfig: BigidGridWithToolbarProps<SarSubScan> = {
    gridId,
    entityName: 'sub scans',
    preSelectedIds: selectedIds,
    onGridStateChange: state => setSelectedIds(state.selectedRowIds),
    fetchData: async gridQueryParams => {
      let subScans: SarSubScan[] = [];
      let totalCount = 0;
      try {
        if (parentScanId) {
          const { data } = await getSarParentScan(parentScanId, gridQueryParams);
          setHasBulkId(!!data.bulkId);
          totalCount = data.totalCount;
          subScans = data.sub_scans;
          setHasFailureObject(isFailureObjectExist(data.sub_scans));
          setDialogTitle(`${displayName || ''} Scan Progress`);
        }
      } catch (err) {
        notificationService.error('An error has occurred. Could not fetch scan progress.');
        console.error(err);
      }

      return {
        data: subScans,
        totalCount,
      };
    },
    columns: [
      {
        title: 'Name',
        name: 'name',
        type: BigidGridColumnTypes.TEXT,
        getCellValue: ({ name }) => name,
      },
      {
        title: 'Type',
        name: 'type',
        type: BigidGridColumnTypes.TEXT,
        width: 120,
        getCellValue: ({ type }) => type,
      },
      {
        title: 'State',
        name: 'state',
        type: BigidGridColumnTypes.CUSTOM,
        width: 110,
        getCellValue: ({ state }) => {
          return (<div className={classNames(state === 'Failed' && classes.failed)}>{state}</div>) as ReactNode;
        },
      },
      {
        title: 'Info',
        name: 'info',
        type: BigidGridColumnTypes.TEXT,
        width: 200,
        getCellValue: ({ info }) => info,
        sortingEnabled: false,
      },
      {
        title: 'Updated_at',
        name: 'updated_at',
        width: 200,
        type: BigidGridColumnTypes.DATE,
        getCellValue: ({ updated_at }) => updated_at,
      },
      {
        title: 'Started at',
        name: 'started_at',
        width: 200,
        type: BigidGridColumnTypes.DATE,
        getCellValue: ({ started_at }) => started_at,
      },
      {
        title: 'Ended at',
        name: 'scan_progress_status.Completed',
        width: 200,
        type: BigidGridColumnTypes.DATE,
        getCellValue: ({ scan_progress_status }) => scan_progress_status?.Completed ?? '',
      },
    ] as BigidGridColumn<SarSubScan>[],
    toolbarActions: [
      {
        label: 'Retry Failed Objects',
        execute: async ({ selectedRowIds, selectedRows }) => {
          const subScanIds: string[] = !selectedRowIds.length
            ? []
            : selectedRows.filter(row => row.state === 'Failed' || row.totalFailedCollections > 0).map(({ id }) => id);
          await handleRetryFailedObject(subScanIds);
          return { shouldGridReload: false };
        },
        disable: ({ selectedRowIds, selectedRows }) => {
          if (isLoading || !hasFailureObject) {
            return true;
          }
          return selectedRowIds?.length && !isFailureObjectExist(selectedRows);
        },
        show: () => isPermitted(DSAR_PERMISSIONS.MANAGE_REQUEST_SCAN_PROGRESS.name),
      },
      {
        label: 'Download Failed Object Report',
        isGlobal: true,
        execute: async () => {
          await handleDownloadFailedObjectReport();
          return { shouldGridReload: false };
        },
        disable: () => isLoading,
        show: ({ selectedRowIds }) =>
          selectedRowIds.length === 0 && isPermitted(DSAR_PERMISSIONS.DOWNLOAD_REQUEST_REPORT.name),
      },
      {
        label: 'Update Report',
        isGlobal: true,
        execute: async () => {
          handleUpdateReport();
          return { shouldGridReload: false };
        },
        disable: () => isLoading,
        show: ({ selectedRowIds }) =>
          selectedRowIds.length === 0 && isPermitted(DSAR_PERMISSIONS.MANAGE_REQUEST_SCAN_PROGRESS.name),
      },
      {
        label: 'Pause',
        isGlobal: false,
        execute: async ({ selectedRows }) => {
          handlePause(selectedRows.map(({ id }) => id));
          return { shouldGridReload: false };
        },
        disable: () => isLoading,
        show: ({ selectedRowIds, selectedRows }) =>
          selectedRowIds.length > 0 &&
          !hasBulkId &&
          selectedRows.filter(
            ({ state, type }) =>
              type === 'jit_scan' && (state === 'Queued' || state === 'InProgress' || state === 'Pending'),
          ).length === selectedRows.length &&
          isPermitted(DSAR_PERMISSIONS.MANAGE_REQUEST_SCAN_PROGRESS.name),
      },
      {
        label: 'Resume',
        isGlobal: false,
        execute: async ({ selectedRows }) => {
          handleResume(selectedRows.map(({ id }) => id));
          return { shouldGridReload: false };
        },
        disable: () => isLoading,
        show: ({ selectedRowIds, selectedRows }) =>
          selectedRowIds.length > 0 &&
          !hasBulkId &&
          selectedRows.filter(({ state, type }) => type === 'jit_scan' && state === 'Paused').length ===
            selectedRows.length &&
          isPermitted(DSAR_PERMISSIONS.MANAGE_REQUEST_SCAN_PROGRESS.name),
      },
    ],
  };

  useEffect(() => {
    setDialogTitle(`${displayName || ''} Scan Progress`);
    setHasFailureObject(false);
    setSelectedIds([]);
  }, [displayName, parentScanId]);

  const handleDialogClose = (): void => {
    onClose();
  };

  return (
    <BigidDialog
      isOpen={isOpen}
      onClose={handleDialogClose}
      onExit={handleDialogClose}
      title={dialogTitle}
      maxWidth="xl"
    >
      <Paper className={classes.gridWrapper}>
        <BigidGridWithToolbar {...gridWithToolbarConfig} />
      </Paper>
    </BigidDialog>
  );
};

function isFailureObjectExist(subScans: SarSubScan[]): boolean {
  return subScans.filter(subScan => subScan.state === 'Failed' || subScan.totalFailedCollections > 0).length > 0;
}

async function getSarParentScan(id: string, gridQueryParams: BigidGridQueryComponents) {
  const gridConfigQuery = queryService.getGridConfigQuery({ ...gridQueryParams, filter: [] });
  return httpService.fetch<SarParentScan>(`sar/scans/${encodeURIComponent(id)}?&${gridConfigQuery}`);
}

async function retryFailedObject(id: string, subScanIds: string[]) {
  return httpService.post<any, RetryObject>(`sar/reports/${encodeURIComponent(id)}/retry-failed-objects`, {
    subScanIds,
  });
}

function updateReport(requestId: string) {
  return httpService.put<any, any>(`sar/reports/${encodeURIComponent(requestId)}`);
}

function pause(requestId: string, subScanIds: string[]) {
  return httpService.post<any, any>(`sar/reports/${encodeURIComponent(requestId)}/pause`, {
    subScanIds,
  });
}

function resume(requestId: string, subScanIds: string[]) {
  return httpService.post<any, any>(`sar/reports/${encodeURIComponent(requestId)}/resume`, {
    subScanIds,
  });
}

angular
  .module('app')
  .component(
    'sarScansDialogReact',
    convertToAngular<SarScansDialogProps>(SarScansDialog, ['isOpen', 'onClose', 'parentScanId', 'displayName']),
  );
