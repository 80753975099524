import { useMemo } from 'react';
import {
  BigidFormField,
  BigidFormFieldLabelPosition,
  BigidFormFieldTypes,
  BigidFormValues,
} from '@bigid-ui/components';
import { useLocalTranslation } from './translations';
import { SaveQueryFormProps } from './SaveQueryForm';

export interface UseSaveQueryFormConfigProps extends Pick<SaveQueryFormProps, 'savedQueries'> {
  isNewFilter: boolean;
}

export interface UseSaveQueryFormConfigReturnData {
  fields: BigidFormField[];
}

export function useSaveQueryFormConfig({
  isNewFilter,
  savedQueries,
}: UseSaveQueryFormConfigProps): UseSaveQueryFormConfigReturnData {
  const { t } = useLocalTranslation('actions.saveQuery.form');

  const { fields } = useMemo(() => {
    const saveAsOptionsRadio: BigidFormField[] = !isNewFilter
      ? [
          {
            name: 'saveAsNew',
            type: BigidFormFieldTypes.RADIO,
            labelPosition: BigidFormFieldLabelPosition.left,
            options: [
              {
                value: true,
                label: t('fields.saveAsNew.label'),
              },
              {
                value: false,
                label: t('fields.saveAsCurrent.label'),
              },
            ],
          },
        ]
      : [];

    const fields: BigidFormField[] = [
      {
        type: BigidFormFieldTypes.TEXT,
        labelPosition: BigidFormFieldLabelPosition.top,
        name: 'name',
        label: t('fields.name.label'),
        misc: {
          fullWidth: true,
        },
        isRequired: true,
        validate: (value: string, values: BigidFormValues) => {
          const valueComputed = value?.trim();

          if (valueComputed?.length > 0) {
            const isExisted =
              values.saveAsNew !== 'false' &&
              Boolean(savedQueries.find(savedQuery => savedQuery.name === valueComputed));

            if (isExisted) {
              return t('fields.name.validation.alreadyExists');
            } else {
              return false;
            }
          } else {
            return t('fields.name.validation.empty');
          }
        },
      },
      {
        type: BigidFormFieldTypes.TEXTAREA,
        labelPosition: BigidFormFieldLabelPosition.top,
        name: 'query',
        label: t('fields.query.label'),
        misc: {
          fullWidth: true,
          rows: 5,
        },
        fieldProps: {
          multiline: true,
          disabled: true,
        },
        isRequired: false,
      },
      {
        type: BigidFormFieldTypes.TEXTAREA,
        labelPosition: BigidFormFieldLabelPosition.top,
        name: 'description',
        label: t('fields.description.label'),
        misc: {
          fullWidth: true,
          rows: 3,
        },
        fieldProps: {
          multiline: true,
        },
        isRequired: false,
      },
      ...saveAsOptionsRadio,
    ];

    return { fields };
  }, [isNewFilter, savedQueries, t]);

  return { fields };
}
