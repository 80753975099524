import { httpService } from '../../../../services/httpService';
import { TestConnectionStatus } from './TestConnectionBox';

export interface AdConfiguration {
  name: string;
  appId: string;
  appTenant: string;
  appSecret: string;
}

export interface AdConfigurationResponse {
  data: {
    name: string;
    app_id: string;
    app_tenant: string;
    app_secret: string;
    testResult?: TestConnectionStatus;
    testedAt?: string;
  };
}

export const getAdConfiguration = (name = 'default') => {
  return httpService.fetch<AdConfigurationResponse>(`aci/ad/config/${name}`).then(({ data }) => {
    if (data.data) {
      return {
        name: data.data.name,
        appId: data.data.app_id,
        appTenant: data.data.app_tenant,
        appSecret: data.data.app_secret,
        testResult: data.data.testResult,
        testedAt: data.data.testedAt,
      };
    }
    return null;
  });
};

export const syncAd = (name = 'default') => {
  return httpService.post(`aci/ad/sync`, { name });
};

export const updateAdConfiguration = (configuration: AdConfiguration) => {
  return httpService.post(`aci/ad/config`, {
    name: 'default',
    app_id: configuration.appId,
    app_tenant: configuration.appTenant,
    app_secret: configuration.appSecret,
  });
};

export const testAdConnection = (configuration: AdConfiguration) => {
  return httpService.post(`aci/ad/test-connection`, {
    name: 'default',
    app_id: configuration.appId,
    app_tenant: configuration.appTenant,
    app_secret: configuration.appSecret,
  });
};
