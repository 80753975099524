import { EntityEvents, entityEventsEmitter } from '@bigid-ui/components';
import { subscribeToRepeatedSSEEventById } from '../../../../../services/sseService';
import { useEffect } from 'react';
import { refreshDsOnboardingLayoutGrid } from '../constants/gridConstants';

type UseDsOnBoardingSseProps = {
  onEvent?: () => void;
};

export const useDsOnboardingSse = ({ onEvent }: UseDsOnBoardingSseProps) => {
  const reloadGrid = () => {
    entityEventsEmitter.emit(EntityEvents.RELOAD);
  };

  useEffect(() => {
    const unregister = subscribeToRepeatedSSEEventById(refreshDsOnboardingLayoutGrid, () => {
      reloadGrid();
      onEvent?.();
    });
    return () => {
      unregister();
    };
  }, []);
};
