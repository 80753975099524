import React, { FC, useState, useEffect, useCallback, useMemo } from 'react';
import {
  PrimaryButton,
  SecondaryButton,
  BigidDialog,
  BigidTagWizard,
  BigidTagBaseProps,
  getTagsDictionary,
  BigidBody1,
  BigidColors,
  BigidTagsValidationPayload,
} from '@bigid-ui/components';
import { makeStyles } from '@mui/styles';
import { getTagsAllPairs } from '../../../../../TagsManagement/TagsManagementService';
import { isPermitted } from '../../../../../../services/userPermissionsService';
import { TAGS_PERMISSIONS } from '@bigid/permissions';
import { assignmentTagProcessor, createAndAttachTagsBulk, TagAssignmentMode } from './actionsService';
import { notificationService } from '../../../../../../services/notificationService';
import { useQuery, useMutation } from 'react-query';
import { FormHelperText, FormLabel } from '@mui/material';
import { validateTag } from '../../../../../TagsManagement/TagsManagementUtils';
import { generateDataAid } from '@bigid-ui/utils';
import { useLocalTranslation } from '../../../../translations';
import { fetchRemoveableTags } from '../../../../explorerSearchResultsService';

const useStyles = makeStyles({
  wrapper: {
    display: 'flex',
  },
  label: {
    width: '20%',
    height: '34px',
    display: 'flex',
    alignItems: 'center',
  },
  wizard: {
    width: '80%',
  },
  error: {
    color: `${BigidColors.failureRed} !important`,
    fontSize: '0.75rem',
    height: '16px',
    whiteSpace: 'nowrap',
  },
});

export interface DataExplorerBulkTagAssignmentDialogProps {
  isOpen: boolean;
  onClose?: (mode: TagAssignmentMode) => void;
  onSubmit?: (mode: TagAssignmentMode, assignedTag?: BigidTagBaseProps) => void;
  searchText?: string;
  dataAid?: string;
  mode: TagAssignmentMode;
  filter: string;
}

export const DataExplorerBulkTagAssignmentDialog: FC<DataExplorerBulkTagAssignmentDialogProps> = ({
  isOpen,
  onClose,
  onSubmit,
  searchText,
  mode,
  filter,
  dataAid = 'DataExplorerBulkTagAssignmentDialog',
}) => {
  const classes = useStyles({});
  const [isInvalid, setIsInvalid] = useState<boolean>(false);
  const [validationMessage, setValidationMessage] = useState<string>();
  const [createdTag, setCreatedTag] = useState<BigidTagBaseProps>();
  const [attachedTag, setAttachedTag] = useState<BigidTagBaseProps>();
  const { t } = useLocalTranslation('actions.tagAssignment');

  const { isCreateTagsPermitted, isReadTagsPermitted } = useMemo(
    () => ({
      isCreateTagsPermitted: isPermitted(TAGS_PERMISSIONS.CREATE.name),
      isReadTagsPermitted: isPermitted(TAGS_PERMISSIONS.READ.name),
    }),
    [],
  );

  const {
    isLoading: isFetchTagsLoading,
    data: { dictionary, systemTags },
    refetch,
  } = useQuery(
    ['fetchTags', filter, searchText, mode],
    async () => {
      if (mode === 'unassign') {
        return fetchRemoveableTags(filter, searchText);
      }

      const systemTags = await getTagsAllPairs();
      const dictionary = getTagsDictionary(
        systemTags.map(({ tagName, tagValue }) => ({ name: tagName, value: tagValue })),
      );

      return {
        systemTags,
        dictionary,
      };
    },
    {
      initialData: {
        dictionary: new Map(),
        systemTags: [],
      },
      enabled: isOpen,
    },
  );

  useEffect(() => {
    if (isOpen && isReadTagsPermitted) {
      refetch();
    }
  }, [isOpen, isReadTagsPermitted, refetch]);

  const submitCreatedTags = useMutation(
    async () => {
      return await createAndAttachTagsBulk({
        tagToProcess: [createdTag],
        filter,
        systemTags,
        searchText,
        assignmentMode: mode,
      });
    },
    {
      onError: ({ message, response }) => {
        notificationService.error('An error has occurred');
        console.error(`An error has occurred: ${message}`);
      },
    },
  );

  const submitAttachedTags = useMutation(
    async () => {
      const {
        data: {
          data: { taskUuid },
        },
      } = await assignmentTagProcessor({
        tagToProcess: [attachedTag],
        filter,
        systemTags,
        searchText,
        assignmentMode: mode,
      });
    },
    {
      onError: ({ message, response }) => {
        notificationService.error('An error has occurred');
        console.error(`An error has occurred: ${message}`);
      },
    },
  );

  const submitChanges = useMutation(
    async () => {
      if (validationMessage) {
        setIsInvalid(true);
      } else {
        let tagToSubmit;

        if (createdTag) {
          tagToSubmit = createdTag;
          await submitCreatedTags.mutate();
        } else if (attachedTag) {
          tagToSubmit = attachedTag;
          await submitAttachedTags.mutate();
        }

        onSubmit(mode, tagToSubmit);
        resetState();
      }
    },
    {
      onError: ({ message }) => {
        notificationService.error('An error has occurred');
        console.error(`An error has occurred: ${message}`);
      },
    },
  );

  const handleTagCreate = useCallback((tag: BigidTagBaseProps): void => {
    setCreatedTag(tag);
    setAttachedTag(undefined);
  }, []);

  const handleTagAttach = useCallback((tag: BigidTagBaseProps): void => {
    setAttachedTag(tag);
    setCreatedTag(undefined);
  }, []);

  const resetState = () => {
    setIsInvalid(false);
    setValidationMessage(undefined);
    setCreatedTag(undefined);
    setAttachedTag(undefined);
  };

  const handleOnDialogSave = () => {
    submitChanges.mutate();
  };

  const handleOnDialogClose = () => {
    resetState();
    onClose(mode);
  };

  const handleOnMenuClose = (message: string) => {
    setValidationMessage(message);
  };

  const validateTagName = ({ tagName }: BigidTagsValidationPayload): string => {
    const { isTagNameValueValid } = validateTag;

    if (!isTagNameValueValid(tagName)) {
      return t('errors.invalidTagName');
    }

    return;
  };

  const validateTagValue = ({ tagName, tagValue }: BigidTagsValidationPayload): string => {
    const { isTagNameValueValid, isTagValueNotEqualsToTagName } = validateTag;

    if (!isTagNameValueValid(tagValue)) {
      return t('errors.invalidTagValue');
    }

    if (!isTagValueNotEqualsToTagName(tagValue, tagName)) {
      return t('errors.tagValueEqualsToTagName');
    }

    return;
  };

  const showValidationMessage = isInvalid && validationMessage;
  const isLoading =
    submitChanges.isLoading || submitCreatedTags.isLoading || submitAttachedTags.isLoading || isFetchTagsLoading;

  return (
    <BigidDialog
      title={t(`${mode}.title`)}
      isOpen={isOpen}
      onClose={handleOnDialogClose}
      borderTop
      buttons={[
        {
          component: SecondaryButton,
          onClick: handleOnDialogClose,
          text: t(`${mode}.cancelButton`),
        },
        {
          component: PrimaryButton,
          onClick: handleOnDialogSave,
          text: t(`${mode}.actionButton`),
          disabled: !(Boolean(createdTag) || Boolean(attachedTag)),
        },
      ]}
      isLoading={isLoading}
      maxWidth="sm"
    >
      <div className={classes.wrapper}>
        <FormLabel
          data-aid={generateDataAid(dataAid, ['assignment-label'])}
          className={classes.label}
          component={BigidBody1}
        >
          Tag
        </FormLabel>
        <div className={classes.wizard}>
          <BigidTagWizard
            dataAid={generateDataAid(dataAid, ['assignment-wizard'])}
            tagsDictionary={dictionary}
            onSelect={handleTagAttach}
            onCreate={handleTagCreate}
            isAbleToCreate={isCreateTagsPermitted}
            onMenuClose={handleOnMenuClose}
            validateName={validateTagName}
            validateValue={validateTagValue}
            isOpenByDefault={false}
            tagWizardMenuPosition="fixed"
            isStandalone
          />
          {showValidationMessage && (
            <FormHelperText error margin="dense" classes={{ error: classes.error }}>
              {validationMessage}
            </FormHelperText>
          )}
        </div>
      </div>
    </BigidDialog>
  );
};
