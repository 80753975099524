import React, { ReactNode, SVGProps } from 'react';

import { EntityType, HighlightedField } from '../../types';
import {
  BigidCatalogIcon,
  BigidDataSourceIcon,
  BigidHistoryIcon,
  BigidPolicyIcon,
  IconComponentProps,
} from '@bigid-ui/icons';
import { styled } from '@mui/material';

const HighlightComponent = styled('span')`
  background-color: ${({ theme }) => theme.vars.tokens.bigid.backgroundTextHighlight};
`;

export const mapEntityTypeToIcon = (
  entityType: EntityType,
): ((props: IconComponentProps & SVGProps<SVGSVGElement>) => JSX.Element) => {
  switch (entityType) {
    case 'catalog':
      return BigidCatalogIcon;
    case 'datasource':
      return BigidDataSourceIcon;
    case 'policy':
      return BigidPolicyIcon;
    case 'recentlyViewed':
      return BigidHistoryIcon;
    default:
      return null;
  }
};

export const processHighlight = (field: HighlightedField): ReactNode => {
  const { values } = field;
  const text = values[0].value;
  const highlights = values[0].highlights;
  if (!highlights || highlights.length === 0) {
    return text;
  }

  const textParts: ReactNode[] = [];
  let lastHighlightEnd = 0;
  highlights.forEach(highlight => {
    const { start, length } = highlight;

    if (start > lastHighlightEnd) {
      textParts.push(text.substring(lastHighlightEnd, start));
    }

    const key = `${start}-${length}`;
    textParts.push(<HighlightComponent key={key}>{text.substring(start, start + length)}</HighlightComponent>);
    lastHighlightEnd = start + length;
  });

  if (lastHighlightEnd < text.length) {
    textParts.push(text.substring(lastHighlightEnd));
  }

  return textParts;
};
