import React, { FC } from 'react';
import { BigidNoDataIllustration } from '@bigid-ui/icons';
import styled from '@emotion/styled';
import { BigidBody1, BigidColorsV2 } from '@bigid-ui/components';

const Wrapper = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  height: 'calc(100% - 110px)',
});

const StyledBigidBody1 = styled(BigidBody1)({
  textAlign: 'center',
  color: BigidColorsV2.gray[600],
  marginTop: 14,
});

export interface InteractiveDashboardNoData {}

export const InteractiveDashboardNoData: FC<InteractiveDashboardNoData> = ({}) => {
  return (
    <Wrapper>
      <BigidNoDataIllustration />
      <StyledBigidBody1>{'No data found'}</StyledBigidBody1>
    </Wrapper>
  );
};
