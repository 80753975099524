import { module } from 'angular';
import classNames from 'classnames';
import React, { FunctionComponent, useEffect, useRef, useState } from 'react';
import { notificationService } from '../../../../services/notificationService';
import { convertToAngular } from '../../../../../common/services/convertToAngular';
import { useCustomScrollbar } from '../../../../components/hooks/useCustomScrollbar';
import { ReportRow } from '../ReportRow/ReportRow';
import styles from './ExtendedInfo.scss';
import { DSARReportNormalizedAttribute, extendedInfoService } from './extendedInfoService';
import { isPermitted } from '../../../../services/userPermissionsService';
import { DSAR_PERMISSIONS } from '@bigid/permissions';
import { getApplicationPreference } from '../../../../services/appPreferencesService';

export interface ExtendedInfoProps {
  title: string;
  requestId: string;
}

export const DEFAULT_TOGGLE_VALUE = true;

export const ExtendedInfo: FunctionComponent<ExtendedInfoProps> = ({ title, requestId }) => {
  const [attributes, setAttributes] = useState<DSARReportNormalizedAttribute[]>([]);
  const [inProgress, setInProgress] = useState<Set<string>>(new Set());
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const hidePurpose = getApplicationPreference<boolean>('HIDE_ATTRIBUTE_PURPOSE_OF_USE');
  const ref = useRef(null);
  useCustomScrollbar(ref);

  useEffect(() => {
    if (!requestId) {
      setIsLoading(false);
      return;
    }
    setIsLoading(true);
    extendedInfoService
      .getExtendedInfo(requestId)
      .then(extendedInfoService.aggregateExtendedAttributes)
      .then(aggregatedAttributes => {
        setAttributes(aggregatedAttributes);
      })
      .catch((err: any) => {
        notificationService.error(`Failed to get Extended Info for user ${requestId}.`);
        window.console.error(`Failed to get SAR Request profile`, JSON.stringify(err));
        throw err;
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [requestId]);

  const onToggleClick = (attribute: DSARReportNormalizedAttribute, isEnabled: boolean) => {
    const { name, isEnabled: oldIsEnabled } = attribute;
    if (inProgress.has(name)) {
      return;
    }
    inProgress.add(name);
    const newAttribute = { ...attribute, isEnabled };
    const newAttributes = attributes.map(attr => (attr === attribute ? newAttribute : attr));

    extendedInfoService
      .updateExtendedReportConfig(requestId, {
        name,
        is_enabled: isEnabled,
      })
      .then(result => {
        if (result.isModified) {
          notificationService.success(`Updated successfully.`);
        } else {
          throw new Error(`PUT failed, 'isModified' is '${result.isModified}'`);
        }
      })
      .catch(err => {
        newAttribute.isEnabled = oldIsEnabled;
        notificationService.error(`Failed to save changes`);
        window.console.error(`Failed to update extended info config:`, err);
        throw err;
      })
      .finally(() => {
        inProgress.delete(name);
        setInProgress(new Set(inProgress));
        setAttributes(newAttributes);
      });
  };

  const hasPermission = isPermitted(DSAR_PERMISSIONS.EDIT_PERSONAL_INFO.name);

  return (
    <div className={classNames(styles.wrapper, isLoading && 'spinner-widget-target')}>
      <div className={styles.header}>
        <div className={styles.title}>{title}</div>
      </div>
      <div className={styles.main} ref={ref}>
        {attributes &&
          attributes.map((attr, i, arr) => (
            <ReportRow
              isEditable={hasPermission}
              key={i}
              {...attr}
              hidePurpose={hidePurpose}
              isLast={i === arr.length - 1}
              onToggleClick={isEnabled => onToggleClick(attr, isEnabled)}
            />
          ))}
      </div>
      {isLoading && <div className="spinnerWidget" />}
    </div>
  );
};

module('app').component('sarExtendedInfo', convertToAngular<ExtendedInfoProps>(ExtendedInfo, ['title', 'requestId']));
