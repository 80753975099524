import { useQuery, UseQueryOptions, QueryFunction, UseQueryResult, QueryKey } from 'react-query';
import {
  DEFAULT_MAX_SCAN_TEMPLATES_IN_REQUEST,
  getScanTemplateQueries,
} from '../../../../Scans/ScanProfileConverter/scanProfileConversionUtils';
import { getScanTemplates } from '../../../../Scans/ScanTemplates/scanTemplatesService';
import type { AsyncReturnType } from '../../../DataSourceConnectionModal/types';
import type { ErrorType } from '../../../../../config/request';

export type GetScanTemplatesQueryResult = NonNullable<AsyncReturnType<typeof getScanTemplates>>;

export type GetScanTemplatesQueryError = ErrorType<unknown>;

export const getScanTemplatesQueryKey = (searchTerm: string): Array<unknown> => ['getScanTemplates', searchTerm];

const DEFAULT_SEARCH_TERM: string = null;

export const useGetScanTemplates = <
  TData = AsyncReturnType<typeof getScanTemplates>,
  TError = GetScanTemplatesQueryError,
>(
  searchTerm = DEFAULT_SEARCH_TERM,
  options?: { query?: UseQueryOptions<AsyncReturnType<typeof getScanTemplates>, TError, TData> },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options || {};

  const queryKey = queryOptions?.queryKey ?? getScanTemplatesQueryKey(searchTerm);
  const queryString = getScanTemplateQueries({
    searchString: searchTerm,
    maxTemplates: DEFAULT_MAX_SCAN_TEMPLATES_IN_REQUEST,
  });
  const queryFn: QueryFunction<AsyncReturnType<typeof getScanTemplates>> = async () => getScanTemplates(queryString);
  const query = useQuery<AsyncReturnType<typeof getScanTemplates>, TError, TData>(queryKey, queryFn, {
    ...queryOptions,
  });

  return {
    queryKey,
    ...query,
  };
};
