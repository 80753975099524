import React, { FunctionComponent, MouseEvent, useEffect, useState, Fragment } from 'react';
import angular from 'angular';
import styles from './LicenseExpirationNoticeBar.scss';
import { convertToAngular } from '../../../common/services/convertToAngular';
import { licenseService, License } from '../../services/licenseService';
import { sessionStorageService } from '../../../common/services/sessionStorageService';
import { SystemEvents, systemEventsEmitter } from '../../services/systemEvents';
import BigidInfoCircle from '../../assets/icons/BigidInfoCircle.svg';
import { BannerComponent, BigidColorsV2 } from '@bigid-ui/components';
import { publicUrls } from '../../config/publicUrls';

const onAddLicenseKeyClick = (e: MouseEvent): void => {
  e.preventDefault();
  licenseService.goToLicensePage();
};

const getLicenseBannerMessage = (isExpired: boolean, isDataSourceAllowanceExceeded: boolean): string => {
  if (isDataSourceAllowanceExceeded && !isExpired) {
    return `You've exceeded the number of permitted data sources. `;
  }
  return isExpired
    ? `Your license has expired. `
    : `Your license expires in ${licenseService.getAmountOfDaysUntilExpired()} days. `;
};

const onCloseClick = () => {
  licenseService.setUserClosedExpirationBar(true);
};

export const LicenseExpirationNoticeBar: FunctionComponent = () => {
  const CUSTOMER_SUPPORT_LINK = publicUrls.SUPPORT_BIGID;
  const MARKETING_TEAM = 'our team';
  const ADD_LICENSE_KEY_MESSAGE = 'Add a license key';
  const USER_MESSAGE = 'Please contact your administrator for assistance.';

  const [license, setLicense] = useState<License>(licenseService.getLicense());

  useEffect(() => {
    const updateLicenseListener = systemEventsEmitter.addEventListener(SystemEvents.UPDATE_LICENSE, () =>
      setLicense(licenseService.getLicense()),
    );
    return function cleanup() {
      updateLicenseListener();
    };
  });
  const userRoles: string[] = sessionStorageService.get('userRoles');
  const isAdmin: boolean = userRoles.includes('admin');
  const isExpired = licenseService.isExpired();
  const isDataSourceAllowanceExceeded = licenseService.isExceededMaximumDataSourceAllowance();

  const getExpirationBarColor = () =>
    isExpired || isDataSourceAllowanceExceeded ? BigidColorsV2.red[900] : BigidColorsV2.blue[300];

  const licenseBannerMessage = getLicenseBannerMessage(isExpired, isDataSourceAllowanceExceeded);

  return (
    <BannerComponent data-aid={'license-banner'} background={getExpirationBarColor()} onClose={onCloseClick}>
      <div className={styles.licenseMessageWrapper}>
        <BigidInfoCircle className={styles.infoCircle} />
        <span data-aid={'License-banner-message'} className={styles.message}>
          {licenseBannerMessage}
        </span>
        <div>
          {isAdmin ? (
            <>
              <span onClick={onAddLicenseKeyClick} className={styles.link}>
                {ADD_LICENSE_KEY_MESSAGE}
              </span>
              {' or contact '}
              <a href={CUSTOMER_SUPPORT_LINK} target="_blank" rel="noopener noreferrer" className={styles.link}>
                {MARKETING_TEAM}
              </a>
              {' for assistance.'}
            </>
          ) : (
            USER_MESSAGE
          )}
        </div>
      </div>
    </BannerComponent>
  );
};

angular.module('app').component('licenseExpirationNoticeBar', convertToAngular(LicenseExpirationNoticeBar));
