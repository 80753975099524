import { format, formatDuration, intervalToDuration } from 'date-fns';

export const calculateDuration = (startTime: string, endTime: string) => {
  const { days, hours, minutes, seconds } = intervalToDuration({
    start: new Date(startTime),
    end: new Date(endTime),
  });
  if (!days && !hours && !minutes && !seconds) return 'Less than a second';
  return formatDuration({ days, hours, minutes, seconds });
};

export const formatTimeToVerbal = (time: string) => format(new Date(time), 'MMM.dd, yyyy, hh:mm aa');
export const formatTimeToActivityLogs = (time: string) => format(new Date(time), "yyyy-MM-dd'T'HH:mm:ss.SSS") + 'Z';
