import React, { FC, useEffect, useMemo, useState } from 'react';
import { BigidDropdownOption, BigidDropdownValue, EntityEvents, entityEventsEmitter } from '@bigid-ui/components';
import { useLocalTranslation } from '../../../translations';
import { assignCategoryToAttributeRequest, createCategoryRequest } from './assignCategoryService';
import { getCategoriesDetails } from '../../../../AccessIntelligence/UsersPermissions/UsersPermissionsService';
import { SystemDialogContentProps } from '../../../../../services/systemDialogService';
import { CuratedAttribute, LegacyCategoryDetails } from '../../../curationService';
import { getPayload, mapCategoriesToDropdownValues, mapLegacyCategoriesToDropdownValues } from './utils';
import { notificationService } from '../../../../../services/notificationService';
import { AssignCategoryDialog } from '../../../../../components/AssignCategoryDialog/AssignCategoryDialog';
import { CategoryAssignModes, CreateCategory } from '../../../../../components/AssignCategoryDialog/types';

export interface AssignCategoryDialogContentProps {
  attribute: CuratedAttribute;
  gridId: string;
}

export const AssignCategoryDialogContent: FC<SystemDialogContentProps<AssignCategoryDialogContentProps>> = ({
  onClose,
  attribute,
  gridId,
}) => {
  const { t } = useLocalTranslation('AssignCategoryDialogContent');
  const [categories, setCategories] = useState<LegacyCategoryDetails[]>([]);
  const options: BigidDropdownOption[] = useMemo(() => {
    return mapLegacyCategoriesToDropdownValues(categories);
  }, [categories]);

  useEffect(() => {
    const fetchCategories = async () => {
      const catagoriesResponse = await getCategoriesDetails();
      setCategories(catagoriesResponse.data as unknown as LegacyCategoryDetails[]);
    };
    fetchCategories();
  }, []);

  const handleOnSaveClick = async (
    selectedCategories: BigidDropdownValue,
    categoryAssignMode: CategoryAssignModes,
    state: CreateCategory,
  ) => {
    if (categoryAssignMode === CategoryAssignModes.NEW) {
      if (!state.name) {
        notificationService.info(t('messages.nameRequired'));
        return;
      }

      const response = await createCategoryRequest(state);
      if (response?.success) {
        const selectedCategoryIds = selectedCategories.map(({ id }) => id);
        await assignCategoryToAttributeRequest(attribute, [...selectedCategoryIds, response.glossary_id]);

        const previousSelectedCategories = selectedCategories.map(({ displayValue, id, value }) => ({
          color: value.color,
          description: value.description,
          displayName: displayValue,
          uniqueName: id,
        }));

        const newCategory = {
          color: state.color,
          description: state.description,
          displayName: state.name,
          uniqueName: response.glossary_id,
        };

        const allCategories = previousSelectedCategories.concat(newCategory);

        entityEventsEmitter.emit(
          EntityEvents.UPDATE_BY_ID,
          getPayload({
            attributeName: attribute.attributeName,
            categories: allCategories,
            gridId,
          }),
        );
        onClose();
      }
    } else {
      const selectedCategoryIds = selectedCategories.map(({ id }) => id);
      await assignCategoryToAttributeRequest(attribute, selectedCategoryIds);

      entityEventsEmitter.emit(
        EntityEvents.UPDATE_BY_ID,
        getPayload({
          attributeName: attribute.attributeName,
          categories: selectedCategories.map(({ displayValue, id, value }) => ({
            color: value.color,
            description: value.description,
            displayName: displayValue,
            uniqueName: id,
          })),
          gridId,
        }),
      );

      onClose();
    }
  };

  return (
    <AssignCategoryDialog
      categories={options}
      onClose={onClose}
      initialSelectedCategories={mapCategoriesToDropdownValues(attribute.categories)}
      onSave={handleOnSaveClick}
    />
  );
};
