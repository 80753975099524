import { openSystemDialog } from '../../services/systemDialogService';
import React, { Dispatch, FC, SetStateAction, useState, useEffect } from 'react';
import { BigidPrimaryCheckbox, PrimaryButton, SecondaryButton } from '@bigid-ui/components';
import { betaToolsService } from '../../services/angularServices';
import { notificationService } from '../../services/notificationService';
import angular from 'angular';
import { convertToAngular } from '../../../common/services/convertToAngular';
import makeStyles from '@mui/styles/makeStyles';

type DisableDeleteFindingsFunction = (disabled: boolean) => void;

const useStyles = makeStyles({
  content: {
    marginTop: '0px',
    marginBottom: '16px',
  },
});

interface DeleteFindingsDialogProps {
  isOpen: boolean;
  onClose: () => void;
  setDisableDeleteFindings: DisableDeleteFindingsFunction;
}

interface DeleteFindingsDialogContentProps {
  isCheckedPIIEnrichment: boolean;
  isCheckedClassification: boolean;
  setIsCheckedPIIEnrichment: Dispatch<boolean>;
  setIsCheckedClassification: Dispatch<boolean>;
}

const DeleteFindingsDialogContent: FC<DeleteFindingsDialogContentProps> = ({
  isCheckedPIIEnrichment,
  setIsCheckedPIIEnrichment,
  isCheckedClassification,
  setIsCheckedClassification,
}) => {
  const classes = useStyles({});
  return (
    <>
      <div>Delete these findings from BigID:</div>
      <BigidPrimaryCheckbox
        label="Delete correlation findings"
        checked={isCheckedPIIEnrichment}
        onChange={(event, checked) => setIsCheckedPIIEnrichment(checked)}
      />
      <BigidPrimaryCheckbox
        label="Delete classification findings"
        checked={isCheckedClassification}
        onChange={(event, checked) => setIsCheckedClassification(checked)}
      />
    </>
  );
};

const deleteAndNotify = async (
  setDisableDeleteFindings: DisableDeleteFindingsFunction,
  isCheckedPIIEnrichment: boolean,
  isCheckedClassification: boolean,
) => {
  setDisableDeleteFindings(true);

  isCheckedPIIEnrichment && (await deletePIIEnrichment());
  isCheckedClassification && (await deleteClassification());
  setDisableDeleteFindings(false);
};

async function deletePIIEnrichment() {
  try {
    await betaToolsService.deleteFindings();
    notificationService.success('Findings deleted successfully! ');
  } catch (err) {
    console.error(err);
  }
}

async function deleteClassification() {
  try {
    await betaToolsService.deleteClassificationFindings();
    notificationService.success('Findings deleted successfully! ');
  } catch (err) {
    console.error(err);
  }
}

export const DeleteFindingsDialog: FC<DeleteFindingsDialogProps> = ({ setDisableDeleteFindings, isOpen, onClose }) => {
  const [isCheckedPIIEnrichment, setIsCheckedPIIEnrichment] = useState(false);
  const [isCheckedClassification, setIsCheckedClassification] = useState(false);
  const deleteFindingsDialog: () => void = () => {
    openSystemDialog<DeleteFindingsDialogContentProps>({
      title: 'Delete Findings',
      onClose: () => {
        onClose();
        setIsCheckedPIIEnrichment(false);
        setIsCheckedClassification(false);
      },
      content: DeleteFindingsDialogContent,
      contentProps: {
        isCheckedPIIEnrichment,
        setIsCheckedPIIEnrichment,
        isCheckedClassification,
        setIsCheckedClassification,
      },
      buttons: [
        {
          text: 'Cancel',
          component: SecondaryButton,
          onClick: () => null,
          isClose: true,
        },
        {
          text: 'Delete Now',
          component: PrimaryButton,
          onClick: () => deleteAndNotify(setDisableDeleteFindings, isCheckedPIIEnrichment, isCheckedClassification),
          isClose: true,
          disabled: !(isCheckedClassification || isCheckedPIIEnrichment),
        },
      ],
    });
  };
  useEffect(() => {
    if (isOpen) {
      deleteFindingsDialog();
    }
  }, [isOpen, deleteFindingsDialog]);
  return null;
};
angular
  .module('app')
  .component(
    'deleteFindingsDialog',
    convertToAngular<DeleteFindingsDialogProps>(DeleteFindingsDialog, [
      'isOpen',
      'onClose',
      'setDisableDeleteFindings',
    ]),
  );
