import './policiesWrapper.component.scss';

import { module } from 'angular';
import template from './policiesWrapper.component.html';
const app = module('app');

app.component('policiesWrapper', {
  template,
  controller: function ($rootScope, $scope, $translate, $stateParams, commonMethods) {
    'ngInject';

    $translate('POLICIES').then(translation => {
      $rootScope.$broadcast('changePage', translation, false);
    });

    this.isLoading = false;
    this.filter = null;
    this.policyId = null;
    this.policyName = null;

    this.$onInit = () => {
      const { filter, policyId, policyName } = $stateParams;

      this.filter = commonMethods.decodeUrlParams(filter);
      this.policyId = policyId ? decodeURIComponent(policyId) : null;
      this.policyName = null;

      if (!policyId && policyName) {
        this.policyName = decodeURIComponent(policyName);
      }
    };

    this.onLoading = () => {
      this.isLoading = true;
    };

    this.onLoaded = () => {
      $scope.$evalAsync(() => {
        this.isLoading = false;
      });
    };
  },
});
