import {
  MetadataSearchResultsGridRecord,
  MetadataSearchResultsGridColumn,
} from '../MetadataSearchResultsGrid/MetadataSearchResultsGridTypes';
import {
  getGridCellContentSingular,
  getGridCellContentPlural,
} from '../MetadataSearchResultsGrid/MetadataSearchResultsGridUtils';
import { getMetadataSearchEntityFieldByName } from '../MetadataSearchService';
import {
  MetadataSearchResultEntity,
  MetadataSearchResultEntityFieldName,
  MetadataSearchEntityTypeConfig,
  MetadataSearchEntityTypeIcons,
  MetadataSearchEntityType,
} from '../MetadataSearchTypes';
import {
  getResultEntityGridDisplayFieldsPerType,
  getMetadataSearchEntityIconNode,
  getEntityTypeConfigByTypeId,
} from '../MetadataSearchUtils';

export const getRecentObjectsGridColumns = (
  fieldsToDisplay: MetadataSearchResultEntityFieldName[],
  recentObject: MetadataSearchResultEntity,
  iconMap: MetadataSearchEntityTypeIcons,
  entityTypeConfig: MetadataSearchEntityTypeConfig,
): MetadataSearchResultsGridColumn[] => {
  const columns: MetadataSearchResultsGridColumn[] = [];

  if (fieldsToDisplay.includes(MetadataSearchResultEntityFieldName.NAME)) {
    const name = getMetadataSearchEntityFieldByName(recentObject, MetadataSearchResultEntityFieldName.NAME);
    const { cellValue: nameCellValue, tooltipValue } = getGridCellContentSingular(name, true);

    columns.push({
      id: 'name',
      name: 'name',
      value: nameCellValue,
      tooltipTitle: name.originalField || 'Name',
      tooltipValue,
      isBold: true,
      icon: getMetadataSearchEntityIconNode(recentObject.type, 'medium', iconMap, entityTypeConfig),
    });
  }

  if (fieldsToDisplay.includes(MetadataSearchResultEntityFieldName.NAME)) {
    const container = getMetadataSearchEntityFieldByName(recentObject, MetadataSearchResultEntityFieldName.CONTAINER);
    const columnWidth = fieldsToDisplay.length === 2 ? '40%' : undefined;
    const containerColumnBase: MetadataSearchResultsGridColumn = {
      id: 'container',
      name: 'container',
      value: '',
      width: columnWidth,
    };

    if (container) {
      const { cellValue, tooltipValue } = getGridCellContentSingular(container, true);
      columns.push({
        ...containerColumnBase,
        value: cellValue,
        tooltipTitle: container.originalField || 'Container',
        tooltipValue,
      });
    } else {
      columns.push(containerColumnBase);
    }
  }

  if (fieldsToDisplay.includes(MetadataSearchResultEntityFieldName.OWNER)) {
    const owner = getMetadataSearchEntityFieldByName(recentObject, MetadataSearchResultEntityFieldName.OWNER);
    const columnWidth = fieldsToDisplay.length === 2 ? '40%' : undefined;
    const ownerColumnBase: MetadataSearchResultsGridColumn = {
      id: 'owner',
      name: 'owner',
      value: '',
      width: columnWidth,
    };

    if (owner) {
      const { cellValue, cellExtraValueCount, tooltipValue } = getGridCellContentPlural(owner, 'Owners', true);
      columns.push({
        ...ownerColumnBase,
        value: cellValue,
        extraValueCount: cellExtraValueCount,
        tooltipTitle: owner.originalField || 'Owners',
        tooltipValue,
      });
    } else {
      columns.push(ownerColumnBase);
    }
  }

  return columns;
};

export const getRecentObjectsGridData = (
  recentObjects: MetadataSearchResultEntity[],
  iconMap: MetadataSearchEntityTypeIcons,
  entityTypeConfigs: MetadataSearchEntityTypeConfig[],
): MetadataSearchResultsGridRecord[] => {
  return recentObjects.map(recentObject => {
    const entityId = getMetadataSearchEntityFieldByName(recentObject, MetadataSearchResultEntityFieldName.ENTITY_ID);
    const entityType = getMetadataSearchEntityFieldByName(recentObject, MetadataSearchResultEntityFieldName.TYPE)
      ?.value as MetadataSearchEntityType;
    const fields = getResultEntityGridDisplayFieldsPerType(recentObject.type);
    const entityTypeConfig = getEntityTypeConfigByTypeId(entityTypeConfigs, entityType);
    const columns: MetadataSearchResultsGridColumn[] = getRecentObjectsGridColumns(
      fields,
      recentObject,
      iconMap,
      entityTypeConfig,
    );

    return {
      id: getGridCellContentSingular(entityId, true).cellValue,
      columns,
      originalRecord: recentObject,
    };
  });
};
