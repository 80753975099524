import { debounce, isEmpty } from 'lodash';
import { queryService } from '../../../../../../../services/queryService';
import {
  BigidFieldFilterOperator,
  BigidFormField,
  BigidFormFieldTypes,
  BigidFormValues,
  BigidSelectOption,
} from '@bigid-ui/components';
import { getConfigurations } from '../../../../../ConfigurationManagement/configurationManagementService';
import {
  Configuration,
  ConfigurationFormField,
  FieldType,
  fieldTypeToFormFieldType,
} from '../../../../../ConfigurationManagement/configurationManagementTypes';
import {
  JiraActionConfiguration,
  OpenServiceTicketActionConfiguration,
  OpenServiceTicketMetadata,
} from '../../../../actionWorkflowTypes';
import { fetchJiraUsers } from '../../../../actionWorkflowService';

export const ISSUE_TYPE_FIELD_NAME = 'issuetype';
export const CONFIGURATION_FIELD_NAME = 'configurationId';
export const ISSUE_CUSTOM_FIELD_SEPARATOR = '-';

export const loadConfigurationSearchOptions = (selectedConfigurationId?: string) =>
  debounce(
    async (inputType: string) => {
      const paginationObject = { skip: 0, limit: 20 };

      let filter;
      if (inputType) {
        const splitSearchString = inputType.split(' ').join('|');
        filter = [
          {
            field: 'name',
            operator: 'textSearch' as BigidFieldFilterOperator,
            value: splitSearchString,
          },
        ];
      }

      const query = queryService.getGridConfigQuery({
        ...paginationObject,
        ...(filter && { filter }),
      });

      const { configurations: configurationsFromQuery } = await getConfigurations(query);

      const configurationsFromQueryOptions = configurationsFromQuery.map((result: Configuration) => ({
        label: result.name,
        value: result.id,
        isSelected: false,
        isDisabled: result.isOffline,
      }));

      const isSelectedConfigurationNotInResults =
        selectedConfigurationId &&
        !configurationsFromQueryOptions.find(({ value }) => value === selectedConfigurationId);

      if (isSelectedConfigurationNotInResults) {
        const chosenConfigurationQuery = queryService.getGridConfigQuery({
          filter: [
            {
              field: '_id',
              operator: 'equal' as BigidFieldFilterOperator,
              value: selectedConfigurationId,
            },
          ],
        });

        const {
          configurations: [configurationChosen],
        } = await getConfigurations(chosenConfigurationQuery);

        return [
          ...configurationsFromQueryOptions,
          { label: configurationChosen.name, value: configurationChosen.id, isSelected: false },
        ];
      }

      return [...configurationsFromQueryOptions];
    },
    500,
    {
      leading: true,
      trailing: true,
    },
  );

export const loadJiraUsersSearchOptions = (selectedConfigurationId?: string, selectedUsers?: string[]) =>
  debounce(
    async (inputType: string): Promise<BigidSelectOption[]> => {
      const { users = [] } = await fetchJiraUsers({
        configurationId: selectedConfigurationId,
        query: inputType,
        ids: selectedUsers,
      });
      return users;
    },
    500,
    {
      leading: true,
      trailing: true,
    },
  );

export const configurationField: BigidFormField = {
  type: BigidFormFieldTypes.SELECT,
  name: CONFIGURATION_FIELD_NAME,
  label: 'Ticketing account',
  isRequired: false,
  fieldProps: {
    message: '',
  },
  misc: {
    fullWidth: true,
  },
  options: [],
  validate: (selectedOption: BigidSelectOption[]) => {
    if (!selectedOption?.length) {
      return 'Please select a configuration';
    }
    return false;
  },
};

const getFormField = (
  { name, displayName, type: fieldType, required, description, options, dropDownOptionsName }: ConfigurationFormField,
  autocompleteListItems?: string[],
  dropDownOptions?: Record<string, any[]>,
): BigidFormField => {
  const type = fieldTypeToFormFieldType[fieldType];
  const isTextArea = fieldType === FieldType.TEXTAREA;
  const isAutoComplete = type === BigidFormFieldTypes.AUTOCOMPLETE;
  const isDropDown = fieldType === FieldType.DROP_DOWN;

  return {
    name,
    label: displayName,
    type,
    isRequired: required,
    options,
    ...(isDropDown && { dropDownOptions: dropDownOptions[dropDownOptionsName] }),
    tooltipText: description,
    fieldProps: {
      ...(isTextArea && {
        multiline: true,
        rows: 5,
      }),
      ...(isAutoComplete && {
        triggerChar: '$',
        autocompleteListItems,
      }),
      ...(isDropDown && {
        isSearchable: true,
      }),
    },
    ...(required && {
      validate: (value: any, values?: Record<string, any>) => {
        const selectedIssue = values?.[ISSUE_TYPE_FIELD_NAME]?.[0].value;
        if (isCustomField(name) && !name.includes(selectedIssue)) {
          return false;
        }

        if (!value) {
          return `${displayName} is required`;
        }

        switch (type) {
          case BigidFormFieldTypes.SELECT: {
            if (!value?.length) {
              return `Please select ${displayName.toLowerCase()}`;
            }
            return false;
          }
          case BigidFormFieldTypes.CHECKBOX: {
            const shouldHaveItemChecked = Object.values(value).includes(true);
            if (!shouldHaveItemChecked) {
              return 'Please choose at least one';
            }
            return false;
          }
          default: {
            return false;
          }
        }
      },
    }),
  };
};

const getCustomFieldNameForIssue = (field: string, issueType: string) =>
  `${issueType}${ISSUE_CUSTOM_FIELD_SEPARATOR}${field}`;

export const ticketFieldsMetadataToFormFields = (
  { fields, fieldsForIssueTypeMap = {}, dropDownOptions, issueName }: OpenServiceTicketMetadata,
  autocompleteListItems: string[],
): BigidFormField[] => {
  const formFields = fields.map(field => getFormField(field, autocompleteListItems, dropDownOptions));
  configurationField.fieldProps.message = issueName ? `Create an ${issueName}` : '';
  const customFormFields = Object.keys(fieldsForIssueTypeMap).reduce<BigidFormField[]>((customFormFields, key) => {
    const customFormFieldsForIssue = fieldsForIssueTypeMap[key];
    return [
      ...customFormFields,
      ...customFormFieldsForIssue.map(({ name, ...rest }) =>
        getFormField({ ...rest, name: getCustomFieldNameForIssue(name, key) }, autocompleteListItems),
      ),
    ];
  }, []);

  return [configurationField, ...formFields, ...customFormFields];
};

const isCustomField = (field: string) => field.includes(ISSUE_CUSTOM_FIELD_SEPARATOR);

export const getFieldType = (field: string, issueType: string, fields: OpenServiceTicketMetadata) => {
  return isCustomField(field)
    ? fields.fieldsForIssueTypeMap[issueType]?.find(({ name }) => name === field.split(ISSUE_CUSTOM_FIELD_SEPARATOR)[1])
        ?.type
    : fields.fields.find(({ name }) => name === field)?.type;
};

export const getIssueTypeOptions = (formFields: BigidFormField[]): BigidSelectOption[] =>
  formFields.find(({ name }) => name === ISSUE_TYPE_FIELD_NAME)?.options;

export const convertFormFormatToAction = (
  values: BigidFormValues,
  fields: OpenServiceTicketMetadata,
): OpenServiceTicketActionConfiguration => {
  const selectedIssue = values[ISSUE_TYPE_FIELD_NAME] && values[ISSUE_TYPE_FIELD_NAME][0].value;
  return Object.keys(values).reduce(
    (action, name) => {
      const isConfigurationField = name === CONFIGURATION_FIELD_NAME;

      if (isConfigurationField) {
        return { ...action, configurationId: values[name][0].value };
      }

      const fieldType = getFieldType(name, selectedIssue, fields);
      const formFieldType = fieldTypeToFormFieldType[fieldType];
      const fieldName = isCustomField(name) ? name.split(ISSUE_CUSTOM_FIELD_SEPARATOR)[1] : name;

      const formValue = values[name];
      switch (formFieldType) {
        case BigidFormFieldTypes.SELECT: {
          const selectValues = formValue?.map(({ value }: BigidSelectOption) => value);
          action = {
            ...action,
            fields: {
              ...action.fields,
              [fieldName]: fieldType === FieldType.MULTISELECT ? selectValues : selectValues[0],
            },
          };
          break;
        }
        case BigidFormFieldTypes.CHECKBOX: {
          action = {
            ...action,
            fields: {
              ...action.fields,
              [fieldName]: Object.keys(formValue).filter(checkboxId => formValue[checkboxId]),
            },
          };
          break;
        }
        case BigidFormFieldTypes.NUMBER: {
          action = {
            ...action,
            fields: {
              ...action.fields,
              [fieldName]: parseInt(formValue),
            },
          };
          break;
        }
        case BigidFormFieldTypes.DROP_DOWN: {
          action = {
            ...action,
            fields: {
              ...action.fields,
              [fieldName]: formValue[0].id,
            },
          };
          break;
        }
        default: {
          action = { ...action, fields: { ...action.fields, [fieldName]: formValue } };
        }
      }
      return action;
    },
    { fields: {} as JiraActionConfiguration, configurationId: '' } as OpenServiceTicketActionConfiguration,
  );
};

export const hasSavedConfiguration = (savedConfiguration: OpenServiceTicketActionConfiguration) => {
  if (!savedConfiguration) {
    return false;
  }
  const { enabled, ...actionConfiguration } = savedConfiguration;
  return !isEmpty(actionConfiguration);
};

const getConfigurationSelection = async (configurationId: string) => {
  const chosenConfigurationQuery = queryService.getGridConfigQuery({
    filter: [
      {
        field: '_id',
        operator: 'equal' as BigidFieldFilterOperator,
        value: configurationId,
      },
    ],
  });

  const {
    configurations: [configurationChosen],
  } = await getConfigurations(chosenConfigurationQuery);

  return [{ label: configurationChosen.name, value: configurationChosen.id, isSelected: true }];
};

export const convertOpenServiceTicketActionToFormFormat = async (
  { configurationId, fields }: OpenServiceTicketActionConfiguration,
  { fields: metadataFields, fieldsForIssueTypeMap, dropDownOptions }: OpenServiceTicketMetadata,
) => {
  const selectedIssue = fields[ISSUE_TYPE_FIELD_NAME];
  const metadataFieldsForIssue = fieldsForIssueTypeMap ? fieldsForIssueTypeMap[selectedIssue] : [];
  const customFieldNames = metadataFieldsForIssue.map(({ name }) => name);
  const allFields = [...metadataFields, ...metadataFieldsForIssue];

  const formFields: BigidFormValues = {};

  for (const fieldName of Object.keys(fields)) {
    const value = fields[fieldName];
    const { type, options } = allFields.find(({ name }) => name === fieldName);
    const formType = fieldTypeToFormFieldType[type];
    const isCustomField = customFieldNames.includes(fieldName);
    const formFieldName = isCustomField ? getCustomFieldNameForIssue(fieldName, selectedIssue) : fieldName;

    switch (formType) {
      case BigidFormFieldTypes.DROP_DOWN: {
        const optionsName = metadataFields.find(field => field.name === fieldName).dropDownOptionsName;
        const options = dropDownOptions[optionsName];
        const formFieldToUpdate = options.filter(({ value }) => value === fields[fieldName]);
        formFields[formFieldName] = formFieldToUpdate.map(itemSelected => ({
          ...itemSelected,
          isSelected: true,
        }));
        break;
      }
      case BigidFormFieldTypes.SELECT: {
        let formFieldToUpdate;
        if (type === FieldType.USER) {
          const { users = [] } = await fetchJiraUsers({ configurationId, ids: [value] });
          formFieldToUpdate = users.filter(({ value: userId }) => userId === value);
        } else if (type === FieldType.MULTISELECT) {
          formFieldToUpdate = options.filter(({ value }) => fields[fieldName].includes(value));
        } else {
          formFieldToUpdate = options.filter(({ value }) => value === fields[fieldName]);
        }
        formFields[formFieldName] = formFieldToUpdate.map(itemSelected => ({
          ...itemSelected,
          isSelected: true,
        }));
        break;
      }
      case BigidFormFieldTypes.CHECKBOX: {
        formFields[formFieldName] = options
          .filter(({ value }) => fields[fieldName].includes(value))
          .reduce(
            (checkboxes, { value }) => ({
              ...checkboxes,
              [value]: true,
            }),
            {},
          );
        break;
      }
      case BigidFormFieldTypes.NUMBER: {
        formFields[formFieldName] = value.toString();
        break;
      }
      default: {
        formFields[formFieldName] = value;
      }
    }
  }

  return { configurationId: await getConfigurationSelection(configurationId), ...formFields };
};

export const standardizeFieldsMetadata = (fieldsMetadata: OpenServiceTicketMetadata): OpenServiceTicketMetadata => {
  const { dropDownOptions } = fieldsMetadata;
  const standardizedDropDownOptions = Object.keys(dropDownOptions).reduce((acc, optionsListName) => {
    const standardizedList = dropDownOptions[optionsListName].map(listItem => {
      return {
        value: listItem.value,
        id: listItem.value,
        displayValue: listItem.displayValue,
      };
    });
    return { ...acc, [optionsListName]: standardizedList };
  }, {});

  return { ...fieldsMetadata, dropDownOptions: standardizedDropDownOptions };
};
