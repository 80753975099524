import React, { FC } from 'react';
import { BigidGuidedTour, Step } from '@bigid-ui/guided-tour';
import { CallBackProps } from 'react-joyride';
import { generateDataAid } from '@bigid-ui/utils';
import { useLocalTranslation } from '../translations';
import stepOneGif from './animation/Step1.gif';

export const actionableWorkspacesTourSelectors = {
  WORKSPACE_BUTTON: '[data-aid="WorkspaceSelector-button"]',
  NAVIGATION_BAR_BUTTON: '[data-aid="dualNavigationMenuToggle-open-semi-open"]',
  NAVIGATION_BAR: '[data-aid="BigidNavigationContainer-open"]',
  WORKSPACE_POPPER: '[data-aid="BigidPopper"]',
  EDIT_MENU_BUTTON: '[data-aid="bigidNavigationActions-EditModeButton"]',
};

interface WorkspacesGuidedTourProps {
  dataAid?: string;
  joyrideCallback: (props: CallBackProps) => void;
  isTourRunning: boolean;
  stepIndex: number;
}

export const WorkspacesGuidedTour: FC<WorkspacesGuidedTourProps> = ({
  dataAid = 'WorkspacesGuidedTour',
  isTourRunning,
  joyrideCallback,
  stepIndex,
}) => {
  const { t } = useLocalTranslation('workspacesGuidedTour.steps');

  const steps: Step[] = [
    {
      title: t('start.title'),
      carouselItems: [
        {
          type: 'img',
          url: stepOneGif,
        },
      ],
      content: t('start.content'),
      target: '#app',
      placement: 'center',
      inverseXButtonColor: true,
      showSkipButton: true,
      dataAid: generateDataAid(dataAid, ['start']),
    },
    {
      title: t('discover.title'),
      content: t('discover.content'),
      target: actionableWorkspacesTourSelectors.WORKSPACE_POPPER,
      disableBeacon: true,
      placement: 'right-end',
      dataAid: generateDataAid(dataAid, ['discover']),
    },
    {
      title: t('navigation.title'),
      content: t('navigation.content'),
      target: actionableWorkspacesTourSelectors.NAVIGATION_BAR,
      disableBeacon: true,
      placement: 'right',
      spotlightPadding: 0,
      spotlightClicks: true,
      dataAid: generateDataAid(dataAid, ['navigation']),
    },
    {
      title: t('personalize.title'),
      content: t('personalize.content'),
      target: actionableWorkspacesTourSelectors.EDIT_MENU_BUTTON,
      disableBeacon: true,
      placement: 'right-end',
      dataAid: generateDataAid(dataAid, ['personalize']),
    },
  ];

  return (
    <BigidGuidedTour
      steps={steps}
      stepIndex={stepIndex}
      joyrideCallback={joyrideCallback}
      run={isTourRunning}
      spotlightPadding={0}
      delay={2000}
    />
  );
};
