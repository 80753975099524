import { createContext } from 'react';
import { ObjectsByDataSource, ObjectsByType } from './consts';
import { UserPermission } from '../UsersPermissions/UsersPermissionsService';
import { LabelConfiguration } from '../Settings/Configuration/LabelConfigurationService';

export interface AciDashboardData {
  objectsByDataSource: ObjectsByDataSource[];
  objectsByType: ObjectsByType;
  aciLabels: LabelConfiguration[];
  topUsersWithSharedObjects: UserPermission[];
}

export interface AccessIntelligenceDashboardContextInterface {
  dashboardData: AciDashboardData;
  goToInventoryWithOpenAccessFilter: () => void;
}

export const AccessIntelligenceDashboardContext = createContext<AccessIntelligenceDashboardContextInterface>(null);
