import React, { PropsWithChildren, useState } from 'react';
import { BigidPaper, BigidTabs, BigidTabsItem } from '@bigid-ui/components';
import { WizardLayout } from './../mappers/styles';

type DataSourceWizardLayoutProps = {
  dataAid?: string;
  initial?: number;
  tabs: BigidTabsItem[];
  onChange: (value: number, tab: BigidTabsItem) => void;
} & PropsWithChildren;

export const DataSourceWizardLayout = ({
  dataAid = 'DataSourceWizardLayout',
  tabs,
  onChange,
  initial,
  children,
}: DataSourceWizardLayoutProps) => {
  const [tab, setTab] = useState(initial ?? 0);

  const handleTabChange = (value: number, tab: BigidTabsItem) => {
    setTab(value);
    onChange?.(value, tab);
  };

  return (
    <BigidPaper dataAid={dataAid}>
      <WizardLayout.Wrapper>
        <WizardLayout.Tabs>
          <BigidTabs selectedIndex={tab} tabs={tabs} onChange={handleTabChange} variant="standard" />
        </WizardLayout.Tabs>
        <WizardLayout.Content>{children}</WizardLayout.Content>
      </WizardLayout.Wrapper>
    </BigidPaper>
  );
};
