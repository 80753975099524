import React, { FC } from 'react';
import { BigidLayoutEmptyState, BigidLayoutEmptyStateAction } from '@bigid-ui/layout';
import { BigidAddIcon, BigidSystemUserIllustration } from '@bigid-ui/icons';
import { UsersGridEmptyStateWrapper } from '../styles';
import { useLocalTranslation } from '../translations';
import { useAccessManagementContext } from '../../../AccessManagementContext';

interface UsersGridEmptyStateProps {
  onNewUser: () => void;
  shouldShowActions?: boolean;
  showEmptyFilterState?: boolean;
}

export const UsersGridEmptyState: FC<UsersGridEmptyStateProps> = ({
  onNewUser,
  shouldShowActions,
  showEmptyFilterState,
}) => {
  const { t } = useLocalTranslation('grid.emptyState');
  const { permissions } = useAccessManagementContext();

  const actions: BigidLayoutEmptyStateAction[] = [
    {
      label: t('button'),
      icon: BigidAddIcon,
      show: () => shouldShowActions,
      execute: async () => {
        onNewUser();
        return { shouldGridReload: false };
      },
    },
  ];

  return (
    <UsersGridEmptyStateWrapper>
      <BigidLayoutEmptyState
        illustration={BigidSystemUserIllustration}
        description={t('text')}
        showEmptyStateWithFilter={showEmptyFilterState}
        actions={permissions.manage && actions}
      />
    </UsersGridEmptyStateWrapper>
  );
};
