import React, { FC, useContext } from 'react';
import { GettingStartedContext } from '../hooks/GettingStartedContext';
import { BigidHeading6, BigidBody1, PrimaryButton, LinkColor } from '@bigid-ui/components';
import makeStyles from '@mui/styles/makeStyles';
import { GettingStartedFooter } from './GettingStartedFooter';
import { GettingStartedFooterItemProps, underlineOptions } from './GettingStartedFooterItem';
import { generateDataAid } from '@bigid-ui/utils';

const useStyles = makeStyles(theme => ({
  root: {
    width: '50%',
    padding: '80px 100px',
    background: theme.vars.palette.bigid.white,
    borderLeft: `1px solid ${theme.vars.palette.bigid.gray150}`,
  },

  mainContent: {
    color: theme.vars.palette.bigid.primary400,
    paddingBottom: 14,
    display: 'flex',
    flexDirection: 'column',
  },
  title: {
    paddingBottom: 24,
  },
  body: {
    paddingBottom: 40,
  },
  mainButton: {
    width: '100%',
    display: 'flex',
  },
  mainIcon: {
    height: '51px',
    width: '42px',
    marginBottom: 24,
  },
  footer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    fontWeight: 700,
  },
}));

export const GettingStartedSelectedCard: FC = () => {
  const classes = useStyles({});
  const { currentStepObject } = useContext(GettingStartedContext);
  const { icon: MainIcon } = currentStepObject || {};

  const getFooterItems = (): Array<GettingStartedFooterItemProps> => {
    const result = [];
    if (currentStepObject?.footerLinkLeft)
      result.push({
        url: currentStepObject.footerLinkLeft,
        name: `${currentStepObject.name}-0`,
        biEventName: currentStepObject.footerLinkLeftBiEvent,
        text: currentStepObject.footerTextLeft,
        linkColor: LinkColor.SECONDARY,
        underline: underlineOptions.ALWAYS,
      });
    if (currentStepObject?.footerLinkRight)
      result.push({
        url: currentStepObject.footerLinkRight,
        name: `${currentStepObject.name}-1`,
        biEventName: currentStepObject.footerLinkRightBiEvent,
        text: currentStepObject.footerTextRight,
        linkColor: LinkColor.SECONDARY,
        underline: underlineOptions.ALWAYS,
      });
    return result;
  };

  return (
    <div className={classes.root}>
      <div className={classes.mainContent}>
        <MainIcon className={classes.mainIcon} />
        <BigidHeading6
          className={classes.title}
          data-aid={generateDataAid('GettingStartedSelectedCard', ['title', currentStepObject.name])}
        >
          {currentStepObject.title}
        </BigidHeading6>
        <BigidBody1
          data-aid={generateDataAid('GettingStartedSelectedCard', ['body', currentStepObject.name])}
          className={classes.body}
        >
          {currentStepObject.body}
        </BigidBody1>
        <PrimaryButton
          onClick={() => {
            currentStepObject.onButtonClick();
          }}
          text={currentStepObject.buttonText}
          size="medium"
          width="fill"
          dataAid={generateDataAid('GettingStartedSelectedCard', ['button', currentStepObject.name])}
          bi={{ eventType: currentStepObject.buttonBiEvent }}
        />
      </div>
      <div className={classes.footer}>
        <GettingStartedFooter items={getFooterItems()} />
      </div>
    </div>
  );
};
