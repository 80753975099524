import { BigidColorsV2 } from '@bigid-ui/components';
import styled from '@emotion/styled';
import { Paper } from '@mui/material';

type DataSourceConnectionDialogStylesProps = {
  isSimple: boolean;
  showSidebar: boolean;
};

type DataSourceConnectionDialogContentStylesProps = {
  showSidebar: boolean;
};

export const Main = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  width: ${({ isSimple, showSidebar }: DataSourceConnectionDialogStylesProps) =>
    !showSidebar ? '100%' : isSimple ? '600px' : 'calc(100% - 580px)'};
  justify-content: stretch;
  border-right: ${({ showSidebar }: DataSourceConnectionDialogStylesProps) =>
    !showSidebar ? '0' : `1px solid ${BigidColorsV2.gray[200]}`};
`;

export const Header = styled.div`
  width: 100%;
`;

export const Footer = styled.div`
  height: 80px;
  width: 100%;
`;

export const Content = styled.div`
  width: auto;
  max-height: calc(100% - 80px);
  overflow: scroll;
  flex: 1 1 auto;
  scroll-behavior: smooth;
  margin-top: 0.3rem;
  margin-right: 0.3rem;

  & > div {
    max-width: ${({ showSidebar }: DataSourceConnectionDialogContentStylesProps) =>
      showSidebar ? '100%' : `calc(100% - 580px)`};
  }

  ::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 7px;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: rgba(0, 0, 0, 0.1);
    box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
  }
`;

export const Aside = styled.div`
  width: 580px;
`;

export const Overlay = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  backdrop-filter: blur(8px);
`;

export const Dialog = styled(Paper)`
  display: flex;
  margin: -16px;
  height: calc(100% + 32px);
  position: relative;
  overflow: hidden;

  & *:not(.DataSourceConnectionDialog-content) {
    -ms-overflow-style: none !important;
  }

  & *:not(.DataSourceConnectionDialog-content)::-webkit-scrollbar {
    width: 0 !important;
  }
`;

export const Container = styled.div``;
