import { BigidEditableTextArea, BigidFormValues } from '@bigid-ui/components';
import makeStyles from '@mui/styles/makeStyles';
import React, { FC, PropsWithChildren, useEffect, useState } from 'react';
import { v4 as uuid } from 'uuid';
import { useReportTemplateContext } from '../hooks/ReportTemplateContext';
import { ReducerActions } from '../hooks/ReportTemplateReducer';
import { DynamicRichTextSubSection, ReportTemplate, ReportTemplateMetaData } from '../Types';
import { AddButton } from './AddSectionButton';
import { DynamicRichText } from './DynamicRichText';
import { SectionName } from './FormSection';

export interface SectionsWrapperProps extends PropsWithChildren {
  sectionName: SectionName;
}

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexFlow: 'column nowrap',
    gap: theme.spacing(2),
    paddingBottom: theme.spacing(3),
  },
  title: {
    width: 'fit-content',
  },
  addSubSectionButton: {
    display: 'flex',
    justifyContent: 'flex-end',
    paddingRight: '8px',
    marginRight: '10%',
  },
}));

export const SectionsWrapper: FC<SectionsWrapperProps> = ({ children, sectionName }) => {
  const classes = useStyles({});
  const { hasEditPermission, dispatch, initialTemplateData } = useReportTemplateContext();
  const [richTextsArray, setRichTextsArray] = useState<DynamicRichTextSubSection[]>([]);
  const dynamicRichTextSectionName = `${sectionName}DynamicRichText` as keyof ReportTemplate;
  const dynamicRichTextInitialValues = initialTemplateData[dynamicRichTextSectionName] as DynamicRichTextSubSection[];

  useEffect(() => {
    if (dynamicRichTextInitialValues?.length) {
      const dynamicValuesCopy = [...dynamicRichTextInitialValues];
      dynamicValuesCopy.forEach(richTextItem => {
        richTextItem.dynamicRichTextId = uuid();
      });
      setRichTextsArray(dynamicValuesCopy);
    }
  }, [dynamicRichTextInitialValues]);

  const updateTitle = (value: string) => {
    const data = { [sectionName]: { sectionTitle: value } };
    dispatch({
      type: ReducerActions.UPDATE_SECTION,
      payload: {
        data,
        sectionName,
      },
    });
  };

  const handleAddRichTextSubSection = () => {
    const richTextNewElement: DynamicRichTextSubSection = {
      sectionTitle: 'Enter Sub-Section Name',
      isEnabled: true,
      dynamicRichTextId: uuid(),
    };
    const updatedArray = [...richTextsArray, richTextNewElement];
    setRichTextsArray(updatedArray);
    dispatch({
      type: ReducerActions.UPDATE_DYNAMIC_RICH_TEXT_SECTION,
      payload: {
        sectionName: `${sectionName}DynamicRichText` as keyof ReportTemplate,
        data: { [`${sectionName}DynamicRichText`]: updatedArray },
      },
    });
  };

  const handleRemoveRichTextSubSection = (index: number) => {
    const updatedArray = [...richTextsArray];
    updatedArray.splice(index, 1);
    setRichTextsArray(updatedArray);
    dispatch({
      type: ReducerActions.UPDATE_DYNAMIC_RICH_TEXT_SECTION,
      payload: {
        sectionName: `${sectionName}DynamicRichText` as keyof ReportTemplate,
        data: { [`${sectionName}DynamicRichText`]: updatedArray },
      },
    });
  };

  const onValuesChange = (values: BigidFormValues, subSectionIndex: number) => {
    if (
      values.hasOwnProperty('content') &&
      ((!richTextsArray[subSectionIndex].content && !values.content) ||
        values.content === richTextsArray[subSectionIndex].content)
    ) {
      return;
    }
    const richTextArrayCopy = [...richTextsArray];
    richTextArrayCopy[subSectionIndex] = { ...richTextArrayCopy[subSectionIndex], ...values };
    setRichTextsArray(richTextArrayCopy);
    dispatch({
      type: ReducerActions.UPDATE_DYNAMIC_RICH_TEXT_SECTION,
      payload: {
        sectionName: `${sectionName}DynamicRichText` as keyof ReportTemplate,
        data: { [`${sectionName}DynamicRichText`]: richTextArrayCopy },
      },
    });
  };

  return (
    <div className={classes.root}>
      <div className={classes.title}>
        <BigidEditableTextArea
          value={(initialTemplateData[sectionName] as ReportTemplateMetaData).sectionTitle}
          isEditPencilAlwaysVisible={hasEditPermission}
          showControls={hasEditPermission}
          controlsPlacement={['right']}
          enlargedControls
          customFontWeight="normal"
          onSubmit={updateTitle}
          isInlineEdit={hasEditPermission}
        />
      </div>
      {children}
      {richTextsArray?.map((richTextItem, index) => {
        return (
          <DynamicRichText
            sectionWrapperName={dynamicRichTextSectionName}
            key={richTextItem.dynamicRichTextId}
            indexInArray={index}
            initialValues={richTextItem}
            sectionWrapperOnChangeHandler={onValuesChange}
            onDeleteSectionHandler={handleRemoveRichTextSubSection}
          />
        );
      })}
      <div className={classes.addSubSectionButton}>
        <AddButton
          label="Add Sub-Section"
          onClick={handleAddRichTextSubSection}
          dataAid={`button-${dynamicRichTextSectionName}`}
        />
      </div>
    </div>
  );
};
