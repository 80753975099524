import './roles.component.scss';
import { module } from 'angular';
const app = module('app');
import { CONFIG } from '../../../config/common';
import template from './roles.component.html';
import '../../../react/views/AccessManagement/components/Roles/RolePermissions';
import { updateUserPermissions, isPermitted } from '../../../react/services/userPermissionsService';
import { ACCESS_MANAGEMENT_PERMISSIONS } from '@bigid/permissions';
import { getApplicationPreference } from '../../../react/services/appPreferencesService';
import { CustomAppEvents, customAppEventsEmitter } from '../../../react/services/customAppEvents';

app.component('roles', {
  template,
  controller: function (
    $rootScope,
    $translate,
    notificationService,
    DeleteConfirmation,
    rolesService,
    $document,
    $timeout,
    $transitions,
    $q,
  ) {
    'ngInject';
    //NOTE: requested by server-side
    const ROOT_SCOPE = { id: 'root', name: 'root' };

    const ROLES_DATA_LIST_ID = 'rolesDataList';
    const ROLES_DATA_LIST_ITEM_ID = 'role';
    const ROLE_CONTENT_ID = 'rolesContentBody';

    const dataListContainer = $document[0].getElementById(ROLES_DATA_LIST_ID);
    const roleContentContainer = $document[0].getElementById(ROLE_CONTENT_ID);

    let isFormDirty = false;

    this.userQuery = '';
    this.role = {};
    this.roleForm = {};
    this.roles = [];
    this.hasCustomRoles = false;

    this.roleId = null;

    this.editModeOn = false;
    this.createModeOn = false;

    this.scopes = [];
    this.permissions = [];

    this.useLandingPagePerUser = getApplicationPreference('ENABLE_LANDING_PAGE_PER_USER_FF');

    // API calls -- start

    const initializeData = async callback => {
      try {
        const [
          {
            data: { roles, permissions },
          },
          {
            data: { scopes },
          },
        ] = await Promise.all([rolesService.getRBACRoles(), rolesService.getRBACScopes()]);

        return callback({ roles, permissions, scopes });
      } catch (err) {
        $translate('API:MESSAGE:COMMON_ERROR').then(translation => {
          notificationService.error(translation);
        });

        return callback({ roles: [], permissions: [], scopes: [] });
      }
    };

    const updateRoles = roles => {
      this.roles = roles
        .map(role => {
          // TODO: change when UI started to support multi select
          const connectedScope = this.scopes.find(scope => scope.id === role.scopes[0]);
          role.scopeName = typeof connectedScope != 'undefined' ? connectedScope.name : '';

          return role;
        })
        .sort(alphaSort);
      this.hasCustomRoles = !!this.roles.find(role => !role.isSystem);
    };

    const fetchRoles = (scrollTo = '', entityToProceed = null, isClone = false, fromDelete = false) => {
      this.onDataLoading();

      rolesService.getRBACRoles().then(
        response => {
          const { roles } = response.data;
          updateRoles(roles);

          if (isClone) {
            this.role = this.roles.find(({ _id }) => _id === this.role._id);
            this.roleId = this.role.name;
          }

          this.onDataLoaded();

          if (entityToProceed !== null) {
            if (Object.keys(entityToProceed).length === 0 && entityToProceed.constructor === Object) {
              onCreateRole();
            } else {
              onEditRole(entityToProceed);
            }
          } else {
            if (fromDelete && this.roles?.length > 0) {
              onEditRole(this.roles[0]);
            }
            if (this.editModeOn) {
              resetListSelection();
              setListSelection(this.role._id);
            }

            $timeout(() => {
              const index = this.roles.findIndex(role => role._id === this.role._id),
                dataListItem = $document[0].getElementById(`${ROLES_DATA_LIST_ITEM_ID}-${index}`);

              if (dataListItem !== null && index !== -1) {
                dataListContainer.scrollTop = index * dataListItem.offsetHeight;
              }
            }, 0);
          }

          $timeout(() => {
            if (!dataListContainer) return;
            switch (scrollTo) {
              case 'top':
                dataListContainer.scrollTop = 0;
                break;
              case 'bottom':
                dataListContainer.scrollTop = dataListContainer.scrollHeight;
                break;
              default:
                if (entityToProceed !== null) {
                  if (Object.keys(entityToProceed).length === 0 && entityToProceed.constructor === Object) {
                    dataListContainer.scrollTop = 0;
                  } else {
                    const index = this.roles.findIndex(role => role._id === entityToProceed._id),
                      dataListItem = $document[0].getElementById(`${ROLES_DATA_LIST_ITEM_ID}-${index}`);

                    if (dataListItem !== null && index !== -1) {
                      dataListContainer.scrollTop = index * dataListItem.offsetHeight;
                    }
                  }
                }
            }
          }, 0);
        },
        () => {
          this.onDataLoaded();

          $translate('API:MESSAGE:COMMON_ERROR').then(translation => {
            notificationService.error(translation);
          });
        },
      );
    };

    this.onCreateSuccess = response => {
      $translate('API:MESSAGE:COMMON_POST_SUCCESS').then(translation => {
        notificationService.success(translation);
      });

      this.editModeOn = true;
      this.createModeOn = false;

      this.role._id = response.data;
      this.roleId = this.role.name;

      isFormDirty = false;

      this.roleForm.$submitted = false;

      fetchRoles('', null, true);
      return true;
    };

    this.onCreateFail = response => {
      if (typeof response.data.message == 'undefined' && typeof response.data.details == 'undefined') {
        $translate('API:MESSAGE:COMMON_ERROR').then(translation => {
          notificationService.error(translation);
        });
      } else {
        const message = response.data.message || '';
        const details = response.data.details || '';

        notificationService.error(`${message} ${details}`);
      }
      return false;
    };

    const createRole = (data, entityToProceed = null, isClone = false) => {
      return rolesService.createRole({ role: data, isClone }).then(this.onCreateSuccess).catch(this.onCreateFail);
    };

    const duplicateRole = roleName => {
      return rolesService.duplicateRole(roleName).then(this.onCreateSuccess).catch(this.onCreateFail);
    };

    const updateRole = (id, data, entityToProceed = null) => {
      return rolesService.updateRole(id, { role: data }).then(
        () => {
          $translate('API:MESSAGE:COMMON_PUT_SUCCESS').then(translation => {
            notificationService.success(translation);
          });

          isFormDirty = false;

          this.roleForm.$submitted = false;
          this.roleId = data.name;

          fetchRoles('', entityToProceed);
          updateUserPermissions();
          // update left nav for privacy portal
          customAppEventsEmitter.emit(CustomAppEvents.UPDATE_APP_LIST);
          return true;
        },
        response => {
          if (typeof response.data.message == 'undefined' && typeof response.data.details == 'undefined') {
            $translate('API:MESSAGE:COMMON_ERROR').then(translation => {
              notificationService.error(translation);
            });
          } else {
            const message = response.data.message || '';
            const details = response.data.details || '';

            notificationService.error(`${message} ${details}`);
          }
          return false;
        },
      );
    };

    const deleteRole = id => {
      rolesService.deleteRole(id).then(
        () => {
          $translate('API:MESSAGE:COMMON_DELETE_SUCCESS').then(translation => {
            notificationService.success(translation);
          });

          this.editModeOn = false;
          this.createModeOn = false;

          fetchRoles('top', null, false, true);
        },
        response => {
          if (typeof response.data.message == 'undefined' && typeof response.data.details == 'undefined') {
            $translate('API:MESSAGE:COMMON_ERROR').then(translation => {
              notificationService.error(translation);
            });
          } else {
            const message = response?.data?.message || '';
            const details = response?.data?.details?.message || '';

            notificationService.error(`${message} ${details}`);
          }
        },
      );
    };

    // API calls -- end

    // component methods -- start

    this.$onInit = () => {
      this.onStartTransitionListener = $transitions.onBefore({}, () => {
        return new $q(resolve => {
          if (!isFormDirty) {
            resolve();
            return;
          }

          onUserLeavesForm(
            () => {
              if (this.isFormInvalid()) {
                notificationService.warning('In order to save your changes, please fill out all the mandatory fields');
                resolve(false);
              } else {
                this.roleForm.$submitted = true;
                onSubmitRole({})
                  .then(result => {
                    resolve(result);
                    isFormDirty = !result;
                  })
                  .catch(() => {
                    resolve(false);
                    isFormDirty = false;
                  });
              }
            },
            () => {
              isFormDirty = false;
              resolve();
            },
          );
        });
      });

      this.isManagePermitted = isPermitted(ACCESS_MANAGEMENT_PERMISSIONS.MANAGE.name);

      this.onDataLoading();

      initializeData(result => {
        const { roles, scopes } = result;

        this.scopes = scopes.length ? [...scopes] : [ROOT_SCOPE];

        updateRoles(roles);

        if (this.roles.length > 0) {
          onEditRole(this.roles[0]);
        }

        this.onDataLoaded();
      });
    };

    this.$onDestory = () => {
      this.onStartTransitionListener();
    };

    this.onCreate = () => {
      if (isFormDirty) {
        onUserLeavesForm(
          () => {
            this.roleForm.$submitted = true;

            onSubmitRole({});
          },
          () => {
            onCreateRole();
          },
        );
      } else {
        onCreateRole();
      }
    };

    this.onEdit = role => {
      if (this.role._id !== role._id) {
        if (this.isManagePermitted && isFormDirty) {
          onUserLeavesForm(
            () => {
              this.roleForm.$submitted = true;
              onSubmitRole(role);
            },
            () => {
              onEditRole(role);
            },
          );
        } else {
          this.scrollRoleContentToTop();
          onEditRole(role);
        }
      }
    };

    this.onDelete = async role => {
      const closeButtonText = await $translate('BUTTONS:CLOSE');
      const actionButtonText = await $translate('BUTTONS:DELETE');
      const headerText = await $translate('APP:COMMON:DELETE_CONFIRMATION_HEADER', { entityName: role.name });
      const bodyText = await $translate('APP:COMMON:DELETE_CONFIRMATION_TEXT', { entityName: role.name });

      const modalOptions = {
        closeButtonText: closeButtonText,
        actionButtonText: actionButtonText,
        headerText: headerText,
        bodyText: bodyText,
      };

      DeleteConfirmation.showModal({}, modalOptions).then(() => deleteRole(role.name));
    };

    this.onSubmit = () => {
      onSubmitRole();
    };

    this.onCancel = () => {
      this.role = {};

      this.roleId = null;

      resetFormState();
    };

    this.onPermissionSelected = role => {
      // TODO: change when UI started to support multi select
      this.role.permissions = [role];

      isFormDirty = true;
    };

    this.onScopeSelected = scope => {
      // TODO: change when UI started to support multi select
      this.role.scopes = [scope.id];

      isFormDirty = true;
    };

    this.onFormChanged = () => {
      isFormDirty = true;
    };

    const alphaSort = (a, b) => {
      const aName = a.name.toLowerCase(),
        bName = b.name.toLowerCase();

      if (aName < bName) {
        return -1;
      } else if (aName > bName) {
        return 1;
      } else {
        return 0;
      }
    };

    const resetFormState = () => {
      if (
        typeof this.roleForm != 'undefined' &&
        typeof this.roleForm.$setPristine == 'function' &&
        typeof this.roleForm.$setUntouched == 'function'
      ) {
        this.roleForm.$setPristine();
        this.roleForm.$setUntouched();
      } else {
        this.roleForm = {};
      }

      this.roleForm.$submitted = false;

      isFormDirty = false;
    };

    const resetListSelection = (except = '') => {
      for (let i = 0, len = this.roles.length; i < len; i++) {
        if (this.roles[i]._id === except) {
          continue;
        }
        this.roles[i]['selected'] = false;
      }
    };

    const setListSelection = _id => {
      for (let i = 0, len = this.roles.length; i < len; i++) {
        if (this.roles[i]._id === _id) {
          this.roles[i]['selected'] = true;
          break;
        }
      }
    };

    const onUserLeavesForm = async (resolveCallback, rejectCallback) => {
      const closeButtonText = await $translate('BUTTONS:NO');
      const actionButtonText = await $translate('BUTTONS:YES');
      const headerText = await $translate('APP:COMMON:LEAVE_CONFIRMATION_HEADER');
      const bodyText = await $translate('APP:COMMON:LEAVE_CONFIRMATION_TEXT');

      const modalOptions = {
        closeButtonText: closeButtonText,
        actionButtonText: actionButtonText,
        headerText: headerText,
        bodyText: bodyText,
      };

      DeleteConfirmation.showModal({}, modalOptions).then(resolveCallback, rejectCallback);
    };

    const onCreateRole = () => {
      resetListSelection();

      this.createModeOn = true;
      this.editModeOn = false;

      this.role = {};
      this.roleId = null;

      resetFormState();
    };

    const onEditRole = role => {
      resetListSelection();
      setListSelection(role._id);

      this.createModeOn = false;
      this.editModeOn = true;

      this.role = angular.copy(role);
      this.roleId = angular.copy(role.name);

      resetFormState();
    };

    this.duplicateRole = role => {
      duplicateRole(role.name);
    };

    const onSubmitRole = async () => {
      if (isNameChosenSystemRoleDisplayName()) {
        notificationService.error('This role name already exists');
        return false;
      }

      if (this.isFormInvalid()) {
        notificationService.warning('In order to save your changes, please fill out all the mandatory fields');
        return false;
      }
      const { selected, scopeName, ...roleToUpdate } = { ...this.role };
      return this.createModeOn ? createRole(roleToUpdate) : updateRole(this.roleId, roleToUpdate);
    };

    this.updateRoleGranularPermissions = granularPermissions => {
      this.role = { ...this.role, granularPermissions };
      isFormDirty = true;
      $rootScope.$applyAsync();
    };

    this.updateRoleInfo = rolePropsToUpdate => {
      this.role = { ...this.role, ...rolePropsToUpdate };
      isFormDirty = true;
      $rootScope.$applyAsync();
    };

    const isNameChosenSystemRoleDisplayName = () =>
      Object.values(CONFIG.permissionDisplayNames).findIndex(name => name === this.role.name) > -1;

    this.isFormInvalid = () =>
      !this.roleForm.$valid || !this.role.granularPermissions || this.role.granularPermissions?.length === 0;

    this.scrollRoleContentToTop = () => {
      if (!roleContentContainer) return;
      roleContentContainer.scrollTop = 0;
    };
  },
  bindings: {
    onDataLoading: '&',
    onDataLoaded: '&',
  },
});
