import { BigidGridRow } from '@bigid-ui/grid';
import { TagEntity } from '../../TagsManagement/TagsManagementService';
import { OwnerFieldNameEnum } from '../DataSourceConfiguration/types';

interface ConnectionStatus {
  is_success: boolean;
  is_connection_ever_succeeded?: boolean;
  last_connection: string;
  num_of_object: number;
}

export interface OwnerV2 {
  id: string;
  email?: string;
  type: OwnerFieldNameEnum;
  origin: string;
  isNotified?: boolean;
}

export interface DataSourceModel extends BigidGridRow {
  name: string;
  friendly_name: string;
  type: string;
  displayType?: string;
  enabled: string;
  archived?: boolean;
  comment?: boolean;
  connectionStatusScan?: ConnectionStatus;
  connectionStatusTest?: ConnectionStatus;
  connectionStatusDeleteFindings?: ConnectionStatus;
  updated_at: string;
  location?: string;
  rdb_comma_sep_table_list?: string;
  rdb_name?: string;
  classification_is_enabled?: boolean;
  ner_classification_is_enabled?: boolean;
  hyperScanModelAvailable?: boolean;
  tags: TagEntity[];
  isDsarOnly?: boolean;
  owners_v2: OwnerV2[];
  created_at: string;
  discoveryAppType?: string;
  isFromDiscoveryApp: boolean;
  last_scan_at: number;
  nameInDocs: string;
}

export interface DataSourcesResponse {
  ds_connections: DataSourceModel[];
  totalCount: number;
}

export interface DsCountByTypeResponse {
  totalCount: number;
  dsCountByType: Array<{
    type: string;
    count: number;
  }>;
}

export interface TestConnectionStatusResponse {
  isInProgress: boolean;
  seeEventID: string;
}

export const IMPORT_PREFIX = 'Import and testing Data Sources in progress!';
export const TEST_CONNECTION_PREFIX = 'Testing Data Sources in progress!';
export const ACTION_SUFFIX = 'This may take a while...';
export const DELETE_DESCRIPTION_FINDINGS = ' and all related findings if they exist';
export const TOTAL_ROWS_THRESHOLD = 1e6;
