import { isEqual } from 'lodash';
import { BigidFormValues } from '@bigid-ui/components';
import {
  ConfigurationFormFields,
  ConfigurationsMetadata,
  ConfigurationObject,
  FieldType,
  Configuration,
} from './configurationManagementTypes';

export const getConfigurationTypeOptions = (configurationsMetadata: ConfigurationsMetadata) =>
  configurationsMetadata.map(({ type, displayName }) => ({
    label: displayName,
    value: type,
  }));

export const getFormKey = (configurationType: string, key: string) => `configuration.${configurationType}.${key}`;

const getConfigurationFormInitialFields = (
  configurationsMetadata: ConfigurationsMetadata,
  configuration?: ConfigurationFormFields,
) =>
  configurationsMetadata.reduce<ConfigurationObject>(
    (configurationInitialValue, { type: configurationType, fields }) => {
      fields.forEach(({ name, type, options }) => {
        const formKey = getFormKey(configurationType, name);
        switch (type) {
          case FieldType.CHECKBOX: {
            configurationInitialValue[formKey] = configuration?.configuration[name] || false;
            break;
          }
          case FieldType.SELECT: {
            configurationInitialValue[formKey] = configuration
              ? options.filter(({ value }) => configuration.configuration[name] === value)
              : [];
            break;
          }
          default: {
            configurationInitialValue[formKey] = configuration ? configuration?.configuration[name] : '';
            break;
          }
        }
      });
      return configurationInitialValue;
    },
    {},
  );

export const getConfigurationInitialFormValue = (
  configurationsMetadata: ConfigurationsMetadata,
  configuration?: ConfigurationFormFields,
) => {
  const configurationTypeOptions = getConfigurationTypeOptions(configurationsMetadata);
  return {
    name: configuration?.name || '',
    type: configuration
      ? configurationTypeOptions.filter(({ value }) => value === configuration.type)
      : [configurationTypeOptions[0]],
    ...getConfigurationFormInitialFields(configurationsMetadata, configuration),
  };
};

export const checkIfFormIsDirty = (
  currentConfiguration: ConfigurationFormFields,
  formValues: ConfigurationFormFields,
  configurationsMetadata: ConfigurationsMetadata,
): boolean => {
  const newConfiguration = getServiceFormatFromForm(formValues, configurationsMetadata);
  if (!currentConfiguration && newConfiguration) {
    return true;
  }
  return !isEqual(newConfiguration, currentConfiguration);
};

const getConfigurationInServiceFormat = (
  selectedType: string,
  configuration: ConfigurationObject,
  configurationsMetadata: ConfigurationsMetadata,
) => {
  const fields = configurationsMetadata.find(({ type }) => type === selectedType)?.fields || [];
  return Object.keys(configuration).reduce<ConfigurationObject>((configurationFormat, key) => {
    const keyWithoutType = key.replace(`configuration.${selectedType}.`, '');
    const { type } = fields.find(({ name }) => name === keyWithoutType) || {};

    if (type === FieldType.SELECT) {
      configurationFormat[keyWithoutType] = configuration[key]?.[0]?.value ?? '';
    } else {
      configurationFormat[keyWithoutType] = configuration[key];
    }

    return configurationFormat;
  }, {});
};

export const getServiceFormatFromForm = (values: BigidFormValues, configurationsMetadata: ConfigurationsMetadata) => {
  delete values.__valuesCounter;
  const { type, name, ...configurationForm } = values;

  const extractedType = type[0].value;
  return {
    name,
    type: extractedType,
    configuration: getConfigurationInServiceFormat(extractedType, configurationForm, configurationsMetadata),
  };
};

export const removeTpasConfigurations = (configurations: Configuration[]) => {
  const configurationsWithoutTpas = configurations.filter(configuration => configuration.type !== 'tpa');
  return {
    configurations: configurationsWithoutTpas,
    totalCount: configurationsWithoutTpas.length,
  };
};
