import React, { FC, useMemo, useState, useContext, useEffect, useCallback } from 'react';
import { ReducerActions } from '../../hooks/ActionableInsightsReducer';
import {
  BigidGridColumn,
  BigidGridColumnTypes,
  BigidGridQueryComponents,
  BigidGridWithToolbar,
  BigidGridWithToolbarProps,
} from '@bigid-ui/grid';
import { ActionableInsightsDetailedCase } from '../ActionableInsightsDetailedCase';
import {
  BigidLoader,
  EntityEvents,
  entityEventsEmitter,
  BigidSeverityBadge,
  BigidStatusBadgeSize,
} from '@bigid-ui/components';
import { useUserPreferences } from '../../../../components/hooks/useUserPrefrences';
import {
  fetchGroupedCasesData,
  getFilterConfig,
  PolicyModel,
  ViewType,
  generateTabs,
  ACTIONABLE_INSIGHTS_PREFERENCE_IDENTIFIER_PREFIX,
  eventTracking,
  getBulkSelectionToolbarActions,
} from '../../actionableInsightsService';
import { useLocalTranslation } from '../../translations';
import { LoaderWrapper } from '../actionableInsightsGridStyles';
import { ActionableInsightsContext } from '../../hooks/ActionableInsightsContext';
import {
  ActionableInsightsPageViewsEvents,
  trackActionableInsightsPageView,
} from '../../actionableInsightsTrackingUtil';
import { formatNumberCompact } from '../../../../utilities/numericDataConverter';
import { ActionableInsightsGuidedTour } from '../../ActionableInsightsGuidedTours/ActionableInsightsGuidedTour';
import { CaseActionsModal } from '../CaseActionsModal/CaseActionsModal';
import { ActionsDialogTypes, useCaseActions } from '../CaseActionsModal/hooks/useCaseActions';
import styled from '@emotion/styled';
/* this is workaround for hidding "Select All Item" button which is not working in group view. It should be fix in the BDT-71704 ticket */
import './actionable-insights-grouped-cases.css';
import { CaseSidePanel } from '../../CaseSidePanel/CaseSidePanel';
import { BigidGridWithToolbarViewToggle } from '../BigidGridWithToolbarViewToggle';

const GroupedGridWrapper = styled('div')({
  display: 'flex',
  height: '100%',
  width: '100%',
  flex: 1,
  '& .MuiTableRow-root>.TableDetailCell-active:first-child': {
    padding: 0,
  },
});

export interface ActionableInsightsGroupedCasesProps {
  initialCaseReportData: any;
}

export const ActionableInsightsGroupedCases: FC<ActionableInsightsGroupedCasesProps> = ({ initialCaseReportData }) => {
  const [expandedRowIds, setExpandedRowIds] = useState<(number | string)[]>([]);
  const { caseState, dispatch, viewType } = useContext(ActionableInsightsContext);
  const {
    isOpen,
    isCaseSidePanelOpen,
    closeCaseSidePanel,
    openModalWithData,
    modalType,
    closeModal,
    rowData,
    openCaseSidePanelWithData,
    filters,
  } = useCaseActions();
  const [isDetailedCaseLoading, setIsDetailedCaseLoading] = useState(false);
  const { t } = useLocalTranslation('Case');

  const columns = useMemo<BigidGridColumn<PolicyModel>[]>(
    () => [
      {
        name: 'policyName',
        title: t('fields.cases'),
        getCellValue: row => row.policyName,
        type: BigidGridColumnTypes.TEXT,
        width: 400,
        disableTooltip: true,
      },
      {
        name: 'policyLastTriggered',
        title: t('fields.lastTriggered'),
        getCellValue: row => row.policyLastTriggered,
        type: BigidGridColumnTypes.DATE,
        width: 200,
        disableTooltip: true,
      },
      {
        name: 'numOfCases',
        title: t('fields.numOfCases'),
        getCellValue: row => formatNumberCompact(row.numOfCases),
        type: BigidGridColumnTypes.TEXT,
        width: 100,
        disableTooltip: true,
      },
      {
        name: 'severityLevel',
        title: t('fields.severityLevel'),
        getCellValue: row => (
          <BigidSeverityBadge hasBackground={false} size={BigidStatusBadgeSize.SMALL} level={row.severityLevel} />
        ),
        type: BigidGridColumnTypes.CUSTOM,
        width: 120,
        disableTooltip: true,
      },
      {
        name: 'domain',
        title: t('fields.domain'),
        getCellValue: row => (row.domain ? t(`category.${row.domain}`) : ''),
        type: BigidGridColumnTypes.TEXT,
        width: 120,
        disableTooltip: true,
      },
      {
        name: 'compliance',
        title: t('fields.compliance'),
        disableTooltip: true,
        type: BigidGridColumnTypes.CHIPS,
        getCellValue: row => ({
          chips: {
            value: row.compliance ? [{ label: row.compliance }] : [],
            isEditMode: false,
            isDisabled: true,
          },
        }),
      },
      {
        name: 'policyDescription',
        title: t('fields.policyDescription'),
        getCellValue: row => row.policyDescription,
        type: BigidGridColumnTypes.TEXT,
        width: 200,
        disableTooltip: false,
      },
    ],
    [t],
  );

  const { isReady, gridColumns, filterToolbarConfig, updatePreferences } = useUserPreferences({
    stateName: `${ACTIONABLE_INSIGHTS_PREFERENCE_IDENTIFIER_PREFIX}.${caseState}`,
    initialGridColumns: columns,
    getInitialFilterToolbarConfig: getFilterConfig,
  });

  useEffect(() => {
    trackActionableInsightsPageView(ActionableInsightsPageViewsEvents.DATA_RISK_MANAGEMENT_PAGE_VIEW);
  }, []);

  useEffect(() => {
    if (initialCaseReportData) {
      openCaseSidePanelWithData([initialCaseReportData]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    isOpen && eventTracking(modalType, ViewType.GROUPED);
  }, [isOpen, modalType]);

  const onCaseAction = useCallback(async () => {
    const tabs = await generateTabs();
    dispatch({
      type: ReducerActions.UPDATE_ACTIONABLE_INSIGHTS_DATA,
      payload: { tabs },
    });
  }, [dispatch]);

  const onCloseExpandedRows = () => {
    setExpandedRowIds([]);
  };

  const onModalCtaClick = (modifiedValue?: string, additionalFilters = {}, customModalType?: ActionsDialogTypes) => {
    const mergeFilters = { ...filters, ...additionalFilters };
    const type = modalType || customModalType;
    filters?.callback?.();

    switch (type) {
      case ActionsDialogTypes.SILENCE:
      case ActionsDialogTypes.ACKNOWLEDGE: {
        if (mergeFilters.caseIds?.length) {
          entityEventsEmitter.emit(EntityEvents.UPDATE_BY_ID, mergeFilters?.caseIds[0], { caseStatus: modifiedValue });
        }

        entityEventsEmitter.emit(EntityEvents.DELETE, mergeFilters?.policies || mergeFilters?.caseIds);
        entityEventsEmitter.emit(EntityEvents.RELOAD);
        break;
      }
      case ActionsDialogTypes.ASSIGN: {
        if (mergeFilters.caseIds?.length) {
          entityEventsEmitter.emit(EntityEvents.UPDATE_BY_ID, mergeFilters?.caseIds[0], { assignee: modifiedValue });
        } else {
          const updatedPolicies = mergeFilters?.policies?.map(policy => ({
            ...policy,
            cases: policy.cases.map(caseItem => ({ ...caseItem, assignee: modifiedValue })),
          }));
          entityEventsEmitter.emit(EntityEvents.UPDATE, updatedPolicies);
          entityEventsEmitter.emit(EntityEvents.RELOAD);
        }
        break;
      }
      default: {
        if (mergeFilters.caseIds?.length) {
          entityEventsEmitter.emit(EntityEvents.UPDATE_BY_ID, mergeFilters?.caseIds[0], { caseStatus: modifiedValue });
        }

        entityEventsEmitter.emit(EntityEvents.RELOAD);
      }
    }

    onCaseAction();
  };

  const gridWithToolbarConfig: BigidGridWithToolbarProps<PolicyModel> = useMemo(
    () => ({
      filterToolbarConfig,
      onGridStateChange: ({ filter, ...gridState }) => updatePreferences({ filterState: { filter }, gridState }),
      pagingMode: true,
      pageSize: 15,
      customRowIdName: 'policyName',
      hideColumnChooser: false,
      foreignElementPlacement: 'right',
      rowClickShouldKeepSelection: true,
      showSelectAll: false,
      showSelectionCheckboxes: false,
      rowDetailConfig: {
        expandedRowIds: expandedRowIds,
        onExpandedRowIdsChange: ([initialRow, newRow]) => {
          const rowId = newRow ?? initialRow;
          setExpandedRowIds([rowId]);
        },

        detailRowContentComponent: row => (
          <ActionableInsightsDetailedCase
            onCloseExpandedRows={onCloseExpandedRows}
            onCaseAction={onModalCtaClick}
            row={row?.row}
            openModalWithData={openModalWithData}
            openCaseReportWithData={openCaseSidePanelWithData}
            isDetailedCaseLoading={isDetailedCaseLoading}
          />
        ),
      },
      columns: gridColumns,
      fetchData: (queryComponents: BigidGridQueryComponents) => fetchGroupedCasesData(queryComponents, caseState),
      showSortingControls: true,
      displayActionToolbar: true,
      entityName: t('fields.groupedViewEntityName'),
      foreignElement: (
        <BigidGridWithToolbarViewToggle
          currentView={viewType}
          onToggle={viewType => {
            dispatch({
              type: ReducerActions.UPDATE_ACTIONABLE_INSIGHTS_DATA,
              payload: { viewType },
            });
          }}
        />
      ),
      shouldShowViewToggle: false,
      toolbarActions: [
        ...getBulkSelectionToolbarActions(openModalWithData, caseState, onModalCtaClick, ViewType.GROUPED),
      ],
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      caseState,
      dispatch,
      expandedRowIds,
      filterToolbarConfig,
      gridColumns,
      isDetailedCaseLoading,
      updatePreferences,
      t,
    ],
  );

  useEffect(() => {
    trackActionableInsightsPageView(ActionableInsightsPageViewsEvents.DATA_RISK_MANAGEMENT_PAGE_VIEW);
  }, []);

  useEffect(() => {
    if (initialCaseReportData) {
      openCaseSidePanelWithData([initialCaseReportData]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    isOpen && eventTracking(modalType, ViewType.GROUPED);
  }, [isOpen, modalType]);

  return (
    <>
      <ActionableInsightsGuidedTour />
      <CaseSidePanel
        caseData={rowData}
        isOpen={isCaseSidePanelOpen}
        onClose={closeCaseSidePanel}
        onCaseAction={onModalCtaClick}
      />
      <CaseActionsModal
        onCaseAction={onModalCtaClick}
        rowsData={rowData}
        type={modalType}
        closeModal={closeModal}
        isOpen={isOpen}
        viewType={ViewType.GROUPED}
        filters={filters}
      />
      {!isReady ? (
        <LoaderWrapper>
          <BigidLoader />
        </LoaderWrapper>
      ) : (
        <GroupedGridWrapper>
          <BigidGridWithToolbar {...gridWithToolbarConfig} />
        </GroupedGridWrapper>
      )}
    </>
  );
};
