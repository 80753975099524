import React, { FC } from 'react';
import styled from '@emotion/styled';
import { ProxiesActions, ProxiesActionsProps } from './ProxiesActions';
import { BigidHeading5 } from '@bigid-ui/components';

export type ProxiesHeaderProps = ProxiesActionsProps & {
  title: string;
};

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 24px;
  gap: 16px;
  height: 100%;
`;

const styles = {
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  fontWeight: 700,
};

export const ProxiesHeader: FC<ProxiesHeaderProps> = ({
  title,
  row,
  isBusy,
  handleSubmit,
  handleDelete,
  handleTestConnection,
}) => (
  <Header>
    <BigidHeading5 sx={styles}>{title}</BigidHeading5>
    <ProxiesActions
      row={row}
      isBusy={isBusy}
      handleSubmit={handleSubmit}
      handleDelete={handleDelete}
      handleTestConnection={handleTestConnection}
    />
  </Header>
);
