import React, { FunctionComponent } from 'react';
import { BigidContentItem } from '@bigid-ui/components';
import { DataCatalogRecord } from '../DataCatalogService';
import { CustomDashboardContainer } from '../../CustomDashboard/CustomDashboardPreviewMode';
import { externalDashboardConfig } from './constants';
import { styled } from '@mui/material';

const TabRoot = styled('div')({
  display: 'flex',
  flexFlow: 'column nowrap',
  width: '100%',
  overflow: 'auto',
});

export const DataCatalogActivityMonitoring: FunctionComponent<BigidContentItem & DataCatalogRecord> = ({
  fullyQualifiedName,
}) => {
  return (
    <TabRoot>
      <CustomDashboardContainer config={externalDashboardConfig(fullyQualifiedName)} />
    </TabRoot>
  );
};
