import { analyticsService } from '../../services/analyticsService';

export enum ScanViewEnum {
  ACTIVITY_QUEUE = 'Activity Queue',
  COMPLETED_SCANS = 'Completed Scans',
  SAVED_PROFILES = 'Saved Profiles',
  SCAN_INSIGHTS = 'Scan Insights',
  LEGACY_SCANS_PAGE = 'Legacy Scan Page',
  SCAN_TEMPLATES = 'Scan Templates',
}

export enum ScansUITrackingEvent {
  // Navigation Clicks:
  SHOW_NEW_UI_CLICK = 'ADMINISTRATION_SCANS_SHOW_NEW_UI_CLICK',
  SHOW_OLD_UI_CLICK = 'ADMINISTRATION_SCANS_SHOW_OLD_UI_CLICK',
  SCAN_TAB_CLICK = 'ADMINISTRATION_SCANS_SCAN_TAB_CLICK',
  CREATE_SCAN_PROFILE_CLICK = 'ADMINISTRATION_SCANS_CREATE_SCAN_PROFILE_CLICK',
  CREATE_SCHEDULED_SCAN_CLICK = 'CREATE_SCHEDULED_SCAN_CLICK',
  CREATE_SCHEDULED_SCAN_CLICK_EMPTY_TEMPLATE = 'CREATE_SCHEDULED_SCAN_CLICK_EMPTY_TEMPLATE',
  CREATE_SCAN_TEMPLATE_CLICK = 'CREATE_SCAN_TEMPLATE_CLICK',

  // Scan Action Clicked:
  ACTIVE_SCANS_SCAN_INSIGHT_LINK_CLICK = 'ADMINISTRATION_SCANS_ACTIVE_SCANS_SCAN_INSIGHT_LINK_CLICK',
  COMPLETED_SCANS_SCAN_INSIGHT_LINK_CLICK = 'ADMINISTRATION_SCANS_COMPLETED_SCANS_SCAN_INSIGHT_LINK_CLICK',
  SCAN_ACTION_CLICK = 'ADMINISTRATION_SCANS_SCAN_ACTION_CLICK',
  SCAN_OLD_UI_ACTION_CLICK = 'ADMINISTRATION_SCANS_SCAN_OLD_UI_ACTION_CLICK',

  // Scan Action Complete:
  SCAN_ACTION_COMPLETE = 'ADMINISTRATION_SCANS_SCAN_ACTION_COMPLETE',

  // Scan Profile Action Clicked:
  SCAN_PROFILE_ACTION_CLICK = 'ADMINISTRATION_SCANS_SCAN_PROFILE_ACTION',

  //Scan Insights Page:
  SUB_SCAN_INSIGHT_CLICK = 'SCANS_SCAN_INSIGHTS_SUB_SCAN_INSIGHT_CLICK',
  VIEW_IN_CATALOG_CLICK = 'SCANS_SCAN_INSIGHTS_VIEW_IN_CATALOG_CLICK',
  EDIT_SCAN_PROFILE_CLICK = 'SCANS_SCAN_INSIGHTS_EDIT_SCAN_PROFILE_CLICK',
  DOWNLOAD_REPORT = 'SCANS_SCAN_INSIGHTS_DOWNLOAD_REPORT',
  VIEW_DETAILS_DATE_SOURCES_INSIGHTS_CLICK = 'VIEW_DETAILS_DATE_SOURCES_INSIGHTS',
  VIEW_DETAILS_CORRELATION_SET_ATTRIBUTES_CLICK = 'VIEW_DETAILS_CORRELATION_SET_ATTRIBUTES',
  VIEW_DETAILS_CLASSIFICATION_ATTRIBUTES_CLICK = 'VIEW_DETAILS_CLASSIFICATION_ATTRIBUTES',
  SCAN_SIDE_PANEL_TAB_CLICK = 'SCAN_SIDE_PANEL_TAB_CLICK',
  SCAN_OVERVIEW_COLLAPSE_CLICK = 'SCAN_OVERVIEW_COLLAPSE_CLICK',
  SCAN_DETAILS_COLLAPSE_CLICK = 'SCAN_DETAILS_COLLAPSE_CLICK',
}

export const trackEventScansView = (eventType: ScansUITrackingEvent, data?: Record<string, unknown>) => {
  // temporary ignore type
  analyticsService.trackManualEvent(eventType as any, data);
};
