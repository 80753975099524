import * as React from 'react';
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const RiskLibraryEmptyGrid = (props: any) => (
  // eslint-disable-next-line no-restricted-syntax
  <svg xmlns="http://www.w3.org/2000/svg" width={140} height={121} fill="none" {...props}>
    <path
      fill="#F0F0F0"
      d="M112.155 71.31c40.485 15.711 19.787 26.905 13.016 31.316-6.771 4.412-13.016 7.395-13.016 7.395L2.257 47.099c-.452-.258-.269-.943.248-.88 11.816 1.435 67.801 8.852 109.65 25.092Z"
    />
    <path
      fill="#F8F8F8"
      stroke="#222"
      strokeWidth={0.29}
      d="m33.955 1.877-12.81 7.4 91.518 99.347c2.373-1.281 7.828-4.27 10.675-5.978-1.566-32.878-15.229-65.045-43.268-87.106C57.639-2.108 39.98-.922 33.955 1.877Z"
    />
    <path
      fill="#fff"
      d="M60.922 15.531C16.302-9.598 3.41 24.898 3.212 45.566L112.98 108.91c1.686-20.47-15.766-69.588-52.057-93.378Z"
    />
    <path
      fill="#fff"
      d="M60.922 15.531C16.302-9.598 3.41 24.898 3.212 45.566L112.98 108.91c1.686-20.47-15.766-69.588-52.057-93.378Z"
    />
    <path
      stroke="#222"
      strokeWidth={0.29}
      d="M60.922 15.531C16.302-9.598 3.41 24.898 3.212 45.566L112.98 108.91c1.686-20.47-15.766-69.588-52.057-93.378Z"
    />
    <path
      fill="#fff"
      d="M60.802 17.34C18.103-6.714 4.275 25.48 4.085 45.265l106.532 61.457c1.613-19.593-15.087-66.61-49.815-89.382Z"
    />
    <path
      fill="#fff"
      d="M60.802 17.34C18.103-6.714 4.275 25.48 4.085 45.265l106.532 61.457c1.613-19.593-15.087-66.61-49.815-89.382Z"
    />
    <path
      stroke="#222"
      strokeWidth={0.29}
      d="M60.802 17.34C18.103-6.714 4.275 25.48 4.085 45.265l106.532 61.457c1.613-19.593-15.087-66.61-49.815-89.382Z"
    />
    <path
      fill="#F2F2F2"
      fillRule="evenodd"
      d="M45.849 49.683c-1.803.285-4.793 3.979-1.99 9.162 1.987 3.673 5.263 4.075 8.537 1.94l9.109-7.828-15.656-3.274Z"
      clipRule="evenodd"
    />
    <path fill="#D9D9D9" d="m45.849 49.683 15.656 3.274 1.708-1.139-15.372-3.274c-.711 0-1.423.427-1.992 1.14Z" />
    <path
      stroke="#222"
      strokeWidth={0.29}
      d="m61.505 52.957-15.656-3.274m15.656 3.274-9.11 7.828c-3.273 2.135-6.55 1.733-8.536-1.94-2.803-5.183.187-8.877 1.99-9.162m15.656 3.274 1.708-1.139-15.372-3.274c-.711 0-1.423.427-1.992 1.14"
    />
    <path
      fill="#D9D9D9"
      stroke="#222"
      strokeWidth={0.29}
      d="M48.804 55.7c.421.792.59 1.614.53 2.299-.06.686-.346 1.215-.816 1.465s-1.07.192-1.672-.143c-.601-.333-1.188-.933-1.609-1.725-.42-.792-.59-1.614-.53-2.299.06-.686.347-1.215.817-1.465s1.069-.192 1.671.142c.602.334 1.188.934 1.61 1.726Z"
    />
    <path
      fill="#CDC9C9"
      stroke="#222"
      strokeWidth={0.29}
      d="M49.006 55.699c.422.794.612 1.607.584 2.276-.029.673-.274 1.17-.687 1.39-.413.22-.962.145-1.536-.208-.57-.35-1.139-.963-1.56-1.757-.423-.795-.613-1.608-.585-2.277.029-.673.274-1.17.687-1.39.413-.22.963-.145 1.536.208.57.351 1.139.963 1.561 1.758Z"
    />
    <g opacity={0.6}>
      <path
        fill="#DCEEC6"
        fillOpacity={0.71}
        d="m24.578 53.11-14.282-8.19c.458-5.295 2.194-15.127 6.028-19.304 7.647 9.872 10.894 13.95 11.706 14.899-1.697 2.164-3.254 6.092-3.452 12.596Z"
      />
      <path
        fill="#FFCEBF"
        d="m80.355 43.775-8.136 8.84c2.47 3.307 6.905 9.89 8.637 15.372 6.179-.366 10.483-1.007 12.021-1.191-2.335-6.12-7.496-17.605-12.522-23.021Z"
      />
      <path
        fill="#FFC2C2"
        d="M94.025 69.302 81.267 70.56c1.221 3.73 3.942 11.799 3.212 16.32 4.896 2.807 11.66 6.632 14.43 8.194-.16-6.146-1.273-19.68-4.884-25.772Z"
      />
      <path
        fill="#FFD7B2"
        fillOpacity={0.8}
        d="m57.974 22.97-.954 15.294c3.297 2.085 10.5 7.435 14.016 12.75 4.56-5.624 7.029-8.467 7.693-9.185-3.759-4.802-12.565-15.123-20.755-18.859Z"
      />
      <path
        fill="#FEDF90"
        fillOpacity={0.43}
        d="m34.07 15.914 6.734 17.517c3.018-.093 9.639.46 13.936 3.47.34-7.302.456-13.375.47-15.498-4.032-2.292-13.514-6.588-21.14-5.49Z"
      />
      <path
        fill="#E8EEC6"
        fillOpacity={0.66}
        d="M17.79 24.082c2.805-2.823 8.301-7.295 13.941-7.597C33.276 20.3 36.65 29.746 38.3 33.998c-2.128.074-5.759.958-8.968 5.07-2.628-3.292-8.636-11.389-11.541-14.986Z"
      />
    </g>
    <g stroke="#222" strokeWidth={0.29} opacity={0.8}>
      <path d="m10.296 44.92 14.282 8.19c.197-6.503 1.755-10.43 3.451-12.595-.811-.948-4.058-5.027-11.706-14.899-3.834 4.178-5.57 14.01-6.027 19.305ZM31.73 16.485c-5.64.302-11.136 4.774-13.94 7.597 2.906 3.597 8.913 11.694 11.541 14.987 3.21-4.113 6.84-4.997 8.968-5.071-1.65-4.252-5.023-13.699-6.568-17.513ZM40.804 33.431 34.07 15.914c7.625-1.099 17.107 3.197 21.14 5.49-.015 2.122-.13 8.195-.47 15.497-4.298-3.01-10.919-3.563-13.936-3.47ZM57.02 38.264l.953-15.294c8.19 3.736 16.996 14.057 20.755 18.859-.664.718-3.133 3.561-7.692 9.185-3.516-5.315-10.72-10.665-14.017-12.75ZM72.218 52.615l8.137-8.84c5.025 5.416 10.186 16.902 12.521 23.02-1.537.185-5.842.826-12.021 1.192-1.732-5.482-6.167-12.065-8.637-15.372ZM81.266 70.56l12.758-1.258c3.611 6.093 4.725 19.626 4.884 25.772-2.77-1.562-9.533-5.387-14.43-8.195.73-4.52-1.99-12.589-3.212-16.32Z" />
    </g>
  </svg>
);
