import React, { ReactNode, useEffect, useState } from 'react';
import { BigidColorsV2, BigidTabs, BigidIconType, BigidTooltip } from '@bigid-ui/components';
import {
  BigidAddressIcon,
  BigidDataSourceAddIcon,
  BigidDeleteIcon,
  BigidEditIcon,
  BigidImportIcon,
  BigidLocationIcon,
  BigidMessageIcon,
  BigidPhoneNumberIcon,
  BigidUserIcon,
} from '@bigid-ui/icons';
import { $state } from '../../services/angularServices';
import { CONFIG } from '../../../config/common';
import {
  VendorAction,
  VendorProject,
  VendorProjectAuditLog,
  VendorSummaryTabNames,
  VendorSummaryTabPaths,
} from './VendorsTypes';
import { FormLabel } from '@mui/material';
import { useVendorProjectContext } from './VendorProjectContext';
import {
  DetailsWrapper,
  FlexWrapper,
  IconWrapper,
  VendorDetailsSectionWrapper,
  VendorDetailsSectionDescriptionWrapper,
  VendorDetailsSectionWrapperFullWidth,
  VendorDetailsWrapper,
  VendorSummaryAsideLogItemWrapper,
  VendorSummaryAsideWrapper,
  VendorSummaryMainWrapper,
  ContentWrapper,
  TabsWrapper,
  Wrapper,
  VendorSummaryAsideItemWrapper,
  NotesBold,
  NotesBoldGray,
  LabelWithEllipsis,
  NotesBoldEllipsis,
  FlexWrapperHidden,
  ContactDetailsEmptyStateWrapper,
} from './VendorsSummaryStyles';
import { format } from 'date-fns';
import { DynamicChipsArea } from './DynamicChipsArea';
import { VendorSummaryActivityLog } from './VendorSummaryActivityLog';
import { VendorAttachedFilesGrid } from './VendorAttachedFilesGrid';
import { VendorsEditDialog, useVendorsEditDialog } from './VendorsEditDialog';
import { VendorEvents, vendorsEventEmitter } from './utils/VendorEvents';
import { VendorSummaryAssessments } from './VendorSummaryAssessments';

const SectionLabel = ({ text }: { text: ReactNode }) => {
  return (
    <FormLabel sx={{ marginBottom: '12px', paddingLeft: '8px' }}>
      <strong>{text}</strong>
    </FormLabel>
  );
};

const SubSectionLabel = ({ text }: { text: ReactNode }) => {
  return <FormLabel sx={{ color: BigidColorsV2.gray[500], marginBottom: 0 }}>{text}</FormLabel>;
};

const VendorDetails = ({ items, withChips, label }: { items: string[]; label: string; withChips?: boolean }) => {
  return (
    <VendorDetailsSectionWrapper>
      <SubSectionLabel text={label} />
      <DynamicChipsArea values={items?.map(value => ({ label: value }))} withChips={withChips} />
    </VendorDetailsSectionWrapper>
  );
};

export const VendorSummaryMain = () => {
  const { vendorProject = {} as VendorProject } = useVendorProjectContext();
  return (
    <VendorSummaryMainWrapper>
      <SectionLabel text="Vendor Details" />
      <VendorDetailsWrapper>
        <VendorDetails label="Assets" items={vendorProject.assets?.map(asset => asset.name)} withChips />
        <VendorDetails label="Vendor Location" items={vendorProject.locations} />
        <VendorDetails
          label="Business Owner"
          items={vendorProject.businessOwner ? [vendorProject.businessOwner] : undefined}
        />
        <VendorDetails label="Purpose of processing" items={vendorProject.purposeOfDataCollecting?.map(p => p.name)} />
        <VendorDetails label="Data Activities" items={vendorProject.dataActivities?.map(d => d.name)} />
        <VendorDetails label="Data exchange type" items={vendorProject.dataExchangeTypes} />
        {vendorProject?.description && (
          <VendorDetailsSectionWrapperFullWidth>
            <SubSectionLabel text="Description" />
            <BigidTooltip title={vendorProject?.description} placement="top">
              <VendorDetailsSectionDescriptionWrapper>
                {vendorProject?.description}
              </VendorDetailsSectionDescriptionWrapper>
            </BigidTooltip>
          </VendorDetailsSectionWrapperFullWidth>
        )}
      </VendorDetailsWrapper>
      <VendorSummaryAssessments />
    </VendorSummaryMainWrapper>
  );
};

export const VendorSummaryAsideDetailsItem = ({
  title,
  subTitle,
  icon: Icon,
  oneLine,
}: {
  title: ReactNode;
  subTitle?: string;
  icon: BigidIconType;
  oneLine?: boolean;
}) => {
  if (!title) {
    return null;
  }

  const TitleWrapper = oneLine ? NotesBoldEllipsis : NotesBold;

  return (
    <VendorSummaryAsideItemWrapper>
      <IconWrapper>
        <Icon size="small" />
      </IconWrapper>
      <FlexWrapperHidden>
        <BigidTooltip title={title}>
          <TitleWrapper>{title}</TitleWrapper>
        </BigidTooltip>
        {subTitle && (
          <BigidTooltip title={subTitle}>
            <NotesBoldGray>{subTitle}</NotesBoldGray>
          </BigidTooltip>
        )}
      </FlexWrapperHidden>
    </VendorSummaryAsideItemWrapper>
  );
};

const getIconByType = (type: VendorAction) => {
  switch (type) {
    case VendorAction.CREATE:
      return BigidDataSourceAddIcon;
    case VendorAction.UPDATE:
      return BigidEditIcon;
    case VendorAction.DELETE:
      return BigidDeleteIcon;
    case VendorAction.IMPORT:
      return BigidImportIcon;
  }
};

const toDate = (date: Date) => format(new Date(date), 'MMM dd, yyyy hh:mm');

export const VendorSummaryAsideLogItem = ({ log }: { log: VendorProjectAuditLog }) => {
  const { description, action, timestamp, user } = log;
  const Icon = getIconByType(action);
  return (
    <VendorSummaryAsideLogItemWrapper>
      {/* <IconWrapper>
        <Icon size="small" />
      </IconWrapper> */}
      <FlexWrapperHidden>
        <BigidTooltip title={description}>
          <FormLabel>{description}</FormLabel>
        </BigidTooltip>
        <BigidTooltip title={`${toDate(timestamp)} by ${user}`}>
          <LabelWithEllipsis size="small">{`${toDate(timestamp)} by ${user}`}</LabelWithEllipsis>
        </BigidTooltip>
      </FlexWrapperHidden>
    </VendorSummaryAsideLogItemWrapper>
  );
};

const ContactDetailsEmptyState = () => {
  return (
    <ContactDetailsEmptyStateWrapper>
      {'No Contact details has been added yet. Edit the vendor details.'}
    </ContactDetailsEmptyStateWrapper>
  );
};

export const VendorSummaryAside = () => {
  const { vendorProject: { contactPerson = {} } = {}, vendorLogs } = useVendorProjectContext();
  const showEmptyState = !Object.keys(contactPerson).length;
  return (
    <VendorSummaryAsideWrapper>
      <FlexWrapper>
        <SectionLabel text="Contact Details" />
        {showEmptyState && <ContactDetailsEmptyState />}
        <DetailsWrapper>
          <VendorSummaryAsideDetailsItem
            title={contactPerson?.name}
            subTitle={contactPerson?.role}
            icon={BigidUserIcon}
          />
          <VendorSummaryAsideDetailsItem title={contactPerson?.email} icon={BigidMessageIcon} oneLine />
          <VendorSummaryAsideDetailsItem title={contactPerson?.groupEmail} icon={BigidMessageIcon} oneLine />
          <VendorSummaryAsideDetailsItem title={contactPerson?.phone} icon={BigidPhoneNumberIcon} oneLine />
          <VendorSummaryAsideDetailsItem title={contactPerson?.companyAddress} icon={BigidLocationIcon} />
          <VendorSummaryAsideDetailsItem title={contactPerson?.companyWebsite} icon={BigidAddressIcon} oneLine />
        </DetailsWrapper>
      </FlexWrapper>
      <FlexWrapper>
        <SectionLabel text="Latest Actions" />
        <DetailsWrapper>
          {vendorLogs?.map(log => (
            <VendorSummaryAsideLogItem key={log.timestamp as unknown as string} log={log} />
          ))}
        </DetailsWrapper>
      </FlexWrapper>
    </VendorSummaryAsideWrapper>
  );
};

export const VendorSummaryContent = () => {
  const { vendorProject, update } = useVendorProjectContext();
  const { dialogProps, openDialog } = useVendorsEditDialog();

  useEffect(() => {
    const eventHandler = async () => {
      await openDialog(vendorProject);
      update();
    };
    vendorsEventEmitter.addEventListener(VendorEvents.OPEN_EDIT_VENDOR_DIALOG, eventHandler);

    return () => {
      vendorsEventEmitter.removeEventListener(VendorEvents.OPEN_EDIT_VENDOR_DIALOG, eventHandler);
    };
  }, [openDialog, update, vendorProject]);

  return (
    <>
      <VendorSummaryMain />
      <VendorSummaryAside />
      <VendorsEditDialog {...dialogProps} />
    </>
  );
};

const componentTabMapper: Record<
  VendorSummaryTabPaths,
  typeof VendorSummaryContent | typeof VendorAttachedFilesGrid | typeof VendorSummaryActivityLog
> = {
  [VendorSummaryTabPaths.SUMMARY]: VendorSummaryContent,
  [VendorSummaryTabPaths.FILES]: VendorAttachedFilesGrid,
  [VendorSummaryTabPaths.LOGS]: VendorSummaryActivityLog,
};

const TAB_PATHS = Object.values(VendorSummaryTabPaths);
const TAB_NAMES = Object.values(VendorSummaryTabNames);

const getComponentBasedOnTab = (tab: VendorSummaryTabPaths) =>
  componentTabMapper[tab] || componentTabMapper[VendorSummaryTabPaths.SUMMARY];

const getCurrentTabIndex = (currentTab: VendorSummaryTabPaths) => TAB_PATHS.indexOf(currentTab) || 0;

export const VendorSummaryContentWithTabs = () => {
  const { tab: currentTab } = useVendorProjectContext();
  const [selectedTabIndex, setSelectedTabIndex] = useState<number>(getCurrentTabIndex(currentTab));

  useEffect(() => {
    setSelectedTabIndex(getCurrentTabIndex(currentTab));
  }, [currentTab]);

  const onTabChange = (tabIndex: number) => {
    $state.go(
      CONFIG.states.VENDOR_SUMMARY,
      { ...$state.params, tab: TAB_PATHS[tabIndex] },
      { reload: false, notify: false, inherit: true },
    );
    setSelectedTabIndex(tabIndex);
  };

  const Component = getComponentBasedOnTab(currentTab);

  return (
    <Wrapper>
      <TabsWrapper>
        <BigidTabs
          onChange={onTabChange}
          orientation="horizontal"
          selectedIndex={selectedTabIndex}
          tabs={TAB_NAMES.map(tabName => ({ label: tabName }))}
          variant="standard"
        />
      </TabsWrapper>
      <ContentWrapper>
        <Component />
      </ContentWrapper>
    </Wrapper>
  );
};
