import React, { FC } from 'react';
import { Stack } from '@mui/material';
import { BigidCaption, BigidChartLegend, BigidTooltip } from '@bigid-ui/components';

import { formatNumberCompact } from '../../../utilities/numericDataConverter';

import { useLocalTranslation } from '../translations';
import { State, States } from './types';
import { colors } from './constants';
import { Progress } from './Progress';

export const ProgressCell: FC<{ states?: States }> = ({ states = {} }) => {
  const { t } = useLocalTranslation('DataSource');
  const keys = Object.keys(State) as Array<keyof States>;
  const filteredKeys = keys.filter(item => item !== 'total' && states[item]);
  const legendItems = filteredKeys.map(keyName => ({
    isActive: true,
    label: t(keyName),
    markerColor: colors[keyName],
    value: formatNumberCompact(states[keyName] ?? 0),
  }));

  return (
    <BigidTooltip
      fontSize="medium"
      placement="top"
      title={
        <Stack>
          <Stack direction="row" justifyContent="space-between" alignItems="center" px={0.5} mb={1}>
            <BigidCaption>
              <strong>{t('total')}</strong>
            </BigidCaption>
            <BigidCaption>
              <strong>{formatNumberCompact(states.total)}</strong>
            </BigidCaption>
          </Stack>
          <BigidChartLegend width={200} orientation="column" items={legendItems} />
        </Stack>
      }
    >
      <div>
        <Progress states={states} />
      </div>
    </BigidTooltip>
  );
};
