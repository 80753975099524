import React, { FC, ReactNode, useContext } from 'react';
import styled from '@emotion/styled';
import {
  AccordionSummarySizeEnum,
  BigidAccordion,
  BigidAccordionDetails,
  BigidAccordionSummary,
  BigidHeading6,
  BigidBody1,
} from '@bigid-ui/components';
import { AutoDiscoverWizardAsideExpandedStates, AutoDiscoveryWizardContext } from '../autoDiscoveryWizardContext';
import { AutoDiscoverWizardAsideItemTitle } from './autoDiscoverInfoStyledComponents';
import { generateDataAid } from '@bigid-ui/utils';
import { AutoDiscoverWizardAccordionItemStyled } from './AccordionInfoPanel/styles';

export interface AutoDiscoverWizardAccordionItem {
  title: string;
  subTitle?: string;
  children?: ReactNode;
  name?: AutoDiscoverWizardAsideExpandedStates;
  isExpanded?: boolean;
  onChange?: () => void;
  showExpandIcon?: boolean;
  startIcon?: JSX.Element;
}

const accordionDetailsStyle = {
  padding: '4px 24px 20px 32px !important',
};

export const AutoDiscoverWizardAccordionItemSubtitle = styled(BigidBody1)`
  padding-left: 4px;
`;

export const AutoDiscoverWizardAccordionItem: FC<AutoDiscoverWizardAccordionItem> = ({
  title,
  subTitle,
  children,
  name,
  isExpanded,
  onChange,
  showExpandIcon = true,
  startIcon: StartIcon,
}) => {
  const { setDiscoveryConfigData, discoveryConfigData } = useContext(AutoDiscoveryWizardContext);
  const expanded = discoveryConfigData.asideExpandedState === name;
  const controlledProps = name
    ? {
        expanded: expanded,
        onChange: () =>
          setDiscoveryConfigData(current => ({
            ...current,
            asideExpandedState: expanded ? AutoDiscoverWizardAsideExpandedStates.OPENED : name,
          })),
      }
    : {
        ...(isExpanded !== undefined ? { expanded: isExpanded, onChange } : {}),
      };

  return (
    <AutoDiscoverWizardAccordionItemStyled showExpandIcon={showExpandIcon}>
      <BigidAccordion
        className={'AutoDiscoverWizardAccordionItem'}
        {...controlledProps}
        sx={{ borderRadius: '8px !important' }}
        dataAid={generateDataAid('AutoDiscoverWizardAccordionItem', [title, subTitle])}
      >
        <BigidAccordionSummary
          sx={{
            width: '100%',
          }}
          size={AccordionSummarySizeEnum.large}
        >
          <AutoDiscoverWizardAsideItemTitle>
            {StartIcon && StartIcon}
            <BigidHeading6 sx={{ cursor: 'pointer' }}>{title}</BigidHeading6>
            <AutoDiscoverWizardAccordionItemSubtitle>{subTitle ?? ''}</AutoDiscoverWizardAccordionItemSubtitle>
          </AutoDiscoverWizardAsideItemTitle>
        </BigidAccordionSummary>
        <BigidAccordionDetails sx={accordionDetailsStyle}>{children}</BigidAccordionDetails>
      </BigidAccordion>
    </AutoDiscoverWizardAccordionItemStyled>
  );
};
