import React, { FC, useEffect } from 'react';
import { getApplicationPreference } from '../../services/appPreferencesService';
import { $state } from '../../services/angularServices';
import { ReportingEtlMonitoring } from './EtlGridComponents/ReportingEtlMonitoring';
import { DisWelcomePage } from './DisWelcomePage/DisWelcomePage';
import { analyticsService } from '../../services/analyticsService';
import { ReportingEtlTrackingEvents } from './consts/ReportingEtlEventsConsts';
import { pageHeaderService } from '../../../common/services/pageHeaderService';
import { getEtlTitle } from './reportingEtlMonitoringUtils';

export const EtlMain: FC = () => {
  useEffect(() => {
    if (getApplicationPreference('ENABLE_NEW_ETL_LANDING_PAGE')) {
      $state.params.isDataInsightsStudio
        ? pageHeaderService.setIsHidden(true)
        : pageHeaderService.setTitle({ ...getEtlTitle() });
    }
    analyticsService.trackPageView(
      $state.params.isDataInsightsStudio
        ? ReportingEtlTrackingEvents.REPORTING_ETL_DATA_INSIGHTS_STUDIO_PAGE_VIEW
        : ReportingEtlTrackingEvents.REPORTING_ETL_DATA_PIPELINE_PAGE_VIEW,
    );
    return () => pageHeaderService.setIsHidden(false);
  }, []);

  return (
    <>
      {getApplicationPreference('ENABLE_NEW_ETL_LANDING_PAGE') && $state.params.isDataInsightsStudio ? (
        <DisWelcomePage />
      ) : (
        <ReportingEtlMonitoring />
      )}
    </>
  );
};
