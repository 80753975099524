import React, { FC } from 'react';
import { styled } from '@mui/material';
import { BigidUserFilledIcon } from '@bigid-ui/icons';
import { BigidColorsV2 } from '@bigid-ui/components';

const Root = styled('div')`
  width: 32px;
  height: 32px;
  box-sizing: border-box;
  border: 1px solid ${BigidColorsV2.purple['200']};
  background-color: ${BigidColorsV2.purple['100']};
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
`;

export const AssigneeIndicator: FC = () => {
  return (
    <Root>
      <BigidUserFilledIcon />
    </Root>
  );
};
