import React, { FC } from 'react';
import { CatalogRule } from './catalogRulesService';
import { BigidApplyIcon } from '@bigid-ui/icons';
import { BigidTooltip } from '@bigid-ui/components';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
    overflow: 'hidden',
  },
  name: {
    flex: 1,
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  },
  icon: {
    flex: '0 0 30px',
    height: '100%',
    padding: '1px',
  },
});

type CatalogRulesLayoutCellProps = { dataAid?: string } & Pick<CatalogRule, 'name' | 'isEnabled'>;

export const CatalogRulesLayoutCell: FC<CatalogRulesLayoutCellProps> = ({
  dataAid = 'CatalogRulesLayoutCell',
  name,
  isEnabled,
}) => {
  const classes = useStyles();

  return (
    <div className={classes.root} data-aid={`${dataAid}-${name}`}>
      <BigidTooltip title={name}>
        <div className={classes.name} data-aid={`${dataAid}-${name}-name`}>
          {name}
        </div>
      </BigidTooltip>
      {isEnabled && (
        <BigidApplyIcon color="positive" className={classes.icon} dataAid={`${dataAid}-${name}-BigidApplyIcon`} />
      )}
    </div>
  );
};
