import React, { FC, useEffect } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { PrimaryButton } from '@bigid-ui/components';
import { BigidReportsIllustration, BigidScanIllustration, BigidTasksIllustration } from '@bigid-ui/icons';
import { generateDataAid } from '@bigid-ui/utils';
import { WizardFlowActions } from '../hooks/useWizardFlow';
import { FmsdPageViewsEvents, FmsdTrackingEvents, trackFmsdPageView } from '../fmsdEventsTrackerUtils';
import { DataDiscoveryItems, DataDiscoveryItem } from '../FmsdComponents/DataDiscoveryItems/DataDiscoveryItems';
import { useLocalTranslation, getFixedT } from '../translations';

const useStyles = makeStyles(theme => ({
  wrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    height: '100%',
    flexDirection: 'column',
    position: 'fixed',
    overflowY: 'scroll',
    left: 0,
    marginTop: -7,
    backgroundColor: theme.vars.palette.bigid.white,
    textAlign: 'center',
    padding: '0 24px 0 88px',
  },
  mainBox: {
    width: 874,
    maxWidth: '100%',
  },
  header: {
    color: theme.vars.palette.bigid.primary400,
    fontSize: 27,
    fontWeight: 700,
    lineHeight: '32px',
    marginBottom: 8,
  },
  body: {
    color: theme.vars.palette.bigid.gray700,
    fontSize: 15,
    lineHeight: '22px',
    marginBottom: 85,
  },
}));

const fixedT = getFixedT('Welcome');

const dataDiscoveryConfig: DataDiscoveryItem[] = [
  {
    Icon: BigidTasksIllustration,
    text: fixedT('dataDiscovery.choose'),
  },
  {
    Icon: BigidScanIllustration,
    text: fixedT('dataDiscovery.connect'),
  },
  {
    Icon: BigidReportsIllustration,
    text: fixedT('dataDiscovery.view'),
  },
];

export const FmsdWelcome: FC<WizardFlowActions> = ({ goToNext }) => {
  const { t } = useLocalTranslation('Welcome');
  const { wrapper, mainBox, header, body } = useStyles();

  const onNextClick = () => {
    goToNext();
  };

  useEffect(() => {
    trackFmsdPageView(FmsdPageViewsEvents.FMSD_WELCOME_PAGE_VIEW);
  }, []);

  return (
    <div className={wrapper}>
      <div className={mainBox} data-aid={generateDataAid('FmsdWelcomeView', ['main', 'container'])}>
        <div className={header}>{t('stepTitle')}</div>
        <div className={body}>{t('body')}</div>
        <DataDiscoveryItems items={dataDiscoveryConfig} />
        <PrimaryButton
          size="medium"
          width="fill"
          onClick={onNextClick}
          dataAid={generateDataAid('FmsdWelcomeView', ['start', 'button'])}
          bi={{ eventType: FmsdTrackingEvents.FMSD_WELCOME_START_CLICK }}
          text={t('startButton')}
        />
      </div>
    </div>
  );
};
