import React, { FC, ReactNode, useEffect, useMemo, useState, Fragment, useCallback } from 'react';
import {
  ActionData,
  BigidChip,
  BigidColors,
  BigidIcon,
  BigidIconSize,
  BigidSideFilterSectionBase,
  objectToQueryString,
  QueryParams,
  EntityEvents,
  entityEventsEmitter,
  ToolbarAction,
  ToolbarActionType,
} from '@bigid-ui/components';
import {
  BigidGridColumnTypes,
  BigidGridProps,
  BigidGridColumn,
  ChipFormatterProps,
  ChipsFormatterProps,
  FetchDataFunction,
  TagsFormatterProps,
  BigidGridDataFetchResult,
  FetchTotalCount,
} from '@bigid-ui/grid';
import {
  BigidMasterDetailsContentProps,
  BigidLayout,
  BigidLayoutConfig,
  LayoutSideFilterProps,
  LayoutContentType,
  LayoutSideFilterSectionAddedEventData,
  LayoutSideFilterSectionRemovedEventData,
  parseSearchQueryToFieldFilters,
  BigidLayoutEmptyState,
} from '@bigid-ui/layout';
import {
  DataCatalogRecord,
  getDataCatalogRecords,
  getDataCatalogRecordsCountCancellable,
  getDataCatalogRecordsAsCSV,
  DataCatalogPagePreferences,
  DataCatalogRecordScannerTypeGroup,
  DataCatalogObjectType,
  addFollowedObjects,
  deleteFollowedObjects,
  DetailedObjectType,
  getConfidenceLevel,
  postIgnoreConfidenceLevel,
  deleteIgnoreConfidenceLevel,
  AppIntegrationSettings,
  getIntegrationSettings,
  AppIntegrationSettingsTabConfig,
  generateSharingCatalogUrl,
} from './DataCatalogService';
import { AppIntegrationTab, validateAppTabSettings } from './DataCatalogAppIntegrations/AppIntegrationTab';
import { DataCatalogColumns } from './DataCatalogColumns';
import { DataCatalogLineage } from './DataCatalogLineage';
import { DataCatalogActivityMonitoring } from './DataCatalogActivityMonitoring/DataCatalogActivityMonitoring';
import { DataCatalogAttributes } from './DataCatalogAttributes';
import { DataCatalogDetailsForGrid } from './DataCatalogDetails';
import { DataCatalogTablePreview } from './DataCatalogTablePreview';
import { DataCatalogInsights } from './DataCatalogInsights';
import {
  DataCatalogBulkTagAssignmentDialog,
  DataCatalogBulkTagAssignmentDialogProps,
} from './DataCatalogBulkTagAssignmentDialog';
import { pageHeaderService } from '../../../common/services/pageHeaderService';
import ErrorIcon from '@mui/icons-material/Error';
import { PreviewFileContent } from './PreviewFileObjects/PreviewFileContent';
import { CONFIG } from '../../../config/common';
import { DataCatalogFileDuplicates } from './DataCatalogFileDuplicates/DataCatalogFileDuplicates';
import { DataCatalogAuditTrail } from './DataCatalogAuditTrail/DataCatalogAuditTrail';
import { $stateParams, $state } from '../../services/angularServices';
import { userPreferencesService, PageFilterPreferences, UserPreference } from '../../services/userPreferencesService';
import makeStyles from '@mui/styles/makeStyles';
import { notificationService } from '../../services/notificationService';
import {
  CATALOG_PERMISSIONS,
  CLUSTER_ANALYSIS_PERMISSIONS,
  LINEAGE_PERMISSIONS,
  TAGS_PERMISSIONS,
} from '@bigid/permissions';
import { isPermitted, getDataSourcesOwnerShip } from '../../services/userPermissionsService';
import { TagAssignmentTarget } from '../TagsManagement/TagsManagementService';
import { getTagFormattedName, getTagIcon } from '../TagsManagement/TagsManagementUtils';
import {
  BigidUserReportIcon,
  BigidViewIcon,
  BigidDataSourceOnlyIcon,
  BigidDataSourceViewIcon,
  BigidFileIcon,
  BigidIgnoreConfidenceLevel,
  BigidRestoreConfidenceLevel,
  BigidHideIcon,
  BigidNoDataIllustration,
  DataSourceIconByDsType,
  BigidModelIcon,
  BigidDatasetIcon,
  BigidVectorDbIcon,
} from '@bigid-ui/icons';
import { DataCatalogObjectIssueDialog, DataCatalogObjectIssueDialogProps } from './DataCatalogObjectIssueDialog';
import { getApplicationPreference } from '../../services/appPreferencesService';
import { httpService } from '../../services/httpService';
import {
  loadApplications,
  loadAttributes,
  loadCloudTypes,
  loadClusters,
  loadDataSources,
  loadExtendedFileTypes,
  loadFileTypes,
  loadPolicies,
  loadQuickFilters,
  loadSavedQueries,
  loadTags,
  loadScanStatus,
  SectionName,
  loadIntegrations,
  loadAccessType,
} from './DataCatalogFilter';
import {
  CONTAINS_PI,
  HAS_DUPLICATES,
  HAS_DUPLICATES_CHIP_TYPE,
  NO,
  NO_DUPLICATES,
  NO_OPEN_ACCESS,
  OPEN_ACCESS,
  OPEN_ACCESS_CHIP_TYPE,
  TOTAL_COUNT_THRESHOLD,
  YES,
} from './DataCatalogConsts';
import { CatalogEventsEnum } from './events';
import { showGenericConfirmationDialog } from '../../services/genericConfirmationDialog';
import { docsUrls } from '../../config/publicUrls';
import { analyticsService } from '../../services/analyticsService';
import { getIconByObjectType, getIsClassifyFileNamesEnabled, getObjectTypeName, getTitleServiceConfig } from './utils';
import { useLocalTranslation } from './translations';
import { getIsCatalogDiscoveryAvailable } from '../CatalogDiscovery/catalogDiscoveryService';
import { NewDataCatalog } from './NewDataCatalog/NewDataCatalog';
import { QueryClientProvider } from 'react-query';
import { getQueryClient } from '../../config/query';
import { DataCatalogContextProvider } from './NewDataCatalog/newDataCatalogContext';
import { DataCatalogFields } from './DataCatalogFields';
import { DataCatalogSimilarTables } from './DataCatalogSimilarTables/DataCatalogSimilarTables';

const queryClient = getQueryClient();

export interface DataCatalogExtendedRecord extends DataCatalogRecord {
  attributes?: ChipsFormatterProps;
  applications?: ChipsFormatterProps;
  manualTags?: TagsFormatterProps;
  icon?: ReactNode;
  generalTags?: ReactNode[];
}

export interface FileTypeProps {
  value: string;
}

const useStyles = makeStyles({
  contentContainer: {
    display: 'flex',
    overflow: 'hidden',
    flexFlow: 'row nowrap',
    flex: '1 1 auto',
  },
  noData: {
    width: '100%',
    height: '600px',
    position: 'relative',
  },
});

const updateFilterPreferences = async (newFilterPreferences: PageFilterPreferences) => {
  try {
    const preferences = await userPreferencesService.get<DataCatalogPagePreferences>(CONFIG.states.CATALOG);

    if (preferences) {
      const { sideFilterSelection, optionalSideFilterSections, searchQuery } = newFilterPreferences;
      await userPreferencesService.update({
        ...preferences,
        filter: {
          sideFilterSelection: sideFilterSelection || preferences?.filter?.sideFilterSelection,
          optionalSideFilterSections: optionalSideFilterSections || preferences?.filter?.optionalSideFilterSections,
          searchQuery: typeof searchQuery === 'string' ? searchQuery : preferences?.filter?.searchQuery,
        },
      });
    } else {
      const { sideFilterSelection = [], optionalSideFilterSections = [], searchQuery = '' } = newFilterPreferences;
      await userPreferencesService.add({
        preference: CONFIG.states.CATALOG,
        filter: { sideFilterSelection, optionalSideFilterSections, searchQuery },
      });
    }
  } catch ({ message }) {
    console.error(`An error has occurred: ${message}`);
    notificationService.error(`An error has occurred`);
  }
};

const hasIndicatorMapping = new Map([
  [
    OPEN_ACCESS_CHIP_TYPE,
    new Map<string, ChipFormatterProps>([
      [
        YES,
        {
          chip: {
            icon: <BigidIcon color={BigidColors.red[700]} icon={ErrorIcon} size={BigidIconSize.MEDIUM} />,
            bgColor: BigidColors.red[100],
            size: 'small',
            label: OPEN_ACCESS,
          },
        },
      ],
      [
        NO,
        {
          chip: {
            bgColor: BigidColors.gray[50],
            size: 'small',
            label: NO_OPEN_ACCESS,
          },
        },
      ],
    ]),
  ],
  [
    HAS_DUPLICATES_CHIP_TYPE,
    new Map<string, ChipFormatterProps>([
      [
        YES,
        {
          chip: {
            bgColor: BigidColors.red[100],
            size: 'small',
            label: HAS_DUPLICATES,
          },
        },
      ],
      [
        NO,
        {
          chip: {
            bgColor: BigidColors.gray[50],
            size: 'small',
            label: NO_DUPLICATES,
          },
        },
      ],
    ]),
  ],
]);

const detailsIconMapping: Record<DataCatalogObjectType, ReactNode> = {
  [DataCatalogObjectType.APP_LEAF]: <BigidDataSourceOnlyIcon staticMode={true} />,
  [DataCatalogObjectType.STRUCTURED_LEAF]: <BigidDataSourceOnlyIcon staticMode={true} />,
  [DataCatalogObjectType.STRUCTURED_FILE_LEAF]: <BigidFileIcon staticMode={true} />,
  [DataCatalogObjectType.UNSTRUCTURED_LEAF]: <BigidFileIcon staticMode={true} />,
  [DataCatalogObjectType.PARTITIONED_TABLE_LEAF]: <BigidFileIcon staticMode={true} />,
  [DataCatalogObjectType.TABLE]: <BigidDataSourceOnlyIcon staticMode={true} />,
  [DataCatalogObjectType.TABLE_VIEW]: <BigidDataSourceViewIcon staticMode={true} />,
  [DataCatalogObjectType.FILE]: <BigidFileIcon staticMode={true} />,
  [DataCatalogObjectType.MODEL]: <BigidModelIcon staticMode={true} />,
  [DataCatalogObjectType.DATASET]: <BigidDatasetIcon staticMode={true} />,
  [DataCatalogObjectType.VECTOR]: <BigidVectorDbIcon staticMode={true} />,
};

const getGridData = (results: DataCatalogRecord[], permittedDs: Record<string, boolean>, isRootScope?: boolean) => {
  return results.map(record => {
    const {
      open_access = NO,
      total_pii_count = 0,
      duplicate_id = null,
      attribute = [],
      application_name = [],
      tags = [],
    } = record;
    const generalTags = [];
    const icon = detailsIconMapping[record.extendedObjectType];

    if (open_access.toLowerCase() === YES && Boolean(getApplicationPreference('DISABLE_LEGACY_ACL_FF') !== true)) {
      const { chip } = hasIndicatorMapping.get(OPEN_ACCESS_CHIP_TYPE).get(YES);
      generalTags.push(<BigidChip icon={chip.icon} bgColor={chip.bgColor} size={chip.size} label={chip.label} />);
    }

    if (total_pii_count > 0) {
      generalTags.push(<BigidChip bgColor={BigidColors.red[100]} size="small" label={CONTAINS_PI} />);
    }

    if (duplicate_id) {
      generalTags.push(<BigidChip bgColor={BigidColors.red[100]} size="small" label={HAS_DUPLICATES} />);
    }

    const attributes: ChipsFormatterProps = {
      chips: {
        value: attribute.map(attr => ({
          label: attr,
        })),
        isDisabled: true,
        isAutoFit: false,
      },
    };

    const applications: ChipsFormatterProps = {
      chips: {
        value: application_name.map(app => ({
          label: app,
        })),
        isDisabled: true,
        isAutoFit: false,
      },
    };

    const manualTags: TagsFormatterProps = {
      tags: {
        value: tags
          .reduce((tagsFiltered, tag) => {
            if (tag.tagType === TagAssignmentTarget.object) {
              return [tag, ...tagsFiltered];
            } else {
              return tagsFiltered.find(t => t.tagName === tag.tagName && t.tagValue === tag.tagValue)
                ? tagsFiltered
                : [...tagsFiltered, tag];
            }
          }, [])
          .filter(({ properties }) => !properties?.hidden)
          .map(({ tagName, tagValue, tagType, properties }) => ({
            name: getTagFormattedName(tagName),
            value: tagValue,
            iconDescription: tagType === TagAssignmentTarget.column ? tagType : undefined,
            icon: getTagIcon(properties, tagType),
          })),
        isDisabled: true,
        isAutoFit: false,
        entityMaxWidth: 150,
        tagBgColor: BigidColors.purple[50],
      },
    };

    const isPermittedToPreview =
      permittedDs?.[record.source] || (isRootScope && isPermitted(CATALOG_PERMISSIONS.PREVIEW_FILE_INVESTIGATION.name));

    return {
      ...record,
      generalTags,
      icon,
      attributes,
      applications,
      manualTags,
      isPermittedToPreview,
    };
  });
};

export const previewTableDataSourcesBlacklist: string[] = ['cassandra', 'ldap', 'external-catalog'];
export const previewFileDataSourcesBlacklist: string[] = ['external-catalog'];

export const DataCatalog: FC = () => {
  const classes = useStyles({});
  const { t } = useLocalTranslation();
  const { filter, shouldResetFilter, shouldSelectFirstItem, filterSelections } = $stateParams;

  const [bulkTagAssignmentDialogState, setBulkTagAssignmentDialogState] =
    useState<DataCatalogBulkTagAssignmentDialogProps>({
      filter: '',
      isOpen: false,
    });

  const [objectIssueDialogState, setObjectIssueDialogState] = useState<DataCatalogObjectIssueDialogProps>({
    isOpen: false,
  });

  const {
    isTagsBulkAssignmentEnabled,
    isTagsReadPermitted,
    isClusteringEnabled,
    isNewQueryFilterEnabled,
    isDataCatalogFileTypeEnabled,
    isDataCatalogExtendedFileTypeEnabled,
    isColumnClusteringEnabled,
    isObjectIssuesEnabled,
    isFollowObjectEnabled,
    isLineageEnabled,
    isActivityMonitoringEnabled,
    disableLegacyACL,
    isClassifyFileNamesFFEnabled,
    isSimilarTablesEnabled,
  } = useMemo(
    () => ({
      isTagsBulkAssignmentEnabled: Boolean(getApplicationPreference('TAGS_BULK_ASSIGNMENT_ENABLED')),
      isTagsReadPermitted: isPermitted(TAGS_PERMISSIONS.READ.name),
      isClusteringEnabled:
        getApplicationPreference('CLUSTERING_ENABLED') && isPermitted(CLUSTER_ANALYSIS_PERMISSIONS.ACCESS.name),
      isNewQueryFilterEnabled: Boolean(getApplicationPreference('NEW_QUERY_FILTER_ENABLED')),
      isDataCatalogFileTypeEnabled: Boolean(getApplicationPreference('DATA_CATALOG_FILE_TYPE_ENABLED')),
      isDataCatalogExtendedFileTypeEnabled: Boolean(
        getApplicationPreference('DATA_CATALOG_EXTENDED_FILE_TYPE_ENABLED'),
      ),
      isColumnClusteringEnabled: getApplicationPreference('SHOW_STRUCTURED_CLUSTERING'),
      isObjectIssuesEnabled: getApplicationPreference('OBJECT_ISSUES_ENABLED'),
      isFollowObjectEnabled:
        getApplicationPreference('FOLLOW_OBJECT_ENABLED') && getApplicationPreference('CATALOG_OBJECT_MANAGE_FF'),
      isLineageEnabled:
        getApplicationPreference('ENABLE_LINEAGE') &&
        isPermitted(LINEAGE_PERMISSIONS.READ.name) &&
        isPermitted(LINEAGE_PERMISSIONS.ACCESS.name),
      isActivityMonitoringEnabled: getApplicationPreference('ENABLE_DATA_CATALOG_ACTIVITY_MONITORING'),
      disableLegacyACL: Boolean(getApplicationPreference('DISABLE_LEGACY_ACL_FF')),
      isClassifyFileNamesFFEnabled: getIsClassifyFileNamesEnabled(),
      isSimilarTablesEnabled: getApplicationPreference('SIMILAR_TABLES_ENABLED'),
    }),
    [],
  );

  const masterDetailsConfig: BigidMasterDetailsContentProps = {
    getInitialSelectedItem: shouldSelectFirstItem !== null ? () => true : undefined,
    actions: [
      {
        type: ToolbarActionType.ACTION_ICON,
        label: 'Ignore Confidence Level',
        icon: BigidIgnoreConfidenceLevel,
        tooltip: 'Showing all attributes',
        show: ({ selectedItem }) => {
          return (
            selectedItem.scanner_type_group === DataCatalogRecordScannerTypeGroup.STRUCTURED &&
            !selectedItem.isConfidenceLevelIgnored
          );
        },
        execute: async ({ selectedItem }) => {
          let shouldExecute = false;
          try {
            shouldExecute = await showGenericConfirmationDialog({
              title: 'Show all attributes',
              closeButton: 'Cancel',
              actionButton: 'Proceed',
              text: 'Show attributes of all confidence levels, including Low.',
            });

            if (shouldExecute) {
              analyticsService.trackManualEvent(CatalogEventsEnum.CATALOG_OBJECT_IGNORE_CONFIDENCE);
              try {
                await postIgnoreConfidenceLevel(selectedItem.fullyQualifiedName);
                entityEventsEmitter.emit(EntityEvents.UPDATE_BY_ID, selectedItem.id, {
                  isConfidenceLevelIgnored: true,
                });
                notificationService.success('Low confidence level attributes are shown.');
              } catch (e) {
                notificationService.error('An error has occurred');
                console.error(e.message);
              }
            }
          } catch (e) {
            notificationService.error('An error has occurred');
            console.error(e);
          } finally {
            return { shouldGridReload: shouldExecute, shouldClearSelection: shouldExecute };
          }
        },
      },
      {
        type: ToolbarActionType.ACTION_ICON,
        label: 'Restore Confidence Level',
        icon: BigidRestoreConfidenceLevel,
        tooltip: 'Revert from "Ignore" confidence',
        show: ({ selectedItem }) => {
          return (
            selectedItem.scanner_type_group === DataCatalogRecordScannerTypeGroup.STRUCTURED &&
            selectedItem.isConfidenceLevelIgnored
          );
        },
        execute: async ({ selectedItem }) => {
          let shouldExecute = false;
          try {
            shouldExecute = await showGenericConfirmationDialog({
              title: 'Revert from "Ignore" confidence',
              closeButton: 'Cancel',
              actionButton: 'Restore Confidence Level',
              text: 'This process removes the low confidence level attributes from the object. Do you want to continue?',
            });

            if (shouldExecute) {
              const { source, fullyQualifiedName, scannerType } = selectedItem;
              const trackData = {
                fullyQualifiedName,
                dsType: scannerType,
                dsName: source,
              };
              analyticsService.trackManualEvent(CatalogEventsEnum.CATALOG_OBJECT_REVERT_CONFIDENCE, trackData);
              try {
                const confidenceLevelData = await getConfidenceLevel(selectedItem.fullyQualifiedName);
                await deleteIgnoreConfidenceLevel(confidenceLevelData[0]?.id);
                entityEventsEmitter.emit(EntityEvents.UPDATE_BY_ID, selectedItem.id, {
                  isConfidenceLevelIgnored: false,
                });
                notificationService.success('Low confidence level attributes are not shown.');
              } catch (e) {
                notificationService.error('An error has occurred');
                console.error(e.message);
              }
            }
          } catch (e) {
            notificationService.error('An error has occurred');
            console.error(e);
          } finally {
            return { shouldGridReload: shouldExecute, shouldClearSelection: shouldExecute };
          }
        },
      },
      ...(isFollowObjectEnabled
        ? ([
            {
              type: ToolbarActionType.ACTION_ICON,
              label: 'Follow Object',
              icon: BigidViewIcon,
              tooltip: 'Follow object',
              show: ({ selectedItem }) => selectedItem.collaborationStatus?.isFollowing === false,
              execute: async ({ selectedItem }) => {
                try {
                  await addFollowedObjects([selectedItem.fullyQualifiedName]);
                  entityEventsEmitter.emit(EntityEvents.UPDATE_BY_ID, selectedItem.id, {
                    collaborationStatus: { ...selectedItem.collaborationStatus, isFollowing: true },
                  });
                } catch (err) {
                  const errMsg = `An error has occurred`;
                  notificationService.error(errMsg);
                  console.error(`${errMsg}: ${err}`);
                } finally {
                  const { fullyQualifiedName, scanner_type_group, name, containerName, type } = selectedItem;
                  const trackData = {
                    objectName: name,
                    fullyQualifiedName,
                    dsType: type,
                    dsName: containerName,
                    scannerType: scanner_type_group,
                    scanner_type_group,
                  };

                  analyticsService.trackManualEvent(CatalogEventsEnum.CATALOG_FOLLOW_OBJECT, trackData);
                  return {};
                }
              },
            },
            {
              type: ToolbarActionType.ACTION_ICON,
              label: 'Unfollow Object',
              icon: BigidHideIcon,
              tooltip: 'Unfollow object',
              show: ({ selectedItem }) => !!selectedItem.collaborationStatus?.isFollowing,
              execute: async ({ selectedItem }) => {
                try {
                  await deleteFollowedObjects([selectedItem.fullyQualifiedName]);
                  entityEventsEmitter.emit(EntityEvents.UPDATE_BY_ID, selectedItem.id, {
                    collaborationStatus: { ...selectedItem.collaborationStatus, isFollowing: false },
                  });
                } catch (err) {
                  const errMsg = `An error has occurred`;
                  notificationService.error(errMsg);
                  console.error(`${errMsg}: ${err}`);
                } finally {
                  return {};
                }
              },
            },
          ] as ToolbarAction[])
        : []),
      ...(isObjectIssuesEnabled
        ? ([
            {
              type: ToolbarActionType.ACTION_ICON,
              label: 'Report Issue',
              icon: BigidUserReportIcon,
              tooltip: 'Report an issue',
              show: ({ selectedItem }) => !!selectedItem.collaborationStatus?.canOpenIssue,
              execute: ({ selectedItem }) => {
                analyticsService.trackManualEvent(CatalogEventsEnum.CATALOG_REPORT_AN_ISSUE);

                return new Promise(resolve => {
                  setObjectIssueDialogState({
                    isOpen: true,
                    fullyQualifiedName: selectedItem.fullyQualifiedName,
                    onSubmit: () => {
                      closeObjectIssueDialog();
                      resolve({});
                    },
                    onClose: () => {
                      closeObjectIssueDialog();
                      resolve({});
                    },
                  });
                });
              },
            },
          ] as ToolbarAction[])
        : []),
    ],
    shouldReloadGridOnClose: true,
    loadAsyncTabsAndContent: async () => {
      const appIntegrationSettings = await getIntegrationSettings();
      const appIntegrationTabs = appIntegrationSettings
        ? appIntegrationSettings
            .filter(validateAppTabSettings)
            .map((setting: AppIntegrationSettings) => {
              return setting.objectDetails.tabs.map(tabSettings => {
                return {
                  tabSettings,
                  appId: setting.appId,
                  appName: setting.appFriendlyName,
                };
              });
            })
            .flat()
            .map(
              ({
                tabSettings,
                appId,
                appName,
              }: {
                tabSettings: AppIntegrationSettingsTabConfig;
                appId: string;
                appName: string;
              }) => ({
                label: tabSettings.title,
                data: {
                  component: AppIntegrationTab,
                  customProps: {
                    tabSettings,
                    appId,
                    appName,
                  },
                },
              }),
            )
        : [];
      return {
        classes: {
          contentContainer: classes.contentContainer,
        },
        tabProps: {
          tabs: [
            {
              label: 'Details',
              id: 'details',
              data: { component: DataCatalogDetailsForGrid, customProps: { filter } },
            },
            {
              label: 'Attributes',
              id: 'attributes',
              data: { component: DataCatalogAttributes },
            },
            {
              label: 'Columns',
              id: 'columns',
              data: { component: DataCatalogColumns },
              getIsAvailable: function ({ scanner_type_group, detailedObjectType }) {
                return (
                  detailedObjectType === DetailedObjectType.STRUCTURED ||
                  detailedObjectType === DetailedObjectType.STRUCTURED_FILE ||
                  detailedObjectType === DetailedObjectType.PARTITIONED_TABLE ||
                  (!detailedObjectType &&
                    scanner_type_group !== DataCatalogRecordScannerTypeGroup.UNSTRUCTURED &&
                    scanner_type_group !== DataCatalogRecordScannerTypeGroup.EMAIL)
                );
              },
            },
            {
              label: 'Similar tables',
              id: 'similar tables',
              data: { component: DataCatalogSimilarTables },
              getIsAvailable: ({ scanner_type_group, detailedObjectType }) => {
                return (
                  (detailedObjectType === DetailedObjectType.STRUCTURED ||
                    detailedObjectType === DetailedObjectType.PARTITIONED_TABLE ||
                    detailedObjectType === DetailedObjectType.STRUCTURED_FILE ||
                    (!detailedObjectType && scanner_type_group === DataCatalogRecordScannerTypeGroup.STRUCTURED)) &&
                  isSimilarTablesEnabled
                );
              },
            },
            {
              label: 'Fields',
              id: 'fields',
              data: { component: DataCatalogFields },
              getIsAvailable: function ({ scanner_type_group, detailedObjectType }) {
                return (
                  (detailedObjectType === DetailedObjectType.UNSTRUCTURED ||
                    (!detailedObjectType && scanner_type_group === DataCatalogRecordScannerTypeGroup.UNSTRUCTURED)) &&
                  isClassifyFileNamesFFEnabled
                );
              },
            },
            {
              label: 'Graph View',
              id: 'lineage',
              data: { component: DataCatalogLineage },
              getIsAvailable: () => isLineageEnabled,
            },
            {
              label: 'Preview',
              id: 'preview',
              data: { component: PreviewFileContent },
              getIsAvailable: ({
                objectType,
                scanner_type_group,
                scannerType,
                isPermittedToPreview,
                detailedObjectType,
              }) => {
                return (
                  (detailedObjectType === DetailedObjectType.UNSTRUCTURED ||
                    (!detailedObjectType && scanner_type_group === DataCatalogRecordScannerTypeGroup.UNSTRUCTURED)) &&
                  isPermittedToPreview &&
                  !previewFileDataSourcesBlacklist.includes(scannerType) &&
                  !getApplicationPreference('DATA_PREVIEW_DISABLED') &&
                  objectType !== DataCatalogObjectType.MODEL
                );
              },
            },
            {
              label: 'Preview',
              id: 'preview',
              data: { component: DataCatalogTablePreview },
              getIsAvailable: ({ scanner_type_group, scannerType, isPermittedToPreview, detailedObjectType }) => {
                return (
                  (detailedObjectType === DetailedObjectType.STRUCTURED ||
                    detailedObjectType === DetailedObjectType.PARTITIONED_TABLE ||
                    detailedObjectType === DetailedObjectType.STRUCTURED_FILE ||
                    (!detailedObjectType && scanner_type_group === DataCatalogRecordScannerTypeGroup.STRUCTURED)) &&
                  isPermittedToPreview &&
                  !previewTableDataSourcesBlacklist.includes(scannerType) &&
                  !getApplicationPreference('DATA_PREVIEW_DISABLED')
                );
              },
            },
            {
              label: 'Duplicates',
              id: 'duplicates',
              data: { component: DataCatalogFileDuplicates },
              getIsAvailable: ({ objectType, detailedObjectType }) =>
                (objectType === DataCatalogObjectType.FILE ||
                  detailedObjectType === DetailedObjectType.UNSTRUCTURED ||
                  detailedObjectType === DetailedObjectType.APP) &&
                objectType != DataCatalogObjectType.MODEL,
            },
            {
              label: 'Activity Monitoring',
              id: 'Activity Monitoring',
              data: { component: DataCatalogActivityMonitoring },
              getIsAvailable: () => isActivityMonitoringEnabled,
            },
            ...appIntegrationTabs,
            {
              label: 'Audit Trail',
              id: 'auditTrail',
              data: { component: DataCatalogAuditTrail },
              getIsAvailable: () => getApplicationPreference('CATALOG_AUDIT_TRAIL_UI_ENABLED'),
            },
          ],
          selectedIndex: 0,
          indexByTabId: true,
        },
      };
    },
  };

  const sideFilterConfig: LayoutSideFilterProps = {
    isSideFilterOpen: true,
    getSectionsConfig: async (): Promise<BigidSideFilterSectionBase[]> => {
      let preferences: UserPreference<DataCatalogPagePreferences>;

      try {
        if (shouldResetFilter) {
          updateFilterPreferences({
            searchQuery: '',
            sideFilterSelection: [],
          });
        } else {
          if (!filterSelections) {
            preferences = await userPreferencesService.get<DataCatalogPagePreferences>(CONFIG.states.CATALOG);
          } else {
            const decodedSelections = decodeURIComponent(filterSelections);
            preferences = {
              filter: {
                sideFilterSelection: JSON.parse(decodedSelections),
              },
              preference: 'dataCatalog',
            };
          }
        }
      } catch ({ message }) {
        console.error(message);
      }

      try {
        const sectionsConfig = await Promise.allSettled([
          loadAttributes({ filter, preferences }),
          loadDataSources({ filter, preferences }),
          loadScanStatus({ filter, preferences }),
          loadApplications({ filter, preferences }),
          loadCloudTypes({ filter, preferences }),
          loadPolicies({ filter, preferences }),
          isDataCatalogFileTypeEnabled && loadFileTypes({ filter, preferences }),
          isDataCatalogExtendedFileTypeEnabled && loadExtendedFileTypes({ filter, preferences }),
          isTagsReadPermitted && loadTags({ filter, preferences }, SectionName.TAGS),
          isTagsReadPermitted && loadTags({ filter, preferences }, SectionName.DS_TAGS),
          isTagsReadPermitted && loadIntegrations({ filter, preferences }),
          loadQuickFilters({ filter, preferences }, disableLegacyACL),
          isTagsReadPermitted && loadAccessType({ filter, preferences }, SectionName.ACCESS_TYPE),
          isClusteringEnabled && loadClusters({ filter, preferences }),
          loadSavedQueries({ filter, preferences }),
        ]).then(results =>
          results.map(result => {
            if (result.status === 'fulfilled') {
              return result.value;
            }

            // Rejected
            console.error(result.reason);
            console.error(new Error().stack);
          }),
        );

        return sectionsConfig.filter(Boolean).flat();
      } catch (e) {
        console.error(e);
      }

      return [];
    },
    onSideFilterSelectionChanged: () => {
      if ($stateParams.filter) {
        $state.go($state.current.name, { filter: null, sideFilterConfig: null }, { reload: false, notify: false });
      }
    },
    onSideFilterSectionAdded: ({ optionalSections }: LayoutSideFilterSectionAddedEventData) => {
      updateFilterPreferences({
        optionalSideFilterSections: optionalSections,
      });
    },
    onSideFilterSectionRemoved: ({ optionalSections }: LayoutSideFilterSectionRemovedEventData) => {
      updateFilterPreferences({
        optionalSideFilterSections: optionalSections,
      });
    },
  };

  const fetchGridData: FetchDataFunction<DataCatalogRecord> = async (queryComponents, customData) => {
    try {
      const { searchQuery } = customData;
      //NOTE: temporarily disabled until filtering flow is designed
      // const filter = parseFieldFiltersToSearchQuery(queryComponents.filter);
      const query = objectToQueryString({
        ...(queryComponents as QueryParams),
        filter: searchQuery,
        requireTotalCount: false,
      });
      const { results, totalRowsCounter } = await getDataCatalogRecords(query.length > 0 ? `&${query}` : '');

      const { permittedDs, isRootScope } = await getDataSourcesOwnerShip(
        CATALOG_PERMISSIONS.PREVIEW_FILE_INVESTIGATION.name,
      );

      const result: BigidGridDataFetchResult<DataCatalogRecord> = {
        totalCount: queryComponents.requireTotalCount ? totalRowsCounter : undefined,
        data: getGridData(results, permittedDs, isRootScope),
      };

      if (queryComponents.requireTotalCount) {
        updateFilterPreferences({
          searchQuery,
          sideFilterSelection: parseSearchQueryToFieldFilters(searchQuery),
        });

        if (queryComponents.limit === totalRowsCounter) {
          const { promise, cancel } = getDataCatalogRecordsCountCancellable(query.length > 0 ? query : '');
          const fetchCount: FetchTotalCount = {
            fetchCountCanceler: cancel,
            fetchCountFunction: async () => {
              try {
                const {
                  data: { count },
                } = await promise;
                return {
                  totalCount: count,
                };
              } catch (e) {
                let totalCount = 0;
                if (httpService.isAxiosError(e)) {
                  totalCount = e.response?.status === 408 || e.response?.status === 524 ? TOTAL_COUNT_THRESHOLD : 0;
                }
                return { totalCount };
              }
            },
          };
          result.fetchCount = fetchCount;
        }
      }

      if (searchQuery) {
        const { hasSideFilterBeenTriggered } = customData;
        const trackData = {
          Search_Query_Input: searchQuery,
          totalHits: result.totalCount,
          isCustomQuery: !hasSideFilterBeenTriggered,
        };

        analyticsService.trackManualEvent(CatalogEventsEnum.CATALOG_SEARCH, trackData);
        userPreferencesService.setSharedBooleanUserPreference(
          userPreferencesService.SHARED_PREFERENCES.CATALOG_WAS_USED_AT_LEAST_ONCE,
          true,
        );
      }

      return result;
    } catch ({ message, response }) {
      let errMessage;

      switch (response.status) {
        case 524:
          errMessage = 'This query is taking too long. Add more filters and try again.';
          break;
        case 422:
          errMessage = `Invalid filter query. Please, use the following format: ${
            isNewQueryFilterEnabled ? 'key = "value"' : 'key = value'
          }`;
          break;
        default:
          errMessage = 'An error has occurred while fetching the data';
      }

      notificationService.error(errMessage);
      console.error(`An error has occurred: ${message}`);

      return {
        totalCount: 0,
        data: [],
      };
    }
  };

  const tagsColumnIfEnabled: BigidGridColumn<DataCatalogExtendedRecord>[] = isTagsReadPermitted
    ? [
        {
          title: 'Tags',
          name: 'manualTags',
          type: BigidGridColumnTypes.TAGS,
          width: 400,
          getCellValue: ({ manualTags }) => ({
            tags: {
              ...manualTags.tags,
              value: manualTags.tags.value.map(tagDetails => ({
                ...tagDetails,
                name: tagDetails.name,
              })),
            },
          }),
        },
      ]
    : [];

  const getColumns = (): BigidGridColumn<DataCatalogExtendedRecord>[] => {
    const columns: BigidGridColumn<DataCatalogExtendedRecord>[] = [
      {
        title: 'Object Name',
        name: 'objectName',
        isListColumn: true,
        type: BigidGridColumnTypes.TEXT,
        getCellValue: ({ objectName }) => objectName,
      },
      {
        title: 'Type',
        name: 'objectType',
        type: BigidGridColumnTypes.ICON,
        getCellValue: ({ extendedObjectType }) => ({
          icon: {
            icon: getIconByObjectType(extendedObjectType),
            label: getObjectTypeName(extendedObjectType),
          },
        }),
      },
      {
        title: 'Data Source Name',
        name: 'source',
        type: BigidGridColumnTypes.TEXT,
        getCellValue: ({ source }) => source,
      },
      {
        title: 'Data Source Type',
        name: 'type',
        type: BigidGridColumnTypes.ICON,
        getCellValue: ({ type }) => ({
          icon: {
            icon: () => <DataSourceIconByDsType dataSourceType={type} width={24} height={24} />,
            label: type,
          },
        }),
      },
      {
        title: 'Full Object Name',
        name: 'fullObjectName',
        type: BigidGridColumnTypes.TEXT,
        getCellValue: ({ fullObjectName }) => fullObjectName,
      },
      {
        title: 'Attributes',
        name: 'attributes',
        type: BigidGridColumnTypes.CHIPS,
        width: 400,
        getCellValue: ({ attributes }) => attributes,
      },
      ...tagsColumnIfEnabled,
      {
        getCellValue: ({ modified_date }) => modified_date,
        title: 'Last Modified',
        name: 'modified_date',
        type: BigidGridColumnTypes.DATE,
        width: 180,
      },
      {
        getCellValue: ({ created_date }) => created_date,
        title: 'Created Date',
        name: 'created_date',
        type: BigidGridColumnTypes.DATE,
        width: 180,
      },
      {
        title: 'Assets',
        name: 'applications',
        type: BigidGridColumnTypes.CHIPS,
        width: 400,
        getCellValue: ({ applications }) => applications,
      },
      {
        title: 'Has Duplicates',
        name: 'has_duplicates',
        type: BigidGridColumnTypes.CHIP,
        getCellValue: ({ has_duplicates }) =>
          hasIndicatorMapping.get(HAS_DUPLICATES_CHIP_TYPE).get(has_duplicates.toLowerCase()),
      },
    ];
    if (disableLegacyACL !== true) {
      columns.push({
        title: 'Open Access',
        name: 'open_access',
        type: BigidGridColumnTypes.CHIP,
        getCellValue: ({ open_access }) =>
          hasIndicatorMapping.get(OPEN_ACCESS_CHIP_TYPE).get(open_access.toLowerCase()),
      });
    }
    return columns;
  };

  const gridConfig: BigidGridProps<DataCatalogExtendedRecord> = {
    showSortingControls: false,
    pageSize: 100,
    columns: getColumns(),
    noDataContent: (
      <td className={classes.noData} colSpan={5}>
        <BigidLayoutEmptyState illustration={BigidNoDataIllustration} description={t('noDataContent.description')} />
      </td>
    ),
  };

  const toolbarActions: ToolbarAction[] = [
    {
      label: 'Export',
      isGlobal: true,
      execute: async ({ searchQuery, hasSideFilterBeenTriggered, totalRows }: ActionData) => {
        try {
          await getDataCatalogRecordsAsCSV(searchQuery);
        } catch (err) {
          const errMsg = `An error has occurred`;
          notificationService.error(errMsg);
          console.error(`${errMsg}: ${err}`);
        } finally {
          const trackData = {
            Search_Query_Input: searchQuery,
            totalHits: totalRows,
            isCustomQuery: searchQuery.length > 0 && !hasSideFilterBeenTriggered,
          };

          analyticsService.trackManualEvent(CatalogEventsEnum.CATALOG_EXPORT, trackData);
          return Promise.resolve({ shouldGridReload: false });
        }
      },
      disable: ({ totalRows }: ActionData) => {
        return totalRows === 0;
      },
      show: () => isPermitted(CATALOG_PERMISSIONS.EXPORT.name),
    },
    ...(isTagsBulkAssignmentEnabled
      ? [
          {
            label: t('actions.assignTag.label'),
            isGlobal: true,
            execute: async (actionData: ActionData) => {
              try {
                const { searchQuery, totalRows } = actionData;

                return new Promise((resolve, reject) => {
                  setBulkTagAssignmentDialogState(prevState => ({
                    ...prevState,
                    filter: searchQuery,
                    isOpen: true,
                    onSubmit: () => {
                      closeBulkTagAssignmentDialog();
                      resolve({
                        shouldGridReload: false,
                        shouldClearSelection: true,
                      });

                      const trackingData = {
                        objectsCount: totalRows,
                        searchQuery,
                      };
                      analyticsService.trackManualEvent(CatalogEventsEnum.CATALOG_ASSIGN_BULK_TAG, trackingData);
                    },
                    onClose: () => {
                      reject();
                    },
                  }));
                });
              } catch (err) {
                const errMsg = `An error has occurred`;
                notificationService.error(errMsg);
                console.error(`${errMsg}: ${err}`);
              }
            },
            tooltip: ({ totalRows, searchQuery }: ActionData) => {
              const isDisabled = totalRows === 0 || !searchQuery;

              if (isDisabled) {
                return t('actions.assignTag.tooltipDisabled');
              } else return t('actions.assignTag.tooltipEnabled');
            },
            disable: ({ totalRows, searchQuery }: ActionData) => {
              return totalRows === 0 || !searchQuery;
            },
            show: () => {
              return isPermitted(CATALOG_PERMISSIONS.ASSIGN_TAG.name) && isPermitted(TAGS_PERMISSIONS.READ.name);
            },
          },
        ]
      : []),
    {
      label: 'View in Data overview',
      isGlobal: true,
      execute: async ({ filter }: ActionData) => {
        const isDataOverviewEnabled = getApplicationPreference('DATA_OVERVIEW_ENABLED');

        analyticsService.trackManualEvent(CatalogEventsEnum.CATALOG_GOTODISCOVERYINSIGHTS_CLICK);

        if (!isDataOverviewEnabled) {
          window.open(docsUrls.DISCOVERY_INSIGHTS, '_blank');
          return;
        }

        $state.go(CONFIG.states.CATALOG_DISCOVERY, { selectedCatalogFilters: { dropdownFilters: filter } });
        return {};
      },
      disable: ({ totalRows, hasSideFilterBeenTriggered, searchQuery }: ActionData) => {
        return totalRows === 0 || (hasSideFilterBeenTriggered === false && Boolean(searchQuery));
      },
      getIsDisabledAsync: async ({ totalRows, hasSideFilterBeenTriggered, searchQuery }: ActionData) => {
        const isDataOverviewEnabled = getApplicationPreference('DATA_OVERVIEW_ENABLED');
        const isMetadataSearchEnabled = getApplicationPreference('METADATA_SEARCH_ENABLED');
        const isDataOverviewAvailable = await getIsCatalogDiscoveryAvailable(isMetadataSearchEnabled);
        const isDisabled =
          isDataOverviewEnabled &&
          (!isDataOverviewAvailable ||
            totalRows === 0 ||
            (hasSideFilterBeenTriggered === false && Boolean(searchQuery)));

        return {
          isDisabled,
          justification:
            "Data isn't available yet in the Data Overview. Wait for the data to be indexed (wait period can be up to 12 hours), or perform a manual index by clicking Metadata Search Indexing in Settings > Advanced Tools and then click play next to Catalog",
        };
      },
      show: () => true,
    },
  ];

  const layoutConfig: BigidLayoutConfig = {
    filter: {
      hasInitialFilter: true,
      sideFilter: sideFilterConfig,
      search: {
        initialSearchQuery: filter || '',
        isQueryLanguage: true,
        useExperimentalQuery: isNewQueryFilterEnabled,
        onSearchQueryChange: () => {
          if ($stateParams.filter) {
            $state.go($state.current.name, { filter: null }, { reload: false, notify: false });
          }
        },
      },
      shareIconConfig: {
        getIsShown: () => true,
        getShareUrl: actionData => generateSharingCatalogUrl(actionData, $state.current.name),
        getIsDisabled: actionData => actionData.searchQuery === '',
      },
    },
    content: {
      entityName: 'objects',
      totalRowsThreshold: TOTAL_COUNT_THRESHOLD,
      getCustomThresholdDescription: () => 'Counted millions of',
      contentTypes: [LayoutContentType.MASTER_DETAILS],
      viewConfig: {
        fetchGridData,
        gridConfig,
        masterDetailsConfig,
        selectedItemPropsMapping: {
          id: 'id',
          name: 'objectName',
          objectName: 'objectName',
          fullyQualifiedName: 'fullyQualifiedName',
          scanner_type_group: 'scanner_type_group',
          detailedObjectType: 'detailedObjectType',
          hierarchyType: 'hierarchyType',
          containerName: 'containerName',
          source: 'source',
          fullObjectName: 'fullObjectName',
          objectType: 'objectType',
          owner: 'owner',
          tags: 'generalTags',
          icon: 'icon',
          scannerType: 'type',
          collaborationStatus: 'collaborationStatus',
          isPermittedToPreview: 'isPermittedToPreview',
          isConfidenceLevelIgnored: 'isConfidenceLevelIgnored',
        },
      },
      toolbarActions,
    },
    ...(getApplicationPreference('SHOW_DATA_CATALOG_HIGHLIGHTS') && { insights: { component: DataCatalogInsights } }),
  } as BigidLayoutConfig;

  const closeBulkTagAssignmentDialog = useCallback(() => {
    setBulkTagAssignmentDialogState(prevState => ({
      ...prevState,
      filter: undefined,
      isOpen: false,
    }));
  }, []);

  const bulkTagAssignmentDialogConfig: DataCatalogBulkTagAssignmentDialogProps = useMemo(
    () => ({
      ...bulkTagAssignmentDialogState,
      onClose: closeBulkTagAssignmentDialog,
    }),
    [bulkTagAssignmentDialogState, closeBulkTagAssignmentDialog],
  );

  const closeObjectIssueDialog = useCallback(() => {
    setObjectIssueDialogState({ isOpen: false });
  }, []);

  const handleProcessingWidgetGotCompletedOperations = useCallback(() => {
    entityEventsEmitter.emit(EntityEvents.RELOAD);
  }, []);

  useEffect(() => {
    const titleConfig = getTitleServiceConfig(
      isTagsBulkAssignmentEnabled,
      isColumnClusteringEnabled,
      handleProcessingWidgetGotCompletedOperations,
    );
    pageHeaderService.setTitle(titleConfig);
  }, [handleProcessingWidgetGotCompletedOperations, isColumnClusteringEnabled, isTagsBulkAssignmentEnabled]);

  return (
    <Fragment>
      <BigidLayout config={layoutConfig} />
      <DataCatalogBulkTagAssignmentDialog {...bulkTagAssignmentDialogConfig} />
      <DataCatalogObjectIssueDialog {...objectIssueDialogState} />
    </Fragment>
  );
};

export class DataCatalogComponentClass extends React.Component {
  render() {
    const showNewDataCatalog = getApplicationPreference('NEW_DATA_CATALOG_ENABLED');
    if (showNewDataCatalog) {
      return (
        <QueryClientProvider client={queryClient}>
          <DataCatalogContextProvider>
            <NewDataCatalog />
          </DataCatalogContextProvider>
        </QueryClientProvider>
      );
    }
    return <DataCatalog />;
  }
}
