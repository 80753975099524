import makeStyles from '@mui/styles/makeStyles';
import React, { FC, useEffect, useState } from 'react';
import { ExplorationBarChart as BarChart } from '../../../exploration/explorationBarChart/explorationBarChart.component';
import { explorationBarChartService } from '../../services/angularServices';
import { ExplorationObjectsWidget } from '../../../exploration/objects/objects.component';
import { BigidLoader, BigidColors, BigidBypassButton } from '@bigid-ui/components';
import { MapTreeMap as TreeMap } from '../../../commonComponents/mapTreeMap/mapTreeMap.component';
import { NormalizedClassifier } from './ClassifiersService';
import { httpService } from '../../services/httpService';
import { notificationService } from '../../services/notificationService';
import { sessionStorageService } from '../../../common/services/sessionStorageService';
import { getApplicationPreference } from '../../services/appPreferencesService';

const ExplorationBarChart = BarChart as React.ComponentClass<any>;

const MapTreeMap = TreeMap as React.ComponentClass<any>;

export interface EntitySummary {
  title: string;
  entity: string;
  counter: number;
  sub_title: string;
  sub_counter: number;
  totalInIDSoR?: number;
  sampledIdentities?: number;
}

interface ExplorationSummary {
  totalSystems: number;
  totalSystemsUnmanaged: number;
  totalApplications: number;
  totalApplicationsAtRisk: number;
}

const useStyles = makeStyles({
  explorationBarChartContainer: {
    display: 'grid',
    gridGap: 30,
    gridTemplateRows: '170px 400px',
    gridTemplateColumns: 'repeat(2,calc(50% - 15px))',
    gridAutoColumns: '1fr',
  },
  '@media screen and (max-width: 1920px)': {
    explorationBarChartContainer: {
      gridTemplateRows: '170px 400px 400px',
      gridTemplateAreas: `"row1 row1" "row2 row2" "row3 row3"`,
    },
    mapContainer: {
      gridArea: 'row2',
    },
    explorationObjectsContainer: {
      gridArea: 'row3',
    },
  },
  chartWidget: {
    width: '100%',
    height: '100%',
    boxShadow: '1px 2px 6px 1px rgba(51, 113, 179, 0.6)',
    borderRadius: '4px',
    backgroundColor: '#fff',
  },
  explorationObjectsContainer: {
    height: 400,
    '& .objectsWidgetUiGrid': {
      height: '325px !important',
    },
  },
  mapContainer: {
    height: 400,
    overflow: 'hidden',
    position: 'relative',
    boxShadow: '1px 2px 6px 1px rgba(51, 113, 179, 0.6)',
    borderRadius: '4px',
    '& .toggleMapTreeMap': {
      display: 'none',
    },
    '& ammap': {
      '& .mapPanel': {
        height: '100% !important',
        width: '100% !important',
        margin: 0,
        boxShadow: 'none',
      },
      '& #selector': {
        padding: 10,
        background: 'transparent',
        width: 170,
        position: 'absolute',
        top: 5,
        right: 0,
        textAlign: 'left',
      },
      '& .map-controls': {
        position: 'absolute',
        bottom: 15,
        right: 15,
        fontSize: 5,

        '& input': {
          padding: 15,
        },
      },
    },
  },
  noFindings: {
    padding: '20px auto',
    textAlign: 'center',
    color: BigidColors.gray[500],
  },
  bypassWidgets: {
    '&:focus': {
      left: '60px',
      marginTop: '-6px',
    },
  },
  bypassMap: {
    '&:focus': {
      zIndex: '1',
    },
  },
});

const FILTERS_CHANGE_DEBOUNCE = 100;

interface Filter {
  name: string;
  values: string[];
}

interface Filters {
  classifications: Filter;
  system: Filter;
  application: Filter;
  systemLocations: Filter;
  applicationLocations: Filter;
  userLocations: Filter;
}

type FiltersKey = keyof Filters;

interface HandleCallToFilterSearchHeaderCallback {
  (type: string, values: string[], valuesToRemove: string[]): void;
}

const ExplorationObjectsWidgetComponent = ExplorationObjectsWidget as unknown as FC<any>;

export const ClassificationWidgets: FC<NormalizedClassifier> = (item: NormalizedClassifier) => {
  const { attributeRiskName, totalFindings } = item;

  const classes = useStyles({});

  const [summary, setSummary] = useState<Partial<ExplorationSummary>>({});
  const [isSummaryLoading, setIsSummaryLoading] = useState(true);
  const [filters, setFilters] = useState<Filters>(createFiltersObject());
  const [widgetQueryStringGenerator, setWidgetQueryStringGenerator] = useState<(name: FiltersKey) => string>(null);

  useEffect(() => {
    explorationBarChartService.clearGetApplicationRisksRequest();
    setIsSummaryLoading(true);
    getEntitiesSummary()
      .then(explorationSummary => {
        setSummary(explorationSummary);
      })
      .catch(err => {
        const errMessage = `Failed to load Classifier findings data`;
        notificationService.error(errMessage);
        window.console.error(errMessage, err.response);
        throw err;
      })
      .finally(() => {
        setIsSummaryLoading(false);
      });

    return () => explorationBarChartService.clearGetApplicationRisksRequest();
  }, []);

  useEffect(() => {
    const cleanFilters = createFiltersObject();
    cleanFilters.classifications.values = [attributeRiskName];
    setFilters(cleanFilters);
  }, [attributeRiskName]);

  useEffect(() => {
    // debounce filters changes to avoid too frequent API requests
    const debouncer = setTimeout(() => {
      setWidgetQueryStringGenerator(() => (type: FiltersKey) => getQueryStringFilter(filters, type));
    }, FILTERS_CHANGE_DEBOUNCE);

    return () => clearTimeout(debouncer);
  }, [filters]);

  const handleCallToFilterSearchHeader: HandleCallToFilterSearchHeaderCallback = (type, values) => {
    const eventTypeToFilterNameMap: Map<string, FiltersKey> = new Map([
      ['Systems', 'system'],
      ['Applications', 'application'],
    ]);

    if (!eventTypeToFilterNameMap.has(type)) {
      console.warn(`widget type not found for '${type}'`);
      return;
    }

    const filterName = eventTypeToFilterNameMap.get(type);
    const newQueryFilter: Filter = { ...filters[filterName], values };
    const newFilters = { ...filters, [filterName]: newQueryFilter };
    setFilters(newFilters);
  };

  const handleAmmapClearFilter = () => {
    const filtersToReset: FiltersKey[] = ['applicationLocations', 'systemLocations', 'userLocations'];
    const newFilters = {
      ...createFiltersObject(),
      ...Object.fromEntries(Object.entries(filters).filter(([key]) => !filtersToReset.includes(key as FiltersKey))),
    };
    setFilters(newFilters);
  };

  const handleAmmapFilterChange = (mapFilterValue: string) => {
    if (!mapFilterValue) {
      return;
    }
    const [name, value] = mapFilterValue.trim().split('=');

    const filterKey = getFilterKeyByName(name, filters);
    const currentFilter = filters[filterKey];
    const newFilter = { ...currentFilter, values: [...new Set([...currentFilter.values, value])] };
    const newFilters = { ...filters, [filterKey]: newFilter };
    setFilters(newFilters);
  };

  return (
    <React.Fragment>
      {isSummaryLoading && <BigidLoader></BigidLoader>}
      {widgetQueryStringGenerator && Boolean(totalFindings) && (
        <React.Fragment>
          <div className={classes.explorationBarChartContainer}>
            <BigidBypassButton bypassTo="map-chart" ariaLabel="Skip to map chart" className={classes.bypassWidgets} />
            <div className={classes.chartWidget}>
              <ExplorationBarChart
                type={'Systems'}
                header={'Data Sources'}
                showSubHeader={false}
                totalSystems={summary.totalSystems}
                totalSystemsUnmanaged={summary.totalSystemsUnmanaged}
                externalActiveObjectsSet={new Set(filters.system.values)}
                querystring={widgetQueryStringGenerator('system')}
                onCallToFilterSearchHeader={handleCallToFilterSearchHeader}
                useExperimentalQuery={false}
              />
            </div>
            <div className={classes.chartWidget}>
              <ExplorationBarChart
                type={'Applications'}
                header={'Applications'}
                showSubHeader={false}
                totalApplications={summary.totalApplications}
                totalApplicationsAtRisk={summary.totalApplicationsAtRisk}
                querystring={widgetQueryStringGenerator('application')}
                externalActiveObjectsSet={new Set(filters.application.values)}
                onCallToFilterSearchHeader={handleCallToFilterSearchHeader}
                useExperimentalQuery={false}
              />
            </div>
            <div className={classes.mapContainer} data-bypass="map-chart" aria-label="Map chart" tabIndex={-1}>
              <BigidBypassButton
                bypassTo="objects-grid"
                ariaLabel="Skip to objects grid"
                className={classes.bypassMap}
              />

              <div style={{ width: '100%', height: '100%', position: 'relative' }}>
                <MapTreeMap
                  isClickedOnImageObject="true"
                  mapZoomLevel={1}
                  mapZoomLatitude={-30}
                  mapZoomLongitude={30}
                  useBindingsDataFlow={true}
                  queryString={widgetQueryStringGenerator(null)}
                  onAmmapFilterChange={handleAmmapFilterChange}
                  onAmmapClearFilter={handleAmmapClearFilter}
                />
              </div>
            </div>
            <div
              className={classes.chartWidget + ' ' + classes.explorationObjectsContainer}
              tabIndex={-1}
              aria-label="Objects grid"
              data-bypass="objects-grid"
            >
              <ExplorationObjectsWidgetComponent
                styles={{ display: 'block' }}
                querystring={widgetQueryStringGenerator(null)}
                useBindingsDataFlow={true}
              />
            </div>
          </div>
        </React.Fragment>
      )}
      {widgetQueryStringGenerator && !totalFindings && <div className={classes.noFindings}>No findings to show</div>}
    </React.Fragment>
  );
};

function createFiltersObject(): Filters {
  return {
    classifications: {
      name: 'field',
      values: [],
    },
    system: {
      name: 'system',
      values: [],
    },
    application: {
      name: 'application',
      values: [],
    },
    applicationLocations: {
      name: 'application.location',
      values: [],
    },
    systemLocations: {
      name: 'system.location',
      values: [],
    },
    userLocations: {
      name: 'user.location',
      values: [],
    },
  };
}

function getFilterKeyByName(filterName: string, filters: Filters): FiltersKey {
  const entry = Object.entries(filters).find(([, { name }]) => name === filterName);
  return entry ? (entry[0] as FiltersKey) : undefined;
}

function getQueryStringFilter(filters: Filters, excludeFilter?: FiltersKey): string {
  const isNewQueryFilterEnabled = Boolean(getApplicationPreference('NEW_QUERY_FILTER_ENABLED'));
  const queryFilter = Object.entries(filters)
    .filter(([, { name }]) => name !== excludeFilter)
    .filter(([, { values }]) => values.length)
    .map(
      ([, { name, values }]) =>
        `${name} in (${(isNewQueryFilterEnabled ? values.map((value: string) => `"${value}"`) : values).join(',')})`,
    )
    .join(' AND ');
  return queryFilter ? `?filter=${encodeURIComponent(queryFilter)}` : '';
}

export async function getEntitiesSummary() {
  return httpService
    .fetch<{ entity_summaries: EntitySummary[] }>(`entitySummariesScoped`)
    .then(({ data: { entity_summaries: entitySummaries } }) => {
      const { counter: totalSystems, sub_counter: totalSystemsUnmanaged } =
        entitySummaries.find(({ entity }) => entity === 'source') || {};

      const { counter: totalApplications, sub_counter: totalApplicationsAtRisk } =
        entitySummaries.find(({ entity }) => entity === 'application') || {};

      return {
        totalSystems,
        totalSystemsUnmanaged,
        totalApplications,
        totalApplicationsAtRisk,
      };
    });
}
