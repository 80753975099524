import { module } from 'angular';
const app = module('app');

app.factory('scanResultsService', [
  '$http',
  'appSettings',
  function ($http, appSettings) {
    return {
      getApplicationsData: function getApplicationsData(queryString) {
        queryString = queryString || '';
        return $http
          .get(appSettings.serverPath + '/' + appSettings.version + '/applicationRisks' + queryString)
          .then(function (response) {
            return response.data;
          });
      },
      getAttributesData: function getAttributesData(queryString) {
        queryString = queryString || '';
        return $http
          .get(appSettings.serverPath + '/' + appSettings.version + '/attributeRisks' + queryString)
          .then(function (response) {
            return response.data;
          });
      },
      getAttributeRisksExtended: function getAttributeRisksExtended() {
        return $http
          .get(appSettings.serverPath + '/' + appSettings.version + '/attributeRisksExtended')
          .then(function (response) {
            return response.data;
          });
      },
      getSystemsData: function getSystemsData(queryString) {
        queryString = queryString || '';
        return $http
          .get(appSettings.serverPath + '/' + appSettings.version + '/sourceRisks' + queryString)
          .then(function (response) {
            return response.data;
          });
      },
    };
  },
]);
