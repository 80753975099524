import React, { FC } from 'react';
import { useLocalTranslation } from '../../../translations';
import { ActionableInsightsGuidedTourStepTemplate } from './ActionableInsightsGuidedTourStepTemplate';

interface ActionableInsightsStartStepProps {
  dataAid?: string;
}

export const InvestigateStep: FC<ActionableInsightsStartStepProps> = ({ dataAid }) => {
  const { t } = useLocalTranslation('Tour.steps.investigate');

  return <ActionableInsightsGuidedTourStepTemplate dataAid={dataAid} firstSectionText={t('firstSectionText')} />;
};
