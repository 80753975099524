import './classifiersWrapper.component.scss';
import classifiersWrapper from './classifiersWrapper.component.html';

import { module } from 'angular';
const app = module('app');

app.component('classifiersWrapper', {
  template: classifiersWrapper,
  controller: function ($rootScope, $scope, $translate, $state, $stateParams) {
    'ngInject';

    $translate('CLASSIFIERS:SETTINGS:PAGE_TITLE').then(translation => {
      $rootScope.$broadcast('changePage', translation, false, !$state.params.showBackButton);
    });

    this.isLoading = false;
    this.classifierName = null;
    this.classifierType = null;

    this.onLoading = () => {
      this.isLoading = true;
    };

    this.onLoaded = () => {
      $scope.$evalAsync(() => {
        this.isLoading = false;
      });
    };

    this.$onInit = () => {
      const { name, type } = $stateParams;

      this.classifierName = name ? decodeURIComponent(name) : null;
      this.classifierType = type && name ? decodeURIComponent(type) : null;
    };
  },
});
