import React, { FC, useState } from 'react';
import { BigidButtonIcon } from '@bigid-ui/components';
import { BIGCHAT_FEEDBACK_STATE, BIGCHAT_TRACKING_EVENTS } from '../BigChatTypes';
import styled from '@emotion/styled';
import { BigidDislikeIcon, BigidLikeIcon, BigidDislikeFilledIcon, BigidLikeFilledIcon } from '@bigid-ui/icons';
import { saveMessages } from '../../../services/bigchat/bigchatStorageService';
import { generateDataAid } from '@bigid-ui/utils';
import { analyticsService } from '../../../services/analyticsService';

const Wrapper = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-end',
  gap: '8px',
  margin: '15px 0px 15px 0px',
  alignSelf: 'stretch',
});
const FeedbackWrapper = styled('div')({
  display: 'flex',
  alignItems: 'center',
});
export const BigChatFeedback: FC = (props: any) => {
  const {
    state: { messages },
    payload: { question, answer, approved, rejected, index },
    setState,
  } = props;

  const [isApprovedSelected, setIsApprovedSelected] = useState<boolean>(approved);
  const [isRejectSelected, setIsRejectSelected] = useState<boolean>(rejected);

  const handleClickRejected = (): void => {
    setIsRejectSelected(prevState => {
      const newState = !prevState;
      updateFeedbackInMessages(newState, BIGCHAT_FEEDBACK_STATE.REJECTED);
      if (newState) {
        analyticsService.trackManualEvent(BIGCHAT_TRACKING_EVENTS.BIGCHAT_FEEDBACK_CLICK, {
          question: question,
          answer: answer,
          feedback: BIGCHAT_FEEDBACK_STATE.REJECTED,
        });
      }
      return newState;
    });
  };

  const handleClickApproved = (): void => {
    setIsApprovedSelected(prevState => {
      const newState = !prevState;
      updateFeedbackInMessages(newState, BIGCHAT_FEEDBACK_STATE.APPROVED);
      if (newState) {
        analyticsService.trackManualEvent(BIGCHAT_TRACKING_EVENTS.BIGCHAT_FEEDBACK_CLICK, {
          question: question,
          answer: answer,
          feedback: BIGCHAT_FEEDBACK_STATE.APPROVED,
        });
      }
      return newState;
    });
  };

  const updateFeedbackInMessages = (feedbackNewState: boolean, feedbackType: BIGCHAT_FEEDBACK_STATE): void => {
    const messagesToUpdate = [...messages];
    messagesToUpdate[index] = {
      ...messagesToUpdate[index],
      payload: {
        ...messagesToUpdate[index].payload,
        [feedbackType]: feedbackNewState,
      },
    };
    setState((prevState: any) => ({ ...prevState, messages: [...messagesToUpdate] }));
    saveMessages([...messagesToUpdate]);
  };

  return (
    <Wrapper>
      <FeedbackWrapper>
        <BigidButtonIcon
          data-aid={`${generateDataAid('BigChat', ['feedback', 'reject', 'button'])}`}
          onClick={handleClickRejected}
          size="small"
          iconColor={isRejectSelected ? 'negative' : 'default'}
          icon={isRejectSelected ? BigidDislikeFilledIcon : BigidDislikeIcon}
          variant="tertiary"
          bi={{ disabled: true }}
        />
        <BigidButtonIcon
          data-aid={`${generateDataAid('BigChat', ['feedback', 'approve', 'button'])}`}
          onClick={handleClickApproved}
          size="small"
          iconColor={isApprovedSelected ? 'positive' : 'default'}
          icon={isApprovedSelected ? BigidLikeFilledIcon : BigidLikeIcon}
          variant="tertiary"
          bi={{ disabled: true }}
        />
      </FeedbackWrapper>
    </Wrapper>
  );
};
