import React, { FC } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { BigidHeading5, BigidBody1 } from '@bigid-ui/components';
import { BigidReviewFormIllustration, BigidRiskAlertIllustration } from '@bigid-ui/icons';

interface DialogHeadingProps {
  isError: boolean;
}

const useStyles = makeStyles({
  headingWrapper: { display: 'flex', alignItems: 'center', margin: '0 56px 20px 56px' },
  iconWrapper: { display: 'flex', justifyContent: 'center', marginRight: 35 },
  text: { marginTop: 10 },
});

export const DialogHeading: FC<DialogHeadingProps> = ({ isError }) => {
  const { iconWrapper, text, headingWrapper } = useStyles({});

  return (
    <div className={headingWrapper}>
      <div className={iconWrapper}>{isError ? <BigidRiskAlertIllustration /> : <BigidReviewFormIllustration />}</div>
      <div>
        <BigidHeading5 gutterBottom className={isError ? text : ''}>
          {isError ? 'Oops! It looks like something broke…' : `We want to hear from you`}
        </BigidHeading5>
        <BigidBody1 gutterBottom className={isError ? text : ''}>
          {isError
            ? `Report this issue by filling in the form below. We'll do our best so this won't happen again.`
            : `Please fill in this form to let us know of your thoughts or suggestions for how we can improve.`}
        </BigidBody1>
      </div>
    </div>
  );
};
