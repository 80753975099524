import React, { FC } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { BigidAuditIllustration } from '@bigid-ui/icons';
import { BigidLayoutEmptyState } from '@bigid-ui/layout';

const useStyles = makeStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
    gap: '8px',
    alignItems: 'center',
    paddingTop: '10%',
  },
});

export const ActionsEmptyState: FC = () => {
  const classes = useStyles({});

  return (
    <div className={classes.root}>
      <BigidLayoutEmptyState
        illustration={BigidAuditIllustration}
        description="There are no actions available for this app"
      />
    </div>
  );
};
