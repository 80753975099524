import './saml.component.scss';
import { module } from 'angular';
import template from './saml.component.html';
import { isPermitted } from '../../../react/services/userPermissionsService';
import { ACCESS_MANAGEMENT_PERMISSIONS } from '@bigid/permissions';
import { loginService } from '../../../authentication/login/login.service';
import { getApplicationPreference } from '../../../react/services/appPreferencesService';

const USER_FIRST_NAME_DEFAULT = 'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/givenname';
const USER_LAST_NAME_DEFAULT = 'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/surname';
const USER_EMAIL_DEFAULT = 'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/emailaddress';

const app = module('app');

class SamlComponent {
  constructor($translate, notificationService, samlService, rolesService) {
    'ngInject';

    this.$translate = $translate;
    this.notificationService = notificationService;
    this.samlService = samlService;
    this.rolesService = rolesService;
  }

  $onInit() {
    this.isManagePermitted = isPermitted(ACCESS_MANAGEMENT_PERMISSIONS.MANAGE_IDP.name);
    this.isLogoutUrlEnabled = getApplicationPreference('SAML_LOGOUT_ENABLED');
    this.roles = [];
    this.mdFileLink = undefined;
    this.mdFile = undefined;
    this.filenameToImport = undefined;
    this.isLink = true;
    this.isCollapsed = true;
    this.isStep2Enabled = false;

    this.isLoading = false;

    this.saml = {
      entityId: '',
      userObject: {
        firstName: USER_FIRST_NAME_DEFAULT,
        lastName: USER_LAST_NAME_DEFAULT,
        email: USER_EMAIL_DEFAULT,
      },
      configRolesGroups: [],
      enabled: false,
    };

    this.getRoles();
    this.getSaml();
  }

  alphaSort(a, b) {
    const aName = a.name.toLowerCase(),
      bName = b.name.toLowerCase();
    if (aName < bName) {
      return -1;
    } else if (aName > bName) {
      return 1;
    } else {
      return 0;
    }
  }

  getRoles() {
    this.rolesService.getRBACRoles().then(
      response => {
        this.roles = response.data.roles.sort(this.alphaSort);
      },
      () => {
        this.$translate('SAML:ROLES:FAILURE').then(translation => {
          this.notificationService.error(translation);
        });
      },
    );
  }

  getNewSamlConf() {
    loginService.clear();
    loginService.getSamlConf();
  }

  createSaml(payload) {
    this.onDataLoading();
    this.samlService
      .createSaml(payload)
      .then(
        response => {
          this.$translate('SAML:CREATE:SUCCESS').then(translation => {
            this.notificationService.success(translation);
          });

          this.saml.samlId = response.data.saml.samlId;
          this.resetXmlAfterSave();
          this.getNewSamlConf();
        },
        res => {
          this.$translate('SAML:FAILURE').then(translation => {
            this.notificationService.error(res?.data?.message || translation);
          });
        },
      )
      .finally(() => {
        this.onDataLoaded();
      });
  }

  updateSaml(payload) {
    this.onDataLoading();
    this.samlService
      .updateSaml(payload, this.saml.samlId)
      .then(
        () => {
          this.$translate('SAML:UPDATE:SUCCESS').then(translation => {
            this.notificationService.success(translation);
          });

          this.resetXmlAfterSave();
          this.getNewSamlConf();
        },
        res => {
          this.$translate('SAML:FAILURE').then(translation => {
            this.notificationService.error(res?.data?.message || translation);
          });
        },
      )
      .finally(() => {
        this.onDataLoaded();
      });
  }

  resetXmlAfterSave() {
    this.isLink ? this.fileRemoved() : (this.mdFileLink = undefined);
    this.setIsTestConnectionIsEnabled();
  }

  testSAMLConnection() {
    this.onDataLoading();
    this.samlForm.$submitted = true;

    this.testSAMLWithOrWithoutFile()
      .then(() => {
        this.$translate('SAML:TEST_CONNECTION:SUCCESS').then(translation => {
          this.notificationService.success(translation);
        });
        this.isStep2Enabled = true;
      })
      .catch(({ data: { message } }) => {
        this.$translate('SAML:TEST_CONNECTION:FAILURE').then(translation => {
          this.notificationService.error(`${translation}: ${message}`);
        });
      })
      .finally(() => {
        this.onDataLoaded();
      });
  }

  testSAMLWithOrWithoutFile() {
    const formData = new FormData();

    if (this.isLink) {
      formData.append('mdFileLink', this.mdFileLink);
      return this.samlService.testSAMLConnection(formData);
    } else if (this.isDummyFile()) {
      return this.samlService.testSAMLExistingFileConnection(this.saml.samlId);
    }

    formData.append('samlConfigFile', this.mdFile);
    return this.samlService.testSAMLConnection(formData);
  }

  onEnabledChanged() {
    if (!this.saml.samlId) return;
    const payload = {
      enabled: this.saml.enabled,
      samlId: this.saml.samlId,
    };
    const formData = new FormData();
    formData.append('data', JSON.stringify(payload));
    this.updateSaml(formData);
  }

  getSAMLForSave() {
    this.saml.configRolesGroups = this.roles.map(role =>
      role.saml_group
        ? { roleName: role.name, claim: role.saml_group.claim, saml_group: role.saml_group.value, roleId: role._id }
        : { roleName: role.name, roleId: role._id },
    );
    return angular.copy(this.saml);
  }

  submit() {
    const formData = new FormData();
    const payload = this.getSAMLForSave();

    if (this.isXMLDataValid()) {
      this.appendXMLDataToForm(formData, payload);
    }

    formData.append('data', JSON.stringify(payload));

    if (payload.samlId) {
      this.updateSaml(formData);
    } else {
      this.createSaml(formData);
    }
    this.samlForm.$submitted = false;
  }

  appendXMLDataToForm(formData, payload) {
    if (this.isLink) {
      payload.mdFileLink = this.mdFileLink;
    } else if (!this.isDummyFile()) {
      formData.append('samlConfigFile', this.mdFile);
    }
  }

  initUserObjectData(userObject) {
    if (!userObject) {
      return {};
    }

    return {
      firstName: userObject.firstName || USER_FIRST_NAME_DEFAULT,
      lastName: userObject.lastName || USER_LAST_NAME_DEFAULT,
      email: userObject.email || USER_EMAIL_DEFAULT,
    };
  }

  getSaml() {
    this.onDataLoading();
    this.samlService
      .getSaml()
      .then(response => {
        if (response && response.data && Array.isArray(response.data.saml) && response.data.saml.length > 0) {
          const { mdFileLink, mdFile, ...samlResponse } = response.data.saml[0];

          this.saml = {
            ...samlResponse,
            userObject: this.initUserObjectData(samlResponse.userObject),
            configRolesGroups: Array.isArray(samlResponse.configRolesGroups) ? samlResponse.configRolesGroups : [],
          };

          this.mdFileLink = mdFileLink;
          this.isLink = !!this.mdFileLink;

          if (!this.isLink && mdFile) {
            this.setDummyFile(mdFile.originalname);
          }

          this.setIsTestConnectionIsEnabled();
          this.onDataLoaded();
        }
      })
      .catch(() => {
        this.onDataLoaded();
        this.$translate('SAML:FAILURE').then(translation => {
          this.notificationService.error(translation);
        });
      });
  }

  onXMLDataChange() {
    this.isTestConnectionButtonEnabled = this.saml.enabled && this.isXMLDataValid();

    if (this.isLink && this.mdFile && !this.isDummyFile()) {
      this.fileRemoved();
    }
  }

  isXMLDataValid() {
    return this.isLink ? this.mdFileLink && this.mdFileLink.length > 0 : !!this.mdFile;
  }

  setDummyFile(name) {
    const dummyFile = {
      attachmentId: 'dummy-file',
      name,
      type: 'text/xml',
      lastModified: 0,
    };

    this.mdFile = dummyFile;
    this.filenameToImport = name;
    this.fileList = [dummyFile];
  }

  fileSelected(files) {
    this.mdFile = files;
    this.filenameToImport = this.mdFile.name;
    this.onXMLDataChange();
  }

  fileRemoved() {
    this.mdFile = undefined;
    this.filenameToImport = undefined;
    this.fileList = [];
    this.isTestConnectionButtonEnabled = false;
  }

  isDummyFile() {
    return this.mdFile && this.mdFile.attachmentId === 'dummy-file';
  }

  setIsTestConnectionIsEnabled() {
    this.isTestConnectionButtonEnabled = this.saml.enabled && this.isXMLDataValid();
  }
}

app.component('saml', {
  template,
  controller: SamlComponent,
  bindings: {
    onDataLoading: '&',
    onDataLoaded: '&',
  },
});
