import React, { FC, MouseEvent, useMemo, useRef, useState } from 'react';
import { BigidLayoutEmptyState, BigidLayoutEmptyStateAction, BigidLayoutEmptyStateProps } from '@bigid-ui/layout';
import { BigidReportsIllustration, BigidDataManagementIllustration } from '@bigid-ui/icons';
import { DATA_INSIGHTS_STUDIO, DATA_PIPELINE } from '../consts/ReportingEtlConsts';
import styled from '@emotion/styled';
import { executeEtl, isEtlLicensed, sendEmail } from '../reportingEtlMonitoringUtils';
import { $state } from '../../../services/angularServices';
import { CONFIG } from '../../../../config/common';
import {
  BigidDialog,
  PrimaryButton,
  SecondaryButton,
  BigidForm,
  BigidFormValues,
  BigidFormField,
  BigidFormFieldTypes,
  BigidFormFieldLabelPosition,
  BigidFormStateAndHandlers,
  BigidBody1,
} from '@bigid-ui/components';
import { noop } from 'lodash';
import isEmail from 'validator/lib/isEmail';
import { notificationService } from '../../../services/notificationService';
import { useLocalTranslation } from '../translations';
import { analyticsService } from '../../../services/analyticsService';
import { ReportingEtlTrackingEvents } from '../consts/ReportingEtlEventsConsts';

interface EtlEmptyStateProps {
  isDataInsightsStudio: boolean;
  openConfigurationForm: React.Dispatch<React.SetStateAction<boolean>>;
  setExecuteInProgress: React.Dispatch<React.SetStateAction<boolean>>;
}

const EmptyStateWrapper = styled('div')`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 65%;
  position: fixed;
`;

const formInitialValues: BigidFormValues = {
  email: '',
};

export const EtlNoData: FC<EtlEmptyStateProps> = ({
  isDataInsightsStudio,
  openConfigurationForm,
  setExecuteInProgress,
}) => {
  const { t } = useLocalTranslation();
  const [isEmailDialogOpen, setIsEmailDialogOpen] = useState(false);
  const formControls = useRef<BigidFormStateAndHandlers>();

  const formFields: BigidFormField[] = [
    {
      name: 'email',
      label: 'E-mail',
      isRequired: true,
      validate: (email: string) => {
        if (!isEmail(email)) {
          return 'Please enter a valid email address';
        }
        return false;
      },
      type: BigidFormFieldTypes.TEXT,
      labelWidth: '120px',
      labelPosition: BigidFormFieldLabelPosition.left,
      tooltipText: 'An email address of your IT',
      fieldProps: {
        size: 'medium',
      },
    },
  ];
  const actions: BigidLayoutEmptyStateAction[] = useMemo(() => {
    const etlLicensed = isEtlLicensed(isDataInsightsStudio);
    return [
      {
        label: etlLicensed ? t('emptyState.execute') : t('emptyState.emailToIT'),
        execute: async () => {
          if (etlLicensed) {
            setExecuteInProgress(true);
            await executeEtl(isDataInsightsStudio);
            setExecuteInProgress(false);
            analyticsService.trackManualEvent(ReportingEtlTrackingEvents.REPORTING_ETL_EMPTY_STATE_EXECUTE_ETL);
          } else {
            setIsEmailDialogOpen(true);
            analyticsService.trackManualEvent(ReportingEtlTrackingEvents.REPORTING_ETL_EMPTY_STATE_EMAIL_DIALOG_OPENED);
          }
          return { shouldGridReload: false };
        },
        show: () => true,
      },
      {
        label: etlLicensed ? t('emptyState.openSettings') : t('emptyState.connectLicense'),
        execute: () => {
          if (etlLicensed) {
            openConfigurationForm(true);
            analyticsService.trackManualEvent(ReportingEtlTrackingEvents.REPORTING_ETL_EMPTY_STATE_CONFIGURATION_OPEN);
          } else {
            analyticsService.trackManualEvent(
              ReportingEtlTrackingEvents.REPORTING_ETL_EMPTY_STATE_REDIRECT_TO_LICENSE_PAGE,
            );
            $state.go(CONFIG.states.GENERAL_SETTINGS, { currentTab: 'licenses', showBackButton: true });
          }
          return Promise.resolve({ shouldGridReload: false });
        },
        show: () => true,
      },
    ];
  }, [isDataInsightsStudio, openConfigurationForm, setExecuteInProgress, t]);

  const dialogButtons = [
    {
      text: t('emptyState.dialogButtons.cancel'),
      component: SecondaryButton,
      onClick: noop,
      isClose: true,
    },
    {
      text: t('emptyState.dialogButtons.send'),
      component: PrimaryButton,
      onClick: (event: MouseEvent) => {
        formControls?.current?.validateAndSubmit(async () => {
          await formControls?.current?.submit(event);
        });
      },
    },
  ];

  const emptyStateConfig: BigidLayoutEmptyStateProps = useMemo(
    () => ({
      illustration: (isDataInsightsStudio
        ? BigidReportsIllustration
        : BigidDataManagementIllustration) as BigidLayoutEmptyStateProps['illustration'],
      actions,
      title: isDataInsightsStudio ? DATA_INSIGHTS_STUDIO : DATA_PIPELINE,
      description: isDataInsightsStudio ? t('emptyState.disDescription') : t('emptyState.dpDescription'),
    }),
    [actions, isDataInsightsStudio, t],
  );

  const handleFormSubmit = async ({ email }: BigidFormValues) => {
    try {
      await sendEmail(email, isDataInsightsStudio);
      notificationService.success('Email sent successfully!');
    } catch (e) {
      notificationService.error('Failed to send email to IT, please contact BigID support');
    }
  };

  return (
    <EmptyStateWrapper>
      <BigidDialog
        isOpen={isEmailDialogOpen}
        onClose={() => setIsEmailDialogOpen(false)}
        title={'Email IT'}
        buttons={dialogButtons}
        maxWidth={'xs'}
      >
        <BigidBody1 display={'flex'}>{'Send an email to the IT owner to help you to connect your license'}</BigidBody1>
        <BigidForm
          initialValues={formInitialValues}
          fields={formFields}
          stateAndHandlersRef={formControls}
          controlButtons={false}
          onSubmit={handleFormSubmit}
        />
      </BigidDialog>
      <BigidLayoutEmptyState {...emptyStateConfig} />
    </EmptyStateWrapper>
  );
};
