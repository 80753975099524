import { analyticsService } from '../../services/analyticsService';

export enum GettingStartedUITrackingEvent {
  GETTING_STARTED_PAGE_VIEW = 'GETTING_STARTED_PAGE_VIEW',
  GETTING_STARTED_SECURITY_PAGE_VIEW = 'GETTING_STARTED_SECURITY_PAGE_VIEW',
  GETTING_STARTED_DEFINE_SENSITIVITY_SETUP_CLICK = 'GETTING_STARTED_DEFINE_SENSITIVITY_SETUP_CLICK',
  GETTING_STARTED_DEFINE_SENSITIVITY_DOCS_CLICK = 'GETTING_STARTED_DEFINE_SENSITIVITY_DOCS_CLICK',
  GETTING_STARTED_CONNECT_SETUP_CLICK = 'GETTING_STARTED_CONNECT_SETUP_CLICK',
  GETTING_STARTED_CONNECT_DOCS_CLICK = 'GETTING_STARTED_CONNECT_DOCS_CLICK',
  GETTING_STARTED_REDUCE_RISKS_SETUP_CLICK = 'GETTING_STARTED_REDUCE_RISKS_SETUP_CLICK',
  GETTING_STARTED_MANAGE_TEAM_SETUP_CLICK = 'GETTING_STARTED_MANAGE_TEAM_SETUP_CLICK',
  GETTING_STARTED_SLACK_INTEGRATION_CLICK = 'GETTING_STARTED_SLACK_INTEGRATION_CLICK',
  GETTING_STARTED_FINDINGS_SETUP_CLICK = 'GETTING_STARTED_FINDINGS_SETUP_CLICK',
  GETTING_STARTED_GET_HELP_CLICK = 'GETTING_STARTED_GET_HELP_CLICK',
  GETTING_STARTED_DOCS_CLICK = 'GETTING_STARTED_DOCS_CLICK',
  GETTING_STARTED_OVERVIEW_CLICK = 'GETTING_STARTED_OVERVIEW_CLICK',
  GETTING_STARTED_STATUS = 'GETTING_STARTED_STATUS',
}

export const trackEventGettingStarted = (eventType: GettingStartedUITrackingEvent, data?: Record<string, unknown>) => {
  analyticsService.trackManualEvent(eventType as any, data);
};
