import { $state } from '../../services/angularServices';
import { CONFIG } from '../../../config/common';
import { hasNewTemplate } from '../dataSourcesUtils';
import { OwnerFieldNameEnum } from '../../views/DataSources/DataSourceConfiguration/types';
import { BigidSeverityEnum } from '@bigid-ui/components';
import {
  CollaborationCommentMentionPayload,
  DSOwnerGeneralPayload,
  DSOwnerPayload,
  MentionPayload,
  NotificationResponse,
  NotificationType,
} from './headerNotificationTypes';
import { ENTITY_TYPE } from '../../types/commentsTypes';
import { ObjectDiffEntity, ObjectDiffType } from '../../views/DataCatalog/DataCatalogFollowedObjects/ObjectDiffTypes';

export const NotificationTypeToSeverity: Record<string, BigidSeverityEnum> = {
  [NotificationType.MENTION]: BigidSeverityEnum.NORMAL,
  [NotificationType.DS_OWNER]: BigidSeverityEnum.NORMAL,
  [NotificationType.OBJECT_ISSUE]: BigidSeverityEnum.NORMAL,
  [NotificationType.OBJECT_DIFF]: BigidSeverityEnum.NORMAL,
  [NotificationType.DS_OWNER_GENERAL]: BigidSeverityEnum.NORMAL,
};

const ownerDisplayName = {
  [OwnerFieldNameEnum.businessOwners]: 'business',
  [OwnerFieldNameEnum.itOwners]: 'IT',
};

export const getNotificationTemplate = ({ type, payload }: Pick<NotificationResponse, 'type' | 'payload'>) => {
  switch (type) {
    case NotificationType.DS_OWNER: {
      const { from, dsType, dsName, ownerType } = payload as DSOwnerPayload;
      const description = `${from} marked you as an owner of ${dsName}`;
      const title = `You were defined as a data source ${ownerDisplayName[ownerType]} owner`;
      if (ownerType === OwnerFieldNameEnum.itOwners) {
        return {
          title,
          description,
          link: {
            text: 'View data source',
            onClick: () =>
              $state.go(
                hasNewTemplate(dsType) ? CONFIG.states.CONFIG_DATA_SOURCE : CONFIG.states.EDIT_DATA_SOURCE_CONNECTION,
                { id: dsName },
              ),
          },
        };
      }
      if (ownerType === OwnerFieldNameEnum.businessOwners) {
        return {
          title,
          description,
          link: {
            text: 'View dashboard',
            onClick: () => $state.go(CONFIG.states.DASHBOARD, { id: dsName }),
          },
        };
      }
    }
    case NotificationType.MENTION: {
      const { from, entityName, entityId, entityType } = payload as MentionPayload;
      return {
        title: `You were mentioned in a ${entityType}`,
        ...(entityType === ENTITY_TYPE.TASK && {
          link: {
            text: `View ${entityType}`,
            onClick: () =>
              $state.go(CONFIG.states.TASKS_LIST, {
                taskId: entityId,
              }),
          },
        }),
        description: `${from} mentioned you in a ${entityName} ${entityType}`,
      };
    }
    case NotificationType.OBJECT_DIFF: {
      const { objectName, fullyQualifiedName, subject, objectDiffType } = payload as ObjectDiffEntity;
      const link =
        objectDiffType !== ObjectDiffType.OBJECT_DELETED
          ? {
              link: {
                text: 'View object',
                onClick: () => $state.go(CONFIG.states.CATALOG_PREVIEW, { id: fullyQualifiedName }),
              },
            }
          : {};

      return { ...link, title: subject, description: `Object Name: ${objectName}` };
    }
    case NotificationType.OBJECT_ISSUE: {
      const { from, entityName, entityId } = payload as MentionPayload;
      return {
        title: entityName,
        link: {
          text: 'View task',
          onClick: () =>
            $state.go(CONFIG.states.TASKS_LIST, {
              taskId: entityId,
            }),
        },
        description: `Created by: ${from}`,
      };
    }
    case NotificationType.DS_OWNER_GENERAL: {
      const { from, ownerId } = payload as DSOwnerGeneralPayload;
      const description = `${from} marked you as an owner of several data sources`;
      const title = `You were defined as a data sources owner`;
      return {
        title,
        description,
        link: {
          text: 'View data sources',
          onClick: () =>
            $state.go(CONFIG.states.DATA_SOURCE_ROUTER, {
              filter: JSON.stringify([{ field: 'owners_v2.id', value: [ownerId], operator: 'in' }]),
            }),
        },
      };
    }
    case NotificationType.SCAN_SCHEDULED: {
      const description = `Scans are scheduled for the relevant data sources. To customize the scan profiles, go to Saved Profiles.`;
      const title = `Scans are scheduled`;
      return {
        title,
        description,
        link: {
          text: 'Saved Profiles',
          onClick: () => $state.go(CONFIG.states.SCANS_SAVED_PROFILES),
        },
      };
    }
    case NotificationType.COLLABORATION_COMMENT_MENTION: {
      const { from, action, deepLink } = payload as CollaborationCommentMentionPayload;
      return {
        title: `${from} ${action}`,
        description: '',
        ...(deepLink && {
          link: {
            text: 'View Comment',
            href: deepLink,
            shouldOpenNewTab: false,
          },
        }),
      };
    }
    default:
      return { title: '', description: '' };
  }
};
