import React, { ChangeEvent, FC, useContext, useState, useEffect, MouseEvent } from 'react';
import { ScanTemplateWizardContext } from '../../../scanTemplateContext';
import { ClassificationSelectionGrid } from './ClassificationSelectionGrid';
import styled from '@emotion/styled';
import { BigidSwitch, BigidInlineNotification, BigidToggleButton, BigidToggleButtonProps } from '@bigid-ui/components';
import { BulkClassifierSelectionMethod } from '../../../scanTemplateTypes';
import { showGenericConfirmationDialog } from '../../../../../../services/genericConfirmationDialog';
import { getGlobalEnabledClassifierCount, CLASSIFIERS_LIMIT } from './classificationService';

const ClassificationStepWrapper = styled.div({
  flexGrow: 1,
  display: 'flex',
  flexDirection: 'column',
  minHeight: 0,
});

const GridWrapper = styled('div')<{ isUseGlobalClassifiers: boolean }>(
  { overflowX: 'hidden', flexGrow: 1 },
  ({ isUseGlobalClassifiers }) => ({
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    minHeight: 0,
    gap: 8,
    width: '100%',
    position: 'relative',
    height: '100%',
    maxHeight: '100%',
    overflow: 'hidden',
    ...(isUseGlobalClassifiers
      ? {
          overflowY: 'hidden',
          pointerEvents: 'none',
          opacity: 0.3,
          '.TableContainer-root': {
            overflow: 'hidden',
          },
        }
      : { overflowY: 'auto' }),
  }),
);

const Wrapper = styled('div')({
  display: 'flex',
  gap: 10,
});

const NotificationWrapper = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  gap: 16,
});

enum ClassificationGridView {
  REGULATION,
  CATEGORY,
}

const shouldChangeView = async (selectedTab: string, isClassifiersSelected: boolean) => {
  return isClassifiersSelected
    ? showGenericConfirmationDialog({
        title: 'Switch classification view',
        text: `All selections made on the ${selectedTab} screen will be cleared. Would you like to continue?`,
        actionButton: 'Yes',
        closeButton: 'No',
      })
    : true;
};

export const Classification: FC = () => {
  const { scanTemplateFormData, setScanTemplateFormData } = useContext(ScanTemplateWizardContext);
  const selectedIndex =
    scanTemplateFormData.bulkClassifierSelectionMethod === BulkClassifierSelectionMethod.CATEGORY
      ? ClassificationGridView.CATEGORY
      : ClassificationGridView.REGULATION;

  const [view, setView] = useState<number>(selectedIndex);
  const [loading, setLoading] = useState<boolean>(false);

  const tabs: BigidToggleButtonProps[] = [
    {
      label: BulkClassifierSelectionMethod.REGULATION,
      value: BulkClassifierSelectionMethod.REGULATION,
      isDisabled: scanTemplateFormData.isUseGlobalClassifiers,
    },
    {
      label: BulkClassifierSelectionMethod.CATEGORY,
      value: BulkClassifierSelectionMethod.CATEGORY,
      isDisabled: scanTemplateFormData.isUseGlobalClassifiers,
    },
  ];

  const onChangeTab = async (event: MouseEvent<HTMLElement>, tabValue: string) => {
    const isClassifiersSelected =
      scanTemplateFormData.classifiers?.length > 0 || scanTemplateFormData.bulkClassifierSelection?.length > 0;
    const isChangeView = await shouldChangeView(tabs[view].label.toLowerCase(), isClassifiersSelected);
    if (isChangeView) {
      setLoading(true);
      setView(tabs.findIndex(({ value }) => tabValue === value));
      setScanTemplateFormData(prevState => {
        return {
          ...prevState,
          classifiers: [],
          bulkClassifierSelection: [],
          bulkClassifierSelectionMethod: tabValue as BulkClassifierSelectionMethod,
        };
      });
      setLoading(false);
    }
  };

  const handleGlobalClassifiersSwitch = (event: ChangeEvent, checked: boolean) => {
    if (checked) {
      setScanTemplateFormData(prevState => ({
        ...prevState,
        classifiers: [],
        bulkClassifierSelection: [],
        isUseGlobalClassifiers: true,
      }));
    } else {
      setScanTemplateFormData(prevState => ({ ...prevState, isUseGlobalClassifiers: false }));
    }
  };

  return (
    <ClassificationStepWrapper>
      {
        <Wrapper>
          <BigidToggleButton toggleButtons={tabs} initialSelected={tabs[view].value} onChange={onChangeTab} />
          <BigidSwitch
            leftLabel={'Use Global Classifiers'}
            value={scanTemplateFormData.isUseGlobalClassifiers}
            checked={scanTemplateFormData.isUseGlobalClassifiers}
            onChange={handleGlobalClassifiersSwitch}
            dataAid={'useGlobalClassifiersSwitch'}
            dataTourId="useGlobalClassifiersSwitch"
          />
        </Wrapper>
      }
      {!loading && (
        <GridWrapper
          isUseGlobalClassifiers={scanTemplateFormData.isUseGlobalClassifiers}
          data-aid={'ClassifierSelectionGridWrapper'}
        >
          <ClassificationSelectionGrid
            scanTemplateFormData={{ ...scanTemplateFormData, classifiers: scanTemplateFormData?.classifiers ?? [] }}
            setScanTemplateFormData={setScanTemplateFormData}
            selectedTabLabel={tabs[view].label}
          />
        </GridWrapper>
      )}
    </ClassificationStepWrapper>
  );
};
