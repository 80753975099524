import React, { FC, useEffect, useMemo, useRef } from 'react';
import {
  BigidGridColumnTypes,
  BigidGridWithToolbar,
  BigidGridWithToolbarProps,
  BigidGridColumn,
  BigidGridQueryComponents,
} from '@bigid-ui/grid';
import { httpService } from '../../services/httpService';
import makeStyles from '@mui/styles/makeStyles';
import { $state, downloadFileService } from '../../services/angularServices';
import { notificationService } from '../../services/notificationService';
import { isPermitted } from '../../services/userPermissionsService';
import { AUDIT_PERMISSIONS } from '@bigid/permissions';
import {
  AuditModel,
  getAuditFilterConfig,
  getAuditGridData,
  getAuditGridDataObject,
  gridAuditClientSideFiltering,
} from './auditUtils';
import { BigidLoader, BigidPaper } from '@bigid-ui/components';
import { pageHeaderService } from '../../../common/services/pageHeaderService';
import { useUserPreferences } from '../../components/hooks/useUserPrefrences';
import { LegacyAuditPage } from '../../../administration/audit/audit.component';
import { getApplicationPreference } from '../../services/appPreferencesService';
import { TFunction } from 'i18next';
import { useLocalTranslation } from './translations';
import { queryService } from '../../services/queryService';
import { isMultiTenantModeEnabled } from '../../utilities/multiTenantUtils';
import { BigidLayoutEmptyState } from '@bigid-ui/layout';
import { BigidAuditIllustration } from '@bigid-ui/icons';

const useStyles = makeStyles({
  paper: {
    display: 'flex',
    justifyContent: 'space-between',
    flexWrap: 'nowrap',
    boxShadow: 'none',
    padding: '20px 20px 10px',
  },
  gridWrapper: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flex: '1 1 auto',
    flexFlow: 'column nowrap',
    overflow: 'hidden',
    paddingBottom: 10,
  },
  noData: {
    width: '100%',
    height: '100%',
    position: 'absolute',
  },
});

const useColumns = (t: TFunction): BigidGridColumn<AuditModel>[] =>
  useMemo(
    () => [
      {
        name: 'timeStamp',
        title: t('columns.timestamp'),
        getCellValue: row => row.timeStamp,
        sortingEnabled: true,
        type: BigidGridColumnTypes.DATE,
      },
      {
        name: 'user',
        title: t('columns.user'),
        getCellValue: row => row.user,
        type: BigidGridColumnTypes.TEXT,
      },
      {
        name: 'ipSource',
        title: t('columns.sourceIp'),
        getCellValue: row => row.ipSource,
        type: BigidGridColumnTypes.TEXT,
      },
      {
        name: 'action',
        title: t('columns.action'),
        getCellValue: row => row.action,
        type: BigidGridColumnTypes.TEXT,
      },
      {
        name: 'api',
        title: t('columns.endpoint'),
        getCellValue: row => row.api,
        type: BigidGridColumnTypes.TEXT,
      },
      {
        name: 'resourceObject',
        title: t('columns.resource'),
        getCellValue: row => row.resourceObject,
        type: BigidGridColumnTypes.TEXT,
      },
      {
        name: 'userAgent',
        title: t('columns.userAgent'),
        getCellValue: row => row.userAgent,
        type: BigidGridColumnTypes.TEXT,
      },
      {
        name: 'additionalData',
        title: t('columns.additionalData'),
        getCellValue: row => {
          return row.additionalData.replace(/[{}"]/g, '').replace(/,/g, ', \n').replace(/:/g, ': ');
        },
        type: BigidGridColumnTypes.TEXT,
      },
    ],
    [t],
  );

export const AuditLog: FC = () => {
  const { t } = useLocalTranslation('Audit');
  useEffect(() => {
    pageHeaderService.setTitle({ pageTitle: t('pageTitle') });
  }, [t]);
  const dataResultsRef = useRef<AuditModel[]>();

  const classes = useStyles({});

  const { isReady, gridColumns, filterToolbarConfig, preferences, updatePreferences } = useUserPreferences({
    stateName: $state.$current.name,
    initialGridColumns: useColumns(t),
    getInitialFilterToolbarConfig: getAuditFilterConfig,
  });

  const useNewApi = useMemo(
    () =>
      (getApplicationPreference('WRITE_AUDIT_LOGS_TO_MQ') && getApplicationPreference('USE_AUDIT_OBJECT_API')) ||
      isMultiTenantModeEnabled(),
    [],
  );

  const gridWithToolbarConfig: BigidGridWithToolbarProps<AuditModel> = useMemo(() => {
    const gridConfiguration: BigidGridWithToolbarProps<AuditModel> = {
      pagingMode: true,
      pageSize: 20,
      columns: gridColumns,
      entityName: t('toolbar.title'),
      showSortingControls: true,
      noDataContent: (
        <div className={classes.noData}>
          <BigidLayoutEmptyState illustration={BigidAuditIllustration} description={t('noDataContent.description')} />
        </div>
      ),
      defaultSorting: preferences?.grid?.sort || [{ field: 'timestamp', order: 'desc' }],
      filterToolbarConfig,
      onGridStateChange: ({ filter, ...gridState }) => updatePreferences({ filterState: { filter }, gridState }),
      fetchData: async (queryComponents: BigidGridQueryComponents) => {
        let auditGridData: AuditModel[] = [];
        const gridConfigQuery = queryService.getGridConfigQuery(queryComponents);
        try {
          if (useNewApi) {
            const { log, totalCount } = await getAuditGridDataObject(gridConfigQuery);
            return { data: log, totalCount };
          }
          if (!dataResultsRef.current) {
            dataResultsRef.current = await getAuditGridData();
          }
          auditGridData = gridAuditClientSideFiltering(dataResultsRef.current, queryComponents);
          return { data: auditGridData, totalCount: auditGridData.length };
        } catch (err) {
          const errMsg = t('fetchDataError');
          notificationService.error(errMsg);
          console.error(`${errMsg} ${err}`);
          return { data: [], totalCount: 0 };
        }
      },
      toolbarActions: [
        {
          label: t('toolbar.download'),
          isGlobal: true,
          execute: async () => {
            try {
              await httpService
                .fetch(`audit-log/export${useNewApi ? '?type=objects' : ''}`, {})
                .then(response => downloadFileService.download(response.data, 'attachment/csv', 'audit.csv'));
            } catch (err) {
              const errMsg = t('toolbar.downloadError');
              notificationService.error(errMsg);
              console.error(`${errMsg} ${err}`);
            } finally {
              return Promise.resolve({ shouldGridReload: false });
            }
          },
          disable: () => false,
          show: () => isPermitted(AUDIT_PERMISSIONS.DOWNLOAD_REPORT.name),
        },
        {
          label: t('toolbar.refresh'),
          isGlobal: true,
          execute: async () => {
            dataResultsRef.current = null;
            return { shouldGridReload: true };
          },
          disable: () => false,
          show: () => true,
        },
      ],
    };
    return useNewApi ? { ...gridConfiguration, pagingMode: false, pageSize: 200 } : gridConfiguration;
  }, [classes.noData, filterToolbarConfig, gridColumns, preferences?.grid?.sort, t, updatePreferences, useNewApi]);

  return (
    <div className={classes.gridWrapper}>
      <BigidPaper>
        {!isReady && <BigidLoader />}
        {isReady && <BigidGridWithToolbar {...gridWithToolbarConfig} />}
      </BigidPaper>
    </div>
  );
};

export const AuditLogConditionalWrapper = () => {
  const showNew = useMemo(() => {
    return getApplicationPreference('SHOW_NEW_AUDIT_PAGE');
  }, []);

  if (showNew) {
    return <AuditLog />;
  }

  return <LegacyAuditPage />;
};
