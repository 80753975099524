import React, { useMemo } from 'react';
import isEmail from 'validator/lib/isEmail';
import { BigidDropdownOption, BigidDropdownProps, BigidFieldRenderProps } from '@bigid-ui/components';
import { useLocalTranslation } from '../../translations';
import { EmailPickerField } from '../../../../../components/EmailPickerField/EmailPickerField';
import type { ValidationRule } from '../../../../../utilities/validation';
import { sessionStorageService } from '../../../../../../common/services/sessionStorageService';
import { LoginStrategies } from '../../../../Login/types';

type DataSourceConnectionCollaborationEmailFieldProps = {
  dataAid?: string;
  data: BigidDropdownOption[];
  onChange?: (values: BigidDropdownOption[]) => void;
  onInputChange?: (value: string) => void;
} & Partial<BigidFieldRenderProps<unknown, BigidDropdownOption[]>>;

export const DataSourceConnectionCollaborationEmailField = ({
  dataAid = 'DataSourceConnectionCollaborationEmailField',
  ...props
}: DataSourceConnectionCollaborationEmailFieldProps) => {
  const { t } = useLocalTranslation();

  const rules: ValidationRule<string, BigidDropdownOption[]>[] = useMemo(
    () => [
      {
        id: 'EMAIL',
        validate: term => isEmail(term),
        message: t('collaboration.emailValid'),
      },
      {
        id: 'NO_DUPLICATES',
        validate(term, options) {
          return !options?.map(({ value }) => value).includes(term);
        },
        message: t('collaboration.emailExists'),
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const overrides = useMemo<Partial<BigidDropdownProps>>(
    () => ({
      dataAid,
      applyOnChange: true,
      createOptionText: t('collaboration.createOption'),
      entityNamePlural: t('collaboration.email', { count: 2 }),
      entityNameSingular: t('collaboration.email', { count: 1 }),
      noOptionsMessage: t('collaboration.noOptions'),
      noSearchResultsMessage: t('collaboration.noSearchResults'),
      placeholder: t('collaboration.emailPlaceholder'),
      displayLimit: 20,
      isCreatable: sessionStorageService.get('LOGIN_STRATEGY') !== LoginStrategies.DEFAULT,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  return <EmailPickerField disableDynamicSearch dataAid={dataAid} rules={rules} field={overrides} {...props} />;
};
