import React, { FC } from 'react';
import { styled } from '@mui/material';
import { BigidBody1, BigidColorsV2 } from '@bigid-ui/components';
import { ListItemProps } from './types';
import { generateDataAid } from '@bigid-ui/utils';
import { useLocalTranslation } from '../../translations';
import { Trans } from 'react-i18next';

interface ScanTemplateGuidedTourStartProps {
  dataAid?: string;
}

const ContentContainer = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 18px;
`;

export const ScanTemplateGuidedTourStart: FC<ScanTemplateGuidedTourStartProps> = ({
  dataAid = 'ScanTemplateGuidedTourStart',
}) => {
  const { t } = useLocalTranslation('guidedTour.scanTemplates.start');

  return (
    <ContentContainer data-aid={generateDataAid(dataAid, [])}>
      <BigidBody1>{t('content1')}</BigidBody1>
      <BigidBody1>{t('content2')}</BigidBody1>
      <BigidBody1>{t('content3')}</BigidBody1>
    </ContentContainer>
  );
};
