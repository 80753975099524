import { ReactElement } from 'react';
import { ScanProfileConversionTypeEnum, ScanProfileFields } from '../ScanProfileConverter/scanProfileConversionTypes';
import { BigidGridColumn, BigidGridProps } from '@bigid-ui/grid';

export interface HeaderData {
  title?: string;
  content: ReactElement | string;
  hasDivider?: boolean;
  dataAid?: string;
}

export interface ConvertScanProfileOverviewHeaderProps {
  scanProfile: ScanProfileFields;
}

export interface ConvertScanProfileOverviewContentProps {
  scanProfile: ScanProfileFields;
}

interface BigidGridDataRow {
  name: string;
  id: string | number;
  isGlobalOrAllEnabled: boolean;
}

export interface BigidGridData extends BigidGridProps<BigidGridDataRow> {
  title: string;
  columns: BigidGridColumn<BigidGridDataRow>[];
  rows: BigidGridDataRow[];
  totalRowsCount: number;
  hasDivider: boolean;
}

export enum EntityTable {
  classifiers = 'classifiers',
  dataSourceList = 'dataSourceList',
  idsorList = 'idsorList',
}

export interface ConvertScanProfileProps {
  scanProfileId: string;
  isForReview: boolean;
  scanType?: string;
}

export type LabelManagmentContentProps = Pick<ScanProfileFields, 'isOverwriteTag' | 'isOverwritePrivilegedLabels'>;
