import { Reducer, useCallback, useEffect, useMemo, useReducer, useState } from 'react';
import { pageHeaderService } from '../../../common/services/pageHeaderService';
import {
  CuratedAttribute,
  CuratedAttributeType,
  CuratedDataSource,
  CurationStatus,
  getAttributeCurationStatus,
  GetAttributesCurationStatusResponse,
  getFieldsCurationStatus,
  GetFieldsCurationStatusResponse,
  getUserPreferences,
  setUserPreferences,
} from './curationService';
import { sessionStorageService } from '../../../common/services/sessionStorageService';
import { History, HistoryItem } from '../../components/BigidHeader/HeaderService';
import { $state, $stateParams } from '../../services/angularServices';
import { BigidFilter, Breadcrumb } from '@bigid-ui/components';
import { notificationService } from '../../services/notificationService';
import { CONFIG } from '../../../config/common';
import { getFixedT, useLocalTranslation } from './translations';
import { isUndefined } from 'lodash';
import {
  CurationHeaderHelpIconProps,
  curationPageTitleHelper,
  getGuidedTourKeyByCurationStateId,
  getStatusBaseQuery,
} from './curationUtils';
import { parseFieldFiltersToSearchQuery } from '@bigid-ui/layout';
import { getApplicationPreference } from '../../services/appPreferencesService';

interface UseCurationStateProps {
  scanId?: string;
  scanProfileName?: string;
  dataSource?: string;
  attributeName?: string;
  attributeType?: CuratedAttributeType;
  displayName?: string;
}

enum UseCurationStateAction {
  UPDATE_CURATION_WIZARD_STAGE,
  UPDATE_ATTRIBUTES_CURATION_STATUS,
  UPDATE_FIELDS_CURATION_STATUS,
  UPDATE_FIELDS_CURATION_STATUS_INCREMENT,
  UPDATE_GUIDED_TOUR_STATUS_BY_STAGE,
  UPDATE_GUIDED_TOUR_CURRENT_STAGE,
  INITIALIZE_USER_PREFERENCES,
}

export enum CurationStageId {
  CURATED_SOURCES = 'CURATED_SOURCES',
  CURATED_ATTRIBUTES = 'CURATED_ATTRIBUTES',
  CURATED_FIELDS = 'CURATED_FIELDS',
}

export enum CurationGuidedTourStageStatus {
  NOT_STARTED = 'NOT_STARTED',
  STARTED_AUTOMATICALLY = 'STARTED_AUTOMATICALLY',
  STARTED_MANUALLY = 'STARTED_MANUALLY',
  IS_AKNOWLEGED = 'IS_AKNOWLEGED',
}

export interface UseCurationState {
  defaultInitialStageId: CurationStageId;
  currentStageId: CurationStageId;
  attributesCurationStatus: CurationStatus;
  fieldsCurationStatus: CurationStatus;
  currentCuratedDataSource: CuratedDataSource;
  currentCuratedAttribute: CuratedAttribute;
  guidedTourStatus: Record<CurationGuidedTourStageId, CurationGuidedTourStageStatus>;
  isPageInitialised: boolean;
  currentGuidedTour: CurationGuidedTourStageId;
  isRootPage?: boolean;
}

export enum CurationGuidedTourStageId {
  DATA_SOURCES = 'DATA_SOURCES',
  ATTRIBUTES = 'ATTRIBUTES',
  FIELDS = 'FIELDS',
  PREVIEW = 'PREVIEW',
  COLUMN_PROFILE = 'COLUMN_PROFILE',
  ADDITIONAL_ATTRIBUTES = 'ADDITIONAL_ATTRIBUTES',
}

type UseCurationStatePayload = {
  action: UseCurationStateAction;
  data?: Partial<UseCurationState>;
};

export const CURATION_ATTRIBUTE_PREFIX = 'classifier.';

export interface UseCurationStateResponse
  extends Pick<
    UseCurationState,
    | 'currentStageId'
    | 'currentCuratedAttribute'
    | 'currentCuratedDataSource'
    | 'attributesCurationStatus'
    | 'fieldsCurationStatus'
    | 'guidedTourStatus'
    | 'isPageInitialised'
    | 'currentGuidedTour'
  > {
  originState: HistoryItem;
  onProceedToAttributeList: (curatedDataSource: CuratedDataSource) => void;
  onProceedToFieldsReview: (curatedDataSource: CuratedDataSource, curatedAttribute: CuratedAttribute) => void;
  onProceedToFieldsReviewTab: (currentGuidedTour: CurationGuidedTourStageId, openGuidedTour: boolean) => void;
  onGuidedTourFirstRun: (currentGuidedTour: CurationGuidedTourStageId) => void;
  onFieldReviewed: (isBulk?: boolean) => void;
  onBackToDataSourceList: () => void;
  onBackToAttributeList: () => void;
  onBackToOriginPage: () => void;
  goToScanPage: () => void;
  onGuidedTourStageFinished: (currentGuidedTour: CurationGuidedTourStageId) => void;
  onCurationGuidedTourStart: (currentGuidedTour?: CurationGuidedTourStageId) => void;
  onCurationDefaultInitialStageSelect: (
    stageId: CurationStageId.CURATED_SOURCES | CurationStageId.CURATED_ATTRIBUTES,
  ) => void;
}

const getInitialStageFromParams = (dataSource?: string, attributeName?: string) => {
  if (attributeName) {
    return CurationStageId.CURATED_FIELDS;
  }
  if (dataSource) {
    return CurationStageId.CURATED_ATTRIBUTES;
  } else {
    return CurationStageId.CURATED_SOURCES;
  }
};

const getInitialStageGuidedFromParams = (dataSource: string, attributeName: string) => {
  if (attributeName) {
    return CurationGuidedTourStageId.FIELDS;
  }
  if (dataSource) {
    return CurationGuidedTourStageId.ATTRIBUTES;
  } else {
    return CurationGuidedTourStageId.DATA_SOURCES;
  }
};

const getAttributeDataFromParams = (
  attributeName: string,
  attributeType: CuratedAttributeType,
  displayName: string,
) => {
  return {
    attributeName: attributeName ?? null,
    attributeType: attributeType ?? null,
    displayName: displayName ?? null,
  };
};

const getCurationInitialState = (
  dataSource: string,
  attributeName: string,
  attributeType: CuratedAttributeType,
  displayName: string,
): UseCurationState => {
  return {
    defaultInitialStageId: getInitialStageFromParams(dataSource, attributeName),
    attributesCurationStatus: null,
    fieldsCurationStatus: null,
    currentStageId: getInitialStageFromParams(dataSource, attributeName),
    currentCuratedAttribute: getAttributeDataFromParams(attributeName, attributeType, displayName),
    currentCuratedDataSource: { source: dataSource } ?? null,
    currentGuidedTour: getInitialStageGuidedFromParams(dataSource, attributeName),
    guidedTourStatus: {
      [CurationGuidedTourStageId.DATA_SOURCES]: CurationGuidedTourStageStatus.NOT_STARTED,
      [CurationGuidedTourStageId.ATTRIBUTES]: CurationGuidedTourStageStatus.NOT_STARTED,
      [CurationGuidedTourStageId.FIELDS]: CurationGuidedTourStageStatus.NOT_STARTED,
      [CurationGuidedTourStageId.PREVIEW]: CurationGuidedTourStageStatus.NOT_STARTED,
      [CurationGuidedTourStageId.COLUMN_PROFILE]: CurationGuidedTourStageStatus.NOT_STARTED,
      [CurationGuidedTourStageId.ADDITIONAL_ATTRIBUTES]: CurationGuidedTourStageStatus.NOT_STARTED,
    },
    isPageInitialised: false,
  };
};

const reducer: Reducer<UseCurationState, UseCurationStatePayload> = (state, { action, data }) => {
  switch (action) {
    case UseCurationStateAction.UPDATE_GUIDED_TOUR_CURRENT_STAGE: {
      return {
        ...state,
        currentGuidedTour: data.currentGuidedTour,
      };
    }
    case UseCurationStateAction.INITIALIZE_USER_PREFERENCES: {
      const { guidedTourStatus, defaultInitialStageId, isRootPage } = data;
      const updatedState = {
        ...state,
        guidedTourStatus: { ...state.guidedTourStatus, ...guidedTourStatus },
        defaultInitialStageId,
        isPageInitialised: true,
      };

      if (defaultInitialStageId && isRootPage) {
        updatedState.currentStageId = defaultInitialStageId;
      }

      return updatedState;
    }

    case UseCurationStateAction.UPDATE_GUIDED_TOUR_STATUS_BY_STAGE: {
      return {
        ...state,
        guidedTourStatus: { ...state.guidedTourStatus, ...data },
        isPageInitialised: true,
      };
    }
    case UseCurationStateAction.UPDATE_CURATION_WIZARD_STAGE: {
      const {
        currentCuratedAttribute,
        currentCuratedDataSource,
        currentStageId,
        attributesCurationStatus,
        fieldsCurationStatus,
      } = data;

      const guidedTourStageId = getGuidedTourKeyByCurationStateId(currentStageId);
      let updatedState = {
        ...state,
        currentStageId,
        isPageInitialised: true,
        currentGuidedTour: guidedTourStageId,
      };

      if (!isUndefined(currentCuratedAttribute)) {
        updatedState = { ...updatedState, currentCuratedAttribute };
      }

      if (!isUndefined(currentCuratedDataSource)) {
        updatedState = { ...updatedState, currentCuratedDataSource };
      }

      if (!isUndefined(attributesCurationStatus)) {
        updatedState = { ...updatedState, attributesCurationStatus };
      }

      if (!isUndefined(fieldsCurationStatus)) {
        updatedState = { ...updatedState, fieldsCurationStatus };
      }

      return updatedState;
    }
    case UseCurationStateAction.UPDATE_ATTRIBUTES_CURATION_STATUS: {
      const { attributesCurationStatus } = data;

      return { ...state, attributesCurationStatus };
    }
    case UseCurationStateAction.UPDATE_FIELDS_CURATION_STATUS: {
      const { fieldsCurationStatus } = data;

      return { ...state, fieldsCurationStatus };
    }
    case UseCurationStateAction.UPDATE_FIELDS_CURATION_STATUS_INCREMENT: {
      const { fieldsCurationStatus } = state;

      return {
        ...state,
        fieldsCurationStatus: {
          ...fieldsCurationStatus,
          curatedCount: fieldsCurationStatus.curatedCount + 1,
        },
      };
    }
    default:
      return state;
  }
};

export const useCurationState = ({
  scanProfileName,
  dataSource,
  attributeName,
  attributeType,
  displayName,
}: UseCurationStateProps): UseCurationStateResponse => {
  const [isReady, setIsReady] = useState(false);
  const initialState = getCurationInitialState(dataSource, attributeName, attributeType, displayName);
  const [
    {
      attributesCurationStatus,
      fieldsCurationStatus,
      currentStageId,
      currentCuratedAttribute,
      currentCuratedDataSource,
      guidedTourStatus,
      isPageInitialised,
      currentGuidedTour,
    },
    dispatch,
  ] = useReducer(reducer, initialState);

  const { t } = useLocalTranslation('Curation.common');

  const originState = useMemo((): HistoryItem => {
    const history: History[] = sessionStorageService.get('history') ?? [];
    let originState;

    if (history.length > 0) {
      const { to } = history.pop();
      originState = to;
    }

    return originState;
  }, []);

  const fetchAttributeCurationStatus = useCallback(async () => {
    try {
      const filter: BigidFilter = [];
      if (currentCuratedDataSource?.source) {
        filter.push({
          field: 'source',
          value: [currentCuratedDataSource?.source],
          operator: 'in',
        });
      }
      const filterQuery = parseFieldFiltersToSearchQuery(
        filter,
        Boolean(getApplicationPreference('NEW_QUERY_FILTER_ENABLED')),
      );

      const data: { data: GetAttributesCurationStatusResponse } = await getAttributeCurationStatus(filterQuery);
      const curationStatus: CurationStatus = data?.data?.curationStatus;

      dispatch({
        action: UseCurationStateAction.UPDATE_ATTRIBUTES_CURATION_STATUS,
        data: {
          attributesCurationStatus: curationStatus,
        },
      });
    } catch ({ message }) {
      notificationService.error(t('errors.fetchingAttributeCurationStatus'));
      console.error(`An error has occurred: ${message}`);
    }
  }, [currentCuratedDataSource?.source, t]);

  const fetchFieldsCurationStatus = useCallback(async () => {
    try {
      const filterQuery = getStatusBaseQuery(
        currentCuratedAttribute.attributeType,
        currentCuratedAttribute.attributeName,
        currentCuratedDataSource?.source,
      );
      const data: { data: GetFieldsCurationStatusResponse } = await getFieldsCurationStatus(filterQuery);
      const curationStatus: CurationStatus = data?.data?.curationStatus;

      dispatch({
        action: UseCurationStateAction.UPDATE_FIELDS_CURATION_STATUS,
        data: {
          fieldsCurationStatus: curationStatus,
        },
      });
    } catch ({ message }) {
      notificationService.error(t('errors.fetchingFieldsCurationStatus'));
      console.error(`An error has occurred: ${message}`);
    }
  }, [currentCuratedAttribute, currentCuratedDataSource?.source, t]);

  const onProceedToAttributeList = useCallback((curatedDataSource: CuratedDataSource): void => {
    $state.go(
      $state.current.name,

      {
        dataSource: curatedDataSource?.source,
        attributeName: null,
        attributeType: null,
        displayName: null,
        scanId: $stateParams.scanId,
      },
      {
        reload: false,
        notify: false,
      },
    );
  }, []);

  const onProceedToFieldsReview = useCallback(
    (curatedDataSource: CuratedDataSource, curatedAttribute: CuratedAttribute): void => {
      $state.go(
        $state.current.name,
        {
          dataSource: curatedDataSource?.source,
          attributeType: curatedAttribute.attributeType,
          attributeName: curatedAttribute.attributeName,
          displayName: curatedAttribute.displayName,
          scanId: $stateParams.scanId,
        },
        {
          reload: false,
          notify: false,
        },
      );
    },
    [],
  );

  const onBackToDataSourceList = useCallback(() => {
    $state.go(
      $state.current.name,

      { dataSource: null, attributeName: null, attributeType: null, displayName: null, scanId: $stateParams.scanId },
      {
        reload: false,
        notify: false,
      },
    );
  }, []);

  const onBackToAttributeList = useCallback(() => {
    $state.go(
      $state.current.name,

      {
        dataSource: currentCuratedDataSource?.source,
        attributeName: null,
        attributeType: null,
        displayName: null,
        scanId: $stateParams.scanId,
      },
      {
        reload: false,
        notify: false,
      },
    );
  }, [currentCuratedDataSource?.source]);

  const onBackToOriginPage = useCallback(() => {
    const { state, params } = originState;
    $state.go(state, params);
  }, [originState]);

  const goToScanPage = useCallback(() => {
    $state.go(CONFIG.states.SCANS_NEW_SCANS_COMPLETED);
  }, []);

  const onCurationGuidedTourStart = useCallback(
    (currentGuidedTour?: CurationGuidedTourStageId) => {
      if (currentGuidedTour) {
        onGuidedTourStageChanged(currentGuidedTour, CurationGuidedTourStageStatus.STARTED_MANUALLY);
      } else {
        onGuidedTourStageChanged(
          getGuidedTourKeyByCurationStateId(currentStageId),
          CurationGuidedTourStageStatus.STARTED_MANUALLY,
        );
      }
    },
    [currentStageId],
  );

  const onFieldReviewed = useCallback(
    (isBulk: boolean) => {
      if (isBulk) {
        fetchFieldsCurationStatus();
      } else {
        dispatch({
          action: UseCurationStateAction.UPDATE_FIELDS_CURATION_STATUS_INCREMENT,
        });
      }
    },
    [fetchFieldsCurationStatus],
  );

  const onGuidedTourFirstRun = useCallback(
    (currentGuidedTour: CurationGuidedTourStageId) => {
      if (guidedTourStatus[currentGuidedTour] === CurationGuidedTourStageStatus.NOT_STARTED) {
        onGuidedTourStageChanged(currentGuidedTour, CurationGuidedTourStageStatus.STARTED_AUTOMATICALLY);
      }
    },
    [guidedTourStatus],
  );

  const onProceedToFieldsReviewTab = useCallback(
    (currentGuidedTour: CurationGuidedTourStageId, openGuidedTour: boolean) => {
      if (openGuidedTour) {
        onGuidedTourFirstRun(currentGuidedTour);
        onUpdateGuidedTourCurrentStage(currentGuidedTour);
      }
    },
    [onGuidedTourFirstRun],
  );

  const onGuidedTourStageFinished = useCallback(
    (currentGuidedTour: CurationGuidedTourStageId) => {
      if (guidedTourStatus[currentGuidedTour] === CurationGuidedTourStageStatus.STARTED_AUTOMATICALLY) {
        const userNewPreferences = {
          guidedTourStatus: {
            ...guidedTourStatus,
            ...{ [currentGuidedTour]: CurationGuidedTourStageStatus.IS_AKNOWLEGED },
          },
        };
        setUserPreferences(userNewPreferences);
      }
      onGuidedTourStageChanged(currentGuidedTour, CurationGuidedTourStageStatus.IS_AKNOWLEGED);
    },
    [guidedTourStatus],
  );

  const setCurationWizardBreadcrumbs = useCallback(
    (currentStageId: CurationStageId, { onCurationGuidedTourStart }: CurationHeaderHelpIconProps): void => {
      const t = getFixedT('Curation.breadcrumbs');
      const breadcrumbs: Breadcrumb[] = [];

      switch (originState?.state) {
        case CONFIG.states.SCANS_NEW_SCANS_COMPLETED:
          breadcrumbs.push({
            label: t('completedScans'),
            onClick: onBackToOriginPage,
          });
          break;
        case CONFIG.states.SCANS_SCAN_INSIGHTS:
          breadcrumbs.push({
            label: t('scanProfile', { scanProfile: scanProfileName }),
            onClick: onBackToOriginPage,
          });
          break;
        case CONFIG.states.DATA_SOURCE_CONNECTIONS:
          breadcrumbs.push({
            label: t('dataSourcesPage'),
            onClick: onBackToOriginPage,
          });
          break;
      }

      switch (currentStageId) {
        case CurationStageId.CURATED_SOURCES:
          breadcrumbs.push({
            label: t('classifierTuning'),
          });
          break;
        case CurationStageId.CURATED_ATTRIBUTES:
          if (currentCuratedDataSource?.source) {
            breadcrumbs.push(
              {
                label: t('classifierTuning'),
                onClick: onBackToDataSourceList,
              },
              {
                label: t('onAttributeWithSource', { dataSource: currentCuratedDataSource.source }),
              },
            );
          } else {
            breadcrumbs.push({
              label: t('attributesWithNoDataSource'),
            });
          }
          break;
        case CurationStageId.CURATED_FIELDS:
          if (currentCuratedDataSource?.source) {
            breadcrumbs.push(
              {
                label: t('classifierTuning'),
                onClick: onBackToDataSourceList,
              },
              {
                label: t('onAttributeWithSource', { dataSource: currentCuratedDataSource.source }),
                onClick: onBackToAttributeList,
              },
              {
                label: t('reviewObjects', {
                  attributeName: currentCuratedAttribute.displayName,
                }),
              },
            );
          } else {
            breadcrumbs.push(
              {
                label: t('attributesWithNoDataSource'),
                onClick: onBackToAttributeList,
              },
              {
                label: t('reviewObjects', {
                  attributeName: currentCuratedAttribute.displayName,
                }),
              },
            );
          }
          break;
      }

      const helpIcon = curationPageTitleHelper({ onCurationGuidedTourStart });
      pageHeaderService.setTitle({
        breadcrumbs,
        ...helpIcon,
      });
    },
    [
      currentCuratedAttribute,
      currentCuratedDataSource?.source,
      onBackToAttributeList,
      onBackToDataSourceList,
      onBackToOriginPage,
      originState?.state,
      scanProfileName,
    ],
  );

  useEffect(() => {
    switch (currentStageId) {
      case CurationStageId.CURATED_ATTRIBUTES:
        fetchAttributeCurationStatus();
        break;
      case CurationStageId.CURATED_FIELDS:
        fetchFieldsCurationStatus();
        break;
    }
  }, [currentStageId, fetchAttributeCurationStatus, fetchFieldsCurationStatus]);

  useEffect(() => {
    async function getUserCurationPreferences() {
      const { guidedTourStatus, defaultInitialStageId } = await getUserPreferences();
      const isRootPage = !dataSource && !attributeType && !attributeName && !displayName;

      dispatch({
        action: UseCurationStateAction.INITIALIZE_USER_PREFERENCES,
        data: { guidedTourStatus, defaultInitialStageId, isRootPage },
      });
      setIsReady(true);
    }
    getUserCurationPreferences();
  }, [attributeName, attributeType, dataSource, displayName]);

  useEffect(() => {
    setCurationWizardBreadcrumbs(currentStageId, { onCurationGuidedTourStart });
  }, [currentStageId, onCurationGuidedTourStart, setCurationWizardBreadcrumbs]);

  // Due to a limitation with using angular state service, this is to handle "back" and "forward" in the browser
  useEffect(() => {
    const handleUrlChange = () => {
      const currentUrl = window.location.href;
      if (currentUrl.includes('/results-tuning') && isReady) {
        const queryStringIndex = currentUrl.indexOf('?');
        const urlParams =
          queryStringIndex !== -1 ? currentUrl.substring(queryStringIndex, currentUrl.length) : currentUrl;

        const params = new URLSearchParams(urlParams);

        const dataSourceParam = params.get('dataSource' || null);
        const attributeTypeParam = params.get('attributeType' || null);
        const attributeNameParam = params.get('attributeName' || null);
        const displayNameParam = params.get('displayName' || null);
        const scanId = params.get('scanId' || null);

        $state.go(
          $state.current.name,
          {
            dataSource: dataSourceParam,
            attributeName: attributeNameParam,
            attributeType: attributeTypeParam,
            displayName: displayNameParam,
            scanId: scanId,
          },
          {
            reload: true,
            notify: false,
          },
        );
        dispatch({
          action: UseCurationStateAction.UPDATE_CURATION_WIZARD_STAGE,
          data: {
            currentCuratedDataSource: { source: dataSourceParam },
            currentCuratedAttribute: getAttributeDataFromParams(
              attributeNameParam,
              attributeTypeParam as CuratedAttributeType,
              displayNameParam,
            ),
            currentStageId: getInitialStageFromParams(dataSourceParam, attributeNameParam),
          },
        });
      }
    };

    window.addEventListener('popstate', handleUrlChange);

    return () => {
      window.removeEventListener('popstate', handleUrlChange);
    };
  }, [isReady]);

  const onGuidedTourStageChanged = (
    currentGuidedTour: CurationGuidedTourStageId,
    shouldRun: CurationGuidedTourStageStatus,
  ) => {
    dispatch({
      action: UseCurationStateAction.UPDATE_GUIDED_TOUR_STATUS_BY_STAGE,
      data: { [currentGuidedTour]: shouldRun },
    });
  };

  const onUpdateGuidedTourCurrentStage = (currentGuidedTour: CurationGuidedTourStageId) => {
    dispatch({
      action: UseCurationStateAction.UPDATE_GUIDED_TOUR_CURRENT_STAGE,
      data: { currentGuidedTour: currentGuidedTour },
    });
  };

  const onCurationDefaultInitialStageSelect = (
    stageId: CurationStageId.CURATED_SOURCES | CurationStageId.CURATED_ATTRIBUTES,
  ): void => {
    setUserPreferences({ defaultInitialStageId: stageId });
    dispatch({
      action: UseCurationStateAction.UPDATE_CURATION_WIZARD_STAGE,
      data: {
        currentStageId: stageId,
        currentCuratedDataSource: null,
        currentCuratedAttribute: null,
      },
    });
  };

  return {
    currentStageId,
    originState,
    attributesCurationStatus,
    fieldsCurationStatus,
    currentCuratedAttribute,
    currentCuratedDataSource,
    onProceedToAttributeList,
    onProceedToFieldsReview,
    onProceedToFieldsReviewTab,
    onGuidedTourFirstRun,
    onBackToDataSourceList,
    onBackToAttributeList,
    onBackToOriginPage,
    goToScanPage,
    onFieldReviewed,
    guidedTourStatus,
    isPageInitialised,
    onGuidedTourStageFinished,
    currentGuidedTour,
    onCurationGuidedTourStart,
    onCurationDefaultInitialStageSelect,
  };
};
