import { BigidGridColumn, BigidGridColumnTypes, BigidGridWithToolbarProps } from '@bigid-ui/grid';
import { DataSourceModel } from '../../DataSources/DataSourceConnections/DataSourceConnectionTypes';
import { DataCatalogForDeletionModel } from './DataDeletionGlobalSettings';
import { getSupportedDataSources } from '../../../utilities/dataSourcesUtils';
import { actionTypes } from '../DataMinimizationObjects/DataMinimizationObjects';

export const dataMinimizationObjectsDefaultColumns: BigidGridColumn<DataCatalogForDeletionModel>[] = [
  {
    title: 'Object Name',
    name: 'objectName',
    type: BigidGridColumnTypes.TEXT,
    width: 200,
    sortingEnabled: false,
    getCellValue: ({ objectName }) => objectName,
    isListColumn: true,
  },
  {
    title: 'Data Source Name',
    name: 'source',
    width: 200,
    type: BigidGridColumnTypes.TEXT,
    sortingEnabled: false,
    getCellValue: ({ source }) => source,
  },
  {
    title: 'Schema Name',
    name: 'containerName',
    width: 200,
    type: BigidGridColumnTypes.TEXT,
    sortingEnabled: false,
    getCellValue: ({ containerName }) => containerName,
  },
  {
    title: 'Scanner type',
    name: 'type',
    width: 200,
    type: BigidGridColumnTypes.TEXT,
    sortingEnabled: false,
    getCellValue: ({ type }) => type,
  },
  {
    title: 'Marked as',
    name: 'markedAs',
    width: 250,
    type: BigidGridColumnTypes.TEXT,
    sortingEnabled: false,
    getCellValue: ({ markedAs }) => markedAs,
  },
  {
    title: 'Decided User',
    name: 'decidedUser',
    width: 250,
    type: BigidGridColumnTypes.TEXT,
    sortingEnabled: false,
    getCellValue: ({ decidedUser }) => decidedUser,
  },
  {
    title: 'Reason',
    name: 'reason',
    width: 400,
    type: BigidGridColumnTypes.TEXT,
    sortingEnabled: false,
    getCellValue: ({ reason }) => reason,
  },
];

export async function getDataDeletionGlobalSettingsObjectsFilterConfig(): Promise<
  BigidGridWithToolbarProps<DataSourceModel>['filterToolbarConfig']
> {
  const dataSources = await getSupportedDataSources();
  return {
    filters: [
      {
        title: 'Scanner Type',
        field: 'scannerType',
        operator: 'equal',
        single: true,
        disabled: true,
        options: dataSources.map(({ name, displayName }) => ({
          label: displayName || name,
          value: name.toLowerCase(),
          isSelected: false,
        })),
        value: [],
      },
      {
        title: 'Marked As',
        field: 'annotations.markedAs',
        operator: 'equal',
        disabled: true,
        single: true,
        value: [],
        options: [
          {
            label: 'Delete Automatically',
            value: actionTypes.DELETE_AUTO,
            isSelected: false,
          },
          {
            label: 'Delete Manually',
            value: actionTypes.DELETE_MANUAL,
            isSelected: false,
          },
          {
            label: 'Retained',
            value: actionTypes.RETAINED,
            isSelected: false,
          },
        ],
      },
    ],
    searchConfig: {
      searchFilterKeys: ['objectName'],
      placeholder: 'Object Name',
      initialValue: '',
      operator: 'equal',
    },
  };
}
