import React, { useCallback, useEffect, useRef } from 'react';
import { BigidFormRenderProps, ExtraFieldItemData, FormValue } from '@bigid-ui/components';
import { $transitions } from '../../../../services/angularServices';
import { useOnCloseHandler } from './useOnCloseHandler';
import { OnSubmitDataSourceType } from './useSubmitDataSource';
import { $q } from 'ngimport';
import { differenceBy, isEqual } from 'lodash';
import { DataSourceConnectionFormField, DsTypesEnum } from '../types';

export const useChangesMonitoring = (
  initialValues: Record<string, string | number | string[] | Record<string, FormValue>>,
  onSave: OnSubmitDataSourceType,
  getFieldPropsFunction: React.MutableRefObject<BigidFormRenderProps['getFieldProps']>,
  fields: DataSourceConnectionFormField[],
  saveError?: string,
  disableSaveBeforeUnload = false,
) => {
  const isTouched = useRef(false);
  const isError = useRef(saveError);
  isError.current = saveError;
  const getValuesContainer = useRef<BigidFormRenderProps['getValues'] | undefined>();
  const onCloseDSConfig = useOnCloseHandler();

  const fieldsWithInitialValues = fields.map(field => ({
    ...field,
    initValue: initialValues?.[field?.name],
  }));

  const checkIfSaveNeeded = useCallback(() => {
    if (!isTouched.current && !isError.current) return { isNeedSave: false, isErrors: false };

    const values = getValuesContainer?.current() || {};

    return fieldsWithInitialValues?.reduce(
      ({ isNeedSave, isErrors }, fieldWithInitValue) => {
        const {
          name,
          initValue,
          misc: { type },
        } = fieldWithInitValue;
        const previousValue = initValue;
        const currentValue = values[name];
        return {
          isNeedSave: isNeedSave || checkIsFieldDirty(previousValue, currentValue, type),
          isErrors: Boolean(isErrors || getFieldPropsFunction?.current(name)?.error),
        };
      },
      { isNeedSave: false, isErrors: false },
    );
  }, [fieldsWithInitialValues, getFieldPropsFunction]);

  useEffect(() => {
    if (disableSaveBeforeUnload) {
      return;
    }

    const handler = (event: BeforeUnloadEvent) => {
      if (checkIfSaveNeeded()?.isNeedSave) {
        event.preventDefault();
        event.returnValue = '';
        return 'Do you want to save changes on this Data Source before leaving?';
      }
      return '';
    };

    window.addEventListener('beforeunload', handler, true);
    return () => {
      window.removeEventListener('beforeunload', handler, true);
    };
  }, [checkIfSaveNeeded, disableSaveBeforeUnload]);

  useEffect(() => {
    if (disableSaveBeforeUnload) {
      return;
    }

    const deregisterLeaveFormInterceptor = $transitions.onBefore({}, transition => {
      return new $q(resolve => {
        let isSaving = false;
        const { isNeedSave, isErrors } = checkIfSaveNeeded();
        if (isNeedSave && transition.params()?.abortDisabled !== 'true') {
          onCloseDSConfig({
            isError: Boolean(isErrors || isError.current),
            onSave: () => {
              isSaving = true;
              onSave()
                .then(result => {
                  resolve(result);
                })
                .catch(() => {
                  resolve(false);
                });
            },
            onNotSave: () => {
              resolve();
            },
            onClose: () => {
              !isSaving && resolve(false);
            },
          });
        } else {
          resolve();
        }
      }) as Promise<boolean>;
    });

    return () => {
      deregisterLeaveFormInterceptor();
    };
  }, [checkIfSaveNeeded, onSave, onCloseDSConfig]);

  return {
    isTouched,
    getValuesContainer,
    checkIfSaveNeeded,
  };
};

function isExtraFieldItemChanged(
  extraFieldsCurrent: ExtraFieldItemData[],
  extraFieldsInitial: ExtraFieldItemData[],
  type: string,
) {
  const extraFieldsInitialValues = extraFieldsInitial?.map(extraField => {
    return extraField && Array.isArray(extraField) ? extraField.value?.[0] : extraField;
  });

  const extraFieldsCurrentValues = extraFieldsCurrent?.reduce((valueAcc: ExtraFieldItemData[], item) => {
    const resultValue = item?.value ?? item;
    const hasObjectValue = resultValue?.[0]?.value?.id || resultValue.field_value || resultValue.field_name;
    return resultValue && (typeof resultValue === 'string' || (typeof resultValue === 'object' && hasObjectValue))
      ? Array.isArray(resultValue)
        ? [...valueAcc, ...resultValue]
        : [...valueAcc, resultValue]
      : valueAcc;
  }, []);

  if (type === DsTypesEnum.array) {
    return (
      Boolean(differenceBy(extraFieldsInitialValues, extraFieldsCurrentValues, 'id').length) ||
      extraFieldsCurrentValues.length !== extraFieldsInitialValues?.length
    );
  } else {
    return (
      !isEqual(
        normalizeCustomArrayValueType(extraFieldsInitialValues),
        normalizeCustomArrayValueType(extraFieldsCurrentValues),
      ) || extraFieldsCurrentValues.length !== extraFieldsInitialValues.length
    );
  }
}

function checkIsFieldDirty(initialValue: FormValue, currentValue: FormValue, type: DsTypesEnum) {
  if (type === DsTypesEnum.array || type === DsTypesEnum.customFieldsArray) {
    return !isEqual(initialValue, currentValue) && isExtraFieldItemChanged(currentValue, initialValue, type);
  }

  if (typeof initialValue === 'object' || typeof initialValue === 'string') {
    return !isEqual(initialValue, currentValue);
  }

  return Boolean(currentValue) !== Boolean(initialValue);
}

function normalizeCustomArrayValueType(fieldValuesToNormalize: ExtraFieldItemData[]) {
  return fieldValuesToNormalize.map(value =>
    typeof value.field_type === 'string' ? value : { ...value, field_type: value.field_type?.[0]?.value },
  );
}
