import {
  DashboardCommonContainer,
  BigidHeading6,
  BigidChartLegendPosition,
  BigidLineChart,
  BigidLineChartProps,
  BigidLineChartDataItem,
  BigidColorsV2,
  BigidLoader,
} from '@bigid-ui/components';
import React, { useEffect, useState } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { notificationService } from '../../../../react/services/notificationService';
import { generateDataAid } from '@bigid-ui/utils';
import { httpService } from '../../../../react/services/httpService';

const useStyles = makeStyles({
  main: {
    minHeight: 466,
    padding: '12px 16px',
    display: 'grid',
    gridTemplateRows: '24px 1fr',
    rowGap: 20,
  },
  headerWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  contentWrapper: {
    display: 'grid',
    alignItems: 'center',
    rowGap: 16,
    justifyItems: 'stretch',
    columnGap: 0,
    fontSize: 10,
  },
});

export const CasesTrendWidget = () => {
  const classes = useStyles();

  const defaultWidgetSchema: BigidLineChartProps = {
    titles: {
      main: 'Last 30 Days',
      yAxis: 'Date',
      opened: 'Open',
      closed: 'Resolved',
    },
    minValue: 0,
    maxValue: {
      opened: 1000,
      closed: 1000,
    },
    notShowBulletIfSameValue: true,
    skipEmptyPeriods: false,
    colors: {
      opened: BigidColorsV2.cyan[400],
      closed: BigidColorsV2.magenta[700],
    },
    series: ['opened', 'closed'],
    legend: BigidChartLegendPosition.LEFT,
    tooltip: true,
    data: [],
    isSmoothedLine: true,
  };

  const [allTrendCases, setAllTrendCases] = useState<BigidLineChartProps>(defaultWidgetSchema);
  const [isLoading, setIsLoading] = useState(false);

  const daysCountLogic = (data: any[]) => {
    const daysCount = data?.length;

    if (daysCount === 1) {
      return `Last one day`;
    }
    if (data?.length > 1) {
      return `Last ${daysCount} days`;
    }
  };

  useEffect(() => {
    const requestTrendCases = async () => {
      setIsLoading(true);

      try {
        const {
          data: { data },
        } = await httpService.fetch('actionable-insights/trends');

        const requestedData: Record<string, any> = {
          opened: data?.open,
          closed: data?.closed,
          max: data.max,
        };

        setAllTrendCases((prevState: BigidLineChartProps) => {
          const result = {
            ...prevState,
            titles: { ...prevState.titles, main: daysCountLogic(data?.open) },
            maxValue: {
              opened: requestedData.max,
              closed: requestedData.max,
            },
          };

          for (const key in requestedData) {
            if (key !== 'max') {
              requestedData[key].map((el: BigidLineChartDataItem) => {
                const existedDateIndex = result.data.findIndex(
                  (element: BigidLineChartDataItem) => new Date(element.date).getTime() === new Date(el.date).getTime(),
                );
                if (existedDateIndex === -1) {
                  result.data = [
                    ...result.data,
                    {
                      date: new Date(el.date),
                      [key]: el.value,
                    },
                  ];
                } else {
                  result.data[existedDateIndex] = {
                    ...result.data[existedDateIndex],
                    [key]: el.value,
                  };
                }
              });
            }
          }

          return result;
        });

        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
        notificationService.error('Error in fetching trend cases');
        console.log('Error in fetching trend cases');
      }
    };
    requestTrendCases();
  }, []);

  return (
    <DashboardCommonContainer
      dataAid={generateDataAid('BigidDashboardWidget', ['case-trends'])}
      className={classes.main}
    >
      <div className={classes.headerWrapper}>
        <BigidHeading6>Case Trends</BigidHeading6>
      </div>
      <div className={classes.contentWrapper}>
        {isLoading ? <BigidLoader position="relative" /> : <BigidLineChart {...allTrendCases} />}
      </div>
    </DashboardCommonContainer>
  );
};
