import './agreements.component.scss';
import { module } from 'angular';
import template from './agreements.component.html';
import agreementsPurposeOfUseModalTemplate from './agreements-purposeOfUseModal.html';
import { CONSENT_GOVERNANCE_PERMISSIONS } from '@bigid/permissions';
import { isPermitted } from '../../react/services/userPermissionsService';
const app = module('app');

app.component('agreements', {
  template,
  controller: function (
    notificationService,
    $translate,
    agreementsService,
    $document,
    DeleteConfirmation,
    $timeout,
    systemUsersService,
    mentionsTextareaService,
    $uibModal,
    $sce,
  ) {
    'ngInject';

    const TRANSLATIONS_REQUIRED = [
      'AGREEMENTS:TOOLTIP:NAME',
      'AGREEMENTS:TOOLTIP:WEB_ADDRESS',
      'AGREEMENTS:TOOLTIP:VALID_DATE',
      'AGREEMENTS:TOOLTIP:OWNER_NAME',
      'AGREEMENTS:TOOLTIP:OWNER_EMAIL',
      'AGREEMENTS:TOOLTIP:LEGITIMACY_OF_USE',
      'AGREEMENTS:TOOLTIP:PURPOSES_OF_USE',
      'AGREEMENTS:TOOLTIP:ADD_PURPOSE',
      'AGREEMENTS:TOOLTIP:CONSENT',
    ];

    const legitimacyOfUse = [
      { id: '1', name: 'Consent' },
      { id: '2', name: 'Performance of contract' },
      { id: '3', name: 'Compliance with legal obligation' },
      { id: '4', name: 'Protection of vital interests' },
      { id: '5', name: 'Public interest' },
      { id: '6', name: 'Legitimate use' },
      { id: '7', name: 'Other' },
    ];

    this.agreementsContentBodyScrollConfig = {
      wheelPropagation: true,
    };

    this.attributesDropdownSettings = {
      showCheckAll: false,
      showUncheckAll: false,
      checkBoxes: true,
      selectedToTop: true,
      displayProp: 'friendly_name',
      idProperty: 'friendly_name',
      enableSearch: true,
      smartButtonMaxItems: 2,
      smartButtonTextConverter: function (itemText) {
        return itemText;
      },
      template: '<span title="{{option.friendly_name}}">{{option.friendly_name}}</span>',
    };

    this.consentDropdownSettings = {
      showCheckAll: false,
      showUncheckAll: false,
      checkBoxes: true,
      displayProp: 'name',
      idProperty: 'name',
      smartButtonMaxItems: 2,
      smartButtonTextConverter: function (itemText) {
        return itemText;
      },
      template: '<span title="{{option.name}}">{{option.name}}</span>',
    };

    const AGREEMENTS_DATA_LIST_ID = 'agreementsDataList';
    const AGREEMENTS_CONTENT_BODY_ID = 'agreementsContentBody';
    const AGREEMENTS_DATA_LIST_ITEM_ID = 'agreement';

    const dataListContainer = $document[0].getElementById(AGREEMENTS_DATA_LIST_ID);
    const contentBodyContainer = $document[0].getElementById(AGREEMENTS_CONTENT_BODY_ID);

    const getSystemUsers = () => systemUsersService.getAllSystemUsers();

    let isFormDirty = false;

    this.agreements = [];
    this.agreementsAttributes = [];
    this.agreement = {};
    this.agreementForm = {};
    this.userQuery = '';
    this.editModeOn = false;
    this.createModeOn = false;
    this.legitimacy = {};
    this.legitimacyOfUse = legitimacyOfUse;
    this.isStepTwoEnabled = this.agreement.agreementName;
    this.isStepThreeEnabled = false;
    this.isStepFourEnabled = false;
    this.isStepFiveEnabled = false;
    this.attributesSections = [];
    this.attributesAndPurposesNumber = [];
    this.agreementOwners = [];
    this.agreementsPurposes = [];
    this.agreementsConsentConnections = [];
    this.attributesAndPurposesNumber[0] = 1;
    this.datePickPopUp = { opened: false };

    this.stepsTooltips = [];

    const setTooltipTemplate = items => {
      if (items.length === 1) {
        return `
                  <span>${items[0]}</span>
                `;
      } else {
        return `
                  <ul>
                    ${items.reduce((aggregator, item) => aggregator + `<li>${item}</li>`, '')}
                  </ul>
                `;
      }
    };

    const getAgreements = () => agreementsService.getAgreements();

    this.onAttributeValueChanged = () => {
      this.isStepTwoEnabled = this.agreement.agreementName;
      this.isStepThreeEnabled = this.agreement.agreementOwnerName && this.agreement.agreementOwnerEmail;
      isFormDirty = true;
    };

    const fetchAgreements = (scrollTo = '', entityToProceed = null) => {
      this.onDataLoading();

      agreementsService.getAgreements().then(
        response => {
          this.agreements = response.data.agreements.sort(alphaSort);
          initSections();
          this.onDataLoaded();

          if (!this.agreements || this.agreements.length <= 0) {
            onCreateAgreement();
          }

          if (entityToProceed !== null) {
            if (Object.keys(entityToProceed).length === 0 && entityToProceed.constructor === Object) {
              onCreateAgreement();
            } else {
              onEditAgreement(entityToProceed);
            }
          } else {
            if (this.editModeOn) {
              resetListSelection();
              setListSelection(this.agreement.agreementName);
            }

            $timeout(() => {
              const index = this.agreements.findIndex(
                  classifier => classifier.agreementName === this.agreement.agreementName,
                ),
                dataListItem = $document[0].getElementById(`${AGREEMENTS_DATA_LIST_ITEM_ID}-${index}`);

              if (dataListItem !== null && index !== -1) {
                dataListContainer.scrollTop = index * dataListItem.offsetHeight;
              }
            }, 0);
          }

          $timeout(() => {
            if (dataListContainer != null) {
              if (dataListContainer.className.indexOf('ps-container') > 0) {
                switch (scrollTo) {
                  case 'top':
                    dataListContainer.scrollTop = 0;
                    break;
                  case 'bottom':
                    dataListContainer.scrollTop = dataListContainer.scrollHeight;
                    break;
                  default:
                    if (entityToProceed !== null) {
                      if (Object.keys(entityToProceed).length === 0 && entityToProceed.constructor === Object) {
                        dataListContainer.scrollTop = 0;
                      } else {
                        const index = this.agreements.findIndex(classifier => classifier._id === entityToProceed._id),
                          dataListItem = $document[0].getElementById(`${AGREEMENTS_DATA_LIST_ITEM_ID}-${index}`);

                        if (dataListItem !== null && index !== -1) {
                          dataListContainer.scrollTop = index * dataListItem.offsetHeight;
                        }
                      }
                    }
                }
              }
            }
          }, 0);
        },
        () => {
          $translate('API:MESSAGE:COMMON_ERROR').then(translation => {
            notificationService.error(translation);
          });

          this.onDataLoaded();
        },
      );
    };

    const updateAgreement = (id, data, entityToProceed = null) => {
      agreementsService.updateAgreement(id, data).then(
        response => {
          if (response.data.agreements.isModified) {
            $translate('API:MESSAGE:COMMON_PUT_SUCCESS').then(translation => {
              notificationService.success(translation);
            });

            if (contentBodyContainer !== null) contentBodyContainer.scrollTop = 0;

            isFormDirty = false;
            this.agreementForm.$submitted = false;

            if (this.formOnly) this.onFormSubmitted({ isUpdated: true });
            else fetchAgreements('', entityToProceed);
          } else {
            $translate('API:MESSAGE:COMMON_ERROR').then(translation => {
              notificationService.error(`${translation} ${response.data.message}`);
            });
          }
        },
        () => {
          $translate('API:MESSAGE:COMMON_ERROR').then(translation => {
            notificationService.error(translation);
          });
        },
      );
    };

    const createAgreement = (data, entityToProceed = null) => {
      agreementsService.createAgreement(data).then(
        response => {
          if (response.data.agreements) {
            $translate('API:MESSAGE:COMMON_PUT_SUCCESS').then(translation => {
              notificationService.success(translation);
            });

            if (contentBodyContainer !== null) contentBodyContainer.scrollTop = 0;

            isFormDirty = false;
            this.agreementForm.$submitted = false;

            if (this.formOnly) {
              this.onFormSubmitted({ isUpdated: true });
            } else {
              this.editModeOn = true;
              this.createModeOn = false;

              fetchAgreements('', entityToProceed);
            }
            if (this.agreement && !this.agreement.id) {
              this.agreement.id = response.data.agreements.agreementId;
            }
          } else {
            $translate('API:MESSAGE:COMMON_ERROR').then(translation => {
              notificationService.error(`${translation} ${response.data.message}`);
            });
          }
        },
        () => {
          $translate('API:MESSAGE:COMMON_ERROR').then(translation => {
            notificationService.error(translation);
          });
        },
      );
    };

    const deleteAgreement = id => {
      agreementsService.deleteAgreement(id).then(
        () => {
          $translate('API:MESSAGE:COMMON_DELETE_SUCCESS').then(translation => {
            notificationService.success(translation);
          });
          isFormDirty = false;
          this.editModeOn = false;
          this.createModeOn = false;
          this.agreement = {};

          if (this.formOnly) {
            this.onFormSubmitted({ isUpdated: true });
          } else {
            fetchAgreements('top');
          }
        },
        () => {
          $translate('API:MESSAGE:COMMON_ERROR').then(translation => {
            notificationService.error(translation);
          });
        },
      );
    };

    const getAgreementForSave = agreement => {
      prepareAttributesToSave(agreement);
      prepareConnectionsForSave(agreement);
      const payload = Object.assign({}, agreement);
      return payload;
    };

    const prepareConnectionsForSave = agreement => {
      if (agreement && agreement.consentConnection && agreement.consentConnection.length > 0) {
        agreement.consentConnection = agreement.consentConnection.map(consent => ({ name: consent.name }));
      }
    };

    const prepareAttributesToSave = agreement => {
      const flatAttributes = [];
      if (agreement) {
        for (let i = 0; i < agreement.attributesSectionsNumber.length; i++) {
          if (
            agreement.attributesSectionsNumber[i].purposes &&
            agreement.attributesSectionsNumber[i].purposes.length > 0
          ) {
            const att = {};
            for (let j = 0; j < agreement.attributesSectionsNumber[i].purposes.length; j++) {
              const currAtts = agreement.attributesSectionsNumber[i].attributes[j];
              if (!att.purposes) {
                att.purposes = [];
              }
              att.type = agreement.attributesSectionsNumber[i]['type'];
              const currPurpose = agreement.attributesSectionsNumber[i].purposes[j];
              att.purposes.push({ purpose: currPurpose, attributes: currAtts });
            }
            flatAttributes.push(att);
          }
        }
      }
      agreement.attributes = flatAttributes;
    };

    const initSteps = () => {
      if (this.agreement) {
        this.isStepTwoEnabled = this.agreement.agreementName;
        this.isStepThreeEnabled =
          this.isStepTwoEnabled && this.agreement.agreementOwnerName && this.agreement.agreementOwnerEmail;
        this.isStepFourEnabled = this.isStepThreeEnabled && this.agreement.id;
      }
    };

    const alphaSort = (a, b) => {
      const nameA = a.agreementName.toLowerCase();
      const NameB = b.agreementName.toLowerCase();
      if (nameA < NameB) {
        return -1;
      } else if (nameA > NameB) {
        return 1;
      } else {
        return 0;
      }
    };

    const initializeData = async callback => {
      try {
        const agreementsResponse = await getAgreements();
        const agreements = agreementsResponse.data.agreements;
        return callback(agreements);
      } catch (err) {
        $translate('API:MESSAGE:COMMON_ERROR').then(translation => {
          notificationService.error(translation);
        });

        return callback([]);
      }
    };

    const onUserLeavesForm = async (resolveCallback, rejectCallback) => {
      const closeButtonText = await $translate('BUTTONS:NO');
      const actionButtonText = await $translate('BUTTONS:YES');
      const headerText = await $translate('APP:COMMON:LEAVE_CONFIRMATION_HEADER');
      const bodyText = await $translate('APP:COMMON:LEAVE_CONFIRMATION_TEXT');

      const modalOptions = { closeButtonText, actionButtonText, headerText, bodyText };

      DeleteConfirmation.showModal({}, modalOptions).then(resolveCallback, rejectCallback);
    };

    const onSubmitAgreement = (entityToProceed = null) => {
      const payload = getAgreementForSave(this.agreement);
      if (typeof payload.selected != 'undefined') {
        delete payload.selected;
      }
      isFormDirty = false;
      switch (true) {
        case this.createModeOn:
          createAgreement(payload, entityToProceed);
          break;
        case this.editModeOn:
          updateAgreement(payload.id, payload, entityToProceed);
          break;
      }
    };

    const createEmptySection = () => {
      const section = [];
      section['type'] = this.legitimacyOfUse[0].name;
      section.purposes = [];
      section.purposes.push({});
      section.attributes = [];
      section.attributes.push([]);
      return section;
    };

    const onCreateAgreement = () => {
      resetListSelection();
      this.agreement = {};
      this.agreement.attributesSectionsNumber = [];
      this.agreement.attributesSectionsNumber.push(createEmptySection());
      this.agreement.consentConnection = [];
      this.agreement.enabled = true;
      this.isStepTwoEnabled = this.isStepThreeEnabled = this.isStepFourEnabled = this.isStepFiveEnabled = false;
      this.createModeOn = true;
      this.editModeOn = false;
      resetFormState();
    };

    const onEditAgreement = agreement => {
      resetListSelection();
      setListSelection(agreement.agreementName);
      this.agreement = Object.assign({}, agreement);
      initSteps();
      this.editModeOn = true;
      this.createModeOn = false;
      resetFormState();
    };

    const resetFormState = () => {
      if (
        typeof this.agreementForm != 'undefined' &&
        typeof this.agreementForm.$setPristine == 'function' &&
        typeof this.agreementForm.$setUntouched == 'function'
      ) {
        this.agreementForm.$setPristine();
        this.agreementForm.$setUntouched();
      } else {
        this.ifierForm = {};
      }

      this.agreementForm.$submitted = false;

      isFormDirty = false;
    };

    const resetListSelection = (except = '') => {
      for (let i = 0, len = this.agreements.length; i < len; i++) {
        if (this.agreements[i].agreementName === except) continue;
        this.agreements[i]['selected'] = false;
      }
    };

    const setListSelection = name => {
      for (let i = 0, len = this.agreements.length; i < len; i++) {
        if (this.agreements[i].agreementName === name) {
          this.agreements[i]['selected'] = true;
          break;
        }
      }
    };

    this.onEdit = agreement => {
      if (this.agreement.agreementName !== agreement.agreementName) {
        if (this.isEditPermitted && isFormDirty) {
          onUserLeavesForm(
            () => {
              this.agreementForm.$submitted = true;

              onSubmitAgreement(agreement);
            },
            () => {
              onEditAgreement(agreement);
            },
          );
        } else {
          onEditAgreement(agreement);
        }
      }
    };

    this.onCreate = () => {
      if (isFormDirty) {
        onUserLeavesForm(
          () => {
            this.agreementForm.$submitted = true;

            onSubmitAgreement({});
          },
          () => {
            onCreateAgreement();
          },
        );
      } else {
        onCreateAgreement();
      }
    };

    this.onDelete = async agreement => {
      const closeButtonText = await $translate('BUTTONS:CLOSE');
      const actionButtonText = await $translate('BUTTONS:DELETE');
      const headerText = await $translate('APP:COMMON:DELETE_CONFIRMATION_HEADER', {
        entityName: agreement.agreementName,
      });
      const bodyText = await $translate('APP:COMMON:DELETE_CONFIRMATION_TEXT', { entityName: agreement.agreementName });

      const modalOptions = {
        closeButtonText: closeButtonText,
        actionButtonText: actionButtonText,
        headerText: headerText,
        bodyText: bodyText,
      };

      DeleteConfirmation.showModal({}, modalOptions).then(() => deleteAgreement(agreement.id));
    };

    this.onSubmit = () => {
      onSubmitAgreement();
    };

    this.onSwitcherToggled = (isEnabled, ...rest) => {
      const agreement = rest[0];
      agreement.enabled = isEnabled;
      const payload = getAgreementForSave(agreement);
      if (typeof payload.selected != 'undefined') {
        delete payload.selected;
      }
      updateAgreement(agreement.id, payload);
    };

    this.onClose = () => {
      this.editModeOn = false;
      this.createModeOn = false;

      resetListSelection();

      if (this.formOnly) this.onFormClosed();
    };

    this.onFormChanged = () => {
      isFormDirty = true;
    };

    this.filterAgreements = query => {
      if (!query) return;
      return agreement =>
        agreement.agreementName && agreement.agreementName.toLowerCase().indexOf(query.toLowerCase()) !== -1
          ? true
          : false;
    };

    this.filterPurposes = (purposes, selectedPurpose) => {
      if (!purposes) return;
      const index = purposes.indexOf(selectedPurpose);
      const purposesToFilter = purposes.slice();
      if (index !== -1) {
        purposesToFilter.splice(index, 1);
      }
      const purposesIntersection = this.agreementsPurposes.filter(p => -1 !== purposesToFilter.indexOf(p.name));
      return purpose => (purposesIntersection.indexOf(purpose) !== -1 ? false : true);
    };

    this.onAddAttribute = () => {
      this.agreement.attributesSectionsNumber.push(createEmptySection());
      isFormDirty = true;
    };

    this.onRemovePurposeAndAttribute = (sectionIndex, rowIndex) => {
      this.agreement.attributesSectionsNumber[sectionIndex].purposes.splice(rowIndex, 1);
      this.agreement.attributesSectionsNumber[sectionIndex].attributes.splice(rowIndex, 1);
      isFormDirty = true;
    };

    this.onAddPurposeAndAttribute = index => {
      this.agreement.attributesSectionsNumber[index].purposes.push('');
      this.agreement.attributesSectionsNumber[index].attributes.push([]);
      isFormDirty = true;
    };

    this.onDeleteSection = index => {
      this.agreement.attributesSectionsNumber.splice(index, 1);
      isFormDirty = true;
    };

    this.onAttributeSelect = (rowIndex, fieldIndex) => {
      this.isStepFourEnabled =
        this.agreement.attributesSectionsNumber.length > 1 ||
        (!angular.equals(this.agreement.attributesSectionsNumber[rowIndex].purposes[fieldIndex], {}) &&
          !angular.equals(this.agreement.attributesSectionsNumber[rowIndex].attributes[fieldIndex], {}));
      isFormDirty = true;
    };

    this.markFormAsDirty = () => {
      isFormDirty = true;
    };

    const getOwners = async callback => {
      try {
        const ownersResponse = await getSystemUsers();
        return callback(ownersResponse);
      } catch (err) {
        $translate('API:MESSAGE:COMMON_ERROR').then(translation => {
          notificationService.error(translation);
        });

        return callback([]);
      }
    };

    const getAttributes = async callback => {
      try {
        const attributesResponse = await agreementsService.getAttributes();
        return callback(attributesResponse);
      } catch (err) {
        $translate('API:MESSAGE:COMMON_ERROR').then(translation => {
          notificationService.error(translation);
        });
        return callback([]);
      }
    };

    const getConsentConnections = () => {
      agreementsService.getConsentConnectionsData().then(result => {
        this.agreementsConsentConnections = result;
      });
    };

    this.openDatePicker = () => {
      this.datePickPopUp.opened = true;
    };

    const getPurposes = () => {
      mentionsTextareaService.getPurposes().then(
        result => {
          this.agreementsPurposes = result.data;
          if (!this.agreementsPurposes || this.agreementsPurposes.length <= 0) {
            this.onMissingPurposesModal();
          }
        },
        () => {
          notificationService.error('An error has occurred!');
        },
      );
    };

    const initSections = () => {
      if (this.agreements && this.agreements.length > 0) {
        this.agreements.forEach(agreement => {
          if (!agreement.attributes || agreement.attributes.length <= 0) {
            agreement.attributesSectionsNumber.push({});
          }
          for (let i = 0; i < agreement.attributes.length; i++) {
            const sectionObj = [];
            sectionObj.purposes = [];
            sectionObj.attributes = [];
            sectionObj['type'] = agreement.attributes[i].type;
            for (let j = 0; j < agreement.attributes[i].purposes.length; j++) {
              sectionObj.purposes.push(agreement.attributes[i].purposes[j].purpose);
              sectionObj.attributes.push(agreement.attributes[i].purposes[j].attributes);
            }
            if (!agreement.attributesSectionsNumber) {
              agreement.attributesSectionsNumber = [];
            }
            agreement.attributesSectionsNumber.push(sectionObj);
          }
        });
      }
    };

    this.onMissingPurposesModal = () => {
      $uibModal.open({
        template: agreementsPurposeOfUseModalTemplate,
        windowClass: 'configure-purpose-of-use',
        controller: [
          '$scope',
          '$uibModalInstance',
          function ($scope, $uibModalInstance) {
            Object.assign($scope, {
              close: () => {
                $uibModalInstance.close();
              },
            });
          },
        ],
      });
    };

    this.sortAttributes = (a, b) => {
      const att1 = a.friendly_name.toUpperCase();
      const att2 = b.friendly_name.toUpperCase();
      return att1 < att2 ? -1 : att1 > att2 ? 1 : 0;
    };

    this.$onInit = () => {
      this.isCreatePermitted = isPermitted(CONSENT_GOVERNANCE_PERMISSIONS.CREATE_AGREEMENT.name);
      this.isEditPermitted = isPermitted(CONSENT_GOVERNANCE_PERMISSIONS.EDIT_AGREEMENT.name);
      this.isDeletePermitted = isPermitted(CONSENT_GOVERNANCE_PERMISSIONS.DELETE_AGREEMENT.name);

      $translate(TRANSLATIONS_REQUIRED).then(translations => {
        const stepOneTooltipItems = [
          translations['AGREEMENTS:TOOLTIP:NAME'],
          translations['AGREEMENTS:TOOLTIP:WEB_ADDRESS'],
          translations['AGREEMENTS:TOOLTIP:VALID_DATE'],
        ];
        const stepTwoTooltipItems = [
          translations['AGREEMENTS:TOOLTIP:OWNER_NAME'],
          translations['AGREEMENTS:TOOLTIP:OWNER_EMAIL'],
        ];
        const stepThreeTooltipItems = [
          translations['AGREEMENTS:TOOLTIP:LEGITIMACY_OF_USE'],
          translations['AGREEMENTS:TOOLTIP:PURPOSES_OF_USE'],
          translations['AGREEMENTS:TOOLTIP:ADD_PURPOSE'],
        ];
        const stepFourTooltipItems = [translations['AGREEMENTS:TOOLTIP:CONSENT']];

        this.stepsTooltips = [
          $sce.trustAsHtml(setTooltipTemplate(stepOneTooltipItems)),
          $sce.trustAsHtml(setTooltipTemplate(stepTwoTooltipItems)),
          $sce.trustAsHtml(setTooltipTemplate(stepThreeTooltipItems)),
          $sce.trustAsHtml(setTooltipTemplate(stepFourTooltipItems)),
        ];

        if (this.formOnly) {
          if (typeof this.formData != 'undefined') {
            this.agreement = Object.assign({}, this.formData);
            initSteps();
            this.editModeOn = true;
            this.createModeOn = false;
          } else {
            this.editModeOn = false;
            this.createModeOn = true;
          }

          resetFormState();
        } else {
          this.onDataLoading();
          initializeData(agreements => {
            this.agreements = agreements.sort(alphaSort);
            initSections();
            if (this.agreements.length > 0) {
              onEditAgreement(this.agreements[0]);
            } else {
              onCreateAgreement();
            }

            this.onDataLoaded();
          });
          getOwners(response => {
            if (response) {
              this.agreementOwners = response.data;
            }
          });
          getAttributes(response => {
            if (response) {
              this.agreementsAttributes = response.agreements;
              this.agreementsAttributes = this.agreementsAttributes.sort(this.sortAttributes);
            }
          });
          getPurposes();
          getConsentConnections();
        }
      });
    };
  },
  bindings: {
    formOnly: '=',
    formData: '<',
    onFormClosed: '&',
    onFormSubmitted: '&',
    onDataLoading: '&',
    onDataLoaded: '&',
  },
});
