import { httpService } from '../../../../../services/httpService';
import { notificationService } from '../../../../../services/notificationService';
import { CuratedAttribute } from '../../../curationService';
import { getFixedT } from '../../../translations';
import { CreateCategory } from '../../../../../components/AssignCategoryDialog/types';

export interface CategoryCreationObject {
  display_name: string;
  unique_name: string;
  description: string;
  color: string;
}

export type CategoryAssignmentObject = Pick<CategoryCreationObject, 'unique_name' | 'display_name'>;

interface AssignCategoryResponse {
  category: CategoryAssignmentObject;
  original_name: string;
}

interface AssignCategoryRequest {
  original_name: string;
  categories: CategoryAssignmentObject[];
}

const t = getFixedT('AssignCategoryDialogContent.NewCategory');

export const assignCategoryToAttributeRequest = async (
  { attributeName }: CuratedAttribute,
  categoriesIds: string[],
) => {
  try {
    const { data } = await httpService.post<AssignCategoryResponse, AssignCategoryRequest>(`attributes`, {
      original_name: attributeName,
      categories: categoriesIds.map(id => ({ unique_name: id, display_name: id })),
    });
    return data;
  } catch (error) {
    console.error(`An error has occurred: ${error.message}`);
    notificationService.error(t('errors.categoryAssignmentFailed'));
  }
};

interface CreateCategoryResponse {
  success: boolean;
  glossary_id: string;
}

interface CreateCategoryRequest extends Partial<CategoryCreationObject> {}

export const createCategoryRequest = async ({
  color,
  name,
  description,
}: CreateCategory): Promise<CreateCategoryResponse> | null => {
  try {
    const { data } = await httpService.post<CreateCategoryResponse, CreateCategoryRequest>(`data_categories`, {
      color,
      description,
      display_name: name.trim(),
      unique_name: name.trim(),
    });
    return data;
  } catch ({ response }) {
    if (response.status === 400 && response.data.startsWith('E11000')) {
      notificationService.error(t('errors.categoryAlreadyExists'));
    } else {
      notificationService.error(t('errors.categoryCreationFailed'));
    }

    return null;
  }
};
