import { httpRequest } from '../../../../config/request';
import { SUGGESTED_ACTIONS_ENDPOINT, TRIGGER_SUGGESTED_ACTION_ENDPOINT } from '../constants/actions';
import type { FilterQueryItemRequest } from '../../DataSourceConnections/OnboardingAssistant/types';
import type { InitiativeType, CreateSuggestedActionsItemResponse, GetSuggestedActionsItemResponse } from '../types';
import type { BigidFieldFilter, BigidFilter } from '@bigid-ui/components';

const FILTER_DELIMITER = '.';

export const getSuggestedActions = async (data: FilterQueryItemRequest): Promise<GetSuggestedActionsItemResponse> =>
  httpRequest<GetSuggestedActionsItemResponse>({
    url: `${SUGGESTED_ACTIONS_ENDPOINT}`,
    method: 'post',
    data,
  });

export const triggerSuggestedAction = async (
  actionType: string,
  initiativeType: string,
  filters?: BigidFilter,
): Promise<CreateSuggestedActionsItemResponse> =>
  httpRequest<CreateSuggestedActionsItemResponse>({
    url: `${TRIGGER_SUGGESTED_ACTION_ENDPOINT}/${initiativeType}/${actionType}`,
    method: 'post',
    params: { filter: filters },
  });

export const mapInitiativeFilterToInitiativeType = (filter: BigidFieldFilter): InitiativeType => {
  const [type] = filter?.id ? (filter?.id as string).split(FILTER_DELIMITER) : [];

  return type as InitiativeType;
};
