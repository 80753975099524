export function getTagNameAndValueSplitted(tag: string): string[] {
  const regex = new RegExp(/.(?:@#&){1}./);

  if (regex.test(tag)) {
    return tag.split('@#&');
  } else {
    return tag.split(':');
  }
}

export function getTagConvertedToDisplayForm(tag: string): string {
  const regex = new RegExp(/.(?:@#&){1}./);

  if (regex.test(tag)) {
    return tag.replace('@#&', ' : ');
  } else {
    return tag.replace(':', ' : ');
  }
}

export function getIsTagNameOrValueValid(str: string): boolean {
  const regex = new RegExp(/^[A-Za-z\d](?:[A-Za-z\d_\.:\-\t ]{0,126}[A-Za-z\d_\.:\-])?$/);

  return regex.test(str);
}
