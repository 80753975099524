import { BigidPaper } from '@bigid-ui/components';
import { BigidGridColumnTypes, BigidGridWithToolbar, BigidGridWithToolbarProps } from '@bigid-ui/grid';
import { BigidAttributeIcon, BigidAddUserIcon, BigidDataSearchIcon, BigidUserReportIcon } from '@bigid-ui/icons';
import { makeStyles } from '@mui/styles';
import React, { useMemo } from 'react';
import { $state } from '../../../services/angularServices';
import { dateTimeService } from '@bigid-ui/i18n';
import { notificationService } from '../../../services/notificationService';
import {
  getEntityPreviewRoute,
  scannerTypeToMetadataSearchEntityType,
} from '../DataCatalogEntityPreview/DataCatalogEntityPreviewUtils';
import { getObjectDiffs } from '../DataCatalogService';
import { ObjectDiffModel, ObjectDiffType } from './ObjectDiffTypes';

const useStyles = makeStyles({
  root: {
    height: 'calc(100% - 10px)',

    '& > *': {
      height: '100%',
    },
  },

  subjectWrapper: {
    display: 'flex',
    alignItems: 'center',
    gap: '5px',
  },
});

const iconByType = {
  [ObjectDiffType.SCAN]: BigidDataSearchIcon,
  [ObjectDiffType.ATTRIBUTES]: BigidAttributeIcon,
  [ObjectDiffType.OBJECT_OWNER]: BigidAddUserIcon,
  [ObjectDiffType.OBJECT_ISSUE]: BigidUserReportIcon,
  [ObjectDiffType.OBJECT_DELETED]: BigidDataSearchIcon,
};

export const ActivityFeed = () => {
  const classes = useStyles();

  const gridWithToolbarConfig = useMemo<BigidGridWithToolbarProps<ObjectDiffModel>>(
    () => ({
      columns: [
        {
          title: 'Event',
          name: 'subject',
          type: BigidGridColumnTypes.CUSTOM,
          width: 700,
          getCellValue({ objectDiffType, subject }) {
            const Icon = iconByType[objectDiffType];
            return (
              <div className={classes.subjectWrapper}>
                <Icon staticMode={true} />
                {subject}
              </div>
            );
          },
          sortingEnabled: false,
        },
        {
          title: 'Object Name',
          name: 'objectName',
          type: BigidGridColumnTypes.LINK,
          width: 250,
          formatter: {
            onClick: async ({ row }) => {
              const { objectName, fullyQualifiedName, scannerTypeGroup } = row;
              const metadataEntityType = scannerTypeToMetadataSearchEntityType(scannerTypeGroup);
              const { route: entityPreviewRoute, params } = getEntityPreviewRoute(metadataEntityType, {
                fullyQualifiedName,
                objectName,
              });
              $state.go(entityPreviewRoute, params);

              return {};
            },
          },
          getCellValue: ({ objectName, fullyQualifiedName, objectDiffType }) => ({
            link: {
              text: objectName || fullyQualifiedName,
              disabled: objectDiffType === ObjectDiffType.OBJECT_DELETED,
            },
          }),
          sortingEnabled: false,
        },
        {
          title: 'Time',
          name: 'createdAt',
          type: BigidGridColumnTypes.TEXT,
          getCellValue: ({ createdAt }) => dateTimeService.formatRelativeToNow(createdAt),
        },
      ],
      filterToolbarConfig: {
        filters: [
          {
            title: 'Type',
            field: 'payload.objectDiffType',
            operator: 'in',
            options: [
              {
                label: 'Scan',
                value: ObjectDiffType.SCAN,
                isSelected: false,
              },
              {
                label: 'Attribute',
                value: ObjectDiffType.ATTRIBUTES,
                isSelected: false,
              },
              {
                label: 'Owner',
                value: ObjectDiffType.OBJECT_OWNER,
                isSelected: false,
              },
              {
                label: 'Issue',
                value: ObjectDiffType.OBJECT_ISSUE,
                isSelected: false,
              },
              {
                label: 'Deleted',
                value: ObjectDiffType.OBJECT_DELETED,
                isSelected: false,
              },
            ],
            value: [],
          },
        ],
        searchConfig: {
          operator: 'contains',
          searchFilterKeys: ['entityId'],
        },
      },
      defaultSorting: [{ field: 'createdAt', order: 'desc' }],
      fetchData: async queryComponents => {
        try {
          const { notifications: objectDiffs, totalCount } = await getObjectDiffs(queryComponents);
          const data: ObjectDiffModel[] = objectDiffs.map(e => ({
            id: e.id,
            createdAt: e.createdAt,
            ...e.payload,
          }));

          return {
            data,
            totalCount,
          };
        } catch (e) {
          console.error(e);
          notificationService.error('Could not fetch data. See logs for more information');

          return {
            data: [],
            totalCount: 0,
          };
        }
      },
      entityName: 'events',
      hideColumnChooser: true,
    }),
    [classes.subjectWrapper],
  );

  return (
    <BigidPaper classes={{ root: classes.root }}>
      <BigidGridWithToolbar {...gridWithToolbarConfig} />
    </BigidPaper>
  );
};
