import React, { memo, FC } from 'react';
import { useExtraFieldControl, BigidExtraField, ExtraFieldItemData } from '@bigid-ui/components';
import { EditTagDialogTextField } from './EditTagDialogTextField';

interface EditTagDialogValuesFieldSetProps {
  values: ExtraFieldItemData[];
  onCreate: (values: ExtraFieldItemData[]) => ExtraFieldItemData;
  onChange: (values: ExtraFieldItemData[]) => void;
  errorMessage?: string;
}

export const EditTagDialogValuesFieldSet: FC<EditTagDialogValuesFieldSetProps> = memo(
  ({ values, onCreate, onChange }) => {
    const { onAddNew, onDelete, onChangeItem } = useExtraFieldControl({
      initialData: values,
      createNewItem: onCreate,
      onChange,
    });

    return (
      <BigidExtraField
        fieldComponent={EditTagDialogTextField}
        fieldsBottomOffset={10}
        data={values}
        onChangeItem={onChangeItem}
        onDelete={onDelete}
        onAddNew={onAddNew}
      />
    );
  },
);

EditTagDialogValuesFieldSet.displayName = 'EditTagDialogValuesFieldSet';
