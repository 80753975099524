import { BigidToastNotificationType, NotificationsServiceOptions } from '@bigid-ui/components';
import { notificationService } from '../../../../services/notificationService';
import { EventEmitter } from '@bigid-ui/utils';

type EmitDsNotification = { type: BigidToastNotificationType; message: string; options?: NotificationsServiceOptions };

const dataSourceNotificationEmmiter = new EventEmitter<string>();

const invokeDataSourceModalNotification = ({ type, message, options }: EmitDsNotification) => {
  notificationService?.[type](message, options);
};

const addNotificationListener = (dsName: string) => {
  return dsName && dataSourceNotificationEmmiter.addEventListener(dsName, invokeDataSourceModalNotification);
};

const removeNotificationListener = (dsName: string) => {
  dsName && dataSourceNotificationEmmiter.removeEventListener(dsName, invokeDataSourceModalNotification);
};

const emitNotification = (dsName: string, emitOptions: EmitDsNotification) => {
  dsName && dataSourceNotificationEmmiter.emit(dsName, emitOptions);
};

export const dsModalNotificationService = {
  emitNotification,
  addNotificationListener,
  removeNotificationListener,
};
