import './bulkRequest.scss';
import template from './bulkRequest.html';
import { module } from 'angular';
import { notificationService } from '../../../react/services/notificationService';
import { EVENTS, REQUEST_TYPES } from './../sarConsts.js';
import { get } from 'lodash';

const app = module('app');

class SarBulkRequestComponent {
  constructor($translate, $rootScope, sarBulkRequestService, subjectAccessRequestService) {
    'ngInject';

    this.$translate = $translate;
    this.$rootScope = $rootScope;
    this.sarBulkRequestService = sarBulkRequestService;
    this.subjectAccessRequestService = subjectAccessRequestService;

    this.downloadCSVTemplate = this.downloadCSVTemplate.bind(this);
    this.uploadCsv = this.uploadCsv.bind(this);
    this.onFileSelected = this.onFileSelected.bind(this);
    this.onFileRemoved = this.onFileRemoved.bind(this);
    this.setSelectedProfileId = this.setSelectedProfileId.bind(this);

    this.file = undefined;
    this.isLoading = false;
    this.unregister = [];
    this.profileSelected = undefined;
  }

  $onInit() {
    this.fileUploaderConfig = {
      formData: true,
      errorsList: true,
      replace: true,
      filesList: true,
      multiple: false,
      limit: 1,
      mode: 'drop',
      maxSize: '1MB',
      accept: 'text/csv',
      permitted: '.csv',
      onFileAdded: files => this.onFileSelected(files),
      onFileRemoved: () => this.onFileRemoved(),
    };

    this.unregister.push(
      this.$rootScope.$on(EVENTS.callToSubmitSarRequest, (event, { type }) => {
        if (type === REQUEST_TYPES.bulk && this.file) {
          this.uploadCsv();
        }
      }),
    );

    if (this.profiles && !this.profileSelected) {
      this.profileSelected = this.profiles.find(profile => !profile.isCustom) || this.profiles[0];
    }
  }

  $onChanges(onChangesObject) {
    const { profiles } = onChangesObject;
    if (profiles && !profiles.previousValue && profiles.currentValue) {
      this.profileSelected = profiles.currentValue.find(profile => !profile.isCustom) || profiles.currentValue[0];
    }
  }

  $onDestroy() {
    this.unregister.forEach(unreg => unreg());
    this.unregister = null;
  }

  setSelectedProfileId(profileSelected) {
    this.profileSelected = profileSelected;
  }

  downloadCSVTemplate() {
    if (this.isLoading) {
      return;
    }
    this.isLoading = true;
    this.sarBulkRequestService
      .downloadCSVTemplate(this.profileSelected._id)
      .catch(err => {
        const noAttributesForProfileError = get(err, 'data.message') === 'no attributes found';
        this.$translate(
          noAttributesForProfileError ? 'DSAR:BULK_REQUEST:NO_ATTRIBUTES' : 'DSAR:BULK_REQUEST:DOWNLOAD_FAILED',
        ).then(translation => {
          notificationService.error(translation);
        });
        window.console.error(`Failed to download csv`, err);
        throw err;
      })
      .finally(() => {
        this.isLoading = false;
      });
  }

  uploadCsv() {
    if (!this.file || this.isLoading) {
      return;
    }
    this.isLoading = true;

    this.subjectAccessRequestService
      .showSubmitSarRequestModalWithProfile(this.profileSelected)
      .then(() =>
        this.sarBulkRequestService.uploadFile({
          file: this.file,
          profileId: this.profileSelected._id,
          profileName: this.profileSelected.name,
        }),
      )
      .then(response => {
        if (response) {
          notificationService.success('File Uploaded Successfully');
        }
        this.file = undefined;
        // update listeners that file upload was finished successfully
        this.onFileUploaded({ event: { isSuccess: true } });
        this.$rootScope.$emit(EVENTS.onSarBulkFileUploaded, { isSuccess: true });
      })
      .catch(err => {
        notificationService.error('Failed to upload the file ' + err.data.message);
        window.console.error(err.data.message);
        throw err;
      })
      .finally(() => (this.isLoading = false));
  }

  onFileSelected(file) {
    this.file = file;
    this.onSubmitIsEnabled({ event: { isEnabled: !!this.file && !!this.profileSelected } });
  }

  onFileRemoved(...args) {
    this.file = undefined;
    this.onSubmitIsEnabled({ event: { isEnabled: !!this.file && !!this.profileSelected } });
  }
}

app.component('sarBulkRequest', {
  template,
  controller: SarBulkRequestComponent,
  bindings: {
    onFileUploaded: '&',
    onSubmitIsEnabled: '&',
    profiles: '<',
  },
});
