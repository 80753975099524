import {
  AdvancedToolbarOverrideValue,
  BigidAdvancedToolbarFilterUnion,
  advancedToolbarFilterMinifier,
  useHashQueryParams,
} from '@bigid-ui/components';
import React, {
  PropsWithChildren,
  createContext,
  useContext,
  useEffect,
  useMemo,
  useReducer,
  useRef,
  useState,
} from 'react';
import { useQuery } from 'react-query';
import { getNewDataCatalogFilterPreferences } from './newDataCatalogService';
import { noop } from 'lodash';
import { $state, $stateParams } from '../../../services/angularServices';

export type DataCatalogContext = {
  selectedFilters: BigidAdvancedToolbarFilterUnion[];
  hiddenColumnsNames: string[];
  isCustomQuery: boolean;
  gridDataFetchEnabled: boolean;
  filterString: string;
};

type UpdateStateAction = {
  type: 'UPDATE_STATE';
  payload: Partial<DataCatalogContext>;
};

type SetFilterStringAction = {
  type: 'SET_FILTER_STRING';
  payload: string;
};

type SetSelectedFiltersAction = {
  type: 'SET_SELECTED_FILTERS';
  payload: BigidAdvancedToolbarFilterUnion[];
};

type Action = SetFilterStringAction | SetSelectedFiltersAction | UpdateStateAction;

const reducer = (state: DataCatalogContext, action: Action) => {
  switch (action.type) {
    case 'UPDATE_STATE':
      return { ...state, ...action.payload };

    case 'SET_FILTER_STRING':
      return { ...state, filterString: action.payload };

    case 'SET_SELECTED_FILTERS':
      return { ...state, selectedFilters: action.payload };
    default:
      return state;
  }
};

// Adjusted to directly use `filter` and `filterSelections` if available
const getInitState = (filter = '', filterSelections: BigidAdvancedToolbarFilterUnion[] = []): DataCatalogContext => {
  return {
    selectedFilters: [],
    hiddenColumnsNames: [],
    isCustomQuery: true,
    filterString: filter,
    gridDataFetchEnabled: !!filter,
  };
};

type DataCatalogContextPassingProps = {
  state: DataCatalogContext;
  dispatch: (action: Action) => void;
  filterString: string;
  shouldSelectFirstItem: boolean;
  overrideFilterSelections: AdvancedToolbarOverrideValue[];
};

const DataCatalogContext = createContext<DataCatalogContextPassingProps>({
  state: getInitState('', []),
  dispatch: noop,
  filterString: '',
  shouldSelectFirstItem: false,
  overrideFilterSelections: [],
});

export const DataCatalogContextProvider = ({ children }: PropsWithChildren) => {
  const { filterSelections = '[]', shouldSelectFirstItem } = useHashQueryParams();

  const [overrideFilterSelections, setOverrideFilterSelections] = useState(() => {
    try {
      const minifiedOverrideValues = JSON.parse(decodeURI(filterSelections));
      return minifiedOverrideValues.map(advancedToolbarFilterMinifier.getOverrideValueUnminified);
    } catch (e) {
      console.error('Error parsing filterSelections', e);
      return [];
    }
  });

  const initialState = getInitState($stateParams.filter);
  const [state, dispatch] = useReducer(reducer, initialState);
  const hadInitialFilter = useRef(!!$stateParams.filter);
  const [shouldOpenFirstItem, setShouldOpenFirstItem] = useState(false);

  const { filterString, selectedFilters } = state;

  const minifiedFilters = useMemo(() => {
    const overrideValues = selectedFilters.map(advancedToolbarFilterMinifier.getFilterCompressedToOverrideValue);
    return overrideValues.map(advancedToolbarFilterMinifier.getOverrideValueMinified);
  }, [selectedFilters]);

  useEffect(() => {
    if (filterString !== $stateParams.filter || shouldSelectFirstItem) {
      const newFilterSelections = minifiedFilters.length > 0 ? JSON.stringify(minifiedFilters) : null;

      $state.go(
        '.',
        {
          filterSelections: newFilterSelections,
          filter: filterString,
          shouldSelectFirstItem: null,
        },
        { notify: false, reload: false },
      );

      if (shouldSelectFirstItem) {
        setShouldOpenFirstItem(true);
      }
    }
  }, [filterString, shouldSelectFirstItem, minifiedFilters]);

  useQuery(
    ['fetchNewCatalogUserPreferences'],
    async () => {
      return await getNewDataCatalogFilterPreferences()
        .then(newDataCatalogPreferences => {
          const { filterString, overrideValues } = newDataCatalogPreferences;

          if (overrideValues.length > 0) {
            setOverrideFilterSelections(overrideValues);
          }

          dispatch({
            type: 'UPDATE_STATE',
            payload: {
              filterString,
              gridDataFetchEnabled: true,
            },
          });
        })
        .finally(() => {
          dispatch({
            type: 'UPDATE_STATE',
            payload: {
              gridDataFetchEnabled: true,
            },
          });
        });
    },
    {
      //NOTE: disabling the query if there is an initial filter
      enabled: !hadInitialFilter.current,
    },
  );

  return (
    <DataCatalogContext.Provider
      value={{
        state,
        dispatch,
        filterString,
        shouldSelectFirstItem: shouldOpenFirstItem,
        overrideFilterSelections,
      }}
    >
      {children}
    </DataCatalogContext.Provider>
  );
};

export const useDataCatalogContext = () => {
  const context = useContext(DataCatalogContext);
  if (!context) {
    throw new Error('useDataCatalogContext must be used within a DataCatalogContextProvider');
  }
  return context;
};
