import { getScanByName } from '../../Scans/ScanService';
import { openSystemDialog } from '../../../services/systemDialogService';
import { ActionData, BigidToolbarActionDropDownOptions, SecondaryButton } from '@bigid-ui/components';
import { $state } from '../../../services/angularServices';
import { CONFIG } from '../../../../config/common';
import {
  createScheduledScan,
  getQuickScanToRun,
  handleScheduledScanErrors,
} from '../../Scans/ScanCreateWizard/createScanService';
import { runScanProfile } from '../../Scans/ScanProfiles/scanProfileService';
import { ParentScanType } from '../../Scans/ScanTypes';
import { ScanTemplate, ScanTemplateGridRow } from '../../Scans/ScanTemplates/scanTemplateTypes';
import { ScanStartedDialog } from './ScanStartedDialog';
import { notificationService } from '../../../services/notificationService';
import { showConfirmationDialog } from '../../../services/confirmationDialogService';
import {
  AI_ASSESSMENT_SCAN,
  ASSESSMENT_SCAN,
  DEFAULT_DATA_SOURCE_SCAN_NAME,
} from '../../Scans/ScanTemplates/scanTemplatesService';
import { getMaxAmountOfDsPerScanFF } from '../../Scans/ScanUtils';
import { openDialogForLargeScanSet } from './LargeAmountOfDataSourcesModal';

const INSTANT_SCAN_WARNING_TEXT = 'The selected data sources will be scanned now. Are you sure you want to continue?';
const INSTANT_SCAN_WARNING_TEXT_SINGLE =
  'The selected data source will be scanned now. Are you sure you want to continue?';
const DEFAULT_INSTANT_SCANS = [DEFAULT_DATA_SOURCE_SCAN_NAME, ASSESSMENT_SCAN, AI_ASSESSMENT_SCAN];

export const openScanRunningDialog = async (scanName: string, templateName: string, templateId?: string) => {
  const { id: scanId } = await getScanByName(scanName);
  openSystemDialog({
    title: 'Your scan has started',
    maxWidth: 'xs',
    buttons: [
      {
        component: SecondaryButton,
        text: 'View Scan',
        onClick: () => {
          $state.go(CONFIG.states.SCANS_SCAN_INSIGHTS, {
            scanProfileName: scanName,
            scanId,
          });
        },
      },
    ],
    content: () => ScanStartedDialog(templateName, templateId),
    onClose: () => null,
  });
};

const mapScanTemplateToBigidToolbarActions = (
  { name, _id, scanType }: ScanTemplate,
  owner: string,
): Partial<BigidToolbarActionDropDownOptions[]> => {
  const isDefaultTemplate = DEFAULT_INSTANT_SCANS.includes(name);

  return [
    {
      label: name,
      value: {
        scanTemplateId: _id,
        currentOwner: { name: owner },
      },
      hasSeparator: isDefaultTemplate,
      execute: executeQuickScanWithTemplate(name, scanType),
    },
  ];
};

export const executeQuickScanForDataSource = (template: ScanTemplate, sourceId: string, owner: string) => {
  const isDefaultTemplate = DEFAULT_INSTANT_SCANS.includes(template.name);
  const options = mapScanTemplateToBigidToolbarActions(template, owner);

  executeQuickScanWithTemplate(
    isDefaultTemplate ? template.name.toUpperCase() : template.name,
    template.scanType,
  )({ selectedRows: [{ name: sourceId }], selectedOptions: options });
};

export const executeQuickScanWithTemplate =
  (label: string, scanType: string) =>
  async ({ selectedRows, selectedOptions, allSelected, totalRows }: ActionData) => {
    const [selectedTemplate] = selectedOptions;
    const ownerName = selectedTemplate.value.currentOwner;
    const templateId = selectedTemplate.value.scanTemplateId;
    const templateName = selectedTemplate.label;
    const isOTTB = selectedTemplate.hasSeparator;
    const isSingle = selectedRows.length === 1;
    const exceededMaximumNumberOfDs = hasExceededMaxAmountOfSelectedDs(selectedRows.length, allSelected, totalRows);
    const dataSourcesIds = selectedRows.map(({ name }) => name);
    let shouldRun;
    if (exceededMaximumNumberOfDs) {
      shouldRun = await openDialogForLargeScanSet();
    } else {
      shouldRun = await showConfirmationDialog({
        allSelected: false,
        actionName: 'Run Scan',
        entityNameSingular: '',
        actionButtonName: 'Continue',
        cancelButtonName: 'Cancel',
        customDescription: isSingle ? INSTANT_SCAN_WARNING_TEXT_SINGLE : INSTANT_SCAN_WARNING_TEXT,
      });
    }
    if (!shouldRun) {
      return { shouldClearSelection: false };
    }
    const scanToRun = getQuickScanToRun({ ownerName, templateName, templateId, dataSourcesIds });

    try {
      notificationService.info(`Your scan is processing, we'll let you know when it starts.`);
      const {
        data: { scanProfile },
      } = await createScheduledScan(scanToRun, selectedTemplate.value.scanTemplateId, false, true);

      const isRunScanSuccessful = await runScanProfile({
        scanType: scanType as ParentScanType,
        scanProfileName: scanProfile.name,
      });

      if (isRunScanSuccessful) {
        await openScanRunningDialog(scanProfile.name, label, isOTTB ? '' : templateId);
      }
    } catch (e) {
      handleScheduledScanErrors(e, selectedTemplate.label, 'create');
    }
    return { shouldClearSelection: true };
  };

const hasExceededMaxAmountOfSelectedDs = (totalSelected: number, allSelected: boolean, totalRows: number) => {
  const MAX_AMOUNT_OF_DS_PER_SCAN = getMaxAmountOfDsPerScanFF();
  return totalSelected >= MAX_AMOUNT_OF_DS_PER_SCAN || (allSelected && totalRows >= MAX_AMOUNT_OF_DS_PER_SCAN);
};

export const getQuickScanOptions = (favoriteTemplates: ScanTemplateGridRow[], currentOwner: string) => {
  return favoriteTemplates.map(({ name: label, _id: scanTemplateId, scanType }) => {
    const isDefaultTemplate = DEFAULT_INSTANT_SCANS.includes(label);
    return {
      label: label,
      value: {
        scanTemplateId,
        currentOwner,
      },
      hasSeparator: isDefaultTemplate,
      execute: executeQuickScanWithTemplate(label, scanType),
    };
  });
};
