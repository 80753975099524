import deleteConfirmationTemplate from './myModalContent.html';
import { handleUnauthorizedError } from '../../react/services/httpService';
import { getTranslationFileNames } from '../../react/services/productTypePropertiesService';
import { SystemEvents, systemEventsEmitter } from '../../react/services/systemEvents';
import { getApplicationPreference } from '../../react/services/appPreferencesService';
import { sessionStorageService } from './sessionStorageService';

const app = angular.module('app');

const version = 'api/v1';
const serverPath = '//' + window.location.host;

export const appSettings = {
  serverPath,
  version,
};

app.constant('appSettings', appSettings);

app.factory('translationsLoader', function ($http) {
  'ngInject';

  return function ({ key: locale }) {
    const TRANSLATIONS_PATH = '/translations';
    const PRODUCT_TYPE_BASED_TRANSLATIONS_PATH = 'productType';
    const TRANSLATIONS_FILE_EXT = 'json';

    const translationsPromises = getTranslationFileNames().map(source =>
      $http.get(
        `${TRANSLATIONS_PATH}/${locale}/${PRODUCT_TYPE_BASED_TRANSLATIONS_PATH}/${source}.${TRANSLATIONS_FILE_EXT}`,
      ),
    );

    return Promise.all(translationsPromises).then(responses => {
      return responses.reduce((result, { data }) => ({ ...result, ...data }), {});
    });
  };
});

app.factory('queryStringService', function () {
  return {
    getQueryStringFilter(filterValue, ids) {
      let queryString = '';

      if (filterValue) {
        queryString += '?filter=' + encodeURIComponent(filterValue);
        if (ids) {
          ids.forEach(function (entry) {
            queryString += '&ids[]=' + encodeURIComponent(entry);
          });
        }
        return queryString;
      }
      if (ids) {
        if (ids.length >= 1) {
          queryString += '?ids=' + encodeURIComponent(ids[0]);
        }
        for (let i = 1; i < ids.length; i++) {
          queryString += '&ids[]=' + encodeURIComponent(ids[i]);
        }
      }
      return queryString;
    },
  };
});

app.factory('commonMethods', function () {
  return {
    getAbbrNum(number, decPlaces) {
      // 2 decimal places => 100, 3 => 1000, etc
      decPlaces = Math.pow(10, decPlaces);

      // Enumerate number abbreviations
      const abbrev = ['K', 'M', 'B', 'T'];

      // Go through the array backwards, so we do the largest first
      for (let i = abbrev.length - 1; i >= 0; i--) {
        // Convert array index to "1000", "1000000", etc
        const size = Math.pow(10, (i + 1) * 3);

        // If the number is bigger or equal do the abbreviation
        if (size <= number) {
          // Here, we multiply by decPlaces, round, and then divide by decPlaces.
          // This gives us nice rounding to a particular decimal place.
          number = Math.round((number * decPlaces) / size) / decPlaces;

          // Handle special case where we round up to the next abbreviation
          if (number == 1000 && i < abbrev.length - 1) {
            number = 1;
            i++;
          }

          // Add the letter for the abbreviation
          number += abbrev[i];

          // We are done... stop
          break;
        }
      }
      return number;
    },
    encodeUrlParams: params => {
      const DEFAULT_QUERY = '';

      let query = Object.entries(params).reduce((aggregatedQuery, param) => {
        if (aggregatedQuery === DEFAULT_QUERY) {
          return `${param[0]}=${param[1]}`;
        } else {
          return `${aggregatedQuery}&${param[0]}=${param[1]}`;
        }
      }, DEFAULT_QUERY);

      if (query !== DEFAULT_QUERY) {
        query = `?${query}`;
      }

      return encodeURI(query);
    },
    decodeUrlParams: query => {
      const params = {};

      if (typeof query == 'string') {
        const decodedQuery = decodeURI(query.replace(/^\?/, ''));

        const paramEntries = decodedQuery.split('&');

        for (const pair of paramEntries) {
          const [key, value] = pair.split('=');

          if (value === 'true') {
            params[key] = true;
          } else if (value === 'false') {
            params[key] = false;
          } else {
            params[key] = value;
          }
        }
      }

      return params;
    },
  };
});

app.factory('httpRequestInterceptor', function () {
  return {
    request: function (config) {
      config.headers['authorization'] = sessionStorageService.get('bigIdTokenID');
      return config;
    },
    responseError: response => handleUnauthorizedError(response),
  };
});

app.service('DeleteConfirmation', function ($uibModal, $sce, $templateCache) {
  'ngInject';
  const TEMPLATE_NAME_BODY_HTML = 'DELETE_CONFIRMATION_UIB_MODAL_BODY_HTML_TEMPLATE_CACHE';
  const modalDefaults = {
    backdrop: true,
    keyboard: true,
    modalFade: true,
    template: deleteConfirmationTemplate,
  };

  const modalOptions = {
    closeButtonText: 'Close',
    actionButtonText: 'OK',
    headerText: 'Proceed?',
    bodyText: 'Perform this action?',
    bodyHtml: '',
  };

  this.showModal = function (customModalDefaults, customModalOptions) {
    if (!customModalDefaults) customModalDefaults = {};
    customModalDefaults.backdrop = 'static';
    return this.show(customModalDefaults, customModalOptions);
  };

  this.show = function (customModalDefaults, customModalOptions) {
    //Create temp objects to work with since we're in a singleton service
    const tempModalDefaults = {};
    const tempModalOptions = {};

    //Map angular-ui modal custom defaults to modal defaults defined in service
    angular.extend(tempModalDefaults, modalDefaults, customModalDefaults);

    //Map modal.html $scope custom properties to defaults defined in service
    angular.extend(tempModalOptions, modalOptions, customModalOptions);

    $templateCache.remove(TEMPLATE_NAME_BODY_HTML);
    if (tempModalOptions.bodyHtml) {
      $templateCache.put(TEMPLATE_NAME_BODY_HTML, $sce.trustAsHtml(tempModalOptions.bodyHtml));
    }

    if (!tempModalDefaults.controller) {
      tempModalDefaults.controller = function ($scope, $uibModalInstance) {
        'ngInject';
        $scope.TEMPLATE_NAME_BODY_HTML = TEMPLATE_NAME_BODY_HTML;
        $scope.modalOptions = tempModalOptions;
        $scope.modalOptions.ok = function (result) {
          $uibModalInstance.close(result);
        };
        $scope.modalOptions.close = function (result) {
          $uibModalInstance.dismiss('cancel');
        };
      };
    }

    return $uibModal.open(tempModalDefaults).result.finally(() => {
      $templateCache.remove(TEMPLATE_NAME_BODY_HTML);
    });
  };
});

app.service('downloadFileService', [
  '$timeout',
  function ($timeout) {
    return {
      download: (data, mimeType, filename) => {
        const blob = new Blob([data], { type: mimeType });

        if (navigator.appVersion.toString().indexOf('.NET') > 0) {
          window.navigator.msSaveBlob(blob, filename);
        } else {
          const dummyLink = document.createElement('a');
          dummyLink.href = URL.createObjectURL(blob);
          dummyLink.download = filename;
          document.body.appendChild(dummyLink);
          $timeout(() => {
            dummyLink.click();
          });
        }
      },
    };
  },
]);

app.service('parseCSV', [
  '$q',
  function ($q) {
    const _convert = data =>
      $q(resolve => {
        const handleStringValue = v => v.replace(/"/g, '');

        const lines = data.split('\n'),
          headers = lines[0].split(',').map(item => handleStringValue(item)),
          result = {
            columns: {},
            data: [],
          };

        for (let i = 1; i < lines.length; i++) {
          const obj = {},
            currentLine = lines[i].split(',');

          for (let j = 0; j < headers.length; j++) {
            const prop = handleStringValue(headers[j]).toLowerCase().replace(/ /g, '_');
            obj[prop] = handleStringValue(currentLine[j]);
            result.columns[prop] = headers[j];
          }

          result.data.push(obj);
        }

        resolve(result);
      });

    this.toJSON = data => JSON.stringify(_convert(data));

    this.toObject = data => _convert(data);
  },
]);

app.service('reportLanguageService', [
  function () {
    return {
      reportLanguage: function () {
        return getApplicationPreference('REPORT_LANGUAGE')
          ? getApplicationPreference('REPORT_LANGUAGE').toLowerCase()
          : undefined;
      },
    };
  },
]);

app.service('scanResultsQueryLimitService', [
  function () {
    return {
      scanResultsQueryLimit: function () {
        const intValue = parseInt(getApplicationPreference('SCAN_RESULTS_QUERY_LIMIT'), 10);
        return isNaN(intValue) ? 10000 : intValue;
      },
    };
  },
]);

app.service('$exceptionHandler', [
  '$log',
  function ($log) {
    return function (exception, cause) {
      if (getApplicationPreference('SHOW_ERROR_REPORTER')) {
        $log.error(exception, cause);
        systemEventsEmitter.emit(SystemEvents.FEEDBACK_DIALOG, { errorEvent: exception });
      } else {
        $log.error(exception, cause);
      }
    };
  },
]);

app.config(function ($httpProvider) {
  'ngInject';

  $httpProvider.interceptors.push('httpRequestInterceptor');
});
