import { CONFIG } from '../../../../config/common';
import { MetadataSearchEntityType } from '../../../components/MetadataSearch/MetadataSearchTypes';
import { MetadataSearchEntityLabelMap } from '../../../components/MetadataSearch/MetadataSearchUtils';
import { DataCatalogRecord, DataCatalogRecordScannerTypeGroup } from '../DataCatalogService';

const dictionary: Record<string, MetadataSearchEntityType> = {
  [DataCatalogRecordScannerTypeGroup.STRUCTURED]: MetadataSearchEntityType.TABLE,
  [DataCatalogRecordScannerTypeGroup.UNSTRUCTURED]: MetadataSearchEntityType.FILE,
};

export function scannerTypeToMetadataSearchEntityType(
  scannerType: DataCatalogRecordScannerTypeGroup,
): MetadataSearchEntityType {
  return dictionary[scannerType] ?? MetadataSearchEntityType.OTHER_CATALOG;
}

export function getEntityPreviewRoute(
  type: MetadataSearchEntityType,
  { fullyQualifiedName, objectName }: Pick<DataCatalogRecord, 'fullyQualifiedName' | 'objectName'>,
) {
  return {
    route: CONFIG.states.CATALOG_PREVIEW,
    params: {
      id: encodeURIComponent(fullyQualifiedName),
      name: encodeURIComponent(objectName),
      type: MetadataSearchEntityLabelMap[type].singular.toLowerCase(),
    },
  };
}
