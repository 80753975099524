import { module } from 'angular';
const app = module('app');

app.factory('samlService', [
  '$http',
  'appSettings',
  function ($http, appSettings) {
    const multiPartConfig = {
      transformRequest: angular.identity,
      headers: {
        'Content-Type': undefined,
      },
    };

    return {
      getSaml: () => $http.get(`${appSettings.serverPath}/${appSettings.version}/idp/saml`).then(response => response),
      getsamlByID: samlId =>
        $http.get(`${appSettings.serverPath}/${appSettings.version}/idp/saml/${samlId}`).then(response => response),
      createSaml: saml =>
        $http
          .post(`${appSettings.serverPath}/${appSettings.version}/idp/saml`, saml, multiPartConfig)
          .then(response => response),
      updateSaml: (saml, id) =>
        $http
          .put(`${appSettings.serverPath}/${appSettings.version}/idp/saml/${id}`, saml, multiPartConfig)
          .then(response => response),
      deleteSaml: id =>
        $http.delete(`${appSettings.serverPath}/${appSettings.version}/idp/saml/${id}`).then(response => response),
      testSAMLConnection: saml =>
        $http
          .post(`${appSettings.serverPath}/${appSettings.version}/idp/saml/test`, saml, multiPartConfig)
          .then(response => response),
      testSAMLExistingFileConnection: id =>
        $http.post(`${appSettings.serverPath}/${appSettings.version}/idp/saml/test/${id}`).then(response => response),
      loginWithSSO: () => $http.get(`${appSettings.serverPath}/${appSettings.version}/saml`).then(response => response),
    };
  },
]);
