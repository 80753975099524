import { BigidLoader } from '@bigid-ui/components';
import angular from 'angular';
import React, { Suspense } from 'react';
import { convertToAngular } from '../../../../common/services/convertToAngular';

const BusinessGlossaryLazy = React.lazy(() => import(/* webpackChunkName: "BusinessGlossary" */ './BusinessGlossary'));

const BusinessGlossaryLazyComponent = () => (
  <Suspense fallback={<BigidLoader />}>
    <BusinessGlossaryLazy />
  </Suspense>
);

angular.module('app').component('businessGlossaryReact', convertToAngular(BusinessGlossaryLazyComponent, null));
