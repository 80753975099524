import { BigidFormField, BigidFormFieldTypes } from '@bigid-ui/components';
import { getOwnerField, nameValidator } from '../../../ScanUtils';
const DESCRIPTION_MAX_LENGTH = 512;

export const fields: BigidFormField[] = [
  {
    name: 'name',
    label: 'Name',
    isRequired: true,
    validate: nameValidator,
  },
  {
    name: 'description',
    label: 'Description',
    type: BigidFormFieldTypes.TEXTAREA,
    validate: (val?: string) => {
      if ((val?.length || 0) > DESCRIPTION_MAX_LENGTH) {
        return `Max length is ${DESCRIPTION_MAX_LENGTH} characters`;
      }
      return false;
    },
    fieldProps: {
      maxlength: DESCRIPTION_MAX_LENGTH,
      multiline: true,
    },
    misc: {
      rows: 3,
    },
  },
  getOwnerField('owners', false),
];
