import { useState, useEffect } from 'react';
import { BigidDataScanIllustration } from '@bigid-ui/icons';
import { commonMethods, $state } from '../../../services/angularServices';
import { DashboardUpperSectionItemData } from '../DashboardUpperSection/DashboardUpperSectionCommon';
import { DATA_SOURCES_INIT_VIEWS } from '../../DataSources/DataSources';
import { loginService } from '../../../../authentication/login/login.service';
import { CONFIG } from '../../../../config/common';
import { getAmountOfDiscoveredDs, CloudProvider } from '../../AutoDiscoveryWizard/autoDiscoveryWizardServices';
import { getAutoDiscoveryAppInfo } from '../../../services/autoDiscoveryService';
import { CustomAppStatus } from '../../CustomApp/views/ActivityLog/ActivityLog';
import { getApplicationPreference } from '../../../services/appPreferencesService';

interface AutoDiscoveryInfo {
  numOfAutoDiscoveryDs: number;
  isPreviousRunExisted: boolean;
  lastRunStatus: CustomAppStatus;
}

const fmsdDisplayData = (): DashboardUpperSectionItemData => {
  return {
    value: '',
    description: 'Data Discovery Wizard',
    onClick: () => {
      $state.go(CONFIG.states.FMSD);
    },
    disabled: false,
    Icon: BigidDataScanIllustration,
    iconProps: { style: { height: '34', width: '34' } },
    isLoading: false,
    id: 'fmsd',
  };
};

const autoDiscoveryDisplayData = ({
  numOfAutoDiscoveryDs,
  isPreviousRunExisted,
  lastRunStatus,
}: AutoDiscoveryInfo): DashboardUpperSectionItemData => {
  return {
    value: isPreviousRunExisted && numOfAutoDiscoveryDs ? `${commonMethods.getAbbrNum(numOfAutoDiscoveryDs, 1)}` : '',
    description:
      isPreviousRunExisted && numOfAutoDiscoveryDs ? 'Auto-Discovered Data Sources' : 'Auto-Discover AWS Data Sources',
    onClick: () => {
      $state.go(`dataSourcesInit`, {
        path:
          lastRunStatus === CustomAppStatus.IN_PROGRESS || numOfAutoDiscoveryDs
            ? DATA_SOURCES_INIT_VIEWS.autoDiscovery.path
            : DATA_SOURCES_INIT_VIEWS.smallIdInit.path,
      });
    },
    disabled: false,
    Icon: BigidDataScanIllustration,
    iconProps: { style: { height: '34', width: '34' } },
    isLoading: true,
    id: 'autoDiscovery',
  };
};

interface AutoDiscoveryDataNaiveCacheInterface {
  isNotInitiated: boolean;
  autoDiscoveryData: DashboardUpperSectionItemData;
}

const naiveCacheInitialState: AutoDiscoveryDataNaiveCacheInterface = {
  isNotInitiated: true,
  autoDiscoveryData: autoDiscoveryDisplayData({
    numOfAutoDiscoveryDs: 0,
    isPreviousRunExisted: false,
    lastRunStatus: CustomAppStatus.COMPLETED,
  }),
};

let naiveCache: AutoDiscoveryDataNaiveCacheInterface = { ...naiveCacheInitialState };

export const useAutoDiscoveryData = () => {
  const [autoDiscoveryData, setAutoDiscoveryData] = useState(naiveCache.autoDiscoveryData);
  const [isLoading, setIsObjectsLoading] = useState(naiveCache.isNotInitiated);

  useEffect(() => {
    if (getApplicationPreference('FMSD_ENABLED')) {
      setAutoDiscoveryData(fmsdDisplayData());
      setIsObjectsLoading(false);
    } else {
      Promise.all([getAutoDiscoveryAppInfo(CloudProvider.AWS), getAmountOfDiscoveredDs(CloudProvider.AWS)])
        .then(([autoDiscoveryAppInfo, amountOfDiscoveredDs]) => {
          const { isPreviousRunExisted, lastRunStatus } = autoDiscoveryAppInfo;
          const displayData = autoDiscoveryDisplayData({
            numOfAutoDiscoveryDs: amountOfDiscoveredDs,
            isPreviousRunExisted,
            lastRunStatus,
          });
          naiveCache = { ...naiveCache, isNotInitiated: false, autoDiscoveryData: displayData };
          setAutoDiscoveryData(displayData);
        })
        .catch(e => {
          console.error(e);
        })
        .finally(() => {
          setIsObjectsLoading(false);
        });
    }

    return () => {
      if (!loginService.isLoggedIn()) {
        naiveCache = { ...naiveCacheInitialState };
      }
    };
  }, [setAutoDiscoveryData]);

  return {
    ...autoDiscoveryData,
    isLoading,
  };
};
