import { HeaderImage, HeaderWrapper } from '../DataSourceConfigurationStyles';
import {
  DataSourceWizardContent,
  DataSourceWizardLayout,
  DataSourceWizardTabs,
} from '../DataSourceWizard/DataSourceWizardStyles';

export const WizardLayout = {
  Wrapper: DataSourceWizardLayout,
  Content: DataSourceWizardContent,
  Tabs: DataSourceWizardTabs,
};

export const Header = {
  Image: HeaderImage,
  Wrapper: HeaderWrapper,
};
