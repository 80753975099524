import React, { FC } from 'react';
import styled from '@emotion/styled';
import { generateDataAid } from '@bigid-ui/utils';
import { BigidXIcon } from '@bigid-ui/icons';
import { BigidButtonIcon, BigidBody1, BigidTooltip } from '@bigid-ui/components';
import { AuditTrailRecordGridItem } from '../grid/Grid';
import { AuditTrailPropOperation } from '../dataCatalogAuditTrailService';
import { Added } from './content/Added';
import { Modified } from './content/Modified';

export interface DetailsProps {
  dataAid: string;
  gridRecord: AuditTrailRecordGridItem;
  onClose: () => void;
}

const Root = styled('div')`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
`;

const Header = styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0px 16px;
  height: 42px;
  width: 100%;
  border-bottom: ${({ theme }) => theme.vars.tokens.bigid.borderDefault};
`;

const HeaderText = styled(BigidBody1)`
  overflow: hidden;
  text-overflow: ellipsis;
  flex: 1;
`;

const Content = styled('div')`
  height: calc(100% - 42px);
  overflow-x: hidden;
  overflow-y: auto;
  width: 100%;
  padding: 24px 16px;
`;

export const Details: FC<DetailsProps> = ({ dataAid, gridRecord, onClose }) => {
  const { name, previous, current, propertyOperation } = gridRecord;

  return (
    <Root data-aid={dataAid}>
      <Header data-aid={generateDataAid(dataAid, ['header'])}>
        <BigidTooltip title={name}>
          <HeaderText data-aid={generateDataAid(dataAid, ['header', 'title'])}>{name}</HeaderText>
        </BigidTooltip>
        <BigidButtonIcon dataAid={generateDataAid(dataAid, ['header', 'close'])} icon={BigidXIcon} onClick={onClose} />
      </Header>
      <Content data-aid={generateDataAid(dataAid, ['content'])}>
        {propertyOperation === AuditTrailPropOperation.ADDED && (
          <Added dataAid={generateDataAid(dataAid, ['details', 'added'])} details={current} />
        )}
        {propertyOperation === AuditTrailPropOperation.MODIFIED && (
          <Modified
            dataAid={generateDataAid(dataAid, ['details', 'added'])}
            currentDetails={current}
            previousDetails={previous}
          />
        )}
      </Content>
    </Root>
  );
};
