import React, { FC, useContext } from 'react';
import { styled } from '@mui/material';
import {
  BigidColorsV2,
  EntityEvents,
  entityEventsEmitter,
  SecondaryButton,
  TertiaryButton,
} from '@bigid-ui/components';
import { AutoDiscoveryPresetConfigTabsEnum, AutoDiscoveryWizardContext } from '../autoDiscoveryWizardContext';
import { useCheckPermissionsErrors } from '../../hooks/useCheckPermissionsErrors';
import { useLocalTranslation } from '../../translations';
import { RunAndScanWithConfirm } from '../autoDiscoveryComponents/RunAndScanWithConfirm';
import { useActionHandlers } from '../../hooks/useActionHandlers';
import { closeSystemDialog } from '../../../../services/systemDialogService';
import { $state } from '../../../../services/angularServices';
import { CONFIG } from '../../../../../config/common';
import { BigidSaveIcon } from '@bigid-ui/icons';

const AutoDiscoverWizardFooterWrapper = styled('div')`
  padding: 8px 12px;
  display: flex;
  align-items: center;
  height: 100%;
  color: ${BigidColorsV2.gray[600]};
  margin-top: 8px;
  gap: 8px;
`;

const AutoDiscoverWizardFooterLeftWrapper = styled('div')`
  display: flex;
  align-items: center;
  flex: 1 0 auto;
`;

export const AutoDiscoveryWizardFooter: FC = () => {
  const { t } = useLocalTranslation('AutoDiscovery');
  const { discoveryConfigData, setDiscoveryConfigData, discoveryConfigDataRef } =
    useContext(AutoDiscoveryWizardContext);
  const { isAssessmentScanEnabled, isDisabled, onSavePreset, onRunDiscover, isDiscoveryReady } = useActionHandlers({
    discoveryConfigData,
    setDiscoveryConfigData,
    discoveryConfigDataRef,
  });

  useCheckPermissionsErrors();

  return (
    <AutoDiscoverWizardFooterWrapper>
      <AutoDiscoverWizardFooterLeftWrapper>
        <TertiaryButton
          dataAid={'AutoDiscoverWizardFooterSaveDraft'}
          size={'medium'}
          disabled={!discoveryConfigData?.formData?.name}
          startIcon={<BigidSaveIcon />}
          onClick={async () => {
            await onSavePreset();
            closeSystemDialog();
            entityEventsEmitter.emit(EntityEvents.RELOAD);
          }}
          text={t('configModal.buttons.saveAsDraft')}
        />
      </AutoDiscoverWizardFooterLeftWrapper>
      <TertiaryButton
        dataAid={'AutoDiscoverWizardFooterGoToEdit'}
        size={'medium'}
        disabled={!discoveryConfigData?.formData?.name}
        onClick={async () => {
          const presetId = await onSavePreset();
          closeSystemDialog();

          $state.go(CONFIG.states.AUTO_DISCOVERY_PRESET_CONFIG, {
            id: presetId,
            type: discoveryConfigDataRef.current.type,
            tab: AutoDiscoveryPresetConfigTabsEnum.discovery,
          });
        }}
        text={t('configModal.buttons.advanced')}
      />
      <SecondaryButton
        size={'medium'}
        disabled={isDisabled}
        dataAid={'AutoDiscoverWizardFooterDiscover'}
        onClick={onRunDiscover}
        text={t('configModal.buttons.runDiscovery')}
      />
      <RunAndScanWithConfirm onRunDiscover={onRunDiscover} isDisabled={!isDiscoveryReady || !isAssessmentScanEnabled} />
    </AutoDiscoverWizardFooterWrapper>
  );
};
