import React, { FC, useContext, useState, useEffect, useRef } from 'react';
import styled from '@emotion/styled';
import { BigidHeading5, BigidLoader, BigidHeading6, BigidColorsV2, BigidTooltip } from '@bigid-ui/components';
import { BigidLampIcon } from '@bigid-ui/icons';
import { CaseSidePanelContext } from '../../hooks/CaseSidePanelContext';
import { fetchViolationLocation, HotspotData, isFeatureFlagEnabled } from '../caseReportService';
import { useLocalTranslation } from '../../../translations';
import { generateDataAid } from '@bigid-ui/utils';
import { HeaderIcon, NoData } from '../CaseReportComponents/caseReportStyles';
import { formatNumberCompact } from '../../../../../utilities/numericDataConverter';
import { ReducerActions } from '../../hooks/CaseSidePanelReducer';

const mapHotspotBoxSizes = [['100%'], ['70%', '30%'], ['33%', '33%', '33%']];

const HeaderTitle = styled.div({
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'row',
  gap: 7,
});

const Header = styled.div({
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'row',
  justifyContent: 'space-between',
  width: 407,
});

const WidgetContainer = styled.div<{
  isVisible?: boolean;
}>(({ isVisible }) => ({
  display: isVisible ? 'flex' : 'none',
  flexDirection: 'column',
  width: 407,
  height: '100%',
  position: 'relative',
  gap: 14,
}));

const DataContainer = styled.div({
  display: 'flex',
  flexDirection: 'row',
  gap: 2,
});

const Hotspot = styled('div')<{ customWidth: string }>`
  height: 64px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  justify-content: center;
  gap: 4px;
  padding: 4px;
  width: ${({ customWidth }) => customWidth};
  > h6 {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    color: white;
    width: 100%;
  }
  :first-child {
    background-color: ${BigidColorsV2.purple['300']};
    > h6 {
      color: white;
    }
  }
  :nth-child(2) {
    background-color: ${BigidColorsV2.purple['200']};
    > h6 {
      color: ${BigidColorsV2.gray['700']};
    }
  }
  :nth-child(3) {
    background-color: ${BigidColorsV2.purple['150']};
    > h6 {
      color: ${BigidColorsV2.gray['700']};
    }
  }
`;

export const ViolationsLocationWidget: FC = () => {
  const { caseSidePanelData, dispatch, isViolationWidgetVisible } = useContext(CaseSidePanelContext);
  const [hotspotsData, setHotspotsData] = useState<HotspotData[]>();
  const [violationsPercentage, setViolationsPercentage] = useState<number>(0);
  const [isLoading, setIsLoading] = useState(true);
  const intervalNumber = useRef<number>();
  const { t } = useLocalTranslation('CaseReport');

  const getHotspotContent = (item: HotspotData, hotspotsLength: number): string => {
    const formatAttributesNum = formatNumberCompact(item.totalAttrTag, 1);
    const attributesDesc =
      hotspotsLength > 1 ? t('violationsLocation.hotspotAttrShort') : t('violationsLocation.hotspotAttrFull');
    return `${formatAttributesNum} ${attributesDesc} (${item.percentage}%)`;
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const { dataSourceName, policyName } = caseSidePanelData;
        const violationLocation = isFeatureFlagEnabled('HOTSPOTS_ENABLED')
          ? await fetchViolationLocation(dataSourceName, policyName, intervalNumber)
          : null;
        const violationData = violationLocation?.data || [];
        setHotspotsData(violationData);
        if (violationData.length) {
          const totalPercentage = violationData.reduce((acc, currHotspot) => acc + currHotspot.percentage, 0);
          setViolationsPercentage(totalPercentage);
        }
        dispatch({
          type: ReducerActions.UPDATE_VIOLATION_WIDGET_VISIBILITY,
          payload: { isViolationWidgetVisible: violationData.length > 0 },
        });
      } catch ({ message }) {
        if (message) {
          console.error(`An error has occurred: ${message}`);
        }
        if (message !== 'Scan failed') {
          dispatch({
            type: ReducerActions.UPDATE_WIDGETS_ERROR_STATUS,
            payload: { hasWidgetsErrors: true },
          });
        }
        dispatch({
          type: ReducerActions.UPDATE_VIOLATION_WIDGET_VISIBILITY,
          payload: { isViolationWidgetVisible: false },
        });
      } finally {
        setIsLoading(false);
      }
    };
    fetchData();
  }, [caseSidePanelData, dispatch, t]);

  return (
    <WidgetContainer isVisible={isViolationWidgetVisible}>
      {isLoading ? (
        <BigidLoader />
      ) : (
        <>
          <Header>
            <HeaderTitle>
              <HeaderIcon>
                <BigidLampIcon />
              </HeaderIcon>
              <BigidHeading5 fontWeight={700}>
                {hotspotsData?.length > 0
                  ? `${violationsPercentage}${t('violationsLocation.title')}`
                  : t('violationsLocation.notDataTitle')}
              </BigidHeading5>
            </HeaderTitle>
          </Header>
          <DataContainer data-aid={generateDataAid('ViolationsLocationWidget', ['container'])}>
            {hotspotsData?.length > 0 ? (
              <>
                {hotspotsData.map((item, index) => (
                  <BigidTooltip title={`${item.hotspotName} ${item.percentage}%`} key={item.hotspotName}>
                    <Hotspot customWidth={mapHotspotBoxSizes[hotspotsData.length - 1][index]} key={item.hotspotName}>
                      <BigidHeading6 fontWeight={700}>{item.hotspotName}</BigidHeading6>
                      <BigidHeading6 fontWeight={400}>{getHotspotContent(item, hotspotsData?.length)}</BigidHeading6>
                    </Hotspot>
                  </BigidTooltip>
                ))}
              </>
            ) : (
              <NoData width={448}>{t('errorMessages.noDataErr')}</NoData>
            )}
          </DataContainer>
        </>
      )}
    </WidgetContainer>
  );
};
