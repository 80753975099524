import React, { FC, ReactElement, useState, useEffect, ReactText, useCallback, Fragment, useMemo } from 'react';
import angular from 'angular';
import makeStyles from '@mui/styles/makeStyles';
import {
  BigidGridColumn,
  BigidGridColumnTypes,
  useFetch,
  BigidGrid,
  FetchDataFunction,
  BigidGridRow,
} from '@bigid-ui/grid';
import { convertToAngular } from '../../../common/services/convertToAngular';
import { queryService } from '../../services/queryService';
import { httpService } from '../../services/httpService';
import { notificationService } from '../../services/notificationService';
import {
  DataSourceModel,
  DataSourcesResponse,
} from '../../views/DataSources/DataSourceConnections/DataSourceConnectionTypes';
import {
  BigidConditionalWrapper,
  BigidSearch,
  BigidColors,
  BigidIconSize,
  BigidFilter,
  entityEventsEmitter,
  EntityEvents,
} from '@bigid-ui/components';
import { ALLOWED_NAME_REGEX, INVALID_NAME_REGEX_MESSAGE } from '../../config/consts';
import CheckCircle from '../../assets/icons/BigidCheckCircle.svg';
import { hasNewTemplate, getSupportedDataSources } from '../../utilities/dataSourcesUtils';

interface DataSourceSelectionGridProps {
  setSelected: (selectedRowIds: ReactText[]) => void;
  selected?: string[];
  wrapped?: boolean;
  shouldDisableRow?: boolean;
  compact?: boolean;
  useArchiveFilter?: boolean;
  scanTypeId?: string;
  dsLink?: boolean;
  useNotDsarOnlyFilter?: boolean;
  fetchDataFunction?: FetchDataFunction<BigidGridRow>;
}

const baseColumns: BigidGridColumn<DataSourceModel>[] = [
  {
    name: 'type',
    title: 'Type',
    getCellValue: ({ type }) => type,
    filteringEnabled: true,
    sortingEnabled: true,
    type: BigidGridColumnTypes.TEXT,
    width: 200,
  },
];

const baseDataSourceColumn = {
  name: 'name',
  title: 'Data Source',
  filteringEnabled: true,
  sortingEnabled: true,
  width: 300,
};
const getDataSourceColumn = (dsLink: boolean): BigidGridColumn<DataSourceModel> => {
  return dsLink
    ? {
        ...baseDataSourceColumn,
        type: BigidGridColumnTypes.LINK,
        formatter: {
          preventDefaultAndStopPropagation: false,
        },
        getCellValue: ({ id, name, type }) => ({
          link: {
            text: name,
            href: hasNewTemplate(type) ? `/#/configDataSource/${id}` : `/#/dataSourceConnections/${id}`,
            shouldOpenNewTab: true,
            rel: 'opener',
          },
        }),
      }
    : {
        ...baseDataSourceColumn,
        type: BigidGridColumnTypes.TEXT,
        getCellValue: ({ name }) => name,
      };
};

const getColumns = ({ compact = false, dsLink = false } = {}): BigidGridColumn<DataSourceModel>[] =>
  compact
    ? [
        getDataSourceColumn(dsLink),
        ...baseColumns,
        {
          name: 'location',
          title: 'Location',
          getCellValue: ({ location }) => location,
          filteringEnabled: true,
          sortingEnabled: true,
          type: BigidGridColumnTypes.TEXT,
          width: 200,
        },
      ]
    : [
        getDataSourceColumn(dsLink),
        ...baseColumns,
        {
          name: 'classification_is_enabled',
          title: 'Classifier Enabled',
          getCellValue: ({ classification_is_enabled }) => (classification_is_enabled || false ? 'Yes' : 'No'),
          filteringEnabled: false,
          sortingEnabled: false,
          type: BigidGridColumnTypes.TEXT,
          width: 180,
        },
        {
          name: 'ner_classification_is_enabled',
          title: 'Advanced Classifier Enabled',
          getCellValue: ({ ner_classification_is_enabled }) => (ner_classification_is_enabled || false ? 'Yes' : 'No'),
          filteringEnabled: false,
          sortingEnabled: false,
          type: BigidGridColumnTypes.TEXT,
          width: 220,
        },
        {
          name: 'comment',
          title: 'Comment',
          getCellValue: ({ comment }) => comment,
          filteringEnabled: true,
          sortingEnabled: false,
          type: BigidGridColumnTypes.TEXT,
          width: 200,
        },
        {
          name: 'hyperScanModelAvailable',
          title: 'Has Model',
          getCellValue: ({ hyperScanModelAvailable }) =>
            hyperScanModelAvailable
              ? {
                  icon: {
                    icon: CheckCircle,
                    size: BigidIconSize.REGULAR,
                    fill: BigidColors.successGreen,
                  },
                }
              : null,
          filteringEnabled: true,
          sortingEnabled: true,
          type: BigidGridColumnTypes.ICON,
          width: 150,
        },
      ];

const useStyles = makeStyles({
  root: { display: 'flex', height: 400, flexDirection: 'column', marginTop: 16 },
  wrapper: { display: 'flex', flexDirection: 'column' },
  searchWrapper: { display: 'flex', alignItems: 'center' },
  searchTitle: { color: BigidColors.gray[700], fontSize: '0.875rem', fontWeight: 'bold', marginRight: 40 },
  searchContainer: { display: 'flex', width: 300 },
  error: { color: BigidColors.failureRed, fontSize: '0.75rem', marginLeft: 130, height: 20 },
});

const ARCHIVE_FILTER: BigidFilter = [{ field: 'archived', operator: 'in', value: [false, null] }];
const NOT_ONLY_DSAR_FILTER: BigidFilter = [{ field: 'isDsarOnly', operator: 'in', value: [false, null] }];

export const DataSourceSelectionGrid: FC<DataSourceSelectionGridProps> = ({
  setSelected,
  selected = [],
  wrapped = false,
  shouldDisableRow = false,
  compact = false,
  useArchiveFilter,
  scanTypeId,
  dsLink,
  useNotDsarOnlyFilter,
  fetchDataFunction,
}) => {
  const classes = useStyles({});
  const [searchValue, setSearchValue] = useState<string>('');
  const [externalFilter, setExternalFilter] = useState<BigidFilter>([
    ...(useArchiveFilter ? ARCHIVE_FILTER : []),
    ...(useNotDsarOnlyFilter ? NOT_ONLY_DSAR_FILTER : []),
  ]);
  const [errorMessage, setErrorMessage] = useState('');
  const [selectedRowIds, setSelectedRowIds] = useState<ReactText[]>(shouldDisableRow ? [] : selected);

  useEffect(() => {
    setSelected(selectedRowIds);
  }, [selectedRowIds, setSelected]);

  useEffect(() => {
    entityEventsEmitter.emit(EntityEvents.RELOAD);
  }, [scanTypeId]);

  const onSearchChange = useCallback((searchValue: string) => {
    const invalidDsName = !ALLOWED_NAME_REGEX.test(searchValue);
    const hasError = searchValue !== '' && invalidDsName;
    setErrorMessage(hasError ? INVALID_NAME_REGEX_MESSAGE : '');
    setSearchValue(searchValue);
    if (!hasError) {
      setExternalFilter(prevFilter => {
        const newFilter = [...prevFilter];
        const hasNameField = newFilter.findIndex(({ field }) => field === 'name');
        if (hasNameField > -1) {
          newFilter[hasNameField] = { field: 'name', operator: 'contains', value: searchValue };
          return newFilter;
        }
        return [...newFilter, { field: 'name', operator: 'contains', value: searchValue }];
      });
    }
  }, []);

  const { columns, fields } = useMemo(() => {
    const columns = getColumns({ compact, dsLink });
    const fieldsNames = [...columns.map(({ name }) => name), 'id'];
    return {
      columns,
      fields: `&fields=${JSON.stringify(fieldsNames)}`,
    };
  }, [compact, dsLink]);

  const defaultFetchDataFunction: FetchDataFunction<BigidGridRow> = useCallback(
    async queryComponents => {
      let data: DataSourceModel[] = [];
      let totalCount = 0;
      try {
        const gridConfigQuery = queryService.getGridConfigQuery(queryComponents);
        const {
          data: {
            data: { ds_connections, totalCount: count },
          },
        } = await httpService.fetch<{ data: DataSourcesResponse }>(
          `ds-connections?${gridConfigQuery}${fields}${scanTypeId ? `&scanTypeId=${scanTypeId}` : ''}`,
        );
        //TMP FIX for init templates
        await getSupportedDataSources();
        data = ds_connections;
        totalCount = count;
      } catch (error) {
        console.error(error);
        notificationService.error('Could not fetch data. See logs for more information');
      }

      return Promise.resolve({
        data,
        totalCount,
      });
    },
    [scanTypeId],
  );

  const useFetchState = useFetch({
    externalFilter,
    initialSorting: [{ field: 'name', order: 'asc' }],
    fetchDataFunction: fetchDataFunction ?? defaultFetchDataFunction,
  });

  const gridConfig = {
    columns,
    pageSize: 200,
    selectedRowIds,
    customRowIdName: 'name',
    rows: useFetchState.rows as DataSourceModel[],
    showSortingControls: true,
    showSelectAll: false,
    ...(shouldDisableRow && { isRowDisabled: (row: DataSourceModel) => selected.includes(row.name) }),
    onSelectedRowIdsChanged: setSelectedRowIds,
    showSelectionColumn: true,
    skip: useFetchState.skip,
    onPagingChanged: useFetchState.onPagingChanged,
    onSortingChanged: useFetchState.onSortingChanged,
    totalRowsCount: useFetchState.totalRowsCount,
    onFiltersChange: useFetchState.onFiltersChanged,
    defaultSorting: useFetchState.defaultSorting,
    apiRef: useFetchState.apiRef,
    loading: useFetchState.isLoading,
  };

  return (
    <BigidConditionalWrapper
      condition={wrapped}
      wrapper={(children: ReactElement) => <div className={classes.root}>{children}</div>}
    >
      <Fragment>
        <div className={classes.wrapper}>
          <div className={classes.searchWrapper}>
            <div className={classes.searchTitle}>Search Data Source Name</div>
            <div className={classes.searchContainer}>
              <BigidSearch value={searchValue} onSubmit={onSearchChange} placeholder="Search" />
            </div>
          </div>
          <div className={classes.error}>{errorMessage}</div>
        </div>
        <BigidGrid {...gridConfig} />
      </Fragment>
    </BigidConditionalWrapper>
  );
};

angular
  .module('app')
  .component(
    'selectDataSourcesGrid',
    convertToAngular(DataSourceSelectionGrid, [
      'setSelected',
      'selected',
      'wrapped',
      'shouldDisableRow',
      'useArchiveFilter',
      'scanTypeId',
      'dsLink',
      'useNotDsarOnlyFilter',
    ]),
  );
