export const getRegExpressionForTester = (regexToTest: string) => {
  let flags = 'g';
  let regexExp = regexToTest.replace('\\A', '^').replace('\\Z', '$');
  if (regexExp.indexOf('(?i)') >= 0) {
    regexExp = regexExp.replace(new RegExp('\\(\\?i\\)', 'g'), '');
    flags = 'gi';
  }
  return new RegExp(regexExp, flags);
};

export const validateRegExpression = (classifierRegex: string) => {
  const regex = classifierRegex.replace(new RegExp('\\(\\?-i\\)', 'g'), '');
  getRegExpressionForTester(regex);
};
