import React, { FC, useState, useEffect } from 'react';
import { Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { BigidColors, DashboardCommonContainer } from '@bigid-ui/components';
import { DashboardInsightsBox } from './DashboardInsightsBox/DashboardInsightsBox';
import { DashboardInsightsArrowsContainer } from './DashboardInsightsArrowsContainer/DashboardInsightsArrowsContainer';
import { InsightData, setInsightsData } from './insightsUtils';
import { loginService } from '../../../../authentication/login/login.service';

const useStyles = makeStyles({
  wrapper: {
    minHeight: '212px',
    width: '100%',
    padding: '16px 16px 28px 16px',
    position: 'relative',
    transition: 'height 0.3s ease',
    margin: '8px 0 8px 0',
    flexGrow: 1,
  },
  header: {
    display: 'flex',
    height: '30px',
    alignItems: 'center',
  },
  title: {
    marginRight: '16px',
    fontSize: '16px',
    fontWeight: 600,
    color: BigidColors.gray[700],
  },
});

export interface DashboardBottomSectionCommonProps {
  insightsList: InsightData[];
}

let naiveCache: InsightData[] | null;

export const DashboardBottomSectionCommon: FC<DashboardBottomSectionCommonProps> = React.memo(({ insightsList }) => {
  const { title, wrapper, header } = useStyles({});
  const [insights, setInsights] = useState<InsightData[]>(
    naiveCache || insightsList.map(insightConfig => ({ ...insightConfig, isLoading: true })),
  );

  useEffect(() => {
    setInsightsData((callback: (cur: InsightData[]) => InsightData[]) => {
      setInsights((current: InsightData[]) => {
        const result = callback(current);
        naiveCache = result;
        return result;
      });
    }, insightsList);

    return () => {
      if (!loginService.isLoggedIn()) {
        naiveCache = null;
      }
    };
  }, [setInsights, insightsList]);

  return (
    <DashboardCommonContainer
      aria-label="Insights"
      data-bypass="dashboard-insights"
      className={`focusable ${wrapper}`}
      dataAid="DashboardBottomSectionBoxWrapper"
    >
      <div className={header}>
        <Typography variant={'h4'} className={title} data-aid="SelectDataSourceTypeTitle">
          Insights
        </Typography>
      </div>
      <DashboardInsightsArrowsContainer>{insights.map(DashboardInsightsBox)}</DashboardInsightsArrowsContainer>
    </DashboardCommonContainer>
  );
});
