import React, { ChangeEvent, FC } from 'react';
import { SystemDialogContentProps } from '../../../services/systemDialogService';
import {
  BigidBody1,
  BigidCaption,
  BigidColorsV2,
  BigidHeading6,
  BigidInfoBox,
  BigidLink,
  BigidRadioGroup,
} from '@bigid-ui/components';
import { ParentScanType } from '../ScanTypes';
import { useLocalTranslation } from '../translations';
import styled from '@emotion/styled';
import { ScanTypes } from '../ScanTemplates/scanTemplateTypes';
import { generateDataAid } from '@bigid-ui/utils';
import { getApplicationPreference } from '../../../services/appPreferencesService';
import { Trans } from 'react-i18next';
import { docsUrls } from '../../../config/publicUrls';

interface RunScanProfileActionDialogContentProps {
  dataSourceList?: string[];
  allEnabledDs: boolean;
  allEnabledIdSor: boolean;
  idsorList?: string[];
  type: ParentScanType;
  scanType: ScanTypes;
  isReviewFindingsEnabled: boolean;
  changeIsEnableClassifierTuning: (enableClassifierTuning: string) => void;
}

const Info = styled.div({
  marginBottom: '15px',
});

const Note = styled.div({
  display: 'flex',
  alignItems: 'center',
  marginTop: '12px',
});

const RadioGroup = styled.div({
  display: 'flex',
  margin: '15px 0px',
});

const ListItem = styled.li({
  margin: '12px 0px',
});

const List = styled.ul({
  margin: '0px',
  paddingLeft: '15px',
  maxHeight: '130px',
  overflow: 'auto',
});

const Separator = styled.hr({
  width: '100%',
  color: BigidColorsV2.gray[400],
  margin: '0px',
});

const TextWrapper = styled.div({
  marginBottom: '12px',
});

const NoteWrapper = styled.div({
  marginRight: '4px',
});

const Bold = styled.span({
  fontWeight: 'bold',
  fontSize: '14px',
});

export const RunScanProfileActionDialogContent: FC<
  SystemDialogContentProps<RunScanProfileActionDialogContentProps>
> = ({
  dataSourceList = [],
  allEnabledDs,
  allEnabledIdSor,
  idsorList = [],
  type,
  scanType,
  isReviewFindingsEnabled,
  changeIsEnableClassifierTuning,
}) => {
  const { t } = useLocalTranslation('RunScanProfileActionDialogContent');
  const isClassifierTuningPermitted = Boolean(getApplicationPreference('CLASSIFIER_TUNING_ENABLED'));
  const hasDataSourcesSelected = allEnabledDs || dataSourceList.length > 0;
  const hasNoCorrelationSet = !allEnabledIdSor && idsorList.length == 0;
  const showCorrelationSetWarning =
    hasNoCorrelationSet &&
    hasDataSourcesSelected &&
    scanType !== ScanTypes.METADATA_SCAN &&
    scanType !== ScanTypes.LABELING;
  const showPropToEnableClassifierTuning =
    isClassifierTuningPermitted &&
    !isReviewFindingsEnabled &&
    (scanType === ScanTypes.DS_SCAN || scanType == ScanTypes.ASSESSMENT_SCAN);

  const onClassifierTuningChange = ({ target }: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    changeIsEnableClassifierTuning(target.value);
  };

  return (
    <>
      {showPropToEnableClassifierTuning && (
        <>
          <BigidBody1> {t('enableClassifierTuningDialogMsg')} </BigidBody1>
          <RadioGroup>
            <BigidRadioGroup
              defaultValue="false"
              horizontal
              onChange={onClassifierTuningChange}
              options={[
                {
                  label: `${t('radioButtonYes')}`,
                  value: 'true',
                  dataAid: `${generateDataAid('runScanDialog', ['true'])}`,
                },
                {
                  label: `${t('radioButtonNo')}`,
                  value: 'false',
                  dataAid: `${generateDataAid('runScanDialog', ['false'])}`,
                },
              ]}
            />
          </RadioGroup>
        </>
      )}
      {hasDataSourcesSelected && (
        <TextWrapper>
          <BigidBody1>{`${t('areYouSure')} ${
            allEnabledDs ? `${t('allEnabledDataSources')}` : `${t('theFollowingDataSources')}`
          } `}</BigidBody1>
        </TextWrapper>
      )}
      {!allEnabledDs && dataSourceList.length > 0 && (
        <List>
          {dataSourceList.map(dataSource => {
            return (
              <ListItem key={dataSource} data-aid={`BigidListItem-${dataSource}`}>
                <BigidBody1>{dataSource}</BigidBody1>
              </ListItem>
            );
          })}
        </List>
      )}
      <Separator />
      {!hasDataSourcesSelected && (
        <TextWrapper>
          <Note>
            <BigidBody1>
              <Bold>{t('note')}: </Bold>
              {t('mustSelectDataSourcePart1')} {t('mustSelectDataSourcePart2')}
            </BigidBody1>
          </Note>
        </TextWrapper>
      )}
      {showCorrelationSetWarning && (
        <TextWrapper>
          <Note>
            <BigidBody1>
              <Bold>{t('note')}: </Bold>
              {t('correlationSetsMsgPart1')} {t('correlationSetsMsgPart2')}
            </BigidBody1>
          </Note>
        </TextWrapper>
      )}
    </>
  );
};
