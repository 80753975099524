import React, { ReactNode } from 'react';
import {
  DataSourceConnectionDialogContextType,
  useDataSourceConnectionDialogContext,
} from './DataSourceConnectionDialogProvider';
import { DialogSlots } from '../DataSourceConfiguration/types';

type DataSourceConnectionDialogAsideProps = {
  render?: (props: DataSourceConnectionDialogContextType) => JSX.Element;
  children?: ReactNode;
};

export const DataSourceConnectionDialogAside = ({
  render,
  children,
}: DataSourceConnectionDialogAsideProps): JSX.Element => {
  const context = useDataSourceConnectionDialogContext(DialogSlots.ASIDE);
  return <>{render ? render(context) : children}</>;
};
