import React, { FC, memo } from 'react';
import { BigidBody2, BigidHeading6, BigidBody1 } from '@bigid-ui/components';
import {
  getMetadataSearchEntityIconNode,
  MetadataSearchTypeIconSourceMap,
  getMetadataSearchEntityTypeLabel,
  MetadataSearchEntityLabelMap,
  getEntityTypeConfigByTypeId,
} from '../MetadataSearchUtils';
import { getMetadataSearchMapKeys } from '../MetadataSearchService';
import { MetadataSearchResultsGrid } from '../MetadataSearchResultsGrid/MetadataSearchResultsGrid';
import { MetadataSearchResultsGridRecord } from '../MetadataSearchResultsGrid/MetadataSearchResultsGridTypes';
import { UseMetadataSearchStateResponse } from '../useMetadataSearchState';
import { useStyles } from './MetadataSearchRecentObjectsStyles';
import { getRecentObjectsGridData } from './MetadataSearchRecentObjectsUtils';

export interface MetadataSearchRecentObjectsProps
  extends Pick<
    UseMetadataSearchStateResponse,
    | 'recentObjects'
    | 'onSearchResultEntityClick'
    | 'onSearchResultEntityGroupClick'
    | 'supportedEntityTypesMap'
    | 'entityTypeConfigs'
  > {
  dataAid: string;
}

export const MetadataSearchRecentObjects: FC<MetadataSearchRecentObjectsProps> = memo(
  ({
    dataAid,
    recentObjects = [],
    onSearchResultEntityClick,
    onSearchResultEntityGroupClick,
    supportedEntityTypesMap,
    entityTypeConfigs,
  }) => {
    const classes = useStyles();
    const gridData: MetadataSearchResultsGridRecord[] = getRecentObjectsGridData(
      recentObjects,
      MetadataSearchTypeIconSourceMap,
      entityTypeConfigs.types,
    );

    return (
      <div className={classes.root} data-aid={dataAid}>
        {recentObjects.length > 0 && (
          <BigidHeading6 classes={{ root: classes.header }} data-aid={`${dataAid}-dropdown-title`}>
            Recently Viewed Objects
          </BigidHeading6>
        )}
        <div className={classes.results}>
          {recentObjects.length > 0 ? (
            <MetadataSearchResultsGrid
              dataAid={`${dataAid}-grid`}
              data={gridData}
              onSearchResultEntityClick={onSearchResultEntityClick}
            />
          ) : (
            <BigidBody1 classes={{ root: classes.emptyResult }} data-aid={`${dataAid}-no-objects`}>
              No recently viewed objects
            </BigidBody1>
          )}
        </div>
        <div className={classes.footer}>
          <div className={classes.types}>
            {getMetadataSearchMapKeys(MetadataSearchTypeIconSourceMap)
              .filter(type => supportedEntityTypesMap[type])
              .map((type, index) => {
                const entityTypeConfig = getEntityTypeConfigByTypeId(entityTypeConfigs.types, type);
                const icon = getMetadataSearchEntityIconNode(
                  type,
                  'large',
                  MetadataSearchTypeIconSourceMap,
                  entityTypeConfig,
                );
                const label =
                  getMetadataSearchEntityTypeLabel(type, MetadataSearchEntityLabelMap, entityTypeConfig, 'singular') ||
                  type.toString();

                return (
                  <div
                    key={index}
                    className={classes.type}
                    onClick={() => onSearchResultEntityGroupClick(type)}
                    data-aid={`${dataAid}-quick-filter-${type}`}
                  >
                    {icon}
                    <BigidBody2 className={classes.typeName}>{label}</BigidBody2>
                  </div>
                );
              })}
          </div>
        </div>
      </div>
    );
  },
);

MetadataSearchRecentObjects.displayName = 'MetadataSearchRecentObjects';
