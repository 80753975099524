import React, { FC } from 'react';
import {
  BigidBody1,
  BigidNotes1,
  BigidHeading6,
  BigidColorsV2,
  BigidIconType,
  BigidSearchableList,
  BigidSearchableListItem,
} from '@bigid-ui/components';
import makeStyles from '@mui/styles/makeStyles';
import { Divider } from '@mui/material';
import { getCreatedByText, getScanTypeLabel } from '../../../ScanUtils';
import { BigidWarningFilledIcon, BigidClassificationIcon, BigidFlagIcon } from '@bigid-ui/icons';
import { generateDataAid } from '@bigid-ui/utils';
import { dateTimeService } from '@bigid-ui/i18n';
import { ScanTemplate } from '../../../ScanTemplates/scanTemplateTypes';

export interface ScanTemplateInformationDialogProps
  extends Pick<
    ScanTemplate,
    | 'name'
    | 'scanType'
    | 'description'
    | 'classifiers'
    | 'createdBy'
    | 'updated_at'
    | 'idConnectionList'
    | 'allEnabledIdSor'
    | 'isUseGlobalClassifiers'
  > {
  shouldDisplayWarning?: boolean;
}

interface SelectTemplateCardMoreDialogItemProps {
  label: string;
  value: string;
  showDivider: boolean;
}

interface SelectTemplateCardMoreDialogListProps {
  listItems?: BigidSearchableListItem[];
  entityName: string;
  itemsIcon: BigidIconType;
  alternativeText?: string;
}

const useStyles = makeStyles({
  dialogContent: {
    display: 'flex',
    flexDirection: 'column',
    padding: '8px 32px 24px 32px',
  },
  section: {
    padding: '16px 0',
    display: 'flex',
    justifyContent: 'space-between',
  },
  listWrapper: {
    width: '46%',
    height: 300,
  },
  footer: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    '& > :first-child': {
      marginRight: 8,
    },
  },
  listAltText: {
    display: 'inline-flex',
    alignItems: 'center',
    '& > :first-child': {
      marginRight: 12,
    },
  },
});

const SelectTemplateCardMoreDialogItem: FC<SelectTemplateCardMoreDialogItemProps> = ({ label, value, showDivider }) => {
  return (
    <>
      <div data-aid={generateDataAid('SelectTemplateCardMoreDialogItem', [label])}>
        <BigidNotes1>{label}</BigidNotes1>
        <BigidHeading6>{value}</BigidHeading6>
      </div>
      {showDivider && <Divider orientation="vertical" flexItem />}
    </>
  );
};

const SelectTemplateCardMoreDialogList: FC<SelectTemplateCardMoreDialogListProps> = ({
  listItems = [],
  entityName,
  itemsIcon: ItemsIcon,
  alternativeText,
}) => {
  const { listWrapper, listAltText } = useStyles({});
  const content = alternativeText ? (
    <BigidBody1 className={listAltText}>
      <ItemsIcon staticMode />
      {alternativeText}
    </BigidBody1>
  ) : (
    <BigidSearchableList
      listItems={listItems}
      searchPlaceHolder="Search"
      entityName={entityName}
      entityIcon={ItemsIcon}
      showTotalRows
      nameColumnWidth={100}
    />
  );

  return (
    <div data-aid={generateDataAid('SelectTemplateCardMoreDialogCounter', [entityName])} className={listWrapper}>
      {content}
    </div>
  );
};

const HOW_TO_EDIT_INFO_TEXT = 'To edit this template, go to the "Scan Templates" page';

export const ScanTemplateInformationDialog: FC<ScanTemplateInformationDialogProps> = ({
  name,
  scanType,
  description,
  classifiers,
  createdBy,
  updated_at,
  idConnectionList,
  allEnabledIdSor,
  shouldDisplayWarning,
  isUseGlobalClassifiers,
}) => {
  const { dialogContent, section, footer } = useStyles({});
  const topItems = [
    { label: 'Template Name', value: name },
    { label: 'Last modified date', value: dateTimeService.formatDate(updated_at) },
    { label: 'Scan Type', value: getScanTypeLabel(scanType) },
    { label: 'Created by', value: getCreatedByText(createdBy) },
  ];

  return (
    <div className={dialogContent}>
      <div className={section}>
        {topItems.map(({ label, value }, index) => (
          <SelectTemplateCardMoreDialogItem
            key={label}
            label={label}
            value={value}
            showDivider={index < topItems.length - 1}
          />
        ))}
      </div>

      {description && (
        <div className={section}>
          <div>
            <BigidNotes1>Description</BigidNotes1>
            <BigidBody1 data-aid="more-description" style={{ whiteSpace: 'pre-line' }}>{`${description}`}</BigidBody1>
          </div>
        </div>
      )}
      <div className={section}>
        <SelectTemplateCardMoreDialogList
          listItems={classifiers?.map(({ name }) => ({ text: name }))}
          itemsIcon={BigidClassificationIcon}
          entityName="Classification"
          alternativeText={isUseGlobalClassifiers ? 'All enabled global classifiers' : ''}
        />
        <Divider orientation="vertical" flexItem />
        <SelectTemplateCardMoreDialogList
          listItems={idConnectionList?.map(text => ({ text }))}
          itemsIcon={BigidFlagIcon}
          entityName="Correlation"
          alternativeText={allEnabledIdSor ? 'All enabled correlation sets' : ''}
        />
      </div>
      {shouldDisplayWarning && (
        <div className={footer}>
          <BigidWarningFilledIcon color={BigidColorsV2.yellow[900]} />
          <BigidBody1>{HOW_TO_EDIT_INFO_TEXT}</BigidBody1>
        </div>
      )}
    </div>
  );
};
