import React, { FC, memo } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { BigidRiskAlertIllustration } from '@bigid-ui/icons';
import { BigidHeading3, BigidBody1 } from '@bigid-ui/components';
import { MetadataSearchError } from './MetadataSearchTypes';

interface MetadataSearchErrorHandlerProps extends MetadataSearchError {
  dataAid?: string;
}

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '&:not(:last-child)': {
      marginBottom: '8px',
    },
  },
  row: {
    '&:not(:last-child)': {
      marginRight: '8px',
    },
  },
  icon: {
    transform: 'scale(0.8)',
  },
  header: {
    color: theme.vars.tokens.bigid.foregroundPrimary,
  },
}));

export const MetadataSearchErrorHandler: FC<MetadataSearchErrorHandlerProps> = memo(
  ({
    dataAid = 'MetadataSearchQuickErrorHandler',
    title = 'Something went wrong',
    description = 'The search failed because of an error, try to search again.',
  }) => {
    const classes = useStyles();

    return (
      <div className={classes.root} data-aid={dataAid}>
        <div className={classes.row}>
          <div className={classes.icon}>
            <BigidRiskAlertIllustration size="small" />
          </div>
        </div>
        <div className={classes.row}>
          <div className={classes.row}>
            <BigidHeading3 classes={{ root: classes.header }} data-aid={`${dataAid}-title`}>
              {title}
            </BigidHeading3>
          </div>
          {typeof description === 'string' && (
            <div className={classes.row}>
              <BigidBody1 data-aid={`${dataAid}-description`}>{description}</BigidBody1>
            </div>
          )}
        </div>
      </div>
    );
  },
);

MetadataSearchErrorHandler.displayName = 'MetadataSearchErrorHandler';
