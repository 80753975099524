import React, { FC } from 'react';
import { BigidLayoutEmptyState } from '@bigid-ui/layout';
import { AddBoxOutlined } from '@mui/icons-material';
import { BigidCertificateIllustration } from '@bigid-ui/icons';
import { useLocalTranslation } from './translations';

interface CertificatesManagementEmptyStateProps {
  handleCertificateAdd: () => void;
}

export const CertificatesManagementEmptyState: FC<CertificatesManagementEmptyStateProps> = ({
  handleCertificateAdd,
}) => {
  const { t } = useLocalTranslation();

  const emptyStateActions = [
    {
      execute: async () => {
        handleCertificateAdd();
        return { shouldGridReload: false };
      },
      icon: AddBoxOutlined,
      label: t('buttons.addFile'),
      show: () => true,
    },
  ];

  return (
    <BigidLayoutEmptyState
      actions={emptyStateActions}
      illustration={BigidCertificateIllustration}
      description={t('message.manageCertificates')}
    />
  );
};
