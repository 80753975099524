enum ContentType {
  HTML = 'html',
  TEXT = 'text',
}

interface Footer {
  text: string;
  contentType: ContentType;
}

export interface BrandItemResponse {
  _id: string;
  name: string;
  description?: string;
  logo?: string;
  color?: string;
  font?: Font | string;
  footer?: Footer;
  updated_at?: Date;
  created_at?: Date;
}

export type BrandGridRow = Partial<BrandItemResponse> & {
  id: string | number;
  isPending?: boolean;
};

export interface CredentialsPermissions {
  isDeletePermitted: boolean;
  isEditPermitted: boolean;
  isCreatePermitted: boolean;
}

export interface ForwardFormRef {
  fieldsToRender: string[];
  formElement: HTMLDivElement;
}

export enum Font {
  MULISH = 'mulish',
  ARIAL = 'arial',
}
