import { IComponentOptions, module } from 'angular';
import { angular2react } from 'angular2react';
import * as React from 'react';

// All converted components have to be registered, thus we create our own module in which we'll register the original ng components.
const ngModule = module('converted-ng-components', []);

let injector: angular.auto.IInjectorService;
ngModule.run(($injector: angular.auto.IInjectorService) => {
  'ngInject';
  injector = $injector;
});

// eslint-disable-next-line @typescript-eslint/ban-types
export function convertToReact<Props extends {}>(
  name: string,
  options: IComponentOptions,
): React.ComponentClass<Props> {
  ngModule.component(name, options);

  return angular2react<Props>(name, options, {
    // angular2react requires the $injector but doesn't actually use it until a component needs to be rendered.
    get get() {
      return injector.get;
    },
  } as any);
}

export const convertedNgComponentsModuleName = ngModule.name;
