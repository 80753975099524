import { useCallback } from 'react';
import { UpdateDataSourceConfigState } from './useDataSourceConfigState';

export const useConnectionTypeChanged = (updateState: UpdateDataSourceConfigState) =>
  useCallback(
    (values: Record<string, any>) => {
      updateState(currentState =>
        currentState.connectionType === values.connectionType?.[0]?.value
          ? currentState
          : {
              ...currentState,
              connectionType: values.connectionType?.[0]?.value,
            },
      );
    },
    [updateState],
  );
