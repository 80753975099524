import './dropdownButton.component.scss';
import template from './dropdownButton.component.html';
import { module } from 'angular';
const app = module('app');

app.component('dropdownButton', {
  template,
  controller: function () {
    const DEFAULT_POSITION = 'bottom-left';
    const DEFAULT_TYPE = 'primary-dark';

    this.items = [];
    this.title = '';
    this.position = DEFAULT_POSITION;
    this.type = DEFAULT_TYPE;

    this.isDisabled = false;
    this.isToggled = false;
    this.isTitleClickable = false;

    this.killEvent = () => {
      event.preventDefault();
      event.stopPropagation();
      event.cancelBubble = true;
    };

    this.$onChanges = () => {
      if (typeof this.position !== 'string' || this.position === '') {
        this.position = DEFAULT_POSITION;
      }

      if (typeof this.type !== 'string' || this.type === '') {
        this.type = DEFAULT_TYPE;
      }

      if (typeof this.title !== 'string') {
        this.title = '';
      }

      if (typeof this.items === 'undefined' || !Array.isArray(this.items)) {
        this.items = [];
      }
    };

    this.onDropdownItemClick = item => {
      if (item.isDisabled) return;

      this.onItemClick({ item });
    };

    this.onTitleClick = event => {
      if (this.isDisabled) {
        this.killEvent(event);
      } else {
        if (this.isTitleClickable) {
          this.killEvent(event);

          this.onButtonClick();
        }
      }
    };

    this.onCaretClick = event => {
      if (this.isDisabled) {
        this.killEvent(event);
      }
    };
  },
  bindings: {
    /**
     *  OBJECT
     *  name: <STRING> item name
     *  action: <STRING> parent component function name, click handler,
     *  isDisabled: <BOOLEAN> makes menu item disabled/enabled
     */
    items: '<',
    /**
     *  EVENT
     *  item: {name: ITEM_NAME_HERE, action: ITEM_ACTION_HERE}
     */
    onButtonClick: '&',
    /**
     *  STRING
     *  dropdown position, see DEFAULT_POSITION
     *  available options:
     *  [top-right, top-left, bottom-right, bottom-left, left-top, left-bottom, right-top, right-bottom]
     */
    onItemClick: '&',
    /**
     *  STRING
     *  dropdown position, see DEFAULT_POSITION
     *  available options:
     *  [top-right, top-left, bottom-right, bottom-left, left-top, left-bottom, right-top, right-bottom]
     */
    position: '@',
    /**
     *  STRING
     *  dropdown trigger type, see DEFAULT_TYPE
     *  available options:
     *  [primary-bright, primary-dark, secondary-bright, secondary-dark]
     */
    type: '@',
    /**
     *  STRING
     *  dropdown trigger title
     */
    title: '@',
    /**
     *  BOOLEAN
     *  is trigger button disabled: true/false
     */
    isDisabled: '<',
    /**
     *  BOOLEAN
     *  is title clickable: true/false
     *  non-clickable by default
     */
    isTitleClickable: '<',
  },
});
