import { debounce } from 'lodash';
import PerfectScrollbar from 'perfect-scrollbar';
import { MutableRefObject, useEffect } from 'react';

export function useCustomScrollbar(ref: MutableRefObject<HTMLElement>, options: PerfectScrollbar.Options = {}) {
  const DEFAULT_PS_CONFIG: PerfectScrollbar.Options = {
    wheelSpeed: 3,
    minScrollbarLength: 20,
    wheelPropagation: true,
  };

  useEffect(() => {
    const element: HTMLElement = ref.current;
    if (!element) {
      return;
    }

    const perfectScrollbar = new PerfectScrollbar(element, {
      ...DEFAULT_PS_CONFIG,
      ...options,
    });

    const onResize = debounce(() => {
      perfectScrollbar.update();
    }, 400);

    let resizeObserver: ResizeObserver;
    if ('ResizeObserver' in window) {
      resizeObserver = new ResizeObserver(() => {
        onResize();
      });
      resizeObserver.observe(element);
    }

    return () => {
      if (perfectScrollbar) {
        perfectScrollbar.destroy();
      }
      onResize.cancel();
      if (resizeObserver) {
        resizeObserver.disconnect();
      }
    };
  }, [options, ref, DEFAULT_PS_CONFIG]);
}
