import React, { FC, lazy, Suspense, useEffect, useCallback } from 'react';
import { getCustomDashboardValidationErrors } from '@bigid-ui/custom-dashboard';
import makeStyles from '@mui/styles/makeStyles';
import { BigidLoader } from '@bigid-ui/components';
import { ACCESS_MANAGEMENT_PERMISSIONS, CUSTOM_DASHBOARD_PERMISSIONS } from '@bigid/permissions';

import { $state, $stateParams } from '../../services/angularServices';
import { notificationService } from '../../services/notificationService';

import {
  getCustomDashboardById,
  addCustomDashboard,
  updateCustomDashboard,
  updateUserDefaultDashboard,
  removeCustomDashboardById,
  getCustomDashboards,
  getUserDefaultDashboard,
} from '../../services/customDashboardService';
import { blankTemplate } from './TemplateSelector';
import { useCustomDashboardGeneralManagerState, Action } from './CustomDashboardGeneralWrapperHook';
import { CustomDashboard } from './CustomDashboardManagement';
import { isPermitted } from '../../services/userPermissionsService';
import { showConfirmationDialog } from '../../services/confirmationDialogService';
import { CONFIG } from '../../../config/common';

export const NEW_DASHBOARD_ID = 'new';

const CustomDashboardPreviewMode = lazy(
  () =>
    import(
      /* webpackChunkName: "CustomDashboardPreviewMode" */ './CustomDashboardPreviewMode/CustomDashboardPreviewMode'
    ),
);
const CustomDashboardEditMode = lazy(
  () => import(/* webpackChunkName: "CustomDashboardEditMode" */ './CustomDashboardEditMode'),
);
const CustomDashboardManagementWrapper = lazy(
  () => import(/* webpackChunkName: "CustomDashboardManagementWrapper" */ './CustomDashboardManagementWrapper'),
);

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    height: 'calc(100% - 10px)',
    padding: '16px 8px 12px 8px',
    background: theme.vars.palette.bigid.white,
    border: theme.vars.tokens.bigid.borderDefault,
    boxShadow: theme.vars.tokens.bigid.shadow10,
    borderRadius: '6px',
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'column',
  },
}));

export const CustomDashboardGeneralWrapper: FC = () => {
  const { root } = useStyles({});
  const [state, dispatch] = useCustomDashboardGeneralManagerState();
  const {
    originalDashboardName,
    originalDashboardDescription,
    originalJsonAsString,
    dashboardNameToPreview,
    dashboardDescriptionToPreview,
    jsonAsStringToPreview,
    isLoading,
    isEditMode,
    dashboardId,
    dashboards,
    defaultDashboardId,
  } = state;

  useEffect(() => {
    dispatch({
      payload: {
        dashboardId: $stateParams.dashboardId,
        isEditMode: $stateParams.isEditMode,
      },
    });
  }, [dispatch]);

  useEffect(() => {
    dispatch({ payload: { isLoading: true } });
    const getAllDashboards = async () => {
      try {
        const [dashboardsList, defaultDashboard] = await Promise.all([
          getCustomDashboards(),
          getUserDefaultDashboard(),
        ]);

        dispatch({ payload: { defaultDashboardId: defaultDashboard } });

        const options: CustomDashboard[] = dashboardsList.map(
          ({ name, description, _id }: { name: string; description: string; _id: string }) => ({
            name,
            description,
            id: _id,
          }),
        );
        dispatch({ payload: { dashboards: options } });
      } catch (err) {
        notificationService.error('Problem fetching dashboards');
        console.error(err);
      }
      dispatch({ payload: { isLoading: false } });
    };

    getAllDashboards();
  }, [dispatch]);

  const resetDashboardState = useCallback(
    (config: string, name: string, description: string) => {
      dispatch({
        payload: {
          jsonAsString: config,
          jsonAsStringToPreview: config,
          dashboardName: name,
          dashboardNameToPreview: name,
          originalDashboardDescription: description,
          dashboardDescriptionToPreview: description,
        },
      });
    },
    [dispatch],
  );

  useEffect(() => {
    if (!dashboardId) return;
    if (dashboardId === NEW_DASHBOARD_ID) {
      resetDashboardState(
        $stateParams.jsonAsString || JSON.stringify(blankTemplate),
        $stateParams.dashboardName || 'New blank template',
        '',
      );
    } else {
      (async () => {
        dispatch({ payload: { isLoading: true } });
        try {
          const { config, name, description } = await getCustomDashboardById(dashboardId);
          resetDashboardState(config, name, description);
        } catch (err) {
          notificationService.error('Problem fetching dashboard');
          console.error('Problem fetching dashboard', err);
        }
        dispatch({ payload: { isLoading: false } });
      })();
    }
  }, [dashboardId, dispatch, resetDashboardState]);

  const handleCreateNewDashboard = async (dashboardName: string, jsonAsString?: string) => {
    const errors = await getCustomDashboardValidationErrors(JSON.parse(jsonAsString));

    $state.go('customDashboardPreview', {
      dashboardId: NEW_DASHBOARD_ID,
      jsonAsString,
      dashboardName,
      isEditMode: !!errors.length,
    });
  };

  const handleOnSave = async (name?: string, description?: string, jsonAsString?: string) => {
    const nameToSave = name || dashboardNameToPreview;
    const descriptionToSave = description || dashboardDescriptionToPreview;
    const jsonAsStringToSave = jsonAsString || jsonAsStringToPreview;

    try {
      const data =
        $stateParams.dashboardId === NEW_DASHBOARD_ID
          ? await addCustomDashboard(jsonAsStringToSave, nameToSave, descriptionToSave)
          : await updateCustomDashboard($stateParams.dashboardId, jsonAsStringToSave, nameToSave, descriptionToSave);

      notificationService.success(
        $stateParams.dashboardId === NEW_DASHBOARD_ID ? 'New Dashboard saved' : 'Dashboard saved',
      );

      if ($stateParams.dashboardId === NEW_DASHBOARD_ID && isPermitted(ACCESS_MANAGEMENT_PERMISSIONS.MANAGE.name)) {
        const shouldGoToPermissions = await showConfirmationDialog({
          entitiesCount: 1,
          entityNamePlural: 'none',
          entityNameSingular: `Assign roles to ${nameToSave}`,
          actionName: '',
          actionButtonName: 'Manage roles',
          cancelButtonName: 'Later',
          customDescription:
            'Access Management lets you manage user roles and define who can access the dashboard you just created.',
        });

        if (shouldGoToPermissions) {
          return $state.go(CONFIG.states.IDENTITY_ACCESS_MANAGEMENT, {
            currentTab: 'roles',
          });
        }
      }

      $state.go(
        'customDashboardPreview',
        {
          dashboardId: $stateParams.dashboardId === NEW_DASHBOARD_ID ? data.id : $stateParams.dashboardId,
          isEditMode: false,
        },
        { reload: true },
      );
    } catch (err) {
      notificationService.error('Problem occurred, Please try later');
    }
  };

  const handleOnDashboardSelect = (dashboardId: string) => {
    $state.go('customDashboardPreview', {
      dashboardId,
    });
  };

  const handleOnEditDashboard = () => {
    dispatch({ payload: { isEditMode: true } });
  };

  const handleCancelFromPreview = () => {
    $state.go('customDashboard');
  };

  const handleCancelFromEdit = () => {
    const { payload }: Action = {
      payload: {
        isEditMode: false,
        originalDashboardName: originalDashboardName,
        originalDashboardDescription: originalDashboardDescription,
        originalJsonAsString: originalJsonAsString,
      },
    };
    dispatch({ payload });
  };

  const handleOnPreview = (name: string, description: string, jsonAsString: string) => {
    const { payload }: Action = {
      payload: {
        isEditMode: false,
        dashboardNameToPreview: name,
        dashboardDescriptionToPreview: description,
        jsonAsStringToPreview: jsonAsString,
      },
    };
    dispatch({ payload });
  };

  const handleSetAsDefaultDashboard = async (id: string) => {
    try {
      let defaultIdToSet = id;
      let message = 'Custom dashboard set successfully';

      if (id === defaultDashboardId) {
        defaultIdToSet = 'Default';
        message = 'Default BigID dashboard set successfully';
      }
      await updateUserDefaultDashboard(defaultIdToSet);
      dispatch({ payload: { defaultDashboardId: defaultIdToSet } });
      notificationService.success(message);
    } catch (err) {
      console.error("Can't set default dashboard", err);
      notificationService.error('Problem set as default dashboard');
    }
  };

  const handleOnDeleteDashboard = async (id: string) => {
    try {
      const currentFavoriteDashboard = await getUserDefaultDashboard();
      await removeCustomDashboardById(id);
      if (currentFavoriteDashboard == id) updateUserDefaultDashboard('Default');
      const newDashboards = dashboards.filter((dashboard: CustomDashboard) => dashboard.id !== id);
      dispatch({ payload: { dashboards: newDashboards } });
    } catch (err) {
      notificationService.error('Problem delete dashboard');
      console.error(err);
    }
  };

  const loadScreenByStateName = (name: string) => {
    switch (true) {
      case name === 'customDashboard':
        return (
          <CustomDashboardManagementWrapper
            onCreateNewDashboard={handleCreateNewDashboard}
            onDashboardSelect={handleOnDashboardSelect}
            onDeleteDashboard={handleOnDeleteDashboard}
            onSetAsDefaultDashboard={handleSetAsDefaultDashboard}
            isLoading={isLoading}
            dashboards={dashboards}
            defaultDashboardId={defaultDashboardId}
            enableGeneralManage={isPermitted(CUSTOM_DASHBOARD_PERMISSIONS.MANAGE_ALL_CUSTOM_DASHBOARDS.name)}
          />
        );
      case name === 'customDashboardPreview' && !isEditMode:
        return (
          jsonAsStringToPreview && (
            <CustomDashboardPreviewMode
              dashboardId={dashboardId}
              dashboardConfig={JSON.parse(jsonAsStringToPreview)}
              name={dashboardNameToPreview}
              description={dashboardDescriptionToPreview}
              onSave={handleOnSave}
              onEdit={handleOnEditDashboard}
              onCancel={handleCancelFromPreview}
            />
          )
        );
      case name === 'customDashboardPreview' && isEditMode:
        const existingDashboardNames = dashboards
          .map((d: CustomDashboard) => d.name)
          .filter((name: string) => name !== dashboardNameToPreview);

        return (
          jsonAsStringToPreview !== null && (
            <CustomDashboardEditMode
              defaultName={dashboardNameToPreview}
              defaultDescription={dashboardDescriptionToPreview}
              defaultJsonAsString={jsonAsStringToPreview}
              onSave={handleOnSave}
              onPreview={handleOnPreview}
              onCancel={handleCancelFromEdit}
              existingDashboardsNames={existingDashboardNames}
            />
          )
        );
      default:
        return '';
    }
  };

  return (
    <Suspense fallback={<BigidLoader />}>
      {isLoading && <BigidLoader />}
      {!isLoading && (
        <div className={root} data-aid="CustomDashboardGeneralWrapper">
          {loadScreenByStateName($state.current.name)}
        </div>
      )}
    </Suspense>
  );
};
