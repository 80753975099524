import React, { FC, Fragment, useEffect, useState } from 'react';
import { capitalize, Divider } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import {
  BigidBody2,
  BigidChartChip,
  BigidColors,
  BigidPieChart,
  BigidTooltip,
  PieChartData,
} from '@bigid-ui/components';
import { AciEntityType } from './AccessIntelligenceInsightsService';

export interface DataSourceChartProps {
  dataSources: { [name: string]: number };
  minimized?: boolean;
  aciEntityType: AciEntityType;
}

const useStyles = makeStyles({
  wrapper: ({ minimized }: Partial<DataSourceChartProps>) => ({
    display: 'flex',
    alignItems: minimized ? 'center' : 'unset',
  }),
  minimizedContent: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
  },
  chipsWrapper: ({ minimized }: Partial<DataSourceChartProps>) => ({
    display: 'flex',
    alignItems: minimized ? 'center' : 'unset',
    flexDirection: !minimized ? 'column' : 'unset',
    justifyContent: !minimized ? 'center' : 'unset',
    marginLeft: !minimized ? 32 : 'unset',
  }),
  chartContainer: {
    display: 'flex',
    flexDirection: 'column',
    marginRight: 24,
  },
  divider: {
    background: BigidColors.borderLight,
    width: '100%',
    margin: '6px 0 16px 0',
  },
  title: {
    padding: '14px 0 6px 0',
  },
  usersDivider: {
    marginLeft: 12,
    marginRight: 10,
  },
});

const categoriesColors = [
  BigidColors.blue[500],
  BigidColors.orange[500],
  BigidColors.purple[500],
  BigidColors.yellow[500],
];

function getDataSourceChartData(dataSources: { [name: string]: number }) {
  const nomalisedDataSources = Object.entries(dataSources)
    .sort(([, firstCount], [, secondCount]) => secondCount - firstCount)
    .reduce((acc: { [name: string]: number }, [name, count]) => {
      if (Object.keys(acc).length >= categoriesColors.length - 1) {
        acc['Others'] = (acc['Others'] || 0) + count;
      } else {
        acc[name] = count;
      }

      return acc;
    }, {});

  const categories = Object.entries(nomalisedDataSources)
    .sort(([, firstCount], [, secondCount]) => secondCount - firstCount)
    .reduce((categories, [name, count], index) => {
      if (index < categoriesColors.length) {
        categories.push({ category: name, value: count, color: categoriesColors[index] });
      }

      return categories;
    }, []);

  const othersCategoryIndex = categories.findIndex(({ category }) => category === 'Others');

  if (othersCategoryIndex !== -1) {
    categories.push(categories.splice(othersCategoryIndex, 1)[0]);
  }

  return categories;
}

export const DataSourcesWithEntityAccessChart: FC<DataSourceChartProps> = ({
  dataSources,
  minimized,
  aciEntityType,
}) => {
  const classes = useStyles({ minimized });
  const [chartData, setChartData] = useState<PieChartData<string>[]>([]);
  const [total, setTotal] = useState<number>(0);

  useEffect(() => {
    if (Object.keys(dataSources).length > 0) {
      const data = getDataSourceChartData(dataSources);
      setChartData(data);
      setTotal(Object.values(dataSources).reduce((total, count) => total + count));
    } else {
      setChartData([]);
      setTotal(0);
    }
  }, [dataSources]);

  return (
    <Fragment>
      {!minimized &&
        (aciEntityType === AciEntityType.Groups ? (
          <BigidBody2>Top Data Source with Groups</BigidBody2>
        ) : (
          <BigidBody2>Top Data Source with Direct User Access</BigidBody2>
        ))}

      {!minimized && <Divider variant="middle" className={classes.divider} />}
      <div className={classes.wrapper}>
        <div>
          <BigidPieChart
            data={chartData}
            minimized={minimized}
            entityName={capitalize(aciEntityType)}
            total={total}
            onlyShowOnViewport={false}
          />
        </div>
        {minimized &&
          (aciEntityType === AciEntityType.Groups ? <BigidBody2>Groups</BigidBody2> : <BigidBody2>Users</BigidBody2>)}
        {minimized && <Divider className={classes.usersDivider} orientation="vertical" />}
        <div className={classes.chipsWrapper}>
          {chartData.map(({ color, category, value }, key) => (
            <BigidTooltip title={category} key={key} placement={'bottom'}>
              <div>
                {minimized && (
                  <BigidChartChip
                    color={color}
                    label={category}
                    value={value}
                    textOverFlowEllipsis={chartData.length > 3}
                  />
                )}
                {!minimized && <BigidChartChip color={color} label={category} value={value} />}
              </div>
            </BigidTooltip>
          ))}
        </div>
      </div>
    </Fragment>
  );
};
