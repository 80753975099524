// TODO: it is not good approach, we will have git conflicts when people adding new events in same line!
// TODO: consider moving it to "register" pattern
const MISC_EVENTS = {
  SUPPORT_AND_CONFIGURATION: 'SUPPORT_AND_CONFIGURATION',
  INVENTORY: 'INVENTORY',
  CORRELATION: 'CORRELATION',
  TASKS: 'TASKS',
  CLASSIFICATION: 'CLASSIFICATION',
  ACTION_CENTER_POLICIES: 'ACTION_CENTER_POLICIES',
  CLUSTER_ANALYSIS: 'CLUSTER_ANALYSIS',
  CATALOG: 'CATALOG',
  ACTION_CENTER_ACTIONS: 'ACTION_CENTER_ACTIONS',
  USER_NOTIFICATION: 'USER_NOTIFICATION',
  DASHBOARD_SWITCH: 'DASHBOARD_SWITCH',
  SCANS_SCAN_INSIGHTS: 'SCANS_SCAN_INSIGHTS',
  METERING_SUMMARY: 'METERING_SUMMARY',
  BUSINESS_GLOSSARY: 'BUSINESS_GLOSSARY',
} as const;

const ADMINISTRATION_EVENTS = {
  ADMINISTRATION_ACCESS_MANAGEMENT: 'ADMINISTRATION_ACCESS_MANAGEMENT',
  ADMINISTRATION_DATA_SOURCES: 'ADMINISTRATION_DATA_SOURCES',
  ADMINISTRATION_CORRELATION_SETS: 'ADMINISTRATION_CORRELATION_SETS',
  ADMINISTRATION_SECONDARY_SOURCES: 'ADMINISTRATION_SECONDARY_SOURCES',
  ADMINISTRATION_SCANS_OLD: 'ADMINISTRATION_SCANS_OLD',
  ADMINISTRATION_SCANS: 'ADMINISTRATION_SCANS',
  ADMINISTRATION_SCANS_ACTIVE_SCANS: 'ADMINISTRATION_SCANS_ACTIVE_SCANS',
  ADMINISTRATION_SCANS_COMPLETED_SCANS: 'ADMINISTRATION_SCANS_COMPLETED_SCANS',
  ADMINISTRATION_SCANS_SAVED_PROFILES: 'ADMINISTRATION_SCANS_SAVED_PROFILES',
  ADMINISTRATION_SCANS_SCAN_TEMPLATES: 'ADMINISTRATION_SCANS_SCAN_TEMPLATES',
  ADMINISTRATION_APPLICATION_SETUP: 'ADMINISTRATION_APPLICATION_SETUP',
  ADMINISTRATION_GENERAL_SETTINGS: 'ADMINISTRATION_GENERAL_SETTINGS',
  ADMINISTRATION_AUDIT: 'ADMINISTRATION_AUDIT',
  ADMINISTRATION_SENSITIVITY_CLASSIFICATION: 'ADMINISTRATION_SENSITIVITY_CLASSIFICATION',
  ADMINISTRATION_CERTIFICATIONS: 'ADMINISTRATION_CERTIFICATIONS',
  ADMINISTRATION_SCAN_WINDOWS: 'ADMINISTRATION_SCAN_WINDOWS',
  ADMINISTRATION_ADVANCED_TOOLS: 'ADMINISTRATION_SCAN_WINDOWS',
  ADMINISTRATION_COMPOUND_ATTRIBUTES: 'ADMINISTRATION_COMPOUND_ATTRIBUTES',
  ADMINISTRATION_CREDENTIALS: 'ADMINISTRATION_CREDENTIALS',
  ADMINISTRATION_CLASSIFIERS: 'ADMINISTRATION_CLASSIFIERS',
  ADMINISTRATION_CLASSIFIERS_MANAGEMENT: 'ADMINISTRATION_CLASSIFIERS_MANAGEMENT',
  GENERAL_SETTINGS_BUSINESS_GLOSSARY: 'GENERAL_SETTINGS_BUSINESS_GLOSSARY',
  ADMINISTRATION_SAVED_QUERIES: 'ADMINISTRATION_SAVED_QUERIES',
  ADMINISTRATION_TAGS: 'ADMINISTRATION_TAGS',
  ADMINISTRATION_DATA_RISK_MANAGEMENT: 'ADMINISTRATION_DATA_RISK_MANAGEMENT',
} as const;

const REPORTS_EVENTS = {
  REPORTS_SCANNED_FILES: 'REPORTS_SCANNED_FILES',
  REPORTS_FAILED_OBJECT_REPORT: 'REPORTS_FAILED_OBJECT_REPORT',
  REPORTS_LABELING_PROPAGATION_REPORT: 'REPORTS_LABELING_PROPAGATION_REPORT',
  REPORTS_METADATA_CLASSIFICATION_REPORT: 'REPORTS_METADATA_CLASSIFICATION_REPORT',
  REPORTS_DATA_CUSTODIAN: 'REPORTS_DATA_CUSTODIAN',
  REPORTS_FILES_ATTRIBUTES_DISTRIBUTION: 'REPORTS_FILES_ATTRIBUTES_DISTRIBUTION',
  REPORTS_TAGGED_ENTITIES: 'REPORTS_TAGGED_ENTITIES',
  REPORTS_USAGE_REPORT: 'REPORTS_USAGE_REPORT',
  REPORTS_EXECUTIVE_REPORT: 'REPORTS_EXECUTIVE_REPORT',
  REPORTS_ACTIVITY_HIGHLIGHTS: 'REPORTS_ACTIVITY_HIGHLIGHTS',
  REPORTS_DASHBOARD_MANAGER: 'REPORTS_DASHBOARD_MANAGER',
  REPORTS_SCAN_RESULTS_SUMMARY: 'REPORTS_SCAN_RESULTS_SUMMARY',
  REPORTS_SCAN_RESULTS_DETAILS: 'REPORTS_SCAN_RESULTS_DETAILS',
} as const;

const DATA_INSIGHTS_EVENTS = {
  DATAINSIGHTS: 'DATAINSIGHTS',
  DATAINSIGHTS_FILTERSPAGE_FILTERING_CLICK: 'DATAINSIGHTS_FILTERSPAGE_FILTERING_CLICK',
  DATAINSIGHTS_WIDGETS_WIDGETCLICKS_CLICK: 'DATAINSIGHTS_WIDGETS_WIDGETCLICKS_CLICK',
  DATAINSIGHTS_ACTIONS_GO_TO_CATALOG_CLICK: 'DATAINSIGHTS_ACTIONS_GO_TO_CATALOG_CLICK',
  DATAINSIGHTS_GRID_COMPONENT_SWITCHER_CLICK: 'DATAINSIGHTS_GRID_COMPONENT_SWITCHER_CLICK',
  DATAINSIGHTS_GRID_COMPONENT_APPLY_FILTER_CLICK: 'DATAINSIGHTS_GRID_COMPONENT_APPLY_FILTER_CLICK',
  DATAINSIGHTS_ACTIONS_EXPORT_REPORT_CLICK: 'DATAINSIGHTS_ACTIONS_EXPORT_REPORT_CLICK',
  DATAINSIGHTS_PAGE_INITIALISATION_TIME: 'DATAINSIGHTS_PAGE_INITIALISATION_TIME',
  DATAINSIGHTS_WIDGET_LOADING_TIME: 'DATAINSIGHTS_WIDGET_FILTERED_DATA_LOADING_TIME',
};

/** will be deprecated very soon, delete when deprecated */
const METADATA_SEARCH_BAR_EVENTS = {
  METADATA_SEARCH_BAR_SUBMIT: 'METADATA_SEARCH_BAR_SUBMIT',
  METADATA_SEARCH_BAR_FETCH_FILTERS: 'METADATA_SEARCH_BAR_FETCH_FILTERS',
};

const DATA_EXPLORER_EVENTS = {
  DATA_EXPLORER_SYSTEM_HEADER_BAR_SUBMIT: 'DATA_EXPLORER_TOP_BAR_SUBMIT',
  DATA_EXPLORER_LANDING_PAGE_BAR_SUBMIT: 'DATA_EXPLORER_LANDING_PAGE_BAR_SUBMIT',
  DATA_EXPLORER_LANDING_PAGE_LINK_CLUSTERING: 'DATA_EXPLORER_LANDING_PAGE_LINK_CLUSTERING',
  DATA_EXPLORER_LANDING_PAGE_LINK_CORRELATION: 'DATA_EXPLORER_LANDING_PAGE_LINK_CORRELATION',
  DATA_EXPLORER_LANDING_PAGE_LINK_CLASSIFICATION: 'DATA_EXPLORER_LANDING_PAGE_LINK_CLASSIFICATION',
  DATA_EXPLORER_LANDING_PAGE_LINK_DATA_OVERVIEW: 'DATA_EXPLORER_LANDING_PAGE_LINK_DATA_OVERVIEW',
  DATA_EXPLORER_LANDING_PAGE_LINK_POLICIES: 'DATA_EXPLORER_LANDING_PAGE_LINK_POLICIES',
  DATA_EXPLORER_LANDING_PAGE_LINK_RECENTLY_DISC_DS: 'DATA_EXPLORER_LANDING_PAGE_LINK_RECENTLY_DISC_DS',
  DATA_EXPLORER_LANDING_PAGE_LINK_RECENTLY_VIEW_OBJ: 'DATA_EXPLORER_LANDING_PAGE_LINK_RECENTLY_VIEW_OBJ',
  DATA_EXPLORER_LANDING_PAGE_LINK_FOLLOWED_OBJ: 'DATA_EXPLORER_LANDING_PAGE_LINK_FOLLOWED_OBJ',
  DATA_EXPLORER_LANDING_PAGE_LINK_BROWSE_CATALOG: 'DATA_EXPLORER_LANDING_PAGE_LINK_BROWSE_CATALOG',
  DATA_EXPLORER_RESULTS_PAGE_BAR_SUBMIT: 'DATA_EXPLORER_RESULTS_PAGE_BAR_SUBMIT',
  DATA_EXPLORER_RESULTS_PAGE_SELECT_ALL: 'DATA_EXPLORER_RESULTS_PAGE_SELECT_ALL',
  DATA_EXPLORER_RESULTS_PAGE_ACTION_ADD_TAG_OPEN: 'DATA_EXPLORER_RESULTS_PAGE_ACTION_ADD_TAG_OPEN',
  DATA_EXPLORER_RESULTS_PAGE_ACTION_ADD_TAG_CLOSE: 'DATA_EXPLORER_RESULTS_PAGE_ACTION_ADD_TAG_CLOSE',
  DATA_EXPLORER_RESULTS_PAGE_ACTION_ADD_TAG_SUBMIT: 'DATA_EXPLORER_RESULTS_PAGE_ACTION_ADD_TAG_SUBMIT',
  DATA_EXPLORER_RESULTS_PAGE_ACTION_REMOVE_TAG_OPEN: 'DATA_EXPLORER_RESULTS_PAGE_ACTION_REMOVE_TAG_OPEN',
  DATA_EXPLORER_RESULTS_PAGE_ACTION_REMOVE_TAG_CLOSE: 'DATA_EXPLORER_RESULTS_PAGE_ACTION_REMOVE_TAG_CLOSE',
  DATA_EXPLORER_RESULTS_PAGE_ACTION_REMOVE_TAG_SUBMIT: 'DATA_EXPLORER_RESULTS_PAGE_ACTION_REMOVE_TAG_CLOSE',
  DATA_EXPLORER_RESULTS_PAGE_ACTION_EXPORT_SUBMIT: 'DATA_EXPLORER_RESULTS_PAGE_ACTION_EXPORT_SUBMIT',
  DATA_EXPLORER_RESULTS_PAGE_ACTION_SHARE_URL_OPEN: 'DATA_EXPLORER_RESULTS_PAGE_ACTION_SHARE_URL_OPEN',
  DATA_EXPLORER_RESULTS_PAGE_ACTION_SHARE_URL_CLOSE: 'DATA_EXPLORER_RESULTS_PAGE_ACTION_SHARE_URL_CLOSE',
  DATA_EXPLORER_RESULTS_PAGE_ACTION_SHARE_URL_COPY: 'DATA_EXPLORER_RESULTS_PAGE_ACTION_SHARE_URL_COPY',
  DATA_EXPLORER_RESULTS_PAGE_TAB_SWITCH: 'DATA_EXPLORER_RESULTS_PAGE_TAB_SWITCH',
  DATA_EXPLORER_RESULTS_PAGE_FILTER_SELECT: 'DATA_EXPLORER_RESULTS_PAGE_FILTER_CHANGE',
  DATA_EXPLORER_QUICK_SEARCH_API_REQUEST: 'DATA_EXPLORER_QUICK_SEARCH_API_REQUEST',
  DATA_EXPLORER_QUICK_SEARCH_VIEW_ALL_CLICK: 'DATA_EXPLORER_QUICK_SEARCH_VIEW_ALL_CLICK',
  DATA_EXPLORER_QUICK_SEARCH_LIST_ITEM_CLICK: 'DATA_EXPLORER_QUICK_SEARCH_LIST_ITEM_CLICK',
  DATA_EXPLORER_QUICK_SEARCH_RECENTLY_VIEW_OBJ_CLICK: 'DATA_EXPLORER_QUICK_SEARCH_RECENTLY_VIEW_OBJ_CLICK',
};

const WORKSPACES_EVENTS = {
  WORKSPACES_LEFT_NAV_ITEM_CLICK: 'WORKSPACES_LEFT_NAV_ITEM_CLICK',
  WORKSPACES_BENTO_WORKSPACE_SELECT: 'WORKSPACES_BENTO_WORKSPACE_SELECT',
  WORKSPACES_HEADER_ICON_CLICK: 'WORKSPACES_HEADER_ICON_CLICK',
  WORKSPACES_GUIDED_TOUR: 'WORKSPACES_GUIDED_TOUR',
};

export const BIGID_BI_EVENTS = {
  ...MISC_EVENTS,
  ...ADMINISTRATION_EVENTS,
  ...REPORTS_EVENTS,
  ...DATA_INSIGHTS_EVENTS,
  ...METADATA_SEARCH_BAR_EVENTS,
  ...DATA_EXPLORER_EVENTS,
  ...WORKSPACES_EVENTS,
} as const;
