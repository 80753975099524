import React, { FC } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { BigidAuditIllustration } from '@bigid-ui/icons';
import { BigidLayoutEmptyState } from '@bigid-ui/layout';

const useStyles = makeStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
    gap: '8px',
    alignItems: 'center',
    marginBottom: '8px',
  },
  message: {
    maxWidth: '390px',
  },
});

export const PresetEmptyState: FC = () => {
  const classes = useStyles({});

  return (
    <div className={classes.root}>
      <BigidLayoutEmptyState
        illustration={BigidAuditIllustration}
        description="There are no parameters defined yet in this preset. Adding parameters can be made by your developer."
      />
    </div>
  );
};
