import React, { FC, useState, useEffect } from 'react';
import angular from 'angular';
import makeStyles from '@mui/styles/makeStyles';
import { convertToAngular } from '../../../../../../common/services/convertToAngular';
import { BigidCollapsable } from '../../../../../components/BigidCollapsable/BigidCollapsable';
import BigidTrashCanIcon from '../../../../../assets/icons/BigidTrashCan.svg';
import { PrefixDataSourceGrid } from './PrefixDataSourceGrid';
import { SingleSelectedDataSourcesGrid } from './SingleSelectedDataSourcesGrid';
import { getApplicationPreference } from '../../../../../services/appPreferencesService';

interface DataSourceExpressions {
  operator: string;
  value: string;
}

export interface Scope {
  id: string;
  name: string;
  dataSourceNames?: string[];
  dataSourceExpressions?: DataSourceExpressions[];
  idSourceNames?: string[];
}

interface ScopesDataSourcesContentProps {
  scope: Scope;
  onRemovePrefix: (value: string) => void;
  onRemoveSingle: (value: string[]) => void;
  isRoot: boolean;
}

const useStyles = makeStyles({
  emptyState: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: 35,
  },
  root: { margin: '10px 0', height: '100%' },
  trashCanIcon: { paddingRight: 11, display: 'flex', cursor: 'pointer' },
});

export const SINGLE_SELECT_DATA_SOURCES = 'Single Select Data Sources';

export const ScopesDataSourcesContent: FC<ScopesDataSourcesContentProps> = ({
  scope: { dataSourceNames = [], dataSourceExpressions = [], name },
  onRemovePrefix,
  onRemoveSingle,
  isRoot,
}) => {
  const classes = useStyles({});
  const [openCollapsable, setOpenCollapsable] = useState<string>('');
  const isWildcardScope = getApplicationPreference('WILDCARD_SCOPING');

  useEffect(() => {
    setOpenCollapsable('');
  }, [name]);

  if (dataSourceNames.length === 0 && dataSourceExpressions.length === 0) {
    return <div className={classes.emptyState}>No Data Sources Connected</div>;
  }

  return (
    <div className={classes.root}>
      {dataSourceNames.length > 0 && (
        <BigidCollapsable
          isOpen={openCollapsable === SINGLE_SELECT_DATA_SOURCES}
          leftHeader={SINGLE_SELECT_DATA_SOURCES}
          onCollapseClick={() =>
            setOpenCollapsable(openCollapsable !== SINGLE_SELECT_DATA_SOURCES ? SINGLE_SELECT_DATA_SOURCES : '')
          }
          dataAid="singleSelectedDS"
        >
          {openCollapsable === SINGLE_SELECT_DATA_SOURCES && (
            <SingleSelectedDataSourcesGrid onRemove={onRemoveSingle} dataSourceNames={dataSourceNames} />
          )}
        </BigidCollapsable>
      )}
      {isWildcardScope &&
        dataSourceExpressions.map(({ value }) => (
          <BigidCollapsable
            key={value}
            isOpen={openCollapsable === value}
            leftHeader={value}
            rightHeader={
              isRoot && (
                <div className={classes.trashCanIcon} onClick={() => onRemovePrefix(value)}>
                  <BigidTrashCanIcon />
                </div>
              )
            }
            onCollapseClick={() => setOpenCollapsable(openCollapsable !== value ? value : '')}
            dataAid={`prefixDS-${value}`}
          >
            {openCollapsable === value && <PrefixDataSourceGrid prefix={value} />}
          </BigidCollapsable>
        ))}
    </div>
  );
};

angular
  .module('app')
  .component(
    'scopesDataSourcesContent',
    convertToAngular(ScopesDataSourcesContent, ['scope', 'onRemovePrefix', 'onRemoveSingle', 'isRoot']),
  );
