import { useEffect, useState } from 'react';
import { QueryFunction, QueryKey, UseQueryOptions, UseQueryResult, useQuery } from 'react-query';
import { systemUsersService } from '../../../../services/angularServices';
import { User, getUsersQuery, DEFAULT_MAX_USERS_IN_REQUEST } from '../../../../utilities/systemUsersUtils';
import { useDebounce } from './useDebounce';
import type { AsyncReturnType } from '../types';
import type { ErrorType } from '../../../../config/request';
import type { BigidFilter } from '@bigid-ui/components';

type GetUsersQueryError = ErrorType<unknown>;

type UseSearchUsersOptions = {
  filter?: BigidFilter;
  maxUsers?: number;
};

type UseGetUsersOptions = typeof useGetUsers extends (query: string, options?: infer T) => unknown ? T : never;

const DEFAULT_USERS_TOTAL_COUNT = 0;
const DEFAULT_DEBOUNCE_INTERVAL = 400;

export const useSearchUsers = (searchTerm: string, options?: UseSearchUsersOptions, queryOpts?: UseGetUsersOptions) => {
  const { maxUsers = DEFAULT_MAX_USERS_IN_REQUEST, ...rest } = options ?? {};
  const [count, setCount] = useState(DEFAULT_USERS_TOTAL_COUNT);
  const debouncedTerm = useDebounce(searchTerm, DEFAULT_DEBOUNCE_INTERVAL);

  const query = getUsersQuery({
    filter: [{ field: 'name', operator: 'textSearch', value: debouncedTerm }],
    maxUsers,
    ...rest,
  });

  const { data, ...usersQuery } = useGetUsers(query, {
    query: {
      ...queryOpts?.query,
    },
  }) as UseQueryResult<User[], GetUsersQueryError>;

  useEffect(() => {
    // @info get count from returned 'totalCount' prop in case pagination is required
    setCount(data?.length ?? DEFAULT_USERS_TOTAL_COUNT);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return { ...usersQuery, data, totalCount: count };
};

// @info get system users
export const getUsers = async (query: string): Promise<{ data: { users: User[]; totalCount: number } }> =>
  systemUsersService?.getAllSystemUsersByQuery?.(query);

export const getUsersQueryKey = (query: string): Array<unknown> => [query];

export const useGetUsers = <TData = AsyncReturnType<typeof getUsers>, TError = GetUsersQueryError>(
  query: string,
  options?: { query?: UseQueryOptions<AsyncReturnType<typeof getUsers>, TError, TData> },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options || {};

  const queryKey = queryOptions?.queryKey ?? getUsersQueryKey(query);
  const queryFn: QueryFunction<AsyncReturnType<typeof getUsers>> = async () => getUsers(query);
  const result = useQuery<AsyncReturnType<typeof getUsers>, TError, TData>(queryKey, queryFn, {
    enabled: !!query,
    ...queryOptions,
  });

  return {
    queryKey,
    ...result,
  };
};
