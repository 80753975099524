import { useEffect, useMemo, useState } from 'react';
import { BigidDropdownOption } from '@bigid-ui/components';
import { mapCategoriesToDropdownValues, mapLegacyCategoriesToDropdownValues } from '../utils/utils';
import { Category } from '../types/ClassifierTypes';
import { ASSIGN_CATEGORIES_TYPE, AssignCategories } from '../types/AssignCategories';
import { getCategoriesDetails } from '../services/classifiersManagementService';

export const useCategories = (attributes: AssignCategories[], assignType: ASSIGN_CATEGORIES_TYPE) => {
  const [categories, setCategories] = useState<Category[]>([]);

  const categoriesList: BigidDropdownOption[] = useMemo(() => {
    return mapLegacyCategoriesToDropdownValues(categories);
  }, [categories]);

  useEffect(() => {
    const fetchCategories = async () => {
      const catagoriesResponse = await getCategoriesDetails();
      setCategories(catagoriesResponse.data);
    };
    fetchCategories();
  }, []);

  return {
    categoriesList,
    initialSelectedCategories:
      assignType === ASSIGN_CATEGORIES_TYPE.DEFAULT
        ? mapCategoriesToDropdownValues(attributes[0]?.categories as any)
        : [],
    setCategories,
  };
};
