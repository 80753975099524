import React, { FC } from 'react';
import { TertiaryButton } from '@bigid-ui/components';
import { BigidSyncIcon } from '@bigid-ui/icons';
import styled from '@emotion/styled';
import { generateDataAid } from '@bigid-ui/utils';
import { getFixedT } from '../translations';

const buttonText = `${getFixedT('resetWidget')('button')}`;

const Wrapper = styled('div')({
  margin: '15px 0px 15px 0px',
  display: 'flex',
  padding: '10px 8px',
  justifyContent: 'center',
  alignItems: 'center',
  gap: '4px',
  borderRadius: '4px',
});

export const BigChatReset: FC = (props: any) => {
  const { actions } = props;

  const handleOnClick = (): void => {
    return actions.initMessages();
  };

  return (
    <Wrapper data-aid={`${generateDataAid('BigChat', ['reset', 'button'])}`}>
      <TertiaryButton text={buttonText} onClick={handleOnClick} size="large" startIcon={<BigidSyncIcon />} />
    </Wrapper>
  );
};
