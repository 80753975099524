import React, { FC } from 'react';
import classnames from 'classnames';
import makeStyles from '@mui/styles/makeStyles';
import { BigidLink, BigidColors } from '@bigid-ui/components';
import BigidLogoIcon from '../../../../assets/icons/BigidLogoNoText.svg';
import { publicUrls } from '../../../../config/publicUrls';

const useStyles = makeStyles({
  root: { display: 'flex' },
  icon: { marginRight: 24 },
  text: { fontSize: '0.875rem', color: BigidColors.gray[700], marginBottom: 10 },
  bold: { fontWeight: 'bold' },
});

export const LicenseUpgradeFlow: FC = () => {
  const classes = useStyles({});
  return (
    <div className={classes.root}>
      <div>
        <BigidLogoIcon className={classes.icon} />
      </div>
      <div>
        <div
          className={classes.text}
        >{`SmallID is the best way to discover and manage personal and sensitive data across your AWS services and other data sources.`}</div>
        <div
          className={classes.text}
        >{`Upgrade to BigID and get unmatched data intelligence for privacy, protection and perspective across your entire data ecosystem - for all types of data, everywhere.`}</div>
        <div className={classes.text}>
          {`See the full list of BiglD upgrade benefits `}
          <BigidLink href={publicUrls.BIGID_SMALLID} text="here" shouldOpenNewTab />
          {'.'}
        </div>
        <div className={classes.text}>
          {`Contact BigID `}
          <BigidLink href="mailto:sales@bigid.com" text="Sales@BigID.com" shouldOpenNewTab />
          {` to unlock the full power of BigID.`}
        </div>
        <div className={classnames(classes.text, classes.bold)}>{`— BigID Team`}</div>
      </div>
    </div>
  );
};
