import React, { useEffect, useState } from 'react';
import { Divider } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { BigidColors } from '@bigid-ui/components';
import classnames from 'classnames';
import {
  AciEntityType,
  getAciEntitiesByDataSourceInsight,
  getAciEntitiesByVisibilityInsight,
} from './AccessIntelligenceInsightsService';
import { DataSourcesWithEntityAccessChart } from './DataSourcesWithEntityAccessChart';
import { Visibility, VisibilityChart } from './VisibilityChart';
import { BigidInsightsComponent } from '@bigid-ui/layout';

const useStyles = makeStyles({
  content: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
  },
  chartWrapper: {
    display: 'flex',
    flexDirection: 'column',
    width: '38%',
  },
  divider: {
    background: BigidColors.borderLight,
    margin: '0 32px 0 32px',
  },
});

const useMinimizedStyles = makeStyles({
  content: { display: 'flex', justifyContent: 'center', width: '100%' },
  layout: {
    display: 'flex',
    maxHeight: 50,
    overflowX: 'auto',
    overflowY: 'hidden',
  },
});

export const getAccessIntelligenceInsight = (aciEntityType: AciEntityType) => {
  const getInsights = async () => {
    const visibilities = await getAciEntitiesByVisibilityInsight(aciEntityType);
    const dataSourceInsight = await getAciEntitiesByDataSourceInsight(aciEntityType);
    return [visibilities, dataSourceInsight];
  };

  const Insights: BigidInsightsComponent = ({ minimized }) => {
    const classes = useStyles({});
    const minimizedClasses = useMinimizedStyles({});

    const [visibilities, setVisibilities] = useState<Visibility>({});
    const [dataSources, setDataSources] = useState<{ [name: string]: number }>({});

    useEffect(() => {
      const getInsightsCaller = async () => {
        const [visibilities, dataSourceInsight] = await getInsights();
        setVisibilities(visibilities);
        setDataSources(dataSourceInsight);
      };
      getInsightsCaller();
    }, []);

    return minimized ? (
      <div className={minimizedClasses.content}>
        <div className={classnames(classes.chartWrapper, minimizedClasses.layout)}>
          <VisibilityChart aciEntityType={aciEntityType} visibilities={visibilities} minimized />
        </div>

        <Divider variant="middle" className={classes.divider} orientation="vertical" />

        <div className={minimizedClasses.layout}>
          <DataSourcesWithEntityAccessChart aciEntityType={aciEntityType} dataSources={dataSources} minimized />
        </div>
      </div>
    ) : (
      <div className={classes.content}>
        <div className={classes.chartWrapper}>
          <VisibilityChart aciEntityType={aciEntityType} visibilities={visibilities} />
        </div>
        <Divider variant="middle" className={classes.divider} orientation="vertical" />
        <div className={classes.chartWrapper}>
          <DataSourcesWithEntityAccessChart aciEntityType={aciEntityType} dataSources={dataSources} />
        </div>
      </div>
    );
  };

  return Insights;
};
