import { $state, localStorageService } from '../../services/angularServices';
import { smallIdLicenseService } from '../../services/smallIdLicenseService';
import { smallidOnboardingService } from '../../services/smallIdOnboardingService';
import { openOnBoardingModal } from '../../components/StartOnboardingModal/onboardingModalService';
import { areSomePermitted, isPermitted } from '../../services/userPermissionsService';
import { DASHBOARD_PERMISSIONS } from '@bigid/permissions';
import { CONFIG } from '../../../config/common';
import { StateParams } from '@uirouter/core';
import { loginService } from '../../../authentication/login/login.service';
import { getApplicationPreference } from '../../services/appPreferencesService';
import { userPreferencesService } from '../../services/userPreferencesService';
import { sessionStorageService } from '../../../common/services/sessionStorageService';
import { getLoginOverrides } from './config/login';
import { LoginStrategies, ResolvedLoginOverride } from './types';
import { getSelectedWorkspace, getSelectedWorkspaceSettings } from '../../services/workspacesService';
import { isWorkspacesEnabled } from '../../utilities/featureFlagUtils';

type RequestedPageType = {
  name: string;
  queryParams: StateParams;
};

const checkForNavigationOverrides = async (): Promise<ResolvedLoginOverride> => {
  const loginNavigationOverrides = await getLoginOverrides();
  return loginNavigationOverrides?.find(({ isPermitted }) => isPermitted);
};

export const goToRequestedPage = async () => {
  const redirectPathInEnv = await getRedirectPath();
  const requestedPage: RequestedPageType = localStorageService.get('requestedPage');
  const isRootPath: boolean = localStorageService.get('isRootPath');
  const redirectToWorkspaceHomePage = (isRootPath || !requestedPage) && isWorkspacesEnabled();
  const navigationOverride = await checkForNavigationOverrides();

  if (navigationOverride) {
    const { action: handleOverride, isFinal } = navigationOverride;
    handleOverride();
    if (isFinal) return;
  }

  if (
    redirectPathInEnv &&
    !redirectToWorkspaceHomePage &&
    (!requestedPage || requestedPage?.name === CONFIG.states.DASHBOARD)
  ) {
    window.location.href = `#/${redirectPathInEnv}`;
    return;
  }

  const onBoardingService = smallIdLicenseService.isSmallIDLicense() ? smallidOnboardingService : openOnBoardingModal;

  if (getApplicationPreference('IS_SMALLID_FOR_DEV')) {
    return $state.go(CONFIG.states.SMALLID_FOR_DEVELOPERS).then(onBoardingService);
  }

  if (requestedPage && !redirectToWorkspaceHomePage) {
    return smallIdLicenseService.isAccessGranted(requestedPage.name)
      ? $state.go(requestedPage.name, requestedPage.queryParams).then(onBoardingService)
      : $state.go(smallIdLicenseService.getSmallIDLandingPage()).then(onBoardingService);
  }

  let landingPage = isPermitted(DASHBOARD_PERMISSIONS.ACCESS.name) ? CONFIG.states.DASHBOARD : CONFIG.states.TASKS_LIST;
  let landingPageParams = {};

  if (redirectToWorkspaceHomePage) {
    const workspacesSettingsPreferences = await getSelectedWorkspaceSettings(getSelectedWorkspace().id);
    if (workspacesSettingsPreferences?.lastVisitedPage) {
      if (workspacesSettingsPreferences.lastVisitedPage === CONFIG.states.CUSTOM_APP) {
        landingPageParams = {
          appRoute: workspacesSettingsPreferences.appRoute,
          id: workspacesSettingsPreferences.appId,
        };
      }
      landingPage = workspacesSettingsPreferences.lastVisitedPage;
    } else {
      landingPage = getSelectedWorkspace().getHomePage() ?? landingPage;
    }
  }

  return $state
    .go(
      smallIdLicenseService.isAccessGranted(landingPage) ? landingPage : smallIdLicenseService.getSmallIDLandingPage(),
      { ...landingPageParams },
    )
    .then(onBoardingService);
};

const getRedirectPath = async () => {
  const isElasticEnabled = getApplicationPreference('DATA_OVERVIEW_ENABLED');
  const isLandingPageOverWritten = getApplicationPreference('DEFAULT_LANDING_PAGE');

  const defaultRedirectPath =
    isElasticEnabled && !isLandingPageOverWritten
      ? CONFIG.states.DATA_OVERVIEW
      : getApplicationPreference('DEFAULT_LANDING_PAGE');

  try {
    const useLandingPagePerUser = getApplicationPreference('ENABLE_LANDING_PAGE_PER_USER_FF');
    const landingPagePerUser = useLandingPagePerUser && (await userPreferencesService.get('landingPage'))?.data?.value;
    return landingPagePerUser || defaultRedirectPath;
  } catch (err) {
    console.error(err);
    return defaultRedirectPath;
  }
};

export const loginWithSSO = (userInfoRespondJson: string) => {
  if (userInfoRespondJson) {
    const decodedResponse = decodeURIComponent(userInfoRespondJson);
    const userInfoRespondObj = jsonParseSafe(atob(decodedResponse), null);
    loginService.performUserLoginFlow(userInfoRespondObj).then(() => goToRequestedPage());
  }
};

export const validateStringAndCompare = (valueToCompare: string, equalTo: string) => {
  return valueToCompare?.toUpperCase?.() === equalTo;
};

export const jsonParseSafe = (json: string, fallbackValue: { error: string }) => {
  try {
    return JSON.parse(json);
  } catch (e) {
    console.error(`Error parsing JSON in the login component, json:`, json);
    return fallbackValue;
  }
};

export const getErrorDisplayMessage = (errorCode?: string) => {
  const decodedError = atob(decodeURIComponent(errorCode));
  const errorToDisplay = jsonParseSafe(decodedError, { error: 'Unknown error occurred.' });
  return JSON.stringify(errorToDisplay?.error);
};

export const shouldDenySamlLogin = (encodedErrorObject: string): boolean => {
  const decodedError = atob(decodeURIComponent(encodedErrorObject));
  const errorObject = jsonParseSafe(decodedError, { error: 'Unknown error occurred.' });
  const { statusCode, errors } = errorObject.error || {};
  return statusCode === 401 && errors[0]?.isSamlDeny;
};

export const setLoginStrategyInSessionStorage = (isSamlIdpEnabled: boolean, isCloudIdpEnabled: boolean): void => {
  if (isSamlIdpEnabled) {
    sessionStorageService.set('LOGIN_STRATEGY', LoginStrategies.SAML);
  } else if (isCloudIdpEnabled) {
    sessionStorageService.set('LOGIN_STRATEGY', LoginStrategies.AUTH0);
  } else {
    sessionStorageService.set('LOGIN_STRATEGY', LoginStrategies.DEFAULT);
  }
};

export const isPagePermitted = (stateName: string): boolean => {
  const states = $state.get();
  const {
    data: { permission, permissions },
  } = states.find(state => state.name === stateName);
  return permissions ? areSomePermitted(permissions) : permission ? isPermitted(permission) : true;
};
