import './groupPicker.component.scss';
import template from './groupPicker.component.html';
import angular from 'angular';

angular.module('app').component('groupPicker', {
  template,
  controller: [
    function () {
      let ctrl = this;

      ctrl.check = () => {
        if (ctrl.checked) {
          ctrl.updateSelectedFields('Insert');
          return;
        }
        ctrl.updateSelectedFields('Remove');
      };

      let setPersonalDataFields = (personalDataItems, fieldName, type) => {
        personalDataItems.forEach((personalDataItem, idx) => {
          if (
            !this.entity.selectedFields.find(
              item =>
                item.fieldName === personalDataItem.type ||
                item.fieldName === personalDataItem[fieldName] ||
                item.fieldName === personalDataItem,
            )
          ) {
            this.addSelectedFields(personalDataItem, fieldName, type);
          }
        });
      };

      this.setAttributeIsFromSystem = (attributeObject, attributesFromSystem) => {
        attributeObject.is_fromSystem = false;
        if (attributesFromSystem && attributesFromSystem.length > 0) {
          let attributeFromSystem = attributesFromSystem.find(
            attributeFromSystem =>
              attributeFromSystem._id === attributeObject.attribute || attributeFromSystem._id === attributeObject._id,
          ); //attributeObject.attribute is from entity model - attributeObject._id is from System
          if (attributeFromSystem) {
            attributeObject.is_fromSystem = true;
          }
        }
        return attributeObject;
      };

      this.addSelectedFields = (attributeObject, fieldName, type) => {
        if (
          !this.entity.selectedFields.find(
            field =>
              attributeObject.hasOwnProperty('display_name') &&
              (field.fieldName === attributeObject.display_name ||
                field.fieldName === attributeObject[type] ||
                field.fieldName === attributeObject[fieldName] ||
                field.fieldName === attributeObject),
          )
        ) {
          attributeObject = this.setAttributeIsFromSystem(attributeObject, this.entity.attributesFromSystem);
          this.entity.selectedFields.push({
            fieldName:
              attributeObject.display_name ||
              attributeObject[type] ||
              attributeObject[fieldName] ||
              attributeObject ||
              '',
            fieldValue: attributeObject.purpose || '',
            is_excluded: attributeObject.is_excluded ? attributeObject.is_excluded : false,
            is_fromSystem: attributeObject.is_fromSystem,
            type:
              attributeObject.hasOwnProperty('display_name') || attributeObject.hasOwnProperty('category')
                ? 'category'
                : 'purpose',
          });

          Object.assign(
            this.entity.selectedFields[this.entity.selectedFields.length - 1],
            attributeObject.purpose_id ? { purpose_id: attributeObject.purpose_id } : null,
          );
          Object.assign(
            this.entity.selectedFields[this.entity.selectedFields.length - 1],
            attributeObject.attribute_id ? { attribute_id: attributeObject.attribute_id } : null,
          );
          Object.assign(
            this.entity.selectedFields[this.entity.selectedFields.length - 1],
            attributeObject.category_id ? { category_id: attributeObject.category_id } : null,
          );
          Object.assign(
            this.entity.selectedFields[this.entity.selectedFields.length - 1],
            attributeObject.is_excluded ? attributeObject.is_excluded : false,
          );
          Object.assign(
            this.entity.selectedFields[this.entity.selectedFields.length - 1],
            attributeObject.glossary_id && type === 'attribute' ? { attribute_id: attributeObject.glossary_id } : null,
          );
          Object.assign(
            this.entity.selectedFields[this.entity.selectedFields.length - 1],
            attributeObject.glossary_id && type === 'category' ? { category_id: attributeObject.glossary_id } : null,
          );
        }
      };

      ctrl.updateSelectedFields = state => {
        switch (state) {
          case 'Insert': {
            if (!this.entity.selectedFields) {
              this.entity.selectedFields = [];
            }
            switch (this.group) {
              case 'attributes': {
                let attributes = this.entity.attributes;
                if (attributes && attributes.length > 0) {
                  setPersonalDataFields(attributes, 'friendly_name', 'attribute');
                }
                let categories = this.entity.categories;
                if (categories && categories.length > 0) {
                  setPersonalDataFields(categories, 'category', 'category');
                }

                //in case the user selected the attributes without any attributes
                if (!attributes && !categories) {
                  this.entity.selectedFields.push({
                    fieldName: '',
                    fieldValue: '',
                    type: 'purpose',
                  });
                }

                let attributesFromSystem = this.entity.attributesFromSystem;
                if (attributesFromSystem && attributesFromSystem.length > 0) {
                  setPersonalDataFields(attributesFromSystem, 'friendly_name', 'attribute');
                }
                let categoriesFromSystem = this.entity.categoriesFromSystem;
                if (categoriesFromSystem && categoriesFromSystem.length > 0) {
                  setPersonalDataFields(categoriesFromSystem, 'category', 'category');
                }
                break;
              }
              default: {
                let fields = this.fieldsName.split(',');
                if (fields) {
                  fields.forEach((field, idx) => {
                    if (this.fieldsName) {
                      if (field === 'additionalApps' || field === 'additionalDataSources') {
                        let group = this.group;
                        if (this.fieldsValue[idx]) {
                          this.fieldsValue[idx].forEach(value => {
                            this.entity.selectedFields.push({
                              fieldName: group + '.' + field || '',
                              fieldValue: value,
                              type: 'field',
                            });
                          });
                        }
                      } else {
                        this.entity.selectedFields.push({
                          fieldName: this.group + '.' + field || '',
                          fieldValue: this.fieldsValue[idx],
                          type: 'field',
                        });
                      }
                    }
                  });
                }
              }
            }
            break;
          }
          case 'Remove': {
            let groupName = this.group;
            if (groupName === 'attributes') {
              this.entity.selectedFields = this.entity.selectedFields.filter(function (el) {
                return !(el.type === 'purpose');
              });
              return;
            }
            this.entity.selectedFields = this.entity.selectedFields.filter(function (el) {
              return !el.fieldName.includes(groupName);
            });
            break;
          }
        }
      };
    },
  ],
  bindings: {
    entity: '<',
    group: '@',
    fieldsValue: '<',
    fieldsName: '@',
  },
});
