import { BigidGridColumn, BigidGridColumnTypes } from '@bigid-ui/grid';
import { BigidStateProgressBarStatus } from '@bigid-ui/components';
import { MetadataModel, ScanState } from '../../../../AutoDiscoveryWizard/MetadataConnectionTypes';
import { formatBytes } from '../../../../../utilities/numericDataConverter';
import { getFixedT } from '../../../translations';

const t = getFixedT('Discover.tableColumns');

export type ExplorerGridColumn = BigidGridColumn<MetadataModel>[];

const getPercentage = (percentage: string, state: ScanState): number => {
  if (state === ScanState.FAILED) {
    return 0;
  }

  return Math.round(parseInt(percentage));
};

const getScanStatus = (state: ScanState): BigidStateProgressBarStatus => {
  if (state === ScanState.FAILED || state === ScanState.COMPLETED || state === ScanState.STOPPED) {
    return BigidStateProgressBarStatus.STATUS;
  }
  if (state === ScanState.PAUSED) {
    return BigidStateProgressBarStatus.PAUSED;
  }

  return BigidStateProgressBarStatus.REGULAR;
};

const getScanText = (percentage: number, state: ScanState) => {
  if (
    state === ScanState.COMPLETED ||
    state === ScanState.FAILED ||
    state === ScanState.STOPPED ||
    state === ScanState.PAUSED
  ) {
    return state;
  } else {
    return `${percentage}%`;
  }
};

const baseColumns: ExplorerGridColumn = [
  {
    name: 'name',
    title: t('name'),
    getCellValue: ({ name }) => name,
    filteringEnabled: true,
    type: BigidGridColumnTypes.TEXT,
  },
  {
    name: 'created_at',
    title: t('discoveredAt'),
    getCellValue: ({ created_at }) => created_at,
    filteringEnabled: false,
    type: BigidGridColumnTypes.DATE,
    width: 160,
  },
  {
    name: 'region',
    title: t('region'),
    getCellValue: ({ region }) => region,
    filteringEnabled: true,
    type: BigidGridColumnTypes.TEXT,
    width: 100,
  },
  {
    name: 'createdAt',
    title: t('createdAt'),
    getCellValue: ({ createdAt }) => createdAt,
    filteringEnabled: false,
    type: BigidGridColumnTypes.DATE,
    width: 160,
  },
];

const scanInfoColumns: ExplorerGridColumn = [
  {
    name: 'scanProgress',
    title: t('scanProgress'),
    getCellValue: ({ scan }) => {
      const percentageBase = scan?.breakdown_progress_state?.percentage;
      if (!percentageBase) {
        return undefined;
      }
      const percentage = getPercentage(percentageBase, scan?.state);

      return {
        progressBar: {
          percentage,
          status: getScanStatus(scan?.state),
          size: 'large',
          rightFilter: getScanText(percentage, scan?.state),
        },
      };
    },
    filteringEnabled: true,
    sortingEnabled: false,
    type: BigidGridColumnTypes.PROGRESS_BAR,
    width: 200,
  },
];

export const FmsdExplorerColumns = new Map<string, ExplorerGridColumn>([
  ['base', [...baseColumns, ...scanInfoColumns]],
  [
    's3',
    [
      ...baseColumns,
      {
        name: 'sizeInBytes',
        title: t('size'),
        getCellValue: ({ sizeInBytes }) => (sizeInBytes || sizeInBytes === 0 ? formatBytes(sizeInBytes) : 'unknown'),
        filteringEnabled: false,
        type: BigidGridColumnTypes.NUMBER,
        width: 150,
      },
      {
        name: 'bucket_name',
        title: t('bucketName'),
        getCellValue: ({ bucket_name }) => bucket_name,
        filteringEnabled: false,
        type: BigidGridColumnTypes.TEXT,
      },
      {
        name: 'ownerName',
        title: t('ownerName'),
        getCellValue: ({ ownerName }) => ownerName,
        filteringEnabled: true,
        type: BigidGridColumnTypes.TEXT,
        width: 120,
      },
      ...scanInfoColumns,
    ],
  ],
  [
    'athena',
    [
      ...baseColumns,
      {
        name: 'lastAccessTime',
        title: t('lastAccessTime'),
        getCellValue: ({ lastAccessTime }) => lastAccessTime,
        filteringEnabled: true,
        type: BigidGridColumnTypes.DATE,
        width: 160,
      },
      ...scanInfoColumns,
    ],
  ],
  [
    'dynamodb',
    [
      ...baseColumns,
      {
        name: 'sizeInBytes',
        title: t('size'),
        getCellValue: ({ sizeInBytes }) => (sizeInBytes || sizeInBytes === 0 ? formatBytes(sizeInBytes) : 'unknown'),
        filteringEnabled: false,
        type: BigidGridColumnTypes.NUMBER,
        width: 150,
      },
      ...scanInfoColumns,
    ],
  ],
]);
