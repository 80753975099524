import React, { FC, memo, Fragment, ReactNode } from 'react';
import { BigidHeading6, BigidBody1, BigidNotes1, BigidTooltip, BigidNotes2 } from '@bigid-ui/components';
import { MetadataSearchResultsGridColumn } from './MetadataSearchResultsGridTypes';
import { useStyles } from './MetadataSearchResultsGridCellStyles';
import classNames from 'classnames';

export interface MetadataSearchResultsGridCellProps extends Omit<MetadataSearchResultsGridColumn, 'width'> {
  dataAid: string;
}

type GetCellComponentFuncParams = {
  value: string;
  isBold: boolean;
  isHighlighted: boolean;
  isFirst?: boolean;
  isLast?: boolean;
};

export const MetadataSearchResultsGridCell: FC<MetadataSearchResultsGridCellProps> = memo(
  ({ dataAid, icon, value, extraValueCount, tooltipTitle, tooltipValue, isBold }) => {
    const classes = useStyles();
    const shouldHighlight = /<\/?\bem\b[\s\S]*>/.test(value);

    const tooltip = (
      <>
        {tooltipTitle && (
          <BigidHeading6 data-aid={`${dataAid}-tooltip-title`} className={classes.tooltipTitle}>
            {tooltipTitle}
          </BigidHeading6>
        )}
        <div className={classes.tooltipValue} data-aid={`${dataAid}-tooltip-value`}>
          {tooltipValue?.length > 0
            ? tooltipValue.map((value, index) => (
                <BigidNotes1 data-aid={`${dataAid}-tooltip-value-${index}`} key={index}>
                  {value.replace(/<\/?[^>]+(>|$)/g, '')}
                </BigidNotes1>
              ))
            : value.replace(/<\/?[^>]+(>|$)/g, '')}
        </div>
      </>
    );

    const getCellComponent = ({
      value,
      isHighlighted,
      isBold,
      isFirst = false,
      isLast = false,
    }: GetCellComponentFuncParams): ReactNode => {
      const displayValue = isHighlighted ? value.replace(/<\/?[^>]+(>|$)/g, '') : value;
      const startsWithSpace = displayValue.startsWith(' ');
      const endsWithSpace = displayValue.endsWith(' ');
      const shouldShrink = displayValue.length > 3;
      const textClasses = classNames(
        classes.textValue,
        isHighlighted && classes.textValueHighlighted,
        !isHighlighted && shouldShrink && classes.textValueShrinked,
      );
      const cellDataAid = `${dataAid}-value-${value}${isHighlighted ? '-highlighted' : ''}`;

      return (
        <>
          {!isFirst && startsWithSpace && '\u00A0'}
          {isBold ? (
            <BigidHeading6 className={textClasses} data-aid={cellDataAid}>
              {displayValue}
            </BigidHeading6>
          ) : (
            <BigidBody1 className={textClasses} data-aid={cellDataAid}>
              {displayValue}
            </BigidBody1>
          )}
          {!isLast && endsWithSpace && '\u00A0'}
        </>
      );
    };

    return (
      <>
        {icon && <div className={classes.icon}>{icon}</div>}
        <BigidTooltip dataAid={`${dataAid}-tooltip`} title={tooltip} placement="top" enterDelay={1500}>
          <div className={classes.text}>
            {shouldHighlight ? (
              <>
                {value
                  .split(/(<em>.*?<\/em>)/g)
                  .filter(valueChunk => valueChunk.length)
                  .map((valueChunk, index, { length }) => {
                    const isFirst = index === 0;
                    const isLast = index === length - 1;
                    const isHighlighted = /<em>(.*?)<\/em>/.test(valueChunk);

                    return (
                      <Fragment key={index}>
                        {getCellComponent({
                          value: valueChunk,
                          isHighlighted,
                          isBold,
                          isFirst,
                          isLast,
                        })}
                      </Fragment>
                    );
                  })}
              </>
            ) : (
              getCellComponent({
                isHighlighted: false,
                value,
                isBold,
              })
            )}
            {extraValueCount > 0 && (
              <div className={classes.extraValueIndicator}>
                <BigidNotes2 classes={{ root: classes.extraValueIndicatorText }} data-aid={`${dataAid}-value-extra`}>
                  +{extraValueCount}
                </BigidNotes2>
              </div>
            )}
          </div>
        </BigidTooltip>
      </>
    );
  },
);

MetadataSearchResultsGridCell.displayName = 'MetadataSearchResultsGridCell';
