import React, { FC } from 'react';
import { styled } from '@mui/material';
import { BigidPaper } from '@bigid-ui/components';
import { $stateParams } from '../../../services/angularServices';
import { CuratedFields } from '../CuratedFields/CuratedFields';
import { useClassifierFindings } from './useClassifierPredictions';
import { CuratedFieldsVariant } from '../CuratedFields/curatedFieldsUtils';
import { CurationTabsLayoutStateContext } from '../CurationStateContext';
import { noop } from 'lodash';
import { useLocalTranslation } from '../translations';

const Root = styled('div')`
  display: flex;
  flex-direction: column;
  height: calc(100% - 10px);
  width: 100%;
  position: relative;
`;

export const ClassifierPredictions: FC = () => {
  const { attributeType, attributeName, displayName } = $stateParams;
  const { curationStatus } = useClassifierFindings({ attributeType, attributeName, displayName });
  const { t } = useLocalTranslation('CurationProgressBar.entity');

  return (
    <Root>
      <BigidPaper>
        <CurationTabsLayoutStateContext.Provider
          value={{
            onProceedToFieldsReviewTab: noop,
            onCurationGuidedTourStart: noop,
          }}
        >
          <CuratedFields
            dataAid="ClassifierPredictions"
            curationStatus={curationStatus}
            currentCuratedAttribute={{ attributeName, attributeType, displayName }}
            variant={CuratedFieldsVariant.CLASSIFIER_PREDICTIONS}
            curatedEntityName={t('fields')}
          />
        </CurationTabsLayoutStateContext.Provider>
      </BigidPaper>
    </Root>
  );
};
