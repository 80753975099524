import { openSystemDialog } from '../../services/systemDialogService';
import React, { FC } from 'react';
import { PrimaryButton, SecondaryButton } from '@bigid-ui/components';
import { betaToolsService } from '../../services/angularServices';
import { notificationService } from '../../services/notificationService';

type DisableReloadEntitiesCacheFunction = (disabled: boolean) => void;

const ReloadEntitiesCacheDialogContent: FC = () => {
  return <div className="reload-entities-cache-warning">Are you sure you want to reload entities cache?</div>;
};
const reloadAndNotify = async (setDisableReloadEntitiesCache: DisableReloadEntitiesCacheFunction) => {
  setDisableReloadEntitiesCache(true);
  try {
    await betaToolsService.reloadEntitiesCache();
    notificationService.success('Entities Cache reloaded successfully! ');
  } catch {
    notificationService.error('An error has occurred! ');
  }
  setDisableReloadEntitiesCache(false);
};

export function reloadEntitiesCacheDialog(setDisableReloadEntitiesCache: DisableReloadEntitiesCacheFunction) {
  return openSystemDialog({
    title: 'Reload Entities Cache',
    onClose: () => null,
    content: ReloadEntitiesCacheDialogContent,
    buttons: [
      {
        text: 'Close',
        component: SecondaryButton,
        onClick: () => null,
        isClose: true,
      },
      {
        text: 'Reload',
        component: PrimaryButton,
        onClick: () => reloadAndNotify(setDisableReloadEntitiesCache),
        isClose: true,
      },
    ],
  });
}
