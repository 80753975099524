import React, { ChangeEvent, FC, useEffect, useState } from 'react';
import { BigidBody1, BigidDialog, BigidTextField, PrimaryButton, SecondaryButton } from '@bigid-ui/components';
import styled from '@emotion/styled';
import { generateDataAid } from '@bigid-ui/utils';
import { getPercentageAsNumber, isNumberFieldValid, SIMILAR_TABLES_TRACKING_EVENTS } from '../utilities/utils';
import { useLocalTranslation } from '../translations';
import { analyticsService } from '../../../../services/analyticsService';

const DEFAULT_THRESHOLD = '90';

export interface ThresholdDialogProps {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (threshold: number) => void;
  dataAid?: string;
}

const ContentWrapper = styled('div')({
  display: 'flex',
  alignItems: 'flex-start',
  gap: '16px',
  flexDirection: 'column',
  flex: '1 0 0',
});

const Wrapper = styled('div')({
  display: 'flex',
  alignItems: 'center',
  gap: '8px',
  whiteSpace: 'nowrap',
});

export const ThresholdDialog: FC<ThresholdDialogProps> = ({ isOpen, onClose, onSubmit, dataAid = 'similarTables' }) => {
  const { t } = useLocalTranslation();

  const [similarityThreshold, setSimilarityThreshold] = useState<string>(DEFAULT_THRESHOLD);
  const [isInvalidInput, setIsInvalidInput] = useState<boolean>(false);

  useEffect(() => {
    if (isOpen) {
      setSimilarityThreshold(DEFAULT_THRESHOLD);
    }
  }, [isOpen]);

  const handleOnDialogApply = () => {
    onSubmit(getPercentageAsNumber(similarityThreshold));
    analyticsService.trackManualEvent(SIMILAR_TABLES_TRACKING_EVENTS.THRESHOLD_DIALOG_APPLY_CLICK);
  };
  const handleOnDialogClose = () => {
    onClose();
  };

  const handleThresholdChange = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    if (!isNumberFieldValid(event.target.value)) {
      setIsInvalidInput(true);
    } else {
      setSimilarityThreshold(event.target.value);
      setIsInvalidInput(false);
    }
  };

  return (
    <BigidDialog
      data-aid={`${generateDataAid(dataAid, ['threshold', 'dialog'])}`}
      title={t('thresholdDialog.title')}
      isOpen={isOpen}
      borderTop={true}
      borderBottom={false}
      onClose={handleOnDialogClose}
      buttons={[
        {
          component: SecondaryButton,
          onClick: handleOnDialogClose,
          text: t('thresholdDialog.buttons.cancel'),
        },
        {
          component: PrimaryButton,
          onClick: handleOnDialogApply,
          disabled: isInvalidInput,
          text: t('thresholdDialog.buttons.apply'),
        },
      ]}
      maxWidth="sm"
    >
      <ContentWrapper>
        <BigidBody1>{t('thresholdDialog.description')}</BigidBody1>
        <Wrapper>
          <BigidBody1>{t('thresholdDialog.input.title')}</BigidBody1>
          <BigidTextField
            required={true}
            defaultValue={similarityThreshold}
            isError={isInvalidInput}
            errorMessage={isInvalidInput ? t('thresholdDialog.input.invalidError') : ''}
            onChange={handleThresholdChange}
            placeholder={t('thresholdDialog.input.placeholder')}
            size={'medium'}
          />
        </Wrapper>
      </ContentWrapper>
    </BigidDialog>
  );
};
