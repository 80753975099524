import React, { ChangeEvent, FunctionComponent } from 'react';
import styles from './ThycoticConfig.scss';
import { BigidSelect, BigidSelectOption, BigidTextField } from '@bigid-ui/components';
import { styled } from '@mui/material';

interface ConfigInputItemProps {
  title: string;
  field: string;
  onChange: (name: string, value: string) => void;
  hasError: boolean;
  value: string;
  isPassword: boolean;
  isRequired: boolean;
  options?: BigidSelectOption[];
  isMultiSelect?: boolean;
}

const Asterisk = styled('span')(({ theme }) => ({
  color: `${theme.vars.palette.bigid.red600}`,
}));

export const ConfigInputItem: FunctionComponent<ConfigInputItemProps> = ({
  field,
  title,
  onChange,
  hasError,
  value,
  isPassword,
  isRequired,
  options = [],
  isMultiSelect = false,
}: ConfigInputItemProps) => {
  const handleSelectChanged = (selected: BigidSelectOption[]) => {
    const { value } = selected[0];
    onChange(field, value);
  };

  const handleInputChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { value } = e.target;
    onChange(field, value);
  };

  return (
    <div className={styles.formInput}>
      <span>{title}</span>
      {isRequired && <Asterisk>*</Asterisk>}
      <div className={styles.inputMargin}>
        {isMultiSelect ? (
          <BigidSelect
            name={field}
            value={options.filter(({ value: optionsValue }) => optionsValue === value)}
            options={options}
            onChange={handleSelectChanged}
          />
        ) : (
          <div data-aid={title.replace(' ', '-')}>
            <BigidTextField
              type={isPassword ? 'password' : 'text'}
              onChange={handleInputChange}
              defaultValue={value}
              autoComplete={'new-password'}
              isError={hasError}
              errorMessage={hasError ? `Please Enter ${title}` : null}
              required={isRequired}
            />
          </div>
        )}
      </div>
    </div>
  );
};
