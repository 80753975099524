import React, { FC, PropsWithChildren } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import DoneOutlineRoundedIcon from '@mui/icons-material/DoneOutlineRounded';

const useStyles = makeStyles({
  wrapper: {
    height: '100%',
    maxHeight: 600,
    display: 'flex',
    flex: '1 0 100%',
    flexFlow: 'column nowrap',
    alignItems: 'center',
  },
  responsivePadding: {
    maxHeight: '20%',
    flexGrow: 1,
    width: '100%',
  },
  icon: {
    position: 'relative',
    zIndex: 1,
  },
  iconRect: {
    marginBottom: 30,
    width: 160,
    height: 38,
    background: '#FFFFFF 0% 0% no-repeat padding-box',
    boxShadow: '-3px 4px 12px rgba(0, 0, 0, 0.16)',
    borderRadius: 10,
    position: 'relative',

    display: 'flex',
    flexFlow: 'row nowrap',
    alignItems: 'center',
    justifyContent: 'space-evenly',

    '&:before': {
      zIndex: -1,
      position: 'absolute',
      top: -20,
      left: 20,
      content: '""',
      width: 160,
      height: 38,
      background: '#F3F3F3 0% 0% no-repeat padding-box',
      borderRadius: 10,
    },
  },
  iconLine: {
    display: 'block',
    background: '#EEEEEE 0% 0% no-repeat padding-box',
    borderRadius: 10,
    width: '30%',
    height: 7,
  },
});

// TODO: move this placeholder to bigid-ui as layout config

export const PlaceHolderComponent: FC<PropsWithChildren> = ({ children }) => {
  const classes = useStyles({});

  return (
    <div className={classes.wrapper}>
      <div className={classes.responsivePadding}></div>
      <div className={classes.icon}>
        <div className={classes.iconRect}>
          <DoneOutlineRoundedIcon htmlColor={'#ddd'} />
          <span className={classes.iconLine}></span>
          <span className={classes.iconLine}></span>
        </div>
      </div>
      {children}
    </div>
  );
};
