export const openConfigWindow = (trayUrl: string, callback: (configData: any) => void, errorCallback?: () => void) => {
  const configWindow = window.open(
    trayUrl,
    '_blank',
    'width=500,height=500,scrollbars=no,toolbar=0,location=0,menubar=0',
  );

  let configFinished = false;
  let configData = {};
  const onmessage = (e: {
    data: { type: string; err: any; data: { visibleSlots: any[]; slotValues: { [x: string]: any } } };
  }) => {
    if (e.data.type === 'tray.authpopup.finish') {
      configFinished = true;
      configData = e.data;
      configWindow?.close();
    } else if (e.data.type === 'tray.authpopup.close' && configFinished == false) {
      configData = e.data;
      configWindow?.close();
    } else if (e.data.type === 'tray.authpopup.error') {
      console.log(`Error: ${e.data.err}`);
    } else if (e.data.type === 'tray.authpopup.cancel') {
      configWindow?.close();
    }

    if (e.data.type === 'tray.authpopup.validate') {
      configWindow?.postMessage(
        {
          type: 'tray.authpopup.client.validation',
          data: {
            inProgress: true,
          },
        },
        '*',
      );

      setTimeout(() => {
        const errors = e.data.data.visibleSlots.reduce((errors: any, externalId: string | number) => {
          return errors;
        }, {});

        configWindow?.postMessage(
          {
            type: 'tray.authpopup.client.validation',
            data: {
              inProgress: false,
              errors: errors,
            },
          },
          '*',
        );
      }, 2000);
    }
  };
  window.addEventListener('message', onmessage);

  const CHECK_TIMEOUT = 1000;
  const checkWindow = () => {
    if (configWindow?.closed) {
      if (!configFinished) {
        errorCallback && errorCallback();
      } else {
        callback(configData);
      }
      window.removeEventListener('message', onmessage);
    } else {
      setTimeout(checkWindow, CHECK_TIMEOUT);
    }
  };

  checkWindow();

  return configWindow;
};
