import React from 'react';
import { EntitySection } from './EntitySection/EntitySection';
import { noop, take } from 'lodash';
import { useQuery } from 'react-query';
import { getRecentlyViewedCatalogItems } from '../../../DataCatalog/recentlyViewedCatalogItemService';
import { RecentlyViewedListItem } from './EntitySection/ListItems/RecentlyViewedListItem';
import { generateDataAid } from '@bigid-ui/utils';

export const RecentlyViewedItemsPopper = () => {
  const result = useQuery(['recentlyViewed'], getRecentlyViewedCatalogItems, {
    keepPreviousData: true,
    placeholderData: {
      preference: '',
      data: {
        recentlyViewedItems: [],
      },
    },
  });

  if (!result.data) return null;

  const list = take(result.data.data?.recentlyViewedItems, 3).map(
    ({ dataSourceName, dataSourceType, fullyQualifiedName, objectName }, index) => {
      return (
        <RecentlyViewedListItem
          key={fullyQualifiedName}
          dsName={dataSourceName}
          dsType={dataSourceType}
          fullyQualifiedName={fullyQualifiedName}
          objectName={objectName}
          dataAid={generateDataAid('RecentlyViewedListItem', [index])}
        />
      );
    },
  );

  return <EntitySection listItems={list} handleShowAllClick={noop} sectionName="recentlyViewed" />;
};
