import { useMemo } from 'react';
import { areSomePermitted } from '../../../services/userPermissionsService';
import { APPLICATIONS_PERMISSIONS, getManageBasePermissionNameByTpaName } from '@bigid/permissions';

export const useCheckManagerPermissions = (appName: string) => {
  const hasManagerPermission = useMemo(
    () =>
      areSomePermitted([
        APPLICATIONS_PERMISSIONS.MANAGE_TPA_CUSTOM_APPS.name,
        APPLICATIONS_PERMISSIONS.DELETE_AND_ADD_CUSTOM_APPS.name,
        getManageBasePermissionNameByTpaName(appName),
      ]),
    [appName],
  );

  return hasManagerPermission;
};
