import { BigidDropdownOption } from '@bigid-ui/components';
import { systemUsersService } from '../../../../../services/angularServices';
import { httpService, QueryParamsAndDataObject } from '../../../../../services/httpService';
import { DateISO8601 } from '../../../../../types/types';
import { getOptionsFromSystemUsers, getUsersQuery } from '../../../../../utilities/systemUsersUtils';

export interface SystemUser {
  id: string;
  name: string;
  firstName: string;
  lastName: string;
  origin: string;
  email: string;
}

export interface ApplicationActionPreset {
  _id: string;
  actionId: string;
  name: string;
}

export interface ApplicationAction {
  _id: string;
  tpa_id: string;
  action_name: string;
  presets: ApplicationActionPreset[];
}

export type PolicyAction = {
  label: string;
  value: string;
};

export type PolicyApp = {
  label: string;
  value: string;
};

export type PolicyPreset = {
  label: string;
  value: string;
};

export enum PolicyType {
  PRIVACY = 'privacy',
  ACCESS_GOVERNANCE = 'access_governance',
  CATALOG = 'catalog',
}

export type Policy = {
  id?: string;
  name: string;
  type: PolicyType;
  action?: string;
  allEnabledDs?: boolean;
  description: string;
  category?: string;
  is_enabled?: false;
  findings?: string[];
  complianceRuleCalc: {
    bigidQuery: string;
    maxFindings: number;
  };
  taskSettings: {
    includeLinkToInventory: false;
    includeObjectsReport: false;
    includeLinkToCatalog: false;
  };
  task?: string;
  owner: string;
  severity?: string;
};

export interface PolicyTestResult {
  calcDate: DateISO8601;
  findingsAmt: number;
  violated: boolean;
}

export type CreatePolicyPayload = Omit<Policy, 'id'>;
export type CreatePolicyResponse = {
  ruleId: string;
  status: string;
};

export type TestPolicyPayload = {
  bigidQuery: string;
  maxFindings: number;
  type: PolicyType;
};
export type TestPolicyResponse = PolicyTestResult;

export function createPolicy(payload: CreatePolicyPayload) {
  return httpService
    .post<CreatePolicyResponse, CreatePolicyPayload>('compliance-rules', payload)
    .then(({ data }) => data);
}

export function testPolicy(payload: TestPolicyPayload) {
  return httpService
    .post<TestPolicyResponse, TestPolicyPayload>('compliance-rules/test', payload)
    .then(({ data }) => data);
}

export async function getSystemUsers(searchString?: string): Promise<BigidDropdownOption[]> {
  try {
    const query = getUsersQuery({ searchString, maxUsers: 50 });
    const {
      data: { users },
    } = await systemUsersService.getAllSystemUsersByQuery(query);

    return getOptionsFromSystemUsers(users).map(({ id, label, value }) => ({
      id,
      value,
      displayValue: label,
    }));
  } catch ({ message }) {
    console.error(`An error has occurred: ${message}`);
    return [];
  }
}
