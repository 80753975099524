import React, { FC, useEffect, useMemo, useRef } from 'react';
import {
  BigidLayout,
  BigidLayoutConfig,
  BigidLayoutEmptyState,
  BigidMasterDetailsContentProps,
  LayoutContentType,
} from '@bigid-ui/layout';
import { pageHeaderService } from '../../../common/services/pageHeaderService';
import { BigidGridColumn, BigidGridColumnTypes, BigidGridProps, FetchDataFunction } from '@bigid-ui/grid';
import { ScanWindowModel, scanWindowService } from './scanWindowService';
import { BigidContentItem, EntityEvents, entityEventsEmitter, ToolbarAction } from '@bigid-ui/components';
import { isPermitted } from '../../services/userPermissionsService';
import { SCAN_WINDOWS_PERMISSIONS } from '@bigid/permissions';
import { EditScanWindow, DEAFULT_SCAN_WINDOW } from './EditScanWindow/EditScanWindow';
import makeStyles from '@mui/styles/makeStyles';
import { AddBoxOutlined } from '@mui/icons-material';
import DeleteOutlineRoundedIcon from '@mui/icons-material/DeleteOutlineRounded';
import FilterNoneRoundedIcon from '@mui/icons-material/FilterNoneRounded';
import { notificationService } from '../../services/notificationService';
import { BigidCalendarIllustration } from '@bigid-ui/icons';

const PREFIX = 'New Timeframe';

const useStyles = makeStyles({
  contentContainer: {
    display: 'flex',
    overflow: 'hidden',
    flexFlow: 'column nowrap',
    flex: '1 1 auto',
    overflowY: 'auto',
  },
});

interface ExtendedScanWindowModel extends ScanWindowModel {
  id: string;
  name: string;
}

const getNextNumberOfScanWindow = (scanWindowName: string, init: number): number => {
  let next = init;
  if (scanWindowName.startsWith(PREFIX)) {
    const suffix = scanWindowName.replace(PREFIX, '').trim();
    const number = parseInt(suffix);
    if (isNaN(number) === false) {
      next = Math.max(number + 1, next);
    }
  }
  return next;
};

export const ScanWindows: FC = () => {
  const classes = useStyles({});
  const count = useRef(0);
  const { isAddScanWindowPermitted, isEditScanWindowPermitted, isDeleteScanWindowPermitted } = useMemo(
    () => ({
      isAddScanWindowPermitted: isPermitted(SCAN_WINDOWS_PERMISSIONS.CREATE.name),
      isEditScanWindowPermitted: isPermitted(SCAN_WINDOWS_PERMISSIONS.EDIT.name),
      isDeleteScanWindowPermitted: isPermitted(SCAN_WINDOWS_PERMISSIONS.DELETE.name),
    }),
    [],
  );

  const columns: BigidGridColumn<ExtendedScanWindowModel>[] = useMemo(
    () => [
      {
        width: 250,
        title: 'Timeframe',
        name: 'name',
        isListColumn: true,
        type: BigidGridColumnTypes.TEXT,
        getCellValue: ({ name }) => name,
      },
    ],
    [],
  );

  const getNewWindowName = () => {
    const number = count.current;
    count.current++;
    return `${PREFIX} ${number}`;
  };

  const getDuplicateWindowName = (name: string) => {
    return `Copy of (${name})`;
  };

  const duplicateScanWindow = async (scanWindowName: string) => {
    const scanWindowObj = await scanWindowService.getScanWindowByName(scanWindowName);
    const name = getDuplicateWindowName(scanWindowObj[0].scanWindowName);
    const newScanWindowObj = { scanWindowName: name, scanWindows: scanWindowObj[0].scanWindows };
    await scanWindowService.createScanWindow(newScanWindowObj);
    return { shouldGridReload: true, shouldClearSelection: true };
  };

  const addNewScanWindow = async () => {
    const newScanWindowObj = {
      scanWindowName: getNewWindowName(),
      scanWindows: [DEAFULT_SCAN_WINDOW],
    } as ScanWindowModel;
    const res = await scanWindowService.createScanWindow(newScanWindowObj);
    return { shouldGridReload: true, shouldClearSelection: true };
  };

  const deleteScanWindow = async (selectedItem: BigidContentItem) => {
    const actionResult = await scanWindowService.deleteScanWindow(selectedItem.name);
    return { shouldGridReload: true, shouldClearSelection: true };
  };

  const toolbarActions: ToolbarAction[] = [
    {
      label: 'New Timeframe',
      icon: AddBoxOutlined,
      execute: async () => {
        return await addNewScanWindow();
      },
      disable: () => false,
      show: () => isAddScanWindowPermitted,
    },
    {
      label: 'Delete',
      icon: DeleteOutlineRoundedIcon,
      execute: async ({ selectedItem }) => {
        return await deleteScanWindow(selectedItem);
      },
      disable: ({ selectedRowIds }) => selectedRowIds.length > 1,
      show: ({ selectedItem }) => isDeleteScanWindowPermitted && selectedItem != null,
    },
    {
      label: 'Duplicate',
      icon: FilterNoneRoundedIcon,
      execute: async ({ selectedItem }) => {
        return await duplicateScanWindow(selectedItem.name);
      },
      disable: ({ selectedRowIds }) => selectedRowIds.length > 1,
      show: ({ selectedItem }) => isAddScanWindowPermitted && selectedItem != null,
    },
  ];

  const fetchGridData: FetchDataFunction<ExtendedScanWindowModel> = async (
    query,
    { setSelectedItem, selectedItem },
  ) => {
    const scanWindows = await scanWindowService.getAllScanWindow();
    const extendedScanWindows = scanWindows.map(sw => ({ ...sw, name: sw.scanWindowName, id: sw.scanWindowName }));
    if (!selectedItem && extendedScanWindows && extendedScanWindows.length > 0) {
      setSelectedItem(extendedScanWindows?.[0]);
    }
    const currentItem = extendedScanWindows.find(sw => sw._id.toString() === selectedItem?._id.toString());
    if (currentItem != null && currentItem.scanWindowName !== selectedItem.id) {
      setSelectedItem(currentItem);
    }
    return {
      totalCount: scanWindows.length,
      data: extendedScanWindows,
    };
  };

  const gridConfig: BigidGridProps<ExtendedScanWindowModel> = {
    showSelectionColumn: false,
    showSortingControls: false,
    columns: columns,
  };

  const emptyStateActions = [
    {
      execute: async () => {
        addNewScanWindow();
        entityEventsEmitter.emit(EntityEvents.RELOAD);
        return { shouldGridReload: true };
      },
      label: 'New Timeframe',
      show: () => true,
    },
  ];

  const masterDetailsConfig: BigidMasterDetailsContentProps = {
    isPersistentListMode: true,
    setSelectedItemInFetch: true,
    placeholderComponent: (
      <BigidLayoutEmptyState
        actions={emptyStateActions}
        illustration={BigidCalendarIllustration}
        description="Define a timeframe for your scan schedule"
      />
    ),
    tabsAndContent: {
      classes: {
        contentContainer: classes.contentContainer,
      },
      tabProps: {
        selectedIndex: 0,
        tabs: [
          {
            label: 'Scan Windows',
            data: {
              component: EditScanWindow,
            },
            getIsAvailable: () => isEditScanWindowPermitted,
          },
        ],
      },
    },
    isEditableHeader: isEditScanWindowPermitted,
  };

  useEffect(() => {
    const handleUpdateName = async (id: string, updatedFields: BigidContentItem) => {
      try {
        const { name: scanWindowName } = updatedFields;
        count.current = getNextNumberOfScanWindow(scanWindowName, count.current);
        let objFields = {} as ScanWindowModel;
        if (scanWindowName != null) {
          objFields = { scanWindowName: scanWindowName.trim() };
        }
        await scanWindowService.updateScanWindow(id, objFields);
        entityEventsEmitter.emit(EntityEvents.RELOAD);
      } catch ({ response }) {
        const { data, status } = response;
        if (status === 409) {
          notificationService.error(data.message);
        } else {
          notificationService.error('Timeframe with this name already exists. Please type another name.');
        }
        console.error('An error has occurred while trying to update scan window name');
      }
    };

    const handleUpdateObj = async (id: string, updatedFields: ExtendedScanWindowModel) => {
      try {
        const { name: scanWindowName, scanWindows } = updatedFields;
        let objFields = {} as ScanWindowModel;
        if (scanWindowName != null) {
          objFields = { scanWindowName };
        }
        if (scanWindows != null) {
          objFields = { scanWindows };
        }
        await scanWindowService.updateScanWindow(id, objFields);
      } catch (err) {
        console.error('An error has occurred while trying to update scan window object');
      }
    };

    const updateNameEvent = entityEventsEmitter.addEventListener(EntityEvents.ENTITY_UPDATED, handleUpdateName);
    const updateObjEvent = entityEventsEmitter.addEventListener(EntityEvents.UPDATE_BY_ID, handleUpdateObj);
    return function cleanup() {
      updateNameEvent();
      updateObjEvent();
    };
  }, []);

  const layoutConfig = {
    content: {
      entityName: 'Timeframes',
      contentTypes: [LayoutContentType.MASTER_DETAILS],
      toolbarActions,
      viewConfig: {
        fetchGridData,
        gridConfig,
        masterDetailsConfig,
        selectedItemPropsMapping: {
          id: 'id',
          name: 'name',
          scanWindows: 'scanWindows',
        },
      },
    },
  } as BigidLayoutConfig;

  useEffect(() => {
    pageHeaderService.setTitle({ pageTitle: 'Scan Windows' });
  }, []);

  useEffect(() => {
    async function countWindows() {
      try {
        const scanWindows = await scanWindowService.getAllScanWindow();
        const amount = scanWindows?.length | 0;
        let next = amount;
        scanWindows.forEach(({ scanWindowName }) => {
          next = getNextNumberOfScanWindow(scanWindowName, next);
        });
        count.current = Math.max(next, amount);
      } catch (e) {
        count.current = Math.floor(Math.random() * 1000) + 100;
      }
    }

    countWindows();
  }, []);

  return <BigidLayout config={layoutConfig} />;
};
