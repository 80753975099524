import React, { FC, memo, useMemo } from 'react';
import {
  BigidBody1,
  BigidButtonTypography,
  BigidHeading2,
  BigidHeading6,
  BigidTooltip,
  PrimaryButton,
} from '@bigid-ui/components';
import ConnectAnimationTopPart from '../../../../assets/icons/BigidConnectionTop.svg';
import ConnectAnimationBottomPart from '../../../../assets/icons/BigidConnectionBottom.svg';
import classnames from 'classnames';
import { useStyles } from './TestConnectionBoxStyles';

export enum TestConnectionStatus {
  NOT_STARTED = 'notStarted',
  SUCCESS = 'success',
  FAILED = 'failed',
}

const RESULT_HEADER_TEXT_MAP = {
  [TestConnectionStatus.NOT_STARTED]: '',
  [TestConnectionStatus.SUCCESS]: 'Connection Successful',
  [TestConnectionStatus.FAILED]: 'Connection Failed',
};

export interface TestConnectionBoxProps {
  onTest: () => void;
  lastTestDate?: string;
  testInProgress: boolean;
  testError?: string;
  testStatus: TestConnectionStatus;
  isTestAvailable: boolean;
}

export const TestConnectionBox: FC<TestConnectionBoxProps> = memo(
  ({ onTest, lastTestDate, testError, testInProgress, testStatus, isTestAvailable }) => {
    const {
      wrapper,
      dateWrapper,
      buttonTextWrapper,
      topImage,
      bottomImage,
      actionsWrapper,
      topImageDone,
      bottomImageDone,
      resultHeaderWrapper,
      bottomImageFail,
      topImageFail,
      errorTextWrapper,
      failedLine,
      failedLineTopLeft,
      failedLineTopRight,
      failedLineBottomLeft,
      failedLineBottomRight,
      buttonTextFirstTestWrapper,
    } = useStyles({ testStatus });

    const disabled = !isTestAvailable || testInProgress;
    const isFirstTestAvailable = testStatus === TestConnectionStatus.NOT_STARTED && disabled;

    const [topImageClass, bottomImageClass] = useMemo(
      () =>
        (testInProgress && [topImage, bottomImage]) ||
        {
          [TestConnectionStatus.NOT_STARTED]: [topImage, bottomImage],
          [TestConnectionStatus.FAILED]: [topImageFail, bottomImageFail],
          [TestConnectionStatus.SUCCESS]: [topImageDone, bottomImageDone],
        }[testStatus],
      [bottomImage, bottomImageDone, bottomImageFail, testInProgress, testStatus, topImage, topImageDone, topImageFail],
    );

    const dateText = useMemo(() => `Tested on ${lastTestDate}`, [lastTestDate]);

    const error = useMemo(() => {
      const text =
        (typeof testError === 'string' && testError?.replace('- Please check logs for more details', '')) || '';
      return (
        testStatus === TestConnectionStatus.FAILED &&
        testError && (
          <BigidTooltip title={text} placement="top">
            <div data-aid="TestConnectionErrorText" className={errorTextWrapper}>
              <BigidHeading2>{text}</BigidHeading2>
            </div>
          </BigidTooltip>
        )
      );
    }, [testError, testStatus, errorTextWrapper]);

    const resultHeader = useMemo(
      () =>
        RESULT_HEADER_TEXT_MAP[testStatus] && (
          <React.Fragment>
            <BigidHeading6 data-aid={`TestConnection-result-${testStatus}`} className={resultHeaderWrapper}>
              {RESULT_HEADER_TEXT_MAP[testStatus]}
            </BigidHeading6>
            <BigidBody1 data-aid={`TestConnection-result-date`} className={dateWrapper}>
              {dateText}
            </BigidBody1>
            {error}
          </React.Fragment>
        ),
      [dateText, dateWrapper, error, resultHeaderWrapper, testStatus],
    );

    const renderFailedLine = (name: string) => <div key={name} className={classnames(failedLine, name)} />;

    return (
      <div className={wrapper} data-aid="TestConnection">
        <div data-aid="TestConnectionAnimationTopPart" className={topImageClass}>
          <ConnectAnimationTopPart />
        </div>
        <div data-aid="TestConnectionAnimationBottomPart" className={bottomImageClass}>
          <ConnectAnimationBottomPart />
        </div>
        {testStatus === TestConnectionStatus.FAILED &&
          !testInProgress &&
          [failedLineTopLeft, failedLineBottomLeft, failedLineTopRight, failedLineBottomRight].map(renderFailedLine)}
        <div className={actionsWrapper} data-aid="TestConnection-actions">
          {resultHeader}
          <PrimaryButton
            dataAid={`TestConnectionTestButton`}
            onClick={onTest}
            size="large"
            disabled={disabled}
            margin="4px"
          >
            <BigidButtonTypography
              className={isFirstTestAvailable ? buttonTextFirstTestWrapper : buttonTextWrapper}
              data-aid={`TestConnectionTestText-${testStatus}`}
            >
              {testStatus === TestConnectionStatus.NOT_STARTED ? 'Test connection' : 'Test again'}
            </BigidButtonTypography>
          </PrimaryButton>
        </div>
      </div>
    );
  },
);
