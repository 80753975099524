import React, { FunctionComponent } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { BigidColors } from '@bigid-ui/components';

const useStyles = makeStyles({
  list: {
    padding: 0,
    listStyle: 'none',
  },
  listItem: {
    color: BigidColors.gray[700],
    fontWeight: 400,
    fontSize: '0.8125rem',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
});

interface ProgressStepDetailsProps {
  details: string[];
}

export const ProgressStepDetails: FunctionComponent<ProgressStepDetailsProps> = ({
  details,
}: ProgressStepDetailsProps) => {
  const classes = useStyles({});

  return (
    <ul className={classes.list}>
      {details.map((detailsEntity, index) => (
        <li key={index} className={classes.listItem} title={detailsEntity}>
          {detailsEntity}
        </li>
      ))}
    </ul>
  );
};
