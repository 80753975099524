import React, { FC, useCallback, useMemo, useState } from 'react';

import { RiskMatrix } from './components/RiskMatrix';
import {
  BigidBody1,
  BigidColorSchemeTokens,
  BigidColorSchemesVars,
  BigidDialog,
  BigidDropdown,
  BigidDropdownOption,
  BigidTextField,
  PrimaryButton,
  SecondaryButton,
} from '@bigid-ui/components';
import { styled } from '@mui/material';
import { BigidChevronUpIcon, BigidChevronDownIcon } from '@bigid-ui/icons';
import { cloneDeep, isEqual } from 'lodash';
import { CellData, RiskMatrixMetadata, cellData, xAxis, yAxis } from './RiskMatrixDefaults';
import { httpService } from '../../services/httpService';
import { generateDataAid } from '@bigid-ui/utils';
import { isPermitted } from '../../services/userPermissionsService';
import { PRIVACY_RISKS_PERMISSIONS } from '@bigid/permissions';

enum ExpandedSection {
  RiskLevel,
  XAxis,
  YAxis,
  None,
}

const Sizes: BigidDropdownOption[] = [
  {
    displayValue: '3x3',
    id: '3',
    value: 3,
  },
  {
    displayValue: '4x4',
    id: '4',
    value: 4,
  },
  {
    displayValue: '5x5',
    id: '5',
    value: 5,
  },
];

const Root = styled('div')({
  display: 'flex',
  flexDirection: 'row',
});

const Form = styled('div')({
  width: '100%',
  padding: '24px',
  display: 'flex',
  flexDirection: 'column',
  gap: '20px',
});

const Section = styled('div')({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  gap: '8px',
});

const Preview = styled('div')({
  backgroundColor: BigidColorSchemesVars.light.gray125,
  padding: '24px',
});

const MatrixSizeContainer = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  '& > div': {
    width: '120px',
  },
});

const Expandable = styled('div', { shouldForwardProp: prop => !['expanded'].includes(prop.toString()) })<{
  expanded: boolean;
}>(({ expanded }) => ({
  display: 'flex',
  padding: '16px 20px 16px 16px',
  flexDirection: 'column',
  alignItems: 'flex-start',
  gap: '24px',
  alignSelf: 'stretch',
  borderRadius: '8px',
  height: expanded ? 'unset' : '72px',
  backgroundColor: BigidColorSchemesVars.light.gray125,
  border: BigidColorSchemeTokens.light.borderDefault,
}));

const ExpandableHeader = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  width: '100%',
  alignItems: 'center',

  '& > div': {
    width: '294px',
  },
});

const ExpandableDetails = styled('div', { shouldForwardProp: prop => !['expanded'].includes(prop.toString()) })<{
  expanded: boolean;
}>(({ expanded }) => ({
  display: 'flex',
  flexDirection: 'column',
  visibility: expanded ? 'unset' : 'hidden',
  gap: '16px',
}));

const FieldWrapper = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  gap: '8px',
  '& > div': {
    width: '268px',
  },
});

export const RiskMatrixDialog: FC<{
  open: boolean;
  onClose: (data?: RiskMatrixMetadata) => void;
  data: RiskMatrixMetadata;
}> = ({ open, data, onClose }) => {
  const [expandedSection, setExpandedSection] = useState<ExpandedSection>(ExpandedSection.RiskLevel);
  const [prevSelectedGridSize, setPrevSelectedGridSize] = useState<number | undefined>();
  const [metadata, setMetadata] = useState<RiskMatrixMetadata>(cloneDeep(data));

  const listOfLevel = useMemo(() => {
    const tempListOfLevel: { label: string; ids: number[] }[] = [];
    for (const key of Object.keys(metadata.cellData)) {
      const index = tempListOfLevel.findIndex(item => item.label === metadata.cellData[Number(key)].riskLevelLabel);
      if (index > -1) {
        tempListOfLevel[index].ids.push(Number(key));
      } else {
        tempListOfLevel.push({
          label: metadata.cellData[Number(key)].riskLevelLabel,
          ids: [Number(key)],
        });
      }
    }

    return tempListOfLevel;
  }, [metadata]);

  const getSeverityLabels = useCallback(() => {
    const items = [];
    let counter = 1;
    for (const level of listOfLevel) {
      items.push(
        <FieldWrapper>
          <BigidBody1>{`#${counter}`}</BigidBody1>
          <BigidTextField
            required
            dataAid={generateDataAid('riskMatrixForm', ['severity-level', level.label])}
            value={level.label}
            isError={level.label.length === 0}
            onChange={event => {
              if (event.target.value.length > 13) return;
              const metadataClone = cloneDeep(metadata);
              level.ids.forEach(id => {
                metadataClone.cellData[id].riskLevelLabel = event.target.value;
              });
              setMetadata(metadataClone);
            }}
            readOnly={
              !isPermitted(PRIVACY_RISKS_PERMISSIONS.MATRIX_CONFIG_EDIT.name) ||
              (listOfLevel.some(lvl => lvl.label === '') && level.label !== '')
            }
          />
        </FieldWrapper>,
      );
      counter++;
    }

    return items;
  }, [listOfLevel, metadata]);

  const getProbabilityLabels = useCallback(() => {
    const items = [];
    for (let index = 0; index < metadata.matrixSize; index++) {
      items.push(
        <FieldWrapper>
          <BigidBody1>{`#${index + 1}`}</BigidBody1>
          <BigidTextField
            required
            dataAid={generateDataAid('riskMatrixForm', ['yAxis', 'labels', metadata.probabilityLabels[index + 1]])}
            value={metadata.probabilityLabels[index + 1]}
            isError={metadata.probabilityLabels[index + 1].length === 0}
            onChange={event => {
              if (event.target.value.length > 15) return;
              const metadataClone = cloneDeep(metadata);
              metadataClone.probabilityLabels[index + 1] = event.target.value;
              setMetadata(metadataClone);
            }}
            readOnly={!isPermitted(PRIVACY_RISKS_PERMISSIONS.MATRIX_CONFIG_EDIT.name)}
          />
        </FieldWrapper>,
      );
    }

    return items;
  }, [metadata]);

  const getImpactLabels = useCallback(() => {
    const items = [];
    for (let index = 0; index < metadata.matrixSize; index++) {
      items.push(
        <FieldWrapper>
          <BigidBody1>{`#${index + 1}`}</BigidBody1>
          <BigidTextField
            required
            isError={metadata.impactLabels[index + 1].length === 0}
            dataAid={generateDataAid('riskMatrixForm', ['xAxis', 'labels', metadata.impactLabels[index + 1]])}
            value={metadata.impactLabels[index + 1]}
            onChange={event => {
              if (event.target.value.length > 15) return;
              const metadataClone = cloneDeep(metadata);
              metadataClone.impactLabels[index + 1] = event.target.value;
              setMetadata(metadataClone);
            }}
            readOnly={!isPermitted(PRIVACY_RISKS_PERMISSIONS.MATRIX_CONFIG_EDIT.name)}
          />
        </FieldWrapper>,
      );
    }

    return items;
  }, [metadata]);

  const MatrixPreview = useMemo(() => {
    return <RiskMatrix {...metadata} oldGridSize={prevSelectedGridSize} />;
  }, [metadata, prevSelectedGridSize]);

  const isValid = () => {
    if (metadata.impactLabel.length === 0 || metadata.probabilityLabel.length === 0) return false;

    for (const value of Object.values(metadata.impactLabels)) {
      if ((value as string).length === 0) return false;
    }

    for (const value of Object.values(metadata.probabilityLabels)) {
      if ((value as string).length === 0) return false;
    }

    for (const value of Object.values(metadata.cellData)) {
      if ((value as CellData).riskLevelLabel.length === 0) return false;
    }

    return true;
  };

  return (
    <BigidDialog
      onExited={() => onClose()}
      isOpen={open}
      borderTop={true}
      maxWidth={'xl'}
      onClose={() => onClose()}
      buttons={[
        {
          component: SecondaryButton,
          onClick: () => onClose(),
          text: 'Close',
          dataAid: generateDataAid('riskMatrixForm', ['close']),
        },
        ...(isPermitted(PRIVACY_RISKS_PERMISSIONS.MATRIX_CONFIG_EDIT.name)
          ? [
              {
                component: PrimaryButton,
                onClick: async () => {
                  await httpService.put('risk-matrix', {
                    cellData: metadata.cellData,
                    yAxisLabel: metadata.impactLabel,
                    yAxisLabels: metadata.impactLabels,
                    matrixSize: metadata.matrixSize,
                    xAxisLabel: metadata.probabilityLabel,
                    xAxisLabels: metadata.probabilityLabels,
                  });
                  onClose(metadata);
                },
                text: 'Save',
                disabled: isEqual(data, metadata) || !isValid(),
                dataAid: generateDataAid('riskMatrixForm', ['save']),
              },
            ]
          : []),
      ]}
      title={'Customize risk matrix'}
    >
      <Root>
        <Form>
          <MatrixSizeContainer data-aid={generateDataAid('riskMatrixForm', ['matrix-size'])}>
            <BigidBody1>{'Matrix Size'}</BigidBody1>
            <div>
              <BigidDropdown
                dataAid={generateDataAid('riskMatrixForm', ['matrix-size'])}
                options={Sizes}
                onSelect={val => {
                  const metadataClone = cloneDeep(metadata);
                  setPrevSelectedGridSize(metadataClone.matrixSize);
                  metadataClone.matrixSize = val[0].value;
                  metadataClone.cellData = cellData.get(val[0].value);
                  metadataClone.probabilityLabels = xAxis.get(val[0].value);
                  metadataClone.impactLabels = yAxis.get(val[0].value);
                  setMetadata(metadataClone);
                }}
                listWidth={120}
                value={Sizes.filter(size => size.value === metadata.matrixSize)}
                readOnly={!isPermitted(PRIVACY_RISKS_PERMISSIONS.MATRIX_CONFIG_EDIT.name)}
              />
            </div>
          </MatrixSizeContainer>
          <Section data-aid={generateDataAid('riskMatrixForm', ['severity-level'])}>
            <BigidBody1>{'Severity level'}</BigidBody1>
            <Expandable expanded={expandedSection === ExpandedSection.RiskLevel}>
              <ExpandableHeader
                data-aid={generateDataAid('riskMatrixForm', ['severity-level', 'header'])}
                onClick={() =>
                  setExpandedSection(prev =>
                    prev === ExpandedSection.RiskLevel ? ExpandedSection.None : ExpandedSection.RiskLevel,
                  )
                }
              >
                <div
                  onClick={event => {
                    event.preventDefault();
                    event.stopPropagation();
                  }}
                >
                  <BigidBody1>{'Risk Level'}</BigidBody1>
                </div>
                {expandedSection === ExpandedSection.RiskLevel ? (
                  <BigidChevronUpIcon data-aid={generateDataAid('riskMatrixForm', ['severity-level', 'collapse'])} />
                ) : (
                  <BigidChevronDownIcon data-aid={generateDataAid('riskMatrixForm', ['severity-level', 'expand'])} />
                )}
              </ExpandableHeader>
              <ExpandableDetails expanded={expandedSection === ExpandedSection.RiskLevel}>
                {getSeverityLabels()}
              </ExpandableDetails>
            </Expandable>
          </Section>
          <Section data-aid={generateDataAid('riskMatrixForm', ['yAxis'])}>
            <BigidBody1>{'X Axis'}</BigidBody1>
            <Expandable expanded={expandedSection === ExpandedSection.YAxis}>
              <ExpandableHeader
                data-aid={generateDataAid('riskMatrixForm', ['yAxis', 'header'])}
                onClick={() =>
                  setExpandedSection(prev =>
                    prev === ExpandedSection.YAxis ? ExpandedSection.None : ExpandedSection.YAxis,
                  )
                }
              >
                <div
                  onClick={event => {
                    event.preventDefault();
                    event.stopPropagation();
                  }}
                >
                  <BigidTextField
                    dataAid={generateDataAid('riskMatrixForm', ['yAxis', 'label'])}
                    value={metadata.probabilityLabel}
                    isError={metadata.probabilityLabel.length === 0}
                    required
                    onChange={event => {
                      if (event.target.value.length > 15) return;
                      const metadataClone = cloneDeep(metadata);
                      metadataClone.probabilityLabel = event.target.value;
                      setMetadata(metadataClone);
                    }}
                    readOnly={!isPermitted(PRIVACY_RISKS_PERMISSIONS.MATRIX_CONFIG_EDIT.name)}
                  />
                </div>
                {expandedSection === ExpandedSection.YAxis ? (
                  <BigidChevronUpIcon data-aid={generateDataAid('riskMatrixForm', ['yAxis', 'collapse'])} />
                ) : (
                  <BigidChevronDownIcon data-aid={generateDataAid('riskMatrixForm', ['yAxis', 'expand'])} />
                )}
              </ExpandableHeader>
              <ExpandableDetails expanded={expandedSection === ExpandedSection.YAxis}>
                {getProbabilityLabels()}
              </ExpandableDetails>
            </Expandable>
          </Section>
          <Section data-aid={generateDataAid('riskMatrixForm', ['xAxis'])}>
            <BigidBody1>{'Y Axis'}</BigidBody1>
            <Expandable expanded={expandedSection === ExpandedSection.XAxis}>
              <ExpandableHeader
                data-aid={generateDataAid('riskMatrixForm', ['xAxis', 'header'])}
                onClick={() =>
                  setExpandedSection(prev =>
                    prev === ExpandedSection.XAxis ? ExpandedSection.None : ExpandedSection.XAxis,
                  )
                }
              >
                <div
                  onClick={event => {
                    event.preventDefault();
                    event.stopPropagation();
                  }}
                >
                  <BigidTextField
                    dataAid={generateDataAid('riskMatrixForm', ['xAxis', 'label'])}
                    value={metadata.impactLabel}
                    isError={metadata.impactLabel.length === 0}
                    required
                    onChange={event => {
                      if (event.target.value.length > 15) return;
                      const metadataClone = cloneDeep(metadata);
                      metadataClone.impactLabel = event.target.value;
                      setMetadata(metadataClone);
                    }}
                    readOnly={!isPermitted(PRIVACY_RISKS_PERMISSIONS.MATRIX_CONFIG_EDIT.name)}
                  />
                </div>
                {expandedSection === ExpandedSection.XAxis ? (
                  <BigidChevronUpIcon data-aid={generateDataAid('riskMatrixForm', ['xAxis', 'collapse'])} />
                ) : (
                  <BigidChevronDownIcon data-aid={generateDataAid('riskMatrixForm', ['xAxis', 'expand'])} />
                )}
              </ExpandableHeader>
              <ExpandableDetails expanded={expandedSection === ExpandedSection.XAxis}>
                {getImpactLabels()}
              </ExpandableDetails>
            </Expandable>
          </Section>
        </Form>
        <Preview>{MatrixPreview}</Preview>
      </Root>
    </BigidDialog>
  );
};
