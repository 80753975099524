import { openSystemDialog } from '../../services/systemDialogService';
import React, { useState, useEffect, FC, Dispatch } from 'react';
import { BigidColorsV2, BigidHeading6, BigidPrimaryCheckbox, PrimaryButton } from '@bigid-ui/components';
import { betaToolsService } from '../../services/angularServices';
import { notificationService } from '../../services/notificationService';
import angular from 'angular';
import { convertToAngular } from '../../../common/services/convertToAngular';
import makeStyles from '@mui/styles/makeStyles';
import { capitalize } from 'lodash';

interface DeletePIIDataDialogProps {
  isOpen: boolean;
  onClose: () => void;
  handleDeletePIIData: () => void;
}

const useStyles = makeStyles({
  content: {
    marginTop: '0px',
  },

  warning: {
    marginTop: '10px',
    fontSize: '14px',
    color: BigidColorsV2.red[700],
  },
});

interface DeletePIIDataDialogContentProps {
  deletePersonalInfo: boolean;
  deleteMetadata: boolean;
  deleteScanHistory: boolean;
  stopAllRunningScans: boolean;
  setDeletePersonalInfo: Dispatch<boolean>;
  setDeleteMetadata: Dispatch<boolean>;
  setDeleteScanHistory: Dispatch<boolean>;
  setStopAllRunningScans: Dispatch<boolean>;
}

const DeletePIIDataDialogContent: FC<DeletePIIDataDialogContentProps> = ({
  deletePersonalInfo,
  deleteMetadata,
  deleteScanHistory,
  stopAllRunningScans,
  setDeletePersonalInfo,
  setDeleteMetadata,
  setDeleteScanHistory,
  setStopAllRunningScans,
}) => {
  const classes = useStyles({});
  return (
    <>
      <div className={classes.content}>
        <BigidHeading6 gutterBottom>Delete my discovery results from BigID:</BigidHeading6>
      </div>
      <BigidPrimaryCheckbox
        label="Delete classification and correlation findings"
        checked={deletePersonalInfo}
        onChange={(event, checked) => setDeletePersonalInfo(checked)}
      />
      <BigidPrimaryCheckbox
        label="Delete metadata findings"
        checked={deleteMetadata}
        onChange={(event, checked) => setDeleteMetadata(checked)}
      />
      <BigidPrimaryCheckbox
        label="Delete scan history (results from scans in progress may be distorted)"
        checked={deleteScanHistory}
        onChange={(event, checked) => setDeleteScanHistory(checked)}
      />
      <BigidPrimaryCheckbox
        label="Stop all running scans (this may take some time)"
        checked={stopAllRunningScans}
        onChange={(event, checked) => setStopAllRunningScans(checked)}
      />
      <div className={classes.warning}>
        <strong>Warning: this cannot be undone.</strong>
      </div>
    </>
  );
};

const deleteAndNotify = async (handleDeletePIIData: () => void, stopAllRunningScans: boolean) => {
  try {
    await betaToolsService.deleteData(stopAllRunningScans);
    notificationService.success('PII Data has been deleted successfully! ');
    setTimeout(handleDeletePIIData, 30000);
  } catch (e) {
    const defaultErrorMessage = 'An error has occurred!';
    const responseErrorMessage = e?.data?.error && capitalize(e.data.error);

    notificationService.error(responseErrorMessage || defaultErrorMessage);
  } finally {
    handleDeletePIIData();
  }
};

export const DeletePIIDataDialog: FC<DeletePIIDataDialogProps> = ({ handleDeletePIIData, isOpen, onClose }) => {
  const [deletePersonalInfo, setDeletePersonalInfo] = useState(false);
  const [deleteMetadata, setDeleteMetadata] = useState(false);
  const [deleteScanHistory, setDeleteScanHistory] = useState(false);
  const [stopAllRunningScans, setStopAllRunningScans] = useState(false);

  const disabled = !(deletePersonalInfo && deleteMetadata && deleteScanHistory);

  const deletePIIDataDialog: () => void = () => {
    openSystemDialog<DeletePIIDataDialogContentProps>({
      title: 'Delete Discovery Results',
      onClose: () => {
        onClose();
        setDeletePersonalInfo(false);
        setDeleteMetadata(false);
        setDeleteScanHistory(false);
        setStopAllRunningScans(false);
      },
      content: DeletePIIDataDialogContent,
      contentProps: {
        deletePersonalInfo,
        deleteMetadata,
        deleteScanHistory,
        stopAllRunningScans,
        setDeletePersonalInfo,
        setDeleteMetadata,
        setDeleteScanHistory,
        setStopAllRunningScans,
      },
      buttons: [
        {
          text: 'Cancel',
          component: PrimaryButton,
          onClick: () => {
            return null;
          },
          isClose: true,
        },
        {
          text: 'Delete Now',
          component: PrimaryButton,
          onClick: () => deleteAndNotify(handleDeletePIIData, stopAllRunningScans),
          isClose: true,
          disabled: disabled,
        },
      ],
    });
  };

  useEffect(() => {
    if (isOpen) {
      deletePIIDataDialog();
    }
  }, [isOpen, deletePIIDataDialog]);
  return null;
};

angular
  .module('app')
  .component(
    'deletePiiDataDialog',
    convertToAngular<DeletePIIDataDialogProps>(DeletePIIDataDialog, ['isOpen', 'onClose', 'handleDeletePIIData']),
  );
