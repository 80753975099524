import { httpService } from '../../../../services/httpService';
import { DEFAULT_TOGGLE_VALUE } from './ExtendedInfo';

export interface ExtendedInfo {
  extended_attributes: ExtendedInfoAttribute[];
  report_config: PersonalInfoReportConfig[];
}

export interface ExtendedInfoAttribute {
  att_friendly_name: string;
  attribute: string;
  value: string;
  purpose_of_use: string;
}

export interface ReportConfigAttribute {
  name: string;
  is_enabled: boolean;
}

export interface PersonalInfoReportConfig {
  _id: string;
  type: 'personal_info_attributes' | 'personal_info';
  requestId?: string;
  attributes?: ReportConfigAttribute[];
  extended_attributes?: ReportConfigAttribute[];
}

export interface ExtendedInfoPutResponseModel {
  isModified: boolean;
}

export interface DSARReportNormalizedAttribute {
  name: string;
  values: string[];
  purposes: string[];
  isEnabled: boolean;
}

export const extendedInfoService = {
  getExtendedInfo: (id: string) =>
    httpService
      .fetch<ExtendedInfo>(`sar/reports/${encodeURIComponent(id)}/extended-info`)
      .then(response => response.data),

  updateExtendedReportConfig: (id: string, data: ReportConfigAttribute) =>
    httpService
      .put<ExtendedInfoPutResponseModel, ReportConfigAttribute>(
        `sar/reports/${encodeURIComponent(id)}/extended-info/config`,
        data,
      )
      .then(response => response.data),

  aggregateExtendedAttributes: (extendedInfo: ExtendedInfo): DSARReportNormalizedAttribute[] => {
    const { extended_attributes: extendedAttributes, report_config: reportConfig } = extendedInfo;
    const togglesMap = new Map<string, boolean>();
    if (reportConfig) {
      reportConfig
        .filter(({ type, extended_attributes: attrs }) => type === 'personal_info_attributes' && attrs)
        .reduce<ReportConfigAttribute[]>((acc, { extended_attributes: attrs }) => [...acc, ...attrs], [])
        .forEach(({ name, is_enabled: isEnabled }) => togglesMap.set(name, isEnabled));
    }

    const mapByName = new Map<string, DSARReportNormalizedAttribute>();
    extendedAttributes.forEach(({ att_friendly_name: name, purpose_of_use: purpose, value }) => {
      const {
        values = [],
        purposes = [],
        isEnabled = togglesMap.has(name) ? togglesMap.get(name) : DEFAULT_TOGGLE_VALUE,
      } = mapByName.get(name) || {};

      mapByName.set(name, {
        name,
        values: values.concat(value),
        purposes: purposes.concat(purpose),
        isEnabled,
      });
    }, {});

    // convert all arrays to unique values
    return [...mapByName].map(([, attr]) => ({
      ...attr,
      values: [...new Set(attr.values)],
      purposes: [...new Set(attr.purposes)],
    }));
  },
};
