import {
  BigidBody1,
  BigidColors,
  BigidDropZone,
  BigidFieldRenderProps,
  compareObjectsExceptFunctions,
  generateFieldId,
} from '@bigid-ui/components';
import { FormControl } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React, { FC, memo, useState, useEffect } from 'react';

import { base64ToFile, toBase64 } from './mappers';

const useStyles = makeStyles({
  root: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  labelWrapper: ({ labelWidth }: Partial<BigidFieldRenderProps>) => ({
    minWidth: labelWidth,
    alignItems: 'center',
    display: 'inline-flex',
    paddingLeft: 8,
    height: '100%',
    color: BigidColors.gray[700],
  }),
  formField: {
    display: 'flex',
    width: '100%',
  },
  fieldWrapper: {
    maxWidth: '100%',
  },
});

// TODO: move to bigid-ui in the next phase of Report Template development and after BigidForm changes merged
export const BigidFormFileUpload: FC<BigidFieldRenderProps> = memo(
  ({ value, setValue, onBlur, disabled, misc, label, name, labelWidth }) => {
    const [file, setFile] = useState<File | undefined>(undefined);
    const classes = useStyles({ labelWidth });

    const handleOnChangeItem = async (files: File[]) => {
      if (!files || !files.length || !files[0]) {
        setValue(undefined);
        setFile(undefined);
        return;
      }
      const file = files[0];
      const fileAsBase64 = await toBase64(file);
      setValue(fileAsBase64);
      setFile(file);
    };

    useEffect(() => {
      const convert = async () => {
        if (value) {
          const dataAsFile = await base64ToFile(value, 'logo');
          if (dataAsFile && dataAsFile instanceof File) {
            setFile(dataAsFile);
          }
        }
      };
      convert();
    }, [value]);

    const htmlId = generateFieldId(name);
    return (
      <div className={classes.root}>
        {label !== false && (
          <label className={classes.labelWrapper} htmlFor={htmlId}>
            <BigidBody1>{label}</BigidBody1>
          </label>
        )}
        <FormControl disabled={disabled} className={classes.formField} margin="normal" fullWidth={misc?.fullWidth}>
          <div
            id={htmlId}
            onBlurCapture={onBlur}
            data-aid={`BigidFormFileUpload-name-${name}`}
            className={classes.fieldWrapper}
          >
            <BigidDropZone maxSize={1024} accept="image/*" onDrop={handleOnChangeItem} files={file ? [file] : []} />
          </div>
        </FormControl>
      </div>
    );
  },
  compareObjectsExceptFunctions,
);

BigidFormFileUpload.displayName = 'BigidFormFileUpload';
