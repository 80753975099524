import makeStyles from '@mui/styles/makeStyles';
import { BigidColors } from '@bigid-ui/components';
import { DataSourceTestConnectionStatusEnum } from '../hooks/useTestConnection';
import { DataSourceConfigurationState } from '../hooks/useDataSourceConfigState';

export const useStyles = makeStyles({
  wrapper: {
    display: 'flex',
    minHeight: '310px',
    justifyContent: 'center',
    position: 'relative',
  },
  buttonTextWrapper: {
    maxHeight: '18px',
    lineHeight: '18px',
  },
  connectedIllustration: {
    display: 'flex',
    width: '100%',
    justifyContent: 'center',
  },
  '@keyframes fromTopToMiddle': {
    '0%': {
      top: '15px',
      left: 0,
    },
    '100%': {
      top: '86px',
      left: 'calc(50% - 120px)',
    },
  },
  '@keyframes fromBottomToMiddle': {
    '0%': {
      top: '210px',
      right: 0,
    },
    '100%': {
      top: '130px',
      right: 'calc(50% - 115px)',
    },
  },
  '@keyframes fromTopToMiddleFailed': {
    '0%': {
      top: '15px',
      left: 0,
    },
    '100%': {
      top: '55px',
      left: 'calc(50% - 130px)',
    },
  },
  '@keyframes fromBottomToMiddleFailed': {
    '0%': {
      top: '210px',
      right: 0,
    },
    '100%': {
      top: '120px',
      right: '32px',
    },
  },
  actionsWrapper: ({ testStatus }: Partial<DataSourceConfigurationState>) => ({
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: testStatus === DataSourceTestConnectionStatusEnum.success ? '20px' : '100px',
  }),
  resultHeaderWrapper: ({ testStatus }: Partial<DataSourceConfigurationState>) => ({
    color: testStatus === DataSourceTestConnectionStatusEnum.success ? BigidColors.green[400] : '#D80000',
    margin: '16px 0 0 0',
  }),
  dateWrapper: {
    margin: '16px 0',
    width: '100%',
    textAlign: 'center',
  },
  errorTextWrapper: {
    marginBottom: '20px',
    padding: '0 40px',
    textAlign: 'center',
    wordBreak: 'break-word',
    maxWidth: '340px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    '-webkit-line-clamp': '2',
    '-webkit-box-orient': 'vertical',
  },
  tableNameWrapper: {
    color: BigidColors.gray[700],
    flex: '1 0 auto',
    width: '100%',
    marginTop: '48px',
  },
  resultTableWrapper: {
    width: '338px',
    height: 'calc(100vh - 660px)',
    minHeight: '136px',
    display: 'flex',
    margin: '8px -8px 20px 0',
  },
  buttonTextFirstTestWrapper: {
    maxHeight: '18px',
    lineHeight: '18px',
    color: BigidColors.white,
  },
  connectionNotSupported: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
});
