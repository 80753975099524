import React, { useState, useEffect, useMemo } from 'react';
import { getCountries } from '../../DSAR/dsarService';
import { CountryData } from '../../Fmsd/fmsdServices';
import { notificationService } from '../../../services/notificationService';
import { CountryCodeMapperType } from '.././LegalEntitiesTypes';

export const useCountryCodes = () => {
  const [countryData, setCountryData] = useState<CountryData[]>([]);
  useEffect(() => {
    const fetchCountryData = async () => {
      try {
        const countries = await getCountries();
        setCountryData(countries);
      } catch (err) {
        notificationService.error(`Failed to fetch Locations`);
        console.error(`Failed to fetch Locations: ${JSON.stringify(err?.response)}`);
        setCountryData([]);
      }
    };
    fetchCountryData();
  }, []);

  const countryNameToCodeMapper = useMemo(() => {
    return countryData?.reduce(
      (acc, prop) => ((acc[prop.name] = prop.groupedCode.slice(0, 2)), acc),
      {} as CountryCodeMapperType,
    );
  }, [countryData]);
  return {
    countryNameToCodeMapper,
  };
};
