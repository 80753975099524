import React from 'react';
import { DetailsTab, PermissionsTab } from '.';

export const TabContent = ({ tab, caseDetails }: any) => {
  switch (true) {
    case tab === 0:
      return <DetailsTab caseDetails={caseDetails} />;
    case tab === 1:
      return <PermissionsTab />;
    default:
      return null;
  }
};
