import React, { FC, Dispatch, SetStateAction } from 'react';
import { BigidToolbarAction, ToolbarAction, ToolbarActionType, BigidBody1 } from '@bigid-ui/components';
import styled from '@emotion/styled';

import { hotspotsChartEntityFields } from './HotspotsReportChart';
import { BigidContentContainer, BigidHotspotsChartFieldSettings, LayoutContentType } from '@bigid-ui/layout';
import { HotspotsContainer } from './HotspotsContainer';
import { HOTSPOTS_CHART_VIEW_SIZES } from './consts';

const SwitchLeftLabel = styled(BigidBody1)({
  marginRight: 12,
  transform: 'translate(0, 3px)',
});

const ActionsWrapper = styled.div({
  display: 'flex',
  justifyContent: 'flex-end',
  margin: '0 12px',
});

type BigidContentContainerWithCustomViewProp = Omit<
  ReturnType<typeof BigidContentContainer.useContainer>,
  'customViewProp' | 'setCustomViewProp'
> & {
  customViewProp: BigidHotspotsChartFieldSettings;
  setCustomViewProp: Dispatch<SetStateAction<BigidHotspotsChartFieldSettings>>;
};

export const HotspotsReportChartControls: FC = () => {
  const {
    contentView: { currentView },
    setCustomViewProp,
    customViewProp,
  } = BigidContentContainer.useContainer() as BigidContentContainerWithCustomViewProp;

  const { setSavedChartFieldSettings } = HotspotsContainer.useContainer();
  const actions: ToolbarAction[] = [
    {
      label: 'Density',
      type: ToolbarActionType.SWITCH,
      switchProps: {
        isChecked: () => {
          return customViewProp?.hotspotsChartBoxSizeField === 'totalDensity';
        },
      },
      show: () => true,
      execute: async () => {
        if (customViewProp?.hotspotsChartBoxSizeField === 'totalDensity') {
          setCustomViewProp(prevState => ({
            ...prevState,
            hotspotsChartBoxSizeField: 'totalAttrTag',
            hotspotsChartColorField: 'totalAttrTag',
          }));
          setSavedChartFieldSettings(prevState => ({
            ...prevState,
            hotspotsChartBoxSizeField: 'totalAttrTag',
            hotspotsChartColorField: 'totalAttrTag',
          }));
        }
        if (customViewProp?.hotspotsChartBoxSizeField === 'totalAttrTag') {
          setCustomViewProp(prevState => ({
            ...prevState,
            hotspotsChartBoxSizeField: 'totalDensity',
            hotspotsChartColorField: 'totalDensity',
          }));
          setSavedChartFieldSettings(prevState => ({
            ...prevState,
            hotspotsChartBoxSizeField: 'totalDensity',
            hotspotsChartColorField: 'totalDensity',
          }));
        }
        return {};
      },
    },
    {
      label: 'View',
      type: ToolbarActionType.DROPDOWN,
      show: () => true,
      dropdownProps: {
        placeholder: 'View',
        value: [{ label: '' + customViewProp?.hotspotsChartViewSize, value: customViewProp?.hotspotsChartViewSize }],
        options: HOTSPOTS_CHART_VIEW_SIZES.map(option => ({
          label: '' + option,
          value: option,
          show: () => true,
          execute: async () => {
            setCustomViewProp(prevState => ({
              ...prevState,
              hotspotsChartViewSize: option,
            }));
            setSavedChartFieldSettings(prevState => ({
              ...prevState,
              hotspotsChartViewSize: option,
            }));
            return {};
          },
        })),
      },
    },
  ];

  return (
    <>
      {currentView === LayoutContentType.HOTSPOTS_CHART && (
        <ActionsWrapper>
          <SwitchLeftLabel>
            {hotspotsChartEntityFields.find(({ value }) => value === 'totalAttrTag')?.label}
          </SwitchLeftLabel>
          {actions.map((action, i) => (
            <BigidToolbarAction key={i} {...action} />
          ))}
        </ActionsWrapper>
      )}
    </>
  );
};
