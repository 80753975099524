import { CloudProvider } from '../AutoDiscoveryWizard/autoDiscoveryWizardServices';
import { AutoDiscoveryConfigModel } from './AutoDiscoveryGrid/AutoDiscoveryGridConfiguration';
import { getApplicationPreference } from '../../services/appPreferencesService';
import { $state } from '../../services/angularServices';
import { CONFIG } from '../../../config/common';

export const goToAutoDiscovery = async (
  openAutoDiscoveryModal: (type?: CloudProvider, configData?: AutoDiscoveryConfigModel) => void,
) => {
  if (openAutoDiscoveryModal && getApplicationPreference('SHOW_NEW_AUTO_DISCOVERY_UI_FF')) {
    await $state.go(CONFIG.states.DATA_SOURCE_CONNECTIONS, { ...$state.params, tab: 'discovery' });
    openAutoDiscoveryModal();
  } else {
    $state.go(CONFIG.states.AUTO_DISCOVERY);
  }
};
