import { countries } from '../../config/countries';
import { getApplicationPreference } from '../../react/services/appPreferencesService';

export const getLocationName = location => {
  if (getApplicationPreference('DISPLAY_CHINA_REGULATIONS')) {
    if (location.toLowerCase() === 'taiwan') {
      return 'Taiwan, China';
    }
    if (location.toLowerCase() === 'hong kong') {
      return 'Hong Kong, China';
    }
    if (location.toLowerCase() === 'macao') {
      return 'Macao, China';
    }
  }
  return location;
};

export function setAmMapData(model, result) {
  const circleSVG = 'M10,0a5,5 0 1,1 0,10a5,5 0 1,1 1,-10Z';

  model.imagesArray = [];
  model.linesArray = [];

  let sumIdentity = 0;
  for (let j = 0; j < result[0].identity_locations.length; j++) {
    sumIdentity += result[0].identity_locations[j].count;
  }

  for (let i = 0; i < result[0].identity_locations.length; i++) {
    const dataIdentityObject = getObjectDetailsFromCountriesArray(result[0].identity_locations[i].name);

    if (dataIdentityObject) {
      let scale = (result[0].identity_locations[i].count / sumIdentity) * 11;
      if (sumIdentity == 1) {
        scale = 5;
      }

      const residency = getLocationName(result[0].identity_locations[i].name);
      const title = `Residency: ${residency}<br>PII Records: ${result[0].identity_locations[i].count}`;

      model.imagesArray.push({
        color: '#FF5E43',
        svgPath: circleSVG,
        title,
        groupId: 'user',
        latitude: dataIdentityObject.points[0].latitude,
        longitude: dataIdentityObject.points[0].longitude,
        selectable: true,
        country: result[0].identity_locations[i].name,
        scale: scale > 1 ? scale : 1,
        addClassNames: true,
      });
    }
  }

  let sumLocations = 0;
  for (let i = 0; i < result[1].system_locations.length; i++) {
    sumLocations += result[1].system_locations[i].count;
  }
  for (let j = 0; j < result[1].system_locations.length; j++) {
    const dataSystemObject = getObjectDetailsFromCountriesArray(result[1].system_locations[j].name);
    if (dataSystemObject) {
      let scale = (result[1].system_locations[j].count / sumLocations) * 11;
      if (sumLocations == 1) {
        scale = 5;
      }

      const location = getLocationName(result[1].system_locations[j].name);
      const title = `Location: ${location}<br>PII Records: ${result[1].system_locations[j].count}`;

      model.imagesArray.push({
        color: '#2196F3',
        svgPath: circleSVG,
        title,
        groupId: 'system',
        latitude: dataSystemObject.points[1].latitude,
        longitude: dataSystemObject.points[1].longitude,
        selectable: true,
        country: result[1].system_locations[j].name,
        scale: scale > 1 ? scale : 1,
        addClassNames: true,
      });
    }
  }

  let sumApplications = 0;
  let lineIndex = 0;

  for (let i = 0; i < result[2].applications_locations.length; i++) {
    sumApplications += result[2].applications_locations[i].count;
  }
  for (let j = 0; j < result[2].applications_locations.length; j++) {
    const dataApplicationObject = getObjectDetailsFromCountriesArray(result[2].applications_locations[j].name);
    if (dataApplicationObject) {
      let scale = (result[2].applications_locations[j].count / sumApplications) * 11;
      if (sumApplications == 1) {
        scale = 5;
      }

      const application = getLocationName(result[2].applications_locations[j].name);
      const title = `Asset: ${application}<br>PII Records: ${result[2].applications_locations[j].count}`;

      model.imagesArray.push({
        color: '#2ADE73',
        svgPath: circleSVG,
        title,
        groupId: 'application',
        latitude: dataApplicationObject.points[2].latitude,
        longitude: dataApplicationObject.points[2].longitude,
        selectable: true,
        country: result[2].applications_locations[j].name,
        scale: scale > 1 ? scale : 1,
        addClassNames: true,
      });
    }
  }

  for (let t = 0; t < result[2].applications_locations.length; t++) {
    let dataSourceName = '';
    //Get data source name
    if (result[2].applications_locations[t].target_data_sources) {
      for (let index = 0; index < result[2].applications_locations[t].target_data_sources.length; index++) {
        dataSourceName = result[2].applications_locations[t].target_data_sources[index]; //data source name
        //check if the data source name exists on the system location
        for (let j = 0; j < result[1].system_locations.length; j++) {
          const systemsNameArray = result[1].system_locations[j].systems;
          if (systemsNameArray) {
            for (let i = 0; i < systemsNameArray.length; i++) {
              if (dataSourceName == '') break;
              if (systemsNameArray[i] === dataSourceName) {
                const sourceCountry = result[2].applications_locations[t].name;
                let cordinatePointsOfSourceCountry = null;
                let cordinatePointsOfDestinationCountry = null;
                if (sourceCountry) {
                  cordinatePointsOfSourceCountry = getObjectDetailsFromCountriesArray(sourceCountry);
                }
                const destinationCountry = result[1].system_locations[j].name;
                if (destinationCountry) {
                  cordinatePointsOfDestinationCountry = getObjectDetailsFromCountriesArray(destinationCountry);
                }
                if (
                  sourceCountry &&
                  destinationCountry &&
                  cordinatePointsOfSourceCountry &&
                  cordinatePointsOfDestinationCountry
                ) {
                  model.linesArray.push({
                    arc: -0.85,
                    alpha: 0.3,
                    id: 'line' + lineIndex,
                    latitudes: [
                      cordinatePointsOfDestinationCountry.points[1].latitude,
                      cordinatePointsOfSourceCountry.points[2].latitude,
                    ],
                    longitudes: [
                      cordinatePointsOfDestinationCountry.points[1].longitude,
                      cordinatePointsOfSourceCountry.points[2].longitude,
                    ],
                    arrow: 'middle',
                    //"color": "#CC0000",
                    arrowAlpha: 1,
                    arrowSize: 4,
                  });

                  lineIndex++;
                }
              }
            }
          }
        }
      }
    }
  }
}

const countriesMaps = {
  name: new Map(),
  alpha2: new Map(),
  alpha3: new Map(),
  isInitialized: false,
};

export function getCountriesMaps() {
  if (!countriesMaps.isInitialized) {
    countries.forEach(country => {
      [
        { prop: 'name', map: countriesMaps.name },
        { prop: 'alpha-2', map: countriesMaps.alpha2 },
        { prop: 'alpha-3', map: countriesMaps.alpha3 },
      ].forEach(({ prop, map }) => {
        const mapKey = country[prop].toUpperCase();
        map.set(mapKey, [...(map.get(mapKey) || []), country]);
      });
    });
    countriesMaps.isInitialized = true;
  }

  return countriesMaps;
}

export function getObjectDetailsFromCountriesArray(name) {
  if (!name) {
    return null;
  }

  const RESIDENCY_STATES_FIRST = getApplicationPreference('RESIDENCY_STATES_FIRST');
  const maps = getCountriesMaps();
  const key = name.toUpperCase();
  const matches = [];

  // check for case of `state vs country` by alpha-2 first, eg: 'CA' will result California vs Canada
  if (name.length === 2 && maps.alpha2.has(key)) {
    matches.push(...maps.alpha2.get(key));
  }
  // check for case of `state vs country` by Name, eg: 'Georgia'
  else if (maps.name.has(key)) {
    matches.push(...maps.name.get(key));
  }
  // if we didn't find any match by Name or alpha-2, try to find it by alpha-3
  else if (key.length === 3 && maps.alpha3.has(key)) {
    matches.push(...maps.alpha3.get(key));
  }

  // sort by isState and use RESIDENCY_PREFER_STATE flag to make the `state vs country` preference decision
  matches.sort(({ isState: a }, { isState: b }) => ((b || 0) - (a || 0)) * (RESIDENCY_STATES_FIRST ? 1 : -1));

  if (matches.length > 1) {
    window.console.log(
      `ammap service 'state vs country' collision for name: '${name}', matches: `,
      JSON.stringify(matches),
    );
  }

  return matches[0] || null;
}
