import { BigidFieldFilter, convertRecurringScheduleObjectToCron } from '@bigid-ui/components';
import { queryService } from '../../../services/queryService';
import { getScanTemplates } from '../ScanTemplates/scanTemplatesService';
import { uniqBy, isNil } from 'lodash';
import {
  GetConvertProfileToScanButtonsParams,
  GetScanTemplateQueryProps,
  ScanProfileConversionFormData,
  ScanProfileConversionTypeEnum,
  ScanProfileFields,
} from './scanProfileConversionTypes';
import {
  createScheduledScan,
  handleScheduledScanErrors,
  handleSaveTemplate,
  CreateScheduledScanFormData,
} from '../ScanCreateWizard/createScanService';
import { notificationService } from '../../../services/notificationService';
import { ScanTypes } from '../ScanTemplates/scanTemplateTypes';

export const DEFAULT_MAX_SCAN_TEMPLATES_IN_REQUEST = 100;

const getScanTemplateFilterByString = (scanTemplateName: string): BigidFieldFilter[] => {
  return [
    {
      field: 'name',
      operator: 'textSearch',
      value: scanTemplateName,
    },
  ];
};

export const getScanTemplateQueries = ({
  searchString,
  maxTemplates = DEFAULT_MAX_SCAN_TEMPLATES_IN_REQUEST,
  filter,
}: GetScanTemplateQueryProps) => {
  const paginationObject = { skip: 0, limit: maxTemplates };
  const filterObject = filter
    ? { filter }
    : {
        filter: searchString ? getScanTemplateFilterByString(searchString) : [],
      };

  return queryService.getGridConfigQuery({ ...paginationObject, ...filterObject });
};

export const fetchScanTemplateNames = async (searchString?: string) => {
  const query = getScanTemplateQueries({ searchString, maxTemplates: DEFAULT_MAX_SCAN_TEMPLATES_IN_REQUEST });
  const { scanTemplates } = await getScanTemplates(query);
  return uniqBy(
    scanTemplates.map(scanTemplate => ({
      label: scanTemplate.name,
      value: scanTemplate._id,
      isSelected: false,
    })),
    'value',
  );
};

export const createScanTemplateFromProfileFields = async (
  scanProfileFields: ScanProfileFields,
  scanTemplateName: string,
): Promise<string> => {
  const {
    scanType,
    idsorList,
    allEnabledIdSor,
    description,
    classifiers,
    isReviewFindingsEnabled,
    skipIdScan,
    isClassificationsAsPiiFindings,
    labelFramework,
  } = scanProfileFields;
  const isLabeling = scanProfileFields.scanType === ScanTypes.LABELING;
  const isUseGlobalClassifiers = classifiers?.length === 0 || isNil(classifiers);

  const scanTemplateId = await handleSaveTemplate({
    scanType,
    name: scanTemplateName,
    description,
    ...(!isLabeling && {
      allEnabledIdSor,
      isClassificationsAsPiiFindings,
      idConnectionList: idsorList,
      isReviewFindingsEnabled,
      classifiers: isNil(classifiers) ? [] : classifiers,
      skipIdScan,
      isUseGlobalClassifiers,
    }),
    ...(isLabeling && {
      isOverwritePrivilegedLabels: scanProfileFields?.isOverwritePrivilegedLabels,
      isOverwriteTag: scanProfileFields?.isOverwriteTag,
    }),
  });

  return scanTemplateId;
};

export const getScanTemplateId = async (
  scanProfileConversionFormData: ScanProfileConversionFormData,
  scanProfileFields: ScanProfileFields,
): Promise<string> => {
  let scanTemplateId;
  if (scanProfileConversionFormData.conversionType === ScanProfileConversionTypeEnum.SCAN) {
    scanTemplateId = await createScanTemplateFromProfileFields(
      scanProfileFields,
      scanProfileConversionFormData.templateName,
    );
  } else {
    scanTemplateId = scanProfileConversionFormData.templateId;
  }
  return scanTemplateId;
};

export const createScan = async (
  {
    scanProfileFields,
    scanProfileConversionFormData,
    isSchedule,
  }: Pick<GetConvertProfileToScanButtonsParams, 'scanProfileFields' | 'isSchedule' | 'scanProfileConversionFormData'>,
  scanTemplateId: string,
  isSingleRunScan?: boolean,
): Promise<ScanProfileFields | null> => {
  try {
    const scan: CreateScheduledScanFormData = {
      scanId: null,
      ...(isSchedule && {
        schedule: convertRecurringScheduleObjectToCron(scanProfileConversionFormData.scanSchedule),
      }),
      dataSourcesIds: scanProfileFields?.dataSourceList ?? [],
      owners: scanProfileConversionFormData.owners,
      description: scanProfileFields.description,
      allEnabledDs: scanProfileFields?.allEnabledDs ?? false,
      name: scanProfileConversionFormData.name,
    };
    const { data } = await createScheduledScan(scan, scanTemplateId, false, isSingleRunScan);
    if (data) {
      notificationService.success('Scan created successfully!');
      return data;
    } else {
      notificationService.error('Failed to create scan, see logs for more information.');
      return null;
    }
  } catch (e) {
    handleScheduledScanErrors(e, scanProfileConversionFormData.name, 'create');
  }
};
