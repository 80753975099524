import React, { FC, ComponentType } from 'react';
import { styled } from '@mui/material';
import { BigidBody1 } from '@bigid-ui/components';
import { generateDataAid } from '@bigid-ui/utils';
import { IconComponentProps } from '@bigid-ui/icons';
import { useLocalTranslation } from '../translations';
import { getCurationGuidedTourStageTranslation } from '../curationUtils';
import { CurationGuidedTourStageId } from '../useCurationState';

const Root = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

const Body = styled(BigidBody1)`
  margin-top: 4px;
`;

const IconContainer = styled('div')`
  margin-top: 16px;
  display: flex;
  align-self: center;
`;

interface CurationGuidedTourStepProps {
  dataAid: string;
  icons?: ComponentType<IconComponentProps>[];
  body: string;
  curationGuidedTourStageId: CurationGuidedTourStageId;
}

export const CurationGuidedTourStep: FC<CurationGuidedTourStepProps> = ({
  dataAid = 'CurationGuidedTourStep',
  body,
  icons,
  curationGuidedTourStageId,
}) => {
  const { t } = useLocalTranslation(getCurationGuidedTourStageTranslation(curationGuidedTourStageId));

  return (
    <Root data-aid={dataAid}>
      <Body data-aid={generateDataAid(dataAid, ['body'])}>{t(body)}</Body>
      {icons && (
        <IconContainer>
          {icons.map((Icon, b) => {
            return <Icon key={b} dataAid={generateDataAid(dataAid, ['icon'])} />;
          })}
        </IconContainer>
      )}
    </Root>
  );
};
