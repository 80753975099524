import React, { memo, FC } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { BigidPrimaryCheckbox, BigidCheckboxProps } from '@bigid-ui/components';

const useStyles = makeStyles({
  root: isShorten => ({
    // NOTE: a hot-fix in order to align form inputs with BigidExtraField
    width: isShorten ? 'calc(100% - 58px)' : '100%',
    display: 'flex',
    flexWrap: 'nowrap',
    alignItems: 'baseline',
    justifyContent: 'flex-end',
  }),
});

type EditTagDialogTextFieldProps = Pick<BigidCheckboxProps, 'onChange' | 'checked' | 'label'> & {
  isShorten: boolean;
};

export const EditTagDialogCheckboxField: FC<EditTagDialogTextFieldProps> = memo(
  ({ checked, label, isShorten, onChange }) => {
    const classes = useStyles({ isShorten });

    return (
      <div className={classes.root}>
        <BigidPrimaryCheckbox
          checked={checked}
          onChange={onChange}
          size="medium"
          label={label}
          dataAid="BigidCheckbox-tag-form-mutually-exclusive"
        />
      </div>
    );
  },
);

EditTagDialogCheckboxField.displayName = 'EditTagDialogCheckboxField';
