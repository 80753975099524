import { AsyncOperation } from '../../../components/AsyncOperationProcessingWidget/AsyncOperationProcessingWidget';
import { DateISO8601 } from '../../../types/types';
import { TagAssignmentTarget, TagEntity } from '../../TagsManagement/TagsManagementService';

export enum DataExplorerAsyncOperationRoutingKey {
  TAG_BULK_ASSIGNMENT = 'catalog.tasks.bulks.tags.assignment',
  TAG_BULK_UNASSIGNMENT = 'catalog.tasks.bulks.tags.unassignment',
}

export enum DataExplorerAsyncOperationType {
  TAG_BY_QUERY = 'TagByQuery',
  UPDATE_BY_QUERY = 'UpdateByQuery',
  UNTAG_BY_QUERY = 'UntagByQuery',
  FIND_AND_DELEGATE_QUERY = 'FindAndDelegateQuery',
}

export const tagBulkAssignmentNameMapping: DataExplorerAsyncOperationNameMapping = {
  [DataExplorerAsyncOperationType.TAG_BY_QUERY]: 'Assigning',
  [DataExplorerAsyncOperationType.UNTAG_BY_QUERY]: 'Unassigning',
};

export enum DataExplorerAsyncOperationStatus {
  NOT_STARTED = 'NOT_STARTED',
  COMPLETED = 'COMPLETED',
  ERROR = 'ERROR',
  RUNNING = 'RUNNING',
}

export interface DataExplorerAsyncOperation<Payload = any, QueryObject = any, SseData = any> {
  id: string;
  /** actual task status */
  taskStatus?: DataExplorerAsyncOperationStatus;
  /** status of the post-task indexing if relevant for the given operation */
  indexingStatus?: DataExplorerAsyncOperationStatus;
  /** computed status of the operation: taskStatus & indexingStatus */
  status?: DataExplorerAsyncOperationStatus;
  payload?: Payload;
  name?: DataExplorerAsyncOperationType;
  totalParts?: number;
  completedParts?: number;
  totalObjects?: number;
  find: QueryObject;
  nextRoutingKey: DataExplorerAsyncOperationRoutingKey;
  percentage?: number;
  sseRoute?: string;
  sseData?: SseData;
  updatedAt?: DateISO8601;
}

export type DataExplorerAsyncOperationNameMapping = { [operator in DataExplorerAsyncOperationType]?: string };

export type DataExplorerActingAsyncOperationsStorage = {
  [operation in DataExplorerAsyncOperationRoutingKey]?: DataExplorerAsyncOperation[];
};

export type DataExplorerAsyncOperationListenerParams = {
  onOperationRun?: (sseRoute: string, isOperationRunning: boolean) => void;
  onOperationCompleted?: () => void;
};

export type DataExplorerAsyncOperationListenerResponse<Operation = AsyncOperation> = {
  operations: Operation[];
};

export interface TagBulkAssignmentPayloadData {
  type: TagAssignmentTarget;
  tags: TagEntity[];
}

export interface TagBulkAssignmentAsyncOperationPayload {
  type: TagAssignmentTarget;
  tags: TagEntity[];
}

export type TagBulkAssignmentSseResults = DataExplorerAsyncOperation<TagBulkAssignmentAsyncOperationPayload>;

export type TagBulkAssignmentOperationsSorted = Record<DataExplorerAsyncOperationStatus, TagBulkAssignmentSseResults[]>;

export type UseTagBulkAssignmentParams = DataExplorerAsyncOperationListenerParams;
export type UseTagBulkAssignmentResponse = DataExplorerAsyncOperationListenerResponse<AsyncOperation>;
