export const openGenericCloudOauthWindow = (
  oauthUrl: string,
  callback: (resData: any) => void,
  errorCallback?: () => void,
) => {
  const configWindow = window.open(
    oauthUrl,
    '_blank',
    'width=500,height=600,scrollbars=no,toolbar=0,location=0,menubar=0',
  );

  let configFinished = false;
  let resData = {};
  const onmessage = (msg: {
    data: { type: string; err: any; data: { visibleSlots: any[]; slotValues: { [x: string]: any } } };
  }) => {
    if (msg.data.type === 'ldsar.authpopup.finish') {
      configFinished = true;
      resData = msg.data;
      configWindow?.close();
    } else if (msg.data.type === 'ldsar.authpopup.close' && configFinished == false) {
      resData = msg.data;
      configWindow?.close();
    } else if (msg.data.type === 'ldsar.authpopup.error') {
      console.log(`Error: ${msg.data.err}`);
    } else if (msg.data.type === 'ldsar.authpopup.cancel') {
      configWindow?.close();
    }

    if (msg.data.type === 'ldsar.authpopup.validate') {
      configWindow?.postMessage(
        {
          type: 'ldsar.authpopup.client.validation',
          data: {
            inProgress: true,
          },
        },
        '*',
      );

      setTimeout(() => {
        const errors = msg.data.data.visibleSlots.reduce((errors: any, externalId: string | number) => {
          return errors;
        }, {});

        configWindow?.postMessage(
          {
            type: 'ldsar.authpopup.client.validation',
            data: {
              inProgress: false,
              errors: errors,
            },
          },
          '*',
        );
      }, 2000);
    }
  };
  window.addEventListener('message', onmessage);

  const CHECK_TIMEOUT = 1000;
  const checkWindow = () => {
    if (configWindow?.closed) {
      if (!configFinished) {
        errorCallback && errorCallback();
      } else {
        callback(resData);
      }
      window.removeEventListener('message', onmessage);
    } else {
      setTimeout(checkWindow, CHECK_TIMEOUT);
    }
  };

  checkWindow();

  return configWindow;
};
