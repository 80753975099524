import React, { FC, memo, ChangeEvent } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { BigidPrimaryCheckbox, BigidHeading6 } from '@bigid-ui/components';
import { MetadataSearchFilterComponentBaseProps } from '../../MetadataSearchFiltersTypes';

export type MetadataSearchFilterBooleanProps = MetadataSearchFilterComponentBaseProps;

const useStyles = makeStyles({
  root: {
    width: '100%',
  },
  control: {
    padding: '8px',
  },
});

export const MetadataSearchFilterBoolean: FC<MetadataSearchFilterBooleanProps> = memo(
  ({ dataAid, config, value, onFilterChange, isFiltersFetching }) => {
    const classes = useStyles();
    const { fieldType, fieldName, displayName } = config;
    const label = displayName || fieldName;

    const handleOnChange = (_event: ChangeEvent<HTMLInputElement>, checked: boolean): void => {
      onFilterChange({
        field: fieldName,
        value: checked,
        operator: 'equal',
        fieldType,
        fieldDisplayName: label,
      });
    };

    return (
      <div className={classes.root}>
        <div className={classes.control}>
          <BigidPrimaryCheckbox
            dataAid={dataAid}
            onChange={handleOnChange}
            size="medium"
            label={<BigidHeading6 data-aid={`${dataAid}-label`}>{label}</BigidHeading6>}
            checked={value?.value.toString() === 'true'}
            disabled={isFiltersFetching}
          />
        </div>
      </div>
    );
  },
);

MetadataSearchFilterBoolean.displayName = 'MetadataSearchFilterBoolean';
