import React, { FC, useState, useEffect, Dispatch } from 'react';
import classnames from 'classnames';
import makeStyles from '@mui/styles/makeStyles';
import { BigidLoader } from '@bigid-ui/components';
import { WorkflowResponse } from '../actionWorkflowTypes';
import { ActionWorkflowContentToolbar } from './ActionWorkflowContentToolbar';
import { ConfigureActionWorkflow } from './ConfigureActionWorkflow/ConfigureActionWorkflow';
import { ActionWorkflowEmpty } from './ActionWorkflowEmpty/ActionWorkflowEmpty';
import { ActionWorkflowCardsGrid } from './ActionWorkflowCardsGrid/ActionWorkflowCardsGrid';
import { OnPagingChanged, OnSortingChanged, OnFiltersChanged } from '@bigid-ui/grid';
import { queryService } from '../../../../services/queryService';
import { getConfigurations } from '../../ConfigurationManagement/configurationManagementService';

export const categoriesContainerWidth = '300px';

const useStyles = makeStyles({
  root: {
    width: `calc(100% - ${categoriesContainerWidth})`,
    height: '100%',
  },
  gridWrapper: {
    padding: '16px 8px 0 0',
    display: 'flex',
    overflow: 'hidden',
    maxHeight: '100%',
  },
  wrapper: ({ isEditMode }: { isEditMode: boolean }) => ({
    height: '100%',
    ...(!isEditMode && { paddingBottom: 10, display: 'flex', flexDirection: 'column' }),
  }),
  isHidden: { display: 'none' },
  loader: { height: '100%', width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' },
});

interface ActionWorkflowContentProps {
  workflows: WorkflowResponse[];
  totalWorkflows: number;
  selectedCategory: string;
  selectedSubCategory: string;
  onFiltersChanged: OnFiltersChanged;
  onUpdateWorkflows: () => void;
  setWorkflowToEdit: Dispatch<WorkflowResponse>;
  isCreateMode: boolean;
  workflowToEdit: WorkflowResponse;
  onCloseWorkflowForm: () => void;
  shouldUpdateCount: boolean;
  isLoadingWorkflows: boolean;
  skip: number;
  onPagingChanged: OnPagingChanged;
  onSortingChanged: OnSortingChanged;
  isLoading: boolean;
}

export const ActionWorkflowContent: FC<ActionWorkflowContentProps> = ({
  skip,
  workflows,
  totalWorkflows,
  selectedCategory,
  selectedSubCategory,
  onFiltersChanged,
  onUpdateWorkflows,
  setWorkflowToEdit,
  isCreateMode,
  workflowToEdit,
  onCloseWorkflowForm,
  shouldUpdateCount,
  isLoadingWorkflows,
  onPagingChanged,
  onSortingChanged,
}) => {
  const isEditMode = isCreateMode || !!workflowToEdit;
  const classes = useStyles({ isEditMode });
  const [isLoading, setIsLoading] = useState(false);
  const [shouldDisplayLoader, setShouldDisplayLoader] = useState(false);
  const [configurationsTotal, setConfigurationsTotal] = useState(0);

  const isEmptyState = !selectedCategory && !isEditMode;

  const getTotalConfigurations = async () => {
    setIsLoading(true);
    try {
      const query = queryService.getGridConfigQuery({ requireTotalCount: true });
      const { totalCount } = await getConfigurations(query);
      setConfigurationsTotal(totalCount);
    } catch (e) {
      console.error('error getting configurations count', e);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (isEditMode) {
      getTotalConfigurations();
    }
  }, [isEditMode]);

  useEffect(() => {
    setShouldDisplayLoader(true);
  }, [selectedCategory, selectedSubCategory]);

  useEffect(() => {
    !isLoadingWorkflows && setShouldDisplayLoader(false);
  }, [isLoadingWorkflows]);

  if (isEmptyState) {
    return <ActionWorkflowEmpty />;
  }

  return (
    <div className={classes.root}>
      {shouldDisplayLoader && (
        <div className={classes.loader}>
          <BigidLoader position="relative" label="Loading actions..." />
        </div>
      )}
      {!shouldDisplayLoader && (
        <div className={classnames(classes.wrapper, isLoading && classes.isHidden)}>
          <ActionWorkflowContentToolbar
            category={selectedCategory}
            subCategory={selectedSubCategory}
            totalWorkflows={totalWorkflows}
            onFiltersChanged={onFiltersChanged}
            workflowName={workflowToEdit?.name}
            isEditMode={isEditMode}
            shouldUpdateCount={shouldUpdateCount}
            isLoadingFilters={isLoading}
          />
          {!isEditMode && (
            <div className={classes.gridWrapper}>
              <ActionWorkflowCardsGrid
                skip={skip}
                rows={workflows}
                isLoading={isLoadingWorkflows}
                totalRowsCount={totalWorkflows}
                onPagingChanged={onPagingChanged}
                onSortingChanged={onSortingChanged}
                setWorkflowToEdit={setWorkflowToEdit}
                onDeleteWorkflowItem={onUpdateWorkflows}
                setIsLoading={setIsLoading}
              />
            </div>
          )}
          {isEditMode && !isLoading && (
            <ConfigureActionWorkflow
              initialWorkflow={workflowToEdit}
              onClose={onCloseWorkflowForm}
              isCreateMode={isCreateMode}
              onUpdateWorkflows={onUpdateWorkflows}
              shouldAddConfigurations={configurationsTotal === 0}
            />
          )}
        </div>
      )}
    </div>
  );
};
