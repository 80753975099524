import { $http } from 'ngimport';
import { businessGlossaryService } from '../../../../administration/generalSettings/businessGlossary/businessGlossary.service';
import { appSettings } from '../../../../common/services/app.common.services';
import { httpService, QueryParamsAndDataObject } from '../../../services/httpService';
import {
  SarProfile,
  SarProfileConnectionModel,
  SarProfilePutModel,
  SarProfilePutResponseModel,
  SarProfilesGetAllModel,
  SarFieldsSettings,
  SarFieldsSettingsPutBatchModel,
  SarFieldsSettingsBatchUpdate,
} from './ProfileSettingsTypes';
import { queryService } from '../../../services/queryService';
import { BigidFieldFilter } from '@bigid-ui/components';
import { BigidGridQueryComponents } from '@bigid-ui/grid';

type SarConfigItem = any;

interface ExcludeConnectionsOptions {
  excludeDsConnections?: boolean;
  excludeEsConnections?: boolean;
}

export interface UpdateProfileFieldsPayload {
  defaultFriendlyNameId?: string;
  customFriendlyNameId?: string;
}

export const sarConfigService = {
  getSarConfig: (profileId: string, bigidGridQuery: BigidGridQueryComponents) => {
    const gridConfigQuery = queryService.getGridConfigQuery({
      ...bigidGridQuery,
      sort: bigidGridQuery.sort?.length
        ? bigidGridQuery.sort
        : [
            { field: 'field_name_lower', order: 'asc' },
            { field: 'fully_qualified_name', order: 'asc' },
          ],
    });
    return httpService
      .fetch<{
        total: number;
        offset: number;
        sarFieldsConf: SarFieldsSettings[];
      }>(`sar/config?profileId=${profileId}&${gridConfigQuery}`)
      .then(({ data }) => {
        return {
          ...data,
          sarFieldsConf: data.sarFieldsConf,
        };
      });
  },
  getSarConfigItem: ({ profileId, fieldId }: { profileId: string; fieldId: string }) => {
    const gridConfigQuery = queryService.getGridConfigQuery({
      filter: [{ field: '_id', value: fieldId, operator: 'equals' as any }],
    });
    return httpService.fetch(`sar/config?profileId=${profileId}&${gridConfigQuery}`).then(response => response.data);
  },
  getSarConfigAsCSV: (profileId: string) => httpService.fetch(`sar/config/export`, { profileId }),
  importSarConfig: (profileId: string, file: File, params: QueryParamsAndDataObject) =>
    httpService.multipart('post', `sar/config/import`, {
      data: { profileId },
      files: { file },
      params,
    }),
  updateSarConfigItem: (id: string, item: SarConfigItem) =>
    $http.post(`${appSettings.serverPath}/${appSettings.version}/sar/config/${encodeURIComponent(id)}`, item),

  createGlossaryItem: (item: SarConfigItem) => businessGlossaryService.createGlossaryItem(item),

  getGlossaryItems: () => businessGlossaryService.getGlossaryItems(),

  getProfiles: () =>
    httpService.fetch<{ profiles: SarProfilesGetAllModel[] }>(`sar/profiles`).then(response => response.data.profiles),

  getProfileById: (
    id: string,
    bigidGridQuery: BigidGridQueryComponents,
    { excludeDsConnections = false, excludeEsConnections = false }: ExcludeConnectionsOptions = {},
  ) => {
    const gridConfigQuery = queryService.getGridConfigQuery(bigidGridQuery);
    const excludeDsFilter = excludeDsConnections ? `&excludeDsConnections=true` : '';
    const excludeEsFilter = excludeEsConnections ? `&excludeEsConnections=true` : '';
    return httpService
      .fetch<SarProfile>(
        `sar/profiles/${encodeURIComponent(id)}?${gridConfigQuery}${excludeEsFilter}${excludeDsFilter}`,
      )
      .then(response => response.data);
  },

  updateProfile: (id: string, data: SarProfilePutModel) =>
    httpService
      .put<SarProfilePutResponseModel, SarProfilePutModel>(`sar/profiles/${encodeURIComponent(id)}`, data)
      .then(response => response.data),

  addConnections: (id: string, data: SarProfileConnectionModel) =>
    httpService
      .post<SarProfilePutResponseModel, SarProfileConnectionModel>(
        `sar/profiles/${encodeURIComponent(id)}/connections`,
        data,
      )
      .then(response => response.data),

  updateProfileFields: (
    profileId: string,
    postData: {
      query: {
        filter?: BigidFieldFilter[];
      };
      data: SarFieldsSettingsPutBatchModel;
    },
  ) => httpService.post(`sar/profiles/${encodeURIComponent(profileId)}/fields`, postData),

  updateProfileFieldsV2: (
    profileId: string,
    payload: {
      query: {
        filter?: BigidFieldFilter[];
      };
      data: SarFieldsSettingsBatchUpdate;
    },
  ) => httpService.patch(`sar/profiles/${encodeURIComponent(profileId)}/fields`, payload),

  updateProfileField: (profileId: string, fieldId: string, payload: UpdateProfileFieldsPayload) =>
    httpService.patch(`sar/profiles/${encodeURIComponent(profileId)}/fields/${encodeURIComponent(fieldId)}`, payload),
};
