import React, { FC, memo, MutableRefObject, useCallback, useMemo } from 'react';
import {
  BigidFieldRenderProps,
  BigidForm,
  BigidFormField,
  BigidFormRenderProps,
  BigidFormValues,
  compareObjectsExceptFunctions,
  useExtraFieldControl,
  BigidTooltip,
  BigidHeading6,
} from '@bigid-ui/components';
import { useLocalTranslation } from '../translations';
import { ExtraFields } from './components/ExtraFields';
import { NerFields } from './components/NerFields';
import { createNewExtraField, getInitialFormData, ListItem } from './initialFormConfig';
import { ClassifierGridRow, NERPayload, RegexFormFields } from '../types/ClassifierTypes';
import { BigidHelpIcon } from '@bigid-ui/icons';
import { styled } from '@mui/material';

interface RenderFormProps extends BigidFormRenderProps {
  fields: BigidFormField[];
}

const NERFormHolder = styled('div')`
  fieldset {
    margin: 0 0 16px;
    width: 100%;
  }
`;

const Title = styled(BigidHeading6)`
  display: flex;
  align-items: center;
  span {
    line-height: 1;
    padding-left: 8px;
  }
`;

export const NerExtraFields: FC<BigidFieldRenderProps> = memo(
  ({ value, setValue, fieldProps, error, errorIsShown, misc, name, isValidated }) => {
    const createNew = useCallback(() => createNewExtraField(), []);
    const { onAddNew, onDelete, onChangeItem, data } = useExtraFieldControl({
      initialData: value?.length
        ? (value as ListItem[]).map(({ id, value }, index: number) => ({
            id: id ?? `${name}-${index}`,
            value,
          }))
        : [createNew()],
      createNewItem: createNew,
      onChange: newValue => {
        setValue(newValue);
      },
    });

    return (
      <ExtraFields
        fieldProps={fieldProps}
        data={data}
        onAddNew={onAddNew}
        fieldComponent={NerFields}
        onDelete={onDelete}
        onChangeItem={onChangeItem}
        error={error}
        errorIsShown={errorIsShown}
        isValidated={isValidated}
        addNewButtonVisible={misc?.addNewButtonVisible}
      />
    );
  },
  compareObjectsExceptFunctions,
);

interface RenderFormType extends RenderFormProps {
  isOOTB: boolean;
}

const RenderForm = ({ renderField, isOOTB }: RenderFormType) => {
  const { t } = useLocalTranslation('form');

  return (
    <NERFormHolder>
      {renderField(RegexFormFields.modelName)}
      {renderField('attribute')}
      {renderField(RegexFormFields.categories)}

      {isOOTB && renderField('description')}

      {!isOOTB && (
        <>
          <Title>
            {t('nerExtraFieldsLabel')}
            <BigidTooltip fontSize="medium" placement="right" title={t('tooltips.nerEntityMap')}>
              <span>
                <BigidHelpIcon />
              </span>
            </BigidTooltip>
          </Title>
          {renderField('extraFields')}
        </>
      )}
    </NERFormHolder>
  );
};

interface NERFormProps {
  formControlsRef: MutableRefObject<any>;
  initialData?: ClassifierGridRow;
  isEdit?: boolean;
  isOOTB?: boolean;
}

const NERForm = ({ initialData, formControlsRef, isEdit, isOOTB }: NERFormProps) => {
  const transformedInitialData = {
    ...initialData,
    attribute: {
      friendly_name: initialData?.friendly_name || '',
      glossary_id: initialData?.glossary_id || '',
    },
  };
  const { initialValues, fields } = useMemo(() => getInitialFormData(transformedInitialData, isEdit, isOOTB), []);

  // to avoid additional calls for attributes and categories when component rerender
  const memoizedRenderForm = useCallback(
    (props: BigidFormRenderProps) => <RenderForm {...props} fields={fields} isOOTB={isOOTB} />,
    [],
  );

  return (
    <BigidForm
      initialValues={initialValues}
      fields={fields}
      renderForm={memoizedRenderForm}
      controlButtons={false}
      stateAndHandlersRef={formControlsRef}
    />
  );
};

const getNERFormSubmitFields = (values: BigidFormValues): NERPayload[] => {
  const { modelName, extraFields } = values;

  return (extraFields as ListItem[]).map(fields => ({
    nerEntityMap: fields.value?.nerEntityMap,
    classifierName: fields.value?.[RegexFormFields.classifierName],
    modelName,
  }));
};

export { NERForm, getNERFormSubmitFields };
