import React, { FC } from 'react';
import { BigidDialog, BigidLoader, PrimaryButton, TertiaryButton, StyledButtonType } from '@bigid-ui/components';
import { styled } from '@mui/material';
import { docsUrls } from '../../../../config/publicUrls';
import { useLocalTranslation } from '../../translations';
import { BigidExternalLinkIcon } from '@bigid-ui/icons';
import { CLASSIFIER_TYPES } from '../../types/ClassifierTypes';
import { analyticsService } from '../../../../services/analyticsService';
import { ClassifiersEventsEnum } from '../../types/Events';

export interface CreateClassifierDialogProps {
  title: string;
  isOpen: boolean;
  onSave: () => void;
  onClose: () => void;
  children: React.ReactNode;
  isLoading?: boolean;
  classifierType: CLASSIFIER_TYPES.CONTENT | CLASSIFIER_TYPES.NER;
}

const IconBox = styled('span')`
  padding-left: 4px;
  line-height: 1;
  height: 20px;
`;

const DocumentButton: FC<StyledButtonType> = props => {
  return (
    <TertiaryButton
      {...props}
      endIcon={
        <IconBox>
          <BigidExternalLinkIcon />
        </IconBox>
      }
    />
  );
};

export const CreateClassifierDialog = ({
  title,
  isOpen,
  onSave,
  onClose,
  children,
  isLoading,
  classifierType,
}: CreateClassifierDialogProps) => {
  const { t } = useLocalTranslation('createClassifierDialog');

  const handleOpenDocumentation = () => {
    const eventName =
      classifierType === CLASSIFIER_TYPES.CONTENT
        ? ClassifiersEventsEnum.REGEX_VIEW_DOCUMENTATION
        : ClassifiersEventsEnum.NER_VIEW_DOCUMENTATION;
    analyticsService.trackManualEvent(eventName);

    const url =
      classifierType === CLASSIFIER_TYPES.CONTENT ? docsUrls.REGEX_CLASSIFIERS_DOC : docsUrls.NER_CLASSIFIERS_DOC;
    window.open(url, '_blank');
  };

  return (
    <BigidDialog
      isOpen={isOpen}
      onClose={onClose}
      title={title}
      fixedHeight={600}
      borderBottom
      buttons={[
        { component: DocumentButton, onClick: handleOpenDocumentation, text: t('viewDoc'), alignment: 'left' },
        { component: TertiaryButton, onClick: onClose, text: t('close') },
        { component: PrimaryButton, onClick: onSave, text: t('save') },
      ]}
    >
      {isLoading && <BigidLoader />}
      {children}
    </BigidDialog>
  );
};
