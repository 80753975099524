import '../../react/views/DSAR/SarProfileSettings/SarScansDialog/SarScansDialog';
import './completedSubjectRequests.component.scss';
import template from './completedSubjectRequests.component.html';
import { isEqual, noop } from 'lodash';
import { module } from 'angular';
import { openSearchAttributesInfoDialog } from '../../react/components/SearchAttributesInfo/searchAttributesInfoService';
import { isPermitted } from '../../react/services/userPermissionsService';
import { DSAR_PERMISSIONS } from '@bigid/permissions';
const app = module('app');

const getCell = (link = '') => `<div class="ui-grid-cell-contents ">
    ${link}
    <span title=""
    tooltip-class="bigid-uib-tooltip-styles"
    tooltip-placement="center top"
    tooltip-append-to-body="true"
    uib-tooltip="{{COL_FIELD}}"
  >{{COL_FIELD}}</span>
</div>`;

function CompletedSubjectRequestsComponent($state, $scope, downloadReportService) {
  'ngInject';

  /** @type UiGridScrollbarContext */
  this.uiGridScrollContext = undefined;

  this.jitScansInterval = undefined;

  this.$onInit = () => {
    this.loading = false;
    this.showGrid = false;

    this.hasDownloadPermission = isPermitted(DSAR_PERMISSIONS.DOWNLOAD_REQUEST_REPORT.name);
    this.hasPersonalInfoReportPermission = isPermitted(DSAR_PERMISSIONS.READ_PERSONAL_INFO.name);
    this.hasDeletePermission = isPermitted(DSAR_PERMISSIONS.DELETE_REQUEST.name);

    this.initExistingSubjectAccessRequestModel();

    this.grid.onRegisterApi = gridApi => {
      this.gridApi = gridApi;

      this.gridApi.core.on.filterChanged(null, () => {
        if (this.uiGridScrollContext) {
          this.uiGridScrollContext.scrollToTop();
        }
      });
    };
  };

  const updateJitResults = () => {
    this.onScanrefreshdate();

    this.showGrid = true;
    if (this.jitScanResults) {
      this.grid.data = this.jitScanResults.map(item => {
        if (this.profiles && item.profile_name) {
          item.profileNameToDisplay = item.profile_name;
          const profile = this.profiles.find(profile => profile._id === item.profile_id);
          item.profileNameToDisplay = profile ? item.profile_name : '';
        }
        return item;
      });
    }
  };

  this.$onChanges = changesObj => {
    const { jitScanResults, profiles } = changesObj;
    if (
      (jitScanResults && !isEqual(jitScanResults.previousValue, jitScanResults.currentValue)) ||
      (profiles && !isEqual(profiles.previousValue, profiles.currentValue))
    ) {
      updateJitResults();
    }
  };

  this.onGridScrollContextReady = context => {
    this.uiGridScrollContext = context;
  };

  this.isGridDisabled = () => {
    return this.loading || !Array.isArray(this.grid.data) || !this.grid.data.length;
  };

  this.initExistingSubjectAccessRequestModel = () => {
    this.grid = {
      enableSorting: true,
      enablePaginationControls: false,
      enableFiltering: true,
      columnDefs: [
        {
          name: 'Display name',
          field: 'identity_display_name',
          width: '15%',
          filterHeaderTemplate: `<ui-grid-bigid-text-filter/>`,
          cellTemplate: getCell(),
        },
        {
          name: 'Unique Id',
          displayName: 'Unique ID',
          field: 'identity_unique_id',
          filterHeaderTemplate: `<ui-grid-bigid-text-filter/>`,
          cellTemplate: getCell(),
        },
        {
          name: 'Profile',
          displayName: 'Profile',
          field: 'profileNameToDisplay',
          maxWidth: 170,
          filterHeaderTemplate: `<ui-grid-bigid-text-filter/>`,
          cellTemplate: getCell(),
        },
        {
          name: 'Request Id',
          displayName: 'Request ID',
          field: 'request_id',
          maxWidth: 200,
          width: 200,
          filterHeaderTemplate: `<ui-grid-bigid-text-filter/>`,
          cellTemplate: getCell(
            `<a href ng-click="grid.appScope.$ctrl.openSearchAttributesInfo(row.entity.identity_display_name ,row.entity.request_id)">`,
          ),
        },
        {
          name: 'Submit Date',
          field: 'created_at',
          filterHeaderTemplate: `<ui-grid-bigid-text-filter/>`,
          cellTemplate: getCell(),
          maxWidth: 200,
        },
        {
          name: 'Progress',
          field: 'statuses_str',
          filterHeaderTemplate: `<ui-grid-bigid-text-filter/>`,
          minWidth: 100,
          cellTemplate: getCell(`<a href ng-click="grid.appScope.$ctrl.openSarScansDialog(row.entity)">`),
        },
        {
          name: 'Expires on',
          field: 'expires_on',
          filterHeaderTemplate: `<ui-grid-bigid-text-filter/>`,
          cellTemplate: getCell(),
          maxWidth: 200,
        },
        {
          name: 'Records',
          field: 'record_count',
          width: 90,
          enableColumnMenu: false,
          cellClass: 'ui-grid-cell--align-center',
          filterHeaderTemplate: `<ui-grid-bigid-text-filter/>`,
          cellTemplate: getCell(),
        },
        {
          name: 'Actions',
          enableSorting: false,
          enableCellEdit: false,
          enableFiltering: false,
          enableColumnMenu: false,
          width: '14%',
          maxWidth: 140,
          cellTemplate: `
            <div class="actions-container">
              <div class="three-points"></div>
              <div class="img-container">
                <div class="img-button img-download-button"
                     ng-click="grid.appScope.$ctrl.download(row.entity)"
                     ng-if="!row.entity.data_expired && grid.appScope.$ctrl.hasDownloadPermission"
                     tooltip-class="bigid-uib-tooltip-styles"
                     tooltip-placement="center top"
                     tooltip-append-to-body="true"
                     uib-tooltip="Download"></div>
                <div class="img-button img-delete-button"
                     ng-if="grid.appScope.$ctrl.hasDeletePermission"
                     ng-click="grid.appScope.$ctrl.deleteScanItem({entity: row.entity})"
                     tooltip-class="bigid-uib-tooltip-styles"
                     tooltip-placement="center top"
                     tooltip-append-to-body="true"
                     uib-tooltip="Delete"></div>
                <div class="img-button img-link-button"
                     ng-click="grid.appScope.$ctrl.createPersonalInfoReport(row.entity)"
                     ng-if="!row.entity.data_expired && (row.entity.is_report_ready === null || row.entity.is_report_ready) && grid.appScope.$ctrl.hasPersonalInfoReportPermission"
                     tooltip-class="bigid-uib-tooltip-styles"
                     tooltip-placement="center top"
                     tooltip-append-to-body="true"
                     uib-tooltip="{{'SUMMARY_REPORT'|translate}}"></div>
              </div>
            </div>`,
        },
      ],
    };
  };

  this.download = entity => {
    this.loading = true;
    downloadReportService.downLoadReport(entity.request_id).finally(() => (this.loading = false));
  };

  this.closeSarScansDialog = () => {
    $scope.$evalAsync(() => {
      this.sarScansDialog = { autoRefresh: false, isOpen: false, onClose: noop, parentScanId: null };
    });
  };

  this.openSarScansDialog = scanObject => {
    this.sarScansDialog = {
      autoRefresh: false,
      isOpen: true,
      parentScanId: scanObject.request_id,
      onClose: () => {
        this.closeSarScansDialog();
      },
      displayName: scanObject.identity_display_name,
    };
  };

  this.createPersonalInfoReport = entity => {
    $state.go('personalInfoReport', {
      userId: entity.request_id,
      targetTab: 'completed-subject-requests',
      profileId: entity.profile_id,
      profileName: entity.profile_name,
    });
  };

  this.openSearchAttributesInfo = (name, requestId) => {
    openSearchAttributesInfoDialog(name, requestId);
  };
}

app.component('completedSubjectRequests', {
  template,
  controller: CompletedSubjectRequestsComponent,
  bindings: {
    onScanrefreshdate: '&',
    profiles: '<',
    deleteScanItem: '&',
    jitScanResults: '<',
  },
});
