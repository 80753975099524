import React, { FC } from 'react';
import { Grid } from '@mui/material';
import { MainWidgets, UserWidgets } from './ACIDashboardWrapper';
import { ACIDashboardHotspots } from './components/ACIDashboardHotspots/ACIDashboardHotspots';
import { BigidDataSourceIcon, BigidGlobeIcon, BigidUserIcon } from '@bigid-ui/icons';
import { makeRowsWithActions, makeTitleActions } from './actions';
import { InteractiveDashboardListWidget } from './InteractiveDashboardListWidget';
import { InteractiveDashboardBarChartWidget } from './InteractiveDashboardBarChartWidget';
import { InteractiveDashboardCard } from './InteractiveDashboardCard';
import { ACIDashboardAccessPoliciesTriggered } from './ACIDashboardAccessPoliciesTriggered';
import { getApplicationPreference } from '../../../services/appPreferencesService';
import { isArray } from 'lodash';
import { InteractiveDashboardLoadingData } from './InteractiveDashboardLoadingData';
import { ACIDashboardObjectsFoundByAccessPolicies } from './ACIDashboardObjectsFoundByAccessPolicies';

export interface ACIDashboardWithComponentsProps {
  mainWidgets: MainWidgets;
  userWidgets: UserWidgets;
  selectedDatasources: string[];
  availableDatasources: string[];
}

const SPACING = 2;

export const ACIDashboard: FC<ACIDashboardWithComponentsProps> = ({
  mainWidgets,
  userWidgets,
  selectedDatasources,
  availableDatasources,
}) => {
  const isHotspotsEnabled: boolean = getApplicationPreference('ENABLE_HOTSPOTS_REPORT');
  // const isActionableInsightsEnabled: boolean = getApplicationPreference('ACTIONABLE_INSIGHTS_ENABLED');

  return (
    <Grid container spacing={SPACING}>
      <Grid item container spacing={SPACING}>
        <Grid container item xs={12} md={12} lg={12} spacing={SPACING} flexWrap={'wrap'} alignContent={'baseline'}>
          <Grid item xs={6} md={6} lg={6} data-aid={'policiesTriggered'}>
            <ACIDashboardAccessPoliciesTriggered
              triggeredAccessPolicies={mainWidgets.policiesTriggered.triggeredAccessPolicies}
              totalObjectsFoundByAccessPolicies={mainWidgets.totalPoliciesWithAccessType}
            />
          </Grid>
          <Grid item xs={6} md={6} lg={6} data-aid={'objectsFoundByAccessPolicies'}>
            <ACIDashboardObjectsFoundByAccessPolicies
              selectedDatasources={selectedDatasources}
              totalObjectsFoundByAccessPolicies={mainWidgets.policiesTriggered.totalObjectsFoundByAccessPolicies}
              triggeredAccessPolicies={mainWidgets.policiesTriggered.triggeredAccessPolicies}
            />
          </Grid>
        </Grid>
        {/*{isActionableInsightsEnabled && (*/}
        {/*  <Grid item xs={12} md={12} lg={6} data-aid={'topCasesByAccessType'}>*/}
        {/*    <InteractiveDashboardBarChartWidget*/}
        {/*      title={'Top Cases By Access Type'}*/}
        {/*      titleActions={makeTitleActions.topCasesByAccessType()}*/}
        {/*      rowsWithActions={makeRowsWithActions.topCasesByAccessType(*/}
        {/*        datasources,*/}
        {/*        dashboardData.topCasesByAccessType,*/}
        {/*      )}*/}
        {/*    />*/}
        {/*  </Grid>*/}
        {/*)}*/}
      </Grid>

      <Grid item container spacing={SPACING}>
        <Grid item xs={12} md={12} lg={4} data-aid={'topExternalDomains'}>
          {!isArray(userWidgets?.topExternalDomains) && <InteractiveDashboardLoadingData title={'External Domains'} />}
          {isArray(userWidgets?.topExternalDomains) && (
            <InteractiveDashboardListWidget
              title={'External Domains'}
              titleActions={[]}
              rowsWithActions={makeRowsWithActions.topExternalDomains(userWidgets.topExternalDomains)}
              icon={BigidGlobeIcon}
            />
          )}
        </Grid>
        <Grid item xs={12} md={12} lg={4} data-aid={'topUsersFileAccess'}>
          {!isArray(userWidgets?.topUsersAccessWithFileCount) && (
            <InteractiveDashboardLoadingData title={'Accessible Files By Users'} />
          )}
          {isArray(userWidgets?.topUsersAccessWithFileCount) && (
            <InteractiveDashboardListWidget
              title={'Accessible Files By Users'}
              rowsWithActions={makeRowsWithActions.topUsersAccessWithFileCount(
                selectedDatasources,
                userWidgets.topUsersAccessWithFileCount,
              )}
              titleActions={makeTitleActions.topUsersAccessWithFileCount(
                selectedDatasources,
                userWidgets.topUsersAccessWithFileCount,
              )}
              icon={BigidUserIcon}
            />
          )}
        </Grid>
        <Grid item xs={12} md={12} lg={4} data-aid={'topExternalUsers'}>
          {!isArray(userWidgets?.topExternalUsers) && <InteractiveDashboardLoadingData title={'External Users'} />}
          {isArray(userWidgets?.topExternalUsers) && (
            <InteractiveDashboardListWidget
              title={'External Users'}
              titleActions={[]}
              rowsWithActions={makeRowsWithActions.topExternalUsers(selectedDatasources, userWidgets.topExternalUsers)}
              icon={BigidUserIcon}
            />
          )}
        </Grid>
      </Grid>

      {isHotspotsEnabled && (
        <Grid item xs={12} md={12} lg={12} data-aid={'dashboard-hotspots'}>
          <InteractiveDashboardCard>
            <ACIDashboardHotspots
              title={'Hotspots'}
              selectedDatasources={selectedDatasources}
              availableDatasources={availableDatasources}
              height={400}
            />
          </InteractiveDashboardCard>
        </Grid>
      )}
      <Grid item xs={12} md={12} lg={6} data-aid={'topAccessTypesByFileCount'}>
        {!isArray(mainWidgets.topAccessTypesByFileCount) && (
          <InteractiveDashboardLoadingData title={'Files by Access Type'} />
        )}
        {isArray(mainWidgets.topAccessTypesByFileCount) && (
          <InteractiveDashboardBarChartWidget
            title={'Files by Access Type'}
            titleActions={makeTitleActions.topAccessTypesByFileCount(
              selectedDatasources,
              mainWidgets.topAccessTypesByFileCount,
            )}
            rowsWithActions={makeRowsWithActions.topAccessTypesByFileCount(
              selectedDatasources,
              mainWidgets.topAccessTypesByFileCount,
            )}
          />
        )}
      </Grid>
      <Grid item xs={12} md={12} lg={6} data-aid={'topDataSources'}>
        {mainWidgets.topDataSources === 'IN_PROGRESS' && <InteractiveDashboardLoadingData title={'Data Sources'} />}
        {mainWidgets.topDataSources !== 'IN_PROGRESS' && (
          <InteractiveDashboardListWidget
            title={'Data Sources'}
            rowsWithActions={makeRowsWithActions.topDataSources(mainWidgets.topDataSources)}
            titleActions={makeTitleActions.topDataSources(selectedDatasources, mainWidgets.topDataSources)}
            icon={BigidDataSourceIcon}
          />
        )}
      </Grid>
    </Grid>
  );
};
