import { ClassificationLevel, SensitivityClassificationData } from './SensitivityClassification';
import { v4 as uuid } from 'uuid';
import { PriorityChange } from './SensitivityClassificationForm';
import { QueryLogicalOperatorMap, QueryMathOperatorMap } from '@bigid-ui/components';
import { QueryType } from './QuerySelector';

export const changeLevel = (
  currentClassificationLevel: ClassificationLevel,
  classificationLevels: ClassificationLevel[],
) => {
  return classificationLevels.map(classification => {
    if (classification.id === currentClassificationLevel.id) {
      return currentClassificationLevel;
    } else {
      return classification;
    }
  });
};

export const deleteLevel = (
  currentClassificationLevel: ClassificationLevel,
  classificationLevels: ClassificationLevel[],
) => {
  return classificationLevels
    .filter(classification => classification.priority !== currentClassificationLevel.priority)
    .map(classification => {
      if (classification.priority > currentClassificationLevel.priority) {
        return { ...classification, priority: classification.priority - 1 };
      } else {
        return classification;
      }
    });
};

export const changeLevelPriority = (
  currentPriority: number,
  direction: PriorityChange,
  classificationLevels: ClassificationLevel[],
) => {
  const change = direction === PriorityChange.Increase ? -1 : PriorityChange.Decrease ? 1 : 0;
  return swapClassificationLevelsPriority(currentPriority, currentPriority + change, classificationLevels);
};

export const duplicateLevel = (
  currentClassificationLevel: ClassificationLevel,
  classificationLevels: ClassificationLevel[],
) => {
  const indexToInsert = classificationLevels.findIndex(
    classification => classification.id === currentClassificationLevel.id,
  );
  return [
    ...classificationLevels.slice(0, indexToInsert + 1),
    {
      ...currentClassificationLevel,
      name: currentClassificationLevel.name ? `${currentClassificationLevel.name}_copy` : '',
      priority: currentClassificationLevel.priority + 1,
      id: uuid(),
    },
    ...classificationLevels
      .slice(indexToInsert + 1)
      .map(classification => ({ ...classification, priority: classification.priority + 1 })),
  ];
};

export const createNewLevel = (classificationLevels: ClassificationLevel[]) => {
  return [
    ...classificationLevels,
    {
      id: uuid(),
      name: '',
      queryObject: createEmptyQueryObject(),
      queryString: '',
      queryType: QueryType.Object,
      priority: classificationLevels.length,
      isQueryTouched: false,
    },
  ];
};

export const compareClassificationLevels = (
  { priority: a }: ClassificationLevel,
  { priority: b }: ClassificationLevel,
) => {
  return a - b;
};

export const swapClassificationLevelsPriority = (
  first: number,
  second: number,
  classificationLevels: ClassificationLevel[],
) => {
  return classificationLevels
    .map(classificationLevel => {
      if (classificationLevel.priority === first) {
        return { ...classificationLevel, priority: second };
      } else if (classificationLevel.priority === second) {
        return { ...classificationLevel, priority: first };
      } else {
        return classificationLevel;
      }
    })
    .sort(compareClassificationLevels);
};

export const createEmptyQueryObject = () => ({
  id: '0',
  operator: QueryLogicalOperatorMap.AND,
  rules: [
    {
      id: '0-1',
      leftOperand: 'attribute',
      operator: QueryMathOperatorMap.EQUAL,
      parentId: '0',
      rightOperand: '',
    },
  ],
});

export const createEmptySc = (): SensitivityClassificationData => ({
  name: '',
  description: '',
  classifications: [
    {
      id: uuid(),
      priority: 0,
      name: '',
      queryObject: {
        id: '0',
        operator: QueryLogicalOperatorMap.AND,
        rules: [
          {
            id: '0-1',
            leftOperand: 'attribute',
            operator: QueryMathOperatorMap.EQUAL,
            parentId: '0',
            rightOperand: '',
          },
        ],
      },
      queryType: QueryType.Object,
      queryString: '',
      isQueryTouched: false,
    },
  ],
});

const SC_TAG_PREFIX = 'system.sensitivityClassification.';
const TEMP_SC_TAG_PREFIX = `T.`;
const MAX_SC_CONFIG_NAME_LEN = 126 - TEMP_SC_TAG_PREFIX.length - SC_TAG_PREFIX.length;

export const isNameValid = (str: string): boolean => {
  const regex = new RegExp(`^[A-Za-z\\d](?:[A-Za-z\\d_\\.:\\-\\t ]{0,${MAX_SC_CONFIG_NAME_LEN}}[A-Za-z\\d_\\.:\\-])?$`);
  return str?.length <= MAX_SC_CONFIG_NAME_LEN && regex.test(str);
};
