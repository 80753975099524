import React, { FC, Dispatch, useMemo } from 'react';
import { BigidGrid, BigidGridColumn, BigidGridColumnTypes, OnPagingChanged, OnSortingChanged } from '@bigid-ui/grid';
import { ACCategory, ACEventType, Workflow, WorkflowResponse } from '../../actionWorkflowTypes';
import { BigidCardListItemValue, BigidCardListItemVariantEnum } from '@bigid-ui/components';
import { ActionCenterMetadataContainer } from '../../../useActionCenterMetadata';
import { dateTimeService } from '@bigid-ui/i18n';
import { ActionWorkflowCollapseContent } from './ActionWorkflowCollapseContent';
import { deleteWorkflow } from '../../actionWorkflowService';
import { notificationService } from '../../../../../services/notificationService';
import { showConfirmationDialog } from '../../../../../services/confirmationDialogService';
import { isPermitted } from '../../../../../services/userPermissionsService';
import { ACTION_CENTER_PERMISSIONS } from '@bigid/permissions';

interface ActionWorkflowCardsGridProps {
  selectedCategory?: ACCategory;
  selectedSubCategory?: ACEventType;
  onDeleteWorkflowItem?: () => void;
  setIsLoading?: Dispatch<boolean>;
  setWorkflowToEdit?: Dispatch<WorkflowResponse>;
  rows: WorkflowResponse[];
  isLoading: boolean;
  totalRowsCount: number;
  skip: number;
  onPagingChanged: OnPagingChanged;
  onSortingChanged: OnSortingChanged;
}

interface OnDeleteWorkflow {
  workflow: WorkflowResponse;
  onDelete: () => void;
  setIsLoading: Dispatch<boolean>;
}

const onDeleteWorkflow =
  ({ workflow, onDelete, setIsLoading }: OnDeleteWorkflow) =>
  async () => {
    try {
      const shouldDeleteApp = await showConfirmationDialog({
        entityNameSingular: workflow.name,
        customDescription: `Are you sure you want to delete ${workflow.name}?`,
      });
      setIsLoading(true);
      if (shouldDeleteApp) {
        await deleteWorkflow(workflow.id);
        await onDelete();
        notificationService.success(`${workflow.name} was deleted successfully`);
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.error(error);
      notificationService.error(`There was a problem deleting ${workflow.name}`);
    }
  };

export const ActionWorkflowCardsGrid: FC<ActionWorkflowCardsGridProps> = ({
  skip,
  rows,
  isLoading,
  totalRowsCount,
  onPagingChanged,
  onSortingChanged,
  onDeleteWorkflowItem,
  setIsLoading,
  setWorkflowToEdit,
}) => {
  const { actionCenterMetadata } = ActionCenterMetadataContainer.useContainer();
  const isDeleteWorkflowPermitted = isPermitted(ACTION_CENTER_PERMISSIONS.DELETE_ACTIONS.name);
  const isEditWorkflowPermitted = isPermitted(ACTION_CENTER_PERMISSIONS.EDIT_ACTIONS.name);
  const columns: BigidGridColumn<WorkflowResponse>[] = useMemo(
    () => [
      {
        title: 'Results',
        name: 'results',
        width: 'auto',
        resizingMode: 'nextColumn',
        isListColumn: false,
        disableTooltip: true,
        noHeader: true,
        isNotDraggable: true,
        getCellValue: (workflow: WorkflowResponse) => {
          const { eventToEntityMap, entities } = actionCenterMetadata.events.find(({ supportedEvents }) =>
            supportedEvents.includes(workflow.event),
          );
          const { displayName: entityName } = entities.find(({ name }) => name === eventToEntityMap[workflow.event]);

          const { name, receivers, created_at } = workflow;
          const title: BigidCardListItemValue = {
            value: name,
          };
          const date = { value: dateTimeService.formatDate(created_at) };
          const collapseContent = <ActionWorkflowCollapseContent entityName={entityName} {...workflow} />;
          const assetsCounters = [
            { name: entityName, total: workflow.entityIds.length },
            { name: 'Owners', total: receivers.length },
          ];

          return {
            card: {
              ...(isEditWorkflowPermitted && { onEdit: () => setWorkflowToEdit(workflow) }),
              ...(isDeleteWorkflowPermitted && {
                onDelete: onDeleteWorkflow({ workflow, onDelete: onDeleteWorkflowItem, setIsLoading }),
              }),
              title,
              date,
              collapseContent,
              assetsCounters,
              hasPreview: false,
              variant: BigidCardListItemVariantEnum.expanded,
            },
          };
        },
        type: BigidGridColumnTypes.CARD,
      },
    ],
    [
      actionCenterMetadata.events,
      isDeleteWorkflowPermitted,
      isEditWorkflowPermitted,
      onDeleteWorkflowItem,
      setIsLoading,
      setWorkflowToEdit,
    ],
  );

  const gridCardListConfig = useMemo(
    () => ({
      key: '_id',
      pageSize: 50,
      rows: rows,
      loading: isLoading,
      totalRowsCount: totalRowsCount,
      skip: skip,
      columns: columns,
      showSortingControls: false,
      showFilteringControls: false,
      onSortingChanged: onSortingChanged,
      onPagingChanged: onPagingChanged,
      cardListMode: true,
      onRowClick: undefined,
    }),
    [columns, isLoading, onPagingChanged, onSortingChanged, rows, skip, totalRowsCount],
  );

  return <BigidGrid {...gridCardListConfig} />;
};
