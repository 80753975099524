import { BrandItemResponse } from './brandsTypes';
import { httpService } from '../../../services/httpService';

const BRANDS_ENDPOINT_PREFIX = 'brands';

export const getBrands = async (): Promise<BrandItemResponse[]> => {
  try {
    const {
      data: { data },
    } = await httpService.fetch<{ data: BrandItemResponse[] }>(BRANDS_ENDPOINT_PREFIX);
    return data;
  } catch (e) {
    console.error('Failed to fetch brands', e);
    throw new Error(e?.message);
  }
};

export const updateBrand = async (id: string | number, body: Partial<BrandItemResponse>): Promise<any> => {
  return httpService.put(`${BRANDS_ENDPOINT_PREFIX}/${encodeURIComponent(id)}`, body);
};

export const createBrand = async (body: Partial<BrandItemResponse>): Promise<any> => {
  return httpService.post(BRANDS_ENDPOINT_PREFIX, body);
};

export const deleteBrand = async (id: string): Promise<void> => {
  httpService.delete(`${BRANDS_ENDPOINT_PREFIX}/${encodeURIComponent(id)}`);
};
