import React, { ReactNode } from 'react';
import {
  BigidChip,
  BigidColorsV2,
  BigidConfidenceIndicator,
  BigidConfidenceLevel,
  BigidFieldFilter,
  BigidTooltip,
} from '@bigid-ui/components';
import { BigidGridColumn, BigidGridColumnTypes } from '@bigid-ui/grid';
import { CuratedFieldRecord } from './useCurationFieldsConfig';
import { getApplicationPreference } from '../../../services/appPreferencesService';
import { CuratedFieldsFieldNameCell } from './CuratedFieldsFieldNameCell';
import { getFixedT } from '../translations';
import { generateDataAid } from '@bigid-ui/utils';
import { getDetailedObjectTypeLabel, getPercentageValueFormatted } from '../curationUtils';
import { BigidAiIcon } from '@bigid-ui/icons';

export enum CuratedFieldsVariant {
  CURATED_FIELDS,
  CLASSIFIER_FINDINGS,
  CLASSIFIER_PREDICTIONS,
}

const columnsOrderPerVariantMap: Record<CuratedFieldsVariant, string[]> = {
  [CuratedFieldsVariant.CURATED_FIELDS]: [
    'fieldName',
    'objectName',
    'container',
    'source',
    'confidenceLevel',
    'updatedConfidenceLevel',
    'note',
    'noteCreatedBy',
    'noteUpdatedBy',
    'objectType',
    'sample1',
    'sample2',
    'totalFindings',
    'curatedByUser',
    'curatedAt',
  ],
  [CuratedFieldsVariant.CLASSIFIER_FINDINGS]: [
    'fieldName',
    'objectName',
    'container',
    'source',
    'confidenceLevel',
    'updatedConfidenceLevel',
    'note',
    'noteCreatedBy',
    'noteUpdatedBy',
    'objectType',
    'sample1',
    'sample2',
    'totalFindings',
    'curatedByUser',
    'curatedAt',
  ],
  [CuratedFieldsVariant.CLASSIFIER_PREDICTIONS]: [
    'fieldName',
    'objectName',
    'container',
    'source',
    'estimatedConfidenceLevel',
    'confidenceLevel',
    'updatedConfidenceLevel',
    'note',
    'noteCreatedBy',
    'noteUpdatedBy',
    'objectType',
    'sample1',
    'sample2',
    'totalFindings',
    'curatedByUser',
    'curatedAt',
  ],
};

function getColumnsOrderedByVariant(
  variant: CuratedFieldsVariant,
  columns: BigidGridColumn<CuratedFieldRecord>[],
): BigidGridColumn<CuratedFieldRecord>[] {
  return columnsOrderPerVariantMap[variant].reduce((orderedColumns, columnName) => {
    const column = columns.find(({ name }) => name === columnName);

    if (column) {
      return [...orderedColumns, column];
    } else {
      return orderedColumns;
    }
  }, []);
}

export function getColumnsConfigByVariant(
  variant: CuratedFieldsVariant,
  shouldShowSourceColumn?: boolean,
): BigidGridColumn<CuratedFieldRecord>[] {
  const t = getFixedT('CuratedFields.CurationFieldsConfig.columns');
  const columnsPerVariant: BigidGridColumn<CuratedFieldRecord>[] = [];
  const sampleColumns: BigidGridColumn<CuratedFieldRecord>[] = !getApplicationPreference('DATA_PREVIEW_DISABLED')
    ? [
        {
          title: t('sample1.name'),
          name: 'sample1',
          type: BigidGridColumnTypes.TEXT,
          isTooltipFollowCursor: true,
          getCellValue: ({ sample1 }) => sample1,
        },
        {
          title: t('sample2.name'),
          name: 'sample2',
          type: BigidGridColumnTypes.TEXT,
          isTooltipFollowCursor: true,
          getCellValue: ({ sample2 }) => sample2,
        },
      ]
    : [];

  if (shouldShowSourceColumn) {
    columnsPerVariant.push({
      title: t('source.name'),
      name: 'source',
      type: BigidGridColumnTypes.TEXT,
      isTooltipFollowCursor: true,
      getCellValue: ({ source }) => source,
    });
  }

  if (variant === CuratedFieldsVariant.CLASSIFIER_PREDICTIONS) {
    columnsPerVariant.push({
      title: t('estimatedConfidenceLevel.name'),
      name: 'estimatedConfidenceLevel',
      type: BigidGridColumnTypes.CHIP,
      tooltip: {
        icon: BigidAiIcon,
        value: t('estimatedConfidenceLevel.tooltip') as ReactNode,
      },
      getCellValue: ({ estimatedConfidenceLevel }) => {
        return estimatedConfidenceLevel
          ? {
              chip: {
                icon: (
                  <BigidConfidenceIndicator level={estimatedConfidenceLevel.toLowerCase() as BigidConfidenceLevel} />
                ),
                size: 'small',
                label: estimatedConfidenceLevel,
                bgColor: BigidColorsV2.white,
                shadow: true,
              },
            }
          : null;
      },
    });
  }

  const columns: BigidGridColumn<CuratedFieldRecord>[] = [
    {
      title: t('fieldName.name'),
      name: 'fieldName',
      isListColumn: true,
      type: BigidGridColumnTypes.CUSTOM,
      getCellValue: record => (
        <CuratedFieldsFieldNameCell {...record} dataAid={generateDataAid('CuratedFieldsFieldNameCell', [record.id])} />
      ),
    },
    {
      title: t('objectName.name'),
      name: 'objectName',
      type: BigidGridColumnTypes.TEXT,
      isTooltipFollowCursor: true,
      getCellValue: ({ objectName }) => objectName,
    },
    {
      title: t('containerName.name'),
      name: 'container',
      type: BigidGridColumnTypes.TEXT,
      isTooltipFollowCursor: true,
      getCellValue: ({ container }) => `${container}`,
    },
    {
      title: t('confidenceLevel.name'),
      name: 'confidenceLevel',
      type: BigidGridColumnTypes.CUSTOM,
      getCellValue: ({ confidenceLevel, confidenceValue }) => {
        return confidenceLevel && !isNaN(confidenceValue) ? (
          <BigidChip
            icon={<BigidConfidenceIndicator level={confidenceLevel.toLowerCase() as BigidConfidenceLevel} />}
            label={`${confidenceLevel} (${getPercentageValueFormatted(confidenceValue)})`}
            bgColor={BigidColorsV2.white}
            size="small"
            shadow={true}
          />
        ) : (
          <BigidTooltip placement="top" title={t('confidenceLevel.placeholder.tooltip') as ReactNode}>
            <span>{t('confidenceLevel.placeholder.n/a') as ReactNode}</span>
          </BigidTooltip>
        );
      },
    },
    {
      title: t('updatedConfidenceLevel.name'),
      name: 'updatedConfidenceLevel',
      type: BigidGridColumnTypes.CHIP,
      getCellValue: ({ updatedConfidenceLevel }) => {
        return updatedConfidenceLevel
          ? {
              chip: {
                icon: <BigidConfidenceIndicator level={updatedConfidenceLevel.toLowerCase() as BigidConfidenceLevel} />,
                size: 'small',
                label: updatedConfidenceLevel,
                bgColor: BigidColorsV2.white,
                shadow: true,
              },
            }
          : null;
      },
    },
    {
      title: t('note.name'),
      name: 'note',
      type: BigidGridColumnTypes.TEXT,
      isTooltipFollowCursor: true,
      getCellValue: ({ note }) => note?.text,
    },
    {
      title: t('noteCreatedBy.name'),
      name: 'noteCreatedBy',
      type: BigidGridColumnTypes.TEXT,
      isTooltipFollowCursor: true,
      getCellValue: ({ note }) => note?.createdBy,
    },
    {
      title: t('noteUpdatedBy.name'),
      name: 'noteUpdatedBy',
      type: BigidGridColumnTypes.TEXT,
      isTooltipFollowCursor: true,
      getCellValue: ({ note }) => note?.lastUpdatedBy,
    },
    {
      title: t('objectType.name'),
      name: 'objectType',
      type: BigidGridColumnTypes.TEXT,
      isTooltipFollowCursor: true,
      getCellValue: ({ detailedObjectType }) => getDetailedObjectTypeLabel(detailedObjectType),
    },
    {
      title: t('numberOfFindings.name'),
      name: 'totalFindings',
      type: BigidGridColumnTypes.TEXT,
      isTooltipFollowCursor: true,
      getCellValue: ({ totalFindings }) => totalFindings,
    },
    {
      title: t('curatedByUser.name'),
      name: 'curatedByUser',
      type: BigidGridColumnTypes.TEXT,
      isTooltipFollowCursor: true,
      getCellValue: ({ curatedByUser }) => curatedByUser,
    },
    {
      title: t('curatedAt.name'),
      name: 'curatedAt',
      type: BigidGridColumnTypes.DATE,
      isTooltipFollowCursor: true,
      getCellValue: ({ curatedAt }) => curatedAt,
    },
    ...sampleColumns,
    ...columnsPerVariant,
  ];

  return getColumnsOrderedByVariant(variant, columns);
}

export function getAdditionalStaticRequestFilterByVariant(
  variant: CuratedFieldsVariant,
  source: string,
  structuredOnly: boolean,
): BigidFieldFilter[] {
  switch (variant) {
    case CuratedFieldsVariant.CLASSIFIER_FINDINGS:
      if (structuredOnly) {
        return [
          {
            field: 'detailedObjectType',
            value: 'STRUCTURED',
            operator: 'equal',
          },
        ];
      } else {
        return [];
      }
    case CuratedFieldsVariant.CURATED_FIELDS:
      if (source) {
        return [
          {
            field: 'source',
            value: [source],
            operator: 'in',
          },
        ];
      } else return [];
    case CuratedFieldsVariant.CLASSIFIER_PREDICTIONS:
      return [
        {
          field: 'detailedObjectType',
          value: 'STRUCTURED',
          operator: 'equal',
        },
        {
          field: 'estimatedConfidenceLevel',
          value: ['HIGH', 'LOW'],
          operator: 'equal',
        },
      ];
    default:
      return [];
  }
}

export function getSearchFilterOriginalNames(searchQueryFilter: BigidFieldFilter[], searchFilterKeys: string[]) {
  return searchQueryFilter.map(searchTerm => {
    if (searchTerm.field === searchFilterKeys[0]) {
      searchTerm.field = 'fieldName';
    } else if (searchTerm.field === searchFilterKeys[1]) {
      searchTerm.field = 'objectName';
    }
    return searchTerm;
  });
}
