import React from 'react';
import { BigidApplyFilledIcon, BigidClearFilledIcon } from '@bigid-ui/icons';
import { styled } from '@mui/styles';

const ScheduledCellContent = styled('div')({
  display: 'flex',
  alignItems: 'center',
  gap: '5px',
});

export const scheduledCell = (isActive: boolean) => {
  const getIcon = (isActive: boolean) => {
    return isActive ? <BigidApplyFilledIcon color="positive" /> : <BigidClearFilledIcon color="negative" />;
  };

  return (
    <ScheduledCellContent>
      {getIcon(isActive)}
      <span>{isActive ? 'Yes' : 'No'}</span>
    </ScheduledCellContent>
  );
};
