import './downloadReport.scss';
import downloadReportTemplate from './downloadReport.html';

class DownloadReportService {
  reportTypesOptions = [
    {
      name: 'Full report',
      id: 'full',
    },
    {
      name: 'Short report',
      id: 'short',
    },
  ];

  constructor($uibModal, subjectAccessRequestService, notificationService, BIGID_MODAL_CLASSES) {
    'ngInject';
    this.notificationService = notificationService;
    this.subjectAccessRequestService = subjectAccessRequestService;
    this.$uibModal = $uibModal;
    this.BIGID_MODAL_CLASSES = BIGID_MODAL_CLASSES;
  }

  downLoadReport(requestId) {
    return this.showDownloadReportModal().result.then(reportType => {
      if (reportType) {
        const apis = {
          short: () => this.subjectAccessRequestService.getJITCsvShortReportFile(requestId),
          full: () => this.subjectAccessRequestService.getJITCsvFile(requestId),
        };

        return apis[reportType.id]().catch(err => {
          this.notificationService.error('Failed to download report.');
          window.console.error(`Filed to download csv for ${reportType.id} report`, err);
        });
      }
    });
  }

  showDownloadReportModal(reportTypesOptions = this.reportTypesOptions, defaultReportType = 'short') {
    return this.$uibModal.open({
      template: downloadReportTemplate,
      windowClass: `${this.BIGID_MODAL_CLASSES.centred} download-report-modal`,
      backdrop: true,
      controllerAs: '$ctrl',
      controller: function ($uibModalInstance) {
        'ngInject';

        this.reportTypes = {
          options: reportTypesOptions,
          selected: reportTypesOptions.find(({ id }) => id === defaultReportType),
        };

        this.close = () => {
          $uibModalInstance.close();
        };

        this.download = reportType => {
          $uibModalInstance.close(reportType);
        };
      },
    });
  }
}

angular.module('app').service('downloadReportService', DownloadReportService);
