// lazy load pdf make files, more than 2 MB will be loaded on demand
import { module } from 'angular';

const app = module('app');

//ISO 639-2 Language Code
const REPORT_LANGUAGES = {
  english: 'eng',
  korean: 'kor',
  hebrew: 'heb',
  thai: 'thai',
  japanese: 'jpn',
};

app.service('lazyLoadService', function ($q, $timeout, reportLanguageService) {
  'ngInject';

  class LazyLoadService {
    constructor() {
      this.deferredPdfMake = undefined;
      this.deferredPdfMakeFont = undefined;
      this.deferredHtmlDocX = undefined;
    }

    get REPORT_SUPPORTED_LANGUAGES() {
      return REPORT_LANGUAGES;
    }

    loadPdfMake() {
      if (this.deferredPdfMake) {
        return this.deferredPdfMake.promise;
      }

      this.deferredPdfMake = $q.defer();

      import(/* webpackChunkName: "pdfmake" */ 'pdfmake/build/pdfmake.js')
        .then(({ default: pdfMake }) => {
          this.addReportFont(pdfMake);
          this.loadPdfMakeFonts(pdfMake).then(() => {
            // resolve outside of this function and trigger $digest
            $timeout(() => this.deferredPdfMake.resolve(pdfMake));
          });
        })
        .catch(error => `An error occurred while lazy loading pdfmake ${error}`);

      return this.deferredPdfMake.promise;
    }

    loadPdfMakeFonts(pdfMake) {
      if (this.deferredPdfMakeFont) {
        return this.deferredPdfMakeFont.promise;
      }
      this.deferredPdfMakeFont = $q.defer();

      // preserve the context here since we can't use arrow function as require.ensure callback :?/
      const onLoaded = font => {
        pdfMake.vfs = (font.pdfMake && font.pdfMake.vfs) || window.pdfMake.vfs;
        // resolve outside of this function and trigger $digest
        $timeout(() => this.deferredPdfMakeFont.resolve());
      };

      switch (reportLanguageService.reportLanguage()) {
        case REPORT_LANGUAGES.korean:
          import(/* webpackChunkName: "pdfmake-font-kor" */ '../pdfmake-font/vfs_fonts-KOR.js')
            .then(onLoaded)
            .catch(
              error =>
                `An error occurred while lazy loading pdfmake font ${reportLanguageService.reportLanguage()} ${error}`,
            );
          break;
        case REPORT_LANGUAGES.hebrew:
          import(/* webpackChunkName: "pdfmake-font-heb" */ '../pdfmake-font/vfs_fonts-HEB.js')
            .then(onLoaded)
            .catch(
              error =>
                `An error occurred while lazy loading pdfmake font ${reportLanguageService.reportLanguage()} ${error}`,
            );
          break;
        case REPORT_LANGUAGES.thai:
          import(/* webpackChunkName: "pdfmake-font-thai" */ '../pdfmake-font/vfs_fonts-Thai.js')
            .then(onLoaded)
            .catch(
              error =>
                `An error occurred while lazy loading pdfmake font ${reportLanguageService.reportLanguage()} ${error}`,
            );
          break;
        case REPORT_LANGUAGES.japanese:
          import(/* webpackChunkName: "pdfmake-font-jpn" */ '../pdfmake-font/vfs_fonts-JPN.js')
            .then(onLoaded)
            .catch(
              error =>
                `An error occurred while lazy loading pdfmake font ${reportLanguageService.reportLanguage()} ${error}`,
            );
          break;
        default:
          import(/* webpackChunkName: "pdfmake-font-default" */ 'pdfmake/build/vfs_fonts.js')
            .then(onLoaded)
            .catch(
              error =>
                `An error occurred while lazy loading pdfmake font ${reportLanguageService.reportLanguage()} ${error}`,
            );
      }

      return this.deferredPdfMakeFont.promise;
    }

    addReportFont(pdfMake) {
      switch (reportLanguageService.reportLanguage()) {
        case REPORT_LANGUAGES.korean:
          pdfMake.fonts = {
            malgun: {
              normal: 'malgun.ttf',
              bold: 'malgun.ttf',
            },
          };
          break;
        case REPORT_LANGUAGES.hebrew:
          pdfMake.fonts = {
            OpenSansHebrew: {
              normal: 'OpenSansHebrew-Light.ttf',
              bold: 'OpenSansHebrew-Bold.ttf',
            },
          };
          break;
        case REPORT_LANGUAGES.thai:
          pdfMake.fonts = {
            THSarabunNew: {
              normal: 'THSarabunNew.ttf',
              bold: 'THSarabunNew-Bold.ttf',
            },
          };
          break;
        case REPORT_LANGUAGES.japanese:
          pdfMake.fonts = {
            KaigenSansJRegular: {
              normal: 'KaigenSansJRegular.ttf',
              bold: 'KaigenSansJRegular.ttf',
            },
          };
          break;
      }
    }

    setReportFont() {
      switch (reportLanguageService.reportLanguage()) {
        case REPORT_LANGUAGES.korean:
          return { font: 'malgun' };
        case REPORT_LANGUAGES.hebrew:
          return { font: 'OpenSansHebrew' };
        case REPORT_LANGUAGES.thai:
          return { font: 'THSarabunNew' };
        case REPORT_LANGUAGES.japanese:
          return { font: 'KaigenSansJRegular' };
        default:
          return '';
      }
    }

    loadHtmlDocX() {
      if (this.deferredHtmlDocX) {
        return this.deferredHtmlDocX.promise;
      }

      this.deferredHtmlDocX = $q.defer();

      import(/* webpackChunkName: "htmlDocX" */ 'html-docx-js/dist/html-docx.js')
        .then(({ default: htmlDocX }) => {
          // resolve outside of this function and trigger $digest
          $timeout(() => this.deferredHtmlDocX.resolve(htmlDocX));
        })
        .catch(error => `An error occurred while lazy loading htmlDocX ${error}`);

      return this.deferredHtmlDocX.promise;
    }
  }

  return new LazyLoadService();
});
