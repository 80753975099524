import { ComponentType, HTMLAttributes } from 'react';
import { SvgIconProps } from '@mui/material/SvgIcon';
import BigidCopyIcon from '../../../assets/icons/BigidCopyIcon.svg';
import BigidServerIcon from '../../../assets/icons/BigidServerIcon.svg';
import BigIDShieldIcon from '../../../assets/icons/BigIDShieldIcon.svg';
import BigIdInfoIcon from '../../../assets/icons/BigidHelpCircle.svg';
import { CONFIG } from '../../../../config/common';
import { goToCatalogWithChangePreferences } from '../goToCatalogWithChangePreferences';
import { activityHighlightsService } from '../../../services/activityHighlightsService';
import { $state } from '../../../services/angularServices';
import { getDataCatalogRecordsCount } from '../../DataCatalog/DataCatalogService';
import { clusteringService } from '../../Clustering/clusteringService';
import { IPromise } from 'angular';
import {
  CATALOG_PERMISSIONS,
  CLUSTER_ANALYSIS_PERMISSIONS,
  POLICIES_PERMISSIONS,
  ACCESS_MANAGEMENT_PERMISSIONS,
} from '@bigid/permissions';
import { isPermitted } from '../../../services/userPermissionsService';
import { getApplicationPreference } from '../../../services/appPreferencesService';
import { getObjectsSummariesByType } from '../services/dashboardService';

export interface InsightData {
  title: string;
  description: string;
  Icon: ComponentType<HTMLAttributes<SVGElement>> | ComponentType<SvgIconProps>;
  key: string;
  fetchPromiseName: string;
  getValue: (data: any) => Record<string, any>;
  emptyDescription?: string;
  createOnClick: (data: any) => () => void;
  onClick?: () => void;
  isLoading?: boolean;
  hide?: boolean;
  permission: string;
}

export const mostRelatedPolicyInsight: InsightData = {
  title: 'Know Your Data',
  description: 'Most of your data is {value}',
  permission: POLICIES_PERMISSIONS.ACCESS.name,
  createOnClick:
    ({ value }: { value: string }) =>
    () => {
      if (value) {
        $state.go(CONFIG.states.POLICIES, { policyName: value });
      } else {
        $state.go(CONFIG.states.DATA_SOURCE_INIT);
      }
    },
  Icon: BigIdInfoIcon,
  key: 'mustPolicy',
  fetchPromiseName: 'getActivityHighlights',
  getValue: (result: any) => {
    const {
      data: {
        data: [activityHighlights],
      },
    } = result;
    const { name } = activityHighlights?.highlights?.detailedInformation?.policies?.triggeredPolicies[0] || {};

    return {
      value: name,
    };
  },
  emptyDescription: 'Use our onboarding wizard to get started',
};

export const clusterInsight: InsightData = {
  title: 'Know Your Data',
  description: 'Your files were grouped into {value} clusters using Cluster Analysis.',
  permission: CLUSTER_ANALYSIS_PERMISSIONS.ACCESS.name,
  createOnClick: () => () => {
    $state.go(CONFIG.states.CLUSTERING);
  },
  Icon: BigIdInfoIcon,
  key: 'Cluster',
  fetchPromiseName: 'getClusters',
  getValue: ({ clusters }) => {
    return { value: clusters?.length || '' };
  },
  emptyDescription: '',
};

export const duplicatesInsight: InsightData = {
  title: 'Minimize Duplicates',
  description: '{value} duplicate files were found in your Catalog.',
  permission: CATALOG_PERMISSIONS.ACCESS.name,
  createOnClick: () => () => {
    goToCatalogWithChangePreferences({
      sideFilterSelection: [
        {
          field: 'has_duplicates',
          operator: 'equal',
          value: 'true',
        },
      ],
      optionalSideFilterSections: [],
      searchQuery: '',
    });
  },
  Icon: BigidCopyIcon,
  key: 'Duplicates',
  fetchPromiseName: 'getDataCatalogRecordsDuplicatesCount',
  getValue: ({ count }) => {
    return { value: count || '' };
  },
  emptyDescription: '',
};

const sensitiveInsight: InsightData = {
  title: 'Keep it Safe',
  description: '{value} files containing sensitive information are open to large groups of users.',
  permission: CATALOG_PERMISSIONS.ACCESS.name,
  createOnClick: () => () => {
    goToCatalogWithChangePreferences({
      sideFilterSelection: [
        {
          field: 'contains_pi',
          operator: 'equal',
          value: 'true',
        },
        {
          field: 'metadata.access_rights',
          operator: 'equal',
          value: 'OPEN ACCESS',
        },
      ],
      optionalSideFilterSections: [],
      searchQuery: '',
    });
  },
  Icon: BigIDShieldIcon,
  key: 'sensitive',
  fetchPromiseName: 'getObjectsSummariesByType',
  getValue: summaryByTypes => {
    const value = summaryByTypes.reduce(
      (acc: number, { totalObjectsWithPiiAndOpenAccess }: { totalObjectsWithPiiAndOpenAccess: number }) =>
        acc + totalObjectsWithPiiAndOpenAccess,
      0,
    );
    return { value };
  },
  emptyDescription: '',
};

const scansCompletedInsight = {
  title: 'Manage Data Rights Fulfillment',
  description: '{value} Access Request scans were completed.',
  permission: ACCESS_MANAGEMENT_PERMISSIONS.ACCESS.name,
  createOnClick: () => () => {
    $state.go(CONFIG.states.ACCESS_REQUEST_MANAGEMENT, { path: 'completed-requests' });
  },
  Icon: BigidServerIcon,
  key: 'scans',
  fetchPromiseName: 'getActivityHighlights',
  getValue: (result: any) => {
    const {
      data: {
        data: [activityHighlights],
      },
    } = result;
    const { header } = activityHighlights?.highlights?.overview?.cards[3] || {};

    return {
      value: header && header !== '0' ? header : '',
    };
  },
};

export const similarContentInsight: InsightData = {
  title: 'Find Similar Content',
  description: '{value} groups of files with similar content and metadata',
  permission: CLUSTER_ANALYSIS_PERMISSIONS.ACCESS.name,
  createOnClick: () => () => {
    $state.go(CONFIG.states.CLUSTERING);
  },
  Icon: BigidServerIcon,
  key: 'ClusterSimilarContent',
  fetchPromiseName: 'getClusters',
  getValue: ({ clusters }) => {
    return { value: clusters?.length || '' };
  },
  emptyDescription: '',
};

export const defaultInsightsList: InsightData[] = [
  mostRelatedPolicyInsight,
  clusterInsight,
  duplicatesInsight,
  sensitiveInsight,
  scansCompletedInsight,
];

export const smallIdInsightsList: InsightData[] = [mostRelatedPolicyInsight, sensitiveInsight];

const setInsightsItem = (currentInsights: InsightData[], key: string, data: Partial<InsightData>) =>
  currentInsights.map(insight =>
    insight.key === key
      ? {
          ...insight,
          isLoading: false,
          ...data,
        }
      : insight,
  );

const dataPromisesMap: Record<string, () => Promise<any> | IPromise<any>> = {
  getActivityHighlights: () => activityHighlightsService.getActivityHighlights(true),
  getObjectsSummariesByType: () => getObjectsSummariesByType(),
  getDataCatalogRecordsDuplicatesCount: () =>
    getDataCatalogRecordsCount('limit=100&sort=&filter=has_duplicates%20%3D%20true'),
  getClusters: () =>
    getApplicationPreference('CLUSTERING_ENABLED') ? clusteringService.getClusters() : Promise.resolve({}),
};

export const setInsightsData = (setInsights: any, insightsList = defaultInsightsList) => {
  const dataPromises: Record<string, Promise<any> | IPromise<any>> = {};

  insightsList.map(
    ({ getValue, description, key, createOnClick, fetchPromiseName, emptyDescription, permission, hide }) => {
      if (!hide && isPermitted(permission)) {
        dataPromises[fetchPromiseName] = dataPromises[fetchPromiseName] || dataPromisesMap[fetchPromiseName]();
        (dataPromises[fetchPromiseName] as Promise<any>)
          .then(data => {
            const { value } = getValue(data);
            setInsights((currentInsights: InsightData[]) =>
              setInsightsItem(currentInsights, key, {
                isLoading: false,
                description: !value ? emptyDescription : description.replace('{value}', value),
                hide: !value && !emptyDescription,
                onClick: createOnClick({ value }),
              }),
            );
          })
          .catch(() => {
            setInsights((currentInsights: InsightData[]) => setInsightsItem(currentInsights, key, { hide: true }));
          });
      } else {
        setInsights((currentInsights: InsightData[]) => setInsightsItem(currentInsights, key, { hide: true }));
      }
    },
  );
};
