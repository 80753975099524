import React, { useRef, useMemo, useCallback, FC } from 'react';
import { objectToQueryString, QueryParams } from '@bigid-ui/components';
import { BigidLayoutMasterDetails, BigidLayoutMasterDetailsConfig, useLayout } from '@bigid-ui/layout';
import { BigidGridDataFetchResult, BigidGridProps, BigidGridQueryComponents, BigidGridRow } from '@bigid-ui/grid';
import { useLocalTranslation } from './translations';
import { createGridConfig, createLayoutConfig } from './utils';
import { CertificateFormData, CertificateGridRow, ForwardFormRef } from './types';
import { CertificatesManagement, getCertificates } from '../CertificatesManagement';
import { usePageHeader } from './hooks/usePageHeader';
import { useRouteLeavingListener } from '../../../components/RouteLeaving/hooks/useRouteLeavingListener';
import { useFormActions } from './hooks/useFormActions';
import { CertificatesManagementFormMemo } from './CertificatesManagementForm';
import { CertificatesManagementEmptyState } from './CertificatesManagementEmptyState';
import { CertificatesManagementHeader } from './CertificatesManagementHeader';
import { getApplicationPreference } from '../../../services/appPreferencesService';
import styled from '@emotion/styled';
import { BigidLayoutMasterDetailsRenderProps } from '../../../types/BigidLayoutMasterDetailsTypes';

const pageSize = 400;

const LayoutWrapper = styled.div({
  height: 'calc(100% - 24px)',
  marginLeft: 12,
  marginRight: 12,
});

export const CertificatesManagementView: FC = () => {
  const isShowNewView = useMemo(() => getApplicationPreference('ENABLE_NEW_CERTIFICATES_MANAGEMENT_VIEW_FF'), []);
  const certificatesFormRef = useRef<ForwardFormRef>();
  const { t } = useLocalTranslation();

  const layout = useLayout<CertificateGridRow>('BigidLayoutMasterDetails', {
    fetchDataFunction: async (
      queryComponents: BigidGridQueryComponents,
    ): Promise<BigidGridDataFetchResult<CertificateGridRow>> => {
      const query = objectToQueryString({ ...(queryComponents as QueryParams), limit: pageSize });
      const { total, results } = await getCertificates(`?${query}`);
      return { totalCount: total, data: results.map(result => ({ ...result, id: result._id })) };
    },
    pageSize,
  });

  const { gridId, rows, isLoading, apiRef, getSelectedRow, ...rest } = layout;
  const selectedRow = getSelectedRow();
  const hasNoCredentials = !rows?.length && !isLoading;

  const gridConfig: BigidGridProps<CertificateGridRow> = useMemo(
    () => createGridConfig(gridId, { ...rest, rows, isLoading, apiRef }),
    [gridId, rest, rows, isLoading, apiRef],
  );

  const {
    showUnsavedChangesDialog,
    handleSearchChange,
    handleSelectionChange,
    handleCertificateAdd,
    handleSubmit,
    formControls,
    handleDelete,
  } = useFormActions({
    row: selectedRow,
    layout,
    certificatesFormRef,
  });

  usePageHeader({ handleCertificateAdd, shouldShowAction: !!rows?.length && isShowNewView });

  const { updateIsRouteLeavingEnabled } = useRouteLeavingListener(showUnsavedChangesDialog);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const updateIsRouteLeavingEnabledMemo = useCallback(updateIsRouteLeavingEnabled, []);

  const config: BigidLayoutMasterDetailsConfig<CertificateGridRow> = useMemo(
    () => createLayoutConfig(gridConfig),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [gridConfig],
  );

  return isShowNewView ? (
    <LayoutWrapper>
      <BigidLayoutMasterDetails
        disabled={hasNoCredentials}
        onSelectionChange={handleSelectionChange}
        onSearchSubmit={handleSearchChange}
        onSearchChange={handleSearchChange}
        config={config as unknown as BigidLayoutMasterDetailsConfig<BigidGridRow>}
      >
        <BigidLayoutMasterDetails.Header
          render={({ selectedItem }: BigidLayoutMasterDetailsRenderProps<CertificateGridRow>): JSX.Element => {
            const { isPending } = selectedItem ?? {};
            return (
              selectedItem && (
                <CertificatesManagementHeader
                  title={isPending ? t('message.addCertificatesHeader') : selectedItem?.name}
                  row={selectedItem}
                  handleSubmit={handleSubmit}
                  handleDelete={handleDelete}
                />
              )
            );
          }}
        />
        <BigidLayoutMasterDetails.Content
          render={({ selectedItem }: BigidLayoutMasterDetailsRenderProps<CertificateGridRow>) => {
            return selectedItem ? (
              <CertificatesManagementFormMemo
                key={selectedItem?.id}
                row={selectedItem as unknown as CertificateFormData}
                formControls={formControls}
                setFormIsTouched={updateIsRouteLeavingEnabledMemo}
              />
            ) : (
              hasNoCredentials && <CertificatesManagementEmptyState handleCertificateAdd={handleCertificateAdd} />
            );
          }}
        />
      </BigidLayoutMasterDetails>
    </LayoutWrapper>
  ) : (
    <CertificatesManagement />
  );
};
