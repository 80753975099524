import React, { FC, useEffect, useState } from 'react';
import { isEqual } from 'lodash';
import makeStyles from '@mui/styles/makeStyles';
import {
  BigidMapChart,
  MapChartPropsType,
  BigidLoader,
  MapChartImageItemDataType,
  MapChartLineItemDataType,
  MapChartImageSeriesConfig,
  MapChartLineSeriesConfig,
} from '@bigid-ui/components';
import { notificationService } from '../../../../services/notificationService';
import { loginService } from '../../../../../authentication/login/login.service';

const useStyles = makeStyles({
  wrapper: ({ hidden }: Partial<DashboardMapChartContainerProps>) => ({
    visibility: hidden ? 'hidden' : 'unset',
    position: hidden ? 'absolute' : 'relative',
    minWidth: '585px',
    width: '100%',
    minHeight: '330px',
    flex: '1 0 auto',
    marginTop: '12px',
  }),
});

export interface DashboardMapChartFetchDataResult {
  imagesData: MapChartImageItemDataType[];
  lineData: MapChartLineItemDataType[];
}

export interface DashboardMapChartContainerProps extends MapChartPropsType {
  fetchData: () => Promise<DashboardMapChartFetchDataResult>;
  hidden: boolean;
  height: number;
  imageSeries: MapChartImageSeriesConfig[];
  lineSeries: MapChartLineSeriesConfig[];
}

const naiveCacheInitialState = { data: {}, isLoading: true };
let naiveCache = { ...naiveCacheInitialState };

export const DashboardMapChartContainer: FC<DashboardMapChartContainerProps> = React.memo(
  ({ fetchData, hidden, imageSeries, lineSeries, height }) => {
    const { wrapper } = useStyles({ hidden });
    const [isLoading, setIsLoading] = useState(naiveCache.isLoading);
    const [{ imagesData, lineData }, setData] = useState<any>(naiveCache.data);

    useEffect(() => {
      fetchData()
        .then(data => {
          setData((currentData: DashboardMapChartFetchDataResult) => {
            const isSameData = isEqual(currentData.imagesData, data.imagesData);
            return isSameData ? currentData : data;
          });
          naiveCache.data = data;
        })
        .finally(() => {
          naiveCache.isLoading = false;
          setIsLoading(false);
        })
        .catch(err => {
          notificationService.error(err.message);
        });

      return () => {
        if (!loginService.isLoggedIn()) {
          naiveCache = { ...naiveCacheInitialState };
        }
      };
    }, [fetchData]);

    return (
      <div className={wrapper} data-aid={`DashboardMapChartContainer`}>
        {isLoading ? (
          <BigidLoader />
        ) : (
          <BigidMapChart
            imagesData={imagesData}
            imageSeries={imageSeries}
            height={height}
            lineSeries={lineSeries}
            lineData={lineData}
          />
        )}
      </div>
    );
  },
);
