import * as React from 'react';
import { BigidFormFieldSwitch, compareObjectsExceptFunctions, BigidSwitchFieldRenderProps } from '@bigid-ui/components';

const ENABLE_LABEL = 'Enable';
const DISABLE_LABEL = 'Disable';

function calculateLabelByProps(props: BigidSwitchFieldRenderProps) {
  return props?.fieldProps.label !== ENABLE_LABEL || props.value === true ? props?.fieldProps.label : DISABLE_LABEL;
}

export const BigidFormFieldSwitchWithDynamicLabel: React.FC<
  BigidSwitchFieldRenderProps & {
    calcLabel?: (props: BigidSwitchFieldRenderProps) => string;
  }
> = React.memo(({ calcLabel = calculateLabelByProps, ...restProps }) => {
  const label = calculateLabelByProps(restProps);
  const fieldProps = { ...restProps.fieldProps, label };

  return <BigidFormFieldSwitch {...restProps} fieldProps={fieldProps} />;
}, compareObjectsExceptFunctions);

BigidFormFieldSwitchWithDynamicLabel.displayName = 'BigidFormFieldSwitchWithDynamicLabel';
