import { useMutation, UseMutationResult, MutationFunction, UseMutationOptions } from 'react-query';
import { httpRequest, ErrorType, BodyType } from '../config/request';
import type {
  CreateCustomBiEventItemResponse,
  CreateCustomBiEventItemRequest,
} from '../types/useCreateCustomBiEventTypes';
import type { AsyncReturnType } from '../types/types';

export type CreateCustomBiEventQueryResult = NonNullable<AsyncReturnType<typeof createCustomBiEvent>>;

export type CreateCustomBiEventQueryError = ErrorType<unknown>;

export type UseCreateCustomBiEventOptions = typeof useCreateCustomBiEvent extends (
  biEventSystem: string,
  action: string,
  options?: infer O,
) => unknown
  ? O
  : never;

// @info already versioned by request logic
const API_VERSION = '';
const BI_EVENT_ENDPOINT = 'reporting-events/';

export const createCustomBiEvent = async (
  biEventSystem: string,
  action: string,
  event: CreateCustomBiEventItemRequest,
): Promise<CreateCustomBiEventItemResponse> =>
  httpRequest<CreateCustomBiEventItemResponse>({
    url: `${API_VERSION}${BI_EVENT_ENDPOINT}${biEventSystem}:${action}`,
    method: 'post',
    data: event,
  });

export const createCustomBiEventMutationKey = (
  biEventSystem: string,
  action: string,
  event: CreateCustomBiEventItemRequest,
): Array<unknown> => [`${API_VERSION}${BI_EVENT_ENDPOINT}${biEventSystem}:${action}`, ...(event ? [event] : [])];

export const useCreateCustomBiEvent = <TError = CreateCustomBiEventQueryError, TContext = unknown>(
  biEventSystem: string,
  action: string,
  options?: {
    mutation?: UseMutationOptions<
      AsyncReturnType<typeof createCustomBiEvent>,
      TError,
      { data: BodyType<CreateCustomBiEventItemRequest> },
      TContext
    >;
  },
): UseMutationResult<
  CreateCustomBiEventItemResponse,
  TError,
  { data: BodyType<CreateCustomBiEventItemRequest> },
  TContext
> => {
  const { mutation: mutationOptions } = options || {};
  // eslint-disable-next-line max-len
  const mutationFn: MutationFunction<
    AsyncReturnType<typeof createCustomBiEvent>,
    { data: BodyType<CreateCustomBiEventItemRequest> }
  > = async props => {
    const { data } = props || {};

    return createCustomBiEvent(biEventSystem, action, data);
  };

  return useMutation<
    AsyncReturnType<typeof createCustomBiEvent>,
    TError,
    { data: BodyType<CreateCustomBiEventItemRequest> },
    TContext
  >(mutationFn, mutationOptions);
};
