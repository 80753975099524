import { HeadersObject, httpService } from '../../services/httpService';
import { ReactText } from 'react';
import { DateISO8601 } from '../../types/types';
import {
  ActionData,
  BigidFilter,
  BigidSideFilterSectionIdentifier,
  BigidTagBaseProps,
  objectToQueryString,
  QueryParams,
} from '@bigid-ui/components';
import { parseFieldFiltersToSearchQuery } from '@bigid-ui/layout';
import { BigidGridQueryComponents } from '@bigid-ui/grid';
import {
  TagEntity,
  TagAssignmentTarget,
  TagResponseEntity,
  createTag,
  TagCompositionPartType,
} from '../TagsManagement/TagsManagementService';
import { getTagEntityByName } from '../TagsManagement/TagsManagementUtils';
import { sendSSERequestWithEventId } from '../../services/sseService';
import {
  DataCatalogAsyncOperationRoutingKey,
  DataCatalogAsyncOperation,
  DataCatalogAsyncOperationStatus,
} from './DataCatalogAsyncOps/DataCatalogAsyncOpsTypes';
import { TagBulkAssignmentPayloadData } from './DataCatalogAsyncOps/operations/TagBulkAssignment';
import { FollowedObjectsResponse } from './DataCatalogFollowedObjects/FollowedObjectTypes';
import { queryService } from '../../services/queryService';
import { ObjectDiffNotification } from '../../utilities/headerNotifications/headerNotificationTypes';
import { getApplicationPreference } from '../../services/appPreferencesService';
import { $state } from '../../services/angularServices';

const UNKNOWN = 'Unknown';

export type RecentlyViewedCatalogItem = Pick<DataCatalogRecord, 'fullyQualifiedName' | 'objectName'> & {
  objectType: DataCatalogObjectType;
  dataSourceName: string;
  dataSourceType: string;
};
export interface DataCatalogPagePreferences {
  optionalSectionsIds?: BigidSideFilterSectionIdentifier[];
  recentlyViewedItems: RecentlyViewedCatalogItem[];
}

export enum DataCatalogRecordScannerTypeGroup {
  STRUCTURED = 'structured',
  UNSTRUCTURED = 'unstructured',
  EMAIL = 'email',
}

export enum DataCatalogObjectType {
  TABLE = 'rdb',
  TABLE_VIEW = 'rdb-view',
  FILE = 'file',
  STRUCTURED_LEAF = 'STRUCTURED-LEAF_DATA_OBJECT',
  STRUCTURED_FILE_LEAF = 'STRUCTURED_FILE-LEAF_DATA_OBJECT',
  UNSTRUCTURED_LEAF = 'UNSTRUCTURED-LEAF_DATA_OBJECT',
  PARTITIONED_TABLE_LEAF = 'PARTITIONED_TABLE-LEAF_DATA_OBJECT',
  APP_LEAF = 'APP-LEAF_DATA_OBJECT',
  MODEL = 'model',
  DATASET = 'dataset',
  VECTOR = 'vector',
}

export enum DetailedObjectType {
  STRUCTURED = 'STRUCTURED',
  STRUCTURED_FILE = 'STRUCTURED_FILE',
  UNSTRUCTURED = 'UNSTRUCTURED',
  PARTITIONED_TABLE = 'PARTITIONED_TABLE',
  APP = 'APP',
}

export enum HierarchyType {
  CONTAINER = 'CONTAINER',
  SUB_CONTAINER = 'SUB_CONTAINER',
  LEAF_DATA_OBJECT = 'LEAF_DATA_OBJECT',
}

export interface DataCatalogRecord {
  id: ReactText;
  source: string;
  type: string;
  objectName: string;
  objectId: string;
  scannerType: string;
  containerName?: string;
  containerId?: string;
  subContainerName?: string;
  subContainerId?: string;
  objectType?: DataCatalogObjectType;
  extendedObjectType?: DataCatalogObjectType;
  detailedObjectType?: DetailedObjectType;
  hierarchyType?: HierarchyType;
  fullyQualifiedName: string;
  fullObjectName: string;
  attribute?: string[];
  application_name?: string[];
  open_access: 'Yes' | 'No';
  scanner_type_group?: DataCatalogRecordScannerTypeGroup;
  total_pii_count?: number;
  owner?: string;
  modified_date?: DateISO8601;
  created_date?: DateISO8601;
  duplicate_id?: string;
  cluster_id?: string;
  fileId?: string;
  has_duplicates: 'Yes' | 'No';
  tags?: TagEntity[];
  isPermittedToPreview?: boolean;
  last_scanned?: DateISO8601;
}

export interface IdentityLocationEntity {
  id: string;
  name: string;
  count: number;
  avg: number;
}

export interface SourceRiskEntity {
  id: string;
  name: string;
  count: number;
  avg: number;
}

export interface ApplicationRiskEntity {
  id: string;
  name: string;
  count: number;
  avg: number;
}

export interface AppAnnotations {
  annotations: Record<string, Record<string, any>>[];
  app_id: string;
}

export interface AppIntegrationSettingsTabMetadataConfig {
  key: string;
  label: string;
}

export enum WidgetTypes {
  GRID = 'GRID',
  DATE = 'DATE',
  TEXT = 'TEXT',
}

export interface AppIntegrationSettingsTabWidgetConfig {
  title?: string;
  widgetType: WidgetTypes;
  annotationsFilter?: string;
  metadata?: AppIntegrationSettingsTabMetadataConfig[];
}

export interface AppIntegrationSettingsTabConfig {
  title: string;
  deepLinkPath?: string;
  widgets?: AppIntegrationSettingsTabWidgetConfig[];
}

export interface AppIntegrationSettingsColumnConfig {
  name: string;
  tagName: string;
  iconName: string;
}

export interface AppIntegrationSettingsFilterConfig {
  name: string;
  tagName: string;
}

export interface AppIntegrationSettings {
  appId: string;
  appFriendlyName: string;
  filters?: AppIntegrationSettingsFilterConfig[];
  objectDetails?: {
    tabs?: AppIntegrationSettingsTabConfig[];
  };
  columns?: AppIntegrationSettingsColumnConfig[];
}

export interface ApplicationEntity {
  id: string;
  name: string;
}

export interface AttributeRiskEntity {
  id: string;
  glossary_id: string | null;
  original_names: string[];
  short_name: string;
  name: string;
  count: number;
  avg: number;
  id_score: number;
  min_score: number;
  max_score: number;
}

export interface DataCatalogResponse {
  totalRowsCounter?: number;
  estimatedCount?: number;
  total?: number;
  results: DataCatalogRecord[];
}

export interface DataCatalogCountResponse {
  count: number;
}

export interface SystemAttributesResponse {
  attributesList: SystemAttribute[];
  objectAttributeList: SystemAttribute[];
}

export type SystemAttributeType =
  | 'IDSoR Attribute'
  | 'Enrichment Attribute'
  | 'Classification'
  | 'Composite Fields'
  | 'Manual'
  | 'OOTB';

export interface SystemAttribute {
  id?: ReactText;
  attribute_id?: ReactText;
  attribute_original_name: string;
  attribute_name: string;
  attribute_type?: SystemAttributeType;
  attribute_original_type?: SystemAttributeType;
}

export interface DistinctDataSource {
  value: string;
}

export interface DistinctAttribute {
  value: string;
}

export interface DistinctFileType {
  value: string;
}

export interface SavedQueryEntity {
  _id: string;
  tag_name: string;
  tag_value: string;
  tag_type: string;
  query: string;
  create_data: DateISO8601;
  update_data: DateISO8601;
}

export interface Policies {
  name: string;
  complianceRuleCalc: ComplianceRuleCalc;
  is_enabled: boolean;
  findings: any;
  displayName?: string;
}

export interface ComplianceRuleCalc {
  maxFindings: string;
  bigidQuery: string;
}

interface ObjectIssueDto {
  fullyQualifiedName: string;
  comment: string;
}

interface ObjectDiffNotificationResponse {
  data: {
    notifications: ObjectDiffNotification[];
    totalCount: number;
  };
}

export interface CloudEntity {
  cloudName?: string;
  cloudValue?: string;
  mongoType?: string;
  cloudValues?: CloudEntity[];
}

export interface FileTypeEntity {
  value?: string;
  type?: string;
  types?: FileTypeEntity[];
}

export type FetchDataCatalogTasksResponse = { data: DataCatalogAsyncOperation[] };

export type FetchDataCatalogRecordsPayload = {
  offset: number;
  skip: number;
  limit: number;
  format: 'csv' | 'json';
  sort: { field: string; order: 'asc' | 'desc' }[];
  filter?: string;
  requireTotalCount?: 'requireTotalCount=true' | 'requireTotalCount=false';
};

export const getDataCatalogRecords = (query?: string, headers?: HeadersObject) => {
  return httpService
    .fetch<DataCatalogResponse>(`data-catalog/?format=json${query || ''}`, undefined, headers)
    .then(({ data }) => data);
};

export const getDataCatalogRecordsPost = (payload?: FetchDataCatalogRecordsPayload, headers?: HeadersObject) => {
  return httpService.post<DataCatalogResponse>(`data-catalog`, payload, headers).then(({ data }) => data);
};

export const getDataCatalogRecordsCount = (query?: string, headers?: HeadersObject) => {
  return httpService
    .fetch<DataCatalogCountResponse>(`data-catalog/count${query ? '?' + query : ''}`, undefined, headers)
    .then(({ data }) => data);
};

export const getDataCatalogRecordsCountCancellable = (query?: string) => {
  return httpService.cancelableFetch<DataCatalogCountResponse>(`data-catalog/count${query ? '?' + query : ''}`);
};

export const getDataCatalogRecordsAsCSV = (query?: string) => {
  return httpService.downloadFilePost(`data-catalog/file-download/export`, { filter: query });
};

export const getIdentityLocations = (query?: string) => {
  return httpService
    .fetch<{ identity_locations: IdentityLocationEntity[] }>(`identityLocations${query || ''}`)
    .then(({ data }) => data.identity_locations.filter(({ id, name }) => id !== UNKNOWN && name !== UNKNOWN));
};

export const getSourceRisks = (query?: string) => {
  return httpService
    .fetch<{ source_risks: SourceRiskEntity[] }>(`sourceRisks${query || ''}`)
    .then(({ data }) => data.source_risks);
};

export const getApplicationRisks = (query?: string) => {
  return httpService
    .fetch<{ application_risks: ApplicationRiskEntity[] }>(`applicationRisks${query || ''}`)
    .then(({ data }) => data.application_risks);
};

export const getApplications = (query?: string) => {
  return httpService
    .fetch<{ applications: ApplicationEntity[] }>(`applications${query || ''}`)
    .then(({ data }) => data.applications);
};

export const getAttributeRisks = (query?: string) => {
  return httpService
    .fetch<{ attribute_risks: ApplicationRiskEntity[] }>(`attributeRisks${query || ''}`)
    .then(({ data }) => data.attribute_risks);
};

export const getIntegrationSettings = () => {
  return httpService.fetch<{ data: AppIntegrationSettings[] }>(`data-catalog/integration-settings`).then(({ data }) => {
    return data.data;
  });
};

export const getAppAnnotations = (appId: string, params?: Record<string, any>) => {
  const query = objectToQueryString(params as QueryParams);
  return httpService
    .fetch<{ data: AppAnnotations[] }>(`app-annotations/${appId}${query ? '?' + query : ''}`)
    .then(({ data }) => {
      return data.data;
    });
};

export const getSystemAttributes = (columnName?: string, objectName?: string) => {
  const query = objectToQueryString({
    column_name: columnName,
    object_name: objectName,
  });
  return httpService
    .fetch<{ data: SystemAttributesResponse[] }>(`data-catalog/manual-fields/attributes-list/?${query}`)
    .then(({ data }) => data.data[0]);
};

export const getFollowedObjects = async () => {
  return httpService
    .fetch<FollowedObjectsResponse>('data-catalog/object-followers/objects')
    .then(({ data }) => data.data?.results ?? []);
};

export const addFollowedObjects = (fqnList: string[]) => {
  const data = fqnList.map(fullyQualifiedName => ({ fullyQualifiedName }));
  return httpService.post('data-catalog/object-followers', {
    data,
  });
};

export const deleteFollowedObjects = (fqnList: string[]) => {
  const data = fqnList.map(fullyQualifiedName => ({ fullyQualifiedName }));
  return httpService.delete('data-catalog/object-followers', {
    data,
  });
};

export const getObjectDiffs = async (queryComponents: BigidGridQueryComponents) => {
  const updatedFilters: BigidFilter = [
    ...(queryComponents.filter ?? []),
    { field: 'type', operator: 'equal', value: 'OBJECT_DIFF' },
  ];
  const gridConfigQuery = queryService.getGridConfigQuery({
    ...queryComponents,
    filter: updatedFilters,
  });
  const {
    data: { data },
  } = await httpService.fetch<ObjectDiffNotificationResponse>(`notifications?${gridConfigQuery}&filteredCount=true`);

  return data;
};

export const getDataCatalogInsights = () => {
  return httpService.fetch(`data-catalog/insights`).then(({ data }) => data.data);
};

export const getDistinctDataSources = (filter?: string) => {
  const query = filter && filter.length ? `?filter=${filter}` : '';
  return httpService
    .fetch<{ results: DistinctDataSource[] }>(`data-catalog/distinct-values/source${query}`)
    .then(({ data }) => data.results.filter(({ value }) => value));
};

export const getDistinctFileTypes = () => {
  return httpService
    .fetch<{ results: DistinctFileType[] }>(`data-catalog/distinct-values/fileType`)
    .then(({ data }) => data.results);
};

export const getPolicies = () => {
  return httpService.fetch<Policies[]>(`compliance-rules`).then(({ data }) => data);
};

export const getDistinctAttributes = (filter?: string) => {
  const query = filter && filter.length ? `?filter=${filter}` : '';
  return httpService
    .fetch<{ results: DistinctAttribute[] }>(`data-catalog/distinct-values/attribute${query}`)
    .then(({ data }) => data.results.filter(({ value }) => value));
};

export const getFileTypes = () => {
  return httpService
    .fetch<{ data: FileTypeEntity[] }>(`data-catalog/side-filter/file-types`)
    .then(({ data }) => data.data);
};

export const getCloudData = () => {
  return httpService
    .fetch<{ data: CloudEntity[] }>(`data-catalog/side-filter/cloud-types`)
    .then(({ data }) => data.data);
};

export const getSavedQueries = () => {
  return httpService.fetch<SavedQueryEntity[]>(`saved-queries`).then(({ data }) => data);
};

export const createObjectIssue = (fullyQualifiedName: string, comment: string) => {
  return httpService.post<unknown, ObjectIssueDto>('data-catalog/object-issues', { fullyQualifiedName, comment });
};

export const attachTagsBulk = (
  systemTags: TagEntity[],
  tags: BigidTagBaseProps[],
  filter?: string,
  skipInvokeBulkTag?: boolean,
) => {
  const data: TagBulkAssignmentPayloadData = {
    type: TagAssignmentTarget.object,
    tags: tags.reduce((tagsPayload, tag) => {
      const { name, value } = tag;
      const tagToAttach = getTagEntityByName(systemTags, name, value);

      if (tagToAttach) {
        return [
          ...tagsPayload,
          { tagId: tagToAttach.tagId, tagName: name, valueId: tagToAttach.valueId, tagValue: value },
        ];
      } else {
        return tagsPayload;
      }
    }, []),
  };

  if (skipInvokeBulkTag) {
    return data;
  }
  return sendSSERequestWithEventId(
    'post',
    `data-catalog/manual-fields/tags/async`,
    { data },
    { filter },
    DataCatalogAsyncOperationRoutingKey.TAG_BULK_ASSIGNMENT,
  );
};

export const createAndAttachTagsBulk = async (
  systemTags: TagEntity[],
  tags: BigidTagBaseProps[],
  filter?: string,
  skipInvokeBulkTag?: boolean,
) => {
  const data: TagBulkAssignmentPayloadData = {
    type: TagAssignmentTarget.object,
    tags: [],
  };
  for (const tag of tags) {
    const { name, value, isNew } = tag;

    let nameCreated: TagResponseEntity;
    let valueCreated: TagResponseEntity;
    let tagNameIdToAttach: TagResponseEntity['_id'];
    let tagValueIdToAttach: TagResponseEntity['_id'];
    const tagNameDescription = `${name} tag name`;
    const tagValueDescription = `${value} tag value`;

    if (isNew) {
      const { data: tagCreatedResponse } = await createTag({
        name,
        type: TagCompositionPartType.tag,
        description: tagNameDescription,
      });
      nameCreated = tagCreatedResponse[0];
      tagNameIdToAttach = nameCreated._id;
      const { data: valueCreatedResponse } = await createTag({
        name: value,
        type: TagCompositionPartType.value,
        description: tagValueDescription,
        parentId: tagNameIdToAttach,
      });
      valueCreated = valueCreatedResponse[0];
      tagValueIdToAttach = valueCreated._id;
    } else {
      tagNameIdToAttach = getTagEntityByName(systemTags, name)?.tagId;
      const { data: valueCreatedResponse } = await createTag({
        name: value,
        type: TagCompositionPartType.value,
        description: tagValueDescription,
        parentId: tagNameIdToAttach,
      });
      valueCreated = valueCreatedResponse[0];
      tagValueIdToAttach = valueCreated._id;
    }

    if (tagNameIdToAttach && tagValueIdToAttach) {
      data.tags.push({ tagId: tagNameIdToAttach, tagName: name, valueId: tagValueIdToAttach, tagValue: value });
    }
  }
  if (skipInvokeBulkTag) {
    return data;
  }
  return sendSSERequestWithEventId(
    'post',
    `data-catalog/manual-fields/tags/async`,
    { data },
    { filter },
    DataCatalogAsyncOperationRoutingKey.TAG_BULK_ASSIGNMENT,
  );
};

export const getDataCatalogTasks = () => {
  return httpService.fetch(`data-catalog/tasks`).then(({ data }) => data.data);
};

export const getConfidenceLevel = (id: string) => {
  return httpService
    .fetch(`data-catalog/conf-level-rules?filter=field_name=${encodeURIComponent(id)}`)
    .then(({ data }) => data.data);
};

export const postIgnoreConfidenceLevel = (fullyQualifiedName: string) => {
  const data = [
    {
      rule: 'IGNORE',
      fieldType: 'FQN',
      fieldName: fullyQualifiedName,
    },
  ];
  return httpService.post('data-catalog/conf-level-rules', {
    data,
  });
};

export const deleteIgnoreConfidenceLevel = (id: string) => {
  return httpService.delete(`data-catalog/conf-level-rules/${id}`);
};

export const getDataCatalogTaskByStatusAndSseRoute = (
  status: DataCatalogAsyncOperationStatus,
  sseRoutingKey: string,
) => {
  const query = parseFieldFiltersToSearchQuery(
    [
      { field: 'status', id: status, operator: 'in', value: status },
      { field: 'sse_route', id: sseRoutingKey, operator: 'in', value: sseRoutingKey },
    ],
    Boolean(getApplicationPreference('NEW_QUERY_FILTER_ENABLED')),
  );

  return httpService
    .fetch<FetchDataCatalogTasksResponse>(`data-catalog/tasks?filter=${query}`)
    .then(({ data }) => data.data);
};

export const generateQueryParams = ({ hasSideFilterBeenTriggered, filter, searchQuery }: ActionData) => {
  const paramsObj = {
    filterSelections: '',
    filter: '',
  };

  if (filter?.length > 0 && hasSideFilterBeenTriggered) {
    const optionsToSet = filter.filter(option => option.value);
    paramsObj.filterSelections = JSON.stringify(optionsToSet);
  } else if (searchQuery) {
    paramsObj.filter = searchQuery;
  }

  return paramsObj;
};

export const generateSharingCatalogUrl = (actionData: ActionData, path: string) => {
  const { origin } = window.location;

  const shareUrl = `${origin}${$state.href(path, generateQueryParams(actionData))}`;

  return shareUrl;
};
