import React, { FC, ReactText, useEffect, useState } from 'react';
import {
  BigidBody1,
  PrimaryButton,
  SecondaryButton,
  BigidWizardNavVerticalStepData,
  BigidWizardNavVertical,
  useVerticalWizard,
  BigidLoader,
} from '@bigid-ui/components';
import { BigidGridRow } from '@bigid-ui/grid';
import makeStyles from '@mui/styles/makeStyles';
import { ExplorerGrid } from './ExplorerGrid';
import { notificationService } from '../../../services/notificationService';
import { AutoDiscoveryWizardProfile, AutoDiscoveryViews, WizardSubtexts } from '../AutoDiscoveryWizard';
import { fetchAvailableDsTypes, createScanProfiles } from '../autoDiscoveryWizardServices';
import { generateFilterToolbarConfigMap, ExplorerGridFiltersMap } from './explorerGridFiltersManager';
import BigidWizardDsIcon from '../../../assets/icons/BigidWizardDsIcon.svg';
import { $state } from '../../../services/angularServices';
import { DATA_SOURCES_INIT_VIEWS } from '../../DataSources/DataSources';

const useStyles = makeStyles({
  viewContainer: {
    display: 'flex',
    paddingTop: 30,
    width: '100%',
    height: '100%',
    flexDirection: 'column',
  },
  topArea: {
    paddingBottom: 30,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  buttonsContainer: {
    '& button': {
      margin: 5,
    },
    '& button:first-child': {
      marginLeft: 0,
    },
    '& button:last-child': {
      marginRight: 0,
    },
  },
  mainArea: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    height: 'calc(100% - 76px)',
  },
  wizardContainer: {
    width: '10%',
    minWidth: '185px',
    '& svg path': {
      strokeWidth: '0',
    },
  },
  gridContainer: {
    width: '90%',
    height: '100%',
  },
});

export interface WizardDataSourceItem extends BigidWizardNavVerticalStepData {
  type: string;
}

export interface AutoDiscoveryExplorerProps {
  profiles: Map<string, AutoDiscoveryWizardProfile>;
  updateProfile: (key: string, value: AutoDiscoveryWizardProfile) => void;
  updateWizardStage: (value: AutoDiscoveryViews) => void;
  wizardSubtexts: WizardSubtexts;
  autoDiscoveryProfileDesc: string;
  autoDiscoveryRunCount: number;
}

export const AutoDiscoveryExplorer: FC<AutoDiscoveryExplorerProps> = ({
  profiles,
  updateProfile,
  updateWizardStage,
  wizardSubtexts,
  autoDiscoveryProfileDesc,
  autoDiscoveryRunCount,
}) => {
  const { viewContainer, topArea, buttonsContainer, mainArea, wizardContainer, gridContainer } = useStyles({});
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [dataSourcesItems, setDataSourcesItems] = useState<WizardDataSourceItem[]>([]);
  const [currentIdSelection, setCurrentIdSelection] = useState<ReactText[]>([]);
  const [currentRows, setCurrentRows] = useState<BigidGridRow[]>([]);
  const [gridFiltersMap, setGridFiltersMap] = useState<ExplorerGridFiltersMap>(new Map());
  const { steps, setActiveStepId, uptateStepAttributes, activeStep, activeStepId, goToNext, goToPrev, noNext, noPrev } =
    useVerticalWizard(dataSourcesItems, '');

  const updateNumberOfSelectedDs = (numOfSelectedDs: number) => {
    const newSubText = numOfSelectedDs ? `${numOfSelectedDs} Selected` : '';
    if (!activeStep.subtext || activeStep.subtext !== newSubText) {
      uptateStepAttributes(activeStepId, { subtext: newSubText });
    }
  };

  const onSelectionHandler = (selectedRowIds: ReactText[], rows: BigidGridRow[]) => {
    updateNumberOfSelectedDs(selectedRowIds.length);
    setCurrentIdSelection(selectedRowIds);
    setCurrentRows(rows);
  };

  const saveToUserSelections = () => {
    const selectedDsNames: string[] = [];
    const scanProfileName = `${activeStep.title} ${autoDiscoveryProfileDesc} ${autoDiscoveryRunCount}`;
    const scanType = activeStepId === 's3' ? 'hyper_scan' : 'dsScan';
    if (currentIdSelection.length) {
      currentIdSelection.forEach(id => {
        const row: any = currentRows.find(row => row._id === id);
        if (row && row.name) {
          selectedDsNames.push(row.name);
        }
      });
    }
    updateProfile(activeStepId, {
      selectedIds: currentIdSelection,
      selectedDsNames,
      scanProfileName,
      scanType,
      isScanRunning: false,
      dsType: activeStepId,
      subtext: currentIdSelection.length ? `${currentIdSelection.length} Selected` : '',
    });
  };

  const onFinishClick = () => {
    setIsLoading(true);
    saveToUserSelections();
    createScanProfiles(profiles)
      .then(() => updateWizardStage(AutoDiscoveryViews.SUMMARY))
      .catch(error => {
        console.error(error);
      })
      .finally(() => setIsLoading(false));
  };

  const onStepClick = (id: string) => {
    saveToUserSelections();
    setActiveStepId(id);
  };

  const onNavigationClick = (event: React.MouseEvent) => {
    saveToUserSelections();
    const input = event.target as HTMLElement;
    if (input.innerText === 'Back') {
      goToPrev();
    } else {
      goToNext();
    }
  };

  const newIds = profiles.get(activeStepId) ? profiles.get(activeStepId).selectedIds : [];

  useEffect(() => {
    Promise.all([fetchAvailableDsTypes(), generateFilterToolbarConfigMap()])
      .then(([dsTypes, filtersConfigMap]) => {
        if (filtersConfigMap && dsTypes?.length) {
          const dataSources: WizardDataSourceItem[] = [];
          dsTypes.forEach(ds => {
            dataSources.push({
              title: ds.displayName,
              subtext: wizardSubtexts[ds.type] ? wizardSubtexts[ds.type] : '',
              type: ds.type,
              id: ds.type,
              icon: BigidWizardDsIcon,
            });
          });
          setDataSourcesItems(dataSources);
          setActiveStepId(dataSources[0].id);
          setGridFiltersMap(filtersConfigMap);
          setIsLoading(false);
        } else {
          throw new Error("Error. Can't run the Auto Discovery Wizard.");
        }
      })
      .catch(e => {
        notificationService.error(e.message);
        $state.go('dataSourcesInit', { path: DATA_SOURCES_INIT_VIEWS.smallIdInit.path });
      });
  }, [setActiveStepId, wizardSubtexts]);

  return (
    <div className={viewContainer}>
      {!isLoading ? (
        <React.Fragment>
          <div className={topArea}>
            <BigidBody1>Select {activeStep.title} data sources that you wish to scan.</BigidBody1>
            <div className={buttonsContainer}>
              <SecondaryButton
                dataAid="AutoDiscoveryWizardNavButtons-back"
                disabled={noPrev}
                size="large"
                onClick={onNavigationClick}
                text="Back"
              />
              <SecondaryButton
                dataAid="AutoDiscoveryWizardNavButtons-next"
                disabled={noNext}
                size="large"
                onClick={onNavigationClick}
                text="Next"
              />
              <PrimaryButton
                color="purple"
                size="large"
                onClick={onFinishClick}
                dataAid="AutoDiscoveryWizardNavButtons-finish"
                text="Finish"
              />
            </div>
          </div>
          <div className={mainArea}>
            <div className={wizardContainer}>
              <BigidWizardNavVertical onSelect={onStepClick} activeId={activeStepId} steps={steps} />
            </div>
            <div className={gridContainer}>
              <ExplorerGrid
                preSelectedIds={newIds}
                onSelection={onSelectionHandler}
                dataSourceName={activeStepId}
                gridFilters={gridFiltersMap}
              />
            </div>
          </div>
        </React.Fragment>
      ) : (
        <BigidLoader />
      )}
    </div>
  );
};
