import { BigidFormField, BigidFormValues } from '@bigid-ui/components';
import { DataSourceConnectionFormField, DataSourceConnectionTemplateField, DsTypesEnum } from '../types';
import { fieldsToRender } from './dataSourceBulkUpdateConstants';
import { BulkUpdateDataSourceFormField } from './DataSourceBulkUpdateContext';

export const isBooleanField = (type: DsTypesEnum) => type === DsTypesEnum.boolean || type === DsTypesEnum.stringBoolean;

export const prepareFieldsForGeneration = (fieldsConfig: DataSourceConnectionTemplateField[]) => {
  return fieldsConfig.map(({ mandatory, mandatoryForTest, ...rest }: DataSourceConnectionTemplateField) => ({
    mandatory: false,
    mandatoryForTest: false,
    ...rest,
  }));
};

export const getFieldsToDisplay = (generatedFields: BigidFormField[]): DataSourceConnectionFormField[] => {
  return generatedFields.filter(
    field => field.misc.isAuthenticationField || fieldsToRender.includes(field.name),
  ) as DataSourceConnectionFormField[];
};

export const getInitialFormValues = (fields: BigidFormField[]): BigidFormValues => {
  let initialValues: BigidFormValues = {};
  fields.map(({ name, misc }) => {
    if (isBooleanField(misc.type)) {
      const value = { [name]: false };
      initialValues = { ...initialValues, ...value };
    }
  });

  return initialValues;
};

export const getValuesToSubmit = (
  normalizedFieldValues: BigidFormValues,
  fieldsToSubmit: BulkUpdateDataSourceFormField[],
) => {
  return Object.keys(normalizedFieldValues)
    .filter(name => fieldsToSubmit.find(({ misc }) => misc.apiName === name))
    .reduce((fieldsToSend: BigidFormValues, fieldName) => {
      fieldsToSend[fieldName] = normalizedFieldValues[fieldName];
      return fieldsToSend;
    }, {});
};
