import { BigidSelect, BigidSelectOption, BigidSelectFieldState, BigidSelectProps } from '@bigid-ui/components';
import { FormControl, FormLabel } from '@mui/material';
import React, { FC, useEffect, useState } from 'react';
import { rolesService } from '../../../../services/angularServices';
import { DateISO8601 } from '../../../../types/types';

export interface Scope {
  id: string;
  name: string;
  updated_at?: DateISO8601;
  created_at?: DateISO8601;
  dataSourceNames?: string[];
  idSourceNames?: string[];
  description?: string;
}

export interface UserScopesSelectorProps {
  title?: string;
  placeholder?: string;
  initialIds?: string[];
  errorMsg?: string;
  isDisabled?: boolean;
  onChange: (scopes: Scope[]) => void;
}

export const UserScopesSelector: FC<UserScopesSelectorProps> = ({
  title = 'Profile Scopes',
  placeholder = 'Select Scopes',
  initialIds,
  errorMsg,
  isDisabled,
  onChange,
}) => {
  const [scopes, setScopes] = useState<Scope[]>([]);
  const [options, setOptions] = useState<BigidSelectOption[]>([]);
  const [selectedScopes, setSelectedScopes] = useState<BigidSelectOption[]>([]);

  const handleSelectOnChange = (options: BigidSelectOption[]) => {
    const selectedIds = new Set(options.map(({ value }) => value));
    onChange(scopes.filter(({ id }) => selectedIds.has(id)));
  };

  useEffect(() => {
    const loadOptions = async () => {
      const scopes = await getScopes();
      const options: BigidSelectOption[] = scopes.map(scope => ({
        label: scope.name,
        value: scope.id,
        subLabel: scope.description,
        id: scope.id,
        idDisabled: true,
        disabled: true,
      }));
      setScopes(scopes);
      setOptions(options);
    };
    loadOptions();
  }, []);

  useEffect(() => {
    if (initialIds?.length) {
      setSelectedScopes(options.filter(({ value }) => initialIds.includes(value)));
    }
  }, [initialIds, options]);

  const errorState: Partial<BigidSelectProps> = errorMsg
    ? {
        fieldState: BigidSelectFieldState.error,
        message: errorMsg,
      }
    : {};

  return (
    <FormControl fullWidth style={{ minHeight: 90 }}>
      <FormLabel style={{ marginTop: 5 }}>{title}</FormLabel>
      <BigidSelect
        options={options}
        value={selectedScopes}
        menuPosition="fixed"
        name={'scopes'}
        isDisabled={isDisabled}
        placeholder={placeholder}
        onChange={handleSelectOnChange}
        isSearchable
        isClearable
        isMulti
        {...errorState}
        dataAid={'UserScopesSelector'}
      />
    </FormControl>
  );
};

const getScopes = async () => {
  const {
    data: { scopes },
  } = await rolesService.getRBACScopes();
  return scopes as Scope[];
};
