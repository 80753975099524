import { useEffect, useRef, useState } from 'react';
import { BigidFormStateAndHandlers, BigidFormValues, PrimaryButton, SecondaryButton } from '@bigid-ui/components';
import { isNil, isNull, noop, uniqueId } from 'lodash';
import { notificationService } from '../../../../services/notificationService';
import { useLocalTranslation } from '../translations';
import { ForwardFormRef, CertificateGridRow } from '../types';
import { BigidLayoutMasterDetailsEvent, BigidLayoutMasterDetailsGridEvents, useLayout } from '@bigid-ui/layout';
import { BigidGridRow } from '@bigid-ui/grid';
import { generateDataAid } from '@bigid-ui/utils';
import { BigidSystemDialogOptions, openSystemDialog } from '../../../../services/systemDialogService';
import { CertificatesManagementDialogContent } from '../CertificatesManagementDialogContent';
import {
  CertificatePostModel,
  CertificateType,
  CertificateUsage,
  createCertificate,
  deleteCertificates,
  updateCertificate,
} from '../../CertificatesManagement';
import { getPermissions, normalizeFormData } from '../utils';
interface UseFormActions {
  row: CertificateGridRow;
  layout: ReturnType<typeof useLayout<CertificateGridRow>>;
  certificatesFormRef: React.MutableRefObject<ForwardFormRef>;
}

interface ShowUnsavedChangesDialog {
  onClickCancel: () => void;
  onClickSave: () => Promise<void>;
}

export const useFormActions = ({ row, layout, certificatesFormRef }: UseFormActions) => {
  const [isBusy, setIsBusy] = useState(false);
  const pendingSearchTerm = useRef(null);
  const selectedRowNameRef = useRef(null);
  const formControls = useRef<BigidFormStateAndHandlers>();
  const { isEditPermitted } = getPermissions();
  const { t } = useLocalTranslation();

  const { refresh, addRow, deleteRow, setSelectedRowById, clearSearch, emit, rows, EmitterType, isLoading, apiRef } =
    layout;

  useEffect(() => {
    if (!isLoading) {
      apiRef.current.clearVirtualTabelCache?.();
      const hasPendingSearch = !isNull(pendingSearchTerm.current);
      const selectedRow = selectedRowNameRef.current
        ? rows?.find(({ name }) => name === selectedRowNameRef.current)
        : false;
      const firstRowId = rows.length && [rows[0].id];
      const selectedId = selectedRow && [selectedRow.id];

      hasPendingSearch &&
        emit(EmitterType.LAYOUT, BigidLayoutMasterDetailsGridEvents.SEARCH_STATE_CHANGE, pendingSearchTerm.current);
      setSelectedRowById(selectedId || firstRowId || []);

      selectedRowNameRef.current = null;
      pendingSearchTerm.current = null;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading]);

  const createNewGridRow = (): string => {
    const rowId = uniqueId('');
    const row: CertificateGridRow = {
      name: t('message.newCertificate'),
      password: '',
      user: '',
      type: CertificateType.P12,
      usage: CertificateUsage.JAVA_KEYSTORE,
      cert: undefined as File,
      configFile: undefined as File,
      id: rowId,
      isPending: true,
    };
    addRow(row);
    return rowId;
  };

  const resetForm = () => {
    formControls.current?.clear();
    formControls.current?.setAllTouched(false);
  };

  const handleSubmit = (shouldRefreshGrid = true) =>
    new Promise<void>(resolve => {
      formControls.current?.validateAndSubmit(
        async (values: BigidFormValues) => {
          try {
            const { isPending, id } = row ?? {};
            const isNameDuplicated = rows.find(({ name: existingName }) => existingName === values.name);
            const isMissingFile = isNil(values.cert);
            if (isPending && isNameDuplicated) {
              formControls.current?.setFieldError('name', t('message.duplicatedName'));
              return;
            }
            if (isPending && isMissingFile) {
              notificationService.error(t('message.fileMissing'));
              return;
            }

            setIsBusy(true);
            const data = normalizeFormData(values);
            if (isPending) {
              await createCertificate(data as CertificatePostModel);
            } else {
              const { type, usage, password, cert, configFile } = data;
              await updateCertificate({ id, type, usage, password, cert, configFile });
            }

            const hasPendingSearch = !isNull(pendingSearchTerm.current);
            const prevSelectedRowNameRef = selectedRowNameRef.current;
            formControls.current?.setAllTouched(false);
            selectedRowNameRef.current = hasPendingSearch ? null : prevSelectedRowNameRef ?? data.name;
            notificationService.success(
              isPending ? t('message.createdSuccessfully') : t('message.updatedSuccessfully'),
              {
                shouldCloseOnTransition: false,
              },
            );
            shouldRefreshGrid && refresh();
            resolve();
          } catch ({ data }) {
            const message = typeof data === 'string' ? data : t('message.commonError');
            notificationService.error(message);
            pendingSearchTerm.current = null;
            selectedRowNameRef.current = null;
          } finally {
            setIsBusy(false);
          }
        },
        () => {
          selectedRowNameRef.current = null;
          return certificatesFormRef.current?.formElement.scrollTo({ top: 0, behavior: 'smooth' });
        },
      );
    });

  const handleDelete = async () => {
    try {
      const { isPending, id } = row;

      if (isPending) {
        resetForm();
        deleteRow(row);
        formControls.current.formTouched = false;
        const remainingRows = rows.filter(({ id }) => id !== row.id);
        if (remainingRows.length) {
          setSelectedRowById([remainingRows[0].id]);
        }
        return;
      }

      setIsBusy(true);
      await deleteCertificates([id as string]);
      notificationService.success(t('message.commonDeleteSuccess'));

      refresh();
    } catch ({ data }) {
      notificationService.error(data?.message ?? t('message.commonError'));
    } finally {
      setIsBusy(false);
    }
  };

  const handleSubmitCancel = () => {
    const { isPending } = row ?? {};
    const hasPendingSearch = !isNull(pendingSearchTerm.current);

    isPending ? deleteRow(row) : resetForm();
    emit(EmitterType.LAYOUT, BigidLayoutMasterDetailsGridEvents.SELECT_STATE_CHANGE, [
      rows?.find(row => row.name === selectedRowNameRef.current)?.id,
    ]);
    hasPendingSearch &&
      emit(EmitterType.LAYOUT, BigidLayoutMasterDetailsGridEvents.SEARCH_STATE_CHANGE, pendingSearchTerm.current);

    pendingSearchTerm.current = null;
    selectedRowNameRef.current = null;
  };

  const configureUnsavedChangesDialog = ({ onClickCancel, onClickSave }: ShowUnsavedChangesDialog) =>
    new Promise<boolean>(resolve => {
      const dialogOptions: BigidSystemDialogOptions = {
        title: t('modal.unsavedChanges.title'),
        onClose: noop,
        maxWidth: 'xs',
        buttons: [
          {
            component: SecondaryButton,
            dataAid: generateDataAid('CertificateManagementFooterAction', ['cancel', 'submit']),
            isClose: true,
            onClick: () => {
              onClickCancel();
              resolve(true);
            },
            text: t('buttons.no'),
          },
          {
            component: PrimaryButton,
            dataAid: generateDataAid('CertificateManagementFooterAction', ['confirm', 'submit']),
            isClose: true,
            disabled: !isEditPermitted,
            onClick: async () => {
              await onClickSave();
              resolve(true);
            },
            text: t('buttons.yes'),
          },
        ],
        content: CertificatesManagementDialogContent,
        contentProps: { body: t('modal.unsavedChanges.body') },
        borderTop: true,
      };

      openSystemDialog(dialogOptions);
    });

  const showUnsavedChangesDialog = () => {
    if (formControls.current?.formTouched && rows?.length !== 0) {
      return configureUnsavedChangesDialog({
        onClickCancel: () => {
          handleSubmitCancel();
          row.isPending && refresh();
        },
        onClickSave: async () => {
          await handleSubmit();
        },
      });
    }
    return Promise.resolve(true);
  };

  const addGridRow = () => {
    const hasPendingRows = rows.find(({ isPending }) => isPending);

    if (hasPendingRows) {
      notificationService.warning(t('message.pendingCertificate'));
      return;
    }

    clearSearch();
    const rowId = createNewGridRow();
    setSelectedRowById([rowId]);
  };

  const handleCertificateAdd = () => {
    if (formControls.current?.formTouched) {
      return configureUnsavedChangesDialog({
        onClickCancel: addGridRow,
        onClickSave: async () => {
          await handleSubmit(false);
          addGridRow();
        },
      });
    }

    addGridRow();
  };

  const handleSelectionChange = ({ selectedRowIds = [] }: BigidLayoutMasterDetailsEvent<BigidGridRow>): boolean => {
    const isDirty = formControls.current?.formTouched;
    const [id] = selectedRowIds;
    const { isPending, id: prevSelectedId } = row ?? {};
    const hasSelectedRow = !!row;
    const shouldChangeSelection = !hasSelectedRow ? true : !isPending && !isDirty && !isBusy;

    if (id === prevSelectedId) {
      return;
    }

    if (!shouldChangeSelection) {
      selectedRowNameRef.current = rows?.find(row => row.id === id)?.name;
      showUnsavedChangesDialog();
    }

    return shouldChangeSelection;
  };

  const handleSearchChange = ({ searchTerm }: BigidLayoutMasterDetailsEvent<BigidGridRow>) => {
    const { isPending, name } = row ?? {};
    const isDirty = formControls.current?.formTouched;
    const shouldChangeSearchTerm = !isPending && !isDirty && !isBusy;

    if (!shouldChangeSearchTerm) {
      selectedRowNameRef.current = name;
      pendingSearchTerm.current = searchTerm;
      showUnsavedChangesDialog();
    }

    return shouldChangeSearchTerm;
  };

  return {
    isBusy,
    formControls,
    handleSubmit,
    handleDelete,
    handleSubmitCancel,
    handleCertificateAdd,
    handleSelectionChange,
    handleSearchChange,
    showUnsavedChangesDialog,
  };
};
