import React, { FC, useRef } from 'react';
import styled from '@emotion/styled';
import {
  BigidBody1,
  BigidDropdownOption,
  BigidForm,
  BigidLoader,
  StyledButtonType,
  BigidFormField,
  BigidFormFieldTypes,
  BigidFormProps,
  BigidFormStateAndHandlers,
  BigidFormValidateOnTypes,
  BigidFormValues,
  PrimaryButton,
} from '@bigid-ui/components';
import { useLocalTranslation } from '../../translations';
import { BigidSendIcon } from '@bigid-ui/icons';
import { isValidEmail } from '../../../../utilities/validation';

const EMAIL_ADDRESS_FIELD_NAME = 'emailAddress';
const BRAND_FIELD_NAME = 'brand';

const Container = styled('div')`
  display: flex;
  flex-direction: column;
  padding: 10px 16px;
  width: 100%;
  min-height: 100px;
  & #BigidDropdown-popper {
    z-index: 10000;
  }
`;

export const FieldWrapper = styled.div`
  display: flex;
  width: 100%;
  margin-bottom: 8px;
`;

export const ButtonWrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row-reverse;
`;

export const ButtonIconArea = styled.div`
  display: flex;
  padding-left: 2px;
`;

export const ButtonTextArea = styled.div`
  display: flex;
  padding-right: 2px;
`;

export const ButtonLoaderWrapper = styled.div`
  display: flex;
  padding-right: 4px;
`;

export interface SendTestEmailProps {
  dataAid?: string;
  brandOptions: BigidDropdownOption[];
  preferredBrand: string;
  currentUser: string;
  onSendTestEmail: (email: string, brand: string) => void;
  isEmailSendingInProgress: boolean;
}

export const SendTestEmailForm: FC<SendTestEmailProps> = ({
  dataAid = 'SendTestEmailForm',
  preferredBrand,
  currentUser,
  brandOptions,
  onSendTestEmail,
  isEmailSendingInProgress,
}) => {
  const testEmailFormControls = useRef<BigidFormStateAndHandlers>();
  const { t } = useLocalTranslation();

  const fields: BigidFormField[] = [
    {
      name: EMAIL_ADDRESS_FIELD_NAME,
      type: BigidFormFieldTypes.TEXT,
      fieldProps: { placeholder: t('testMailSection.emailPlaceholder') },
      validate: (email: string) => {
        if (!isValidEmail(email)) {
          return t('testMailSection.errorMessages.invalidEmail');
        }
        return false;
      },
    },
    ...(brandOptions?.length > 1
      ? [
          {
            name: BRAND_FIELD_NAME,
            type: BigidFormFieldTypes.DROP_DOWN,
            dropDownOptions: brandOptions,
            fieldProps: { placeholder: t('testMailSection.brandPlaceholder'), dropdownPopperZIndex: 10000 },
            validate: (selectedOption: BigidDropdownOption[]) => {
              if (!selectedOption?.length || !selectedOption[0]?.value) {
                return t('testMailSection.errorMessages.missingBrand');
              }
              return false;
            },
          },
        ]
      : []),
  ];

  const generateInitialValues = () => {
    const initialValues: BigidFormValues = { emailAddress: currentUser };
    if (preferredBrand) {
      const preSelectedBrand = brandOptions.find(brand => brand.id === preferredBrand);
      initialValues.brand = [preSelectedBrand];
    }
    return initialValues;
  };

  const testEmailFormProps: BigidFormProps = {
    controlButtons: false,
    validateOn: BigidFormValidateOnTypes.SUBMIT,
    stateAndHandlersRef: testEmailFormControls,
    initialValues: generateInitialValues(),
    displayFormLevelError: true,
    fields,
  };

  const onSendTestEmailClick = () => {
    testEmailFormControls.current.validateAndSubmit(values => {
      onSendTestEmail(values[EMAIL_ADDRESS_FIELD_NAME], values[BRAND_FIELD_NAME]?.[0]?.value);
    });
  };

  const sendButtonProps: StyledButtonType = {
    size: 'medium',
    disabled: isEmailSendingInProgress,
    onClick: onSendTestEmailClick,
    bi: { disabled: true },
  };

  return (
    <Container data-aid={dataAid}>
      <BigidForm {...testEmailFormProps} />
      {brandOptions.length > 1 && (
        <BigidBody1 size="small" paddingBottom="8px">
          {t('testMailSection.brandInfo')}
        </BigidBody1>
      )}
      <ButtonWrapper>
        <PrimaryButton {...sendButtonProps}>
          <ButtonIconArea>
            {isEmailSendingInProgress ? (
              <ButtonLoaderWrapper>
                <BigidLoader position="relative" color="#B6B6B6" thickness={2} size={16} />
              </ButtonLoaderWrapper>
            ) : (
              <BigidSendIcon />
            )}
          </ButtonIconArea>
          <ButtonTextArea>
            {isEmailSendingInProgress ? t('buttonLables.sendInProgress') : t('buttonLables.send')}
          </ButtonTextArea>
        </PrimaryButton>
      </ButtonWrapper>
    </Container>
  );
};
