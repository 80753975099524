import { ReactText } from 'react';
import { httpService } from '../../../../../../services/httpService';
import { getEncodedArray } from '../../../../../../services/queryService';
import { omit } from 'lodash';
import { getApplicationPreference } from '../../../../../../services/appPreferencesService';
import { DateISO8601 } from '../../../../../../types/types';

const ENABLE_LEGACY_STRUCTURED_PREVIEW = 'ENABLE_LEGACY_STRUCTURED_PREVIEW';

export interface DataCatalogPreviewTableField {
  fieldName: string;
  fieldValue: string;
  fieldType: string;
}

export interface DataCatalogPreviewTableDataPayload {
  fullyQualifiedName: string;
  limit: number;
}

export interface DataCatalogPreviewTableDataResponse {
  amountOfFields: number;
  results: DataCatalogPreviewTableField[][];
}

export interface AssociatedColumn {
  column_name: string;
  rank: string;
  calc_confidence_level: number;
}

export interface Category {
  _id: string;
  display_name: string;
  unique_name: string;
  color: string;
  glossary_id: string;
}

export interface DataCatalogAttribute {
  fullyQualifiedName: string;
  attribute_name: string;
  attribute_original_name: string;
  column_list: AssociatedColumn[];
  categories: Category[];
  description: string;
  business_flow: string[];
  is_support_investigation: boolean;
  is_expired: boolean;
  investigation_scan_id_list: string[];
  attribute_type: string;
  attribute_original_type: string;
  attribute_id: string;
}

export interface DataCatalogPreviewTableRecord {
  id: string;
  [fieldName: string]: string;
}

export interface DsObjectPreviewTableProps extends Partial<DataCatalogRecord> {
  filteredColumn: string;
  handleScanStatus: (value: boolean) => void;
  dataAid: string;
  handleVisibilityCacheButton: (value: boolean) => void;
}

export enum FetchStatus {
  FETCHED_FROM_CACHE = 'FETCHED_FROM_CACHE',
  FETCHED_FROM_REQUEST = 'FETCHED_FROM_REQUEST',
  FETCHED_SUCCESS = 'FETCHED_SUCCESS',
  FETCHED_FAILURE = 'FETCHED_FAILURE',
}

export type ObjectAttributeDetails = {
  name: string;
  count: number;
  type: string[];
  ranks: string[];
};

export interface CollaborationStatus {
  canOpenIssue: boolean;
}

export interface DataCatalogObjectDetails {
  type: string;
  modified_date: DateISO8601;
  lastUpdatedBy?: string;
  dataSourceName: string;
  ds_location: string;
  num_identities: number;
  fullyQualifiedName: string;
  owner: string;
  scannerType: string;
  objectName: string;
  fullObjectName: string;
  scanner_type_group: string;
  sizeInBytes: number;
  scanDate: number;
  update_date: DateISO8601;
  ds: any[];
  language: string;
  branchName: string;
  reporter: string;
  reported_date: DateISO8601;
  cluster_id?: string;
  duplicate_id?: string;
  cluster_name?: string;
  document_type?: string[];
  was_scanned?: boolean;
  scanStatus?: ScanStatus;
  tags?: TagEntity[];
  messageLink?: string;
  object_owners_struct?: DataOwnerEntity[];
  collaborationStatus: CollaborationStatus;
  tableTotalRows?: number;
  columnOrFieldOccurrencesCounter?: object[];
  attribute_details?: ObjectAttributeDetails[];
  created_date?: DateISO8601;
  createdBy?: string;
  last_opened?: DateISO8601;
  lastAccessedBy?: string;
  isConfidenceLevelIgnored?: boolean;
  extendedObjectType?: DataCatalogObjectType;
}

export interface DsObjectPreviewFileProps {
  fullyQualifiedName: string;
  scannerType: string;
  source: string;
  filteredAttribute: string;
  handleScanStatus: (value: boolean) => void;
  handleSegmnetsExists: (value: boolean) => void;
  caseId: string;
  objectPreviewData: DataCatalogObjectDetails | any;
  handleVisibilityCacheButton: (value: boolean) => void;
  dataAid: string;
}

export type PreviewContentStatus = 'Completed' | 'InProgress' | 'Failed';

export enum ScanStatus {
  COMPLETED = 'Completed',
  FAILED = 'Failed',
  METADATACOMPLETED = 'MetadataCompleted',
  INPROGRESS = 'InProgress',
}

export interface DataOwnerEntity {
  email: string;
  fullName?: string;
}

type SystemAttributeType =
  | 'IDSoR Attribute'
  | 'Enrichment Attribute'
  | 'Classification'
  | 'Composite Fields'
  | 'Manual'
  | 'OOTB';

export enum DataCatalogObjectType {
  TABLE = 'rdb',
  TABLE_VIEW = 'rdb-view',
  FILE = 'file',
  STRUCTURED_LEAF = 'STRUCTURED-LEAF_DATA_OBJECT',
  STRUCTURED_FILE_LEAF = 'STRUCTURED_FILE-LEAF_DATA_OBJECT',
  UNSTRUCTURED_LEAF = 'UNSTRUCTURED-LEAF_DATA_OBJECT',
  PARTITIONED_TABLE_LEAF = 'PARTITIONED_TABLE-LEAF_DATA_OBJECT',
  APP_LEAF = 'APP-LEAF_DATA_OBJECT',
}

enum DetailedObjectType {
  STRUCTURED = 'STRUCTURED',
  STRUCTURED_FILE = 'STRUCTURED_FILE',
  UNSTRUCTURED = 'UNSTRUCTURED',
  PARTITIONED_TABLE = 'PARTITIONED_TABLE',
  APP = 'APP',
}

enum HierarchyType {
  CONTAINER = 'CONTAINER',
  SUB_CONTAINER = 'SUB_CONTAINER',
  LEAF_DATA_OBJECT = 'LEAF_DATA_OBJECT',
}

enum DataCatalogRecordScannerTypeGroup {
  STRUCTURED = 'structured',
  UNSTRUCTURED = 'unstructured',
  EMAIL = 'email',
}

enum TagAssignmentTarget {
  object = 'OBJECT',
  column = 'COLUMN',
}

enum TagApplicationType {
  USER_DEFINED = 'userDefined',
  SENSITIVITY_CLASSIFICATION = 'sensitivityClassification',
  RISK = 'risk',
}

class TagProperties {
  applicationType?: TagApplicationType;
  hidden?: boolean;
  isExplicit?: boolean;
  displayName?: string;
  explicitValueType?: 'number' | 'string' | 'date';
}

export interface TagEntity {
  tagName?: string;
  tagValue?: string;
  tagId: string;
  valueId: string;
  tagType?: TagAssignmentTarget;
  isMutuallyExclusive?: boolean;
  properties?: TagProperties;
}

export interface DataCatalogRecord {
  id: ReactText;
  source: string;
  type: string;
  objectName: string;
  objectId: string;
  scannerType: string;
  containerName?: string;
  containerId?: string;
  subContainerName?: string;
  subContainerId?: string;
  objectType?: DataCatalogObjectType;
  extendedObjectType?: DataCatalogObjectType;
  detailedObjectType?: DetailedObjectType;
  hierarchyType?: HierarchyType;
  fullyQualifiedName: string;
  fullObjectName: string;
  attribute?: string[];
  application_name?: string[];
  open_access: 'Yes' | 'No';
  scanner_type_group?: DataCatalogRecordScannerTypeGroup;
  total_pii_count?: number;
  owner?: string;
  modified_date?: DateISO8601;
  duplicate_id?: string;
  cluster_id?: string;
  fileId?: string;
  has_duplicates: 'Yes' | 'No';
  tags?: TagEntity[];
  isPermittedToPreview?: boolean;
}

export interface InvestigationResponse {
  data: ContentCache;
}

export interface InvestigateError {
  message: string;
}

export interface ChunkData {
  content?: string;
  attributes?: FileContentAttribute[];
}

export interface ScanInvestigationResponse {
  id: string;
  state: PreviewContentStatus;
  page: number;
  page_details?: ChunkData;
  next_page?: number;
  error?: InvestigateError;
}

export interface FileContentAttribute {
  label: string;
  offset: number;
  length: number;
  allLabels?: string[];
  attributes: AttributesDetails[];
}

interface AttributesDetails {
  attributeName: string;
  attributeType: SystemAttributeType;
}

export interface FileTextContents {
  content?: string;
  attributes?: FileContentAttribute[];
}

export interface ContentCache {
  next_page?: number;
  error?: InvestigateError;
  pages: FileTextContents[];
  with_attributes?: boolean;
  attributeTypes?: AttributeType[];
}

export enum AttributeType {
  CLASSIFIER = 'Classification',
  IDSOR_ATTRIBUTE = 'IDSoR Attribute',
}

type ScanFileContentChunkPayload = {
  scanType: string;
  fullyQualifiedName: string;
  with_attributes: boolean;
  page?: number;
  attributeTypes?: AttributeType[];
  attribute_names?: string[];
};

export const previewFileContentService = {
  getDetailsByObjectName: (fullyQualifiedName: string) => {
    const fullyQualifiedNameParsed = encodeURIComponent(fullyQualifiedName);
    return httpService
      .fetch<{ data: DataCatalogRecord }>(`data-catalog/object-details`, { object_name: fullyQualifiedNameParsed })
      .then(({ data }) => data);
  },
  getObjectCachedContent: (
    investigationId: string,
    requestAttributes: boolean,
    attributeTypes: AttributeType[],
    attributeFilter: string,
  ) => {
    let query = `?with_attributes=${requestAttributes}`;

    if (attributeTypes.length > 0) {
      query += `&attributeTypes=${getEncodedArray(attributeTypes || [])}`;
    }

    if (attributeFilter?.length > 0) {
      query += `&attributes_names[]=${encodeURIComponent(attributeFilter)}`;
    }

    return httpService
      .fetch(`data-catalog/preview/investigation/${investigationId}${query}`)
      .then(({ data }) => data.data);
  },
  deleteObjectCachedContent: (fullyQualifiedName: string) => {
    return httpService
      .delete(`data-catalog/preview/${encodeURIComponent(fullyQualifiedName)}`)
      .then(({ data }) => data);
  },
  getRequestAttributesValue: (fullyQualifiedName: string, attributeTypes: AttributeType[]) => {
    let query = '';

    if (attributeTypes.length > 0) {
      query = `?attributeTypes=${getEncodedArray(attributeTypes || [])}`;
    }

    return httpService
      .fetch(`data-catalog/preview/${encodeURIComponent(fullyQualifiedName)}${query}`)
      .then(({ data }) => data.data.state);
  },
  updateRequestAttributesValue: (
    fullyQualifiedName: string,
    with_attributes: boolean,
    attributeTypes: AttributeType[],
  ) => {
    return httpService
      .post(`data-catalog/preview/${encodeURIComponent(fullyQualifiedName)}`, {
        with_attributes,
        attributeTypes,
      })
      .then(({ data }) => data);
  },
  getCachedContentObject: (fullyQualifiedName: string, attributeTypes: AttributeType[], attributeFilter: string) => {
    let query = '';

    if (attributeTypes.length > 0) {
      query = `?attributeTypes=${getEncodedArray(attributeTypes || [])}`;
    }

    if (attributeFilter?.length > 0) {
      query += `?attributes_names[]=${encodeURIComponent(attributeFilter)}`;
    }

    return httpService
      .fetch<InvestigationResponse>(`data-catalog/preview/${encodeURIComponent(fullyQualifiedName)}${query}`)
      .then(({ data }) => data.data);
  },
  scanFileContentChunk: (payload: ScanFileContentChunkPayload) => {
    const propsToOmit: Array<keyof ScanFileContentChunkPayload> = [];

    if (!payload.page) {
      propsToOmit.push('page');
    }

    if (payload.attributeTypes.length === 0) {
      propsToOmit.push('attributeTypes');
    }

    return httpService.post('scans', omit(payload, propsToOmit)).then(({ data }) => data);
  },
};

export const getPreviewTableData = (data: DataCatalogPreviewTableDataPayload) => {
  if (getApplicationPreference(ENABLE_LEGACY_STRUCTURED_PREVIEW)) {
    return httpService
      .post<{ data: DataCatalogPreviewTableDataResponse }, DataCatalogPreviewTableDataPayload>(
        `data-catalog/table-preview`,
        data,
      )
      .then(({ data }) => data);
  }
  return httpService
    .post<{ data: DataCatalogPreviewTableDataResponse }, DataCatalogPreviewTableDataPayload>(
      `data-catalog/structured-preview`,
      data,
    )
    .then(({ data }) => data);
};

export const getPreviewTableDataCached = (fullyQualifiedName: string) => {
  fullyQualifiedName = encodeURIComponent(fullyQualifiedName);

  if (getApplicationPreference(ENABLE_LEGACY_STRUCTURED_PREVIEW)) {
    return httpService
      .fetch<{ data: DataCatalogPreviewTableDataResponse }>(`data-catalog/table-preview/${fullyQualifiedName}`)
      .then(({ data }) => data);
  }

  return httpService
    .fetch<{ data: DataCatalogPreviewTableDataResponse }>(`data-catalog/structured-preview/${fullyQualifiedName}`)
    .then(({ data }) => data);
};

export const deletePreviewTableData = (objectName: string) => {
  if (getApplicationPreference(ENABLE_LEGACY_STRUCTURED_PREVIEW)) {
    return httpService.delete(`data-catalog/table-preview/${encodeURIComponent(objectName)}`).then(({ data }) => data);
  }
  return httpService
    .delete(`data-catalog/structured-preview/${encodeURIComponent(objectName)}`)
    .then(({ data }) => data);
};
