import { DataSource } from '../../../../utilities/dataSourcesUtils';
import { useCallback, useEffect, useRef, useState } from 'react';
import {
  addDataSourceNameToTypeMap,
  getSupportedScanTypesByDsNames,
  useGetDataSourceTypeToSupportedScansMap,
} from '../../../Scans/ScanCreateWizard/hooks/useUpdateSupportedScanTypesBySelectedDs';
import { DataSourceModel } from '../DataSourceConnectionTypes';
import { ScanTypes } from '../../../Scans/ScanTemplates/scanTemplateTypes';
import { dataSourceInfoForMap } from '../../../Scans/ScanCreateWizard/createScanContext';

export const useSupportedScanTypesForSelectedDs = (
  supportedDataSources: DataSource[],
  preselectedDataSourceInfo: dataSourceInfoForMap[],
) => {
  const [initialValuesLoaded, setInitialValuesLoaded] = useState(false);
  const dataSourceNameToTypeMapRef = useRef({});
  const supportedScansMap = useGetDataSourceTypeToSupportedScansMap(supportedDataSources);
  const supportedScansMapRef = useRef(supportedScansMap);
  supportedScansMapRef.current = supportedScansMap;
  const disabledDataSourcesRef = useRef<Record<string, boolean>>({});
  const archivedDataSourcesRef = useRef<Record<string, boolean>>({});

  const setDisabledAndArchivedDataSources = useCallback((dataSources: DataSourceModel[]) => {
    dataSources.forEach(({ name, enabled, archived }) => {
      const isWasDisabled = disabledDataSourcesRef.current[name];
      const isWasArchived = archivedDataSourcesRef.current[name];
      disabledDataSourcesRef.current = {
        ...disabledDataSourcesRef.current,
        ...(enabled !== 'yes' ? { [name]: true } : isWasDisabled ? { [name]: false } : {}),
      };
      archivedDataSourcesRef.current = {
        ...archivedDataSourcesRef.current,
        ...(archived ? { [name]: true } : isWasArchived ? { [name]: false } : {}),
      };
    });
  }, []);
  const [supportedScanTypesForSelectedDs, setSupportedScanTypesForSelectedDs] = useState<ScanTypes[]>([]);

  const updateSupportedScanTypes = useCallback((dataSourcesIds: string[]) => {
    if (dataSourcesIds.some(id => archivedDataSourcesRef.current[id])) {
      return [];
    }
    const isDisabledSelected = dataSourcesIds.some(id => disabledDataSourcesRef.current[id]);
    const scanTypes = getSupportedScanTypesByDsNames(
      { dataSourceNameToTypeMap: dataSourceNameToTypeMapRef.current, dataSourcesIds },
      supportedScansMapRef.current,
      isDisabledSelected,
      false,
    );
    setSupportedScanTypesForSelectedDs(scanTypes);

    return scanTypes;
  }, []);

  useEffect(() => {
    if (!initialValuesLoaded && preselectedDataSourceInfo?.length && supportedScansMap) {
      const ids = preselectedDataSourceInfo?.map(({ name }) => name);
      dataSourceNameToTypeMapRef.current = { ...preselectedDataSourceInfo?.reduce(addDataSourceNameToTypeMap, {}) };
      // TODO: Add Archived value to preselectedDataSourceIds, currently its undefined
      setDisabledAndArchivedDataSources(preselectedDataSourceInfo as DataSourceModel[]);
      updateSupportedScanTypes(ids);
      setInitialValuesLoaded(true);
    }
  }, [supportedScansMap, preselectedDataSourceInfo, initialValuesLoaded]);

  return {
    updateSupportedScanTypes,
    dataSourceNameToTypeMapRef,
    supportedScanTypesForSelectedDs,
    setDisabledAndArchivedDataSources,
  };
};
