import { useMutation, UseMutationResult, MutationFunction, UseMutationOptions } from 'react-query';
import { ErrorType, BodyType } from '../../../../config/request';
import {
  type DeleteDsCollaboratorItemResponse,
  type DeleteDsCollaboratorItemRequest,
  type DataSourceCollaborator,
  type AsyncReturnType,
  CollaborationEventType,
} from '../types';
import { deleteDsCollaborator, API_VERSION, DS_COLLABORATOR_ENDPOINT } from '../services/collaboration';

export type DeleteDsCollaboratorQueryResult = NonNullable<AsyncReturnType<typeof deleteDsCollaborator>>;

export type DeleteDsCollaboratorQueryError = ErrorType<unknown>;

export const deleteDsCollaboratorMutationKey = (
  dataSource: string,
  collaborators: DataSourceCollaborator[],
): Array<unknown> => [
  CollaborationEventType.DELETE_COLLABORATORS,
  `${API_VERSION}/${DS_COLLABORATOR_ENDPOINT}/${dataSource}`,
  ...(collaborators ? [collaborators] : []),
];

export const useDeleteDsCollaborator = <TError = DeleteDsCollaboratorQueryError, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    AsyncReturnType<typeof deleteDsCollaborator>,
    TError,
    { data: BodyType<DeleteDsCollaboratorItemRequest>; dataSource: string },
    TContext
  >;
}): UseMutationResult<
  DeleteDsCollaboratorItemResponse,
  TError,
  { data: BodyType<DeleteDsCollaboratorItemRequest>; dataSource: string },
  TContext
> => {
  const { mutation: mutationOptions } = options || {};
  // eslint-disable-next-line max-len
  const mutationFn: MutationFunction<
    AsyncReturnType<typeof deleteDsCollaborator>,
    { data: BodyType<DeleteDsCollaboratorItemRequest>; dataSource: string }
  > = async props => {
    const { data, dataSource } = props || {};

    return deleteDsCollaborator(dataSource, data);
  };

  return useMutation<
    AsyncReturnType<typeof deleteDsCollaborator>,
    TError,
    { data: BodyType<DeleteDsCollaboratorItemRequest>; dataSource: string },
    TContext
  >(mutationFn, mutationOptions);
};
