import { CustomDashboardConfig, OPERATORS } from '@bigid-ui/custom-dashboard';
import { CONFIG } from '../../../../../config/common';
import { docsUrls } from '../../../../config/publicUrls';

export const fmsdDefaultDashboard: CustomDashboardConfig = {
  data: {
    searchableList: {
      rightSideIcon:
        "<svg xmlns='http://www.w3.org/2000/svg' width='18' height='18' viewBox='0 0 18 18'><defs><style>.aHelpCircle,.bHelpCircle{fill:none;stroke:#358fdb;stroke-linecap:round;stroke-width:2px;}.a{stroke-linejoin:round;}</style></defs><g transform='translate(-1 -1)'><circle class='aHelpCircle' cx='8' cy='8' r='8' transform='translate(2 2)'/><line class='aHelpCircle' x2='0.008' transform='translate(10 6.5)'/><line class='bHelpCircle' y1='4' transform='translate(10 10)'/></g></svg>",
      colors: {
        section: {
          iconColor: '#8C669A',
          iconBackgroundColor: 'rgba(159, 84, 186, 0.1)',
        },
        app: {
          iconColor: 'rgb(255, 94, 67)',
          iconBackgroundColor: 'rgba(255, 94, 67, 0.1)',
        },
      },
    },
  },
  endpoints: [
    {
      id: 'scan_activity_summary',
      api: {
        url: 'api/v1/scan-activity-summary',
        method: 'get',
      },
    },
    {
      id: 'policies_triggered',
      api: {
        url: 'api/v1/complianceSummaries',
        method: 'get',
      },
    },
    {
      id: 'object_summaries',
      api: {
        url: 'api/v1/object-summaries/by-type',
        method: 'get',
      },
    },
    {
      id: 'default_group_sensitivity_classification_ratio',
      api: {
        url: 'api/v1/aci/sc/ratio/name/Sensitivity',
        method: 'get',
      },
    },
    {
      id: 'catalog_sensitivity_levels_undefined',
      api: {
        url: 'api/v1/data-catalog?filter=contains_pi%3Dtrue%20AND%20NIN%20catalog_tag.system.sensitivityClassification.Sensitivity%20IN%20(%20%22High%22%2C%20%22Medium%22%2C%20%22Low%22%20)',
        method: 'get',
      },
    },
    {
      id: 'object_summaries_sorted_by_pii',
      api: {
        url: 'api/v1/sorted-object-summaries-by-ds?len=3&makeOther=true',
        method: 'get',
      },
    },
    {
      id: 'attribute_by_ds',
      api: {
        url: 'api/v1/getAttributesByDs?len=3',
        method: 'get',
      },
    },
    {
      id: 'total_findings_by_top_attribute',
      api: {
        url: 'api/v1/sorted-and-limited-attributes-risks?len=3',
        method: 'get',
      },
    },
  ],
  dataManipulations: [
    {
      id: 'policies_triggered_array',
      setVar: {
        path: 'data.policies_triggered.compliance_summaries',
      },
    },
    {
      id: 'policies_triggered_sum',
      sumFromArray: {
        pathToArray: 'data.policies_triggered_array',
        pathToAttribute: 'count',
      },
    },
    {
      id: 'compliance_summary_fail_item',
      findItemInArray: {
        pathToArray: 'data.policies_triggered_array',
        operation: {
          pathToAttribute: 'id',
          operator: OPERATORS.EQUAL_TO,
          value: 'fail',
        },
      },
    },
    {
      id: 'objects_summary_total_objects_with_pii',
      sumFromArray: {
        pathToArray: 'data.object_summaries.summaryByTypes',
        pathToAttribute: 'totalObjectsWithPii',
      },
    },
    {
      id: 'count_catalog_sensitivity_levels_undefined',
      countItemsOfArray: {
        pathToArray: 'data.catalog_sensitivity_levels_undefined.results',
      },
    },
    {
      id: 'catalog_sensitivity_levels_undefined_array_for_concat',
      createArray: {
        array: [
          {
            name: 'Undefined',
            count: 'data.count_catalog_sensitivity_levels_undefined',
          },
        ],
      },
    },
    {
      id: 'sensitivity_levels_array',
      concatArrays: {
        pathToArrays: [
          'data.default_group_sensitivity_classification_ratio.data.counters',
          'data.catalog_sensitivity_levels_undefined_array_for_concat',
        ],
      },
    },
    {
      id: 'total_findings_count_by_ds',
      filterArray: {
        pathToArray: 'data.object_summaries_sorted_by_pii.summaries',
        operation: {
          pathToAttribute: 'totalObjectsWithPii',
          operator: OPERATORS.GREATER_THAN,
          value: 0,
        },
      },
    },
  ],
  widgets: [
    {
      id: 'highlight_box_scanned_ds',
      header: {
        title: ' ',
      },
      widget: {
        highlightBox: {
          title: {
            if: {
              argument1: '{{data.scan_activity_summary.inventory.dataSourcesInfo.dataSourcesConfigured}}',
              operator: OPERATORS.GREATER_THAN,
              argument2: 0,
            },
            then: {
              return:
                '{{data.scan_activity_summary.inventory.dataSourcesInfo.dataSourcesScanned}} / {{data.scan_activity_summary.inventory.dataSourcesInfo.dataSourcesConfigured}}',
            },
            else: {
              return: '',
            },
          },
          subtitle: {
            if: {
              argument1: '{{data.scan_activity_summary.inventory.dataSourcesInfo.dataSourcesConfigured}}',
              operator: OPERATORS.GREATER_THAN,
              argument2: 0,
            },
            then: {
              return: 'Scanned Data Sources',
            },
            else: {
              return: '',
            },
          },
          svgString:
            "<svg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='33' height='36.419' viewBox='0 0 33 36.419'><defs><style>.BigIdSourcesGradient{fill:url(#BigIdSourcesGradient);}</style><linearGradient id='BigIdSourcesGradient' x2='1' y2='3.149' gradientUnits='objectBoundingBox'><stop offset='0' stop-color='#ee80ee'/><stop offset='1' stop-color='#7f269f'/></linearGradient></defs><g transform='translate(-128.999 -290.887)'><path class='BigIdSourcesGradient' d='M145.5,301.937c-7.947,0-16.5-1.729-16.5-5.525s8.552-5.525,16.5-5.525,16.5,1.729,16.5,5.525S153.445,301.937,145.5,301.937Zm0-8.681c-9.285,0-14.205,2.212-14.261,3.156.048.944,4.96,3.156,14.261,3.156s14.213-2.212,14.262-3.156C159.7,295.468,154.784,293.256,145.5,293.256Z' transform='translate(0 0)'/><path class='BigIdSourcesGradient' d='M145.5,313.406c-6.128,0-16.5-1.163-16.5-5.525a1.121,1.121,0,1,1,2.238,0c0,.917,5,3.156,14.261,3.156s14.262-2.24,14.262-3.156a1.121,1.121,0,1,1,2.238,0C162,312.243,151.627,313.406,145.5,313.406Z' transform='translate(0 1.666)'/><path class='BigIdSourcesGradient' d='M145.5,326.63c-8.2,0-16.5-2.192-16.5-6.38V295.663a1.119,1.119,0,0,1,2.238,0v24.588c0,1.7,5.555,4.158,14.261,4.158s14.262-2.463,14.262-4.158V295.663a1.119,1.119,0,0,1,2.238,0v24.588C162,324.439,153.7,326.63,145.5,326.63Z' transform='translate(0 0.676)'/></g></svg>",
          onClickRedirectUrl: {
            state: {
              if: {
                argument1: '{{data.scan_activity_summary.inventory.dataSourcesInfo.dataSourcesConfigured}}',
                operator: OPERATORS.GREATER_THAN,
                argument2: 0,
              },
              then: {
                return: 'dataCatalog',
              },
              else: {
                return: 'dataSourcesInit',
              },
            },
            data: {
              if: {
                argument1: '{{data.scan_activity_summary.inventory.dataSourcesInfo.dataSourcesConfigured}}',
                operator: OPERATORS.GREATER_THAN,
                argument2: 0,
              },
              then: {
                return: {
                  filter: 'contains_pi=true',
                },
              },
              else: {
                return: {
                  filter: '',
                },
              },
            },
          },
        },
      },
    },
    {
      id: 'highlight_box_scanned_ds_with_findings',
      header: {
        title: ' ',
      },
      widget: {
        highlightBox: {
          title: {
            if: {
              argument1: '{{data.scan_activity_summary.inventory.dataSourcesInfo.dataSourcesConfigured}}',
              operator: OPERATORS.GREATER_THAN,
              argument2: 0,
            },
            then: {
              return:
                '{{data.scan_activity_summary.inventory.dataSourcesInfo.dataSourceWithPiRecords}} / {{data.scan_activity_summary.inventory.dataSourcesInfo.dataSourcesScanned}}',
            },
            else: {
              return: '',
            },
          },
          subtitle: {
            if: {
              argument1: '{{data.scan_activity_summary.inventory.dataSourcesInfo.dataSourcesConfigured}}',
              operator: OPERATORS.GREATER_THAN,
              argument2: 0,
            },
            then: {
              return: 'Data Sources With Findings',
            },
            else: {
              return: '',
            },
          },
          svgString:
            "<svg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='33' height='36.419' viewBox='0 0 33 36.419'><defs><style>.BigIdSourcesGradient{fill:url(#BigIdSourcesGradient);}</style><linearGradient id='BigIdSourcesGradient' x2='1' y2='3.149' gradientUnits='objectBoundingBox'><stop offset='0' stop-color='#ee80ee'/><stop offset='1' stop-color='#7f269f'/></linearGradient></defs><g transform='translate(-128.999 -290.887)'><path class='BigIdSourcesGradient' d='M145.5,301.937c-7.947,0-16.5-1.729-16.5-5.525s8.552-5.525,16.5-5.525,16.5,1.729,16.5,5.525S153.445,301.937,145.5,301.937Zm0-8.681c-9.285,0-14.205,2.212-14.261,3.156.048.944,4.96,3.156,14.261,3.156s14.213-2.212,14.262-3.156C159.7,295.468,154.784,293.256,145.5,293.256Z' transform='translate(0 0)'/><path class='BigIdSourcesGradient' d='M145.5,313.406c-6.128,0-16.5-1.163-16.5-5.525a1.121,1.121,0,1,1,2.238,0c0,.917,5,3.156,14.261,3.156s14.262-2.24,14.262-3.156a1.121,1.121,0,1,1,2.238,0C162,312.243,151.627,313.406,145.5,313.406Z' transform='translate(0 1.666)'/><path class='BigIdSourcesGradient' d='M145.5,326.63c-8.2,0-16.5-2.192-16.5-6.38V295.663a1.119,1.119,0,0,1,2.238,0v24.588c0,1.7,5.555,4.158,14.261,4.158s14.262-2.463,14.262-4.158V295.663a1.119,1.119,0,0,1,2.238,0v24.588C162,324.439,153.7,326.63,145.5,326.63Z' transform='translate(0 0.676)'/></g></svg>",
          onClickRedirectUrl: {
            state: {
              if: {
                argument1: '{{data.scan_activity_summary.inventory.dataSourcesInfo.dataSourcesConfigured}}',
                operator: OPERATORS.GREATER_THAN,
                argument2: 0,
              },
              then: {
                return: 'dataCatalog',
              },
              else: {
                return: 'dataSourcesInit',
              },
            },
            data: {
              if: {
                argument1: '{{data.scan_activity_summary.inventory.dataSourcesInfo.dataSourcesConfigured}}',
                operator: OPERATORS.GREATER_THAN,
                argument2: 0,
              },
              then: {
                return: {
                  filter: 'contains_pi=true',
                },
              },
              else: {
                return: {
                  filter: '',
                },
              },
            },
          },
        },
      },
    },
    {
      id: 'highlight_box_objects_with_findings',
      header: {
        title: ' ',
      },
      widget: {
        highlightBox: {
          title: {
            if: {
              argument1: '{{data.objects_summary_total_objects_with_pii}}',
              operator: OPERATORS.GREATER_THAN,
              argument2: 0,
            },
            then: {
              return: '{{data.objects_summary_total_objects_with_pii}}',
            },
            else: {
              return: '',
            },
          },
          subtitle: {
            if: {
              argument1: '{{data.objects_summary_total_objects_with_pii}}',
              operator: OPERATORS.GREATER_THAN,
              argument2: 0,
            },
            then: {
              return: 'Objects With Findings',
            },
            else: {
              return: 'Configure Scan',
            },
          },
          svgString:
            "<svg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='27.961' height='32.546' viewBox='0 0 27.961 32.546'><defs><style>.BigidObjectsGradient{fill:url(#BigidObjectsGradient);}</style><linearGradient id='BigidObjectsGradient' x2='1' y2='1' gradientUnits='objectBoundingBox'><stop offset='0' stop-color='#80eecc'/><stop offset='1' stop-color='#00987b'/></linearGradient></defs><g transform='translate(-60.136 -53.279)'><path class='BigidObjectsGradient' d='M64.259,85.824A4.155,4.155,0,0,1,60.136,81.8V57.334a4.163,4.163,0,0,1,4.275-4.053H75.738a1,1,0,0,1,.685.272l11.359,10.69a1,1,0,0,1,.315.728v16.8a4.158,4.158,0,0,1-4.275,4.054H64.381Zm.054-30.545a2.163,2.163,0,0,0-2.178,2.087v24.4a2.17,2.17,0,0,0,2.216,2.055h19.5A2.145,2.145,0,0,0,86.1,81.737V65.4L75.341,55.28H64.313Z'/><path class='BigidObjectsGradient' d='M87.1,65.97H75.738a1,1,0,0,1-1-1V54.28a1,1,0,1,1,2,0v9.69H87.1a1,1,0,0,1,0,2Z'/></g></svg>",
          onClickRedirectUrl: {
            state: {
              if: {
                argument1: '{{data.objects_summary_total_objects_with_pii}}',
                operator: OPERATORS.GREATER_THAN,
                argument2: 0,
              },
              then: {
                return: 'dataCatalog',
              },
              else: {
                return: 'scans',
              },
            },
            data: {
              if: {
                argument1: '{{data.objects_summary_total_objects_with_pii}}',
                operator: OPERATORS.GREATER_THAN,
                argument2: 0,
              },
              then: {
                return: {
                  filter: 'contains_pi=true',
                },
              },
              else: {
                return: {
                  filter: '',
                },
              },
            },
          },
        },
      },
    },
    {
      id: 'sensitivity_levels_in_catalog_pie_chart',
      header: {
        title: 'Sensitivity Levels Distribution In The Catalog',
      },
      showFrame: true,
      widget: {
        pieChart: {
          minimized: false,
          data: {
            pathToArray: 'data.sensitivity_levels_array',
            pathToKey: 'name',
            pathToValue: 'count',
          },
          onLegendClickRedirectUrl: [
            {
              category: 'Restricted',
              onClickRedirectUrl: {
                state: 'dataCatalog',
                data: {
                  filter: 'contains_pi=true AND catalog_tag.system.sensitivityClassification.Sensitivity=Restricted',
                },
              },
            },
            {
              category: 'Confidential',
              onClickRedirectUrl: {
                state: 'dataCatalog',
                data: {
                  filter: 'contains_pi=true AND catalog_tag.system.sensitivityClassification.Sensitivity=Confidential',
                },
              },
            },
            {
              category: 'Public',
              onClickRedirectUrl: {
                state: 'dataCatalog',
                data: {
                  filter: 'contains_pi=true AND catalog_tag.system.sensitivityClassification.Sensitivity=Public',
                },
              },
            },
            {
              category: 'High',
              onClickRedirectUrl: {
                state: 'dataCatalog',
                data: {
                  filter: 'contains_pi=true AND catalog_tag.system.sensitivityClassification.Sensitivity=High',
                },
              },
            },
            {
              category: 'Medium',
              onClickRedirectUrl: {
                state: 'dataCatalog',
                data: {
                  filter: 'contains_pi=true AND catalog_tag.system.sensitivityClassification.Sensitivity=Medium',
                },
              },
            },
            {
              category: 'Low',
              onClickRedirectUrl: {
                state: 'dataCatalog',
                data: {
                  filter: 'contains_pi=true AND catalog_tag.system.sensitivityClassification.Sensitivity=Low',
                },
              },
            },
            {
              category: 'Undefined',
              onClickRedirectUrl: {
                state: 'dataCatalog',
                data: {
                  filter: 'contains_pi=true',
                },
              },
            },
          ],
        },
      },
    },
    {
      id: 'total_objects_findings_count_by_data_source_pie_chart',
      header: {
        title: 'Objects With Findings Count By Data Source',
      },
      showFrame: true,
      widget: {
        pieChart: {
          minimized: false,
          data: {
            pathToArray: 'data.total_findings_count_by_ds',
            pathToKey: 'source',
            pathToValue: 'totalObjectsWithPii',
          },
          onLegendClickRedirectUrl: [
            {
              category: 'Other',
              onClickRedirectUrl: {
                state: 'dataCatalog',
                data: {
                  filter: 'contains_pi=true',
                },
              },
            },
          ],
        },
      },
    },
    {
      id: 'top_ds_with_findings_bar_chart',
      header: {
        title: 'Top Data Sources With Attributes Count',
      },
      showFrame: true,
      widget: {
        barChart: {
          height: '320px',
          maxColumnLabelWidth: 130,
          data: {
            pathToArray: 'data.attribute_by_ds.attributeByDs',
            pathToColumnCategory: 'source',
            keys: [
              {
                keyName: 'numberOfAttributes',
                label: 'Total attribute count',
              },
            ],
          },
        },
      },
    },
    {
      id: 'total_findings_count_by_top_attribute_bar_chart',
      header: {
        title: 'Total Findings Count By Top Attribute',
      },
      showFrame: true,
      widget: {
        barChart: {
          height: '320px',
          maxColumnLabelWidth: 130,
          data: {
            pathToArray: 'data.total_findings_by_top_attribute.attribute_risks',
            pathToColumnCategory: 'short_name',
            keys: [
              {
                keyName: 'count',
                label: 'Total attribute count',
              },
            ],
          },
        },
      },
    },
    {
      id: 'searchableList',
      header: {
        title: ' ',
      },
      widget: {
        searchableList: {
          title: 'Shortcuts',
          searchPlaceHolder: 'Find whatevet you seek',
          height: '405px',
          items: [
            {
              title: 'Getting Started',
              onClickRedirectUrl: {
                state: 'dataCatalog',
                data: {
                  filter: 'contains_pi=true',
                },
              },
              icon: "<svg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 20 20'><defs><style></style></defs><path d='M14.333,11a3.333,3.333,0,1,0,3.333,3.333A3.333,3.333,0,0,0,14.333,11Zm0,5.333a2,2,0,1,1,2-2A2,2,0,0,1,14.333,16.333Z' transform='translate(-4.333 -4.333)'/><path d='M17.463,12.5H20v-5H17.463a.625.625,0,0,1-.419-1.006L18.837,4.7,15.306,1.163l-1.8,1.794a.587.587,0,0,1-.625.113.575.575,0,0,1-.381-.531V0h-5V2.537a.625.625,0,0,1-1.006.419l-1.8-1.794L1.163,4.7,2.956,6.494a.581.581,0,0,1,.119.625.575.575,0,0,1-.531.363H0v5H2.537a.588.588,0,0,1,.538.375.575.575,0,0,1-.125.625L1.163,15.306,4.7,18.844,6.494,17.05a.581.581,0,0,1,.625-.112.575.575,0,0,1,.363.531V20h5V17.463a.581.581,0,0,1,.375-.538.575.575,0,0,1,.625.125l1.794,1.794,3.538-3.537-1.794-1.794a.588.588,0,0,1-.119-.625A.575.575,0,0,1,17.463,12.5Zm-1.3,1.875.913.912-1.769,1.769-.931-.894a1.875,1.875,0,0,0-3.125,1.3V18.75H8.75V17.463a1.875,1.875,0,0,0-3.125-1.3l-.931.906L2.931,15.306l.906-.931a1.875,1.875,0,0,0-1.3-3.125H1.25V8.75H2.537a1.875,1.875,0,0,0,1.3-3.125L2.931,4.7,4.694,2.931l.931.906a1.875,1.875,0,0,0,3.125-1.3V1.25h2.5V2.537a1.875,1.875,0,0,0,3.125,1.3l.912-.913,1.769,1.769-.894.931a1.875,1.875,0,0,0,1.3,3.125H18.75v2.5H17.463a1.875,1.875,0,0,0-1.3,3.125Z'/></svg>",
              iconColor: '{{data.searchableList.colors.section.iconColor}}',
              iconBackgroundColor: '{{data.searchableList.colors.section.iconBackgroundColor}}',
              rightSideIcon: '{{data.searchableList.rightSideIcon}}',
              rightSideIconRedirectUrl: {
                url: docsUrls.OVERVIEW,
              },
              items: [
                {
                  title: 'Data Discovery Wizard',
                  onClickRedirectUrl: {
                    state: CONFIG.states.FMSD,
                  },
                },
                {
                  title: 'Restart Data Discovery Wizard',
                  onClickRedirectUrl: {
                    state: CONFIG.states.FMSD,
                    data: {
                      restart: true,
                    },
                  },
                },
                {
                  title: 'View recent scans',
                  onClickRedirectUrl: {
                    state: CONFIG.states.SCANS_NEW_SCANS_IN_PROGRESS,
                  },
                },
                {
                  title: 'View scan results',
                  onClickRedirectUrl: {
                    state: 'dataCatalog',
                    data: {
                      filter: 'contains_pi=true',
                    },
                  },
                },
                {
                  title: 'Import glossary list',
                  onClickRedirectUrl: {
                    state: 'generalSettings',
                  },
                },
              ],
            },
            {
              title: 'Catalog',
              onClickRedirectUrl: {
                state: 'dataCatalog',
                data: {
                  filter: 'contains_pi=true',
                },
              },
              icon: "<svg id='Layer_1' data-name='Layer 1' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 25.4 24.7'><defs><style></style></defs><title>Big id icon_catalog</title><g id='Layer_2' data-name='Layer 2'><path d='M27.7,17.7l-4.1-15H21.4V4.4h.9L26,17.7H4L7.6,4.3h.9V2.7H6.4l-4.1,15h0v9.7H27.7V17.7ZM26,25.8H3.9V19.4H26Z' transform='translate(-2.3 -2.7)'/><rect x='8.6' y='19' width='7' height='1.7'/><polygon points='6 8.3 7.7 8.3 7.7 6.7 17.7 6.7 17.7 8.3 19.4 8.3 19.4 5 6 5 6 8.3'/><polygon points='9.7 1.6 15.7 1.6 15.7 3.3 17.4 3.3 17.4 0 8 0 8 3.3 9.7 3.3 9.7 1.6'/><polygon points='20.4 13.4 20.4 10 5 10 5 13.4 6.7 13.4 6.7 11.7 18.7 11.7 18.7 13.4 20.4 13.4'/></g></svg>",
              iconColor: '{{data.searchableList.colors.section.iconColor}}',
              iconBackgroundColor: '{{data.searchableList.colors.section.iconBackgroundColor}}',
              rightSideIcon: '{{data.searchableList.rightSideIcon}}',
              rightSideIconRedirectUrl: {
                url: docsUrls.DATA_CATALOG_OBJECT_DETAILS,
              },
              items: [
                {
                  title: 'Review objects that contain findings',
                  onClickRedirectUrl: {
                    state: 'dataCatalog',
                    data: {
                      filter: 'contains_pi=true',
                    },
                  },
                },
                {
                  title: 'View objects that has duplicates',
                  onClickRedirectUrl: {
                    state: 'dataCatalog',
                    data: {
                      filter: 'contains_pi=true',
                    },
                  },
                },
                {
                  title: 'View objects that are open to access',
                  onClickRedirectUrl: {
                    state: 'dataCatalog',
                    data: {
                      filter: 'contains_pi=true',
                    },
                  },
                },
              ],
            },
            {
              title: 'Classifications',
              onClickRedirectUrl: {
                state: 'classification',
              },
              icon: "<svg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 20 20'><defs><style></style></defs><g transform='translate(0.014)'><path d='M16.87,0h-7a.938.938,0,0,0-.875,1.062V6.438A.938.938,0,0,0,9.87,7.5h7a.938.938,0,0,0,.875-1.062V1.063A.937.937,0,0,0,16.87,0Zm-.375,6.25h-6.25v-5h6.25Z' transform='translate(-3.384)'/><path d='M7.87,20h-7a.938.938,0,0,0-.875,1.063v5.375A.938.938,0,0,0,.87,27.5h7a.938.938,0,0,0,.875-1.062V21.063A.938.938,0,0,0,7.87,20Zm-.375,6.25H1.245v-5h6.25Z' transform='translate(0 -7.5)'/><path d='M25.87,20h-7a.938.938,0,0,0-.875,1.063v5.375A.938.938,0,0,0,18.87,27.5h7a.938.938,0,0,0,.875-1.062V21.063A.937.937,0,0,0,25.87,20Zm-.375,6.25h-6.25v-5h6.25Z' transform='translate(-6.767 -7.5)'/><path d='M16.25,12H15v1.875h1.25Z' transform='translate(-5.639 -4.5)'/><path d='M25,19.371h1.25v-3.25A1.125,1.125,0,0,0,25,15h0Z' transform='translate(-9.394 -5.621)'/><path d='M6.25,19.371H5v-3.25A1.125,1.125,0,0,1,6.25,15h0Z' transform='translate(-1.883 -5.621)'/><path d='M18.233,15H7v1.25H18.233Z' transform='translate(-2.63 -5.625)'/></g></svg>",
              iconColor: '{{data.searchableList.colors.section.iconColor}}',
              iconBackgroundColor: '{{data.searchableList.colors.section.iconBackgroundColor}}',
              rightSideIcon: '{{data.searchableList.rightSideIcon}}',
              rightSideIconRedirectUrl: {
                url: docsUrls.CLASSIFIERS,
              },
            },
            {
              title: 'Policies',
              onClickRedirectUrl: {
                state: 'rules',
              },
              icon: "<svg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 20 20'><defs><style></style></defs><path d='M17.741,8.742,18.786,7.73l.657.637,2.242-2.171L16.157.84,13.915,3.011l.657.637-4.333,4.2-.657-.637L7.34,9.379l5.529,5.355,2.249-2.171-.657-.637,1.045-1.012,5.26,5.094,2.242-2.171ZM16.157,2.636l3.675,3.559-.388.376L16.426,3.648l-.657-.637Zm-3.287,10.3L9.194,9.372,9.582,9l.657.637,2.36,2.286h0l.657.637Zm.657-1.91-2.36-2.286,4.333-4.2,2.36,2.286L16.814,7.838l-.927.9-.388.376-.927.9Zm2.9-1.012.388-.376,4.333,4.2-.388.376Z' transform='translate(-3.007 -0.84)'/><path d='M12.584,24.25H2.065v1.91H.75v3.184H13.9V26.16H12.584Zm-9.2,1.273h7.89v.637H3.38Zm9.2,2.547H2.065v-.637H12.584Z' transform='translate(-0.75 -9.344)'/><rect width='3.287' height='1.273' transform='translate(0.657 12.359)'/><path d='M0,0,1.294.021l.052,3.236L.052,3.236Z' transform='translate(1.732 9.845) rotate(-45)'/></svg>",
              iconColor: '{{data.searchableList.colors.section.iconColor}}',
              iconBackgroundColor: '{{data.searchableList.colors.section.iconBackgroundColor}}',
              rightSideIcon: '{{data.searchableList.rightSideIcon}}',
              rightSideIconRedirectUrl: {
                url: docsUrls.POLICIES,
              },
            },
            {
              title: 'Reports',
              onClickRedirectUrl: {
                state: 'digestReport',
              },
              icon: "<svg xmlns='http://www.w3.org/2000/svg' width='16.248' height='20' viewBox='0 0 16.248 20'><defs><style></style></defs><g transform='translate(-3 0.004)'><path d='M14.873,1.873V1.248H12.892a1.875,1.875,0,0,0-3.537,0H7.374v.625H3V20H19.248V1.873ZM10.5,2.5V1.873a.625.625,0,0,1,1.25,0V2.5h1.875V4.373h-5V2.5Zm5.624,10.624H13v3.125H6.125V5h1.25v.625h7.5V5h1.25Zm-.881,1.25-.994.994v-.994ZM18,18.746H4.25V3.123H7.374v.625h-2.5V17.5h9L17.373,14V3.748h-2.5V3.123H18Z'/><rect width='7.499' height='1.25' transform='translate(7.374 7.497)'/><rect width='7.499' height='1.25' transform='translate(7.374 9.997)'/><rect width='3.749' height='1.25' transform='translate(7.374 12.497)'/></g></svg>",
              iconColor: '{{data.searchableList.colors.section.iconColor}}',
              iconBackgroundColor: '{{data.searchableList.colors.section.iconBackgroundColor}}',
              rightSideIcon: '{{data.searchableList.rightSideIcon}}',
              rightSideIconRedirectUrl: {
                url: docsUrls.REPORTS,
              },
              items: [
                {
                  title: 'View activity highlights',
                  onClickRedirectUrl: {
                    state: 'digestReport',
                  },
                },
                {
                  title: 'View scan results details',
                  onClickRedirectUrl: {
                    state: 'scanAnalysis',
                  },
                },
              ],
            },
            {
              title: 'Administration',
              icon: "<svg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 20 20'><defs><style></style></defs><path d='M14.333,11a3.333,3.333,0,1,0,3.333,3.333A3.333,3.333,0,0,0,14.333,11Zm0,5.333a2,2,0,1,1,2-2A2,2,0,0,1,14.333,16.333Z' transform='translate(-4.333 -4.333)'/><path d='M17.463,12.5H20v-5H17.463a.625.625,0,0,1-.419-1.006L18.837,4.7,15.306,1.163l-1.8,1.794a.587.587,0,0,1-.625.113.575.575,0,0,1-.381-.531V0h-5V2.537a.625.625,0,0,1-1.006.419l-1.8-1.794L1.163,4.7,2.956,6.494a.581.581,0,0,1,.119.625.575.575,0,0,1-.531.363H0v5H2.537a.588.588,0,0,1,.538.375.575.575,0,0,1-.125.625L1.163,15.306,4.7,18.844,6.494,17.05a.581.581,0,0,1,.625-.112.575.575,0,0,1,.363.531V20h5V17.463a.581.581,0,0,1,.375-.538.575.575,0,0,1,.625.125l1.794,1.794,3.538-3.537-1.794-1.794a.588.588,0,0,1-.119-.625A.575.575,0,0,1,17.463,12.5Zm-1.3,1.875.913.912-1.769,1.769-.931-.894a1.875,1.875,0,0,0-3.125,1.3V18.75H8.75V17.463a1.875,1.875,0,0,0-3.125-1.3l-.931.906L2.931,15.306l.906-.931a1.875,1.875,0,0,0-1.3-3.125H1.25V8.75H2.537a1.875,1.875,0,0,0,1.3-3.125L2.931,4.7,4.694,2.931l.931.906a1.875,1.875,0,0,0,3.125-1.3V1.25h2.5V2.537a1.875,1.875,0,0,0,3.125,1.3l.912-.913,1.769,1.769-.894.931a1.875,1.875,0,0,0,1.3,3.125H18.75v2.5H17.463a1.875,1.875,0,0,0-1.3,3.125Z'/></svg>",
              iconColor: '{{data.searchableList.colors.section.iconColor}}',
              iconBackgroundColor: '{{data.searchableList.colors.section.iconBackgroundColor}}',
              items: [
                {
                  title: 'Configure data sources',
                  onClickRedirectUrl: {
                    state: 'dataSourceConnections',
                  },
                },
                {
                  title: 'Configure correlation sets',
                  onClickRedirectUrl: {
                    state: 'entityCorrelation',
                  },
                },
                {
                  title: 'Configure classifiers',
                  onClickRedirectUrl: {
                    state: 'classifiers',
                  },
                },
                {
                  title: 'Set up email server',
                  onClickRedirectUrl: {
                    state: 'generalSettings',
                    data: {
                      currentTab: 'emailSetup',
                    },
                  },
                },
                {
                  title: 'Configure ignored list',
                  onClickRedirectUrl: {
                    state: 'generalSettings',
                    data: {
                      currentTab: 'ignoredList',
                    },
                  },
                },
                {
                  title: 'Configure system licenses',
                  onClickRedirectUrl: {
                    state: 'generalSettings',
                    data: {
                      currentTab: 'licenses',
                    },
                  },
                },
                {
                  title: 'Manage user roles and permissions',
                  onClickRedirectUrl: {
                    state: 'identityAccessManagement',
                  },
                },
              ],
            },
            {
              title: 'Scans',
              onClickRedirectUrl: {
                state: 'scans',
              },
              icon: "<svg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 20 20'><defs><style></style></defs><path d='M14.333,11a3.333,3.333,0,1,0,3.333,3.333A3.333,3.333,0,0,0,14.333,11Zm0,5.333a2,2,0,1,1,2-2A2,2,0,0,1,14.333,16.333Z' transform='translate(-4.333 -4.333)'/><path d='M17.463,12.5H20v-5H17.463a.625.625,0,0,1-.419-1.006L18.837,4.7,15.306,1.163l-1.8,1.794a.587.587,0,0,1-.625.113.575.575,0,0,1-.381-.531V0h-5V2.537a.625.625,0,0,1-1.006.419l-1.8-1.794L1.163,4.7,2.956,6.494a.581.581,0,0,1,.119.625.575.575,0,0,1-.531.363H0v5H2.537a.588.588,0,0,1,.538.375.575.575,0,0,1-.125.625L1.163,15.306,4.7,18.844,6.494,17.05a.581.581,0,0,1,.625-.112.575.575,0,0,1,.363.531V20h5V17.463a.581.581,0,0,1,.375-.538.575.575,0,0,1,.625.125l1.794,1.794,3.538-3.537-1.794-1.794a.588.588,0,0,1-.119-.625A.575.575,0,0,1,17.463,12.5Zm-1.3,1.875.913.912-1.769,1.769-.931-.894a1.875,1.875,0,0,0-3.125,1.3V18.75H8.75V17.463a1.875,1.875,0,0,0-3.125-1.3l-.931.906L2.931,15.306l.906-.931a1.875,1.875,0,0,0-1.3-3.125H1.25V8.75H2.537a1.875,1.875,0,0,0,1.3-3.125L2.931,4.7,4.694,2.931l.931.906a1.875,1.875,0,0,0,3.125-1.3V1.25h2.5V2.537a1.875,1.875,0,0,0,3.125,1.3l.912-.913,1.769,1.769-.894.931a1.875,1.875,0,0,0,1.3,3.125H18.75v2.5H17.463a1.875,1.875,0,0,0-1.3,3.125Z'/></svg>",
              iconColor: '{{data.searchableList.colors.section.iconColor}}',
              iconBackgroundColor: '{{data.searchableList.colors.section.iconBackgroundColor}}',
              rightSideIcon: '{{data.searchableList.rightSideIcon}}',
              rightSideIconRedirectUrl: {
                url: docsUrls.MANAGING_SCANS,
              },
              items: [
                {
                  title: 'Configure a new scan profile',
                  onClickRedirectUrl: {
                    state: 'createScanProfile',
                  },
                },
                {
                  title: 'View last scans',
                  onClickRedirectUrl: {
                    state: 'scans',
                  },
                },
              ],
            },
            {
              title: 'Access Intelligence',
              onClickRedirectUrl: {
                url: 'accessGovernance',
              },
              icon: "<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'><defs><style>.aaid{fill:none;}.baid{fill:#ff5e43;}</style></defs><g transform='translate(0.232)'><rect class='aaid' width='24' height='24' transform='translate(-0.232)'/><g transform='translate(2.113 0.581)'><path class='baid' d='M21.8,10.026H10.761V4.692h6.021V7.755h3.592V4.427A3.3,3.3,0,0,0,17.1,1.1H10.444A3.3,3.3,0,0,0,7.169,4.427v5.6H5.743A1.77,1.77,0,0,0,4,11.822V22.015a1.758,1.758,0,0,0,1.743,1.743h16a1.725,1.725,0,0,0,1.743-1.743V11.822A1.7,1.7,0,0,0,21.8,10.026ZM8.225,4.427a2.243,2.243,0,0,1,2.218-2.271H17.1a2.243,2.243,0,0,1,2.218,2.271V6.7H17.838V4.427a.8.8,0,0,0-.739-.792H10.444a.755.755,0,0,0-.739.739h0v5.651H8.225ZM22.486,22.015a.7.7,0,0,1-.687.687H5.743a.7.7,0,0,1-.687-.687V11.822a.712.712,0,0,1,.687-.739H21.8a.712.712,0,0,1,.687.739S22.486,22.015,22.486,22.015Z' transform='translate(-4 -1.1)'/><path class='baid' d='M20.613,28.056a1.056,1.056,0,1,1-1.056,1.056,1.059,1.059,0,0,1,1.056-1.056m0-1.056a2.113,2.113,0,1,0,2.113,2.113A2.086,2.086,0,0,0,20.613,27Z' transform='translate(-10.842 -13.32)'/><path class='baid' d='M15.706,23.256a7.851,7.851,0,0,1,6.338,3.592h0a7.851,7.851,0,0,1-6.338,3.592,7.851,7.851,0,0,1-6.338-3.592h0a7.851,7.851,0,0,1,6.338-3.592m0-1.056a8.846,8.846,0,0,0-7.183,4.067l-.423.581.423.581A8.982,8.982,0,0,0,15.706,31.5a8.846,8.846,0,0,0,7.183-4.067l.475-.581-.423-.581A9.009,9.009,0,0,0,15.706,22.2Z' transform='translate(-5.935 -11.056)'/></g></g></svg>",
              iconColor: '{{data.searchableList.colors.app.iconColor}}',
              iconBackgroundColor: '{{data.searchableList.colors.app.iconBackgroundColor}}',
              rightSideIcon: '{{data.searchableList.rightSideIcon}}',
              rightSideIconRedirectUrl: {
                url: docsUrls.DATA_ACCESS_GOVERNANCE,
              },
            },
          ],
        },
      },
    },
    {
      id: 'trendsAndRisksCustomWidget',
      widget: {
        predefinedWidget: {
          id: 'trendsAndRisks',
        },
      },
    },
    {
      id: 'insightsCustomWidget',
      widget: {
        predefinedWidget: {
          id: 'insights',
        },
      },
    },
  ],
  layout: {
    direction: 'row',
    justifyContent: 'center',
    alignContent: 'center',
    alignItems: 'center',
    spacing: 1,
    layoutSlots: [
      {
        size: 7,
        layoutGrid: {
          spacing: 5,
          justifyContent: 'center',
          alignItems: 'center',
          layoutSlots: [
            {
              widgetId: 'highlight_box_scanned_ds',
            },
            {
              widgetId: 'highlight_box_scanned_ds_with_findings',
            },
            {
              widgetId: 'highlight_box_objects_with_findings',
            },
          ],
        },
      },
      {
        size: 7,
        layoutGrid: {
          justifyContent: 'center',
          alignItems: 'center',
          spacing: 1,
          layoutSlots: [
            {
              widgetId: 'sensitivity_levels_in_catalog_pie_chart',
              size: 6,
            },
            {
              widgetId: 'total_objects_findings_count_by_data_source_pie_chart',
              size: 6,
            },
          ],
        },
      },
      {
        size: 7,
        layoutGrid: {
          justifyContent: 'center',
          alignItems: 'center',
          spacing: 1,
          layoutSlots: [
            {
              widgetId: 'top_ds_with_findings_bar_chart',
              size: 6,
            },
            {
              widgetId: 'total_findings_count_by_top_attribute_bar_chart',
              size: 6,
            },
          ],
        },
      },
      {
        size: 7,
        layoutGrid: {
          justifyContent: 'center',
          alignItems: 'center',
          spacing: 1,
          layoutSlots: [
            {
              widgetId: 'searchableList',
              size: 4,
            },
            {
              widgetId: 'trendsAndRisksCustomWidget',
              size: 8,
            },
          ],
        },
      },
      {
        size: 7,
        layoutGrid: {
          justifyContent: 'center',
          alignItems: 'center',
          layoutSlots: [
            {
              widgetId: 'insightsCustomWidget',
              size: 12,
            },
          ],
        },
      },
    ],
  },
};
