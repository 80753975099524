import { useMemo } from 'react';
import { BigidFormField, BigidFormFieldLabelPosition, BigidFormFieldTypes } from '@bigid-ui/components';
import { useLocalTranslation } from '../translations';
import {
  validateEmail,
  validateEmptyOrUnicodeString,
  validatePasswordRepeat,
  validatePrimaryPassword,
} from '../../../utils';

interface UseCreateUserFormFields {
  shouldRenderPasswordFields: boolean;
  enforceStrongPasswordSecurity: boolean;
}

const inputFieldProps = {
  size: 'large',
};

const passwordFieldProps = {
  ...inputFieldProps,
  showValueOnlyIfDirty: true,
};

export const useCreateUserFormFields = ({
  shouldRenderPasswordFields,
  enforceStrongPasswordSecurity,
}: UseCreateUserFormFields): BigidFormField[] => {
  const { t } = useLocalTranslation('dialogs.createUser');

  const formFields = useMemo<BigidFormField[]>(
    () => [
      {
        fieldProps: inputFieldProps,
        isRequired: false,
        label: t('fields.firstName'),
        labelPosition: BigidFormFieldLabelPosition.top,
        name: 'firstName',
        type: BigidFormFieldTypes.TEXT,
        validate: validateEmptyOrUnicodeString(t('errors.wrongFirstNameFormat')),
      },
      {
        fieldProps: inputFieldProps,
        isRequired: false,
        label: t('fields.lastName'),
        labelPosition: BigidFormFieldLabelPosition.top,
        name: 'lastName',
        type: BigidFormFieldTypes.TEXT,
        validate: validateEmptyOrUnicodeString(t('errors.wrongLastNameFormat')),
      },
      {
        fieldProps: inputFieldProps,
        isRequired: true,
        label: t('fields.email'),
        labelPosition: BigidFormFieldLabelPosition.top,
        name: 'name',
        type: BigidFormFieldTypes.TEXT,
        validate: validateEmail(t('errors.emailFieldEmpty'), t('errors.emailFieldWrongValue')),
      },
      {
        fieldProps: inputFieldProps,
        isRequired: false,
        label: t('fields.origin'),
        disabled: true,
        labelPosition: BigidFormFieldLabelPosition.top,
        labelWidth: '120px',
        name: 'origin',
        type: BigidFormFieldTypes.TEXT,
        validate: () => false,
      },
      ...(shouldRenderPasswordFields
        ? [
            {
              fieldProps: passwordFieldProps,
              isRequired: false,
              label: t('fields.password'),
              labelPosition: BigidFormFieldLabelPosition.top,
              labelWidth: '120px',
              name: 'password',
              tooltipText: t('toolTips.password'),
              type: BigidFormFieldTypes.PASSWORD,
              validate: validatePrimaryPassword(t('errors.passwordFieldWeakValue'), enforceStrongPasswordSecurity),
            },
            {
              fieldProps: passwordFieldProps,
              isRequired: false,
              label: t('fields.confirmPassword'),
              labelPosition: BigidFormFieldLabelPosition.top,
              labelWidth: '120px',
              name: 'passwordRepeat',
              type: BigidFormFieldTypes.PASSWORD,
              validate: validatePasswordRepeat(t('errors.passwordFieldNotMatching')),
            },
          ]
        : []),
    ],
    [enforceStrongPasswordSecurity, shouldRenderPasswordFields, t],
  );

  return formFields;
};
