import React, { FC, useMemo, useCallback, useState, useEffect } from 'react';
import angular from 'angular';
import { reduce, forEach, debounce, startCase, words } from 'lodash';
import { convertToAngular } from '../../../../../common/services/convertToAngular';
import { BigidColors, BigidLoader, BigidSearch } from '@bigid-ui/components';
import makeStyles from '@mui/styles/makeStyles';
import { PermissionsPicker } from './PermissionsPicker/PermissionsPicker';
import { ModulePermissions } from './PermissionsPicker/PermissionsCollapsable';
import { BigidCheckTool } from '../../../../components/BigidCheckTool/BigidCheckTool';
import { httpService } from '../../../../services/httpService';
import { isPermitted } from '../../../../services/userPermissionsService';
import { ACCESS_MANAGEMENT_PERMISSIONS } from '@bigid/permissions';
import { SystemRole } from '../../types';

interface RolePermissionsProps {
  selectedRole: SystemRole;
  updateRolePermissions: (granularPermissions: string[]) => void;
  updateRoleInfo: (roleProps: Partial<SystemRole>) => void;
}

export type PermissionsHierarchy = Record<string, ModulePermissions>;

const FORM_HEIGHT = 276;
const SEARCH_WIDTH = 360;
export const MODULES_WITH_CATEGORY_SEARCH = ['applications'];
const PERMISSIONS_DISPLAY_NAMES = new Map<string, string>([
  ['classifierTuning', 'reviewFindings'],
  ['securityPosture', 'actionableInsights'],
]);
const useStyles = makeStyles(theme => ({
  root: {
    height: `calc(100% - ${FORM_HEIGHT}px)`,
    display: 'flex',
    flexDirection: 'column',
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    margin: '0 25px 25px 25px',
  },
  title: { fontWeight: 500, color: BigidColors.gray[900], fontSize: '1.125rem' },
  search: { width: SEARCH_WIDTH, marginLeft: 20 },
  asterisk: { color: `${theme.vars.palette.bigid.red600}`, marginLeft: 4 },
}));

const moduleDisplayNames: Record<string, string> = {
  tagsSavedQueries: 'savedQueries',
  applicationSetup: 'assets',
};

const getModuleDisplayNames = (permissionsHierarchy: PermissionsHierarchy): string[] =>
  Object.keys(permissionsHierarchy).map(key => startCase(moduleDisplayNames[key] || key));

const getSpecificModuleDisplayNames = (permissionsHierarchy: PermissionsHierarchy, moduleName: string): string[] =>
  Object.keys(permissionsHierarchy[moduleName]).map(key => startCase(key));

const getModulesFromSearch = (search: string, modules: string[]): string[] => {
  const searchWords = words(search.toLowerCase());
  const modulesSearched: string[] = [];
  modules.forEach(module => {
    const moduleWords = words(module.toLowerCase());
    if (
      searchWords.every(searchWord => moduleWords.filter(moduleWord => moduleWord.startsWith(searchWord)).length > 0)
    ) {
      modulesSearched.push(module);
    }
  });
  return modulesSearched;
};

function convertOverridenPermissions(permissionsHierarchyData: PermissionsHierarchy) {
  Object.entries(moduleDisplayNames).forEach(([key, value]) => {
    permissionsHierarchyData[value] = permissionsHierarchyData[key];
    delete permissionsHierarchyData[key];
  });
}

const setDisplayNames = (permissionsHierarchyData: PermissionsHierarchy) => {
  Array.from(PERMISSIONS_DISPLAY_NAMES.entries()).forEach(([displayName, originalName]) => {
    permissionsHierarchyData[displayName] = permissionsHierarchyData[originalName];
    delete permissionsHierarchyData[originalName];
  });
};

export const RolePermissions: FC<RolePermissionsProps> = ({
  selectedRole: {
    isSystem,
    granularPermissions = [],
    name = 'New Role',
    _id: roleId,
    privacyPortalDepartments,
    privacyPortalPermissions,
  },
  updateRolePermissions,
  updateRoleInfo,
}) => {
  const classes = useStyles({});
  const [modulesToDisplay, setModulesToDisplay] = useState<string[]>([]);
  const [permissionsHierarchy, setPermissionsHierarchy] = useState<PermissionsHierarchy>({});
  const [searchValue, setSearchValue] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const loadPermissionsHierarchy = async () => {
    setIsLoading(true);
    const {
      data: { permissions: permissionsHierarchyData },
    } = await httpService.fetch('roles/rbac/permissions-hierarchy');
    setDisplayNames(permissionsHierarchyData);
    convertOverridenPermissions(permissionsHierarchyData);
    setPermissionsHierarchy(permissionsHierarchyData);
    setModulesToDisplay(getModuleDisplayNames(permissionsHierarchyData));
    setIsLoading(false);
  };

  useEffect(() => {
    loadPermissionsHierarchy();
  }, []);

  useEffect(() => {
    if (name === 'New Role' || roleId) {
      setSearchValue('');
      setModulesToDisplay(getModuleDisplayNames(permissionsHierarchy));
    }
  }, [name, roleId, permissionsHierarchy]);

  const allPermissions = useMemo(() => {
    return reduce(
      permissionsHierarchy,
      (permissions, module) => {
        forEach(module, category => {
          permissions = [...permissions, ...category.map(({ name }) => name)];
        });
        return permissions;
      },
      [],
    );
  }, [permissionsHierarchy]);

  const onCheckAll = () => {
    updateRolePermissions(allPermissions);
  };
  const onCheckNone = () => {
    updateRolePermissions([]);
  };

  const isAllPermissionsSelected = granularPermissions.length === allPermissions.length;
  const isNoPermissionsSelected = granularPermissions.length === 0;

  const onSearchChange = useCallback(
    debounce((value: string) => {
      setSearchValue(value);
      if (!value || value === '') {
        setModulesToDisplay(getModuleDisplayNames(permissionsHierarchy));
        return;
      }
      const categoryDisplayNames = MODULES_WITH_CATEGORY_SEARCH.reduce(
        (aggregatedValue, currentValue) => [
          ...aggregatedValue,
          ...getSpecificModuleDisplayNames(permissionsHierarchy, currentValue),
        ],
        [],
      );
      setModulesToDisplay(
        getModulesFromSearch(value, [...categoryDisplayNames, ...getModuleDisplayNames(permissionsHierarchy)]),
      );
    }, 150),
    [permissionsHierarchy],
  );

  const shouldShowPermissionsCheckTool = !isSystem && isPermitted(ACCESS_MANAGEMENT_PERMISSIONS.MANAGE.name);

  return (
    <div className={classes.root}>
      <div className={classes.header}>
        <div className={classes.title}>
          Role Permissions
          <span className={classes.asterisk}>*</span>
        </div>
        <div className={classes.search}>
          <BigidSearch value={searchValue} onChange={onSearchChange} onSubmit={onSearchChange} placeholder="Search" />
        </div>
        {shouldShowPermissionsCheckTool && (
          <BigidCheckTool
            onCheckAll={onCheckAll}
            onCheckNone={onCheckNone}
            disabled={{ all: isAllPermissionsSelected, none: isNoPermissionsSelected }}
          />
        )}
      </div>
      {isLoading ? (
        <BigidLoader label={'Loading Roles'} />
      ) : (
        <PermissionsPicker
          selectedPermissions={granularPermissions}
          setSelectedPermissions={updateRolePermissions}
          updateRoleInfo={updateRoleInfo}
          modulesToDisplay={modulesToDisplay}
          isSystemRole={isSystem}
          roleName={name}
          permissionsHierarchy={permissionsHierarchy}
          privacyPortalDepartments={privacyPortalDepartments}
          privacyPortalPermissions={privacyPortalPermissions}
        />
      )}
    </div>
  );
};

angular
  .module('app')
  .component(
    'rolePermissions',
    convertToAngular(RolePermissions, ['selectedRole', 'updateRolePermissions', 'updateRoleInfo']),
  );
