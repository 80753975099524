import { BigidGridColumnTypes, BigidGridProps, useFetch } from '@bigid-ui/grid';
import {
  BigidLayoutMasterDetails,
  BigidLayoutMasterDetailsConfig,
  BigidLayoutMasterDetailsGridRowLayouts as BigidGridRowLayouts,
  BigidLayoutMasterDetailsGridSearchTypes as BigidGridSearchType,
} from '@bigid-ui/layout';
import React from 'react';
import { CertificateFormData, CertificateGridRow, CertificatesManagementPermissions } from './types';
import { getFixedT } from './translations';
import {
  BigidDropdownOption,
  BigidFormField,
  BigidFormFieldTypes,
  BigidFormValues,
  BigidStatusBadge,
  BigidStatusBadgeSize,
  BigidStatusBadgeType,
} from '@bigid-ui/components';
import { isPermitted } from '../../../services/userPermissionsService';
import { CERTIFICATES_PERMISSIONS } from '@bigid/permissions';
import { CertificateType, CertificateUsage, certUsageNames } from '../CertificatesManagement';
import { ALLOWED_NAME_REGEX } from '../../../config/consts';
import { CertificatesManagementNoDataView } from './CertificatesManagementNoDataView';
import { isMultiTenantModeEnabled } from '../../../utilities/multiTenantUtils';

export const t = (key: string): string => getFixedT('')(key);

export const ACCEPTED_FILE_TYPES = ['.p12', '.pfx', '.jks', '.pem', '.keytab', '.sso', '.zip'];

export const createGridConfig = (
  id: string,
  config: ReturnType<typeof useFetch<CertificateGridRow>>,
): BigidGridProps<CertificateGridRow> => ({
  gridId: id,
  customRowIdName: 'name',
  rows: config.rows,
  totalRowsCount: config.totalRowsCount,
  skip: config.skip,
  onPagingChanged: config.onPagingChanged,
  onSortingChanged: config.onSortingChanged,
  onFiltersChange: config.onFiltersChanged,
  defaultSorting: config.defaultSorting,
  loading: config.isLoading,
  pageSize: 1000,
  noDataContent: <CertificatesManagementNoDataView message={t('message.noCertificates')} />,
  columns: [
    {
      name: 'name',
      title: 'name',
      type: BigidGridColumnTypes.CUSTOM,
      width: 'auto',
      getCellValue: row => {
        return (
          <BigidLayoutMasterDetails.Row
            title={row.isPending ? t('message.newCertificate') : row.name}
            subtitle={
              row.isPending ? (
                <BigidStatusBadge
                  label={t('statusText')}
                  type={BigidStatusBadgeType.DARK}
                  size={BigidStatusBadgeSize.SMALL}
                />
              ) : (
                usageValueToUsageName[row.usage]
              )
            }
            layout={BigidGridRowLayouts.TWO_ROW}
          />
        );
      },
    },
  ],
});

export const createLayoutConfig = (
  gridConfig: BigidGridProps<CertificateGridRow>,
): BigidLayoutMasterDetailsConfig<CertificateGridRow> => ({
  grid: gridConfig,
  search: {
    fields: ['name'],
    placeholder: t('search.placeholder'),
    type: BigidGridSearchType.INTEGRATED,
  },
});

export const getPermissions = (): CertificatesManagementPermissions => ({
  isDeletePermitted: isPermitted(CERTIFICATES_PERMISSIONS.DELETE.name),
  isEditPermitted: isPermitted(CERTIFICATES_PERMISSIONS.EDIT.name),
  isCreatePermitted: isPermitted(CERTIFICATES_PERMISSIONS.CREATE.name),
});

export const getCertificateFileTypes = (usage: CertificateUsage): BigidDropdownOption[] => {
  let selectedTypes: CertificateType[] = [];
  switch (usage) {
    case CertificateUsage.CLIENT_CERT:
      selectedTypes = [CertificateType.P12, CertificateType.JKS];
      break;
    case CertificateUsage.JAVA_KEYSTORE:
      selectedTypes = [CertificateType.P12, CertificateType.JKS];
      if (!isMultiTenantModeEnabled()) {
        selectedTypes.push(CertificateType.KERBEROS);
      }
      break;
    case CertificateUsage.ROOT_CA:
      selectedTypes = [CertificateType.PEM];
      break;
    case CertificateUsage.CUSTOM:
      selectedTypes = [CertificateType.P12, CertificateType.JKS, CertificateType.PEM, CertificateType.SSO];
      break;
    case CertificateUsage.SECURE_BUNDLE:
      selectedTypes = [CertificateType.ZIP];
      break;
  }
  return selectedTypes.map(selectedType => ({ displayValue: selectedType, value: selectedType, id: selectedType }));
};

const usageTypes = [
  CertificateUsage.JAVA_KEYSTORE,
  CertificateUsage.ROOT_CA,
  CertificateUsage.CLIENT_CERT,
  CertificateUsage.SECURE_BUNDLE,
  CertificateUsage.CUSTOM,
];

const usageValueToUsageName = {
  [CertificateUsage.CUSTOM]: certUsageNames.CUSTOM,
  [CertificateUsage.JAVA_KEYSTORE]: certUsageNames.JAVA_KEYSTORE,
  [CertificateUsage.ROOT_CA]: certUsageNames.ROOT_CA,
  [CertificateUsage.SECURE_BUNDLE]: certUsageNames.SECURE_BUNDLE,
  [CertificateUsage.CLIENT_CERT]: certUsageNames.CLIENT_CERT,
};

export const getUsageNameByType = (certUsage: CertificateUsage) => {
  switch (certUsage) {
    case CertificateUsage.CUSTOM:
      return certUsageNames.CUSTOM;
    case CertificateUsage.JAVA_KEYSTORE:
      return certUsageNames.JAVA_KEYSTORE;
    case CertificateUsage.CLIENT_CERT:
      return certUsageNames.CLIENT_CERT;
    case CertificateUsage.SECURE_BUNDLE:
      return certUsageNames.SECURE_BUNDLE;
    default:
      return certUsageNames.ROOT_CA;
  }
};

const getUsageDropdownOptions = () => {
  return usageTypes.map(certUsage => {
    const displayValue = getUsageNameByType(certUsage);
    return { displayValue, value: certUsage, id: displayValue };
  });
};

const validateCertificateName = (value: string) => {
  if (!value) {
    return t('message.requiredField');
  }

  if (!value.match(ALLOWED_NAME_REGEX)) {
    return t('message.invalidCredentialIdPattern');
  }

  return false;
};

const validateDropdown = (message: string) => (value: BigidDropdownOption[]) => value?.length > 0 ? false : t(message);

export const getFormFields = (formData: CertificateFormData, isEditMode?: boolean): BigidFormField[] => {
  return [
    {
      name: 'name',
      type: BigidFormFieldTypes.TEXT,
      validate: validateCertificateName,
      isRequired: true,
      label: t('form.name'),
      disabled: isEditMode,
      tooltipText: t('form.tooltipText.name'),
      fieldProps: {
        size: 'large',
      },
    },
    {
      name: 'usage',
      label: t('form.usage'),
      isRequired: true,
      validate: validateDropdown('message.requiredField'),
      dropDownOptions: getUsageDropdownOptions(),
      type: BigidFormFieldTypes.DROP_DOWN,
      fieldProps: {
        size: 'large',
      },
    },
    {
      name: 'type',
      label: t('form.type'),
      isRequired: true,
      validate: validateDropdown('message.requiredField'),
      dropDownOptions: getCertificateFileTypes(formData.usage),
      type: BigidFormFieldTypes.DROP_DOWN,
      fieldProps: {
        size: 'large',
      },
    },
    {
      name: 'password',
      type: BigidFormFieldTypes.PASSWORD,
      label: t('form.password'),
      tooltipText: t('form.tooltipText.password'),
      fieldProps: {
        size: 'large',
        showValueOnlyIfDirty: true,
      },
    },
  ];
};

const getDropdownInitialValue = (field: BigidFormField, row: CertificateFormData): BigidDropdownOption[] => {
  const { name, dropDownOptions } = field;
  return dropDownOptions.filter(option => option?.value === row[name as keyof CertificateFormData]);
};

export const getInitialValues = (fields: BigidFormField[], row: CertificateFormData): BigidFormValues => {
  return fields.reduce<BigidFormValues>((acc, field) => {
    const { name, type } = field;
    const fieldName = name as keyof CertificateFormData;
    const isDropDownType = type === BigidFormFieldTypes.DROP_DOWN;

    const getValue = () => {
      if (isDropDownType) {
        return getDropdownInitialValue(field, row);
      }
      if (name === 'configFile' || name === 'cert') {
        return null;
      }
      if (name === 'name') {
        return row.isPending ? '' : row.name;
      }

      return row[fieldName];
    };

    return {
      ...acc,
      [name]: getValue(),
    };
  }, {});
};

const mapFormFieldToRequestData: Partial<
  Record<keyof CertificateFormData, (options: BigidDropdownOption[]) => CertificateType>
> = {
  type: (options: BigidDropdownOption[]) => options[0].value,
  usage: (options: BigidDropdownOption[]) => options[0].value,
};

export const normalizeFormData = (values: BigidFormValues): Partial<CertificateFormData> => {
  const certificateType = mapFormFieldToRequestData['type'](values.type);

  const data = Object.keys(values).reduce((accumulator, fieldName) => {
    const getValue = mapFormFieldToRequestData[fieldName as keyof CertificateFormData];
    const value = getValue ? getValue(values[fieldName]) : values[fieldName];
    return {
      ...accumulator,
      [fieldName]: value,
    };
  }, {});

  return { ...data, ...(certificateType === CertificateType.KERBEROS && { configFile: values.configFile }) };
};
