import React, { FC, useEffect, useState } from 'react';
import { BigidLoader } from '@bigid-ui/components';
import makeStyles from '@mui/styles/makeStyles';
import Grid from '@mui/material/Grid';
import {
  AciEntityType,
  getObjectsByDataSourceInsight,
  getObjectsByDataTypeInsight,
  getTopAciEntitiesWithSharedObjects,
} from '../InsightCharts/AccessIntelligenceInsightsService';
import { DataSourcesWithSensitiveDataChart } from '../InsightCharts/DataSourcesWithSensitiveDataChart';
import { TopDataSourcesWithSensitiveData } from '../InsightCharts/TopDataSourcesWithSensitiveData';
import { ObjectsWithSensitiveDataChart } from '../InsightCharts/ObjectsWithSensitiveDataChart';
import { TopAccessTypesWithSensitiveData } from '../InsightCharts/TopAccessTypesWithSensitiveData';
import { $state } from '../../../services/angularServices';
import {
  AccessIntelligenceDashboardContext,
  AciDashboardData,
} from '../InsightCharts/AccessIntelligenceDashboardContext';
import { TopUsersWithSharedObjects } from '../InsightCharts/TopUsersWithSharedObjects';
import { getLabels } from '../Settings/Configuration/LabelConfigurationService';
import { notificationService } from '../../../services/notificationService';

const useStyles = makeStyles({
  grid: {
    overflowY: 'auto',
  },
});

export const AccessIntelligenceDashboard: FC = () => {
  const { grid } = useStyles({});
  const [dashboardData, setDashboardData] = useState<AciDashboardData>({
    objectsByDataSource: null,
    objectsByType: null,
    aciLabels: null,
    topUsersWithSharedObjects: null,
  });
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const objectsByDataSource = await getObjectsByDataSourceInsight();
        const objectsByType = await getObjectsByDataTypeInsight();
        const aciLabels = await getLabels();
        const topUsersWithSharedObjects = await getTopAciEntitiesWithSharedObjects(AciEntityType.Users, 5);
        setDashboardData({
          objectsByDataSource,
          objectsByType,
          aciLabels,
          topUsersWithSharedObjects,
        });
      } catch (e) {
        const errMsg = `An error has occurred`;
        notificationService.error(errMsg);
        console.error(`${errMsg} ${e}`);
      } finally {
        setIsLoading(false);
      }
    };
    fetchData();
  }, []);

  const goToInventoryWithOpenAccessFilter = () => {
    $state.go('accessGovernance', { selectedTab: 'dataManager' }, { reload: true, notify: false, inherit: true });
  };

  if (isLoading) {
    return <BigidLoader />;
  }

  return (
    <AccessIntelligenceDashboardContext.Provider
      value={{
        dashboardData,
        goToInventoryWithOpenAccessFilter,
      }}
    >
      <Grid container className={grid} alignContent={'flex-start'} spacing={2}>
        <Grid item xs={6} lg={4}>
          <DataSourcesWithSensitiveDataChart />
        </Grid>
        <Grid item xs={6} lg={4}>
          <ObjectsWithSensitiveDataChart />
        </Grid>
        <Grid item xs={6} lg={4}>
          <TopUsersWithSharedObjects />
        </Grid>
        <Grid item xs={6}>
          <TopDataSourcesWithSensitiveData />
        </Grid>
        <Grid item xs={6}>
          <TopAccessTypesWithSensitiveData />
        </Grid>
      </Grid>
    </AccessIntelligenceDashboardContext.Provider>
  );
};
