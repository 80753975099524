import React, { FC, useState, useEffect } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { DashboardCommon } from './DashboardCommon';
import { DashboardUpperSection } from './DashboardUpperSection/DashboardUpperSection';
import { DashboardLeftSection } from './DashboardLeftSection/DashboardLeftSection';
import { DashboardRightSection } from './DashboardRightSection/DashboardRightSection';
import { DashboardBottomSection } from './DashboardBottomSection/DashboardBottomSection';
import { smallIdLicenseService } from '../../services/smallIdLicenseService';
import { SmallIdDashboard } from './SmallIdDashboard/SmallidDashboard';
import {
  CustomDashboardData,
  getCustomDashboardById,
  getCustomDashboards,
  getUserDefaultDashboard,
  CUSTOM_DASHBOARD_WRAPPER_CLASS_NAME,
  updateUserDefaultDashboard,
  addCustomDashboard,
} from '../../services/customDashboardService';
import { notificationService } from '../../services/notificationService';
import { CustomDashboardContainer } from '../CustomDashboard/CustomDashboardPreviewMode';
import { DashboardItem } from '@bigid-ui/custom-dashboard';
import { pageHeaderService } from '../../../common/services/pageHeaderService';
import { CustomDashboardMenu } from '../CustomDashboard/CustomDashboardMenu';
import { DomToPdfButton } from '../../components/DomToPdfButton';
import { isEmpty } from 'lodash';
import { BIGID_BI_EVENTS } from '../../config/BigIdBiEvents';
import { analyticsService } from '../../services/analyticsService';
import { $state } from '../../services/angularServices';
import { actionableInsightsDashboard } from '../CustomDashboard/TemplateSelector';
import { CONFIG } from '../../../config/common';
import { SECURITY_WORKSPACE_DASHBOARD_NAME } from '../../utilities/workspacesUtils';

const REGULAR_DASHBOARD_TITLE = 'Welcome to BigID';

const useStyles = makeStyles({
  leftSide: {
    width: '410px',
    height: '403px',
    flexGrow: 1,
    margin: '7px 16px 5px 0',
  },
  rightSide: {
    width: '624px',
    height: '400px',
    margin: '7px 0 5px 0',
    flexGrow: 1,
  },
});

const DefaultDashboard: FC = () => {
  const { leftSide, rightSide } = useStyles({});
  return (
    <DashboardCommon>
      <DashboardUpperSection />

      <div className={leftSide}>
        <DashboardLeftSection />
      </div>
      <div className={rightSide}>
        <DashboardRightSection />
      </div>

      <DashboardBottomSection />
    </DashboardCommon>
  );
};

const getDomToPdfProps = (customDashboardName: string, customDashboardDescription: string) => {
  const DEFAULT_DASHBOARD_NAME = 'Default';
  const fileName = customDashboardName || DEFAULT_DASHBOARD_NAME;
  const header = `Dashboard Report: ${customDashboardName || DEFAULT_DASHBOARD_NAME}`;
  const subHeader = customDashboardDescription || '';
  const selectorQueryToFindElementToCapture = `.${CUSTOM_DASHBOARD_WRAPPER_CLASS_NAME}`;
  return {
    fileName,
    header,
    subHeader,
    selectorQueryToFindElementToCapture,
  };
};

export const DashboardRegular: FC = () => {
  const [showDefaultDashboard, setShowDefaultDashboard] = useState<boolean>(false);
  const [isCustomDashboard, setIsCustomDashboard] = useState<boolean>(false);
  const [customDashboard, setCustomDashboard] = useState<CustomDashboardData>();
  const [dashboards, setDashboards] = useState<DashboardItem[]>();

  const isSecurityWorkspaceDashboard = $state.current.name === CONFIG.states.DASHBOARD_SECURITY;

  useEffect(() => {
    const init = async () => {
      try {
        const customDashboards = await getCustomDashboards();
        setDashboards(customDashboards);

        if (isSecurityWorkspaceDashboard) {
          let securityDashboard = null;
          let securityDashboardId = customDashboards?.find(
            (d: DashboardItem) => d.name === SECURITY_WORKSPACE_DASHBOARD_NAME,
          )?._id;

          if (!securityDashboardId) {
            const { id: newSecurityDashboardId } = await addCustomDashboard(
              JSON.stringify(actionableInsightsDashboard),
              SECURITY_WORKSPACE_DASHBOARD_NAME,
              '',
            );
            securityDashboardId = newSecurityDashboardId;
          }
          securityDashboard = await getCustomDashboardById(securityDashboardId);

          if (securityDashboard?.config) {
            securityDashboard.parsedConfig = JSON.parse(securityDashboard.config);
            setCustomDashboard(securityDashboard);
            setIsCustomDashboard(true);
            return;
          }
        }
      } catch (err) {
        console.error(err);
        notificationService.error(`Can't Initiate Dashboards Menu`);
      }
      const defaultDashboardId = 'Default';
      let customDashboardId = '';
      try {
        customDashboardId = await getUserDefaultDashboard();
        if (isEmpty(customDashboardId) || customDashboardId === defaultDashboardId) setShowDefaultDashboard(true);
        else {
          const customDashboard = await getCustomDashboardById(customDashboardId);
          if (customDashboard.config) {
            customDashboard.parsedConfig = JSON.parse(customDashboard.config);
            setCustomDashboard(customDashboard);
            setIsCustomDashboard(true);
            return;
          }
        }
      } catch (err) {
        if (customDashboardId !== defaultDashboardId) {
          updateUserDefaultDashboard(defaultDashboardId);
          const msg = 'Can’t display custom dashboard, please contact your admin.';
          console.error(msg, err);
          notificationService.error(msg);
        }
        setShowDefaultDashboard(true);
      }
    };
    init();
  }, []);

  const onDashboardMenuItemClick = async (dashboardId: string) => {
    if (dashboardId == 'Default') {
      setIsCustomDashboard(false);
      setShowDefaultDashboard(true);
      return;
    }
    try {
      analyticsService.trackManualEvent(BIGID_BI_EVENTS.DASHBOARD_SWITCH, {
        Dashboard_Name: dashboards.find(d => d._id === dashboardId)?.name,
      });

      const customDashboard = await getCustomDashboardById(dashboardId);
      if (customDashboard.config) {
        customDashboard.parsedConfig = JSON.parse(customDashboard.config);
        setCustomDashboard(customDashboard);
        setIsCustomDashboard(true);
        setShowDefaultDashboard(false);
      }
    } catch (err) {
      const msg = `Can't Present Selected Dashboard`;
      console.error(msg, err);
      notificationService.error(msg);
    }
  };

  useEffect(() => {
    const getDashboardPageTitle = () => {
      if (isSecurityWorkspaceDashboard) {
        return 'Security Dashboard';
      }
      return smallIdLicenseService.isSmallIDLicense() ? '' : REGULAR_DASHBOARD_TITLE;
    };

    const pageTitle = getDashboardPageTitle();
    const domToPdfProps = getDomToPdfProps(customDashboard?.name, customDashboard?.description);
    const rightSideComponentsContainer = (
      <div>
        {isCustomDashboard && <DomToPdfButton {...domToPdfProps} />}
        {!!dashboards?.length && !isSecurityWorkspaceDashboard && (
          <CustomDashboardMenu dashboards={dashboards} onMenuItemClick={onDashboardMenuItemClick} />
        )}
      </div>
    );

    pageHeaderService.setTitle({ pageTitle, rightSideComponentsContainer });
  }, [isCustomDashboard, customDashboard?.name, customDashboard?.description, dashboards, onDashboardMenuItemClick]);

  return (
    <>
      {isCustomDashboard && <CustomDashboardContainer config={customDashboard.parsedConfig} />}
      {showDefaultDashboard && <DefaultDashboard />}
    </>
  );
};

export const Dashboard: FC = () => {
  if (smallIdLicenseService.isSmallIDLicense()) {
    return <SmallIdDashboard />;
  }

  return <DashboardRegular />;
};
