import { BigidFormField } from '@bigid-ui/components';
import React, { FC, useMemo } from 'react';
import { BigidFormExtraField } from '../FormSection/BigidFormExtraField';
import { FormSection, FormSectionInitialStateProps } from '../FormSection/FormSection';
import { useReportTemplateContext } from '../hooks/ReportTemplateContext';

export const ThirdPartySection: FC = () => {
  const sectionName = 'thirdParties';
  const description = 'Display to which third party we transfer the data.';

  const { initialTemplateData } = useReportTemplateContext();

  const formSectionInitProps: FormSectionInitialStateProps = useMemo(() => {
    const {
      thirdParties: { ...initialValues },
    } = initialTemplateData;

    const fields: BigidFormField[] = [
      {
        render: BigidFormExtraField,
        name: 'parties',
        label: '',
        misc: {
          placeholder: '',
          fullWidth: true,
          addLabel: 'Add Third Party',
        },
      },
    ];

    const newInitProps: FormSectionInitialStateProps = {
      fields,
      initialValues,
    };

    return newInitProps;
  }, [initialTemplateData]);

  return <FormSection sectionName={sectionName} description={description} {...formSectionInitProps} />;
};
