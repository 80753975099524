import { noop } from 'lodash';
import { HashiCorpDialogComponent } from '../views/HashiCorpConfig/HashiCorpConfig';
import { httpService } from './httpService';
import { notificationService } from './notificationService';
import { openSystemDialog } from './systemDialogService';

export interface HashiCorpData {
  name: string;
  auth_method: string;
  url: string;
  bind_secret_id: boolean;
  secret_id?: string;
  role_id: string;
  _id?: string;
  [propName: string]: any;
}

interface HashiCorpService {
  showDialog: () => void;
  getConfiguration: () => Promise<HashiCorpData>;
  createConfiguration: (configuration: HashiCorpData, callback: (id: string) => void) => void;
  updateConfiguration: ({ configuration, id }: { configuration: any; id: string }) => void;
  testConfiguration: (configuration: HashiCorpData) => void;
  deleteConfiguration: (id: string) => void;
}

const getConfiguration = async (): Promise<HashiCorpData> => {
  const {
    data: { results },
  } = await httpService.fetch('credentials-providers?type=HashiCorp&limit=1');
  return results?.[0];
};

const createConfiguration = async (configuration: HashiCorpData, callback: (id: string) => void) => {
  try {
    const {
      data: { id },
    } = await httpService.post('credentials-providers', {
      data: JSON.stringify({ ...configuration, type: 'HashiCorp' }),
    });
    callback(id);
    notificationService.success('Configuration Saved');
  } catch (error) {
    notificationService.error('Failed to create configuration');
  }
};
const updateConfiguration = async ({ configuration, id }: { configuration: any; id: string }) => {
  try {
    await httpService.put(`credentials-providers/${id}`, {
      data: JSON.stringify({ ...configuration, type: 'HashiCorp' }),
    });
    notificationService.success('Configuration Saved');
  } catch (error) {
    notificationService.error('Failed to update configuration');
  }
};
const testConfiguration = async (configuration: HashiCorpData) => {
  try {
    await httpService.post('credentials-providers/connection/test', {
      data: JSON.stringify({ ...configuration, type: 'HashiCorp' }),
    });
    notificationService.success('Connection Success');
  } catch (error) {
    console.error(error);
    notificationService.error('Connection Failed');
  }
};

const deleteConfiguration = async (id: string) => {
  await httpService.delete(`credentials-providers/${id}`);
};

const dialogTitle = 'HashiCorp Password Vault';

const showDialog = () => {
  return openSystemDialog({
    title: dialogTitle,
    content: HashiCorpDialogComponent,
    onClose: noop,
  });
};

export const hashiCorpService: HashiCorpService = {
  showDialog,
  getConfiguration,
  createConfiguration,
  updateConfiguration,
  testConfiguration,
  deleteConfiguration,
};
