import { httpService } from '../../../../services/httpService';
import { LabelConfigurationRecord } from './LabelsConfigurations';

export type ActionType = 'Add' | 'Duplicate';

export interface LabelConfiguration {
  _id?: string;
  label_name: string;
  notes?: string;
  external_user?: boolean;
  external_link?: boolean;
  internal_user?: boolean;
  internal_link?: boolean;
  external_group?: boolean;
  global_group?: boolean;
  internal_group?: boolean;
  organization_group?: boolean;
  groups?: string[];
  users_count?: number | null;
  users_operand?: string;
  objects_count?: number;
  is_immutable?: boolean;
  modifiedAt?: Date;
  createdAt?: Date;
}

export interface LabelConfigurationResponse {
  data: LabelConfiguration[];
}

export const getLabels = () => {
  return httpService.fetch<{ data: LabelConfiguration[] }>(`aci/coa`).then(({ data }) => data.data);
};

export const getAllLabels = () => {
  return httpService.fetch<{ data: LabelConfiguration[] }>(`aci/coa/all-labels`).then(({ data }) => data.data);
};

export const getLabelById = (labelName: string) => {
  return httpService.fetch<{ data: LabelConfigurationRecord }>(`aci/coa/${labelName}`).then(({ data }) => data.data);
};

export const createLabel = (labelName: string) => {
  return httpService.post(`aci/coa`, { data: [{ label_name: labelName }] }).then(({ data }) => data);
};

export const updateLabel = (label: LabelConfigurationRecord) => {
  const { _id, createdAt, modifiedAt, ...requestPayload } = label;
  return httpService.put(`aci/coa/${label.label_name}`, { ...requestPayload }).then(({ data }) => data);
};

export const duplicateLabel = (label: LabelConfigurationRecord) => {
  const { _id, createdAt, modifiedAt, ...requestPayload } = label;
  return httpService.post(`aci/coa`, { data: [{ ...requestPayload }] }).then(({ data }) => data);
};

export const deleteLabel = (labelName: string) => {
  return httpService
    .delete(`aci/coa/${encodeURIComponent(labelName)}`, { label_name: labelName })
    .then(({ data }) => data);
};
