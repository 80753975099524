import React, { Dispatch, FC, SetStateAction, useCallback, useMemo, useRef, useState } from 'react';
import { BigidColorsV2, BigidMenu, BigidMenuProps, BigidTooltip } from '@bigid-ui/components';
import { infinitePalette, RowWithActions } from './BigidHorizontalBarWithActions';
import { BigidHorizontalBarWithActionsRowTooltip } from './BigidHorizontalBarWithActionsRowTooltip';
import styled from '@emotion/styled';
import { IconButton } from '@mui/material';
import { BigidMoreActionIcon } from '@bigid-ui/icons';
import { generateDataAid } from '@bigid-ui/utils';

export interface BigidHorizontalBarWithActionsRowProps {
  rowWithActions: RowWithActions;
  index: number;
  hoveredElement: number;
  setHoveredElement: Dispatch<SetStateAction<number>>;
  longestBarLength: number;
}

const BarTooltip = styled(BigidTooltip)({
  maxWidth: '100%',
  whiteSpace: 'nowrap',
});

const NameWrapper = styled('div')`
  display: flex;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  align-items: center;
  height: 32px;
  justify-content: space-between;
`;

const Name = styled('div')<{ isHovered: boolean }>`
  color: ${BigidColorsV2.gray[700]};
  font-size: 0.8125rem;
  text-decoration: ${props => (props.isHovered ? 'underline' : undefined)};
  overflow: hidden;
  text-overflow: ellipsis;
`;

const Bar = styled('div')<{ width: number; color: string }>`
  width: ${props => props.width}%;
  background-color: ${props => props.color};
  max-width: 100%;
  white-space: nowrap;
  height: 12px;
  border-radius: 6px;
  transition: width 1s ease;
`;

const ButtonWrapper = styled('div')<{ isVisible: boolean }>`
  display: ${props => (!props.isVisible ? 'none' : undefined)};
`;

const Value = styled('div')({
  textAlign: 'right',
  fontSize: 12,
  fontWeight: 700,
});

const dataAid = 'BigidHorizontalBarWithActionsRow';

export const BigidHorizontalBarWithActionsRow: FC<BigidHorizontalBarWithActionsRowProps> = ({
  rowWithActions: {
    row: { name, value, valueLabel, color, tooltip },
    actions,
  },
  setHoveredElement,
  hoveredElement,
  index,
  longestBarLength,
}) => {
  const [open, setOpen] = useState(false);
  const anchorRef = useRef();
  const handleClick = useCallback(() => {
    setHoveredElement(-1);
    setOpen(open => !open);
  }, [setHoveredElement]);

  const menuProps: BigidMenuProps = useMemo(() => {
    return {
      open,
      onMenuClose: () => {
        setOpen(false);
        setHoveredElement(-1);
      },
      anchorEl: anchorRef.current,
      anchorOrigin: {
        vertical: 'bottom',
        horizontal: 'right',
      },
      transformOrigin: {
        vertical: 'top',
        horizontal: 'right',
      },
    };
  }, [open, setHoveredElement]);

  const handleMouseOver = useCallback(() => {
    setHoveredElement(index);
  }, [index, setHoveredElement]);

  const handleMouseOut = useCallback(() => {
    setHoveredElement(-1);
  }, [setHoveredElement]);

  return (
    <>
      <BarTooltip title={<BigidHorizontalBarWithActionsRowTooltip {...tooltip} />} isDisabled={!tooltip}>
        <Bar color={color || infinitePalette(index)} width={longestBarLength ? (value / longestBarLength) * 100 : 0} />
      </BarTooltip>
      <Value data-aid={generateDataAid(dataAid, ['value', index + ''])}>{valueLabel || value}</Value>
      <NameWrapper title={name} onMouseOver={handleMouseOver} onMouseOut={handleMouseOut} ref={anchorRef}>
        <Name isHovered={hoveredElement === index} data-aid={generateDataAid(dataAid, ['name', index + ''])}>
          {name}
        </Name>
        <ButtonWrapper
          isVisible={actions?.length > 0 && hoveredElement === index}
          data-aid={generateDataAid(dataAid, ['menu-button', index + ''])}
        >
          <IconButton onClick={handleClick}>
            <BigidMoreActionIcon />
          </IconButton>
          <BigidMenu {...menuProps} items={actions} />
        </ButtonWrapper>
      </NameWrapper>
    </>
  );
};
