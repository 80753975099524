import { BigidLoader, BigidSecondaryMenu } from '@bigid-ui/components';
import React, { Dispatch, FC, memo, SetStateAction, useContext, useMemo } from 'react';
import styled from '@emotion/styled';
import { SelectDataSourceOnboardingTypeContext } from '../../SelectDataSourceOnboardingTypeContext';
import { CategoriesState, DataSourceOnboardingCategoriesKeys } from '../../types';
import { DATA_SOURCE_ONBOARDING_CATEGORIES_CONFIG } from '../../config';
import { useSetInObjectState } from '../../hooks/useSetInObjectState';

const MENU_WIDTH = '300px';
export const DataSourceOnboardingCategoriesMenuWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  padding: 12px;
  border-radius: 0;
  min-width: 324px;
`;

export const DataSourceOnboardingCategoriesMenuFooter = styled('div')`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  flex: 1 0 auto;
  padding-bottom: 12px;
`;
export const DataSourceOnboardingCategoriesMenu: FC = memo(() => {
  const { categoriesState, selectedCategory, setState } = useContext(SelectDataSourceOnboardingTypeContext);
  const menuItems = useMemo(() => transformCategoryStateToMenuItems(categoriesState), [categoriesState]);
  const setSelectedMenuItem = useSetInObjectState(setState, selectedCategory => ({ selectedCategory })) as Dispatch<
    SetStateAction<string>
  >;

  return (
    <DataSourceOnboardingCategoriesMenuWrapper data-aid={'DataSourceOnboardingCategoriesMenu'}>
      {!menuItems?.length ? (
        <BigidLoader />
      ) : (
        <BigidSecondaryMenu
          hideRightBorder={true}
          variant={'extended'}
          selectedMenuItem={selectedCategory}
          setSelectedMenuItem={setSelectedMenuItem}
          menuItems={menuItems}
          menuWidth={MENU_WIDTH}
        />
      )}
      <DataSourceOnboardingCategoriesMenuFooter>
        {/*<TertiaryButton*/}
        {/*  size={'large'}*/}
        {/*  onClick={() => console.log('Add manual data source')}*/}
        {/*  text={'Add manual data source'}*/}
        {/*  startIcon={<BigidDataSourceAddIcon />}*/}
        {/*/>*/}
      </DataSourceOnboardingCategoriesMenuFooter>
    </DataSourceOnboardingCategoriesMenuWrapper>
  );
});

function transformCategoryStateToMenuItems(categoriesState: CategoriesState) {
  return categoriesState
    ? Object.entries(categoriesState).map(([name, { isLoading }]) => ({
        name,
        displayName: `${DATA_SOURCE_ONBOARDING_CATEGORIES_CONFIG[name as DataSourceOnboardingCategoriesKeys].label}${
          isLoading ? ' - Loading...' : ''
        }`,
      }))
    : [];
}
