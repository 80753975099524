import { module } from 'angular';
const app = module('app');

function lazyLoadHighcharts() {
  return Promise.all([
    import(/* webpackChunkName: "highcharts" */ 'highcharts'),
    import(/* webpackChunkName: "highchartsHeatmap" */ 'highcharts/modules/heatmap'),
    import(/* webpackChunkName: "highchartsTreemap" */ 'highcharts/modules/treemap'),
  ]).then(([Highcharts, { default: highchartsHeatmap }, { default: highchartsTreemap }]) => {
    highchartsHeatmap(Highcharts);
    highchartsTreemap(Highcharts);
    return Highcharts;
  });
}

app.component('treemap', {
  template:
    ' <div style="width:100%;height: 590px;box-shadow: 0 0 3px rgba(0, 0, 0, .1);border-radius: 4px;" id="myChart"></div>' +
    '<div class="spinnerWidget" ng-show="treemapModel.loading"> <div class="spinner-label-wrapper"> <div class="spinner-label">This might take few seconds</div> </div> </div>',
  controllerAs: 'treemapModel',
  controller: [
    '$q',
    '$scope',
    '$window',
    '$document',
    '$rootScope',
    'treeMapService',
    function ($q, $scope, $window, $document, $rootScope, treeMapService) {
      let treemapModel = this;
      treemapModel.resultAll = [];
      this.$onChanges = function () {
        treemapModel.loading = true;
        treeMapService.getTreeMapData().then(function (result) {
          treemapModel.loading = false;
          treemapModel.resultAll = result;
          result = result.filter(item => !item.parent);
          result.forEach(item => {
            const childrenCount = treemapModel.resultAll.filter(result => result.parent === item.id);
            if (childrenCount && childrenCount.length > 0) {
              item.drilldown = true;
            }
          });

          $q.resolve(lazyLoadHighcharts()).then(Highcharts => {
            Highcharts.chart('myChart', {
              resultAll: treemapModel.resultAll,
              plotOptions: {
                series: {
                  animation: false,
                  turboThreshold: 0,
                  drillUpButton: {
                    theme: {
                      fill: 'white',
                      states: {
                        hover: {
                          fill: 'white',
                        },
                      },
                    },
                  },
                },
              },
              credits: {
                enabled: false,
              },
              tooltip: {
                backgroundColor: 'black',
                style: {
                  color: 'white',
                  font: 'Muli',
                },
                borderColor: 'black',
                borderRadius: 5,
                formatter: function () {
                  return this.point.value + ' PII Records in ' + this.point.nameNoCounter;
                },
              },
              colorAxis: {
                minColor: '#ffcfab', //Highcharts.getOptions().colors[3],
                maxColor: '#ff5e43', //'#FF5E43',
              },
              legend: {
                enabled: false,
              },
              series: [
                {
                  type: 'treemap',
                  layoutAlgorithm: 'squarified',
                  allowDrillToNode: true,
                  animation: false,
                  dataLabels: {
                    enabled: false,
                  },
                  levelIsConstant: false,
                  levels: [
                    {
                      level: 1,
                      dataLabels: {
                        enabled: true,
                      },
                      borderWidth: 3,
                    },
                  ],
                  name: 'Root',
                  data: result,
                },
              ],
              title: {
                text: 'All Data Sources',
                margin: 1,
                style: {
                  fontSize: '24px',
                  fontWeight: 700,
                  display: 'block',
                },
              },
              chart: {
                events: {
                  drilldown: function (e) {
                    const chart = this;
                    if (e.point.id) {
                      const arr = this.options.resultAll.filter(result => result.parent === e.point.id);
                      const dataArr = arr.map(item => ({
                        id: item.id,
                        name: item.name,
                        value: item.value,
                        nameNoCounter: item.nameNoCounter,
                      }));
                      dataArr.forEach(item => {
                        const childrenCount = treemapModel.resultAll.filter(result => result.parent === item.id);
                        if (childrenCount && childrenCount.length > 0) {
                          item.drilldown = true;
                        }
                      });

                      chart.showLoading('This might take few seconds');
                      setTimeout(function () {
                        chart.hideLoading();
                        chart.addSeriesAsDrilldown(e.point, {
                          type: 'treemap',
                          layoutAlgorithm: 'squarified',
                          id: e.point.id,
                          name: e.point.name,
                          data: dataArr,
                          value: e.point.value,
                          nameNoCounter: e.point.nameNoCounter,
                        });
                      }, 1000);
                    }
                  },
                },
              },
            });
          });
        });
      };
    },
  ],
  bindings: {
    onRenderCompleted: '&',
    onRenderStarted: '&',
  },
});
