import React from 'react';
import { createChatBotMessage } from 'react-chatbot-kit';
import { BigidColorsV2 } from '@bigid-ui/components';
import { BigChatFeedback } from './widgets/BigChatFeedback';
import { BigChatBotAvatar, BigChatUserAvatar } from './widgets/BigChatAvatars';
import { BigChatHeader } from './widgets/BigChatHeader';
import { BigChatLinks } from './widgets/BigChatLinks';
import { BigChatReset } from './widgets/BigChatReset';
import { BIGCHAT_WIDGETS } from './BigChatTypes';
import { getFixedT } from './translations';
import { IMessage } from 'react-chatbot-kit/src/interfaces/IMessages';

export const getInitialMessage = (): IMessage => {
  const message = createChatBotMessage(`${getFixedT('chatbot')('initialMessage')}`, {});
  return { ...message, loading: false };
};

export const getConfig = (setDisableOpenChat: React.Dispatch<React.SetStateAction<boolean>>) => {
  return { ...config, state: { setDisableOpenChat: setDisableOpenChat } };
};
export const config = {
  initialMessages: [getInitialMessage()],
  botName: 'BigChat',
  customStyles: {
    botMessageBox: {
      backgroundColor: BigidColorsV2.gray[150],
    },
    chatButton: {
      backgroundColor: BigidColorsV2.purple[900],
    },
  },
  state: {},
  customComponents: {
    header: (props: any) => <BigChatHeader />,
    botAvatar: () => <BigChatBotAvatar />,
    userAvatar: () => <BigChatUserAvatar />,
  },
  customMessages: {
    [BIGCHAT_WIDGETS.LINKS]: (props: any) => <BigChatLinks {...props} />,
    [BIGCHAT_WIDGETS.RESET]: (props: any) => <BigChatReset {...props} />,
  },
  widgets: [
    {
      widgetName: BIGCHAT_WIDGETS.FEEDBACK,
      widgetFunc: (props: any) => <BigChatFeedback {...props} />,
      mapStateToProps: [''],
      props: {},
    },
  ],
};
