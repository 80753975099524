import { BigidFormField } from '@bigid-ui/components';
import React, { FC, useMemo } from 'react';
import { BigidFormExtraField } from '../FormSection/BigidFormExtraField';
import { FormSection, FormSectionInitialStateProps } from '../FormSection/FormSection';
import { useReportTemplateContext } from '../hooks/ReportTemplateContext';

export const CollectionMechanismSection: FC = () => {
  const sectionName = 'mechanisms';
  const description = 'Display how we collect the data';

  const { initialTemplateData } = useReportTemplateContext();

  const formSectionInitProps: FormSectionInitialStateProps = useMemo(() => {
    const {
      mechanisms: { ...initialValues },
    } = initialTemplateData;

    const fields: BigidFormField[] = [
      {
        render: BigidFormExtraField,
        name: 'mechanisms',
        label: '',
        misc: {
          placeholder: 'e.g Website Cookies',
          fullWidth: true,
          addLabel: 'Add Mechanisms',
        },
      },
    ];

    const newInitProps: FormSectionInitialStateProps = {
      fields,
      initialValues,
    };

    return newInitProps;
  }, [initialTemplateData]);

  return <FormSection sectionName={sectionName} description={description} {...formSectionInitProps} />;
};
