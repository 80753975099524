import { styled } from '@mui/material';

type ListItemProps = {
  width?: string;
};

export const BaseFooterSectionContainer = styled('div')<ListItemProps>`
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 25%;
  padding: 0 20px;
`;
