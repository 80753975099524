import {
  BigidLoader,
  BigidTextField,
  EntityEvents,
  entityEventsEmitter,
  PrimaryButton,
  SecondaryButton,
} from '@bigid-ui/components';
import { FormControl, FormLabel } from '@mui/material';
import React, { FC, useEffect, useState } from 'react';
import { v4 as uuid } from 'uuid';
import { $state } from '../../../../services/angularServices';
import { openSystemDialog, SystemDialogContentProps } from '../../../../services/systemDialogService';
import { createFlow, deleteFlow, getAllFlows, getFlow, updateDefaultFlow } from '../attributesEnrichmentService';
import { AttributesEnrichmentCreateFlowItem, MappingFlowItem } from '../Types';

export interface ProfileManagementDialogProps {
  initialValue: string;
  onNameChange: (name: string) => void;
}

const RESERVED_NAMES = ['id', 'name'];

export const NewFlowDialogContent: FC<SystemDialogContentProps<ProfileManagementDialogProps>> = ({
  initialValue,
  onNameChange,
}) => {
  const [allNames, setAllNames] = useState<string[]>();
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    const initAllNames = async () => {
      const allNames = (await getAllFlows()).map(({ name }) => name);
      setAllNames(allNames);
    };
    initAllNames();
  }, []);

  const isInvalidName = (name: string) => {
    return !name?.length || !name.match(/^[_a-zA-Z0-9]{2,}$/);
  };

  const isNameTaken = (name: string) => allNames.includes(name);

  const handleNameChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    if (!allNames) {
      return;
    }
    const newName = event?.target?.value.trim();

    onNameChange('');
    if (isInvalidName(newName)) {
      setErrorMessage(`Invalid name, only [_a-zA-Z0-9] characters allowed`);
    } else if (RESERVED_NAMES.includes(newName)) {
      setErrorMessage(`Input "${newName}" is reserved and can't be used`);
    } else if (isNameTaken(newName)) {
      setErrorMessage(`Input name already exist`);
    } else {
      setErrorMessage('');
      onNameChange(newName);
    }
  };

  return !allNames ? (
    <BigidLoader />
  ) : (
    <div>
      <FormControl fullWidth margin="normal" style={{ minHeight: 80 }}>
        <FormLabel style={{ marginTop: 5 }}>{'Input name'}</FormLabel>
        <BigidTextField autoFocus defaultValue={initialValue} onChange={handleNameChange} errorMessage={errorMessage} />
      </FormControl>
    </div>
  );
};

export const openCreateNewInputDialog = async () => {
  const id = uuid();
  const draftFlow = {
    id: `draft-${id}`,
    name: `input_${id.substr(0, 3)}`,
    isDraft: true,
  };
  openSystemDialog({
    title: 'Create new Input',
    content: NewFlowDialogContent,
    contentProps: {
      initialValue: draftFlow.name,
      onNameChange: name => (draftFlow.name = name),
    },
    onClose: () => null,
    buttons: [
      {
        text: 'Create',
        component: PrimaryButton,
        onClick: () => {
          if (draftFlow.name) {
            entityEventsEmitter.emit(EntityEvents.ADD, [draftFlow]);
          }
        },
        isClose: true,
      },
      {
        text: 'Cancel',
        component: SecondaryButton,
        onClick: () => null,
        isClose: true,
      },
    ],
  });
};

export const openEditDialog = (selectedItem: MappingFlowItem) => {
  const newItem = { ...selectedItem };
  return new Promise(res => {
    openSystemDialog({
      title: `Change Input "${selectedItem.name}"`,
      content: NewFlowDialogContent,
      contentProps: {
        initialValue: selectedItem.name,
        onNameChange: name => (newItem.name = name),
      },
      onClose: () => null,
      buttons: [
        {
          text: 'Save',
          component: PrimaryButton,
          onClick: async () => {
            let shouldGridReload = false;
            if (selectedItem.name && newItem.name && newItem.name !== selectedItem.name) {
              await renameFlow(selectedItem.name, newItem.name);
              shouldGridReload = true;
              $state.go('.', { ...$state.params, flowName: newItem.name }, { reload: false, notify: false });
            }
            res({ shouldClearSelection: false, shouldGridReload });
          },
          isClose: true,
        },
        {
          text: 'Cancel',
          component: SecondaryButton,
          onClick: () => res({}),
          isClose: true,
        },
      ],
    });
  });
};

const renameFlow = async (oldName: string, newName: string) => {
  const flows = await getFlow(oldName);
  const newFlows: AttributesEnrichmentCreateFlowItem[] = flows.map(({ source, fieldsMapping }) => {
    const flow: AttributesEnrichmentCreateFlowItem = {
      source,
      fieldsMapping: fieldsMapping.map(({ origin, from, ...mapping }) => {
        return {
          ...mapping,
          origin,
          from: origin === 'input' && from === oldName ? newName : from,
        };
      }),
    };
    return flow;
  });
  await createFlow(newName, newFlows);
  await deleteFlow(oldName);
  await updateDefaultFlow();
};
