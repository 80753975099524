import { useEffect, useMemo, useState } from 'react';
import { BigidDropdownOption } from '@bigid-ui/components';
import { getCertificates } from '../../CertificatesManagement/CertificatesManagement';
import { notificationService } from '../../../services/notificationService';
import { isPermitted } from '../../../services/userPermissionsService';
import { CERTIFICATES_PERMISSIONS } from '@bigid/permissions';
import { useLocalTranslation } from '../translations';

export const useCertificates = () => {
  const [certificateOptions, setCertificateOptions] = useState<BigidDropdownOption[]>([]);
  const [isCertificatesLoading, setIsCertificatesLoading] = useState(true);
  const { t } = useLocalTranslation();

  const canViewCertificates = useMemo(() => isPermitted(CERTIFICATES_PERMISSIONS.READ.name), []);

  useEffect(() => {
    const getCertificatesOptions = async () => {
      try {
        const { results = [] } = await getCertificates('');
        setCertificateOptions(
          results
            .filter(({ type }) => type === 'pem')
            .map(({ name }) => ({ displayValue: name, value: name, id: name })),
        );
      } catch (error) {
        console.error(error);
        notificationService.error(t('message.commonError'));
      } finally {
        setIsCertificatesLoading(false);
      }
    };
    if (canViewCertificates) {
      (async () => await getCertificatesOptions())();
    } else {
      setIsCertificatesLoading(false);
      setCertificateOptions([]);
    }
  }, [canViewCertificates, t]);

  return { certificateOptions, isCertificatesLoading };
};
