import { useState, useEffect } from 'react';
import { commonMethods, $state } from '../../../services/angularServices';
import { DashboardUpperSectionItemData } from '../DashboardUpperSection/DashboardUpperSectionCommon';
import { goToCatalogWithChangePreferences } from '../goToCatalogWithChangePreferences';
import { CATALOG_PERMISSIONS } from '@bigid/permissions';
import { isPermitted } from '../../../services/userPermissionsService';
import { CONFIG } from '../../../../config/common';
import { loginService } from '../../../../authentication/login/login.service';
import { getDataCatalogRecordsCount } from '../../DataCatalog/DataCatalogService';
import { BigidDataObjectIllustration } from '@bigid-ui/icons';

interface SummaryByTypesItem {
  count?: number;
}

const FILTER_BY_OBJECTS_WITH_FINDINGS = 'filter=contains_pi%20%3D%20true';

const objectsDisplayData = (data: SummaryByTypesItem): DashboardUpperSectionItemData => {
  const disabled = !isPermitted(CATALOG_PERMISSIONS.ACCESS.name);
  const count = data.count || 0;
  return {
    value: count ? commonMethods.getAbbrNum(count, 1) : '',
    description: count ? 'Objects with Findings' : 'Configure Scan',
    onClick: () => {
      if (count) {
        goToCatalogWithChangePreferences({
          sideFilterSelection: [
            {
              field: 'contains_pi',
              operator: 'equal',
              value: 'true',
            },
          ],
          optionalSideFilterSections: [],
          searchQuery: '',
        });
      } else {
        $state.go(CONFIG.states.SCANS);
      }
    },
    disabled,
    Icon: BigidDataObjectIllustration,
    isLoading: true,
    id: 'objects',
  };
};

interface ObjectsDataNaiveCacheInterface {
  isNotInitiated: boolean;
  objectsData: DashboardUpperSectionItemData;
}

const naiveCacheInitialState: ObjectsDataNaiveCacheInterface = {
  isNotInitiated: true,
  objectsData: objectsDisplayData({}),
};

let naiveCache: ObjectsDataNaiveCacheInterface = { ...naiveCacheInitialState };

export const useObjectsData = () => {
  const [objectsData, setObjectsData] = useState(naiveCache.objectsData);
  const [isLoading, setIsObjectsLoading] = useState(naiveCache.isNotInitiated);

  useEffect(() => {
    getDataCatalogRecordsCount(FILTER_BY_OBJECTS_WITH_FINDINGS)
      .then(summaryByTypes => {
        const displayData = objectsDisplayData(summaryByTypes);
        naiveCache = { ...naiveCache, isNotInitiated: false, objectsData: displayData };
        setObjectsData(displayData);
      })
      .finally(() => {
        setIsObjectsLoading(false);
      })
      .catch(() => {
        setObjectsData(current => ({ ...current, disabled: true }));
      });

    return () => {
      if (!loginService.isLoggedIn()) {
        naiveCache = { ...naiveCacheInitialState };
      }
    };
  }, [setObjectsData]);

  return {
    ...objectsData,
    isLoading,
  };
};
