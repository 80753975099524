import React from 'react';
import styled from '@emotion/styled';
import { BigidBody1 } from '@bigid-ui/components';
import { useTheme } from '@mui/styles';
import { MappedControlsProps, NormalizedMappedControlsProps } from './MappedControlsWidget';
import { frameworkIconGenerator } from '../../../../react/views/Frameworks/utils';

const Main = styled.div({
  maxHeight: '600px',
  overflowY: 'scroll',
});

const MainWrapper = styled.div({
  display: 'flex',
  flexDirection: 'column',
  width: '400px',
  maxHeight: '335px',
  padding: '16px 32px 16px 16px',
  rowGap: '20px',
});

const MainWrapperTitle = styled.div({
  display: 'flex',
  columnGap: '8px',
  alignItems: 'center',
});

export const MappedControlsTooltip = ({ widgetFrameworkName, controls }: NormalizedMappedControlsProps) => {
  const Icon = frameworkIconGenerator(widgetFrameworkName);
  const Theme = useTheme();

  return (
    <Main>
      <MainWrapper>
        <MainWrapperTitle>
          <Icon width={28} height={28} />
          <BigidBody1>{widgetFrameworkName}</BigidBody1>
          <BigidBody1 color={Theme.palette.bigid.gray500}>{`${controls.length} control${
            controls.length > 1 ? 's' : ''
          }`}</BigidBody1>
        </MainWrapperTitle>
        {controls.map((control: MappedControlsProps) => (
          <div key={control.name}>
            <BigidBody1>{control.name}</BigidBody1>
            <BigidBody1 color={Theme.palette.bigid.gray500}>{control.description}</BigidBody1>
          </div>
        ))}
      </MainWrapper>
    </Main>
  );
};
