import React, { FC } from 'react';
import { useLocalTranslation } from '../translations';
import { CatalogDiscoveryGuidedTourStep } from '../CatalogDiscoveryGuidedTourStep';

interface FiltersProps {
  dataAid: string;
}

export const Filters: FC<FiltersProps> = ({ dataAid }) => {
  const { t } = useLocalTranslation('steps.Filters');

  return <CatalogDiscoveryGuidedTourStep dataAid={dataAid} body={t('body')} />;
};
