import React, { FC, useState, useEffect, ChangeEvent, useMemo, useCallback } from 'react';
import {
  BigidPaper,
  objectToQueryString,
  QueryParams,
  BigidHeading5,
  BigidDialog,
  SecondaryButton,
  PrimaryButton,
  BigidRadio,
  BigidTextField,
  BigidColors,
  BigidEditableChipsAreaValue,
  BigidContentItem,
} from '@bigid-ui/components';
import {
  BigidGrid,
  BigidGridProps,
  BigidGridRow,
  BigidGridColumnTypes,
  useFetch,
  BigidGridColumn,
} from '@bigid-ui/grid';
import makeStyles from '@mui/styles/makeStyles';
import {
  getColumnBusinessAttributeSuggestions,
  ColumnBusinessAttributeSuggestion,
  addColumnBusinessAttribute,
  addClusterBusinessAttribute,
  UseColumnBusinessAttributeSuggestionPayload,
  UseClusterBusinessAttributeSuggestionPayload,
} from '../DataCatalogColumnsService';
import { notificationService } from '../../../../services/notificationService';
import { sessionStorageService } from '../../../../../common/services/sessionStorageService';
import { isEqual } from 'lodash';
import { ColumnWidgetsWrapper } from '../widgets/ColumnWidgetsWrapper';
import classNames from 'classnames';
import { CatalogEventsEnum } from '../../events';
import { analyticsService } from '../../../../services/analyticsService';

export interface BusinessAttributeDialogProps {
  clusterId?: string;
  fullyQualifiedName: string;
  columnName: string;
  businessAttribute?: BigidEditableChipsAreaValue;
  isOpen: boolean;
  onApplyToColumn?: (businessAttribute?: string) => void;
  onApplyToCluster?: (businessAttribute?: string) => void;
  onClose?: () => void;
  dsType: string;
  dsName: string;
  scanner_type_group: string;
}

const COLUMN_WIDGET_WIDTH = 335;

const useStyles = makeStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
  },
  header: {
    marginBottom: '15px',
  },
  body: {
    display: 'flex',
    flexDirection: 'column',
  },
  section: {
    display: 'flex',
    marginBottom: '20px',
    width: '100%',
    '&:not(:last-child)': {
      '& $sectionToggler': {
        marginBottom: '10px',
      },
    },
  },
  sectionToggler: {},
  sectionContent: {},
  sectionContentPrimary: {
    display: 'flex',
    width: '100%',
    height: '100%',
  },
  sectionContentPrimaryShrinked: {
    width: `calc(100% - ${COLUMN_WIDGET_WIDTH}px)`,
    paddingRight: '16px',
  },
  sectionContentOptional: {
    width: `${COLUMN_WIDGET_WIDTH}px`,
    display: 'flex',
    height: '100%',
  },
  grid: {
    flexDirection: 'column',
    '& $sectionContent': {
      display: 'flex',
      width: '100%',
      height: '300px',
    },
  },
  manualValue: {
    flexDirection: 'row',
    '& $sectionContent': {
      flex: 1,
    },
  },
  error: {
    color: `${BigidColors.failureRed} !important`,
    fontSize: '0.8125rem',
    whiteSpace: 'nowrap',
    marginTop: '5px',
  },
});

type BusinessAttributeGridColumn = BigidGridRow & ColumnBusinessAttributeSuggestion;

enum BusinessAttributeDialogSection {
  SUGGESTED = 'suggested',
  MANUAL = 'manual',
  CLEAR = 'clear',
}

const handleBiEventSend = (sectionSelected: BusinessAttributeDialogSection, trackData: Record<string, unknown>) => {
  if (sectionSelected === BusinessAttributeDialogSection.CLEAR) {
    analyticsService.trackManualEvent(CatalogEventsEnum.CATALOG_REMOVE_BUSINESS_ATTRIBUTE, trackData);
  } else {
    analyticsService.trackManualEvent(CatalogEventsEnum.CATALOG_ADD_BUSINESS_ATTRIBUTE, trackData);
  }
};

type BusinessAttributeDialogPayload = { [section in BusinessAttributeDialogSection]?: string };

const validateFriendlyName = (
  sectionSelected: BusinessAttributeDialogSection,
  dialogPayload: BusinessAttributeDialogPayload,
): string => {
  let message: string;
  const { suggested, manual } = dialogPayload || {};

  if (sectionSelected === BusinessAttributeDialogSection.SUGGESTED) {
    message = suggested?.length > 0 ? undefined : 'Please select suggested Business Term';
  }

  if (sectionSelected == BusinessAttributeDialogSection.MANUAL) {
    if (manual?.length > 0) {
      const regex = new RegExp(/^[A-Za-z\d](?:[A-Za-z\d_\.:\-\t ]{0,126}[A-Za-z\d_\.:\-])?$/);
      message = regex.test(manual) ? undefined : 'Invalid manual Business Term';
    } else {
      message = 'Please enter manual Business Term';
    }
  }

  return message;
};

const getFriendlyName = (
  sectionSelected: BusinessAttributeDialogSection,
  dialogPayload: BusinessAttributeDialogPayload,
): string => {
  const { suggested, manual, clear } = dialogPayload || {};

  if (sectionSelected === BusinessAttributeDialogSection.SUGGESTED) {
    return suggested;
  }

  if (sectionSelected === BusinessAttributeDialogSection.MANUAL) {
    return manual;
  }

  if (sectionSelected === BusinessAttributeDialogSection.CLEAR) {
    return clear;
  }

  return undefined;
};

export const BusinessAttributeDialog: FC<BusinessAttributeDialogProps> = ({
  clusterId,
  businessAttribute,
  fullyQualifiedName,
  columnName,
  isOpen,
  onClose,
  onApplyToColumn,
  onApplyToCluster,
  dsName,
  dsType,
  scanner_type_group,
}) => {
  const classes = useStyles({});

  const [dialogPayload, setDialogPayload] = useState<BusinessAttributeDialogPayload>();
  const [sectionSelected, setSectionSelected] = useState<BusinessAttributeDialogSection>();
  const [errorMessage, setErrorMessage] = useState<string>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [selectedItem, setSelectedItem] = useState<BigidContentItem>(null);
  const [isColumnWidgetEnabled, setIsColumnWidgetEnabled] = useState<boolean>(false);

  const resetDialogState = (): void => {
    setDialogPayload(undefined);
    setErrorMessage(undefined);
  };

  const handleOnDialogApplyToColumn = async () => {
    try {
      setIsLoading(true);

      const validationMessage = validateFriendlyName(sectionSelected, dialogPayload);
      const isFriendlyNameValid = !Boolean(validationMessage);

      setErrorMessage(validationMessage);

      if (isFriendlyNameValid) {
        const friendlyName = getFriendlyName(sectionSelected, dialogPayload);
        const payload: UseColumnBusinessAttributeSuggestionPayload = {
          fullyQualifiedName,
          columnName,
          friendlyName,
        };
        await addColumnBusinessAttribute(payload);
        onApplyToColumn(friendlyName);
      }
    } catch ({ message }) {
      notificationService.error('An error has occurred');
      console.error(`An error has occurred: ${message}`);
    } finally {
      const trackData = {
        fullyQualifiedName,
        dsType,
        dsName,
        columnName,
        scanner_type_group,
      };
      handleBiEventSend(sectionSelected, trackData);
      setIsLoading(false);
    }
  };

  const handleOnDialogApplyToCluster = async () => {
    try {
      setIsLoading(true);

      const validationMessage = validateFriendlyName(sectionSelected, dialogPayload);
      const isFriendlyNameValid = !Boolean(validationMessage);

      setErrorMessage(validationMessage);

      if (isFriendlyNameValid) {
        const friendlyName = getFriendlyName(sectionSelected, dialogPayload);
        const payload: UseClusterBusinessAttributeSuggestionPayload = {
          clusterId,
          friendlyName,
        };
        await addClusterBusinessAttribute(payload);
        onApplyToCluster(friendlyName);
      }
    } catch ({ message }) {
      notificationService.error('An error has occurred');
      console.error(`An error has occurred: ${message}`);
    } finally {
      const trackData = {
        fullyQualifiedName,
        dsType,
        dsName,
        columnName,
        scanner_type_group,
      };
      handleBiEventSend(sectionSelected, trackData);
      setIsLoading(false);
    }
  };

  const handleSectionSelectedChange = (event: ChangeEvent<any>) => {
    const { value } = event.target;
    setSectionSelected(value);
    setErrorMessage(undefined);

    if (value === BusinessAttributeDialogSection.CLEAR) {
      setDialogPayload({ [BusinessAttributeDialogSection.CLEAR]: '' });
    }

    if (value === BusinessAttributeDialogSection.SUGGESTED || value === BusinessAttributeDialogSection.MANUAL) {
      setDialogPayload(undefined);
    }
  };

  const handleManualAttributeChange = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void => {
    const { value } = event.target;
    setDialogPayload({ [BusinessAttributeDialogSection.MANUAL]: value });
  };

  const handleGridRowClick = useCallback(
    (row: BigidGridRow): void => {
      const newSelectedRow = !isEqual(selectedItem, row) ? row : undefined;

      setErrorMessage(undefined);
      setDialogPayload({
        suggested: newSelectedRow ? (newSelectedRow as BusinessAttributeGridColumn).friendlyName : undefined,
      });
      setSectionSelected(BusinessAttributeDialogSection.SUGGESTED);

      setSelectedItem(newSelectedRow as BigidContentItem);
      setIsColumnWidgetEnabled(!!newSelectedRow);
    },
    [selectedItem],
  );

  const handleOnClose = (): void => {
    onClose();
    resetDialogState();
  };

  const handleColumnWidgetClose = (): void => {
    setIsColumnWidgetEnabled(false);
    setSelectedItem(null);
  };

  const useFetchState = useFetch({
    fetchDataFunction: async queryComponents => {
      try {
        const query = objectToQueryString({
          ...(queryComponents as QueryParams),
          fullyQualifiedName,
          columnName,
          requireTotalCount: false,
        });
        const {
          data: { results },
        } = await getColumnBusinessAttributeSuggestions(query);

        return {
          totalCount: results.length,
          data: results.map(suggestion => ({ id: suggestion.friendlyName, ...suggestion })),
        };
      } catch ({ message }) {
        notificationService.error('An error has occurred');
        console.error(`An error has occurred: ${message}`);
        return {
          totalCount: 0,
          data: [],
        };
      }
    },
  });

  const getSelectedRowId = useCallback((): string[] => {
    const { suggested } = dialogPayload || {};
    const isAttributeAmongRows = useFetchState.rows.find(row => {
      return (row as BusinessAttributeGridColumn).friendlyName === suggested;
    });
    return isAttributeAmongRows ? [suggested] : [];
  }, [useFetchState, dialogPayload]);

  const columns: BigidGridColumn<BusinessAttributeGridColumn>[] = useMemo(
    () => [
      {
        name: 'friendlyName',
        title: 'Friendly Name',
        getCellValue: ({ friendlyName }) => friendlyName,
        type: BigidGridColumnTypes.TEXT,
      },
      {
        name: 'columnName',
        title: 'Column Name',
        getCellValue: ({ columnName }) => columnName,
        type: BigidGridColumnTypes.TEXT,
      },
      {
        name: 'tab',
        title: 'Table Name',
        getCellValue: ({ tableName }) => tableName,
        type: BigidGridColumnTypes.TEXT,
      },
      {
        name: 'source',
        title: 'Data Source',
        getCellValue: ({ source }) => source,
        type: BigidGridColumnTypes.TEXT,
      },
      {
        name: 'sourceType',
        title: 'Type',
        getCellValue: ({ sourceType }) => sourceType,
        type: BigidGridColumnTypes.TEXT,
      },
    ],
    [],
  );

  const gridConfig: BigidGridProps<BusinessAttributeGridColumn> = {
    apiRef: useFetchState.apiRef,
    rows: useFetchState.rows as BusinessAttributeGridColumn[],
    skip: useFetchState.skip,
    onPagingChanged: useFetchState.onPagingChanged,
    onSortingChanged: useFetchState.onSortingChanged,
    totalRowsCount: useFetchState.totalRowsCount,
    showSortingControls: false,
    selectedRowIds: getSelectedRowId(),
    onRowClick: handleGridRowClick,
    columns,
  };

  useEffect(() => {
    setIsLoading(true);

    if (useFetchState.isInitiallyFetched) {
      const businessAttributeChip = businessAttribute?.[0];

      if (businessAttributeChip) {
        const isSuggestion = businessAttributeChip?.outline === 'dashed';
        const isAutoPopulated = Boolean(businessAttributeChip?.icon);
        const businessAttributeName = businessAttributeChip?.label;

        if (businessAttributeName && !isSuggestion && useFetchState.isInitiallyFetched) {
          const suggestedBusinessAttribute = useFetchState.rows.find(
            row => (row as BusinessAttributeGridColumn).friendlyName === businessAttributeName,
          );

          if (suggestedBusinessAttribute) {
            setDialogPayload({ [BusinessAttributeDialogSection.SUGGESTED]: businessAttributeName });
            setSectionSelected(BusinessAttributeDialogSection.SUGGESTED);
            setSelectedItem(suggestedBusinessAttribute as unknown as BigidContentItem);
            setIsColumnWidgetEnabled(true);
          } else if (!isAutoPopulated) {
            setDialogPayload({ [BusinessAttributeDialogSection.MANUAL]: businessAttributeName });
            setSectionSelected(BusinessAttributeDialogSection.MANUAL);
          }

          setIsLoading(false);
        } else {
          setSectionSelected(BusinessAttributeDialogSection.MANUAL);
        }
      } else {
        setSectionSelected(BusinessAttributeDialogSection.MANUAL);
      }

      setIsLoading(false);
    }
  }, [useFetchState.rows, businessAttribute, useFetchState.isInitiallyFetched]);

  useEffect(() => {
    if (!dialogPayload?.suggested) {
      setSelectedItem(null);
      setIsColumnWidgetEnabled(false);
    }
  }, [dialogPayload]);

  return (
    <BigidDialog
      title={`Apply Business Term for '${columnName}'`}
      isOpen={isOpen}
      onClose={handleOnClose}
      borderTop
      buttons={[
        {
          component: SecondaryButton,
          onClick: handleOnClose,
          text: 'Cancel',
        },
        {
          component: SecondaryButton,
          onClick: handleOnDialogApplyToCluster,
          disabled: !Boolean(clusterId),
          text: 'Apply to Cluster',
        },
        {
          component: PrimaryButton,
          onClick: handleOnDialogApplyToColumn,
          text: 'Apply to Column',
        },
      ]}
      maxWidth="md"
      isLoading={isLoading}
    >
      <div className={classes.root}>
        <div className={classes.header}>
          <BigidHeading5>Select, manually specify or clear Business Term</BigidHeading5>
        </div>
        <div className={classes.body}>
          <div className={classNames(classes.section, classes.grid)}>
            <div className={classes.sectionToggler}>
              <BigidRadio
                dataAid={`BusinessAttributeDialog-section-selector-${BusinessAttributeDialogSection.SUGGESTED}`}
                value={BusinessAttributeDialogSection.SUGGESTED}
                checked={sectionSelected === BusinessAttributeDialogSection.SUGGESTED}
                onChange={handleSectionSelectedChange}
                label="Select suggested Business Term"
                color="primary"
                size="small"
                disableRipple
              />
            </div>
            <div className={classes.sectionContent}>
              <div
                className={classNames(
                  classes.sectionContentPrimary,
                  isColumnWidgetEnabled && classes.sectionContentPrimaryShrinked,
                )}
              >
                <BigidPaper>
                  <BigidGrid {...gridConfig} />
                </BigidPaper>
              </div>
              {isColumnWidgetEnabled && (
                <div className={classes.sectionContentOptional}>
                  <ColumnWidgetsWrapper
                    dataAid="BusinessAttributeDialog-column-widget"
                    preferenceId="DataCatalogColumnPreviewPreferences"
                    fullyQualifiedName={selectedItem.fullyQualifiedName}
                    columnName={selectedItem.columnName}
                    isProfiled={selectedItem.isProfiled}
                    onClose={handleColumnWidgetClose}
                    dsName={selectedItem.source}
                    dsType={selectedItem.scannerType}
                    scannerType={selectedItem.scanner_type_group}
                  />
                </div>
              )}
            </div>
            {errorMessage && sectionSelected === BusinessAttributeDialogSection.SUGGESTED && (
              <div className={classes.error}>{errorMessage}</div>
            )}
          </div>
          <div
            className={classNames(
              classes.section,
              classes.manualValue,
              isColumnWidgetEnabled && classes.sectionContentPrimaryShrinked,
            )}
          >
            <div className={classes.sectionToggler}>
              <BigidRadio
                dataAid={`BusinessAttributeDialog-section-selector-${BusinessAttributeDialogSection.MANUAL}`}
                value={BusinessAttributeDialogSection.MANUAL}
                checked={sectionSelected === BusinessAttributeDialogSection.MANUAL}
                onChange={handleSectionSelectedChange}
                label="Manually specify Business Term"
                color="primary"
                size="small"
                disableRipple
              />
            </div>
            <div className={classes.sectionContent}>
              <BigidTextField
                id={`BusinessAttributeDialog-manual-attribute-field`}
                onChange={handleManualAttributeChange}
                value={dialogPayload?.manual || ''}
                errorMessage={
                  errorMessage && sectionSelected === BusinessAttributeDialogSection.MANUAL ? errorMessage : undefined
                }
                disabled={sectionSelected !== BusinessAttributeDialogSection.MANUAL}
              />
            </div>
          </div>
          <div className={classNames(classes.section, isColumnWidgetEnabled && classes.sectionContentPrimaryShrinked)}>
            <div className={classes.sectionToggler}>
              <BigidRadio
                dataAid={`BusinessAttributeDialog-section-selector-${BusinessAttributeDialogSection.CLEAR}`}
                value={BusinessAttributeDialogSection.CLEAR}
                checked={sectionSelected === BusinessAttributeDialogSection.CLEAR}
                onChange={handleSectionSelectedChange}
                label="Clear Business Term"
                color="primary"
                size="small"
                disableRipple
              />
            </div>
          </div>
        </div>
      </div>
    </BigidDialog>
  );
};
