import React, { FC } from 'react';
import { BigidLayoutEmptyState } from '@bigid-ui/layout';
import { BigidAppsIllustration, BigidAddIcon } from '@bigid-ui/icons';
import { useLocalTranslation } from './translations';

interface AppllicationEmptyStateProps {
  handleApplicationAdd: () => void;
}

export const AppllicationEmptyState: FC<AppllicationEmptyStateProps> = ({ handleApplicationAdd }) => {
  const { t } = useLocalTranslation();

  return (
    <BigidLayoutEmptyState
      actions={[
        {
          execute: async () => {
            handleApplicationAdd();
            return { shouldGridReload: false };
          },
          icon: () => <BigidAddIcon color="currentColor" />,
          label: t('buttons.add'),
          show: () => true,
        },
      ]}
      illustration={BigidAppsIllustration}
      description={t('message.manageApplications')}
    />
  );
};
