import React, { FC, memo, ReactText } from 'react';
import { makeStyles } from '@mui/styles';
import { BigidBody1 } from '@bigid-ui/components';
import { generateDataAid } from '@bigid-ui/utils';
import { CuratedAttribute, CuratedFieldStatus } from '../curationService';
import { getFindingIndicatorColorByStatus } from '../curationUtils';
import { useLocalTranslation } from '../translations';

export interface CuratedAttributeAccuracyOperandsProps
  extends Pick<CuratedAttribute, 'approvedCount' | 'rejectedCount'> {
  id: ReactText;
  dataAid: string;
}

const useStyles = makeStyles({
  root: {
    display: 'flex',
    alignItems: 'center',
  },
  delimeter: {
    margin: '0px 4px',
  },
});

export const CuratedAttributeAccuracyOperands: FC<CuratedAttributeAccuracyOperandsProps> = memo(
  ({ dataAid, approvedCount, rejectedCount }) => {
    const classes = useStyles();
    const { t } = useLocalTranslation('CuratedAttributes');

    return (
      <div className={classes.root}>
        <BigidBody1
          sx={{ color: getFindingIndicatorColorByStatus(CuratedFieldStatus.APPROVED) }}
          data-aid={generateDataAid(dataAid, ['approved-count'])}
        >
          {approvedCount} {t('approved')}
        </BigidBody1>
        <BigidBody1 className={classes.delimeter}>|</BigidBody1>
        <BigidBody1
          sx={{ color: getFindingIndicatorColorByStatus(CuratedFieldStatus.REJECTED) }}
          data-aid={generateDataAid(dataAid, ['rejected-count'])}
        >
          {rejectedCount} {t('rejected')}
        </BigidBody1>
      </div>
    );
  },
);
