import {
  MetadataSearchResultsGridRecord,
  MetadataSearchResultsGridColumn,
} from '../MetadataSearchResultsGrid/MetadataSearchResultsGridTypes';
import {
  getGridCellContentSingular,
  getGridCellContentPlural,
} from '../MetadataSearchResultsGrid/MetadataSearchResultsGridUtils';
import { getMetadataSearchEntityFieldByName } from '../MetadataSearchService';
import {
  MetadataSearchEntityType,
  MetadataSearchResultEntity,
  MetadataSearchResultEntityFieldName,
} from '../MetadataSearchTypes';
import { getResultEntityGridDisplayFieldsPerType } from '../MetadataSearchUtils';

export const getSummaryGridColumns = (
  fieldsToDisplay: MetadataSearchResultEntityFieldName[],
  resultEntity: MetadataSearchResultEntity,
): MetadataSearchResultsGridColumn[] => {
  const columns: MetadataSearchResultsGridColumn[] = [];

  if (fieldsToDisplay.includes(MetadataSearchResultEntityFieldName.NAME)) {
    const name = getMetadataSearchEntityFieldByName(resultEntity, MetadataSearchResultEntityFieldName.NAME);

    const { cellValue: nameCellValue, tooltipValue: nameCellTooltipValue } = getGridCellContentSingular(name);

    columns.push({
      id: 'name',
      name: 'name',
      value: nameCellValue,
      tooltipTitle: name.originalField || 'Name',
      tooltipValue: nameCellTooltipValue,
      isBold: true,
      width: '60%',
    });
  }

  if (fieldsToDisplay.includes(MetadataSearchResultEntityFieldName.CONTAINER)) {
    const container = getMetadataSearchEntityFieldByName(resultEntity, MetadataSearchResultEntityFieldName.CONTAINER);
    const columnWidth = fieldsToDisplay.length === 2 ? '40%' : undefined;
    const containerColumnBase: MetadataSearchResultsGridColumn = {
      id: 'container',
      name: 'container',
      value: '',
      width: columnWidth,
    };

    if (container?.value) {
      const { cellValue, tooltipValue } = getGridCellContentSingular(container);
      columns.push({
        ...containerColumnBase,
        value: cellValue,
        tooltipTitle: container.originalField || 'Container',
        tooltipValue,
      });
    } else {
      columns.push(containerColumnBase);
    }
  }

  if (fieldsToDisplay.includes(MetadataSearchResultEntityFieldName.OWNER)) {
    const owner = getMetadataSearchEntityFieldByName(resultEntity, MetadataSearchResultEntityFieldName.OWNER);
    const columnWidth = fieldsToDisplay.length === 2 ? '40%' : undefined;
    const ownerColumnBase: MetadataSearchResultsGridColumn = {
      id: 'owner',
      name: 'owner',
      value: '',
      width: columnWidth,
    };

    if (owner) {
      const { cellValue, cellExtraValueCount, tooltipValue } = getGridCellContentPlural(owner, 'Owners');
      columns.push({
        ...ownerColumnBase,
        value: cellValue || '',
        extraValueCount: cellExtraValueCount,
        tooltipTitle: owner.originalField || 'Owners',
        tooltipValue,
      });
    } else {
      columns.push(ownerColumnBase);
    }
  }

  return columns;
};

export const getSummaryGridData = (
  results: MetadataSearchResultEntity[],
  entityType: MetadataSearchEntityType,
): MetadataSearchResultsGridRecord[] => {
  return results.map(resultEntity => {
    const entityId = getMetadataSearchEntityFieldByName(resultEntity, MetadataSearchResultEntityFieldName.ENTITY_ID);
    const fields = getResultEntityGridDisplayFieldsPerType(entityType);
    const columns: MetadataSearchResultsGridColumn[] = getSummaryGridColumns(fields, resultEntity);

    return {
      id: getGridCellContentSingular(entityId, true).cellValue,
      columns,
      originalRecord: { type: entityType, ...resultEntity },
    };
  });
};
