import React, { FC, useEffect, useMemo, useRef, useState } from 'react';
import {
  BigidForm,
  BigidFormField,
  BigidFormFieldLabelPosition,
  BigidFormFieldTypes,
  BigidFormStateAndHandlers,
  BigidFormValues,
  BigidLoader,
  BigidSelect,
  BigidSelectOption,
  PrimaryButton,
  SecondaryButton,
  BigidDropdownOption,
} from '@bigid-ui/components';
import { getAvailableScannerGroups, getMipSettings, postMipSettings } from './LabelingService';
import { useLocalTranslation } from './translations';
import { SystemDialogContentProps } from '../../services/systemDialogService';
import { notificationService } from '../../services/notificationService';
import makeStyles from '@mui/styles/makeStyles';

interface LabelingSettingsForm {
  clientid: string;
  tenantid: string;
  secret: string;
  scannerGroup: BigidDropdownOption;
}

const initialValues: LabelingSettingsForm = {
  clientid: '',
  tenantid: '',
  secret: '',
  scannerGroup: undefined,
};

const LABEL_WIDTH = '200px';

const useStyles = makeStyles({
  root: {},
  form: { padding: '16px', overflow: 'visible' },
  select: { marginLeft: LABEL_WIDTH },
  footer: {
    display: 'flex',
    justifyContent: 'flex-end',
    '& > *': {
      marginLeft: '8px',
    },
  },
});

const validateRequired = (fieldValue: string) => {
  if (!fieldValue) {
    return 'This field is required';
  }
  return false;
};

export const LabelingSettingsDialog: FC<SystemDialogContentProps<unknown>> = ({ onClose }) => {
  const classes = useStyles();
  const { t } = useLocalTranslation('LabelingSettingsDialog');
  const [formValues, setFormValues] = useState<BigidFormValues>(initialValues);
  const [loading, setLoading] = useState<boolean>(false);
  const [bigidSelectValue, setBigidSelectValue] = useState<BigidSelectOption[]>([{ value: 'MIP', label: 'MIP' }]);

  const formControls = useRef<BigidFormStateAndHandlers>();

  const fields: BigidFormField[] = useMemo(() => {
    const clientIdLabel =
      bigidSelectValue[0].value === 'MIP' ? t(`formLabels.${bigidSelectValue[0].value}.clientid`) : undefined;
    const tenantIdLabel = t(`formLabels.${bigidSelectValue[0].value}.tenantid`);
    const secretLabel = t(`formLabels.${bigidSelectValue[0].value}.secret`);
    const scannerGroupLabel = t(`formLabels.${bigidSelectValue[0].value}.scannerGroup`);

    return [
      ...(bigidSelectValue[0].value === 'MIP'
        ? [
            {
              name: 'clientid',
              label: clientIdLabel,
              isRequired: bigidSelectValue[0].value === 'MIP',
              labelWidth: LABEL_WIDTH,
              labelPosition: BigidFormFieldLabelPosition.left,
              validate: validateRequired,
            },
          ]
        : []),
      {
        name: 'tenantid',
        label: tenantIdLabel,
        isRequired: true,
        labelWidth: LABEL_WIDTH,
        labelPosition: BigidFormFieldLabelPosition.left,
        validate: validateRequired,
      },
      {
        name: 'secret',
        label: secretLabel,
        isRequired: true,
        labelWidth: LABEL_WIDTH,
        type: BigidFormFieldTypes.PASSWORD,
        labelPosition: BigidFormFieldLabelPosition.left,
        validate: validateRequired,
      },
      {
        name: 'scannerGroup',
        label: scannerGroupLabel,
        isRequired: false,
        labelWidth: LABEL_WIDTH,
        type: BigidFormFieldTypes.SELECT,
        labelPosition: BigidFormFieldLabelPosition.left,
        options: formValues.scannerGroupOptions,
        fieldProps: {
          menuPosition: 'fixed',
          placeholder: 'Choose a scanner group',
        },
      },
    ];
  }, [bigidSelectValue, formValues.scannerGroupOptions, t]);

  const createOptionsForScannerGroups = (groupOptions: string[], selectedGroup: string) => {
    const scannerGroupsAsDropdownOptions: BigidSelectOption[] = groupOptions.map(group => ({
      value: group,
      label: group,
    }));
    const selectedScannerGroupAsDropdownOption = selectedGroup
      ? [{ value: selectedGroup, label: selectedGroup }]
      : undefined;
    return { scannerGroupsAsDropdownOptions, selectedScannerGroupAsDropdownOption };
  };

  const saveNewCredentials = async () => {
    try {
      formControls.current?.validateAndSubmit(async values => {
        const isNewSecret = Object.keys(formControls.current.touchedFields).includes('secret');
        const options = {
          clientid: values.clientid,
          type: bigidSelectValue[0].value,
          secret: values.secret,
          tenantid: values.tenantid,
          scannerGroup: values.scannerGroup ? values.scannerGroup[0].value : undefined,
          isNewSecret,
        };
        if (bigidSelectValue[0].value !== 'MIP') {
          delete options.clientid;
        }
        await postMipSettings(options);
        notificationService.success('Credentials saved');
        onClose();
      });
    } catch (err) {
      notificationService.error('Credentials could not be saved');
    }
  };

  const options: BigidSelectOption[] = [
    { label: 'MIP', value: 'MIP' },
    { label: 'GDRIVE', value: 'GDRIVE' },
  ];

  const clearFormValues = () => {
    setFormValues({
      clientid: '',
      tenantid: '',
      secret: '',
      scannerGroupOptions: formValues.scannerGroupOptions,
    });
    formControls?.current.clear();
  };

  const handleOnSelectType = (val: BigidSelectOption[]) => {
    setBigidSelectValue(val);
    clearFormValues();
  };

  useEffect(() => {
    const initFormValues = async () => {
      try {
        setLoading(true);
        const mipSettings = await getMipSettings();
        const scannerGroups = await getAvailableScannerGroups();
        const { scannerGroupsAsDropdownOptions, selectedScannerGroupAsDropdownOption } = createOptionsForScannerGroups(
          scannerGroups,
          mipSettings.scannerGroup,
        );

        setFormValues({
          ...mipSettings,
          scannerGroupOptions: scannerGroupsAsDropdownOptions,
          scannerGroup: selectedScannerGroupAsDropdownOption,
        });

        if (mipSettings.type) {
          setBigidSelectValue([{ label: mipSettings.type, value: mipSettings.type }]);
        } else {
          setBigidSelectValue([{ label: 'MIP', value: 'MIP' }]);
        }
      } finally {
        setLoading(false);
      }
    };

    initFormValues();
  }, []);

  if (loading) {
    return <BigidLoader />;
  }

  return (
    <>
      <div className={classes.form}>
        <div className={classes.select}>
          <BigidSelect options={options} value={bigidSelectValue} onChange={handleOnSelectType} />
        </div>

        <BigidForm
          key={JSON.stringify(bigidSelectValue)}
          controlButtons={false}
          initialValues={formValues}
          fields={fields}
          stateAndHandlersRef={formControls}
        />
      </div>

      <div className={classes.footer}>
        <SecondaryButton onClick={onClose} text="Close" size={'medium'} dataAid={'labeling-settings-dialog-close'} />
        <PrimaryButton
          onClick={saveNewCredentials}
          text="Save"
          size={'medium'}
          dataAid={'labeling-settings-dialog-save'}
        />
      </div>
    </>
  );
};
