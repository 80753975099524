import styled from '@emotion/styled';

export const DataSourceWizardLayout = styled.div`
  padding: 12px;
  height: 100%;

  & > div:nth-of-type(2) {
    height: calc(100% - 16px);
    & > div {
      border-top: ${({ theme }) => `1px solid ${theme.vars.palette.bigid.gray200}`};
    }
  }
`;

export const DataSourceWizardContent = styled.div``;

export const DataSourceWizardTabs = styled.div`
  min-height: 48px;
  padding-left: 12px;
`;
