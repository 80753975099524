import { IComponentOptions } from 'angular';
import { react2angular } from 'react2angular';
import { ComponentType } from 'react';
import { withTheme } from '../../react/theme/BigidTheme';

export function convertToAngular<Props>(
  Class: ComponentType<Props>,
  bindingNames: (keyof Props)[] | null = null,
  injectNames: string[] = [],
): IComponentOptions {
  return react2angular<Props>(withTheme(Class), bindingNames, injectNames);
}
