import React, { FC, memo } from 'react';
import { makeStyles } from '@mui/styles';
import { BigidBody1, SecondaryButton } from '@bigid-ui/components';
import { generateDataAid } from '@bigid-ui/utils';
import { useLocalTranslation } from '../../translations';
import ClassifierTuningFieldPreview from '../../../../assets/icons/ClassifierTuningFieldPreview.svg';
import { BigidNoDataIllustration } from '@bigid-ui/icons';

export enum CuratedFieldPreviewErrorType {
  ERROR = 'error',
  EXPIRED_ATTRIBUTE = 'expired-attribute',
}

export interface CuratedFieldValuePreviewErrorProps {
  dataAid: string;
  onPreviewClick: () => void;
  errorType: CuratedFieldPreviewErrorType;
  errorText: string;
}

const useStyles = makeStyles({
  root: {
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    gap: '16px',
    flex: '1 0 0',
    alignSelf: 'stretch',
  },
  row: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    '&:not(:last-child)': {
      marginBottom: '8px',
    },
  },
});

export const CuratedFieldValuePreviewError: FC<CuratedFieldValuePreviewErrorProps> = memo(
  ({ dataAid, errorText, errorType, onPreviewClick }) => {
    const classes = useStyles();
    const { t } = useLocalTranslation('CuratedFields');

    const handleOnPreviewClick = () => {
      onPreviewClick();
    };

    return (
      <div className={classes.root} data-aid={dataAid}>
        {errorType !== CuratedFieldPreviewErrorType.EXPIRED_ATTRIBUTE ? (
          <>
            <ClassifierTuningFieldPreview />
            <SecondaryButton
              size="medium"
              onClick={handleOnPreviewClick}
              dataAid={generateDataAid(dataAid, ['load-previews'])}
              text={t('loadPreview')}
            />
          </>
        ) : (
          <>
            <div className={classes.row}>
              <BigidNoDataIllustration />
            </div>
            <div className={classes.row}>
              <BigidBody1 data-aid={generateDataAid(dataAid, ['title'])}>{errorText}</BigidBody1>
            </div>
          </>
        )}
      </div>
    );
  },
);
