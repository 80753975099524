import { httpService } from '../../services/httpService';

interface CompanyNameResponse {
  data: {
    companyName: string;
  };
}

interface CompanyLogoResponse {
  data: {
    logo: string;
  };
}

interface LatestReportDateResponse {
  data: {
    latestReportDate: string;
  };
}

interface ReportConfig {
  companyName?: string;
  primarySc: string;
  secondarySc: string;
  monthsToCompare: number;
  isSmallid: boolean;
}

export const downloadExecutiveReport = () => {
  return httpService.downloadFile(`aci/executive-summary/file-download/report-data`);
};

export const getReportConfig = () => {
  return httpService
    .fetch<{ data: ReportConfig }>('aci/executive-summary/getSummaryConfig')
    .then(({ data }) => data.data);
};

export const getLatestReportDate = () => {
  return httpService
    .fetch<LatestReportDateResponse>('aci/executive-summary/get-latest-report-date')
    .then(({ data }) => data.data);
};

export const getCompanyLogo = () => {
  return httpService.fetch<CompanyLogoResponse>('aci/executive-summary/getLogo').then(({ data }) => data.data);
};

export const updateReportConfig = (payload: ReportConfig) => {
  return httpService.post('aci/executive-summary/update-config', { ...payload });
};

export const saveCompanyName = async (name: string) => {
  return httpService.post(`aci/executive-summary/saveCompanyName`, { companyName: name });
};
export const saveCompanyLogo = async (data: string) => {
  return httpService.post(`aci/executive-summary/saveLogo`, { logo: data });
};
