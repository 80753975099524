import styled from '@emotion/styled';

export const Content = styled.div`
  display: flex;
  justify-content: center;
  padding: 16px 26px;
  max-width: 452px;
  height: fit-content;

  form {
    flex: 1;

    > fieldset {
      margin: 0 0 24px;
    }

    label {
      margin-bottom: 4px;
    }
    
`;

export const FormWrapper = styled.div`
  flex-grow: 1;
  height: fit-content;
`;
