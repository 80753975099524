import React, { FC } from 'react';
import { FormControlLabel, Radio } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { BigidColorsV2 } from '@bigid-ui/components';

import classNames from 'classnames';

const useStyles = makeStyles(theme => ({
  icon: {
    borderRadius: '50%',
    width: 16,
    height: 16,
    boxShadow: 'inset 0 0 0 1px #777777',
    'input:hover ~ &': {
      boxShadow: `inset 0 0 0 1px ${BigidColorsV2.gray[600]}`,
    },
    'input:disabled ~ &': {
      boxShadow: `inset 0 0 0 1px ${BigidColorsV2.gray[500]}`,
    },
  },
  checkedIcon: {
    boxShadow: `inset 0 0 0px 3px ${BigidColorsV2.white}`,
    backgroundColor: theme.vars.palette.bigid.primary900,
    border: '1px solid #777777',
    boxSizing: 'border-box',
    'input:hover ~ &': {
      boxShadow: `inset 0 0 0px 3px ${theme.vars.palette.bigid.white}`,
      border: `1px solid ${theme.vars.palette.bigid.gray600}`,
      boxSizing: 'border-box',
      backgroundColor: theme.vars.palette.bigid.primary900,
    },
    'input:disabled ~ &': {
      boxShadow: `inset 0 0 0px 3px ${theme.vars.palette.bigid.white}`,
      border: `1px solid ${theme.vars.palette.bigid.gray500}`,
      boxSizing: 'border-box',
      backgroundColor: theme.vars.palette.bigid.gray500,
    },
  },
}));

const useStylesFormControlLabel = makeStyles(theme => ({
  root: {
    display: 'flex',
    marginLeft: 0,
    marginRight: 0,
    marginBottom: 0,
    width: '100%',
    '&.Mui-disabled': {
      width: 'auto',
    },
    '&:hover:not(.Mui-disabled)': {
      '& .MuiRadio-root.Mui-checked': {
        color: theme.vars.palette.bigid.primary900,
      },
    },
    '& .MuiTypography-root': {
      textOverflow: 'ellipsis',
      maxWidth: 100,
      overflow: 'hidden',
    },
    '& .MuiRadio-root.Mui-checked + .MuiTypography-root': {
      fontWeight: 700,
    },
  },
  label: {
    fontSize: '0.875rem',
    color: theme.vars.palette.bigid.gray700,
  },
}));

type RadioOptionType = { value: string; label: string; disabled?: boolean };

interface FmsdRadioProps {
  option: RadioOptionType;
  activeOption: string;
  dataAid?: string;
}

export const FmsdRadio: FC<FmsdRadioProps> = ({ option, activeOption, dataAid = '' }) => {
  const { checkedIcon, icon } = useStyles({});
  const classesFormControlLabel = useStylesFormControlLabel({});
  const isSelected = option?.value === activeOption;
  const fullDataAid = `${dataAid}-radio-button-${option?.value}${isSelected ? '-selected' : ''}${
    option?.disabled ? '-disabled' : ''
  }`;

  return (
    <FormControlLabel
      {...option}
      classes={classesFormControlLabel}
      control={
        <Radio
          size="small"
          disableRipple
          disabled={option?.disabled}
          icon={<span className={icon} />}
          checkedIcon={<span className={classNames(icon, checkedIcon)} />}
          data-aid={fullDataAid}
        />
      }
    />
  );
};
