import React, { FC } from 'react';
import { styled } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { BigidHeading3, BigidNotes1, BigidPaper } from '@bigid-ui/components';
import classNames from 'classnames';

const useStyles = makeStyles(theme => ({
  cardPaper: {
    height: '108px',
    marginLeft: '12px',
    alignItems: 'center',
    border: `1px solid ${theme.vars.palette.bigid.gray200}`,
    boxShadow: '0px 1px 12px 0px rgba(0, 0, 0, 0.05)',
    width: '168px',
    display: 'flex',
    borderRadius: '8px',
    padding: '12px 20px',
    cursor: 'pointer',
    '&:hover': {
      border: `1px solid ${theme.vars.palette.bigid.primary900}`,
    },
  },
  activeCard: {
    border: `1px solid ${theme.vars.palette.bigid.primary900}`,
  },
  wrapper: {
    position: 'relative'
  }
}));

const Container = styled('div')`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  position: relative;
`;

const IconContainer = styled('div')`
  position: absolute;
  transform: scaleX(-1);
  top: 12px;
  right: 12px;
  height: 16px;
`;

export interface TopSectionCardProps {
  numberingColor: string;
  label: string;
  numOfItems: number;
  icon?: JSX.Element;
  isActive?: boolean;
  onCardClick?: () => void;
}

export const TopSectionCard: FC<TopSectionCardProps> = ({
  numberingColor,
  label,
  numOfItems,
  icon,
  isActive,
  onCardClick,
}) => {
  const { cardPaper, activeCard,wrapper } = useStyles();
  return (
    <div className={wrapper} onClick={onCardClick}>
      {icon && <IconContainer>{icon}</IconContainer>}
      <BigidPaper classes={{ root: classNames(cardPaper, isActive && activeCard) }}>
        <Container>
          <BigidHeading3 fontWeight={700} color={numberingColor}>
            {numOfItems}
          </BigidHeading3>
          <BigidNotes1>{label}</BigidNotes1>
        </Container>
      </BigidPaper>
    </div>
  );
};
