import React, { FC, useCallback } from 'react';
import { isEmpty } from 'lodash';
import makeStyles from '@mui/styles/makeStyles';
import { CustomAppAction, Preset } from '../views/CustomAppActions/CustomAppActions';
import { PresetCard, getTooltipContentForNoParamsInTheApp } from './PresetCard/PresetCard';
import {
  BigidBody2,
  BigidColorsV2,
  BigidHeading3,
  BigidHeading6,
  BigidTooltip,
  SecondaryButton,
} from '@bigid-ui/components';
import { BigidAddIcon } from '@bigid-ui/icons';
import { CustomAppParam, InputTypes } from '../views/EditCustomApp/EditCustomApp';
import { ExecutionData } from './PresetCard/PresetLatestExecutionStatus';
import { GlobalPreset } from '../utils/CustomAppTypes';
import { getApplicationPreference } from '../../../services/appPreferencesService';

export interface ActionCardProps {
  appGlobalParams: CustomAppParam[];
  action: CustomAppAction;
  isAppOnline: boolean;
  hasManagerPermission: boolean;
  latestExecutionsMap: Record<string, ExecutionData>;
  onAddNewPreset: (action: CustomAppAction) => void;
  onDeletePreset: (preset: Preset) => void;
  onEditPreset: (preset: Preset) => void;
  onSchedule: (preset: Preset) => void;
  onRunPreset: (preset: Preset, actionName: string) => void;
  onClonePreset: (preset: Preset) => void;
  globalPresets: GlobalPreset[];
}

export const getLatestExecutionsObjectKey = (actionId: string, presetId: string) => `${actionId}_${presetId}`;

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: '16px',
    backgroundColor: BigidColorsV2.white,
    padding: '16px',
    border: theme.vars.tokens.bigid.borderDefault,
    boxShadow: theme.vars.tokens.bigid.shadow10,
  },
  descriptionTitle: { color: BigidColorsV2.gray[600] },
  errorMessage: { color: BigidColorsV2.red[900], marginLeft: 0, marginRight: 'auto' },
  descriptionContainer: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: '8px',
    width: '75%',
  },
  addButtonContainer: {
    display: 'flex',
    flexDirection: 'row-reverse',
  },
  presetCardsContainer: {
    marginTop: '16px',
    display: 'flex',
    flexDirection: 'column',
    rowGap: '8px',
  },
}));

export const isCurrentValueExistsWithinItems = (param: CustomAppParam, paramValue: string) => {
  const { inputItems, inputType, fetchItems } = param;
  if ([InputTypes.MULTIPLE_SELECTION, InputTypes.SINGLE_ELECTION].includes(inputType) && !fetchItems) {
    return paramValue?.split(',').every(val => inputItems.includes(val));
  }
  return true;
};

export const isMissingMandatoryParams = (
  paramsKeyValues: Record<string, string>,
  mandatoryParams: CustomAppParam[],
) => {
  return !mandatoryParams.every(param => {
    const value = paramsKeyValues[param.name] || param.value;
    const isValidValue = isCurrentValueExistsWithinItems(param, value);
    return value && isValidValue;
  });
};

export const ActionCard: FC<ActionCardProps> = ({
  appGlobalParams,
  action,
  onAddNewPreset,
  onDeletePreset,
  onEditPreset,
  onRunPreset,
  onSchedule,
  isAppOnline,
  hasManagerPermission,
  onClonePreset,
  latestExecutionsMap,
  globalPresets,
}) => {
  const { description, presets, name, friendlyName, params } = action;
  const actionMandatoryParams = params.filter(({ isMandatory }) => isMandatory);

  const noParamsForThisAction = isEmpty(params) && isEmpty(appGlobalParams);

  const classes = useStyles({});

  const getErrorMessage = useCallback(
    ({ paramsKeyValue, overrideGlobalParams, globalParamsKeyValue, globalPresetId }: Preset): string => {
      const globalParams = getGlobalParamsForPreset(globalPresetId, appGlobalParams);
      const globalMandatoryParams = globalParams.filter(({ isMandatory }) => isMandatory);
      const isMissingActionParams = isMissingMandatoryParams(paramsKeyValue, actionMandatoryParams);
      if (isMissingActionParams) return 'Missing Mandatory Action Fields';

      let isMissingGlobalParams = false;
      if (overrideGlobalParams) {
        isMissingGlobalParams = isMissingMandatoryParams(globalParamsKeyValue, globalMandatoryParams);
      }
      return isMissingGlobalParams ? 'Missing Mandatory Global Fields' : '';
    },
    [actionMandatoryParams],
  );

  const isGlobalPresetsEnabled = () => {
    return getApplicationPreference('TPA_GLOBAL_PRESETS_ENABLED');
  };

  const getGlobalParamsForPreset = (globalPresetId: string, globalParams: CustomAppParam[]): CustomAppParam[] => {
    if (isGlobalPresetsEnabled()) {
      const specificGlobalPresetSelected =
        globalPresetId && globalPresets.find((globalPreset: GlobalPreset) => globalPreset._id === globalPresetId);

      const relevantGlobalPreset =
        specificGlobalPresetSelected ||
        globalPresets.find((globalPreset: GlobalPreset) => globalPreset.is_default === true);

      return globalParams.map(globalParam => {
        return { ...globalParam, value: relevantGlobalPreset.params_key_value[globalParam.name] };
      });
    }
    return globalParams;
  };

  return (
    <div className={classes.root}>
      <BigidHeading3 data-aid="action_name">{friendlyName || name}</BigidHeading3>

      <div className={classes.descriptionContainer}>
        <BigidHeading6 className={classes.descriptionTitle}>Description</BigidHeading6>
        <BigidBody2>{description}</BigidBody2>
      </div>

      <div className={classes.presetCardsContainer}>
        {presets.map(preset => {
          const { _id, name, description, isScheduled, cronExpression, is_default: isDefaultPreset } = preset;
          const schedule = isScheduled ? cronExpression : '';
          const latestExecutionKey = getLatestExecutionsObjectKey(
            action.id,
            preset.isDefaultPreset ? 'default' : preset._id,
          );
          const latestExecution = latestExecutionsMap[latestExecutionKey];

          return (
            <PresetCard
              key={_id}
              title={name}
              description={description}
              schedule={schedule}
              errorMessage={getErrorMessage(preset)}
              onSchedule={() => onSchedule(preset)}
              onEdit={() => onEditPreset(preset)}
              onDelete={() => onDeletePreset(preset)}
              onRun={() => onRunPreset(preset, action.name)}
              enableDelete={!isDefaultPreset}
              isAppOnline={isAppOnline}
              hasManagerPermission={hasManagerPermission}
              onClone={() => onClonePreset(preset)}
              action={action}
              preset={preset}
              globalParams={appGlobalParams}
              noParamsForThisAction={noParamsForThisAction}
              latestExecution={latestExecution}
              globalPresets={globalPresets}
            />
          );
        })}

        {hasManagerPermission && (
          <div className={classes.addButtonContainer}>
            <BigidTooltip
              title={getTooltipContentForNoParamsInTheApp('Add Preset')}
              isDisabled={!noParamsForThisAction}
            >
              <span>
                <SecondaryButton
                  onClick={() => onAddNewPreset(action)}
                  size={'medium'}
                  disabled={noParamsForThisAction}
                  startIcon={<BigidAddIcon />}
                  text="Add"
                />
              </span>
            </BigidTooltip>
            {!action.isEnabled && (
              <BigidBody2
                className={classes.errorMessage}
              >{`This action isn't licensed, please contact BigID.`}</BigidBody2>
            )}
          </div>
        )}
      </div>
    </div>
  );
};
