import React, { FC, useContext } from 'react';
import { BigidFormField, StringOrBooleanMap } from '@bigid-ui/components';
import { AutoDiscoveryPresetConfigTabsEnum, AutoDiscoveryWizardContext } from '../../autoDiscoveryWizardContext';
import styled from '@emotion/styled';
import { AutoDiscoveryWizardAuthSection } from '../../autoDiscoveryComponents/AutoDiscoveryWizardForm/AutoDiscoveryWizardAuthSection';
import { AutoDiscoveryWizardSideScanningSection } from '../../autoDiscoveryComponents/AutoDiscoveryWizardForm/AutoDiscoveryWizardSideScanningSection';
import { AUTH_TYPE_FIELD_NAME } from '../../../constants';
import { getApplicationPreference } from '../../../../../services/appPreferencesService';

export interface AutoDiscoveryPresetConnectionsTabProps {
  values?: Record<string, any>;
  authConditionalFields: BigidFormField[];
  sideScanFields: BigidFormField[];
  renderField: (fieldName: string, additionalProps?: Record<string, any>) => any;
  errors: StringOrBooleanMap;
  connectionSection?: BigidFormField[];
}

const AutoDiscoveryPresetConnectionsTabStyled = styled.div`
  display: ${({ visible }: { visible: boolean }) => (visible ? `block` : 'none ')};
`;
export const AutoDiscoveryPresetConnectionsTab: FC<AutoDiscoveryPresetConnectionsTabProps> = ({
  values,
  authConditionalFields,
  renderField,
  sideScanFields,
  errors,
  connectionSection,
}) => {
  const { discoveryConfigData, discoveryConfigDataRef } = useContext(AutoDiscoveryWizardContext);

  return (
    <AutoDiscoveryPresetConnectionsTabStyled
      visible={discoveryConfigData.tab === AutoDiscoveryPresetConfigTabsEnum.connection}
    >
      {renderField('name')}
      {renderField(AUTH_TYPE_FIELD_NAME)}
      <AutoDiscoveryWizardAuthSection
        errors={errors}
        renderField={renderField}
        getFieldProps={discoveryConfigDataRef.current.getFieldProps}
        fields={authConditionalFields}
        values={values}
        sideScanFields={sideScanFields}
        connectionSection={connectionSection}
      />
    </AutoDiscoveryPresetConnectionsTabStyled>
  );
};
