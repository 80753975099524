import { getSupportedDataSources } from '../../utilities/dataSourcesUtils';
import { $state, systemUsersService } from '../../services/angularServices';
import { getApplicationPreference } from '../../services/appPreferencesService';
import { getUsersQuery, getOptionsFromSystemUsers } from '../../utilities/systemUsersUtils';
import { BigidSelectOption, BigidFilter } from '@bigid-ui/components';
import { notificationService } from '../../services/notificationService';
import { httpService } from '../../services/httpService';
import { ReactText } from 'react';

export async function goToDataSource(id: string, type: string) {
  const isTemplatesFlagOn = getApplicationPreference('DS_TYPES_TEMPLATES_SUPPORTED');
  const isTemplateSupported = (await getSupportedDataSources()).some(({ templateName }) => type === templateName);
  $state.go(isTemplatesFlagOn && isTemplateSupported ? 'configDataSource' : `dataSourceConnection`, {
    id,
  });
}

const MAX_USERS_IN_REQUEST = 100;
export const getItOwnersOptions = async (searchString?: string): Promise<BigidSelectOption[]> => {
  try {
    const query = getUsersQuery({ searchString, maxUsers: MAX_USERS_IN_REQUEST });
    const {
      data: { users },
    } = await systemUsersService.getAllSystemUsersByQuery(query);
    return getOptionsFromSystemUsers(users);
  } catch (error) {
    notificationService.error('Error getting the list of Users');
    console.error(error);
  }
};

export const fetchDsTemplate = async (selectedType: string) => {
  return httpService.fetch(`ds-connections-types/template/${selectedType}`);
};

export const updateDataSourceFields = async (updateFields: {
  allSelected?: boolean;
  ids?: ReactText[];
  isMultiFieldUpdate?: boolean;
  updatedFields?: Record<string, unknown>;
  query?: { filter: BigidFilter };
}) => {
  return httpService.put(`ds-connections`, updateFields);
};

export const deleteDataSourceClassificationFindings = async (dsName: string) => {
  return httpService.delete(`ds-connections/${dsName}/classification-findings`);
};
