import React from 'react';
import { styled } from '@mui/material';
import { PrimaryButton, TertiaryButton, BigidMenuItemProps } from '@bigid-ui/components';
import { BigidAddIcon, BigidChevronDownIcon } from '@bigid-ui/icons';
import { generateDataAid } from '@bigid-ui/utils';
import { ButtonWithMenu } from './ButtonWithMenu';

const HeaderButtonsWrapper = styled('div')`
  display: flex;
  gap: 12px;
`;

interface HeaderButtonsProps {
  actionsMenuItems: BigidMenuItemProps[];
  addClassifierMenuItems: BigidMenuItemProps[];
  isImportExportSupportedFFEnabled: boolean;
}

export const HeaderButtons = ({
  actionsMenuItems,
  addClassifierMenuItems,
  isImportExportSupportedFFEnabled,
}: HeaderButtonsProps) => {
  return (
    <HeaderButtonsWrapper>
      {isImportExportSupportedFFEnabled && !!actionsMenuItems.length && (
        <ButtonWithMenu
          render={onClick => (
            <TertiaryButton
              text="Actions"
              size="large"
              onClick={onClick}
              endIcon={<BigidChevronDownIcon />}
              dataAid={generateDataAid('HeaderButtons', ['actions-button'])}
            />
          )}
          menuItems={actionsMenuItems}
        />
      )}

      {!!addClassifierMenuItems.length && (
        <ButtonWithMenu
          render={onClick => (
            <PrimaryButton
              text="Add Classifier"
              dataAid={generateDataAid('HeaderButtons', ['add-classifier-button'])}
              onClick={onClick}
              size="large"
              startIcon={<BigidAddIcon />}
            />
          )}
          menuItems={addClassifierMenuItems}
        />
      )}
    </HeaderButtonsWrapper>
  );
};
