import {
  BigidBody1,
  BigidColors,
  EntityEvents,
  entityEventsEmitter,
  SecondaryButton,
  ToolbarAction,
} from '@bigid-ui/components';
import { BigidGridColumn, BigidGridColumnTypes, BigidGridProps, FetchDataFunction } from '@bigid-ui/grid';
import { BigidLayout, BigidLayoutConfig, BigidMasterDetailsContentProps, LayoutContentType } from '@bigid-ui/layout';
import makeStyles from '@mui/styles/makeStyles';
import AddIcon from '@mui/icons-material/Add';
import DeleteOutlineRoundedIcon from '@mui/icons-material/DeleteOutlineRounded';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import angular from 'angular';
import { DSAR_PERMISSIONS } from '@bigid/permissions';
import React, { FC } from 'react';
import { convertToAngular } from '../../../../common/services/convertToAngular';
import { $state } from '../../../services/angularServices';
import { showConfirmationDialog } from '../../../services/confirmationDialogService';
import { isPermitted } from '../../../services/userPermissionsService';
import { PlaceHolderComponent } from '../SarProfileSettings/PlaceHolderComponent';
import { deleteFlow, getAllFlows, updateDefaultFlow } from './attributesEnrichmentService';
import { openCreateNewInputDialog, openEditDialog } from './Dialogs/NewFlowDialog';
import { isReadOnly } from './MappingDiagram/helpers/permissions';
import { MappingDiagram } from './MappingDiagram/MappingDiagram';
import { MappingFlowItem } from './Types';

export const DEFAULT_FLOW_NAME = 'Default';

const useStyles = makeStyles({
  contentContainer: {
    display: 'flex',
    overflow: 'hidden',
    borderRadius: '4px 4px 0px 0px',
    boxShadow: BigidColors.containerShadow,
    flexFlow: 'column nowrap',
    flex: '1 1 auto',
  },
  noPadding: {
    padding: 0,
  },
});

const columns: BigidGridColumn<MappingFlowItem>[] = [
  {
    title: 'Input Name',
    name: 'name',
    isListColumn: true,
    type: BigidGridColumnTypes.TEXT,
    getCellValue: ({ name }) => name,
  },
];

export const DsarJointCorrelations: FC = () => {
  const classes = useStyles({});

  const isReadOnlyMode = isReadOnly();
  const masterDetailsConfig: BigidMasterDetailsContentProps = {
    isPersistentListMode: true,
    setSelectedItemInFetch: true,
    placeholderComponent: (
      <PlaceHolderComponent>
        <BigidBody1>
          Select a Mapping Flow
          {!isReadOnlyMode && (
            <>
              {' or'}
              <span style={{ padding: 12 }}>
                <SecondaryButton size="small" onClick={() => openCreateNewInputDialog()} text="Add new Mapping" />
              </span>
            </>
          )}
        </BigidBody1>
      </PlaceHolderComponent>
    ),
    tabsAndContent: {
      classes: {
        contentContainer: classes.contentContainer,
      },
      hideTabs: true,
      tabProps: {
        selectedIndex: 0,
        tabs: [
          {
            label: 'Mapping',
            data: {
              component: MappingDiagram,
            },
          },
        ],
      },
    },
  };

  const layoutGridConfig: BigidGridProps<MappingFlowItem> = {
    showSelectionColumn: false,
    columns,
  };

  const fetchGridData: FetchDataFunction<MappingFlowItem> = async (query, { setSelectedItem }) => {
    let flows: MappingFlowItem[] = [];
    try {
      flows = (await getAllFlows()).filter(flow => flow.name !== DEFAULT_FLOW_NAME);

      if (flows.length) {
        const { flowName } = $state.params;
        const selectedItem = (flowName && flows.find(({ name }) => name === flowName)) || flows[0];
        setSelectedItem(selectedItem);
      }
    } catch {
      console.error('Error while fetching enrichments');
    }

    return {
      totalCount: flows.length,
      data: flows,
    };
  };

  const toolbarActions: ToolbarAction[] = [
    {
      label: 'New Input',
      icon: AddIcon,
      isGlobal: true,
      execute: async () => {
        await openCreateNewInputDialog();
        return { shouldGridReload: false, shouldClearSelection: false };
      },
      disable: () => false,
      show: () => isPermitted(DSAR_PERMISSIONS.EDIT_ATTRIBUTES_ENRICHMENT_FLOW.name),
    },
    {
      label: 'Edit',
      icon: EditOutlinedIcon,
      execute: async ({ selectedItem }) => {
        return openEditDialog(selectedItem as MappingFlowItem);
      },
      disable: () => false,
      show: ({ selectedRowIds }) => {
        const isActionPermitted = isPermitted(DSAR_PERMISSIONS.EDIT_ATTRIBUTES_ENRICHMENT_FLOW.name);
        return isActionPermitted && selectedRowIds.length === 1;
      },
    },
    {
      label: 'Delete Input',
      icon: DeleteOutlineRoundedIcon,
      execute: async ({ selectedItem }) => {
        if (!selectedItem.name) {
          return {};
        }
        if (
          await showConfirmationDialog({
            entitiesCount: 1,
            entityNameSingular: selectedItem.name,
            entityNamePlural: selectedItem.name,
          })
        ) {
          await deleteFlow(selectedItem.name);
          await updateDefaultFlow();
          entityEventsEmitter.emit(EntityEvents.DELETE, [selectedItem]);
          $state.go('.', { ...$state.params, flowName: null }, { reload: false, notify: false });
          return { shouldGridReload: false, shouldClearSelection: true };
        }
        return {};
      },
      disable: () => false,
      show: ({ selectedRowIds }) => {
        const isActionPermitted = isPermitted(DSAR_PERMISSIONS.EDIT_ATTRIBUTES_ENRICHMENT_FLOW.name);
        return isActionPermitted && selectedRowIds.length > 0;
      },
    },
  ];

  const layoutConfig: BigidLayoutConfig = {
    content: {
      entityName: 'Flows',
      toolbarActions,
      noPadding: true,
      contentTypes: [LayoutContentType.MASTER_DETAILS],
      viewConfig: {
        fetchGridData,
        gridConfig: layoutGridConfig,
        masterDetailsConfig,
      },
    },
  };

  return (
    <React.Fragment>
      <BigidLayout config={layoutConfig} />
    </React.Fragment>
  );
};

angular.module('app').component('dsarJointCorrelationsMapping', convertToAngular(DsarJointCorrelations));
