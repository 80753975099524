import React, { FC } from 'react';
import { makeStyles } from '@mui/styles';
import { MarketplaceAppSpecs } from '../../../../../applicationManagementService';
import { BigidBody1, BigidColorsV2, BigidHeading5 } from '@bigid-ui/components';

interface SpecsTabProps {
  appSpecs: MarketplaceAppSpecs;
}

const useStyles = makeStyles({
  root: {
    backgroundColor: BigidColorsV2.gray[200],
    padding: '8px',
    display: 'grid',
    gridTemplateColumns: 'auto auto auto',
    minHeight: '152px',
  },
  content: {
    margin: '12px',
    display: 'flex',
    flexDirection: 'column',
    rowGap: '4px',
  },
});

const getContent = (title: string, label: string, className: string) => (
  <div className={className} key={title}>
    <BigidHeading5>{title}</BigidHeading5>
    <BigidBody1>{label}</BigidBody1>
  </div>
);

export const SpecsTab: FC<SpecsTabProps> = ({
  appSpecs: { category, industries, lastRelease, listedDate, version },
}) => {
  const classes = useStyles();
  const row1 = [
    ['Category', category],
    ['Listed Date', listedDate],
    ['Version', version],
    ['Industry', industries],
    ['Last Release Date', lastRelease],
  ];

  return <div className={classes.root}>{row1.map(([title, label]) => getContent(title, label, classes.content))}</div>;
};
