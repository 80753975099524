export interface AzurePolicy {
  properties: AzurePolicyProperties;
}

export interface AzurePolicyProperties {
  roleName: string;
  description: string;
  assignableScopes: string[];
  permissions: AzurePolicyPermissionsItem[];
}
export interface AzurePolicyPermissionsItem {
  actions: string[];
  notActions: string[];
  dataActions: string[];
  notDataActions: string[];
}

export enum AzurePolicyType {
  ALL = 'all',
  STORAGE = 'storage',
  DB = 'db',
  DATABRICKS = 'databricks',
}

export const azureActions: Record<string, string[]> = {
  [AzurePolicyType.STORAGE]: [
    'Microsoft.Storage/storageAccounts/listKeys/action',
    'Microsoft.Storage/storageAccounts/read',
    'Microsoft.Storage/storageAccounts/listAccountSas/action',
    'Microsoft.Storage/storageAccounts/listServiceSas/action',
    'Microsoft.Storage/storageAccounts/accountLocks/read',
    'Microsoft.Storage/storageAccounts/consumerDataSharePolicies/read',
    'Microsoft.Storage/storageAccounts/dataSharePolicies/read',
    'Microsoft.Storage/storageAccounts/fileServices/read',
    'Microsoft.Storage/storageAccounts/fileServices/shares/read',
    'Microsoft.Storage/storageAccounts/localusers/listKeys/action',
    'Microsoft.Storage/storageAccounts/localusers/read',
    'Microsoft.Storage/storageAccounts/managementPolicies/read',
    'Microsoft.Storage/storageAccounts/blobServices/read',
    'Microsoft.Storage/storageAccounts/blobServices/containers/read',
    'Microsoft.Storage/storageAccounts/privateLinkResources/read',
  ],
  [AzurePolicyType.DB]: [
    'Microsoft.DocumentDB/databaseAccounts/read',
    'Microsoft.DocumentDB/databaseAccounts/listKeys/action',
    'Microsoft.DocumentDB/databaseAccounts/readonlykeys/action',
    'Microsoft.DocumentDB/databaseAccounts/listConnectionStrings/action',
    'Microsoft.DocumentDB/databaseAccounts/cassandraKeyspaces/read',
    'Microsoft.DocumentDB/databaseAccounts/cassandraKeyspaces/tables/read',
    'Microsoft.DocumentDB/databaseAccounts/databases/collections/partitions/read',
    'Microsoft.DocumentDB/databaseAccounts/readonlykeys/read',
    'Microsoft.DocumentDB/databaseAccounts/mongodbDatabases/read',
    'Microsoft.DocumentDB/databaseAccounts/mongodbDatabases/collections/read',
    'Microsoft.DocumentDB/databaseAccounts/notebookWorkspaces/read',
    'Microsoft.DocumentDB/databaseAccounts/region/databases/collections/partitions/read',
    'Microsoft.DocumentDB/databaseAccountNames/read',
  ],
  [AzurePolicyType.DATABRICKS]: [
    'Microsoft.Databricks/locations/getNetworkPolicies/action',
    'Microsoft.Databricks/locations/operationstatuses/read',
    'Microsoft.Databricks/workspaces/read',
    'Microsoft.Databricks/workspaces/providers/Microsoft.Insights/diagnosticSettings/read',
    'Microsoft.Databricks/workspaces/providers/Microsoft.Insights/logDefinitions/read',
    'Microsoft.Databricks/workspaces/outboundNetworkDependenciesEndpoints/read',
    'Microsoft.Databricks/workspaces/privateEndpointConnectionProxies/read',
    'Microsoft.Databricks/workspaces/privateLinkResources/read',
    'Microsoft.Databricks/workspaces/privateEndpointConnections/read',
    'Microsoft.Databricks/workspaces/virtualNetworkPeerings/read',
    'Microsoft.Databricks/operations/read',
  ],
};

export const azureDataActions: Record<string, string[]> = {
  [AzurePolicyType.STORAGE]: [
    'Microsoft.Storage/storageAccounts/fileServices/fileshares/files/read',
    'Microsoft.Storage/storageAccounts/blobServices/containers/blobs/read',
    'Microsoft.Storage/storageAccounts/blobServices/containers/blobs/tags/read',
    'Microsoft.Storage/storageAccounts/blobServices/containers/blobs/filter/action',
  ],
};
