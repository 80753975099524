import React, { FC } from 'react';
import { useLocalTranslation } from '../translations';
import { CatalogDiscoveryGuidedTourStep } from '../CatalogDiscoveryGuidedTourStep';

interface HotspotProps {
  dataAid: string;
}

export const Hotspot: FC<HotspotProps> = ({ dataAid }) => {
  const { t } = useLocalTranslation('steps.Hotspot');

  return <CatalogDiscoveryGuidedTourStep dataAid={dataAid} body={t('body')} />;
};
