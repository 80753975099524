import { BigidGridQueryComponents, BigidGridWithToolbarProps } from '@bigid-ui/grid';
import { DataSourceModel } from '../DataSources/DataSourceConnections/DataSourceConnectionTypes';
import { httpService } from '../../services/httpService';
import { notificationService } from '../../services/notificationService';

export interface AuditModel {
  timeStamp: string;
  ipSource: string;
  resourceObject: string;
  action: string;
  userAgent: string;
  api: string;
  additionalData: string;
  user: string;
  id: string;
}

export type AuditItemKeys = keyof AuditModel;

const AUDIT_LOG_COLS_IN_LINE = 8;

const convertAuditArrayToGridRow = ([
  timeStamp,
  user,
  ipSource,
  action,
  api,
  resourceObject,
  userAgent,
  additionalData,
  id,
]: string[]): AuditModel => {
  return {
    timeStamp,
    user,
    ipSource,
    action,
    api,
    resourceObject,
    userAgent,
    additionalData,
    id,
  };
};

export const auditLogToGridData = (log: string) => {
  return log
    .trim()
    .split(/\s*\n\s*/)
    .reverse()
    .map(line => {
      const cols = line.replace(/^\[|]$/g, '').split(/]\s+\[/);
      if (cols.length !== AUDIT_LOG_COLS_IN_LINE) {
        return {
          timeStamp: 'N/A',
          user: 'N/A',
          ipSource: 'N/A',
          action: 'N/A',
          api: 'N/A',
          resourceObject: 'N/A',
          userAgent: 'N/A',
          additionalData: line,
          id: null,
        };
      }
      return convertAuditArrayToGridRow(cols);
    });
};

export const gridAuditClientSideFiltering = (
  data: AuditModel[],
  { filter: columnsToCheck }: BigidGridQueryComponents,
) => {
  if (columnsToCheck?.length) {
    const valueToFind = new RegExp(columnsToCheck[0].value.toString());
    return data.filter(field => {
      const rowValuesString = Object.values(field).toString();
      return rowValuesString.match(valueToFind);
    });
  }
  return data;
};

export async function getAuditFilterConfig(): Promise<
  BigidGridWithToolbarProps<DataSourceModel>['filterToolbarConfig']
> {
  return {
    filters: [],
    searchConfig: {
      searchFilterKeys: ['user', 'ipsource', 'action', 'api', 'resourceObject', 'userAgent', 'additionalData'],
      placeholder: 'text',
      initialValue: '',
      operator: 'textSearch',
      autoSubmit: true,
    },
  };
}

export function getAuditGridData() {
  const url = 'audit-log';
  return httpService
    .fetch(url)
    .then(res => res.data)
    .then(auditLogData => auditLogToGridData(auditLogData));
}

export async function getAuditGridDataObject(queryComponents: string) {
  try {
    const {
      data: { data },
    } = await httpService.fetch(`audit-log?type=objects&${queryComponents}`);

    return data;
  } catch (e) {
    notificationService.error('Error fetching audit data');
    console.error(e);
  }
}
