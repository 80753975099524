import React, { FC, useState } from 'react';
import { BigidPrimaryCheckbox } from '@bigid-ui/components';
import { BigidFormBox } from '../../../../../components/BigidFormBox/BigidFormBox';
import { TpaGeneralValues } from '../EditCustomApp';
import { TPATrackingEvents, trackTPAEvent } from '../../../customAppEventsTrackerUtils';

export interface AdditionalPermissionsSectionProps {
  canGetCredentialsChecked: boolean;
  onChange: (obj: TpaGeneralValues) => void;
}

export const AdditionalPermissionsSection: FC<AdditionalPermissionsSectionProps> = ({
  canGetCredentialsChecked,
  onChange,
}) => {
  const [isChecked, setIsChecked] = useState<boolean>(canGetCredentialsChecked);

  const handleCanGetCredentialsChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const key = 'can_get_ds_credentials';
    const value = e.target.checked;
    setIsChecked(value);
    onChange({ [key]: value });
    trackTPAEvent(TPATrackingEvents.TPA_SETTINGS_ALLOW_DS_CREDENTIALS_CLICK, { allowRetrieveAppCredentials: value });
  };

  return (
    <BigidFormBox title="Additional Permissions">
      <BigidPrimaryCheckbox
        label="Allow Application to retrieve BigID data sources credentials"
        checked={isChecked}
        onChange={handleCanGetCredentialsChange}
      />
    </BigidFormBox>
  );
};
