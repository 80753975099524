import React, { FC } from 'react';
import { useLocalTranslation } from '../../../translations';
import { dateTimeService } from '@bigid-ui/i18n';
import { styled, useTheme } from '@mui/material';
import { BigidBody1 } from '@bigid-ui/components';

const Container = styled('div')`
  display: flex;
  height: 100%;
  align-items: end;
  justify-content: end;
  flex-grow: 1;
  gap: 4px;
  padding-bottom: 16px;
`;

type Props = {
  date: string;
};

export const ModifiedDate: FC<Props> = ({ date }) => {
  const { t } = useLocalTranslation('modifiedDate');
  const {
    vars: { palette },
  } = useTheme();

  const valueToDisplay = date
    ? dateTimeService.formatDate(date, { formatString: 'MM/dd/yyyy' })
    : t('noModifiedDateDescription');

  return (
    <Container>
      <BigidBody1 color={palette.bigid.gray500}>{t('name')}</BigidBody1>
      <BigidBody1>{valueToDisplay}</BigidBody1>
    </Container>
  );
};
