import React, { FC, useEffect, useState, useCallback, useRef } from 'react';
import { BigidBody1, BigidHeading1, BigidProgressBar, BigidProgressBarStatus, BigidLoader } from '@bigid-ui/components';
import { AutoDiscoveryViews } from '../AutoDiscoveryWizard';
import { startAutoDiscoveryApp, getAppLastRunStatus } from '../../../services/autoDiscoveryService';
import { fetchAvailableDsTypes, CloudProvider } from '../autoDiscoveryWizardServices';
import makeStyles from '@mui/styles/makeStyles';
import DiscoveryImage from '../../../assets/icons/AutoDiscovery.svg';
import { notificationService } from '../../../services/notificationService';
import { $state } from '../../../services/angularServices';
import { DATA_SOURCES_INIT_VIEWS } from '../../DataSources/DataSources';
import { CustomAppStatus } from '../../CustomApp/views/ActivityLog/ActivityLog';

const useStyles = makeStyles({
  mainContainer: {
    display: 'flex',
    padding: '30px 50px 0 50px',
    width: '100%',
    height: '100%',
    flexDirection: 'row',
    alignItems: 'center',
  },
  contentContainer: {
    width: '70%',
    maxWidth: 520,
    paddingLeft: 30,
  },
  imageContainer: {
    width: '30%',
    height: '100%',
  },
  discoveryImage: {
    width: '100%',
    height: '100%',
  },
  mainText: {
    paddingTop: 20,
    paddingBottom: 20,
  },
  progressBarContainer: {
    width: '100%',
    paddingBottom: 20,
  },
});

export interface AutoDiscoveryProgressProps {
  updateWizardStage: (value: AutoDiscoveryViews) => void;
}

export const AutoDiscoveryProgress: FC<AutoDiscoveryProgressProps> = ({ updateWizardStage }) => {
  const classes = useStyles({});
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [progressPercentage, setProgressPercentage] = useState<number>(10);
  const interval = useRef(null);

  const updateProgress = useCallback(
    async (tpa_id: string) => {
      const { isPreviousRunExisted, lastRunStatus, lastRunProgress } = await getAppLastRunStatus(tpa_id);
      if (isPreviousRunExisted) {
        if (lastRunProgress > progressPercentage) setProgressPercentage(lastRunProgress);
        if (lastRunStatus === CustomAppStatus.COMPLETED) {
          clearInterval(interval.current);
          fetchAvailableDsTypes().then(dsTypes => {
            if (dsTypes?.length) {
              updateWizardStage(AutoDiscoveryViews.EXPLORER);
            } else {
              updateWizardStage(AutoDiscoveryViews.NO_DATA);
            }
          });
        } else {
          setIsLoading(false);
        }
      }
    },
    [progressPercentage, updateWizardStage],
  );

  useEffect(() => {
    startAutoDiscoveryApp(CloudProvider.AWS)
      .then(tpa_id => {
        updateProgress(tpa_id);
        interval.current = setInterval(() => updateProgress(tpa_id), 10000);
      })
      .catch(e => {
        notificationService.error(e.message);
        $state.go('dataSourcesInit', { path: DATA_SOURCES_INIT_VIEWS.smallIdInit.path });
      });
    return () => clearInterval(interval.current);
  }, [updateProgress]);

  return (
    <div className={classes.mainContainer}>
      {isLoading ? (
        <BigidLoader />
      ) : (
        <>
          <div className={classes.imageContainer}>
            <DiscoveryImage className={classes.discoveryImage} />
          </div>
          <div className={classes.contentContainer}>
            <BigidHeading1>Discovering Data Sources...</BigidHeading1>
            <BigidBody1 className={classes.mainText}>
              We are discovering your data sources, this process might take some time.
              <br />
              Once completed, you will be proceeded to the wizard.
            </BigidBody1>
            <div className={classes.progressBarContainer}>
              <BigidProgressBar
                displayPercentage={true}
                percentage={progressPercentage}
                status={BigidProgressBarStatus.ACTING}
              />
            </div>
          </div>
        </>
      )}
    </div>
  );
};
