import React, { FC, useState } from 'react';
import styled from '@emotion/styled';
import { Paper } from '@mui/material';
import {
  SelectDataSourceOnboardingTypeContext,
  DEFAULT_CREATE_DATA_SOURCE_ONBOARDING_STATE,
} from './SelectDataSourceOnboardingTypeContext';
import { DataSourceOnboardingCategoriesMenu } from './components/DataSourceOnboardingCategoriesMenu/DataSourceOnboardingCategoriesMenu';
import { DataSourceOnboardingHeader } from './components/DataSourceOnboardingHeader/DataSourceOnboardingHeader';
import { SelectOnboardingTypeGridLazy } from './components/DataSourceOnboardingCategoriesGrid/SelectOnboardingTypeGridLazy';

export const CreateDataSourceOnboardingDialog = styled(Paper)`
  display: flex;
  margin: -16px;
  height: calc(100vh - 224px);
  position: relative;
  overflow: hidden;

  & * {
    -ms-overflow-style: none !important;
  }
`;

export const CreateDataSourceOnboardingContent = styled(Paper)`
  padding: 24px;
  width: 100%;
`;
export const SelectDataSourceOnboardingTypeModal: FC = () => {
  const [state, setState] = useState(DEFAULT_CREATE_DATA_SOURCE_ONBOARDING_STATE);
  return (
    <SelectDataSourceOnboardingTypeContext.Provider value={{ ...state, setState }}>
      <CreateDataSourceOnboardingDialog>
        <DataSourceOnboardingCategoriesMenu />
        <CreateDataSourceOnboardingContent>
          <DataSourceOnboardingHeader />
          <SelectOnboardingTypeGridLazy />
        </CreateDataSourceOnboardingContent>
      </CreateDataSourceOnboardingDialog>
    </SelectDataSourceOnboardingTypeContext.Provider>
  );
};
