import { module } from 'angular';
import { dateTimeService } from '@bigid-ui/i18n';
import { httpService } from '../react/services/httpService';
import { sendSSERequestAndWait } from '../react/services/sseService';
import { getApplicationPreference } from '../react/services/appPreferencesService';
import { sarConfigService } from '../react/views/DSAR/SarProfileSettings/sarConfigService';

const app = module('app');

app.factory('subjectAccessRequestService', function ($http, $translate, appSettings, DeleteConfirmation) {
  'ngInject';
  function createFilterValue(filterValue) {
    filterValue = filterValue || '';
    return getFilterValueAsArr(filterValue)
      .map(elem => elem.split('='))
      .map(elem => {
        let key;
        let value;
        if (elem.length === 2) {
          key = elem[0].trim();
          value = elem[1].trim();
          if (key === 'id') {
            key = 'userId';
          } else if (key === 'name') {
            key = 'userName';
          }
          return `${key}=${value}`;
        }
        return `userName=${elem[0].trim()}`;
      })
      .join('&');
  }

  function getFilterValueAsArr(filterValue) {
    let filterValueTmp = filterValue;
    const filterArr = [];

    const currentIndex = filterValueTmp.indexOf(',');
    if (currentIndex < 0) {
      filterArr.push(filterValueTmp.trim());
      return filterArr;
    }

    let current = [];
    while (filterValueTmp.length) {
      const currentIndex = filterValueTmp.indexOf(',');
      const currentElem = currentIndex < 0 ? filterValueTmp : filterValueTmp.substring(0, filterValueTmp.indexOf(','));
      if (currentElem.split('=').length > 1) {
        if (current.length > 0) {
          filterArr.push(current.join(','));
        }
        current = [currentElem.trim()];
      } else {
        current.push(currentElem.trim());
      }
      filterValueTmp = currentIndex < 0 ? '' : filterValueTmp.slice(currentIndex + 1);
    }

    if (current.length) {
      filterArr.push(current.join(','));
    }
    return filterArr;
  }

  const showSubmitSarRequestModalWithProfile = profile => {
    const { allEnabledDs } = profile;
    const modalOptions = {
      closeButtonText: $translate.instant('BUTTONS:CANCEL'),
      actionButtonText: $translate.instant('BUTTONS:CONFIRM'),
      headerText: $translate.instant('PERSONAL_INFO:SUBMIT_REQUEST_HEADER'),
      bodyText: '',
      bodyHtml: allEnabledDs
        ? $translate.instant('PERSONAL_INFO:SUBMIT_REQUEST_BODY:ALL_SELECTED')
        : $translate.instant('PERSONAL_INFO:SUBMIT_REQUEST_BODY:CUSTOM_SELECTED', {
            link: `<a ui-sref="subjectAccessRequest.profile({path:'profile',tab:'ds',profileId:'${profile._id}'})"
                      ng-click="$dismiss()">selected</a>`,
          }),
    };

    return DeleteConfirmation.showModal({}, modalOptions).then(() => profile);
  };

  const getProfiles = () => httpService.fetch('sar/profiles');

  const subjectAccessRequestService = {
    getSARIdentities: async ({ query, profileId }) => {
      const broadcastEvent = `sar-search-completed`;
      const url = 'sar/search/entity-sources/client';
      const filterParts = createFilterValue(query);
      const params = {
        allAttributes: 'true',
        profileId,
      };
      const searchArr = [];
      filterParts
        .split('&')
        .map(elem => elem.split('='))
        .forEach(elem => {
          if (elem[0] === 'userName' || elem[0] === 'userId') {
            params[elem[0]] = elem[1];
          } else {
            searchArr.push(`${elem[0]}=${elem[1]}`);
          }
        });
      if (searchArr.length > 0) {
        params.search = searchArr;
      }

      if (!getApplicationPreference('SSE_DSAR_ENTITY_SEARCH')) {
        const { data } = await httpService.fetch(`sar/search/entity-sources`, params);
        return data;
      }

      try {
        const { results } = await sendSSERequestAndWait('get', url, {}, params, broadcastEvent);
        return results[0];
      } catch (e) {
        throw e;
      }
    },

    getSARIdentitiesAdvanced: async function getSARIdentitiesAdvanced({ query: filter, idSourceName, profileId }) {
      const broadcastEvent = `sar-advanced-search-completed`;
      const url = `sar/search/entity-sources/${idSourceName}/client`;
      const params = {
        filter,
        profileId,
      };

      if (!getApplicationPreference('SSE_DSAR_ENTITY_SEARCH')) {
        const { data } = await httpService.fetch(`sar/search/entity-sources/${idSourceName}`, params);
        return data;
      }

      try {
        const { results } = await sendSSERequestAndWait('get', url, {}, params, broadcastEvent);
        return results[0];
      } catch (e) {
        throw e;
      }
    },

    getUserByFilter: ({ filterValue, profileId }) => {
      return $http
        .get(
          `${appSettings.serverPath}/${appSettings.version}/sar/search/cache?${createFilterValue(
            filterValue,
          ).trim()}&profileId=${profileId}`,
        )
        .then(response => {
          return response.data;
        });
    },

    getAttributes: profileId => {
      return $http
        .get(
          `${appSettings.serverPath}/${appSettings.version}/sar/attributes${
            profileId ? `?profileId=${profileId}` : ''
          }`,
        )
        .then(function (response) {
          return response.data;
        });
    },

    getScannedAttributes: function getScannedAttributes(idSourceName) {
      return Promise.all([
        subjectAccessRequestService.getAttributes(),
        $http.get(`${appSettings.serverPath}/${appSettings.version}/id_connections/${idSourceName}`).then(
          ({
            data: {
              id_connection: { attributes },
            },
          }) => attributes,
        ),
      ]).then(([systemAttributes, esAttributes]) => {
        const esAttributesSet = new Set(esAttributes.map(({ columnName }) => columnName));
        const filteredAttributes = systemAttributes.attributes[0].idsor_attributes.reduce((acc, attribute) => {
          if (esAttributesSet.has(attribute._id)) {
            acc[attribute._id] = {
              ...attribute,
              friendlyName: attribute.friendly_name,
              field_name: attribute._id,
            };
          }
          return acc;
        }, {});
        return {
          attributes: Object.values(filteredAttributes),
        };
      });
    },

    getIdentitySources: profileId => {
      return $http
        .get(`${appSettings.serverPath}/${appSettings.version}/sar/search/entity-source-connections/${profileId}`)
        .then(response => response.data);
    },

    getJit_Scans: function getJit_Scans(state) {
      const dsarScanLimit = getApplicationPreference('DSAR_SCANS_LIMIT') ?? 100;
      return $http
        .get(`${appSettings.serverPath}/${appSettings.version}/sar/scans?state=${state}&limit=${dsarScanLimit}`)
        .then(function (response) {
          return response.data.scans;
        })
        .then(function (data) {
          data.forEach(row => {
            row.create_date = dateTimeService.formatDate(row.create_date);
            row.expires_on = dateTimeService.formatDate(row.expires_on);
          });
          return data;
        });
    },
    launchJITScan: function launchJITScan(jitScanData) {
      return $http
        .post(appSettings.serverPath + '/' + appSettings.version + '/sar/reports', jitScanData)
        .then(function (response) {
          return response.data;
        });
    },
    deleteJitScan: function deleteJitScan(userID) {
      return $http
        .delete(appSettings.serverPath + '/' + appSettings.version + '/sar/reports/' + userID)
        .then(function (response) {
          return response.data;
        });
    },
    stopJitScan: function stopJitScan(scanID) {
      return $http
        .put(appSettings.serverPath + '/' + appSettings.version + '/scans/' + scanID + '/requestStop')
        .then(function (response) {
          return response.data;
        });
    },
    pauseJitScan: function pauseJitScan(requestId, subScanIds) {
      return $http
        .post(`${appSettings.serverPath}/${appSettings.version}/sar/reports/${requestId}/pause`, {
          subScanIds,
        })
        .then(function (response) {
          return response.data;
        });
    },
    resumeJitScan: function resumeJitScan(requestId, subScanIds) {
      return $http
        .post(`${appSettings.serverPath}/${appSettings.version}/sar/reports/${requestId}/resume`, {
          subScanIds,
        })
        .then(function (response) {
          return response.data;
        });
    },
    getJITCsvFile: userId => {
      return httpService.downloadFile(`sar/reports/file-download/${userId}`, { format: 'csv' });
    },
    getJITCsvShortReportFile: requestId => {
      return httpService.downloadFile(`sar/reports/file-download/${requestId}/short-report`, { format: 'csv' });
    },
    getAndAggregateIDSORAttributes: profileId => {
      return subjectAccessRequestService
        .getAttributes(profileId)
        .then(attributes => filterAttributeByProfile(profileId, attributes))
        .then(attributes => {
          const aggregatedResult = {
            searchable: [],
            other: [],
            original: [],
          };

          try {
            const original = attributes.attributes[0].idsor_attributes || [];
            const originalOther = attributes.attributes[0].other || [];
            const classifications = attributes.classifications || [];
            aggregatedResult.searchable = original
              .filter(({ searchable }) => searchable === true)
              .concat(attributes.attributes[0].predefinedAttributes || [])
              .map(origToAggr);
            aggregatedResult.other = [...original, ...originalOther]
              .filter(({ searchable }) => searchable === false)
              .map(origToAggr);
            aggregatedResult.classifications = classifications.map(origToAggr);
            aggregatedResult.original = original;
          } catch (e) {
            // means result doesn't contain expected data
            window.console.error(e);
          }

          return aggregatedResult;
        });

      function origToAggr({ _id, original_name, friendly_name, glossary_id, overrideSureMatch, searchable }) {
        return {
          _id,
          friendly_name,
          glossary_id,
          overrideSureMatch,
          searchable,
          original_name: _id,
        };
      }
      function filterAttributeByProfile(profileId, attributes) {
        return sarConfigService
          .getProfileById(profileId, {
            skip: 0,
            limit: 200,
          })
          .then(({ esConnections, allEnabledEs }) => {
            if (allEnabledEs || !attributes.attributes[0].idsor_attributes) {
              return attributes;
            }
            const attributesInAllEs = new Set(
              esConnections.flatMap(({ attributes }) => attributes?.map(attribute => attribute?.columnName)),
            );
            const attributesInProfile = new Set(
              esConnections
                .filter(({ sourceEnabled, profileEnabled }) => sourceEnabled && profileEnabled)
                .flatMap(({ attributes }) => attributes.map(({ columnName }) => columnName)),
            );

            attributes.attributes[0].other = attributes.attributes[0].idsor_attributes.filter(
              ({ _id }) => !attributesInAllEs.has(_id),
            );
            attributes.attributes[0].idsor_attributes = attributes.attributes[0].idsor_attributes.filter(({ _id }) =>
              attributesInProfile.has(_id),
            );
            return attributes;
          });
      }
    },

    setNewDeletionValidationStatus: configuration => {
      return $http
        .post(`${appSettings.serverPath}/${appSettings.version}/sar/deletion-validations/config`, configuration)
        .then(response => {
          return response.data;
        });
    },

    createTaggedEntity: function createTaggedEntity(entity) {
      return $http
        .post(`${appSettings.serverPath}/${appSettings.version}/tagged-entities`, entity)
        .then(function (response) {
          return response.data;
        });
    },

    getTaggedEntity: function getTaggedEntity(userFilter) {
      const encodedFilter = encodeURIComponent(userFilter);
      return $http
        .get(`${appSettings.serverPath}/${appSettings.version}/tagged-entities/${encodedFilter}`)
        .then(function (response) {
          return response.data;
        });
    },
    showSubmitSarRequestModalWithProfile,
    getProfiles,
  };

  return subjectAccessRequestService;
});
