import React, { FC, useCallback, useEffect, useReducer, useState } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import {
  BigidLoader,
  BigidWizardHorizontalNav,
  BigidWizardHorizontalStepStatus,
  useHorizontalWizard,
} from '@bigid-ui/components';
import {
  FmsdPreDiscoverCustomizeTypes,
  FmsdPreDiscoverViewsIds,
  getFmsdPreDiscoverViews,
} from './FmsdPreDiscoverViews/FmsdPreDiscoverViews';
import { MainArea, NavigationButtons, TopArea } from '../containers';
import { FmsdViewsIds } from '../FmsdViews';
import { WizardFlowActions } from '../hooks/useWizardFlow';
import { getInitialReducerState, PreDiscoverContext, preDiscoverReducer, ReducerActions, WizardActions } from './hooks';
import { FmsdConfigKeys, FmsdState, hasDsConnections, persistFmsdState } from '../fmsdServices';
import { FmsdPageViewsEvents, FmsdTrackingEvents, trackFmsdEvent } from '../fmsdEventsTrackerUtils';
import { startAutoDiscoveryApp } from '../../../services/autoDiscoveryService';
import { CredsStatus } from '../FmsdComponents/ConnectionsStatus/ConnectionsStatus';
import { CloudProvider } from '../../AutoDiscoveryWizard/autoDiscoveryWizardServices';
import { $state } from '../../../services/angularServices';
import { CONFIG } from '../../../../config/common';
import { OverwriteSettingsDialog } from '../FmsdComponents/OverwriteSettingsDialog/OverwriteSettingsDialog';
import { useTheme } from '@mui/styles';

const useStyles = makeStyles({
  container: {
    display: 'flex',
    width: '100%',
    height: '100%',
    flexDirection: 'column',
    position: 'fixed',
    overflowY: 'scroll',
    left: 0,
    marginTop: -7,
    zIndex: 2,
  },
  mainViewContainer: {
    minHeight: 502,
    position: 'relative',
  },
});

export type PreDiscoverProps = WizardFlowActions & FmsdState;

const getFmsdPageViewsEvent = (
  activeStepId: FmsdPreDiscoverViewsIds,
  classifiersMethodology: FmsdPreDiscoverCustomizeTypes,
): FmsdPageViewsEvents => {
  const fmsdPageViewsEventMap = {
    [FmsdPreDiscoverViewsIds.CUSTOMIZE]: {
      [FmsdPreDiscoverCustomizeTypes.CATEGORIES]: FmsdPageViewsEvents.FMSD_CATEGORIES_PAGE_VIEW,
      [FmsdPreDiscoverCustomizeTypes.REGULATIONS]: FmsdPageViewsEvents.FMSD_REGULATIONS_PAGE_VIEW,
    },
    [FmsdPreDiscoverViewsIds.CONNECT]: FmsdPageViewsEvents.FMSD_CONNECT_PAGE_VIEW,
    [FmsdPreDiscoverViewsIds.DONE]: FmsdPageViewsEvents.FMSD_DISCOVER_PAGE_VIEW,
  };

  if (activeStepId === FmsdPreDiscoverViewsIds.CUSTOMIZE) {
    return fmsdPageViewsEventMap[activeStepId][classifiersMethodology];
  }

  return fmsdPageViewsEventMap[activeStepId];
};

const trackFmsdEventWrapper = (pageEventType: FmsdPageViewsEvents) => {
  return (eventType: FmsdTrackingEvents, data?: Record<string, unknown>) => {
    const event = `${pageEventType}_${eventType}`;
    trackFmsdEvent(event as any, data);
  };
};

export const FmsdPreDiscover: FC<PreDiscoverProps> = ({
  isFirstWizardUsage,
  setActiveViewId,
  fmsdPreDiscoverActiveViewId,
  fmsdPreDiscoverConnectionsState,
  fmsdPreDiscoverSelectionData,
}) => {
  const { container, mainViewContainer } = useStyles();
  const [isNextButtonDisabled, setIsNextButtonDisabled] = useState(true);
  const [isOpen, setIsDialogOpen] = useState(false);
  const [state, dispatch] = useReducer(preDiscoverReducer, {}, getInitialReducerState);
  const [isLoading, setIsLoading] = useState(true);
  const [isDsExisted, setIsDsExisted] = useState(false);
  const [shouldDisplaySkip, setShouldDisplaySkip] = useState(false);
  const { steps, setActiveStepId, updateStepAttributes, ActiveView, activeStepId, goToNext, goToPrev, noPrev } =
    useHorizontalWizard(getFmsdPreDiscoverViews(fmsdPreDiscoverActiveViewId), '', true);
  const trackFmsdEventPerPageView = trackFmsdEventWrapper(
    getFmsdPageViewsEvent(activeStepId as FmsdPreDiscoverViewsIds, state?.selectionData?.classifiersMethodology),
  );
  const isConnectedToDiscoveryApp = Object.keys(state.connections).some(
    key => state.connections[key].credsStatus === CredsStatus.VALID,
  );
  const theme = useTheme();

  const horizontalWizardColors = {
    [BigidWizardHorizontalStepStatus.ACTIVE]: {
      main: theme.vars.palette.bigid.primary900,
      secondary: theme.vars.palette.bigid.primary900,
      fill: 'white',
    },
    [BigidWizardHorizontalStepStatus.WARNING]: {
      main: theme.vars.palette.bigid.primary900,
      secondary: theme.vars.palette.bigid.primary900,
      fill: 'white',
    },
    [BigidWizardHorizontalStepStatus.FAIL]: {
      main: theme.vars.palette.bigid.primary900,
      secondary: theme.vars.palette.bigid.primary900,
      fill: 'white',
    },
    [BigidWizardHorizontalStepStatus.IN_PROGRESS]: {
      main: theme.vars.palette.bigid.primary900,
      secondary: theme.vars.palette.bigid.primary900,
      fill: 'white',
    },
    [BigidWizardHorizontalStepStatus.DONE]: {
      main: theme.vars.palette.bigid.primary900,
      secondary: theme.vars.palette.bigid.primary900,
      fill: 'white',
    },
  };

  useEffect(() => {
    const fetchDsConnections = async () => {
      const isDsExisted = await hasDsConnections();
      setIsDsExisted(isDsExisted);
    };

    fetchDsConnections();
  }, []);

  useEffect(() => {
    const isSkipAvailable = isDsExisted || !isConnectedToDiscoveryApp;
    setShouldDisplaySkip(isSkipAvailable && activeStepId === FmsdPreDiscoverViewsIds.CONNECT);
  }, [activeStepId, isConnectedToDiscoveryApp, isDsExisted]);

  useEffect(() => {
    dispatch({
      type: ReducerActions.INIT,
      payload: { selectionData: fmsdPreDiscoverSelectionData, connections: fmsdPreDiscoverConnectionsState },
    });
    setActiveStepId(fmsdPreDiscoverActiveViewId);
    setIsLoading(false);
  }, [fmsdPreDiscoverActiveViewId, fmsdPreDiscoverConnectionsState, fmsdPreDiscoverSelectionData, setActiveStepId]);

  const currentStepIndex = steps.findIndex(({ id }) => id === activeStepId);
  const onStepClick = (id: string) => {
    setActiveStepId(id);
  };

  const goNextAndUpdateStepAttrs = () => {
    updateStepAttributes([{ stepId: activeStepId, attr: { status: BigidWizardHorizontalStepStatus.DONE } }]);
    goToNext();
  };

  const getCurrentStepStatus = useCallback(() => {
    return steps[currentStepIndex].status;
  }, [currentStepIndex, steps]);

  const setStepAsActive = useCallback(() => {
    persistFmsdState({ [FmsdConfigKeys.PRE_DISCOVER_ACTIVE_VIEW_ID]: activeStepId });
    updateStepAttributes([{ stepId: activeStepId, attr: { status: BigidWizardHorizontalStepStatus.ACTIVE } }]);
  }, [activeStepId, updateStepAttributes]);

  const executeLastStepAction = () => {
    setActiveViewId(FmsdViewsIds.DISCOVER);
  };

  const moveToNext = async () => {
    if (activeStepId === FmsdPreDiscoverViewsIds.CONNECT) {
      setIsLoading(true);
      for (const type in state.connections) {
        if (
          (type === CloudProvider.AWS || type === CloudProvider.AZURE) &&
          state.connections[type]?.credsStatus &&
          state.connections[type]?.credsStatus !== CredsStatus.WRONG_CREDS
        ) {
          await startAutoDiscoveryApp(type as CloudProvider);
        }
      }
      executeLastStepAction();
    }
    if (activeStepId === FmsdPreDiscoverViewsIds.CUSTOMIZE) {
      if (!isFirstWizardUsage && state.isSelectionChanged) {
        setIsDialogOpen(true);
        return;
      } else {
        persistSelection();
      }
    }
    goNextAndUpdateStepAttrs();
  };

  const persistSelection = () => {
    if (state.selectionData.classifiersMethodology === FmsdPreDiscoverCustomizeTypes.REGULATIONS) {
      persistSelectedRegulations(state.selectionData.regulations);
    } else {
      persistSelectedCategories(state.selectionData.categories);
    }
    goNextAndUpdateStepAttrs();
  };

  const persistSelectedCategories = (categories: string[]) => {
    dispatch({
      type: ReducerActions.PERSIST_SELECTED_CATEGORIES,
      payload: {
        selectionData: {
          categories,
          classifiersMethodology: FmsdPreDiscoverCustomizeTypes.CATEGORIES,
        },
      },
    });
  };

  const persistSelectedRegulations = (regulations: string[]) => {
    dispatch({
      type: ReducerActions.PERSIST_SELECTED_REGULATIONS,
      payload: {
        selectionData: {
          regulations,
          classifiersMethodology: FmsdPreDiscoverCustomizeTypes.REGULATIONS,
        },
      },
    });
  };

  const moveToPrev = async () => {
    if (
      state.selectionData.classifiersMethodology === FmsdPreDiscoverCustomizeTypes.REGULATIONS &&
      activeStepId === FmsdPreDiscoverViewsIds.CUSTOMIZE
    ) {
      trackFmsdEvent(FmsdTrackingEvents.FMSD_REGULATIONS_SWITCH_TO_CATEGORIES_CLICK);
      dispatch({
        type: ReducerActions.SWITCH_CUSTOMIZE_VIEW,
        payload: {
          selectionData: {
            classifiersMethodology: FmsdPreDiscoverCustomizeTypes.CATEGORIES,
          },
        },
      });
    } else {
      trackFmsdEventPerPageView(FmsdTrackingEvents.BACK_CLICK);
      goToPrev();
    }
  };

  const onSkipClick = () => {
    trackFmsdEventPerPageView(FmsdTrackingEvents.SKIP_CLICK);

    if (isDsExisted) {
      executeLastStepAction();
      goNextAndUpdateStepAttrs();
    } else if (!isConnectedToDiscoveryApp) {
      $state.go(CONFIG.states.GETTING_STARTED);
    }
  };

  const isNextStepDisabled = () => {
    return steps[currentStepIndex + 1]?.status === BigidWizardHorizontalStepStatus.DISABLED;
  };

  const disableNextSteps = () => {
    persistFmsdState({ [FmsdConfigKeys.PRE_DISCOVER_ACTIVE_VIEW_ID]: activeStepId });
    const stepsToUpdate = [{ stepId: activeStepId, attr: { status: BigidWizardHorizontalStepStatus.ACTIVE } }];
    for (let i = currentStepIndex + 1; i < steps.length - 1; i++) {
      stepsToUpdate.push({ stepId: steps[i].id, attr: { status: BigidWizardHorizontalStepStatus.DISABLED } });
    }
    updateStepAttributes(stepsToUpdate);
  };

  const wizardActions: WizardActions = {
    setIsNextButtonDisabled,
    setStepAsActive,
    disableNextSteps,
    isNextStepDisabled,
    getCurrentStepStatus,
  };

  return (
    <PreDiscoverContext.Provider
      value={{
        connections: state.connections,
        selectionsData: state.selectionData,
        isSelectionChanged: state.isSelectionChanged,
        wizardActions,
        dispatch,
      }}
    >
      <div className={container} data-aid="FmsdPreDiscvoerView-main-container">
        <TopArea>
          <BigidWizardHorizontalNav
            statusColors={horizontalWizardColors}
            onSelect={onStepClick}
            steps={steps}
            activeId={activeStepId}
          />
        </TopArea>
        <MainArea>
          <div className={mainViewContainer}>{isLoading ? <BigidLoader /> : <ActiveView />}</div>
          <NavigationButtons
            noNext={isNextButtonDisabled}
            noPrev={noPrev}
            goToNext={moveToNext}
            goToPrev={moveToPrev}
            onSkipClick={onSkipClick}
            shouldDisplaySkip={shouldDisplaySkip}
            classifiersMethodology={state.selectionData.classifiersMethodology}
            activeStepId={activeStepId}
            isConnectedToDiscoveryApp={isConnectedToDiscoveryApp}
          />
          <OverwriteSettingsDialog onConfirm={persistSelection} isOpen={isOpen} updateIsDialogOpen={setIsDialogOpen} />
        </MainArea>
      </div>
    </PreDiscoverContext.Provider>
  );
};
