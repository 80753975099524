import { Notification } from '@bigid-ui/components';
import debounce from 'lodash/debounce';
import { sessionStorageService } from '../../common/services/sessionStorageService';
import { dateTimeService } from '@bigid-ui/i18n';
import {
  getNotificationTemplate,
  NotificationTypeToSeverity,
} from '../utilities/headerNotifications/headerNotificationsUtils';
import { NotificationResponse } from '../utilities/headerNotifications/headerNotificationTypes';
import { headerEventEmitter, HeaderEvents } from './eventEmitters/headerEvents';
import { httpService } from './httpService';
import { queryService } from './queryService';
import { SystemEvents, systemEventsEmitter } from './systemEvents';
import { getApplicationPreference } from './appPreferencesService';

const LIMIT = 10;

interface NotificationsResponse {
  notifications: NotificationResponse[];
  totalCount: number;
}

let notifications: Notification[] = [];
let notificationsTotalCount = 0;

export const getNotifications = (): Notification[] => notifications;

const emitLoadingDone = debounce(
  ({ isScroll = false, hasError = false }) =>
    headerEventEmitter.emit(HeaderEvents.UPDATE_NOTIFICATIONS, {
      data: { isLoading: false, isScroll, hasError },
    }),
  500,
);

const checkIfNeedToUpdateNotifications = async () => {
  const totalNotifications = await getTotalNotifications();
  const hasNewNotifications = totalNotifications === notificationsTotalCount;

  const unreadTotal = await getUnreadNotifications();
  const hasNewUnreadNotifications = unreadTotal > 0;

  if (
    !hasNewNotifications &&
    !hasNewUnreadNotifications &&
    notificationsTotalCount > 0 &&
    notifications.length === notificationsTotalCount
  ) {
    return false;
  }

  return true;
};

const fetchNotifications = async (skip = 0) => {
  const gridConfigQuery = queryService.getGridConfigQuery({
    skip,
    limit: LIMIT,
    sort: [{ field: 'createdAt', order: 'desc' }],
  });
  const {
    data: { data },
  } = await httpService.fetch<{ data: NotificationsResponse }>(`notifications?${gridConfigQuery}`);

  return data;
};

export const getNewNotifications = async () => {
  headerEventEmitter.emit(HeaderEvents.UPDATE_NOTIFICATIONS, { data: { isLoading: true } });
  try {
    const shouldUpdateNotifications = await checkIfNeedToUpdateNotifications();
    if (!shouldUpdateNotifications) {
      emitLoadingDone({});
      return;
    }

    const data = await fetchNotifications();

    notifications = (data?.notifications || []).map(formatNotification);
    notificationsTotalCount = data?.totalCount || 0;

    if (data?.notifications?.length) {
      await httpService.put('notifications', {
        beforeDate: data?.notifications[0].createdAt,
        updatedFields: { isRead: true },
      });
    }

    emitLoadingDone({});
  } catch (error) {
    console.error(error);
    emitLoadingDone({ hasError: true });
  }
};

export const updateNotifications = async () => {
  try {
    if (notifications.length === notificationsTotalCount) {
      return;
    }
    headerEventEmitter.emit(HeaderEvents.UPDATE_NOTIFICATIONS, { data: { isLoading: true, isScroll: true } });

    const data = await fetchNotifications(notifications.length);

    notifications = [...notifications, ...(data?.notifications || []).map(formatNotification)];
    notificationsTotalCount = data?.totalCount || 0;

    emitLoadingDone({ isScroll: true });
  } catch (error) {
    console.error(error);
    emitLoadingDone({ isScroll: true });
  }
};

export const getUnreadNotifications = async () => {
  if (!getApplicationPreference('ENABLE_NOTIFICATIONS')) {
    return 0;
  }

  const gridConfigQuery = queryService.getGridConfigQuery({
    filter: [{ field: 'isRead', operator: 'equal', value: false }],
  });
  const {
    data: { data },
  } = await httpService.fetch<{ data: { count: number } }>(`notifications/count?${gridConfigQuery}`);
  return data?.count || 0;
};

const getTotalNotifications = async () => {
  if (!getApplicationPreference('ENABLE_NOTIFICATIONS')) {
    return 0;
  }

  const {
    data: { data },
  } = await httpService.fetch<{ data: { count: number } }>('notifications/count');
  return data?.count || 0;
};

const formatNotification = ({ createdAt, type, payload, ...notification }: NotificationResponse): Notification => {
  const createdAtFormatted = dateTimeService.formatDate(createdAt, { formatString: 'MMMM d, yyyy h:mm a' });
  return {
    ...notification,
    createdAtFormatted,
    severity: NotificationTypeToSeverity[type],
    ...getNotificationTemplate({ type, payload }),
  };
};

const cleanNotificationsListener = systemEventsEmitter.addEventListener(SystemEvents.LOGIN, () => {
  notifications = [];
  notificationsTotalCount = 0;
});

window.addEventListener('unload', () => {
  cleanNotificationsListener();
});
