import { httpService } from '../../../services/httpService';
import { notificationService } from '../../../services/notificationService';
import {
  ActionData,
  BigidFieldFilterOperator,
  BigidFilter,
  EntityEvents,
  entityEventsEmitter,
} from '@bigid-ui/components';
import { ReactText } from 'react';
import { isNil, startCase } from 'lodash';
import { ConfirmationDialogOptions, showConfirmationDialog } from '../../../services/confirmationDialogService';
import { $state } from '../../../services/angularServices';
import {
  CreateScanTemplateResponse,
  ScanTemplate,
  ScanTemplateActionType,
  ScanTemplateFavorite,
  ScanTemplateGridRow,
  ScanTemplateUserPreferences,
  ScanTemplateWithoutId,
  UpsertScanTemplateType,
} from './scanTemplateTypes';
import { userPreferencesService } from '../../../services/userPreferencesService';
import { CONFIG } from '../../../../config/common';
import { queryService } from '../../../services/queryService';
import { DEFAULT_SORTING } from './ScanTemplateWizard/Steps/ClassificationStep/classificationService';
import { BigidGridQueryComponents } from '@bigid-ui/grid';
import { getApplicationPreference } from '../../../services/appPreferencesService';
import { isAISecurityAndGovernanceEnabled } from '../../../utilities/featureFlagUtils';

const SCAN_TEMPLATES_ROUTE = 'scan-templates';

const SCAN_TEMPLATE_ACTIONS_WITH_CONFIRM = [ScanTemplateActionType.DELETE, ScanTemplateActionType.DUPLICATE];

const MAX_FAVORITE_TEMPLATES = 15;

const scanTemplatePreference = CONFIG.states.SCANS_SCAN_TEMPLATES;
export const ASSESSMENT_SCAN = 'Assessment scan';
export const AI_ASSESSMENT_SCAN = 'AI Assessment scan';
export const DEFAULT_DATA_SOURCE_SCAN_NAME = 'Data source scan';

let favoriteTemplatesForGrid: ScanTemplateGridRow[] = [];

export const getTemplateById = async (id: string, addTemplateMetadata?: boolean): Promise<ScanTemplate> => {
  const {
    data: {
      data: { created_at, updated_at, createdBy, ...scanTemplate },
    },
  } = await httpService.fetch<{ data: ScanTemplate }>(`${SCAN_TEMPLATES_ROUTE}/${id}`);
  return { ...scanTemplate, ...(addTemplateMetadata ? { created_at, updated_at, createdBy } : {}) };
};

export const createScanTemplate = async (
  templateToSave: ScanTemplateWithoutId,
  isAllowDuplicate: boolean,
): Promise<CreateScanTemplateResponse> => {
  removeUiOnlyFieldsFromTemplate(templateToSave);
  const {
    data: { data },
  } = await httpService.post<{ data: CreateScanTemplateResponse }, UpsertScanTemplateType>(SCAN_TEMPLATES_ROUTE, {
    scanTemplate: templateToSave,
    isAllowDuplicate,
  });
  return data;
};

export const updateScanTemplate = async (
  templateToSave: ScanTemplate,
  isAllowDuplicate: boolean,
): Promise<CreateScanTemplateResponse> => {
  removeUiOnlyFieldsFromTemplate(templateToSave);
  const {
    data: { data },
  } = await httpService.put<{ data: CreateScanTemplateResponse }, UpsertScanTemplateType>(
    `${SCAN_TEMPLATES_ROUTE}/${templateToSave._id}`,
    {
      scanTemplate: templateToSave,
      isAllowDuplicate,
    },
  );
  return data;
};

export const isScanTemplateNameExist = async (name: string, showNotification: boolean) => {
  const gridConfigQuery = queryService.getGridConfigQuery({
    skip: 0,
    limit: 1,
    requireTotalCount: true,
    filter: [
      {
        field: 'name',
        value: [name],
        operator: 'in',
      },
    ],
  });

  try {
    const {
      data: { data },
    } = await httpService.fetch(`${SCAN_TEMPLATES_ROUTE}?${gridConfigQuery}`);
    const { totalCount } = data;
    showNotification && totalCount > 0 && notificationService.error(`Scan Template name is already in use`);
    return totalCount > 0;
  } catch (error) {
    notificationService.error('An error occurred during creating new Scan Template name.');
    console.error(error);
  }
};

export async function deleteScanTemplate(id: ReactText, isFavorite: boolean) {
  try {
    const {
      data: {
        data: { success, message },
      },
    } = await httpService.delete(`${SCAN_TEMPLATES_ROUTE}/${id}`);
    if (!success) {
      notificationService.error(message);
      return {};
    } else {
      let hasFavoriteTemplate;
      if (isNil(isFavorite)) {
        const userPreference = await userPreferencesService.get(scanTemplatePreference);
        hasFavoriteTemplate = userPreference?.data?.favorites?.some(
          (favoriteTemplate: { scanTemplateId: string; scanTemplateName: string }) =>
            favoriteTemplate.scanTemplateId === id,
        );
      }

      if (isFavorite || hasFavoriteTemplate) {
        await updateFavoritesPreference(id as string);
      }
      notificationService.success('Deleted successfully.');
      return { shouldGridReload: true, shouldClearSelection: true, entityEventToEmit: EntityEvents.RELOAD };
    }
  } catch (error) {
    notificationService.error('An error occurred deleting the current template. See logs for more information.');
    console.error(error);
  }
}

export async function duplicateScanTemplate(scanTemplateId: ReactText) {
  try {
    await httpService.post(`${SCAN_TEMPLATES_ROUTE}/duplicate`, {
      scanTemplateId,
    });
    notificationService.success('Scan Template was duplicated successfully!');
    return {
      shouldGridReload: true,
      entityEventToEmit: EntityEvents.RELOAD,
    };
  } catch (e) {
    console.error(e);
    notificationService.error('Could not duplicate scan template. See logs for more information.');
    return {};
  }
}

export async function getScanTemplates(gridConfigQuery: string, correlationSetsRequired?: boolean) {
  const query = correlationSetsRequired ? 'correlationSetsRequired=true&' : '';
  try {
    const {
      data: {
        data: { scanTemplates, totalCount },
      },
    } = await httpService.fetch<{ data: { scanTemplates: ScanTemplateGridRow[]; totalCount: number } }>(
      `${SCAN_TEMPLATES_ROUTE}?${query}${gridConfigQuery}`,
    );

    return {
      scanTemplates,
      totalCount,
    };
  } catch (e) {
    console.error(e);
    notificationService.error('Could get scan templates. See logs for more information.');
    return {};
  }
}

export const goToScanTemplatePage = async (id?: ReactText, name?: string) => {
  $state.go('scanTemplate', {
    id,
    name,
  });
  return { shouldGridReload: false, entityEventToEmit: EntityEvents.ADD };
};

export function isOOTB(actionData: ActionData) {
  const {
    selectedRows: [{ isOOTB }],
  } = actionData;
  return !!isOOTB;
}

export async function runActionDialog(actionData: ActionData, actionType: ScanTemplateActionType) {
  const {
    selectedRowIds,
    selectedRows: [{ name, isFavorite }],
  } = actionData;
  const dialogProps: ConfirmationDialogOptions = {
    entityNameSingular: 'Scan Template',
    entityNamePlural: 'Scan Templates',
    actionName: startCase(actionType),
    actionButtonName: startCase(actionType),
  };
  if (actionType === ScanTemplateActionType.DELETE) {
    dialogProps.customDescription =
      'Deleting this template will delete any related saved scan. Are you sure you wish to proceed?';
  }

  const isRequiredConfirm = SCAN_TEMPLATE_ACTIONS_WITH_CONFIRM.includes(actionType);
  const shouldCompleteAction = isRequiredConfirm ? await showConfirmationDialog(dialogProps) : true;

  if (shouldCompleteAction) {
    const [id] = selectedRowIds;

    switch (actionType) {
      case ScanTemplateActionType.EDIT:
        return goToScanTemplatePage(id, name);
      case ScanTemplateActionType.DUPLICATE:
        return await duplicateScanTemplate(id);
      case ScanTemplateActionType.DELETE:
        return await deleteScanTemplate(id, isFavorite);
    }
  } else {
    return {};
  }
}

export const runCardAction = async ({ _id, ...rest }: ScanTemplateGridRow, action: ScanTemplateActionType) => {
  const { shouldGridReload, entityEventToEmit } = await runActionDialog(
    {
      selectedRowIds: [_id],
      selectedRows: [{ _id, ...rest }],
    },
    action,
  );
  if (shouldGridReload) {
    entityEventsEmitter.emit(entityEventToEmit);
  }
};

export const handleTemplateError = (e: any) => {
  if (e?.response?.data?.errMessage) {
    notificationService.error(e.response.data.errMessage);
  } else {
    notificationService.error('Failed to save scan template. See logs for more information.');
    console.error(e);
  }
};

const removeUiOnlyFieldsFromTemplate = (template: ScanTemplate | ScanTemplateWithoutId) => {
  template.bulkClassifierSelection?.forEach(regulation => {
    delete regulation.isPartial;
    delete regulation.id;
    delete regulation._id;
  });
};

const addFavoritesPreference = async (scanTemplateId: string, scanTemplateName?: string) => {
  await userPreferencesService.add<ScanTemplateUserPreferences>({
    preference: scanTemplatePreference,
    data: { favorites: [{ scanTemplateId, scanTemplateName }] },
  });
};

const insertTemplatesToFavorites = async (favoritesToUpdate: ScanTemplateFavorite[]) => {
  const scanTemplateUserPreference = await userPreferencesService.get(scanTemplatePreference);
  await userPreferencesService.update<ScanTemplateUserPreferences>({
    preference: scanTemplatePreference,
    data: { ...(scanTemplateUserPreference?.data || {}), favorites: favoritesToUpdate },
  });
};

export const updateFavoritesPreference = async (
  scanTemplateId: string,
  scanTemplateName?: string,
  isEdit?: boolean,
): Promise<boolean> => {
  const preference = await userPreferencesService.get<ScanTemplateUserPreferences>(scanTemplatePreference);
  if (!preference.data) {
    await addFavoritesPreference(scanTemplateId, scanTemplateName);
  } else {
    const {
      data: { favorites = [] },
    } = preference;
    const favoritesIndex = favorites.findIndex(favorite => favorite.scanTemplateId === scanTemplateId);
    if (favoritesIndex === -1) {
      if (isEdit) {
        return false;
      }
      const favoritesToUpdate = [...favorites, { scanTemplateId, scanTemplateName }];
      if (favoritesToUpdate.length > MAX_FAVORITE_TEMPLATES) {
        notificationService.error(`Maximum of ${MAX_FAVORITE_TEMPLATES} favorite templates has been reached.`);
        return false;
      }
      await insertTemplatesToFavorites(favoritesToUpdate);
    } else {
      favorites.splice(favoritesIndex, 1);
      const favoritesToUpdate = isEdit ? [...favorites, { scanTemplateId, scanTemplateName }] : favorites;

      await insertTemplatesToFavorites(favoritesToUpdate);
      !isEdit && notificationService.success(`${scanTemplateName} removed from favorites successfully!`);
      return false;
    }
  }
  notificationService.success(`${scanTemplateName} added to favorites successfully!`);
  return true;
};

export const fetchTemplateFavoritePreferences = async () => {
  try {
    const templatePreferences = await userPreferencesService.get<ScanTemplateUserPreferences>(
      CONFIG.states.SCANS_SCAN_TEMPLATES,
    );
    return templatePreferences?.data?.favorites || [];
  } catch (e) {
    notificationService.error('Failed to fetch favorites from preferences. See logs for more information.');
    console.error(e);
  }
};

export const fetchFavoriteTemplates = async (
  favorites: string[],
  additionalFilters: BigidFilter = [],
): Promise<{ favoriteTemplates: ScanTemplateGridRow[] }> => {
  const getFavoritesFilter = {
    filter: [
      ...additionalFilters,
      {
        field: '_id',
        value: favorites,
        operator: 'in' as BigidFieldFilterOperator,
      },
    ],
  };

  const { scanTemplates: favoriteTemplates = [] } = await getScanTemplates(
    queryService.getGridConfigQuery({
      sort: DEFAULT_SORTING,
      ...getFavoritesFilter,
    }),
  );

  return { favoriteTemplates };
};

export const getDefaultScanTemplate = async () => {
  try {
    const defaultDataSourceScannerFilter = {
      filter: [
        {
          field: 'name',
          value: defaultScanTemplateFilter(),
          operator: 'in' as BigidFieldFilterOperator,
        },
        {
          field: 'isOOTB',
          value: true,
          operator: 'equal' as BigidFieldFilterOperator,
        },
      ],
    };
    const { scanTemplates } = await getScanTemplates(
      queryService.getGridConfigQuery({
        sort: DEFAULT_SORTING,
        ...defaultDataSourceScannerFilter,
      }),
    );

    return scanTemplates;
  } catch (e) {
    notificationService.error('Failed to get Data source scan template. See logs for more information.');
    console.error(e);
  }
};

function defaultScanTemplateFilter() {
  const filter = [DEFAULT_DATA_SOURCE_SCAN_NAME];
  if (isAssessmentScanEnabled()) {
    filter.push(ASSESSMENT_SCAN);
  }
  if (isAISecurityAndGovernanceEnabled()) {
    filter.push(AI_ASSESSMENT_SCAN);
  }
  return filter;
}

function isAssessmentScanEnabled() {
  return getApplicationPreference('ASSESSMENT_SCAN_ENABLED');
}

export const getTemplatesForGrid = async (
  queryComponents: BigidGridQueryComponents,
  correlationSetsRequired?: boolean,
) => {
  favoriteTemplatesForGrid = [];
  const favoriteScanTemplates = await fetchTemplateFavoritePreferences();
  const favoriteTemplatesToFetch = favoriteScanTemplates?.map(favorite => favorite.scanTemplateId);
  const templatesNotInFavoritesFilter = {
    field: '_id',
    value: favoriteTemplatesToFetch,
    operator: 'notIn' as BigidFieldFilterOperator,
  };
  const gridConfigQuery = queryService.getGridConfigQuery({
    ...queryComponents,
    requireTotalCount: true,
    sort: [...queryComponents.sort],
    filter: [...queryComponents.filter, templatesNotInFavoritesFilter],
  });

  const { scanTemplates, totalCount } = await getScanTemplates(gridConfigQuery, correlationSetsRequired);
  const { favoriteTemplates } = await fetchFavoriteTemplates(favoriteTemplatesToFetch, queryComponents.filter);
  if (favoriteTemplates.length > 0) {
    favoriteTemplatesForGrid = favoriteTemplates.map(favoriteTemplate => ({
      ...favoriteTemplate,
      isFavorite: true,
    }));

    if (correlationSetsRequired) {
      favoriteTemplatesForGrid = favoriteTemplates.filter(
        favoriteTemplate => favoriteTemplate?.allEnabledIdSor || favoriteTemplate?.idConnectionList?.length > 0,
      );
    }
  }
  return {
    data: [...favoriteTemplatesForGrid, ...scanTemplates],
    totalCount: totalCount + favoriteTemplatesForGrid.length,
  };
};
