import { Reducer, useReducer, ChangeEvent, KeyboardEvent, useCallback, useEffect, useMemo, useRef } from 'react';
import { useCancelablePromise, usePrevious } from '@bigid-ui/components';
import {
  MetadataSearchFetchFiltersPayload,
  MetadataSearchFetchFiltersResponse,
  MetadataSearchFetchQuickResultsPayload,
  MetadataSearchFetchQuickResultsResponse,
  MetadataSearchFetchFilterSuggestionsResponse,
  getEntityTypeConfigs,
} from './MetadataSearchService';
import {
  MetadataSearchQuery,
  MetadataSearchResultsPerType,
  MetadataSearchResultEntity,
  MetadataSearchEntityType,
  MetadataSearchTransitionPayload,
  MetadataSearchEvents,
  MetadataSearchError,
  MetadataSearchOnboardingHint,
  MetadataSearchEntityTypeConfig,
  MetadataSearchSupportedEntityTypes,
} from './MetadataSearchTypes';
import {
  metadataSearchEventEmitter,
  getSearchResultEntitiesPerTypePreprocessed,
  MetadataSearchTypeIconSourceMap,
} from './MetadataSearchUtils';
import {
  MetadataSearchFieldFilter,
  MetadataSearchFilterConfigPreprocessed,
  MetadataSearchFilterFieldType,
  MetadataSearchFilterConfigValuePreprocessed,
  MetadataSearchFilterCommonFieldName,
} from './MetadataSearchFilters/MetadataSearchFiltersTypes';
import {
  MetadataSearchFetchTriggerFilterList,
  preprocessFilterConfig,
  preprocessFilterConfigValues,
  preprocessFilter,
} from './MetadataSearchFilters/MetadataSearchFiltersUtils';
import { debounce, isEqual, isEmpty, omit } from 'lodash';
import { getApplicationPreference } from '../../services/appPreferencesService';

type UseMetadataSearchFilterSelectedField = Pick<MetadataSearchFieldFilter, 'fieldType' | 'field'> & {
  isSpecial: boolean;
};

export type UseMetadataSearchEntityTypesConfig = {
  types: MetadataSearchEntityTypeConfig[];
};

interface UseMetadataSearchState {
  query: MetadataSearchQuery;
  filters: MetadataSearchFieldFilter[];
  shouldFetchResults: boolean;
  filtersConfig: MetadataSearchFilterConfigPreprocessed[];
  searchResults: MetadataSearchResultsPerType[];
  recentObjects: MetadataSearchResultEntity[];
  isReady: boolean;
  isFiltersFetching: boolean;
  isFiltersFetchingLingers: boolean;
  isFiltersFetched: boolean;
  isFiltersFetchingRequired: boolean;
  isFiltersPrefetchingRequired: boolean;
  isResultsFetching: boolean;
  isResultsFetchingLingers: boolean;
  error: MetadataSearchError;
  isResultsFetched: boolean;
  isRecentObjectsFetching: boolean;
  isRecentObjectsFetched: boolean;
  isInputFocused: boolean;
  isPopperTriggered: boolean;
  isSummaryModeOn: boolean;
  isFiltersModeOn: boolean;
  isRecentObjectsModeOn: boolean;
  viewportHeight: number;
  selectedField?: UseMetadataSearchFilterSelectedField;
  supportedEntityTypesMap: MetadataSearchSupportedEntityTypes;
  entityTypeConfigs: UseMetadataSearchEntityTypesConfig;
}

enum UseMetadataSearchStateAction {
  INIT_STATE,
  INIT_CONFIG,
  UPDATE_QUERY,
  RESET_QUERY,
  RESET_FILTERS,
  DELETE_FILTER,
  UPDATE_FILTER,
  FETCH_RESULTS_INIT,
  FETCH_RESULTS_LINGERS,
  FETCH_RESULTS_DONE,
  FETCH_RESULTS_FAILED,
  FETCH_RESULTS_CANCELED,
  FETCH_RECENT_INIT,
  FETCH_RECENT_DONE,
  FETCH_RECENT_FAILED,
  FETCH_PREVIOUS_RESULTS_INIT,
  FETCH_PREVIOUS_RESULTS_DONE,
  FETCH_PREVIOUS_RESULTS_FAILED,
  FETCH_FILTERS_INIT,
  FETCH_FILTERS_LINGERS,
  FETCH_FILTERS_DONE,
  FETCH_FILTERS_FAILED,
  FETCH_INDEXING_STATUS_DONE,
  FETCH_INDEXING_STATUS_FAILED,
  TRIGGER_POPPER,
  TRIGGER_FILTERS_MODE,
  TRIGGER_INPUT_FOCUSED,
  HANDLE_VIEWPORT_RESIZE,
}

type UseMetadataSearchPayload = {
  action: UseMetadataSearchStateAction;
  data?: Partial<UseMetadataSearchState> & {
    hint?: MetadataSearchOnboardingHint;
    preRequestFiltersConfig?: MetadataSearchFilterConfigPreprocessed[];
  };
};

type UseMetadataSearchFetchFiltersConfigParams = {
  requestPayload: MetadataSearchFetchFiltersPayload;
  prerequestPayload?: MetadataSearchFetchFiltersPayload;
  selectedField?: UseMetadataSearchFilterSelectedField;
};

export type UseMetadataSearchInitialState = {
  query?: MetadataSearchQuery;
  filters?: MetadataSearchFieldFilter[];
};

export interface UseMetadataSearchProps {
  isResultsRouteActive?: boolean;
  shouldInitiateOnboarding?: boolean;
  initialState?: UseMetadataSearchInitialState;
  minQueryLength?: number;
  topTypeEntitiesToShow?: number;
  recentObjectMaxAmount?: number;
  indexingCheckInterval?: number;
  fetchResultsIdleTime?: number;
  fetchFiltersIdleTime?: number;
  fetchFilterOptionAmount?: number;
  onSubmit: (payload: MetadataSearchTransitionPayload) => void;
  onResultEntityClick: (
    entity: MetadataSearchResultEntity,
    payload: MetadataSearchTransitionPayload,
    entityTypeConfig: MetadataSearchEntityTypeConfig,
  ) => void;
  fetchRecentObjects: () => Promise<MetadataSearchResultEntity[]>;
  saveRecentObjects: (recentObjects: MetadataSearchResultEntity[]) => void;
  fetchResults: (payload: MetadataSearchFetchQuickResultsPayload) => Promise<MetadataSearchFetchQuickResultsResponse>;
  fetchFilters: (payload: MetadataSearchFetchFiltersPayload) => Promise<MetadataSearchFetchFiltersResponse>;
  fetchFilterSuggestions: (
    payload: MetadataSearchFetchFiltersPayload,
  ) => Promise<MetadataSearchFetchFilterSuggestionsResponse>;
  onQueryChange?: (query: MetadataSearchQuery) => void;
  onResetClick?: () => void;
  isQuickSearchDisabled?: boolean;
}

export type UseMetadataSearchStateResponse = Omit<
  UseMetadataSearchState,
  | 'isFiltersFetched'
  | 'isFiltersFetchingRequired'
  | 'isFiltersPrefetchingRequired'
  | 'isRecentObjectsFetched'
  | 'isResultsFetched'
  | 'shouldFetchResults'
  | 'shouldInitiateOnboarding'
> & {
  onSearchIconClick: () => void;
  onSearchInputFocus: () => void;
  onSearchInputBlur: () => void;
  onSearchInputChange: (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => void;
  onSearchInputKeyPress: (event: KeyboardEvent<HTMLInputElement>) => void;
  onResetIconClick: () => void;
  onFilterTriggerClick: () => void;
  onSearchResultEntityClick: (entity: MetadataSearchResultEntity, shouldPreserveRecentObject?: boolean) => void;
  onSearchResultEntityGroupClick: (type: MetadataSearchEntityType) => void;
  onSearchResultShowAllClick: () => void;
  onFilterChange: (filter: MetadataSearchFieldFilter) => void;
  onFilterSearch: (
    query: string,
    fieldConfig: MetadataSearchFilterConfigPreprocessed,
  ) => Promise<MetadataSearchFilterConfigValuePreprocessed[]>;
  onFiltersSubmit: () => void;
  onFiltersReset: () => void;
  onPopperClose: () => void;
};

const getMetadataSearchDefaultState = (): UseMetadataSearchState => {
  return {
    isReady: false,
    query: '',
    filters: [],
    shouldFetchResults: false,
    filtersConfig: [],
    searchResults: null,
    recentObjects: [],
    isFiltersFetching: false,
    isFiltersFetchingLingers: false,
    isFiltersFetched: false,
    isFiltersFetchingRequired: true,
    isFiltersPrefetchingRequired: false,
    isResultsFetching: false,
    isResultsFetchingLingers: false,
    isResultsFetched: false,
    isRecentObjectsFetching: false,
    isRecentObjectsFetched: false,
    isInputFocused: false,
    isPopperTriggered: false,
    isSummaryModeOn: false,
    isFiltersModeOn: false,
    isRecentObjectsModeOn: false,
    viewportHeight: window.innerHeight,
    error: null,
    supportedEntityTypesMap: {
      [MetadataSearchEntityType.TABLE]: true,
      [MetadataSearchEntityType.FILE]: true,
      [MetadataSearchEntityType.DATA_SOURCE]: true,
      [MetadataSearchEntityType.POLICY]: true,
      [MetadataSearchEntityType.OTHER_CATALOG]: true,
      [MetadataSearchEntityType.CLASSIFICATION_FINDINGS]: getApplicationPreference('USE_METADATA_SEARCH_FOR_DSAR'),
    },
    entityTypeConfigs: {
      types: [],
    },
  };
};

const reducer: Reducer<UseMetadataSearchState, UseMetadataSearchPayload> = (state, { action, data }) => {
  switch (action) {
    case UseMetadataSearchStateAction.INIT_CONFIG: {
      const { entityTypeConfigs } = data;

      return {
        ...state,
        entityTypeConfigs,
        isReady: true,
      };
    }
    case UseMetadataSearchStateAction.INIT_STATE: {
      const { query, filters } = data;

      return {
        ...state,
        query,
        filters,
        filtersConfig: [],
        isFiltersPrefetchingRequired: filters.length > 0,
        isFiltersFetchingRequired: true,
        shouldFetchResults: filters.length > 0 || query.length > 0,
      };
    }
    case UseMetadataSearchStateAction.UPDATE_QUERY: {
      const { query, isRecentObjectsModeOn, isSummaryModeOn, isPopperTriggered } = data;
      const { searchResults, error } = state;

      return {
        ...state,
        query,
        searchResults: !isSummaryModeOn ? null : !error ? searchResults : null,
        isRecentObjectsModeOn,
        isSummaryModeOn,
        isPopperTriggered,
        isResultsFetchingLingers: false,
        error: null,
        shouldFetchResults: true,
      };
    }
    case UseMetadataSearchStateAction.RESET_QUERY: {
      const { isSummaryModeOn, filters, searchResults } = state;

      return {
        ...state,
        query: '',
        error: null,
        searchResults: filters.length === 0 ? [] : searchResults,
        isSummaryModeOn: filters.length === 0 ? false : isSummaryModeOn,
        isRecentObjectsModeOn: filters.length === 0 ? isSummaryModeOn : false,
        isFiltersModeOn: filters.length === 0 ? !isSummaryModeOn : false,
      };
    }
    case UseMetadataSearchStateAction.UPDATE_FILTER: {
      const { filters } = data;

      return {
        ...state,
        filters,
        shouldFetchResults: true,
        isFiltersFetchingRequired: true,
      };
    }
    case UseMetadataSearchStateAction.DELETE_FILTER: {
      const { filters, isFiltersFetchingRequired } = data;
      const { filtersConfig } = state;

      const updatedState: UseMetadataSearchState = {
        ...state,
        filters,
        filtersConfig: filters.length === 0 ? [] : filtersConfig,
        isFiltersFetchingRequired,
        shouldFetchResults: true,
      };

      if (filters.length === 0) {
        updatedState.filtersConfig = [];
      }

      return updatedState;
    }
    case UseMetadataSearchStateAction.RESET_FILTERS: {
      return {
        ...state,
        filters: [],
        filtersConfig: [],
        isFiltersFetchingRequired: true,
        isFiltersPrefetchingRequired: false,
        selectedField: null,
        shouldFetchResults: true,
        error: null,
      };
    }
    case UseMetadataSearchStateAction.FETCH_FILTERS_INIT: {
      const { selectedField } = data;
      const { filtersConfig = [] } = state;
      const entityTypeFilterField = filtersConfig.find(
        ({ fieldType }) => fieldType === MetadataSearchFilterFieldType.ENTITY_TYPE,
      );

      return {
        ...state,
        error: null,
        selectedField,
        filtersConfig:
          selectedField?.fieldType === MetadataSearchFilterFieldType.ENTITY_TYPE && entityTypeFilterField
            ? [entityTypeFilterField]
            : filtersConfig,
        isFiltersFetching: true,
        isFiltersPretching: false,
        isFiltersFetched: false,
        isFiltersFetchingRequired: false,
        isFiltersFetchingLingers: false,
      };
    }
    case UseMetadataSearchStateAction.FETCH_FILTERS_LINGERS: {
      return {
        ...state,
        isFiltersFetchingLingers: true,
      };
    }
    case UseMetadataSearchStateAction.FETCH_FILTERS_DONE: {
      const { filtersConfig: newFiltersConfig, selectedField, preRequestFiltersConfig } = data;

      const prevFiltersConfig = preRequestFiltersConfig || state.filtersConfig;

      const entityTypeFieldPrev = prevFiltersConfig.find(
        ({ fieldType }) => fieldType === MetadataSearchFilterFieldType.ENTITY_TYPE,
      );

      const lastFilterField = prevFiltersConfig.find(({ fieldName, specialValueFieldName }) => {
        if (selectedField?.isSpecial) {
          return specialValueFieldName === selectedField?.field;
        } else {
          return fieldName === selectedField?.field;
        }
      });

      return {
        ...state,
        filtersConfig: newFiltersConfig.map(filter => {
          if (filter.fieldType === MetadataSearchFilterFieldType.ENTITY_TYPE) {
            return entityTypeFieldPrev || filter;
          } else {
            if (selectedField) {
              if (selectedField.field === null) {
                return filter;
              } else {
                if (selectedField.isSpecial) {
                  return filter.specialValueFieldName === lastFilterField?.specialValueFieldName
                    ? lastFilterField
                    : filter;
                } else {
                  return filter.fieldName === lastFilterField?.fieldName ? lastFilterField : filter;
                }
              }
            } else {
              return filter;
            }
          }
        }),
        isFiltersFetching: false,
        isFiltersFetched: true,
        isFiltersFetchingLingers: false,
        selectedField,
      };
    }
    case UseMetadataSearchStateAction.FETCH_FILTERS_FAILED: {
      const { error } = data;

      return {
        ...state,
        error,
        isFiltersFetching: false,
        isFiltersFetched: false,
        isFiltersFetchingRequired: true,
        isFiltersFetchingLingers: false,
      };
    }
    case UseMetadataSearchStateAction.FETCH_RECENT_INIT: {
      return {
        ...state,
        error: null,
        isRecentObjectsFetching: true,
        isRecentObjectsFetched: false,
      };
    }
    case UseMetadataSearchStateAction.FETCH_RECENT_DONE: {
      const { recentObjects } = data;
      return {
        ...state,
        recentObjects,
        isRecentObjectsFetching: false,
        isRecentObjectsFetched: true,
      };
    }
    case UseMetadataSearchStateAction.FETCH_RECENT_FAILED: {
      return {
        ...state,
        recentObjects: [],
        isRecentObjectsFetching: false,
        isRecentObjectsFetched: true,
      };
    }
    case UseMetadataSearchStateAction.FETCH_RESULTS_INIT: {
      return {
        ...state,
        error: null,
        searchResults: null,
        isResultsFetching: true,
        isResultsFetched: false,
        isResultsFetchingLingers: false,
      };
    }
    case UseMetadataSearchStateAction.FETCH_RESULTS_LINGERS: {
      return {
        ...state,
        isResultsFetchingLingers: true,
      };
    }
    case UseMetadataSearchStateAction.FETCH_RESULTS_DONE: {
      const { searchResults } = data;
      const { supportedEntityTypesMap, entityTypeConfigs } = state;

      return {
        ...state,
        searchResults: getSearchResultEntitiesPerTypePreprocessed(
          searchResults,
          entityTypeConfigs.types,
          supportedEntityTypesMap,
        ),
        isResultsFetching: false,
        isResultsFetchingLingers: false,
        isResultsFetched: true,
      };
    }
    case UseMetadataSearchStateAction.FETCH_RESULTS_FAILED: {
      const { error } = data;

      return {
        ...state,
        error,
        searchResults: [],
        isResultsFetching: false,
        isResultsFetchingLingers: false,
        isResultsFetched: true,
        isFiltersFetchingRequired: true,
      };
    }
    case UseMetadataSearchStateAction.TRIGGER_POPPER: {
      const { isPopperTriggered } = data;

      if (isPopperTriggered) {
        return { ...state, isPopperTriggered };
      } else {
        return {
          ...state,
          isPopperTriggered,
          isFiltersModeOn: false,
          isSummaryModeOn: false,
          isRecentObjectsModeOn: false,
          isInputFocused: false,
        };
      }
    }
    case UseMetadataSearchStateAction.TRIGGER_FILTERS_MODE: {
      const { isFiltersModeOn, isSummaryModeOn, isRecentObjectsModeOn } = state;
      const isFiltersModeOnUpdated = !isFiltersModeOn;
      return {
        ...state,
        isFiltersModeOn: isFiltersModeOnUpdated,
        isPopperTriggered: isSummaryModeOn || isRecentObjectsModeOn || isFiltersModeOnUpdated,
        isSummaryModeOn: false,
        isRecentObjectsModeOn: false,
        isInputFocused: true,
      };
    }
    case UseMetadataSearchStateAction.TRIGGER_INPUT_FOCUSED: {
      const { isInputFocused, isSummaryModeOn, isRecentObjectsModeOn } = data;

      if (isInputFocused) {
        return {
          ...state,
          isInputFocused,
          isFiltersModeOn: false,
          isPopperTriggered: true,
          isSummaryModeOn,
          isRecentObjectsModeOn,
        };
      } else {
        return {
          ...state,
          isInputFocused,
        };
      }
    }
    case UseMetadataSearchStateAction.HANDLE_VIEWPORT_RESIZE: {
      return {
        ...state,
        viewportHeight: window.innerHeight,
      };
    }
    default:
      return state;
  }
};

export const useMetadataSearchState = ({
  initialState,
  minQueryLength,
  recentObjectMaxAmount,
  topTypeEntitiesToShow,
  fetchFilters,
  fetchFilterSuggestions,
  fetchResults,
  fetchRecentObjects,
  saveRecentObjects,
  onSubmit,
  onResultEntityClick,
  fetchResultsIdleTime,
  fetchFiltersIdleTime,
  fetchFilterOptionAmount,
  isResultsRouteActive,
  onQueryChange,
  onResetClick,
  isQuickSearchDisabled,
}: UseMetadataSearchProps): UseMetadataSearchStateResponse => {
  const [
    {
      isReady,
      query,
      filters,
      shouldFetchResults,
      filtersConfig,
      searchResults,
      recentObjects,
      isFiltersFetching,
      isFiltersFetchingLingers,
      isFiltersFetchingRequired,
      isFiltersPrefetchingRequired,
      isResultsFetching,
      isResultsFetchingLingers,
      isRecentObjectsFetching,
      isRecentObjectsFetched,
      isInputFocused,
      isPopperTriggered,
      isSummaryModeOn,
      isFiltersModeOn,
      isRecentObjectsModeOn,
      viewportHeight,
      error,
      selectedField,
      supportedEntityTypesMap,
      entityTypeConfigs,
    },
    dispatch,
  ] = useReducer(reducer, {}, getMetadataSearchDefaultState);

  const loaderTimeoutId = useRef<ReturnType<typeof setTimeout>>(null);
  const prevInitialState = usePrevious(initialState);
  const fetchResultsCancellable = useCancelablePromise<MetadataSearchFetchQuickResultsResponse>();
  const fetchFiltersCancellable = useCancelablePromise<MetadataSearchFetchFiltersResponse>();
  const fetchSuggestionsCancellable = useCancelablePromise<MetadataSearchFetchFilterSuggestionsResponse>();

  const fetchConfig = useCallback(async () => {
    {
      try {
        const types = await getEntityTypeConfigs();

        dispatch({
          action: UseMetadataSearchStateAction.INIT_CONFIG,
          data: {
            entityTypeConfigs: {
              types: types.filter(({ appId }) => appId),
            },
          },
        });
      } catch {
        dispatch({
          action: UseMetadataSearchStateAction.INIT_CONFIG,
          data: {
            entityTypeConfigs: {
              types: [],
            },
          },
        });
      }
    }
  }, []);

  const fetchRecentlyUsedObjects = useCallback(async () => {
    {
      try {
        dispatch({
          action: UseMetadataSearchStateAction.FETCH_RECENT_INIT,
        });

        const results = await fetchRecentObjects();

        dispatch({
          action: UseMetadataSearchStateAction.FETCH_RECENT_DONE,
          data: {
            recentObjects: results,
          },
        });
      } catch {
        dispatch({
          action: UseMetadataSearchStateAction.FETCH_RECENT_FAILED,
        });
      }
    }
  }, [fetchRecentObjects]);

  const fetchFiltersConfig = useCallback(
    async ({ requestPayload, prerequestPayload, selectedField }: UseMetadataSearchFetchFiltersConfigParams) => {
      {
        try {
          if (!isFiltersFetching) {
            loaderTimeoutId.current = setTimeout(() => {
              dispatch({
                action: UseMetadataSearchStateAction.FETCH_FILTERS_LINGERS,
              });
            }, fetchFiltersIdleTime);

            dispatch({
              action: UseMetadataSearchStateAction.FETCH_FILTERS_INIT,
              data: {
                selectedField,
              },
            });

            let preRequestFiltersConfig: MetadataSearchFilterConfigPreprocessed[];

            if (prerequestPayload) {
              const { filter } = prerequestPayload;

              const { filterFields } = await fetchFiltersCancellable(
                fetchFilters({
                  ...prerequestPayload,
                  selectedField: selectedField?.field ?? null,
                  filter: preprocessFilter(filter, true),
                }),
              );

              preRequestFiltersConfig = filterFields.map(filter => {
                return preprocessFilterConfig(
                  filter,
                  entityTypeConfigs,
                  supportedEntityTypesMap,
                  MetadataSearchTypeIconSourceMap,
                );
              });
            }

            const { filter, ...rest } = omit(requestPayload, ['selectedFieldType']);
            const { filterFields } = await fetchFilters({
              ...rest,
              selectedField: selectedField?.field ?? null,
              filter: preprocessFilter(filter, true),
            });

            dispatch({
              action: UseMetadataSearchStateAction.FETCH_FILTERS_DONE,
              data: {
                selectedField,
                filtersConfig: filterFields.map(filter => {
                  return preprocessFilterConfig(
                    filter,
                    entityTypeConfigs,
                    supportedEntityTypesMap,
                    MetadataSearchTypeIconSourceMap,
                  );
                }),
                preRequestFiltersConfig,
              },
            });
          }
        } catch (error) {
          console.error(`An error has occurred: ${error.message}`);

          if (error.response?.status === 408) {
            const error: MetadataSearchError = {
              title: 'Your search timed out',
              description: 'Reset your filters or shorten your search term and try again.',
            };
            dispatch({
              action: UseMetadataSearchStateAction.FETCH_FILTERS_FAILED,
              data: {
                error,
              },
            });
          } else {
            dispatch({
              action: UseMetadataSearchStateAction.FETCH_FILTERS_FAILED,
              data: {
                error: {},
              },
            });
          }
        } finally {
          clearTimeout(loaderTimeoutId.current);
          loaderTimeoutId.current = null;
        }
      }
    },
    [
      entityTypeConfigs,
      fetchFilters,
      fetchFiltersCancellable,
      fetchFiltersIdleTime,
      isFiltersFetching,
      supportedEntityTypesMap,
    ],
  );

  const fetchFilterFieldOptions = useCallback(
    async (payload: MetadataSearchFetchFiltersPayload) => {
      try {
        const { filter, selectedFieldType, ...rest } = payload;
        const { suggestions } = await fetchSuggestionsCancellable(
          fetchFilterSuggestions({
            ...rest,
            filter: preprocessFilter(filter, true),
          }),
        );

        return preprocessFilterConfigValues(
          selectedFieldType,
          suggestions,
          entityTypeConfigs,
          supportedEntityTypesMap,
          MetadataSearchTypeIconSourceMap,
        );
      } catch {
        return [];
      } finally {
        clearTimeout(loaderTimeoutId.current);
        loaderTimeoutId.current = null;
      }
    },
    [entityTypeConfigs, fetchFilterSuggestions, fetchSuggestionsCancellable, supportedEntityTypesMap],
  );

  const updateRecentlyUsedObjects = useCallback(
    async (entity: MetadataSearchResultEntity) => {
      const newRecentObjects = [
        entity,
        ...recentObjects.filter(({ id }) => {
          return id !== entity.id;
        }),
      ];

      if (newRecentObjects.length > recentObjectMaxAmount) {
        newRecentObjects.pop();
      }

      await saveRecentObjects(newRecentObjects);

      dispatch({
        action: UseMetadataSearchStateAction.FETCH_RECENT_DONE,
        data: {
          recentObjects: newRecentObjects,
        },
      });
    },
    [recentObjectMaxAmount, recentObjects, saveRecentObjects],
  );

  const deleteFilter = useCallback(
    (filter: MetadataSearchFieldFilter) => {
      const filterUpdated = filters.filter(({ field }) => {
        return filter.field !== field;
      });

      dispatch({
        action: UseMetadataSearchStateAction.DELETE_FILTER,
        data: {
          filters: filterUpdated,
          isFiltersFetchingRequired:
            MetadataSearchFetchTriggerFilterList.includes(filter.fieldType) || filterUpdated.length === 0,
        },
      });
    },
    [filters],
  );

  const updateFilters = useCallback(
    (filter: MetadataSearchFieldFilter, suspendFetch?: boolean) => {
      const { field: fieldName, fieldType, specialField, value } = filter;
      const isSpecial = Boolean(specialField);
      const isEmptyValue = isEmpty(value?.toString());
      const selectedField: UseMetadataSearchFilterSelectedField = !isEmptyValue
        ? {
            isSpecial,
            field: isSpecial ? specialField : fieldName,
            fieldType,
          }
        : null;
      let filterUpdated: MetadataSearchFieldFilter[] = [];

      if (fieldType === MetadataSearchFilterFieldType.ENTITY_TYPE) {
        if (value) {
          filterUpdated = [filter];
        } else {
          filterUpdated = [];
        }
      } else {
        filterUpdated = isEmptyValue
          ? filters.filter(({ field }) => fieldName !== field)
          : [...filters.filter(({ field }) => fieldName !== field), filter];
      }

      dispatch({
        action: UseMetadataSearchStateAction.UPDATE_FILTER,
        data: {
          filters: filterUpdated,
        },
      });

      const shouldTriggerFetchFilters = MetadataSearchFetchTriggerFilterList.includes(fieldType);

      if (shouldTriggerFetchFilters && !suspendFetch) {
        const requestPayload: MetadataSearchFetchFiltersPayload = {
          top: fetchFilterOptionAmount,
          text: '',
          filter: filterUpdated,
          selectedFieldType: fieldType,
        };

        fetchFiltersConfig({
          requestPayload,
          selectedField,
        });
      }
    },
    [fetchFilterOptionAmount, fetchFiltersConfig, filters],
  );

  const fetchSearchResults = useCallback(
    async (payload: MetadataSearchFetchQuickResultsPayload) => {
      try {
        if (!isResultsFetching) {
          dispatch({
            action: UseMetadataSearchStateAction.FETCH_RESULTS_INIT,
          });
          loaderTimeoutId.current = setTimeout(() => {
            dispatch({
              action: UseMetadataSearchStateAction.FETCH_RESULTS_LINGERS,
            });
          }, fetchResultsIdleTime);
        }

        const { typeResults = [] } = await fetchResultsCancellable(
          fetchResults({
            ...payload,
            filter: preprocessFilter(payload.filter, true),
          }),
        );

        dispatch({
          action: UseMetadataSearchStateAction.FETCH_RESULTS_DONE,
          data: {
            searchResults: typeResults,
          },
        });
      } catch (error) {
        if (!error.isCanceled) {
          if (error.response?.status === 408) {
            const error: MetadataSearchError = {
              title: 'Your search timed out',
              description: 'Reset your filters or shorten your search term and try again.',
            };
            dispatch({
              action: UseMetadataSearchStateAction.FETCH_RESULTS_FAILED,
              data: {
                error,
              },
            });
          } else {
            dispatch({
              action: UseMetadataSearchStateAction.FETCH_RESULTS_FAILED,
              data: {
                error: {},
              },
            });
          }
        }
      } finally {
        clearTimeout(loaderTimeoutId.current);
        loaderTimeoutId.current = null;
      }
    },
    [fetchResults, fetchResultsCancellable, isResultsFetching, fetchResultsIdleTime],
  );

  const fetchSearchResultsDebounced = useMemo(() => {
    return debounce(fetchSearchResults, 300);
  }, [fetchSearchResults]);

  const isSubmitPermitted = useMemo(
    () => query?.trim().length > 0 || filters.length > 0 || isQuickSearchDisabled,
    [query, filters.length, isQuickSearchDisabled],
  );

  useEffect(() => {
    fetchConfig();

    const handleResize = debounce((): void => {
      dispatch({
        action: UseMetadataSearchStateAction.HANDLE_VIEWPORT_RESIZE,
      });
    }, 200);

    window.addEventListener('resize', handleResize);

    return () => {
      handleResize.cancel();
      window.removeEventListener('resize', handleResize);
      clearTimeout(loaderTimeoutId.current);
    };
  }, [fetchConfig]);

  useEffect(() => {
    if (initialState && !isEqual(prevInitialState, initialState)) {
      dispatch({
        action: UseMetadataSearchStateAction.INIT_STATE,
        data: {
          query: initialState.query || '',
          filters: initialState.filters || [],
        },
      });
    }
  }, [initialState, prevInitialState]);

  useEffect(() => {
    if (onQueryChange) {
      onQueryChange(query);
    }
  }, [onQueryChange, query]);

  useEffect(() => {
    const handleMetadataFullSearchResultEntityClick = (entity: MetadataSearchResultEntity) => {
      updateRecentlyUsedObjects(entity);
    };

    const handleMetadataFullSearchResultFilterUpdate = (filter: MetadataSearchFieldFilter) => {
      updateFilters(filter, true);
    };

    const handleMetadataFullSearchResultFilterDelete = (filter: MetadataSearchFieldFilter) => {
      deleteFilter(filter);
    };

    const handleMetadataFullSearchResultFilterDeleteAll = () => {
      dispatch({
        action: UseMetadataSearchStateAction.RESET_FILTERS,
      });
    };

    const unregisterFullSearchResultEntityClick = metadataSearchEventEmitter.addEventListener(
      MetadataSearchEvents.SEARCH_RESULT_ENTITY_CLICK,
      handleMetadataFullSearchResultEntityClick,
    );

    const unregisterFullSearchResultFilterUpdate = metadataSearchEventEmitter.addEventListener(
      MetadataSearchEvents.FIELD_FILTER_UPDATE,
      handleMetadataFullSearchResultFilterUpdate,
    );

    const unregisterFullSearchResultFilterDelete = metadataSearchEventEmitter.addEventListener(
      MetadataSearchEvents.FIELD_FILTER_DELETE,
      handleMetadataFullSearchResultFilterDelete,
    );

    const unregisterFullSearchResultFilterDeleteAll = metadataSearchEventEmitter.addEventListener(
      MetadataSearchEvents.FIELD_FILTER_DELETE_ALL,
      handleMetadataFullSearchResultFilterDeleteAll,
    );

    return () => {
      unregisterFullSearchResultEntityClick();
      unregisterFullSearchResultFilterUpdate();
      unregisterFullSearchResultFilterDelete();
      unregisterFullSearchResultFilterDeleteAll();
    };
  }, [deleteFilter, updateFilters, updateRecentlyUsedObjects]);

  const onSearchIconClick = useCallback((): void => {
    if (!isSubmitPermitted) return;
    const payload: MetadataSearchTransitionPayload = {
      text: query.trim(),
      filter: filters,
    };

    onSubmit(payload);
    dispatch({
      action: UseMetadataSearchStateAction.TRIGGER_POPPER,
      data: {
        isPopperTriggered: false,
      },
    });
  }, [isSubmitPermitted, query, filters, onSubmit]);

  const onSearchInputFocus = useCallback((): void => {
    const trimmedQuery = query.trim();
    const isSummaryModeOnUpdated = trimmedQuery.length >= minQueryLength || filters.length > 0;
    const isRecentObjectsModeOnUpdated = trimmedQuery.length < minQueryLength && filters.length === 0;

    if (isSummaryModeOnUpdated && !isSummaryModeOn && shouldFetchResults) {
      const payload: MetadataSearchFetchQuickResultsPayload = {
        text: trimmedQuery,
        top: topTypeEntitiesToShow,
        filter: filters,
      };

      fetchSearchResults(payload);
    } else if (isRecentObjectsModeOnUpdated && !isRecentObjectsFetching && !isRecentObjectsFetched) {
      fetchRecentlyUsedObjects();
    }

    dispatch({
      action: UseMetadataSearchStateAction.TRIGGER_INPUT_FOCUSED,
      data: {
        isInputFocused: true,
        isSummaryModeOn: isSummaryModeOnUpdated,
        isRecentObjectsModeOn: isRecentObjectsModeOnUpdated,
      },
    });
  }, [
    query,
    minQueryLength,
    isSummaryModeOn,
    shouldFetchResults,
    isRecentObjectsFetching,
    isRecentObjectsFetched,
    topTypeEntitiesToShow,
    filters,
    fetchSearchResults,
    fetchRecentlyUsedObjects,
  ]);

  const onSearchInputBlur = useCallback((): void => {
    if (!isPopperTriggered) {
      dispatch({
        action: UseMetadataSearchStateAction.TRIGGER_INPUT_FOCUSED,
        data: {
          isInputFocused: false,
        },
      });
    }
  }, [isPopperTriggered]);

  const onSearchInputChange = useCallback(
    async ({ target }: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
      const value = target.value;
      const valueTrimmed = value.trim();
      const isSummaryModeTriggered = valueTrimmed.length >= minQueryLength || filters.length > 0;
      const isRecentObjectModeTriggered = !isSummaryModeTriggered;

      const payload: UseMetadataSearchPayload = {
        action: UseMetadataSearchStateAction.UPDATE_QUERY,
        data: {
          query: value,
          isRecentObjectsModeOn: isRecentObjectModeTriggered,
          isSummaryModeOn: isSummaryModeTriggered,
          isPopperTriggered: isRecentObjectModeTriggered || isSummaryModeTriggered,
        },
      };

      dispatch(payload);

      if (isSummaryModeTriggered && !isEqual(valueTrimmed, query.trim())) {
        const payload: MetadataSearchFetchQuickResultsPayload = {
          text: valueTrimmed,
          top: topTypeEntitiesToShow,
          filter: filters,
        };

        window.clearTimeout(loaderTimeoutId.current);
        loaderTimeoutId.current = null;

        if (!isQuickSearchDisabled) {
          fetchSearchResultsDebounced(payload);
        }
      }
    },
    [minQueryLength, filters, query, topTypeEntitiesToShow, isQuickSearchDisabled, fetchSearchResultsDebounced],
  );

  const onSearchInputKeyPress = useCallback(
    ({ charCode, key }: KeyboardEvent<HTMLInputElement>): void => {
      if ((charCode === 13 || key === 'Enter') && isSubmitPermitted) {
        const payload: MetadataSearchTransitionPayload = {
          text: query.trim(),
          filter: filters,
        };

        onSubmit(payload);
        dispatch({
          action: UseMetadataSearchStateAction.TRIGGER_POPPER,
          data: {
            isPopperTriggered: false,
          },
        });
      }
    },
    [filters, isSubmitPermitted, onSubmit, query],
  );

  const onResetIconClick = useCallback((): void => {
    onResetClick?.();

    dispatch({
      action: UseMetadataSearchStateAction.RESET_QUERY,
    });

    if (filters.length === 0) {
      dispatch({
        action: UseMetadataSearchStateAction.TRIGGER_INPUT_FOCUSED,
        data: {
          isInputFocused: true,
          isSummaryModeOn: false,
          isFiltersModeOn: false,
          isRecentObjectsModeOn: true,
        },
      });

      fetchRecentlyUsedObjects();
    } else {
      const payload: MetadataSearchFetchQuickResultsPayload = {
        text: '',
        top: topTypeEntitiesToShow,
        filter: filters,
      };

      fetchSearchResults(payload);

      dispatch({
        action: UseMetadataSearchStateAction.TRIGGER_INPUT_FOCUSED,
        data: {
          isInputFocused: true,
          isSummaryModeOn: true,
          isFiltersModeOn: false,
          isRecentObjectsModeOn: false,
        },
      });
    }
  }, [fetchRecentlyUsedObjects, fetchSearchResults, filters, onResetClick, topTypeEntitiesToShow]);

  const onFilterTriggerClick = useCallback(() => {
    dispatch({
      action: UseMetadataSearchStateAction.TRIGGER_FILTERS_MODE,
    });

    if (isFiltersFetchingRequired) {
      const requestPayload: MetadataSearchFetchFiltersPayload = {
        top: fetchFilterOptionAmount,
        text: '',
        filter: filters,
      };

      if (isFiltersPrefetchingRequired) {
        const selectedField: UseMetadataSearchFilterSelectedField = {
          isSpecial: false,
          field: MetadataSearchFilterCommonFieldName.ENTITY_TYPE,
          fieldType: MetadataSearchFilterFieldType.ENTITY_TYPE,
        };
        const prerequestPayload: MetadataSearchFetchFiltersPayload = {
          top: fetchFilterOptionAmount,
          text: '',
          filter: [],
        };

        fetchFiltersConfig({
          requestPayload,
          prerequestPayload,
          selectedField,
        });
      } else {
        fetchFiltersConfig({
          requestPayload,
        });
      }
    }
  }, [fetchFilterOptionAmount, fetchFiltersConfig, filters, isFiltersPrefetchingRequired, isFiltersFetchingRequired]);

  const onSearchResultEntityClick = useCallback(
    async (entity: MetadataSearchResultEntity) => {
      updateRecentlyUsedObjects(entity);

      const payload: MetadataSearchTransitionPayload = {
        text: query.trim(),
        filter: filters,
      };

      const entityTypeConfig = entityTypeConfigs.types.find(({ entityTypeId }) => entityTypeId === entity.type);

      onResultEntityClick(entity, payload, entityTypeConfig);

      dispatch({
        action: UseMetadataSearchStateAction.TRIGGER_POPPER,
        data: {
          isPopperTriggered: false,
        },
      });
    },
    [entityTypeConfigs, filters, onResultEntityClick, query, updateRecentlyUsedObjects],
  );

  const onSearchResultEntityGroupClick = useCallback(
    (type: MetadataSearchEntityType) => {
      const filter: MetadataSearchFieldFilter = {
        field: MetadataSearchFilterCommonFieldName.ENTITY_TYPE,
        value: type,
        operator: 'equal',
        fieldType: MetadataSearchFilterFieldType.ENTITY_TYPE,
        fieldDisplayName: 'Entity Type',
      };

      const payload: MetadataSearchTransitionPayload = {
        text: query.trim(),
        filter: [filter],
      };

      onSubmit(payload);

      dispatch({
        action: UseMetadataSearchStateAction.TRIGGER_POPPER,
        data: {
          isPopperTriggered: false,
        },
      });

      dispatch({
        action: UseMetadataSearchStateAction.UPDATE_FILTER,
        data: {
          filters: [filter],
        },
      });
    },
    [onSubmit, query],
  );

  const onSearchResultShowAllClick = useCallback((): void => {
    const payload: MetadataSearchTransitionPayload = {
      text: query.trim(),
      filter: filters,
    };

    onSubmit(payload);
    dispatch({
      action: UseMetadataSearchStateAction.TRIGGER_POPPER,
      data: {
        isPopperTriggered: false,
      },
    });
  }, [filters, onSubmit, query]);

  const onFilterChange = (filter: MetadataSearchFieldFilter): void => {
    updateFilters(filter);
  };

  const onFilterSearch = useCallback(
    (fieldText: string, fieldConfig: MetadataSearchFilterConfigPreprocessed) => {
      const { fieldName, fieldType } = fieldConfig;
      const payload: MetadataSearchFetchFiltersPayload = {
        selectedField: fieldName,
        selectedFieldType: fieldType,
        selectedFieldValue: fieldText,
        top: fetchFilterOptionAmount,
        text: '',
        filter: filters.filter(({ field }) => field !== fieldName),
      };

      return fetchFilterFieldOptions(payload);
    },
    [fetchFilterOptionAmount, filters, fetchFilterFieldOptions],
  );

  const onFiltersSubmit = useCallback((): void => {
    if (!isSubmitPermitted) return;
    const payload: MetadataSearchTransitionPayload = {
      text: query.trim(),
      filter: filters,
    };

    onSubmit(payload);
    dispatch({
      action: UseMetadataSearchStateAction.TRIGGER_POPPER,
      data: {
        isPopperTriggered: false,
      },
    });
  }, [filters, isSubmitPermitted, onSubmit, query]);

  const onFiltersReset = useCallback((): void => {
    dispatch({
      action: UseMetadataSearchStateAction.RESET_FILTERS,
    });

    if (error) {
      const requestPayload: MetadataSearchFetchFiltersPayload = {
        top: fetchFilterOptionAmount,
        text: '',
        filter: [],
      };

      fetchFiltersConfig({
        requestPayload,
      });
    } else {
      const trimmedQuery = query.trim();

      if (trimmedQuery.length >= minQueryLength) {
        const payload: MetadataSearchTransitionPayload = {
          text: trimmedQuery,
          filter: [],
        };

        onSubmit(payload);

        dispatch({
          action: UseMetadataSearchStateAction.TRIGGER_POPPER,
          data: {
            isPopperTriggered: false,
          },
        });
      } else {
        dispatch({
          action: UseMetadataSearchStateAction.TRIGGER_INPUT_FOCUSED,
          data: {
            isInputFocused: true,
            isSummaryModeOn: false,
            isFiltersModeOn: false,
            isRecentObjectsModeOn: true,
          },
        });

        fetchRecentlyUsedObjects();
      }

      if (isResultsRouteActive) {
        metadataSearchEventEmitter.emit(MetadataSearchEvents.FIELD_FILTER_DELETE_ALL);
      }
    }
  }, [
    error,
    fetchFilterOptionAmount,
    fetchFiltersConfig,
    query,
    minQueryLength,
    isResultsRouteActive,
    onSubmit,
    fetchRecentlyUsedObjects,
  ]);

  const onPopperClose = (): void => {
    dispatch({
      action: UseMetadataSearchStateAction.TRIGGER_POPPER,
      data: {
        isPopperTriggered: false,
      },
    });
  };

  return {
    isReady,
    query,
    filters,
    filtersConfig,
    searchResults,
    recentObjects,
    isFiltersFetching,
    isFiltersFetchingLingers,
    isResultsFetching,
    isResultsFetchingLingers,
    isRecentObjectsFetching,
    isInputFocused,
    isPopperTriggered,
    isSummaryModeOn,
    isFiltersModeOn,
    isRecentObjectsModeOn,
    onSearchIconClick,
    onSearchInputFocus,
    onSearchInputBlur,
    onSearchInputChange,
    onSearchInputKeyPress,
    onResetIconClick,
    onFilterTriggerClick,
    onSearchResultEntityClick,
    onSearchResultEntityGroupClick,
    onSearchResultShowAllClick,
    onFilterSearch,
    onFilterChange,
    onFiltersSubmit,
    onFiltersReset,
    onPopperClose,
    viewportHeight,
    error,
    selectedField,
    supportedEntityTypesMap,
    entityTypeConfigs,
  };
};
