import React, { FC } from 'react';
import { Typography } from '@mui/material';
import { SystemDialogContentProps } from '../../services/systemDialogService';
import { ConfirmationDialogOptions } from '../../services/confirmationDialogService';

export type ConfirmationDialogContentProps = { entityName: string; allSelected?: boolean } & Pick<
  ConfirmationDialogOptions,
  'actionName' | 'entitiesCount' | 'postfix' | 'customDescription' | 'ContentComponent'
>;

export const ConfirmationDialogContent: FC<SystemDialogContentProps<ConfirmationDialogContentProps>> = ({
  actionName,
  entitiesCount,
  entityName,
  allSelected = false,
  postfix,
  customDescription,
  ContentComponent = null,
}) => {
  return ContentComponent ? (
    ContentComponent
  ) : (
    <Typography variant={'body1'}>
      {customDescription ||
        `Are you sure you want to ${actionName.toLowerCase()} ${
          allSelected ? 'all' : entitiesCount || 'this'
        } ${entityName.toLowerCase()}${postfix || '?'}`}
    </Typography>
  );
};
