import { BigidSystemDialogOptions, openSystemDialog } from './systemDialogService';
import { PrimaryButton } from '@bigid-ui/components';
import { RouteLeavingContent, CancelButton } from '../components/RouteLeaving/RouteLeavingContent';

export enum RouteLeavingResult {
  leave = 'leave',
  cancel = 'cancel',
}

export interface RouteLeavingOptions {
  onClose: BigidSystemDialogOptions['onClose'];
  header: string;
  body: string;
}

export const showRouteLeaving = ({ onClose, header, body }: RouteLeavingOptions): Promise<RouteLeavingResult> => {
  return new Promise(resolve => {
    const options: BigidSystemDialogOptions = {
      title: header,
      onClose,
      maxWidth: 'xs',
      buttons: [
        {
          text: `Cancel`,
          component: CancelButton,
          isClose: true,
          onClick: () => {
            resolve(RouteLeavingResult.cancel);
          },
          size: 'large',
        },
        {
          text: 'Leave',
          component: PrimaryButton,
          onClick: () => {
            resolve(RouteLeavingResult.leave);
          },
          isClose: true,
          size: 'large',
        },
      ],
      content: RouteLeavingContent,
      contentProps: { body },
      borderTop: true,
    };
    openSystemDialog(options);
  });
};
