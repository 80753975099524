import './taskList.component.scss';
import template from './taskList.component.html';
import { module } from 'angular';
const app = module('app');
import { HeaderEvents, headerEventEmitter } from '../react/services/eventEmitters/headerEvents';
import '../react/views/TaskList/TasksHeader';
import { dateTimeService } from '@bigid-ui/i18n';

app.component('taskList', {
  template,
  controllerAs: 'taskListModel',
  controller: function (
    $rootScope,
    $scope,
    taskListService,
    notificationService,
    $translate,
    $uibModal,
    DeleteConfirmation,
    $stateParams,
    $document,
    $q,
    locationService,
    dataSourceConnectionsService,
    applicationSetupService,
    dataMappingService,
    $timeout,
  ) {
    'ngInject';
    const taskListModel = this;

    const NONE = 'None';
    const ASSIGNED_TO_ME = 'Assigned to me';
    const INITIATED_BY_ME = 'Initiated by me';
    const ALL = 'All';
    const OPEN = 'Open';
    const RESOLVED = 'Resolved';
    const INITIATOR = 'initiator';
    const OWNER = 'owner';
    const ADMIN = 'admin';

    taskListModel.tab = 'settings';
    taskListModel.mentionedUsers = [];
    taskListModel.tasks = [];
    taskListModel.additionalSourceNames = [];
    taskListModel.comment = '';
    taskListModel.altPressed = false;
    taskListModel.securityTierValues = [1, 2, 3, 4, 5].map(tier => ({ id: tier, name: tier }));

    taskListModel.dataMappingClassifications = [
      'Collection',
      'Transformation',
      'Retention',
      'Transfer',
      'Disposal',
    ].map((classification, idx) => ({
      classifierId: idx + 1,
      name: classification,
    }));
    taskListModel.isAssigneeMentionsToggled = false;

    let updateTimer = null;

    taskListModel.$onInit = () => {
      taskListModel.loadingTaskList = true;
      headerEventEmitter.emit(HeaderEvents.UPDATE_UNREAD_TASKS_AND_RISK);

      taskListModel.filterBy = ASSIGNED_TO_ME;
      taskListModel.status = OPEN;
      getAllTask(OPEN.toLowerCase(), OWNER).then(tasks => {
        if ($stateParams.taskId) {
          preselectTask(true);
        } else if (tasks) {
          const firstTask = tasks.sort(sortDates)[0];
          selectTask(firstTask);
        }
      });
    };

    const getAllTask = (status, filterBy) => {
      return $q((resolve, reject) => {
        taskListService.getAllTask(status, filterBy).then(
          result => {
            taskListModel.tasks = result.data.sort((a, b) => (a.isRead === b.isRead ? 0 : a.isRead ? 1 : -1));
            taskListModel.showTaskEntity = false;
            resolve(taskListModel.tasks);
          },
          () => {
            notificationService.error('An error has occurred!');
          },
        );
      });
    };

    const updateTaskRead = (data, id) => {
      taskListService.updateTask(data, id).catch(() => {
        notificationService.error('An error has occurred!');
      });
    };

    taskListModel.updateFiltersValue = (filterSelected, statusSelected) => {
      taskListModel.loadingTaskList = true;
      const filterBy = getFilterByValue(filterSelected);
      const status = getStatusValue(statusSelected);
      taskListModel.filterBy = filterSelected;
      taskListModel.status = statusSelected;
      getAllTaskByFilters(status, filterBy);
    };

    const getFilterByValue = filterBy => {
      switch (filterBy) {
        case ASSIGNED_TO_ME:
          return OWNER;
        case INITIATED_BY_ME:
          return INITIATOR;
        case ALL:
          return ADMIN;
        default:
          return null;
      }
    };

    const getStatusValue = status => {
      switch (status) {
        case OPEN:
          return OPEN.toLowerCase();
        case RESOLVED:
          return RESOLVED.toLowerCase();
        default:
          return null;
      }
    };

    const getAllTaskByFilters = (status, filterBy) => {
      getAllTask(status, filterBy).then(result => {
        selectTask(result.sort(sortDates)[0]).then(() => {
          $timeout(() => {
            $document[0].getElementById('taskListSection').scrollTop = 0;
          });
        });
      });
    };

    const getfilterValuesAndGetAllTaskByFilter = () => {
      const filterBy = getFilterByValue(taskListModel.filterBy);
      const status = getStatusValue(taskListModel.status);
      getAllTaskByFilters(status, filterBy);
    };

    const resetSelection = () => {
      for (const i in taskListModel.tasks) {
        taskListModel.tasks[i]['isSelected'] = false;
      }
    };

    const selectTask = task => {
      return $q((resolve, reject) => {
        taskListModel.loadingTaskList = false;
        resetSelection();
        taskListService.getTaskByID(task._id).then(
          result => {
            setFieldForReturnedTask(result, task);

            if (!task.isRead) {
              updateTimer = $timeout(() => {
                task.isRead = true;
                updateTaskRead({ isRead: true }, taskListModel.currentTask._id);
                headerEventEmitter.emit(HeaderEvents.UPDATE_UNREAD_TASKS);
              }, 2000);
            }

            taskListModel.loadingTaskList = false;
            resolve(taskListModel.currentTask);

            if (taskListModel.tasks && taskListModel.tasks.length < 1) {
              setFieldsForEmptyTask(task);
              taskListModel.tasks.push(taskListModel);
            }

            //case of complianceRule type
            if (
              taskListModel.currentTask &&
              taskListModel.currentTask.type === 'complianceRule' &&
              taskListModel.currentTask.details &&
              taskListModel.currentTask.details.ruleId
            ) {
              taskListModel.currentRuleData = {};
              getRuleDataFromServer(taskListModel.currentTask.details.ruleId);
            }

            //show the fields that related to the task fields
          },
          () => {
            notificationService.error('An error has occurred!');
          },
        );
      });
    };

    const getRuleDataFromServer = ruleId => {
      taskListService.getRuleData(ruleId).then(result => {
        taskListModel.currentRuleData = result.data;
        if (!taskListModel.currentRuleData.action) {
          taskListModel.currentRuleData.action = {};
          if (taskListModel.currentTask.fields) {
            const type = taskListModel.currentTask.fields.find(field => field.fieldName === 'ruleAction');
            if (type && type.fieldValue) {
              taskListModel.currentRuleData.type =
                type.fieldValue.charAt(0).toUpperCase() + type.fieldValue.slice(1).toLowerCase();
            }
          } else {
            taskListModel.currentRuleData.type = NONE;
          }
          if (taskListModel.currentTask.fields) {
            const postponeToDate = taskListModel.currentTask.fields.find(field => field.fieldName === 'postponeToDate');
            if (postponeToDate && postponeToDate.fieldValue) {
              taskListModel.currentRuleData.ruleActionDate = postponeToDate.fieldValue;
            }
          } else {
            taskListModel.currentRuleData.ruleActionDate = '';
          }
        }
        const resolutionDescription = taskListModel.currentTask.fields.find(
          field => field.fieldName === 'resolutionDescription',
        );
        if (resolutionDescription && resolutionDescription.fieldValue) {
          taskListModel.currentRuleData.resolutionDescription = resolutionDescription.fieldValue;
        }
      });
    };

    const setFieldForReturnedTask = (returnedTask, task) => {
      taskListModel.additionalSourceNames = [];
      taskListModel.mentionedUsers = [];
      taskListModel.showTaskEntity = true;
      taskListModel.comment = '';
      taskListModel.currentTask = returnedTask;
      taskListModel.currentTask.comments = taskListModel.currentTask.comments.sort(sortDates).reverse();
      task.isSelected = true;
      taskListModel.currentTask.isSelected = true;

      //prepareCommentsSection();
    };

    const setFieldsForEmptyTask = task => {
      taskListModel.initiator = taskListModel.currentTask.initiator;
      taskListModel.subject = taskListModel.currentTask.subject;
      taskListModel.owner = taskListModel.currentTask.owner;
      taskListModel.created_at = taskListModel.currentTask.created_at;
      taskListModel.isSelected = true;
      taskListModel.isRead = true;
      taskListModel._id = task._id;
    };

    const preselectTask = usingStateParam => {
      let found;
      for (const i in taskListModel.tasks) {
        if (taskListModel.tasks[i]['_id'] == $stateParams.taskId) {
          found = taskListModel.tasks[i];
          break;
        }
      }

      if (found) {
        selectTask(found).then(() => document.getElementById($stateParams.taskId)?.scrollIntoView());
        return;
      } else {
        if (usingStateParam) {
          //case of resolve task
          selectTask({ _id: $stateParams.taskId });
        }
      }
    };

    taskListModel.contextMenuOptions = [
      [
        'Mark as unread',
        $itemScope => {
          if ($itemScope.task.isRead) {
            updateTaskRead({ isRead: false }, $itemScope.task._id);
            $itemScope.task.isRead = false;
            headerEventEmitter.emit(HeaderEvents.UPDATE_UNREAD_TASKS_AND_RISK);
          }
        },
        $itemScope => $itemScope.task.isRead,
      ],
    ];

    taskListModel.selectTaskEntity = task => {
      $timeout.cancel(updateTimer);
      selectTask(task);
    };

    taskListModel.showCommentSection = true;

    taskListModel.reassignModalInstance = {};

    taskListModel.reassignTask = user => {
      const selectedTasksIds = taskListModel.tasks.filter(({ checked }) => checked).map(({ _id }) => _id);
      if (selectedTasksIds.length === 0) {
        taskListService.updateTask({ owner: user }, taskListModel.currentTask._id).then(
          result => {
            notificationService.success('Task has successfully been reassigned!');
            getfilterValuesAndGetAllTaskByFilter();
          },
          () => {
            notificationService.error('An error has occurred!');
          },
        );
      } else {
        taskListService.updateTasksBulk({ assignee: user, action: 'reassign', tasksIds: selectedTasksIds }).then(
          () => {
            notificationService.success('Task has successfully been reassigned!');
            getfilterValuesAndGetAllTaskByFilter();
          },
          () => {
            notificationService.error('An error has occurred!');
          },
        );
      }
    };

    taskListModel.resolveTask = () => {
      const selectedTasksIds = taskListModel.tasks.filter(({ checked }) => checked).map(({ _id }) => _id);
      if (selectedTasksIds.length === 0) {
        taskListService.updateTask({ status: RESOLVED.toLowerCase() }, taskListModel.currentTask._id).then(
          result => {
            notificationService.success('Task has successfully been resolved!');
            taskListModel.currentTask = [];
            getfilterValuesAndGetAllTaskByFilter();
            headerEventEmitter.emit(HeaderEvents.UPDATE_UNREAD_TASKS);
          },
          () => {
            notificationService.error('An error has occurred!');
          },
        );
      } else {
        taskListService.updateTasksBulk({ action: 'resolve', tasksIds: selectedTasksIds }).then(
          () => {
            notificationService.success('Task has successfully been resolved!');
            getfilterValuesAndGetAllTaskByFilter();
            headerEventEmitter.emit(HeaderEvents.UPDATE_UNREAD_TASKS);
          },
          () => {
            notificationService.error('An error has occurred!');
          },
        );
      }
    };

    taskListModel.onCheckToolClick = type => {
      const isCheckAll = type === 'all';
      taskListModel.tasks = taskListModel.tasks.map(task => ({ ...task, checked: isCheckAll }));
      $rootScope.$applyAsync();
    };

    taskListModel.onCheckboxChange = () => {
      // Needed for updating react component with single check
      taskListModel.tasks = [...taskListModel.tasks];
      $rootScope.$applyAsync();
    };

    const sortDates = (a, b) => new Date(b.created_at) - new Date(a.created_at);

    $translate('TASK_LIST').then(translation => {
      $rootScope.$broadcast('changePage', translation, false, true);
    });

    $scope.$watch('taskListModel.comment', newVal => {
      taskListModel.sendBtnDisabled = newVal ? false : true;
    });
  },
});

app.filter('entityDate', () => {
  return input => {
    const d = new Date(input);
    return dateTimeService.formatDate(d);
  };
});

app.filter('listDate', () => {
  return input => {
    const d = new Date(input);
    return dateTimeService.formatDate(d);
  };
});
