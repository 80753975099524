import { BigidAdvancedToolbarFilter, BigidLoader } from '@bigid-ui/components';
import { generateDataAid } from '@bigid-ui/utils';
import React, { FC, useMemo } from 'react';
import { WithDataAid } from '../../../../types';
import { useFilterConfig } from './useCatalogConfig';
import { useDataCatalogSearchResultsContext } from '../../../../contexts/dataCatalogSearchResultsContext';
import { styled } from '@mui/material';

const ItemWrapper = styled('div')<{ showContent: boolean }>`
  display: ${({ showContent }) => (showContent ? 'flex' : 'none')};
  align-items: center;
  flex-grow: 1;
`;

export const CatalogToolbar: FC<WithDataAid> = ({ dataAid = 'CatalogSearchToolbar' }) => {
  const { query, isGridDataLoading } = useDataCatalogSearchResultsContext();
  const { filterConfig } = useFilterConfig();

  const memoizedConfig = useMemo(() => {
    return filterConfig;
  }, []);

  return (
    <>
      <ItemWrapper showContent={isGridDataLoading}>
        <BigidLoader position="relative" size={20} />
      </ItemWrapper>
      <ItemWrapper showContent={!isGridDataLoading}>
        <BigidAdvancedToolbarFilter
          dataAid={generateDataAid(dataAid, ['toolbar'])}
          {...memoizedConfig}
          filterSearchMetadata={{
            searchText: query,
          }}
        />
      </ItemWrapper>
    </>
  );
};
