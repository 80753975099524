import { BigidAdvancedToolbarDateRangeFilter, BigidAdvancedToolbarFilterUnion } from '@bigid-ui/components';

export function getFromDateMidnight(date: Date): Date {
  return new Date(date.setHours(0, 0, 0, 0));
}

export function getUntilDateMidnight(date: Date): Date {
  return new Date(date.setHours(23, 59, 59, 0));
}

export function getFromDateQueryExpression(field: string, value: Date): string {
  return value ? `${field} >= "to_date(${getFromDateMidnight(new Date(value.toString())).toISOString()})"` : '';
}

export function getUntilDateQueryExpression(field: string, value: Date): string {
  return value ? `${field} <= "to_date(${getUntilDateMidnight(new Date(value.toString())).toISOString()})"` : '';
}

export function parseDateFilterOutputToQueryString(filter: BigidAdvancedToolbarFilterUnion[]): string {
  return (filter as BigidAdvancedToolbarDateRangeFilter[])
    .map(({ field, options }) => {
      const { from, until } = options.pickersState.dates;
      const fromQueryExpression = getFromDateQueryExpression(field, from);
      const untilQueryExpression = getUntilDateQueryExpression(field, until);

      return [fromQueryExpression, untilQueryExpression]
        .filter(expression => typeof expression === 'string' && expression.length > 0)
        .join(' AND ');
    })
    .join(' AND ');
}
