import React, { useCallback, useEffect, useState } from 'react';
import {
  BigidTextField,
  BigidInlineNotification,
  PrimaryButton,
  toastNotificationService,
  TertiaryButton,
  BigidHeading4,
} from '@bigid-ui/components';
import { styled } from '@mui/material';
import { cloneDeep, isEqual } from 'lodash';
import { RiskFormField } from './components/RiskFormField';
import { RiskControlChangeDialog } from './components/RiskControlChangeDialog';
import { RiskControlUnsavedChangesDialog } from './components/RiskControlUnsavedChangesDialog';
import { RiskControl } from './types';
import { httpService } from '../../services/httpService';
import { trackManualEvent, RisksAndControlsTrackingEvents } from './risksAnalytics';
import { isPermitted } from '../../services/userPermissionsService';
import { RISK_CONTROLS_PERMISSIONS } from '@bigid/permissions';

const { RISK_CONTROL_ADD_CONTROL, RISK_CONTROL_UPDATE_CONTROL } = RisksAndControlsTrackingEvents;

const RootContainer = styled('div')({
  width: '100%',
  justifyContent: 'space-between',
  alignItems: 'flex-start',
  display: 'flex',
  flexDirection: 'row',
  marginBottom: 20,
});
const FormContainer = styled('div')({
  justifyContent: 'space-between',
  display: 'flex',
  flexDirection: 'column',
  width: 600,
  gap: 16,
});
const ButtonsContainer = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  gap: 8,
});

export interface ControlComponentProps {
  id?: string;
  onClose?: () => void;
}

export const ControlComponent: React.FC<ControlComponentProps> = ({ id, onClose }) => {
  const emptyRiskControl: Partial<RiskControl> = { name: '', description: '', category: '' };
  const [riskControl, setRiskControl] = useState<RiskControl>(emptyRiskControl as RiskControl);
  const [oldRiskControl, setOldRiskControl] = useState<RiskControl>(emptyRiskControl as RiskControl);
  const [isRiskControlChangedDialogOpen, setIsRiskControlChangedDialogOpen] = useState(false);
  const [isRiskControlUnsavedChangesDialogOpen, setIsRiskControlUnsavedChangesDialogOpen] = useState(false);
  const [isValidate, setIsValidate] = useState(false);
  const [shouldKeepOpen, setShouldKeepOpen] = useState(false);

  const getRiskControlByIdAsync = async () => {
    if (id) {
      const res = (await httpService.fetch(`risk-controls/${id}`))?.data?.data as RiskControl; //await getRiskControlById(id);

      if (res) {
        setRiskControl(res);
        setOldRiskControl(res);
      } else {
        toastNotificationService.error('Error getting risk from server', {
          shouldCloseOnTransition: true,
        });
      }
    } else {
      setRiskControl(emptyRiskControl as RiskControl);
      setOldRiskControl(emptyRiskControl as RiskControl);
    }
  };

  useEffect(() => {
    if (riskControl && oldRiskControl && !isEqual(riskControl, oldRiskControl)) {
      setShouldKeepOpen(true);
      setIsRiskControlUnsavedChangesDialogOpen(true);
    } else {
      setShouldKeepOpen(false);
      getRiskControlByIdAsync();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const validateRisk = useCallback((): boolean => {
    return !riskControl?.name?.trim().length || !riskControl?.category;
  }, [riskControl]);

  const onSave = useCallback(async () => {
    try {
      setIsValidate(true);
      if (!riskControl || validateRisk()) {
        return;
      }

      let response;
      if (id) {
        response = await httpService.put(`risk-controls/${id}`, riskControl);
        trackManualEvent(RISK_CONTROL_UPDATE_CONTROL, { ...riskControl });
      } else {
        response = await httpService.post(`risk-controls`, riskControl);
        trackManualEvent(RISK_CONTROL_ADD_CONTROL, { ...riskControl });
      }

      setIsRiskControlChangedDialogOpen(false);
      setIsRiskControlUnsavedChangesDialogOpen(false);

      onClose?.();
      if (response)
        toastNotificationService.success('Created successfully', {
          shouldCloseOnTransition: true,
        });

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (err: any) {
      if (err.code === 11000) {
        // todo: change errors
        toastNotificationService.error(err.message, {
          shouldCloseOnTransition: true,
        });
        console.log(err);
      } else {
        console.log(err);
        toastNotificationService.error(err.message, {
          shouldCloseOnTransition: true,
        });
      }
    }
  }, [id, onClose, riskControl, validateRisk]);

  return (
    <RootContainer>
      <FormContainer>
        <BigidHeading4>{riskControl?.name}</BigidHeading4>
        <BigidInlineNotification
          open
          type={'insight'}
          title={'Risk Controls'}
          text={[
            {
              subText:
                'The risk control process is an essential part of risk management. It involves evaluating potential losses and reducing or eliminating them.',
            },
          ]}
        />
        <RiskFormField tooltip="Friendly Name">
          <BigidTextField
            label="Friendly Name"
            required
            dataAid="control-library-friendly-name"
            errorMessage={isValidate && !riskControl?.name?.length ? 'Required' : undefined}
            isError={isValidate && !riskControl?.name?.length}
            onChange={event => {
              if (riskControl) {
                const riskClone = cloneDeep(riskControl);
                riskClone.name = event.target.value;
                setRiskControl(riskClone);
              }
            }}
            value={riskControl?.name}
          />
        </RiskFormField>

        {/*<RiskFormField tooltip="ID# Name">*/}
        {/*  <BigidTextField*/}
        {/*    label="ID# Name"*/}
        {/*    required*/}
        {/*    dataAid="control-library-id-name"*/}
        {/*    errorMessage={isValidate && !riskControl?.controlId?.length ? 'Required' : undefined}*/}
        {/*    isError={isValidate && !riskControl?.controlId?.length}*/}
        {/*    onChange={event => {*/}
        {/*      if (riskControl) {*/}
        {/*        const riskClone = cloneDeep(riskControl);*/}
        {/*        riskClone.controlId = event.target.value;*/}
        {/*        setRiskControl(riskClone);*/}
        {/*      }*/}
        {/*    }}*/}
        {/*    value={riskControl?.controlId}*/}
        {/*  />*/}
        {/*</RiskFormField>*/}

        <RiskFormField tooltip="Description">
          <BigidTextField
            label="Description"
            dataAid="control-library-description"
            placeholder="Description of this control"
            multiline
            rows={3}
            onChange={event => {
              if (riskControl) {
                const riskClone = cloneDeep(riskControl);
                riskClone.description = event.target.value;
                setRiskControl(riskClone);
              }
            }}
            value={riskControl?.description}
          />
        </RiskFormField>

        <RiskFormField tooltip="Standard / Category">
          <BigidTextField
            label="Standard / Category"
            required
            dataAid="control-library-category"
            errorMessage={isValidate && !riskControl?.category?.length ? 'Required' : undefined}
            isError={isValidate && !riskControl?.category?.length}
            onChange={event => {
              if (riskControl) {
                const riskClone = cloneDeep(riskControl);
                riskClone.category = event.target.value;
                setRiskControl(riskClone);
              }
            }}
            value={riskControl?.category}
          />
        </RiskFormField>
      </FormContainer>
      <ButtonsContainer>
        <TertiaryButton
          dataAid="control-library-cancel"
          size="medium"
          // variant="outlined"

          onClick={() => {
            if (isEqual(oldRiskControl, riskControl)) {
              onClose?.();
            } else {
              setIsRiskControlUnsavedChangesDialogOpen(true);
            }
          }}
        >
          {'Close'}
        </TertiaryButton>

        {isPermitted(RISK_CONTROLS_PERMISSIONS.EDIT.name) && (
          <PrimaryButton
            size="medium"
            dataAid="control-library-save"
            disabled={isEqual(oldRiskControl, riskControl) || validateRisk()}
            onClick={() => {
              if (id) {
                setIsRiskControlChangedDialogOpen(true);
              } else {
                onSave();
              }
            }}
          >
            {'save'}
          </PrimaryButton>
        )}
      </ButtonsContainer>
      <RiskControlChangeDialog
        controlName={riskControl?.name ?? ''}
        isOpen={isRiskControlChangedDialogOpen}
        cancel={() => {
          setIsRiskControlChangedDialogOpen(false);
          onClose?.();
        }}
        confirm={() => {
          setIsRiskControlChangedDialogOpen(false);
          onSave();
        }}
      />
      <RiskControlUnsavedChangesDialog
        confirm={() => {
          setIsRiskControlUnsavedChangesDialogOpen(false);
          onSave();
        }}
        isOpen={isRiskControlUnsavedChangesDialogOpen}
        cancel={() => {
          setIsRiskControlUnsavedChangesDialogOpen(false);
          if (shouldKeepOpen) {
            getRiskControlByIdAsync();
          } else {
            onClose?.();
          }
        }}
      />
    </RootContainer>
  );
};
