import { BigidIconType } from '@bigid-ui/components';
import GoogleShortLogo from '../../../../assets/icons/GoogleShortLogo.svg';
import oneDrivePersonal from '../../../../assets/icons/onedrivePersonal.svg';
import serviceNowLogo from '../../../../assets/icons/serviceNowLogo.svg';
import genericCloud from '../../../../assets/icons/genericCloud.svg';
import salesforceLogo from '../../../../assets/icons/salesforceLogo.svg';
import gmailLogo from '../../../../assets/icons/gmailLogo.svg';
import googleDriveLogo from '../../../../assets/icons/googleDriveLogo.svg';
import jiraLogo from '../../../../assets/icons/jiraLogo.svg';
import iterableLogo from '../../../../assets/icons/iterableLogo.svg';
import snowflakeLogo from '../../../../assets/icons/snowflakeLogo.svg';

export const IMAGE_NAME_TO_COMPONENT_MAP: Record<string, BigidIconType> = {
  google: GoogleShortLogo,
  serviceNow: serviceNowLogo,
  genericCloudLogo: genericCloud,
  oneDrivePersonal: oneDrivePersonal,
  salesforce: salesforceLogo,
  gmail: gmailLogo,
  googleDrive: googleDriveLogo,
  jira: jiraLogo,
  iterable: iterableLogo,
  snowflake: snowflakeLogo,
};
