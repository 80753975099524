import React, { FunctionComponent, Fragment } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import {
  SecondaryButton,
  BigidColors,
  BigidProgressBarSimple,
  BigidTooltip,
  BigidDropdownValue,
} from '@bigid-ui/components';
import { FileContentAttribute, FileTextContents } from './PreviewFileContentService';
import { PreviewFileFindingsTooltip } from './PreviewFileFindingsTooltip';

interface PreviewFileContentChunkProps {
  content: string;
  isLoadMoreEnabled?: boolean;
  displayLoadMore?: boolean;
  displaySeparator?: boolean;
  displayProgressbar?: boolean;
  attributes?: FileContentAttribute[];
  isLoadMoreFailed?: boolean;
  onLoadMoreClick?: () => void;
  attributeNameSelected?: BigidDropdownValue;
}

type ContentValueChunk = {
  value: string;
  isAttribute: boolean;
  allLabels?: string[];
};

const useStyles = makeStyles(() => ({
  chunkWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  chunkContentWrapper: {
    width: '100%',
    height: '100%',
  },
  highlighted: {
    border: `1px solid ${BigidColors.green[700]}`,
    borderRadius: 4,
    backgroundColor: BigidColors.green[50],
    margin: '1px',
  },
  chunksSeparator: {
    width: '95%',
    color: BigidColors.gray[100],
    marginBottom: '12px',
  },
  loadMore: {
    margin: '12px 0 12px 0',
  },
  progressbarContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '8px 24px 16px 24px',
    fontSize: '0.875rem',
    minWidth: '365px',
  },
  textContainer: {
    marginTop: '24px',
    maxWidth: '350px',
    textAlign: 'center',
    color: BigidColors.gray[600],
  },
}));

export const PreviewFileContentChunk: FunctionComponent<PreviewFileContentChunkProps> = ({
  content,
  onLoadMoreClick,
  isLoadMoreEnabled = true,
  displayLoadMore = false,
  displayProgressbar = true,
  displaySeparator = false,
  isLoadMoreFailed = false,
  attributes = [],
  attributeNameSelected = [],
}) => {
  const classes = useStyles({});

  function getHighlightedAttributes(fileContent: Pick<FileTextContents, 'content' | 'attributes'>): JSX.Element[] {
    const { content: text, attributes } = fileContent;

    const selectedAttributes = attributes.filter(attribute =>
      attributeNameSelected.some(selectedAttr => attribute.allLabels.includes(selectedAttr.id)),
    );

    const attributesToHighlight = attributeNameSelected.length ? selectedAttributes : attributes;

    const sortedAttributes = attributesToHighlight.sort(({ offset: a }, { offset: b }) => a - b);
    const firstOffset = sortedAttributes?.[0]?.offset;
    let current = !firstOffset ? 0 : sortedAttributes?.[0]?.offset;
    const result: ContentValueChunk[] = [
      {
        value: text.substr(0, firstOffset),
        isAttribute: false,
      },
    ];

    (sortedAttributes || []).forEach(({ length = 0, offset = 0, allLabels }) => {
      if (current <= offset) {
        result.push({
          value: text.substr(current, offset - current),
          isAttribute: false,
        });
        current = offset;
        result.push({
          value: text.substr(offset, length),
          isAttribute: true,
          allLabels,
        });
        current = offset + length;
      }
    });
    if (current !== text.length && sortedAttributes.length > 0) {
      result.push({
        value: text.substr(current, text.length - current),
        isAttribute: false,
      });
      current = text.length;
    }

    const highlightedContent = result.map(({ value, isAttribute, allLabels = [] }, index) => {
      return (
        <BigidTooltip
          key={index}
          title={<PreviewFileFindingsTooltip text={allLabels.join(', ')} />}
          isDisabled={allLabels.length === 0}
        >
          <span className={isAttribute ? classes.highlighted : ''}>{value}</span>
        </BigidTooltip>
      );
    });
    return highlightedContent;
  }

  return (
    <Fragment>
      <span className={classes.chunkContentWrapper} data-aid="chunkContentWrapper">
        {getHighlightedAttributes({ content, attributes })}
      </span>
      {(displayLoadMore || displaySeparator || displayProgressbar) && (
        <div className={classes.chunkWrapper}>
          {!displayLoadMore && displaySeparator && <hr className={classes.chunksSeparator} />}
          {displayLoadMore && !displayProgressbar && (
            <div className={classes.loadMore}>
              <SecondaryButton
                disabled={!isLoadMoreEnabled}
                onClick={onLoadMoreClick}
                size="small"
                text={isLoadMoreFailed ? 'Retry' : 'Load more'}
              />
            </div>
          )}
          {displayProgressbar && (
            <div className={classes.progressbarContainer}>
              <div className={classes.textContainer}>Scanning...</div>
              <BigidProgressBarSimple type="indeterminate" />
            </div>
          )}
        </div>
      )}
    </Fragment>
  );
};
