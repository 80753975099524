import { useEffect, useState } from 'react';
import { DataSource, getSupportedDataSources } from '../../../../utilities/dataSourcesUtils';
import { notificationService } from '../../../../services/notificationService';

export type DataSourcesTypeMapping = Map<string, string>;

interface SupportedDataSourceResult {
  supportedDataSources: DataSource[];
  dataSourcesTypeMapping: DataSourcesTypeMapping;
}

export const useSupportedDataSources = (): SupportedDataSourceResult => {
  const [supportedDataSources, setSupportedDataSources] = useState<DataSource[]>();
  const [dataSourcesTypeMapping, setDataSourcesTypeMapping] = useState<Map<string, string>>();

  useEffect(() => {
    async function getData() {
      try {
        const dataSources = await getSupportedDataSources();
        const nameToDisplayName = dataSources.map<[string, string]>(({ name, displayName }) => [
          name.toLowerCase(),
          displayName || name,
        ]);
        setDataSourcesTypeMapping(new Map(nameToDisplayName));
        setSupportedDataSources(dataSources);
      } catch (error) {
        setSupportedDataSources([]);
        notificationService.warning('There was a problem getting user preferences, using default values.');
        console.error(error);
      }
    }

    getData();
  }, []);

  return { supportedDataSources, dataSourcesTypeMapping };
};
