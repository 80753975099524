import React, { FC, useMemo, useEffect, useState, Suspense } from 'react';
import { Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { BigidColors } from '@bigid-ui/components';
import { DashboardCommon } from '../DashboardCommon';
import { DashboardRightSection } from '../DashboardRightSection/DashboardRightSection';
import { DashboardBottomSectionCommon } from '../DashboardBottomSection/DashboardBottomSectionCommon';
import { smallIdInsightsList } from '../DashboardBottomSection/insightsUtils';
import { useSummaryData } from '../hooks/useSummaryData';
import { usePolicyData } from '../hooks/usePolicyData';
import { useObjectsData } from '../hooks/useObjectsData';
import { useAutoDiscoveryData } from '../hooks/useAutoDiscoveryData';
import { getShortcuts } from '../DashboardLeftSection/shortcutsUtils';
import { smallIdLicenseService } from '../../../services/smallIdLicenseService';
import { DashboardLeftSectionCommon } from '../DashboardLeftSection/DashboardLeftSectionCommon';
import { DashboardUpperSectionCommon } from '../DashboardUpperSection/DashboardUpperSectionCommon';
import { CONFIG } from '../../../../config/common';
import { pageHeaderService } from '../../../../common/services/pageHeaderService';
import { enrichSmallIdShortcuts } from './smallIdDashboardUtils';
import { getApplicationPreference } from '../../../services/appPreferencesService';
import { CustomDashboardContainer } from '../../CustomDashboard/CustomDashboardPreviewMode';
import { fmsdDefaultDashboard } from '../../CustomDashboard/TemplateSelector/templates/fmsdDefaultDashboard';
import { useBigIdGuidedTourSteps } from '../../BigIdGuidedTours/useBigIdGuidedTourSteps';
import { actionableInsightsDashboard } from '../../CustomDashboard/TemplateSelector/templates/actionableInsightsDashboard';

const SmallidDashboardGuidedTour = React.lazy(() =>
  import('../../BigIdGuidedTours/BigIdGuidedTours').then(module => ({
    default: module.BigIdGuidedTours,
  })),
);

const useStyles = makeStyles({
  titleWrapper: {
    position: 'absolute',
    top: '58px',
    width: '100%',
    left: 0,
  },
  title: {
    fontWeight: 500,
    fontSize: '1.5rem',
    color: BigidColors.gray[700],
    maxWidth: '1050px',
    margin: '0 auto',
    paddingLeft: '32px',
  },
  titleEnd: {
    color: '#9F54BA',
  },
  leftSide: {
    width: '410px',
    height: '403px',
    flexGrow: 1,
    margin: '7px 16px 5px 0',
  },
  rightSide: {
    width: '624px',
    height: '400px',
    margin: '7px 0 5px 0',
    flexGrow: 1,
  },
});

const SHORTCUTS_HIDDEN = [
  CONFIG.states.CLUSTERING,
  CONFIG.states.CLUSTERING_OBJECTS_PREVIEW,
  CONFIG.states.CLUSTER_PREVIEW,
];

export const SmallIdDashboard: FC = () => {
  const { title, titleWrapper, titleEnd, leftSide, rightSide } = useStyles({});
  const { sourceDisplayData, recordsDisplayData } = useSummaryData();
  const policyData = usePolicyData();
  const objectsData = useObjectsData();
  const autodiscoveryData = useAutoDiscoveryData();
  const allDisplayData = [sourceDisplayData, recordsDisplayData, policyData, autodiscoveryData, objectsData];
  const isFmsd = getApplicationPreference('FMSD_ENABLED');
  const isActionableInsights = getApplicationPreference('ACTIONABLE_INSIGHTS_ENABLED');
  const shortCuts = useMemo(() => {
    if (!isFmsd) {
      const smallIdShortcuts = getShortcuts(({ state }) => {
        return !state || (smallIdLicenseService.isAccessGranted(state) && !SHORTCUTS_HIDDEN.includes(state));
      });
      return enrichSmallIdShortcuts(smallIdShortcuts);
    }
  }, [isFmsd]);

  const smallIdGuidedTourSteps = useBigIdGuidedTourSteps('smallIdDashboard');

  const renderSwitch = () => {
    switch (true) {
      case isActionableInsights:
        return (
          <div>
            <Suspense fallback={null}>
              {smallIdLicenseService.isSmallIDLicense() && (
                <SmallidDashboardGuidedTour steps={smallIdGuidedTourSteps} />
              )}
            </Suspense>
            <CustomDashboardContainer config={actionableInsightsDashboard} />
          </div>
        );

      case isFmsd:
        return (
          <div>
            <Suspense fallback={null}>
              {smallIdLicenseService.isSmallIDLicense() && (
                <SmallidDashboardGuidedTour steps={smallIdGuidedTourSteps} />
              )}
            </Suspense>
            <CustomDashboardContainer config={fmsdDefaultDashboard} />
          </div>
        );

      default:
        return (
          <DashboardCommon>
            <div className={titleWrapper} data-aid="smallIdDashboardTitle">
              <Typography variant={'h6'} className={title}>
                Welcome to Small<span className={titleEnd}>ID</span>
              </Typography>
            </div>
            <DashboardUpperSectionCommon displayData={allDisplayData} />
            <div className={leftSide}>
              <DashboardLeftSectionCommon shortCuts={shortCuts} />
            </div>
            <div className={rightSide}>
              <DashboardRightSection />
            </div>
            <DashboardBottomSectionCommon insightsList={smallIdInsightsList} />
          </DashboardCommon>
        );
    }
  };

  useEffect(() => {
    pageHeaderService.setTitle({ pageTitle: '' });
  }, []);

  return <>{renderSwitch()}</>;
};
