import { PrimaryButton } from '@bigid-ui/components';
import {
  Classifier,
  updateClassifierVersion,
  getClassifierVersion,
  ClassifierVersionToUpdateConfig,
} from '../../services/classifiersService';
import { ClassifierUpdatePreview } from './ClassifierUpdatePreview';
import { ClassifierUpdatePreviewBulk, ClassifierUpdatePreviewBulkProps } from './ClassifierUpdatePreviewBulk';
import { openSystemDialog } from '../../services/systemDialogService';
import { notificationService } from '../../services/notificationService';
import { showConfirmationDialog } from '../../services/confirmationDialogService';
import { noop } from 'lodash';

type ClassifierVersionToUpdateConfigExtended = Pick<Classifier, 'versionState' | 'classification_name'> &
  ClassifierVersionToUpdateConfig;

const updatePropsToClassifierPropsMapping: {
  [Property in keyof ClassifierVersionToUpdateConfigExtended]?: keyof Classifier;
} = {
  classification_name: 'classification_name',
  version: 'version',
  versionState: 'versionState',
  regex: 'classification_regex',
  term: 'support_term_value',
  minLength: 'min_length',
  maxLength: 'max_length',
  proximityBefore: 'proximity_before',
  proximityAfter: 'proximity_after',
  description: 'description',
  validation: 'validation',
};

export const mapUpdatePropsToClassifierProps = (
  versionUpdate: ClassifierVersionToUpdateConfigExtended,
): Partial<Classifier> => {
  return Object.entries(updatePropsToClassifierPropsMapping).reduce(
    (classifier: Classifier, [versionProp, classifierProp]: string[]) => {
      return {
        ...classifier,
        [classifierProp]: versionUpdate[versionProp as keyof ClassifierVersionToUpdateConfigExtended],
      };
    },
    {} as Classifier,
  );
};

export const openClassifierUpdatePreviewBulkDialog = async ({
  classifiers,
  onUpdate,
}: ClassifierUpdatePreviewBulkProps) => {
  openSystemDialog({
    title: `Preview ${classifiers.length} ${classifiers.length === 1 ? 'Classifier' : 'Classifiers'}`,
    onClose: noop,
    content: ClassifierUpdatePreviewBulk,
    contentProps: { classifiers, onUpdate },
    maxWidth: 'lg',
  });
};

export const openClassifierUpdatePreviewDialog = async (
  classifier: Classifier,
  onUpdate: (updatedClassifier: Partial<Classifier>) => void,
) => {
  const { classification_name } = classifier;

  try {
    const versionUpdate = await getClassifierVersion(classification_name);
    const { new: newVersion } = versionUpdate;

    openSystemDialog({
      title: classification_name,
      onClose: noop,
      content: ClassifierUpdatePreview,
      contentProps: {
        versionUpdate,
      },
      maxWidth: 'md',
      isContentScrollable: true,
      buttons: [
        {
          text: 'Update',
          component: PrimaryButton,
          dataAid: 'ClassifierUpdatePreview-update',
          onClick: () => {
            updateClassifierVersion(classification_name, { versionToUpdate: newVersion.version })
              .then(({ version, versionState }) => {
                const updatedClassifier = mapUpdatePropsToClassifierProps({
                  ...newVersion,
                  version,
                  versionState,
                  classification_name,
                });

                onUpdate(updatedClassifier);
                notificationService.success(
                  `'${classification_name}' version has successfully been updated to ${version}`,
                );
              })
              .catch(({ message }) => {
                console.error(`An error has occurred: ${message}`);
                notificationService.error(`An error has occurred during '${classification_name}' version update`);
              });
          },
          isClose: true,
        },
      ],
    });
  } catch ({ message }) {
    console.error(`An error has occurred: ${message}`);
    notificationService.error('An error has occurred');
  }
};

export const openClassifierRestoreVersionDialog = async (classifier: Classifier): Promise<Partial<Classifier>> => {
  const { classification_name } = classifier;

  try {
    const { current: currentVersion, new: newVersion } = await getClassifierVersion(classification_name);

    const isClassifierVersionRestoreConfirmed = await showConfirmationDialog({
      entityNameSingular: 'Restore',
      actionName: 'Confirm',
      actionButtonName: 'Restore',
      customDescription: `The current classifier version is ${currentVersion.version}. Would you like to replace it with the previous version ${newVersion.version}?`,
    });

    try {
      if (isClassifierVersionRestoreConfirmed) {
        const { version, versionState } = await updateClassifierVersion(classification_name, {
          versionToUpdate: newVersion.version,
        });

        const updatedClassifier = mapUpdatePropsToClassifierProps({
          ...newVersion,
          version,
          versionState,
          classification_name,
        });

        notificationService.success(`'${classification_name}' version has successfully been restored to ${version}`);

        return updatedClassifier;
      }

      return null;
    } catch ({ message }) {
      console.error(`An error has occurred: ${message}`);
      notificationService.error(`An error has occurred during '${classification_name}' version restoration.`);
    }
  } catch ({ message }) {
    console.error(`An error has occurred: ${message}`);
    notificationService.error('An error has occurred');
  }
};
