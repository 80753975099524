import './personalInfo.component.scss';
import template from './personalInfo.component.html';
import personalInfoConsentViolationModalTemplate from './personalInfo-consentViolationModal.html';
import { module } from 'angular';
import { DEFAULT_TOGGLE_VALUE } from '../../../react/views/DSAR/SarInfo/ExtendedInfo/ExtendedInfo';
import { isPermitted } from '../../../react/services/userPermissionsService';
import { DSAR_PERMISSIONS } from '@bigid/permissions';
import { getApplicationPreference } from '../../../react/services/appPreferencesService';

const app = module('app');

const REPORT_CONFIG_TYPES = {
  attributes: 'personal_info_attributes',
  personalInfo: 'personal_info',
};

const REPORT_CONFIG_PERSONAL_INFO_NAMES = {
  consent: 'consent',
  dataSources: 'data_sources',
};

app.component('personalInfo', {
  template,
  controller: function ($uibModal, $translate, notificationService, personalInfoService) {
    'ngInject';

    const initializeData = async callback => {
      try {
        const { data } = await personalInfoService.getUserPersonalInfo(this.userId);
        callback(data);
      } catch (err) {
        $translate('API:MESSAGE:COMMON_ERROR').then(translation => {
          notificationService.error(translation);
        });

        callback(null);
      }
    };

    this.personalInfoAttributes = {};
    this.userName = '';
    this.hideAttributePurposeOfUse = getApplicationPreference('HIDE_ATTRIBUTE_PURPOSE_OF_USE');
    this.isUseDsarReportTemplates = getApplicationPreference('USE_DSAR_REPORT_TEMPLATES');
    this.consentLogsModel = {};
    this.personalInfoDataSourcesModel = {};
    this.personalInfoReportConfig = {};
    this.MAX_RECORDS_TO_DISPLAY = 100;
    this.attributesWithoutConsent = [];
    this.attributesWithoutLegitimacyOfUse = [];
    this.hasEditPermission = isPermitted(DSAR_PERMISSIONS.EDIT_PERSONAL_INFO.name);

    // indicate if there are update request in progress
    this.isUpdating = false;

    /**
     * Init report config state
     */
    this.initReportConfigPersonalInfo = reportConfig => {
      // init this state with default values
      this.personalInfoReportConfig = {
        consent: {
          name: REPORT_CONFIG_PERSONAL_INFO_NAMES.consent,
          type: REPORT_CONFIG_TYPES.personalInfo,
          isEnabled: DEFAULT_TOGGLE_VALUE,
        },
        dataSources: {
          name: REPORT_CONFIG_PERSONAL_INFO_NAMES.dataSources,
          type: REPORT_CONFIG_TYPES.personalInfo,
          isEnabled: DEFAULT_TOGGLE_VALUE,
        },
      };

      if (Array.isArray(reportConfig)) {
        reportConfig
          .filter(({ type }) => type === REPORT_CONFIG_TYPES.personalInfo)
          .forEach(({ name, is_enabled: isEnabled }) => {
            switch (name) {
              case REPORT_CONFIG_PERSONAL_INFO_NAMES.consent:
                this.personalInfoReportConfig.consent.isEnabled = isEnabled;
                break;

              case REPORT_CONFIG_PERSONAL_INFO_NAMES.dataSources:
                this.personalInfoReportConfig.dataSources.isEnabled = isEnabled;
                break;

              default:
                window.console.warn(`Unrecognized report_config name '${name}' with value ${isEnabled}`);
            }
          });
      }
    };

    /**
     * Join report_config states with personal_info_attributes
     */
    this.initReportConfigPersonalInfoAttributes = (reportConfig, personalInfoAttributes) => {
      const map = new Map(
        (Array.isArray(reportConfig) ? reportConfig : [])
          .filter(({ type }) => type === REPORT_CONFIG_TYPES.attributes)
          .reduce(
            (acc, { attributes }) => acc.concat(attributes.map(({ name, is_enabled }) => [name, is_enabled])),
            [],
          ),
      );

      // per each attribute in attributes add object of type { type, name, isEnabled } to use this object as
      // state of the toggle and in the onSwitcherToggled callback, cause we need to send this data in PUT request
      Object.entries(personalInfoAttributes)
        .filter(([, attr]) => attr && attr.friendlyName)
        .forEach(([, attr]) => {
          attr.reportConfig = {
            name: attr.friendlyName,
            type: REPORT_CONFIG_TYPES.attributes,
            isEnabled: isFieldEnabled(attr.friendlyName),
          };
        });

      function isFieldEnabled(name) {
        if (typeof map.get(name) === 'boolean') {
          return map.get(name);
        }
        return DEFAULT_TOGGLE_VALUE;
      }
    };

    this.initConsentLogsGrid = () => {
      this.consentLogsModel = {
        enableSorting: true,
        enablePaginationControls: false,
        virtualizationThreshold: this.MAX_RECORDS_TO_DISPLAY,
        columnDefs: [
          {
            name: 'Source Name',
            field: 'name',
            cellTemplate: '<div  title="{{COL_FIELD}}" class="cell-template-overflow">{{row.entity.name}}</div>',
          },
          { name: 'Timestamp', field: 'timestamp', cellTemplate: '<div >{{row.entity.timestamp}}</div>' },
          {
            name: 'Type of Consent',
            field: 'type_of_consent',
            cellTemplate: '<div >{{row.entity.type_of_consent}}</div>',
          },
          { name: 'Status', field: 'status', cellTemplate: '<div >{{row.entity.status}}</div>' },
          {
            name: 'Agreement Version',
            field: 'agreement_version',
            cellTemplate: '<div >{{row.entity.agreement_version}}</div>',
          },
          {
            name: 'Agreement',
            field: 'agreement',
            cellTemplate: '<div title="{{COL_FIELD}}" class="cell-template-overflow">{{row.entity.agreement}}</div>',
          },
          {
            name: 'Application ID',
            field: 'application_id',
            cellTemplate: '<div >{{row.entity.application_id}}</div>',
          },
        ],
      };
    };

    this.consentLogsModel.onRegisterApi = gridApi => {
      this.gridApi = gridApi;
    };

    this.initDataSourcesGrid = () => {
      this.personalInfoDataSourcesModel = {
        enableSorting: true,
        enablePaginationControls: false,
        virtualizationThreshold: this.MAX_RECORDS_TO_DISPLAY,
        columnDefs: [
          {
            name: 'Data Source',
            field: 'name',
            cellTemplate: '<div  title="{{COL_FIELD}}" class="cell-template-overflow">{{row.entity.name}}</div>',
          },
          {
            name: 'Type',
            field: 'type',
            cellTemplate: '<div >{{row.entity.type}}</div>',
          },
          {
            name: 'Location',
            field: 'location',
            cellTemplate: '<div >{{row.entity.location}}</div>',
          },
        ],
      };
    };

    this.personalInfoDataSourcesModel.onRegisterApi = gridApi => {
      this.gridApi = gridApi;
    };

    /**
     * onSwitcherToggled callback
     * Used as to update specific property report_config state locally and sends to the server
     * @param isEnabled {boolean} - the value passed from the bigid-switcher @output callback
     * @param {name, type, isEnabled} - specific property (local) state of reportConfig
     */
    this.onSwitcherToggled = (isEnabled, reportConfig) => {
      if (this.isUpdating) {
        return;
      }

      this.isUpdating = true;
      this.onDataLoaded();
      const prevStateIsEnabled = !isEnabled;

      const { name, type } = reportConfig;
      // update state of the reportConfig property object
      reportConfig.isEnabled = isEnabled;
      personalInfoService
        .updateReportConfig(this.userId, {
          name,
          type,
          is_enabled: isEnabled,
        })
        .then(
          () => {
            $translate('API:MESSAGE:COMMON_PUT_SUCCESS').then(translation => {
              notificationService.success(translation);
            });
          },
          () => {
            $translate('API:MESSAGE:COMMON_ERROR').then(translation => {
              notificationService.error(translation);
              // restore old toggle state
              reportConfig.isEnabled = prevStateIsEnabled;
            });
          },
        )
        .finally(() => {
          this.onDataLoaded();
          this.isUpdating = false;
        });
    };

    this.initAttributesConsentViolationAlert = (attributesWithoutConsent, personalInfoAttributes) => {
      if (!Array.isArray(attributesWithoutConsent) || !attributesWithoutConsent.length) {
        return;
      }
      Object.keys(personalInfoAttributes).forEach(key => {
        if (attributesWithoutConsent.includes(personalInfoAttributes[key].friendlyName)) {
          personalInfoAttributes[key].is_consent_violated = true;
        }
      });
    };

    this.openViolationModal = (attributesWithoutConsent, attributesWithoutLegitimacyOfUse) => {
      $uibModal.open({
        template: personalInfoConsentViolationModalTemplate,
        windowClass: 'unassociated-consent-attributes',
        controller: [
          '$scope',
          '$uibModalInstance',
          function ($scope, $uibModalInstance) {
            Object.assign($scope, {
              attributesWithoutConsent,
              attributesWithoutLegitimacyOfUse,
              close: () => {
                $uibModalInstance.close();
              },
            });
          },
        ],
      });
    };

    this.$onInit = () => {
      this.initConsentLogsGrid();
      this.initDataSourcesGrid();
    };

    this.$onChanges = () => {
      if (typeof this.userId != 'undefined' && this.userId != null) {
        this.onDataLoading();

        // TODO: check why are we calling async function inside onchanges ?
        // TODO: also, why is it called with callback ? It's a mix of callback + Promise,
        // TODO: seems to me some kind of mistake / anti pattern
        initializeData(result => {
          if (result && Array.isArray(result.attributes) && result.attributes.length > 0 && result.userName) {
            this.userName = result.userName;

            this.personalInfoAttributes = personalInfoService.aggregateUserPersonalInfo(result.attributes);

            if (result.consent) {
              this.consentLogsModel.data = personalInfoService.getTop5ConsentsForeachName(result.consent);
            }

            if (result.data_sources) {
              this.personalInfoDataSourcesModel.data = result.data_sources;
            }
            if (result.agreementConsentAttributes) {
              if (Array.isArray(result.agreementConsentAttributes.attributeUnderConsentTypeInAgreement)) {
                this.attributesWithoutConsent = [
                  ...new Set(
                    result.agreementConsentAttributes.attributeUnderConsentTypeInAgreement.map(
                      att => att.friendly_name,
                    ),
                  ),
                ];
              }
              if (Array.isArray(result.agreementConsentAttributes.attributeNotUnderConsentTypeInAgreement)) {
                this.attributesWithoutLegitimacyOfUse = [
                  ...new Set(
                    result.agreementConsentAttributes.attributeNotUnderConsentTypeInAgreement.map(
                      att => att.friendly_name,
                    ),
                  ),
                ];
              }
            }
            const consentViolationAttributes = [
              ...this.attributesWithoutConsent,
              ...this.attributesWithoutLegitimacyOfUse,
            ];
            this.initAttributesConsentViolationAlert(consentViolationAttributes, this.personalInfoAttributes);

            // re-set reportConfig state if available
            if (Array.isArray(result.report_config)) {
              this.initReportConfigPersonalInfo(result.report_config);
              this.initReportConfigPersonalInfoAttributes(result.report_config, this.personalInfoAttributes);
            }

            // FIXME: temporal solution
            // What should be fixed here?
            this.onDataLoaded({ userName: this.userName });
          } else {
            this.userName = 'Report is empty - User Unique ID not found';
            this.onDataLoaded();
          }
        });
      }
    };
  },
  bindings: {
    userId: '<',
    onDataLoading: '&',
    onDataLoaded: '&',
  },
});
