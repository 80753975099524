import { httpService } from './httpService';
import { scansService } from './angularServices';
import { downloadFile } from './downloadFile';
import { BIGID_BI_EVENTS } from '../config/BigIdBiEvents';
import { analyticsService } from './analyticsService';
import { downloadReport } from './downloadReport';
import { usageReportDialogContentElement } from '../components/Reports/UsageReport/UsageReportDialogContent';

const getScannedFilesReport = () => httpService.downloadFile('scanned-files-report/file-download/csv');
const getDataCustodianReport = () => httpService.downloadFile('data-custodian-report/file-download/csv');
const getFilesAttributeDistributionReport = () =>
  httpService.downloadFile('files-attribute-distribution-report/file-download/csv');
const getFailedObjectReport = () => httpService.fetch('failed-object-report');
const getLabelerReport = () => httpService.downloadFile('file-download/labeler-report?type=User-Friendly');
const getTaggedEntity = () => httpService.fetch('tagged-entities?type=csv');
const getMetadataClassificationReport = () => httpService.fetch('metadata-classification-report');
const getUsageReport = () => httpService.downloadFile('file-download/usage-report');

export const failedObjectReportFileName = 'bigid-scanned-failed-object-report';

export const onGetScannedFilesReport = () => {
  analyticsService.trackManualEvent(BIGID_BI_EVENTS.REPORTS_SCANNED_FILES);

  downloadReport({
    headerToTranslate: 'modals.reports.scannedFiles.header',
    bodyToTranslate: 'modals.reports.scannedFiles.body',
    downloadReport: getScannedFilesReport,
  });
};

export const onGetFailedObjectReport = () => {
  analyticsService.trackManualEvent(BIGID_BI_EVENTS.REPORTS_FAILED_OBJECT_REPORT);

  downloadReport({
    headerToTranslate: 'modals.reports.failedObject.header',
    fileName: failedObjectReportFileName,
    downloadReport: scansService.getFailedObjectReport,
  });
};

export const onGetLabelerReport = () => {
  analyticsService.trackManualEvent(BIGID_BI_EVENTS.REPORTS_LABELING_PROPAGATION_REPORT);
  downloadReport({
    headerToTranslate: 'modals.reports.labeler.header',
    bodyToTranslate: 'modals.reports.labeler.body',
    downloadReport: getLabelerReport,
  });
};

export const onGetMetadataClassifierReport = () => {
  analyticsService.trackManualEvent(BIGID_BI_EVENTS.REPORTS_METADATA_CLASSIFICATION_REPORT);
  downloadReport({
    headerToTranslate: 'modals.reports.metadataClassifier.header',
    bodyToTranslate: 'modals.reports.metadataClassifier.body',
    getReport: getMetadataClassificationReport,
    fileName: 'bigid-metadata-classification-report',
  });
};

export const onGetDataCustodianReport = () => {
  analyticsService.trackManualEvent(BIGID_BI_EVENTS.REPORTS_DATA_CUSTODIAN);
  downloadReport({
    headerToTranslate: 'modals.reports.dataCustodian.header',
    bodyToTranslate: 'modals.reports.dataCustodian.body',
    downloadReport: getDataCustodianReport,
  });
};

export const onGetFilesAttributeDistributionReport = () => {
  analyticsService.trackManualEvent(BIGID_BI_EVENTS.REPORTS_FILES_ATTRIBUTES_DISTRIBUTION);
  downloadReport({
    headerToTranslate: 'modals.reports.filesAttributeDistribution.header',
    bodyToTranslate: 'modals.reports.filesAttributeDistribution.body',
    downloadReport: getFilesAttributeDistributionReport,
  });
};

export const onGetTaggedEntityReport = async () => {
  analyticsService.trackManualEvent(BIGID_BI_EVENTS.REPORTS_TAGGED_ENTITIES);
  const { data } = await getTaggedEntity();
  downloadFile(data, 'attachment/octet-stream', `bigid-tagged-entities-report-${new Date().toISOString()}.csv`);
};

export const onGetUsageReport = async () => {
  analyticsService.trackManualEvent(BIGID_BI_EVENTS.REPORTS_USAGE_REPORT);
  downloadReport({
    headerToTranslate: 'modals.reports.usageReport.header',
    contentComponent: usageReportDialogContentElement,
    downloadReport: getUsageReport,
  });
};

export const sidebarService = {
  onGetScannedFilesReport,
  onGetFailedObjectReport,
  onGetLabelerReport,
  onGetMetadataClassifierReport,
  onGetDataCustodianReport,
  onGetFilesAttributeDistributionReport,
  onGetTaggedEntityReport,
  getFailedObjectReport,
  onGetUsageReport,
};
