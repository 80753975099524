import template from './customContent.component.html';
import { module } from 'angular';
const app = module('app');

app.component('customContent', {
  template,
  bindings: {
    content: '<',
    title: '<',
    style: '<',
  },
  controllerAs: 'customContentModel',
  controller: [
    '$scope',
    '$rootScope',
    '$stateParams',
    '$translate',
    function ($scope, $rootScope, $stateParams, $translate) {
      var customContentModel = this;

      let _setContent = content => {
        $scope.compiledContent = content;
      };
      let _setStyle = style => {
        $scope.style = style;
      };

      let _setTitle = title => {
        $translate(title).then(function (translation) {
          $rootScope.$broadcast('changePage', translation, false);
        });
      };

      if ($stateParams.content) {
        _setContent($stateParams.content);
      }

      if ($stateParams.title) {
        _setTitle($stateParams.title);
      }

      if ($stateParams.style) {
        _setStyle($stateParams.style);
      }

      customContentModel.$onChanges = () => {
        if (angular.isDefined(customContentModel.content) && !$stateParams.content) {
          _setContent($stateParams.content);
        }
        if (angular.isDefined(customContentModel.title) && !$stateParams.title) {
          _setTitle($stateParams.title);
        }
      };
    },
  ],
});

app.directive('compile', [
  '$compile',
  $compile => {
    return (scope, element, attrs) => {
      scope.$watch(
        scope => {
          return scope.$eval(attrs.compile);
        },
        value => {
          element.html(value);
          $compile(element.contents())(scope);
        },
      );
    };
  },
]);
