import React, { FC, useState, useCallback } from 'react';
import styled from '@emotion/styled';
import { BigidContentItem, BigidPaper } from '@bigid-ui/components';
import { generateDataAid } from '@bigid-ui/utils';
import { DataCatalogRecord } from '../DataCatalogService';
import { Grid, AuditTrailRecordGridItem } from './grid/Grid';
import { Details } from './details/Details';

const Root = styled('div')`
  width: 100%;
  height: 100%;
  display: flex;
  padding: 5px;
`;

const GridWrapper = styled('div')<{ shouldShrink: boolean }>`
  display: flex;
  height: 100%;
  width: ${({ shouldShrink }) => (shouldShrink ? 'calc(100% - 335px)' : '100%')};
  padding-right: ${({ shouldShrink }) => (shouldShrink ? '16px' : 0)};
`;

const DetailsWrapper = styled('div')`
  display: flex;
  height: 100%;
  width: 335px;
`;

const dataAid = 'DataCatalogAuditTrail';

export const DataCatalogAuditTrailGrid: FC<BigidContentItem & DataCatalogRecord> = ({ fullyQualifiedName }) => {
  const [isDetailsEnabled, setIsDetailsEnabled] = useState<boolean>(false);
  const [currentlyPreviewedRecord, setCurrentlyPreviewedRecord] = useState<AuditTrailRecordGridItem>();

  const handleGridRowClick = useCallback(
    (record: AuditTrailRecordGridItem): void => {
      if (record.id !== currentlyPreviewedRecord?.id) {
        setCurrentlyPreviewedRecord(record);
        setIsDetailsEnabled(true);
      } else {
        setCurrentlyPreviewedRecord(null);
        setIsDetailsEnabled(false);
      }
    },
    [currentlyPreviewedRecord],
  );

  const handleDetailsOnClose = (): void => {
    setCurrentlyPreviewedRecord(null);
    setIsDetailsEnabled(false);
  };

  return (
    <Root data-aid={dataAid}>
      <GridWrapper shouldShrink={isDetailsEnabled}>
        <BigidPaper>
          <Grid
            dataAid={generateDataAid(dataAid, ['grid'])}
            fullyQualifiedName={fullyQualifiedName}
            onRowClick={handleGridRowClick}
          />
        </BigidPaper>
      </GridWrapper>
      {isDetailsEnabled && (
        <DetailsWrapper>
          <BigidPaper>
            <Details
              dataAid={generateDataAid(dataAid, ['details'])}
              gridRecord={currentlyPreviewedRecord}
              onClose={handleDetailsOnClose}
            />
          </BigidPaper>
        </DetailsWrapper>
      )}
    </Root>
  );
};
