import React, { FC, memo, MouseEvent, useCallback, useMemo } from 'react';
import { BigidLink, BigidBody1, BigidLoader, SecondaryButton, BigidChip, BigidColorsV2 } from '@bigid-ui/components';
import { useStyles } from './MetadataSearchSummaryStyles';
import { MetadataSearchProps } from '../MetadataSearch';
import { MetadataSearchEntityType } from '../MetadataSearchTypes';
import { MetadataSearchResultsGrid } from '../MetadataSearchResultsGrid/MetadataSearchResultsGrid';
import { MetadataSearchResultsGridRecord } from '../MetadataSearchResultsGrid/MetadataSearchResultsGridTypes';
import { MetadataSearchNoResultsHandler } from '../MetadataSearchNoResultsHandler';
import { MetadataSearchErrorHandler } from '../MetadataSearchErrorHandler';
import {
  getMetadataSearchEntityIconNode,
  getMetadataSearchEntityTypeLabel,
  MetadataSearchEntityLabelMap,
  getEntityTypeConfigByTypeId,
  getEntityQuantityModifierByCount,
  MetadataSearchTypeIconSourceMap,
} from '../MetadataSearchUtils';
import { UseMetadataSearchStateResponse } from '../useMetadataSearchState';
import { formatNumberCompact } from '../../../utilities/numericDataConverter';
import { getSummaryGridData } from './MetadataSearchSummaryUtils';
import classNames from 'classnames';

export interface MetadataSearchSummaryProps
  extends Pick<MetadataSearchProps, 'topTypesToShow' | 'topTypeEntitiesToShow'>,
    Pick<
      UseMetadataSearchStateResponse,
      | 'searchResults'
      | 'onSearchResultEntityClick'
      | 'onSearchResultEntityGroupClick'
      | 'onSearchResultShowAllClick'
      | 'isResultsFetchingLingers'
      | 'error'
      | 'entityTypeConfigs'
    > {
  dataAid: string;
}

export const MetadataSearchSummary: FC<MetadataSearchSummaryProps> = memo(
  ({
    dataAid,
    isResultsFetchingLingers,
    searchResults,
    topTypesToShow,
    topTypeEntitiesToShow,
    onSearchResultEntityClick,
    onSearchResultEntityGroupClick,
    onSearchResultShowAllClick,
    error,
    entityTypeConfigs,
  }) => {
    const classes = useStyles();

    const handleResultEntityGroupClick = useCallback(
      (event: MouseEvent, type?: MetadataSearchEntityType): void => {
        event.stopPropagation();
        onSearchResultEntityGroupClick(type);
      },
      [onSearchResultEntityGroupClick],
    );

    const handleShowAllClick = useCallback(
      (event: MouseEvent): void => {
        event.stopPropagation();
        onSearchResultShowAllClick();
      },
      [onSearchResultShowAllClick],
    );

    const { primaryResults, secondaryResults, hasMoreResults } = useMemo(() => {
      const primaryResults = searchResults?.slice(0, topTypesToShow);
      const secondaryResults =
        searchResults?.length > topTypesToShow ? searchResults.slice(topTypesToShow, searchResults.length) : [];

      return {
        primaryResults,
        secondaryResults,
        hasMoreResults: searchResults?.length > 1,
      };
    }, [searchResults, topTypesToShow]);

    const hasResults = Array.isArray(searchResults);
    const rootClasses = classNames(
      classes.root,
      (searchResults?.length === 0 || isResultsFetchingLingers) && classes.noResultsRoot,
    );
    const wrapperClasses = classNames(
      classes.wrapper,
      isResultsFetchingLingers && classes.busy,
      (searchResults?.length === 0 || isResultsFetchingLingers) && classes.noResultsWrapper,
    );

    return (
      <div className={rootClasses} data-aid={dataAid}>
        {hasResults && (
          <div className={wrapperClasses}>
            {error ? (
              <MetadataSearchErrorHandler {...error} />
            ) : (
              <>
                {searchResults?.length > 0 ? (
                  <>
                    {primaryResults.map((result, index) => {
                      const { type, count, results } = result;
                      const entityTypeConfig = getEntityTypeConfigByTypeId(entityTypeConfigs.types, type);
                      const quantityModifier = getEntityQuantityModifierByCount(count);
                      const groupTitle = getMetadataSearchEntityTypeLabel(
                        type,
                        MetadataSearchEntityLabelMap,
                        entityTypeConfig,
                        quantityModifier,
                      );
                      const groupIcon = getMetadataSearchEntityIconNode(
                        type,
                        'medium',
                        MetadataSearchTypeIconSourceMap,
                        entityTypeConfig,
                      );
                      const moreResultsCount = count - topTypeEntitiesToShow;
                      const gridData: MetadataSearchResultsGridRecord[] = getSummaryGridData(results, type);
                      const groupDataAid = `${dataAid}-group-${type}`;

                      return (
                        <div
                          key={index}
                          className={classNames(
                            classes.group,
                            classes.columnGroup,
                            moreResultsCount > 0 && classes.noOffset,
                          )}
                          data-aid={groupDataAid}
                        >
                          <div className={classes.groupTitle}>
                            <div className={classes.groupTitleIcon} data-aid={`${groupDataAid}-icon`}>
                              {groupIcon}
                            </div>
                            <BigidBody1 classes={{ root: classes.groupTitleText }} data-aid={`${groupDataAid}-title`}>
                              {formatNumberCompact(count)} {groupTitle}
                            </BigidBody1>
                          </div>
                          <div className={classes.groupContentColumn}>
                            <MetadataSearchResultsGrid
                              dataAid={`${groupDataAid}-grid`}
                              data={gridData}
                              onSearchResultEntityClick={onSearchResultEntityClick}
                            />
                            {moreResultsCount > 0 && (
                              <div className={classes.groupLink}>
                                <BigidLink
                                  dataAid={`${groupDataAid}-show-more`}
                                  underline="none"
                                  text={`Show ${formatNumberCompact(moreResultsCount)} more...`}
                                  title={`Show ${formatNumberCompact(moreResultsCount)} more...`}
                                  onClick={event => handleResultEntityGroupClick(event, type)}
                                />
                              </div>
                            )}
                          </div>
                        </div>
                      );
                    })}
                    {secondaryResults.length > 0 && (
                      <div
                        className={classNames(classes.group, classes.rowGroup, classes.noOffset)}
                        data-aid={`${dataAid}-group-other-types`}
                      >
                        <div className={classNames(classes.groupTitle, classes.rowGroupTitle)}>
                          <BigidBody1 classes={{ root: classes.groupTitleText }}>Other results types</BigidBody1>
                        </div>
                        <div className={classNames(classes.groupContentRow, classes.rowGroupContent)}>
                          {secondaryResults.map((result, index) => {
                            const { type, count } = result;
                            const entityTypeConfig = getEntityTypeConfigByTypeId(entityTypeConfigs.types, type);
                            const quantityModifier = getEntityQuantityModifierByCount(count);
                            const label = getMetadataSearchEntityTypeLabel(
                              type,
                              MetadataSearchEntityLabelMap,
                              entityTypeConfig,
                              quantityModifier,
                            );

                            return (
                              <BigidChip
                                key={index}
                                size="small"
                                shadow
                                bgColor={BigidColorsV2.white}
                                label={`${formatNumberCompact(count)} ${label}`}
                                onClick={() => onSearchResultEntityGroupClick(type)}
                              />
                            );
                          })}
                        </div>
                      </div>
                    )}
                    {hasMoreResults && (
                      <div className={classes.footer}>
                        {hasMoreResults && (
                          <SecondaryButton
                            dataAid={`${dataAid}-show-all`}
                            onClick={handleShowAllClick}
                            size="medium"
                            text="Show All Results"
                          />
                        )}
                      </div>
                    )}
                  </>
                ) : (
                  <MetadataSearchNoResultsHandler />
                )}
              </>
            )}
          </div>
        )}
        {isResultsFetchingLingers && <BigidLoader label="Searching..." labelPosition="bottom" />}
      </div>
    );
  },
);

MetadataSearchSummary.displayName = 'MetadataSearchSummary';
