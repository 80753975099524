import React, { useEffect, useState } from 'react';
import { BigidBody1, TertiaryButton } from '@bigid-ui/components';
import { BigidLikeFilledIcon, BigidLikeIcon, BigidDislikeFilledIcon, BigidDislikeIcon } from '@bigid-ui/icons';
import { generateDataAid } from '@bigid-ui/utils';
import { useLocalTranslation } from '../../../translations';
import { useTheme } from '@mui/styles';
import styled from '@emotion/styled';
import { ActionableInsightsTrackingEvents } from '../../../actionableInsightsTrackingUtil';

interface CaseRemediationVoteProps {
  platform: string;
  policyName: string;
  dataSourceType: string;
  dataAid?: string;
}

interface MainProps {
  isShown: boolean;
}

const Main = styled.div<MainProps>(({ isShown }) => ({
  display: isShown ? 'flex' : 'none',
  rowGap: '12px',
  width: 'max-content',
}));

const MainWrapper = styled.div({
  display: 'flex',
  alignItems: 'center',
  columnGap: '12px',
});

const ButtonWrapper = styled.div({
  display: 'flex',
  columnGap: '4px',
});

enum VoteVariants {
  LIKE = 'like',
  DISLIKE = 'dislike',
}

type Vote = VoteVariants.LIKE | VoteVariants.DISLIKE | undefined;

export const CaseRemediationVote = ({
  platform,
  policyName,
  dataSourceType,
  dataAid = 'RemediationStepsVote',
}: CaseRemediationVoteProps) => {
  const [voteStatus, setVoteStatus] = useState<Vote>(undefined);

  const theme = useTheme();

  const handleVoteStatus = (value: Vote) => {
    setVoteStatus(value);
  };

  const { t } = useLocalTranslation('RemediationSteps');

  useEffect(() => {
    setVoteStatus(undefined);
  }, [platform]);

  return (
    <Main isShown={Boolean(platform)}>
      <MainWrapper data-aid={generateDataAid(dataAid, ['vote', 'container'])}>
        <BigidBody1>{t('vote.title')}</BigidBody1>
        <ButtonWrapper>
          <TertiaryButton
            onClick={() => handleVoteStatus(VoteVariants.LIKE)}
            size="medium"
            text="Yes"
            startIcon={
              voteStatus === VoteVariants.LIKE ? <BigidLikeFilledIcon size="medium" /> : <BigidLikeIcon size="medium" />
            }
            iconColor={voteStatus === VoteVariants.LIKE ? theme.palette.bigid.green600 : ''}
            dataAid={generateDataAid(dataAid, [VoteVariants.LIKE])}
            bi={{
              eventType: ActionableInsightsTrackingEvents.DATA_RISK_MANAGEMENT_REMEDIATION_STEPS_FEEDBACK,
              eventData: { platform, policyName, dataSourceType, vote: VoteVariants.LIKE },
            }}
          />
          <TertiaryButton
            onClick={() => handleVoteStatus(VoteVariants.DISLIKE)}
            size="medium"
            text="No"
            startIcon={
              voteStatus === VoteVariants.DISLIKE ? (
                <BigidDislikeFilledIcon size="medium" />
              ) : (
                <BigidDislikeIcon size="medium" />
              )
            }
            iconColor={voteStatus === VoteVariants.DISLIKE ? theme.palette.bigid.red600 : ''}
            dataAid={generateDataAid(dataAid, [VoteVariants.DISLIKE])}
            bi={{
              eventType: ActionableInsightsTrackingEvents.DATA_RISK_MANAGEMENT_REMEDIATION_STEPS_FEEDBACK,
              eventData: { platform, policyName, dataSourceType, vote: VoteVariants.DISLIKE },
            }}
          />
        </ButtonWrapper>
      </MainWrapper>
    </Main>
  );
};
