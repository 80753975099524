import React, { FC, useEffect, useMemo, useRef, useState } from 'react';
import { Divider } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import {
  BigidColors,
  BigidForm,
  BigidFormField,
  BigidFormFieldLabelPosition,
  BigidFormFieldTypes,
  BigidFormStateAndHandlers,
  BigidFormValidateOnTypes,
  BigidFormValues,
  BigidLoader,
  BigidPaper,
  SecondaryButton,
} from '@bigid-ui/components';
import { TestConnectionBox, TestConnectionStatus } from './TestConnectionBox';
import {
  AdConfiguration,
  getAdConfiguration,
  syncAd,
  testAdConnection,
  updateAdConfiguration,
} from './ActiveDirectoryService';
import { notificationService } from '../../../../services/notificationService';
import { isPermitted } from '../../../../services/userPermissionsService';
import { APPLICATIONS_PERMISSIONS } from '@bigid/permissions';

const useStyles = makeStyles({
  root: {
    height: '100%',
    padding: 30,
    display: 'flex',
    justifyContent: 'center',
  },
  wrapper: {
    display: 'flex',
    height: '40%',
    flexDirection: 'row',
    maxWidth: '70vw',
    alignItems: 'center',
  },
  divider: {
    background: BigidColors.borderLight,
    margin: '0 32px 0 32px',
  },
  testConnectionContainer: {
    width: '40%',
  },
  formContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
    width: '60%',
  },
  formButtons: {
    display: 'flex',
    margin: '14px 0px',
  },
});

export const ActiveDirectorySettings: FC = () => {
  const classes = useStyles({});
  const formControls = useRef<BigidFormStateAndHandlers>();
  const [isSyncAvailable, setIsSyncAvailable] = useState<boolean>(false);
  const [allFieldsAreFilled, setAllFieldsAreFilled] = useState<boolean>(false);
  const [testInProgress, setTestInProgress] = useState<boolean>(false);
  const [isFormDirty, setIsFormDirty] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [currentAdConfiguration, setCurrentAdConfiguration] = useState<AdConfiguration>({
    name: 'default',
    appId: '',
    appTenant: '',
    appSecret: '',
  });
  const [testConnectionStatus, setTestConnectionStatus] = useState<TestConnectionStatus>(
    TestConnectionStatus.NOT_STARTED,
  );
  const [lastTestDate, setLastTestDate] = useState<string>('');

  const isEditable = useMemo(
    () => isPermitted(APPLICATIONS_PERMISSIONS.EDIT_ACTIVE_DIRECTORY_CREDENTIALS_IN_ACCESS_INTELLIGENCE.name),
    [],
  );

  useEffect(() => {
    const getCurrentAdConfiguration = async () => {
      try {
        setLoading(true);
        const adConfiguration = await getAdConfiguration();
        if (adConfiguration) {
          const { testResult, testedAt, ...rest } = adConfiguration;
          setCurrentAdConfiguration(rest);
          testResult && setTestConnectionStatus(testResult);
          testedAt && setLastTestDate(testedAt);
          setIsSyncAvailable(true);
        }
      } finally {
        setAllFieldsAreFilled(true);
        setLoading(false);
      }
    };
    getCurrentAdConfiguration();
  }, []);

  const handleFormChange = (values: BigidFormValues) => {
    setAllFieldsAreFilled(areAllFieldsFilled(values));
    const isFormDirty = checkIfFormIsDirty(values);
    setIsFormDirty(isFormDirty);
    isFormDirty ? setIsSyncAvailable(false) : setIsSyncAvailable(true);
  };

  const areAllFieldsFilled = (values: BigidFormValues) => {
    if (values) {
      return Object.values(values).every(formValue => !!formValue);
    }
    return false;
  };

  const handleTestConnection = async () => {
    try {
      setTestInProgress(true);
      const response = await testAdConnection(formControls.current.getValues() as AdConfiguration);
      if (response.data.status === 'error') {
        setTestConnectionStatus(TestConnectionStatus.FAILED);
      } else {
        setTestConnectionStatus(TestConnectionStatus.SUCCESS);
      }
    } catch ({ message }) {
      setTestConnectionStatus(TestConnectionStatus.FAILED);
    } finally {
      setLastTestDate(new Date().toString());
      setTestInProgress(false);
    }
  };

  const handleFormSubmit = async (values: BigidFormValues) => {
    try {
      await updateAdConfiguration(values as AdConfiguration);
      setCurrentAdConfiguration(values as AdConfiguration);
      setIsSyncAvailable(true);
      setTestConnectionStatus(TestConnectionStatus.NOT_STARTED);
      notificationService.success('Active Directory configuration saved');
    } catch ({ message }) {
      console.error(`An error has occurred: ${message}`);
      notificationService.error('An error has occurred');
    }
  };

  const syncActiveDirectory = async () => {
    try {
      await syncAd();
      notificationService.success('Active Directory sync started');
    } catch ({ message }) {
      console.error(`An error has occurred: ${message}`);
      notificationService.error('An error has occurred');
    }
  };

  const checkIfFormIsDirty = (formValues: BigidFormValues) => {
    return JSON.stringify(formValues) !== JSON.stringify(currentAdConfiguration);
  };

  const fields: BigidFormField[] = useMemo(() => {
    return [
      {
        type: BigidFormFieldTypes.TEXT,
        labelPosition: BigidFormFieldLabelPosition.left,
        name: 'appId',
        label: 'App ID',

        misc: {
          fullWidth: true,
        },
        validate: (value: string) => {
          if (!value) {
            return 'Please enter client ID';
          }
          return false;
        },
        disabled: !isEditable,
      },
      {
        type: BigidFormFieldTypes.TEXT,
        validateOn: BigidFormValidateOnTypes.CHANGE,
        labelPosition: BigidFormFieldLabelPosition.left,
        name: 'appTenant',
        label: 'Tenant ID',
        misc: {
          fullWidth: true,
        },
        validate: (value: string) => {
          if (!value) {
            return 'Please enter tenant ID';
          }
          return false;
        },
        disabled: !isEditable,
      },
      {
        type: BigidFormFieldTypes.PASSWORD,
        labelPosition: BigidFormFieldLabelPosition.left,
        name: 'appSecret',
        label: 'Secret',
        misc: {
          fullWidth: true,
        },

        validate: (value: string) => {
          if (!value) {
            return 'Please enter secret';
          }
          return false;
        },
        disabled: !isEditable,
      },
    ];
  }, [isEditable]);

  if (loading) {
    return <BigidLoader />;
  }

  return (
    <BigidPaper classes={{ root: classes.root }}>
      <div className={classes.wrapper}>
        <div className={classes.formContainer}>
          <BigidForm
            stateAndHandlersRef={formControls}
            onChange={handleFormChange}
            onSubmit={handleFormSubmit}
            controlButtons={false}
            fields={fields}
            initialValues={currentAdConfiguration}
          />
          <div className={classes.formButtons}>
            <SecondaryButton
              dataAid={'ActiveDirectorySaveButton'}
              margin={'0px 10px 0px 0px'}
              onClick={formControls?.current?.submit}
              disabled={!allFieldsAreFilled || !isFormDirty || !isEditable}
              size="medium"
              text="Save"
            />
            <SecondaryButton
              dataAid={'ActiveDirectorySyncButton'}
              onClick={syncActiveDirectory}
              disabled={!isSyncAvailable || testConnectionStatus !== TestConnectionStatus.SUCCESS || !isEditable}
              size="medium"
              text="Sync"
            />
          </div>
        </div>
        <Divider variant="middle" className={classes.divider} orientation="vertical" />
        <div className={classes.testConnectionContainer}>
          <TestConnectionBox
            lastTestDate={new Date(lastTestDate).toLocaleString('en-US')}
            onTest={handleTestConnection}
            isTestAvailable={allFieldsAreFilled && isSyncAvailable}
            testInProgress={testInProgress}
            testStatus={testConnectionStatus}
          />
        </div>
      </div>
    </BigidPaper>
  );
};
