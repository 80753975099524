import { BigidSelectOption } from '@bigid-ui/components';

export interface SystemUser {
  firstName?: string;
  lastName?: string;
  name: string;
  email?: string;
  id: string;
  origin?: string;
}

export interface SystemUsersOptionsState {
  options: BigidSelectOption[];
  optionsPromise: Promise<{ data: { users: SystemUser[] } }> | null;
  isLoading: boolean;
}

export const naiveCache: SystemUsersOptionsState = {
  options: [],
  optionsPromise: null,
  isLoading: true,
};

export const resetDsWizardUsersCache = () => {
  naiveCache.options = [];
  naiveCache.optionsPromise = null;
  naiveCache.isLoading = true;
};
