import React, { FC, ReactNode } from 'react';
import { styled } from '@mui/material';
import {
  BigidPieChart,
  BigidPieChartProps,
  PieChartData,
  BigidLoader,
  BigidTooltip,
  BigidBody1,
  PieChartSizeTypes,
} from '@bigid-ui/components';
import { generateDataAid } from '@bigid-ui/utils';
import { PieChartLegend } from './PieChartLegend';
import { AggregationItemBase } from '../../catalogDiscoveryTypes';
import { omit } from 'lodash';
import { useLocalTranslation } from './translations';

export type PieChartWidgetSeries<ItemType = AggregationItemBase> = PieChartData<string> & { aggItem: ItemType };

export interface PieChartWidgetProps<ItemType = AggregationItemBase>
  extends Pick<BigidPieChartProps, 'entityName' | 'total' | 'onSectorClick'> {
  dataAid?: string;
  dataTourId?: string;
  height?: string;
  width?: string;
  data: PieChartWidgetSeries<ItemType>[];
  tooltipText?: ReactNode;
  isBusy?: boolean;
  onLegendItemClick?: (item: PieChartWidgetSeries<ItemType>) => void;
  size?: PieChartSizeTypes;
  legendOffset?: number;
}

const pieWidthMap: Record<PieChartSizeTypes, number> = {
  lg: 88,
  xl: 136,
};

const Root = styled('div')<Pick<PieChartWidgetProps, 'height' | 'width'>>`
  display: flex;
  align-items: center;
  justify-content: center;
  height: ${({ height }) => height};
  width: ${({ width }) => width};
`;

const Title = styled(BigidBody1)`
  font-weight: bold;
  margin-bottom: 16px;
`;

const ChartWrapper = styled('div')<
  {
    isTransparent?: boolean;
  } & Pick<PieChartWidgetProps, 'size'>
>(({ isTransparent, size }) => {
  return `
  display: flex;
  flex-direction: column;
  align-items: center;
  width: ${pieWidthMap[size] ?? 136}px;
  height: 100%;
  position: relative;
    ${isTransparent && 'opacity: 0;'}
  `;
});

const LegendWrapper = styled('div')<Pick<PieChartWidgetProps, 'legendOffset'>>`
  display: flex;
  margin-left: 24px;
  height: 100%;
  max-width: 150px; //NOTE: completely random value, no requirements from UX
  overflow: hidden;
  align-items: center;
  ${({ legendOffset }) => legendOffset && `padding-top: ${legendOffset}px;`}
`;

const spinnerSize = 42;
const spinnerThickness = 6;

export const PieChartWidget: FC<PieChartWidgetProps> = ({
  dataAid = 'PieChartsWidget',
  dataTourId = 'PieChartsWidget',
  height = '100%',
  width = '100%',
  data = [],
  entityName,
  total,
  onSectorClick,
  onLegendItemClick,
  tooltipText,
  isBusy,
  size = 'xl',
  legendOffset,
}) => {
  const { t } = useLocalTranslation();
  const isNoDataShown = data.length === 0;

  return (
    <Root data-aid={dataAid} data-tour-id={dataTourId} height={height} width={width}>
      <ChartWrapper data-aid={generateDataAid(dataAid, ['chart-wrapper'])}>
        <BigidTooltip title={tooltipText} isDisabled={!tooltipText}>
          <Title data-aid={generateDataAid(dataAid, ['entity-name'])}>{entityName}</Title>
        </BigidTooltip>
        <ChartWrapper isTransparent={isBusy} size={size}>
          {isBusy ? (
            <BigidLoader
              data-aid={generateDataAid(dataAid, ['chart-loader'])}
              size={spinnerSize}
              thickness={spinnerThickness}
            />
          ) : (
            <BigidPieChart
              total={total}
              data={data.map(series => omit(series, ['active']))}
              showZeroedData
              showZeroedTotal
              shouldUseSpaceInTotal={false}
              onSectorClick={onSectorClick}
              withTooltips
              size={size}
            />
          )}
        </ChartWrapper>
      </ChartWrapper>
      <LegendWrapper legendOffset={legendOffset}>
        {isNoDataShown ? (
          <BigidBody1>{t('noData')}</BigidBody1>
        ) : (
          <PieChartLegend
            dataAid={generateDataAid(dataAid, ['legend'])}
            data={data}
            onLegendItemClick={onLegendItemClick}
            isBusy={isBusy}
          />
        )}
      </LegendWrapper>
    </Root>
  );
};
