import { BigidLoader, BigidSearch } from '@bigid-ui/components';
import { BigidGrid, BigidGridColumn, BigidGridColumnTypes } from '@bigid-ui/grid';
import { styled } from '@mui/material';

import React, { useEffect, useMemo, useState } from 'react';
import { AttributesGridColumn, getAttributesGridData, getHighlightedElement } from '../../utils';
import { useGetObjectDetails } from '../hooks/useGetObjectDetails';
import { DataCatalogAttribute } from '../../../../../../DataCatalog/DataCatalogAttributes';
import { CatalogSidePanelProps } from '../CatalogSidePanel';
import { noop } from 'lodash';
import { useLocalTranslation } from '../../../../../translations';
import { GridRowComponent } from './GridRowComponent';

const Root = styled('div')`
  display: flex;
  flex-direction: column;
  position: relative;
  flex-grow: 1;
  height: 100%;
`;

const SearchContainer = styled('div')`
  padding: 8px;
  border-bottom: 1px solid ${({ theme }) => theme.vars.tokens.bigid.borderDefault};
`;

type AttributesGridProps = CatalogSidePanelProps & {
  selectedAttribute: DataCatalogAttribute;
  setSelectedAttribute: (attribute: DataCatalogAttribute) => void;
  attributesData: DataCatalogAttribute[];
  isLoading: boolean;
};

export const AttributesGrid = ({
  selectedItem,
  highlightValue,
  selectedAttribute,
  setSelectedAttribute,
  attributesData,
  isLoading,
}: AttributesGridProps) => {
  const [searchValue, setSearchValue] = useState(highlightValue);
  const [attributes, setAttributes] = useState<AttributesGridColumn[]>([]);
  const { id } = selectedItem;
  const fqn = String(id);
  const { data: objectDetailsData } = useGetObjectDetails(fqn);

  const { t } = useLocalTranslation('sidePanel.attributes.grid');
  const gridData = useMemo(
    () => getAttributesGridData(attributesData, objectDetailsData?.data),
    [attributesData, objectDetailsData],
  );

  useEffect(() => {
    if (attributesData.length > 0 && highlightValue) {
      setSelectedAttribute(attributesData[0]);
    }
  }, [attributesData, highlightValue, setSelectedAttribute]);

  const gridColumns: BigidGridColumn<AttributesGridColumn>[] = [
    {
      name: 'attribute_name',
      title: 'Attribute',
      getCellValue: ({ attribute_name, numberOfFindings, id }) => {
        return (
          <GridRowComponent
            name={getHighlightedElement(attribute_name, searchValue, 'body')}
            count={numberOfFindings}
            isSelected={id === selectedAttribute?.attribute_id}
          />
        );
      },

      type: BigidGridColumnTypes.CUSTOM,
      width: 436, // For some reason, there is no way to display only one column, so I have to set the width to 436
    },
  ];

  const handleSearchChange = (searchText: string) => {
    setSearchValue(searchText);
  };

  useEffect(() => {
    if (searchValue) {
      const attributesToDisplay = gridData.filter(({ attribute_name }) =>
        attribute_name.toLowerCase().includes(searchValue.toLowerCase()),
      );
      setAttributes(attributesToDisplay);
    } else {
      setAttributes(gridData);
    }
  }, [searchValue, gridData]);

  const handleRowClick = (row: AttributesGridColumn) => {
    const selectedAttribute = attributesData.find(({ attribute_id }) => attribute_id === row.id);
    setSelectedAttribute(selectedAttribute);
  };

  return (
    <Root>
      {isLoading ? (
        <BigidLoader position="absolute" />
      ) : (
        <>
          <SearchContainer>
            <BigidSearch
              placeholder={t('emptySearchPlaceholder', { count: attributes.length })}
              onSubmit={handleSearchChange}
              value={searchValue}
              size="large"
            />
          </SearchContainer>

          <BigidGrid
            columns={gridColumns}
            rows={attributes}
            onPagingChanged={noop}
            totalRowsCount={attributes.length}
            rowClickShouldKeepSelection
            showSelectionColumn={false}
            gridId="attributes"
            pageSize={attributes.length}
            selectedRowIds={[selectedAttribute?.attribute_id]}
            onRowClick={handleRowClick}
            hideHeadersRow
          />
        </>
      )}
    </Root>
  );
};
