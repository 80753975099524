import React, { FC, useState, useEffect, useMemo, useCallback, ChangeEvent } from 'react';
import {
  BigidDialog,
  PrimaryButton,
  SecondaryButton,
  ExtraFieldItemData,
  BigidColors,
  BigidBody1,
} from '@bigid-ui/components';
import makeStyles from '@mui/styles/makeStyles';
import { TagsManagementGridRecordExtended } from '../TagsManagement';
import { addTag, editTag, EditTagPayload, EditTagPayloadValues } from './EditTagDialogService';
import { EditTagDialogTextField } from './EditTagDialogTextField';
import { EditTagDialogCheckboxField } from './EditTagDialogCheckboxField';
import { EditTagDialogValuesFieldSet } from './EditTagDialogValuesFieldSet';
import { isPermitted } from '../../../services/userPermissionsService';
import { TAGS_PERMISSIONS } from '@bigid/permissions';
import classNames from 'classnames';
import { deleteTag } from '../TagsManagementService';
import { validateTag } from '../TagsManagementUtils';
import { showConfirmationDialog } from '../../../services/confirmationDialogService';
import { notificationService } from '../../../services/notificationService';
import { sample } from 'lodash';

export interface EditTagDialogSubmitData {
  tag?: TagsManagementGridRecordExtended;
  shouldGridReload?: boolean;
}

export interface EditTagDialogProps {
  title: string;
  tag?: TagsManagementGridRecordExtended;
  isOpen: boolean;
  onClose?: () => void;
  onSubmit?: (data: EditTagDialogSubmitData) => void;
}

const useStyles = makeStyles({
  root: {
    width: '100%',
    height: '100%',
  },
  fieldset: {
    width: '100%',
  },
  tagName: {},
  tagMutuallyExclusive: {
    marginBottom: '15px',
  },
  tagValue: {
    maxHeight: '430px',
    overflowY: 'auto',
  },
  fieldsetError: {
    marginTop: '5px',
    color: BigidColors.failureRed,
  },
});

const HTTP_400_SAME_NAME_ERROR = 'this tag-name already exist';
const TAG_NAME_REQUIREMENTS_TIP_TEXT = `
    Tag name must comprise of the following characters:
    A-Z, a-z, 0-9, underscore (_), dot (.), dash (-), colons (:) and space.
    A tag name cannot start with a special character and cannot start or end up with space.
  `;

const getIsTagNameUniqueAmongValues = (
  name: TagsManagementGridRecordExtended['tagName'],
  value: ExtraFieldItemData[],
) => {
  return !value.map(({ value }) => value.trim().toLowerCase()).includes(name.trim().toLowerCase());
};

const getIsTagNameValid = (name: TagsManagementGridRecordExtended['tagName'], value: ExtraFieldItemData[]) => {
  const { isTagNameValueValid } = validateTag;

  const isNameUniqueAmongValues = getIsTagNameUniqueAmongValues(name, value);
  const isNameValidString = isTagNameValueValid(name);

  return isNameUniqueAmongValues && isNameValidString;
};

const getIsTagValueValid = (value: ExtraFieldItemData[]) => {
  const { isTagNameValueValid } = validateTag;
  const values = value.map(({ value }) => value);

  const areValuesUnique = values.length === new Set(values).size;
  const areValuesValid = values.every(value => isTagNameValueValid(value));

  return areValuesUnique && areValuesValid;
};

const getTagValueHasDuplicates = (tagValue: ExtraFieldItemData, values: ExtraFieldItemData[]): boolean => {
  return values.some(({ id, value }) => value.trim() === tagValue.value.trim() && id !== tagValue.id);
};

const getIsFormValid = (name: TagsManagementGridRecordExtended['tagName'], value: ExtraFieldItemData[]) => {
  return getIsTagNameValid(name, value) && getIsTagValueValid(value);
};

export const EditTagDialog: FC<EditTagDialogProps> = ({ title, tag, isOpen, onClose, onSubmit }) => {
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [tagStated, setTagStated] = useState<TagsManagementGridRecordExtended>();
  const [tagNameCurrent, setTagNameCurrent] = useState<TagsManagementGridRecordExtended['tagName']>('');
  const [tagValueCurrent, setTagValueCurrent] = useState<ExtraFieldItemData[]>([]);
  const [isTagMutuallyExclusive, setIsTagMutuallyExclusive] = useState<boolean>(false);
  const [tagNameErrorMessage, setTagNameErrorMessage] = useState<string>();

  const { isDeleteTagPermitted } = useMemo(
    () => ({
      isDeleteTagPermitted: isPermitted(TAGS_PERMISSIONS.DELETE.name),
    }),
    [],
  );

  useEffect(() => {
    if (tag && isOpen) {
      const { tagName, tagValues, isMutuallyExclusive } = tag;
      setTagNameCurrent(tagName);
      setTagValueCurrent(
        tagValues.length > 0
          ? tagValues.map(({ tagValue, valueId }, index) => ({
              id: valueId,
              label: `Value ${index + 1}`,
              valueId,
              value: tagValue,
              errorMessage: undefined,
            }))
          : [{ id: '0', value: '', label: 'Value 1', errorMessage: undefined, valueId: undefined }],
      );
      setTagStated(tag);
      setIsTagMutuallyExclusive(isMutuallyExclusive);
    } else {
      setTagValueCurrent([{ id: '0', value: '', label: 'Value 1', errorMessage: undefined, valueId: undefined }]);
    }
  }, [tag, isOpen]);

  const validateForm = useCallback(() => {
    const { isTagNameValueNotEmpty, isTagNameValueValid } = validateTag;
    setTagNameErrorMessage(() => {
      const errorMessagesPool: string[] = [];

      if (!isTagNameValueNotEmpty(tagNameCurrent)) {
        errorMessagesPool.push('Tag must have a name');
      } else {
        if (!getIsTagNameUniqueAmongValues(tagNameCurrent, tagValueCurrent)) {
          errorMessagesPool.push('Tag name cannot be equal to value');
        }

        if (!isTagNameValueValid(tagNameCurrent)) {
          errorMessagesPool.push('Invalid tag name');
        }
      }

      return sample(errorMessagesPool);
    });
    setTagValueCurrent(prevTagValuesCurrent =>
      prevTagValuesCurrent.map((tagValue, _index, self) => {
        let errorMessage;

        if (!isTagNameValueNotEmpty(tagValue.value)) {
          errorMessage = 'Tag value cannot be empty';
        } else {
          if (getTagValueHasDuplicates(tagValue, self)) {
            errorMessage = 'Tag value must be unique';
          }

          if (!isTagNameValueValid(tagValue.value)) {
            errorMessage = 'Invalid tag value';
          }
        }

        return {
          ...tagValue,
          errorMessage,
        };
      }),
    );
  }, [tagNameCurrent, tagValueCurrent]);

  const handleOnAdd = useCallback(async () => {
    try {
      const isFormValid = getIsFormValid(tagNameCurrent, tagValueCurrent);

      validateForm();

      if (isFormValid) {
        setIsLoading(true);

        const payload: EditTagPayload = {
          tagName: tagNameCurrent,
          tagValues: {
            newTagValues: tagValueCurrent,
          },
          isMutuallyExclusive: isTagMutuallyExclusive,
        };

        await addTag(payload);

        onSubmit({ shouldGridReload: true });
        resetState();
      }
    } catch ({ message, response }) {
      let notificationMessage;

      if (response?.data?.message === HTTP_400_SAME_NAME_ERROR) {
        notificationMessage = 'A tag with this name already exists';
        setTagNameErrorMessage(notificationMessage);
      } else {
        notificationMessage = 'An error has occurred';
      }

      notificationService.error(notificationMessage);
      console.error(`An error has occurred: ${message}`);
    } finally {
      setIsLoading(false);
    }
  }, [isTagMutuallyExclusive, onSubmit, tagNameCurrent, tagValueCurrent, validateForm]);

  const handleOnSave = useCallback(async () => {
    try {
      const isFormValid = getIsFormValid(tagNameCurrent, tagValueCurrent);

      validateForm();

      if (isFormValid) {
        setIsLoading(true);

        const { tagId, tagValues } = tagStated;
        const tagValuesToProcess: EditTagPayloadValues = {};

        tagValuesToProcess.newTagValues = tagValueCurrent.filter(({ valueId }) => !valueId);
        tagValuesToProcess.deletedTagValues = tagValues
          .filter(tagValue => !tagValueCurrent.map(({ valueId }) => valueId).includes(tagValue.valueId))
          .map(({ valueId, tagValue }) => ({ id: valueId, value: tagValue, valueId }));
        tagValuesToProcess.updatedTagValues = tagValueCurrent.filter(({ valueId, value }) => {
          const existedTagValue = tagValues.find(value => value.valueId === valueId);

          if (existedTagValue) {
            return (
              existedTagValue.tagValue !== value &&
              !tagValuesToProcess.deletedTagValues.map(({ valueId }) => valueId).includes(valueId)
            );
          } else {
            return false;
          }
        });

        const payload: EditTagPayload = {
          tagId,
          tagName: tagNameCurrent,
          tagValues: tagValuesToProcess,
          isMutuallyExclusive: isTagMutuallyExclusive,
          shouldUpdateTag:
            tagNameCurrent !== tagStated.tagName || isTagMutuallyExclusive !== tagStated.isMutuallyExclusive,
        };

        await editTag(payload);
        onSubmit({ shouldGridReload: true });
        resetState();
      }
    } catch ({ message, response }) {
      let notificationMessage;

      if (response?.data?.message === HTTP_400_SAME_NAME_ERROR) {
        notificationMessage = 'A tag with this name already exists';
        setTagNameErrorMessage(notificationMessage);
      } else {
        notificationMessage = 'An error has occurred';
      }

      notificationService.error(notificationMessage);
      console.error(`An error has occurred: ${message}`);
    } finally {
      setIsLoading(false);
    }
  }, [isTagMutuallyExclusive, onSubmit, tagNameCurrent, tagStated, tagValueCurrent, validateForm]);

  const handleOnDelete = useCallback(async () => {
    try {
      const isTagDeletionConfirmed = await showConfirmationDialog({
        entityNameSingular: 'Tag',
        actionName: 'Delete',
      });

      if (isTagDeletionConfirmed) {
        setIsLoading(true);
        const { tagId } = tagStated;
        await deleteTag(tagId);
        onSubmit({ shouldGridReload: true });
        resetState();
      }
    } catch ({ message }) {
      notificationService.error('An error has occurred');
      console.error(`An error has occurred: ${message}`);
    } finally {
      setIsLoading(false);
    }
  }, [onSubmit, tagStated]);

  const handleOnClose = useCallback((): void => {
    onClose();
    resetState();
  }, [onClose]);

  const handleTagNameChange = (tagName: string): void => {
    setTagNameCurrent(tagName);
  };

  const handleTagValueCreate = useCallback((tagValues: ExtraFieldItemData[]) => {
    const id = tagValues.length + 1;
    return { id: id.toString(), value: '', label: `Value ${id}`, valueId: undefined };
  }, []);

  const handleTagValueChange = (tagValues: ExtraFieldItemData[]): void => {
    setTagValueCurrent(preTagValuesCurrent => {
      return tagValues.map((value, index) => {
        const prevValue = preTagValuesCurrent.find(({ id }) => id === value.id);
        return {
          ...value,
          label: `Value ${index + 1}`,
          errorMessage: prevValue ? prevValue.errorMessage : value.errorMessage,
        };
      });
    });
  };

  const handleOnMutuallyExclusiveChange = useCallback(
    (_event: ChangeEvent<HTMLInputElement>, checked: boolean): void => {
      setIsTagMutuallyExclusive(checked);
    },
    [],
  );

  const resetState = (): void => {
    setTagNameCurrent('');
    setTagNameErrorMessage(undefined);
    setTagValueCurrent([]);
    setIsTagMutuallyExclusive(false);
  };

  const dialogButtons = useMemo(() => {
    const buttons = [
      {
        component: SecondaryButton,
        onClick: handleOnClose,
        text: 'Cancel',
      },
    ];

    if (tag) {
      isDeleteTagPermitted &&
        buttons.splice(1, 0, {
          component: SecondaryButton,
          onClick: handleOnDelete,
          text: 'Delete',
        });

      buttons.push({
        component: PrimaryButton,
        onClick: handleOnSave,
        text: 'Save',
      });
    } else {
      buttons.push({
        component: PrimaryButton,
        onClick: handleOnAdd,
        text: 'Add',
      });
    }

    return buttons;
  }, [tag, isDeleteTagPermitted, handleOnAdd, handleOnSave, handleOnDelete, handleOnClose]);

  return (
    <BigidDialog
      borderTop
      title={title}
      isOpen={isOpen}
      onClose={handleOnClose}
      buttons={dialogButtons}
      isLoading={isLoading}
      maxWidth="sm"
    >
      <div className={classes.root} data-aid="EditTagDialog">
        <div className={classNames(classes.fieldset, classes.tagName)}>
          <EditTagDialogTextField
            value={tagNameCurrent}
            label="Tag Name"
            onChange={handleTagNameChange}
            isShorten
            tip={TAG_NAME_REQUIREMENTS_TIP_TEXT}
            errorMessage={tagNameErrorMessage}
          />
        </div>
        <div className={classNames(classes.fieldset, classes.tagMutuallyExclusive)}>
          <EditTagDialogCheckboxField
            label={<BigidBody1>Mutually Exclusive</BigidBody1>}
            checked={isTagMutuallyExclusive}
            onChange={handleOnMutuallyExclusiveChange}
            isShorten
          />
        </div>
        <div className={classNames(classes.fieldset, classes.tagValue)}>
          <EditTagDialogValuesFieldSet
            values={tagValueCurrent}
            onCreate={handleTagValueCreate}
            onChange={handleTagValueChange}
          />
        </div>
      </div>
    </BigidDialog>
  );
};
