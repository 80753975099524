import { ReactText } from 'react';

const DIVIDER = '_||_';

export const sourceToNodeId = (source: string) => {
  return source;
};

export const nodeIdToSource = (nodeId: ReactText) => {
  return nodeId as string;
};

export const attributeNameToPortId = (source: string, attributeOriginalName: string) => {
  const fieldId = `${source}${DIVIDER}${attributeOriginalName}`;
  return fieldId;
};

export const portIdToAttributeOriginalName = (portId: ReactText) => {
  const attributeOriginalName = `${portId}`.split(DIVIDER)[1];
  return attributeOriginalName;
};

export const getLinkId = (source: string, fromAttributeName: string, toAttributeName: string) => {
  return `${source}${DIVIDER}${fromAttributeName}${DIVIDER}${toAttributeName}`;
};
