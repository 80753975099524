import React, { FC } from 'react';
import { styled } from '@mui/material';
import { BigidBody1, BigidColorsV2 } from '@bigid-ui/components';
import { BigidPreviewIcon } from '@bigid-ui/icons';
import { generateDataAid } from '@bigid-ui/utils';
import { useLocalTranslation } from '../../translations';

const ContainerImages = styled('div')`
  display: flex;
  align-items: center;
  gap: 6px;
  margin-bottom: 12px;
  background: ${BigidColorsV2.gray[150]};
  width: max-content;
  border-radius: 5px;
  padding: 8px;
`;

interface ViewScanProfileContentProps {
  dataAid?: string;
}

export const ViewScanProfileContent: FC<ViewScanProfileContentProps> = ({ dataAid = 'ViewScanProfileContent' }) => {
  const { t } = useLocalTranslation('guidedTour.plannedScans.viewScanProfile');
  return (
    <div data-aid={generateDataAid(dataAid, ['container'])}>
      <ContainerImages>
        <BigidPreviewIcon />
      </ContainerImages>
      <BigidBody1>{t('content')}</BigidBody1>
    </div>
  );
};
