import React, { ReactNode, PropsWithChildren, FC } from 'react';
import { DataSourceConnectionDialogSlotComponentProps, mapNodeTypeToComponentSlot } from '../mappers/dialog';
import { DataSourceConnectionDialogHeader } from './DataSourceConnectionDialogHeader';
import { DataSourceConnectionDialogFooter } from './DataSourceConnectionDialogFooter';
import { DataSourceConnectionDialogContent } from './DataSourceConnectionDialogContent';
import { DataSourceConnectionDialogAside } from './DataSourceConnectionAside';
import { Aside, Content, Dialog, Footer, Header, Main, Overlay } from './DataSourceConnectionDialogMainStyles';
import { DialogSlots } from '../DataSourceConfiguration/types';
import {
  DataSourceConnectionDialogContextType,
  DataSourceConnectionDialogProvider,
} from './DataSourceConnectionDialogProvider';

type DataSourceConnectionDialogProps = {
  overlay: ReactNode;
  config?: DataSourceConnectionDialogContextType;
  layout?: DataSourceConnectionDialogLayout;
  showSidebar?: boolean;
} & PropsWithChildren;

export enum DataSourceConnectionDialogLayout {
  SIMPLE = 'simple',
  FULL = 'full',
  STANDALONE = 'standalone'
}

const componentSlotMapper: Record<DialogSlots, FC<DataSourceConnectionDialogSlotComponentProps>> = {
  [DialogSlots.HEADER]: DataSourceConnectionDialogHeader,
  [DialogSlots.FOOTER]: DataSourceConnectionDialogFooter,
  [DialogSlots.CONTENT]: DataSourceConnectionDialogContent,
  [DialogSlots.ASIDE]: DataSourceConnectionDialogAside,
};

export const DataSourceConnectionDialog = ({
  children,
  overlay,
  config,
  layout = DataSourceConnectionDialogLayout.FULL,
  showSidebar = true,
}: DataSourceConnectionDialogProps): JSX.Element => {
  const [header, footer, content, aside] = [
    DialogSlots.HEADER,
    DialogSlots.FOOTER,
    DialogSlots.CONTENT,
    DialogSlots.ASIDE,
  ].map(slot => mapNodeTypeToComponentSlot(children, slot, componentSlotMapper));

  const isSimpleLayout = layout === DataSourceConnectionDialogLayout.SIMPLE;
  const isStandaloneLayout = layout === DataSourceConnectionDialogLayout.STANDALONE

  return (
    <Dialog data-aid="DataSourceConnectionDialog">
      <DataSourceConnectionDialogProvider config={config}>
        <Main showSidebar={showSidebar} isSimple={isSimpleLayout}>
          <Header>{header}</Header>
          <Content showSidebar={showSidebar} className="DataSourceConnectionDialog-content">{content}</Content>
          {!isStandaloneLayout && <Footer>{footer}</Footer>}
        </Main>
        {showSidebar && !isSimpleLayout && <Aside>{aside}</Aside>}
        {overlay && <Overlay>{overlay}</Overlay>}
      </DataSourceConnectionDialogProvider>
    </Dialog>
  );
};

DataSourceConnectionDialog.Header = DataSourceConnectionDialogHeader;
DataSourceConnectionDialog.Footer = DataSourceConnectionDialogFooter;
DataSourceConnectionDialog.Aside = DataSourceConnectionDialogAside;
DataSourceConnectionDialog.Content = DataSourceConnectionDialogContent;
