import React, { FC } from 'react';
import { BigidBody1 } from '@bigid-ui/components';
import styled from '@emotion/styled';
import { showConfirmationDialog } from '../../../services/confirmationDialogService';
import { useLocalTranslation } from '../translations';
import { generateDataAid } from '@bigid-ui/utils';

const DialogContainer = styled('div')`
  display: grid;
  gap: 16px;
`;

interface DuplicateTemplateWarningProps {
  templateName: string;
  count: number;
  dataAid?: string;
}

const DuplicateTemplateWarning: FC<DuplicateTemplateWarningProps> = ({
  templateName,
  count,
  dataAid = 'DuplicateTemplateWarning',
}) => {
  const { t } = useLocalTranslation('duplicateScanTemplate');
  const foundTemplateText = `${templateName} ${count > 1 ? `and ${count - 1} more` : ''}`;
  return (
    <DialogContainer data-aid={generateDataAid(dataAid, ['container'])}>
      <BigidBody1 data-aid={generateDataAid(dataAid, ['content'])}>{t('content')}</BigidBody1>
      <BigidBody1 style={{ fontWeight: 700 }} data-aid={generateDataAid(dataAid, ['templateName'])}>
        {foundTemplateText}
      </BigidBody1>
    </DialogContainer>
  );
};

export const openDuplicateTemplateWarningDialog = async (matchScanTemplateName: string, countMatching: number) => {
  return showConfirmationDialog({
    entityNameSingular: 'Template',
    actionName: 'Duplicate',
    actionButtonName: 'Create duplicate',
    ContentComponent: <DuplicateTemplateWarning templateName={matchScanTemplateName} count={countMatching} />,
  });
};
