import React, { FC, ReactText } from 'react';
import { BigidBody1, BigidHeading6 } from '@bigid-ui/components';
import makeStyles from '@mui/styles/makeStyles';
import { generateDataAid } from '@bigid-ui/utils';

const useStyles = makeStyles({
  dataText: {
    display: 'flex',
    textAlign: 'center',
  },
  dataContent: {
    display: 'flex',
    alignItems: 'baseline',
  },
  divider: {
    margin: '0 10px',
  },
  data: {
    marginRight: 6,
  },
  title: {
    marginRight: 6,
  },
});

export interface DataSnippetType {
  title: string;
  data: ReactText;
  color?: 'inherit' | 'initial' | 'primary' | 'secondary' | 'textPrimary' | 'textSecondary' | 'error';
  isTitleFirst?: boolean;
}

interface DataSnippetProps {
  dataSnippets: DataSnippetType[];
}

export const DataSnippet: FC<DataSnippetProps> = ({ dataSnippets = [] }) => {
  const classes = useStyles({});
  return (
    <div className={classes.dataText}>
      {dataSnippets.map(({ data, title, color, isTitleFirst }, index) => {
        return (
          <div key={index} className={classes.dataContent}>
            {isTitleFirst ? (
              <>
                <BigidBody1 color={color} className={classes.title}>
                  {title}
                </BigidBody1>
                <BigidHeading6 className={classes.data}>{data}</BigidHeading6>
              </>
            ) : (
              <>
                <BigidHeading6 className={classes.data} data-aid={generateDataAid('DataSnippet-data', [title])}>
                  {data}
                </BigidHeading6>
                <BigidBody1 color={color}>{title}</BigidBody1>
              </>
            )}
            {index < dataSnippets.length - 1 && <BigidBody1 className={classes.divider}>{`|`}</BigidBody1>}
          </div>
        );
      })}
    </div>
  );
};
