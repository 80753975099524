import React, { FC, memo } from 'react';
import {
  BigidFormFieldSideLabelWrapper,
  compareObjectsExceptFunctions,
  BigidFieldRenderProps,
  BigidTags,
  BigidLoader,
} from '@bigid-ui/components';
import { validateTagNameWithMessage, validateTagValueWithMessage } from '../../../TagsManagement/TagsManagementUtils';
import { useTagsFieldData } from '../hooks/useTagsFieldData';

const DEFAULT_ADD_PLACEHOLDER = 'Click the + to add tag';

export const FormTagsField: FC<BigidFieldRenderProps> = memo(
  ({ setValue, name, label = '', value, misc, isRequired, labelWidth, tooltipText, disabled }) => {
    const {
      tags,
      isLoading,
      handleTagCreate,
      handleTagAttach,
      handleTagDetach,
      isReadTagsPermitted,
      systemTagsDictionary,
      isCreateTagsPermitted,
    } = useTagsFieldData({ value, setValue });

    return isLoading ? (
      <BigidLoader />
    ) : (
      <BigidFormFieldSideLabelWrapper
        id={`bigid-form-field-${name}`}
        name={name}
        label={String(label)}
        isSeparatorAfter={misc?.isSeparatorAfter}
        labelWidth={labelWidth}
        tooltipText={tooltipText}
        isRequired={isRequired}
      >
        <BigidTags
          tags={tags}
          tagWizardMenuPosition="absolute"
          tagsDictionary={systemTagsDictionary}
          onCreate={handleTagCreate}
          onAdd={handleTagAttach}
          onDelete={handleTagDetach}
          isAbleToCreate={isCreateTagsPermitted}
          isAbleToEdit={!disabled && isReadTagsPermitted}
          isAbleToDelete={!disabled}
          placeholder={misc?.placeholder ?? DEFAULT_ADD_PLACEHOLDER}
          useMinHeight
          validateName={validateTagNameWithMessage}
          validateValue={validateTagValueWithMessage}
        />
      </BigidFormFieldSideLabelWrapper>
    );
  },
  compareObjectsExceptFunctions,
);

FormTagsField.displayName = 'FormTagsField';
