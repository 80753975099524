import React, { FC } from 'react';
import styled from '@emotion/styled';
import { BigidBody1, BigidIconType } from '@bigid-ui/components';

const Container = styled('div')`
  display: flex;
  flex-direction: column;
  height: calc(100% - 42px);
  width: 100%;
  position: absolute;
  justify-content: center;
  align-items: center;
`;

interface NoDataContentProps {
  icon: BigidIconType;
  message: string;
  dataAid?: string;
}

export const NoDataContent: FC<NoDataContentProps> = ({ icon: Icon, message, dataAid = 'NoDataContent' }) => {
  return (
    <Container data-aid={dataAid}>
      <Icon />
      <BigidBody1>{message}</BigidBody1>
    </Container>
  );
};
