import React, { FC, useContext } from 'react';
import { FmsdCategories } from '../index';
import { PreDiscoverContext } from '../../hooks';
import { fmsdCustomizeViews } from '../FmsdPreDiscoverViews';

export const FmsdCustomize: FC = () => {
  const {
    selectionsData: { classifiersMethodology },
  } = useContext(PreDiscoverContext);

  const View = fmsdCustomizeViews[classifiersMethodology] || FmsdCategories;

  return <View />;
};
