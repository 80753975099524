import angular from 'angular';
import React, { FunctionComponent, useCallback, useState } from 'react';
import {
  BigidScheduleEventPicker,
  BigidScheduleEventPickerProps,
  ScheduledIdentifierOptions,
  BigidBody2,
  BigidColors,
} from '@bigid-ui/components';
import { convertToAngular } from '../../../common/services/convertToAngular';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
  },
  errorRoot: {
    marginBottom: '14px',
  },
  errorMsg: {
    color: BigidColors.failureRed,
  },
});

export const ScanScheduler: FunctionComponent<BigidScheduleEventPickerProps> = ({
  onChange,
  startTime = '* * * * *',
  dataAid,
}) => {
  const [errorMsg, setErrorMsg] = useState<string>('');
  const classes = useStyles({});
  const handleOnError = useCallback((message: string) => {
    setErrorMsg(message);
  }, []);

  return (
    <div>
      <BigidScheduleEventPicker
        startTime={startTime}
        onChange={onChange}
        includedIdentifiers={[
          ScheduledIdentifierOptions.DAILY,
          ScheduledIdentifierOptions.HOURLY,
          ScheduledIdentifierOptions.WEEKLY,
          ScheduledIdentifierOptions.MONTHLY,
        ]}
        onError={handleOnError}
        selectProps={{ menuPosition: 'fixed', isSearchable: true }}
        dataAid={dataAid}
      />

      <div className={classes.errorRoot}>
        <BigidBody2 className={classes.errorMsg}>{errorMsg}</BigidBody2>
      </div>
    </div>
  );
};

angular
  .module('app')
  .component(
    'scanScheduler',
    convertToAngular<BigidScheduleEventPickerProps>(ScanScheduler, [
      'onChange',
      'includedIdentifiers',
      'onError',
      'startTime',
      'dataAid',
    ]),
  );
