import React, { useMemo } from 'react';
import { BigidGridGroupingProps, StatusFormatter } from '@bigid-ui/grid';
import { BigidBody1, BigidCaption } from '@bigid-ui/components';
import { getFinalEtlRunStatus, ETLStatusMode, getEtlStatusForIcon } from '../reportingEtlMonitoringUtils';
import styled from '@emotion/styled';
import { useLocalTranslation } from '../translations';

const StatusWrapper = styled('strong')`
  margin-right: 5px;
`;
const GroupedRowWrapper = styled('div')`
  display: inline-block;
  vertical-align: middle;
`;

export const EtlGroupRowContentComponent: BigidGridGroupingProps['groupRowComponent'] = ({ row, column }) => {
  const { t } = useLocalTranslation();
  const finalStatus = useMemo<ETLStatusMode>(() => {
    const collapsedRowsSymbol = Object.getOwnPropertySymbols(row).find(key => key.toString().includes('collapsedRows'));
    if (collapsedRowsSymbol) {
      const collapsedRows = (row as any)[collapsedRowsSymbol];
      return getFinalEtlRunStatus(collapsedRows);
    }
  }, [row]);

  return (
    <GroupedRowWrapper>
      <span>
        {finalStatus && (
          <BigidBody1 display={'flex'}>
            <StatusWrapper> {t('main.grid.groupedRow.status') + ':'} </StatusWrapper>
            <StatusFormatter
              value={{
                status: getEtlStatusForIcon(finalStatus),
                text: finalStatus,
              }}
              column={{ name: '' }}
            />
          </BigidBody1>
        )}
        <BigidCaption>
          {t('main.grid.groupedRow.runId') + ':'} {row.key}
        </BigidCaption>
      </span>
    </GroupedRowWrapper>
  );
};
