import React from 'react';
import { BigidButtonIcon } from '@bigid-ui/components';
import { BigidDeleteIcon } from '@bigid-ui/icons';
import { useDeleteDsCollaborator } from '../../hooks/useDeleteDsCollaborator';
import { useLocalTranslation } from '../../translations';
import { notificationService } from '../../../../../services/notificationService';
import { mapRowToDeleteDsCollaboratorPayload } from '../../mappers/collaboration';

type DataSourceConnectionCollaborationRevokeFieldProps = {
  dataAid?: string;
  id: string;
  sourceId: string;
  onRevoke?: () => void;
};

export const DataSourceConnectionCollaborationRevokeField = ({
  dataAid = 'DataSourceConnectionCollaborationRevokeField',
  id,
  sourceId,
  onRevoke,
}: DataSourceConnectionCollaborationRevokeFieldProps) => {
  const { t } = useLocalTranslation();
  const { mutateAsync: deleteCollaborator, isLoading: isDeleting } = useDeleteDsCollaborator({
    mutation: {
      onError: () => notificationService.error(t('collaboration.deleteError')),
      onSuccess: () => notificationService.success(t('collaboration.deleteSuccess')),
    },
  });

  const handleRevokeAccess = async () => {
    try {
      const payload = mapRowToDeleteDsCollaboratorPayload({ email: id });

      await deleteCollaborator(
        {
          dataSource: sourceId,
          data: payload,
        },
        {
          onSuccess: async () => onRevoke?.(),
        },
      );
    } catch {}
  };
  return (
    <BigidButtonIcon icon={BigidDeleteIcon} disabled={isDeleting} onClick={handleRevokeAccess} dataAid={dataAid} />
  );
};
