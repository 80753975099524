import React, { FC } from 'react';
import { useTheme } from '@mui/styles';
import { BigidApplyFilledIcon } from '@bigid-ui/icons';
import { areAllStepsCompleted } from './utils/gettingStartedUtils';

interface CircularProgressBarProps {
  currentStep: number;
  totalSteps: number;
  dataAid?: string;
}

export const CircularProgressBar: FC<CircularProgressBarProps> = ({ currentStep, totalSteps, dataAid }) => {
  const theme = useTheme();
  const size = 48;
  const outerStrokeWidth = 1;
  const innerStrokeWidth = 3;
  const outerRadius = Math.floor(size / 2 - outerStrokeWidth / 2);
  const innerRadius = Math.ceil(outerRadius + outerStrokeWidth / 2 - innerStrokeWidth / 2);
  const circumference = 2 * Math.PI * innerRadius;
  const progress = (currentStep / totalSteps) * 100;
  const strokeDashoffset = circumference - (progress / 100) * circumference;

  return (
    <svg width={size} height={size} viewBox={`0 0 ${size} ${size}`} data-aid={dataAid}>
      <circle
        cx={size / 2}
        cy={size / 2}
        r={outerRadius}
        stroke={theme.vars.palette.bigid.gray200}
        strokeWidth={outerStrokeWidth}
        fill="none"
      />
      {areAllStepsCompleted(currentStep, totalSteps) ? (
        <BigidApplyFilledIcon
          color={theme.vars.tokens.bigid.positive}
          width={Math.ceil(size - (outerStrokeWidth + innerStrokeWidth) * 2)}
          height={Math.ceil(size - (outerStrokeWidth + innerStrokeWidth) * 2)}
          x={outerStrokeWidth + innerStrokeWidth}
          y={outerStrokeWidth + innerStrokeWidth}
        />
      ) : (
        <>
          <circle
            cx={size / 2}
            cy={size / 2}
            r={innerRadius}
            stroke={theme.vars.palette.bigid.primary400}
            strokeWidth={innerStrokeWidth}
            strokeDasharray={circumference}
            strokeDashoffset={strokeDashoffset}
            strokeLinecap="round"
            fill="none"
            transform={`rotate(-90 ${size / 2} ${size / 2})`}
          />
          <text x={size / 2} y={size / 2} textAnchor="middle" dy=".3em" fontSize="16px" color="#222">
            {`${currentStep}/${totalSteps}`}
          </text>
        </>
      )}
    </svg>
  );
};
