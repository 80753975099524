import React, { useEffect, useState, CSSProperties } from 'react';
import { isFunction, uniqueId } from 'lodash';
import isEmail from 'validator/lib/isEmail';
import {
  BigidDropdown,
  BigidDropdownNewOptionValidation,
  BigidDropdownOption,
  BigidDropdownProps,
  BigidFieldRenderProps,
} from '@bigid-ui/components';
import { useValidator } from '../../hooks/useValidator';
import { useLocalTranslation } from '../../hooks/useLocalTranslation';
import styled from '@emotion/styled';
import { Validation } from '../../types/validation';
import type { ValidationRule } from '../../utilities/validation';

type EmailPickerFieldProps = {
  dataAid?: string;
  data: BigidDropdownOption[];
  field?: Partial<BigidDropdownProps>;
  rules?: ValidationRule<string, BigidDropdownOption[]>[];
  disableDynamicSearch?: boolean;
  onChange?: (values: BigidDropdownOption[]) => void;
  onInputChange?: (value: string) => void;
} & Partial<BigidFieldRenderProps<unknown, BigidDropdownOption[]>>;

type Position = CSSProperties['position'];

type DropdownValidationRule = ValidationRule<string, BigidDropdownOption[]>;

export const Field = styled.div`
  & [data-aid='BigidDropdown-bar'] {
    & > div {
      min-height: 34px !important;
    }
  }
`;

const mapValidationRuleToId = (rules: DropdownValidationRule[]): string[] =>
  rules.map(rule => {
    return !isFunction(rule) ? rule.id : null;
  });

const style = { width: '100%', position: 'relative' as Position };

export const EmailPickerField = ({
  dataAid = 'EmailPickerField',
  disableDynamicSearch = false,
  data,
  onChange,
  onInputChange,
  setValue,
  error,
  errorIsShown,
  value,
  field: overrides,
  rules,
}: EmailPickerFieldProps) => {
  const [options, setOptions] = useState<BigidDropdownOption[]>();
  const { t } = useLocalTranslation();

  const defaultRules: DropdownValidationRule[] = [
    {
      id: Validation.EMAIl,
      validate: term => isEmail(term),
      message: t('collaboration.emailValid'),
    },
    {
      id: Validation.UNIQUE,
      validate(term, options) {
        return !options.map(({ value }) => value).includes(term);
      },
      message: t('collaboration.emailExists'),
    },
  ];

  const { validate } = useValidator(rules ?? defaultRules);

  const handleAdd = async (option: string): Promise<BigidDropdownOption> => ({
    id: uniqueId(),
    displayValue: option,
    value: option,
  });

  const handleSelect = (options: BigidDropdownOption[]) => {
    setValue(options);
    onChange?.(options);
  };

  const handleValidate = async (term: string): Promise<BigidDropdownNewOptionValidation> => {
    const { isValid, errors } = await validate(
      term,
      rules ? mapValidationRuleToId(rules) : [Validation.EMAIl, Validation.UNIQUE],
      value,
    );
    const [{ message }] = errors ?? [{}];
    return { isValid, message };
  };

  const handleFetchOptions = async (term: string): Promise<BigidDropdownOption[]> => {
    onInputChange?.(term);
    return options;
  };

  useEffect(() => {
    setOptions(data);
  }, [data]);

  return (
    <fieldset>
      <Field style={style}>
        <BigidDropdown
          dataAid={dataAid}
          applyOnChange
          createOptionText={t('collaboration.createOption')}
          displayLimit={20}
          entityNamePlural={t('collaboration.email', { count: 2 })}
          entityNameSingular={t('collaboration.email', { count: 1 })}
          errorMessage={errorIsShown ? (error as unknown as string) : null}
          fetchOptions={disableDynamicSearch ? null : handleFetchOptions}
          getIsNewOptionValid={handleValidate}
          isAsync
          isCreatable
          isExpandable
          isError={errorIsShown && !!error}
          isMulti
          isSearchable
          isValueDisplayedAsChips
          noOptionsMessage={t('collaboration.noOptions')}
          noSearchResultsMessage={t('collaboration.noSearchResults')}
          onCreate={handleAdd}
          onSelect={handleSelect}
          useClearButton={false}
          options={options ?? []}
          placeholder={t('collaboration.emailPlaceholder')}
          placement="bottom-start"
          size="small"
          value={value}
          searchBarPlaceholder={t('collaboration.searchBarPalceholder')}
          searchBarAutoFocus={true}
          {...overrides}
        />
      </Field>
    </fieldset>
  );
};
