import { userPreferencesService } from '../../../services/userPreferencesService';
import { map, uniq } from 'lodash';
import {
  ACTION_DATA_KEY_BY_TYPE,
  AUTO_DISCOVER_PREREQUISITES_CONTENT_ID,
  DISCOVER_ACTION_TYPE_BY_AUTH_TYPE,
  DiscoveryErrors,
  ERRORS_MESSAGES_TO_KEY,
  TYPE_TO_AVAILABLE_CHECK_PERMISSIONS_BY_AUTH_FIELDS,
  TYPE_TO_DEFAULT_VALUES_BY_AUTH_FIELDS,
  TYPE_TO_VALUES_COMPUTE,
  CloudProvider,
  ASSESSMENT_SCAN_KEY_NAME,
  ALL_DS_TYPES_SELECTED,
  TYPE_TO_NULLIFY_AUTH_FIELDS,
  DiscoveryActionType,
} from '../constants';
import { notificationService } from '../../../services/notificationService';
import { BigidFieldFilterOperator, BigidFormValues } from '@bigid-ui/components';
import { ActionItemParamsEntity } from '../../CustomApp/types';
import { AutoDiscoveryWizardContextState } from './autoDiscoveryWizardContext';
import { customAppService } from '../../../services/customAppService';
import { AutoDiscoveryWizardDsProps } from './autoDiscoveryComponents/AutoDiscoveryWizardForm/AutoDiscoveryWizardDsList/AutoDiscoveryWizardDs';
import {
  AUTO_DISCOVERY_CHECK_PERMISSION_PARAM,
  extractPermissionsFromMessage,
  findActionByType,
  getAutoDiscoveryAppsConfigsList,
} from '../../../services/autoDiscoveryService';
import { PresetPayload } from '../../CustomApp/views/CustomAppActions/CustomAppActions';
import { $state } from '../../../services/angularServices';
import { CONFIG } from '../../../../config/common';
import { getDiscoverAuthTypeString } from './autoDiscoveryWizardFormUtils';
import { CredsStatus } from '../../Fmsd/FmsdComponents';
import { TYPE_TO_SIDE_SCAN_FIELDS } from '../constants/sideScanFields';
import React from 'react';

const DONT_SHOW_AGAIN_PREFERENCE_NAME = 'dontShowAgainAutoDiscoverPrerequisites';

export interface DoNotShowAgainPrerequisitesPreferences {
  typeNotShow: string[];
}

export interface CheckAutoDiscoveryConfigPermissions {
  fieldsNamesByAuthTypeFields: string[];
  values: Record<string, any>;
  discoveryConfigDataRef: React.MutableRefObject<AutoDiscoveryWizardContextState>;
}

export const getDoNotShowAgainPrerequisites = async () => {
  const result = await userPreferencesService.get<DoNotShowAgainPrerequisitesPreferences>(
    DONT_SHOW_AGAIN_PREFERENCE_NAME,
  );
  return result?.data?.typeNotShow;
};

export const onDoNotShowAgainPrerequisites = async (type: CloudProvider, checked: boolean) => {
  const preference = await getDoNotShowAgainPrerequisites();
  if (!preference) {
    if (checked) {
      await userPreferencesService.add<DoNotShowAgainPrerequisitesPreferences>({
        preference: DONT_SHOW_AGAIN_PREFERENCE_NAME,
        data: { typeNotShow: [type] },
      });
    }
  } else {
    await userPreferencesService.update<DoNotShowAgainPrerequisitesPreferences>({
      preference: DONT_SHOW_AGAIN_PREFERENCE_NAME,
      data: { typeNotShow: checked ? uniq([...preference, type]) : preference.filter(item => item !== type) },
    });
  }
};

export function getTextFromDomNode(node: Node): string {
  if (node.nodeType === Node.TEXT_NODE) {
    return node.textContent;
  } else if (node.nodeType === Node.ELEMENT_NODE) {
    if ((node as HTMLLinkElement).tagName === 'A') {
      return `${node.textContent}: ${(node as HTMLLinkElement).href} `;
    } else {
      return map(node.childNodes, getTextFromDomNode).join('');
    }
  }
  return '';
}

export const getInstructionFromElementById = (id: string) => {
  const element = document.getElementById(id);
  return element ? getTextFromDomNode(element) : '';
};

export const copyPrerequisitesInstructions = async () => {
  const instruction = getInstructionFromElementById(AUTO_DISCOVER_PREREQUISITES_CONTENT_ID);
  try {
    await navigator.clipboard.writeText(instruction);
    notificationService.success('Instructions was copied to clipboard');
  } catch (err) {
    console.error("Can't copy Instructions", err);
  }
};

export async function findPresetInData(discoveryConfigData: AutoDiscoveryWizardContextState, appId: string) {
  const { data } = await customAppService.getCustomAppActions(appId);
  const authType = getDiscoverAuthTypeString(discoveryConfigData.formData.authType);
  const actionName = DISCOVER_ACTION_TYPE_BY_AUTH_TYPE[discoveryConfigData.type][authType];
  const runAction = findActionByType(actionName, discoveryConfigData.type, data);
  return runAction.presets.find((preset: PresetPayload) => preset.name === discoveryConfigData?.formData?.name);
}

export const runAutoDiscovery = async (
  values: BigidFormValues,
  discoveryConfigDataRef: React.MutableRefObject<AutoDiscoveryWizardContextState>,
) => {
  const discoveryConfigData = discoveryConfigDataRef.current;
  const authTypeValue = getDiscoverAuthTypeString(discoveryConfigData.formData.authType);
  const fieldInStateForGetConfig = ACTION_DATA_KEY_BY_TYPE[discoveryConfigData.type][authTypeValue];
  const {
    [fieldInStateForGetConfig]: { appId, appRunAction },
  } = discoveryConfigData;
  await upsertAutoDiscoveryConfig(discoveryConfigDataRef, values);

  const preset = await findPresetInData(discoveryConfigData, appId);
  return customAppService.runCustomAppPreset(appRunAction._id, preset._id, appId);
};

const prepareParamsKeyValue = (
  values: { [p: string]: any },
  fieldInStateForGetConfig: DiscoveryActionType,
  discoveryConfigDataRef: React.MutableRefObject<AutoDiscoveryWizardContextState>,
) => {
  const params = discoveryConfigDataRef.current[fieldInStateForGetConfig].appRunAction.params;
  const fieldsForAdd = params.map(({ param_name }) => param_name);

  return params.reduce((acc, { param_name, default_value, param_type }) => {
    const value = fieldsForAdd.includes(param_name) ? values[param_name] : default_value;
    const fieldProps = discoveryConfigDataRef.current.getFieldProps(param_name);
    if (fieldProps.misc.isYesNoField) {
      return {
        ...acc,
        [param_name]: value !== 'no' && !!value ? 'yes' : 'no',
      };
    }
    if (fieldProps.misc.isTrueFalse) {
      return {
        ...acc,
        [param_name]: value !== 'false' && !!value ? 'true' : 'false',
      };
    }
    return {
      ...acc,
      [param_name]: param_type === 'String' ? String(value?.[0]?.value || value)?.trim?.() : value,
    };
  }, {});
};

export function getSideScanScanFieldsByType(type: CloudProvider, params: ActionItemParamsEntity[]) {
  return TYPE_TO_SIDE_SCAN_FIELDS[type].filter(({ name }) => params.find(({ param_name }) => param_name === name));
}

export const convertSelectedAuthTypeToParams = async (
  discoveryConfigDataRef: React.MutableRefObject<AutoDiscoveryWizardContextState>,
) => {
  const { formData, type } = discoveryConfigDataRef.current;
  const authTypeValue = getDiscoverAuthTypeString(formData.authType);
  const valuesByAuth = TYPE_TO_DEFAULT_VALUES_BY_AUTH_FIELDS[type][authTypeValue] ?? {};
  const valuesNullifiedByAuth =
    TYPE_TO_NULLIFY_AUTH_FIELDS[type][authTypeValue].reduce((acc, fieldName) => ({ ...acc, [fieldName]: '' }), {}) ??
    {};

  const fieldInStateForGetConfig = ACTION_DATA_KEY_BY_TYPE[discoveryConfigDataRef.current.type][authTypeValue];
  const computedValues = (await TYPE_TO_VALUES_COMPUTE[type][authTypeValue]?.(formData)) ?? {};

  return {
    ...prepareParamsKeyValue(
      { ...formData, ...computedValues, ...valuesNullifiedByAuth },
      fieldInStateForGetConfig,
      discoveryConfigDataRef,
    ),
    ...valuesByAuth,
  };
};

const getDsTypeFromDsListGeneratedByChecking = (values?: BigidFormValues, formData?: BigidFormValues) => {
  const dsListByCheck: AutoDiscoveryWizardDsProps[] = formData?.dsList ?? values?.dsList;
  const dsType = dsListByCheck && dsListByCheck?.filter(({ selected }) => selected).map(({ id }) => id);
  return !!dsType?.length && dsType.join(',');
};

const convertDsTypeFormToPreset = (values?: BigidFormValues, formData?: BigidFormValues) => {
  const dsType = values?.ds_type ?? formData?.ds_type;
  return dsType?.[0]?.value ? dsType.map((item: { value?: string }) => item?.value).join(',') : dsType;
};

const getDsType = (values?: BigidFormValues, formData?: BigidFormValues) => {
  return {
    ds_type:
      getDsTypeFromDsListGeneratedByChecking(values, formData) ||
      convertDsTypeFormToPreset(values, formData) ||
      ALL_DS_TYPES_SELECTED,
  };
};

export const upsertAutoDiscoveryConfig = async (
  discoveryConfigDataRef: React.MutableRefObject<AutoDiscoveryWizardContextState>,
  values?: BigidFormValues,
) => {
  const discoveryConfigData = discoveryConfigDataRef.current;
  const { name, authType } = values ?? discoveryConfigData.formData;
  const authTypeValue = getDiscoverAuthTypeString(authType);
  const fieldInStateForGetConfig = ACTION_DATA_KEY_BY_TYPE[discoveryConfigData.type][authTypeValue];
  const {
    [fieldInStateForGetConfig]: { appId, appRunAction },
  } = discoveryConfigData;
  const paramsByAuthType = await convertSelectedAuthTypeToParams(discoveryConfigDataRef);
  const dsType = getDsType(values, discoveryConfigData.formData);
  const isCheckAvailable = TYPE_TO_AVAILABLE_CHECK_PERMISSIONS_BY_AUTH_FIELDS[discoveryConfigData.type][authTypeValue];

  const presetData = {
    name,
    description: '',
    paramsKeyValue: {
      ...paramsByAuthType,
      ...dsType,
      ...(isCheckAvailable
        ? {
            [AUTO_DISCOVERY_CHECK_PERMISSION_PARAM]: 'false',
          }
        : {}),
      ...(isRunAndScanAvailable(discoveryConfigData)
        ? {
            [ASSESSMENT_SCAN_KEY_NAME]: String(
              values?.[ASSESSMENT_SCAN_KEY_NAME]?.[0]?.value ?? values?.[ASSESSMENT_SCAN_KEY_NAME],
            ),
          }
        : {}),
    },
  };
  const isActionChanged =
    discoveryConfigDataRef?.current?.preset?.appRunAction?._id &&
    discoveryConfigDataRef?.current?.preset?.appRunAction?._id !== appRunAction._id;

  const result =
    discoveryConfigData.isEdit && discoveryConfigData.id && !isActionChanged
      ? await customAppService.editActionPreset(appId, appRunAction._id, discoveryConfigData.id, presetData)
      : await customAppService.addActionPreset(appId, appRunAction._id, presetData);

  if (isActionChanged) {
    await customAppService.deleteActionPreset(
      appId,
      discoveryConfigDataRef?.current?.preset?.appRunAction?._id,
      discoveryConfigData.id,
    );
  }
  return {
    result,
    fieldInStateForGetConfig,
  };
};

export const checkAutoDiscoveryConfigPermissions = async ({
  fieldsNamesByAuthTypeFields,
  values,
  discoveryConfigDataRef,
}: CheckAutoDiscoveryConfigPermissions) => {
  const { authType } = discoveryConfigDataRef.current.formData;
  const authTypeValue = getDiscoverAuthTypeString(authType);
  const fieldInStateForGetConfig = ACTION_DATA_KEY_BY_TYPE[discoveryConfigDataRef.current.type][authTypeValue];
  const {
    [fieldInStateForGetConfig]: { appId, appRunAction },
  } = discoveryConfigDataRef.current;
  try {
    const paramsByAuthType = await convertSelectedAuthTypeToParams(discoveryConfigDataRef);
    const paramsFromFormByAuth = fieldsNamesByAuthTypeFields?.reduce?.(
      (acc, fieldName) => ({ ...acc, [fieldName]: values[fieldName] ?? discoveryConfigDataRef.current.formData }),
      {},
    );
    const paramsKeyValuesMap = prepareParamsKeyValue(
      {
        ...paramsByAuthType,
        ...paramsFromFormByAuth,
        [AUTO_DISCOVERY_CHECK_PERMISSION_PARAM]: 'true',
      },
      fieldInStateForGetConfig,
      discoveryConfigDataRef,
    );
    const toUpdate = {
      actionParamsKeyValuesMap: [
        {
          actionId: appRunAction._id,
          paramsKeyValuesMap,
        },
      ],
      paramsKeyValuesMap: {},
      generalKeyValuesMap: {},
    };

    await customAppService.updateCustomAppParamValues(appId, toUpdate);

    const { data } = await customAppService.runCustomAppPreset(appRunAction._id, '', appId);
    const connectionInfo = extractPermissionsFromMessage(data.data.message);
    const isValid = connectionInfo?.credsStatus === CredsStatus.VALID;
    const errorMessageKey =
      findErrorKeyFromMessage(data.data?.message) ?? (!isValid && DiscoveryErrors.PERMISSIONS_COMMON);

    return {
      connectionInfo,
      ...data.data,
      isValid,
      errorMessageKey,
    };
  } catch (err) {
    const message = err?.response?.data?.message ?? err?.message ?? err;
    const errorMessageKey = findErrorKeyFromMessage(message) ?? DiscoveryErrors.PERMISSIONS_COMMON;

    return {
      isValid: false,
      connectionInfo: { permissions: [], credsStatus: CredsStatus.CANT_EVALUATE },
      message,
      errorMessageKey,
    };
  }
};

export const loadDefaultConfigsForWizard = async (type: CloudProvider, id?: string) => {
  const queryComponents = {
    filter: [
      {
        field: 'type',
        value: [type],
        operator: 'equal' as BigidFieldFilterOperator,
      },
      {
        field: 'is_default',
        value: true,
        operator: 'eq' as BigidFieldFilterOperator,
      },
      ...(id
        ? [
            {
              field: 'id',
              value: id,
              operator: 'eq' as BigidFieldFilterOperator,
            },
          ]
        : []),
    ],
  };
  const result = await getAutoDiscoveryAppsConfigsList(queryComponents);

  return {
    configData: result.data.find(({ isMultiConfig, is_default }) => !isMultiConfig && is_default),
    configDataMultiply: result.data.find(({ isMultiConfig, is_default }) => isMultiConfig && is_default),
    preset: result.data.find(({ _id }) => _id === id),
  };
};

export const goToTpaPresetEdit = async (
  discoveryConfigData: AutoDiscoveryWizardContextState,
  discoveryConfigDataRef: React.MutableRefObject<AutoDiscoveryWizardContextState>,
) => {
  try {
    const authTypeValue = getDiscoverAuthTypeString(discoveryConfigData.formData.authType);
    const fieldInStateForGetConfig = ACTION_DATA_KEY_BY_TYPE[discoveryConfigData.type][authTypeValue];
    const params = {
      id: discoveryConfigData[fieldInStateForGetConfig].appId,
      actionId: discoveryConfigData[fieldInStateForGetConfig].actionId,
      presetId: '',
      onReturnState: JSON.stringify({
        url: CONFIG.states.DATA_SOURCE_CONNECTIONS,
        params: {
          tab: 'discovery',
        },
      }),
    };
    if (discoveryConfigData.formData?.name) {
      await upsertAutoDiscoveryConfig(discoveryConfigDataRef);
      const preset = await findPresetInData(discoveryConfigData, discoveryConfigData.configData.appId);
      params.presetId = preset._id;
    }
    $state.go(CONFIG.states.CUSTOM_APP_EDIT_PRESET, params);
  } catch (err) {
    notificationService.error('Problem with go to advanced edit');
  }
};

export function findErrorKeyFromMessage(messageInError?: string) {
  return messageInError
    ? ERRORS_MESSAGES_TO_KEY.find(item => item.messages.some(messagePart => messageInError.includes(messagePart)))?.key
    : DiscoveryErrors.NOT_FOUND;
}

export const isRunAndScanAvailable = (discoveryConfigData: AutoDiscoveryWizardContextState) => {
  const authTypeValue = getDiscoverAuthTypeString(discoveryConfigData?.formData?.authType);
  const fieldInStateForGetConfig = ACTION_DATA_KEY_BY_TYPE[discoveryConfigData?.type]?.[authTypeValue];

  return !!discoveryConfigData[fieldInStateForGetConfig]?.appRunAction?.params?.some(
    ({ param_name }) => param_name === ASSESSMENT_SCAN_KEY_NAME,
  );
};
