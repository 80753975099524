import React, { Fragment } from 'react';
import { BigidDropdown, BigidMenuItemProps } from '@bigid-ui/components';
import { pageHeaderService } from '../../../common/services/pageHeaderService';
import { $state } from '../../services/angularServices';
import { areSomePermitted } from '../../services/userPermissionsService';
import { CustomAppMenu } from './components/CustomAppMenu';
import { CONFIG } from '../../../config/common';
import { CustomAppPagesEnum, AppInfo } from './utils/CustomAppTypes';
import { styled } from '@mui/material';
import { getApplicationPreference } from '../../services/appPreferencesService';
import { getInstancesDropdownOption, onSelectAppInstance } from './utils/CustomAppUtils';

const StyledDiv = styled('div')({
  display: 'flex',
  alignItems: 'center',
});

export interface CustomAppHeaderProps {
  appInfo: AppInfo;
  pageName?: string;
  permissions: string[];
}

const navigateToPage = (appInfo: any, page: string) => {
  const params = { id: appInfo.id, appInfo };
  $state.go(page, params);
};

export const handleMenuItemSelect = (appInfo: any) => (item: BigidMenuItemProps) => {
  switch (item.id) {
    case CustomAppPagesEnum.UI:
      navigateToPage(appInfo, CONFIG.states.CUSTOM_APP);
      break;
    case CustomAppPagesEnum.ACTIONS:
      navigateToPage(appInfo, CONFIG.states.CUSTOM_APP_ACTIONS);
      break;
    case CustomAppPagesEnum.ACTIVITY_LOG:
      navigateToPage(appInfo, CONFIG.states.CUSTOM_APP_ACTIVITY_LOG);
      break;
    case CustomAppPagesEnum.EDIT_APP:
      navigateToPage(appInfo, CONFIG.states.CUSTOM_APP_EDIT);
      break;
  }
};

export const CustomAppHeader = ({ appInfo, pageName, permissions }: CustomAppHeaderProps) => {
  const instancesDropdownOption = getInstancesDropdownOption(appInfo);
  const initialInstance = instancesDropdownOption.filter(option => option.isSelected);

  const getTitle = () => {
    return pageName
      ? {
          breadcrumbs: [
            {
              label: appInfo.friendlyName ? appInfo.friendlyName : appInfo.name,
              onClick: () => navigateToPage(appInfo, CONFIG.states.CUSTOM_APP),
            },
            {
              label: pageName,
            },
          ],
        }
      : { pageTitle: appInfo.friendlyName || appInfo.name || 'Custom App' };
  };

  const menu = (
    <Fragment>
      <StyledDiv>
        {getApplicationPreference('TPA_MULTIPLE_DEPLOYMENTS_ENABLED') &&
          instancesDropdownOption.length > 1 &&
          !appInfo.isInteractive && (
            <BigidDropdown
              options={instancesDropdownOption}
              value={initialInstance}
              onSelect={option => option[0].value !== initialInstance[0].value && onSelectAppInstance(option)}
            />
          )}
        {permissions && areSomePermitted(permissions) && (
          <CustomAppMenu
            onItemSelect={handleMenuItemSelect(appInfo)}
            isInteractive={appInfo.isInteractive && !appInfo.hideUI}
            uiTabName={appInfo.uiTabName}
            popMenuDown
          />
        )}
      </StyledDiv>
    </Fragment>
  );

  pageHeaderService.setTitle({
    ...getTitle(),
    rightSideComponentsContainer: menu,
  });
};
