import React, { useEffect, useState } from 'react';
import { notificationService } from '../../../services/notificationService';
import { BigidDataEmailTemplateIllustration, BigidEditIcon, BigidViewIcon } from '@bigid-ui/icons';
import {
  BigidBody1,
  BigidContentCard,
  BigidContentCardFooterActionType,
  BigidLoader,
  BigidSearch,
  BigidStatusBadge,
  BigidStatusBadgeSize,
  BigidStatusBadgeType,
  BigidSecondaryMenu,
  MenuItem,
  BigidFilter,
} from '@bigid-ui/components';
import { getFixedT, useLocalTranslation } from '../translations';
import { cloneDeep, debounce } from 'lodash';
import { EmailTemplate } from '../types/emailTemplatesTypes';
import styled from '@emotion/styled';
import { PAGE_BOTTOM_MARGIN } from '../EmailTemplates';
import { deleteTemplateService, getEmailTemplates, getEmailTemplatesTypes } from './emailTemplatesService';
import { filterOutTemplate, filterTemplatesByName, handleDuplicateButtonClick } from './utils';
import { EmptyTemplates } from './EmptyTemplates';
import { openEmailEditor } from '../../../components/EmailEditor/emailEditorService';
import { emailTemplatesEventEmitter, EmailTemplatesEvents } from '../emailTemplatesEvents';
import { queryService } from '../../../services/queryService';
import { BigidGridSorting } from '@bigid-ui/grid';
import { trackEmailTemplateEvent, EmailTemplateTrackingEvents } from '../emailTemplateTrackingUtils';
import { generateDataAid } from '@bigid-ui/utils';

const SECONDARY_MENU_WIDTH = '300px';
const CUSTOM_TEMPLATE_NAME = 'BigID Custom Template';
export const DEFAULT_SORTING: BigidGridSorting[] = [{ field: 'name', order: 'asc' }];

export enum GenericEmailCategories {
  ALL = 'all',
  CUSTOM = 'custom',
}

const Root = styled('div')(({ theme }) => ({
  position: 'relative',
  height: `calc(100% - ${PAGE_BOTTOM_MARGIN}px)`,
  width: '100%',
  background: theme.vars.palette.bigid.gray50,
  borderRadius: '6px',
  padding: '16px 16px 16px 0px',
  display: 'flex',
  overflow: 'scroll',
  boxShadow: theme.vars.tokens.bigid.shadow10,
  border: theme.vars.tokens.bigid.borderDefault,
}));

const TabWrapper = styled('div')({
  width: SECONDARY_MENU_WIDTH,
  height: '100%',
  '& > div': {
    height: '100%',
    padding: '16px',
  },
});

const ContentWrapper = styled('div')({
  width: '100%',
  justifyContent: 'center',
  paddingLeft: '16px',
});

const ContentHeader = styled('div')({
  display: 'flex',
  width: '100%',
  alignItems: 'flex-end',
  flexDirection: 'column',
  gap: '10px',
});

const SearchContainer = styled('div')({
  width: '240px',
  height: '40px',
});

const TemplatesSection = styled('div')({
  width: '100%',
  display: 'flex',
});

const TemplatesWrapper = styled('div')({
  overflow: 'scroll',
  width: '100%',
  display: 'grid',
  gridAutoFlow: 'row',
  flex: '0 1 auto',
  gap: '12px',
  marginTop: '16px',
  gridTemplateColumns: 'repeat(auto-fit, minmax(205px, max-content))',
  '& > div > div': {
    maxWidth: '100%',
  },
});

const Card = styled('div')(({ theme }) => ({
  border: `1px solid ${theme.vars.tokens.bigid.borderDefault}`,
  background: theme.vars.tokens.bigid.backgroundPrimary,
  '& > div > div > div': {
    width: '100%',
    '& > h6': {
      width: '100%',
      display: '-webkit-box',
      overflow: 'hidden',
      WebkitLineClamp: 2,
      WebkitBoxOrient: 'vertical',
    },
  },
}));

const generateMenuItems = (data: string[]): MenuItem[] => {
  const t = getFixedT('TemplatesManager.labels');
  const allCategories = data.length > 1 ? [GenericEmailCategories.ALL as string].concat(data) : data;
  return allCategories.map(type => {
    return {
      name: type,
      displayName: t(type),
    };
  });
};

const getEmailTemplatesGridAggregationQuery = (selectedCategory: string) => {
  const filter: BigidFilter = [{ field: 'name', operator: 'notIn', value: [CUSTOM_TEMPLATE_NAME] }];

  if (selectedCategory && selectedCategory !== GenericEmailCategories.ALL) {
    filter.push({ field: 'type', operator: 'equal', value: selectedCategory });
  }

  return queryService.getGridConfigQuery({ filter, sort: DEFAULT_SORTING });
};

export const EmailTemplatesBrowse: React.FC = () => {
  const [isLoadingCategories, setIsLoadingCategories] = useState(true);
  const [isTemplatesDataLoading, setIsTemplatesDataLoading] = useState(true);
  const [menuItems, setMenuItems] = useState([]);
  const [selectedMenuItem, setSelectedMenuItem] = useState<string>('');
  const [searchValue, setSearchValue] = useState<string>('');
  const [templates, setTemplates] = useState<EmailTemplate[]>([]);
  const [filteredTemplates, setFilteredTemplates] = useState<EmailTemplate[]>([]);
  const { t } = useLocalTranslation('TemplatesManager');

  useEffect(() => {
    const handleEvent = async () => {
      const data = await getEmailTemplates(getEmailTemplatesGridAggregationQuery(selectedMenuItem));
      setTemplates(data);
      setFilteredTemplates(filterTemplatesByName(data, searchValue));
    };

    const unregisterRefresh = emailTemplatesEventEmitter.addEventListener(
      EmailTemplatesEvents.REFRESH_TEMPLATES_VIEW,
      handleEvent,
    );

    return function cleanup() {
      unregisterRefresh();
    };
  }, [searchValue, selectedMenuItem]);

  useEffect(() => {
    setIsLoadingCategories(true);
    const getTemplatesCategories = async () => {
      try {
        const data = await getEmailTemplatesTypes();
        const menuItems = generateMenuItems(data);
        setMenuItems(menuItems);
        setSelectedMenuItem(menuItems?.[0]?.name);
      } catch (e) {
        const fetchTemplateCategoriesError = t('messages.unableToLoadTemplatesCategories');
        console.error(fetchTemplateCategoriesError);
        notificationService.error(fetchTemplateCategoriesError);
      } finally {
        setIsLoadingCategories(false);
      }
    };

    getTemplatesCategories();
  }, [t]);

  useEffect(() => {
    const fetchTemplates = async () => {
      try {
        if (menuItems == null || menuItems.length === 0) {
          return;
        }
        const emailTemplates = await getEmailTemplates(getEmailTemplatesGridAggregationQuery(selectedMenuItem));
        setTemplates(emailTemplates);
        setIsTemplatesDataLoading(false);
        setFilteredTemplates(filterTemplatesByName(emailTemplates, searchValue));
      } catch (error) {
        const fetchTemplatesError = t('messages.unableToLoadTemplates');
        console.error(`${fetchTemplatesError} ${error}`);
        notificationService.error(fetchTemplatesError);
      }
    };

    if (selectedMenuItem !== null) {
      fetchTemplates();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedMenuItem, menuItems]);

  const onSuccessfulDuplication = (newEmailTemplate: EmailTemplate) => {
    const updatedTemplatesArray = cloneDeep(templates);
    const index = updatedTemplatesArray.findIndex(
      emailTemplate =>
        !emailTemplate.isSystem &&
        (newEmailTemplate.name.toLowerCase() < emailTemplate.name.toLowerCase() ||
          newEmailTemplate.name < emailTemplate.name),
    );
    if (index === -1) {
      updatedTemplatesArray.push(newEmailTemplate);
    } else {
      updatedTemplatesArray.splice(index, 0, newEmailTemplate);
    }
    setTemplates(updatedTemplatesArray);
    setFilteredTemplates(filterTemplatesByName(updatedTemplatesArray, searchValue));
  };

  const defaultStatusBadge = (
    <BigidStatusBadge
      label={t('templateCard.default')}
      size={BigidStatusBadgeSize.SMALL}
      type={BigidStatusBadgeType.PENDING}
    />
  );

  const getContentCardAttributes = (object: EmailTemplate) => {
    return {
      size: {
        height: '200px',
        width: '238px',
      },
      headerIcon: BigidDataEmailTemplateIllustration,
      ...(object.isSystem ? { footerLeftItem: defaultStatusBadge } : {}),
      footerActions: [
        {
          type: BigidContentCardFooterActionType.MENU,
          actions: [
            {
              action: async () => {
                await handleDuplicateButtonClick(object, onSuccessfulDuplication);
              },
              text: t('buttons.duplicate'),
            },
            ...(object.isSystem
              ? []
              : [
                  {
                    action: async () => {
                      await deleteTemplate(object);
                    },
                    text: t('buttons.delete'),
                  },
                ]),
          ],
        },
        {
          type: BigidContentCardFooterActionType.ICON_BUTTON,
          actions: [
            {
              action: () => {
                const viewOnlyMode = !!object?.isSystem;
                trackEmailTemplateEvent(
                  viewOnlyMode
                    ? EmailTemplateTrackingEvents.EMAIL_TEMPLATES_BROWSE_VIEW_TEMPLATE_CLICK
                    : EmailTemplateTrackingEvents.EMAIL_TEMPLATES_BROWSE_EDIT_TEMPLATE_CLICK,
                  { templateName: object?.name },
                );
                openEmailEditor({
                  templateId: object?._id,
                  viewOnlyMode,
                  ...(!object?.isSystem
                    ? { onClose: () => emailTemplatesEventEmitter.emit(EmailTemplatesEvents.REFRESH_TEMPLATES_VIEW) }
                    : {}),
                });
              },
              text: object?.isSystem ? t('buttons.view') : t('buttons.edit'),
              icon: object?.isSystem ? BigidViewIcon : BigidEditIcon,
            },
          ],
        },
      ],
    };
  };

  const onSearchHandler = debounce((value: string) => {
    setSearchValue(value);
    if (!value || value === '') {
      setFilteredTemplates(templates);
      return;
    }
    setFilteredTemplates(filterTemplatesByName(templates, value));
  }, 150);

  const deleteTemplate = async (templateToDelete: EmailTemplate): Promise<void> => {
    if (templateToDelete.isSystem) {
      notificationService.error(t('messages.unableToDeleteDefaultTemplate', { entityName: templateToDelete.name }));
      return;
    }
    try {
      await deleteTemplateService(templateToDelete._id);
    } catch (e) {
      notificationService.error(t('messages.unableToDeleteTemplate', { entityName: templateToDelete.name }));
      return;
    }
    const updatedTemplates = filterOutTemplate(cloneDeep(templates), templateToDelete);
    setTemplates(updatedTemplates);
    setFilteredTemplates(filterTemplatesByName(updatedTemplates, searchValue));
    notificationService.success(t('messages.successfullyDeletedTemplate', { entityName: templateToDelete.name }));
    trackEmailTemplateEvent(EmailTemplateTrackingEvents.EMAIL_TEMPLATES_DELETE_TEMPLATE_CLICK, {
      templateName: templateToDelete.name,
    });
  };

  return (
    <Root>
      <TabWrapper>
        {isLoadingCategories ? (
          <BigidLoader />
        ) : (
          <BigidSecondaryMenu
            selectedMenuItem={selectedMenuItem}
            setSelectedMenuItem={setSelectedMenuItem}
            menuItems={menuItems}
            menuWidth={SECONDARY_MENU_WIDTH}
            unselectingItemEnabled={false}
          />
        )}
      </TabWrapper>
      <ContentWrapper>
        <ContentHeader>
          <SearchContainer>
            <BigidSearch
              value={searchValue}
              onChange={onSearchHandler}
              onSubmit={onSearchHandler}
              placeholder={t('search.placeholder')}
              size="large"
            />
          </SearchContainer>
        </ContentHeader>
        <TemplatesSection>
          {isTemplatesDataLoading ? (
            <BigidLoader position="absolute" />
          ) : (
            <>
              {!!filteredTemplates.length ? (
                <TemplatesWrapper>
                  {filteredTemplates.map((template: EmailTemplate) => (
                    <Card
                      data-aid={generateDataAid('TemplateCard', [template?.name || template?._id])}
                      key={template?._id || template?.name}
                    >
                      <BigidContentCard headerTitle={template.name} {...getContentCardAttributes(template)}>
                        <BigidBody1 size="small">{template.description}</BigidBody1>
                      </BigidContentCard>
                    </Card>
                  ))}
                </TemplatesWrapper>
              ) : (
                <EmptyTemplates isEmptyCategory={!templates?.length} />
              )}
            </>
          )}
        </TemplatesSection>
      </ContentWrapper>
    </Root>
  );
};
