import React, { ChangeEvent, FC } from 'react';
import { BigidBody1, BigidFieldRenderProps } from '@bigid-ui/components';
import styled from '@emotion/styled';

const ColorPickerWrapper = styled('div')({
  display: 'flex',
  flexDirection: 'column',
});

const StyledColorInput = styled('input')`
  width: 100%;
`;

export const FormColorPicker: FC<BigidFieldRenderProps> = ({ name, value = '', setValue, label }) => {
  const handleChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setValue(e.target.value);
  };

  return (
    <ColorPickerWrapper id={`bigid-form-color-picker-${name}`}>
      {label && <BigidBody1 marginBottom={'8px'}>{label}</BigidBody1>}
      <StyledColorInput type="color" value={value} name={name} onChange={handleChange} />
    </ColorPickerWrapper>
  );
};
