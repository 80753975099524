import React, { FC, ReactElement, ReactText, Dispatch, SetStateAction } from 'react';
import { generateDataAid } from '@bigid-ui/utils';
import { FindingDetails } from '../../curationService';
import {
  CuratedFieldValuePreviewFinding,
  CuratedFieldValuePreviewFindingProps,
} from './CuratedFieldValuePreviewFinding';

export interface CuratedFieldValuePreviewContentProps
  extends Pick<CuratedFieldValuePreviewFindingProps, 'isPermittedToExclude' | 'onFindingCurate'> {
  dataAid: string;
  content: string;
  curatedFindingDetails: FindingDetails[];
  fieldId?: ReactText;
}

type ContentPreprocessed = (string | ReactElement)[];

export const getContentPreprocessed = ({
  dataAid,
  content,
  curatedFindingDetails,
  isPermittedToExclude,
  onFindingCurate,
  fieldId,
}: CuratedFieldValuePreviewContentProps): ContentPreprocessed => {
  let currentIndex = 0;
  const results = [];
  const sortedFindingDetails = curatedFindingDetails.sort((a, b) => a.position - b.position);

  for (const finding of sortedFindingDetails) {
    const textBefore = content.slice(currentIndex, finding.position);

    results.push(textBefore);
    results.push(
      <CuratedFieldValuePreviewFinding
        key={finding.findingId}
        dataAid={generateDataAid(dataAid, ['finding', finding.findingId])}
        isPermittedToExclude={isPermittedToExclude}
        finding={finding}
        onFindingCurate={onFindingCurate}
        fieldId={fieldId}
      />,
    );

    currentIndex = finding.length + finding.position;
  }

  const textAfter = content.slice(currentIndex);
  results.push(textAfter);

  return results;
};

export const CuratedFieldValuePreviewContent: FC<CuratedFieldValuePreviewContentProps> = props => {
  return <>{getContentPreprocessed(props)}</>;
};
