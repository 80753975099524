import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { ActionData, BigidPaper, EntityEvents, entityEventsEmitter, QueryTree } from '@bigid-ui/components';
import { BigidDeleteIcon } from '@bigid-ui/icons';
import {
  ActionsFormatterAlignment,
  ActionsFormatterProps,
  BigidGridColumn,
  BigidGridColumnTypes,
  BigidGridRow,
  BigidGridWithToolbar,
  BigidGridWithToolbarProps,
  FormatterOnClickHandlerPayloadData,
} from '@bigid-ui/grid';
import { queryService } from '../../services/queryService';
import { notificationService } from '../../services/notificationService';
import { showConfirmationDialog } from '../../services/confirmationDialogService';
import { deleteSensitivityClassification, getSensitivityClassifications } from './SensitivityClassificationService';
import { pageHeaderService } from '../../../common/services/pageHeaderService';
import { $state } from '../../services/angularServices';
import { CONFIG } from '../../../config/common';
import { getApplicationPreference } from '../../services/appPreferencesService';
import { QueryType } from './QuerySelector';

export enum ActionStatus {
  INITIALIZING = 'initializing',
  DC_TAGGING = 'dc tagging',
  DS_TAGGING = 'ds tagging',
  SUCCEEDED = 'succeeded',
  FAILED = 'failed',
  CANCELED = 'canceled',
}

export interface SensitivityClassificationProgress {
  totalStages: number;
  currentStage: number;
}

export interface ClassificationLevel {
  levelId?: string;
  id?: string;
  name: string;
  priority: number;
  queryObject?: QueryTree;
  queryString?: string;
  query?: string;
  queryObj?: QueryTree;
  queryExpanded?: boolean;
  isQueryTouched?: boolean;
  queryType: QueryType;
}

export interface SensitivityClassificationSchedule {
  schedule?: string;
  active?: boolean;
}

export interface ScColumnTagging {
  isActive: boolean;
  shouldPropagateToObject: boolean;
}

export interface SensitivityClassificationData {
  name: string;
  description: string;
  classifications: ClassificationLevel[];
  createdAt?: Date;
  modifiedAt?: Date;
  lastSuccess?: Date;
  actionStatus?: { status: ActionStatus; progress?: SensitivityClassificationProgress };
  schedule?: SensitivityClassificationSchedule;
  isTaggingApplicable?: boolean;
  dsTagging?: boolean;
  columnTagging?: ScColumnTagging;
  defaultSc?: boolean;
  progress?: string;
}

export interface SensitivityClassificationDataRow extends BigidGridRow, SensitivityClassificationData {}

const useStyles = makeStyles({
  root: {
    height: '95%',
    overflow: 'scroll',
  },
  warningMessage: {
    marginTop: 30,
    display: 'flex',
    alignItems: 'center',
  },
});

export const getScProgress = (progress: string) => {
  const [completed, total] = progress.split('/').map(str => parseInt(str.trim(), 10));

  if (completed === total) {
    return 'Completed';
  } else {
    return `${progress} Data sources completed`;
  }
};

export const SensitivityClassification: FC = () => {
  const classes = useStyles({});
  const [query, setQuery] = useState<string>('');

  const isACIEnabled: boolean = getApplicationPreference('ACI_ENABLED');

  const goToEditForm = (scId: string) => {
    $state.go(CONFIG.states.SENSITIVITY_CLASSIFICATION_EDIT, { id: scId });
  };

  useEffect(() => {
    let timeout: ReturnType<typeof setTimeout>;
    const refreshGridData = async () => {
      try {
        if (query) {
          const { scConfigs } = await getSensitivityClassifications(query);
          entityEventsEmitter.emit(EntityEvents.UPDATE, scConfigs, false);
          timeout = setTimeout(refreshGridData, 1000 * 30);
        }
      } catch (e) {
        console.error(`An error has occurred while fetching sensitivity classifications`);
      }
    };
    refreshGridData();
    return () => {
      clearInterval(timeout);
    };
  }, [query]);

  useEffect(() => {
    pageHeaderService.setTitle({
      pageTitle: 'Sensitivity Classification',
    });
  }, []);

  const getColumns = useCallback(() => {
    const columns: BigidGridColumn<SensitivityClassificationDataRow>[] = [
      {
        name: 'name',
        title: 'Classification Group',
        type: BigidGridColumnTypes.LINK,
        formatter: {
          onClick: async ({ value }) => {
            const { linkParams } = value;
            goToEditForm(linkParams.id);
            return {};
          },
        },
        getCellValue: ({ id, name }) => ({
          link: {
            text: name,
          },
          linkParams: {
            id,
          },
        }),
      },
      {
        name: 'classificationValues',
        title: 'Classification Levels',
        sortingEnabled: false,
        getCellValue: row =>
          row.classifications.reduce<string>(
            (acc, classificationValue, index) => `${acc} ${index === 0 ? '' : '/'} ${classificationValue.name}`,
            '',
          ),
        type: BigidGridColumnTypes.TEXT,
      },
      {
        name: 'description',
        title: 'Description',
        sortingEnabled: false,
        getCellValue: row => row.description,

        type: BigidGridColumnTypes.TEXT,
      },
      {
        name: 'progress',
        title: 'Sync Progress',
        sortingEnabled: false,
        getCellValue: row => row.progress && getScProgress(row.progress),
        type: BigidGridColumnTypes.TEXT,
      },
      {
        name: 'action',
        title: 'Action',
        isNotDraggable: true,
        type: BigidGridColumnTypes.ACTIONS,
        width: 150,
        getCellValue: row => {
          const actionsFormatted: ActionsFormatterProps = {
            alignment: ActionsFormatterAlignment.LEFT,
            actions: [
              {
                name: 'delete',
                iconConfig: {
                  isVisible: !row?.defaultSc,
                  icon: BigidDeleteIcon,
                },
                execute: async ({ row }: FormatterOnClickHandlerPayloadData) => {
                  try {
                    const isClassificationDeletionConfirmed = await showConfirmationDialog({
                      entityNameSingular: 'Sensitivity Classification',
                      actionName: 'Delete',
                      actionButtonName: 'Delete',
                    });

                    if (isClassificationDeletionConfirmed) {
                      await deleteSensitivityClassification(row.id);
                      return {
                        row,
                        shouldGridReload: false,
                        entityEventToEmit: EntityEvents.DELETE,
                      };
                    } else {
                      return {};
                    }
                  } catch (error) {
                    notificationService.error(`An error has occurred: ${error?.response?.data?.message}`);
                    console.error(`An error has occurred: ${error?.response?.data?.message}`);
                    return {};
                  }
                },
              },
            ],
          };
          return actionsFormatted;
        },
      },
    ];
    return columns;
  }, []);

  const config: BigidGridWithToolbarProps<SensitivityClassificationDataRow> = useMemo(
    () => ({
      entityName: 'Classification Groups',
      showSortingControls: true,
      toolbarActions: [
        {
          disable: ({ totalRows }: ActionData) => {
            return totalRows === 20;
          },
          label: 'Add new',
          isGlobal: true,
          execute: async () => {
            $state.go(CONFIG.states.SENSITIVITY_CLASSIFICATION_NEW);
            return {};
          },
          show: () => {
            return true;
          },
        },
      ],
      defaultSorting: [{ field: 'name', order: 'desc' }],
      filterToolbarConfig: {
        searchConfig: {
          searchFilterKeys: ['name'],
          initialValue: '',
          placeholder: 'Name',
          operator: 'contains',
          autoSubmit: true,
        },
        filters: [],
      },
      fetchData: async queryComponents => {
        try {
          const gridConfigQuery = queryService.getGridConfigQuery(queryComponents);
          setQuery(gridConfigQuery);
          const { scConfigs, totalCount } = await getSensitivityClassifications(gridConfigQuery);
          return {
            totalCount,
            data: scConfigs,
          };
        } catch ({
          response: {
            data: { message },
          },
        }) {
          notificationService.error(
            isACIEnabled
              ? `An error has occurred: ${message}`
              : `An error has occurred: to activate Sensitivity Classification, please make sure that bigid-aci component is running`,
          );
          console.error(`An error has occurred: ${message}`);
          return {
            totalCount: 0,
            data: [],
          };
        }
      },
      columns: getColumns(),
    }),
    [getColumns, isACIEnabled],
  );

  return (
    <BigidPaper classes={{ root: classes.root }}>
      <BigidGridWithToolbar {...config} />
    </BigidPaper>
  );
};
