import { module } from 'angular';
const app = module('app');

app.factory('privacyRiskMapService', [
  '$q',
  '$http',
  'appSettings',
  'explorationBarChartService',
  function ($q, $http, appSettings, explorationBarChartService) {
    let that = this;
    that.getSystemLocationsRequest = {};
    that.getApplicationLocationsRequest = {};

    function getSystemLocations(queryString, isClearCache) {
      queryString = queryString || '';
      if (isClearCache) {
        that.getSystemLocationsRequest[queryString] = null;
      }
      if (!that.getSystemLocationsRequest[queryString]) {
        that.getSystemLocationsRequest[queryString] = $http
          .get(appSettings.serverPath + '/' + appSettings.version + '/systemLocations' + queryString)
          .then(response => response.data);
      }
      return that.getSystemLocationsRequest[queryString];
    }

    function getApplicationLocations(queryString, isClearCache) {
      queryString = queryString || '';
      if (isClearCache) {
        that.getApplicationLocationsRequest[queryString] = null;
      }
      if (!that.getApplicationLocationsRequest[queryString]) {
        that.getApplicationLocationsRequest[queryString] = $http
          .get(appSettings.serverPath + '/' + appSettings.version + '/applicationLocations' + queryString)
          .then(response => response.data);
      }
      return that.getApplicationLocationsRequest[queryString];
    }

    return {
      getPrivacyRiskMapResults(queryString, isClearCache) {
        return $q
          .all([
            explorationBarChartService.getResidencyData(queryString),
            getSystemLocations(queryString, isClearCache),
            getApplicationLocations(queryString, isClearCache),
          ])
          .then(function (results) {
            return results;
          });
      },
    };
  },
]);
