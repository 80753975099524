import React, { FC } from 'react';
import { useLocalTranslation } from '../../../translations';
import { ActionableInsightsGuidedTourStepTemplate } from './ActionableInsightsGuidedTourStepTemplate';

interface ActionableInsightsStartStepProps {
  dataAid?: string;
}

export const ManageRisksStep: FC<ActionableInsightsStartStepProps> = ({ dataAid }) => {
  const { t } = useLocalTranslation('Tour.steps.manageRisks');

  return (
    <ActionableInsightsGuidedTourStepTemplate
      dataAid={dataAid}
      firstSectionText={t('firstSectionText')}
      secondSectionText={t('secondSectionText')}
    />
  );
};
