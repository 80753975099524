import './newSubjectAccessRequest.component.scss';
import { REQUEST_TYPES } from './../sarConsts.js';

import { module } from 'angular';
import newSubjectAccessRequestTemplate from './newSubjectAccessRequest.component.html';
import { getApplicationPreference } from '../../../react/services/appPreferencesService';
const app = module('app');

app.component('newSubjectAccessRequest', {
  template: newSubjectAccessRequestTemplate,
  require: {
    createSarPaneCtrl: '?^^createSarPane',
  },
  controllerAs: 'newSubjectAccessRequestModel',
  controller: function (
    subjectAccessRequestService,
    $scope,
    DeleteConfirmation,
    notificationService,
    $uibModal,
    $timeout,
  ) {
    'ngInject';

    const newSubjectAccessRequestModel = this;

    newSubjectAccessRequestModel.usersData = [];
    newSubjectAccessRequestModel.selectedUsersData = {};
    newSubjectAccessRequestModel.scannedAttributes = [];
    newSubjectAccessRequestModel.identitySources = [];
    newSubjectAccessRequestModel.cachedUsers = [];
    newSubjectAccessRequestModel.showPulse = false;
    newSubjectAccessRequestModel.calledToClearResults = false;
    newSubjectAccessRequestModel.fetchingAttributes = false;
    newSubjectAccessRequestModel.singleColumnAttrsAmount = 5;
    newSubjectAccessRequestModel.showManualRequestContainer = false;
    newSubjectAccessRequestModel.manualRequestUserId = undefined;
    newSubjectAccessRequestModel.manualRequestDisplayName = undefined;
    newSubjectAccessRequestModel.manualRequestAttributes = undefined;
    newSubjectAccessRequestModel.profileSelected = null;
    newSubjectAccessRequestModel.disableSubmitRequestBtn = true; // no-unused-vars candidate

    newSubjectAccessRequestModel.getIdentitySourcesForProfile = profileId =>
      subjectAccessRequestService.getIdentitySources(profileId).then(result => {
        newSubjectAccessRequestModel.identitySources = result.id_connections;
      });

    newSubjectAccessRequestModel.getAttributesForProfile = profileId => {
      // TODO: Not sure this is required anymore,
      //  the attributes was only used by the advanced and manual request dialogs
      //  Refactoring candidate...
      subjectAccessRequestService.getAndAggregateIDSORAttributes(profileId).then(
        aggregatedAttributes => {
          newSubjectAccessRequestModel.attributes = {
            searchable: aggregatedAttributes.searchable,
            other: aggregatedAttributes.other,
          };
        },
        () => {
          newSubjectAccessRequestModel.cannotSubmitScan = true; // no-unused-vars candidate
          // newSubjectAccessRequestModel.showAttributesList = true; // no-unused-vars candidate
        },
      );
    };

    newSubjectAccessRequestModel.setSelectedProfile = profileSelected => {
      if (
        newSubjectAccessRequestModel.profileSelected &&
        profileSelected._id !== newSubjectAccessRequestModel.profileSelected._id
      ) {
        newSubjectAccessRequestModel.clear();
        newSubjectAccessRequestModel.getIdentitySourcesForProfile(profileSelected._id);
        newSubjectAccessRequestModel.getAttributesForProfile(profileSelected._id);
      }
      newSubjectAccessRequestModel.profileSelected = profileSelected;
    };

    const TIMEOUT_MSG = `Some of the Identity Data Sources timed out.
        This may be due to a complex query or un indexed fields in the identity data source.
          Try using id=<user identifier> or name=<Full display name> for faster results.`;

    newSubjectAccessRequestModel.$onInit = function () {
      newSubjectAccessRequestModel.showGrid = false;
      newSubjectAccessRequestModel.showNoResultContainer = false;
      newSubjectAccessRequestModel.showAttributesList = false; // no-unused-vars candidate
      newSubjectAccessRequestModel.showNoAttributesLabel = false; // no-unused-vars candidate
      newSubjectAccessRequestModel.cannotSubmitScan = false; // no-unused-vars candidate

      if (newSubjectAccessRequestModel.profiles && !newSubjectAccessRequestModel.profileSelected) {
        newSubjectAccessRequestModel.profileSelected =
          newSubjectAccessRequestModel.profiles.find(profile => !profile.isCustom) ||
          newSubjectAccessRequestModel.profiles[0];
      }

      this.initSubjectAccessRequestModel();

      $scope.newSubjectAccessRequestModel.grid.onRegisterApi = function (gridApi) {
        $scope.gridApi = gridApi;
        gridApi.edit.on.afterCellEdit($scope, () => {
          $scope.$apply(); //FIXME: refactor
        });
        gridApi.selection.on.rowSelectionChanged($scope, function (row) {
          if (row.isSelected) {
            newSubjectAccessRequestModel.selectRowGrid(row);
          } else {
            // show the container but clear the data associated with previous selection
            // newSubjectAccessRequestModel.showManualRequestContainer = false;
            newSubjectAccessRequestModel.disableSubmitRequestBtn = true; // no-unused-vars candidate
            newSubjectAccessRequestModel.userData = undefined;
            newSubjectAccessRequestModel.selectedUsersData = undefined;
            newSubjectAccessRequestModel.clearManualRequest();
          }
        });
      };
    };

    newSubjectAccessRequestModel.$onChanges = onChangesObject => {
      const { profiles } = onChangesObject;
      if (profiles && !profiles.previousValue && profiles.currentValue) {
        newSubjectAccessRequestModel.profileSelected =
          profiles.currentValue.find(profile => !profile.isCustom) || profiles.currentValue[0];
        if (!!newSubjectAccessRequestModel.profileSelected) {
          newSubjectAccessRequestModel.getIdentitySourcesForProfile(newSubjectAccessRequestModel.profileSelected._id);
          newSubjectAccessRequestModel.getAttributesForProfile(newSubjectAccessRequestModel.profileSelected._id);
        }
      }
    };

    newSubjectAccessRequestModel.initSubjectAccessRequestModel = () => {
      $scope.newSubjectAccessRequestModel.grid = {
        enableSorting: true,
        enablePaginationControls: false,
        enableRowSelection: true,
        multiSelect: false,
        enableRowHeaderSelection: false,
        columnDefs: [
          {
            name: 'Unique Id',
            field: 'unique_id',
            cellTemplate:
              '<div style="cursor: pointer;color:#2196f3;"  title="{{row.entity.unique_id}}"><a>{{row.entity.unique_id}}</a></div>',
            enableCellEdit: false,
          },
          {
            name: 'Name',
            field: 'name',
            cellTemplate: '<div title="{{row.entity.name}}">{{row.entity.name}}</div>',
            enableCellEdit: false,
          },
          {
            name: 'Residency',
            field: 'residency',
            cellTemplate: '<div title="{{row.entity.residency}}"}>{{row.entity.residency}}</div>',
            enableCellEdit: false,
          },
        ],
      };
      if (getApplicationPreference('ENTITY_LEVEL_TAGGING_SUPPORTED')) {
        $scope.newSubjectAccessRequestModel.grid.columnDefs.push({
          name: 'Tags',
          field: 'tags',
          cellTemplate: '<div title="Tag"}>{{row.entity.tags}}</div>',
          enableCellEdit: false,
        });
      }
    };

    newSubjectAccessRequestModel.selectRowGrid = row => {
      let _id = null;
      if (row.entity && (row.entity.id || row.entity.unique_id)) {
        _id = row.entity.id || row.entity.unique_id;
      }
      newSubjectAccessRequestModel.showManualRequestContainer = true;
      newSubjectAccessRequestModel.disableSubmitRequestBtn = false; // no-unused-vars candidate
      newSubjectAccessRequestModel.userData = row.entity.id
        ? newSubjectAccessRequestModel.usersData.filter(item => item.id === _id)
        : newSubjectAccessRequestModel.usersData.filter(item => item.unique_id === _id);

      if (newSubjectAccessRequestModel.userData && newSubjectAccessRequestModel.userData.length > 0) {
        newSubjectAccessRequestModel.selectedUsersData = newSubjectAccessRequestModel.userData;
        newSubjectAccessRequestModel.setManualRequestUserId();
        newSubjectAccessRequestModel.setManualRequestInputAttributes();
      }
    };

    newSubjectAccessRequestModel.setManualRequestInputAttributes = () => {
      // prepare sar attributes for sar-manual-request component:
      // the logic here was inherited from previous versions of the component but converted into functional programming
      // the logic has strange results now AND this is why we need to filter by field_value as the final step
      newSubjectAccessRequestModel.manualRequestAttributes = Object.values(newSubjectAccessRequestModel.attributes)
        // flat array
        .reduce((acc, arr) => acc.concat(arr), [])
        .map(attribute => {
          const originalName = attribute.original_name;
          const { attributesMap, attributes } = Array.isArray(newSubjectAccessRequestModel.userData)
            ? newSubjectAccessRequestModel.userData[0]
            : {};
          if (attributesMap) {
            return { originalName, value: attributesMap[originalName] };
          } else if (attributes) {
            const inputAttribute = attributes.find(origAttr => origAttr.field_name === originalName);
            if (inputAttribute) {
              return { originalName: inputAttribute.field_name, value: inputAttribute.field_value };
            }
          }
        })
        .filter(inputAttribute => inputAttribute && inputAttribute.value !== undefined);
    };

    newSubjectAccessRequestModel.setManualRequestUserId = () => {
      newSubjectAccessRequestModel.manualRequestUserId = undefined;
      newSubjectAccessRequestModel.manualRequestDisplayName = undefined;
      if (Array.isArray(newSubjectAccessRequestModel.selectedUsersData)) {
        const { id, unique_id, name } = newSubjectAccessRequestModel.selectedUsersData[0] || {};
        newSubjectAccessRequestModel.manualRequestDisplayName = name;
        if (id) {
          newSubjectAccessRequestModel.manualRequestUserId = id;
        } else if (unique_id) {
          newSubjectAccessRequestModel.manualRequestUserId = unique_id;
        }
      }
    };

    newSubjectAccessRequestModel.onIdentitySourceSelected = idSourceName => {
      newSubjectAccessRequestModel.fetchingAttributes = true;

      subjectAccessRequestService.getScannedAttributes(idSourceName).then(
        result => {
          newSubjectAccessRequestModel.scannedAttributes = result.attributes;

          $timeout(() => {
            newSubjectAccessRequestModel.fetchingAttributes = false;
          }, 300);
        },
        err => {
          console.error(`error while getting scanned attributes ${err}`);
          newSubjectAccessRequestModel.fetchingAttributes = false;
        },
      );
    };

    newSubjectAccessRequestModel.searchUsers = (query, idSourceName, profileId) => {
      newSubjectAccessRequestModel.cachedUsers = [];
      newSubjectAccessRequestModel.showNoResultContainer = false;
      newSubjectAccessRequestModel.showManualRequestContainer = false;
      newSubjectAccessRequestModel.clearManualRequest();
      newSubjectAccessRequestModel.showGrid = false;
      newSubjectAccessRequestModel.showAttributesList = false; // no-unused-vars candidate

      if (query && query !== '') {
        newSubjectAccessRequestModel.calledToClearResults = false;
        newSubjectAccessRequestModel.loading = true;
        const usersArr = [];
        newSubjectAccessRequestModel.calledToBothSearchMethods = 0;

        //search in idsor
        if (typeof idSourceName == 'undefined') {
          subjectAccessRequestService
            .getSARIdentities({ query, profileId })
            .then(
              async results => {
                newSubjectAccessRequestModel.showPulse = false;
                if (results && !newSubjectAccessRequestModel.calledToClearResults) {
                  await newSubjectAccessRequestModel.setValuesFromIDSOR(results, usersArr);
                  handleSearchUsersErrors(results);
                  newSubjectAccessRequestModel.loading = false;
                }
              },
              () => {
                newSubjectAccessRequestModel.showPulse = false;
                newSubjectAccessRequestModel.loading = false;
              },
            )
            .finally(() => {
              $scope.$applyAsync();
            });

          //search in scanned identities
          subjectAccessRequestService.getUserByFilter({ filterValue: query, profileId }).then(
            results => {
              if (results && !newSubjectAccessRequestModel.calledToClearResults) {
                newSubjectAccessRequestModel.loading = false;
                newSubjectAccessRequestModel.showPulse = true;
                newSubjectAccessRequestModel.setValuesFromScannedIdentities(results);
              }
            },
            () => {
              newSubjectAccessRequestModel.loading = false;
              newSubjectAccessRequestModel.showNoResultContainer = true;
              newSubjectAccessRequestModel.showManualRequestContainer = false;
              newSubjectAccessRequestModel.showPulse = false;
            },
          );
        } else {
          newSubjectAccessRequestModel.showPulse = true;

          subjectAccessRequestService
            .getSARIdentitiesAdvanced({ query, idSourceName, profileId })
            .then(
              async results => {
                newSubjectAccessRequestModel.loading = false;
                newSubjectAccessRequestModel.showPulse = false;

                if (results && !newSubjectAccessRequestModel.calledToClearResults) {
                  newSubjectAccessRequestModel.calledToBothSearchMethods++;
                  await newSubjectAccessRequestModel.setValuesFromIDSOR(results, usersArr);
                  handleSearchUsersErrors(results);
                }
              },
              () => {
                newSubjectAccessRequestModel.showGrid = false;
                newSubjectAccessRequestModel.loading = false;
                newSubjectAccessRequestModel.showPulse = false;
              },
            )
            .finally(() => {
              $scope.$applyAsync();
            });
        }
      }
    };

    function handleSearchUsersErrors(results) {
      if (Array.isArray(results.errMessages) && results.errMessages.length) {
        const isTimeOutErrMsg = results.errMessages.find(errorItem => errorItem.isTimeOutErrMsg === true);
        if (isTimeOutErrMsg) {
          notificationService.warning(TIMEOUT_MSG, {
            closeButton: true,
            progressBar: false,
            tapToDismiss: true,
            timeOut: 0,
          });
        } else if (results.errMessages[0] && results.errMessages[0].message) {
          notificationService.error(results.errMessages[0].message, {
            closeButton: true,
            progressBar: false,
            tapToDismiss: true,
            timeOut: 0,
          });
        }
      }
    }

    newSubjectAccessRequestModel.setValuesFromScannedIdentities = async result => {
      if (result.length === 0 && ++newSubjectAccessRequestModel.calledToBothSearchMethods === 2) {
        newSubjectAccessRequestModel.showGrid = false;
        newSubjectAccessRequestModel.showNoResultContainer = true;
        newSubjectAccessRequestModel.showManualRequestContainer = false;
      } else {
        if (result.length > 0) {
          newSubjectAccessRequestModel.showGrid = true;
        }
        const userToShowInGrid = [];
        newSubjectAccessRequestModel.clearDataInGrid();
        newSubjectAccessRequestModel.usersData = result;
        result.forEach(user => {
          user.name = `${user.name} (cached)`;
          newSubjectAccessRequestModel.cachedUsers.push(user);
          userToShowInGrid.push(user);
        });
        await newSubjectAccessRequestModel.addUsersToGrid(userToShowInGrid);
      }
    };

    newSubjectAccessRequestModel.setValuesFromIDSOR = async (results, calledToBothSearchMethods, usersArr) => {
      if (results != null && results.usersMap != null) {
        usersArr = Object.values(results.usersMap);
      }
      if (usersArr.length === 0 && ++newSubjectAccessRequestModel.calledToBothSearchMethods === 2) {
        newSubjectAccessRequestModel.showNoResultContainer = true;
        newSubjectAccessRequestModel.showGrid = false;
        newSubjectAccessRequestModel.showManualRequestContainer = false;
        newSubjectAccessRequestModel.clearManualRequest();
      } else {
        // not sure all this working as expected because nothing special happens when Non cached data populated
        // in UI (except the Cached indication removed in ui-grid
        newSubjectAccessRequestModel.usersData = usersArr.map(user => user.attributes);
        if (newSubjectAccessRequestModel.usersData && newSubjectAccessRequestModel.usersData.length > 0) {
          newSubjectAccessRequestModel.showGrid = true;
        }
        newSubjectAccessRequestModel.clearDataInGrid();
        const userToShowInGrid = [];
        newSubjectAccessRequestModel.usersData.forEach(userData => {
          if (newSubjectAccessRequestModel.cachedUsers) {
            newSubjectAccessRequestModel.cachedUsers = newSubjectAccessRequestModel.cachedUsers.filter(
              cachedUser => !cachedUser.unique_id.includes(userData.unique_id),
            );
          }
          userToShowInGrid.push(userData);
        });
        if (newSubjectAccessRequestModel.cachedUsers) {
          newSubjectAccessRequestModel.cachedUsers.forEach(user => {
            userToShowInGrid.push(user);
          });
        }
        await newSubjectAccessRequestModel.addUsersToGrid(userToShowInGrid);
      }
    };

    newSubjectAccessRequestModel.addUsersToGrid = async users => {
      let usersToGrid = users;
      $scope.newSubjectAccessRequestModel.grid.data = $scope.newSubjectAccessRequestModel.grid.data || [];
      if (getApplicationPreference('ENTITY_LEVEL_TAGGING_SUPPORTED')) {
        usersToGrid = await enrichTagsToUsersIfNeeded(users);
      }
      $scope.newSubjectAccessRequestModel.grid.data = $scope.newSubjectAccessRequestModel.grid.data.concat(usersToGrid);
    };

    newSubjectAccessRequestModel.clearDataInGrid = () => {
      $scope.newSubjectAccessRequestModel.grid.data = [];
    };

    function enrichTagsToUsersIfNeeded(users) {
      const chunks = chunkArray(users);
      const taggedEntitiesP = getTaggedEntitiesPerChunkP(chunks);
      const userTaggedEntities = {};
      return Promise.all(taggedEntitiesP)
        .then(taggedEntities => {
          taggedEntities.map(currTaggedEntities => {
            currTaggedEntities.taggedEntities.forEach(tagEntity => {
              userTaggedEntities[tagEntity.entityId] = tagEntity.tags
                .map(tag => `${tag.tag_name}=${tag.tag_value}`)
                .join(', ');
            });
          });
        })
        .then(() =>
          users.map(user => {
            if (userTaggedEntities[user.unique_id]) {
              user.tags = userTaggedEntities[user.unique_id];
            }
            return user;
          }),
        );
    }

    function chunkArray(users) {
      let tmpArr = [];
      const chunks = [];

      users
        .filter(user => user.unique_id)
        .map(user => user.unique_id)
        .forEach(uniqueId => {
          if (tmpArr.length > 0 && tmpArr.length % 30 === 0) {
            chunks.push(tmpArr);
            tmpArr = [];
          } else {
            tmpArr.push(uniqueId);
          }
        });

      if (tmpArr.length > 0) {
        chunks.push(tmpArr);
        tmpArr = [];
      }
      return chunks;
    }

    function getTaggedEntitiesPerChunkP(chunks) {
      const delay = t => new Promise(resolve => setTimeout(resolve, t));
      return chunks
        .map(chunk => chunk.join())
        .map((uniqueIds, i) => {
          return delay(i * 100).then(() => subjectAccessRequestService.getTaggedEntity(uniqueIds));
        });
    }

    newSubjectAccessRequestModel.clear = () => {
      newSubjectAccessRequestModel.calledToClearResults = true;
      newSubjectAccessRequestModel.showPulse = false;
      newSubjectAccessRequestModel.showGrid = false;
      newSubjectAccessRequestModel.showNoAttributesLabel = false; // no-unused-vars candidate
      newSubjectAccessRequestModel.showAttributesList = false; // no-unused-vars candidate
      newSubjectAccessRequestModel.showNoResultContainer = false;
      newSubjectAccessRequestModel.disableSubmitRequestBtn = true; // no-unused-vars candidate
      newSubjectAccessRequestModel.showManualRequestContainer = false;
      newSubjectAccessRequestModel.clearManualRequest();
    };

    newSubjectAccessRequestModel.clearManualRequest = () => {
      newSubjectAccessRequestModel.manualRequestUserId = undefined;
      newSubjectAccessRequestModel.manualRequestDisplayName = undefined;
      newSubjectAccessRequestModel.manualRequestAttributes = undefined;
    };

    newSubjectAccessRequestModel.goToManualRequest = () => {
      newSubjectAccessRequestModel.createSarPaneCtrl.navigateToRequestType(REQUEST_TYPES.manual);
    };

    // no-unused-vars candidate
    $scope.select = function (row) {
      $scope.gridApi.selection.clearSelectedRows();
      newSubjectAccessRequestModel.selectRowGrid(row);
    };
  },
  bindings: {
    onSubmit: '&',
    userQuery: '<',
    profiles: '<',
  },
});
