import {
  BigidAdvancedToolbarFilter,
  BigidAdvancedToolbarFilterTypes,
  BigidAdvancedToolbarFilterUnion,
} from '@bigid-ui/components';
import { generateDataAid } from '@bigid-ui/utils';
import { styled } from '@mui/material';
import React from 'react';
import { useLocalTranslation } from './translations';
import { useAuditTrialGridContext } from './dataCatalogAuditContext';
import { mapDropdownFiltersToFilterString } from '../../../utilities/toolbarFilterToBIQL/toolbarFilterToBIQL';

const ToolbarContainer = styled('div')`
  display: flex;
`;

export const DataCatalogAuditTrailToolbar = () => {
  const { t } = useLocalTranslation('filters');
  const { dispatch } = useAuditTrialGridContext();
  const filtersConfig: BigidAdvancedToolbarFilterUnion[] = [
    {
      field: 'property_operation',
      type: BigidAdvancedToolbarFilterTypes.DROPDOWN,
      id: 'property_operation',
      operator: 'in',
      options: [
        {
          displayValue: t('propertyOperation.options.create'),
          value: 'create',
          id: 'create',
        },
        {
          displayValue: t('propertyOperation.options.update'),
          value: 'update',
          id: 'update',
        },
        {
          displayValue: t('propertyOperation.options.delete'),
          value: 'delete',
          id: 'delete',
        },
      ],
      title: t('propertyOperation.label'),
      isSearchable: false,
    },
    {
      field: 'changed_property',
      type: BigidAdvancedToolbarFilterTypes.DROPDOWN,
      id: 'changed_property',
      operator: 'in',
      options: [
        {
          displayValue: t('changedProperty.options.attribute'),
          value: 'attribute',
          id: 'attribute',
        },
        {
          displayValue: t('changedProperty.options.tag'),
          value: 'tag',
          id: 'tag',
        },
        {
          displayValue: t('changedProperty.options.column'),
          value: 'column',
          id: 'column',
        },
      ],
      title: t('changedProperty.label'),
      isSearchable: false,
    },
    {
      field: 'date',
      type: BigidAdvancedToolbarFilterTypes.DATE_RANGE,
      id: 'date',
      operator: 'in',
      title: t('date.label'),
      options: {
        currentRangeOptionSelected: 'none',
        pickersState: {
          currentMode: 'from',
          dates: {
            from: null,
            until: null,
          },
        },
      },
    },
  ];

  const handleFiltersChange = (filters: BigidAdvancedToolbarFilterUnion[]) => {
    const filterString = mapDropdownFiltersToFilterString(filters);

    dispatch({
      type: 'SET_FILTER_STRING',
      payload: filterString,
    });
  };

  return (
    <ToolbarContainer>
      <BigidAdvancedToolbarFilter
        filters={filtersConfig}
        onFiltersChange={handleFiltersChange}
        dataAid={generateDataAid('DataCatalogAuditTrailToolbar', ['toolbar'])}
      />
    </ToolbarContainer>
  );
};
