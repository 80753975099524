import React, { FC, useContext, useMemo, useRef, useState } from 'react';
import { BigidBody1, BigidMenu, BigidMenuItem, TertiaryButton } from '@bigid-ui/components';
import { BigidActionIcon, BigidChevronDownIcon } from '@bigid-ui/icons';
import styled from '@emotion/styled';
import { CaseActionsModal } from './CaseActionsModal';
import { CaseSidePanelContext } from '../../../hooks/CaseSidePanelContext';
import { ActionsListDataProps, ActionsListProps } from '../../../hooks/CaseSidePanelReducer';
import { generateDataAid } from '@bigid-ui/utils';

export const REVOKE_EXTERNAL_ACCESS = 'Revoke External Access';
export const REVOKE_OPEN_ACCESS = 'Revoke Open Access';
export const ENABLE_ACCESS_LOGGING = 'Enable Access Logging';
export const RESTRICT_PUBLIC_ACCESS = 'Restrict Public Access';

export const SEND_TO_CORTEX = 'Send to Cortex';
export const SEND_TO_CORTEX_ACTION = 'send_to_cortex';

export const ACTION_COMPLETED = 'COMPLETED';
export const ACTION_IN_PROGRESS = 'IN_PROGRESS';

export const CaseActionCommands = {
  REVOKE_EXTERNAL_ACCESS_COMMAND: 'Revoke External Access',
  REVOKE_OPEN_ACCESS_COMMAND: 'Revoke Open Access',
  ENABLE_ACCESS_LOGGING_COMMAND: 'logging',
  RESTRICT_PUBLIC_ACCESS_COMMAND: 'public_access',
  SEND_TO_CORTEX_COMMAND: 'send_to_cortex_default_command',
};

export const mapCommandToDisplayName = new Map<string, string>([
  ['Revoke External Access', REVOKE_EXTERNAL_ACCESS],
  ['Revoke Open Access', REVOKE_OPEN_ACCESS],
  ['logging', ENABLE_ACCESS_LOGGING],
  ['public_access', RESTRICT_PUBLIC_ACCESS],
  ['send_to_cortex_default_command', SEND_TO_CORTEX],
]);

const MenuItemWrapper = styled.div({
  width: 229,
  display: 'grid',
  gridTemplateColumns: '40px 169px',
  alignItems: 'center',
  cursor: 'pointer',
});

const IconWrapper = styled.div({
  display: 'flex',
  justifyContent: 'center',
});

export const capitalizeWords = (str: string) => {
  return str
    .split(' ')
    .map(word => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
};

export const getCommandToDisplayName = (action: string) => {
  const displayName = mapCommandToDisplayName.get(action);
  return displayName ? displayName : capitalizeWords(action);
};

export const CaseActionsWidget: FC = () => {
  const { caseSidePanelData, actionsList, enableActionsOnCaseLevel, dispatch } = useContext(CaseSidePanelContext);
  const [open, setOpen] = useState(false);
  const menuSectionRef = useRef();
  const listOfActions = Object.keys(actionsList);

  const handleClick = () => setOpen(!open);
  const handleClose = () => {
    setOpen(false);
  };

  const menuProps = {
    open,
    onMenuClose: handleClose,
    anchorEl: menuSectionRef.current,
  };

  const handleSelectItem = (actionData: ActionsListDataProps) => {
    CaseActionsModal(caseSidePanelData, true, [], dispatch, actionData).then(() => {
      setOpen(false);
    });
  };

  const currentAction = (actionData: ActionsListDataProps) => {
    return handleSelectItem(actionData);
  };

  const MenuItems = useMemo(() => {
    return (
      <BigidMenu {...menuProps}>
        {listOfActions?.map((action: string) => {
          const displayName = getCommandToDisplayName(action);
          return (
            <BigidMenuItem
              key={action}
              onClick={() => currentAction(actionsList[action as keyof ActionsListProps])}
              disabled={
                actionsList[action as keyof ActionsListProps]?.latestExecution?.status === ACTION_IN_PROGRESS ||
                (actionsList[action as keyof ActionsListProps]?.latestExecution?.status === ACTION_COMPLETED &&
                  actionsList[action as keyof ActionsListProps]?.latestExecution?.isBlocked) ||
                !enableActionsOnCaseLevel
              }
              dataAid={generateDataAid('MetaDataWidget', ['actions', action])}
            >
              <MenuItemWrapper>
                <IconWrapper>
                  <BigidActionIcon />
                </IconWrapper>
                <BigidBody1>{displayName}</BigidBody1>
              </MenuItemWrapper>
            </BigidMenuItem>
          );
        })}
      </BigidMenu>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [actionsList, currentAction, listOfActions, menuProps]);

  const NoActionsItem = useMemo(() => {
    return (
      <BigidMenu {...menuProps}>
        <BigidMenuItem key="NoOptions">
          <MenuItemWrapper>
            <BigidBody1>No options</BigidBody1>
          </MenuItemWrapper>
        </BigidMenuItem>
      </BigidMenu>
    );
  }, [menuProps]);

  return (
    <>
      <div ref={menuSectionRef}>
        <TertiaryButton
          onClick={handleClick}
          size="medium"
          endIcon={<BigidChevronDownIcon />}
          text="All actions"
          dataAid={generateDataAid('MetaDataWidget', ['actions'])}
        />
      </div>
      {listOfActions?.length > 0 ? MenuItems : NoActionsItem}
    </>
  );
};
