import { DateISO8601 } from '../../types/types';

export enum DsarMainTabPaths {
  NEW_REQUEST_PATH = 'new-request',
  PENDING_REQUEST_PATH = 'pending-requests',
  COMPLETED_REQUEST_PATH = 'completed-requests',
  DELETION_VALIDATION_PATH = 'deletion-validation-requests',
  DSAR_AUDIT_PATH = 'audit',
}

export enum DsarSettingsTabPaths {
  SAR_PROFILE_SETTINGS_PATH = 'profile',
  JOINT_CORRELATIONS_MAPPING_PATH = 'correlations-mapping',
  PDF_REPORT_TEMPLATES_PATH = 'report-templates',
  ME_SETTINGS_PATH = 'me-settings',
  FILES_RETRIEVAL_PATH = 'files-retrieval',
  DICTIONARY_PATH = 'dictionary',
}

export type DsarTabPaths = DsarMainTabPaths | DsarSettingsTabPaths;
export const DsarTabPaths = { ...DsarSettingsTabPaths, ...DsarMainTabPaths };

export enum DsarDictionaryStatus {
  IN_PROGRESS = 'in_progress',
  FINISHED = 'finished',
  FAILED = 'failed',
}

export interface DsarDictionary {
  id: string;
  name: string;
  fileName: string;
  createdDate: DateISO8601;
  updatedDate: DateISO8601;
  message?: string;
  inUse?: boolean;
  status: DsarDictionaryStatus;
  statistics: {
    imported: number;
    skipped: number;
    duplicates: number;
  };
}
