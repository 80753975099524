import { httpRequest } from '../../../../../config/request';
import type { GetDsConnectionEnrichmentStatusItemResponse, GetDsConnectionEnrichmentStatusItemRequest } from '../types';

export const DS_ENRICHMENT_ENDPOINT = 'ds-connections';

export const getDsConnectionEnrichmentStatus = async (
  data: GetDsConnectionEnrichmentStatusItemRequest,
): Promise<GetDsConnectionEnrichmentStatusItemResponse> =>
  httpRequest<GetDsConnectionEnrichmentStatusItemResponse>({
    url: `${DS_ENRICHMENT_ENDPOINT}/grouped-statuses`,
    method: 'post',
    data,
  });
