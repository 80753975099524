import { module } from 'angular';
import { MappingProcessViewModel } from './mapping-process.view-model';
import './dataMappingTab.component.scss';
import { pdfSharedDataReportService } from '../../react/services/pdfSharedDataReportService';
import { isPermitted } from '../../react/services/userPermissionsService';
import { BUSINESS_FLOWS_PERMISSIONS } from '@bigid/permissions';
import template from './dataMappingTab.component.html';
const app = module('app');
import { lazyLoadGoJs } from '../../identity-lineage/identity-lineage-graph/identity-lineage-graph.component';

app.component('datamappingTab', {
  template,
  controller: [
    '$translate',
    '$rootScope',
    '$scope',
    '$timeout',
    'notificationService',
    'DeleteConfirmation',
    'locationService',
    'dataMappingService',
    '$q',
    'dataSourceConnectionsService',
    'applicationSetupService',
    'localStorageService',
    'dataMappingReportService',
    '$window',
    '$uibModal',
    'dataMappingDocxReportService',
    function (
      $translate,
      $rootScope,
      $scope,
      $timeout,
      notificationService,
      DeleteConfirmation,
      locationService,
      dataMappingService,
      $q,
      dataSourceConnectionsService,
      applicationSetupService,
      localStorageService,
      dataMappingReportService,
      $window,
      $uibModal,
      dataMappingDocxReportService,
    ) {
      this.legendOpen = true;
      this.hasManagePermission = isPermitted(BUSINESS_FLOWS_PERMISSIONS.MANAGE.name);

      this.$onInit = () => {
        // load go.js in parent component
        lazyLoadGoJs().then(() => {
          this.goJsLoaded = true;
          $scope.$evalAsync();
        });
      };

      this.$onChanges = changes => {
        //FIXME: big mess in architecture, temporal solution, refactoring required
        const isExistingProcessesWindowOpened =
          typeof this.vm != 'undefined'
            ? typeof this.vm.openExistingBusinessProcess != 'undefined'
              ? this.vm.openExistingBusinessProcess
              : false
            : false;

        if (
          changes.businessProcess &&
          !angular.equals(changes.businessProcess.currentValue, changes.businessProcess.previousValue)
        ) {
          const selectedEntityIDByEmail = changes.selectedentityidbyemail
            ? changes.selectedentityidbyemail.currentValue
            : false;
          this.vm = new MappingProcessViewModel(
            changes.businessProcess.currentValue,
            notificationService,
            DeleteConfirmation,
            locationService,
            dataMappingService,
            $q,
            dataSourceConnectionsService,
            applicationSetupService,
            localStorageService,
            dataMappingReportService,
            selectedEntityIDByEmail,
            $window,
            dataMappingDocxReportService,
            this.ropaMode,
          );
        }
        if (!changes.businessProcess.currentValue) {
          if (this.vm) {
            this.vm.graphData = null;
          }
        }
        if (this.vm) {
          this.vm.isReport = false;
        }

        //FIXME: big mess in architecture, temporal solution, refactoring required
        this.vm.openExistingBusinessProcess = isExistingProcessesWindowOpened;
      };

      this.onGraphAction = (action, data) => {
        $scope.$evalAsync(() => {
          switch (action) {
            case 'updateClassifier':
              this.vm.editClassificationName(data.classification, data.textNew, data.textPrevious);
              break;
            case 'deleteLane':
              this.hasManagePermission && this.vm.deleteLane(data.laneNumber);
              break;
            case 'createEntity':
            case 'editEntity':
              this.vm.editEntity(data);
              break;
            case 'updatePosition':
              this.hasManagePermission && this.vm.updatePosition(data.entity, data.direction);
              break;
            case 'deleteEntity':
              this.hasManagePermission && this.vm.deleteEntity(data);
              break;
            case 'createLink':
            case 'editLink':
              if (!this.hasManagePermission) {
                return;
              }

              if (action === 'createLink') {
                this.vm.selectedConnector = null;
                data.flowId = this.vm.businessFlows[0]._id;
              }
              this.hasManagePermission && this.vm.editConnector(data);
              break;
            case 'reLink':
              this.hasManagePermission && this.vm.saveConnector(true, null, data);
              break;
            case 'deleteLink':
              this.hasManagePermission && this.vm.deleteConnector(data);
              break;
            case 'openCollaboration':
              if (data.hasActiveCollaborationTask) {
                this.vm.openCollaborationWindow(data);
              }
              break;
            case 'openTaskList':
              if (data.hasActiveCollaborationTask || data.hasOpenTasks) {
                this.vm.openTaskListWindow(data);
              }
              break;
            case 'cancelEdit':
              this.vm.cancelEdit();
              break;
            case 'createReportPDF':
              this.openReportModal(data.imageData, this.vm);
              break;
            default:
              break;
          }
        });
      };

      this.openReportModal = (imageData, vm) => {
        $uibModal
          .open({
            template: `<third-party-shared-data-report-modal
              dismiss="$dismiss()"
              data="$ctrl.data"
              on-download-shared-data-report="$ctrl.downloadSharedDataReportAsPdf()"
              on-download-shared-data-report-as-csv="$ctrl.downloadSharedDataReportAsCsv()"
              on-download-business-process-report="$ctrl.createBusinessProcessReport(type)"
            />`,
            controllerAs: '$ctrl',
            controller: function () {
              this.data = {
                processName: vm.businessProcess.title,
              };

              this.downloadSharedDataReportAsPdf = () => pdfSharedDataReportService.downloadSharedDataReportAsPdf();

              this.downloadSharedDataReportAsCsv = () => {
                pdfSharedDataReportService
                  .downloadSharedDataReportAsCsv()
                  .then(() => {
                    notificationService.success('CSV download complete.');
                  })
                  .catch(err => {
                    $translate('API:MESSAGE:COMMON_ERROR').then(translation => {
                      notificationService.error(translation);
                    });
                    console.error('Error in downloadSharedDataReportAsCsv', err);
                    throw err;
                  });
              };

              this.createBusinessProcessReport = type => {
                return vm.createReport(imageData, vm, type);
              };
            },
          })
          .result.finally(() => {
            vm.isReport = false;
          });
      };

      this.toggleLegend = openState => {
        this.legendOpen = typeof openState === 'boolean' ? openState : !this.legendOpen;
        $timeout(() => {
          this.graphReloadFlag = !this.graphReloadFlag;
        }, 400); // [DA]: 400 ms delay to account for animation
      };

      this.deleteBusinessProcess = businessProcess => {
        this.onDeleteBusinessProcess({ businessProcess: businessProcess });
      };

      this.openBusinessProcess = businessProcess => {
        this.onOpenBusinessProcess({ businessProcess: businessProcess });
      };
    },
  ],
  bindings: {
    businessProcess: '<',
    ropaMode: '<',
    selectedentityidbyemail: '<',
    showSetPropertiesPage: '<',
    onDeleteBusinessProcess: '&',
    onOpenBusinessProcess: '&',
  },
});
