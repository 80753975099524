import React, { FC } from 'react';
import { CatalogListItemContainer, DataSourceContainer } from './CatalogListItem';
import { CONFIG } from '../../../../../../../config/common';
import { $state } from '../../../../../../services/angularServices';
import { BigidBody1 } from '@bigid-ui/components';
import { DataSourceIconByDsType } from '@bigid-ui/icons';
import { styled } from '@mui/material';
import { sendDataExplorerQuickSearchListItemClickBiAnalytics } from '../../../../services/analytics';
import { mapFieldValueToBiqlString } from '../../../../../../services/filtersToBiqlService';
import { generateDataAid } from '@bigid-ui/utils';

type RecentlyViewedListItemProps = {
  dsName: string;
  dsType: string;
  fullyQualifiedName: string;
  objectName: string;
  dataAid?: string;
};

const StyledName = styled(BigidBody1)`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const StyledDataSource = styled(BigidBody1)`
  white-space: nowrap;
`;

export const RecentlyViewedListItem: FC<RecentlyViewedListItemProps> = ({
  dsName,
  dsType,
  fullyQualifiedName,
  objectName,
  dataAid = 'RecentlyViewedListItem',
}) => {
  const handleOnClick = () => {
    sendDataExplorerQuickSearchListItemClickBiAnalytics({ entityName: 'recentlyViewed', id: fullyQualifiedName });
    $state.go(CONFIG.states.CATALOG_PREVIEW, { id: encodeURIComponent(fullyQualifiedName), name: objectName });
  };

  return (
    <CatalogListItemContainer onClick={handleOnClick} data-aid={generateDataAid(dataAid, ['container'])}>
      <StyledName>{objectName}</StyledName>
      <DataSourceContainer>
        <DataSourceIconByDsType dataSourceType={dsType} width={24} />
        <StyledDataSource>{dsName}</StyledDataSource>
      </DataSourceContainer>
    </CatalogListItemContainer>
  );
};
