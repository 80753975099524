import { BigidFormFieldTypes } from '@bigid-ui/components';
import { Category, ClassifierGridRow, RegexFormFields } from '../types/ClassifierTypes';
import { getFixedT } from '../translations';
import { CategoriesFieldDropdown } from '../components/CategoriesField/CategoriesFieldDropdown';
import { AttributesFieldDropdown } from '../components/AttributesField/AttributesFieldDropdown';
import { isEmpty } from 'lodash';

const t = getFixedT('form');

export const initialFormValues = {
  [RegexFormFields.categories]: [] as Category[],
  attribute: {
    friendly_name: '',
  },
  [RegexFormFields.isOverrideSureMatch]: false,
};

export const getInitialFormData = (initialData: Partial<ClassifierGridRow>) => ({
  initialValues: {
    ...initialFormValues,
    ...initialData,
    attribute: {
      friendly_name: initialData?.friendly_name,
      glossary_id: initialData?.glossary_id || '',
    },
  },
  fields: [
    {
      name: 'attribute',
      render: AttributesFieldDropdown,
      isRequired: false,
      fieldProps: {
        size: 'large',
      },
    },
    {
      name: RegexFormFields.categories,
      label: t('categories'),
      render: CategoriesFieldDropdown,
    },
    {
      name: RegexFormFields.isOverrideSureMatch,
      label: '',
      type: BigidFormFieldTypes.SWITCH,
      size: 'small',
      fieldProps: {
        disabled: isEmpty(initialData?.sources),
      },
    },
  ],
});
