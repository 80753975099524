import { sessionStorageService } from '../../common/services/sessionStorageService';
import { httpService } from './httpService';
import { notificationService } from './notificationService';
import { getApplicationPreference } from './appPreferencesService';

/**
 * RegEx	Description
 * (?=.*[a-z])	The string must contain at least 1 lowercase alphabetical character
 * (?=.*[A-Z])	The string must contain at least 1 uppercase alphabetical character
 * (?=.*[0-9])	The string must contain at least 1 numeric character
 * (?=.*[!@#$%^&*])	The string must contain at least one special character, but we are escaping reserved RegEx characters to avoid conflict
 * (?=.{8,})	The string must be eight characters or longer
 **/

interface updatePasswordPayload {
  oldPassword: string;
  newPassword: string;
}

export const passwordRegex = new RegExp('^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})');

export function isStrongPasswordSecurityEnabled() {
  return getApplicationPreference('ENFORCE_STRONG_PASSWORD_SECURITY');
}

export function isPasswordChangeNeeded() {
  return (
    isStrongPasswordSecurityEnabled() && sessionStorageService.get('isPasswordChangeNeeded')?.toString() === 'true'
  );
}

export function isPasswordStrengthValid(password = '') {
  if (!isStrongPasswordSecurityEnabled()) {
    return true;
  }

  return passwordRegex.test(password);
}

export async function updatePassword({ oldPassword, newPassword }: updatePasswordPayload): Promise<boolean> {
  try {
    const userName = sessionStorageService.get('userName');
    await httpService.put(`system_users/${userName}/password`, {
      systemUser: {
        old_password: oldPassword,
        new_password: newPassword,
      },
      user_id: userName,
      password: oldPassword,
    });
    notificationService.success('Password changed successfully!');
    sessionStorageService.remove('isPasswordChangeNeeded');
    return true;
  } catch ({ response }) {
    const { status, data = {} } = response;
    const { message } = data;
    const errorMessage = status === 401 ? 'Incorrect Password - Please provide your current password' : message;
    notificationService.error(errorMessage);
    return false;
  }
}
