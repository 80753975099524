import { openSystemDialog } from '../../../services/systemDialogService';
import { noop } from 'lodash';
import { SelectDataSourceOnboardingTypeModal } from './SelectDataSourceOnboardingTypeModal';
import { getFixedT } from '../DataSourceConnections/translations';
import { getApplicationPreference } from '../../../services/appPreferencesService';
import { $state } from '../../../services/angularServices';
import { CONFIG } from '../../../../config/common';
import { DATA_SOURCES_INIT_VIEWS } from '../DataSources';

const t = getFixedT('CreateOnboarding');
export const openSelectDataSourceOnboarding = () => {
  if (getApplicationPreference('NEW_SELECT_DS_VIEW_ENABLED_FF')) {
    return openSystemDialog({
      borderBottom: false,
      title: t('modalTitle'),
      onClose: noop,
      content: SelectDataSourceOnboardingTypeModal,
      maxWidth: 'xl',
    });
  }

  $state.go(CONFIG.states.DATA_SOURCE_INIT, { path: DATA_SOURCES_INIT_VIEWS.selectDataType.path });
};
