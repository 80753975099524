import './dataMapping.component.scss';
import template from './dataMapping.component.html';
import { BUSINESS_FLOWS_PERMISSIONS } from '@bigid/permissions';
import { module } from 'angular';
import { HeaderEvents, headerEventEmitter } from '../react/services/eventEmitters/headerEvents';
import { isPermitted } from '../react/services/userPermissionsService';
import { pageHeaderService } from '../common/services/pageHeaderService';
import { CONFIG } from '../config/common';
const app = module('app');

app.component('datamapping', {
  template,
  controller: function (
    $rootScope,
    $document,
    $timeout,
    $q,
    $scope,
    $translate,
    $state,
    $stateParams,
    $transitions,
    DeleteConfirmation,
    dataMappingService,
    notificationService,
    localStorageService,
    ropaService,
  ) {
    'ngInject';
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const ctrl = this;

    const getViewportWidth = () => Math.max(document.documentElement.clientWidth, window.innerWidth || 0);

    const getViewportRatio = () => (window.innerWidth >= 1200 && window.innerWidth <= 1600 ? 0.8 : 0.85);

    const getVisibleTabsAmount = () => (window.innerWidth >= 1200 && window.innerWidth <= 1600 ? 6 : 8);

    const setScroll = () => {
      this.turnOnCarousel = this.businessProcessesTabs.length > visibleTabsAmount ? true : false;
      this.scrollLeftDisabled = true;
      this.scrollRightDisabled = this.businessProcessesTabs.length - visibleTabsAmount == 0 ? true : false;
    };

    const calculateOffset = id => {
      for (let i = 0; i < this.businessProcessesTabs.length; i++) {
        if (id == this.businessProcessesTabs[i]['_id']) {
          return (i + 1 - visibleTabsAmount) * this.tabOffset;
        }
      }

      return 0;
    };

    const tabsContainer = $document[0].getElementsByClassName('data-mapping-tabs-inner')[0];

    let viewportWidth = getViewportWidth(),
      viewportRatio = getViewportRatio(),
      visibleTabsAmount = getVisibleTabsAmount();

    const TRANSLATION_REQUIRED = [
      'DATA_MAPPING',
      'DATA_MAPPING:TOOL',
      'DATA_MAPPING:MORE_BUTTON:NEW_BUSINESS_PROCESS',
      'DATA_MAPPING:MORE_BUTTON:OPEN_BUSINESS_PROCESS',
      'DATA_MAPPING:MORE_BUTTON:DUPLICATE_BUSINESS_PROCESS',
      'DATA_MAPPING:MORE_BUTTON:DELETE_BUSINESS_PROCESS',
      'DATA_MAPPING:MORE_BUTTON:BUSINESS_PROCESS_PROPERTIES',
      'DATA_MAPPING:MORE_BUTTON:CREATE_REPORT',
    ];

    this.scrollLeft = 0;
    this.scrollLeftDisabled = true;
    this.scrollRightDisabled = false;
    this.turnOnCarousel = false;
    this.newProcessName = 'New';
    this.newTabBlock = false;
    this.isNewBusinessProcessMode = false;
    this.businessProcessesTabs = [];
    this.processTitleBackup = null;

    this.tabsContainerMaxWidth = viewportWidth * viewportRatio + 'px';
    this.tabOffset = (viewportWidth * viewportRatio) / visibleTabsAmount;
    this.tabContainerWidth = this.tabOffset - 5 + 'px';

    this.moreButtonItems = [];

    const onResize = () => {
      $scope.$evalAsync(() => {
        visibleTabsAmount = getVisibleTabsAmount();
        viewportWidth = getViewportWidth();
        viewportRatio = getViewportRatio();

        this.tabsContainerMaxWidth = viewportWidth * viewportRatio + 'px';
        this.tabOffset = (viewportWidth * viewportRatio) / visibleTabsAmount;
        this.tabContainerWidth = this.tabOffset - 5 + 'px';
      });
    };
    window.addEventListener('resize', onResize);

    Object.assign(this, { $rootScope, $translate, dataMappingService });

    this.$onInit = () => {
      this.isManagePermitted = isPermitted(BUSINESS_FLOWS_PERMISSIONS.MANAGE.name);
      $translate(TRANSLATION_REQUIRED).then(translation => {
        $rootScope.$broadcast('changePage', translation['DATA_MAPPING:TOOL'], false);

        this.moreButtonItems = this.buildMoreButtonItems(translation, this.ropaMode);

        if ($stateParams.shouldShowCreateReportModal) {
          $timeout(
            () => this.onMoreButtonItemClick(this.moreButtonItems.find(item => item.action === 'createPDF')),
            400,
          );
        }
      });

      this.showSetPropertiesPage = false;
      headerEventEmitter.emit(HeaderEvents.UPDATE_UNREAD_TASKS_AND_RISK);
      const { businessProcessId, ropaMode, ropaId } = $stateParams;
      this.ropaMode = ropaMode;
      if (this.ropaMode) {
        this.loadRopa(businessProcessId, ropaId);
      } else {
        this.loadLegacy();
      }
      this.addDeepLinkingHandlersToDataFlows();
    };

    this.buildMoreButtonItems = (translation, isRopa) => {
      if (isRopa) {
        return [
          {
            name: translation['DATA_MAPPING:MORE_BUTTON:BUSINESS_PROCESS_PROPERTIES'],
            action: 'setProcessProperties',
          },
          {
            name: translation['DATA_MAPPING:MORE_BUTTON:CREATE_REPORT'],
            action: 'createPDF',
          },
        ];
      }

      let result = [
        {
          name: translation['DATA_MAPPING:MORE_BUTTON:OPEN_BUSINESS_PROCESS'],
          action: 'openExistingProcess',
        },
        {
          name: translation['DATA_MAPPING:MORE_BUTTON:BUSINESS_PROCESS_PROPERTIES'],
          action: 'setProcessProperties',
        },
        {
          name: translation['DATA_MAPPING:MORE_BUTTON:CREATE_REPORT'],
          action: 'createPDF',
        },
      ];
      if (this.isManagePermitted) {
        result = [
          ...result,
          ...[
            {
              name: translation['DATA_MAPPING:MORE_BUTTON:NEW_BUSINESS_PROCESS'],
              action: 'createNewProcess',
            },
            {
              name: translation['DATA_MAPPING:MORE_BUTTON:DUPLICATE_BUSINESS_PROCESS'],
              action: 'duplicateBusinessProcess',
            },
            {
              name: translation['DATA_MAPPING:MORE_BUTTON:DELETE_BUSINESS_PROCESS'],
              action: 'deleteBusinessProcess',
            },
          ],
        ];
      }
      return result;
    };

    this.loadRopa = async (businessProcessId, ropaId) => {
      const tpaId = await ropaService.getRopaTpaId();
      pageHeaderService.setTitle({
        showBackButton: true,
        breadcrumbs: [
          { label: 'RoPA Mapping App', onClick: () => $state.go(CONFIG.states.CUSTOM_APP, { id: tpaId }) },
          { label: 'Data Processing and Sharing' },
        ],
      });

      const ropaResource = await ropaService.getRopaBusinessProcess(ropaId);
      const businessProcess = await this.dataMappingService.getBusinessProcess(businessProcessId);
      businessProcess.title = ropaResource.processName;
      businessProcess.description = ropaResource.businessProcessDescription;
      businessProcess.ropaResource = ropaResource;
      this.businessProcesses = [businessProcess];
      this.businessProcessesTabs = [businessProcess];
      if (this.businessProcesses && this.businessProcesses.length > 0) {
        // case of link from collaboration email
        const selectedEntityIdByEmail = $stateParams.selectedEntity;
        const selectedBusinessProcessIdByMail = $stateParams.businessProcessId;
        //if there is selected Entity and selected business Process ID
        if (selectedEntityIdByEmail && selectedBusinessProcessIdByMail) {
          this.selectedentityidbyemail = selectedEntityIdByEmail;
          this.openBusinessProcess(this.businessProcesses.find(({ _id }) => _id === selectedBusinessProcessIdByMail));
        }
        //if there is only selected business Process ID by mail
        else if (selectedBusinessProcessIdByMail) {
          this.openBusinessProcess(this.businessProcesses.find(({ _id }) => _id === selectedBusinessProcessIdByMail));
          // const offset = calculateOffset(selectedBusinessProcessIdByMail);
          // if (offset > 0) {
          //   this.slideTabs('right', offset);
          // }
        }
        //if it's not by mail
        else {
          //TODO
          // const selectedBusinessProcessesID = localStorageService.get('dataMapping.selectedBusinessProcessID');
          // if (selectedBusinessProcessesID !== null && selectedBusinessProcessesID !== '') {
          //   if (this.businessProcessesTabs && this.businessProcessesTabs.length > 0) {
          //     this.selectProcess(
          //         this.businessProcessesTabs.find(businessProcess => businessProcess._id === selectedBusinessProcessesID),
          //     );
          //   }
          // } else {
          //   if (this.businessProcessesTabs && this.businessProcessesTabs.length > 0) {
          //     localStorageService.set('dataMapping.selectedBusinessProcessID', this.businessProcessesTabs[0]._id);
          //     this.selectProcess(this.businessProcessesTabs[0]);
          //   } else {
          //     localStorageService.set('dataMapping.selectedBusinessProcessID', this.businessProcesses[0]._id);
          //     this.selectProcess(this.businessProcesses[0]);
          //   }
          // }
        }
      }
    };
    this.loadLegacy = () => {
      this.dataMappingService.getBusinessProcesses().then(businessProcesses => {
        this.businessProcesses = businessProcesses;
        const businessProcessesTabsFromStorage = localStorageService.get('businessProcessesTabs');
        if (businessProcessesTabsFromStorage) {
          const businessProcessTabsIDs = JSON.parse(businessProcessesTabsFromStorage);
          if (businessProcessTabsIDs) {
            businessProcessTabsIDs.forEach(bp_id => {
              const existBusinessProcess = this.businessProcesses.find(
                businessProcess => businessProcess._id === bp_id._id,
              );
              if (existBusinessProcess) {
                let alreadyExist = false;
                //prevent inserting duplicate bp
                if (this.businessProcessesTabs && this.businessProcessesTabs.length > 0) {
                  alreadyExist = !!this.businessProcessesTabs.find(
                    businessProcessTab => businessProcessTab._id === existBusinessProcess._id,
                  );
                }
                if (!alreadyExist) {
                  this.businessProcessesTabs.push(existBusinessProcess);
                }
              }
            });
          }
        } else {
          if (this.businessProcesses) {
            const bp_ids = this.businessProcesses.map(businessProcess => ({
              _id: businessProcess._id,
            }));
            localStorageService.set('businessProcessesTabs', JSON.stringify(bp_ids));
            this.businessProcessesTabs = businessProcesses;
          }
        }
        setScroll();
        if (this.businessProcesses && this.businessProcesses.length > 0) {
          // case of link from collaboration email
          const selectedEntityIdByEmail = $stateParams.selectedEntity;
          const selectedBusinessProcessIdByMail = $stateParams.businessProcessId;
          //if there is selected Entity and selected business Process ID
          if (selectedEntityIdByEmail && selectedBusinessProcessIdByMail) {
            this.selectedentityidbyemail = selectedEntityIdByEmail;
            this.openBusinessProcess(this.businessProcesses.find(({ _id }) => _id === selectedBusinessProcessIdByMail));
          }
          //if there is only selected business Process ID by mail
          else if (selectedBusinessProcessIdByMail) {
            this.openBusinessProcess(this.businessProcesses.find(({ _id }) => _id === selectedBusinessProcessIdByMail));
            const offset = calculateOffset(selectedBusinessProcessIdByMail);
            if (offset > 0) {
              this.slideTabs('right', offset);
            }
          }
          //if it's not by mail
          else {
            const selectedBusinessProcessesID = localStorageService.get('dataMapping.selectedBusinessProcessID');
            if (selectedBusinessProcessesID !== null && selectedBusinessProcessesID !== '') {
              if (this.businessProcessesTabs && this.businessProcessesTabs.length > 0) {
                this.selectProcess(
                  this.businessProcessesTabs.find(
                    businessProcess => businessProcess._id === selectedBusinessProcessesID,
                  ),
                );
              }
            } else {
              if (this.businessProcessesTabs && this.businessProcessesTabs.length > 0) {
                localStorageService.set('dataMapping.selectedBusinessProcessID', this.businessProcessesTabs[0]._id);
                this.selectProcess(this.businessProcessesTabs[0]);
              } else {
                localStorageService.set('dataMapping.selectedBusinessProcessID', this.businessProcesses[0]._id);
                this.selectProcess(this.businessProcesses[0]);
              }
            }
          }
        }
      });
    };

    this.$onDestroy = () => {
      window.removeEventListener('resize', onResize);
    };

    this.addDeepLinkingHandlersToDataFlows = () => {
      // this solution use "dynamic" params in `state('dataMapping')` and implements manual logic to handle transitions
      // after spending more than one day trying different implementations, I found this way the most safest and cleanest
      // any other solution I tried using $state configurations / nested states and ui-views require refactoring
      // of components / templates / routes which is much more risky in terms of regressions

      // change URL according to the selectedProcess
      $scope.$watch('$ctrl.selectedProcess', (newValue, oldValue) => {
        if (newValue && newValue !== oldValue && newValue._id !== $stateParams.businessProcessId) {
          $state.go('.', { businessProcessId: newValue._id }, { notify: true });
        }
      });

      // change selectedProcess according to URL changes
      // mostly for the browser history navigation (back / forward) use case
      $scope.$on(
        '$destroy',
        $transitions.onSuccess({}, () => {
          const { _id: selectedId } = this.selectedProcess || {};
          const { businessProcessId: stateParamId } = $stateParams;
          if (stateParamId && selectedId !== stateParamId) {
            this.openBusinessProcess(this.businessProcesses.find(({ _id }) => _id === stateParamId));
          }
        }),
      );
    };

    this.onMoreButtonItemClick = item => {
      if (typeof item.action != 'undefined' && typeof this[item.action] == 'function') {
        //FIXME: mess in architecture
        if (item.action === 'deleteBusinessProcess') {
          this[item.action](this.selectedProcess);
        } else {
          this[item.action]();
        }
      }
    };

    this.selectProcess = process => {
      //in case there is tabs that are open
      this.showSetPropertiesPage = false;
      const previousProcessID = localStorageService.get('dataMapping.selectedBusinessProcessID') || null;
      if (process && previousProcessID != process._id) {
        window.sessionStorage.setItem('dataMapping.hiddenEntities', null);
        window.sessionStorage.setItem('dataMapping.hiddenConnectors', null);
      }

      if (process) {
        localStorageService.set('dataMapping.selectedBusinessProcessID', process._id);
        const offset = calculateOffset(process._id);
        if (offset > 0) {
          this.slideTabs('right', offset);
        }
      }

      if (process && (!process.classifiers || process.classifiers.length == 0)) {
        this.setDefaultClassifierData(process);
      }

      this.selectedProcess = process;
    };

    this.setDefaultClassifierData = process => {
      process.classifiers = ['Collection', 'Transformation', 'Retention', 'Transfer', 'Disposal'].map(
        (classification, idx) => ({ classifierId: idx + 1, name: classification }),
      );
    };

    this.createBusinessProcess = title => {
      return $q(resolve => {
        this.dataMappingService.createBusinessProcess({ title }).then(({ id }) =>
          this.dataMappingService
            .createDataFlow({
              businessProcessId: id,
              name: 'Default',
              checked: true,
              color: '#7bd148',
            })
            .then(() => {
              const newBusinessProcess = {
                _id: id,
                title,
              };
              this.businessProcessesTabs = [...(this.businessProcessesTabs || []), newBusinessProcess];
              this.businessProcesses = [...(this.businessProcesses || []), newBusinessProcess];
              this.saveBusinessProcessToState(newBusinessProcess);

              this.turnOnCarousel = this.businessProcessesTabs.length > visibleTabsAmount ? true : false;

              this.selectProcess(this.businessProcessesTabs[this.businessProcessesTabs.length - 1]);

              resolve();
            }),
        );
      });
    };

    this.saveBusinessProcessToState = businessProcess => {
      let businessProcessTabsIDs = JSON.parse(localStorageService.get('businessProcessesTabs'));
      businessProcessTabsIDs = [...(businessProcessTabsIDs || []), { _id: businessProcess._id }];
      localStorageService.set('businessProcessesTabs', JSON.stringify(businessProcessTabsIDs));
    };

    this.updateBusinessProcess = businessProcess => {
      this.dataMappingService.updateBusinessProcess(businessProcess, businessProcess._id).then(result => {
        if (result.err) {
          notificationService.error(result.err);
          businessProcess.title = this.processTitleBackup;
        } else {
          const index = this.businessProcessesTabs.findIndex(process => process._id === businessProcess._id);
          this.businessProcessesTabs = [
            ...this.businessProcessesTabs.slice(0, index),
            businessProcess,
            ...this.businessProcessesTabs.slice(index + 1),
          ];
          const indexBp = this.businessProcesses.findIndex(process => process._id === businessProcess._id);
          this.businessProcesses = [
            ...this.businessProcesses.slice(0, indexBp),
            businessProcess,
            ...this.businessProcesses.slice(indexBp + 1),
          ];
        }
      });
    };

    this.deleteBusinessProcessTab = businessProcess => {
      if (businessProcess) {
        const id = businessProcess._id;

        this.businessProcesses = this.businessProcesses.filter(process => process._id !== id);
        this.businessProcessesTabs = this.businessProcessesTabs.filter(process => process._id !== id);
        let businessProcessTabsIDs = JSON.parse(localStorageService.get('businessProcessesTabs'));
        businessProcessTabsIDs = businessProcessTabsIDs.filter(
          businessProcessTabsID => businessProcessTabsID._id !== id,
        );
        localStorageService.set('businessProcessesTabs', JSON.stringify(businessProcessTabsIDs));

        this.turnOnCarousel = this.businessProcessesTabs.length + 1 > visibleTabsAmount ? true : false;

        if (this.turnOnCarousel) {
          this.slideTabs('left', 0);
        }

        this.selectProcess(this.businessProcessesTabs[0]);
      }
    };

    this.deleteBusinessProcess = businessProcess => {
      if (!businessProcess) {
        return;
      }

      DeleteConfirmation.showModal(
        {},
        {
          closeButtonText: 'Close',
          actionButtonText: 'Delete',
          headerText: 'Delete Business Process',
          bodyText: `Are you sure you want to delete "${businessProcess.title}"?`,
        },
      ).then(() => {
        dataMappingService
          .deleteBusinessProcess(businessProcess._id)
          .then(() => {
            notificationService.success(`${businessProcess.title} deleted successfully!`);
            this.deleteBusinessProcessTab(businessProcess);
          })
          .catch(() => {
            notificationService.error(`Failed to delete "${businessProcess.title}"`);
          });
      });
    };

    this.openBusinessProcess = businessProcess => {
      if (!businessProcess || !this.businessProcesses.find(({ _id }) => _id === businessProcess._id)) {
        this.selectProcess();
        return;
      }
      if (!this.businessProcessesTabs.find(businessProcessItem => businessProcessItem._id === businessProcess._id)) {
        this.saveBusinessProcessToState(businessProcess);
        this.businessProcessesTabs = [...(this.businessProcessesTabs || []), businessProcess];
      }
      //if it's a business process that are exists in the tab list
      else {
        this.focusSelectedBusinessProcess(businessProcess);
      }
      this.turnOnCarousel = this.businessProcessesTabs.length > visibleTabsAmount ? true : false;
      this.selectProcess(businessProcess);
    };

    this.focusSelectedBusinessProcess = businessProcess => {
      const length = this.businessProcessesTabs.length;
      const new_index = this.businessProcessesTabs.findIndex(
        businessProcessItem => businessProcessItem._id === businessProcess._id,
      );
      const offset = calculateOffset(businessProcess);
      if (new_index > length / 2) {
        this.slideTabs('right', offset);
        this.setSlideTab();
      } else {
        this.slideTabs('left', offset);
      }
    };

    this.removeBusinessProcess = businessProcess => {
      if (this.businessProcessesTabs && this.businessProcessesTabs.length > 1) {
        this.businessProcessesTabs = this.businessProcessesTabs.filter(
          businessProcessTab => businessProcessTab._id != businessProcess._id,
        );
        if (this.businessProcessesTabs && this.businessProcessesTabs.length > 0) {
          this.selectProcess(this.businessProcessesTabs[0]);
          localStorageService.set('dataMapping.selectedBusinessProcessID', this.businessProcessesTabs[0]._id);
          let businessProcessTabsIDs = JSON.parse(localStorageService.get('businessProcessesTabs'));
          businessProcessTabsIDs = businessProcessTabsIDs.filter(
            businessProcessTabsID => businessProcessTabsID._id !== businessProcess._id,
          );
          localStorageService.set('businessProcessesTabs', JSON.stringify(businessProcessTabsIDs));
          this.focusSelectedBusinessProcess(this.businessProcessesTabs[0]);
        }
      }
    };

    this.onBusinessProcessNameChanged = (event, process) => {
      if (event.keyCode === 13) {
        this.newTabBlock = true;
        this.updateBusinessProcess(process);
        if (this.isNewBusinessProcessMode) {
          this.isNewBusinessProcessMode = false;
          const bpInput = document.getElementById(
            'businessProcessNameInput-' + (this.businessProcessesTabs.length - 1),
          );
          if (bpInput) {
            bpInput.blur();
          }
        }
      }
    };

    this.createPDF = () => {
      ctrl.vm.isReport = true;
    };

    this.createNewProcess = () => {
      this.createBusinessProcess(this.newProcessName).then(bp => {
        this.setSlideTab();
      });
    };

    this.openExistingProcess = () => {
      if (this.vm) {
        this.vm.openExistingBusinessProcess = true;
      }
    };

    this.setSlideTab = () => {
      if (this.turnOnCarousel) this.slideTabs('right', 0);
      this.isNewBusinessProcessMode = true;
      $timeout(() => {
        const bpInput = document.getElementById('businessProcessNameInput-' + (this.businessProcessesTabs.length - 1));
        if (bpInput) {
          bpInput.focus();
        }
      }, 500);
    };

    this.duplicateBusinessProcess = () => {
      const title = this.selectedProcess.title;
      this.dataMappingService.duplicateBusinessProcess(this.selectedProcess._id).then(
        result => {
          if (result) {
            this.businessProcessesTabs = [...(this.businessProcessesTabs || []), result];

            this.saveBusinessProcessToState(result);

            this.turnOnCarousel = this.businessProcessesTabs.length > visibleTabsAmount ? true : false;
            this.selectProcess(this.businessProcessesTabs[this.businessProcessesTabs.length - 1]);
            this.setSlideTab();
            notificationService.success(`${title} Duplicated Successfully!`);
          }
        },
        () => {
          notificationService.error(`${title} Duplicated Failed!`);
        },
      );
    };

    this.setProcessProperties = () => {
      ctrl.vm.selectedEntity = null;
      ctrl.vm.showSetPropertiesPage = true;
    };

    this.onBusinessProcessNameFocus = process => {
      this.processTitleBackup = process.title;
      if (this.newProcessName) {
        const bpInput = document.getElementById('businessProcessNameInput-' + (this.businessProcessesTabs.length - 1));
        if (bpInput) {
          bpInput.setSelectionRange(0, this.newProcessName.length);
        }
      }
    };

    this.onBusinessProcessNameBlurred = ($event, process) => {
      if (!this.newTabBlock && process.title != this.newProcessName) this.updateBusinessProcess(process);
    };

    this.slideTabs = (direction, offset) => {
      let _offset;

      if (typeof offset === 'undefined') {
        _offset = this.tabOffset;
      } else {
        if (offset == 0) {
          switch (direction) {
            case 'left':
              _offset = this.scrollLeft;
              break;
            case 'right':
              this.scrollLeft = 0;
              _offset = (this.businessProcessesTabs.length - visibleTabsAmount) * this.tabOffset;
              break;
          }
        } else {
          _offset = offset;
        }
      }

      let stepSum = 0;
      const step = Math.round(_offset / 100);

      const scroll = direction => {
        return $q(resolve => {
          if (_offset <= stepSum + step) {
            clearInterval(timer);
            resolve();
          }

          this.scrollLeft = direction == 'right' ? this.scrollLeft + step : this.scrollLeft - step;

          tabsContainer.scrollLeft = this.scrollLeft;

          stepSum += step;
        });
      };

      const timer = setInterval(() => {
        scroll(direction).then(() => {
          this.scrollLeftDisabled = this.scrollLeft - this.tabOffset < 0 ? true : false;
          this.scrollRightDisabled =
            (this.businessProcessesTabs.length - visibleTabsAmount) * this.tabOffset <= this.scrollLeft ? true : false;
        });
      }, 10);
    };
  },
  bindings: {},
});
