import '../../react/components/BigidTooltipHelper/BigidTooltipHelper';
import './bigidInput.scss';
import template from './bigidInput.html';
import { v4 as uuid } from 'uuid';
import { module } from 'angular';
const app = module('app');

/**
 * Very basic implementation of generic form field, with label and type
 * It currently support only one kind of use case, renders "Label" and "Input" elements with bigid look and feel
 */
function BigidInputController($scope, $element) {
  'ngInject';

  const PASSWORD_MASK_VIEW_VALUE = '********';

  /**
   * @ngdoc component
   * @name bigidInput
   *
   * must have this annotation for the IDE navigation to work
   */
  class BigidInputComponent {
    constructor() {
      this.uuid = uuid();
      this.passEditToggled = false;
      this.beforeTogglePropState = undefined;
      this.beforeToggleFormState = undefined;
      this.onFormSetUntouchedListener = undefined;
      this.dontShowRequiredError = undefined;
      this.passViewFormatter = this.passViewFormatter.bind(this);
    }

    $onInit() {
      if (!this.prop || typeof this.prop !== 'object') {
        throw new TypeError(`Can't init bigid-input, "prop" field must be an object.`);
      }
      if (typeof this.prop.name !== 'string' || !this.prop.name) {
        throw new TypeError(`Can't init bigid-input, "prop.name" field must be non empty string.`);
      }
      if (!this.formCtrl || typeof this.formCtrl !== 'object') {
        throw new TypeError(`Can't init bigid-input, "formCtrl" field must be an object.`);
      }
    }

    $postLink() {
      if (this.isPasswordField()) {
        if (this.prop.placeholder === undefined) {
          this.prop.placeholder = `Enter Password${!this.prop.isRequired ? ' (optional)' : ''}`;
        }

        const unregAddFormatters = $scope.$watch('$ctrl.getNgModelCtrl()', () => {
          this.addFormatters();
          unregAddFormatters();
        });
      }
    }

    $onDestroy() {
      this.removeFormatters();
    }

    isPasswordField() {
      return this.prop.type === 'password';
    }

    isCheckboxField() {
      return this.prop.type === 'checkbox';
    }

    onChange($event) {
      const { value } = this.prop;
      if (typeof this.prop.onChange === 'function') {
        this.prop.onChange({ $event, value });
      }
    }

    showRequiredError() {
      const ngModelCtrl = this.getNgModelCtrl();
      if (this.isPasswordField()) {
        return (
          !this.dontShowRequiredError &&
          ngModelCtrl.$error.required &&
          ((ngModelCtrl.$dirty && ngModelCtrl.$touched) || this.formCtrl.$submitted)
        );
      }
      return ngModelCtrl.$error.required && (ngModelCtrl.$dirty || this.formCtrl.$submitted);
    }

    showPatternError() {
      const ngModelCtrl = this.getNgModelCtrl();
      return ngModelCtrl.$error.pattern && (ngModelCtrl.$dirty || this.formCtrl.$submitted);
    }

    onBlur($event) {
      // temp fix, need to find better solution for the isRequired error message gap
      if (!this.isPasswordField() || this.isReadOnly()) {
        return;
      }
      if ($event.relatedTarget === $element[0].querySelector('.bigid-input__cancel-password')) {
        this.dontShowRequiredError = true;
      }
    }

    getNgModelCtrl() {
      return this.formCtrl[this.prop.name];
    }

    passViewFormatter() {
      if (this.prop.value) {
        return PASSWORD_MASK_VIEW_VALUE;
      }
    }

    addFormatters() {
      this.removeFormatters();
      this.getNgModelCtrl().$formatters.push(this.passViewFormatter);
    }

    removeFormatters() {
      const index = this.getNgModelCtrl().$formatters.indexOf(this.passViewFormatter);
      if (index !== -1) {
        this.getNgModelCtrl().$formatters.splice(index, 1);
      }
    }

    isReadOnly() {
      if (!this.isPasswordField()) {
        return this.prop.readOnly;
      }

      if (this.passEditToggled) {
        return false;
      }

      const ngModelCtrl = this.getNgModelCtrl();
      return ngModelCtrl ? ngModelCtrl.$pristine && Boolean(this.prop.value) : false;
    }

    showPassActions() {
      return this.isPasswordField();
    }

    showChangeBtn() {
      if (this.passEditToggled) {
        return false;
      }

      const ngModelCtrl = this.getNgModelCtrl();
      return ngModelCtrl ? ngModelCtrl.$pristine && Boolean(this.prop.value) : false;
    }

    showCancelBtn() {
      return this.passEditToggled;
    }

    onChangePassClick() {
      if (this.passEditToggled) {
        return;
      }
      this.passEditToggled = true;
      this.beforeTogglePropState = { ...this.prop };
      this.beforeToggleFormState = this.formCtrl.$pristine === true && this.formCtrl.$dirty === false;
      this.prop.value = '';
      this.removeFormatters();

      // set listener in case form control will be reset
      this.onFormSetUntouchedListener = $scope.$watch('$ctrl.formCtrl.$pristine', (newVal, oldVal) => {
        if (this.passEditToggled && newVal === true && oldVal === false) {
          this.beforeTogglePropState = undefined;
          this.beforeToggleFormState = undefined;
          this.passEditToggled = false;
          this.addFormatters();
          this.removeFormUntouchedListener();
        }
      });

      this.getNgModelCtrl().$setPristine();
      this.getNgModelCtrl().$setDirty();
      this.getNgModelCtrl().$setUntouched();
      setTimeout(() => $element[0].querySelector('input[type=password]').focus(), 0, false);
    }

    onCancelResetClick() {
      if (!this.passEditToggled) {
        return;
      }
      this.removeFormUntouchedListener();
      const ngModelCtrl = this.getNgModelCtrl();
      const { value, readOnly } = this.beforeTogglePropState;
      this.prop.value = value;
      this.prop.readOnly = readOnly;
      this.beforeTogglePropState = undefined;
      this.passEditToggled = false;
      this.dontShowRequiredError = false;
      this.addFormatters();
      ngModelCtrl.$setPristine();
      ngModelCtrl.$setUntouched();
      if (
        this.beforeToggleFormState &&
        (!this.formCtrl.$pristine || this.formCtrl.$dirty) &&
        this.formCtrl.$$controls
          .filter(control => control !== ngModelCtrl)
          .every(control => control.$pristine === true && control.$dirty === false)
      ) {
        this.formCtrl.$setPristine();
        this.formCtrl.$setUntouched();
      }
      this.beforeToggleFormState = undefined;
    }

    removeFormUntouchedListener() {
      if (this.onFormSetUntouchedListener) {
        this.onFormSetUntouchedListener();
        this.onFormSetUntouchedListener = undefined;
      }
    }
  }

  return new BigidInputComponent();
}

app.component('bigidInput', {
  template,
  controller: BigidInputController,
  bindings: {
    /** Object with following properties
     * name{string}
     * label{string}
     * tabIndex{string} - used for tabindex attribute
     * value{any} - the value to bind to ng-model
     * type{text,password,select,textarea} - the type of the input element (other types not yet implemented)
     * placeholder{string} - the input placeholder attribute value
     * showResetButton{boolean} - will show the reset password button and related functionality, use with passwords
     * isRequired{boolean}
     * isDisabled{boolean}
     * onChange{Function} - bound like this: to ng-change="onChange({$event, value})"
     * pattern{regex}
     */
    prop: '=',
    /**
     * angular FormController instance
     */
    formCtrl: '=',
  },
});
