import React, { ChangeEvent, Dispatch, FC, SetStateAction, useEffect, useState } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { BigidFormBox } from '../../../../../components/BigidFormBox/BigidFormBox';
import { BigidColors, BigidPrimaryCheckbox, BigidTextField } from '@bigid-ui/components';
import { customAppService } from '../../../../../services/customAppService';
import { TpaGeneralValues } from '../EditCustomApp';
import { TPATrackingEvents, trackTPAEvent } from '../../../customAppEventsTrackerUtils';

export interface InteractiveSectionProps {
  uiUrl: string;
  menuItemName: string;
  isUseProxy: boolean;
  setSectionValid: Dispatch<SetStateAction<boolean>>;
  onChange: (obj: TpaGeneralValues) => void;
}

const MENU_ITEM_NAME_ERROR_MESSAGE =
  'Menu item name should contain only letters, numbers, or underscore and should be at least 2 characters long';

const UI_URL_ERROR_MESSAGE = `This field is mandatory and shouldn't contain url params`;

const useStyles = makeStyles({
  inBoxTitle: {
    color: BigidColors.purple[600],
    fontSize: '0.875rem',
    margin: '10px 0 2px 0',
  },
});

const testMenuItemNameValidity = (str: string): boolean => {
  const re = /^\w+(\w|\s)+$/;
  return re.test(str);
};

export const InteractiveSection: FC<InteractiveSectionProps> = ({
  uiUrl,
  menuItemName,
  isUseProxy,
  onChange,
  setSectionValid,
}) => {
  const classes = useStyles({});
  const [isUiMenuItemNameValid, setIsUiMenuItemNameValid] = useState<boolean>(true);
  const [isUiUrlValid, setIsUiUrlValid] = useState<boolean>(true);
  const [isUseProxyChecked, setIsUseProxyChecked] = useState<boolean>(isUseProxy);

  const handleUiMenuItemNameChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const key = 'ui_tab_name';
    const { value } = e.target;
    const isMenuItemNameValid = testMenuItemNameValidity(value);
    setIsUiMenuItemNameValid(isMenuItemNameValid);
    isMenuItemNameValid && onChange({ [key]: value });
  };

  const removeLastSlash = (value: string) => (value.slice(-1) == '/' ? value.slice(0, -1) : value);

  const handleUiUrlChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const key = 'ui_url';
    const value = removeLastSlash(e.target.value);
    const isUiUrlValid = customAppService.isValidURL(value.trim());
    setIsUiUrlValid(isUiUrlValid);
    isUiUrlValid && onChange({ [key]: value });
  };

  const handleIsUseProxyChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const key = 'is_use_proxy';
    const value = e.target.checked;
    setIsUseProxyChecked(value);
    trackTPAEvent(TPATrackingEvents.TPA_SETTINGS_PROXY_CLICK, { isProxy: value });
    onChange({ [key]: value });
  };

  useEffect(() => {
    setSectionValid(isUiUrlValid && isUiMenuItemNameValid);
  }, [isUiUrlValid, isUiMenuItemNameValid, setSectionValid]);

  return (
    <>
      <BigidFormBox title="UI Settings" description={'Configure UI Settings'}>
        <div className={classes.inBoxTitle}>app iFrame URL</div>
        <BigidTextField
          onChange={handleUiUrlChange}
          defaultValue={uiUrl}
          isError={!isUiUrlValid}
          errorMessage={isUiUrlValid ? '' : UI_URL_ERROR_MESSAGE}
          type="text"
          dataAid="BigidCustomAppInput-text-app-iFrame-URL"
        />
        <div className={classes.inBoxTitle}>Menu item name</div>
        <BigidTextField
          onChange={handleUiMenuItemNameChange}
          defaultValue={menuItemName}
          isError={!isUiMenuItemNameValid}
          errorMessage={isUiMenuItemNameValid ? '' : MENU_ITEM_NAME_ERROR_MESSAGE}
          type="text"
        />
      </BigidFormBox>
      <BigidPrimaryCheckbox
        label="Use BigID proxy server to display the UI"
        checked={isUseProxyChecked}
        onChange={handleIsUseProxyChange}
      />
    </>
  );
};
