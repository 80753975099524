import React, { FC, useState, useEffect } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { BigidColorsV2, PrimaryButton } from '@bigid-ui/components';
import { FmsdTrackingEvents } from '../../../fmsdEventsTrackerUtils';
import { DsGridRow } from '../../components/FmsdDiscoverExplorer/FmsdExplorer';
import { startDsDcan, updateGridRowsWithScanProgress } from '../../../FmsdDiscover/fmsdDiscoverServices';
import { useLocalTranslation } from '../../../translations';

const useStyles = makeStyles({
  scanPanelContainer: {
    height: 40,
    fontSize: 14,
    padding: '16px 24px 32px 24px',
    boxSizing: 'content-box',
    color: BigidColorsV2.gray[700],
    display: 'flex',
    fontWeight: 700,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end',
    '& button': {
      padding: '0 70px',
    },
  },
});

export interface NavigationButtonsProps {
  goToNext: () => void;
  goToPrev: () => void;
  noNext: boolean;
  noPrev: boolean;
}

export interface ScanPanelProps {
  selectedDsIds: React.ReactText[];
  rowsData: DsGridRow[];
  dataSourceName: string;
}

export const ScanPanel: FC<ScanPanelProps> = ({ selectedDsIds, rowsData, dataSourceName }) => {
  const [isScanPanelDisabled, setIsScanPanelDisabled] = useState(true);
  const { scanPanelContainer } = useStyles({});
  const { t } = useLocalTranslation('Discover');

  useEffect(() => {
    setIsScanPanelDisabled(selectedDsIds.length === 0);
  }, [selectedDsIds.length]);

  const onScanClickHandler = async () => {
    const dsNames = selectedDsIds.map(id => {
      return rowsData.find(row => row._id === id)?.name;
    });
    setIsScanPanelDisabled(true);
    await startDsDcan(dsNames, dataSourceName);
    await updateGridRowsWithScanProgress(dataSourceName);
  };

  return (
    <div className={scanPanelContainer}>
      <PrimaryButton
        dataAid="fmsdDiscover-start-scan-button"
        disabled={isScanPanelDisabled}
        text={t('startScanButtonText')}
        size="large"
        onClick={onScanClickHandler}
        bi={{
          eventType: FmsdTrackingEvents.FMSD_DISCOVER_SCAN_CLICK,
          eventData: {
            [FmsdTrackingEvents.SELECTED_DS]: selectedDsIds,
            [FmsdTrackingEvents.DS_NAMES]: selectedDsIds.map(id => {
              return rowsData.find(row => row._id === id)?.name;
            }),
          },
        }}
      />
    </div>
  );
};
