import { BigidAnyIcon } from '@bigid-ui/icons';
import { MetadataSearchIcon } from '../../../MetadataSearchTypes';

export enum MetadataSearchFilterEntityTypeModifier {
  ANY = 'ANY',
}

export const MetadataSearchFilterEntityTypeModifierLabelMap: Record<MetadataSearchFilterEntityTypeModifier, string> = {
  [MetadataSearchFilterEntityTypeModifier.ANY]: 'All',
};

export const MetadataSearchFilterEntityTypeModifierIconMap: Partial<
  Record<MetadataSearchFilterEntityTypeModifier, MetadataSearchIcon>
> = {
  [MetadataSearchFilterEntityTypeModifier.ANY]: {
    icon: BigidAnyIcon,
    name: 'BigidAnyIcon',
  },
};
