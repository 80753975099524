import { BigidBody1, BigidBreadcrumbs, BigidButtonIcon, BigidStyledBadge, Breadcrumb } from '@bigid-ui/components';
import { styled, useTheme } from '@mui/material';
import React from 'react';
import { useGetObjectDetails } from './hooks/useGetObjectDetails';
import { useLocalTranslation } from '../../../../translations';
import { CatalogSidePanelProps } from './CatalogSidePanel';
import { BigidHideIcon, BigidViewIcon } from '@bigid-ui/icons';
import { useFollowObjectMutation } from './hooks/useFollowObjectMutation';

const Root = styled('div')`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const Section = styled('div')<{ gap: number; width?: string }>`
  display: flex;
  align-items: center;
  gap: ${({ gap }) => gap}px;
  width: ${({ width }) => width || '100%'};
`;

const RightSection = styled('div')`
  align-self: flex-end;
`;

const Dot = styled('div')`
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.vars.tokens.bigid.negative};
`;

type SidePanelMetadataProps = Pick<CatalogSidePanelProps, 'selectedItem'>;

const PiiBadge = () => {
  const theme = useTheme();
  const { t } = useLocalTranslation('sidePanel.metadata');
  return (
    <BigidStyledBadge
      backgroundColor={theme.vars.tokens.bigid.negativeSubtle}
      size="small"
      label={t('containsPii')}
      icon={<Dot />}
    />
  );
};

export const SidePanelMetadata = ({ selectedItem }: SidePanelMetadataProps) => {
  const {
    id,
    data: { fullPath },
  } = selectedItem;
  const { data } = useGetObjectDetails(String(id));
  const { t } = useLocalTranslation('sidePanel.metadata');

  const responseData = data?.data;
  const isFollowing = responseData ? responseData.collaborationStatus?.isFollowing : false;

  const breadcrumbs: Breadcrumb[] = fullPath.map(path => ({
    label: path.name,
  }));

  const { mutate } = useFollowObjectMutation(String(id), isFollowing);
  const showPiiBadge = responseData?.total_pii_count > 0;

  return (
    <Root>
      <Section gap={24} width="calc(100% - 32px)">
        {showPiiBadge && <PiiBadge />}
        <Section gap={16}>
          <BigidBody1 whiteSpace="nowrap" textOverflow="ellipsis" flexShrink={1} overflow="hidden">
            {t('owner', { owner: responseData?.owner || '-' })}
          </BigidBody1>
          <BigidBreadcrumbs breadcrumbs={breadcrumbs} />
        </Section>
      </Section>

      <RightSection>
        <BigidButtonIcon
          icon={isFollowing ? BigidHideIcon : BigidViewIcon}
          onClick={() => mutate()}
          tooltipText={t(isFollowing ? 'unfollowAction' : 'followAction')}
        />
      </RightSection>
    </Root>
  );
};
