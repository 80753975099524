import './scanAnalysis.component.scss';
import { find } from 'lodash';
import PerfectScrollbar from 'perfect-scrollbar';
import { v4 as uuid } from 'uuid';
import { module } from 'angular';
import './addTagModal.component';
import './commentModal/addCommentModal.component';
import { httpService } from '../react/services/httpService';
import template from './scanAnalysis.component.html';
import { getCopyOfCountries } from '../config/countries';
import { SCAN_RESULT_DETAILS_PERMISSIONS, TAGS_SAVED_QUERIES_PERMISSIONS } from '@bigid/permissions';
import { isPermitted } from '../react/services/userPermissionsService';
import '../react/components/ConfidenceLevelExplanation/ConfidenceLevelExplanation';
import { getApplicationPreference } from '../react/services/appPreferencesService';
import { dateUtils } from '../react/services/angularServices';
const app = module('app');

app.component('scanAnalysis', {
  template,
  controller: ScanAnalysisController,
});

function ScanAnalysisController(
  $scope,
  $rootScope,
  $filter,
  $element,
  notificationService,
  $uibModal,
  $translate,
  scanAnalysisService,
  appSettings,
  queryStringService,
  uiGridExporterConstants,
  uiGridExporterService,
  downloadFileService,
  DeleteConfirmation,
  httpDataStream,
  scanResultsQueryLimitService,
  localStorageService,
  downloadReportService,
) {
  'ngInject';

  const FILENAME_PREFIX = 'heat-map';

  const MIN_CONFIDENCE_LEVEL_VALUE = 0;
  const MAX_CONFIDENCE_LEVEL_VALUE = 1;
  const EMPTY_FIELD = '(Empty fields)';
  const DS_FILTER_ELEMENT_ID = '#multiselectSource';
  const ATT_NAME_FILTER_ELEMENT_ID = '#multiselectAttName';
  const ATT_ORIGINAL_NAME_FILTER_ELEMENT_ID = '#multiselectAttOriginalName';
  const GRID_WRAPPER_ELEMENT_CLASS = '.grid-wrapper';

  const TRANSLATION_REQUIRED = [
    'SCAN_ANALYSIS',
    'SCAN_ANALYSIS:GRID:COLUMN:SOURCE',
    'SCAN_ANALYSIS:GRID:COLUMN:TYPE',
    'SCAN_ANALYSIS:GRID:COLUMN:LOCATION',
    'SCAN_ANALYSIS:GRID:COLUMN:OWNER',
    'SCAN_ANALYSIS:GRID:COLUMN:OBJECT',
    'SCAN_ANALYSIS:GRID:COLUMN:FIELD_NAME',
    'SCAN_ANALYSIS:GRID:COLUMN:ATTR_NAME',
    'SCAN_ANALYSIS:GRID:COLUMN:ATTR_ORIGINAL_NAME',
    'SCAN_ANALYSIS:GRID:COLUMN:ATTR_TYPE',
    'SCAN_ANALYSIS:GRID:COLUMN:CATEGORIES',
    'SCAN_ANALYSIS:GRID:COLUMN:FIELD_COUNT',
    'SCAN_ANALYSIS:GRID:COLUMN:ATTR_FINDINGS_COUNT',
    'SCAN_ANALYSIS:GRID:COLUMN:ATTR_DIST_VALUES_COUNT',
    'SCAN_ANALYSIS:GRID:COLUMN:ATTR_RECORDS_COUNT',
    'SCAN_ANALYSIS:GRID:COLUMN:ATTR_DIST_IDS_COUNT',
    'SCAN_ANALYSIS:GRID:COLUMN:IDSOR_SAMPLE_1',
    'SCAN_ANALYSIS:GRID:COLUMN:DS_SAMPLE_1',
    'SCAN_ANALYSIS:GRID:COLUMN:IDSOR_SAMPLE_2',
    'SCAN_ANALYSIS:GRID:COLUMN:DS_SAMPLE_2',
    'SCAN_ANALYSIS:GRID:COLUMN:LAST_SCAN_AT',
    'SCAN_ANALYSIS:GRID:COLUMN:AVG_RISK',
    'SCAN_ANALYSIS:GRID:COLUMN:RANK',
    'SCAN_ANALYSIS:GRID:COLUMN:CALCULATED_RANK',
    'SCAN_ANALYSIS:GRID:COLUMN:CONFIDENCE_LEVEL',
    'SCAN_ANALYSIS:GRID:COLUMN:SAVED_QUERIES',
    'SCAN_ANALYSIS:CONFIDENCE_LEVEL_MAPPING:LOW',
    'SCAN_ANALYSIS:CONFIDENCE_LEVEL_MAPPING:MEDIUM',
    'SCAN_ANALYSIS:CONFIDENCE_LEVEL_MAPPING:HIGH',
    'SCAN_ANALYSIS:GRID:COLUMN:RECORDS_DIGITAL_VALUES_COUNT',
    'SCAN_ANALYSIS:GRID:COLUMN:RECORDS_AVERAGE_LENGTH',
    'SCAN_ANALYSIS:GRID:COLUMN:ATTRIBUTE_AVERAGE_LENGTH',
    'SCAN_ANALYSIS:GRID:COLUMN:ATTRIBUTE_TOTAL_IDENTITIES',
    'SCAN_ANALYSIS:GRID:COLUMN:ATTRIBUTE_DIGITAL_VALUES_COUNT',
    'SCAN_ANALYSIS:GRID:COLUMN:ATTRIBUTE_DISTINCT_VALUES_COUNT',
    'SCAN_ANALYSIS:GRID:COLUMN:COMMENT',
    'SCAN_ANALYSIS:GRID:COLUMN:POLLUTION',
    'SCAN_ANALYSIS:GRID:COLUMN:COLLECTION_SIZE',
    'SCAN_ANALYSIS:GRID:COLUMN:EMPTY_COUNT',
  ];

  const DEFAULT_PAGE_SIZE = 100;
  const DEFAULT_LIMIT = scanResultsQueryLimitService.scanResultsQueryLimit();

  const ROW_HEIGHT = 40;

  const FETCH_API_URL = `${appSettings.serverPath}/${appSettings.version}/scan-result/stream/json`;

  const AVAILABLE_COLUMNS = {
    source: { name: 'DS Name', visible: true },
    type: { name: 'Type', visible: true },
    location: { name: 'Location', visible: false },
    owner: { name: 'Schema', visible: true },
    object: { name: 'Collection', visible: true },
    fieldName: { name: 'Field', visible: true },
    attribute_name: { name: 'Attribute Name', visible: true },
    attribute_original_name: { name: 'Attribute Original Name', visible: true },
    attribute_type: { name: 'Attribute Type', visible: true },
    categories: { name: 'Categories', visible: false },
    fieldCount: { name: 'Field Count', visible: false },
    attributeFindingsCount: { name: 'Findings Count', visible: false },
    attributeDistValsCount: { name: 'Distinct Values Count', visible: false },
    attributeRecordsCount: { name: 'PII Records Count', visible: false },
    attributeDistIdsCount: { name: 'Distinct Entities Count', visible: false },
    idsor_sample_1: { name: 'Entity SoR Sample 1', visible: false },
    attributeRecordsCountNumbers: { name: 'PII Records Numeric Values Count', visible: false },
    records_avg_len: { name: 'PII Records Average Length', visible: false },
    avg_values_length: { name: 'Attribute Average Length', visible: false },
    distinct_ids_with_att_field: { name: 'Attribute Total Identities', visible: false },
    distinct_field_value_count: { name: 'Attribute Distinct Values Count', visible: false },
    number_values_count: { name: 'Attribute Numeric Values Count', visible: false },
    ds_sample_1: { name: 'DS Sample 1', visible: false },
    idsor_sample_2: { name: 'Entity SoR Sample 2', visible: false },
    ds_sample_2: { name: 'DS Sample 2', visible: false },
    last_scan_at: { name: 'Last Scanned At', visible: false },
    displayedTags: { name: 'Saved Queries', visible: false },
    comment: { name: 'Comment', visible: true },
    avgRisk: { name: 'Risk', visible: false },
    rank: { name: 'Updated Confidence Level', visible: true },
    calculatedRank: { name: 'Initial Confidence Level', visible: false },
    confidence_level: { name: 'Initial Confidence Accuracy', visible: true },
    pollution: { name: 'Pollution Estimation', visible: false },
    estimated_rows: { name: 'Collection Size', visible: false },
    emptyCount: { name: 'Empty Count', visible: false },
  };

  const confidenceLevelMapping = {};

  let selectedRows = [];

  this.gridModel = {};
  this.searchQuery = '';
  this.advancedSearchQuery = '';
  this.isDataFetchedWithQuery = false;
  this.actions = {};
  this.data = [];
  this.selectedRowsAmount = 0;
  this.gridState = null;
  this.gridFilter = {};
  this.dataLoaded = false;
  this.loading = false;
  this.backgroundLoading = false;
  this.downloading = false;
  this.gridConfig = {
    isRestored: false,
    enableSorting: true,
    paginationPageSizes: [25, 50, 75, 100],
    paginationPageSize: DEFAULT_PAGE_SIZE,
    enableRowSelection: true,
    enableSelectAll: true,
    enableHorizontalScrollbar: 2,
    appScopeProvider: this,
    rowHeight: ROW_HEIGHT,
    columnDefs: [],
    data: [],
  };
  this.isAdvancedSearchToggled = false;
  this.displayColumnsSelection = false;
  this.gridColumnsVisibility = {};
  this.filterDropdownSettings = {
    enableSearch: true,
    checkBoxes: true,
    displayProp: 'name',
    idProperty: 'name',
    scrollable: false,
    smartButtonMaxItems: 2,
    smartButtonTextConverter: function (itemText) {
      return itemText;
    },
    template: '<span title={{option.name}}>{{option.name}}</span>',
  };
  this.filterDropDownDisplayText = { uncheckAll: 'Clear filter' };
  this.sourceFilterOption = [];
  this.attNameFilterOption = [];
  this.attOriginalNameFilterOptions = [];
  this.sourceFilterOptionSelected = [];
  this.attNameFilterOptionSelected = [];
  this.attOriginalNameOptionsSelected = [];
  this.collecionSelected = [];
  this.filterMultiSourceElement = {};
  this.filterMultiAttNameElement = {};
  this.filterMultiAttOriginalNameElement = {};
  this.gridWrapperElement = {};
  this.isFilteredGridEmpty = false;
  this.hasGridFilterChanged = false;

  this.hasExportPermission = isPermitted(SCAN_RESULT_DETAILS_PERMISSIONS.EXPORT_SCAN_RESULT_DETAILS_DATA.name);
  this.hasEditPermission = isPermitted(SCAN_RESULT_DETAILS_PERMISSIONS.EDIT.name);
  this.hasReadTagsPermission = isPermitted(TAGS_SAVED_QUERIES_PERMISSIONS.READ.name);

  this.hasUpdateConfidenceThresholdPermission = isPermitted(
    SCAN_RESULT_DETAILS_PERMISSIONS.UPDATE_CONFIDENCE_THRESHOLD.name,
  );
  this.hasUpdateConfidenceLevelPermission = isPermitted(SCAN_RESULT_DETAILS_PERMISSIONS.UPDATE_CONFIDENCE_LEVEL.name);
  this.hasAnyEditPermission = this.hasEditPermission || this.hasUpdateConfidenceLevelPermission;

  this.setGridColumnsVisibility = () => {
    const columnsVisibility = localStorageService.get('scanResultGridColumnsVisibility');
    if (columnsVisibility) {
      this.gridColumnsVisibility = Object.keys(AVAILABLE_COLUMNS).reduce((aggregator, column) => {
        const columnObj = columnsVisibility[column] || AVAILABLE_COLUMNS[column];

        const { name, visible } = columnObj;

        aggregator[column] = { name, visible };

        return aggregator;
      }, {});
    } else {
      this.gridColumnsVisibility = Object.assign({}, AVAILABLE_COLUMNS);
    }
  };

  const fetchData = (advancedQuery = '', isGridDdlFilter = false) => {
    this.loadFilterOptions();
    this.isFilteredGridEmpty = false;
    let data = [],
      counter = 0,
      query;

    if (advancedQuery !== '') {
      query = `${advancedQuery}&limit=${DEFAULT_LIMIT}`;
    } else {
      query = `?limit=${DEFAULT_LIMIT}`;
    }

    const streamParams = {
      pattern: '!*',
      url: `${FETCH_API_URL}${query}`,
      pageSize: DEFAULT_PAGE_SIZE,
      isServerStream: true,
      onStart: () => {
        this.dataLoaded = false;
        this.loading = true;
        this.data = [];
        this.gridConfig.data = [];
        this.isAdvancedSearchToggled = false;
      },
      onDone: () => {
        this.loading = false;
        this.dataLoaded = true;

        if (advancedQuery !== '' && !isGridDdlFilter) {
          this.isDataFetchedWithQuery = true;
        } else {
          this.isDataFetchedWithQuery = false;
        }
      },
    };

    httpDataStream
      .get(streamParams)
      .then(
        () => {
          this.loading = false;
          this.backgroundLoading = false;

          if (this.gridConfig.data.length < DEFAULT_LIMIT) {
            if (data.length > 0) {
              if (this.gridConfig.data.length + data.length >= DEFAULT_LIMIT) {
                const delta = DEFAULT_LIMIT - this.gridConfig.data.length;
                const _data = data.slice(0, delta);

                this.gridConfig.data = [...this.gridConfig.data, ...mapGridData(_data)];
                this.data = [...this.data, ..._data];

                $translate('SCAN_ANALYSIS:LIMITED_DATA_NOTIFICATION').then(translation => {
                  notificationService.success(translation);
                });
              } else {
                this.gridConfig.data = [...this.gridConfig.data, ...mapGridData(data)];
                this.data = [...this.data, ...data];
              }
            }

            if (this.searchQuery !== '') {
              this.gridConfig.data = $filter('filter')(this.data, this.searchQuery);
            }
          } else {
            $translate('SCAN_ANALYSIS:LIMITED_DATA_NOTIFICATION').then(translation => {
              notificationService.success(translation);
            });
          }
        },
        error => {
          const isNewQueryFilterEnabled = getApplicationPreference('NEW_QUERY_FILTER_ENABLED');

          if (error.status === 422) {
            const errMessage = `Invalid filter query. Please, use the following format: ${
              isNewQueryFilterEnabled ? 'key = "value"' : 'key = value'
            }`;
            notificationService.error(errMessage);
          } else {
            $translate('API:MESSAGE:COMMON_ERROR').then(translation => {
              notificationService.error(translation);
            });
          }

          this.loading = false;
        },
        node => {
          data = [...data, node];
          counter += 1;

          if (counter === DEFAULT_PAGE_SIZE) {
            this.loading = false;
            this.backgroundLoading = true;
            data = mapGridDisplayTagData(data);
            this.gridConfig.data = [...this.gridConfig.data, ...mapGridData(data)];
            this.data = [...this.data, ...data];
            data = [];
            counter = 0;
          }
        },
      )
      .then(() => {
        if (isGridDdlFilter && this.gridConfig.data.length === 0) {
          this.data = [''];
          this.isFilteredGridEmpty = true;
        }
        if (this.data.length < DEFAULT_PAGE_SIZE) {
          this.gridConfig.data = mapGridDisplayTagData(this.gridConfig.data);
          this.data = mapGridDisplayTagData(this.data);
        }
      });

    selectedRows = [];
  };

  function mapGridDisplayTagData(data) {
    return data.map(row => {
      row.displayedTags = getDisplayedTags(row);
      return row;
    });
  }

  const updateConfidenceLevel = data => {
    $uibModal
      .open({
        animation: true,
        templateUrl: 'updateConfidenceLevel.template.html',
        controllerAs: '$modalCtrl',
        backdrop: 'static',
        size: 'sm',
        windowClass: 'update-confidence-level',
        controller: [
          'data',
          '$uibModalInstance',
          function (data, $uibModalInstance) {
            const mandatoryPropsMap = {
              fullyQualifiedName: 'fullyQualifiedName',
              fieldName: 'fieldName',
              attribute_type: 'attribute_type',
              attribute_name: 'attribute_name',
              attribute_original_name: 'attribute_original_name',
              confLevel: 'rank',
            };

            this.selectedLevel = undefined;

            this.cancel = () => {
              $uibModalInstance.close({ shouldUpdate: false });
            };

            this.submit = () => {
              let payload = [],
                updatedRowsIdx = [];

              data.forEach(item => {
                if (typeof item.rank != 'undefined') {
                  const payloadItem = {};

                  Object.keys(mandatoryPropsMap).forEach(prop => {
                    if (prop == 'confLevel') {
                      payloadItem['confLevel'] = this.selectedLevel;
                    } else {
                      payloadItem[prop] =
                        typeof item[mandatoryPropsMap[prop]] == 'undefined' ? null : item[mandatoryPropsMap[prop]];
                    }
                  });

                  updatedRowsIdx = [...updatedRowsIdx, item.uuid];

                  payload = [...payload, payloadItem];
                }
              });

              scanAnalysisService.updateConfidenceLevel(payload).then(
                result => {
                  if (result.status == 'SUCCESS') {
                    $translate('SCAN_ANALYSIS:CONFIDENCE_LEVEL_MODAL:MESSAGE:UPDATED').then(translation => {
                      notificationService.success(translation);
                    });
                  }

                  $uibModalInstance.close({ shouldUpdate: true, updatedRowsIdx, selectedLevel: this.selectedLevel });
                },
                err => {
                  $translate('API:MESSAGE:COMMON_ERROR').then(translation => {
                    notificationService.error(`${translation} ${err}`);
                  });

                  $uibModalInstance.close({ shouldUpdate: false });
                },
              );
            };
          },
        ],
        resolve: {
          data: () => data,
        },
      })
      .result.then(result => {
        if (typeof result != 'undefined' && typeof result.shouldUpdate != 'undefined' && result.shouldUpdate)
          updateGridData(result.updatedRowsIdx, result.selectedLevel);
      });
  };

  const updateGridData = (idx, level) => {
    this.gridConfig.data.forEach(row => {
      if (idx.includes(row.uuid)) {
        row['rank'] = confidenceLevelMapping[level];
      }
    });
  };

  const preprocessExportData = (exportColumnHeaders, exportData) => {
    const commentIndex = exportColumnHeaders.map(({ name }) => name).indexOf('comment');
    if (commentIndex >= 0) {
      exportData.map(row => {
        const startWithVulnerableChars = new RegExp(/^[@=+-]/g).test(row[commentIndex].value);
        if (startWithVulnerableChars) {
          row[commentIndex].value = null;
        }
        return row;
      });
    }
    return exportData;
  };

  const exportRows = () => {
    this.downloading = true;

    uiGridExporterService
      .loadAllDataIfNeeded(this.gridApi.grid, uiGridExporterConstants.SELECTED, uiGridExporterConstants.VISIBLE)
      .then(() => {
        const exportColumnHeaders = uiGridExporterService.getColumnHeaders(
            this.gridApi.grid,
            uiGridExporterConstants.VISIBLE,
          ),
          exportData = uiGridExporterService.getData(
            this.gridApi.grid,
            uiGridExporterConstants.SELECTED,
            uiGridExporterConstants.VISIBLE,
          ),
          csvContent = uiGridExporterService.formatAsCsv(
            exportColumnHeaders,
            preprocessExportData(exportColumnHeaders, exportData),
            this.gridApi.grid.options.exporterCsvColumnSeparator,
          );

        this.downloading = false;
        uiGridExporterService.downloadFile(
          FILENAME_PREFIX + '-export.csv',
          csvContent,
          this.gridApi.grid.options.exporterOlderExcelCompatibility,
        );
      });
  };

  const cleanAdvancedSearch = () => {
    this.advancedSearchQuery = '';
    fetchData();
  };

  this.alphaSort = (a, b) => {
    const name1 = a.name.toUpperCase();
    const name2 = b.name.toUpperCase();
    return name1 < name2 ? -1 : name1 > name2 ? 1 : 0;
  };

  this.removeEmptyFieldAndSort = translation => {
    const filterSourceEmptyItem = this.sourceFilterOption.filter(s => s.name).sort(this.alphaSort);
    this.sourceFilterOption = filterSourceEmptyItem;
    const filterEmptyAtt = this.attNameFilterOption.filter(a => a.name).sort(this.alphaSort);
    this.attNameFilterOption = filterEmptyAtt;
    const filterEmptyAttOriginal = this.attOriginalNameFilterOptions.filter(a => a.name).sort(this.alphaSort);
    this.attOriginalNameFilterOptions = filterEmptyAttOriginal;
  };

  this.loadFilterOptions = () => {
    scanAnalysisService.getColumnsFilterOptions().then(result => {
      if (result) {
        if (Array.isArray(result.dataSources)) {
          this.sourceFilterOption = result.dataSources.map(ds => ({ name: ds }));
        }
        if (Array.isArray(result.attributes)) {
          this.attNameFilterOption = result.attributes.map(att => ({ name: att }));
        }
        if (Array.isArray(result.attributesOriginalNames)) {
          this.attOriginalNameFilterOptions = result.attributesOriginalNames.map(originalName => ({
            name: originalName,
          }));
        }
        $translate('SCAN_ANALYSIS:GRID:EMPTY_FIELDS').then(translation => {
          this.removeEmptyFieldAndSort(translation);
        });
      }
    });
  };

  this.$onInit = () => {
    this.setGridColumnsVisibility();
    $translate(TRANSLATION_REQUIRED).then(translations => {
      $rootScope.$broadcast('changePage', translations['SCAN_ANALYSIS'], false);

      const inputTextFilterTemplate =
        '<div title="{{col.displayName}}" class="ui-grid-header-cell-label">' +
        '{{col.displayName}}' +
        '<div role="button" tabindex="0" ui-grid-one-bind-id-grid="col.uid + \'-menu-button\'" class="ui-grid-column-menu-button ng-scope" ng-if="grid.options.enableColumnMenus && !col.isRowHeader && col.colDef.enableColumnMenu !== false" ' +
        'ng-click="toggleMenu($event)" ng-class="{\'ui-grid-column-menu-button-last-col\': isLastCol}" ui-grid-one-bind-aria-label="i18n.headerCell.aria.columnMenuButtonLabel" aria-haspopup="true" id="1508993678749-uiGrid-01YG-menu-button" aria-label="Column Menu"><i class="ui-grid-icon-angle-down" aria-hidden="true">&nbsp;</i></div>' +
        '</div>' +
        '<div class="ui-grid-filter-container" style="padding: 4px 10px 4px 0px;">' +
        '<input type="text" style="font-size: 15px;" class="ui-grid-filter-input " ' +
        'ng-model="grid.appScope.gridFilter[col.field]" ng-change="grid.appScope.handleColFilter(grid.appScope.gridFilter[col.field],col.field)">' +
        '<i style="cursor: pointer; position: absolute; right: 5px;" ng-hide="!grid.appScope.gridFilter[col.field]" class="ui-grid-icon-cancel" ng-click="grid.appScope.resetFilter(col.field)" aria-label="Remove Filter">&nbsp;</i>' +
        '</div>';
      const multiSelectFilterTemplate =
        '<div title="{{col.displayName}}" class="ui-grid-header-cell-label">' +
        '{{col.displayName}}' +
        '<div role="button" tabindex="0" ui-grid-one-bind-id-grid="col.uid + \'-menu-button\'" class="ui-grid-column-menu-button ng-scope" ng-if="grid.options.enableColumnMenus && !col.isRowHeader && col.colDef.enableColumnMenu !== false" ' +
        'ng-click="toggleMenu($event)" ng-class="{\'ui-grid-column-menu-button-last-col\': isLastCol}" ui-grid-one-bind-aria-label="i18n.headerCell.aria.columnMenuButtonLabel" aria-haspopup="true" id="1508993678749-uiGrid-01YG-menu-button" aria-label="Column Menu"><i class="ui-grid-icon-angle-down" aria-hidden="true">&nbsp;</i></div>' +
        '</div>';
      const multiSelectForSource =
        multiSelectFilterTemplate +
        `<div class="ui-grid-filter-container" style="padding: 3px 10px 0px 0px;">
                          <div ng-dropdown-multiselect="" id="multiselectSource"
                          ng-click="grid.appScope.calcFilterElementPosition(grid.appScope.filterMultiSourceElement, '#multiselectSource')" events="grid.appScope.multiSelectEvents"
                          options="grid.appScope.sourceFilterOption" selected-model="grid.appScope.sourceFilterOptionSelected" translation-texts="grid.appScope.filterDropDownDisplayText"
                          extra-settings="grid.appScope.filterDropdownSettings"></div>
                          </div>`;
      const multiSelectForAttName =
        multiSelectFilterTemplate +
        `<div class="ui-grid-filter-container" style="padding: 3px 10px 0px 0px;">
                          <div ng-dropdown-multiselect="" id="multiselectAttName"
                          ng-click="grid.appScope.calcFilterElementPosition(grid.appScope.filterMultiAttNameElement, '#multiselectAttName')"
                          events="grid.appScope.multiSelectEvents" options="grid.appScope.attNameFilterOption"
                          selected-model="grid.appScope.attNameFilterOptionSelected" extra-settings="grid.appScope.filterDropdownSettings" translation-texts="grid.appScope.filterDropDownDisplayText"></div>
                          </div>`;
      const multiSelectForAttOriginalName =
        multiSelectFilterTemplate +
        `<div class="ui-grid-filter-container" style="padding: 3px 10px 0px 0px;"> <div ng-dropdown-multiselect=""
                          id="multiselectAttOriginalName"
                          ng-click="grid.appScope.calcFilterElementPosition(grid.appScope.filterMultiAttOriginalNameElement, '#multiselectAttOriginalName')"
                          events="grid.appScope.multiSelectEvents" translation-texts="grid.appScope.filterDropDownDisplayText"
                          options="grid.appScope.attOriginalNameFilterOptions" selected-model="grid.appScope.attOriginalNameOptionsSelected" extra-settings="grid.appScope.filterDropdownSettings"></div>
                          </div>`;

      const gridColumnsNames = {
        source: translations['SCAN_ANALYSIS:GRID:COLUMN:SOURCE'],
        type: translations['SCAN_ANALYSIS:GRID:COLUMN:TYPE'],
        location: translations['SCAN_ANALYSIS:GRID:COLUMN:LOCATION'],
        owner: translations['SCAN_ANALYSIS:GRID:COLUMN:OWNER'],
        object: translations['SCAN_ANALYSIS:GRID:COLUMN:OBJECT'],
        fieldName: translations['SCAN_ANALYSIS:GRID:COLUMN:FIELD_NAME'],
        attribute_name: translations['SCAN_ANALYSIS:GRID:COLUMN:ATTR_NAME'],
        attribute_original_name: translations['SCAN_ANALYSIS:GRID:COLUMN:ATTR_ORIGINAL_NAME'],
        attribute_type: translations['SCAN_ANALYSIS:GRID:COLUMN:ATTR_TYPE'],
        categories: translations['SCAN_ANALYSIS:GRID:COLUMN:CATEGORIES'],
        fieldCount: translations['SCAN_ANALYSIS:GRID:COLUMN:FIELD_COUNT'],
        attributeFindingsCount: translations['SCAN_ANALYSIS:GRID:COLUMN:ATTR_FINDINGS_COUNT'],
        attributeDistValsCount: translations['SCAN_ANALYSIS:GRID:COLUMN:ATTR_DIST_VALUES_COUNT'],
        attributeRecordsCount: translations['SCAN_ANALYSIS:GRID:COLUMN:ATTR_RECORDS_COUNT'],
        attributeDistIdsCount: translations['SCAN_ANALYSIS:GRID:COLUMN:ATTR_DIST_IDS_COUNT'],
        attributeRecordsCountNumbers: translations['SCAN_ANALYSIS:GRID:COLUMN:RECORDS_DIGITAL_VALUES_COUNT'],
        records_avg_len: translations['SCAN_ANALYSIS:GRID:COLUMN:RECORDS_AVERAGE_LENGTH'],
        avg_values_length: translations['SCAN_ANALYSIS:GRID:COLUMN:ATTRIBUTE_AVERAGE_LENGTH'],
        distinct_ids_with_att_field: translations['SCAN_ANALYSIS:GRID:COLUMN:ATTRIBUTE_TOTAL_IDENTITIES'],
        distinct_field_value_count: translations['SCAN_ANALYSIS:GRID:COLUMN:ATTRIBUTE_DISTINCT_VALUES_COUNT'],
        number_values_count: translations['SCAN_ANALYSIS:GRID:COLUMN:ATTRIBUTE_DIGITAL_VALUES_COUNT'],
        idsor_sample_1: translations['SCAN_ANALYSIS:GRID:COLUMN:IDSOR_SAMPLE_1'],
        ds_sample_1: translations['SCAN_ANALYSIS:GRID:COLUMN:DS_SAMPLE_1'],
        idsor_sample_2: translations['SCAN_ANALYSIS:GRID:COLUMN:IDSOR_SAMPLE_2'],
        ds_sample_2: translations['SCAN_ANALYSIS:GRID:COLUMN:DS_SAMPLE_2'],
        last_scan_at: translations['SCAN_ANALYSIS:GRID:COLUMN:LAST_SCAN_AT'],
        displayedTags: translations['SCAN_ANALYSIS:GRID:COLUMN:SAVED_QUERIES'],
        comment: translations['SCAN_ANALYSIS:GRID:COLUMN:COMMENT'],
        avgRisk: translations['SCAN_ANALYSIS:GRID:COLUMN:AVG_RISK'],
        rank: translations['SCAN_ANALYSIS:GRID:COLUMN:RANK'],
        calculatedRank: translations['SCAN_ANALYSIS:GRID:COLUMN:CALCULATED_RANK'],
        confidence_level: translations['SCAN_ANALYSIS:GRID:COLUMN:CONFIDENCE_LEVEL'],
        pollution: translations['SCAN_ANALYSIS:GRID:COLUMN:POLLUTION'],
        estimated_rows: translations['SCAN_ANALYSIS:GRID:COLUMN:COLLECTION_SIZE'],
        emptyCount: translations['SCAN_ANALYSIS:GRID:COLUMN:EMPTY_COUNT'],
      };

      const CONFIDENCE_LEVEL_EXPLAIN_TOOLTIP_ENABLED = getApplicationPreference(
        'CONFIDENCE_LEVEL_EXPLAIN_TOOLTIP_ENABLED',
      );
      const CLUSTERING_ENABLED = getApplicationPreference(
        'CLUSTERING_ENABLED',
      );
      const gridColumns = [
        {
          field: 'source',
          enableFiltering: false,
          headerCellTemplate: multiSelectForSource,
          cellTemplate:
            '<div tabindex="0" aria-label="' +
            gridColumnsNames.source +
            ' {{row.entity.source}}" class="ui-grid-cell-contents">{{row.entity.source}}</div>',
        },
        {
          field: 'type',
          cellTemplate:
            '<div tabindex="0" aria-label="' +
            gridColumnsNames.type +
            ' {{row.entity.type}}" class="ui-grid-cell-contents">{{row.entity.type}}</div>',
        },
        {
          field: 'location',
          cellTemplate:
            '<div tabindex="0" aria-label="' +
            gridColumnsNames.location +
            ' {{row.entity.location}}" class="ui-grid-cell-contents">{{row.entity.location}}</div>',
        },
        {
          field: 'owner',
          cellTemplate:
            '<div tabindex="0" aria-label="' +
            gridColumnsNames.owner +
            ' {{row.entity.owner}}" class="ui-grid-cell-contents">{{row.entity.owner}}</div>',
        },
        {
          field: 'object',
          cellTemplate:
            '<div tabindex="0" aria-label="' +
            gridColumnsNames.object +
            ' {{row.entity.object}}" class="ui-grid-cell-contents">{{row.entity.object}}</div>',
        },
        {
          field: 'fieldName',
          cellTemplate:
            '<div tabindex="0" aria-label="' +
            gridColumnsNames.fieldName +
            ' {{row.entity.fieldName}}" class="ui-grid-cell-contents">{{row.entity.fieldName}}</div>',
        },
        {
          field: 'attribute_name',
          headerCellTemplate: multiSelectForAttName,
          enableFiltering: false,
          cellTemplate:
            '<div tabindex="0" aria-label="' +
            gridColumnsNames.attribute_name +
            ' {{row.entity.attribute_name}}" class="ui-grid-cell-contents">{{row.entity.attribute_name}}</div>',
        },
        {
          field: 'attribute_original_name',
          headerCellTemplate: multiSelectForAttOriginalName,
          enableFiltering: false,
          cellTemplate:
            '<div tabindex="0" aria-label="' +
            gridColumnsNames.attribute_original_name +
            ' {{row.entity.attribute_original_name}}" class="ui-grid-cell-contents">{{row.entity.attribute_original_name}}</div>',
        },
        {
          field: 'attribute_type',
          cellTemplate:
            '<div tabindex="0" aria-label="' +
            gridColumnsNames.attribute_type +
            ' {{row.entity.attribute_type}}" class="ui-grid-cell-contents">{{row.entity.attribute_type}}</div>',
        },
        {
          field: 'categories',
          cellTemplate:
            '<div tabindex="0" aria-label="' +
            gridColumnsNames.categories +
            ' {{row.entity.categories}}" class="ui-grid-cell-contents">{{row.entity.categories | toCommaSeparatedString}}</div>',
        },
        {
          field: 'fieldCount',
          type: 'number',
          cellTemplate:
            '<div tabindex="0" aria-label="' +
            gridColumnsNames.fieldCount +
            ' {{row.entity.fieldCount}}" class="ui-grid-cell-contents">{{row.entity.fieldCount}}</div>',
        },
        {
          field: 'emptyCount',
          type: 'number',
          cellTemplate:
            '<div tabindex="0" aria-label="' +
            gridColumnsNames.emptyCount +
            ' {{row.entity.emptyCount}}" class="ui-grid-cell-contents">{{row.entity.emptyCount}}</div>',
        },
        {
          field: 'attributeFindingsCount',
          type: 'number',
          cellTemplate:
            '<div tabindex="0" aria-label="' +
            gridColumnsNames.attributeFindingsCount +
            ' {{row.entity.attributeFindingsCount}}" class="ui-grid-cell-contents">{{row.entity.attributeFindingsCount}}</div>',
        },
        {
          field: 'attributeDistValsCount',
          type: 'number',
          cellTemplate:
            '<div tabindex="0" aria-label="' +
            gridColumnsNames.attributeDistValsCount +
            ' {{row.entity.attributeDistValsCount}}" class="ui-grid-cell-contents">{{row.entity.attributeDistValsCount}}</div>',
        },
        {
          field: 'attributeRecordsCount',
          type: 'number',
          cellTemplate:
            '<div tabindex="0" aria-label="' +
            gridColumnsNames.attributeRecordsCount +
            ' {{row.entity.attributeRecordsCount}}" class="ui-grid-cell-contents">{{row.entity.attributeRecordsCount}}</div>',
        },
        {
          field: 'attributeDistIdsCount',
          type: 'number',
          cellTemplate:
            '<div tabindex="0" aria-label="' +
            gridColumnsNames.attributeDistIdsCount +
            ' {{row.entity.attributeDistIdsCount}}" class="ui-grid-cell-contents">{{row.entity.attributeDistIdsCount}}</div>',
        },
        {
          field: 'attributeRecordsCountNumbers',
          type: 'number',
          cellTemplate:
            '<div tabindex="0" aria-label="' +
            gridColumnsNames.attributeRecordsCountNumbers +
            ' {{row.entity.attributeRecordsCountNumbers}}" class="ui-grid-cell-contents">{{row.entity.attributeRecordsCountNumbers}}</div>',
        },
        {
          field: 'records_avg_len',
          type: 'number',
          cellTemplate:
            '<div tabindex="0" aria-label="' +
            gridColumnsNames.records_avg_len +
            ' {{row.entity.records_avg_len}}" class="ui-grid-cell-contents">{{row.entity.records_avg_len}}</div>',
        },
        {
          field: 'avg_values_length',
          type: 'number',
          cellTemplate:
            '<div tabindex="0" aria-label="' +
            gridColumnsNames.avg_values_length +
            ' {{row.entity.avg_values_length}}" class="ui-grid-cell-contents">{{row.entity.avg_values_length}}</div>',
        },
        {
          field: 'distinct_ids_with_att_field',
          type: 'number',
          cellTemplate:
            '<div tabindex="0" aria-label="' +
            gridColumnsNames.distinct_ids_with_att_field +
            ' {{row.entity.distinct_ids_with_att_field}}" class="ui-grid-cell-contents">{{row.entity.distinct_ids_with_att_field}}</div>',
        },
        {
          field: 'distinct_field_value_count',
          type: 'number',
          cellTemplate:
            '<div tabindex="0" aria-label="' +
            gridColumnsNames.distinct_field_value_count +
            ' {{row.entity.distinct_field_value_count}}" class="ui-grid-cell-contents">{{row.entity.distinct_field_value_count}}</div>',
        },
        {
          field: 'number_values_count',
          type: 'number',
          cellTemplate:
            '<div tabindex="0" aria-label="' +
            gridColumnsNames.number_values_count +
            ' {{row.entity.number_values_count}}" class="ui-grid-cell-contents">{{row.entity.number_values_count}}</div>',
        },
        {
          field: 'idsor_sample_1',
          cellTemplate:
            '<div tabindex="0" aria-label="' +
            gridColumnsNames.idsor_sample_1 +
            ' {{row.entity.idsor_sample_1}}" class="ui-grid-cell-contents">{{row.entity.idsor_sample_1}}</div>',
        },
        {
          field: 'ds_sample_1',
          cellTemplate:
            '<div tabindex="0" aria-label="' +
            gridColumnsNames.ds_sample_1 +
            ' {{row.entity.ds_sample_1}}" class="ui-grid-cell-contents">{{row.entity.ds_sample_1}}</div>',
        },
        {
          field: 'idsor_sample_2',
          cellTemplate:
            '<div tabindex="0" aria-label="' +
            gridColumnsNames.idsor_sample_2 +
            ' {{row.entity.idsor_sample_2}}" class="ui-grid-cell-contents">{{row.entity.idsor_sample_2}}</div>',
        },
        {
          field: 'ds_sample_2',
          cellTemplate:
            '<div tabindex="0" aria-label="' +
            gridColumnsNames.ds_sample_2 +
            ' {{row.entity.ds_sample_2}}" class="ui-grid-cell-contents">{{row.entity.ds_sample_2}}</div>',
        },
        {
          field: 'last_scan_at',
          type: 'date',
          cellFilter: 'formatDateCellFilter',
          cellTemplate:
            '<div tabindex="0" aria-label="' +
            gridColumnsNames.last_scan_at +
            ' {{row.entity.last_scan_at}}" class="ui-grid-cell-contents">{{row.entity.last_scan_at}}</div>',
        },
        {
          field: 'displayedTags',
          cellTemplate:
            '<div tabindex="0" aria-label="' +
            gridColumnsNames.displayedTags +
            ' {{row.entity.displayedTags}}" class="ui-grid-cell-contents">{{row.entity.displayedTags}}</div>',
        },
        {
          field: 'comment',
          cellTemplate:
            '<div tabindex="0" aria-label="' +
            gridColumnsNames.comment +
            ' {{row.entity.comment}}" class="ui-grid-cell-contents">{{row.entity.comment}}</div>',
        },
        {
          field: 'avgRisk',
          type: 'number',
          cellTemplate:
            '<div tabindex="0" aria-label="' +
            gridColumnsNames.avgRisk +
            ' {{row.entity.avgRisk}}" class="ui-grid-cell-contents">{{row.entity.avgRisk}}</div>',
        },
        {
          field: 'rank',
          cellTemplate:
            '<div tabindex="0" aria-label="' +
            gridColumnsNames.rank +
            ' {{row.entity.rank}}" class="ui-grid-cell-contents">{{row.entity.rank}}</div>',
        },
        {
          field: 'calculatedRank',
          cellTemplate: getConfidenceLevelElementForCalculatedRank(),
        },
        {
          field: 'confidence_level',
          type: 'number',
          cellTemplate: getConfidenceLevelElement(),
        },
        {
          field: 'estimated_rows',
          type: 'number',
          cellTemplate:
            '<div tabindex="0" aria-label="' +
            gridColumnsNames.estimated_rows +
            ' {{row.entity.estimated_rows}}" class="ui-grid-cell-contents">{{row.entity.estimated_rows}}</div>',
        },
        {
          field: 'pollution',
          type: 'number',
          cellTemplate:
            '<div tabindex="0" aria-label="' +
            gridColumnsNames.pollution +
            ' {{row.entity.pollution}}" class="ui-grid-cell-contents">{{row.entity.pollution}}</div>',
        },
      ];

      function getConfidenceLevelElement() {
        return CONFIDENCE_LEVEL_EXPLAIN_TOOLTIP_ENABLED && CLUSTERING_ENABLED
          ? `<div ng-if="(row.entity.attribute_type !== 'Classification' &&
              row.entity.attribute_type !== 'Manual' &&
              row.entity.attribute_type !== 'ClassificationMd' &&
              row.entity.attribute_type !== 'Enrichment Attribute') &&
              row.entity.confidence_level !== undefined"
              tabindex="0" aria-label="${gridColumnsNames.confidence_level} {{row.entity.confidence_level}}" class="ui-grid-cell-contents">
              <confidence-level-explanation-with-tooltip item="row.entity"></confidence-level-explanation-with-tooltip>
              </div>
              <div ng-if="(row.entity.attribute_type === 'Classification' ||
              row.entity.attribute_type === 'Manual' ||
              row.entity.attribute_type === 'ClassificationMd' ||
              row.entity.attribute_type === 'Enrichment Attribute') &&
              row.entity.confidence_level !== undefined"
              tabindex="0" aria-label="${gridColumnsNames.confidence_level} {{row.entity.confidence_level}}"
              class="ui-grid-cell-contents">{{row.entity.confidence_level.toFixed(3)}}</div>`
          : `<div tabindex="0" aria-label="${gridColumnsNames.confidence_level} {{row.entity.confidence_level}}"
              class="ui-grid-cell-contents">{{row.entity.confidence_level.toFixed(3)}}</div>`;
      }

      function getConfidenceLevelElementForCalculatedRank() {
        return CONFIDENCE_LEVEL_EXPLAIN_TOOLTIP_ENABLED && CLUSTERING_ENABLED
          ? `<div ng-if="(row.entity.attribute_type !== 'Classification' &&
              row.entity.attribute_type !== 'Manual' &&
              row.entity.attribute_type !== 'ClassificationMd' &&
              row.entity.attribute_type !== 'Enrichment Attribute') &&
              row.entity.confidence_level !== undefined"
              tabindex="0" aria-label="${gridColumnsNames.calculatedRank} {{row.entity.calculatedRank}}" class="ui-grid-cell-contents">
              <confidence-level-explanation-with-tooltip item="row.entity" is-calculated-rank-column="true"></confidence-level-explanation-with-tooltip>
              </div>
              <div ng-if="(row.entity.attribute_type === 'Classification' ||
              row.entity.attribute_type === 'Manual' ||
              row.entity.attribute_type === 'ClassificationMd' ||
              row.entity.attribute_type === 'Enrichment Attribute') &&
              row.entity.confidence_level !== undefined"
              tabindex="0" aria-label="${gridColumnsNames.calculatedRank} {{row.entity.calculatedRank}}"
              class="ui-grid-cell-contents">{{row.entity.calculatedRank}}</div>`
          : `<div tabindex="0" aria-label="${gridColumnsNames.calculatedRank} {{row.entity.calculatedRank}}"
              class="ui-grid-cell-contents">{{row.entity.calculatedRank}}</div>`;
      }

      this.gridConfig.columnDefs = gridColumns.map(oneColDef => {
        const { field } = oneColDef;

        const { visible = false } = this.gridColumnsVisibility[field];
        const name = gridColumnsNames[field];

        return {
          name,
          visible,
          displayName: name,
          headerCellTemplate: inputTextFilterTemplate,
          width: '*',
          cellFilter: 'formatNumber:2',
          enableFiltering: true,
          cellTooltip: true,
          enableHiding: false,
          ...oneColDef,
        };
      });

      confidenceLevelMapping['LOW'] = `3 - ${translations['SCAN_ANALYSIS:CONFIDENCE_LEVEL_MAPPING:LOW']}`;
      confidenceLevelMapping['MEDIUM'] = `2 - ${translations['SCAN_ANALYSIS:CONFIDENCE_LEVEL_MAPPING:MEDIUM']}`;
      confidenceLevelMapping['HIGH'] = `1 - ${translations['SCAN_ANALYSIS:CONFIDENCE_LEVEL_MAPPING:HIGH']}`;

      fetchData();

      this.calcFilterElementPosition = (filterElement, elementId) => {
        if (filterElement && filterElement.getBoundingClientRect && this.gridWrapperElement) {
          const elementLeft =
            filterElement.getBoundingClientRect().left - this.gridWrapperElement.getBoundingClientRect().left;
          const multiSelectList = angular.element($element[0].querySelector(`${elementId} .dropdown-menu`));
          if (multiSelectList && multiSelectList.length) {
            multiSelectList[0].style.left = elementLeft + 'px';
            multiSelectList[0].style.width = filterElement.getBoundingClientRect().width + 'px';
            this.setFilterMultiSelectPerfectScroll(multiSelectList[0]);
          }
        }
      };

      // TODO: refactor candidate
      this.setFilterMultiSelectPerfectScroll = multiSelectElement => {
        if (multiSelectElement.classList.contains('ps') && multiSelectElement.___ps) {
          multiSelectElement.___ps.update();
        } else {
          multiSelectElement.___ps = new PerfectScrollbar(multiSelectElement, {
            wheelSpeed: 2,
            wheelPropagation: true,
          });
        }
      };

      this.gridConfig.onRegisterApi = gridApi => {
        this.gridApi = gridApi;

        const onRowsSelected = (data, e) => {
          if (typeof e != 'undefined' && typeof e.isRestored != 'undefined' && e.isRestored) return;

          const manageRow = data => {
            const index = selectedRows.findIndex(row => row.uuid == data.entity.uuid);

            if (index > -1) {
              selectedRows = [...selectedRows.slice(0, index), ...selectedRows.slice(index + 1)];
            } else {
              selectedRows = [...selectedRows, data.entity];
            }
          };

          if (!Array.isArray(data)) {
            manageRow(data);
          } else {
            data.forEach(row => manageRow(row));
          }

          this.selectedRowsAmount = selectedRows.length;
        };

        this.gridApi.selection.on.rowSelectionChanged($scope, onRowsSelected);
        this.gridApi.selection.on.rowSelectionChangedBatch($scope, onRowsSelected);

        this.gridApi.grid.registerRowsProcessor(rows => {
          if (Object.keys(this.gridFilter).length > 0) {
            rows.forEach(row => {
              const match = [];
              Object.keys(this.gridFilter).forEach(col => {
                if (
                  this.gridFilter[col] === '' ||
                  (row.entity[col] &&
                    row.entity[col].toLowerCase().match(new RegExp(this.gridFilter[col].toLowerCase())))
                )
                  match.push(true);
                else match.push(false);
              });
              row.visible = match.every(item => item);
            });
          }
          return rows;
        }, 200);

        this.gridApi.core.on.rowsRendered($scope, () => {
          this.getMultiFilterElements();
          this.loading = false;

          if (!this.gridConfig.isRestored && selectedRows.length > 0) {
            this.gridApi.grid.modifyRows(this.gridConfig.data);

            selectedRows.forEach(row => {
              this.gridApi.selection.selectRow(row, { isRestored: true });
            });

            this.gridConfig.isRestored = true;
          }
        });
      };
    });
  };

  this.onToggleAdvancedRequest = isToggled => {
    this.isAdvancedSearchToggled = isToggled;
  };

  this.onClearSearchInput = () => {
    this.searchQuery = '';

    if (this.isDataFetchedWithQuery) {
      cleanAdvancedSearch();
    } else {
      this.gridConfig.isRestored = false;
      this.gridConfig.data = $filter('filter')(this.data, this.searchQuery);
    }
  };

  this.onClearAdvancedSearchInput = () => {
    cleanAdvancedSearch();
  };

  this.onExport = () => {
    if (this.selectedRowsAmount == 0) {
      $translate('SCAN_ANALYSIS:GRID:WARNING_EMPTY_SELECTION').then(translation => {
        notificationService.warning(translation);
      });
    } else {
      exportRows();
    }
  };

  this.selectedRowsTagsMatched = selectedRows => {
    const firstRowHaveTags = Array.isArray(selectedRows[0].tags) && selectedRows[0].tags.length > 0;
    if (!firstRowHaveTags) {
      const rowWithTags = selectedRows.find(row => Array.isArray(row.tags) && row.tags.length > 0);
      return rowWithTags ? false : true;
    }
    let haveMatchedTags = true;
    selectedRows.forEach(row => {
      if (!row.tags || row.tags.length !== selectedRows[0].tags.length) {
        haveMatchedTags = false;
        return;
      } else {
        if (Array.isArray(row.tags) && row.tags.length > 0) {
          row.tags.forEach(tag => {
            const commonTag = selectedRows[0].tags.find(t => t._id === tag._id);
            if (!commonTag) {
              haveMatchedTags = false;
              return;
            }
          });
        }
      }
    });
    return haveMatchedTags;
  };

  this.addTag = () => {
    if (this.selectedRowsAmount === 0) {
      return;
    }
    const selectedRows = this.gridApi.selection.getSelectedRows();
    const isTagsIdenticalForAll = this.selectedRowsTagsMatched(selectedRows);
    let selectedTags = [];
    if (isTagsIdenticalForAll) {
      if (selectedRows[0].tags) {
        selectedTags = selectedRows[0].tags;
      }
    }
    this.openAddTagsModal(selectedTags, isTagsIdenticalForAll);
  };

  function getDisplayedTags(row) {
    let displayedTags = [];
    if (row.tags && row.tags.length > 0) {
      displayedTags = row.tags
        .filter(row => row.tag_name && row.tag_value)
        .map(row => `${row.tag_name} = ${row.tag_value}`);
    }
    return displayedTags.join();
  }

  this.handleDeleteTags = selectedTags => {
    if (selectedTags && selectedTags.length === 1) {
      if (!selectedTags[0] || Object.entries(selectedTags[0]).length === 0) {
        selectedTags.splice(0, 1);
      }
    }
  };

  this.updateTagsForRows = selectedTags => {
    if (Array.isArray(selectedTags)) {
      this.handleDeleteTags(selectedTags);
      const selectedRowsIds = selectedRows.map(r => r._id);
      const selectedTagsIds = selectedTags.map(r => r._id);
      const payload = this.getRowsTagsPayload(selectedTagsIds);
      scanAnalysisService.updateRowsTags(payload).then(() => {
        selectedRows.forEach(row => {
          row.tags = selectedTags;
          row.displayedTags = getDisplayedTags(row);
        });
      });
    }
  };

  this.getRowsTagsPayload = selectedTagsIds => {
    const mandatoryPropsMap = {
      fullyQualifiedName: 'fullyQualifiedName',
      fieldName: 'fieldName',
      attribute_type: 'attribute_type',
      attribute_name: 'attribute_name',
      attribute_original_name: 'attribute_original_name',
      scanResultTagIds: 'scanResultTagIds',
    };

    const selectedRows = this.gridApi.selection.getSelectedRows();
    let payload = [],
      updatedRowsIdx = [];
    selectedRows.forEach(row => {
      const payloadItem = {};

      Object.keys(mandatoryPropsMap).forEach(prop => {
        if (prop === 'scanResultTagIds') {
          payloadItem[prop] = selectedTagsIds;
        } else {
          payloadItem[prop] = typeof row[mandatoryPropsMap[prop]] == 'undefined' ? null : row[mandatoryPropsMap[prop]];
        }
      });
      updatedRowsIdx = [...updatedRowsIdx, row.uuid];
      payload = [...payload, payloadItem];
    });
    return payload;
  };

  this.openAddTagsModal = (selectedTags, isTagsIdenticalForAll) => {
    $uibModal
      .open({
        template:
          "<add-tag-modal selected-tags='$ctrl.selectedTags' is-tags-identical-for-all='$ctrl.isTagsIdenticalForAll' $close='$close(selectedTags)' $dismiss='$dismiss()'><add-tag-modal/>",
        windowClass: 'edit-tags',
        backdrop: 'static',
        controllerAs: '$ctrl',
        controller: [
          '$scope',
          '$uibModalInstance',
          'selectedTags',
          function ($scope, $uibModalInstance, selectedTags) {
            (this.selectedTags = selectedTags), (this.isTagsIdenticalForAll = isTagsIdenticalForAll);
          },
        ],
        resolve: {
          selectedTags: () => {
            return selectedTags;
          },
          isTagsIdenticalForAll: () => {
            return isTagsIdenticalForAll;
          },
        },
      })
      .result.then(
        selectedTags => {
          this.updateTagsForRows(selectedTags);
        },
        () => {
          //dismiss
        },
      );
  };

  this.selectedRowsCommentMatched = selectedRows => {
    const firstRowHaveComment = selectedRows[0].comment && selectedRows[0].comment.length > 0;
    if (!firstRowHaveComment) {
      const rowWithComment = selectedRows.find(row => row.comment && row.comment.length > 0);
      return rowWithComment ? false : true;
    }
    let haveMatchedComment = true;
    selectedRows.forEach(row => {
      if (!row.comment || row.comment.toLowerCase() !== selectedRows[0].comment.toLowerCase()) {
        haveMatchedComment = false;
        return;
      }
    });
    return haveMatchedComment;
  };

  this.addComment = () => {
    if (this.selectedRowsAmount === 0) {
      return;
    }
    const selectedRows = this.gridApi.selection.getSelectedRows();
    const isCommentIdenticalForAll = this.selectedRowsCommentMatched(selectedRows);
    let comment = '';
    if (isCommentIdenticalForAll) {
      if (selectedRows[0].comment) {
        comment = selectedRows[0].comment;
      }
    }
    this.openAddCommentModal(comment, isCommentIdenticalForAll);
  };

  this.openAddCommentModal = (comment, isCommentIdenticalForAll) => {
    $uibModal
      .open({
        template:
          "<add-comment-modal comment='$ctrl.comment' is-comment-identical-for-all='$ctrl.isCommentIdenticalForAll' $close='$close(comment)' $dismiss='$dismiss()'><add-comment-modal/>",
        windowClass: 'edit-comment',
        backdrop: 'static',
        controllerAs: '$ctrl',
        controller: [
          '$scope',
          '$uibModalInstance',
          'comment',
          function ($scope, $uibModalInstance, comment) {
            (this.comment = comment), (this.isCommentIdenticalForAll = isCommentIdenticalForAll);
          },
        ],
        resolve: {
          comment: () => {
            return comment;
          },
          isCommentIdenticalForAll: () => {
            return isCommentIdenticalForAll;
          },
        },
      })
      .result.then(
        comment => {
          this.updateCommentForRows(comment);
        },
        () => {
          //dismiss
        },
      );
  };

  this.getRowsCommentPayload = comment => {
    const mandatoryPropsMap = {
      fullyQualifiedName: 'fullyQualifiedName',
      fieldName: 'fieldName',
      attribute_type: 'attribute_type',
      attribute_name: 'attribute_name',
      attribute_original_name: 'attribute_original_name',
      comment: 'comment',
    };

    const selectedRows = this.gridApi.selection.getSelectedRows();
    let missingMandatoryPayload = false;
    let payload = [],
      updatedRowsIdx = [];
    selectedRows.forEach(row => {
      const payloadItem = {};

      Object.keys(mandatoryPropsMap).forEach(prop => {
        if (prop === 'comment') {
          payloadItem[prop] = comment;
        } else {
          if (typeof row[mandatoryPropsMap[prop]] == 'undefined') {
            missingMandatoryPayload = true;
          } else payloadItem[prop] = row[mandatoryPropsMap[prop]];
        }
      });
      if (!missingMandatoryPayload) {
        updatedRowsIdx = [...updatedRowsIdx, row.uuid];
        payload = [...payload, payloadItem];
        row.comment = comment;
      }
      missingMandatoryPayload = false;
    });
    return payload;
  };

  this.updateCommentForRows = comment => {
    const payload = this.getRowsCommentPayload(comment);
    if (!payload || payload.length === 0) {
      return;
    }
    scanAnalysisService
      .updateRowsComment(payload)
      .then(() => {
        $translate('SCAN_ANALYSIS:EDIT_COMMENT_MODAL:MESSAGE:UPDATED').then(translation => {
          notificationService.success(translation);
        });
      })
      .catch(() => {
        $translate('API:MESSAGE:COMMON_ERROR').then(translation => {
          notificationService.success(translation);
        });
        selectedRows.forEach(row => {
          row.comment = '';
        });
      });
  };

  this.onUpdateConfidenceLevel = () => {
    if (this.selectedRowsAmount == 0) {
      $translate('SCAN_ANALYSIS:GRID:WARNING_EMPTY_SELECTION').then(translation => {
        notificationService.warning(translation);
      });
    } else {
      const selectedRows = this.gridApi.selection.getSelectedRows();

      updateConfidenceLevel(selectedRows);
    }
  };

  this.clearSelection = () => {
    this.gridApi.selection.clearSelectedRows();
    this.selectedRowsAmount = 0;

    selectedRows = [];
  };

  this.onSearchLaunched = query => {
    if (query === '') return;

    this.gridConfig.isRestored = false;
    this.gridConfig.data = $filter('filter')(this.data, query);
  };

  this.onAdvancedSearchLaunched = query => {
    const decodedQueryString = queryStringService.getQueryStringFilter(query, null);
    fetchData(decodedQueryString);
  };

  this.handleColFilter = (input, col) => {
    this.gridFilter[col] = input;
    this.gridApi.grid.refresh();
  };

  this.resetFilter = col => {
    delete this.gridFilter[col];
    this.gridApi.grid.refresh();
  };

  this.getMultiFilterElements = () => {
    const filterSourceElemArr = angular.element($element[0].querySelector(DS_FILTER_ELEMENT_ID));
    const filterAttNameElemArr = angular.element($element[0].querySelector(ATT_NAME_FILTER_ELEMENT_ID));
    const filterAttOriginalNameElemArr = angular.element(
      $element[0].querySelector(ATT_ORIGINAL_NAME_FILTER_ELEMENT_ID),
    );
    const gridWrapperElemArr = angular.element($element[0].querySelector(GRID_WRAPPER_ELEMENT_CLASS));
    if (filterSourceElemArr && filterSourceElemArr.length > 0) {
      this.filterMultiSourceElement = filterSourceElemArr[0];
    }
    if (filterAttNameElemArr && filterAttNameElemArr.length > 0) {
      this.filterMultiAttNameElement = filterAttNameElemArr[0];
    }
    if (filterAttOriginalNameElemArr && filterAttOriginalNameElemArr.length > 0) {
      this.filterMultiAttOriginalNameElement = filterAttOriginalNameElemArr[0];
    }
    if (gridWrapperElemArr && gridWrapperElemArr.length > 0) {
      this.gridWrapperElement = gridWrapperElemArr[0];
    }
  };

  this.multiSelectEvents = {
    scanResultModel: this,
    onClose: function () {
      const searchQuery = buildSearchQuery(this.scanResultModel);

      if (this.scanResultModel.hasGridFilterChanged) {
        if (searchQuery !== '') {
          const decodedQueryString = queryStringService.getQueryStringFilter(searchQuery, null);
          fetchData(decodedQueryString, true);
        } else {
          fetchData();
        }
      }

      this.scanResultModel.hasGridFilterChanged = false;
    },
    onItemSelect: function () {
      this.scanResultModel.hasGridFilterChanged = true;
    },
    onItemDeselect: function () {
      this.scanResultModel.hasGridFilterChanged = true;
    },
    onSelectAll: function () {
      this.scanResultModel.hasGridFilterChanged = true;
    },
    onDeselectAll: function () {
      this.scanResultModel.hasGridFilterChanged = true;
    },
  };

  this.downloadCsvFile = () => {
    const searchQuery = buildSearchQuery(this);
    this.downloading = true;

    httpService.downloadFile('scan-result/file-download/csv', { filter: searchQuery }).then(() => {
      this.downloading = false;
    });
  };

  this.downloadCSVReport = () => {
    const reportTypesOptions = [
      {
        name: 'CSV report',
        id: 'csv',
      },
      {
        name: 'Compressed CSV report',
        id: 'zip',
      },
    ];

    downloadReportService.showDownloadReportModal(reportTypesOptions, 'csv').result.then(reportType => {
      if (reportType) {
        const apis = {
          zip: () => this.downloadCompressedCSVFile(),
          csv: () => this.downloadCsvFile(),
        };

        return apis[reportType.id]().catch(err => {
          this.notificationService.error('Failed to download report.');
          window.console.error(`Filed to download csv for ${reportType.id} report`, err);
        });
      }
    });
  };

  this.downloadCompressedCSVFile = () => {
    const searchQuery = buildSearchQuery(this);
    this.downloading = true;

    httpService.downloadFile('scan-result/file-download/zip', { filter: searchQuery }).then(() => {
      this.downloading = false;
    });
  };

  this.onColumnVisibilityChanged = colName => {
    const col = this.gridConfig.columnDefs.find(c => c.name === colName);
    if (col) {
      col.visible = this.gridColumnsVisibility[col.field].visible;
      this.gridApi.grid.refresh();
      localStorageService.set('scanResultGridColumnsVisibility', this.gridColumnsVisibility);
    }
  };

  this.toggleConfidenceThresholdModal = () => {
    scanAnalysisService.getConfidenceThreshold().then(result => {
      const data = {
        low: {
          minValue: MIN_CONFIDENCE_LEVEL_VALUE,
          maxValue: result.mediumConfidenceLevel,
        },
        medium: {
          minValue: result.mediumConfidenceLevel,
          maxValue: result.highConfidenceLevel,
        },
        high: {
          minValue: result.highConfidenceLevel,
          maxValue: MAX_CONFIDENCE_LEVEL_VALUE,
        },
      };

      const defaultData = {
        low: {
          minValue: MIN_CONFIDENCE_LEVEL_VALUE,
          maxValue: result.defaultMediumConfidenceLevel,
        },
        medium: {
          minValue: result.defaultMediumConfidenceLevel,
          maxValue: result.defaultHighConfidenceLevel,
        },
        high: {
          minValue: result.defaultHighConfidenceLevel,
          maxValue: MAX_CONFIDENCE_LEVEL_VALUE,
        },
      };

      $uibModal
        .open({
          animation: true,
          templateUrl: 'updateConfidenceThreshold.template.html',
          controllerAs: '$modalCtrl',
          backdrop: 'static',
          windowClass: 'update-confidence-threshold',
          controller: [
            'data',
            'defaultData',
            '$uibModalInstance',
            function (data, defaultData, $uibModalInstance) {
              this.defaultData = defaultData;
              this.data = data;

              this.hasUpdateConfidenceThresholdPermission = isPermitted(
                SCAN_RESULT_DETAILS_PERMISSIONS.UPDATE_CONFIDENCE_THRESHOLD.name,
              );

              this.updatedData = {
                mediumConfidenceLevel: this.data.medium.minValue.toString(),
                highConfidenceLevel: this.data.medium.maxValue.toString(),
              };

              this.cancel = () => {
                $uibModalInstance.close();
              };

              this.submit = async () => {
                const closeButtonText = await $translate('SCAN_ANALYSIS:CONFIDENCE_THRESHOLD_MODAL:BUTTONS:CANCEL');
                const actionButtonText = await $translate('SCAN_ANALYSIS:CONFIDENCE_THRESHOLD_MODAL:BUTTONS:SUBMIT');
                const headerText = await $translate('SCAN_ANALYSIS:CONFIDENCE_THRESHOLD_MODAL:HEADER');
                const bodyText = await $translate('SCAN_ANALYSIS:CONFIDENCE_THRESHOLD_MODAL:BODY');

                const modalOptions = { closeButtonText, actionButtonText, headerText, bodyText };

                DeleteConfirmation.showModal({}, modalOptions).then(result => {
                  scanAnalysisService
                    .updateConfidenceThreshold(this.updatedData)
                    .then(response => {
                      if (typeof response.result != 'undefined' && response.result === 'OK') {
                        $translate('SCAN_ANALYSIS:CONFIDENCE_THRESHOLD_MODAL:MESSAGE:UPDATING').then(translation => {
                          notificationService.success(translation);
                        });
                        $uibModalInstance.close({ shouldUpdate: true });
                      } else {
                        $translate('API:MESSAGE:COMMON_ERROR').then(translation => {
                          notificationService.error(translation);
                        });
                      }
                    })
                    .then(() => scanAnalysisService.updateCache())
                    .then(response => {
                      if (typeof response.success != 'undefined' && response.success === true) {
                        $translate('SCAN_ANALYSIS:CONFIDENCE_THRESHOLD_MODAL:MESSAGE:UPDATED').then(translation => {
                          notificationService.success(translation);
                        });
                        $uibModalInstance.close({ shouldUpdate: true });
                      } else {
                        $translate('SCAN_ANALYSIS:CONFIDENCE_THRESHOLD_MODAL:MESSAGE:CACHE_ERROR').then(translation => {
                          notificationService.error(translation);
                        });
                      }
                    })
                    .catch(err => {
                      $translate('API:MESSAGE:COMMON_ERROR').then(translation => {
                        notificationService.error(translation);
                      });
                    });
                });
              };

              this.onRangeSliderChange = data => {
                this.isUpdateBtnDisabled = false;

                this.updatedData = {
                  mediumConfidenceLevel: data.medium.minValue.toString(),
                  highConfidenceLevel: data.medium.maxValue.toString(),
                };
              };

              this.onInvalidRangeValue = () => {
                this.isUpdateBtnDisabled = true;
              };

              this.resetThresholdToDefault = () => {
                this.isUpdateBtnDisabled = false;
                this.data = angular.copy(this.defaultData);

                this.updatedData = {
                  mediumConfidenceLevel: this.data.medium.minValue.toString(),
                  highConfidenceLevel: this.data.medium.maxValue.toString(),
                };
              };
            },
          ],
          resolve: {
            data: () => data,
            defaultData: () => defaultData,
          },
        })
        .result.then(result => {
          if (typeof result != 'undefined' && typeof result.shouldUpdate != 'undefined' && result.shouldUpdate) {
            fetchData();
          }
        });
    });
  };

  function buildSearchQuery(scanModel) {
    const isNewQueryFilterEnabled = getApplicationPreference('NEW_QUERY_FILTER_ENABLED');

    let sourceSearchValues = [];
    if (Array.isArray(scanModel.sourceFilterOptionSelected)) {
      scanModel.sourceFilterOptionSelected.forEach(s => sourceSearchValues.push(s.name));
      if (isNewQueryFilterEnabled) {
        sourceSearchValues = sourceSearchValues.map(value => `"${value}"`);
      }
    }

    let attNameSearchValues = [];
    if (Array.isArray(scanModel.attNameFilterOptionSelected)) {
      scanModel.attNameFilterOptionSelected.forEach(a => attNameSearchValues.push(a.name));
      if (isNewQueryFilterEnabled) {
        attNameSearchValues = attNameSearchValues.map(value => `"${value}"`);
      }
    }

    let attOriginalNameSearchValues = [];
    if (Array.isArray(scanModel.attOriginalNameOptionsSelected)) {
      scanModel.attOriginalNameOptionsSelected.forEach(a => attOriginalNameSearchValues.push(a.name));
      if (isNewQueryFilterEnabled) {
        attOriginalNameSearchValues = attOriginalNameSearchValues.map(value => `"${value}"`);
      }
    }

    let searchQuery = sourceSearchValues.length > 0 ? `system IN (${sourceSearchValues.join(',')})` : '';
    if (attNameSearchValues.length > 0 || attOriginalNameSearchValues.length > 0) {
      attNameSearchValues = attNameSearchValues.concat(attOriginalNameSearchValues);
      if (searchQuery.length > 0) {
        searchQuery += ` AND field IN (${attNameSearchValues.join(',')})`;
      } else {
        searchQuery = `field IN (${attNameSearchValues.join(',')})`;
      }
    }

    searchQuery = searchQuery.replaceAll(EMPTY_FIELD, '');
    return searchQuery;
  }

  function mapGridData(data) {
    const countries = getCopyOfCountries(getApplicationPreference('DISPLAY_CHINA_REGULATIONS'));
    return data.map(row => {
      row.displayedTags = getDisplayedTags(row);
      row['uuid'] = uuid();

      if (row.att_stat !== undefined && row.att_stat[0] !== undefined) {
        const attStat = row.att_stat[0];

        for (const prop in attStat) {
          row[prop] = attStat[prop];
        }
      }

      const country = find(countries, { name: row.location });
      return { ...row, location: country?.displayName || row.location };
    });
  }
}

app.filter('formatDateCellFilter', function () {
  'ngInject';
  return function (input) {
    // we have '1970-01-01T00:00:00.000Z' value from API and it seems meaningless for the user
    // so we simply won't show it
    if (input !== '1970-01-01T00:00:00.000Z') {
      const formattedDate = dateUtils.formatDate(input);
      if (formattedDate) {
        return formattedDate;
      }
    }

    return '';
  };
});

app.filter('formatNumber', () => (input, decimalPlaces) => {
  if (isNaN(input)) {
    return input;
  } else {
    return !isNaN(parseFloat(input)) && isFinite(input)
      ? input % 1 === 0
        ? input
        : parseFloat(input).toFixed(decimalPlaces)
      : input;
  }
});

app.filter('toCommaSeparatedString', () => {
  return list => {
    if (Array.isArray(list)) {
      return list.join(', ');
    }
    return list;
  };
});
