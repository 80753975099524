import cronstrue from 'cronstrue';
import { dateTimeService } from '@bigid-ui/i18n';

function convertCronStringVerbose(cronExpression: string) {
  return cronstrue.toString(cronExpression, {
    verbose: true,
    use24HourTimeFormat: true,
    locale: dateTimeService.getLocale(),
  });
}

export function convertCronToReadableString(cronExpression: string) {
  try {
    return convertCronStringVerbose(cronExpression);
  } catch (e) {
    console.error('Invalid cron string: ', e);
  }
}
