export interface RiskControl {
  id: string;
  name: string;
  description: string;
  category: string;
  status: ControlStatus;
}

export enum ControlStatus {
  Implemented = 'Implemented',
  InProgress = 'InProgress',
  ToDo = 'ToDo',
}

export const controlStatusMap = new Map<ControlStatus, string>([
  [ControlStatus.Implemented, 'Implemented'],
  [ControlStatus.InProgress, 'In Progress'],
  [ControlStatus.ToDo, 'To Do'],
]);

export enum SettingsTabsLabels {
  RiskLibrary = 'Risk Library',
  ControlsLibrary = 'Controls Library',
  Collaboration = 'Collaboration',
}

export enum RiskStatus {
  Deleted = 'Deleted',
  Available = 'Available',
}

export interface UsersFromServerModel {
  name: string;
  firstName?: string;
  lastName?: string;
  id: string;
}

export interface RiskInterface {
  id: string;
  description: string;
  category: string;
  probability: number;
  impact: number;
  riskLevel: number;
  owner: string;
  controls: { name: string; id: string }[];
  name: string;
}

export enum RiskPermissionActions {
  RISK_MANAGER_READ = 'risk_manager_read',
  RISK_MANAGER_MANAGE = 'risk_manager_manage',
  RISK_MANAGER_DELETE = 'risk_manager_delete',
  RISK_SUMMARY_READ = 'risk_summary_read',
}

export interface RiskMetadata {
  totalCount: number;
  filter: {
    name: FilterOption[];
    impact: FilterOption[];
    probability: FilterOption[];
    owner: FilterOption[];
    category: FilterOption[];
    riskLevel: FilterOption[];
  };
}

export interface FilterOption {
  totalCount: number;
  value: string;
}

export interface GetAllRiskResponse {
  data: RiskInterface[];
  totalCount: number;
}

export const RISK_POLICY_TYPE = 'risk';
export const RISK_TYPE = 'privacyRisk';
