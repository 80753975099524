import {
  BigidDialog,
  PrimaryButton,
  SecondaryButton,
  BigidTextField,
  BigidPrimaryCheckbox,
} from '@bigid-ui/components';
import DialogContentText from '@mui/material/DialogContentText';
import React, { FC, useState, useEffect } from 'react';
import { httpService } from '../../../../services/httpService';
import { notificationService } from '../../../../services/notificationService';
import { SarProfileType } from './ProfileManagement';
import { Scope, UserScopesSelector } from './UserScopesSelector';
import { FormControl, FormLabel } from '@mui/material';

const ERROR_MESSAGE = 'Invalid value';
const SAME_NAME_ERROR_MESSAGE = 'This profile name already exists, please enter a new name';
const NO_SCOPES_ERROR_MESSAGE = 'Please select at least 1 scope';

export enum PROFILE_ACTION {
  DUPLICATE = 'Duplicate',
  DELETE = 'Delete',
  CREATE = 'New Profile',
  EDIT = 'Edit',
}

export interface ProfileManagementDialogProps {
  isOpen: boolean;
  actionType: PROFILE_ACTION;
  profile: SarProfileType;
  defaultProfileId: string;
  profileNames: string[];
  onClose: () => void;
  onActionSuccess?: (actionType: PROFILE_ACTION, profileId: string) => void;
}

const getDialogText = (actionType: PROFILE_ACTION, profileName: string) => {
  switch (actionType) {
    case PROFILE_ACTION.EDIT:
      return {
        title: `Edit "${profileName}" Profile`,
      };
    case PROFILE_ACTION.DUPLICATE:
      return {
        title: `Duplicate "${profileName}" Profile`,
      };
    case PROFILE_ACTION.DELETE:
      return {
        title: `Delete "${profileName}" Profile`,
        content: `Are you sure you want to delete the "${profileName}" profile?`,
      };
    case PROFILE_ACTION.CREATE:
      return {
        title: `Create a New Profile`,
      };
    default:
      return {
        title: '',
        content: '',
      };
  }
};

const isInvalidName = (name: string): boolean => {
  if (name.trim() === '') {
    return true;
  }
  const regex = /^[^\s][\w\-\s():]+$/;
  return !regex.test(name);
};

export const ProfileManagementDialog: FC<ProfileManagementDialogProps> = ({
  isOpen,
  actionType,
  profile,
  defaultProfileId,
  profileNames,
  onClose,
  onActionSuccess,
}) => {
  const { title, content } = getDialogText(actionType, profile?.name);
  const [newName, setNewName] = useState('');
  const [scopes, setScopes] = useState<string[]>();
  const [initialScopesIds, setInitialScopesIds] = useState<string[]>([]);
  const [shouldAttributesEnrichment, setShouldAttributesEnrichment] = useState(true);

  useEffect(() => {
    if (isOpen && profile) {
      if (actionType === PROFILE_ACTION.EDIT) {
        setNewName(profile.name);
      }
      setInitialScopesIds(profile.scopes);
      setScopes(profile.scopes || []);
      setShouldAttributesEnrichment(profile.shouldAttributesEnrichment ?? true);
    }
  }, [profile, isOpen, actionType]);

  const [isUpdating, setIsUpdating] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [errorMessageScopes, setErrorMessageScopes] = useState('');
  const profileId = profile?._id;

  const handleNameChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const newName = event?.target?.value.trim();

    const isNameTaken =
      !!newName &&
      (actionType === PROFILE_ACTION.EDIT ? profile?.name : '') !== newName &&
      profileNames.includes(newName);

    if (isNameTaken) {
      setErrorMessage(SAME_NAME_ERROR_MESSAGE);
    } else if (isInvalidName(newName)) {
      setErrorMessage(ERROR_MESSAGE);
    } else {
      setErrorMessage('');
      setNewName(newName);
    }
  };

  const handleActionSuccess = (profileId: string = defaultProfileId) => {
    return onActionSuccess?.(actionType, profileId);
  };

  const handleAction = async () => {
    try {
      if (actionType === PROFILE_ACTION.DELETE) {
        if (profileId !== defaultProfileId && profile?.isCustom === true) {
          await httpService.delete(`sar/profiles/${profileId}`);
        }
        return handleActionSuccess(defaultProfileId);
      }
      if (errorMessage || errorMessageScopes) {
        return;
      }
      if (newName === '') {
        return setErrorMessage(ERROR_MESSAGE);
      }
      if (!scopes?.length) {
        setErrorMessageScopes(NO_SCOPES_ERROR_MESSAGE);
        return;
      }
      switch (actionType) {
        case PROFILE_ACTION.EDIT:
          setIsUpdating(true);
          await httpService.put(`sar/profiles/${profileId}`, {
            ...(profileId !== defaultProfileId && profile?.isCustom === true && { name: newName }),
            scopes,
            shouldAttributesEnrichment,
          });
          handleActionSuccess(profileId);
          break;
        case PROFILE_ACTION.DUPLICATE:
          setIsUpdating(true);
          const duplicateRes = await httpService.post(`sar/profiles/duplicate`, {
            profileIdToDuplicate: profileId,
            name: newName,
          });
          handleActionSuccess(duplicateRes?.data?.profileId);
          break;
        case PROFILE_ACTION.CREATE:
          setIsUpdating(true);
          const createRes = await httpService.post(`sar/profiles`, {
            name: newName,
            allEnabledEs: true,
            allEnabledDs: true,
            scopes,
            shouldAttributesEnrichment,
          });
          handleActionSuccess(createRes?.data?.profileId);
          break;
      }
    } catch (error) {
      setIsUpdating(false);
      notificationService.error(error);
    }
  };

  const onCloseDialog = () => {
    setErrorMessage('');
    setErrorMessageScopes('');
    setNewName('');
    setInitialScopesIds([]);
    setShouldAttributesEnrichment(true);
    onClose();
  };

  const disabled = actionType !== PROFILE_ACTION.DELETE && (profileId === defaultProfileId ? false : newName === '');
  const actionButtonText = actionType === PROFILE_ACTION.DELETE ? 'Delete' : 'Save';

  const handleSelectOnChange = (options: Scope[]) => {
    setScopes(options.map(scope => scope.id));
    setErrorMessageScopes(!options.length ? NO_SCOPES_ERROR_MESSAGE : '');
  };

  const showNameTextField =
    isOpen &&
    actionType !== PROFILE_ACTION.DELETE &&
    !(actionType === PROFILE_ACTION.EDIT && profileId === defaultProfileId);

  const textFieldInitialValue = actionType === PROFILE_ACTION.EDIT ? newName : '';

  return (
    <BigidDialog
      isOpen={isOpen}
      onClose={onCloseDialog}
      title={title}
      buttons={[
        { component: SecondaryButton, onClick: onCloseDialog, text: 'Cancel' },
        { component: PrimaryButton, onClick: handleAction, text: actionButtonText, disabled },
      ]}
      isLoading={isUpdating}
    >
      {actionType === PROFILE_ACTION.DELETE && <DialogContentText>{content}</DialogContentText>}
      {showNameTextField && (
        <FormControl fullWidth margin="normal" style={{ minHeight: 80 }}>
          <FormLabel style={{ marginTop: 5 }}>{'Profile Name'}</FormLabel>
          <BigidTextField
            autoFocus
            defaultValue={textFieldInitialValue}
            onChange={handleNameChange}
            errorMessage={errorMessage}
          />
        </FormControl>
      )}
      {actionType !== PROFILE_ACTION.DELETE && (
        <UserScopesSelector
          isDisabled={actionType === PROFILE_ACTION.DUPLICATE}
          onChange={handleSelectOnChange}
          title={'Profile Scopes'}
          initialIds={initialScopesIds}
          errorMsg={errorMessageScopes}
        />
      )}
      {actionType !== PROFILE_ACTION.DELETE && (
        <BigidPrimaryCheckbox
          label="Enable Attributes Enrichment"
          checked={shouldAttributesEnrichment}
          disabled={actionType === PROFILE_ACTION.DUPLICATE}
          onChange={() => setShouldAttributesEnrichment(!shouldAttributesEnrichment)}
          size="small"
        />
      )}
    </BigidDialog>
  );
};
