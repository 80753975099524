import { useEffect } from 'react';
import { getValidator, ValidationRule } from '../utilities/validation';

export const useValidator = <T = unknown>(rules: ValidationRule<T>[], deps?: unknown[]) => {
  const validator = getValidator<T>();

  useEffect(() => {
    const ruleIds = rules.map(rule => validator.addRule(rule));
    return () => validator.clearRules(ruleIds);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [...(deps ?? [])]);

  return validator;
};
