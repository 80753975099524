import { BigidStatusBadgeType } from '@bigid-ui/components';
import { DsStatuses } from '../types';

export const dsStatusToChipProps: Record<DsStatuses, BigidStatusBadgeType> = {
  [DsStatuses.DRAFT]: BigidStatusBadgeType.DISABLED,
  [DsStatuses.CONNECTED]: BigidStatusBadgeType.SUCCESS,
  [DsStatuses.CONNECTION_ERROR]: BigidStatusBadgeType.ERROR,
  [DsStatuses.SCAN_COMPLETED]: BigidStatusBadgeType.SUCCESS,
  [DsStatuses.SCAN_COMPLETED_WITH_FAILURES]: BigidStatusBadgeType.SUCCESS,
  [DsStatuses.SCAN_IN_PROGRESS]: BigidStatusBadgeType.INFO,
  [DsStatuses.SCAN_FAILED]: BigidStatusBadgeType.ERROR,
  [DsStatuses.SCAN_PAUSED]: BigidStatusBadgeType.PENDING,
  [DsStatuses.SCAN_STOPPED]: BigidStatusBadgeType.PENDING,
};
