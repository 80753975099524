import React, { FC, useEffect, useRef, useState } from 'react';
import {
  BigidSidePanel,
  PrimaryButton,
  BigidButtonIcon,
  BigidEntity,
  BigidForm,
  BigidFormValues,
  BigidFormStateAndHandlers,
  TertiaryButton,
  BigidHeading5,
  BigidCaption,
} from '@bigid-ui/components';

import { BigidAddIcon, BigidDeleteIcon } from '@bigid-ui/icons';
import {
  DeleteUserCallback,
  RolePickerSelectedRoles,
  SystemRole,
  SystemUser,
  UserEditableModel,
  UserToken,
} from '../../../types';
import { useRouteLeavingListener } from '../../../../../components/RouteLeaving/hooks/useRouteLeavingListener';
import { dateTimeService } from '@bigid-ui/i18n';
import { useEditUserFormFields } from './useEditUserFormFields';
import { renderEditUserForm } from './renderEditUserForm';
import { RolesPreview } from '../RolesPreview';
import { RolePicker } from '../../../components/SystemUsers/RolePicker';
import { TokenPreview } from '../TokensPreview';
import { EmptyRolesState } from '../CreateUserDialog/EmptyRolesState';

import { EditUserFormSection, EditUserMainControlsWrapper, EditUserPanelWrapper, EditUserWrapper } from './styles';

import { TokenEmptyState } from './TokenEmptyState';
import { showUnsavedChangesDialog } from './systemDialog';
import { DATE_FORMAT } from '../constants';
import { getRolesIdArray, getUserName, getUserModelChangesStatus } from '../../../utils';
import { useAccessManagementContext } from '../../../AccessManagementContext';
import { useLocalTranslation } from '../translations';
import { HeadingAndControlsBlock } from '../styles';

interface EditUserPanelProps {
  isOpen: boolean;
  onClose: () => void;
  onUpdateUser: (user: SystemUser) => void;
  onDeleteUser: DeleteUserCallback;
  user: SystemUser;
  userRoles: SystemRole[];
  userTokens: UserToken[];
  authUser: SystemUser;
}

export const EditUserPanel: FC<EditUserPanelProps> = ({
  isOpen,
  onClose,
  onUpdateUser,
  onDeleteUser,
  user,
  userRoles,
  userTokens,
  authUser,
}) => {
  const editUserFormRef = useRef<BigidFormStateAndHandlers>();
  const [editableRoles, setEditableRoles] = useState(userRoles);
  const [rolesPickerState, setRolesPickerState] = useState({ isVisible: false });
  const [editableTokens, setEditableTokens] = useState(userTokens);
  const [formState, setFormState] = useState({ isDirty: false });
  const { permissions, serviceConfigurations } = useAccessManagementContext();
  const { t } = useLocalTranslation('dialogs.createUser');
  const shouldRenderPasswordFields = !serviceConfigurations.useSaas;
  const formFields = useEditUserFormFields(
    permissions.manage,
    serviceConfigurations.enforceStrongPasswordSecurity,
    shouldRenderPasswordFields,
  );

  const userNameToDisplay = getUserName(user);
  const initialValues = {
    firstName: user.firstName,
    lastName: user.lastName,
    name: user.name,
    origin: user.origin,
    ...(shouldRenderPasswordFields && { password: '', passwordRepeat: '' }),
  };

  const handleOnLeavePage = async () => {
    const currentValues = { ...editUserFormRef.current.getValues() };
    delete currentValues.__valuesCounter;

    const originalUserModel: UserEditableModel = {
      tokens: userTokens,
      roles: userRoles,
      formValues: initialValues,
    };

    const newUserModel: UserEditableModel = {
      tokens: editableTokens,
      roles: editableRoles,
      formValues: currentValues,
    };

    const { isUserModelChanged } = getUserModelChangesStatus(originalUserModel, newUserModel);

    if (!isUserModelChanged) {
      return true;
    }

    return showUnsavedChangesDialog(handleFormSave);
  };

  const { updateIsRouteLeavingEnabled } = useRouteLeavingListener(handleOnLeavePage);

  useEffect(() => {
    updateIsRouteLeavingEnabled(true);

    return () => {
      updateIsRouteLeavingEnabled(false);
    };
  }, []);

  const handleFormValidateAndSubmit = (editedUserData: BigidFormValues) => {
    delete editedUserData.passwordRepeat;

    if (!editedUserData.password) {
      delete editedUserData.password;
    }

    const newRefreshTokens = editableTokens.map(token => {
      return {
        creationDate: token.creationDate,
        expirationDate: token.expirationDate,
        tokenName: token.tokenName,
        value: '',
      };
    });
    const newUserModel = {
      ...editedUserData,
      roleIds: getRolesIdArray(editableRoles as SystemRole[]),
      refreshTokens: newRefreshTokens as UserToken[],
    };
    updateIsRouteLeavingEnabled(false);
    onUpdateUser(newUserModel as SystemUser);
  };

  const handleFormErrorBeforeSubmit = () => {
    setFormState({ isDirty: true });
  };

  const handleFormSave = () => {
    editUserFormRef.current?.validateAndSubmit(handleFormValidateAndSubmit, handleFormErrorBeforeSubmit);
  };

  const handleClosePanel = () => {
    handleOnLeavePage().then(onClose);
  };

  const handleOpenRolesPicker = () => {
    setRolesPickerState({ isVisible: true });
  };

  const handleCloseRolesPicker = () => {
    setRolesPickerState({ isVisible: false });
  };

  const handleAddToken = (token: UserToken) => {
    setEditableTokens(tokens => {
      return [...tokens, token];
    });
  };

  const handleDeleteToken = (tokenName: string) => {
    setEditableTokens(tokens => {
      const newTokens = tokens.filter(token => token.tokenName !== tokenName);
      return newTokens;
    });
  };

  const handleAddRoles = (roles: RolePickerSelectedRoles) => {
    setEditableRoles(oldRolesState => {
      const nextRoles = roles as SystemRole[];
      const newRoles = [...oldRolesState, ...nextRoles];
      return newRoles;
    });
  };

  const handleDeleteRole = (id: string) => {
    setEditableRoles(roles => {
      const newRoles = roles.filter(role => role._id !== id);
      return newRoles;
    });
  };

  const handleDeleteUser = () => {
    onDeleteUser(userNameToDisplay, user.id);
  };

  const ContentComponent = (
    <EditUserPanelWrapper>
      <RolePicker
        isOpen={rolesPickerState.isVisible}
        onClose={handleCloseRolesPicker}
        onConnectRoles={handleAddRoles}
        returnOnlyIds={false}
        selectedRoles={getRolesIdArray(editableRoles as SystemRole[])}
      />
      <BigidForm
        stateAndHandlersRef={editUserFormRef}
        controlButtons={false}
        validate={() => !editableRoles.length}
        fields={formFields}
        renderForm={renderEditUserForm(shouldRenderPasswordFields)}
        initialValues={initialValues}
      />
      <EditUserFormSection>
        <HeadingAndControlsBlock>
          <BigidHeading5>{t('rolesLabel')}</BigidHeading5>
          {permissions.manage && (
            <TertiaryButton
              onClick={handleOpenRolesPicker}
              size="medium"
              text={t('actions.addRole')}
              startIcon={<BigidAddIcon />}
            />
          )}
        </HeadingAndControlsBlock>
        <RolesPreview
          roles={editableRoles as SystemRole[]}
          onDeleteRole={handleDeleteRole}
          isEditable={permissions.manage}
          isWithNewWindowIcon
        >
          <EmptyRolesState
            actionMethod={handleOpenRolesPicker}
            isVisible={true}
            isCompact
            isWithError={formState.isDirty && editableRoles.length === 0}
          />
        </RolesPreview>
      </EditUserFormSection>
      <EditUserFormSection>
        <TokenPreview
          title={t('tokensLabel')}
          userId={user.id}
          tokens={editableTokens}
          onDeleteToken={handleDeleteToken}
          onGeneratedNewToken={handleAddToken}
          isEditable={permissions.manage}
        >
          <TokenEmptyState />
        </TokenPreview>
      </EditUserFormSection>
    </EditUserPanelWrapper>
  );

  const SidePanelActions = (user.last_successful_login_at || permissions.manage) && (
    <EditUserWrapper justifyContent={user.last_successful_login_at ? 'space-between' : 'flex-end'}>
      {user.last_successful_login_at && (
        <BigidCaption>
          {t('editPanel.lastLogin', {
            lastLogin: dateTimeService.formatDate(user.last_successful_login_at, DATE_FORMAT),
          })}
        </BigidCaption>
      )}
      {permissions.manage && (
        <EditUserMainControlsWrapper>
          {authUser.name !== user.name && <BigidButtonIcon icon={BigidDeleteIcon} onClick={handleDeleteUser} />}
          <PrimaryButton onClick={handleFormSave} text={t('actions.save')} size="medium" />
        </EditUserMainControlsWrapper>
      )}
    </EditUserWrapper>
  );

  if (!user) {
    return null;
  }

  return (
    <BigidSidePanel
      open={isOpen}
      onClose={handleClosePanel}
      maxWidth="medium"
      content={ContentComponent}
      actions={SidePanelActions}
      isShowBackdrop={true}
      title={userNameToDisplay}
      customTitleComponent={<BigidEntity size="medium" name={userNameToDisplay} isNameBold />}
    />
  );
};
