import React, { FC, useRef, MouseEvent, PropsWithChildren } from 'react';
import styled from '@emotion/styled';
import { generateDataAid } from '@bigid-ui/utils';
import { BigidXIcon } from '@bigid-ui/icons';
import { BigidButtonIcon, BigidHeading5, BigidIconType, BigidPopper, SecondaryButton } from '@bigid-ui/components';

const Container = styled('div')`
  position: relative;
  display: flex;
  align-items: center;
`;

const HeaderContainer = styled('div')`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 16px;
  border-bottom: 1px solid ${({ theme }) => theme.palette.divider};
`;

export interface QuickActionPopperProps extends PropsWithChildren {
  isOpen: boolean;
  buttonIcon: BigidIconType;
  popperTitle: string;
  isDisabled?: boolean;
  buttonText?: string;
  popperWidth?: number;
  dataAid?: string;
  onToggle: () => void;
}

export interface ActionButtonProps {
  size: 'small' | 'medium' | 'large';
  buttonText: string;
  disabled: boolean;
  onClick: (event: MouseEvent<Element>) => void;
  dataAid: string;
  buttonIcon: BigidIconType;
}

export const QuickActionButtonWithPopper: FC<QuickActionPopperProps> = ({
  isDisabled,
  buttonText,
  dataAid = 'QuickAction',
  popperWidth = 500,
  onToggle,
  popperTitle,
  buttonIcon,
  children,
  isOpen,
}) => {
  const anchroRef = useRef(null);

  const handleButtonClick = (event: MouseEvent<Element>) => {
    event.stopPropagation();
    onToggle();
  };

  const Button = (props: ActionButtonProps) => {
    if (buttonText) {
      const StartIcon = buttonIcon;
      return <SecondaryButton startIcon={<StartIcon />} text={buttonText} {...props} />;
    }

    return <BigidButtonIcon icon={buttonIcon} {...props} />;
  };

  return (
    <>
      <Container ref={anchroRef}>
        <Button
          buttonIcon={buttonIcon}
          size="medium"
          buttonText={buttonText}
          disabled={isDisabled}
          onClick={handleButtonClick}
          dataAid={generateDataAid(dataAid, ['button'])}
        />
      </Container>
      <BigidPopper
        popperOffset={[0, 8]}
        anchorEl={anchroRef.current}
        open={isOpen}
        width={popperWidth}
        isArrowDisabled
        onClose={onToggle}
        zIndex={9999}
      >
        <>
          <HeaderContainer>
            <BigidHeading5>{popperTitle}</BigidHeading5>
            <BigidButtonIcon
              onClick={onToggle}
              icon={BigidXIcon}
              size="small"
              dataAid={generateDataAid(dataAid, ['close-button'])}
            />
          </HeaderContainer>
          {children}
        </>
      </BigidPopper>
    </>
  );
};
