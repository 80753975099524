import { useState } from 'react';
import { Filters } from '../../../../../types/actionableInsights';

export enum ActionsDialogTypes {
  ASSIGN = 'assign',
  SILENCE = 'silence',
  REMEDIATE = 'remediate',
  ACKNOWLEDGE = 'acknowledge',
  JIRA = 'jira',
}

export type OpenModalWithData = (dialogType: ActionsDialogTypes, data: any[], _filters?: Filters) => void;
export type OpenCaseSidePanelWithData = (data: any[]) => void;

export const useCaseActions = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [isCaseSidePanelOpen, setIsCaseSidePanelOpen] = useState(false);
  const [modalType, setModalType] = useState<ActionsDialogTypes>();
  const [rowData, setRowData] = useState<any[]>();
  const [filters, setFilters] = useState<Filters | null>(null);

  const closeModal = () => {
    setIsOpen(false);
  };

  const openModalWithData: OpenModalWithData = (dialogType, data, _filters) => {
    setIsOpen(true);
    setRowData(data);
    setModalType(dialogType);
    _filters && setFilters(_filters);
  };

  const openCaseSidePanelWithData: OpenCaseSidePanelWithData = data => {
    setRowData(data);
    setIsCaseSidePanelOpen(true);
  };

  const closeCaseSidePanel = () => setIsCaseSidePanelOpen(false);

  return {
    isOpen,
    isCaseSidePanelOpen,
    setIsCaseSidePanelOpen,
    closeCaseSidePanel,
    modalType,
    rowData,
    openCaseSidePanelWithData,
    closeModal,
    openModalWithData,
    filters,
  };
};
