import { CustomDashboardConfig } from '@bigid-ui/custom-dashboard';

export const externalDashboardConfig = (fullyQualifiedName: string): CustomDashboardConfig => {
  return {
    widgets: [
      {
        id: 'bamDashboardWidget',
        widget: {
          externalApp: {
            height: '100vh',
            appName: 'BAM',
            routeToWidget: `?entity=${encodeURI(fullyQualifiedName)}`,
          },
        },
      },
    ],
    layout: {
      direction: 'row',
      justifyContent: 'center',
      alignContent: 'center',
      alignItems: 'center',
      spacing: 1,
      layoutSlots: [
        {
          size: 12,
          layoutGrid: {
            justifyContent: 'space-between',
            alignItems: 'flex-start',
            spacing: 2,
            layoutSlots: [
              {
                widgetId: 'bamDashboardWidget',
                size: 12,
              },
            ],
          },
        },
      ],
    },
  };
};
