import { styled } from '@mui/material';
import React, { FC } from 'react';
import { useLocalTranslation } from '../../../../../translations';
import { HightlightedValueResponse } from '../../../../../types';
import { getHighlightedValue } from '../../../../../utils';

const Container = styled('div')`
  gap: 12px;
  align-items: center;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: pre-wrap;
`;

export const Description: FC<HightlightedValueResponse> = ({ highlightedValue }) => {
  const { t } = useLocalTranslation('reasons');

  return (
    <Container>
      {t('description')}
      {highlightedValue.map(highlight => getHighlightedValue(highlight))}
    </Container>
  );
};
