import { AuditTrailPropChangeDetails } from '../dataCatalogAuditTrailService';

export type GroupedProperties = {
  /** [[previous][current]] */
  modifiedProps: AuditTrailPropChangeDetails[][];
  unaffectedProps: AuditTrailPropChangeDetails[];
};

export function getPropertiesGrouped(
  current: AuditTrailPropChangeDetails[],
  previous: AuditTrailPropChangeDetails[],
): GroupedProperties {
  return current.reduce(
    (groupedFields, currentPropDetails) => {
      const previousPropDetails = previous.find(({ name }) => name === currentPropDetails.name);

      if (previousPropDetails) {
        return {
          ...groupedFields,
          modifiedProps: [...groupedFields.modifiedProps, [previousPropDetails, currentPropDetails]],
        };
      } else {
        return {
          ...groupedFields,
          unaffectedProps: [...groupedFields.unaffectedProps, currentPropDetails],
        };
      }
    },
    { modifiedProps: [], unaffectedProps: [] },
  );
}

export function getDisplayValue(value: AuditTrailPropChangeDetails['value']): string {
  return Array.isArray(value) ? value.join(', ') : value;
}
