import './stepContainer.scss';
import template from './stepContainer.html';
import { module } from 'angular';
const app = module('app');

/*
Usage Example:

<step-container step-number="::1">
  <step-title>My Step title</step-title>
  <step-content>
    <div>Press this button</div>
    <bigid-button btn-on-click="$ctrl.doSomething()">Download</bigid-button>
    <div>More content</div>
  </step-content>
</step-container>

*/

app.component('stepContainer', {
  template,
  transclude: {
    stepTitle: '?stepTitle',
    stepContent: '?stepContent',
  },
  /**
   * Please use prefix in this component bindings,
   * to avoid attribute names collisions with Native and Popular UI frameworks such as ui-bootstrap etc...
   */
  bindings: {
    stepNumber: '<',
    stepDisabled: '<',
  },
});
