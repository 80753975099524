import { BigidColorsV2 } from '@bigid-ui/components';
import styled from '@emotion/styled';
import React, { FC } from 'react';

const ColorCircleItem = styled('div')<Omit<ColorProps, 'handleColorClick'>>`
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background-color: ${props => props.color};
  cursor: pointer;
  &::after {
    content: '';
    position: absolute;
    margin-top: -5px;
    margin-left: -5px;
    width: 26px;
    height: 26px;
    border-radius: 50%;
    border: 1px solid ${BigidColorsV2.gray[500]};
  }
`;

interface ColorProps {
  color: string;
}
export const ColorComponent: FC<ColorProps> = ({ color }) => {
  return <ColorCircleItem color={color} />;
};
