import React from 'react';
import { PrimaryButton, TertiaryButton } from '@bigid-ui/components';
import { openSystemDialog } from '../../../../services/systemDialogService';
import { useLocalTranslation } from '../../translations';

interface SaveChangesProp {
  classifierName: string;
}

const SaveChanges = ({ classifierName }: SaveChangesProp) => {
  const { t } = useLocalTranslation('saveChangesDialog');
  return <div>{t('description', { classifierName })}</div>;
};

export const openSaveChangesDialog = async (classifierName: string) => {
  return new Promise<boolean>(resolve => {
    openSystemDialog({
      title: 'Save Changes',
      content: SaveChanges,
      contentProps: {
        classifierName,
      },
      maxWidth: 'sm',
      onClose: () => null,
      buttons: [
        {
          text: 'Discard',
          component: TertiaryButton,
          onClick: () => resolve(false),
          isClose: true,
          dataAid: 'discard-changes',
        },
        {
          text: 'Save',
          component: PrimaryButton,
          onClick: () => {
            resolve(true);
          },
          isClose: true,
          dataAid: 'save-changes',
        },
      ],
    });
  });
};
