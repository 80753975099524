import { head } from 'lodash';
import { HightlightedValueResponse } from '../../types';

function getFirstValue(value: string[] | string): string {
  if (Array.isArray(value)) {
    return head(value) || '';
  }
  return value;
}

export function getHighlightOrFallback(
  fieldName: string,
  highlights: HightlightedValueResponse[],
  defaultValue: string,
) {
  const highlight = highlights.find(highlight => highlight.fieldName === fieldName);
  const valueToParse = highlight?.highlightedValue[0] || highlight?.value || defaultValue;

  return getFirstValue(valueToParse);
}
