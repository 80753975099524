import React, { FC, useEffect, useState, memo, useContext } from 'react';
import { BigidHeading1, BigidHeading6, BigidLoader } from '@bigid-ui/components';
import { CaseSidePanelContext } from '../../hooks/CaseSidePanelContext';
import { fetchNumOfFindings } from '../caseReportService';
import { useLocalTranslation } from '../../../translations';
import { generateDataAid } from '@bigid-ui/utils';
import { ReducerActions } from '../../hooks/CaseSidePanelReducer';
import styled from '@emotion/styled';
import { formatNumberCompact } from '../../../../../utilities/numericDataConverter';

const Main = styled.div<{
  isVisible?: boolean;
}>(({ isVisible }) => ({
  position: 'relative',
  display: isVisible ? 'flex' : 'none',
  flexDirection: 'column',
  justifyContent: 'center',
  gap: 2,
  height: 104,
  width: 147,
}));

const TypographyOverflow = styled.div({
  overflow: 'hidden',
  textOverflow: 'ellipsis',
});

const ColumnWrapper = styled.div({
  display: 'flex',
  flexDirection: 'column',
  gap: 8,
  padding: '0px 32px',
});

const RowWrapper = styled.div({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-start',
  gap: 8,
  height: 45,
  width: 147,
});

export const FindingsWidget: FC = memo(() => {
  const { caseSidePanelData, dispatch, isFindingsWidgetsVisible } = useContext(CaseSidePanelContext);
  const [numOfFindings, setNumOfFindings] = useState<string>('0');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { t } = useLocalTranslation('CaseReport');

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const { dataSourceName, policyName } = caseSidePanelData;
        const result = await fetchNumOfFindings({ dataSourceName, policyName });
        dispatch({
          type: ReducerActions.UPDATE_FINDINGS_WIDGET_VISIBILITY,
          payload: { isFindingsWidgetsVisible: Number(result) > 0 },
        });
        dispatch({
          type: ReducerActions.UPDATE_AMOUNT_OF_FINDINGS,
          payload: { amountOfFindings: Number(result) },
        });
        if (Number(result) === 0) {
          console.warn('Amount of findings are equal to 0');
        }
        const formattedFindingsValue = formatNumberCompact(result, 1);
        setNumOfFindings(formattedFindingsValue);
      } catch ({ message }) {
        if (message) {
          console.error(`An error has occurred: ${message}`);
        }
        dispatch({
          type: ReducerActions.UPDATE_WIDGETS_ERROR_STATUS,
          payload: { hasWidgetsErrors: true },
        });
        dispatch({
          type: ReducerActions.UPDATE_FINDINGS_WIDGET_VISIBILITY,
          payload: { isFindingsWidgetsVisible: false },
        });
      } finally {
        setIsLoading(false);
      }
    };
    fetchData().catch(console.error);
  }, [caseSidePanelData, dispatch, t]);

  return (
    <Main isVisible={isFindingsWidgetsVisible}>
      {isLoading ? (
        <BigidLoader />
      ) : (
        <ColumnWrapper>
          <RowWrapper>
            <TypographyOverflow data-aid={generateDataAid('FindingsWidget', ['container'])}>
              <BigidHeading1 fontWeight={600} fontSize={36}>
                {numOfFindings}
              </BigidHeading1>
            </TypographyOverflow>
          </RowWrapper>
          <TypographyOverflow>
            <BigidHeading6 fontWeight={400}>{t('upperWidgetsTitles.findings')}</BigidHeading6>
          </TypographyOverflow>
        </ColumnWrapper>
      )}
    </Main>
  );
});
