import { useRef } from 'react';
import { CLASSIFIER_TYPES, ClassifierGridRow } from '../types/ClassifierTypes';
import * as classifiersManagementService from '../services/classifiersManagementService';
import { openCreateExcludeListClassifierDialog } from '../../../../administration/classifiers/ClassifiersExcludeListDialog/ClassifiersExcludeListDialog';
import { intersectionWith, omit } from 'lodash';
import { notificationService } from '../../../services/notificationService';
import * as classifierManagementUtils from '../utils/utils';

export const useExcludeList = () => {
  const removeExcludeList = useRef([]);

  const isNerOrDoc = (classifierType: CLASSIFIER_TYPES) => {
    const isNER = classifierManagementUtils.isNer(classifierType);
    const isDOC = classifierManagementUtils.isDoc(classifierType);
    return isNER || isDOC;
  };

  const removeIgnoreValueHandler = (excludeValue: any) => {
    removeExcludeList.current.push(excludeValue);
  };

  const generateExcludeListRequest = (modifiedItems: any[]) => {
    const commonItems = intersectionWith(modifiedItems, removeExcludeList.current, (modifyItem, removeItem) => {
      return modifyItem.value === removeItem.value && modifyItem.type === removeItem.type;
    });
    const updatedModifiedItems = modifiedItems.filter(modifyItem => {
      const isExistInCommon = commonItems.some(
        commonItem => commonItem.value === modifyItem.value && commonItem.type === modifyItem.type,
      );
      if (isExistInCommon) {
        return false;
      }
      return true;
    });

    const updatedRemovedItems = removeExcludeList.current.filter(removedItem => {
      const isExistInCommon = commonItems.some(
        commonItem => commonItem.value === removedItem.value && commonItem.type === removedItem.type,
      );
      if (isExistInCommon) {
        return false;
      }
      return true;
    });
    return { updatedModifiedItems, updatedRemovedItems };
  };

  const excludeListHandler = async (classifier: ClassifierGridRow) => {
    const requestedClassifierName = isNerOrDoc(classifier.classifierType)
      ? (classifier._id as string)
      : classifier.classification_name;

    const [regexList, stringList] = await Promise.all([
      classifiersManagementService.getClassifierExcludeListByName(requestedClassifierName, 'regex'),
      classifiersManagementService.getClassifierExcludeListByName(requestedClassifierName, 'string'),
    ]);

    const requestedRegexList = regexList.data.data.excludeList.map((excludeRegexValue: any) => ({
      ...excludeRegexValue,
      id: excludeRegexValue._id,
    }));
    const requestedStringList = stringList.data.data.excludeList.map((excludeStringValue: any) => ({
      ...excludeStringValue,
      id: excludeStringValue._id,
    }));

    const defaultValueCaseSensetiveString = stringList.data.data.excludeList.some(
      (excludeStringValue: any) => excludeStringValue.isCaseSensitive === true,
    );

    const { data, isCancelOccured } = await openCreateExcludeListClassifierDialog(
      requestedClassifierName,
      requestedRegexList,
      requestedStringList,
      removeIgnoreValueHandler,
      defaultValueCaseSensetiveString,
    );
    if (isCancelOccured) {
      removeExcludeList.current = [];
      return;
    }
    const { updatedModifiedItems, updatedRemovedItems } = generateExcludeListRequest(data);
    removeExcludeList.current = [];

    const requests = [];
    if (updatedModifiedItems.length > 0) {
      const body = {
        excludeList: updatedModifiedItems.map(modifiedItem => omit(modifiedItem, ['id'])),
      };
      const upsertExcludeValues = classifiersManagementService.upsertExcludeList(body);
      requests.push(upsertExcludeValues);
    }
    if (updatedRemovedItems.length > 0) {
      const body = {
        excludeValuesIds: updatedRemovedItems.map(removedItem => removedItem._id),
      };
      const deleteManyExcludeValues = classifiersManagementService.deleteManyExcludeValues(body);
      requests.push(deleteManyExcludeValues);
    }

    try {
      if (requests.length > 0) {
        await Promise.all(requests);
        notificationService.success('Exclude list saved successfully');
      }
    } catch (error) {
      notificationService.error(error.data.message);
    }
  };

  return { excludeListHandler };
};
