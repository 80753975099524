import { objectToQueryString, QueryParams } from '@bigid-ui/components';
import { BigidGridQueryComponents } from '@bigid-ui/grid';
import { getDataCatalogRecords } from '../DataCatalogService';

export const getFileDuplicatesByDuplicateId = async (duplicateId: string, baseQuery: BigidGridQueryComponents = {}) => {
  const filter = `duplicate_id = ${duplicateId}`;
  const query = objectToQueryString({
    ...(baseQuery as QueryParams),
    filter,
  });

  return getDataCatalogRecords(query.length > 0 && `&${query}`);
};
