import React, { FC } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { BigidHeading4, SecondaryButton, StyledButtonType } from '@bigid-ui/components';

export interface SaveDialogContentProps {
  entityName: string;
  isError?: boolean;
}

const useStyles = makeStyles({
  wrapper: {
    marginRight: 'auto',
  },
  textWrapper: {
    paddingBottom: '40px',
  },
});

export const SaveModalCancelButton: FC<StyledButtonType> = props => (
  <div className={useStyles({}).wrapper}>
    <SecondaryButton {...props} />
  </div>
);

export const SaveDialogContent: FC<SaveDialogContentProps> = ({ entityName, isError }) => (
  <BigidHeading4 data-aid="DataSourceConfCloseModalContent" className={useStyles({}).textWrapper}>
    {isError
      ? 'Some of the required fields contain invalid values. Do you wish to fix and save them before leaving?'
      : `Do you want to save changes on this ${entityName} before leaving?`}
  </BigidHeading4>
);
