import './credentials.component.scss';
import { module } from 'angular';
import { sortAlphabetically } from '../../common/services/localizationHelper';
import template from './credentials.component.html';
import { differenceBy, difference, isEqual, isEmpty } from 'lodash';
import { httpService } from '../../react/services/httpService';
import { isPermitted } from '../../react/services/userPermissionsService';
import { CREDENTIALS_PERMISSIONS } from '@bigid/permissions';
import { $rootScope } from 'ngimport';
import { dateUtils } from '../../react/services/angularServices';
import { getApplicationPreference } from '../../react/services/appPreferencesService';
import { defaultApiCallFormValue } from '../../react/components/Credentials/CustomCredentialApiCallDialog/customCredentailDialogUtils';
import '../../react/components/Credentials/CustomCredentialApiCallDialog/CustomCredentialApiCallDialog';
import '../../react/views/Credentials/CredentialsTypesForm';
import { CredentialType, DEFAULT_CREDENTIAL_INITIAL_VALUE } from '../../react/views/Credentials/credentialsFormUtils';
import { ALLOWED_NAME_REGEX } from '../../react/config/consts';
import { convertToReact } from '../../common/services/convertToReact';
import { isMultiTenantModeEnabled } from '../../react/utilities/multiTenantUtils';
const BIGID_TYPE_LABEL = 'BigID';

const app = module('app');

export const CredentialTypes = {
  CyberArk: 'CyberArk',
  RemoteCyberArk: 'RemoteCyberArk',
  BigID: 'simple',
  HashiCorp: 'HashiCorp',
  CustomApplicationVault: 'Custom',
  RemoteHashiCorp: 'RemoteHashiCorp',
  RemoteCustom: 'RemoteCustom',
  Thycotic: 'Thycotic',
  RemoteThycotic: 'RemoteThycotic',
};

export const EnginesTypes = {
  'Key/Value': 'Key/Value',
  'Google Cloud': 'Google Cloud',
};

export const getCredentialTitle = credential => {
  if (!credential?.credential_id) return;

  const { type, credential_id: credentialId } = credential;

  if (type === CredentialTypes.BigID) {
    return credentialId;
  }

  return `${credentialId} (${type})`;
};

app.constant('CredentialTypes', CredentialTypes);

const config = {
  template,
  controller: function (
    $element,
    $timeout,
    $translate,
    credentialsService,
    DeleteConfirmation,
    notificationService,
    rolesService,
  ) {
    'ngInject';

    const TYPE_FIELD_NAME = 'type';
    const SCOPES_FIELD_NAME = 'selectedScopes';
    const CREDENTIAL_ID_FIELD_NAME = 'credential_id';
    const USERNAME_FIELD_NAME = 'username';
    const PASS_FIELD_NAME = 'password';
    const QUERY_FIELD_NAME = 'query';
    const SCANNER_GROUP_FIELD_NAME = 'scanner_group';
    const VAULT_SECRET_PATH = 'url';
    const VAULT_SECRET_ENGINE = 'secret_engine';
    const KEY_VALUE = 'Key/Value';
    const GCP = 'Google Cloud';
    const TIME_TO_LIVE = 'ttl';
    const USERNAME_FIELD_IDENTIFIER = 'usernameIdentifier';
    const PASSWORD_FIELD_IDENTIFIER = 'passwordIdentifier';

    const CREDENTIALS_DATA_LIST_ID = 'credentialsDataList';
    const CREDENTIALS_DATA_CONTAINER_BODY_ID = 'credentialsContentBody';
    const CREDENTIALS_DATA_LIST_ITEM_ID = 'credential';

    const element = $element[0];

    const dataListContainer = element.querySelector(`#${CREDENTIALS_DATA_LIST_ID}`);
    const contentBodyContainer = element.querySelector(`#${CREDENTIALS_DATA_CONTAINER_BODY_ID}`);

    this.credentialForm = undefined;
    this.testConnectionStatus = undefined;
    this.credentials = [];
    this.scopes = [];
    this.apps = [];
    this.selectedApps = [];
    this.actions = [];
    this.selectedActions = [];
    this.selectedScopes = [];
    this.credential = {};
    this.userQuery = '';
    this.customQuery = '';
    this.usernameAsPlainText = false;
    this.editModeOn = false;
    this.createModeOn = false;
    this.credentialId = '';
    this.isApiCallDialogOpen = false;
    this.apiCallFormDefaultValue = defaultApiCallFormValue;
    this.credentialsTypesForm = undefined;

    this.isDeletePermitted = isPermitted(CREDENTIALS_PERMISSIONS.DELETE.name);
    this.isEditPermitted = isPermitted(CREDENTIALS_PERMISSIONS.EDIT.name);
    this.isCreatePermitted = isPermitted(CREDENTIALS_PERMISSIONS.CREATE.name);
    this.isTestConnectionPermitted = isPermitted(CREDENTIALS_PERMISSIONS.TEST_CONNECTION.name);

    this.$onInit = () => {
      this.isLoading = true;
      this.onDataLoading();
      this.resetFormData();

      const promises = [rolesService.getRBACScopes(), httpService.fetch('tpa')];

      Promise.all(promises)
        .then(
          ([
            {
              data: { scopes = [] },
            },
            { data: apps },
          ]) => {
            this.apps = apps.map(({ tpa_name: label, _id: value }) => ({ value, label }));
            this.scopes = scopes.map(scope => ({ label: scope.name, value: scope.id }));

            if (this.formOnly) {
              if (this.formData) {
                return updateActionsOnEntityChange(this.formData);
              } else {
                this.onCreateCredential();
              }
            } else {
              return this.loadCredentialsData().then(credentials => {
                const filteredCredentials = filterCredentials(credentials);
                this.credentials = filteredCredentials.sort(sortCredentials);
                if (this.credentials.length) {
                  return updateActionsOnEntityChange(this.credentials[0]);
                }
              });
            }
          },
        )
        .finally(() => {
          this.onDataLoaded();
          this.isLoading = false;
          $rootScope.$applyAsync();
        });
    };

    this.getCredentialLastUpdateTime = credential => {
      const updatedAt = credential?.updated_at;
      return updatedAt ? dateUtils.formatDate(updatedAt) : '';
    };

    this.loadCredentialsData = () => {
      return credentialsService
        .getCredentials()
        .then(({ data: creds = [] }) => {
          return creds;
        })
        .catch(err => {
          $translate('API:MESSAGE:COMMON_ERROR').then(translation => {
            notificationService.error(translation);
          });
          throw err;
        });
    };

    this.onCreate = () => {
      if (
        this.credentialForm &&
        (this.credentialForm.$dirty ||
          !isSelectedScopesEquals() ||
          isAppsDataHasChanged() ||
          isFetchFunctionChanged() ||
          this.hasCredentialsTypesFormChanged())
      ) {
        this.onUserLeavesForm().then(
          () => this.onSubmitCredential({}),
          () => this.onCreateCredential(),
        );
      } else {
        this.onCreateCredential();
      }
    };

    this.onEdit = entityToProceed => {
      const { credential_id: credentialId } = entityToProceed || {};

      if (credentialId && this.credentialId !== credentialId) {
        if (
          this.credentialForm.$dirty ||
          !isSelectedScopesEquals() ||
          isAppsDataHasChanged() ||
          this.hasCredentialsTypesFormChanged()
        ) {
          const areAppsAndActionLegal =
            this.getValueFromFormData(TYPE_FIELD_NAME) !== CredentialTypes.CustomApplicationVault ||
            this.selectedActions.length > 0;

          if (this.isEditPermitted && entityToProceed.scopes.length > 0 && areAppsAndActionLegal) {
            this.onUserLeavesForm().then(
              () => this.onSubmitCredential(entityToProceed),
              () => updateActionsOnEntityChange(entityToProceed),
            );
          } else {
            updateActionsOnEntityChange(entityToProceed);
          }
        } else {
          updateActionsOnEntityChange(entityToProceed);
        }
      }
    };

    this.onDeleteClick = credential => {
      const { credential_id: entityName, credential_id: credentialId } = credential;
      return DeleteConfirmation.showModal(
        {},
        {
          closeButtonText: $translate.instant('BUTTONS:CLOSE'),
          actionButtonText: $translate.instant('BUTTONS:DELETE'),
          headerText: $translate.instant('APP:COMMON:DELETE_CONFIRMATION_HEADER', { entityName }),
          bodyText: $translate.instant('APP:COMMON:DELETE_CONFIRMATION_TEXT', { entityName }),
        },
      ).then(() => this.deleteCredential(credentialId));
    };

    this.fetchCredentials = entityToProceed => {
      this.onDataLoading();
      return credentialsService.getCredentials().then(
        ({ data: creds = [] }) => {
          this.onDataLoaded();
          this.credentials = creds.sort(sortCredentials);
          this.onDataLoaded();

          if (entityToProceed) {
            if (Object.keys(entityToProceed).length === 0 && entityToProceed.constructor === Object) {
              this.onCreateCredential();
              this.scrollToTop();
            } else {
              updateActionsOnEntityChange(entityToProceed).then(() => {
                this.scrollToEntity(entityToProceed);
              });
            }
          } else {
            if (this.editModeOn) {
              this.resetListSelection(this.credentialId);
            }
          }
        },
        () => {
          this.onDataLoaded();
          notificationService.error($translate.instant('API:MESSAGE:COMMON_ERROR'));
        },
      );
    };

    this.scrollToTop = () => {
      if (!dataListContainer) return;
      dataListContainer.scrollTop = 0;
    };

    this.scrollToEntity = entityToProceed => {
      if (!dataListContainer || !entityToProceed) return;

      const findByObjectId = entityToProceed._id
        ? ({ _id: id }) => id === entityToProceed._id
        : ({ credential_id: credentialId }) => credentialId === this.credentialId;

      const index = this.credentials.findIndex(findByObjectId);
      const dataListItem = index !== -1 && element.querySelector(`#${CREDENTIALS_DATA_LIST_ITEM_ID}-${index}`);
      if (dataListItem) {
        dataListItem.scrollIntoViewIfNeeded ? dataListItem.scrollIntoViewIfNeeded() : dataListItem.scrollIntoView();
      }
    };

    this.createCredential = data => {
      return credentialsService
        .createCredential(data)
        .then(() => {
          notificationService.success($translate.instant('API:MESSAGE:COMMON_POST_SUCCESS'));
        })
        .catch(err => {
          // TODO: should handle duplicate key on the server in more elegant way
          const apiError = (err.data && err.data.error) || '';
          let notificationMsg = $translate.instant('API:MESSAGE:COMMON_ERROR');
          if (apiError.includes('duplicate key error')) {
            const uniqueFieldLabel = $translate.instant(
              this.dataInputFields.find(({ name }) => name === CREDENTIAL_ID_FIELD_NAME).label,
            );
            notificationMsg = $translate.instant('API:MESSAGE:COMMON_DUPLICATE_KEY_ERROR', {
              name: uniqueFieldLabel,
            });
          }
          notificationService.warning(notificationMsg);
          window.console.error(err);
          throw err;
        });
    };

    this.updateCredential = (id, data) => {
      return credentialsService
        .updateCredential(id, data)
        .then(() => {
          const shouldAddCredentialsTypesForm = this.credentialsTypesForm && this.shouldShowCredentialsTypesForm();

          this.credential = {
            ...this.credential,
            scopes: [...this.selectedScopes],
            actions: [...this.selectedActions],
            customQuery: this.customQuery,
            usernameAsPlainText: this.usernameAsPlainText,
            apps: [...this.selectedApps],
            ...(shouldAddCredentialsTypesForm && this.credentialsTypesForm),
          };

          notificationService.success($translate.instant('API:MESSAGE:COMMON_PUT_SUCCESS'));
        })
        .catch(() => {
          notificationService.error($translate.instant('API:MESSAGE:COMMON_ERROR'));
        });
    };

    this.deleteCredential = id => {
      credentialsService.deleteCredential(id).then(
        () => {
          notificationService.success($translate.instant('API:MESSAGE:COMMON_DELETE_SUCCESS'));

          this.credential = {};
          this.credentialId = '';
          this.createModeOn = false;
          this.editModeOn = false;
          this.resetScopesAppsActions();

          this.resetListSelection();
          this.resetFormData();
          this.resetFormState();

          if (this.formOnly) {
            this.onFormSubmitted({ isUpdated: true, credentialName: '' });
          } else {
            this.fetchCredentials().then(() => this.scrollToTop());
          }
        },
        () => {
          notificationService.error($translate.instant('API:MESSAGE:COMMON_ERROR'));
        },
      );
    };

    this.resetScopesAppsActions = () => {
      this.selectedScopes = [];
      this.selectedApps = [];
      this.selectedActions = [];
      this.customQuery = '';
      this.usernameAsPlainText = false;
    };

    this.onUserLeavesForm = () => {
      return DeleteConfirmation.showModal(
        {},
        {
          closeButtonText: $translate.instant('BUTTONS:NO'),
          actionButtonText: $translate.instant('BUTTONS:YES'),
          headerText: $translate.instant('APP:COMMON:LEAVE_CONFIRMATION_HEADER'),
          bodyText: $translate.instant('APP:COMMON:LEAVE_CONFIRMATION_TEXT'),
        },
      );
    };

    this.onCreateCredential = () => {
      this.credential = {};
      this.credentialId = '';
      this.createModeOn = true;
      this.editModeOn = false;

      this.resetListSelection();
      this.resetFormData();
      this.updateFormData(this.credential);
      this.resetFormState();
      this.resetScopesAppsActions();
      this.lastUpdatedTime = '';
    };

    this.onEditCredential = credential => {
      const { credential_id: credentialId } = credential;
      this.credentialId = credentialId;
      const scopes = credential.scopes
        ? credential.scopes.map(scope => (scope.label || scope.value ? scope : { label: scope, value: scope }))
        : [];

      const apps = credential.apps || [];
      const actions = credential.actions || [];
      const customQuery = credential.customQuery || '';
      const usernameAsPlainText = credential.usernameAsPlainText || false;

      this.lastUpdatedTime = this.getCredentialLastUpdateTime(credential);
      this.credential = { ...credential, scopes, apps, actions, customQuery, usernameAsPlainText };
      this.selectedScopes = [...scopes];
      this.selectedApps = [...apps];
      this.selectedActions = [...actions];
      this.customQuery = customQuery;
      this.usernameAsPlainText = usernameAsPlainText;
      this.resetListSelection(credentialId);
      this.setFetchFunction();

      this.editModeOn = true;
      this.createModeOn = false;

      this.resetFormData();
      this.updateFormData();
      this.resetFormState();
    };

    this.getHeaderTitle = () => {
      if (this.createModeOn) {
        const name = this.getValueFromFormData(CREDENTIAL_ID_FIELD_NAME);
        return name
          ? getCredentialTitle({
              [CREDENTIAL_ID_FIELD_NAME]: name,
              [TYPE_FIELD_NAME]: this.getValueFromFormData(TYPE_FIELD_NAME),
            })
          : $translate.instant('CREDENTIALS:HEADER:NAME_PLACEHOLDER') || '';
      }
      if (this.editModeOn) {
        return this.getCredentialTitle(this.credential);
      }
    };

    this.onSaveFetchFunctionConfig = apiCall => {
      this.fetchFunctionRequestParams = apiCall;
      this.fetchFunctionFormValues = apiCall;
    };

    this.onSubmitCredential = (entityToProceed = null) => {
      if (!this.credentialForm || !this.credentialForm.$valid) return;

      const shouldAddCredentialsTypesForm = this.credentialsTypesForm && this.shouldShowCredentialsTypesForm();

      if (this.createModeOn) {
        let data = this.getApiDataObject();

        data = {
          ...data,
          scopes: this.selectedScopes.map(scope => scope.value).filter(val => val),
          apps: this.selectedApps,
          actions: this.selectedActions,
          customQuery: this.customQuery,
          usernameAsPlainText: this.usernameAsPlainText,
          fetchFunctionRequestParams: this.fetchFunctionRequestParams,
          ...(shouldAddCredentialsTypesForm && this.credentialsTypesForm),
        };

        this.createCredential(data).then(() => {
          this.resetFormState();

          if (this.formOnly) {
            this.onFormSubmitted({
              isUpdated: true,
              credentialName: this.getValueFromFormData(CREDENTIAL_ID_FIELD_NAME),
            });
          } else {
            this.editModeOn = true;
            this.createModeOn = false;
            this.credentialId = this.getValueFromFormData(CREDENTIAL_ID_FIELD_NAME);
            this.fetchCredentials().then(() => {
              const newCredential = {
                ...this.credentials.find(({ credential_id: credentialId }) => credentialId === this.credentialId),
                scopes: this.selectedScopes,
                actions: this.selectedActions,
                apps: this.selectedApps,
                customQuery: this.customQuery,
                usernameAsPlainText: this.usernameAsPlainText,
              };
              if (newCredential) {
                this.onEditCredential(newCredential || {});
                $timeout(() => this.scrollToEntity(newCredential));
              }
            });
          }
        });
      }

      if (this.editModeOn) {
        const data = this.getApiDataObject();
        const credentialId = this.getValueFromData(CREDENTIAL_ID_FIELD_NAME);
        const removeFields = [CREDENTIAL_ID_FIELD_NAME, TYPE_FIELD_NAME];
        const payload = {
          ...Object.entries(data)
            .filter(([fieldName]) => !removeFields.includes(fieldName))
            .filter(([fieldName]) => this.credentialForm[fieldName].$dirty)
            .reduce((acc, [key, val]) => Object.assign(acc, { [key]: val }), {}),
          ...(shouldAddCredentialsTypesForm && this.credentialsTypesForm),
        };

        if (PASS_FIELD_NAME in payload) {
          payload.isPasswordChanged = true;
        }

        if (!isSelectedScopesEquals()) {
          payload.scopes = [...this.selectedScopes].map(scope => scope.value).filter(val => val);
        }

        if (isAppsDataHasChanged()) {
          payload.apps = [...this.selectedApps];
          payload.actions = [...this.selectedActions];
          payload.customQuery = this.customQuery;
          payload.usernameAsPlainText = this.usernameAsPlainText;
        }

        if (isFetchFunctionChanged()) {
          payload.fetchFunctionRequestParams = this.fetchFunctionRequestParams;
          this.credential.fetchFunctionRequestParams = this.fetchFunctionRequestParams;
        }

        this.updateCredential(credentialId, payload).then(() => {
          this.resetFormState();

          if (this.formOnly) {
            this.onFormSubmitted({ isUpdated: true });
          } else {
            if (contentBodyContainer) contentBodyContainer.scrollTop = 0;
            this.fetchCredentials(entityToProceed).then(() => {
              const updatedCred = this.credentials.find(
                ({ credential_id: credentialId }) => credentialId === this.credentialId,
              );
              this.lastUpdatedTime = this.getCredentialLastUpdateTime(updatedCred);
            });
          }
        });
      }
    };

    this.onSaveClick = () => {
      this.onSubmitCredential();
    };

    const isSelectedScopesEquals = () => {
      const originalScopes = this.credential?.scopes || [];

      if (this.selectedScopes.length !== originalScopes.length) {
        return false;
      }

      return differenceBy(this.selectedScopes, originalScopes, 'value').length === 0;
    };

    const isAppsDataHasChanged = () => {
      const originalApps = this.credential?.apps || [];
      const originalActions = this.credential?.actions || [];
      const originalCustomQuery = this.credential?.customQuery || '';
      const originalUsernameAsPlainText = this.credential?.usernameAsPlainText || false;
      const hasAppsChanged = difference(this.selectedApps, originalApps, 'value').length !== 0;
      const hasActionsChanged = difference(this.selectedActions, originalActions, 'value').length !== 0;
      const hasCustomQueryChanged = this.customQuery !== originalCustomQuery;
      const hasUsernameAsPlainTextChanged = this.usernameAsPlainText !== originalUsernameAsPlainText;

      return hasAppsChanged || hasActionsChanged || hasCustomQueryChanged || hasUsernameAsPlainTextChanged;
    };

    const isFetchFunctionChanged = () => {
      const originalFetchFunction = this.credential?.fetchFunctionRequestParams || {};
      return this.fetchFunctionRequestParams && !isEqual(this.fetchFunctionRequestParams, originalFetchFunction);
    };

    const isRemoteCustomSaveEnabled = () =>
      (isFetchFunctionChanged() || !this.credentialForm.$pristine) &&
      isFetchFunctionValid() &&
      this.credentialForm &&
      this.credentialForm.$valid;

    const isFetchFunctionValid = () => {
      return this.fetchFunctionFormValues.type !== null && this.fetchFunctionFormValues.url !== null;
    };

    const enableToSaveByScope = () => {
      return (
        this.selectedScopes.filter(scope => this.scopes.find(generalScope => generalScope.value === scope.value))
          .length > 0
      );
    };

    this.isSaveEnabled = () => {
      if (this.isCustomMode && (this.selectedApps.length === 0 || this.selectedActions.length === 0)) {
        return false;
      }

      if (this.shouldShowCredentialsTypesForm() && !this.isCredentialsTypesFormValid()) {
        return false;
      }

      if (this.isRemoteCustom) {
        return isRemoteCustomSaveEnabled();
      }

      return (
        this.credentialForm &&
        this.credentialForm.$valid &&
        (!this.credentialForm.$pristine ||
          !isSelectedScopesEquals() ||
          isAppsDataHasChanged() ||
          this.hasCredentialsTypesFormChanged()) &&
        enableToSaveByScope() &&
        this.selectedScopes.length > 0
      );
    };

    this.isTestConnectionEnabled = () => {
      if (
        [
          CredentialTypes.RemoteCyberArk,
          CredentialTypes.RemoteHashiCorp,
          CredentialTypes.RemoteCustom,
          CredentialTypes.RemoteThycotic,
        ].includes(this.getValueFromFormData(TYPE_FIELD_NAME))
      ) {
        return !this.isSaveEnabled() && this.editModeOn && this.credentialForm.$valid;
      }

      return this.credentialForm && this.credentialForm.$valid;
    };

    this.showTestConnection = () => {
      return this.getValueFromFormData(TYPE_FIELD_NAME) !== CredentialTypes.BigID;
    };

    this.shouldShowCredentialsTypesForm = () => {
      return (
        getApplicationPreference('ENABLE_CREDENTIAL_CUSTOM_TYPES') &&
        this.getValueFromFormData(TYPE_FIELD_NAME) === CredentialTypes.BigID
      );
    };

    this.onCancelClick = () => this.onCreateCredential();

    this.onCloseClick = () => {
      this.onFormClosed();
    };

    this.credentialsFilter = query => credential => {
      const visibleName = this.getCredentialTitle(credential);
      return query && typeof query === 'string' ? visibleName.includes(query.toLowerCase()) : true;
    };

    this.getCredentialTitle = credential => getCredentialTitle(credential);

    this.openApiCallDialog = () => {
      this.isApiCallDialogOpen = true;
    };

    this.closeApiCallDialog = () => {
      this.isApiCallDialogOpen = false;
      $rootScope.$apply();
    };

    this.attributesDropdownSettings = {
      showCheckAll: false,
      showUncheckAll: false,
      checkBoxes: true,
      selectedToTop: false,
      displayProp: 'label',
      idProperty: 'value',
      enableSearch: true,
      smartButtonMaxItems: 2,
      smartButtonTextConverter: function (itemText) {
        return itemText;
      },
      template: '<span title="{{option.label}}">{{option.label}}</span>',
    };

    this.attributesDropdownSingleSettings = {
      ...this.attributesDropdownSettings,
      selectionLimit: 1,
    };

    this.resetFormState = () => {
      if (!this.credentialForm) return;
      this.credentialForm.$setPristine();
      this.credentialForm.$setUntouched();
      this.resetCredentialTypesForm();
    };

    function getTypesForDropDown() {
      return Object.entries(CredentialTypes)
        .map(([label, value]) => ({ label, value }))
        .filter(
          ({ value }) =>
            (!isRemoteCyberArk(value) &&
              !isRemoteHashiCorp(value) &&
              !isRemoteCustom(value) &&
              !isThycotic(value) &&
              !isRemoteThycotic(value)) ||
            shouldShowRemoteCyberArk(value) ||
            shouldShowRemoteHashiCorp(value) ||
            shouldShowThycotic(value) ||
            shouldShowRemoteThycotic(value) ||
            shouldShowRemoteCustom(value),
        );
    }

    function isRemoteCyberArk(value) {
      return value === CredentialTypes.RemoteCyberArk;
    }

    function shouldShowRemoteCyberArk(value) {
      return isRemoteCyberArk(value) && getApplicationPreference('SHOW_REMOTE_CYBERARK_CREDENTIALS_TYPE') === true;
    }

    function isRemoteHashiCorp(value) {
      return value === CredentialTypes.RemoteHashiCorp;
    }

    function shouldShowRemoteHashiCorp(value) {
      return isRemoteHashiCorp(value) && getApplicationPreference('SHOW_REMOTE_HASHICORP_CREDENTIALS_TYPE') === true;
    }

    function isRemoteCustom(value) {
      return value === CredentialTypes.RemoteCustom;
    }

    function shouldShowRemoteCustom(value) {
      return isRemoteCustom(value) && getApplicationPreference('SHOW_REMOTE_CUSTOM_CREDENTIALS_TYPE') === true;
    }

    function isThycotic(value) {
      return value === CredentialTypes.Thycotic;
    }

    function shouldShowThycotic(value) {
      return isThycotic(value) && getApplicationPreference('SHOW_THYCOTIC_CREDENTIALS_TYPE') === true;
    }

    function isRemoteThycotic(value) {
      return value === CredentialTypes.RemoteThycotic;
    }

    function shouldShowRemoteThycotic(value) {
      return isRemoteThycotic(value) && getApplicationPreference('SHOW_REMOTE_THYCOTIC_CREDENTIALS_TYPE') === true;
    }

    this.shoudShowApiCallFormModal = () =>
      (this.editModeOn && this.isEditPermitted && this.isRemoteCustom) ||
      (this.createModeOn && this.isCreatePermitted && this.isRemoteCustom);

    this.resetFormData = () => {
      const formFields = [
        {
          type: 'text',
          name: CREDENTIAL_ID_FIELD_NAME,
          label: 'CREDENTIALS:FORM:CREDENTIAL_ID',
          tabIndex: 1,
          apiField: true,
          supportedTypes: Object.values(CredentialTypes),
          disableInEditMode: true,
          pattern: ALLOWED_NAME_REGEX,
        },
        {
          type: 'select',
          name: TYPE_FIELD_NAME,
          label: 'CREDENTIALS:FORM:TYPE',
          tabIndex: 1,
          apiField: true,
          disableInEditMode: true,
          supportedTypes: Object.values(CredentialTypes),
          value: CredentialTypes.BigID,
          options: getTypesForDropDown(),
          onChange: () => {
            this.updateFormData();
          },
        },
        {
          type: 'select',
          name: SCOPES_FIELD_NAME,
          label: 'CREDENTIALS:FORM:SCOPES',
          tabIndex: 1,
          apiField: true,
          options: this.scopes,
          supportedTypes: Object.values(CredentialTypes),
          onChange: () => {
            this.updateFormData();
          },
        },
        ...(getApplicationPreference('ENABLE_CREDENTIAL_CUSTOM_TYPES')
          ? []
          : [
              {
                type: 'text',
                name: USERNAME_FIELD_NAME,
                label: 'CREDENTIALS:FORM:USERNAME',
                tabIndex: 1,
                apiField: true,
                supportedTypes: [CredentialTypes.BigID],
              },
              {
                type: 'password',
                name: PASS_FIELD_NAME,
                label: 'CREDENTIALS:FORM:PASSWORD',
                tabIndex: 1,
                apiField: true,
                supportedTypes: [CredentialTypes.BigID],
              },
            ]),
        {
          type: 'textarea',
          name: QUERY_FIELD_NAME,
          hidden: true,
          label: 'CREDENTIALS:FORM:CYBERARK_QUERY',
          tabIndex: 1,
          apiField: true,
          rows: 3,
          supportedTypes: [CredentialTypes.CyberArk, CredentialTypes.RemoteCyberArk],
        },
        {
          type: 'text',
          name: SCANNER_GROUP_FIELD_NAME,
          hidden: true,
          label: 'CREDENTIALS:FORM:REMOTE_CYBERARK_SCANNER_GROUP',
          tabIndex: 1,
          apiField: true,
          supportedTypes: [
            CredentialTypes.RemoteCyberArk,
            CredentialTypes.RemoteHashiCorp,
            CredentialTypes.RemoteThycotic,
            CredentialTypes.RemoteCustom,
          ],
        },
        {
          type: 'select',
          name: VAULT_SECRET_ENGINE,
          label: 'Vault Secret Engine',
          tabIndex: 1,
          apiField: true,
          disableInEditMode: true,
          value: EnginesTypes[KEY_VALUE],
          options: Object.entries(EnginesTypes).map(([label, value]) => ({ label, value })),
          supportedTypes: [CredentialTypes.HashiCorp, CredentialTypes.RemoteHashiCorp],
          onChange: () => {
            this.updateFormData();
          },
        },
        {
          type: 'text',
          name: VAULT_SECRET_PATH,
          label: 'Vault Secret Path',
          tabIndex: 1,
          apiField: true,
          supportedTypes: [
            CredentialTypes.HashiCorp,
            CredentialTypes.RemoteHashiCorp,
            CredentialTypes.Thycotic,
            CredentialTypes.RemoteThycotic,
          ],
        },
        {
          type: 'text',
          name: TIME_TO_LIVE,
          label: 'Time To Live (seconds)',
          tabIndex: 1,
          apiField: true,
          supportedTypes: [CredentialTypes.HashiCorp, CredentialTypes.RemoteHashiCorp],
          isRequired: false,
          value: 0,
          vault_type: GCP,
          description:
            "Seconds until the token is expired. Use 0 for default vault's TTL. Value must be between 0 and max TTL configured in vault.",
        },
        {
          type: 'text',
          name: USERNAME_FIELD_IDENTIFIER,
          tabIndex: 1,
          apiField: true,
          supportedTypes: [
            CredentialTypes.HashiCorp,
            CredentialTypes.RemoteHashiCorp,
            CredentialTypes.Thycotic,
            CredentialTypes.RemoteThycotic,
          ],
          isRequired: false,
          vault_type: KEY_VALUE,
          userNamePlainTextOrFieldDescription:
            'To use the username as a field identifier, select "Username Field Identifier" (default). To use a plain text username, select "Username".',
        },
        {
          type: 'text',
          name: PASSWORD_FIELD_IDENTIFIER,
          label: 'Password Field Identifier',
          tabIndex: 1,
          apiField: true,
          supportedTypes: [
            CredentialTypes.HashiCorp,
            CredentialTypes.RemoteHashiCorp,
            CredentialTypes.Thycotic,
            CredentialTypes.RemoteThycotic,
          ],
          isRequired: false,
          vault_type: KEY_VALUE,
          description:
            'The default value for this field is "password", update this field if a different configuration exists',
        },
      ].map(field => ({
        isRequired: true,
        ...field,
      }));

      this.allFormFields = formFields;
      this.testConnectionStatus = undefined;
      this.fetchFunctionRequestParams = undefined;
      this.fetchFunctionFormValues = {};
    };

    this.onApplicationSelectionChanged = () => {
      this.actions = [];
      this.selectedActions = [];

      if (this.selectedApps.length !== 0) {
        const { value: selectedAppId } = this.selectedApps[0];
        return loadActionsData(selectedAppId);
      }
    };

    const updateActionsOnEntityChange = entity => {
      const appId = entity.apps?.[0]?.value;
      return loadActionsData(appId).then(() => {
        this.onEditCredential(entity);
      });
    };

    const loadActionsData = appId => {
      if (!appId) {
        return Promise.resolve();
      }

      return httpService
        .fetch(`tpa/${appId}/actions`, {
          limit: 1000,
          skip: 0,
          sort_by: 'action_name',
          is_asc: true,
        })
        .then(({ data }) => {
          this.actions = data
            .filter(({ execution_type }) => execution_type === 'OneTimeRun')
            .map(({ action_name: label, _id: value }) => ({ label, value }));
        });
    };

    this.setFetchFunction = () => {
      const fetchFunctionRequestParams = this.credential?.fetchFunctionRequestParams;
      this.fetchFunctionFormValues = fetchFunctionRequestParams ?? this.apiCallFormDefaultValue;
    };

    this.setCredentialTypesForm = () => {
      if (this.createModeOn) {
        this.credentialsTypesFormInitialValues = DEFAULT_CREDENTIAL_INITIAL_VALUE;
      } else {
        const { username, password, credentialFields, subType } = this.credential;
        this.credentialsTypesFormInitialValues = {
          subType: subType || CredentialType.BASIC,
          credentialFields: {
            ...(username && { username }),
            ...(password && { password }),
            ...(credentialFields && credentialFields),
          },
        };
      }
    };

    this.updateFormData = () => {
      const type = this.editModeOn
        ? this.getValueFromData(TYPE_FIELD_NAME)
        : this.getValueFromFormData(TYPE_FIELD_NAME);

      const engine_type = this.editModeOn
        ? this.getValueFromData(VAULT_SECRET_ENGINE)
        : this.getValueFromFormData(VAULT_SECRET_ENGINE);

      this.dataInputFields = this.allFormFields.filter(({ supportedTypes }) => supportedTypes.includes(type));

      if (type === CredentialTypes.HashiCorp || type === CredentialTypes.RemoteHashiCorp) {
        this.dataInputFields = this.dataInputFields.filter(({ vault_type }) =>
          vault_type ? vault_type === engine_type : true,
        );
      }

      if (!this.createModeOn) {
        this.dataInputFields.forEach(field => (field.value = this.getValueFromData(field.name)));
        this.setFetchFunction();
      }

      this.dataInputFields
        .filter(({ disableInEditMode }) => disableInEditMode)
        .forEach(field =>
          Object.assign(field, {
            isDisabled: this.editModeOn,
          }),
        );

      this.isCustomMode = type === CredentialTypes.CustomApplicationVault;
      this.isRemoteCustom = type === CredentialTypes.RemoteCustom;

      if (type === CredentialTypes.BigID) {
        this.setCredentialTypesForm();
      }
    };

    this.onTestConnectionClick = () => {
      this.onDataLoading();
      let data = this.getApiDataObject();

      if (data.type === CredentialTypes.CustomApplicationVault) {
        data = { ...data, actions: this.selectedActions, customQuery: this.customQuery };
      }

      if (
        ((data.type === CredentialTypes.HashiCorp || data.type === CredentialTypes.RemoteHashiCorp) &&
          data.secret_engine === EnginesTypes[KEY_VALUE]) ||
        data.type === CredentialTypes.Thycotic ||
        data.type === CredentialTypes.RemoteThycotic
      ) {
        data = { ...data, usernameAsPlainText: this.usernameAsPlainText };
      }

      credentialsService
        .testConnection(data)
        .then(res => {
          this.testConnectionStatus = Boolean(res && res.operationStatus === 'SUCCESS');
          notificationService.success($translate.instant('CREDENTIALS:TEST_CONNECTION:SUCCESS'));
        })
        .catch(err => {
          this.testConnectionStatus = false;
          console.warn(err);
          notificationService.error(`${$translate.instant('CREDENTIALS:TEST_CONNECTION:FAILURE')}. ${err.data.error}`);
        })
        .finally(() => this.onDataLoaded());
    };

    this.getApiDataObject = () => {
      return this.dataInputFields
        .filter(({ apiField, value }) => apiField && value !== undefined)
        .reduce((acc, { name, value }) => Object.assign(acc, { [name]: value }), {});
    };

    this.getValueFromData = field => {
      return this.credential && this.credential[field] !== undefined ? this.credential[field] : undefined;
    };

    this.getValueFromFormData = fieldName => {
      if (!this.allFormFields) return;
      const { value } = this.allFormFields.find(({ name }) => name === fieldName) || {};
      return value;
    };

    this.resetListSelection = credentialId => {
      this.credentials.forEach(credential => {
        credential.selected = credentialId ? credential.credential_id === credentialId : false;
      });
    };

    function sortCredentials({ credential_id: a }, { credential_id: b }) {
      return sortAlphabetically(a, b);
    }

    function filterCredentials(credentials) {
      const filteredCredentials = credentials.filter(({ type }) => {
        switch (type) {
          case CredentialTypes.RemoteCustom:
            return shouldShowRemoteCustom(type);
          case CredentialTypes.RemoteCyberArk:
            return shouldShowRemoteCyberArk(type);
          case CredentialTypes.RemoteHashiCorp:
            return shouldShowRemoteHashiCorp(type);
          case CredentialTypes.RemoteThycotic:
            return shouldShowRemoteThycotic(type);
          case CredentialTypes.Thycotic:
            return shouldShowThycotic(type);
          default:
            return true;
        }
      });
      return filteredCredentials;
    }

    this.isCredentialsTypesFormValid = () => {
      if (!this.credentialsTypesForm || this.credentialsTypesFormErrors) {
        return false;
      }

      const { subType, credentialFields } = this.credentialsTypesForm;
      if (!subType || !credentialFields) {
        return false;
      }

      const credentialsHaveEmptyFields =
        Object.values(credentialFields).filter(credentialFieldValue => !credentialFieldValue).length > 0;
      if (credentialsHaveEmptyFields) {
        return false;
      }

      return true;
    };

    this.setValuesFromCredentialTypesForm = (subType, credentialFields, hasErrors = false) => {
      this.credentialsTypesForm = { subType, credentialFields };
      this.credentialsTypesFormErrors = hasErrors;
      $rootScope.$applyAsync();
    };

    this.hasCredentialsTypesFormChanged = () => {
      if (!this.shouldShowCredentialsTypesForm()) {
        return false;
      }

      if (isEmpty(this.credential)) {
        return !!this.credentialsTypesForm;
      }

      const { subType, credentialFields } = this.credential;

      const credentialIsSavedInOldForm = !subType && !credentialFields;
      if (credentialIsSavedInOldForm) {
        const { username, password } = this.credential;
        const valueToCompare = { ...DEFAULT_CREDENTIAL_INITIAL_VALUE, credentialFields: { username, password } };
        return !isEqual(this.credentialsTypesForm, valueToCompare);
      }

      return !isEqual(this.credentialsTypesForm, { subType, credentialFields });
    };

    this.resetCredentialTypesForm = () => {
      this.credentialsTypesForm = undefined;
      this.setCredentialTypesForm();
    };
  },

  bindings: {
    formOnly: '=',
    formData: '<',
    onDataLoading: '&',
    onDataLoaded: '&',
    onFormClosed: '&',
    onFormSubmitted: '&',
  },
};

app.component('credentials', config);

export const LegacyCredentialsPage = convertToReact('credentialsPage', config);
