import {
  ApiCallConfigForm,
  VaultConnectionAPIConfiguration,
} from '../components/Credentials/CustomCredentialApiCallDialog/customCredentailDialogUtils';
import { changeExtraFieldToNameValueObject } from '../views/ActionCenter/ActionWorkflow/actionWorkflowUtils';
import { ExtraFieldItemData } from '@bigid-ui/components';

const hasBodyError = (payload: string) => {
  try {
    if (!payload) {
      return false;
    }
    JSON.stringify(JSON.parse(payload.toString()), null, '\t');
    return false;
  } catch (err) {
    return true;
  }
};

export const validateExtraField = (extraFields: ExtraFieldItemData[] = []) =>
  extraFields.filter(({ value: { name, value } }) => !name || !value).length === 0;

export const validateAPICallForm = ({
  url,
  type,
  queryParams,
  headers,
  useCertificate,
  clientCert,
  clientKeyCert,
  payload,
}: ApiCallConfigForm): string[] => {
  const errors = [];

  if (!url) {
    errors.push('Please add a url');
  }
  if (!type) {
    errors.push('Please add a type');
  }
  if (useCertificate && (!clientCert || !clientKeyCert)) {
    errors.push('In order to use a certificate, select a client certificate and key');
  }
  if (!validateExtraField(queryParams)) {
    errors.push('Please add a name and a value for each query parameter');
  }
  if (!validateExtraField(headers)) {
    errors.push('Please add a name and a value for each header');
  }
  if (hasBodyError(payload)) {
    errors.push('Please fix errors in the body field');
  }
  return errors;
};

export const convertFormFormatToApiCallAction = ({
  headers,
  queryParams,
  payload,
  ...rest
}: ApiCallConfigForm): VaultConnectionAPIConfiguration => ({
  ...rest,
  ...(headers?.length && { headers: changeExtraFieldToNameValueObject(headers) }),
  ...(queryParams?.length && { queryParams: changeExtraFieldToNameValueObject(queryParams) }),
  ...(payload && { payload: JSON.parse(payload) }),
});
