import React, { FC, useEffect, useState, useContext } from 'react';
import {
  BigidHorizontalBarChart,
  BarChartDataItemType,
  BigidHeading5,
  BigidLoader,
  BigidHeading1,
  BigidHeading6,
} from '@bigid-ui/components';
import { generateDataAid } from '@bigid-ui/utils';
import styled from '@emotion/styled';
import { CaseSidePanelContext } from '../../hooks/CaseSidePanelContext';
import { ActionableInsightsContext } from '../../../hooks/ActionableInsightsContext';
import { useLocalTranslation } from '../../../translations';
import { formatNumberCompact } from '../../../../../utilities/numericDataConverter';
import { fetchSensitivityRatio, SensitivityRatioData } from '../caseReportService';
import { getColorByPriority, SensitivityLevelIndicator } from '../CaseReportComponents/SensitivityLevelIndicator';
import { ReducerActions } from '../../hooks/CaseSidePanelReducer';
import { NoData } from '../CaseReportComponents/caseReportStyles';

const MainContainer = styled.div<{
  isVisible?: boolean;
}>(({ isVisible }) => ({
  display: isVisible ? 'flex' : 'none',
  flexDirection: 'column',
  alignItems: 'flex-start',
  width: 294,
  height: 104,
  position: 'relative',
}));

const RatioLegendText = styled.div({
  display: 'flex',
  alignItems: 'center',
  height: 16,
  fontSize: 10,
  fontWeight: 400,
});

const SingleSensitivityLegend = styled.div({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
});

const LegendWrapper = styled.div({
  display: 'flex',
  flexDirection: 'row',
  width: 270,
  gap: 14,
  marginTop: 6,
  marginLeft: 15,
});

const ColumnWrapper = styled.div({
  display: 'flex',
  flexDirection: 'column',
  gap: 8,
  padding: '0px 32px',
  marginTop: 12,
});

const RowNumWrapper = styled.div({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-start',
  gap: 8,
  height: 45,
  width: 147,
});

const ExplanationWrapper = styled.div({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-start',
  paddingLeft: 32,
});

export const ObjectsSensitivityWidget: FC = () => {
  const { caseSidePanelData, dispatch, isSensitivityWidgetVisible } = useContext(CaseSidePanelContext);
  const { scMapping } = useContext(ActionableInsightsContext);
  const [aggData, setAggData] = useState<SensitivityRatioData[]>([]);
  const [horizontalChartData, setHorizontalChartData] = useState<BarChartDataItemType[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [chartColors, setChartColors] = useState<string[]>([]);
  const { t } = useLocalTranslation('CaseReport');

  useEffect(() => {
    const fetchData = async () => {
      try {
        const { dataSourceName, policyName, numberOfAffectedObjects } = caseSidePanelData;
        const aggregationResult = await fetchSensitivityRatio({
          dataSourceName,
          policyName,
          scMapping,
          numberOfAffectedObjects,
        });
        setAggData(aggregationResult);
        dispatch({
          type: ReducerActions.UPDATE_SENSITIVITY_WIDGET_VISIBILITY,
          payload: { isSensitivityWidgetVisible: aggregationResult?.length > 0 },
        });
        const chartData = aggregationResult.reduce((acc, curr) => {
          return { ...acc, [`${curr['aggItemName']} ${curr['percentage']}%`]: curr['percentage'] };
        }, {});
        setHorizontalChartData([{ ...chartData, category: t('upperWidgetsTitles.objectsSensitivity') }]);
        const currChartColors = aggregationResult.map(curr => {
          return getColorByPriority(scMapping.get(curr['aggItemName']));
        });
        setChartColors(currChartColors);
      } catch ({ message }) {
        if (message) {
          console.error(`An error has occurred: ${message}`);
        }
        dispatch({
          type: ReducerActions.UPDATE_SENSITIVITY_WIDGET_VISIBILITY,
          payload: { isSensitivityWidgetVisible: false },
        });
        dispatch({
          type: ReducerActions.UPDATE_WIDGETS_ERROR_STATUS,
          payload: { hasWidgetsErrors: true },
        });
      } finally {
        setIsLoading(false);
      }
    };
    fetchData().catch(console.error);
  }, [caseSidePanelData, dispatch, scMapping, t]);

  return (
    <MainContainer isVisible={isSensitivityWidgetVisible}>
      {isLoading ? (
        <BigidLoader />
      ) : (
        <>
          {aggData.length === 1 ? (
            <ColumnWrapper data-aid={generateDataAid('ObjectsSingleSensitivityWidget', ['container'])}>
              <RowNumWrapper>
                <SensitivityLevelIndicator priority={scMapping.get(aggData[0].aggItemName)} size={15} />
                <BigidHeading1 fontWeight={600} fontSize={36}>
                  {formatNumberCompact(aggData[0].docCount, 1)}
                </BigidHeading1>
              </RowNumWrapper>
              <ExplanationWrapper>
                <BigidHeading6 fontWeight={400}>{`Objects with ${aggData[0].aggItemName} Sensitivity`}</BigidHeading6>
              </ExplanationWrapper>
            </ColumnWrapper>
          ) : (
            <>
              <BigidHeading5 fontWeight={700} width={294} marginTop={'4px'} marginBottom={'2px'} marginLeft={'14px'}>
                {t('upperWidgetsTitles.objectsSensitivity')}
              </BigidHeading5>
              {aggData.length > 0 ? (
                <>
                  <div
                    style={{ height: 60, width: 230 }}
                    data-aid={generateDataAid('ObjectsSensitivityWidget', ['container'])}
                  >
                    <BigidHorizontalBarChart
                      data={horizontalChartData}
                      barColors={chartColors}
                      enableClickableLegend={false}
                      hideCategoryLabel={true}
                      withLegend={false}
                    />
                  </div>
                  <LegendWrapper>
                    {aggData.map(curr => (
                      <SingleSensitivityLegend key={curr.aggItemName}>
                        <SensitivityLevelIndicator priority={scMapping.get(curr.aggItemName)} size={8} />
                        <RatioLegendText>{`${curr.aggItemName} ${curr.percentage}%`}</RatioLegendText>
                      </SingleSensitivityLegend>
                    ))}
                  </LegendWrapper>
                </>
              ) : (
                <div style={{ marginTop: 15, marginLeft: 14 }}>
                  <NoData width={250}>{t('errorMessages.noDataErr')}</NoData>
                </div>
              )}
            </>
          )}
        </>
      )}
    </MainContainer>
  );
};
