import { useQuery, UseQueryOptions, QueryFunction, UseQueryResult, QueryKey } from 'react-query';
import { getTestConnectionSSEStatus } from '../../../../services/dataSourcesService';
import type { ErrorType } from '../../../../config/request';
import type { AsyncReturnType } from '../types';

export type GetTestConnectionStatusQueryError = ErrorType<unknown>;

export const getTestConnectionStatusQueryKey = (dataSourceId: string): Array<unknown> => [
  'testConnectionStatus',
  dataSourceId,
];

export const useGetTestConnectionStatus = <
  TData = AsyncReturnType<typeof getTestConnectionSSEStatus>,
  TError = GetTestConnectionStatusQueryError,
>(
  dataSourceId: string,
  options?: { query?: UseQueryOptions<AsyncReturnType<typeof getTestConnectionSSEStatus>, TError, TData> },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options || {};

  const queryKey = queryOptions?.queryKey ?? getTestConnectionStatusQueryKey(dataSourceId);
  const queryFn: QueryFunction<AsyncReturnType<typeof getTestConnectionSSEStatus>> = async () =>
    getTestConnectionSSEStatus(dataSourceId);
  const query = useQuery<AsyncReturnType<typeof getTestConnectionSSEStatus>, TError, TData>(queryKey, queryFn, {
    ...queryOptions,
  });

  return {
    queryKey,
    ...query,
  };
};
