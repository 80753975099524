import { BigidHeading6, BigidColors, BigidSwitch, BigidEditableTextArea } from '@bigid-ui/components';
import React, { FC, useCallback, useEffect, useState } from 'react';
import { FormSection, FormSectionInitialStateProps } from '../FormSection/FormSection';
import { useReportTemplateContext } from '../hooks/ReportTemplateContext';
import makeStyles from '@mui/styles/makeStyles';
import { cloneDeep } from 'lodash';
import { ReducerActions } from '../hooks/ReportTemplateReducer';
import { ReportTemplate } from '../Types';
import styled from '@emotion/styled';

const useStyles = makeStyles(() => ({
  heading: {
    color: BigidColors.gray[600],
  },
}));

const FieldDataContainer = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  gap: '30px',
});
const FieldDataLabelContainer = styled('div')({
  width: '170px',
});

interface FieldsDataModel {
  name: string;
  label: string;
  value: string;
  shouldDisplay: boolean;
}

interface fieldDisplays {
  displayRequestId: boolean;
  displayProfileName: boolean;
  displayDisplayName: boolean;
  requestIdLabel: string;
  profileNameLabel: string;
  displayNameLabel: string;
}

const FieldData: FC<{
  customfieldData: FieldsDataModel;
  onToggleChange: (enabled: boolean) => void;
  onLabelChange: (value: string) => void;
}> = ({ customfieldData, onToggleChange, onLabelChange }) => {
  const classes = useStyles({});
  return (
    <FieldDataContainer>
      <BigidSwitch
        dataAid={`bigid-form-field-User-Display-Information-${customfieldData.name}`}
        checked={customfieldData.shouldDisplay}
        onChange={(_, checked) => {
          if (checked !== customfieldData.shouldDisplay) {
            customfieldData.shouldDisplay = checked;
            onToggleChange(checked);
          }
        }}
      />
      <FieldDataLabelContainer>
        <BigidEditableTextArea
          value={customfieldData.label}
          onSubmit={value => {
            customfieldData.label = value;
            onLabelChange(value);
          }}
          isInlineEdit
          enlargedControls={false}
          isEditPencilAlwaysVisible
          controlsPlacement={['right']}
          key={`${customfieldData.name}_${0}`}
          dataAid={`bigid-form-field-${customfieldData.name}`}
        />
      </FieldDataLabelContainer>
      <BigidHeading6 gutterBottom className={classes.heading}>
        {customfieldData.value}
      </BigidHeading6>
    </FieldDataContainer>
  );
};

const FieldsData: FC<{
  fieldDisplays: fieldDisplays;
  onChange: (fieldDisplays: fieldDisplays) => void;
}> = ({ fieldDisplays, onChange }) => {
  return (
    <>
      <FieldData
        customfieldData={{
          name: 'requestId',
          label: fieldDisplays.requestIdLabel,
          value: 'eg. 123456789',
          shouldDisplay: fieldDisplays.displayRequestId,
        }}
        onLabelChange={value => {
          onChange({ ...fieldDisplays, requestIdLabel: value });
        }}
        onToggleChange={(enabled: boolean) => {
          onChange({ ...fieldDisplays, displayRequestId: enabled });
        }}
      />
      <FieldData
        customfieldData={{
          name: 'profileName',
          label: fieldDisplays.profileNameLabel,
          value: 'eg. Customers',
          shouldDisplay: fieldDisplays.displayProfileName,
        }}
        onLabelChange={value => {
          onChange({ ...fieldDisplays, profileNameLabel: value });
        }}
        onToggleChange={(enabled: boolean) => {
          onChange({ ...fieldDisplays, displayProfileName: enabled });
        }}
      />
      <FieldData
        customfieldData={{
          name: 'displayName',
          label: fieldDisplays.displayNameLabel,
          value: 'eg. Marty McFly',
          shouldDisplay: fieldDisplays.displayDisplayName,
        }}
        onLabelChange={value => {
          onChange({ ...fieldDisplays, displayNameLabel: value });
        }}
        onToggleChange={(enabled: boolean) => {
          onChange({ ...fieldDisplays, displayDisplayName: enabled });
        }}
      />
    </>
  );
};

export const UserDisplayInformationSection: FC = () => {
  const [items, setItems] = useState<fieldDisplays>();
  const [formSectionInitProps, setFormSectionInitProps] = useState<FormSectionInitialStateProps>();
  const sectionName = 'userDisplayInfo';

  const { initialTemplateData, dispatch } = useReportTemplateContext();

  useEffect(() => {
    const {
      userDisplayInfo: { ...initialValues },
    } = initialTemplateData;

    setItems({
      displayRequestId: initialValues.displayRequestId ?? true,
      displayProfileName: initialValues.displayProfileName ?? true,
      displayDisplayName: initialValues.displayDisplayName ?? true,

      requestIdLabel: initialValues.requestIdLabel ?? 'Request ID',
      profileNameLabel: initialValues.profileNameLabel ?? 'Profile Name',
      displayNameLabel: initialValues.displayNameLabel ?? 'Display name',
    });

    initialValues.isEnabled = !(
      !(initialValues.displayRequestId ?? true) &&
      !(initialValues.displayProfileName ?? true) &&
      !(initialValues.displayDisplayName ?? true)
    );

    setFormSectionInitProps({ initialValues });
  }, [initialTemplateData]);

  const updateSection = useCallback(
    (items: fieldDisplays, isEnabled: boolean) => {
      const data = {
        [sectionName]: {
          isEnabled,
          displayRequestId: items.displayRequestId,
          displayProfileName: items.displayProfileName,
          displayDisplayName: items.displayDisplayName,
          requestIdLabel: items.requestIdLabel,
          profileNameLabel: items.profileNameLabel,
          displayNameLabel: items.displayNameLabel,
        },
      } as Partial<ReportTemplate>;
      dispatch({
        type: ReducerActions.UPDATE_SECTION,
        payload: {
          data,
          sectionName,
        },
      });
    },
    [dispatch],
  );

  const fieldsDataChanged = useCallback(
    (fieldDisplays: fieldDisplays) => {
      const formSectionInitPropsClone = cloneDeep(formSectionInitProps);

      const values = {
        ...formSectionInitPropsClone.initialValues,
        displayRequestId: fieldDisplays.displayRequestId,
        displayProfileName: fieldDisplays.displayProfileName,
        displayDisplayName: fieldDisplays.displayDisplayName,
        requestIdLabel: fieldDisplays.requestIdLabel,
        profileNameLabel: fieldDisplays.profileNameLabel,
        displayNameLabel: fieldDisplays.displayNameLabel,
        isEnabled: !(
          !fieldDisplays.displayRequestId &&
          !fieldDisplays.displayProfileName &&
          !fieldDisplays.displayDisplayName
        ),
      };

      setItems(fieldDisplays);
      setFormSectionInitProps({ initialValues: values });
      updateSection(fieldDisplays, formSectionInitProps?.initialValues?.isEnabled);
    },
    [formSectionInitProps, updateSection],
  );

  const isEnabledChangedHandler = useCallback(
    (enabled: boolean) => {
      const formSectionInitPropsClone = cloneDeep(formSectionInitProps);

      const values = {
        ...formSectionInitPropsClone.initialValues,
        displayRequestId: enabled,
        displayProfileName: enabled,
        displayDisplayName: enabled,
        isEnabled: enabled,
      };

      setFormSectionInitProps({ initialValues: values });

      const newItems = {
        ...items,
        displayDisplayName: enabled,
        displayProfileName: enabled,
        displayRequestId: enabled,
      };
      setItems(newItems);
      updateSection(newItems, enabled);
    },
    [formSectionInitProps, items, updateSection],
  );

  return (
    <>
      {formSectionInitProps && (
        <FormSection
          sectionName={sectionName}
          {...formSectionInitProps}
          isEnabledChanged={isEnabledChangedHandler}
          customFieldsToRender={() => <FieldsData fieldDisplays={items} onChange={fieldsDataChanged} />}
        />
      )}
    </>
  );
};
