import { CONFIG } from '../../../../config/common';
import { $state } from '../../../services/angularServices';
import { DATA_SOURCES_INIT_VIEWS } from '../../DataSources/DataSources';
import { startAutoDiscoveryApp, getAutoDiscoveryAppInfo } from '../../../services/autoDiscoveryService';
import { getAmountOfDiscoveredDs, CloudProvider } from '../../AutoDiscoveryWizard/autoDiscoveryWizardServices';
import { notificationService } from '../../../services/notificationService';
import { getApplicationPreference } from '../../../services/appPreferencesService';
import { CustomAppStatus } from '../../CustomApp/views/ActivityLog/ActivityLog';
import { resetFmsdProgress } from '../../Fmsd/fmsdServices';

const triggerAutoDiscoveryApp = async () => {
  if (getApplicationPreference('USE_SAAS')) {
    $state.go(`dataSourcesInit`, {
      path: DATA_SOURCES_INIT_VIEWS.smallIdInit.path,
    });
  } else {
    try {
      const tpa_id = await startAutoDiscoveryApp(CloudProvider.AWS);
      if (tpa_id) {
        $state.go(`dataSourcesInit`, {
          path: DATA_SOURCES_INIT_VIEWS.autoDiscovery.path,
        });
      }
    } catch (e) {
      notificationService.error(e.message);
    }
  }
};

const checkForDiscoveredDs = async () => {
  Promise.all([getAutoDiscoveryAppInfo(CloudProvider.AWS), getAmountOfDiscoveredDs(CloudProvider.AWS)])
    .then(([autoDiscoveryAppInfo, amountOfDiscoveredDs]) => {
      $state.go(`dataSourcesInit`, {
        path:
          autoDiscoveryAppInfo.lastRunStatus === CustomAppStatus.IN_PROGRESS || amountOfDiscoveredDs
            ? DATA_SOURCES_INIT_VIEWS.autoDiscovery.path
            : DATA_SOURCES_INIT_VIEWS.smallIdInit.path,
      });
    })
    .catch(e => {
      notificationService.error(e.message);
    });
};

const resetFmsdAndNavigate = async () => {
  await resetFmsdProgress();
  $state.go(CONFIG.states.FMSD);
};

export const enrichSmallIdShortcuts = (shortcuts: any) => {
  if (shortcuts[0] && Array.isArray(shortcuts[0].children)) {
    const smallIdGettingStartedShortcuts = [];
    if (getApplicationPreference('IS_SMALLID_FOR_DEV')) {
      smallIdGettingStartedShortcuts.push({
        title: 'Materials for developers',
        onClick: () => {
          $state.go(CONFIG.states.SMALLID_FOR_DEVELOPERS);
        },
        state: CONFIG.states.SMALLID_FOR_DEVELOPERS,
      });
    } else if (getApplicationPreference('FMSD_ENABLED')) {
      smallIdGettingStartedShortcuts.push(
        {
          title: 'Run Data Discovery Wizard',
          onClick: () => {
            $state.go(CONFIG.states.FMSD);
          },
          state: CONFIG.states.DATA_SOURCE_INIT,
        },
        {
          title: 'Restart Data Discovery Wizard',
          onClick: () => {
            resetFmsdAndNavigate();
          },
          state: CONFIG.states.DATA_SOURCE_INIT,
        },
      );
    } else {
      smallIdGettingStartedShortcuts.push(
        {
          title: 'Run AWS auto-discovery and scan wizard',
          onClick: () => {
            triggerAutoDiscoveryApp();
          },
          state: CONFIG.states.DATA_SOURCE_INIT,
        },
        {
          title: 'Scan AWS auto-discovered data sources',
          onClick: () => {
            checkForDiscoveredDs();
          },
          state: CONFIG.states.DATA_SOURCE_INIT,
        },
      );
    }
    shortcuts[0].children = [...smallIdGettingStartedShortcuts, ...shortcuts[0].children];
  }

  return shortcuts;
};
