import './bigidButton.scss';
import template from './bigidButton.html';
import { module } from 'angular';
const app = module('app');

/**
 *  Reusable generic button
 * For more info see bindings options below */
app.component('bigidButton', {
  template,
  transclude: true,
  controller: function () {
    this.getBtnClasses = () => {
      return [
        this.btnBright ? 'bigid-btn--primary-bright' : 'bigid-btn--primary-dark',
        this.btnClasses,
        { 'bigid-btn--image': Boolean(this.btnIconSrc || this.btnIconFa) },
      ];
    };
  },
  /**
   * Please use prefix in this component bindings,
   * to avoid attribute names collisions with Native and Popular UI frameworks such as ui-bootstrap etc...
   */
  bindings: {
    btnBright: '<',
    /** {string} this value will be used as the class attribute of the icon element */
    btnIconFa: '<',
    /** {string} img[src] path */
    btnIconSrc: '<',
    btnIsDisabled: '<',
    btnClasses: '<',
    btnOnClick: '&',
  },
});
