import { useEffect, useState } from 'react';
import { $location, $rootScope } from 'ngimport';
import { $state } from '../../services/angularServices';

export const useSelectedTabIndex = (ACI_TABS: string[]) => {
  const [selectedTabIndex, setSelectedTabIndex] = useState<number>(ACI_TABS.indexOf($state.params.selectedTab) || 0);

  useEffect(() => {
    // Set up a listener for location changes
    const deregister = $rootScope.$on('$locationChangeSuccess', () => {
      const urlString = $location.path();
      const parts = urlString.split('/');
      const selectedTab = parts[parts.length - 1];
      if (ACI_TABS.includes(selectedTab)) {
        setSelectedTabIndex(ACI_TABS.indexOf(selectedTab));
      }
    });

    // Clean up the listener when the component is unmounted
    return () => {
      deregister();
    };
  }, [ACI_TABS]);

  return { selectedTabIndex, setSelectedTabIndex };
};
