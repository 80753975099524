import './applicationSetup.component.scss';
import template from './applicationSetup.component.html';
import angular, { module } from 'angular';
import { isPermitted } from '../../react/services/userPermissionsService';
import { APPLICATION_SETUP_PERMISSIONS } from '@bigid/permissions';
const app = module('app');
import './addAnotherButton';
import { sessionStorageService } from '../../common/services/sessionStorageService';
import './BigidDeleteIcon';
import { getApplicationPreference } from '../../react/services/appPreferencesService';
import { convertToReact } from '../../common/services/convertToReact';

const config = {
  template,
  controller: function (
    notificationService,
    $translate,
    applicationSetupService,
    $document,
    DeleteConfirmation,
    locationService,
    dataSourceConnectionsService,
    $timeout,
  ) {
    'ngInject';

    const APP_DATA_LIST_ID = 'applicationsDataList';
    const APP_DATA_CONTAINER_BODY_ID = 'applicationsContentBody';
    const APP_DATA_LIST_ITEM_ID = 'app';

    const TRANSLATION_REQUIRED = ['PLACEHOLDERS:NONE'];

    const SECURITY_TIER_OPTIONS = [
      { id: '1', name: '1' },
      { id: '2', name: '2' },
      { id: '3', name: '3' },
      { id: '4', name: '4' },
      { id: '5', name: '5' },
    ];

    const DISPOSITION_OPTIONS = [
      { id: '1', name: 'Collection' },
      { id: '2', name: 'Transformation' },
      { id: '3', name: 'Retention' },
      { id: '4', name: 'Transfer' },
      { id: '5', name: 'Disposal' },
    ];

    const dataListContainer = $document[0].getElementById(APP_DATA_LIST_ID);
    const contentBodyContainer = $document[0].getElementById(APP_DATA_CONTAINER_BODY_ID);

    let isFormDirty = false;
    this.applicationsDataSourceListEnabled = getApplicationPreference('APPLICATIONS_DATA_SOURCE_LIST_ENABLED');
    this.applications = [];
    this.application = {};
    this.dataSources = [];
    this.dataSourcesNames = [];
    this.userQuery = '';
    this.editModeOn = false;
    this.createModeOn = false;
    this.applicationName = '';
    this.applicationForm = {};
    this.countries = [];
    this.securityTierOptions = SECURITY_TIER_OPTIONS;
    this.dispositionOptions = DISPOSITION_OPTIONS;

    /* API calls --start */

    const getCountries = () => locationService.getCountriesList();

    const getApplications = () => applicationSetupService.getApplicationItems();

    const getDataSources = () => dataSourceConnectionsService.getAllSystems();

    const fetchApplications = (scrollTo = '', entityToProceed = null) => {
      this.onDataLoading();

      applicationSetupService.getApplicationItems().then(
        result => {
          this.applications = result.applications.filter(app => typeof app.name != 'undefined').sort(alphaSort);

          this.onDataLoaded();

          if (entityToProceed !== null) {
            if (Object.keys(entityToProceed).length === 0 && entityToProceed.constructor === Object) {
              onCreateApplication();
            } else {
              onEditApplication(entityToProceed);
            }
          } else {
            if (this.editModeOn) {
              resetListSelection();
              setListSelection(this.applicationName);

              $timeout(() => {
                const index = this.applications.findIndex(app => app.name === this.applicationName),
                  dataListItem = $document[0].getElementById(`${APP_DATA_LIST_ITEM_ID}-${index}`);

                if (dataListItem !== null && index !== -1) {
                  dataListContainer.scrollTop = index * dataListItem.offsetHeight;
                }
              }, 0);
            }
          }

          $timeout(() => {
            if (dataListContainer === null) return;

            switch (scrollTo) {
              case 'top':
                dataListContainer.scrollTop = 0;
                break;
              case 'bottom':
                dataListContainer.scrollTop = dataListContainer.scrollHeight;
                break;
              default:
                if (entityToProceed !== null) {
                  if (Object.keys(entityToProceed).length === 0 && entityToProceed.constructor === Object) {
                    dataListContainer.scrollTop = 0;
                  } else {
                    const index = this.applications.findIndex(app => app._id === entityToProceed._id),
                      dataListItem = $document[0].getElementById(`${APP_DATA_LIST_ITEM_ID}-${index}`);

                    if (dataListItem !== null && index !== -1) {
                      dataListContainer.scrollTop = index * dataListItem.offsetHeight;
                    }
                  }
                }
            }
          }, 0);
        },
        () => {
          this.onDataLoaded();

          $translate('API:MESSAGE:COMMON_ERROR').then(translation => {
            notificationService.error(translation);
          });
        },
      );
    };

    const createApplication = (data, entityToProceed = null) => {
      applicationSetupService.createApplicationItem(data).then(
        result => {
          $translate('API:MESSAGE:COMMON_POST_SUCCESS').then(translation => {
            notificationService.success(translation);
          });

          this.editModeOn = true;
          this.createModeOn = false;

          this.applicationName = angular.copy(this.application.name);

          isFormDirty = false;

          this.applicationForm.$submitted = false;

          if (contentBodyContainer !== null) contentBodyContainer.scrollTop = 0;

          fetchApplications('', entityToProceed);
        },
        () => {
          $translate('API:MESSAGE:COMMON_ERROR').then(translation => {
            notificationService.error(translation);
          });
        },
      );
    };

    const updateApplication = (id, data, entityToProceed = null) => {
      applicationSetupService.updateApplicationItem(id, data).then(
        result => {
          $translate('API:MESSAGE:COMMON_PUT_SUCCESS').then(translation => {
            notificationService.success(translation);
          });

          this.applicationName = angular.copy(this.application.name);

          if (contentBodyContainer !== null) contentBodyContainer.scrollTop = 0;

          isFormDirty = false;

          this.applicationForm.$submitted = false;

          fetchApplications('', entityToProceed);
        },
        () => {
          $translate('API:MESSAGE:COMMON_ERROR').then(translation => {
            notificationService.error(translation);
          });
        },
      );
    };

    const deleteApplication = id => {
      applicationSetupService.deleteApplicationItem(id).then(
        result => {
          $translate('API:MESSAGE:COMMON_DELETE_SUCCESS').then(translation => {
            notificationService.success(translation);
          });

          this.editModeOn = false;
          this.createModeOn = false;

          this.application = {};

          fetchApplications('top');
        },
        () => {
          $translate('API:MESSAGE:COMMON_ERROR').then(translation => {
            notificationService.error(translation);
          });
        },
      );
    };

    /* API calls --end */

    /* component methods --start */

    const initializeData = async callback => {
      try {
        const applicationsResponse = await getApplications();
        const { applications } = applicationsResponse;

        const dataSourcesResponse = await getDataSources();
        const dataSources = dataSourcesResponse.systems;

        const countries = await getCountries();

        return callback({ applications, dataSources, countries });
      } catch (err) {
        $translate('API:MESSAGE:COMMON_ERROR').then(translation => {
          notificationService.error(translation);
        });

        return callback({ applications: [], dataSources: [], countries: [] });
      }
    };

    const onUserLeavesForm = async (resolveCallback, rejectCallback) => {
      const closeButtonText = await $translate('BUTTONS:NO');
      const actionButtonText = await $translate('BUTTONS:YES');
      const headerText = await $translate('APP:COMMON:LEAVE_CONFIRMATION_HEADER');
      const bodyText = await $translate('APP:COMMON:LEAVE_CONFIRMATION_TEXT');

      const modalOptions = { closeButtonText, actionButtonText, headerText, bodyText };

      DeleteConfirmation.showModal({}, modalOptions).then(resolveCallback, rejectCallback);
    };

    const onCreateApplication = () => {
      resetListSelection();
      this.application = {};

      this.application.securityTierName = SECURITY_TIER_OPTIONS[0]['name'];
      this.application.dataMappingClassifierName = DISPOSITION_OPTIONS[0]['name'];
      this.application.locationName = '';

      // NOTE: for backward compatibility
      this.application.app_account_name = '';
      this.application.location = '';
      this.application.owner_email = '';
      this.application.owner_name = '';
      this.application.owner_phone = '';
      this.application.security_tier = SECURITY_TIER_OPTIONS[0]['id'];
      this.application.data_mapping_classifier = DISPOSITION_OPTIONS[0]['id'];
      this.application.source_ip = '';
      if (this.applicationsDataSourceListEnabled) {
        this.application.target_source_list = [{ source: 'None' }];
      } else {
        this.application.target_data_source = '';
      }

      this.application.filter = '';

      this.createModeOn = true;
      this.editModeOn = false;

      this.applicationName = '';

      resetFormState();
    };

    this.onAddDataSource = () => {
      this.application.target_source_list.push({ source: 'None' });
    };
    this.onDeleteDataSource = index => {
      this.application.target_source_list.splice(index, 1);
    };

    const onEditApplication = application => {
      resetListSelection();
      setListSelection(application.name);

      this.application = angular.copy(application);
      this.applicationName = angular.copy(this.application.name);

      const securityTier = this.securityTierOptions.find(option => option.id === this.application.security_tier);

      if (typeof securityTier == 'undefined') {
        this.application.securityTierName = '';
      } else {
        this.application.securityTierName = securityTier.name;
      }

      const dataMappingClassifier = this.dispositionOptions.find(
        option => option.id === this.application.data_mapping_classifier,
      );

      if (typeof dataMappingClassifier == 'undefined') {
        this.application.dataMappingClassifierName = '';
      } else {
        this.application.dataMappingClassifierName = dataMappingClassifier.name;
      }

      const location = this.countries.find(
        location => location.name === this.application.location || location.displayName === this.application.location,
      );

      if (typeof location == 'undefined') {
        this.application.locationName = '';
      } else {
        this.application.locationName = location.name;
      }

      this.editModeOn = true;
      this.createModeOn = false;

      resetFormState();
    };

    const onSubmitApplication = (entityToProceed = null) => {
      if (!this.applicationForm.$valid) return;

      const payload = angular.copy(this.application);

      if (typeof payload.selected != 'undefined') delete payload.selected;

      if (typeof payload.dataMappingClassifierName != 'undefined') delete payload.dataMappingClassifierName;

      if (typeof payload.securityTierName != 'undefined') delete payload.securityTierName;

      if (typeof payload.locationName != 'undefined') delete payload.locationName;

      switch (true) {
        case this.createModeOn:
          createApplication(payload, entityToProceed);
          break;
        case this.editModeOn:
          updateApplication(this.applicationName, payload, entityToProceed);
          break;
      }
    };

    const resetFormState = () => {
      if (
        typeof this.applicationForm != 'undefined' &&
        typeof this.applicationForm.$setPristine == 'function' &&
        typeof this.applicationForm.$setUntouched == 'function'
      ) {
        this.applicationForm.$setPristine();
        this.applicationForm.$setUntouched();
      } else {
        this.applicationForm = {};
      }

      this.applicationForm.$submitted = false;

      isFormDirty = false;
    };

    const resetListSelection = (except = '') => {
      for (let i = 0, len = this.applications.length; i < len; i++) {
        if (this.applications[i].name === except) {
          continue;
        }
        this.applications[i]['selected'] = false;
      }
    };

    const setListSelection = name => {
      for (let i = 0, len = this.applications.length; i < len; i++) {
        if (this.applications[i].name === name) {
          this.applications[i]['selected'] = true;
          break;
        }
      }
    };

    const alphaSort = (a, b) => {
      const aName = a.name.toLowerCase(),
        bName = b.name.toLowerCase();

      if (aName < bName) {
        return -1;
      } else if (aName > bName) {
        return 1;
      } else {
        return 0;
      }
    };

    /* component methods --end */

    this.$onInit = () => {
      this.isCreatePermitted = isPermitted(APPLICATION_SETUP_PERMISSIONS.CREATE.name);
      this.isEditPermitted = isPermitted(APPLICATION_SETUP_PERMISSIONS.EDIT.name);
      this.isDeletePermitted = isPermitted(APPLICATION_SETUP_PERMISSIONS.DELETE.name);

      $translate(TRANSLATION_REQUIRED).then(translations => {
        this.onDataLoading();

        initializeData(result => {
          const { applications, dataSources, countries } = result;

          this.applications = applications.filter(app => typeof app.name != 'undefined').sort(alphaSort);
          this.countries = countries;
          this.dataSources = dataSources;
          this.dataSourcesNames = [
            ...this.dataSources.map(dataSource => dataSource.name),
            translations['PLACEHOLDERS:NONE'],
          ];

          if (this.applications.length > 0) {
            onEditApplication(this.applications[0]);
          }

          this.onDataLoaded();
        });
      });
    };

    this.applicationsFilter = criteria => {
      const _criteria = criteria.toLowerCase();

      return application =>
        typeof application.name != 'undefined' && application.name.toLowerCase().indexOf(_criteria) != -1
          ? true
          : false;
    };

    this.onFormChanged = () => {
      isFormDirty = true;
    };

    this.onEdit = application => {
      if (this.application.name !== application.name) {
        if (this.isEditPermitted && isFormDirty) {
          onUserLeavesForm(
            () => {
              this.applicationForm.$submitted = true;

              onSubmitApplication(application);
            },
            () => {
              onEditApplication(application);
            },
          );
        } else {
          onEditApplication(application);
        }
      }
    };

    this.onCreate = () => {
      if (isFormDirty) {
        onUserLeavesForm(
          () => {
            this.applicationForm.$submitted = true;

            onSubmitApplication({});
          },
          () => {
            onCreateApplication();
          },
        );
      } else {
        onCreateApplication();
      }
    };

    this.onDelete = async application => {
      const closeButtonText = await $translate('BUTTONS:CLOSE');
      const actionButtonText = await $translate('BUTTONS:DELETE');
      const headerText = await $translate('APP:COMMON:DELETE_CONFIRMATION_HEADER', { entityName: application.name });
      const bodyText = await $translate('APP:COMMON:DELETE_CONFIRMATION_TEXT', { entityName: application.name });

      const modalOptions = { closeButtonText, actionButtonText, headerText, bodyText };

      DeleteConfirmation.showModal({}, modalOptions).then(() => deleteApplication(application.name));
    };

    this.onSubmit = () => {
      onSubmitApplication();
    };

    this.onCancel = () => {
      this.application = {};

      this.applicationName = '';

      resetFormState();
    };

    this.onSecurityTierSelected = item => {
      this.application.security_tier = item.id;
    };

    this.onDataFlowsClassifierSelected = item => {
      this.application.data_mapping_classifier = item.id;
    };

    this.onDataCountrySelected = item => {
      this.application.location = item.name;
    };
  },
  bindings: {
    onDataLoading: '&',
    onDataLoaded: '&',
  },
};

app.component('applicationSetup', config);

export const LegacyApplicationSetup = convertToReact('applicationSetupPage', config);
