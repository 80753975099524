import React, { useEffect, useCallback, useState, useMemo } from 'react';
import {
  BigidErrorBoundary,
  BigidPageLayout,
  BigidPaper,
  BigidTabs,
  Breadcrumb,
  SecondaryButton,
  BigidStatusButton,
  BigidStatusButtonItem,
  entityEventsEmitter,
  EntityEvents,
  BigidStatusBadge,
  BigidStatusBadgeSize,
  BigidBreadcrumbsProps,
} from '@bigid-ui/components';
import { $state } from '../../../react/services/angularServices';
import { CONFIG } from '../../../config/common';
import { notificationService } from '../../services/notificationService';
import { pageHeaderService } from '../../../common/services/pageHeaderService';
import { updateLegalEntity, getLevels, getHierarchyById } from './LegalEntitiesService';
import {
  LegalEntitiesInterface,
  LegalEntitiesStatus,
  LegalEntityPageTabsPath,
  LegalEntityPageTabs,
  LegalEntityLevel,
  HierarchyType,
} from './LegalEntitiesTypes';
import { TabsWrapper, ContentWrapper } from './LegalEntitiesCommonStyles';
import { LegalEntityOverview } from './LegalEntityOverview';
import { LegalEntitiesActivityLogs } from './LegalEntitiesActivityLogs';
import { LegalEntityContextProvider, useLegalEntityContext } from './LegalEntitiesContext';
import { LegalEntityEventEmitter, LegalEntityEvents } from './utils/EntityEvents';
import { useLegalEntitiesEditDialog, LegalEntitiesEditDialog } from './LegalEntitiesEditDialog';
import { isPermitted } from '../../services/userPermissionsService';
import { LEGAL_ENTITIES_PERMISSIONS } from '@bigid/permissions';
import { statusBadgeMapper } from './utils/common';
import { LegalEntitiesTrackingEvents, trackLegalEntities } from './utils/analytics';

const TAB_NAMES = Object.values(LegalEntityPageTabs);
const TAB_PATHS = Object.values(LegalEntityPageTabsPath);

const getActiveTabIndex = (currentTab: LegalEntityPageTabsPath) => TAB_PATHS.indexOf(currentTab) || 0;

const EntityPageLayout = () => {
  const { legalEntity, id, update, tab } = useLegalEntityContext();
  const [activeTabIndex, setActiveTabIndex] = useState<number>(getActiveTabIndex(tab));
  const [breadcrumbs, setBreadcrumbs] = useState<BigidBreadcrumbsProps>();
  const { openLegalEntitiesEditDialog, legalEntitiesEditDialogProps } = useLegalEntitiesEditDialog();

  useEffect(() => {
    pageHeaderService.setIsHidden(true);
    return () => {
      pageHeaderService.setIsHidden(false);
    };
  }, []);

  const getSubEntityLevel = useCallback(async (levelId: string) => {
    try {
      const { legalEntitiesLevel } = await getLevels();
      const entityIndex: number = legalEntitiesLevel?.findIndex((level: LegalEntityLevel) => level.id === levelId);
      const subLevel =
        entityIndex >= 0 && (entityIndex + 1 < legalEntitiesLevel?.length ? legalEntitiesLevel?.[entityIndex + 1] : {});
      return subLevel as LegalEntityLevel;
    } catch (err) {
      notificationService.error(`Failed to fetch levels`);
      console.error(`Failed to fetch levels: ${JSON.stringify(err?.response)}`);
      return {} as LegalEntityLevel;
    }
  }, []);

  useEffect(() => {
    if (legalEntity) {
      entityPageBreadcrumbs(legalEntity).then(value =>
        setBreadcrumbs({
          itemsAfterCollapse: 2,
          itemsBeforeCollapse: 2,
          breadcrumbs: value,
          maxItems: 1,
        }),
      );
    }
  }, [legalEntity]);

  useEffect(() => {
    const eventCallback = async () => {
      const level = await getSubEntityLevel(legalEntity.level.id);
      if (Object.keys(level).length) {
        await openLegalEntitiesEditDialog(true, {
          level: {
            id: level?.id,
            level: level?.level,
            name: level?.name,
          },
          parentEntity: {
            id: legalEntity.id,
            name: legalEntity.name,
          },
          mainContactEmail: legalEntity?.mainContactEmail,
          mainContactName: legalEntity?.mainContactName,
        } as LegalEntitiesInterface);
        update();
      }
    };
    LegalEntityEventEmitter.addEventListener(LegalEntityEvents.OPEN_EDIT_LEGAL_ENTITY_DIALOG, eventCallback);

    return () => {
      LegalEntityEventEmitter.removeEventListener(LegalEntityEvents.OPEN_EDIT_LEGAL_ENTITY_DIALOG, eventCallback);
    };
  }, [openLegalEntitiesEditDialog, update, legalEntity, getSubEntityLevel]);

  const onStatusChange = useCallback(
    async (id: string, updatedStatus: BigidStatusButtonItem[]) => {
      try {
        const changedValue = updatedStatus.filter(item => item.isSelected)?.[0].label;
        const status =
          changedValue === 'Under Review' ? 'UnderReview' : (changedValue as LegalEntitiesInterface['status']);
        await updateLegalEntity(id, {
          status: status,
        });
        entityEventsEmitter.emit(EntityEvents.RELOAD);
        update();
        trackLegalEntities(trackingEventsMapper[status]);
      } catch (err) {
        notificationService.error(`Failed to update Legal Entity status`);
        console.error(`Failed to update Legal Entity status: ${JSON.stringify(err?.response)}`);
      }
    },
    [update],
  );

  const onTabChange = (tabIndex: number) => {
    $state.go(
      CONFIG.states.LEGAL_ENTITIES_OVERVIEW,
      { ...$state.params, tab: TAB_PATHS[tabIndex] },
      { reload: false, notify: false, inherit: true },
    );
    setActiveTabIndex(tabIndex);
  };

  const pageLayoutActionDecider = useCallback(
    (activeTab: LegalEntityPageTabsPath) => {
      const actionArray = [
        isPermitted(LEGAL_ENTITIES_PERMISSIONS.LEGAL_ENTITIES_EDIT.name) ? (
          <BigidStatusButton
            key="1"
            statusItems={getStatusItems(legalEntity?.status)}
            onStatusChange={updatedItem => onStatusChange(id, updatedItem)}
          />
        ) : (
          <BigidStatusBadge
            label={legalEntity?.status === 'UnderReview' ? 'Under Review' : legalEntity?.status}
            size={BigidStatusBadgeSize.LARGE}
            type={statusBadgeMapper[legalEntity?.status || 'Draft']}
          />
        ),
      ];
      switch (activeTab) {
        case LegalEntityPageTabsPath.OVERVIEW:
          return actionArray.concat(
            isPermitted(LEGAL_ENTITIES_PERMISSIONS.LEGAL_ENTITIES_EDIT.name) && (
              <SecondaryButton
                key="2"
                onClick={async () => {
                  trackLegalEntities(LegalEntitiesTrackingEvents.EDIT_LEGAL_ENTITY_ACTION);
                  await openLegalEntitiesEditDialog(false, legalEntity);
                  update();
                }}
                size="medium"
              >
                {'Edit Details'}
              </SecondaryButton>
            ),
          );
        default:
          return [];
      }
    },
    [legalEntity, id, openLegalEntitiesEditDialog, onStatusChange],
  );
  const Component = getComponentBasedOnTab(TAB_PATHS[activeTabIndex]);

  return (
    <BigidPageLayout
      dataAid="legalEntityPageWithTabs"
      actions={pageLayoutActionDecider(TAB_PATHS[activeTabIndex])}
      title={legalEntity?.name}
      showHeaderBackground={false}
      pageBackgroundColor="transparent"
      breadcrumbs={breadcrumbs}
    >
      <BigidPaper>
        <TabsWrapper>
          <BigidTabs
            onChange={onTabChange}
            orientation="horizontal"
            selectedIndex={activeTabIndex}
            tabs={TAB_NAMES.map(tab => ({
              label: tab,
            }))}
            variant="standard"
          />
        </TabsWrapper>
        <ContentWrapper>
          <LegalEntitiesEditDialog {...legalEntitiesEditDialogProps} />
          <Component />
        </ContentWrapper>
      </BigidPaper>
    </BigidPageLayout>
  );
};

export const LegalEntityPageWithTabs = () => {
  return (
    <BigidErrorBoundary>
      <LegalEntityContextProvider>
        <EntityPageLayout />
      </LegalEntityContextProvider>
    </BigidErrorBoundary>
  );
};

const entityPageBreadcrumbs = async (entityData: LegalEntitiesInterface) => {
  const breadcrumbs: Breadcrumb[] = [
    {
      label: 'Legal Entities',
      onClick: () => {
        $state.go(CONFIG.states.LEGAL_ENTITIES);
      },
    },
  ];
  const hierarchy = await getHierarchy(entityData?.id);
  if (hierarchy) {
    hierarchy.forEach((value, index) => {
      index === hierarchy.length - 1
        ? breadcrumbs.push({
            label: value.name,
          })
        : breadcrumbs.push({
            label: value.name,
            onClick: () =>
              $state.go(
                CONFIG.states.LEGAL_ENTITIES_OVERVIEW,
                { entityId: value.id, tab: 'overview' },
                { reload: true, notify: false, inherit: true },
              ),
          });
    });
  }
  return breadcrumbs;
};
const statusMapper = {
  [LegalEntitiesStatus.ACTIVE]: 'success',
  [LegalEntitiesStatus.INACTIVE]: 'error',
  [LegalEntitiesStatus.DRAFT]: 'pending',
  [LegalEntitiesStatus.UNDER_REVIEW]: 'general',
};
const getStatusItems = (entityStatus: LegalEntitiesInterface['status']) => {
  return Object.values(LegalEntitiesStatus).map(status => ({
    label: status === 'UnderReview' ? 'Under Review' : status,
    value: status,
    isSelected: status === entityStatus,
    type: statusMapper[status],
  })) as BigidStatusButtonItem[];
};

const tabBasedComponentMapper: Record<
  LegalEntityPageTabsPath,
  typeof LegalEntityOverview | typeof LegalEntitiesActivityLogs
> = {
  [LegalEntityPageTabsPath.OVERVIEW]: LegalEntityOverview,
};

const getComponentBasedOnTab = (tab: LegalEntityPageTabsPath) => {
  return tabBasedComponentMapper[tab] || tabBasedComponentMapper[LegalEntityPageTabsPath.OVERVIEW];
};

const trackingEventsMapper: Record<LegalEntitiesStatus, LegalEntitiesTrackingEvents> = {
  [LegalEntitiesStatus.ACTIVE]: LegalEntitiesTrackingEvents.SET_STATUS_ACTIVE_CLICK,
  [LegalEntitiesStatus.INACTIVE]: LegalEntitiesTrackingEvents.SET_STATUS_INACTIVE_CLICK,
  [LegalEntitiesStatus.DRAFT]: LegalEntitiesTrackingEvents.SET_STATUS_DRAFT_CLICK,
  [LegalEntitiesStatus.UNDER_REVIEW]: LegalEntitiesTrackingEvents.SET_STATUS_UNDER_REVIEW_CLICK,
};

const getHierarchy = async (id: string) => {
  try {
    const hierarchyData: HierarchyType[] = await getHierarchyById(id);
    return hierarchyData;
  } catch (error) {
    notificationService.error(`Failed to hierarchy`);
    console.error(`Failed to fetch hierarchy: ${JSON.stringify(error?.response)}`);
    return [];
  }
};
