import { useEffect, useMemo } from 'react';
import { $state } from '../../../services/angularServices';
import { CONFIG } from '../../../../config/common';
import { cloudProviderLabel, TYPE_TO_ICON } from '../constants';
import { pageHeaderService } from '../../../../common/services/pageHeaderService';
import { AutoDiscoveryPresetConfigUrlParams } from '../AutoDiscoveryWizard/AutoDiscoveryWizardAdvanced/AutoDiscoveryWizardAdvanced';
import { AutoDiscoveryWizardContextState } from '../AutoDiscoveryWizard/autoDiscoveryWizardContext';
import { TFunction } from 'i18next';

export const useAutoDiscoveryConfigHeaderProps = (
  $stateParams: AutoDiscoveryPresetConfigUrlParams,
  discoveryConfigData: AutoDiscoveryWizardContextState,
  t: TFunction,
) => {
  const pageTitle = useMemo(
    () =>
      `${cloudProviderLabel[$stateParams?.type]}${$stateParams.id ? ' - ' + discoveryConfigData?.preset?.name : ''}`,
    [$stateParams.id, $stateParams?.type, discoveryConfigData?.preset?.name],
  );

  const Icon = TYPE_TO_ICON[$stateParams?.type];

  const breadcrumbs = useMemo(
    () => ({
      breadcrumbs: [
        {
          label: t('configAdvanced.breadcrumbs.dataSources'),
          onClick: () =>
            $state.go(CONFIG.states.DATA_SOURCE_CONNECTIONS, {
              tab: 'ds',
            }),
        },
        {
          label: t('configAdvanced.breadcrumbs.automaticOnboarding'),
          onClick: () =>
            $state.go(CONFIG.states.DATA_SOURCE_CONNECTIONS, {
              tab: 'discovery',
            }),
        },
        {
          label: pageTitle,
        },
      ],
    }),
    [pageTitle],
  );

  useEffect(() => {
    pageHeaderService.setIsHidden(true);
    return () => {
      pageHeaderService.setIsHidden(false);
    };
  }, []);

  return {
    Icon,
    breadcrumbs,
    pageTitle,
  };
};
