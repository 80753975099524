import {
  AdvancedToolbarOverrideValue,
  BigidAdvancedToolbarDateRangeFilter,
  BigidAdvancedToolbarDropdownFilter,
  BigidAdvancedToolbarFilterTypes,
  BigidAdvancedToolbarFilterUnion,
  BigidContentItem,
  BigidDropdownOption,
  BigidFieldFilter,
  BigidFieldFilterOperator,
  BigidFilter,
  BigidSideFilterSectionBase,
  BigidSideFilterSectionItemBase,
  DateRangeFilterSchema,
} from '@bigid-ui/components';
import {
  AbstractQueryConditionNode,
  BigidValueType,
  QueryConditionOperation,
  QueryExpressionOperator,
  parseAbstractQueryTreeIntoString,
  parseExpressionsIntoAbstractQueryTree,
  AbstractQueryNode,
  parseAbstractQueryTreeFromNodes,
} from '@bigid/query-object-serialization';
import { BigidGridProps } from '@bigid-ui/grid';
import { LayoutContentMasterDetailsProps, parseObjectToMasterDetailsContentItem } from '@bigid-ui/layout';
import { ReactText } from 'react';
import { GetViewInDataOverviewFiltersResult, SeparatedFilters } from './types';
import { mapDateRangeFilterToConditionNode } from '../../../utilities/toolbarFilterToBIQL/toolbarFilterToBIQL';
import { partition } from 'lodash';

export const OPTIONS_DISPLAY_LIMIT = 50;

type SideFilterToDropdownOption = {
  item: BigidSideFilterSectionItemBase;
  parentId?: string;
  isTag?: boolean;
};

type GetSelectedItemPayload = Pick<BigidGridProps<any>, 'rowClickShouldKeepSelection'> &
  Pick<LayoutContentMasterDetailsProps, 'selectedItemPropsMapping'> & {
    selectedItem: BigidContentItem;
    selectedRowIds: ReactText[];
  };

const sideFilterItemToDropdownOption = ({ item, isTag, parentId }: SideFilterToDropdownOption): BigidDropdownOption => {
  const id = isTag ? `${parentId ? parentId + '.' : ''}${item.name}` : String(item.id);

  return {
    displayValue: item.displayName || item.name,
    id,
    value: item.value,
    isSelected: Boolean(item.isSelected),
    parentId: parentId,
    children: item.items?.map(childItem => sideFilterItemToDropdownOption({ item: childItem, parentId: id, isTag })),
  };
};

type MapSideFilterToAdvancedFilterOptions = {
  useOptionParentIdAsFieldName?: boolean;
  isTagFilter?: boolean;
};

export const mapSideFilterToAdvancedDropdownFilter = (
  filter: BigidSideFilterSectionBase,
  filterOptions?: MapSideFilterToAdvancedFilterOptions,
): BigidAdvancedToolbarDropdownFilter => {
  const advancedFilters: BigidAdvancedToolbarDropdownFilter = {
    field: String(filter.name),
    id: filter.id,
    title: filter.displayName,
    operator: 'in',
    disabled: !filter.isEnabled,
    optionsDisplayLimit: OPTIONS_DISPLAY_LIMIT,
    type: BigidAdvancedToolbarFilterTypes.DROPDOWN,
    options: [],
    /** FIXME: a workaround to fix BDT-95452, a better regression solution is needed. To be discussed ASAP */
    asyncOptionsFetch: (_filters: BigidAdvancedToolbarFilterUnion[], searchText: string) => {
      const asyncOptions: BigidDropdownOption[] = filter.items.map(item =>
        sideFilterItemToDropdownOption({ item, isTag: filterOptions?.isTagFilter }),
      );

      if (searchText) {
        return Promise.resolve(
          asyncOptions.reduce((filteredAsyncOptions, option) => {
            const { displayValue, children } = option;
            const normalizedQuery = searchText.toLowerCase();
            const isParentMatched = displayValue.toLowerCase().includes(normalizedQuery);

            if (children?.length > 0) {
              if (isParentMatched) {
                return [...filteredAsyncOptions, option];
              } else {
                const matchedChildren = children.filter(({ displayValue }) => {
                  return displayValue.toLowerCase().includes(normalizedQuery);
                });

                return matchedChildren.length > 0
                  ? [...filteredAsyncOptions, { ...option, children: matchedChildren }]
                  : filteredAsyncOptions;
              }
            } else {
              return isParentMatched ? [...filteredAsyncOptions, option] : filteredAsyncOptions;
            }
          }, []),
        );
      } else {
        return Promise.resolve(asyncOptions);
      }
    },
    isSearchable: true,
    parentId: filterOptions?.isTagFilter ? 'tag' : undefined,
  };

  return advancedFilters;
};

export const mapTagsFilterToAdvancedDropdownFilter = (
  baseFilter: BigidSideFilterSectionBase,
): BigidAdvancedToolbarDropdownFilter => {
  const advancedFilters: BigidAdvancedToolbarDropdownFilter = {
    field: String(baseFilter.name),
    id: baseFilter.id,
    title: baseFilter.displayName,
    operator: 'in',
    disabled: !baseFilter.isEnabled,
    optionsDisplayLimit: OPTIONS_DISPLAY_LIMIT,
    type: BigidAdvancedToolbarFilterTypes.DROPDOWN,
    options: [],
    /** FIXME: a workaround to fix BDT-95452, a better regression solution is needed. To be discussed ASAP */
    asyncOptionsFetch: (_filters: BigidAdvancedToolbarFilterUnion[], searchText: string) => {
      const asyncOptions: BigidDropdownOption[] = baseFilter.items.map(item =>
        sideFilterItemToDropdownOption({ item, isTag: true }),
      );

      if (searchText) {
        return Promise.resolve(
          asyncOptions.reduce((filteredAsyncOptions, option) => {
            const { displayValue, children } = option;
            const normalizedQuery = searchText.toLowerCase();
            const isParentMatched = displayValue.toLowerCase().includes(normalizedQuery);

            if (children?.length > 0) {
              if (isParentMatched) {
                return [...filteredAsyncOptions, option];
              } else {
                const matchedChildren = children.filter(({ displayValue }) => {
                  return displayValue.toLowerCase().includes(normalizedQuery);
                });

                return matchedChildren.length > 0
                  ? [...filteredAsyncOptions, { ...option, children: matchedChildren }]
                  : filteredAsyncOptions;
              }
            } else {
              return isParentMatched ? [...filteredAsyncOptions, option] : filteredAsyncOptions;
            }
          }, []),
        );
      } else {
        return Promise.resolve(asyncOptions);
      }
    },
    isSearchable: false,
  };

  return advancedFilters;
};

const advancedFilterOperatorToQueryConditionOperation = (
  operator: BigidFieldFilterOperator,
): QueryConditionOperation => {
  switch (operator) {
    case 'in':
      return QueryConditionOperation.IN;
    case 'equal':
      return QueryConditionOperation.EQUAL;
    case 'greaterThan':
      return QueryConditionOperation.GT;
    case 'greaterThanOrEqual':
      return QueryConditionOperation.GTE;
    case 'lessThan':
      return QueryConditionOperation.LT;
    case 'lessThanOrEqual':
      return QueryConditionOperation.LTE;
    default:
      return QueryConditionOperation.IN;
  }
};

const getIsNegativeOperation = (options: BigidDropdownOption[], negativeValue: unknown): boolean => {
  return options.some(option => option.value === negativeValue);
};

const defaultCatalogFilterToConditionNode = (
  filter: BigidAdvancedToolbarDropdownFilter,
): AbstractQueryConditionNode => {
  const { field, options, operator } = filter;

  const operation = advancedFilterOperatorToQueryConditionOperation(operator);

  return {
    name: field,
    bigidName: field,
    operation,
    operator: QueryExpressionOperator.UNDEFINED,
    type: BigidValueType.STRING,
    value: options.flatMap(option => option.value),
    isIgnored: false,
    isNegativeOperation: false,
    isTagsNegativeOperation: false,
    arrayFieldName: null,
  };
};

const piFindingsFilterToConditionNode = (
  dropdownFilter: BigidAdvancedToolbarDropdownFilter,
): AbstractQueryConditionNode => {
  const { field, options, operator } = dropdownFilter;

  const operation = advancedFilterOperatorToQueryConditionOperation(operator);

  // if options contain both true and false, we should ignore the filter
  if (options.length === 2) {
    return null;
  }

  const isNegativeOperation = getIsNegativeOperation(options, false);

  return {
    name: field,
    bigidName: field,
    operation,
    operator: QueryExpressionOperator.UNDEFINED,
    type: BigidValueType.STRING,
    value: `true`,
    isIgnored: false,
    isNegativeOperation,
    isTagsNegativeOperation: false,
    arrayFieldName: null,
  };
};

const getValueMappingFn = (field: string) => {
  switch (field) {
    case 'contains_pi':
    case 'has_duplicates':
      return piFindingsFilterToConditionNode;
    default:
      return defaultCatalogFilterToConditionNode;
  }
};

export const mapCatalogFilterToAbstractNode = (
  filter: BigidAdvancedToolbarFilterUnion,
): AbstractQueryNode | AbstractQueryNode[] => {
  const mapFn = getValueMappingFn(filter.field);
  if (filter.type === BigidAdvancedToolbarFilterTypes.DATE_RANGE) {
    return mapDateRangeFilterToConditionNode(filter);
  }

  return mapFn(filter);
};

export const splitTagFilterByParentId = (
  filter: BigidAdvancedToolbarDropdownFilter,
): BigidAdvancedToolbarDropdownFilter[] => {
  const { options } = filter;
  const parentIds = new Set(options.map(option => option.parentId));
  const filters = Array.from(parentIds).map(parentId => {
    const filteredOptions = options.filter(option => option.parentId === parentId);

    return {
      ...filter,
      field: parentId,
      options: filteredOptions,
    };
  });

  return filters;
};

export const mapCatalogFiltersToFilterString = (filters: BigidAdvancedToolbarDropdownFilter[]) => {
  const prepared = prepareDropdownFilters(filters);

  const [tagFilters, otherFilters] = prepared.reduce<SeparatedFilters>(
    ([tags, others], filter) => {
      if (filter.parentId === 'tag') {
        return [tags.concat(filter), others];
      }
      return [tags, others.concat(filter)];
    },
    [[], []],
  );

  const allTagFilters = tagFilters.map(splitTagFilterByParentId).flat();
  const tagNodes = allTagFilters.map(mapCatalogFilterToAbstractNode);
  const tagsNode = parseAbstractQueryTreeFromNodes(tagNodes.flat(), QueryExpressionOperator.OR);

  const otherFiltersNodes = otherFilters.map(mapCatalogFilterToAbstractNode).flat();
  const abstractTree = parseExpressionsIntoAbstractQueryTree([tagsNode, ...otherFiltersNodes].filter(Boolean));

  const query = parseAbstractQueryTreeIntoString(abstractTree);

  return query;
};

export const prepareDropdownFilters = (filters: BigidAdvancedToolbarDropdownFilter[]) => {
  return filters
    .map(filter => {
      if (filter.parentId === 'tag') {
        return splitTagFilterByParentId(filter);
      }
      return filter;
    })
    .flat();
};

export const getSelectedItem = ({
  selectedItem,
  selectedRowIds,
  selectedItemPropsMapping,
  rowClickShouldKeepSelection,
}: GetSelectedItemPayload): BigidContentItem => {
  let selItem: BigidContentItem = null;

  if (selectedItem) {
    if (rowClickShouldKeepSelection) {
      selItem = selectedItemPropsMapping
        ? parseObjectToMasterDetailsContentItem(selectedItem, selectedItemPropsMapping)
        : selectedItem;
    } else {
      selItem =
        selectedRowIds.length === 1
          ? selectedItemPropsMapping
            ? parseObjectToMasterDetailsContentItem(selectedItem, selectedItemPropsMapping)
            : selectedItem
          : null;
    }
  }

  return selItem;
};

export const objectToQueryString = (obj: any, prefix?: string): string => {
  const pairs = Object.entries(obj).flatMap(([key, value]) => {
    const fullKey = prefix ? `${prefix}[${key}]` : key;

    if (Array.isArray(value)) {
      return value.map((v, i) => objectToQueryString(v, `${fullKey}[${i}]`));
    } else if (typeof value === 'object' && value !== null) {
      return objectToQueryString(value, fullKey);
    } else {
      return `${encodeURIComponent(fullKey)}=${encodeURIComponent(value as string)}`;
    }
  });

  return pairs.join('&');
};

export const mapAdvancedToolbarFilterToBaseFilter = (
  advancedFilters: BigidAdvancedToolbarFilterUnion[],
): BigidFilter => {
  return advancedFilters.flatMap<BigidFieldFilter>(filter => {
    if (filter.type === BigidAdvancedToolbarFilterTypes.DROPDOWN) {
      return filter.options.map(option => {
        const filterMapped: BigidFieldFilter = {
          field: option.parentId ? option.parentId : String(filter.field),
          value: option.value,
          operator: filter.operator,
          id: option.id,
        };

        if (option.parentId) {
          filterMapped.parentId = option.parentId;
        }

        return filterMapped;
      });
    }
    return [];
  });
};

export const processStringifiedDateRangeFilterToDateObject = (
  overrideValue: AdvancedToolbarOverrideValue,
): AdvancedToolbarOverrideValue => {
  if (overrideValue.type === BigidAdvancedToolbarFilterTypes.DATE_RANGE) {
    const { currentRangeOptionSelected, pickersState } = overrideValue.options as DateRangeFilterSchema;
    return {
      ...overrideValue,
      options: {
        currentRangeOptionSelected,
        pickersState: {
          currentMode: pickersState.currentMode,
          dates: {
            from: pickersState.dates.from ? new Date(pickersState.dates.from) : null,
            until: pickersState.dates.until ? new Date(pickersState.dates.until) : null,
          },
        },
      },
    };
  } else {
    return overrideValue;
  }
};

export const getViewInDataOverviewFilters = (
  selectedFilters: BigidAdvancedToolbarFilterUnion[],
): GetViewInDataOverviewFiltersResult => {
  const [dateRangeFilters, dropdownFilters] = partition(
    selectedFilters,
    filter => filter.type === BigidAdvancedToolbarFilterTypes.DATE_RANGE,
  );

  return {
    dropdownFilters: mapAdvancedToolbarFilterToBaseFilter(dropdownFilters),
    dateRangeFilters: dateRangeFilters as BigidAdvancedToolbarDateRangeFilter[],
  };
};
