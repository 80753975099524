import { CONFIG } from '../../../../../../config/common';
import { userPreferencesService } from '../../../../../services/userPreferencesService';
import { CatalogDiscoveryPreferences } from '../../../catalogDiscoveryTypes';
import { AdvancedToolbarOverrideValue } from '@bigid-ui/components';
import { DateISO8601 } from '../../../../../types/types';

export type SavedQueryLabel = {
  _id: string;
  name: string;
  displayName: string;
  label_id: string;
  description: string;
};

export type SavedQuery = {
  _id: string;
  query: string;
  description: string;
  tag_name: string;
  tag_value: string;
  update_date: DateISO8601;
  create_date: DateISO8601;
  label: SavedQueryLabel;
};

//NOTE: a temporal solution to mimic SavedQuery
export type SavedFilter = {
  id?: string;
  name: string;
  description?: string;
  query: string;
  filter: AdvancedToolbarOverrideValue[];
};

//NOTE: enable once we have a parser and are able to fully support Saved Queries
// export function getSavedQueries() {
//   return httpService.fetch('saved-queries').then(data => data);
// }

// export function getSavedQuery(id: string) {
//   return httpService.fetch(`saved-queries/${id}`).then(data => data);
// }

// export function createSavedQuery(payload: unknown) {
//   return httpService.post('saved-queries', payload).then(data => data);
// }

// export function updateSavedQuery(id: string) {
//   return httpService.put(`saved-queries/${id}`).then(data => data);
// }

// export function deleteSavedQuery(id: string) {
//   return httpService.delete(`saved-queries/${id}`).then(data => data);
// }

export async function createSavedFilter(filter: SavedFilter) {
  const preferences = await userPreferencesService.get<CatalogDiscoveryPreferences>(CONFIG.states.CATALOG_DISCOVERY);

  if (preferences) {
    await userPreferencesService.update({
      preference: CONFIG.states.CATALOG_DISCOVERY,
      data: {
        ...preferences.data,
        savedFilters: [...(preferences.data.savedFilters ?? []), filter],
      },
    });
  } else {
    await userPreferencesService.add({
      preference: CONFIG.states.CATALOG_DISCOVERY,
      data: {
        savedFilters: [filter],
      },
    });
  }
}

export async function updateSavedFilter(filter: SavedFilter) {
  const preferences = await userPreferencesService.get<CatalogDiscoveryPreferences>(CONFIG.states.CATALOG_DISCOVERY);

  if (preferences) {
    await userPreferencesService.update({
      preference: CONFIG.states.CATALOG_DISCOVERY,
      data: {
        ...preferences.data,
        savedFilters: preferences.data.savedFilters
          ? preferences.data.savedFilters.map(savedFilter => {
              if (savedFilter.id === filter.id) {
                return { ...savedFilter, ...filter };
              }

              return savedFilter;
            })
          : [filter],
      },
    });
  } else {
    await userPreferencesService.add({
      preference: CONFIG.states.CATALOG_DISCOVERY,
      data: {
        savedFilters: [filter],
      },
    });
  }
}

export async function deleteSavedFilter(filter: SavedFilter) {
  const preferences = await userPreferencesService.get<CatalogDiscoveryPreferences>(CONFIG.states.CATALOG_DISCOVERY);

  if (preferences) {
    await userPreferencesService.update({
      preference: CONFIG.states.CATALOG_DISCOVERY,
      data: {
        ...preferences.data,
        savedFilters: (preferences.data.savedFilters ?? []).filter(({ id }) => filter.id !== id),
      },
    });
  }
}
