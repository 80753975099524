import React, { FC } from 'react';
import { BigidBody1, BigidColorsV2 } from '@bigid-ui/components';
import { initialDataSourceList, ConnectStatus, normalizeDataSourceList } from './utils';
import makeStyles from '@mui/styles/makeStyles';
import classnames from 'classnames';
import { CredsStatus } from './ConnectionsStatus';
import { CloudProvider } from '../../../AutoDiscoveryWizard/autoDiscoveryWizardServices';
import { cloudProviderLabel } from '../../FmsdDiscover/hooks/useAutodiscoveryApp';
import { useLocalTranslation } from '../../translations';

const useStyles = makeStyles({
  connectionsWrapper: {
    paddingTop: 24,
    paddingBottom: 8,
  },
  dot: {
    height: 12,
    width: 12,
    backgroundColor: BigidColorsV2.gray[400],
    borderRadius: '50%',
    display: 'inline-block',
  },
  success: {
    backgroundColor: BigidColorsV2.green[700],
  },
  failed: {
    backgroundColor: BigidColorsV2.red[600],
  },
  item: {
    display: 'flex',
    paddingBottom: 8,
    alignItems: 'baseline',
    fontSize: 14,
  },
  itemName: {
    paddingLeft: 5,
  },
  title: {
    paddingBottom: 12,
  },
});

export interface ConnectionsListProps {
  type: CloudProvider;
  permissions: string[];
  credsStatus: CredsStatus;
  dataAid?: string;
}

export const ConnectionsList: FC<ConnectionsListProps> = ({ type, permissions, credsStatus, dataAid }) => {
  const { connectionsWrapper, dot, success, failed, item, itemName, title } = useStyles({});
  const { t } = useLocalTranslation('Connect.Dialog.ConnectionsList');
  const dsList =
    credsStatus === CredsStatus.VALID ? normalizeDataSourceList(type, permissions) : initialDataSourceList[type];

  return (
    <div className={connectionsWrapper}>
      <BigidBody1 className={title}>{t('bodyText', { cloudProviderLabel: cloudProviderLabel[type] })}</BigidBody1>
      <div>
        {dsList.map(({ id, name, connected }) => (
          <div key={id} className={item}>
            <span
              className={classnames(dot, {
                [success]: connected === ConnectStatus.CONNECTED,
                [failed]: connected === ConnectStatus.FAILED,
              })}
              data-aid={`${dataAid}-ds-dot-${connected === ConnectStatus.CONNECTED ? 'permitted' : 'forbidden'}`}
            />
            <BigidBody1 data-aid={`${dataAid}-ds-description`} className={itemName}>
              {name}
            </BigidBody1>
            {connected === ConnectStatus.FAILED && <b>&nbsp;- {t('noPermissionsText')}</b>}
          </div>
        ))}
      </div>
    </div>
  );
};
