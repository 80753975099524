import { CustomDashboardConfig, OPERATORS } from '@bigid-ui/custom-dashboard';

export const actionableInsightsDashboard: CustomDashboardConfig = {
  endpoints: [
    {
      id: 'scan_activity_summary',
      api: {
        url: 'api/v1/scan-activity-summary',
        method: 'get',
      },
    },
    {
      id: 'policies_triggered',
      api: {
        url: 'api/v1/complianceSummaries',
        method: 'get',
      },
    },
    {
      id: 'object_summaries',
      api: {
        url: 'api/v1/object-summaries/by-type',
        method: 'get',
      },
    },
    {
      id: 'default_group_sensitivity_classification_ratio',
      api: {
        url: 'api/v1/aci/sc/ratio/name/Sensitivity',
        method: 'get',
      },
    },
    {
      id: 'catalog_sensitivity_levels_undefined',
      api: {
        url: 'api/v1/data-catalog?filter=contains_pi%3Dtrue%20AND%20NIN%20catalog_tag.system.sensitivityClassification.Sensitivity%20IN%20(%20%22High%22%2C%20%22Medium%22%2C%20%22Low%22%20)',
        method: 'get',
      },
    },
    {
      id: 'object_summaries_sorted_by_pii',
      api: {
        url: 'api/v1/sorted-object-summaries-by-ds?len=3&makeOther=true',
        method: 'get',
      },
    },
    {
      id: 'attribute_by_ds',
      api: {
        url: 'api/v1/getAttributesByDs?len=3',
        method: 'get',
      },
    },
    {
      id: 'total_findings_by_top_attribute',
      api: {
        url: 'api/v1/sorted-and-limited-attributes-risks?len=3',
        method: 'get',
      },
    },
  ],
  dataManipulations: [
    {
      id: 'policies_triggered_array',
      setVar: {
        path: 'data.policies_triggered.compliance_summaries',
      },
      status: {
        dependency: ['policies_triggered'],
      },
    },
    {
      id: 'policies_triggered_sum',
      sumFromArray: {
        pathToArray: 'data.policies_triggered_array',
        pathToAttribute: 'count',
      },
      status: {
        dependency: ['policies_triggered'],
      },
    },
    {
      id: 'compliance_summary_fail_item',
      findItemInArray: {
        pathToArray: 'data.policies_triggered_array',
        operation: {
          pathToAttribute: 'id',
          operator: OPERATORS.EQUAL_TO,
          value: 'fail',
        },
      },
      status: {
        dependency: ['policies_triggered'],
      },
    },
    {
      id: 'objects_summary_total_objects_with_pii',
      sumFromArray: {
        pathToArray: 'data.object_summaries.summaryByTypes',
        pathToAttribute: 'totalObjectsWithPii',
      },
      status: {
        dependency: ['object_summaries'],
      },
    },
    {
      id: 'count_catalog_sensitivity_levels_undefined',
      countItemsOfArray: {
        pathToArray: 'data.catalog_sensitivity_levels_undefined.results',
      },
      status: {
        dependency: ['catalog_sensitivity_levels_undefined'],
      },
    },
    {
      id: 'catalog_sensitivity_levels_undefined_array_for_concat',
      createArray: {
        array: [
          {
            name: 'Undefined',
            count: 'data.count_catalog_sensitivity_levels_undefined',
          },
        ],
      },
      status: {
        dependency: ['catalog_sensitivity_levels_undefined'],
      },
    },
    {
      id: 'sensitivity_levels_array',
      concatArrays: {
        pathToArrays: [
          'data.default_group_sensitivity_classification_ratio.data.counters',
          'data.catalog_sensitivity_levels_undefined_array_for_concat',
        ],
      },
      status: {
        dependency: ['default_group_sensitivity_classification_ratio', 'catalog_sensitivity_levels_undefined'],
      },
    },
    {
      id: 'total_findings_count_by_ds',
      filterArray: {
        pathToArray: 'data.object_summaries_sorted_by_pii.summaries',
        operation: {
          pathToAttribute: 'totalObjectsWithPii',
          operator: OPERATORS.GREATER_THAN,
          value: 0,
        },
      },
      status: {
        dependency: ['object_summaries_sorted_by_pii'],
      },
    },
  ],
  widgets: [
    {
      id: 'highlight_box_scanned_ds',
      header: {
        title: ' ',
      },
      widget: {
        highlightBox: {
          title: {
            if: {
              argument1: '{{data.scan_activity_summary.inventory.dataSourcesInfo.dataSourcesConfigured}}',
              operator: OPERATORS.GREATER_THAN,
              argument2: 0,
            },
            then: {
              return:
                '{{data.scan_activity_summary.inventory.dataSourcesInfo.dataSourcesScanned}} / {{data.scan_activity_summary.inventory.dataSourcesInfo.dataSourcesConfigured}}',
            },
            else: {
              return: '',
            },
          },
          subtitle: {
            if: {
              argument1: '{{data.scan_activity_summary.inventory.dataSourcesInfo.dataSourcesConfigured}}',
              operator: OPERATORS.GREATER_THAN,
              argument2: 0,
            },
            then: {
              return: 'Scanned Data Sources',
            },
            else: {
              return: '',
            },
          },
          svgString:
            "<svg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='33' height='36.419' viewBox='0 0 33 36.419'><defs><style>.BigIdSourcesGradient{fill:url(#BigIdSourcesGradient);}</style><linearGradient id='BigIdSourcesGradient' x2='1' y2='3.149' gradientUnits='objectBoundingBox'><stop offset='0' stop-color='#ee80ee'/><stop offset='1' stop-color='#7f269f'/></linearGradient></defs><g transform='translate(-128.999 -290.887)'><path class='BigIdSourcesGradient' d='M145.5,301.937c-7.947,0-16.5-1.729-16.5-5.525s8.552-5.525,16.5-5.525,16.5,1.729,16.5,5.525S153.445,301.937,145.5,301.937Zm0-8.681c-9.285,0-14.205,2.212-14.261,3.156.048.944,4.96,3.156,14.261,3.156s14.213-2.212,14.262-3.156C159.7,295.468,154.784,293.256,145.5,293.256Z' transform='translate(0 0)'/><path class='BigIdSourcesGradient' d='M145.5,313.406c-6.128,0-16.5-1.163-16.5-5.525a1.121,1.121,0,1,1,2.238,0c0,.917,5,3.156,14.261,3.156s14.262-2.24,14.262-3.156a1.121,1.121,0,1,1,2.238,0C162,312.243,151.627,313.406,145.5,313.406Z' transform='translate(0 1.666)'/><path class='BigIdSourcesGradient' d='M145.5,326.63c-8.2,0-16.5-2.192-16.5-6.38V295.663a1.119,1.119,0,0,1,2.238,0v24.588c0,1.7,5.555,4.158,14.261,4.158s14.262-2.463,14.262-4.158V295.663a1.119,1.119,0,0,1,2.238,0v24.588C162,324.439,153.7,326.63,145.5,326.63Z' transform='translate(0 0.676)'/></g></svg>",
          onClickRedirectUrl: {
            state: {
              if: {
                argument1: '{{data.scan_activity_summary.inventory.dataSourcesInfo.dataSourcesConfigured}}',
                operator: OPERATORS.GREATER_THAN,
                argument2: 0,
              },
              then: {
                return: 'dataCatalog',
              },
              else: {
                return: 'dataSourcesInit',
              },
            },
            data: {
              if: {
                argument1: '{{data.scan_activity_summary.inventory.dataSourcesInfo.dataSourcesConfigured}}',
                operator: OPERATORS.GREATER_THAN,
                argument2: 0,
              },
              then: {
                return: {
                  filter: 'contains_pi=true',
                },
              },
              else: {
                return: {
                  filter: '',
                },
              },
            },
          },
        },
      },
    },
    {
      id: 'highlight_box_scanned_ds_with_findings',
      header: {
        title: ' ',
      },
      widget: {
        highlightBox: {
          title: {
            if: {
              argument1: '{{data.scan_activity_summary.inventory.dataSourcesInfo.dataSourcesConfigured}}',
              operator: OPERATORS.GREATER_THAN,
              argument2: 0,
            },
            then: {
              return:
                '{{data.scan_activity_summary.inventory.dataSourcesInfo.dataSourceWithPiRecords}} / {{data.scan_activity_summary.inventory.dataSourcesInfo.dataSourcesScanned}}',
            },
            else: {
              return: '',
            },
          },
          subtitle: {
            if: {
              argument1: '{{data.scan_activity_summary.inventory.dataSourcesInfo.dataSourcesConfigured}}',
              operator: OPERATORS.GREATER_THAN,
              argument2: 0,
            },
            then: {
              return: 'Data Sources With Findings',
            },
            else: {
              return: '',
            },
          },
          svgString:
            "<svg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='33' height='36.419' viewBox='0 0 33 36.419'><defs><style>.BigIdSourcesGradient{fill:url(#BigIdSourcesGradient);}</style><linearGradient id='BigIdSourcesGradient' x2='1' y2='3.149' gradientUnits='objectBoundingBox'><stop offset='0' stop-color='#ee80ee'/><stop offset='1' stop-color='#7f269f'/></linearGradient></defs><g transform='translate(-128.999 -290.887)'><path class='BigIdSourcesGradient' d='M145.5,301.937c-7.947,0-16.5-1.729-16.5-5.525s8.552-5.525,16.5-5.525,16.5,1.729,16.5,5.525S153.445,301.937,145.5,301.937Zm0-8.681c-9.285,0-14.205,2.212-14.261,3.156.048.944,4.96,3.156,14.261,3.156s14.213-2.212,14.262-3.156C159.7,295.468,154.784,293.256,145.5,293.256Z' transform='translate(0 0)'/><path class='BigIdSourcesGradient' d='M145.5,313.406c-6.128,0-16.5-1.163-16.5-5.525a1.121,1.121,0,1,1,2.238,0c0,.917,5,3.156,14.261,3.156s14.262-2.24,14.262-3.156a1.121,1.121,0,1,1,2.238,0C162,312.243,151.627,313.406,145.5,313.406Z' transform='translate(0 1.666)'/><path class='BigIdSourcesGradient' d='M145.5,326.63c-8.2,0-16.5-2.192-16.5-6.38V295.663a1.119,1.119,0,0,1,2.238,0v24.588c0,1.7,5.555,4.158,14.261,4.158s14.262-2.463,14.262-4.158V295.663a1.119,1.119,0,0,1,2.238,0v24.588C162,324.439,153.7,326.63,145.5,326.63Z' transform='translate(0 0.676)'/></g></svg>",
          onClickRedirectUrl: {
            state: {
              if: {
                argument1: '{{data.scan_activity_summary.inventory.dataSourcesInfo.dataSourcesConfigured}}',
                operator: OPERATORS.GREATER_THAN,
                argument2: 0,
              },
              then: {
                return: 'dataCatalog',
              },
              else: {
                return: 'dataSourcesInit',
              },
            },
            data: {
              if: {
                argument1: '{{data.scan_activity_summary.inventory.dataSourcesInfo.dataSourcesConfigured}}',
                operator: OPERATORS.GREATER_THAN,
                argument2: 0,
              },
              then: {
                return: {
                  filter: 'contains_pi=true',
                },
              },
              else: {
                return: {
                  filter: '',
                },
              },
            },
          },
        },
      },
    },
    {
      id: 'highlight_box_objects_with_findings',
      header: {
        title: ' ',
      },
      widget: {
        highlightBox: {
          title: {
            if: {
              argument1: '{{data.objects_summary_total_objects_with_pii}}',
              operator: OPERATORS.GREATER_THAN,
              argument2: 0,
            },
            then: {
              return: '{{data.objects_summary_total_objects_with_pii}}',
            },
            else: {
              return: '',
            },
          },
          subtitle: {
            if: {
              argument1: '{{data.objects_summary_total_objects_with_pii}}',
              operator: OPERATORS.GREATER_THAN,
              argument2: 0,
            },
            then: {
              return: 'Objects With Findings',
            },
            else: {
              return: 'Configure Scan',
            },
          },
          svgString:
            "<svg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='27.961' height='32.546' viewBox='0 0 27.961 32.546'><defs><style>.BigidObjectsGradient{fill:url(#BigidObjectsGradient);}</style><linearGradient id='BigidObjectsGradient' x2='1' y2='1' gradientUnits='objectBoundingBox'><stop offset='0' stop-color='#80eecc'/><stop offset='1' stop-color='#00987b'/></linearGradient></defs><g transform='translate(-60.136 -53.279)'><path class='BigidObjectsGradient' d='M64.259,85.824A4.155,4.155,0,0,1,60.136,81.8V57.334a4.163,4.163,0,0,1,4.275-4.053H75.738a1,1,0,0,1,.685.272l11.359,10.69a1,1,0,0,1,.315.728v16.8a4.158,4.158,0,0,1-4.275,4.054H64.381Zm.054-30.545a2.163,2.163,0,0,0-2.178,2.087v24.4a2.17,2.17,0,0,0,2.216,2.055h19.5A2.145,2.145,0,0,0,86.1,81.737V65.4L75.341,55.28H64.313Z'/><path class='BigidObjectsGradient' d='M87.1,65.97H75.738a1,1,0,0,1-1-1V54.28a1,1,0,1,1,2,0v9.69H87.1a1,1,0,0,1,0,2Z'/></g></svg>",
          onClickRedirectUrl: {
            state: {
              if: {
                argument1: '{{data.objects_summary_total_objects_with_pii}}',
                operator: OPERATORS.GREATER_THAN,
                argument2: 0,
              },
              then: {
                return: 'dataCatalog',
              },
              else: {
                return: 'scans',
              },
            },
            data: {
              if: {
                argument1: '{{data.objects_summary_total_objects_with_pii}}',
                operator: OPERATORS.GREATER_THAN,
                argument2: 0,
              },
              then: {
                return: {
                  filter: 'contains_pi=true',
                },
              },
              else: {
                return: {
                  filter: '',
                },
              },
            },
          },
        },
      },
    },
    {
      id: 'sensitivity_levels_in_catalog_pie_chart',
      header: {
        title: 'Sensitivity Levels Distribution In The Catalog',
      },
      showFrame: true,
      widget: {
        pieChart: {
          minimized: false,
          height: '146px',
          data: {
            pathToArray: 'data.sensitivity_levels_array',
            pathToKey: 'name',
            pathToValue: 'count',
          },
          onLegendClickRedirectUrl: [
            {
              category: 'Restricted',
              onClickRedirectUrl: {
                state: 'dataCatalog',
                data: {
                  filter: 'contains_pi=true AND catalog_tag.system.sensitivityClassification.Sensitivity=Restricted',
                },
              },
            },
            {
              category: 'Confidential',
              onClickRedirectUrl: {
                state: 'dataCatalog',
                data: {
                  filter: 'contains_pi=true AND catalog_tag.system.sensitivityClassification.Sensitivity=Confidential',
                },
              },
            },
            {
              category: 'Public',
              onClickRedirectUrl: {
                state: 'dataCatalog',
                data: {
                  filter: 'contains_pi=true AND catalog_tag.system.sensitivityClassification.Sensitivity=Public',
                },
              },
            },
            {
              category: 'High',
              onClickRedirectUrl: {
                state: 'dataCatalog',
                data: {
                  filter: 'contains_pi=true AND catalog_tag.system.sensitivityClassification.Sensitivity=High',
                },
              },
            },
            {
              category: 'Medium',
              onClickRedirectUrl: {
                state: 'dataCatalog',
                data: {
                  filter: 'contains_pi=true AND catalog_tag.system.sensitivityClassification.Sensitivity=Medium',
                },
              },
            },
            {
              category: 'Low',
              onClickRedirectUrl: {
                state: 'dataCatalog',
                data: {
                  filter: 'contains_pi=true AND catalog_tag.system.sensitivityClassification.Sensitivity=Low',
                },
              },
            },
            {
              category: 'Undefined',
              onClickRedirectUrl: {
                state: 'dataCatalog',
                data: {
                  filter: 'contains_pi=true',
                },
              },
            },
          ],
        },
      },
    },
    {
      id: 'total_objects_findings_count_by_data_source_pie_chart',
      header: {
        title: 'Objects With Findings Count By Data Source',
      },
      showFrame: true,
      widget: {
        pieChart: {
          minimized: false,
          height: '146px',
          data: {
            pathToArray: 'data.total_findings_count_by_ds',
            pathToKey: 'source',
            pathToValue: 'totalObjectsWithPii',
          },
          onLegendClickRedirectUrl: [
            {
              category: 'Other',
              onClickRedirectUrl: {
                state: 'dataCatalog',
                data: {
                  filter: 'contains_pi=true',
                },
              },
            },
          ],
        },
      },
    },
    {
      id: 'top_ds_with_findings_bar_chart',
      header: {
        title: 'Top Data Sources With Attributes Count',
      },
      showFrame: true,
      widget: {
        barChart: {
          height: '320px',
          maxColumnLabelWidth: 130,
          data: {
            pathToArray: 'data.attribute_by_ds.attributeByDs',
            pathToColumnCategory: 'source',
            keys: [
              {
                keyName: 'numberOfAttributes',
                label: 'Total attribute count',
              },
            ],
          },
        },
      },
    },
    {
      id: 'total_findings_count_by_top_attribute_bar_chart',
      header: {
        title: 'Total Findings Count By Top Attribute',
      },
      showFrame: true,
      widget: {
        barChart: {
          height: '320px',
          maxColumnLabelWidth: 130,
          data: {
            pathToArray: 'data.total_findings_by_top_attribute.attribute_risks',
            pathToColumnCategory: 'short_name',
            keys: [
              {
                keyName: 'count',
                label: 'Total attribute count',
              },
            ],
          },
        },
      },
    },
    {
      id: 'trendsAndRisksCustomWidget',
      widget: {
        predefinedWidget: {
          id: 'trendsAndRisks',
        },
      },
    },
    {
      id: 'criticalCasesWidget',
      widget: {
        predefinedWidget: {
          id: 'criticalCases',
        },
      },
      showFrame: true,
    },
    {
      id: 'trendCasesWidget',
      widget: {
        predefinedWidget: {
          id: 'trendCases',
        },
      },
      showFrame: true,
    },
    {
      id: 'casesBySeverityWidget',
      widget: {
        predefinedWidget: {
          id: 'casesBySeverity',
        },
      },
      showFrame: true,
    },
  ],
  layout: {
    direction: 'row',
    justifyContent: 'center',
    alignContent: 'center',
    alignItems: 'center',
    spacing: 1,
    layoutSlots: [
      {
        size: 7,
        layoutGrid: {
          spacing: 5,
          justifyContent: 'center',
          alignItems: 'center',
          layoutSlots: [
            {
              widgetId: 'highlight_box_scanned_ds',
            },
            {
              widgetId: 'highlight_box_scanned_ds_with_findings',
            },
            {
              widgetId: 'highlight_box_objects_with_findings',
            },
          ],
        },
      },
      {
        size: 7,
        layoutGrid: {
          justifyContent: 'center',
          alignItems: 'center',
          spacing: 1,
          layoutSlots: [
            {
              widgetId: 'sensitivity_levels_in_catalog_pie_chart',
              size: 6,
            },
            {
              widgetId: 'total_objects_findings_count_by_data_source_pie_chart',
              size: 6,
            },
          ],
        },
      },
      {
        size: 7,
        layoutGrid: {
          justifyContent: 'center',
          alignItems: 'center',
          spacing: 1,
          layoutSlots: [
            {
              widgetId: 'casesBySeverityWidget',
              size: 6,
            },
            {
              widgetId: 'criticalCasesWidget',
              size: 6,
            },
          ],
        },
      },
      {
        size: 7,
        layoutGrid: {
          justifyContent: 'center',
          alignItems: 'center',
          spacing: 1,
          layoutSlots: [
            {
              widgetId: 'trendCasesWidget',
              size: 12,
            },
          ],
        },
      },
      {
        size: 7,
        layoutGrid: {
          justifyContent: 'center',
          alignItems: 'center',
          spacing: 1,
          layoutSlots: [
            { widgetId: 'top_ds_with_findings_bar_chart', size: 6 },
            { widgetId: 'total_findings_count_by_top_attribute_bar_chart', size: 6 },
          ],
        },
      },
      {
        size: 7,
        layoutGrid: {
          justifyContent: 'center',
          alignItems: 'center',
          spacing: 1,
          layoutSlots: [
            {
              widgetId: 'trendsAndRisksCustomWidget',
              size: 12,
            },
          ],
        },
      },
    ],
  },
  loaderForEachWidget: true,
};
