export enum ReportingEtlTrackingEvents {
  REPORTING_ETL_TOOLBAR_EXECUTE_CLICK = 'REPORTING_ETL_TOOLBAR_EXECUTE_CLICK',
  REPORTING_ETL_TOOLBAR_STOP_CLICK = 'REPORTING_ETL_TOOLBAR_STOP_CLICK',
  REPORTING_ETL_TOOLBAR_REFRESH_CLICK = 'REPORTING_ETL_TOOLBAR_REFRESH_CLICK',
  REPORTING_ETL_TOOLBAR_CONFIGURATION_CLICK = 'REPORTING_ETL_TOOLBAR_CONFIGURATION_CLICK',
  REPORTING_ETL_GO_TO_LOOKER_BUTTON_CLICK = 'REPORTING_ETL_GO_TO_LOOKER_BUTTON_CLICK',
  REPORTING_ETL_CONFIGURATION_SAVE_SUCCESS = 'REPORTING_ETL_CONFIGURATION_SAVE_SUCCESS',
  REPORTING_ETL_CONFIGURATION_SAVE_FAIL = 'REPORTING_ETL_CONFIGURATION_SAVE_FAIL',
  REPORTING_ETL_TEST_CONNECTION_SUCCESS = 'REPORTING_ETL_TEST_CONNECTION_SUCCESS',
  REPORTING_ETL_TEST_CONNECTION_FAILS = 'REPORTING_ETL_TEST_CONNECTION_FAILS',
  REPORTING_ETL_CONFIGURATION_DIALOG_CLOSE = 'REPORTING_ETL_CONFIGURATION_DIALOG_CLOSE',
  REPORTING_ETL_EMPTY_STATE_CONFIGURATION_OPEN = 'REPORTING_ETL_EMPTY_STATE_CONFIGURATION_OPEN',
  REPORTING_ETL_EMPTY_STATE_REDIRECT_TO_LICENSE_PAGE = 'REPORTING_ETL_EMPTY_STATE_REDIRECT_TO_LICENSE_PAGE',
  REPORTING_ETL_EMPTY_STATE_EXECUTE_ETL = 'REPORTING_ETL_EMPTY_STATE_EXECUTE_ETL',
  REPORTING_ETL_EMPTY_STATE_EMAIL_DIALOG_OPENED = 'REPORTING_ETL_EMPTY_STATE_EMAIL_DIALOG_OPENED',
  REPORTING_ETL_SUCCESSFUL_EXECUTION = 'REPORTING_ETL_SUCCESSFUL_EXECUTION',
  REPORTING_ETL_SUCCESSFUL_ETL_STOP = 'REPORTING_ETL_SUCCESSFUL_ETL_STOP',
  REPORTING_ETL_FAILED_EXECUTION = 'REPORTING_ETL_FAILED_EXECUTION',
  REPORTING_ETL_FAILED_ETL_STOP = 'REPORTING_ETL_FAILED_ETL_STOP',
  REPORTING_ETL_DATA_PIPELINE_PAGE_VIEW = 'REPORTING_ETL_DATA_PIPELINE_PAGE_VIEW',
  REPORTING_ETL_DATA_INSIGHTS_STUDIO_PAGE_VIEW = 'REPORTING_ETL_DATA_INSIGHTS_STUDIO_PAGE_VIEW',
}
