import './taskComment.component.scss';
import { module } from 'angular';
import { ROOT_ANGULAR_APP_NAME } from '../../app.component';
import template from './taskComment.component.html';
import { isPermitted } from '../../react/services/userPermissionsService';
import { DSAR_PERMISSIONS, DATA_MINIMIZATION_PERMISSIONS } from '@bigid/permissions';
import { systemUsersService } from '../../react/services/angularServices';
import { $rootScope } from 'ngimport/index';
import { getUsersQuery } from '../../react/utilities/systemUsersUtils';
import { getApplicationPreference } from '../../react/services/appPreferencesService';
import { createContinueTask } from '../../react/views/AutoDiscovery/AutoDiscoveryCollaboration/autoDiscoveryCollaborationService';
import { notificationService } from '../../react/services/notificationService';
const app = module(ROOT_ANGULAR_APP_NAME);

class TaskCommentController {
  mentionRegex = /\[~user:\s\S+]/gi;
  linksRegex = /\[~link:\s\S+]/gi;
  ngClickRegex = /\[~ngClick:\s\S+]/gi;

  constructor(userDetailsService, $state, $sce) {
    'ngInject';

    this.userDetailsService = userDetailsService;
    this.$state = $state;
    this.$sce = $sce;
  }

  $onInit() {
    if (this.comment) {
      const { actionLinks, text } = this.comment;
      const directDownloadLink = 'directDownloadLink';
      const userPageLink = 'userPageLink';
      const customAction = 'customAction';
      const dataMinimizationAppLink = 'dataMinimizationAppLink';
      const createContinueTask = 'createContinueTask';

      const hasActionLinks = !!actionLinks;
      this.parseText(text).then(parsedText => {
        this.parsedText = parsedText;
        this.hasDownloadPermission = isPermitted(DSAR_PERMISSIONS.DOWNLOAD_REQUEST_REPORT.name);
        this.hasViewPersonalInfoPermission = isPermitted(DSAR_PERMISSIONS.READ_PERSONAL_INFO.name);
        const showDataMinimizationApp = getApplicationPreference('SHOW_DATA_MINIMIZATION_APP');
        this.hasViewDataMinimizationAppPermission =
          showDataMinimizationApp && isPermitted(DATA_MINIMIZATION_PERMISSIONS.READ.name);

        this.downloadReportAction =
          this.hasDownloadPermission &&
          ((hasActionLinks && actionLinks.find(actionBtn => actionBtn.type === directDownloadLink)) ||
            this.isLegacyNgClick(text));

        this.userPageAction =
          this.hasViewPersonalInfoPermission &&
          ((hasActionLinks && actionLinks.find(actionBtn => actionBtn.type === userPageLink)) ||
            this.isLegacyUserPageLink(text));

        this.dataMinimizationAction =
          this.hasViewDataMinimizationAppPermission &&
          ((hasActionLinks && actionLinks.find(actionBtn => actionBtn.type === dataMinimizationAppLink)) ||
            this.isLegacyUserPageLink(text));

        this.createContinueTaskAction =
          hasActionLinks && actionLinks.find(actionBtn => actionBtn.type === createContinueTask);

        this.customAction = hasActionLinks && actionLinks.find(actionBtn => actionBtn.type === customAction);
        this.showActions =
          this.downloadReportAction ||
          this.userPageAction ||
          this.customAction ||
          this.dataMinimizationAction ||
          this.createContinueTaskAction;
      });
    }
  }

  async parseText(input) {
    const mentionsMatches = input.match(this.mentionRegex);
    const linksMatches = input.match(this.linksRegex);
    const ngClicksMatches = input.match(this.ngClickRegex);

    input = await this.handleMentionsInText(input, mentionsMatches);
    input = this.handleLinksInText(input, linksMatches);
    input = this.handleLegacyNgClicks(input, ngClicksMatches);

    $rootScope.$applyAsync();
    return input;
  }

  downloadUserDetails() {
    this.userDetailsService.getJITCsvFile(this.downloadReportAction.requestId);
  }

  goToPersonalInfoReport() {
    this.$state.go('personalInfoReport', { userId: this.userPageAction.requestId, targetTab: 'tasks-list' });
  }

  goToDataMinimizationApp() {
    this.$state.go('dataMinimizationObject', { requestId: this.dataMinimizationAction.requestId });
  }

  createContinueTask() {
    createContinueTask(this.createContinueTaskAction).then(() => {
      notificationService.success('Invite sent');
    });
  }

  goToByCustomAction() {
    if (!this.customAction.stateName) {
      throw new Error('Custom action must contain a state name');
    }
    const params = 'params' in this.customAction ? this.customAction.params : this.customAction;
    this.$state.go(this.customAction.stateName, params);
  }

  async handleMentionsInText(input, mentionsMatches) {
    if (Array.isArray(mentionsMatches) && mentionsMatches.length) {
      const mentions = mentionsMatches
        .filter(match => typeof match === 'string')
        .map(mention => {
          return mention.replace('[~user:', '').replace(']', '').trim();
        });

      const users = await this.getUsersByMentions(mentions);
      mentionsMatches
        .filter(match => typeof match === 'string')
        .forEach(mention => {
          const mentionedUsername = mention.replace('[~user:', '').replace(']', '').trim();

          const mentionedSystemUser = users.find(user => user.name === mentionedUsername);
          if (mentionedSystemUser) {
            const { firstName, lastName, name } = mentionedSystemUser;
            const result = firstName && lastName ? `${firstName} ${lastName}` : name;
            const safeLink = this.$sce.trustAs(this.$sce.URL, 'mailto:' + mentionedUsername);
            input = input.replace(mention, `<span><a href="${safeLink}">${result}</a></span>`);
          }
        });
    }
    return input;
  }

  async getUsersByMentions(mentions) {
    try {
      const query = getUsersQuery({
        filter: mentions.map(mention => ({
          field: 'name',
          operator: 'textSearch',
          value: mention,
        })),
      });

      const {
        data: { users },
      } = await systemUsersService.getAllSystemUsersByQuery(query);

      return users;
    } catch (e) {
      console.error('error getting mentions', e);
      return [];
    }
  }

  handleLinksInText(input, linksMatches) {
    if (Array.isArray(linksMatches)) {
      linksMatches
        .filter(match => typeof match === 'string')
        .forEach(linkMatch => {
          const link = linkMatch.replace('[~link:', '').replace(']', '').trim();

          // we replace old personal-info-links with text for backward compatibility
          input = input.replace(
            linkMatch,
            linkMatch.includes('/#/personal-info-report/')
              ? 'Click the buttons below '
              : `<a href="${link}">click here</a>`,
          );
        });
    }
    return input;
  }

  handleLegacyNgClicks(input, ngClicksMatches) {
    // we remove old personal-info-links with text for backward compatibility
    if (Array.isArray(ngClicksMatches)) {
      ngClicksMatches
        .filter(match => typeof match === 'string')
        .forEach(match => {
          input = input.replace(match, '');
        });
    }
    return input;
  }

  isLegacyNgClick(text) {
    const legacyNgClickMatch = text.match(/\[~ngClick:\s\S+]/gi);

    if (legacyNgClickMatch) {
      const legacyNgClick = legacyNgClickMatch.find(match => match.includes('downloadUserDetails'));

      if (legacyNgClick) {
        return {
          type: 'directDownloadLink',
          requestId: legacyNgClick.match("'(.*?)'")[1],
        };
      }
    }

    return null;
  }

  isLegacyUserPageLink(text) {
    const legacyPersonalReportLinkMatch = text.match(/\[~link:\s\S+]/gi);

    if (legacyPersonalReportLinkMatch) {
      const legacyPersonalReportLink = legacyPersonalReportLinkMatch.find(match =>
        match.includes('/#/personal-info-report/'),
      );

      if (legacyPersonalReportLink) {
        return {
          type: 'userPageLink',
          requestId: legacyPersonalReportLink.match('personal-info-report/(.*?)]')[1],
        };
      }
    }

    return null;
  }
}

app.component('taskComment', {
  template,
  controller: TaskCommentController,
  bindings: {
    comment: '<',
  },
});
