import { httpService } from './httpService';
import { userPreferencesService } from '../services/userPreferencesService';
import { generatePermissionsForACustomDashboard } from '@bigid/permissions';
import { updateUserPermissions } from './userPermissionsService';
import { CustomDashboardConfig } from '@bigid-ui/custom-dashboard';

export interface CustomDashboardPreferences {
  default: string;
}

export interface CustomDashboardData {
  name: string;
  description: string;
  config: string;
  parsedConfig?: CustomDashboardConfig;
}

const CUSTOM_DASHBOARD_PREFERENCE_NAME = 'customDashboard';
const CUSTOM_DASHBOARD_URL = 'custom-dashboard';

export const CUSTOM_DASHBOARD_WRAPPER_CLASS_NAME = 'custom-dashboard-wrapper';

export const getCustomDashboards = async () => {
  const {
    data: { data },
  } = await httpService.fetch(CUSTOM_DASHBOARD_URL);
  return data;
};

export const getCustomDashboardById = async (customDashboardId: string) => {
  const res = await httpService.fetch(`${CUSTOM_DASHBOARD_URL}/${customDashboardId}`);
  const data = res.data.data || {};
  data.id = data._id;
  return data as CustomDashboardData;
};

export const addCustomDashboard = async (config: string, name: string, description: string) => {
  const customDashboard = { config, name, description };
  const {
    data: {
      data: [id],
    },
  } = await httpService.post(CUSTOM_DASHBOARD_URL, { customDashboard });

  return { id };
};

export const updateCustomDashboard = async (
  customDashboardId: string,
  config: string,
  name: string,
  description: string,
) => {
  const customDashboard = { config, name, description };
  await httpService.put(`${CUSTOM_DASHBOARD_URL}/${customDashboardId}`, { customDashboard });
  await updateUserPermissions();
  return { id: customDashboardId };
};

export const removeCustomDashboardById = (customDashboardId: string) =>
  httpService.delete(`${CUSTOM_DASHBOARD_URL}/${customDashboardId}`);

export const getUserDefaultDashboard = async () => {
  const preference = await userPreferencesService.get<CustomDashboardPreferences>(CUSTOM_DASHBOARD_PREFERENCE_NAME);
  return preference?.data?.default;
};

export const updateUserDefaultDashboard = async (dashboardId: string) => {
  await userPreferencesService.update<CustomDashboardPreferences>({
    preference: CUSTOM_DASHBOARD_PREFERENCE_NAME,
    data: { default: dashboardId },
  });
  return dashboardId;
};

export const getCustomDashboardManagePermission = (customDashboardId: string, customDashboardName: string) => {
  const [, managePermission] = generatePermissionsForACustomDashboard(customDashboardId, customDashboardName).map(
    ({ name }) => name,
  );
  return managePermission;
};
