import { BigidSelectOption } from '@bigid-ui/components';

export interface GenericField<Type, PutFields> {
  name: keyof PutFields;
  allowEmptyString?: boolean;
  apiName?: keyof PutFields;
  getValue?: <K extends keyof PutFields>(newStateFields: PutFields, item: Type) => unknown;
}

export function getPutData<Type, PutFields>(
  identityFields: (keyof Type)[],
  genericFields: GenericField<Type, PutFields>[],
  newState: PutFields,
  items: Partial<Type> | Partial<Type>[],
): PutFields[] {
  return []
    .concat(items)
    .map(item => {
      const itemIdentityFields = getIdentityFields(item, identityFields);
      const fieldsToUpdate = getObjectFieldsToUpdate(item, genericFields, newState);
      return Object.keys(fieldsToUpdate).length
        ? ({
            ...itemIdentityFields,
            ...fieldsToUpdate,
          } as PutFields)
        : undefined;
    })
    .filter(obj => obj);
}

export function getObjectFieldsToUpdate<Type, PutFields>(
  item: Type,
  genericFields: GenericField<Type, PutFields>[],
  newState: PutFields,
) {
  return genericFields.reduce(
    (acc, fieldDefinition) => Object.assign(acc, getFieldToUpdate(item, newState, fieldDefinition)),
    {},
  );
}

export function getFieldToUpdate<Type, PutFields>(
  item: Type,
  newState: PutFields,
  { name, apiName, getValue }: GenericField<Type, PutFields>,
) {
  const fieldName = apiName ?? name;
  const value = getValue ? getValue(newState, item) : newState[name];
  return value === undefined ? {} : { [fieldName]: value };
}

export function getIdentityFields<Type, Key extends keyof Type>(item: Type, fields: Key[]) {
  return Object.fromEntries(fields.map(field => [field, item[field]]));
}

export function getSelectedOptions<DataType, Key extends keyof DataType, SelectOption extends BigidSelectOption>(
  sources: DataType[],
  propName: Key,
  availableOptions: SelectOption[],
) {
  const allSelectedOptions = [...new Set(sources.map(item => item[propName]))].map(val =>
    availableOptions.find(({ value }) => value === val),
  );

  if (allSelectedOptions.length > 1) {
    return [{ label: 'Multiple Values', value: undefined }] as SelectOption[];
  }
  if (allSelectedOptions.length === 1) {
    return allSelectedOptions;
  }

  return [];
}

/**
 * return `''` if new value is null, means clear existing value
 * return `undefined` when value wasn't changed
 */
export function getValueFromClearableSelect(newValue: string, oldValue?: string) {
  const sendNewValue = newValue === null ? '' : newValue;
  if (typeof sendNewValue === 'string' && newValue !== oldValue) {
    return sendNewValue;
  }
  return undefined;
}
