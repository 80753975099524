import React, { FC } from 'react';
import { BigidAddIcon } from '@bigid-ui/icons';
import { isPermitted } from '../../services/userPermissionsService';
import { DATA_SOURCES_PERMISSIONS } from '@bigid/permissions';
import { $state } from '../../services/angularServices';
import { DATA_SOURCES_INIT_VIEWS } from '../../views/DataSources/DataSources';
import { DataSourcesUITrackingEvent } from '../../views/DataSources/DataSourcesEventTrackerUtils';
import { CONFIG } from '../../../config/common';
import { BigidTooltip, PrimaryButton } from '@bigid-ui/components';
import { AutoDiscoveryUITrackingEvent } from '../../views/AutoDiscovery/AutoDiscoveryEventTrackerUtils';
import { isAutoDiscoveryAvailableInDs } from '../../views/DataSources/DataSourceConnections/hooks/useAutoDiscoveryAppStatus';
import { useAutoDiscoverWizard } from '../../views/AutoDiscovery/hooks/useAutoDiscoverWizard';
import { CloudProvider } from '../../views/AutoDiscoveryWizard/autoDiscoveryWizardServices';
import { AutoDiscoveryConfigModel } from '../../views/AutoDiscovery/AutoDiscoveryGrid/AutoDiscoveryGridConfiguration';
import { getApplicationPreference } from '../../services/appPreferencesService';

interface CreateNewDatasourceButtonProps {
  isAutoDiscoveryAvailable: boolean;
  onAddDataSource?: () => void;
}

const createNewManualDataSource = async () => {
  $state.go(CONFIG.states.DATA_SOURCE_INIT, {
    path: DATA_SOURCES_INIT_VIEWS.selectDataType.path,
  });
};

export const goToAutoDiscovery = async (
  openAutoDiscoveryModal: (type?: CloudProvider, configData?: AutoDiscoveryConfigModel) => void,
) => {
  if (openAutoDiscoveryModal && getApplicationPreference('SHOW_NEW_AUTO_DISCOVERY_UI_FF')) {
    await $state.go(CONFIG.states.DATA_SOURCE_CONNECTIONS, { ...$state.params, tab: 'discovery' });
    openAutoDiscoveryModal();
  } else {
    $state.go(CONFIG.states.AUTO_DISCOVERY);
  }
};

export const AddDataSourceButtons: FC<CreateNewDatasourceButtonProps> = ({
  isAutoDiscoveryAvailable,
  onAddDataSource,
}) => {
  const handleAddDataSource = () => {
    createNewManualDataSource();
    onAddDataSource?.();
  };

  const { openAutoDiscoveryModal } = useAutoDiscoverWizard();

  return isPermitted(DATA_SOURCES_PERMISSIONS.CREATE.name) ? (
    <>
      <PrimaryButton
        onClick={handleAddDataSource}
        dataAid={'add-data-source-button'}
        size="large"
        bi={{ eventType: DataSourcesUITrackingEvent.ADMINISTRATION_DATA_SOURCES_ADD_DATA_SOURCE_CLICK }}
        text="Data Sources"
        startIcon={<BigidAddIcon />}
      />
      {isAutoDiscoveryAvailableInDs() && (
        <BigidTooltip
          title={'The autodiscovery application is not installed in your environment. Please contact the system admin.'}
          placement={'bottom'}
          isDisabled={isAutoDiscoveryAvailable}
        >
          <div>
            <PrimaryButton
              bi={{ eventType: AutoDiscoveryUITrackingEvent.DATA_SOURCES_OPEN_AUTO_DISCOVERY_CLICK }}
              onClick={() => goToAutoDiscovery(openAutoDiscoveryModal)}
              size="large"
              disabled={!isAutoDiscoveryAvailable}
              startIcon={<BigidAddIcon />}
              text="Start Onboarding"
            />
          </div>
        </BigidTooltip>
      )}
    </>
  ) : null;
};
