import {
  BigidDialog,
  BigidExtraField,
  BigidListItem,
  BigidSelectOption,
  ExtraFieldItemData,
  PrimaryButton,
  SecondaryButton,
  useExtraFieldControl,
} from '@bigid-ui/components';
import { notificationService } from '../../../services/notificationService';
import React, { useState, useCallback, FunctionComponent, useEffect, useMemo, FC } from 'react';
import { DataOwnerEntity, updateOwners } from './DataCatalogDetailsService';
import { DataCatalogDetailsOwnerSelector } from './DataCatalogDetailsOwnerSelector';
import isEmail from 'validator/lib/isEmail';
import { CatalogEventsEnum } from '../events';
import { analyticsService } from '../../../services/analyticsService';

export interface DataCatalogOwnersProps {
  fullyQualifiedName: string;
  usersList: BigidSelectOption[];
  isAddOwnerDialogOpen: boolean;
  basicDetailsList: BigidListItem[];
  onClose: () => void;
  onSubmit: () => void;
  dsType: string;
  dsName: string;
}

interface SelectExtraFieldProps {
  onChange: (option: BigidSelectOption[]) => void;
  value: BigidSelectOption[];
}

export const strWitoutBrackets = (value: string) => {
  return value.replace(/[\(\)']+/g, '');
};

export const getName = (value: string, email: string) => {
  return value.replace(email, '').replace(/[\(\)']+/g, '');
};

export const getEmail = (value: string) => {
  const splitValue = value.split(' ');
  return splitValue.find(str => {
    const email = strWitoutBrackets(str);
    return isEmail(email);
  });
};

export const DataCatalogOwnersDialog: FunctionComponent<DataCatalogOwnersProps> = ({
  fullyQualifiedName,
  usersList,
  isAddOwnerDialogOpen,
  basicDetailsList,
  onClose,
  onSubmit,
  dsType,
  dsName,
}) => {
  const [allOwnersSelected, setAllOwnersSelected] = useState<ExtraFieldItemData[]>([]);
  const [errorMessage, setErrorMessage] = useState<string>(null);
  const maxLength = 100;
  const OBJECT_OWNERS = 'object_owners';
  const ADD_OWNER = 'Add owner';

  useEffect(() => {
    if (allOwnersSelected) {
      const longName = allOwnersSelected.filter(owner => {
        const selectValue = owner.value?.[0];
        if (selectValue) {
          return getName(selectValue.label, '').length > maxLength;
        }
      });

      if (longName.length) {
        setErrorMessage(`Name should not exceed ${maxLength} characters. Please adjust.`);
      } else {
        setErrorMessage(null);
      }
    }
  }, [allOwnersSelected]);

  const dialogOwnersList = useMemo(() => {
    return (
      basicDetailsList
        .filter(({ id, values }) => id === OBJECT_OWNERS && values?.length > 0)
        .map(({ values }) =>
          values
            .filter(owner => owner !== ADD_OWNER)
            .map(owner => {
              const email = getEmail(owner);
              const name = getName(owner, email);
              if (!name.length) {
                return {
                  id: email,
                  value: [{ label: email, value: email } as BigidSelectOption],
                  label: email,
                };
              } else {
                return {
                  id: email,
                  value: [{ label: name, subLabel: email, value: name } as BigidSelectOption],
                  label: name,
                };
              }
            }),
        )?.[0] || []
    );
  }, [basicDetailsList]);

  const createNew = useCallback(() => {
    return {
      id: String(new Date().getTime()),
      value: '',
      label: '',
    };
  }, []);

  const { onAddNew, onDelete, onChangeItem, data } = useExtraFieldControl({
    initialData: dialogOwnersList?.length
      ? dialogOwnersList.map(({ value, id }) => ({
          id: id,
          value: value,
        }))
      : [createNew()],
    createNewItem: createNew,
    onChange: value => {
      setAllOwnersSelected(value);
    },
  });

  const handleOnSaveOwners = async () => {
    if (allOwnersSelected.length < 1) {
      closeOwnerDialog();
    } else {
      const removeEmptyValue = allOwnersSelected.filter(select => select.value && select.value?.[0] !== null);
      const dataToSend: DataOwnerEntity[] = removeEmptyValue.map(owner => {
        const selectValue = owner.value[0];
        if (!selectValue?.subLabel) {
          const email = getEmail(selectValue.label);
          const name = getName(selectValue.label, email).trim();
          return {
            fullName: name !== '' ? name : undefined,
            email: strWitoutBrackets(email),
          };
        } else {
          return {
            fullName: selectValue.label || undefined,
            email: selectValue?.subLabel,
          };
        }
      });

      const uniqueEmais = [...new Set(dataToSend.map(a => a.email))];

      const inputWithoutMail = uniqueEmais.some(email => {
        return !isEmail(strWitoutBrackets(email));
      });

      if (inputWithoutMail) {
        setErrorMessage('Email is missing for one or more owners. Please specify an email.');
      } else if (uniqueEmais.length !== dataToSend.length) {
        setErrorMessage("You can't save different owners with the same email");
      } else if (!errorMessage) {
        try {
          const updateOwnersPayload = {
            data: [{ fullyQualifiedName, owners: dataToSend }],
          };
          setErrorMessage(null);
          await updateOwners(updateOwnersPayload).then(() => onSubmit());

          if (dataToSend.length > 0) {
            const trackData = {
              fullyQualifiedName,
              dsType,
              dsName,
              owners: dataToSend,
            };
            analyticsService.trackManualEvent(CatalogEventsEnum.CATALOG_ADD_DATASOURCE_OWNER_EVENT, trackData);
          } else {
            const trackData = {
              fullyQualifiedName,
              dsType,
              dsName,
            };
            analyticsService.trackManualEvent(CatalogEventsEnum.CATALOG_REMOVE_DATASOURCE_OWNER_EVENT, trackData);
          }
        } catch {
          notificationService.error('An error has occurred');
        }
      }
    }
  };

  const closeOwnerDialog = () => {
    onClose();
  };

  const BigidSelectExtraField: FC<SelectExtraFieldProps> = ({ onChange, value }) => {
    return <DataCatalogDetailsOwnerSelector onChange={onChange} value={value} usersList={usersList} />;
  };

  return (
    <BigidDialog
      buttons={[
        {
          component: SecondaryButton,
          isClose: true,
          onClick: closeOwnerDialog,
          text: 'Cancel',
        },
        {
          component: PrimaryButton,
          onClick: handleOnSaveOwners,
          text: 'Save',
          disabled: !!errorMessage,
        },
      ]}
      borderTop
      isOpen={isAddOwnerDialogOpen}
      maxWidth="sm"
      onClose={closeOwnerDialog}
      title="Add Owner"
    >
      <BigidExtraField
        fieldComponent={BigidSelectExtraField}
        fieldsBottomOffset={10}
        data={data}
        onChangeItem={onChangeItem}
        onDelete={onDelete}
        onAddNew={onAddNew}
        errorMessage={errorMessage}
      />
    </BigidDialog>
  );
};
