import './ner.component.scss';
import template from './ner.component.html';

import { module } from 'angular';
const app = module('app');

app.component('ner', {
  template,
  bindings: {
    classifier: '=',
  },
  controller: function (classifiersService) {
    'ngInject';

    this.returnNerTexts = () => {
      switch (this.classifier._id) {
        case classifiersService.NER_CLASSIFIERS_ENUM.FULL_NAME:
          return 'CLASSIFIERS:FULL_NAME:DESCRIPTION';
        case classifiersService.NER_CLASSIFIERS_ENUM.GPE:
          return 'CLASSIFIERS:GPE:DESCRIPTION';
        case classifiersService.NER_CLASSIFIERS_ENUM.PHONE:
          return 'CLASSIFIERS:PHONE:DESCRIPTION';
        case classifiersService.NER_CLASSIFIERS_ENUM.ZIP:
          return 'CLASSIFIERS:ZIP:DESCRIPTION';
        case classifiersService.NER_CLASSIFIERS_ENUM.STREET:
          return 'CLASSIFIERS:STREET:DESCRIPTION';
        case classifiersService.NER_CLASSIFIERS_ENUM.NORP:
          return 'CLASSIFIERS:NORP:DESCRIPTION';
        case classifiersService.NER_CLASSIFIERS_ENUM.OTHERS:
          return 'CLASSIFIERS:OTHERS:DESCRIPTION';
        case classifiersService.NER_CLASSIFIERS_ENUM.CUSTOM_MODELS:
          return 'CLASSIFIERS:CUSTOM_MODELS:DESCRIPTION';
        default:
          return 'CLASSIFIERS:NER:DESCRIPTION';
      }
    };

    const onSiteDocClassifierTemplate = 'is a custom BigID classifier that requires no further configuration.';
    this.returnDocTexts = () => {
      switch (this.classifier._id) {
        case classifiersService.DOC_CLASSIFIERS_ENUM.BOARDING_PASS:
          return 'CLASSIFIERS:BOARDING_PASS:DESCRIPTION';
        case classifiersService.DOC_CLASSIFIERS_ENUM.HR_CV:
          return 'CLASSIFIERS:HR_CV:DESCRIPTION';
        case classifiersService.DOC_CLASSIFIERS_ENUM.FINANCIAL_INVOICE:
          return 'CLASSIFIERS:FINANCIAL_INVOICE:DESCRIPTION';
        case classifiersService.DOC_CLASSIFIERS_ENUM.LEGAL_RFP:
          return 'CLASSIFIERS:LEGAL_RFP:DESCRIPTION';
        case classifiersService.DOC_CLASSIFIERS_ENUM.MEDICAL_HEALTH_FORM:
          return 'CLASSIFIERS:MEDICAL_HEALTH_FORM:DESCRIPTION';
        case classifiersService.DOC_CLASSIFIERS_ENUM.MEDICAL_DISCHARGE_SUMMARY:
          return 'CLASSIFIERS:MEDICAL_DISCHARGE_SUMMARY:DESCRIPTION';
        case classifiersService.DOC_CLASSIFIERS_ENUM.FINANCIAL_FINANCIAL_STATEMENT:
          return 'CLASSIFIERS:FINANCIAL_FINANCIAL_STATEMENT:DESCRIPTION';
        case classifiersService.DOC_CLASSIFIERS_ENUM.FINANCIAL_MERGER_ACQUISITION:
          return 'CLASSIFIERS:FINANCIAL_MERGER_ACQUISITION:DESCRIPTION';
        case classifiersService.DOC_CLASSIFIERS_ENUM.LEGAL_RFI:
          return 'CLASSIFIERS:LEGAL_RFI:DESCRIPTION';
        case classifiersService.DOC_CLASSIFIERS_ENUM.LEGAL_RENTAL_AGREEMENT:
          return 'CLASSIFIERS:LEGAL_RENTAL_AGREEMENT:DESCRIPTION';
        case classifiersService.DOC_CLASSIFIERS_ENUM.IP_PATENT:
          return 'CLASSIFIERS:IP_PATENT:DESCRIPTION';
        case classifiersService.DOC_CLASSIFIERS_ENUM.LEGAL_INSURANCE_POLICY_APPLICATION_FORM:
          return 'CLASSIFIERS:LEGAL_INSURANCE_POLICY_APPLICATION_FORM:DESCRIPTION';
        case classifiersService.DOC_CLASSIFIERS_ENUM.HR_CRIMINAL_RECORD_CHECK:
          return 'CLASSIFIERS:HR_CRIMINAL_RECORD_CHECK:DESCRIPTION';
        case classifiersService.DOC_CLASSIFIERS_ENUM.LEGAL_NON_DISCLOSURE_AGREEMENT:
          return 'CLASSIFIERS:LEGAL_NON_DISCLOSURE_AGREEMENT:DESCRIPTION';
        case classifiersService.DOC_CLASSIFIERS_ENUM.HR_EMPLOYMENT_AGREEMENT:
          return 'CLASSIFIERS:HR_EMPLOYMENT_AGREEMENT:DESCRIPTION';
        case classifiersService.DOC_CLASSIFIERS_ENUM.HR_STOCK_OPTION_AGREEMENT:
          return 'CLASSIFIERS:HR_STOCK_OPTION_AGREEMENT:DESCRIPTION';
        case classifiersService.DOC_CLASSIFIERS_ENUM.FINANCIAL_CREDIT_CARD_APPLICATION_FORM:
          return 'CLASSIFIERS:FINANCIAL_CREDIT_CARD_APPLICATION_FORM:DESCRIPTION';
        case classifiersService.DOC_CLASSIFIERS_ENUM.FINANCIAL_INCOME_TAX_RETURN:
          return 'CLASSIFIERS:FINANCIAL_INCOME_TAX_RETURN:DESCRIPTION';
        case classifiersService.DOC_CLASSIFIERS_ENUM.EDUCATION_ACADEMIC_TRANSCRIPT:
          return 'CLASSIFIERS:EDUCATION_ACADEMIC_TRANSCRIPT:DESCRIPTION';
        default:
          return this.classifier._id + ' ' + onSiteDocClassifierTemplate || 'CLASSIFIERS:DOC:DESCRIPTION';
      }
    };

    this.getTranslationKey = () => {
      if (!this.classifier) {
        return;
      }

      if (this.classifier.type === classifiersService.CLASSIFIER_ALGORITHMS.DOC) {
        return this.returnDocTexts();
      } else {
        return this.returnNerTexts();
      }
    };
  },
});
