import { BigidBody1, SecondaryButton } from '@bigid-ui/components';
import { CustomDashboardGenerator, CustomDashboardGeneratorProps } from '@bigid-ui/custom-dashboard';
import { BigidGridColumnTypes } from '@bigid-ui/grid';
import { dateTimeService } from '@bigid-ui/i18n';
import { styled } from '@mui/material';
import React, { FC, useEffect, useState } from 'react';
import {
  AppAnnotations,
  getAppAnnotations,
  AppIntegrationSettings,
  AppIntegrationSettingsTabConfig,
  AppIntegrationSettingsTabMetadataConfig,
  AppIntegrationSettingsTabWidgetConfig,
  WidgetTypes,
} from '../DataCatalogService';
import { getCustomAppInfo } from '../../CustomApp/utils/CustomAppUtils';
import { CONFIG } from '../../../../config/common';
import { $state } from '../../../services/angularServices';

export type AppIntegrationTabProps = {
  tabSettings: AppIntegrationSettingsTabConfig;
  appId: string;
  appName: string;
  fullyQualifiedName: string;
  deepLinkPath?: string;
};

type WidgetAndObjectConfig = {
  appId: string;
  fullyQualifiedName: string;
  widgetConfig: AppIntegrationSettingsTabWidgetConfig;
  transformValue?: (value: string) => string;
};

export const validateAppTabSettings = (settings: AppIntegrationSettings) => {
  return !!settings?.objectDetails?.tabs?.length;
};

const getAnnotationsFilterQueryString = (fullyQualifiedName: string, annotationsFilter?: string): string => {
  return 'fully_qualified_name=' + fullyQualifiedName + (annotationsFilter ? ' AND ' + annotationsFilter : '');
};

const AppIntegrationWidgetsContainer = styled('ul')({
  listStyle: 'none',
  maxWidth: '100%',
  padding: 0,
  overflowY: 'auto',
  '& > li': {
    marginBottom: 20,
    overflowX: 'auto',
  },
});

const WidgetLabel = styled('div')({
  fontWeight: 'bold',
  display: 'inline-block',
  paddingRight: 10,
});

const DeepLinkContainer = styled('div')({
  textAlign: 'right',
  padding: '0 10px',
});

const useAnnotations = (
  widgetConfig: AppIntegrationSettingsTabWidgetConfig,
  appId: string,
  fullyQualifiedName: string,
) => {
  const [data, setData] = useState<AppAnnotations[]>([]);

  useEffect(() => {
    getAppAnnotations(appId, {
      filter: getAnnotationsFilterQueryString(fullyQualifiedName, widgetConfig.annotationsFilter),
    }).then(res => {
      setData(res);
    });
  }, [appId, fullyQualifiedName, widgetConfig.annotationsFilter]);
  return data;
};

export const KeyValueWidgetComponent = ({
  appId,
  fullyQualifiedName,
  widgetConfig,
  transformValue,
}: WidgetAndObjectConfig) => {
  const data = useAnnotations(widgetConfig, appId, fullyQualifiedName);

  return (
    <BigidBody1>
      {data.map((item: any) =>
        widgetConfig.metadata.map(el => {
          if (!item.annotations[el.key]) return null;
          return (
            <div key={el.key}>
              <WidgetLabel>{el.label}:</WidgetLabel>
              {transformValue ? transformValue(item.annotations[el.key]) : item.annotations[el.key]}
            </div>
          );
        }),
      )}
    </BigidBody1>
  );
};

const widgetMapping: Record<
  WidgetTypes,
  {
    component: FC<any>;
    getProps?: (widgetConfig: AppIntegrationSettingsTabWidgetConfig, appId: string, fullyQualifiedName: string) => any;
  }
> = {
  [WidgetTypes.DATE]: {
    component: KeyValueWidgetComponent,
    getProps: (widgetConfig, appId, fullyQualifiedName) => {
      return {
        widgetConfig,
        appId,
        fullyQualifiedName,
        transformValue: dateTimeService.dateLong,
      };
    },
  },
  [WidgetTypes.TEXT]: {
    component: KeyValueWidgetComponent,
    getProps: (widgetConfig, appId, fullyQualifiedName) => {
      return {
        widgetConfig,
        appId,
        fullyQualifiedName,
      };
    },
  },
  [WidgetTypes.GRID]: {
    component: CustomDashboardGenerator,
    getProps: (widgetConfig, appId, fullyQualifiedName) => {
      const widgetId = 'widget_1';
      const dashboardConfigsProps: CustomDashboardGeneratorProps = {
        globalParams: {},
        config: {
          widgets: [
            {
              id: widgetId,
              header: {
                title: widgetConfig?.title || '',
              },
              showFrame: true,
              widget: {
                catalog: {
                  filters: [] as any,
                  tableHeight: '50vh',
                },
              },
            },
          ],
          layout: {
            direction: 'column',
            spacing: 1,
            layoutSlots: [{ size: 12, widgetId }],
          },
        },
        catalogGridConfig: {
          showSortingControls: false,
          fetchData: async () => {
            const res = await getAppAnnotations(appId, {
              filter: getAnnotationsFilterQueryString(fullyQualifiedName, widgetConfig.annotationsFilter),
            });
            const data =
              res?.map((item: any) => {
                return item?.annotations;
              }) || [];
            return {
              data,
              totalCount: data.length,
            };
          },
          columns: widgetConfig?.metadata.map((column: AppIntegrationSettingsTabMetadataConfig) => ({
            title: column.label,
            name: column.key,
            isListColumn: true,
            type: BigidGridColumnTypes.TEXT,
            getCellValue: (obj: any) => obj[column.key],
          })),
        },
      };
      return dashboardConfigsProps;
    },
  },
};

export const AppIntegrationTab: FC<AppIntegrationTabProps> = ({
  tabSettings,
  appId,
  appName,
  fullyQualifiedName,
}: AppIntegrationTabProps) => {
  const [appInfo, setAppInfo] = useState<any>();

  useEffect(() => {
    getCustomAppInfo(appId).then(setAppInfo);
  }, [appId]);

  return (
    <AppIntegrationWidgetsContainer>
      {tabSettings?.deepLinkPath && appInfo && (
        <li>
          <DeepLinkContainer>
            <SecondaryButton
              size={'medium'}
              onClick={() => {
                $state.go(CONFIG.states.CUSTOM_APP, {
                  id: appInfo?.id,
                  appRoute: tabSettings?.deepLinkPath + encodeURI(fullyQualifiedName),
                });
              }}
              text={`View in ${tabSettings.title}`}
            />
          </DeepLinkContainer>
        </li>
      )}
      {tabSettings?.widgets?.map((widgetConfig: AppIntegrationSettingsTabWidgetConfig, key: number) => {
        const Component = widgetMapping[widgetConfig.widgetType].component;
        const widgetProps = widgetMapping[widgetConfig.widgetType].getProps(widgetConfig, appName, fullyQualifiedName);
        return (
          <li key={key}>
            <Component {...widgetProps} />
          </li>
        );
      })}
    </AppIntegrationWidgetsContainer>
  );
};
