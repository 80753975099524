import './labeling.component.scss';
import { module } from 'angular';
import template from './labeling.component.html';
import labelingSettingsModalTemplate from './modal/settings.template.html';
import { APPLICATIONS_PERMISSIONS } from '@bigid/permissions';
import { isPermitted } from '../../react/services/userPermissionsService';
const app = module('app');

app.component('labeling', {
  template,
  controller: [
    '$timeout',
    '$element',
    '$uibModal',
    '$translate',
    '$interval',
    '$scope',
    'labelingService',
    'DeleteConfirmation',
    'scansService',
    'notificationService',
    function (
      $timeout,
      $element,
      $uibModal,
      $translate,
      $interval,
      $scope,
      labelingService,
      DeleteConfirmation,
      scansService,
      notificationService,
    ) {
      const GET_SCAN_BY_ID_INTERVAL = 5000;

      const SCAN_STATE_COMPLETED = 'Completed';

      const LABELING_DATA_LIST_ID = 'labelingDataList';
      const LABELING_DATA_CONTAINER_BODY_ID = 'labelingContentBody';
      const LABELING_DATA_LIST_ITEM_ID = 'label';

      const MIP = 'MIP';
      const GDRIVE = 'GDRIVE';

      const dataListContainer = $element[0].querySelector(`#${LABELING_DATA_LIST_ID}`);
      const contentBodyContainer = $element[0].querySelector(`#${LABELING_DATA_CONTAINER_BODY_ID}`);

      let isFormDirty = false;

      this.labels = [];
      this.label = {};
      this.editModeOn = false;
      this.createModeOn = false;
      this.labelName = '';
      this.labelForm = {};
      this.userQuery = '';

      this.getScanByIdInterval = null;

      const killGetScanByIdInterval = () => {
        $interval.cancel(this.getScanByIdInterval);
        this.getScanByIdInterval = null;
      };

      /* API calls --start */

      const onCreateLabel = () => {
        resetListSelection();

        this.label = {};

        this.createModeOn = true;
        this.editModeOn = false;

        this.labelName = '';

        resetFormState();
      };

      const onEditLabel = label => {
        resetListSelection();
        setListSelection(label.name);

        this.label = angular.copy(label);
        this.labelName = angular.copy(this.label.name);

        this.editModeOn = true;
        this.createModeOn = false;

        resetFormState();
      };

      const fetchLabels = (scrollTo = '', entityToProceed = null) => {
        this.onDataLoading();

        labelingService.getLabels().then(
          result => {
            this.labels = result.data.labels.sort(alphaSort);

            this.onDataLoaded();

            if (entityToProceed !== null) {
              if (Object.keys(entityToProceed).length === 0 && entityToProceed.constructor === Object) {
                onCreateLabel();
              } else {
                onEditLabel(entityToProceed);
              }
            } else {
              if (this.editModeOn) {
                resetListSelection();
                setListSelection(this.labelName);

                $timeout(() => {
                  const index = this.labels.findIndex(label => label.name === this.labelName),
                    dataListItem = $element[0].querySelector(`#${LABELING_DATA_LIST_ITEM_ID}-${index}`);

                  if (dataListItem !== null && index !== -1) {
                    dataListContainer.scrollTop = index * dataListItem.offsetHeight;
                  }
                }, 0);
              }
            }

            $timeout(() => {
              if (!dataListContainer) return;
              switch (scrollTo) {
                case 'top':
                  dataListContainer.scrollTop = 0;
                  break;
                case 'bottom':
                  dataListContainer.scrollTop = dataListContainer.scrollHeight;
                  break;
                default:
                  if (entityToProceed !== null) {
                    if (Object.keys(entityToProceed).length === 0 && entityToProceed.constructor === Object) {
                      dataListContainer.scrollTop = 0;
                    } else {
                      const index = this.labels.findIndex(label => label.name === entityToProceed.name),
                        dataListItem = $element[0].querySelector(`#${LABELING_DATA_LIST_ITEM_ID}-${index}`);

                      if (dataListItem !== null && index !== -1) {
                        dataListContainer.scrollTop = index * dataListItem.offsetHeight;
                      }
                    }
                  }
              }
            }, 0);
          },
          () => {
            this.onDataLoaded();

            $translate('API:MESSAGE:COMMON_ERROR').then(translation => {
              notificationService.error(translation);
            });
          },
        );
      };

      const createLabel = (data, entityToProceed = null) => {
        labelingService.createLabel(data).then(
          result => {
            $translate('API:MESSAGE:COMMON_POST_SUCCESS').then(translation => {
              notificationService.success(translation);
            });

            this.editModeOn = true;
            this.createModeOn = false;

            this.labelName = angular.copy(this.label.name);

            isFormDirty = false;

            this.labelForm.$submitted = false;

            if (contentBodyContainer !== null) {
              contentBodyContainer.scrollTop = 0;
            }

            fetchLabels('', entityToProceed);
          },
          () => {
            $translate('API:MESSAGE:COMMON_ERROR').then(translation => {
              notificationService.error(translation);
            });
          },
        );
      };

      const updateLabel = (id, data, entityToProceed = null) => {
        labelingService.updateLabel(id, data).then(
          result => {
            $translate('API:MESSAGE:COMMON_PUT_SUCCESS').then(translation => {
              notificationService.success(translation);
            });

            this.labelName = angular.copy(this.label.name);

            if (contentBodyContainer !== null) {
              contentBodyContainer.scrollTop = 0;
            }

            isFormDirty = false;

            this.labelForm.$submitted = false;

            fetchLabels('', entityToProceed);
          },
          () => {
            $translate('API:MESSAGE:COMMON_ERROR').then(translation => {
              notificationService.error(translation);
            });
          },
        );
      };

      const getScanById = id => {
        scansService
          .getScanData(id)
          .then(
            result => {
              const scan = result[0];

              if (scan.state === SCAN_STATE_COMPLETED && typeof scan.errorMessage != 'undefined') {
                notificationService.success(scan.errorMessage);
                killGetScanByIdInterval();

                fetchLabels('', this.label);
              }
            },
            () => {
              $translate('LABELING:API:REFRESH_LABELS:FAILURE').then(translation => {
                notificationService.error(translation);
              });

              killGetScanByIdInterval();
            },
          )
          .catch(() => {
            $translate('API:MESSAGE:COMMON_ERROR').then(translation => {
              notificationService.error(translation);
            });

            killGetScanByIdInterval();
          });
      };

      /* API calls --end */

      /* component methods --start */

      const onUserLeavesForm = async (resolveCallback, rejectCallback) => {
        const closeButtonText = await $translate('BUTTONS:NO');
        const actionButtonText = await $translate('BUTTONS:YES');
        const headerText = await $translate('APP:COMMON:LEAVE_CONFIRMATION_HEADER');
        const bodyText = await $translate('APP:COMMON:LEAVE_CONFIRMATION_TEXT');

        const modalOptions = { closeButtonText, actionButtonText, headerText, bodyText };

        DeleteConfirmation.showModal({}, modalOptions).then(resolveCallback, rejectCallback);
      };

      const resetListSelection = (except = '') => {
        for (let i = 0, len = this.labels.length; i < len; i++) {
          if (this.labels[i].name === except) {
            continue;
          }

          this.labels[i]['selected'] = false;
        }
      };

      const setListSelection = name => {
        for (let i = 0, len = this.labels.length; i < len; i++) {
          if (this.labels[i].name === name) {
            this.labels[i]['selected'] = true;
            break;
          }
        }
      };

      const resetFormState = () => {
        if (
          typeof this.labelForm != 'undefined' &&
          typeof this.labelForm.$setPristine == 'function' &&
          typeof this.labelForm.$setUntouched == 'function'
        ) {
          this.labelForm.$setPristine();
          this.labelForm.$setUntouched();
        } else {
          this.labelForm = {};
        }

        this.labelForm.$submitted = false;

        isFormDirty = false;
      };

      const onSubmitLabel = (entityToProceed = null) => {
        if (!this.labelForm.$valid) return;

        const payload = angular.copy(this.label);

        if (typeof payload.selected != 'undefined') delete payload.selected;

        switch (true) {
          case this.createModeOn:
            createLabel(payload, entityToProceed);
            break;
          case this.editModeOn:
            updateLabel(this.labelName, payload, entityToProceed);
            break;
        }
      };

      const alphaSort = (a, b) => {
        const aName = a.name.toLowerCase(),
          bName = b.name.toLowerCase();

        if (aName < bName) {
          return -1;
        } else if (aName > bName) {
          return 1;
        } else {
          return 0;
        }
      };

      /* component methods --end */

      this.$onInit = () => {
        this.isDeletePermitted = isPermitted(APPLICATIONS_PERMISSIONS.DELETE_MICROSOFT_INFORMATION_PROTECTION.name);
        this.isImportPermitted = isPermitted(APPLICATIONS_PERMISSIONS.IMPORT_MICROSOFT_INFORMATION_PROTECTION.name);
        this.isManagePermitted = isPermitted(APPLICATIONS_PERMISSIONS.MANAGE_MICROSOFT_INFORMATION_PROTECTION.name);

        this.onDataLoading();
        labelingService.getLabels().then(
          result => {
            this.labels = result.data.labels.sort(alphaSort);

            if (this.labels.length > 0) {
              onEditLabel(this.labels[0]);
            }

            this.onDataLoaded();
          },
          () => {
            $translate('API:MESSAGE:COMMON_ERROR').then(translation => {
              notificationService.error(translation);
            });

            this.onDataLoaded();
          },
        );
      };

      this.onSubmit = () => {
        onSubmitLabel();
      };

      this.onCancel = () => {
        this.label = {};

        this.labelName = '';

        resetFormState();
      };

      this.onCreate = () => {
        if (isFormDirty) {
          onUserLeavesForm(
            () => {
              this.labelForm.$submitted = true;

              onSubmitLabel({});
            },
            () => {
              onCreateLabel();
            },
          );
        } else {
          onCreateLabel();
        }
      };

      this.onEdit = label => {
        if (this.label.name !== label.name) {
          if (this.isManagePermitted && isFormDirty) {
            onUserLeavesForm(
              () => {
                this.labelForm.$submitted = true;

                onSubmitLabel(label);
              },
              () => {
                onEditLabel(label);
              },
            );
          } else {
            onEditLabel(label);
          }
        }
      };

      this.onFormChanged = () => {
        isFormDirty = true;
      };

      this.importLabels = () => {
        this.onDataLoading();

        labelingService.importLabels().then(
          result => {
            const { result: scanId } = result.data;

            $translate('LABELING:API:REFRESH_LABELS:REQUESTED').then(translation => {
              notificationService.success(translation);
            });

            this.onDataLoaded();

            scansService
              .getScanData(scanId)
              .then(
                result => {
                  const scan = result[0];

                  if (scan.state === SCAN_STATE_COMPLETED && typeof scan.errorMessage != 'undefined') {
                    notificationService.success(scan.errorMessage);
                    killGetScanByIdInterval();

                    fetchLabels('', this.label);
                  } else {
                    if (this.getScanByIdInterval === null) {
                      this.getScanByIdInterval = $interval(getScanById.bind(null, scanId), GET_SCAN_BY_ID_INTERVAL);
                    }
                  }
                },
                () => {
                  $translate('LABELING:API:REFRESH_LABELS:FAILURE').then(translation => {
                    notificationService.error(translation);
                  });

                  killGetScanByIdInterval();
                },
              )
              .catch(() => {
                $translate('API:MESSAGE:COMMON_ERROR').then(translation => {
                  notificationService.error(translation);
                });

                killGetScanByIdInterval();
              });
          },
          () => {
            this.onDataLoaded();

            $translate('LABELING:API:REFRESH_LABELS:FAILURE').then(translation => {
              notificationService.error(translation);
            });
          },
        );
      };

      this.defineSettings = () => {
        this.onDataLoading();

        labelingService.getLabelerSettings().then(
          result => {
            this.onDataLoaded();

            const formData = result.data;

            $uibModal.open({
              template: labelingSettingsModalTemplate,
              windowClass: 'labeling-settings',
              backdrop: 'static',
              controllerAs: '$ctrl',

              controller: function (formData, labelingService, $translate, notificationService, $uibModalInstance) {
                'ngInject';

                this.labelTypes = [MIP, GDRIVE];
                this.formData = formData || {};
                this.form = {};
                this.isNewSecret = false;
                this.loading = false;

                this.onTypeChange = () => {
                  this.formData.secret = null;
                  this.formData.clientid = null;
                  this.formData.tenantid = null;
                  this.form.$submitted = false;
                };

                this.isTypeSelected = () => {
                  return this.formData?.type === MIP || this.formData?.type === GDRIVE;
                };

                this.getClientIdTitle = () => {
                  if (!this.formData?.type) {
                    return null;
                  }

                  switch (this.formData?.type) {
                    case MIP:
                      return 'LABELING:SETTINGS_MODAL:BODY:CLIENT_ID';
                    case GDRIVE:
                      return 'LABELING:SETTINGS_MODAL:BODY:BATCHED_LABEL_NAME';
                    default:
                      return null;
                  }
                };

                this.getTenantIdTitle = () => {
                  if (!this.formData?.type) {
                    return null;
                  }

                  switch (this.formData?.type) {
                    case MIP:
                      return 'LABELING:SETTINGS_MODAL:BODY:TENANT_ID';
                    case GDRIVE:
                      return 'LABELING:SETTINGS_MODAL:BODY:DOMAIN_ADMIN';
                    default:
                      return null;
                  }
                };

                this.getSecretTitle = () => {
                  if (!this.formData?.type) {
                    return null;
                  }

                  switch (this.formData?.type) {
                    case MIP:
                      return 'LABELING:SETTINGS_MODAL:BODY:SECRET';
                    case GDRIVE:
                      return 'LABELING:SETTINGS_MODAL:BODY:SERVICE_ACCOUNT_CREDS';
                    default:
                      return null;
                  }
                };

                this.onSecretChanged = () => {
                  this.isNewSecret = true;
                };

                this.onClose = () => {
                  $uibModalInstance.close();
                };

                this.onSubmit = () => {
                  if (!this.form.$valid) return;

                  const formData = Object.assign({}, this.formData);

                  if (this.isNewSecret) {
                    formData.isNewSecret = this.isNewSecret;
                  }

                  this.loading = true;

                  labelingService.setLabelerSettings(formData).then(
                    result => {
                      this.loading = false;

                      $translate('API:MESSAGE:COMMON_PUT_SUCCESS').then(translation => {
                        notificationService.success(translation);
                      });

                      $uibModalInstance.close();
                    },
                    () => {
                      this.loading = false;

                      $translate('API:MESSAGE:COMMON_ERROR').then(translation => {
                        notificationService.error(translation);
                      });
                    },
                  );
                };
              },
              resolve: {
                formData: () => formData,
                labelingService: () => labelingService,
                notificationService: () => notificationService,
                $translate: () => $translate,
              },
            });
          },
          () => {
            $translate('API:MESSAGE:COMMON_ERROR').then(translation => {
              notificationService.error(translation);
            });

            this.onDataLoaded();
          },
        );
      };

      this.labelsFilter = query => {
        return label =>
          query != '' ? (label.name.toLowerCase().indexOf(query.toLowerCase()) != -1 ? true : false) : true;
      };

      $scope.$on('$destroy', () => {
        $interval.cancel(this.getScanByIdInterval);
      });
    },
  ],
  bindings: {
    onDataLoading: '&',
    onDataLoaded: '&',
  },
});
