import React, { FC } from 'react';
import { BigidSearchIllustration, BigidEmailTemplateIllustration } from '@bigid-ui/icons';
import { BigidBody1 } from '@bigid-ui/components';
import { useLocalTranslation } from '../translations';
import styled from '@emotion/styled';

export interface EmptyTemplatesProps {
  isEmptyCategory: boolean;
}

const EmptyTemplatesContainer = styled('div')({
  width: '100%',
  paddingTop: 180,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
});

export const EmptyTemplates: FC<EmptyTemplatesProps> = ({ isEmptyCategory }) => {
  const { t } = useLocalTranslation('TemplatesManager.messages');
  return (
    <EmptyTemplatesContainer>
      {isEmptyCategory ? <BigidEmailTemplateIllustration /> : <BigidSearchIllustration />}
      <BigidBody1>{isEmptyCategory ? t('emptyCategory') : t('noMatchingTemplates')}</BigidBody1>
    </EmptyTemplatesContainer>
  );
};
