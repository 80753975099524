import React, { FC, useState, useContext, useRef } from 'react';
import {
  BigidColorsV2,
  BigidBody1,
  BigidMenu,
  BigidMenuProps,
  BigidMenuItem,
  BigidButtonIcon,
  BigidBody3,
  BigidTooltip,
} from '@bigid-ui/components';
import { useLocalTranslation } from '../../../../../translations';
import { BigidOpenTicketIcon, BigidMoreActionIcon, BigidExternalLinkIcon, BigidServiceNowIcon } from '@bigid-ui/icons';
import { Divider, useTheme } from '@mui/material';
import { ActionableInsightsContext } from '../../../../../hooks/ActionableInsightsContext';
import { ContentTypes } from '../TicketModal';
import styled from '@emotion/styled';
import { ACTION_CENTER_PERMISSIONS } from '@bigid/permissions';
import { isPermitted } from '../../../../../../../services/userPermissionsService';
import { getApplicationPreference } from '../../../../../../../services/appPreferencesService';
import { generateDataAid } from '@bigid-ui/utils';
import { ServiceTicketingType } from './CreateTicket';
import { $state } from '../../../../../../../services/angularServices';
import { CONFIG } from '../../../../../../../../config/common';
import { JiraConfiguration } from '../../../../../actionableInsightsService';

const Wrapper = styled.div({
  display: 'flex',
  flexDirection: 'column',
  position: 'relative',
});

const ProjectItem = styled.div({
  width: '100%',
  height: 48,
  borderRadius: 4,
  backgroundColor: BigidColorsV2.gray[150],
  padding: 12,
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  '& *:last-child': {
    marginLeft: 'auto',
  },
  position: 'relative',
});

const DotsIconContainer = styled.div({
  display: 'flex',
  justifyContent: 'center',
  backgroundColor: 'transparent',
});

const FieldLabel = styled.div({
  paddingBottom: 4,
});

export interface ProjectSelectorProps {
  updateModalContent: React.Dispatch<React.SetStateAction<ContentTypes>>;
  updateSelectedJiraProject: (accountName: string) => void;
  tooltipText?: string;
}

export const ProjectSelector: FC<ProjectSelectorProps> = ({
  updateModalContent,
  updateSelectedJiraProject,
  tooltipText,
}) => {
  const theme = useTheme();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const { jiraConfigurations, selectedTicketingService } = useContext(ActionableInsightsContext);
  const menuIconRef = useRef();
  const { t } = useLocalTranslation('Action.jira');

  const handleClick = () => setIsMenuOpen(!isMenuOpen);
  const handleClose = () => setIsMenuOpen(false);
  const handleRoute = () => $state.go(CONFIG.states.ACTION_CENTER_CONFIGURATIONS);

  const isServiceNowPermitted = getApplicationPreference('DSPM_SERVICE_NOW');
  const isUserHasConfigPermission = isPermitted(ACTION_CENTER_PERMISSIONS.CREATE_CONFIGURATIONS.name);
  const premittedListOfServices = jiraConfigurations.filter((config: JiraConfiguration) =>
    (config?.type === ServiceTicketingType.SERVICE_NOW && !isServiceNowPermitted) || config.isOffline ? false : true,
  );

  const iconsSchema: any = {
    [ServiceTicketingType.JIRA]: <BigidOpenTicketIcon />,
    [ServiceTicketingType.SERVICE_NOW]: <BigidServiceNowIcon />,
  };

  const MenuChildren = () => (
    <>
      {premittedListOfServices.map(config => {
        return (
          <BigidMenuItem key={config.id} onClick={() => updateSelectedJiraProject(config.name)}>
            {iconsSchema[config?.type]}
            <BigidTooltip title={config.name}>
              <BigidBody1
                padding={0.5}
                style={{ textOverflow: 'ellipsis', overflow: 'hidden', width: '200px', whiteSpace: 'nowrap' }}
              >
                {config.name}
              </BigidBody1>
            </BigidTooltip>
          </BigidMenuItem>
        );
      })}
      {isUserHasConfigPermission && (
        <>
          <Divider />
          <BigidMenuItem onClick={handleRoute}>
            <BigidBody1 padding={0.5}>{t('common.addProject')}</BigidBody1>
            <BigidExternalLinkIcon />
          </BigidMenuItem>
        </>
      )}
    </>
  );

  const menuProps: BigidMenuProps = {
    open: isMenuOpen,
    onMenuClose: handleClose,
    anchorEl: menuIconRef.current,
    children: <MenuChildren />,
  };
  return (
    <>
      <Wrapper>
        <FieldLabel>
          <BigidBody1>{t('form.serviceDescription')}</BigidBody1>
        </FieldLabel>
        <ProjectItem
          data-aid={generateDataAid('JiraProjectSelector', ['selected-project', selectedTicketingService?.name])}
        >
          {iconsSchema[selectedTicketingService?.type]}
          <BigidBody1 paddingLeft={0.5}>{selectedTicketingService?.name}</BigidBody1>
          <DotsIconContainer ref={menuIconRef}>
            <BigidButtonIcon icon={BigidMoreActionIcon} selected={false} onClick={handleClick} />
          </DotsIconContainer>
        </ProjectItem>
        <BigidMenu {...menuProps} />
      </Wrapper>
      {tooltipText && <BigidBody3 color={theme.palette.bigid.gray500}>{tooltipText}</BigidBody3>}
    </>
  );
};
