import makeStyles from '@mui/styles/makeStyles';
import { BigidColorsV2 } from '@bigid-ui/components';

export const useStyles = makeStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    width: '100%',
  },
  busy: {
    opacity: 0.5,
    pointerEvents: 'none',
  },
  filters: {
    display: 'flex',
    flexWrap: 'wrap',
    padding: '12px 16px',
    overflowY: 'auto',
    flexGrow: 1,
    minHeight: '100px',
  },
  filter: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'center',
  },
  flexBreak: {
    flexBasis: '100%',
    height: 0,
  },
  filter25: {
    flex: '0 0 25%',
    maxWidth: '25%',
  },
  filter50: {
    flex: '0 0 50%',
    maxWidth: '50%',
  },
  filter100: {
    flex: '0 0 100%',
    maxWidth: '100%',
  },
  controls: {
    display: 'flex',
    justifyContent: 'flex-end',
    padding: '12px 16px',
    borderTop: `1px solid ${BigidColorsV2.gray[100]}`,
    '& button': {
      '&:not(:last-child)': {
        marginRight: '8px',
      },
    },
  },
});
