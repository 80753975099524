import React, { FC } from 'react';
import { BigidBody1, BigidColorsV2, BigidHeading1 } from '@bigid-ui/components';
import styled from '@emotion/styled';
import { BigidDataObjectIllustration } from '@bigid-ui/icons';
import { formatNumberCompact } from '../../../../utilities/numericDataConverter';

const TotalFindingsSection = styled('div')`
  display: flex;
  align-items: center;
  gap: 14px;
  border-top: 1px solid ${BigidColorsV2.gray[300]};
  padding-top: 32px;
`;

const TotalFindingsBox = styled('div')`
  display: flex;
  align-items: center;
  gap: 6px;
`;

interface TotalFindingsContainerProps {
  totalFindings: number;
}

export const TotalFindingsContainer: FC<TotalFindingsContainerProps> = ({ totalFindings }) => {
  return (
    <TotalFindingsSection>
      <BigidDataObjectIllustration />
      <TotalFindingsBox>
        <BigidHeading1>{formatNumberCompact(totalFindings)}</BigidHeading1>
        <BigidBody1 fontWeight={700}>Total Findings</BigidBody1>
      </TotalFindingsBox>
    </TotalFindingsSection>
  );
};
