import { useForm } from './useForm';
import { UseValidationOpts, useValidation as useNameValidation } from './useValidation';
import type { BigidFormStateAndHandlers } from '@bigid-ui/components';
import type { MutableRefObject } from 'react';

export const useFormWithValidation = <T>(formStateRef?: MutableRefObject<BigidFormStateAndHandlers>) => {
  const { setErrors, control, ...rest } = useForm<T>(formStateRef);

  return {
    useValidation: (sourceId: string, opts?: UseValidationOpts<{ isAppliedFromDialog: boolean }>) =>
      useNameValidation(sourceId, control, {
        onError: (field, message, { isAppliedFromDialog }) => {
          opts?.onError?.(field, message, { isAppliedFromDialog });
          setErrors({ [field]: message });
        },
        onSuccess: misc => opts?.onSuccess?.(misc),
      }),
    setErrors,
    control,
    ...rest,
  };
};
