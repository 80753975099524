import React, { FC } from 'react';
import styled from '@emotion/styled';
import { generateDataAid } from '@bigid-ui/utils';
import { BigidNotes1, BigidBody1 } from '@bigid-ui/components';
import { AuditTrailPropChangeDetails } from '../../dataCatalogAuditTrailService';
import { getDisplayValue } from '../utils';

export interface AddedProps {
  dataAid: string;
  details: AuditTrailPropChangeDetails[];
}

const Root = styled('div')`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  gap: 24px;
`;

const Prop = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const PropName = styled(BigidNotes1)`
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
`;

const PropValue = styled(BigidBody1)`
  overflow-wrap: break-word;
`;

export const Added: FC<AddedProps> = ({ dataAid, details }) => {
  return (
    <Root data-aid={dataAid}>
      {details.map(({ name, value }, index) => {
        const displayValue = getDisplayValue(value);

        return (
          <Prop key={`${name}-${index}`}>
            <PropName data-aid={generateDataAid(dataAid, ['propName', name])}>{name}</PropName>
            <PropValue data-aid={generateDataAid(dataAid, ['propValue', value])}>{displayValue}</PropValue>
          </Prop>
        );
      })}
    </Root>
  );
};
