import { useState, useEffect, Dispatch } from 'react';
import { createContainer } from 'unstated-next';
import { ACEventGroup, ACActionType, ActionCenterMetadata } from './ActionWorkflow/actionWorkflowTypes';
import { isPermitted } from '../../services/userPermissionsService';
import { ACTION_CENTER_PERMISSIONS } from '@bigid/permissions';
import { fetchActionCenterMetadata } from './ActionWorkflow/actionWorkflowService';
import { notificationService } from '../../services/notificationService';
import { ConfigurationsMetadata } from './ConfigurationManagement/configurationManagementTypes';
import { getConfigurationsMetadata } from './ConfigurationManagement/configurationManagementService';
import { isMultiTenantModeEnabled } from '../../utilities/multiTenantUtils';

const getWorkflowTypes = async (): Promise<ActionCenterMetadata> => {
  try {
    if (!isPermitted(ACTION_CENTER_PERMISSIONS.READ_ACTIONS.name)) {
      throw new Error('Missing action center permissions');
    }
    const {
      data: { data },
    } = await fetchActionCenterMetadata();
    return data;
  } catch (error) {
    notificationService.error('Could not fetch action center metadata');
    console.error(error);
  }
};

const getConfigurationTypes = async (): Promise<ConfigurationsMetadata> => {
  try {
    if (!isPermitted(ACTION_CENTER_PERMISSIONS.READ_CONFIGURATIONS.name)) {
      throw new Error('Missing action center configuration permissions');
    }
    return await getConfigurationsMetadata();
  } catch (error) {
    notificationService.error('Could not fetch action center configurations metadata');
    console.error(error);
  }
};

type MetadataCallback = Dispatch<ActionCenterMetadata | ConfigurationsMetadata>;

interface Metadata {
  shouldFetch: boolean;
  callback: MetadataCallback;
}

const setMetadata = async (actionCenterMetadata: Metadata, configurationsMetadata: Metadata) => {
  if (actionCenterMetadata.shouldFetch) {
    const metadataToUpdate = await getWorkflowTypes();
    actionCenterMetadata.callback(metadataToUpdate);
  }
  if (configurationsMetadata.shouldFetch) {
    const metadataToUpdate = await getConfigurationTypes();
    configurationsMetadata.callback(metadataToUpdate);
  }
};

const useActionCenterMetadata = () => {
  const [actionCenterMetadata, setActionCenterMetadata] = useState<ActionCenterMetadata>();
  const [configurationsMetadata, setConfigurationsMetadata] = useState<ConfigurationsMetadata>();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (isLoading) {
      setMetadata(
        { shouldFetch: !actionCenterMetadata, callback: setActionCenterMetadata as MetadataCallback },
        { shouldFetch: !configurationsMetadata, callback: setConfigurationsMetadata as MetadataCallback },
      ).finally(() => setIsLoading(false));
    }
  }, [isLoading, actionCenterMetadata, configurationsMetadata]);

  return { isLoading, actionCenterMetadata, configurationsMetadata };
};

export const ActionCenterMetadataContainer = createContainer(useActionCenterMetadata);
