import { analyticsService } from '../../../services/analyticsService';

export const trackLegalEntities = (eventType: string, eventData?: Record<string, unknown>) => {
  analyticsService.trackManualEvent(eventType, eventData);
};

export enum LegalEntitiesTrackingEvents {
  //status clicks
  SET_STATUS_ACTIVE_CLICK = 'LEGAL_ENTITIES.SET_STATUS_ACTIVE_CLICK',
  SET_STATUS_INACTIVE_CLICK = 'LEGAL_ENTITIES.SET_STATUS_INACTIVE_CLICK',
  SET_STATUS_DRAFT_CLICK = 'LEGAL_ENTITIES.SET_STATUS_DRAFT_CLICK',
  SET_STATUS_UNDER_REVIEW_CLICK = 'LEGAL_ENTITIES.SET_STATUS_UNDER_REVIEW_CLICK',
  //grid action
  DELETE_LEGAL_ENTITY_INLINE_ACTION = 'LEGAL_ENTITIES.DELETE_LEGAL_ENTITY_INLINE_ACTION',
  EDIT_LEGAL_ENTITY_INLINE_ACTION = 'LEGAL_ENTITIES.EDIT_LEGAL_ENTITY_INLINE_ACTION',
  VIEW_LEGAL_ENTITY_INLINE_ACTION = 'LEGAL_ENTITIES.VIEW_LEGAL_ENTITY_INLINE_ACTION',
  //general actions
  CREATE_LEGAL_ENTITY_ACTION = 'LEGAL_ENTITIES.CREATE_LEGAL_ENTITY_ACTION',
  EDIT_LEGAL_ENTITY_ACTION = 'LEGAL_ENTITIES.EDIT_LEGAL_ENTITY_ACTION',
  CREATE_SUB_ENTITY_ACTION = 'LEGAL_ENTITIES.CREATE_SUB_ENTITY_ACTION',
  //modal clicks
  DELETE_LEGAL_ENTITY_DIALOG_DELETE_CLICK = 'LEGAL_ENTITIES.DELETE_LEGAL_ENTITY_DIALOG_DELETE_CLICK',
  DELETE_LEGAL_ENTITY_DIALOG_CANCEL_CLICK = 'LEGAL_ENTITIES.DELETE_LEGAL_ENTITY_DIALOG_CANCEL_CLICK',
  CREATE_LEGAL_ENTITY_DIALOG_ADD_CLICK = 'LEGAL_ENTITIES.CREATE_LEGAL_ENTITY_DIALOG_ADD_CLICK',
  CREATE_LEGAL_ENTITY_DIALOG_CANCEL_CLICK = 'LEGAL_ENTITIES.CREATE_LEGAL_ENTITY_DIALOG_CANCEL_CLICK',
  EDIT_LEGAL_ENTITY_DIALOG_ADD_CLICK = 'LEGAL_ENTITIES.EDIT_LEGAL_ENTITY_DIALOG_ADD_CLICK',
  EDIT_LEGAL_ENTITY_DIALOG_CANCEL_CLICK = 'LEGAL_ENTITIES.EDIT_LEGAL_ENTITY_DIALOG_CANCEL_CLICK',
  CREATE_SUB_ENTITY_DIALOG_ADD_CLICK = 'LEGAL_ENTITIES.CREATE_SUB_ENTITY_DIALOG_ADD_CLICK',
  CREATE_SUB_ENTITY_DIALOG_CANCEL_CLICK = 'LEGAL_ENTITIES.CREATE_SUB_ENTITY_DIALOG_CANCEL_CLICK',
}
