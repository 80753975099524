import { module } from 'angular';
const app = module('app');

app.factory('riskConfigurationService', [
  '$http',
  'appSettings',
  function ($http, appSettings) {
    return {
      getRiskProfiles: function getRiskProfiles() {
        return $http.get(`${appSettings.serverPath}/${appSettings.version}/risk/profiles`).then(({ data }) => data);
      },
      updateRiskProfile: function updateRiskProfile(profileId, body) {
        return $http
          .put(`${appSettings.serverPath}/${appSettings.version}/risk/profiles/${profileId}`, body)
          .then(({ data }) => data);
      },
      getRiskFields: function getRiskFields(profileId, componentName, skip = 0, limit = 1000, showAll = true) {
        return $http
          .get(
            `${appSettings.serverPath}/${appSettings.version}/risk/${profileId}/fields/${componentName}?skip=${skip}&limit=${limit}&showAll=${showAll}`,
          )
          .then(({ data }) => data);
      },
      saveLastSnapshot: function saveLastSnapshot(riskConfigurationData) {
        return $http
          .put(
            appSettings.serverPath + '/' + appSettings.version + '/risk_snapshots/update_last_snapshot',
            riskConfigurationData.risk_configurations[0],
          )
          .then(function (response) {
            return response.data;
          });
      },
      updateRiskField: function updateRiskField(profileId, componentName, body) {
        return $http
          .put(`${appSettings.serverPath}/${appSettings.version}/risk/${profileId}/fields/${componentName}`, body)
          .then(({ data }) => data);
      },
      calcRisk: function calcRisk(riskConfigurationData) {
        return $http
          .post(appSettings.serverPath + '/' + appSettings.version + '/risk_snapshots/calc_risk', riskConfigurationData)
          .then(function (response) {
            return response.data;
          });
      },
    };
  },
]);
