import React, { FC, ReactElement, ReactHTMLElement, ReactNode } from 'react';
import { BigidBody1, BigidPieChart, PieChartData } from '@bigid-ui/components';
import styled from '@emotion/styled';

type AlignITemsDirection = 'start' | 'end' | 'center';

interface PieInsightChartProps {
  dataAid?: string;
  dataAidLabels?: string;
  title?: string;
  data: PieChartData<string>[];
  total?: number;
  withTooltips?: boolean;
  showZeroedTotal?: boolean;
  showZeroedData?: boolean;
  entityName?: string;
  disableFiltering: boolean;
  alignItems?: AlignITemsDirection;
  size?: any;
  insightSubTitleContent?: ReactElement;
}

const InsightsSubTitle = styled(BigidBody1)`
  margin-bottom: 18px;
  font-weight: bold;
`;

const PiiChartRow = styled('div')<{ alignItems: AlignITemsDirection }>`
  display: flex;
  gap: 16px;
  align-items: ${({ alignItems }) => alignItems};
`;

const PiiChartLabelsContainer = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 6px;
  font-size: 12px;
`;

const PiiChartLabelsRow = styled('div')`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const PiiChartLabelsBadge = styled('div')`
  width: 12px;
  height: 12px;
  border-radius: 50%;
`;

export const PieInsightChart: FC<PieInsightChartProps> = ({
  title,
  data,
  total,
  withTooltips,
  showZeroedTotal,
  showZeroedData,
  entityName = '',
  disableFiltering,
  dataAid = 'PieInsightChart',
  dataAidLabels = 'PieInsightChartLabels',
  alignItems = 'start',
  size,
  insightSubTitleContent: InsightSubTitleContent,
}) => {
  return (
    <div data-aid={dataAid}>
      {(title || InsightSubTitleContent) && <InsightsSubTitle>{InsightSubTitleContent || title}</InsightsSubTitle>}
      <PiiChartRow alignItems={alignItems}>
        <BigidPieChart
          data={data}
          total={total}
          withTooltips={withTooltips}
          showZeroedTotal={showZeroedTotal}
          showZeroedData={showZeroedData}
          entityName={entityName}
          disableFiltering={disableFiltering}
          size={size}
        />
        <PiiChartLabelsContainer data-aid={dataAidLabels}>
          {data?.map((row, index) => (
            <PiiChartLabelsRow key={index}>
              <PiiChartLabelsBadge style={{ backgroundColor: row.color }}></PiiChartLabelsBadge>
              <span>
                {row.value} {row.category}
              </span>
            </PiiChartLabelsRow>
          ))}
        </PiiChartLabelsContainer>
      </PiiChartRow>
    </div>
  );
};
