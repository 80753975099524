import { Dispatch } from 'react';
import { pick, isEmpty, last } from 'lodash';
import { ToastData, API_METHODS, ChangeRouteData } from '@bigid/app-fw-ui-sdk';
import { httpService } from '../../../../../services/httpService';
import { notificationService } from '../../../../../services/notificationService';
import { $state, $stateParams } from '../../../../../services/angularServices';

import { sessionStorageService } from '../../../../../../common/services/sessionStorageService';
import { getAllPermissions } from '../../../../../services/userPermissionsService';
import { pageHeaderService } from '../../../../../../common/services/pageHeaderService';
import { Action } from '../CustomAppUI';
import { BiEventsTypes, legacySendBiEvent, analyticsService, BiEvents } from '../../../../../services/analyticsService';
import { capitalizeAndConvertToSnakeCase } from '@bigid-ui/utils';
import { AppInfo } from '../../../utils/CustomAppTypes';
import { APPLICATIONS_PERMISSIONS } from '@bigid/permissions';
import { getApplicationPreference } from '../../../../../services/appPreferencesService';
import { SystemEvents, systemEventsEmitter } from '../../../../../services/systemEvents';

interface ActionsLogicProps {
  data: any;
  dispatch: Dispatch<Action>;
  appInfo: AppInfo;
}

type ApplicationsRouteParams = {
  id: string;
  appRoute: string;
  queryParams: string;
};

export type ActionLogicFunction = (props: ActionsLogicProps) => any | Promise<any>;

export const getToken: ActionLogicFunction = async () =>
  ((sessionStorageService.get('bigIdTokenID') as string) || '').split('"').join('');

export const useToast: ActionLogicFunction = async ({ data }) => {
  const { severity, message, options = {} }: ToastData = data;
  if ([isEmpty(severity), isEmpty(message)].some(i => i)) {
    return Promise.reject(`severity and/or message can't be empty`);
  }

  notificationService[severity](message, options);
  return true;
};

export const openDialog: ActionLogicFunction = ({ data, dispatch }) => {
  const closeDialog = () => {
    const action = {
      type: 'openDialog',
      payload: { isOpen: false },
    };
    dispatch(action);
  };

  return new Promise(resolve => {
    const { title, buttons, maxWidth, borderBottom, borderTop, message } = data;
    const onClose = () => {
      resolve(false);
      closeDialog();
    };

    const onButtonClick = (value: string | boolean) => {
      resolve(value);
      closeDialog();
    };

    const actionToDispatch: Action = {
      type: 'openDialog',
      payload: { title, message, buttons, maxWidth, borderBottom, borderTop, onClose, onButtonClick, isOpen: true },
    };

    dispatch(actionToDispatch);
  });
};

export const getPermissions: ActionLogicFunction = async ({ appInfo }) => {
  const appName = (appInfo.name || '').replace(/ /g, '_').toLowerCase();
  const generalCustomAppPermissions = new Set([
    APPLICATIONS_PERMISSIONS.READ_TPA_CUSTOM_APPS.name,
    APPLICATIONS_PERMISSIONS.MANAGE_TPA_CUSTOM_APPS.name,
    APPLICATIONS_PERMISSIONS.DELETE_AND_ADD_CUSTOM_APPS.name,
  ]);
  const permissions = getAllPermissions();
  const granularPermissionsArr = Object.keys(permissions).filter(
    permission =>
      isValidAppPermission(permission.toLowerCase(), appName) || generalCustomAppPermissions.has(permission),
  );
  return granularPermissionsArr;
};

const isValidAppPermission = (permission: string, appName: string): boolean => {
  const appNameFromPermission = permission.split('.');
  return appNameFromPermission[2] === appName;
};

export const getApiUrl: ActionLogicFunction = async () => window.location.origin || '';

export const getBffUrl: ActionLogicFunction = async ({ appInfo: { baseUrl, isUseProxy, id } }) => {
  const getProxyBffUrl = (customAppId: string) => `/proxy/tpa/api/${customAppId}`;
  return isUseProxy ? getProxyBffUrl(id) : baseUrl;
};

export const bigidAPI: ActionLogicFunction = async ({ data: apiRequestData }) => {
  const { path, method, params = {}, data = {} } = apiRequestData;
  if ([isEmpty(path), isEmpty(method)].some(i => i)) {
    return Promise.reject(`path and/or method can't be empty`);
  }
  try {
    let res = {};
    switch (method) {
      case API_METHODS.GET:
        res = await httpService.fetch(path, params);
        break;
      case API_METHODS.PUT:
        res = await httpService.put(path, params);
        break;
      case API_METHODS.POST:
        res = await httpService.post(path, data, params);
        break;
      case API_METHODS.DELETE:
        res = await httpService.delete(path, data);
        break;
    }
    return pick(res, ['data', 'headers', 'status', 'statusText']);
  } catch (error) {
    return Promise.reject(error.message);
  }
};

const makeInnerAppUrl = (appId: string, appRoute: string, appSearch: string) => {
  const normRoute = !appRoute || appRoute === '/' ? '' : appRoute;
  const normSearch = !appSearch ? '' : appSearch;
  return `/customApp/${appId}${normRoute}${normSearch}`;
};

export const changeRoute: ActionLogicFunction = async ({ data }: { data: ChangeRouteData }) => {
  const { url, params = {} } = data;

  if (isEmpty(url)) {
    return Promise.reject(`url can't be empty`);
  }

  if (params.withAppInnerNavigation) {
    const appId = $state.params.id;
    if (appId) {
      window.location.hash = makeInnerAppUrl(appId, url, params.search);
      return 'OK';
    }
  }

  $state.go(url, params);
  return 'OK';
};

export const getRouteParams: ActionLogicFunction = (): ApplicationsRouteParams => {
  const { id, appRoute } = $stateParams;
  const routeParams: ApplicationsRouteParams = { id, appRoute, queryParams: '' };
  if (window.location.href.includes('?')) {
    routeParams.queryParams = last(window.location.href.split('?'));
  }

  return routeParams;
};

export const hidePageHeader: ActionLogicFunction = ({ data }): void => {
  pageHeaderService.setIsHidden(data);
};

export const sendDataLakeEvent: ActionLogicFunction = async ({ data }) => {
  await legacySendBiEvent(BiEventsTypes.APPS_SDK, data);
};

const canSendAmplitude = (isAppVerified: boolean, eventType: string) => {
  return isAppVerified && eventType && getApplicationPreference('ALLOW_TPA_AMPLITUDE_EVENTS');
};

export const trackPageView: ActionLogicFunction = async ({ data, appInfo }) => {
  if (canSendAmplitude(appInfo.settings?.isVerified, data.eventType))
    analyticsService.trackPageView(`TPA_${appInfo.name}_${data.eventType}` as BiEvents);
};

export const trackManualEvent: ActionLogicFunction = async ({ data, appInfo }) => {
  if (canSendAmplitude(appInfo.settings?.isVerified, data.eventType))
    analyticsService.trackManualEvent(
      capitalizeAndConvertToSnakeCase(`TPA_${appInfo.name}_${data.eventType}`) as BiEvents,
      {
        ...data.eventData,
        customAppEvent: true,
      },
    );
};

export const setLayoutFullWidthState: ActionLogicFunction = async ({ data }) => {
  systemEventsEmitter.emit(SystemEvents.UPDATE_LAYOUT_FULL_WIDTH_STATE, data);
};

export const setAppToken: ActionLogicFunction = async ({ data }) => {
  systemEventsEmitter.emit(SystemEvents.SET_APP_TOKEN, data);
};
