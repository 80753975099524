import { MappedControlsProps, NormalizedMappedControlsProps } from './MappedControls/MappedControlsWidget';
import { comparator } from '../../../react/views/Frameworks/utils';

export function getOptimizedControls(data: MappedControlsProps[]): NormalizedMappedControlsProps[] {
  const frameworkMap = new Map<string, MappedControlsProps[]>();
  data.forEach(control => {
    if (!frameworkMap.has(control.framework_name)) {
      frameworkMap.set(control.framework_name, []);
    }
    frameworkMap.get(control.framework_name).push(control);
  });
  const normalizedMappedControls = Array.from(frameworkMap.entries()).map(([framework_name, controls]) => ({
    widgetFrameworkName: framework_name,
    controls: controls.sort(comparator('name')),
  }));

  return normalizedMappedControls;
}
