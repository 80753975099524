import { useCallback } from 'react';

export function useSetInObjectState<T>(
  setState: React.Dispatch<React.SetStateAction<T>>,
  transformer?: (args: any, currentState: T) => Partial<T>,
) {
  return useCallback(
    (props: any) => setState(prevState => ({ ...prevState, ...transformer(props, prevState) })),
    [setState, transformer],
  );
}
