import React from 'react';
import styled from '@emotion/styled';

export type BrandsNoDataProps = {
  message: string;
};

const Wrapper = styled.div({
  position: 'absolute',
  width: '100%',
  height: '100%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
});

const Message = styled.div({
  position: 'relative',
  top: '-30px',
});

export const BrandsNoData = ({ message }: BrandsNoDataProps) => (
  <Wrapper>
    <Message>{message}</Message>
  </Wrapper>
);
