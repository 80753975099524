import React, { FC, Fragment } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { GettingStartedFooterItem, GettingStartedFooterItemProps, underlineOptions } from './GettingStartedFooterItem';
import { publicUrls } from '../../../config/publicUrls';
import { GettingStartedUITrackingEvent } from '../GettingStartedEventTrackerUtils';
import { LinkColor } from '@bigid-ui/components';
import { BigidHelpingHand, BigidAcademyIcon, BigidPlayIcon } from '@bigid-ui/icons';
import { getFixedT } from '../translations';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    height: 20,
  },
  separator: {
    width: '2px',
    backgroundColor: theme.vars.palette.bigid.gray300,
    height: '100%',
    margin: '0px 15px',
  },
}));

export interface GettingStartedFooterProps {
  items: Array<GettingStartedFooterItemProps>;
}
const footerItemsText = getFixedT('footer');
export const gettingStartedDefaultFooterItems: Array<GettingStartedFooterItemProps> = [
  {
    url: publicUrls.SMALLID_GETTING_STARTED_HELP,
    name: 'main-footer-help',
    biEventName: GettingStartedUITrackingEvent.GETTING_STARTED_GET_HELP_CLICK,
    text: footerItemsText('help'),
    Icon: BigidHelpingHand,
    linkColor: LinkColor.PRIMARY,
    underline: underlineOptions.NONE,
  },
  {
    url: publicUrls.SMALLID_GETTING_STARTED_DOC,
    name: 'main-footer-doc',
    biEventName: GettingStartedUITrackingEvent.GETTING_STARTED_DOCS_CLICK,
    text: footerItemsText('docs'),
    Icon: BigidAcademyIcon,
    linkColor: LinkColor.PRIMARY,
    underline: underlineOptions.NONE,
  },
  {
    url: publicUrls.SMALLID_GETTING_STARTED_VIDEO,
    name: 'main-footer-overview',
    biEventName: GettingStartedUITrackingEvent.GETTING_STARTED_OVERVIEW_CLICK,
    text: footerItemsText('overview'),
    Icon: BigidPlayIcon,
    linkColor: LinkColor.PRIMARY,
    underline: underlineOptions.NONE,
  },
];

export const GettingStartedFooter: FC<GettingStartedFooterProps> = ({ items }) => {
  const classes = useStyles({});

  return (
    <div className={classes.root}>
      {items.map((item, index) => {
        return (
          <Fragment key={item.url}>
            <GettingStartedFooterItem {...item} />
            {items.length < 2 || index + 1 == items.length ? '' : <span className={classes.separator} />}
          </Fragment>
        );
      })}
    </div>
  );
};
