import { BigidFieldFilterOperator, BigidFieldFilter, BigidDropdownOption } from '@bigid-ui/components';
import { UseMetadataSearchStateResponse } from '../useMetadataSearchState';

export enum MetadataSearchFilterCommonFieldName {
  ENTITY_TYPE = '_es_entityType',
}

export type MetadataSearchFilterFieldName = string | MetadataSearchFilterCommonFieldName;

export enum MetadataSearchFilterFieldType {
  OBJECT = 'OBJECT',
  STRING = 'STRING',
  NUMBER = 'NUMBER',
  DATE = 'DATE',
  BOOLEAN = 'BOOLEAN',
  USER = 'USER',
  TAGS = 'TAGS',
  ENTITY_TYPE = 'ENTITY_TYPE',
  HASH_STRING = 'HASH_STRING',
}

export type MetadataSearchFilterConfigValue = {
  value: string | number | boolean;
  count: number;
  displayValue?: string;
  isKeyword?: boolean;
};

export type MetadataSearchFilterConfigValuePreprocessed = BigidDropdownOption;
export interface MetadataSearchFilterConfig {
  fieldName: MetadataSearchFilterFieldName | string;
  specialValueFieldName?: string;
  fieldType: MetadataSearchFilterFieldType;
  multiple?: boolean;
  displayName?: string;
  totalValues: number;
  fieldValues?: MetadataSearchFilterConfigValue[];
  fieldSpecialValues?: MetadataSearchFilterConfigValue[];
}

export interface MetadataSearchFilterConfigPreprocessed
  extends Omit<MetadataSearchFilterConfig, 'fieldValues' | 'fieldSpecialValues'> {
  originalValues?: MetadataSearchFilterConfigValue[];
  values?: MetadataSearchFilterConfigValuePreprocessed[];
  specialValues?: MetadataSearchFilterConfigValuePreprocessed[];
}

export type MetadataSearchFieldFilterOperator = BigidFieldFilterOperator;
export type MetadataSearchFieldFilter = Omit<BigidFieldFilter, 'field'> & {
  field: MetadataSearchFilterFieldName;
  specialField?: MetadataSearchFilterFieldName;
  fieldType: MetadataSearchFilterFieldType;
  isKeyword?: boolean;
  fieldDisplayName?: string;
  fieldDisplayValue?: string;
  originalValue?: BigidFieldFilter['value'];
};

export interface MetadataSearchFilterComponentBaseProps
  extends Pick<UseMetadataSearchStateResponse, 'onFilterSearch' | 'onFilterChange' | 'isFiltersFetching'> {
  dataAid: string;
  value: MetadataSearchFieldFilter;
  config: MetadataSearchFilterConfigPreprocessed;
  focused?: boolean;
}
