import React, { FC, ReactText, useCallback, useEffect, useState } from 'react';
import { BigidBody1, BigidPaper, PrimaryButton } from '@bigid-ui/components';
import { pageHeaderService } from '../../../common/services/pageHeaderService';
import { Divider } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { notificationService } from '../../services/notificationService';
import { DataSourceSelectionGrid } from '../../components/DataSourceSelectionGrid/DataSourceSelectionGrid';
import { $state } from '../../services/angularServices';
import { CONFIG } from '../../../config/common';
import { BigidInfoIcon } from '@bigid-ui/icons';
import { BigidGridRow, FetchDataFunction } from '@bigid-ui/grid';
import { DataSourceModel, DataSourcesResponse } from '../DataSources/DataSourceConnections/DataSourceConnectionTypes';
import { queryService } from '../../services/queryService';
import { httpService } from '../../services/httpService';
import { getSupportedDataSources } from '../../utilities/dataSourcesUtils';
import { createHotspotsReport, getHotspotsDatasources } from './HotspotsReportChart/HotspotsReportChartService';

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    width: '100%',
    height: '100%',
    maxHeight: '100%',
    overflow: 'hidden',
    paddingBottom: 10,
  },
  paper: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: '100%',
    maxHeight: '100%',
    overflow: 'hidden',
  },
  header: {
    padding: 15,
    display: 'flex',
    flexDirection: 'row',
  },
  info: {
    display: 'flex',
    marginTop: 5,
    marginLeft: 15,
  },
  formWrapper: {
    width: '100%',
    overflow: 'scroll',
    padding: 20,
  },
  formPaper: {
    padding: '20px',
  },
}));

export const CreateHotspotsReport: FC = () => {
  const classes = useStyles({});
  const [selected, setSelected] = useState<ReactText[]>([]);

  const handleCreateReport = async () => {
    try {
      await createHotspotsReport({ data_sources: selected });
      notificationService.success('A new Hotspots report task has been created');
      $state.go(
        CONFIG.states.HOTSPOTS_REPORT,
        { selectedTab: 'reports' },
        { reload: false, notify: false, inherit: true },
      );
    } catch ({
      response: {
        data: { message },
      },
    }) {
      notificationService.error('An error has occurred');
      console.error(`An error has occurred: ${message}`);
    }
  };

  useEffect(() => {
    pageHeaderService.setTitle({ pageTitle: 'Create New Hotspots Report' });
  }, []);

  const fetchDataFunction: FetchDataFunction<BigidGridRow> = useCallback(async queryComponents => {
    let data: DataSourceModel[] = [];

    try {
      const gridConfigQuery = queryService.getGridConfigQuery(queryComponents);
      const {
        data: {
          data: { ds_connections, totalCount: count },
        },
      } = await httpService.fetch<{ data: DataSourcesResponse }>(`ds-connections?${gridConfigQuery}`);

      //TMP FIX for init templates
      await getSupportedDataSources();
      data = ds_connections;

      const dss = await getHotspotsDatasources();
      data = data.filter(ds => dss.includes(ds.name));
    } catch (error) {
      console.error(error);
      notificationService.error('Could not fetch data. See logs for more information');
    }

    return Promise.resolve({
      data,
      totalCount: data.length,
    });
  }, []);

  return (
    <div className={classes.root}>
      <BigidPaper classes={{ root: classes.paper }}>
        <div className={classes.header}>
          <PrimaryButton
            dataAid="download-hotspots-report-pdf-button"
            size="medium"
            disabled={selected?.length < 1}
            onClick={handleCreateReport}
            text="Create Report"
          />
          <div className={classes.info}>
            <BigidInfoIcon />
            <BigidBody1>Select data sources to include in the report</BigidBody1>
          </div>
        </div>
        <Divider variant={'fullWidth'} orientation="horizontal" />
        <div className={classes.formWrapper}>
          <BigidPaper classes={{ root: classes.formPaper }}>
            <DataSourceSelectionGrid setSelected={setSelected} fetchDataFunction={fetchDataFunction} />
          </BigidPaper>
        </div>
      </BigidPaper>
    </div>
  );
};
