import type { BigidDialogButtonType, BigidFilter } from '@bigid-ui/components';

export enum SuggestedActionType {
  API = 'api',
  REDIRECT = 'redirect',
}

export enum InitiativeType {
  SUGGESTED_ACTION = 'suggestedAction',
  OPEN_ISSUE = 'openIssue',
}

export enum ToolbarAction {
  DONE = 'done',
  FILTER = 'filter',
}

export type SuggestedActionButtonType = BigidDialogButtonType;

export type SuggestedAction = {
  actionLabel: string;
  actionType: SuggestedActionType;
  limit?: number;
  redirectTo?: string;
  isEnabled: boolean;
};

export type ActionInitiative = {
  initiativeLabel: string;
  count: number;
  actions: SuggestedAction[];
};

export type ActionInitiativeAggregate = ActionInitiative & { type?: InitiativeType };

export type CreateSuggestedActionsItemRequest = {};

export type CreateSuggestedActionsItemQueryParams = { filters: BigidFilter };

export type CreateSuggestedActionsItemResponse = {};

export type GetSuggestedActionsItemRequest = {};

export type GetSuggestedActionsItemResponse = {
  status: string;
  statusCode: number;
  data: {
    suggestedActions: ActionInitiative[];
    openIssues: ActionInitiative[];
    openIssuesTotalCount: number;
    suggestedActionsTotalCount: number;
  };
  message: string;
};
