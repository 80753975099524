import { useEffect, useMemo, useState } from 'react';
import { isArray, isPlainObject } from 'lodash';

export function getSelectedRowsWithActions<T>(rowsWithActions: T[] | { [key: string]: T[] }, selectedTab: string): T[] {
  if (isArray(rowsWithActions)) {
    return (rowsWithActions as T[]) ?? [];
  } else if (isPlainObject(rowsWithActions) && Object.keys(rowsWithActions).includes(selectedTab)) {
    return (rowsWithActions as { [key: string]: T[] })[selectedTab];
  } else return [];
}

export function useSelectedRowsWithActions<T>(rowsWithActions: T[] | { [key: string]: T[] }) {
  const [selectedTab, setSelectedTab] = useState<string>();

  const selectedRowsWithActions = useMemo(() => {
    return getSelectedRowsWithActions(rowsWithActions, selectedTab);
  }, [rowsWithActions, selectedTab]);

  useEffect(() => {
    if (!isArray(rowsWithActions) && isPlainObject(rowsWithActions)) {
      const keys = Object.keys(rowsWithActions);
      if (keys.length > 0) {
        setSelectedTab(keys[0]);
      }
    }
  }, [rowsWithActions]);

  return { selectedTab, setSelectedTab, selectedRowsWithActions };
}
