import { publicUrls } from '../../../../config/publicUrls';
import { DsConnectionFieldSectionsEnum } from '../../DataSourceConfiguration/types';

export const NESTED_FIELD_DELIMITER = '.';

export const DEFAULT_TEST_CONNECTION_TIMEOUT = 10000;

export const PREREQUISITES_REQUIRED_FIELDS = ['authenticationType'];

export const MIN_ERROR_OPTIONS_VISIBLE = 3;

export const MARKDOWN_SELECTOR = '__markdown';

// @info array of section fields to display on top for all data sources based field name
export const REQUIRED_FIELDS_OVERRIDE_BY_NAME: string[] = [];

// @info array of section fields to display at the top for all data sources based on misc.source
export const REQUIRED_FIELDS_OVERRIDE_BY_SOURCE: string[] = ['connectOauth', 'disconnectOauth'];

// @info array of section fields to hide for all data sources
export const HIDDEN_FIELDS_OVERRIDE: string[] = ['friendlyName', 'enabled', 'description', 'tags'];

// @info array of section fields to disable for all data sources in edit mode
export const DISABLED_EDIT_FIELDS_OVERRIDE: string[] = [];

export const COMMON_NON_RESIZABLE_FIELDS_OVERRIDE = ['ocrParameters', 'typeRequestProperties'];

export const REQUIRED_FIELDS_SORT_ORDER = ['name', 'authenticationType'];

export const BIGID_SUPPORT_URL = publicUrls.SUPPORT_BIGID;

export const DEFAULT_MUI_OVERRIDES = {
  disableEnforceFocus: true,
} as const;

export const MAX_TEST_CONNECTION_OBJECTS_TO_DISPLAY = 10;

export const MAX_ERROR_INSIGHTS_TITLE_LENGTH = 52;

export const DS_COLLABORATOR_ROLE_NAME = 'ds-collaborator';

export const DEFAULT_FIELD_SECTION = DsConnectionFieldSectionsEnum.connection;

export const DEFAULT_REDUCER_STATE = {
  showTestResults: false,
  showCollaborationSuccess: false,
  showSaveBeforeClose: false,
  showCollaboration: false,
  isFirstAttempt: true,
};
