import { module } from 'angular';
const app = module('app');

app.factory('ignoredListService', [
  '$http',
  'appSettings',
  function ($http, appSettings) {
    return {
      getIgnoredListItems: function getIgnoredListItems() {
        return $http
          .get(appSettings.serverPath + '/' + appSettings.version + '/ignored_list_items')
          .then(function (response) {
            return response.data;
          });
      },
      createIgnoredListItem: function createIgnoredListItem(data) {
        return $http
          .post(appSettings.serverPath + '/' + appSettings.version + '/ignored_list_items', data)
          .then(function (response) {
            return response.data;
          });
      },
      updateIgnoredListItem: function updateIgnoredListItem(item_id, data) {
        return $http
          .put(appSettings.serverPath + '/' + appSettings.version + '/ignored_list_items/' + item_id, data)
          .then(function (response) {
            return response.data;
          });
      },
      deleteIgnoredListItem: function deleteIgnoredListItem(item_id) {
        return $http
          .delete(appSettings.serverPath + '/' + appSettings.version + '/ignored_list_items/' + item_id)
          .then(function (response) {
            return response.data;
          });
      },
    };
  },
]);
