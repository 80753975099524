import React, { FC } from 'react';
import { styled } from '@mui/material';
import { BigidBody1 } from '@bigid-ui/components';
import { generateDataAid } from '@bigid-ui/utils';
import { AttributesGridRecord } from './AttributesLayout';
import { formatNumberCompact } from '../../../../utilities/numericDataConverter';
import { useLocalTranslation } from './translations';
import { isUndefined } from 'lodash';

interface AttributesHotspotTooltipContentProps {
  dataAid?: string;
  row: AttributesGridRecord;
}

const Root = styled('div')`
  display: flex;
  flex-direction: column;
  padding: 16px;
`;

const Properties = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const Property = styled('div')`
  display: flex;
  align-items: center;
`;

const PropertyName = styled(BigidBody1)`
  margin-right: 4px;
`;

const PropertyValue = styled(BigidBody1)`
  font-weight: bold;
`;

export const AttributesHotspotTooltipContent: FC<AttributesHotspotTooltipContentProps> = ({
  dataAid = 'AttributesHotspotTooltipContent',
  row,
}) => {
  const { t } = useLocalTranslation('hotspot');
  const { id, aggItemName, friendlyName, docCount, findings, categories = [] } = row;

  return (
    <Root data-aid={generateDataAid(dataAid, [id])}>
      <Properties>
        {aggItemName && (
          <Property>
            <PropertyName data-aid={generateDataAid(dataAid, [id, 'property', 'aggItemName', 'name'])}>
              {t('fields.name')}
            </PropertyName>
            <PropertyValue data-aid={generateDataAid(dataAid, [id, 'property', 'aggItemName', 'value'])}>
              {aggItemName}
            </PropertyValue>
          </Property>
        )}
        {friendlyName && (
          <Property>
            <PropertyName data-aid={generateDataAid(dataAid, [id, 'property', 'friendlyName', 'name'])}>
              {t('fields.friendlyName')}
            </PropertyName>
            <PropertyValue data-aid={generateDataAid(dataAid, [id, 'property', 'friendlyName', 'value'])}>
              {friendlyName}
            </PropertyValue>
          </Property>
        )}
        {!isUndefined(docCount) && (
          <Property>
            <PropertyName data-aid={generateDataAid(dataAid, [id, 'property', 'docCount', 'name'])}>
              {t('fields.numOfObjects')}
            </PropertyName>
            <PropertyValue data-aid={generateDataAid(dataAid, [id, 'property', 'docCount', 'value'])}>
              {formatNumberCompact(docCount)}
            </PropertyValue>
          </Property>
        )}
        {!isUndefined(findings) && (
          <Property>
            <PropertyName data-aid={generateDataAid(dataAid, [id, 'property', 'findings', 'name'])}>
              {t('fields.numOfFindings')}
            </PropertyName>
            <PropertyValue data-aid={generateDataAid(dataAid, [id, 'property', 'findings', 'value'])}>
              {formatNumberCompact(findings)}
            </PropertyValue>
          </Property>
        )}
        <Property>
          <PropertyName data-aid={generateDataAid(dataAid, [id, 'property', 'sourceLocation', 'name'])}>
            {t('fields.categories')}
          </PropertyName>
          <PropertyValue data-aid={generateDataAid(dataAid, [id, 'property', 'sourceLocation', 'value'])}>
            {categories?.length > 0 ? categories.join(', ') : t('placeholders.noCategories')}
          </PropertyValue>
        </Property>
      </Properties>
    </Root>
  );
};
