import React, { FC, useMemo, useRef, useState, MouseEvent, useCallback } from 'react';
import { noop } from 'lodash';
import makeStyles from '@mui/styles/makeStyles';
import {
  BigidDialog,
  PrimaryButton,
  SecondaryButton,
  BigidFormStateAndHandlers,
  BigidBody1,
  BigidColorsV2,
} from '@bigid-ui/components';
import { BigidInfoFilledIcon } from '@bigid-ui/icons';
import { ConfigurationForm, ConfigurationFormProps } from './ConfigurationForm';
import { ActionCenterMetadataContainer } from '../useActionCenterMetadata';
import { isPermitted } from '../../../services/userPermissionsService';
import { ACTION_CENTER_PERMISSIONS } from '@bigid/permissions';
import { ConfigurationFormFields, EditConfigurationData } from './configurationManagementTypes';
import { checkIfFormIsDirty, getServiceFormatFromForm } from './configurationManagementUtils';
import { testConnectionConfiguration } from './configurationManagementService';
import { notificationService } from '../../../services/notificationService';

export const CONFIGURATIONS_WARNING = `There are no settings configured. To continue, set at least one configuration setting from the Configuration Management tab.`;

interface ConfigurationDialogProps extends Omit<ConfigurationFormProps, 'formControls' | 'configurationsMetadata'> {
  isOpen: boolean;
  handleClose?: () => void;
  withWarning?: boolean;
}

const useStyles = makeStyles({
  warning: {
    display: 'flex',
    background: BigidColorsV2.purple[100],
    height: 100,
    padding: 16,
    marginBottom: 16,
    borderRadius: 4,
    boxShadow: '0px 0px 1px rgba(0, 0, 0, 0.15)',
  },
  warningMessage: { marginLeft: 8 },
});

export const ConfigurationDialog: FC<ConfigurationDialogProps> = ({
  isOpen,
  handleClose,
  editConfigurationData,
  onSubmit,
  withWarning,
}) => {
  const classes = useStyles();
  const { configurationsMetadata } = ActionCenterMetadataContainer.useContainer();
  const [isSaveEnabled, setIsSaveEnabled] = useState(false);
  const formControls = useRef<BigidFormStateAndHandlers>();

  const handleCloseForm = useCallback(() => {
    handleClose?.();
    setIsSaveEnabled(false);
  }, [handleClose]);

  const handleTestConnection = (initialConfiguration?: EditConfigurationData) => async () => {
    try {
      const formValues = formControls.current.getValues() as ConfigurationFormFields;
      const testConfiguration = getServiceFormatFromForm(formValues, configurationsMetadata);

      const { success, error } = await testConnectionConfiguration({
        ...testConfiguration,
        ...(initialConfiguration && { id: initialConfiguration.id }),
      });
      if (!success) {
        throw new Error(error);
      }
      const isFormDirty = checkIfFormIsDirty(initialConfiguration?.configuration, formValues, configurationsMetadata);
      notificationService.success('Test Connection Succeeded!');
      success && isFormDirty && setIsSaveEnabled(true);
    } catch (error) {
      const notificationMessage = error?.message.includes('code 400')
        ? 'Missing configuration name.'
        : `${error?.message}.`;
      notificationService.error(
        `Test Connection Failed: ${notificationMessage} See Action Center logs for more information.`,
      );
    }
  };

  const buttons = useMemo(
    () => [
      ...(isPermitted(ACTION_CENTER_PERMISSIONS.TEST_CONNECTION_CONFIGURATIONS.name)
        ? [
            {
              text: 'Test Connection',
              component: PrimaryButton,
              onClick: handleTestConnection(editConfigurationData),
            },
          ]
        : []),
      {
        text: 'Save',
        component: PrimaryButton,
        onClick: (event: MouseEvent) => {
          formControls?.current?.validateAndSubmit(() => {
            formControls?.current?.submit(event);
            handleCloseForm();
          });
        },
        disabled: !isSaveEnabled,
      },
      {
        text: 'Cancel',
        component: SecondaryButton,
        onClick: noop,
        isClose: true,
      },
    ],
    [editConfigurationData, handleCloseForm, handleTestConnection, isSaveEnabled],
  );

  return (
    <BigidDialog
      isOpen={isOpen}
      onClose={handleCloseForm}
      showCloseIcon
      isContentScrollable
      title={`${editConfigurationData ? 'Edit' : 'New'} Configuration`}
      buttons={buttons}
    >
      {withWarning && (
        <div className={classes.warning}>
          <span>
            <BigidInfoFilledIcon color="insight" size="medium" />
          </span>
          <BigidBody1 className={classes.warningMessage}>{CONFIGURATIONS_WARNING}</BigidBody1>
        </div>
      )}
      <ConfigurationForm
        configurationsMetadata={configurationsMetadata}
        editConfigurationData={editConfigurationData}
        formControls={formControls}
        onSubmit={onSubmit}
      />
    </BigidDialog>
  );
};
