import { useEffect, useState } from 'react';
import { $state, commonMethods, entitySummaryService } from '../../../services/angularServices';
import { notificationService } from '../../../services/notificationService';
import { CONFIG } from '../../../../config/common';
import { DashboardUpperSectionItemData } from '../DashboardUpperSection/DashboardUpperSectionCommon';
import {
  CATALOG_PERMISSIONS,
  CORRELATION_PERMISSIONS,
  CORRELATION_SET_PERMISSIONS,
  DATA_SOURCES_PERMISSIONS,
  INVENTORY_PERMISSIONS,
} from '@bigid/permissions';
import { isPermitted } from '../../../services/userPermissionsService';
import { loginService } from '../../../../authentication/login/login.service';
import {
  BigidDataAttributeIllustration,
  BigidDataEntityIllustration,
  BigidDataFindingsIllustration,
} from '@bigid-ui/icons';

interface SummaryItem {
  counter?: number;
  entity: string;
  sub_counter?: number;
  attributesCount?: number;
  sampledIdentities?: number;
}

type getDisplayDataFunction = (data: Partial<SummaryItem>) => DashboardUpperSectionItemData;

const getDataSourcesDisplayData: getDisplayDataFunction = ({ counter, sub_counter }) => {
  const disabled = !isPermitted(counter ? CATALOG_PERMISSIONS.ACCESS.name : DATA_SOURCES_PERMISSIONS.ACCESS.name);
  return {
    value: counter ? `${commonMethods.getAbbrNum(sub_counter, 1)} / ${commonMethods.getAbbrNum(counter, 1)}` : '',
    description: counter ? 'Sources with Findings' : 'Configure Data Sources',
    onClick: () => {
      if (counter) {
        $state.go(CONFIG.states.CATALOG, {
          filter: 'contains_pi = "true"',
        });
      } else {
        $state.go(CONFIG.states.DATA_SOURCE_CONNECTIONS_INIT);
      }
    },
    disabled,
    Icon: BigidDataFindingsIllustration,
    isLoading: true,
    id: 'dataSources',
  };
};

const getEntitiesDisplayData: getDisplayDataFunction = ({ sampledIdentities }) => {
  const disabled = !isPermitted(
    sampledIdentities ? INVENTORY_PERMISSIONS.ACCESS.name : CORRELATION_SET_PERMISSIONS.ACCESS.name,
  );
  return {
    value: sampledIdentities ? commonMethods.getAbbrNum(sampledIdentities, 1) : '',
    description: sampledIdentities ? 'Monitored Entities' : 'Configure Correlation Sets',
    onClick: () => {
      $state.go(sampledIdentities ? CONFIG.states.INVENTORY : CONFIG.states.IDENTITY_SOURCE_CONNECTIONS);
    },
    disabled,
    Icon: BigidDataEntityIllustration,
    isLoading: true,
    id: 'entities',
  };
};

const getAttributesDisplayData: getDisplayDataFunction = ({ attributesCount }) => {
  const disabled = !isPermitted(CORRELATION_PERMISSIONS.ACCESS.name);
  return {
    value: attributesCount ? commonMethods.getAbbrNum(attributesCount, 1) : '',
    description: attributesCount ? 'Attributes Found' : 'Configure Scan',
    onClick: () => {
      $state.go(attributesCount ? CONFIG.states.CORRELATION : CONFIG.states.SCANS_NEW);
    },
    disabled,
    Icon: BigidDataAttributeIllustration,
    isLoading: true,
    id: 'attributes',
  };
};

const getRecordsDisplayData: getDisplayDataFunction = ({ sub_counter, counter }) => {
  const disabled = !isPermitted(CATALOG_PERMISSIONS.ACCESS.name);
  return {
    value: sub_counter ? commonMethods.getAbbrNum(sub_counter, 1) : '',
    description: counter ? 'Open Access PI Records' : 'Scan Open Access PII',
    onClick: () => {
      if (sub_counter) {
        $state.go(CONFIG.states.CATALOG, {
          filter: 'contains_pi = "true" AND metadata.access_rights = "OPEN ACCESS"',
        });
      } else {
        $state.go(CONFIG.states.SCANS);
      }
    },
    disabled,
    Icon: BigidDataEntityIllustration,
    isLoading: true,
    id: 'records',
  };
};

interface SummaryDataNaiveCacheInterface {
  isNotInitiated: boolean;
  sourceDisplayData: DashboardUpperSectionItemData;
  entitiesDisplayData: DashboardUpperSectionItemData;
  attributesDisplayData: DashboardUpperSectionItemData;
  recordsDisplayData: DashboardUpperSectionItemData;
}

const naiveCacheInitialState: SummaryDataNaiveCacheInterface = {
  isNotInitiated: true,
  sourceDisplayData: getDataSourcesDisplayData({}),
  entitiesDisplayData: getEntitiesDisplayData({}),
  attributesDisplayData: getAttributesDisplayData({}),
  recordsDisplayData: getRecordsDisplayData({}),
};

let naiveCache: SummaryDataNaiveCacheInterface = { ...naiveCacheInitialState };

const transformDataToDisplayByType: any = {
  source: {
    sourceDisplayData: getDataSourcesDisplayData,
  },
  user: {
    attributesDisplayData: getAttributesDisplayData,
    entitiesDisplayData: getEntitiesDisplayData,
  },
  record: {
    recordsDisplayData: getRecordsDisplayData,
  },
};

const getDataObjectByEntityName = (item: SummaryItem) => {
  const mapTypeToFunction = transformDataToDisplayByType[item.entity];
  const result: { [key: string]: DashboardUpperSectionItemData } = {};

  if (mapTypeToFunction) {
    Object.entries(mapTypeToFunction).forEach(([name, value]): void => {
      const convertFunction = value as getDisplayDataFunction;
      result[name] = { ...convertFunction(item), isLoading: false };
    });
  }
  return result;
};

export const useSummaryData = () => {
  const [summaryData, setSummaryData] = useState(naiveCache);
  const [isLoading, setIsLoading] = useState(naiveCache.isNotInitiated);

  useEffect(() => {
    entitySummaryService
      .getPanelData()
      .then(({ entity_summaries }) => {
        const dataByTypes = entity_summaries.reduce(
          (acc: SummaryItem[], item: SummaryItem) => ({
            ...acc,
            ...getDataObjectByEntityName(item),
          }),
          { isNotInitiated: false },
        );
        naiveCache = dataByTypes;
        setSummaryData(dataByTypes);
      })
      .catch(err => {
        notificationService.error(err.message);
        setSummaryData(({ entitiesDisplayData, sourceDisplayData, attributesDisplayData, recordsDisplayData }) => ({
          sourceDisplayData: { ...sourceDisplayData, disabled: true },
          entitiesDisplayData: { ...entitiesDisplayData, disabled: true },
          attributesDisplayData: { ...attributesDisplayData, disabled: true },
          recordsDisplayData: { ...recordsDisplayData, disabled: true },
          isNotInitiated: false,
        }));
      })
      .finally(() => {
        setIsLoading(false);
      });

    return () => {
      if (!loginService.isLoggedIn()) {
        naiveCache = { ...naiveCacheInitialState };
      }
    };
  }, [setSummaryData]);

  return {
    isSummaryLoading: isLoading,
    ...summaryData,
  };
};
