import { CloudProvider } from '../../../AutoDiscoveryWizard/autoDiscoveryWizardServices';
import { DiscoveryAppTypeToDisplayName } from '../../../DataSources/DataSourceConnections/hooks/useAutoDiscoveryAppStatus';
import { CredsStatus } from './ConnectionsStatus';
import { getFixedT } from '../../translations';

const t = getFixedT();

export enum ConnectStatus {
  CONNECTED = 'connected',
  FAILED = 'falied',
  NONE = 'none',
}

export interface DataSourceInfo {
  id: string;
  name: string;
  connected: ConnectStatus;
}

export const initialDataSourceList: Record<CloudProvider, DataSourceInfo[]> = {
  [CloudProvider.AWS]: [
    {
      id: 's3',
      name: t('General.dsTypes.s3'),
      connected: ConnectStatus.NONE,
    },
    {
      id: 'athena',
      name: t('General.dsTypes.athena'),
      connected: ConnectStatus.NONE,
    },
    {
      id: 'dynamodb',
      name: t('General.dsTypes.dynamodb'),
      connected: ConnectStatus.NONE,
    },
    {
      id: 'kinesis',
      name: t('General.dsTypes.kinesis'),
      connected: ConnectStatus.NONE,
    },
  ],
  [CloudProvider.AZURE]: [
    {
      id: 'blob',
      name: t('General.dsTypes.blobStorage'),
      connected: ConnectStatus.NONE,
    },
    {
      id: 'storage',
      name: t('General.dsTypes.fileStorage'),
      connected: ConnectStatus.NONE,
    },
    {
      id: 'cassandra',
      name: t('General.dsTypes.cassandra'),
      connected: ConnectStatus.NONE,
    },
    {
      id: 'mongodb',
      name: t('General.dsTypes.mongodb'),
      connected: ConnectStatus.NONE,
    },
    {
      id: 'cosmosdb',
      name: t('General.dsTypes.cosmosdb'),
      connected: ConnectStatus.NONE,
    },
    {
      id: 'databricks',
      name: t('General.dsTypes.databricks'),
      connected: ConnectStatus.NONE,
    },
  ],
};

export const normalizeDataSourceList = (type: CloudProvider, permissions: string[]) => {
  return initialDataSourceList[type].map(item =>
    permissions.indexOf(item.id) !== -1
      ? { ...item, connected: ConnectStatus.CONNECTED }
      : { ...item, connected: ConnectStatus.FAILED },
  );
};

export const getWarningTitle = (status: CredsStatus): string => {
  switch (status) {
    case CredsStatus.CANT_RUN:
      return t('Connect.ConnectionStatusWarning.title.cantRun');
    case CredsStatus.WRONG_CREDS:
      return t('Connect.ConnectionStatusWarning.title.wrongCreds');
    default:
      return t('Connect.ConnectionStatusWarning.title.default');
  }
};

export const getWarningBody = (status: CredsStatus, type: CloudProvider): string => {
  switch (status) {
    case CredsStatus.CANT_RUN:
      return t('Connect.ConnectionStatusWarning.body.cantRun');
    case CredsStatus.WRONG_CREDS:
      return t('Connect.ConnectionStatusWarning.body.wrongCreds', { type: DiscoveryAppTypeToDisplayName[type] });
    default:
      return t('Connect.ConnectionStatusWarning.body.default', { type: DiscoveryAppTypeToDisplayName[type] });
  }
};
