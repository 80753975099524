import React, { useCallback, useState } from 'react';
import { BigidDialog, EntityEvents, PrimaryButton, SecondaryButton, entityEventsEmitter } from '@bigid-ui/components';
import { VendorProject } from './VendorsTypes';
import { deleteVendor } from './vendorsService';
import { notificationService } from '../../services/notificationService';
import styled from '@emotion/styled';
import { VendorsTrackingEvents } from './utils/analytics';

const ContentWrapper = styled.div({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'start',
  fontWeight: 400,
  fontSize: '14px',
});

export type VendorDeleteDetails = Pick<VendorProject, 'id' | 'name'>;

export interface VendorsDeleteDialogProps {
  selectedVendor: VendorDeleteDetails;
  isOpen: boolean;
  onClose?: () => void;
  onDelete?: () => void;
}

export interface VendorsDeleteDialog {
  deleteDialogProps: VendorsDeleteDialogProps;
  openDeleteDialog: (selectedVendor: VendorDeleteDetails) => Promise<boolean>;
}

const initialDeleteProps: VendorsDeleteDialogProps = {
  isOpen: false,
  selectedVendor: {
    id: '',
    name: '',
  },
};

export const useVendorsDeleteDialog = (): VendorsDeleteDialog => {
  const [deleteDialogProps, setDeleteDialogProps] = useState<VendorsDeleteDialogProps>(initialDeleteProps);

  const openDeleteDialog = useCallback<VendorsDeleteDialog['openDeleteDialog']>(selectedVendor => {
    return new Promise<boolean>(resolve => {
      setDeleteDialogProps({
        isOpen: true,
        selectedVendor: selectedVendor,
        onDelete: () => {
          setDeleteDialogProps(initialDeleteProps);
          entityEventsEmitter.emit(EntityEvents.RELOAD);
          resolve(true);
        },
        onClose: () => setDeleteDialogProps(initialDeleteProps),
      });
    });
  }, []);

  return {
    deleteDialogProps,
    openDeleteDialog,
  };
};

export const VendorsDeleteDialog = ({ isOpen, onClose, onDelete, selectedVendor }: VendorsDeleteDialogProps) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const onCloseButtonClicked = () => {
    onClose();
    setIsLoading(false);
  };

  const onDeleteButtonClicked = useCallback(async () => {
    setIsLoading(true);
    try {
      await deleteVendor(selectedVendor.id);
      onDelete();
    } catch (error) {
      const errorMessage = `Failed to delete project : ${error?.message}`;
      notificationService.error(JSON.stringify(errorMessage));
      console.error(`${JSON.stringify(errorMessage)}`);
    } finally {
      setIsLoading(false);
    }
  }, [selectedVendor, onDelete]);

  return (
    <BigidDialog
      title="Delete vendor?"
      showCloseIcon
      isLoading={isLoading}
      borderTop
      maxWidth="xs"
      isOpen={isOpen}
      onClose={onCloseButtonClicked}
      buttons={[
        {
          component: props => (
            <SecondaryButton
              {...props}
              bi={{
                eventType: VendorsTrackingEvents.DELETE_VENDOR_DIALOG_CANCEL_CLICK,
              }}
            />
          ),
          onClick: onCloseButtonClicked,
          text: 'Cancel',
        },
        {
          component: props => (
            <PrimaryButton
              {...props}
              bi={{
                eventType: VendorsTrackingEvents.DELETE_VENDOR_DIALOG_DELETE_CLICK,
              }}
            />
          ),
          onClick: onDeleteButtonClicked,
          text: 'Delete',
        },
      ]}
    >
      <ContentWrapper>{`Are you sure you want to delete "${selectedVendor.name}"?`}</ContentWrapper>
    </BigidDialog>
  );
};
