import React, { FC, memo, useState, useEffect, useMemo, useCallback } from 'react';
import {
  compareObjectsExceptFunctions,
  BigidLoader,
  BigidSelectOption,
  BigidSelect,
  BigidFormFieldSideLabelWrapper,
} from '@bigid-ui/components';
import { systemUsersService } from '../../../../services/angularServices';
import { notificationService } from '../../../../services/notificationService';
import { sessionStorageService } from '../../../../../common/services/sessionStorageService';
import { showConfirmationDialog } from '../../../../services/confirmationDialogService';
import { hasRootScope } from '../../../../services/userPermissionsService';
import { debounce, uniqBy } from 'lodash';
import { getUsersQuery } from '../../../../utilities/systemUsersUtils';
import { SystemUser, naiveCache } from '../systemUsersCacheForFields';

const MAX_USERS_IN_REQUEST = 400;

type GetSystemUsersDataResult = [BigidSelectOption[], number];

export const normalizeUsersData = (users: SystemUser[]) =>
  users.map(({ id, firstName, lastName, email, name, origin }: SystemUser) => {
    const firsLastName = (firstName || lastName) && `${firstName}${firstName && lastName ? ' ' : ''}${lastName || ''}`;
    const labelEnd = email || name;
    const labelStart = firsLastName ? firsLastName : name !== labelEnd ? name : '';

    return {
      id,
      value: {
        id: name,
        origin,
        email,
      },
      label: labelStart ? labelStart : labelEnd,
      subLabel: labelEnd && labelStart ? labelEnd : '',
    };
  });

const getSelectedUserData = async (selectedUser: string) => {
  try {
    const query = getUsersQuery({
      filter: [
        {
          field: 'name',
          operator: 'in',
          value: [selectedUser],
        },
      ],
      maxUsers: 1,
    });

    const {
      data: { users },
    } = await systemUsersService.getAllSystemUsersByQuery(query);

    return normalizeUsersData(users);
  } catch (error) {
    naiveCache.isLoading = false;
    notificationService.error('Error getting user data');
    console.error(error);
  }
};

const getSystemUsersData = async (searchString: string): Promise<GetSystemUsersDataResult> => {
  try {
    const query = getUsersQuery({ searchString, maxUsers: MAX_USERS_IN_REQUEST });
    const dataPromiseWhenSearch = searchString && systemUsersService.getAllSystemUsersByQuery(query);
    if (!dataPromiseWhenSearch) {
      naiveCache.optionsPromise = naiveCache.optionsPromise || systemUsersService.getAllSystemUsersByQuery(query);
    }

    const {
      data: { users, totalCount },
    } = (await dataPromiseWhenSearch) || (await naiveCache.optionsPromise);
    const options: BigidSelectOption[] = normalizeUsersData(users);
    if (!dataPromiseWhenSearch) {
      naiveCache.options = options;
    }
    naiveCache.isLoading = false;

    return [options, totalCount];
  } catch (error) {
    naiveCache.isLoading = false;
    notificationService.error('Error getting the list of Users');
    console.error(error);
  }
};

const useSystemUserOptions = (selectedInOtherIds: (string | number)[], selectedUserName?: string) => {
  const [{ options, isLoading }, setOptions] = useState(naiveCache);
  const [searchState, setSearchState] = useState('');
  const [selectedUserOption, setSelectedUserOption] = useState([]);
  const updateOptions = useCallback(async (searchString: string) => {
    try {
      const [options]: GetSystemUsersDataResult = await getSystemUsersData(searchString);
      setOptions(current => ({
        ...current,
        options,
      }));
    } catch (error) {
      console.error(error);
    } finally {
      setOptions(current => ({
        ...current,
        isLoading: false,
      }));
    }
  }, []);

  const onSearchChange = useCallback((searchString: string) => {
    searchString && setSearchState(searchString);
  }, []);

  useEffect(() => {
    selectedUserName && getSelectedUserData(selectedUserName).then(userData => setSelectedUserOption(userData));
  }, [selectedUserName, updateOptions]);

  useEffect(() => {
    updateOptions(searchState);
  }, [updateOptions, searchState]);

  return {
    options: uniqBy([...options.filter(({ id }) => !selectedInOtherIds?.includes(id)), ...selectedUserOption], 'id'),
    isLoading,
    onSearchChange: debounce(onSearchChange, 500),
  };
};

export const useSystemUserSelfDelete = (
  onDeleteFunc: (id: string) => void,
  onChange: (id: string, value: any) => void,
  data: Record<string, any>[],
) => {
  const currentUserInData = useMemo(
    () => data.find(({ value }) => value?.[0]?.id === sessionStorageService.get('userName')),
    [data],
  );
  const isCanPerformAction = useCallback(
    async (id: string) =>
      !hasRootScope() && id === currentUserInData?.id
        ? await showConfirmationDialog({
            entityNameSingular: 'yourself from owners',
            actionName: 'Removing',
            customDescription:
              'You are about to remove yourself as an owner of this data sources. If you don’t have the required scopes to view this data source, you won’t be able to see it anymore. Are you sure you wish to continue?',
          })
        : true,
    [currentUserInData],
  );

  const deleteUser = useCallback(
    async (id: string) => {
      if (await isCanPerformAction(id)) {
        onDeleteFunc(id);
      }
    },
    [isCanPerformAction, onDeleteFunc],
  );

  const changeUser = useCallback(
    async (id: string, value: any) => {
      if (await isCanPerformAction(id)) {
        onChange(id, value);
      } else {
        const userInOptions = naiveCache?.options?.filter(option => option.id === id);
        userInOptions && onChange(id, userInOptions);
      }
    },
    [isCanPerformAction, onChange],
  );

  return { deleteUser, changeUser };
};

export interface SystemUserValueOption extends SystemUser {
  placeholder?: string;
  parentName?: string;
}

export interface SystemUserValue {
  value: SystemUserValueOption[];
}

export interface FormSystemUserSelectFieldProps {
  setValue: (value: BigidSelectOption[]) => void;
  value: SystemUserValueOption[];
  id: string | number;
  disabled: boolean;
  data: { value: SystemUserValueOption[] }[];
}

export const FormSystemUserSelectField: FC<FormSystemUserSelectFieldProps> = memo(
  ({ value, setValue, id, disabled, data }) => {
    const selectedInOtherIds = data
      .map(({ value }) => value?.[0]?.id)
      .filter(itemId => itemId && itemId !== value?.[0]?.id);
    const { options, isLoading, onSearchChange } = useSystemUserOptions(selectedInOtherIds, value?.[0]?.id);
    const valueNormalized = useMemo(() => {
      const valueId = value?.[0]?.id;
      if (!valueId) return undefined;
      const filteredOptions = options.filter(({ id }) => id === valueId);
      return filteredOptions?.length
        ? filteredOptions
        : [
            {
              id: value?.[0]?.id ?? valueId,
              value: value?.[0]?.id ?? valueId,
              disabled: true,
              label: `${value?.[0]?.id ?? valueId} <Not Valid>`,
            },
          ];
    }, [value, options]);

    const onChange = useCallback(
      (value: BigidSelectOption[]) => {
        const newValue = [...value];
        if (value[0]?.__isNew__) {
          const userInput = value[0].value;
          newValue[0].label = `${userInput} <Data Source Owner>`;
          newValue[0].value = {
            origin: 'legacy',
            id: userInput,
            email: userInput,
          };
        }
        setValue(newValue);
      },
      [setValue],
    );

    return isLoading ? (
      <BigidLoader />
    ) : (
      <BigidFormFieldSideLabelWrapper
        id={`bigid-form-field-system-user-${valueNormalized?.[0]?.label || '-not-selected'}`}
        name={`bigid-form-field-system-user-${id}`}
        isRequired={false}
        tooltipText=""
      >
        <BigidSelect
          dataAid={`systemUser-select-${valueNormalized?.[0]?.label || `${value?.[0]?.parentName || ''}-not-selected`}`}
          isDisabled={disabled}
          isSearchable={true}
          value={valueNormalized}
          placeholder={value?.[0]?.placeholder}
          onChange={onChange}
          name={`bigid-form-field-system-user-select-${id}`}
          options={options}
          isClearable={true}
          onInputChange={onSearchChange}
          isCreatable={true}
          formatCreateLabel={ownerString => `Save "${ownerString}" to list of data source owners`}
        />
      </BigidFormFieldSideLabelWrapper>
    );
  },
  compareObjectsExceptFunctions,
);

FormSystemUserSelectField.displayName = 'FormSystemUserSelectField';
