import { useCallback, useMemo } from 'react';
import {
  AdvancedToolbarOverrideValue,
  AdvancedToolbarOverrideValueStrategy,
  BigidAdvancedToolbarFilterTypes,
  BigidAdvancedToolbarFilterUnion,
  BigidAdvancedToolbarProps,
  BigidDropdownOption,
  SelectedOptionsConfig,
  AdvancedToolbarOverrideValueMinified,
  advancedToolbarFilterMinifier,
  OptionsTypes,
} from '@bigid-ui/components';
import { useDataCatalogSearchResultsContext } from '../../../../contexts/dataCatalogSearchResultsContext';
import { useCatalogFilters } from './useCatalogFiltersConfig';
import { $state } from '../../../../../../services/angularServices';
import {
  DataExplorerSearchFilterSelectBiPayload,
  sendDataExplorerResultsPageFilterSelectBiAnalytics,
} from '../../../../../DataExplorer/services/analytics';
import { parseCatalogFilterOutputToQueryString } from '../../../../queryHelpers';
import { partition } from 'lodash';

export function useFilterConfig() {
  const { dispatchCatalogSearchAction, filterOverrideValues } = useDataCatalogSearchResultsContext();

  const handleFiltersChange = useCallback(
    (selectedFilters: BigidAdvancedToolbarFilterUnion[]) => {
      dispatchCatalogSearchAction({
        type: 'UPDATE_SAVED_FILTERS_MAP',
        payload: {
          entityType: 'catalog',
          filter: parseCatalogFilterOutputToQueryString(selectedFilters),
        },
      });

      dispatchCatalogSearchAction({
        payload: {
          selectedRowsIds: [],
          unselectedRowsIds: [],
        },
        type: 'UPDATE_GRID_CONFIG',
      });

      const overrideValues = selectedFilters.map(advancedToolbarFilterMinifier.getFilterCompressedToOverrideValue);
      const minifiedFilters = overrideValues.map(advancedToolbarFilterMinifier.getOverrideValueMinified);

      $state.go(
        $state.$current.name,
        {
          filters: selectedFilters.length > 0 ? JSON.stringify(minifiedFilters) : null,
        },
        { reload: false, notify: false, location: 'replace' },
      );

      const biPayload: DataExplorerSearchFilterSelectBiPayload = {
        filter: selectedFilters.map(filter => advancedToolbarFilterMinifier.getFilterCompressedToOverrideValue(filter)),
      };

      sendDataExplorerResultsPageFilterSelectBiAnalytics(biPayload);
    },
    [dispatchCatalogSearchAction],
  );

  const transformDropdownValuesToNestedStructure = (
    incomingInitialValues: AdvancedToolbarOverrideValue[],
  ): AdvancedToolbarOverrideValue[] => {
    return incomingInitialValues.map(incomingValue => {
      const optionsToSet: OptionsTypes = [];

      switch (incomingValue.type) {
        case BigidAdvancedToolbarFilterTypes.DROPDOWN: {
          const { options } = incomingValue;

          for (const option of options as BigidDropdownOption[]) {
            const parentId = option.value?.aggItemGroup;
            optionsToSet.push({ ...option, parentId });

            break;
          }
        }
      }

      return {
        id: incomingValue.id,
        type: incomingValue.type,
        options: optionsToSet,
      };
    });
  };

  const unminifiedFilters = (JSON.parse(filterOverrideValues || '[]') as AdvancedToolbarOverrideValueMinified[]).map(
    advancedToolbarFilterMinifier.getOverrideValueUnminified,
  );

  const [dropdownUnminifiedFilters, otherFilters] = partition(
    unminifiedFilters,
    ({ type }) => type === BigidAdvancedToolbarFilterTypes.DROPDOWN,
  );

  const overrideValues = [...transformDropdownValuesToNestedStructure(dropdownUnminifiedFilters), ...otherFilters];

  const overrideConfig: SelectedOptionsConfig = {
    strategy: AdvancedToolbarOverrideValueStrategy.INIT_WITH_RELOAD_MERGE,
    values: overrideValues,
  };

  const filters = useCatalogFilters();
  const filterConfig: BigidAdvancedToolbarProps = useMemo(() => {
    return {
      filters,
      onFiltersChange: handleFiltersChange,
      selectedOptionsConfig: overrideConfig,
    };
  }, []);

  const getFilterConfigById = useCallback(
    (filterId: string | number) => {
      return filterConfig.filters.find(({ id }) => id === filterId);
    },
    [filterConfig.filters],
  );

  return {
    filterConfig,
    getFilterConfigById,
  };
}
