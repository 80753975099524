import React, { useState } from 'react';
import { BigidBody1, BigidCheckbox, BigidDialog, PrimaryButton, SecondaryButton } from '@bigid-ui/components';
import { BigidWarningIcon } from '@bigid-ui/icons';
export interface RiskUnsavedChangesDialogProps {
  isOpen: boolean;
  cancel: () => void;
  confirm: () => void;
}

export const RiskUnsavedChangesDialog: React.FC<RiskUnsavedChangesDialogProps> = ({ isOpen, cancel, confirm }) => {
  const [checked, setChecked] = useState(false);

  return (
    <BigidDialog
      data-aid="risk-library-unsaved-changes"
      isOpen={isOpen}
      onClose={cancel}
      buttons={[
        {
          component: SecondaryButton,
          onClick: cancel,
          text: `Don't Update`,
          dataAid: 'risk-library-unsaved-changes-cancel',
        },
        {
          component: PrimaryButton,
          onClick: confirm,
          text: 'Update Risk',
          disabled: !checked,
          dataAid: 'risk-library-unsaved-changes-save',
        },
      ]}
      title="Unsaved Changes"
      borderTop
      icon={BigidWarningIcon}
      maxWidth={'sm'}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          gap: 16,
        }}
      >
        <BigidBody1>
          {
            ' Do you want to save the changes on this risk before leaving the page? If “Update Risk” is selected, all assessments using this risk will be updated as a result of this action and might result in change of the Risk Overall.'
          }
        </BigidBody1>

        <BigidBody1>
          {'Choosing "Don\'t Update" will not save the updates and the changes will be discarded.'}
        </BigidBody1>
        <BigidCheckbox
          dataAid="risk-library-unsaved-changes-confirm"
          checked={checked}
          onChange={(_, checked) => setChecked(checked)}
          label="I understand that multiple Assessments using this Risk will also be updated."
        />
      </div>
    </BigidDialog>
  );
};
