import { useMutation, UseMutationResult, MutationFunction, UseMutationOptions } from 'react-query';
import { TRIGGER_SUGGESTED_ACTION_ENDPOINT } from '../constants/actions';
import { triggerSuggestedAction } from '../services/actions';
import type { BigidFilter } from '@bigid-ui/components';
import type { CreateSuggestedActionsItemQueryParams, CreateSuggestedActionsItemResponse } from '../types';
import type { AsyncReturnType } from '../../DataSourceConnectionModal/types';
import type { ErrorType } from '../../../../config/request';

export type CreateSuggestedActionQueryResult = NonNullable<AsyncReturnType<typeof triggerSuggestedAction>>;

export type CreateSuggestedActionQueryError = ErrorType<unknown>;

export const createSuggestedActionMutationKey = (
  actionType: string,
  initiativeType: string,
  filters?: BigidFilter,
): Array<unknown> => [`${TRIGGER_SUGGESTED_ACTION_ENDPOINT}`, actionType, initiativeType, filters];

export const useCreateSuggestedAction = <TError = CreateSuggestedActionQueryError, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    AsyncReturnType<typeof triggerSuggestedAction>,
    TError,
    { params: CreateSuggestedActionsItemQueryParams; actionType: string; initiativeType: string },
    TContext
  >;
}): UseMutationResult<
  CreateSuggestedActionsItemResponse,
  TError,
  { params: CreateSuggestedActionsItemQueryParams; actionType: string; initiativeType: string },
  TContext
> => {
  const { mutation: mutationOptions } = options || {};
  // eslint-disable-next-line max-len
  const mutationFn: MutationFunction<
    AsyncReturnType<typeof triggerSuggestedAction>,
    { params: CreateSuggestedActionsItemQueryParams; actionType: string; initiativeType: string }
  > = async props => {
    const { params, actionType, initiativeType } = props || {};

    return triggerSuggestedAction(actionType, initiativeType, params?.filters);
  };

  return useMutation<
    AsyncReturnType<typeof triggerSuggestedAction>,
    TError,
    { params: CreateSuggestedActionsItemQueryParams; actionType: string; initiativeType: string },
    TContext
  >(mutationFn, mutationOptions);
};
