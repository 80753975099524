import angular from 'angular';
import React, { FC } from 'react';
import { convertToAngular } from '../../../common/services/convertToAngular';
import { BigidTooltip } from '@bigid-ui/components';
import BigidHelpIcon from '../../assets/icons/BigidHelpFilled.svg';
import styled from '@emotion/styled';
import { publicUrls } from '../../config/publicUrls';

interface ScanProfileLegacyPageTooltipProps {}

const BigidHelpIconContainer = styled('div')`
  display: inline-block;
`;

const title = (
  <div>
    If the correlation sets were scanned in the last 30 days and there has been no change to the system, there&apos;s no
    need to rescan them. Select this option to reduce processing time. For more information, see the&nbsp;
    <a target="_blank" href={publicUrls.SMALLID_GETTING_STARTED_SCAN_TYPES_DOC} rel="noreferrer">
      documentation.
    </a>
  </div>
);

export const ScanProfileLegacyPageTooltip: FC<ScanProfileLegacyPageTooltipProps> = () => {
  return (
    <BigidTooltip width={'400px'} title={title} placement="top">
      <BigidHelpIconContainer>
        <BigidHelpIcon />
      </BigidHelpIconContainer>
    </BigidTooltip>
  );
};
angular
  .module('app')
  .component(
    'scanProfileLegacyPageTooltip',
    convertToAngular<ScanProfileLegacyPageTooltipProps>(ScanProfileLegacyPageTooltip),
  );
