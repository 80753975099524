import React, { FC } from 'react';
import { BigidBody1, BigidLoader } from '@bigid-ui/components';
import { useLocalTranslation } from '../../translations';
import styled from '@emotion/styled';
import ClassifierTuningFieldPreview from '../../../../assets/icons/ClassifierTuningFieldPreview.svg';

const LoadingWrapper = styled('div')`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 16px;
  flex: 1 0 0;
  align-self: stretch;
`;

const LoaderTextWrapper = styled('div')`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 32px;
  gap: 8px;
`;

const LoaderText = styled(BigidBody1)`
  font-weight: 600;
  color: ${({ theme }) => theme.vars.tokens.bigid.foregroundCta};
`;

export const CuratedFieldValuePreviewLoading: FC = () => {
  const { t } = useLocalTranslation('CuratedFields');

  return (
    <LoadingWrapper>
      <ClassifierTuningFieldPreview />
      <>
        <LoaderTextWrapper>
          <BigidLoader position="static" size={20} />
          <LoaderText>{t('loading')}</LoaderText>
        </LoaderTextWrapper>
      </>
    </LoadingWrapper>
  );
};
