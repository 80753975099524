import React, { FC, useCallback, useEffect, useRef, useState } from 'react';
import { BigidGridWithToolbarProps } from '@bigid-ui/grid';
import { BigidFieldFilterOperator, BigidLoader } from '@bigid-ui/components';
import {
  createHotspotsReport,
  getHotspotsDatasources,
  Hotspot,
  HotspotChartRow,
} from '../../../../HotspotsReport/HotspotsReportChart/HotspotsReportChartService';
import { ACIDashboardHotspotsChartWidget } from './ACIDashboardHotspotsChartWidget';
import { performScan } from '../../../../HotspotsReport/HotspotsReportChart/utils';
import { intersection } from 'lodash';
import { BigidHotspotsChartLazy } from '@bigid-ui/visualisation';
import styled from '@emotion/styled';
import { HotspotsContainer } from '../../../../HotspotsReport/HotspotsReportChart/HotspotsContainer';
import { InteractiveDashboardHeadingWithDropdown } from '../../InteractiveDashboardWidgetHeader';
import { notificationService } from '../../../../../services/notificationService';

export interface HotspotsForACIProps {
  height: number;
  selectedDatasources: string[];
  availableDatasources: string[];
  title: string;
}
export const SENSITIVITY_CLASSIFICATION_STARTS_WITH = 'system.sensitivityClassification';
export const ACCESS_TYPES_STARTS_WITH = 'Access Type/';
export const FILTERS_SECTION_HEIGHT = 197;

const BigidHotspotsChartWrapper = styled('div')({
  margin: '20px 4px 0 4px',
});

const MinHeightWrapper = styled('div')<{ minHeight: number }>`
  min-height: ${props => props.minHeight + 'px'};
`;

export const createFilterToolbarConfig = (sensitivity: string[], accessType: string[]) => {
  const setterFunction = (
    filterToolbarConfig: BigidGridWithToolbarProps<HotspotChartRow>['filterToolbarConfig'],
  ): BigidGridWithToolbarProps<HotspotChartRow>['filterToolbarConfig'] => ({
    ...filterToolbarConfig,
    filters: [
      {
        title: 'Sensitivity',
        field: 'sensitivity',
        operator: 'in' as BigidFieldFilterOperator,
        options: sensitivity.map(v => ({ value: v, label: v, isSelected: true })),
        value: sensitivity,
      },
      {
        title: 'Access Type',
        field: 'accessType',
        operator: 'in' as BigidFieldFilterOperator,
        options: accessType.map(v => ({ value: v, label: v, isSelected: true })),
        value: accessType,
      },
    ],
  });
  return setterFunction;
};

export const ACIDashboardHotspots: FC<HotspotsForACIProps> = ({
  selectedDatasources,
  availableDatasources,
  height,
  title,
}) => {
  const scanResultId = useRef<string>();
  const intervalNumber = useRef<number>();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [filterToolbarConfig, setFilterToolbarConfig] =
    useState<BigidGridWithToolbarProps<Hotspot>['filterToolbarConfig']>();
  const [noSensitivityOrAccessType, setNoSensitivityOrAccessType] = useState<boolean>(true);

  const [datasourcesWithHotspotData, setDatasourcesWithHotspotData] = useState<string[]>(null);

  const renderEmptyHotspotsChart = useCallback(
    () => (
      <BigidHotspotsChartWrapper>
        <BigidHotspotsChartLazy options={[]} />
      </BigidHotspotsChartWrapper>
    ),
    [],
  );

  const renderNonEmptyHotspotsChart = useCallback(
    () =>
      isLoading ? (
        <BigidLoader />
      ) : (
        <ACIDashboardHotspotsChartWidget
          filterToolbarConfig={filterToolbarConfig}
          scanResultId={scanResultId.current}
          height={height}
        />
      ),
    [filterToolbarConfig, height, isLoading],
  );

  useEffect(() => {
    const getDatasourcesWithHotspotData = async () => {
      try {
        const hotspotsDatasources = await getHotspotsDatasources();

        if (selectedDatasources?.length > 0) {
          setDatasourcesWithHotspotData(intersection(hotspotsDatasources, availableDatasources, selectedDatasources));
        } else {
          setDatasourcesWithHotspotData(intersection(hotspotsDatasources, availableDatasources));
        }
      } catch (err) {
        console.error(err);
        notificationService.error('No data sources with hotspots found');
      }
    };
    getDatasourcesWithHotspotData();
  }, [availableDatasources, selectedDatasources]);

  useEffect(() => {
    const runWhenDatasourcesChanged = async () => {
      if (datasourcesWithHotspotData?.length > 0) {
        try {
          setIsLoading(true);

          await createHotspotsReport({
            data_sources: datasourcesWithHotspotData,
          });

          const { scanResult, tags } = await performScan(intervalNumber, datasourcesWithHotspotData);

          if (scanResult) {
            scanResultId.current = scanResult.id;
          }

          const sensitivity = tags.filter(tag => tag.startsWith(SENSITIVITY_CLASSIFICATION_STARTS_WITH));
          const accessType = tags.filter(tag => tag.startsWith(ACCESS_TYPES_STARTS_WITH));

          setNoSensitivityOrAccessType(!(sensitivity?.length > 0 || accessType?.length > 0));
          setFilterToolbarConfig(createFilterToolbarConfig(sensitivity, accessType));
        } catch (err) {
          console.log(err);
        } finally {
          setIsLoading(false);
        }
      }
    };

    runWhenDatasourcesChanged();
  }, [datasourcesWithHotspotData]);
  return (
    <HotspotsContainer.Provider>
      <MinHeightWrapper minHeight={height + FILTERS_SECTION_HEIGHT}>
        <InteractiveDashboardHeadingWithDropdown title={title} />
        {!(datasourcesWithHotspotData?.length > 0) || noSensitivityOrAccessType || !filterToolbarConfig
          ? renderEmptyHotspotsChart()
          : renderNonEmptyHotspotsChart()}
      </MinHeightWrapper>
    </HotspotsContainer.Provider>
  );
};
