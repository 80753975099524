import React, { FC, useState, useEffect, useCallback } from 'react';
import { BigidGuidedTour } from '@bigid-ui/guided-tour';
import { fetchGroupedCasesData } from '../actionableInsightsService';
import { CaseState } from '../../../types/actionableInsights';
import { CallBackProps, STATUS, EVENTS, ACTIONS } from 'react-joyride';
import {
  actionableInsightsTourSteps,
  ACTIONABLE_INSIGHTS_TOUR_LOCAL_STORAGE_KEY,
  actionableInsightsTourSelectors,
} from './components/steps';

export const ActionableInsightsGuidedTour: FC = () => {
  const [isTourRunning, setIsTourRunning] = useState<boolean>(false);
  const [stepIndex, setStepIndex] = useState<number>(0);

  useEffect(() => {
    const startTourIfRequirementsSatisfied = async (): Promise<void> => {
      const isTourCompleted = localStorage.getItem(ACTIONABLE_INSIGHTS_TOUR_LOCAL_STORAGE_KEY) === 'true';
      const { totalCount } = await fetchGroupedCasesData({ filter: [], requireTotalCount: true }, CaseState.OPEN);
      if (totalCount !== 0 && isTourCompleted !== true) {
        setIsTourRunning(true);
      }
    };

    startTourIfRequirementsSatisfied();
  }, []);

  useEffect(() => {
    const onClickOpenGridRowButton = () => {
      setIsTourRunning(false);
      setTimeout(() => {
        setStepIndex(2);
        setIsTourRunning(true);
      }, 1);
    };

    const onClickSingleDetailedCase = () => {
      setIsTourRunning(false);
      setTimeout(() => {
        setStepIndex(4);
        setIsTourRunning(true);
      }, 2000);
    };

    const singleDetailedCase = document.querySelector(
      actionableInsightsTourSelectors.SINGLE_DETAIL_CASE,
    ) as HTMLElement;
    const openGridRowButton = document.querySelector(
      actionableInsightsTourSelectors.OPEN_GRID_ROW_BUTTON,
    ) as HTMLElement;

    if (isTourRunning) {
      openGridRowButton?.addEventListener('click', onClickOpenGridRowButton);
      singleDetailedCase?.addEventListener('click', onClickSingleDetailedCase);
    }
    return () => {
      openGridRowButton?.removeEventListener('click', onClickOpenGridRowButton);
      singleDetailedCase?.removeEventListener('click', onClickSingleDetailedCase);
    };
  }, [stepIndex, isTourRunning]);

  const getNextStepIndex = useCallback(({ action, index }: CallBackProps) => {
    const dispatchClickEventOnElementByQuerySelector = (selector: string): void => {
      const targetElement = document.querySelector(selector) as HTMLElement;
      const clickEvent = new MouseEvent('click', { bubbles: true });
      targetElement?.dispatchEvent(clickEvent);
    };

    const nextStepIndex = index + (action === ACTIONS.PREV ? -1 : 1);
    if (action === ACTIONS.PREV) {
      if (nextStepIndex === 3) {
        dispatchClickEventOnElementByQuerySelector(actionableInsightsTourSelectors.CLOSE_CASE_REPORT_BUTTON);
        return 2;
      } else if (nextStepIndex === 1) {
        dispatchClickEventOnElementByQuerySelector(actionableInsightsTourSelectors.CLOSE_GRID_ROW_BUTTON);
        return 0;
      }
    }
    return nextStepIndex;
  }, []);

  const joyrideCallback = useCallback(
    (data: CallBackProps) => {
      const { status, type } = data;
      if (status === STATUS.FINISHED || status === STATUS.SKIPPED) {
        setIsTourRunning(false);
        localStorage.setItem(ACTIONABLE_INSIGHTS_TOUR_LOCAL_STORAGE_KEY, 'true');
      } else if (type === EVENTS.STEP_AFTER || type === EVENTS.TARGET_NOT_FOUND) {
        setStepIndex(getNextStepIndex(data));
      }
    },
    [getNextStepIndex],
  );

  return (
    <BigidGuidedTour
      run={isTourRunning}
      joyrideCallback={joyrideCallback}
      floaterProps={{
        hideArrow: true,
      }}
      styles={{
        options: {
          zIndex: 100000,
        },
      }}
      steps={actionableInsightsTourSteps()}
      stepIndex={stepIndex}
      disableCloseOnEsc
      disableOverlayClose
      disableScrollParentFix
      disableScrolling
      delay={1000}
    />
  );
};
