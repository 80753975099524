import { differenceInDays, isAfter, isEqual, format } from 'date-fns';
import { sessionStorageService } from '../../common/services/sessionStorageService';
import { $state } from './angularServices';
import { SystemEvents, systemEventsEmitter } from './systemEvents';
import { $rootScope } from 'ngimport';
import { BigidSystemDialogOptions, openSystemDialog } from './systemDialogService';
import { LicenseUpgradeFlow } from '../views/GeneralSettings/Licenses/LicenseUpgradeFlow/LicenseUpgradeFlow';
import { internalApps } from './appsLicenseService';

const DAYS_TO_NOTIFY_LICENSE_EXPIRATION = 10;
const LICENSE_PAGE = 'generalSettings';
export const LICENSE_TAB = 'licenses';

export enum LICENSE_TYPES {
  SMALLID = 'SmallID',
  DDM = 'DDM',
}

interface TransitionOptions {
  reload?: boolean;
  inherit?: boolean;
  notify?: boolean;
}

interface Amount {
  total: number;
  used: number;
}

interface DataSourcesMetadata extends Amount {
  permitted: string[];
}

interface SingleApp {
  id: string;
  name: string;
}

export interface License {
  creationDate: string;
  expirationDate: string;
  dataSources: DataSourcesMetadata;
  identitiesCount: Amount;
  quotaLimit: Amount;
  licenseType: string;
  isTrial: boolean;
  owner: string;
  applicationBundles: string[];
  singleApps: SingleApp[];
}

const hasLicense = (): boolean => sessionStorageService.get('license');

const setLicense = (license: License) => {
  sessionStorageService.set('license', license);
  systemEventsEmitter.emit(SystemEvents.UPDATE_LICENSE);
};

const getLicense = (): License => ({ ...sessionStorageService.get('license') });

const getAmountOfDaysUntilExpired = (): number =>
  hasLicense() ? (isExpired() ? 0 : differenceInDays(new Date(getLicense().expirationDate), new Date())) : -1;

const shouldShowExpirationWarning = (): boolean => getAmountOfDaysUntilExpired() <= DAYS_TO_NOTIFY_LICENSE_EXPIRATION;

const shouldNotifyLicenseExpiration = () => !hasLicense() || shouldShowExpirationWarning();

const shouldNotifyLicenseViolation = (): boolean => {
  return isExceededMaximumDataSourceAllowance() || shouldShowExpirationWarning();
};

const shouldShowLicenseViolationBanner = (): boolean => {
  return !getUserClosedExpirationBar() && shouldNotifyLicenseViolation();
};

const isExceededMaximumDataSourceAllowance = (): boolean =>
  hasLicense() && getLicense().dataSources.total > 0 && getLicense().dataSources.used > getLicense().dataSources.total;

const isExpired = (): boolean =>
  hasLicense()
    ? isAfter(new Date(format(new Date(), 'yyyy-MM-dd')), new Date(getLicense().expirationDate)) ||
      isEqual(new Date(format(new Date(), 'yyyy-MM-dd')), new Date(getLicense().expirationDate))
    : true;
const isTrial = (): boolean => hasLicense() && getLicense().isTrial;

const goToLicensePage = (options?: TransitionOptions) => {
  $state.go(LICENSE_PAGE, { currentTab: LICENSE_TAB }, options);
};

const shouldBlockUserWithLicenseExpired = (): boolean =>
  !licenseService.hasLicense() || (licenseService.isTrial() && licenseService.isExpired());

const getLicenseType = (): string => (hasLicense() ? getLicense().licenseType : null);

const getCreationDate = (): string => (hasLicense() ? getLicense().creationDate : null);

const getApplicationBundles = (): string[] => (hasLicense() ? getLicense().applicationBundles : []);

const setUserClosedExpirationBar = (userClosedExpirationBar: boolean) => {
  sessionStorageService.set('userClosedExpirationBar', userClosedExpirationBar);
  systemEventsEmitter.emit(SystemEvents.USER_CLOSED_EXPIRATION_BAR);
  $rootScope.$applyAsync();
};

const getUserClosedExpirationBar = (): boolean => Boolean(sessionStorageService.get('userClosedExpirationBar'));

const openLicenseUpgradeFlow = () => {
  const options: BigidSystemDialogOptions = {
    title: 'Upgrade Your License',
    onClose: () => {}, // eslint-disable-line @typescript-eslint/no-empty-function
    content: LicenseUpgradeFlow,
    borderTop: true,
    withCloseButton: true,
  };
  openSystemDialog(options);
};

const isSAPLicense = () => {
  return getLicense().licenseType?.toUpperCase() === LICENSE_TYPES.DDM;
};

const hasCustomAppInSingleApps = (name: string, vendor: string) => {
  if (!getLicense().singleApps?.length) {
    return false;
  }
  return getLicense()
    .singleApps.filter(({ id }) => !internalApps.find(({ id: internalAppId }) => id === internalAppId))
    .find(({ id }) => {
      const splitAppId = id.split('.');
      const appVendor = splitAppId[0];
      const appName = splitAppId[splitAppId.length - 1];
      return name === appName && vendor === appVendor;
    });
};

export const licenseService = {
  getLicense,
  setLicense,
  shouldNotifyLicenseViolation,
  isExpired,
  isTrial,
  getAmountOfDaysUntilExpired,
  hasLicense,
  goToLicensePage,
  shouldBlockUserWithLicenseExpired,
  getLicenseType,
  getCreationDate,
  getApplicationBundles,
  shouldShowLicenseViolationBanner,
  setUserClosedExpirationBar,
  openLicenseUpgradeFlow,
  isSAPLicense,
  hasCustomAppInSingleApps,
  isExceededMaximumDataSourceAllowance,
  shouldNotifyLicenseExpiration,
};
