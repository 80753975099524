import React, { FC, PropsWithChildren, useState } from 'react';
import {
  BigidDialog,
  BigidDialogProps,
  PrimaryButton,
  SecondaryButton,
  BigidBody1,
  StyledButtonType,
  BigidLink,
  BigidDialogButtonType,
} from '@bigid-ui/components';
import { LicenseForm, LicenseFormProps } from '../GeneralSettings/Licenses/AccountLicense/LicenseForm';
import { httpService } from '../../services/httpService';
import { isPermitted } from '../../services/userPermissionsService';
import { GENERAL_SETTINGS_PERMISSIONS } from '@bigid/permissions';
import { publicUrls } from '../../config/publicUrls';
import { CreateAppPayload } from '../../services/customAppService';

interface AppLicenseDialog extends Pick<BigidDialogProps, 'isOpen' | 'onClose'>, LicenseFormProps {
  onApply: () => void;
  appPayload?: CreateAppPayload;
}

const LinkButton: FC<StyledButtonType> = ({ children, onClick }: PropsWithChildren<StyledButtonType>) => (
  <BigidLink text={children as string} onClick={onClick} underline={'hover'} />
);

export const AppLicenseDialog: FC<AppLicenseDialog> = ({ isOpen, onClose, onApply, appPayload }) => {
  const [licenseKey, setLicenseKey] = useState('');
  const [showError, setShowError] = useState(false);

  const isAllowedManageLicense = isPermitted(GENERAL_SETTINGS_PERMISSIONS.MANAGE_LICENSE.name);
  const handleSupportLink = () => {
    window.open(publicUrls.SUPPORT_BIGID, '_blank').focus();
  };

  const handleClose = () => {
    onClose();
    setShowError(false);
  };

  const onClickApply = async () => {
    try {
      if (!licenseKey) {
        return setShowError(true);
      }
      await httpService.post(appPayload?.appUrl ? 'apps-license-tpa' : 'apps-license', {
        token: licenseKey.trim(),
        ...(appPayload?.appUrl && appPayload),
      });
      onApply();
      handleClose();
    } catch (error) {
      console.error(error);
      setShowError(true);
    }
  };

  const buttons: BigidDialogButtonType[] = [
    {
      component: LinkButton,
      text: "Don't have a license?",
      onClick: handleSupportLink,
      dataAid: 'closeAppLicenseKeyDialog',
      alignment: 'left',
    },

    { component: SecondaryButton, text: 'Close', onClick: handleClose, dataAid: 'closeAppLicenseKeyDialog' },
    ...(isAllowedManageLicense
      ? [{ component: PrimaryButton, text: 'Apply', onClick: onClickApply, dataAid: 'applyAppLicenseKey' }]
      : []),
  ];

  const onFormChange = (licenseKey: string) => {
    setLicenseKey(licenseKey);
  };

  return (
    <BigidDialog isOpen={isOpen} onClose={handleClose} title="Insert App License Key" buttons={buttons}>
      {isAllowedManageLicense ? (
        <LicenseForm onFormChange={onFormChange} error={showError} />
      ) : (
        <BigidBody1>This app requires a license, please contact your administrator.</BigidBody1>
      )}
    </BigidDialog>
  );
};
