import React, { useCallback, useState, ReactNode, useEffect, ChangeEvent } from 'react';
import styled from '@emotion/styled';
import {
  BigidColorsV2,
  BigidDialog,
  BigidDropdown,
  BigidDropdownOption,
  BigidCheckbox,
  BigidTextField,
  BigidTooltip,
  EntityEvents,
  PrimaryButton,
  SecondaryButton,
  TertiaryButton,
  entityEventsEmitter,
  BigidDropdownNewOptionValidation,
} from '@bigid-ui/components';
import { FormLabel, Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { VendorsTrackingEvents } from './utils/analytics';
import { BigidAddIcon, BigidHelpIcon, DataSourceIconByDsType } from '@bigid-ui/icons';
import { DataActivity, DataExchangeType, MultiValueEntity, Vendor, VendorProject } from './VendorsTypes';
import { notificationService } from '../../services/notificationService';
import { getCountries } from '../DSAR/dsarService';
import { applicationSetupService } from '../../../administration/applicationSetup/applicationSetup.service';
import { ApplicationEntity } from '../ApplicationSetup/types';
import {
  GlossaryItemType,
  businessGlossaryService,
} from '../../../administration/generalSettings/businessGlossary/businessGlossary.service';
import {
  createVendorProject,
  getVendors,
  getDataActivities,
  createDataActivity,
  createVendor,
  updateVendorProject,
  createAsset,
  getSystemUsers,
} from './vendorsService';

const useStyles = makeStyles((theme: Theme) => ({
  wrapper: {
    display: 'flex',
    flexFlow: 'row wrap',
    marginBottom: theme.spacing(3),
  },
  withSpacing: {
    padding: theme.spacing(2),
    gap: theme.spacing(2),
    backgroundColor: BigidColorsV2.gray[100],
  },
  asterisk: {
    color: BigidColorsV2.red[600],
    width: '10px',
    display: 'inline-block',
    textAlign: 'center',
  },
  error: {
    color: BigidColorsV2.red[600],
    marginTop: '-5px',
  },
  flexWrapper: {
    display: 'flex',
  },
  vendorType: {
    width: '33%',
    marginRight: '12px',
  },
  displayName: {
    flexGrow: 1,
  },
  dataExchangeTypeWrapper: {
    display: 'flex',
    gap: '8px',
    marginLeft: '-7px',
  },
  description: {
    width: '100%',
    marginTop: theme.spacing(2),
  },
  addDescription: {
    display: 'flex',
    justifyContent: 'end',
  },
  noBottomSpacing: {
    marginBottom: 0,
  },
}));

const Wrapper = styled('div')<{ fullWidth?: boolean }>(({ fullWidth }) => ({
  display: 'inline-flex',
  flexDirection: 'column',
  position: 'relative',
  minWidth: 0,
  padding: 0,
  margin: 0,
  border: 0,
  verticalAlign: 'top',
  width: fullWidth ? '100%' : 'calc(50% - 8px)',
}));

type NewlyAddedOptionsType = {
  addedNewVendor: boolean;
  addedNewDataActivity: boolean;
};

export interface VendorsEditDialogProps {
  selectedVendor: VendorProject;
  isOpen: boolean;
  isNewVendor: boolean;
  onSave?: () => void;
  onClose?: (addedNewOptions: NewlyAddedOptionsType) => void;
}
type VendorProjectCreateUpdate = Omit<VendorProject, 'id'>;

enum FormNames {
  vendorType = 'vendorType',
  name = 'name',
  description = 'description',
  assets = 'assets',
  locations = 'locations',
  businessOwner = 'businessOwner',
  businessOwnerOptions = 'businessOwnerOptions',
  businessOwnerValid = 'businessOwnerValid',
  purposeOfProcessing = 'purposeOfProcessing',
  dataActivities = 'dataActivities',
  isDataProcessor = 'isDataProcessor',
  isDataController = 'isDataController',
  contactPerson = 'contactPerson',
  role = 'role',
  contactEmail = 'contactEmail',
  contactPhoneNumber = 'contactPhoneNumber',
  groupEmail = 'groupEmail',
  companyAddress = 'companyAddress',
  companyWebsite = 'companyWebsite',
}

type FormState = Record<FormNames, unknown>;
const initialFormState = Object.values(FormNames).reduce(
  (state, key) => ({ ...state, [key]: undefined }),
  {} as FormState,
);

const statePropToFormFieldMapper: Partial<Record<keyof FormState, string>> = {
  [FormNames.vendorType]: 'vendorId',
  [FormNames.assets]: 'assetIds',
  [FormNames.businessOwnerOptions]: 'businessOwner',
  [FormNames.purposeOfProcessing]: 'purposeOfDataCollecting',
  [FormNames.contactPerson]: 'contactPerson.name',
  [FormNames.role]: 'contactPerson.role',
  [FormNames.contactEmail]: 'contactPerson.email',
  [FormNames.contactPhoneNumber]: 'contactPerson.phone',
  [FormNames.groupEmail]: 'contactPerson.groupEmail',
  [FormNames.companyAddress]: 'contactPerson.companyAddress',
  [FormNames.companyWebsite]: 'contactPerson.companyWebsite',
};

const fieldKeyToNameMapper = {
  [FormNames.description]: 'Description',
  [FormNames.businessOwner]: 'Internal Business Owner',
  [statePropToFormFieldMapper[FormNames.contactPerson]]: 'Contact Person',
  [statePropToFormFieldMapper[FormNames.role]]: 'Role',
  [statePropToFormFieldMapper[FormNames.contactEmail]]: 'Contact Email',
  [statePropToFormFieldMapper[FormNames.contactPhoneNumber]]: 'Contact Phone Number',
  [statePropToFormFieldMapper[FormNames.groupEmail]]: 'Group Email',
  [statePropToFormFieldMapper[FormNames.companyAddress]]: 'Company Address',
  [statePropToFormFieldMapper[FormNames.companyWebsite]]: 'Company Website',
};

const defaultErrorMessage = 'Mandatory field';
const requiredFields: Partial<keyof FormState>[] = [FormNames.vendorType, FormNames.name];

const filterSelectedOptions = (formState: FormState) =>
  Object.keys(formState).reduce((acc, prop) => {
    const existingValue = formState[prop as keyof FormState];
    const value = Array.isArray(existingValue) ? existingValue.filter(option => option.isSelected) : existingValue;
    acc[prop as keyof FormState] = value;
    return acc;
  }, {} as FormState);
const validate = (selectedOptions: FormState) => {
  return requiredFields
    .filter(field => {
      const value = selectedOptions[field];
      return Array.isArray(value) ? !value.length : !(value as string)?.trim();
    })
    .map(field => ({ instance: field, message: defaultErrorMessage }));
};
const NUMBER_OF_APIS = 6;

export const VendorsEditDialog = ({ selectedVendor, isOpen, isNewVendor, onSave, onClose }: VendorsEditDialogProps) => {
  const classes = useStyles({});
  const [isLoading, setIsLoading] = useState(false);
  const [fetchedApis, setFetchedApis] = useState(0);
  const isDataReady = fetchedApis === NUMBER_OF_APIS;
  const [formState, setState] = useState<FormState>(initialFormState);
  const [errors, setErrors] = useState<{ instance: string; message: string }[]>([]);
  const [newOptionsCreated, setNewOptionsCreated] = useState<NewlyAddedOptionsType>({
    addedNewVendor: false,
    addedNewDataActivity: false,
  });
  const getErrorMessage = (prop: keyof FormState) => {
    const error = errors.find(
      ({ instance }) => (!!instance && instance === statePropToFormFieldMapper[prop]) || instance === prop,
    );
    return error
      ? error.message?.replace(
          error.instance,
          fieldKeyToNameMapper[statePropToFormFieldMapper[prop] || prop] || error.instance,
        )
      : '';
  };

  const resetCurrentFieldError = (name: FormNames) => {
    const filteredErrors = errors.filter(
      ({ instance }) => !(!!instance && instance === statePropToFormFieldMapper[name]) || instance === name,
    );
    setErrors(filteredErrors);
  };

  const getErrorProps = (formName: FormNames) => {
    const errorMessage = getErrorMessage(formName);
    return {
      errorMessage,
      isError: !!errorMessage,
    };
  };

  useEffect(() => {
    if (!selectedVendor || !isDataReady) {
      return;
    }
    const markSelectedOptions = (options: BigidDropdownOption[], newValues: (string | MultiValueEntity)[] = []) => {
      return options?.map(option => {
        const values = newValues.map(value => (typeof value === 'string' ? value : value?.id));
        return {
          ...option,
          isSelected: !!values.includes(option.value),
        };
      });
    };
    setState(state => ({
      ...state,
      name: selectedVendor.name,
      description: selectedVendor.description,
      vendorType: markSelectedOptions(state.vendorType as BigidDropdownOption[], [selectedVendor.vendorId]),
      assets: markSelectedOptions(state.assets as BigidDropdownOption[], selectedVendor.assets),
      locations: markSelectedOptions(state.locations as BigidDropdownOption[], selectedVendor.locations),
      businessOwnerOptions: markSelectedOptions(state.businessOwnerOptions as BigidDropdownOption[], [
        selectedVendor.businessOwner,
      ]),
      businessOwner: selectedVendor.businessOwner,
      purposeOfProcessing: markSelectedOptions(
        state.purposeOfProcessing as BigidDropdownOption[],
        selectedVendor.purposeOfDataCollecting,
      ),
      dataActivities: markSelectedOptions(state.dataActivities as BigidDropdownOption[], selectedVendor.dataActivities),
      isDataProcessor: !!selectedVendor.dataExchangeTypes?.includes(DataExchangeType.DATA_PROCESSOR),
      isDataController: !!selectedVendor.dataExchangeTypes?.includes(DataExchangeType.DATA_CONTROLLER),
      contactPerson: selectedVendor.contactPerson?.name,
      role: selectedVendor.contactPerson?.role,
      contactPhoneNumber: selectedVendor.contactPerson?.phone,
      contactEmail: selectedVendor.contactPerson?.email,
      groupEmail: selectedVendor.contactPerson?.groupEmail,
      companyAddress: selectedVendor.contactPerson?.companyAddress,
      companyWebsite: selectedVendor.contactPerson?.companyWebsite,
    }));
  }, [selectedVendor, isDataReady]);

  const handleOnSave = async () => {
    try {
      const selectedOptionsState = filterSelectedOptions(formState);
      const invalidFields = validate(selectedOptionsState);
      if (invalidFields.length) {
        setErrors(invalidFields);
        return;
      }
      const state: FormState = Object.keys(selectedOptionsState).reduce((acc, key) => {
        const currentValue = selectedOptionsState[key as keyof FormState];
        return {
          ...acc,
          [key]: ([undefined, null, ''] as Array<unknown>).includes(currentValue)
            ? isNewVendor
              ? undefined
              : null
            : currentValue,
        };
      }, {} as FormState);
      const getOptionsValue = (options: BigidDropdownOption[] = []) => options.map(option => option.value);
      const payload: VendorProjectCreateUpdate = {
        vendorId: (state.vendorType as BigidDropdownOption[])[0]?.id,
        name: state.name as string,
        description: state.description as string,
        assetIds: getOptionsValue(state.assets as BigidDropdownOption[]),
        locations: getOptionsValue(state.locations as BigidDropdownOption[]),
        businessOwner: (state.businessOwnerOptions as BigidDropdownOption[])?.[0]?.value || state.businessOwner,
        purposeOfDataCollecting: getOptionsValue(state.purposeOfProcessing as BigidDropdownOption[]),
        dataActivities: getOptionsValue(state.dataActivities as BigidDropdownOption[]),
        dataExchangeTypes: [
          state.isDataController && DataExchangeType.DATA_CONTROLLER,
          state.isDataProcessor && DataExchangeType.DATA_PROCESSOR,
        ].filter(Boolean),
        contactPerson: {
          name: state.contactPerson as string,
          role: state.role as string,
          phone: state.contactPhoneNumber as string,
          email: state.contactEmail as string,
          groupEmail: state.groupEmail as string,
          companyAddress: state.companyAddress as string,
          companyWebsite: state.companyWebsite as string,
        },
      };
      setIsLoading(true);
      if (isNewVendor) {
        await createVendorProject(payload);
      } else {
        await updateVendorProject(selectedVendor?.id, payload);
      }
      onSave();
      handleOnClose();
    } catch (err) {
      const errorMessage = `Failed to ${isNewVendor ? 'create' : 'update'} Vendor Project "${formState.name}"`;
      notificationService.error(errorMessage);
      console.error(`${errorMessage}: ${JSON.stringify(err)}`);
      if (err?.response?.data?.errors?.length) {
        setErrors(err?.response?.data?.errors);
      } else if (err?.response?.data?.status === 'error' && err?.response?.data?.message) {
        setErrors([{ instance: 'generic', message: err?.response?.data?.message }]);
      }
      setIsLoading(false);
    }
  };

  const handleOnClose = () => {
    onClose(newOptionsCreated);
    setIsLoading(false);
    setErrors([]);
    setState(initialFormState);
    setFetchedApis(0);
    setNewOptionsCreated({ addedNewDataActivity: false, addedNewVendor: false });
  };

  const handleChange = (name: FormNames, value: unknown) => {
    setState(state => ({ ...state, [name]: value }));
  };

  const onInputChange = (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    const { value, name } = event.target;
    setState(state => ({ ...state, [name]: value }));
    resetCurrentFieldError(name as FormNames);
  };

  const fetchDropdownOptions =
    (optionsFetcherFunction: (searchTerm?: string) => Promise<BigidDropdownOption[]>, stateProp: FormNames) =>
    async (searchTerm?: string) => {
      try {
        const options = await optionsFetcherFunction(searchTerm);
        handleChange(stateProp, options);
        return options;
      } catch (err) {
        return [];
      } finally {
        setFetchedApis(num => ++num);
      }
    };

  const handleDropdownChange = (stateProp: FormNames) => (values: BigidDropdownOption[]) => {
    const ids = values.map(val => val.id);
    const toSelected = (val: BigidDropdownOption) => ({
      ...val,
      isSelected: ids.includes(val.id) || !!val.isNew,
    });
    setState(state => ({
      ...state,
      [stateProp]: [
        ...new Map(
          [...((state[stateProp] as BigidDropdownOption[]) || []), ...values].map(item => [item.id, item]),
        ).values(),
      ].map(toSelected),
    }));
    resetCurrentFieldError(stateProp);
  };

  useEffect(() => {
    if (isOpen) {
      (async () => {
        if (selectedVendor?.businessOwner) {
          const checkIsUserValid = true;
          const options = await fetchOwnersOptions(selectedVendor?.businessOwner as string, checkIsUserValid);
          handleChange(FormNames.businessOwnerValid, !!options?.length);
        }
      })();
      fetchDropdownOptions(fetchOwnersOptions, FormNames.businessOwnerOptions)();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  if (!isOpen) {
    return null;
  }

  const getDropdownValue = (stateProp: FormNames) =>
    ((formState[stateProp] as BigidDropdownOption[]) || []).filter(country => country.isSelected);

  const isNewAssetValid = (value: string): Promise<BigidDropdownNewOptionValidation> => {
    const trimmedValue = value.trim();
    const isInValidValue = !trimmedValue || /[!@#$%^&*()\\=+]/gi.test(trimmedValue);
    if (isInValidValue) {
      notificationService.error(
        `Failed to create new asset. Asset name cannot be blank or contain special characters: !@#$%^&*()=+`,
      );
    }
    return Promise.resolve({
      isValid: !isInValidValue,
      message: 'Invalid Asset',
    });
  };

  return (
    <BigidDialog
      title={isNewVendor ? 'Add Vendor' : 'Edit Vendor'}
      isOpen={isOpen}
      onClose={handleOnClose}
      borderTop
      borderBottom
      maxWidth="sm"
      buttons={[
        {
          component: props => (
            <SecondaryButton
              {...props}
              bi={{
                eventType: isNewVendor
                  ? VendorsTrackingEvents.CREATE_VENDOR_DIALOG_CANCEL_CLICK
                  : VendorsTrackingEvents.EDIT_VENDOR_DIALOG_CANCEL_CLICK,
              }}
            />
          ),
          onClick: handleOnClose,
          text: 'Cancel',
        },
        {
          component: props => (
            <PrimaryButton
              {...props}
              bi={{
                eventType: isNewVendor
                  ? VendorsTrackingEvents.CREATE_VENDOR_DIALOG_SAVE_CLICK
                  : VendorsTrackingEvents.EDIT_VENDOR_DIALOG_SAVE_CLICK,
              }}
            />
          ),
          onClick: handleOnSave,
          text: isNewVendor ? 'Add' : 'Update',
        },
      ]}
      isLoading={isLoading}
    >
      <div className={classes.wrapper}>
        <div className={classes.vendorType}>
          <Label text="Vendor" required />
          <BigidDropdown
            options={formState.vendorType as BigidDropdownOption[]}
            onSelect={(values: (BigidDropdownOption & { contactDetails?: Vendor['contactDetails'] })[]) => {
              handleDropdownChange(FormNames.vendorType)(values);
              const value = values[0];
              setState(state => ({
                ...state,
                [FormNames.name]: value?.displayValue,
                companyAddress: value?.contactDetails?.companyAddress,
                companyWebsite: value?.contactDetails?.companyWebsite,
                contactPerson: undefined,
                role: undefined,
                contactPhoneNumber: undefined,
                contactEmail: undefined,
                groupEmail: undefined,
              }));
            }}
            value={getDropdownValue(FormNames.vendorType)}
            fetchOptions={fetchDropdownOptions(fetchVendorsOptions, FormNames.vendorType)}
            onCreate={value =>
              createVendorOptions(value).then(options => {
                setNewOptionsCreated(prev => ({ ...prev, addedNewVendor: true }));
                return options;
              })
            }
            placeholder="Select"
            noOptionsMessage="No Vendors included."
            size="large"
            {...getErrorProps(FormNames.vendorType)}
            isSearchable
            shouldLoadInitialOptions
            isAsync
            isCreatable
            isDisabled={!!selectedVendor}
            hasIconBefore
            displayLimit={100}
          />
        </div>
        <div className={classes.displayName}>
          <Label text="Vendor Engagement" required />
          <BigidTextField
            name={FormNames.name}
            placeholder="Enter Name"
            size="large"
            onChange={onInputChange}
            value={(formState.name as string) || ''}
            {...getErrorProps(FormNames.name)}
          />
          {typeof formState.description === 'undefined' && (
            <div className={classes.addDescription}>
              <TertiaryButton
                onClick={() => {
                  handleChange(FormNames.description, '');
                }}
                size="large"
                startIcon={<BigidAddIcon />}
                text="Add description"
              />
            </div>
          )}
        </div>
        {typeof formState.description !== 'undefined' && (
          <div className={classes.description}>
            <Label text="Description" />
            <BigidTextField
              name={FormNames.description}
              placeholder="Vendor Description"
              size="large"
              rows={4}
              onChange={onInputChange}
              value={(formState.description as string) || ''}
              {...getErrorProps(FormNames.description)}
              multiline
            />
          </div>
        )}
      </div>
      <Label text="Details" bold />
      <div className={`${classes.wrapper} ${classes.withSpacing}`}>
        <Wrapper>
          <Label text="Assets" />
          <BigidDropdown
            options={(formState.assets as BigidDropdownOption[]) || []}
            onSelect={handleDropdownChange(FormNames.assets)}
            value={getDropdownValue(FormNames.assets)}
            placeholder="Select"
            size="large"
            fetchOptions={fetchDropdownOptions(fetchAssetsOptions, FormNames.assets)}
            onCreate={createAssetsOptions}
            shouldLoadInitialOptions
            isAsync
            isMulti
            isSearchable
            isCreatable
            defaultInvalidNewOptionMessage="Invalid new asset"
            defaultServerErrorMessage="An error has occurred"
            getIsNewOptionValid={isNewAssetValid}
            withChipsBar
            {...getErrorProps(FormNames.assets)}
          />
        </Wrapper>
        <Wrapper>
          <Label text="Data Location" />
          <BigidDropdown
            options={(formState.locations as BigidDropdownOption[]) || []}
            onSelect={handleDropdownChange(FormNames.locations)}
            value={getDropdownValue(FormNames.locations)}
            placeholder="Select Location"
            size="large"
            fetchOptions={fetchDropdownOptions(fetchLocationOptions, FormNames.locations)}
            displayLimit={300}
            shouldLoadInitialOptions
            isAsync
            isMulti
            isSearchable
            withChipsBar
            {...getErrorProps(FormNames.locations)}
          />
        </Wrapper>
        <Wrapper>
          <Label text="Internal Business Owner" />
          <BigidDropdown
            options={(formState.businessOwnerOptions as BigidDropdownOption[]) || []}
            onSelect={handleDropdownChange(FormNames.businessOwnerOptions)}
            value={[
              ...getDropdownValue(FormNames.businessOwnerOptions),
              ...(formState.businessOwner
                ? [
                    {
                      id: formState.businessOwner as string,
                      value: formState.businessOwner,
                      displayValue: `${formState.businessOwner}${formState.businessOwnerValid ? '' : ' <Not Valid>'}`,
                    },
                  ]
                : []),
            ].slice(0, 1)}
            placeholder="Select Owner"
            size="large"
            fetchOptions={fetchDropdownOptions(fetchOwnersOptions, FormNames.businessOwnerOptions)}
            onCreate={value => {
              handleChange(FormNames.businessOwner, value);
              fetchDropdownOptions(fetchOwnersOptions, FormNames.businessOwnerOptions)();
              return Promise.resolve({ displayValue: `${value} <Not Valid>` } as BigidDropdownOption);
            }}
            isAsync
            isSearchable
            isCreatable
            {...getErrorProps(FormNames.businessOwner)}
          />
        </Wrapper>
      </div>
      <Label text="Data Processing" bold />
      <div className={`${classes.wrapper} ${classes.withSpacing}`}>
        <Wrapper>
          <Label text="Purpose of Processing" />
          <BigidDropdown
            options={(formState.purposeOfProcessing as BigidDropdownOption[]) || []}
            onSelect={handleDropdownChange(FormNames.purposeOfProcessing)}
            value={getDropdownValue(FormNames.purposeOfProcessing)}
            placeholder="Select"
            size="large"
            onCreate={createPurposeOfProcessingCustomOption}
            fetchOptions={fetchDropdownOptions(fetchPurposeOfProcessingOptions, FormNames.purposeOfProcessing)}
            shouldLoadInitialOptions
            isAsync
            isSearchable
            isCreatable
            isMulti
            withChipsBar
            {...getErrorProps(FormNames.purposeOfProcessing)}
          />
        </Wrapper>
        <Wrapper>
          <Label text="Data Activities" />
          <BigidDropdown
            options={(formState.dataActivities as BigidDropdownOption[]) || []}
            onSelect={handleDropdownChange(FormNames.dataActivities)}
            value={getDropdownValue(FormNames.dataActivities)}
            placeholder="Select"
            size="large"
            onCreate={value =>
              createDataActivityCustomOption(value).then(options => {
                setNewOptionsCreated(prev => ({ ...prev, addedNewDataActivity: true }));
                return options;
              })
            }
            fetchOptions={fetchDropdownOptions(fetchDataActivityOptions, FormNames.dataActivities)}
            shouldLoadInitialOptions
            isAsync
            isSearchable
            isCreatable
            isMulti
            withChipsBar
            {...getErrorProps(FormNames.dataActivities)}
          />
        </Wrapper>
        <Wrapper fullWidth>
          <Label text="Data Exchange Type" />
          <div className={classes.dataExchangeTypeWrapper}>
            <BigidCheckbox
              name={FormNames.isDataProcessor}
              label="Data Processor"
              checked={!!formState.isDataProcessor}
              onChange={(_, checked) => {
                handleChange(FormNames.isDataProcessor, checked);
              }}
            />
            <BigidCheckbox
              name={FormNames.isDataController}
              label="Data Controller"
              checked={!!formState.isDataController}
              onChange={(_, checked) => {
                handleChange(FormNames.isDataController, checked);
              }}
            />
          </div>
        </Wrapper>
      </div>
      <Label text="Contact Information" bold />
      <div className={`${classes.wrapper} ${classes.withSpacing} ${classes.noBottomSpacing}`}>
        <Wrapper>
          <Label text="Contact Person" />
          <BigidTextField
            name={FormNames.contactPerson}
            placeholder="Contact Person"
            size="large"
            onChange={onInputChange}
            value={(formState.contactPerson as string) || ''}
            {...getErrorProps(FormNames.contactPerson)}
          />
        </Wrapper>
        <Wrapper>
          <Label text="Role" />
          <BigidTextField
            name={FormNames.role}
            placeholder="Role / Title"
            size="large"
            onChange={onInputChange}
            value={(formState.role as string) || ''}
            {...getErrorProps(FormNames.role)}
          />
        </Wrapper>
        <Wrapper>
          <Label text="Contact Email" />
          <BigidTextField
            name={FormNames.contactEmail}
            placeholder="name@example.com"
            size="large"
            onChange={onInputChange}
            value={(formState.contactEmail as string) || ''}
            {...getErrorProps(FormNames.contactEmail)}
          />
        </Wrapper>
        <Wrapper>
          <Label text="Contact Phone Number" />
          <BigidTextField
            name={FormNames.contactPhoneNumber}
            placeholder="100-123-4567"
            size="large"
            onChange={onInputChange}
            value={(formState.contactPhoneNumber as string) || ''}
            {...getErrorProps(FormNames.contactPhoneNumber)}
          />
        </Wrapper>
        <Wrapper fullWidth>
          <Label text="Group Email" />
          <BigidTextField
            name={FormNames.groupEmail}
            placeholder="department@example.com"
            size="large"
            type="email"
            onChange={onInputChange}
            value={(formState.groupEmail as string) || ''}
            {...getErrorProps(FormNames.groupEmail)}
          />
        </Wrapper>
        <Wrapper fullWidth>
          <Label text="Company Address" />
          <BigidTextField
            name={FormNames.companyAddress}
            placeholder="Address"
            size="large"
            onChange={onInputChange}
            value={(formState.companyAddress as string) || ''}
            {...getErrorProps(FormNames.companyAddress)}
          />
        </Wrapper>
        <Wrapper fullWidth>
          <Label text="Company Website" />
          <BigidTextField
            name={FormNames.companyWebsite}
            placeholder="example.com"
            size="large"
            onChange={onInputChange}
            value={(formState.companyWebsite as string) || ''}
            {...getErrorProps(FormNames.companyWebsite)}
          />
        </Wrapper>
        {getErrorMessage('generic' as FormNames) && (
          <p className={classes.error}>{getErrorMessage('generic' as FormNames)}</p>
        )}
      </div>
    </BigidDialog>
  );
};

const Label = ({
  text,
  tooltip,
  required,
  bold,
}: {
  text: ReactNode;
  tooltip?: string;
  required?: boolean;
  bold?: boolean;
}) => {
  const classes = useStyles({});
  return (
    <FormLabel className={classes.flexWrapper}>
      {bold ? <strong>{text}</strong> : text}
      {required && <span className={classes.asterisk}>*</span>}
      {tooltip && (
        <BigidTooltip title={tooltip} placement="top" width="240px">
          <div className={classes.flexWrapper}>
            <BigidHelpIcon size="medium" />
          </div>
        </BigidTooltip>
      )}
    </FormLabel>
  );
};

export interface VendorsEditDialog {
  dialogProps: VendorsEditDialogProps;
  openDialog: (selectedVendor?: VendorProject) => Promise<boolean>;
  key: { vendorsKey: number; dataActivityKey: number };
}

const initialState: VendorsEditDialogProps = {
  selectedVendor: null,
  isOpen: false,
  isNewVendor: false,
};

export const useVendorsEditDialog = (): VendorsEditDialog => {
  const [dialogProps, setDialogProps] = useState<VendorsEditDialogProps>(initialState);
  const [key, setKey] = useState<{ vendorsKey: number; dataActivityKey: number }>({
    vendorsKey: 0,
    dataActivityKey: 0,
  });

  const openDialog = useCallback<VendorsEditDialog['openDialog']>(selectedVendor => {
    return new Promise<boolean>(resolve => {
      setDialogProps({
        selectedVendor,
        isOpen: true,
        isNewVendor: !selectedVendor,
        onSave: () => {
          setDialogProps(initialState);
          entityEventsEmitter.emit(EntityEvents.RELOAD);
          resolve(true);
        },
        onClose: (newOptionsCreated: NewlyAddedOptionsType) => {
          setDialogProps(initialState);
          setKey(key => ({
            vendorsKey: newOptionsCreated.addedNewVendor ? ++key.vendorsKey : key.vendorsKey,
            dataActivityKey: newOptionsCreated.addedNewDataActivity ? ++key.dataActivityKey : key.dataActivityKey,
          }));
        },
      });
    });
  }, []);

  return {
    openDialog,
    dialogProps,
    key,
  };
};

export const fetchLocationOptions = async () => {
  try {
    const countries = await getCountries();
    const countryOptions = countries.map(c => ({
      id: c.groupedCode,
      value: c.name,
      displayValue: c.displayName,
      isSelected: false,
    }));
    return countryOptions;
  } catch (err) {
    notificationService.error(`Failed to fetch Locations`);
    console.error(`Failed to fetch Locations: ${JSON.stringify(err?.response)}`);
    return [];
  }
};

export const fetchOwnersOptions = async (searchTerm?: string, checkIsValid?: boolean) => {
  try {
    const { users: systemUsers } = await getSystemUsers(searchTerm, checkIsValid);
    const systemUsersList = systemUsers.map(user => {
      return {
        displayValue: user.firstName ? `${user.firstName} (${user.name})` : user.name,
        value: user.id,
        id: user._id,
      };
    });
    return systemUsersList;
  } catch (err) {
    notificationService.error(`Failed to fetch Business owners`);
    console.error(`Failed to fetch Business owners: ${JSON.stringify(err?.response)}`);
    return [];
  }
};

export const fetchAssetsOptions = async () => {
  try {
    const { applications } = await applicationSetupService.getApplicationItems();
    const options = applications.map((application: ApplicationEntity) => {
      return {
        displayValue: application.name,
        value: application._id,
        id: application._id,
      };
    });
    return options;
  } catch (err) {
    notificationService.error(`Failed to fetch Assets`);
    console.error(`Failed to fetch Assets: ${JSON.stringify(err?.response)}`);
    return [];
  }
};

export const createAssetsOptions = async (value: string) => {
  try {
    const { _id } = await createAsset({ name: value });
    const option: BigidDropdownOption = {
      id: _id,
      value: _id,
      displayValue: value,
      isNew: true,
    };
    return option;
  } catch (err) {
    notificationService.error(`Failed to create Asset`);
    console.error(`Failed to create Asset: ${JSON.stringify(err?.response)}`);
    return {} as BigidDropdownOption;
  }
};

export const fetchPurposeOfProcessingOptions = async () => {
  try {
    const glossaryItems = await businessGlossaryService.getGlossaryItems({
      type: GlossaryItemType.PurposeOfProcessing,
    });
    const options = glossaryItems.map(glossary => {
      return {
        displayValue: glossary.name,
        value: glossary.glossary_id,
        id: glossary._id,
      };
    });
    return options;
  } catch (err) {
    notificationService.error(`Failed to fetch ${GlossaryItemType.PurposeOfProcessing}`);
    console.error(`Failed to fetch ${GlossaryItemType.PurposeOfProcessing}: ${JSON.stringify(err?.response)}`);
    return [];
  }
};

export const createPurposeOfProcessingCustomOption = async (value: string) => {
  try {
    const newGlossaryItem = await businessGlossaryService.createGlossaryItem({
      type: GlossaryItemType.PurposeOfProcessing,
      name: value,
    });
    const option: BigidDropdownOption = {
      id: newGlossaryItem._id,
      value: newGlossaryItem.glossary_id,
      displayValue: value,
      isNew: true,
    };
    return option;
  } catch (err) {
    notificationService.error(`Failed to create ${GlossaryItemType.PurposeOfProcessing}`);
    console.error(`Failed to create ${GlossaryItemType.PurposeOfProcessing}: ${JSON.stringify(err?.response)}`);
    return {} as BigidDropdownOption;
  }
};

export const fetchVendorsOptions = async () => {
  try {
    const { vendors } = await getVendors();
    const options = vendors.map(vendor => {
      return {
        displayValue: vendor.name,
        value: vendor.id,
        id: vendor.id,
        icon: () => <DataSourceIconByDsType dataSourceType={vendor.type || vendor.name} width={24} />,
        contactDetails: vendor.contactDetails,
      };
    });
    return options;
  } catch (err) {
    notificationService.error(`Failed to fetch ${GlossaryItemType.PurposeOfProcessing}`);
    console.error(`Failed to fetch ${GlossaryItemType.PurposeOfProcessing}: ${JSON.stringify(err?.response)}`);
    return [];
  }
};

export const createVendorOptions = async (value: string) => {
  try {
    const { id } = await createVendor({ name: value });
    const option: BigidDropdownOption = {
      id: id,
      value: id,
      displayValue: value,
      isNew: true,
      icon: () => <DataSourceIconByDsType dataSourceType={value} width={24} />,
    };
    return option;
  } catch (err) {
    notificationService.error(`Failed to create Vendor`);
    console.error(`Failed to create Vendor: ${JSON.stringify(err?.response)}`);
    return {} as BigidDropdownOption;
  }
};

export const fetchDataActivityOptions = async () => {
  try {
    const dataActivitiesResponse: { dataActivities: DataActivity[] } = await getDataActivities();
    const options = dataActivitiesResponse.dataActivities.map(da => {
      return {
        displayValue: da.name,
        value: da.id,
        id: da.id,
      };
    });
    return options;
  } catch (err) {
    notificationService.error(`Failed to fetch data activities`);
    console.error(`Failed to fetch data activities: ${JSON.stringify(err?.response)}`);
    return [];
  }
};

export const createDataActivityCustomOption = async (value: string) => {
  try {
    const { id } = await createDataActivity({
      name: value,
    });
    const option: BigidDropdownOption = {
      id: id,
      value: id,
      displayValue: value,
      isNew: true,
    };
    return option;
  } catch (err) {
    notificationService.error(`Failed to create data activity`);
    console.error(`Failed to create data activity: ${JSON.stringify(err?.response)}`);
    return {} as BigidDropdownOption;
  }
};
