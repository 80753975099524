export const fieldsToRender: string[] = [
  'scannerGroup',
  'numberOfParsingThreads',
  'enableClassifiers',
  'enabledAdvancedClassifiers',
  'sampleFolders',
  'sampleFileContent',
  'samplePercentage',
  'sampleScanOnly',
  'rdbSampleDataMaxSize',
  'sampleScanType',
  'rdbSampleDataMaxPercentage',
  'contentSamplingDropDown',
  'limit',
  'percentage',
  'enumerationPercentage',
  'enumerationNoneSampling',
  'enumerationLimit',
  'sampleNative',
  'enumerationSamplingDropDown',
  'contentNoneSampling',
];

export const omittedFieldNames: string[] = [
  'Scanner Group',
  'Number of threads',
  'Enable Classifiers',
  'Advanced Classifiers',
  'Authentication Type',
  'Sampling',
];
