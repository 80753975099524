import React, { FC, useCallback, useMemo, useRef, useState } from 'react';
import { BigidLayoutMasterDetails, BigidLayoutMasterDetailsConfig, useLayout } from '@bigid-ui/layout';
import { BigidGridDataFetchResult, BigidGridProps, BigidGridQueryComponents, BigidGridRow } from '@bigid-ui/grid';
import { BigidLoader, objectToQueryString, QueryParams } from '@bigid-ui/components';
import { pageSize, createGridConfig, createLayoutConfig } from './utils';
import { getProviders } from './CredentialProvidersService';
import { CredentialProviderRow, ForwardFormRef } from './types';
import { useLocalTranslation } from './translations';
import { usePageHeader } from './hooks/usePageHaeder';
import { useFormActions } from './hooks/useFormActions';
import { CredentialProvidersHeader } from './CredentialProvidersHeader';
import { LoaderWrapper, Wrapper } from '../Credentials/CredentialsStyles';
import { CredentialProvidersEmptyState } from './CredentialProvidersEmptyState';
import { BigidLayoutMasterDetailsRenderProps } from '../../types/BigidLayoutMasterDetailsTypes';
import { useCertificates } from './hooks/useCertificates';
import { useRouteLeavingListener } from '../../components/RouteLeaving/hooks/useRouteLeavingListener';
import { CredentialProvidersFormMemo } from './CredentialProvidersForm';

export const CredentialProvidersView: FC<CredentialProviderRow> = () => {
  const [isCertErrorShown, setIsCertErrorShown] = useState<boolean>(false);
  const credentialProviderFormRef = useRef<ForwardFormRef>();
  const { t } = useLocalTranslation();

  const layout = useLayout<CredentialProviderRow>('BigidLayoutMasterDetails', {
    fetchDataFunction: async (
      queryComponents: BigidGridQueryComponents,
    ): Promise<BigidGridDataFetchResult<CredentialProviderRow>> => {
      try {
        const query = objectToQueryString({
          ...(queryComponents as QueryParams),
          limit: pageSize,
        });
        const { total, results } = await getProviders(`?${query}`);
        return { totalCount: total, data: results.map(result => ({ ...result, id: result._id })) };
      } catch (e) {
        console.error(e);
        return { totalCount: 0, data: [] };
      }
    },
    pageSize,
  });

  const { gridId, rows, isLoading, apiRef, getSelectedRow, ...rest } = layout;
  const selectedRow = getSelectedRow();
  const hasNoCredentials = !rows?.length && !isLoading;

  const gridConfig: BigidGridProps<CredentialProviderRow> = useMemo(
    () => createGridConfig(gridId, { ...rest, rows, isLoading, apiRef }),
    [gridId, rest, rows, isLoading, apiRef],
  );

  const {
    handleAddCredentialProvider,
    isBusy,
    handleSearchChange,
    handleSelectionChange,
    handleDelete,
    handleSubmit,
    handleTestConnection,
    showUnsavedChangesDialog,
    formControls,
  } = useFormActions({
    row: selectedRow,
    layout,
    credentialProviderFormRef,
    setIsCertErrorShown,
  });

  usePageHeader({ handleAddCredentialProvider, shouldShowAction: !!rows?.length });
  const { updateIsRouteLeavingEnabled } = useRouteLeavingListener(showUnsavedChangesDialog);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const updateIsRouteLeavingEnabledMemo = useCallback(updateIsRouteLeavingEnabled, []);

  const { isCertificatesLoading, certificateOptions } = useCertificates();

  const config: BigidLayoutMasterDetailsConfig<CredentialProviderRow> = useMemo(
    () => createLayoutConfig(gridConfig),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [gridConfig],
  );
  return (
    <Wrapper>
      <BigidLayoutMasterDetails
        disabled={isBusy || hasNoCredentials}
        onSelectionChange={handleSelectionChange}
        onSearchSubmit={handleSearchChange}
        onSearchChange={handleSearchChange}
        config={config as unknown as BigidLayoutMasterDetailsConfig<BigidGridRow>}
      >
        <BigidLayoutMasterDetails.Header
          render={({ selectedItem }: BigidLayoutMasterDetailsRenderProps<CredentialProviderRow>): JSX.Element => {
            const { isPending } = selectedItem ?? {};
            return (
              selectedItem && (
                <CredentialProvidersHeader
                  title={isPending ? t('message.addCredentialProvidersHeader') : selectedItem?.name}
                  row={selectedItem}
                  isBusy={isBusy}
                  handleSubmit={handleSubmit}
                  handleDelete={handleDelete}
                  handleTestConnection={handleTestConnection}
                />
              )
            );
          }}
        />
        <BigidLayoutMasterDetails.Content
          render={({ selectedItem }: BigidLayoutMasterDetailsRenderProps<CredentialProviderRow>) => {
            if (isCertificatesLoading || isLoading) {
              return (
                <LoaderWrapper>
                  <BigidLoader />
                </LoaderWrapper>
              );
            } else if (hasNoCredentials) {
              return <CredentialProvidersEmptyState handleAddCredentialProvider={handleAddCredentialProvider} />;
            }
            return (
              selectedItem && (
                <CredentialProvidersFormMemo
                  key={selectedItem?.id}
                  certificateOptions={certificateOptions}
                  setFormIsTouched={updateIsRouteLeavingEnabledMemo}
                  row={selectedItem}
                  ref={credentialProviderFormRef}
                  formControls={formControls}
                  isCertErrorShown={isCertErrorShown}
                />
              )
            );
          }}
        />
      </BigidLayoutMasterDetails>
    </Wrapper>
  );
};
