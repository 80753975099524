import { module } from 'angular';
import template from './newDLPConnection.component.html';
const app = module('app');
import { SECONDARY_SOURCE_PERMISSIONS } from '@bigid/permissions';
import { isPermitted } from '../../../react/services/userPermissionsService';

app.component('newDlpConnection', {
  template,
  controllerAs: 'newDLPConnectionModel',
  controller: [
    '$http',
    'appSettings',
    '$scope',
    '$state',
    '$stateParams',
    'notificationService',
    'newDLPConnectionService',
    '$rootScope',
    'dlpConnectionsService',
    '$timeout',
    function (
      $http,
      appSettings,
      $scope,
      $state,
      $stateParams,
      notificationService,
      newDLPConnectionService,
      $rootScope,
      dlpConnectionsService,
      $timeout,
    ) {
      const availableTypeOptions = [
        { id: '2', name: 'Datahub', value: 'SAP Data Intelligence' },
        { id: '1', name: 'Guardium', value: 'Guardium' },
        { id: '0', name: 'Csv', value: 'Csv' },
      ];

      const availableEnableOptions = [
        { id: '1', name: 'Yes' },
        { id: '2', name: 'No' },
      ];

      const newDLPConnectionModel = this;

      newDLPConnectionModel.fields = {};
      newDLPConnectionModel.fieldsDDL = {};
      newDLPConnectionModel.filenameToImport = '';
      newDLPConnectionModel.importing = false;
      newDLPConnectionModel.importPayload = {
        isIncludeHeaders: false,
        fileToImport: '',
      };
      newDLPConnectionModel.isTestConnectionRunning = false;
      newDLPConnectionModel.testConnectionGrid = {
        enableSorting: true,
        enableFiltering: true,
        enableRowSelection: false,
        multiSelect: false,
        enableRowHeaderSelection: false,
        selectionRowHeaderWidth: 35,
        columnDefs: [
          {
            name: 'Data Source Name',
            field: 'key',
            cellTemplate:
              '<div style="margin:4px"> {{row.entity.key}}<span style="font-size:13px !important;"></span></div>',
          },
          {
            name: 'Supported in Bigid System',
            field: 'value',
            cellTemplate:
              '<div style="margin:4px"> {{row.entity.value}}<span style="font-size:13px !important;"></span></div>',
          },
        ],
        data: [],
      };

      const selectedName = $stateParams.name;

      //case of update
      if (selectedName) {
        dlpConnectionsService.getDLPConnectionsDataByID(selectedName).then(result => {
          if (result && result.dlp_connection) {
            //name
            newDLPConnectionModel.fields.name = result.dlp_connection.name;
            //type
            if (result.dlp_connection.type) {
              const dlpConnectionType =
                result.dlp_connection.type.charAt(0).toUpperCase() + result.dlp_connection.type.slice(1);
              newDLPConnectionModel[dlpConnectionType] = true;
              newDLPConnectionModel.fieldsDDL.selectedTypeOption = getSelectedObject(
                dlpConnectionType,
                availableTypeOptions,
              );
            }
            //enabled
            if (result.dlp_connection.enabled) {
              newDLPConnectionModel.fieldsDDL.selectedEnabledOption = getSelectedObject(
                result.dlp_connection.enabled,
                availableEnableOptions,
              );
            }
            newDLPConnectionModel.fields.targetHost = result.dlp_connection.targetHost;
            newDLPConnectionModel.fields.targetPort = result.dlp_connection.targetPort;
            newDLPConnectionModel.fields.username = result.dlp_connection.username;
            newDLPConnectionModel.fields.password = '********';
            newDLPConnectionModel.fields.clientId = result.dlp_connection.clientId;
            newDLPConnectionModel.fields.client_secret = '********';

            // Fields for DataHub
            newDLPConnectionModel.fields.dataHubUrl = result.dlp_connection.dataHubUrl;
            newDLPConnectionModel.fields.port = result.dlp_connection.port;
          }
        });
      }
      //case of insert
      else {
        newDLPConnectionModel.Guardium = true;
        newDLPConnectionModel.fields.password = '';
      }

      newDLPConnectionModel.$onInit = () => {
        newDLPConnectionModel.isSavePermitted = $stateParams.name
          ? isPermitted(SECONDARY_SOURCE_PERMISSIONS.EDIT.name)
          : isPermitted(SECONDARY_SOURCE_PERMISSIONS.CREATE.name);

        const pageTitle = $stateParams.name ? 'Edit Secondary Source Connections' : 'New Secondary Source Connection';
        const breadcrumb = 'Secondary Sources';

        $rootScope.$broadcast('changePage', pageTitle, false, true, null, [
          { label: breadcrumb, onClick: () => $state.go('dlpConnections') },
          { label: $stateParams.name ?? pageTitle },
        ]);
      };

      newDLPConnectionModel.type = {
        availableOptions: availableTypeOptions,
      };

      newDLPConnectionModel.enabled = {
        availableOptions: availableEnableOptions,
      };

      newDLPConnectionModel.fieldsDDL.selectedEnabledOption = {
        id: '1',
        name: 'Yes', //This sets the default value of the select in the ui
      };

      newDLPConnectionModel.fieldsDDL.selectedTypeOption = {
        id: '1',
        name: 'Guardium', //This sets the default value of the select in the ui
      };

      const set_dlp_connections = () => {
        $scope.dlp_connection = {};

        if (newDLPConnectionModel.fields.password === '********') {
          newDLPConnectionModel.fields.password = null;
        } else if (newDLPConnectionModel.fields.password === '') {
          delete newDLPConnectionModel.fields.password;
        }

        if (newDLPConnectionModel.fields.client_secret === '********') {
          newDLPConnectionModel.fields.client_secret = null;
        } else if (newDLPConnectionModel.fields.client_secret === '') {
          delete newDLPConnectionModel.fields.client_secret;
        }

        $scope.dlp_connection.dlp_connection = angular.copy(newDLPConnectionModel.fields);
        $scope.dlp_connection.dlp_connection.type =
          newDLPConnectionModel.fieldsDDL.selectedTypeOption.name.toLowerCase();

        $scope.dlp_connection.dlp_connection.enabled =
          newDLPConnectionModel.fieldsDDL.selectedEnabledOption.name.toLowerCase();
        if (newDLPConnectionModel.fieldsDDL.selectedTypeOption === 'datahub') {
          $scope.dlp_connection.dlp_connection.dataHubUrl = newDLPConnectionModel.fields.dataHubUrl.toLowerCase();
          $scope.dlp_connection.dlp_connection.port = newDLPConnectionModel.fields.port;
        }
      };

      const getSelectedObject = (typeName, availableTypeOptions) => {
        let id;
        let name;
        for (let i = 0; i < availableTypeOptions.length; i++) {
          if (availableTypeOptions[i].name.toLowerCase() === typeName.toString().toLowerCase()) {
            id = availableTypeOptions[i].id;
            name = availableTypeOptions[i].name;
            break;
          }
        }

        return { id: id, name: name };
      };

      const unsetModelTypes = () => {
        for (const i in availableTypeOptions) {
          newDLPConnectionModel[availableTypeOptions[i]['name']] = false;
        }
      };

      const setModelType = prop => {
        $timeout(() => {
          unsetModelTypes();
          newDLPConnectionModel[prop] = true;
        });
      };

      newDLPConnectionModel.update = type => {
        setModelType(type);
      };

      newDLPConnectionModel.submit = isValid => {
        if (isValid) {
          if (newDLPConnectionModel.Csv) {
            newDLPConnectionModel.import();
          } else {
            set_dlp_connections();
            //case of update

            if ($stateParams.name) {
              $http
                .put(
                  appSettings.serverPath + '/' + appSettings.version + '/dlp_connections/' + $stateParams.name,
                  $scope.dlp_connection,
                )
                .then(
                  response => {
                    notificationService.success('Updated ' + $stateParams.name + ' Successfully! ');
                    $state.go('dlpConnections');
                  },
                  response => {
                    newDLPConnectionModel.fields.password = '********';
                    newDLPConnectionModel.fields.client_secret = '********';
                    if (response.data) {
                      notificationService.error(response.data, 'Update DLP Connection Failed!');
                    }
                  },
                );
            }
            //case of insert
            else {
              $http
                .post(appSettings.serverPath + '/' + appSettings.version + '/dlp_connections', $scope.dlp_connection)
                .then(
                  response => {
                    notificationService.success('Saved Successfully! ');
                    $state.go('dlpConnections');
                  },
                  response => {
                    newDLPConnectionModel.fields.password = '********';
                    newDLPConnectionModel.fields.client_secret = '********';
                    if (response.data) {
                      notificationService.error(response.data, 'Saved DLP Connection Failed!');
                    }
                  },
                );
            }
          }
        } else {
          newDLPConnectionModel.inputNotValid = true;
        }
      };

      newDLPConnectionModel.fileSelected = file => {
        newDLPConnectionModel.importPayload.fileToImport = file;
        newDLPConnectionModel.filenameToImport = file.name;
      };

      newDLPConnectionModel.fileRemoved = () => {
        newDLPConnectionModel.filenameToImport = '';
        newDLPConnectionModel.importing = false;
        newDLPConnectionModel.importPayload = {
          isIncludeHeaders: false,
          fileToImport: '',
        };
      };

      newDLPConnectionModel.testDataHub = () => {
        newDLPConnectionModel.isTestConnectionRunning = true;
        dlpConnectionsService
          .testDataHubConnection(newDLPConnectionModel.fields)
          .then(response => {
            if (!response.data) {
              notificationService.error('Test connection was unsuccessful!');
            } else {
              newDLPConnectionModel.testConnectionGrid.data = Object.entries(response.data).reduce(
                (aggregator, entry) => {
                  const [key, value] = entry;
                  return [...aggregator, { key, value }];
                },
                [],
              );
              notificationService.success('Test Connection Succeeded!');
            }
          })
          .catch(() => {
            notificationService.error('Test Connection was unsuccessful!');
          })
          .finally(() => {
            newDLPConnectionModel.isTestConnectionRunning = false;
          });
      };

      newDLPConnectionModel.setIncludeHeadersFlag = () => {
        $timeout(() => {
          newDLPConnectionModel.importPayload.isIncludeHeaders = !newDLPConnectionModel.importPayload.isIncludeHeaders;
        });
      };

      newDLPConnectionModel.import = () => {
        newDLPConnectionModel.importing = true;

        const formData = new FormData();

        formData.append('isIncludeHeaders', newDLPConnectionModel.importPayload.isIncludeHeaders);
        formData.append('fileToImport', newDLPConnectionModel.importPayload.fileToImport);
        formData.append('dlpSourceName', angular.copy(newDLPConnectionModel.fields.name));
        formData.append('dlpConnectionType', angular.copy(newDLPConnectionModel.fieldsDDL.selectedTypeOption.name));

        dlpConnectionsService.importFromFile(formData).then(
          response => {
            newDLPConnectionModel.importing = false;
            if (response) {
              let result = '';
              for (const i in response) {
                result = result + i + ' :' + response[i] + '; ';
              }
              notificationService.success(result);
              $state.go('dlpConnections');
            }
          },
          () => {
            newDLPConnectionModel.importing = false;
            notificationService.error('An error has occurred!');
          },
        );
      };
    },
  ],
});
