import React, { SyntheticEvent, useState, useEffect } from 'react';
import { Guide } from '../mappers/styles';
import Markdown from 'react-markdown';
import {
  BigidAccordion,
  BigidAccordionSummary,
  AccordionSummarySizeEnum,
  BigidAccordionDetails,
  BigidHeading6,
  BigidBody1,
} from '@bigid-ui/components';
import { getMarkdownComponentOverrides } from '../config/markdown';
import { MARKDOWN_SELECTOR } from '../constants/constants';
import type { DataSourceConnectionGuideTab as DataSourceConnectionGuideTabType } from './types';

type DataSourceConnectionGuideTabProps = DataSourceConnectionGuideTabType & {
  dataAid: string;
  className?: string;
};

const components = getMarkdownComponentOverrides();

const style = {
  padding: '8px 32px 12px !important',
  overflow: 'hidden',
  wordBreak: 'break-word',
};

export const DataSourceConnectionGuideTab = ({
  dataAid,
  className = 'DataSourceConnectionGuideTab',
  title,
  content,
  id,
  isOpen = false,
}: DataSourceConnectionGuideTabProps) => {
  const [isExpanded, setTabExpanded] = useState(isOpen);
  const isContentAvailable = !!content;
  const handleTabChange = (_: SyntheticEvent, expanded: boolean) => setTabExpanded(expanded);

  useEffect(() => {
    setTabExpanded(isOpen);
  }, [isOpen]);

  return (
    isContentAvailable && (
      <BigidAccordion
        expanded={isExpanded}
        className={className}
        dataAid={dataAid}
        onChange={handleTabChange}
        sx={{ borderRadius: '8px !important' }}
        key={id}
      >
        <BigidAccordionSummary
          sx={{
            width: '100%',
          }}
          size={AccordionSummarySizeEnum.large}
        >
          <Guide.Tab>
            <BigidHeading6 sx={{ cursor: 'pointer' }}>{title}</BigidHeading6>
          </Guide.Tab>
        </BigidAccordionSummary>
        <BigidAccordionDetails sx={style}>
          <BigidBody1>
            <Markdown className={`${MARKDOWN_SELECTOR}__${id}`} components={components}>
              {content}
            </Markdown>
          </BigidBody1>
        </BigidAccordionDetails>
      </BigidAccordion>
    )
  );
};
