export interface QueryParams {
  [key: string]: boolean | number | string;
}

export const objectToQueryString = function (params: QueryParams = {}): string {
  return Object.entries(params)
    .filter(entry => entry[1])
    .map(entry => `${encodeURIComponent(entry[0].toString())}=${encodeURIComponent(entry[1].toString())}`)
    .join('&');
};

export const queryStringToObject = function (query = ''): QueryParams {
  //TBD:
  return {};
};

export const urlUtilities = {
  objectToQueryString,
  queryStringToObject,
};
