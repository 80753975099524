import React, { FC } from 'react';
import { SxProps, styled } from '@mui/material';
import { State, States } from './types';
import { colors } from './constants';

const Wrapper = styled('div')`
  display: flex;
  width: 100%;
  height: 8px;
  border-radius: 24px;
  background: #e6e6e6;
  overflow: hidden;
`;
export const Inner = styled('div', {
  shouldForwardProp: prop => prop !== 'state' && prop !== 'width',
})<{ state: State; width: number }>(({ state, width }) => ({
  backgroundColor: colors[state],
  height: '100%',
  cursor: 'pointer',
  flexBasis: width + '%',
  marginRight: width ? '2px' : '0px',
  transition: 'all 200ms',
  transform: 'scale(1.0)',
  '&:last-of-type': {
    marginRight: '-2px',
  }
}));

export type Props = {
  states: States;
  onClick?: (state: State) => void;
  sx?: SxProps;
};

export const Progress: FC<Props> = ({ states = {}, onClick, sx }) => {
  const allKeys = Object.keys(State) as Array<State>;
  const currentKeys = Object.keys(states) as Array<keyof typeof states>;
  const total = states.total || currentKeys.reduce((acc, curr) => acc + states[curr] ?? 0, 0);

  return (
    <Wrapper sx={sx}>
      {allKeys
        .filter(item => item !== 'total')
        .map(key => {
          const value = states[key] ?? 0;
          const width = (value / total) * 100;

          return <Inner key={key} state={key} width={width} onClick={() => onClick?.(key)} />;
        })}
    </Wrapper>
  );
};
