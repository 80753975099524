import { useEffect, useState } from 'react';
import { smallIdLicenseService } from '../../services/smallIdLicenseService';
import { SystemEvents, systemEventsEmitter } from '../../services/systemEvents';

export const useIsSmallIdLicense = () => {
  const [isSmallId, setIsSmallId] = useState(smallIdLicenseService.isSmallIDLicense());
  const updateIsSmallId = () => {
    setIsSmallId(smallIdLicenseService.isSmallIDLicense());
  };
  useEffect(() => {
    const unregisterUpdateIsSmallIdListener = systemEventsEmitter.addEventListener(
      SystemEvents.UPDATE_LICENSE,
      updateIsSmallId,
    );
    return () => {
      unregisterUpdateIsSmallIdListener();
    };
  }, []);

  return isSmallId;
};
