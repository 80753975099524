import { BigidFilter, ChartType, EntityEvents, FunnelChartData, entityEventsEmitter } from '@bigid-ui/components';
import { getApplicationPreference } from '../../../../services/appPreferencesService';
import { DS_ENRICHMENT_STATUS_FIELD } from './constants/enrichment';
import { INITIATIVE_FIELD_LABEL } from '../../DataSourceSuggestedActions/constants/actions';
import { Initiative } from './types';
import { isPermitted } from '../../../../services/userPermissionsService';
import { SCANS_PERMISSIONS } from '@bigid/permissions';

export const omitStatusFilterFromCurrentFilter = (filters: BigidFilter) =>
  filters?.filter(({ field }) => field !== DS_ENRICHMENT_STATUS_FIELD);

export const omitInitiativeFilterFromCurrentFilter = (filters: BigidFilter) =>
  filters?.filter(({ field }) => field !== INITIATIVE_FIELD_LABEL);

export const pickInitiativeFilterFromCurrentFilter = (filters: BigidFilter) =>
  filters?.filter(({ field }) => field === INITIATIVE_FIELD_LABEL);

export const isFilterContainsInitiative = (filters: BigidFilter) =>
  filters?.some(({ field }) => field === INITIATIVE_FIELD_LABEL);

export const isInitiativeInFilter = (filters: BigidFilter, initiative: Initiative) => {
  const [initiativeFilter] = pickInitiativeFilterFromCurrentFilter(filters);
  return initiativeFilter?.value === initiative;
};

const resetChartDataSelection = (chartData: FunnelChartData[]) =>
  chartData?.map(props => ({
    ...props,
    isMainChartSelected: false,
    isDropChartSelected: false,
  }));

export const updateChartSelection = (
  chartData: FunnelChartData[],
  selection: [string, ChartType],
): FunnelChartData[] => {
  const [chartId, chartType] = selection ?? [];
  const unselectedChartData = resetChartDataSelection(chartData);

  if (!selection) return unselectedChartData;

  const preselectedChartData = unselectedChartData.map(({ id, ...rest }) =>
    id !== chartId
      ? { id, ...rest }
      : {
          ...rest,
          id,
          isMainChartSelected: chartType === ChartType.MAIN_CHART,
          isDropChartSelected: chartType === ChartType.DROP_CHART,
        },
  );

  return preselectedChartData;
};

export const isMaintainCoverageActionsVisible = (
  filter: BigidFilter,
  selectedRowIds: (string | number)[],
  isOnboardingAssistantEnabled: boolean,
) => {
  return (
    isOnboardingAssistantEnabled &&
    isScanPermitted() &&
    selectedRowIds.length > 0 &&
    isInitiativeInFilter(filter, Initiative.MAINTAIN_DATA_COVERAGE)
  );
};

export const isScanPermitted = () => {
  return getApplicationPreference('ENABLE_SCAN_TEMPLATES') && isPermitted(SCANS_PERMISSIONS.CREATE_SCAN_PROFILES.name);
};
