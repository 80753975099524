import { openSystemDialog } from '../../services/systemDialogService';
import { Attribute, SearchAttributesInfo, SearchAttributesInfoProps } from './SearchAttributesInfo';
import { httpService } from '../../services/httpService';
import { notificationService } from '../../services/notificationService';
import { noop } from 'lodash';

export const openSearchAttributesInfoDialog = async (name: string, requestId: string) => {
  let sarSearchAttributes;
  openSystemDialog({
    title: `${name} Request Attributes`,
    onClose: noop,
    content: null,
    borderTop: true,
    maxWidth: 'sm',
    withCloseButton: true,
    isLoading: true,
  });

  try {
    const { data } = await httpService.fetch(`sar/reports/${requestId}/searched-attributes`);
    sarSearchAttributes = data;
  } catch (err) {
    const errMsg = 'error getting DSAR search attributes';
    console.error(`${errMsg} ${err}`);
    notificationService.error(errMsg);
    openSystemDialog({
      title: `${name} Request Attributes`,
      onClose: noop,
      content: null,
      borderTop: true,
      maxWidth: 'sm',
      withCloseButton: true,
    });
    return;
  }
  const attributes = createAttributesObject(sarSearchAttributes);

  openSystemDialog<SearchAttributesInfoProps>({
    title: `${name} Request Attributes`,
    onClose: noop,
    content: SearchAttributesInfo,
    contentProps: { attributes },
    borderTop: true,
    maxWidth: 'sm',
    withCloseButton: true,
  });
};

function createAttributesObject(sarSearchAttributes: any) {
  const attributes = sarSearchAttributes?.searchedAttributes?.allAttributes;
  let attributesObject: Attribute[] = [];
  if (attributes) {
    attributesObject = Object.keys(attributes).map(name => ({
      name,
      value: attributes[name],
    }));
  }
  return attributesObject;
}
