import React, { FC, useMemo, useCallback } from 'react';
import {
  BigidGridProps,
  BigidGrid,
  BigidGridColumnTypes,
  FetchDataFunction,
  BigidGridColumn,
  useFetch,
  BigidGridRow,
} from '@bigid-ui/grid';
import { BigidStatusBadge, BigidStatusBadgeSize } from '@bigid-ui/components';
import { useTheme } from '@mui/styles';
import { useLocalTranslation } from '../translations';
import { AuditTrailRecord, getAuditTrailRecords, AuditTrailRequestPayload } from '../dataCatalogAuditTrailService';
import { getChangeTypeCellValue, getEventCellBadgeProps, getValueCellComponent } from './utils';
import { notificationService } from '../../../../services/notificationService';
import { uniqueId } from 'lodash';
import { useAuditTrialGridContext } from '../dataCatalogAuditContext';

export type AuditTrailRecordGridItem = AuditTrailRecord & BigidGridRow;

interface GridProps {
  dataAid: string;
  fullyQualifiedName: string;
  query?: string; //NOTE: for GA when we have filters
  onRowClick: (record: AuditTrailRecordGridItem) => void;
}

export const Grid: FC<GridProps> = ({ dataAid, fullyQualifiedName, onRowClick }) => {
  const { t } = useLocalTranslation('grid');
  const {
    state: { filterString, searchQuery },
  } = useAuditTrialGridContext();
  const theme = useTheme();
  const fetchDataFunction: FetchDataFunction<AuditTrailRecordGridItem> = useCallback(
    async queryComponents => {
      try {
        const { sort, skip, limit } = queryComponents;
        const payload: AuditTrailRequestPayload = {
          sort,
          filter: filterString,
          searchText: searchQuery,
          paging: {
            limit,
            skip,
          },
        };
        const { data } = await getAuditTrailRecords(fullyQualifiedName, payload);

        return {
          data: data.map(record => ({ id: uniqueId(), ...record })),
          totalCount: data.length,
        };
      } catch ({ message }) {
        notificationService.error('An error has occurred');
        console.error(`An error has occurred: ${message}`);

        return {
          data: [],
          totalCount: 0,
        };
      }
    },
    [fullyQualifiedName],
  );

  const columns: BigidGridColumn<AuditTrailRecordGridItem>[] = useMemo(() => {
    return [
      {
        title: t('columns.timestamp'),
        name: 'createdAt',
        type: BigidGridColumnTypes.DATE,
        getCellValue: ({ createdAt }) => createdAt,
        width: 150,
        sortingEnabled: false,
      },
      {
        title: t('columns.triggeredBy'),
        name: 'eventSource',
        type: BigidGridColumnTypes.TEXT,
        getCellValue: ({ eventSource }) => eventSource,
        width: 250,
        sortingEnabled: false,
      },
      {
        title: t('columns.eventId'),
        name: 'eventId',
        type: BigidGridColumnTypes.TEXT,
        getCellValue: ({ eventId }) => eventId,
        width: 250,
        sortingEnabled: false,
      },
      {
        title: t('columns.event'),
        name: 'changedProperty',
        type: BigidGridColumnTypes.TEXT,
        getCellValue: ({ changedProperty }) => {
          return getChangeTypeCellValue(changedProperty);
        },
        width: 120,
        sortingEnabled: false,
      },
      {
        title: t('columns.changeType'),
        name: 'propertyOperation',
        type: BigidGridColumnTypes.CUSTOM,
        getCellValue: ({ propertyOperation }) => {
          const { label, type } = getEventCellBadgeProps(propertyOperation);

          return <BigidStatusBadge label={label} type={type} size={BigidStatusBadgeSize.SMALL} />;
        },
        width: 130,
        sortingEnabled: false,
      },
      {
        title: t('columns.value'),
        name: 'name',
        type: BigidGridColumnTypes.TEXT,
        getCellValue: ({ name }) => {
          return getValueCellComponent(name, theme);
        },
        width: 280,
        sortingEnabled: false,
      },
    ];
  }, [t, theme]);
  const useFetchState = useFetch({
    pageSize: 100,
    fetchDataFunction,
    initialSorting: [
      {
        field: 'Timestamp',
        order: 'desc',
      },
    ],
  });
  const gridConfig: BigidGridProps<AuditTrailRecordGridItem> = useMemo(
    () => ({
      dataAid,
      columns,
      onRowClick: (record: AuditTrailRecordGridItem) => {
        onRowClick(record);
      },
      pageSize: useFetchState.pageSize,
      rows: useFetchState.rows,
      showSelectAll: false,
      skip: useFetchState.skip,
      onPagingChanged: useFetchState.onPagingChanged,
      onSortingChanged: useFetchState.onSortingChanged,
      totalRowsCount: useFetchState.totalRowsCount,
      onFiltersChange: useFetchState.onFiltersChanged,
      defaultSorting: useFetchState.defaultSorting,
      apiRef: useFetchState.apiRef,
      loading: useFetchState.isLoading,
    }),
    [columns, dataAid, onRowClick, useFetchState],
  );

  return <BigidGrid dataAid={dataAid} {...gridConfig} />;
};
