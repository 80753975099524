import React, { FC, useMemo, useRef, useState } from 'react';
import {
  BigidGridColumn,
  BigidGridColumnTypes,
  BigidGridQueryComponents,
  BigidGridWithToolbar,
  BigidGridWithToolbarProps,
} from '@bigid-ui/grid';
import { notificationService } from '../../../../../../services/notificationService';
import {
  ClassifiersResponse,
  getNormalizedClassifiersViaPost,
  NormalizedClassifier,
} from '../../../../../Classification/ClassifiersService';
import { ScanTemplateFormContext } from '../../../scanTemplateContext';
import { BigidDialogButtonType, BigidFieldFilterOperator, ToolbarActionType, ActionData } from '@bigid-ui/components';
import { difference, isEqual, isObject, some, uniq } from 'lodash';
import { BulkClassifierSelectionGridRow } from './classificationStepTypes';
import { getClassifierIds, getClassifierIdsOfBulkSelected, getNormalizedName } from './classificationService';
import { openSystemDialog } from '../../../../../../services/systemDialogService';
import styled from '@emotion/styled';
import { NoDataClassificationStep } from './NoDataClassificationStep';
import { ClassifiersInfoNotification } from '../../../../../../components/ClassifiersInfoNotification/ClassifiersInfoNotification';
import {
  getIsClassifyFileNamesEnabled,
  getIsNerClassifierFFEnabled,
} from '../../../../../ClassifiersManagement/utils/utils';

const ClassificationManualSelectionGridWrapper = styled('div')({
  display: 'flex',
  flex: '1 1 auto',
  border: 'none',
  height: '60vh',
  overflow: 'hidden',
  position: 'relative',
  flexFlow: 'column nowrap',
});

interface ClassificationManualSelectionGridProps {
  setManuallySelectedClassifiers: ((classifiers: string[]) => void) | ((classifiers: NormalizedClassifier[]) => void);
  isBulkSelection: boolean;
  scanTemplateContext: Pick<ScanTemplateFormContext, 'scanTemplateFormData' | 'setScanTemplateFormData'>;
  bulkSelectedClassifiers?: BulkClassifierSelectionGridRow;
}

interface OpenManualClassifierSelectionParams extends ClassificationManualSelectionGridProps {
  buttons: BigidDialogButtonType[];
}

const ManuallySelectClassifiersGridColumns: BigidGridColumn<NormalizedClassifier>[] = [
  {
    title: 'Name',
    name: 'name',
    width: 450,
    type: BigidGridColumnTypes.TEXT,
    sortingEnabled: true,
    getCellValue: getNormalizedName,
  },
  {
    title: 'Description',
    name: 'description',
    sortingEnabled: true,
    width: 450,
    type: BigidGridColumnTypes.TEXT,
    getCellValue: ({ originalData, type }) => {
      const [originalDataObject] = originalData as ClassifiersResponse[];
      return originalDataObject?.description ? originalDataObject.description : `Type = ${type}`;
    },
  },
];

export const ClassificationManualSelectionGrid: FC<ClassificationManualSelectionGridProps> = ({
  scanTemplateContext,
  setManuallySelectedClassifiers,
  isBulkSelection,
  bulkSelectedClassifiers,
}) => {
  const [isShowSelectedToggleActive, setIsShowSelectedToggleActive] = useState(false);
  const { scanTemplateFormData } = scanTemplateContext;
  const { classifiers = [], bulkClassifierSelection = [] } = scanTemplateFormData;
  const selectedRowIdsRef = useRef([]);

  const currentRegulation = scanTemplateFormData.bulkClassifierSelection?.find(
    ({ name }) => name === bulkSelectedClassifiers?.name,
  );

  const selectedClassifiersFromRegulations = useMemo(
    () => (currentRegulation ? currentRegulation?.selectedClassifiers : []),
    [currentRegulation],
  );

  const classifiersFromRegulations = isBulkSelection
    ? bulkSelectedClassifiers.selectedClassifiers
    : getClassifierIdsOfBulkSelected(bulkClassifierSelection);

  const classifierNamesAsIds = getClassifierIds(classifiers);
  const manuallySelectedClassifiers = difference(classifierNamesAsIds, classifiersFromRegulations);

  const correlationSetSelectionGridConfig: BigidGridWithToolbarProps<NormalizedClassifier> = useMemo(
    () => ({
      columns: ManuallySelectClassifiersGridColumns,
      pageSize: 100,
      customRowIdName: 'originalName',
      entityName: 'Classifiers',
      defaultSorting: [{ field: 'name', order: 'asc' }],
      showSortingControls: true,
      preSelectedIds: isBulkSelection ? selectedClassifiersFromRegulations : manuallySelectedClassifiers,
      shouldKeepSelectionAfterFilter: true,
      filterToolbarConfig: {
        filters: [
          {
            title: 'Origin',
            field: 'isOutOfTheBox',
            operator: 'in',
            options: [
              {
                value: true,
                label: 'BigID',
                isSelected: false,
              },
              {
                value: false,
                label: 'Custom',
                isSelected: false,
              },
            ],
            value: [],
          },
          {
            title: 'Type',
            field: 'type',
            operator: 'in',
            options: [
              {
                label: 'Regex (Values)',
                value: [null, 'data'],
                isSelected: false,
              },
              {
                label: 'Regex (Metadata)',
                value: 'columnName',
                isSelected: false,
              },
              ...(getIsClassifyFileNamesEnabled()
                ? [
                    {
                      label: 'Regex (Values + Metadata)',
                      value: 'data-and-metadata',
                      isSelected: false,
                    },
                  ]
                : []),
              ...(getIsNerClassifierFFEnabled()
                ? [
                    {
                      label: 'DOC',
                      value: 'DOC',
                      isSelected: false,
                    },
                  ]
                : []),
              ...(getIsNerClassifierFFEnabled()
                ? [
                    {
                      label: 'NLP/NER',
                      value: 'NER',
                      isSelected: false,
                    },
                  ]
                : []),
            ],
            value: [],
          },
          {
            title: 'Global list',
            field: 'enabled',
            operator: 'in',
            value: [],
            options: [
              {
                value: true,
                label: 'Included',
                isSelected: false,
              },
              {
                label: 'Not included',
                value: false,
                isSelected: false,
              },
            ],
          },
        ],
        searchConfig: {
          searchFilterKeys: ['name'],
          placeholder: 'Name',
          initialValue: '',
          operator: 'textSearch',
          autoSubmit: true,
        },
      },
      rowClickShouldKeepSelection: true,
      hideColumnChooser: true,
      showSelectAll: false,
      onGridStateChange: ({ selectedRows, selectedRowIds }) => {
        const isRowChanged = !isEqual(selectedRows, selectedRowIdsRef.current);

        if (isRowChanged) {
          selectedRowIdsRef.current = isBulkSelection ? selectedRows : selectedRowIds;
          setManuallySelectedClassifiers(selectedRowIdsRef.current);
        }
      },
      toolbarActions: [
        {
          label: 'action_for_toolbar',
          execute: async () => {
            return {};
          },
          disable: () => false,
          show: () => false,
          placement: 'end',
        },
        {
          label: 'show selected',
          execute: async (_actionData: ActionData) => {
            setIsShowSelectedToggleActive(isActive => !isActive);
            return {
              shouldGridReload: true,
            };
          },
          disable: () => false,
          show: () => true,
          placement: 'end',
          type: ToolbarActionType.SWITCH,
          switchProps: {
            isChecked: _actionData => isShowSelectedToggleActive,
          },
        },
      ],
      noDataContent: <NoDataClassificationStep />,
      fetchData: async (queryComponents: BigidGridQueryComponents) => {
        const hasObjectsClassifiers = some(selectedRowIdsRef.current, isObject);
        let selectedRowsNames = [...selectedRowIdsRef.current];
        if (hasObjectsClassifiers) {
          selectedRowsNames = selectedRowIdsRef.current.map(selectedRow => selectedRow.name);
        }
        const classifiersRowsSelected = isBulkSelection
          ? selectedRowsNames
          : uniq([...manuallySelectedClassifiers, ...selectedRowsNames]);

        try {
          const initialFilter = [
            {
              field: 'name',
              value: classifiersFromRegulations,
              operator: (isBulkSelection ? 'in' : 'notIn') as BigidFieldFilterOperator,
            },
          ];
          const selectedFilter = isShowSelectedToggleActive
            ? [
                {
                  field: 'name',
                  operator: 'in' as BigidFieldFilterOperator,
                  value: classifiersRowsSelected,
                },
              ]
            : [];

          const queryObject = {
            ...queryComponents,
            filter: [...(isShowSelectedToggleActive ? selectedFilter : initialFilter), ...queryComponents.filter],
          };
          const { classifiers, totalCount } = await getNormalizedClassifiersViaPost(queryObject, classifierNamesAsIds);
          return {
            data: classifiers,
            totalCount,
          };
        } catch (e) {
          console.error(e);
          notificationService.error('Could not fetch classifiers, sorry about that. See logs for more information');
          return {
            data: [],
            totalCount: 0,
          };
        }
      },
    }),
    [
      classifiersFromRegulations,
      isBulkSelection,
      manuallySelectedClassifiers,
      selectedClassifiersFromRegulations,
      setManuallySelectedClassifiers,
      isShowSelectedToggleActive,
    ],
  );

  return <BigidGridWithToolbar {...correlationSetSelectionGridConfig} />;
};

export const openManualClassifierSelection = async ({
  scanTemplateContext,
  setManuallySelectedClassifiers,
  bulkSelectedClassifiers,
  isBulkSelection,
  buttons,
}: OpenManualClassifierSelectionParams) => {
  const { setScanTemplateFormData, scanTemplateFormData } = scanTemplateContext;

  return openSystemDialog({
    title: 'Choose Classifiers',
    buttons,
    onClose: () => null,
    maxWidth: 'lg',
    content: () => (
      <>
        <ClassifiersInfoNotification />
        <ClassificationManualSelectionGridWrapper>
          <ClassificationManualSelectionGrid
            setManuallySelectedClassifiers={setManuallySelectedClassifiers}
            isBulkSelection={isBulkSelection}
            scanTemplateContext={{
              setScanTemplateFormData,
              scanTemplateFormData,
            }}
            bulkSelectedClassifiers={bulkSelectedClassifiers}
          />
        </ClassificationManualSelectionGridWrapper>
      </>
    ),
    contentProps: { setManuallySelectedClassifiers },
  });
};
