import { module } from 'angular';
const app = module('app');

app.factory('explorationService', [
  '$http',
  'appSettings',
  function ($http, appSettings) {
    return {
      generateDataMap: (queryFilter, name) => {
        queryFilter = queryFilter || '';
        return $http
          .post(`${appSettings.serverPath}/${appSettings.version}/exploration/generateDataMap`, {
            queryFilter: queryFilter,
            businessProcessName: name,
          })
          .then(response => response.data)
          .catch(function (response) {
            return { err: response.data.message };
          });
      },
      getSavedQueries: () =>
        $http
          .get(appSettings.serverPath + '/' + appSettings.version + '/saved-queries')
          .then(response => response.data),
    };
  },
]);
