import React, { FC, useMemo, memo } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import {
  BigidQueryBuilder,
  QueryLogicalOperatorMap,
  QueryMathOperatorMap,
  QueryTree,
  QueryComposerOperandConfig,
} from '@bigid-ui/components';
import { SystemAttribute } from '../../DataCatalog/DataCatalogService';
import { cloneDeep } from 'lodash';

export interface CatalogRuleQueryBuilderProps {
  dataAid?: string;
  query: QueryTree;
  attributesList: SystemAttribute[];
  isReadOnly: boolean;
  onChangeQueryBuilder: (query: QueryTree) => void;
}

const useStyles = makeStyles({
  root: {
    width: '100%',
    maxHeight: 'calc(100% - 40px)',
    paddingTop: '20px',
  },
});

const defaultQuery = {
  id: '0',
  operator: QueryLogicalOperatorMap.AND,
  rules: [
    {
      id: '0-1',
      leftOperand: 'attribute',
      operator: QueryMathOperatorMap.EQUAL,
      parentId: '0',
      rightOperand: '',
    },
  ],
};

const logicalOperators = [QueryLogicalOperatorMap.AND, QueryLogicalOperatorMap.OR];

export const CatalogRuleQueryBuilder: FC<CatalogRuleQueryBuilderProps> = memo(
  ({ dataAid = 'CatalogRuleQueryBuilder', attributesList, query, isReadOnly, onChangeQueryBuilder }) => {
    const classes = useStyles();

    const operands: QueryComposerOperandConfig[] = useMemo(
      () => [
        {
          leftOperand: {
            label: 'Attribute',
            value: 'attribute',
          },
          rightOperands: attributesList.map(
            ({ attribute_id, attribute_name, attribute_original_name, attribute_original_type, attribute_type }) => {
              return {
                id: attribute_id,
                label: attribute_name,
                subLabel:
                  attribute_type === 'OOTB'
                    ? 'Friendly Name'
                    : `${attribute_original_name} - ${attribute_original_type || attribute_type}`,
                value: attribute_original_name,
              };
            },
          ),
        },
      ],
      [attributesList],
    );

    return (
      <div className={classes.root}>
        <BigidQueryBuilder
          dataAid={dataAid}
          logicalOperators={logicalOperators}
          onQueryChange={onChangeQueryBuilder}
          operands={operands}
          initialQuery={query || cloneDeep(defaultQuery)}
          isLeftOperandDisabled
          isReadOnly={isReadOnly}
          menuPortalTarget={document.body}
        />
      </div>
    );
  },
);

CatalogRuleQueryBuilder.displayName = 'CatalogRuleQueryBuilder';
