import React, { FC, useContext } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { ReducerActions } from '../hooks/GettingStartedReducer';
import { GettingStartedContext } from '../hooks/GettingStartedContext';
import { GettingStartedItem } from '../GettingStartedItems';
import { BigidSubtitle, BigidHeading6 } from '@bigid-ui/components';
import classnames from 'classnames';
import { BigidChevronRightIcon } from '@bigid-ui/icons';
import { generateDataAid } from '@bigid-ui/utils';

const useStyles = makeStyles(theme => ({
  root: {
    width: '50%',
    backgroundColor: theme.vars.palette.bigid.white,
  },
  menuListItem: {
    display: 'flex',
    flexDirection: 'column',
    padding: 32,
  },
  menuItem: {
    padding: '20px',
    boxSizing: 'border-box',
    background: theme.vars.palette.bigid.white,
    border: '1px solid transparent',
    boxShadow: '0px 0px 5px rgba(0, 0, 0, 0.15)',
    borderRadius: '4px',
    margin: '10px',
    color: theme.vars.palette.bigid.primary400,
    display: 'flex',
    flexDirection: 'row',
    cursor: 'pointer',
    '&:hover': {
      border: `1px solid ${theme.vars.palette.bigid.primary600}`,
    },
  },
  selectedMenuItem: {
    padding: '20px',
    boxSizing: 'border-box',
    background: theme.vars.palette.bigid.primary50,
    border: `1px solid ${theme.vars.palette.bigid.primary400}`,
    boxShadow: '0px 0px 5px rgba(0, 0, 0, 0.15)',
    borderRadius: '4px',
    margin: '10px',
    display: 'flex',
    flexDirection: 'row',
    color: theme.vars.palette.bigid.primary900,
  },
  circle: {
    width: '24px',
    height: '24px',
    lineHeight: '22px',
    borderRadius: '50%',
    textAlign: 'center',
    fontSize: '14px',
    border: `1px solid ${theme.vars.palette.bigid.primary600}`,
    marginRight: '10px',
  },
  selectedCircle: {
    width: '24px',
    height: '24px',
    lineHeight: '22px',
    borderRadius: '50%',
    textAlign: 'center',
    fontSize: '14px',
    fontWeight: '900',
    background: theme.vars.palette.bigid.primary600,
    color: theme.vars.palette.bigid.white,
    border: `1px solid ${theme.vars.palette.bigid.primary600}`,
    marginRight: '10px',
  },
  completedCircle: {
    border: `1px solid ${theme.vars.palette.bigid.green700}`,
    background: theme.vars.palette.bigid.green700,
    color: theme.vars.palette.bigid.white,
  },
  itemTitle: {
    width: '82%',
  },
}));

export const GettingStartedMenu: FC = () => {
  const classes = useStyles({});
  const { currentStep, items, dispatch } = useContext(GettingStartedContext);

  const getItemClassname = (item: GettingStartedItem) =>
    currentStep == item.name ? classes.selectedMenuItem : classes.menuItem;

  const getCircleClassnames = (item: GettingStartedItem) => {
    return classnames(
      currentStep == item.name ? [classes.selectedCircle] : [classes.circle],
      item.isCompleted && classes.completedCircle,
    );
  };

  return (
    <div className={classes.root}>
      <div className={classes.menuListItem}>
        {Object.values(items).map(i => {
          return (
            <div
              data-aid={generateDataAid('GettingStartedMenu', ['item', i.name, i.isCompleted ? 'completed' : ''])}
              className={getItemClassname(i)}
              key={i.name}
              onClick={() => dispatch({ type: ReducerActions.SET_CURRENT_STEP, payload: { currentStep: i.name } })}
            >
              <div className={getCircleClassnames(i)}>{i.id}</div>
              {currentStep == i.name ? (
                <BigidHeading6 className={classes.itemTitle}> {i.title}</BigidHeading6>
              ) : (
                <BigidSubtitle className={classes.itemTitle}> {i.title} </BigidSubtitle>
              )}
              <BigidChevronRightIcon />
            </div>
          );
        })}
      </div>
    </div>
  );
};
