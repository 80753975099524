import './collaborationTask.component.scss';
import template from './collaborationTask.component.html';
import { module } from 'angular';
import { isPermitted } from '../../react/services/userPermissionsService';
import { BUSINESS_FLOWS_PERMISSIONS } from '@bigid/permissions';
const app = module('app');

app.component('collaborationTask', {
  template,
  controllerAs: 'collaborationTaskModel',
  controller: [
    'taskListService',
    '$timeout',
    '$document',
    'notificationService',
    'dataMappingService',
    '$q',
    'locationService',
    'dataSourceConnectionsService',
    'applicationSetupService',
    function (
      taskListService,
      $timeout,
      $document,
      notificationService,
      dataMappingService,
      $q,
      locationService,
      dataSourceConnectionsService,
      applicationSetupService,
    ) {
      const collaborationTaskModel = this;
      collaborationTaskModel.securityTierValues = [1, 2, 3, 4, 5].map(tier => ({ id: tier, name: tier }));
      collaborationTaskModel.countries = [];
      collaborationTaskModel.dataMappingClassifications = [
        'Collection',
        'Transformation',
        'Retention',
        'Transfer',
        'Disposal',
      ].map((classification, idx) => ({ classifierId: idx + 1, name: classification }));
      collaborationTaskModel.tab = 'settings';
      collaborationTaskModel.sourceNames = [];

      const PRIMARY_DATA_SOURCE = 'Primary Data Source';
      const APP_NAME = 'App Name';
      const ATTACHMENTS_DUMMY = 'attachmentsDummy';
      const ATTACHMENTS = 'attachments';
      const APP_ACCOUNT_NAME = 'appAccountName';

      this.isLoading = true;
      collaborationTaskModel.$onInit = () => {
        locationService.getCountriesList().then(countries => {
          collaborationTaskModel.countries = countries;
          this.isLoading = false;
        });
      };

      collaborationTaskModel.$onChanges = () => {
        collaborationTaskModel.currentTask = this.task;
        if (collaborationTaskModel.currentTask) {
          showTaskCollaborationEntityFields(collaborationTaskModel.currentTask);
          if (collaborationTaskModel.sourceTypeLabel === PRIMARY_DATA_SOURCE) {
            setDataSourceNamesArr(collaborationTaskModel.sourceName, collaborationTaskModel.additionalSourceNames);
          } else if (collaborationTaskModel.sourceTypeLabel === APP_NAME) {
            setAppNamesArr(collaborationTaskModel.sourceName, collaborationTaskModel.additionalSourceNames);
          }
        }
      };

      collaborationTaskModel.saveEntityChanges = (selectedEntity, vm) => {
        const uploads = [];
        const relations = [];

        for (const i in selectedEntity.annotations) {
          relations[i] = [];
          if (selectedEntity.annotations[i][ATTACHMENTS_DUMMY]) {
            for (const j in selectedEntity.annotations[i][ATTACHMENTS_DUMMY]) {
              uploads.push(dataMappingService.uploadAttachment(selectedEntity.annotations[i][ATTACHMENTS_DUMMY][j]));
              relations[i].push(j);
            }
          }
        }

        $q.all(uploads).then(
          attachments => {
            for (const i in relations) {
              const annotationAtt = relations[i];
              for (let j = 0; j < annotationAtt.length; j++) {
                if (attachments[j].upload === 'OK') {
                  for (const k in selectedEntity.annotations[i][ATTACHMENTS]) {
                    if (selectedEntity.annotations[i][ATTACHMENTS][k]['isDummy']) {
                      selectedEntity.annotations[i][ATTACHMENTS].splice(k, 1);
                    }
                  }
                  selectedEntity.annotations[i][ATTACHMENTS].push({
                    attachmentId: attachments[j].attachmentId,
                    filename: selectedEntity.annotations[i][ATTACHMENTS_DUMMY][j]['filename'],
                    fileType: selectedEntity.annotations[i][ATTACHMENTS_DUMMY][j]['fileType'],
                  });
                } else {
                  notificationService.error(
                    'An error has occurred during ' +
                      selectedEntity.annotations[i][ATTACHMENTS_DUMMY][j]['filename'] +
                      ' uploading!',
                  );
                }
              }
              delete selectedEntity.annotations[i][ATTACHMENTS_DUMMY];
            }
            $q.all([
              dataMappingService.updateBusinessEntity(selectedEntity, selectedEntity._id),
              dataMappingService.updateBusinessProcess(vm.businessProcess, vm.businessProcess._id),
            ]).then(() => {
              notificationService.success('Annotation Was Inserted!');
            });
          },
          () => {
            notificationService.error('An error has occurred during attachments uploading!');
          },
        );
      };

      const showTaskCollaborationEntityFields = task => {
        collaborationTaskModel.showTaskCollaborationEntityFields = false;
        collaborationTaskModel.showAttributesGroup = false;
        collaborationTaskModel.showDisplayNameGroup = false;
        collaborationTaskModel.showNameGroup = false;
        collaborationTaskModel.showDescriptionGroup = false;
        collaborationTaskModel.showOwnerDetails = false;

        //was in the if statement : task.fields && task.fields.length > 0 &&
        if (
          isPermitted(BUSINESS_FLOWS_PERMISSIONS.READ.name) &&
          collaborationTaskModel.currentTask.type !== 'complianceRule'
        ) {
          collaborationTaskModel.showTaskCollaborationEntityFields = true;
          $q.all([
            dataMappingService.getBusinessProcess(task.businessProcessId),
            dataMappingService.getBusinessEntity(task.businessEntityId),
          ]).then(([businessProcessResult, businessEntityResult]) => {
            collaborationTaskModel.selectedEntity = businessEntityResult;
            collaborationTaskModel.vm = {};
            collaborationTaskModel.vm.businessProcess = businessProcessResult;
            task.fields.forEach(field => {
              if (field.type === 'field') {
                const groupName = field.fieldName ? field.fieldName.split('.')[0] : undefined;
                switch (groupName) {
                  case 'displayName':
                    collaborationTaskModel.showDisplayNameGroup = true;
                    if (field.fieldName.split('.')[1] === 'name') {
                      collaborationTaskModel.displayName = field.fieldValue;
                    }
                    break;
                  case 'name':
                    if (field.fieldName.split('.')[1] === APP_ACCOUNT_NAME) {
                      collaborationTaskModel.sourceTypeLabel = APP_NAME;
                      collaborationTaskModel.additionalSource = 'Additional Apps';
                      collaborationTaskModel.sourceType = 'application';
                      setAppNames();
                    } else if (field.fieldName.split('.')[1] === 'dataSourceName') {
                      collaborationTaskModel.sourceTypeLabel = PRIMARY_DATA_SOURCE;
                      collaborationTaskModel.additionalSource = 'Additional Data Source';
                      collaborationTaskModel.sourceType = 'data source';
                      setDataSourcesNamesArrayFromDB();
                    }
                    collaborationTaskModel.showNameGroup = true;

                    if (!collaborationTaskModel.additionalSourceNames) {
                      collaborationTaskModel.additionalSourceNames = [];
                    }

                    if (field.fieldName.includes('additional')) {
                      collaborationTaskModel.additionalSourceNames.push(field.fieldValue);
                    } else {
                      collaborationTaskModel.sourceName = field.fieldValue;
                    }

                    if (field.fieldName.split('.')[1] === APP_ACCOUNT_NAME) {
                      setAppNamesArr(collaborationTaskModel.sourceName, collaborationTaskModel.additionalSourceNames);
                    } else if (field.fieldName.split('.')[1] === 'dataSourceName') {
                      setDataSourceNamesArr(
                        collaborationTaskModel.sourceName,
                        collaborationTaskModel.additionalSourceNames,
                      );
                    }

                    collaborationTaskModel.additionalSourceNamesEnabled = collaborationTaskModel.sourceName
                      ? true
                      : false;

                    break;
                  case 'description':
                    collaborationTaskModel.showDescriptionGroup = true;
                    if (field.fieldName.split('.')[1] === 'description') {
                      collaborationTaskModel.description = field.fieldValue;
                    }
                    break;
                  case 'ownerDetails':
                    collaborationTaskModel.showOwnerDetails = true;
                    if (businessProcessResult.classifiers) {
                      collaborationTaskModel.dataMappingClassifications = businessProcessResult.classifiers;
                    }
                    if (field.fieldName.split('.')[1] === 'ownerCell') {
                      collaborationTaskModel.ownerCell = field.fieldValue;
                    }
                    if (field.fieldName.split('.')[1] === 'ownerEmail') {
                      collaborationTaskModel.ownerEmail = field.fieldValue;
                    }
                    if (field.fieldName.split('.')[1] === 'securityTier') {
                      collaborationTaskModel.securityTier = field.fieldValue;
                    }
                    if (field.fieldName.split('.')[1] === 'country') {
                      collaborationTaskModel.country = field.fieldValue;
                    }
                    if (field.fieldName.split('.')[1] === 'dataMappingClassification') {
                      collaborationTaskModel.dataMappingClassification = parseInt(field.fieldValue);
                    }

                    break;
                }
              } else if (field.type === 'purpose') {
                collaborationTaskModel.showAttributesGroup = true;
                if (!collaborationTaskModel.currentTask.attributes) {
                  collaborationTaskModel.currentTask.attributes = [];
                }
                if (field.fieldName) {
                  collaborationTaskModel.currentTask.attributes.push({
                    attribute: field.fieldName,
                    purpose: field.fieldValue,
                    attribute_id: field.attribute_id,
                    purpose_id: field.purpose_id,
                    is_excluded: field.is_excluded,
                  });
                } else if (field && field.attribute) {
                  collaborationTaskModel.currentTask.attributes.push({
                    attribute: field.attribute,
                    purpose: field.purpose,
                    attribute_id: field.attribute_id || field.glossary_id,
                    purpose_id: field.purpose_id,
                    is_excluded: field.is_excluded,
                  });
                }
              } else if (field.type === 'category') {
                collaborationTaskModel.showAttributesGroup = true;
                if (!collaborationTaskModel.currentTask.categories) {
                  collaborationTaskModel.currentTask.categories = [];
                }
                if (field.fieldName) {
                  collaborationTaskModel.currentTask.categories.push({
                    category: field.fieldName,
                    purpose: field.fieldValue,
                    category_id: field.category_id || field.glossary_id,
                    purpose_id: field.purpose_id,
                  });
                } else if (field && field.categories) {
                  collaborationTaskModel.currentTask.categories.push({
                    category: field.unique_name,
                    purpose: field.purpose,
                    category_id: field.category_id || field.glossary_id,
                    purpose_id: field.purpose_id,
                  });
                }
              }
            });
          });
        }
      };

      collaborationTaskModel.changeAttributes = attributes => {
        const data = {};
        data.fields = [];
        if (attributes) {
          attributes.forEach(attribute => {
            data.fields.push({
              fieldName: attribute.attribute,
              fieldValue: attribute.purpose,
              attribute_id: attribute.attribute_id || attribute.glossary_id,
              purpose_id: attribute.purpose_id,
              is_excluded: attribute.is_excluded,
              is_fromSystem: attribute.is_fromSystem,
              type: 'purpose',
            });
          });
          this.updateCollaborationFields(data);
        }
      };

      this.updateCollaborationFields = (data = {}) => {
        if (!data.fields) {
          data.fields = [];
        }
        data.isEdit = true;

        createFieldsArray(data);

        taskListService.updateTask(data, collaborationTaskModel.currentTask._id).then(
          result => {
            notificationService.success('Task has successfully been updated!');
          },
          () => {
            notificationService.error('An error has occurred!');
          },
        );
      };

      const createFieldsArray = data => {
        if (collaborationTaskModel.displayName) {
          data.fields.push({
            fieldName: 'displayName.name',
            fieldValue: collaborationTaskModel.displayName,
            type: 'field',
          });
        }
        if (collaborationTaskModel.sourceName) {
          collaborationTaskModel.sourceTypeLabel === PRIMARY_DATA_SOURCE
            ? data.fields.push({
                fieldName: 'name.dataSourceName',
                fieldValue: collaborationTaskModel.sourceName,
                type: 'field',
              })
            : data.fields.push({
                fieldName: 'name.appAccountName',
                fieldValue: collaborationTaskModel.sourceName,
                type: 'field',
              });
          if (collaborationTaskModel.additionalSource === 'Additional Data Source') {
            if (collaborationTaskModel.additionalSourceNames) {
              collaborationTaskModel.additionalSourceNames.forEach(additionalSourceName => {
                data.fields.push({
                  fieldName: 'name.additionalDataSources',
                  fieldValue: additionalSourceName,
                  type: 'field',
                });
              });
            }
          } else if (collaborationTaskModel.additionalSource === 'Additional Apps') {
            if (collaborationTaskModel.additionalSourceNames) {
              collaborationTaskModel.additionalSourceNames.forEach(additionalSourceName => {
                data.fields.push({
                  fieldName: 'name.additionalApps',
                  fieldValue: additionalSourceName,
                  type: 'field',
                });
              });
            }
          }
        }
        if (collaborationTaskModel.description) {
          data.fields.push({
            fieldName: 'description.description',
            fieldValue: collaborationTaskModel.description,
            type: 'field',
          });
        }
        if (collaborationTaskModel.ownerCell) {
          data.fields.push({
            fieldName: 'ownerDetails.ownerCell',
            fieldValue: collaborationTaskModel.ownerCell,
            type: 'field',
          });
        }
        if (collaborationTaskModel.ownerEmail) {
          data.fields.push({
            fieldName: 'ownerDetails.ownerEmail',
            fieldValue: collaborationTaskModel.ownerEmail,
            type: 'field',
          });
        }
        if (collaborationTaskModel.securityTier) {
          data.fields.push({
            fieldName: 'ownerDetails.securityTier',
            fieldValue: collaborationTaskModel.securityTier,
            type: 'field',
          });
        }
        if (collaborationTaskModel.country) {
          data.fields.push({
            fieldName: 'ownerDetails.country',
            fieldValue: collaborationTaskModel.country,
            type: 'field',
          });
        }
        if (collaborationTaskModel.dataMappingClassification) {
          data.fields.push({
            fieldName: 'ownerDetails.dataMappingClassification',
            fieldValue: collaborationTaskModel.dataMappingClassification,
            type: 'field',
          });
        }
        if (!data.fields.find(field => field.type === 'purpose')) {
          if (collaborationTaskModel.currentTask.attributes) {
            collaborationTaskModel.currentTask.attributes.forEach(field => {
              data.fields.push({
                attribute_id: field.attribute_id,
                fieldName: field.attribute,
                fieldValue: field.purpose,
                purpose_id: field.purpose_id,
                is_excluded: field.is_excluded,
                type: 'purpose',
              });
            });
          }
        }
        if (!data.fields.find(field => field.type === 'category')) {
          if (collaborationTaskModel.currentTask.categories) {
            collaborationTaskModel.currentTask.categories.forEach(field => {
              data.fields.push({
                category_id: field.category_id,
                fieldName: field.category,
                fieldValue: field.purpose,
                purpose_id: field.purpose_id,
                type: 'category',
              });
            });
          }
        }
      };

      collaborationTaskModel.changeCategory = categories => {
        const data = {};
        data.fields = [];
        if (categories) {
          categories.forEach(category => {
            data.fields.push({
              fieldName: category.category || category.unique_name,
              fieldValue: category.purpose,
              category_id: category.category_id || category.glossary_id,
              purpose_id: category.purpose_id,
              type: 'category',
            });
          });
          this.updateCollaborationFields(data);
        }
      };

      this.onAdditionalSourceAction = () => {
        getAttributes(
          collaborationTaskModel.additionalSourceNames.concat([collaborationTaskModel.sourceName]),
          'system',
        );
        manageSourceNamesArr(collaborationTaskModel.sourceName, collaborationTaskModel.additionalSourceNames);
        this.updateCollaborationFields();
      };

      this.onSourceNameSelected = selectedItem => {
        collaborationTaskModel.additionalSourceNamesEnabled = !selectedItem || selectedItem == 'None' ? false : true;

        if (!collaborationTaskModel.additionalSourceNamesEnabled) {
          collaborationTaskModel.additionalSourceNames = [];
        }

        if (collaborationTaskModel.sourceTypeLabel === PRIMARY_DATA_SOURCE) {
          manageSourceNamesArr(collaborationTaskModel.sourceName, collaborationTaskModel.additionalSourceNames);

          dataSourceConnectionsService.getSystemDetailsByName(selectedItem).then(result => {
            if (result) {
              collaborationTaskModel.securityTier = parseInt(result.security_tier);
              collaborationTaskModel.country = collaborationTaskModel.checkIfCountryExist(result.location);
            } else {
              collaborationTaskModel.securityTier = '';
              collaborationTaskModel.country = '';
            }
            if (selectedItem !== 'None')
              getAttributes(
                collaborationTaskModel.additionalSourceNames.concat([collaborationTaskModel.sourceName]),
                'system',
              );
            else collaborationTaskModel.attributesFromSystem = [];
          });
        } else if (collaborationTaskModel.sourceTypeLabel === APP_NAME) {
          manageSourceNamesArr(collaborationTaskModel.sourceName, collaborationTaskModel.additionalSourceNames);

          applicationSetupService.getApplicationDetailsByName(selectedItem).then(result => {
            if (result) {
              collaborationTaskModel.securityTier = parseInt(result.security_tier);
              collaborationTaskModel.country = collaborationTaskModel.checkIfCountryExist(result.location);
              collaborationTaskModel.ownerCell = result.owner_phone;
              collaborationTaskModel.ownerEmail = result.owner_email;
            } else {
              collaborationTaskModel.securityTier = '';
              collaborationTaskModel.country = '';
              collaborationTaskModel.ownerCell = '';
              collaborationTaskModel.ownerEmail = '';
            }
            if (selectedItem !== 'None')
              getAttributes(
                collaborationTaskModel.additionalSourceNames.concat([collaborationTaskModel.sourceName]),
                'application',
              );
            else collaborationTaskModel.attributesFromSystem = [];
          });
        }
      };

      const setAppNames = () => {
        return applicationSetupService.getAllApplications().then(applications => {
          collaborationTaskModel.names = ['None', ...applications.applications.map(item => item.name)].map(
            (ds, idx) => ({
              id: idx + 1,
              name: ds,
            }),
          );
        });
      };

      const setDataSourcesNamesArrayFromDB = () => {
        return dataSourceConnectionsService.getAllSystems().then(dataSources => {
          collaborationTaskModel.names = ['None', ...dataSources.systems.map(item => item.name)].map((ds, idx) => ({
            id: idx + 1,
            name: ds,
          }));
        });
      };

      this.checkIfCountryExist = countryName => {
        const country = collaborationTaskModel.countries.find(
          country => countryName === country.name || countryName === country.displayName,
        );
        return country ? country.name : '';
      };

      const getAttributes = (namesArray, type) => {
        dataMappingService.getAttributes(namesArray, type).then(result => {
          collaborationTaskModel.currentTask.attributes = result ? result : [];
        });
      };

      const setDataSourceNamesArr = (primary, additional) => {
        setDataSourcesNamesArrayFromDB().then(() => {
          setSourceNames(primary, additional);
        });
      };

      const setAppNamesArr = (primary, additional) => {
        setAppNames().then(() => {
          setSourceNames(primary, additional);
        });
      };

      const setSourceNames = (primary, additional) => {
        const _sourceNames = collaborationTaskModel.names;
        for (let i = 0; i < _sourceNames.length; i++) {
          collaborationTaskModel.sourceNames[i] = {};

          collaborationTaskModel.sourceNames[i]['name'] = _sourceNames[i].name;
          if (_sourceNames[i].name == primary) {
            collaborationTaskModel.sourceNames[i]['isPrimary'] = true;
            collaborationTaskModel.sourceNames[i]['isAdditional'] = false;
          } else {
            collaborationTaskModel.sourceNames[i]['isPrimary'] = false;
            collaborationTaskModel.sourceNames[i]['isAdditional'] = additional
              ? additional.indexOf(_sourceNames[i]['name']) > -1
                ? true
                : false
              : false;
          }
        }
      };

      const manageSourceNamesArr = (primary, additional) => {
        for (let i = 0; i < collaborationTaskModel.sourceNames.length; i++) {
          if (collaborationTaskModel.sourceNames[i]['name'] == primary) {
            collaborationTaskModel.sourceNames[i]['isPrimary'] = true;
            collaborationTaskModel.sourceNames[i]['isAdditional'] = false;
          } else {
            collaborationTaskModel.sourceNames[i]['isPrimary'] = false;
            collaborationTaskModel.sourceNames[i]['isAdditional'] = additional
              ? additional.indexOf(collaborationTaskModel.sourceNames[i]['name']) > -1
                ? true
                : false
              : false;
          }
        }
      };
    },
  ],
  bindings: {
    task: '<',
  },
});
