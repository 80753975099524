import React, { useEffect, useMemo } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import {
  BigidDeleteIcon,
  BigidDownloadIcon,
  BigidFileIcon,
  BigidEditIcon,
  BigidNoDataIllustration,
  BigidAddIcon,
  BigidWarningFilledIcon,
} from '@bigid-ui/icons';
import {
  BigidFlexibleListCardData,
  BigidFlexibleListCardActionType,
  BigidColorsV2,
  entityEventsEmitter,
  EntityEvents,
} from '@bigid-ui/components';
import {
  BigidGridColumnTypes,
  BigidGridQueryComponents,
  BigidGridWithToolbar,
  BigidGridWithToolbarProps,
} from '@bigid-ui/grid';
import angular from 'angular';
import { convertToAngular } from '../../../../common/services/convertToAngular';
import { isPermitted } from '../../../services/userPermissionsService';
import { DSAR_PERMISSIONS } from '@bigid/permissions';
import { useUserPreferences } from '../../../components/hooks/useUserPrefrences';
import { NEW_DSAR_SETTINGS_STATE_NAMES } from '../SarProfileSettings/SarProfileSettingsTypes';
import { DsarEvents, dsarEventEmitter } from '../SarProfileSettings/dsarEvents';
import { getDictionaries } from '../dsarService';
import { DsarDictionaryStatus, DsarDictionary as DsarDictionaryType } from '../DsarTypes';
import { dateTimeService } from '@bigid-ui/i18n';
import { DsarEditDictionaryDialog, downloadDictionary, useDsarEditDictionaryDialog } from './DsarEditDictionaryDialog';
import {
  SSEDataMessage,
  SSEMessageTypes,
  SSE_EVENTS,
  subscribeToRepeatedSSEEventById,
} from '../../../services/sseService';
import { notificationService } from '../../../services/notificationService';
import { BigidLayoutEmptyState } from '@bigid-ui/layout';
import { DsarDeleteDictionaryDialog, useDsarDeleteDictionaryDialog } from './DsarDeleteDictionaryDialog';
import { DsarSettingsTrackingEvents, trackDsar } from '../analyticsUtils';

type ConfigProps = BigidGridWithToolbarProps<DsarDictionaryType>;

const useStyles = makeStyles({
  root: {
    display: 'flex',
    height: '100%',
    width: '100%',
    overflow: 'hidden',
    paddingBottom: '12px',
    backgroundColor: BigidColorsV2.white,
    borderRadius: '4px',
    border: `1px solid ${BigidColorsV2.gray[300]}`,
  },
});

export const DsarDictionary = () => {
  const classes = useStyles();
  const dialog = useDsarEditDictionaryDialog();
  const deleteDialog = useDsarDeleteDictionaryDialog();

  const defaultColumns = useMemo(
    () => getDefaultColumns(dialog.openDialog, deleteDialog.openDialog),
    [dialog.openDialog, deleteDialog.openDialog],
  );
  const {
    isReady,
    gridColumns,
    filterToolbarConfig,
    updatePreferences,
    key: gridKey,
  } = useUserPreferences({
    stateName: NEW_DSAR_SETTINGS_STATE_NAMES.DICTIONARY,
    initialGridColumns: defaultColumns,
    getInitialFilterToolbarConfig: getFilterConfig,
  });

  useEffect(() => {
    const uploadFileEventHandler = async () => {
      await dialog.openDialog();
      entityEventsEmitter.emit(EntityEvents.RELOAD);
    };
    dsarEventEmitter.addEventListener(DsarEvents.UPLOAD_FILE, uploadFileEventHandler);

    const handleUpdateDictionary = ({ message, type, results }: SSEDataMessage) => {
      if (type === SSEMessageTypes.IN_PROGRESS) {
        notificationService.success(message);
        return;
      }
      const { imported, skipped, duplicates } = results[0] || {};
      const notification = type === SSEMessageTypes.FAILURE ? notificationService.error : notificationService.success;
      notification(`${message}. Imported - ${imported}, detected duplicates - ${duplicates}, skipped - ${skipped}.`);
      entityEventsEmitter.emit(EntityEvents.RELOAD);
    };
    const unregisterSSEEvent = subscribeToRepeatedSSEEventById(
      SSE_EVENTS.DSAR_UPLOAD_DICTIONARY,
      handleUpdateDictionary,
    );

    return () => {
      dsarEventEmitter.removeEventListener(DsarEvents.UPLOAD_FILE, uploadFileEventHandler);
      unregisterSSEEvent();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const config: ConfigProps = useMemo(
    () => ({
      showSelectionCheckboxes: false,
      hideHeadersRow: true,
      hideColumnChooser: true,
      cardListMode: true,
      showFilteringControls: false,
      showSortingControls: true,
      onGridStateChange: ({ filter, ...gridState }) => updatePreferences({ filterState: { filter }, gridState }),
      fetchData: async queryComponents => {
        const { objects, totalCount } = await fetchDictionaries(queryComponents);
        return {
          data: objects,
          totalCount: totalCount,
        };
      },
      noDataContent: (
        <BigidLayoutEmptyState
          actions={[
            {
              label: 'Upload a new dictionary',
              icon: BigidAddIcon,
              execute: async () => {
                dsarEventEmitter.emit(DsarEvents.UPLOAD_FILE);
                trackDsar(DsarSettingsTrackingEvents.DICTIONARY_UPLOAD_DICTIONARY_ACTION);
                return { shouldGridReload: false };
              },
              show: () => isPermitted(DSAR_PERMISSIONS.READ_DICTIONARY.name),
            },
          ]}
          illustration={BigidNoDataIllustration}
          description="Upload your first file"
        />
      ),

      filterToolbarConfig,
      columns: gridColumns,
      shouldShowViewToggle: false,
    }),
    [filterToolbarConfig, gridColumns, updatePreferences],
  );

  return (
    <div className={classes.root}>
      <DsarEditDictionaryDialog {...dialog.dialogProps} />
      <DsarDeleteDictionaryDialog {...deleteDialog.dialogProps} />
      {isReady && <BigidGridWithToolbar key={gridKey} {...config} />}
    </div>
  );
};

const fetchDictionaries = async (
  queryComponents: BigidGridQueryComponents,
): Promise<{ objects: DsarDictionaryType[]; totalCount: number }> => {
  try {
    const { objects, totalCount } = await getDictionaries(queryComponents);
    return { objects, totalCount };
  } catch (error) {
    console.error('Could not get library files');
    return { objects: [], totalCount: 0 };
  }
};

const getFilterConfig = async (): Promise<ConfigProps['filterToolbarConfig']> => {
  return {
    filters: [],
    searchConfig: {
      searchFilterKeys: ['name'],
      initialValue: '',
      operator: 'textSearch',
    },
  };
};

export enum INLINE_ACTION {
  DOWNLOAD = 'Download',
  DELETE = 'Delete',
  EDIT = 'Edit',
}

const getDefaultColumns = (
  openEditDictionaryDialog: (selectedDictionary?: DsarDictionaryType) => Promise<boolean>,
  openDeleteDictionaryDialog: (dictionaryId?: string) => Promise<boolean>,
): ConfigProps['columns'] => {
  return [
    {
      name: 'name',
      title: 'name',
      width: 'auto',
      getCellValue: data => {
        const {
          id,
          name,
          fileName,
          createdDate,
          statistics: { duplicates, imported, skipped },
          message,
          status,
        } = data;
        const cardData: BigidFlexibleListCardData<DsarDictionaryType> = {
          id,
          title: (
            <div style={{ display: 'flex', gap: '4px' }}>
              {name}
              {status === DsarDictionaryStatus.FAILED && <BigidWarningFilledIcon color="red" />}
            </div>
          ),
          subTitle: `Created at ${dateTimeService.formatDate(createdDate)}`,
          avatar: {
            icon: () => <BigidFileIcon />,
            name: `File name: ${fileName}
          Entries: Imported - ${imported}, detected duplicates - ${duplicates}, skipped - ${skipped}
         ${message ? 'Error: ' + message : ''}`,
          },
          originalData: data,
        };

        return {
          card: {
            actions: [
              {
                label: INLINE_ACTION.EDIT,
                name: 'edit',
                tooltip: 'Edit dictionary',
                onExecute: async ({ originalData }) => {
                  const actionResult = await openEditDictionaryDialog(originalData as DsarDictionaryType);
                  actionResult && entityEventsEmitter.emit(EntityEvents.RELOAD);
                  trackDsar(DsarSettingsTrackingEvents.DICTIONARY_EDIT_ACTION);
                  return { shouldGridReload: actionResult, shouldClearSelection: actionResult };
                },
                getIsShown: () => isPermitted(DSAR_PERMISSIONS.EDIT_DICTIONARY.name),
                isAlwaysExposed: true,
                type: BigidFlexibleListCardActionType.ICON,
                getActionIcon: () => BigidEditIcon,
              },
              {
                label: INLINE_ACTION.DELETE,
                name: 'delete',
                tooltip: 'Delete dictionary',
                onExecute: async () => {
                  const actionResult = await openDeleteDictionaryDialog(id);
                  actionResult && entityEventsEmitter.emit(EntityEvents.RELOAD);
                  trackDsar(DsarSettingsTrackingEvents.DICTIONARY_DELETE_ACTION);
                  return { shouldGridReload: actionResult, shouldClearSelection: actionResult };
                },
                getIsShown: () => isPermitted(DSAR_PERMISSIONS.DELETE_DICTIONARY.name),
                isAlwaysExposed: true,
                type: BigidFlexibleListCardActionType.ICON,
                getActionIcon: () => BigidDeleteIcon,
              },
              {
                label: INLINE_ACTION.DOWNLOAD,
                name: 'download',
                tooltip: 'Download dictionary',
                onExecute: async () => {
                  downloadDictionary(id, name);
                  trackDsar(DsarSettingsTrackingEvents.DICTIONARY_DOWNLOAD_ACTION);
                  return { shouldGridReload: false, shouldClearSelection: false };
                },
                getIsShown: () => isPermitted(DSAR_PERMISSIONS.READ_DICTIONARY.name),
                isAlwaysExposed: true,
                type: BigidFlexibleListCardActionType.ICON,
                getActionIcon: () => BigidDownloadIcon,
              },
            ],
            cardData,
            disableActionsTransformation: true,
          },
        };
      },
      type: BigidGridColumnTypes.FLEXIBLE_CARD,
    },
  ];
};

angular.module('app').component('dsarDictionary', convertToAngular(DsarDictionary));
