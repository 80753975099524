import React, { FC, useState } from 'react';
import { ActionData, BigidDropdownOption, BigidDropdownValue, BigidLoader } from '@bigid-ui/components';
import { assignCategoryToAttributeRequest, createCategoryRequest } from '../../services/classifiersManagementService';
import { SystemDialogContentProps } from '../../../../services/systemDialogService';
import { getAssignCategoryPayload, getMappedAssignedCategoriesInGridDataFormat } from '../../utils/utils';
import { notificationService } from '../../../../services/notificationService';
import { CategoryAssignModes, CreateCategory } from '../../../../components/AssignCategoryDialog/types';
import { AssignCategoryDialog } from '../../../../components/AssignCategoryDialog/AssignCategoryDialog';
import { useLocalTranslation } from '../../translations';
import { Category } from '../../types/ClassifierTypes';
import { ASSIGN_CATEGORIES_TYPE, AssignCategories, AssignCategoryRequest } from '../../types/AssignCategories';
import { useCategories } from '../../hooks/useCategories';

export interface AssignCategoryDialogContentProps {
  // attributes: AssignCategories[];
  actionData: ActionData;
  assignType: ASSIGN_CATEGORIES_TYPE;
  handleAssignCreateSucceeded?: (categories: AssignCategories[]) => void;
  resolve?: () => Promise<any>;
}

export const AssignCategoryDialogContent: FC<SystemDialogContentProps<AssignCategoryDialogContentProps>> = ({
  actionData,
  assignType,
  onClose,
  handleAssignCreateSucceeded,
  resolve,
}) => {
  const attributes = actionData.selectedRows;

  const [isLoading, setIsLoading] = useState(false);
  const { t } = useLocalTranslation('AssignCategoryDialog');
  const { categoriesList, initialSelectedCategories } = useCategories(attributes, assignType);

  const handleAssignCategoryToAttribute = async (
    selectedCategories: BigidDropdownValue,
    payload: AssignCategoryRequest[],
    newCategory?: Category,
  ) => {
    await assignCategoryToAttributeRequest(payload);

    const newAttributes = getMappedAssignedCategoriesInGridDataFormat(
      attributes,
      selectedCategories,
      assignType,
      newCategory,
    );

    handleAssignCreateSucceeded && handleAssignCreateSucceeded(newAttributes);
    onClose();
  };

  const getPayloadWhenAllSelected = (actionData: ActionData, selectedCategories: any[]) => {
    const selected = selectedCategories.map(({ id, displayValue }) => ({
      unique_name: id,
      display_name: displayValue,
    }));

    return {
      allSelected: actionData.allSelected,
      categories: selected,
      query: {
        filter: actionData.filter,
      },
    };
  };

  const handleOnSaveClick = async (
    selectedCategories: BigidDropdownValue,
    categoryAssignMode: CategoryAssignModes,
    state: CreateCategory,
  ) => {
    setIsLoading(true);
    if (categoryAssignMode === CategoryAssignModes.NEW) {
      if (!state.name) {
        notificationService.info(t('errors.nameRequired'));
        setIsLoading(false);
        resolve();
        return;
      }
      let payload;
      const response = await createCategoryRequest(state);
      if (response?.success) {
        const newCategory = {
          color: state?.color,
          description: state?.description,
          display_name: state?.name,
          unique_name: response.glossary_id,
        } as Category;

        if (actionData.allSelected) {
          payload = getPayloadWhenAllSelected(actionData, [{ id: response.glossary_id }]);
        } else {
          payload = getAssignCategoryPayload(
            attributes,
            [{ id: response.glossary_id } as BigidDropdownOption],
            assignType,
          );
        }
        await handleAssignCategoryToAttribute([], payload as any, newCategory);
        resolve();
      }
    } else {
      let payload;
      if (actionData.allSelected) {
        payload = getPayloadWhenAllSelected(actionData, selectedCategories);
      } else {
        payload = getAssignCategoryPayload(attributes, selectedCategories, assignType);
      }

      await handleAssignCategoryToAttribute(selectedCategories, payload as any);
      resolve();
    }
    setIsLoading(false);
    resolve();
  };

  return (
    <>
      {isLoading && <BigidLoader />}
      <AssignCategoryDialog
        initialSelectedCategories={initialSelectedCategories}
        categories={categoriesList}
        onSave={handleOnSaveClick}
        onClose={onClose}
      />
    </>
  );
};
