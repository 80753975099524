import React, { useState } from 'react';
import { Typography } from '@mui/material';
import { Prerequisites } from './DataSourceConnectionModalStyles';
import { useLocalTranslation } from './translations';
import { BigidForm, BigidFormField, BigidFormProps, BigidFormValues } from '@bigid-ui/components';

export type DataSourceConnectionPrerequisitesProps = {
  source: string;
  fields: BigidFormField[];
};

export const DataSourceConnectionPrerequisites = ({
  source,
  fields,
}: DataSourceConnectionPrerequisitesProps): JSX.Element => {
  const [content, setContent] = useState();
  const { t } = useLocalTranslation();

  const handleOnChange = (values: BigidFormValues) => ({});

  const formProps: BigidFormProps = {
    fields,
    onChange: handleOnChange,
    controlButtons: false,
  };
  return (
    <Prerequisites>
      <Typography variant="body1">
        {t('prerequisites.sub', { source })}
        <Typography variant="body2">{t('prerequisites.sub2')}</Typography>
        {t('prerequisites.sub3')}
      </Typography>
      <BigidForm {...formProps} />
      <Typography variant="body1">{content}</Typography>
    </Prerequisites>
  );
};
