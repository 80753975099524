import './moreButton.component.scss';
import template from './moreButton.component.html';
import { module } from 'angular';
const app = module('app');

app.component('moreButton', {
  template,
  controller: function () {
    const DEFAULT_POSITION = 'bottom-left';

    this.items = [];
    this.position = DEFAULT_POSITION;

    this.isToggled = false;

    this.$onChanges = () => {
      if (this.position.constructor !== String || this.position === '') {
        this.position = DEFAULT_POSITION;
      }

      if (typeof this.items == 'undefined' || this.items.constructor !== Array) {
        this.items = [];
      }
    };

    this.onClick = item => {
      this.onItemClick({ item });
    };

    this.onToggle = isToggled => {
      this.isToggled = isToggled;
    };
  },
  bindings: {
    /**
     *  OBJECT
     *  name: <STRING> item name
     *  action: <STRING> parent component function name, click handler
     *  isEnabled: <BOOLEAN> is item enabled flag, true: enabled, false: disabled
     *  isVisible: <BOOLEAN> is item visible flag, true: visible, false: hidden
     */
    items: '<',
    /**
     *  EVENT
     *  item: {name: ITEM_NAME_HERE, action: ITEM_ACTION_HERE}
     */
    onItemClick: '&',
    /**
     *  STRING
     *  dropdown position, see DEFAULT_POSITION
     */
    position: '@',
  },
});
