import { httpService } from '../../../../../services/httpService';
import { ApplyBody, ApplyResponse, ModelData } from './classifierModelUtils';

export async function getCurrentInputsCounts(classifierName: string) {
  return httpService
    .fetch(
      `data-catalog/results-tuning/attributes?filter=attributeName IN ("classifier.${classifierName}")&isStructuredOnly=true`,
    )
    .then(({ data }) => data);
}

export async function getSupervisedLearningProcess(classificationName: string): Promise<ModelData[]> {
  const { data } = await httpService.fetch<{ data: ModelData[] }>(
    `data-catalog/supervised-learning/processes?filter=classificationName IN ("${classificationName}")`,
  );

  const modelData = data.data.map(model => {
    if (model.evaluationScore) {
      model.evaluationScore = Number((Number(model.evaluationScore) * 100).toFixed(0));
    }
    return model;
  });

  return modelData;
}

export async function triggerApplyModel(classificationName: string, modelId: string): Promise<ApplyResponse> {
  const body: ApplyBody = {
    classificationName,
    modelId,
  };

  const { data } = await httpService.post(`data-catalog/supervised-learning/apply`, body);
  return data.data;
}
