import React from 'react';
import { PrimaryButton, TertiaryButton } from '@bigid-ui/components';
import { openSystemDialog } from '../../../../services/systemDialogService';
import { getFixedT, useLocalTranslation } from '../../translations';
import { generateDataAid } from '@bigid-ui/utils';
const KeepEditingContent = () => {
  const { t } = useLocalTranslation('keepEditingDialog');
  return <div data-aid={generateDataAid('KeepEditingContent', ['body'])}>{t('body')}</div>;
};
export const openKeepEditingDialog = async () => {
  const t = getFixedT('keepEditingDialog');
  return new Promise<boolean>(resolve => {
    openSystemDialog({
      title: t('title'),
      content: KeepEditingContent,
      maxWidth: 'xs',
      onClose: () => null,
      buttons: [
        {
          text: t('leaveWithoutSaving'),
          component: TertiaryButton,
          onClick: () => resolve(false),
          isClose: true,
          dataAid: 'leave-without-saving',
        },
        {
          text: t('continueEditing'),
          component: PrimaryButton,
          onClick: () => {
            resolve(true);
          },
          isClose: true,
          dataAid: 'continue-editing',
        },
      ],
    });
  });
};
