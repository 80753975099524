import React, { FC } from 'react';
import { PrimaryButton, TertiaryButton, BigidDialog, BigidBody1 } from '@bigid-ui/components';
import { useLocalTranslation } from './translations';

interface DeleteUserDialogProps {
  isOpen: boolean;
  userName: string;
  onDelete: () => void;
  onClose: () => void;
}

export const DeleteUserDialog: FC<DeleteUserDialogProps> = ({ isOpen, userName, onDelete, onClose }) => {
  const { t } = useLocalTranslation('dialogs.deleteUser');

  return (
    <BigidDialog
      isOpen={isOpen}
      buttons={[
        {
          alignment: 'right',
          component: TertiaryButton,
          onClick: onClose,
          text: t('close'),
        },
        {
          alignment: 'right',
          component: PrimaryButton,
          onClick: onDelete,
          text: t('delete'),
        },
      ]}
      onClose={onClose}
      title={t('title')}
    >
      <BigidBody1>{t('body', { name: userName })}</BigidBody1>
    </BigidDialog>
  );
};
