import React, { FC } from 'react';
import {
  BigidFieldRenderProps,
  BigidFormFieldLabelPosition,
  BigidFormFieldLabelWrapper,
  BigidSwitch,
  BigidSwitchProps,
  compareObjectsExceptFunctions,
} from '@bigid-ui/components';
import { styled } from '@mui/material';

export type BigidSwitchFieldRenderProps = BigidFieldRenderProps & { switchFieldProps?: Partial<BigidSwitchProps> };

const SwitchWrapper = styled('div')`
  margin-top: 3px;
  margin-left: 3px;
  position: absolute;
  right: 0;
  top: 0;
`;

const CustomSwitchField: FC<BigidSwitchFieldRenderProps> = React.memo(
  ({
    setValue,
    name,
    label = '',
    value,
    error,
    disabled,
    errorIsShown,
    isRequired,
    tooltipText,
    description,
    fieldProps = {},
    isValidated,
    isFieldsBySelection,
    setDisabled,
    labelWidth,
    misc,
  }) => {
    const id = 'bigid-form-field-' + name;
    const labelText = label ? label : undefined;
    return (
      <BigidFormFieldLabelWrapper
        id={id}
        isRequired={isRequired}
        errorIsShown={errorIsShown}
        error={error}
        tooltipText={tooltipText}
        description={description}
        labelPosition={BigidFormFieldLabelPosition.left}
        labelWidth={labelWidth}
        labelOnEnd={false}
        label={labelText}
        isSeparatorAfter={misc?.isSeparatorAfter}
        isFieldsBySelection={isFieldsBySelection}
        name={name}
        disabled={disabled}
        setDisabled={setDisabled}
      >
        <SwitchWrapper>
          <BigidSwitch
            label={''}
            id={id}
            onChange={(e: any) => setValue(e?.target?.checked)}
            name={name}
            checked={value}
            disabled={disabled || isValidated}
            {...fieldProps}
          />
        </SwitchWrapper>
      </BigidFormFieldLabelWrapper>
    );
  },
  compareObjectsExceptFunctions,
);

CustomSwitchField.displayName = 'CustomSwitchField';

export { CustomSwitchField };
