import React, { FC, useState } from 'react';
import {
  BigidTextField,
  BigidExtraField,
  BigidExtraFieldLabelPosition,
  useExtraFieldControl,
  BigidSwitch,
  BigidTooltip,
} from '@bigid-ui/components';
import { v4 as uuid } from 'uuid';
import makeStyles from '@mui/styles/makeStyles';
import { IgnoreValue } from '../ClassifiersExcludeListDialog/ClassifiersExcludeListDialog';
import { BigidHelpIcon } from '@bigid-ui/icons';

export interface ExtraFieldIgnoreValueProps {
  onChange: () => void;
  initialData: IgnoreValue[];
  classificationName: string;
  label: string;
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
  titleWithPrefixCount: string;
  type: string;
  deleteExcludeValueHandler: (ignoreValue: IgnoreValue) => void;
  validate?: (val: string) => string | boolean;
  onChangeCaseSensitive?: (val: boolean) => void;
  caseSensitiveModeData?: Record<string, boolean>;
}

const useStyles = makeStyles({
  title: {
    marginBottom: '18px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  wrapperList: {
    height: '340px',
    overflowY: 'auto',
  },
  switchContainer: {
    display: 'flex',
    alignItems: 'center',
    '& > div:last-child': {
      display: 'flex',
    },
  },
});

export const ExtraFieldIgnoreValue: FC<ExtraFieldIgnoreValueProps> = ({
  onChange,
  initialData,
  classificationName,
  label,
  setIsLoading,
  titleWithPrefixCount,
  type,
  deleteExcludeValueHandler,
  validate,
  onChangeCaseSensitive,
  caseSensitiveModeData,
}) => {
  const [checked, setChecked] = useState(caseSensitiveModeData?.isCaseSensitive);
  const [errorMessage, setErrorMessage] = useState('');
  const { onAddNew, onDelete, data, onChangeItem } = useExtraFieldControl({
    initialData,
    createNewItem: () => ({ id: uuid(), value: '', classification_name: classificationName, type }),
    onChange,
  });

  const handleOnChangeItem = (id: string, e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    const { value } = e.target;
    const error = validate && validate(value);
    if (error) {
      setErrorMessage(error as string);
      onChangeItem(id, value);
      return;
    }
    setErrorMessage('');
    onChangeItem(id, value);
  };

  const handleOnRemoveItem = async (deleteId: string) => {
    const requestedIgnoreValue = data.find(ignoreValue => ignoreValue.id === deleteId);
    // Check if is it object from mongo or object just added only in client
    if (requestedIgnoreValue && requestedIgnoreValue._id) {
      deleteExcludeValueHandler(requestedIgnoreValue as IgnoreValue);
    }
    onDelete(deleteId);
  };
  const handelCaseSensitiveSwitch = (checked: boolean) => {
    setChecked(checked);
    onChangeCaseSensitive(checked);
  };

  const classes = useStyles({});
  return (
    <div>
      <div className={classes.title}>
        <div>
          {data.length} {titleWithPrefixCount}
        </div>
        {caseSensitiveModeData && (
          <div className={classes.switchContainer}>
            <BigidSwitch
              leftLabel={'Enable case-sensitive'}
              onChange={(event, checked) => handelCaseSensitiveSwitch(checked)}
              checked={checked}
            />
            <BigidTooltip
              title={
                'Enable case-sensitive if you want to exclude an exact match only. For example, if you enter MAN, it won’t exclude Man or man. Applies to all string values for this classifier.'
              }
            >
              <div>
                <BigidHelpIcon />
              </div>
            </BigidTooltip>
          </div>
        )}
      </div>
      <div className={classes.wrapperList}>
        <BigidExtraField
          label={label}
          fieldComponent={BigidTextField}
          data={data}
          onChangeItem={handleOnChangeItem}
          onDelete={handleOnRemoveItem}
          onAddNew={onAddNew}
          labelPosition={data.length === 0 ? BigidExtraFieldLabelPosition.left : BigidExtraFieldLabelPosition.top}
          alignCenter
          allowToDeleteAll
          errorMessage={errorMessage || ''}
          emptyTemplate={{
            title: 'To create your exclude list start by adding a field',
            isShow: true,
          }}
        />
      </div>
    </div>
  );
};
