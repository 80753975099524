import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  header: {
    marginBottom: '8px',
    padding: '12px 16px 0 16px',
  },
  results: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: '16px',
    flexGrow: 1,
    overflowY: 'auto',
    padding: '16px 16px 0 16px',
  },
  footer: {
    padding: '0px 16px 12px 16px',
  },
  types: {
    display: 'flex',
    '&:not(:last-child)': {
      marginRight: '8px',
    },
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  type: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
    cursor: 'pointer',
  },
  typeName: {
    marginTop: '8px',
  },
  emptyResult: {
    textAlign: 'center',
  },
});
