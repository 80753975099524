import './deletePiiDataModal.component.scss';
import template from './deletePiiDataModal.component.html';
import { module } from 'angular';
const app = module('app');

app.component('deletePiiDataModal', {
  template,
  controller: [
    '$state',
    function ($state) {
      this.personalDeletedChecked = false;
      this.metadataDeletedChecked = false;
      this.scanHistoryDeletedChecked = false;
      this.stopAllRunningScans = false;

      this.submit = () => {
        this.$close({
          result: {
            stopAllRunningScans: this.stopAllRunningScans,
          },
        });
      };
    },
  ],
  bindings: {
    $close: '&',
    $dismiss: '&',
  },
});
