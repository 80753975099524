import { BigidGridRow } from '@bigid-ui/grid';
import { OwnerV2 } from '../../DataSources/DataSourceConnections/DataSourceConnectionTypes';
import { TagEntity } from '../../TagsManagement/TagsManagementService';

export enum State {
  'pending' = 'pending',
  'queued' = 'queued',
  'in_progress' = 'in_progress',
  'completed' = 'completed',
  'failed' = 'failed',
  'total' = 'total',
}
export type States = Partial<Record<State, number>>;

export interface DataSourceDeletionModel extends BigidGridRow {
  name: string;
  type: string;
  enabled?: 'yes';
  last_execution_date: string;
  updated_at: string;
  created_at: string;
  location?: string;
  it_data_owners: OwnerV2[];
  states: States;
  tags: Array<TagEntity>;
}

export interface DeletionDataSourceResponse {
  data_minimization_data_sources: DataSourceDeletionModel[];
  total_count: number;
}
