import React, { FC } from 'react';
import { PrimaryButton, BigidBody1, TertiaryButton } from '@bigid-ui/components';
import { noop } from 'lodash';
import { BigidWarningIcon } from '@bigid-ui/icons';
import { getApplicationPreference } from '../../../services/appPreferencesService';
import { closeSystemDialog, openSystemDialog, SystemDialogContentProps } from '../../../services/systemDialogService';
import { generateDataAid } from '@bigid-ui/utils';
import styled from '@emotion/styled';
import { getMaxAmountOfDsPerScanFF } from '../../Scans/ScanUtils';

interface OpenDialogForLargeScanSetProps {
  onContinueClick?: () => void;
}

const LargeScanSetContainer = styled('div')`
  display: grid;
  gap: 24px;
`;

const getLargeScanSetDescription = () => {
  const isLimitAmountOfDsPerScanEnabled = getApplicationPreference('ENABLE_LIMIT_AMOUNT_OF_DS_PER_SCAN');
  const maxDsSize = getMaxAmountOfDsPerScanFF();
  return isLimitAmountOfDsPerScanEnabled
    ? `For performance considerations, only ${maxDsSize} data sources can be included per scan. The first ${maxDsSize} data sources you selected will be scanned.`
    : `You are about to scan more than ${maxDsSize} data sources. This may impact scan performance.`;
};

const LARGE_SCAN_SET_CONTINUE = 'Are you sure you wish to continue?';

const LargeScanSet: FC<SystemDialogContentProps<unknown>> = () => (
  <LargeScanSetContainer>
    <BigidBody1 data-aid={generateDataAid('LargeScanSet', ['description'])}>{getLargeScanSetDescription()}</BigidBody1>
    <BigidBody1 data-aid={generateDataAid('LargeScanSet', ['continue'])}>{LARGE_SCAN_SET_CONTINUE}</BigidBody1>
  </LargeScanSetContainer>
);

export const openDialogForLargeScanSet = ({
  onContinueClick = noop,
}: OpenDialogForLargeScanSetProps = {}): Promise<boolean> => {
  return new Promise(resolve => {
    openSystemDialog({
      title: 'Large scan set',
      withCloseButton: false,
      content: LargeScanSet,
      onClose: noop,
      maxWidth: 'xs',
      icon: BigidWarningIcon,
      buttons: [
        {
          component: TertiaryButton,
          onClick: () => {
            closeSystemDialog();
            resolve(false);
          },
          text: 'Cancel',
        },
        {
          component: PrimaryButton,
          onClick: () => {
            onContinueClick();
            resolve(true);
          },
          text: 'Continue',
        },
      ],
      borderBottom: true,
    });
  });
};
