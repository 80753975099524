import { module } from 'angular';
import './newConsentConnection.component.scss';
import template from './newConsentConnection.component.html';
import { agreementsService } from '../../react/services/angularServices';
import { CONSENT_GOVERNANCE_PERMISSIONS } from '@bigid/permissions';
import { isPermitted } from '../../react/services/userPermissionsService';
import { getDataSourcesListWithSelection, getDsConnectionsQuery } from '../../react/utilities/dataSourcesUtils';
const app = module('app');

app.component('newConsent', {
  template,
  controller: function (
    $scope,
    $state,
    $stateParams,
    $rootScope,
    $translate,
    uiGridConstants,
    $appConfig,
    notificationService,
    consentConnectionsService,
    dataSourceConnectionsService,
  ) {
    'ngInject';
    const RESET_RESIDENCY = 'None';

    const availableTypeOptions = { id: '1', name: 'DS-Connection', modelProp: 'isDsConnection' };
    const availableEnableOptions = [
      { id: '1', name: 'Yes' },
      { id: '2', name: 'No' },
    ];

    this.attributesDropdownSettings = {
      showCheckAll: false,
      showUncheckAll: false,
      checkBoxes: true,
      selectedToTop: true,
      displayProp: 'friendly_name',
      idProperty: 'friendly_name',
      enableSearch: true,
      smartButtonMaxItems: 2,
      smartButtonTextConverter: function (itemText) {
        return itemText;
      },
      template: '<span title="{{option.friendly_name}}">{{option.friendly_name}}</span>',
    };

    const selectedType = $stateParams.id;

    this.isTestConnectionSucceeded = false;
    this.isNewPassword = false;
    this.realPass = '';
    this.fields = {
      custom_query_string: 'SELECT * FROM table',
    };

    this.apiKeyInput = {
      type: 'password',
      name: 'site_api_key',
      label: 'Site api key',
      tabIndex: 1,
      apiField: true,
      disableInEditMode: false,
      isRequired: true,
    };

    this.secretKeyInput = {
      type: 'password',
      name: 'application_secret_key',
      label: 'Application secret key',
      tabIndex: 1,
      apiField: true,
      disableInEditMode: false,
      isRequired: true,
    };

    this.fieldsDDL = {};
    this.attributes = [RESET_RESIDENCY];
    this.attributesType = {};

    this.availableDsConnections = [];

    this.isCustomQueryMode = false;
    this.customQuerySelection = [];

    this.country = {};
    this.clickedToAction = false;

    this.$onInit = () => {
      this.isSavePermitted = $stateParams.id
        ? isPermitted(CONSENT_GOVERNANCE_PERMISSIONS.EDIT_CONSENT_SOURCES.name)
        : isPermitted(CONSENT_GOVERNANCE_PERMISSIONS.CREATE_CONSENT_SOURCES.name);
      this.isTestConnectionPermitted = isPermitted(CONSENT_GOVERNANCE_PERMISSIONS.TEST_CONSENT_SOURCES.name);

      this.update('DS-Connection');

      const TRANSLATION_REQUIRED = ['CONSENT_GOVERNANCE', 'CONSENT_GOVERNANCE:TABS:CONSENT_SOURCES'];
      $translate(TRANSLATION_REQUIRED).then(translations => {
        const pageTitle = `${$stateParams.id ? 'Edit' : 'New'} Consent Connections`;

        $rootScope.$broadcast('changePage', pageTitle, false, true, null, [
          { label: translations['CONSENT_GOVERNANCE'], onClick: () => $state.go('consentGovernance.reports') },
          {
            label: translations['CONSENT_GOVERNANCE:TABS:CONSENT_SOURCES'],
            onClick: () => $state.go('consentGovernance.list'),
          },
          { label: pageTitle },
        ]);
      });
      getAgreementsAttributes(response => {
        if (response) {
          this.agreementsAttributes = response.agreements;
          this.agreementsAttributes = this.agreementsAttributes.sort(this.sortAttributes);
        }
      });
      this.fields.selectedAttributes = [];
      this.fields.isExternalSystem = false;
    };

    this.dsType = '';

    const loadConsentForUpdate = async loadConsentData => {
      try {
        $rootScope.$broadcast('changePage', 'Edit Consent Connections', false, true);
        const consentConnection = await consentConnectionsService.getConsentConnectionsDataByID(selectedType);
        loadConsentData(consentConnection);
        await initDataSourcesSelection(consentConnection);
      } catch (e) {
        $translate('API:MESSAGE:COMMON_ERROR').then(translation => {
          notificationService.error(translation);
        });
      }
    };

    async function initDataSourcesSelection(consentConnection) {
      try {
        if (consentConnection) {
          const { availableDsConnections } = await getDataSourcesListWithSelection(consentConnection);

          if (availableDsConnections.find(ds => ds.isExternalSystem) == null) {
            availableDsConnections.push({ name: 'SAP Customer Data Cloud', isExternalSystem: true });
          }
          loadDataSourceConnections(availableDsConnections, consentConnection.dsConnection);
        }
      } catch (e) {
        notificationService.error('failed to load data sources');
        console.error(e);
      }
    }

    const loadDataSourceConnections = (availableDsConnections, consentDsConnection) => {
      if (availableDsConnections) {
        this.availableDsConnections = availableDsConnections;
        const dsConnection = this.availableDsConnections.find(item => item.name === consentDsConnection);
        if (dsConnection) {
          this.dsType = dsConnection.type;
          this.fields.isExternalSystem = dsConnection.isExternalSystem || false;
        }
      }
    };

    const loadConsentData = consentConnection => {
      if (consentConnection) {
        this.realPass = angular.copy(consentConnection.db_pw);
        this.fields.name = consentConnection.name;
        this.fieldsDDL.selectedTypeOption = availableTypeOptions;
        if (consentConnection.enabled) {
          this.fieldsDDL.selectedEnabledOption = getSelectedObject(consentConnection.enabled, availableEnableOptions);
        }
        this.country.selected = {};
        this.update(consentConnection.type);
        this.fields.db_table = consentConnection.db_table;
        this.fields.dsConnection = consentConnection.dsConnection;
        this.fields.custom_query_string = consentConnection.custom_query_string
          ? consentConnection.custom_query_string
          : 'SELECT * FROM ' + (consentConnection.db_table ? consentConnection.db_table : 'table');
        if (consentConnection.connectionStatusScan) {
          this.fields.scan_is_success = consentConnection.connectionStatusScan.is_success;
          this.fields.scan_timestamp = consentConnection.connectionStatusScan.last_connection;
          this.fields.scan_num_of_objects = consentConnection.connectionStatusScan.num_of_object;
        }
        if (consentConnection.connectionStatusTest) {
          this.fields.test_is_success = consentConnection.connectionStatusTest.is_success;
          this.fields.test_timestamp = consentConnection.connectionStatusTest.last_connection;
        }
        loadConsentMappedFields(consentConnection);
        this.isCustomQueryMode = consentConnection.isCustomQueryString ? consentConnection.isCustomQueryString : false;
        this.newIdentityConnectionModelGrid.columnDefs[2]['visible'] = !this.isCustomQueryMode;
        if (consentConnection.attributes) {
          this.newIdentityConnectionModelGrid.data = consentConnection.attributes;
          this.isTestConnectionSucceeded = true;
          this.attributes = [RESET_RESIDENCY];
          consentConnection.attributes.forEach(column => {
            this.attributes.push(column.columnName);
            this.attributesType[column.columnName] = column.isNumber;
          });

          if (!this.isCustomQueryMode) {
            consentConnection.attributes.forEach(column => {
              if (column.selection) {
                this.customQuerySelection.push(column.columnName);
              }
            });
            setCustomQueryString();
          }
        }

        if (consentConnection.selectedAttributes) {
          this.fields.selectedAttributes = consentConnection.selectedAttributes;
        }

        this.fields.isExternalSystem = consentConnection.isExternalSystem || false;
      }
    };

    const loadConsentMappedFields = consentConnection => {
      this.fields.unique_id = consentConnection.unique_id.data;
      this.fields.timestamp = consentConnection.timestamp;
      this.fields.type_of_consent = consentConnection.type_of_consent;
      this.fields.status = consentConnection.status;
      this.fields.agreement_version = consentConnection.agreement_version;
      this.fields.agreement = consentConnection.agreement;
      this.fields.application_id = consentConnection.application_id;
      this.fields.site_api_key = consentConnection.site_api_key;
      this.fields.application_secret_key = consentConnection.application_secret_key;
      this.fields.application_user_key = consentConnection.application_user_key;
      this.apiKeyInput.value = consentConnection.site_api_key;
      this.secretKeyInput.value = consentConnection.application_secret_key;
    };

    //case of update
    if (selectedType) {
      loadConsentForUpdate(loadConsentData, loadDataSourceConnections);
    }
    //case of insert
    else {
      $rootScope.$broadcast('changePage', 'New Consent Connections', false, true);
      this.fields.db_pw = '';
      this.isDsConnection = true;
    }

    this.type = {
      availableOptions: availableTypeOptions,
    };

    this.enabled = {
      availableOptions: availableEnableOptions,
    };

    this.fieldsDDL.selectedEnabledOption = {
      id: '1',
      name: 'Yes', //This sets the default value of the select in the ui
    };

    this.fieldsDDL.selectedTypeOption = {
      id: '1',
      name: 'MySQL', //This sets the default value of the select in the ui
    };

    this.update = type => {
      this.isFormSubmitted = false;
      this.isDsConnection = true;
      this.fieldsDDL.selectedTypeOption = availableTypeOptions;
      const query = getDsConnectionsQuery({});
      dataSourceConnectionsService.getDSConnectionDataByQuery(query).then(({ data }) => {
        const dsConnections = [];
        if (data.ds_connections.find(ds => ds.isExternalSystem) == null) {
          dsConnections.push({ name: 'SAP Customer Data Cloud', isExternalSystem: true });
        }
        this.availableDsConnections = dsConnections.concat(data.ds_connections);
      });
    };

    this.onDsConnectionSelected = dsConnection => {
      this.dsType = dsConnection.type;
      this.fields.isExternalSystem = dsConnection.isExternalSystem || false;
    };

    this.isCustomQueryModeChange = value => {
      this.newIdentityConnectionModelGrid.columnDefs[2]['visible'] = !value;
      if (this.gridApi) {
        this.gridApi.core.notifyDataChange(uiGridConstants.dataChange.COLUMN);
      }
    };

    this.submit = isValid => {
      if (isValid) {
        this.clickedToAction = true;
        setConsentConnection();
        if ($stateParams.id) {
          consentConnectionsService.updateConsentConnection($stateParams.id, this.consentConnection).then(
            () => {
              this.clickedToAction = false;
              notificationService.success('Consent Connection Updated Successfully! ');
              $state.go('consentGovernance.list');
            },
            response => {
              this.clickedToAction = false;
              this.fields.db_pw = '********';
              if (response.data) {
                notificationService.error(response.data, 'Update Consent Connection Failed!');
              }
            },
          );
        } else {
          consentConnectionsService.addConsentConnection(this.consentConnection).then(
            () => {
              this.clickedToAction = false;
              notificationService.success('Consent Connection Added Successfully! ');
              $state.go('consentGovernance.list');
            },
            response => {
              this.clickedToAction = false;
              this.fields.db_pw = '********';
              if (response.data) {
                notificationService.error(response.data, 'Added Connection Connection Failed!');
              }
            },
          );
        }
      } else {
        this.inputNotValid = true;
      }
    };

    const setConsentConnection = isTest => {
      this.consentConnection = {};
      if (!angular.isDefined(isTest)) {
        if (this.fields.db_pw === '********') {
          this.fields.db_pw = null;
        } else if (this.fields.db_pw === '') {
          delete this.fields.db_pw;
        }
      }

      if (this.newIdentityConnectionModelGrid.data) {
        this.fields.attributes = [];

        for (let i = 0; i < this.newIdentityConnectionModelGrid.data.length; i++) {
          this.fields.attributes.row = {};
          this.fields.attributes.row.columnName = this.newIdentityConnectionModelGrid.data[i].columnName;
          this.fields.attributes.row.isNumber = this.newIdentityConnectionModelGrid.data[i].isNumber;
          this.fields.attributes.row.selection = this.newIdentityConnectionModelGrid.data[i].selection;
          this.fields.attributes.row.fieldType = this.newIdentityConnectionModelGrid.data[i].fieldType;
          this.fields.attributes.push(this.fields.attributes.row);
        }
      }
      this.consentConnection.consent_connection = angular.copy(this.fields);
      this.consentConnection.consent_connection.type = 'ds-connection';

      if (!this.isCustomQueryMode) {
        this.consentConnection.consent_connection.custom_query_string = '';
      }
      if (angular.isDefined(isTest)) {
        this.consentConnection.isNewPassword = this.isNewPassword;
        if (selectedType) {
          this.consentConnection.consent_connection.db_pw = !this.isNewPassword ? this.realPass : this.fields.db_pw;
        } else {
          this.consentConnection.consent_connection.db_pw = this.fields.db_pw;
        }
      }
      this.consentConnection.isNewApiKey = this.apiKeyInput.value !== this.fields.site_api_key;
      this.consentConnection.isNewSecretKey = this.secretKeyInput.value !== this.fields.application_secret_key;

      this.consentConnection.consent_connection.site_api_key = this.apiKeyInput.value;
      this.consentConnection.consent_connection.application_secret_key = this.secretKeyInput.value;

      this.consentConnection.consent_connection.enabled = this.fieldsDDL.selectedEnabledOption.name.toLowerCase();
      this.consentConnection.consent_connection.isCustomQueryString = this.isCustomQueryMode;
      this.consentConnection.consent_connection.unique_id = {
        isNumber: this.attributesType[this.fields.unique_id],
        data: this.fields.unique_id,
      };
      this.consentConnection.consent_connection.timestamp = this.fields.timestamp;
      this.consentConnection.consent_connection.type_of_consent = this.fields.type_of_consent;
      this.consentConnection.consent_connection.status = this.fields.status;
      this.consentConnection.consent_connection.agreement_version = this.fields.agreement_version;
      this.consentConnection.consent_connection.agreement = this.fields.agreement;
      this.consentConnection.consent_connection.application_id = this.fields.application_id;
    };

    function getSelectedObject(typeName, availableTypeOptions) {
      const selectedType = availableTypeOptions.find(type => type.name.toLowerCase() === typeName.toLowerCase());
      if (selectedType) {
        return { id: selectedType.id, name: selectedType.name };
      }
      return { id: -1, name: '' };
    }

    this.newIdentityConnectionModelGrid = {
      enableSorting: true,
      enableFiltering: true,
      enableRowSelection: true,
      multiSelect: false,
      enableRowHeaderSelection: false,
      selectionRowHeaderWidth: 35,
      rowEditWaitInterval: -1,
      columnDefs: [
        {
          name: 'Column Name',
          field: 'columnName',
          width: '20%',
          enableCellEdit: false,
          cellTemplate:
            '<div style="margin-left:4px"> {{row.entity.columnName}}<span style="font-size:13px !important;"></span></div>',
        },
        {
          name: 'Data Preview',
          enableFiltering: false,
          enableCellEdit: false,
          field: 'columnData',
          cellTemplate:
            '<div style="margin-left:4px"> {{row.entity.columnData}}<span style="font-size:13px !important;"></span></div>',
        },
        {
          cellClass: 'grid-align',
          name: 'Select',
          field: 'selection',
          enableFiltering: false,
          enableCellEdit: true,
          width: '8%',
          visible: false,
          cellTooltip: row => 'Select columns',
          cellTemplate:
            '<input style="z-index: 99999;" title="Select columns" type="checkbox" ng-change="grid.appScope.selectionChanged(row.entity)" ng-model="row.entity.selection">',
        },
      ],
    };

    this.newIdentityConnectionModelGrid.onRegisterApi = gridApi => {
      this.gridApi = gridApi;
    };

    const setCustomQueryString = () => {
      let customQueryString = 'SELECT ';
      if (this.customQuerySelection.length > 0) {
        customQueryString += this.customQuerySelection.join(', ');
      } else {
        customQueryString += '*';
      }
      customQueryString += ' FROM ';
      customQueryString += getFullTableName(this.fields);
      this.fields.custom_query_string = customQueryString;
    };

    const getFullTableName = consentConnectionTable => {
      let fullTableName = consentConnectionTable.db_table ? consentConnectionTable.db_table : 'table';
      if (
        this.fieldsDDL &&
        this.fieldsDDL.selectedTypeOption.name.toLowerCase() == 'oracle' &&
        consentConnectionTable.db_table &&
        consentConnectionTable.db_name &&
        consentConnectionTable.db_name.trim().length > 0
      ) {
        fullTableName = consentConnectionTable.db_name + '.' + fullTableName;
      }
      return fullTableName;
    };

    $scope.selectionChanged = entity => {
      const index = this.customQuerySelection.indexOf(entity.columnName);
      index > -1 ? this.customQuerySelection.splice(index, 1) : this.customQuerySelection.push(entity.columnName);
      setCustomQueryString();
    };

    this.tableNameChanged = () => {
      setCustomQueryString();
    };

    const testConsentConnection = async testConnectionResponse => {
      try {
        this.clickedToAction = true;
        this.piiGridLoading = true;
        setConsentConnection(true);
        const testConnectionRes = await consentConnectionsService.testConnection(this.consentConnection);
        if ($stateParams.id) {
          consentConnectionsService.updateConsentConnection($stateParams.id, {
            consent_connection: {
              connectionStatusTest: {
                is_success: testConnectionRes.data.isSuccessful,
                last_connection: new Date(),
              },
            },
          });
        }
        testConnectionResponse(testConnectionRes);
      } catch (e) {
        this.clickedToAction = false;
        this.isTestConnectionSucceeded = false;
        this.piiGridLoading = false;
        notificationService.error('Test Connection Failed! ' + e?.message);
      }
    };

    const testConnectionResponse = testConnectionRes => {
      this.clickedToAction = false;
      this.piiGridLoading = false;
      if (testConnectionRes.data.isSuccessful) {
        if (testConnectionRes.data.columns) {
          if (testConnectionRes.data.columns) {
            this.attributes = [RESET_RESIDENCY];
            testConnectionRes.data.columns.forEach(column => {
              this.attributes.push(column.columnName);
              this.attributesType[column.columnName] = column.isNumber;
            });
            this.newIdentityConnectionModelGrid.data = testConnectionRes.data.columns;
          }
          notificationService.success('Test Connection Success! ');
          $rootScope.$applyAsync();

          this.inputNotValid = true;
          this.isTestConnectionSucceeded = true;
          this.newIdentityConnectionModelGrid.columnDefs[2]['visible'] = !this.isCustomQueryMode;
          $rootScope.$emit('$scrollTop');
        }
      } else {
        const errorMessage = testConnectionRes?.data?.err?.message || 'Test Connection Failed!';
        notificationService.error(errorMessage);
        $rootScope.$applyAsync();
      }
    };

    this.testConnection = function (isValid) {
      if (isValid) {
        testConsentConnection(testConnectionResponse);
      } else {
        this.inputNotValid = true;
      }
    };

    const getAgreementsAttributes = async callback => {
      try {
        const attributesResponse = await agreementsService.getAttributes();
        return callback(attributesResponse);
      } catch (err) {
        $translate('API:MESSAGE:COMMON_ERROR').then(translation => {
          notificationService.error(translation);
        });
        return callback([]);
      }
    };

    this.getAttributes = (search, toFilter) => {
      let attribute = this.attributes.slice();
      if (search && attribute.indexOf(search) === -1) {
        attribute.unshift(search);
      }
      if (toFilter) {
        attribute = attribute.filter(att => toFilter.indexOf(att) < 0);
      }
      return attribute;
    };

    this.selectInputChange = async searchString => {
      try {
        if (!this.initialAvailableDSConnections && this.availableDsConnections) {
          this.initialAvailableDSConnections = this.availableDsConnections;
        }
        if (!searchString) {
          this.availableDsConnections = this.initialAvailableDSConnections;
        } else {
          const query = getDsConnectionsQuery({ maxDS: 50, searchString });
          const { data } = await dataSourceConnectionsService.getDSConnectionDataByQuery(query);

          $scope.$applyAsync(() => {
            this.availableDsConnections = data.ds_connections;
          });
        }
      } catch (e) {
        console.error(e);
      }
    };
  },
});
