export enum MetadataSearchFilterDateModifier {
  ANY = 'ANY',
  SPECIFIC = 'SPECIFIC',
}

export const MetadataSearchFilterDateModifierLabelMap: Record<MetadataSearchFilterDateModifier, string> = {
  [MetadataSearchFilterDateModifier.ANY]: 'Any',
  [MetadataSearchFilterDateModifier.SPECIFIC]: 'Specific dates...',
};

export const getIsDateValid = (date: Date): boolean => {
  return date instanceof Date && !isNaN(date.getTime());
};

export const getFromDateMidnight = (date: Date): Date => {
  return new Date(date.setHours(0, 0, 0));
};

export const getToDateMidnight = (date: Date): Date => {
  return new Date(date.setHours(23, 59, 59));
};
