import React, { FC } from 'react';
import styled from '@emotion/styled';
import { noop } from 'lodash';
import { PrimaryButton, SecondaryButton, StyledButtonType, TertiaryButton } from '@bigid-ui/components';
import { BigidDeleteIcon } from '@bigid-ui/icons';
import { generateDataAid } from '@bigid-ui/utils';
import { ApplicationGridRow } from './types';
import { useLocalTranslation } from './translations';
import { getPermissions } from './utils';
import { BigidSystemDialogOptions, openSystemDialog } from '../../services/systemDialogService';
import { ApplicationDialogContent } from './ApplicationDialogContent';

export type ApplicationActionsProps = {
  row: ApplicationGridRow;
  handleSubmit: () => void;
  handleDelete: () => void;
};

const Buttons = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  justify-content: flex-end;
  flex-grow: 1;
`;

export const ApplicationActions: FC<ApplicationActionsProps> = ({ row, handleSubmit, handleDelete }) => {
  const { t } = useLocalTranslation();
  const { isDeletePermitted, isCreatePermitted, isEditPermitted } = getPermissions();

  const showConfirmDeleteDialog = () => {
    const dialogOptions: BigidSystemDialogOptions = {
      title: t('modal.delete.title'),
      onClose: noop,
      maxWidth: 'xs',
      buttons: [
        {
          component: SecondaryButton,
          dataAid: generateDataAid('ApplicationActions', ['cancel', 'delete']),
          isClose: true,
          onClick: noop,
          text: t('buttons.cancel'),
        },
        {
          component: PrimaryButton,
          dataAid: generateDataAid('ApplicationActions', ['confirm', 'delete']),
          isClose: true,
          disabled: !isDeletePermitted,
          onClick: handleDelete,
          text: t('buttons.delete'),
        },
      ],
      content: ApplicationDialogContent,
      contentProps: { body: t('modal.delete.body', { entityName: row.name }) },
      borderTop: true,
    };

    openSystemDialog(dialogOptions);
  };

  const actions: (StyledButtonType & { component: FC<StyledButtonType> })[] = [
    {
      component: TertiaryButton,
      dataAid: generateDataAid('ApplicationActions', ['delete']),
      startIcon: <BigidDeleteIcon />,
      disabled: !isDeletePermitted,
      onClick: () => showConfirmDeleteDialog(),
      color: 'grey',
      size: 'medium',
      text: t('buttons.delete'),
    },
    {
      component: SecondaryButton,
      dataAid: generateDataAid('ApplicationActions', ['save']),
      disabled: row?.isPending ? !isCreatePermitted : !isEditPermitted,
      onClick: handleSubmit,
      color: 'grey',
      size: 'medium',
      text: t('buttons.save'),
    },
  ];

  return (
    <Buttons>
      {actions.map(({ component: Component, ...rest }) => (
        <Component key={rest.dataAid} {...rest} />
      ))}
    </Buttons>
  );
};
