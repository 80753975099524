import React, { FC } from 'react';
import { BigidBody1, BigidColorsV2, BigidHeading2, BigidMenuItemProps } from '@bigid-ui/components';
import { InteractiveDashboardCard } from './InteractiveDashboardCard';
import { InteractiveDashboardMenu } from './InteractiveDashboardMenu';
import styled from '@emotion/styled';
import { IsHovered } from './IsHovered';
import { generateDataAid } from '@bigid-ui/utils';

export interface InteractiveDashboardInfoBoxWidgetProps {
  icon?: React.ReactElement;
  data: React.ReactElement;
  description: string;
  titleActions?: BigidMenuItemProps[] | { [key: string]: BigidMenuItemProps[] };
}

const StyledBigidHeading3 = styled(BigidHeading2)({
  display: 'flex',
  alignItems: 'stretch',
  justifyContent: 'center',
  marginBottom: 12,
  lineHeight: '33px',
});

const StyledBigidBody1 = styled(BigidBody1)({ textAlign: 'center', color: BigidColorsV2.gray[600] });
const IconWrapper = styled('div')({
  marginRight: 8,
});

interface InteractiveDashboardInfoBoxWidgetHeadingProps {
  children?: React.ReactNode;
  icon?: React.ReactElement;
  titleActions: BigidMenuItemProps[] | { [key: string]: BigidMenuItemProps[] };
  dataAid?: string;
}
const InteractiveDashboardInfoBoxWidgetHeading: FC<InteractiveDashboardInfoBoxWidgetHeadingProps> = ({
  children,
  icon,
  titleActions,
  dataAid,
}) => {
  const { isHovered, setIsHovered } = IsHovered.useContainer();
  return (
    <StyledBigidHeading3 data-aid={dataAid}>
      <IconWrapper>{icon}</IconWrapper>
      <div data-aid={generateDataAid(dataAid, ['middle-value'])}>{children}</div>
      <InteractiveDashboardMenu
        titleActions={titleActions}
        setIsHovered={setIsHovered}
        isHovered={isHovered}
        dataAid={dataAid}
      />
    </StyledBigidHeading3>
  );
};

const Centered = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  padding: 24px;
`;

export const InteractiveDashboardInfoBoxWidget: FC<InteractiveDashboardInfoBoxWidgetProps> = ({
  icon,
  data,
  description,
  titleActions,
}) => {
  return (
    <InteractiveDashboardCard minHeight={158}>
      <Centered>
        <InteractiveDashboardInfoBoxWidgetHeading
          icon={icon}
          titleActions={titleActions}
          dataAid={generateDataAid('InteractiveDashboardInfoBoxWidget', ['data'])}
        >
          {data}
        </InteractiveDashboardInfoBoxWidgetHeading>
        <StyledBigidBody1 data-aid={generateDataAid('InteractiveDashboardInfoBoxWidget', ['description'])}>
          {description}
        </StyledBigidBody1>
      </Centered>
    </InteractiveDashboardCard>
  );
};
