import { useState, useEffect, useRef } from 'react';
import { CloudProvider } from '../../../../../../AutoDiscoveryWizard/autoDiscoveryWizardServices';

export type CloudProviderDialog = Record<CloudProvider, boolean>;

export interface ConnectionDialogsData {
  dialogsState: CloudProviderDialog;
  updateDialogState: (type: CloudProvider, isOpened: boolean) => void;
}

export const useConnectionDialogs = (cloudProviders: CloudProvider[]): ConnectionDialogsData => {
  const [dialogsState, setDialogsState] = useState({} as CloudProviderDialog);
  const initialProviders = useRef(cloudProviders);

  useEffect(() => {
    const dialogs: CloudProviderDialog = {} as CloudProviderDialog;
    initialProviders.current.forEach(proivder => (dialogs[proivder] = false));
    setDialogsState(dialogs);
  }, []);

  const updateDialogState = (type: CloudProvider, isOpened: boolean) => {
    if (dialogsState[type] !== undefined) {
      setDialogsState({ ...dialogsState, [type]: isOpened });
    }
  };

  return {
    dialogsState,
    updateDialogState,
  };
};
