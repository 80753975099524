import React, { FC, useEffect, useState, useMemo } from 'react';
import { BigidBody2, BigidHeading6, BigidLink } from '@bigid-ui/components';
import { generateDataAid } from '@bigid-ui/utils';
import {
  CORRELATION_PERMISSIONS,
  CLUSTER_ANALYSIS_PERMISSIONS,
  CLASSIFICATION_PERMISSIONS,
  POLICIES_PERMISSIONS,
} from '@bigid/permissions';
import { styled } from '@mui/material';
import { CONFIG } from '../../../../config/common';
import { $state } from '../../../services/angularServices';
import { MarketplaceApp } from '../../ApplicationsManagement/applicationManagementService';
import { AppInfoModal } from '../../ApplicationsManagement/Tabs/MarketplaceConnectivity/AppInfoModal/AppInfoModal';
import { useLocalTranslation } from '../translations';
import { fetchDataStewardshipMarketingAppData } from '../dataExplorerService';
import { getApplicationPreference } from '../../../services/appPreferencesService';
import { isPermitted } from '../../../services/userPermissionsService';
import {
  sendDataExplorerLandingPageBrowseCatalogLinkBiAnalytics,
  sendDataExplorerLandingPageClassificationLinkBiAnalytics,
  sendDataExplorerLandingPageCorrelationLinkBiAnalytics,
  sendDataExplorerLandingPageDataOverviewLinkBiAnalytics,
  sendDataExplorerLandingPagePoliciesLinkBiAnalytics,
  sendDataExplorerLandingPageDiscoveredDataSourceLinkBiAnalytics,
  sendDataExplorerLandingPageFollowedObjectLinkBiAnalytics,
  sendDataExplorerLandingPageViewedObjectLinkBiAnalytics,
  sendDataExplorerLandingPageClusteringLinkBiAnalytics,
} from '../services/analytics';

const Container = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 140px;
  ${({ theme }) => theme.breakpoints.down('lg')} {
    width: 130px;
  }
`;

const List = styled('ul')`
  list-style: none;
  padding: 0;
  gap: 18px;
  display: flex;
  flex-direction: column;
`;

type QuickLinksProps = {
  dataAid?: string;
};

type QuickLink = {
  title: string;
  link: string;
  params?: Record<string, unknown>;
  biAnalyticsFunc?: () => void;
};

export const QuickLinks: FC<QuickLinksProps> = ({ dataAid = 'QuickLinks' }) => {
  const { t } = useLocalTranslation('QuickLinks');
  const [showAppInfoModal, setShowAppInfoModal] = useState(false);
  const [stewardshipData, setStewardshipData] = useState<MarketplaceApp>();
  //NOTE: keep disabled till Stewardship is deprecated, remove afterwards
  // const licenseData = useMemo(() => {
  //   return getDataStewardshipLicenseData();
  // }, []);
  const linksConfig = useMemo(() => {
    const linksConfig: QuickLink[] = [];

    if (isPermitted(CORRELATION_PERMISSIONS.ACCESS.name)) {
      linksConfig.push({
        title: t('items.correlation'),
        link: CONFIG.states.CORRELATION,
        biAnalyticsFunc: sendDataExplorerLandingPageCorrelationLinkBiAnalytics,
      });
    }

    if (isPermitted(CLASSIFICATION_PERMISSIONS.ACCESS.name)) {
      linksConfig.push({
        title: t('items.classification'),
        link: CONFIG.states.CLASSIFICATION,
        biAnalyticsFunc: sendDataExplorerLandingPageClassificationLinkBiAnalytics,
      });
    }

    if (isPermitted(CLUSTER_ANALYSIS_PERMISSIONS.ACCESS.name) && getApplicationPreference('CLUSTERING_ENABLED')) {
      linksConfig.push({
        title: t('items.clusters'),
        link: CONFIG.states.CLUSTERING,
        biAnalyticsFunc: sendDataExplorerLandingPageClusteringLinkBiAnalytics,
      });
    }

    if (getApplicationPreference('DATA_OVERVIEW_ENABLED')) {
      linksConfig.push({
        title: t('items.dataOverview'),
        link: CONFIG.states.CATALOG_DISCOVERY,
        biAnalyticsFunc: sendDataExplorerLandingPageDataOverviewLinkBiAnalytics,
      });
    }

    //NOTE: keep disabled till Stewardship is deprecated, remove afterwards
    // linksConfig.push({
    //   title: t('items.dataStewardship'),
    //   link: CONFIG.states.CUSTOM_APP,
    //   ...(licenseData && {
    //     params: {
    //       id: licenseData.name,
    //       name: licenseData.name,
    //       vendor: licenseData.vendor,
    //     },
    //   }),
    // });

    if (isPermitted(POLICIES_PERMISSIONS.ACCESS.name)) {
      linksConfig.push({
        title: t('items.policies'),
        link: CONFIG.states.POLICIES,
        biAnalyticsFunc: sendDataExplorerLandingPagePoliciesLinkBiAnalytics,
      });
    }

    return linksConfig;
  }, [t]);

  const handleItemClick = (
    link: string,
    title: string,
    biAnalyticsFunc: () => void,
    params?: Record<string, unknown>,
  ) => {
    //NOTE: keep disabled till Stewardship is deprecated, remove afterwards
    // if (title === t('items.dataStewardship') && !isDataStewardshipAppInstalled(licenseData)) {
    //   setShowAppInfoModal(true);
    //   return;
    // }
    $state.go(link, params);
    biAnalyticsFunc?.();
  };

  useEffect(() => {
    const getAppData = async () => {
      const appData = await fetchDataStewardshipMarketingAppData();
      setStewardshipData(appData);
    };
    getAppData();
  }, []);

  return (
    <Container data-aid={generateDataAid(dataAid, ['container'])}>
      <BigidHeading6>{t('title')}</BigidHeading6>
      <List data-aid={generateDataAid(dataAid, ['list'])}>
        {linksConfig.map(({ link, title, params, biAnalyticsFunc }) => {
          return (
            <li key={title}>
              <BigidBody2 whiteSpace="nowrap">
                <BigidLink
                  text={title}
                  onClick={() => handleItemClick(link, title, biAnalyticsFunc, params)}
                  underline="hover"
                />
              </BigidBody2>
            </li>
          );
        })}
      </List>
      <AppInfoModal
        appData={stewardshipData}
        isOpen={showAppInfoModal}
        onClose={() => setShowAppInfoModal(false)}
        onClickToInstall={() => setShowAppInfoModal(false)}
      />
    </Container>
  );
};
