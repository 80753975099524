import React, { FC } from 'react';
import { BigidFormProps } from '@bigid-ui/components';
import styled from '@emotion/styled';
import { TemplateDetailsForm, EmailContentForm } from './FormItems';

export interface EmailEditorFormProps {
  dataAid?: string;
  templateId?: string;
  templateDetailsFormProps: BigidFormProps;
  emailContentFormProps: BigidFormProps;
  isEmailContentLoading: boolean;
  sectionFields: string[][];
  selectedCategory?: string;
  validateBeforeSend?: boolean;
  onValidate: () => Promise<boolean>;
}

const MainContainer = styled.div({
  height: '100%',
  display: 'flex',
  flexDirection: 'row',
  '& fieldset': {
    margin: 0,
    padding: '8px 0',
  },
});

export const EmailEditorForm: FC<EmailEditorFormProps> = ({
  dataAid = 'EmailEditorForm',
  templateDetailsFormProps,
  emailContentFormProps,
  isEmailContentLoading,
  sectionFields,
  selectedCategory,
  validateBeforeSend,
  onValidate,
}) => {
  return (
    <MainContainer data-aid={dataAid}>
      <TemplateDetailsForm formProps={templateDetailsFormProps} />
      <EmailContentForm
        isLoading={isEmailContentLoading}
        selectedCategory={selectedCategory}
        formProps={emailContentFormProps}
        sectionFields={sectionFields}
        validateBeforeSend={validateBeforeSend}
        onValidate={onValidate}
      />
    </MainContainer>
  );
};
