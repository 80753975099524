import React from 'react';
import { DataSourceConnectionGuideTab } from './DataSourceConnectionGuideTab';
import { generateDataAid } from '@bigid-ui/utils';
import type { DataSourceConnectionGuideTab as DataSourceConnectionGuideTabType } from './types';

type DataSourceConnectionGuideContentProps = {
  dataAid?: string;
  tabs: DataSourceConnectionGuideTabType[];
};

export const DataSourceConnectionGuideContent = ({
  dataAid = 'DataSourceConnectionGuideContent',
  tabs,
}: DataSourceConnectionGuideContentProps): JSX.Element => {
  return (
    <>
      {tabs.map(({ id, ...rest }) => {
        return (
          <DataSourceConnectionGuideTab dataAid={generateDataAid(dataAid, ['tab', id])} key={id} id={id} {...rest} />
        );
      })}
    </>
  );
};
