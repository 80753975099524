import '../components/BigidNotifications/BigidNotifications';
import { toastNotificationService } from '@bigid-ui/components';
import { module } from 'angular';
const app = module('app');

export const notificationService = toastNotificationService;

app.factory('notificationService', () => {
  return notificationService;
});
