import React, { FC, useState } from 'react';
import {
  BigidAdditionalItemsChip,
  BigidBody1,
  BigidButtonIcon,
  BigidHeading6,
  BigidTooltip,
} from '@bigid-ui/components';
import { openScannersDialog } from './OpenScannersDialog';
import { BigidCopyIcon } from '@bigid-ui/icons';
import { copyToClipboard, NOT_APPLICABLE } from '../../ScanUtils';
import makeStyles from '@mui/styles/makeStyles';
import { useCopy } from '../../../../hooks/useCopy';

interface ScannerFieldProps {
  dataAid?: string;
  scannersIds: string[];
  title: string;
}

const useStyles = makeStyles({
  scannerRow: {
    display: 'flex',
    alignItems: 'center',
    '& [data-aid="BigidAdditionalItemsChip"]': { width: 'auto', marginLeft: '12px', padding: '0 4px' },
    '& > p': {
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      maxWidth: 'calc(100% - 65px)',
    },
  },
  copyAction: {
    marginLeft: '12px',
  },
});

export const ScannerField: FC<ScannerFieldProps> = ({ scannersIds, title, dataAid = 'ScannerField' }) => {
  const classes = useStyles({});
  const { handleHasClicked, label } = useCopy();

  const copyToClipboardHandler = () => {
    copyToClipboard(scannersIds?.[0]);
    handleHasClicked();
  };

  return (
    <div data-aid={dataAid}>
      <BigidHeading6>{title}</BigidHeading6>
      <div className={classes.scannerRow}>
        <BigidBody1>{scannersIds?.[0] ?? NOT_APPLICABLE}</BigidBody1>
        {scannersIds?.length > 1 ? (
          <BigidAdditionalItemsChip
            onClick={() => openScannersDialog('Scanner ID', scannersIds)}
            total={scannersIds.length - 1}
            isTooltipDisabled
          />
        ) : (
          <BigidTooltip title={label}>
            <BigidButtonIcon className={classes.copyAction} icon={BigidCopyIcon} onClick={copyToClipboardHandler} />
          </BigidTooltip>
        )}
      </div>
    </div>
  );
};
