import { httpService } from './httpService';
import { notificationService } from './notificationService';
import { smallIdLicenseService } from './smallIdLicenseService';
import { sessionStorageService } from '../../common/services/sessionStorageService';
import { licenseService } from './licenseService';
import { SystemEvents, systemEventsEmitter } from './systemEvents';
import { analyticsService } from './analyticsService';
import { BIGID_BI_EVENTS } from '../config/BigIdBiEvents';

export interface MeteringSummary {
  percentageUsed: number;
  percentageLeft: number;
  quotaUsedInGB: number;
  isQuotaReached: boolean;
}

const MESSAGES = {
  WARNING:
    'You have used 85% of your monthly volume quota limit. Once the limit is reached, new scan cannot be triggered this month. Consider upgrading your license quota limit or reducing the number of scans this month.',
  ERROR:
    'You have reached your monthly volume quota limit. Running scans will not be stopped but new scans cannot be triggered this month. If you need more quota please consider upgrading your license quota limit.',
};

let meteringIntervalId: number;

systemEventsEmitter.addEventListener(SystemEvents.LOGOUT, () => {
  sessionStorageService.remove('isMeteringQuotaReached');
  sessionStorageService.remove('isMeteringWarningReached');
  clearInterval(meteringIntervalId);
});

export const getMeteringSummary = async () => {
  const {
    data: { data },
  } = await httpService.fetch<{ data: MeteringSummary }>('metering/monthly-summary');
  analyticsService.trackOneTimeSessionEvent(BIGID_BI_EVENTS.METERING_SUMMARY, data);
  return data;
};

export const showQuotaMessagesIfNeededAndReturnSummary = async () => {
  if (!smallIdLicenseService.isSmallIDLicense()) return;

  try {
    const summary = await getMeteringSummary();
    const { isQuotaReached, percentageUsed } = summary;
    if (isQuotaReached) {
      notificationService.error(MESSAGES.ERROR);
    } else if (percentageUsed >= 85) {
      notificationService.warning(MESSAGES.WARNING);
    }

    return summary;
  } catch (e) {
    console.warn('failed to fetch metering data');
  }
};

export const startMeteringInterval = async () => {
  const meteringSummary: MeteringSummary = await getMeteringSummary();
  if (shouldStartMeteringInterval()) {
    const isQuotaReached = await showQuotaNotificationsOnce(meteringSummary, false);
    if (!isQuotaReached) {
      meteringIntervalId = window.setInterval(async () => {
        showQuotaNotificationsOnce(meteringSummary, true);
      }, 2000 * 60);
    }
  }
};

const shouldStartMeteringInterval = () => {
  return (
    smallIdLicenseService.isSmallIDLicense() &&
    sessionStorageService.get('isMeteringQuotaReached') !== true &&
    licenseService.getLicense()?.quotaLimit?.total !== -1
  );
};

const showQuotaNotificationsOnce = async (meteringSummary: MeteringSummary, shouldClearInterval?: boolean) => {
  try {
    const hasQuotaWarningNotified = sessionStorageService.get('isMeteringWarningReached') === true;

    if (meteringSummary.isQuotaReached) {
      shouldClearInterval && window.clearInterval(meteringIntervalId);
      notificationService.error(MESSAGES.ERROR);
      sessionStorageService.set('isMeteringQuotaReached', true);
    } else if (meteringSummary.percentageUsed >= 85 && !hasQuotaWarningNotified) {
      sessionStorageService.set('isMeteringWarningReached', true);
      notificationService.warning(MESSAGES.WARNING);
    }

    return meteringSummary.isQuotaReached;
  } catch (e) {
    console.warn('failed to fetch metering data');
  }
};
