import styled from '@emotion/styled';

type ActionSidebarWrapperStyleProps = {
  showExpandIcon: boolean;
};

type ActionSidebarCardStyleProps = {
  offset?: number;
};

export const ActionSidebarWrapper = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;

  .MuiAccordionSummary-expandIconWrapper {
    display: ${(props: ActionSidebarWrapperStyleProps) => (props.showExpandIcon ? 'initial' : 'none')};
  }

  .MuiAccordionSummary-content {
    cursor: ${(props: ActionSidebarWrapperStyleProps) => (props.showExpandIcon ? 'pointer' : 'default')};
  }
`;

export const ActionSidebarHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 12px;
`;

export const ActionSidebarContent = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  gap: 1rem;
  overflow: auto;
`;

export const ActionSidebarItemHeader = styled.div`
  display: flex;
  gap: 0.3rem;
  overflow: hidden;
  align-items: center;
`;

export const ActionSidebarItemActions = styled.div`
  display: flex;
  gap: 0.3rem;
`;

export const ActionSidebarCard = styled.div`
  position: relative;

  .MuiCollapse-root {
    min-height: ${(props: ActionSidebarCardStyleProps) => (props?.offset ? `${props.offset}px` : 0)} !important;
    visibility: visible !important;
  }
`;

export const ActionSidebarIndicator = styled.div`
  position: absolute;
  top: 6px;
  right: 6px;
  z-index: 1;
`;
