import React, { FC, useContext, useEffect, useRef } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { PreDiscoverProps } from '../../FmsdPreDiscover';
import { BigidBody1, BigidHeading5, BigidIcon, BigidLink } from '@bigid-ui/components';
import { PreDiscoverContext, ReducerActions } from '../../hooks';
import { AwsInfoDialog, AzureInfoDialog } from './ConnectionDialogs';
import { ConnectionCard, CredsStatus } from '../../../FmsdComponents';
import {
  CloudProvider,
  SaaSProvider,
  SaasConnectors,
} from '../../../../AutoDiscoveryWizard/autoDiscoveryWizardServices';
import { useConnectionDialogs } from './ConnectionDialogs/hooks/useConnectionDialogs';
import { useOAuthFlowConnection } from '../../hooks/useOAuthFlowConnection';
import FmsdFirstLogo from '../../../../../assets/icons/fmsdOfferedConnector1.svg';
import FmsdSecondLogo from '../../../../../assets/icons/fmsdOfferedConnector2.svg';
import FmsdThirdLogo from '../../../../../assets/icons/fmsdOfferedConnector3.svg';
import FmsdFourthLogo from '../../../../../assets/icons/fmsdOfferedConnector4.svg';
import {
  FmsdPageViewsEvents,
  FmsdTrackingEvents,
  trackFmsdEvent,
  trackFmsdPageView,
} from '../../../fmsdEventsTrackerUtils';
import { useLocalTranslation } from '../../../translations';

export interface AutoDiscoveryAppIdsType {
  id: string;
  tpaId: string;
}

const useStyles = makeStyles(theme => ({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    width: 700,
    margin: '0 auto',
  },
  mainContainer: {
    display: 'flex',
    flexDirection: 'column',
    paddingTop: 16,
  },
  formContainer: {
    '& *': {
      boxSizing: 'content-box',
    },
  },
  cardsContainer: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'row',
    padding: '24px 0 32px 0',
    '& >div:not(:last-child)': {
      paddingRight: 24,
    },
  },
  infoContainer: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: 15,
    justifyContent: 'center',
    alignItems: 'center',
  },
  descriptionContainer: {
    display: 'flex',
    columnGap: 28,
    alignItems: 'center',
    fontWeight: 400,
    fontSize: 12,
    paddingTop: 16,
  },
  descriptionContainerTitle: {
    color: theme.vars.palette.bigid.gray500,
  },
  descriptionContainerInfo: {
    paddingBottom: 16,
    paddingTop: 8,
    textAlign: 'center',
  },
  iconsContainer: {
    display: 'flex',
    columnGap: 12,
    alignItems: 'center',
  },
  title: {
    textAlign: 'center',
  },
}));

export const FmsdConnect: FC<PreDiscoverProps> = () => {
  const classes = useStyles({});
  const {
    dispatch,
    connections,
    wizardActions: { setStepAsActive, setIsNextButtonDisabled },
  } = useContext(PreDiscoverContext);
  const setStepAsActiveRef = useRef(setStepAsActive);
  const { dialogsState, updateDialogState } = useConnectionDialogs([CloudProvider.AWS, CloudProvider.AZURE]);
  const { oAuthIsLoading, oAuthConnectionFlow } = useOAuthFlowConnection();
  const { t } = useLocalTranslation('Connect');
  const cloudProviderCardsData = [
    { type: CloudProvider.AWS, status: connections.aws?.credsStatus },
    { type: CloudProvider.AZURE, status: connections.azure?.credsStatus },
  ];

  const saasConnectorCardsData = [
    {
      type: SaaSProvider.SAAS_CONNECTORS,
      status: connections.salesforce?.credsStatus,
      category: SaasConnectors.SALESFORCE,
    },
  ];

  const onConnectionDialogClose = (provider: CloudProvider) => {
    connections && dispatch({ type: ReducerActions.UPDATE_PERMISSIONS, payload: { connections } });
    updateDialogState(provider, false);
  };

  useEffect(() => {
    let shouldDisableNextButton = true;
    for (const type in connections) {
      const status = connections[type].credsStatus;
      if (status === CredsStatus.CANT_RUN) {
        shouldDisableNextButton = true;
        break;
      } else if (status === CredsStatus.VALID || status === CredsStatus.CANT_EVALUATE) {
        shouldDisableNextButton = false;
      }
    }
    setStepAsActiveRef.current();
    setIsNextButtonDisabled(shouldDisableNextButton);
  }, [connections, setIsNextButtonDisabled]);

  useEffect(() => {
    trackFmsdPageView(FmsdPageViewsEvents.FMSD_CONNECT_PAGE_VIEW);
  }, []);

  return (
    <div className={classes.wrapper} data-aid="FmsdConnect-main-container">
      <div className={classes.mainContainer}>
        <BigidHeading5 className={classes.title}>{t('title')}</BigidHeading5>
        <BigidBody1 className={classes.descriptionContainerInfo}>{t('subTitle')}</BigidBody1>
        <div className={classes.cardsContainer}>
          {cloudProviderCardsData.map(connection => (
            <ConnectionCard
              key={connection.type}
              onCardClick={() => updateDialogState(connection.type, true)}
              id={connection.type}
              status={connection.status}
              type={connection.type}
            />
          ))}
          {saasConnectorCardsData.map(connection => (
            <ConnectionCard
              key={connection.type}
              onCardClick={() => oAuthConnectionFlow(connection.category)}
              id={connection.category}
              status={connection.status}
              isLoading={oAuthIsLoading}
              type={connection.type}
            />
          ))}
        </div>
      </div>
      <div className={classes.infoContainer}>
        <div className={classes.descriptionContainer}>
          <BigidBody1 className={classes.descriptionContainerTitle}>{t('descriptionText')}</BigidBody1>
          <div className={classes.iconsContainer}>
            <BigidIcon icon={FmsdFourthLogo} />
            <BigidIcon icon={FmsdThirdLogo} />
            <BigidIcon icon={FmsdSecondLogo} />
            <BigidIcon icon={FmsdFirstLogo} />
          </div>
        </div>
        <BigidBody1 className={classes.descriptionContainerInfo}>
          {`${t('helpText')} `}
          <BigidLink
            href="mailto:support-smallid@bigid.com?subject=SmallID Support Ticket"
            rel="noreferrer"
            text={t('serviceLinkText')}
            underline="none"
            onClick={() => trackFmsdEvent(FmsdTrackingEvents.FMSD_CONNECT_HELP_SERVICE_CLICK)}
          />
        </BigidBody1>
      </div>
      <AwsInfoDialog
        isOpen={dialogsState[CloudProvider.AWS] || false}
        onClose={() => onConnectionDialogClose(CloudProvider.AWS)}
      />
      <AzureInfoDialog
        isOpen={dialogsState[CloudProvider.AZURE] || false}
        onClose={() => onConnectionDialogClose(CloudProvider.AZURE)}
      />
    </div>
  );
};
