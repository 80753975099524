import {
  BigidColorsV2,
  BigidDropdown,
  BigidDropdownOption,
  BigidHeading6,
  BigidMenuItemProps,
} from '@bigid-ui/components';
import React, { FC } from 'react';
import { isArray, isEqual, isPlainObject } from 'lodash';
import { RowWithActions } from './InteractiveDashboardBarChartWidget';
import { InteractiveDashboardMenu } from './InteractiveDashboardMenu';
import styled from '@emotion/styled';
import { IsHovered } from './IsHovered';
import { generateDataAid } from '@bigid-ui/utils';

export interface InteractiveDashboardHeadingWithDropdownProps {
  title: string;
  rowsWithActions?: RowWithActions[] | { [key: string]: RowWithActions[] };
  titleActions?: BigidMenuItemProps[] | { [key: string]: BigidMenuItemProps[] };
  setSelectedTab?: React.Dispatch<React.SetStateAction<string>>;
  selectedTab?: string;
}

const Spacer = styled('div')({
  flexGrow: 1,
});

const StyledHeader = styled('header')({
  display: 'flex',
  padding: '24px',
});

const DropdownWrapper = styled('div')<{ isHovered: boolean }>`
  display: flex;
  justify-content: flex-end;
  transform: ${props => (props.isHovered ? 'translateX(0)' : 'translateX(40px)')};
  transition: transform 0.3s ease-out;
  will-change: transform;
`;

const StyledBigidHeading6 = styled(BigidHeading6)`
  color: ${BigidColorsV2.gray['700']};
`;

const propsAreEqual = (
  prevProps: InteractiveDashboardHeadingWithDropdownProps,
  nextProps: InteractiveDashboardHeadingWithDropdownProps,
) => {
  return (
    prevProps.title === nextProps.title &&
    isEqual(prevProps.rowsWithActions, nextProps.rowsWithActions) &&
    prevProps.selectedTab === nextProps.selectedTab &&
    isEqual(prevProps.titleActions, nextProps.titleActions)
  );
};
export const InteractiveDashboardHeadingWithDropdown: FC<InteractiveDashboardHeadingWithDropdownProps> = React.memo(
  ({ rowsWithActions, setSelectedTab, selectedTab, titleActions, title }) => {
    let selectedTitleActions: BigidMenuItemProps[] = [];

    if (isArray(titleActions)) {
      selectedTitleActions = titleActions;
    } else if (selectedTab && titleActions) {
      selectedTitleActions = titleActions[selectedTab];
    }

    const { isHovered, setIsHovered } = IsHovered.useContainer();

    const options: BigidDropdownOption[] = isPlainObject(rowsWithActions)
      ? Object.keys(rowsWithActions).map(key => {
          const bigidDropdownOption: BigidDropdownOption = {
            id: key,
            value: key,
            displayValue: key,
          };
          return bigidDropdownOption;
        })
      : [];
    const onSelect = (options: BigidDropdownOption[]) => {
      setSelectedTab?.(options[0].value);
    };

    return (
      <StyledHeader>
        <StyledBigidHeading6>{title}</StyledBigidHeading6>

        <Spacer />

        {options?.length > 1 && (
          <DropdownWrapper isHovered={isHovered}>
            <BigidDropdown
              options={options}
              value={options}
              onSelect={onSelect}
              dataAid={generateDataAid('InteractiveDashboardHeadingWithDropdown', ['dropdown'])}
            />
          </DropdownWrapper>
        )}

        <InteractiveDashboardMenu
          titleActions={selectedTitleActions}
          isHovered={isHovered}
          setIsHovered={setIsHovered}
        />
      </StyledHeader>
    );
  },
  propsAreEqual,
);
