import React, { useRef, useState, forwardRef, useEffect, MutableRefObject, useMemo, FC } from 'react';
import {
  BigidLayoutEmptyState,
  BigidLayoutMasterDetails as BigidLayout,
  BigidLayoutMasterDetailsComponent,
  BigidLayoutMasterDetailsConfig,
  BigidLayoutMasterDetailsEvent,
  useLayout,
  BigidLayoutMasterDetailsGridEvents,
} from '@bigid-ui/layout';
import {
  BigidBody1,
  BigidForm,
  BigidFormProps,
  BigidFormStateAndHandlers,
  BigidFormValidateOnTypes,
  BigidFormValues,
  PrimaryButton,
  SecondaryButton,
  StyledButtonType,
  TertiaryButton,
} from '@bigid-ui/components';
import { BigidGridProps, BigidGridRow } from '@bigid-ui/grid';
import { BigidDeleteIcon, BigidQueriesIllustration } from '@bigid-ui/icons';
import { AddBoxOutlined } from '@mui/icons-material';
import { LegacySavedQueriesPage } from '../../../administration/savedQueriesWrapper/savedQueriesWrapper.component';
import { SavedQueriesHeader } from './SavedQueriesHeader';
import { Wrapper, Content } from './SavedQueriesStyles';
import { notificationService } from '../../services/notificationService';
import { pageHeaderService } from '../../../common/services/pageHeaderService';
import { savedQueriesService } from '../../../administration/savedQueries/savedQueries.service';
import { isPermitted } from './../../services/userPermissionsService';
import { getApplicationPreference } from '../../services/appPreferencesService';
import {
  createGridConfig,
  createLayoutConfig,
  mapQueryGridRowKeyToFormField,
  mapQueryResponseToQueryRequest,
  mapQueryResponseToGridRow,
} from './mappers/query';
import { useLocalTranslation } from './translations';
import { sortBy } from './utils';
import { uniqueId, noop, isNull } from 'lodash';
import { EMPTY_SAVED_QUERY_ROW } from './constants';
import { TAGS_SAVED_QUERIES_PERMISSIONS } from '@bigid/permissions';
import type { QueryGridRow, QueryItemResponse, SavedQueriesService } from './types';
import { BigidSystemDialogOptions, openSystemDialog } from '../../services/systemDialogService';
import { generateDataAid } from '@bigid-ui/utils';
import { useRouteLeavingListener } from '../../components/RouteLeaving/hooks/useRouteLeavingListener';

type ContentComponentProps = {
  row: QueryGridRow;
};

type BigidLayoutMasterDetailsRenderProps<GridRow extends BigidGridRow> = {
  config: BigidLayoutMasterDetailsConfig<GridRow>;
  selectedItem: GridRow;
};

interface ShowUnsavedChangesDialog {
  onClickCancel: () => void;
  onClickSave: () => Promise<void>;
}

const NO_DATA: QueryGridRow[] = [];
const NO_DATA_TOTAL_ROWS = 0;
const TEMP_ROW_PREFIX = '0000';

const excludedFields = ['create_date', 'update_date', 'id', '_id', 'isPending'];
const sortOrder: (keyof QueryItemResponse)[] = ['tag_name', 'tag_value', 'query', 'description'];

const filterQueryNameIfPendingQuery = (key: string, isPending: boolean) =>
  !isPending && key === 'tag_name' ? false : true;

const excludeQueryFields = (key: string) => !excludedFields.includes(key);

const useNotifications = () => notificationService;

const useSavedQueryService = (): SavedQueriesService => savedQueriesService as unknown as SavedQueriesService;

const usePageHeaderService = () => pageHeaderService;

const useForm = (): BigidFormStateAndHandlers & {
  ref: MutableRefObject<BigidFormStateAndHandlers>;
} => {
  const ref = useRef<BigidFormStateAndHandlers>();
  return {
    ref,
    ...ref.current,
    validateAndSubmit: (...props) => ref.current.validateAndSubmit?.(...props),
    cancel: () => ref.current.cancel?.(),
    clear: () => ref.current.clear?.(),
    setAllTouched: (isTouched: boolean) => ref.current.setAllTouched?.(isTouched),
  };
};

//@info Assertion for correct casting of slot component render props
const BigidLayoutMasterDetails = BigidLayout as BigidLayoutMasterDetailsComponent<QueryGridRow> & typeof BigidLayout;

export const SavedQueriesPage = () => {
  const entityId = useRef(null);
  const pendingSearchTerm = useRef(null);
  const [isSubmitting, setSubmitting] = useState(false);
  const ref = useRef<HTMLDivElement>();
  const { t } = useLocalTranslation();
  const { ref: formControls, validateAndSubmit, clear, setAllTouched } = useForm();
  const { error, success, warning } = useNotifications();
  const { setTitle } = usePageHeaderService();
  const { getSavedQueries, createSavedQuery, updateSavedQuery, deleteSavedQuery } = useSavedQueryService();
  const {
    gridId,
    // @info Grid props START
    rows,
    isLoading,
    apiRef,
    // @info Grid props END
    addRow,
    setSelectedRowById,
    deleteRow,
    updateRowById,
    refresh: refreshQuery,
    getSelectedRow,
    emit,
    clearSearch,
    EmitterType,
    ...rest
  } = useLayout<QueryGridRow>('BigidLayoutMasterDetails', {
    pageSize: 1000,
    fetchDataFunction: async () => {
      const data = await getSavedQueries();

      return {
        data: data.map(mapQueryResponseToGridRow) ?? NO_DATA,
        totalCount: data.length ?? NO_DATA_TOTAL_ROWS,
      } as { data: QueryGridRow[]; totalCount: number };
    },
    initialSorting: [{ field: 'tag_name', order: 'asc' }],
  });

  const selectedRow = getSelectedRow();
  const hasNoQueries = !rows?.length && !isLoading;

  const isCreatePermitted = isPermitted(TAGS_SAVED_QUERIES_PERMISSIONS.CREATE.name);
  const isEditPermitted = isPermitted(TAGS_SAVED_QUERIES_PERMISSIONS.EDIT.name);
  const isDeletePermitted = isPermitted(TAGS_SAVED_QUERIES_PERMISSIONS.DELETE.name);

  const gridConfig: BigidGridProps<QueryGridRow> = useMemo(
    () => createGridConfig(gridId, { ...rest, rows, isLoading, apiRef }, t),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [rows, isLoading],
  );

  const resetForm = () => {
    clear();
    setAllTouched(false);
  };

  const createNewGridRow = (): string => {
    const rowId = uniqueId(TEMP_ROW_PREFIX);

    const row = {
      ...EMPTY_SAVED_QUERY_ROW,
      id: rowId,
      _id: rowId,
    };

    addRow(row);

    return rowId;
  };

  const handleQuerySubmit = (shouldRefreshGrid = true) =>
    new Promise<void>(resolve => {
      formControls.current?.validateAndSubmit(
        async (values: BigidFormValues) => {
          try {
            const { isPending: isSubmit, id } = selectedRow ?? {};
            const isNameDuplicated = rows.find(({ tag_name: name }) => name === values.tag_name);
            if (isSubmit && isNameDuplicated) {
              formControls.current?.setFieldError('tag_name', t('message.duplicatedName'));
              return;
            }
            const payload = mapQueryResponseToQueryRequest(values as QueryItemResponse);

            setSubmitting(true);
            const response = await Promise.resolve(
              isSubmit ? createSavedQuery(payload) : updateSavedQuery(id, payload),
            );
            formControls.current?.setAllTouched(false);

            success(t(isSubmit ? 'message.commonPostSuccess' : 'message.commonPutSuccess'), {
              shouldCloseOnTransition: false,
            });

            const hasPendingSearch = !isNull(pendingSearchTerm.current);
            const prevEntityId = entityId.current;

            entityId.current = hasPendingSearch
              ? null
              : prevEntityId ?? (isSubmit ? (response as Awaited<ReturnType<typeof createSavedQuery>>).insertedId : id);
            setSubmitting(false);

            if (shouldRefreshGrid) {
              refreshQuery();
            } else {
              updateRowById(selectedRow.id, { ...selectedRow, ...payload });
            }
            resolve();
          } catch (err) {
            const isDuplicateKeyError = err.data?.includes('E11000 duplicate key error');

            entityId.current = null;
            pendingSearchTerm.current = null;

            setSubmitting(false);

            if (err.data) {
              error(err.data?.message ?? isDuplicateKeyError ? t('message.unique') : t('message.commonError'));
            } else error(t('message.commonError'));
          }
        },
        () => {
          entityId.current = null;
          pendingSearchTerm.current = null;

          return ref.current.scrollTo({ top: 0, behavior: 'smooth' });
        },
      );
    });

  const handleQueryCancel = () => {
    const { isPending } = selectedRow ?? {};
    const hasPendingSearch = !isNull(pendingSearchTerm.current);

    isPending ? deleteRow(selectedRow) : resetForm();
    emit(EmitterType.LAYOUT, BigidLayoutMasterDetailsGridEvents.SELECT_STATE_CHANGE, [entityId.current]);
    hasPendingSearch &&
      emit(EmitterType.LAYOUT, BigidLayoutMasterDetailsGridEvents.SEARCH_STATE_CHANGE, pendingSearchTerm.current);

    pendingSearchTerm.current = null;
    entityId.current = null;
  };

  const handleQueryDelete = async () => {
    try {
      const { id, isPending } = selectedRow;

      if (isPending) {
        resetForm();
        deleteRow(selectedRow);

        return;
      }

      setSubmitting(true);
      await deleteSavedQuery(id);

      success(t('message.commonDeleteSuccess'));
      setSubmitting(false);

      refreshQuery();
    } catch (err) {
      setSubmitting(false);
      error(err.data?.message ?? t('message.commonError'));
    }
  };

  const addGridRow = () => {
    const hasPendingRows = rows.find(({ isPending }) => isPending);

    if (hasPendingRows) {
      warning(t('message.queryExists'));
      return;
    }

    clearSearch();

    const rowId = createNewGridRow();
    setSelectedRowById([rowId]);
  };

  const handleQueryAdd = () => {
    if (formControls.current?.formTouched) {
      return configureUnsavedChangesDialog({
        onClickCancel: addGridRow,
        onClickSave: async () => {
          await handleQuerySubmit(false);
          addGridRow();
        },
      });
    }

    addGridRow();
  };

  const configureUnsavedChangesDialog = ({ onClickCancel, onClickSave }: ShowUnsavedChangesDialog) =>
    new Promise<boolean>(resolve => {
      const dialogOptions: BigidSystemDialogOptions = {
        title: t('modal.unsavedChanges.title'),
        onClose: noop,
        maxWidth: 'xs',
        buttons: [
          {
            component: SecondaryButton,
            dataAid: 'BigidLayoutMasterDetails-submitCancel',
            isClose: true,
            onClick: () => {
              onClickCancel();
              resolve(true);
            },
            text: t('buttons.no'),
          },
          {
            component: PrimaryButton,
            dataAid: 'BigidLayoutMasterDetails-submitConfirmation',
            isClose: true,
            disabled: !isEditPermitted,
            onClick: async () => {
              await onClickSave();
              resolve(true);
            },
            text: t('buttons.yes'),
          },
        ],
        content: ({ body }) => <BigidBody1 data-aid={generateDataAid('DialogContentText', [])}>{body}</BigidBody1>,
        contentProps: { body: t('modal.unsavedChanges.body') },
        borderTop: true,
      };

      openSystemDialog(dialogOptions);
    });

  const showUnsavedChangesDialog = () =>
    configureUnsavedChangesDialog({
      onClickCancel: handleQueryCancel,
      onClickSave: async () => {
        await handleQuerySubmit();
      },
    });

  const confirmDeleteDialogOptions: BigidSystemDialogOptions = {
    title: t('modal.delete.title'),
    onClose: noop,
    maxWidth: 'xs',
    buttons: [
      {
        component: SecondaryButton,
        dataAid: generateDataAid('BigidLayoutMasterDetails', ['delete', 'cancel']),
        isClose: true,
        onClick: noop,
        text: t('buttons.cancel'),
      },
      {
        component: PrimaryButton,
        dataAid: generateDataAid('BigidLayoutMasterDetails', ['delete', 'confirmation']),
        isClose: true,
        disabled: !isDeletePermitted,
        onClick: handleQueryDelete,
        text: t('buttons.delete'),
      },
    ],
    content: ({ body }) => <BigidBody1 data-aid={generateDataAid('DialogContentText', [])}>{body}</BigidBody1>,
    contentProps: { body: t('modal.delete.body', { entityName: selectedRow?.tag_name }) },
    borderTop: true,
  };

  const handleSelectionChange = ({ selectedRowIds = [] }: BigidLayoutMasterDetailsEvent<BigidGridRow>): boolean => {
    const isDirty = formControls.current?.formTouched;
    const [id] = selectedRowIds;
    const { isPending, id: prevSelectedId } = selectedRow ?? {};
    const hasSelectedRow = !!selectedRow;
    const shouldChangeSelection = !hasSelectedRow ? true : !isPending && !isDirty && !isSubmitting;

    if (id === prevSelectedId) {
      return;
    }

    if (!shouldChangeSelection) {
      entityId.current = id;
      showUnsavedChangesDialog();
    }

    return shouldChangeSelection;
  };

  const handleSearchChange = ({ searchTerm }: BigidLayoutMasterDetailsEvent<BigidGridRow>) => {
    const { isPending, id } = selectedRow ?? {};
    const isDirty = formControls.current?.formTouched;
    const shouldChangeSearchTerm = !isPending && !isDirty && !isSubmitting;

    if (!shouldChangeSearchTerm) {
      entityId.current = id;
      pendingSearchTerm.current = searchTerm;
      showUnsavedChangesDialog();
    }

    return shouldChangeSearchTerm;
  };

  useEffect(() => {
    setTitle({
      pageTitle: t('title'),
      rightSideComponentsContainer:
        isLoading || !hasNoQueries ? (
          <PrimaryButton
            disabled={!isCreatePermitted || isLoading}
            text={t('buttons.addNewSavedQuery')}
            size="large"
            dataAid="BigidLayoutMasterDetails-addQueryButton"
            onClick={handleQueryAdd}
            startIcon={<AddBoxOutlined />}
          />
        ) : null,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rows, selectedRow]);

  useEffect(() => {
    if (!isLoading) {
      apiRef.current.clearVirtualTabelCache?.();
      const hasPendingSearch = !!pendingSearchTerm.current;
      const hasInitialId = entityId.current ? rows?.some(({ id }) => id === entityId.current) : false;
      const [firstRow] = rows ?? [];

      hasPendingSearch &&
        emit(EmitterType.LAYOUT, BigidLayoutMasterDetailsGridEvents.SEARCH_STATE_CHANGE, pendingSearchTerm.current);
      setSelectedRowById(hasInitialId ? [entityId.current] : firstRow ? [firstRow.id] : []);

      entityId.current = null;
      pendingSearchTerm.current = null;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading]);

  const { updateIsRouteLeavingEnabled } = useRouteLeavingListener(showUnsavedChangesDialog);

  const ContentComponent = forwardRef<HTMLDivElement, ContentComponentProps>(({ row }, ref) => {
    const fields = row
      ? sortBy<keyof QueryItemResponse>(Object.keys(row) as (keyof QueryItemResponse)[], sortOrder)
          .filter(key => filterQueryNameIfPendingQuery(key, row.isPending))
          .filter(excludeQueryFields)
          .map(key => mapQueryGridRowKeyToFormField(key, t))
      : [];

    const formProps: BigidFormProps = useMemo(
      () => ({
        controlButtons: false,
        validateOn: BigidFormValidateOnTypes.SUBMIT,
        stateAndHandlersRef: formControls,
        initialValues: row,
        displayFormLevelError: true,
        fields,
        onChange: () => {
          setTimeout(() => {
            updateIsRouteLeavingEnabled(formControls.current?.formTouched);
          }, 0);
        },
      }),
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [row?.id, formControls],
    );

    const handleQueryAddAction = async () => {
      handleQueryAdd();
      return { shouldGridReload: false };
    };

    const actions = [
      {
        execute: handleQueryAddAction,
        icon: AddBoxOutlined,
        label: t('buttons.addNewSavedQuery'),
        show: () => true,
      },
    ];

    return (
      <Content isEmpty={hasNoQueries} ref={ref}>
        {row ? (
          <BigidForm key={row.id} {...formProps} />
        ) : hasNoQueries ? (
          <BigidLayoutEmptyState
            actions={actions}
            illustration={BigidQueriesIllustration}
            description={t('message.emtyStateDescription')}
          />
        ) : null}
      </Content>
    );
  });

  const config: BigidLayoutMasterDetailsConfig<QueryGridRow> = useMemo(
    () => createLayoutConfig(gridConfig, t),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [gridConfig],
  );

  const layoutActions: (StyledButtonType & { component: FC<StyledButtonType> })[] = [
    {
      component: TertiaryButton,
      dataAid: 'BigidLayoutMasterDetails-deleteButton',
      startIcon: <BigidDeleteIcon />,
      disabled: !isDeletePermitted,
      onClick: () => openSystemDialog(confirmDeleteDialogOptions),
      color: 'grey',
      size: 'medium',
      text: t('buttons.delete'),
    },
    {
      component: SecondaryButton,
      dataAid: 'BigidLayoutMasterDetails-submitButton',
      disabled: selectedRow?.isPending ? !isCreatePermitted : !isEditPermitted,
      onClick: () => handleQuerySubmit(),
      color: 'grey',
      size: 'medium',
      text: t('buttons.save'),
    },
  ];

  const ContentRenderComponent = ({ selectedItem }: BigidLayoutMasterDetailsRenderProps<QueryGridRow>): JSX.Element => (
    <ContentComponent ref={ref} row={selectedItem} />
  );
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const renderContent = useMemo(() => ContentRenderComponent, [formControls, ref, hasNoQueries]);

  return (
    <Wrapper>
      <BigidLayoutMasterDetails
        disabled={isSubmitting || hasNoQueries}
        onSelectionChange={handleSelectionChange}
        onSearchSubmit={handleSearchChange}
        onSearchChange={handleSearchChange}
        config={config as unknown as BigidLayoutMasterDetailsConfig<BigidGridRow>}
      >
        <BigidLayoutMasterDetails.Header
          render={({ selectedItem }) => {
            const { isPending } = selectedItem ?? {};
            return selectedItem ? (
              <SavedQueriesHeader
                title={isPending ? t('message.addNewQueryHeader') : selectedItem?.tag_name}
                buttons={layoutActions}
                showActions={!!selectedRow}
              />
            ) : null;
          }}
        />
        <BigidLayoutMasterDetails.Content render={renderContent} />
      </BigidLayoutMasterDetails>
    </Wrapper>
  );
};

const SavedQueriesComponentPicker = () => {
  const isNewPageWithoutLabeling = useMemo(() => getApplicationPreference('SHOW_LEGACY_MASTER_DETAILS_VIEWS'), []);
  return isNewPageWithoutLabeling ? <SavedQueriesPage /> : <LegacySavedQueriesPage />;
};

export const SavedQueries: FC = () => {
  return <LegacySavedQueriesPage />;
};
