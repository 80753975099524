import angular from 'angular';
import { TertiaryButton } from '@bigid-ui/components';
import React, { FC } from 'react';
import { BigidEditIcon } from '@bigid-ui/icons';
import { convertToAngular } from '../../../../common/services/convertToAngular';

export interface BigidEditClassifiersChecksumButtonProps {
  openChecksumValidationDialog: () => void;
}
export const BigidEditClassifiersChecksumValidationButton: FC<BigidEditClassifiersChecksumButtonProps> = ({
  openChecksumValidationDialog,
}) => {
  return (
    <TertiaryButton
      size="medium"
      onClick={openChecksumValidationDialog}
      width="fill"
      dataAid="edit-classifier-checksum-button"
      text="Edit Settings"
      startIcon={<BigidEditIcon />}
    />
  );
};

angular
  .module('app')
  .component(
    'editClassifiersChecksumValidationButton',
    convertToAngular<BigidEditClassifiersChecksumButtonProps>(BigidEditClassifiersChecksumValidationButton, [
      'openChecksumValidationDialog',
    ]),
  );
