import { BigidSystemDialogOptions, openSystemDialog } from './systemDialogService';
import { SecondaryButton, PrimaryButton } from '@bigid-ui/components';
import { ConfirmationDialogContent, GenericConfirmationDialogProps } from '../components/GenericConfirmationDialog';

export interface GenericConfirmationDialogOptions {
  text?: string;
  title?: string;
  actionButton?: string;
  closeButton?: string;
}

export const showGenericConfirmationDialog = ({
  title,
  text,
  actionButton,
  closeButton,
}: GenericConfirmationDialogOptions): Promise<boolean> => {
  return new Promise(resolve => {
    const options: BigidSystemDialogOptions<GenericConfirmationDialogProps> = {
      title: title,
      maxWidth: 'xs',
      buttons: [
        {
          text: closeButton,
          component: SecondaryButton,
          onClick: () => {
            resolve(false);
          },
          isClose: true,
        },
        {
          text: actionButton,
          component: PrimaryButton,
          onClick: () => {
            resolve(true);
          },
          isClose: true,
        },
      ],
      onClose: () => {
        resolve(false);
      },
      content: ConfirmationDialogContent,
      contentProps: { text },
      borderTop: true,
    };
    openSystemDialog<GenericConfirmationDialogProps>(options);
  });
};
