import React, { FC } from 'react';
import { BigidHeading6, BigidBody1, BigidLink } from '@bigid-ui/components';
import { useLocalTranslation } from '../translations';

interface DataSourceNotificationSingleActionProps {
  dsName: string;
  translationKey: string;
  onAction: () => void;
}

export const DataSourceNotificationSingleAction: FC<DataSourceNotificationSingleActionProps> = ({
  dsName,
  translationKey,
  onAction,
}) => {
  const { t } = useLocalTranslation(`notifications`);
  return (
    <>
      <BigidHeading6>{t(`${translationKey}.title`, { dsName })}</BigidHeading6>
      <BigidBody1>{t(`${translationKey}.message`)}</BigidBody1>
      <BigidLink underline="none" onClick={onAction} text={t(`${translationKey}.action`)}></BigidLink>
    </>
  );
};
