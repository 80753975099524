import { module } from 'angular';
import './actorDetails.component.scss';
import template from './actorDetails.component.html';

import { isPermitted } from '../../../react/services/userPermissionsService';
import { BUSINESS_FLOWS_PERMISSIONS } from '@bigid/permissions';
const app = module('app');

app.component('actor', {
  template,
  controller: [
    function () {
      this.tab = 'settings';

      this.$onInit = () => {
        this.vm.selectedEntity.showGroupPicker = false;
        this.hasManagePermission = isPermitted(BUSINESS_FLOWS_PERMISSIONS.MANAGE.name);
      };
    },
  ],
  bindings: {
    vm: '<',
  },
});
