import { startCase } from 'lodash';
import React from 'react';
import { SubscanStage } from '../ScanInsightTypes';
import styled from '@emotion/styled';
import { Typography } from '@mui/material';
interface BasicInfoProps {
  type: string;
}

export const BasicInfo = ({ type }: BasicInfoProps) => {
  return <Typography>{type === SubscanStage.DATA_SOURCE_SCAN ? 'Data Source Scan' : startCase(type)}</Typography>;
};
