import React from 'react';
import { getApplicationPreference } from '../../services/appPreferencesService';

import { DataMinimizationDataSources as v1 } from './DataMinimizationDS/DataMinimizationDataSources';
import { Layout as v2 } from './DataMinimizationDSV2/Layout';

export const DataMinimizationDataSources = () => {
  const showNewUI = getApplicationPreference('DATA_DELETION_DATA_SOURCES_PAGE_V2');
  const DataMinimizationDataSources = showNewUI ? v2 : v1;
  return <DataMinimizationDataSources />;
};

export { DataMinimization } from './DataMinimization';
export { DataDeletionGlobalSettings } from './DataDeletionGlobalSettings/DataDeletionGlobalSettings';
export { DataMinimizationObjects } from './DataMinimizationObjects/DataMinimizationObjects';
export { DataMinimizationRequests } from './DataMinimizationRequests/DataMinimizationRequests';
