import React, { FC, HTMLAttributes, ComponentType, useState, useEffect, useCallback } from 'react';
import { SvgIconProps } from '@mui/material/SvgIcon';
import makeStyles from '@mui/styles/makeStyles';
import {
  BigidButtonIcon,
  BigidColors,
  BigidIcon,
  BigidIconSize,
  BigidIconSourceType,
  BigidLoader,
} from '@bigid-ui/components';
import BigIdInfoIcon from '../../../../assets/icons/BigidHelpCircle.svg';
import { DashboardShortcutsTypeEnum } from '../shortcutsUtils';
import classnames from 'classnames';
import { BigidInfoIcon } from '@bigid-ui/icons';

const useStyles = makeStyles({
  shortcutsBlock: {
    display: 'flex',
    boxSizing: 'border-box',
    margin: '8px 8px 8px 0',
    flex: '1 0 auto',
    flexDirection: 'column',
    marginBottom: '16px',
    position: 'relative',
  },
  startIcon: ({ type }: Partial<DashboardShortcutsBlockProps>) => ({
    marginRight: '12px',
    width: '48px',
    height: '48px',
    borderRadius: '50%',
    backgroundColor: type === 'app' ? 'rgba(255, 94, 67, 0.1)' : 'rgba(159, 84, 186, 0.1)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  }),
  shortcutsBlockHeader: ({ onClick }: Partial<DashboardShortcutsBlockProps>) => ({
    fontSize: '16px',
    display: 'flex',
    alignItems: 'center',
    cursor: onClick ? 'pointer' : 'auto',
    fontWeight: 600,
    color: BigidColors.gray[700],
    '&:focus': {
      color: '#9F54BA',

      '& $startIcon': {
        border: 'solid 2px #9F54BA',
      },
    },
  }),
  shortcutsBlocItem: {
    fontSize: '14px',
    margin: '0 0 12px 60px',
    cursor: 'pointer',
    color: BigidColors.gray[700],
    '&:focus': {
      textDecoration: 'underline',
      color: '#B47CC9',
    },
  },
  shortcutsBlocItemHidden: {
    display: 'none',
  },
  info: {
    marginLeft: '8px',
    marginBottom: '2px',
  },
  arrow: {
    marginLeft: '6px',
    width: '6px',
    height: '6px',
    border: `1px solid ${BigidColors.gray[700]}`,
    borderLeft: 0,
    borderTop: 0,
    display: 'inline-block',
    transform: 'rotate(-45deg)',
    marginBottom: '2px',
  },
});

export interface DashboardShortcutsBlockInterface {
  title: string;
  onClick?: () => void;
  hidden?: boolean;
}

export interface DashboardShortcutsBlockProps extends DashboardShortcutsBlockInterface {
  type?: DashboardShortcutsTypeEnum;
  infoLink?: string;
  children?: DashboardShortcutsBlockInterface[];
  icon?: ComponentType<HTMLAttributes<SVGElement>> | ComponentType<SvgIconProps>;
}

export const DashboardShortcutsBlock: FC<DashboardShortcutsBlockProps> = React.memo(
  ({ title, children, infoLink, type, onClick, icon }) => {
    const { shortcutsBlock, shortcutsBlockHeader, startIcon, info, shortcutsBlocItem, arrow, shortcutsBlocItemHidden } =
      useStyles({ type, onClick });
    const [isOpened, setIsOpened] = useState(false);
    useEffect(() => {
      const timeOut = setTimeout(() => {
        setIsOpened(false);
      }, 1000);
      return () => {
        clearTimeout(timeOut);
      };
    }, [isOpened]);

    const onClickHandler = useCallback(
      (onClick: () => void) => {
        setIsOpened(currentOpenedState => {
          !currentOpenedState && onClick();
          return true;
        });
      },
      [setIsOpened],
    );

    return (
      <div className={shortcutsBlock} data-aid={`DashboardShortcutsBlockWrapper-${title}`}>
        {isOpened && <BigidLoader />}
        <div
          aria-label={title}
          tabIndex={0}
          className={shortcutsBlockHeader}
          data-aid={`DashboardShortcutsBlock-${title}`}
          role={onClick ? 'button' : ''}
          onClick={onClick ? () => onClickHandler(onClick) : null}
        >
          <div className={startIcon}>
            <BigidIcon
              icon={icon}
              size={BigidIconSize.REGULAR_PLUS}
              color={type === DashboardShortcutsTypeEnum.app ? '#ff5e43' : BigidColors.purple[500]}
              type={BigidIconSourceType.CUSTOM}
            />
          </div>
          {title}
          {infoLink ? (
            <BigidButtonIcon
              icon={BigidInfoIcon}
              className={info}
              size="small"
              onClick={event => {
                event.stopPropagation();
                window.open(infoLink, '_blank');
              }}
            />
          ) : null}
        </div>

        {children &&
          children.map(({ title, onClick, hidden }: DashboardShortcutsBlockInterface) => (
            <div
              tabIndex={0}
              data-aid={`DashboardShortcutsBlock-${title}`}
              onClick={onClick ? () => onClickHandler(onClick) : null}
              key={title}
              className={classnames(shortcutsBlocItem, hidden && shortcutsBlocItemHidden)}
              aria-label={title}
            >
              <span>{title}</span>
              <div className={arrow} />
            </div>
          ))}
      </div>
    );
  },
);
