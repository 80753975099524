import { CredentialProviderRow, ProviderType } from './types';
import { httpService } from '../../services/httpService';

const credentialProvidersUrl = 'credentials-providers';

export async function getProviders(query: string): Promise<{ results: CredentialProviderRow[]; total: number }> {
  const url = `${credentialProvidersUrl}${query}`;
  const {
    data: { results, total },
  } = await httpService.fetch(url);
  return { results, total };
}

export function deleteProvider(id: string) {
  const url = `${credentialProvidersUrl}/${id}`;
  return httpService.delete(url);
}

export function createProviderByType(data: CredentialProviderRow) {
  if (data.type === ProviderType.CYBERARK) {
    const { cert, ...rest } = data;
    return httpService.multipart('post', credentialProvidersUrl, { data: { ...rest }, files: { cert } });
  }
  return httpService.post<CredentialProviderRow>(credentialProvidersUrl, { data }).then(({ data }) => data);
}

export function updateProviderByType(data: CredentialProviderRow, id: string) {
  const url = `${credentialProvidersUrl}/${id}`;
  if (data.type === ProviderType.CYBERARK) {
    const { cert, type, ...rest } = data;
    return httpService.multipart('put', url, {
      data: { ...rest },
      ...(cert && Object.keys(cert).length ? { files: { cert } } : {}),
    });
  }
  return httpService.put<CredentialProviderRow>(url, { data }).then(({ data }) => data);
}

export async function testProviderByType(data: CredentialProviderRow) {
  const url = `${credentialProvidersUrl}/connection/test`;
  if (data.type === ProviderType.CYBERARK) {
    const { cert, ...rest } = data;
    return httpService.multipart('post', url, { data: { ...rest }, files: { cert } });
  }
  return httpService.post(url, { data });
}
