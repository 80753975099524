import React, { FC, PropsWithChildren, ReactNode } from 'react';
import { BigidColors } from '@bigid-ui/components';
import { Collapse } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { BigidChevronCircleDownIcon, BigidChevronCircleRightIcon } from '@bigid-ui/icons';

interface CollapsableProps extends PropsWithChildren {
  isOpen: boolean;
  leftHeader: ReactNode;
  rightHeader?: ReactNode;
  dataAid?: string;
  onCollapseClick: () => void;
}

const useStyles = makeStyles({
  root: { border: `1px solid ${BigidColors.gray[100]}`, borderRadius: 4, marginBottom: 16 },
  header: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    borderBottom: `1px solid ${BigidColors.gray[100]}`,
  },
  leftHeader: { display: 'flex', alignItems: 'center' },
  collapseIcon: { display: 'flex', cursor: 'pointer', padding: '18px 12px 18px 11px' },
  wrapperInner: { display: 'flex', flexDirection: 'column' },
});

export const BigidCollapsable: FC<CollapsableProps> = ({
  leftHeader,
  rightHeader,
  isOpen,
  children,
  dataAid,
  onCollapseClick,
}) => {
  const classes = useStyles({});

  return (
    <div className={classes.root} data-aid={`collapsable${dataAid ? `-${dataAid}` : ''}`}>
      <div className={classes.header}>
        <div className={classes.leftHeader}>
          <div
            data-aid={`collapsible-icon${dataAid ? `-${dataAid}` : ''}`}
            className={classes.collapseIcon}
            onClick={onCollapseClick}
          >
            {isOpen ? <BigidChevronCircleDownIcon /> : <BigidChevronCircleRightIcon />}
          </div>
          {leftHeader}
        </div>

        {rightHeader}
      </div>
      <Collapse in={isOpen} timeout="auto" classes={{ wrapperInner: classes.wrapperInner }}>
        {isOpen && children}
      </Collapse>
    </div>
  );
};
