import React, { FunctionComponent, useCallback, useEffect, useMemo } from 'react';
import { BigidPaper } from '@bigid-ui/components';
import { UserPermission } from '../UsersPermissions/UsersPermissionsService';
import { notificationService } from '../../../services/notificationService';
import { getFilePermissions } from './OpenAccessFilesService';
import {
  BigidGrid,
  BigidGridColumn,
  BigidGridColumnTypes,
  BigidGridProps,
  ChipsFormatterProps,
  useFetch,
  UseFetchActions,
} from '@bigid-ui/grid';
import { queryService } from '../../../services/queryService';
import { capitalize } from 'lodash';

export interface FilePermissionsProps {
  fullyQualifiedName: string;
  id: string;
  grantType: GrantType;
}

export enum GrantType {
  EFFECTIVE = 'EFFECTIVE',
  DIRECT = 'DIRECT',
}

export type FilePermission = Pick<UserPermission, 'id' | '_id' | 'name' | 'email' | 'access' | 'subType'> & {
  grantType: GrantType;
  type: PermissionType;
};

export enum PermissionType {
  Link = 'link',
  Group = 'group',
  User = 'user',
}

export const FilePermissions: FunctionComponent<FilePermissionsProps> = ({ fullyQualifiedName, grantType }) => {
  const getAccessChips = (access: string[]): ChipsFormatterProps => {
    return {
      chips: {
        value: access.map(accessPermission => ({
          label: accessPermission,
        })),
        isDisabled: true,
      },
    };
  };

  const columns = useMemo<BigidGridColumn<FilePermission>[]>(
    () => [
      {
        name: 'type',
        title: 'Entity Type',
        getCellValue: ({ type }) => capitalize(type),
        type: BigidGridColumnTypes.TEXT,
      },
      {
        name: 'subType',
        title: 'Entity Subtype',
        getCellValue: ({ subType }) => capitalize(subType),
        type: BigidGridColumnTypes.TEXT,
      },
      {
        name: 'name',
        title: 'Name',
        getCellValue: ({ name }) => capitalize(name),
        type: BigidGridColumnTypes.TEXT,
      },
      {
        name: 'email',
        title: 'Email / URL / ID',
        getCellValue: ({ email }) => email,
        type: BigidGridColumnTypes.TEXT,
      },

      {
        name: 'access',
        title: 'Access',
        getCellValue: ({ access }) => getAccessChips(access),
        type: BigidGridColumnTypes.CHIPS,
      },
    ],
    [],
  );

  const { dispatch: dispatchUseFetch, ...useFetchState } = useFetch({
    fetchDataFunction: async queryComponents => {
      try {
        const gridConfigQuery = queryService.getGridConfigQuery({ ...queryComponents, limit: 2000 });
        let { permissions, totalCount } = await getFilePermissions(fullyQualifiedName, gridConfigQuery);
        if (grantType) {
          permissions = permissions.filter(permission => permission.grantType === grantType);
          totalCount = permissions.length;
        }
        return {
          data: permissions,
          totalCount,
        };
      } catch (err) {
        notificationService.error(`Failed to get permissions.`);
        console.error(err);
      }
    },
  });

  const refreshGrid = useCallback(
    () => dispatchUseFetch({ type: UseFetchActions.FORCE_RELOAD, payload: {} }),
    [dispatchUseFetch],
  );

  useEffect(() => {
    refreshGrid();
  }, [refreshGrid, fullyQualifiedName]);

  const gridConfig: BigidGridProps<FilePermission> = {
    columns,
    showFilteringControls: false,
    showSortingControls: false,
    rows: useFetchState.rows as FilePermission[],
    skip: useFetchState.skip,
    onPagingChanged: useFetchState.onPagingChanged,
    onSortingChanged: useFetchState.onSortingChanged,
    totalRowsCount: useFetchState.totalRowsCount,
    onFiltersChange: useFetchState.onFiltersChanged,
    apiRef: useFetchState.apiRef,
    loading: useFetchState.isLoading,
  };

  return (
    <BigidPaper>
      <BigidGrid {...gridConfig} />
    </BigidPaper>
  );
};
