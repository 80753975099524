import { BigidBody1, BigidDialog } from '@bigid-ui/components';
import { BigidInfoIcon } from '@bigid-ui/icons';
import { styled } from '@mui/material';
import React from 'react';
import { RiskMatrixMetadata } from '../RiskMatrixDefaults';
import { RiskMatrix } from './RiskMatrix';

const ImageContainer = styled('div')({
  display: 'flex',
});

export interface RiskLevelInfoProps {
  isOpen: boolean;
  riskMatrix: RiskMatrixMetadata;
  onClose: () => void;
}

export const RiskLevelInfo: React.FC<RiskLevelInfoProps> = ({ isOpen, riskMatrix, onClose }) => {
  return (
    <BigidDialog isOpen={isOpen} onClose={onClose} title="Risk Level" borderTop icon={BigidInfoIcon} maxWidth={'md'}>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <BigidBody1>
          {`The risk level measures the overall severity of the risk’s occurrence, determined by the provided ${riskMatrix.probabilityLabel} and ${riskMatrix.impactLabel}.`}
        </BigidBody1>
        <ImageContainer>
          <RiskMatrix {...riskMatrix} />
        </ImageContainer>
      </div>
    </BigidDialog>
  );
};
