import { openSystemDialog } from '../../../services/systemDialogService';
import { useState, useEffect, FC } from 'react';
import { objectToQueryString, PrimaryButton, SecondaryButton } from '@bigid-ui/components';
import angular from 'angular';
import { convertToAngular } from '../../../../common/services/convertToAngular';
import { betaToolsService } from '../../../services/angularServices';
import { ExportSupportPackageContent } from './ExportSupportPackageContent';
import { ExportSupportPackageDialogProps, supportPackageDialogOptions } from './types';
import { useLocalTranslation } from './translations';

export const ExportSupportPackageDialog: FC<ExportSupportPackageDialogProps> = ({ isOpen, onClose }) => {
  const [dialogOptions, setDialogOptions] = useState<supportPackageDialogOptions>({
    isCheckedSensitiveLogs: false,
    isCheckedScanRelatedCollections: false,
    isCheckedScanResultDetails: false,
    isCheckedConfigurationInfo: false,
  });

  const handleAction = () => {
    const query = objectToQueryString(
      Object.entries(dialogOptions).reduce((result: { [index: string]: string }, [key, value]) => {
        result[key] = value.toString();
        return result;
      }, {}),
    );
    betaToolsService.downloadSupportPackage(query);
  };

  const { t } = useLocalTranslation('supportPackageDialog');
  const exportSupportPackageDialog: () => void = () => {
    openSystemDialog({
      title: t('exportSupportPackageTitle'),
      onClose: () => {
        onClose();
      },
      content: ExportSupportPackageContent,
      contentProps: {
        dialogOptions,
        setDialogOptions,
      },
      buttons: [
        {
          text: t('cancel'),
          component: SecondaryButton,
          onClick: () => null,
          isClose: true,
        },
        {
          text: t('download'),
          component: PrimaryButton,
          onClick: () => handleAction(),
          isClose: true,
        },
      ],
    });
  };

  useEffect(() => {
    if (isOpen) {
      exportSupportPackageDialog();
    }
  }, [isOpen, exportSupportPackageDialog]);
  return null;
};

angular
  .module('app')
  .component(
    'exportSupportPackageDialog',
    convertToAngular<ExportSupportPackageDialogProps>(ExportSupportPackageDialog, ['isOpen', 'onClose']),
  );
