import React, { Dispatch, SetStateAction, useCallback, useEffect } from 'react';
import { useOnCloseHandler } from '../../DataSources/DataSourceConfiguration/hooks/useOnCloseHandler';
import { $transitions } from '../../../services/angularServices';
import { $q } from 'ngimport';
import { isEqual } from 'lodash';
import { AutoDiscoveryWizardContextState } from '../AutoDiscoveryWizard/autoDiscoveryWizardContext';
import { TFunction } from 'i18next';
import { FormValue } from '@bigid-ui/components';
import { useLocalTranslation } from '../translations';

export const useChangesMonitoring = (
  saveHandler: (
    setDiscoveryConfigData: React.Dispatch<React.SetStateAction<AutoDiscoveryWizardContextState>>,
    discoveryConfigDataRef: React.MutableRefObject<AutoDiscoveryWizardContextState>,
    t?: TFunction,
  ) => Promise<boolean>,
  discoveryConfigDataRef: React.MutableRefObject<AutoDiscoveryWizardContextState>,
  setDiscoveryConfigData: Dispatch<SetStateAction<AutoDiscoveryWizardContextState>>,
  isDisabled = false,
) => {
  const { t } = useLocalTranslation('AutoDiscovery');
  const onCloseConfig = useOnCloseHandler(discoveryConfigDataRef.current.type, true);

  const checkIfSaveNeeded = useCallback(() => {
    if (
      isDisabled ||
      (!discoveryConfigDataRef?.current?.formTouched && !discoveryConfigDataRef?.current?.errorMessageKey)
    ) {
      return false;
    }

    return Object.entries(discoveryConfigDataRef.current?.initialValues).reduce((isNeedSave, [key, value]) => {
      return isNeedSave || checkIsFieldDirty(value, discoveryConfigDataRef.current.formData[key]);
    }, false);
  }, [isDisabled]);

  useEffect(() => {
    const handler = (event: BeforeUnloadEvent) => {
      if (checkIfSaveNeeded()) {
        event.preventDefault();
        event.returnValue = '';
        return 'Do you want to save changes on this Onboarding before leaving?';
      }
      return '';
    };

    !isDisabled && window.addEventListener('beforeunload', handler, true);
    return () => {
      !isDisabled && window.removeEventListener('beforeunload', handler, true);
    };
  }, [isDisabled]);

  useEffect(() => {
    const deregisterLeaveFormInterceptor = $transitions.onBefore({}, transition => {
      if (isDisabled) {
        return null;
      }
      return new $q(resolve => {
        let isSaving = false;
        const isNeedSave = checkIfSaveNeeded();

        if (
          isNeedSave &&
          transition.params()?.type !== discoveryConfigDataRef.current.type &&
          transition.params()?.abortDisabled !== 'true'
        ) {
          onCloseConfig({
            isError: false,
            onSave: () => {
              isSaving = true;
              saveHandler(setDiscoveryConfigData, discoveryConfigDataRef, t)
                .then((result: boolean) => {
                  resolve(result);
                })
                .catch(() => {
                  resolve(false);
                });
            },
            onNotSave: () => {
              resolve();
            },
            onClose: () => {
              !isSaving && resolve(false);
            },
          });
        } else {
          resolve();
        }
      }) as Promise<boolean>;
    });

    return () => {
      deregisterLeaveFormInterceptor();
    };
  }, [isDisabled]);

  return {
    checkIfSaveNeeded,
  };
};

function checkIsFieldDirty(initialValue: FormValue, currentValue: FormValue) {
  if (typeof initialValue === 'object' || typeof initialValue === 'string') {
    return !isEqual(initialValue, currentValue);
  }

  return Boolean(currentValue) !== Boolean(initialValue);
}
