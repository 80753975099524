import { BigIdPermission, PermissionModules, replaceSpacesWithUnderscore } from '@bigid/permissions';
import { DOC_APP_NAME } from './index';

export const DOC_APP_PERMISSIONS = {
  READ: new BigIdPermission({
    label: 'Create',
    category: replaceSpacesWithUnderscore(DOC_APP_NAME),
    action: 'read_custom_apps',
    module: PermissionModules.APPLICATIONS,
  }),
  READ_CUSTOM_APP: new BigIdPermission({
    label: 'Admin read',
    action: 'read_custom_apps',
    module: PermissionModules.APPLICATIONS,
  }),
  MANAGE_CUSTOM_APP: new BigIdPermission({
    label: 'Manage custom app',
    action: 'manage_custom_apps',
    module: PermissionModules.APPLICATIONS,
  }),
};
