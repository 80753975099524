import React, { FC, ChangeEvent, Fragment, Dispatch, SetStateAction } from 'react';
import classnames from 'classnames';
import {
  BigidTextField,
  BigidLoader,
  BigidLink,
  BigidColors,
  BigidRecurringEventPicker,
  EventPickerIdentifierOptions,
} from '@bigid-ui/components';
import { $state } from '../../../services/angularServices';
import makeStyles from '@mui/styles/makeStyles';
import { GENERAL_SETTINGS_PERMISSIONS } from '@bigid/permissions';
import { isPermitted } from '../../../services/userPermissionsService';

interface SubscriptionDialogPropsContentProps {
  errorMessage: string;
  emailDefaultValue: string;
  onEmailChange: (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => void;
  isEmailConfigured: boolean;
  scheduleInitialValue?: string;
  onScheduleChange: Dispatch<SetStateAction<string>>;
  setIsScheduleInputValid: Dispatch<SetStateAction<boolean>>;
}

const useStyles = makeStyles({
  loader: { height: 100 },
  text: { color: BigidColors.gray[700], fontSize: '0.875rem' },
  description: { marginBottom: 20 },
  timePickerWrapper: { marginBottom: 16 },
  label: { color: BigidColors.gray[700], fontSize: '0.875rem', marginBottom: 4 },
  warning: { color: BigidColors.red[700], fontSize: '1.1rem' },
});

export const SubscriptionDialogContent: FC<SubscriptionDialogPropsContentProps> = ({
  errorMessage,
  onEmailChange,
  emailDefaultValue,
  isEmailConfigured,
  scheduleInitialValue,
  onScheduleChange,
  setIsScheduleInputValid,
}) => {
  const classes = useStyles({});

  if (isEmailConfigured === null) {
    return (
      <div className={classes.loader}>
        <BigidLoader />
      </div>
    );
  }

  const onChangeScheduler = (cronExpression: string, isValid: boolean): void => {
    onScheduleChange(cronExpression);
    setIsScheduleInputValid(isValid);
  };

  const emailConfigurationEnabled = isPermitted(GENERAL_SETTINGS_PERMISSIONS.MANAGE_EMAIL_SETUP.name);

  return (
    <Fragment>
      <div className={classnames(classes.text, classes.description)}>
        {'Subscribe to a report summarizing scan activity & findings'}
      </div>
      {isEmailConfigured ? (
        <Fragment>
          <div className={classes.timePickerWrapper}>
            <div className={classes.label}>{'Frequency'}</div>
            <BigidRecurringEventPicker
              initialValue={scheduleInitialValue}
              onChange={onChangeScheduler}
              excludedIdentifiers={[EventPickerIdentifierOptions.MINUTELY, EventPickerIdentifierOptions.HOURLY]}
            />
          </div>
          <div className={classes.label}>{'Email'}</div>
          <BigidTextField
            autoFocus
            placeholder="Enter your email"
            errorMessage={errorMessage}
            onChange={onEmailChange}
            defaultValue={emailDefaultValue}
          />
        </Fragment>
      ) : (
        <div>
          <div className={classnames(classes.text, classes.warning)}>
            {'You need to set up an email service in order to subscribe'}
          </div>
          <div className={classes.text}>
            {'In order to set up an email service, '}
            {emailConfigurationEnabled ? (
              <BigidLink
                text={'click here'}
                onClick={() => $state.go('generalSettings', { currentTab: 'emailSetup' })}
              />
            ) : (
              'please contact your administrator'
            )}
          </div>
        </div>
      )}
    </Fragment>
  );
};
