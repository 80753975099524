import React, { FC, useCallback, useEffect, useState, ComponentType, useMemo, memo } from 'react';
import { BigidSkeletonGenerator } from '@bigid-ui/components';
import { generateDataAid } from '@bigid-ui/utils';
import { IconComponentProps, BigidFilterIcon } from '@bigid-ui/icons';
import { UseCatalogDiscoveryResponse } from '../../useCatalogDiscovery';
import { getAggregationIcon, getSummaryWidgetItem } from '../../utils/widgets';
import { SummaryWidgetWrapper } from '../../utils/SummaryWidgetWrapper';
import { getAggregatedData, GetAggregatedDataPayload, GetAggregatedDataResponse } from '../../catalogDiscoveryService';
import { useLocalTranslation } from './translations';
import { AggregationItemBase, AggregationPossesionIdentifier, AggregationType } from '../../catalogDiscoveryTypes';
import { SummaryWidget, SummaryWidgetAction } from '../../widgets/SummaryWidget/SummaryWidget';
import { useFetchDataCancelable } from '../../config/useFetchDataCancelable';
import { summaryWidgetSkeletonConfig } from '../../config/skeleton';
import { DuplicateFilesSummaryTooltip } from './DuplicateFilesSummaryTooltip';
import { formatNumberCompact } from '../../../../utilities/numericDataConverter';
import { CatalogDiscoveryWidget } from '../../config/widgets';

export interface DuplicateFilesSummaryProps
  extends Pick<UseCatalogDiscoveryResponse, 'query' | 'onDataFetchStatusChange' | 'onWidgetFilterChange'> {
  dataAid?: string;
  isPageInitialised: boolean;
}

export const DuplicateFilesSummary: FC<DuplicateFilesSummaryProps> = memo(
  ({ dataAid = 'DuplicateFilesSummary', query, onDataFetchStatusChange, onWidgetFilterChange, isPageInitialised }) => {
    const { fetchDuplicateFilesCancelable } = useFetchDataCancelable();
    const { t } = useLocalTranslation();
    const icon: ComponentType<IconComponentProps> = useMemo(
      () => getAggregationIcon(AggregationType.HAS_DUPLICATES),
      [],
    );
    const [widgetData, setWidgetData] = useState<AggregationItemBase>();
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const fetchAggregationData = useCallback(async () => {
      try {
        const payload: GetAggregatedDataPayload = {
          filter: query,
          aggregations: [
            {
              aggName: AggregationType.HAS_DUPLICATES,
              isTotalRequired: true,
            },
          ],
        };

        setIsLoading(true);
        onDataFetchStatusChange(CatalogDiscoveryWidget.DUPLICATE_FILES_SUMMARY, true);

        const { aggregations } = await fetchDuplicateFilesCancelable(
          getAggregatedData(payload) as Promise<GetAggregatedDataResponse>,
        );

        const value = getSummaryWidgetItem(aggregations?.[0], AggregationPossesionIdentifier.WITH);

        setWidgetData(value);
      } catch ({ message, isCanceled }) {
        if (!isCanceled) {
          console.error(`An error has occurred: ${message}`);
        }
      } finally {
        setIsLoading(false);
        onDataFetchStatusChange(CatalogDiscoveryWidget.DUPLICATE_FILES_SUMMARY, false);
      }
    }, [fetchDuplicateFilesCancelable, onDataFetchStatusChange, query]);

    useEffect(() => {
      if (isPageInitialised) {
        fetchAggregationData();
      }
    }, [fetchAggregationData, isPageInitialised]);

    const { secondaryAction } = useMemo(() => {
      const secondaryAction: SummaryWidgetAction = {
        text: t('secondaryActionText'),
        icon: BigidFilterIcon,
        type: 'secondary',
        onClick: () => {
          widgetData?.docCount > 0 && onWidgetFilterChange(AggregationType.HAS_DUPLICATES, [widgetData]);
        },
        isDisabled: !widgetData,
      };

      return { secondaryAction };
    }, [onWidgetFilterChange, t, widgetData]);

    return (
      <SummaryWidgetWrapper dataAid={dataAid}>
        {isPageInitialised ? (
          <SummaryWidget
            dataAid={dataAid}
            label={t('title')}
            value={formatNumberCompact(widgetData?.docCount, 1)}
            icon={icon}
            isBusy={isLoading}
            rightAction={secondaryAction}
            tooltipText={<DuplicateFilesSummaryTooltip />}
          />
        ) : (
          <BigidSkeletonGenerator dataAid={generateDataAid(dataAid, ['skeleton'])} {...summaryWidgetSkeletonConfig} />
        )}
      </SummaryWidgetWrapper>
    );
  },
  (prevProps, newProps) =>
    prevProps.query === newProps.query && prevProps.isPageInitialised === newProps.isPageInitialised,
);
