export enum CategoryAssignModes {
  NEW = 'new',
  EXISTING = 'existing',
}

export interface CreateCategory {
  description?: string;
  color: string;
  name: string;
}

export enum DetailsActionsTypes {
  SET_CATEGORY_DETAILS_FIELD = 'setCategoryDetailsField',
  SET_CATEGORY_DETAILS = 'setCategoryDetails',
  RESET_CATEGORY_DETAILS = 'resetCategoryDetails',
}

export interface SetCategoryDetailsFieldAction {
  type: DetailsActionsTypes.SET_CATEGORY_DETAILS_FIELD;
  fieldName: keyof CreateCategory;
  value: string;
}

export interface SetCategoryDetailsAction {
  type: DetailsActionsTypes.SET_CATEGORY_DETAILS;
  payload: CreateCategory;
}

export interface ResetCategoryDetailsAction {
  type: DetailsActionsTypes.RESET_CATEGORY_DETAILS;
}

export type CategoryDetailsActions =
  | SetCategoryDetailsFieldAction
  | SetCategoryDetailsAction
  | ResetCategoryDetailsAction;
