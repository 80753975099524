import styled from '@emotion/styled';

export const SELECTOR = '.react-chatbot-kit-chat-btn-send';

export const ButtonWrapper = styled('div')({
  position: 'absolute',
  bottom: '0px',
});
export const BigChatContainer = styled('div')(({ theme }) => ({
  display: 'inline-flex',
  flexDirection: 'column',
  alignItems: 'flex-end',
  gap: '16px',
  height: `calc(100% - 276px)`,
  maxHeight: '724px',
  position: 'fixed',
  bottom: '32px',
  right: '40px',
  zIndex: '999',
  '& .react-chatbot-kit-chat-inner-container': {
    height: '100%',
  },
  '& .react-chatbot-kit-chat-input': {
    borderTop: 'none',
    display: 'flex',
    alignItems: 'center',
    gap: '16px',
    alignSelf: 'stretch',
    padding: '16px',
  },
  '& .react-chatbot-kit-chat-input-container': {
    borderTop: '1px solid #d8d8d8',
    padding: '0px 16px',
    height: '66px',
  },
  '& .react-chatbot-kit-chat-container': {
    height: `calc(100% - 276px)`,
    maxHeight: '660px',
    width: '448px',
    border: theme.vars.tokens.bigid.borderDefault,
    position: 'fixed',
    right: '40px',
    zIndex: '999',
    bottom: '96px',
    /* shadow-20 */
    boxShadow:
      '0px 0.33935px 1.79553px 0px rgba(0, 0, 0, 0.04), 0px 2.58316px 6.0308px 0px rgba(0, 0, 0, 0.06), 0px 9px 27px 0px rgba(0, 0, 0, 0.10);',
  },
  '& .react-chatbot-kit-chat-message-container': {
    height: `calc(100% - 132px)`,
  },
  '& .react-chatbot-kit-chat-bot-message': {
    color: theme.vars.palette.bigid.gray600,
    wordBreak: 'break-word',
    width: 'fit-content',
    marginLeft: '0',
    display: 'flex',
    padding: '16px',
    alignItems: 'center',
    gap: '10px',
    flex: '1 0 0',
    borderRadius: '4px',
  },
  '& .react-chatbot-kit-chat-bot-message-container': {
    display: 'flex',
    alignItems: 'flex-start',
    gap: '16px',
    alignSelf: 'stretch',
  },
  '& .react-chatbot-kit-user-chat-message-container': {
    justifyContent: 'flex-end',
    display: 'flex',
    alignItems: 'flex-start',
    gap: '16px',
    alignSelf: 'stretch',
  },
  '& .react-chatbot-kit-user-chat-message': {
    color: theme.vars.palette.bigid.gray600,
    backgroundColor: theme.vars.palette.bigid.primary150,
  },
  '& .react-chatbot-kit-user-chat-message-arrow': {
    borderLeftColor: theme.vars.palette.bigid.primary150,
  },
  '& .react-chatbot-kit-chat-btn-send': {
    width: '32px',
    height: '32px',
    margin: '16px 0px 16px 0px',
    borderRadius: '4px',
  },
  '& .react-chatbot-kit-chat-btn-send-icon': {
    margin: '6px 2px 0px 0px',
  },
  '& .react-chatbot-kit-chat-btn-send:disabled': {
    backgroundColor: `${theme.vars.palette.bigid.gray200} !important`,
  },
}));
