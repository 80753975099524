import { useCallback, useState } from 'react';
import { customAppService } from '../../../services/customAppService';
import { Preset } from '../../CustomApp/views/CustomAppActions/CustomAppActions';
import { AutoDiscoveryConfigModel } from '../AutoDiscoveryGrid/AutoDiscoveryGridConfiguration';
import { EntityEvents, entityEventsEmitter } from '@bigid-ui/components';

export const useScheduleConfig = (hasManagerPermission: boolean) => {
  const [isScheduleDialogOpen, setIsScheduleDialogOpen] = useState(false);
  const [selectedPreset, setSelectedPreset] = useState<Preset>(null);
  const [rowData, setRowData] = useState({ appId: null });

  const handleOnScheduleButtonClick = useCallback(
    ({
      _id,
      actionId,
      isScheduled,
      is_default,
      id,
      name,
      description,
      cronExpression,
      ...rest
    }: AutoDiscoveryConfigModel) => {
      const preset = {
        _id,
        actionId,
        isScheduled,
        isDefaultPreset: is_default,
        id,
        name,
        description,
        value: '',
        tpaId: rest.appRunAction.tpa_id,
        cronExpression,
        presets: rest.appRunAction.presets,
        isEnabled: rest.paramsKeyValue.enabled,
        params: rest.appRunAction.friendly_name,
        sequentialExecute: rest.appRunAction.sequential_execute,
        friendlyName: rest.appRunAction.friendly_name,
      };
      if (hasManagerPermission) {
        setSelectedPreset(preset);
        setRowData(rest);
        setIsScheduleDialogOpen(true);
      }
      return {
        shouldGridReload: false,
        shouldClearSelection: false,
      };
    },
    [hasManagerPermission],
  );

  const handleUpdateSchedule = useCallback(
    async (preset: Preset, isScheduled: boolean, cronExpression: string) => {
      const { _id, actionId } = preset;
      await customAppService.editActionPreset(rowData?.appId, actionId, _id, { isScheduled, cronExpression });
      setIsScheduleDialogOpen(false);
      entityEventsEmitter.emit(EntityEvents.RELOAD, _id);
    },
    [rowData],
  );

  const handleScheduleDialogClose = useCallback(() => {
    setIsScheduleDialogOpen(false);
  }, []);

  return {
    selectedPreset,
    isScheduleDialogOpen,
    handleScheduleDialogClose,
    handleUpdateSchedule,
    handleOnScheduleButtonClick,
  };
};
