import { useContext, useEffect } from 'react';
import { AutoDiscoveryWizardContext } from '../AutoDiscoveryWizard/autoDiscoveryWizardContext';

const EMPTY_ERRORS_STATE = {};

export const useCheckPermissionsErrors = () => {
  const { discoveryConfigData, discoveryConfigDataRef } = useContext(AutoDiscoveryWizardContext);

  useEffect(() => {
    if (!discoveryConfigDataRef?.current?.setErrors) {
      return;
    }

    const fieldsErrors = discoveryConfigData?.errorMessageKey
      ? discoveryConfigData.fieldsNamesByAuthTypeFields?.reduce((acc, fieldName) => {
          return {
            ...acc,
            [fieldName]: fieldName !== 'name' && true,
          };
        }, EMPTY_ERRORS_STATE)
      : EMPTY_ERRORS_STATE;

    discoveryConfigDataRef.current.setErrors(fieldsErrors ?? EMPTY_ERRORS_STATE);
  }, [
    discoveryConfigData?.connectionInfo,
    discoveryConfigData?.errorMessageKey,
    discoveryConfigData.fieldsNamesByAuthTypeFields,
    discoveryConfigDataRef,
  ]);
};
