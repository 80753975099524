import React, { FC, useState, Fragment, useMemo } from 'react';
import { Add as AddIcon, Remove as RemoveIcon, EditOutlined } from '@mui/icons-material';
import { NormalizedSarProfile } from '../SarProfileSettings';
import {
  BigidImportDialog,
  BigidFieldFilter,
  ActionData,
  entityEventsEmitter,
  EntityEvents,
} from '@bigid-ui/components';
import { BigidGridColumnTypes, BigidGridWithToolbar, BigidGridWithToolbarProps, BigidGridColumn } from '@bigid-ui/grid';
import { notificationService } from '../../../../services/notificationService';
import { sarConfigService } from '../sarConfigService';
import { SarFieldsSettings } from '../ProfileSettingsTypes';
import { httpService } from '../../../../services/httpService';
import { SSEDataMessage, SSEOperationStatus, sendSSERequestWithActionCallback } from '../../../../services/sseService';
import { $state } from '../../../../services/angularServices';
import { EditFieldsDialog, updateSources } from './EditFieldsDialog';
import { useEditFieldsDialog } from './useEditFieldsDialog';
import {
  getCellValueForIncludeExclude,
  getCellValueForIncludeInProximityCategory,
  getCellValueForMaskValue,
} from '../gridCellValues';
import { isPermitted } from '../../../../services/userPermissionsService';
import { DSAR_PERMISSIONS } from '@bigid/permissions';
import { SarProfileTabNames } from '../SarProfileSettingsTypes';
import { useUserPreferences } from '../../../../components/hooks/useUserPrefrences';
import { getApplicationPreference } from '../../../../services/appPreferencesService';

const columns: BigidGridColumn<SarFieldsSettings>[] = [
  {
    width: 170,
    title: 'Field Name',
    name: 'field_name_lower',
    type: BigidGridColumnTypes.TEXT,
    getCellValue: ({ field_name }) => field_name,
  },
  {
    width: 170,
    title: 'Friendly Name',
    name: 'field_friendly_name',
    type: BigidGridColumnTypes.TEXT,
    getCellValue: ({ field_friendly_name }) => field_friendly_name,
  },
  {
    width: 180,
    title: 'Purpose of Use',
    name: 'purpose_of_use',
    type: BigidGridColumnTypes.TEXT,
    getCellValue: ({ purpose_of_use }) => purpose_of_use,
  },
  {
    width: 170,
    title: 'Category',
    name: 'category',
    type: BigidGridColumnTypes.TEXT,
    getCellValue: ({ category }) => category,
  },
  {
    width: 170,
    title: 'Data Source',
    name: 'data_source',
    type: BigidGridColumnTypes.TEXT,
    getCellValue: ({ data_source }) => data_source,
  },
  {
    width: 150,
    title: 'Schema',
    name: 'schema',
    type: BigidGridColumnTypes.TEXT,
    getCellValue: ({ schema }) => schema,
  },
  {
    width: 170,
    title: 'Table',
    name: 'table',
    type: BigidGridColumnTypes.TEXT,
    getCellValue: ({ table }) => table,
  },
  {
    width: 160,
    title: 'Sample Values',
    name: 'sample_values',
    type: BigidGridColumnTypes.TEXT,
    getCellValue: ({ sample_values, mask_it }) => (mask_it ? '*******' : sample_values),
    filteringEnabled: false,
  },
  {
    width: 160,
    title: 'Field Type',
    name: 'attribute_type',
    type: BigidGridColumnTypes.TEXT,
    getCellValue: ({ attribute_type }) => attribute_type,
  },
  {
    width: 140,
    title: 'Mask Value',
    name: 'mask_operation',
    type: BigidGridColumnTypes.TEXT,
    getCellValue: ({ mask_operation }) => getCellValueForMaskValue(mask_operation),
    filteringEnabled: false,
  },
  {
    width: 210,
    title: 'Include in Consumer Report',
    name: 'is_included_in_report',
    type: BigidGridColumnTypes.CHIP,
    getCellValue: ({ is_included_in_report }) => getCellValueForIncludeExclude(is_included_in_report),
    filteringEnabled: false,
  },
  {
    width: 210,
    title: 'Include in Proximity Category',
    name: 'is_included_in_proximity_category',
    type: BigidGridColumnTypes.CHIP,
    getCellValue: ({ is_included_in_proximity_category, attribute_type }) =>
      getCellValueForIncludeInProximityCategory(is_included_in_proximity_category, attribute_type),
    filteringEnabled: false,
    isHiddenByDefault: true,
  },
];

// FieldsSettings
export const FieldsSettings: FC<NormalizedSarProfile> = ({ id: profileId }) => {
  if (profileId && $state.params.profileId !== profileId) {
    $state.go('.', { profileId }, { reload: false, notify: false });
  }

  const [isExportInProgress, setIsExportInProgress] = useState<boolean>(false);
  const [isImportDialogOpen, setImportDialogOpen] = useState<boolean>(false);
  const editFieldsDialogHook = useEditFieldsDialog();

  const userPrefStateName = `${$state.$current.name}.${SarProfileTabNames.FIELDS}`;
  const { isReady, preferences, gridColumns, updatePreferences } = useUserPreferences({
    stateName: userPrefStateName,
    initialGridColumns: columns,
  });

  const handleOnImportClicked = () => {
    setImportDialogOpen(true);
  };

  const handleImportDialogClose = () => {
    setImportDialogOpen(false);
  };

  const maxSize: number = getApplicationPreference('MAX_UPLOAD_LIMITATION');

  const importFile = async (file: File) => {
    setIsExportInProgress(false);

    const sseActionCallback = ({ operationStatus, message }: SSEDataMessage) => {
      if (operationStatus === SSEOperationStatus.BLOCKED) {
        notificationService.warning(message);
      } else {
        entityEventsEmitter.emit(EntityEvents.RELOAD, [], true);
        notificationService.success(message);
      }
    };

    const {
      data: { message },
    } = await sendSSERequestWithActionCallback<{ message: string }>(
      'post',
      `sar/config/import`,
      {
        data: { profileId },
        files: { file },
      },
      {},
      sseActionCallback,
    );

    notificationService.success(message);
  };

  const gridWithToolbarConfig: BigidGridWithToolbarProps<SarFieldsSettings> = useMemo(() => {
    const handleOnExportConfigClicked = async () => {
      try {
        setIsExportInProgress(true);
        await httpService.downloadFile('/sar/config/file-download/config-report', { profileId });
      } catch (e) {
        notificationService.error(`Could not download file ${e.message}`);
        console.error(e);
      } finally {
        setIsExportInProgress(false);
      }
    };

    const includeExcludeExecutor = async (
      isIncludedInReport: boolean,
      { allSelected, filter, selectedRowIds }: ActionData,
    ) => {
      if (!selectedRowIds.length) return {};
      let isIncludedInProximityCategory;
      if (!isIncludedInReport) {
        isIncludedInProximityCategory = false;
      }
      await updateSources(
        profileId,
        { is_included_in_report: isIncludedInReport, is_included_in_proximity_category: isIncludedInProximityCategory },
        selectedRowIds.map(id => ({ _id: id as string })),
        allSelected,
        filter as BigidFieldFilter[],
      );
      return { shouldGridReload: true, shouldClearSelection: true };
    };

    const gridWithToolbarConfig: BigidGridWithToolbarProps<SarFieldsSettings> = {
      showFilteringControls: true,
      columns: gridColumns,
      entityName: 'Fields',
      defaultSorting: preferences?.grid?.sort || [{ field: 'name', order: 'asc' }],
      onGridStateChange: nextGridState => updatePreferences({ gridState: { ...nextGridState, filter: undefined } }),
      toolbarActions: [
        {
          isGlobal: true,
          label: 'Export',
          execute: async () => {
            handleOnExportConfigClicked();
            return { shouldGridReload: false, shouldClearSelection: false };
          },
          disable: () => isExportInProgress,
          show: () => isPermitted(DSAR_PERMISSIONS.EXPORT_PROFILE_SETTINGS.name),
        },
        {
          isGlobal: true,
          label: 'Import',
          execute: async () => {
            handleOnImportClicked();
            return {};
          },
          disable: () => isExportInProgress,
          show: () => isPermitted(DSAR_PERMISSIONS.IMPORT_PROFILE_SETTINGS.name),
        },
        {
          label: 'Include',
          icon: AddIcon,
          execute: actionData => includeExcludeExecutor(true, actionData),
          disable: ({ selectedRowIds }) => !selectedRowIds.length,
          show: ({ totalRows }) => !!totalRows && isPermitted(DSAR_PERMISSIONS.EDIT_PROFILE_SETTINGS.name),
        },
        {
          label: 'Exclude',
          icon: RemoveIcon,
          execute: actionData => includeExcludeExecutor(false, actionData),
          disable: ({ selectedRowIds }) => !selectedRowIds.length,
          show: ({ totalRows }) => !!totalRows && isPermitted(DSAR_PERMISSIONS.EDIT_PROFILE_SETTINGS.name),
        },
        {
          label: 'Edit',
          icon: EditOutlined,
          execute: async ({ selectedRowIds, selectedRows, allSelected, totalRows, filter }) => {
            if (!selectedRowIds.length) return {};
            // fix BigidGrid bug related to `rowsCache`
            selectedRows = selectedRowIds.map(rowId => {
              return (selectedRows as SarFieldsSettings[]).find(({ _id }) => _id === rowId) || { _id: rowId };
            });
            const actionResult = await editFieldsDialogHook.openDialog(
              profileId,
              selectedRows,
              allSelected,
              totalRows,
              filter as BigidFieldFilter[],
            );
            return { shouldClearSelection: actionResult, shouldGridReload: actionResult };
          },
          disable: ({ selectedRowIds }) => !selectedRowIds.length,
          show: () => isPermitted(DSAR_PERMISSIONS.EDIT_PROFILE_SETTINGS.name),
        },
      ],
      fetchData: async gridQueryParams => {
        try {
          const { sarFieldsConf, total } = await sarConfigService.getSarConfig(profileId, gridQueryParams);
          return {
            data: sarFieldsConf ?? [],
            totalCount: total,
          };
        } catch (err) {
          notificationService.error(`Failed to get Fields Settings.`);
          console.error(`Failed to get SAR Request profile by id '${profileId}'`, JSON.stringify(err));
          return { data: [], totalCount: 0 };
        }
      },
    };

    return gridWithToolbarConfig;
  }, [editFieldsDialogHook, gridColumns, isExportInProgress, preferences, profileId, updatePreferences]);

  return (
    <Fragment>
      <BigidImportDialog
        isOpen={isImportDialogOpen}
        onClose={handleImportDialogClose}
        onImportClicked={importFile}
        title={'Import Report Settings'}
        description={'Imported Items will be added to the list. Existing Items will not be affected.'}
        accept={['.csv']}
        maxSize={maxSize}
      />
      <EditFieldsDialog {...editFieldsDialogHook.dialogProps} />
      {isReady && <BigidGridWithToolbar key={profileId} {...gridWithToolbarConfig} />}
    </Fragment>
  );
};
