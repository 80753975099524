import React, { useState } from 'react';
import {
  BigidDialog,
  BigidDialogButtonType,
  BigidDropdown,
  BigidDropdownOption,
  BigidDropdownValue,
  BigidScheduler,
  PrimaryButton,
  RecurringSchedule,
  StyledButtonType,
  TertiaryButton,
  convertRecurringScheduleObjectToCron,
} from '@bigid-ui/components';
import { BigidAddIcon } from '@bigid-ui/icons';
import { Scheduler } from './mappers/styles';
import { notificationService } from '../../../../services/notificationService';
import { getCurrentUser } from '../../../../utilities/systemUsersUtils';
import { useLocalTranslation } from '../translations';
import { useGetScanTemplates } from './hooks/useGetScanTemplates';
import { mapFrequencyTypeToString, mapGetScanTemplatesResponseToBigidDropdownOptions } from './mappers/templates';
import { getDefaultScheduleRecurrence } from './config/scheduler';
import { useCreateScanProfile } from './hooks/useCreateScanProfile';
import { $state } from '../../../../services/angularServices';
import { CONFIG } from '../../../../../config/common';
import { shuffle } from 'lodash';

export type DataSourceScanSchedulerProps = {
  dataAid?: string;
  onClose?: () => void;
  onSchedule?: (payload: DataSourceScanSchedulerPayload) => void;
  schedule?: RecurringSchedule;
  dataSourcesIds: string[];
  isOpen: boolean;
};

export type DataSourceScanSchedulerPayload = {
  schedule: RecurringSchedule;
  template: string;
  dataSourcesIds: string[];
};

const DEFAULT_SEARCH_TERM: string = null;
const DEFAULT_OPTION: BigidDropdownValue = [];
const NO_OPTIONS: BigidDropdownOption[] = [];
const DEFAULT_SCHEDULER = getDefaultScheduleRecurrence();
const TRANSLATION_PREFIX = 'OnboardingAssistant';

export const DataSourceScanScheduler = ({
  dataAid = 'DataSourceScanScheduler',
  onClose,
  onSchedule,
  schedule,
  dataSourcesIds,
  isOpen: defaultIsOpen,
}: DataSourceScanSchedulerProps) => {
  const [isOpen, setOpen] = useState(defaultIsOpen);
  const [scheduleData, setSchedule] = useState<RecurringSchedule>(schedule ?? DEFAULT_SCHEDULER);
  const [option, setOption] = useState<BigidDropdownValue>(DEFAULT_OPTION);
  const { t } = useLocalTranslation(TRANSLATION_PREFIX);

  const { data: options, isError } = useGetScanTemplates(DEFAULT_SEARCH_TERM, {
    query: {
      select: mapGetScanTemplatesResponseToBigidDropdownOptions,
      onError: () => notificationService.error(t('scheduler.notifications.templateFetchError')),
    },
  });

  const { mutateAsync: createScanProfile, isLoading: isCreating } = useCreateScanProfile({
    mutation: {
      onSuccess: () => notificationService.success(t('scheduler.notifications.success')),
      onError: () => notificationService.error(t('scheduler.notifications.error')),
      onSettled: () => onClose?.(),
    },
  });

  const isSaveDisabled = isCreating || !scheduleData || !option?.length;

  const handleScheduleChange = (changes: RecurringSchedule) => {
    setSchedule(prev => ({ ...prev, ...changes }));
  };

  const handleAddTemplate = () => $state.go(CONFIG.states.SCAN_TEMPLATE);

  const handleCloseDialog = () => {
    setOpen(false);
    onClose?.();
  };

  const handleSaveSchedule = async () => {
    try {
      const [template] = option;
      const payload = {
        schedule: scheduleData,
        dataSourcesIds,
        template: template?.value,
      };
      const { name: owner } = await getCurrentUser();
      const frequency = mapFrequencyTypeToString(scheduleData?.frequency);
      // @info randomizes the first received data source name to maintain a semi unique scan name without adding a uuid to the name
      const [first, ...rest] = shuffle(dataSourcesIds ?? []);
      const date = scheduleData?.startDate?.toLocaleDateString('en-US', {
        year: 'numeric',
        month: 'long',
      });
      const name =
        t('scheduler.labels.name', { frequency, date }) +
        ` - ${first}${rest?.length ? ` and ${rest.length} more sources` : ''}`;

      createScanProfile({
        data: {
          name,
          description: t('scheduler.labels.description', { frequency: frequency.toLocaleLowerCase() }),
          owners: [owner],
          dataSourceList: payload.dataSourcesIds,
          scanTemplateId: payload.template,
          schedule: convertRecurringScheduleObjectToCron(payload.schedule),
          active: true,
          isCustomScanProfile: true,
          allEnabledDs: true,
          isSingleRunScan: false,
        },
      });

      onSchedule?.(payload);
    } catch {}
  };

  const handleTemplateChange = (selected: BigidDropdownOption[]) => {
    setOption(selected);
  };

  const buttons: BigidDialogButtonType[] = [
    {
      component: (props: StyledButtonType) => <TertiaryButton size="large" startIcon={<BigidAddIcon />} {...props} />,
      text: t('scheduler.actions.addTemplate'),
      onClick: handleAddTemplate,
      isClose: true,
      alignment: 'left',
    },
    {
      component: TertiaryButton,
      text: t('scheduler.actions.cancel'),
      onClick: () => onClose?.(),
      isClose: true,
    },
    {
      component: PrimaryButton,
      text: t('scheduler.actions.save'),
      onClick: handleSaveSchedule,
      disabled: isSaveDisabled,
    },
  ];

  return (
    <BigidDialog buttons={buttons} title={t('scheduler.title')} isOpen={isOpen} onClose={handleCloseDialog}>
      <Scheduler.Container data-aid={dataAid}>
        <BigidScheduler
          hideDelimiter
          formFieldSize="large"
          scheduleData={scheduleData}
          onChange={handleScheduleChange}
        />
        <Scheduler.Field>
          <Scheduler.Label>{t('scheduler.labels.template')}</Scheduler.Label>
          <BigidDropdown
            size="large"
            isError={isError}
            isSearchable={false}
            isMulti={false}
            value={option}
            onSelect={handleTemplateChange}
            options={options ?? NO_OPTIONS}
          />
        </Scheduler.Field>
      </Scheduler.Container>
    </BigidDialog>
  );
};
