import React, { memo, FC } from 'react';
import { FormLabel } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { Help } from '@mui/icons-material';
import {
  BigidTextField,
  TextFieldProps,
  BigidBody1,
  BigidTooltip,
  BigidIcon,
  BigidIconSize,
  BigidColors,
} from '@bigid-ui/components';

const useStyles = makeStyles({
  root: isShorten => ({
    // NOTE: a hot-fix in order to align form inputs with BigidExtraField
    width: isShorten ? 'calc(100% - 58px)' : '100%',
    display: 'flex',
    flexWrap: 'nowrap',
    alignItems: 'center',
  }),
  label: {
    width: '30%',
    marginRight: '10px',
    position: 'relative',
    display: 'flex',
  },
  tip: {
    marginLeft: '5px',
  },
});

type EditTagDialogTextFieldProps = Omit<TextFieldProps, 'onChange'> & {
  tip?: string;
  isShorten: boolean;
  onChange: (value: string) => void;
};

export const EditTagDialogTextField: FC<EditTagDialogTextFieldProps> = memo(
  ({ value, label, tip, isShorten, onChange, errorMessage }) => {
    const classes = useStyles({ isShorten });

    return (
      <div className={classes.root}>
        <FormLabel id={`TagFormTextField-${label}-label`} className={classes.label} component={BigidBody1}>
          {label}
          {tip && (
            <BigidTooltip placement="top" title={tip}>
              <div className={classes.tip}>
                <BigidIcon size={BigidIconSize.REGULAR} icon={Help} color={BigidColors.gray[300]} />
              </div>
            </BigidTooltip>
          )}
        </FormLabel>
        <BigidTextField
          id={`TagFormTextField-${label}-field`}
          onChange={event => {
            onChange(event.target.value);
          }}
          value={value}
          isError={!!errorMessage}
          errorMessage={errorMessage}
        />
      </div>
    );
  },
);

EditTagDialogTextField.displayName = 'EditTagDialogTextField';
