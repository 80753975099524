import React, { FC, useEffect, useState } from 'react';
import angular from 'angular';
import {
  BigidCaption,
  BigidColorsV2,
  BigidDialog,
  PrimaryButton,
  SecondaryButton,
  BigidFormValues,
  BigidApiCallForm,
} from '@bigid-ui/components';
import makeStyles from '@mui/styles/makeStyles';
import { convertToAngular } from '../../../../common/services/convertToAngular';
import { ApiCallCertificateForm } from '../../ApiCallCertificateForm/ApiCallCertificateForm';
import {
  ApiCallConfigForm,
  convertApiCallConfigToFormFormat,
  CustomCredentialApiCallProps,
  removeEmptyExtraField,
} from './customCredentailDialogUtils';
import { convertFormFormatToApiCallAction, validateAPICallForm } from '../../../utilities/apiCallFormUtilities';

export type CertificateFormType = {
  useCertificate: boolean;
  allowSelfSignCertificate?: boolean;
  clientCert?: string;
  clientKeyCert?: string;
};

const useStyles = makeStyles({
  errorMessageWrapper: {
    marginTop: 16,
    display: 'flex',
    flexDirection: 'column',
    background: BigidColorsV2.red[200],
    borderRadius: 4,
    padding: '8px 16px',
  },
  errorMessage: { color: BigidColorsV2.red[900] },
});

export const CustomCredentialApiCallDialog: FC<CustomCredentialApiCallProps> = ({
  isOpen = false,
  onClose,
  value,
  onSaveApiConfig,
}) => {
  const [form, setForm] = useState<ApiCallConfigForm>();
  const [errors, setErrors] = useState<string[]>([]);
  const handleSetValue = (name: string, value: BigidFormValues) =>
    setForm(prevValue => ({ ...prevValue, [name]: value }));
  const classes = useStyles();

  useEffect(() => {
    setForm(convertApiCallConfigToFormFormat(value));
  }, [value]);

  const handleCloseDialog = () => {
    onClose();
    setErrors([]);
  };

  const handleSave = () => {
    const formToSave = removeEmptyExtraField(form);
    const errors = validateAPICallForm(formToSave);
    if (errors.length === 0) {
      onSaveApiConfig(convertFormFormatToApiCallAction(formToSave));
      handleCloseDialog();
    } else {
      setErrors(errors);
    }
  };

  return (
    <BigidDialog
      isOpen={isOpen}
      onClose={handleCloseDialog}
      title="Connect to Remote Vault"
      showCloseIcon
      buttons={[
        {
          text: 'Save',
          component: PrimaryButton,
          onClick: handleSave,
          dataAid: 'APICallSettingsSaveButton',
        },
        {
          text: 'Cancel',
          component: SecondaryButton,
          onClick: handleCloseDialog,
          isClose: true,
          dataAid: 'APICallSettingsCancelButton',
        },
      ]}
    >
      <BigidApiCallForm setValue={handleSetValue} value={form}>
        <ApiCallCertificateForm {...form} setValue={handleSetValue} />
        {errors?.length > 0 && (
          <div className={classes.errorMessageWrapper}>
            {errors?.length > 0 &&
              errors.map((error, index) => (
                <BigidCaption key={index} className={classes.errorMessage} data-aid={`ApiCallError-${index}`}>
                  {error}
                </BigidCaption>
              ))}
          </div>
        )}
      </BigidApiCallForm>
    </BigidDialog>
  );
};

angular
  .module('app')
  .component(
    'customCredentialApiCallDialog',
    convertToAngular(CustomCredentialApiCallDialog, ['isOpen', 'onClose', 'value', 'onSaveApiConfig']),
  );
