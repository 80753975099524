import { BigidGridColumn, BigidGridColumnTypes, BigidGridWithToolbar, BigidGridWithToolbarProps } from '@bigid-ui/grid';
import React, { FC, ReactText, useCallback, useEffect, useState } from 'react';
import { openSystemDialog, SystemDialogContentProps } from '../../../../services/systemDialogService';
import { BigidCopyIcon } from '@bigid-ui/icons';
import { Part, PartsInfo } from './RetrieveFilesDialogSelector';
import { v4 as uuid } from 'uuid';
import { httpService } from '../../../../services/httpService';

export enum RetrieveFileStatus {
  inProgress = 'inProgress',
  Completed = 'Completed',
  Queued = 'Queued',
}

export interface RetrieveFilesProgress {
  dataSourceName: string;
  state: RetrieveFileStatus;
  info: string;
  id: ReactText;
}

export interface RetrieveFilesDialogProps {
  retrievedFiles: PartsInfo;
  requestId: string;
}
export const RetrieveFilesDialogContent: FC<SystemDialogContentProps<RetrieveFilesDialogProps>> = ({
  retrievedFiles,
  requestId,
}) => {
  const [rows, setRows] = useState<RetrieveFilesProgress[]>([]);

  const buildRetrievedFiles = (part: Part): string => {
    let info = `${part?.completed ?? 0} Files Succeeded`;

    if (part?.failed > 0) {
      info += ` ,${part.failed} Files Failed`;
    }
    if (part?.inProgress > 0) {
      info += ` ,${part.inProgress} Files In progress`;
    }
    if (part?.queue > 0) {
      info += ` ,${part.queue} Files in queue`;
    }

    return info;
  };

  useEffect(() => {
    const convertedRows = Object.keys(retrievedFiles).map(key => ({
      dataSourceName: key,
      state: retrievedFiles[key].state,
      info: buildRetrievedFiles(retrievedFiles[key].partsInfo),
      id: uuid(),
    }));

    setRows(convertedRows);
  }, [retrievedFiles]);

  const getGridColumns = useCallback((): BigidGridColumn<RetrieveFilesProgress>[] => {
    return [
      {
        name: 'dataSourceName',
        title: 'Data source name',
        getCellValue: row => row.dataSourceName,
        type: BigidGridColumnTypes.TEXT,
        width: 220,
      },
      {
        name: 'state',
        title: 'State',
        getCellValue: row => row.state,
        type: BigidGridColumnTypes.TEXT,
        width: 170,
      },
      {
        name: 'info',
        title: 'Info',
        getCellValue: row => row.info,
        type: BigidGridColumnTypes.TEXT,
        width: 500,
      },
    ];
  }, []);

  const openUrlInNewTab = (url: string): void => {
    const newWindow = window.open(url, '_blank', 'noopener,noreferrer');
    if (newWindow) {
      newWindow.opener = null;
    }
  };

  const gridWithToolbarConfig: BigidGridWithToolbarProps<RetrieveFilesProgress> = {
    columns: getGridColumns(),
    hideColumnChooser: true,
    entityName: 'Requests',
    displayActionToolbar: true,
    showSortingControls: false,
    fetchData: async () => {
      try {
        return {
          data: rows,
          totalCount: rows.length,
        };
      } catch (err) {
        const errMsg = `an error in fetch the DSAR results`;
        console.error(`${errMsg} ${err}`);
      }
    },
    toolbarActions: [
      {
        label: 'Retry Failed Files to Collect',
        isGlobal: false,
        execute: async actionData => {
          return {};
        },
        disable: () => false,
        show: () => false,
      },
      {
        label: 'Download Failed Collection Report',
        isGlobal: true,
        execute: async actionData => {
          return {};
        },
        disable: () => false,
        show: () => false,
      },
      {
        label: 'Open target folder',
        icon: BigidCopyIcon,
        isGlobal: true,
        execute: async actionData => {
          const {
            data: {
              data: { targetPathLink },
            },
          } = await httpService.fetch(`sar/retrieve-files/${requestId}/link`);
          openUrlInNewTab(targetPathLink);
          return {};
        },
        disable: () => false,
        show: () => true,
      },
    ],
  };

  return (
    <div data-aid="Retrieve-Files-Dialog">
      <BigidGridWithToolbar {...gridWithToolbarConfig} />
    </div>
  );
};

export const openRetrievedFilesDialog = (retrievedFiles: PartsInfo, requestId: string) => {
  openSystemDialog({
    maxWidth: 'lg',
    title: 'Collect Files',
    content: RetrieveFilesDialogContent,
    contentProps: {
      retrievedFiles: retrievedFiles,
      requestId,
    },
    onClose: () => null,
  });
};
