import React, { FC, Dispatch } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { BigidBody1, BigidLink, BigidPrimaryCheckbox, BigidColors } from '@bigid-ui/components';
import ReportProblemOutlinedIcon from '@mui/icons-material/ReportProblemOutlined';
import { publicUrls } from '../../../config/publicUrls';

const FEEDBACK_PRIVACY_CHECKBOX_TEXT = `By checking this box, you understand and accept that BigID, as part of their service delivery, may have access to information that is considered personal or sensitive under applicable data protection laws. We process this information in accordance with our`;
const errorMessage = 'Privacy notice consent is required';

interface PrivacyCheckboxProps {
  checked: boolean;
  setChecked: Dispatch<boolean>;
  error?: boolean;
}

const useStyles = makeStyles({
  root: { display: 'flex', flexDirection: 'column' },
  privacyCheckbox: { display: 'flex', marginTop: 16 },
  privacyCheckboxText: { marginTop: 4 },
  privacyNoticeLink: { fontSize: '0.8125rem' },
  errorWrapper: { color: BigidColors.failureRed, display: 'flex', alignItems: 'center', marginLeft: 30 },
  errorIcon: { height: 16, width: 16, marginRight: 4 },
});

export const PrivacyCheckbox: FC<PrivacyCheckboxProps> = ({ checked, setChecked, error }) => {
  const { root, privacyCheckbox, privacyCheckboxText, privacyNoticeLink, errorWrapper, errorIcon } = useStyles({});
  return (
    <div className={root}>
      <div className={privacyCheckbox}>
        <div>
          <BigidPrimaryCheckbox checked={checked} onChange={(e, val) => setChecked(val)} label="" />
        </div>
        <BigidBody1 className={privacyCheckboxText}>
          {FEEDBACK_PRIVACY_CHECKBOX_TEXT}{' '}
          <BigidLink
            text="Privacy Notice"
            href={publicUrls.BIGID_PRIVACY_NOTICE}
            shouldOpenNewTab
            underline="none"
            classNames={privacyNoticeLink}
          />
        </BigidBody1>
      </div>
      {error && (
        <BigidBody1 className={errorWrapper}>
          <ReportProblemOutlinedIcon className={errorIcon} />
          {errorMessage}
        </BigidBody1>
      )}
    </div>
  );
};
