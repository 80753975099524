import React from 'react';
import { BigidGridColumnTypes, BigidGridProps, useFetch } from '@bigid-ui/grid';
import {
  BigidLayoutMasterDetails,
  BigidLayoutMasterDetailsConfig,
  BigidLayoutMasterDetailsGridRowLayouts,
  BigidLayoutMasterDetailsGridSearchTypes,
} from '@bigid-ui/layout';
import { ApplicationGridRow, CountryEntity, DatasourceEntity } from './types';
import { ApplicationNoData } from './ApplicationNoData';
import { getFixedT } from './translations';
import { isPermitted } from '../../services/userPermissionsService';
import { CREDENTIALS_PERMISSIONS } from '@bigid/permissions';
import {
  BigidStatusBadge,
  BigidDropdownOption,
  BigidFormField,
  BigidFormFieldTypes,
  BigidFormValues,
  BigidStatusBadgeSize,
  BigidStatusBadgeType,
} from '@bigid-ui/components';

interface GetFormFieldsArgs {
  dataSourcesOptions: BigidDropdownOption[];
  countriesOptions: BigidDropdownOption[];
  isDatasourceListEnabled: boolean;
}

const t = (key: string): string => getFixedT('')(key);
const validateField = (message: string) => (value: string) => value ? false : t(message);

const SECURITY_TIER_OPTIONS: BigidDropdownOption[] = [
  { id: '1', value: '1', displayValue: '1' },
  { id: '2', value: '2', displayValue: '2' },
  { id: '3', value: '3', displayValue: '3' },
  { id: '4', value: '4', displayValue: '4' },
  { id: '5', value: '5', displayValue: '5' },
];

const DISPOSITION_OPTIONS: BigidDropdownOption[] = [
  { id: '1', value: '1', displayValue: 'Collection' },
  { id: '2', value: '2', displayValue: 'Transformation' },
  { id: '3', value: '3', displayValue: 'Retention' },
  { id: '4', value: '4', displayValue: 'Transfer' },
  { id: '5', value: '5', displayValue: 'Disposal' },
];

export const createGridConfig = (
  id: string,
  config: ReturnType<typeof useFetch<ApplicationGridRow>>,
): BigidGridProps<ApplicationGridRow> => ({
  gridId: id,
  rows: config.rows,
  totalRowsCount: config.totalRowsCount,
  skip: config.skip,
  onPagingChanged: config.onPagingChanged,
  onSortingChanged: config.onSortingChanged,
  onFiltersChange: config.onFiltersChanged,
  defaultSorting: config.defaultSorting,
  loading: config.isLoading,
  pageSize: 1000,
  noDataContent: <ApplicationNoData message={t('message.noSavedApplications')} />,
  columns: [
    {
      name: 'name',
      title: 'name',
      width: 'auto',
      getCellValue: row => (
        <BigidLayoutMasterDetails.Row
          title={row.name}
          subtitle={
            row.isPending ? (
              <BigidStatusBadge
                label={t('statusText')}
                type={BigidStatusBadgeType.DARK}
                size={BigidStatusBadgeSize.SMALL}
              />
            ) : (
              `${t('rowSubtitlePrefix')}: ${row.target_data_source}`
            )
          }
          layout={BigidLayoutMasterDetailsGridRowLayouts.TWO_ROW}
        />
      ),
      type: BigidGridColumnTypes.CUSTOM,
    },
  ],
});

export const createLayoutConfig = (
  gridConfig: BigidGridProps<ApplicationGridRow>,
): BigidLayoutMasterDetailsConfig<ApplicationGridRow> => ({
  grid: gridConfig,
  search: {
    fields: ['name'],
    placeholder: t('search.placeholder'),
    type: BigidLayoutMasterDetailsGridSearchTypes.INTEGRATED,
  },
});

export const getPermissions = () => ({
  isDeletePermitted: isPermitted(CREDENTIALS_PERMISSIONS.DELETE.name),
  isEditPermitted: isPermitted(CREDENTIALS_PERMISSIONS.EDIT.name),
  isCreatePermitted: isPermitted(CREDENTIALS_PERMISSIONS.CREATE.name),
});

export const alphaSort = (a: ApplicationGridRow, b: ApplicationGridRow) => {
  const aName = a.name.toLowerCase();
  const bName = b.name.toLowerCase();

  if (aName < bName) return -1;
  if (aName > bName) return 1;
  return 0;
};

export const convertDataSourcesToDropdownOptions = (dataSources: DatasourceEntity[]): BigidDropdownOption[] => {
  const noneOptionText = t('form.dataSourceSection.nonePlaceholderOption');
  const options = dataSources.map(({ name, id }) => ({ id, value: name, displayValue: name }));
  options.push({ id: noneOptionText, value: noneOptionText, displayValue: noneOptionText });
  return options;
};

export const convertCountriesToDropdownOptions = (countries: CountryEntity[]): BigidDropdownOption[] =>
  countries.map(({ name, displayName, groupedCode }) => ({ id: groupedCode, value: name, displayValue: displayName }));

export const getFormFields = ({
  dataSourcesOptions,
  countriesOptions,
  isDatasourceListEnabled,
}: GetFormFieldsArgs): BigidFormField[] => [
  {
    name: 'name',
    label: t('form.appName'),
    isRequired: true,
    fieldProps: {
      size: 'large',
    },
    validate: validateField(t('message.requiredField')),
  },
  {
    name: 'app_account_name',
    label: t('form.appAccountName'),
    fieldProps: {
      size: 'large',
    },
  },
  {
    name: 'source_ip',
    label: t('form.dataSourceSection.activityDataSources'),
    fieldProps: {
      size: 'large',
    },
  },
  {
    name: 'target_data_source',
    label: t('form.dataSourceSection.dataSource'),
    type: BigidFormFieldTypes.DROP_DOWN,
    dropDownOptions: dataSourcesOptions,
    fieldProps: {
      isSearchable: true,
      size: 'large',
      ...(isDatasourceListEnabled && {
        isMulti: true,
        isExpandable: true,
        applyOnChange: true,
      }),
    },
  },
  {
    name: 'owner_name',
    label: t('form.ownerDetailsSection.name'),
    fieldProps: {
      size: 'large',
    },
  },
  {
    name: 'owner_phone',
    label: t('form.ownerDetailsSection.cell'),
    fieldProps: {
      size: 'large',
    },
  },
  {
    name: 'owner_email',
    label: t('form.ownerDetailsSection.email'),
    fieldProps: {
      size: 'large',
    },
  },
  {
    name: 'security_tier',
    label: t('form.appDetailsSection.securityTier'),
    type: BigidFormFieldTypes.DROP_DOWN,
    dropDownOptions: SECURITY_TIER_OPTIONS,
    fieldProps: {
      isSearchable: true,
      size: 'large',
    },
  },
  {
    name: 'location',
    label: t('form.appDetailsSection.location'),
    type: BigidFormFieldTypes.DROP_DOWN,
    dropDownOptions: countriesOptions,
    fieldProps: {
      isSearchable: true,
      size: 'large',
      displayLimit: 300,
    },
  },
  {
    name: 'data_mapping_classifier',
    label: t('form.appDetailsSection.dataFlowsClassifier'),
    type: BigidFormFieldTypes.DROP_DOWN,
    dropDownOptions: DISPOSITION_OPTIONS,
    fieldProps: {
      isSearchable: true,
      size: 'large',
    },
  },
];

export const getInitialValues = (fields: BigidFormField[], row: ApplicationGridRow, isDatasourceListEnabled: boolean) =>
  fields.reduce<BigidFormValues>((prev, current) => {
    const name = current.name as keyof ApplicationGridRow;
    const isDropDownType = current.type === BigidFormFieldTypes.DROP_DOWN;

    const getValue = () => {
      if (isDropDownType) {
        const isDSList = name === 'target_data_source' && isDatasourceListEnabled;

        return current.dropDownOptions.filter(({ value }) =>
          isDSList ? row.target_source_list.map(({ source }) => source).includes(value) : value === row[name],
        );
      }

      if (name === 'name' && row.isPending) {
        return '';
      }

      return row[name];
    };

    return {
      ...prev,
      [name]: getValue(),
    };
  }, {} as BigidFormValues);

export const prepareRequestData = (
  values: BigidFormValues,
  fields: BigidFormField[],
  isDatasourceListEnabled: boolean,
) => {
  const requestData = { ...values };

  fields.forEach(({ type, name }) => {
    if (type === BigidFormFieldTypes.DROP_DOWN) {
      requestData[name] = requestData[name].length ? requestData[name][0].value : '';
      if (name === 'target_data_source' && isDatasourceListEnabled) {
        requestData.target_source_list = values[name].map(({ value }: BigidDropdownOption) => ({ source: value }));
      }
    }
  });

  if (isDatasourceListEnabled) {
    delete requestData.target_data_source;
  }

  // @todo do this value needed???
  requestData.filter = '';
  return requestData;
};
