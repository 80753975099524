import { $uibModal } from './angularServices';
import '../views/ThycoticConfig/ThycoticConfig';
import '../views/ThycoticConfig/ThycoticConfig.scss';
import { httpService } from './httpService';
import { notificationService } from './notificationService';

export interface ThycoticData {
    name: string;
    auth_method: string;
    url: string;
    bind_secret_id: boolean;
    secret_id?: string;
    role_id: string;
    _id?: string;
    [propName: string]: any;
}

interface ThycoticService {
    showDialog: () => void;
    getConfiguration: (callback: (configuration: ThycoticData) => void) => void;
    createConfiguration: (configuration: ThycoticData, callback: (id: string) => void) => void;
    updateConfiguration: ({ configuration, id }: { configuration: any; id: string }) => void;
    testConfiguration: (configuration: ThycoticData) => void;
    deleteConfiguration: (id: string) => void;
}

const getConfiguration = async (callback: (configuration: ThycoticData) => void) => {
    const {
        data: { results },
    } = await httpService.fetch('credentials-providers?type=Thycotic&limit=1');
    callback(results.length ? results[0] : null);
};

const createConfiguration = async (configuration: ThycoticData, callback: (id: string) => void) => {
    try {
        const {
            data: { id },
        } = await httpService.post('credentials-providers', {
            data: JSON.stringify({ ...configuration, type: 'Thycotic' }),
        });
        callback(id);
        notificationService.success('Configuration Saved');
    } catch (error) {
        notificationService.error('Failed to create configuration');
    }
};
const updateConfiguration = async ({ configuration, id }: { configuration: any; id: string }) => {
    try {
        await httpService.put(`credentials-providers/${id}`, {
            data: JSON.stringify({ ...configuration, type: 'Thycotic' }),
        });
        notificationService.success('Configuration Saved');
    } catch (error) {
        notificationService.error('Failed to update configuration');
    }
};
const testConfiguration = async (configuration: ThycoticData) => {
    try {
        await httpService.post('credentials-providers/connection/test', {
            data: JSON.stringify({ ...configuration, type: 'Thycotic' }),
        });
        notificationService.success('Connection Success');
    } catch (error) {
        console.error(error);
        notificationService.error('Connection Failed');
    }
};

const deleteConfiguration = async (id: string) => {
    await httpService.delete(`credentials-providers/${id}`);
};

const showDialog = () => {
    return $uibModal.open({
        animation: true,
        backdrop: 'static',
        windowClass: `bigid__uibmodal--centered modal-config`,
        template: '<thycotic-dialog on-cancel="$dismiss"></thycotic-dialog>',
    });
};

export const thycoticService: ThycoticService = {
    showDialog,
    getConfiguration,
    createConfiguration,
    updateConfiguration,
    testConfiguration,
    deleteConfiguration,
};
