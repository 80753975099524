import React, { FC, useEffect, useState } from 'react';
import {
  BigidGridWithToolbar,
  BigidGridWithToolbarProps,
  BigidGridColumnTypes,
  BigidGridColumn,
  BigidGridQueryComponents,
  BigidGridRow,
} from '@bigid-ui/grid';
import { getAttributesDetailsByParentId, getDataSourcesDetailsByParentId } from '../ScanInsightService';
import { BigidPaper } from '@bigid-ui/components';
import makeStyles from '@mui/styles/makeStyles';
import { queryService } from '../../../../services/queryService';
import { AttributeDetails } from '../ScanInsightTypes';
import { EmptyTemplate } from '../../../../components/EmptyTemplate/EmptyTemplate';
import { ViewDetailsDocumentation } from '../ViewDetailsDocumentation/ViewDetailsDocumentation';

interface AttributesViewDetailsProps {
  scanId: string;
  type?: string;
  title: string;
  scannedDataSources: number;
}

const useStyles = makeStyles({
  gridWrapper: {
    width: '100%',
    display: 'flex',
    position: 'relative',
    height: '70vh',
    maxHeight: '70vh',
    overflow: 'hidden',
  },
});

const columns: BigidGridColumn<AttributeDetails & BigidGridRow>[] = [
  {
    name: 'name',
    title: 'Attribute Name',
    width: 450,
    getCellValue: ({ _id: name }) => name,
    type: BigidGridColumnTypes.TEXT,
  },
  {
    name: 'new',
    title: 'New',
    sortingEnabled: true,
    getCellValue: ({ new: newCount }) => newCount || 0,
    type: BigidGridColumnTypes.NUMBER,
  },
  {
    name: 'removed',
    title: 'Removed',
    sortingEnabled: true,
    getCellValue: ({ removed }) => removed || 0,
    type: BigidGridColumnTypes.NUMBER,
  },
];

export const AttributesViewDetails: FC<AttributesViewDetailsProps> = ({ scanId, type, title, scannedDataSources }) => {
  const [isOldFlow, setIsOldFlow] = useState(true);
  const [isFinishLoading, setIsFinishLoading] = useState(false);
  const classes = useStyles();

  useEffect(() => {
    handleOldFlow();
  }, []);

  const handleOldFlow = async () => {
    if (scannedDataSources === 0) {
      return;
    }
    const response = await getDataSourcesDetailsByParentId(scanId, 'limit=1');
    setIsFinishLoading(true);
    if (response?.dataSourcesInsights) {
      setIsOldFlow(false);
    }
  };

  const gridConfig: BigidGridWithToolbarProps<any> = {
    pageSize: 10,
    entityName: title,
    defaultSorting: [{ field: 'new', order: 'desc' }],
    showSortingControls: true,
    columns,
    filterToolbarConfig: {
      filters: [
        {
          title: 'Status',
          field: 'status',
          operator: 'greaterThan',
          disabled: true,
          single: true,
          value: [],
          options: [
            {
              label: 'New',
              value: 'new',
              isSelected: false,
            },
            {
              label: 'Removed',
              value: 'removed',
              isSelected: false,
            },
          ],
        },
      ],
      searchConfig: {
        searchFilterKeys: ['name'],
        initialValue: '',
        placeholder: `${type} name`,
        operator: 'textSearch',
        autoSubmit: true,
      },
    },
    fetchData: async (queryComponents: BigidGridQueryComponents) => {
      if (scannedDataSources === 0) {
        return { data: [], totalCount: 0 };
      }
      const { filter } = queryComponents;
      const requiredField = filter.find(row => row.field === 'status');
      if (requiredField && (requiredField.value === 'new' || requiredField.value === 'removed')) {
        requiredField.field = requiredField.value as string;
        requiredField.value = 0;
      }
      const gridConfigQuery = queryService.getGridConfigQuery(queryComponents);
      const response = await getAttributesDetailsByParentId({ parentId: scanId, type, gridConfigQuery });
      return { data: response.dataSourcesInsights, totalCount: response.totalCount };
    },
  };

  const isShowBigidGrid = () => {
    return scannedDataSources === 0 || (isFinishLoading && isOldFlow === false);
  };

  return (
    <>
      {isFinishLoading && isOldFlow === true && <EmptyTemplate content={<ViewDetailsDocumentation />} />}
      {isShowBigidGrid() && (
        <div className={classes.gridWrapper}>
          <BigidPaper>
            <BigidGridWithToolbar {...gridConfig} />
          </BigidPaper>
        </div>
      )}
    </>
  );
};
