import angular from 'angular';
import React, { FC, useEffect, useState } from 'react';
import { convertToAngular } from '../../../common/services/convertToAngular';
import { httpService } from '../../services/httpService';
import { BigidTooltip } from '@bigid-ui/components';
const LOADING_TEXT = 'Loading explanation...';
const ERROR_FETCHING_TEXT = 'There was an error fetching the data';
const NO_DATA_TEXT = 'No data found';
export interface ConfidenceLevelExplanationItem {
  fullyQualifiedName: string;
  fieldName: string;
  attribute_type: string;
  attribute_name: string;
  confidence_level: number;
  calculatedRank?: string;
}
export interface ConfidenceLevelExplanationProps {
  item: ConfidenceLevelExplanationItem;
  isCalculatedRankColumn?: boolean;
}
export const ConfidenceLevelExplanation: FC<ConfidenceLevelExplanationProps> = ({ item }) => {
  const [isLoading, setLoading] = useState(false);
  const [explanationTextResult, setExplanationTextResult] = useState(LOADING_TEXT);
  useEffect(() => {
    async function getExplanation() {
      setLoading(true);
      try {
        const { fullyQualifiedName, fieldName, attribute_type, attribute_name, confidence_level } = item;
        const filter = `fullyQualifiedName="${fullyQualifiedName}" 
            and attribute="${attribute_name}" 
            and attribute_type="${attribute_type}" 
            and fieldName="${fieldName}" 
            and confidence_level="${confidence_level}"`;
        const {
          data: {
            data: { text = NO_DATA_TEXT },
          },
        } = await httpService.fetch('explainer/explain', { filter });
        setExplanationTextResult(text);
      } catch (error) {
        setExplanationTextResult(ERROR_FETCHING_TEXT);
        console.error(error);
      }
      setLoading(false);
    }
    const timeoutId = setTimeout(getExplanation, 500);
    return () => {
      clearTimeout(timeoutId);
      setExplanationTextResult(LOADING_TEXT);
      setLoading(false);
    };
  }, [item]);
  const explanationPreLinedText = <div style={{ whiteSpace: 'pre-line' }}>{explanationTextResult}</div>;
  return <>{isLoading ? LOADING_TEXT : explanationPreLinedText}</>;
};
export const ConfidenceLevelExplanationWithTooltip: FC<ConfidenceLevelExplanationProps> = ({
  item,
  isCalculatedRankColumn,
}) => {
  const { confidence_level, calculatedRank } = item;
  const tooltipContent = isCalculatedRankColumn ? calculatedRank : confidence_level?.toFixed(3);
  return (
    <BigidTooltip width={'400px'} title={<ConfidenceLevelExplanation item={item} />}>
      <div>{tooltipContent}</div>
    </BigidTooltip>
  );
};
angular
  .module('app')
  .component(
    'confidenceLevelExplanationWithTooltip',
    convertToAngular<ConfidenceLevelExplanationProps>(ConfidenceLevelExplanationWithTooltip, [
      'item',
      'isCalculatedRankColumn',
    ]),
  );
