import './newIdentityConnectionPii.component.scss';
import template from './newIdentityConnectionPii.component.html';
import { module } from 'angular';
const app = module('app');

app.component('newidentitypii', {
  template,
  controllerAs: 'newIdentityConnectionModel',
  controller: function (
    $http,
    appSettings,
    $scope,
    $state,
    $stateParams,
    notificationService,
    identityConnectionsService,
    $timeout,
    $rootScope,
    DeleteConfirmation,
  ) {
    'ngInject';

    const newIdentityConnectionModel = this;
    newIdentityConnectionModel.fields = {};
    newIdentityConnectionModel.fieldsDDL = {};

    //Initialize for validation
    $scope.form = $scope.$parent.form;

    const availableTypeOptions = [
      { id: '1', name: 'MySQL' },
      { id: '2', name: 'Postgres' },
      { id: '3', name: 'MS-SQL' },
      { id: '4', name: 'Oracle' },
      { id: '5', name: 'Salesforce' },
    ];

    $scope.DefinePiiSelectionObject = {
      ID: {
        rowSelected: null,
        columnNameSelected: null,
      },
      DisplayName: {
        rowSelected: null,
        columnNameSelected: null,
      },
      Location: {
        rowSelected: null,
        columnNameSelected: null,
      },
    };

    const requiredValues = ['ID', 'DisplayName', 'Location'];

    // --------------------------End Initialize----------------------------------------//

    // -----------------------------Methods For Service--------------------------------//

    //get data of tag attribute list
    function getTagAttributesList() {
      return $http.get(appSettings.serverPath + '/' + appSettings.version + '/tags').then(
        function (response) {
          return response.data.tags;
        },
        function (response) {
          // Handle error here
        },
      );
    }

    //get data of tables name
    function getTablesList() {
      return $http
        .post(appSettings.serverPath + '/' + appSettings.version + '/id_connection_tables/', $scope.id_connection)
        .then(
          function (response) {
            return response.data.tables;
          },
          function (response) {
            // Handle error here
          },
        );
    }

    function testConnection() {
      return $http
        .post(appSettings.serverPath + '/' + appSettings.version + '/id_connection_test/', $scope.id_connection)
        .then(
          function (response) {
            return response.data.test_connection;
          },
          function (response) {
            // Handle error here
          },
        );
    }

    //get define_pii columns by query string
    function getPiiDefineDataFromQuery(queryValue) {
      return $http.get(appSettings.serverPath + '/' + appSettings.version + '/pii_define/').then(
        function (response) {
          $scope.newIdentityConnectionModelGrid.data = response.data;
        },
        function (response) {
          // Handle error here
        },
      );
    }

    //get define_pii columns by table name
    function getPiiDefineData() {
      return $http
        .post(appSettings.serverPath + '/' + appSettings.version + '/id_connection_table_data/', $scope.id_connection)
        .then(
          function (response) {
            if (response.data.table_data) {
              for (let i = 0; i < response.data.table_data.length; i++) {
                const tagObject = $scope.tagDropDownOptions.find(
                  obj => obj.tag_name === response.data.table_data[i].tagAttribute,
                );
                if (tagObject) {
                  response.data.table_data[i].selected = tagObject.tag_id.toString();
                }
              }

              $scope.newIdentityConnectionModelGrid.data = response.data.table_data;
            }
          },
          function (response) {
            // Handle error here
          },
        );
    }

    // -----------------------------End Methods For Service----------------------------//

    // -----------------------------Methods Update Tag Attibutes DDL-------------------//

    const that = this;
    getTagAttributesList().then(function (result) {
      const tagDropDownOptions = result;
      that.ds = tagDropDownOptions.map(obj =>
        Object.assign({}, obj, {
          selectedBy: null,
        }),
      );
      $scope.tagDropDownOptions = that.ds;
    });

    this.updateSelection = row => {
      // deselect previous value
      const toDeselect = this.ds.find(obj => obj.selectedBy === row);
      toDeselect && (toDeselect.selectedBy = null);

      // if a valid option was chosen
      if (row.selected) {
        // find the new value to select
        const toSelect = this.ds.find(obj => obj.tag_id === +row.selected);

        if (toSelect) {
          $rootScope.selectValue = toSelect.tag_name;
          // if the value was selected by another control, reset this control's selection to empty
          toSelect.selectedBy && (toSelect.selectedBy.selected = null);
          // select a value
          if (row.selected !== '0' && contains(requiredValues, toSelect.tag_name)) {
            toSelect.selectedBy = row;
          }
          row.tagAttribute = toSelect.tag_name;
        } else {
          $rootScope.selectValue = null;
        }
      }
    };

    // -------------------------End Methods Update Tag Attibutes DDL-------------------//

    // -----------------------------Helper Methods ------------------------------------//

    function contains(arr, obj) {
      let i = arr.length;
      while (i--) {
        if (arr[i] === obj) {
          return true;
        }
      }
      return false;
    }

    function getSelectedObject(typeName, availableTypeOptions) {
      let id;
      let name;
      for (let i = 0; i < availableTypeOptions.length; i++) {
        if (availableTypeOptions[i].name.toLowerCase() === typeName.toLowerCase()) {
          id = availableTypeOptions[i].id;
          name = availableTypeOptions[i].name;
          break;
        }
      }

      return { id: id, name: name };
    }

    function setSelectedRow(rowEntity, selectedTagValue, newColumnName) {
      let rowSelected;
      switch (selectedTagValue) {
        case 'ID':
          rowSelected = $scope.DefinePiiSelectionObject.ID.rowSelected;
          if (rowSelected) {
            ensureUserChange(
              rowSelected,
              rowEntity.id,
              $scope.DefinePiiSelectionObject.ID.columnNameSelected,
              selectedTagValue,
              newColumnName,
            );
          } else {
            $scope.DefinePiiSelectionObject.ID.rowSelected = rowEntity.id;
            $scope.DefinePiiSelectionObject.ID.columnNameSelected = newColumnName;
          }
          break;
        case 'DisplayName':
          rowSelected = $scope.DefinePiiSelectionObject.DisplayName.rowSelected;
          if (rowSelected) {
            ensureUserChange(
              rowSelected,
              rowEntity.id,
              $scope.DefinePiiSelectionObject.DisplayName.columnNameSelected,
              selectedTagValue,
              newColumnName,
            );
          } else {
            $scope.DefinePiiSelectionObject.DisplayName.rowSelected = rowEntity.id;
            $scope.DefinePiiSelectionObject.DisplayName.columnNameSelected = newColumnName;
          }
          break;
        case 'Location':
          rowSelected = $scope.DefinePiiSelectionObject.Location.rowSelected;
          if (rowSelected) {
            ensureUserChange(
              rowSelected,
              rowEntity.id,
              $scope.DefinePiiSelectionObject.Location.columnNameSelected,
              selectedTagValue,
              newColumnName,
            );
          } else {
            $scope.DefinePiiSelectionObject.Location.rowSelected = rowEntity.id;
            $scope.DefinePiiSelectionObject.Location.columnNameSelected = newColumnName;
          }
          break;
      }
      rowEntity.tag = $rootScope.selectValue;
    }

    function GetTableSchemaData() {
      if (!$scope.id_connection) {
        set_id_connections();
      }
      getPiiDefineData();
      setFlagsForPiiGrid();
    }

    function setFlagsForPiiGrid() {
      newIdentityConnectionModel.isChoosedTable = true;
      newIdentityConnectionModel.piiGridLoading = false;
    }

    // -------------------------End Helper Methods ------------------------------------//

    // -----------------------------PII Define Grid Methods ---------------------------//

    $scope.newIdentityConnectionModelGrid = {
      enableSorting: true,
      enableFiltering: true,
      enableRowSelection: true,
      multiSelect: false,
      enableRowHeaderSelection: false,
      selectionRowHeaderWidth: 35,
      rowEditWaitInterval: -1,
      columnDefs: [
        {
          name: '',
          width: '45',
          enableFiltering: false,
          field: 'isChecked',
          cellTemplate:
            '<label style="margin:4px;" class="ui-switch ui-switch-success ui-switch-sm">' +
            '                                                                               <input type="checkbox" checked ng-model="row.entity.isChecked">' +
            '                                                                                   <i></i>' +
            '                                                                         </label>',
          editableCellTemplate:
            '<label style="margin:4px;" class="ui-switch ui-switch-success ui-switch-sm">' +
            '                                                                               <input type="checkbox" checked ng-model="row.entity.isChecked">' +
            '                                                                                   <i></i>' +
            '                                                                         </label>',
        },
        {
          name: 'Column Name',
          field: 'name',
          cellTemplate:
            '<div style="margin:4px"> {{row.entity.name}}<span style="font-size:13px !important;"></span></div>',
        },
        {
          name: 'Data Sample',
          enableFiltering: false,
          field: 'dataSample',
          cellTemplate:
            '<div style="margin:4px"> {{row.entity.dataSample}}<span style="font-size:13px !important;"></span></div>',
        },

        {
          name: 'tag',
          field: 'tagAttribute',
          displayName: 'Tag Attribute',
          enableFiltering: false,
          cellTemplate:
            '<selectw>' +
            '<div class="tomer">' +
            '<select style="width:95%;margin: 3px 0px 1px 5px; " ng-model="row.entity.selected" ng-change="grid.appScope.newIdentityConnectionModel.updateSelection(row.entity)">' +
            '<option value="">Select One...</option>' +
            '<option ng-repeat="opt in grid.appScope.newIdentityConnectionModel.ds" value="{{opt.tag_id}}"' +
            'ng-class="{ busy: opt.selectedBy && opt.selectedBy !== row.entity }">{{opt.tag_name}}</option>' +
            '</select>' +
            '</div>' +
            '</selectw>',
          width: '20%',
          editDropdownValueLabel: 'tag',
          editDropdownIdLabel: 'tag',
        },
        {
          name: 'Identifiably',
          enableFiltering: false,
          field: 'identifiably',
          cellTemplate:
            '<div style="margin:4px"> {{row.entity.identifiably}}<span style="font-size:13px !important;"></span></div>',
        },
      ],
    };

    $scope.newIdentityConnectionModelGrid.onRegisterApi = function (gridApi) {
      //set gridApi on scope
      $scope.gridApi = gridApi;
      gridApi.edit.on.afterCellEdit($scope, function (rowEntity, colDef, newValue, oldValue) {
        if (colDef.name === 'tag') {
          //if there is value that was selected in the DDL
          if ($rootScope.selectValue) {
            setSelectedRow(rowEntity, $rootScope.selectValue, rowEntity.name);
            rowEntity.tag = $rootScope.selectValue;
          }
          $rootScope.selectValue = '';
        }
      });
    };

    function ensureUserChange(
      previousRowSelected,
      rowNumberNewSelected,
      previousColumnName,
      selectedTagValue,
      newColumnName,
    ) {
      const modalOptions = {
        closeButtonText: 'Cancel',
        actionButtonText: 'Remove',
        headerText: 'Remove Tag',
        bodyText: `Are you sure to remove tag ${selectedTagValue} from column ${previousColumnName}?`,
      };
      DeleteConfirmation.showModal({}, modalOptions).then(
        () => {
          $scope.newIdentityConnectionModelGrid.data[+previousRowSelected - 1].tag = '';
          switch (selectedTagValue) {
            case 'ID':
              $scope.DefinePiiSelectionObject.ID.rowSelected = rowNumberNewSelected;
              $scope.DefinePiiSelectionObject.ID.columnNameSelected = newColumnName;
              break;
            case 'DisplayName':
              $scope.DefinePiiSelectionObject.DisplayName.rowSelected = rowNumberNewSelected;
              $scope.DefinePiiSelectionObject.DisplayName.columnNameSelected = newColumnName;
              break;
            case 'Location':
              $scope.DefinePiiSelectionObject.Location.rowSelected = rowNumberNewSelected;
              $scope.DefinePiiSelectionObject.Location.columnNameSelected = newColumnName;
              break;
          }
          notificationService.success('Your tag has been deleted successfully');
        },
        () => {
          $scope.newIdentityConnectionModelGrid.data[+rowNumberNewSelected - 1].tag = '';
        },
      );
    }

    newIdentityConnectionModel.chooseTableName = function () {
      if (!(newIdentityConnectionModel.fields.query_pii_define || newIdentityConnectionModel.tables.selected)) {
        newIdentityConnectionModel.showTableNotChoosedError = true;
        return;
      }

      newIdentityConnectionModel.showTableNotChoosedError = false;
      newIdentityConnectionModel.piiGridLoading = true;

      GetTableSchemaData();
    };

    // -----------------------------End PII Define Grid Methods ------------------------//

    // -----------------------------Event component Methods ---------------------------//

    newIdentityConnectionModel.$onInit = function () {
      newIdentityConnectionModel.testConnectionSucceeded = false;
      newIdentityConnectionModel.isChoosedTable = false;
    };

    // --------------------------End Event component Methods ---------------------------//

    // ---------------Id Connections Fields Methods (Submit and Test Connection)--------//

    const selectedType = $stateParams.id;
    //case of update
    if (selectedType) {
      newIdentityConnectionModel.headerName = 'Edit Identity Connection';

      identityConnectionsService.getIdentityConnectionsDataByID(selectedType).then(function (result) {
        if (result && result.id_connection) {
          newIdentityConnectionModel.fields.name = result.id_connection.name;

          newIdentityConnectionModel.fieldsDDL.selectedTypeOption = getSelectedObject(
            result.id_connection.type,
            availableTypeOptions,
          ); //result.id_connection.selectedTypeOption;
          newIdentityConnectionModel.fields.db_host = result.id_connection.db_host;
          newIdentityConnectionModel.fields.db_name = result.id_connection.db_name;
          newIdentityConnectionModel.fields.db_table = result.id_connection.db_table;
          newIdentityConnectionModel.fields.db_user = result.id_connection.db_user;
          newIdentityConnectionModel.fields.location = result.id_connection.location;
          newIdentityConnectionModel.fields.custom_query_string = result.id_connection.custom_query_string;
          newIdentityConnectionModel.fields.unique_id = result.id_connection.unique_id;
          newIdentityConnectionModel.fields.residency = result.id_connection.residency;
          newIdentityConnectionModel.fields.display_name = result.id_connection.display_name;
          newIdentityConnectionModel.fields.db_pw = '********';

          if (result.id_connection.piiDefineGridRows) {
            newIdentityConnectionModel.testConnectionSucceeded = true;
            getTablesList().then(function (tablesResult) {
              newIdentityConnectionModel.tables = tablesResult;
              let index = 0;
              if (result.id_connection.selectedTableName) {
                for (let i = 0; i < tablesResult.length; i++) {
                  if (tablesResult[i].name === result.id_connection.selectedTableName) {
                    index = i;
                    break;
                  }
                }
                newIdentityConnectionModel.tables.selected = newIdentityConnectionModel.tables[index];
              }
            });
            newIdentityConnectionModel.isChoosedTable = true;
            $scope.newIdentityConnectionModelGrid.data = result.id_connection.piiDefineGridRows;

            //$scope.newIdentityConnectionModelGrid.data.row.selected = 1;
            if (result.id_connection.query_pii_define) {
              newIdentityConnectionModel.fields.query_pii_define = result.id_connection.query_pii_define;
            }
          }
        }
      });
    }
    //case of insert
    else {
      newIdentityConnectionModel.headerName = 'Create a new Identity Connection';
      newIdentityConnectionModel.fields.db_pw = '';
    }

    newIdentityConnectionModel.type = {
      availableOptions: availableTypeOptions,
    };

    newIdentityConnectionModel.fieldsDDL.selectedTypeOption = {
      id: '1',
      name: 'MySQL', //This sets the default value of the select in the ui
    };

    function checkRequiredTagAttributes(rowsData) {
      return ['1', '2', '3'].every(function (val) {
        return rowsData.indexOf(val) >= 0;
      });
    }

    newIdentityConnectionModel.submit = function (isValid) {
      //check if pii define grid is exist
      if ($scope.newIdentityConnectionModelGrid.data) {
        const dataDirtyRows = $scope.newIdentityConnectionModelGrid.data.map(function (gridRow) {
          return gridRow.selected;
        });

        if (!checkRequiredTagAttributes(dataDirtyRows)) {
          const modalOptions = {
            closeButtonText: 'Cancel',
            actionButtonText: 'Confirm',
            headerText: 'Save Identity Connection',
            bodyText:
              'Are you sure to save Identity Connection without required Tag Attributes (ID, DisplayName and Location)',
          };
          DeleteConfirmation.showModal({}, modalOptions).then(() => {
            postDSConnection(isValid, $scope.newIdentityConnectionModelGrid.data);
          });
        } else {
          postDSConnection(isValid, $scope.newIdentityConnectionModelGrid.data);
        }
      } else {
        postDSConnection(isValid);
      }
    };

    function postDSConnection(isValid, piiDefineGridRowData) {
      if (isValid) {
        //create $scope.id_connection object to save or update
        set_id_connections(piiDefineGridRowData);

        //case of update
        if ($stateParams.id) {
          $http
            .put(
              appSettings.serverPath + '/' + appSettings.version + '/id_connections/' + $stateParams.id,
              $scope.id_connection,
            )
            .then(function (response) {
              notificationService.success('Updated ' + $stateParams.id + ' Successfully! ');
              $state.go('entitySources');
            });
        }

        //case of insert
        else {
          //http://localhost:3000 / api/v1 / id_connections
          $http
            .post(appSettings.serverPath + '/' + appSettings.version + '/id_connections', $scope.id_connection)
            .then(function (response) {
              notificationService.success('Saved Successfully! ');
              $state.go('entitySources');
            });
        }
      } else {
        newIdentityConnectionModel.inputNotValid = true;
      }
    }

    newIdentityConnectionModel.testConnection = function () {
      set_id_connections();
      //set tables name in DDL
      testConnection().then(
        function (resultTestConnection) {
          notificationService.success('Test Connection Success! ');
          newIdentityConnectionModel.testConnectionSucceeded = true;
          newIdentityConnectionModel.inputNotValid = true;
          if (resultTestConnection.isSuccessful) {
            getTablesList().then(function (result) {
              newIdentityConnectionModel.tables = result;
            });
          }
        },
        function (resultTestConnection) {
          // Handle error here
          notificationService.error('Test Connection Failed! ' + resultTestConnection.message);
        },
      );
    };

    function set_id_connections(piiDefineGridRowData) {
      $scope.id_connection = {};

      if (piiDefineGridRowData) {
        newIdentityConnectionModel.fields.piiDefineGridRows = [];

        for (let i = 0; i < piiDefineGridRowData.length; i++) {
          newIdentityConnectionModel.fields.piiDefineGridRows.row = {};
          if (piiDefineGridRowData[i].isChecked) {
            if (piiDefineGridRowData[i].tagAttribute) {
              newIdentityConnectionModel.fields.piiDefineGridRows.row.tagAttribute =
                piiDefineGridRowData[i].tagAttribute;
            }
            newIdentityConnectionModel.fields.piiDefineGridRows.row.id = piiDefineGridRowData[i].id;
            newIdentityConnectionModel.fields.piiDefineGridRows.row.identifiably = piiDefineGridRowData[i].identifiably;
            newIdentityConnectionModel.fields.piiDefineGridRows.row.name = piiDefineGridRowData[i].name;
            newIdentityConnectionModel.fields.piiDefineGridRows.row.dataSample = piiDefineGridRowData[i].dataSample;
            newIdentityConnectionModel.fields.piiDefineGridRows.row.isChecked = piiDefineGridRowData[i].isChecked;

            if (piiDefineGridRowData[i].selected) {
              newIdentityConnectionModel.fields.piiDefineGridRows.row.selected = piiDefineGridRowData[i].selected;
            }
            newIdentityConnectionModel.fields.piiDefineGridRows.push(
              newIdentityConnectionModel.fields.piiDefineGridRows.row,
            );
          }
        }
        newIdentityConnectionModel.fields.selectedTableName = newIdentityConnectionModel.tables.selected.name;
      }

      if (newIdentityConnectionModel.fields.db_pw === '********') {
        newIdentityConnectionModel.fields.db_pw = null;
      } else if (newIdentityConnectionModel.fields.db_pw === '') {
        delete newIdentityConnectionModel.fields.db_pw;
      }
      $scope.id_connection.id_connection = angular.copy(newIdentityConnectionModel.fields);
      $scope.id_connection.id_connection.type =
        newIdentityConnectionModel.fieldsDDL.selectedTypeOption.name.toLowerCase();
    }

    // ------------End Id Connections Fields Methods (Submit and Test Connection)--------//
  },
});
