import React, { useState, useEffect } from 'react';

interface FetchingOptions {
  forceUpdate?: boolean;
  skipFetching?: boolean;
}

export const useFetchEntity = (
  fetcherFunction: () => Promise<unknown>,
  { forceUpdate = false, skipFetching = false }: FetchingOptions = {},
) => {
  const [entity, setEntity] = useState(undefined);

  useEffect(() => {
    // Sometimes is required to refetch the API to update the entity.
    // In this case pass forceUpdate: true prop.
    // In other cases it's required not to fetch the API, where skipFetching: true is used.
    if ((entity && !forceUpdate) || skipFetching) {
      return;
    }
    (async () => {
      const entity = await fetcherFunction();
      setEntity(entity);
    })();
  }, [entity, fetcherFunction, forceUpdate, skipFetching]);

  return entity;
};
