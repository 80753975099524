import { useEffect, useMemo, useState } from 'react';
import { getEventEmitter } from '../config/toolbar';
import { UseToolbarEvent } from '../types';
import { uniqueId } from 'lodash';
import type { BigidFilterToolbarProps, BigidFieldFilter } from '@bigid-ui/components';

const emitter = getEventEmitter();

export const useToolbar = (config?: Pick<BigidFilterToolbarProps, 'searchConfig' | 'filters'>, id?: string) => {
  const [toolbarId] = useState(id ?? uniqueId());
  const [activeFilters, setActiveFilters] = useState<BigidFieldFilter[]>([]);
  const [searchTerm, setSearchTerm] = useState<string>('');

  const handleFilterUpdate = (filters: BigidFieldFilter[]) => {
    // @info omit search box filters
    const omitted = config?.searchConfig?.searchFilterKeys ?? [];
    const searchFilter = filters.find(({ field }) => omitted.includes(field));
    const searchTerm = (searchFilter?.value as string) ?? config?.searchConfig?.initialValue;

    setActiveFilters(filters);
    setSearchTerm(searchTerm);
  };

  const actions = useMemo(
    () => ({
      updateFilters: (filters: BigidFieldFilter[]) =>
        emitter.emit(UseToolbarEvent.UPDATE_FILTERS, { filters, entityId: toolbarId }),
      clearFilters: () =>
        emitter.emit(UseToolbarEvent.CLEAR_FILTERS, {
          entityId: toolbarId,
        }),
    }),
    [toolbarId],
  );

  useEffect(() => {
    const unregisterActions = [
      emitter.addEventListener(
        UseToolbarEvent.UPDATE_FILTERS,
        ({ filters, entityId }) => entityId === toolbarId && handleFilterUpdate(filters),
      ),
      emitter.addEventListener(
        UseToolbarEvent.CLEAR_FILTERS,
        ({ entityId }) => entityId === toolbarId && handleFilterUpdate([]),
      ),
    ];
    return () => unregisterActions.forEach(unregister => unregister());
  }, [toolbarId, config]);

  return { config, ...emitter, id: toolbarId, actions, activeFilters, searchTerm };
};
