import { createContext, RefObject } from 'react';
import { BigidFormRenderProps } from '@bigid-ui/components';
import {
  DataSourceConfigurationState,
  DEFAULT_CONFIG_DS_STATE,
  UpdateDataSourceConfigState,
} from './hooks/useDataSourceConfigState';
import { OnSubmitDataSourceType } from './hooks/useSubmitDataSource';
import { RefreshDataSourceDataType } from './hooks/useGetDataSourceByName';
import { OAuthHandlers } from '../DataSourceConnectionModal/hooks/useOAuth';

export interface DataSourceConfigurationContextInterface extends DataSourceConfigurationState, OAuthHandlers {
  updateState?: UpdateDataSourceConfigState;
  onSubmitHandler?: OnSubmitDataSourceType;
  isSaveAvailable?: boolean;
  refreshData?: RefreshDataSourceDataType;
  isTouched?: RefObject<boolean>;
  getValuesContainer?: RefObject<BigidFormRenderProps['getValues']>;
  resetValuesToInitFunction?: RefObject<BigidFormRenderProps['clear']>;
  createSetValueFunction?: RefObject<BigidFormRenderProps['setValue']>;
}

export const DataSourceConfigurationContext =
  createContext<DataSourceConfigurationContextInterface>(DEFAULT_CONFIG_DS_STATE);
