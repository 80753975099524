import { BigidFormField } from '@bigid-ui/components';
import React, { FC, useMemo } from 'react';
import { BigidFormExtraField } from '../FormSection/BigidFormExtraField';
import { FormSection, FormSectionInitialStateProps } from '../FormSection/FormSection';
import { useReportTemplateContext } from '../hooks/ReportTemplateContext';
import { BigidFormExtraCountriesField } from '../FormSection/BigidFormExtraCountriesField';

export const GeoDataTransferSection: FC = () => {
  const sectionName = 'geoDataTransfer';
  const description = 'Display where we collect the data.';

  const { initialTemplateData } = useReportTemplateContext();

  const formSectionInitProps: FormSectionInitialStateProps = useMemo(() => {
    const {
      geoDataTransfer: { ...initialValues },
    } = initialTemplateData;

    const fields: BigidFormField[] = [
      {
        render: BigidFormExtraCountriesField,
        name: 'countries',
        label: '',
        misc: {
          placeholder: 'Select a Country',
          fullWidth: true,
          addLabel: 'Add Country',
          initialValues,
        },
      },
    ];

    const newInitProps: FormSectionInitialStateProps = {
      fields,
      initialValues,
    };

    return newInitProps;
  }, [initialTemplateData]);

  return <FormSection sectionName={sectionName} description={description} {...formSectionInitProps} />;
};
