import { EmailTemplate } from '../types/emailTemplatesTypes';
import { getFixedT } from '../translations';
import { openSystemDialog } from '../../../services/systemDialogService';
import { EmailTemplateDuplicateDialog } from './DuplicationDialogContent';
import { noop } from 'lodash';

export const filterOutTemplate = (templates: any[], template: EmailTemplate) => {
  return templates.filter(currTemplate => currTemplate.name !== template.name);
};

export const handleDuplicateButtonClick = async (
  emailTemplate: EmailTemplate,
  onSuccessfulDuplication: (emailTemplate: EmailTemplate) => void,
) => {
  const t = getFixedT('TemplatesManager.duplicate');

  const contentProps = {
    template: emailTemplate,
    onSuccessfulDuplication: onSuccessfulDuplication,
  };

  openSystemDialog({
    title: t('title'),
    content: EmailTemplateDuplicateDialog,
    contentProps,
    onClose: noop,
    showCloseIcon: true,
    borderTop: true,
    maxWidth: 'xs',
  });
};

export const filterTemplatesByName = (templates: EmailTemplate[], searchInput: string): EmailTemplate[] => {
  return templates.filter(template => template.name.toLowerCase().includes(searchInput.toLowerCase()));
};
