import { LICENSE_TYPES, licenseService } from './licenseService';
import { getApplicationPreference } from './appPreferencesService';
import { docsUrls, publicUrls } from '../config/publicUrls';

interface ProductTypeProperties {
  type: LICENSE_TYPES | 'default';
  supportLink: string;
  docsLink: string;
  translationFileName?: string;
  translationPostfix: string;
}

const DEFAULT = 'default';

const getProductTypeProperties = (): ProductTypeProperties[] => {
  return [
    {
      type: DEFAULT,
      supportLink: publicUrls.SUPPORT_BIGID,
      docsLink: docsUrls.DOCS,
      translationPostfix: '',
      translationFileName: 'basic',
    },
    {
      type: LICENSE_TYPES.DDM,
      supportLink: getApplicationPreference('USE_SAAS')
        ? publicUrls.LAUNCHPAD_SUPPORT_SAP_INCIDENT_CREATE
        : publicUrls.SUPPORT_SAP,
      docsLink: docsUrls.DOCS,
      translationPostfix: '_%%DDM%%',
      translationFileName: 'ddm',
    },
  ];
};

export const getTranslationFileNames = () => {
  return getProductTypeProperties().map(({ translationFileName }) => translationFileName);
};

export const getProductProperties = () => {
  const productType = licenseService.getLicenseType();
  const product = getProductTypeProperties().find(({ type }) => type === productType);
  return product || getProductTypeProperties().find(({ type }) => type === DEFAULT);
};
