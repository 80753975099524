import { EventEmitter } from '@bigid-ui/utils';

export enum DsarEvents {
  CREATE_STATIC_RECORD = 'createStaticRecord',
  UPLOAD_FILE = 'uploadFile',
  OPEN_EDIT_CELL_DIALOG = 'openEditCellDialog',
  OPEN_IMPORT_FIELDS_DIALOG = 'openImportFieldsDialog',
}

export const dsarEventEmitter = new EventEmitter<DsarEvents>();
