import { createContext, useContext } from 'react';
import { ReportTemplate } from '../Types';
import { ReportTemplateFormReducerAction } from './ReportTemplateReducer';

export interface ReportTemplateContextState {
  templateId: string;
  initialTemplateData: Partial<ReportTemplate>;
  hasEditPermission: boolean;
  dispatch: React.Dispatch<ReportTemplateFormReducerAction>;
}

export const ReportTemplateContext = createContext<ReportTemplateContextState>(null);

export type ReportTemplateFormData = Partial<ReportTemplate>;

export function useReportTemplateContext() {
  const context = useContext(ReportTemplateContext);
  if (!context) {
    console.error('useReportTemplateContext must be used within a ReportTemplateContext.Provider');
  }
  return context;
}
