import React, { FC, useMemo, useState } from 'react';
import { BigidHorizontalBarWithActionsRow } from './BigidHorizontalBarWithActionsRow';
import { BigidColorsV2, BigidMenuItemProps } from '@bigid-ui/components';
import styled from '@emotion/styled';

export interface BigidHorizontalBarWithActionsItem {
  name: string;
  value: number;
  valueLabel?: string;
  color?: string;
  tooltip?: {
    left?: string;
    right?: string;
    bottom?: string;
  };
}

export interface RowWithActions {
  row: BigidHorizontalBarWithActionsItem;
  actions: BigidMenuItemProps[];
}

export interface BigidHorizontalBarWithActionsProps {
  rowsWithActions: RowWithActions[];
}
const DefaultPalette = ['#FECCFF', '#B1D5FF', '#AEFFDF', BigidColorsV2.purpleBlue[200]];
export const infinitePalette = (index: number) => {
  while (!DefaultPalette[index]) {
    index = index - DefaultPalette.length;
  }
  return DefaultPalette[index];
};

const GridWrapper = styled('div')`
  display: grid;
  grid-template-columns: 3fr auto minmax(100px, 1fr);
  gap: 10px;
  align-items: center;
  padding: 24px;
`;
export const BigidHorizontalBarWithActions: FC<BigidHorizontalBarWithActionsProps> = ({ rowsWithActions }) => {
  const [hoveredElement, setHoveredElement] = useState<number>(-1);
  const longestBarLength = useMemo(
    () => (rowsWithActions ?? []).map(({ row: { value } }) => value).reduce((a, b) => Math.max(a, b), 0),
    [rowsWithActions],
  );

  return (
    <GridWrapper>
      {rowsWithActions?.length > 0 &&
        rowsWithActions.map((rowWithActions, index) => (
          <BigidHorizontalBarWithActionsRow
            rowWithActions={rowWithActions}
            key={index}
            longestBarLength={longestBarLength}
            hoveredElement={hoveredElement}
            setHoveredElement={setHoveredElement}
            index={index}
          />
        ))}
    </GridWrapper>
  );
};
