import React, { Fragment, useState, useEffect, FC } from 'react';
import { PROFILE_ACTION, ProfileManagementDialog } from './ProfileManagementDialog';
import { SarProfile } from '../ProfileSettingsTypes';

export interface SarProfileType
  extends Pick<
    SarProfile,
    'allEnabledDs' | 'allEnabledEs' | 'name' | '_id' | 'isCustom' | 'scopes' | 'shouldAttributesEnrichment'
  > {}

export interface ProfileManagementProps {
  profiles: SarProfileType[];
  profileId: string;
  isOpen: boolean;
  actionType: PROFILE_ACTION;
  onActionSuccess: (actionType: PROFILE_ACTION, profileId: string) => void;
}

export const ProfileManagement: FC<ProfileManagementProps> = ({
  profiles,
  profileId,
  isOpen,
  actionType,
  onActionSuccess,
}) => {
  const defaultProfile = profiles.find(profile => !profile.isCustom);
  const [dialogState, setDialogState] = useState({
    onActionSuccess: null,
    isOpen: false,
    actionType: null,
  });
  const onCloseDialog = () => setDialogState({ isOpen: false, actionType: null, onActionSuccess: null });
  const profileChosen = profiles.find(profile => profile._id === profileId);

  useEffect(() => {
    setDialogState({ isOpen, actionType, onActionSuccess });
  }, [isOpen, actionType, onActionSuccess]);

  return (
    <Fragment>
      <ProfileManagementDialog
        {...dialogState}
        onClose={onCloseDialog}
        profile={profileChosen}
        defaultProfileId={defaultProfile?._id}
        profileNames={profiles.map(profile => profile.name)}
      />
    </Fragment>
  );
};
