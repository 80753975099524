import {
  BigidLoader,
  BigidSelect,
  PrimaryButton,
  SecondaryButton,
  BigidBody1,
  BigidBody3,
  BigidHeading6,
  BigidSelectOption,
  BigidRadioGroup,
} from '@bigid-ui/components';
import { FormControl } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React, { ChangeEvent, FC, useEffect, useState } from 'react';
import { notificationService } from '../../../../services/notificationService';
import { openSystemDialog, SystemDialogContentProps } from '../../../../services/systemDialogService';
import { getAllTemplates } from '../reportTemplatesService';
import { httpService } from '../../../../services/httpService';
import { getApplicationPreference } from '../../../../services/appPreferencesService';
import { SummaryReportType } from '../Types';

export interface DialogState {
  selectedTemplate: BigidSelectOption[];
  selectedType: SummaryReportType;
}

export interface DownloadSummaryReportDialogProps {
  onStateChange: (state: Partial<DialogState>) => void;
}

const useStyles = makeStyles(theme => ({
  description: {
    marginBottom: '15px',
  },
  heading: {
    marginBottom: '10px',
  },
  form: {
    maxHeight: '25px',
    marginTop: '5px',
  },
  radioWrapper: { display: 'flex', flexDirection: 'column', marginTop: '10px', position: 'relative', zIndex: 0 },
}));

export const DownloadSummaryReportDialogContent: FC<SystemDialogContentProps<DownloadSummaryReportDialogProps>> = ({
  onStateChange,
}) => {
  const classes = useStyles({});
  const [allTemplates, setAllTemplates] = useState<BigidSelectOption[]>([]);
  const [selectedTemplate, setSelectedTemplate] = useState<BigidSelectOption[]>();
  const [selectedType, setSelectedType] = useState<SummaryReportType>(SummaryReportType.PDF);
  const isAllowCsvSummaryReport = getApplicationPreference('DSAR_ALLOW_CSV_SUMMARY_REPORT');
  useEffect(() => {
    const fetchTemplates = async () => {
      const data = (await getAllTemplates(['templateName'])) || [];
      if (data.length) {
        const mappedTemplates: BigidSelectOption[] = data.map(template => {
          const { id, templateName } = template;
          return {
            label: templateName,
            value: id,
          };
        });
        setAllTemplates(mappedTemplates);
        setSelectedTemplate([mappedTemplates[0]]);
        onStateChange({ selectedTemplate: [mappedTemplates[0]], selectedType: SummaryReportType.PDF });
      }
    };
    fetchTemplates();
  }, [onStateChange]);

  const onTemplateSelect = (selectedValue: BigidSelectOption[]) => {
    setSelectedTemplate(selectedValue);
    onStateChange({ selectedTemplate: selectedValue });
  };

  const onTypeSelect = (selectedType: string) => {
    setSelectedType(selectedType as SummaryReportType);
    onStateChange({ selectedType: selectedType as SummaryReportType });
  };

  return !allTemplates.length ? (
    <BigidLoader />
  ) : (
    <div>
      <div>
        <BigidBody3 gutterBottom className={classes.description}>
          Choose a template to download your report.
        </BigidBody3>
        <BigidHeading6 gutterBottom className={classes.heading}>
          Report template
        </BigidHeading6>
        <BigidBody1>Select template</BigidBody1>
        <FormControl fullWidth margin="normal" className={classes.form}>
          <BigidSelect
            name={'templateNamesDropDown'}
            options={allTemplates}
            onChange={onTemplateSelect}
            value={selectedTemplate}
            menuPosition="fixed"
            dataAid={'select-template-dropdown'}
          />
        </FormControl>
      </div>
      {isAllowCsvSummaryReport && (
        <div className={classes.radioWrapper}>
          <BigidBody1>{'File Type'}</BigidBody1>
          <BigidRadioGroup
            value={selectedType}
            horizontal
            onChange={(event: ChangeEvent<HTMLInputElement>) => onTypeSelect(event.target.value)}
            options={[
              {
                label: 'PDF',
                value: SummaryReportType.PDF,
              },
              {
                label: 'CSV',
                value: SummaryReportType.CSV,
              },
            ]}
          />
        </div>
      )}
    </div>
  );
};

export const openDownloadPdfReportTemplateDialog = async (userId = '') => {
  return new Promise<{ wasCreated: boolean }>(resolve => {
    let stateRef: DialogState;
    openSystemDialog({
      title: 'Download Report',
      content: DownloadSummaryReportDialogContent,
      contentProps: {
        onStateChange: dialogState => {
          stateRef = { ...stateRef, ...dialogState };
        },
      },
      onClose: () => resolve({ wasCreated: false }),
      borderTop: true,
      maxWidth: 'xs',
      buttons: [
        {
          text: 'Cancel',
          component: SecondaryButton,
          onClick: () => null,
          dataAid: 'cancel-button',
          isClose: true,
        },
        {
          text: 'Download',
          component: PrimaryButton,
          onClick: async () => {
            try {
              const { selectedTemplate = [], selectedType } = stateRef;
              if (selectedTemplate.length) {
                const { value: templateId } = selectedTemplate[0];
                await httpService.downloadFile(
                  `/file-download/sar/reports/${userId}/summary-report?templateId=${templateId}&format=${selectedType}`,
                );
                notificationService.success('Report is being generated, this might take a while');
                resolve({ wasCreated: true });
              } else {
                notificationService.error('No Template was selected, please try again.');
              }
            } catch (err) {
              const errMessage = `Failed to download report template, err: ${err}`;
              console.error(errMessage);
              notificationService.error(errMessage, err);
            }
          },
          dataAid: 'download-button',
          isClose: true,
        },
      ],
    });
  });
};
