import { DataSourceModel } from '../../../DataSources/DataSourceConnections/DataSourceConnectionTypes';
import { useCallback, useState } from 'react';

export const useIsSelectedDsDisabled = () => {
  const [isSelectedDsDisabled, setIsSelectedDsDisabled] = useState(false);
  const checkSelectedDsForDisabled = useCallback((dataSources: DataSourceModel[]) => {
    const isDisabled = dataSources.reduce(
      (isDisabledSelected, { enabled }) => isDisabledSelected || enabled !== 'yes',
      false,
    );
    setIsSelectedDsDisabled(isDisabled);
  }, []);

  return { isSelectedDsDisabled, checkSelectedDsForDisabled };
};
