import { styled } from '@mui/material';
import React from 'react';
import { useDataCatalogSearchResultsContext } from '../../contexts/dataCatalogSearchResultsContext';
import { BigidToolbar } from '@bigid-ui/components';
import { useLocalTranslation } from '../../translations';

const ControlsContainer = styled('div')`
  border-bottom: 1px solid ${({ theme }) => theme.palette.divider};
`;

export const AlienEntityControls = () => {
  const {
    state: { gridConfig },
  } = useDataCatalogSearchResultsContext();
  const { t } = useLocalTranslation('actions');
  const { totalCount } = gridConfig;

  return (
    <ControlsContainer>
      <BigidToolbar
        totalRows={totalCount}
        entityName={t('countEntityNames.items')}
        shareIconConfig={{
          getIsDisabled: () => false,
          getIsShown: () => true,
          getShareUrl: () => {
            return window.location.href;
          },
        }}
      />
    </ControlsContainer>
  );
};
