import React, { FC, useMemo } from 'react';
import { isEqual, cloneDeep } from 'lodash';
import {
  CustomDashboardGeneratorProps,
  CustomDashboardGenerator,
  RouterParams,
  CustomDashboardConfig,
} from '@bigid-ui/custom-dashboard';
import { gridConfig } from '../gridConfig';
import { sessionStorageService } from '../../../../common/services/sessionStorageService';
import { DashboardRightSection } from '../../Dashboard/DashboardRightSection/DashboardRightSection';
import { DashboardBottomSection } from '../../Dashboard/DashboardBottomSection/DashboardBottomSection';
import { $state } from '../../../services/angularServices';
import { CUSTOM_DASHBOARD_WRAPPER_CLASS_NAME } from '../../../services/customDashboardService';
import { ExternalAppForCustomDashboard } from '../../CustomApp/components/ExternalAppForCustomDashboard';
import { ListCasesBySeverityWidget, CasesTrendWidget, TopCriticalCasesWidget } from '../ActionableInsightsWidgets';

export interface CustomDashboardContainerProps {
  config: CustomDashboardConfig;
}

const configPropIsEqual = (
  { config: previousConfig }: CustomDashboardContainerProps,
  { config: nextConfig }: CustomDashboardContainerProps,
) => {
  return isEqual(previousConfig, nextConfig);
};

const CustomDashboardContainerComponent: FC<CustomDashboardContainerProps> = ({ config }) => {
  const customDashboardGeneratorProps: CustomDashboardGeneratorProps = useMemo(
    () => ({
      catalogGridConfig: gridConfig,
      globalParams: {
        internalApiHeaders: {
          authorization: sessionStorageService.get('bigIdTokenID'),
        },
      },
      config: cloneDeep(config),
      predefinedWidgets: [
        {
          id: 'trendsAndRisks',
          widget: (
            <div>
              <DashboardRightSection />
            </div>
          ),
        },
        {
          id: 'insights',
          widget: (
            <div>
              <DashboardBottomSection />
            </div>
          ),
        },
        {
          id: 'criticalCases',
          widget: (
            <div>
              <TopCriticalCasesWidget />
            </div>
          ),
        },
        {
          id: 'trendCases',
          widget: (
            <div>
              <CasesTrendWidget />
            </div>
          ),
        },
        {
          id: 'casesBySeverity',
          widget: (
            <div>
              <ListCasesBySeverityWidget />
            </div>
          ),
        },
      ],
      ExternalAppComp: ExternalAppForCustomDashboard,
    }),
    [config],
  );

  const handleRouteChange = (route: RouterParams) => {
    const url: string = route.url as string;
    const state: string = route.state as string;
    const { data = {} } = route;

    if (url) {
      try {
        if (new URL(url).origin !== location.origin) {
          window.open(url, '_blank');
        } else {
          window.location.href = url;
        }
      } catch (err) {
        window.location.href = `#/${url}`;
      }
    }

    if (state) {
      $state.go(state, data);
    }
  };

  return (
    <div className={CUSTOM_DASHBOARD_WRAPPER_CLASS_NAME}>
      <CustomDashboardGenerator {...customDashboardGeneratorProps} onRouteChange={handleRouteChange} />
    </div>
  );
};
export const CustomDashboardContainer = React.memo(CustomDashboardContainerComponent, configPropIsEqual);
