import { RequestType, ApiCallForm } from '@bigid-ui/components';
import { v4 as uuid } from 'uuid';
import { CertificateFormType } from './CustomCredentialApiCallDialog';

export const defaultApiCallFormValue = { url: '', type: RequestType.GET, useCertificate: false };
export const emptyParam = { '': '' };
export type ApiCallConfigForm = ApiCallForm & CertificateFormType;

export interface VaultConnectionAPIConfiguration {
  url: string;
  type: RequestType;
  queryParams?: Record<string, string>;
  headers?: Record<string, string>;
  payload?: Record<string, any>;
  useCertificate: boolean;
  allowSelfSignCertificate?: boolean;
  clientCert?: string;
  clientKeyCert?: string;
}

export interface CustomCredentialApiCallProps {
  isOpen: boolean;
  onClose: () => void;
  value: VaultConnectionAPIConfiguration;
  onSaveApiConfig: (apiCall: VaultConnectionAPIConfiguration) => void;
}

export const removeEmptyExtraField = ({ queryParams = [], headers = [], ...form }: ApiCallConfigForm) => {
  return {
    ...form,
    queryParams: queryParams.filter(({ value: { name, value } }) => name || value),
    headers: headers.filter(({ value: { name, value } }) => Boolean(name || value)),
  };
};

export const changeNameValueObjectToExtraField = (nameValueObject: Record<string, string>) =>
  Object.keys(nameValueObject).map(key => ({ id: uuid(), value: { name: key, value: nameValueObject[key] } }));

export const convertApiCallConfigToFormFormat = ({
  payload = {},
  headers = emptyParam,
  queryParams = emptyParam,
  type = RequestType.GET,
  ...rest
}: VaultConnectionAPIConfiguration): ApiCallConfigForm => ({
  ...rest,
  type,
  ...(payload && { payload: JSON.stringify(payload, null, '\t') }),
  ...(headers && { headers: changeNameValueObjectToExtraField(headers) }),
  ...(queryParams && {
    queryParams: changeNameValueObjectToExtraField(queryParams),
  }),
});
