import React, { FC, lazy, Suspense } from 'react';
import { BigidLoader } from '@bigid-ui/components';

const DataSourceOnboardingCategoriesGrid = lazy(
  () => import(/* webpackChunkName: "SelectOnboardingTypeGrid" */ './SelectOnboardingTypeGrid'),
);

export const SelectOnboardingTypeGridLazy: FC = () => {
  return (
    <Suspense fallback={<BigidLoader />}>
      <DataSourceOnboardingCategoriesGrid />
    </Suspense>
  );
};
