import React from 'react';
import {
  AccordionSummarySizeEnum,
  BigidAccordion,
  BigidAccordionDetails,
  BigidAccordionSummary,
  BigidColorsV2,
  BigidFormRenderProps,
  BigidHeading6,
} from '@bigid-ui/components';
import { styled } from '@mui/material';
import { RegexFormFields } from '../../types/ClassifierTypes';
import { useLocalTranslation } from '../../translations';
import { generateDataAid } from '@bigid-ui/utils';

export const BigidAccordionSummaryContainer = styled(BigidAccordionSummary)`
  min-height: 66px !important;
  background: ${BigidColorsV2.gray[100]};
`;

export const BigidAccordionDetailsContainer = styled(BigidAccordionDetails)`
  padding: 12px 20px 20px !important;

  fieldset:last-child {
    margin-bottom: 0;
  }
`;

interface AdvancedOptionsProps {
  renderField: BigidFormRenderProps['renderField'];
  isPiiFindingSelected: boolean;
}

export const AdvancedOptions = ({ renderField, isPiiFindingSelected }: AdvancedOptionsProps) => {
  const { t } = useLocalTranslation('form');
  return (
    <BigidAccordion
      dataAid={generateDataAid('BigidAccordion', ['advanced-options'])}
      hasBorder={false}
      hasShadow={false}
    >
      <BigidAccordionSummaryContainer size={AccordionSummarySizeEnum.medium}>
        <BigidHeading6>{t('advanced_options_title')}</BigidHeading6>
      </BigidAccordionSummaryContainer>
      <BigidAccordionDetailsContainer>
        {renderField(RegexFormFields.report_as_classification)}
        {renderField(RegexFormFields.report_as_pii_finding)}
        {isPiiFindingSelected && renderField(RegexFormFields.ignored)}
      </BigidAccordionDetailsContainer>
    </BigidAccordion>
  );
};
