import React, { FC } from 'react';
import { BigidBody1, BigidLink, LinkColor } from '@bigid-ui/components';
import { useLocalTranslation } from '../translations';
import { docsUrls } from '../../../config/publicUrls';
import styled from '@emotion/styled';

const DialogBody = styled('p')({
  flexDirection: 'column',
});

const UsageReportDialogContent: FC = () => {
  const { t } = useLocalTranslation('usageReport.body');
  return (
    <DialogBody>
      <BigidBody1 marginBottom={'16px'}>{t('summaryLine')}</BigidBody1>
      <BigidBody1 display={'inline'}>{t('learnMoreLineBeginning')}</BigidBody1>
      <BigidLink
        color={LinkColor.PRIMARY}
        href={docsUrls.MANAGED_VOLUME_DOC}
        shouldOpenNewTab
        text={t('managedVolume')}
        underline="always"
      />
      <BigidBody1 display={'inline'}>{t('and')}</BigidBody1>
      <BigidLink
        color={LinkColor.PRIMARY}
        href={docsUrls.LOGICAL_DATA_SOURCE_COUNT_DOC}
        shouldOpenNewTab
        text={t('logicalDataSourceCount')}
        underline="always"
      />
      <BigidBody1 display={'inline'}>{t('learnMoreLineEnding')}</BigidBody1>
    </DialogBody>
  );
};

export const usageReportDialogContentElement = React.createElement(UsageReportDialogContent);
