import './tabSet.component.scss';
import template from './tabSet.component.html';

import { module } from 'angular';
const app = module('app');

app.component('tabSet', {
  template,
  controller: function () {
    /**
     * tabs
     * @type {tabName: tabTitle}
     */
    this.tabs = {};

    /**
     *  active tab
     *  @type: <String>
     */
    this.active = null;

    /**
     *  disabled tabs
     *  @type: [<String>]
     */
    this.disabled = [];

    /**
     *  on tab select event handler
     *  @param: tabName <String>
     */
    this.onTabSelected = tabName => {
      this.onSelect({ tabName });
    };
  },
  bindings: {
    active: '<',
    disabled: '<',
    tabs: '<',
    onSelect: '&',
  },
});
