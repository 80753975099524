export const EVENTS = {
  callToSubmitSarRequest: 'callToSubmitSarRequest',
  onSarBulkFileUploaded: 'onSarBulkFileUploaded',
};

export const REQUEST_TYPES = {
  bulk: 'bulk',
  single: 'single',
  manual: 'manual',
};
