import React, { FC, memo, MouseEvent, useRef } from 'react';
import { Divider, Paper } from '@mui/material';
import { BigidXIcon, BigidSearchIcon, BigidAdvanceSearchIcon } from '@bigid-ui/icons';
import { BigidNotes2, BigidLoader, BigidButtonIcon } from '@bigid-ui/components';
import { MetadataSearchProps } from '../MetadataSearch';
import { UseMetadataSearchStateResponse } from '../useMetadataSearchState';
import { MetadataSearchInput } from '../MetadataSearchInput/MetadataSearchInput';
import { useStyles } from './MetadataSearchBarStyles';
import classNames from 'classnames';

export interface MetadataSearchBarProps
  extends Pick<
      UseMetadataSearchStateResponse,
      | 'isReady'
      | 'query'
      | 'filters'
      | 'isFiltersModeOn'
      | 'isInputFocused'
      | 'onSearchIconClick'
      | 'onSearchInputBlur'
      | 'onSearchInputFocus'
      | 'onSearchInputChange'
      | 'onSearchInputKeyPress'
      | 'onResetIconClick'
      | 'onFilterTriggerClick'
    >,
    Pick<
      MetadataSearchProps,
      'placeholder' | 'baseBackgroundColor' | 'focusedBorderColor' | 'variant' | 'isDisabled' | 'isFilterHidden'
    > {
  dataAid: string;
}

export const MetadataSearchBar: FC<MetadataSearchBarProps> = memo(
  ({
    dataAid,
    isReady,
    query,
    filters,
    placeholder,
    isInputFocused,
    isFiltersModeOn,
    onSearchInputFocus,
    onSearchIconClick,
    onSearchInputBlur,
    onSearchInputChange,
    onSearchInputKeyPress,
    onResetIconClick,
    onFilterTriggerClick,
    baseBackgroundColor,
    focusedBorderColor,
    variant,
    isDisabled,
    isFilterHidden,
  }) => {
    const classes = useStyles({ isFocused: isInputFocused, isReady, baseBackgroundColor, focusedBorderColor, variant });
    const effectiveFiltersAmount = filters ? filters?.filter(({ value }) => Boolean(value)).length : 0;

    const handleInputFocus = (event: MouseEvent<HTMLDivElement>): void => {
      event.stopPropagation();
      onSearchInputFocus();
    };

    const handleSearchIconClick = (event: MouseEvent<HTMLDivElement>) => {
      event.stopPropagation();
      onSearchIconClick();
    };

    const handleClearIconClick = (event: MouseEvent<HTMLDivElement>): void => {
      event.stopPropagation();
      onResetIconClick();
    };

    const handleFiltersTriggerClick = (event: MouseEvent<HTMLDivElement>): void => {
      event.stopPropagation();
      onFilterTriggerClick();
    };

    const handleFilterIndicatorClick = (event: MouseEvent<HTMLDivElement>): void => {
      event.stopPropagation();
      onFilterTriggerClick();
    };

    return (
      <Paper classes={{ root: classes.root }} onClick={handleInputFocus} data-aid={dataAid}>
        <div className={classNames(classes.control, classes.controlInput)}>
          <MetadataSearchInput
            dataAid={`${dataAid}-input`}
            query={query}
            placeholder={placeholder}
            isInputFocused={isInputFocused}
            onSearchInputBlur={onSearchInputBlur}
            onSearchInputChange={onSearchInputChange}
            onSearchInputKeyPress={onSearchInputKeyPress}
            isDisabled={isDisabled}
          />
        </div>
        {query?.length > 0 && (
          <BigidButtonIcon
            dataAid={`${dataAid}-clear`}
            className={classes.buttonControl}
            onClick={handleClearIconClick}
            icon={BigidXIcon}
            size="small"
          />
        )}
        {!isDisabled && (
          <BigidButtonIcon
            icon={BigidSearchIcon}
            size="small"
            onClick={handleSearchIconClick}
            dataAid={`${dataAid}-submit`}
            className={classes.buttonControl}
          />
        )}
        {!isFilterHidden && (
          <>
            <Divider orientation="vertical" className={classes.divider} />
            <div
              data-aid={`${dataAid}-filters-trigger`}
              className={classNames(classes.control, classes.actionControl, classes.actionControlIcon)}
              onClick={handleFiltersTriggerClick}
            >
              {isReady ? (
                <BigidButtonIcon
                  icon={BigidAdvanceSearchIcon}
                  size="small"
                  selected={isFiltersModeOn}
                  className={classes.buttonControl}
                />
              ) : (
                <BigidLoader size={22} thickness={3} />
              )}
            </div>
          </>
        )}
        {effectiveFiltersAmount > 0 && (
          <div
            className={classNames(classes.control, classes.actionControl, classes.activeFilterIndicator)}
            onClick={handleFilterIndicatorClick}
          >
            <BigidNotes2 classes={{ root: classes.activeFilterIndicatorText }} data-aid={`${dataAid}-filters-count`}>
              +{effectiveFiltersAmount}
            </BigidNotes2>
          </div>
        )}
      </Paper>
    );
  },
);
