import React, { useEffect, useState, useCallback } from 'react';
import { isEmpty } from 'lodash';
import Divider from '@mui/material/Divider';
import makeStyles from '@mui/styles/makeStyles';
import {
  BigidPieChart,
  BigidChartChip,
  BigidColors,
  BigidLoader,
  BigidBarChart,
  BarChartDataItemType,
  BigidFieldFilterOperator,
  PieChartData,
} from '@bigid-ui/components';
import { BigidInsightsComponent, BigidFilterContainer, parseFieldFiltersToSearchQuery } from '@bigid-ui/layout';
import Typography from '@mui/material/Typography';
import { httpService } from '../../../services/httpService';

const useStyles = makeStyles({
  content: { display: 'flex', width: '100%', alignItems: 'center' },
  leftContentWrapper: { display: 'flex', flexDirection: 'column', height: '100%', padding: '12px 0 12px 8px' },
  rightContentWrapper: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    padding: '12px 8px 12px 0',
  },
  pieChartContent: { display: 'flex' },
  chipsWrapper: { display: 'flex', flexDirection: 'column', justifyContent: 'center', marginLeft: 32 },
  chipWrapper: { margin: 6 },
  title: {
    fontSize: '0.8125rem',
    fontWeight: 'bold',
    marginBottom: 16,
    color: BigidColors.gray[700],
    lineHeight: '1.42',
  },
  loaderWrapper: {
    height: 50,
  },
  chartWrapper: { flexGrow: 1 },
  divider: {
    background: BigidColors.borderLight,
    margin: '0 32px 0 16px',
  },
});

const useMinimizedStyles = makeStyles({
  content: { display: 'flex', justifyContent: 'center', width: '100%' },
  leftContentWrapper: { marginRight: 32 },
  rightContentWrapper: {
    display: 'flex',
    alignItems: 'center',
  },
});

interface Insights {
  inProgressObjects: number;
  almostOverdueObjects: number;
  overdueObjects: number;
  topDsWithObjToDelete: BarChartDataItemType[];
  objectsCount?: number;
}

export enum DueInTypes {
  IN_PROGRESS = 'In Progress',
  ALMOST_OVERDUE = 'Almost Overdue',
  OVERDUE = 'Overdue',
  ALL = 'All Objects',
}

const typeEnumToText: Record<DueInTypes, string> = {
  ['In Progress']: 'InProgress',
  ['Almost Overdue']: 'AlmostOverdue',
  ['Overdue']: 'Overdue',
  ['All Objects']: 'AllObjects',
};

export enum InsightsType {
  REQUESTS = 'Requests',
  DATA_SOURCES = 'DataSources',
}

export const getDataMinimizationInsights = (insightsType: InsightsType) => {
  const getInsightsData = async () => {
    let insights: Insights;
    if (insightsType === InsightsType.REQUESTS) {
      insights = await httpService.fetch(`data-minimization/requests/insights`).then(({ data }) => data.data);
    } else if (insightsType === InsightsType.DATA_SOURCES) {
      insights = await httpService.fetch(`data-minimization/ds/insights`).then(({ data }) => data.data);
    }
    return insights;
  };

  const DataMinimizationInsights: BigidInsightsComponent = ({ minimized }) => {
    const classes = useStyles({});
    const minimizedClasses = useMinimizedStyles({});
    const [insights, setInsights] = useState<Insights>(null);
    const [maxColumnLabelWidth, setMaxColumnLabelWidth] = useState(null);
    const [hasTopDs, setHasTopDs] = useState<boolean>(false);
    const [displayData, setDisplayData] = useState<PieChartData<string>[]>([]);
    const [pieChartActiveSeries, setPieChartActiveSeries] = useState<string>(null);
    const { setFilter, setSearchQuery, setHasSideFilterBeenTriggered } = BigidFilterContainer.useContainer();

    const getInsights = useCallback(async () => {
      try {
        const { inProgressObjects, almostOverdueObjects, overdueObjects, topDsWithObjToDelete }: Insights =
          await getInsightsData();

        const displayData = [
          { category: DueInTypes.IN_PROGRESS, value: inProgressObjects, color: '#8ed8b7' },
          { category: DueInTypes.ALMOST_OVERDUE, value: almostOverdueObjects, color: '#FFBD42' },
          { category: DueInTypes.OVERDUE, value: overdueObjects, color: '#FC5C42' },
        ];
        const insights = {
          inProgressObjects,
          almostOverdueObjects,
          overdueObjects,
          objectsCount: inProgressObjects + almostOverdueObjects + overdueObjects,
          topDsWithObjToDelete,
        };
        setInsights(insights);
        setDisplayData(displayData);
        setHasTopDs(!isEmpty(insights.topDsWithObjToDelete));
      } catch (e) {
        window.console.error(`An error has occurred: ${e.message}`);
      }
    }, []);

    useEffect(() => {
      const handleResize = () => {
        setMaxColumnLabelWidth(window.innerWidth < 1400 ? (window.innerWidth < 1200 ? 100 : 120) : 180);
      };
      handleResize();
      window.addEventListener('resize', handleResize);

      getInsights();

      return () => {
        window.removeEventListener('resize', handleResize);
      };
    }, [getInsights]);

    const handleBarClick = (_category: string, series: string): void => {
      setFilter(() => {
        const newFilter = [
          {
            id: series,
            field: 'dataSourceName',
            value: series,
            operator: 'equal' as BigidFieldFilterOperator,
          },
        ];
        setHasSideFilterBeenTriggered(false); //NOTE: will reset side filter selection
        setSearchQuery(parseFieldFiltersToSearchQuery(newFilter));
        return newFilter;
      });
    };

    const setFilterByState = (dueInDays: string): void => {
      setFilter(() => {
        const newFilter = [
          {
            field: 'dueInDays',
            id: 'dueInDays',
            value: dueInDays,
            operator: 'equal' as BigidFieldFilterOperator,
          },
        ];
        setHasSideFilterBeenTriggered(false); //NOTE: will reset side filter selection
        setSearchQuery(parseFieldFiltersToSearchQuery(newFilter));
        return newFilter;
      });
    };

    const handlePieChartClick = (type: string): void => {
      if (type) {
        setFilterByState(typeEnumToText[type as DueInTypes]);
      } else {
        setFilter(() => {
          setHasSideFilterBeenTriggered(false); //NOTE: will reset side filter selection
          setSearchQuery('');
          return [];
        });
      }
      setPieChartActiveSeries(type);
    };

    const handleSectorClick = (type: string): void => {
      handlePieChartClick(type);
    };

    const handleChipClick = (type: string): void => {
      handlePieChartClick(type);
    };

    if (!insights) {
      return (
        <div className={classes.loaderWrapper}>
          <BigidLoader />
        </div>
      );
    }

    return minimized ? (
      <div className={minimizedClasses.content}>
        <div className={minimizedClasses.leftContentWrapper}>
          <BigidPieChart
            activeFilter={pieChartActiveSeries}
            data={hasTopDs ? displayData : []}
            total={insights.objectsCount}
            minimized
            minimizedDescription={'Data deletion objects to delete'}
            onSectorClick={handleSectorClick}
          />
        </div>
        <Divider variant="middle" className={classes.divider} orientation="vertical" />
        <div className={minimizedClasses.rightContentWrapper}>
          {displayData.map(({ color, category, value }, key) => (
            <BigidChartChip
              color={color}
              label={category}
              value={value}
              key={key}
              onClick={() => handleChipClick(category)}
            />
          ))}
        </div>
      </div>
    ) : (
      <div className={classes.content}>
        <div className={classes.leftContentWrapper}>
          <Typography className={classes.title}>{'Data deletion objects to delete'}</Typography>
          <div className={classes.pieChartContent}>
            <div>
              <BigidPieChart
                activeFilter={pieChartActiveSeries}
                data={hasTopDs ? displayData : []}
                total={insights.objectsCount}
                onSectorClick={handleSectorClick}
              />
            </div>
            <div className={classes.chipsWrapper}>
              {displayData.map(({ color, category, value }, key) => (
                <div key={key} className={classes.chipWrapper}>
                  <BigidChartChip
                    color={color}
                    label={category}
                    value={value}
                    onClick={() => handleChipClick(category)}
                  />
                </div>
              ))}
            </div>
          </div>
        </div>
        <Divider variant="middle" className={classes.divider} orientation="vertical" />
        {
          <div className={classes.rightContentWrapper}>
            <div className={classes.chartWrapper}>
              <BigidBarChart
                title={{ main: 'Top 7 data sources with objects to delete' }}
                data={insights.topDsWithObjToDelete}
                showBarTotal
                maxColumnLabelWidth={maxColumnLabelWidth}
                disableTooltip
                onBarClick={handleBarClick}
              />
            </div>
          </div>
        }
      </div>
    );
  };
  return DataMinimizationInsights;
};
