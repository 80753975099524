import { styled } from '@mui/material';
import React, { useState } from 'react';
import { CatalogSidePanelProps } from '../CatalogSidePanel';
import { AttributesGrid } from './AttributesGrid';
import { AttributeClearValue } from '../../../../../../DataCatalog/DataCatalogAttributes/AttributeClearValue';
import { isAttributeExpired } from '../../../../../../DataCatalog/DataCatalogAttributes/utils';
import { noop } from 'lodash';
import { useQuery } from 'react-query';
import { DataCatalogAttribute, getAttributesByObjectName } from '../../../../../../DataCatalog/DataCatalogAttributes';
import { BigidContentItem } from '@bigid-ui/components';

const Root = styled('div')`
  display: flex;
  gap: 24px;
  flex-grow: 1;
`;

const Section = styled('div')`
  display: flex;
  flex-direction: column;
  width: 50%;
  border-radius: 6px;
  border: ${({ theme }) => theme.vars.tokens.bigid.borderDefault};
  border-style: solid;
  border-width: 1px;
`;

export const Attributes = (props: CatalogSidePanelProps) => {
  const { selectedItem } = props;
  const { id } = selectedItem;
  const fullyQualifiedName = String(id);
  const [selectedAttribute, setSelectedAttribute] = useState<DataCatalogAttribute>({
    attribute_id: '',
    attribute_name: '',
    is_support_investigation: false,
    investigation_scan_id_list: [],
    attribute_original_name: '',
    attribute_original_type: '',
    attribute_type: '',
    business_flow: [],
    categories: [],
    column_list: [],
    description: '',
    fullyQualifiedName: '',
    is_expired: false,
  });
  const { data: attributesData, isLoading: isAttributesDataLoading } = useQuery(
    'getAttributes',
    async () => getAttributesByObjectName(fullyQualifiedName),
    {
      placeholderData: {
        data: [],
      },
    },
  );

  const attributeSelectedItem: BigidContentItem = {
    ...selectedAttribute,
    id: selectedAttribute?.attribute_id || '',
    name: selectedAttribute?.attribute_name || '',
  };

  return (
    <Root>
      <Section>
        <AttributesGrid
          {...props}
          selectedAttribute={selectedAttribute}
          setSelectedAttribute={setSelectedAttribute}
          isLoading={isAttributesDataLoading}
          attributesData={attributesData.data}
        />
      </Section>
      <Section>
        <AttributeClearValue
          fullyQualifiedName={String(fullyQualifiedName)}
          selectedItem={attributeSelectedItem}
          onFetched={noop}
          onClose={noop}
          dataAid={`AttributeClearValue-${selectedItem.id}`}
          isExpired={isAttributeExpired(attributesData.data, attributeSelectedItem)}
        />
      </Section>
    </Root>
  );
};
