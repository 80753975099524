import React, { useEffect } from 'react';
import { notificationService } from '../../../../services/notificationService';
import { dataSourceInfoForMap, SetScanWizardState } from '../createScanContext';
import { addDataSourceNameToTypeMap } from './useUpdateSupportedScanTypesBySelectedDs';
import { localStorageService } from '../../../../services/angularServices';

const STORAGE_KEY = 'selectedDataSourcesIds';

export const getSelectedDataSourceFromStorage = (): dataSourceInfoForMap[] => {
  const selectedDataSources: dataSourceInfoForMap[] = localStorageService.get(STORAGE_KEY);
  localStorageService.remove(STORAGE_KEY);
  return selectedDataSources;
};

export const setSelectedDataSourcesToStorage = (selectedDataSources: dataSourceInfoForMap[]) => {
  localStorageService.set(STORAGE_KEY, selectedDataSources);
};

export const useGetPreselectedDataSources = (setScanWizardState: SetScanWizardState) => {
  useEffect(() => {
    const dataSourcesPreselected = getSelectedDataSourceFromStorage();
    const updateState = () => {
      const dsNames = dataSourcesPreselected.map(({ name }) => name);
      const isDisabledDataSourcesSelected = dataSourcesPreselected.some(({ enabled }) => enabled !== 'yes');
      const dataSourceNameToTypeMap = dataSourcesPreselected.reduce(addDataSourceNameToTypeMap, {});
      setScanWizardState(state => ({
        ...state,
        dataSourcesIds: dsNames,
        dataSourceNameToTypeMap,
        isLoading: false,
        dataSourcesPreselected,
        isDisabledDataSourcesSelected,
      }));
    };

    if (dataSourcesPreselected?.length) {
      updateState();
    }
  }, [setScanWizardState]);
};
