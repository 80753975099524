import './importModal.component.scss';
import template from './importModal.component.html';
import { module } from 'angular';
const app = module('app');

app.component('importModal', {
  template,
  controller: function (businessGlossaryService, notificationService, $translate) {
    'ngInject';
    this.filenameToImport = '';
    this.importPayload = {
      fileToImport: '',
    };

    this.fileSelected = file => {
      this.importPayload.fileToImport = file;
      this.filenameToImport = file.name;
    };

    this.fileRemoved = () => {
      this.filenameToImport = '';
      this.importing = false;
      this.importPayload = {
        isIncludeHeaders: false,
        fileToImport: '',
      };
    };

    this.import = () => {
      this.importing = true;

      let formData = new FormData();
      formData.append('fileToImport', this.importPayload.fileToImport);

      businessGlossaryService
        .importFromFile(formData)
        .then(
          response => {
            this.importing = false;

            if (response.validItems > 0) {
              $translate('BUSINESS_GLOSSARY:MODAL:SUCCESSFUL_IMPORT', { entityValue: response.validItems }).then(
                translation => {
                  notificationService.success(translation);
                },
              );
            } else {
              $translate('BUSINESS_GLOSSARY:MODAL:SUCCESSFUL_EMPTY_IMPORT').then(translation => {
                notificationService.warning(translation);
              });
            }

            this.$close({ isImported: true });
          },
          () => {
            $translate('API:MESSAGE:COMMON_ERROR').then(translation => {
              notificationService.error(translation);
            });

            this.importing = false;
          },
        )
        .catch(() => {
          $translate('API:MESSAGE:COMMON_ERROR').then(translation => {
            notificationService.error(translation);
          });

          this.importing = false;
        });
    };
  },
  bindings: {
    $close: '&',
    $dismiss: '&',
  },
});
