import { analyticsService } from '../../services/analyticsService';

export enum EmailEditorTrackingEvents {
  EMAIL_EDITOR_EMAIL_STRUCTURE_PREVIEW_CLICK = 'EMAIL_EDITOR_EMAIL_STRUCTURE_PREVIEW_CLICK',
  EMAIL_EDITOR_SEND_TEST_EMAIL_CLICK = 'EMAIL_EDITOR_SEND_TEST_EMAIL_CLICK',
}

export const trackEmailEditorEvent = (eventType: EmailEditorTrackingEvents, data?: Record<string, unknown>) => {
  analyticsService.trackManualEvent(eventType as any, data);
};
