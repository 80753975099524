import { sessionStorageService } from '../../common/services/sessionStorageService';

const TOUR_STORAGE_KEY = 'tourQueue';

export interface TourQueueState {
  queue: string[];
  currentTour: string | null;
}

export const getTourState = (): TourQueueState => {
  const stored = sessionStorageService.get(TOUR_STORAGE_KEY);
  return stored ? JSON.parse(stored as string) : { queue: [], currentTour: null };
};

export const setTourState = (state: TourQueueState) => {
  sessionStorageService.set(TOUR_STORAGE_KEY, JSON.stringify(state));
};

export const addTourToQueue = (tourName: string) => {
  const state = getTourState();
  if (!state.queue.includes(tourName) && state.currentTour !== tourName) {
    state.queue.push(tourName);
  }
  if (!state.currentTour) {
    state.currentTour = tourName;
  }
  setTourState(state);
};

export const removeCurrentTour = () => {
  const state = getTourState();
  state.currentTour = state.queue.shift() || null;
  setTourState(state);
};
