import { userPreferencesService, PageFilterPreferences } from '../../services/userPreferencesService';
import { CONFIG } from '../../../config/common';
import { $state } from '../../services/angularServices';
import { DataCatalogPagePreferences } from '../DataCatalog/DataCatalogService';

export const goToCatalogWithChangePreferences = async (
  newFilterPreferences: PageFilterPreferences = {
    sideFilterSelection: [],
    optionalSideFilterSections: [],
    searchQuery: '',
  },
) => {
  try {
    const preferences = await userPreferencesService.get<DataCatalogPagePreferences>(CONFIG.states.CATALOG);
    if (preferences) {
      await userPreferencesService.update({
        ...preferences,
        filter: newFilterPreferences,
      });
    } else {
      await userPreferencesService.add({
        preference: CONFIG.states.CATALOG,
        filter: newFilterPreferences,
      });
    }
    $state.go(CONFIG.states.CATALOG, {
      filter: newFilterPreferences.searchQuery,
    });
  } catch ({ message }) {
    console.error(`An error has occurred: ${message}`);
  }
};
