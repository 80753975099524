import React, { FC, useEffect, useMemo, useState } from 'react';
import { BigidLayout, BigidLayoutConfig, LayoutContentType } from '@bigid-ui/layout';
import { getAccessIntelligenceInsight } from '../InsightCharts/AccessIntelligenceInsight';
import makeStyles from '@mui/styles/makeStyles';
import { $state } from '../../../services/angularServices';
import { userPreferencesService } from '../../../services/userPreferencesService';
import { notificationService } from '../../../services/notificationService';
import { UsersPermissions } from './UsersPermissions';
import { BigidLoader } from '@bigid-ui/components';
import { AciEntityType } from '../InsightCharts/AccessIntelligenceInsightsService';

export interface UserPermissionsPreferences {
  areInsightsMinimized?: boolean;
}

const useStyles = makeStyles(() => ({
  root: {
    width: '100%',
  },
}));

const newPreferences: UserPermissionsPreferences = {
  areInsightsMinimized: false,
};

export const UsersPermissionsWrapper: FC = () => {
  const classes = useStyles({});
  const [userPermissionsPreferences, setUserPermissionsPreferences] = useState<UserPermissionsPreferences>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const state = `${$state.$current.name}.users`;

  useEffect(() => {
    setIsLoading(true);
    const getPreferences = async () => {
      try {
        const { data } = await userPreferencesService.get<UserPermissionsPreferences>(state);
        if (data) {
          setUserPermissionsPreferences(data);
        } else {
          await userPreferencesService.add<UserPermissionsPreferences>({
            preference: state,
            data: newPreferences,
          });
        }
      } catch ({ message }) {
        console.error(`An error has occurred: ${message}`);
        notificationService.error(`An error has occurred`);
      } finally {
        setIsLoading(false);
      }
    };
    getPreferences();
  }, [state]);

  const handleOnMinimize = async (isMinimized: boolean): Promise<void> => {
    await userPreferencesService.update({
      preference: state,
      data: {
        areInsightsMinimized: isMinimized,
      },
    });
  };

  const layoutConfig = useMemo<BigidLayoutConfig>(
    () => ({
      content: {
        entityName: 'users',
        contentTypes: [LayoutContentType.CUSTOM],
        defaultContentType: LayoutContentType.CUSTOM,
        viewConfig: {
          customContentConfig: { component: UsersPermissions },
          fetchGridData: async () => {
            return { totalCount: 0, data: [] };
          },
          toolbarConfig: { hideToolbar: true },
        },
      },
      insights: {
        component: getAccessIntelligenceInsight(AciEntityType.Users),
        isInitiallyMinimized: userPermissionsPreferences?.areInsightsMinimized,
        onMinimize: handleOnMinimize,
      },
    }),
    [handleOnMinimize, userPermissionsPreferences?.areInsightsMinimized],
  );

  return <div className={classes.root}>{isLoading ? <BigidLoader /> : <BigidLayout config={layoutConfig} />}</div>;
};
