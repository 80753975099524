import React, { FC } from 'react';
import { BigidDialog, TertiaryButton, PrimaryButton, SecondaryButton } from '@bigid-ui/components';
import { DialogData, DialogButton } from '@bigid/app-fw-ui-sdk';

export interface CustomAppDialogProps extends DialogData {
  isOpen: boolean;
  onButtonClick?: (value: string | boolean) => void;
  onClose?: () => void;
}

const MAX_WIDTH_DEFAULT = 'md';
const BORDER_TOP_DEFAULT = true;
const BORDER_BOTTOM_DEFAULT = true;
const BUTTONS_DEFAULT: DialogButton[] = [
  {
    text: 'Ok',
    type: 'primary',
    value: true,
  },
  {
    text: 'Cancel',
    type: 'tertiary',
    value: false,
  },
];

export const CustomAppDialog: FC<CustomAppDialogProps> = ({
  isOpen,
  maxWidth = MAX_WIDTH_DEFAULT,
  title = '',
  message = '',
  buttons = BUTTONS_DEFAULT,
  borderTop = BORDER_TOP_DEFAULT,
  borderBottom = BORDER_BOTTOM_DEFAULT,
  onButtonClick,
  onClose,
}) => {
  const buttonsMap = buttons.map(({ text, value, type, alignment }) => ({
    onClick: () => onButtonClick?.(value),
    text,
    component: type === 'primary' ? PrimaryButton : type === 'secondary' ? SecondaryButton : TertiaryButton,
    alignment,
  }));

  return (
    <BigidDialog
      isOpen={isOpen}
      title={title}
      buttons={buttonsMap}
      onClose={onClose}
      maxWidth={maxWidth}
      borderBottom={borderBottom}
      borderTop={borderTop}
    >
      <div>{message}</div>
    </BigidDialog>
  );
};
