import makeStyles from '@mui/styles/makeStyles';
import { BigidColorsV2 } from '@bigid-ui/components';

export const useStyles = makeStyles({
  root: {
    width: '100%',
    minHeight: '120px',
    padding: '12px 16px',
    overflowY: 'auto',
  },
  noResultsRoot: {
    display: 'flex',
  },
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
  },
  noResultsWrapper: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
  footer: {
    borderTop: `1px solid ${BigidColorsV2.gray[100]}`,
    textAlign: 'right',
    paddingTop: '8px',
    marginTop: '8px',
    display: 'flex',
    justifyContent: 'flex-end',
  },
  group: {
    display: 'flex',
    flexWrap: 'nowrap',
    '&:not(:last-child)': {
      marginBottom: '8px',
    },
  },
  noOffset: {
    margin: '0 !important',
  },
  columnGroup: {
    flexDirection: 'column',
  },
  rowGroup: {
    flexDirection: 'row',
  },
  groupTitle: {
    display: 'flex',
    height: '36px',
    alignItems: 'center',
  },
  columnGroupTitle: {
    paddingBottom: '8px',
  },
  rowGroupTitle: {
    flexGrow: 0,
    flexShrink: 0,
    alignItems: 'center',
    marginRight: '16px',
  },
  groupTitleIcon: {
    display: 'flex',
    alignItems: 'center',
    marginRight: '4px',
  },
  groupTitleText: {
    display: 'flex',
    alignItems: 'center',
    flex: 1,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  groupContentColumn: {
    display: 'flex',
    flexDirection: 'column',
  },
  groupContentRow: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    alignItems: 'center',
    '& .MuiChip-root': {
      margin: '4px 4px 4px 0px',
      flex: '0 0 auto',
    },
  },
  rowGroupContent: {
    flex: 1,
  },
  groupLink: {
    display: 'flex',
    justifyContent: 'flex-end',
    '& .MuiLink-root': {
      fontSize: '0.75rem',
    },
  },
  busy: {
    opacity: 0,
    pointerEvents: 'none',
  },
});
