import React, { FunctionComponent, ReactText, useEffect, useState } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import {
  BigidColors,
  BigidChip,
  BigidObjectDetailsSection,
  BigidEditableTextArea,
  BigidTagBaseProps,
} from '@bigid-ui/components';
import { clusteringService } from '../clusteringService';
import { ClustersDataSourcesChart } from '../ClustersDataSourcesChart';
import { $stateParams } from '../../../services/angularServices';
import { notificationService } from '../../../services/notificationService';
import { tagsService, TagModel } from '../../../services/tagsService';
import { ClusterTagsList } from './ClusterTagsList';
import { ClusterDuplicatesChart } from './ClusterDuplicatesChart';
import { isPermitted } from '../../../services/userPermissionsService';
import { CLUSTER_ANALYSIS_PERMISSIONS, TAGS_SAVED_QUERIES_PERMISSIONS } from '@bigid/permissions';

interface ClusterDetailsRecord {
  id: ReactText;
}

export interface DataSource {
  dataSourceName: string;
  count: number;
}

export interface ClusterDetailsModel {
  cluster_id: string;
  name: string;
  description?: string;
  keywords: string[];
  attributes: string[];
  objectsPerDataSource: DataSource[];
  duplicates: number;
  unique_duplicates: number;
}

const MAX_KEYWORDS_TO_DISPLAY = 7;
const MAX_DATA_SOURCES_TO_DISPLAY = 7;

const useStyles = makeStyles({
  wrapper: {
    display: 'flex',
    flexFlow: 'column nowrap',
    width: '100%',
    overflow: 'auto',
    flex: '1 1 auto',
  },
  sectionTitle: {
    color: BigidColors.purple[600],
    fontSize: '1.25rem',
    paddingBottom: '12px',
  },
  sectionContainer: {
    marginBottom: '24px',
    width: '40%',
    minWidth: '600px',
  },
  keywordsContainer: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  tagsContainer: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chipWrapper: {
    padding: '5px',
  },
  chartWrapper: {
    height: '175px',
    display: 'flex',
  },
  emptyMessage: {
    color: BigidColors.gray[500],
    fontSize: '0.875rem',
  },
});

export const ClusterDetails: FunctionComponent<ClusterDetailsRecord> = ({ id }: ClusterDetailsRecord) => {
  const classes = useStyles({});

  const [keywords, setKeywords] = useState<string[]>([]);
  const [tags, setTags] = useState<TagModel[]>([]);
  const [description, setDescription] = useState<string>('');
  const [dataSources, setDataSources] = useState<DataSource[]>([]);
  const [attributes, setAttributes] = useState<string[]>([]);
  const [clusterId, setClusterId] = useState<ReactText>(id);
  const [duplicates, setDuplicates] = useState<number>(0);
  const [uniques, setUniques] = useState<number>(0);

  const getSectionsLists = async (id: ReactText) => {
    try {
      const cluster: ClusterDetailsModel = await clusteringService.getClusterById(id as string);

      if (isPermitted(TAGS_SAVED_QUERIES_PERMISSIONS.READ.name)) {
        const tags: TagModel[] = await tagsService.getAllTags();
        setTags(tags);
      }

      setKeywords(cluster.keywords.slice(0, MAX_KEYWORDS_TO_DISPLAY));
      setDescription(cluster.description);
      setDataSources(cluster.objectsPerDataSource.slice(0, MAX_DATA_SOURCES_TO_DISPLAY));
      setAttributes(cluster.attributes);
      setDuplicates(cluster.duplicates);
      setUniques(cluster.unique_duplicates);
    } catch (err) {
      notificationService.error('An error has occurred');
      window.console.error(`An error has occurred: ${err.message}`);
    }
  };

  const handleUpdateClusterDescription = async (updatedDescription: string) => {
    try {
      const { name } = await clusteringService.getClusterById(clusterId as string);
      await clusteringService.updateCluster({ name, description: updatedDescription }, id as string);
      setDescription(updatedDescription);
    } catch (err) {
      notificationService.error('An error has occurred');
      window.console.error(`An error has occurred: ${err.message}`);
    }
  };

  useEffect(() => {
    if (id) {
      setClusterId(id);
      getSectionsLists(id);
      setDataSources([]);
    }
  }, [id]);

  useEffect(() => {
    const { clusterId } = $stateParams;

    if (clusterId) {
      setClusterId(clusterId);
      getSectionsLists(clusterId);
      setDataSources([]);
    }
  }, []);

  async function handleAttachTagToCluster(tag: BigidTagBaseProps) {
    const foundTag = tags.find(tagModel => tagModel.tag_name === tag.name && tagModel.tag_value === tag.value);

    try {
      const tagId = foundTag?._id ?? (await tagsService.createTag(tag));
      await tagsService.attachClusterToTag(tagId, clusterId);
    } catch (error) {
      notificationService.error('An error has occurred');

      if (error.response?.data && error.response?.status) {
        window.console.error(
          `An error has occurred while trying to tag a cluster, response message: "${error.response.data}", response status: "${error.response.status}"`,
        );
      } else if (error.request) {
        window.console.error(
          'An error has occurred while trying to tag a cluster, no response received from the server',
        );
      } else {
        window.console.error('An error has occurred while trying to tag a cluster');
      }
    }

    setTags(await tagsService.getAllTags());
  }

  async function handleDetachTagFromCluster(tag: BigidTagBaseProps) {
    const tagModel = tags.find(tagModel => tagModel.tag_name === tag.name && tagModel.tag_value === tag.value);
    const tagId = tagModel?._id;

    try {
      tagId && (await tagsService.detachClusterFromTag(tagId, clusterId));
    } catch (error) {
      notificationService.error('An error has occurred');

      if (error.response?.data && error.response?.status) {
        window.console.error(
          `An error has occurred while trying to untag a cluster, response message: "${error.response.data}", response status: "${error.response.status}"`,
        );
      } else if (error.request) {
        window.console.error(
          'An error has occurred while trying to untag a cluster, no response received from the server',
        );
      } else {
        window.console.error('An error has occurred while trying to untag a cluster');
      }
    }

    setTags(await tagsService.getAllTags());
  }

  return (
    <div className={classes.wrapper}>
      <div className={classes.sectionContainer}>
        <BigidObjectDetailsSection title="Description">
          <BigidEditableTextArea
            value={description}
            onSubmit={handleUpdateClusterDescription}
            isInlineEdit={true}
            isMultiLineMode={true}
            controlsPlacement={['right', 'center']}
            placeholder="Click to edit description"
            isDisabled={!isPermitted(CLUSTER_ANALYSIS_PERMISSIONS.EDIT.name)}
          />
        </BigidObjectDetailsSection>
      </div>
      {isPermitted(TAGS_SAVED_QUERIES_PERMISSIONS.READ.name) && (
        <div className={classes.sectionContainer}>
          <BigidObjectDetailsSection title="Saved Queries">
            <ClusterTagsList
              tags={tags}
              clusterId={clusterId}
              onAttach={handleAttachTagToCluster}
              onDetach={handleDetachTagFromCluster}
            />
          </BigidObjectDetailsSection>
        </div>
      )}
      <div className={classes.sectionContainer}>
        <BigidObjectDetailsSection title="Top keywords">
          <div className={classes.keywordsContainer}>
            {keywords &&
              keywords.map((keyword, index) => (
                <div className={classes.chipWrapper} key={index}>
                  <BigidChip label={keyword} size="medium" bgColor={BigidColors.white} shadow={true} />
                </div>
              ))}
          </div>
          {keywords.length === 0 && <div className={classes.emptyMessage}>No keywords found</div>}
        </BigidObjectDetailsSection>
      </div>
      <div className={classes.sectionContainer}>
        <BigidObjectDetailsSection title="Attributes">
          <div className={classes.keywordsContainer}>
            {attributes &&
              attributes.map((att, index) => (
                <div className={classes.chipWrapper} key={index}>
                  <BigidChip label={att} size="medium" bgColor={BigidColors.white} shadow={true} />
                </div>
              ))}
          </div>
          {attributes.length === 0 && <div className={classes.emptyMessage}>No attributes found</div>}
        </BigidObjectDetailsSection>
      </div>
      <div className={classes.sectionContainer}>
        <BigidObjectDetailsSection title="Objects per data source">
          {dataSources && (
            <div className={classes.chartWrapper}>
              <ClustersDataSourcesChart dataSources={dataSources} />
            </div>
          )}
        </BigidObjectDetailsSection>
      </div>
      <div className={classes.sectionContainer}>
        <BigidObjectDetailsSection title="Duplicates">
          <div className={classes.keywordsContainer}>
            {duplicates >= 0 && uniques >= 0 && <ClusterDuplicatesChart duplicates={duplicates} uniques={uniques} />}
          </div>
        </BigidObjectDetailsSection>
      </div>
    </div>
  );
};
