import React, { PropsWithChildren } from 'react';
import { BigidTooltip } from '@bigid-ui/components';
import { BigidInfoIcon } from '@bigid-ui/icons';
import { styled } from '@mui/material';

export interface RiskFormFieldProps extends PropsWithChildren {
  tooltip: NonNullable<React.ReactNode>;
  onClick?: () => void;
  marginRight?: number;
}

const IconContainer = styled('div')<{ marginRight?: number }>(({ marginRight }) => ({
  width: 20,
  position: 'absolute',
  top: -2,
  right: marginRight ?? 2,
  zIndex: 999,
}));

export const RiskFormField: React.FC<RiskFormFieldProps> = ({ marginRight, tooltip, onClick, children }) => {
  return (
    <div
      style={{
        position: 'relative',
      }}
    >
      <BigidTooltip placement="bottom" title={tooltip}>
        <IconContainer onClick={onClick} marginRight={marginRight}>
          <BigidInfoIcon />
        </IconContainer>
      </BigidTooltip>
      {children}
    </div>
  );
};
