import React from 'react';
import { BigidFormFieldLabelWrapper, BigidFormRenderProps } from '@bigid-ui/components';
import { StyledFlexWrapper, StyledProximity } from './ClassifiersFormStyledComponents';
import { useLocalTranslation } from '../../translations';

interface SupportTermRegexFormProps {
  textAreaName: string;
  fieldNameAfter: string;
  fieldNameBefore: string;
  renderField: BigidFormRenderProps['renderField'];
}

export const SupportTermRegexForm = ({
  renderField,
  textAreaName,
  fieldNameAfter,
  fieldNameBefore,
}: SupportTermRegexFormProps) => {
  const { t } = useLocalTranslation('form');

  return (
    <StyledProximity>
      {renderField(textAreaName)}
      <BigidFormFieldLabelWrapper id={textAreaName} label={t('proximity')} tooltipText={t('tooltips.proximity')}>
        <StyledFlexWrapper>
          {renderField(fieldNameBefore)}
          {renderField(fieldNameAfter)}
        </StyledFlexWrapper>
      </BigidFormFieldLabelWrapper>
    </StyledProximity>
  );
};
