import React, { FC, useContext, useEffect, useMemo, useState } from 'react';
import {
  BigidChartChip,
  BigidColors,
  BigidColorsV2,
  BigidPieChart,
  PieChartData,
  SecondaryButton,
} from '@bigid-ui/components';
import { Divider, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import {
  dataSources,
  DataSourcesAggregation,
  ObjectsByDataSource,
  TOTAL_OBJECTS_WITH_PII,
  TOTAL_OBJECTS_WITH_PII_AND_OPEN_ACCESS,
} from './consts';
import { AccessIntelligenceDashboardContext } from './AccessIntelligenceDashboardContext';

const useStyles = makeStyles(() => ({
  wrapper: {
    backgroundColor: '#fff',
    borderRadius: 12,
    padding: '15px 20px',
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    alignItems: 'center',
  },
  chartContainer: {
    display: 'flex',
    marginTop: 60,
  },
  chipsWrapper: {
    width: '12vw',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    marginLeft: 32,
  },
  divider: {
    background: BigidColors.borderLight,
    width: '100%',
    margin: '17px 0 17px 0',
  },
  inventoryInfo: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: 20,
  },
  dataSourcesWithPiiAndOpenAccessValue: {
    marginLeft: 5,
    marginRight: 5,
    color: BigidColorsV2.red[600],
  },
}));

const aggregateDataSources = (objectsByDataSource: ObjectsByDataSource[]): DataSourcesAggregation => {
  return objectsByDataSource.reduce<DataSourcesAggregation>(
    (acc, dataSourceInfo) => {
      return {
        dataSourcesWithPii: acc.dataSourcesWithPii + (dataSourceInfo[TOTAL_OBJECTS_WITH_PII] ? 1 : 0),
        dataSourcesWithPiiAndOpenAccess:
          acc.dataSourcesWithPiiAndOpenAccess + (dataSourceInfo[TOTAL_OBJECTS_WITH_PII_AND_OPEN_ACCESS] ? 1 : 0),
      };
    },
    { dataSourcesWithPii: 0, dataSourcesWithPiiAndOpenAccess: 0 },
  );
};

const convertAggregationToPieCategories = (dataSourcesAggregation: DataSourcesAggregation): PieChartData<string>[] => {
  return Object.entries(dataSourcesAggregation).map(([key, value]) => ({
    category: dataSources[key as keyof DataSourcesAggregation].label,
    value,
    color: dataSources[key as keyof DataSourcesAggregation].color,
  }));
};

export const DataSourcesWithSensitiveDataChart: FC = () => {
  const classes = useStyles({});
  const [chartData, setChartData] = useState<PieChartData<string>[] | null>([]);
  const [totalCount, setTotalCount] = useState<number>(0);
  const { dashboardData, goToInventoryWithOpenAccessFilter } = useContext(AccessIntelligenceDashboardContext);
  const { objectsByDataSource } = dashboardData;

  useEffect(() => {
    if (objectsByDataSource) {
      const chartData = convertAggregationToPieCategories(aggregateDataSources(objectsByDataSource));
      setChartData(chartData);
      setTotalCount(chartData.reduce<number>((total, { value }) => total + value, 0));
    } else {
      setChartData([]);
      setTotalCount(0);
    }
  }, [objectsByDataSource]);

  const dataSourcesWithPiiAndOpenAccess = useMemo(() => {
    return chartData.find(pieCategory => pieCategory.category === dataSources['dataSourcesWithPiiAndOpenAccess'].label);
  }, [chartData]);

  const isDataEmpty = () => {
    if (!dataSourcesWithPiiAndOpenAccess) {
      return true;
    }
    return dataSourcesWithPiiAndOpenAccess.value === 0;
  };

  return (
    <div className={classes.wrapper} data-aid={'sensitiveByDataSourceInsight'}>
      <Typography variant={'h4'} data-aid="ImportDataSourcesTitle">
        Data Sources With Sensitive Data
      </Typography>
      <div className={classes.chartContainer}>
        <BigidPieChart data={chartData} entityName={'Data Sources'} total={totalCount} />
        <div className={classes.chipsWrapper}>
          {chartData.map(({ color, category, value }, key) => (
            <BigidChartChip color={color} label={category} value={value} key={key} />
          ))}
        </div>
      </div>
    </div>
  );
};
