import {
  checkIfOverviewWasUsed,
  checkIsUsersReady,
  wasReportVisited,
  checkIfDsAndScanReady,
} from './utils/gettingStartedCheckingUtils';
import { $state } from '../../services/angularServices';
import { CONFIG } from '../../../config/common';
import { getApplicationPreference } from '../../services/appPreferencesService';
import { BigidIconType } from '@bigid-ui/components';
import { getFixedT } from './translations';
import { isPermitted } from '../../services/userPermissionsService';
import {
  ACCESS_MANAGEMENT_PERMISSIONS,
  ACTIONABLE_INSIGHTS_PERMISSIONS,
  DASHBOARD_PERMISSIONS,
  DATA_SOURCES_PERMISSIONS,
  REPORTS_PERMISSIONS,
  SCANS_PERMISSIONS,
} from '@bigid/permissions';
import {
  BigidCollaborateIllustration,
  BigidDiscoveryIllustration,
  BigidReportsIllustration,
  BigidScanIllustration,
} from '@bigid-ui/icons';

const itemsText = getFixedT('items');

export enum GettingStartedStepsIds {
  'STEP_ONE_ID',
  'STEP_TWO_ID',
  'STEP_THREE_ID',
  'STEP_FOUR_ID',
}

export interface GettingStartedStep {
  id: GettingStartedStepsIds;
  title: string;
  menuTitle: string;
  description: string;
  actionLabel: string;
  checkIsComplete: () => Promise<boolean>;
  icon: BigidIconType;
  actionMethod: () => void;
  isComplete?: boolean;
  isActive?: boolean;
  checkIsEnabled?: () => boolean;
  isDisabled?: boolean;
}

export const GETTING_STARTED_STEPS: Record<GettingStartedStepsIds, GettingStartedStep> = {
  [GettingStartedStepsIds.STEP_ONE_ID]: {
    id: GettingStartedStepsIds.STEP_ONE_ID,
    menuTitle: itemsText('connectAndScan.menuTitle'),
    title: itemsText('connectAndScan.title'),
    description: itemsText('connectAndScan.description'),
    checkIsComplete: checkIfDsAndScanReady,
    icon: BigidScanIllustration,
    actionLabel: itemsText('connectAndScan.actionLabel'),
    actionMethod: () => {
      $state.go(CONFIG.states.DATA_SOURCE_CONNECTIONS);
    },
    checkIsEnabled: () => {
      return (
        isPermitted(DATA_SOURCES_PERMISSIONS.CREATE.name) && isPermitted(SCANS_PERMISSIONS.EDIT_SCAN_ACTIVITY.name)
      );
    },
  },
  [GettingStartedStepsIds.STEP_TWO_ID]: {
    id: GettingStartedStepsIds.STEP_TWO_ID,
    menuTitle: itemsText('discoverAndRemediate.menuTitle'),
    title: itemsText('discoverAndRemediate.title'),
    description: itemsText('discoverAndRemediate.description'),
    checkIsComplete: checkIfOverviewWasUsed,
    icon: BigidDiscoveryIllustration,
    actionLabel: itemsText('discoverAndRemediate.actionLabel'),
    actionMethod: () => {
      const isEnabled =
        getApplicationPreference('METADATA_SEARCH_ENABLED') && getApplicationPreference('DATA_OVERVIEW_ENABLED');
      $state.go(isEnabled ? CONFIG.states.CATALOG_DISCOVERY : CONFIG.states.FORBIDDEN);
    },
    checkIsEnabled: () => {
      return getApplicationPreference('DATA_OVERVIEW_ENABLED') || isPermitted(DASHBOARD_PERMISSIONS.ACCESS.name);
    },
  },
  [GettingStartedStepsIds.STEP_THREE_ID]: {
    id: GettingStartedStepsIds.STEP_THREE_ID,
    menuTitle: itemsText('reviewRisk.menuTitle'),
    title: itemsText('reviewRisk.title'),
    description: itemsText('reviewRisk.description'),
    checkIsComplete: wasReportVisited,
    icon: BigidReportsIllustration,
    actionLabel: itemsText('reviewRisk.actionLabel'),
    actionMethod: () => {
      const isEnabled = getApplicationPreference('ACTIONABLE_INSIGHTS_ENABLED');
      $state.go(isEnabled ? CONFIG.states.ACTIONABLE_INSIGHTS : CONFIG.states.DIGEST_REPORT);
    },
    checkIsEnabled: () => {
      const isEnabled = getApplicationPreference('ACTIONABLE_INSIGHTS_ENABLED');
      return isPermitted(
        isEnabled ? ACTIONABLE_INSIGHTS_PERMISSIONS.ACCESS.name : REPORTS_PERMISSIONS.READ_ACTIVITY_HIGHLIGHTS.name,
      );
    },
  },
  [GettingStartedStepsIds.STEP_FOUR_ID]: {
    id: GettingStartedStepsIds.STEP_FOUR_ID,
    menuTitle: itemsText('yourTeam.menuTitle'),
    title: itemsText('yourTeam.title'),
    description: itemsText('yourTeam.description'),
    checkIsComplete: checkIsUsersReady,
    icon: BigidCollaborateIllustration,
    actionLabel: itemsText('yourTeam.actionLabel'),
    actionMethod: () => {
      $state.go(CONFIG.states.IDENTITY_ACCESS_MANAGEMENT);
    },
    checkIsEnabled: () => {
      return (
        isPermitted(ACCESS_MANAGEMENT_PERMISSIONS.MANAGE.name) &&
        isPermitted(ACCESS_MANAGEMENT_PERMISSIONS.MANAGE_IDP.name)
      );
    },
  },
};
