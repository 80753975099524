import * as dsarService from '../dsarService';
import { closeSystemDialog } from '../../../services/systemDialogService';
import { DownloadReportType } from './DsarRequestsGrid';
import { notificationService } from '../../../services/notificationService';
import { EntityEvents, entityEventsEmitter } from '@bigid-ui/components';
import { dateUtils } from '../../../services/angularServices';

export const handleDownloadDialog = (requestId: string, downloadReportType: string, parse: boolean) => {
  console.log(`requestId=${requestId}, type=${downloadReportType}, parsePipedValues=${parse}`);
  if (downloadReportType === DownloadReportType.Full) {
    dsarService.getDsarFullReport(requestId, 'csv', parse);
  } else if (downloadReportType === DownloadReportType.Short) {
    dsarService.getDsarShortReport(requestId);
  } else {
    console.error(`no recognize report type ${downloadReportType}`);
  }
  closeSystemDialog();
};

export const handleDeleteDialog = async (requestId: string, setLastUpdate: (date: string) => void) => {
  try {
    await dsarService.deleteDsar(requestId);
    notificationService.success(`Request ${requestId} Deleted Successfully! `);
    entityEventsEmitter.emit(EntityEvents.RELOAD);
    setLastUpdate(dateUtils.formatDate(new Date()));
  } catch (err) {
    notificationService.error(`Delete User Data Failed! ${err}`);
  }
};

export const handleStopDialog = async (requestId: string) => {
  try {
    await dsarService.stopDsar(requestId);
    notificationService.success(`Request ${requestId} Stopped Successfully!`);
    entityEventsEmitter.emit(EntityEvents.RELOAD);
  } catch (err) {
    notificationService.error(`Stop Scan Failed For Request ${requestId}!`);
  }
};

export const handlePauseDialog = async (requestId: string) => {
  try {
    const {
      data: { pausedPartsNumber },
    } = await dsarService.pauseDsar(requestId);
    notificationService.success(`Paused ${pausedPartsNumber} Objects for Request ${requestId}`);
    entityEventsEmitter.emit(EntityEvents.RELOAD);
  } catch (err) {
    notificationService.error(`Paused Failed For Request ${requestId}!`);
  }
};

export const handleResumeDialog = async (requestId: string) => {
  try {
    const {
      data: { resumePartsNumber },
    } = await dsarService.resumeDsar(requestId);
    notificationService.success(`Resume ${resumePartsNumber} Objects for Request ${requestId}`);
    entityEventsEmitter.emit(EntityEvents.RELOAD);
  } catch (err) {
    notificationService.error(`Resume Failed For Request ${requestId}!`);
  }
};
