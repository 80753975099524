import React, { useEffect, useState, useCallback, FC, useMemo } from 'react';
import { omit } from 'lodash';
import { Divider, Stack } from '@mui/material';
import { Trans } from 'react-i18next';
import {
  BigidLoader,
  BigidPaper,
  BigidCaption,
  AdvancedToolbarOverrideValue,
  BigidChartLegend,
  advancedToolbarFilterMinifier,
  BigidDropdownOption,
  BigidFilter,
} from '@bigid-ui/components';

import { getApplicationPreference } from '../../../services/appPreferencesService';
import { formatNumber } from '../../../utilities/numericDataConverter';
import { useLocalTranslation } from '../translations';

import { Progress } from './Progress';
import { State, States } from './types';
import { colors, filtersConfig } from './constants';
import { fetchInsights } from './helpers';

type Props = {
  onFiltersChange: (filters: AdvancedToolbarOverrideValue[]) => void;
  filters: AdvancedToolbarOverrideValue[];
  oldFilter: BigidFilter;
};

const defaultStatesFilter = advancedToolbarFilterMinifier.getFilterCompressedToOverrideValue(
  filtersConfig.find(item => item.id === 'states'),
);
const MAX_STATES_OPTIONS = (defaultStatesFilter.options as BigidDropdownOption[]).length;

export const Metrics: FC<Props> = ({ onFiltersChange, filters, oldFilter }) => {
  const { t } = useLocalTranslation('DataSource');
  const fetchTimeout = (getApplicationPreference('DATA_MINIMIZATION_DS_GRID_REFRESH_TIMEOUT') || 30) * 1000;
  const [states, setStates] = useState<States>({});

  const fetchStates = useCallback(async () => {
    const statesFromBE = await fetchInsights(oldFilter);

    const orderedStates = {
      pending: statesFromBE[State.pending],
      queued: statesFromBE[State.queued],
      in_progress: statesFromBE[State.in_progress],
      completed: statesFromBE[State.completed],
      failed: statesFromBE[State.failed],
      total: statesFromBE[State.total],
    };
    setStates(orderedStates);
  }, [oldFilter]);

  useEffect(() => {
    fetchStates();
    const intervalId = setInterval(() => {
      fetchStates();
    }, fetchTimeout);

    return () => clearInterval(intervalId);
  }, [fetchStates, fetchTimeout]);

  const handleClickProgress = useCallback(
    (state: State) => {
      const statesFilterIndex = filters.findIndex(item => item.id === 'states');
      const statesFilter = filters[statesFilterIndex] || defaultStatesFilter;
      const options = statesFilter.options as BigidDropdownOption[];
      const currentOptionIndex = options.findIndex(item => item.id === state);
      const currentOption = options[currentOptionIndex];

      let newOptions = [...options];

      if (currentOption) {
        if (options.length === MAX_STATES_OPTIONS) {
          newOptions = [currentOption];
        } else {
          newOptions.splice(currentOptionIndex, 1);
        }
      } else {
        newOptions.push({
          id: state,
          displayValue: state,
          value: state,
        });
      }

      const newFilters = [...filters];
      const newStatesFilter: AdvancedToolbarOverrideValue = {
        ...statesFilter,
        options: newOptions,
      };
      if (statesFilterIndex !== -1) {
        newFilters[statesFilterIndex] = newStatesFilter;
      } else {
        newFilters.push(newStatesFilter);
      }

      onFiltersChange(newFilters);
    },
    [filters, onFiltersChange],
  );

  const filteredStates = useMemo(() => {
    const stateFilter = filters.find(item => item.id === 'states');
    const selectedOptions = (stateFilter?.options || []) as BigidDropdownOption[];
    const filteredStates = selectedOptions.reduce<States>(
      (acc, curr) => ({ ...acc, [curr.id]: states[curr.value as State] }),
      {},
    );
    const allOrFiltered = omit(selectedOptions.length ? filteredStates : states, 'total');

    return allOrFiltered;
  }, [filters, states]);
  const legendItems = useMemo(
    () =>
      (Object.keys(filteredStates) as Array<keyof typeof filteredStates>).map(keyName => ({
        isActive: true,
        label: t(keyName),
        markerColor: colors[keyName],
        onClick: () => handleClickProgress(keyName),
        value: formatNumber(filteredStates[keyName]),
      })),
    [filteredStates, handleClickProgress, t],
  );

  return (
    <BigidPaper>
      <Stack direction="row" alignItems="center" p={2} spacing={4}>
        {states ? (
          <>
            <BigidCaption noWrap flexShrink={0}>
              <Trans t={t} i18nKey="totalObjectsLabel" values={{ count: states.total || 0 }} />
            </BigidCaption>
            <Progress states={filteredStates} sx={{ maxWidth: '240px' }} onClick={handleClickProgress} />
            <Divider orientation="vertical" flexItem />
            <BigidChartLegend orientation="row" items={legendItems} />
          </>
        ) : (
          <BigidLoader />
        )}
      </Stack>
    </BigidPaper>
  );
};
