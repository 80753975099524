import { isPermitted } from '../../../../services/userPermissionsService';
import { DS_COLLABORATOR_ROLE_NAME } from '../constants/constants';
import { ACCESS_MANAGEMENT_PERMISSIONS } from '@bigid/permissions';
import type { DataSourceCollaborator } from '../types';
import { isDsCollaborationEnabled } from '../../../../utilities/featureFlagUtils';

/**
 * @info isDsCollaborator is a restrictive flag by default where we restrict features
 * if the user IS a collaborator so if the flag is set to `true` we hide certain features and restrict other ui elements.
 * Going by least privilege principle we should restrict access to features first before granting access to the user,
 * so the default state of this flag should be `true` to first restrict access
 */

export const checkCollaboratorStatus = (
  collaborators: DataSourceCollaborator[],
  userEmail: string,
  isLoading: boolean,
): boolean => {
  const isDataSourceCollaborator = !!collaborators?.find(({ email }) => email === userEmail);

  // @info don't restrict access for users with access management permissions
  const isManagePermitted = isPermitted(ACCESS_MANAGEMENT_PERMISSIONS.MANAGE.name);

  const isDsCollaborator =
    isDsCollaborationEnabled() && isPermitted(DS_COLLABORATOR_ROLE_NAME) && isDataSourceCollaborator;

  return isManagePermitted ? false : isLoading ? true : isDsCollaborator;
};
