import { $state, localStorageService } from '../../../../services/angularServices';
import { CallbackProps } from '../../DataSourceConnectionModal/DataSourceConnectionCollaboration/DataSourceCollaborationGuidedTour';
import { getGuidedTourDataSourceEvent } from '../../DataSourceConnectionModal/mappers/collaboration';
import { trackEventDataSources } from '../../DataSourcesEventTrackerUtils';
import { CONFIG } from '../../../../../config/common';
import { DataSourceModel, DataSourcesResponse } from '../DataSourceConnectionTypes';
import { DATA_SOURCE_CONNECTIONS_QUERY_KEY } from '../../constants/newConnectionFlowConstants';
import { useEffect, useState } from 'react';
import { isBoolean } from 'lodash';
import { QueryClient } from 'react-query';

const getGuidedTourDataSource = (dataSources: DataSourceModel[]): DataSourceModel => {
  const [first] = dataSources;
  return first;
};

const useGetDataSources = (queryClient: QueryClient): { data: DataSourcesResponse } =>
  queryClient.getQueryData<{ data: DataSourcesResponse }>([DATA_SOURCE_CONNECTIONS_QUERY_KEY]) ?? {
    data: { ds_connections: [], totalCount: 0 },
  };

export const useDataSourceConnectionTour = (
  shouldDisplayGuidedTour: boolean,
  queryClient: QueryClient,
  isLoadingCache: boolean,
) => {
  const [guidedTourDataSourceId, setGuidedTourDataSourceId] = useState<string>();
  const [showGuidedTour] = useState<boolean>(() => {
    const skipGuidedTour = localStorageService.get<boolean>('skipGuidedTour');
    return shouldDisplayGuidedTour && (isBoolean(skipGuidedTour) ? !skipGuidedTour : skipGuidedTour !== 'true');
  });

  const {
    data: { ds_connections: dataSources },
  } = useGetDataSources(queryClient);
  const handleEndTour = () => localStorageService.set('skipGuidedTour', true);

  const handleSkipTour = (props: CallbackProps) => {
    const event = getGuidedTourDataSourceEvent(props);
    handleEndTour();

    event && trackEventDataSources(event);
  };

  const handleCompleteTour = (props: CallbackProps) => {
    const event = getGuidedTourDataSourceEvent(props);
    handleEndTour();

    event && trackEventDataSources(event);

    $state.go(CONFIG.states.CONFIG_DATA_SOURCE, {
      id: guidedTourDataSourceId,
      shouldOpenNewModal: true,
    });
  };

  const handleTourStateChange = (props: CallbackProps) => {
    const event = getGuidedTourDataSourceEvent(props);
    event && trackEventDataSources(event);
  };

  useEffect(() => {
    const dataSourceName = getGuidedTourDataSource(dataSources)?.name;
    !isLoadingCache && setGuidedTourDataSourceId(dataSourceName);
  }, [isLoadingCache]);

  return {
    showGuidedTour,
    guidedTourDataSourceId,
    handleSkipTour,
    handleCompleteTour,
    handleEndTour,
    handleTourStateChange,
  };
};
