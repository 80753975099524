import { useState, useCallback, useEffect } from 'react';
import { AsyncOperation } from '../../../../../../components/AsyncOperationProcessingWidget/AsyncOperationProcessingWidget';
import { SSEDataMessage, subscribeToRepeatedSSEEventById } from '../../../../../../services/sseService';
import { useLocalTranslation } from '../../../../translations';
import {
  DataExplorerAsyncOperationRoutingKey,
  DataExplorerAsyncOperationStatus,
  tagBulkAssignmentNameMapping,
  TagBulkAssignmentOperationsSorted,
  TagBulkAssignmentSseResults,
  UseTagBulkAssignmentParams,
  UseTagBulkAssignmentResponse,
} from '../../../../types/DataExplorerAsyncOpsTypes';

export const useTagBulkAssignment = ({
  onOperationRun,
  onOperationCompleted,
}: UseTagBulkAssignmentParams): UseTagBulkAssignmentResponse => {
  const [operations, setOperations] = useState<AsyncOperation[]>([]);
  const [isUnAssignmentInProcess, setIsUnAssignmentInProcess] = useState<boolean>(false);
  const { t } = useLocalTranslation('operations.tags');

  useEffect(() => {
    onOperationRun(DataExplorerAsyncOperationRoutingKey.TAG_BULK_ASSIGNMENT, isUnAssignmentInProcess);
  }, [isUnAssignmentInProcess, onOperationRun]);

  const handleTagBulkAssignmentBroadcastEventReceived = useCallback(
    ({ results = [] }: SSEDataMessage<TagBulkAssignmentSseResults>) => {
      const operationsSortedSkeleton = Object.keys(DataExplorerAsyncOperationStatus).reduce(
        (operationsSortedBase, status) => ({ ...operationsSortedBase, [status]: [] }),
        {},
      ) as TagBulkAssignmentOperationsSorted;
      const operationsSorted = results.reduce((operationsSortedAggr, operation) => {
        const { status } = operation;
        return { ...operationsSortedAggr, [status]: [...(operationsSortedAggr[status] || []), operation] };
      }, operationsSortedSkeleton);

      const operationsUpdated = [
        ...operationsSorted.ERROR,
        ...operationsSorted.COMPLETED,
        ...operationsSorted.RUNNING,
        ...operationsSorted.NOT_STARTED,
      ].reduce((operationsAggr, serverOperation) => {
        const { name, payload, status, totalObjects, percentage, taskStatus, indexingStatus } = serverOperation;

        if (payload?.tags.length > 0) {
          const hasIndexingPostTask = taskStatus && indexingStatus;
          const { tagName, tagValue } = payload.tags[0];
          const taskSubject = `"${tagName}:${tagValue}"`;
          const taskDescription =
            totalObjects > 0 ? t('assign.description.total', { totalObjects }) : t('description.counting');
          const taskOperation = {
            status: hasIndexingPostTask ? taskStatus : status,
            percentage:
              status === DataExplorerAsyncOperationStatus.RUNNING && typeof percentage === 'number'
                ? Math.round(percentage)
                : undefined,
            name: tagBulkAssignmentNameMapping[name],
            description: taskDescription,
            entityName: taskSubject,
          };

          operationsAggr.push(taskOperation);

          if (hasIndexingPostTask && totalObjects > 0) {
            const indexingOperation = {
              status: indexingStatus,
              name: t('indexing'),
              description: t('assign.description.indexing', { totalObjects }),
              entityName: taskSubject,
            };

            operationsAggr.push(indexingOperation);
          }

          return operationsAggr;
        } else {
          return operationsAggr;
        }
      }, []);

      const hasCompletedOperations = operationsUpdated.some(
        ({ status }) => status === DataExplorerAsyncOperationStatus.COMPLETED,
      );

      if (hasCompletedOperations) {
        onOperationCompleted();
      }

      setOperations(operationsUpdated);
      setIsUnAssignmentInProcess(!!results.length);
    },
    [onOperationCompleted, t],
  );

  useEffect(() => {
    const unsubscribe = subscribeToRepeatedSSEEventById(
      DataExplorerAsyncOperationRoutingKey.TAG_BULK_ASSIGNMENT,
      handleTagBulkAssignmentBroadcastEventReceived,
    );

    return () => {
      unsubscribe();
    };
  }, [handleTagBulkAssignmentBroadcastEventReceived]);

  return { operations };
};
