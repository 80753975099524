import React, { FC, useCallback, useEffect } from 'react';
import { orderBy, find, some } from 'lodash';
import Grid from '@mui/material/Grid';
import {
  TabUrls,
  ApplicationsManagementTabProps,
  CategoriesEnum,
  onSelectFavorite,
} from '../applicationManagementService';
import makeStyles from '@mui/styles/makeStyles';
import { BigidColors } from '@bigid-ui/components';
import { ApplicationCard } from '../Components/ApplicationCard';
import { CustomAppIcon } from '../Components/CustomAppIcon';
import { AppLicenseType, appsLicenseService } from '../../../services/appsLicenseService';
import { tpaStatusService } from '../../../services/tpaStatusService';
import { TPAPageViewsEvents, trackTPAPageView } from '../../CustomApp/customAppEventsTrackerUtils';
import { BigidFavoritesIllustration, BigidAppsIllustration } from '@bigid-ui/icons';
import { getCustomAppNormalizedFriendlyName } from '../../CustomApp/utils/CustomAppUtils';
import { getApplicationPreference } from '../../../services/appPreferencesService';

const useStyles = makeStyles({
  root: {
    overflow: 'scroll',
    padding: 8,
    height: 'inherit',
  },
});

const useStylesEmpty = makeStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: 'inherit',
    flexGrow: 1,
  },
  text: {
    fontSize: '0.8125rem',
    color: BigidColors.gray[400],
    marginTop: 30,
  },
});

const EmptyState: FC<Pick<ApplicationsManagementTabProps, 'preference'>> = ({ preference }) => {
  const emptyStateClasses = useStylesEmpty({});
  const Icon = preference === TabUrls.FAVORITES ? BigidFavoritesIllustration : BigidAppsIllustration;
  const text =
    preference === TabUrls.FAVORITES
      ? 'Apps you favorite will appear here'
      : 'Your most recently used apps will appear here';
  return (
    <div className={emptyStateClasses.root}>
      <Icon />
      <div className={emptyStateClasses.text}>{text}</div>
    </div>
  );
};

export const PreferenceTab: FC<ApplicationsManagementTabProps> = ({
  apps,
  preference,
  selectedCategory,
  customApps = [],
  favorites = [],
  recentlyUsed = [],
  fetchUserPreferences,
}) => {
  const classes = useStyles({});

  useEffect(() => {
    trackTPAPageView(TPAPageViewsEvents.TPA_PREFERENCES_APPLICATIONS_VIEW);
  }, []);

  const preferences =
    preference === TabUrls.FAVORITES
      ? orderBy(favorites, ['dateAdded'], ['desc']).map(({ appId }) => appId)
      : orderBy(recentlyUsed, ['lastUsed'], ['desc']).map(({ appId }) => appId);

  const selectFavorite = useCallback(
    (id: string) => async () => {
      await onSelectFavorite(id);
      await fetchUserPreferences();
    },
    [fetchUserPreferences],
  );

  const appsForCategory = apps.filter(
    ({ category }) => selectedCategory === CategoriesEnum.ALL || category === selectedCategory,
  );

  const preferenceForCategory = preferences.filter(appId => {
    const app = find(appsForCategory, { id: appId });
    const customApp = find(customApps, { _id: appId });
    if (!app && !customApp) {
      return false;
    }
    if (selectedCategory === CategoriesEnum.ALL) {
      return true;
    }
    return app?.category === selectedCategory || customApp?.category === selectedCategory;
  });

  if (preferenceForCategory.length === 0) {
    return <EmptyState preference={preference} />;
  }

  return (
    <div className={classes.root}>
      <Grid container spacing={2} alignItems="stretch">
        {preferenceForCategory.map(appId => {
          const app = find(appsForCategory, { id: appId });
          const customApp = find(customApps, { _id: appId });
          if (app) {
            const { id, name, description, to, link, icon, onClick, dataAid, isCertified, isPaid } = app;
            const favorite = some(favorites, { appId });
            return (
              <Grid item key={id} xs={12} sm={6} md={4} lg={3}>
                <ApplicationCard
                  favorite={favorite}
                  name={name}
                  description={description}
                  icon={icon}
                  to={to}
                  link={link}
                  onClick={onClick}
                  onSelectFavorite={selectFavorite(id)}
                  dataAid={dataAid}
                  isCertified={isCertified}
                  isPaid={isPaid}
                />
              </Grid>
            );
          } else {
            const {
              logo,
              tpa_name: name,
              manifest_name,
              friendlyName,
              description,
              _id: id,
              vendor,
              version,
              status,
              license_verification_key,
              license_type,
              settings,
            } = customApp;
            const favorite = some(favorites, { appId });
            const isPaid = license_type.toLowerCase() === AppLicenseType.PAID;
            const displayName = friendlyName
              ? getCustomAppNormalizedFriendlyName(friendlyName, settings?.instanceId)
              : name;
            return (
              <Grid item key={id} xs={12} sm={6} md={4} lg={3}>
                <ApplicationCard
                  id={id}
                  custom
                  favorite={favorite}
                  name={displayName}
                  tpaName={name}
                  hasManifestName={!!manifest_name}
                  description={description}
                  logo={logo && <CustomAppIcon logo={logo} />}
                  onClick={() =>
                    appsLicenseService.showAppExpirationNotificationForCustomAppAndGoToCustomAppPage(
                      id,
                      name,
                      vendor,
                      manifest_name,
                    )
                  }
                  onSelectFavorite={selectFavorite(id)}
                  version={version}
                  status={tpaStatusService.permitTpaUpdateStatus(status, name)}
                  dataAid={`open-app-${name}-${vendor}`}
                  isCertified={!!license_verification_key}
                  isPaid={isPaid}
                />
              </Grid>
            );
          }
        })}
      </Grid>
    </div>
  );
};
