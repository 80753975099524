import './business-step.component.scss';
import { module } from 'angular';
import template from './business-step.component.html';
import { BUSINESS_FLOWS_PERMISSIONS } from '@bigid/permissions';
import { isPermitted } from '../../../react/services/userPermissionsService';
const app = module('app');

app.component('businessStep', {
  template,
  controller: [
    function () {
      this.tab = 'settings';

      this.hasManagePermission = isPermitted(BUSINESS_FLOWS_PERMISSIONS.MANAGE.name);

      this.$onChanges = changes => {
        if (changes.connector) {
          this.tab = 'settings';
        }

        if (this.connector.direction) {
          this.vm.selectedConnector.direction = this.vm.selectedConnector.direction;
        } else {
          this.vm.selectedConnector.direction = 'outbound';
        }
        this.connector.flowId = this.connector.flowId || this.vm.businessFlows[0]._id;
        this._setNextStepNumber(this.connector.flowId, true);
      };
      this.setFlowId = flowId => {
        this.connector.flowId = flowId;
        this._setNextStepNumber(flowId, false);
      };

      this._setNextStepNumber = (flowId, isFromOnInit) => {
        if (this.connector._id) {
          if (!isFromOnInit) {
            this.vm.selectedConnector.flowId = flowId;
            this.vm.saveConnector(false, 'Flow field was updated!', this.vm.selectedConnector);
          }
          return;
        }

        this.connector.stepNumber =
          Math.max(
            0,
            ...this.vm.connectors
              .filter(connector => connector._id !== this.connector._id && connector.flowId === this.connector.flowId)
              .map(connector => connector.stepNumber),
          ) + 1;
        this.vm.selectedConnector.stepNumber = this.connector.stepNumber;
        this.vm.saveConnector(false, 'Flow field was created!', this.vm.selectedConnector);
      };

      this.onStepNumberInputClick = e => {
        if (e.target != document.activeElement) e.target.focus();
      };
    },
  ],
  bindings: {
    vm: '<',
    connector: '<',
  },
});
