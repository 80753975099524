import React, { FC, useMemo } from 'react';
import BigidLogoNoText from '../../../assets/icons/BigidLogoNoText.svg';
import { generateDataAid } from '@bigid-ui/utils';
import { useLocalTranslation } from '../translations';
import styled from '@emotion/styled';
import { BigidHeading6 } from '@bigid-ui/components';

const Modal = styled('div')({
  display: 'flex',
  justifyContent: 'center',
  paddingLeft: '48px',
  paddingRight: '48px',
  flexDirection: 'column',
  alignItems: 'center',
  textAlign: 'left',
  whiteSpace: 'pre-wrap',
  gap: '16px',
});

export const DisclaimerModalContent: FC = () => {
  const { t } = useLocalTranslation();

  const { headerText, contentText } = useMemo(() => {
    return {
      headerText: t('disclaimer.modal.header'),
      contentText: t('disclaimer.modal.content'),
    };
  }, []);

  return (
    <Modal data-aid={`${generateDataAid('BigChat', ['disclaimer', 'modal'])}`}>
      <BigidLogoNoText />
      <BigidHeading6 sx={{ fontSize: '22px' }}>{`${headerText}`}</BigidHeading6>
      {contentText}
    </Modal>
  );
};
