import { httpService, QueryParamsAndDataObject } from '../../services/httpService';
import { BigidGridQueryComponents } from '@bigid-ui/grid';
import { queryService } from '../../services/queryService';

export const COMPLIANCE_FRAMEWORKS = 'compliance-frameworks';

export interface ControlsProps {
  id: string;
  _id: string;
  category: number | string;
  description: string;
  name: string;
  framework_name: string;
}

export interface FrameworkProps {
  id: string;
  _id: string;
  framework_name: string;
  number_of_controls?: number;
  enabled?: boolean;
  controls?: ControlsProps[];
  list?: any[];
  category: number | string;
}

export interface IconsShcemaProps {
  [key: string]: any;
}

export interface FrameworkUpdatedProps {
  data: FrameworkProps[];
  amountOfControls: number;
}

export const fetchFrameworks = async (queryComponents: BigidGridQueryComponents) => {
  try {
    const gridConfigQuery = queryService.getGridConfigQuery(queryComponents);
    const {
      data: { data },
    } = await httpService.fetch(`${COMPLIANCE_FRAMEWORKS}?${gridConfigQuery}`);
    return data;
  } catch (e) {
    console.error(e);
  }
};

export const fetchFrameworksControls = async (name: string) => {
  try {
    const {
      data: { data },
    } = await httpService.fetch(`${COMPLIANCE_FRAMEWORKS}/${name}`);
    return data;
  } catch (e) {
    console.error(e);
  }
};

export const updateFrameworksStatus = async (body: QueryParamsAndDataObject) => {
  try {
    await httpService.put(`${COMPLIANCE_FRAMEWORKS}/enabled-frameworks`, body);
  } catch (e) {
    console.error(e);
  }
};
