import { createContext } from 'react';
import { SelectionData, PreDiscoverReducerAction, ConnectionsState } from './PreDiscoverReducer';
import { BigidWizardHorizontalStepStatus } from '@bigid-ui/components';

export interface WizardActions {
  setIsNextButtonDisabled: (val: boolean) => void;
  setStepAsActive: () => void;
  disableNextSteps: () => void;
  isNextStepDisabled: () => boolean;
  getCurrentStepStatus: () => BigidWizardHorizontalStepStatus;
}

export interface PreDiscoverContextData {
  selectionsData: Partial<SelectionData>;
  wizardActions: WizardActions;
  connections: ConnectionsState;
  isSelectionChanged: boolean;
  isFirstWizardUsage?: boolean;
  dispatch: React.Dispatch<PreDiscoverReducerAction>;
}

export const PreDiscoverContext = createContext<PreDiscoverContextData>(null);
