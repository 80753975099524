import React, { useCallback, useEffect, useState } from 'react';
import { BigidBody1, BigidLoader, TertiaryButton } from '@bigid-ui/components';
import { BigidSyncIcon } from '@bigid-ui/icons';

import { httpService } from '../../../services/httpService';
import { getApplicationPreference } from '../../../services/appPreferencesService';
import { DataSourceDeletionModel } from './DataMinimizationDataSources';
import { AxiosResponse } from 'axios';
import { IPromise } from 'angular';
import { isString } from 'lodash';
import styled from '@emotion/styled';

const ProgressCellRoot = styled('div')`
  display: flex;
  justifycontent: space-between;
  alignitems: center;
  gap: 5px;
`;

const ValueWrapper = styled('div')`
  min-width: 110px;
  padding-top: 2px;
`;

const LoaderWrapper = styled('div')`
  position: relative;
  height: 20px;
  paddingleft: 10px;
`;

type CountValueOrPromise = string | IPromise<AxiosResponse<any>>;

const countRequestsListeners: Record<string, (val: string) => void> = {};
const countRequestsValues: Record<string, CountValueOrPromise> = {};

const fetchProgressCount = async (name: string) => {
  try {
    const requestPromise = httpService.fetch<{ data: any }>(`data-minimization/datasource-state?ds_name=${name}`);
    countRequestsValues[name] = requestPromise;

    const {
      data: {
        data: { states_str },
      },
    } = await requestPromise;

    countRequestsValues[name] = String(states_str);
    return states_str;
  } catch {
    countRequestsValues[name] = null;
  }
};

export const ProgressCell = ({ name, states_str }: DataSourceDeletionModel) => {
  const [value, setValue] = useState<CountValueOrPromise>(countRequestsValues[name] || null);
  const requestValue = useCallback(async (e: any) => {
    e.stopPropagation();
    setValue(Promise.resolve(null));

    try {
      const count = await fetchProgressCount(name);
      countRequestsValues[name] = count;
      countRequestsListeners[name](count);
    } catch {
      countRequestsListeners[name](null);
      console.error('Could not fetch progress count for ' + name);
    }
  }, []);

  useEffect(() => {
    countRequestsListeners[name] = (val: string) => {
      setValue(val);
    };
  }, []);

  return (
    <ProgressCellRoot>
      {!getApplicationPreference('DATA_MINIMIZATION_PROGRESS_COUNT_ENABLED') ? (
        <>
          <ValueWrapper>
            {!value ? (
              <BigidBody1 fontSize="small">Refresh to display</BigidBody1>
            ) : isString(value) ? (
              <BigidBody1>{value}</BigidBody1>
            ) : (
              <LoaderWrapper>
                <BigidLoader size={20} />
              </LoaderWrapper>
            )}
          </ValueWrapper>
          <div>
            <TertiaryButton size="small" onClick={requestValue} disabled={value instanceof Promise}>
              <BigidSyncIcon />
            </TertiaryButton>
          </div>
        </>
      ) : (
        <>{states_str}</>
      )}
    </ProgressCellRoot>
  );
};
