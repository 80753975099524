import React, { FC, useContext, useCallback, useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { CaseSidePanelContext } from '../../hooks/CaseSidePanelContext';
import { BigidUserIcon, BigidCreateTicketIcon } from '@bigid-ui/icons';
import {
  BigidBody1,
  BigidTooltip,
  TertiaryButton,
  EntityEvents,
  entityEventsEmitter,
  BigidDropdownOption,
  BigidSeverityBadge,
  BigidStatusBadgeSize,
} from '@bigid-ui/components';
import { dateUtils } from '../../../../../services/angularServices';
import { generateDataAid, generateGuidedTourId } from '@bigid-ui/utils';
import {
  isUrl,
  CaseStatus,
  syncAndGetLatestTicketStatus,
  applyTicketSyncWithNewCaseStatus,
  getJiraConfigurations,
  STORED_SERVICE_NAME,
} from '../../../actionableInsightsService';
import { useLocalTranslation } from '../../../translations';
import { getTextColorByStatus } from '../../../ActionableInsightsComponents/CaseStatusIndicator';
import { CaseActionsModal } from '../../../ActionableInsightsGridViews/CaseActionsModal/CaseActionsModal';
import { ActionsDialogTypes } from '../../../ActionableInsightsGridViews/CaseActionsModal/hooks/useCaseActions';
import { updateCaseStatus } from '../../../ActionableInsightsGridViews/CaseActionsModal/caseActionsService';
import { getActionTypeForStatus, getSelectionOptionsForSelectedType } from '../caseReportService';
import { ActionableInsightsContext } from '../../../hooks/ActionableInsightsContext';
import { CaseActionsWidget } from './CaseActionWidget/CaseActionsWidget';
import { ReducerActions } from '../../../hooks/ActionableInsightsReducer';
import { getApplicationPreference } from '../../../../../services/appPreferencesService';
import { ServiceTicketingType } from '../../../ActionableInsightsGridViews/CaseActionsModal/ModalContent/TicketModal/TicketContent';
import { CaseStatusWidget } from './CaseStatusWidget/CaseStatusWidget';

const MetaDataInfo = styled.div({
  display: 'flex',
  flexDirection: 'row',
  width: '100%',
});

const SectionWrapper = styled.div({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  padding: 24,
  width: '100%',
});

const ActionsWrapper = styled.div({
  marginLeft: 'auto',
  display: 'flex',
  alignItems: 'center',
  gap: '8px',
});

const BigidChipWrapper = styled.div({
  marginRight: 8,
});

const StatusSelector = styled('div')<{ status: CaseStatus }>`
  width: 156px;
  & p {
    color: ${({ status, theme }) => getTextColorByStatus(status, theme)};
  }
`;

export const MetaDataWidget: FC = () => {
  const { caseSidePanelData, onCaseAction } = useContext(CaseSidePanelContext);
  const { dispatch } = useContext(ActionableInsightsContext);
  const { t } = useLocalTranslation();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalType, setModalType] = useState<ActionsDialogTypes>();
  const [assignee, setAssignee] = useState(caseSidePanelData?.assignee);
  const [jiraTicketUrl, setJiraTicketUrl] = useState(caseSidePanelData?.ticketUrl);
  const [ticketMetadata, setTicketMetadata] = useState(caseSidePanelData?.ticketMetadata);
  const [caseStatus, setCaseStatus] = useState(caseSidePanelData?.caseStatus);
  const [selectionOptions, setSelectionOptions] = useState(
    getSelectionOptionsForSelectedType(caseSidePanelData?.caseStatus),
  );
  const [isShowingLoadingMessage, setIsShowingLoadingMessage] = useState(false);
  const filterCase = { caseIds: [caseSidePanelData.id as string] };
  const jiraTicketNumber = jiraTicketUrl && ticketMetadata?.key;

  const openModal = (newModalType: ActionsDialogTypes) => {
    setModalType(newModalType);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setModalType(null);
    setIsModalOpen(false);
  };

  const onJiraButtonClick = () => {
    if (isUrl(jiraTicketUrl)) {
      window.open(jiraTicketUrl, '_blank');
    } else {
      openModal(ActionsDialogTypes.JIRA);
    }
  };

  const handlePayloadExternalUpdate = useCallback(
    (
      _rowId: string | number,
      payloadChanges: { assignee: string; ticketUrl: string; caseStatus: CaseStatus; ticketMetadata: any },
    ) => {
      if (payloadChanges?.ticketUrl) {
        setJiraTicketUrl(payloadChanges.ticketUrl);
      } else if (!payloadChanges?.ticketUrl && payloadChanges?.caseStatus === CaseStatus.REMEDIATED) {
        setJiraTicketUrl(undefined);
      }
      payloadChanges?.ticketMetadata && setTicketMetadata(payloadChanges.ticketMetadata);
      payloadChanges?.assignee && setAssignee(payloadChanges.assignee);
      if (payloadChanges?.caseStatus) {
        setCaseStatus(payloadChanges.caseStatus);
        setSelectionOptions(getSelectionOptionsForSelectedType(payloadChanges.caseStatus));
        setIsShowingLoadingMessage(false);
      }
    },
    [],
  );

  const onSelectionChange = async (value: BigidDropdownOption[]) => {
    const newStatus = value[0]?.value;
    if (Object.values(CaseStatus).includes(newStatus)) {
      if (newStatus === CaseStatus.OPEN) {
        setIsShowingLoadingMessage(true);
        updateCaseStatus({ caseIds: [caseSidePanelData.id] }, CaseStatus.OPEN, null, onModalCtaClick);
      } else {
        setModalType(getActionTypeForStatus(newStatus));
        setIsModalOpen(true);
      }
    }
  };

  const onModalCtaClick = (modifiedValue?: string) =>
    onCaseAction(modifiedValue, { caseIds: [caseSidePanelData.id] }, modalType);

  useEffect(() => {
    const unregister = entityEventsEmitter.addEventListener(EntityEvents.UPDATE_BY_ID, handlePayloadExternalUpdate);

    return () => {
      unregister();
    };
  }, [handlePayloadExternalUpdate]);

  useEffect(() => {
    const syncCaseStatus = async () => {
      const latestTicketStatus = await syncAndGetLatestTicketStatus(
        caseSidePanelData?.id,
        caseSidePanelData?.ticketMetadata?.configurationId || caseSidePanelData?.ticketMetadata?.id,
        caseSidePanelData?.ticketMetadata?.key,
        caseSidePanelData?.ticketMetadata?.ticketType,
      );
      if (caseSidePanelData.caseStatus !== latestTicketStatus) {
        applyTicketSyncWithNewCaseStatus(caseSidePanelData?.id, latestTicketStatus, dispatch);
      }
    };
    if (caseSidePanelData?.ticketUrl && caseSidePanelData?.ticketMetadata) {
      syncCaseStatus();
    }
  }, [
    caseSidePanelData?.caseStatus,
    caseSidePanelData?.id,
    caseSidePanelData?.ticketMetadata?.configurationId,
    caseSidePanelData?.ticketUrl,
    caseSidePanelData?.ticketMetadata,
    dispatch,
  ]);

  useEffect(() => {
    caseSidePanelData?.ticketUrl && setJiraTicketUrl(caseSidePanelData?.ticketUrl);
  }, [caseSidePanelData?.ticketUrl]);

  useEffect(() => {
    const updateLatestConfig = async () => {
      const latestConfigurations = await getJiraConfigurations();
      const isServiceNowPermitted = getApplicationPreference('DSPM_SERVICE_NOW');

      const onlineServices = latestConfigurations.filter(config => {
        if (config?.type === ServiceTicketingType.SERVICE_NOW) {
          if (!isServiceNowPermitted) {
            return false;
          } else if (config.isOffline === true) {
            return false;
          } else {
            return true;
          }
        } else {
          return true;
        }
      });
      dispatch({
        type: ReducerActions.UPDATE_CONFIGURATIONS,
        payload: { jiraConfigurations: onlineServices },
      });
      const storedService = JSON.parse(localStorage.getItem(STORED_SERVICE_NAME));
      const isSelectedServiceOnline = onlineServices.find(config => config.id === storedService?.id);

      if (!isSelectedServiceOnline) {
        dispatch({
          type: ReducerActions.UPDATE_LATEST_TICKETING_SERVICE,
          payload: { selectedTicketingService: latestConfigurations[0] },
        });
      } else {
        dispatch({
          type: ReducerActions.UPDATE_LATEST_TICKETING_SERVICE,
          payload: { selectedTicketingService: storedService },
        });
      }
    };
    updateLatestConfig();
  }, []);

  return (
    <MetaDataInfo
      data-aid={generateDataAid('MetaDataWidget', ['main-container'])}
      data-tour-id={generateGuidedTourId('MetaDataWidget', ['main-container'])}
    >
      <SectionWrapper>
        <BigidChipWrapper>
          <BigidSeverityBadge
            level={caseSidePanelData?.severityLevel}
            size={BigidStatusBadgeSize.SMALL}
            hasBackground={true}
          />
        </BigidChipWrapper>
        {caseSidePanelData?.created_at && (
          <BigidTooltip title={t('CaseReport.lastUpdate')} placement="top">
            <BigidBody1 data-aid={generateDataAid('MetaDataWidget', ['case-updated-at'])} paddingLeft="8px">
              {dateUtils.formatDate(caseSidePanelData.created_at, { formatString: 'MMM do, yyyy, HH:mm' })}
            </BigidBody1>
          </BigidTooltip>
        )}
        <ActionsWrapper>
          <TertiaryButton
            dataAid={generateDataAid('MetaDataWidget', ['createJiraTicket', 'button'])}
            onClick={onJiraButtonClick}
            size="medium"
            startIcon={<BigidCreateTicketIcon />}
            text={jiraTicketNumber || 'Create Ticket'}
          />
          <TertiaryButton
            dataAid={generateDataAid('MetaDataWidget', ['assignUser', 'dropdown'])}
            onClick={() => openModal(ActionsDialogTypes.ASSIGN)}
            size="medium"
            startIcon={<BigidUserIcon />}
            text={assignee}
          />
          <CaseActionsWidget />
          {isShowingLoadingMessage ? (
            <BigidBody1 width={156}>{t('Case.common.messages.pleaseWait')}</BigidBody1>
          ) : (
            <StatusSelector
              status={caseStatus}
              data-aid={generateDataAid('MetaDataWidget', ['selected-status', caseStatus])}
            >
              <CaseStatusWidget
                selectionOptions={selectionOptions}
                currentValue={getSelectionOptionsForSelectedType(caseStatus, true)}
                onChange={onSelectionChange}
              />
            </StatusSelector>
          )}
        </ActionsWrapper>
      </SectionWrapper>

      <CaseActionsModal
        isOpen={isModalOpen}
        closeModal={closeModal}
        rowsData={[caseSidePanelData]}
        type={modalType}
        onCaseAction={onModalCtaClick}
        filters={filterCase}
      />
    </MetaDataInfo>
  );
};
