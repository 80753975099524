import { unionBy, groupBy, toPairs, sortBy, fromPairs, maxBy, size } from 'lodash';
import {
  DataSourceTestConnectionRow,
  DataSourceTestConnectionRowType,
} from '../../DataSourcesTestConnectionGrid/DataSourcesTestConnectionGrid';
import { docsUrls } from '../../../../config/publicUrls';

const DEFAULT_SORT_ORDER_FIELD = 0; // @info sort by first element of the tuple

/**
 * @info removes duplicate elements retaining sort order (elements first in the sort order are picked as unique)
 * @summary the function works the same as lodash 'uniqBy' passing a 'uniqFn' function to pick object elements to compare
 * but with an added 'sortFn' sort order function which instead of picking the first instance of a unique object sorts the elements
 * beforehand and picks the first sorted element giving the user a way of selecting which unique element to keep
 **/
export const uniqByWithOrder = <T>(array: T[], uniqFn: (element: T) => unknown, sortFn: (element: T) => unknown) => {
  const sorted = fromPairs(sortBy(toPairs(groupBy(array, sortFn)), DEFAULT_SORT_ORDER_FIELD));
  return unionBy<T>(...(Object.values(sorted) as [T[], T[]]), uniqFn);
};

/**
 * @info sorts an array of objects by using an additional array
 * @summary sorts an array of objects by comparing the selected object prop with it's position within the sortArray
 **/
export const sortByArray = <T = unknown>(arr: T[], prop: keyof T, sortArray: string[]) =>
  arr.sort((a, b) => {
    const [indexA, indexB] = [a, b].map(compared =>
      sortArray.findIndex(item => item === (compared[prop] as unknown as string)),
    );
    return indexA - indexB;
  });

/**
 * @info add the id property to each item in the array;
 * @summary add the id property to each item in the array;"
 **/
export const addIdToArray = <T = unknown>(arr: T[]) => {
  return arr.map((item, index) => ({ id: index.toString(), ...item }));
};

/**
 * @info get the most common objectType from tablesResult;
 * @summary Returns the most common objectType from tablesResult based on the enum DataSourceTestConnectionRowType. If not found, it returns null."
 **/
export const getMostCommonObjectType = (
  tablesResult: DataSourceTestConnectionRow[],
): DataSourceTestConnectionRowType | null => {
  const validResults = tablesResult.filter(result => result.objectType in DataSourceTestConnectionRowType);
  const groupedByObjectType = groupBy(validResults, 'objectType');
  const mostCommonObjectType = maxBy(Object.keys(groupedByObjectType), type =>
    size(groupedByObjectType[type]),
  ) as DataSourceTestConnectionRowType;
  return mostCommonObjectType || null;
};

export const generateDsDocsUrl = (nameInDocs: string) => {
  return `${docsUrls.DS_DOCS_URL_PREFIX}${(nameInDocs || 'bigid-supported-data-sources').toLowerCase()}`;
};

export const addPropertyToAllObjectsInArray = <T = unknown>(arr: T[], property: string, value: any) => {
  return arr.map(item => ({ ...item, [property]: value }));
};

export const hash = (content: string) => {
  let hash = 0;
  for (let i = 0; i < content.length; i++) {
    const char = content.charCodeAt(i);
    hash = (hash << 5) - hash + char;
    hash = hash & hash; // Convert to 32bit integer
  }
  return Math.abs(hash);
};

export const convertNewLineWithHtmlTag = (content: string) => content.replace(/\n/g, '<br />');
