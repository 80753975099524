import { BigidDsTextHighlighter, BigidLoader, FileContentAttribute, FileTextContents } from '@bigid-ui/components';
import React, { useEffect, useLayoutEffect, useMemo, useState, forwardRef, useImperativeHandle } from 'react';
import { useDsObjectFilePreview } from '../hook/useDsObjectFilePreview';
import { ObjectPreviewDSPMEvents, trackManualEvent } from '../events';
import { getApplicationPreference } from '../../../../../../services/appPreferencesService';
import styled from '@emotion/styled';
import { DsObjectPreviewFileProps } from '../hook/DsObjectFilePreviewService';

const Main = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  wordbreak: break-word;
  position: relative;
`;

export interface FilePreviewRefProps {
  clearCache: () => void;
}

const DEFAULT_POLLING_PERMISSIBLE_TIMEOUT = 30000;

export const DsObjectPreviewFile = forwardRef<FilePreviewRefProps, DsObjectPreviewFileProps>(
  (
    {
      fullyQualifiedName,
      scannerType,
      source,
      filteredAttribute,
      caseId,
      objectPreviewData,
      handleScanStatus,
      handleSegmnetsExists,
      handleVisibilityCacheButton,
    },
    ref,
  ) => {
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [filteredChunks, setFilteredChunks] = useState([]);
    const { isPollingLimitedWithTimeout, pollingPermissibleTimeout } = useMemo(() => {
      const pollingPermissibleTimeout = getApplicationPreference('FILE_PREVIEW_TIMEOUT');

      return {
        pollingPermissibleTimeout: isNaN(pollingPermissibleTimeout)
          ? DEFAULT_POLLING_PERMISSIBLE_TIMEOUT
          : pollingPermissibleTimeout,
        isPollingLimitedWithTimeout: Boolean(getApplicationPreference('FILE_PREVIEW_TIMEOUT_ENABLED')),
      };
    }, []);

    const {
      isFetchingFromScan,
      isFetchingFromCache,
      isInitiallyFetched,
      isScanFailed,
      isContentCached,
      isSegmentsWithAttributesOnly,
      isNoAttributesFile,
      chunks,
      nextChunkIndex,
      onPreviewClick,
      onSegmentsWithAttributesOnlyToggle,
      onLoadMoreClick,
      onClearCacheClick,
    } = useDsObjectFilePreview({
      fullyQualifiedName,
      isPollingLimitedWithTimeout,
      pollingPermissibleTimeout,
      scannerType,
      source,
      attributeFilter: filteredAttribute,
    });

    const handleLoadMore = () => {
      onLoadMoreClick();
      trackManualEvent({
        appInfo: ObjectPreviewDSPMEvents.DSPM_OBJECT_LOAD_MORE,
        appData: {
          caseId,
          objectId: objectPreviewData?._id,
          attributeName: filteredAttribute,
          timeStamp: Date.now(),
        },
      });
    };

    const handleInitialLoad = async () => {
      const isLoadedFirstTime = isInitiallyFetched && chunks.length === 0;
      if (!isSegmentsWithAttributesOnly && isLoadedFirstTime) {
        await onSegmentsWithAttributesOnlyToggle(true);
      } else {
        const isChunkExist = chunks.length > 0;
        isLoadedFirstTime && (await onPreviewClick());
        isChunkExist && handleVisibilityCacheButton(isChunkExist);
      }
    };

    useLayoutEffect(() => {
      handleInitialLoad();
      setIsLoading(!isContentCached);
    }, [isContentCached, isSegmentsWithAttributesOnly, isInitiallyFetched]);

    useEffect(() => {
      const filteredByAttribute = chunks.map((chunk: FileTextContents) => ({
        ...chunk,
        attributes: chunk.attributes.filter(
          (attr: FileContentAttribute) => attr?.attributes[0]?.attributeName === filteredAttribute,
        ),
      }));
      setFilteredChunks(filteredByAttribute);
    }, [chunks, filteredAttribute]);

    useEffect(() => {
      if (isScanFailed) {
        handleScanStatus(isScanFailed);
      }
    }, [isScanFailed]);

    useEffect(() => {
      if (!isFetchingFromCache && !isFetchingFromScan && isSegmentsWithAttributesOnly && isNoAttributesFile) {
        handleSegmnetsExists(false);
      }
    }, [isFetchingFromCache, isFetchingFromScan, isSegmentsWithAttributesOnly, isNoAttributesFile]);

    useImperativeHandle(
      ref,
      () => {
        return {
          clearCache() {
            onClearCacheClick();
          },
        };
      },
      [chunks],
    );

    return (
      <Main data-aid="fileObjectPreview">
        {!isLoading &&
          filteredChunks.length > 0 &&
          filteredChunks.map(({ content, attributes }, index) => {
            const isLastChunk = index === filteredChunks?.length - 1;
            return (
              <BigidDsTextHighlighter
                key={index}
                content={content}
                displayLoadMore={!isFetchingFromScan && isLastChunk && nextChunkIndex != null}
                attributes={attributes}
                displayLoader={isLastChunk && isFetchingFromScan}
                onLoadMoreClick={handleLoadMore}
                divideSegment={isSegmentsWithAttributesOnly}
                isLoadMoreFailed={isScanFailed}
              />
            );
          })}
        {isLoading && <BigidLoader position="absolute" />}
      </Main>
    );
  },
);
