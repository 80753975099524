import React, { FC } from 'react';
import { BigidHeading6, BigidLink } from '@bigid-ui/components';
import { Links } from '../BigChatTypes';
import styled from '@emotion/styled';
import { generateDataAid } from '@bigid-ui/utils';
import { getFixedT } from '../translations';

const headingText = `${getFixedT('linksWidget')('header')}`;

const Wrapper = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-end',
  gap: '8px',
  margin: '15px 0px 15px 0px',
  alignSelf: 'stretch',
});

const LinksContainer = styled('div')(({ theme }) => ({
  backgroundColor: theme.vars.palette.bigid.gray150,
  display: 'flex',
  padding: '16px',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'flex-start',
  gap: '4px',
  alignSelf: 'stretch',
  marginLeft: '48px',
  borderRadius: '4px',
}));

export const BigChatLinks: FC = (props: any) => {
  const {
    payload: { resourcesLinks },
  } = props;
  return (
    <Wrapper data-aid={`${generateDataAid('BigChat', ['links', 'wrapper'])}`}>
      {resourcesLinks?.length > 0 && (
        <LinksContainer data-aid={`${generateDataAid('BigChat', ['links', 'container'])}`}>
          <BigidHeading6> {headingText}</BigidHeading6>
          {resourcesLinks.map((link: Links, index: number) => {
            return <BigidLink href={link.url} shouldOpenNewTab text={link.title} underline="hover" key={index} />;
          })}
        </LinksContainer>
      )}
    </Wrapper>
  );
};
