import React, { FC, ReactNode, useEffect } from 'react';
import { BigidGridColumn, BigidGridColumnTypes, BigidGridProps, BigidGrid, useFetch } from '@bigid-ui/grid';
import { BigidDeleteIcon, BigidExternalLinkIcon } from '@bigid-ui/icons';
import { EntityEvents, entityEventsEmitter } from '@bigid-ui/components';
import { SystemRole } from '../../types';
import { useLocalTranslation } from './translations';

interface RolesPreviewProps {
  roles: SystemRole[];
  onDeleteRole: (id: string) => void;
  isEditable: boolean;
  isWithNewWindowIcon?: boolean;
  gridId?: string;
  children?: ReactNode;
  isWithHeading?: boolean;
}

interface GridSystemRole extends SystemRole {
  id: string;
}

export const RolesPreview: FC<RolesPreviewProps> = ({
  children,
  roles,
  onDeleteRole,
  isWithNewWindowIcon,
  isEditable,
  gridId = 'gridRolesShortView',
}) => {
  const { t } = useLocalTranslation();

  useEffect(() => {
    entityEventsEmitter.emit(EntityEvents.RELOAD, gridId);
  }, [roles]);

  const useFetchState = useFetch({
    fetchDataFunction: async () => {
      return Promise.resolve({
        data: roles.map(role => ({
          ...role,
          id: role._id,
        })),
        totalCount: roles.length,
      });
    },
  });

  const columns: BigidGridColumn<GridSystemRole>[] = [
    {
      name: 'displayName',
      title: t('dialogs.createUser.roleLabel'),
      width: 210,
      getCellValue: ({ displayName }) => {
        return {
          link: {
            text: displayName,
            underline: 'none',
          },
          linkParams: {
            isCorporate: true,
          },
        };
      },
      type: BigidGridColumnTypes.LINK,
      formatter: {
        onClick: (): Promise<null> => {
          alert('TBD in next task');
          return Promise.reject();
        },
      },
    },
    {
      name: 'description',
      title: t('dialogs.createUser.descriptionLabel'),
      getCellValue: (row: SystemRole) => row.description,
      type: BigidGridColumnTypes.TEXT,
    },
  ];

  const rolesGridPreviewConfig: BigidGridProps<GridSystemRole> = {
    columns,
    showFilteringControls: false,
    forceSelectAll: false,
    showSelectAll: false,
    showSortingControls: false,
    inlineActions: [
      {
        disable: () => false,
        hideActionInToolBar: true,
        execute: async ({ selectedRows }) => {
          const { id } = selectedRows[0];
          onDeleteRole(id);
          return {
            shouldClearSelection: true,
          };
        },
        icon: BigidDeleteIcon,
        isInline: true,
        label: t('dialogs.createUser.actions.deleteRole'),
        show: () => isEditable,
      },
      {
        disable: () => false,
        hideActionInToolBar: true,
        execute: async () => {
          alert('TBD in Roles story');
          return {
            shouldClearSelection: true,
          };
        },
        icon: BigidExternalLinkIcon,
        isInline: true,
        label: t('dialogs.createUser.actions.openRole'),
        show: () => isWithNewWindowIcon && isEditable,
      },
    ],
    showSelectionColumn: false,
    showResizingControls: true,
    rows: useFetchState.rows,
    skip: useFetchState.skip,
    totalRowsCount: useFetchState.totalRowsCount,
    apiRef: useFetchState.apiRef,
    loading: useFetchState.isLoading,
    onPagingChanged: useFetchState.onPagingChanged,
    onSortingChanged: useFetchState.onSortingChanged,
    onFiltersChange: useFetchState.onFiltersChanged,
    isRowDisabled: () => false,
    showSelectionCheckboxes: false,
    gridId,
  };

  return <>{roles.length > 0 ? <BigidGrid {...rolesGridPreviewConfig} /> : children}</>;
};
