import React from 'react';
import styled from '@emotion/styled';
import { useLocalTranslation } from '../translations';
import { BigidLayoutEmptyState, BigidLayoutEmptyStateAction } from '@bigid-ui/layout';
import { BigidScanIllustration } from '@bigid-ui/icons';
import { $state } from '../../../services/angularServices';
import { getApplicationPreference } from '../../../services/appPreferencesService';
import { CONFIG } from '../../../../config/common';

const Container = styled('div')`
  width: 100%;
  height: 100%;
  position: absolute;
`;

export const NoDataContentActiveScans = ({ totalCountDataSources }: { totalCountDataSources: number }) => {
  const { t } = useLocalTranslation('noDataContentActiveScans');

  const actionsEmptyScans: BigidLayoutEmptyStateAction[] = [
    {
      label: t('createNewScan'),
      execute: async () => {
        $state.go(CONFIG.states.CREATE_SCAN);
        return { shouldGridReload: false };
      },
      show: () => true,
    },
  ];

  const actionsEmptyDataSources: BigidLayoutEmptyStateAction[] = [
    {
      label: t('connectDataSourceButtonText'),
      execute: async () => {
        $state.go(CONFIG.states.DATA_SOURCE_INIT);
        return { shouldGridReload: false };
      },
      show: () => true,
    },
  ];

  return (
    <Container>
      {getApplicationPreference('ENABLE_SCAN_TEMPLATES') ? (
        <>
          {totalCountDataSources === 0 && (
            <BigidLayoutEmptyState
              description={t('connectDataSource')}
              actions={actionsEmptyDataSources}
              illustration={BigidScanIllustration}
            />
          )}

          {totalCountDataSources > 0 && (
            <BigidLayoutEmptyState
              description={t('scanAndDiscover')}
              actions={actionsEmptyScans}
              illustration={BigidScanIllustration}
            />
          )}
        </>
      ) : (
        <BigidLayoutEmptyState description={t('noActiveScans')} illustration={BigidScanIllustration} />
      )}
    </Container>
  );
};
