import classnames from 'classnames';
import React, { FunctionComponent, Fragment, useState, useEffect } from 'react';
import styles from './AccountLicense.scss';
import { notificationService } from '../../../../services/notificationService';
import { httpService } from '../../../../services/httpService';
import { licenseService, License } from '../../../../services/licenseService';
import { smallIdLicenseService } from '../../../../services/smallIdLicenseService';
import { AppLicense, appsLicenseService } from '../../../../services/appsLicenseService';
import { smallidOnboardingService } from '../../../../services/smallIdOnboardingService';
import { BigidBody2 } from '@bigid-ui/components';
import { LicenseInformation } from './LicenseInformation';
import { openOnBoardingModal } from '../../../../components/StartOnboardingModal/onboardingModalService';
import { sessionStorageService } from '../../../../../common/services/sessionStorageService';
import { LicenseForm } from './LicenseForm';

const LICENSE_KEY_SUCCESS = 'License Updated';

interface LicenseResponse {
  license: License;
  supportedApps: AppLicense[];
}

export const AccountLicense: FunctionComponent = () => {
  const [license, setLicense] = useState(licenseService.getLicense());
  const [isInvalidLicense, setIsInvalidLicense] = useState(false);
  const [isAppLicenseError, setIsAppLicenseError] = useState(false);
  const isTrial = licenseService.isTrial();
  const shouldNotifyLicenseExpire = licenseService.shouldNotifyLicenseExpiration();

  const postFileOrToken = async (licenseFile: File, licenseKey: string) =>
    licenseFile
      ? httpService.multipart<LicenseResponse>('post', 'license/import', {
          data: undefined,
          files: { licenseFile },
        })
      : await httpService.post<LicenseResponse>('license', {
          token: licenseKey.trim(),
        });

  const startOnBoardingFlow = (isUserBlocked: boolean) => {
    if (smallIdLicenseService.isSmallIDLicense()) {
      smallidOnboardingService();
    } else {
      if (isUserBlocked !== licenseService.shouldBlockUserWithLicenseExpired()) {
        sessionStorageService.set('showOnboardModalAfterReloadAccountPage', 'true');
        window.location.reload();
      } else {
        openOnBoardingModal(true);
      }
    }
  };

  const onAddLicenseKey = async (licenseKey: string, licenseFile: File) => {
    try {
      setIsInvalidLicense(false);
      const isUserBlocked = licenseService.shouldBlockUserWithLicenseExpired();
      const { data } = await postFileOrToken(licenseFile, licenseKey);
      setLicense(data.license);
      licenseService.setLicense(data.license);
      appsLicenseService.setAppsLicense(data.supportedApps);
      notificationService.success(LICENSE_KEY_SUCCESS);
      licenseService.setUserClosedExpirationBar(false);
      startOnBoardingFlow(isUserBlocked);
    } catch (error) {
      console.error(error);
      setIsInvalidLicense(true);

      if (error?.response?.data?.message === 'This is an app license') {
        setIsAppLicenseError(true);
      } else {
        setIsAppLicenseError(false);
      }
    }
  };

  useEffect(() => {
    if (sessionStorageService.get('showOnboardModalAfterReloadAccountPage') === 'true') {
      openOnBoardingModal(true);
      sessionStorageService.set('showOnboardModalAfterReloadAccountPage', 'false');
    }
  }, []);

  return (
    <Fragment>
      <LicenseInformation license={license} />
      <hr
        className={classnames(
          styles.licenseInformationWrapper,
          shouldNotifyLicenseExpire && (isTrial ? styles.trialExpired : styles.expired),
          styles.line,
        )}
      />
      <div
        className={classnames(
          styles.licenseInformationWrapper,
          shouldNotifyLicenseExpire && (isTrial ? styles.trialExpired : styles.expired),
        )}
      >
        <h3 className={styles.licenseInformationTitle}>{'Add an Account License Key'}</h3>
        <BigidBody2 className={styles.licenseInformationSubTitle}>
          {'(Application License Key should be uploaded via the “Application Licenses” area)'}
        </BigidBody2>
        <div className={styles.licenseForm}>
          <LicenseForm
            onApply={onAddLicenseKey}
            error={isInvalidLicense}
            isAppLicenseError={isAppLicenseError}
            withButton
            withFile
          />
        </div>
      </div>
    </Fragment>
  );
};
