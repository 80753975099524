import { BigidDropdownOption } from '@bigid-ui/components';
import { CuratedDataSource, getSystemUsersByPermissions } from '../../../curationService';
import { noop } from 'lodash';
import { BigidSystemDialogOptions, openSystemDialog } from '../../../../../services/systemDialogService';
import { getFixedT } from './translations';
import { AssignCollaborator, AssignCollaboratorProps } from './AssignCollaborator';
import { httpService } from '../../../../../services/httpService';

type ShowAssignCollaboratorDialogProps = Pick<AssignCollaboratorProps, 'curatedDataSource' | 'onSubmit'>;

type AssignCollaboratorPayload = {
  collaborator: string;
  curatedDataSource?: CuratedDataSource;
};

type AssignCollaboratorRequestPayload = {
  taskOwner: string;
  source: string;
  scanId: string;
};

type AssignCollaboratorResponse = {
  data: {
    taskId: string;
    collaborator: string;
  };
};

export async function getSystemUsersListOptions(source: string): Promise<BigidDropdownOption[]> {
  const users = await getSystemUsersByPermissions(source);
  return users.map(({ id, value, label }) => ({ id: id.toString(), value, displayValue: label }));
}

export async function showAssignCollaboratorDialog({
  curatedDataSource,
  onSubmit,
}: ShowAssignCollaboratorDialogProps): Promise<boolean> {
  const t = getFixedT(`AssignCollaborator`);

  return new Promise(() => {
    const options: BigidSystemDialogOptions = {
      title: t('title'),
      maxWidth: 'sm',
      isContentScrollable: false,
      onClose: noop,
      showCloseIcon: true,
      borderTop: true,
      content: AssignCollaborator,
      contentProps: {
        curatedDataSource,
        onSubmit,
      },
    };

    openSystemDialog(options);
  });
}

export async function assignCollaborator({
  collaborator,
  curatedDataSource,
}: AssignCollaboratorPayload): Promise<boolean> {
  const assignCollaboratorRequestPayload: AssignCollaboratorRequestPayload = {
    taskOwner: collaborator,
    source: curatedDataSource.source,
    scanId: curatedDataSource.scanId,
  };

  const { data, status } = await httpService.patch<AssignCollaboratorResponse, AssignCollaboratorRequestPayload>(
    `data-catalog/classifier-tuning/collaborator`,
    assignCollaboratorRequestPayload,
  );

  return Boolean(data.data?.taskId) && status === 200;
}
