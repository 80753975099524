import React, { FC } from 'react';
import { BigidLoader } from '@bigid-ui/components';
import makeStyles from '@mui/styles/makeStyles';
import { BigidWarningIcon, BigidClearFilledIcon } from '@bigid-ui/icons';
import { ConnectionsList } from './ConnectionsList';
import { getWarningBody, getWarningTitle } from './utils';
import { CloudProvider } from '../../../AutoDiscoveryWizard/autoDiscoveryWizardServices';

const useStyles = makeStyles({
  statusWrapper: {
    paddingTop: 16,
    display: 'flex',
    fontSize: 12,
  },
  status: {
    display: 'inline-grid',
    padding: 4,
    width: 324,
  },
  statusTitle: {
    paddingBottom: 6,
  },
  loaderWrapper: {
    position: 'relative',
    paddingTop: 120,
  },
});

export enum CredsStatus {
  WRONG_CREDS = 'wrongCreds',
  VALID = 'valid',
  INITIAL = 'initial',
  CANT_EVALUATE = 'cantEvaluate',
  CANT_RUN = 'cantRun',
}

export interface ConnectionsStatusProps {
  type: CloudProvider;
  isLoading: boolean;
  credsStatus: CredsStatus;
  permissions?: string[];
  dataAid?: string;
}

export const ConnectionsStatus: FC<ConnectionsStatusProps> = ({
  type,
  isLoading,
  credsStatus,
  permissions = [],
  dataAid = 'ConnectionsStatus',
}) => {
  const { statusWrapper, loaderWrapper, status, statusTitle } = useStyles({});
  const statusIcon =
    credsStatus === CredsStatus.CANT_RUN || credsStatus === CredsStatus.WRONG_CREDS ? (
      <BigidClearFilledIcon dataAid={dataAid} color="negative" />
    ) : (
      <BigidWarningIcon dataAid={dataAid} />
    );

  return (
    <div data-aid={`${dataAid}-status-wrapper`}>
      {isLoading ? (
        <div className={loaderWrapper}>
          <BigidLoader />
        </div>
      ) : credsStatus === CredsStatus.VALID || credsStatus === CredsStatus.INITIAL ? (
        <ConnectionsList type={type} dataAid={dataAid} permissions={permissions} credsStatus={credsStatus} />
      ) : (
        <div className={statusWrapper}>
          <div>{statusIcon}</div>
          <div className={status}>
            <b className={statusTitle} data-aid={`${dataAid}-status-title`}>
              {getWarningTitle(credsStatus)}
            </b>
            <div data-aid={`${dataAid}-status-body`}>{getWarningBody(credsStatus, type)}</div>
          </div>
        </div>
      )}
    </div>
  );
};
