import oboe from 'oboe/dist/oboe-browser.min.js';

import { module } from 'angular';
import { sessionStorageService } from '../services/sessionStorageService';
const app = module('app');

app.factory('httpDataStream', [
  '$q',
  $q => {
    return {
      /*  GET purposes only */
      get: params => {
        /* bigID token for API authorization */
        const authorization = sessionStorageService.get('bigIdTokenID');

        /* oboejs stream params according to oboejs.com/api */
        const streamParams = {
          url: params.url,
          pattern: params.pattern || '.',
          cached: params.pattern || false,
          headers: {
            ...(params.headers || {}),
            authorization,
          },
        };

        /* defer */
        const defer = $q.defer();

        /* server stream*/
        const isServerStream = params.isServerStream || false;

        /* page size */
        const pageSize = params.pageSize || 0;

        /* stream */
        oboe(streamParams)
          /* start */
          .on('start', status => {
            if (typeof params.onStart === 'function' && status === 200) {
              params.onStart();
            } else {
              defer.reject({ status });
            }
          })
          /* error */
          .on('fail', error => {
            defer.reject(error);
          })
          /* streaming */
          .on('node', streamParams.pattern, node => {
            defer.notify(node);

            return oboe.drop;
          })
          /* finish */
          .on('done', parsedJson => {
            const resolveDone = () => {
              if (typeof params.onDone === 'function') {
                params.onDone();
              }

              defer.resolve();
            };

            if (
              !isServerStream ||
              pageSize == 0 ||
              parsedJson.length < pageSize ||
              (Object.keys(parsedJson).length === 0 && parsedJson.constructor === Object)
            ) {
              resolveDone();
            }

            return oboe.drop;
          });

        return defer.promise;
      },
    };
  },
]);
