import { useEffect, useRef, useState } from 'react';
import {
  DEFAULT_CONFIG_DS_STATE,
  DataSourceConfigurationState,
} from '../../DataSourceConfiguration/hooks/useDataSourceConfigState';
import type { DataSourceConnectionModalAction, DataSourceConnectionModalControl } from '../DataSourceConnectionModal';

type DataSourceConnectionModalState = typeof DEFAULT_CONFIG_DS_STATE;

type UseModalOptions = {
  onSuccess?: () => void;
  onError?: () => void;
  enabled?: boolean;
};

export const useModal = (opts?: UseModalOptions) => {
  const [state, setState] = useState(DEFAULT_CONFIG_DS_STATE);
  const ref = useRef<DataSourceConnectionModalControl>({
    state: { configDataSourceState: DEFAULT_CONFIG_DS_STATE },
  } as unknown as DataSourceConnectionModalControl);

  const trigger = () => setState(ref.current?.state?.configDataSourceState ?? DEFAULT_CONFIG_DS_STATE);

  const watch = (key: keyof DataSourceConnectionModalState) => state[key];

  async function execute(action: 'update', state: Partial<DataSourceConfigurationState>): Promise<void>;
  async function execute(action: DataSourceConnectionModalAction, ...args: any[]): Promise<void>;
  async function execute(action: any, ...args: any[]) {
    await ref.current.handle?.(action, ...args);
  }

  useEffect(() => {
    const { onSuccess, onError } = opts ?? {};
    ref.current = { ...ref.current, onSuccess, onError };
  });

  return {
    control: ref,
    state,
    isInitiated: ref.current?.isInitiated ?? false,
    trigger,
    watch,
    execute,
  };
};
