import { checkIsFieldVisible, fieldConditionCheck } from './conditionUtils';
import {
  DataSourceConnectionTemplateField,
  DataSourceConnectionTemplateFieldMisc,
  DsTypesEnum,
  OwnerFieldNameEnum,
} from '../types';
import { DataSourceConfigurationState } from '../hooks/useDataSourceConfigState';
import { BigidFormRenderProps, BigidSelectOption, PASSWORD_MASK_VIEW_VALUE } from '@bigid-ui/components';
import { CustomDsFieldItem } from '../fields/FormCustomParametersField';
import { TagEntity } from '../../../TagsManagement/TagsManagementService';
import { uniqBy } from 'lodash';

const normalizeUsersFieldValue = (items: any[], fieldName: keyof typeof OwnerFieldNameEnum) => {
  const type = OwnerFieldNameEnum[fieldName];
  const owners = items?.reduce((ownersAcc: string[], item) => {
    const oneOwnerValue = item?.value?.[0]?.value?.id
      ? item?.value?.[0]?.value
      : (typeof item?.value === 'object' && item?.value) || item;
    return oneOwnerValue?.id ? [...ownersAcc, { ...oneOwnerValue, type }] : ownersAcc;
  }, []);

  return uniqBy(owners, 'id');
};
export const MapTypeValueNormalizeBase: Record<DsTypesEnum, any> = {
  [DsTypesEnum.stringSelect]: (
    value: BigidSelectOption[],
    name: string,
    getFieldPropsFunction: BigidFormRenderProps['getFieldProps'],
  ) => {
    const valueNormalized =
      typeof value?.[0]?.value === 'string' ? value?.[0]?.value || String(value) : value?.[0]?.value;
    const { isClearable, source } = getFieldPropsFunction?.(name)?.misc || {};
    if (isClearable || source === 'certificates') {
      return valueNormalized === undefined ? '' : valueNormalized; // can't be cleared in API if undefined (converted to null in JSON)
    }
    return valueNormalized;
  },
  [DsTypesEnum.string]: (value: any) => String(value),
  [DsTypesEnum.number]: (value: any) => (value !== '' && value !== undefined && value !== null ? Number(value) : null),
  [DsTypesEnum.boolean]: (value: any) => Boolean(value),
  [DsTypesEnum.stringBoolean]: (value: any) => String(value),
  [DsTypesEnum.multiStringBoolean]: (value: any) => String(value),
  [DsTypesEnum.options]: (value: BigidSelectOption[]) => {
    const result = value?.[0]?.value || value;
    return result === 'false' ? false : result === 'true' ? true : result;
  },
  [DsTypesEnum.email]: (value: any) => String(value),
  // eslint-disable-next-line react/destructuring-assignment
  [DsTypesEnum.date]: (value?: Date) => (value ? new Date(value.toString()) : null),
  [DsTypesEnum.stringLong]: (value: any) => String(value),
  [DsTypesEnum.password]: (value: any) => (value === PASSWORD_MASK_VIEW_VALUE ? null : String(value)),
  [DsTypesEnum.json]: (value: any) => (value === PASSWORD_MASK_VIEW_VALUE ? null : String(value)),
  [DsTypesEnum.object]: (value: any) => value,
  [DsTypesEnum.verticalObject]: (value: any) => value,
  [DsTypesEnum.objectSelect]: (value: any) => value,
  [DsTypesEnum.customFieldsArray]: (values: { value: CustomDsFieldItem }[]) =>
    values
      ?.map(({ value, ...valueRaw }) =>
        value
          ? {
              ...value,
              field_type: typeof value.field_type === 'string' ? value.field_type : value.field_type?.[0]?.value,
            }
          : valueRaw,
      )
      .filter((item: Record<string, any>) => !!item?.field_name || !!item.field_value),
  [DsTypesEnum.array]: (items?: any[], name?: string) => {
    if (OwnerFieldNameEnum[name as keyof typeof OwnerFieldNameEnum]) {
      return normalizeUsersFieldValue(items, name as keyof typeof OwnerFieldNameEnum);
    }

    return items?.reduce((valueForSend: string[], item) => {
      const resultValue = item?.id && item?.value && typeof item?.value === 'object' ? item.value : item;
      return resultValue &&
        (typeof resultValue === 'string' || (typeof resultValue === 'object' && Object.keys(resultValue)?.length))
        ? [...valueForSend, resultValue]
        : valueForSend;
    }, []);
  },
  [DsTypesEnum.action]: (value: any) => value,
  [DsTypesEnum.tags]: (value: any) => value?.map(({ valueId, tagId }: TagEntity) => ({ valueId, tagId })),
};

export const MapTypeValueNormalize: Record<DsTypesEnum, any> = {
  ...MapTypeValueNormalizeBase,
  [DsTypesEnum.verticalObject]: (value: any, name: string, getFieldPropsFunction: any) => {
    if (value) {
      const { misc } = getFieldPropsFunction(name);
      const getFieldPropsFunctionWithNested = (nestedName: string) =>
        misc?.objectFields?.find((field: DataSourceConnectionTemplateField) => field?.name === nestedName);

      Object.entries(value).forEach(([name, val]) => {
        const type: DsTypesEnum = misc?.objectFields?.find(
          ({ apiName }: DataSourceConnectionTemplateFieldMisc) => apiName === name,
        )?.type;
        value[name] = type ? MapTypeValueNormalizeBase[type](val, name, getFieldPropsFunctionWithNested) : val;
      });
    }

    return value;
  },
};
const isFieldReadyForSend = (
  allValues: Record<string, any>,
  fields: DataSourceConfigurationState['fields'],
  currentValue: any,
  misc: DataSourceConnectionTemplateFieldMisc,
) => {
  const { apiName, enabled, enabledIf } = misc;
  return (
    currentValue !== undefined &&
    apiName &&
    fieldConditionCheck(enabled, enabledIf, (field: string) => {
      const value = allValues[field];
      const {
        misc: { type },
      } = fields.find(({ name }) => name === field) || {};
      return { value, type };
    })
  );
};

export interface PrepareDataSourceFormValueDataForSendProps {
  fields: DataSourceConfigurationState['fields'];
  values: Record<string, any>;
  getFieldPropsFunction: BigidFormRenderProps['getFieldProps'];
  isTest?: boolean;
  isNewPassword?: boolean;
  encryptFields?: string[];
  isBulkUpdate?: boolean;
}

const isNestedPassword = (misc: DataSourceConnectionTemplateFieldMisc): boolean => {
  if (misc?.type === DsTypesEnum.verticalObject) {
    return misc.objectFields.some(element => element?.type === DsTypesEnum.password || element?.isSensitive);
  }
  return false;
};

const populateSensitiveDataToNull = (
  value: any,
  misc: DataSourceConnectionTemplateFieldMisc,
  isNewPassword: boolean,
  isTest: boolean,
  encryptFields: string[],
): any => {
  if (misc?.type === DsTypesEnum.verticalObject) {
    misc?.objectFields.forEach(element => {
      if (
        (element?.type === DsTypesEnum.password || element?.isSensitive) &&
        !isTest &&
        (!isNewPassword || !encryptFields?.includes(`${misc.apiName}::${element.name}`))
      ) {
        value[element.name] = null;
      }
    });
  }
  return value;
};

const getFieldToPush = (
  currentValue: any,
  value: any,
  isReadyForSend: boolean,
  dataForSendAcc: Record<string, any>,
  misc: DataSourceConnectionTemplateFieldMisc,
  isVisible: boolean,
) => {
  const { apiName, type } = misc;
  if (isReadyForSend && type === DsTypesEnum.verticalObject && (Object.keys(currentValue).length === 0 || !isVisible)) {
    return {};
  } else {
    return isReadyForSend
      ? {
          [apiName]:
            type === DsTypesEnum.array && dataForSendAcc[apiName] ? [...dataForSendAcc[apiName], ...value] : value,
        }
      : {};
  }
};
const normalizeValueForSubmit = (
  misc: DataSourceConnectionTemplateFieldMisc,
  value: any,
  name: string,
  getFieldPropsFunction: BigidFormRenderProps['getFieldProps'],
  isTest: boolean,
  isNewPassword: boolean,
  encryptFields: string[],
) => {
  const { type } = misc;
  const isSensitiveField =
    type === DsTypesEnum.password || (type === DsTypesEnum.json && getFieldPropsFunction(name).misc.isSensitive);
  if (isNestedPassword(misc)) {
    return populateSensitiveDataToNull({ ...value }, misc, isNewPassword, isTest, encryptFields);
  }
  if (!isTest && isSensitiveField && !isNewPassword && !encryptFields?.includes(misc.apiName)) {
    return null;
  }
  return MapTypeValueNormalize[type] ? MapTypeValueNormalize[type](value, name, getFieldPropsFunction) : value;
};
export const prepareDataSourceFormValueDataForSend = ({
  fields,
  values,
  getFieldPropsFunction,
  isTest,
  isNewPassword,
  encryptFields,
  isBulkUpdate,
}: PrepareDataSourceFormValueDataForSendProps): Record<string, any> =>
  fields.reduce((dataForSendAcc: Record<string, any>, { name, misc }) => {
    const currentValue = values[name];
    const { defaultValue, apiName, type } = misc;
    const isReadyForSend = isFieldReadyForSend(values, fields, currentValue, misc);

    const isVisible = misc?.ignoreVisibleInCheck || checkIsFieldVisible({ misc, getFieldProps: getFieldPropsFunction });
    if (misc.type === DsTypesEnum.verticalObject && dataForSendAcc[apiName] && !isVisible) {
      return dataForSendAcc;
    }
    const value = isVisible
      ? normalizeValueForSubmit(misc, currentValue, name, getFieldPropsFunction, isTest, isNewPassword, encryptFields)
      : defaultValue;
    const fieldToPush =
      !isVisible && isBulkUpdate
        ? {}
        : getFieldToPush(currentValue, value, isReadyForSend, dataForSendAcc, misc, isVisible);

    const multiBooleanFields = type === 'multiStringBoolean' ? fallbackMultiBooleanField(value, misc) : {};

    return {
      ...dataForSendAcc,
      ...fieldToPush,
      ...multiBooleanFields,
    };
  }, {});

// @info fixes templates sometimes having `apiName` instead of expected `apiNames` for `multiBooleanFields` fields
const fallbackMultiBooleanField = (value: any, misc: DataSourceConnectionTemplateFieldMisc) => {
  const { apiName, apiNames } = misc;
  const hasMultiple = apiNames && Array.isArray(apiNames);
  const fields = hasMultiple ? apiNames : [apiName];
  return fields.filter(Boolean).reduce((acc, apiName) => ({ ...acc, [apiName]: value }), {});
};
