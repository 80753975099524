import React, { FC, useCallback, useMemo, useState, ReactNode } from 'react';
import { BigidApplyFilledIcon, BigidClearFilledIcon, BigidClockIcon, BigidInProgressIcon } from '@bigid-ui/icons';
import { Theme } from '@mui/material/styles';
import {
  AsyncOperationMap,
  AsyncOperationProcessingWidget,
} from '../../../../../components/AsyncOperationProcessingWidget/AsyncOperationProcessingWidget';
import { notificationService } from '../../../../../services/notificationService';
import { useLocalTranslation, getFixedT } from '../../../translations';
import { useTagBulkAssignment } from './operations/TagBulkAssignment';
import { useTagBulkUnassignment } from './operations/TagBulkUnassignment';
import { DataExplorerAsyncOperationStatus } from '../../../types/DataExplorerAsyncOpsTypes';

const getStatusIcon = (status: DataExplorerAsyncOperationStatus, dataAid: string, theme: Theme): ReactNode => {
  const operationIconMapping: { [status in DataExplorerAsyncOperationStatus]?: ReactNode } = {
    [DataExplorerAsyncOperationStatus.COMPLETED]: (
      <BigidApplyFilledIcon size="medium" color="positive" dataAid={`${dataAid}-success`} />
    ),
    [DataExplorerAsyncOperationStatus.ERROR]: (
      <BigidClearFilledIcon size="medium" color="negative" dataAid={`${dataAid}-failure`} />
    ),
    [DataExplorerAsyncOperationStatus.NOT_STARTED]: (
      <BigidClockIcon size="medium" color={theme.vars.palette.bigid.gray300} dataAid={`${dataAid}-pending`} />
    ),
    [DataExplorerAsyncOperationStatus.RUNNING]: (
      <BigidInProgressIcon size="medium" color="insight" dataAid={`${dataAid}-running`} />
    ),
  };

  return operationIconMapping[status];
};

export const getStatusIconTooltipText = (status: DataExplorerAsyncOperationStatus): string => {
  const t = getFixedT('operations.tags.status');

  switch (status) {
    case DataExplorerAsyncOperationStatus.COMPLETED:
      return t('completed');
    case DataExplorerAsyncOperationStatus.ERROR:
      return t('failed');
    case DataExplorerAsyncOperationStatus.NOT_STARTED:
      return t('pending');
    case DataExplorerAsyncOperationStatus.RUNNING:
      return t('running');
    default:
      return status;
  }
};

export const ProcessingIndicator: FC = () => {
  const [runningOperationsMap, setRunningOperationsMap] = useState<AsyncOperationMap>(new Map());
  const { t } = useLocalTranslation('operations');

  const onOperationRun = useCallback((sseRoute: string, isOperationRan: boolean) => {
    setRunningOperationsMap(prevRunningOperationsMap => {
      return prevRunningOperationsMap.set(sseRoute, isOperationRan);
    });
  }, []);

  const onTagAssignmentOperationCompleted = useCallback(() => {
    notificationService.success(t('tags.assign.completed'));
  }, [t]);

  const onTagUnAssignmentOperationCompleted = useCallback(() => {
    notificationService.success(t('tags.unassign.completed'));
  }, [t]);

  const { operations: tagsBulkOperations } = useTagBulkAssignment({
    onOperationRun,
    onOperationCompleted: onTagAssignmentOperationCompleted,
  });

  const { operations: rulesBulkApplyingOperations } = useTagBulkUnassignment({
    onOperationRun,
    onOperationCompleted: onTagUnAssignmentOperationCompleted,
  });

  const operations = useMemo(() => {
    return [...tagsBulkOperations, ...rulesBulkApplyingOperations];
  }, [tagsBulkOperations, rulesBulkApplyingOperations]);

  return (
    <AsyncOperationProcessingWidget
      operations={operations}
      operationsMap={runningOperationsMap}
      getStatusIcon={getStatusIcon}
      getStatusTooltipText={getStatusIconTooltipText}
    />
  );
};
