import React, { FC, ReactElement } from 'react';
import { BigidBody1, BigidHeading6, PieChartData } from '@bigid-ui/components';
import styled from '@emotion/styled';
import { PieInsightChart } from './PieInsightChart';
import { PieChartSectionData } from '../ScanInsightTypes';

const InsightsTitleSection = styled(BigidHeading6)`
  font-size: 16px;
  margin-bottom: 16px;
`;

const InsightsSubTitle = styled(BigidBody1)`
  margin-bottom: 18px;
  font-weight: bold;
`;

const PieInsightSectionContainer = styled('div')<{ orientation: 'horizontal' | 'vertical' }>`
  ${({ orientation }) =>
    orientation === 'vertical'
      ? {
          '& > div': {
            marginBottom: 34,
          },
        }
      : {
          display: 'flex',
          gap: 40,
          justifyContent: 'space-evenly',
        }}
`;

interface PieInsightsSectionProps {
  dataAid?: string;
  datAidSummary?: string;
  title?: string;
  data: PieChartSectionData[];
  summary?: string;
  orientation?: 'horizontal' | 'vertical';
  alignItems?: 'start' | 'end' | 'center';
  pieInsightsSectionClassName?: string;
}

export const PieInsightsSection: FC<PieInsightsSectionProps> = ({
  title,
  data,
  summary,
  dataAid = 'PieInsightsSection',
  datAidSummary = 'PieInsightsSectionSummary',
  orientation = 'vertical',
  alignItems = 'start',
  pieInsightsSectionClassName,
}) => {
  return (
    <div data-aid={dataAid} className={pieInsightsSectionClassName}>
      {title && <InsightsTitleSection>{title}</InsightsTitleSection>}
      <PieInsightSectionContainer orientation={orientation}>
        {data?.map((insight, index) => (
          <PieInsightChart
            key={index}
            dataAid={insight.dataAid}
            data={insight.data}
            total={insight.total}
            title={insight.title}
            withTooltips={insight.withTooltips}
            showZeroedTotal={insight.showZeroedTotal}
            showZeroedData={insight.showZeroedData}
            entityName={insight.entityName}
            disableFiltering={insight.disableFiltering}
            dataAidLabels={insight.dataAidLabels}
            alignItems={alignItems}
            insightSubTitleContent={insight.insightSubTitleContent}
          />
        ))}
        {summary && <InsightsSubTitle data-aid={datAidSummary}>{summary}</InsightsSubTitle>}
      </PieInsightSectionContainer>
    </div>
  );
};
