import React, { FunctionComponent, useCallback, useEffect, useMemo, useState } from 'react';
import { find } from 'lodash';
import makeStyles from '@mui/styles/makeStyles';
import { BubbleChartOutlined, EditOutlined } from '@mui/icons-material';
import { BigidAiIcon } from '@bigid-ui/icons';
import {
  BigidColors,
  BigidIconLabelPlacement,
  BigidList,
  BigidListItem,
  BigidObjectDetailsSection,
  BigidSelectOption,
  BigidTagBaseProps,
  BigidTagProps,
  BigidTags,
  BigidTagsValidationPayload,
  BigidTagWizardDictionary,
  getTagsDictionary,
  objectToQueryString,
  QueryParams,
} from '@bigid-ui/components';
import { formatBytes } from '../../../utilities/numericDataConverter';
import { getTagsAllPairs, TagEntity, TagMutualExclusion } from '../../TagsManagement/TagsManagementService';
import {
  getTagEntityById,
  getTagEntityByName,
  getTagFormattedName,
  getTagIcon,
  validateTag,
} from '../../TagsManagement/TagsManagementUtils';
import { $state, $stateParams } from '../../../services/angularServices';
import { CountryDefinition, getCopyOfCountries } from '../../../../config/countries';

import {
  attachTag,
  createAndAttachTag,
  DataCatalogObjectDetails,
  DataCatalogRecord,
  detachTag,
  getDataCatalogRecords,
  getSystemUsers,
  GetSystemUsersResponse,
  ScanStatus,
  scanStatusDisplayName,
  transformRecordToObjectDetails,
} from './ACIDetailsService';
import { CATALOG_PERMISSIONS, CLUSTER_ANALYSIS_PERMISSIONS, TAGS_PERMISSIONS } from '@bigid/permissions';
import { isPermitted } from '../../../services/userPermissionsService';
import { notificationService } from '../../../services/notificationService';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { BigidContentContainer } from '@bigid-ui/layout';
import { getApplicationPreference } from '../../../services/appPreferencesService';
import { dateTimeService } from '@bigid-ui/i18n';
import { analyticsService } from '../../../services/analyticsService';
import { getObjectTypeName } from '../../DataCatalog/utils';
import { DataCatalogRecordScannerTypeGroup } from '../../DataCatalog/DataCatalogService';
import { CatalogEventsEnum } from '../../DataCatalog/events';
import { DataCatalogOwnersDialog, getEmail } from '../../DataCatalog/DataCatalogDetails/DataCatalogOwnersDialog';

const useStyles = makeStyles({
  wrapper: {
    flex: 1,
    overflowY: 'auto',
  },
  wrapperInner: {
    width: '40%',
    minWidth: '600px',
  },
  sectionTitle: {
    color: BigidColors.purple[600],
    fontSize: '1.25rem',
    fontWeight: 500,
    paddingBottom: '12px',
  },
  sectionContainer: {
    paddingBottom: '24px',
  },
  addAnotherOwner: {
    width: '30%',
    marginTop: '20px',
  },
});

const STRUCTURED = DataCatalogRecordScannerTypeGroup.STRUCTURED;
const OBJECT_OWNERS = 'object_owners';
const ADD_OWNER = 'Add owner';
const INFERRED_METADATA_ICON_TOOLTIP_PRECURSOR_TEXT = 'Predicted by the Inferred Metadata App';

const getBasicDetails = (details: DataCatalogObjectDetails) => {
  const basicDetailsList: BigidListItem[] = [];
  const isClusteringEnabled =
    Boolean(getApplicationPreference('CLUSTERING_ENABLED')) && isPermitted(CLUSTER_ANALYSIS_PERMISSIONS.ACCESS.name);
  const {
    type,
    owner,
    fullObjectName,
    sizeInBytes,
    cluster_id,
    cluster_name,
    document_type = [],
    language,
    branchName,
    reporter,
    reported_date,
    scanStatus,
    messageLink,
    object_owners_struct,
    scanner_type_group,
    columnOrFieldOccurrencesCounter,
    tableTotalRows,
    extendedObjectType,
  } = details;

  const isObjectOwnersAvailable = !!object_owners_struct.length;
  const isPredicted = owner?.includes('InferredMetadataPrediction');

  type &&
    basicDetailsList.push({
      id: 'type',
      name: 'Object type',
      value: getObjectTypeName(extendedObjectType) || type,
    });
  scanner_type_group !== STRUCTURED && owner && basicDetailsList.push({ id: 'owner', name: 'Owner', value: owner });
  fullObjectName && basicDetailsList.push({ id: 'fullObjectName', name: 'Full object name', value: fullObjectName });
  messageLink &&
    basicDetailsList.push({
      id: 'messageLink',
      name: 'Object path',
      value: messageLink,
      isValueClickable: true,
    });
  scanner_type_group === STRUCTURED &&
    basicDetailsList.push({
      id: OBJECT_OWNERS,
      name: 'Owner',
      values: isObjectOwnersAvailable
        ? object_owners_struct.map(({ fullName, email }) => {
            return fullName && email ? fullName + ' ' + email : email;
          })
        : null,
      nameIcon: isObjectOwnersAvailable ? EditOutlined : null,
      valueIcon: !isObjectOwnersAvailable ? AddCircleOutlineIcon : isPredicted ? BigidAiIcon : null,
      toolTipPrecursorText: isPredicted ? INFERRED_METADATA_ICON_TOOLTIP_PRECURSOR_TEXT : null,
      isNameClickable: isObjectOwnersAvailable,
      isValueClickable: !isObjectOwnersAvailable,
      value: !isObjectOwnersAvailable ? ADD_OWNER : null,
    });

  sizeInBytes && basicDetailsList.push({ id: 'sizeInBytes', name: 'Size', value: formatBytes(sizeInBytes) });
  language && basicDetailsList.push({ id: 'language', name: 'Language', value: language });
  branchName && basicDetailsList.push({ id: 'branch', name: 'Branch', value: branchName });
  reporter && basicDetailsList.push({ id: 'reporter', name: 'Reporter', value: reporter });

  reported_date &&
    basicDetailsList.push({
      id: 'reported_date',
      name: 'Date Reported',
      value: dateTimeService.formatDate(reported_date),
    });

  scanStatus &&
    basicDetailsList.push({
      id: 'scanDepth',
      name: 'Scan Status',
      value: getScanStatusDisplayName(scanStatus),
    });

  scanner_type_group === STRUCTURED &&
    columnOrFieldOccurrencesCounter &&
    basicDetailsList.push({
      id: 'NumberOfColumns',
      name: 'Number of columns',
      value: `${columnOrFieldOccurrencesCounter.length} columns`,
    });

  scanner_type_group === STRUCTURED &&
    tableTotalRows &&
    basicDetailsList.push({
      id: 'NumberOfRows',
      name: 'Number of rows',
      value: `${tableTotalRows} rows`,
    });

  document_type.length > 0 &&
    basicDetailsList.push({
      id: 'documentType',
      name: 'Document Classifier',
      value: document_type.join(', '),
    });

  isClusteringEnabled &&
    cluster_name &&
    basicDetailsList.push({
      id: cluster_id,
      name: 'Cluster',
      value: cluster_name,
      isValueClickable: !!cluster_id,
      valueIcon: BubbleChartOutlined,
    });
  return basicDetailsList;
};

const getScanStatusDisplayName = (scanStatus: ScanStatus) => {
  return scanStatusDisplayName[scanStatus] || scanStatus;
};

const getTimestampsDetails = (details: DataCatalogObjectDetails) => {
  const timestampsList: BigidListItem[] = [];
  const {
    scanDate,
    update_date,
    modified_date,
    created_date,
    createdBy,
    last_opened,
    lastAccessedBy,
    lastUpdatedBy,
    scanner_type_group,
  } = details;
  const lastScanned = scanDate || update_date;
  const isUnstructured = scanner_type_group === DataCatalogRecordScannerTypeGroup.UNSTRUCTURED;

  lastScanned &&
    timestampsList.push({
      id: 'scanDate',
      name: 'Last scanned',
      value: dateTimeService.formatDate(lastScanned),
    });
  if (isUnstructured) {
    created_date &&
      timestampsList.push({
        id: 'createdDate',
        name: 'Created on',
        value: dateTimeService.formatDate(created_date),
      });
    createdBy &&
      timestampsList.push({
        id: 'createdBy',
        name: 'Created by',
        value: createdBy,
      });
    last_opened &&
      timestampsList.push({
        id: 'LastAccessed:',
        name: 'Last accessed:',
        value: dateTimeService.formatDate(last_opened),
      });
    lastAccessedBy &&
      timestampsList.push({
        id: 'LastAccessedBy:',
        name: 'Last accessed by:',
        value: lastAccessedBy,
      });
  }
  modified_date &&
    timestampsList.push({
      id: 'lastModified',
      name: isUnstructured ? 'Last modified' : 'Modified',
      value: dateTimeService.formatDate(modified_date),
    });
  isUnstructured &&
    lastUpdatedBy &&
    timestampsList.push({
      id: 'lastModifiedBy',
      name: 'Last modified by',
      value: lastUpdatedBy,
    });

  return timestampsList;
};

const getDSDetails = (details: DataCatalogObjectDetails, countries: CountryDefinition[]) => {
  const dsDetailsList: BigidListItem[] = [];
  const { ds, ds_location } = details;

  if (Array.isArray(ds) && ds[0]) {
    const { owners = [], name, type } = ds[0];

    if (name) {
      dsDetailsList.push({ id: 'dataSourceName', name: 'Data source name', value: name });
    }

    if (type) {
      dsDetailsList.push({ id: 'dataSourceType', name: 'Data source type', value: type });
    }

    if (owners.length > 0) {
      dsDetailsList.push({ id: 'dataSourceOwners', name: 'Data source owners', value: ds[0].owners.join(', ') });
    }
  }

  if (ds_location) {
    const country = find(countries, { name: ds_location });
    dsDetailsList.push({
      id: 'dataSourceLocation',
      name: 'Data source location',
      value: country?.displayName || ds_location,
    });
  }

  return dsDetailsList;
};

const validateTagName = ({ tagName }: BigidTagsValidationPayload): string => {
  const { isTagNameValueValid } = validateTag;

  if (!isTagNameValueValid(tagName)) {
    return 'Invalid tag name';
  }

  return undefined;
};

const validateTagValue = ({ tagName, tagValue, tagDictionary }: BigidTagsValidationPayload): string => {
  const { isTagValuePairUnique, isTagValueNotEqualsToTagName, isTagNameValueValid } = validateTag;

  if (!isTagValuePairUnique(tagValue, tagName, tagDictionary)) {
    return 'Tag & Value pair has to be unique';
  }

  if (!isTagValueNotEqualsToTagName(tagValue, tagName)) {
    return 'Tag & Value cannot be equal';
  }

  if (!isTagNameValueValid(tagValue)) {
    return 'Invalid tag value';
  }

  return undefined;
};

const showMutuallyExclusiveNotification = (exclusion: TagMutualExclusion[], systemTags: TagEntity[]): void => {
  let message = '';
  if (exclusion.length > 1) {
    const messageBase = 'The following tags were excluded due to their mutual exclusion:';

    message = exclusion.reduce((messageAggr, { from, to }) => {
      const fromTagEntity = getTagEntityById(systemTags, from.tagId, from.valueId);
      const toTagEntity = getTagEntityById(systemTags, to.tagId, to.valueId);

      if (fromTagEntity && toTagEntity) {
        const fromTag = `${fromTagEntity.tagName}:${fromTagEntity.tagValue}`;
        const toTag = `${toTagEntity.tagName}:${toTagEntity.tagValue}`;
        return messageAggr ? `${messageAggr}, '${fromTag}' to '${toTag}'` : `${messageBase} '${fromTag}' to '${toTag}'`;
      } else {
        return messageAggr;
      }
    }, '');
  } else if (exclusion.length === 1) {
    const { from, to } = exclusion[0];
    if (from && to) {
      const fromTagEntity = getTagEntityById(systemTags, from.tagId, from.valueId);
      const toTagEntity = getTagEntityById(systemTags, to.tagId, to.valueId);

      if (fromTagEntity && toTagEntity) {
        message = `'${fromTagEntity.tagName}:${fromTagEntity.tagValue}'
          was replaced by '${toTagEntity.tagName}:${toTagEntity.tagValue}'
          because '${fromTagEntity.tagName}' is a mutually exclusive tag`;
      }
    }
  }

  if (message.length > 0) {
    notificationService.success(message);
  }
};

type DataCatalogDetailsProps = DataCatalogRecord & {
  setSelectedItem?: React.Dispatch<any>;
};

export const ACIDetails: FunctionComponent<DataCatalogDetailsProps> = ({
  fullyQualifiedName,
  source,
  setSelectedItem,
  scannerType,
  scanner_type_group,
  hierarchyType,
}) => {
  const classes = useStyles({});

  const [basicDetailsList, setBasicDetailsList] = useState<BigidListItem[]>([]);
  const [dsDetailsList, setDsDetailsList] = useState<BigidListItem[]>([]);
  const [timestampsList, setTimestampsList] = useState<BigidListItem[]>([]);
  const [systemTags, setSystemTags] = useState<TagEntity[]>([]);
  const [systemTagsDictionary, setSystemTagsDictionary] = useState<BigidTagWizardDictionary>();
  const [objectTags, setObjectTags] = useState<BigidTagProps[]>([]);
  const [isAddOwnerDialogOpen, setIsAddOwnerDialogOpen] = useState<boolean>(false);
  const [usersList, setUsersList] = useState<BigidSelectOption[]>(null);

  const { isCreateTagsPermitted, isReadTagsPermitted, isObjectTagsAssignmentPermitted } = useMemo(
    () => ({
      isCreateTagsPermitted: isPermitted(TAGS_PERMISSIONS.CREATE.name),
      isReadTagsPermitted: isPermitted(TAGS_PERMISSIONS.READ.name),
      isObjectTagsAssignmentPermitted: isPermitted(CATALOG_PERMISSIONS.ASSIGN_TAG.name),
    }),
    [],
  );

  const getSectionsData = useCallback(async () => {
    try {
      const filter = {
        limit: 1,
        filter: `fullyQualifiedName IN ("${fullyQualifiedName ?? $stateParams.fullyQualifiedName}")`,
      };
      const query = objectToQueryString({ ...(filter as QueryParams) });
      const { results } = await getDataCatalogRecords(`&${query}`, hierarchyType);
      const objectDetails: DataCatalogObjectDetails = transformRecordToObjectDetails(results[0]);

      if (isReadTagsPermitted) {
        setObjectTags(
          objectDetails.tags
            ?.filter(({ properties }) => !properties?.hidden)
            .map(({ tagName, tagValue, properties }) => ({
              name: getTagFormattedName(tagName),
              value: tagValue,
              icon: getTagIcon(properties),
            })) || [],
        );
      }

      if (isObjectTagsAssignmentPermitted) {
        const tags = await getTagsAllPairs();
        setSystemTags(tags);
        const dictionary = getTagsDictionary(
          tags
            .filter(({ properties }) => !properties?.isExplicit)
            .map(({ tagName, tagValue }) => ({
              name: tagName,
              value: tagValue,
            })),
        );
        setSystemTagsDictionary(dictionary);
      }

      const basicDetails = getBasicDetails(objectDetails);
      setBasicDetailsList(basicDetails);

      const countries = getCopyOfCountries(getApplicationPreference('DISPLAY_CHINA_REGULATIONS'));
      const dsDetails = getDSDetails(objectDetails, countries);
      setDsDetailsList(dsDetails);

      const timeStampDetails = getTimestampsDetails(objectDetails);
      setTimestampsList(timeStampDetails);

      setSelectedItem?.((prev: any) => ({
        ...prev,
      }));
    } catch ({ message }) {
      notificationService.error('An error has occurred');
      console.error(`An error has occurred: ${message}`);
    }
  }, [fullyQualifiedName, isObjectTagsAssignmentPermitted, isReadTagsPermitted, setSelectedItem]);

  const handleTagAttached = useCallback(
    (tagAttached: TagEntity, mutuallyExcluded: TagMutualExclusion[], systemTags: TagEntity[]) => {
      const { tagName, tagValue } = tagAttached;
      if (mutuallyExcluded) {
        setObjectTags(objectTagsPrev =>
          [...objectTagsPrev, { name: tagName, value: tagValue }].filter(tag => {
            const isBeingExcluded = !!mutuallyExcluded.find(({ from: excludedTag }) => {
              const tagToExclude = getTagEntityByName(systemTags, tag?.name, tag?.value);
              return excludedTag.tagId === tagToExclude?.tagId && excludedTag.valueId === tagToExclude?.valueId;
            });
            return !isBeingExcluded;
          }),
        );

        if (mutuallyExcluded?.length > 0) {
          showMutuallyExclusiveNotification(mutuallyExcluded, systemTags);
        }
      } else {
        setObjectTags(objectTagsPrev => [...objectTagsPrev, { name: tagName, value: tagValue }]);
      }
    },
    [],
  );

  const handleClusterClick = ({ id, value }: BigidListItem): void => {
    if (id === 'messageLink') {
      window.open(value, '_blank');
    } else if (id === OBJECT_OWNERS) {
      setIsAddOwnerDialogOpen(true);
      getOwners();
    } else {
      $state.go('clusterPreview', {
        clusterId: id,
        clusterName: value,
      });
    }
  };

  const handleOnNameClick = ({ id }: BigidListItem): void => {
    if (id === OBJECT_OWNERS) {
      setIsAddOwnerDialogOpen(true);
      getOwners();
    }
  };

  const handleTagCreate = useCallback(
    async (tag: BigidTagBaseProps) => {
      try {
        const { tag: createdTag, response } = await createAndAttachTag(
          systemTags,
          tag,
          fullyQualifiedName ?? $stateParams.fullyQualifiedName,
          source,
        );

        const systemTagsUpdated = [...systemTags, createdTag];
        setSystemTags(systemTagsUpdated);
        const dictionary = getTagsDictionary(
          systemTagsUpdated
            .filter(({ properties }) => !properties?.isExplicit)
            .map(({ tagName, tagValue }) => ({ name: tagName, value: tagValue })),
        );
        setSystemTagsDictionary(dictionary);

        handleTagAttached(createdTag, response.mutuallyExcluded, systemTagsUpdated);
      } catch ({ message, response }) {
        const notificationMessage =
          response?.data?.message === 'this tag-name already exist'
            ? 'A tag with this name already exists'
            : 'An error has occurred';
        notificationService.error(notificationMessage);
        console.error(`An error has occurred: ${message}`);
      }
    },
    [systemTags, fullyQualifiedName, source, handleTagAttached],
  );

  const handleTagAttach = useCallback(
    async (tag: BigidTagBaseProps) => {
      try {
        const { tag: attachedTag, response } = await attachTag(
          systemTags.filter(({ properties }) => !properties?.isExplicit),
          tag,
          fullyQualifiedName ?? $stateParams.fullyQualifiedName,
          source,
        );

        handleTagAttached(attachedTag, response.mutuallyExcluded, systemTags);
      } catch ({ message }) {
        notificationService.error('An error has occurred');
        console.error(`An error has occurred: ${message}`);
      }

      const trackingData = {
        fullyQualifiedName: fullyQualifiedName ?? $stateParams.fullyQualifiedName,
        tagKey: tag.name,
        tagValue: tag.value,
        dsType: scannerType,
        scannerType: scanner_type_group,
      };

      analyticsService.trackManualEvent(CatalogEventsEnum.CATALOG_ASSIGN_TAG, trackingData);
    },
    [systemTags, fullyQualifiedName, source, handleTagAttached, scanner_type_group, scannerType],
  );

  const handleTagDetach = useCallback(
    async (tag: BigidTagBaseProps) => {
      try {
        const { tag: detachedTag } = await detachTag(
          systemTags,
          tag,
          fullyQualifiedName ?? $stateParams.fullyQualifiedName,
          source,
        );

        setObjectTags(objectTagsPrev => {
          return objectTagsPrev.filter(({ name, value }) => {
            const isBeingDetached = name === detachedTag.tagName && value === detachedTag.tagValue;
            return !isBeingDetached;
          });
        });
      } catch ({ message }) {
        notificationService.error('An error has occurred');
        console.error(`An error has occurred: ${message}`);
      }
    },
    [systemTags, fullyQualifiedName, source],
  );

  useEffect(() => {
    if (fullyQualifiedName || $stateParams.fullyQualifiedName) {
      getSectionsData();
    }
  }, [fullyQualifiedName, getSectionsData]);

  const getOwners = useCallback(async () => {
    try {
      const systemUsers = await getSystemUsers();
      const filterSystemUsersWithMail = systemUsers.filter(({ id }) => getEmail(id));
      const systemUsersList = filterSystemUsersWithMail.map((user: GetSystemUsersResponse) => {
        return { label: user.firstName, subLabel: user.id, value: user._id };
      });
      setUsersList(systemUsersList);
    } catch ({ message }) {
      console.error(`An error has occurred: ${message}`);
    }
  }, []);

  const handleOnDialogClose = () => {
    setIsAddOwnerDialogOpen(false);
  };

  const handleOnDialogSubmit = () => {
    getSectionsData();
    setIsAddOwnerDialogOpen(false);
  };

  return (
    <div className={classes.wrapper}>
      <div className={classes.wrapperInner}>
        {isReadTagsPermitted && (
          <div className={classes.sectionContainer}>
            <BigidObjectDetailsSection title="Tags">
              <BigidTags
                tags={objectTags}
                tagWizardMenuPosition="absolute"
                tagsDictionary={systemTagsDictionary}
                onCreate={handleTagCreate}
                onAdd={handleTagAttach}
                onDelete={handleTagDetach}
                isAbleToCreate={isCreateTagsPermitted}
                isAbleToEdit={isObjectTagsAssignmentPermitted && isReadTagsPermitted}
                isAbleToDelete={isObjectTagsAssignmentPermitted}
                placeholder="Click the + to add tag"
                useMinHeight
                validateName={validateTagName}
                validateValue={validateTagValue}
              />
            </BigidObjectDetailsSection>
          </div>
        )}
        <div className={classes.sectionContainer}>
          <BigidObjectDetailsSection title="Basic details">
            {basicDetailsList.length > 0 && (
              <BigidList
                listItems={basicDetailsList}
                onItemValueClick={handleClusterClick}
                onItemNameClick={handleOnNameClick}
                nameLabelPlacement={BigidIconLabelPlacement.LEFT}
              />
            )}
          </BigidObjectDetailsSection>
        </div>
        <div className={classes.sectionContainer}>
          <BigidObjectDetailsSection title="Activity log">
            {timestampsList.length > 0 && <BigidList listItems={timestampsList} />}
          </BigidObjectDetailsSection>
        </div>
        <div className={classes.sectionContainer}>
          <BigidObjectDetailsSection title="Data source details">
            {dsDetailsList.length > 0 && <BigidList listItems={dsDetailsList} />}
          </BigidObjectDetailsSection>
        </div>

        {isAddOwnerDialogOpen && (
          <DataCatalogOwnersDialog
            fullyQualifiedName={fullyQualifiedName}
            usersList={usersList}
            isAddOwnerDialogOpen={isAddOwnerDialogOpen}
            basicDetailsList={basicDetailsList}
            onClose={handleOnDialogClose}
            onSubmit={handleOnDialogSubmit}
            dsName={source}
            dsType={scannerType}
          />
        )}
      </div>
    </div>
  );
};

export const ACIDetailsForTheGrid: FunctionComponent<DataCatalogRecord> = props => {
  const { setSelectedItem } = BigidContentContainer.useContainer();

  return <ACIDetails {...props} setSelectedItem={setSelectedItem} />;
};
