import { BigidFlexibleListCardContentComponent } from '@bigid-ui/components';
import { IconComponentProps } from '@bigid-ui/icons';
import React, { ComponentType, ReactNode, ReactText } from 'react';
import { CONFIG } from '../../../../../config/common';
import { $state } from '../../../../services/angularServices';
import { CatalogSearchGridRow, ResultsEntityType, SearchResultResponseDataForEntityType } from '../../types';
import { filterCardReasons, getAlienIconByType, getCatalogEntityIconByType } from '../../utils';
import { AlienEntityTitle } from './components/AlienEnityTitle';
import { DataSourceName } from './components/DataSourceName';
import { ModifiedDate } from './components/ModifiedDate';
import { Reasons } from './components/Reasons/Reasons';
import { Header } from './Header';
import { getHighlightOrFallback } from './cardComponentUtils';
import { mapFieldValueToBiqlString } from '../../../../services/filtersToBiqlService';

const catalogAdapter: AdapterFunc<'catalog'> = row => {
  const {
    id,
    title,
    highlights,
    data: { datasource, type },
  } = row;

  const Icon = getCatalogEntityIconByType(type);
  const supportedHighlights = filterCardReasons(highlights);
  const fqnValue = getHighlightOrFallback('fullyQualifiedName', highlights, String(id));
  const titleValue = getHighlightOrFallback('name', highlights, title);
  const cardObject: CardObject = {
    contentComponent: () => <DataSourceName datasource={datasource} />,
    footerComponent: <Reasons highlights={supportedHighlights} />,
    icon: () => <Icon size="large" />,
    titleComponent: <Header subtitle={fqnValue} title={titleValue} />,
    id,
  };

  return cardObject;
};

const entityTypeToDataStewardshipTypeMap: Record<
  Extract<ResultsEntityType, 'business_term' | 'business_attribute'>,
  string
> = {
  business_term: 'business_term',
  business_attribute: 'business_attribute',
};

const getOnClickHandler = ({ id, entityType, data, title }: EntityRow) => {
  switch (entityType) {
    case 'policy': {
      return () => {
        const { id: policyId } = data;
        $state.go(CONFIG.states.POLICIES, { policyId });
      };
    }
    case 'business_attribute':
    case 'business_term': {
      return () => {
        const dataStewardshipType = entityTypeToDataStewardshipTypeMap[entityType];
        const params = {
          entityType: dataStewardshipType,
          entityId: String(id),
        };

        $state.go(CONFIG.states.APP_DATA_STEWARDSHIP, params);
      };
    }
    case 'datasource': {
      return () => {
        const filter = mapFieldValueToBiqlString('system', String(id));
        $state.go(CONFIG.states.CATALOG, { filter });
      };
    }
  }
};

type EntityRow = CatalogSearchGridRow<'datasource' | 'business_attribute' | 'business_term' | 'policy'>;

const alienEntityAdapter: AdapterFunc<'datasource' | 'business_attribute' | 'business_term' | 'policy'> = row => {
  const {
    data: { modifiedDate, id, owners, name },
    highlights,
    entityType,
  } = row;
  const Icon = getAlienIconByType(entityType);
  const handleTitleClick = getOnClickHandler(row);
  const highlightOwnersValue =
    highlights.find(highlight => highlight.fieldName === 'owner')?.highlightedValue || owners;

  const titleHighlight = highlights.find(highlight => highlight.fieldName === 'name');
  const cardObject: CardObject = {
    contentComponent: () => <ModifiedDate date={modifiedDate} />,
    icon: () => <Icon size="large" />,
    id,
    titleComponent: (
      <AlienEntityTitle owners={highlightOwnersValue} title={titleHighlight?.highlightedValue[0] || name} />
    ),
    onTitleClick: handleTitleClick,
  };

  return cardObject;
};

const rowToCardAdapterMap: {
  [K in ResultsEntityType]: AdapterFunc<K>;
} = {
  catalog: catalogAdapter,
  datasource: alienEntityAdapter,
  business_attribute: alienEntityAdapter,
  business_term: alienEntityAdapter,
  policy: alienEntityAdapter,
};

const getAdapter = <K extends ResultsEntityType>(tabName: K): AdapterFunc<K> => {
  return rowToCardAdapterMap[tabName];
};

type CardObject = {
  icon: ComponentType<IconComponentProps & React.SVGProps<SVGSVGElement>>;
  titleComponent: ReactNode;
  footerComponent?: ReactNode;
  contentComponent: BigidFlexibleListCardContentComponent;
  id: ReactText;
  onTitleClick?: () => void;
};

export type AdapterFunc<T extends keyof SearchResultResponseDataForEntityType = ResultsEntityType> = (
  row: CatalogSearchGridRow<T>,
) => CardObject;

export const getAdaptedCardData = (tabName: ResultsEntityType) => {
  const adapterFn = getAdapter(tabName);
  return adapterFn;
};
