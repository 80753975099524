import { BigidFormField, BigidFormFieldTypes } from '@bigid-ui/components';
import React, { FC, useMemo } from 'react';
import { FormSection, FormSectionInitialStateProps } from '../FormSection/FormSection';
import { useReportTemplateContext } from '../hooks/ReportTemplateContext';

export const ReportTitleSection: FC = () => {
  const sectionName = 'reportTitle';
  const bottomDescription =
    'Insert “[customer_name]” in your title and the report will automatically fill in the customer’s name.';
  const contextState = useReportTemplateContext();
  const { initialTemplateData } = contextState;

  const formSectionInitProps: FormSectionInitialStateProps = useMemo(() => {
    const { reportTitle = {} } = initialTemplateData;
    const { ...initialValues } = reportTitle;

    const fields: BigidFormField[] = [
      {
        type: BigidFormFieldTypes.TEXT,
        name: 'reportMainTitle',
        label: 'Report Title',
        misc: {
          placeholder: 'Personal Data Report',
          fullWidth: true,
        },
      },
      {
        type: BigidFormFieldTypes.TEXT,
        name: 'reportSubTitle',
        label: 'Report Sub-Title',
        misc: {
          placeholder: 'For [customer_name]',
          fullWidth: true,
        },
      },
    ];

    return {
      fields,
      initialValues,
    };
  }, [initialTemplateData]);

  return (
    <FormSection
      sectionName={sectionName}
      bottomDescription={bottomDescription}
      shouldHideHeaderSection
      shouldHideLeftBorder
      {...formSectionInitProps}
    />
  );
};
