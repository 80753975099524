import { TFunction } from 'i18next';
import { taskListService } from '../../../services/angularServices';
import { CloudProvider } from '../constants';

export interface BigidTask {
  owner: string;
  initiator: string;
  subject: string;
  type: string;
  comments?: BigidComment[];
  status: string;
  shouldSendEmail?: boolean;
}

export interface BigidComment {
  mentions?: string[];
  actionLinks?: Record<string, any>[];
  user: string;
  text: string;
}

export interface InviteCollaborators {
  collaborators: Record<string, any>[];
  message: string;
  email: string;
  type: CloudProvider;
}

export const COLLABORATION_ONBOARDING_TYPE = 'collaborationOnboarding';

export async function inviteAutoDiscoveryCollaborators(
  { collaborators, message, email, type }: InviteCollaborators,
  t: TFunction,
) {
  return Promise.all(
    collaborators.map(({ value }) => {
      const data: BigidTask = {
        type: COLLABORATION_ONBOARDING_TYPE,
        initiator: email,
        owner: value,
        status: 'open',
        subject: t('collaborateModal.subject', {
          email,
          type,
        }),
        comments: [
          {
            actionLinks: [
              {
                id: type,
                label: t('collaborateModal.inviteOwner'),
                type: 'createContinueTask',
                initiator: value,
                owner: email,
                subject: t('collaborateModal.inviteOwnerBody', { type }),
                comments: [
                  {
                    actionLinks: [
                      {
                        stateName: 'dataSourceConnections',
                        params: {
                          tab: 'discovery',
                        },
                        label: `Continue`,
                        type: 'customAction',
                      },
                    ],
                    mentions: [],
                    text: t('collaborateModal.inviteOwnerBody', { type }),
                    user: email,
                  },
                ],
              },
            ],
            mentions: [],
            text: message,
            user: value,
          },
        ],
      };

      return taskListService.createNewTask(data);
    }),
  );
}

export interface CreateContinueTask {
  owner: string;
  subject: string;
  comments: BigidComment[];
  initiator: string;
}

export async function createContinueTask({ owner, subject, comments, initiator }: CreateContinueTask) {
  const data: BigidTask = {
    type: 'collaboration',
    initiator,
    owner,
    status: 'open',
    subject,
    comments,
  };

  return taskListService.createNewTask(data);
}

export async function getOnboardingCollaborationTasks(discoveryType: CloudProvider) {
  const { data } = await taskListService.getAllTask('open', 'initiator', COLLABORATION_ONBOARDING_TYPE);

  return data.filter(({ comments }) =>
    comments.some(({ actionLinks }: any) => actionLinks.some(({ id }: any) => id === discoveryType)),
  );
}

export async function getOnboardingCollaborationTasksOwners(discoveryType: CloudProvider) {
  const tasks = await getOnboardingCollaborationTasks(discoveryType);

  return tasks?.map(({ owner }) => owner);
}
