import React, { FC } from 'react';
import {
  BigidHeading6,
  BigidBody1,
  BigidNotes2,
  BigidNotes1,
  BigidTooltip,
  BigidLink,
  BigidButtonIcon,
} from '@bigid-ui/components';
import { BigidScan3Icon, BigidDeleteIcon, BigidDuplicateIcon, BigidEditIcon } from '@bigid-ui/icons';
import makeStyles from '@mui/styles/makeStyles';
import { Divider, IconButton } from '@mui/material';
import { generateDataAid } from '@bigid-ui/utils';
import { ScanTemplateActionType, ScanTemplateGridRow } from './scanTemplateTypes';
import { runCardAction } from './scanTemplatesService';
import {
  getScanTypeLabel,
  getLabelWithCount,
  handleScanTemplateMoreDetailsClick,
  getCreatedByText,
} from '../ScanUtils';
import { dateTimeService } from '@bigid-ui/i18n';
import { isPermitted } from '../../../services/userPermissionsService';
import { SCANS_PERMISSIONS } from '@bigid/permissions';
import { ScanTemplateFavoritesIcon } from './ScanTemplateFavoritesIcon';

interface CardProps {
  row: ScanTemplateGridRow;
}

const useStyles = makeStyles({
  cardHeader: {
    display: 'flex',
    width: '100%',
    flexDirection: 'column',
    justifyContent: 'space-between',
    paddingTop: 8,
  },
  templateName: {
    height: 23,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  cardContent: {
    paddingTop: 12,
    display: 'flex',
    height: '100%',
    width: '100%',
    flexDirection: 'column',
  },
  cardContentCounters: {
    display: 'grid',
    alignItems: 'center',
    gridTemplateColumns: '1fr auto 1fr',
    paddingBottom: 12,
    '& div': {
      paddingBottom: 0,
      minWidth: 80,
    },
  },
  divider: { margin: '4px 12px' },
  cardFooter: {
    height: '100%',
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-end',
    '& button': {
      flexGrow: 1,
    },
    paddingRight: '8px',
  },
  cardContentSection: {
    paddingBottom: 12,
    display: 'flex',
    flexDirection: 'column',
  },
  cardFooterIconsButtons: {
    alignItems: 'flex-start',
    marginRight: 8,
  },
  cardValue: {
    lineHeight: '20px',
  },
  topIcon: {
    display: 'flex',
    height: 32,
    width: '100%',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  descriptionValue: {
    lineHeight: '20px',
    overflow: 'hidden',
    display: '-webkit-box',
    '-webkit-line-clamp': 2,
    '-webkit-box-orient': 'vertical',
  },
  moreDetailsWrapper: {
    marginBottom: 4,
  },
  cutText: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
});

export const CardHeader: FC<CardProps> = ({ row: { _id, name, isFavorite } }) => {
  const { cardHeader, cardContentSection, templateName, topIcon } = useStyles();

  return (
    <div className={cardHeader}>
      <div className={topIcon}>
        <BigidScan3Icon size="xlarge" staticMode />
        <ScanTemplateFavoritesIcon isFavorite={isFavorite} _id={_id} name={name} />
      </div>
      <div className={cardContentSection}>
        <BigidTooltip title={name} dataAid={generateDataAid('templateHeaderName', ['tooltip'])}>
          <BigidHeading6 className={templateName}>{name}</BigidHeading6>
        </BigidTooltip>
      </div>
    </div>
  );
};

export const CardContent: FC<CardProps> = ({
  row: {
    isUseGlobalClassifiers,
    description,
    allEnabledIdSor,
    updated_at,
    scanType,
    createdBy,
    classifiers,
    idConnectionList,
  },
}) => {
  const { cardContent, cardValue, cardContentCounters, divider, cardContentSection, descriptionValue, cutText } =
    useStyles();
  const classifiersStr = isUseGlobalClassifiers ? 'Global' : getLabelWithCount('classifier', classifiers);
  const correlationStr = allEnabledIdSor ? 'All Enabled' : getLabelWithCount('correlation', idConnectionList);

  return (
    <div className={cardContent}>
      <div className={cardContentSection}>
        <BigidNotes2>Last Modified Date</BigidNotes2>
        <BigidBody1>{dateTimeService.formatDate(updated_at)}</BigidBody1>
      </div>
      <div className={cardContentCounters}>
        <div className={cardContentSection}>
          <BigidNotes2>Scan Type</BigidNotes2>
          <BigidTooltip title={getScanTypeLabel(scanType)}>
            <BigidNotes1 className={cutText}>{getScanTypeLabel(scanType)}</BigidNotes1>
          </BigidTooltip>
        </div>
        <Divider orientation="vertical" className={divider} />
        <div className={cardContentSection}>
          <BigidNotes2>Created By</BigidNotes2>
          <BigidTooltip title={getCreatedByText(createdBy)}>
            <BigidNotes1 className={cutText}>{getCreatedByText(createdBy)}</BigidNotes1>
          </BigidTooltip>
        </div>
      </div>

      <div className={cardContentCounters}>
        <div className={cardContentSection}>
          <BigidNotes2>Classifiers</BigidNotes2>
          <BigidNotes1 className={cardValue}>{classifiersStr}</BigidNotes1>
        </div>
        <Divider orientation="vertical" className={divider} />
        <div className={cardContentSection}>
          <BigidNotes2>Correlation</BigidNotes2>
          <BigidNotes1 className={cardValue}>{correlationStr}</BigidNotes1>
        </div>
      </div>

      {description && (
        <div className={cardContentSection}>
          <BigidNotes2>Description</BigidNotes2>
          <BigidTooltip title={description}>
            <BigidNotes1 className={descriptionValue}>{description}</BigidNotes1>
          </BigidTooltip>
        </div>
      )}
    </div>
  );
};

export const CardFooter: FC<CardProps> = ({ row }) => {
  const { cardFooter, cardFooterIconsButtons, moreDetailsWrapper } = useStyles();
  const { name, isOOTB } = row;

  return (
    <div className={cardFooter}>
      <div className={cardFooterIconsButtons}>
        {isPermitted(SCANS_PERMISSIONS.CREATE_SCAN_PROFILES.name) && (
          <BigidButtonIcon
            icon={BigidDuplicateIcon}
            onClick={() => runCardAction(row, ScanTemplateActionType.DUPLICATE)}
            dataAid={`duplicate-template-${name}`}
          />
        )}
        {!isOOTB && isPermitted(SCANS_PERMISSIONS.DELETE_SCAN_PROFILES.name) && (
          <BigidButtonIcon
            icon={BigidDeleteIcon}
            onClick={() => runCardAction(row, ScanTemplateActionType.DELETE)}
            dataAid={`delete-template-${name}`}
          />
        )}
        {!isOOTB && isPermitted(SCANS_PERMISSIONS.EDIT_SCAN_PROFILES.name) && (
          <BigidButtonIcon
            icon={BigidEditIcon}
            onClick={() => runCardAction(row, ScanTemplateActionType.EDIT)}
            dataAid={`edit-template-${name}`}
          />
        )}
      </div>
      <div className={moreDetailsWrapper}>
        <BigidLink
          text={'More Details'}
          dataAid={generateDataAid('templateCardDetails', [name])}
          onClick={() => handleScanTemplateMoreDetailsClick(row)}
          underline="always"
        />
      </div>
    </div>
  );
};
