import { createContext } from 'react';
import { ScanProfileFields } from '../../ScanProfileConverter/scanProfileConversionTypes';

export interface ScanTemplateFormContext {
  scanProfileData: ScanProfileFields;
  isForReview: boolean;
  scanType?: string;
}

export const ConvertScanProfileContext = createContext<ScanTemplateFormContext>(null);
