import { module } from 'angular';
const app = module('app');

app.factory('generalInfoService', [
  '$http',
  'appSettings',
  function ($http, appSettings) {
    return {
      getUserGeneralInfo: () =>
        $http.get(`${appSettings.serverPath}/${appSettings.version}/sar/general-info`).then(response => response),
      createUserGeneralInfo: (data = {}) =>
        $http
          .post(`${appSettings.serverPath}/${appSettings.version}/sar/general-info`, data)
          .then(response => response),
      updateUserGeneralInfo: (data = {}) =>
        $http
          .put(`${appSettings.serverPath}/${appSettings.version}/sar/general-info/`, data)
          .then(response => response),
      deleteUserGeneralInfo: () =>
        $http.delete(`${appSettings.serverPath}/${appSettings.version}/sar/general-info`).then(response => response),
    };
  },
]);
