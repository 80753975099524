import React, { ChangeEvent, FC, useEffect, useMemo, useRef, useState } from 'react';
import {
  BigidColors,
  BigidSelect,
  BigidSelectFieldState,
  BigidSelectOption,
  BigidTextField,
} from '@bigid-ui/components';
import makeStyles from '@mui/styles/makeStyles';
import { CustomAppParam, InputTypes } from '../../views/EditCustomApp/EditCustomApp';

export interface CustomAppValueInputProps {
  onChange: (name: string, val: string) => void;
  param: CustomAppParam;
  paramValue?: string | Record<string, unknown> | Array<unknown>;
  errorMessage: string;
  readOnly?: boolean;
}

const MASKED_VALUE = '********';
const useStyles = makeStyles({
  root: {
    width: '100%',
    '&input': {
      background: BigidColors.white,
    },
  },
});

const getSelectedOptions = (items: string[]) => items.map(label => ({ label, value: label }));

export const CustomAppValueInput: FC<CustomAppValueInputProps> = ({
  onChange,
  param,
  paramValue = '',
  errorMessage,
  readOnly = false,
}) => {
  const classes = useStyles({});
  const { name, placeholder, isClearText, inputItems = [], inputType = InputTypes.TEXT } = param;
  const valueRef = useRef<string | Record<string, unknown> | Array<unknown>>(isClearText ? paramValue : MASKED_VALUE);

  valueRef.current = paramValue;

  const selectValue: BigidSelectOption[] = useMemo(() => {
    if ([InputTypes.SINGLE_ELECTION, InputTypes.MULTIPLE_SELECTION].includes(inputType)) {
      return paramValue
        ?.toString()
        ?.split(',')
        .filter((paramVal: string) => inputItems.includes(paramVal))
        .map((label: string) => ({ label, value: label }));
    }
    return [];
  }, [inputType, paramValue, inputItems]);

  const handleInputChange = ({ target }: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>): void => {
    valueRef.current = target.value;
    onChange(name, target.value);
  };

  const handleSelectionChange = (values: BigidSelectOption[]) => {
    const val = values.map(({ value }) => value).join(',');
    onChange(name, val);
  };

  const renderInput = () => {
    switch (inputType) {
      case InputTypes.SINGLE_ELECTION:
      case InputTypes.MULTIPLE_SELECTION: {
        return (
          <BigidSelect
            options={getSelectedOptions(inputItems)}
            onChange={handleSelectionChange}
            isMulti={inputType === InputTypes.MULTIPLE_SELECTION}
            value={selectValue}
            fieldState={errorMessage ? BigidSelectFieldState.error : BigidSelectFieldState.normal}
            message={errorMessage}
            dataAid={'select-field'}
            isDisabled={readOnly}
          />
        );
      }

      default:
        return (
          <BigidTextField
            onChange={handleInputChange}
            placeholder={placeholder}
            value={valueRef.current?.toString()}
            type={isClearText ? 'text' : 'password'}
            errorMessage={errorMessage}
            disabled={readOnly}
          />
        );
    }
  };

  return <div className={classes.root}>{renderInput()}</div>;
};
