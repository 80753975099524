import { UseMutationOptions, MutationFunction, useMutation, UseMutationResult } from 'react-query';
import { scansService } from '../../../../../services/angularServices';
import type { AsyncReturnType } from '../../../DataSourceConnectionModal/types';
import type { BodyType, ErrorType } from '../../../../../config/request';
import type { CreateScanProfileItemRequest, CreateScanProfilePayload, CreateScanProfileItemResponse } from '../types';

const createScanProfile = async (data: CreateScanProfileItemRequest): Promise<CreateScanProfileItemResponse> =>
  scansService?.createScanProfile?.(data);

export type CreateScanProfileQueryResult = NonNullable<AsyncReturnType<typeof createScanProfile>>;

export type CreateScanProfileQueryError = ErrorType<unknown>;

export const useCreateScanProfile = <TError = CreateScanProfileQueryError, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    AsyncReturnType<typeof createScanProfile>,
    TError,
    { data: BodyType<CreateScanProfilePayload> },
    TContext
  >;
}): UseMutationResult<
  CreateScanProfileItemResponse,
  TError,
  { data: BodyType<CreateScanProfilePayload> },
  TContext
> => {
  const { mutation: mutationOptions } = options || {};
  const mutationFn: MutationFunction<
    AsyncReturnType<typeof createScanProfile>,
    { data: BodyType<CreateScanProfilePayload> }
  > = async props => {
    const { data } = props || {};
    const formData = new FormData();

    formData.append(
      'data',
      JSON.stringify({
        ...data,
        isCustomScanProfile: true,
      }),
    );

    return createScanProfile(formData);
  };

  return useMutation<
    AsyncReturnType<typeof createScanProfile>,
    TError,
    { data: BodyType<CreateScanProfilePayload> },
    TContext
  >(mutationFn, mutationOptions);
};
