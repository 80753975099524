import { module } from 'angular';
const app = module('app');

app.factory('personalInfoService', [
  '$http',
  'appSettings',
  function ($http, appSettings) {
    return {
      getUserPersonalInfo: id => {
        return $http
          .get(`${appSettings.serverPath}/${appSettings.version}/sar/reports/${id}/personal-info`)
          .then(response => response);
      },

      updateReportConfig: (id, data) => {
        return $http
          .put(`${appSettings.serverPath}/${appSettings.version}/sar/reports/${id}/personal-info/config`, data)
          .then(response => response);
      },

      aggregateUserPersonalInfo: data => {
        const aggregatedData = {};

        for (const item of data) {
          const attributeId = item['att_friendly_name'].replace(/\s/g, '_');

          const friendlyName = item['att_friendly_name'],
            attributeName = item['attribute'],
            attributePurpose = item['purpose_of_use'],
            attributeValue = item['attribute_value'];

          // define once per attribute
          if (typeof aggregatedData[attributeId] == 'undefined') {
            aggregatedData[attributeId] = {
              friendlyName,
              attributes: [],
              purposes: [],
              values: [],
            };
          }

          const { attributes, purposes, values } = aggregatedData[attributeId];
          if (typeof attributeValue != 'undefined' && attributeValue !== '' && !values.includes(attributeValue)) {
            values.push(attributeValue);
          }

          if (
            typeof attributePurpose != 'undefined' &&
            attributePurpose !== '' &&
            !purposes.includes(attributePurpose)
          ) {
            purposes.push(attributePurpose);
          }

          if (typeof attributeName != 'undefined' && attributeName !== '' && !attributes.includes(attributeName)) {
            attributes.push(attributeName);
          }
        }

        return aggregatedData;
      },

      getTop5ConsentsForeachName: consentData => {
        if (!Array.isArray(consentData)) {
          return [];
        }

        const consentsByName = consentData
          .sort((a, b) => {
            // will be coerced to Number
            return new Date(b.timestamp) - new Date(a.timestamp);
          })
          .reduce((a, b) => {
            (a[b.name] = a[b.name] || []).push(b);
            return a;
          }, {});

        // return first 5 consents per each unique name
        return Object.keys(consentsByName).reduce(
          (consentsToDisplay, name) => consentsToDisplay.concat(...consentsByName[name].slice(0, 5)),
          [],
        );
      },
    };
  },
]);
