/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable i18next/no-literal-string */
import React, { ReactNode, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import {
  ActionData,
  BigidBody1,
  BigidButtonIcon,
  BigidCheckbox,
  BigidColorSchemeTokens,
  BigidDialog,
  BigidDropdownOption,
  BigidFilterOptionType,
  BigidFilterType,
  BigidLoader,
  BigidMenu,
  BigidSeverityBadge,
  BigidTextField,
  EntityEvents,
  LayoutContentType,
  PrimaryButton,
  SecondaryButton,
  entityEventsEmitter,
  toastNotificationService,
} from '@bigid-ui/components';
import {
  BigidGridColumn,
  BigidGridColumnTypes,
  BigidGridDataFetchResult,
  BigidGridQueryComponents,
  BigidGridWithToolbarProps,
} from '@bigid-ui/grid';
import { BigidLayout, BigidLayoutConfig } from '@bigid-ui/layout';
import { styled } from '@mui/material';
import { RiskComponent } from './RiskComponent';
import { cloneDeep, isEqual } from 'lodash';
import { v4 as uuid } from 'uuid';
import { RiskControl, RiskInterface, RiskMetadata } from './types';
import { AvatarCell } from './components/GridAvatarCell';
import { RiskLibraryEmptyGrid } from './components/RiskLibraryEmptyGrid';
import { useUserPreferences } from '../../components/hooks/useUserPrefrences';
import { httpService } from '../../services/httpService';
import { useTranslation } from 'react-i18next';
import { pageHeaderService } from '../../../common/services/pageHeaderService';
import { generateDataAid } from '@bigid-ui/utils';
import { RiskMatrixDialog } from './RiskMatrixDialog';
import { BigidAddIcon, BigidMoreActionIcon } from '@bigid-ui/icons';
import { RiskMatrixMetadata } from './RiskMatrixDefaults';
import { queryService } from '../../services/queryService';
import { trackManualEvent, RisksAndControlsTrackingEvents } from './risksAnalytics';
import { isPermitted } from '../../services/userPermissionsService';
import { PRIVACY_RISKS_PERMISSIONS } from '@bigid/permissions';
import { CreateRiskDialog } from './CreateRiskDialog';

const { RISK_LIBRARY_DELETE_RISK, RISK_LIBRARY_DUPLICATE_RISK } = RisksAndControlsTrackingEvents;

const RiskLibraryContainer = styled('div')({
  height: '100%',
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
});

export const NAMESPACE = 'bigid/riskLibrary';

export const useLocalTranslation = (keyPrefix?: string) => useTranslation(NAMESPACE, { keyPrefix });

export const RiskLibrary: React.FC = () => {
  const [riskMetadata, setRiskMetadata] = useState<RiskMetadata>();
  const [showDialog, setShowDialog] = useState<boolean>(false);
  const [dialogAction, setDialogAction] = useState<'create' | 'duplicate' | 'delete'>('create');
  const [isReloading, setIsReloading] = useState<boolean>(false);
  const [selectedRisk, setSelectedRisk] = useState<RiskInterface[] | undefined>(undefined);
  const [selectedRiskId, setSelectedRiskId] = useState<string | undefined>(undefined);
  const [layoutKey, setLayoutKey] = useState(uuid());
  const [checked, setChecked] = useState(false);
  const [controls, setControls] = useState<BigidDropdownOption[]>([]);
  const [duplicateOriginalRisk, setDuplicateOriginalRisk] = useState<RiskInterface | undefined>(undefined);
  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);
  const [isShowRiskMatrixDialog, setIsShowRiskMatrixDialog] = useState<boolean>(false);
  const [riskMatrixData, setRiskMatrixData] = useState<RiskMatrixMetadata>();

  const menuRef = useRef<HTMLDivElement>(null);

  pageHeaderService.setTitle({
    showBackButton: false,
    breadcrumbs: [
      {
        label: 'Risk Library',
      },
    ],
    rightSideComponentsContainer: (
      <div style={{ display: 'flex', flexDirection: 'row', gap: '8px', alignItems: 'center' }}>
        <div ref={menuRef}>
          <BigidButtonIcon onClick={() => setIsMenuOpen(true)} icon={BigidMoreActionIcon} />
        </div>
        {isPermitted(PRIVACY_RISKS_PERMISSIONS.CREATE.name) && (
          <PrimaryButton
            dataAid="BigidToolbarAction-row-create-new-risk"
            startIcon={<BigidAddIcon />}
            onClick={async () => {
              setDialogAction('create');
              setShowDialog(true);
              return Promise.resolve({
                shouldGridReload: false,
                shouldClearSelection: false,
                shouldClearSelectedItem: false,
              });
            }}
            size={'medium'}
          >
            {'Add Risk'}
          </PrimaryButton>
        )}
      </div>
    ),
  });

  const riskLevelFilter = (matrix: RiskMatrixMetadata) => {
    const filterMap = new Map<string, BigidFilterOptionType>();
    for (const cellKey of Object.keys(matrix.cellData)) {
      const cell = matrix.cellData[Number(cellKey)].riskLevelLabel;
      const opt = filterMap.get(cell);
      const value = [...((opt?.value as number[]) ?? []), Number(cellKey)];
      filterMap.set(cell, {
        label: cell,
        value,
        isSelected: false,
      });
    }

    return Array.from(filterMap.values());
  };

  const getFiltersFromServer = useCallback(async () => {
    const res = await httpService.fetch('risk-matrix');

    const matrix = {
      cellData: res.data.data.cellData,
      impactLabel: res.data.data.yAxisLabel,
      impactLabels: res.data.data.yAxisLabels,
      matrixSize: res.data.data.matrixSize,
      probabilityLabel: res.data.data.xAxisLabel,
      probabilityLabels: res.data.data.xAxisLabels,
    };

    const metadata = await httpService.fetch<RiskMetadata>('privacy-risks/search-metadata');
    setRiskMetadata(metadata.data);
    const filtersArray = [
      {
        operator: 'in',
        field: 'name',
        title: 'Name',
        value: [],
        options:
          metadata?.data?.filter?.name?.map(filter => ({
            label: filter.value,
            value: filter.value,
            isSelected: false,
          })) ?? [],
      } as BigidFilterType,
      {
        operator: 'in',
        field: 'owner',
        title: 'Owner',
        value: [],
        options:
          metadata?.data?.filter?.owner?.map(filter => ({
            label: filter.value,
            value: filter.value,
            isSelected: false,
          })) ?? [],
      } as BigidFilterType,
      {
        operator: 'in',
        field: 'category',
        title: 'Category',
        value: [],
        options:
          metadata?.data?.filter?.category?.map(filter => ({
            label: filter.value,
            value: filter.value,
            isSelected: false,
          })) ?? [],
      } as BigidFilterType,
      {
        operator: 'in',
        field: 'impact',
        title: 'Impact',
        disabled: true,
        value: [],
        options: matrix
          ? metadata?.data?.filter?.impact?.map(filter => ({
              label: matrix?.impactLabels[Number(filter.value)],
              value: filter.value,
              isSelected: false,
            })) ?? []
          : [],
      } as BigidFilterType,
      {
        operator: 'in',
        field: 'probability',
        title: 'Probability',
        disabled: true,
        value: [],
        options: matrix
          ? metadata?.data?.filter?.probability?.map(filter => ({
              label: matrix?.probabilityLabels[Number(filter.value)],
              value: filter.value,
              isSelected: false,
            })) ?? []
          : [],
      } as BigidFilterType,
      {
        title: 'Risk level',
        field: 'riskLevel',
        operator: 'in',
        disabled: false,
        value: [],
        options: matrix ? riskLevelFilter(matrix) : [],
      } as BigidFilterType,
    ];

    entityEventsEmitter.emit(EntityEvents.RELOAD, {
      entityId: layoutConfig.content.viewConfig.gridConfig.gridId,
      shouldEntityReload: true,
    });
    entityEventsEmitter.emit(EntityEvents.REFRESH_FILTERS, filtersArray);

    setRiskMatrixData(prev => (isEqual(prev, matrix) ? prev : matrix));

    return filtersArray;
  }, []);

  const getControls = async () => {
    const res = await httpService.fetch<{
      data: RiskControl[];
      totalCount: number;
    }>('risk-controls');

    if (res) {
      const controlList: BigidDropdownOption[] = res.data.data?.map(control => ({
        displayValue: control.name,
        value: control.id,
        id: control.id,
      }));

      setControls(controlList);
    }
  };

  useEffect(() => {
    const loadData = async () => {
      await getControls();
      setIsReloading(true);
    };
    loadData();
  }, []);

  const deleteRisks = async (riskIds: string[]) => {
    try {
      await httpService.delete('privacy-risks?' + riskIds.map(id => `rule_ids=${id}`).join('&'));
      setLayoutKey(uuid());
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (err: any) {
      console.log(err);
      toastNotificationService.error(err.message, {
        shouldCloseOnTransition: true,
      });
    }
  };

  const duplicateRiskHandler = async (id: string, risk: RiskInterface) => {
    try {
      const response = await httpService.post(`/privacy-risks/${id}/duplicate`, {
        name: risk.name,
        description: risk.description,
      });

      setSelectedRiskId(response.data.riskId);
      setIsReloading(true);
      setLayoutKey(uuid());
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (err: any) {
      console.log(err);
      toastNotificationService.error(err.message, {
        shouldCloseOnTransition: true,
      });
    }
  };

  const fetchGridData = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    async (queryComponents: BigidGridQueryComponents): Promise<BigidGridDataFetchResult<RiskInterface>> => {
      queryComponents.filter.forEach(filter => {
        if (Array.isArray(filter.value)) {
          filter.value = filter.value.map(val => (typeof filter.value === 'string' ? (val as string).trim() : val));
        } else {
          filter.value = typeof filter.value === 'string' ? (filter.value as string).trim() : filter.value;
        }
      });
      const gridConfigQuery = queryService.getGridConfigQuery(queryComponents);

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const response = await httpService.fetch('privacy-risks?' + gridConfigQuery);
      const risksResults = response.data?.rules?.map(
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (policy: any) =>
          ({
            id: policy.id,
            name: policy.displayName || policy.name,
            description: policy.description,
            category: policy.category,
            probability: policy.probability,
            impact: policy.impact,
            riskLevel: policy.riskLevel,
            owner: policy.owner,
            controls: policy.controls,
          } as RiskInterface),
      );

      return {
        totalCount: response.data.totalCount,
        data: risksResults ?? [],
      };
    },
    [],
  );

  const initialGridColumns: BigidGridColumn<RiskInterface>[] = useMemo(
    () => [
      {
        title: 'Risk name',
        name: 'name',
        getCellValue: row => row.name,
        type: BigidGridColumnTypes.TEXT,
        isListColumn: true,
      },
      {
        title: 'Description',
        name: 'description',
        getCellValue: row => row.description,
        type: BigidGridColumnTypes.TEXT,
      },
      {
        title: 'Category',
        name: 'category',
        getCellValue: row => row.category,
        type: BigidGridColumnTypes.TEXT,
      },
      {
        title: 'Probability',
        name: 'probability',
        getCellValue: row => riskMatrixData?.probabilityLabels[row.probability],
        type: BigidGridColumnTypes.TEXT,
      },
      {
        title: 'Impact',
        name: 'impact',
        getCellValue: row => riskMatrixData?.impactLabels[row.impact],
        type: BigidGridColumnTypes.TEXT,
      },
      {
        title: 'Risk Level',
        name: 'riskLevel',
        getCellValue: ({ riskLevel }: { riskLevel: number }) => (
          <BigidSeverityBadge
            dataAid={'risk-library-risk-level'}
            size="medium"
            level="custom"
            hasBackground={false}
            customColor={riskMatrixData?.cellData[riskLevel]?.color ?? BigidColorSchemeTokens.light.backgroundActive}
            customLabel={riskMatrixData?.cellData[riskLevel]?.riskLevelLabel ?? ''}
          />
        ),
        type: BigidGridColumnTypes.CUSTOM,
      },
      {
        title: 'Risk Owner',
        name: 'owner',
        getCellValue: row => <AvatarCell users={row.owner ? [row.owner] : []} />,
        type: BigidGridColumnTypes.CUSTOM,
      },
      {
        title: 'Controls',
        name: 'controls',
        getCellValue: row =>
          row.controls
            ?.map(riskControl => controls.find(control => control.id === riskControl.id)?.displayValue)
            .join(', '),
        type: BigidGridColumnTypes.TEXT,
      },
    ],
    [controls, riskMatrixData],
  );

  const filterConfig = useCallback(async () => {
    const newFilters = await getFiltersFromServer();

    const filterConfig: BigidGridWithToolbarProps<RiskInterface>['filterToolbarConfig'] = {
      filters: newFilters,
      searchConfig: {
        searchFilterKeys: ['name'],
        operator: 'contains',
        placeholder: '',
        initialValue: '',
      },
    };

    return filterConfig;
  }, []);

  const { isReady, preferences, gridColumns, filterToolbarConfig, updatePreferences } = useUserPreferences({
    stateName: `RISK_SETTINGS_RiskLibrary`,
    initialGridColumns,
    getInitialFilterToolbarConfig: filterConfig,
  });

  const layoutConfig: BigidLayoutConfig = useMemo(() => {
    return {
      content: {
        contentTypes: [LayoutContentType.MASTER_DETAILS],
        defaultContentType: LayoutContentType.MASTER_DETAILS,
        selectedItem: selectedRisk?.length > 0 ? selectedRisk[0] : undefined,
        viewConfig: {
          filterToolbarConfig,
          onGridStateChange: ({ filter, ...gridState }) => {
            updatePreferences({ filterState: { filter }, gridState });
          },
          fetchGridData: fetchGridData,
          gridConfig: {
            selectedRowIds: [selectedRiskId],
            defaultSorting: preferences?.grid?.sort || [{ field: 'name', order: 'asc' }],
            columns: initialGridColumns,
            rowClickShouldKeepSelection: true,
            showSelectionCheckboxes: true,
            showSelectionColumn: true,
            gridId: 'riskLibrary',
          },
          selectedItemPropsMapping: {
            id: 'id',
            name: 'name',
            description: 'description',
            category: 'category',
            controls: 'controls',
            impact: 'impact',
            owner: 'owner',
            probability: 'probability',
            riskLevel: 'riskLevel',
          },
          masterDetailsConfig: {
            placeholderComponent: BigidLoader as unknown as ReactNode,
            actions: [],
            selectedItem: selectedRisk?.[0],
            setSelectedItemInFetch: true,
            shouldReloadGridOnClose: true,
            tabsAndContent: {
              hideTabs: true,
              tabProps: {
                tabs: [
                  {
                    label: '',
                    data: {
                      component: RiskComponent,
                      customProps: {
                        onClose: async (reload?: boolean) => {
                          setSelectedRisk(undefined);
                          setSelectedRiskId(undefined);
                          reload && setIsReloading(true);
                          setLayoutKey(uuid());
                        },
                        riskControls: controls,
                        categories: riskMetadata ? riskMetadata?.filter?.category?.map(filter => filter.value) : [],
                        riskMatrix: riskMatrixData,
                      },
                    },
                  },
                ],
                selectedIndex: 0,
              },
            },
            isEditableHeader: true,
            isHeaderHidden: true,
            isEditableHeaderDisabled: true,
          },
          toolbarConfig: {
            showSelectAllColumnChooser: true,
            hideToolbar: false,
          },
        },
        toolbarActions: [
          {
            label: 'Delete',
            execute: async (props: ActionData) => {
              setSelectedRisk(cloneDeep(props.selectedRows));
              setDialogAction('delete');
              setShowDialog(true);

              return Promise.resolve({
                shouldGridReload: false,
                shouldClearSelection: false,
                shouldClearSelectedItem: false,
              });
            },
            show: (props: ActionData) =>
              (props.selectedRowIds as string[])?.length > 0 && isPermitted(PRIVACY_RISKS_PERMISSIONS.DELETE.name),
          },
          {
            label: 'Duplicate',
            execute: async (props: ActionData) => {
              setSelectedRisk(cloneDeep(props.selectedRows));
              setDuplicateOriginalRisk(props.selectedRows?.length === 1 ? cloneDeep(props.selectedRows[0]) : undefined);
              setDialogAction('duplicate');
              setShowDialog(true);

              return Promise.resolve({
                shouldGridReload: false,
                shouldClearSelection: false,
                shouldClearSelectedItem: false,
              });
            },

            show: (props: ActionData) =>
              (props.selectedRowIds as string[])?.length === 1 && isPermitted(PRIVACY_RISKS_PERMISSIONS.CREATE.name),
          },
        ],
      },
      emptyState: {
        illustration: RiskLibraryEmptyGrid,
        title: `The library doesn't contain any risks`,
        actions: [
          {
            label: 'Create New Risk',
            execute: async () => {
              setDialogAction('create');
              setShowDialog(true);
              return Promise.resolve({
                shouldGridReload: true,
                layoutEntities: [{}],
              });
            },
            show: () => isPermitted(PRIVACY_RISKS_PERMISSIONS.CREATE.name),
          },
        ],
        show: state => {
          return (
            !isReloading &&
            isReady &&
            !state.useFetchState.rows?.length &&
            !state.useFetchState.filter?.length &&
            !filterToolbarConfig?.filters?.[0].options.length
          );
        },
      },
    } as BigidLayoutConfig;
  }, [
    gridColumns,
    controls,
    fetchGridData,
    filterToolbarConfig?.filters,
    isReloading,
    riskMetadata,
    selectedRisk,
    selectedRiskId,
    riskMatrixData,
    isReady,
  ]);

  return (
    <RiskLibraryContainer>
      {isReady && filterToolbarConfig?.filters && riskMatrixData && (
        <BigidLayout key={layoutKey} config={layoutConfig} />
      )}
      <BigidDialog
        isOpen={showDialog && dialogAction === 'duplicate'}
        onClose={() => {
          setSelectedRisk(undefined);
          setShowDialog(false);
        }}
        title={'Duplicate Risk'}
        borderTop
        buttons={[
          {
            component: SecondaryButton,
            text: 'Cancel',
            onClick: () => {
              setSelectedRisk(undefined);
              setShowDialog(false);
            },
            dataAid: 'risk-library-duplicate-cancel',
          },
          {
            component: PrimaryButton,
            text: 'Duplicate',
            onClick: async () => {
              setSelectedRisk(undefined);
              setSelectedRiskId(undefined);
              setShowDialog(false);
              if (selectedRisk) {
                await duplicateRiskHandler(selectedRisk?.[0].id, selectedRisk?.[0]);
                trackManualEvent(RISK_LIBRARY_DUPLICATE_RISK, {
                  ...selectedRisk[0],
                  riskLevelValue: riskMatrixData?.cellData[selectedRisk[0].riskLevel]?.riskLevelLabel ?? '',
                });
              }
            },
            dataAid: 'risk-library-duplicate-duplicate',
            disabled: duplicateOriginalRisk && duplicateOriginalRisk.name === selectedRisk?.[0].name,
          },
        ]}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            gap: 14,
          }}
        >
          <BigidTextField
            label="Risk Name"
            required
            value={selectedRisk ? selectedRisk[0].name : ''}
            onChange={event => {
              if (selectedRisk?.length === 1) {
                const controlClone = cloneDeep(selectedRisk);
                controlClone[0].name = event.target.value;
                setSelectedRisk(controlClone);
              }
            }}
          ></BigidTextField>
          <BigidTextField
            label="Description"
            required
            value={selectedRisk ? selectedRisk[0].description : ''}
            onChange={event => {
              if (selectedRisk?.length === 1) {
                const controlClone = cloneDeep(selectedRisk);
                controlClone[0].description = event.target.value;
                setSelectedRisk(controlClone);
              }
            }}
          ></BigidTextField>
        </div>
      </BigidDialog>
      <BigidDialog
        isOpen={showDialog && dialogAction === 'delete'}
        onClose={() => {
          setSelectedRisk(undefined);
          setShowDialog(false);
        }}
        title={'Risk Deletion'}
        borderTop
        buttons={[
          {
            component: SecondaryButton,
            text: 'Cancel',
            onClick: () => {
              setSelectedRisk(undefined);
              setShowDialog(false);
            },
            dataAid: 'risk-library-delete-cancel',
          },
          {
            component: PrimaryButton,
            text: 'Delete Risk',
            onClick: async () => {
              if (selectedRisk && checked) {
                await deleteRisks(selectedRisk.map(risk => risk.id));
                setIsReloading(true);
                selectedRisk?.forEach(risk => {
                  trackManualEvent(RISK_LIBRARY_DELETE_RISK, {
                    ...risk,
                    riskLevelValue: riskMatrixData?.cellData[risk.riskLevel]?.riskLevelLabel ?? '',
                  });
                });
              }
              setSelectedRisk(undefined);
              setShowDialog(false);
              setChecked(false);
            },
            disabled: !checked,
            dataAid: 'risk-library-delete-delete',
          },
        ]}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            gap: 14,
          }}
        >
          {selectedRisk?.length === 1 && (
            <BigidBody1>
              You are about to delete the risk <b>“{selectedRisk[0].name}”</b>. As a result, all assessments using this
              risk will be updated.
            </BigidBody1>
          )}
          {selectedRisk && selectedRisk?.length > 1 && (
            <BigidBody1>
              You are about to delete {selectedRisk?.length} Risks. As a result, all assessments using this risks will
              be updated.
            </BigidBody1>
          )}
          <BigidBody1>This action can’t be undone. Are you sure</BigidBody1>
          <BigidBody1>you want to delete the risk?</BigidBody1>
          <BigidCheckbox
            dataAid="risk-library-delete-confirm"
            checked={checked}
            onChange={(_event, value) => setChecked(value)}
            label={`I understand that multiple Assessments using ${
              selectedRisk?.length === 1 ? 'this risk' : 'these risks'
            } may be affected`}
          />
        </div>
      </BigidDialog>
      {showDialog && dialogAction === 'create' && (
        <CreateRiskDialog
          onClose={() => {
            setShowDialog(false);
            setIsReloading(true);
            getFiltersFromServer();
          }}
          isOpen={showDialog && dialogAction === 'create'}
          riskControls={controls}
          categories={riskMetadata ? riskMetadata?.filter?.category?.map(filter => filter.value) : []}
          riskMatrix={riskMatrixData}
        />
      )}

      <BigidMenu
        open={isMenuOpen}
        onMenuClose={() => {
          setIsMenuOpen(false);
        }}
        anchorEl={menuRef.current ?? undefined}
        anchorOrigin={{
          horizontal: 'right',
          vertical: 'bottom',
        }}
        transformOrigin={{
          horizontal: 'right',
          vertical: 'top',
        }}
        items={[
          {
            id: generateDataAid('risk-settings-menu-risk-matrix', []),
            label: 'Customize risk matrix',
            onClick: () => {
              setIsMenuOpen(false);
              setIsShowRiskMatrixDialog(true);
            },
          },
        ]}
      />
      {isShowRiskMatrixDialog && riskMatrixData && (
        <RiskMatrixDialog
          data={riskMatrixData}
          open={isShowRiskMatrixDialog}
          onClose={data => {
            setIsShowRiskMatrixDialog(false);
            if (data) {
              setRiskMatrixData(data);
              setIsReloading(true);
              setLayoutKey(uuid());
            }
          }}
        />
      )}
    </RiskLibraryContainer>
  );
};
