import { IComponentOptions } from 'angular';
import template from './searchHeader.component.html';
import './searchHeader.component.scss';
import { convertToReact } from '../../common/services/convertToReact';
import { sessionStorageService } from '../../common/services/sessionStorageService';
import { $rootScope, $window } from 'ngimport';
import { $state, $stateParams, localStorageService, queryStringService } from '../../react/services/angularServices';
import { getQueryRiskScore, HistoryItem } from '../../react/components/BigidHeader/HeaderService';

const SYNC_EVENT_LISTENER_CHART = 'chart';
const SYNC_EVENT_LISTENER_SIDE_FILTER = 'sideFilter';
const EXPLORATION_ROUTE = 'exploration';

export class SearchHeaderController implements ng.IController {
  public scope: ng.IScope;
  public queryString = '';
  public queryItemsObj = {};
  public showSearchHeader = false;

  static $inject = ['$scope'];

  constructor($scope: ng.IScope) {
    this.scope = $scope;
  }

  public $onDestory = (): void => {
    this.listenerChangePage();
    this.listenerChangeHeaderTextBox();
    this.listenerClearFilterMapClicked();
    this.listenerMapFilter();
    this.listenerClearMapFilter();
    this.listenerFilterSearchHeader();
  };

  public onCleanQueryString = () => {
    this.cleanQueryString();
  };

  public onQueryStringChanged = () => {
    if (this.queryString === '') {
      this.cleanQueryString();
    }
  };

  public cleanQueryString = () => {
    this.queryString = '';
    this.queryItemsObj = {};
    sessionStorageService.remove('queryFilterString');

    localStorageService.remove('filterValue');
    localStorageService.remove('userNameListChecked');
    getQueryRiskScore();
    if ($state.current.name === 'exploration') {
      $rootScope.$emit('cleanFilter', $stateParams.filter ? true : false);
      $rootScope.$emit('cleanAmmapFilter');
      $state.go('exploration', { filter: null });
    } else {
      //if the page is not exploration
      $rootScope.$emit('cleanFilter');
      $rootScope.$emit('callFilter', null, null);
    }
  };

  /**
   * This function makes this.queryItemsObj
   * initialized by selected/removed items for every type
   * e.g. {country: [Germany, Israel], system: [MySQL]}
   */
  public setQueryItemsObject = (queryItemsObject: any, selected: any, removed: any, type: string) => {
    const newQueryItemsObject = Object.assign({}, queryItemsObject);

    if (selected.length > 0) {
      if (typeof newQueryItemsObject[type] === 'undefined') {
        newQueryItemsObject[type] = [];
      }

      newQueryItemsObject[type] = [...newQueryItemsObject[type], ...selected].filter(
        (item, index, array) => array.indexOf(item) === index,
      );
    } else if (removed.length > 0 && typeof newQueryItemsObject[type] !== 'undefined') {
      for (let i = 0, len = removed.length; i < len; i++) {
        const index = newQueryItemsObject[type].indexOf(removed[i]);

        if (index > -1) {
          newQueryItemsObject[type] = [
            ...newQueryItemsObject[type].slice(0, index),
            ...newQueryItemsObject[type].slice(index + 1),
          ];
        }
      }
    }

    return newQueryItemsObject;
  };

  public listenerFilterSearchHeader = $rootScope.$on(
    'callToFilterSearchHeader',
    (event, filterType, selectedFilterValue, removedFilterValue, eventInitiator) => {
      const filterTypeName = typeof filterType === 'object' ? filterType.name : filterType;

      switch (filterTypeName) {
        case 'Identities':
          this.queryItemsObj = this.setQueryItemsObject(
            this.queryItemsObj,
            selectedFilterValue,
            removedFilterValue,
            'country',
          );
          this.queryString = this.createSearchText(this.queryItemsObj);
          break;
        case 'Systems':
          this.queryItemsObj = this.setQueryItemsObject(
            this.queryItemsObj,
            selectedFilterValue,
            removedFilterValue,
            'system',
          );
          this.queryString = this.createSearchText(this.queryItemsObj);
          break;
        case 'Applications':
          this.queryItemsObj = this.setQueryItemsObject(
            this.queryItemsObj,
            selectedFilterValue,
            removedFilterValue,
            'application',
          );
          this.queryString = this.createSearchText(this.queryItemsObj);
          break;
        case 'Attributes':
          this.queryItemsObj = this.setQueryItemsObject(
            this.queryItemsObj,
            selectedFilterValue,
            removedFilterValue,
            'field',
          );
          this.queryString = this.createSearchText(this.queryItemsObj);
          break;
        default: {
          const _filterType = filterType.prefix + '.' + filterTypeName.toLowerCase();

          this.queryItemsObj = this.setQueryItemsObject(
            this.queryItemsObj,
            selectedFilterValue,
            removedFilterValue,
            _filterType,
          );
          this.queryString = this.createSearchText(this.queryItemsObj);
        }
      }

      const listeners = {
        [SYNC_EVENT_LISTENER_CHART]: eventInitiator === SYNC_EVENT_LISTENER_SIDE_FILTER ? true : false,
        [SYNC_EVENT_LISTENER_SIDE_FILTER]: eventInitiator === SYNC_EVENT_LISTENER_CHART ? true : false,
      };

      this.callToHeaderQueryFilter(listeners);
    },
  );

  //Clear filter for map selected object
  public listenerClearMapFilter = $rootScope.$on('ammapClearFilter', (event, filterMapValue) => {
    if (this.queryString) {
      if (this.queryString.includes(filterMapValue + ' AND')) {
        this.queryString = this.queryString.replace(filterMapValue + ' AND ', '');
      } else if (this.queryString.includes('AND ' + filterMapValue)) {
        this.queryString = this.queryString.replace(' AND ' + filterMapValue, '');
      } else if (this.queryString.includes(filterMapValue)) {
        this.queryString = this.queryString.replace(filterMapValue, '');
      }
      $rootScope.$emit('callToHeaderQueryFilter', queryStringService.getQueryStringFilter(this.queryString, null));
      $rootScope.$emit('callFilter', this.queryString, null);
    }
  });

  //Filter for map selected object
  public listenerMapFilter = $rootScope.$on('ammapFilter', (event, filterMapValue) => {
    if (this.queryString) {
      //check if the value already exists in the filter text box
      if (!this.queryString.includes(filterMapValue)) {
        if (!this.queryString.replace(/\s/g, '').length) {
          // string onlyclae contained whitespace (ie. spaces, tabs or line breaks)
          this.queryString = filterMapValue.trim();
        }
        if (this.queryString.trim().startsWith('AND')) {
          this.queryString = this.queryString.trim().substring(3);
        }
        this.queryString = this.queryString.trimEnd();
        this.queryString += ' AND ' + filterMapValue;
      }
    } else {
      this.queryString = filterMapValue.trimStart();
    }

    $rootScope.$emit('callToHeaderQueryFilter', queryStringService.getQueryStringFilter(this.queryString, null));
    $rootScope.$emit('callFilter', this.queryString, null);

    getQueryRiskScore(queryStringService.getQueryStringFilter(this.queryString, null));
  });

  public listenerClearFilterMapClicked = $rootScope.$on('ClearFilterMapClicked', () => {
    const user_location = $window.sessionStorage.getItem('user.location');
    const system_location = $window.sessionStorage.getItem('system.location');

    user_location && this.fixAndClearSearchText(user_location);
    system_location && this.fixAndClearSearchText(system_location);

    $rootScope.$emit('callToHeaderQueryFilter', queryStringService.getQueryStringFilter(this.queryString, null));
    $rootScope.$emit('callFilter', this.queryString, null);

    getQueryRiskScore(queryStringService.getQueryStringFilter(this.queryString, null));

    $window.sessionStorage.removeItem('user.location');
    $window.sessionStorage.removeItem('system.location');
  });

  public listenerChangeHeaderTextBox = $rootScope.$on('changeHeaderTextBox', (event, filterValue) => {
    if (filterValue) {
      filterValue = decodeURIComponent(filterValue);
      let startIndex = 0;
      let endIndex = 0;
      const filterWord = '?filter=';
      const idsWord = '&ids[]=';
      if (filterValue.includes(filterWord)) {
        startIndex = filterValue.indexOf(filterWord) + filterWord.length;
        if (filterValue.includes(idsWord)) {
          endIndex = filterValue.indexOf(idsWord);
        } else {
          endIndex = filterValue.length;
        }
        this.queryString = filterValue.substring(startIndex, endIndex);
      }

      this.onSubmitFilter();
    }
  });

  public onChangePage = (
    event: any,
    pageName: string,
    showSearchHeader = false,
    showBackButton = false,
    from: HistoryItem = null,
  ) => {
    this.showSearchHeader = showSearchHeader;

    if (pageName !== 'Inventory') {
      this.queryString = '';
      this.queryString = '';
    } else if ($stateParams.filter !== null) {
      this.queryString = decodeURIComponent($stateParams.filter).replace('?filter=', '');
    }

    this.queryItemsObj = {};

    if (pageName === 'Inventory' || pageName === 'Scan Results Summary' || pageName === 'Dashboard') {
      getQueryRiskScore($stateParams.filter);

      if ($state.current.name === EXPLORATION_ROUTE) {
        this.onSubmitFilter();
      }
    }
    if (pageName === 'Entity Details') {
      const id = decodeURIComponent($stateParams.id);
      const queryString = queryStringService.getQueryStringFilter(null, [id]);
      getQueryRiskScore(queryString);
    }
  };

  public listenerChangePage = $rootScope.$on('changePage', this.onChangePage);

  public onSubmitFilter = () => {
    let savedQueryFilterString: string = sessionStorageService.get('queryFilterString');

    if (savedQueryFilterString?.includes('tag.')) {
      savedQueryFilterString = savedQueryFilterString.replace('tag.', 'query.');
    }

    if (!this.queryString && savedQueryFilterString) {
      this.queryString = savedQueryFilterString;
    }

    localStorageService.set('filterValue', this.queryString);

    const queryString = queryStringService.getQueryStringFilter(
      this.queryString,
      localStorageService.get('userNameListChecked'),
    );

    this.queryItemsObj = this.parseQueryToObject(this.queryString);

    getQueryRiskScore(queryString);

    const listeners = {
      [SYNC_EVENT_LISTENER_CHART]: true,
      [SYNC_EVENT_LISTENER_SIDE_FILTER]: true,
    };

    if ($state.current.name === 'Inventory') {
      this.callToHeaderQueryFilter(listeners);
    } else {
      if ($state.current.name !== 'exploration') {
        listeners[SYNC_EVENT_LISTENER_CHART] = false;
        listeners[SYNC_EVENT_LISTENER_SIDE_FILTER] = false;

        this.callToHeaderQueryFilter(listeners);
        $state.transitionTo('exploration', { searchTextBox: this.queryString, filter: queryString });
      } else {
        this.callToHeaderQueryFilter(listeners);
      }
    }
  };

  public callToHeaderQueryFilter = (eventListeners = {}) => {
    getQueryRiskScore(queryStringService.getQueryStringFilter(this.queryString, null));
    sessionStorageService.set('queryFilterString', this.queryString);

    localStorageService.set('filterValue', this.queryString);
    $rootScope.$emit('callFilter', this.queryString, localStorageService.get('userNameListChecked'), true);

    $rootScope.$emit(
      'callToHeaderQueryFilter',
      queryStringService.getQueryStringFilter(this.queryString, null),
      eventListeners,
    );
  };

  public parseQueryToObject = (query: string) => {
    const parsedObject: any = {};

    const SEQUENCE_SEPARATOR_REGEX = /\s*(?:AND|OR)+\s*/;
    const MULTIPLE_VALUES_SEPARATOR = /\s*,\s*/;
    const SINGLE_VALUE_SEPARATOR = /\s*=\s*/;

    const IS_MULTIPLE_VALUES_REGEX = /\s*IN\s*\(/;
    const IS_SINGLE_VALUE_REGEX = /\s*=\s*/;

    const IN_REGEX = /\s*IN\s*/;
    const MULTIPLE_VALUES_REGEX = /\s*\(.*\)$/;

    query.split(SEQUENCE_SEPARATOR_REGEX).forEach(part => {
      const isMultipleValuesCase = !!part.match(IS_MULTIPLE_VALUES_REGEX);
      const isSingleValueCase = !!part.match(IS_SINGLE_VALUE_REGEX);

      if (isMultipleValuesCase) {
        const [key = null, value = null] = part.split(IN_REGEX);

        if (key !== null && value !== null) {
          const [multipleValuesStringMatch = null] = value.match(MULTIPLE_VALUES_REGEX);

          if (multipleValuesStringMatch !== null) {
            if (typeof parsedObject[key] == 'undefined') {
              parsedObject[key] = [];
            }

            const values = multipleValuesStringMatch
              .slice(1, -1)
              .split(MULTIPLE_VALUES_SEPARATOR)
              .map(value => value.trim())
              .filter(value => value !== '');

            parsedObject[key] = [...parsedObject[key], ...values];
          }
        }
      } else if (isSingleValueCase) {
        const [key = null, value = null] = part.split(SINGLE_VALUE_SEPARATOR);

        if (key !== null && value !== null) {
          if (typeof parsedObject[key] == 'undefined') {
            parsedObject[key] = [];
          }

          if (value !== '') {
            parsedObject[key] = [...parsedObject[key], value];
          }
        }
      }
    });

    return parsedObject;
  };

  public createSearchText = (queryItemsObj: any) => {
    let searchText = '';

    Object.keys(queryItemsObj).forEach(type => {
      if (queryItemsObj[type].length > 1) {
        searchText += (searchText.length > 0 ? ' AND ' : '') + type + ' IN (' + queryItemsObj[type].join(', ') + ')';
      } else if (queryItemsObj[type].length === 1) {
        searchText += (searchText.length > 0 ? ' AND ' : '') + type + '=' + queryItemsObj[type][0];
      }
    });

    return searchText;
  };

  public fixAndClearSearchText = (filterMapValue: string) => {
    if (this.queryString) {
      if (this.queryString.includes(filterMapValue + ' AND')) {
        this.queryString = this.queryString.replace(filterMapValue + ' AND ', '');
      } else if (this.queryString.includes('AND ' + filterMapValue)) {
        this.queryString = this.queryString.replace(' AND ' + filterMapValue, '');
      } else if (this.queryString.includes(filterMapValue)) {
        this.queryString = this.queryString.replace(filterMapValue, '');
      }
    }
  };
}

export const SearchHeaderComponentImpl: IComponentOptions = {
  template,
  controller: SearchHeaderController,
};

export const BigidSearchHeader = convertToReact('bigidSearchHeader', SearchHeaderComponentImpl);
