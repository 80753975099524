import { UseMutationOptions, MutationFunction, useMutation, UseMutationResult } from 'react-query';
import { UserPreference, userPreferencesService } from '../services/userPreferencesService';
import type { AsyncReturnType } from '../types/types';
import type { BodyType, ErrorType } from '../config/request';

export type UpdateUserPreferencesPayload<T> = UserPreference<T>;

const updateUserPreferences = async <T = any>(data: UserPreference<T>): Promise<void> =>
  userPreferencesService?.update(data);

export type UpdateUserPreferencesQueryResult = NonNullable<AsyncReturnType<typeof updateUserPreferences>>;

export type UpdateUserPreferencesQueryError = ErrorType<unknown>;

export const useUpdateUserPreferences = <
  TData = any,
  TError = UpdateUserPreferencesQueryError,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    AsyncReturnType<typeof updateUserPreferences>,
    TError,
    { data: BodyType<UpdateUserPreferencesPayload<TData>> },
    TContext
  >;
}): UseMutationResult<void, TError, { data: BodyType<UpdateUserPreferencesPayload<TData>> }, TContext> => {
  const { mutation: mutationOptions } = options || {};
  const mutationFn: MutationFunction<
    AsyncReturnType<typeof updateUserPreferences>,
    { data: BodyType<UpdateUserPreferencesPayload<TData>> }
  > = async props => {
    const { data } = props || {};

    return updateUserPreferences(data);
  };

  return useMutation<
    AsyncReturnType<typeof updateUserPreferences>,
    TError,
    { data: BodyType<UpdateUserPreferencesPayload<TData>> },
    TContext
  >(mutationFn, mutationOptions);
};
