import { DataSourceConfigurationContextInterface } from '../DataSourceConfigurationContext';
import { httpService } from '../../../../services/httpService';
import { notificationService } from '../../../../services/notificationService';

export const configureHadoopFieldsFromSource = async (
  lid: string,
  stateAndHandlers: DataSourceConfigurationContextInterface,
) => {
  const { getValuesContainer, createSetValueFunction } = stateAndHandlers;
  const values = getValuesContainer.current();
  const configURL = values.loadHadoopConfiguration;
  try {
    const fieldData = await getHadoopConfiguration(configURL);
    if (fieldData) {
      createSetValueFunction.current('yarnAddress')(fieldData['yarn.resourcemanager.address']);
      createSetValueFunction.current('hdfsURI')(fieldData['fs.defaultFS']);
      createSetValueFunction.current('hdfsNameNodePrincipal')(fieldData['dfs.namenode.kerberos.principal']);
      createSetValueFunction.current('yarnNodeManagerPrincipal')(fieldData['yarn.nodemanager.principal']);
      createSetValueFunction.current('yarnPrincipal')(fieldData['yarn.resourcemanager.principal']);
      createSetValueFunction.current('yarnHostname')(fieldData['yarn.resourcemanager.hostname']);
      createSetValueFunction.current('yarnSchedulerAddress')(fieldData['yarn.resourcemanager.scheduler.address']);
      createSetValueFunction.current('mapReduceFrameworkPath')(fieldData['mapreduce.application.framework.path']);
      createSetValueFunction.current('yarnClassPath')(fieldData['yarn.application.classpath']);
      createSetValueFunction.current('mapReduceClassPath')(fieldData['mapreduce.application.classpath']);
      createSetValueFunction.current('mapReduceJobQueueName')(fieldData['mapreduce.job.queuename']);
    }
    createSetValueFunction.current('authMethod')('KERBEROS');
  } catch (Error) {
    notificationService.error('Request Failed');
  }
};

export async function getHadoopConfiguration(url: string) {
  const request = { url };
  const response = await httpService.post('hadoopConfig', request);
  return response.data;
}
