import React, { ComponentType, FC, HTMLAttributes } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { BigidLink, LinkColor } from '@bigid-ui/components';
import { GettingStartedUITrackingEvent, trackEventGettingStarted } from '../GettingStartedEventTrackerUtils';
import { SvgIconProps } from '@mui/material/SvgIcon';
import { IconComponentProps, IconTheme } from '@bigid-ui/icons';
import { generateDataAid } from '@bigid-ui/utils';

export const enum underlineOptions {
  ALWAYS = 'always',
  NONE = 'none',
  HOVER = 'hover',
}

const useStyles = makeStyles({
  root: {
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex',
    fontSize: 12,
  },
  iconMargin: {
    marginRight: '7px',
  },
});

export interface GettingStartedFooterItemProps {
  url: string;
  name: string;
  biEventName: GettingStartedUITrackingEvent;
  text: string;
  Icon?: ComponentType<HTMLAttributes<SVGElement>> | ComponentType<SvgIconProps> | ComponentType<IconComponentProps>;
  linkColor: LinkColor;
  underline: underlineOptions;
}

export const GettingStartedFooterItem: FC<GettingStartedFooterItemProps> = ({
  url,
  name,
  biEventName,
  text,
  Icon,
  linkColor,
  underline,
}) => {
  const classes = useStyles({});

  return (
    <div className={classes.root} data-aid={generateDataAid('GettingStartedFooterItem', [name])}>
      {Icon ? <Icon theme={IconTheme.CLOUD} className={classes.iconMargin} /> : ''}
      <BigidLink
        color={linkColor}
        href={url}
        onClick={() => {
          trackEventGettingStarted(biEventName);
        }}
        shouldOpenNewTab={true}
        text={text}
        title={text}
        underline={underline}
      />
    </div>
  );
};
