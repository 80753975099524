import React, { FC, useEffect, ReactText, useState } from 'react';
import { BigidPaper } from '@bigid-ui/components';
import makeStyles from '@mui/styles/makeStyles';
import { pageHeaderService } from '../../../common/services/pageHeaderService';
import { AutoDiscoveryProgress } from './AutoDiscoveryProgress/AutoDiscoveryProgress';
import { AutoDiscoveryExplorer } from './AutoDiscoveryExplorer/AutoDiscoveryExplorer';
import { AutoDiscoveryWizardSummary } from './AutoDiscoverySummary/AutoDiscoverySummery';
import { AutoDiscoveryNoData } from './AutoDiscoveryNoData/AutoDiscoveryNoData';
import { getAppRunCount } from '../../services/autoDiscoveryService';

const AUTO_DISCOVERY_PAGE_TITLE = 'Discover Data Sources';
const AUTO_DISCOVERY_PROFILE_DESC = 'Auto Scan Profile';

const useStyles = makeStyles({
  autoDiscoveryContainer: {
    height: '35vw',
    maxHeight: 635,
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
  },
});

export enum AutoDiscoveryViews {
  DISCOVER = 'discover',
  NO_DATA = 'noData',
  EXPLORER = 'explorer',
  SUMMARY = 'summary',
}

export interface AutoDiscoveryWizardProfile {
  selectedIds: ReactText[];
  selectedDsNames: string[];
  scanProfileName: string;
  scanType: string;
  isScanRunning: boolean;
  dsType: string;
  subtext: string;
}

export type WizardSubtexts = Record<string, string>;

export const AutoDiscoveryWizard: FC = () => {
  const { autoDiscoveryContainer } = useStyles({});
  const [flowStage, setFlowStage] = useState<AutoDiscoveryViews>(AutoDiscoveryViews.DISCOVER);
  const [profiles, setProfiles] = useState<Map<string, AutoDiscoveryWizardProfile>>(new Map());
  const [profileRunCount, setProfileRunCount] = useState<number>(0);
  const [wizardSubtexts, setWizardSubtexts] = useState<WizardSubtexts>({});

  const updateProfile = (key: string, value: AutoDiscoveryWizardProfile) => {
    if (key && value) {
      setProfiles(new Map(profiles.set(key, value)));
    }
  };

  const renderSwitch = (stage: AutoDiscoveryViews) => {
    switch (stage) {
      case AutoDiscoveryViews.DISCOVER:
        return (
          <BigidPaper classes={{ root: autoDiscoveryContainer }} data-aid="AutoDiscoveryProgressContainer">
            <AutoDiscoveryProgress updateWizardStage={setFlowStage} />
          </BigidPaper>
        );
      case AutoDiscoveryViews.NO_DATA:
        return (
          <BigidPaper classes={{ root: autoDiscoveryContainer }} data-aid="AutoDiscoveryProgressContainer">
            <AutoDiscoveryNoData />
          </BigidPaper>
        );
      case AutoDiscoveryViews.EXPLORER:
        return (
          <AutoDiscoveryExplorer
            profiles={profiles}
            updateProfile={updateProfile}
            updateWizardStage={setFlowStage}
            wizardSubtexts={wizardSubtexts}
            autoDiscoveryProfileDesc={AUTO_DISCOVERY_PROFILE_DESC}
            autoDiscoveryRunCount={profileRunCount}
            data-aid="AutoDiscoveryExplorerContainer"
          />
        );
      case AutoDiscoveryViews.SUMMARY:
        return (
          <BigidPaper data-aid="AutoDiscoveryWizardSummaryContainer">
            <AutoDiscoveryWizardSummary
              updateWizardStage={setFlowStage}
              profiles={profiles}
              updateProfile={updateProfile}
            />
          </BigidPaper>
        );
    }
  };

  useEffect(() => {
    const getLastRunCounter = async () => {
      const newRunCount = await getAppRunCount(AUTO_DISCOVERY_PROFILE_DESC);
      setProfileRunCount(newRunCount);
    };

    pageHeaderService.setTitle({ pageTitle: AUTO_DISCOVERY_PAGE_TITLE });
    getLastRunCounter();
  }, []);

  useEffect(() => {
    if (flowStage === AutoDiscoveryViews.SUMMARY) {
      const updatedSubTexts: WizardSubtexts = {};
      profiles.forEach((profile, key) => {
        updatedSubTexts[key] = profile.subtext;
      });
      setWizardSubtexts(updatedSubTexts);
    }
  }, [flowStage, profiles]);

  return <React.Fragment>{renderSwitch(flowStage)}</React.Fragment>;
};
