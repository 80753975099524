import React, { FC, Fragment } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import ListSubheader from '@mui/material/ListSubheader';
import { BigidColors } from '@bigid-ui/components';

const useStyles = makeStyles({
  listSubheader: {
    background: BigidColors.gray[900],
    padding: 8,
    display: 'flex',
    alignItems: 'center',
    height: 20,
  },
  line: {
    background: BigidColors.purple[500],
    height: 4,
    borderRadius: 4,
    width: '100%',
  },
});

export const SidebarHeader: FC = () => {
  const classes = useStyles({});

  return (
    <Fragment>
      <ListSubheader classes={{ root: classes.listSubheader }}>
        <div className={classes.line} />
      </ListSubheader>
    </Fragment>
  );
};
