import React, { FC } from 'react';
import { BigidFilter, PrimaryButton, BigidFieldFilter } from '@bigid-ui/components';
import { noop } from 'lodash';
import { $state } from '../../../services/angularServices';
import { setSelectedDataSourcesToStorage } from '../../Scans/ScanCreateWizard/hooks/useGetPreselectedDataSources';
import { CONFIG } from '../../../../config/common';
import { BigidDataSearchIcon, BigidWarningIcon } from '@bigid-ui/icons';
import { isPermitted } from '../../../services/userPermissionsService';
import { SCANS_PERMISSIONS } from '@bigid/permissions';
import { getApplicationPreference } from '../../../services/appPreferencesService';
import { openSystemDialog, SystemDialogContentProps } from '../../../services/systemDialogService';
import { queryService } from '../../../services/queryService';
import { getDataSources } from '../../../services/dataSourcesService';
import { notificationService } from '../../../services/notificationService';
import { openDialogForLargeScanSet } from './LargeAmountOfDataSourcesModal';

export interface DataSourceScanSelectedButtonProps {
  dataSourcesIds: string[];
  supportedScanTypesForSelectedDs: string[];
  isAllSelected: boolean;
  filterForDs: BigidFilter;
  totalCount: number;
  hasMoreThanThresholdSelected?: boolean;
}

export const NO_INTERSECTION_MESSAGE =
  'There are no scans that support the combination of data source(s) chosen. You may use the "scan type" filter to check which scans are supported.';

const NoIntersectionScansContent: FC<SystemDialogContentProps<unknown>> = () => <div>{NO_INTERSECTION_MESSAGE}</div>;

export const FILTER_ARCHIVED: BigidFieldFilter = {
  field: 'archived',
  value: [false, null],
  operator: 'in',
};

export const addArchiveFilter = (filters: BigidFilter): BigidFilter => {
  const isAlreadyAdded = filters.some(filter => filter.field === FILTER_ARCHIVED.field);
  return isAlreadyAdded ? filters : [...filters, FILTER_ARCHIVED];
};

export const openScanWarningDialog = () => {
  openSystemDialog({
    title: 'Scan Warning',
    withCloseButton: true,
    content: NoIntersectionScansContent,
    onClose: noop,
    maxWidth: 'sm',
    icon: BigidWarningIcon,
  });
};

export const DataSourceScanSelectedButton: FC<DataSourceScanSelectedButtonProps> = ({
  supportedScanTypesForSelectedDs,
  dataSourcesIds,
  isAllSelected,
  filterForDs,
  totalCount,
  hasMoreThanThresholdSelected,
}) => {
  const createScanButtonDisabled = Boolean(dataSourcesIds.length && !supportedScanTypesForSelectedDs?.length);
  const showButton = Boolean(
    getApplicationPreference('ENABLE_SCAN_TEMPLATES') &&
      isPermitted(SCANS_PERMISSIONS.CREATE_SCAN_PROFILES.name) &&
      dataSourcesIds.length,
  );

  const navigateToCreateScan = async () => {
    try {
      const dsFilterQuery = queryService.getGridConfigQuery({
        filter: isAllSelected
          ? addArchiveFilter(filterForDs)
          : [{ field: 'name', value: dataSourcesIds, operator: 'in' }],
        limit: totalCount,
        skip: 0,
      });

      const { dsConnections } = await getDataSources(dsFilterQuery);

      setSelectedDataSourcesToStorage(dsConnections.map(({ name, type, enabled }) => ({ name, type, enabled })));
    } catch (e) {
      notificationService.error('Failed to add data sources to scan, see logs for more information.', {
        shouldCloseOnTransition: false,
      });
      console.error(e);
    }
    $state.go(CONFIG.states.CREATE_SCAN, { from: CONFIG.states.DATA_SOURCE_CONNECTIONS });
  };

  return (
    showButton && (
      <PrimaryButton
        onClick={async () => {
          if (createScanButtonDisabled) {
            openScanWarningDialog();
          } else if (hasMoreThanThresholdSelected) {
            await openDialogForLargeScanSet({
              onContinueClick: navigateToCreateScan,
            });
          } else {
            navigateToCreateScan();
          }
        }}
        size="large"
        dataAid={'create-new-scheduled-scan-button-from-ds-list'}
        startIcon={<BigidDataSearchIcon />}
        text="Create New Scan"
      />
    )
  );
};
