import React, { useRef } from 'react';
import { noop, isEqual } from 'lodash';
import { openSystemDialog } from '../../../../services/systemDialogService';
import { DataSourceTemplateValueBase } from '../types';
import { BigidBody1, BigidLink } from '@bigid-ui/components';
import { BigidWarningIcon } from '@bigid-ui/icons';

export const useCustomNotification = (
  fields: DataSourceTemplateValueBase[] | any[],
  fieldName: string,
  parentFieldName?: string,
) => {
  const existingValues = useRef<Record<string, any>>({});
  const notificationContent = parentFieldName
    ? fields
        ?.find((el: DataSourceTemplateValueBase | any) => el?.name === parentFieldName)
        ?.misc?.objectFields?.find((el: DataSourceTemplateValueBase | any) => el?.name === fieldName)
        ?.notificationContent
    : fields?.find((el: DataSourceTemplateValueBase | any) => el?.name === fieldName)?.misc?.notificationContent;

  const NotificationContent = () => (
    <BigidBody1>
      {notificationContent?.message} See{' '}
      <BigidLink shouldOpenNewTab={true} text="BigID documentation" href={notificationContent?.url} rel="noreferrer" />{' '}
      for more information.
    </BigidBody1>
  );

  const additionalNotification = (values: Record<string, any>) => {
    if (isEqual(existingValues.current, {})) {
      existingValues.current = values;
    }
    const state = parentFieldName ? values[parentFieldName]?.[fieldName] : values[fieldName];
    const currentState = parentFieldName
      ? !existingValues.current[parentFieldName]?.[fieldName]
      : !existingValues.current[fieldName];
    switch (true) {
      case state && currentState:
        existingValues.current = values;

        openSystemDialog({
          title: notificationContent?.title,
          content: NotificationContent,
          onClose: noop,
          icon: BigidWarningIcon,
        });
        break;
    }
  };

  return { additionalNotification };
};
