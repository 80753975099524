import { module } from 'angular';
import { escape, find } from 'lodash';
import './records.component.scss';
import { getCopyOfCountries } from '../../../config/countries';
import template from './records.component.html';
import { INVENTORY_PERMISSIONS } from '@bigid/permissions';
import { isPermitted, getScopedPermissionByDataSource } from '../../../react/services/userPermissionsService';
import { getApplicationPreference } from '../../../react/services/appPreferencesService';
import { dateUtils } from '../../../react/services/angularServices';
const app = module('app');

app.component('recordsCom', {
  template,
  controllerAs: 'recordsModel',
  controller: function (
    $http,
    appSettings,
    recordsService,
    $scope,
    $rootScope,
    localStorageService,
    queryStringService,
    $uibModal,
    $window,
    activitiesAndRecordsService,
    $sce,
  ) {
    'ngInject';
    const recordsModel = this;
    recordsModel.filterValue = localStorageService.get('filterValue');
    recordsModel.userNameListChecked = localStorageService.get('userNameListChecked');
    recordsModel.identity_unique_id = null;
    recordsModel.countries = [];
    recordsModel.hasInvestigatePermission = isPermitted(INVENTORY_PERMISSIONS.INVESTIGATE_ATTRIBUTE.name);
    recordsModel.hasDrilldownDetails = isPermitted(INVENTORY_PERMISSIONS.DRILLDOWN.name);
    $scope.gridError = false;

    const getLocationToDisplay = systemLocation => {
      if (!systemLocation) {
        return '';
      }
      const country = find(recordsModel.countries, { name: systemLocation });
      return country?.displayName || systemLocation;
    };

    recordsModel.$onInit = () => {
      recordsService.clearRecordsCache();
      recordsModel.countries = getCopyOfCountries(getApplicationPreference('DISPLAY_CHINA_REGULATIONS'));
      $scope.recordsModel = {
        enableSorting: true,
        enablePaginationControls: false,
        columnDefs: [
          { name: 'Entity', field: 'identity_name', enableHiding: false },
          { name: 'Attribute', field: 'attribute', enableHiding: false },
          { name: 'Data Source', field: 'source', enableHiding: false },
          {
            name: 'Location',
            field: 'location',
            enableHiding: false,
          },
          ...getDrilldownDetailsColumn(),
          {
            name: 'Risk',
            field: 'risk',
            cellTemplate: '<div style="margin:4px;color:#f95759;font-weight: 500;;"> {{row.entity.risk}}</div>',
            enableHiding: false,
          },
          {
            name: 'Cached Data',
            field: 'piiInvestigationId',
            cellTemplate:
              '<img style="height:20px;width:20px;margin:10px 10px 0 20px" src="/images/Bigid_Active.svg" ng-show="row.entity.piiInvestigationId "  ng-click="grid.appScope.getDetails(row.entity)" />',
            enableHiding: false,
          },
        ],
      };
    };

    function getDrilldownDetailsColumn() {
      const detailsColumn = [
        {
          name: 'Details',
          field: '',
          cellTemplate:
            '<button id="{{row.entity._id}}-details" style="margin-top:9px;display:block;" ng-click="grid.appScope.getDetails(row.entity)" class="btn primary btnAction btnActionsEdit" >Details</button>',
          enableHiding: false,
        },
      ];
      return recordsModel.hasDrilldownDetails ? detailsColumn : [];
    }

    recordsModel.$onChanges = function () {
      recordsModel.userNameListChecked = localStorageService.get('userNameListChecked');
      recordsModel.isClearCache = false;
      recordsModel.filterValue = recordsModel.querystring;
      if (recordsModel.filteruser) {
        const queryString = queryStringService.getQueryStringFilter(null, [recordsModel.filteruser]);
        getRecordsData(queryString, recordsModel.isClearCache);
        return;
      } else if (recordsModel.urlfilter) {
        getRecordsData(recordsModel.urlfilter, recordsModel.isClearCache);
      }

      if (
        recordsModel.filter === 'all' &&
        (recordsModel.filterValue === null || recordsModel.filterValue == undefined) &&
        (recordsModel.userNameListChecked === null || recordsModel.userNameListChecked == undefined)
      ) {
        getRecordsData(null, recordsModel.isClearCache);
      } else {
        getRecordsData(recordsModel.filterValue, recordsModel.isClearCache);
        return;
      }
    };

    $scope.getImages = function (rowEntity) {
      if (rowEntity.piiInvestigationId) return '/images/Bigid_Active.svg';
      //other mappings
    };

    $scope.openProcessModal = function (rowEntity) {
      $rootScope.$emit('openPiiInvestigationProcessModal', rowEntity.piiInvestigationId);
    };

    $scope.getDetails = function (rowEntity) {
      const dataLink = '';
      recordsModel.identity_unique_id = rowEntity.identity_unique_id.toString();

      openPiiRecordDetailsModal(rowEntity, false, dataLink);
    };

    function openPiiRecordDetailsModal(rowEntity, isOpenFromModal, dataLink) {
      if (rowEntity.piiRecord) {
        for (const key in rowEntity.piiRecord.data_link) {
          if (rowEntity.piiRecord.data_link[key]) {
            dataLink +=
              '<div><h5>' +
              key +
              ': ' +
              "<span style='font-weight: 500: word-wrap: break-word'>" +
              escape(rowEntity.piiRecord.data_link[key]) +
              '</span></h5></div>';
          }
        }
      } else {
        for (const key in rowEntity.data_link) {
          const datalinkKey = rowEntity.data_link[key];
          if (null !== datalinkKey && undefined !== datalinkKey) {
            dataLink +=
              '<div><h5>' +
              key +
              ': ' +
              "<span style='font-weight: 500; word-wrap: break-word'>" +
              escape(rowEntity.data_link[key]) +
              '</span></h5></div>';
          }
        }
      }

      const request = { id: '' + rowEntity._id + '' };

      let addedColumnsByPiiInvestigationId = '';

      if (rowEntity.attribute) {
        addedColumnsByPiiInvestigationId +=
          '<div><h5> Attribute: ' + "<span style='font-weight: 500'>" + rowEntity.attribute + '</span></h5></div>';
      } else if (rowEntity.piiRecord.attribute) {
        addedColumnsByPiiInvestigationId +=
          '<div><h5> Attribute: ' +
          "<span style='font-weight: 500'>" +
          rowEntity.piiRecord.attribute +
          '</span></h5></div>';
      }

      if (rowEntity.attribute) {
        addedColumnsByPiiInvestigationId +=
          '<div><h5> Attribute Original Name: ' +
          "<span style='font-weight: 500'>" +
          rowEntity.attr_original_name +
          '</span></h5></div>';
      } else if (rowEntity.piiRecord.attribute) {
        addedColumnsByPiiInvestigationId +=
          '<div><h5> Attribute Original Name: ' +
          "<span style='font-weight: 500'>" +
          rowEntity.piiRecord.attr_original_name +
          '</span></h5></div>';
      }

      if (rowEntity.correlation_status) {
        addedColumnsByPiiInvestigationId +=
          '<div><h5> Correlation Status : ' +
          "<span style='font-weight: 500'>" +
          rowEntity.correlation_status +
          '</span></h5></div>';
      } else if (rowEntity.piiRecord.correlation_status) {
        addedColumnsByPiiInvestigationId +=
          '<div><h5> Correlation Status : ' +
          "<span style='font-weight: 500'>" +
          rowEntity.piiRecord.correlation_status +
          '</span></h5></div>';
      }

      const systemLocation = rowEntity.system_location || rowEntity.piiRecord.system_location;
      if (systemLocation) {
        addedColumnsByPiiInvestigationId += `<div><h5> Location: <span style='font-weight: 500'> ${getLocationToDisplay(
          systemLocation,
        )} </span></h5></div>`;
      }
      if (rowEntity.risk != null) {
        addedColumnsByPiiInvestigationId +=
          '<div><h5> Risk: ' + "<span style='font-weight: 500'>" + rowEntity.risk + '</span></h5></div>';
      } else if (rowEntity.piiRecord.risk) {
        addedColumnsByPiiInvestigationId +=
          '<div><h5> Risk: ' + "<span style='font-weight: 500'>" + rowEntity.piiRecord.risk + '</span></h5></div>';
      }

      if (recordsModel.hasInvestigatePermission && rowEntity.piiInvestigationId) {
        activitiesAndRecordsService.getPiiInvestigationsData(rowEntity.piiInvestigationId).then(function (result) {
          let addedColumnByPiiInvestigationIdIdentityName = '';
          if (result[0].piiRecord && result[0].piiRecord.identity_name) {
            addedColumnByPiiInvestigationIdIdentityName =
              '<div><h5> Identity Name: ' +
              "<span style='font-weight: 500'>" +
              result[0].piiRecord.identity_name +
              '</span></h5></div>';
          }

          openModal(
            rowEntity,
            dataLink,
            request,
            addedColumnsByPiiInvestigationId,
            addedColumnByPiiInvestigationIdIdentityName,
            result[0],
            isOpenFromModal,
          );
        });
      } else {
        openModal(rowEntity, dataLink, request, addedColumnsByPiiInvestigationId, '', null, isOpenFromModal);
      }
    }

    function openModal(
      rowEntity,
      dataLink,
      request,
      addedColumnsByPiiInvestigationId,
      addedColumnByPiiInvestigationIdIdentityName,
      resultFromPiiInvestigation,
      isOpenFromModal,
    ) {
      if (!resultFromPiiInvestigation) {
        resultFromPiiInvestigation = rowEntity;
      }

      const dsValue =
        resultFromPiiInvestigation.ds_value_exact !== ''
          ? "<textarea style='resize: none;' readonly rows='4' cols='50'>" +
            resultFromPiiInvestigation.ds_value_exact +
            '</textarea>'
          : "<span style='font-style: italic;color:#471C56;'>Fetching Data...</span>";
      const idsorValue =
        resultFromPiiInvestigation.idsor_value !== ''
          ? "<textarea style='resize: none;' readonly rows='4' cols='50'>" +
            resultFromPiiInvestigation.idsor_value +
            '</textarea>'
          : "<span style='font-style: italic;color:#471C56;'>Fetching Data...</span>";

      const piiRecordsTable = '';
      let piiInvestigationId = '';

      if (rowEntity.piiInvestigationId) {
        piiInvestigationId = rowEntity.piiInvestigationId;
      } else if (resultFromPiiInvestigation.piiRecord && resultFromPiiInvestigation.piiRecord.piiInvestigationId) {
        piiInvestigationId = resultFromPiiInvestigation.piiRecord.piiInvestigationId;
      }

      if (resultFromPiiInvestigation.created_at) {
        $rootScope.piiRecordsTableShow = true;
      } else {
        $rootScope.piiRecordsTableShow = false;
      }

      let detected_at = '';
      let scanner_type = '';
      let source = '';
      let id_source = '';
      let identity_unique_id = '';

      if (rowEntity.detected_at) {
        detected_at = rowEntity.detected_at;
      } else {
        detected_at = rowEntity.piiRecord.detected_at;
      }

      if (rowEntity.scanner_type) {
        scanner_type = rowEntity.scanner_type;
      } else {
        scanner_type = rowEntity.piiRecord.scanner_type;
      }

      if (rowEntity.source) {
        source = rowEntity.source;
      } else {
        source = rowEntity.piiRecord.source;
      }

      if (rowEntity.id_source) {
        id_source = rowEntity.id_source;
      } else if (rowEntity.piiRecord) {
        id_source = rowEntity.piiRecord.id_source;
      }

      if (rowEntity.identity_unique_id) {
        identity_unique_id = rowEntity.identity_unique_id;
      } else {
        identity_unique_id = rowEntity.piiRecord.identity_unique_id;
      }

      resultFromPiiInvestigation.createdAt = dateUtils.formatDate(resultFromPiiInvestigation.created_at);
      resultFromPiiInvestigation.updatedAt = dateUtils.formatDate(resultFromPiiInvestigation.updated_at);
      $window.sessionStorage.setItem('resultFromPiiInvestigation', JSON.stringify(resultFromPiiInvestigation));
      $scope.resultFromPiiInvestigation = resultFromPiiInvestigation;

      const template =
        "<div class='modal-header' style='border: 1px solid #323a45;border-radius: 4px 4px 0 0;" +
        "height: 66px;background-color: #262d37;'>" +
        "<span class='modal-title' style='color: white;font-size: 18px;margin: 5px 0 0 0;position:absolute;'>PII Record Details</span>" +
        '</div>' +
        "<div class='modal-body'>" +
        '<ul>' +
        '<div>' +
        '<h5>Detected At: ' +
        "<span style='font-weight: 500'>" +
        detected_at +
        '</span>' +
        '</h5>' +
        '</div>' +
        '<div>' +
        '<h5>Scanner Type: ' +
        "<span style='font-weight: 500'>" +
        scanner_type +
        '</span>' +
        '</h5>' +
        '</div>' +
        '<div>' +
        '<h5>Source: ' +
        "<span style='font-weight: 500'>" +
        source +
        '</span>' +
        '</h5>' +
        '</div>' +
        '<div>' +
        '<h5>IDSoR Source: ' +
        "<span style='font-weight: 500'>" +
        id_source +
        '</span>' +
        '</h5>' +
        '</div>' +
        '<div>' +
        '<h5>Identity Unique ID: ' +
        "<span style='font-weight: 500'>" +
        identity_unique_id +
        '</span>' +
        '</h5>' +
        '</div>' +
        addedColumnByPiiInvestigationIdIdentityName +
        addedColumnsByPiiInvestigationId +
        dataLink +
        '<div>' +
        "<button class='btn' id='investigate-button' ng-if='hasInvestigatePermission' ng-show='editable.piiRecordsTableShow!==true' ng-disabled='editable.piiRecordsTableShow' style='border: 1px solid #cdd1e4;background-color: white;' type='button' ng-click='investigate(\"" +
        request.id.toString() +
        '")\'>Investigate</button>' +
        '</div>' +
        "<div ng-show='editable.piiRecordsTableShow'>" +
        "<table style='width: 100%; table-layout: fixed' ng-show='editable.showInvestigateTable' style='margin-top: 20px;'>" +
        '<tr>' +
        "<td style='text-align: center;border-bottom: 1px solid #cdd1e4;'>" +
        'DS Value' +
        '</td>' +
        "<td style='text-align: center;border-bottom: 1px solid #cdd1e4;'>" +
        'IDSOR Value' +
        '</td>' +
        "<td style='text-align: center;border-bottom: 1px solid #cdd1e4;'>" +
        'Created At' +
        '</td>' +
        "<td style='text-align: center;border-bottom: 1px solid #cdd1e4;'>" +
        'Update At' +
        '</td>' +
        "<td style='text-align: center;border-bottom: 1px solid #cdd1e4;'>" +
        'Action' +
        '</td>' +
        '</tr>' +
        '<tr>' +
        "<td ng-if='!editable.showDS_Value' id='ds-value-results' style='text-align: center;padding: 5px 5px 5px 0;'" +
        " ng-class='{fetchingData: editable.showFethchingData}' class='investigateTableRow'>" +
        '{{editable.ds_value ? editable.ds_value : "Loading..."}}' +
        '</td>' +
        "<td ng-if='editable.showDS_Value' id='ds-value-results' style='text-align: center;padding: 5px 5px 5px 0;' class='investigateTableRow'>" +
        "{{editable.ds_value ? editable.ds_value : 'Loading...'}}" +
        '</td>' +
        "<td ng-if='!editable.showIDSOR_Value' id='idsor-value-results' style='text-align: center;padding: 5px;' ng-class='{fetchingData:" +
        " editable.showFethchingData}' class='investigateTableRow'>" +
        '{{editable.idsor_value ? editable.idsor_value : "Loading..."}}' +
        '</td>' +
        "<td ng-if='editable.showIDSOR_Value' id='idsor-value-results' style='text-align: center;padding: 5px 5px 5px 0;' class='investigateTableRow'>" +
        "{{editable.idsor_value ? editable.idsor_value : 'Loading...'}}" +
        '</td>' +
        "<td style='text-align: center;padding: 5px;' class='investigateTableRow'>" +
        '{{editable.createdAt}}' +
        '</td>' +
        "<td style='text-align: center;padding: 5px;' class='investigateTableRow'>" +
        '{{editable.updatedAt}}' +
        '</td>' +
        "<td style='text-align: center;padding: 5px;' class='investigateTableRow'>" +
        "<button style='margin-top: -9px;' type='button' ng-click='deleteItem(editable.pii_record_id)' class='btn primary btnAction btnActionsEdit' ng-disabled='!editable.showDS_Value || !editable.idsor_value' >Delete</button>" +
        '</td>' +
        '</tr>' +
        '</table>' +
        '</div>' +
        '</ul>' +
        '</div>' +
        "<div class='modal-footer'>" +
        "<button class='btn btn-primary' type='button' ng-click='refresh()' style='font-size: 12px;font-weight: 500;;height: 34px !important'>Refresh</button>" +
        "<button class='btn btn-primary' type='button' id='close-modal' ng-click='close()' style='float: left;width: 74px;height: 34px;background-color: #e7ebf2 !important;" +
        "border: 1px solid #cdd1e4 !important;border-radius: 1px;color: #4d595e !important;font-size: 12px;font-weight: 500;;'>Close</button>" +
        '</div>';

      $uibModal.open({
        animation: true,
        template: template,
        controller: 'ModalInstanceCtrl',
        size: 'lg',
        resolve: {
          items: function () {
            return $scope.resultFromPiiInvestigation;
          },
        },
      });
    }

    //for url loading
    if (recordsModel.urlfilter) {
      const queryString = queryStringService.getQueryStringFilter(
        recordsModel.filterValue,
        recordsModel.userNameListChecked,
      );
      getRecordsData(queryString);
    }

    function getRecordsData(queryString, isClearCache) {
      $scope.loading = true;
      $scope.gridError = false;
      recordsService.getRecordsData(queryString, isClearCache).then(
        function (result) {
          $scope.piiRecordsData = result.pii_records;
          $scope.recordsModel.data = $scope.piiRecordsData.map(item => ({
            ...item,
            location: getLocationToDisplay(item.system_location),
          }));
          $scope.loading = false;
        },
        function () {
          $scope.loading = false;
          $scope.gridError = true;
        },
      );
    }

    //Listen to header search
    const listenerCallFilter = $rootScope.$on('callFilter', function (event, filterValue, userNameListChecked) {
      $window.sessionStorage.removeItem('callFilter.urlfilter');
      callToGetRecordsData(filterValue, userNameListChecked);
      if (filterValue) {
        $window.sessionStorage.setItem('callFilter.urlfilter', filterValue);
      }
    });

    const listenerCleanFilter = $rootScope.$on('cleanFilter', function (event) {
      callToGetRecordsData(null);
    });

    //Listen to identities search
    const listenerOpenPiiRecordDetailsModal = $rootScope.$on(
      'openPiiRecordDetailsModal',
      function (event, rowEntity, isOpenFromModal) {
        openPiiRecordDetailsModal(rowEntity, isOpenFromModal, '');
      },
    );

    function callToGetRecordsData(filterValue, ids) {
      //tmp solution
      let queryString;
      if (recordsModel.filteruser) {
        queryString = queryStringService.getQueryStringFilter(null, [recordsModel.filteruser]);
      } else {
        queryString = queryStringService.getQueryStringFilter(filterValue, ids);
      }

      getRecordsData(queryString, true);
    }

    //Unregister listeners
    $scope.$on('$destroy', function () {
      listenerCallFilter();
      listenerCleanFilter();
      listenerOpenPiiRecordDetailsModal();
    });
  },
  bindings: {
    filter: '@',
    filteruser: '<',
    querystring: '<',
    searchtextbox: '<',
    urlfilter: '=',
    isClearCache: '<',
  },
});

app.controller('ModalInstanceCtrl', [
  '$scope',
  '$uibModalInstance',
  'activitiesAndRecordsService',
  '$rootScope',
  '$window',
  'items',
  '$interval',
  function ($scope, $uibModalInstance, activitiesAndRecordsService, $rootScope, $window, items, $interval) {
    $scope.editable = items;
    $scope.$applyAsync();
    getScopedPermissionByDataSource(INVENTORY_PERMISSIONS.INVESTIGATE_ATTRIBUTE.name, items.source)
      .then(result => {
        $scope.hasInvestigatePermission = result;
      })
      .finally(() => {
        $scope.$applyAsync();
      });
    let refreshInterval;

    if (items.created_at) {
      $scope.editable.piiRecordsTableShow = true;
      $scope.editable.showInvestigateTable = true;
    } else {
      $scope.editable.piiRecordsTableShow = false;
      $scope.editable.showInvestigateTable = false;
    }
    setDS_And_IDSOR_Values(items.ds_value_exact, items.idsor_value);

    $scope.$on('modal.closing', function (event, reason, closed) {
      const filterValue = $window.sessionStorage.getItem('callFilter.urlfilter');
      if (filterValue) {
        $rootScope.$emit('callGetPiiInvestigationsCount', filterValue);
        $rootScope.$emit('callFilter', filterValue);
      } else {
        $rootScope.$emit('callGetPiiInvestigationsCount', '');
        $rootScope.$emit('callFilter', '');
      }
    });

    $scope.investigate = function (identity_unique_id) {
      $scope.editable.piiRecordsTableShow = true;
      const REFRESH_PII_DATA_INTERVAL = 5000;
      const pii_record_id_object = { pii_record_id: identity_unique_id };
      activitiesAndRecordsService
        .createPiiInvestigation(pii_record_id_object)
        .then(function (result) {
          if (result.message == null) {
            $window.sessionStorage.removeItem('resultFromPiiInvestigation');
            $scope.editable.piiRecordsTableShow = true;
            $scope.editable.showInvestigateTable = true;
            if (refreshInterval) {
              $interval.cancel(refreshInterval);
            }
            refreshInterval = $interval(function () {
              getPiiInvestigationsData(result.piiInvestigationId);
            }, REFRESH_PII_DATA_INTERVAL);
          }
        })
        .catch(err => {
          $scope.editable.piiRecordsTableShow = false;
        });
    };

    $scope.close = function () {
      $uibModalInstance.close();
    };

    $scope.refresh = function () {
      const rowEntity = JSON.parse($window.sessionStorage.getItem('resultFromPiiInvestigation'));

      if (rowEntity && rowEntity.piiRecord && rowEntity.piiRecord.piiInvestigationId) {
        getPiiInvestigationsData(rowEntity.piiRecord.piiInvestigationId);
      }
    };

    function getPiiInvestigationsData(piiInvestigationId) {
      activitiesAndRecordsService.getPiiInvestigationsData(piiInvestigationId).then(function (result) {
        $window.sessionStorage.setItem('resultFromPiiInvestigation', JSON.stringify(result[0]));
        if (result[0].piiRecord) {
          setDS_And_IDSOR_Values(result[0].ds_value_exact, result[0].idsor_value, piiInvestigationId);
          $interval.cancel(refreshInterval);

          $scope.editable.createdAt = dateUtils.formatDate(result[0].created_at);
          $scope.editable.updatedAt = dateUtils.formatDate(result[0].updated_at);
          $scope.editable.pii_record_id = result[0].pii_record_id;
        }
      });
    }

    $scope.$on('$destroy', () => {
      $interval.cancel(refreshInterval);
    });

    function setDS_And_IDSOR_Values(ds_value, idsor_value) {
      if (ds_value === '') {
        getPiiInvestigationsData(piiInvestigationId);
        $scope.editable.ds_value = 'An error has occurred, please refresh';
        $scope.editable.showFethchingData = true;
        $scope.editable.showDS_Value = false;
      } else {
        $scope.editable.showDS_Value = true;
        $scope.editable.ds_value = ds_value;
        $scope.editable.showFethchingData = false;
      }

      if (idsor_value === '') {
        getPiiInvestigationsData(piiInvestigationId);
        $scope.editable.idsor_value = 'An error has occurred, please refresh';
        $scope.editable.showFethchingData = true;
        $scope.editable.showIDSOR_Value = false;
      } else {
        $scope.editable.showIDSOR_Value = true;
        $scope.editable.idsor_value = idsor_value;
        $scope.editable.showFethchingData = false;
      }
    }

    $scope.deleteItem = function (id) {
      activitiesAndRecordsService.deletePiiInvestigations(id).then(function (result) {
        $rootScope.piiRecordsTableShow = false;
        $scope.editable.showInvestigateTable = false;
        $scope.editable.piiRecordsTableShow = false;
      });
    };
  },
]);
