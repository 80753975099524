import React, { FC, useState, useEffect } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import {
  BigidHeading6,
  BigidBody1,
  BigidColorsV2,
  BigidLink,
  BigidLoader,
  BigidTooltip,
  BigidButtonIcon,
} from '@bigid-ui/components';
import { BigidCopyIcon } from '@bigid-ui/icons';
import {
  ClassifierVersionToUpdateConfig,
  getClassifierVersion,
  ClassifierVersionUpdateResponse,
} from '../../services/classifiersService';
import { notificationService } from '../../services/notificationService';
import classnames from 'classnames';

export interface ClassifierUpdatePreviewProps {
  classifierName?: string;
  versionUpdate?: ClassifierVersionUpdateResponse;
  dataAid?: 'string';
}

type ClassifierVersionComparisonPreview<T = ClassifierVersionToUpdateConfig> = Map<keyof T, T[keyof T][]>;

const useStyles = makeStyles({
  root: {
    width: '100%',
    height: '100%',
    position: 'relative',
  },
  busy: {
    opacity: '0.5',
    pointerEvents: 'none',
  },
  row: {
    display: 'flex',
    padding: '8px 24px',
  },
  column: {
    width: '50%',
    padding: '8px',
  },
  version: {
    color: BigidColorsV2.purple[400],
  },
  propertyName: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  propertyValue: {
    marginTop: '4px',
  },
  properyLink: {
    marginTop: '4px',
    textAlign: 'left',
    '& a': {
      fontSize: '0.75rem',
    },
  },
  even: {
    backgroundColor: BigidColorsV2.white,
  },
  odd: {
    backgroundColor: BigidColorsV2.gray[50],
  },
});

const copyToClipboard = (regex: string) => {
  navigator.clipboard.writeText(regex).catch(err => {
    console.error("Can't copy regex", err);
  });
};

const getVersionComparison = (
  currentClassifier: ClassifierVersionToUpdateConfig,
  newClassifier: ClassifierVersionToUpdateConfig,
): ClassifierVersionComparisonPreview => {
  const props = new Set([...Object.keys(currentClassifier), ...Object.keys(newClassifier)]);

  return Array.from(props).reduce((mergedProps, prop) => {
    const currValue = currentClassifier[prop as keyof ClassifierVersionToUpdateConfig];
    const newValue = newClassifier[prop as keyof ClassifierVersionToUpdateConfig];

    mergedProps.set(prop, [currValue, newValue]);

    return mergedProps;
  }, new Map());
};

const classifierPropFriendlyName = new Map<keyof ClassifierVersionToUpdateConfig, string>([
  ['version', 'Version'],
  ['regex', 'Classifier Regex'],
  ['term', 'Support Term Regex'],
  ['minLength', 'Min Length'],
  ['maxLength', 'Max Length'],
  ['proximityBefore', 'Number of characters before data'],
  ['proximityAfter', 'Number of characters after data'],
  ['description', 'Description'],
  ['type', 'Type'],
  ['validation', 'Checksum Validation'],
]);

const classifierVersionDiffPropsOrder: (keyof ClassifierVersionToUpdateConfig)[] = [
  'version',
  'description',
  'regex',
  'minLength',
  'maxLength',
  'validation',
  'term',
  'proximityBefore',
  'proximityAfter',
];

export const ClassifierUpdatePreview: FC<ClassifierUpdatePreviewProps> = ({
  classifierName,
  versionUpdate,
  dataAid = 'ClassifierUpdatePreview',
}) => {
  const classes = useStyles({});

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [versionComparison, setVersionComparison] = useState<ClassifierVersionComparisonPreview>(new Map());
  const [isRegexShown, setIsRegexShown] = useState<boolean>(false);
  const [isSupportTermShown, setIsSupportTermShown] = useState<boolean>(false);

  useEffect(() => {
    if (classifierName) {
      setIsLoading(true);
      getClassifierVersion(classifierName)
        .then(({ current: currentVersion, new: newVersion }) => {
          const comparison = getVersionComparison(currentVersion, newVersion);
          setVersionComparison(comparison);
        })
        .catch(({ message }) => {
          console.error(`An error has occurred: ${message}`);
          notificationService.error('An error has occurred');
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  }, [classifierName]);

  useEffect(() => {
    if (versionUpdate) {
      const { current: currentVersion, new: newVersion } = versionUpdate;
      const comparison = getVersionComparison(currentVersion, newVersion);
      setVersionComparison(comparison);
    }
  }, [versionUpdate]);

  const handleToggleIsRegexShown = (): void => {
    setIsRegexShown(isRegexShownPrev => !isRegexShownPrev);
  };

  const handleIsSupportTermShown = (): void => {
    setIsSupportTermShown(isSupportTermShownPrev => !isSupportTermShownPrev);
  };

  return (
    <div className={classnames(classes.root, isLoading && classes.busy)} data-aid={dataAid}>
      {isLoading && <BigidLoader />}
      {classifierVersionDiffPropsOrder
        .filter(prop => versionComparison.has(prop))
        .map((prop, index) => {
          const values = versionComparison.get(prop);
          const isVersion = prop === 'version';
          const isRegex = prop === 'regex';
          const isSupportTerm = prop === 'term';
          const isValidation = prop === 'validation';
          const isRowEven = index % 2 === 1;

          return (
            <div
              key={index}
              className={classnames(classes.row, isRowEven ? classes.even : classes.odd)}
              data-aid={`${dataAid}-${prop}`}
            >
              {values.map((value, index) => {
                const isCurrentVersion = index === 0;
                const versionPrefix = isCurrentVersion ? 'Current' : 'New';
                const regexPlaceholder = isCurrentVersion ? 'Current Version' : 'New Version';
                const valueDataAid = `${dataAid}-${prop}-${versionPrefix}`;

                return (
                  <div key={index} className={classes.column} data-aid={valueDataAid}>
                    <div className={classes.propertyName}>
                      {isVersion ? (
                        <BigidHeading6 className={classes.version} data-aid={`${valueDataAid}-header`}>
                          {versionPrefix} {classifierPropFriendlyName.get(prop) || prop} ({value})
                        </BigidHeading6>
                      ) : (
                        <BigidHeading6 data-aid={`${valueDataAid}-header`}>
                          {classifierPropFriendlyName.get(prop) || prop}
                        </BigidHeading6>
                      )}
                      {value && (isRegex || isSupportTerm) && (
                        <BigidTooltip title="Copy to Clipboard">
                          <div>
                            <BigidButtonIcon
                              icon={BigidCopyIcon}
                              dataAid={`${valueDataAid}-copy`}
                              onClick={() => copyToClipboard(value.toString())}
                            />
                          </div>
                        </BigidTooltip>
                      )}
                    </div>
                    {!isVersion && (
                      <div className={classes.propertyValue}>
                        {isRegex || isSupportTerm ? (
                          <>
                            {isRegex && value && (
                              <>
                                {isRegexShown ? (
                                  <BigidBody1 data-aid={`${valueDataAid}-value`}>{value}</BigidBody1>
                                ) : (
                                  <BigidBody1 data-aid={`${valueDataAid}-placeholder`}>{regexPlaceholder}</BigidBody1>
                                )}
                              </>
                            )}
                            {isSupportTerm && value && (
                              <>
                                {isSupportTermShown ? (
                                  <BigidBody1 data-aid={`${valueDataAid}-value`}>{value}</BigidBody1>
                                ) : (
                                  <BigidBody1 data-aid={`${valueDataAid}-placeholder`}>{regexPlaceholder}</BigidBody1>
                                )}
                              </>
                            )}
                          </>
                        ) : (
                          <BigidBody1 data-aid={`${valueDataAid}-value`}>
                            {isValidation && !value ? 'None' : value}
                          </BigidBody1>
                        )}
                      </div>
                    )}
                    {isCurrentVersion && (isRegex || isSupportTerm) && value && (
                      <div className={classes.properyLink}>
                        {isRegex && (
                          <BigidLink
                            dataAid={valueDataAid}
                            text={isRegexShown ? 'Hide' : 'Show'}
                            onClick={handleToggleIsRegexShown}
                            underline="always"
                          />
                        )}
                        {isSupportTerm && (
                          <BigidLink
                            dataAid={valueDataAid}
                            text={isSupportTermShown ? 'Hide' : 'Show'}
                            onClick={handleIsSupportTermShown}
                            underline="always"
                          />
                        )}
                      </div>
                    )}
                  </div>
                );
              })}
            </div>
          );
        })}
    </div>
  );
};
