import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { BigidGridColumn, BigidGridColumnTypes, BigidGridProps, FetchDataFunction } from '@bigid-ui/grid';
import { BigidLayout, BigidLayoutConfig, LayoutContentType } from '@bigid-ui/layout';
import { pageHeaderService } from '../../../common/services/pageHeaderService';
import { LabelingLabelDetails } from './LabelingLabelDetails';
import { getLabels, LabelConfiguration, labelingGetScanById, LatestScanState, postMipFetch } from './LabelingService';
import { EntityEvents, entityEventsEmitter, ToolbarAction } from '@bigid-ui/components';
import { BigidRefreshIcon } from '@bigid-ui/icons';
import { notificationService } from '../../services/notificationService';

export interface LabelConfigurationRecord extends LabelConfiguration {
  id: string;
  name: string;
}

const NAME_COLUMN_WIDTH = 250;

const columns: BigidGridColumn<LabelConfigurationRecord>[] = [
  {
    width: NAME_COLUMN_WIDTH,
    title: 'Name',
    name: 'name',
    isListColumn: true,
    type: BigidGridColumnTypes.TEXT,
    getCellValue: ({ name }) => name,
  },
];

const setImmediateInterval = (func: () => void, intervalPeriod: number) => {
  func();
  return window.setInterval(func, intervalPeriod);
};

const gridConfig: BigidGridProps<LabelConfigurationRecord> = {
  showSelectionColumn: false,
  columns,
};
export const LabelingLayout: FC = () => {
  const [, setLabelsNames] = useState<string[]>([]);
  let pollingInterval: number;

  useEffect(() => {
    pageHeaderService.setTitle({
      pageTitle: 'File Labeling',
      showBackButton: false,
    });
  }, []);

  const importLabels = useCallback(async () => {
    notificationService.info('import started');

    const scanId = await postMipFetch();

    window.clearInterval(pollingInterval);
    pollingInterval = setImmediateInterval(async () => {
      const latestScan = await labelingGetScanById(scanId);
      if (latestScan.state === LatestScanState.COMPLETED) {
        notificationService.success('labels imported successfully');
        entityEventsEmitter.emit(EntityEvents.RELOAD);
        window.clearInterval(pollingInterval);
      } else if (latestScan.state === LatestScanState.FAILED) {
        notificationService.error(`import failed: ${latestScan?.errorMessage}`);
        window.clearInterval(pollingInterval);
      }
    }, 5000);
  }, []);

  const fetchGridData: FetchDataFunction<LabelConfigurationRecord> = async () => {
    const data = await getLabels();
    const labels: LabelConfigurationRecord[] = data.labels.map(label => ({
      ...label,
      id: label.labelId,
    }));
    setLabelsNames(labels.map(({ name }) => name));
    return {
      totalCount: labels.length,
      data: labels || [],
    };
  };

  const toolbarActions = useMemo(() => {
    const _toolbarActions: ToolbarAction[] = [
      {
        label: 'Refresh',
        icon: BigidRefreshIcon,
        execute: () => {
          importLabels();
          return Promise.resolve({});
        },
        disable: ({}) => {
          return false;
        },
        show: () => {
          return true;
        },
      },
    ];
    return _toolbarActions;
  }, [importLabels]);

  const layoutConfig = useMemo<BigidLayoutConfig>(() => {
    const _layoutConfig: BigidLayoutConfig = {
      content: {
        entityName: 'Labels',
        noPadding: true,
        toolbarActions,
        contentTypes: [LayoutContentType.MASTER_DETAILS],
        defaultContentType: LayoutContentType.MASTER_DETAILS,
        viewConfig: {
          fetchGridData,
          gridConfig,
          masterDetailsConfig: {
            isPersistentListMode: true,
            setSelectedItemInFetch: false,
            isEditableHeader: false,
            isHeaderHidden: false,
            shouldReloadGridOnClose: false,
            tabsAndContent: {
              hideTabs: true,
              tabProps: {
                tabs: [
                  {
                    label: 'Label details',
                    data: {
                      component: LabelingLabelDetails,
                    },
                  },
                ],
                selectedIndex: 0,
              },
            },
          },
          selectedItemPropsMapping: {
            name: 'name',
            labelId: 'labelId',
            description: 'description',
            displayName: 'displayName',
          },
        },
      },
    };
    return _layoutConfig;
  }, [toolbarActions]);

  return <BigidLayout config={layoutConfig} />;
};
