import { module } from 'angular';
import { convertToAngular } from '../../../common/services/convertToAngular';
import {
  BigidImportDialog,
  BigidImportDialogProps,
  BigidBypassButton,
  BigidBypassButtonProps,
  BigidChip,
  BigidChipProps,
} from '@bigid-ui/components';

module('app').component(
  'bigidImportDialog',
  convertToAngular<BigidImportDialogProps>(BigidImportDialog, [
    'isOpen',
    'onClose',
    'onImportClicked',
    'title',
    'description',
    'accept',
  ]),
);

module('app').component(
  'bigidChip',
  convertToAngular<BigidChipProps>(BigidChip, [
    'label',
    'iconPlacement',
    'outline',
    'size',
    'variant',
    'width',
    'color',
    'bgColor',
    'outlineColor',
    'variant',
  ]),
);

module('app').component(
  'bypassButton',
  convertToAngular<BigidBypassButtonProps>(BigidBypassButton, [
    'bypassTo',
    'ariaLabel',
    'className',
    'text',
    'onClick',
  ]),
);
