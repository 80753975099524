import { toastNotificationService, BigidFormValues, StringOrBooleanMap, FormValue } from '@bigid-ui/components';
import { isEqual } from 'lodash';
import { getFixedT } from './translations';
import { FormValidationResult, UserEditableModel, SystemRole } from './types';
import { passwordRegex } from '../../services/passwordService';

const t = getFixedT('');

interface GetUserNameArgs {
  name: string;
  firstName: string;
  lastName: string;
}

export const getUserName = ({ firstName, lastName, name }: GetUserNameArgs): string => {
  if (!firstName && !lastName) {
    return name;
  }

  if (lastName && !firstName) {
    return lastName;
  }

  if (firstName && !lastName) {
    return firstName;
  }

  return `${firstName} ${lastName}`;
};

export const showGenericApiErrorNotification = (message?: string) => {
  toastNotificationService.error(message || t('notifications.apiCommonError'));
};

export const getRolesIdArray = (roles: SystemRole[]): string[] => roles.map(({ _id }) => _id);

const emailRegexp = new RegExp(
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
);

interface UserFormStateValidationResult {
  isOkToCleanUp: boolean;
  isOkToMarkAsDirty: boolean;
}

interface UserModelStateMutabilityResult {
  isUserModelChanged: boolean;
  isFormValuesChanged: boolean;
  isRolesChanged: boolean;
  isTokensChanged: boolean;
}

export const validateEmail =
  (emptyFieldText: string, notEmailText: string) =>
  (val: FormValue): FormValidationResult => {
    if (!val) {
      return emptyFieldText;
    }

    const validationResult = !emailRegexp.test(val) ? notEmailText : false;
    return validationResult;
  };

export const validateEmptyOrUnicodeString =
  (notNameText: string) =>
  (val: FormValue): FormValidationResult => {
    const isEmpty = !val;

    if (isEmpty) {
      return false;
    }

    const regularString = /\p{Letter}+/u;
    const containsSpecCharacter = /[\!\@\#\$\%\^\&\*\)\(\+\=\.\<\>\{\}\[\]\:\;\'\"\|\~\`\_\-]/g;

    const failedValidation = !regularString.test(val) || containsSpecCharacter.test(val);

    const result = failedValidation ? notNameText : false;

    return result;
  };

export const validatePasswordRepeat =
  (shouldMatchPasswordsText: string) =>
  (_: FormValue, formValues: BigidFormValues): FormValidationResult => {
    const isPasswordsMatching = formValues.password == formValues.passwordRepeat;

    if (!isPasswordsMatching) {
      return shouldMatchPasswordsText;
    }

    return false;
  };

export const validatePrimaryPassword =
  (weakSecurityText: string, strongPasswordCheck: boolean) =>
  (val: FormValue): FormValidationResult => {
    if (!val) {
      return false;
    }

    if (strongPasswordCheck && !passwordRegex.test(val)) {
      return weakSecurityText;
    }

    return false;
  };

export const getFormState = (
  errors: StringOrBooleanMap,
  hasRoles: boolean,
  isFormDirty: boolean,
): UserFormStateValidationResult => {
  const formOk = !errors.password && !errors.firstName && !errors.lastnMame && !errors.name && !errors.passwordRepeat;

  const isOkToCleanUp = formOk && hasRoles && isFormDirty;
  const isOkToMarkAsDirty = (!formOk || !hasRoles) && !isFormDirty;

  return { isOkToCleanUp, isOkToMarkAsDirty };
};

export const getUserModelChangesStatus = (
  originalValues: UserEditableModel,
  newValues: UserEditableModel,
): UserModelStateMutabilityResult => {
  const isFormValuesChanged = !isEqual(originalValues.formValues, newValues.formValues);
  const isRolesChanged = !isEqual(getRolesIdArray(originalValues.roles), getRolesIdArray(newValues.roles));
  const isTokensChanged = !isEqual(originalValues.tokens, newValues.tokens);

  const isUserModelChanged = isFormValuesChanged || isRolesChanged || isTokensChanged;

  return { isUserModelChanged, isFormValuesChanged, isRolesChanged, isTokensChanged };
};

export const skipValidation = (): boolean => false;
