import React, { FC, useEffect, useState } from 'react';
import { Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { BigidLineChart, BigidChartLegendPosition, BigidLoader, BigidLineChartProps } from '@bigid-ui/components';
import { notificationService } from '../../../../services/notificationService';

const useStyles = makeStyles({
  wrapper: ({ hidden }: Partial<DashboardLineChartContainerProps>) => ({
    visibility: hidden ? 'hidden' : 'unset',
    position: hidden ? 'absolute' : 'relative',
    minWidth: '540px',
    width: '100%',
    minHeight: '330px',
    flex: '1 0 auto',
  }),
  title: ({ emptyData }: Partial<DashboardLineChartContainerProps> & { emptyData: boolean }) => ({
    visibility: emptyData ? 'hidden' : 'unset',
    fontSize: '13px',
    color: '#3E3E3E',
    borderBottom: '1px solid rgba(214, 214, 214, 0.63)',
    padding: '0 0 12px 0',
    marginBottom: '8px',
    marginTop: emptyData ? 0 : '24px',
  }),
  chartWrapper: {
    width: '560px',
    height: '270px',
  },
});

interface DashboardChartDataInterface {
  value: number;
  date: Date;
}

export interface DashboardChartFetchDataResult {
  data: DashboardChartDataInterface;
  colorsMap?: Record<string, string>;
  titlesMap?: Record<string, string>;
  series?: string[];
  maxValue?: number;
}

export interface DashboardLineChartContainerProps {
  fetchData: () => Promise<DashboardChartFetchDataResult>;
  hidden: boolean;
  titlesMap?: Record<string, string>;
  colorsMap?: Record<string, string>;
  series?: string[];
  hideValuesLabels?: boolean;
  key: string;
  id: string;
  maxValue?: number;
}

const DASHBOARD_LINE_CHART_DATES_INTERVAL: BigidLineChartProps['dateInterval'] = [
  { timeUnit: 'hour', count: 12 },
  { timeUnit: 'day', count: 7 },
  { timeUnit: 'week', count: 1 },
  { timeUnit: 'month', count: 1 },
  { timeUnit: 'month', count: 2 },
  { timeUnit: 'month', count: 3 },
  { timeUnit: 'month', count: 6 },
  { timeUnit: 'year', count: 1 },
];
const configCache: Record<string, any> = {};
const dataCache: Record<string, any> = {};

const updateConfigCache = (
  { colorsMap = {}, titlesMap = {}, series = [], maxValue }: Partial<DashboardLineChartContainerProps>,
  id: string,
) => {
  configCache[id] = configCache[id] ?? {};
  configCache[id].colorsMap = { ...(configCache.colorsMap ?? {}), ...colorsMap };
  configCache[id].titlesMap = { ...(configCache.titlesMap ?? {}), ...titlesMap };
  configCache[id].series = [...(configCache.series ?? []), ...series];
  configCache[id].maxValue = maxValue;

  return configCache[id];
};

export const DashboardLineChartContainer: FC<DashboardLineChartContainerProps> = React.memo(
  ({ hideValuesLabels = false, fetchData, hidden, id, ...restProps }) => {
    const [isLoading, setIsLoading] = useState(!dataCache[id]);
    const [data, setData] = useState<any>(dataCache[id]);
    const [{ colorsMap, titlesMap, series, maxValue }, setChartConfig] = useState(updateConfigCache(restProps, id));
    const emptyData = !isLoading && data?.length === 0;
    const { title, wrapper, chartWrapper } = useStyles({ hidden, emptyData });

    useEffect(() => {
      fetchData()
        .then(({ data, colorsMap, titlesMap, series, maxValue }) => {
          setData(data);
          setChartConfig((current: any) => {
            const result = {
              colorsMap: colorsMap ? { ...current.colorsMap, ...colorsMap } : current.colorsMap,
              titlesMap: titlesMap ? { ...current.titlesMap, ...titlesMap } : current.titlesMap,
              series: series ? [...current.series, ...series] : current.series,
              maxValue: maxValue ? maxValue : current.maxValue,
            };

            return result;
          });
        })
        .finally(() => {
          setIsLoading(false);
        })
        .catch(err => {
          notificationService.error(err.message);
        });
    }, [fetchData]);

    return (
      <div className={wrapper} data-aid={`DashboardLineChartContainer-${id}`} key={id}>
        <Typography variant={'h6'} className={title}>
          {titlesMap?.header ?? ''}
        </Typography>
        <div className={chartWrapper}>
          {isLoading || data === undefined ? (
            <BigidLoader />
          ) : (
            <BigidLineChart
              titles={titlesMap}
              colors={colorsMap}
              series={series}
              data={data}
              legend={BigidChartLegendPosition.TOP}
              hideValuesLabels={hideValuesLabels}
              tooltip
              minValue={0}
              maxValue={maxValue}
              dateInterval={DASHBOARD_LINE_CHART_DATES_INTERVAL}
              notShowBulletIfSameValue={false}
              skipEmptyPeriods={false}
            />
          )}
        </div>
      </div>
    );
  },
);
