import React, { FC } from 'react';
import { styled } from '@mui/material';
import { BigidBody1 } from '@bigid-ui/components';
import { generateDataAid } from '@bigid-ui/utils';
import { useLocalTranslation } from '../translations';

const Root = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 4px;
  width: 300px;
`;

const Body = styled('ul')`
  display: flex;
  flex-direction: column;
  margin: 0;
  padding-left: 15px;
`;

interface CurationGuidedTourMoreActionsProps {
  dataAid: string;
}

export const CurationGuidedTourMoreActions: FC<CurationGuidedTourMoreActionsProps> = ({ dataAid }) => {
  const { t } = useLocalTranslation('CurationGuidedTour.Attributes');

  return (
    <Root data-aid={dataAid}>
      <Body>
        <li>
          <BigidBody1 data-aid={generateDataAid(dataAid, ['removeFromCatalog'])}>
            {t('moreActionsRemoveFromCatalog')}
          </BigidBody1>
        </li>
        <li>
          <BigidBody1 data-aid={generateDataAid(dataAid, ['assignFriendlyName'])}>
            {t('moreActionsAssignFriendlyName')}
          </BigidBody1>
        </li>
        <li>
          <BigidBody1 data-aid={generateDataAid(dataAid, ['assigncategory'])}>
            {t('moreActionsAssigncategory')}
          </BigidBody1>
        </li>
        <li>
          <BigidBody1 data-aid={generateDataAid(dataAid, ['classifiersPage'])}>
            {t('moreActionsClassifiersPage')}
          </BigidBody1>
        </li>
      </Body>
    </Root>
  );
};
