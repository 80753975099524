import React, { FC, ReactNode } from 'react';
import { BigidBody1, BigidHeading6 } from '@bigid-ui/components';
import makeStyles from '@mui/styles/makeStyles';
import { BigidRiskAlertIllustration } from '@bigid-ui/icons';

interface EmptyTemplateProps {
  title?: string;
  content: ReactNode;
}

const useStyles = makeStyles({
  emptyTemplateContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    padding: '18px 0',
  },
  emptyTemplateBox: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '500px',
  },
  emptyTemplateContainerDescription: {
    textAlign: 'center',
  },
});

export const EmptyTemplate: FC<EmptyTemplateProps> = ({ title, content }) => {
  const classes = useStyles();
  return (
    <div className={classes.emptyTemplateContainer}>
      <div className={classes.emptyTemplateBox}>
        <BigidRiskAlertIllustration />
        <BigidHeading6>{title}</BigidHeading6>
        <BigidBody1 className={classes.emptyTemplateContainerDescription}>{content}</BigidBody1>
      </div>
    </div>
  );
};
