import React, { FC } from 'react';
import { SearchResult } from '../../../types';
import { styled } from '@mui/material';
import { BigidBody1 } from '@bigid-ui/components';
import { processHighlight } from '../utils';
import { generateDataAid } from '@bigid-ui/utils';

export const ListItemContainer = styled('li')`
  display: flex;
  list-style-type: none;
  cursor: pointer;
`;

type GenericListItemProps = {
  result: SearchResult;
  handleOnClick: () => void;
  dataAid?: string;
};

const StyledName = styled(BigidBody1)`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const GenericListItem: FC<GenericListItemProps> = ({ result, handleOnClick, dataAid = 'GenericListItem' }) => {
  const { fields, highlightedFields } = result;
  const nameField = highlightedFields.find(field => field.name === 'name');
  const computedName = nameField ? processHighlight(nameField) : fields?.name;

  return (
    <ListItemContainer onClick={handleOnClick} data-aid={generateDataAid(dataAid, ['container'])}>
      <StyledName>{computedName}</StyledName>
    </ListItemContainer>
  );
};
