import React, { useState, useCallback } from 'react';
import { BigidEditIcon, BigidLampIcon } from '@bigid-ui/icons';
import { styled } from '@mui/material';
import { SarFieldsSettingsV2 as SarFieldsSettings } from '../ProfileSettingsTypes';
import { COLUMN_NAME_TO_WARNING_MAPPER, ColumnName, getEntityByColumnName } from '../SarFields/EditFieldsCellDialog';
import { DsarEvents, dsarEventEmitter } from '../dsarEvents';
import { GridChip } from './GridChipDsar';

const CellWrapper = styled('div')`
  display: flex;
  gap: 4px;
  cursor: pointer;
  justify-content: space-between;
  align-items: center;
  height: 100%;
`;

const ChipWrapper = styled('span')`
  display: flex;
  gap: 4px;
  min-width: calc(100% - 24px);
`;

export const GridEditableChipAreaDsar = ({ columnName, row }: { columnName: ColumnName; row: SarFieldsSettings }) => {
  const [mouseIsOver, setMouseOver] = useState(false);
  const [, customValue, , defaultValue] = getEntityByColumnName(columnName, row);
  const value = customValue || defaultValue;
  const showIcon = row[COLUMN_NAME_TO_WARNING_MAPPER[columnName] as keyof SarFieldsSettings];
  const icon = showIcon ? [{ icon: <BigidLampIcon width={18} height={18} viewBox="0 0 18 24" />, label: '' }] : [];
  const valuesToRender = value ? [...icon, { label: value as string }] : [...icon];

  const onChipsCellClick = useCallback(() => {
    dsarEventEmitter.emit(DsarEvents.OPEN_EDIT_CELL_DIALOG, row, columnName);
  }, [row, columnName]);

  return (
    <CellWrapper
      onMouseEnter={() => setMouseOver(true)}
      onMouseLeave={() => setMouseOver(false)}
      onClick={onChipsCellClick}
    >
      {valuesToRender.length ? (
        <ChipWrapper>
          {valuesToRender.map((props, i) => (
            <GridChip key={i} {...props} />
          ))}
        </ChipWrapper>
      ) : (
        mouseIsOver && `Add ${columnName.toLowerCase()}`
      )}
      {mouseIsOver && <BigidEditIcon size="small" />}
    </CellWrapper>
  );
};
