import React, { FC } from 'react';
import { BigidHeading1, BigidBody1 } from '@bigid-ui/components';
import styled from '@emotion/styled';
import { useLocalTranslation } from '../translations';

const GettingStartedHeaderStyled = styled.div({
  width: '872px',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  gap: '8px',
  marginBottom: '16px',
});

export const GettingStartedHeader: FC = () => {
  const { t } = useLocalTranslation('main');
  return (
    <GettingStartedHeaderStyled>
      <BigidHeading1 data-aid={'GettingStartedHeader-title'}>{t('title')}</BigidHeading1>
      <BigidBody1 data-aid={'GettingStartedHeader-description'} fontSize={'15px'}>
        {t('subTitle')}
      </BigidBody1>
    </GettingStartedHeaderStyled>
  );
};
