import { queryService } from '../services/queryService';
import { BigidGridQueryComponents } from '@bigid-ui/grid';
import { BigidFieldFilter, BigidSelectOption, BigidFilterOptionType } from '@bigid-ui/components';
import { sessionStorageService } from '../../common/services/sessionStorageService';
import { systemUsersService } from '../services/angularServices';

export const DEFAULT_MAX_USERS_IN_REQUEST = 400;

interface GetUsersQueryProps {
  maxUsers?: number;
  searchString?: string;
  filter?: BigidGridQueryComponents['filter'];
}

export type User = {
  firstName: string;
  name: string;
  lastName?: string;
  email?: string;
  id: string;
  origin?: string;
};

export const convertUsersToOptions = (users: User[], saveUserDetails?: boolean) =>
  users.map<BigidSelectOption>(({ firstName, name, lastName, id }) => {
    const wrappedFirstName = firstName ?? '';
    const wrappedName = name ?? '';
    const wrappedLastName = lastName ?? '';
    const value = saveUserDetails ? { firstName: wrappedFirstName, lastName, id, username: wrappedName } : wrappedName;

    if (!firstName && !lastName) {
      return { label: wrappedName, value };
    }

    return {
      label: `${wrappedFirstName} ${wrappedLastName}`,
      subLabel: wrappedName,
      value,
    };
  });

export const convertUsersToFilterOptions = (users: User[], saveUserDetails?: boolean) =>
  convertUsersToOptions(users, saveUserDetails).map<BigidFilterOptionType>(option => ({
    ...option,
    isSelected: false,
  }));

export const getUserFilterByString = (searchString: string): BigidFieldFilter[] => {
  const splitSearchString = searchString.split(' ').join('|');
  return [
    {
      field: 'name',
      operator: 'textSearch',
      value: splitSearchString,
    },
    {
      field: 'email',
      operator: 'textSearch',
      value: splitSearchString,
    },
    {
      field: 'lastName',
      operator: 'textSearch',
      value: splitSearchString,
    },
    {
      field: 'firstName',
      operator: 'textSearch',
      value: splitSearchString,
    },
  ];
};

export const getUsersQuery = ({
  maxUsers = DEFAULT_MAX_USERS_IN_REQUEST,
  searchString,
  filter,
}: GetUsersQueryProps): string => {
  const pagginationObject = { skip: 0, limit: maxUsers };
  const filterObject = filter
    ? { filter }
    : {
        filter: searchString ? getUserFilterByString(searchString) : [],
      };

  return queryService.getGridConfigQuery({
    ...pagginationObject,
    ...filterObject,
  });
};

export const getUserOptionLabel = ({ email, firstName, lastName, name }: User) => {
  const firsLastName = (firstName || lastName) && `${firstName}${firstName && lastName ? ' ' : ''}${lastName || ''}`;
  const labelEnd = email || name;
  const labelStart = firsLastName ? firsLastName : name !== labelEnd ? name : '';

  return `${labelStart || labelEnd}${labelEnd && labelStart ? ` (${labelEnd})` : ''}`;
};

export const getOptionsFromSystemUsers = (users: User[]) =>
  users.map(({ id, email, firstName, lastName, name, origin }: User) => ({
    id,
    value: id || name,
    label: getUserOptionLabel({ id, email, firstName, lastName, name, origin }),
  }));

export const getCurrentUser = async (): Promise<{ firstName: string; lastName: string; name: string; id: string }> => {
  try {
    const userName = sessionStorageService.get('userName') as string;
    const {
      data: { system_user },
    } = await systemUsersService.getSystemUserById(userName);
    const { firstName, lastName, name, id } = system_user;
    return { firstName, lastName, name, id };
  } catch (e) {
    console.error('Failed fetching current user');
  }
};
