import React, { FC, ReactText } from 'react';
import { useTheme, makeStyles } from '@mui/styles';
import { BigidChip, BigidTooltip } from '@bigid-ui/components';

export interface Widget {
  label: ReactText;
  description?: string;
  color?: string;
  tooltip?: ReactText;
}

const useStyles = makeStyles(theme => ({
  wrapper: {
    marginLeft: 16,
  },
  content: {
    display: 'flex',
    alignItems: 'center',
  },
  label: ({ color }: { color: string }) => ({
    fontSize: '1.5rem',
    marginRight: 10,
    color: color || theme.vars.palette.bigid.gray900,
  }),
  description: {
    fontSize: '0.8125rem',
    textAlign: 'center',
  },
}));

export const BigidTitleWidget: FC<Widget> = ({ label, color, description, tooltip }) => {
  const classes = useStyles({ color });
  const theme = useTheme();

  const disableTooltip = !tooltip;

  return (
    <BigidTooltip title={tooltip} isDisabled={disableTooltip}>
      <div className={classes.wrapper}>
        <BigidChip
          label={
            <div className={classes.content}>
              <div className={classes.label}>{label}</div>
              <div className={classes.description}>{description}</div>
            </div>
          }
          bgColor={theme.vars.palette.bigid.white}
          shadow
        />
      </div>
    </BigidTooltip>
  );
};
