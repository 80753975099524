import './mentionsTextarea.component.scss';
import template from './mentionsTextarea.component.html';
import { getUsersQuery } from '../../react/utilities/systemUsersUtils';

import { module } from 'angular';
const app = module('app');

app.component('mentionsTextarea', {
  template,
  controller: [
    '$scope',
    '$rootScope',
    'mentionsTextareaService',
    'notificationService',
    'systemUsersService',
    '$element',
    function ($scope, $rootScope, mentionsTextareaService, notificationService, systemUsersService, $element) {
      const users = 'users',
        purposes = 'purposes',
        attributes = 'attributes';

      let ctrl = this;

      ctrl.isPopoverToggled = false;
      ctrl.triggerValue = 'none';
      ctrl.areaClassCalculated = '';
      ctrl.focusIndex = 0;
      ctrl.userQuery = '';
      ctrl.filteredItems = [];
      ctrl.mentions = [];
      ctrl.items = [];
      ctrl.listDomObject = {};
      ctrl.listItemHeight = 0;
      ctrl.scrollTop = 0;
      ctrl.areaValue = '';

      ctrl.$onInit = () => {
        if (angular.isDefined(ctrl.source)) {
          switch (ctrl.source) {
            case users:
              getUsers();
              break;
            case purposes:
              getPurposes();
              break;
            case attributes:
              getAttributes();
              break;
          }
        }

        if (!angular.isDefined(ctrl.mode)) {
          ctrl.mode = 'textarea';
        }

        ctrl.popoverPosition = angular.isDefined(ctrl.popoverPosition) ? ctrl.popoverPosition : 'bottom';

        setAreaClass();
      };

      ctrl.$onChanges = () => {
        ctrl.popoverPosition = angular.isDefined(ctrl.popoverPosition) ? ctrl.popoverPosition : 'bottom';
        if (ctrl.refreshData === 'refreshPurposes') {
          getPurposes();
        }

        setAreaClass();
      };

      let scrollList = direction => {
        let listHeight = ctrl.listHeight || 200;

        switch (direction) {
          case 'up':
            ctrl.scrollTop = ctrl.scrollTop - ctrl.listItemHeight;
            ctrl.listDomObject.scrollTop = ctrl.scrollTop;
            break;
          case 'down':
            ctrl.scrollTop = ctrl.scrollTop + ctrl.listItemHeight;
            if (ctrl.scrollTop + ctrl.listItemHeight >= listHeight) ctrl.listDomObject.scrollTop = ctrl.scrollTop;
            break;
        }
      };

      let getUsers = searchString => {
        const query = getUsersQuery({
          maxUsers: 20,
          searchString,
        });

        systemUsersService.getAllSystemUsersByQuery(query).then(
          result => {
            ctrl.items = result.data?.users;
          },
          () => {
            notificationService.error('An error has occurred!');
          },
        );
      };

      let getPurposes = () => {
        mentionsTextareaService.getPurposes().then(
          result => {
            ctrl.items = result.data;
          },
          () => {
            notificationService.error('An error has occurred!');
          },
        );
      };

      let getAttributes = () => {
        mentionsTextareaService.getAttributes().then(
          result => {
            ctrl.items = result.data.attribute_risks;
          },
          () => {
            notificationService.error('An error has occurred!');
          },
        );
      };

      let eraseTriggerChar = () => {
        let areaValue = angular.copy(ctrl.areaValue) || '';
        for (let i = areaValue.length; i--; ) {
          if (areaValue[i] == ctrl.triggerChar) {
            areaValue = areaValue.substring(0, i);
            return areaValue;
          }
        }
        return areaValue;
      };

      let insertItem = item => {
        setPopoverToggled(false);

        let areaValue = ctrl.triggerChar ? eraseTriggerChar() : angular.copy(ctrl.areaValue);

        switch (ctrl.source) {
          case users:
            ctrl.mentions.push(item.name);
            ctrl.mentions = ctrl.mentions.filter((value, index, self) => self.indexOf(value) === index);

            ctrl.areaValue =
              (areaValue && !ctrl.togglerEnabled ? areaValue : '') +
              (ctrl.insertAsMention ? '[~user: ' + item.name + ']' : item.name);
            ctrl.onChanged({ value: ctrl.areaValue, mentions: ctrl.mentions });
            break;
          case purposes:
            item = typeof item === 'object' ? item.name : item;
            ctrl.areaValue = ctrl.replace ? item : (areaValue ? areaValue : '') + item;
            ctrl.onChanged({ value: ctrl.areaValue });
            break;
          case attributes:
            ctrl.areaValue = ctrl.replace ? item.short_name : (areaValue ? areaValue : '') + item.short_name;
            ctrl.onChanged({ value: ctrl.areaValue });
            break;
        }
      };

      let setAreaClass = () => {
        let _className = ctrl.areaClass;
        if (angular.isDefined(ctrl.areaDynamicClass)) {
          for (let className in ctrl.areaDynamicClass) {
            if (ctrl.areaDynamicClass[className]) {
              _className = _className + ' ' + className;
            }
          }
        }
        ctrl.areaClassCalculated = _className;
      };

      let filterItems = () => {
        ctrl.filteredItems = ctrl.items.filter(
          ctrl['filter' + ctrl.source.charAt(0).toUpperCase() + ctrl.source.slice(1)],
        );

        if (ctrl.filteredItems.length > 0) setPopoverToggled(true);
      };

      let setQueryString = value => {
        let parsedString = value.split(ctrl.triggerChar);
        ctrl.userQuery = parsedString[parsedString.length - 1];
        if (ctrl.source === 'users') {
          getUsers(ctrl.userQuery);
        }
      };

      let setPopoverToggled = value => {
        ctrl.isPopoverToggled = value;
        ctrl.focusIndex = 0;
        ctrl.scrollTop = 0;

        setTimeout(() => {
          $element[0].querySelector(ctrl.mode == 'textarea' ? '#textArea' : '#Input').focus();

          if (value) {
            ctrl.listDomObject = $element[0].querySelector('#' + ctrl.source + 'MentionsList');
            if (ctrl.listDomObject) {
              let listItemObjects = ctrl.listDomObject.querySelectorAll('.item');
              if (listItemObjects[0]) ctrl.listItemHeight = listItemObjects[0]['offsetHeight'];
            }
          }
        }, 300);
      };

      let keyPressHandler = event => {
        if ((ctrl.isPopoverToggled && event.which == 38) || event.which == 40 || event.which == 13) {
          event.preventDefault();
        }

        switch (event.which) {
          case 13:
            if (ctrl.isPopoverToggled) {
              insertItem(ctrl.filteredItems[ctrl.focusIndex]);
            } else {
              ctrl.onAreaSubmit();
            }
            break;
          case 16:
            // ignore Shift key
            break;
          case 17:
            // ignore Ctrl key
            break;
          case 18:
            // ignore Alt key
            break;
          case 27:
            // ESC click
            ctrl.isPopoverToggled = false;
            break;
          case 32:
            // space click
            if (ctrl.source == 'users') ctrl.isPopoverToggled = false;
            break;
          case 37:
          case 39:
            if (ctrl.isPopoverToggled) {
              ctrl.isPopoverToggled = false;
            }
            break;
          case 38:
            if (ctrl.isPopoverToggled) {
              if (ctrl.focusIndex != 0) {
                ctrl.focusIndex--;
                scrollList('up');
              }
            }
            break;
          case 40:
            if (ctrl.isPopoverToggled) {
              let length = ctrl.filteredItems.length;
              if (ctrl.focusIndex < length - 1) {
                ctrl.focusIndex++;
                scrollList('down');
              }
            }
            break;
        }
      };

      ctrl.onItemClick = item => {
        insertItem(item);
      };

      ctrl.onAreaClick = event => {
        ctrl.onClick({ event: event });
      };

      ctrl.onAreaSubmit = () => {
        ctrl.onSubmit({ value: ctrl.areaValue, mentions: ctrl.mentions });
      };

      ctrl.onAreaFocused = event => {
        ctrl.onFocus({ event: event });
      };

      ctrl.onAreaBlurred = event => {
        if (!ctrl.isPopoverToggled) ctrl.onBlur({ event: event });
      };

      ctrl.onAreaKeyup = event => {
        ctrl.onKeyup({ event: event });
      };

      ctrl.onAreaChanged = value => {
        ctrl.onChanged(ctrl.source == users ? { value: value, mentions: ctrl.mentions } : { value: value });

        if (value) {
          if (ctrl.source == users) {
            if (ctrl.triggerChar) {
              if (value.slice(-1) === ctrl.triggerChar) {
                if (!ctrl.isPopoverToggled) {
                  ctrl.userQuery = '';
                  setPopoverToggled(true);
                }
              } else {
                if (ctrl.isPopoverToggled) setQueryString(value);
              }
            } else {
              ctrl.userQuery = value;
            }
          } else {
            ctrl.userQuery = value;
            filterItems();
          }
        } else {
          setPopoverToggled(false);
          ctrl.mentions = [];
        }
      };

      ctrl.onPopoverKeyPress = event => {
        keyPressHandler(event);
      };

      ctrl.onKeyPress = event => {
        keyPressHandler(event);
      };

      ctrl.togglePopover = () => {
        ctrl.areaValue = '@';
        setPopoverToggled(!ctrl.isPopoverToggled);
      };

      ctrl.getFilteredData = (filteredItems, userQuery) => {
        if (filteredItems.length > 0) {
          ctrl.filteredItems = angular.copy(filteredItems);
          if (!userQuery) setPopoverToggled(false);
        } else {
          setPopoverToggled(false);
        }
        return filteredItems;
      };

      ctrl.filter = () => item =>
        ctrl['filter' + ctrl.source.charAt(0).toUpperCase() + ctrl.source.slice(1)].apply(this, [item]);

      ctrl.filterUsers = item => {
        let _criteria = ctrl.userQuery.toLowerCase().slice(1);
        return (
          (item.name && item.name.toLowerCase().includes(_criteria)) ||
          (item.firstName && item.firstName.toLowerCase().includes(_criteria)) ||
          (item.lastName && item.lastName.toLowerCase().includes(_criteria))
        );
      };

      ctrl.filterPurposes = item => {
        return typeof item === 'object'
          ? item.name.toLowerCase().includes(ctrl.userQuery.toLowerCase())
          : item.toLowerCase().includes(ctrl.userQuery.toLowerCase());
      };

      ctrl.filterAttributes = item => item.short_name && item.short_name.toLowerCase().includes(ctrl.userQuery);

      let listenForPopoverClosing = $rootScope.$on('closePopoverOnOutsideClick', (event, data) => {
        if (ctrl.isPopoverToggled) setPopoverToggled(false);
      });

      $scope.$on('$destroy', function () {
        listenForPopoverClosing();
      });
    },
  ],
  bindings: {
    source: '@',
    triggerChar: '@',
    popoverTmpl: '@',
    areaClass: '@',
    replace: '@',
    areaPlaceholder: '@',
    areaDynamicClass: '<',
    areaRows: '@',
    areaValue: '<',
    onBlur: '&',
    onChanged: '&',
    onSubmit: '&',
    onFocus: '&',
    onClick: '&',
    onKeyup: '&',
    popoverPosition: '@',
    popoverClassname: '@',
    togglerEnabled: '@',
    insertAsMention: '@',
    mode: '@',
    uniqueId: '@',
    isDisabled: '<',
    listHeight: '@',
    refreshData: '<',
  },
});
app.directive('popoverCloseOutside', [
  '$rootScope',
  $rootScope => {
    return {
      link: (scope, element) => {
        element.on('click', () => {
          if (document.querySelector('.popover')) $rootScope.$broadcast('closePopoverOnOutsideClick');
        });
      },
    };
  },
]);
