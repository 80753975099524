import React, { FC } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { BigidHeading5 } from '@bigid-ui/components';
import { BigidChevronRightIcon, BigidChevronDownIcon } from '@bigid-ui/icons';

interface DiscoverySideMenu {
  category: string;
  selected?: boolean;
  disabled?: boolean;
  onClick?: () => void;
}

type SidebarMenuStyles = Pick<DiscoverySideMenu, 'selected' | 'disabled'>;

const useStyles = makeStyles(theme => ({
  root: ({ selected, disabled }: SidebarMenuStyles) => ({
    display: 'flex',
    alignItems: 'center',
    height: 42,
    padding: '11px 16px',
    justifyContent: 'space-between',
    cursor: 'pointer',
    borderRadius: 4,
    ...(disabled && { opacity: 0.3 }),
    ...(selected && { background: theme.vars.palette.bigid.primary50 }),
    '&:hover': { background: disabled ? theme.vars.palette.bigid.gray100 : theme.vars.palette.bigid.primary50 },
  }),
  titleWrapper: { display: 'flex', alignItems: 'center' },
}));

export const DiscoverySideMenu: FC<DiscoverySideMenu> = ({ category, selected, onClick, disabled }) => {
  const classes = useStyles({ selected, disabled });
  return (
    <div className={classes.root} onClick={onClick}>
      <div className={classes.titleWrapper}>
        <BigidHeading5>{category}</BigidHeading5>
      </div>
      {selected ? <BigidChevronDownIcon /> : <BigidChevronRightIcon />}
    </div>
  );
};
