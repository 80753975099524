import React, { FC } from 'react';
import { noop } from 'lodash';
import { SecondaryButton, PrimaryButton } from '@bigid-ui/components';
import { BigidFormBox } from '../../../../../components/BigidFormBox/BigidFormBox';
import { tpaStatusService } from '../../../../../services/tpaStatusService';
import { openSystemDialog } from '../../../../../services/systemDialogService';

export interface AppVersionSectionProps {
  onUpdate: () => void;
  appName: string;
  version: string;
}

export const AppVersionSection: FC<AppVersionSectionProps> = ({ onUpdate, version, appName }) => {
  const openUpdateApprovalModal = () => {
    openSystemDialog({
      title: `${appName} Update`,
      onClose: noop,
      content: () => <div>Are you sure you want to update?</div>,
      maxWidth: 'xs',
      borderTop: true,
      buttons: [
        {
          text: 'Yes',
          onClick: () => onUpdate(),
          component: PrimaryButton,
        },
        {
          text: 'No',
          onClick: noop,
          component: SecondaryButton,
          isClose: true,
        },
      ],
    });
  };

  return (
    <BigidFormBox title="Application Version" description={version ? version : 'Unspecified Version'}>
      <SecondaryButton
        disabled={tpaStatusService.isTpaOffline(status)}
        size="small"
        onClick={openUpdateApprovalModal}
        dataAid="updateAppButton"
        text="Update Now"
      />
    </BigidFormBox>
  );
};
