import React, { FC } from 'react';
import { BigidLayoutEmptyState } from '@bigid-ui/layout';
import { BigidAddIcon, BigidCredentialsIllustration } from '@bigid-ui/icons';
import { useLocalTranslation } from './translations';

interface CredentialsEmptyStateProps {
  handleCredentialAdd: () => void;
}

export const CredentialsEmptyState: FC<CredentialsEmptyStateProps> = ({ handleCredentialAdd }) => {
  const { t } = useLocalTranslation();

  const emptyStateActions = [
    {
      execute: async () => {
        handleCredentialAdd();
        return { shouldGridReload: false };
      },
      icon: () => <BigidAddIcon color="currentColor" />,
      label: t('buttons.addCredentials'),
      show: () => true,
    },
  ];

  return (
    <BigidLayoutEmptyState
      actions={emptyStateActions}
      illustration={BigidCredentialsIllustration}
      description={t('message.manageCredentials')}
    />
  );
};
