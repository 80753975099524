import React, { FC } from 'react';
import { BigidLayoutEmptyState } from '@bigid-ui/layout';
import { BigidNoDataIllustration } from '@bigid-ui/icons';

export const ActionWorkflowEmpty: FC = () => {
  return (
    <BigidLayoutEmptyState
      illustration={BigidNoDataIllustration}
      description="Select a category from the list to view or edit your actions"
    />
  );
};
