import React, { FC } from 'react';
import { BigidBody1, BigidLink } from '@bigid-ui/components';
import { useSectionInfoStyle } from '../useSectionInfoStyle';
import { docsUrls } from '../../../../../../config/publicUrls';

export const ConnectionSectionTopInfo: FC = () => (
  <BigidBody1 className={useSectionInfoStyle({}).sectionInfoWrapper}>
    {`Fill in basic details to test connection to data source. `}
    <BigidLink text="Read more." href={docsUrls.DATA_SOURCES} shouldOpenNewTab={true} />
  </BigidBody1>
);
