import React, { FC, useState, useMemo, useEffect, useCallback } from 'react';
import { BigidDropdown, BigidDropdownOption, BigidDropdownValue, BigidHeading6 } from '@bigid-ui/components';
import makeStyles from '@mui/styles/makeStyles';
import { MetadataSearchFilterComponentBaseProps } from '../../MetadataSearchFiltersTypes';
import {
  MetadataSearchFilterUserModifier,
  MetadataSearchFilterUserModifierLabelMap,
} from './MetadataSearchFilterUserUtils';
import { getMetadataSearchMapKeys } from '../../../MetadataSearchService';

export type MetadataSearchFilterUserProps = MetadataSearchFilterComponentBaseProps;

const useStyles = makeStyles({
  root: {
    display: 'flex',
    width: '100%',
  },
  control: {
    padding: '8px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    width: '50%',
  },
  label: {
    marginBottom: '8px',
  },
});

export const MetadataSearchFilterUser: FC<MetadataSearchFilterUserProps> = ({
  dataAid,
  config,
  value: initialValue,
  onFilterChange,
  onFilterSearch,
  isFiltersFetching,
}) => {
  const classes = useStyles();
  const [genericOptionSelected, setGenericOptionSelected] = useState<BigidDropdownValue>([]);
  const [isSpecificOptionFieldEnabled, setIsSpecificOptionFieldEnabled] = useState<boolean>(false);

  const { fieldName, specialValueFieldName, fieldType, displayName, values: options, specialValues } = config;
  const label = displayName || fieldName;
  const isSpecificOptionFieldDisabled = options?.length === 0 && !initialValue;
  const isSpecificOptionFieldErasable = !isSpecificOptionFieldDisabled;

  const genericOptions: BigidDropdownOption[] = useMemo(() => {
    const [any, ...rest] = getMetadataSearchMapKeys(MetadataSearchFilterUserModifierLabelMap).reduce(
      (genericOptionsReduced, modifier) => {
        const option: BigidDropdownOption = {
          id: modifier,
          displayValue: MetadataSearchFilterUserModifierLabelMap[modifier],
          value: modifier,
        };

        if (modifier === MetadataSearchFilterUserModifier.SPECIFIC) {
          return options?.length === 0 && !initialValue ? genericOptionsReduced : [...genericOptionsReduced, option];
        } else {
          return [...genericOptionsReduced, option];
        }
      },
      [] as BigidDropdownOption[],
    );

    return [any, ...specialValues, ...rest];
  }, [options, specialValues, initialValue]);

  const selectedValue: BigidDropdownValue = useMemo(
    () =>
      initialValue && !initialValue.isKeyword
        ? [
            {
              id: String(initialValue.value),
              value: initialValue.value,
              displayValue: String(initialValue.value),
            },
          ]
        : [],
    [initialValue],
  );

  useEffect(() => {
    setIsSpecificOptionFieldEnabled(genericOptionSelected[0]?.value === MetadataSearchFilterUserModifier.SPECIFIC);
  }, [genericOptionSelected]);

  useEffect(() => {
    if (initialValue) {
      let option: BigidDropdownValue = [];
      if (initialValue.isKeyword) {
        option = [genericOptions.find(({ id }) => id === initialValue.value) || genericOptions[0]];
      } else {
        option = [genericOptions.find(({ id }) => id === MetadataSearchFilterUserModifier.SPECIFIC)];
      }
      setGenericOptionSelected(option);
    } else {
      setGenericOptionSelected([genericOptions[0]]);
    }
  }, [genericOptions, initialValue]);

  const handleGenericOptionOnSelect = useCallback(
    (selectedOption: BigidDropdownOption[]): void => {
      const { value, displayValue } = selectedOption[0];
      const isAnyValue = value === MetadataSearchFilterUserModifier.ANY;
      const isSpecificValue = value === MetadataSearchFilterUserModifier.SPECIFIC;

      setGenericOptionSelected(selectedOption);

      if (!isSpecificValue) {
        onFilterChange({
          field: fieldName,
          specialField: isAnyValue ? null : specialValueFieldName,
          value: isAnyValue ? null : value,
          operator: 'equal',
          fieldType,
          isKeyword: true,
          fieldDisplayValue: displayValue,
          fieldDisplayName: label,
        });
      }
    },
    [fieldName, fieldType, label, onFilterChange, specialValueFieldName],
  );

  const handleSpecificOptionOnSelect = useCallback(
    (selectedOption: BigidDropdownOption[]): void => {
      if (selectedOption.length > 0) {
        const { value } = selectedOption[0];

        onFilterChange({
          field: fieldName,
          value,
          operator: 'equal',
          fieldType,
          fieldDisplayName: label,
        });
      } else {
        onFilterChange({
          field: fieldName,
          value: null,
          operator: 'equal',
          fieldType,
          fieldDisplayName: label,
        });
      }
    },
    [fieldName, fieldType, label, onFilterChange],
  );

  const handleFetchOptions = useCallback(
    (query: string) => {
      return onFilterSearch(query, config);
    },
    [config, onFilterSearch],
  );

  return (
    <div className={classes.root} data-aid={dataAid}>
      <div className={classes.control}>
        <BigidHeading6 data-aid={`${dataAid}-label`} className={classes.label}>
          {label}
        </BigidHeading6>
        <BigidDropdown
          dataAid={`${dataAid}-generic`}
          options={genericOptions}
          value={genericOptionSelected}
          onSelect={handleGenericOptionOnSelect}
          isDisabled={isFiltersFetching}
        />
      </div>
      {isSpecificOptionFieldEnabled && (
        <div className={classes.control}>
          <BigidDropdown
            isAsync
            isSearchable
            isErasable={isSpecificOptionFieldErasable}
            isDisabled={isSpecificOptionFieldDisabled || isFiltersFetching}
            options={options}
            value={selectedValue}
            placeholder={isSpecificOptionFieldDisabled ? 'No value' : `Select ${label}`}
            dataAid={`${dataAid}-specific`}
            fetchOptions={handleFetchOptions}
            onSelect={handleSpecificOptionOnSelect}
            noOptionsMessage="Current selection does not match other filters"
          />
        </div>
      )}
    </div>
  );
};
