import { module } from 'angular';
const app = module('app');

app.factory('entitySummaryService', function ($http, appSettings) {
  'ngInject';

  return {
    getPanelData: () =>
      $http
        .get(`${appSettings.serverPath}/${appSettings.version}/entitySummariesScoped`)
        .then(response => response.data),
  };
});
