import React, { FC } from 'react';
import { Typography, Paper } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { BigidColors } from '@bigid-ui/components';
import { $state } from '../../../services/angularServices';
import { CONFIG } from '../../../../config/common';
import { ImportDataSourcesForm } from '../../../components/ImportDataSourcesForm/ImportDataSourcesForm';

const useStyles = makeStyles({
  importDataSourcesPage: {
    maxWidth: 600,
    margin: '0 auto',
  },
  dateSourceImportContainer: {
    padding: 16,
  },
  title: {
    fontSize: 30,
    color: BigidColors.gray[600],
    textAlign: 'center',
  },
  text: {
    fontSize: 14,
    marginTop: 20,
    marginBottom: 26,
    textAlign: 'center',
  },
});

export const ImportDataSources: FC = () => {
  const classes = useStyles({});

  return (
    <div className={classes.importDataSourcesPage} data-aid="ImportDataSources">
      <div>
        <Typography variant={'h4'} className={classes.title} data-aid="ImportDataSourcesTitle">
          Import Data Sources
        </Typography>
        <div className={classes.text} data-aid="ImportDataSourcesInfo">
          After exporting a data source file from another BigID instance, you can import it here.
        </div>
      </div>
      <Paper className={classes.dateSourceImportContainer} data-aid="ImportDataSourcesFormWrapper">
        <ImportDataSourcesForm
          onSuccess={() => {
            $state.go(CONFIG.states.DATA_SOURCE_ROUTER);
          }}
        />
      </Paper>
    </div>
  );
};
