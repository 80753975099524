import { httpService } from './httpService';
import { ProxyItemResponse } from '../views/Proxies/types';

type GetProxiesRes = {
  data: ProxyItemResponse[];
};

const PROXY_ENDPOINT_PREFIX = 'proxy-servers';

export const getProxies = async (): Promise<GetProxiesRes> => {
  return httpService.fetch(PROXY_ENDPOINT_PREFIX);
};

export const deleteProxy = async (id: string): Promise<void> => {
  httpService.delete(`${PROXY_ENDPOINT_PREFIX}/${encodeURIComponent(id)}`);
};

export const createProxy = async (body: Partial<ProxyItemResponse>): Promise<void> => {
  httpService.post(PROXY_ENDPOINT_PREFIX, body);
};

export const updateProxy = async (id: string, body: Partial<ProxyItemResponse>): Promise<void> => {
  httpService.put(`${PROXY_ENDPOINT_PREFIX}/${encodeURIComponent(id)}`, body);
};
