import React, { FC, useCallback, useRef, useState } from 'react';
import { BigidBody1, BigidFieldRenderProps, BigidTooltip } from '@bigid-ui/components';
import { BigidRichTextEditor } from '@bigid-ui/rich-text-editor';
import { FormControl } from '@mui/material';
import styled from '@emotion/styled';
import { BigidInfoIcon } from '@bigid-ui/icons';

const EditorWrapper = styled('div')({
  display: 'flex',
  flexDirection: 'column',
});

const InfoIconWrapper = styled('div')({
  paddingLeft: '4px',
  display: 'flex',
  height: '22px',
});

const LabelWrapper = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  lineHeight: '20px',
  alignItems: 'center',
});

interface FormRichTextFieldValue {
  markdown: string;
}
interface FormRichTextFieldProps extends BigidFieldRenderProps {
  value: FormRichTextFieldValue;
  readOnly?: boolean;
  allowedVariables?: string[];
  allowedVariablesTooltipText?: string;
}
export const FormRichTextEditor: FC<FormRichTextFieldProps> = ({
  name,
  value = '',
  setValue,
  misc,
  label,
  readOnly = false,
  allowedVariables,
  allowedVariablesTooltipText,
}) => {
  const initialValueRef = useRef(value);
  const [isTouched, setIsTouched] = useState(false);
  const onChangeHandler = useCallback(
    (value: string) => {
      // Avoid making the form dirty (setValue) with the initial value - due to JoditEditor behaviour
      if (!isTouched && value !== initialValueRef.current) {
        setValue(value);
        setIsTouched(true);
      }
      if (isTouched) {
        setValue(value);
      }
    },
    [isTouched, setValue],
  );

  return (
    <EditorWrapper id={`bigid-form-field-rich-text-${name}`}>
      <LabelWrapper>
        {label && <BigidBody1 marginBottom={'8px'}>{label}</BigidBody1>}
        {!!allowedVariables?.length && (
          <InfoIconWrapper>
            <BigidTooltip title={allowedVariablesTooltipText}>
              <div>
                <BigidInfoIcon size="small" />
              </div>
            </BigidTooltip>
          </InfoIconWrapper>
        )}
      </LabelWrapper>
      <FormControl fullWidth={misc?.fullWidth}>
        <BigidRichTextEditor readOnly={readOnly} onChange={onChangeHandler} value={value as string} />
      </FormControl>
    </EditorWrapper>
  );
};
