import React, { FC, ReactNode, Fragment, useState } from 'react';
import { BigidGridColumnTypes, BigidGridWithToolbar, BigidGridWithToolbarProps, BigidGridColumn } from '@bigid-ui/grid';
import { AppLicense, appsLicenseService } from '../../../../services/appsLicenseService';
import { notificationService } from '../../../../services/notificationService';
import { httpService } from '../../../../services/httpService';
import { StatusCell } from './StatusCell';
import { AppLicenseDialog } from '../../../CreateCustomApp/AppLicenseDialog';
import { License } from '../../../../services/licenseService';
import { isPermitted } from '../../../../services/userPermissionsService';
import { GENERAL_SETTINGS_PERMISSIONS } from '@bigid/permissions';
import { uniqBy } from 'lodash';

const columns: BigidGridColumn<AppLicense>[] = [
  {
    title: 'App name',
    name: 'name',
    type: BigidGridColumnTypes.TEXT,
    getCellValue: ({ name }) => name,
    width: 350,
  },
  {
    title: 'Category',
    name: 'category',
    type: BigidGridColumnTypes.TEXT,
    getCellValue: ({ category }) => category,
  },
  {
    title: 'Status',
    name: 'status',
    type: BigidGridColumnTypes.CUSTOM,
    getCellValue: ({ status }) => (<StatusCell status={status} />) as ReactNode,
    width: 170,
    disableTooltip: true,
  },
  {
    title: 'Actions',
    name: 'actions',
    type: BigidGridColumnTypes.TEXT,
    getCellValue: ({ actions }) => actions?.join(', '),
    width: 350,
  },
];

type DialogResolve = (value: boolean) => void;

export const ApplicationLicenses: FC = () => {
  const [appLicenseDialogOpen, setAppLicenseDialogOpen] = useState(false);
  const [onResolve, setOnResolve] = useState<DialogResolve>();

  const openDialog = (): Promise<boolean> =>
    new Promise(resolve => {
      setAppLicenseDialogOpen(true);
      setOnResolve(() => resolve);
    });

  const closeAppLicenseDialog = () => setAppLicenseDialogOpen(false);

  const gridWithToolbarConfig: BigidGridWithToolbarProps<AppLicense> = {
    pageSize: 1000,
    columns,
    entityName: 'Applications',
    hideColumnChooser: true,
    toolbarActions: isPermitted(GENERAL_SETTINGS_PERMISSIONS.MANAGE_LICENSE.name)
      ? [
          {
            label: 'Insert License',
            isGlobal: true,
            execute: async () => {
              const didUpdate = await openDialog();
              const {
                data: { supportedApps },
              } = await httpService.fetch<{ license: License; supportedApps: AppLicense[] }>('license');
              appsLicenseService.setAppsLicense(supportedApps);
              return { shouldGridReload: didUpdate };
            },
            disable: () => false,
            show: () => true,
          },
        ]
      : [],
    showSortingControls: false,
    fetchData: async () => {
      try {
        const {
          data: { supportedApps },
        } = await httpService.fetch('license');

        const data = uniqBy<AppLicense>(supportedApps, 'id')
          .filter((app: AppLicense) => app.status)
          .map(({ displayName, name, displayCategory, category, ...app }: AppLicense) => ({
            ...app,
            name: displayName || name,
            category: displayCategory || category,
          }));

        return Promise.resolve({
          data,
          totalCount: data.length,
        });
      } catch (err) {
        notificationService.error(`Failed to get applications.`);
      }
    },
  };

  return (
    <Fragment>
      <AppLicenseDialog isOpen={appLicenseDialogOpen} onClose={closeAppLicenseDialog} onApply={() => onResolve(true)} />
      <BigidGridWithToolbar {...gridWithToolbarConfig} />
    </Fragment>
  );
};
