import React, { FC } from 'react';
import classnames from 'classnames';
import makeStyles from '@mui/styles/makeStyles';
import {
  BigidBody2,
  SecondaryButton,
  PrimaryButton,
  BigidAccordion,
  BigidAccordionDetails,
  BigidAccordionSummary,
  AccordionSummarySizeEnum,
} from '@bigid-ui/components';
import { BigidClearFilledIcon, BigidApplyFilledIcon } from '@bigid-ui/icons';
import { FailedImportLine, ImportDataSourceFailedGrid } from './ImportDataSourceFailedGrid';
import { makeBrowserDownloadFile } from '../../services/httpService';

const useStyles = makeStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '600px',
  },
  actionsWrapper: {
    paddingTop: '32px',
    display: 'flex',
    justifyContent: 'flex-end',
    flex: '1 0 auto',
    alignItems: 'flex-end',
  },
  countInfoWrapper: {
    display: 'grid',
    gridTemplateColumns: '24px 1fr',
    gridTemplateRows: '1fr',
    gap: '0px 16px',
    alignItems: 'center',
  },
  successCountWrapper: {
    padding: '8px 0 16px 12px',
  },
  failedGridWrapper: {
    maxHeight: '400px',
    maxWidth: '530px',
    display: 'flex',
  },
});

export interface ImportDataSourceResultProps {
  onSuccess?: () => void;
  failedList: FailedImportLine[];
  successCount: number;
}

const downloadFailedCsvImportLinesReport = (failedList: FailedImportLine[]) => {
  const data = failedList.reduce(
    (acc, { line, csvLineObject: { name, itOwner, type }, notValidFields }) => `${acc}
${name}, ${itOwner}, ${type}, ${line}, ${Object.values(notValidFields).join('/')}`,
    'name, itOwner, type, line, failReason',
  );
  const fileData = new Blob([data], { type: 'text/csc' });
  const fileName = `failed-import-${new Date().toISOString()}.csv`;
  makeBrowserDownloadFile(fileName, URL.createObjectURL(fileData));
};

export const ImportDataSourceResult: FC<ImportDataSourceResultProps> = ({ onSuccess, failedList, successCount }) => {
  const { root, actionsWrapper, countInfoWrapper, successCountWrapper, failedGridWrapper } = useStyles({});

  return (
    <div className={root} data-aid="ImportDataSourceResult">
      <BigidBody2 className={classnames(countInfoWrapper, successCountWrapper)}>
        <BigidApplyFilledIcon color="positive" /> {successCount} Data Sources were successfully imported.
      </BigidBody2>
      {failedList?.length > 0 && (
        <BigidAccordion>
          <BigidAccordionSummary
            aria-controls={`ImportDataSourceResult-failed-header`}
            id={`ImportDataSourceResult-failed-header`}
            data-aid={'ImportDataSourceResult-failed-header'}
            size={AccordionSummarySizeEnum.medium}
          >
            <BigidBody2 className={countInfoWrapper}>
              <BigidClearFilledIcon color="negative" />
              {failedList.length} Data Sources failed to import.
            </BigidBody2>
          </BigidAccordionSummary>
          <BigidAccordionDetails>
            <div className={failedGridWrapper}>
              <ImportDataSourceFailedGrid failedList={failedList} />
            </div>
          </BigidAccordionDetails>
        </BigidAccordion>
      )}
      <div className={actionsWrapper}>
        <SecondaryButton
          onClick={() => {
            downloadFailedCsvImportLinesReport(failedList);
          }}
          size="large"
          disabled={!failedList.length}
          margin="4px"
          text=" Download Failure Report"
        />
        <PrimaryButton onClick={onSuccess} size="large" margin="4px" text="Done" />
      </div>
    </div>
  );
};
