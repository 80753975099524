import { omit } from 'lodash';
import { v4 as uuid } from 'uuid';
import { BigidFormFieldTypes } from '@bigid-ui/components';
import { CLASSIFIER_TYPES, ClassifierGridRow, RegexFormFields } from '../types/ClassifierTypes';
import { requiredFieldValidator } from '../RegexForm/validationUtils';
import { getFixedT } from '../translations';
import { NerExtraFields } from './NERForm';
import { CategoriesFieldDropdown } from '../components/CategoriesField/CategoriesFieldDropdown';
import { AttributesFieldDropdown } from '../components/AttributesField/AttributesFieldDropdown';

const t = getFixedT('form');

export interface ListItem {
  id: string;
  value: {
    [RegexFormFields.classifierName]: string;
    [RegexFormFields.nerEntityMap]: string;
  };
}

export const createNewExtraField = () => ({
  id: uuid(),
  value: {
    [RegexFormFields.classifierName]: '',
    [RegexFormFields.nerEntityMap]: '',
  },
});

export const initialFormValues = {
  [RegexFormFields.modelName]: '',
  extraFields: [createNewExtraField()],
  attribute: {
    friendly_name: '',
    glossary_id: '',
  },
};

const mapInitialFormData = (initialData: Partial<ClassifierGridRow>) => {
  return {
    extraFields: [
      {
        id: initialData?._id,
        value: {
          [RegexFormFields.classifierName]: initialData?.[RegexFormFields.classifierName],
          [RegexFormFields.nerEntityMap]: initialData?.nerEntityMap,
        },
      },
    ],
    attribute: {
      friendly_name: initialData?.friendly_name,
      glossary_id: initialData?.glossary_id,
    },
    ...omit(initialData, [RegexFormFields.classifierName, RegexFormFields.nerEntityMap]),
  };
};

export const getInitialFormData = (initialData: Partial<ClassifierGridRow>, isEdit: boolean, isOOTB: boolean) => ({
  initialValues: {
    ...initialFormValues,
    classifierType: CLASSIFIER_TYPES.NER,
    ...(initialData ? mapInitialFormData(initialData) : {}),
  },
  fields: [
    {
      name: RegexFormFields.modelName,
      label: t('modelName'),
      isRequired: true,
      validate: requiredFieldValidator,
      tooltipText: t('tooltips.nerModelName'),
      fieldProps: {
        readOnly: isOOTB || isEdit,
        size: 'large',
      },
    },
    {
      name: 'attribute',
      render: AttributesFieldDropdown,
      isRequired: false,
      fieldProps: {
        size: 'large',
      },
    },
    {
      name: RegexFormFields.categories,
      label: t('categories'),
      render: CategoriesFieldDropdown,
    },
    {
      name: RegexFormFields.description,
      label: t('description'),
      type: BigidFormFieldTypes.TEXTAREA,
      fieldProps: {
        readOnly: isOOTB,
        multiline: true,
      },
      misc: {
        rows: 4,
      },
    },
    {
      name: 'extraFields',
      label: 'Test label',
      render: NerExtraFields,
      fieldProps: {
        readOnly: isEdit,
      },
      misc: {
        fullWidth: true,
        addNewButtonVisible: !isEdit,
      },
      validate: (values: ListItem[]) => {
        const validatedFields = values?.map(({ id, value }) => {
          return value[RegexFormFields.nerEntityMap]?.trim() && value[RegexFormFields.classifierName]?.trim()
            ? { id, value, isValid: true }
            : {
                id,
                [RegexFormFields.nerEntityMap]: requiredFieldValidator(value[RegexFormFields.nerEntityMap]),
                [RegexFormFields.classifierName]: requiredFieldValidator(value[RegexFormFields.classifierName]),
              };
        });
        const isAllFieldsValid = validatedFields?.every(({ isValid }) => isValid === true);

        if (isAllFieldsValid) {
          return false;
        }

        return JSON.stringify(validatedFields);
      },
    },
  ],
});
