import { module } from 'angular';
import { getProductProperties } from '../../react/services/productTypePropertiesService';
import { debounce } from 'lodash';
const app = module('app');

app.directive('ngDecimal', function () {
  return {
    restrict: 'A',
    require: '?ngModel',
    scope: {
      ngDecimal: '@',
    },
    link: (scope, element, attr, ngModel) => {
      const symbolsAfterPoint = parseInt(scope.ngDecimal) || 2;

      const regExp = /^[0-9.,]+$/;

      const DECIMAL_POINT = '.';
      const DECIMAL_COMMA = ',';

      ngModel.$render = () => {
        const value = parseFloat(ngModel.$modelValue);

        if (value !== '' && value !== null && value >= 0) {
          if (typeof symbolsAfterPoint === 'number') {
            element.val(value.toFixed(symbolsAfterPoint).toString().replace(DECIMAL_COMMA, DECIMAL_POINT));
          } else {
            element.val(value.toString().replace(DECIMAL_COMMA, DECIMAL_POINT));
          }
        }
      };

      element.on('change', e => {
        const elemVal = element.val();

        if (regExp.test(elemVal)) {
          const floatValue = parseFloat(elemVal.replace(DECIMAL_COMMA, DECIMAL_POINT));

          if (!isNaN(floatValue) && typeof symbolsAfterPoint === 'number') {
            let newValue;

            if (symbolsAfterPoint === 0) {
              newValue = parseInt(floatValue);
            } else {
              newValue = floatValue.toFixed(symbolsAfterPoint);
            }

            element.val(newValue);

            ngModel.$setViewValue(newValue);
          }
        }
      });
    },
  };
});

app.directive('ngEqualTo', () => {
  return {
    require: 'ngModel',
    scope: {
      otherValue: '=ngEqualTo',
    },
    link: (scope, element, attributes, ngModel) => {
      ngModel.$validators.ngEqualTo = value => {
        if (scope.otherValue.enabled) {
          return value == scope.otherValue.value;
        } else {
          return true;
        }
      };
      scope.$watch('otherValue', () => {
        ngModel.$validate();
      });
    },
  };
});

app.directive('bigidTranslate', function ($translate) {
  'ngInject';

  return {
    restrict: 'A',
    link: ($scope, $element, $attrs) => {
      const langConstant = $attrs.bigidTranslate;
      const { translationPostfix } = getProductProperties();

      $translate(`${langConstant}${translationPostfix}`).then(
        translation => {
          $element.text(translation);
        },
        () => {
          $translate(langConstant).then(translation => {
            $element.text(translation);
          });
        },
      );
    },
  };
});

app.directive('myUiSelect', function () {
  return {
    require: 'uiSelect',
    link: function (scope, element) {
      var searchInput = element.querySelectorAll('input.ui-select-search');
      searchInput.on(
        'keyup',
        debounce(function (e) {
          scope?.$parent?.$parent?.$ctrl?.selectInputChange?.(e.target.value);
          scope?.$parent?.$parent?.selectInputChange?.(e.target.value);
        }, 500),
      );

      searchInput.on('focus', function () {
        scope?.$parent?.$parent?.$ctrl?.selectInputChange?.();
        scope?.$parent?.$parent?.selectInputChange?.();
      });
    },
  };
});
