import React, { FC, useRef, useState } from 'react';
import { PrimaryButton, SecondaryButton, BigidBody1, BigidLoader, BigidFormValues } from '@bigid-ui/components';
import { generateDataAid } from '@bigid-ui/utils';
import { styled } from '@mui/material';
import { useLocalTranslation } from './translations';
import { AssignCollaboratorForm, AssignCollaboratorFormStateAndControls } from './AssignCollaboratorForm';
import { CuratedDataSource } from '../../../curationService';
import { SystemDialogContentProps } from '../../../../../services/systemDialogService';

const Root = styled('div')<{ isBusy: boolean }>`
  ${({ isBusy }) =>
    isBusy &&
    `
    pointer-events: none;
    opacity: 0.5;
  `}
`;

const Content = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const Controls = styled('div')`
  display: flex;
  justify-content: flex-end;
  gap: 16px;
  margin-top: 16px;
`;

const Description = styled(BigidBody1)`
  margin-top: 16px;
`;

export interface AssignCollaboratorProps {
  dataAid?: string;
  curatedDataSource: CuratedDataSource;
  onSubmit: (formData: BigidFormValues) => void;
}

export const AssignCollaborator: FC<SystemDialogContentProps<AssignCollaboratorProps>> = ({
  dataAid = 'AssignCollaborator',
  curatedDataSource,
  onClose,
  onSubmit,
}) => {
  const { t } = useLocalTranslation('AssignCollaborator');
  const [isFormBusy, setIsFormBusy] = useState<boolean>(false);
  const dialogControls = useRef<AssignCollaboratorFormStateAndControls>();

  const handleDialogControlsChange = ({ isBusy }: AssignCollaboratorFormStateAndControls) => {
    setIsFormBusy(isBusy);
  };

  const handleInviteButtonClick = async () => {
    const formData = await dialogControls.current?.onInvite.execute();

    if (formData) {
      onSubmit(formData);
    }

    onClose();
  };

  const handleCancelButtonClick = (): void => {
    onClose();
  };

  return (
    <Root data-aid={dataAid} isBusy={isFormBusy}>
      {isFormBusy && <BigidLoader dataAid={generateDataAid(dataAid, ['loader'])} />}
      <Content>
        <AssignCollaboratorForm
          dataAid={generateDataAid(dataAid, ['dialog', 'form'])}
          dialogControls={dialogControls}
          curatedDataSource={curatedDataSource}
          onDialogControlsChange={handleDialogControlsChange}
        />
        <Description>{t('description')}</Description>
      </Content>
      <Controls>
        <SecondaryButton
          dataAid={generateDataAid(dataAid, ['dialog', 'button', 'cancel'])}
          size="medium"
          onClick={handleCancelButtonClick}
          text={t('buttons.cancel')}
        />
        <PrimaryButton
          dataAid={generateDataAid(dataAid, ['dialog', 'button', 'invite'])}
          size="medium"
          disabled={isFormBusy}
          onClick={handleInviteButtonClick}
          text={t('buttons.invite')}
        />
      </Controls>
    </Root>
  );
};
