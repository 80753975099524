export const formatBytes = (bytes: number, decimals = 2, shouldOmitSpace?: boolean) => {
  if (bytes === 0) {
    return `0 Bytes`;
  }

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizeModifierOptions = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
  const sizeModifierIndex = Math.floor(Math.log(bytes) / Math.log(k));
  const sizeModifier = sizeModifierOptions[sizeModifierIndex];
  const delimiterSpaceComputed = shouldOmitSpace && sizeModifier !== 'Bytes' ? '' : ' ';

  return `${parseFloat((bytes / Math.pow(k, sizeModifierIndex)).toFixed(dm))}${delimiterSpaceComputed}${sizeModifier}`;
};

export const formatNumber = (number: number) => {
  const { format } = new Intl.NumberFormat();
  return format(number || 0);
};

export const formatNumberCompact = (number: number, toFixed = 1): string => {
  switch (true) {
    case number >= 1e9:
      return (number / 1e9).toFixed(toFixed).replace(/\.0$/, '') + 'B';
    case number >= 1e6:
      return (number / 1e6).toFixed(toFixed).replace(/\.0$/, '') + 'M';
    case number >= 1e3:
      return (number / 1e3).toFixed(toFixed).replace(/\.0$/, '') + 'K';
    default:
      return formatNumber(number);
  }
};
