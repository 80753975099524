import { useState, useCallback, useEffect } from 'react';
import { DataCatalogAsyncOperation } from '../../../DataCatalog/DataCatalogAsyncOps/DataCatalogAsyncOpsTypes';
import { subscribeToRepeatedSSEEventById, SSEDataMessage } from '../../../../services/sseService';

export type CatalogRuleApplySseResult = DataCatalogAsyncOperation<never, any, string>;

export type UseCatalogRuleApplyParams = {
  sseRoutingKey: string;
};

export type UseCatalogRuleApplyResponse = {
  operation: CatalogRuleApplySseResult;
};

export const useCatalogRuleApply = ({ sseRoutingKey }: UseCatalogRuleApplyParams): UseCatalogRuleApplyResponse => {
  const [operation, setOperation] = useState<CatalogRuleApplySseResult>(null);

  const handleSseEventReceived = useCallback(
    ({ results = [] }: SSEDataMessage<CatalogRuleApplySseResult>) => {
      setOperation(
        results?.find(operation => {
          return operation.sseRoute === sseRoutingKey;
        }),
      );
    },
    [sseRoutingKey],
  );

  useEffect(() => {
    const unsubscribe = subscribeToRepeatedSSEEventById(sseRoutingKey, handleSseEventReceived);

    setOperation(null);

    return () => {
      unsubscribe();
    };
  }, [handleSseEventReceived, sseRoutingKey]);

  return { operation };
};
