import { httpService } from '../../../services/httpService';
import { DataCatalogRecord, SystemAttributeType } from '../DataCatalogService';
import { getEncodedArray } from '../../../services/queryService';
import { omit } from 'lodash';

export type PreviewContentStatus = 'Completed' | 'InProgress' | 'Failed';

export interface InvestigationResponse {
  data: ContentCache;
}

export interface InvestigateError {
  message: string;
}

export interface ChunkData {
  content?: string;
  attributes?: FileContentAttribute[];
}

export interface ScanInvestigationResponse {
  id: string;
  state: PreviewContentStatus;
  page: number;
  page_details?: ChunkData;
  next_page?: number;
  error?: InvestigateError;
}

export interface FileContentAttribute {
  label: string;
  offset: number;
  length: number;
  allLabels?: string[];
  attributes: AttributesDetails[];
}

interface AttributesDetails {
  attributeName: string;
  attributeType: SystemAttributeType;
}

export interface FileTextContents {
  content?: string;
  attributes?: FileContentAttribute[];
}

export interface ContentCache {
  next_page?: number;
  error?: InvestigateError;
  pages: FileTextContents[];
  with_attributes?: boolean;
  attributeTypes?: AttributeType[];
}

export enum AttributeType {
  CLASSIFIER = 'Classification',
  IDSOR_ATTRIBUTE = 'IDSoR Attribute',
}

type ScanFileContentChunkPayload = {
  scanType: string;
  fullyQualifiedName: string;
  with_attributes: boolean;
  page?: number;
  attributeTypes?: AttributeType[];
};

export const previewFileContentService = {
  getDetailsByObjectName: (fullyQualifiedName: string) => {
    const fullyQualifiedNameParsed = encodeURIComponent(fullyQualifiedName);
    return httpService
      .fetch<{ data: DataCatalogRecord }>(`data-catalog/object-details`, { object_name: fullyQualifiedNameParsed })
      .then(({ data }) => data);
  },
  getObjectCachedContent: (
    investigationId: string,
    requestAttributes: boolean,
    attributeTypes: AttributeType[],
    attributeFilter: string,
  ) => {
    let query = `?with_attributes=${requestAttributes}`;

    if (attributeTypes.length > 0) {
      query += `&attributeTypes=${getEncodedArray(attributeTypes || [])}`;
    }

    if (attributeFilter?.length > 0) {
      query += `&attributes_names[]=${encodeURIComponent(attributeFilter)}`;
    }

    return httpService
      .fetch(`data-catalog/preview/investigation/${investigationId}${query}`)
      .then(({ data }) => data.data);
  },
  deleteObjectCachedContent: (fullyQualifiedName: string) => {
    return httpService
      .delete(`data-catalog/preview/${encodeURIComponent(fullyQualifiedName)}`)
      .then(({ data }) => data);
  },
  getRequestAttributesValue: (fullyQualifiedName: string, attributeTypes: AttributeType[]) => {
    let query = '';

    if (attributeTypes.length > 0) {
      query = `?attributeTypes=${getEncodedArray(attributeTypes || [])}`;
    }

    return httpService
      .fetch(`data-catalog/preview/${encodeURIComponent(fullyQualifiedName)}${query}`)
      .then(({ data }) => data.data.state);
  },
  updateRequestAttributesValue: (
    fullyQualifiedName: string,
    with_attributes: boolean,
    attributeTypes: AttributeType[],
  ) => {
    return httpService
      .post(`data-catalog/preview/${encodeURIComponent(fullyQualifiedName)}`, {
        with_attributes,
        attributeTypes,
      })
      .then(({ data }) => data);
  },
  getCachedContentObject: (fullyQualifiedName: string, attributeTypes: AttributeType[], attributeFilter: string) => {
    let query = '';

    if (attributeTypes.length > 0) {
      query = `?attributeTypes=${getEncodedArray(attributeTypes || [])}`;
    }

    if (attributeFilter?.length > 0) {
      query += `?attributes_names[]=${encodeURIComponent(attributeFilter)}`;
    }

    return httpService
      .fetch<InvestigationResponse>(`data-catalog/preview/${encodeURIComponent(fullyQualifiedName)}${query}`)
      .then(({ data }) => data.data);
  },
  scanFileContentChunk: (payload: ScanFileContentChunkPayload) => {
    const propsToOmit: Array<keyof ScanFileContentChunkPayload> = [];

    if (!payload.page) {
      propsToOmit.push('page');
    }

    if (payload.attributeTypes.length === 0) {
      propsToOmit.push('attributeTypes');
    }

    return httpService.post('scans', omit(payload, propsToOmit)).then(({ data }) => data);
  },
};
