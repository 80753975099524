import { forEach, map } from 'lodash';
import { getObjectDetailsFromCountriesArray, getLocationName } from '../../../../common/amMap/ammap.service';
import { MapChartImageItemDataType, MapChartLineItemDataType } from '@bigid-ui/components';

interface CordinatePoints {
  latitude: number;
  longitude: number;
}

interface CordinatePointsData {
  points: CordinatePoints[];
}

interface DataForConfigLine {
  systems: string[];
  dataSourceName: string;
  destinationCountry: string;
  dataIdentityObject: CordinatePointsData;
}

export interface BasicMapDataEntity {
  id: string;
  name: string;
  count: number;
  avg: number;
}

export interface ApplicationLocationMapEntity extends BasicMapDataEntity {
  target_data_sources?: string[];
}

export interface SystemLocationMapEntity extends BasicMapDataEntity {
  systems?: string[];
}

export type RequestResultMapData = [
  { identity_locations: BasicMapDataEntity[] },
  { system_locations: SystemLocationMapEntity[] },
  { applications_locations: ApplicationLocationMapEntity[] },
];

export interface BasicMapEntity {
  id: string;
  name: string;
  count: number;
  avg: number;
}

const getLines = ({
  systems,
  dataSourceName,
  destinationCountry,
  dataIdentityObject,
}: DataForConfigLine): MapChartLineItemDataType[] =>
  systems.reduce((acc, systemName) => {
    if (systemName === dataSourceName) {
      const cordinatePointsOfDestinationCountry = getObjectDetailsFromCountriesArray(destinationCountry);
      return cordinatePointsOfDestinationCountry
        ? [
            ...acc,
            {
              series: 'system',
              multiGeoLine: [
                [
                  {
                    latitude: cordinatePointsOfDestinationCountry.points[1].latitude,
                    longitude: cordinatePointsOfDestinationCountry.points[1].longitude,
                  },
                  {
                    latitude: dataIdentityObject.points[2].latitude,
                    longitude: dataIdentityObject.points[2].longitude,
                  },
                ],
              ],
            },
          ]
        : acc;
    }
    return acc;
  }, []);

interface DataForConfigImages {
  count: number;
  sum: number;
  name: string;
  title: string;
  series: string;
  cordIndex: number;
}
const getItemImagesData = ({ name, count, title, series, sum, cordIndex }: DataForConfigImages) => {
  const dataIdentityObject = getObjectDetailsFromCountriesArray(name);
  if (dataIdentityObject) {
    const scale = sum === 1 ? 5 : (count / sum) * 11;
    const item = {
      title: `${title}: ${getLocationName(name)}<br>PII Records: ${count}`,
      series,
      latitude: dataIdentityObject.points[cordIndex].latitude,
      longitude: dataIdentityObject.points[cordIndex].longitude,
      scale: scale > 1 ? scale : 1,
      value: count,
    };
    return { item, dataIdentityObject };
  }
  return { item: null, dataIdentityObject: null };
};

interface MapDataItem {
  count: number;
}
const getSum = (data: MapDataItem[]) => data?.reduce((acc: number, item) => acc + item?.count, 0);

export const getAmMapData = ([
  { identity_locations: identityLocations },
  { system_locations: systemLocations },
  { applications_locations: applicationsLocations },
]: RequestResultMapData) => {
  const lineData: MapChartLineItemDataType[] = [];
  const sumIdentity = getSum(identityLocations);
  const sumLocations = getSum(systemLocations);
  const sumApplications = getSum(applicationsLocations);
  const imagesData: MapChartImageItemDataType[] = [
    ...map(identityLocations, ({ name, count }) => {
      const { item } = getItemImagesData({
        name,
        count,
        title: 'Residency',
        series: 'user',
        sum: sumIdentity,
        cordIndex: 0,
      });
      return item;
    }),
    ...map(systemLocations, ({ name, count }) => {
      const { item } = getItemImagesData({
        name,
        count,
        title: 'Location',
        series: 'system',
        sum: sumLocations,
        cordIndex: 1,
      });
      return item;
    }),
    ...map(applicationsLocations, ({ name, count, target_data_sources = [] }) => {
      const { dataIdentityObject, item } = getItemImagesData({
        name,
        count,
        sum: sumApplications,
        title: 'Asset',
        series: 'application',
        cordIndex: 2,
      });

      if (dataIdentityObject) {
        forEach(target_data_sources, dataSourceName => {
          forEach(systemLocations, ({ systems, name: destinationCountry }) => {
            if (systems && dataSourceName && name && destinationCountry) {
              lineData.push(
                ...getLines({
                  systems,
                  dataSourceName,
                  destinationCountry,
                  dataIdentityObject,
                }),
              );
            }
          });
        });
      }
      return item;
    }),
  ].filter(item => item);

  return {
    imagesData,
    lineData,
  };
};
