import { QueryFunction, QueryKey, UseQueryOptions, UseQueryResult, useQuery } from 'react-query';
import { rolesService } from '../../../../services/angularServices';
import type { AsyncReturnType } from '../types';
import type { ErrorType } from '../../../../config/request';

type GetUserRolesQueryError = ErrorType<unknown>;

export type UserRole = {
  _id: string;
  scopes: string[];
  name: string;
};

export type UserScope = {
  _id?: string;
  id: string;
  name: string;
  dataSourcesNames: string[];
};

type UseGetUserRolesOptions = typeof useGetUserRoles extends (options?: infer T) => unknown ? T : never;

export const useGetUserRolesWithScope = (options?: UseGetUserRolesOptions) => {
  const query = useGetUserRoles({ query: { ...options?.query, select: ({ data }) => data.roles ?? [] } });
  const { data: scopes, isFetched: isScopeDataFetched } = useGetUserScopes({
    query: {
      enabled: options?.query?.enabled,
      select: ({ data }) => data?.scopes ?? [],
    },
  });
  const { isFetched: isRoleDataFetched, data: roles } = query;

  const hasScope = (roleName: string, scopeName: string) => {
    if (!isScopeDataFetched || !isRoleDataFetched) return false;

    const scope = scopes.find(({ name }) => name === scopeName);
    return !!roles?.find(({ scopes, name }) => name === roleName && scopes.includes(scope?.id));
  };

  const getScope = (scopeId: string): UserScope => scopes?.find(({ id }) => id === scopeId);

  const getRole = (roleName: string) => {
    const role = roles?.find(({ name }) => name === roleName);
    return { ...role, scopes: role?.scopes?.map(getScope) };
  };

  return { ...query, hasScope, getScope, getRole };
};

export const getUserRoles = async (): Promise<{
  data: { roles: UserRole[]; permissions: unknown[] };
}> => rolesService?.getRBACRoles?.();

export const getUserScopes = async (): Promise<{
  data: { scopes: UserScope[] };
}> => rolesService?.getRBACScopes?.();

export const getUserRolesQueryKey = (): Array<unknown> => ['getUserRoles'];

export const useGetUserRoles = <
  TData = AsyncReturnType<typeof getUserRoles>,
  TError = GetUserRolesQueryError,
>(options?: {
  query?: UseQueryOptions<AsyncReturnType<typeof getUserRoles>, TError, TData>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options || {};

  const queryKey = queryOptions?.queryKey ?? getUserRolesQueryKey();
  const queryFn: QueryFunction<AsyncReturnType<typeof getUserRoles>> = async () => getUserRoles();
  const result = useQuery<AsyncReturnType<typeof getUserRoles>, TError, TData>(queryKey, queryFn, {
    ...queryOptions,
  });

  return {
    queryKey,
    ...result,
  };
};

export const getUserScopesQueryKey = (): Array<unknown> => ['getUserScopes'];

export const useGetUserScopes = <
  TData = AsyncReturnType<typeof getUserScopes>,
  TError = GetUserRolesQueryError,
>(options?: {
  query?: UseQueryOptions<AsyncReturnType<typeof getUserScopes>, TError, TData>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options || {};

  const queryKey = queryOptions?.queryKey ?? getUserScopesQueryKey();
  const queryFn: QueryFunction<AsyncReturnType<typeof getUserScopes>> = async () => getUserScopes();
  const result = useQuery<AsyncReturnType<typeof getUserScopes>, TError, TData>(queryKey, queryFn, {
    ...queryOptions,
  });

  return {
    queryKey,
    ...result,
  };
};
