import {
  Iso,
  Ccpa,
  Nist,
  Saif,
  Owasp,
  Dasf,
  ScfGdrp,
  Ofdss,
  Dsmm,
  Soc,
  Fedramp,
  Microsoft,
  Csa,
  BsiC5,
  Irap,
  SpanishEns,
  PciDss,
  Hipaa,
  NydFs,
  Generic2,
} from '@bigid-ui/icons';
import { uniqBy, orderBy } from 'lodash';
import { ControlsProps, FrameworkProps, FrameworkUpdatedProps, IconsShcemaProps } from './FrameworksServices';

const detectValueWithBrackets = (value: string) => {
  const regex1 = /^([\w\-\.]+)/g;
  const regex2 = /(\s\(?\w{1}\)?\s)/g;
  const replacePattern = /^([A-Za-z]*\-*)/g;
  const firstWord = value.match(regex1);
  const secondWord = value.match(regex2);
  const result = [firstWord, secondWord].join('').replace(replacePattern, '');
  return result;
};

const detectSortingValue = (value: string) => {
  if (typeof value === 'number') {
    return value;
  } else {
    switch (true) {
      case typeof value === 'string':
        return isNaN(parseFloat(value)) ? value : parseFloat(value);
      default:
        return value;
    }
  }
};

export function comparator(field: string) {
  return function (a: any, b: any) {
    if (detectSortingValue(a[field]) < detectSortingValue(b[field])) {
      return -1;
    }
    if (detectSortingValue(a[field]) > detectSortingValue(b[field])) {
      return 1;
    }
    return 0;
  };
}

const calculationgAmountOfControls = (data: FrameworkProps[]) => {
  let amountOfControls = 0;
  data.map((item: FrameworkProps) => {
    amountOfControls += item.list.length;
  });
  return amountOfControls;
};

export const formatFrameworkControls = (frameworkControls: FrameworkProps[]): FrameworkUpdatedProps => {
  const uniqueFrameworks = uniqBy(frameworkControls[0].controls, 'category');
  const frameworks = uniqueFrameworks.map((framework: FrameworkProps) => {
    const list = [
      ...frameworkControls[0].controls.filter((control: ControlsProps) => control.category === framework.category),
    ];
    const controlsSorted = orderBy(list, ['name']);
    return {
      ...framework,
      list: controlsSorted,
    };
  });
  const sortedFrameworks: FrameworkProps[] = frameworks.sort(comparator('category'));
  return { data: sortedFrameworks, amountOfControls: calculationgAmountOfControls(sortedFrameworks) };
};

export const filterFrameworkControls = (frameworkControls: FrameworkProps[], value: string) => {
  const result = frameworkControls.map((listControls: FrameworkProps) => ({
    ...listControls,
    list: listControls.list.filter((control: ControlsProps) =>
      control.name.toLowerCase().includes(value.toLowerCase()),
    ),
  }));
  const filteredResults = result.filter((filteredRes: FrameworkProps) => filteredRes.list.length > 0);
  return {
    data: filteredResults,
    amountOfControls: calculationgAmountOfControls(filteredResults),
  };
};

export const iconsSchema: IconsShcemaProps = {
  ['ISO-IEC 27001 2022']: Iso,
  ['ISO-IEC 27001']: Iso,
  ['CCPA']: Ccpa,
  ['ISO-IEC 27017']: Iso,
  ['ISO-IEC 27018']: Iso,
  ['ISO-IEC 27701']: Iso,
  ['NIST Privacy Framework']: Nist,
  ['NIST CSF 2.0']: Nist,
  ['NIST CSF 1.0']: Nist,
  ['NIST 800-171']: Nist,
  ['NIST AI RMF 1.0']: Nist,
  ["SAIF (Google's Secure AI Framework)"]: Saif,
  ['DSMM (Data Security Maturity Model)']: Dsmm,
  ['OWASP']: Owasp,
  ['DASF (Databricks AI Security Framework)']: Dasf,
  ['SCF - EU GDPR']: ScfGdrp,
  ['Open Finance DSS']: Ofdss,
  ['NYDFS Part 500']: NydFs,
  ['SOC 1']: Soc,
  ['NIST SSDF v1.1']: Nist,
  ['ISO 42001']: Iso,
  ['FedRAMP Rev 5 - Mod']: Fedramp,
  ['FedRAMP Rev 5 - Low']: Fedramp,
  ['FedRAMP Rev 5 - High']: Fedramp,
  ['Micorsoft SSPA DPR']: Microsoft,
  ['CSA STAR']: Csa,
  ['CSA CoC - GDPR']: Csa,
  ['BSI C5']: BsiC5,
  ['iRAP']: Irap,
  ['Spanish ENS']: SpanishEns,
  ['Microsoft SSPA DPR']: Microsoft,
  ['PCI-DSS v3.2.1']: PciDss,
  ['HIPAA']: Hipaa,
  ['PCI-DSS v4.0']: PciDss,
  ['SOC 2']: Soc,
};

export const frameworkIconGenerator = (name: string) => {
  return iconsSchema[name] || Generic2;
};
