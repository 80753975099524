import { EventEmitter } from '@bigid-ui/utils';

export enum HeaderEvents {
  UPDATE_UNREAD_TASKS = 'callToGetNumberOfUnreadTasks',
  UPDATE_UNREAD_TASKS_AND_RISK = 'callToGetNumberOfUnreadTasksAndRiskScore',
  SET_TOTAL_RECORDS_AND_RISK = 'setTotalRecordsAndRisk',
  UPDATE_NOTIFICATIONS = 'headerUpdateNotifications',
  UPDATE_CLOUD_SUBSCRIPTIONS = 'headerUpdateCloudSubscriptions',
  HEADER_HIDE = 'headerHide',
  SHOW_BIGCHAT_IN_HEADER = 'showBigChatInHeader',
  SHOW_NAVIGATION_GUIDED_TOUR = 'showNavigationGuidedTour',
}

export const headerEventEmitter = new EventEmitter<HeaderEvents>();
