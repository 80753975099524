import React, { FC, Fragment, useEffect, useState } from 'react';
import { $stateParams } from '../../services/angularServices';
import { AppInfo, CustomAppPagesEnum } from './utils/CustomAppTypes';
import { withApplicationForm } from './ApplicationForm';
import { EditCustomApp } from './views/EditCustomApp/EditCustomApp';
import { CustomAppPageWrapper } from './CustomAppPageWrapper';
import { setCustomAppInfo } from './utils/CustomAppUtils';
import { isEmpty } from 'lodash';

export const CustomAppEditPage: FC = () => {
  const [appInfo, setAppInfo] = useState<AppInfo>(null);

  useEffect(() => {
    const updateAppInfo = async () => {
      if (appInfo) return;
      setCustomAppInfo($stateParams.appInfo, $stateParams.id, setAppInfo);
    };
    updateAppInfo();
  }, [appInfo]);

  return (
    <CustomAppPageWrapper appInfo={appInfo} isReady={!isEmpty(appInfo)}>
      {withApplicationForm(EditCustomApp, 'Edit App', appInfo)}
    </CustomAppPageWrapper>
  );
};
