import React, { FC, useMemo, useState } from 'react';
import {
  BigidGrid,
  BigidGridProps,
  BigidGridColumn,
  BigidGridColumnTypes,
  useFetch,
  BigidGridRow,
} from '@bigid-ui/grid';

interface DataSourceImportMandatoryFields {
  itOwner: string;
  name: string;
  type: string;
}

export interface FailedImportLine {
  id: number;
  csvLineObject: DataSourceImportMandatoryFields;
  line: number;
  notValidFields: Record<string, string>;
}

export type DataSourceFailedColumnsModel = BigidGridRow & FailedImportLine;

export const importDataSourceFailedColumns: BigidGridColumn<DataSourceFailedColumnsModel>[] = [
  {
    title: 'Data Source Name',
    name: 'name',
    width: 170,
    type: BigidGridColumnTypes.TEXT,
    filteringEnabled: false,
    sortingEnabled: true,
    getCellValue: ({ csvLineObject: { name } }) => name,
  },
  {
    title: 'Column Line',
    name: 'line',
    width: 110,
    type: BigidGridColumnTypes.TEXT,
    filteringEnabled: false,
    sortingEnabled: true,
    getCellValue: ({ line }) => line,
  },
  {
    title: 'Failure Reason',
    name: 'failureReason',
    width: 210,
    type: BigidGridColumnTypes.TEXT,
    filteringEnabled: false,
    sortingEnabled: true,
    getCellValue: ({ notValidFields }) =>
      Object.values(notValidFields).reduce((acc, value) => `${acc ? `${acc}; ` : acc}${value}`, ''),
  },
];

export interface ImportDataSourceFailedGridProps {
  failedList: FailedImportLine[];
}

export const ImportDataSourceFailedGrid: FC<ImportDataSourceFailedGridProps> = ({ failedList }) => {
  const [id, setId] = useState(1);

  const useFetchState = useFetch({
    id,
    pageSize: 400,
    fetchDataFunction: async () => {
      const data = failedList.map(item => ({ ...item, id: item.line }));
      return {
        data,
        totalCount: data.length,
      };
    },
  });

  const gridConfiguration: BigidGridProps<FailedImportLine> = useMemo(
    () => ({
      columns: importDataSourceFailedColumns,
      showFilteringControls: false,
      showSortingControls: false,
      pageSize: useFetchState.pageSize,
      rows: useFetchState.rows as FailedImportLine[],
      skip: useFetchState.skip,
      onPagingChanged: useFetchState.onPagingChanged,
      onSortingChanged: useFetchState.onSortingChanged,
      totalRowsCount: useFetchState.totalRowsCount,
      onFiltersChange: useFetchState.onFiltersChanged,
    }),
    [useFetchState],
  );

  return <BigidGrid {...gridConfiguration} />;
};
