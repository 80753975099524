import { BigidFormField } from "@bigid-ui/components";
import { HIDDEN_FIELDS_OVERRIDE } from "../../DataSourceConnectionModal/constants/constants";
import { DsConnectionFieldSectionsEnum } from "../types";

// @info show hidden new connectivity fields in details section
export const mapHiddenFieldsToDetailsSection = (field: BigidFormField): BigidFormField => {
    const isHidden = HIDDEN_FIELDS_OVERRIDE.includes(field.name);
    return {
      ...field,
      misc: { ...field?.misc, section: isHidden ? DsConnectionFieldSectionsEnum.details : field?.misc?.section },
    };
  };