import React, { FC, useEffect, useState } from 'react';
import { BigidColors, EntityEvents, entityEventsEmitter, ToolbarAction } from '@bigid-ui/components';
import { BigidGridColumn, BigidGridColumnTypes, BigidGridProps, FetchDataFunction } from '@bigid-ui/grid';
import {
  BigidLayout,
  BigidLayoutConfig,
  BigidLayoutEmptyState,
  BigidMasterDetailsContentProps,
  LayoutContentType,
} from '@bigid-ui/layout';
import makeStyles from '@mui/styles/makeStyles';
import { ActionType, deleteLabel, getAllLabels, LabelConfiguration } from './LabelConfigurationService';
import { LabelDetails } from './LabelDetails';
import { LabelDialogProps, NewLabelDialog } from './NewLabelDialog';
import { $state } from '../../../../services/angularServices';
import DeleteOutlineRoundedIcon from '@mui/icons-material/DeleteOutlineRounded';
import { pageHeaderService } from '../../../../../common/services/pageHeaderService';
import { isPermitted } from '../../../../services/userPermissionsService';
import { APPLICATIONS_PERMISSIONS } from '@bigid/permissions';
import { BigidNoDataIllustration, BigidDuplicateIcon } from '@bigid-ui/icons';

export interface LabelConfigurationRecord extends LabelConfiguration {
  id: string;
  name: string;
}

const NAME_COLUMN_WIDTH = 250;

const useStyles = makeStyles({
  contentContainer: {
    display: 'flex',
    overflow: 'hidden',
    borderRadius: '4px 4px 0px 0px',
    boxShadow: BigidColors.containerShadow,
    flexFlow: 'column nowrap',
    flex: '1 1 auto',
  },
  noPadding: {
    padding: 0,
  },
});

const columns: BigidGridColumn<LabelConfigurationRecord>[] = [
  {
    width: NAME_COLUMN_WIDTH,
    title: 'Access Type Name',
    name: 'name',
    isListColumn: true,
    type: BigidGridColumnTypes.TEXT,
    getCellValue: ({ name }) => name,
  },
];

export const LabelsConfigurations: FC = () => {
  const [dialogState, setDialogState] = useState<
    Pick<LabelDialogProps, 'isOpen' | 'actionType' | 'labelId' | 'onActionSuccess'>
  >({
    onActionSuccess: null,
    isOpen: false,
    actionType: 'Add',
    labelId: '',
  });
  const [labelsNames, setLabelsNames] = useState<string[]>([]);

  async function openNewLabelDialog(action: ActionType, labelId = ''): Promise<boolean> {
    return new Promise(resolve => {
      setDialogState({
        isOpen: true,
        labelId,
        actionType: action,
        onActionSuccess: (labelName: string) => {
          resolve(true);
          setDialogState({ onActionSuccess: null, isOpen: false, actionType: 'Add', labelId: '' });
          if (labelName !== $state.params.labelName) {
            $state.go('.', { labelName }, { reload: false, notify: false });
          }
          entityEventsEmitter.emit(EntityEvents.RELOAD);
        },
      });
    });
  }

  const classes = useStyles({});

  const emptyStateActions = [
    {
      execute: async () => {
        const actionResult = await openNewLabelDialog('Add');
        if (actionResult) {
          entityEventsEmitter.emit(EntityEvents.RELOAD);
          return { shouldGridReload: true, shouldClearSelection: true };
        }
        return { shouldGridReload: false, shouldClearSelection: false };
      },
      label: 'Add new access type',
      show: () => true,
    },
  ];

  const masterDetailsConfig: BigidMasterDetailsContentProps = {
    isPersistentListMode: true,
    setSelectedItemInFetch: true,
    placeholderComponent: (
      <BigidLayoutEmptyState
        actions={emptyStateActions}
        illustration={BigidNoDataIllustration}
        description="Select an access type or add a new"
      />
    ),
    tabsAndContent: {
      hideTabs: true,
      classes: {
        contentContainer: classes.contentContainer,
      },
      tabProps: {
        tabs: [
          {
            label: 'Labels',
            data: {
              component: LabelDetails,
            },
          },
        ],
      },
    },
  };

  const gridConfig: BigidGridProps<LabelConfigurationRecord> = {
    showSelectionColumn: false,
    columns,
  };

  const fetchGridData: FetchDataFunction<LabelConfigurationRecord> = async (query, { setSelectedItem }) => {
    const data = await getAllLabels();
    const labels: LabelConfigurationRecord[] = data?.map(label => ({
      ...label,
      id: label?._id,
      name: label?.label_name,
    }));

    setLabelsNames(labels.map(l => l.label_name));

    if ($state.params.labelName) {
      const { labelName } = $state.params;
      const selectedItem = labels?.find(({ label_name }) => label_name === labelName);
      setSelectedItem(selectedItem);
    } else {
      const labelName = labels?.[0]?.label_name;
      setSelectedItem(labels?.[0]);
    }

    return {
      totalCount: labels.length,
      data: labels || [],
    };
  };

  const toolbarActions: ToolbarAction[] = [
    {
      label: 'Add New Access Type',
      isGlobal: true,
      execute: async () => {
        const actionResult = await openNewLabelDialog('Add');
        if (actionResult) {
          return { shouldGridReload: true, shouldClearSelection: true };
        }
        return { shouldGridReload: false, shouldClearSelection: false };
      },
      show: () => isPermitted(APPLICATIONS_PERMISSIONS.CREATE_ACCESS_TYPES_IN_ACCESS_INTELLIGENCE.name),
    },
    {
      label: 'Delete',
      icon: DeleteOutlineRoundedIcon,
      execute: async ({ selectedRows }) => {
        try {
          const { label_name } = selectedRows[0];
          await deleteLabel(label_name);
          return { shouldGridReload: true, shouldClearSelection: true };
        } catch (err) {
          console.log('error wile trying to delete label');
        }
      },
      show: ({ selectedRowIds }) => {
        return (
          selectedRowIds.length > 0 &&
          isPermitted(APPLICATIONS_PERMISSIONS.DELETE_ACCESS_TYPES_IN_ACCESS_INTELLIGENCE.name)
        );
      },
    },
    {
      label: 'Duplicate',
      icon: BigidDuplicateIcon,
      execute: async ({ selectedRows }) => {
        const { label_name } = selectedRows[0];
        const actionResult = await openNewLabelDialog('Duplicate', label_name);
        if (actionResult) {
          return { shouldGridReload: true, shouldClearSelection: true };
        }
        return { shouldGridReload: false, shouldClearSelection: false };
      },
      show: ({ selectedRowIds }) => {
        return (
          selectedRowIds.length > 0 &&
          isPermitted(APPLICATIONS_PERMISSIONS.CREATE_ACCESS_TYPES_IN_ACCESS_INTELLIGENCE.name)
        );
      },
    },
  ];

  const layoutConfig: BigidLayoutConfig = {
    content: {
      entityName: 'Access Types',
      toolbarActions,
      noPadding: true,
      contentTypes: [LayoutContentType.MASTER_DETAILS],
      viewConfig: {
        fetchGridData,
        gridConfig,
        masterDetailsConfig,
      },
    },
  };

  const onCloseDialog = () => setDialogState({ onActionSuccess: null, isOpen: false, actionType: 'Add', labelId: '' });

  useEffect(() => {
    pageHeaderService.setTitle({
      pageTitle: 'Settings',
      showBackButton: true,
      defaultFrom: {
        state: 'accessGovernance',
        params: { selectedTab: 'users' },
      },
    });
  }, []);

  return (
    <React.Fragment>
      <BigidLayout config={layoutConfig} />
      <NewLabelDialog {...dialogState} onClose={onCloseDialog} labelsNames={labelsNames} />
    </React.Fragment>
  );
};
