import React, { FC, useMemo, useState } from 'react';
import { BigidDialog, BigidDialogProps } from '@bigid-ui/components';
import { QueryClientProvider } from 'react-query';
import { AutoDiscoveryCollaborationModal } from './AutoDiscoveryCollaborationModal';
import { getQueryClient } from '../../../config/query';
import { AutoDiscoveryWizardContextState } from '../AutoDiscoveryWizard/autoDiscoveryWizardContext';
import { useLocalTranslation } from '../translations';
import makeStyles from '@mui/styles/makeStyles';

const queryClient = getQueryClient();

const useStyles = makeStyles({
  dialogPaper: {
    maxWidth: '458px',
  },
});

export interface AutoDiscoveryCollaborationProps {
  discoveryConfigDataRef: React.MutableRefObject<AutoDiscoveryWizardContextState>;
  button: FC<any>;
  message?: string;
}

export const AutoDiscoveryCollaboration: FC<AutoDiscoveryCollaborationProps> = ({
  button: Button,
  discoveryConfigDataRef,
  message,
}) => {
  const { t } = useLocalTranslation('AutoDiscovery');
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const classes = useStyles();

  const dialogConfig = useMemo(
    () => ({
      muiOverrides: {
        classes: { paper: classes.dialogPaper },
      },
      title: t('collaborateModal.mainTitle'),
      maxWidth: 'xs' as BigidDialogProps['maxWidth'],
      isOpen,
      onClose: () => setIsOpen(false),
      buttons: [],
    }),
    [isOpen, t],
  );

  return (
    <QueryClientProvider client={queryClient}>
      <Button onClick={() => setIsOpen(true)} />
      <BigidDialog {...dialogConfig}>
        <AutoDiscoveryCollaborationModal message={message} discoveryConfigDataRef={discoveryConfigDataRef} />
      </BigidDialog>
    </QueryClientProvider>
  );
};
