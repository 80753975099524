import { module } from 'angular';
import './attributeItems.component.scss';
import template from './attributeItems.component.html';
const app = module('app');

app.component('attributeItems', {
  template,
  controller: [
    'mentionsTextareaService',
    'notificationService',
    function (mentionsTextareaService, notificationService) {
      const addNewText = 'Add New';

      this.allAtrributes = [];
      this.excludedAttributes = [];
      this.allCategories = [];
      this.purposesFromGlossary = [];
      this.attributesFromGlossary = [];
      this.numbersOfExcludedAttributes = 0;
      this.isExpanded = false;
      this.isExcludedChecked = false;
      this.numberOfExcluded = 0;

      /* Start LifeCycle Hooks  */

      this.$onInit = () => {
        this.allAtrributes = this.attributes;
        this.allCategories = this.categories;
        getPurposes();
        getAttributes();
      };

      this.$onChanges = () => {
        this.isExcludedChecked = false;
        setAttributes();
        setCategories();
      };

      /* End LifeCycle Hooks  */

      /* Start Attributes Methods  */

      const setAttributes = () => {
        this.attributes = [];
        let attributesMap = null;
        if (this.entity && this.entity.attributes) {
          if (this.entity.status && this.entity.status === 'resolved') {
            this.disabledItems = true;
          } else {
            this.disabledItems = false;
          }
          attributesMap = new Map();
          //set all the custom attributes
          this.entity.attributes.map(attr => {
            const attributeFromFields_System =
              this.entity.fields && this.entity.fields.find(item => item.fieldName === attr.attribute);
            if (attributeFromFields_System && attributeFromFields_System.is_fromSystem) {
              attr.is_fromSystem = attributeFromFields_System.is_fromSystem;
            } else {
              const attributeAlsoFromSystem =
                this.entity.attributesFromSystem &&
                this.entity.attributesFromSystem.find(item => item.friendly_name === attr.attribute);
              attr.is_fromSystem = attributeAlsoFromSystem && attributeAlsoFromSystem._id ? true : false;
            }
            attributesMap.set(attr.attribute, attr);
          });
          if (this.entity.attributesFromSystem) {
            const attributesFromSystem = this.entity.attributesFromSystem.map(item => ({
              attribute: item.friendly_name,
              item,
            }));
            //add attributes from system if they are not exist in the custom attributes
            attributesFromSystem.forEach(item => {
              item.is_fromSystem = true;
              if (!attributesMap.has(item.attribute)) {
                attributesMap.set(item.attribute, item);
              }
            });
          }
        }
        //if there is only attributes from the system
        else if (this.entity && this.entity.attributesFromSystem) {
          attributesMap = new Map();
          this.entity.attributesFromSystem.map(attr => {
            attr.is_fromSystem = true;
            attributesMap.set(attr.friendly_name, attr);
          });
        }

        if (attributesMap) {
          this.attributes = [...attributesMap.values()];
          this.excludedAttributes = this.attributes.filter(attribute => attribute.is_excluded === true);
          this.excludedAttributes = setAttributesByAlphabeticOrder(this.excludedAttributes);
          setNumberOfExcludedAttributes();
          this.allAtrributes = this.attributes;
          //remove the attributes that are excluded
          this.attributes = this.attributes.filter(attribute => attribute.is_excluded !== true);
        }

        if (this.attributes && this.attributes.length > 0) {
          this.selectedAttribute = this.attributes[0];
        } else {
          this.selectedAttribute = {};
        }
        this.attributes = setAttributesByAlphabeticOrder(this.attributes);
        initAddNewField(addNewText);
      };

      const setAttributesByAlphabeticOrder = attributes => {
        let result = [];

        if (attributes && attributes.length > 0) {
          const attributesFromSystem = attributes.filter(item => item.is_fromSystem);
          attributesFromSystem.sort((a, b) => a.attribute.localeCompare(b.attribute));
          const customAttributes = attributes.filter(item => !item.is_fromSystem);
          customAttributes.sort((a, b) => a.attribute.localeCompare(b.attribute));
          result = [...attributesFromSystem, ...customAttributes];
        }

        return result;
      };

      const initAddNewField = addNewText => (this.newAttributeName = addNewText);

      const setCurrentAttribute = currentAttributeName => {
        if (this.attributes && this.attributes.length > 0) {
          const attribute = this.attributes.find(item => item.attribute === currentAttributeName);
          if (attribute) {
            attribute.purpose = this.selectedAttribute.purpose === undefined ? '' : this.selectedAttribute.purpose;
            attribute.is_excluded = this.isExcludedChecked;
          } else if (!this.isExcludedAttribute(currentAttributeName)) {
            addNewAttribute(currentAttributeName);
          } else {
            this.isExcludedChecked = true;
          }
        } else {
          addNewAttribute(currentAttributeName);
        }
      };

      this.isExcludedAttribute = currentAttributeName => {
        if (this.excludedAttributes) {
          const excludedAtt = this.excludedAttributes.find(exclude => exclude.attribute === currentAttributeName);
          if (excludedAtt) {
            return true;
          }
        }
        return false;
      };

      const addNewAttribute = attributeName => {
        if (!this.attributes) {
          this.attributes = [];
        }
        this.attributes = [
          ...this.attributes,
          {
            attribute: attributeName,
            purpose: this.selectedAttribute.purpose === undefined ? '' : this.selectedAttribute.purpose,
            is_excluded: this.isExcludedChecked,
          },
        ];
        this.attributes = setAttributesByAlphabeticOrder(this.attributes);
      };

      const saveAttribute = attributeName => {
        const currentAttributeName = attributeName ? attributeName : this.newAttributeName;
        setCurrentAttribute(currentAttributeName);
        this.selectedAttribute = this.selectedAttribute
          ? this.selectedAttribute
          : this.attributes && this.attributes.length > 0
          ? this.attributes[this.attributes.length - 1]
          : null;

        const attributesWithExcludedAttributes = [...this.attributes, ...this.excludedAttributes];
        this.onAttributeChanged({ attributes: attributesWithExcludedAttributes });

        addNewObjectToAttributeArray(currentAttributeName);
        initAddNewField(addNewText);
        this.onSaveAttributePurposeChanged({ CloseSideEntityWindow: false, message: 'Attribute field was Inserted!' });
      };

      const addNewObjectToAttributeArray = attributeName => {
        if (attributeName) {
          if (this.entity && this.entity.attributes) {
            const existAttribute = this.entity.attributes.find(attribute => attribute.attribute == attributeName);
            if (!existAttribute) {
              addNewAttributeToEntity(attributeName);
            } else {
              existAttribute.is_excluded = this.isExcludedChecked;
              existAttribute.attribute_id = getAttributeID(existAttribute.attribute);
              existAttribute.purpose = existAttribute.purpose || '';
              existAttribute.purpose_id = existAttribute.purpose_id || '';
            }
          } else {
            this.entity.attributes = [];
            addNewAttributeToEntity(attributeName);
          }
        }
      };

      const addNewAttributeToEntity = attributeName => {
        this.entity.attributes.push({
          attribute: attributeName,
          attribute_id: getAttributeID(this.selectedAttribute.attribute),
          purpose: this.selectedAttribute.purpose ? this.selectedAttribute.purpose : '',
          purpose_id: getPurposeID(this.selectedAttribute.purpose ? this.selectedAttribute.purpose : ''),
          is_excluded: this.isExcludedChecked,
          is_fromSystem: this.selectedAttribute.is_fromSystem,
        });
      };

      const getPurposeID = purposeText => {
        let purposeID = '';
        if (this.purposesFromGlossary) {
          const purposeItem = this.purposesFromGlossary.find(
            purposeGlossaryItem => purposeGlossaryItem.name === purposeText,
          );
          if (purposeItem) {
            purposeID = purposeItem.glossary_id;
          }
        }
        return purposeID;
      };

      const getAttributeID = attributeText => {
        if (this.selectedAttribute && this.selectedAttribute.item && this.selectedAttribute.item.glossary_id != null) {
          return this.selectedAttribute.item.glossary_id;
        }
        let attributeID = '';
        if (this.attributesFromGlossary) {
          const attributeItem = this.attributesFromGlossary.find(
            attributeGlossaryItem => attributeGlossaryItem.id === attributeText,
          );
          if (attributeItem) {
            attributeID = attributeItem.glossary_id;
          }
        }
        return attributeID;
      };

      this.searchValueByKeyPress = (event, value) => {
        if (event.keyCode === 8) {
          if (value.length > 0) {
            this.searchValue = value;
          } else {
            this.searchValue = '';
          }
        } else {
          this.searchValue = value;
        }
        this.findAllValuesRelatedToSearchValue(this.searchValue);
      };

      this.findAllValuesRelatedToSearchValue = searchValue => {
        if (searchValue === '') {
          this.attributes = this.allAtrributes;
          this.categories = this.allCategories;
        } else {
          this.attributes = this.allAtrributes.filter(item =>
            item.attribute.toUpperCase().includes(searchValue.toUpperCase()),
          );
          this.categories = this.allCategories.filter(item =>
            item.category.toUpperCase().includes(searchValue.toUpperCase()),
          );
        }
      };

      this.attributeObjectClicked = attribute => {
        this.isExcludedChecked = false;
        this.setAttributeClickedFields(attribute);
      };

      this.setAttributeClickedFields = attribute => {
        this.selectedAttribute = attribute;
        this.selectedCategory = null;
        this.isReadOnly = true;
      };

      this.attributeExcludedObjectClicked = attribute => {
        this.isExcludedChecked = attribute.is_excluded;
        this.setAttributeClickedFields(attribute);
      };

      this.attributeObjectExcludedDoubleClicked = attribute => {
        this.isExcludedChecked = attribute.is_excluded;
        this.setAttributeDoubleClickedFields(attribute);
      };

      this.setAttributeDoubleClickedFields = attribute => {
        this.selectedAttribute = attribute;
        this.selectedAttribute = null;
        if (this.entity.attributes.map(item => item.attribute).indexOf(attribute.attribute) > -1) {
          this.isReadOnly = false;
        }
      };

      this.attributeObjectDoubleClicked = attribute => {
        this.isExcludedChecked = false;
        this.setAttributeDoubleClickedFields(attribute);
      };

      this.getWidth = attribute => {
        if (attribute) {
          const length = attribute.length * 8;
          return length < 40 ? 40 : length;
        }
      };

      this.removeAttributeFromList = attribute => {
        if (this.entity.status && this.entity.status === 'resolved') {
          return;
        }

        this.excludedAttributes = this.excludedAttributes.filter(element => element.attribute !== attribute.attribute);
        this.attributes = this.attributes.filter(element => element.attribute !== attribute.attribute);
        this.entity.attributes = this.entity.attributes.filter(attr => attr.attribute !== attribute.attribute);
        const attributesWithExcludedAttributes = [...this.attributes, ...this.excludedAttributes];
        this.onAttributeChanged({ attributes: attributesWithExcludedAttributes });
        this.onSaveAttributePurposeChanged({ CloseSideEntityWindow: false, message: 'Attribute field was Removed!' });
        setAttributes();
      };

      this.onAttributeValueFieldClicked = () => {
        this.isExcludedChecked = false;
        this.selectedAttribute = {};
        this.isReadOnly = false;
        initAddNewField('');
      };

      this.addNewAttributeToList = (event, attribute) => {
        if (event.keyCode === 13) {
          const activeElement = document.activeElement;
          if (activeElement) {
            activeElement.blur();
          }

          if (!attribute) {
            if (this.newAttributeName && this.newAttributeName.length > 0 && this.newAttributeName !== addNewText) {
              saveAttribute();
            }
          } else {
            if (attribute.attribute && attribute.attribute.length > 0) {
              initAddNewField(addNewText);
            }
          }
        }
      };

      this.changeAttributeValueField = attribute => {
        if (attribute == undefined) {
          if (this.newAttributeName && this.newAttributeName.length > 0 && this.newAttributeName !== addNewText)
            saveAttribute();
          else initAddNewField(addNewText);
        } else {
          this.onSaveAttributePurposeChanged({ CloseSideEntityWindow: false, message: 'Attribute field was Updated!' });
        }
        this.attributes = setAttributesByAlphabeticOrder(this.attributes);
      };

      this.onAttributeValueFieldChanged = value => {
        this.newAttributeName = value;
      };

      this.onAttributeValueFieldBlurred = () => {
        if (this.newAttributeName && this.newAttributeName.length > 0 && this.newAttributeName !== addNewText) {
          saveAttribute(this.newAttributeName);
        } else {
          initAddNewField(addNewText);
        }
      };

      /* End Attributes Methods  */

      /* Start Common Methods */

      const getPurposes = () => {
        mentionsTextareaService.getPurposes().then(
          result => {
            this.purposesFromGlossary = result.data;
          },
          () => {
            notificationService.error('An error has occurred!');
          },
        );
      };

      const getAttributes = () => {
        mentionsTextareaService.getAttributes().then(
          result => {
            this.attributesFromGlossary = result.data.attribute_risks;
          },
          () => {
            notificationService.error('An error has occurred!');
          },
        );
      };

      this.onPurposeTextareaChanged = value => {
        const type = this.selectedCategory ? 'selectedCategory' : 'selectedAttribute';
        this[type].purpose = value ? value.replace(/[\r\n\v]+/g, '') : '';
      };

      this.onPurposeTextareaBlurred = event => {
        if (this.selectedAttribute) {
          const existingAttribute = this.entity.attributes?.find(
            attribute => this.selectedAttribute.attribute === attribute.attribute,
          );
          //if it's a attribute with purpose that isn't exist in the list
          if (existingAttribute !== undefined && this.selectedAttribute.purpose) {
            if (existingAttribute) {
              existingAttribute.purpose_id = getPurposeID(this.selectedAttribute.purpose);
              existingAttribute.purpose = this.selectedAttribute.purpose;
            } else {
              addNewObjectToAttributeArray(this.selectedAttribute.attribute);
            }
          } else {
            addNewObjectToAttributeArray(this.selectedAttribute.attribute);
          }
          this.onSaveAttributePurposeChanged({ CloseSideEntityWindow: false, message: 'Purpose field was Updated!' });
          if (this.entity && this.entity.attributes) {
            this.onAttributeChanged({ attributes: this.entity.attributes });
          }
          this.toBeRefreshed = 'refreshPurposes';
        } else if (this.selectedCategory) {
          if (!this.entity.categories) {
            this.entity.categories = [];
          }
          const existingAttribute =
            this.entity.categories.length > 0 &&
            this.entity.categories.find(category => this.selectedCategory.category === category.category);
          if (existingAttribute) {
            existingAttribute.purpose = this.selectedCategory.purpose;
          } else {
            this.entity.categories.push({
              category: this.selectedCategory.category,
              purpose: this.selectedCategory.purpose,
              category_id: this.selectedCategory.item.glossary_id,
            });
          }

          this.onSaveAttributePurposeChanged({ CloseSideEntityWindow: false, message: 'Purpose field was Updated!' });
          if (this.entity && this.entity.categories) {
            this.onCategoryChanged({ categories: this.entity.categories });
          }
          this.toBeRefreshed = 'refreshPurposes';
        }
        this.selectedCategory ? setCategories() : setAttributes();
      };

      /* End Common Methods */

      /* Start Categories Methods */

      const setCategories = () => {
        this.categories = [];
        let categoriesMap = null;

        if (this.entity && this.entity.categories) {
          categoriesMap = new Map(this.entity.categories.map(attr => [attr.category, attr]));
          if (this.entity.categoriesFromSystem) {
            const categoriesFromSystem = this.entity.categoriesFromSystem.map(item => ({
              category: item.display_name,
              item,
            }));
            categoriesFromSystem.forEach(item => {
              if (!categoriesMap.has(item.category)) {
                categoriesMap.set(item.category, item);
              }
            });
          }
        } else if (this.entity && this.entity.categoriesFromSystem) {
          categoriesMap = new Map(this.entity.categoriesFromSystem.map(attr => [attr.display_name, attr]));
        }
        if (categoriesMap) {
          this.categories = [...categoriesMap.values()];
          this.categories.forEach(categoryItem => {
            if (this.entity.categoriesFromSystem) {
              const categoryFromSystem = this.entity.categoriesFromSystem.find(
                categoryFromSystem => categoryFromSystem.display_name === categoryItem.category,
              );
              if (categoryFromSystem) {
                categoryItem.attributes = categoryFromSystem.attributes;
              }
            }
          });
          this.categories.forEach(categoryItem => {
            if (this.entity.categories) {
              const category = this.entity.categories.find(category => category.category === categoryItem.category);
              if (category) {
                categoryItem.isRemovable = true;
              }
            }
          });
          this.allCategories = this.categories;
        }

        if (this.categories && this.categories.length > 0) {
          this.isCategoriesExist = true;
        } else {
          this.isCategoriesExist = false;
        }
      };

      this.categoryObjectClicked = category => {
        this.selectedCategory = category;
        this.selectedAttribute = null;
      };

      this.categoryObjectDoubleClicked = category => {
        this.selectedCategory = category;
        this.selectedAttribute = null;
      };

      this.removeCategoryFromList = category => {
        this.categories = this.categories.filter(element => element.category !== category.category);
        this.entity.categories = this.entity.categories.filter(attr => attr.category !== category.category);
        this.onSaveAttributePurposeChanged({ CloseSideEntityWindow: false, message: 'Category field was Removed!' });
        setCategories();
      };

      this.excludedToggle = () => {
        this.isExpanded = !this.isExpanded;
      };

      this.excludeAction = () => {
        if (this.selectedAttribute && this.selectedAttribute.attribute) {
          // case of remove attribute form excluded list
          if (!this.isExcludedChecked && this.selectedAttribute.is_excluded) {
            const attribute = this.entity.attributes.find(
              attribute => this.selectedAttribute.attribute === attribute.attribute,
            );
            attribute.is_excluded = false;
            //remove the selected attributes from the excluded list
            this.excludedAttributes = this.excludedAttributes.filter(
              excludedAttribute => excludedAttribute.attribute !== this.selectedAttribute.attribute,
            );
          }
          saveAttribute(this.selectedAttribute.attribute);
          setAttributes();
          this.isExcludedChecked = false;
        }
      };

      const setNumberOfExcludedAttributes = () => {
        const numberOfExcluded = this.entity.attributes?.filter(attribute => attribute.is_excluded === true);
        this.numberOfExcluded = numberOfExcluded?.length ?? 0;
      };

      /* End Categories Methods */
    },
  ],
  bindings: {
    entity: '<',
    disabled: '<',
    attributesFromSys: '<',
    categories: '<',
    categoriesFromSys: '<',
    istasklist: '<',
    onAttributeChanged: '&',
    onPurposeChanged: '&',
    onCategoryChanged: '&',
    onSaveAttributePurposeChanged: '&',
  },
});
