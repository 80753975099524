import React, { FC, memo, useRef } from 'react';
import {
  BigidFormFieldSideLabelWrapper,
  BigidFormFieldSideText,
  compareObjectsExceptFunctions,
} from '@bigid-ui/components';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles({
  wrapper: {
    display: 'inline-flex',
    width: '100%',
    alignItems: 'center',
  },
  headerWrapper: {
    display: 'inline-block',
  },
  headerTitleWrapper: {
    margin: '20px 0',
  },
});

export interface CustomHeaderFieldItem {
  field_name: string;
  is_changed?: boolean;
  field_value: string;
}

export interface FormCustomParametersFieldOneItemHeaderProps {
  onChange: (value: CustomHeaderFieldItem) => void;
  value: CustomHeaderFieldItem;
  id: string;
}

export const HeaderFields: FC<FormCustomParametersFieldOneItemHeaderProps> = memo(({ value, onChange, id }) => {
  const valueRef = useRef(value);
  valueRef.current = value;
  const { wrapper } = useStyles({});

  return (
    <BigidFormFieldSideLabelWrapper
      id={`bigid-form-field-custom-item-${id}`}
      name={`custom-field-item${id}`}
      isRequired={false}
      tooltipText=""
      noIndent
    >
      <div className={wrapper}>
        <BigidFormFieldSideText
          labelWidth={'60px'}
          value={valueRef.current?.field_name}
          label="Name"
          touched={false}
          errorIsShown={false}
          error={null}
          name={`FormCustomParametersFieldName-${id}`}
          setValue={field_name => {
            onChange({ ...valueRef.current, field_name });
          }}
          isSubmitted={false}
          isValidated={false}
          isRequired={false}
          wasSubmitted={false}
        />
        <BigidFormFieldSideText
          labelWidth={'50px'}
          value={valueRef.current?.field_value}
          label="Value"
          errorIsShown={false}
          error={null}
          name={`FormCustomParametersFieldValue-${id}`}
          setValue={field_value => {
            onChange({ ...valueRef.current, field_value });
          }}
          isSubmitted={false}
          isValidated={false}
          isRequired={false}
          wasSubmitted={false}
          touched
        />
      </div>
    </BigidFormFieldSideLabelWrapper>
  );
}, compareObjectsExceptFunctions);
