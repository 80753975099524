import React, { FC, ComponentType, HTMLAttributes } from 'react';
import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import { SvgIconProps } from '@mui/material/SvgIcon';
import { BigidColors, BigidLoader } from '@bigid-ui/components';

interface DashboardInsightsBoxProps {
  isLoading?: boolean;
  Icon: ComponentType<HTMLAttributes<SVGElement>> | ComponentType<SvgIconProps>;
  title: string;
  description: string;
  onClick?: () => void;
  key: string;
  hide?: boolean;
}

const useStyles = makeStyles((theme: Theme) => ({
  wrapper: {
    minWidth: '224px',
    maxWidth: '224px',
    minHeight: '130px',
    background: BigidColors.white,
    border: '1px solid #EBEBEB',
    borderRadius: '6px',
    position: 'relative',
    margin: '0 8px',
    alignItems: 'center',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'center',
    alignContent: 'flex-end',
    padding: '18px',
    transition: 'height 0.3s ease',
    '&:hover': {
      transition: theme.transitions.create('border', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
      borderColor: '#9F54BA',
      '& svg path': {
        stroke: '#9F54BA!important',
      },
      '& svg line, & svg circle, & svg rect': {
        stroke: '#9F54BA!important',
      },
    },
  },
  iconWrapper: {
    display: 'flex',
    '& svg path': {
      stroke: '#9F54BA!important',
    },
    '& svg line, & svg circle': {
      stroke: '#9F54BA!important',
    },
  },
  titleWrapper: {
    fontSize: '13px',
    letterSpacing: '0px',
    color: BigidColors.gray[700],
    marginTop: '16px',
    marginBottom: '4px',
    fontWeight: 600,
  },
  descriptionWrapper: {
    fontSize: '14px',
    color: BigidColors.gray[600],
    display: 'flex',
  },
}));

export const DashboardInsightsBox: FC<DashboardInsightsBoxProps> = ({
  key,
  Icon,
  title,
  hide,
  onClick,
  isLoading,
  description,
}) => {
  const { wrapper, iconWrapper, titleWrapper, descriptionWrapper } = useStyles({});
  return !hide ? (
    <div
      tabIndex={0}
      key={key}
      className={`focusable ${wrapper}`}
      role="button"
      onClick={onClick}
      data-aid={`DashboardInsightsBox-${key}`}
    >
      {isLoading ? (
        <BigidLoader />
      ) : (
        <React.Fragment>
          <div className={iconWrapper} data-aid="DashboardInsightsBoxIcon">
            <Icon />
          </div>
          <div data-aid={`DashboardInsightsBoxTitle-${title}`} className={titleWrapper}>
            {title}
          </div>
          <div data-aid="DashboardInsightsBoxDescription" className={descriptionWrapper}>
            {description}
          </div>
        </React.Fragment>
      )}
    </div>
  ) : null;
};
