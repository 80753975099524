import React, { ReactNode } from 'react';
import { BigidBody1, BigidStatusBadgeProps, BigidStatusBadgeType } from '@bigid-ui/components';
import { Theme } from '@mui/material/styles';
import { AuditTrailProp, AuditTrailPropOperation } from '../dataCatalogAuditTrailService';
import { getFixedT } from '../translations';
import { isNil } from 'lodash';

function getEventBadgeType(operation: AuditTrailPropOperation): BigidStatusBadgeType {
  switch (operation) {
    case AuditTrailPropOperation.ADDED:
      return BigidStatusBadgeType.SUCCESS;
    case AuditTrailPropOperation.MODIFIED:
      return BigidStatusBadgeType.GENERAL;
    case AuditTrailPropOperation.REMOVED:
      return BigidStatusBadgeType.ERROR;
    default:
      return BigidStatusBadgeType.PENDING;
  }
}

function getEventBadgeText(operation: AuditTrailPropOperation): string {
  const t = getFixedT('grid.event');

  switch (operation) {
    case AuditTrailPropOperation.ADDED:
      return t('added');
    case AuditTrailPropOperation.MODIFIED:
      return t('modified');
    case AuditTrailPropOperation.REMOVED:
      return t('removed');
    default:
      return operation as string;
  }
}

export function getEventCellBadgeProps(
  operation: AuditTrailPropOperation,
): Pick<BigidStatusBadgeProps, 'label' | 'type'> {
  return {
    label: getEventBadgeText(operation),
    type: getEventBadgeType(operation),
  };
}

export function getChangeTypeCellValue(prop: AuditTrailProp): string {
  const t = getFixedT('grid.changeType');

  switch (prop) {
    case AuditTrailProp.ATTRIBUTE:
      return t('attribute');
    case AuditTrailProp.COLUMN:
      return t('column');
    case AuditTrailProp.TAG:
      return t('tag');
    default:
      return prop as string;
  }
}

export function getChangeValuePreprocessed(value: string | number): string {
  if (!isNil(value)) {
    return String(value);
  }

  return value;
}

export function getValueCellComponent(value: string, theme: Theme): ReactNode {
  const t = getFixedT('grid');

  if (isNil(value)) {
    const none = t('none');
    return <BigidBody1 color={theme.vars.palette.bigid.gray500}>{none}</BigidBody1>;
  }

  return <BigidBody1>{value}</BigidBody1>;
}
