import React, { FC, useCallback, useEffect, useMemo, useRef, useState, useTransition } from 'react';
import styled from '@emotion/styled';
import { Global, css } from '@emotion/react';
import {
  BigidGridQueryComponents,
  BigidGridWithToolbar,
  BigidGridWithToolbarProps,
  NextGridState,
} from '@bigid-ui/grid';
import {
  BigidColorsV2,
  BigidFilter,
  BigidFieldFilter,
  BigidFormStateAndHandlers,
  BigidLoader,
  BigidPaper,
  BigidTabsAndContent,
  EntityEvents,
  ToolbarActionType,
  BigidFilterToolbarProps,
  TertiaryButton,
  entityEventsEmitter,
  ToolbarAction,
} from '@bigid-ui/components';
import { $state, $stateParams } from '../../../services/angularServices';
import { notificationService } from '../../../services/notificationService';
import makeStyles from '@mui/styles/makeStyles';
import { httpService } from '../../../services/httpService';
import { isPermitted } from '../../../services/userPermissionsService';
import { DATA_SOURCES_PERMISSIONS } from '@bigid/permissions';
import { useUserPreferences } from '../../../components/hooks/useUserPrefrences';
import { DataSourceModel, DataSourcesResponse, TOTAL_ROWS_THRESHOLD } from './DataSourceConnectionTypes';
import {
  FIRST_RENDER_FIELDS,
  getDataSourceConnectionDefaultColumns,
  getDataSourceConnectionFilterConfig,
} from './DataSourceConnectionGridConfiguration';
import { useSupportedDataSources } from './hooks/useSupportedDataSources';
import { useAutoDiscoveryAppStatus } from './hooks/useAutoDiscoveryAppStatus';
import { useSSEDataSourceEvents } from './hooks/useSSEDataSourceEvents';
import { pageHeaderService } from '../../../../common/services/pageHeaderService';
import { DataSourceAsyncOperationsWidget } from './DataSourceAsyncOperationsWidget';
import { StartDataSourceOnboardingButtons } from '../../../components/CreateNewDataButtons/StartDataSourceOnboardingButtons';
import {
  BigidActionIcon,
  BigidConnectionIcon,
  BigidDataSourceIllustration,
  BigidImportIcon,
  BigidDataSearchIcon,
  BigidInstantScanIcon,
  BigidScheduleIcon,
} from '@bigid-ui/icons';
import { addDataSourceNameToTypeMap } from '../../Scans/ScanCreateWizard/hooks/useUpdateSupportedScanTypesBySelectedDs';
import { getSelectedDataSourceFromStorage } from '../../Scans/ScanCreateWizard/hooks/useGetPreselectedDataSources';
import { useSupportedScanTypesForSelectedDs } from './hooks/useSupportedScanTypesForSelectedDs';
import { CONFIG } from '../../../../config/common';
import { getApplicationPreference } from '../../../services/appPreferencesService';
import { DataSourceConnectionModal } from '../DataSourceConnectionModal/DataSourceConnectionModal';
import { QueryClientProvider } from 'react-query';
import { getQueryClient } from '../../../config/query';
import { BigidLayoutEmptyState } from '@bigid-ui/layout';
import { useDataSourceTabs } from './hooks/useDataSourceTabs';
import { AutoDiscoveryGrid } from '../../AutoDiscovery/AutoDiscoveryGrid/AutoDiscoveryGrid';
import { DATA_SOURCE_CONNECTIONS_QUERY_KEY } from '../constants/newConnectionFlowConstants';
import { useInstantScanData } from './hooks/useInstantScanData';
import { DataSourceCollaborationGuidedTour } from '../DataSourceConnectionModal/DataSourceConnectionCollaboration/DataSourceCollaborationGuidedTour';

import { OnboardingAssitantLayout } from './OnboardingAssistant/OnboardingAssistantLayout';
import { useDataSourceConnectionTour } from './hooks/useDataSourceConnectionTour';
import {
  deleteClassificationFindings,
  deleteDataSources,
  deleteFindings,
  duplicate,
  exportDataSources,
  importDataSources,
  modify,
  createNewScan,
  testConnection,
  updateArchiveStatusDataSources,
  updateDataSources,
} from './actions';
import { useDataSourceImportAndTest } from './hooks/useDataSourceImport.ts';
import { useEmptyStateActions } from './hooks/useEmptyStateActions';
import { fastCheckStringArraysEqual } from '../../../utilities/dataSourcesUtils';
import { DataSourceConnectionsContext } from './DataSourceConnectionsContext';
import { requestIdleCallbackWithFallback } from '../../../utilities/requestIdleCallbackWithFallback';
import { INITIATIVE_FIELD_LABEL } from '../DataSourceSuggestedActions/constants/actions';
import { DS_ENRICHMENT_INITIATIVE_FIELD, DS_ENRICHMENT_STATUS_FIELD } from './OnboardingAssistant/constants/enrichment';
import { getGridWithToolbarFilterOverrides } from './config/grid';
import { getGridFilterOverrides, getOnboardingEventEmitter } from './OnboardingAssistant/config/assistant';
import { useLocalTranslation } from './translations';
import { useToolbar } from './hooks/useToolbar';
import { isEqual } from 'lodash';
import { usePrevious } from '../DataSourceConnectionModal/hooks/usePrevious';
import { DsConnectionFieldSectionsEnum } from '../DataSourceConfiguration/types';
import { onboardingLayoutPreferenceKey } from './OnboardingAssistant/constants/gridConstants';
import {
  isFilterContainsInitiative,
  isInitiativeInFilter,
  isMaintainCoverageActionsVisible,
  isScanPermitted,
} from './OnboardingAssistant/utils';
import { getMaxAmountOfDsPerScanFF } from '../../Scans/ScanUtils';
import { DataSourceScanScheduler } from './DataSourceScanScheduler/DataSourceScanScheduler';
import { Initiative, OnboardingEvent } from './OnboardingAssistant/types';
import { DataSourcesUITrackingEvent, trackEventDataSources } from '../DataSourcesEventTrackerUtils';
import { isOnboardingAssistantEnabled as checkOnboardingAssistantEnabled } from '../../../utilities/featureFlagUtils';
import { DataSourceScanSelectedButton } from './DataSourceScanSelectedButton';
import { getDiscoveryAppPresetFilter } from '../../AutoDiscovery/AutoDiscoveryGrid/autoDiscoveryGridUtils';
import { GRID_ID } from './constants';
import { AddDataSourceButtons } from '../../../components/CreateNewDataButtons/CreateNewDataSourceButtons';

const GlobalDataSourceConnectionModalStyles = (): JSX.Element => (
  <Global
    styles={css`
      body > div:last-child {
        z-index: 1050 !important;
      }
    `}
  />
);

const RightSideComponentWrapper = styled('div')`
  display: flex;
  gap: 8px;
  align-items: center;
`;

const TopLoaderWrapper = styled('div')`
  display: flex;
  position: absolute;
  left: 640px;
  top: 40px;
  height: 80px;
  width: 40px;
`;

const useStyles = makeStyles({
  gridWrapper: {
    width: '100%',
    display: 'flex',
    position: 'relative',
    height: '100%',
    maxHeight: '100%',
    overflow: 'hidden',
    paddingBottom: 10,
    flexDirection: 'column',
  },
  actionsButton: {
    minWidth: 135,
  },
  quickScanActionButton: {
    minWidth: 125,
    '& > div > div > div': {
      color: BigidColorsV2.gray['700'],
    },
  },
  dialogContent: {
    justifyContent: 'space-between',
    alignContent: 'space-between',
  },
  noData: {
    width: '100%',
    height: '100%',
    position: 'absolute',
  },
  tabWrapper: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
});

const queryClient = getQueryClient();
const onboardingEmitter = getOnboardingEventEmitter();

export const DataSourceConnections: FC = () => {
  const { gridWrapper, actionsButton, dialogContent, quickScanActionButton, noData, tabWrapper } = useStyles({});
  const {
    filter,
    shouldDisplayAutoDiscoveryWarning,
    shouldDisplayNewConnectionModal,
    shouldDisplayGuidedTour,
    sourceType,
    sourceId,
    dsLabel,
    nameInDocs,
  } = $stateParams;

  const [isPending, startTransition] = useTransition();
  const [isLoadingData, setLoadingData] = useState(true);
  const [selectedInitiative, setSelectedInitiative] = useState<Initiative>();
  const [showSuggestedActions, setShowSuggestedActions] = useState(false);
  const [activeFilter, setActiveFilter] = useState<BigidFilter>();
  const [dataSourceId, setDataSourceId] = useState(sourceId ?? null);
  const [dataSourcesIds, setSelectedDsIds] = useState([]);
  const [isAllSelected, setIsAllSelected] = useState(false);
  const [filterForDs, setFilterForDs] = useState([]);
  const [dsTotalCount, setDsTotalCount] = useState<number>(0);
  const [displaySchedulerDialog, setDisplaySchedulerDialog] = useState(false);
  const [showModal, setShowModal] = useState(
    () => !!(getApplicationPreference('CONNECTIVITY_EXPERIENCE_ENABLED') && shouldDisplayNewConnectionModal),
  );
  const [hasMoreThanThresholdSelected, setHasMoreThanThresholdSelected] = useState(false);
  const [initiativeRowSelectionEventReady, setInitiativeRowSelectionEventReady] = useState(false);
  const [fetchedDataSourcesNames, setFetchedDataSourcesNames] = useState<string[]>([]);

  const isNewConnectivityEnabled = !!getApplicationPreference('CONNECTIVITY_EXPERIENCE_ENABLED');
  const { t } = useLocalTranslation();
  const dataSourcesIdsRef = useRef(dataSourcesIds);
  const bulkDataSourceUpdateHandlersRef = useRef<BigidFormStateAndHandlers>();
  const bulkDataSourceFieldsToModifyRef = useRef<any>();
  const suggestedActionRef = useRef<HTMLDivElement>();
  const postChangesAsyncOpsRef = useRef(null);

  const isGridExperimentalPerformanceFeaturesEnabled = useMemo(
    () => getApplicationPreference('GRID_EXPERIMENTAL_PERFORMANCE_FEATURES_ENABLED'),
    [],
  );
  const preselectedDataSourceIds = useMemo(() => getSelectedDataSourceFromStorage(), []);

  const isOnboardingAssistantFlagEnabled = checkOnboardingAssistantEnabled();
  const prefrenceKey = isOnboardingAssistantFlagEnabled ? onboardingLayoutPreferenceKey : $state.$current.name;
  const fetchEndPoint = 'ds-connections';

  const dataSourceConnectionDefaultColumns = useMemo(
    () => getDataSourceConnectionDefaultColumns(isGridExperimentalPerformanceFeaturesEnabled),
    [isGridExperimentalPerformanceFeaturesEnabled],
  );
  const fieldsForProjection = useMemo(() => {
    const ADDITIONAL_FIELDS_FOR_ADD = ['displayName', 'discoveryAppPresetId'];
    const fieldsNames = [...dataSourceConnectionDefaultColumns.map(({ name }) => name), ...ADDITIONAL_FIELDS_FOR_ADD];
    return { fields: fieldsNames };
  }, [dataSourceConnectionDefaultColumns]);

  const { dataSourcesTypeMapping, supportedDataSources } = useSupportedDataSources();
  const [isOnChangeInProgress, setIsOnChangeInProgress] = useState(false);

  const {
    supportedScanTypesForSelectedDs,
    dataSourceNameToTypeMapRef,
    setDisabledAndArchivedDataSources,
    updateSupportedScanTypes,
  } = useSupportedScanTypesForSelectedDs(supportedDataSources, preselectedDataSourceIds);

  const { isAutoDiscoveryCompleted, isLoadingAutoDiscoveryAvailability, isAutoDiscoveryAvailable } =
    useAutoDiscoveryAppStatus(shouldDisplayAutoDiscoveryWarning);

  const { tabsAndContentConfig, selectedTabIndex, isTabs } = useDataSourceTabs();
  const isDataSourceList = !isTabs || selectedTabIndex === 0;

  const emptyStateActions = useEmptyStateActions(isAutoDiscoveryAvailable);

  const { unregisterImportHandler, onBroadCastEventFormImport, onBroadCastEventFormTestConnection } =
    useDataSourceImportAndTest({
      onSuccess: () => entityEventsEmitter.emit(EntityEvents.RELOAD, GRID_ID),
      onError: () => entityEventsEmitter.emit(EntityEvents.RELOAD, GRID_ID),
    });

  const getInitialFilterToolbarConfig = useCallback(async () => {
    const filterParsed: Record<string, any>[] = filter ? JSON.parse(decodeURIComponent(filter)) : [];

    const discoveryAppPresetFilter = getDiscoveryAppPresetFilter();
    if (discoveryAppPresetFilter) {
      filterParsed.push(discoveryAppPresetFilter);
    }

    // @info add new 'Initiative' filter for onboarding assistant
    const onboardingAssistantFilters = getGridFilterOverrides();
    const overrides = [
      {
        enabled: false,
        execute: (config: BigidFilterToolbarProps) => {
          return {
            ...config,
            filters: [...(config?.filters ?? []), ...onboardingAssistantFilters],
          };
        },
      },
    ];
    const config = await getDataSourceConnectionFilterConfig(filterParsed);
    const configWithOverrides = getGridWithToolbarFilterOverrides(
      overrides,
      config as unknown as BigidFilterToolbarProps,
    );

    return configWithOverrides as unknown as ReturnType<typeof getDataSourceConnectionFilterConfig>;
  }, [filter]);
  const { isReady, preferences, gridColumns, filterToolbarConfig, updatePreferences } = useUserPreferences({
    stateName: prefrenceKey,
    initialGridColumns: dataSourceConnectionDefaultColumns,
    getInitialFilterToolbarConfig,
  });
  const delayedRenderColumns = useMemo(
    () =>
      isGridExperimentalPerformanceFeaturesEnabled
        ? gridColumns?.filter(({ name }) => !FIRST_RENDER_FIELDS.includes(name)).map(({ name }) => name)
        : [],
    [gridColumns, isGridExperimentalPerformanceFeaturesEnabled],
  );

  const {
    activeFilters,
    actions: { updateFilters: updateToolbarFilters },
    searchTerm,
  } = useToolbar(filterToolbarConfig);

  const prevActiveFilters = usePrevious(activeFilters);
  const prevSearchTerm = usePrevious(searchTerm);

  // fixed implicit any during react 18 migration
  const handleProcessingWidgetGotCompletedOperations = useCallback((dataSourceType: any) => {
    dataSourceType?.length > 0 &&
      notificationService.success(`Auto Discovery Service Completed for ${dataSourceType}!`);
  }, []);

  const {
    guidedTourDataSourceId,
    handleSkipTour,
    handleCompleteTour,
    handleEndTour,
    handleTourStateChange,
    showGuidedTour,
  } = useDataSourceConnectionTour(shouldDisplayGuidedTour, queryClient, isLoadingData);

  const {
    updateCount,
    isScanTemplatesEnabledRef,
    oneFavoriteScanTemplate,
    supportedTemplatesRef,
    updateSupportedTemplates,
  } = useInstantScanData();

  const handleCloseSchedulerDialog = () => setDisplaySchedulerDialog(false);

  const handleShowSuggestedActions = () => setShowSuggestedActions(true);

  const handleHideSuggestedActions = (event: MouseEvent | TouchEvent) => {
    const isActionTrigger = suggestedActionRef.current.contains(event.target as any);
    !isActionTrigger && setShowSuggestedActions(false);
  };

  const handleCloseModal = useCallback(
    (
      shouldReload?: boolean,
      opts?: { wizardStep?: DsConnectionFieldSectionsEnum; sourceId: string; navigateToEditPage?: boolean },
    ) => {
      setShowModal(false);

      const { wizardStep, sourceId, navigateToEditPage } = opts ?? {};

      if (shouldReload && navigateToEditPage) {
        $state.go(CONFIG.states.CONFIG_DATA_SOURCE, {
          wizardStep,
          id: sourceId,
        });

        return;
      }

      $state.go(
        CONFIG.states.DATA_SOURCE_CONNECTIONS,
        {
          shouldDisplayNewConnectionModal: false,
          sourceType: null,
        },
        { reload: true },
      );
    },
    [],
  );

  const loadFavoriteTemplates = useCallback(async () => {
    if (postChangesAsyncOpsRef.current) {
      await postChangesAsyncOpsRef.current;
    }
    return supportedTemplatesRef.current;
  }, []);

  const handleIsShowInlineNotification = (nextGridState: NextGridState) => {
    let exceedsMaxDsPerScan;
    const MAX_DS_PER_SCAN = getMaxAmountOfDsPerScanFF();
    const { allSelected, selectedRowIds } = nextGridState;
    if (allSelected) {
      exceedsMaxDsPerScan = dsTotalCount > MAX_DS_PER_SCAN;
      setHasMoreThanThresholdSelected(exceedsMaxDsPerScan);
      return;
    }

    exceedsMaxDsPerScan = selectedRowIds.length > MAX_DS_PER_SCAN;
    setHasMoreThanThresholdSelected(exceedsMaxDsPerScan);
  };

  const dsOnBoardingAssistantToolBarActions = useMemo<ToolbarAction[]>(() => {
    return [
      {
        label: 'Create New Scan',
        icon: BigidDataSearchIcon,
        type: ToolbarActionType.TERTIARY,
        tooltip: 'Create New Scan',
        execute: async actionData => {
          return createNewScan(actionData, supportedScanTypesForSelectedDs, hasMoreThanThresholdSelected);
        },
        disable: () => false,
        show: ({ selectedRowIds }) => isScanPermitted() && selectedRowIds.length > 0,
      },
      {
        label: 'Schedule Scan',
        icon: BigidScheduleIcon,
        type: ToolbarActionType.TERTIARY,
        tooltip: 'Schedule Scan',
        isGlobal: true,
        execute: async actionData => {
          trackEventDataSources(DataSourcesUITrackingEvent.SUGGESTED_ACTIONS_GRID_ACTION_CLICK, {
            action: 'Schedule Scan',
            count: actionData?.selectedRowIds?.length ?? 0,
          });

          setDisplaySchedulerDialog(true);
          return { shouldGridReload: false };
        },
        disable: () => false,
        show: ({ selectedRowIds, filter }) =>
          isMaintainCoverageActionsVisible(filter, selectedRowIds, isOnboardingAssistantFlagEnabled),
      },
    ];
  }, [supportedScanTypesForSelectedDs, hasMoreThanThresholdSelected, selectedInitiative]);

  const gridWithToolbarConfig = useMemo<BigidGridWithToolbarProps<DataSourceModel>>(
    () =>
      isReady && {
        columns: gridColumns,
        delayedRenderColumns,
        delayedRenderColumnsDelayMs: isGridExperimentalPerformanceFeaturesEnabled ? 5 : 0,
        filterToolbarConfig: isOnboardingAssistantFlagEnabled ? null : filterToolbarConfig,
        gridId: GRID_ID,
        totalRowsThreshold: TOTAL_ROWS_THRESHOLD,
        pageSize: 50,
        customRowIdName: 'id',
        entityName: 'Data Sources',
        noDataContent: (
          <div className={noData}>
            <BigidLayoutEmptyState
              illustration={BigidDataSourceIllustration}
              description="Connect to a data source and discover your data"
              showEmptyStateWithFilter={false}
              actions={emptyStateActions}
            />
          </div>
        ),
        preSelectedIds: preselectedDataSourceIds?.map(({ name }) => name),
        onBeforeSelectToggle: () => {
          setIsOnChangeInProgress(true);
        },
        onGridStateChange: ({ filter, allSelected, ...gridState }) => {
          handleIsShowInlineNotification({ ...gridState, allSelected });
          const selectedInitiativeFilter = filter?.find(({ field }) => field === INITIATIVE_FIELD_LABEL);
          const hasEnrichmentFilters =
            filter?.find(({ field }) => [DS_ENRICHMENT_INITIATIVE_FIELD, DS_ENRICHMENT_STATUS_FIELD].includes(field)) ??
            false;
          const [selectedInitiative] = Array.isArray(selectedInitiativeFilter?.value)
            ? selectedInitiativeFilter?.value
            : [selectedInitiativeFilter?.value];

          setSelectedInitiative(selectedInitiative as Initiative);
          setActiveFilter(filter);

          setIsOnChangeInProgress(true);
          postChangesAsyncOpsRef.current = new Promise(resolve => {
            startTransition(() => {
              updatePreferences({ filterState: { filter }, gridState });
              if (updateCount === 0 && !gridState.selectedRowIds?.length) {
                resolve(true);
                setIsOnChangeInProgress(false);
              } else {
                requestIdleCallbackWithFallback(() => {
                  setSelectedDsIds(currentState => {
                    const ids =
                      gridState.selectedRowIds?.length !== currentState?.length
                        ? gridState?.selectedRowIds
                        : currentState;
                    const isEqual = fastCheckStringArraysEqual(currentState, gridState.selectedRowIds as string[]);
                    if (!isEqual) {
                      const scanTypes = updateSupportedScanTypes(ids);
                      updateSupportedTemplates.current(scanTypes);
                      dataSourcesIdsRef.current = ids;
                    }
                    resolve(true);
                    return ids;
                  });
                  setIsAllSelected(allSelected);
                  setFilterForDs(filter);
                  setIsOnChangeInProgress(false);
                });
              }
            });
          });
        },
        fetchData: async (queryComponents: BigidGridQueryComponents) => {
          let data: DataSourceModel[] = [];
          let totalCount = 0;

          try {
            setLoadingData(true);
            setInitiativeRowSelectionEventReady(false);

            const {
              data: {
                data: { ds_connections, totalCount: count },
              },
            } = await httpService.post<{ data: DataSourcesResponse }>(`${fetchEndPoint}`, {
              query: { ...queryComponents, ...fieldsForProjection },
            });

            queryClient.setQueryData<{ data: DataSourcesResponse }>([DATA_SOURCE_CONNECTIONS_QUERY_KEY], {
              data: { ds_connections, totalCount: count },
            });

            setLoadingData(false);
            data = ds_connections.map(ds => ({ ...ds, displayType: dataSourcesTypeMapping.get(ds.type) || ds.type }));

            isOnboardingAssistantFlagEnabled && setFetchedDataSourcesNames(data?.map(({ name }) => name) ?? []);
            isOnboardingAssistantFlagEnabled && onboardingEmitter.emit(OnboardingEvent.RELOAD_FUNNEL_CHART);

            if (isOnboardingAssistantFlagEnabled && isFilterContainsInitiative(queryComponents.filter)) {
              setInitiativeRowSelectionEventReady(true);
            }

            requestIdleCallbackWithFallback(() => {
              startTransition(() => {
                const dataSourceNameToTypeMap = ds_connections?.reduce(addDataSourceNameToTypeMap, {});
                dataSourceNameToTypeMapRef.current = {
                  ...dataSourceNameToTypeMapRef.current,
                  ...dataSourceNameToTypeMap,
                };
                setDisabledAndArchivedDataSources(ds_connections);
              });
            });

            totalCount = count;
          } catch (e) {
            setLoadingData(false);
            console.error(e);
            notificationService.error('Could not fetch data. See logs for more information');
          }

          setDsTotalCount(totalCount);
          return {
            data,
            totalCount,
          };
        },
        showSortingControls: true,
        defaultSorting: preferences?.grid?.sort || [{ field: 'name', order: 'asc' }],
        toolbarActions: [
          ...(isOnboardingAssistantFlagEnabled ? dsOnBoardingAssistantToolBarActions : []),
          {
            label: 'Instant Scan',
            type: ToolbarActionType.DROPDOWN,
            show: ({ selectedRowIds, filter }) => {
              const showsConnectedDs = !isInitiativeInFilter(filter, Initiative.CONNECTION_ERROR);
              return (
                showsConnectedDs &&
                !!selectedRowIds.length &&
                isScanTemplatesEnabledRef.current &&
                dataSourcesIdsRef.current.length === selectedRowIds.length &&
                !oneFavoriteScanTemplate.current
              );
            },
            dropdownProps: {
              controlShouldRenderValue: true,
              placeholder: 'Instant Scan',
              className: quickScanActionButton,
              entityIcon: <BigidInstantScanIcon />,
              isAsync: true,
              options: [],
              clearOnSelect: true,
              shouldLoadInitialOptions: true,
              isTertiary: true,
              loadOptions: loadFavoriteTemplates,
              noOptionsMessage: `Couldn't find a template to support your data source selection`,
              onMenuOpen: () => {
                oneFavoriteScanTemplate.current?.execute?.({
                  selectedRows: dataSourcesIdsRef.current.map(name => ({ name })),
                  selectedOptions: [oneFavoriteScanTemplate.current],
                });
              },
            },
          },
          {
            label: 'Instant Scan',
            icon: BigidInstantScanIcon,
            type: ToolbarActionType.TERTIARY,
            show: ({ selectedRowIds }) => {
              return (
                !!selectedRowIds.length &&
                isScanTemplatesEnabledRef.current &&
                dataSourcesIdsRef.current.length === selectedRowIds.length &&
                !!oneFavoriteScanTemplate.current
              );
            },
            execute: async () => {
              oneFavoriteScanTemplate.current?.execute?.({
                selectedRows: dataSourcesIdsRef.current.map(name => ({ name })),
                selectedOptions: [oneFavoriteScanTemplate.current],
              });
              return {
                shouldGridReload: false,
              };
            },
          },
          {
            label: 'Import',
            icon: BigidImportIcon,
            tooltip: 'Import Data Sources',
            execute: async () => {
              return importDataSources(unregisterImportHandler, onBroadCastEventFormImport);
            },
            type: ToolbarActionType.ACTION_ICON,
            disable: () => false,
            show: ({ selectedRowIds }) => {
              const hasItemsSelected = selectedRowIds.length > 0;
              return (
                !hasItemsSelected &&
                isPermitted(DATA_SOURCES_PERMISSIONS.IMPORT.name) &&
                !getApplicationPreference('NEW_SELECT_DS_VIEW_ENABLED_FF')
              );
            },
          },
          {
            label: 'Test Connection',
            icon: BigidConnectionIcon,
            type: ToolbarActionType.ACTION_ICON,
            tooltip: 'Test Connection',
            isGlobal: true,
            execute: async actionData => {
              return testConnection(actionData, onBroadCastEventFormTestConnection);
            },
            disable: () => false,
            show: ({ selectedRowIds }) =>
              selectedRowIds.length > 0 && isPermitted(DATA_SOURCES_PERMISSIONS.TEST_CONNECTION.name),
          },

          {
            label: 'More Actions',
            type: ToolbarActionType.MENU,
            menuTitle: 'More Actions',
            show: ({ selectedRowIds }) => !!selectedRowIds.length,
            dropdownProps: {
              placeholder: 'More Actions',
              clearOnSelect: true,
              className: actionsButton,
              options: [
                {
                  label: 'Export',
                  value: 'export',
                  execute: exportDataSources,
                  show: () => isPermitted(DATA_SOURCES_PERMISSIONS.EXPORT.name),
                },
                {
                  label: 'Enable',
                  value: 'enable',
                  show: ({ selectedRowIds }) =>
                    selectedRowIds.length > 0 && isPermitted(DATA_SOURCES_PERMISSIONS.EDIT.name),
                  execute: async actionData => updateDataSources(actionData, { enabled: 'yes' }),
                },
                {
                  label: 'Disable',
                  value: 'disable',
                  show: ({ selectedRowIds }) =>
                    selectedRowIds.length > 0 && isPermitted(DATA_SOURCES_PERMISSIONS.EDIT.name),
                  execute: async actionData => updateDataSources(actionData, { enabled: 'no' }),
                },
                {
                  label: 'Archive',
                  value: 'archive',
                  show: ({ selectedRowIds }) =>
                    selectedRowIds.length > 0 && isPermitted(DATA_SOURCES_PERMISSIONS.EDIT.name),
                  execute: async actionData =>
                    updateArchiveStatusDataSources(actionData, { archived: true }, 'Archive'),
                },
                {
                  label: 'Unarchive',
                  value: 'unarchive',
                  show: ({ selectedRowIds }) =>
                    selectedRowIds.length > 0 && isPermitted(DATA_SOURCES_PERMISSIONS.EDIT.name),
                  execute: async actionData =>
                    updateArchiveStatusDataSources(actionData, { archived: false }, 'Unarchive'),
                },
                {
                  label: 'Duplicate',
                  value: 'duplicate',
                  show: ({ selectedRowIds, selectedRows }) =>
                    selectedRowIds.length === 1 &&
                    isPermitted(DATA_SOURCES_PERMISSIONS.CREATE.name) &&
                    !selectedRows?.[0]?.isDsarOnly,
                  execute: duplicate,
                },
                {
                  label: 'Delete',
                  value: 'delete',
                  show: ({ selectedRowIds }) =>
                    selectedRowIds.length > 0 && isPermitted(DATA_SOURCES_PERMISSIONS.DELETE.name),
                  execute: actionData => deleteDataSources(actionData, dialogContent),
                },
                {
                  label: 'Delete Findings',
                  value: 'delete',
                  show: ({ selectedRowIds }) =>
                    selectedRowIds.length === 1 && isPermitted(DATA_SOURCES_PERMISSIONS.DELETE_FINDING.name),
                  execute: deleteFindings,
                },
                {
                  label: 'Delete Classification Findings',
                  value: 'delete',
                  show: ({ selectedRowIds }) =>
                    selectedRowIds.length === 1 && isPermitted(DATA_SOURCES_PERMISSIONS.DELETE_FINDING.name),
                  execute: deleteClassificationFindings,
                },
                {
                  label: 'Modify',
                  value: 'modify',
                  show: ({ selectedRows }) => {
                    return (
                      selectedRows.length > 0 &&
                      isPermitted(DATA_SOURCES_PERMISSIONS.EDIT.name) &&
                      getApplicationPreference('ENABLE_DATA_SOURCE_BULK_UPDATES')
                    );
                  },
                  execute: async row => {
                    return modify(row, bulkDataSourceUpdateHandlersRef, bulkDataSourceFieldsToModifyRef);
                  },
                },
              ],
            },
          },
        ],
        forceSelectAll: true,
      },
    [
      gridColumns,
      filterToolbarConfig,
      noData,
      emptyStateActions,
      preselectedDataSourceIds,
      preferences?.grid?.sort,
      quickScanActionButton,
      actionsButton,
      updatePreferences,
      setDisabledAndArchivedDataSources,
      // dataSourcesTypeMapping, if render bugs - uncomment
      // updateCount, - if instant scan bugs - uncomment
      dialogContent,
      dataSourcesIds.length,
      dsOnBoardingAssistantToolBarActions,
    ],
  );

  const handleFiltersChange = useCallback(
    (filters: BigidFieldFilter[]) => {
      updateToolbarFilters(filters);
    },
    [updateToolbarFilters],
  );
  const isGridIdle = !isOnChangeInProgress && !isLoadingData && !isPending;

  const onEmitRowSelectionEvent = () => {
    setInitiativeRowSelectionEventReady(false);
  };

  const dataSourceConnectionsLayout = useMemo(
    () =>
      isOnboardingAssistantFlagEnabled ? (
        <OnboardingAssitantLayout
          anchor={suggestedActionRef}
          isPopupOpen={showSuggestedActions}
          onClosePopup={handleHideSuggestedActions}
          filters={activeFilters}
          searchTerm={searchTerm}
          initiative={selectedInitiative}
          filterConfig={filterToolbarConfig}
          onFilterChange={handleFiltersChange}
          isToolbarDisabled={isOnboardingAssistantFlagEnabled}
          dsConnectionsGridConfig={gridWithToolbarConfig}
          isSelectAllDisabled={false}
          fetchedDataSourcesNames={fetchedDataSourcesNames}
          emitRowSelectionEvent={isGridIdle && initiativeRowSelectionEventReady}
          onEmitRowSelectionEvent={onEmitRowSelectionEvent}
        />
      ) : (
        <BigidPaper>
          <BigidGridWithToolbar {...gridWithToolbarConfig} />
        </BigidPaper>
      ),
    [
      gridWithToolbarConfig,
      isOnboardingAssistantFlagEnabled,
      showSuggestedActions,
      selectedInitiative,
      activeFilter,
      activeFilters,
      searchTerm,
      filterToolbarConfig,
      isGridIdle,
      initiativeRowSelectionEventReady,
      fetchedDataSourcesNames,
    ],
  );
  const tabsIndexToComponentMap: Record<number, () => JSX.Element> = useMemo(
    () => ({
      0: () =>
        isReady ? (
          dataSourceConnectionsLayout
        ) : (
          <BigidPaper>
            <BigidLoader />
          </BigidPaper>
        ),
      1: () => (
        <BigidPaper>
          <AutoDiscoveryGrid isAutoDiscoveryAvailable={isAutoDiscoveryAvailable} />
        </BigidPaper>
      ),
    }),
    [dataSourceConnectionsLayout, isAutoDiscoveryAvailable, isReady],
  );

  useSSEDataSourceEvents({ dataSourcesTypeMapping, isActive: isDataSourceList, filterForDs });

  useEffect(() => {
    const shouldReload = !isEqual(activeFilters, prevActiveFilters) || !isEqual(searchTerm, prevSearchTerm);

    shouldReload &&
      entityEventsEmitter.emit(EntityEvents.RELOAD_WITH_FILTER, {
        entityId: gridWithToolbarConfig?.gridId,
        payload: {
          filter: activeFilters,
          searchQuery: searchTerm,
        },
      });
  }, [activeFilters, searchTerm]);

  useEffect(() => {
    pageHeaderService.setTitle({
      pageTitle: t('Header.pageTitle'),
      rightSideComponentsContainer: (
        <RightSideComponentWrapper>
          {!isAutoDiscoveryCompleted && !isLoadingAutoDiscoveryAvailability && isAutoDiscoveryAvailable && (
            <DataSourceAsyncOperationsWidget onOperationCompleted={handleProcessingWidgetGotCompletedOperations} />
          )}
          {!isOnboardingAssistantFlagEnabled && (
            <DataSourceScanSelectedButton
              dataSourcesIds={dataSourcesIds}
              supportedScanTypesForSelectedDs={supportedScanTypesForSelectedDs}
              isAllSelected={isAllSelected}
              filterForDs={filterForDs}
              totalCount={dsTotalCount}
              hasMoreThanThresholdSelected={hasMoreThanThresholdSelected}
            />
          )}
          {getApplicationPreference('NEW_SELECT_DS_VIEW_ENABLED_FF') ? (
            <StartDataSourceOnboardingButtons
              unregisterImportHandler={unregisterImportHandler}
              onBroadCastEventFormImport={onBroadCastEventFormImport}
            />
          ) : (
            <AddDataSourceButtons isAutoDiscoveryAvailable={isAutoDiscoveryAvailable} />
          )}
        </RightSideComponentWrapper>
      ),
    });
  }, [
    dataSourcesIds,
    dsTotalCount,
    filterForDs,
    preselectedDataSourceIds,
    handleProcessingWidgetGotCompletedOperations,
    isAllSelected,
    isAutoDiscoveryAvailable,
    isAutoDiscoveryCompleted,
    isLoadingAutoDiscoveryAvailability,
    hasMoreThanThresholdSelected,
    onBroadCastEventFormImport,
    unregisterImportHandler,
  ]);

  return (
    <DataSourceConnectionsContext.Provider
      value={{
        isGridIdle,
      }}
    >
      <div className={gridWrapper}>
        {isNewConnectivityEnabled && <GlobalDataSourceConnectionModalStyles />}
        <QueryClientProvider client={queryClient}>
          {!isOnboardingAssistantFlagEnabled && (isOnChangeInProgress || isPending) && !isLoadingData && (
            <TopLoaderWrapper>
              <BigidLoader dataAid={'dataSourceGridUpdateLoading'} size={28} thickness={3} />
            </TopLoaderWrapper>
          )}
          {isTabs && (
            <div className={tabWrapper}>
              <BigidTabsAndContent {...tabsAndContentConfig} />
              {isOnboardingAssistantFlagEnabled && isOnboardingAssistantFlagEnabled && selectedTabIndex === 0 && (
                <div ref={suggestedActionRef}>
                  <TertiaryButton
                    dataAid="DataSourceConnections-action-showSuggestedActions"
                    onClick={handleShowSuggestedActions}
                    size="large"
                    text={t('OnboardingAssistant.labels.suggestedActions')}
                    startIcon={<BigidActionIcon />}
                    bi={{ disabled: true }}
                  />
                </div>
              )}
            </div>
          )}
          {tabsIndexToComponentMap[selectedTabIndex ?? 0]()}
          <DataSourceConnectionModal
            source={sourceType}
            sourceId={dataSourceId}
            dsName={dsLabel}
            nameInDocs={nameInDocs}
            isOpen={showModal}
            onClose={handleCloseModal}
            onAddDataSource={id => setDataSourceId(id)}
          />
          {showGuidedTour && (
            <DataSourceCollaborationGuidedTour
              loading={isLoadingData}
              source={guidedTourDataSourceId}
              start={showGuidedTour}
              onSkip={handleSkipTour}
              onDone={handleCompleteTour}
              onClose={handleEndTour}
              onUpdate={handleTourStateChange}
            />
          )}
          {displaySchedulerDialog && (
            <DataSourceScanScheduler
              onClose={handleCloseSchedulerDialog}
              isOpen={displaySchedulerDialog}
              dataSourcesIds={dataSourcesIds}
            />
          )}
        </QueryClientProvider>
      </div>
    </DataSourceConnectionsContext.Provider>
  );
};
