import { ScanTemplate } from '../scanTemplateTypes';
import React, { useState, useRef } from 'react';
import {
  createScanTemplate,
  getTemplateById,
  handleTemplateError,
  updateFavoritesPreference,
  updateScanTemplate,
} from '../scanTemplatesService';
import { notificationService } from '../../../../services/notificationService';
import { CONFIG } from '../../../../../config/common';
import { $state } from '../../../../services/angularServices';
import { getCurrentUser } from '../../../../utilities/systemUsersUtils';
import { openDuplicateTemplateWarningDialog } from '../DuplicateTemplateWarnning';

export interface UseTemplateApi {
  id?: string;
  templateToEdit?: ScanTemplate;
  setTemplateToEdit: React.Dispatch<React.SetStateAction<ScanTemplate>>;
  isEditMode: boolean;
}

export const useTemplateApi = ({ id, templateToEdit, setTemplateToEdit, isEditMode }: UseTemplateApi) => {
  const [isLoading, setIsLoading] = useState(false);
  const fetchingTemplateId = useRef('');
  const isUserFetching = useRef(false);
  const fetchTemplate = async () => {
    if (fetchingTemplateId.current === id) {
      return;
    }
    try {
      setIsLoading(true);
      fetchingTemplateId.current = id;
      const template = await getTemplateById(id);
      setTemplateToEdit(template);
    } catch (error) {
      notificationService.error('Error getting scan template');
    } finally {
      fetchingTemplateId.current = '';
      setIsLoading(isUserFetching.current);
    }
  };

  const handleSaveTemplate = async () => {
    try {
      setIsLoading(true);
      const updateOrCreateScanTemplate = isEditMode ? updateScanTemplate : createScanTemplate;
      const { matchScanTemplateName, matchingCount } = await updateOrCreateScanTemplate(templateToEdit, false);
      if (matchScanTemplateName) {
        const isAllowDuplicateScanTemplate = await openDuplicateTemplateWarningDialog(
          matchScanTemplateName,
          matchingCount,
        );
        if (!isAllowDuplicateScanTemplate) {
          return;
        }
        await updateOrCreateScanTemplate(templateToEdit, true);
      }
      if (isEditMode) {
        await updateFavoritesPreference(templateToEdit._id, templateToEdit.name, true);
      }
      $state.go(CONFIG.states.SCANS_SCAN_TEMPLATES);
    } catch (e) {
      handleTemplateError(e);
    } finally {
      setIsLoading(false);
    }
  };

  const getCurrentUserById = async () => {
    if (isUserFetching.current) {
      return;
    }
    setIsLoading(true);
    isUserFetching.current = true;
    try {
      const { id, firstName, name: username, lastName } = await getCurrentUser();
      setTemplateToEdit(prevState => ({ ...prevState, owner: { id, firstName, username, lastName } }));
      isUserFetching.current = false;
      setIsLoading(!!fetchingTemplateId.current);
    } catch (e) {
      setTemplateToEdit(prevState => ({ ...prevState, owner: { id, firstName: '', username: '', lastName: '' } }));
      isUserFetching.current = false;
      setIsLoading(!!fetchingTemplateId.current);
    }
  };

  return {
    isLoading,
    fetchTemplate,
    handleSaveTemplate,
    getCurrentUserById,
  };
};
