import React, { FC } from 'react';
import { BigidHeading3, BigidBody1, PrimaryButton } from '@bigid-ui/components';
import { useLocalTranslation } from '../../../../../translations';
import { BigidTasksIllustration } from '@bigid-ui/icons';
import styled from '@emotion/styled';
import { generateDataAid } from '@bigid-ui/utils';
import { $state } from '../../../../../../../services/angularServices';
import { CONFIG } from '../../../../../../../../config/common';

const MainContainer = styled.div({
  padding: 16,
  width: '100%',
  height: '100%',
});

const TextContainer = styled.div({
  display: 'flex',
  flexDirection: 'column',
  columnGap: 12,
  rowGap: 12,
});

const ContentContainer = styled.div({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  height: '100%',
  paddingBottom: 24,
});

const ContentWrapperContainer = styled.div({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  rowGap: 16,
  textAlign: 'center',
});

const BigidNonActionIllusIconWrapper = styled.div({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  paddingBottom: 24,
  paddingTop: 8,
  minHeight: 250,
});

export interface NoTicketConfigurationProps {
  withPermission: boolean;
}

export const NoTicketConfiguration: FC<NoTicketConfigurationProps> = ({ withPermission }) => {
  const { t } = useLocalTranslation('Action');

  const handleRoute = () => {
    $state.go(CONFIG.states.ACTION_CENTER_CONFIGURATIONS);
  };

  return (
    <MainContainer>
      <ContentContainer>
        <ContentWrapperContainer>
          <div>
            <BigidNonActionIllusIconWrapper>
              <BigidTasksIllustration dataAid={generateDataAid('noConfiguration', ['icon'])} width={130} />
            </BigidNonActionIllusIconWrapper>
            <TextContainer>
              <BigidHeading3>{t('body.noJiraConfiguration')}</BigidHeading3>
              <BigidBody1>{t('body.contactAdministrator')}</BigidBody1>
            </TextContainer>
          </div>
          {withPermission && <PrimaryButton onClick={handleRoute} size="medium" text="Add account" />}
        </ContentWrapperContainer>
      </ContentContainer>
    </MainContainer>
  );
};
