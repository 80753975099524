import React, { FC, useMemo, MutableRefObject } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import {
  BigidForm,
  BigidFormField,
  BigidFormRenderProps,
  BigidFormFieldTypes,
  BigidSelectOption,
  BigidFormFieldLabelPosition,
  BigidFormStateAndHandlers,
  BigidFormValues,
  BigidFormValidateOnTypes,
} from '@bigid-ui/components';
import { SystemDialogContentProps } from '../../../services/systemDialogService';
import { notificationService } from '../../../services/notificationService';
import {
  ConfigurationsMetadata,
  fieldTypeToFormFieldType,
  FieldType,
  EditConfigurationData,
} from './configurationManagementTypes';
import {
  getConfigurationTypeOptions,
  getConfigurationInitialFormValue,
  getFormKey,
  getServiceFormatFromForm,
} from './configurationManagementUtils';
import { ALLOWED_NAME_REGEX, TRAILING_SPACES, TRAILING_SPACES_MESSAGE } from '../../../config/consts';
import { createConfiguration, updateConfiguration } from './configurationManagementService';
import { isDuplicationError } from '../ActionWorkflow/actionWorkflowUtils';

export interface ConfigurationFormProps {
  configurationsMetadata: ConfigurationsMetadata;
  formControls: MutableRefObject<BigidFormStateAndHandlers>;
  editConfigurationData?: EditConfigurationData;
  onSubmit: () => void;
}

const useStyles = makeStyles({
  formWrapper: { display: 'flex', flexDirection: 'column' },
});

export const ConfigurationForm: FC<SystemDialogContentProps<ConfigurationFormProps>> = ({
  configurationsMetadata,
  formControls,
  editConfigurationData,
  onSubmit,
}) => {
  const { formWrapper } = useStyles();

  const isEditMode = !!editConfigurationData;

  const handleFormSubmit = async (values: BigidFormValues) => {
    try {
      const configurationToUpdate = getServiceFormatFromForm(values, configurationsMetadata);
      isEditMode
        ? await updateConfiguration(editConfigurationData?.id, configurationToUpdate)
        : await createConfiguration(configurationToUpdate);
      notificationService.success('Configuration saved successfully!');
      onSubmit();
    } catch (error) {
      console.error(`An error has occurred: ${error}`);
      const message = isDuplicationError(error)
        ? 'Jira configuration name already related to existing configuration'
        : `Error ${isEditMode ? 'updating' : 'creating'} configurations. See logs for more information`;
      notificationService.error(message);
    }
  };

  const renderForm = ({ renderField, getValues }: BigidFormRenderProps) => {
    const { type } = getValues();
    const selectedType = type?.[0]?.value;
    const fields = configurationsMetadata.find(({ type }) => type === selectedType)?.fields || [];

    return (
      <div className={formWrapper}>
        {renderField('name')}
        {renderField('type', {
          selectProps: { isSearchable: true, placeholder: 'Select Configuration Type' },
        })}
        {fields.map(({ name }) => renderField(getFormKey(selectedType, name)))}
      </div>
    );
  };

  const fields: BigidFormField[] = useMemo(() => {
    return [
      {
        type: BigidFormFieldTypes.TEXT,
        name: 'name',
        label: 'Configuration Name',
        isRequired: true,
        misc: {
          fullWidth: true,
          placeholder: 'Enter a name',
        },
        validate: (value: string) => {
          if (!value) {
            return 'Please enter a name';
          }
          if (!ALLOWED_NAME_REGEX.test(value)) {
            return 'Invalid value, special characters';
          }
          if (TRAILING_SPACES.test(value)) {
            return TRAILING_SPACES_MESSAGE;
          }
        },
      },
      {
        type: BigidFormFieldTypes.SELECT,
        name: 'type',
        label: 'Configuration Type',
        isRequired: true,
        options: getConfigurationTypeOptions(configurationsMetadata),
        validate: (selectedOption: BigidSelectOption[]) => {
          if (selectedOption?.length === 0) {
            return 'Please select the configuration type';
          }
          return false;
        },
        disabled: isEditMode,
      },
      ...configurationsMetadata.reduce((configurationFields, { type: configurationType, fields }) => {
        configurationFields = [
          ...configurationFields,
          ...fields.map(({ name, displayName, type, required, description, options, uneditable }) => ({
            type: fieldTypeToFormFieldType[type],
            name: getFormKey(configurationType, name),
            label: displayName,
            isRequired: required,
            tooltipText: description,
            options,
            ...(type === FieldType.CHECKBOX && { labelPosition: BigidFormFieldLabelPosition.left }),
            ...(isEditMode && uneditable && { disabled: true }),
          })),
        ];
        return configurationFields;
      }, []),
    ];
  }, [configurationsMetadata, isEditMode]);

  return (
    <BigidForm
      controlButtons={false}
      fields={fields}
      initialValues={getConfigurationInitialFormValue(configurationsMetadata, editConfigurationData?.configuration)}
      renderForm={renderForm}
      stateAndHandlersRef={formControls}
      onSubmit={handleFormSubmit}
      validateOn={BigidFormValidateOnTypes.SUBMIT}
    />
  );
};
