import React, { FC, useState } from 'react';
import { styled } from '@mui/material';
import { BigidTabs, BigidTabsItem } from '@bigid-ui/components';
import { useLocalTranslation } from '../../../../translations';
import { Overview } from './Overview/Overview';
import { generateDataAid } from '@bigid-ui/utils';
import { SidePanelMetadata } from './SidePanelMetadata';
import { CatalogSearchGridRow } from '../../../../types';
import { Attributes } from './Attributes/Attributes';

export interface CatalogSidePanelProps {
  dataAid?: string;
  selectedItem: CatalogSearchGridRow<'catalog'>;
  highlightValue?: string;
}

const Root = styled('div')`
  display: flex;
  flex-grow: 1;
  height: 100%;
  width: 100%;
  padding: 24px;
  overflow-y: auto;
  flex-direction: column;
`;

const Header = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 28px;
  margin-bottom: 32px;
`;

export const CatalogSidePanel: FC<CatalogSidePanelProps> = ({
  dataAid = 'DataSourceSidePanel',
  selectedItem,
  highlightValue,
}) => {
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);
  const { t } = useLocalTranslation('sidePanel');
  const tabs: BigidTabsItem[] = [
    {
      label: t('tabs.overview'),
    },
    {
      label: t('tabs.attributes'),
    },
    {
      label: t('tabs.columns'),
      disabled: true,
    },
    {
      label: t('tabs.preview'),
      disabled: true,
    },
  ];
  const tabIndexToContentMap = [Overview, Attributes];
  const TabContent = tabIndexToContentMap[selectedTabIndex];

  return (
    <Root data-aid={generateDataAid(dataAid, ['root'])}>
      <Header>
        <SidePanelMetadata selectedItem={selectedItem} />
        <BigidTabs
          tabs={tabs}
          showBorderBottom
          selectedIndex={selectedTabIndex}
          onChange={setSelectedTabIndex}
        ></BigidTabs>
      </Header>
      <TabContent selectedItem={selectedItem} highlightValue={highlightValue} key={selectedTabIndex} />
    </Root>
  );
};
