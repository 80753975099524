import angular from 'angular';
import React, { FunctionComponent } from 'react';
import { BigidProgressBarDetailed, BigidProgressBarDetailedProps } from '@bigid-ui/components';
import { convertToAngular } from '../../../common/services/convertToAngular';

export const ProgressBarDetailed: FunctionComponent<BigidProgressBarDetailedProps> = ({
  percentage,
  label,
  status,
  steps,
  tooltipText,
  displayPercentage,
  customValue,
  displayCustomValue,
  estimation,
}) => {
  return (
    <BigidProgressBarDetailed
      percentage={percentage}
      label={label}
      status={status}
      steps={steps}
      tooltipText={tooltipText}
      estimation={estimation}
      displayPercentage={displayPercentage}
      customValue={customValue}
      displayCustomValue={displayCustomValue}
    />
  );
};

angular
  .module('app')
  .component(
    'progressBarDetailed',
    convertToAngular<BigidProgressBarDetailedProps>(ProgressBarDetailed, [
      'percentage',
      'label',
      'status',
      'estimation',
      'steps',
      'tooltipText',
      'displayPercentage',
      'customValue',
      'displayCustomValue',
    ]),
  );
