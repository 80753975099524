import angular from 'angular';
import React, { Fragment, FunctionComponent, ReactText, useState } from 'react';
import { convertToAngular } from '../../../common/services/convertToAngular';
import classNames from 'classnames';

type ConfigurationValue = boolean | ReactText;
type ConfigurationTypes = 'numeric' | 'string' | 'boolean';

export interface SettingsConfiguration {
  name: string;
  value: ConfigurationValue;
  service: string;
  type: ConfigurationTypes;
}

export interface EditConfigurationProps {
  onSubmit: (value: ConfigurationValue) => void;
  onCancel: () => void;
  configuration: SettingsConfiguration;
}

export const EditConfigurationModal: FunctionComponent<EditConfigurationProps> = ({
  onSubmit,
  onCancel,
  configuration,
}) => {
  const { value: configurationInitialValue, name, type } = configuration;

  const inputType = type === 'numeric' ? 'number' : 'text';
  const defaultValue = inputType === 'number' ? 0 : '';
  const isMultiSelect = type === 'boolean';
  const initialValue: ReactText = isMultiSelect
    ? configurationInitialValue.toString()
    : (configurationInitialValue as ReactText);

  const [configurationValue, setConfigurationValue] = useState<ReactText>(initialValue);

  const onValueChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setConfigurationValue(event.currentTarget.value || defaultValue);
  };

  const onSaveClick = (): void => {
    let returnValue: ConfigurationValue = configurationValue;
    if (typeof configurationInitialValue === 'boolean' && type === 'boolean') {
      returnValue = configurationValue === 'true';
    } else if (typeof configurationInitialValue === 'number' && typeof configurationValue === 'string') {
      returnValue = parseInt(configurationValue, 10);
    }
    onSubmit(returnValue);
  };

  return (
    <Fragment>
      <div className="modal-header">
        <span className="modal-header-text">{`Edit ${name}`}</span>
        <img className="close-image" onClick={onCancel} src="/images/close_icon.svg" />
      </div>

      <div className="modal-body">
        <div>
          <label>{`Edit ${name}:`}</label>
          {!isMultiSelect && (
            <input type={inputType} className="bigid-input" value={configurationValue} onChange={onValueChange} />
          )}
          {isMultiSelect && (
            <select
              className={'ui-select'}
              value={configurationValue}
              onChange={({ target }) => setConfigurationValue(target.value)}
            >
              {['true', 'false'].map((choice, index) => (
                <option key={index} value={choice}>
                  {choice}
                </option>
              ))}
            </select>
          )}
        </div>
      </div>
      <div className="modal-footer">
        <button
          style={{
            display: 'inline-block',
          }}
          className={classNames('bigid-btn', 'bigid-btn--secondary-dark')}
          onClick={onCancel}
          type="button"
        >
          CANCEL
        </button>
        <button
          style={{
            display: 'inline-block',
            marginLeft: '15px',
          }}
          className={classNames('bigid-btn', 'bigid-btn--primary-dark')}
          onClick={onSaveClick}
          disabled={configurationValue === configurationInitialValue}
          type="button"
        >
          SAVE
        </button>
      </div>
    </Fragment>
  );
};

angular
  .module('app')
  .component(
    'editConfigurationModal',
    convertToAngular<EditConfigurationProps>(EditConfigurationModal, ['onSubmit', 'onCancel', 'configuration']),
  );
