import React from 'react';
import { RegexFormFields } from '../../types/ClassifierTypes';
import { BigidFormFieldLabelWrapper, BigidTextField, ValidationResult } from '@bigid-ui/components';
import { useLocalTranslation } from '../../translations';
import { generateDataAid } from '@bigid-ui/utils';
import { styled } from '@mui/material';

const ExtraFieldsHolder = styled('div')`
  background: #fcfcfc;
  padding: 16px;

  fieldset:last-child {
    margin-bottom: 0;
  }
`;

interface NerFieldsProps {
  id: string;
  value: {
    [RegexFormFields.classifierName]: string;
    [RegexFormFields.nerEntityMap]: string;
  };
  onChange: (value: any) => void;
  errorIsShown: boolean;
  disabled: boolean;
  fieldProps?: Record<string, any>;
  isValidated: boolean;
  error?: ValidationResult;
}

export const NerFields = ({
  id,
  value,
  onChange,
  error,
  errorIsShown,
  disabled,
  fieldProps,
  isValidated,
}: NerFieldsProps) => {
  const { t } = useLocalTranslation('form');
  const errors = typeof error === 'string' && JSON.parse(error);
  const errorData = (Array.isArray(errors) && errors.find(error => error.id === id)) || {};

  return (
    <ExtraFieldsHolder>
      <BigidFormFieldLabelWrapper
        id={id}
        name="nerEntityMap"
        label={t('entityModelName')}
        error={errorData?.nerEntityMap}
        errorIsShown={errorIsShown}
        disabled={disabled}
        isRequired={true}
      >
        <BigidTextField
          id={id}
          isError={errorIsShown && errorData.nerEntityMap}
          onChange={e =>
            onChange({
              ...value,
              nerEntityMap: e.target.value,
            })
          }
          required={true}
          value={value?.nerEntityMap}
          readOnly={fieldProps?.readOnly || isValidated}
          dataAid={generateDataAid('NerFields', ['nerEntityMap-field'])}
          name="nerEntityMap"
          size="large"
        />
      </BigidFormFieldLabelWrapper>
      <BigidFormFieldLabelWrapper
        id={id}
        label={t('name')}
        isRequired={true}
        errorIsShown={errorIsShown}
        error={errorData?.[RegexFormFields.classifierName]}
        name="classifierName"
        disabled={disabled}
      >
        <BigidTextField
          id={id}
          isError={errorIsShown && errorData?.[RegexFormFields.classifierName]}
          onChange={e =>
            onChange({
              ...value,
              [RegexFormFields.classifierName]: e.target.value,
            })
          }
          required={true}
          value={value?.[RegexFormFields.classifierName]}
          dataAid={generateDataAid('NerFields', ['classifierName-field'])}
          readOnly={fieldProps?.readOnly || isValidated}
          name="classifierName"
          size="large"
        />
      </BigidFormFieldLabelWrapper>
    </ExtraFieldsHolder>
  );
};
