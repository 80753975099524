import { useCallback, useRef } from 'react';
import { getApps } from '../../../views/ApplicationsManagement/applicationManagementService';
import { updateFavoritePagePreferencesByWorkspace } from '../favoritePagesManagementService';
import {
  deleteAppsFromPreferences,
  FAVORITES_LINK,
  updateSideNavigationPreferencesByWorkspace,
} from '../navigationSidebarUtils';
import { MenuSettings, MenuSettingsItem } from '@bigid-ui/components';
import {
  userPreferencesEventEmitter,
  UserPreferencesEvents,
} from '../../../services/eventEmitters/userPreferencesEvents';
import { useWorkspaces } from '../../../services/workspacesService';
import { getCustomAppStates } from '../../Sidebar/SidebarUtils';
import { getApplicationPreference } from '../../../services/appPreferencesService';

function filterAppsNotDeleted(apps: any[], favorites: MenuSettingsItem, customAppsIds: string[]) {
  const appsOriginal = getApps();
  const currentFavoritesAppsWithLinks = apps.map(({ appId, _id }) => {
    const app = appsOriginal?.find?.(({ id }) => id === appId);
    const id = appId || _id;
    const isCustomApp = customAppsIds?.includes(id);
    return {
      appId,
      link: app?.to || getLink(id, isCustomApp),
    };
  });
  const favoritesAppsDeleted = currentFavoritesAppsWithLinks.filter(({ link }) => {
    return !favorites?.menuItems?.some(({ id }) => id === link);
  });

  return favoritesAppsDeleted;
}

const getLink = (id: string, isCustomApp: boolean) => {
  const isNewNavigation = getApplicationPreference('ENABLE_NEW_UX_NAVIGATION');
  const [link] = isNewNavigation && isCustomApp ? getCustomAppStates(id) : [id];
  return link;
};

export const useFavoritesAggregated = () => {
  const favoritesRef = useRef({ pages: [], apps: [] });
  const settingsRef = useRef({ main: [], header: [] });

  const { selectedWorkspaceId } = useWorkspaces();

  const updateUserFavorites = useCallback(
    async (settingsNew: MenuSettings, customAppsIds: string[]) => {
      const updateSettingsPromises = [];
      const { apps, pages } = favoritesRef.current;
      const favorites = settingsNew.find(({ id }) => id === FAVORITES_LINK);

      const favoritesPagesAfterDeleteOperation = pages.filter(({ link }) => {
        return favorites?.menuItems?.some(({ id }) => id === link);
      });

      const favoritesAppsDeleted = filterAppsNotDeleted(apps, favorites, customAppsIds);

      if (favoritesPagesAfterDeleteOperation?.length !== pages?.length) {
        updateSettingsPromises.push(
          (async () => {
            await updateFavoritePagePreferencesByWorkspace(selectedWorkspaceId, {
              favorites: favoritesPagesAfterDeleteOperation,
            });
            userPreferencesEventEmitter.emit(UserPreferencesEvents.UPDATE_FAVORITES_MANAGEMENT_PREFERENCES);
          })(),
        );
      }

      if (favoritesAppsDeleted?.length) {
        const favoritesAppsIDs = favoritesAppsDeleted?.map(({ appId }) => appId);
        updateSettingsPromises.push(deleteAppsFromPreferences(favoritesAppsIDs));
      }

      updateSettingsPromises.push(
        updateSideNavigationPreferencesByWorkspace(selectedWorkspaceId, {
          main: settingsRef.current.main,
          header: settingsNew,
        }),
      );
      settingsRef.current = {
        ...settingsRef.current,
        header: settingsNew,
      };
      await Promise.all(updateSettingsPromises);

      return true;
    },
    [selectedWorkspaceId],
  );

  return {
    updateUserFavorites,
    favoritesRef,
    settingsRef,
  };
};
