import { styled } from '@mui/material';
import React from 'react';
import { useLocalTranslation } from './translations';
import { BigidTextField, TertiaryButton, TextFieldIconPositionEnum } from '@bigid-ui/components';
import { useAuditTrialGridContext } from './dataCatalogAuditContext';
import { BigidExportIcon, BigidSearchIcon } from '@bigid-ui/icons';
import { noop } from 'lodash';

const RowContainer = styled('div')`
  display: flex;
  flex-direction: row;
  padding: 12px 16px;
  justify-content: space-between;
  align-items: center;
  gap: 16px;
`;

const StyledTextFieldWrapper = styled('div')`
  width: 500px;
`;

export const DataCatalogAuditTrailServiceRow = () => {
  const { t } = useLocalTranslation('serviceRow');
  const { dispatch, state } = useAuditTrialGridContext();

  function handleExportClick() {
    noop();
  }

  function handleSearchQueryChange(value: string) {
    dispatch({ type: 'SET_SEARCH_QUERY', payload: value });
  }

  return (
    <RowContainer>
      <TertiaryButton
        startIcon={<BigidExportIcon />}
        size="medium"
        onClick={handleExportClick}
        text={t('exportAction')}
      />

      <StyledTextFieldWrapper>
        <BigidTextField
          value={state.searchQuery}
          icon={{
            Icon: BigidSearchIcon,
            position: TextFieldIconPositionEnum.right,
          }}
          placeholder={t('searchPlaceholder')}
          onChange={e => handleSearchQueryChange(e.target.value)}
        />
      </StyledTextFieldWrapper>
    </RowContainer>
  );
};
