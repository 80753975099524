import {
  AdvancedToolbarOverrideValue,
  BigidAdvancedToolbarFilterTypes,
  BigidDropdownOption,
  BigidFieldFilter,
  BigidFilter,
  advancedToolbarFilterMinifier,
} from '@bigid-ui/components';
import { isNil } from 'lodash';
import { AggregationFilterOperand, AggregationType } from '../catalogDiscoveryTypes';
import { nestedFilterOptionIdDelimeter } from '../filter/utils';
import { GetViewInDataOverviewFiltersResult } from '../../DataCatalog/NewDataCatalog/types';

enum CatalogFilterField {
  SYSTEM = 'system',
  SCAN_STATUS = 'scanStatus',
  APPLICATION = 'application',
  CLOUD = 'source.type',
  TAGS = 'catalog_tag',
  SENSITIVITY = 'catalog_tag.system.sensitivityClassification',
  RISK = 'catalog_tag.system.risk.riskGroup',
  HAS_DUPLICATES = 'has_duplicates',
  FILE_TYPE = 'fileType',
  FIELD = 'field',
  ACCESS_TYPE = 'catalog_tag.Access Type',
  POLICY = 'policy',
}

enum PreprocessorType {
  PARENT_ID = 'PARENT_ID',
  ID = 'ID',
  AGG_ITEM_GROUP = 'AGG_ITEM_GROUP',
}

type PreprocessorFunc = (value: string) => string;

type Preprocessor = { [key in PreprocessorType]?: PreprocessorFunc };

const preprocessors: { [key in AggregationType]?: Preprocessor } = {
  [AggregationType.SENSITIVITY_FILTER]: {
    [PreprocessorType.PARENT_ID]: (value: string): string => {
      return value
        .replace(new RegExp(/^(system.sensitivityClassification\.)/), '')
        .replace(new RegExp(/^(catalog_tag\.)/), '');
    },
    [PreprocessorType.ID]: (value: string): string => {
      return value
        .replace(new RegExp(/^(system.sensitivityClassification\.)/), '')
        .replace(new RegExp(/^(catalog_tag\.)/), '');
    },
    [PreprocessorType.AGG_ITEM_GROUP]: (value: string): string => {
      return value
        .replace(new RegExp(/^(system.sensitivityClassification\.)/), '')
        .replace(new RegExp(/^(catalog_tag\.)/), '');
    },
  },
  [AggregationType.TAGS]: {
    [PreprocessorType.PARENT_ID]: (value: string): string => {
      return value.replace(new RegExp(/^(catalog_tag\.)/), '');
    },
    [PreprocessorType.ID]: (value: string): string => {
      return value.replace(new RegExp(/^(catalog_tag\.)/), '');
    },
    [PreprocessorType.AGG_ITEM_GROUP]: (value: string): string => {
      return value.replace(new RegExp(/^(catalog_tag\.)/), '');
    },
  },
};

const parentIdCatalogFiltersIgnoreList: CatalogFilterField[] = [CatalogFilterField.RISK];

const catalogToInsightsFilterMap: Record<string, AggregationType> = {
  [CatalogFilterField.SYSTEM]: AggregationType.DATA_SOURCE_NAME,
  [CatalogFilterField.SCAN_STATUS]: AggregationType.OBJECT_SCAN_STATUS,
  [CatalogFilterField.APPLICATION]: AggregationType.APPLICATION,
  [CatalogFilterField.CLOUD]: AggregationType.CLOUD_TYPE,
  [CatalogFilterField.TAGS]: AggregationType.TAGS,
  [CatalogFilterField.SENSITIVITY]: AggregationType.SENSITIVITY_FILTER,
  [CatalogFilterField.HAS_DUPLICATES]: AggregationType.HAS_DUPLICATES,
  [CatalogFilterField.FILE_TYPE]: AggregationType.FILE_TYPE,
  [CatalogFilterField.FIELD]: AggregationType.ATTRIBUTE_CATEGORY,
  [CatalogFilterField.RISK]: AggregationType.RISK,
  [CatalogFilterField.ACCESS_TYPE]: AggregationType.ACCESS_TYPE_FILTER,
  [CatalogFilterField.POLICY]: AggregationType.VIOLATED_POLICY,
};

const booleanFilterOptionsMap = {
  [AggregationFilterOperand.HAS_DUPLICATES]: {
    id: AggregationType.HAS_DUPLICATES,
    type: BigidAdvancedToolbarFilterTypes.DROPDOWN,
    options: [
      {
        id: 'with',
        value: {
          aggItemName: 'with',
        },
        displayValue: 'Yes',
        isSelected: true,
      },
    ],
  },
  [AggregationFilterOperand.OBJECT_STATUS]: {
    id: AggregationType.OBJECT_STATUS,
    type: BigidAdvancedToolbarFilterTypes.DROPDOWN,
    options: [
      {
        id: 'with',
        value: {
          aggItemName: 'with',
        },
        displayValue: 'Yes',
        isSelected: true,
      },
    ],
  },
};

function getValuePreproccessed(value: string, filterId: AggregationType, preprocessorType: PreprocessorType): string {
  if (!value) return;

  const preprocessor = preprocessors[filterId]?.[preprocessorType];

  return preprocessor ? preprocessor(value) : value;
}

function getFilterId(field: string): AggregationType {
  let filterId;

  switch (true) {
    case field.startsWith(CatalogFilterField.RISK):
      filterId = catalogToInsightsFilterMap[CatalogFilterField.RISK];
      break;
    case field.startsWith(CatalogFilterField.SENSITIVITY):
      filterId = catalogToInsightsFilterMap[CatalogFilterField.SENSITIVITY];
      break;
    case field.startsWith(CatalogFilterField.ACCESS_TYPE):
      filterId = catalogToInsightsFilterMap[CatalogFilterField.ACCESS_TYPE];
      break;
    case field.startsWith(CatalogFilterField.TAGS):
      filterId = catalogToInsightsFilterMap[CatalogFilterField.TAGS];
      break;
    case field === CatalogFilterField.POLICY:
      filterId = catalogToInsightsFilterMap[CatalogFilterField.POLICY];
    default:
      filterId = catalogToInsightsFilterMap[field];
  }

  return filterId;
}

function getFilterOptionId(id: string, parentId: string): string {
  return parentId ? `${parentId}${nestedFilterOptionIdDelimeter}${id}` : id;
}

function getFilterOptions(filter: BigidFieldFilter, transitionFromNewCatalog: boolean): BigidDropdownOption[] {
  const { value, parentId, field } = filter;
  const filterId = getFilterId(field);
  let options: BigidDropdownOption[];

  if (!value) {
    options = [];
  } else {
    const aggItemName = String(value);
    const aggItemGroup =
      !parentIdCatalogFiltersIgnoreList.includes(field as CatalogFilterField) &&
      parentId &&
      getValuePreproccessed(String(parentId), filterId, PreprocessorType.PARENT_ID);
    const id = getFilterOptionId(aggItemName, aggItemGroup);

    const idPreprocessed = transitionFromNewCatalog ? getValuePreproccessed(id, filterId, PreprocessorType.ID) : id;
    const aggItemGroupPreproccessed = transitionFromNewCatalog
      ? getValuePreproccessed(aggItemGroup, filterId, PreprocessorType.AGG_ITEM_GROUP)
      : aggItemGroup;

    options = [
      {
        id: idPreprocessed,
        ...(aggItemGroupPreproccessed ? { parentId: aggItemGroupPreproccessed } : {}),
        value: {
          aggItemName,
          ...(aggItemGroupPreproccessed ? { aggItemGroup: aggItemGroupPreproccessed } : {}),
        },
        displayValue: aggItemName,
        isSelected: true,
      },
    ];
  }

  return options;
}

export function getToolbarValuesFromCatalogDropDownFilters(
  catalogFilters: BigidFilter,
  transitionFromNewCatalog: boolean,
): AdvancedToolbarOverrideValue[] {
  return catalogFilters
    ?.filter(({ value }) => !isNil(value))
    .reduce<AdvancedToolbarOverrideValue[]>((values, catalogFilter) => {
      const filterId = getFilterId(catalogFilter.field);

      switch (true) {
        case catalogFilter.id === AggregationFilterOperand.HAS_DUPLICATES: {
          values.push(booleanFilterOptionsMap[AggregationFilterOperand.HAS_DUPLICATES]);

          return values;
        }
        case filterId === catalogToInsightsFilterMap.field &&
          catalogFilter.id === AggregationFilterOperand.OBJECT_STATUS: {
          values.push(booleanFilterOptionsMap[AggregationFilterOperand.OBJECT_STATUS]);

          return values;
        }
        default: {
          const existingFilterIndex = values.findIndex(({ id }) => id === filterId);

          if (existingFilterIndex >= 0) {
            (values[existingFilterIndex].options as BigidDropdownOption[]).push(
              ...getFilterOptions(catalogFilter, transitionFromNewCatalog),
            );
          } else {
            values.push({
              id: filterId,
              type: BigidAdvancedToolbarFilterTypes.DROPDOWN,
              options: getFilterOptions(catalogFilter, transitionFromNewCatalog),
            });
          }

          return values;
        }
      }
    }, [])
    .filter(({ id }) => id);
}

export function getToolbarValuesFromCatalogFilters(
  { dateRangeFilters = [], dropdownFilters }: GetViewInDataOverviewFiltersResult,
  transitionFromNewCatalog: boolean,
) {
  return [
    ...dateRangeFilters.map(advancedToolbarFilterMinifier.getFilterCompressedToOverrideValue),
    ...getToolbarValuesFromCatalogDropDownFilters(dropdownFilters, transitionFromNewCatalog),
  ];
}
