import React, { FC, useMemo, useState } from 'react';
import { Add as AddIcon, Remove as RemoveIcon, EditOutlined } from '@mui/icons-material';
import { NormalizedSarProfile } from '../SarProfileSettings';
import { BigidLink, ActionData } from '@bigid-ui/components';
import { EntitySource } from '../ProfileSettingsTypes';
import { sarConfigService } from '../sarConfigService';
import { BigidGridColumnTypes, BigidGridWithToolbar, BigidGridWithToolbarProps, BigidGridColumn } from '@bigid-ui/grid';
import { notificationService } from '../../../../services/notificationService';
import { $state } from '../../../../services/angularServices';
import { getCellValueForConnectionStatus, getCellValueForIncludeExclude } from '../gridCellValues';
import { EditDataSourceDialog, updateSources } from './EditDataSourcesDialog';
import { useEditDataSourceDialog } from './useEditDataSourceDialog';
import { EnableAll } from './EnableAll';
import { DSAR_PERMISSIONS } from '@bigid/permissions';
import { isPermitted } from '../../../../services/userPermissionsService';
import { SarProfileSourceTypes, SarProfileTabNames } from '../SarProfileSettingsTypes';
import { useUserPreferences } from '../../../../components/hooks/useUserPrefrences';
import { BigidWarningIcon } from '@bigid-ui/icons';
import { CONFIG } from '../../../../../config/common';
import makeStyles from '@mui/styles/makeStyles';

const columns: BigidGridColumn<EntitySource>[] = [
  {
    title: 'Name',
    name: 'name',
    type: BigidGridColumnTypes.TEXT,
    filteringEnabled: true,
    sortingEnabled: true,
    getCellValue: ({ name }) => {
      return (
        <BigidLink onClick={() => $state.go(`newEntityConnection`, { id: name })} text={name}></BigidLink>
      ) as React.ReactNode;
    },
  },
  {
    title: 'DS Name',
    name: 'dsConnection',
    width: 150,
    type: BigidGridColumnTypes.TEXT,
    filteringEnabled: true,
    sortingEnabled: true,
    getCellValue: ({ dsConnection: name }) => {
      return (
        <BigidLink onClick={() => $state.go(`dataSourceConnection`, { id: name })} text={name}></BigidLink>
      ) as React.ReactNode;
    },
  },
  {
    title: 'Last Test',
    name: 'connectionStatusTest.is_success',
    width: 200,
    type: BigidGridColumnTypes.STATUS,
    getCellValue: ({ connectionStatusTest }) => getCellValueForConnectionStatus(connectionStatusTest),
  },
  {
    title: 'Last Scan',
    name: 'connectionStatusScan.is_success',
    width: 200,
    type: BigidGridColumnTypes.STATUS,
    getCellValue: ({ connectionStatusScan }) => getCellValueForConnectionStatus(connectionStatusScan),
  },
  {
    title: 'Enabled by Admin',
    name: 'enabledByAdmin',
    width: 150,
    type: BigidGridColumnTypes.STATUS,
    getCellValue: ({ sourceEnabled }) => {
      return {
        text: sourceEnabled ? 'Enabled' : 'Disabled',
        status: sourceEnabled === false ? 'warning' : undefined,
      };
    },
  },
  {
    title: 'Case Sensitive',
    name: 'isCaseInSensitive',
    width: 170,
    type: BigidGridColumnTypes.TEXT,
    getCellValue: ({ isCaseInSensitiveSupported, isCaseInSensitive }) =>
      isCaseInSensitiveSupported ? (isCaseInSensitive ? 'Off' : 'On') : 'N/A',
  },
  {
    title: 'Include in Correlation Set Search',
    name: 'profileEnabled',
    type: BigidGridColumnTypes.CHIP,
    getCellValue: ({ profileEnabled }) => getCellValueForIncludeExclude(profileEnabled),
  },
  {
    title: 'Exact Match',
    name: 'isExactMatch',
    width: 170,
    type: BigidGridColumnTypes.TEXT,
    getCellValue: ({ isExactMatchSupported, isExactMatch }) =>
      isExactMatchSupported ? (isExactMatch ? 'On' : 'Off') : 'N/A',
  },
];

const useStyles = makeStyles({
  warning: {
    padding: 20,
    fontSize: 14,
    display: 'flex',
    gap: 8,
    alignItems: 'center',
    paddingTop: 10,
    paddingBottom: 0,
  },
});

export const EntitySources: FC<NormalizedSarProfile> = ({ id: profileId, allEnabledEs }) => {
  const [isEsIncluded, setIsEsIncluded] = useState<boolean>(true);
  const classes = useStyles({});

  if (profileId && $state.params.profileId !== profileId) {
    $state.go(
      CONFIG.states.ACCESS_REQUEST_MANAGEMENT_PROFILE,
      { profileId, tab: SarProfileTabNames.ES },
      { reload: false, notify: false },
    );
  }

  const editDataSourceDialogHook = useEditDataSourceDialog();

  const userPrefStateName = `${CONFIG.states.ACCESS_REQUEST_MANAGEMENT_PROFILE}.${SarProfileTabNames.ES}`;
  const { isReady, preferences, gridColumns, updatePreferences } = useUserPreferences({
    stateName: userPrefStateName,
    initialGridColumns: columns,
  });

  const gridWithToolbarConfig: BigidGridWithToolbarProps<EntitySource> = useMemo(() => {
    const includeExcludeExecutor = async (enabled: boolean, { selectedRows }: ActionData) => {
      if (!selectedRows.length) return {};
      await updateSources(profileId, { enabled }, selectedRows);
      return { shouldGridReload: true, shouldClearSelection: true };
    };

    const gridWithToolbarConfig: BigidGridWithToolbarProps<EntitySource> = {
      columns: gridColumns,
      entityName: 'Correlation Sets',
      showSortingControls: true,
      showFilteringControls: true,
      defaultSorting: preferences?.grid?.sort || [{ field: 'name', order: 'asc' }],
      onGridStateChange: nextGridState => updatePreferences({ gridState: { ...nextGridState, filter: undefined } }),
      toolbarActions: [
        {
          label: 'Include',
          icon: AddIcon,
          execute: actionData => includeExcludeExecutor(true, actionData),
          disable: ({ selectedRows }) => !selectedRows.length,
          show: ({ totalRows }) => !!totalRows && isPermitted(DSAR_PERMISSIONS.EDIT_PROFILE_SETTINGS.name),
        },
        {
          label: 'Exclude',
          icon: RemoveIcon,
          execute: actionData => includeExcludeExecutor(false, actionData),
          disable: ({ selectedRows }) => !selectedRows.length,
          show: ({ totalRows }) => !!totalRows && isPermitted(DSAR_PERMISSIONS.EDIT_PROFILE_SETTINGS.name),
        },
        {
          label: 'Edit',
          icon: EditOutlined,
          execute: async ({ selectedRows }) => {
            if (!selectedRows.length) return {};
            const actionResult = await editDataSourceDialogHook.openDialog({
              profileId,
              sources: selectedRows,
            });
            return { shouldGridReload: actionResult, shouldClearSelection: actionResult };
          },
          disable: ({ selectedRows }) => !selectedRows.length,
          show: ({ totalRows }) => !!totalRows && isPermitted(DSAR_PERMISSIONS.EDIT_PROFILE_SETTINGS.name),
        },
      ],
      pageSize: 5000,
      fetchData: async gridQueryParams => {
        let esConnections: EntitySource[] = [];
        try {
          const profile = await sarConfigService.getProfileById(profileId, gridQueryParams, {
            excludeDsConnections: true,
          });
          esConnections = profile.esConnections;
          setIsEsIncluded(esConnections.some(({ profileEnabled }) => profileEnabled));
        } catch (err) {
          notificationService.error(`Failed to get Correlation Sets.`);
          console.error(`Failed to get SAR Request profile by id '${profileId}'`, JSON.stringify(err));
        }

        return {
          data: esConnections,
          totalCount: esConnections.length,
        };
      },
    };

    return gridWithToolbarConfig;
  }, [gridColumns, preferences?.grid?.sort, profileId, updatePreferences, editDataSourceDialogHook]);

  return (
    <>
      <EditDataSourceDialog sourceType={SarProfileSourceTypes.ES} {...editDataSourceDialogHook.dialogProps} />
      {isPermitted(DSAR_PERMISSIONS.MANAGE_ALL_ENABLED_PROFILE_SETTINGS.name) || allEnabledEs ? (
        <EnableAll
          key={`EnableAll_${profileId}`}
          sourceType={SarProfileSourceTypes.ES}
          profileId={profileId}
          isAllEnabled={allEnabledEs}
        />
      ) : null}
      {isReady && !allEnabledEs && !isEsIncluded && (
        <div className={classes.warning}>
          <BigidWarningIcon />
          <span>At least 1 Correlation Set should be selected in order to perform an Attributes search.</span>
        </div>
      )}
      {!allEnabledEs && isReady ? (
        <BigidGridWithToolbar key={`BigidGridWithToolbar_${profileId}`} {...gridWithToolbarConfig} />
      ) : null}
    </>
  );
};
