import React, { FC, MouseEvent } from 'react';
import { SecondaryButton } from '@bigid-ui/components';
import angular from 'angular';
import { convertToAngular } from '../../common/services/convertToAngular';

export const AddAnotherButton: FC<{ onClick: (event?: MouseEvent) => void }> = ({ onClick }) => {
  const text = '+ Add another Data Source';
  return <SecondaryButton size="medium" onClick={onClick} text={text} />;
};

angular.module('app').component('addAnotherButton', convertToAngular(AddAnotherButton));
