import { BigidTextField, TextFieldIconPositionEnum } from '@bigid-ui/components';
import React, { ChangeEvent, FC, KeyboardEvent, useRef, useState } from 'react';
import { RootResults } from './components/RootResults';
import { BigidSearchIcon } from '@bigid-ui/icons';
import { CONFIG } from '../../../../config/common';
import { $state } from '../../../services/angularServices';
import { styled } from '@mui/material';
import { generateDataAid } from '@bigid-ui/utils';

const DataExplorerQuickSearchContainer = styled('div')<{ width: number }>`
  width: ${props => (props.width ? `${props.width}px` : 'auto')};
`;

type DataExplorerQuickSearchProps = {
  quickSearchDisabled?: boolean;
  placeholder?: string;
  initialSearchValue?: string;
  dataAid?: string;
  isDisabled?: boolean;
  isSmall?: boolean;
  width?: number;
  onSubmit?: (searchText: string) => void;
};

export const DataExplorerQuickSearch: FC<DataExplorerQuickSearchProps> = ({
  quickSearchDisabled,
  placeholder,
  initialSearchValue = '',
  dataAid = 'DataExplorerQuickSearch',
  isDisabled,
  isSmall,
  width,
  onSubmit,
}) => {
  const [searchValue, setSearchValue] = useState(initialSearchValue);
  const anchorEl = useRef<HTMLDivElement>(null);

  const handleSearchSubmit = (event: KeyboardEvent<HTMLDivElement>) => {
    if (event.key === 'Enter') {
      onSubmit?.(searchValue.trim());
      $state.go(CONFIG.states.CATALOG_SEARCH_RESULTS, { query: searchValue.trim() });
    }
  };

  const handleSearchChange = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setSearchValue(event.target.value);
  };

  return (
    <DataExplorerQuickSearchContainer ref={anchorEl} width={width}>
      <BigidTextField
        size={isSmall ? 'medium' : 'large'}
        onChange={handleSearchChange}
        onKeyPress={handleSearchSubmit}
        placeholder={placeholder}
        value={searchValue}
        disabled={isDisabled}
        dataAid={generateDataAid(dataAid, ['search-bar'])}
        icon={{
          Icon: BigidSearchIcon,
          position: TextFieldIconPositionEnum.right,
        }}
      />
      {!quickSearchDisabled && <RootResults anchorEl={anchorEl} searchText={searchValue} />}
    </DataExplorerQuickSearchContainer>
  );
};
