import React, { FC } from 'react';
import styled from '@emotion/styled';
import { SavedQueriesActions, SavedQueriesActionsProps } from './SavedQueriesActions';
import { BigidHeading5 } from '@bigid-ui/components';

export type SavedQueriesHeaderProps = SavedQueriesActionsProps & {
  title: string;
  showActions?: boolean;
};

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 24px;
  gap: 16px;
  height: 100%;
`;

const styles = {
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  fontWeight: 700,
};

export const SavedQueriesHeader: FC<SavedQueriesHeaderProps> = ({ title, buttons, showActions }) => (
  <Header>
    <BigidHeading5 sx={styles}>{title}</BigidHeading5>
    {showActions && <SavedQueriesActions buttons={buttons} />}
  </Header>
);
