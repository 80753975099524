import React, { FC, memo, useEffect } from 'react';
import {
  BigidFieldRenderProps,
  compareObjectsExceptFunctions,
  BigidLoader,
  BigidSelect,
  BigidFormFieldSideLabelWrapper,
  BigidBody1,
  BigidSelectOption,
  BigidPaper,
  BigidFormFieldErrorHelper,
} from '@bigid-ui/components';
import { useScanWindowOptions, NONE_OPTION } from '../hooks/useScanWindowOptions';
import makeStyles from '@mui/styles/makeStyles';
import { getApplicationPreference } from '../../../../services/appPreferencesService';

const ERROR = 'isError';
const SCAN_WINDOW_DEFAULT_TOOLTIP =
  'Note that changing the scan window affects currently running scans as well as future scans.';
const DYNAMIC_MODIFY_SCAN_WINDOW_FF = 'DYNAMIC_MODIFY_SCAN_WINDOW';
const isDynamicModifyScanWindowEnabled = () => getApplicationPreference(DYNAMIC_MODIFY_SCAN_WINDOW_FF);

const useInfoStyle = makeStyles({
  infoClass: {
    margin: '5px 0 0 0',
  },
  paper: {
    width: '250px',
  },
  infoWrapper: {
    paddingTop: '5px',
    display: 'flex',
    flexDirection: 'column',
  },
});

export const FormScanWindowField: FC<BigidFieldRenderProps> = memo(props => {
  const { name, label, isRequired, misc, labelWidth, value, setValue, tooltipText } = props;
  const id = `bigid-form-field-${name}`;
  const dataAid = `${id}`;
  const { isLoading, options, showTooltip, disabled, bottomInfo } = useScanWindowOptions();
  const { infoClass, infoWrapper, paper } = useInfoStyle({});

  const isError = (value: BigidSelectOption[]) => {
    return value[0]?.value === ERROR;
  };

  useEffect(() => {
    const currentValue = value[0];
    const scanWindowName = currentValue?.label;
    const label = scanWindowName ? scanWindowName : NONE_OPTION.label;
    const notExistsInScanWindowCol = scanWindowName && bottomInfo && bottomInfo[scanWindowName] === undefined;
    if (notExistsInScanWindowCol) {
      setValue([{ id: ERROR, label: label, value: ERROR }]);
    } else if (
      currentValue?.id !== undefined &&
      Array.isArray(currentValue?.id) === false &&
      currentValue?.id !== label
    ) {
      setValue([{ id: label, label: label, value: label }]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bottomInfo]);

  const getBottomInfoValues = (value: BigidSelectOption[]) => {
    const scanWindowName = value[0]?.value;
    if (Array.isArray(scanWindowName) || scanWindowName === undefined) {
      return [];
    }
    if (bottomInfo[scanWindowName] != null) {
      return bottomInfo[scanWindowName];
    } else {
      return [];
    }
  };
  const scanWindowTooltipMessage = isDynamicModifyScanWindowEnabled() ? SCAN_WINDOW_DEFAULT_TOOLTIP : '';
  return isLoading ? (
    <BigidLoader />
  ) : (
    <BigidFormFieldSideLabelWrapper
      id={id}
      name={name}
      label={String(label)}
      isRequired={isRequired}
      isSeparatorAfter={misc?.isSeparatorAfter}
      labelWidth={labelWidth}
      tooltipText={showTooltip ? tooltipText : scanWindowTooltipMessage}
      withInnerWrapper={true}
      fullWidth={misc?.fullWidth}
    >
      <BigidSelect
        {...props}
        options={options}
        value={value}
        onChange={(value: BigidSelectOption[]) => {
          setValue(value);
        }}
        isDisabled={disabled}
        isSearchable={true}
        dataAid={dataAid}
        menuPlacement="top"
      />
      <div className={infoWrapper}>
        <BigidPaper hasShadow={false} isOutlined={false} classes={{ elevation0: paper }}>
          {!isError(value) &&
            getBottomInfoValues(value).map((info, index) => (
              <BigidBody1 className={infoClass} key={index}>
                {info}
              </BigidBody1>
            ))}
          {isError(value) && (
            <BigidFormFieldErrorHelper
              errorMessage={"Current scan window doesn't exists. Please select from the above list"}
            />
          )}
        </BigidPaper>
      </div>
    </BigidFormFieldSideLabelWrapper>
  );
}, compareObjectsExceptFunctions);

FormScanWindowField.displayName = 'FormScanWindowField';
