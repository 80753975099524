import { BigidFieldFilter, BigidFieldFilterOperator, BigidFilter } from '@bigid-ui/components';
import { BigidSearchQuery, fieldFilterOperatorsMapping, getFieldFiltersSorted, GetValueProps } from '@bigid-ui/layout';
import React from 'react';
import {
  getAttrTags,
  Hotspot,
  HotspotChartRow,
  HotspotsReportStatus,
  HotspotsScanResult,
  scanForLatestHotspotId,
} from './HotspotsReportChartService';
import { isEqual } from 'lodash';
import { BigidGridWithToolbarProps } from '@bigid-ui/grid';

const AMOUNT_HOT_SPOT_RETRY = 6;

const setImmediateInterval = (func: () => void, intervalPeriod: number) => {
  func();
  return window.setInterval(func, intervalPeriod);
};

export const performScan = (
  intervalNumber: React.MutableRefObject<number>,
  queryDatasources: string[],
  withPolling?: boolean,
) => {
  let pollingAmount = 0;
  window.clearInterval(intervalNumber.current);
  return new Promise<{ scanResult: HotspotsScanResult; attributes: string[]; tags: string[] }>((resolve, reject) => {
    intervalNumber.current = setImmediateInterval(async () => {
      const scanResult = await scanForLatestHotspotId(queryDatasources);
      pollingAmount++;
      if (scanResult?.state === HotspotsReportStatus.COMPLETED) {
        const { attributes, tags } = await getAttrTags(scanResult.id);
        window.clearInterval(intervalNumber.current);
        resolve({ scanResult, attributes, tags });
      } else if (!scanResult || !('state' in scanResult)) {
        window.clearInterval(intervalNumber.current);
        reject('Scan failed');
      } else if (['Failed', 'Expired'].includes(scanResult.state)) {
        window.clearInterval(intervalNumber.current);
        reject('Scan ' + scanResult.state);
      } else if (
        withPolling &&
        scanResult.state === HotspotsReportStatus.INPROGRESS &&
        pollingAmount >= AMOUNT_HOT_SPOT_RETRY
      ) {
        window.clearInterval(intervalNumber.current);
        reject('Scan failed because of out of polling interval and scan still InProgress');
      }
    }, 5000);
  });
};

export const datasourcesChanged = (previousFilters: BigidFieldFilter[], currentFilters: BigidFieldFilter[]) =>
  !isEqual(
    previousFilters.find(filter => filter.field === 'datasources'),
    currentFilters.find(filter => filter.field === 'datasources'),
  );

export const hotspotToHotspotChartRow = (hotspot: Hotspot): HotspotChartRow => {
  const attributes = hotspot.attrTagsList
    .filter(attrTag => attrTag.type === 'attributes')
    .map(attribute => attribute.name);
  const tags = hotspot.attrTagsList.filter(attrTag => attrTag.type === 'tags').map(tag => tag.name);
  return {
    ...hotspot,
    attributes,
    tags,
  };
};

export const createEmptyFilterToolbarConfig = (
  attributes: string[],
  tags: string[],
  datasources: string[],
): BigidGridWithToolbarProps<HotspotChartRow>['filterToolbarConfig'] => ({
  filters: [
    {
      title: 'Data Sources',
      field: 'datasources',
      operator: 'in' as BigidFieldFilterOperator,
      options: datasources.map(v => ({ value: v, label: v, isSelected: false })),
      value: [],
    },
    {
      title: 'Attributes',
      field: 'attributes',
      operator: 'in' as BigidFieldFilterOperator,
      options: attributes.map(v => ({ value: v, label: v, isSelected: false })),
      value: [],
    },
    {
      title: 'Tags',
      field: 'tags',
      operator: 'in' as BigidFieldFilterOperator,
      options: tags.map(v => ({ value: v, label: v, isSelected: false })),
      value: [],
    },
  ],
});

export const createFilterToolbarConfigWithPreselectedDatasources = (
  attributes: string[],
  tags: string[],
  selectedDatasources: string[],
) => {
  const setterFunction = (
    filterToolbarConfig: BigidGridWithToolbarProps<HotspotChartRow>['filterToolbarConfig'],
  ): BigidGridWithToolbarProps<HotspotChartRow>['filterToolbarConfig'] => ({
    ...filterToolbarConfig,
    filters: [
      {
        title: 'Data Sources',
        field: 'datasources',
        operator: 'in' as BigidFieldFilterOperator,
        options: filterToolbarConfig.filters
          .find(v => v.field === 'datasources')
          .options.map(v => {
            v.isSelected = selectedDatasources.includes(v.value as string);
            return v;
          }),
        value: selectedDatasources,
      },
      {
        title: 'Attributes',
        field: 'attributes',
        operator: 'in' as BigidFieldFilterOperator,
        options: attributes.map(v => ({ value: v, label: v, isSelected: false })),
        value: [],
      },
      {
        title: 'Tags',
        field: 'tags',
        operator: 'in' as BigidFieldFilterOperator,
        options: tags.map(v => ({ value: v, label: v, isSelected: false })),
        value: [],
      },
    ],
  });
  return setterFunction;
};

export const insertLineBreakEveryNCharacters = (text: string, n: number): string => {
  const replace = `.{${n}}`;
  const re = new RegExp(replace, 'g');
  return text.replace(re, '$&\n');
};

const getValue = ({ values, filterValues, useExperimentalFormat, queryOperator }: GetValueProps) => {
  if (values.length > 1) {
    return `(${filterValues
      .reduce((fields, value) => [...fields, useExperimentalFormat ? `"${value}"` : value], [])
      .join(',')})`;
  } else if (queryOperator === 'IN') {
    if (useExperimentalFormat) {
      return `("${values[0]}")`;
    } else {
      return `(${values[0]})`;
    }
  } else if (useExperimentalFormat) {
    return `"${values[0]}"`;
  } else {
    return values[0];
  }
};

export const parseFieldFiltersToSearchQuery = (
  filter: BigidFilter,
  useExperimentalFormat?: boolean,
  logicalOperator: 'AND' | 'OR' = 'AND',
): BigidSearchQuery => {
  const filterUnselected = filter.filter(({ isSelected }) => !isSelected);
  const sortedFieldFilters = getFieldFiltersSorted(filterUnselected);
  return Object.keys(sortedFieldFilters).reduce((searchQueryAggregated, fieldName) => {
    const fieldQuery = [...sortedFieldFilters[fieldName].keys()].reduce((fieldQueryAggregated, operator) => {
      let queryOperator = fieldFilterOperatorsMapping[operator];
      const filterValues = sortedFieldFilters[fieldName].get(operator);

      if (queryOperator === '=' && filterValues.length > 1) {
        queryOperator = 'IN';
      }

      const values = filterValues.filter(value => value);

      if (queryOperator && values.length > 0) {
        const value = getValue({ values, filterValues, useExperimentalFormat, queryOperator });
        const query = `${fieldName} ${queryOperator} ${value}`;

        return fieldQueryAggregated.length > 0 ? `${fieldQueryAggregated} ${logicalOperator} ${query}` : query;
      } else {
        return fieldQueryAggregated;
      }
    }, '');

    return searchQueryAggregated.length > 0
      ? fieldQuery.length > 0
        ? `${searchQueryAggregated} ${logicalOperator} ${fieldQuery}`
        : searchQueryAggregated
      : fieldQuery;
  }, '');
};

export const getTagsFilters = (tags: string[]): BigidFilter => {
  const tagsMap: { [key: string]: Set<string> } = {};
  tags.forEach(tag => {
    const tagName = tag.split('/')[0];
    const tagValue = tag.split('/')[1];
    if (!tagsMap[tagName]) {
      tagsMap[tagName] = new Set<string>();
    }
    tagsMap[tagName].add(tagValue);
  });

  const result: BigidFilter = [
    {
      field: 'catalog_tag',
      value: undefined,
      operator: 'equal',
    },
  ];
  Object.keys(tagsMap).forEach(key => {
    tagsMap[key].forEach(v => {
      result.push({
        field: 'catalog_tag.' + key,
        value: v,
        operator: 'equal',
      });
    });
  });
  return result;
};
export const wrapWithBracketsIfNotEmpty = (text: string) => (text ? '(' + text + ')' : '');

export const getHotspotNameFilterAsString = (hotspotNames: string[]): string =>
  hotspotNames.map(hotspotName => `fullyQualifiedName = "${hotspotName}"`).join(' OR ');

export const getAttributesFilterAsString = (attributes: string[]): string =>
  attributes?.length > 0 ? 'field IN ' + ['(', attributes.map(v => `"${v}"`).join(','), ')'].join('') : '';

export const getTagsFilterAsString = (tags: string[]): string =>
  parseFieldFiltersToSearchQuery(getTagsFilters(tags), false, 'OR');

export const getOpenInCatalogFilterString = (selectedRows: HotspotChartRow[]): string => {
  const hotspotNameFilterAsString = getHotspotNameFilterAsString(selectedRows.map(row => row.hotspotName));
  const attributesFilterAsString = getAttributesFilterAsString(selectedRows?.[0].selectedAttributes ?? []);
  const tagsFilterAsString = getTagsFilterAsString(selectedRows?.[0].selectedTags ?? []);

  const tagsAndFiltersFilterAsString = [tagsFilterAsString, attributesFilterAsString].filter(Boolean).join(' OR ');
  return [hotspotNameFilterAsString, tagsAndFiltersFilterAsString]
    .filter(Boolean)
    .map(text => wrapWithBracketsIfNotEmpty(text))
    .join(' AND ');
};
