import React, { FC, memo } from 'react';
import { makeStyles } from '@mui/styles';
import { BigidTooltip } from '@bigid-ui/components';
import { generateDataAid } from '@bigid-ui/utils';
import { AttributePreviewFindingStatusIndicator } from './AttributePreviewFindingStatusIndicator';
import { AttributePreviewFindingLegend } from './AttributePreviewFindingLegend';
import { CuratedFieldStatus } from '../../../../curationService';

export type AttributePreviewFindingStatusProps = {
  dataAid?: string;
  approvedFindingsCount: number;
  rejectedFindingsCount: number;
  uncuratedFindingsCount: number;
};

const useStyles = makeStyles({
  root: {
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
  },
});

export const AttributePreviewFindingStatus: FC<AttributePreviewFindingStatusProps> = memo(
  ({
    dataAid = 'AttributePreviewFindingStatus',
    uncuratedFindingsCount,
    approvedFindingsCount,
    rejectedFindingsCount,
  }) => {
    const classes = useStyles();

    return (
      <BigidTooltip
        title={
          <AttributePreviewFindingLegend
            dataAid={generateDataAid(dataAid, ['legend'])}
            uncuratedFindingsCount={uncuratedFindingsCount}
            approvedFindingsCount={approvedFindingsCount}
            rejectedFindingsCount={rejectedFindingsCount}
          />
        }
      >
        <div className={classes.root}>
          {/* NOTE: temporarily disabled */}
          {/* <AttributePreviewFindingStatusIndicator
            data-aid={generateDataAid(dataAid, ['approved-findings-indicator'])}
            status={CuratedFieldStatus.APPROVED}
            findingsCount={approvedFindingsCount}
          /> */}
          <AttributePreviewFindingStatusIndicator
            data-aid={generateDataAid(dataAid, ['rejected-findings-indicator'])}
            status={CuratedFieldStatus.REJECTED}
            findingsCount={rejectedFindingsCount}
          />
          <AttributePreviewFindingStatusIndicator
            data-aid={generateDataAid(dataAid, ['uncurated-findings-indicator'])}
            status={CuratedFieldStatus.UNCURATED}
            findingsCount={uncuratedFindingsCount}
          />
        </div>
      </BigidTooltip>
    );
  },
);
