import React from 'react';
import { BigidBody1, BigidHeading3, BigidSidePanelProps } from '@bigid-ui/components';
import { generateDataAid } from '@bigid-ui/utils';
import { CatalogSidePanel } from './CatalogSidePanel/CatalogSidePanel';
import { CatalogSearchGridRow, GridCardData, ResultsEntityType } from '../../../types';
import { SidePanelTitle } from './SidePanelTitle';
import { DataCatalogAttribute } from '../../../../DataCatalog/DataCatalogAttributes';
import {
  DataCatalogObjectDetails,
  ObjectAttributeDetails,
} from '../../../../DataCatalog/DataCatalogDetails/DataCatalogDetailsService';
import { Highlight } from '../../../utils';

type CustomSidePanelProps = Pick<BigidSidePanelProps, 'dataAid' | 'title' | 'content' | 'customTitleComponent'>;

export type GetSidePanelComponentProps<T extends keyof GridCardData> = {
  entityType: ResultsEntityType;
  selectedItem: CatalogSearchGridRow<T>;
  highlightValue?: string;
};

export function getSidePanelComponentProps<T extends keyof GridCardData>({
  entityType,
  selectedItem,
  highlightValue,
}: GetSidePanelComponentProps<T>): CustomSidePanelProps {
  let sidePanelProps: CustomSidePanelProps = {
    content: null,
    title: null,
  };

  if (selectedItem) {
    switch (entityType) {
      case 'catalog':
        const item = selectedItem as CatalogSearchGridRow<'catalog'>;
        const { objectName } = item.data;
        sidePanelProps = {
          dataAid: generateDataAid('BigidSidePanel', ['catalog']),
          title: objectName,
          customTitleComponent: <SidePanelTitle selectedItem={item} highlightValue={highlightValue} />,
          content: (
            <CatalogSidePanel
              dataAid={generateDataAid('BigidSidePanel', ['catalog', 'content'])}
              selectedItem={item}
              highlightValue={highlightValue}
            />
          ),
        };
    }
  }

  return sidePanelProps;
}

function getNumberOfFindings(attributesDetails: ObjectAttributeDetails[], attributeName: string): number {
  let numberOfFindings;
  const attributeDetails = attributesDetails?.find(({ name }) => name === attributeName);

  if (attributeDetails) {
    numberOfFindings = attributeDetails.count;
  }

  return numberOfFindings;
}

export type AttributesGridColumn = {
  id: string;
  attribute_name: string;
  numberOfFindings: number;
};

export const getAttributesGridData = (
  data: DataCatalogAttribute[] = [],
  objectDetails: DataCatalogObjectDetails,
): AttributesGridColumn[] => {
  return data.map(att => {
    const { attribute_id, attribute_original_name, attribute_name } = att;
    const { attribute_details } = objectDetails || {};

    return {
      id: attribute_id,
      attribute_name,
      numberOfFindings: getNumberOfFindings(attribute_details, attribute_original_name),
    };
  });
};

export const getHighlightedElement = (title: string, highlightValue: string, type: 'heading' | 'body' = 'heading') => {
  const TypographyComponent = type === 'heading' ? BigidHeading3 : BigidBody1;
  if (!highlightValue) {
    return (
      <TypographyComponent overflow="inherit" textOverflow="inherit">
        {title}
      </TypographyComponent>
    );
  }

  const parts = title.split(new RegExp(`(${highlightValue})`, 'gi'));

  return (
    <TypographyComponent overflow="inherit" textOverflow="inherit">
      {parts.map((part, index) =>
        part.toLowerCase() === highlightValue.toLowerCase() ? <Highlight key={index}>{part}</Highlight> : part,
      )}
    </TypographyComponent>
  );
};
