import angular from 'angular';
import React, { FC } from 'react';
import { convertToAngular } from '../../../../common/services/convertToAngular';
import { BigidLayout, BigidLayoutConfig, BigidMasterDetailsContentProps, LayoutContentType } from '@bigid-ui/layout';
import { BigidGridColumn, BigidGridColumnTypes, BigidGridProps } from '@bigid-ui/grid';
import { ConsentReportGrid } from './ConsentReportGrid';

export interface ConsentReportOptions {
  id: string;
  name: string;
}

const consentReportOptions: ConsentReportOptions[] = [
  {
    id: 'purposes types',
    name: 'Purpose of Processing',
  },
  {
    id: 'lawfulness',
    name: 'Lawfulness of Processing',
  },
];

const columns: BigidGridColumn<ConsentReportOptions>[] = [
  {
    title: 'Report Selected',
    name: 'objectName',
    isListColumn: true,
    type: BigidGridColumnTypes.TEXT,
    getCellValue: ({ name }) => name,
  },
];

export const ConsentReport: FC = () => {
  const profilesGridConfig: BigidGridProps<ConsentReportOptions> = {
    showSelectionColumn: false,
    columns,
  };

  const fetchGridData = async () => {
    return {
      totalCount: consentReportOptions.length,
      data: consentReportOptions,
    };
  };

  const masterDetailsConfig: BigidMasterDetailsContentProps = {
    isPersistentListMode: true,
    tabsAndContent: {
      hideTabs: true,
      tabProps: {
        selectedIndex: 0,
        tabs: [
          {
            label: 'Grid',
            data: {
              component: ConsentReportGrid,
            },
          },
        ],
      },
    },
  };

  const layoutConfig: BigidLayoutConfig = {
    content: {
      entityName: 'Reports',
      noPadding: true,
      contentTypes: [LayoutContentType.MASTER_DETAILS],
      viewConfig: {
        fetchGridData,
        gridConfig: profilesGridConfig,
        masterDetailsConfig,
      },
    },
  };

  return (
    <React.Fragment>
      <BigidLayout config={layoutConfig} />
    </React.Fragment>
  );
};

angular.module('app').component('consentReport', convertToAngular(ConsentReport));
