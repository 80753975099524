import React, { FC } from 'react';
import styled from '@emotion/styled';
import { BigidBody1, BigidHeading3 } from '@bigid-ui/components';
import { generateDataAid } from '@bigid-ui/utils';

const Title = styled(BigidHeading3)`
  font-size: 16px;
  font-weight: 700;
  margin-bottom: 8px;
`;
const BottomContent = styled('div')`
  margin-top: 16px;
`;

const List = styled('ol')`
  font-size: 13px;
  padding-left: 22px;
  display: flex;
  flex-direction: column;
  gap: 5px;
`;

const InlineText = styled(BigidBody1)`
  display: inline;
`;

const ListItem: FC<{ text: string; actionName: string }> = ({ actionName, text }) => {
  return (
    <li>
      <InlineText fontWeight={700}>{actionName}</InlineText> - <InlineText>{text}</InlineText>
    </li>
  );
};

const ListDescription: FC = () => {
  return (
    <List>
      <ListItem
        actionName="Save Scan"
        text="Click ‘Save Scan’ to save your new scan and template, which are now accessible under Saved Scans."
      />
      <ListItem
        actionName="Schedule a Scan"
        text="Click ‘Schedule Scan’ to save your new scan and template and set up a schedule."
      />
      <ListItem
        actionName="Scan Now"
        text={`Click ‘Scan Now’ to save your new template and run your new scan. You'll find it under the ‘Scans’ tab.`}
      />
    </List>
  );
};

interface ScanActionsProps {
  dataAid?: string;
}

export const ScanActions: FC<ScanActionsProps> = ({ dataAid = 'ScanActions' }) => {
  return (
    <>
      <Title data-aid={generateDataAid(dataAid, ['title'])}>Scan Actions</Title>
      <BottomContent data-aid={generateDataAid(dataAid, ['bottomSection'])}>
        <ListDescription />
      </BottomContent>
    </>
  );
};
