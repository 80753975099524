import React, { FC, useCallback, useMemo, useState } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { BigidGridColumn, BigidGridRow, BigidGridColumnTypes, NextGridState } from '@bigid-ui/grid';
import { BigidLayoutConfig, BigidLayout, LayoutContentType } from '@bigid-ui/layout';
import { BigidColorsV2, PrimaryButton } from '@bigid-ui/components';
import {
  Classifier,
  updateClassifiersVersionBulk,
  isClassifierBulkVersionUpdateSucceeded,
  getClassifierVersion,
  ClassifierVersionToUpdateConfig,
  ClassifierUpdateVersion,
} from '../../services/classifiersService';
import { mapUpdatePropsToClassifierProps } from './classifierUpdatePreviewService';
import { ClassifierUpdatePreview } from './ClassifierUpdatePreview';
import { notificationService } from '../../services/notificationService';

type ClassifierExtended = Classifier & BigidGridRow;

type ClassifierUpdateVersionMap = Map<Classifier['classification_name'], ClassifierVersionToUpdateConfig>;

type ClassifierUpdateVersionBulkResultSortedOut = {
  succeeded: Partial<Classifier>[];
  failed: Partial<Classifier>[];
};

export interface ClassifierUpdatePreviewBulkProps {
  classifiers: Classifier[];
  onUpdate: (updatedClassifiers: Partial<Classifier>[]) => void;
  onClose?: () => void;
  dataAid?: string;
}

const useStyles = makeStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
    height: '600px',
    width: '100%',
  },
  body: {
    flex: 1,
    display: 'contents',
  },
  content: {
    display: 'flex',
    overflow: 'auto',
    flexFlow: 'row nowrap',
    flex: '1 1 auto',
  },
  footer: {
    display: 'flex',
    justifyContent: 'flex-end',
    paddingTop: '16px',
    borderTop: `1px solid ${BigidColorsV2.gray[100]}`,
  },
});

const getIsUpdateButtonDisabled = (selectionState: NextGridState): boolean => {
  let isDisabled = true;

  if (selectionState?.selectedRowIds) {
    isDisabled = selectionState.selectedRowIds.length === 0;
  }

  return isDisabled;
};

const getUpdateButtonLabel = (selectionState: NextGridState, isUpdateButtonDisabled: boolean): string => {
  let label;

  if (isUpdateButtonDisabled) {
    label = 'Update';
  } else {
    const { selectedRowIds = [], allSelected = false } = selectionState;
    const selectedRowIdsCount = selectedRowIds.length;

    if (allSelected) {
      label = selectedRowIds.length === 1 ? 'Update 1 classifier' : `Update all Classifiers`;
    } else {
      label = `Update ${selectedRowIdsCount} ${selectedRowIdsCount === 1 ? 'Classifier' : 'Classifiers'}`;
    }
  }

  return label;
};

export const ClassifierUpdatePreviewBulk: FC<ClassifierUpdatePreviewBulkProps> = ({
  classifiers,
  onUpdate,
  onClose,
  dataAid = 'ClassifierUpdatePreviewBulk',
}) => {
  const classes = useStyles();

  const [selectionState, setSelectionState] = useState<NextGridState>(null);

  const columns: BigidGridColumn<ClassifierExtended>[] = useMemo(
    () => [
      {
        width: 250,
        title: 'Classifiers',
        name: 'classification_name',
        isListColumn: true,
        type: BigidGridColumnTypes.TEXT,
        getCellValue: ({ classification_name }) => classification_name,
      },
    ],
    [],
  );

  const layoutConfig: BigidLayoutConfig = useMemo(
    () => ({
      content: {
        contentTypes: [LayoutContentType.MASTER_DETAILS],
        defaultContentType: LayoutContentType.MASTER_DETAILS,
        viewConfig: {
          onGridStateChange: ({ selectedRowIds, allSelected }: NextGridState) => {
            setSelectionState({ selectedRowIds, allSelected });
          },
          fetchGridData: async () => {
            return {
              totalCount: classifiers.length,
              data: classifiers
                .sort((a, b) => {
                  const currClassifierName = a.classification_name.toLowerCase();
                  const nextClassifierName = b.classification_name.toLowerCase();

                  if (currClassifierName < nextClassifierName) {
                    return -1;
                  } else if (currClassifierName > nextClassifierName) {
                    return 1;
                  } else {
                    return 0;
                  }
                })
                .map(classifier => ({ ...classifier, id: classifier._id })),
            };
          },
          gridConfig: {
            dataAid: `${dataAid}-grid`,
            columns,
            pageSize: 50,
            showSortingControls: false,
            showSelectionColumn: true,
            showSelectionCheckboxes: true,
            rowClickShouldKeepSelection: true,
          },
          masterDetailsConfig: {
            isHeaderHidden: true,
            isPersistentListMode: true,
            tabsAndContent: {
              hideTabs: true,
              classes: {
                contentContainer: classes.content,
              },
              tabProps: {
                selectedIndex: 0,
                tabs: [
                  {
                    label: '',
                    data: {
                      component: ClassifierUpdatePreview,
                    },
                  },
                ],
              },
            },
          },
          toolbarConfig: {
            hideToolbar: true,
          },
          selectedItemPropsMapping: {
            id: 'classification_name',
            name: 'classification_name',
            classifierName: 'classification_name',
          },
        },
      },
    }),
    [classes.content, classifiers, columns, dataAid],
  );

  const handleUpdateClick = useCallback(async () => {
    const { selectedRowIds, allSelected } = selectionState;

    const classifiersToUpdate = allSelected
      ? classifiers
      : classifiers.filter(({ _id }) => {
          return selectedRowIds.includes(_id);
        });

    Promise.all(classifiersToUpdate.map(({ classification_name }) => getClassifierVersion(classification_name)))
      .then(versions => {
        const classifiersUpdateMap: ClassifierUpdateVersionMap = new Map();
        const classifiersVersionsToUpdate: ClassifierUpdateVersion[] = [];

        versions.forEach(({ new: newVersion }, index) => {
          const classifierName = classifiersToUpdate[index].classification_name;
          classifiersVersionsToUpdate.push({
            name: classifierName,
            version: newVersion.version,
          });
          classifiersUpdateMap.set(classifierName, newVersion);
        });

        updateClassifiersVersionBulk({ updates: classifiersVersionsToUpdate })
          .then(response => {
            const { succeeded, failed }: ClassifierUpdateVersionBulkResultSortedOut = response.reduce(
              (updatesAggr, classifierStatus) => {
                const [classifierName, classifierUpdateStatus] = classifierStatus;
                const classifierUpdate = classifiersUpdateMap.get(classifierName);

                if (classifierUpdate) {
                  const classifier: Partial<Classifier> = mapUpdatePropsToClassifierProps({
                    classification_name: classifierName,
                    ...classifierUpdate,
                    ...classifierUpdateStatus,
                  });

                  if (isClassifierBulkVersionUpdateSucceeded(classifierStatus)) {
                    return { ...updatesAggr, succeeded: [...updatesAggr.succeeded, classifier] };
                  } else {
                    return { ...updatesAggr, failed: [...updatesAggr.failed, classifier] };
                  }
                } else {
                  return updatesAggr;
                }
              },
              {
                succeeded: [],
                failed: [],
              },
            );

            const succeededUpdatesCount = succeeded.length;
            const failedUpdatesCount = failed.length;

            if (failedUpdatesCount > 0) {
              if (succeededUpdatesCount > 0) {
                notificationService.warning(
                  `${succeededUpdatesCount} selected classifiers were updated to the latest version. ${failedUpdatesCount} classifiers failed to update.`,
                );
              } else {
                notificationService.warning(`${failedUpdatesCount} classifiers failed to update.`);
              }
            } else {
              notificationService.success('All selected classifiers were updated to the latest version.');
            }

            onUpdate(succeeded);
            onClose();
          })
          .catch(({ message }) => {
            console.error(`An error has occurred: ${message}`);
            notificationService.error(`An error has occurred during classifiers version update.`);
          });
      })
      .catch(({ message }) => {
        console.error(`An error has occurred: ${message}`);
        notificationService.error(`An error has occurred during classifiers version update.`);
      });
  }, [selectionState, classifiers, onUpdate, onClose]);

  const isUpdateButtonDisabled = getIsUpdateButtonDisabled(selectionState);
  const updateButtonLabel = getUpdateButtonLabel(selectionState, isUpdateButtonDisabled);

  return (
    <div className={classes.root}>
      <div className={classes.body}>
        <BigidLayout config={layoutConfig} />
      </div>
      <div className={classes.footer}>
        <PrimaryButton
          size="medium"
          onClick={handleUpdateClick}
          disabled={isUpdateButtonDisabled}
          dataAid={`${dataAid}-update`}
          text={updateButtonLabel}
        />
      </div>
    </div>
  );
};
