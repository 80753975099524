import React, { FC, useRef, useState, useMemo, MouseEvent } from 'react';
import { noop } from 'lodash';
import { BigidDialog, BigidFormStateAndHandlers, PrimaryButton, SecondaryButton } from '@bigid-ui/components';
import { OpenServiceTicketForm } from './OpenServiceTicketForm';
import { Action, OpenServiceTicketActionConfiguration } from '../../../../actionWorkflowTypes';
import makeStyles from '@mui/styles/makeStyles';

interface OpenServiceTicketDialogProps {
  isOpen: boolean;
  onClose: () => void;
  autocompleteListItems: string[];
  saveActionConfiguration: (actionToSave: Action) => void;
  initialValue: OpenServiceTicketActionConfiguration;
}

const useStyles = makeStyles({
  dialogPaper: {
    '& .MuiDialogContent-root': {
      maxHeight: '80vh',
    },
  },
});

export const OpenServiceTicketDialog: FC<OpenServiceTicketDialogProps> = ({
  isOpen,
  onClose,
  autocompleteListItems,
  saveActionConfiguration,
  initialValue,
}) => {
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(false);
  const formControls = useRef<BigidFormStateAndHandlers>();

  const buttons = useMemo(
    () => [
      {
        text: 'Cancel',
        component: SecondaryButton,
        onClick: noop,
        isClose: true,
      },
      {
        text: 'Save',
        component: PrimaryButton,
        onClick: (event: MouseEvent) => {
          formControls?.current?.validateAndSubmit(() => {
            formControls?.current?.submit(event);
            onClose();
          });
        },
        disabled: isLoading,
      },
    ],
    [isLoading, onClose],
  );

  return (
    <BigidDialog
      muiOverrides={{
        classes: { paper: classes.dialogPaper },
      }}
      title="Create Ticket"
      isOpen={isOpen}
      onClose={onClose}
      isLoading={isLoading}
      buttons={buttons}
      isContentScrollable
    >
      <OpenServiceTicketForm
        formControls={formControls}
        setDialogLoading={setIsLoading}
        autocompleteListItems={autocompleteListItems}
        saveActionConfiguration={saveActionConfiguration}
        initialValue={initialValue}
        isDialogOpen={isOpen}
      />
    </BigidDialog>
  );
};
