import React, { FC } from 'react';
import { BigidDisconnectedIllustration } from '@bigid-ui/icons';
import makeStyles from '@mui/styles/makeStyles';
import { BigidBody1, BigidColorsV2, BigidHeading5 } from '@bigid-ui/components';

export interface WidgetNotAvailablePlaceHolderProps {
  appName: string;
}

const useStyles = makeStyles({
  root: {
    padding: '16px',
    width: '100%',
    height: '100%',
  },
  header: {
    color: BigidColorsV2.purple[400],
  },
  contentRoot: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
  },
  contentWrapper: {
    height: '200px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    rowGap: '30px',
    textAlign: 'center',
  },
});

export const WidgetNotAvailablePlaceHolder: FC<WidgetNotAvailablePlaceHolderProps> = ({ appName }) => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <BigidHeading5 className={classes.header}>{appName}</BigidHeading5>
      <div className={classes.contentRoot}>
        <div className={classes.contentWrapper}>
          <BigidDisconnectedIllustration />
          <BigidBody1>
            Something went wrong. Check that the <br />
            app is connected and online.
          </BigidBody1>
        </div>
      </div>
    </div>
  );
};
