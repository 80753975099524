import { CONFIG } from '../../../config/common';

export interface VendorProject {
  id: string;
  name: string;
  vendorId: string;
  vendorName?: string;
  vendorType?: string;
  status?: VendorsStatus;
  assets?: MultiValueEntity[];
  locations?: string[];
  businessOwner?: string;
  createdAt?: string;
  contactPerson?: VendorProjectContactPerson;
  purposeOfDataCollecting?: MultiValueEntity[];
  dataActivities?: MultiValueEntity[];
  dataExchangeTypes?: DataExchangeType[];
  assetIds?: string[];
  description?: string;
}

export interface MultiValueEntity {
  id: string;
  name: string;
}

export interface VendorProjectContactPerson {
  name?: string;
  role?: string;
  phone?: string;
  email?: string;
  groupEmail?: string;
  companyAddress?: string;
  companyWebsite?: string;
}

export interface Vendor {
  id: string;
  isBuiltIn: boolean;
  name: string;
  type: string;
  contactDetails?: {
    companyAddress?: string;
    companyWebsite?: string;
  };
}

export enum VendorsStatus {
  CANDIDATE = 'Candidate',
  ACTIVE = 'Active',
  INACTIVE = 'Inactive',
}

export interface DataActivity {
  id: string;
  name: string;
}

export enum VendorSummaryTabNames {
  SUMMARY = 'Vendor Summary',
  FILES = 'Attached Files',
  LOGS = 'Activity Log',
}

export enum VendorSummaryTabPaths {
  SUMMARY = 'summary',
  FILES = 'files',
  LOGS = 'logs',
}

export enum VendorAction {
  CREATE = 'Create',
  UPDATE = 'Update',
  IMPORT = 'Import',
  DELETE = 'Delete',
}

export interface VendorProjectAuditLog {
  vendorProjectId: string;
  vendorProjectStatus: VendorsStatus;
  action: VendorAction;
  description: string;
  user: string;
  timestamp: Date;
}

export interface VendorAttachedFile {
  fileName: string;
  type: string;
  description: string;
  user: string;
  createdAt: Date;
  updatedAt: Date;
}

export enum DataExchangeType {
  DATA_PROCESSOR = 'Data Processor',
  DATA_CONTROLLER = 'Data Controller',
}

type VendorStateNamesType = {
  VENDORS_SUMMARY_LOGS: string;
  VENDORS_LISTING: string;
};

export const VendorStateNames: VendorStateNamesType = {
  VENDORS_SUMMARY_LOGS: 'vendors.summary.activity_logs',
  VENDORS_LISTING: CONFIG.states.VENDORS,
};

export interface SystemUser {
  _id: string;
  id: string;
  firstName: string;
  name: string;
  admin?: boolean;
  origin: string;
  roleIds: string[];
  lastName?: string;
  email?: string;
}

export interface UsersData {
  users: SystemUser[];
  totalCount: number;
}
export interface SystemUserResponseData {
  status: string;
  statusCode: number;
  data: UsersData;
  message: null | string;
}

/**
 * Assessments
 */

export enum VendorAssessmentAppName {
  PIA = 'PIA',
  ROPA = 'RoPA',
}

export enum VendorAssessmentStatus {
  DRAFT = 'Draft',
  IN_PROGRESS = 'InProgress',
  COMPLETED = 'Completed',
  ARCHIVED = 'Archived',
}

export enum AssessmentApplicationStatus {
  ONLINE = 'online',
  OFFLINE = 'offline',
  OUTDATED = 'outdated',
}

export interface VendorAssessment {
  tpaId: string;
  tpaName: string;
  appName: VendorAssessmentAppName;
  addNewAssessmentPath: string;
  redirectToAssessmentGridPath: string;
  totalAssessmentCount: number;
  errorMessage?: string;
  appStatus?: AssessmentApplicationStatus;
  assessmentList: AssessmentListItem[];
}

export interface AssessmentListItem {
  assessmentId: string;
  name: string;
  redirectToAssessmentPath: string;
  createdAt: Date;
  status: string;
  statusType: string;
}
