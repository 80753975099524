import { BigidDropdownOption, BigidFormField, BigidFormValues } from '@bigid-ui/components';
import {
  Policy,
  PolicyType,
  TestPolicyResponse,
  ApplicationAction,
  TestPolicyPayload,
  CreatePolicyPayload,
} from './definePolicyService';
import { getFixedT } from './translations';
import { isEqual } from 'lodash';
import { notificationService } from '../../../../../services/notificationService';

export function getIsValueChanged(prevValue: any, currValue: any): boolean {
  return !isEqual(prevValue, currValue);
}

export function getIsPolicyInventoryLinkable(policyType: PolicyType): boolean {
  return policyType === PolicyType.PRIVACY;
}

export function getIsPolicyDataCatalogLinkable(policyType: PolicyType): boolean {
  return [PolicyType.CATALOG, PolicyType.ACCESS_GOVERNANCE].includes(policyType);
}

export function getIsAppFieldAvailable(policyType: PolicyType): boolean {
  return policyType !== PolicyType.PRIVACY;
}

export function getCreatePolicyPayload(policyFormState: BigidFormValues): CreatePolicyPayload {
  const {
    includeLinkToCatalog = false,
    includeLinkToInventory = false,
    includeObjectsReport = false,
    bigidQuery,
    maxFindings,
    owner = [],
    name,
    type = [],
    description,
  } = policyFormState;

  return {
    name,
    type: type[0]?.value,
    description,

    owner: owner[0]?.id,
    complianceRuleCalc: {
      bigidQuery,
      maxFindings,
    },
    taskSettings: {
      includeLinkToCatalog,
      includeLinkToInventory,
      includeObjectsReport,
    },
    severity: 'medium',
  } as Policy;
}

export function getTestPolicyPayload(policyFormState: BigidFormValues): TestPolicyPayload {
  const { bigidQuery, maxFindings, type = [] } = policyFormState;

  return {
    type: type[0]?.value,
    bigidQuery,
    maxFindings,
  };
}

export function showTestPolicyResults(result: TestPolicyResponse): void {
  const t = getFixedT('dialog.test');
  const { violated, findingsAmt } = result;

  if (violated) {
    notificationService.warning(t('failed.body'), {
      title: t('failed.title', { findings: findingsAmt }),
      showCloseIcon: true,
    });
  } else {
    notificationService.success(t('succeeded.body'), {
      title: t('succeeded.title', { findings: findingsAmt }),
      showCloseIcon: true,
    });
  }
}

export function getPolicyTypeFieldOptions(): BigidDropdownOption[] {
  const t = getFixedT('dialog.form.fields.policyType');

  return [
    {
      id: PolicyType.PRIVACY,
      value: PolicyType.PRIVACY,
      displayValue: t('options.privacy'),
    },
    {
      id: PolicyType.ACCESS_GOVERNANCE,
      value: PolicyType.ACCESS_GOVERNANCE,
      displayValue: t('options.accessGovernance'),
    },
    {
      id: PolicyType.CATALOG,
      value: PolicyType.CATALOG,
      displayValue: t('options.catalog'),
    },
  ];
}

export function getIsFormValid(values: BigidFormValues, fields: BigidFormField[]): boolean {
  let isFormValid = true;

  fields.forEach(({ name, validate, isRequired, disabled }) => {
    if (validate && isRequired && !disabled) {
      isFormValid = isFormValid && Boolean(validate(values[name]));
    }
  });

  return isFormValid;
}

export function getDefaultFormState(query: string): BigidFormValues {
  return {
    name: '',
    type: [],
    description: '',
    owner: [],
    bigidQuery: query,
    maxFindings: '',
    includeLinkToInventory: false,
    includeObjectsReport: false,
    includeLinkToCatalog: false,
  };
}
