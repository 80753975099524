import { useCallback, useRef, useEffect, RefObject } from 'react';
import { dateUtils } from '../../../../services/angularServices';
import { UpdateDataSourceConfigState } from './useDataSourceConfigState';
import { httpService } from '../../../../services/httpService';

export interface UseScanDataSourceProps {
  getValuesContainer: { current: () => Record<string, any> };
  updateState: UpdateDataSourceConfigState;
}

export enum ScanResultStatusEnum {
  COMPLETED = 'Completed',
  STOPPED = 'Stopped',
  PAUSED = 'Paused',
  FAILED = 'Failed',
  QUEUED = 'Queued',
  NOT_STARTED = 'Not Started',
  STARTED = 'Started',
}

export const SCAN_RESULT_FETCH_INTERVAL = 5000;

export interface ScanApiResult {
  data: {
    state: ScanResultStatusEnum;
    results?: Record<string, any>;
    Started?: string;
    Completed?: string;
  };
}

const useCreateScanStepWithErrorHandling = (
  stepHandler: () => Promise<any>,
  updateStateRef: RefObject<UpdateDataSourceConfigState>,
) =>
  useCallback(async () => {
    try {
      const results = await stepHandler();
      updateStateRef.current(results);
      return results;
    } catch (error) {
      updateStateRef.current({
        scanInProgress: false,
        isScanSuccess: false,
        scanResults: null,
      });
      console.error(error);
    } finally {
      updateStateRef.current({
        scanResultsIsLoading: false,
      });
    }
  }, [stepHandler, updateStateRef]);

export const useScanDataSource = ({ getValuesContainer, updateState }: UseScanDataSourceProps) => {
  const scanResultUpdateIntervalContainer = useRef<NodeJS.Timeout>();
  const updateStateRef = useRef(updateState);

  const getScanDataFromApi = useCallback(async () => {
    const { name } = getValuesContainer.current();
    const { data: scan } = await httpService.fetch<ScanApiResult>(`ds-connections/${name}/scan`);
    const scanInProgress = scan?.data?.state === ScanResultStatusEnum.STARTED;
    const scanFailed = scan?.data?.state === ScanResultStatusEnum.FAILED;
    if (!scanInProgress || scan?.data.state === ScanResultStatusEnum.NOT_STARTED) {
      scanResultUpdateIntervalContainer.current && clearInterval(scanResultUpdateIntervalContainer.current);
    }

    return {
      scanResults: scan.data.results,
      scanInProgress,
      isScanSuccess: scanFailed ? false : scan?.data?.state === ScanResultStatusEnum.COMPLETED ? true : undefined,
      scanCompleteDate: scan?.data?.Completed && dateUtils.formatDate(scan?.data?.Completed),
      scanStartDate: scan?.data?.Started && dateUtils.formatDate(scan?.data?.Started),
    };
  }, [getValuesContainer]);

  const getScanResult = useCreateScanStepWithErrorHandling(getScanDataFromApi, updateStateRef);

  const updateScanResult = useCallback(async () => {
    getScanResult();
    scanResultUpdateIntervalContainer.current = setInterval(getScanResult, SCAN_RESULT_FETCH_INTERVAL);
  }, [getScanResult]);

  const runScan = useCreateScanStepWithErrorHandling(async () => {
    const { name } = getValuesContainer.current();
    const { data: scanResults } = await httpService.post(`ds-connections/${name}/scan`);
    updateScanResult();

    return {
      scanInProgress: true,
      isScanSuccess: undefined,
      scanResults,
    };
  }, updateStateRef);

  const stopScan = useCreateScanStepWithErrorHandling(async () => {
    const { name } = getValuesContainer.current();
    clearInterval(scanResultUpdateIntervalContainer.current);
    await httpService.post<ScanApiResult>(`ds-connections/${name}/scan-stop`);
    return {
      scanInProgress: false,
      isScanSuccess: undefined,
      scanResults: undefined,
    };
  }, updateStateRef);

  useEffect(() => {
    if (getValuesContainer.current()?.name) {
      updateStateRef.current({ scanResultsIsLoading: true });
      scanResultUpdateIntervalContainer.current && clearInterval(scanResultUpdateIntervalContainer.current);
      updateScanResult();
    }
    return () => {
      scanResultUpdateIntervalContainer.current && clearInterval(scanResultUpdateIntervalContainer.current);
    };
  }, [getValuesContainer, updateScanResult]);

  return {
    runScan,
    stopScan,
  };
};
