import React, { FC, useContext } from 'react';
import { BigidProgressBar, BigidProgressBarStatus } from '@bigid-ui/components';
import { GettingStartedContext } from '../hooks/GettingStartedContext';
import makeStyles from '@mui/styles/makeStyles';
import { generateDataAid } from '@bigid-ui/utils';

const useStyles = makeStyles({
  progressBarWrapper: {
    width: '100%',
    paddingBottom: 36,
  },
});

export const GettingStartedProgressBar: FC = () => {
  const classes = useStyles({});
  const { items } = useContext(GettingStartedContext);

  const completedItemsCount = Object.values(items).filter(item => item.isCompleted).length;
  const progress = (completedItemsCount / Object.values(items).length) * 100;

  return (
    <div>
      <div className={classes.progressBarWrapper} data-aid={generateDataAid('GettingStartedProgressBar', [])}>
        <BigidProgressBar percentage={progress} status={BigidProgressBarStatus.COMPLETED} />
      </div>
    </div>
  );
};
