import { useCallback, useMemo, useRef } from 'react';
import { UpdateDataSourceConfigState } from './useDataSourceConfigState';
import { DataSourceConnectionFormField } from '../types';

export interface UseUpdateDetailsFilledProps {
  updateState: UpdateDataSourceConfigState;
  fields: DataSourceConnectionFormField[];
  isScopeSelected?: boolean;
}

const checkFilledForArray = (value: any) => !Array.isArray(value) || !!(value?.length && value[0]);
const checkIfArrayField = (value: any) => checkFilledForArray(value) && checkFilledForArray(value[0]?.value);

export const useFieldsDetailsFilled = (fields: DataSourceConnectionFormField[]) =>
  useMemo(() => fields.filter(({ misc: additionalProps }) => additionalProps?.callToFill), [fields]);

export const useUpdateDetailsFilled = ({ updateState, fields, isScopeSelected }: UseUpdateDetailsFilledProps) => {
  const updateStateRef = useRef(updateState);
  const fieldsDetailsFilled = useFieldsDetailsFilled(fields);

  const updateDetailsFilled = useCallback(
    (values: Record<string, any>) => {
      const callToFillState = fieldsDetailsFilled.reduce<Record<string, any>>(
        (newCallToFillState, { name }) => ({
          ...newCallToFillState,
          [name]: values[name] !== undefined && values[name] !== null && checkIfArrayField(values[name]),
        }),
        {},
      );

      updateStateRef.current({
        callToFillState: { ...callToFillState, scope: isScopeSelected },
      });
    },
    [fieldsDetailsFilled, isScopeSelected],
  );

  return updateDetailsFilled;
};
