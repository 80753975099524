import React, { FC } from 'react';
import { SecondaryButton, BigidColors, BigidBody2 } from '@bigid-ui/components';
import makeStyles from '@mui/styles/makeStyles';
import { AutoDiscoveryWizardProfile } from '../AutoDiscoveryWizard';

const useStyles = makeStyles({
  mainContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    boxSizing: 'content-box',
    justifyContent: 'space-between',
    height: 33,
    '&:not(:first-child)': {
      borderTop: '1px solid #CBCBCB',
      paddingTop: 10,
    },
    '&:not(:last-child)': {
      paddingBottom: 10,
    },
  },
  profileDescription: {
    width: '80%',
    fontSize: 14,
    fontWeight: 500,
    color: '#2CB6FF',
    ' & span': {
      paddingLeft: 25,
      color: 'black',
    },
  },
  scanButtonContainer: {
    marginLeft: 'auto',
    '& button': {
      width: 37,
      height: 33,
      borderRadius: 5,
      border: '1px solid black',
      boxShadow: '0px 3px 12px 0px rgba(0,0,0,0.05) !important',
    },
  },
  inProgressElement: {
    marginLeft: 'auto',
    display: 'flex',
    alignItems: 'center',
  },
  inProgressText: {
    paddingLeft: 5,
    color: BigidColors.purple[300],
  },
  spinner: {
    position: 'inherit',
    width: '16px',
    height: '16px',
    '&::after': {
      content: '""',
      width: '16px',
      height: '16px',
      fontSize: '10px',
      position: 'absolute',
      textIndent: '-9999em',
      borderTop: `1px solid ${BigidColors.purple[300]}`,
      borderRight: `1px solid ${BigidColors.purple[300]}`,
      borderBottom: `1px solid ${BigidColors.purple[300]}`,
      borderLeft: `1px solid ${BigidColors.gray[100]}`,
      backgroundColor: 'transparent',
      transform: 'translateZ(0)',
      borderRadius: '50%',
      animation: '$load8 1.1s infinite linear',
    },
  },
  '@keyframes load8': {
    '0%': {
      '-webkit-transform': 'rotate(0deg)',
      transform: 'rotate(0deg)',
    },
    '100%': {
      '-webkit-transform': 'rotate(360deg)',
      transform: 'rotate(360deg)',
    },
  },
});

export interface ScanProfileRowProps {
  profile: AutoDiscoveryWizardProfile;
  triggerScan: (key: string) => void;
}

export const ScanProfileRow: FC<ScanProfileRowProps> = ({ profile, triggerScan }) => {
  const { mainContainer, profileDescription, scanButtonContainer, inProgressElement, inProgressText, spinner } =
    useStyles({});
  const { scanProfileName, selectedDsNames, dsType, isScanRunning } = profile;

  return (
    <div className={mainContainer}>
      <div className={profileDescription}>
        {scanProfileName}{' '}
        <span>{`${selectedDsNames.length} Data Source${selectedDsNames.length > 1 ? 's' : ''} Selected`}</span>
      </div>
      <div>
        {isScanRunning ? (
          <div className={inProgressElement}>
            <div className={spinner}></div>
            <div>
              <BigidBody2 className={inProgressText}>In Progress...</BigidBody2>
            </div>
          </div>
        ) : (
          <div className={scanButtonContainer} role="button">
            <SecondaryButton
              dataAid={`AutoDiscoveryWizardScanButton-${dsType}`}
              onClick={() => triggerScan(dsType)}
              size="medium"
              text="Scan"
            />
          </div>
        )}
      </div>
    </div>
  );
};
