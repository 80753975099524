import { SecondaryButton, PrimaryButton } from '@bigid-ui/components';
import { betaToolsService, downloadFileService } from '../../../../services/angularServices';
import { openSystemDialog } from '../../../../services/systemDialogService';
import { TestConnectionLogsModalContent } from '../DataSourceTestConnection/TestConnectionLogsModalContent/TestConnectionLogsModalContent';

export const LOG_IN_DIALOG_MAX_LENGTH = 131072;

export type GetHighlightedWordsForLogType = () => string[];

export interface ShowFailedTestDialogOptions {
  logText: string;
  date: string;
  getHighlightedWordsForLog: GetHighlightedWordsForLogType;
}

export const showFailedTestDialog = (dialogOptions: ShowFailedTestDialogOptions) => {
  openSystemDialog<any>({
    title: 'Failed Connection Log',
    onClose: () => {
      return;
    },
    content: TestConnectionLogsModalContent,
    contentProps: dialogOptions,
    borderTop: true,
    maxWidth: 'lg',
    buttons: [
      {
        text: 'Download Log',
        component: SecondaryButton,
        onClick: () => {
          betaToolsService.getServicesLogs(['scanner']).then((data: any) => {
            downloadFileService.download(data, 'attachment/octet-stream', 'bigid-scanner-logs.zip');
          });
        },
        size: 'large',
      },
      {
        text: 'Close',
        component: PrimaryButton,
        onClick: () => {
          return;
        },
        isClose: true,
        size: 'large',
      },
    ],
  });
};

export const getScannerLogs = (dateText: string, getHighlightedWordsForLog: GetHighlightedWordsForLogType) => {
  betaToolsService.getScanLogByName('scanner').then((result: string) => {
    showFailedTestDialog({
      logText: result.slice(-LOG_IN_DIALOG_MAX_LENGTH),
      date: dateText,
      getHighlightedWordsForLog,
    });
  });
};
