import { DataCatalogObjectDetails } from '../../../../DataCatalog/DataCatalogDetails/DataCatalogDetailsService';

export enum ReducerActions {
  'UPDATE_OBJECT_DETAILS',
  'INIT',
}

export interface InitialEmptyObject {
  id: string;
  fullyQualifiedName: string;
}

export interface ObjectPreviewReducerAction {
  type: ReducerActions;
  payload: Partial<ObjectPreviewReducerState>;
}

export interface ObjectPreviewReducerState {
  objectPreviewData: DataCatalogObjectDetails | InitialEmptyObject;
}

export const objectPreviewReducer: React.Reducer<ObjectPreviewReducerState, ObjectPreviewReducerAction> = (
  state,
  { type, payload },
) => {
  switch (type) {
    case ReducerActions.UPDATE_OBJECT_DETAILS: {
      return { ...state, ...payload };
    }

    case ReducerActions.INIT: {
      return getInitialReducerState(payload);
    }

    default:
      return state;
  }
};

export const getInitialReducerState = ({
  objectPreviewData = { id: '', fullyQualifiedName: '' },
}: Partial<ObjectPreviewReducerState>): ObjectPreviewReducerState => {
  return {
    objectPreviewData,
  };
};
