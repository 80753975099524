import { licenseService } from '../services/licenseService';
import { getApplicationPreference } from '../services/appPreferencesService';
import { sessionStorageService } from '../../common/services/sessionStorageService';

export const SHOW_AUTH_SECRET_KEY_BANNER = 'SHOW_AUTH_SECRET_KEY_BANNER';
export const SHOW_SECRET_KEY_BANNER = 'SHOW_SECRET_KEY_BANNER';
export const SHOULD_CHANGE_SECRET_KEY = 'SHOULD_CHANGE_SECRET_KEY';

export function shouldShowBanner(): boolean {
  return (
    licenseService.shouldNotifyLicenseViolation() ||
    shouldShowLicenseViolationBanner() ||
    shouldShowSecretKeyAuthBanner() ||
    shouldShowSecretKeyChangeBanner()
  );
}

export function shouldShowSecretKeyAuthBanner(): boolean {
  const changeReason = getApplicationPreference('SHOULD_CHANGE_AUTH_SECRET_KEY');
  return sessionStorageService.get(SHOW_AUTH_SECRET_KEY_BANNER) && changeReason !== '';
}

export function shouldShowSecretKeyChangeBanner(): boolean {
  return getApplicationPreference(SHOULD_CHANGE_SECRET_KEY) && sessionStorageService.get(SHOW_SECRET_KEY_BANNER);
}

export function shouldShowLicenseViolationBanner(): boolean {
  return licenseService.shouldShowLicenseViolationBanner();
}
