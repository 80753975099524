import { analyticsService, BiEvents } from '../../../../../services/analyticsService';

export enum ObjectPreviewDSPMEvents {
  DSPM_OBJECT_TABLE_PREVIEW = 'DSPM_object_table_preview',
  DSPM_ATTRIBUTE_SAMPLE_VIEWED = 'DSPM_attribute_sample_viewed',
  DSPM_OBJECT_LOAD_MORE = 'DSPM_object_preview_load_more_click',
  DSPM_PREVIEW_FILE_EVENT_DISPLAYONLY = 'DSPM_object_preview_file_event_displayonly',
  DSPM_PREVIEW_FILE_EVENT = 'DSPM_preview_file_event',
  DSPM_UNSTRUCTURED_PREVIEW_ATTRIBUTE_FILTER_TYPE = 'DSPM_unstructured_preview_attribute_filter_type',
}

interface DSPMActions {
  appInfo: string;
  appData: any;
}

export type DSPMActionsFunction = (props: DSPMActions) => any | Promise<any>;

export const trackManualEvent: DSPMActionsFunction = async ({ appInfo, appData }) => {
  analyticsService.trackManualEvent(`DSPM_${appInfo}` as BiEvents, {
    ...appData,
    customAppEvent: true,
  });
};
