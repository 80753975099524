import React, { useState, useEffect, useRef, useMemo } from 'react';
import {
  BigidBody1,
  BigidFormField,
  BigidFormFieldTypes,
  BigidFormProps,
  BigidFormRenderProps,
  BigidFormStateAndHandlers,
  BigidFormValidateOnTypes,
  BigidFormValues,
  TertiaryButton,
} from '@bigid-ui/components';
import styled from '@emotion/styled';
import { getFixedT } from './translations';
import { EmailContentSectionWrapper } from './EmailEditorForm/FormItems/EmailContentSectionWrapper';
import { cloneDeep } from 'lodash';
import { BigidAddIcon } from '@bigid-ui/icons';
import {
  EmailTemplateData,
  EmailTemplateFieldType,
  getFieldByName,
  removeKeysByNumberFromObject,
} from './emailEditorService';
import { FormRichTextEditor } from '../CustomFormFields/FormRichTextEditor';

const ContentContainer = styled('div')({
  display: 'flex',
  gap: '24px 16px',
  flexDirection: 'column',
});

const AddSectionButtonContainer = styled('div')({
  width: '126px',
  marginTop: '16px',
});

export interface DynamicContentField {
  fieldName: string;
  fieldType: string;
  isSystem?: boolean;
}

const tHeaders = getFixedT('formHeaders');
const tLables = getFixedT('formLables');
const tButtons = getFixedT('buttonLables');
const tFormPlaceholders = getFixedT('formPlaceholders');
const t = getFixedT('');

const RICH_TEXT_FIELD_NAME_PREFIX = 'message-';
export const SUBJECT_FIELD_NAME = 'subject';
export const HEADER_TEXT_FIELD_NAME = 'headerText';
export const HEADER_IMAGE_LINK_FIELD_NAME = 'headerImageLink';
const EMPTY_FIELD = '';

export const useEmailContentFields = (templateData: EmailTemplateData, viewOnlyMode: boolean) => {
  const [fields, setFields] = useState<BigidFormField[]>([]);
  const [sectionFields, setSectionFields] = useState<string[][]>([]);
  const [initialValues, setInitialValues] = useState<BigidFormValues>({});
  const emailContentFormControls = useRef<BigidFormStateAndHandlers>();
  const allowedVariables = templateData?.allowedVariables || [];
  const emailSubject = templateData?.content?.subject || '';
  const allowedVariablesTooltipText = t('allowedVars', { variablesList: allowedVariables.join(', ') });
  const [isFormLoading, setFormLoading] = useState(false);

  const checkIsSectionDeletionEnabled = (sectionIndex: number) => {
    if (viewOnlyMode) {
      return false;
    }

    const section = sectionFields[sectionIndex];
    for (const fieldName of section) {
      const field = getFieldByName(fields, fieldName);
      if (field?.disabled === true) {
        return false;
      }
    }

    return true;
  };

  const verifyFieldTypeByFieldName = (fieldName: string, fieldType: EmailTemplateFieldType) => {
    const field = getFieldByName(fields, fieldName);
    return field?.misc?.fieldType === fieldType;
  };

  const isRichTextEditorField = (fieldName: string) => fieldName.includes(RICH_TEXT_FIELD_NAME_PREFIX);

  const generateNewEmptySectionFields = () => {
    const newIndex = sectionFields.length;
    const newSectionFields: BigidFormField[] = [
      {
        name: `title-${newIndex}`,
        label: tLables(EmailTemplateFieldType.TITLE) as string,
        type: BigidFormFieldTypes.TEXT,
        misc: { section: `section${newIndex}`, fieldType: EmailTemplateFieldType.TITLE },
      },
      {
        name: `${RICH_TEXT_FIELD_NAME_PREFIX}${newIndex}`,
        label: tLables(EmailTemplateFieldType.HTML) as string,
        misc: { section: `section${newIndex}`, fieldType: EmailTemplateFieldType.HTML },
        render: FormRichTextEditor,
      },
      {
        name: `button-${newIndex}-text`,
        label: tLables(EmailTemplateFieldType.BUTTON_TEXT) as string,
        type: BigidFormFieldTypes.TEXT,
        misc: { section: `section${newIndex}`, fieldType: EmailTemplateFieldType.BUTTON_TEXT },
      },
      {
        name: `button-${newIndex}-url`,
        label: tLables(EmailTemplateFieldType.BUTTON_URL) as string,
        type: BigidFormFieldTypes.TEXT,
        misc: { section: `section${newIndex}`, fieldType: EmailTemplateFieldType.BUTTON_URL },
      },
      {
        name: `link-${newIndex}-text`,
        label: tLables(EmailTemplateFieldType.LINK_TEXT) as string,
        type: BigidFormFieldTypes.TEXT,
        misc: { section: `section${newIndex}`, fieldType: EmailTemplateFieldType.LINK_TEXT },
      },
      {
        name: `link-${newIndex}-url`,
        label: tLables(EmailTemplateFieldType.LINK_URL) as string,
        type: BigidFormFieldTypes.TEXT,
        misc: { section: `section${newIndex}`, fieldType: EmailTemplateFieldType.LINK_URL },
      },
    ];
    const newSectionFieldNames = newSectionFields.map(field => field.name);
    setSectionFields(prevSectionFields => [...prevSectionFields, newSectionFieldNames]);
    setFields(prevFields => [...prevFields, ...newSectionFields]);
    setInitialValues(prevValues => ({
      ...prevValues,
      ...newSectionFields.reduce((acc, { name }) => ({ ...acc, [name]: EMPTY_FIELD }), {}),
    }));
  };

  const generateInitialValues = (templateData: EmailTemplateData) => {
    const {
      content: {
        subject,
        header: { text: headerText, image: headerImageLink },
        body,
      },
    } = templateData;

    const dynamicFieldsIntialValues: BigidFormValues = {};

    body?.forEach(section =>
      section.fields.forEach(field => {
        dynamicFieldsIntialValues[field.fieldName] = field.value;
      }),
    );
    return {
      subject,
      headerText,
      headerImageLink,
      ...dynamicFieldsIntialValues,
    };
  };

  const generateDynamicContentFields = () => {
    const {
      content: { body },
    } = templateData;
    const dynamicFields: BigidFormField[] = [];
    const sectionFieldNames: string[][] = [];
    if (body?.length) {
      body.forEach((section, index) => {
        const fieldNames: string[] = [];
        section.fields.forEach((field: DynamicContentField) => {
          dynamicFields.push({
            label: tLables(field.fieldType) as string,
            name: field.fieldName,
            ...(!isRichTextEditorField(field.fieldName) && { type: BigidFormFieldTypes.TEXT }),
            disabled: field.isSystem,
            misc: { section: `section${index}`, fieldType: field.fieldType },
            ...(viewOnlyMode && { fieldProps: { readOnly: true } }),
            ...(isRichTextEditorField(field.fieldName) && { render: FormRichTextEditor }),
          });
          fieldNames.push(field.fieldName);
        });
        sectionFieldNames.push(fieldNames);
      });
    }
    setFields([
      {
        name: SUBJECT_FIELD_NAME,
        type: BigidFormFieldTypes.TEXT,
        fieldProps: {
          readOnly: viewOnlyMode,
          placeholder: emailSubject,
        },
      },
      {
        name: HEADER_TEXT_FIELD_NAME,
        label: tLables(HEADER_TEXT_FIELD_NAME) as string,
        type: BigidFormFieldTypes.TEXT,
        fieldProps: {
          readOnly: viewOnlyMode,
        },
      },
      {
        name: HEADER_IMAGE_LINK_FIELD_NAME,
        label: tLables(HEADER_IMAGE_LINK_FIELD_NAME) as string,
        type: BigidFormFieldTypes.TEXT,
        fieldProps: {
          placeholder: tFormPlaceholders('headerImagePlaceholder'),
          readOnly: viewOnlyMode,
        },
      },
      ...dynamicFields,
    ]);
    setSectionFields(sectionFieldNames);
  };

  const cleanSectionFieldsByIndex = (sectionIndex: number) => {
    sectionFields[sectionIndex].forEach((fieldName: string) => {
      emailContentFormControls.current.setValue(fieldName)('');
    });
  };

  const onDeleteSection = (sectionIndex: number) => {
    const updatedSectionFields = cloneDeep(sectionFields.filter((_, index) => index !== sectionIndex));
    const updatedInitialValues = cloneDeep(initialValues);

    const sectionName = `section${sectionIndex}`;
    const filteredFields = cloneDeep(
      fields.filter(field => {
        return field?.misc?.section !== sectionName;
      }),
    );
    cleanSectionFieldsByIndex(sectionIndex);
    setInitialValues(removeKeysByNumberFromObject(updatedInitialValues, sectionIndex));
    setSectionFields(updatedSectionFields);
    setFields(filteredFields);
  };

  const resetContentFields = () => {
    if (sectionFields.length) {
      for (const index of sectionFields.keys()) {
        cleanSectionFieldsByIndex(index);
      }
    }
    if (templateData) {
      generateDynamicContentFields();
    }
  };

  const emailContentFormProps: BigidFormProps = useMemo(
    () => ({
      controlButtons: false,
      validateOn: BigidFormValidateOnTypes.SUBMIT,
      stateAndHandlersRef: emailContentFormControls,
      initialValues,
      displayFormLevelError: true,
      fields,
      renderForm: ({ renderField, errors }: BigidFormRenderProps) => {
        return (
          <>
            {!!fields.length && (
              <ContentContainer>
                <EmailContentSectionWrapper isDeletionEnabled={false} titleText={tHeaders('subject') as string}>
                  {renderField(SUBJECT_FIELD_NAME, {
                    error: errors?.[SUBJECT_FIELD_NAME],
                    errorIsShown: !!errors?.[SUBJECT_FIELD_NAME],
                  })}
                </EmailContentSectionWrapper>
                <EmailContentSectionWrapper isDeletionEnabled={false} titleText={tHeaders('header') as string}>
                  <BigidBody1>{`${tLables('headerFieldsExplanations')}`}</BigidBody1>
                  {renderField(HEADER_TEXT_FIELD_NAME)}
                  {renderField(HEADER_IMAGE_LINK_FIELD_NAME, {
                    error: errors?.[HEADER_IMAGE_LINK_FIELD_NAME],
                    errorIsShown: !!errors?.[HEADER_IMAGE_LINK_FIELD_NAME],
                  })}
                </EmailContentSectionWrapper>
                {!!sectionFields?.length && (
                  <>
                    {sectionFields.map((section, index) => {
                      const sectionTitle = `${tHeaders('section')} ${index + 1}`;
                      return (
                        <EmailContentSectionWrapper
                          key={index}
                          titleText={sectionTitle}
                          sectionIndex={index}
                          onDeleteSection={onDeleteSection}
                          isDeletionEnabled={checkIsSectionDeletionEnabled(index)}
                        >
                          {section.map((field: string) => {
                            if (verifyFieldTypeByFieldName(field, EmailTemplateFieldType.HTML)) {
                              return renderField(field, {
                                error: errors?.[field],
                                errorIsShown: !!errors?.[field],
                                readOnly: viewOnlyMode,
                                allowedVariables,
                                allowedVariablesTooltipText,
                              });
                            } else {
                              return renderField(field, { error: errors?.[field], errorIsShown: !!errors?.[field] });
                            }
                          })}
                        </EmailContentSectionWrapper>
                      );
                    })}
                  </>
                )}
              </ContentContainer>
            )}
            {!viewOnlyMode && (
              <AddSectionButtonContainer>
                <TertiaryButton
                  dataAid="EmailEditor-add-section"
                  text={tButtons('addSection') as string}
                  startIcon={<BigidAddIcon />}
                  size="large"
                  onClick={generateNewEmptySectionFields}
                />
              </AddSectionButtonContainer>
            )}
          </>
        );
      },
    }),
    [initialValues, fields, sectionFields, viewOnlyMode, templateData],
  );

  const clearErrors = () => {
    fields.forEach(({ name }) => emailContentFormControls.current.setFieldError(name, null));
  };

  useEffect(() => {
    if (templateData) {
      generateDynamicContentFields();
      const newInitialValues = generateInitialValues(templateData);
      setInitialValues(newInitialValues);
      setFormLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [templateData]);

  useEffect(() => {
    setFormLoading(true);
  }, []);

  return {
    emailContentFormProps,
    emailContentFormControls,
    resetContentFields,
    sectionFields,
    clearErrors,
    isFormLoading,
  };
};
