import { module } from 'angular';
const app = module('app');

app.factory('activitiesService', [
  '$http',
  'appSettings',
  function ($http, appSettings) {
    return {
      getActivitiesData: function getActivitiesData(queryString) {
        queryString = queryString || '';
        return $http
          .get(appSettings.serverPath + '/' + appSettings.version + '/activityViolations' + queryString)
          .then(function (response) {
            return response.data;
          });
      },
      getActivitiesByAppName: function getActivitiesByAppName(queryString) {
        queryString = queryString || '';
        return $http
          .get(appSettings.serverPath + '/' + appSettings.version + '/activities' + queryString)
          .then(function (response) {
            return response.data;
          });
      },
    };
  },
]);
