import { commonTranslate } from '../translations';
import { getApplicationPreference } from './appPreferencesService';

export enum NotificationPermission {
  DEFAULT = 'default',
  DENIED = 'denied',
  GRANTED = 'granted',
}

type NotificationDirection = 'auto' | 'ltr' | 'rtl';

interface NotificationOptions {
  dir?: NotificationDirection;
  lang?: string;
  body?: string;
  tag?: string;
  image?: string;
  icon?: string;
  badge?: string;
  sound?: string;
  vibrate?: number | number[];
  timestamp?: number;
  renotify?: boolean;
  silent?: boolean;
  requireInteraction?: boolean;
  data?: any;
  actions?: NotificationAction[];
}

interface NotificationAction {
  action: string;
  title: string;
  icon?: string;
}

export interface NativeNotification extends NotificationOptions {
  title?: string;
  text?: string;
  hideIfTabInFocus?: boolean;
  focusOnClick?: boolean;
  onClose?: (e: Event) => void;
  onClick?: (e: Event) => void;
}

export const createNativeNotification = async ({
  title = `BigID`,
  text,
  onClose,
  onClick,
  hideIfTabInFocus,
  focusOnClick,
  ...restOptions
}: NativeNotification) => {
  try {
    const isPermittedToShow = await checkNotificationPermission();
    const notShowIfInFocus = hideIfTabInFocus && document.visibilityState === 'visible';
    if (!isPermittedToShow || notShowIfInFocus) {
      return false;
    }

    const optionsWithTranslate = { ...(text ? { body: commonTranslate(text) } : {}), ...restOptions };
    const notification = new Notification(title, optionsWithTranslate);
    addListenersToNotification(notification, { onClose, onClick, hideIfTabInFocus, focusOnClick });
    return notification;
  } catch (e) {
    console.error(e);
  }
};

function addListenersToNotification(
  notification: Notification,
  {
    onClose,
    onClick,
    hideIfTabInFocus,
    focusOnClick = true,
  }: Pick<NativeNotification, 'onClose' | 'onClick' | 'hideIfTabInFocus' | 'focusOnClick'>,
) {
  if (hideIfTabInFocus) {
    closeNotificationWhenTabFocused(notification);
  }
  if (onClick || focusOnClick) {
    notification.addEventListener('click', event => {
      onClick?.(event);
      if (focusOnClick) {
        window.focus();
      }
    });
  }
  if (onClose) {
    notification.addEventListener('close', onClose);
  }
}

function closeNotificationWhenTabFocused(notification: Notification) {
  const eventName = 'visibilitychange';
  const handler = function () {
    if (document?.visibilityState === 'visible') {
      notification.close();
      document.removeEventListener(eventName, handler, false);
    }
  };
  document.addEventListener(eventName, handler, false);
}

export async function checkNotificationPermission() {
  if (!getApplicationPreference('USE_NATIVE_NOTIFICATION') || !window?.Notification) {
    return false;
  }

  if (Notification.permission === NotificationPermission.GRANTED) {
    return true;
  }

  if (Notification.permission !== NotificationPermission.DENIED) {
    return (await Notification.requestPermission()) === NotificationPermission.GRANTED;
  }

  return false;
}
