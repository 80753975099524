import { BigidFormField, BigidFormFieldTypes } from '@bigid-ui/components';
import React, { FC, useMemo } from 'react';
import { FormSection, FormSectionInitialStateProps } from '../FormSection/FormSection';
import { useReportTemplateContext } from '../hooks/ReportTemplateContext';

export const ContactInformationSection: FC = () => {
  const sectionName = 'contactInfo';
  const description = 'Display contact person details.';

  const { initialTemplateData } = useReportTemplateContext();

  const formSectionInitProps: FormSectionInitialStateProps = useMemo(() => {
    const {
      contactInfo: { ...initialValues },
    } = initialTemplateData;

    const fields: BigidFormField[] = [
      {
        type: BigidFormFieldTypes.TEXT,
        name: 'name',
        label: 'Contact name',
        misc: {
          placeholder: 'Thomas Paine',
          fullWidth: true,
        },
      },
      {
        type: BigidFormFieldTypes.TEXT,
        name: 'position',
        label: 'Position',
        misc: {
          placeholder: 'Data Privacy Officer',
          fullWidth: true,
        },
      },
      {
        type: BigidFormFieldTypes.TEXT,
        name: 'phoneNumber',
        label: 'Phone Number',
        misc: {
          placeholder: '(704) 555-9676',
          fullWidth: true,
        },
      },
      {
        type: BigidFormFieldTypes.TEXT,
        name: 'email',
        label: 'Email',
        misc: {
          placeholder: 'thomas.paine@company.com',
          fullWidth: true,
        },
      },
    ];

    return {
      fields,
      initialValues,
    };
  }, [initialTemplateData]);

  return <FormSection sectionName={sectionName} description={description} {...formSectionInitProps} />;
};
