import { module } from 'angular';
const app = module('app');

app.factory('newDLPConnectionService', [
  '$http',
  'appSettings',
  function ($http, appSettings) {
    return {
      getDLPConnectionsData: function getDLPConnectionsData() {
        return $http
          .get(appSettings.serverPath + '/' + appSettings.version + '/dlp_connections')
          .then(function (response) {
            return response.data;
          });
      },
    };
  },
]);
