import React, { FC, useEffect, useMemo, useState } from 'react';
import {
  BigidCarousel,
  BigidDialog,
  BigidCarouselProps,
  BigidCarouselMedia,
  BigidDialogProps,
  BigidDialogButtonType,
  PrimaryButton,
  TertiaryButton,
} from '@bigid-ui/components';
import { getApplicationPreference } from '../../../../react/services/appPreferencesService';
import { userPreferencesService } from '../../../../react/services/userPreferencesService';
import { $state } from '../../../../react/services/angularServices';
import { BigidHelpIcon } from '@bigid-ui/icons';
import styled from '@emotion/styled';
import classifierStep1 from './ClassifiersStep1.json';
import ClassifiersStep2 from './ClassifiersStep2.json';
import { generateDataAid } from '@bigid-ui/utils';
import { DialogGuideGeneralStep } from './DialogGuideGeneralStep';
import { useLocalTranslation } from '../translations';
import { getIsShowGuideClassifierManagement } from '../utils/utils';

const ContainerCarousel = styled('div')`
  [data-aid^='BigidCarousel-media-item'] img {
    width: 100%;
  }
`;

const WrapperDescriptionStep = styled('div')`
  margin: 18px 0;
`;

interface ConvertScanProfileGuidedTourProps {
  manualStart: boolean;
  setIsShowGuidedTour: (value: boolean) => void;
  dataAid?: string;
}

const carouselItems: BigidCarouselMedia[] = [
  {
    type: 'lottie',
    json: classifierStep1,
  },
  {
    type: 'lottie',
    json: ClassifiersStep2,
  },
];

export const ClassifiersManagementDialogGuide: FC<ConvertScanProfileGuidedTourProps> = ({
  manualStart,
  setIsShowGuidedTour,
  dataAid = 'ClassifiersManagementDialogGuide',
}) => {
  const [imageActiveIndex, setImageActiveIndex] = useState<number>(0);
  const { t } = useLocalTranslation('guideTour');

  useEffect(() => {
    handleUserPreferenceManualStart();
  }, []);

  const handleUserPreferenceManualStart = async () => {
    const classifiersManagementUserPreference = await userPreferencesService.get($state.$current.name);
    const isShowDialogGuide = getIsShowGuideClassifierManagement();
    if (!classifiersManagementUserPreference?.data?.isClassifiersManagementDialogGuide && isShowDialogGuide) {
      setIsShowGuidedTour(true);
    }
  };

  const handleClose = async () => {
    setIsShowGuidedTour(false);
    setImageActiveIndex(0);

    const classifiersManagementUserPreference = await userPreferencesService.get($state.$current.name);
    if (!classifiersManagementUserPreference?.data?.isClassifiersManagementDialogGuide) {
      await userPreferencesService.update({
        preference: $state.$current.name,
        data: { ...(classifiersManagementUserPreference?.data || {}), isClassifiersManagementDialogGuide: true },
      });
    }
  };

  const buttons: BigidDialogButtonType[] = useMemo(() => {
    const basicButtons: BigidDialogButtonType[] = [
      { component: TertiaryButton, onClick: handleClose, text: 'Close', alignment: 'right' },
    ];

    if (imageActiveIndex > 0) {
      basicButtons.push({
        component: TertiaryButton,
        onClick: () => setImageActiveIndex(currStepIndex => currStepIndex - 1),
        text: 'Previous',
        alignment: 'left',
        dataAid: 'Previous-button',
      });
    }

    if (imageActiveIndex < carouselItems.length - 1) {
      basicButtons.push({
        component: PrimaryButton,
        onClick: () => setImageActiveIndex(currStepIndex => currStepIndex + 1),
        text: 'Next',
        alignment: 'right',
        dataAid: 'Next-button',
      });
    }

    if (imageActiveIndex === carouselItems.length - 1) {
      basicButtons.push({
        component: PrimaryButton,
        onClick: handleClose,
        text: 'Done',
        alignment: 'right',
      });
    }

    return basicButtons;
  }, [handleClose, imageActiveIndex]);

  const indicatorHandler = (currentImageIndex: number) => {
    setImageActiveIndex(currentImageIndex);
  };

  const bigidCarouselProps: BigidCarouselProps = {
    items: carouselItems,
    size: 'fullWidth',
    hideArrows: true,
    mediaHeight: null,
    dataAid: 'ClassifiersManagementDialogGuideCarousel',
    index: 200,
  };

  const bigidDialogProps: BigidDialogProps = {
    title: t('title'),
    isOpen: manualStart,
    onClose: handleClose,
    fixedHeight: 670,
    buttons,
    borderBottom: true,
  };

  return (
    <BigidDialog {...bigidDialogProps}>
      <ContainerCarousel>
        <BigidCarousel
          dataAid={generateDataAid(dataAid, [])}
          {...bigidCarouselProps}
          onChange={indicatorHandler}
          index={imageActiveIndex}
        />
        {imageActiveIndex === 0 && (
          <WrapperDescriptionStep>
            <DialogGuideGeneralStep title={t('step1Title')} description={t('step1Description')} />
          </WrapperDescriptionStep>
        )}
        {imageActiveIndex === 1 && (
          <WrapperDescriptionStep>
            <DialogGuideGeneralStep title={t('step2Title')} description={t('step2Description')} />
          </WrapperDescriptionStep>
        )}
      </ContainerCarousel>
    </BigidDialog>
  );
};

export const getBigIdHelpIcon: FC<{ onClick: () => void }> = ({ onClick }) => {
  return <BigidHelpIcon cursor="pointer" onClick={onClick} />;
};
