import React, { FC, useContext } from 'react';
import { CorrelationSetSelectionGrid } from './CorrelationSetSelectionGrid';
import { ScanTemplateWizardContext } from '../../../scanTemplateContext';

export const Correlation: FC = () => {
  const { scanTemplateFormData, setScanTemplateFormData, isEditMode } = useContext(ScanTemplateWizardContext);
  return (
    <CorrelationSetSelectionGrid
      scanTemplateFormData={scanTemplateFormData}
      setScanTemplateFormData={setScanTemplateFormData}
      isEditMode={isEditMode}
    />
  );
};
