import React, { MouseEventHandler, MutableRefObject, useEffect, useState } from 'react';
import { delay } from 'lodash';
import { BigidBody1, BigidButtonIcon, BigidHeading6, BigidPopper } from '@bigid-ui/components';
import { BigidXIcon } from '@bigid-ui/icons';
import { useLocalTranslation } from '../translations';
import image from './animation/onboarding-assistant-tour.gif';
import styled from '@emotion/styled';
import { Backdrop } from '@mui/material';

type SuggestedActionsPreviewPopperProps = {
  isOpen: boolean;
  onClose: () => void;
  anchor: MutableRefObject<HTMLElement>;
};

const TRANSLATION_KEY = 'OnboardingAssistant.labels';
const BACKDROP_Z_INDEX = 1050;

const Root = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding: 16px;
`;

const Image = styled.div`
  max-height: 300px;

  img {
    width: 100%;
  }
`;

const Header = styled.div`
  display: flex;
  margin-left: auto;
`;

export const SuggestedActionsPreviewPopper = ({
  isOpen: isPopperOpen,
  onClose,
  anchor,
}: SuggestedActionsPreviewPopperProps): JSX.Element => {
  const [url, setUrl] = useState();
  const [isOpen, setOpen] = useState(false);
  const { t } = useLocalTranslation(TRANSLATION_KEY);

  const handleStopClickEvent: MouseEventHandler<HTMLDivElement> = event => {
    event.stopPropagation();
    event.preventDefault();
  };

  const handleFetchImage = async () => {
    try {
      const res = await fetch(image);
      const text = await res.blob();
      const reader = new FileReader();
      reader.onload = event => {
        setUrl((event.currentTarget as unknown as any).result);
      };
      reader.readAsDataURL(text);
    } catch {
      setUrl(null);
    }
  };

  useEffect(() => {
    handleFetchImage();
  }, []);

  useEffect(() => {
    delay(() => setOpen(isPopperOpen), 500);
  }, [isPopperOpen]);

  return (
    <Backdrop
      onClick={handleStopClickEvent}
      className="MuiDialog-root MuiModal-root"
      sx={{ zIndex: BACKDROP_Z_INDEX }}
      open={isOpen}
    >
      <BigidPopper
        width={350}
        placement="left-start"
        popperOffset={[0, 16]}
        anchorEl={anchor.current}
        isLoading={false}
        isEmpty={false}
        keepMounted
        onClose={onClose}
        open={isOpen}
      >
        <Root onClick={handleStopClickEvent}>
          <Header>
            <BigidButtonIcon onClick={onClose} icon={BigidXIcon} />
          </Header>
          {url && (
            <Image>
              <img src={url} />
            </Image>
          )}
          <BigidHeading6>{t('guidedTour.title')}</BigidHeading6>
          <BigidBody1>{t('guidedTour.body')}</BigidBody1>
        </Root>
      </BigidPopper>
    </Backdrop>
  );
};
