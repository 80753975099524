import { generateDataAid } from '@bigid-ui/utils';
import React, { FC, ReactElement, useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { BigidBody1, BigidColorsV2 } from '@bigid-ui/components';

const StyledMark = styled('mark')({
  backgroundColor: `${BigidColorsV2.blue[200]}`,
  cursor: 'pointer',
  padding: 0,
});

const HighlightContainer = styled('div')({
  width: '100%',
  display: 'inline-flex',
  border: '1px solid rgb(199, 199, 199)',
  borderRadius: '4px',
  alignItems: 'center',
  fontSize: '0.875rem',
});

const HighlightContent = styled('div')({
  padding: '8px 10px 8px 10px',
  flex: '1',
  whiteSpace: 'pre-wrap',
  wordBreak: 'break-all',
  overflow: 'scroll',
  height: '102px',
  color: '#3D3D3D',
});

type ContentPreprocessed = (string | ReactElement)[];

export interface ClassifierTesterHighlightProps {
  dataAid: string;
  content: string;
  regexMatchesDetails: IterableIterator<RegExpMatchArray>;
}

export const ClassifierTesterHighlight: FC<ClassifierTesterHighlightProps> = ({
  dataAid = 'ClassifierTester',
  content,
  regexMatchesDetails,
}) => {
  const [highlightContentResult, setHighlightContentResult] = useState<ContentPreprocessed>([]);

  const getHighlightContentPreprocessed = (
    content: string,
    regexMatchesDetails: IterableIterator<RegExpMatchArray>,
  ): ContentPreprocessed => {
    let currentIndex = 0;
    let keyIndex = 0;
    const results = [];
    for (const match of regexMatchesDetails) {
      const textBefore = content.slice(currentIndex, match.index);

      results.push(textBefore);
      results.push(<StyledMark key={keyIndex}>{match[0]}</StyledMark>);

      currentIndex = match.index + match[0].length;
      keyIndex++;
    }

    const textAfter = content.slice(currentIndex);
    results.push(textAfter);
    return results;
  };

  useEffect(() => {
    if (regexMatchesDetails) {
      const result = getHighlightContentPreprocessed(content, regexMatchesDetails);
      if (result?.length > 0) {
        setHighlightContentResult(result);
      }
    }
  }, [content, regexMatchesDetails]);

  return (
    <>
      <BigidBody1> BigID highlight</BigidBody1>
      <HighlightContainer data-aid={`${generateDataAid(dataAid, ['highlight', 'container'])}`}>
        <HighlightContent data-aid={`${generateDataAid(dataAid, ['highlight', 'content'])}`}>
          {<>{highlightContentResult}</>}
        </HighlightContent>
      </HighlightContainer>
    </>
  );
};
