import React, { ChangeEvent, FC, useEffect, useMemo, useRef, useState } from 'react';
import styled from '@emotion/styled';
import {
  BigidGridColumn,
  BigidGridColumnTypes,
  BigidGridQueryComponents,
  BigidGridWithToolbar,
  BigidGridWithToolbarProps,
} from '@bigid-ui/grid';
import { BigidAdministrationIcon, BigidInfoIcon } from '@bigid-ui/icons';
import { CorrelationSetSelectionGridRow, CorrelationSetSelectionProps } from './correlationStepTypes';
import { CorrelationSetModel } from '../../../../../CorrelationSets/CorrelationSetConnections/CorrelationSetConnectionTypes';
import { AdvancedSettingsDialog } from './AdvancedSettingsDialog';
import {
  BigidRadioGroup,
  BigidRadioProps,
  BigidTooltip,
  BigidIconType,
  TertiaryButton,
  BigidLoader,
  BigidFieldFilterOperator,
  entityEventsEmitter,
  EntityEvents,
  ToolbarActionType,
} from '@bigid-ui/components';
import {
  fetchCorrelationSets,
  getPartialCorrelationsIds,
} from '../../../../../CorrelationSets/CorrelationSetConnections/correlationSetUtils';
import { queryService } from '../../../../../../services/queryService';
import { notificationService } from '../../../../../../services/notificationService';
import { getCorrelationSetConnectionFilterConfig } from '../../../../../CorrelationSets/CorrelationSetConnections/CorrelationSetConnectionGridConfiguration';
import { generateDataAid } from '@bigid-ui/utils';
import { getApplicationPreference } from '../../../../../../services/appPreferencesService';
import { uniq } from 'lodash';

const Wrapper = styled('div')<{ allEnabledIdSor: boolean }>(
  { overflowX: 'hidden', flexGrow: 1 },
  ({ allEnabledIdSor }) => ({
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    minHeight: 0,
    gap: 8,
    width: '100%',
    position: 'relative',
    height: '100%',
    maxHeight: '100%',
    overflow: 'hidden',
    ...(allEnabledIdSor
      ? {
          overflowY: 'hidden',
          pointerEvents: 'none',
          opacity: 0.3,
          '.TableContainer-root': {
            overflow: 'hidden',
          },
        }
      : { overflowY: 'auto' }),
  }),
);
const SelectionWrapper = styled('div')({
  marginBottom: 16,
  display: 'flex',
  alignItems: 'flex-end',
  justifyContent: 'space-between',
});
const RadioLabelStyled = styled('div')<{ dataAid?: string }>({ display: 'flex', alignItems: 'center' });
const IconWrapper = styled('div')<{ dataAid?: string }>({ display: 'flex', marginLeft: 4 });

const CorrelationSetSelectionGridColumns: BigidGridColumn<CorrelationSetSelectionGridRow>[] = [
  {
    title: 'Name',
    name: 'name',
    width: 450,
    type: BigidGridColumnTypes.TEXT,
    sortingEnabled: true,
    getCellValue: ({ name }) => name,
  },
  {
    title: 'Data Source Name',
    name: 'dsConnection',
    sortingEnabled: true,
    width: 450,
    type: BigidGridColumnTypes.TEXT,
    getCellValue: ({ dsConnection }) => dsConnection,
  },
  {
    title: 'Total Entities',
    name: 'total_identities',
    width: 300,
    type: BigidGridColumnTypes.NUMBER,
    getCellValue: ({ total_identities }) => total_identities,
  },
];

enum CORRELATION_SELECTION_VALUES {
  SELECT = 'select',
  ALL_ENABLED = 'allEnabled',
}

interface RadioLabelProps {
  label: string;
  tooltip?: string;
  icon?: BigidIconType;
  dataAid?: string;
}

const RadioLabel: FC<RadioLabelProps> = ({ label, tooltip, icon: Icon, dataAid }) => {
  return (
    <RadioLabelStyled dataAid={generateDataAid(dataAid, ['Wrapper'])}>
      {label}
      {Icon && (
        <BigidTooltip isDisabled={!tooltip} title={tooltip}>
          <IconWrapper dataAid={generateDataAid(dataAid, ['Icon'])}>
            <Icon />
          </IconWrapper>
        </BigidTooltip>
      )}
    </RadioLabelStyled>
  );
};

const correlationSelectionOptions: BigidRadioProps[] = [
  {
    label: <RadioLabel label="Select correlation sets" />,
    value: CORRELATION_SELECTION_VALUES.SELECT,
    dataAid: generateDataAid('BigidRadioButton', [CORRELATION_SELECTION_VALUES.SELECT]),
  },
  {
    label: (
      <RadioLabel
        label="All enabled correlation sets"
        icon={BigidInfoIcon}
        tooltip="Enabled correlation sets, defined in the Correlation Sets page, will be taken at run time"
      />
    ),
    value: CORRELATION_SELECTION_VALUES.ALL_ENABLED,
    dataAid: generateDataAid('BigidRadioButton', [CORRELATION_SELECTION_VALUES.ALL_ENABLED]),
  },
];

export const CorrelationSetSelectionGrid: FC<CorrelationSetSelectionProps> = ({
  scanTemplateFormData,
  setScanTemplateFormData,
  isEditMode,
}) => {
  const [isShowSelectedToggleActive, setIsShowSelectedToggleActive] = useState(false);
  const { idConnectionList, allEnabledIdSor } = scanTemplateFormData || {};
  const allEnabledIdSorRef = useRef(allEnabledIdSor);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isAllSelectedLoading, setIsAllSelectedLoading] = useState<boolean>(false);
  const [toolbarConfig, setToolbarConfig] =
    useState<BigidGridWithToolbarProps<CorrelationSetModel>['filterToolbarConfig']>();
  const selectedRowIdsRef = useRef([]);
  const idsWithPartialCorrelationRef = useRef([]);
  const [csTotalCount, setCsTotalCount] = useState(0);

  useEffect(() => {
    const loadSelectCorrelationSetsFilters = async () => {
      const filtersAndSearchSettings = await getCorrelationSetConnectionFilterConfig(true);
      setToolbarConfig(filtersAndSearchSettings);
      setIsLoading(false);
    };
    loadSelectCorrelationSetsFilters();
  }, []);

  useEffect(() => {
    setScanTemplateFormData({ ...scanTemplateFormData, skipIdScan: scanTemplateFormData.skipIdScan ?? true });
  }, []);

  const checkIsClassificationsAsPiiFindingsDisabled = () => {
    if (allEnabledIdSorRef.current) {
      return !idsWithPartialCorrelationRef.current.length;
    }
    return !selectedRowIdsRef.current.some(id => idsWithPartialCorrelationRef.current.includes(id));
  };

  const onClickAdvancedSettings = async () => {
    await AdvancedSettingsDialog({
      scanTemplateFormData,
      setScanTemplateFormData,
      isClassificationsAsPiiFindingsDisabled: checkIsClassificationsAsPiiFindingsDisabled(),
      isEditMode,
    });

    return {
      shouldGridReload: false,
    };
  };
  const updateIdsWithPartialCorrelation = (correlation: CorrelationSetModel[]) => {
    if (getApplicationPreference('PARTIAL_CORRELATION_SET')) {
      idsWithPartialCorrelationRef.current = uniq([
        ...idsWithPartialCorrelationRef.current,
        ...getPartialCorrelationsIds(correlation),
      ]);
    }
  };

  const correlationSetSelectionGridConfig: BigidGridWithToolbarProps<CorrelationSetSelectionGridRow> = useMemo(
    () => ({
      columns: CorrelationSetSelectionGridColumns,
      pageSize: 20,
      customRowIdName: 'name',
      entityName: 'Correlation Sets',
      defaultSorting: [{ field: 'name', order: 'asc' }],
      filterToolbarConfig: toolbarConfig,
      showSortingControls: true,
      selectedRowIds: idConnectionList,
      preSelectedIds: idConnectionList,
      hideColumnChooser: true,
      showSelectAll: true,
      shouldKeepSelectionAfterFilter: true,
      toolbarActions: [
        {
          label: '',
          value: '',
          show: () => false,
          execute: async () => ({ shouldGridReload: false }),
        },
        {
          label: 'show selected',
          execute: async _params => {
            setIsShowSelectedToggleActive(isActive => !isActive);
            entityEventsEmitter.emit(EntityEvents.RELOAD);
            return {};
          },
          disable: () => false,
          show: () => true,
          placement: 'end',
          type: ToolbarActionType.SWITCH,
          switchProps: {
            isChecked: _actionData => {
              return isShowSelectedToggleActive;
            },
          },
        },
      ],
      onGridStateChange: async nextGridState => {
        let selectedRows = nextGridState.selectedRowIds as string[];
        if (nextGridState.allSelected && !isShowSelectedToggleActive) {
          setIsAllSelectedLoading(true);
          const csFilterQuery = queryService.getGridConfigQuery({
            filter: nextGridState.filter,
            limit: csTotalCount,
            skip: 0,
          });
          const { correlation_sets } = await fetchCorrelationSets(csFilterQuery);

          selectedRows = correlation_sets.map(({ name }) => name);
          setIsAllSelectedLoading(false);
        }

        if (selectedRowIdsRef.current?.length !== selectedRows.length) {
          selectedRowIdsRef.current = selectedRows;
          setScanTemplateFormData(prevState => ({ ...prevState, idConnectionList: selectedRows }));
        }
      },
      fetchData: async (queryComponents: BigidGridQueryComponents) => {
        try {
          const selectedFilter = isShowSelectedToggleActive
            ? [{ field: 'name', operator: 'in' as BigidFieldFilterOperator, value: selectedRowIdsRef.current }]
            : [];
          const query = {
            ...queryComponents,
            filter: [...queryComponents.filter, ...selectedFilter],
          };
          const gridConfigQuery = queryService.getGridConfigQuery(query);
          const { correlation_sets, count } = await fetchCorrelationSets(gridConfigQuery);
          updateIdsWithPartialCorrelation(correlation_sets);
          setCsTotalCount(count);
          return {
            data: correlation_sets,
            totalCount: count,
          };
        } catch (e) {
          console.error(e);
          notificationService.error('Could not fetch correlation set data. See logs for more information');
          return {
            data: [],
            totalCount: 0,
          };
        }
      },
    }),
    [csTotalCount, idConnectionList, setScanTemplateFormData, toolbarConfig, isShowSelectedToggleActive],
  );

  const handleRadioButtonChange = ({ target }: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    const allEnabledIdSor = target.value === CORRELATION_SELECTION_VALUES.ALL_ENABLED;
    allEnabledIdSorRef.current = allEnabledIdSor;
    setScanTemplateFormData({ ...scanTemplateFormData, allEnabledIdSor });
  };

  return (
    <>
      <SelectionWrapper>
        <BigidRadioGroup
          defaultValue={
            allEnabledIdSor ? CORRELATION_SELECTION_VALUES.ALL_ENABLED : CORRELATION_SELECTION_VALUES.SELECT
          }
          options={correlationSelectionOptions}
          onChange={handleRadioButtonChange}
          horizontal
        />
        <TertiaryButton
          onClick={onClickAdvancedSettings}
          size="medium"
          startIcon={<BigidAdministrationIcon />}
          dataAid="advancedSettingsCorrelationStepButton"
          text="Advanced Settings"
        />
      </SelectionWrapper>
      <Wrapper allEnabledIdSor={allEnabledIdSor} data-aid="CorrelationSetSelectionGridWrapper">
        {isAllSelectedLoading && <BigidLoader />}
        {!isLoading && <BigidGridWithToolbar {...correlationSetSelectionGridConfig} />}
      </Wrapper>
    </>
  );
};
