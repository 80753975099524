import { notificationService } from '../../../services/notificationService';
import { GettingStartedStep } from '../types';

export interface GettingStartedInfo {
  numStepsCompleted?: number;
  steps?: GettingStartedStep[];
}

export const initGettingStartedItems = async (gettingStartedSteps: GettingStartedStep[]) => {
  const steps = gettingStartedSteps.map(step => ({ ...step })); // Create a deep copy of the steps
  for (const [index, step] of steps.entries()) {
    step.isComplete = await step.checkIsComplete();
    if (!step.isComplete) {
      step.isActive = step.checkIsActive();
      return { steps, numStepsCompleted: index };
    }
  }
  return { steps, numStepsCompleted: steps.length };
};

export const initState = async (gettingStartedSteps: GettingStartedStep[]): Promise<GettingStartedInfo> => {
  try {
    const { steps, numStepsCompleted } = await initGettingStartedItems(gettingStartedSteps);
    return {
      numStepsCompleted,
      steps,
    };
  } catch (err) {
    notificationService.error('Failed to fetch Getting Started data');
    console.error(err);
  }
};

export const areAllStepsCompleted = (stepsFinished: number, totalSteps: number) => {
  return stepsFinished === totalSteps;
};
