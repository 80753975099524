import { module } from 'angular';
import { sendSSERequestAndWait } from '../../react/services/sseService';
const app = module('app');
import { $rootScope } from 'ngimport';

app.factory('identityConnectionsService', [
  '$http',
  'appSettings',
  'notificationService',
  function ($http, appSettings, notificationService) {
    return {
      getIdentityConnectionsData: function getIdentityConnectionsData(filterValue, archiveFilter = false) {
        const params = { archiveFilter };
        return $http({
          url: appSettings.serverPath + '/' + appSettings.version + '/id_connections',
          method: 'GET',
          params,
        }).then(response => response.data);
      },

      getIdentityConnectionsDataByID: function getIdentityConnectionsDataByID(name) {
        return $http.get(appSettings.serverPath + '/' + appSettings.version + '/id_connections/' + name).then(
          function (response) {
            return response.data;
          },
          function (response) {
            // Handle error here
          },
        );
      },
      deleteIdentityConnectionsDataByID: function deleteIdentityConnectionsDataByID(name) {
        return $http
          .delete(appSettings.serverPath + '/' + appSettings.version + '/id_connections/' + name)
          .then(function (response) {
            return response.data;
          });
      },

      updateIdentityConnectionsDataByID: function updateIdentityConnectionsDataByID(identity_connection, id) {
        return $http
          .put(appSettings.serverPath + '/' + appSettings.version + '/id_connections/' + id, identity_connection)
          .then(function (response) {
            notificationService.success('Updated ' + id + ' Successfully! ');
          });
      },
      deleteScannedIdentitiesBySourceName: sourceName => {
        return $http.delete(`${appSettings.serverPath}/${appSettings.version}/scanned-identities/${sourceName}`);
      },
      testEsConnection: async (esConnection, callback) => {
        try {
          const { results, errors, message } = await sendSSERequestAndWait(
            'post',
            'es-connection-test/client',
            esConnection,
            {},
            esConnection.broadcastEvent,
          );

          errors.length > 0
            ? notificationService.error(errors[0]?.code || errors[0])
            : notificationService.success(message);
          $rootScope.$applyAsync();
          callback(results, errors);
        } catch (error) {
          notificationService.error(error);
          callback([], [error]);
        }
      },
    };
  },
]);
