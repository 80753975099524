import React, { useCallback, useEffect, useState } from 'react';
import {
  BigidBody1,
  BigidColorSchemeTokens,
  BigidColorsV2,
  BigidDropdown,
  BigidDropdownOption,
  BigidHeading4,
  BigidInlineNotification,
  BigidSeverityBadge,
  BigidTextField,
  PrimaryButton,
  TertiaryButton,
  toastNotificationService,
} from '@bigid-ui/components';
import { styled } from '@mui/material';
import { cloneDeep, isEqual } from 'lodash';
import { RiskFormField } from './components/RiskFormField';
import { RiskLevelInfo } from './components/RiskLevelInfo';
import { RiskChangeDialog } from './components/RiskChangeDialog';
import { RISK_POLICY_TYPE, RISK_TYPE, RiskInterface, UsersFromServerModel } from './types';
import { ImpactLevelInfo } from './components/ImpactLevelInfo';
import { RiskUnsavedChangesDialog } from './components/RiskUnsavedChangesDialog';
import { ProbabilityLevelInfo } from './components/ProbabilityLevelInfo';
import { httpService } from '../../services/httpService';
import { RiskMatrixMetadata } from './RiskMatrixDefaults';
import { trackManualEvent, RisksAndControlsTrackingEvents } from './risksAnalytics';
import { isPermitted } from '../..//services/userPermissionsService';
import { PRIVACY_RISKS_PERMISSIONS } from '@bigid/permissions';

const { RISK_LIBRARY_ADD_RISK, RISK_LIBRARY_UPDATE_RISK } = RisksAndControlsTrackingEvents;

export const convertUserNameToString = (
  username: string,
  firstName: string | undefined,
  lastName: string | undefined,
  hideUsername?: boolean,
) => {
  return (
    `${firstName ? `${firstName} ` : ''}${lastName ?? ''} ${hideUsername ? '' : `(${username})`}`.trim() || username
  );
};

const RootContainer = styled('div')({
  width: '100%',
  justifyContent: 'space-between',
  alignItems: 'flex-start',
  display: 'flex',
  flexDirection: 'row',
  marginBottom: 20,
  overflow: 'hidden',
  position: 'relative',
});
const FormContainer = styled('div')({
  justifyContent: 'space-between',
  display: 'flex',
  flexDirection: 'column',
  width: 600,
  gap: 16,
  overflowY: 'auto',
  overflowX: 'hidden',
});
const ButtonsContainer = styled('div')({
  position: 'relative',
  top: 10,
  right: 10,
  display: 'flex',
  flexDirection: 'row',
  gap: 8,
});

const SelectContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  gap: 4,
  alignItems: 'flex-start',
});
const HorizontalAlignment = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  gap: 4,
  alignItems: 'flex-start',
});

export interface RiskComponentProps {
  categories: string[];
  id?: string;

  riskMatrix: RiskMatrixMetadata;
  onClose?: (reload?: boolean) => void;
  riskControls: BigidDropdownOption[];
}

export const RiskComponent: React.FC<RiskComponentProps> = ({ id, categories, riskControls, riskMatrix, onClose }) => {
  const [risk, setRisk] = useState<RiskInterface>();
  const [oldRisk, setOldRisk] = useState<RiskInterface>();
  const [shouldKeepOpen, setShouldKeepOpen] = useState(false);
  const [isRiskInfoOpen, setIsRiskInfoOpen] = useState(false);
  const [isProbabilityLevelInfoOpen, setIsProbabilityLevelInfoOpen] = useState(false);
  const [isImpactLevelInfoOpen, setIsImpactLevelInfoOpen] = useState(false);
  const [isValidate, setIsValidate] = useState(false);
  const [isRiskChangedDialogOpen, setIsRiskChangedDialogOpen] = useState(false);
  const [isRiskUnsavedChangesDialogOpen, setIsRiskUnsavedChangesDialogOpen] = useState(false);
  const [riskLevel, setRiskLevel] = useState(0);
  const [riskCategories, setRiskCategories] = useState<BigidDropdownOption[]>([]);
  const [controls, setControls] = useState<BigidDropdownOption[]>([]);
  const [selectedControls, setSelectedControls] = useState<BigidDropdownOption[]>([]);
  const [users, setUsers] = useState<BigidDropdownOption[]>();

  const mapUsersToDropdownOptions = (users: UsersFromServerModel[]): BigidDropdownOption[] => {
    return users?.map(user => ({
      id: user.id,
      value: user.name,
      displayValue: convertUserNameToString(user.name, user.firstName, user.lastName),
    }));
  };

  useEffect(() => {
    const getUsers = async () => {
      const users = (await httpService.fetch('access-management/users'))?.data?.data.users;
      setUsers(mapUsersToDropdownOptions(users));
    };
    getUsers();
    setRiskCategories(categories?.map((cat: string) => ({ displayValue: `${cat}`, value: cat, id: cat })));
  }, [categories]);

  useEffect(() => {
    setControls(riskControls);
    setSelectedControls(controls.filter(control => risk?.controls?.some(ctl => ctl.id === control.value)));
  }, [controls, risk?.controls, riskControls]);

  const getRiskByIdAsync = useCallback(async () => {
    if (id) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const policy: any = (await httpService.fetch('privacy-risks/' + id))?.data;
      if (policy) {
        const policyToRisk = {
          id: policy.id,
          name: policy.displayName || policy.name,
          description: policy.description,
          category: policy.category,
          probability: policy.probability,
          impact: policy.impact,
          riskLevel: policy.riskLevel,
          owner: policy.owner,
          controls: policy.controls,
        } as RiskInterface;
        setRisk(policyToRisk);
        setOldRisk(policyToRisk);
        if (policyToRisk.riskLevel) {
          setRiskLevel(policyToRisk.riskLevel);
        }
      } else {
        toastNotificationService.error('Error getting risk from server', {
          shouldCloseOnTransition: true,
        });
      }
    } else {
      setRisk({} as RiskInterface);
      setOldRisk({} as RiskInterface);
    }
  }, [id]);

  useEffect(() => {
    if (risk && oldRisk && !isEqual(risk, oldRisk)) {
      setShouldKeepOpen(true);
      setIsRiskUnsavedChangesDialogOpen(true);
    } else {
      setShouldKeepOpen(false);
      getRiskByIdAsync();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const validateRisk = useCallback((): boolean => {
    return (
      !risk?.name?.trim().length ||
      !risk?.probability ||
      !risk?.impact ||
      !risk?.owner?.length ||
      !risk?.category?.length
    );
  }, [risk]);

  const onSave = useCallback(async () => {
    try {
      setIsRiskChangedDialogOpen(false);
      setIsRiskInfoOpen(false);
      setIsImpactLevelInfoOpen(false);
      setIsProbabilityLevelInfoOpen(false);
      setIsValidate(true);

      if (!risk || validateRisk()) {
        return;
      }

      const riskToPolicy = {
        ruleType: RISK_POLICY_TYPE,
        name: risk.name, //string
        is_enabled: true,
        description: risk.description, //string
        owner: risk.owner ?? '',
        type: RISK_TYPE,
        probability: risk.probability,
        impact: risk.impact,
        riskLevel: risk.riskLevel,
        controls: risk.controls || [],
        category: risk.category,
        matrixSize: riskMatrix.matrixSize,
      };

      let response;
      if (id) {
        response = await httpService.put('privacy-risks/' + risk.id, riskToPolicy);
        trackManualEvent(RISK_LIBRARY_UPDATE_RISK, {
          ...riskToPolicy,
          riskLevelValue: riskMatrix?.cellData[riskToPolicy.riskLevel]?.riskLevelLabel ?? '',
        });
      } else {
        response = await httpService.post('privacy-risks', riskToPolicy);
        trackManualEvent(RISK_LIBRARY_ADD_RISK, {
          ...riskToPolicy,
          riskLevelValue: riskMatrix?.cellData[riskToPolicy.riskLevel]?.riskLevelLabel ?? '',
        });
      }

      setIsRiskChangedDialogOpen(false);
      setIsRiskUnsavedChangesDialogOpen(false);

      onClose?.();
      if (response) {
        toastNotificationService.success(id ? 'Created successfully' : 'Updated successfully', {
          shouldCloseOnTransition: true,
        });
      }

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (err: any) {
      if (err.message === 'Request failed with status code 409') {
        toastNotificationService.error(err.message, {
          shouldCloseOnTransition: true,
        });
        console.log(err);
      } else {
        console.log(err);
        toastNotificationService.error(err.message, {
          shouldCloseOnTransition: true,
        });
      }
    }
  }, [id, onClose, risk, riskMatrix?.cellData, riskMatrix.matrixSize, validateRisk]);

  useEffect(() => {
    (async () => {
      if (risk?.impact && risk?.probability) {
        let riskLevel = 0;
        riskLevel = risk.probability * risk.impact; //await getRiskLevel(risk.probability, risk.impact);
        if (riskLevel) {
          setRiskLevel(riskLevel);
          risk.riskLevel = riskLevel;
        }
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [risk?.probability, risk?.impact]);

  const getCategories = useCallback(() => {
    return (
      <BigidDropdown
        isCreatable
        isSearchable
        dataAid="risk-library-risk-category"
        isError={isValidate && !risk?.category?.length}
        placeholder="Enter Risk Category"
        errorMessage={isValidate && !risk?.category?.length ? 'Required' : undefined}
        onSelect={options => {
          const riskClone = risk ? cloneDeep(risk) : ({} as RiskInterface);

          if (!riskClone.category) {
            riskClone.category = '';
          }
          riskClone.category = options.map(opt => opt.value)?.[0] ?? '';
          setRisk(riskClone);
        }}
        onCreate={async value => {
          if (risk) {
            const riskClone = cloneDeep(risk);
            if (!riskClone.category) {
              riskClone.category = '';
            }
            riskClone.category = value;
            setRisk(riskClone);
          }

          const category = {
            displayValue: value,
            value,
            id: value,
          };

          setRiskCategories(prev => [...prev, category]);
          return category;
        }}
        value={
          riskCategories && risk?.category ? riskCategories.filter(category => risk?.category === category.id) : []
        }
        options={riskCategories ?? []}
      />
    );
  }, [isValidate, risk, riskCategories]);

  const getOwners = useCallback(() => {
    return (
      <BigidDropdown
        isSearchable
        placeholder="Select Owner"
        dataAid="risk-library-risk-owner"
        errorMessage={isValidate && !risk?.owner ? 'Required' : undefined}
        isError={isValidate && !risk?.owner?.length}
        options={users ?? []}
        onSelect={options => {
          if (risk && options.length) {
            const riskClone = cloneDeep(risk);
            if (!riskClone.category) {
              riskClone.category = '';
            }
            riskClone.owner = options.map(opt => opt.value)[0];
            setRisk(riskClone);
          }
        }}
        value={users && risk?.owner ? users.filter(user => risk?.owner === user.value) : []}
      />
    );
  }, [isValidate, risk, users]);

  return (
    <RootContainer data-aid="risk-component-root">
      <FormContainer>
        <BigidHeading4 marginTop={'10px'}>{risk?.name}</BigidHeading4>
        <BigidInlineNotification
          open
          type={'insight'}
          title={'Risk Configuration'}
          text={[
            {
              subText:
                'A risk level describes how likely it is that personal information will be exposed during a processing activity. Levels of risk are determined by the impact and probability of the risk occurring.',
            },
          ]}
        />

        <RiskFormField tooltip="Provide a name for the risk">
          <BigidTextField
            label="Risk Name"
            dataAid="risk-library-risk-name"
            required
            errorMessage={isValidate && !risk?.name?.length ? 'Required' : undefined}
            isError={isValidate && !risk?.name?.length}
            placeholder="Enter Risk Name"
            onChange={event => {
              if (risk) {
                const riskClone = cloneDeep(risk);
                riskClone.name = event.target.value;
                setRisk(riskClone);
              }
            }}
            value={risk?.name}
          />
        </RiskFormField>

        <RiskFormField tooltip="Provide a description for the risk">
          <BigidTextField
            label="Description"
            placeholder="Enter Description"
            multiline
            dataAid="risk-library-description"
            rows={3}
            onChange={event => {
              if (risk) {
                const riskClone = cloneDeep(risk);
                riskClone.description = event.target.value;
                setRisk(riskClone);
              }
            }}
            value={risk?.description}
          />
        </RiskFormField>

        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'flex-end',
          }}
        >
          <RiskFormField
            tooltip={`Click on this Info Icon to open the ${riskMatrix.probabilityLabel} explanation`}
            onClick={() => {
              setIsProbabilityLevelInfoOpen(true);
            }}
          >
            <SelectContainer sx={{ width: '182px' }}>
              <HorizontalAlignment>
                <BigidBody1>{riskMatrix.probabilityLabel}</BigidBody1>
                <BigidBody1 style={{ color: BigidColorsV2.purple[400] }}>*</BigidBody1>
              </HorizontalAlignment>
              <BigidDropdown
                dataAid="risk-library-probability"
                isError={isValidate && !risk?.probability}
                errorMessage={isValidate && !risk?.probability ? 'Required' : undefined}
                options={Object.keys(riskMatrix.probabilityLabels).map(key => ({
                  displayValue: riskMatrix.probabilityLabels[Number(key)],
                  id: key,
                  value: Number(key),
                }))}
                placeholder={riskMatrix.probabilityLabel}
                onSelect={items => {
                  if (risk) {
                    const riskClone = cloneDeep(risk);
                    riskClone.probability = items[0].value;
                    setRisk(riskClone);
                  }
                }}
                value={
                  risk && risk?.probability
                    ? [
                        {
                          displayValue: risk?.probability
                            ? riskMatrix.probabilityLabels[risk.probability]
                            : riskMatrix.probabilityLabels[1],
                          value: risk?.probability,
                          id: risk?.probability?.toString(),
                        },
                      ]
                    : []
                }
              />
            </SelectContainer>
          </RiskFormField>
          <BigidBody1 sx={{ marginBottom: '8px' }}>{'x'}</BigidBody1>
          <RiskFormField
            tooltip={`Click on this Info Icon to open the ${riskMatrix.impactLabel} explanation`}
            onClick={() => {
              setIsImpactLevelInfoOpen(true);
            }}
          >
            <SelectContainer sx={{ width: '182px' }}>
              <HorizontalAlignment>
                <BigidBody1>{riskMatrix.impactLabel}</BigidBody1>
                <BigidBody1 style={{ color: BigidColorsV2.purple[400] }}>*</BigidBody1>
              </HorizontalAlignment>
              <BigidDropdown
                errorMessage={isValidate && !risk?.impact ? 'Required' : undefined}
                isError={isValidate && !risk?.probability}
                dataAid="risk-library-impact"
                options={Object.keys(riskMatrix.impactLabels).map(key => ({
                  displayValue: riskMatrix.impactLabels[Number(key)],
                  id: key,
                  value: Number(key),
                }))}
                placeholder={riskMatrix.impactLabel}
                onSelect={items => {
                  const riskClone = risk ? cloneDeep(risk) : ({} as RiskInterface);
                  riskClone.impact = items[0].value;
                  setRisk(riskClone);
                }}
                value={
                  risk && risk?.impact
                    ? [
                        {
                          displayValue: risk?.impact
                            ? riskMatrix.impactLabels[risk.impact]
                            : riskMatrix.impactLabels[1],
                          value: risk?.impact,
                          id: risk?.impact?.toString(),
                        },
                      ]
                    : []
                }
              />
            </SelectContainer>
          </RiskFormField>
          <BigidBody1 sx={{ marginBottom: '8px' }}>=</BigidBody1>
          <RiskFormField
            tooltip="Click on this Info Icon to open the Risk Level explanation"
            onClick={() => setIsRiskInfoOpen(true)}
          >
            <SelectContainer sx={{ width: '182px' }}>
              <HorizontalAlignment>
                <BigidBody1>{'Risk Level'}</BigidBody1>
                <BigidBody1 style={{ color: BigidColorsV2.purple[400] }}>*</BigidBody1>
              </HorizontalAlignment>
              <HorizontalAlignment style={{ height: 32, alignItems: 'center' }}>
                {riskLevel > 0 && (
                  <BigidSeverityBadge
                    dataAid={'risk-library-risk-level'}
                    size="medium"
                    level="custom"
                    hasBackground={false}
                    customColor={riskMatrix.cellData[riskLevel]?.color ?? BigidColorSchemeTokens.light.backgroundActive}
                    customLabel={riskMatrix.cellData[riskLevel]?.riskLevelLabel ?? ''}
                  />
                )}
              </HorizontalAlignment>
            </SelectContainer>
          </RiskFormField>
        </div>

        <RiskFormField
          tooltip="The risk should be managed
by a risk owner"
        >
          <SelectContainer>
            <HorizontalAlignment>
              <BigidBody1>{'Risk Owner'}</BigidBody1>
              <BigidBody1 style={{ color: BigidColorsV2.purple[400] }}>*</BigidBody1>
            </HorizontalAlignment>
            {getOwners()}
          </SelectContainer>
        </RiskFormField>

        <RiskFormField
          tooltip="The risk should be classified 
into a category (Group)"
        >
          <HorizontalAlignment>
            <BigidBody1>{'Risk Category'}</BigidBody1>
            <BigidBody1 style={{ color: BigidColorsV2.purple[400] }}>*</BigidBody1>
          </HorizontalAlignment>
          {getCategories()}
        </RiskFormField>

        <BigidInlineNotification
          open
          type={'insight'}
          title={'Risk Controls'}
          text={[
            {
              subText:
                'The risk control process is an essential part of risk management. It involves evaluating potential losses and reducing or eliminating them.',
            },
          ]}
        />

        <RiskFormField
          tooltip="Keep track of the mitigation requirements
with controls added to the risk"
        >
          <SelectContainer>
            <HorizontalAlignment>
              <BigidBody1>{'Control Risk'}</BigidBody1>
              {/* <BigidBody1 style={{ color: BigidColorsV2.purple[400] }}>*</BigidBody1> */}
            </HorizontalAlignment>
            <BigidDropdown
              isExpandable
              isMulti
              applyOnChange
              useClearButton={false}
              isValueDisplayedAsChips={false}
              placeholder="Choose a mitigation control"
              dataAid="risk-library-control-risk"
              // errorMessage={isValidate && !risk?.controls?.length ? 'Required' : undefined}
              // isError={isValidate && !risk?.controls?.length}
              options={controls ?? []}
              onSelect={items => {
                const riskClone = risk ? cloneDeep(risk) : ({} as RiskInterface);
                riskClone.controls = items.map(item => ({ name: item.displayValue, id: item.id }));
                setRisk(riskClone);
                setSelectedControls(items);
              }}
              value={selectedControls}
            />
          </SelectContainer>
        </RiskFormField>
      </FormContainer>
      <ButtonsContainer>
        <TertiaryButton
          dataAid="risk-library-cancel"
          size="medium"
          onClick={() => {
            if (isEqual(oldRisk, risk)) {
              onClose?.();
            } else {
              setIsRiskUnsavedChangesDialogOpen(true);
            }
          }}
        >
          {'Close'}
        </TertiaryButton>
        {isPermitted(PRIVACY_RISKS_PERMISSIONS.EDIT.name) && (
          <PrimaryButton
            size="medium"
            dataAid="risk-library-save"
            disabled={isEqual(oldRisk, risk) || validateRisk()}
            onClick={() => {
              if (id) {
                setIsRiskChangedDialogOpen(true);
              } else {
                onSave();
              }
            }}
          >
            {'save'}
          </PrimaryButton>
        )}
      </ButtonsContainer>
      <RiskLevelInfo isOpen={isRiskInfoOpen} riskMatrix={riskMatrix} onClose={() => setIsRiskInfoOpen(false)} />
      <ProbabilityLevelInfo
        isOpen={isProbabilityLevelInfoOpen}
        riskMatrix={riskMatrix}
        onClose={() => setIsProbabilityLevelInfoOpen(false)}
      />
      <ImpactLevelInfo
        isOpen={isImpactLevelInfoOpen}
        riskMatrix={riskMatrix}
        onClose={() => setIsImpactLevelInfoOpen(false)}
      />
      <RiskChangeDialog
        name={risk?.name ?? ''}
        isOpen={isRiskChangedDialogOpen}
        cancel={() => {
          setIsRiskChangedDialogOpen(false);
          onClose?.();
        }}
        confirm={onSave}
      />
      <RiskUnsavedChangesDialog
        confirm={onSave}
        isOpen={isRiskUnsavedChangesDialogOpen}
        cancel={() => {
          setIsRiskUnsavedChangesDialogOpen(false);

          if (shouldKeepOpen) {
            getRiskByIdAsync();
          } else {
            onClose?.();
          }
        }}
      />
    </RootContainer>
  );
};
