import { module } from 'angular';
import './applicationDetails.component.scss';
import template from './applicationDetails.component.html';
import { isPermitted } from '../../../../react/services/userPermissionsService';
import { BUSINESS_FLOWS_PERMISSIONS } from '@bigid/permissions';

const app = module('app');

app.component('applicationDetails', {
  template,
  controller: [
    '$state',
    'applicationSetupService',
    'explorationBarChartService',
    'dataMappingService',
    'DeleteConfirmation',
    function ($state, applicationSetupService, explorationBarChartService, dataMappingService, DeleteConfirmation) {
      this.tab = 'settings';
      this.appNames = [];
      this.appAccountName = null;
      this.additionalApps = null;

      this.$onInit = () => {
        this.vm.selectedEntity.showGroupPicker = false;
        this.hasManagePermission = isPermitted(BUSINESS_FLOWS_PERMISSIONS.MANAGE.name);
        this.additionalAppsEnabled = this.vm.selectedEntity.appAccountName ? true : false;
        setAppNamesArr(this.vm.selectedEntity.appAccountName, this.vm.selectedEntity.additionalApps);
        if (this.vm.ropaMode) {
          this.vm.selectedEntity.country = this.vm.businessProcess.ropaResource.dataSources.find(
            item => item.name === this.vm.selectedEntity.appAccountName,
          )?.location;
        }
      };

      this.$onChanges = () => {
        saveFieldsValue();
      };

      const saveFieldsValue = () => {
        this.appAccountName = this.vm.selectedEntity.appAccountName;
        this.additionalApps = this.vm.selectedEntity.additionalApps;
        this.attributes = this.vm.selectedEntity.attributes;
        this.attributesFromSystem = this.vm.selectedEntity.attributesFromSystem;
      };

      const getAttributes = (namesArray, type) => {
        dataMappingService.getAttributes(namesArray, type).then(result => {
          this.vm.selectedEntity.attributes = [];
          this.vm.selectedEntity.attributesFromSystem = result ? result : [];
        });
      };

      const setAppNamesArr = (primary, additional) => {
        const _appNames = this.vm.dataLists.appicationNames;

        for (let i = 0; i < _appNames.length; i++) {
          this.appNames[i] = {};

          this.appNames[i]['name'] = _appNames[i];
          if (_appNames[i] == primary) {
            this.appNames[i]['isPrimary'] = true;
            this.appNames[i]['isAdditional'] = false;
          } else {
            this.appNames[i]['isPrimary'] = false;
            this.appNames[i]['isAdditional'] = additional
              ? additional.indexOf(_appNames[i]) > -1
                ? true
                : false
              : false;
          }
        }
      };

      const manageAppNamesArr = (primary, additional) => {
        for (let i = 0; i < this.appNames.length; i++) {
          if (this.appNames[i]['name'] == primary) {
            this.appNames[i]['isPrimary'] = true;
            this.appNames[i]['isAdditional'] = false;
          } else {
            this.appNames[i]['isPrimary'] = false;
            this.appNames[i]['isAdditional'] = additional
              ? additional.indexOf(this.appNames[i]['name']) > -1
                ? true
                : false
              : false;
          }
        }
      };

      this.moveToApplicationEdit = () => {
        $state.go('applicationSetup');
      };

      this.onAdditionalSourceSelected = () => {
        getAttributes(
          this.vm.selectedEntity.additionalApps
            ? this.vm.selectedEntity.additionalApps.concat([this.vm.selectedEntity.appAccountName])
            : [this.vm.selectedEntity.appAccountName],
          'application',
        );
        manageAppNamesArr(this.vm.selectedEntity.appAccountName, this.vm.selectedEntity.additionalApps);
        saveFieldsValue();
      };

      this.onAdditionalSourceRemoved = () => {
        manageAppNamesArr(this.vm.selectedEntity.appAccountName, this.vm.selectedEntity.additionalApps);
        getAttributes(
          this.vm.selectedEntity.additionalApps
            ? this.vm.selectedEntity.additionalApps.concat([this.vm.selectedEntity.appAccountName])
            : [this.vm.selectedEntity.appAccountName],
          'application',
        );
      };

      this.onSelected = (selectedItem, isFromCancel) => {
        this.additionalAppsEnabled = !selectedItem || selectedItem == 'None' ? false : true;

        if (!this.additionalAppsEnabled) {
          this.vm.selectedEntity.additionalApps = [];
        }

        manageAppNamesArr(this.vm.selectedEntity.appAccountName, this.vm.selectedEntity.additionalApps);

        applicationSetupService.getApplicationDetailsByName(selectedItem).then(result => {
          if (result) {
            this.vm.selectedEntity.securityTier = parseInt(result.security_tier);
            this.vm.selectedEntity.country = this.checkIfCountryExist(result.location);
            this.vm.selectedEntity.ownerCell = result.owner_phone;
            this.vm.selectedEntity.ownerEmail = result.owner_email;
          } else {
            this.vm.selectedEntity.securityTier = '';
            this.vm.selectedEntity.country = '';
            this.vm.selectedEntity.ownerCell = '';
            this.vm.selectedEntity.ownerEmail = '';
          }

          if (this.vm.ropaMode) {
            this.vm.selectedEntity.country = this.vm.businessProcess.ropaResource.dataSources.find(
              item => item.name === this.vm.selectedEntity.appAccountName,
            ).location;
          }

          if (typeof isFromCancel === 'undefined') {
            if (selectedItem !== 'None') {
              getAttributes(
                this.vm.selectedEntity.additionalApps
                  ? this.vm.selectedEntity.additionalApps.concat([this.vm.selectedEntity.appAccountName])
                  : [this.vm.selectedEntity.appAccountName],
                'application',
              );
            } else {
              this.vm.selectedEntity.attributesFromSystem = [];
            }
          }
        });
      };

      this.checkIfCountryExist = countryName => {
        const country = this.vm.dataLists.countries.find(country => country.name === countryName);
        return country ? country.displayName : '';
      };

      this.changeApplication = (value, controlName) => {
        //check if there are attributes
        if (
          (this.vm.selectedEntity.attributes && this.vm.selectedEntity.attributes.length > 0) ||
          (this.vm.selectedEntity.attributesFromSystem && this.vm.selectedEntity.attributesFromSystem.length > 0)
        ) {
          const modalOptions = {
            closeButtonText: 'Close',
            actionButtonText: 'Update',
            headerText: `Update ${controlName}`,
            bodyText: `Are you sure you want to change the linked ${controlName}, This will change the attribute list and reset the purposes of processing?`,
          };
          DeleteConfirmation.showModal({}, modalOptions).then(
            () => {
              this.vm.saveEntityChanges(false, `${controlName} field was updated!`);
            },
            () => {
              this.vm.selectedEntity.appAccountName = this.appAccountName;
              this.vm.selectedEntity.additionalApps = this.additionalApps;
              this.vm.selectedEntity.attributes = this.attributes;
              this.vm.selectedEntity.attributesFromSystem = this.attributesFromSystem;
              this.onSelected(this.vm.selectedEntity.appAccountName, true);
            },
          );
        }
        //if there aren't attributes
        else {
          this.vm.saveEntityChanges(false, `${controlName} field was updated!`);
        }
      };
    },
  ],

  bindings: {
    vm: '<',
    entity: '<',
  },
});
