import './sideFilter.component.scss';
import { startCase, mapKeys, pickBy } from 'lodash';
import { module } from 'angular';
import { licenseService } from '../../react/services/licenseService';
import template from './sideFilter.component.html';
import { isPermitted } from '../../react/services/userPermissionsService';
import { TAGS_SAVED_QUERIES_PERMISSIONS } from '@bigid/permissions';
import { getApplicationPreference } from '../../react/services/appPreferencesService';
import { sessionStorageService } from '../../common/services/sessionStorageService';
const app = module('app');

app.component('sideFilter', {
  template,
  controllerAs: 'sideFilterModel',
  controller: function ($rootScope, $scope, $q, $document, $stateParams, $http, appSettings, localStorageService) {
    'ngInject';

    const sideFilterModel = this;
    const userName = sessionStorageService.get('userName');
    const isNewQueryFilterEnabled = getApplicationPreference('NEW_QUERY_FILTER_ENABLED');

    const QUERY_FILTER_SYNC_EVENT_INITIATOR = 'sideFilter';
    const ENTITY_SOURCES_FILTER = 'top entity sources';
    const ENTITY_SOURCES_FILTER_TITLE = 'top correlation sets';

    // custom filters
    const SYSTEM_TAG_PREFIX = 'system_tag';
    const TAG_PREFIX = 'query';
    const OLD_TAG_PREFIX = 'tag';

    // access governance predefined filter
    const METADATA_PREFIX = 'metadata';
    const ACCESS_RIGHTS = 'access_rights';
    const ACCESS_GOVERNANCE_FILTER_TITLE = 'data access';
    const ACCESS_GOVERNANCE_FILTER = {
      closed: false,
      filter_name: ACCESS_GOVERNANCE_FILTER_TITLE,
      data: [
        {
          value: 'OPEN ACCESS',
          displayValue: 'Open Access',
          prefix: METADATA_PREFIX,
          name: ACCESS_RIGHTS,
        },
      ],
      restOfData: [],
    };

    const PREDEFINED_FILTERS_MAPPING = {
      [ACCESS_RIGHTS]: ACCESS_GOVERNANCE_FILTER_TITLE,
    };

    const DYNAMIC_FILTER_SECTIONS_AMOUNT = 4;

    sideFilterModel.filterMeta = {};
    sideFilterModel.sectionOrdering = [];
    sideFilterModel.customFilters = {};
    sideFilterModel.availableTags = {};
    sideFilterModel.currentlySelectedItem = {};
    sideFilterModel.currentlySelectedCustom = {};
    sideFilterModel.currentlySelectedTag = '';
    sideFilterModel.addNewSelectObj = {};
    sideFilterModel.addNewFilterEnabled = false;
    sideFilterModel.isFilterPopulated = false;
    sideFilterModel.sectionOrderingCustom = [];
    sideFilterModel.queryFilterString = '';
    sideFilterModel.addNewFilterSelectChoices = [];

    sideFilterModel.predefinedFiltersOrdering = [];
    sideFilterModel.predefinedFilters = {};
    sideFilterModel.shouldShowExpirationBar = () => {
      return licenseService.shouldNotifyLicenseExpiration();
    };

    //perfect scroll bar
    const getContainer = () => $document[0].getElementById('filterSection');

    const getAllTags = () =>
      $http.get(appSettings.serverPath + '/' + appSettings.version + '/saved-queries').then(response => response.data);

    const setTags = data => {
      const customFilters = {},
        availableTags = {};

      if (data && data.length > 0) {
        for (let i = 0; i < data.length; i++) {
          const prop = data[i].tag_name.toLowerCase();

          availableTags[prop] = sideFilterModel.sectionOrderingCustom.indexOf(prop) > -1; //? true : false;

          if (!customFilters[prop]) {
            customFilters[prop] = {};
            customFilters[prop]['data'] = [];
            customFilters[prop]['restOfData'] = [];
            customFilters[prop]['closed'] = false;
          }

          customFilters[prop][customFilters[prop]['data'].length == 5 ? 'restOfData' : 'data'].push(data[i]);
        }

        Object.assign(customFilters, angular.copy(sideFilterModel.customFilters));
        Object.assign(availableTags, angular.copy(sideFilterModel.availableTags));
      }

      return { customFilters, availableTags };
    };

    const findObjectByName = (source, prop, value) => {
      const index = null,
        data = null;

      for (let index = 0, len = source.length; index < len; index++) {
        const data = source[index];

        if (data[prop] == value) {
          return { index, data };
        }
      }

      return { index, data };
    };

    const resetSelection = (prop, source, type, value) => {
      return $q(resolve => {
        let unselected = [];

        if (angular.isDefined(type)) {
          const _source = sideFilterModel[source][type.toLowerCase()]['data'];

          for (let j = 0, len = _source.length; j < len; j++) {
            const criteria = _source[j];
            if (criteria[prop] === value && criteria['selected']) {
              criteria['selected'] = false;
              unselected = [...unselected, criteria[prop]];
            }
          }
        } else {
          Object.keys(sideFilterModel[source]).forEach(type => {
            const _source = sideFilterModel[source][type.toLowerCase()]['data'];

            for (let j = 0, len = _source.length; j < len; j++) {
              const criteria = _source[j];

              if (!criteria['selected']) {
                continue;
              }

              criteria['selected'] = false;
            }
          });
        }

        resolve({ type, unselected });
      });
    };

    const setSelection = (type, value, prop, source) => {
      return $q(resolve => {
        let selected = [];

        const _source = sideFilterModel[source][type.toLowerCase()]['data'];

        for (let j = 0, len = _source.length; j < len; j++) {
          const criteria = _source[j];

          if (criteria[prop] === value && !criteria['selected']) {
            criteria['selected'] = true;
          }

          if (criteria['selected']) {
            selected = [...selected, criteria[prop]];
          }
        }

        resolve({ type, selected });
      });
    };

    const setSelectionByName = (data, source, type, prop, deepSource) => {
      return $q(resolve => {
        let selected = [];
        const _data =
          (data[0] === TAG_PREFIX || data[0] === SYSTEM_TAG_PREFIX || data[0] === METADATA_PREFIX) &&
          Array.isArray(data[1])
            ? data[1]
            : data;

        for (let i = 0; i < _data.length; i++) {
          const _source = sideFilterModel[source][type.toLowerCase()][deepSource];

          for (let j = 0, len = _source.length; j < len; j++) {
            const criteria = _source[j];

            if (criteria[prop] == _data[i]) {
              selected = [...selected, _data[i]];
              criteria['selected'] = true;
            }
          }
        }

        resolve(selected);
      });
    };

    const syncSectionData = (data, type, filterName, prop, source) => {
      const innerSearch = findObjectByName(sideFilterModel[source][type.toLowerCase()]['restOfData'], prop, filterName);

      if (innerSearch.data !== null) {
        sideFilterModel[source][type]['restOfData'].splice(innerSearch.index, 1);
        sideFilterModel[source][type]['data'].push(Object.assign(data, innerSearch.data));
      }
    };

    /**
     * This function makes filter query string parsing,
     * e.g. '?filter=%3Ffilter%3Dcountry%2520IN%2520(Germany%252C%2520Israel)%2520AND%2520system%253DMySQL'
     * becomes {identities: [Germany,Israel], attributes: [MySQL]},
     * in order to set filter selection (almost similar to 'parseFilterToObject' method from bigidHeader.component.js,
     * but different data structure because of country=>identities, system=>attributes and etc)
     */
    const parseFilter = filter => {
      return $q(resolve => {
        const parsedObj = {
          filterMeta: {},
          customFilters: {},
          predefinedFilters: {},
        };

        filter
          .replace('?filter=', '')
          .split(/(?:AND|OR)+/)
          .forEach(part => {
            const _part = decodeURIComponent(part),
              item = _part.split(_part.search(/\sIN\s/i) != -1 ? /\sIN\s/i : '=').map(i => i.trim());

            if (item[0] && item[1]) {
              const checkForOldTag = item[0].split('.');
              if (checkForOldTag[0] === OLD_TAG_PREFIX) {
                item[0] = `${TAG_PREFIX}.${checkForOldTag[1]}`;
              }

              const tagInsideRegex = new RegExp(`(?:${TAG_PREFIX}|${SYSTEM_TAG_PREFIX})+`, 'g'),
                metaDataInsideRegex = new RegExp(`(?:${METADATA_PREFIX})+`, 'g');

              const matchedItems = item[1].match(/^\((.+?)\)$/),
                tagInside = item[0].match(tagInsideRegex),
                metaDataInside = item[0].match(metaDataInsideRegex);

              if (tagInside) {
                const tagName = item[0]
                    .replace(tagInside[0] + '.', '')
                    .toLowerCase()
                    .trim(),
                  customFiltersKeys = Object.keys(sideFilterModel.customFilters);

                if (customFiltersKeys.indexOf(tagName) > -1) {
                  if (!parsedObj['customFilters'][tagName]) {
                    parsedObj['customFilters'][tagName] = [tagInside[0], []];
                  }

                  const items = matchedItems ? matchedItems[1].split(',').map(i => i.trim()) : [item[1]];
                  parsedObj['customFilters'][tagName][1] = parsedObj['customFilters'][tagName][1].concat(
                    isNewQueryFilterEnabled ? items.map(item => item.replace(/^"|"$/g, '')) : items,
                  );
                }
              } else if (metaDataInside) {
                const predefinedFilterName = item[0]
                    .replace(metaDataInside[0] + '.', '')
                    .toLowerCase()
                    .trim(),
                  targetPredefinedFilterSection = PREDEFINED_FILTERS_MAPPING[predefinedFilterName];

                if (sideFilterModel.predefinedFilters[targetPredefinedFilterSection]) {
                  if (!parsedObj['predefinedFilters'][targetPredefinedFilterSection]) {
                    parsedObj['predefinedFilters'][targetPredefinedFilterSection] = [metaDataInside[0], []];
                  }

                  parsedObj['predefinedFilters'][targetPredefinedFilterSection][1] = [
                    ...parsedObj['predefinedFilters'][targetPredefinedFilterSection][1],
                    item[1].replace(/^"|"$/g, ''),
                  ];
                }
              } else {
                if (!parsedObj['filterMeta'][sideFilterModel.sectionOrdering[item[0]]]) {
                  parsedObj['filterMeta'][sideFilterModel.sectionOrdering[item[0]]] = [];
                }

                const items = matchedItems ? matchedItems[1].split(',').map(i => i.trim()) : [item[1]];
                parsedObj['filterMeta'][sideFilterModel.sectionOrdering[item[0]]] = parsedObj['filterMeta'][
                  sideFilterModel.sectionOrdering[item[0]]
                ].concat(isNewQueryFilterEnabled ? items.map(item => item.replace(/^"|"$/g, '')) : items);
              }
            }
          });

        resolve(parsedObj);
      });
    };

    const handleFilterValue = (filter, fireEvent) => {
      parseFilter(filter).then(adaptedObject => {
        const targetFilterSections = Object.keys(adaptedObject);

        targetFilterSections.forEach(source => {
          const sourceValueMapping = {
            filterMeta: 'filter_name',
            customFilters: 'tag_value',
            predefinedFilters: 'value',
          };

          const prop = sourceValueMapping[source];

          resetSelection(prop, source).then(() => {
            const targetFilters = Object.keys(adaptedObject[source]);

            if (targetFilters.length > 0) {
              targetFilters.map(type => {
                setSelectionByName(adaptedObject[source][type], source, type, prop, 'data').then(selectedMain => {
                  setSelectionByName(adaptedObject[source][type], source, type, prop, 'restOfData').then(
                    selectedRest => {
                      const selected = [...selectedMain, ...selectedRest];

                      let filterType, selection;

                      switch (source) {
                        case 'filterMeta':
                          filterType = type.charAt(0).toUpperCase() + type.slice(1);
                          selection = adaptedObject[source][type];
                          break;
                        case 'customFilters':
                          filterType = {
                            name: type.charAt(0).toUpperCase() + type.slice(1),
                            prefix: adaptedObject[source][type][0],
                          };
                          selection = adaptedObject[source][type][1];
                          break;
                        case 'predefinedFilters':
                          filterType = {
                            name: Object.keys(PREDEFINED_FILTERS_MAPPING).find(
                              filterName => PREDEFINED_FILTERS_MAPPING[filterName] === type,
                            ),
                            prefix: adaptedObject[source][type][0],
                          };
                          selection = adaptedObject[source][type][1];
                          break;
                      }

                      if (fireEvent) {
                        $rootScope.$emit(
                          'callToFilterSearchHeader',
                          filterType,
                          selected,
                          [],
                          QUERY_FILTER_SYNC_EVENT_INITIATOR,
                        );
                      }

                      selection.forEach(item => {
                        if (selected.indexOf(item) != -1) {
                          const search = findObjectByName(
                            sideFilterModel[source][type.toLowerCase()]['data'],
                            prop,
                            item,
                          );

                          if (search.data === null) {
                            const data = { selected: true };
                            data[prop] = item;
                            syncSectionData(data, type, item, prop, source);
                          }
                        }
                      });
                    },
                  );
                });
              });
            }
          });
        });
      });
    };

    sideFilterModel.$onChanges = () => {
      if (sideFilterModel.data && !sideFilterModel.isFilterPopulated) {
        sideFilterModel.filterMeta = angular.copy(sideFilterModel.data);

        sideFilterModel.isFilterPopulated =
          Object.keys(sideFilterModel.filterMeta).length === DYNAMIC_FILTER_SECTIONS_AMOUNT ? true : false;
      }

      if (angular.isDefined(sideFilterModel.ready)) {
        sideFilterModel.forbiddenToUse = !sideFilterModel.ready;
      }

      if (sideFilterModel.savedQueries) {
        const { customFilters, availableTags } = setTags(sideFilterModel.savedQueries);

        sideFilterModel.customFilters = customFilters;
        sideFilterModel.availableTags = availableTags;
        getAddNewFilterSelectChoices();
      }
    };

    sideFilterModel.$onInit = () => {
      const userCustomFilters = localStorageService.get(userName + '.userCustomFilters');

      sideFilterModel.sectionOrderingCustom = userCustomFilters ? userCustomFilters : [];

      if (isPermitted(TAGS_SAVED_QUERIES_PERMISSIONS.READ.name)) {
        getAllTags().then(data => {
          if (data) {
            const { customFilters, availableTags } = setTags(data);

            sideFilterModel.customFilters = customFilters;
            sideFilterModel.availableTags = availableTags;
            getAddNewFilterSelectChoices();
          }
        });
      }

      sideFilterModel.predefinedFiltersOrdering = [ACCESS_GOVERNANCE_FILTER_TITLE];
      sideFilterModel.predefinedFilters = {
        [ACCESS_GOVERNANCE_FILTER_TITLE]: ACCESS_GOVERNANCE_FILTER,
      };
    };

    sideFilterModel.onItemSelected = (data, type, prop, source) => {
      const filterName = data[prop],
        search = findObjectByName(sideFilterModel[source][type]['data'], prop, filterName),
        currentlySelectedSource = source == 'filterMeta' ? 'currentlySelectedItem' : 'currentlySelectedCustom';

      let filterType;

      if (!search.found) {
        syncSectionData(data, type, filterName, prop, source);
      }

      switch (source) {
        case 'filterMeta':
          filterType = type.charAt(0).toUpperCase() + type.slice(1);
          break;
        case 'customFilters':
          filterType = {
            name: type.charAt(0).toUpperCase() + type.slice(1),
            prefix: data.tag_type ? (data.tag_type === SYSTEM_TAG_PREFIX ? SYSTEM_TAG_PREFIX : TAG_PREFIX) : TAG_PREFIX,
          };
          break;
      }

      resetSelection(prop, source, type).then(({ unselected }) => {
        if (unselected.length > 0) {
          $rootScope.$emit(
            'callToFilterSearchHeader',
            filterType,
            [],
            isNewQueryFilterEnabled ? unselected.map(item => `"${item}"`) : unselected,
            QUERY_FILTER_SYNC_EVENT_INITIATOR,
          );
        }

        setSelection(type, filterName, prop, source).then(({ selected }) => {
          $rootScope.$emit(
            'callToFilterSearchHeader',
            filterType,
            isNewQueryFilterEnabled ? selected.map(item => `"${item}"`) : selected,
            [],
            QUERY_FILTER_SYNC_EVENT_INITIATOR,
          );
        });
      });

      sideFilterModel[currentlySelectedSource][type] = '';
      sideFilterModel[source][type]['more'] = false;
    };

    sideFilterModel.toggleFilter = () => {
      sideFilterModel.filterOpened = !sideFilterModel.filterOpened;
      $scope.$emit('toggle-sidebar-filter', sideFilterModel.filterOpened);
    };

    sideFilterModel.toggleSection = (type, source) => {
      sideFilterModel[source][type.toLowerCase()]['closed'] = !sideFilterModel[source][type.toLowerCase()]['closed'];
    };

    sideFilterModel.addMore = (type, isCustom) => {
      const inputId = isCustom ? 'custom-input-' + type : 'input-' + type,
        source = isCustom ? 'customFilters' : 'filterMeta';

      sideFilterModel[source][type.toLowerCase()]['more'] = !sideFilterModel[source][type.toLowerCase()]['more'];

      if (sideFilterModel[source][type.toLowerCase()]['more']) {
        setTimeout(() => {
          document.getElementById(inputId).focus();
          sideFilterModel[source][type.toLowerCase()]['select'].activate();
        }, 500);
      }
    };

    sideFilterModel.getRiskClass = risk => (isNaN(risk) || risk == 0 ? true : false);

    sideFilterModel.setSelect = (select, type, isCustom) => {
      sideFilterModel[isCustom ? 'customFilters' : 'filterMeta'][type.toLowerCase()]['select'] = select;
    };

    sideFilterModel.setFilterItem = (type, item, prop, source) => {
      const name = item[prop];

      let filterType;

      switch (source) {
        case 'filterMeta':
          filterType = type.charAt(0).toUpperCase() + type.slice(1);
          break;
        case 'customFilters':
          filterType = {
            name: type.charAt(0).toUpperCase() + type.slice(1),
            prefix: item.tag_type ? (item.tag_type === SYSTEM_TAG_PREFIX ? SYSTEM_TAG_PREFIX : TAG_PREFIX) : TAG_PREFIX,
          };
          break;
        case 'predefinedFilters':
          filterType = {
            name: item.name,
            prefix: item.prefix,
          };
          break;
      }

      resetSelection(prop, source, type, name).then(({ unselected }) => {
        if (unselected.length > 0) {
          $rootScope.$emit(
            'callToFilterSearchHeader',
            filterType,
            [],
            isNewQueryFilterEnabled ? unselected.map(item => `"${item}"`) : unselected,
            QUERY_FILTER_SYNC_EVENT_INITIATOR,
          );
        }

        const equalIndex = unselected.indexOf(name);

        if (equalIndex == -1) {
          setSelection(type, name, prop, source).then(({ selected }) => {
            const selection = equalIndex > -1 ? selected.filter(item => item != name) : selected;
            $rootScope.$emit(
              'callToFilterSearchHeader',
              filterType,
              isNewQueryFilterEnabled ? selection.map(item => `"${item}"`) : selection,
              [],
              QUERY_FILTER_SYNC_EVENT_INITIATOR,
            );
          });
        }
      });
    };

    sideFilterModel.setAddNewSelect = select => {
      sideFilterModel.addNewSelectObj = select;
    };

    sideFilterModel.toggleAddNewFilter = () => {
      const filterContainer = getContainer();

      sideFilterModel.addNewFilterEnabled = !sideFilterModel.addNewFilterEnabled;

      if (sideFilterModel.addNewFilterEnabled) {
        setTimeout(() => {
          document.getElementById('addNewFilterInput').focus();
          filterContainer.scrollTop = filterContainer.scrollHeight;
        }, 500);
      }
    };

    sideFilterModel.onTagSelected = key => {
      if (key === ENTITY_SOURCES_FILTER_TITLE) {
        key = ENTITY_SOURCES_FILTER;
      }
      sideFilterModel.availableTags[key] = true;
      getAddNewFilterSelectChoices();

      sideFilterModel.sectionOrderingCustom.push(key);
      localStorageService.set(userName + '.userCustomFilters', sideFilterModel.sectionOrderingCustom);

      sideFilterModel.toggleAddNewFilter();
      sideFilterModel.currentlySelectedTag = '';
    };

    sideFilterModel.deleteCustomSection = key => {
      sideFilterModel.availableTags[key] = false;
      getAddNewFilterSelectChoices();

      sideFilterModel.sectionOrderingCustom.splice(sideFilterModel.sectionOrderingCustom.indexOf(key), 1);
      localStorageService.set(userName + '.userCustomFilters', sideFilterModel.sectionOrderingCustom);
    };

    sideFilterModel.isAddNewFilterEnabled = () => {
      let isEnabled = true;

      Object.keys(sideFilterModel.availableTags).forEach(key => {
        if (!sideFilterModel.availableTags[key]) {
          isEnabled = false;
          return;
        }
      });
      getAddNewFilterSelectChoices();

      return isEnabled;
    };

    sideFilterModel.getCustomFilterTitle = key =>
      startCase(key === ENTITY_SOURCES_FILTER ? ENTITY_SOURCES_FILTER_TITLE : key);

    const getAddNewFilterSelectChoices = () => {
      sideFilterModel.addNewFilterSelectChoices = mapKeys(
        pickBy(sideFilterModel.availableTags, value => value === false),
        (value, key) => (key === ENTITY_SOURCES_FILTER ? ENTITY_SOURCES_FILTER_TITLE : key),
      );
    };

    //Event listeners
    const listenerCallToHeaderQueryFilter = $rootScope.$on('callToHeaderQueryFilter', (event, filter, listeners) => {
      if (listeners && listeners[QUERY_FILTER_SYNC_EVENT_INITIATOR]) {
        if (filter) {
          if (sideFilterModel.isFilterPopulated) {
            handleFilterValue(filter);
          } else {
            sideFilterModel.queryFilterString = filter;
          }
        } else {
          resetSelection('filter_name', 'filterMeta');
          resetSelection('tag_value', 'customFilters');
          resetSelection('value', 'predefinedFilters');
          sideFilterModel.queryFilterString = '';
        }
      }
    });

    const listenerCleanFilter = $rootScope.$on('cleanFilter', (event, clearFilter) => {
      resetSelection('filter_name', 'filterMeta');
      resetSelection('tag_value', 'customFilters');
      resetSelection('value', 'predefinedFilters');

      if (clearFilter) {
        sideFilterModel.queryFilterString = '';
      }
    });

    $scope.$watch('sideFilterModel.isFilterPopulated', (newValue, oldValue) => {
      if (newValue === true && oldValue === false) {
        handleFilterValue(sideFilterModel.queryFilterString);
      }
    });

    //Unregister listeners
    $scope.$on('$destroy', function () {
      listenerCallToHeaderQueryFilter();
      listenerCleanFilter();
    });
  },
  bindings: {
    data: '<',
    sectionOrdering: '<',
    filterOpened: '<',
    savedQueries: '<',
  },
});
app.filter('riskFilter', function () {
  return function (value) {
    return isNaN(value) ? '0' : value;
  };
});
