import React, { FC, useMemo } from 'react';
import { styled } from '@mui/material';
import { BigidGridWithToolbar, BigidGridWithToolbarProps, CardSize, ViewType } from '@bigid-ui/grid';
import { BigidLoader, BigidPaper } from '@bigid-ui/components';
import { useUserPreferences } from '../../../components/hooks/useUserPrefrences';
import { $state } from '../../../services/angularServices';
import {
  getInitialScanTemplateGridColumns,
  getInitialScanTemplatesFiltersAsync,
} from './ScanTemplatesGridConfiguration';
import {
  DEFAULT_SCAN_TEMPLATES_SORTING,
  ScanTemplateActionType,
  ScanTemplateGridRow,
  ScanTemplateUserPreferences,
} from './scanTemplateTypes';
import { getTemplatesForGrid, runActionDialog, isOOTB } from './scanTemplatesService';
import { BigidDeleteIcon, BigidDuplicateIcon, BigidEditIcon } from '@bigid-ui/icons';
import { CardContent, CardFooter, CardHeader } from './ScanTemplateCard';
import { isPermitted } from '../../../services/userPermissionsService';
import { SCANS_PERMISSIONS } from '@bigid/permissions';
import { ScanTemplatesGuidedTour } from './GuidedTour/ScanTemplteGuidedTour';

const GridWrapper = styled('div')`
  width: 100%;
  display: flex;
  position: relative;
  height: 97%;
  max-height: 97;
  overflow: hidden;
`;

export const ScanTemplates: FC = () => {
  const initialScanTemplatesGridColumns = useMemo(getInitialScanTemplateGridColumns, []);
  const getInitialFilterToolbarConfig = useMemo(() => getInitialScanTemplatesFiltersAsync, []);
  const { isReady, gridColumns, preferences, updatePreferences, filterToolbarConfig } = useUserPreferences<
    ScanTemplateUserPreferences,
    ScanTemplateGridRow
  >({
    stateName: $state.$current.name,
    initialGridColumns: initialScanTemplatesGridColumns,
    getInitialFilterToolbarConfig,
  });

  const config: BigidGridWithToolbarProps<ScanTemplateGridRow> = useMemo(
    () => ({
      pageSize: 100,
      customRowIdName: '_id',
      entityName: 'Scan Templates',
      defaultSorting: preferences?.grid?.sort || DEFAULT_SCAN_TEMPLATES_SORTING,
      showSelectionColumn: true,
      showSortingControls: true,
      onGridStateChange: ({ filter, ...gridState }) => updatePreferences({ filterState: { filter }, gridState }),
      filterToolbarConfig,
      fetchData: async queryComponents => {
        try {
          const { data, totalCount } = await getTemplatesForGrid(queryComponents);
          return { data, totalCount };
        } catch (e) {
          return {
            totalCount: 0,
            data: [],
          };
        }
      },
      columns: gridColumns,
      toolbarActions: [
        {
          label: 'Delete',
          execute: async actionData => runActionDialog(actionData, ScanTemplateActionType.DELETE),
          isInline: true,
          hideActionInToolBar: true,
          icon: BigidDeleteIcon,
          disable: () => !isPermitted(SCANS_PERMISSIONS.DELETE_SCAN_PROFILES.name),
          show: actionData => !isOOTB(actionData),
        },
        {
          label: 'Edit',
          execute: async actionData => runActionDialog(actionData, ScanTemplateActionType.EDIT),
          isInline: true,
          hideActionInToolBar: true,
          icon: BigidEditIcon,
          disable: () => !isPermitted(SCANS_PERMISSIONS.EDIT_SCAN_PROFILES.name),
          show: actionData => !isOOTB(actionData),
        },
        {
          label: 'Duplicate',
          execute: async actionData => {
            return runActionDialog(actionData, ScanTemplateActionType.DUPLICATE);
          },
          disable: () => !isPermitted(SCANS_PERMISSIONS.CREATE_SCAN_PROFILES.name),
          show: () => true,
          icon: BigidDuplicateIcon,
          isInline: true,
          hideActionInToolBar: true,
        },
      ],
      shouldShowViewToggle: true,
      defaultView: ViewType.CARD,
      customViewConfiguration: {
        [ViewType.CARD]: {
          cardSize: CardSize.LARGE,
          renderHeader: (row: ScanTemplateGridRow) => <CardHeader row={row} />,
          renderContent: (row: ScanTemplateGridRow) => <CardContent row={row} />,
          renderFooter: (row: ScanTemplateGridRow) => <CardFooter row={row} />,
        },
      },
    }),
    [filterToolbarConfig, gridColumns, preferences?.grid?.sort, updatePreferences],
  );

  return (
    <GridWrapper>
      <BigidPaper dataAid="scanTemplatePaper">
        {!isReady && <BigidLoader />}
        {isReady && <BigidGridWithToolbar {...config} />}
      </BigidPaper>
      <ScanTemplatesGuidedTour />
    </GridWrapper>
  );
};
