import React, { FC } from 'react';
import { BigidClearFilledIcon } from '@bigid-ui/icons';
import styled from '@emotion/styled';
import { Typography } from '@mui/material';

const ErrorMessageContainer = styled('div')`
  padding: 16px;
  display: flex;
  background: #ffe1e1;
  border: 1px solid #ff4949;
  border-radius: 8px;
  & > svg {
    width: 24px;
    margin-right: 8px;
  }
`;

const ErrorMessageDescription = styled(Typography)`
  flex: 1;
  color: #3d3d3d;
  word-break: break-word;
`;

interface ErrorMessageBoxProps {
  errorMessage: string;
}

export const ErrorMessageBox: FC<ErrorMessageBoxProps> = ({ errorMessage }) => {
  return (
    <ErrorMessageContainer>
      <BigidClearFilledIcon color="negative" />
      <ErrorMessageDescription>{errorMessage}</ErrorMessageDescription>
    </ErrorMessageContainer>
  );
};
