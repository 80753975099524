import angular from 'angular';
import { convertToAngular } from '../../../common/services/convertToAngular';
import React, { FC } from 'react';
import { BigidSidePanel } from '@bigid-ui/components';
import { generateDataAid } from '@bigid-ui/utils';
import { useLocalTranslation } from '../../../react/views/ClassifiersManagement/ClassifierManagementSidePanel/Content/AIModel/translations';
import { ClassifierModel } from '../../../react/views/ClassifiersManagement/ClassifierManagementSidePanel/Content/AIModel/ClassifierModel';

export interface ClassifierModelSidePanelProps {
  classifierName: string;
  isOpen: boolean;
  onClose: () => void;
}

export const ClassifierModelSidePanel: FC<ClassifierModelSidePanelProps> = ({ classifierName, isOpen, onClose }) => {
  const { t } = useLocalTranslation();

  return (
    <BigidSidePanel
      dataAid={generateDataAid('BigidSidePanel', ['classifier-model'])}
      maxWidth="medium"
      content={<ClassifierModel classifierName={classifierName}></ClassifierModel>}
      open={isOpen}
      title={`${classifierName} ${t('main.titleSuffix')}`}
      onClose={onClose}
      isShowBackdrop={true}
    />
  );
};

angular
  .module('app')
  .component(
    'classifierModelSidePanel',
    convertToAngular(ClassifierModelSidePanel, ['classifierName', 'isOpen', 'onClose']),
  );
