import { BigidWizardHorizontalNavStepData } from '@bigid-ui/components';
import { ScanBasicDetailsStep } from './Steps/ScanBasicDetailsStep/ScanBasicDetailsStep';
import { SelectDataSourceStep } from './Steps/SelectDataSourceStep/SelectDataSourceStep';
import { SelectTemplateStep } from './Steps/SelectTemplateStep/SelectTemplateStep';
import { BasicDetails } from '../ScanTemplates/ScanTemplateWizard/Steps/BasicDetailsStep/BasicDetails';
import { Classification } from '../ScanTemplates/ScanTemplateWizard/Steps/ClassificationStep/Classification';
import { Correlation } from '../ScanTemplates/ScanTemplateWizard/Steps/CorrelationStep/Correlation';
import { ScanCreateData } from './createScanContext';

export enum CreateScanWizardSteps {
  DATA_SOURCE = 'Data Source',
  SELECT_TEMPLATE = 'Select Template',
  CLASSIFICATION = 'Classification',
  CORRELATION = 'Correlation',
  SCAN = 'Scan',
  TEMPLATE_BASIC_DETAILS = 'Template Details',
  SCAN_BASIC_DETAILS = 'Scan Details',
}

export const createScanWizardStepDisplayNames: Record<string, string> = {
  [CreateScanWizardSteps.DATA_SOURCE]: 'Select Data Source',
  [CreateScanWizardSteps.SELECT_TEMPLATE]: 'Select Template',
  [CreateScanWizardSteps.CLASSIFICATION]: 'Classification',
  [CreateScanWizardSteps.CORRELATION]: 'Correlation',
  [CreateScanWizardSteps.SCAN]: 'Schedule Scan',
  [CreateScanWizardSteps.TEMPLATE_BASIC_DETAILS]: 'Create New Template',
  [CreateScanWizardSteps.SCAN_BASIC_DETAILS]: 'Scan Basic Details',
};

export const CREATE_SCAN_TEMPLATE_STEPS = [
  CreateScanWizardSteps.TEMPLATE_BASIC_DETAILS,
  CreateScanWizardSteps.CLASSIFICATION,
  CreateScanWizardSteps.CORRELATION,
];
export const STEPS_BEFORE_CREATE_SCAN_TEMPLATE = [
  CreateScanWizardSteps.SCAN_BASIC_DETAILS,
  CreateScanWizardSteps.DATA_SOURCE,
];
export const STEPS_TO_HIDE_CREATE_SCAN_TEMPLATE = [CreateScanWizardSteps.SELECT_TEMPLATE];

export const createScanWizardInitialSteps: BigidWizardHorizontalNavStepData[] = [
  {
    id: CreateScanWizardSteps.SCAN_BASIC_DETAILS,
    title: CreateScanWizardSteps.SCAN_BASIC_DETAILS,
    component: ScanBasicDetailsStep,
  },
  {
    id: CreateScanWizardSteps.DATA_SOURCE,
    title: CreateScanWizardSteps.DATA_SOURCE,
    component: SelectDataSourceStep,
    subtext: 'Select Data Source',
  },
  {
    id: CreateScanWizardSteps.SELECT_TEMPLATE,
    title: CreateScanWizardSteps.SELECT_TEMPLATE,
    component: SelectTemplateStep,
    subtext: 'Select Your Template',
  },
  {
    id: CreateScanWizardSteps.TEMPLATE_BASIC_DETAILS,
    title: CreateScanWizardSteps.TEMPLATE_BASIC_DETAILS,
    component: BasicDetails,
    isHidden: true,
  },
  {
    id: CreateScanWizardSteps.CLASSIFICATION,
    title: CreateScanWizardSteps.CLASSIFICATION,
    component: Classification,
    isHidden: true,
  },
  {
    id: CreateScanWizardSteps.CORRELATION,
    title: CreateScanWizardSteps.CORRELATION,
    component: Correlation,
    isHidden: true,
  },
  {
    id: CreateScanWizardSteps.SCAN,
    title: CreateScanWizardSteps.SCAN,
  },
];

export const DEFAULT_STEPS_WITH_SCHEDULING_BUTTONS = [CreateScanWizardSteps.SELECT_TEMPLATE];

export interface OnClickScanParams {
  createScanData: ScanCreateData;
  isEditMode?: boolean;
  name?: string;
}
