import React, { FC, useContext } from 'react';
import styled from '@emotion/styled';
import { CaseSidePanelContext } from '../../hooks/CaseSidePanelContext';
import { generateDataAid } from '@bigid-ui/utils';
import { BigidSeverityBadge, BigidStatusBadgeSize } from '@bigid-ui/components';
import { getSensitivityLevel } from '../../CaseReport/caseReportService';

const BigidBadgeWrapper = styled.div({
  marginLeft: 14,
});

const ObjectPreviewInfoWrapper = styled.div({
  display: 'flex',
  flexDirection: 'row',
  width: '100%',
  padding: '0 10px',
});

export const ObjectPreviewInfo: FC = () => {
  const { objectSensitivity } = useContext(CaseSidePanelContext);
  return (
    <ObjectPreviewInfoWrapper data-aid={generateDataAid('ObjectPreviewInfo', ['main-container'])}>
      <BigidBadgeWrapper>
        <BigidSeverityBadge level={getSensitivityLevel(objectSensitivity)} size={BigidStatusBadgeSize.SMALL} />
      </BigidBadgeWrapper>
    </ObjectPreviewInfoWrapper>
  );
};
