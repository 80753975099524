import angular from 'angular';
import React, { Fragment, FunctionComponent } from 'react';
import style from './DotsLoader.module.scss';
import { convertToAngular } from '../../../common/services/convertToAngular';

export interface DotsLoaderData {
  text?: string;
  textSize?: string;
  textColor?: string;
  numberOfDots?: number;
}

const DOT_ANIMATION_DELAY_INCREMENT_STEP = 0.2;

export const DotsLoader: FunctionComponent<DotsLoaderData> = ({
  text = 'Loading',
  textSize = '1em',
  textColor = '#262d36',
  numberOfDots = 3,
}) => {
  const dots: JSX.Element[] = Array.from({ length: numberOfDots }).map((el, index) => {
    const animationDelay: number = DOT_ANIMATION_DELAY_INCREMENT_STEP * index;

    return (
      <span style={{ animationDelay: `${animationDelay}s` }} key={index} className={style.dot}>
        .
      </span>
    );
  });

  return (
    <Fragment>
      <span style={{ color: textColor, fontSize: textSize }} className={style.wrapper}>
        <span className={style.text}>{text}</span>
        <span className={style.dots}>{dots}</span>
      </span>
    </Fragment>
  );
};

angular
  .module('app')
  .component('bigidDotsLoader', convertToAngular<DotsLoaderData>(DotsLoader, ['text', 'textSize', 'textColor']));
