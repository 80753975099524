import React, { FC, ReactText, useState } from 'react';
import {
  BigidGridColumnTypes,
  BigidGridQueryComponents,
  BigidGridWithToolbar,
  BigidGridWithToolbarProps,
  BigidGridColumn,
} from '@bigid-ui/grid';
import { DateISO8601 } from '../../../types/types';
import { httpService } from '../../../services/httpService';
import { convertToAngular } from '../../../../common/services/convertToAngular';
import angular from 'angular';
import { Paper, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { dateUtils, $state } from '../../../services/angularServices';
import { queryService } from '../../../services/queryService';
import { notificationService } from '../../../services/notificationService';
import { isPermitted } from '../../../services/userPermissionsService';
import { DSAR_PERMISSIONS } from '@bigid/permissions';
import { useUserPreferences } from '../../../components/hooks/useUserPrefrences';
import { BigidLoader } from '@bigid-ui/components';
import { getApplicationPreference } from '../../../services/appPreferencesService';

export interface DsarAuditModel {
  user: string;
  action: string;
  endpoint: string;
  requestId: string;
  uniqueId: string;
  timestamp: DateISO8601;
  userAgent: string;
  displayName: string;
  id: ReactText;
}

export interface DsarAuditGetResponse {
  results: DsarAuditModel[];
  total: number;
}

const useStyles = makeStyles({
  paper: {
    display: 'flex',
    justifyContent: 'space-between',
    flexWrap: 'nowrap',
    boxShadow: 'none',
    padding: '20px 20px 10px',
  },
  root: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flex: '1 1 auto',
    flexFlow: 'column nowrap',
    overflow: 'hidden',
  },
});

const columns: BigidGridColumn<DsarAuditModel>[] = [
  {
    name: 'timestamp',
    title: 'Time Stamp',
    getCellValue: row => row.timestamp,
    type: BigidGridColumnTypes.DATE,
  },
  {
    name: 'user',
    title: 'User',
    getCellValue: row => row.user,
    type: BigidGridColumnTypes.TEXT,
  },
  {
    name: 'action',
    title: 'Action',
    getCellValue: row => row.action,
    type: BigidGridColumnTypes.TEXT,
  },
  {
    name: 'displayName',
    title: 'Display Name',
    getCellValue: row => row.displayName,
    type: BigidGridColumnTypes.TEXT,
    sortingEnabled: getApplicationPreference('ENCRYPT_USER_PRIVATE_DATA_FF') === 'false',
  },
  {
    name: 'requestId',
    title: 'Request ID',
    getCellValue: row => row.requestId,
    type: BigidGridColumnTypes.TEXT,
  },
  {
    name: 'endpoint',
    title: 'API End Point',
    getCellValue: row => row.endpoint,
    type: BigidGridColumnTypes.TEXT,
  },
  {
    name: 'uniqueId',
    title: 'Individual Unique ID',
    getCellValue: row => row.uniqueId,
    type: BigidGridColumnTypes.TEXT,
    sortingEnabled: getApplicationPreference('ENCRYPT_USER_PRIVATE_DATA_FF') === 'false',
  },
  {
    name: 'userAgent',
    title: 'User Agent',
    getCellValue: row => row.userAgent,
    type: BigidGridColumnTypes.TEXT,
  },
];

export const DsarAuditing: FC = () => {
  const classes = useStyles({});

  const [lastUpdate, setLastUpdate] = useState<string>('');

  const { isReady, preferences, gridColumns, updatePreferences } = useUserPreferences({
    stateName: `audit`,
    initialGridColumns: columns,
  });

  const gridWithToolbarConfig: BigidGridWithToolbarProps<DsarAuditModel> = {
    columns: gridColumns,
    entityName: 'Items',
    showSortingControls: true,
    showFilteringControls: true,
    defaultSorting: preferences?.grid?.sort || [{ field: 'timestamp', order: 'desc' }],
    onGridStateChange: ({ filter, ...gridState }) => updatePreferences({ filterState: { filter }, gridState }),
    fetchData: async (queryComponents: BigidGridQueryComponents) => {
      try {
        console.log('FF: ', getApplicationPreference('ENCRYPT_USER_PRIVATE_DATA_FF'));
        const normalizedQuery = getNormalizedQuery(queryComponents);
        const gridConfigQuery = queryService.getGridConfigQuery({ ...normalizedQuery, filter: [] });
        const { total, results } = await getDsarAudit(`?${gridConfigQuery}`);
        setLastUpdate(dateUtils.formatDate(new Date()));
        return {
          data: results,
          totalCount: total,
        };
      } catch (err) {
        const errMsg = `an error in fetch the DSAR audits`;
        notificationService.error(errMsg);
        console.error(`${errMsg} ${err}`);
      }
    },
    toolbarActions: [
      {
        label: 'Download',
        isGlobal: true,
        execute: async () => {
          try {
            await httpService.downloadFile(`sar/file-download/audit`);
          } catch (err) {
            const errMsg = `an error in download DSAR audits report`;
            notificationService.error(errMsg);
            console.error(`${errMsg} ${err}`);
          } finally {
            return Promise.resolve({ shouldGridReload: false });
          }
        },
        disable: () => false,
        show: () => isPermitted(DSAR_PERMISSIONS.EXPORT_AUDIT.name),
      },
    ],
  };

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <Typography variant={'h6'}>Audit</Typography>
        {lastUpdate && <Typography variant={'body2'}>Last Update: {lastUpdate}</Typography>}
      </Paper>
      <div className={classes.root}>
        {!isReady && <BigidLoader />}
        {isReady && <BigidGridWithToolbar {...gridWithToolbarConfig} />}
      </div>
    </div>
  );
};

/** make the sort stable in the query, to prevent duplicate ids errors in the grid */
function getNormalizedQuery(queryComponents: BigidGridQueryComponents): BigidGridQueryComponents {
  const { sort } = queryComponents;
  return {
    ...queryComponents,
    ...(sort?.length ? { sort: [...sort, { field: '_id', order: 'asc' }] } : {}),
  };
}

export function getDsarAudit(query: string) {
  const url = 'sar/audit' + query;
  return httpService.fetch(url).then(({ data: { data } }) => data[0]);
}

angular.module('app').component('dsarAuditing', convertToAngular(DsarAuditing, null));
