import './generalInfo.component.scss';
import { module } from 'angular';
import { compact, isEmpty, cloneDeep } from 'lodash';
import { isPermitted } from '../../../react/services/userPermissionsService';
import { DSAR_PERMISSIONS } from '@bigid/permissions';
const app = module('app');
import template from './generalInfo.component.html';
const DEFAULT_TOGGLE_VALUE = true;

/** An interface of the General Info collection Object consist from From and Report Config data */
const DATA_MODEL_INTERFACE = Object.freeze({
  companyInfo: {
    address: '',
    name: '',
    phoneNumber: '',
  },
  contactInfo: {
    name: '',
    position: '',
  },
  infoCollection: {
    purposes: [''],
    parties: [''],
    mechanisms: [''],
    /** @deprecated - replaced by 'rights' property of the object (see below) */
    // rights: '',
    /** @deprecated - replaced by 'geoDataTransfer.countries' property of the object (see below) */
    // countries: []
  },
  rights: {
    data_subject_rights: '',
  },
  geoDataTransfer: {
    countries: [''],
  },
  reportConfig: {
    infoCollection: {
      isEnabled: DEFAULT_TOGGLE_VALUE,
    },
    companyInfo: {
      isEnabled: DEFAULT_TOGGLE_VALUE,
    },
    contactInfo: {
      isEnabled: DEFAULT_TOGGLE_VALUE,
    },
    rights: {
      isEnabled: DEFAULT_TOGGLE_VALUE,
    },
    geoDataTransfer: {
      isEnabled: DEFAULT_TOGGLE_VALUE,
    },
  },
});

app.component('generalInfo', {
  template,
  controller: function ($document, $translate, generalInfoService, notificationService) {
    'ngInject';

    this.data = undefined;

    // indicate if there are update request in progress
    this.isUpdating = false;

    this.$onInit = () => {
      this.hasEditPermission = isPermitted(DSAR_PERMISSIONS.EDIT_PERSONAL_INFO.name);
      this.loadData(this.userId);
    };

    this.$onChanges = changesObj => {
      if (changesObj.userId && !changesObj.userId.isFirstChange()) {
        this.loadData(changesObj.userId);
      }
    };

    this.loadData = userId => {
      if (!userId) {
        window.console.warn(`Can't load data for empty userId.`);
        return;
      }

      this.onDataLoading();

      generalInfoService
        .getUserGeneralInfo()
        .then(result => this.setData(result.data))
        .catch(err => {
          $translate('API:MESSAGE:COMMON_ERROR').then(translation => {
            notificationService.error(translation);
          });
          window.console.error(err);
          throw err;
        })
        .finally(() => {
          this.onDataLoaded();
        });
    };

    this.setData = data => {
      // this object represents clean form data
      const cleanFormData = angular.copy(DATA_MODEL_INTERFACE);

      // if there is any data on the server
      if (data) {
        // first apply old logic on the stored state
        this.setCollectionsFields(data);

        // override the new form data defaults with the state from the server
        // the new state will be saved on any change to the form
        data = { ...cleanFormData, ...data };

        // handle backward compatibilities
        if (data && data.infoCollection && data.infoCollection.rights) {
          data.rights.data_subject_rights = data.infoCollection.rights;
          delete data.infoCollection.rights;
        }
      }
      this.data = data;
    };

    this.updateGeneralInfo = data => {
      if (this.isUpdating) {
        return;
      }
      this.isUpdating = true;

      this.onDataLoading();

      generalInfoService
        .updateUserGeneralInfo(data)
        .then(
          () => {
            $translate('API:MESSAGE:COMMON_PUT_SUCCESS').then(translation => {
              notificationService.success(translation);
            });
          },
          () => {
            $translate('API:MESSAGE:COMMON_ERROR').then(translation => {
              notificationService.error(translation);
            });
          },
        )
        .finally(() => {
          this.isUpdating = false;
          this.onDataLoaded();
        });
    };

    this.submitForm = data => {
      const _data = angular.copy(data);

      _data.infoCollection.purposes = _data.infoCollection.purposes.filter(item => item !== '');
      _data.infoCollection.parties = _data.infoCollection.parties.filter(item => item !== '');
      _data.infoCollection.mechanisms = _data.infoCollection.mechanisms.filter(item => item !== '');

      _data.geoDataTransfer.countries = _data.geoDataTransfer.countries.filter(item => item !== '');

      delete _data._id;
      delete _data.readableDate;

      this.updateGeneralInfo(_data);
    };

    this.onFormChanged = () => {
      this.submitForm(this.data);
    };

    this.onAddPurpose = () => {
      this.data.infoCollection.purposes = [...this.data.infoCollection.purposes, ''];
    };

    this.onRemovePurpose = index => {
      this.data.infoCollection.purposes = [
        ...this.data.infoCollection.purposes.slice(0, index),
        ...this.data.infoCollection.purposes.slice(index + 1),
      ];

      this.submitForm(this.data);
    };

    this.onAddParty = () => {
      this.data.infoCollection.parties = [...this.data.infoCollection.parties, ''];
    };

    this.onRemoveParty = index => {
      this.data.infoCollection.parties = [
        ...this.data.infoCollection.parties.slice(0, index),
        ...this.data.infoCollection.parties.slice(index + 1),
      ];

      this.submitForm(this.data);
    };

    this.onAddCountry = () => {
      this.data.geoDataTransfer.countries = [...this.data.geoDataTransfer.countries, ''];
    };

    this.onRemoveCountry = index => {
      this.data.geoDataTransfer.countries = [
        ...this.data.geoDataTransfer.countries.slice(0, index),
        ...this.data.geoDataTransfer.countries.slice(index + 1),
      ];

      this.submitForm(this.data);
    };

    this.onAddMechanism = () => {
      this.data.infoCollection.mechanisms = [...this.data.infoCollection.mechanisms, ''];
    };

    this.onRemoveMechanism = index => {
      this.data.infoCollection.mechanisms = [
        ...this.data.infoCollection.mechanisms.slice(0, index),
        ...this.data.infoCollection.mechanisms.slice(index + 1),
      ];

      this.submitForm(this.data);
    };

    this.setCollectionsFields = data => {
      if (!data.infoCollection) {
        return;
      }

      Object.keys(DATA_MODEL_INTERFACE.infoCollection).forEach(key => {
        if (isEmpty(data.infoCollection[key])) {
          data.infoCollection[key] = [''];
        }
      });

      if (!data.geoDataTransfer || isEmpty(compact(data.geoDataTransfer.countries))) {
        if (!isEmpty(compact(data.infoCollection.countries))) {
          data.geoDataTransfer = { countries: cloneDeep(data.infoCollection.countries) };
        } else {
          data.geoDataTransfer = { countries: [''] };
        }
      }
      delete data.infoCollection.countries;
    };

    this.onSwitcherToggled = (isEnabled, collection) => {
      collection.isEnabled = isEnabled;
      this.submitForm(this.data);
    };
  },
  bindings: {
    userId: '<',
    onDataLoading: '&',
    onDataLoaded: '&',
  },
});
