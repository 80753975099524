import React, { FC } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { styled } from '@mui/material';
import { BigidColorsV2, BigidBody1 } from '@bigid-ui/components';
import { generateDataAid } from '@bigid-ui/utils';

const useMinimizedStyles = makeStyles({
  stepBody: {
    fontWeight: '600',
    fontSize: '14px',
    lineHeight: '160%',
    color: BigidColorsV2.gray[700],
  },
});

const Root = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 4px;
  align-items: center;
`;

const Text = styled('div')`
  text-align: left;
`;

const Spacer = styled('br')``;

interface ActionableInsightsGuidedTourStepProps {
  dataAid?: string;
  firstSectionText?: string;
  secondSectionText?: string;
}

export const ActionableInsightsGuidedTourStepTemplate: FC<ActionableInsightsGuidedTourStepProps> = ({
  dataAid = 'ActionableInsightsGuidedTourStep',
  firstSectionText,
  secondSectionText,
}) => {
  const minimizedClasses = useMinimizedStyles({});
  return (
    <Root data-aid={dataAid}>
      <Text>
        <BigidBody1 className={minimizedClasses.stepBody} data-aid={generateDataAid(dataAid, ['body1'])}>
          {firstSectionText}
        </BigidBody1>
        <Spacer />
        {secondSectionText ? (
          <BigidBody1 className={minimizedClasses.stepBody} data-aid={generateDataAid(dataAid, ['body2'])}>
            {secondSectionText}
          </BigidBody1>
        ) : null}
      </Text>
    </Root>
  );
};
