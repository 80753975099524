/**
 * parsing cron expression into readable string
 */
const baseFrequency = { minute: 1, hour: 2, day: 3, week: 4, month: 5, year: 6 };
const baseFrequencyStrValues = { 1: 'minute', 2: 'hour', 3: 'day', 4: 'week', 5: 'month', 6: 'year' };
const monthsStrValues = {
  1: 'January',
  2: 'February',
  3: 'March',
  4: 'April',
  5: 'May',
  6: 'June',
  7: 'July',
  8: 'August',
  9: 'September',
  10: 'October',
  11: 'November',
  12: 'December',
};
const daysStrValues = {
  0: 'Sunday',
  1: 'Monday',
  2: 'Tuesday',
  3: 'Wednesday',
  4: 'Thursday',
  5: 'Friday',
  6: 'Saturday',
};

export const parseCronExpression = value => {
  const cron = value.replace(/\s+/g, ' ').split(' ');
  const frequency = { base: '1' };
  let outputStr = 'Every ';

  if (cron[0] === '*' && cron[1] === '*' && cron[2] === '*' && cron[3] === '*' && cron[4] === '*') {
    frequency.base = baseFrequency.minute; // every minute
  } else if (cron[1] === '*' && cron[2] === '*' && cron[3] === '*' && cron[4] === '*') {
    frequency.base = baseFrequency.hour; // every hour
  } else if (cron[2] === '*' && cron[3] === '*' && cron[4] === '*') {
    frequency.base = baseFrequency.day; // every day
  } else if (cron[2] === '*' && cron[3] === '*') {
    frequency.base = baseFrequency.week; // every week
  } else if (cron[3] === '*' && cron[4] === '*') {
    frequency.base = baseFrequency.month; // every month
  } else if (cron[4] === '*') {
    frequency.base = baseFrequency.year; // every year
  }

  if (cron[0] !== '*') {
    frequency.minuteValues = parseInt(cron[0]);
  }
  if (cron[1] !== '*') {
    frequency.hourValues = parseInt(cron[1]);
  }
  if (cron[2] !== '*') {
    frequency.dayOfMonthValues = parseInt(cron[2]);
  }
  if (cron[3] !== '*') {
    frequency.monthValues = parseInt(cron[3]);
  }
  if (cron[4] !== '*') {
    frequency.dayValues = parseInt(cron[4]);
  }

  let size = 0;

  for (let key in frequency) {
    if (frequency.hasOwnProperty(key)) size++;
  }

  if (frequency.base == 1 && size == 1) {
    return outputStr + 'minute';
  } else {
    outputStr = outputStr + baseFrequencyStrValues[frequency.base];

    if (frequency.base == 4) {
      outputStr = outputStr + ' on ' + daysStrValues[frequency.dayValues];
    }

    if (frequency.base >= 5) {
      const numeralFilter = function (input) {
        switch (input) {
          case 1:
            return '1st';
          case 2:
            return '2nd';
          case 3:
            return '3rd';
          case 21:
            return '21st';
          case 22:
            return '22nd';
          case 23:
            return '23rd';
          case 31:
            return '31st';
          case null:
            return null;
          default:
            return input + 'th';
        }
      };
      outputStr = outputStr + ' on the ' + numeralFilter(frequency.dayOfMonthValues);
    }

    if (frequency.base == 6) {
      outputStr = outputStr + ' of ' + monthsStrValues[frequency.monthValues];
    }

    if (frequency.base >= 2) {
      outputStr = outputStr + ' at ';
    }

    if (frequency.base >= 3) {
      outputStr = outputStr + frequency.hourValues + ':';
    }

    if (frequency.base >= 2) {
      outputStr = outputStr + (frequency.minuteValues == 0 ? '00' : frequency.minuteValues);
    }

    if (frequency.base == 2) {
      outputStr = outputStr + ' past the hour';
    }
  }

  return outputStr;
};

export const sortAlphabetically = new Intl.Collator(undefined, {
  numeric: true,
  sensitivity: 'base',
}).compare;
