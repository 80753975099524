import React, { FC } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { SecondaryButton, PrimaryButton, BigidColorsV2 } from '@bigid-ui/components';
import { topBarHeight } from '../../ActionWorkflowTop';

interface ConfigureActionWorkflowControlBarProps {
  onSave: () => void;
  onClose: () => void;
}

const useStyles = makeStyles({
  root: {
    height: topBarHeight,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: 16,
    borderTop: `1px solid ${BigidColorsV2.gray[200]}`,
  },
});

export const ConfigureActionWorkflowControlBar: FC<ConfigureActionWorkflowControlBarProps> = ({ onSave, onClose }) => {
  const { root } = useStyles({});

  return (
    <div className={root}>
      <SecondaryButton onClick={onClose} size="medium" dataAid="CancelEditAction" margin="0 16px 0 0" text="Cancel" />
      <PrimaryButton onClick={onSave} size="medium" dataAid="SaveAction" text="Save Action Settings" />
    </div>
  );
};
