import { ScanWindowFieldFill, OnChangeResponse } from './ScanWindowFieldFill';
import { Add } from '@mui/icons-material';
import { BigidDeleteIcon } from '@bigid-ui/icons';
import React, { FunctionComponent, useCallback, useEffect, useMemo, useState } from 'react';
import { ScanWindow, ScanWindowModel, scanWindowService } from '../scanWindowService';
import { SecondaryButton, BigidIcon, BigidButtonIcon } from '@bigid-ui/components';
import { v4 as uuid } from 'uuid';
import { debounce } from 'lodash';
const TEN_SEC_TTL = 5000;

interface ExtendedScanWindow extends ScanWindow {
  id: string;
}

export interface EditScanWindowObject {
  id: string;
  scanWindowName: string;
  scanWindows: ExtendedScanWindow[];
}

export interface EditScanWindowProps {
  name: string;
}

export const DEAFULT_SCAN_WINDOW = { end: '0 17 * * 0', start: '0 8 * * 0', isAllDay: false };

export const EditScanWindow: FunctionComponent<EditScanWindowProps> = ({ name }) => {
  const [scanWindowsObj, setScanWindowsObj] = useState<EditScanWindowObject>();
  const dataAid = 'edit-scan-window';

  const getScanWindowData = useCallback(async () => {
    const scanWindow = await scanWindowService.getScanWindowByName(name);
    const extendedObj = {
      scanWindowName: scanWindow[0].scanWindowName,
      id: scanWindow[0]._id.toString(),
      scanWindows: scanWindow[0].scanWindows.map(sw => ({ ...sw, id: uuid() })),
    };
    setScanWindowsObj(extendedObj);
  }, [name]);

  useEffect(() => {
    name && getScanWindowData();
  }, [getScanWindowData, name]);

  function getScanWindowModel(copyOfScanWindowsObj: EditScanWindowObject): ScanWindowModel {
    const scanWindowsNoId = copyOfScanWindowsObj.scanWindows.map(sw => ({
      start: sw.start,
      end: sw.end,
      isAllDay: sw.isAllDay,
    }));
    return { scanWindowName: copyOfScanWindowsObj.scanWindowName, scanWindows: scanWindowsNoId };
  }

  async function onScanWindowUpdate(name: string, copyOfScanWindowsObj: EditScanWindowObject) {
    await scanWindowService.updateScanWindow(name, getScanWindowModel(copyOfScanWindowsObj));
  }

  const onScanWindowUpdateDebounce = useMemo(() => {
    return debounce(onScanWindowUpdate, TEN_SEC_TTL);
    // TODO: this line was disabled on global icon change, the owner of this code should take a look at why there are missing dependencies here.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleOnChange = useCallback(
    async (response: OnChangeResponse) => {
      const { id, cronExpressionStart: start, isValid, cronExpressionEnd: end, allDay } = response;
      const copyOfScanWindowsObj = { ...scanWindowsObj };
      const objToUpdate = copyOfScanWindowsObj.scanWindows.find(sw => sw.id === id);
      objToUpdate.start = start;
      objToUpdate.end = end;
      objToUpdate.isAllDay = allDay;
      if (isValid) {
        await onScanWindowUpdateDebounce(name, copyOfScanWindowsObj);
      }
      setScanWindowsObj(copyOfScanWindowsObj);
    },
    // TODO: this line was disabled on global icon change, the owner of this code should take a look at why there are missing dependencies here.
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [name, scanWindowsObj],
  );

  const handleAddHourClick = useCallback(async () => {
    const newLineFields: ExtendedScanWindow = { ...DEAFULT_SCAN_WINDOW, id: uuid() };
    const objToUpdate = { ...scanWindowsObj };
    objToUpdate.scanWindows.push(newLineFields);
    await onScanWindowUpdateDebounce(name, objToUpdate);
    setScanWindowsObj(objToUpdate);
    // TODO: this line was disabled on global icon change, the owner of this code should take a look at why there are missing dependencies here.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [name, scanWindowsObj]);

  const handleDeleteClick = useCallback(
    async (id: string) => {
      const copyOfScanWindowsObj = { ...scanWindowsObj };
      copyOfScanWindowsObj.scanWindows = copyOfScanWindowsObj.scanWindows.filter(sw => sw.id !== id);
      await onScanWindowUpdateDebounce(name, copyOfScanWindowsObj);
      setScanWindowsObj(copyOfScanWindowsObj);
    },
    // TODO: this line was disabled on global icon change, the owner of this code should take a look at why there are missing dependencies here.
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [name, scanWindowsObj],
  );

  return (
    <div data-aid={dataAid}>
      {scanWindowsObj &&
        scanWindowsObj.scanWindows.map((sw, index) => (
          <div
            data-aid={`${dataAid}-wrapper-${index}`}
            key={sw.id}
            style={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-start' }}
          >
            <ScanWindowFieldFill
              startTime={sw.start}
              endTime={sw.end}
              intervalId={sw.id}
              isAllDay={sw.isAllDay}
              onChange={handleOnChange}
              dataAid={`${dataAid}-object-${index}`}
            />
            <BigidButtonIcon
              icon={BigidDeleteIcon}
              onClick={() => handleDeleteClick(sw.id)}
              disabled={scanWindowsObj.scanWindows.length === 1}
              dataAid={`${dataAid}-object-delete-${index}`}
            />
          </div>
        ))}
      <SecondaryButton key="secondary" size="small" onClick={handleAddHourClick} dataAid={`${dataAid}-add-hours`}>
        <BigidIcon icon={Add} label={'Add Hours'} dataAid={`${dataAid}-add-hours-icon`} />
      </SecondaryButton>
    </div>
  );
};
