import './scopes.component.scss';
import { module } from 'angular';
import template from './scopes.component.html';
import { ACCESS_MANAGEMENT_PERMISSIONS } from '@bigid/permissions';
import { isPermitted } from '../../../react/services/userPermissionsService';
import { $transitions } from '../../../react/services/angularServices';
import '../../../react/views/AccessManagement/components/Scopes/ScopesConnectButtonGroup';
import '../../../react/views/AccessManagement/components/Scopes/DataSourcesCollapsables/ScopesDataSourcesContent';
import '../../../react/views/AccessManagement/components/Scopes/ConnectDataSourcesByPrefixDialog';
import '../../../react/components/DataSourceSelectionGrid/ConnectDataSourcesBySelectionDialog';
import { sessionStorageService } from '../../../common/services/sessionStorageService';
import { getUsersQuery } from '../../../react/utilities/systemUsersUtils';
import { getApplicationPreference } from '../../../react/services/appPreferencesService';
const app = module('app');

app.component('scopes', {
  template,
  controller: function (
    $q,
    $rootScope,
    $translate,
    notificationService,
    DeleteConfirmation,
    $uibModal,
    uiGridConstants,
    scopesService,
    $document,
    $timeout,
    dataSourceConnectionsService,
    identityConnectionsService,
    rolesService,
  ) {
    'ngInject';
    let identitySourcesGridConfig = {};

    let addSourceModal = {};

    let isFormDirty = false;

    const GRID_COMMON_CONFIG = {
      enableSorting: true,
      enableFiltering: true,
      enablePaginationControls: false,
      appScopeProvider: this,
    };

    const SCOPES_DATA_LIST_ID = 'scopesDataList';
    const SCOPES_CONTENT_BODY_ID = 'scopesContentBody';
    const SCOPES_DATA_LIST_ITEM_ID = 'scope';

    const dataListContainer = $document[0].getElementById(SCOPES_DATA_LIST_ID);
    const contentBodyContainer = $document[0].getElementById(SCOPES_CONTENT_BODY_ID);

    const TRANSLATION_REQUIRED = [
      'SCOPES:TABS:CONNECTED_DATA_SOURCES',
      'SCOPES:TABS:CONNECTED_IDENTITY_SOURCES',
      'SCOPES:GRID:DATA_SOURCES',
      'SCOPES:GRID:IDENTITY_SOURCES',
      'SCOPES:GRID:LOCATION',
      'SCOPES:GRID:TYPE',
    ];

    const TAB_NAMES = {
      DATA_SOURCES: 'dataSources',
      ID_SOURCES: 'identitySources',
    };

    const DEFAULT_TAB = TAB_NAMES.DATA_SOURCES;

    const DATA_SOURCES_AVAILABLE_TYPES = [
      { value: 'hadoop', label: 'Hadoop' },
      { value: 'mongodb', label: 'MongoDB' },
      { value: 'rdb-mysql', label: 'RDB-MySQL' },
      { value: 'rdb-oracle', label: 'RDB-Oracle' },
      { value: 'rdb-postgresql', label: 'RDB-PostgreSQL' },
      { value: 'rdb-redshift', label: 'RDB-RedShift' },
      { value: 'rdb-mssql', label: 'RDB-MSSQL' },
      { value: 'nfs', label: 'NFS' },
      { value: 'emr', label: 'EMR' },
      { value: 's3', label: 'S3' },
      { value: 'hbase', label: 'HBase' },
      { value: 'hive', label: 'HIVE' },
      { value: 'smb', label: 'SMB' },
      { value: 'cassandra', label: 'Cassandra' },
      { value: 'csv', label: 'Csv' },
      { value: 'rdb-db2', label: 'RDB-DB2' },
      { value: 'azure-blob', label: 'Azure-Blob' },
      { value: 'azure-file', label: 'Azure-File' },
      { value: 'o365', label: 'O365' },
      { value: 'googledrive', label: 'GoogleDrive' },
      { value: 'salesforce', label: 'Salesforce' },
      { value: 'dynamodb', label: 'DynamoDB' },
      { value: 'sap', label: 'SAP' },
      { value: 'presto', label: 'Presto' },
      { value: 'Generic-rest-API', label: 'Generic-rest-API' },
      { value: 'Box', label: 'Box' },
      { value: 'RDB-teradata', label: 'RDB-teradata' },
      { value: 'SharePoint', label: 'SharePoint' },
      { value: 'Snowflake', label: 'Snowflake' },
      { value: 'O365-OneDrive', label: 'O365-OneDrive' },
      { value: 'O365-Outlook', label: 'O365-Outlook' },
      { value: 'rdb-hana', label: 'RDB-Hana' },
      { value: 'rdb-sybase', label: 'RDB-Sybase' },
      { value: 'workday', label: 'Workday' },
    ];

    const IDENTITY_SOURCES_AVAILABLE_TYPES = [
      { value: 'mysql', label: 'MySQL' },
      { value: 'postgres', label: 'Postgres' },
      { value: 'ms-sql', label: 'MS-SQL' },
      { value: 'oracle', label: 'Oracle' },
      { value: 'salesforce', label: 'Salesforce' },
    ];

    this.scopes = [];
    this.identitySources = [];

    this.tabs = {};
    this.currentTab = '';

    this.scope = {};
    this.scopeForm = {};

    this.userQuery = '';

    this.identitySourcesGridConfig = {};

    this.identitySourcesGridApi = null;
    this.wildcardScoping = getApplicationPreference('WILDCARD_SCOPING');

    const IDENTITY_SOURCES_COLUMN_DELETE = {
      name: 'delete',
      headerCellTemplate:
        '<div class="trash-cell" ><span class="fa fa-trash-o"' +
        ' ng-click="grid.appScope.onDeleteAllRows()"></span></div>',
      cellTemplate:
        '<div class="trash-cell" ><span class="fa fa-trash-o"' +
        ' ng-click="grid.appScope.onDeleteRow(row.entity)"></span></div>',
      minWidth: 30,
      width: 30,
      enableFiltering: false,
      enableSorting: false,
    };

    const initializeGridsConfig = gridColumnsNames => {
      const IDENTITY_SOURCES_GRID_CONFIG = {
        columnDefs: [
          {
            name: gridColumnsNames.identitySources.name,
            field: 'name',
            filter: {
              condition: uiGridConstants.filter.CONTAINS,
              type: uiGridConstants.filter.INPUT,
              flags: {
                caseSensitive: false,
              },
            },
          },
          {
            name: gridColumnsNames.identitySources.type,
            field: 'type',
            filter: {
              type: uiGridConstants.filter.SELECT,
              selectOptions: IDENTITY_SOURCES_AVAILABLE_TYPES,
            },
          },
          {
            name: gridColumnsNames.dataSources.location,
            field: 'location',
            filter: {
              condition: uiGridConstants.filter.CONTAINS,
              type: uiGridConstants.filter.INPUT,
              flags: { caseSensitive: false },
            },
          },
        ],
        data: [],
      };

      identitySourcesGridConfig = IDENTITY_SOURCES_GRID_CONFIG;
    };

    const initializeData = async callback => {
      try {
        const [
          { data },
          {
            data: { users },
          },
          {
            data: { roles },
          },
        ] = await Promise.all([
          rolesService.getRBACScopes(),
          rolesService.getRBACUsers(getUsersQuery({ searchString: sessionStorageService.get('userName') })),
          rolesService.getRBACRoles(),
        ]);
        const scopes = getNonRootScopes(data);
        const { roleIds } = users.find(({ name }) => name === sessionStorageService.get('userName'));
        this.isRoot = roles.filter(({ _id }) => roleIds.includes(_id)).some(({ scopes }) => scopes.includes('root'));

        const identitySourcesFetchResult = await getIdentitySources();
        const identitySources = identitySourcesFetchResult.id_connections;

        return callback({ scopes, identitySources });
      } catch (err) {
        $translate('API:MESSAGE:COMMON_ERROR').then(translation => {
          notificationService.error(translation);
        });
        console.error(err);
        return callback({ scopes: [], identitySources: [] });
      }
    };

    // API calls -- start
    const getNonRootScopes = data => data.scopes.filter(scope => scope.name !== 'root' && scope.id !== 'root');

    const getIdentitySources = () => identityConnectionsService.getIdentityConnectionsData();

    const fetchScopes = (scrollTo = '', entityToProceed = null, fromDelete = false) => {
      this.onDataLoading();

      rolesService.getRBACScopes().then(
        response => {
          const { data } = response;
          const scopes = getNonRootScopes(data);
          this.scopes = scopes.sort(alphaSort).map(scope => {
            if (scope.dataSourceNames === null) {
              scope.dataSourceNames = [];
            }

            if (scope.idSourceNames === null) {
              scope.idSourceNames = [];
            }

            return scope;
          });

          this.onDataLoaded();

          if (entityToProceed !== null) {
            if (Object.keys(entityToProceed).length === 0 && entityToProceed.constructor === Object) {
              onCreateScope();
            } else {
              onEditScope(entityToProceed);
            }
          } else {
            if (fromDelete && this.scopes?.length > 0) {
              onEditScope(this.scopes[0]);
            }
            if (this.editModeOn) {
              resetListSelection();
              setListSelection(this.scope.id);
            }

            $timeout(() => {
              const index = this.scopes.findIndex(scope => scope.id === this.scope.id),
                dataListItem = $document[0].getElementById(`${SCOPES_DATA_LIST_ITEM_ID}-${index}`);

              if (dataListItem !== null && index !== -1) {
                dataListContainer.scrollTop = index * dataListItem.offsetHeight;
              }
            }, 0);
          }

          $timeout(() => {
            if (!dataListContainer) return;
            switch (scrollTo) {
              case 'top':
                dataListContainer.scrollTop = 0;
                break;
              case 'bottom':
                dataListContainer.scrollTop = dataListContainer.scrollHeight;
                break;
              default:
                if (entityToProceed !== null) {
                  if (Object.keys(entityToProceed).length === 0 && entityToProceed.constructor === Object) {
                    dataListContainer.scrollTop = 0;
                  } else {
                    const index = this.scopes.findIndex(scope => scope.id === entityToProceed.id),
                      dataListItem = $document[0].getElementById(`${SCOPES_DATA_LIST_ITEM_ID}-${index}`);

                    if (dataListItem !== null && index !== -1) {
                      dataListContainer.scrollTop = index * dataListItem.offsetHeight;
                    }
                  }
                }
            }
          }, 0);
        },
        () => {
          this.onDataLoaded();

          $translate('API:MESSAGE:COMMON_ERROR').then(translation => {
            notificationService.error(translation);
          });
        },
      );
    };

    const createScope = (data, entityToProceed = null) => {
      scopesService.createScope(data).then(
        response => {
          $translate('API:MESSAGE:COMMON_POST_SUCCESS').then(translation => {
            notificationService.success(translation);
          });

          this.editModeOn = true;
          this.createModeOn = false;

          this.scope.id = response.data.scopeId;

          if (contentBodyContainer !== null) contentBodyContainer.scrollTop = 0;

          isFormDirty = false;

          this.scopeForm.$submitted = false;

          fetchScopes('', entityToProceed);
        },
        response => {
          if (typeof response.data.message == 'undefined' && typeof response.data.details == 'undefined') {
            $translate('API:MESSAGE:COMMON_ERROR').then(translation => {
              notificationService.error(translation);
            });
          } else {
            const message = response.data.message || '';
            const details = response.data.details || '';

            notificationService.error(`${message} ${details}`);
          }
        },
      );
    };

    const updateScope = (id, data, entityToProceed = null) => {
      !data.dataSourceNames && (data.dataSourceNames = []);
      !data.idSourceNames && (data.idSourceNames = []);
      const { updated_at, created_at, ...payload } = data;
      scopesService.updateScope(id, payload).then(
        response => {
          $translate('API:MESSAGE:COMMON_PUT_SUCCESS').then(translation => {
            notificationService.success(translation);
          });

          if (contentBodyContainer !== null) contentBodyContainer.scrollTop = 0;

          isFormDirty = false;

          this.scopeForm.$submitted = false;

          fetchScopes('', entityToProceed);
        },
        response => {
          if (typeof response.data.message == 'undefined' && typeof response.data.details == 'undefined') {
            $translate('API:MESSAGE:COMMON_ERROR').then(translation => {
              notificationService.error(translation);
            });
          } else {
            const message = response.data.message || '';
            const details = response.data.details || '';

            notificationService.error(`${message} ${details}`);
          }
        },
      );
    };

    const deleteScope = id => {
      scopesService.deleteScope(id).then(
        () => {
          $translate('API:MESSAGE:COMMON_DELETE_SUCCESS').then(translation => {
            notificationService.success(translation);
          });

          this.editModeOn = false;
          this.createModeOn = false;

          this.scope = {};

          fetchScopes('top', null, true);
        },
        response => {
          if (typeof response.data.message == 'undefined' && typeof response.data.details == 'undefined') {
            $translate('API:MESSAGE:COMMON_ERROR').then(translation => {
              notificationService.error(translation);
            });
          } else {
            const message = response.data.message || '';
            const details = response.data.details || '';

            notificationService.error(`${message} ${details}`);
          }
        },
      );
    };

    // API calls -- end

    // component methods -- start

    this.$onInit = () => {
      this.isManagePermitted = isPermitted(ACCESS_MANAGEMENT_PERMISSIONS.MANAGE.name);

      this.onStartTransitionListener = $transitions.onBefore({}, () => {
        return new $q(resolve => {
          if (!isFormDirty) {
            resolve();
            return;
          }

          onUserLeavesForm(
            () => {
              if (this.scopeForm.$valid) {
                this.scopeForm.$submitted = true;
                onSubmitScope({});
                isFormDirty = false;
                resolve();
              } else {
                notificationService.warning('In order to save your changes, please fill out all the mandatory fields');
                resolve(false);
              }
            },
            () => {
              isFormDirty = false;
              resolve();
            },
          );
        });
      });

      $translate(TRANSLATION_REQUIRED).then(translation => {
        const tabs = {
          dataSources: '',
          identitySources: '',
        };

        const gridColumnsNames = {
          dataSources: {
            name: '',
            type: '',
            location: '',
          },
          identitySources: {
            name: '',
            type: '',
            location: '',
          },
        };

        //tabs
        tabs.dataSources = translation['SCOPES:TABS:CONNECTED_DATA_SOURCES'];
        tabs.identitySources = translation['SCOPES:TABS:CONNECTED_IDENTITY_SOURCES'];

        this.tabs = tabs;

        //grids
        gridColumnsNames.dataSources.name = translation['SCOPES:GRID:DATA_SOURCES'];
        gridColumnsNames.dataSources.type = translation['SCOPES:GRID:TYPE'];
        gridColumnsNames.dataSources.location = translation['SCOPES:GRID:LOCATION'];

        gridColumnsNames.identitySources.name = translation['SCOPES:GRID:IDENTITY_SOURCES'];
        gridColumnsNames.identitySources.type = translation['SCOPES:GRID:TYPE'];
        gridColumnsNames.identitySources.location = translation['SCOPES:GRID:LOCATION'];

        initializeGridsConfig(gridColumnsNames);

        this.onDataLoading();

        initializeData(result => {
          const { scopes, identitySources } = result;

          this.scopes = scopes.sort(alphaSort).map(scope => {
            if (scope.dataSourceNames === null) {
              scope.dataSourceNames = [];
            }

            if (scope.idSourceNames === null) {
              scope.idSourceNames = [];
            }

            if (this.wildcardScoping && scope.dataSourceExpressions === null) {
              scope.dataSourceExpressions = [];
            }

            return scope;
          });

          this.identitySources = identitySources;

          if (this.scopes.length > 0) {
            onEditScope(this.scopes[0]);
          }

          this.onDataLoaded();
        });
      });
    };

    this.onCreate = () => {
      if (isFormDirty) {
        onUserLeavesForm(
          () => {
            this.scopeForm.$submitted = true;

            onSubmitScope({});
          },
          () => {
            onCreateScope();
          },
        );
      } else {
        onCreateScope();
      }
    };

    this.onEdit = scope => {
      if (this.scope.id !== scope.id) {
        if (this.isManagePermitted && isFormDirty) {
          onUserLeavesForm(
            () => {
              this.scopeForm.$submitted = true;

              onSubmitScope(scope);
            },
            () => {
              onEditScope(scope);
            },
          );
        } else {
          onEditScope(scope);
        }
      }
    };

    this.onDelete = async scope => {
      const closeButtonText = await $translate('BUTTONS:CLOSE');
      const actionButtonText = await $translate('BUTTONS:DELETE');
      const headerText = await $translate('APP:COMMON:DELETE_CONFIRMATION_HEADER', { entityName: scope.name });
      const bodyText = await $translate('APP:COMMON:DELETE_CONFIRMATION_TEXT', { entityName: scope.name });

      const modalOptions = {
        closeButtonText: closeButtonText,
        actionButtonText: actionButtonText,
        headerText: headerText,
        bodyText: bodyText,
      };

      DeleteConfirmation.showModal({}, modalOptions).then(() => deleteScope(scope.id));
    };

    this.onSubmit = () => {
      onSubmitScope();
    };

    this.onCancel = () => {
      this.scope = {};

      this.identitySourcesGridConfig.data = [];

      resetFormState();
    };

    this.onTabSelected = name => {
      setTabSelected(name);
    };

    this.onAddSourceByPrefix = () => {
      this.shouldShowPrefixModal = true;
      $rootScope.$apply();
    };

    this.onClosePrefixModal = () => {
      this.shouldShowPrefixModal = false;
      $rootScope.$apply();
    };

    this.onAddSourceBySelection = () => {
      this.shouldShowSelectionModal = true;
      $rootScope.$apply();
    };

    this.onCloseSelectionModal = () => {
      this.shouldShowSelectionModal = false;
      $rootScope.$apply();
    };

    this.onUpdateDSPrefix = entry => {
      this.scope = {
        ...this.scope,
        dataSourceExpressions: this.scope.dataSourceExpressions
          ? [...this.scope.dataSourceExpressions, entry]
          : [entry],
      };
      isFormDirty = true;
      this.onClosePrefixModal();
    };

    this.onSubmitSelectedDS = dsSelected => {
      this.scope = {
        ...this.scope,
        dataSourceNames: [...(this.scope.dataSourceNames || []), ...dsSelected],
      };
      isFormDirty = true;
    };

    this.onRemoveDSPrefix = toRemove => {
      this.scope = {
        ...this.scope,
        dataSourceExpressions: this.scope.dataSourceExpressions.filter(({ value }) => value !== toRemove),
      };
      isFormDirty = true;
      $rootScope.$apply();
    };

    this.onRemoveDSSingle = toRemove => {
      this.scope = {
        ...this.scope,
        dataSourceNames: this.scope.dataSourceNames.filter(value => !toRemove.includes(value)),
      };

      isFormDirty = true;
      $rootScope.$apply();
    };

    this.onDeleteRow = row => {
      this.scope.idSourceNames = deleteScopeSource(this.scope.idSourceNames, row.name);
      this.identitySourcesGridConfig.data = deleteGridRow(this.identitySourcesGridConfig.data, row.name);

      isFormDirty = true;
    };

    this.onDeleteAllRows = () => {
      this.identitySourcesGridConfig.data = [];
      this.scope.idSourceNames = [];

      isFormDirty = true;
    };

    this.onFormChanged = () => {
      isFormDirty = true;
    };

    const onUserLeavesForm = async (resolveCallback, rejectCallback) => {
      const closeButtonText = await $translate('BUTTONS:NO');
      const actionButtonText = await $translate('BUTTONS:YES');
      const headerText = await $translate('APP:COMMON:LEAVE_CONFIRMATION_HEADER');
      const bodyText = await $translate('APP:COMMON:LEAVE_CONFIRMATION_TEXT');

      const modalOptions = {
        closeButtonText: closeButtonText,
        actionButtonText: actionButtonText,
        headerText: headerText,
        bodyText: bodyText,
      };

      DeleteConfirmation.showModal({}, modalOptions).then(resolveCallback, rejectCallback);
    };

    const onCreateScope = () => {
      resetListSelection();

      this.scope = {};

      this.createModeOn = true;
      this.editModeOn = false;

      resetFormState();

      setTabSelected(DEFAULT_TAB);
    };

    const onEditScope = scope => {
      resetListSelection();
      setListSelection(scope.id);

      this.scope = { ...scope };

      this.editModeOn = true;
      this.createModeOn = false;

      resetFormState();

      setTabSelected(DEFAULT_TAB);
    };

    const onSubmitScope = (entityToProceed = null) => {
      if (!this.scopeForm.$valid) return;

      const payload = angular.copy(this.scope);

      if (typeof payload.selected != 'undefined') delete payload.selected;

      switch (true) {
        case this.createModeOn:
          createScope(payload, entityToProceed);
          break;
        case this.editModeOn:
          updateScope(this.scope.id, payload, entityToProceed);
          break;
      }
    };

    const deleteScopeSource = (source, data) => {
      const index = source.findIndex(name => name === data);

      source = [...source.slice(0, index), ...source.slice(index + 1)];

      return source;
    };

    const deleteGridRow = (source, data) => {
      const index = source.findIndex(row => row.name === data);

      source = [...source.slice(0, index), ...source.slice(index + 1)];

      return source;
    };

    const setTabSelected = name => {
      if (name === TAB_NAMES.ID_SOURCES) {
        this.identitySourcesGridConfig = Object.assign({}, GRID_COMMON_CONFIG, angular.copy(identitySourcesGridConfig));

        this.identitySourcesGridConfig.columnDefs = [
          ...this.identitySourcesGridConfig.columnDefs,
          IDENTITY_SOURCES_COLUMN_DELETE,
        ];

        if (typeof this.scope.idSourceNames != 'undefined') {
          this.identitySourcesGridConfig.data = this.identitySources.filter(row =>
            this.scope.idSourceNames.includes(row.name),
          );
        } else {
          this.identitySourcesGridConfig.data = [];
        }

        this.identitySourcesGridConfig.onRegisterApi = gridApi => {
          this.identitySourcesGridApi = gridApi;
        };
      }

      this.currentTab = name;
    };

    const alphaSort = (a, b) => {
      const aName = a.name.toLowerCase(),
        bName = b.name.toLowerCase();

      if (aName < bName) {
        return -1;
      } else if (aName > bName) {
        return 1;
      } else {
        return 0;
      }
    };

    const resetFormState = () => {
      if (
        typeof this.scopeForm != 'undefined' &&
        typeof this.scopeForm.$setPristine == 'function' &&
        typeof this.scopeForm.$setUntouched == 'function'
      ) {
        this.scopeForm.$setPristine();
        this.scopeForm.$setUntouched();
      } else {
        this.scopeForm = {};
      }

      this.scopeForm.$submitted = false;

      isFormDirty = false;
    };

    const resetListSelection = (except = '') => {
      for (let i = 0, len = this.scopes.length; i < len; i++) {
        if (this.scopes[i].id === except) {
          continue;
        }
        this.scopes[i]['selected'] = false;
      }
    };

    const setListSelection = id => {
      for (let i = 0, len = this.scopes.length; i < len; i++) {
        if (this.scopes[i].id === id) {
          this.scopes[i]['selected'] = true;
          break;
        }
      }
    };

    const onSourceAdded = newSelection => {
      newSelection.forEach(row => {
        this.scope.idSourceNames = [...(this.scope.idSourceNames || []), row.name];
        this.identitySourcesGridConfig.data = [...this.identitySourcesGridConfig.data, row];
      });
      isFormDirty = true;
      $rootScope.apply();
    };

    this.onAddSource = () => {
      let gridConfig, gridData, translationRequired, renderedRows;

      const translations = {
        headerText: '',
        cancelButton: '',
        submitButton: '',
      };

      gridConfig = Object.assign({}, GRID_COMMON_CONFIG, angular.copy(identitySourcesGridConfig));
      renderedRows = this.identitySourcesGridConfig.data.map(row => row.name);
      gridData = this.identitySources.filter(item => !renderedRows.includes(item.name));
      translationRequired = [
        'SCOPES:ADD_SOURCE_MODAL:ID:HEADER',
        'SCOPES:ADD_SOURCE_MODAL:ID:SUBMIT',
        'BUTTONS:CANCEL',
      ];

      $translate(translationRequired).then(translation => {
        translations.headerText = translation['SCOPES:ADD_SOURCE_MODAL:ID:HEADER'];
        translations.submitButton = translation['SCOPES:ADD_SOURCE_MODAL:ID:SUBMIT'];

        translations.cancelButton = translation['BUTTONS:CANCEL'];

        addSourceModal = $uibModal
          .open({
            animation: true,
            templateUrl: 'addSourceModal.template.html',
            backdrop: 'static',
            windowClass: 'scopes-add-source',
            controllerAs: '$ctrl',
            controller: [
              'gridConfig',
              'gridData',
              'translations',
              '$uibModalInstance',
              function (gridConfig, gridData, translations, $uibModalInstance) {
                this.gridConfig = gridConfig;
                this.gridConfig.data = gridData;

                this.gridConfigApi = null;

                this.gridConfig.onRegisterApi = gridApi => {
                  this.gridConfigApi = gridApi;
                };

                this.translations = translations;

                this.cancel = () => {
                  $uibModalInstance.close({ submitted: false });
                };

                this.submit = () => {
                  const selectedSources = this.gridConfigApi.selection.getSelectedRows();

                  $uibModalInstance.close({
                    submitted: true,
                    selectedSources: selectedSources,
                  });
                };
              },
            ],
            resolve: {
              gridConfig: () => gridConfig,
              gridData: () => gridData,
              translations: () => translations,
            },
          })
          .result.then(result => {
            if (result.submitted) {
              const { selectedSources } = result;

              onSourceAdded(selectedSources);
            }
          });
      });
    };

    this.$onDestory = () => {
      this.onStartTransitionListener();
    };
  },
  bindings: {
    onDataLoading: '&',
    onDataLoaded: '&',
  },
});
