import React, { FC } from 'react';
import { generateDataAid } from '@bigid-ui/utils';
import { BigidBody1, BigidHeading6, BigidLink } from '@bigid-ui/components';
import { $state } from '../../../../services/angularServices';
import { CONFIG } from '../../../../../config/common';
import styled from '@emotion/styled';
import { useLocalTranslation } from '../translations';

const Wrapper = styled('div')({
  display: 'flex',
  justifyContent: 'center',
  padding: '4px 0px',
  flexDirection: 'column',
  alignItems: 'flex-start',
  gap: '12px',
  flex: '1 0 0',
  wordBreak: 'break-word',
});

interface NotificationContentProps {
  fullyQualifiedName: string;
  dataAid?: string;
}

const NotificationContent: FC<NotificationContentProps> = ({ fullyQualifiedName, dataAid = 'similarTables' }) => {
  const { t } = useLocalTranslation();

  const handleOnClick = () => {
    $state.go(CONFIG.states.CATALOG_PREVIEW, {
      id: encodeURIComponent(fullyQualifiedName),
    });
  };
  return (
    <Wrapper data-aid={`${generateDataAid(dataAid, ['notification'])}`}>
      <BigidHeading6> {t('notification.title')} </BigidHeading6>
      <BigidBody1>{`${t('notification.description')} ${fullyQualifiedName}`}</BigidBody1>
      <BigidLink underline="none" onClick={handleOnClick} text={t('notification.link')}></BigidLink>
    </Wrapper>
  );
};

export const getNotificationContent = (fullyQualifiedName: string) => {
  return <NotificationContent fullyQualifiedName={fullyQualifiedName} />;
};
