import React from 'react';
import { smallIdLicenseService } from '../../services/smallIdLicenseService';
import { GettingStartedWithSmallID } from '../GettingStartedWithSmallID/GettingStartedWithSmallID';
import { GettingStarted } from './GettingStarted';
import { isPermitted } from '../../services/userPermissionsService';
import { GETTING_STARTED_PERMISSIONS } from '@bigid/permissions';
import { $state } from '../../services/angularServices';
import { CONFIG } from '../../../config/common';

export const GettingStartedRouter: React.FC = () => {
  const isSmallId = smallIdLicenseService.isSmallIDLicense();
  if (isSmallId) {
    return <GettingStartedWithSmallID />;
  }
  if (!isPermitted(GETTING_STARTED_PERMISSIONS.ACCESS.name)) {
    $state.go(CONFIG.states.FORBIDDEN);
    return null;
  }
  return <GettingStarted />;
};
