import React, { ChangeEvent, FC, useState } from 'react';
import {
  BigidBody1,
  BigidColorsV2,
  BigidPrimaryCheckbox,
  BigidRadioGroup,
  BigidTooltip,
  PrimaryButton,
} from '@bigid-ui/components';
import { openSystemDialog } from '../../../services/systemDialogService';
import { noop } from 'lodash';
import { DownloadReportType } from './DsarRequestsGrid';
import { BigidInfoIcon } from '@bigid-ui/icons';
import makeStyles from '@mui/styles/makeStyles';

interface DownloadReportContentProps {
  setReportType: (val: string) => void;
  setIsPiped: (val: boolean) => void;
  piiRecords: number;
}

const TOOL_TIP = (
  <div style={{ textAlign: 'center', width: '250px' }}>
    {'Selecting this option will parse values that are joint in a row divided by pipes into separate rows'}
  </div>
);

const downloadReportType = () => {
  let type: string = DownloadReportType.Full;
  let isPiped = false;
  return {
    getType: () => type,
    setType: (val: string) => (type = val),
    getIsPiped: () => isPiped,
    setIsPiped: (val: boolean) => (isPiped = val),
  };
};

const useStyles = makeStyles({
  wrapper: { display: 'flex', flexDirection: 'column', marginTop: 4, alignItems: 'left', gap: '15px' },
  radioWrapper: { display: 'flex', flexDirection: 'column' },
  checkboxWrapper: { display: 'flex', alignItems: 'flex-end', gap: '8px', marginLeft: '-7px' },
  infoWrapper: {
    display: 'flex',
    padding: '16px',
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: '15px',
    alignSelf: 'stretch',

    borderRadius: '8px',
    background: BigidColorsV2.gray[150],
  },
  additionalInfoWrapper: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    gap: '8px',
    padding: '12px 12px 0 12px',
    borderTop: `1px solid ${BigidColorsV2.gray[200]}`,
  },
  helpIcon: { marginLeft: 4, display: 'flex', alignItems: 'left' },
});

const DownloadReportContent: FC<DownloadReportContentProps> = ({ setReportType, setIsPiped, piiRecords }) => {
  const { wrapper, checkboxWrapper, infoWrapper, radioWrapper, additionalInfoWrapper } = useStyles({});
  const [checkParsePiped, setCheckParsePiped] = useState<boolean>(false);
  const [fullReport, setFullReport] = useState<boolean>(true);
  const hasPiiRecords = !!piiRecords;
  return (
    <div className={wrapper}>
      <div className={radioWrapper}>
        <BigidBody1>{'Choose a report type to download'}</BigidBody1>
        <BigidRadioGroup
          defaultValue={DownloadReportType.Full}
          horizontal
          onChange={(event: ChangeEvent<HTMLInputElement>) => {
            setReportType(event.target.value);
            const isFullReport = event.target.value === DownloadReportType.Full;
            setFullReport(isFullReport);
            setIsPiped(false);
            setCheckParsePiped(false);
          }}
          options={[
            {
              label: 'Full report',
              value: DownloadReportType.Full,
            },
            {
              label: 'Short report',
              value: DownloadReportType.Short,
            },
          ]}
        />
      </div>
      {fullReport && (
        <div className={infoWrapper}>
          <div className={checkboxWrapper}>
            <BigidPrimaryCheckbox
              label={'Parse piped values'}
              checked={checkParsePiped}
              onChange={(event: ChangeEvent<HTMLInputElement>, checked: boolean) => {
                setCheckParsePiped(checked);
                setIsPiped(checked);
              }}
            />
            <BigidTooltip placement="top" title={TOOL_TIP}>
              <span>
                <BigidInfoIcon />
              </span>
            </BigidTooltip>
          </div>
          {hasPiiRecords && (
            <div className={additionalInfoWrapper}>
              <BigidInfoIcon color="blue" />
              <BigidBody1>{`Contains additional ${piiRecords} records from BigID index`}</BigidBody1>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export function getDownloadReportDialog(
  requestId: string,
  handleDownloadDialog: (requestId: string, downloadReportType: string, parse: boolean) => void,
  piiRecords: number,
) {
  const { getType, setType, getIsPiped, setIsPiped } = downloadReportType();
  openSystemDialog<DownloadReportContentProps>({
    title: `Download Report`,
    onClose: noop,
    content: DownloadReportContent,
    contentProps: { setReportType: setType, setIsPiped: setIsPiped, piiRecords },
    borderTop: true,
    maxWidth: 'xs',
    withCloseButton: true,
    isLoading: false,
    buttons: [
      {
        component: PrimaryButton,
        onClick: () => handleDownloadDialog(requestId, getType(), getIsPiped()),
        text: 'Download',
      },
    ],
  });
}
