import React from 'react';
import { Footer } from './mappers/styles';
import type { BigidDialogButtonType as BigidButtonType } from '@bigid-ui/components';

type DataSourceConnectionModalButton = BigidButtonType & { hidden?: boolean, as?: React.ElementType<any> };

type DataSourceConnectionModalFooterProps = {
  buttons: DataSourceConnectionModalButton[];
};

export const DataSourceConnectionModalFooter = ({ buttons }: DataSourceConnectionModalFooterProps): JSX.Element => {
  return (
    <Footer.Content>
      {buttons.map(
        ({ component: Button, hidden: isHidden = false, as,  dataAid, ...rest }) =>
          !isHidden && (
            <Footer.Action as={as}>
              <Button bi={{ disabled: true }} key={dataAid} dataAid={dataAid} size="large" {...rest} />
            </Footer.Action>
          ),
      )}
    </Footer.Content>
  );
};
