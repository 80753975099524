import React from 'react';
import { BigidSystemDialogOptions, openSystemDialog } from './systemDialogService';
import { SecondaryButton, PrimaryButton, TertiaryButton, BigidBody1 } from '@bigid-ui/components';
import {
  SaveDialogContent,
  SaveDialogContentProps,
  SaveModalCancelButton,
} from '../components/SaveDialogContent/SaveDialogContent';

export enum SaveDialogResult {
  save = 'save',
  notSave = 'notSave',
  cancel = 'cancel',
}

export interface SaveDialogOptions {
  entityName: string;
  onClose: BigidSystemDialogOptions['onClose'];
  isError?: boolean;
}

export const showSaveDialog = ({ entityName, onClose, isError }: SaveDialogOptions): Promise<SaveDialogResult> => {
  return new Promise(resolve => {
    const options: BigidSystemDialogOptions<SaveDialogContentProps> = {
      title: 'Unsaved Changes',
      onClose,
      maxWidth: 'xs',
      buttons: [
        {
          text: `Cancel`,
          component: SaveModalCancelButton,
          isClose: true,
          onClick: () => {
            resolve(SaveDialogResult.cancel);
          },
          size: 'large',
        },
        {
          text: `Don't Save`,
          component: SecondaryButton,
          onClick: () => {
            resolve(SaveDialogResult.notSave);
          },
          isClose: true,
          size: 'large',
        },
        {
          text: 'Save',
          component: PrimaryButton,
          onClick: () => {
            resolve(SaveDialogResult.save);
          },
          isClose: true,
          size: 'large',
        },
      ],
      content: SaveDialogContent,
      contentProps: { entityName, isError },
      borderTop: true,
    };
    openSystemDialog<SaveDialogContentProps>(options);
  });
};

export const showSaveDialogV2 = ({ entityName, onClose, isError }: SaveDialogOptions): Promise<SaveDialogResult> => {
  return new Promise(resolve => {
    const options: BigidSystemDialogOptions<SaveDialogContentProps> = {
      title: 'Saved changes',
      onClose,
      maxWidth: 'xs',
      buttons: [
        {
          text: `Discard`,
          component: TertiaryButton,
          onClick: () => {
            resolve(SaveDialogResult.notSave);
          },
          isClose: true,
          size: 'medium',
        },
        {
          text: 'Save',
          component: PrimaryButton,
          onClick: () => {
            resolve(SaveDialogResult.save);
          },
          isClose: true,
          size: 'medium',
        },
      ],

      content: () => <BigidBody1>{`Do you want to save changes to ${entityName}?`}</BigidBody1>,
      contentProps: { entityName, isError },
      borderTop: true,
    };
    openSystemDialog<SaveDialogContentProps>(options);
  });
};
