import React, { FC, useCallback, useMemo } from 'react';
import { BigidFilter, objectToQueryString, ToolbarAction, ToolbarActionType } from '@bigid-ui/components';
import {
  BigidGridColumnTypes,
  BigidGridDataFetchResult,
  BigidGridProps,
  BigidGridRow,
  BigidGridWithToolbarProps,
  FetchDataFunction,
} from '@bigid-ui/grid';
import { BigidLayout, BigidLayoutConfig, LayoutContentType } from '@bigid-ui/layout';
import { orderBy } from 'lodash';
import {
  downloadHotspotsReport,
  getHotspotsCounters,
  Hotspot,
} from '../../../../HotspotsReport/HotspotsReportChart/HotspotsReportChartService';
import {
  getHotspotNameFilterAsString,
  getTagsFilterAsString,
  insertLineBreakEveryNCharacters,
  parseFieldFiltersToSearchQuery,
  wrapWithBracketsIfNotEmpty,
} from '../../../../HotspotsReport/HotspotsReportChart/utils';
import { BigidDownloadIcon, BigidHotspotIcon, BigidTableColumnsIcon } from '@bigid-ui/icons';
import { getTagsAllPairs } from '../../../../TagsManagement/TagsManagementService';
import { attachTagsBulk, createAndAttachTagsBulk } from '../../../../DataCatalog/DataCatalogService';
import { notificationService } from '../../../../../services/notificationService';
import { $state } from '../../../../../services/angularServices';
import { CONFIG } from '../../../../../../config/common';
import { $window } from 'ngimport';
import { HotspotsReportChartControls } from '../../../../HotspotsReport/HotspotsReportChart/HotspotsReportChartControls';
import { HotspotsContainer } from '../../../../HotspotsReport/HotspotsReportChart/HotspotsContainer';
import { getCustomRowDescription } from '../../../../HotspotsReport/HotspotsReportChart/HotspotsReportChart';
import { ACCESS_TYPES_STARTS_WITH, SENSITIVITY_CLASSIFICATION_STARTS_WITH } from './ACIDashboardHotspots';

const gridConfig: BigidGridProps<HotspotChartRow> = {
  showSortingControls: true,
  showSelectionColumn: true,
  columns: [
    {
      title: 'Hotspot Name',
      name: 'hotspotName',
      isListColumn: true,
      type: BigidGridColumnTypes.TEXT,
      getCellValue: ({ hotspotName }) => hotspotName,
    },
    {
      title: 'Sensitivity',
      name: 'sensitivity',
      type: BigidGridColumnTypes.TEXT,
      getCellValue: ({ sensitivity }) => sensitivity?.join(', ') || '',
    },
    {
      title: 'Access Type',
      name: 'accessType',
      type: BigidGridColumnTypes.TEXT,
      getCellValue: ({ accessTypes }) => accessTypes?.join(', ') || '',
    },
    {
      title: 'Total attributes and tags',
      name: 'totalAttrTag',
      type: BigidGridColumnTypes.NUMBER,
      getCellValue: ({ totalAttrTag }) => totalAttrTag,
      sortingEnabled: true,
    },
    {
      title: 'Total files in folder',
      name: 'totalFilesInFolder',
      type: BigidGridColumnTypes.NUMBER,
      getCellValue: ({ totalFilesInFolder }) => totalFilesInFolder,
    },
    {
      title: 'Total density',
      name: 'totalDensity',
      type: BigidGridColumnTypes.NUMBER,
      getCellValue: ({ totalDensity }) => totalDensity,
      sortingEnabled: true,
    },
  ],
};

export const hotspotsChartEntityFields = [
  {
    value: 'totalAttrTag',
    label: 'Attributes and tags',
  },
  {
    value: 'totalFilesInFolder',
    label: 'Files in folder ',
  },
  {
    value: 'totalDensity',
    label: 'Density',
  },
];

const REMEDIATION_TAG_NAME = 'hotspot_remediation';

export const getOpenInCatalogFilterString = (selectedRows: HotspotChartRow[]): string => {
  const hotspotNameFilterAsString = getHotspotNameFilterAsString(selectedRows.map(row => row.hotspotName));
  const tags = [...(selectedRows?.[0].selectedAccessType ?? []), ...(selectedRows?.[0].selectedSensitivity ?? [])];
  const tagsFilterAsString = getTagsFilterAsString(tags);

  const tagsAndFiltersFilterAsString = [tagsFilterAsString].filter(Boolean).join(' OR ');
  return [hotspotNameFilterAsString, tagsAndFiltersFilterAsString]
    .filter(Boolean)
    .map(text => wrapWithBracketsIfNotEmpty(text))
    .join(' AND ');
};

const getToolbarActions = (scanResultId: string): ToolbarAction[] => [
  {
    label: 'Actions',
    isGlobal: false,
    type: ToolbarActionType.DROPDOWN,
    dropdownProps: {
      placeholder: 'Actions',
      options: [
        {
          label: 'Deselect All',
          value: 'deselectAll',
          show: ({ selectedRowIds }) => selectedRowIds.length > 1,
          execute: async () => {
            return { shouldClearSelection: true };
          },
          isInline: true,
        },
        {
          label: 'Open in Catalog',
          value: 'openInCatalog',
          show: ({ selectedRowIds }) => selectedRowIds.length > 0,
          execute: async ({ selectedRows }) => {
            const filter = getOpenInCatalogFilterString(selectedRows);
            const url = $state.href(CONFIG.states.CATALOG, {
              filter,
            });
            $window.open(url, '_blank');
            return {};
          },
        },
        {
          label: 'Send to Remediation',
          value: 'sendToRemediation',
          show: ({ selectedRowIds }) => selectedRowIds.length > 0,
          execute: async ({ selectedRows }) => {
            try {
              const filter = getOpenInCatalogFilterString(selectedRows);
              const allTags = await getTagsAllPairs();
              const remediationTag = allTags.find(v => v.tagName === REMEDIATION_TAG_NAME);
              if (remediationTag) {
                await attachTagsBulk(allTags, [{ name: REMEDIATION_TAG_NAME, value: 'true' }], filter);
              } else {
                await createAndAttachTagsBulk(
                  allTags,
                  [{ name: REMEDIATION_TAG_NAME, value: 'true', isNew: true }],
                  filter,
                );
              }
              notificationService.success('Sent to remediation');
              return { shouldGridReload: true };
            } catch (err) {
              console.error(err);
              notificationService.error('Sending to remediation failed');
            }

            return {};
          },
        },
      ],
      clearOnSelect: true,
    },
    execute: async () => {
      return {};
    },
    disable: () => {
      return false;
    },
    show: ({ selectedRowIds }) => selectedRowIds.length > 0,
  },
  {
    label: 'Download report',
    tooltip: 'Download report',
    isGlobal: false,
    type: ToolbarActionType.ACTION_ICON,
    icon: BigidDownloadIcon,
    placement: 'end',
    execute: async () => {
      try {
        await downloadHotspotsReport(scanResultId);
        return {
          shouldGridReload: false,
        };
      } catch ({ message }) {
        notificationService.error('Report could not be downloaded ');
        console.error(`An error has occurred: ${message}`);
        return {
          shouldGridReload: false,
        };
      }

      return {
        shouldGridReload: false,
      };
    },
    disable: () => {
      return false;
    },
    show: ({}) => true,
  },
];

interface HotspotChartRow extends BigidGridRow {
  hotspotName: string;
  totalAttrTag: number;
  totalDensity: number;
  totalFilesInFolder: number;
  accessTypes: string[];
  sensitivity: string[];
  selectedSensitivity?: string[];
  selectedAccessType?: string[];
}
const hotspotToHotspotChartRow = (hotspot: Hotspot): HotspotChartRow => {
  const tags = hotspot.attrTagsList.filter(attrTag => attrTag.type === 'tags').map(tag => tag.name);
  return {
    ...hotspot,
    sensitivity: tags.filter(tag => tag.startsWith(SENSITIVITY_CLASSIFICATION_STARTS_WITH)),
    accessTypes: tags.filter(tag => tag.startsWith(ACCESS_TYPES_STARTS_WITH)),
  };
};

enum ACIDashboardHotspotsFilterFields {
  ACCESS_TYPE = 'accessType',
  SENSITIVITY = 'sensitivity',
}

export const ACIDashboardHotspotsChartWidget: FC<{
  filterToolbarConfig: BigidGridWithToolbarProps<HotspotChartRow>['filterToolbarConfig'];
  scanResultId: string;
  height: number;
}> = ({ filterToolbarConfig, scanResultId, height }) => {
  const { savedChartFieldSettings } = HotspotsContainer.useContainer();

  const fetchGridData: FetchDataFunction<HotspotChartRow> = useCallback(
    async ({ filter, sort }) => {
      const selectedSensitivity =
        (filter?.find(f => f.field === ACIDashboardHotspotsFilterFields.SENSITIVITY)?.value as string[]) ?? [];
      const selectedAccessType =
        (filter?.find(f => f.field === ACIDashboardHotspotsFilterFields.ACCESS_TYPE)?.value as string[]) ?? [];
      const selectedTags = [...selectedSensitivity, ...selectedAccessType];
      const filterUnderstoodByHotspotsBackend: BigidFilter = [
        {
          field: 'tags',
          operator: 'in',
          value: selectedTags,
        },
      ];

      const parsedFilter = parseFieldFiltersToSearchQuery(filterUnderstoodByHotspotsBackend, true, 'OR');

      const query = objectToQueryString({
        filter: parsedFilter,
        order: sort?.[0]?.field === 'totalDensity' ? 'density' : 'count',
        limit: 2000,
      });
      const hotspots: Hotspot[] = await getHotspotsCounters(scanResultId, query);

      const hotspotChartRows: HotspotChartRow[] = hotspots.map(hotspot => hotspotToHotspotChartRow(hotspot));

      const data: HotspotChartRow[] = hotspotChartRows.map(hotspotChartRow => ({
        ...hotspotChartRow,
        selectedSensitivity,
        selectedAccessType,
      }));

      const sortedOnTheFrontend = orderBy(data, sort?.[0]?.field, sort?.[0]?.order);
      const result: BigidGridDataFetchResult<HotspotChartRow> = {
        data: sortedOnTheFrontend,
        totalCount: data.length,
      };
      return result;
    },
    [scanResultId],
  );

  const layoutConfig: BigidLayoutConfig = useMemo(
    () => ({
      content: {
        entityName: 'hotspots',
        contentTypes: [LayoutContentType.GRID, LayoutContentType.HOTSPOTS_CHART],
        contentTypesExtended: [
          {
            id: 'hotspots',
            name: 'Hotspots',
            type: LayoutContentType.HOTSPOTS_CHART,
            icon: BigidHotspotIcon,
          },
          {
            id: 'grid',
            name: 'Grid',
            type: LayoutContentType.GRID,
            icon: BigidTableColumnsIcon,
          },
        ],
        defaultContentType: LayoutContentType.HOTSPOTS_CHART,
        defaultContentId: 'hotspots',
        defaultSorting: [{ field: 'totalAttrTag', order: 'desc' }],
        toolbarActions: getToolbarActions(scanResultId),
        customToolbarComponent: <HotspotsReportChartControls />,
        viewConfig: {
          fetchGridData,
          gridConfig,
          toolbarConfig: {
            hideColumnChooser: true,
            hideToolbar: false,
          },
          hotspotsChartConfig: {
            paddingInner: 4,
            showOnlyOneMenu: true,
            height,
            tooltipText: ({ row }) => {
              return (
                insertLineBreakEveryNCharacters(row['hotspotName'], 30) +
                `\n` +
                `Total attributes and tags: ${row['totalAttrTag']}\n` +
                `Total files in folder: ${row['totalFilesInFolder']}\n` +
                `Density: ${row['totalDensity']}`
              );
            },
            textTop: ({ row }) => row['hotspotName'],
            textBottom: ({ row, hotspotsChartBoxSizeField }) =>
              hotspotsChartEntityFields.find(field => field.value === hotspotsChartBoxSizeField).label +
              ': ' +
              row[hotspotsChartBoxSizeField],
            fields: hotspotsChartEntityFields,
            ...savedChartFieldSettings,
          },
          filterToolbarConfig,
        },
        getCustomRowDescription,
      },
    }),
    [fetchGridData, filterToolbarConfig, height, savedChartFieldSettings, scanResultId],
  );

  return <BigidLayout config={layoutConfig} />;
};
