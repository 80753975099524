import React from 'react';
import { BigidFormRenderProps, BigidHeading5 } from '@bigid-ui/components';
import { CreateUserFormName, CreateUserNameContainer, CreateUserFormWrapper, CreateUserFormSection } from './styles';
import { useLocalTranslation } from '../translations';

export const renderCreateUserForm =
  (shouldRenderPasswordFields: boolean) =>
  ({ renderField }: BigidFormRenderProps) => {
    const { t } = useLocalTranslation('dialogs.createUser');

    return (
      <CreateUserFormWrapper>
        <CreateUserFormSection>
          <CreateUserNameContainer>
            <CreateUserFormName>{renderField('firstName')}</CreateUserFormName>
            <CreateUserFormName>{renderField('lastName')}</CreateUserFormName>
          </CreateUserNameContainer>
          {renderField('name')}
          {renderField('origin')}
        </CreateUserFormSection>
        {shouldRenderPasswordFields && (
          <CreateUserFormSection>
            <BigidHeading5>{t('passwordLabel')}</BigidHeading5>
            {renderField('password')}
            {renderField('passwordRepeat')}
          </CreateUserFormSection>
        )}
      </CreateUserFormWrapper>
    );
  };
