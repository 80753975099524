import React, { FC, useMemo, useRef, useState } from 'react';
import { BigidBody1, BigidDropdownOption, BigidMenu, BigidMenuItem, TertiaryButton } from '@bigid-ui/components';
import { BigidChevronDownIcon } from '@bigid-ui/icons';
import styled from '@emotion/styled';
import { generateDataAid } from '@bigid-ui/utils';

const Main = styled('div')`
  width: 100%;
  border: ${({ theme }) => `1px solid ${theme.vars.palette.bigid.gray200}`};
  border-radius: 4px;
`;

const MainContainer = styled('div')`
  width: 156px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 8px;
`;

const MenuItemWrapper = styled('div')<{
  isCentered?: boolean;
}>`
  width: 156px;
  display: grid;
  align-items: center;
  justify-content: ${({ isCentered }) => (isCentered ? 'center' : 'start')};
  cursor: pointer;
`;

interface CaseStatusWidgetProps {
  selectionOptions: BigidDropdownOption[];
  currentValue: BigidDropdownOption[];
  onChange: (option: BigidDropdownOption[]) => void;
}

export const CaseStatusWidget: FC<CaseStatusWidgetProps> = ({ selectionOptions, currentValue, onChange }) => {
  const [open, setOpen] = useState(false);
  const menuSectionRef = useRef();
  const handleClick = () => setOpen(!open);
  const handleClose = () => {
    setOpen(false);
  };

  const handleOnChage = (option: any) => {
    setOpen(false);
    onChange([option]);
  };

  const menuProps = {
    open,
    onMenuClose: handleClose,
    anchorEl: menuSectionRef.current,
  };

  const MenuItems = useMemo(() => {
    return (
      <BigidMenu {...menuProps} width={156}>
        {selectionOptions?.map(option => {
          return (
            <BigidMenuItem
              key={option.value}
              onClick={() => handleOnChage(option)}
              dataAid={generateDataAid('MetaDataWidget', ['actions', option.value])}
            >
              <MenuItemWrapper>
                <BigidBody1>{option?.displayValue}</BigidBody1>
              </MenuItemWrapper>
            </BigidMenuItem>
          );
        })}
      </BigidMenu>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectionOptions, menuProps]);

  const NoActionsItem = useMemo(() => {
    return (
      <BigidMenu {...menuProps} width={156}>
        <BigidMenuItem key="NoOptions">
          <MenuItemWrapper isCentered={true}>
            <BigidBody1>No options</BigidBody1>
          </MenuItemWrapper>
        </BigidMenuItem>
      </BigidMenu>
    );
  }, [menuProps]);

  return (
    <>
      <Main ref={menuSectionRef}>
        <TertiaryButton
          onClick={handleClick}
          size="medium"
          dataAid={generateDataAid('MetaDataWidget', ['actions'])}
          width="fill"
        >
          <MainContainer>
            <BigidBody1>{currentValue[0]?.value}</BigidBody1>
            <BigidChevronDownIcon />
          </MainContainer>
        </TertiaryButton>
      </Main>
      {selectionOptions?.length > 0 ? MenuItems : NoActionsItem}
    </>
  );
};
