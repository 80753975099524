import { azureActions, azureDataActions, AzurePolicyType, AzurePolicy, AzurePolicyProperties } from './azureRoles';
import { uniq } from 'lodash';

export const generatePolicyActions = (dsTypes: string[]): string[] => {
  const actions: string[] = [];
  if (dsTypes.includes(AzurePolicyType.ALL)) {
    actions.push(...Object.values(azureActions).flat());
  } else {
    dsTypes.forEach(type => {
      if (azureActions[type]) {
        actions.push(...azureActions[type]);
      }
    });
  }

  return uniq(actions);
};

export const generatePolicyDataActions = (dsTypes: string[]): string[] => {
  const dataActions: string[] = [];
  if (dsTypes.includes(AzurePolicyType.ALL)) {
    for (const key in azureDataActions) {
      dataActions.push(...azureDataActions[key]);
    }
  } else {
    dsTypes.forEach(type => {
      if (azureDataActions[type]) {
        dataActions.push(...azureDataActions[type]);
      }
    });
  }

  return uniq(dataActions);
};

export const getAzurePolicyJson = (dsTypes: string[], subscriptionId: string): string => {
  const properties: AzurePolicyProperties = {
    roleName: 'bigid-autodiscovery-app',
    description: '',
    assignableScopes: [`/subscriptions/${subscriptionId}`],
    permissions: [
      {
        actions: generatePolicyActions(dsTypes),
        notActions: [],
        dataActions: generatePolicyDataActions(dsTypes),
        notDataActions: [],
      },
    ],
  };
  const azurePolicy: AzurePolicy = { properties };

  return JSON.stringify(azurePolicy, null, 2);
};
