import React, { FC, memo } from 'react';
import {
  BigidFormFieldSideText,
  compareObjectsExceptFunctions,
  BigidTextFieldRenderProps,
  useFormattedJsonValue,
  PASSWORD_MASK_VIEW_VALUE,
} from '@bigid-ui/components';

export const FormJsonField: FC<BigidTextFieldRenderProps> = memo(({ touched, value, misc, ...restProps }) => {
  const { isSensitive } = misc;
  const { formattedValue, error, handleOnBlur, isMasked } = useFormattedJsonValue(value, touched, isSensitive);
  const valuePostFormat = isMasked ? PASSWORD_MASK_VIEW_VALUE : formattedValue;

  return (
    <BigidFormFieldSideText
      {...restProps}
      misc={misc}
      error={error}
      errorIsShown={!!error}
      onBlur={handleOnBlur}
      touched={touched}
      value={valuePostFormat}
    />
  );
}, compareObjectsExceptFunctions);

FormJsonField.displayName = 'FormJsonField';
