import { HEADER_IMAGE_LINK_FIELD_NAME, SUBJECT_FIELD_NAME } from './useEmailContentFields';
import { isFieldType, groupBySection, validateAllTouched, isValidUrlTemplateField, isValidEmailSubject } from './utils';
import { EmailEditorFieldType } from './types';
import { getFixedT } from './translations';
import type { EmailTemplateData } from './emailEditorService';
import type { BigidFormStateAndHandlers, BigidFormValues } from '@bigid-ui/components';
import type { ValidationRule } from '../../utilities/validation';
import type { MutableRefObject } from 'react';

export type ValidationInfo = { name: string; message: string; section: string }[];

export const DEFAULT_SECTION = 'section0';

const t = getFixedT('errors');

export const getValidationRules = (
  form: MutableRefObject<BigidFormStateAndHandlers>,
  template: EmailTemplateData,
): ValidationRule<BigidFormValues, ValidationInfo>[] => [
  {
    id: 'NO_INVALID_SUBJECT',
    message: t('invalidSubject'),
    validate: (values, info) => {
      const isValid = isValidEmailSubject(values[SUBJECT_FIELD_NAME]);

      !isValid &&
        info.push({
          name: SUBJECT_FIELD_NAME,
          message: t('invalidSubject'),
          section: DEFAULT_SECTION,
        });

      return isValid;
    },
  },
  {
    id: 'NO_INCORRECT_IMAGE_URL',
    message: t('incorrectUrl'),
    validate: (values, info) => {
      const isValid = isValidUrlTemplateField(values[HEADER_IMAGE_LINK_FIELD_NAME], template);

      !isValid &&
        info.push({
          name: HEADER_IMAGE_LINK_FIELD_NAME,
          message: t('incorrectUrl'),
          section: DEFAULT_SECTION,
        });

      return isValid;
    },
  },
  {
    id: 'NO_UNFILLED_LINKS',
    message: t('fieldEmpty'),
    validate: (values, info) => {
      const isLinkType = (field: string) =>
        isFieldType(field, [EmailEditorFieldType.LINK_URL, EmailEditorFieldType.LINK_TEXT], form);
      const linksBySection = groupBySection(values, isLinkType, form);

      // @info workaround for adding additional info to validation
      Object.entries(linksBySection).forEach(([section, fields]) => {
        const isValid = validateAllTouched(fields.reduce((acc, field) => ({ ...acc, [field]: values[field] }), {}));
        const empty = fields.filter(field => !values[field]);

        !isValid && empty.forEach(field => info.push({ name: field, section, message: t('fieldEmpty') }));
      });

      const isValid = !Object.values(linksBySection).some(fields => {
        const isValid = validateAllTouched(fields.reduce((acc, field) => ({ ...acc, [field]: values[field] }), {}));
        return !isValid;
      });

      return isValid;
    },
  },
  {
    id: 'NO_UNFILLED_BUTTONS',
    message: t('fieldEmpty'),
    validate: (values, info) => {
      const isButtonType = (field: string) =>
        isFieldType(field, [EmailEditorFieldType.BUTTON_URL, EmailEditorFieldType.BUTTON_TEXT], form);
      const buttonsBySection = groupBySection(values, isButtonType, form);

      // @info workaround for adding additional info to validation
      Object.entries(buttonsBySection).forEach(([section, fields]) => {
        const isValid = validateAllTouched(fields.reduce((acc, field) => ({ ...acc, [field]: values[field] }), {}));
        const empty = fields.filter(field => !values[field]);

        !isValid && empty.forEach(field => info.push({ name: field, section, message: t('fieldEmpty') }));
      });

      const isValid = !Object.values(buttonsBySection).some(fields => {
        const isValid = validateAllTouched(fields.reduce((acc, field) => ({ ...acc, [field]: values[field] }), {}));
        return !isValid;
      });

      return isValid;
    },
  },
  {
    id: 'NO_INCORRECT_URLS',
    message: t('incorrectUrl'),
    validate: (values, info) => {
      const isUrlType = (field: string) =>
        isFieldType(field, [EmailEditorFieldType.BUTTON_URL, EmailEditorFieldType.LINK_URL], form);
      const urls = Object.keys(values).filter(field => isUrlType(field));

      // @info workaround for adding additional info to validation
      urls.forEach(fieldName => {
        const isValid = isValidUrlTemplateField(values[fieldName], template);

        !isValid && info.push({ name: fieldName, section: DEFAULT_SECTION, message: t('incorrectUrl') });
      });

      const isValid = !urls.some(fieldName => !isValidUrlTemplateField(values[fieldName], template));

      return isValid;
    },
  },
];
