import {
  AccordionSummarySizeEnum,
  BigidAccordion,
  BigidAccordionDetails,
  BigidAccordionSummary,
  BigidBody2,
  BigidColorsV2,
  BigidFormField,
} from '@bigid-ui/components';
import React, { FC } from 'react';
import { generateDataAid } from '@bigid-ui/utils';
import styled from '@emotion/styled';

export interface GroupedSettingsFields {
  title: string;
  fields: BigidFormField[];
  renderFunction: (fieldName: string, additionalProps?: Record<string, any>) => any;
  shouldShowError?: boolean;
}

const ErrorBorderWrapper = styled('div')<{ isError: boolean }>`
  ${props => props.isError && `border: 1px solid ${BigidColorsV2.red[600]}; border-radius: 6px;`}
`;

const GroupedFields = styled('div')`
  display: flex;
  flex-direction: column;
`;

export const GroupedEtlSettingsFields: FC<GroupedSettingsFields> = ({
  title,
  fields,
  renderFunction,
  shouldShowError,
}) => {
  return (
    <ErrorBorderWrapper isError={shouldShowError}>
      <BigidAccordion dataAid={generateDataAid('BigidAccordion', [title])}>
        <BigidAccordionSummary size={AccordionSummarySizeEnum.large}>
          <BigidBody2>{title}</BigidBody2>
        </BigidAccordionSummary>
        <BigidAccordionDetails>
          <GroupedFields>{fields.map(({ name }) => renderFunction(name))}</GroupedFields>
        </BigidAccordionDetails>
      </BigidAccordion>
    </ErrorBorderWrapper>
  );
};
