import { module } from 'angular';
const app = module('app');

app.factory('identityLineageService', [
  '$http',
  'appSettings',
  function ($http, appSettings) {
    return {
      getLineageAttributes() {
        return $http
          .get(`${appSettings.serverPath}/${appSettings.version}/lineage/attributes`)
          .then(response => response.data);
      },
      getLineageAttributeCollections(attributeName) {
        const query = attributeName ? `?filter=attribute="${attributeName}"` : '';
        return $http
          .get(`${appSettings.serverPath}/${appSettings.version}/lineage/collections${query}`)
          .then(({ data }) => data);
      },
      saveAttributesData(attributes) {
        return $http
          .post(`${appSettings.serverPath}/${appSettings.version}/attributes`, attributes)
          .then(({ data }) => data);
      },
      getLineageTree(anchorCollections, anchorAttributeType) {
        let queryString = '';

        anchorCollections.forEach(value => {
          queryString += `anchorCollections=${value}&`;
        });
        queryString += 'anchorAttributeType=' + anchorAttributeType;

        return $http
          .get(`${appSettings.serverPath}/${appSettings.version}/lineage/tree/?${queryString}`)
          .then(response => response.data);
      },
      createIdConnection(data) {
        return $http
          .post(`${appSettings.serverPath}/${appSettings.version}/createIdConnectionFromTables`, data)
          .then(response => response.data);
      },
      getCategories() {
        return $http
          .get(`${appSettings.serverPath}/${appSettings.version}/data_categories`)
          .then(response => response.data);
      },
      getAttributes() {
        return $http.get(`${appSettings.serverPath}/${appSettings.version}/attributes`).then(response => response.data);
      },
      saveCategory(data) {
        return $http
          .post(`${appSettings.serverPath}/${appSettings.version}/data_categories`, data)
          .then(response => response.data);
      },
      deleteCategory(id) {
        return $http
          .delete(`${appSettings.serverPath}/${appSettings.version}/data_categories/` + id)
          .then(response => response.data);
      },
      scansStatus() {
        return $http
          .get(`${appSettings.serverPath}/${appSettings.version}/scans/status`)
          .then(response => response.data);
      },
    };
  },
]);
