import React, { useEffect, useState } from 'react';
import { useLocalTranslation } from '../../translations';
import { CollapsibleTextareaField } from '../../../../../components/CollapsibleTextareaField/CollapsibleTextareaField';
import { DataSourcesUITrackingEvent, trackEventDataSources } from '../../../DataSourcesEventTrackerUtils';
import type { BigidFieldRenderProps } from '@bigid-ui/components';

export const DataSourceConnectionCollaborationTextareaField = ({
  dataAid = 'DataSourceConnectionCollaborationTextareaField',
  value,
  setValue,
  label,
  ...props
}: BigidFieldRenderProps<unknown, string> & { dataAid?: string }): JSX.Element => {
  const { t } = useLocalTranslation();
  const [isExpanded, setExpanded] = useState(false);

  const handleExpandClick = () => {
    trackEventDataSources(DataSourcesUITrackingEvent.DS_COLLABORATION_CUSTOMIZE_NOTE_CLICK);
    setExpanded(true);
  };

  useEffect(() => {
    !value && setValue(t('collaboration.emailContent', { source: label ?? t('collaboration.defaultDataSource') }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <CollapsibleTextareaField
      dataAid={dataAid}
      setValue={setValue}
      value={value}
      message={t(isExpanded ? 'collaboration.note' : 'collaboration.message')}
      link={isExpanded ? null : t('collaboration.link')}
      onExpand={handleExpandClick}
      {...props}
    />
  );
};
