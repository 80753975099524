import { DsTypesEnum } from '../../DataSourceConfiguration/types';
import { DataSourceCustomParametersField } from '../DataSourceConnectionCustomParametersField';
import type { BigidFieldRenderProps } from '@bigid-ui/components';
import type { FC } from 'react';

const dataSourceTypeSortOverrideMapper: Record<string, string[]> = {};

const dataSourceFieldRenderOverridesByType: Partial<Record<DsTypesEnum, FC<BigidFieldRenderProps>>> = {
  [DsTypesEnum.customFieldsArray]: DataSourceCustomParametersField,
};

export const getDataSourceTypeSortOverrides = (type: string) => dataSourceTypeSortOverrideMapper[type];

export const getDataSourceFieldRenderOverrides = () => ({
  type: dataSourceFieldRenderOverridesByType,
});
