import { CONFIG } from '../../config/common';
import { licenseService, LICENSE_TYPES } from './licenseService';
import { isPermitted } from './userPermissionsService';
import { DASHBOARD_PERMISSIONS } from '@bigid/permissions';
import { failedObjectReportFileName } from './sidebarService';
import { getApplicationPreference } from './appPreferencesService';

const SMALL_ID_ENABLED_ACCESS = [
  CONFIG.states.CATALOG,
  CONFIG.states.CLASSIFICATION,
  CONFIG.states.CLUSTERING_OBJECTS_PREVIEW,
  CONFIG.states.CLUSTER_PREVIEW,
  CONFIG.states.DUPLICATE_OBJECTS_PREVIEW,
  CONFIG.states.POLICIES,
  CONFIG.states.TASKS_LIST,
  CONFIG.states.DATA_SOURCE_CONNECTIONS,
  CONFIG.states.EDIT_DATA_SOURCE_CONNECTION,
  CONFIG.states.NEW_DATA_SOURCE_CONNECTION,
  CONFIG.states.CLASSIFIERS,
  CONFIG.states.SAVED_QUERIES,
  CONFIG.states.IDENTITY_ACCESS_MANAGEMENT,
  CONFIG.states.AUDIT,
  CONFIG.states.GENERAL_SETTINGS,
  CONFIG.states.SCANS,
  CONFIG.states.CREATE_SCAN,
  CONFIG.states.SCANS_SCAN_TEMPLATES,
  CONFIG.states.SCANS_PLANNED_SCANS,
  CONFIG.states.SCAN_TEMPLATE,
  CONFIG.states.EDIT_SCAN_PROFILE,
  CONFIG.states.CREATE_SCAN_PROFILE,
  CONFIG.states.ADVANCED_TOOLS,
  CONFIG.states.UPDATE_PASSWORD,
  CONFIG.states.LOGIN,
  CONFIG.states.FORBIDDEN,
  CONFIG.states.DATA_SOURCE_INIT,
  CONFIG.states.ACCESS_GOVERNANCE,
  CONFIG.states.DIGEST_REPORT,
  CONFIG.states.SCAN_RESULT,
  CONFIG.states.SCAN_ANALYSIS,
  CONFIG.states.DASHBOARD,
  CONFIG.states.PRIVACY_PORTAL,
  CONFIG.states.CONFIG_DATA_SOURCE,
  CONFIG.states.CONFIG_DATA_SOURCE_NEW,
  CONFIG.states.DATA_SOURCE_CONNECTIONS_INIT,
  CONFIG.states.FAILED_OBJECT_REPORT,
  CONFIG.states.FMSD,
  CONFIG.states.SCANS_NEW,
  CONFIG.states.SCANS_NEW_SCANS,
  CONFIG.states.SCANS_NEW_SCANS_IN_PROGRESS,
  CONFIG.states.SCANS_NEW_SCANS_COMPLETED,
  CONFIG.states.SCANS_SCAN_INSIGHTS,
  CONFIG.states.SCANS_SAVED_PROFILES,
  CONFIG.states.SCANS_CONTROLLER,
  CONFIG.states.SENSITIVITY_CLASSIFICATION,
  CONFIG.states.SENSITIVITY_CLASSIFICATION_NEW,
  CONFIG.states.SENSITIVITY_CLASSIFICATION_EDIT,
  CONFIG.states.SMALLID_FOR_DEVELOPERS,
  CONFIG.states.DATA_SOURCE_ROUTER,
  CONFIG.states.EXECUTIVE_REPORT,
  CONFIG.states.ACTIONABLE_INSIGHTS,
  CONFIG.states.ACTIONABLE_INSIGHTS_CASE,
  CONFIG.states.GETTING_STARTED,
  CONFIG.states.RISK_ASSESSMENT,
  CONFIG.states.CATALOG_DISCOVERY,
  CONFIG.states.HOTSPOTS_REPORT,
];

const SMALL_ID_ENABLED_REPORT_FILE_NAMES = [failedObjectReportFileName];

const SMALLID_FALLBACK_LANDING_PAGE = CONFIG.states.TASKS_LIST;

const isAccessGranted = (reference: string): boolean =>
  isSmallIDLicense() ? SMALL_ID_ENABLED_ACCESS.includes(reference) : true;

const isSmallIDLicense = () => licenseService.getLicenseType() === LICENSE_TYPES.SMALLID;

const isSmallIDLimitedLicense = () => {
  const {
    dataSources: { total },
  } = licenseService.getLicense();
  return isSmallIDLicense() && total !== -1; // BCO-770 - non -1 amount of datasources for SmallID license holds the old DSs restrictions accross the app.
};

const getSmallIDLandingPage = () => {
  return isPermitted(DASHBOARD_PERMISSIONS.ACCESS.name) ? CONFIG.states.DASHBOARD : SMALLID_FALLBACK_LANDING_PAGE;
};

const goToUpgradeIfSmallID = () => {
  if (isSmallIDLicense()) {
    licenseService.openLicenseUpgradeFlow();
    return true;
  }
  return false;
};

const goToUpgradeIfLimitedSmallID = () => {
  if (isSmallIDLimitedLicense()) {
    licenseService.openLicenseUpgradeFlow();
    return true;
  }
  return false;
};

const isSmallIDEnabledReport = (fileName: string) => {
  return SMALL_ID_ENABLED_REPORT_FILE_NAMES.includes(fileName);
};

const isSmallIDSmergerMode = (): boolean => isSmallIDLicense() && getApplicationPreference('ENABLE_CLOUD_MERGER');

export const smallIdLicenseService = {
  isAccessGranted,
  isSmallIDLicense,
  isSmallIDLimitedLicense,
  isSmallIDEnabledReport,
  isSmallIDSmergerMode,
  goToUpgradeIfSmallID,
  goToUpgradeIfLimitedSmallID,
  getSmallIDLandingPage,
};
