import { useCallback, useEffect, useRef, useState } from 'react';
import { httpService } from '../../../../services/httpService';
import { normalizeInitValueByType, resolveDefaultValue, UpdateDataSourceConfigState } from './useDataSourceConfigState';
import { DataSourceConnectionFormField } from '../types';
import { DataSourceTestConnectionStatusEnum } from './useTestConnection';
import { dateUtils } from '../../../../services/angularServices';
import { fieldConditionCheck } from '../utils/conditionUtils';

export interface useGetDataSourceByNameProps {
  updateState: UpdateDataSourceConfigState;
  name: string;
  fields: DataSourceConnectionFormField[];
}

export type RefreshDataSourceDataType = () => Promise<Record<string, any> | false>;

const DEFAULT_GET_DS_ERROR = 'No access to Data Source Connection or does not exist';
const ENABLE_ACL = 'enableACL';

export const useGetDataSourceByName = ({ updateState, name, fields }: useGetDataSourceByNameProps) => {
  const [connectionData, setConnectionData] = useState<Record<string, any>>();
  const getData = useCallback<RefreshDataSourceDataType>(async () => {
    try {
      if (name) {
        updateState({
          isLoadingData: true,
        });
        const [{ data }, tablesResultResponse] = await Promise.all([
          httpService.fetch(`ds_connections/${name}`, {
            shouldKeepDisabledCustomFields: 'true',
            withoutCredentialValue: 'true',
          }),
          httpService.fetch(`ds-connections/${name}/connection-results`),
        ]);

        if (data?.ds_connection) {
          const dsConnection = {
            ...data?.ds_connection,
            connectionStatusTest: {
              ...data?.ds_connection?.connectionStatusTest,
              tablesResult: tablesResultResponse?.data?.data?.tablesResult,
            },
          };
          setConnectionData(dsConnection);
          updateState({
            type: dsConnection.type,
            notFoundMessage: '',
          });
          return dsConnection;
        } else {
          updateState({
            isLoading: false,
            isLoadingData: false,
            notFoundMessage: DEFAULT_GET_DS_ERROR,
          });
          return false;
        }
      } else {
        updateState({
          isLoadingData: false,
        });
        return false;
      }
    } catch (e) {
      updateState({
        isLoading: false,
        isLoadingData: false,
        notFoundMessage: e?.message?.includes('403') ? DEFAULT_GET_DS_ERROR : 'Get DataSource Error',
      });
      console.error(e);
      return false;
    }
  }, [name, updateState]);
  const getDataRef = useRef(getData);
  getDataRef.current = getData;

  useEffect(() => {
    getData();
  }, [getData]);

  useEffect(() => {
    if (fields?.length && connectionData) {
      updateState(({ tablesResult }) => {
        const initialValuesNew = fields.reduce(
          (initValuesAcc, { name, options, misc }: DataSourceConnectionFormField) => {
            const { apiName, apiNames, type, defaultValue, filterArrayByFields, objectFields, hidden, visibleIf } =
              misc;
            const isVisible =
              misc?.ignoreVisibleInCheck ||
              fieldConditionCheck(!hidden, visibleIf, (propName: string) => {
                const {
                  misc: { apiName, type, defaultValue },
                } = fields.find(({ name }) => name === propName) || {};
                let value = connectionData[apiName];
                if (value == undefined) value = defaultValue;
                value = normalizeInitValueByType(type, value, filterArrayByFields, options);
                return { value, type };
              });
            return name !== ENABLE_ACL
              ? {
                  ...initValuesAcc,
                  [name]: normalizeInitValueByType(
                    type,
                    connectionData[apiName] !== undefined && isVisible
                      ? connectionData[apiName]
                      : resolveDefaultValue(defaultValue, objectFields),
                    filterArrayByFields,
                    options,
                  ),
                }
              : {
                  ...initValuesAcc,
                  [name]: normalizeInitValueByType(
                    type,
                    connectionData[apiNames[0]] == undefined ? true : connectionData[apiNames[0]],
                    filterArrayByFields,
                    options,
                  ),
                };
          },
          {},
        );
        const { is_success: isScanSuccess, last_connection: scanCompleteDate } =
          connectionData?.connectionStatusScan || {};
        const wasScanExecuted = typeof (connectionData?.connectionStatusScan || {}).is_success === 'boolean';
        const {
          is_success: isTestSuccess,
          last_connection: lastTestDate,
          tablesResult: tablesResultNew,
        } = connectionData?.connectionStatusTest || {};

        return {
          id: name,
          isLoadingData: false,
          initialValues: initialValuesNew,
          testStatus:
            isTestSuccess === true
              ? DataSourceTestConnectionStatusEnum.success
              : (isTestSuccess === false && DataSourceTestConnectionStatusEnum.failed) ||
                DataSourceTestConnectionStatusEnum.notStarted,
          lastTestDate: lastTestDate && dateUtils.formatDate(lastTestDate),
          isScanSuccess,
          scanCompleteDate: scanCompleteDate && dateUtils.formatDate(scanCompleteDate),
          tablesResult: tablesResultNew || tablesResult,
          wasScanExecuted,
          isCredentialDeleted: connectionData?.is_credential_deleted,
        };
      });
    }
  }, [fields, connectionData, updateState, name]);

  return useCallback(() => getDataRef.current(), []);
};
