import { BigidGraphNode } from '@bigid-ui/visualisation';

export interface LineageNode extends BigidGraphNode {
  fqn: string;
  type: string;
  origin: boolean;
  column: number;
  dataSource: string;
}

export interface ParsedFqn {
  source: string;
  container: string;
  object: string;
}

export function parseFqn(fqn: string, type: string): ParsedFqn {
  const source = fqn.substring(0, fqn.indexOf('.'));
  const delimiter = type === 'file' ? '/' : '.';
  const [container, ...objectParts] = fqn.substring(source.length + 1).split(delimiter);
  const object = objectParts.join(delimiter);
  return { source, container, object };
}
