import React, { FC, useMemo } from 'react';
import { BigidGridColumnTypes, BigidGridRow, BigidGridWithToolbar, BigidGridWithToolbarProps } from '@bigid-ui/grid';
import { notificationService } from '../../../services/notificationService';
import { queryService } from '../../../services/queryService';
import { BigidIconSize, BigidPaper } from '@bigid-ui/components';
import makeStyles from '@mui/styles/makeStyles';
import { ACActionType, ACEventType, ACStatusType } from '../ActionWorkflow/actionWorkflowTypes';
import { $state, dateUtils } from '../../../services/angularServices';
import { ActionCenterMetadataContainer } from '../useActionCenterMetadata';
import {
  BigidAuditIllustration,
  BigidClearFilledIcon,
  BigidScheduleIcon,
  BigidApplyFilledIcon,
  BigidWarningFilledIcon,
} from '@bigid-ui/icons';
import { CONFIG } from '../../../../config/common';
import { startCase } from 'lodash';
import { getActionCenterAuditData } from './actionCenterAuditService';
import { BigidLayoutEmptyState } from '@bigid-ui/layout';

export interface AuditGridRow extends BigidGridRow {
  parentId: string;
  workflowId: string;
  workflowName: string;
  type: ACActionType;
  eventType: ACEventType;
  entityId: string;
  status: ACStatusType;
  error?: Error;
  created_at: string;
  updated_at: string;
}

const useStyles = makeStyles({
  gridWrapper: {
    width: '100%',
    display: 'flex',
    position: 'relative',
    height: '97%',
    maxHeight: '97%',
    overflow: 'hidden',
  },
  noData: {
    width: '100%',
    height: '100%',
    position: 'absolute',
  },
  eventStatus: {
    display: 'flex',
    alignItems: 'center',
    gap: '5px',
  },
});

const getActionCenterEventStatus = (acStatus: ACStatusType) => {
  let Icon;

  switch (acStatus) {
    case ACStatusType.COMPLETED:
      Icon = <BigidApplyFilledIcon color="positive" />;
      break;
    case ACStatusType.FAILED:
      Icon = <BigidClearFilledIcon color="negative" />;
      break;
    default:
      Icon = <BigidWarningFilledIcon color="warning" />;
      break;
  }
  return Icon;
};

export const ActionCenterAudit: FC = () => {
  const { actionCenterMetadata } = ActionCenterMetadataContainer.useContainer();
  const classes = useStyles();

  const config: BigidGridWithToolbarProps<AuditGridRow> = useMemo(
    () => ({
      pageSize: 100,
      showSortingControls: true,
      defaultSorting: [{ field: 'updated_at', order: 'desc' }],
      noDataContent: (
        <div className={classes.noData}>
          <BigidLayoutEmptyState
            illustration={BigidAuditIllustration}
            description="There are no items to show in the History"
          />
        </div>
      ),
      filterToolbarConfig: {
        filters: [
          {
            title: 'Status',
            field: 'status',
            operator: 'in',
            options: Object.values(ACStatusType).map(type => ({
              label: startCase(type),
              value: type,
              isSelected: false,
            })),
            disabled: true,
            value: [],
          },
          {
            title: 'Execution Type',
            field: 'type',
            operator: 'in',
            options: Object.values(ACActionType).map(type => ({
              label: startCase(type),
              value: type,
              isSelected: false,
            })),
            disabled: true,
            value: [],
          },
        ],
        searchConfig: {
          searchFilterKeys: ['_id'],
          initialValue: '',
          placeholder: 'Execution ID',
          operator: 'equal',
        },
      },
      fetchData: async queryComponents => {
        try {
          const gridConfigQuery = queryService.getGridConfigQuery(queryComponents);
          const { audit, totalCount } = await getActionCenterAuditData(gridConfigQuery);

          return {
            totalCount,
            data: audit,
          };
        } catch ({ message }) {
          notificationService.error('An error has occurred');
          console.error(`An error has occurred: ${message}`);
          return {
            totalCount: 0,
            data: [],
          };
        }
      },
      columns: [
        {
          name: '_id',
          title: 'Execution ID',
          getCellValue: row => row._id,
          type: BigidGridColumnTypes.TEXT,
        },
        {
          name: 'workflowName',
          title: 'Action Name',
          sortingEnabled: false,
          formatter: {
            onClick: async ({ row }) => {
              if (row.workflowName) {
                $state.go(CONFIG.states.ACTION_CENTER_ACTIONS, { workflowId: row.workflowId });
              }

              return {};
            },
          },
          getCellValue: ({ workflowName = null }) => ({
            link: {
              text: workflowName || '<Deleted>',
              disabled: workflowName === null,
            },
          }),
          type: BigidGridColumnTypes.LINK,
        },
        {
          name: 'type',
          title: 'Execution Type',
          getCellValue: row => actionCenterMetadata.actions.find(({ name }) => name === row.type).displayName,
          type: BigidGridColumnTypes.TEXT,
        },
        {
          name: 'eventType',
          title: 'Event Type',
          sortingEnabled: false,
          getCellValue: row =>
            actionCenterMetadata.events.find(({ supportedEvents }) => supportedEvents.includes(row.eventType))
              .displayName,
          type: BigidGridColumnTypes.TEXT,
        },
        {
          name: 'created_at',
          title: 'Created At',
          getCellValue: row => ({
            icon: {
              icon: BigidScheduleIcon,
              label: dateUtils.formatDate(row.created_at),
              size: BigidIconSize.MEDIUM,
            },
          }),
          type: BigidGridColumnTypes.ICON,
        },
        {
          name: 'updated_at',
          title: 'Updated At',
          getCellValue: row => ({
            icon: {
              icon: BigidScheduleIcon,
              label: dateUtils.formatDate(row.updated_at),
              size: BigidIconSize.MEDIUM,
            },
          }),
          type: BigidGridColumnTypes.ICON,
        },
        {
          name: 'status',
          title: 'Status',
          getCellValue: row => {
            const status = row.status;
            return (
              <span className={classes.eventStatus}>
                {getActionCenterEventStatus(status)}
                <span>{status}</span>
              </span>
            );
          },
          type: BigidGridColumnTypes.CUSTOM,
        },
        {
          name: 'entityId',
          title: 'Entity ID',
          sortingEnabled: false,
          getCellValue: row => row.entityId,
          type: BigidGridColumnTypes.TEXT,
        },
        {
          name: 'error',
          title: 'Additional Information',
          sortingEnabled: false,
          getCellValue: row => row.error?.message || '',
          type: BigidGridColumnTypes.TEXT,
        },
      ],
    }),
    [actionCenterMetadata.actions, actionCenterMetadata.events, classes.eventStatus, classes.noData],
  );

  return (
    <div className={classes.gridWrapper}>
      <BigidPaper>
        <BigidGridWithToolbar {...config} />
      </BigidPaper>
    </div>
  );
};
