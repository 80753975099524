import './cancelButton.scss';
import template from './cancelButton.html';
import { module } from 'angular';
const app = module('app');

/**
 *  Reusable generic button to perform test with SUCCESS and FAIL statuses
 * For more info see bindings options below
 */
app.component('bigidButtonCancel', {
  template,
  transclude: true,
  /**
   * Please use prefix in this component bindings,
   * to avoid attribute names collisions with Native and Popular UI frameworks such as ui-bootstrap etc...
   */
  bindings: {
    btnIsDisabled: '<',
    btnClasses: '<',
    btnOnClick: '&',
  },
});
