import React, { useState, FC, useCallback } from 'react';
import styled from '@emotion/styled';
import {
  AsyncOperationProcessingWidget,
  AsyncOperationMap,
} from '../../../components/AsyncOperationProcessingWidget/AsyncOperationProcessingWidget';
import { useAutoDiscoveryWizardAwsOperation } from './hooks/useAutoDiscoveryWizardAwsOperation';

interface DataCatalogAsyncOpsProcessingWidgetProps {
  onOperationCompleted: (dsType: string) => void;
}

const ProcessingWidgetWrapper = styled('div')`
  margin-right: 16px;
`;

export const DataSourceAsyncOperationsWidget: FC<DataCatalogAsyncOpsProcessingWidgetProps> = ({
  onOperationCompleted,
}) => {
  const [runningOperationsMap, setRunningOperationsMap] = useState<AsyncOperationMap>(new Map());

  const onOperationRun = useCallback((sseRoute: string, isOperationRan: boolean) => {
    setRunningOperationsMap(prevRunningOperationsMap => {
      return prevRunningOperationsMap.set(sseRoute, isOperationRan);
    });
  }, []);

  const { operations } = useAutoDiscoveryWizardAwsOperation({ onOperationRun, onOperationCompleted });

  return (
    <ProcessingWidgetWrapper>
      <AsyncOperationProcessingWidget
        dataAid="data-source-processing"
        operations={operations}
        operationsMap={runningOperationsMap}
      />
    </ProcessingWidgetWrapper>
  );
};
