import { getSupportedDataSources } from '../../../../utilities/dataSourcesUtils';
import { useQuery, UseQueryOptions, QueryFunction, UseQueryResult, QueryKey } from 'react-query';
import type { ErrorType } from '../../../../config/request';
import type { GetDataSourceTypesItemResponse, AsyncReturnType } from '../types';

export type GetDataSourceTypesQueryResult = NonNullable<AsyncReturnType<typeof getSupportedDataSources>>;

export type GetDataSourceTypesQueryError = ErrorType<unknown>;

export const getDataSourceTypes = async (filterNotCreatable?: boolean): Promise<GetDataSourceTypesItemResponse> =>
  getSupportedDataSources(filterNotCreatable);

export const getDataSourceTypesQueryKey = (filterNotCreatable?: boolean): Array<unknown> => [
  `getDataSourceTypes`,
  filterNotCreatable,
];

export const useGetDataSourceTypes = <
  TData = AsyncReturnType<typeof getSupportedDataSources>,
  TError = ErrorType<unknown>,
>(
  filterNotCreatable = false,
  options?: {
    query?: UseQueryOptions<AsyncReturnType<typeof getDataSourceTypes>, TError, TData>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options || {};

  const queryKey = queryOptions?.queryKey ?? getDataSourceTypesQueryKey(filterNotCreatable);
  const queryFn: QueryFunction<AsyncReturnType<typeof getDataSourceTypes>> = async () =>
    getDataSourceTypes(filterNotCreatable);
  const query = useQuery<AsyncReturnType<typeof getDataSourceTypes>, TError, TData>(queryKey, queryFn, {
    ...queryOptions,
  });

  return {
    queryKey,
    ...query,
  };
};
