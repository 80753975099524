import React, { FC, PropsWithChildren } from 'react';
import { BigidButtonIcon, BigidHeading6, BigidPaper } from '@bigid-ui/components';
import { BigidDeleteIcon } from '@bigid-ui/icons';
import styled from '@emotion/styled';

const FormContainer = styled('div')({
  padding: '16px',
  display: 'flex',
  flexDirection: 'column',
});

const SectionTopArea = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
});

export interface EmailContentSectionWrapperProps extends PropsWithChildren {
  titleText: string;
  isDeletionEnabled?: boolean;
  sectionIndex?: number;
  onDeleteSection?: (sectionIndex: number) => void;
}

export const EmailContentSectionWrapper: FC<EmailContentSectionWrapperProps> = ({
  titleText,
  children,
  isDeletionEnabled = true,
  sectionIndex,
  onDeleteSection,
}) => {
  const handleDeleteSectionClick = () => {
    onDeleteSection(sectionIndex);
  };

  return (
    <BigidPaper>
      <FormContainer>
        <SectionTopArea>
          <BigidHeading6 paddingBottom="16px">{titleText}</BigidHeading6>
          {isDeletionEnabled && <BigidButtonIcon icon={BigidDeleteIcon} onClick={handleDeleteSectionClick} />}
        </SectionTopArea>
        {children}
      </FormContainer>
    </BigidPaper>
  );
};
