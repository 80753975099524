import { httpService } from '../../../services/httpService';
import { v4 as uuid } from 'uuid';
import { BigidGridRow } from '@bigid-ui/grid';
import { notificationService } from '../../../services/notificationService';
import { ReactText } from 'react';

export interface HotspotResponse {
  id: ReactText;
  hotspot_name: string;
  total_attr_tag: number;
  total_density: number;
  attr_tags_list: { name: string; type: 'attributes' | 'tags' }[];
}

export interface Hotspot {
  id: ReactText;
  hotspotName: string;
  totalAttrTag: number;
  totalDensity: number;
  totalFilesInFolder: number;
  attrTagsList: { name: string; type: 'attributes' | 'tags' }[];
}

export interface HotspotChartRow extends BigidGridRow {
  hotspotName: string;
  totalAttrTag: number;
  totalDensity: number;
  totalFilesInFolder: number;
  tags: string[];
  attributes: string[];
  selectedAttributes?: string[];
  selectedTags?: string[];
}

export const getHotspotsCounters = async (id?: string, query?: string): Promise<Hotspot[]> => {
  return httpService
    .fetch<HotspotResponse[]>(`hotspots/report/hotspots_counters${id ? '/' + id : ''}${query ? '?' + query : ''}`)
    .then(data =>
      data.data.map(el => {
        const hotspot: Hotspot = {
          id: uuid().toString(),
          hotspotName: el.hotspot_name,
          totalAttrTag: el.total_attr_tag,
          totalDensity: el.total_density,
          attrTagsList: el.attr_tags_list,
          totalFilesInFolder: el.total_density > 0 ? Math.round(el.total_attr_tag / el.total_density) : 0,
        };
        return hotspot;
      }),
    );
};

export const getHotspotsDatasources = () => {
  return httpService
    .fetch<string[]>(`hotspots/dss`)
    .then(({ data }) => data ?? [])
    .catch(() => []);
};

export const getAttrTags = (reportId?: string) => {
  return httpService
    .fetch<
      {
        name: string;
        type: 'tags' | 'attributes';
      }[]
    >(`hotspots/attr${reportId ? '/' + reportId : ''}`)
    .catch(err => {
      notificationService.error('Error fetching attributes and tags');
      console.error(err);
      return { data: [] };
    })
    .then(({ data }) => {
      const attributes: string[] = data.filter(v => v.type === 'attributes').map(v => v.name);
      const tags: string[] = data.filter(v => v.type === 'tags').map(v => v.name);
      return { attributes, tags };
    });
};

export interface HotspotsScanResult {
  id: string;
  data_sources: string[];
  name: string;
  request_time: Date;
  state: string;
}

export const scanForLatestHotspotId = (datasources: string[]) => {
  return httpService
    .fetch<HotspotsScanResult[]>('hotspots/report', { data_sources: (datasources ?? []).join(',') })
    .then(({ data }) => (data?.length > 0 ? data[0] : null));
};
export const downloadHotspotsReport = async (reportId: string) => {
  try {
    const reportIsReady = await httpService
      .fetch<{ report_is_ready: boolean }>(`hotspots/report/file-download-ready/${reportId ?? ''}`)
      .then(({ data }) => data.report_is_ready);
    if (reportIsReady) {
      return httpService.downloadFile(`hotspots/report/file-download/${reportId ?? ''}`, {
        format: 'csv',
      });
    } else {
      notificationService.info('File is not ready. Please try again in a minute.');
    }
  } catch (e) {
    notificationService.error('Could not download file', e);
  }
};

export interface NewHotspotsReportPayload {
  data_sources: ReactText[];
}

export const createHotspotsReport = async (payloadData: NewHotspotsReportPayload) => {
  return httpService.post(`hotspots/report`, payloadData);
};

export enum HotspotsReportStatus {
  NEW = 'New',
  INPROGRESS = 'InProgress',
  FAILED = 'Failed',
  STOPPED = 'Stopped',
  ABORTED = 'Aborted',
  QUEUED = 'Queued',
  COMPLETED = 'Completed',
  PAUSED = 'Paused',
  EXPIRED = 'Expired',
}
