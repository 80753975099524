import React, { FC, useEffect, useState } from 'react';
import { BigidHeading5, BigidLoader, BigidPageLayout } from '@bigid-ui/components';
import { useLocalTranslation } from '../translations';
import { pageHeaderService } from '../../../../common/services/pageHeaderService';
import styled from '@emotion/styled';
import { CircularProgressBar } from '../CircularProgressBar';
import { initState, areAllStepsCompleted, GettingStartedInfo } from '../utils/gettingStartedUtils';
import { GettingStartedCard } from '../GettingStartedCard/GettingStartedCard';
import { generateDataAid } from '@bigid-ui/utils';
import { GETTING_STARTED_STEPS } from './gettingStartedSecurityItems';
import { useTheme } from '@mui/styles';

const Root = styled('div')(() => ({
  height: '100%',
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  overflow: 'hidden',
  position: 'relative',
  padding: '16px clamp(0px, calc((100vw - 1200px) * 0.1), 26px) 0',
}));

const StepsBox = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  overflow: 'hidden',
  alignItems: 'center',
  backgroundColor: theme.vars.palette.bigid.gray50,
  border: `1px solid ${theme.vars.palette.bigid.gray200}`,
  borderRadius: '8px',
  boxShadow: theme.vars.tokens.bigid.shadow10,
  marginTop: '36px',
  padding: 'clamp(24px, calc(24px + (100vw - 1200px) * 0.0267), 32px)',
  paddingTop: '28px',
}));

const Header = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'row',
  paddingBottom: '60px',
  justifyContent: 'space-between',
  maxWidth: '1674px',
  width: '100%',
}));

const StepsContainer = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'row',
  gap: '24px',
  justifyContent: 'center',
  width: '100%',
}));

export const GettingStartedSecurity: FC = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [gettingStartedInfo, setGettingStartedInfo] = useState<GettingStartedInfo>({ numStepsCompleted: 0, steps: [] });
  const { t } = useLocalTranslation('security');
  const theme = useTheme();
  const dataAid = 'GettingStartedSecurity';

  useEffect(() => {
    const initGettingStartedItems = async () => {
      setGettingStartedInfo(await initState(GETTING_STARTED_STEPS));
      setIsLoading(false);
    };
    pageHeaderService.setIsHidden(true);

    initGettingStartedItems();
    return () => {
      pageHeaderService.setIsHidden(false);
    };
  }, []);

  return (
    <Root>
      <BigidPageLayout
        displayHeaderPadding={false}
        title={t('page.title')}
        details={
          <BigidHeading5 fontWeight={'normal'}>
            {!isLoading && areAllStepsCompleted(gettingStartedInfo.numStepsCompleted, gettingStartedInfo.steps.length)
              ? t('page.subTitleFinished')
              : t('page.subTitleNotFinished')}
          </BigidHeading5>
        }
        showHeaderBackground={false}
      >
        <StepsBox>
          <Header>
            <BigidHeading5 color={theme.vars.tokens.bigid.foregroundSecondary}>{t('stepsBox.title')}</BigidHeading5>
            {!isLoading && (
              <CircularProgressBar
                dataAid={generateDataAid(dataAid, ['circular-progress-bar'])}
                currentStep={gettingStartedInfo.numStepsCompleted}
                totalSteps={gettingStartedInfo.steps.length}
              />
            )}
          </Header>
          <StepsContainer>
            {isLoading && <BigidLoader position={'relative'} />}
            {gettingStartedInfo.steps.map((step, index) => (
              <GettingStartedCard key={index} {...step} />
            ))}
          </StepsContainer>
        </StepsBox>
      </BigidPageLayout>
    </Root>
  );
};
