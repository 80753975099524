import './bigidScrollbar.scss';
import PerfectScrollbar from 'perfect-scrollbar';
import { module } from 'angular';
const app = module('app');

const SCROLLBAR_X_CLASS = 'ps__rail-x';
const SCROLLBAR_Y_CLASS = 'ps__rail-y';

interface BigidScrollbarScope extends ng.IScope {
  onBigidScrollbarReady: Function; // eslint-disable-line @typescript-eslint/ban-types
  onBigidScrollbarMove: Function; // eslint-disable-line @typescript-eslint/ban-types
  onBigidScrollbarReachedBottom: Function; // eslint-disable-line @typescript-eslint/ban-types
  onBigidScrollbarReachedTop: Function; // eslint-disable-line @typescript-eslint/ban-types
}

/**
 * @ngdoc directive
 * @name bigidScrollbar
 * @restrict A
 * @priority 0 (default)
 *
 * @param {Function} onBigidScrollbarReady
 * @param {Function} onBigidScrollbarMove
 * @param {Function} onBigidScrollbarReachedBottom
 * @param {Function} onBigidScrollbarReachedTop
 *
 * @description
 * Add custom (perfect-scrollbar) scrolls to container
 *
 * @element ANY
 * @param {Object<} bigidScrollbar - perfect-scrollbar configuration object to override default options
 *
 * @example static config value, defined in HTML file
 * <div class="my-scrollable-container" bigid-scrollbar="{wheelPropagation:false}"></div>
 *
 * @example - static config, defined in $ctrl but not going to be changed - use one time binding
 * <div class="my-scrollable-container" bigid-scrollbar="{{:: $ctrl.bigidScrollbarConfig}}"></div>
 *
 * @example - dynamic config (may change) defined in $ctrl
 * <div class="my-scrollable-container" bigid-scrollbar="{{$ctrl.bigidScrollbarConfig}}"></div>
 */
app.directive('bigidScrollbar', ($timeout: ng.ITimeoutService) => {
  'ngInject';

  const DEFAULT_PS_CONFIG: PerfectScrollbar.Options = {
    wheelSpeed: 3,
    minScrollbarLength: 20,
    wheelPropagation: true,
  };

  const directiveDefinition: ng.IDirective<BigidScrollbarScope> = {
    scope: {
      onBigidScrollbarReady: '&',
      onBigidScrollbarMove: '&',
      onBigidScrollbarReachedBottom: '&',
      onBigidScrollbarReachedTop: '&',
    },
    restrict: 'A',
    link: function ($scope, $element, $attrs) {
      let ps: PerfectScrollbar;

      const element: HTMLElement = $element[0] as HTMLElement;

      const scrollToTop = () => {
        if (element) {
          element.scrollTop = 0;
        }
      };

      const scrollToBottom = () => {
        if (element) {
          element.scrollTop = element.clientHeight;
        }
      };

      const scrollToOffset = (offset = 0) => {
        if (element && !isNaN(offset) && isFinite(offset)) {
          element.scrollTop = offset;
        }
      };

      const onScroll = (event: Event) => {
        if (!ps) return;

        const targetElement = event.target as HTMLElement;

        const scrollBarX = targetElement.querySelector(`.${SCROLLBAR_X_CLASS}`) as HTMLElement;
        const scrollBarY = targetElement.querySelector(`.${SCROLLBAR_Y_CLASS}`) as HTMLElement;

        // TODO ? why?
        switch (true) {
          case targetElement.scrollHeight - targetElement.scrollTop === targetElement.clientHeight:
            // TODO: use new PS API
            $scope.onBigidScrollbarReachedBottom();
            break;
          case targetElement.scrollTop === 0:
            // TODO: use new PS API
            $scope.onBigidScrollbarReachedTop();
            break;
          default:
            // TODO: use new PS API
            $scope.onBigidScrollbarMove({
              scrollState: {
                scrollTop: targetElement.scrollTop,
                scrollBarXWidth: scrollBarX.offsetWidth || 0,
                scrollBarYHeight: scrollBarY.offsetHeight || 0,
              },
            });
        }
      };

      // init or reset existing scrollbar
      const resetPerfectScrollbar = (overrideDefaults?: PerfectScrollbar.Options) => {
        destroyPS();
        ps = new PerfectScrollbar(element, {
          ...DEFAULT_PS_CONFIG,
          ...(overrideDefaults || {}),
        });
        element.addEventListener('scroll', onScroll, { passive: true });

        $timeout(() => {
          $scope.onBigidScrollbarReady({
            context: {
              ps,
              element,
              scrollToTop,
              scrollToBottom,
              scrollToOffset,
              resetPerfectScrollbar,
            },
          });
        }, 0);
      };

      const destroyPS = () => {
        if (ps) {
          ps.destroy();
          ps = undefined;
          element.removeEventListener('scroll', onScroll);
        }
      };

      // observe changes to interpolated attribute
      $attrs.$observe<string>('bigidScrollbar', interpolatedValue => {
        const newOptions: PerfectScrollbar.Options = $scope.$eval(interpolatedValue);
        resetPerfectScrollbar(newOptions);
      });

      $scope.$on('$destroy', destroyPS);

      // perfect-scrollbar container must have `overflow` set to `hidden`
      element.style.overflow = 'hidden';
      resetPerfectScrollbar();
    },
  };

  return directiveDefinition;
});
