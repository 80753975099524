import React from 'react';
import styled from '@emotion/styled';
import { BigidBody1, BigidHeading6, BigidLoader } from '@bigid-ui/components';
import { generateDataAid } from '@bigid-ui/utils';
import { useLocalTranslation } from '../translations';

const ContentWrapper = styled('div')({
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'column',
  gap: '8px',
});

const LoaderContainer = styled('div')({
  display: 'flex',
  alignItems: 'center',
  gap: '6px',
});

export const ResultsContentLoader = ({ dataAid = 'similarTables' }) => {
  const { t } = useLocalTranslation();
  return (
    <ContentWrapper data-aid={`${generateDataAid(dataAid, ['results', 'loader'])}`}>
      <LoaderContainer>
        <BigidLoader position={'relative'} size={20} />
        <BigidHeading6> {t('results.loader.header')} </BigidHeading6>
      </LoaderContainer>
      <BigidBody1> {t('results.loader.description')} </BigidBody1>
    </ContentWrapper>
  );
};
