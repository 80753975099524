import React, { FC, useMemo } from 'react';
import { styled } from '@mui/material';
import { BigidNotes1, BigidTooltip, BigidColorsV2 } from '@bigid-ui/components';
import { generateDataAid } from '@bigid-ui/utils';
import { PieChartWidgetProps } from './PieChartWidget';
import { formatNumberCompact } from '../../../../utilities/numericDataConverter';

export interface PieChartLegendProps extends Pick<PieChartWidgetProps, 'data' | 'onLegendItemClick' | 'isBusy'> {
  dataAid?: string;
}

const Root = styled('div')`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  height: 100%;
`;

const List = styled('div')<{ isBusy: boolean }>(({ isBusy }) => {
  return `display: flex;
  flex-direction: column;
  gap: 8px;
  ${isBusy && 'pointer-events: none;'}
  ${isBusy && 'opacity: 0.5;'}
`;
});

const ListItem = styled('div')<{ isClickable: boolean; isInactive: boolean }>`
  display: flex;
  align-items: center;
  cursor: ${({ isClickable }) => (isClickable ? 'pointer' : 'default')};
  opacity: ${({ isInactive }) => (isInactive ? '0.5' : 1)};
`;

const ListItemColorIndicator = styled('div')<{ color: string; isHighlighted: boolean }>`
  min-width: 12px;
  min-height: 12px;
  border-radius: 50%;
  margin-right: 8px;
  background-color: ${({ color }) => color};
`;

const ListItemName = styled(BigidNotes1)<{ isClickable: boolean; isHighlighted: boolean }>`
  color: ${({ isClickable }) => (isClickable ? BigidColorsV2.gray[700] : BigidColorsV2.gray[500])};
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  font-weight: ${({ isHighlighted }) => (isHighlighted ? 'bold' : 'normal')} !important;
`;

export const PieChartLegend: FC<PieChartLegendProps> = ({
  dataAid = 'PieChartLegend',
  data,
  onLegendItemClick,
  isBusy,
}) => {
  const { hasActiveItem } = useMemo(() => {
    return { hasActiveItem: Boolean(data.find(({ active }) => active)) };
  }, [data]);

  return (
    <Root data-aid={dataAid}>
      <List data-aid={generateDataAid(dataAid, ['list'])} isBusy={isBusy}>
        {data.map((item, index) => {
          const { color, value, category, aggItem, active } = item;
          const valueFormatted = formatNumberCompact(value);
          const isClickable = value > 0 && Boolean(onLegendItemClick) && Boolean(aggItem);

          return (
            <BigidTooltip key={index} title={`${category}: ${valueFormatted}`} followCursor>
              <ListItem
                data-aid={generateDataAid(dataAid, ['list-item', category])}
                isClickable={isClickable}
                isInactive={(hasActiveItem && !active) || !isClickable}
                onClick={() => isClickable && onLegendItemClick?.(item)}
              >
                <ListItemColorIndicator
                  isHighlighted={active}
                  color={color}
                  data-aid={generateDataAid(dataAid, ['list-item-color', category])}
                />
                <ListItemName
                  isHighlighted={active}
                  data-aid={generateDataAid(dataAid, ['list-item-name', category])}
                  isClickable={isClickable}
                >
                  {category}
                </ListItemName>
              </ListItem>
            </BigidTooltip>
          );
        })}
      </List>
    </Root>
  );
};
