import makeStyles from '@mui/styles/makeStyles';
import { BigidColors } from '@bigid-ui/components';
import { TestConnectionStatus, TestConnectionBoxProps } from './TestConnectionBox';

export const useStyles = makeStyles({
  wrapper: {
    display: 'flex',
    minHeight: '310px',
    width: 336,
    flexGrow: 1,
    justifyContent: 'center',
    position: 'relative',
  },
  buttonTextWrapper: {
    maxHeight: '18px',
    lineHeight: '18px',
    color: BigidColors.white,
  },
  topImage: {
    position: 'absolute',
    top: '15px',
    left: 0,
    '& svg path': {
      fill: '#DEDBDB',
    },
  },
  topImageDone: {
    position: 'absolute',
    top: '86px',
    left: 'calc(50% - 120px)',
    animation: `$fromTopToMiddle 500ms`,
    '& svg path': {
      fill: '#71C73C',
    },
  },
  topImageFail: {
    position: 'absolute',
    top: '55px',
    left: 'calc(50% - 130px)',
    animation: `$fromTopToMiddleFailed 500ms`,
    '& svg path': {
      fill: '#5D5D5D',
    },
  },
  bottomImage: ({ testStatus }: Partial<TestConnectionBoxProps>) => ({
    position: 'absolute',
    top: testStatus === TestConnectionStatus.NOT_STARTED ? '210px' : '160px',
    right: 0,
    '& svg path': {
      fill: '#DEDBDB',
    },
  }),
  bottomImageDone: {
    position: 'absolute',
    top: '130px',
    right: 'calc(50% - 115px)',
    animation: `$fromBottomToMiddle 500ms`,
    '& svg path': {
      fill: '#71C73C',
    },
  },
  bottomImageFail: {
    position: 'absolute',
    top: '120px',
    right: '32px',
    animation: `$fromBottomToMiddleFailed 500ms`,
    '& svg path': {
      fill: '#5D5D5D',
    },
  },
  '@keyframes fromTopToMiddle': {
    '0%': {
      top: '15px',
      left: 0,
    },
    '100%': {
      top: '86px',
      left: 'calc(50% - 120px)',
    },
  },
  '@keyframes fromBottomToMiddle': {
    '0%': {
      top: '210px',
      right: 0,
    },
    '100%': {
      top: '130px',
      right: 'calc(50% - 115px)',
    },
  },
  '@keyframes fromTopToMiddleFailed': {
    '0%': {
      top: '15px',
      left: 0,
    },
    '100%': {
      top: '55px',
      left: 'calc(50% - 130px)',
    },
  },
  '@keyframes fromBottomToMiddleFailed': {
    '0%': {
      top: '210px',
      right: 0,
    },
    '100%': {
      top: '120px',
      right: '32px',
    },
  },
  actionsWrapper: ({ testStatus }: Partial<TestConnectionBoxProps>) => ({
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: testStatus === TestConnectionStatus.NOT_STARTED ? '0' : '200px',
  }),
  resultHeaderWrapper: ({ testStatus }: Partial<TestConnectionBoxProps>) => ({
    color: testStatus === TestConnectionStatus.SUCCESS ? BigidColors.green[400] : '#D80000',
    margin: '16px 0 0 0',
  }),
  dateWrapper: {
    margin: '16px 0',
    width: '100%',
    textAlign: 'center',
  },
  errorTextWrapper: {
    marginBottom: '20px',
    padding: '0 40px',
    textAlign: 'center',
    wordBreak: 'break-word',
    maxWidth: '340px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    '-webkit-line-clamp': '2',
    '-webkit-box-orient': 'vertical',
  },
  failedLine: {
    width: '36px',
    height: '6px',
    position: 'absolute',
    borderRadius: '50px',
    background: '#D80000',
  },
  failedLineTopLeft: {
    top: '148px',
    left: '112px',
    transform: 'rotate(160deg)',
  },
  failedLineBottomLeft: {
    top: '165px',
    left: '140px',
    transform: 'rotate(280deg)',
  },
  failedLineTopRight: {
    top: '91px',
    left: '150px',
    transform: 'rotate(280deg)',
  },
  failedLineBottomRight: {
    top: '110px',
    left: '175px',
    transform: 'rotate(160deg)',
  },
  buttonTextFirstTestWrapper: {
    maxHeight: '18px',
    lineHeight: '18px',
    color: BigidColors.white,
  },
});
