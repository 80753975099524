import { module } from 'angular';
const app = module('app');
app.factory('userDetailsService', [
  '$http',
  'appSettings',
  'downloadFileService',
  function ($http, appSettings, downloadFileService) {
    return {
      getUserDetailsData: function (filterValue) {
        filterValue = filterValue || '';
        return $http.get(appSettings.serverPath + '/' + appSettings.version + '/users/' + filterValue).then(
          function (response) {
            return response.data;
          },
          function (error) {
            return error.data;
          },
        );
      },

      getJITCsvFile: function (userId) {
        $http({
          method: 'GET',
          url:
            appSettings.serverPath + '/' + appSettings.version + '/sar/reports/file-download/' + userId + '?format=csv',
        }).then(
          function (response) {
            downloadFileService.download(response.data, 'attachment/csv', 'FullDataSubjectReport.csv');
          },
          function (error) {
            // handle error
          },
        );
      },
      launchJITScan: function (userId) {
        const jitScanData = { scanType: 'JITScan', userIds: [userId], taskType: 'jit-scan-task' };
        return $http
          .post(appSettings.serverPath + '/' + appSettings.version + '/scans', jitScanData)
          .then(function (response) {
            return response.data;
          });
      },
      launchDeleteUserTask: ({ userId, userName, assignee, profileId, profileName }) => {
        return $http
          .post(`${appSettings.serverPath}/${appSettings.version}/sar/reports/${userId}/request-for-remove`, {
            profileId,
            profileName,
          })
          .then(function (response) {
            return response.data;
          });
      },
    };
  },
]);
