import { Reducer, useReducer, useEffect, useCallback } from 'react';
import { usePrevious, useInterval, BigidDropdownOption } from '@bigid-ui/components';
import {
  previewFileContentService,
  FileTextContents,
  AttributeType,
  ContentCache,
  ScanInvestigationResponse,
  PreviewContentStatus,
} from './DsObjectFilePreviewService';
import { notificationService } from '../../../../../../services/notificationService';
import { isUndefined } from 'lodash';
import { analyticsService } from '../../../../../../services/analyticsService';
import { ObjectPreviewDSPMEvents } from '../events';

enum UseDsObjectFilePreviewAction {
  SET_INITIAL_STATE,
  SET_FILTER,
  RUN_SCAN_INIT,
  RUN_SCAN_SUCCEEDED,
  RUN_SCAN_FAILED,
  FETCH_FROM_FILE_INIT,
  FETCH_FROM_FILE_SUCCEEDED,
  FETCH_FROM_FILE_FAILED,
  FETCH_MORE_INIT,
  FETCH_MORE_SUCCEEDED,
  FETCH_MORE_FAILED,
  FETCH_FROM_CACHE_INIT,
  FETCH_FROM_CACHE_SUCCEEDED,
  FETCH_FROM_CACHE_FAILED,
  CLEAR_CACHE_SUCCEEDED,
  CLEAR_INTERVAL,
  TERMINATE_INTERVAL,
}

interface UseDsObjectFilePreviewState {
  isInitiallyFetched: boolean;
  isFetchingFromCache: boolean;
  isFetchingFromScan: boolean;
  isFetchIntervalInitiated: boolean;
  isScanFailed: boolean;
  isPollingTimeoutReached: boolean;
  isContentCached: boolean;
  isSegmentsWithAttributesOnly: boolean;
  isFileEmpty: boolean;
  isNoAttributesFile: boolean;
  scanId: string;
  chunks: FileTextContents[];
  totalChars: number;
  nextChunkIndex: number;
  attributeTypeFilter: AttributeType[];
  attribute: string;
}

type UseCurationStatePayload = {
  action: UseDsObjectFilePreviewAction;
  data?: Partial<UseDsObjectFilePreviewState> & {
    cachedContent?: ContentCache;
    scanContent?: ScanInvestigationResponse;
  };
};

interface DsObjectFilePreviewContentProps {
  dataAid?: string;
  source: string;
  containerName: string;
  fullyQualifiedName: string;
  scannerType: string;
  attributeFilter?: string;
}

export type UseDsObjectFilePreviewProps = Pick<
  DsObjectFilePreviewContentProps,
  'fullyQualifiedName' | 'scannerType' | 'source' | 'attributeFilter'
> & {
  isPollingLimitedWithTimeout: boolean;
  pollingPermissibleTimeout: number;
};

export type UseDsObjectFilePreviewResponse = Pick<
  UseDsObjectFilePreviewState,
  | 'scanId'
  | 'isInitiallyFetched'
  | 'isFetchingFromCache'
  | 'isFetchingFromScan'
  | 'isScanFailed'
  | 'isContentCached'
  | 'isSegmentsWithAttributesOnly'
  | 'isFileEmpty'
  | 'isNoAttributesFile'
  | 'isPollingTimeoutReached'
  | 'chunks'
  | 'totalChars'
  | 'nextChunkIndex'
  | 'attributeTypeFilter'
> & {
  onClearCacheClick: () => void;
  onPreviewClick: () => void;
  onLoadMoreClick: () => void;
  onSegmentsWithAttributesOnlyToggle: (isChecked: boolean) => void;
  onAttributeTypeFilterChange: (options: BigidDropdownOption[]) => void;
};

type FetchContentFromScanPayload = {
  shouldRequestNextPage?: boolean;
  shouldClearCacheBeforehand?: boolean;
} & Partial<UseDsObjectFilePreviewState>;

const getInitialState = (): UseDsObjectFilePreviewState => {
  return {
    isInitiallyFetched: false,
    isFetchingFromCache: false,
    isFetchingFromScan: false,
    isFetchIntervalInitiated: false,
    isScanFailed: false,
    isContentCached: false,
    isSegmentsWithAttributesOnly: false,
    isFileEmpty: false,
    isNoAttributesFile: false,
    isPollingTimeoutReached: false,
    scanId: null,
    nextChunkIndex: 0,
    chunks: [],
    totalChars: 0,
    attributeTypeFilter: [],
    attribute: '',
  };
};

const getIsScanFinished = (state: PreviewContentStatus): boolean => {
  return state?.toLowerCase() === 'completed' || state?.toLowerCase() === 'failed';
};

const reducer: Reducer<UseDsObjectFilePreviewState, UseCurationStatePayload> = (state, { action, data }) => {
  switch (action) {
    case UseDsObjectFilePreviewAction.SET_INITIAL_STATE: {
      return getInitialState();
    }
    case UseDsObjectFilePreviewAction.SET_FILTER: {
      const { isSegmentsWithAttributesOnly, attributeTypeFilter } = data;
      let updatedState = { ...state };

      if (!isUndefined(isSegmentsWithAttributesOnly)) {
        updatedState = { ...updatedState, isSegmentsWithAttributesOnly, isNoAttributesFile: false };
      }

      if (!isUndefined(attributeTypeFilter)) {
        updatedState = { ...updatedState, attributeTypeFilter };
      }

      return updatedState;
    }
    case UseDsObjectFilePreviewAction.FETCH_FROM_FILE_INIT: {
      return {
        ...state,
        isFetchingFromScan: true,
      };
    }
    case UseDsObjectFilePreviewAction.FETCH_FROM_FILE_SUCCEEDED: {
      const { scanContent } = data;
      const { state: scanState, page_details, next_page } = scanContent;

      let updatedState: UseDsObjectFilePreviewState = {
        ...state,
        scanId: null,
        isFetchingFromScan: false,
        isFetchIntervalInitiated: false,
        isInitiallyFetched: true,
      };

      switch (true) {
        case scanState?.toLowerCase() === 'completed': {
          const { content, attributes } = page_details;
          const updatedChunks = [...state.chunks, { content, attributes }];

          updatedState = {
            ...updatedState,
            nextChunkIndex: next_page,
            isContentCached: true,
            isScanFailed: false,
            isFileEmpty: updatedChunks.length === 1 && content.trim().length === 0,
          };

          if (!updatedState.isFileEmpty) {
            updatedState = {
              ...updatedState,
              chunks: updatedChunks,
              totalChars: updatedChunks.reduce((total, { content }) => total + content.length, 0),
            };
          }

          break;
        }
        case scanState?.toLowerCase() === 'failed': {
          updatedState = {
            ...updatedState,
            isScanFailed: true,
          };

          break;
        }
      }

      return updatedState;
    }
    case UseDsObjectFilePreviewAction.FETCH_FROM_FILE_FAILED: {
      return {
        ...state,
        scanId: null,
        isFetchingFromScan: false,
        isScanFailed: true,
        isFetchIntervalInitiated: false,
      };
    }
    case UseDsObjectFilePreviewAction.FETCH_FROM_CACHE_INIT: {
      return {
        ...state,
        isFetchingFromCache: true,
      };
    }
    case UseDsObjectFilePreviewAction.FETCH_FROM_CACHE_SUCCEEDED: {
      const { cachedContent } = data;
      const { with_attributes, next_page, pages = [], attributeTypes = [] } = cachedContent;

      let updatedState: UseDsObjectFilePreviewState = {
        ...state,
        nextChunkIndex: next_page,
        isSegmentsWithAttributesOnly: with_attributes,
        attributeTypeFilter: attributeTypes,
        isFetchingFromCache: false,
        isScanFailed: false,
        isInitiallyFetched: true,
      };

      switch (true) {
        case pages.length === 1: {
          const { content } = pages[0];

          if (content.trim().length === 0) {
            updatedState = {
              ...updatedState,
              isContentCached: true,
              isFileEmpty: true,
            };
          } else {
            updatedState = {
              ...updatedState,
              isContentCached: true,
              chunks: pages,
              totalChars: pages.reduce((total, { content }) => total + content.length, 0),
            };
          }

          break;
        }
        case pages.length > 1: {
          updatedState = {
            ...updatedState,
            isContentCached: true,
            chunks: pages,
            totalChars: pages.reduce((total, { content }) => total + content.length, 0),
          };

          break;
        }
      }

      return updatedState;
    }
    case UseDsObjectFilePreviewAction.FETCH_FROM_CACHE_FAILED: {
      return {
        ...state,
        scanId: null,
        isInitiallyFetched: true,
        isFetchingFromCache: false,
        isScanFailed: false,
      };
    }
    case UseDsObjectFilePreviewAction.RUN_SCAN_INIT: {
      return {
        ...state,
        scanId: null,
        isFetchIntervalInitiated: false,
        isFetchingFromScan: true,
      };
    }
    case UseDsObjectFilePreviewAction.RUN_SCAN_SUCCEEDED: {
      const { scanId } = data;

      return {
        ...state,
        scanId,
        isFetchIntervalInitiated: true,
        isPollingTimeoutReached: false,
      };
    }
    case UseDsObjectFilePreviewAction.RUN_SCAN_FAILED: {
      const { isNoAttributesFile } = data;

      let updatedState: UseDsObjectFilePreviewState = {
        ...state,
        scanId: null,
        isFetchIntervalInitiated: false,
        isFetchingFromScan: false,
        isContentCached: false,
        totalChars: 0,
        chunks: [],
      };

      if (!isUndefined(isNoAttributesFile)) {
        updatedState = {
          ...updatedState,
          isNoAttributesFile,
        };
      }

      return updatedState;
    }
    case UseDsObjectFilePreviewAction.CLEAR_CACHE_SUCCEEDED: {
      return {
        ...state,
        chunks: [],
        totalChars: 0,
        isScanFailed: false,
        isFileEmpty: false,
        isNoAttributesFile: false,
        isContentCached: false,
      };
    }
    case UseDsObjectFilePreviewAction.CLEAR_INTERVAL: {
      return {
        ...state,
        isFetchIntervalInitiated: false,
      };
    }
    case UseDsObjectFilePreviewAction.TERMINATE_INTERVAL: {
      return {
        ...state,
        isFetchIntervalInitiated: false,
        scanId: null,
        isFetchingFromScan: false,
        isScanFailed: true,
        isPollingTimeoutReached: true,
      };
    }
    default:
      return state;
  }
};

const FETCH_INTERVAL = 2000;
const SCAN_TYPE = 'filePreviewScan';

export const useDsObjectFilePreview = ({
  fullyQualifiedName,
  pollingPermissibleTimeout,
  isPollingLimitedWithTimeout,
  scannerType,
  source,
  attributeFilter,
}: UseDsObjectFilePreviewProps): UseDsObjectFilePreviewResponse => {
  const prevFullyQualifiedName = usePrevious(fullyQualifiedName);
  const [
    {
      isInitiallyFetched,
      isFetchingFromCache,
      isFetchingFromScan,
      isFetchIntervalInitiated,
      isSegmentsWithAttributesOnly,
      isScanFailed,
      isContentCached,
      isFileEmpty,
      isNoAttributesFile,
      isPollingTimeoutReached,
      scanId,
      chunks,
      totalChars,
      nextChunkIndex,
      attributeTypeFilter,
    },
    dispatch,
  ] = useReducer(reducer, {}, getInitialState);

  const clearCache = useCallback(
    async (isSilent?: boolean) => {
      try {
        await previewFileContentService.deleteObjectCachedContent(fullyQualifiedName);

        if (!isSilent) {
          notificationService.success('Cached content deleted successfully');
        }

        dispatch({
          action: UseDsObjectFilePreviewAction.CLEAR_CACHE_SUCCEEDED,
        });
      } catch ({ message }) {
        const notificationMessage = 'An error in deleting file cached content';
        if (!isSilent) {
          notificationService.error(notificationMessage);
        }
        console.error(`${notificationMessage}: ${message}`);
      }
    },
    [fullyQualifiedName],
  );

  const fetchFileContent = useCallback(async () => {
    try {
      dispatch({
        action: UseDsObjectFilePreviewAction.FETCH_FROM_FILE_INIT,
      });

      const data = await previewFileContentService.getObjectCachedContent(
        scanId,
        isSegmentsWithAttributesOnly,
        attributeTypeFilter,
        attributeFilter,
      );

      if (getIsScanFinished(data?.state)) {
        dispatch({
          action: UseDsObjectFilePreviewAction.CLEAR_INTERVAL,
        });

        dispatch({
          action: UseDsObjectFilePreviewAction.FETCH_FROM_FILE_SUCCEEDED,
          data: {
            scanContent: data,
          },
        });
      }
    } catch ({ message }) {
      notificationService.error('Failed to get file content');
      console.error(`An error has occurred, ${message}`);

      dispatch({
        action: UseDsObjectFilePreviewAction.FETCH_FROM_FILE_FAILED,
      });
    }
  }, [attributeTypeFilter, isSegmentsWithAttributesOnly, scanId]);

  const updatePreviewSettings = useCallback(
    async (
      { isSegmentsWithAttributesOnly, attributeTypeFilter }: Partial<UseDsObjectFilePreviewState>,
      shouldResetCache?: boolean,
    ) => {
      if (shouldResetCache) {
        await clearCache(true);
      }

      try {
        await previewFileContentService.updateRequestAttributesValue(
          fullyQualifiedName,
          isSegmentsWithAttributesOnly,
          attributeTypeFilter,
        );
      } catch ({ message }) {
        const notificationMessage = 'An error has occurred';
        console.error(`${notificationMessage}: ${message}`);
        notificationService.error(notificationMessage);
      }
    },
    [clearCache, fullyQualifiedName],
  );

  const fetchContentFromScan = useCallback(
    async ({
      shouldRequestNextPage,
      shouldClearCacheBeforehand,
      attributeTypeFilter,
      isSegmentsWithAttributesOnly,
      attribute,
    }: FetchContentFromScanPayload) => {
      const attributeNameForPreviewScan = attribute ? [attribute.replace('classifier.', '')] : [];
      try {
        if (shouldClearCacheBeforehand) {
          await clearCache(true);
        }

        dispatch({
          action: UseDsObjectFilePreviewAction.RUN_SCAN_INIT,
        });

        const { investigationId } = await previewFileContentService.scanFileContentChunk({
          scanType: SCAN_TYPE,
          fullyQualifiedName,
          with_attributes: isSegmentsWithAttributesOnly,
          attributeTypes: attributeTypeFilter,
          page: shouldRequestNextPage ? nextChunkIndex : undefined,
          attribute_names: attributeNameForPreviewScan,
        });

        if (!investigationId) {
          dispatch({
            action: UseDsObjectFilePreviewAction.RUN_SCAN_FAILED,
            data: {
              isNoAttributesFile: true,
            },
          });
        } else {
          dispatch({
            action: UseDsObjectFilePreviewAction.RUN_SCAN_SUCCEEDED,
            data: {
              scanId: investigationId,
            },
          });
        }
      } catch (err) {
        dispatch({
          action: UseDsObjectFilePreviewAction.RUN_SCAN_FAILED,
          data: {},
        });
        notificationService.error('An error has occurred while trying to get file content');
        window.console.error(`An error has occurred, ${err}`);
      }
    },
    [clearCache, fullyQualifiedName, nextChunkIndex],
  );

  const fetchContentFromCache = useCallback(
    async (attributeTypesFilter: AttributeType[] = []) => {
      try {
        dispatch({
          action: UseDsObjectFilePreviewAction.FETCH_FROM_CACHE_INIT,
        });

        const data = await previewFileContentService.getCachedContentObject(
          fullyQualifiedName,
          attributeTypesFilter,
          attributeFilter,
        );

        dispatch({
          action: UseDsObjectFilePreviewAction.FETCH_FROM_CACHE_SUCCEEDED,
          data: {
            cachedContent: data,
          },
        });
      } catch ({ message }) {
        const notificationMessage = 'Failed to get cached content';
        notificationService.error(notificationMessage);
        console.error(`${notificationMessage}: ${message}`);
        dispatch({
          action: UseDsObjectFilePreviewAction.FETCH_FROM_CACHE_FAILED,
        });
      }
    },
    [fullyQualifiedName],
  );

  useEffect(() => {
    if (fullyQualifiedName !== prevFullyQualifiedName) {
      dispatch({
        action: UseDsObjectFilePreviewAction.SET_INITIAL_STATE,
      });

      fetchContentFromCache();
    }
  }, [fetchContentFromCache, prevFullyQualifiedName, fullyQualifiedName]);

  useInterval(
    () => {
      fetchFileContent();
    },
    isFetchIntervalInitiated ? FETCH_INTERVAL : null,
  );

  useInterval(
    () => {
      dispatch({
        action: UseDsObjectFilePreviewAction.TERMINATE_INTERVAL,
      });
    },
    isFetchIntervalInitiated && isPollingLimitedWithTimeout ? pollingPermissibleTimeout : null,
  );

  const onClearCacheClick = (): void => {
    clearCache();
  };

  const onLoadMoreClick = useCallback((): void => {
    fetchContentFromScan({
      shouldRequestNextPage: true,
      attributeTypeFilter,
      isSegmentsWithAttributesOnly,
      attribute: attributeFilter,
    });

    const trackData = {
      fullyQualifiedName,
      dsName: source,
      scannerType,
    };

    analyticsService.trackManualEvent(ObjectPreviewDSPMEvents.DSPM_PREVIEW_FILE_EVENT_DISPLAYONLY, trackData);
  }, [
    attributeTypeFilter,
    fetchContentFromScan,
    isSegmentsWithAttributesOnly,
    fullyQualifiedName,
    source,
    scannerType,
  ]);

  const onPreviewClick = useCallback((): void => {
    fetchContentFromScan({
      attributeTypeFilter,
      isSegmentsWithAttributesOnly,
      attribute: attributeFilter,
    });

    const trackData = {
      fullyQualifiedName,
      dsName: source,
      scannerType,
    };
    analyticsService.trackManualEvent(ObjectPreviewDSPMEvents.DSPM_PREVIEW_FILE_EVENT, trackData);
  }, [
    attributeTypeFilter,
    fetchContentFromScan,
    isSegmentsWithAttributesOnly,
    fullyQualifiedName,
    source,
    scannerType,
  ]);

  const onAttributeTypeFilterChange = useCallback(
    (options: BigidDropdownOption[]): void => {
      analyticsService.trackManualEvent(ObjectPreviewDSPMEvents.DSPM_UNSTRUCTURED_PREVIEW_ATTRIBUTE_FILTER_TYPE);
      const attributeTypeFilter: AttributeType[] = options.map(({ value }) => value);

      dispatch({
        action: UseDsObjectFilePreviewAction.SET_FILTER,
        data: {
          attributeTypeFilter,
        },
      });

      if (isContentCached) {
        fetchContentFromScan({
          attributeTypeFilter,
          isSegmentsWithAttributesOnly,
          shouldClearCacheBeforehand: true,
          attribute: attributeFilter,
        });
      }

      updatePreviewSettings({ attributeTypeFilter, isSegmentsWithAttributesOnly });
    },
    [updatePreviewSettings, isSegmentsWithAttributesOnly, isContentCached, fetchContentFromScan],
  );

  const onSegmentsWithAttributesOnlyToggle = (isChecked: boolean): void => {
    dispatch({
      action: UseDsObjectFilePreviewAction.SET_FILTER,
      data: {
        isSegmentsWithAttributesOnly: isChecked,
      },
    });

    if (isContentCached) {
      fetchContentFromScan({
        attributeTypeFilter,
        isSegmentsWithAttributesOnly: isChecked,
        shouldClearCacheBeforehand: true,
      });
    }

    updatePreviewSettings({ attributeTypeFilter, isSegmentsWithAttributesOnly: isChecked });
  };

  return {
    scanId,
    attributeTypeFilter,
    isInitiallyFetched,
    isFetchingFromCache,
    isFetchingFromScan,
    isScanFailed,
    isContentCached,
    isSegmentsWithAttributesOnly,
    isFileEmpty,
    isNoAttributesFile,
    isPollingTimeoutReached,
    chunks,
    totalChars,
    nextChunkIndex,
    onClearCacheClick,
    onLoadMoreClick,
    onPreviewClick,
    onAttributeTypeFilterChange,
    onSegmentsWithAttributesOnlyToggle,
  };
};
