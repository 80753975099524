import { userPreferencesService } from '../../../services/userPreferencesService';
import { dataSourceConnectionsService, rolesService } from '../../../services/angularServices';
import { getScansData } from '../../Scans/ScanService';
import { activityHighlightsService } from '../../../services/activityHighlightsService';
import { getApplicationPreference } from '../../../services/appPreferencesService';
import { getCatalogEntityIndexingStatusObject } from '../../CatalogDiscovery/catalogDiscoveryService';

const COMPLETION_CRITERIA_SCANS_COUNT = 0;
const COMPLETION_CRITERIA_DS_COUNT = 0;
const COMPLETION_CRITERIA_USERS_COUNT = 4;

export const fetchDataSourcesCount = async (): Promise<number> => {
  const { count } = await dataSourceConnectionsService.getDataSourceConnectionsDataCount();
  return count;
};

export const fetchScansCount = async (): Promise<number> => {
  const { totalCount } = await getScansData('requireTotalCount=true');
  return totalCount;
};

export const fetchUsersCount = async (): Promise<number> => {
  const {
    data: { totalCount },
  } = await rolesService.getRBACUsers('');
  return totalCount;
};

export const checkIfOverviewWasUsed = async (): Promise<boolean> => {
  try {
    if (getApplicationPreference('DATA_OVERVIEW_ENABLED') && getApplicationPreference('METADATA_SEARCH_ENABLED')) {
      const catalogMDSearchEntityStatus = await getCatalogEntityIndexingStatusObject();
      return !!catalogMDSearchEntityStatus?.finishedIndexingAt;
    }
    const data = await userPreferencesService.get(
      userPreferencesService.SHARED_PREFERENCES.CATALOG_WAS_USED_AT_LEAST_ONCE,
    );
    return data?.data?.value;
  } catch (err) {
    console.log(err);
    return false;
  }
};

interface ActivityHighlightsCard {
  description: string;
  header: string;
}

export const wasActionableInsightsVisited = async (): Promise<boolean> => {
  const { filter: value } = (await userPreferencesService.get('actionableInsightsCases.open')) || {};
  return !!value;
};

export const checkIfDsAndScanReady = async () => {
  {
    const [dsCount, scansCount] = await Promise.all([fetchDataSourcesCount(), fetchScansCount()]);
    return dsCount > COMPLETION_CRITERIA_DS_COUNT && scansCount > COMPLETION_CRITERIA_SCANS_COUNT;
  }
};
export const wasReportVisited = async (): Promise<boolean> => {
  const isEnabled = getApplicationPreference('ACTIONABLE_INSIGHTS_ENABLED');
  if (isEnabled) {
    return wasActionableInsightsVisited();
  } else {
    return wasPopulatedActivityHighlightsReportVisited();
  }
};

export const wasPopulatedActivityHighlightsReportVisited = async (): Promise<boolean> => {
  const data = await userPreferencesService.get(
    userPreferencesService.SHARED_PREFERENCES.ACTIVITY_HIGHLIGHTS_REPORT_WAS_VISITED,
  );
  if (data?.data?.value) {
    const {
      data: {
        data: [activityHighlights],
      },
    } = await activityHighlightsService.getActivityHighlights(true);
    const { highlights } = activityHighlights;
    const piRecordsCard = highlights?.overview?.cards?.find(
      (card: ActivityHighlightsCard) => card.description == 'PI records',
    );
    if (0 !== Number(piRecordsCard?.header)) {
      return true;
    }
  }
  return false;
};

export const checkIsUsersReady = async () => {
  const usersCount = await fetchUsersCount();
  return usersCount > COMPLETION_CRITERIA_USERS_COUNT;
};
