import React, { FC } from 'react';
import cronstrue from 'cronstrue';
import { BigidScheduleIcon } from '@bigid-ui/icons';
import makeStyles from '@mui/styles/makeStyles';
import { BigidButtonIcon, BigidColorsV2 } from '@bigid-ui/components';

export interface PresetScheduleInfoProps {
  schedule: string;
  disabled: boolean;
  onClick: () => void;
}

const useStyles = makeStyles({
  scheduleContainer: {
    display: 'flex',
    alignItems: 'center',
    columnGap: '8px',
    color: BigidColorsV2.gray[600],
    fontSize: '14px',
  },
});

export const PresetScheduleInfo: FC<PresetScheduleInfoProps> = ({ schedule, onClick, disabled }) => {
  const classes = useStyles();
  return (
    <div className={classes.scheduleContainer}>
      <BigidButtonIcon icon={BigidScheduleIcon} onClick={onClick} disabled={disabled} />
      <div>{cronstrue.toString(schedule)}</div>
    </div>
  );
};
