import { BigidBody1, BigidHeading6 } from '@bigid-ui/components';
import React, { FC } from 'react';
import { generateDataAid } from '@bigid-ui/utils';

interface DataSourceBulkUpdateOmittedFieldsListProps {
  omittedFields: string[];
}

export const DataSourceBulkUpdateOmittedFieldsList: FC<DataSourceBulkUpdateOmittedFieldsListProps> = ({
  omittedFields,
}) => {
  return (
    <div data-aid={generateDataAid('OmittedFieldsList', [])}>
      <BigidHeading6>
        {`The following controls were omitted as they can only be applied to data sources of the same type:`}
      </BigidHeading6>
      <ul>
        {omittedFields.map(omittedField => (
          <li data-aid={generateDataAid('OmittedFieldsListItem', [omittedField])} key={omittedField}>
            <BigidBody1>{omittedField}</BigidBody1>
          </li>
        ))}
      </ul>
    </div>
  );
};
