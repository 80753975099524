import angular from 'angular';
import React, { FunctionComponent } from 'react';
import { BigidEditableList, BigidEditableListProps } from '@bigid-ui/components';
import { convertToAngular } from '../../../common/services/convertToAngular';

export const EditableList: FunctionComponent<BigidEditableListProps> = (props: BigidEditableListProps) => {
  return <BigidEditableList {...props} />;
};

angular
  .module('app')
  .component(
    'editableList',
    convertToAngular<BigidEditableListProps>(EditableList, [
      'title',
      'listItems',
      'sourceOptions',
      'onChange',
      'isMultiple',
    ]),
  );
