import React, { FC, ReactText, useState } from 'react';
import { BigidBody1, BigidDialog, PrimaryButton, SecondaryButton } from '@bigid-ui/components';
import { ServiceConfigurationsModel, ServiceConfigurationValue } from './ServicesConfiguration';
import { ServiceConfigurationValueControl, UI_DATE_TIME_LOCALE_FEATURE_FLAG } from './ServiceConfigurationValueControl';
import makeStyles from '@mui/styles/makeStyles';
import classNames from 'classnames';

export interface ServicesConfigurationDialogProps {
  onSubmit: (value: ServiceConfigurationValue) => Promise<void>;
  onCancel: () => void;
  configuration: ServiceConfigurationsModel;
}

const useStyles = makeStyles(() => ({
  dialogBodyContainer: {
    width: '100%',
    minHeight: 150,
  },
  dialogBodyContainerLarge: {
    minHeight: 350,
  },
  label: {
    marginBottom: 10,
  },
}));

export const ServicesConfigurationDialog: FC<ServicesConfigurationDialogProps> = ({
  onSubmit,
  onCancel,
  configuration = {} as ServiceConfigurationsModel,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const { dialogBodyContainer, label, dialogBodyContainerLarge } = useStyles();
  const { value: configurationInitialValue, name, type, service } = configuration;
  const isLocaleType = name === UI_DATE_TIME_LOCALE_FEATURE_FLAG;

  const [configurationValue, setConfigurationValue] = useState<ReactText>(configurationInitialValue?.toString());

  const onSaveClick = (): void => {
    setIsLoading(true);
    let returnValue: ServiceConfigurationValue = configurationValue;
    if (typeof configurationInitialValue === 'boolean' && type === 'boolean') {
      returnValue = configurationValue === 'true';
    } else if (typeof configurationInitialValue === 'number' && typeof configurationValue === 'string') {
      returnValue = Number(configurationValue);
    }
    onSubmit(returnValue).finally(() => setIsLoading(false));
  };

  return (
    <BigidDialog
      title={`Edit ${name}`}
      isOpen={!!configuration}
      onClose={onCancel}
      isLoading={isLoading}
      maxWidth="sm"
      borderBottom
      borderTop
      buttons={[
        {
          component: SecondaryButton,
          onClick: onCancel,
          text: 'Cancel',
        },
        {
          component: PrimaryButton,
          onClick: onSaveClick,
          text: 'Save',
        },
      ]}
    >
      <div className={classNames(dialogBodyContainer, isLocaleType && dialogBodyContainerLarge)}>
        <BigidBody1 className={label}>{`Editing variable ${name} in ${service}:`}</BigidBody1>
        <ServiceConfigurationValueControl
          name={name}
          type={isLocaleType ? name : type}
          configurationValue={configurationValue}
          setConfigurationValue={setConfigurationValue}
        />
      </div>
    </BigidDialog>
  );
};
