import React, { FC } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { tpaStatusService } from '../../../services/tpaStatusService';
import { BigidSyncIcon } from '@bigid-ui/icons';
import { BigidButtonIcon, BigidTooltip } from '@bigid-ui/components';

export interface AboutModalProps {
  version: string;
  status: string;
  onRefreshApp: any;
}

const useStyles = makeStyles({
  wrapper: {
    display: 'flex',
    alignItems: 'center',
  },
  valuesWrapper: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: '3vw',
  },
  updateIcon: {
    paddingLeft: '4px',
  },
});

export const AboutApplicationModal: FC<AboutModalProps> = ({ version, status, onRefreshApp }) => {
  const classes = useStyles({});

  return (
    <div className={classes.wrapper}>
      Version Number:
      <div className={classes.valuesWrapper}>
        {version}
        {tpaStatusService.isUpdateAvailable(status) && (
          <BigidTooltip title={'Update Available'} placement={'top'}>
            <span className={classes.updateIcon}>
              <BigidButtonIcon icon={BigidSyncIcon} onClick={onRefreshApp} />
            </span>
          </BigidTooltip>
        )}
      </div>
    </div>
  );
};
