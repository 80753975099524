import { module } from 'angular';
import './dataSource.component.scss';
import template from './dataSource.component.html';
import { BUSINESS_FLOWS_PERMISSIONS, DATA_SOURCES_PERMISSIONS } from '@bigid/permissions';
import { isPermitted } from '../../../react/services/userPermissionsService';
import { goToDataSource } from '../../../react/views/DataSources/DataSourcesService';

const app = module('app');

app.component('datasource', {
  template,
  controller: [
    '$state',
    'dataSourceConnectionsService',
    'explorationBarChartService',
    'dataMappingService',
    'DeleteConfirmation',
    function (
      $state,
      dataSourceConnectionsService,
      explorationBarChartService,
      dataMappingService,
      DeleteConfirmation,
    ) {
      this.tab = 'settings';
      const ctrl = this;
      ctrl.dsNames = [];
      ctrl.originalSelectedDataSource = '';
      ctrl.attributes = [];
      ctrl.attributesFromSystem = [];
      ctrl.dataSourceName = null;
      ctrl.additionalDataSources = null;
      ctrl.showEditableDs = true;

      ctrl.$onInit = () => {
        ctrl.hasManagePermission = isPermitted(BUSINESS_FLOWS_PERMISSIONS.MANAGE.name);
        ctrl.isCreateDSPermitted = isPermitted(DATA_SOURCES_PERMISSIONS.CREATE.name);
        ctrl.isEditDSPermitted = isPermitted(DATA_SOURCES_PERMISSIONS.EDIT.name);

        ctrl.vm.selectedEntity.showGroupPicker = false;
        ctrl.additionalDataSourcesEnabled = ctrl.vm.selectedEntity.dataSourceName ? true : false;
        setAppNamesArr(ctrl.vm.selectedEntity.dataSourceName, ctrl.vm.selectedEntity.additionalDataSources);
        if (ctrl.vm.ropaMode) {
          ctrl.vm.selectedEntity.country = ctrl.vm.businessProcess.ropaResource.dataSources.find(
            ({ name }) => name === ctrl.vm.selectedEntity.dataSourceName,
          )?.location;
          dataSourceConnectionsService
            .getDataSourceConnectionsDataByID(ctrl.vm.selectedEntity.dataSourceName)
            .then(({ ds_connection: { name } }) => (ctrl.showEditableDs = !!name));
        }
      };

      ctrl.$onChanges = () => {
        saveFieldsValue();
      };

      const saveFieldsValue = () => {
        ctrl.dataSourceName = ctrl.vm.selectedEntity.dataSourceName;
        ctrl.additionalDataSources = ctrl.vm.selectedEntity.additionalDataSources;
        ctrl.attributesFromSystem = ctrl.vm.selectedEntity.attributesFromSystem;
        ctrl.attributes = ctrl.vm.selectedEntity.attributes;
        dataSourceConnectionsService
          .getDataSourceConnectionsDataByID(ctrl.dataSourceName)
          .then(({ ds_connection: { name } }) => (ctrl.showEditableDs = !!name));
      };

      const getAttributes = (namesArray, type) => {
        dataMappingService.getAttributes(namesArray, type).then(result => {
          ctrl.vm.selectedEntity.attributes = [];
          ctrl.vm.selectedEntity.categories = [];
          ctrl.vm.selectedEntity.attributesFromSystem = result ? result : [];
          ctrl.vm.selectedEntity.categoriesFromSystem = getCategoriesFromSystem(result);
        });
      };
      const getCategoriesFromSystem = result => {
        const categoriesFromSystem = [];
        const categories = new Set();
        result.forEach(attribute => {
          if (attribute.categories) {
            attribute.categories.forEach(category => {
              //if the category doesn't exist yet
              if (!categories.has(category.unique_name)) {
                categories.add(category.unique_name);
                category.attributes = [];
                category.attributes.push(attribute.friendly_name || attribute._id);
                categoriesFromSystem.push(category);
              }
              //category is already exist
              else {
                const existCategory = categoriesFromSystem.find(
                  categoryFromSystem => categoryFromSystem.unique_name === category.unique_name,
                );
                existCategory && existCategory.attributes.push(attribute.friendly_name || attribute._id);
              }
            });
          }
        });
        return categoriesFromSystem;
      };
      const setAppNamesArr = (primary, additional) => {
        const _dsNames = ctrl.vm.dataLists.dataSourceNames;
        for (let i = 0; i < _dsNames.length; i++) {
          ctrl.dsNames[i] = {};

          ctrl.dsNames[i]['name'] = _dsNames[i];
          if (_dsNames[i] == primary) {
            ctrl.dsNames[i]['isPrimary'] = true;
            ctrl.dsNames[i]['isAdditional'] = false;
          } else {
            ctrl.dsNames[i]['isPrimary'] = false;
            ctrl.dsNames[i]['isAdditional'] = additional
              ? additional.indexOf(_dsNames[i]) > -1
                ? true
                : false
              : false;
          }
        }
      };

      const manageAppNamesArr = (primary, additional) => {
        for (let i = 0; i < ctrl.dsNames.length; i++) {
          if (ctrl.dsNames[i]['name'] == primary) {
            ctrl.dsNames[i]['isPrimary'] = true;
            ctrl.dsNames[i]['isAdditional'] = false;
          } else {
            ctrl.dsNames[i]['isPrimary'] = false;
            ctrl.dsNames[i]['isAdditional'] = additional
              ? additional.indexOf(ctrl.dsNames[i]['name']) > -1
                ? true
                : false
              : false;
          }
        }
      };

      ctrl.moveToDataSourceEdit = (dataSourceName, type) => {
        goToDataSource(dataSourceName, type);
      };

      ctrl.isEditableDs = () => {
        if (!ctrl.vm.ropaMode) {
          return true;
        }
        return ctrl.showEditableDs;
      };

      ctrl.onAdditionalSourceSelected = () => {
        getAttributes(
          ctrl.vm.selectedEntity.additionalDataSources
            ? ctrl.vm.selectedEntity.additionalDataSources.concat([ctrl.vm.selectedEntity.dataSourceName])
            : [ctrl.vm.selectedEntity.dataSourceName],
          'system',
        );
        manageAppNamesArr(ctrl.vm.selectedEntity.dataSourceName, ctrl.vm.selectedEntity.additionalDataSources);
        saveFieldsValue();
      };

      ctrl.onAdditionalSourceRemoved = () => {
        manageAppNamesArr(ctrl.vm.selectedEntity.dataSourceName, ctrl.vm.selectedEntity.additionalDataSources);
        getAttributes(
          ctrl.vm.selectedEntity.additionalDataSources
            ? ctrl.vm.selectedEntity.additionalDataSources.concat([ctrl.vm.selectedEntity.dataSourceName])
            : [ctrl.vm.selectedEntity.dataSourceName],
          'system',
        );
      };

      ctrl.changeDataSource = (value, controlName) => {
        if (
          (ctrl.vm.selectedEntity.attributes && ctrl.vm.selectedEntity.attributes.length > 0) ||
          (ctrl.vm.selectedEntity.attributesFromSystem && ctrl.vm.selectedEntity.attributesFromSystem.length > 0)
        ) {
          const modalOptions = {
            closeButtonText: 'Close',
            actionButtonText: 'Update',
            headerText: `Update ${controlName}`,
            bodyText: `Are you sure you want to change the linked ${controlName}, This will change the attribute list and reset the purposes of processing?`,
          };
          DeleteConfirmation.showModal({}, modalOptions).then(
            () => {
              this.vm.saveEntityChanges(false, `${controlName} field was updated!`);
            },
            function () {
              ctrl.vm.selectedEntity.dataSourceName = ctrl.dataSourceName;
              ctrl.vm.selectedEntity.additionalDataSources = ctrl.additionalDataSources;
              ctrl.vm.selectedEntity.attributesFromSystem = ctrl.attributesFromSystem;
              ctrl.vm.selectedEntity.attributes = ctrl.attributes;
              ctrl.onSelected(ctrl.vm.selectedEntity.dataSourceName, true);
            },
          );
        }
        //if there aren't attributes
        else {
          this.vm.saveEntityChanges(false, `${controlName} field was updated!`);
        }
      };

      this.onSelected = (selectedItem, isFromCancel) => {
        ctrl.additionalDataSourcesEnabled = !selectedItem || selectedItem == 'None' ? false : true;

        if (!ctrl.additionalDataSourcesEnabled) {
          ctrl.vm.selectedEntity.additionalDataSources = [];
        }

        manageAppNamesArr(ctrl.vm.selectedEntity.dataSourceName, ctrl.vm.selectedEntity.additionalDataSources);

        dataSourceConnectionsService.getSystemDetailsByName(selectedItem).then(result => {
          if (result) {
            ctrl.vm.selectedEntity.securityTier = parseInt(result.security_tier);
            ctrl.vm.selectedEntity.country = ctrl.checkIfCountryExist(result.location);
            ctrl.vm.selectedEntity.dsType = result.type;
          } else {
            ctrl.vm.selectedEntity.securityTier = '';
            ctrl.vm.selectedEntity.country = '';
          }
          if (typeof isFromCancel === 'undefined') {
            if (selectedItem !== 'None') {
              getAttributes(
                ctrl.vm.selectedEntity.additionalDataSources
                  ? ctrl.vm.selectedEntity.additionalDataSources.concat([ctrl.vm.selectedEntity.dataSourceName])
                  : [ctrl.vm.selectedEntity.dataSourceName],
                'system',
              );
            } else {
              ctrl.vm.selectedEntity.attributesFromSystem = [];
            }
          }
          if (ctrl.vm.ropaMode) {
            ctrl.vm.selectedEntity.country = ctrl.vm.businessProcess.ropaResource.dataSources.find(
              ({ name }) => name === ctrl.vm.selectedEntity.dataSourceName,
            ).location;
          }
        });
      };

      this.checkIfCountryExist = countryName => {
        const country = ctrl.vm.dataLists.countries.find(country => country.name === countryName);
        return country ? country.displayName : '';
      };
    },
  ],
  bindings: {
    vm: '<',
    entity: '<',
  },
});
