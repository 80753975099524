import React, { FC, useEffect, useState } from 'react';
import angular from 'angular';
import { convertToAngular } from '../../../common/services/convertToAngular';
import classnames from 'classnames';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import { $transitions, $translate } from '../../services/angularServices';
import { BigidColors, BigidIconType, BigidBypassButton } from '@bigid-ui/components';
import { SidebarHeader } from './SidebarHeader';
import { SidebarSection } from './SidebarSection';
import {
  getAccordionMenuItemIfSelected,
  getArrayToTranslate,
  getBottomGroups,
  getGroups,
  getGroupsWithTranslations,
} from './SidebarUtils';
import {
  APP_MANAGEMENT_USER_PREFERENCE_NAME,
  ApplicationManagementPreferences,
  AppPreferencesFavorites,
  CategoriesEnum,
} from '../../views/ApplicationsManagement/applicationManagementService';
import { userPreferencesService } from '../../services/userPreferencesService';
import { userPreferencesEventEmitter, UserPreferencesEvents } from '../../services/eventEmitters/userPreferencesEvents';
import { customAppService } from '../../services/customAppService';
import { EventEmitterDeregistrator } from '@bigid-ui/utils';
import { CustomAppEvents, customAppEventsEmitter } from '../../services/customAppEvents';
import { CONFIG } from '../../../config/common';
import { SystemEvents, systemEventsEmitter } from '../../services/systemEvents';
import { getApplicationPreference } from '../../services/appPreferencesService';

export interface SidebarMenuItem {
  logo?: string;
  icon?: BigidIconType;
  title: string;
  onClick?: () => void;
  link?: string;
  innerMenuItems?: SidebarMenuItem[];
  selectedStates?: string[];
}

export interface SidebarMenuGroup {
  menuItems: SidebarMenuItem[];
}

export const sidebarWidth = 56;
export const sidebarMargin = 8;
const openWidth = 260;

const useStyles = makeStyles((theme: Theme) => ({
  drawer: {
    width: sidebarWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    height: '100%',
  },
  drawerOpen: {
    background: BigidColors.gray[900],
    color: BigidColors.gray[100],
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: sidebarWidth,
    borderRadius: 6,
    position: 'unset',
    top: 'unset',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  drawerOnHover: {
    width: openWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  paperAnchorLeft: {
    left: 'unset',
  },
  bypass: {
    '&:focus': {
      position: 'relative',
    },
  },
}));

export const Sidebar: FC = () => {
  const classes = useStyles({});
  const [menuGroups, setMenuGroups] = useState<SidebarMenuGroup[]>([]);
  const [hover, setHover] = useState<boolean>(false);
  const [selectedMenuItem, setSelectedMenuItem] = useState<string>(null);
  const [openMenuItem, setOpenMenuItem] = useState<string>(null);
  const [favorites, setFavorites] = useState<AppPreferencesFavorites[]>([]);
  const [customApps, setCustomApps] = useState<any>([]);
  const [bottomGroups, setBottomGroups] = useState<SidebarMenuGroup[]>([]);

  const updateCustomApplications = (): void => {
    customAppService.getCustomApps().then(({ data }) => {
      setCustomApps(data.map((item: any) => ({ ...item, category: CategoriesEnum.UTILITIES })));
    });
  };

  const getMenuGroups = async () => {
    const groups = getGroups();
    const toTranslate = getArrayToTranslate(groups);
    const newGroups = getGroupsWithTranslations(await $translate(toTranslate), groups);

    setMenuGroups(newGroups);
  };

  useEffect(() => {
    setBottomGroups(getBottomGroups(favorites, customApps));
  }, [customApps, favorites]);

  useEffect(() => {
    const fetchFavorites = async () => {
      const { data: { favorites = [] } = {} } =
        (await userPreferencesService.get<ApplicationManagementPreferences>(APP_MANAGEMENT_USER_PREFERENCE_NAME)) || {};
      setFavorites(favorites);
    };
    fetchFavorites();

    const destoryUpdateFavoritesListener = userPreferencesEventEmitter.addEventListener(
      UserPreferencesEvents.UPDATE_FAVORITES_MANAGEMENT_PREFERENCES,
      fetchFavorites,
    );

    let customAppEventListener: EventEmitterDeregistrator = null;
    if (getApplicationPreference('SHOW_CUSTOM_APPS')) {
      updateCustomApplications();
      customAppEventListener = customAppEventsEmitter.addEventListener(
        CustomAppEvents.UPDATE_APP_LIST,
        updateCustomApplications,
      );
    }

    getMenuGroups();
    const unregisterUpdateIsSmallIdListener = systemEventsEmitter.addEventListener(
      SystemEvents.UPDATE_LICENSE,
      getMenuGroups,
    );

    const deregister = $transitions.onSuccess({}, transition => {
      const toState = transition.to().name;
      setSelectedMenuItem(toState === CONFIG.states.CUSTOM_APP ? transition.params().id : toState);
    });

    return () => {
      customAppEventListener?.();
      destoryUpdateFavoritesListener();
      unregisterUpdateIsSmallIdListener();
      deregister();
    };
  }, []);

  useEffect(() => {
    if (hover) {
      const accordionMenuItemSelected: SidebarMenuItem = getAccordionMenuItemIfSelected(menuGroups, selectedMenuItem);
      if (accordionMenuItemSelected) {
        setOpenMenuItem(accordionMenuItemSelected.title);
      }
    } else {
      setOpenMenuItem('');
    }
  }, [hover, menuGroups, selectedMenuItem]);

  return (
    <Drawer
      variant="permanent"
      className={classes.drawer}
      classes={{
        paper: classnames(classes.drawerOpen, hover && menuGroups.length > 0 && classes.drawerOnHover),
        paperAnchorLeft: classes.paperAnchorLeft,
      }}
      onFocus={() => setHover(true)}
      onMouseOver={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      <List subheader={<SidebarHeader />}>
        <BigidBypassButton
          bypassTo="bypass-content-container"
          ariaLabel="Skip to main content"
          className={`${classes.bypass} bypass-sidebar`}
          dataBypass="bypass-sidebar"
          onClick={() => setHover(false)}
        />
        {menuGroups.map((group, index) => {
          const isLast = index === menuGroups.length - 1;
          return (
            <SidebarSection
              group={group}
              divider={!isLast}
              key={index}
              selectedMenuItem={selectedMenuItem}
              openMenuItem={openMenuItem}
              setOpenMenuItem={setOpenMenuItem}
              isMenuOpen={hover}
              onItemClick={() => setHover(false)}
            />
          );
        })}
      </List>
      <List>
        {bottomGroups.map((group, index) => {
          const isLast = index === bottomGroups.length - 1;
          const hasDivider = index === bottomGroups.length - 2;
          return (
            <SidebarSection
              group={group}
              divider={hasDivider}
              key={index}
              selectedMenuItem={selectedMenuItem}
              openMenuItem={openMenuItem}
              setOpenMenuItem={setOpenMenuItem}
              isMenuOpen={hover}
              {...(isLast && { onItemBlur: isLastItem => isLastItem && setHover(false) })}
              onItemClick={() => setHover(false)}
            />
          );
        })}
      </List>
    </Drawer>
  );
};

angular.module('app').component('sidebar', convertToAngular(Sidebar));
