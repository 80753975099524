import React, { FC, useContext, useEffect, useMemo } from 'react';
import { makeStyles } from '@mui/styles';
import { BigidContentItem, ToolbarActionType } from '@bigid-ui/components';
import { generateDataAid, generateGuidedTourId } from '@bigid-ui/utils';
import {
  BigidGridWithToolbar,
  BigidGridWithToolbarProps,
  BigidGridRow,
  BigidGridColumnTypes,
  BigidGridQueryComponents,
} from '@bigid-ui/grid';
import { CuratedField, AdditionalAttribute, getAdditionalAttributes } from '../../../../curationService';
import { notificationService } from '../../../../../../services/notificationService';
import { openModifyAdditionalAttributesDialog } from './additionalAttributeService';
import { useLocalTranslation } from '../../../../translations';
import { v4 as uuid } from 'uuid';
import { CurationEvents, trackEventCurationView } from '../../../../curationEventTrackerUtils';
import { queryService } from '../../../../../../services/queryService';
import { BigidHelpIcon } from '@bigid-ui/icons';
import { CurationGuidedTourStageId } from '../../../../useCurationState';
import { CurationTabsLayoutStateContext } from '../../../../CurationStateContext';
import { getAdditionalAttributesDialogProps } from '../../../../curationUtils';
import {
  AdditionalAttributesDialogConfig,
  AdditionalAttributesObjectIdentifier,
} from '../../../useCurationFieldsConfig';
import { isPermitted } from '../../../../../../services/userPermissionsService';
import { CATALOG_PERMISSIONS } from '@bigid/permissions';

interface AdditionalAttributesProps extends BigidContentItem, Omit<CuratedField, 'id' | 'tags'> {
  dataAid?: string;
  contentContainerClassName?: string;
}

type AdditionalAttributesGridRecord = BigidGridRow & AdditionalAttribute;

const useStyles = makeStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    padding: '5px',
    position: 'relative',
  },
});

const currentGuidedTour = CurationGuidedTourStageId.ADDITIONAL_ATTRIBUTES;

export const AdditionalAttributes: FC<AdditionalAttributesProps> = ({
  dataAid = 'AdditionalAttributes',
  fullyQualifiedName,
  fieldName,
  contentContainerClassName,
}) => {
  const classes = useStyles();
  const { t } = useLocalTranslation('CuratedFields.common.AdditionalAttributes');
  const gridId = useMemo(() => `AdditionalAttributes-${uuid()}`, []);
  const { onProceedToFieldsReviewTab, onCurationGuidedTourStart } = useContext(CurationTabsLayoutStateContext);

  useEffect(() => {
    onProceedToFieldsReviewTab(currentGuidedTour, true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const gridConfig = useMemo<BigidGridWithToolbarProps<AdditionalAttributesGridRecord>>(
    () => ({
      gridId,
      entityName: t('grid.entityName'),
      hideColumnChooser: true,
      externalFilter: [],
      fetchData: async (queryComponents: BigidGridQueryComponents) => {
        try {
          const gridConfigQuery = queryService.getGridConfigQuery(queryComponents);
          const { data } = await getAdditionalAttributes({ fieldName, fullyQualifiedName }, gridConfigQuery);
          const { fields } = data;
          const gridRows = fields?.[0]?.attributes
            ? fields[0].attributes.map(attribute => ({ id: attribute.attributeId, ...attribute }))
            : [];

          return {
            totalCount: gridRows.length,
            data: gridRows,
          };
        } catch ({ message }) {
          notificationService.error(t('errors.fetchingAttributes'));
          console.error(`${t('errors.generic')}: ${message}`);

          return {
            totalCount: 0,
            data: [],
          };
        }
      },
      columns: [
        {
          title: t('columns.attributeName'),
          name: 'attributeName',
          type: BigidGridColumnTypes.TEXT,
          getCellValue: ({ attributeName }) => attributeName,
        },
        {
          title: t('columns.attributeType'),
          name: 'attributeType',
          type: BigidGridColumnTypes.TEXT,
          getCellValue: ({ attributeType }) => attributeType,
        },
        {
          title: t('columns.findings'),
          name: 'findings',
          type: BigidGridColumnTypes.TEXT,
          getCellValue: ({ findings }) => findings,
        },
      ],
      toolbarActions: [
        {
          label: t('toolbarActions.modifyAttributes'),
          type: ToolbarActionType.PRIMARY,
          isGlobal: true,
          execute: async () => {
            trackEventCurationView(CurationEvents.CURATION_FIELDS_ADDTIONAL_ATTRIBUTES_MODIFY_ATRRIBUTE_VIEW);
            try {
              const objectsSelected: AdditionalAttributesObjectIdentifier[] = [
                {
                  fieldName,
                  fullyQualifiedName,
                },
              ];

              const additionalAttributesDialogConfigSetup: AdditionalAttributesDialogConfig = {
                objectsSelected,
                gridId,
                dataAid,
                orderAfterSave: true,
              };

              openModifyAdditionalAttributesDialog(
                getAdditionalAttributesDialogProps(additionalAttributesDialogConfigSetup),
              );
            } catch ({ message }) {
              notificationService.error(t('errors.modifyingAttributes'));
              console.error(`${t('errors.generic')}: ${message}`);
            }
            return {
              shouldGridReload: false,
              shouldClearSelection: false,
            };
          },
          disable: () => {
            return false;
          },
          show: () => {
            return isPermitted(CATALOG_PERMISSIONS.EDIT_MANUAL_FIELDS.name);
          },
        },
        {
          label: '',
          execute: async () => {
            onCurationGuidedTourStart(currentGuidedTour);
            return {
              shouldGridReload: false,
            };
          },
          show: () => true,
          placement: 'end',
          icon: BigidHelpIcon,
          isGlobal: true,
          disable: () => false,
          type: ToolbarActionType.ACTION_ICON,
        },
      ],
    }),
    [dataAid, fieldName, fullyQualifiedName, gridId, onCurationGuidedTourStart, t],
  );

  return (
    <div className={contentContainerClassName}>
      <div
        className={classes.root}
        data-aid={generateDataAid(dataAid, [fullyQualifiedName, fieldName])}
        data-tour-id={generateGuidedTourId(currentGuidedTour, ['start'])}
      >
        <BigidGridWithToolbar {...gridConfig} />
      </div>
    </div>
  );
};
