import React from 'react';
import {
  BigidDropdownOption,
  BigidFormField,
  BigidFormFieldLabelPosition,
  BigidFormFieldTypes,
  BigidFormValues,
  BigidStatusBadge,
  BigidStatusBadgeSize,
  BigidStatusBadgeType,
  BooleanMap,
} from '@bigid-ui/components';
import { BigidGridColumnTypes, BigidGridProps, useFetch } from '@bigid-ui/grid';
import {
  BigidLayoutMasterDetails,
  BigidLayoutMasterDetailsConfig,
  BigidLayoutMasterDetailsGridRowLayouts as BigidGridRowLayouts,
  BigidLayoutMasterDetailsGridSearchTypes as BigidGridSearchType,
} from '@bigid-ui/layout';
import { getApplicationPreference } from '../../services/appPreferencesService';
import { CredentialsNoData } from './CredentialsNoData';
import { getFixedT } from './translations';
import {
  CredentialGridRow,
  CredentialItemResponse,
  CredentialsFieldsEntity,
  CredentialsPermissions,
  CredentialTypes,
  EnginesTypes,
  ScopesItemResponse,
} from './types';
import { ActionItemResponse } from '../CustomApp/types';
import { isPermitted } from '../../services/userPermissionsService';
import { CREDENTIALS_PERMISSIONS } from '@bigid/permissions';
import {
  CredentialsTypesMetadata,
  CredentialType,
  CredentialType as MetadataType,
  dsTypeToFormFieldType,
  ENCRYPTED_CREDENTIALS_FORMAT,
  IdentifiersMapValuesType,
} from './credentialsFormUtils';
import { ALLOWED_NAME_REGEX } from '../../config/consts';
import { DsTypesEnum } from '../DataSources/DataSourceConfiguration/types';
import { getCustomAppNormalizedFriendlyName, getNormalizedAppNameForLabel } from '../CustomApp/utils/CustomAppUtils';
import { CustomApps } from '../ApplicationsManagement/applicationManagementService';
import { credentialsService } from '../../services/angularServices';
import { notificationService } from '../../services/notificationService';

interface GetFormFields {
  scopes: ScopesItemResponse[];
  isEditMode: boolean;
  apps: CustomApps[];
  instances: CustomApps[];
  actions: ActionItemResponse[];
  metadata: CredentialsTypesMetadata[];
  cyberArkProviders?: BigidDropdownOption[];
  credentialType: CredentialTypes;
  globalPresets: BigidDropdownOption[];
}

interface PrepareRequestData {
  values: BigidFormValues;
  includeRequiredFields?: boolean;
  renderedFields: string[];
  metadata?: CredentialsTypesMetadata[];
}

interface PrepareTestConnectionData {
  values: BigidFormValues;
  renderedFields: string[];
}

const t = (key: string): string => getFixedT('')(key);
const isRemoteCyberArk = (value: CredentialTypes): boolean => value === CredentialTypes.RemoteCyberArk;
const isRemoteHashiCorp = (value: CredentialTypes): boolean => value === CredentialTypes.RemoteHashiCorp;
const isRemoteCustom = (value: CredentialTypes): boolean => value === CredentialTypes.RemoteCustom;
const isThycotic = (value: CredentialTypes): boolean => value === CredentialTypes.Thycotic;
const isRemoteThycotic = (value: CredentialTypes): boolean => value === CredentialTypes.RemoteThycotic;

const shouldShowRemoteCyberArk = (value: CredentialTypes): boolean =>
  isRemoteCyberArk(value) && getApplicationPreference('SHOW_REMOTE_CYBERARK_CREDENTIALS_TYPE') === true;
const shouldShowRemoteHashiCorp = (value: CredentialTypes): boolean =>
  isRemoteHashiCorp(value) && getApplicationPreference('SHOW_REMOTE_HASHICORP_CREDENTIALS_TYPE') === true;
const shouldShowRemoteCustom = (value: CredentialTypes): boolean =>
  isRemoteCustom(value) && getApplicationPreference('SHOW_REMOTE_CUSTOM_CREDENTIALS_TYPE') === true;
const shouldShowThycotic = (value: CredentialTypes): boolean =>
  isThycotic(value) && getApplicationPreference('SHOW_THYCOTIC_CREDENTIALS_TYPE') === true;
const shouldShowRemoteThycotic = (value: CredentialTypes): boolean =>
  isRemoteThycotic(value) && getApplicationPreference('SHOW_REMOTE_THYCOTIC_CREDENTIALS_TYPE') === true;

export const isMultipleCyberArkProvidersEnabled = () =>
  getApplicationPreference('ENABLE_UNIFIED_VAULTS') === true ||
  getApplicationPreference('ENABLE_UNIFIED_VAULTS') === 'true';

export const IS_NOT_SCANNER_ONLY_FILTER = { isScannerOnly: { $not: { $eq: true } } };
const filterCredentilaTypes = (value: CredentialTypes) =>
  (!isRemoteCyberArk(value) &&
    !isRemoteHashiCorp(value) &&
    !isRemoteCustom(value) &&
    !isThycotic(value) &&
    !isRemoteThycotic(value)) ||
  shouldShowRemoteCyberArk(value) ||
  shouldShowRemoteHashiCorp(value) ||
  shouldShowThycotic(value) ||
  shouldShowRemoteThycotic(value) ||
  shouldShowRemoteCustom(value);

export const getTypesDropdownOptions = (): BigidDropdownOption[] => {
  return Object.entries(CredentialTypes)
    .map(([displayValue, value]) => ({ displayValue, value, id: value }))
    .filter(({ value }) => filterCredentilaTypes(value));
};

export const getCredentialProvidersDropdownOptions = (providers?: BigidDropdownOption[]): BigidDropdownOption[] => {
  return providers;
};

export const getScopesDropdownOptions = (scopes: ScopesItemResponse[]): BigidDropdownOption[] =>
  scopes.map(({ id, name }) => ({ displayValue: name, value: id, id }));

export const getEngineTypesDropdownOptions = (): BigidDropdownOption[] =>
  Object.values(EnginesTypes).map(value => ({ displayValue: value, value, id: value }));

export const getTPADropdownOptions = (apps: CustomApps[]): BigidDropdownOption[] =>
  apps.map(({ tpa_name: displayValue, _id: id, settings }) => ({
    value: id,
    displayValue: getApplicationPreference('TPA_MULTIPLE_DEPLOYMENTS_ENABLED')
      ? getCustomAppNormalizedFriendlyName(displayValue, settings?.instanceId)
      : displayValue,
    id,
  }));

export const getInstancesDropdownOptions = (instances: CustomApps[]): BigidDropdownOption[] =>
  instances.map(({ tpa_name: displayValue, _id: id }) => ({ value: id, displayValue, id }));

export const getActionsDropdownOptions = (actions: ActionItemResponse[]): BigidDropdownOption[] =>
  actions.map(({ action_name: displayValue, _id: id }) => ({ id, value: id, displayValue }));

export const getMetadataDropdownOptions = (
  metadata: CredentialsTypesMetadata[],
  credentialType: CredentialTypes,
): BigidDropdownOption[] => {
  let dropDownOptions = metadata.map(({ type, displayName }) => ({
    displayValue: displayName,
    value: type,
    id: type,
  }));
  if (credentialType === CredentialTypes.RemoteHashiCorp && !getApplicationPreference('NEW_CREDENTIALS_FLOW_ENABLED')) {
    dropDownOptions = dropDownOptions.filter(
      option => option.value === MetadataType.AAD || option.value === MetadataType.BASIC,
    );
  }

  return dropDownOptions;
};

const validateField = (message: string) => (value: string) => value ? false : t(message);

const validateCredentialId = (value: string) => {
  if (!value) {
    return t('message.requiredField');
  }

  if (!value.match(ALLOWED_NAME_REGEX)) {
    return t('message.invalidCredentialIdPattern');
  }

  return false;
};

const validateJSON = (message: string) => (value: string) => {
  try {
    if (!value) {
      return t(message);
    }

    if (value === ENCRYPTED_CREDENTIALS_FORMAT) {
      return false;
    }

    JSON.parse(value);
    return false;
  } catch (e) {
    return t(message);
  }
};

const validateDropdown = (message: string) => (value: BigidDropdownOption[]) => value?.length > 0 ? false : t(message);

export const generateFormFieldsFromMetadata = (metadata: CredentialsTypesMetadata[]): BigidFormField[] =>
  metadata
    .reduce((prev, current) => [...prev, ...current.fields], [])
    .map(({ name, displayName, type, tooltipText, isMasked }) => {
      const isTextArea = type === DsTypesEnum.json;
      const isPassword = type === DsTypesEnum.password || isMasked;

      return {
        name,
        label: displayName,
        type: dsTypeToFormFieldType[type as DsTypesEnum],
        isRequired: true,
        tooltipText,
        fieldProps: {
          lpIgnore: true,
          size: 'large',
          ...(isTextArea && {
            multiline: true,
            rows: 5,
          }),
          ...(isPassword && { showValueOnlyIfDirty: true }),
        },
        validate:
          name === 'content_enc' ? validateJSON('message.validateJSON') : validateField('message.requiredField'),
      };
    })
    .filter(({ name }) => name !== 'username' && name !== 'password');

export const buildAuthenticationTypesToIdentifiersMap = (metadata: CredentialsTypesMetadata[]) => {
  const mapAuthenticationTypesToIdentifiers = metadata.reduce((accumulator, meta) => {
    if (!accumulator[meta.type]) {
      accumulator[meta.type] = [];
    }

    meta.fields.forEach(field => {
      if (!field.isMasked) {
        accumulator[meta.type].push({ name: `${field.name}IsConstant` });
      }
      accumulator[meta.type].push({ name: `${field.name}Identifier` });
    });

    return accumulator;
  }, {} as Record<string, IdentifiersMapValuesType[]>);

  return mapAuthenticationTypesToIdentifiers;
};

const generateFormFieldsFromMetadataWithIdentifier = (
  metadata: CredentialsTypesMetadata[],
  t: (key: string) => string,
): BigidFormField[] => {
  const metadataFormFields: BigidFormField[] = [];
  metadata
    .reduce((prev, current) => [...prev, ...current.fields], [])
    .forEach(({ name, isMasked }) => {
      if (!isMasked) {
        const formPlainTextField: BigidFormField = {
          name: `${name}IsConstant`,
          type: BigidFormFieldTypes.RADIO,
          misc: {
            fullWidth: false,
          },
          options: [
            {
              value: false,
              label: t(`form.${name}Identifier`),
            },
            {
              value: true,
              label: t(`form.${name}Constant`),
            },
          ],
          isRequired: false,
        };
        metadataFormFields.push(formPlainTextField);
        const formField: BigidFormField = {
          name: `${name}Identifier`,
          isRequired: false,
          fieldProps: {
            lpIgnore: true,
            size: 'large',
          },
        };
        metadataFormFields.push(formField);
      } else {
        const formField: BigidFormField = {
          name: `${name}Identifier`,
          label: t(`form.${name}Identifier`),
          isRequired: false,
          fieldProps: {
            lpIgnore: true,
            size: 'large',
          },
        };
        metadataFormFields.push(formField);
      }
    });

  return metadataFormFields;
};

export const getFormFields = ({
  scopes,
  isEditMode,
  apps,
  actions,
  instances,
  metadata,
  cyberArkProviders,
  credentialType,
  globalPresets,
}: GetFormFields): BigidFormField[] => [
  {
    name: 'credential_id',
    label: t('form.credential_id'),
    isRequired: true,
    validate: validateCredentialId,
    disabled: !isEditMode,
    fieldProps: {
      size: 'large',
    },
  },
  {
    name: 'isScannerOnly',
    label: t('form.isScannerOnly'),
    isRequired: false,
    type: BigidFormFieldTypes.CHECKBOX,
    labelPosition: BigidFormFieldLabelPosition.left,
    tooltipText:
      'Selecting this option will keep the credential on the scanner environment that is used to fetch it from the target vault; note - this limits where these credentials can be used from and could potentially increase the load on the target vault system; consult with BigID prior to using this.',
  },
  {
    name: 'type',
    label: t('form.type'),
    isRequired: true,
    validate: validateDropdown('message.requiredField'),
    dropDownOptions: getTypesDropdownOptions(),
    type: BigidFormFieldTypes.DROP_DOWN,
    disabled: !isEditMode,
    fieldProps: {
      size: 'large',
    },
  },
  {
    name: 'scopes',
    label: t('form.scopes'),
    isRequired: true,
    validate: validateDropdown('message.requiredField'),
    dropDownOptions: getScopesDropdownOptions(scopes),
    type: BigidFormFieldTypes.DROP_DOWN,
    fieldProps: {
      isSearchable: true,
      isMulti: true,
      applyOnChange: true,
      isExpandable: true,
      size: 'large',
    },
  },
  {
    name: 'subType',
    label: t('form.subType'),
    isRequired: true,
    validate: validateDropdown('message.requiredField'),
    dropDownOptions: getMetadataDropdownOptions(metadata, credentialType),
    type: BigidFormFieldTypes.DROP_DOWN,
    fieldProps: {
      size: 'large',
    },
  },
  ...(generateFormFieldsFromMetadata(metadata) ?? []),
  {
    name: 'username',
    label: t('form.username'),
    isRequired: true,
    validate: validateField('message.requiredField'),
    fieldProps: {
      size: 'large',
      lpIgnore: true,
    },
  },
  {
    name: 'password',
    label: t('form.password'),
    isRequired: true,
    type: BigidFormFieldTypes.PASSWORD,
    validate: validateField('message.requiredField'),
    fieldProps: {
      size: 'large',
      showValueOnlyIfDirty: true,
      lpIgnore: true,
    },
  },
  {
    name: 'query',
    label: t('form.query'),
    isRequired: true,
    validate: validateField('message.requiredField'),
    type: BigidFormFieldTypes.TEXTAREA,
    misc: {
      fullWidth: true,
      rows: 3,
    },
    fieldProps: {
      multiline: true,
    },
  },
  {
    name: 'scanner_group',
    label: t('form.scannerGroup'),
    isRequired: true,
    validate: validateField('message.requiredField'),
    fieldProps: {
      size: 'large',
    },
  },
  {
    name: 'secret_engine',
    label: t('form.secretEngine'),
    isRequired: true,
    validate: validateDropdown('message.requiredField'),
    dropDownOptions: getEngineTypesDropdownOptions(),
    type: BigidFormFieldTypes.DROP_DOWN,
    disabled: !isEditMode,
    fieldProps: {
      size: 'large',
    },
  },
  {
    name: 'cyberArkVaultId',
    label: t('form.cyberArkVaultId'),
    isRequired: true,
    validate: validateField('message.requiredField'),
    dropDownOptions: getCredentialProvidersDropdownOptions(cyberArkProviders),
    type: BigidFormFieldTypes.DROP_DOWN,
    disabled: !isEditMode,
    fieldProps: {
      size: 'large',
    },
  },
  {
    name: 'url',
    label: t('form.url'),
    isRequired: true,
    validate: validateField('message.requiredField'),
    fieldProps: {
      size: 'large',
    },
  },
  {
    name: 'ttl',
    label: t('form.ttl'),
    isRequired: false,
    type: BigidFormFieldTypes.NUMBER,
    fieldProps: {
      size: 'large',
      min: 0,
    },
  },
  {
    name: 'apps',
    label: t('form.apps'),
    isRequired: true,
    validate: validateDropdown('message.requiredField'),
    dropDownOptions: getTPADropdownOptions(apps),
    type: BigidFormFieldTypes.DROP_DOWN,
    fieldProps: {
      isSearchable: true,
      size: 'large',
    },
  },
  {
    name: 'instances',
    label: t('form.instances'),
    isRequired: true,
    validate: instances.length > 1 && validateDropdown('message.requiredField'),
    dropDownOptions: getInstancesDropdownOptions(instances),
    type: BigidFormFieldTypes.DROP_DOWN,
    fieldProps: {
      isSearchable: true,
      size: 'large',
    },
  },
  {
    name: 'globalPreset',
    label: t('form.globalPresets'),
    tooltipText: t('form.globalPresetsTooltip'),
    isRequired: false,
    dropDownOptions: globalPresets,
    type: BigidFormFieldTypes.DROP_DOWN,
    fieldProps: {
      isSearchable: true,
      size: 'large',
    },
  },
  {
    name: 'actions',
    label: t('form.actions'),
    isRequired: true,
    validate: validateDropdown('message.requiredField'),
    dropDownOptions: getActionsDropdownOptions(actions),
    type: BigidFormFieldTypes.DROP_DOWN,
    fieldProps: {
      isSearchable: true,
      size: 'large',
    },
  },
  {
    name: 'customQuery',
    label: t('form.customQuery'),
    isRequired: false,
    type: BigidFormFieldTypes.TEXTAREA,
    misc: {
      fullWidth: true,
      rows: 3,
    },
    fieldProps: {
      multiline: true,
    },
  },
  ...generateFormFieldsFromMetadataWithIdentifier(metadata, t),
];

const getDropdownInitialValue = (
  field: BigidFormField,
  row: CredentialGridRow,
  apps?: CustomApps[],
): BigidDropdownOption[] => {
  const { name, dropDownOptions } = field;
  if (name === 'scopes') {
    return dropDownOptions.filter(option => row[name]?.includes(option.value));
  }

  if (name === 'globalPreset') {
    return row[name]?.map(({ value, label }) => ({
      id: value,
      value: value,
      displayValue: label,
    }));
  }

  if (name === 'instances') {
    const hasInitialValueForInstancesDropdown = row[name]?.length > 0;
    const instancesOrAppsFieldName = hasInitialValueForInstancesDropdown ? 'instances' : 'apps';
    return row[instancesOrAppsFieldName]?.map(({ value, label }) => ({
      id: value,
      value: value,
      displayValue: label,
    }));
  }

  if (name === 'actions' || name === 'apps') {
    return row[name]?.map(({ value, label }) => ({
      id: value,
      value: value,
      displayValue:
        getApplicationPreference('TPA_MULTIPLE_DEPLOYMENTS_ENABLED') && name === 'apps'
          ? getNormalizedAppNameForLabel(apps, value, label)
          : label,
    }));
  }

  if (name === 'subType') {
    return dropDownOptions.filter(option => option.value === (row[name] ?? MetadataType.BASIC));
  }

  return dropDownOptions.filter(option => option.value === row[name as keyof CredentialGridRow]);
};

const isUserNameAsPlainText = (row: CredentialGridRow) => {
  return row.usernameAsPlainText?.toString() === 'true';
};

export const getInitialValues = (
  formFields: BigidFormField[],
  row: CredentialGridRow,
  apps?: CustomApps[],
): BigidFormValues =>
  formFields.reduce<BigidFormValues>((accumulator, field) => {
    const { name, type } = field;
    const fieldName = name as keyof CredentialGridRow;
    const isDropDownType = type === BigidFormFieldTypes.DROP_DOWN;
    const isCustomType = !!row.credentialFields;

    const getValue = () => {
      if (isDropDownType) {
        return getDropdownInitialValue(field, row, apps);
      }

      if (name === 'usernameIsConstant' && row.hasOwnProperty('usernameAsPlainText')) {
        return isUserNameAsPlainText(row);
      }

      if (name === 'password_encIdentifier' && row.credentialsIdentifiers?.hasOwnProperty('passwordIdentifier')) {
        return row.credentialsIdentifiers['passwordIdentifier'];
      }

      if (name.endsWith('Identifier')) {
        if (row.credentialsIdentifiers && row.credentialsIdentifiers.hasOwnProperty(name)) {
          return row.credentialsIdentifiers[name];
        } else if (row.credentialsConstants && row.credentialsConstants.hasOwnProperty(name)) {
          return row.credentialsConstants[name];
        }
      } else if (name.endsWith('IsConstant')) {
        const baseName = name.replace('IsConstant', '');
        return (row.credentialsConstants && row.credentialsConstants.hasOwnProperty(`${baseName}Identifier`)) || false;
      }

      if (!isCustomType && name === 'password_enc' && row.password && !row.credentialFields?.password_enc) {
        return row.password;
      }

      if (isCustomType) {
        return row.credentialFields[name as keyof CredentialsFieldsEntity] ?? row[fieldName];
      }

      if (name === 'credential_id' && row.isPending) {
        return '';
      }

      return row[fieldName];
    };

    return {
      ...accumulator,
      [name]: getValue(),
    };
  }, {} as BigidFormValues);

export const createLayoutConfig = (
  gridConfig: BigidGridProps<CredentialGridRow>,
): BigidLayoutMasterDetailsConfig<CredentialGridRow> => ({
  grid: gridConfig,
  search: {
    fields: ['credential_id'],
    placeholder: t('search.placeholder'),
    type: BigidGridSearchType.INTEGRATED,
  },
});

export const createGridConfig = (
  id: string,
  config: ReturnType<typeof useFetch<CredentialGridRow>>,
): BigidGridProps<CredentialGridRow> => ({
  gridId: id,
  rows: config.rows,
  totalRowsCount: config.totalRowsCount,
  skip: config.skip,
  onPagingChanged: config.onPagingChanged,
  onSortingChanged: config.onSortingChanged,
  onFiltersChange: config.onFiltersChanged,
  defaultSorting: config.defaultSorting,
  loading: config.isLoading,
  pageSize: 1000,
  noDataContent: <CredentialsNoData message={t('message.noCredentials')} />,
  columns: [
    {
      name: 'credential_id',
      title: 'credential_id',
      width: 'auto',
      getCellValue: row => {
        const subTitle = row.type === 'simple' ? 'BigID' : row.type;
        return (
          <BigidLayoutMasterDetails.Row
            title={row.credential_id}
            subtitle={
              row.isPending ? (
                <BigidStatusBadge
                  label={t('statusText')}
                  type={BigidStatusBadgeType.DARK}
                  size={BigidStatusBadgeSize.SMALL}
                />
              ) : (
                subTitle
              )
            }
            layout={BigidGridRowLayouts.TWO_ROW}
          />
        );
      },
      type: BigidGridColumnTypes.CUSTOM,
    },
  ],
});

export const getPermissions = (): CredentialsPermissions => ({
  isDeletePermitted: isPermitted(CREDENTIALS_PERMISSIONS.DELETE.name),
  isEditPermitted: isPermitted(CREDENTIALS_PERMISSIONS.EDIT.name),
  isCreatePermitted: isPermitted(CREDENTIALS_PERMISSIONS.CREATE.name),
  isTestConnectionPermitted: isPermitted(CREDENTIALS_PERMISSIONS.TEST_CONNECTION.name),
});

const mapDropdownOptionToRequestOptions = ({ value, displayValue }: BigidDropdownOption) => ({
  value,
  label: displayValue,
});

const mapFormFieldValueToRequestData: Partial<Record<keyof CredentialItemResponse, any>> = {
  type: (options: BigidDropdownOption[]) => options[0].value,
  scopes: (options: BigidDropdownOption[]) => options.map(({ value }: BigidDropdownOption) => value),
  actions: (options: BigidDropdownOption[]) => options.map(mapDropdownOptionToRequestOptions),
  apps: (options: BigidDropdownOption[]) => options.map(mapDropdownOptionToRequestOptions),
  instances: (options: BigidDropdownOption[]) => options.map(mapDropdownOptionToRequestOptions),
  globalPreset: (options: BigidDropdownOption[]) => options?.map(mapDropdownOptionToRequestOptions),
  subType: (options: BigidDropdownOption[]) => options[0].value,
  secret_engine: (options: BigidDropdownOption[]) => options[0].value,
  ttl: (value: string) => Number(value),
  cyberArkVaultId: (options: BigidDropdownOption[]) => options[0].value,
};

export const prepareRequestData = ({
  values,
  includeRequiredFields = true,
  renderedFields,
  metadata,
}: PrepareRequestData) => {
  const credentialType = mapFormFieldValueToRequestData['type'](values.type);
  const isCustomCredentials =
    getApplicationPreference('ENABLE_CREDENTIAL_CUSTOM_TYPES') && credentialType === CredentialTypes.BigID;
  const metadataType =
    isCustomCredentials && values.subType?.length && mapFormFieldValueToRequestData['subType'](values.subType);
  const isBasicAuthentication = mapFormFieldValueToRequestData['subType'](values.subType) === MetadataType.BASIC;
  const metadataFields = metadataType
    ? metadata.find(({ type }) => type === metadataType)?.fields.map(({ name }) => name) ?? []
    : [];

  const data = Object.keys(values).reduce((accumulator, fieldName) => {
    if (!renderedFields.includes(fieldName)) {
      return accumulator;
    }

    const getValue = mapFormFieldValueToRequestData[fieldName as keyof CredentialItemResponse];
    const value = getValue ? getValue(values[fieldName]) : values[fieldName];
    const isCustomField = metadataFields.includes(fieldName);

    if (fieldName.endsWith('Identifier')) {
      const baseFieldName = fieldName.replace('Identifier', '');
      const isConstantFieldName = `${baseFieldName}IsConstant`;
      const isConstant = values[isConstantFieldName] === 'true' || values[isConstantFieldName] === true;

      if (getApplicationPreference('NEW_CREDENTIALS_FLOW_ENABLED') || isBasicAuthentication) {
        return {
          ...accumulator,
          ...(isConstant
            ? {
                credentialsConstants: {
                  ...accumulator.credentialsConstants,
                  [fieldName]: value,
                },
              }
            : {
                credentialsIdentifiers: {
                  ...accumulator.credentialsIdentifiers,
                  [fieldName]: value,
                },
              }),
        };
      }
    } else if (fieldName.endsWith('IsConstant')) {
      return { ...accumulator };
    } else if (isCustomCredentials && isCustomField) {
      return {
        ...accumulator,
        credentialFields: {
          ...accumulator.credentialFields,
          [fieldName]: value,
        },
      };
    } else {
      return {
        ...accumulator,
        [fieldName]: value,
      };
    }
  }, {} as BigidFormValues);

  const normalizedData = replaceAppWithInstanceIfPresent(data);

  return {
    ...(includeRequiredFields && {
      apps: [],
      actions: [],
      customQuery: '',
    }),
    ...normalizedData,
    ...(credentialType === CredentialTypes.RemoteCustom && {
      fetchFunctionRequestParams: values.fetchFunctionRequestParams,
    }),
  } as CredentialItemResponse;
};

const replaceAppWithInstanceIfPresent = (data: Record<string, any>): Record<string, any> => {
  return !data.instances || data.instances.length === 0 ? data : { ...data, apps: data.instances ?? data.apps };
};

export const prepareUpdateRequestData = (values: BigidFormValues, updatedFields: BooleanMap) => {
  const customFields = values.credentialFields ?? {};
  const updatedFieldKeys = new Set(Object.keys(updatedFields));

  const updatedCustomFields = Object.keys(customFields).filter(customFieldName =>
    updatedFieldKeys.has(customFieldName),
  );

  const identifierFields = values.credentialsIdentifiers ?? {};
  const constantsFields = values.credentialsConstants ?? {};

  const updatedIdentifierField = Object.keys(identifierFields).filter(customFieldName =>
    updatedFieldKeys.has(customFieldName),
  );

  const updatedConstantField = Object.keys(constantsFields).filter(customFieldName =>
    updatedFieldKeys.has(customFieldName),
  );

  const credentialsIdentifiers =
    updatedIdentifierField.length > 0
      ? updatedIdentifierField.reduce((accumulator, customFieldKey) => {
          return {
            ...accumulator,
            [customFieldKey]: identifierFields[customFieldKey],
          };
        }, {})
      : {};

  const credentialsConstants =
    updatedConstantField.length > 0
      ? updatedConstantField.reduce((accumulator, customFieldKey) => {
          return {
            ...accumulator,
            [customFieldKey]: constantsFields[customFieldKey],
          };
        }, {})
      : {};

  const credentialFields =
    updatedCustomFields.length > 0
      ? updatedCustomFields.reduce((accumulator, customFieldKey) => {
          return {
            ...accumulator,
            [customFieldKey]: values.credentialFields[customFieldKey],
          };
        }, {})
      : {};

  const restFields = Object.keys(values).filter(name => !customFields[name]);
  const fields = restFields.reduce<BigidFormValues>((accumulator, fieldName) => {
    const isFieldUpdated = updatedFieldKeys.has(fieldName);
    return {
      ...accumulator,
      ...(isFieldUpdated && { [fieldName]: values[fieldName] }),
    };
  }, {});

  const result: BigidFormValues = {
    ...fields,
    ...(Object.keys(credentialFields).length && { credentialFields }),
    ...(Object.keys(credentialsIdentifiers).length && { credentialsIdentifiers }),
    ...(Object.keys(credentialsConstants).length && { credentialsConstants }),
  };

  if (result.password) {
    result.isPasswordChanged = true;
  }

  return result;
};

export const prepareTestConnectionData = ({ values, renderedFields }: PrepareTestConnectionData) => {
  const data = prepareRequestData({ values, includeRequiredFields: false, renderedFields });
  delete data.scopes;
  return data;
};

export const getCredentialForNewIntegrationDropdowns = (filter: any) =>
  credentialsService.getCredentials(filter).then(
    ({ data = [] }) =>
      data.filter(({ subType }) => subType === CredentialType.BASIC).map(({ credential_id }) => credential_id),
    () => {
      notificationService.error('Failed to fetch credentials!');
    },
  );
