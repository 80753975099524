import React, { FC } from 'react';
import styled from '@emotion/styled';
import type { StyledButtonType } from '@bigid-ui/components';

export type SavedQueriesActionsProps = {
  buttons: (StyledButtonType & { component: FC<StyledButtonType> })[];
};

const Actions = styled.div`
  display: flex;
  height: 100%;
  align-items: center;
  gap: 0.5rem;
`;

export const SavedQueriesActions = ({ buttons }: SavedQueriesActionsProps): JSX.Element => (
  <Actions>
    {buttons.map(({ component: Component, ...rest }) => (
      <Component key={rest.dataAid} {...rest} />
    ))}
  </Actions>
);
