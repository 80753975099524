import { BigidDropdownValue, EntityEventPayload } from '@bigid-ui/components';
import { CuratedAttributeRowUpdatePayload } from '../../../CuratedFields/useCurationFieldsConfig';
import { CategoryDetails, LegacyCategoryDetails } from '../../../curationService';

export const mapCategoriesToDropdownValues = (categories: CategoryDetails[]): BigidDropdownValue => {
  if (Array.isArray(categories)) {
    return categories.map(category => {
      const { displayName, uniqueName } = category;

      return {
        value: category,
        id: uniqueName,
        displayValue: displayName,
      };
    });
  }

  return [];
};

export const mapLegacyCategoriesToDropdownValues = (categories: LegacyCategoryDetails[]) => {
  if (Array.isArray(categories)) {
    return categories.map(category => {
      const { display_name, glossary_id } = category;

      return {
        value: category,
        id: glossary_id,
        displayValue: display_name,
      };
    });
  }

  return [];
};

interface GetPayloadParams {
  attributeName: string;
  categories: CategoryDetails[];
  gridId: string;
}

export const getPayload = ({
  attributeName,
  gridId,
  categories,
}: GetPayloadParams): EntityEventPayload<CuratedAttributeRowUpdatePayload> => {
  return {
    entityId: gridId,
    payload: {
      rowId: attributeName,
      row: {
        categories,
      },
    },
  };
};
