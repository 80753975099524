import React, { ChangeEvent, FC } from 'react';
import { dateTimeService } from '@bigid-ui/i18n';
import { BigidSelect, BigidTextField } from '@bigid-ui/components';

export interface ServiceConfigurationValueControlProps {
  type: string;
  name: string;
  configurationValue: React.ReactText;
  setConfigurationValue: React.Dispatch<React.SetStateAction<React.ReactText>>;
}

export const UI_DATE_TIME_LOCALE_FEATURE_FLAG = 'UI_DATE_TIME_LOCALE';

export const ServiceConfigurationValueControl: FC<ServiceConfigurationValueControlProps> = ({
  type,
  name,
  configurationValue,
  setConfigurationValue,
}) => {
  const inputType = type === 'numeric' ? 'number' : 'text';
  const defaultValue = inputType === 'number' ? 0 : '';
  const placeholderText = 'New configuration value';
  const step = Number.isInteger(Number(configurationValue)) ? '1' : '0.01';
  const stringConfigurationValue = configurationValue.toString();
  const selectEmptyLabel = 'None';

  const onValueChange = (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>): void => {
    setConfigurationValue(event.currentTarget.value || defaultValue);
  };

  if (type === 'boolean') {
    return (
      <BigidSelect
        name={name}
        placeholder={placeholderText}
        value={[{ label: stringConfigurationValue, value: configurationValue }]}
        options={['true', 'false'].map(option => ({ label: option, value: option }))}
        onChange={([option]) => setConfigurationValue(option.value)}
      />
    );
  }

  if (type === UI_DATE_TIME_LOCALE_FEATURE_FLAG) {
    return (
      <BigidSelect
        name={name}
        placeholder={placeholderText}
        value={[
          {
            label: stringConfigurationValue === '' ? selectEmptyLabel : stringConfigurationValue,
            value: configurationValue,
          },
        ]}
        options={['', ...dateTimeService.getAvailableLocales()].map(option => ({
          label: option === '' ? selectEmptyLabel : option,
          value: option,
        }))}
        onChange={([option]) => setConfigurationValue(option.value)}
        isSearchable
      />
    );
  }

  return (
    <BigidTextField
      onChange={onValueChange}
      placeholder={placeholderText}
      defaultValue={stringConfigurationValue}
      type={inputType}
      inputProps={{ step }}
    />
  );
};
