import React, { useCallback, useState } from 'react';
import { BigidDialog, EntityEvents, PrimaryButton, SecondaryButton, entityEventsEmitter } from '@bigid-ui/components';
import { LegalEntitiesDeleteDetails } from './LegalEntitiesTypes';
import { deleteLegalEntity } from './LegalEntitiesService';
import { notificationService } from '../../services/notificationService';
import styled from '@emotion/styled';
import { LegalEntitiesTrackingEvents } from './utils/analytics';

const ContentWrapper = styled.div({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'start',
  fontWeight: 400,
  fontSize: '14px',
});

export interface LegalEntitiesDeleteDialogProps {
  selectedLegalEntity: LegalEntitiesDeleteDetails;
  isOpen: boolean;
  onClose?: () => void;
  onDelete?: () => void;
}

export interface LegalEntitiesDeleteDialog {
  deleteDialogProps: LegalEntitiesDeleteDialogProps;
  openDeleteDialog: (selectedVendor: LegalEntitiesDeleteDetails) => boolean;
}

const initialDeleteProps: LegalEntitiesDeleteDialogProps = {
  isOpen: false,
  selectedLegalEntity: {
    id: '',
    name: '',
  },
};

export const useLegalEntitiesDeleteDialog = (): LegalEntitiesDeleteDialog => {
  const [deleteDialogProps, setDeleteDialogProps] = useState<LegalEntitiesDeleteDialogProps>(initialDeleteProps);

  const openDeleteDialog = useCallback<LegalEntitiesDeleteDialog['openDeleteDialog']>(selectedLegalEntity => {
    setDeleteDialogProps({
      isOpen: true,
      selectedLegalEntity: selectedLegalEntity,
      onDelete: () => {
        setDeleteDialogProps(initialDeleteProps);
        entityEventsEmitter.emit(EntityEvents.RELOAD);
      },
      onClose: () => setDeleteDialogProps(initialDeleteProps),
    });
    return true;
  }, []);

  return {
    deleteDialogProps,
    openDeleteDialog,
  };
};

export const LegalEntitiesDeleteDialog = ({
  selectedLegalEntity,
  isOpen,
  onClose,
  onDelete,
}: LegalEntitiesDeleteDialog['deleteDialogProps']) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const onCloseButtonClicked = () => {
    onClose();
    setIsLoading(false);
  };

  const onDeleteButtonClicked = useCallback(async () => {
    setIsLoading(true);
    try {
      await deleteLegalEntity(selectedLegalEntity.id);
      onDelete();
    } catch (error) {
      const errorMessage = `Failed to delete Legal entity : ${error?.message}`;
      notificationService.error(JSON.stringify(errorMessage));
      console.error(`${JSON.stringify(errorMessage)}`);
    } finally {
      setIsLoading(false);
    }
  }, [selectedLegalEntity, onDelete]);

  return (
    <BigidDialog
      title="Delete legal entity?"
      showCloseIcon
      isLoading={isLoading}
      borderTop
      maxWidth="xs"
      isOpen={isOpen}
      onClose={onCloseButtonClicked}
      buttons={[
        {
          component: props => (
            <SecondaryButton
              {...props}
              bi={{
                eventType: LegalEntitiesTrackingEvents.DELETE_LEGAL_ENTITY_DIALOG_CANCEL_CLICK,
              }}
            />
          ),
          onClick: onCloseButtonClicked,
          text: 'Cancel',
        },
        {
          component: props => (
            <PrimaryButton
              {...props}
              bi={{
                eventType: LegalEntitiesTrackingEvents.DELETE_LEGAL_ENTITY_DIALOG_DELETE_CLICK,
              }}
            />
          ),
          onClick: onDeleteButtonClicked,
          text: 'Delete',
        },
      ]}
    >
      <ContentWrapper>{`Are you sure you want to delete "${selectedLegalEntity.name}"?`}</ContentWrapper>
    </BigidDialog>
  );
};
