import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { styled } from '@mui/material';
import { ActionData, BigidColorsV2, ToolbarActionType } from '@bigid-ui/components';
import { DataSource, SarProfile } from '../ProfileSettingsTypes';
import { BigidGridColumnTypes, BigidGridWithToolbar, BigidGridWithToolbarProps, BigidGridColumn } from '@bigid-ui/grid';
import { sarConfigService } from '../sarConfigService';
import { notificationService } from '../../../../services/notificationService';
import { getCellValueForConnectionStatus, getCellValueForIncludeExcludeV2 } from '../gridCellValues';
import { EditDataSourceDialog, JIT_DISCOVERY_TOOLTIP_TEXT, updateSources } from './EditDataSourcesDialog';
import { useEditDataSourceDialog } from './useEditDataSourceDialog';
import { EnableAll } from './EnableAllV2';
import { DSAR_PERMISSIONS } from '@bigid/permissions';
import { isPermitted } from '../../../../services/userPermissionsService';
import { useUserPreferences } from '../../../../components/hooks/useUserPrefrences';
import { OnOffCellValue, SarProfileSourceTypes, NEW_DSAR_SETTINGS_STATE_NAMES } from '../SarProfileSettingsTypes';
import { BigidAddContainedIcon, BigidEditIcon, BigidRemoveIcon, BigidWarningIcon } from '@bigid-ui/icons';
import makeStyles from '@mui/styles/makeStyles';
import { BigidFilterContainer } from '@bigid-ui/layout';
import { DataSourceLayoutNameCell } from '../../../DataSources/DataSourceConnections/DataSourceLayoutNameCell';
import { DataSourceModel } from '../../../DataSources/DataSourceConnections/DataSourceConnectionTypes';

const GridWrapper = styled('div')`
  background-color: ${BigidColorsV2.white};
  border-radius: 4px;
  border: 1px solid ${BigidColorsV2.gray[300]};
  width: 100%;
  display: flex;
  position: relative;
  height: 100%;
  max-height: 100%;
  overflow: hidden;
`;

type Grid = BigidGridWithToolbarProps<DataSource>;
type DataSourcesProps = Pick<SarProfile, '_id' | 'allEnabledDs' | 'dsConnections' | 'hasSelectedDs'>;
const getColumns = (isSelectionColumnHidden: boolean): BigidGridColumn<DataSource>[] => [
  {
    title: 'Data Source',
    name: 'name',
    type: BigidGridColumnTypes.CUSTOM,
    filteringEnabled: false,
    sortingEnabled: true,
    getCellValue: ({ name, type }) => (
      <DataSourceLayoutNameCell row={{ id: name, type: type, displayType: name } as DataSourceModel} iconWidth={24} />
    ),
  },
  {
    title: 'Selection',
    name: 'profileEnabled',
    isHiddenByDefault: isSelectionColumnHidden,
    width: 130,
    type: BigidGridColumnTypes.CHIP,
    filteringEnabled: false,
    getCellValue: ({ profileEnabled }) => getCellValueForIncludeExcludeV2(profileEnabled),
  },
  {
    title: 'Enabled by Admin',
    name: 'enabledByAdmin',
    width: 180,
    type: BigidGridColumnTypes.STATUS,
    filteringEnabled: false,
    getCellValue: ({ sourceEnabled }) => {
      return {
        text: sourceEnabled ? 'Enabled' : 'Disabled',
        status: sourceEnabled === false ? 'warning' : undefined,
      };
    },
  },
  {
    title: 'Last Test',
    name: 'connectionStatusTest.is_success',
    width: 200,
    type: BigidGridColumnTypes.STATUS,
    filteringEnabled: false,
    getCellValue: ({ connectionStatusTest }) => getCellValueForConnectionStatus(connectionStatusTest),
  },
  {
    title: 'Last Scan',
    name: 'connectionStatusScan.is_success',
    width: 200,
    type: BigidGridColumnTypes.STATUS,
    filteringEnabled: false,
    getCellValue: ({ connectionStatusScan }) => getCellValueForConnectionStatus(connectionStatusScan),
  },
  {
    title: 'Case Sensitive',
    name: 'isCaseInSensitive',
    width: 170,
    type: BigidGridColumnTypes.TEXT,
    filteringEnabled: false,
    getCellValue: ({ isCaseInSensitiveSupported, isCaseInSensitive }) =>
      isCaseInSensitiveSupported ? (isCaseInSensitive ? OnOffCellValue.OFF : OnOffCellValue.ON) : OnOffCellValue.NA,
  },
  {
    title: 'Linked Tables',
    name: 'isReferentialIntegrity',
    width: 170,
    type: BigidGridColumnTypes.TEXT,
    filteringEnabled: false,
    getCellValue: ({ isReferentialIntegritySupported, isReferentialIntegrity }) =>
      isReferentialIntegritySupported
        ? isReferentialIntegrity
          ? OnOffCellValue.ON
          : OnOffCellValue.OFF
        : OnOffCellValue.NA,
  },
  {
    title: 'Exact Match',
    name: 'isExactMatch',
    width: 170,
    type: BigidGridColumnTypes.TEXT,
    filteringEnabled: false,
    getCellValue: ({ isExactMatchSupported, isExactMatch }) =>
      isExactMatchSupported ? (isExactMatch ? OnOffCellValue.ON : OnOffCellValue.OFF) : OnOffCellValue.NA,
  },
  {
    title: 'Attribute Discovery',
    name: 'isJitRecordDiscovery',
    width: 170,
    type: BigidGridColumnTypes.TEXT,
    filteringEnabled: false,
    tooltip: {
      value: JIT_DISCOVERY_TOOLTIP_TEXT,
    },
    getCellValue: ({ isJitRecordDiscoverySupported, isJitRecordDiscovery }) =>
      isJitRecordDiscoverySupported
        ? isJitRecordDiscovery
          ? OnOffCellValue.ON
          : OnOffCellValue.OFF
        : OnOffCellValue.NA,
  },
];

const useStyles = makeStyles({
  warning: {
    padding: '6px 0 16px',
    fontSize: 14,
    display: 'flex',
    gap: 8,
    alignItems: 'center',
  },
});

export const DataSources = ({ _id: profileId, hasSelectedDs, dsConnections }: DataSourcesProps) => {
  const [isDsIncluded, setIsDsIncluded] = useState<boolean>(hasSelectedDs);
  const [allEnabledDs, setAllEnabled] = useState(undefined);
  const [shouldRestFilters, setResetFilters] = useState(false);
  const classes = useStyles({});

  const handleAllEnabled = (isEnabled: boolean) => {
    setAllEnabled(isEnabled);
    setResetFilters(true);
  };

  useEffect(() => {
    setResetFilters(false);
  }, [shouldRestFilters]);

  const editDataSourceDialogHook = useEditDataSourceDialog();

  const getInitialFilterToolbarConfig = useCallback(() => getFilterConfig(dsConnections), [dsConnections]);
  const initialGridColumns = useMemo(() => getColumns(allEnabledDs), [allEnabledDs]);
  const {
    isReady,
    preferences,
    gridColumns,
    filterToolbarConfig,
    key: gridKey,
    updatePreferences,
  } = useUserPreferences({
    stateName: NEW_DSAR_SETTINGS_STATE_NAMES.DS,
    initialGridColumns,
    getInitialFilterToolbarConfig,
    externalFilter: shouldRestFilters ? [] : undefined,
  });

  const gridWithToolbarConfig: Grid = useMemo(() => {
    const includeExcludeExecutor = async (enabled: boolean, { selectedRows }: ActionData) => {
      if (!selectedRows.length) return {};
      await updateSources(profileId, { enabled }, selectedRows);
      return { shouldGridReload: true, shouldClearSelection: true };
    };

    const gridWithToolbarConfig: Grid = {
      columns: gridColumns,
      entityName: 'Data Sources',
      showSortingControls: true,
      showFilteringControls: true,
      defaultSorting: preferences?.grid?.sort || [{ field: 'name', order: 'asc' }],
      onGridStateChange: ({ filter, ...gridState }) => updatePreferences({ filterState: { filter }, gridState }),
      toolbarActions: [
        {
          label: 'Include',
          type: ToolbarActionType.TERTIARY,
          icon: BigidAddContainedIcon,
          execute: actionData => includeExcludeExecutor(true, actionData),
          disable: ({ selectedRows }) => allEnabledDs || !selectedRows.length,
          show: ({ totalRows }) => !!totalRows && isPermitted(DSAR_PERMISSIONS.EDIT_PROFILE_SETTINGS.name),
        },
        {
          label: 'Exclude',
          type: ToolbarActionType.TERTIARY,
          icon: BigidRemoveIcon,
          execute: actionData => includeExcludeExecutor(false, actionData),
          disable: ({ selectedRows }) => allEnabledDs || !selectedRows.length,
          show: ({ totalRows }) => !!totalRows && isPermitted(DSAR_PERMISSIONS.EDIT_PROFILE_SETTINGS.name),
        },
        {
          label: 'Edit',
          type: ToolbarActionType.TERTIARY,
          icon: BigidEditIcon,
          execute: async ({ selectedRows }) => {
            if (!selectedRows.length) return {};
            const actionResult = await editDataSourceDialogHook.openDialog({
              profileId,
              sources: selectedRows,
              isSelectionDisabled: allEnabledDs,
            });
            return { shouldGridReload: actionResult, shouldClearSelection: actionResult };
          },
          disable: ({ selectedRows }) => !selectedRows.length,
          show: ({ totalRows }) => !!totalRows && isPermitted(DSAR_PERMISSIONS.EDIT_PROFILE_SETTINGS.name),
        },
      ],
      pageSize: 5000,
      filterToolbarConfig,
      fetchData: async gridQueryParams => {
        let dsConnections: DataSource[] = [];
        try {
          const profile = await sarConfigService.getProfileById(profileId, gridQueryParams, {
            excludeEsConnections: true,
          });
          dsConnections = profile.dsConnections;
          setIsDsIncluded(profile.hasSelectedDs);
          setAllEnabled(profile.allEnabledDs);
        } catch (err) {
          notificationService.error(`Failed to get Data Sources.`);
          console.error(`Failed to get SAR Request profile by id ${profileId}: ${JSON.stringify(err)}`);
        }

        return {
          data: dsConnections,
          totalCount: dsConnections.length,
        };
      },
    };

    return gridWithToolbarConfig;
  }, [
    gridColumns,
    preferences,
    updatePreferences,
    editDataSourceDialogHook,
    profileId,
    allEnabledDs,
    filterToolbarConfig,
  ]);

  return (
    <>
      <EditDataSourceDialog sourceType={SarProfileSourceTypes.DS} {...editDataSourceDialogHook.dialogProps} />
      {isPermitted(DSAR_PERMISSIONS.MANAGE_ALL_ENABLED_PROFILE_SETTINGS.name) || allEnabledDs ? (
        <BigidFilterContainer.Provider
          initialState={{
            isQueryLanguage: null,
            initialSearchQuery: null,
            useExperimentalQuery: null,
          }}
        >
          <EnableAll
            key={`EnableAll_${profileId}`}
            sourceType={SarProfileSourceTypes.DS}
            profileId={profileId}
            isAllEnabled={allEnabledDs}
            onAllEnabled={handleAllEnabled}
          />
        </BigidFilterContainer.Provider>
      ) : null}
      {isReady && !allEnabledDs && typeof allEnabledDs !== 'undefined' && !isDsIncluded && (
        <div className={classes.warning}>
          <BigidWarningIcon />
          <span>At least 1 Data Source should be selected in order to perform a DSAR scan.</span>
        </div>
      )}
      {isReady ? (
        <GridWrapper>
          <BigidGridWithToolbar {...gridWithToolbarConfig} key={gridKey} />
        </GridWrapper>
      ) : null}
    </>
  );
};

const getFilterConfig = async (dsConnections: DataSource[] = []): Promise<Grid['filterToolbarConfig']> => {
  return {
    filters: [
      {
        title: 'Status',
        field: 'enabled',
        operator: 'equal',
        value: [],
        single: true,
        options: [
          { value: 'yes', label: 'Enable', isSelected: false },
          { value: 'no', label: 'Disable', isSelected: false },
        ],
      },
      {
        title: 'Selection',
        field: 'profileEnabled',
        operator: 'equal',
        value: [],
        single: true,
        options: [
          { value: true, label: 'Include', isSelected: false },
          { value: false, label: 'Exclude', isSelected: false },
        ],
      },
      {
        title: 'Data Source',
        field: 'name',
        listWidth: 400,
        operator: 'in',
        value: [],
        options: dsConnections.map(ds => ({ value: ds.name, label: ds.name, isSelected: false })),
      },
      {
        title: 'Last Test',
        field: 'connectionStatusTest.is_success',
        operator: 'in',
        disabled: true,
        single: true,
        value: [],
        options: [
          {
            label: 'Success',
            value: true,
            isSelected: false,
          },
          {
            label: 'Failed',
            value: false,
            isSelected: false,
          },
        ],
      },
      {
        title: 'Last Scan',
        field: 'connectionStatusScan.is_success',
        operator: 'in',
        disabled: true,
        single: true,
        value: [],
        options: [
          {
            label: 'Success',
            value: true,
            isSelected: false,
          },
          {
            label: 'Failed',
            value: false,
            isSelected: false,
          },
        ],
      },
    ],
    searchConfig: {
      searchFilterKeys: ['name'],
      initialValue: '',
      operator: 'textSearch',
    },
  };
};
