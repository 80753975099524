import React, { ChangeEvent, FunctionComponent, useEffect, useMemo, useState } from 'react';
import { Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import {
  BigidEditableTextArea,
  BigidExtraField,
  BigidExtraFieldLabelPosition,
  BigidObjectDetailsSection,
  BigidPrimaryCheckbox,
  BigidSelect,
  BigidSelectOption,
  BigidTextField,
  ExtraFieldItemData,
} from '@bigid-ui/components';
import { notificationService } from '../../../../services/notificationService';
import { LabelConfigurationRecord } from './LabelsConfigurations';
import { getLabelById, updateLabel } from './LabelConfigurationService';
import { v4 as uuid } from 'uuid';
import { isEqual, noop } from 'lodash';
import { isPermitted } from '../../../../services/userPermissionsService';
import { APPLICATIONS_PERMISSIONS } from '@bigid/permissions';

export type ComparisonSigns = 'Greater' | 'Greater or Equal' | 'Less' | 'Less or Equal' | 'Equal';
export type ExternalType = 'External User' | 'Internal User' | 'External Link' | 'Internal Link';

const DEFAULT_OPEN_ACCESS_NAME = 'Open Access';

const operandsSigns = [
  {
    label: 'None',
    value: null,
  },
  {
    label: '>',
    value: 'greater',
  },
  {
    label: '>=',
    value: 'greaterOrEqual',
  },
  {
    label: '<',
    value: 'less',
  },
  {
    label: '=<',
    value: 'lessOrEqual',
  },
];

const useStyles = makeStyles({
  wrapper: {
    display: 'flex',
    flexFlow: 'column nowrap',
    width: '100%',
    overflow: 'auto',
    flex: '1 1 auto',
  },
  userCountText: {
    marginLeft: 8,
    marginRight: 8,
  },
  sectionContainer: {
    marginBottom: '24px',
    width: '40%',
    minWidth: '600px',
    margin: 32,
  },
  checkboxesWrapper: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  userNumberElement: {
    minWidth: 85,
    marginRight: 12,
  },
  userNumberContainer: {
    display: 'flex',
    minWidth: '600px',
    alignItems: 'center',
    margin: '32px 6px 24px 0',
  },
  directUserTitle: {
    paddingRight: 12,
    paddingTop: 6,
  },
});

const initLabelGroupItem = (value: string): ExtraFieldItemData => ({
  id: uuid(),
  value: value,
});
const defaultUserOperand = operandsSigns.filter(({ value }) => value === null);

export const LabelDetails: FunctionComponent<LabelConfigurationRecord> = ({ label_name: labelName }) => {
  const classes = useStyles({});
  const [labelData, setLabelData] = useState<LabelConfigurationRecord>({ id: '', label_name: '', name: '' });
  const [labelGroups, setLabelGroups] = useState<ExtraFieldItemData[]>([]);
  const [selectedUsersOperand, setSelectedUsersOperand] = useState<BigidSelectOption[]>(defaultUserOperand);
  const [notes, setNotes] = useState<string>('');
  const isRbacPermitted = isPermitted(APPLICATIONS_PERMISSIONS.EDIT_ACCESS_TYPES_IN_ACCESS_INTELLIGENCE.name);

  const isEditable = useMemo(() => isRbacPermitted, [isRbacPermitted]);

  useEffect(() => {
    const getLabel = async () => {
      try {
        const label = await getLabelById(labelName);
        setNotes(label.notes || '');
        const selectedOperandOption = operandsSigns.filter(({ value }) => value === label.users_operand);
        setSelectedUsersOperand(selectedOperandOption || defaultUserOperand);
        setLabelData({ ...label });
        setLabelGroups(
          label.groups?.length ? label.groups.map(group => initLabelGroupItem(group)) : [{ id: uuid(), value: '' }],
        );
      } catch (err) {
        console.log(`error while trying to get access type: ${err.message}`);
      }
    };
    getLabel();
  }, [labelName]);

  const handleUpdateNotes = async (updatedNotes: string) => {
    try {
      const newLabelData = { ...labelData, notes: updatedNotes, label_name: labelName };
      setLabelData(newLabelData);
      updateLabel(newLabelData);
      notificationService.success('Access type has been saved');
    } catch (err) {
      notificationService.error('An error has occurred');
      window.console.error(`An error has occurred: ${err.message}`);
    }
  };

  const handleExternalUserChanged = async () => {
    try {
      const newLabelData = { ...labelData, external_user: !labelData.external_user, label_name: labelName };
      setLabelData(newLabelData);
      updateLabel(newLabelData);
      notificationService.success('Access type has been saved');
    } catch (err) {
      console.error('Failed to save access type', err.message);
      notificationService.error('Failed to save access type');
    }
  };

  const handleExternalGroupChanged = async () => {
    try {
      const newLabelData = { ...labelData, external_group: !labelData.external_group, label_name: labelName };
      setLabelData(newLabelData);
      updateLabel(newLabelData);
      notificationService.success('Access type has been saved');
    } catch (err) {
      console.error('Failed to save access type', err.message);
      notificationService.error('Failed to save access type');
    }
  };

  const handleInternalGroupChanged = async () => {
    try {
      const newLabelData = { ...labelData, internal_group: !labelData.internal_group, label_name: labelName };
      setLabelData(newLabelData);
      updateLabel(newLabelData);
      notificationService.success('Access type has been saved');
    } catch (err) {
      console.error('Failed to save access type', err.message);
      notificationService.error('Failed to save access type');
    }
  };

  const handleGlobalGroupChanged = async () => {
    try {
      const newLabelData = { ...labelData, global_group: !labelData.global_group, label_name: labelName };
      setLabelData(newLabelData);
      updateLabel(newLabelData);
      notificationService.success('Access type has been saved');
    } catch (err) {
      console.error('Failed to save access type', err.message);
      notificationService.error('Failed to save access type');
    }
  };

  const handleOrganizationGroupChanged = async () => {
    try {
      const newLabelData = { ...labelData, organization_group: !labelData.organization_group, label_name: labelName };
      setLabelData(newLabelData);
      updateLabel(newLabelData);
      notificationService.success('Access type has been saved');
    } catch (err) {
      console.error('Failed to save access type', err.message);
      notificationService.error('Failed to save access type');
    }
  };

  const handleInternalUserChanged = async () => {
    try {
      const newLabelData = { ...labelData, internal_user: !labelData.internal_user, label_name: labelName };
      setLabelData(newLabelData);
      updateLabel(newLabelData);
      notificationService.success('Access type has been saved');
    } catch (err) {
      console.error('Failed to save access type: ', err.message);
      notificationService.error('Failed to save access type');
    }
  };

  const handleExternalLinkChanged = async () => {
    try {
      const newLabelData = { ...labelData, external_link: !labelData.external_link, label_name: labelName };
      setLabelData(newLabelData);
      updateLabel(newLabelData);
      notificationService.success('Access type has been saved');
    } catch (err) {
      console.error('Failed to save access type: ', err.message);
      notificationService.error('Failed to save access type');
    }
  };

  const handleInternalLinkChanged = async () => {
    try {
      const newLabelData = { ...labelData, internal_link: !labelData.internal_link, label_name: labelName };
      setLabelData(newLabelData);
      await updateLabel(newLabelData);
      notificationService.success('Access type has been saved');
    } catch (err) {
      console.error('Failed to save access type: ', err.message);
      notificationService.error('Failed to save access type');
    }
  };

  const handleLabelGroupsChanged = async () => {
    try {
      const newLabelData = {
        ...labelData,
        groups: labelGroups.map(labelGroup => labelGroup.value),
        label_name: labelName,
      };
      setLabelData(newLabelData);
      await updateLabel(newLabelData);
      notificationService.success('Groups have been saved');
    } catch (err) {
      console.error('Failed to groups type: ', err.message);
      notificationService.error('Failed to save groups');
    }
  };

  const handleOnChange = (selected: BigidSelectOption[]) => {
    const { value } = selected[0];
    const newLabelData = {
      ...labelData,
      users_operand: value,
      label_name: labelName,
      ...(value === null && { users_count: null }),
    };
    setLabelData(newLabelData);
    updateLabel(newLabelData);
    setSelectedUsersOperand(selected);
  };

  const handleChangeUserNumber = ({ target }: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>): void => {
    const newLabelData = { ...labelData, users_count: +target.value, label_name: labelName };
    setLabelData(newLabelData);
    updateLabel(newLabelData);
  };

  const createLabelGroup = () => {
    setLabelGroups(prevLabelGroups => [...prevLabelGroups, { id: uuid(), value: '' }]);
  };

  const deleteLabelGroup = async (id: string) => {
    try {
      const newLabelGroupsData = labelGroups.filter(group => group.id !== id);
      const newLabelData = {
        ...labelData,
        groups: newLabelGroupsData.map(labelGroup => labelGroup.value),
        label_name: labelName,
      };
      setLabelGroups(newLabelGroupsData);
      setLabelData(newLabelData);
      await updateLabel(newLabelData);
      notificationService.success('Group has been deleted');
    } catch (err) {
      console.error('Failed to delete group type: ', err.message);
      notificationService.error('Failed to delete group');
    }
  };

  const changeLabelGroup = (id: string, value: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    setLabelGroups(
      labelGroups.map(labelGroup => {
        return labelGroup.id === id ? { ...labelGroup, value: value.target.value.trim() } : labelGroup;
      }),
    );
  };

  const handleOnItemBlur = (id: string) => {
    const activeLabelGroup = labelGroups.find(labelGroup => labelGroup.id === id);
    if (
      activeLabelGroup.value &&
      !isEqual(
        labelData?.groups,
        labelGroups.map(labelGroup => labelGroup.value),
      )
    ) {
      handleLabelGroupsChanged();
    }
  };

  const isOpenAccess = () => {
    return labelData?.label_name?.toLowerCase() === DEFAULT_OPEN_ACCESS_NAME.toLowerCase();
  };

  const handleGeneralOnClick = () => {
    if (!isRbacPermitted) {
      notificationService.warning(`You have no permission to edit Access Types`);
    } else if (isRbacPermitted && !isEditable) {
      notificationService.info(`This field is immutable on default Access Type: ${labelData.label_name}`);
    }
  };

  return (
    <div className={classes.wrapper}>
      <div className={classes.sectionContainer}>
        <BigidObjectDetailsSection title="Users View">
          <div onClick={handleGeneralOnClick} className={classes.checkboxesWrapper}>
            <BigidPrimaryCheckbox
              label="External User"
              checked={labelData.external_user}
              onChange={handleExternalUserChanged}
              size="small"
              disabled={!isEditable}
            />
            <BigidPrimaryCheckbox
              label="Internal User"
              checked={labelData.internal_user}
              onChange={handleInternalUserChanged}
              size="small"
              disabled={!isEditable}
            />
          </div>
          <div className={classes.userNumberContainer}>
            <Typography variant={'body1'} className={classes.userCountText}>
              User Count
            </Typography>
            <div className={classes.userNumberElement}>
              <BigidSelect
                menuPosition="absolute"
                menuPlacement="bottom"
                options={operandsSigns}
                onChange={handleOnChange}
                value={selectedUsersOperand}
                isDisabled={!isEditable}
              />
            </div>
            <div className={classes.userNumberElement}>
              <BigidTextField
                type="number"
                value={String(labelData.users_count)}
                onChange={handleChangeUserNumber}
                disabled={!isEditable || selectedUsersOperand[0].value === null}
              />
            </div>
          </div>
        </BigidObjectDetailsSection>
      </div>
      <div onClick={isOpenAccess() ? noop : handleGeneralOnClick} className={classes.sectionContainer}>
        <BigidObjectDetailsSection title="Group Access">
          <BigidPrimaryCheckbox
            label="Global Group"
            checked={labelData.global_group}
            onChange={handleGlobalGroupChanged}
            size="small"
            disabled={!isEditable}
          />
          <BigidPrimaryCheckbox
            label="Organization Group"
            checked={labelData.organization_group}
            onChange={handleOrganizationGroupChanged}
            size="small"
            disabled={!isEditable}
          />
          <BigidPrimaryCheckbox
            label="External Group"
            checked={labelData.external_group}
            onChange={handleExternalGroupChanged}
            size="small"
            disabled={!isEditable}
          />
          <BigidPrimaryCheckbox
            label="Internal Group"
            checked={labelData.internal_group}
            onChange={handleInternalGroupChanged}
            size="small"
            disabled={!isEditable}
          />
          <br />
          <BigidExtraField
            fieldComponent={BigidTextField}
            data={labelGroups}
            labelPosition={BigidExtraFieldLabelPosition.left}
            label={'Group'}
            fieldsBottomOffset={12}
            onChangeItem={changeLabelGroup}
            onDelete={deleteLabelGroup}
            onAddNew={createLabelGroup}
            onBlurItem={handleOnItemBlur}
            alignCenter
            disabled={!isEditable && !isOpenAccess()}
          />
        </BigidObjectDetailsSection>
      </div>
      <div className={classes.sectionContainer}>
        <BigidObjectDetailsSection title="Domain Access">
          <div onClick={handleGeneralOnClick} className={classes.checkboxesWrapper}>
            <BigidPrimaryCheckbox
              label="External Link"
              checked={labelData.external_link}
              onChange={handleExternalLinkChanged}
              size="small"
              disabled={!isEditable}
            />
            <BigidPrimaryCheckbox
              label="Internal Link"
              checked={labelData.internal_link}
              onChange={handleInternalLinkChanged}
              size="small"
              disabled={!isEditable}
            />
          </div>
        </BigidObjectDetailsSection>
      </div>
      <div className={classes.sectionContainer}>
        <BigidObjectDetailsSection title="Notes">
          <BigidEditableTextArea
            value={notes}
            onSubmit={handleUpdateNotes}
            isInlineEdit={true}
            isMultiLineMode={true}
            controlsPlacement={['right', 'center']}
            placeholder={isEditable ? 'Click to edit notes' : undefined}
            isDisabled={!isEditable}
          />
        </BigidObjectDetailsSection>
      </div>
    </div>
  );
};
