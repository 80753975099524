import { Filters } from '../../../../types/actionableInsights';
import { ActionsDialogTypes } from '../../ActionableInsightsGridViews/CaseActionsModal/hooks/useCaseActions';
import { CaseModel } from '../../actionableInsightsService';
import { CaseReportAffectedObjects } from '../CaseReport/caseReportService';
import { CaseSidePanelViewsIds } from '../CaseSidePanelViews';
import { uniqBy } from 'lodash';

export enum ReducerActions {
  'ADD_INITIAL_ACTIONS',
  'UPDATE_SIDE_PANEL_DATA',
  'UPDATE_SELECTED_OBJECT',
  'UPDATE_ACTIVE_VIEW_ID',
  'UPDATE_ACTIONS',
  'RESET_ACTIONS',
  'UPDATE_SELECTED_ITEMS',
  'UPDATE_ENABLE_CASE_LEVEL_ACTIONS',
  'UPDATE_FINDINGS_WIDGET_VISIBILITY',
  'UPDATE_ATTRIBUTES_WIDGET_VISIBILITY',
  'UPDATE_VIOLATION_WIDGET_VISIBILITY',
  'UPDATE_SENSITIVITY_WIDGET_VISIBILITY',
  'UPDATE_WIDGETS_ERROR_STATUS',
  'UPDATE_AMOUNT_OF_FINDINGS',
  'RESET_WIDGETS_FLAGS_STATUS',
  'INIT',
}

export interface CaseSidePanelReducerAction {
  type: ReducerActions;
  payload: Partial<CaseSidePanelReducerState>;
}

type CurrenCaseStatus = 'COMPLETED' | 'FAILED' | 'IN_PROGRESS';

export interface CaseActionProps {
  status: CurrenCaseStatus;
  isBlocked: boolean;
  created_at: Date;
  estimatedRemainingTimeMinutes: number;
}

export interface ActionsListDataProps {
  actionName: string;
  command: string;
  subType: string;
  latestExecution: CaseActionProps;
}

export interface ActionsListProps {
  [key: string]: ActionsListDataProps;
}

export interface CaseSidePanelReducerState {
  caseSidePanelData: CaseModel;
  objectFullyQualifiedName: string;
  objectSensitivity: string;
  objectType: string;
  activeViewId: CaseSidePanelViewsIds;
  actionsList?: ActionsListProps;
  selectedObjects?: CaseReportAffectedObjects[];
  isFindingsWidgetsVisible?: boolean;
  isAttributesWidgetVisible?: boolean;
  isViolationWidgetVisible?: boolean;
  isSensitivityWidgetVisible?: boolean;
  amountOfFindings: number;
  hasWidgetsErrors?: boolean;
  onCaseAction: (modifiedValue?: string, filters?: Filters, modalType?: ActionsDialogTypes) => void;
  enableActionsOnCaseLevel: boolean;
}

export const caseSidePanelReducer: React.Reducer<CaseSidePanelReducerState, CaseSidePanelReducerAction> = (
  state,
  { type, payload },
) => {
  switch (type) {
    case ReducerActions.UPDATE_SIDE_PANEL_DATA: {
      const { caseSidePanelData } = payload;
      return { ...state, caseSidePanelData };
    }

    case ReducerActions.UPDATE_SELECTED_OBJECT: {
      const { objectFullyQualifiedName, objectSensitivity, objectType } = payload;
      return { ...state, objectFullyQualifiedName, objectSensitivity, objectType };
    }

    case ReducerActions.UPDATE_ACTIVE_VIEW_ID: {
      const { activeViewId } = payload;
      return { ...state, activeViewId };
    }

    case ReducerActions.ADD_INITIAL_ACTIONS: {
      const { actionsList } = payload;
      return { ...state, actionsList };
    }

    case ReducerActions.UPDATE_ACTIONS: {
      const { actionsList } = payload;
      return { ...state, actionsList: { ...state.actionsList, ...actionsList } };
    }

    case ReducerActions.RESET_ACTIONS: {
      return { ...state, actionsList: {} };
    }

    case ReducerActions.UPDATE_SELECTED_ITEMS: {
      const { selectedObjects } = payload;
      const allObjects = uniqBy([...state.selectedObjects, ...selectedObjects], 'id');
      return { ...state, selectedObjects: allObjects };
    }

    case ReducerActions.UPDATE_ENABLE_CASE_LEVEL_ACTIONS: {
      const { enableActionsOnCaseLevel } = payload;
      return { ...state, enableActionsOnCaseLevel };
    }

    case ReducerActions.UPDATE_ATTRIBUTES_WIDGET_VISIBILITY: {
      const { isAttributesWidgetVisible } = payload;
      return { ...state, isAttributesWidgetVisible };
    }

    case ReducerActions.UPDATE_SENSITIVITY_WIDGET_VISIBILITY: {
      const { isSensitivityWidgetVisible } = payload;
      return { ...state, isSensitivityWidgetVisible };
    }

    case ReducerActions.UPDATE_FINDINGS_WIDGET_VISIBILITY: {
      const { isFindingsWidgetsVisible } = payload;
      return { ...state, isFindingsWidgetsVisible };
    }

    case ReducerActions.UPDATE_VIOLATION_WIDGET_VISIBILITY: {
      const { isViolationWidgetVisible } = payload;
      return { ...state, isViolationWidgetVisible };
    }

    case ReducerActions.UPDATE_WIDGETS_ERROR_STATUS: {
      const { hasWidgetsErrors } = payload;
      if (state.hasWidgetsErrors) {
        return { ...state };
      } else {
        return { ...state, hasWidgetsErrors };
      }
    }

    case ReducerActions.RESET_WIDGETS_FLAGS_STATUS: {
      return {
        ...state,
        isAttributesWidgetVisible: true,
        isSensitivityWidgetVisible: true,
        isFindingsWidgetsVisible: true,
        isViolationWidgetVisible: true,
      };
    }

    case ReducerActions.UPDATE_AMOUNT_OF_FINDINGS: {
      const { amountOfFindings } = payload;

      return { ...state, amountOfFindings };
    }

    case ReducerActions.INIT: {
      return getInitialReducerState(payload);
    }

    default:
      return state;
  }
};

export const getInitialReducerState = ({
  caseSidePanelData = { id: '' },
  objectFullyQualifiedName = '',
  objectSensitivity = '',
  objectType = '',
  activeViewId = CaseSidePanelViewsIds.CASE_REPORT,
  actionsList = {},
  selectedObjects = [],
  enableActionsOnCaseLevel = true,
  isAttributesWidgetVisible = true,
  isFindingsWidgetsVisible = true,
  isViolationWidgetVisible = true,
  isSensitivityWidgetVisible = true,
  hasWidgetsErrors = false,
  amountOfFindings = 0,
  onCaseAction,
}: Partial<CaseSidePanelReducerState>): CaseSidePanelReducerState => {
  return {
    caseSidePanelData,
    objectFullyQualifiedName,
    objectSensitivity,
    objectType,
    activeViewId,
    actionsList,
    selectedObjects,
    enableActionsOnCaseLevel,
    isAttributesWidgetVisible,
    isFindingsWidgetsVisible,
    isViolationWidgetVisible,
    isSensitivityWidgetVisible,
    amountOfFindings,
    hasWidgetsErrors,
    onCaseAction,
  };
};
