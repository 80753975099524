import { httpService } from '../../../../services/httpService';
import { ObjectPreviewEntity } from './dataSourcesLayoutTypes';

export interface GetObjectsPreviewResponse {
  totalRowsCounter?: number;
  estimatedCount?: number;
  total?: number;
  results: ObjectPreviewEntity[];
}

export const getObjectsPreview = (query?: string) => {
  return httpService
    .fetch<GetObjectsPreviewResponse>(`data-catalog/?format=json${'&' + query || ''}`)
    .then(({ data }) => data);
};

export const getDataSourcesListAsCSV = (query?: string) => {
  return httpService.downloadFilePost(`inventory/file-download/export/source`, { filter: query });
};
