import React, { FC } from 'react';
import { makeStyles } from '@mui/styles';
import { MarketplaceApp } from '../../../../applicationManagementService';
import { BigidTabsAndContent } from '@bigid-ui/components';
import { SpecsTab } from './tabs/SpecsTab';
import { SupportTab } from './tabs/SupportTab';
import { DocumentsTab } from './tabs/DocumentsTab';

interface ClickToInstallTabsProps {
  appData: MarketplaceApp;
}

const useStyles = makeStyles({
  tabsContainer: {
    marginBottom: 0,
  },
});

export const ClickToInstallTabs: FC<ClickToInstallTabsProps> = ({ appData }) => {
  const classes = useStyles();

  return (
    <BigidTabsAndContent
      classes={{ tabsContainer: classes.tabsContainer }}
      tabProps={{
        tabs: [
          { label: 'Specs', data: { component: SpecsTab, customProps: { appSpecs: appData.appSpecs } } },
          { label: 'Support', data: { component: SupportTab, customProps: { support: appData.support } } },
          {
            label: 'Documents',
            data: { component: DocumentsTab, customProps: { documentsLinks: appData.documentsLinks } },
          },
        ],
      }}
    />
  );
};
