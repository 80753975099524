import { CuratedAttribute, CuratedAttributeType } from '../../../../../Curation/curationService';
import { getApplicationPreference } from '../../../../../../services/appPreferencesService';
import { isPermitted } from '../../../../../../services/userPermissionsService';
import { REVIEW_FINDINGS_PERMISSIONS, SUPERVISED_LEARNING_PERMISSIONS } from '@bigid/permissions';

type ClassifiersWithCurationIndication = {
  hasFindingsInClassifierTuning: boolean;
  shouldShowClassifierModel: boolean;
  curationAttributeType: CuratedAttributeType;
  curationDisplayName: string;
} & Record<string, any>;

export type GetClassifiersWithCurationIndicationParams = {
  classifiers: any;
  curationAttributes: CuratedAttribute[];
  curationAttributesStructured: CuratedAttribute[];
};

export function getClassifiersWithCurationIndication(
  classifiersWithCurationData: GetClassifiersWithCurationIndicationParams,
): ClassifiersWithCurationIndication[] {
  const CURATION_ATTRIBUTE_PREFIX = 'classifier.';

  const curationAttributesMap = classifiersWithCurationData.curationAttributes?.reduce(
    (map: Record<string, CuratedAttribute>, attribute: CuratedAttribute) => {
      map[attribute.attributeName] = attribute;
      return map;
    },
    {},
  );

  const curationStructuredAttributesMap = classifiersWithCurationData.curationAttributesStructured?.reduce(
    (map: Record<string, CuratedAttribute>, attribute: CuratedAttribute) => {
      map[attribute.attributeName] = attribute;
      return map;
    },
    {},
  );

  return classifiersWithCurationData.classifiers?.data?.map((classifier: any) => {
    const currentAttribute: CuratedAttribute =
      curationAttributesMap[`${CURATION_ATTRIBUTE_PREFIX}${classifier.classification_name}`];

    const currentAttributeStructuredOnly: CuratedAttribute =
      curationStructuredAttributesMap[`${CURATION_ATTRIBUTE_PREFIX}${classifier.classification_name}`];

    const shouldShowClassifierTuning = getShouldShowClassifierTuning(currentAttribute);
    const shouldShowClassifierModel = getShouldShowClassifierModel(currentAttributeStructuredOnly);

    return {
      ...classifier,
      hasFindingsInClassifierTuning: shouldShowClassifierTuning,
      shouldShowClassifierModel,
      curationAttributeType: shouldShowClassifierTuning ? currentAttribute?.attributeType : '',
      curationDisplayName: shouldShowClassifierTuning ? currentAttribute?.displayName : '',
    };
  });
}

function getShouldShowClassifierTuning(curatedAttribute: CuratedAttribute) {
  const hasAccess = isPermitted(REVIEW_FINDINGS_PERMISSIONS.ACCESS.name);
  const hasFindings = curatedAttribute?.totalFields > 0;
  return hasAccess && hasFindings;
}

function getShouldShowClassifierModel(curatedAttribute: CuratedAttribute) {
  const isClassifierModelEnabled = getApplicationPreference('CLASSIFIER_MODEL_ENABLED');
  const hasAccess = isPermitted(SUPERVISED_LEARNING_PERMISSIONS.READ.name);

  return (
    isClassifierModelEnabled &&
    hasAccess &&
    curatedAttribute?.totalFields > 0 &&
    curatedAttribute?.attributeType === CuratedAttributeType.CLASSIFIER
  );
}
