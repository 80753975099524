import React, { useEffect, useRef, useCallback, useState } from 'react';
import { setAwsCreds, startAutoDiscoveryApp, isCredsExist } from '../../../services/autoDiscoveryService';
import { CloudProvider } from '../../AutoDiscoveryWizard/autoDiscoveryWizardServices';
import { notificationService } from '../../../services/notificationService';
import AwsLogo from '../../../assets/icons/AwsLogo.svg';
import { $state } from '../../../../react/services/angularServices';
import { DATA_SOURCES_INIT_VIEWS } from '../../DataSources/DataSources';
import makeStyles from '@mui/styles/makeStyles';
import {
  BigidDialog,
  BigidColors,
  BigidForm,
  BigidFormProps,
  BigidFormFieldTypes,
  BigidFormFieldLabelPosition,
  BigidFormValidateOnTypes,
  BigidFormRenderProps,
  BigidLoader,
  PrimaryButton,
} from '@bigid-ui/components';

const useStyles = makeStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: 300,
  },
  logoContainer: {
    margin: '0 auto',
  },
  formContainer: {
    paddingTop: 16,
  },
  buttonContainer: {
    paddingTop: 16,
    flexDirection: 'column',
    display: 'flex',
    '& button:not(:first-child)': {
      marginTop: 16,
    },
  },
  inputContainer: {
    paddingBottom: 8,
  },
  text: {
    color: BigidColors.gray[900],
    fontSize: 16,
    paddingTop: 8,
  },
});

export interface AwsCredsDialogProps {
  isOpen: boolean;
  onClose: () => void;
}

const AWS_DS_TYPES_TO_DISCOVER = 'aws_auto_athena,aws_auto_kinesis,aws_auto_s3,aws_auto_dynamoDb';

export const AwsCredsDialog = ({ isOpen, onClose }: AwsCredsDialogProps) => {
  const { container, logoContainer, formContainer, buttonContainer, inputContainer, text } = useStyles({});
  const [isLoading, setIsLoading] = useState(true);
  const [isEditMode, setIsEditMode] = useState(true);
  const [accessKeyIdInitialValue, setAccessKeyIdInitialValue] = useState('');
  const [secretAccessKeyInitialValue, setSecretAccessKeyInitialValue] = useState('');
  const renderFormRef = useRef<Partial<BigidFormRenderProps>>();
  const maskedTextPlaceholder = 'maskedTextPlaceholder';
  const labelWidth = '150px';
  const labelPosition = BigidFormFieldLabelPosition.left;

  const onModifyClick = () => {
    const accessKeyIdProps = renderFormRef.current.getFieldProps('accessKeyId');
    const secretAccessKeyProps = renderFormRef.current.getFieldProps('secretAccessKey');
    accessKeyIdProps.setValue('');
    secretAccessKeyProps.setValue('');
    setIsEditMode(true);
  };

  const startApp = async () => {
    try {
      const tpa_id = await startAutoDiscoveryApp(CloudProvider.AWS);
      if (tpa_id) {
        $state.go(`dataSourcesInit`, {
          path: DATA_SOURCES_INIT_VIEWS.autoDiscovery.path,
        });
      }
    } catch (e) {
      throw new Error(e);
    }
  };

  const onFormSubmit = useCallback(async () => {
    try {
      if (isEditMode) {
        renderFormRef.current.validateAndSubmit(async (values: any) => {
          const { accessKeyId, secretAccessKey } = values;
          await setAwsCreds(accessKeyId, secretAccessKey, AWS_DS_TYPES_TO_DISCOVER);
          startApp();
        });
      } else {
        startApp();
      }
    } catch (e) {
      notificationService.error(e.message);
    }
  }, [isEditMode]);

  const formProps: BigidFormProps = {
    initialValues: {
      accessKeyId: accessKeyIdInitialValue,
      secretAccessKey: secretAccessKeyInitialValue,
    },
    validateOn: BigidFormValidateOnTypes.SUBMIT,
    controlButtons: false,
    fields: [
      {
        name: 'accessKeyId',
        label: 'Access Key ID',
        isRequired: true,
        labelWidth,
        labelPosition,
        disabled: !isEditMode,
        misc: {
          showValueOnlyIfDirty: true,
        },
        type: BigidFormFieldTypes.PASSWORD,
        validate: (accessKeyId: string) => {
          if (!accessKeyId) {
            return 'Please enter AWS Access Key ID';
          }
          if (accessKeyId.length < 16 || accessKeyId.length > 128) {
            return 'Please enter a valid AWS Access Key ID';
          }
          return false;
        },
      },
      {
        name: 'secretAccessKey',
        label: 'Secret Access Key',
        isRequired: true,
        labelWidth,
        labelPosition,
        disabled: !isEditMode,
        type: BigidFormFieldTypes.PASSWORD,
        misc: {
          showValueOnlyIfDirty: true,
        },
        validate: (secretAccessKey: string) => {
          if (!secretAccessKey) {
            return 'Please enter AWS Secret Access Key';
          }
          return false;
        },
      },
    ],
    renderForm: ({ renderField, getValues, validateAndSubmit, getFieldProps }) => {
      renderFormRef.current = {
        getValues,
        validateAndSubmit,
        getFieldProps,
      };
      return (
        <>
          <div className={inputContainer}>{renderField('accessKeyId')}</div>
          <div className={inputContainer}>{renderField('secretAccessKey')}</div>
        </>
      );
    },
  };

  useEffect(() => {
    async function fetchData() {
      try {
        const credsStatus = await isCredsExist(CloudProvider.AWS);
        if (credsStatus) {
          setAccessKeyIdInitialValue(maskedTextPlaceholder);
          setSecretAccessKeyInitialValue(maskedTextPlaceholder);
          setIsEditMode(false);
        }
        setIsLoading(false);
      } catch (e) {
        notificationService.error(e.message);
        onClose();
      }
    }
    fetchData();
  }, [isOpen, onClose]);

  return (
    <BigidDialog borderTop={false} isOpen={isOpen} title="AWS Auto Discovery" onClose={onClose} maxWidth="sm">
      <div className={container}>
        {isLoading ? (
          <BigidLoader />
        ) : (
          <>
            <div className={logoContainer}>
              <AwsLogo />
            </div>
            <div className={text}>
              Please provide your AWS credentials in order to get started with the Auto Discovery Wizard
            </div>
            <div className={formContainer}>
              <BigidForm {...formProps} />
              <div className={buttonContainer}>
                {!isEditMode && <PrimaryButton text="Modify Values" size="medium" onClick={onModifyClick} />}
                <PrimaryButton text="Start Discovering" color="purple" size="medium" onClick={onFormSubmit} />
              </div>
            </div>
          </>
        )}
      </div>
    </BigidDialog>
  );
};
