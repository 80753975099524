import React, { FC, useEffect, useContext } from 'react';
import styled from '@emotion/styled';
import {
  PolicyDescription,
  ObjectsSensitivityWidget,
  FindingsWidget,
  ViolationsLocationWidget,
  AffectedObjectsWidget,
  AttributesWidget,
} from './CaseReportWidgets';
import { generateDataAid } from '@bigid-ui/utils';
import { CaseSidePanelContext } from '../hooks/CaseSidePanelContext';
import { notificationService } from '../../../../services/notificationService';
import { AccessStatusContainer } from './CaseReportComponents/AccessStatusContainer';
import { ReducerActions } from '../hooks/CaseSidePanelReducer';
import { getCaseActions } from './caseReportService';
import { BigidButtonIcon } from '@bigid-ui/components';
import { BigidExternalLinkIcon } from '@bigid-ui/icons';
import { $state } from '../../../../services/angularServices';
import { CONFIG } from '../../../../../config/common';
import { DsInfoWidget } from './CaseReportWidgets/DsInfoWidget';

const MainContainer = styled.div({
  display: 'flex',
  flexDirection: 'column',
  padding: '24px 24px 0',
});

const UpperWidgets = styled.div<{
  isVisible: boolean;
}>(({ isVisible }) => ({
  height: 176,
  width: '100%',
  padding: '40px 0px 32px 0px',
  display: isVisible ? 'flex' : 'none',
  alignItems: 'flex-start',
  flexDirection: 'row',
  gap: 24,
  position: 'relative',
}));

const ExternalLink = styled.div<{
  isVisible: boolean;
}>(({ isVisible }) => ({
  display: isVisible ? 'block' : 'none',
  position: 'absolute',
  right: 0,
  top: 40,
}));

export const CaseReportContainer: FC = () => {
  const {
    caseSidePanelData,
    dispatch,
    hasWidgetsErrors,
    isFindingsWidgetsVisible,
    isSensitivityWidgetVisible,
    isViolationWidgetVisible,
  } = useContext(CaseSidePanelContext);
  const hotspotsReportUrl = $state.href(CONFIG.states.HOTSPOTS_REPORT);
  const isUpperWidgetHidden = !(
    isFindingsWidgetsVisible === false &&
    isSensitivityWidgetVisible === false &&
    isViolationWidgetVisible === false
  );

  useEffect(() => {
    if (hasWidgetsErrors) {
      notificationService.warning('Some of the data could not be fetched. Check the logs for more information.');
    }
  }, [hasWidgetsErrors]);

  useEffect(() => {
    const getCaseActionsList = async () => {
      try {
        const { data } = await getCaseActions(
          caseSidePanelData.policyName,
          caseSidePanelData.dataSourceType,
          caseSidePanelData.id,
        );
        dispatch({
          type: ReducerActions.ADD_INITIAL_ACTIONS,
          payload: { actionsList: data },
        });
      } catch (e) {
        console.error(e);
        notificationService.error('Could not fetch data. See logs for more information');
      }
    };
    getCaseActionsList();
  }, [caseSidePanelData.dataSourceType, caseSidePanelData.id, caseSidePanelData.policyName, dispatch]);

  return (
    <>
      <MainContainer>
        <AccessStatusContainer />
        <PolicyDescription />
        <UpperWidgets isVisible={isUpperWidgetHidden}>
          <FindingsWidget />
          <ObjectsSensitivityWidget />
          <ViolationsLocationWidget />
          <ExternalLink isVisible={isViolationWidgetVisible}>
            <BigidButtonIcon
              icon={BigidExternalLinkIcon}
              onClick={() => {
                window.open(hotspotsReportUrl, '_blank');
              }}
              dataAid={generateDataAid('ViolationsLocationWidget', ['redirect-to-hotspoto-report', 'button'])}
            />
          </ExternalLink>
        </UpperWidgets>
        <AffectedObjectsWidget />
        <AttributesWidget />
        <DsInfoWidget />
      </MainContainer>
    </>
  );
};
