import React, { FC } from 'react';
import styled from '@emotion/styled';
import { PrimaryButton, SecondaryButton, StyledButtonType, TertiaryButton } from '@bigid-ui/components';
import { BigidDeleteIcon } from '@bigid-ui/icons';
import { noop } from 'lodash';
import { CertificateGridRow } from './types';
import { useLocalTranslation } from './translations';
import { getPermissions } from './utils';
import { generateDataAid } from '@bigid-ui/utils';
import { BigidSystemDialogOptions, openSystemDialog } from '../../../services/systemDialogService';
import { CertificatesManagementDialogContent } from './CertificatesManagementDialogContent';

export type CertificateManagementFooterProps = {
  row: CertificateGridRow;
  handleSubmit: () => void;
  handleDelete: () => void;
};

const Buttons = styled.div({
  display: 'flex',
  alignItems: 'center',
  gap: '0.5rem',
  justifyContent: 'flex-end',
  flexGrow: 1,
});

export const CertificatesManagementActions: FC<CertificateManagementFooterProps> = ({
  row,
  handleSubmit,
  handleDelete,
}) => {
  const { t } = useLocalTranslation();
  const { isDeletePermitted, isCreatePermitted, isEditPermitted } = getPermissions();

  const showConfirmDeleteDialog = () => {
    const dialogOptions: BigidSystemDialogOptions = {
      title: t('modal.delete.title'),
      onClose: noop,
      maxWidth: 'xs',
      buttons: [
        {
          component: SecondaryButton,
          dataAid: generateDataAid('CertificatesManagementActions', ['cancel', 'delete']),
          isClose: true,
          onClick: noop,
          text: t('buttons.cancel'),
        },
        {
          component: PrimaryButton,
          dataAid: generateDataAid('CertificatesManagementActions', ['confirm', 'delete']),
          isClose: true,
          disabled: !isDeletePermitted,
          onClick: handleDelete,
          text: t('buttons.delete'),
        },
      ],
      content: CertificatesManagementDialogContent,
      contentProps: { body: t('modal.delete.body', { entityName: row.name }) },
      borderTop: true,
    };

    openSystemDialog(dialogOptions);
  };

  const actions: (StyledButtonType & { component: FC<StyledButtonType> })[] = [
    {
      component: TertiaryButton,
      dataAid: generateDataAid('CertificatesManagementActions', ['delete']),
      startIcon: <BigidDeleteIcon />,
      disabled: !isDeletePermitted,
      onClick: () => showConfirmDeleteDialog(),
      color: 'grey',
      size: 'medium',
      text: t('buttons.delete'),
    },
    {
      component: SecondaryButton,
      dataAid: generateDataAid('CertificatesManagementActions', ['save']),
      disabled: row?.isPending ? !isCreatePermitted : !isEditPermitted,
      onClick: handleSubmit,
      size: 'medium',
      text: t('buttons.save'),
    },
  ];

  return (
    <Buttons>
      {actions.map(({ component: Component, ...rest }) => (
        <Component key={rest.dataAid} {...rest} />
      ))}
    </Buttons>
  );
};
