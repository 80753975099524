import { BigidBody1, BigidHeading5 } from '@bigid-ui/components';
import { generateDataAid } from '@bigid-ui/utils';
import styled from '@emotion/styled';
import React from 'react';

const DialogGuideGeneralStepTitle = styled(BigidHeading5)`
  margin-bottom: 12px;
`;

const DialogGuideGeneralStepDescription = styled(BigidBody1)`
  font-size: 14px;
`;

interface DialogGuideGeneralStepProps {
  title: string;
  description: string;
  dataAid?: string;
}

export const DialogGuideGeneralStep = ({
  title,
  description,
  dataAid = 'DialogGuideGeneralStep',
}: DialogGuideGeneralStepProps) => {
  return (
    <div data-aid="foo">
      <DialogGuideGeneralStepTitle data-aid={generateDataAid(dataAid, ['title'])}>{title}</DialogGuideGeneralStepTitle>
      <DialogGuideGeneralStepDescription data-aid={generateDataAid(dataAid, ['description'])}>
        {description}
      </DialogGuideGeneralStepDescription>
    </div>
  );
};
