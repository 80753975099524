import { objectToQueryString, QueryParams } from '@bigid-ui/components';
import { BigidGridColumn, BigidGridColumnTypes, BigidGridProps, FetchDataFunction } from '@bigid-ui/grid';
import { BigidLayout, BigidLayoutConfig, LayoutContentType } from '@bigid-ui/layout';
import React, { FC, Fragment, useEffect, useState } from 'react';
import { pageHeaderService } from '../../../common/services/pageHeaderService';
import { $translate, DeleteConfirmation } from '../../services/angularServices';
import { httpService } from '../../services/httpService';
import { notificationService } from '../../services/notificationService';
import { CertificateDialog, CertificateDialogProps } from './CertificateDialog';
import { CERTIFICATES_PERMISSIONS } from '@bigid/permissions';
import { isPermitted } from '../../services/userPermissionsService';
import { DateISO8601 } from '../../types/types';
import { BigidDeleteIcon, BigidImportIcon } from '@bigid-ui/icons';

export interface CertificateGetResponse {
  results: CertificateModel[];
  total: number;
}
export enum CertificateType {
  PEM = 'pem',
  JKS = 'jks',
  P12 = 'p12',
  P8 = 'p8',
  KERBEROS = 'kerberos',
  SSO = 'sso',
  ZIP = 'zip',
}
export enum CertificateUsage {
  JAVA_KEYSTORE = 'javaKeystore',
  ROOT_CA = 'rootCA',
  CLIENT_CERT = 'clientCert',
  SECURE_BUNDLE = 'secureBundle',
  CUSTOM = 'custom',
}

export const certUsageNames = {
  JAVA_KEYSTORE: 'Java Keystore/Truststore',
  ROOT_CA: 'Certificate Authority',
  CLIENT_CERT: 'Client Certificate',
  SECURE_BUNDLE: 'Secure Bundle',
  CUSTOM: 'Custom',
};

export interface CertificateModel {
  id: string | number;
  _id: string;
  updated_at: DateISO8601;
  created_at: DateISO8601;
  name: string;
  user: string;
  password: string;
  type: CertificateType;
  usage: CertificateUsage;
  /** name of the certificate file */
  cert: string;
}
export interface CertificatePutModel extends CertificatePostModel {
  id: string | number;
}
export interface CertificatePostModel {
  name: string;
  type: CertificateType;
  usage: CertificateUsage;
  password: string;
  cert: File;
  configFile?: File;
}

const MAX_RESULTS = 2000;

const columns: BigidGridColumn<CertificateModel>[] = [
  {
    name: 'name',
    title: 'Certificate Name',
    getCellValue: ({ name }) => name,
    type: BigidGridColumnTypes.TEXT,
  },
  {
    name: 'user',
    title: 'Updated By',
    getCellValue: ({ user }) => user,
    type: BigidGridColumnTypes.TEXT,
  },
  {
    name: 'type',
    title: 'Type',
    getCellValue: ({ type }) => type,
    type: BigidGridColumnTypes.TEXT,
  },
  {
    name: 'usage',
    title: 'Usage',
    getCellValue: ({ usage }) => usage,
    type: BigidGridColumnTypes.TEXT,
  },
  {
    name: 'updated_at',
    title: 'Last Updated',
    getCellValue: ({ updated_at }) => updated_at,
    type: BigidGridColumnTypes.TEXT,
  },
];

const CertificatesManagementComponent: FC = () => {
  useEffect(() => {
    pageHeaderService.setTitle({ pageTitle: 'Certificates Management' });
  }, []);

  const [dialogState, setDialogState] = useState<
    Pick<CertificateDialogProps, 'isEditMode' | 'isOpen' | 'isDisabled' | 'isLoading' | 'origData' | 'resolveOnExecute'>
  >({
    isEditMode: false,
    isOpen: false,
    isDisabled: false,
    isLoading: false,
    origData: null,
  });

  const certificateDialog: CertificateDialogProps = {
    ...dialogState,
    onSave: ({ isEditMode, formData, isChanged, resolveOnExecute }) => {
      if (isEditMode) {
        if (!isChanged) {
          certificateDialog.onClose();
        } else {
          updateCertificate(formData)
            .then(() => {
              certificateDialog.onClose();
              notificationService.success($translate.instant('API:MESSAGE:COMMON_PUT_SUCCESS'));
              resolveOnExecute({ shouldClearSelection: true, shouldGridReload: true });
            })
            .catch(err => {
              notificationService.error($translate.instant('API:MESSAGE:COMMON_ERROR'));
              console.error(`Failed to update certificate:`, err.response);
              throw err;
            })
            .finally(() => {
              setDialogState(prevState => ({ ...prevState, isDisabled: false, isLoading: false }));
            });
        }
      } else if (formData) {
        createCertificate(formData)
          .then(() => {
            certificateDialog.onClose();
            notificationService.success($translate.instant('API:MESSAGE:COMMON_POST_SUCCESS'));
            resolveOnExecute({ shouldClearSelection: true, shouldGridReload: true });
          })
          .catch(err => {
            let notificationMsg = $translate.instant('API:MESSAGE:COMMON_ERROR');
            const apiError =
              err.response && err.response.data && err.response.data.error ? err.response.data.error : '';
            if (apiError.includes('duplicate key error')) {
              notificationMsg = $translate.instant('API:MESSAGE:COMMON_DUPLICATE_KEY_ERROR', {
                name: formData.name,
              });
            }

            notificationService.error(notificationMsg);
            console.error(`Failed to create certificate:`, err.response);
            throw err;
          })
          .finally(() => {
            setDialogState(prevState => ({ ...prevState, isDisabled: false, isLoading: false }));
          });
      }
    },
    onClose: () => {
      setDialogState({
        ...dialogState,
        origData: null,
        isEditMode: false,
        isDisabled: false,
        isOpen: false,
        isLoading: false,
      });
    },
  };

  const editCertificate = (origData: CertificatePutModel) => {
    return new Promise(resolveOnExecute => {
      setDialogState({
        ...dialogState,
        origData,
        isOpen: true,
        isEditMode: true,
        resolveOnExecute,
      });
    });
  };

  const createNewCertificate = () => {
    return new Promise(resolveOnExecute => {
      setDialogState({
        ...dialogState,
        origData: null,
        isDisabled: false,
        isLoading: false,
        isOpen: true,
        isEditMode: false,
        resolveOnExecute,
      });
    });
  };

  const pageSize = MAX_RESULTS;
  const gridConfig: BigidGridProps<CertificateModel> = {
    customRowIdName: 'name',
    columns,
    showSortingControls: false,
    pageSize,
  };
  const fetchData: FetchDataFunction<CertificateModel> = async queryComponents => {
    // IMPORTANT: the API doesn't support `skip`, so we bring maximum results to prevent Grid requestsing more data
    const query = objectToQueryString({ ...(queryComponents as QueryParams), limit: pageSize });
    const { total, results } = await getCertificates(`?${query}`);

    return {
      totalCount: total,
      data: results,
    };
  };

  const layoutConfig: BigidLayoutConfig = {
    content: {
      contentTypes: [LayoutContentType.GRID],
      defaultContentType: LayoutContentType.GRID,
      viewConfig: {
        fetchGridData: fetchData,
        gridConfig,
      },
      toolbarActions: [
        {
          isGlobal: true,
          label: 'Add Certificate',
          execute: async () => {
            return createNewCertificate();
          },
          disable: () => false,
          show: () => isPermitted(CERTIFICATES_PERMISSIONS.CREATE.name),
        },
        {
          label: 'Update',
          icon: BigidImportIcon,
          execute: ({ selectedRows }) => {
            if (!selectedRows.length) return;
            const id = selectedRows[0]._id as string;
            return getCertificateById(id).then(certificate => {
              return editCertificate({
                ...certificate,
                id: certificate._id,
                cert: new File([], certificate.cert.originalname as string),
                ...(certificate.type !== CertificateType.KERBEROS
                  ? {}
                  : { configFile: new File([], certificate.configFile?.originalname) }),
              });
            });
          },
          disable: ({ selectedRowIds }) => {
            return selectedRowIds.length !== 1;
          },
          show: () => isPermitted(CERTIFICATES_PERMISSIONS.EDIT.name),
        },
        {
          label: 'Delete',
          icon: BigidDeleteIcon,
          execute: ({ selectedRows }) => {
            const deleteText = `${selectedRows.length} Certificates`;
            return DeleteConfirmation.showModal(
              {},
              {
                closeButtonText: $translate.instant('BUTTONS:CLOSE'),
                actionButtonText: $translate.instant('BUTTONS:DELETE'),
                headerText: $translate.instant('APP:COMMON:DELETE_CONFIRMATION_HEADER', { entityName: deleteText }),
                bodyText: $translate.instant('APP:COMMON:DELETE_CONFIRMATION_TEXT', { entityName: deleteText }),
              },
            ).then(() => {
              return deleteCertificates(selectedRows.map(({ _id }) => String(_id)))
                .then(() => {
                  notificationService.success($translate.instant('API:MESSAGE:COMMON_DELETE_SUCCESS'));
                  return { shouldGridReload: true, shouldClearSelection: true };
                })
                .catch((err: Error) => {
                  notificationService.error($translate.instant('API:MESSAGE:COMMON_ERROR'));
                  console.error(`Failed to delete certificate:`, err);
                  throw err;
                });
            });
          },
          disable: ({ selectedRowIds }) => {
            return selectedRowIds.length < 1;
          },
          show: () => isPermitted(CERTIFICATES_PERMISSIONS.DELETE.name),
        },
      ],
    },
  };

  return (
    <Fragment>
      <BigidLayout config={layoutConfig} />
      <CertificateDialog {...certificateDialog} />
    </Fragment>
  );
};

export function getCertificateById(id: string) {
  const url = `certificates-store/${encodeURIComponent(id)}`;
  return httpService.fetch(url).then(({ data: { certificate } }) => {
    if (Array.isArray(certificate.results) && certificate.results.length) {
      return certificate.results[0];
    }
  });
}

/**
 * query string already url encoded (in BigidGrid)
 */
export function getCertificates(query: string) {
  const url = 'certificates-store' + query;
  return httpService.fetch<{ certificate: CertificateGetResponse }>(url).then(({ data }) => data.certificate);
}

export function deleteCertificates(ids: string[]) {
  const url = 'certificates-store/delete';
  return httpService.post(url, { ids });
}

export function createCertificate(certificate: CertificatePostModel) {
  const url = 'certificates-store';
  const { name, type, password, cert, usage, configFile } = certificate;
  const files: CertificateConfigFile = { configFile, cert };

  if (configFile?.size) {
    files.configFile = configFile;
  }

  return httpService.multipart('post', url, { data: { name, type, password, usage }, files });
}

export function updateCertificate({ id, type, usage, password, cert, configFile }: Partial<CertificatePutModel>) {
  if (!id) {
    return;
  }

  const files: { configFile?: File; cert?: File } = {};

  if (cert?.size) {
    files.cert = cert;
  }

  if (configFile?.size) {
    files.configFile = configFile;
  }

  const url = `certificates-store/${decodeURIComponent(id as string)}`;
  return httpService.multipart('put', url, {
    data: { type, usage, password },
    ...(Object.keys(files).length ? { files } : {}),
  });
}

type CertificateConfigFile = {
  configFile?: File;
  cert?: File;
};

export const CertificatesManagement: FC = () => {
  return <CertificatesManagementComponent />;
};
