import React, { FunctionComponent, useEffect, useState, Fragment } from 'react';
import { Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { BigidColors, BigidPieChart, BigidChartChip, PieChartData } from '@bigid-ui/components';

export interface ClusterDuplicatesChartProps {
  duplicates: number;
  uniques: number;
}

export const chartColors = ['#9CCE6A', '#90A3F4'];

const useStyles = makeStyles({
  wrapper: {
    display: 'flex',
  },
  chipsWrapper: { display: 'flex', flexDirection: 'column', justifyContent: 'center', marginLeft: 32 },
  emptyMessage: {
    color: BigidColors.gray[500],
  },
});

function getDuplicatesChartData(totalDuplicates: number, uniques: number) {
  if (totalDuplicates > 0 && uniques >= 0) {
    return [
      { category: 'original objects', value: uniques, color: BigidColors.green[600] },
      { category: 'duplicate objects', value: totalDuplicates - uniques, color: BigidColors.blue[500] },
    ];
  } else {
    return [];
  }
}

export const ClusterDuplicatesChart: FunctionComponent<ClusterDuplicatesChartProps> = ({
  duplicates = 0,
  uniques = 0,
}) => {
  const classes = useStyles({});
  const [chartData, setChartData] = useState<PieChartData<string>[]>([]);

  useEffect(() => {
    if (duplicates > 0 && uniques > 0) {
      const duplicatesData = getDuplicatesChartData(duplicates, uniques);
      setChartData(duplicatesData);
    } else {
      setChartData([]);
    }
  }, [duplicates, uniques]);

  return (
    <Fragment>
      {chartData.length > 0 && (
        <div className={classes.wrapper}>
          <div>
            <BigidPieChart data={chartData} total={duplicates} entityName="Objects" onlyShowOnViewport={false} />
          </div>
          <div className={classes.chipsWrapper}>
            {chartData.map(({ color, category, value }, key) => (
              <div key={key}>
                <BigidChartChip color={color} label={category} value={value} />
              </div>
            ))}
          </div>
        </div>
      )}
      {chartData.length === 0 && (
        <Typography variant={'body2'} className={classes.emptyMessage}>
          No duplicates found
        </Typography>
      )}
    </Fragment>
  );
};
