import { httpService } from '../../../services/httpService';
import { randUserName, randRecentDate, randWord, randNumber, randBoolean, randUuid } from '@ngneat/falso';
import { sample } from 'lodash';
import { DateISO8601 } from '../../../types/types';

export enum AuditTrailPropOperation {
  ADDED = 'added',
  MODIFIED = 'modified',
  REMOVED = 'removed',
}
export enum AuditTrailProp {
  COLUMN = 'column',
  ATTRIBUTE = 'attribute',
  TAG = 'tag',
}

export type AuditTrailPropChangeDetails = {
  name: string;
  value: string | string[];
};

export type AuditTrailRecord = {
  eventId: string;
  createdAt: string;
  eventSource: string;
  name: string;
  propertyOperation: AuditTrailPropOperation;
  changedProperty: AuditTrailProp;
  current?: AuditTrailPropChangeDetails[] | null;
  previous?: AuditTrailPropChangeDetails[] | null;
};

export type Paging = {
  limit: number;
  skip: number;
};

export type Sorting = {
  field: string;
  order: 'desc' | 'asc';
};

export type AuditTrailRequestPayload = {
  filter?: string;
  searchText?: string;
  fromDate?: DateISO8601;
  paging?: Paging;
  sort?: Sorting[];
};

export type AuditTrailResponse = {
  data: AuditTrailRecord[];
};

export function getAuditTrailRecords(
  fullyQualifiedName: string,
  payload?: AuditTrailRequestPayload,
): Promise<AuditTrailResponse> {
  // return httpService
  //   .post<AuditTrailResponse>(`data-catalog/history/audit/${fullyQualifiedName}`, payload)
  //   .then(({ data }) => data);

  return new Promise(resolve => {
    setTimeout(() => {
      const response: AuditTrailResponse = {
        data: new Array(100).fill({}).map(() => {
          const operation = sample([
            AuditTrailPropOperation.ADDED,
            AuditTrailPropOperation.MODIFIED,
            AuditTrailPropOperation.REMOVED,
          ]);
          const prop = sample([AuditTrailProp.ATTRIBUTE, AuditTrailProp.COLUMN, AuditTrailProp.TAG]);

          let current;
          let previous;

          switch (prop) {
            case AuditTrailProp.ATTRIBUTE: {
              const details = generateAttributeDetails(operation);
              current = details.current;
              previous = details.previous;
              break;
            }
            case AuditTrailProp.COLUMN: {
              const details = generateColumnDetails(operation);
              current = details.current;
              previous = details.previous;
              break;
            }
            case AuditTrailProp.TAG: {
              const details = generateTagDetails(operation);
              current = details.current;
              previous = details.previous;
              break;
            }
          }

          return {
            eventId: randUuid(),
            createdAt: randRecentDate().toISOString(),
            eventSource: sample([randUserName(), 'System']),
            name: randWord(),
            propertyOperation: operation,
            changedProperty: prop,
            current,
            previous,
          };
        }),
      };

      resolve(response);
    }, 2000);
  });
}

function generateAttributeDetails(operation: AuditTrailPropOperation): Pick<AuditTrailRecord, 'current' | 'previous'> {
  const current = {
    attributeType: new Array(randNumber({ min: 1, max: 5 })).fill(null).map(() => randWord()),
    findings: randNumber({ min: 1, max: 100e10 }),
  };
  const previous = {
    attributeType: sample([new Array(randNumber({ min: 1, max: 5 })).fill(null).map(() => randWord()), undefined]),
    findings: sample([randNumber({ min: 1, max: 100e10 }), undefined]),
  };

  switch (operation) {
    case AuditTrailPropOperation.ADDED:
      return {
        current: Object.entries(current)
          .filter(([, value]) => !!value)
          .map(([name, value]) => ({
            name,
            value: Array.isArray(value) ? value : value.toString(),
          })),
        previous: null,
      };
    case AuditTrailPropOperation.MODIFIED:
      return {
        current: Object.entries(current)
          .filter(([, value]) => !!value)
          .map(([name, value]) => ({
            name,
            value: Array.isArray(value) ? value : value.toString(),
          })),
        previous: Object.entries(previous)
          .filter(([, value]) => !!value)
          .map(([name, value]) => ({
            name,
            value: Array.isArray(value) ? value : value.toString(),
          })),
      };
    case AuditTrailPropOperation.REMOVED:
      return {
        current: null,
        previous: Object.entries(previous)
          .filter(([, value]) => !!value)
          .map(([name, value]) => ({
            name,
            value: Array.isArray(value) ? value : value.toString(),
          })),
      };
  }
}

function generateColumnDetails(operation: AuditTrailPropOperation): Pick<AuditTrailRecord, 'current' | 'previous'> {
  const current = {
    columnType: randWord(),
    isPrimary: randBoolean(),
    maxLength: randNumber({ min: 1, max: 10 }),
    nullable: randBoolean(),
    numericPrecision: randNumber({ min: 1, max: 10 }),
    numericScale: randNumber({ min: 1, max: 10 }),
    collationName: randWord(),
  };

  const previous = {
    columnType: sample([randWord(), undefined]),
    isPrimary: sample([randBoolean(), undefined]),
    maxLength: sample([randNumber({ min: 1, max: 10 }), undefined]),
    nullable: sample([randBoolean(), undefined]),
    numericPrecision: sample([randNumber({ min: 1, max: 10 }), undefined]),
    numericScale: sample([randNumber({ min: 1, max: 10 }), undefined]),
    collationName: sample([randWord(), undefined]),
  };

  switch (operation) {
    case AuditTrailPropOperation.ADDED:
      return {
        current: Object.entries(current)
          .filter(([, value]) => !!value)
          .map(([name, value]) => ({
            name,
            value: value.toString(),
          })),
        previous: null,
      };
    case AuditTrailPropOperation.MODIFIED:
      return {
        current: Object.entries(current)
          .filter(([, value]) => !!value)
          .map(([name, value]) => ({
            name,
            value: value.toString(),
          })),
        previous: Object.entries(previous)
          .filter(([, value]) => !!value)
          .map(([name, value]) => ({
            name,
            value: value.toString(),
          })),
      };
    case AuditTrailPropOperation.REMOVED:
      return {
        current: null,
        previous: Object.entries(previous)
          .filter(([, value]) => !!value)
          .map(([name, value]) => ({
            name,
            value: value.toString(),
          })),
      };
  }
}

function generateTagDetails(operation: AuditTrailPropOperation): Pick<AuditTrailRecord, 'current' | 'previous'> {
  const current = {
    tagType: randWord(),
    columnName: randWord(),
  };

  const previous = {
    tagType: sample([randWord(), undefined]),
    columnName: sample([randWord(), undefined]),
  };

  switch (operation) {
    case AuditTrailPropOperation.ADDED:
      return {
        current: Object.entries(current)
          .filter(([, value]) => !!value)
          .map(([name, value]) => ({
            name,
            value: value.toString(),
          })),
        previous: null,
      };
    case AuditTrailPropOperation.MODIFIED:
      return {
        current: Object.entries(current)
          .filter(([, value]) => !!value)
          .map(([name, value]) => ({
            name,
            value: value.toString(),
          })),
        previous: Object.entries(previous)
          .filter(([, value]) => !!value)
          .map(([name, value]) => ({
            name,
            value: value.toString(),
          })),
      };
    case AuditTrailPropOperation.REMOVED:
      return {
        previous: Object.entries(previous)
          .filter(([, value]) => !!value)
          .map(([name, value]) => ({
            name,
            value: value.toString(),
          })),
        current: null,
      };
  }
}
