import React from 'react';
import { BigidLayoutEmptyState } from '@bigid-ui/layout';
import { BigidNoDataIllustration } from '@bigid-ui/icons';
import { generateDataAid } from '@bigid-ui/utils';
import { useLocalTranslation } from '../translations';

export const NotSupportedEmptyState = ({ dataAid = 'similarTables' }) => {
  const { t } = useLocalTranslation();
  return (
    <BigidLayoutEmptyState
      illustration={BigidNoDataIllustration}
      description={t('emptyStates.notSupported.description')}
      dataAid={`${generateDataAid(dataAid, ['notSupported', 'emptyState'])}`}
    />
  );
};
