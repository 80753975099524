import React, { FC, useContext } from 'react';
import { BigidHeading5, BigidBody1 } from '@bigid-ui/components';
import { Paper, styled } from '@mui/material';
import { AutoDiscoveryWizardContext } from '../autoDiscoveryWizardContext';
import { useLocalTranslation } from '../../translations';

const EmptyStateContainer = styled(Paper)`
  min-height: 100%;
  flex: 1 1 auto;
  overflow: auto;
  width: 100%;
  align-items: center;
  align-content: center;
  justify-items: center;
  display: flex;
  flex-direction: column;
  padding: 35vh 16px 16px;
`;

export const AutoDiscoveryNotFound: FC = () => {
  const { discoveryConfigData } = useContext(AutoDiscoveryWizardContext);
  const { t } = useLocalTranslation('AutoDiscovery');

  return (
    <EmptyStateContainer>
      <div data-aid="dataSourceConfigurationNoDataInnerContainer">
        <BigidHeading5 sx={{ paddingBottom: '4px' }}>{t('configAdvanced.notFoundTitle')}</BigidHeading5>
        <BigidBody1>
          {discoveryConfigData?.isIdNotFound && <li>{t('configAdvanced.notFoundIds')}</li>}
          {discoveryConfigData?.isTypeNotFound && (
            <>
              <li>{t('configAdvanced.notFoundTypeOne')}</li> <li>{t('configAdvanced.notFoundTypeTwo')}</li>
            </>
          )}
        </BigidBody1>
        <BigidBody1 sx={{ marginTop: '16px' }}>{t('configAdvanced.notFoundFooterMessage')}</BigidBody1>
      </div>
    </EmptyStateContainer>
  );
};
