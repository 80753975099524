import React, { FC, useEffect } from 'react';
import { LabelingLayout } from './LabelingLayout';
import { pageHeaderService } from '../../../common/services/pageHeaderService';
import { LabelingSettingsDialog } from './LabelingSettingsDialog';
import { openSystemDialog } from '../../services/systemDialogService';
import { noop } from 'lodash';
import { BigidAdministrationIcon } from '@bigid-ui/icons';
import { BigidButtonIcon } from '@bigid-ui/components';

export const Labeling: FC = () => {
  useEffect(() => {
    pageHeaderService.setTitle({
      pageTitle: 'Labeling',
      rightSideComponentsContainer: (
        <BigidButtonIcon
          icon={BigidAdministrationIcon}
          dataAid="labeling-configuration"
          title="Settings"
          onClick={openLabelingSettingsDialog}
        />
      ),
    });
  }, []);

  const openLabelingSettingsDialog = () => {
    openSystemDialog({
      title: 'Credentials',
      onClose: noop,
      content: LabelingSettingsDialog,
      borderTop: true,
    });
  };

  return <LabelingLayout />;
};
