import React, { FC, useContext, useMemo } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { BigidForm } from '@bigid-ui/components';
import { useBasicDetailsFormProps } from './useBasicDetailsFormProps';
import { CreateScanWizardContext } from '../../../../ScanCreateWizard/createScanContext';

const useStyles = makeStyles({
  formWrapper: {
    flexGrow: 1,
    display: 'flex',
    justifyContent: 'center',
    '& > form': {
      width: '520px',
    },
    overflowY: 'auto',
  },
});

export const BasicDetails: FC = () => {
  const { formWrapper } = useStyles();
  const createScanFormContext = useContext(CreateScanWizardContext);
  const formProps = useBasicDetailsFormProps(createScanFormContext?.scanWizardState?.supportedScanTypes);

  const formMemoized = useMemo(() => <BigidForm {...formProps} />, [formProps]);

  return (
    <div data-aid="ScanTemplateBasicDetailsStep" className={formWrapper}>
      {formMemoized}
    </div>
  );
};
