import React, { FC } from 'react';
import styled from '@emotion/styled';
import { PrimaryButton, SecondaryButton, StyledButtonType, TertiaryButton } from '@bigid-ui/components';
import { BigidDeleteIcon } from '@bigid-ui/icons';
import { noop } from 'lodash';
import { useLocalTranslation } from '../translations';
import { getPermissions } from './utils';
import { generateDataAid } from '@bigid-ui/utils';
import { BigidDateTime } from '@bigid-ui/i18n';
import { BrandsDialogContent } from './BrandsDialogContent';
import { BigidSystemDialogOptions, openSystemDialog } from '../../../services/systemDialogService';
import { BrandGridRow } from './brandsTypes';
import { EmailTemplateTrackingEvents } from '../emailTemplateTrackingUtils';

export type BrandsActionsProps = {
  row: BrandGridRow;
  isBusy: boolean;
  handleSubmit: () => void;
  handleDelete: () => void;
};

const Actions = styled('div')({
  display: 'flex',
  alignItems: 'center',
  gap: '1rem',
  flexShrink: 0,
});

const Buttons = styled('div')({
  display: 'flex',
  ali: 'center',
  gap: '0.5rem',
  justifyContent: 'flex-end',
  flexGrow: 1,
});

const UpdatedAt = styled('span')(({ theme }) => ({
  fontSize: '12px',
  color: theme.vars.palette.bigid.gray600,
}));

export const BrandsActions: FC<BrandsActionsProps> = ({ row, isBusy, handleSubmit, handleDelete }) => {
  const { t } = useLocalTranslation();
  const { isDeletePermitted, isCreatePermitted, isEditPermitted } = getPermissions();

  const showConfirmDeleteDialog = () => {
    const dialogOptions: BigidSystemDialogOptions = {
      title: t('modal.delete.title'),
      onClose: noop,
      maxWidth: 'xs',
      buttons: [
        {
          component: SecondaryButton,
          dataAid: generateDataAid('BrandsActions', ['delete', 'cancel']),
          isClose: true,
          onClick: noop,
          text: t('buttons.cancel'),
        },
        {
          component: PrimaryButton,
          dataAid: generateDataAid('BrandsActions', ['delete', 'confirm']),
          isClose: true,
          disabled: !isDeletePermitted,
          onClick: handleDelete,
          text: t('buttons.delete'),
        },
      ],
      content: BrandsDialogContent,
      contentProps: { body: t('modal.delete.body', { entityName: row.name }) },
      borderTop: true,
    };

    openSystemDialog(dialogOptions);
  };

  const actions: (StyledButtonType & { component: FC<StyledButtonType> })[] = [
    {
      component: TertiaryButton,
      dataAid: generateDataAid('BrandsActions', ['delete']),
      startIcon: <BigidDeleteIcon />,
      disabled: !isDeletePermitted || isBusy,
      onClick: () => showConfirmDeleteDialog(),
      size: 'medium',
      text: t('buttons.delete'),
      bi: { disabled: true },
    },
    {
      component: SecondaryButton,
      dataAid: generateDataAid('BrandsActions', ['save']),
      disabled: (row?.isPending ? !isCreatePermitted : !isEditPermitted) || isBusy,
      onClick: handleSubmit,
      size: 'medium',
      text: t('buttons.save'),
      bi: { disabled: true },
    },
  ];

  return (
    <Actions>
      {row.updated_at && (
        <UpdatedAt>
          {`${t('lastUpdated')}: `}
          <BigidDateTime date={row.updated_at}></BigidDateTime>
        </UpdatedAt>
      )}
      <Buttons>
        {actions.map(({ component: Component, ...rest }) => (
          <Component key={rest.dataAid} {...rest} />
        ))}
      </Buttons>
    </Actions>
  );
};
