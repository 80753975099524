import { BigidLineageDiagramLinkConnector } from '@bigid-ui/visualisation';
import {
  AttributesEnrichmentCreateFlowItem,
  AttributesEnrichmentDiagramLink,
  AttributesEnrichmentDiagramNode,
  DiagramData,
  SystemAttributeDiagramPort,
} from '../../Types';
import { nodeIdToSource, portIdToAttributeOriginalName } from './idsMapper';

interface DiagramToFlowHelper extends Pick<AttributesEnrichmentDiagramNode, 'origData'> {
  id: string;
  index: number;
  isRoot: boolean;
  sourceName: string;
  displayName: string;
  ports: Map<string, SystemAttributeDiagramPort>;
}

export const diagramToFlow = (
  diagramData: Readonly<DiagramData>,
  flowName: string,
): AttributesEnrichmentCreateFlowItem[] => {
  const { nodes, links } = diagramData;

  const nodesMap = new Map<string, DiagramToFlowHelper>(
    nodes.map((node, index) => {
      const id = node.id as string;
      const { displayName, ports, origData } = node;
      const sourceName = nodeIdToSource(`${id}`);
      return [
        id,
        {
          id,
          index,
          sourceName,
          isRoot: origData?.isRoot ?? sourceName === flowName,
          displayName,
          origData,
          ports: new Map(ports?.map(port => [port.id as string, port])),
        },
      ];
    }),
  );

  const flows = new Map<string, AttributesEnrichmentCreateFlowItem>();

  links.forEach(link => {
    const { to, from } = link;
    const fromNode = nodesMap.get(from.nodeId as string);
    const toNode = nodesMap.get(to.nodeId as string);
    const source = toNode.sourceName;
    const origin = fromNode.isRoot ? 'input' : fromNode.sourceName;
    if (!flows.has(source)) {
      flows.set(toNode.sourceName, {
        source,
        fieldsMapping: [],
      });
    }
    const { fieldsMapping } = flows.get(source);
    const attributeOriginalNameLink = linkToAttributeOriginalNameLink(nodesMap, link);
    fieldsMapping.push({
      to: attributeOriginalNameLink.to,
      from: attributeOriginalNameLink.from,
      origin,
    });
  });

  return [...flows.values()];
};

const linkToAttributeOriginalNameLink = (
  nodes: Map<string, DiagramToFlowHelper>,
  link: AttributesEnrichmentDiagramLink,
) => {
  const defaults = {
    from: portIdToAttributeOriginalName(link.from.id),
    to: portIdToAttributeOriginalName(link.to.id),
  };

  const from = getOriginalNameFromHelperNode(nodes, link.from);
  const to = getOriginalNameFromHelperNode(nodes, link.to);
  return {
    from: from || defaults.from,
    to: to || defaults.to,
  };
};

const getOriginalNameFromHelperNode = (
  nodes: Map<string, DiagramToFlowHelper>,
  link: BigidLineageDiagramLinkConnector,
) => {
  const node = nodes.get(link.nodeId as string);
  const port = node.ports?.get(link.id as string);
  if (port && port.origData?.systemAttribute) {
    return port.origData.systemAttribute.attributeOriginalName;
  }
};
