import { format } from 'date-fns-tz';
import { getBigIdIcon, getBase64Image } from './dataMappingConsts.js';

import { module } from 'angular';

const app = module('app');

app.factory('dataMappingReportService', function (lazyLoadService, appSettings) {
  'ngInject';

  return {
    createPDFReport: function createPDFReport(vm, dataImage) {
      const title = vm.businessProcess.title;
      const currentdate = new Date();

      const docDefinition = {
        defaultStyle: lazyLoadService.setReportFont(),
        content: [
          {
            table: {
              widths: ['auto', '*'],
              body: [
                [
                  {
                    width: '*',
                    alignment: 'left',
                    stack: [
                      {
                        style: 'h1',
                        text: `Business Process Report : ${title}`,
                      },
                    ],
                  },
                  {
                    image: getBigIdIcon(),
                    width: 46,
                    alignment: 'right',
                    margin: [0, 0, 0, 0],
                  },
                ],
              ],
            },
            layout: {
              hLineWidth: function () {
                return 0;
              },
              vLineWidth: function () {
                return 0;
              },
              paddingBottom: function () {
                return 5;
              },
            },
            style: 'header',
          },
          { text: `${currentdate.toLocaleString()}`, style: 'subHeader' },
          //hr line
          {
            canvas: [
              {
                type: 'line',
                x1: 0,
                y1: 5,
                x2: 515,
                y2: 5,
                lineColor: 'grey',
                lineWidth: 0.05,
              },
            ],
          },
          getDataMappingImage(dataImage),
          {
            text: ``,
            //pageBreak: 'after'
          },
          drawLegends(vm),
          drawPropertiesTables(vm),
          { text: `` },
          getFlowsList(vm),
          drawUnconnectedEntities(vm),
        ],

        styles: {
          header: {
            bold: true,
            color: '#262D37',
            fontSize: 16,
          },
          subHeader: {
            bold: true,
            margin: [0, 5, 0, 0],
            color: '#717D89',
            fontSize: 7,
          },
          tableStyle: {
            margin: [0, 10, 0, 0],
          },
          tableLegendStyle: {
            margin: [0, 8, 0, 30],
          },
          tablePropertiesStyle: {
            margin: [0, 15, 0, 0],
          },

          tableImage: {
            margin: [0, 8, 0, 0],
          },
        },
      };

      const curDate = format(new Date(), 'hh:mm d/M/yyyy');
      lazyLoadService.loadPdfMake().then(pdfMake => {
        pdfMake.createPdf(docDefinition).download(`dataFlowReport_${curDate}.pdf`);
        vm.isReport = false;
      });
    },
  };

  function getDataMappingImage(dataImage) {
    return {
      style: 'tableImage',
      table: {
        widths: ['*'],
        body: [
          [
            {
              image: dataImage,
              fit: [500, 1000],
            },
          ],
        ],
      },
      layout: {
        hLineWidth: function (i, node) {
          return i === 0 || i === node.table.body.length ? 0.1 : 1;
        },
        vLineWidth: function (i, node) {
          return i === 0 || i === node.table.widths.length ? 0.1 : 1;
        },
        hLineColor: function (i, node) {
          return i === 0 || i === node.table.body.length ? '#cdd1e4' : 'gray';
        },
        vLineColor: function (i, node) {
          return i === 0 || i === node.table.widths.length ? '#cdd1e4' : 'gray';
        },
      },
    };
  }

  function getUnconnectedEntities(vm, drawingObjectsArray) {
    let connectedEntitiesID = [];
    //1.Go through the connectors
    if (vm.connectors) {
      vm.connectors.forEach(connector => {
        let attributesToCheck = ['from', 'to'];
        //1.1 in each connectors collect the from and to object and check if they are not already exists in the array of the ids we built.
        attributesToCheck.forEach(attributeToCheck => {
          if (!connectedEntitiesID.find(item => item === connector[attributeToCheck])) {
            connectedEntitiesID.push(connector[attributeToCheck]);
          }
        });
      });
    }
    //2.After we created the ids array we will find the entities that are not exist in the array and returned from the entities array.
    if (vm.entities) {
      vm.entities.forEach(entity => {
        if (!connectedEntitiesID.find(connectedEntityID => connectedEntityID === entity._id)) {
          //3.built a function that build the object with the annotations and attributes belong to the entity.
          drawingObjectsArray.push(buildSingleEntity(entity));
        }
      });
    }
    return drawingObjectsArray;
  }

  function buildSingleEntity(entity) {
    let description = getDescription(entity);

    let singleEntity = [
      //Entity Block - Column
      {
        border: [false, false, true],
        table: {
          widths: ['*', '82%'],
          body: [[]],
        },
        layout: {
          hLineWidth: function (i, node) {
            return 1;
          },
          vLineWidth: function (i, node) {
            return 1;
          },
          hLineColor: function (i, node) {
            return 'gray';
          },
          vLineColor: function (i, node) {
            return 'gray';
          },
        },
      },
      //Annotations - Column
      {
        border: [false, false, true],
        margin: [5, 0, 0, 0],
        table: {
          widths: ['*'],
          body: getAnnotationList(entity),
        },
      },
      //Attributes (Personal Data Used) - Column
      {
        border: [false],
        margin: [10, 0, 0, 0],
        color: 'grey',
        table: {
          body: getPersonalDataList(entity),
        },
        layout: {
          hLineWidth: function (i, node) {
            return 1;
          },
          vLineWidth: function (i, node) {
            return 1;
          },
          hLineColor: function (i, node) {
            return 'gray';
          },
          vLineColor: function (i, node) {
            return 'gray';
          },
        },
      },
    ];

    let itemPicture = {
      border: [true, true, false, true],
      fillColor: '#f1f3f7',
      image: getBase64Image(entity),
      width: 25,
    };

    let itemName = {
      margin: [0, 6, 0, 0],
      border: [false, true, true, true],
      fillColor: '#f1f3f7',
      fontSize: 10,
      text: getEntityName(entity),
    };

    if (description) {
      itemPicture.border = [true, true, false, false];
      itemName.border = [false, true, true, false];

      singleEntity[0].table.body[0].push(itemPicture);
      singleEntity[0].table.body[0].push(itemName);
      let item = [
        {
          colSpan: 2,
          margin: [0, 6, 0, 0],
          border: [true, false, true, true],
          fillColor: '#f1f3f7',
          fontSize: 10,
          text: `${description}`,
        },
      ];
      singleEntity[0].table.body.push(item);
    } else {
      singleEntity[0].table.body[0].push(itemPicture);
      singleEntity[0].table.body[0].push(itemName);
    }

    return singleEntity;
  }

  function getEntityName(entity) {
    if (entity.name) {
      if (entity.name.length > 20) {
        return `${entity.name.substring(0, 20)}...`;
      }
      return entity.name;
    }
  }

  function drawLegends(vm) {
    let businessLegendsBorderTable = [
      {
        style: 'tableLegendStyle',
        table: {
          widths: ['*'],
          border: [true, true, true, true],
          //dontBreakRows: true,
          body: [
            //Legend Table
            [
              {
                style: 'tableStyle',
                pageBreak: 'after',
                table: {
                  fontSize: 8,
                  //widths: ['5','245'],
                  border: [false, false, false, false],
                  body: getLegendsItems(vm),
                },
                layout: {
                  hLineWidth: function (i, node) {
                    return 0;
                  },
                  vLineWidth: function (i, node) {
                    return 0;
                  },
                  hLineColor: function (i, node) {
                    return i === 0 || i === node.table.body.length ? '#cdd1e4' : 'cdd1e4';
                  },
                  vLineColor: function (i, node) {
                    return i === 0 || i === node.table.widths.length ? '#cdd1e4' : 'cdd1e4';
                  },
                },
              },
            ],
          ],
        },
        layout: {
          hLineWidth: function (i, node) {
            return i === 0 || i === node.table.body.length ? 0.1 : 1;
          },
          vLineWidth: function (i, node) {
            return i === 0 || i === node.table.widths.length ? 0.1 : 1;
          },
          hLineColor: function (i, node) {
            return i === 0 || i === node.table.body.length ? '#cdd1e4' : 'cdd1e4';
          },
          vLineColor: function (i, node) {
            return i === 0 || i === node.table.widths.length ? '#cdd1e4' : 'cdd1e4';
          },
        },
      },
    ];

    return businessLegendsBorderTable;
  }

  function drawPropertiesTables(vm) {
    let businessLegendsBorderTable = [
      {
        style: 'tableStyle',
        table: {
          widths: ['*'],
          border: [true, true, true, true],
          //dontBreakRows: true,
          body: [
            //Legend Table
            [
              //Properties Table
              {
                style: 'tablePropertiesStyle',
                pageBreak: 'after',
                table: {
                  //widths: ['5','245'],
                  border: [false, false, false, false],
                  body: getPropertiesItems(vm),
                },
                layout: {
                  hLineWidth: function (i, node) {
                    return 0;
                  },
                  vLineWidth: function (i, node) {
                    return 0;
                  },
                  hLineColor: function (i, node) {
                    return i === 0 || i === node.table.body.length ? '#cdd1e4' : 'cdd1e4';
                  },
                  vLineColor: function (i, node) {
                    return i === 0 || i === node.table.widths.length ? '#cdd1e4' : 'cdd1e4';
                  },
                },
              },
            ],
          ],
        },
        layout: {
          hLineWidth: function (i, node) {
            return i === 0 || i === node.table.body.length ? 0.1 : 1;
          },
          vLineWidth: function (i, node) {
            return i === 0 || i === node.table.widths.length ? 0.1 : 1;
          },
          hLineColor: function (i, node) {
            return i === 0 || i === node.table.body.length ? '#cdd1e4' : 'cdd1e4';
          },
          vLineColor: function (i, node) {
            return i === 0 || i === node.table.widths.length ? '#cdd1e4' : 'cdd1e4';
          },
        },
      },
    ];

    return businessLegendsBorderTable;
  }

  function getLegendsItems(vm) {
    let legendItemsArray = [];
    legendItemsArray.push([
      { text: 'Legend:', border: [true, true, true, true], fontSize: 12, margin: [0, 0, 0, 8], colSpan: 2 },
      {},
    ]);
    let activeFlows = vm.businessFlows.filter(flow => !flow.hidden);
    if (activeFlows) {
      activeFlows.forEach(flow => {
        legendItemsArray.push([
          {
            border: [true, false, true, true],
            fillColor: flow.color,
            text: '',
          },
          {
            border: [true, false, true, true],
            text: flow.name,
            fontSize: 8,
          },
        ]);
      });
    }
    return legendItemsArray;
  }

  function getPropertiesItems(vm) {
    let propertiesItemsArray = [];
    propertiesItemsArray.push([
      { text: 'Properties:', border: [true, true, true, true], fontSize: 12, margin: [0, 0, 0, 8], colSpan: 2 },
      {},
    ]);
    propertiesItemsArray.push([
      {
        border: [false, false, false, false],
        margin: [10, 0, 0, 10],
        text: 'Description:',
        fontSize: 10,
      },
      {
        border: [false, false, false, false],
        margin: [10, 0, 0, 10],
        text: vm.businessProcess.description || '',
        fontSize: 10,
      },
    ]);
    propertiesItemsArray.push([
      {
        border: [false, false, false, false],
        margin: [10, 0, 0, 10],
        text: 'Owner Cell:',
        fontSize: 10,
      },
      {
        border: [false, false, false, false],
        margin: [10, 0, 0, 10],
        text: vm.businessProcess.ownerCell || '',
        fontSize: 10,
      },
    ]);
    propertiesItemsArray.push([
      {
        border: [false, false, false, false],
        margin: [10, 0, 0, 10],
        text: 'Owner Email:',
        fontSize: 10,
      },
      {
        border: [false, false, false, false],
        margin: [10, 0, 0, 10],
        text: vm.businessProcess.ownerEmail || '',
        fontSize: 10,
      },
    ]);
    propertiesItemsArray.push([
      {
        border: [false, false, false, false],
        margin: [10, 0, 0, 10],
        text: 'Annotations:',
      },
      {
        border: [false, false, false, false],
        margin: [5, 0, 0, 0],
        table: {
          widths: ['*'],
          body: getAnnotationList(vm.businessProcess),
        },
      },
    ]);
    return propertiesItemsArray;
  }

  function drawUnconnectedEntities(vm) {
    let drawingObjectsArray = [];
    drawingObjectsArray.push(getHeadlinesOfTheFlow(null, 'Unconnected Entities'));
    return [
      {
        style: 'tableStyle',
        pageBreak: 'before',
        table: {
          widths: ['*', '*', '*'],
          dontBreakRows: true,
          body: getUnconnectedEntities(vm, drawingObjectsArray),
        },
        layout: {
          hLineWidth: function (i, node) {
            return i === 0 || i === node.table.body.length ? 2 : 1;
          },
          vLineWidth: function (i, node) {
            return i === 0 || i === node.table.widths.length ? 2 : 1;
          },
          hLineColor: function (i, node) {
            return i === 0 || i === node.table.body.length ? 'black' : 'gray';
          },
          vLineColor: function (i, node) {
            return i === 0 || i === node.table.widths.length ? 'black' : 'gray';
          },
        },
      },
    ];
  }

  function getFlowsList(vm) {
    let flowArray = vm.businessFlows
      .filter(flow => !flow.hidden)
      .map(flow => ({
        style: 'tableStyle',
        table: {
          widths: ['*', '*', '*'],
          dontBreakRows: true,
          pageBreak: 'before',
          body: getTableEntityConnectorEntity(flow, vm),
        },
        layout: {
          hLineWidth: function (i, node) {
            return i === 0 || i === node.table.body.length ? 2 : 1;
          },
          vLineWidth: function (i, node) {
            return i === 0 || i === node.table.widths.length ? 2 : 1;
          },
          hLineColor: function (i, node) {
            return i === 0 || i === node.table.body.length ? 'black' : 'gray';
          },
          vLineColor: function (i, node) {
            return i === 0 || i === node.table.widths.length ? 'black' : 'gray';
          },
        },
      }));
    return flowArray;
  }

  function getTableEntityConnectorEntity(flow, vm) {
    let toObjectConnectorArray = [];
    let drawingObjectsArray = [];
    let connectorsRelatedToFlow = vm.connectors
      .filter(connector => connector.flowId === flow._id)
      .sort((a, b) => a.stepNumber - b.stepNumber);

    //headlines for the flow
    drawingObjectsArray.push(getHeadlinesOfTheFlow(flow));
    connectorsRelatedToFlow.forEach(connector => {
      //From Object
      //if (!toObjectConnectorArray.find((toObjectConnector) => toObjectConnector === connector.from)) {
      let selectedEntityFrom = vm.entities.find(entity => connector.from === entity._id);
      if (selectedEntityFrom) {
        let numberOfNewLines = getNumberOfNewLines(selectedEntityFrom);
        let description = getDescription(selectedEntityFrom);

        let fromEntity = [
          //Entity Block - Column
          {
            border: [false, false, true],
            table: {
              //widths: ['*', '80%'],
              widths: ['*', '*'],
              body: [
                [
                  {
                    border: [true, true, false, true],
                    fillColor: '#f1f3f7',
                    image: getBase64Image(selectedEntityFrom),
                    width: 25,
                  },
                ],
              ],
            },
            layout: {
              hLineWidth: function (i, node) {
                return 1;
              },
              vLineWidth: function (i, node) {
                return 1;
              },
              hLineColor: function (i, node) {
                return 'gray';
              },
              vLineColor: function (i, node) {
                return 'gray';
              },
            },
          },
          //Annotations - Column
          {
            border: [false, false, true],
            margin: [5, 0, 0, 0],
            table: {
              widths: ['*'],
              body: getAnnotationList(selectedEntityFrom),
            },
          },
          //Attributes - Column
          {
            border: [false],
            margin: [10, 0, 0, 0],
            color: 'grey',
            table: {
              body: getPersonalDataList(selectedEntityFrom),
            },
            layout: {
              hLineWidth: function (i, node) {
                return 1;
              },
              vLineWidth: function (i, node) {
                return 1;
              },
              hLineColor: function (i, node) {
                return 'gray';
              },
              vLineColor: function (i, node) {
                return 'gray';
              },
            },
          },
        ];
        let entityName = {
          margin: [0, 6, 0, 0],
          border: [false, true, true, true],
          fillColor: '#f1f3f7',
          fontSize: 10,
          text: `${selectedEntityFrom.name}`,
        };

        if (description || numberOfNewLines) {
          fromEntity[0].table.body[0][0].border = [true, true, false, false];
          //remove bottom border
          entityName.border = [false, true, true, false];
        }
        if (description) {
          let item = [
            {
              colSpan: 2,
              margin: [0, 6, 0, 0],
              border: [true, false, true, false],
              fillColor: '#f1f3f7',
              fontSize: 10,
              text: `${description}`,
            },
          ];
          if (!numberOfNewLines && numberOfNewLines.length === 0) {
            item[0].border = [true, false, true, true];
          }
          fromEntity[0].table.body.push(item);
        }
        if (numberOfNewLines) {
          let numberOfNewLinesItem = [
            {
              colSpan: 2,
              margin: [0, 6, 0, 0],
              border: [true, false, true, true],
              fillColor: '#f1f3f7',
              fontSize: 10,
              text: `${numberOfNewLines}`,
            },
          ];
          fromEntity[0].table.body.push(numberOfNewLinesItem);
        }
        fromEntity[0].table.body[0].push(entityName);
        //add from object to the drawing object
        drawingObjectsArray.push(fromEntity);
      }
      //}

      //Connector Object
      let connectorColor = vm.businessFlows.find(businessFlow => businessFlow._id === connector.flowId).color;
      let stepNumber = connector.stepNumber + '.';
      let connectorEntity = [
        {
          border: [false, false, true],
          margin: [20, 0, 0, 0],
          table: {
            body: [
              [
                {
                  border: [true, false],
                  text: [
                    ' \n 	',
                    {
                      text: `${stepNumber}`,
                      color: `${connectorColor}`,
                    },
                    ` ${connector.description || ''} \n\n`,
                  ],
                  color: 'black',
                  fontSize: 8,
                },
              ],
            ],
          },
          layout: {
            hLineWidth: function (i, node) {
              return 1;
            },
            vLineWidth: function (i, node) {
              return 1;
            },
            hLineColor: function (i, node) {
              return 'gray';
            },
            vLineColor: function (i, node) {
              return `${connectorColor}`;
            },
          },
        },
        {
          border: [false, false, true],
          //margin: [0, 0, 0, 0],
          text: '',
        },
        {
          border: [false, false, false],
          //margin: [0, 0, 0, 0],
          text: '',
        },
      ];
      drawingObjectsArray.push(connectorEntity);

      //To Object
      let selectedEntityTo = vm.entities.find(entity => connector.to === entity._id);
      if (selectedEntityTo) {
        let description = getDescription(selectedEntityTo);
        let toEntity = [
          {
            border: [false, false, true],

            table: {
              widths: ['*', '*'],
              body: [
                [
                  {
                    border: [true, true, false, true],
                    fillColor: '#f1f3f7',
                    image: getBase64Image(selectedEntityTo),
                    width: 25,
                  },
                ],
              ],
            },
            layout: {
              hLineWidth: function (i, node) {
                return 1;
              },
              vLineWidth: function (i, node) {
                return 1;
              },
              hLineColor: function (i, node) {
                return 'gray';
              },
              vLineColor: function (i, node) {
                return 'gray';
              },
            },
          },
          //Annotations - Column
          {
            border: [false, false, true],
            margin: [5, 0, 0, 0],
            table: {
              widths: ['*'],
              body: getAnnotationList(selectedEntityTo),
            },
          },
          //Attributes - Column
          {
            border: [false],
            margin: [10, 0, 0, 0],
            color: 'grey',
            table: {
              body: getPersonalDataList(selectedEntityTo),
            },
            layout: {
              hLineWidth: function (i, node) {
                return 1;
              },
              vLineWidth: function (i, node) {
                return 1;
              },
              hLineColor: function (i, node) {
                return 'gray';
              },
              vLineColor: function (i, node) {
                return 'gray';
              },
            },
          },
        ];

        let entityName = {
          margin: [0, 6, 0, 0],
          border: [false, true, true, true],
          fillColor: '#f1f3f7',
          fontSize: 10,
          text: `${selectedEntityTo.name}`,
        };

        if (description) {
          toEntity[0].table.body[0][0].border = [true, true, false, false];
          entityName.border = [false, true, true, false];
          let item = [
            {
              colSpan: 2,
              margin: [0, 6, 0, 0],
              border: [true, false, true, true],
              fillColor: '#f1f3f7',
              fontSize: 10,
              text: `${description}`,
            },
          ];
          toEntity[0].table.body.push(item);
        }
        toEntity[0].table.body[0].push(entityName);

        drawingObjectsArray.push(toEntity);
        //toObjectConnectorArray.push(connector.to);
      }
    });

    return drawingObjectsArray;
  }

  function getAnnotationList(entity) {
    let annotationList = [];
    let annotationCategoryItem = [
      {
        text: '',
        color: '#717d89',
        border: [false],
        fontSize: 7,
      },
    ];
    let annotationTitleItem = [
      {
        text: '',
        border: [false],
        fontSize: 7,
      },
    ];
    let annotationDescriptionItem = [
      {
        text: '',
        color: '#4d595e',
        border: [false],
        fontSize: 7,
      },
    ];
    if (entity.annotations && entity.annotations.length > 0) {
      let uniqueCategories = [...new Set(entity.annotations.map(annotation => annotation.category))];
      if (uniqueCategories && uniqueCategories.length > 0) {
        uniqueCategories.forEach((category, idx) => {
          let categoryItems = entity.annotations.filter(annotation => annotation.category === category);
          annotationCategoryItem[0].text = category;
          //Add Category Headline
          let copyAnnotationCategoryItem = JSON.parse(JSON.stringify(annotationCategoryItem));
          annotationList.push(copyAnnotationCategoryItem);
          categoryItems.forEach(categoryItem => {
            annotationTitleItem[0].text = `${idx + 1}.   ${categoryItem.title}`;
            annotationDescriptionItem[0].text = categoryItem.description || '';
            let copyAnnotationTitleItem = JSON.parse(JSON.stringify(annotationTitleItem));
            //Add Title Item
            annotationList.push(copyAnnotationTitleItem);
            let copyAnnotationDescriptionItem = JSON.parse(JSON.stringify(annotationDescriptionItem));
            //Add Description Item
            annotationList.push(copyAnnotationDescriptionItem);
          });
        });
      }
    }
    if (annotationList.length == 0) {
      annotationList.push(annotationCategoryItem);
    }
    return annotationList;
  }

  function getDescription(entity) {
    if (entity && entity.description) {
      return `\n\n ${entity.description}`;
    }
    return '';
  }

  function getNumberOfNewLines(entity) {
    let sumOfNewLinesForAttributes = 0;
    let sumOfNewLinesForAnnotations = 0;
    let result = '';
    if (entity && entity.attributes && entity.attributes.length > 0) {
      sumOfNewLinesForAttributes = entity.attributes.length;
    }
    if (entity && entity.attributesFromSystem && entity.attributesFromSystem.length > 0) {
      sumOfNewLinesForAttributes += entity.attributesFromSystem.length;
    }
    if (entity && entity.annotations && entity.annotations.length > 0) {
      sumOfNewLinesForAnnotations = entity.annotations.length * 3;
    }
    if (sumOfNewLinesForAnnotations > 0 || sumOfNewLinesForAttributes > 0) {
      if (sumOfNewLinesForAnnotations > sumOfNewLinesForAttributes) {
        result = getNumberOfNewLinesAsString(sumOfNewLinesForAnnotations + 1);
      } else {
        result = getNumberOfNewLinesAsString(sumOfNewLinesForAttributes + 1);
      }
    }
    return result;
  }

  function getNumberOfNewLinesAsString(quantity) {
    let result = '';
    for (let i = 0; i < quantity; i++) {
      result += '\n';
    }
    return result;
  }

  function getPersonalDataList(entity) {
    let entityClone = JSON.parse(JSON.stringify(entity));
    let personalListArray = [];
    let attriubtesListArray = [];
    let categoryListArray = [];
    let attributeItemArray = [
      {
        //attribute Name
        text: '',
        fontSize: 7,
      },
      {
        //purpose
        text: '',
        fontSize: 7,
        border: [false],
      },
    ];
    let categoryItemArray = [
      {
        //category Name
        text: '',
        fillColor: '#f1f3f7',
        fontSize: 7,
      },
      {
        //purpose
        text: '',
        fontSize: 7,
        border: [false],
      },
    ];
    let personalDataSpaceArray = [
      {
        text: '',
        margin: [0, 0, 0, 5],
        border: [false],
      },
      {
        text: '',
        border: [false],
      },
    ];
    let excluded_attributes = [];

    if (entityClone.attributes && entityClone.attributes.length > 0) {
      excluded_attributes = entityClone.attributes.filter(attribute => attribute.is_excluded);
      entityClone.attributes = entityClone.attributes.filter(attribute => !attribute.is_excluded);
      entityClone.attributes.sort((a, b) => a.attribute.localeCompare(b.attribute));
      entityClone.attributes.forEach(attribute => {
        let attributeItemArrayLocal = JSON.parse(JSON.stringify(attributeItemArray));
        if (entityClone.attributesFromSystem && entityClone.attributesFromSystem.length > 0) {
          let attributeFromSystem = entityClone.attributesFromSystem.find(
            attributeFromSystem => attributeFromSystem._id === attribute.attribute,
          );
          if (attributeFromSystem) {
            attribute.is_fromSystem = true;
          }
        }
        personalListArray = setAttributeItemArray(
          attribute,
          attributeItemArrayLocal,
          personalListArray,
          personalDataSpaceArray,
          true,
        );
      });
    }

    if (entityClone.attributesFromSystem && entityClone.attributesFromSystem.length > 0) {
      entityClone.attributesFromSystem = entityClone.attributesFromSystem.filter(attribute => !attribute.is_excluded);
      entityClone.attributesFromSystem.sort((a, b) => b.friendly_name.localeCompare(a.friendly_name));
      entityClone.attributesFromSystem.forEach(attributeFromSystem => {
        if (
          !entityClone.attributes.find(attributeItem => attributeItem.attribute === attributeFromSystem._id) &&
          !excluded_attributes.find(attributeItem => attributeItem.attribute === attributeFromSystem._id)
        ) {
          let attributeItemArrayLocal = JSON.parse(JSON.stringify(attributeItemArray));
          personalListArray = setAttributeItemArray(
            attributeFromSystem,
            attributeItemArrayLocal,
            personalListArray,
            personalDataSpaceArray,
            false,
          );
        }
      });
    }

    if (entityClone.categories && entityClone.categories.length > 0) {
      entityClone.categories.forEach(category => {
        let categoryItemArrayLocal = JSON.parse(JSON.stringify(categoryItemArray));
        categoryListArray = setCategoryItemArray(
          category,
          categoryItemArrayLocal,
          categoryListArray,
          personalDataSpaceArray,
          true,
        );
      });
    }

    if (categoryListArray && categoryListArray.length > 0) {
      personalListArray = personalListArray.concat(categoryListArray);
    }

    if (personalListArray && personalListArray.length === 0) {
      personalListArray.push(personalDataSpaceArray);
    }
    return personalListArray;
  }

  //FIXME: related to alphabetical sorting, causes bug, refactoring required
  // function orderAlphabeticAttributesList(attributesList,attributeItemArray,personalListArray,personalDataSpaceArray) {
  //   if ( attributesList && attributesList.length > 0 ) {
  //     attributesList.sort((a, b) => a.attribute.localeCompare(b.attribute));
  //     attributesList.forEach((attributeItem) => {
  //       let attributeItemArrayLocal = JSON.parse(JSON.stringify(attributeItemArray));
  //       personalListArray = setAttributeItemArray(attributeItem, attributeItemArrayLocal, personalListArray, personalDataSpaceArray,false);
  //     })
  //   }
  // }

  function setCategoryItemArray(
    category,
    categoryItemArray,
    categoriesListArray,
    personalDataSpaceArray,
    isNotCategoryFromSystem,
  ) {
    let isExist = false;
    let categoriesListArrayLocal = JSON.parse(JSON.stringify(categoriesListArray));
    categoriesListArrayLocal.forEach(categoriesListArray => {
      categoriesListArray.forEach((item, index) => {
        if (index === 0 && (item.text === category.category || item.text === category._id)) {
          isExist = true;
          categoriesListArrayLocal.length = 0;
        }
      });
    });
    if (!isExist) {
      if (categoriesListArray.length === 0) {
        let categoryItemArrayEmpty = JSON.parse(JSON.stringify(categoryItemArray));
        categoryItemArrayEmpty[0].border = [false];
        categoryItemArrayEmpty[0].fillColor = '#FFF';
        categoryItemArrayEmpty[0].text = 'Categories:';
        let categorySpaceArrayEmpty = JSON.parse(JSON.stringify(personalDataSpaceArray));
        categoriesListArray.push(categoryItemArrayEmpty);
        categoriesListArray.push(categorySpaceArrayEmpty);
      }
      categoryItemArray[0].text = category.category || category._id || '';
      isNotCategoryFromSystem && (categoryItemArray[1].text = category.purpose || '');
      let copy = JSON.parse(JSON.stringify(categoryItemArray));
      categoriesListArray.push(copy);
      categoriesListArray.push(personalDataSpaceArray);
    }

    return categoriesListArray;
  }

  function setAttributeItemArray(
    attribute,
    attributeItemArray,
    attriubtesListArray,
    personalDataSpaceArray,
    isNotAttributeFromSystem,
  ) {
    let isExist = false;
    let attributesItemArrayLocal = JSON.parse(JSON.stringify(attriubtesListArray));
    attributesItemArrayLocal.forEach(attriubtesListArray => {
      attriubtesListArray.forEach((item, index) => {
        if (
          index === 0 &&
          (item.text === attribute.attribute || item.text === attribute.friendly_name || item.text === attribute._id)
        ) {
          isExist = true;
          attributesItemArrayLocal.length = 0;
        }
      });
    });
    if (!isExist) {
      if (attriubtesListArray.length === 0) {
        let attributeItemArrayEmpty = JSON.parse(JSON.stringify(attributeItemArray));
        attributeItemArrayEmpty[0].border = [false];
        attributeItemArrayEmpty[0].text = 'Attributes:';
        let attributeSpaceArrayEmpty = JSON.parse(JSON.stringify(personalDataSpaceArray));
        attriubtesListArray.push(attributeItemArrayEmpty);
        attriubtesListArray.push(attributeSpaceArrayEmpty);
      }
      attributeItemArray[0].text = attribute.attribute || attribute.friendly_name || attribute._id || '';
      if (!attribute.is_fromSystem) {
        attributeItemArray[0].color = '#2196F3';
      }
      isNotAttributeFromSystem && (attributeItemArray[1].text = attribute.purpose || '');
      let copy = JSON.parse(JSON.stringify(attributeItemArray));
      if (isNotAttributeFromSystem) {
        attriubtesListArray.push(copy);
        attriubtesListArray.push(personalDataSpaceArray);
      } else {
        attriubtesListArray.splice(1, 0, personalDataSpaceArray, copy);
      }
    }

    return attriubtesListArray;
  }

  function getHeadlinesOfTheFlow(flow, text) {
    return [
      {
        border: [false, false, true],
        margin: [0, 0, 0, 10],
        text: `${flow ? flow.name : text}:`,
      },
      {
        border: [false, false, true],
        margin: [10, 0, 0, 10],
        text: 'Annotations:',
      },
      {
        border: [false],
        margin: [10, 0, 0, 10],
        text: 'Personal Data Used:',
      },
    ];
  }
});
