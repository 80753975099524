import React from 'react';
import styled from '@emotion/styled';

export const SuggestedActionItemWrapper = styled.div``;

export const SuggestedActionItemContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;

export const SuggestedActionItemActions = styled.div`
  display: flex;
  gap: 0.5rem;
  overflow: hidden;
  align-items: center;
`;

export const SuggestedActionItemSeparator = styled.div`
  border-left: ${({ theme }) => `1px solid ${theme.vars.palette.bigid.gray200}`};
  height: 1rem;
`;

export const SuggestedActionsWrapper = styled.div`
  & .MuiAccordionDetails-root {
    height: auto !important;
    min-height: 0 !important;
  }
`;
