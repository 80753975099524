import template from './consentLog.component.html';
import { module } from 'angular';
const app = module('app');

app.component('consentLog', {
  template,
  controllerAs: 'consentLogModel',
  controller: [
    '$http',
    'appSettings',
    'consentLogService',
    '$scope',
    '$rootScope',
    'queryStringService',
    'localStorageService',
    function ($http, appSettings, consentLogService, $scope, $rootScope, queryStringService, localStorageService) {
      var consentLogModel = this;
      $scope.consentLogModel = {
        enableSorting: true,
        enablePaginationControls: false,
        columnDefs: [
          { name: 'Date', field: 'date' },
          { name: 'Location', field: 'location' },
          { name: 'Device', field: 'device' },
          {
            name: 'Details',
            field: 'details',
            cellTemplate:
              '<div style="padding: 3px 0 0 10px;">' + '<a ui-sref="dashboard">{{row.entity.details}}</a>' + '</div>',
          },
          {
            name: 'Screenshot',
            field: 'screenshot_url',
            cellTemplate:
              '<div style="padding: 3px 0 0 10px;">' +
              '<a ui-sref="dashboard">{{row.entity.screenshot_url}}</a>' +
              '</div>',
          },
        ],
      };

      consentLogModel.$onInit = function () {
        $scope.loading = true;
        getConsentLogData();
      };

      function getConsentLogData() {
        consentLogService.getConsentLogData(consentLogModel.id).then(function (result) {
          var results = result.consent_logs;
          //var results = [{"id":"full_name","name":"full_name","count":322,"avg":69.2080745341615,"max":82.5},{"id":"ssn","name":"ssn","count":340,"avg":68.55882352941177,"max":82.5},{"id":"zipcode","name":"zipcode","count":338,"avg":58.57988165680474,"max":72.5}];

          $scope.consentLogModel.data = results;
          $scope.loading = false;
        });
      }
    },
  ],

  bindings: {
    id: '<',
  },
});
