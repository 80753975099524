import React from 'react';
import { BigidAvatar, BigidAvatarColor, BigidBody1, BigidChip, BigidColorSchemeTokens } from '@bigid-ui/components';
import { styled } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
  tooltipPopper: {
    '& > div': {
      padding: '16px',
      borderRadius: '8px',
    },
  },
});

const Tooltip = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  flexWrap: 'wrap',
  gap: '4px',

  '& > div': {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
});
const Avatar = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  gap: '6px',
});

const colors: BigidAvatarColor[] = [
  'purple',
  'blue',
  'magenta',
  'red',
  'orange',
  'cyan',
  'dark_blue',
  'dark_purple',
  'light_purple',
  'light_gray',
];

const getUsernameColor = (username: string) => {
  const index = username?.split('').reduce((acc, c) => acc + c.charCodeAt(0), 0) % colors.length;
  return index ? colors[index] : colors[0];
};

export const AvatarTooltip = ({ tooltipUsers }: { tooltipUsers: string[] }) => {
  return (
    <Tooltip>
      {tooltipUsers.map((tooltipUser, index) => (
        <Avatar key={index}>
          <BigidAvatar color={getUsernameColor(tooltipUser)} size="small" userName={tooltipUser} />{' '}
          <BigidBody1>{tooltipUser}</BigidBody1>
        </Avatar>
      ))}
    </Tooltip>
  );
};

export const AvatarCell = ({ users }: { users: string[] }) => {
  const { tooltipPopper } = useStyles({});
  if (!users.length) return <div />;

  return (
    <Avatar>
      <BigidAvatar size="small" userName={users[0]} color={getUsernameColor(users[0])} />{' '}
      <BigidBody1>{users[0]}</BigidBody1>
      {users.length > 1 && (
        <BigidChip
          size="small"
          tooltipProps={{
            title: <AvatarTooltip tooltipUsers={users.slice(1)} />,
            classes: {
              popper: tooltipPopper,
            },
          }}
          label={`+${users.length - 1}`}
          outlineColor={BigidColorSchemeTokens.light.borderDefault}
          variant="outlined"
          bgColor={BigidColorSchemeTokens.light.backgroundPrimary}
        />
      )}
    </Avatar>
  );
};
