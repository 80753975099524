import React, { FC, useRef, useState, useEffect, useCallback, MutableRefObject } from 'react';
import { styled } from '@mui/material';
import { BigidForm, BigidFormStateAndHandlers, BigidFormValues, BigidFormValidateOnTypes } from '@bigid-ui/components';
import { generateDataAid } from '@bigid-ui/utils';
import { useLocalTranslation } from './translations';
import { UseCatalogDiscoveryResponse } from '../../../useCatalogDiscovery';
import { createPolicy, testPolicy, CreatePolicyPayload, TestPolicyPayload } from './definePolicyService';
import {
  getIsValueChanged,
  getCreatePolicyPayload,
  getTestPolicyPayload,
  showTestPolicyResults,
  getDefaultFormState,
} from './utils';
import { notificationService } from '../../../../../services/notificationService';
import { useDefinePolicyFormConfig } from './useDefinePolicyFormConfig';

export type DialogFormControl = {
  execute: () => void;
};

export type DefinePolicyFormStateAndControls = {
  isBusy?: boolean;
  onTest?: DialogFormControl;
  onSave?: DialogFormControl;
};

export interface DefinePolicyFormProps extends Pick<UseCatalogDiscoveryResponse, 'query'> {
  dataAid?: string;
  dialogControls: MutableRefObject<DefinePolicyFormStateAndControls>;
  onClose: () => void;
  onDialogControlsChange: (dialogControls: DefinePolicyFormStateAndControls) => void;
}

const Root = styled('div')`
  height: 70vh;
  flex: 1;
  position: relative;
`;

export const DefinePolicyForm: FC<DefinePolicyFormProps> = ({
  dataAid = 'DefinePolicyForm',
  query,
  dialogControls,
  onDialogControlsChange,
  onClose,
}) => {
  const { t } = useLocalTranslation('dialog.form');
  const formControls = useRef<BigidFormStateAndHandlers>();
  const [policyFormState, setPolicyFormState] = useState<BigidFormValues>(getDefaultFormState(query));
  const [isSubmittingForm, setIsSubmittingForm] = useState<boolean>(false);
  const { fields, isInitialisingForm } = useDefinePolicyFormConfig({
    query,
    policyFormState,
  });

  const handleFormChange = useCallback(
    async (values: BigidFormValues) => {
      const isFormChanged = getIsValueChanged(values, policyFormState);

      if (isFormChanged) {
        setPolicyFormState(values as BigidFormValues);
      }
    },
    [policyFormState],
  );

  const dialogFormControls: DefinePolicyFormStateAndControls = {
    isBusy: isInitialisingForm || isSubmittingForm,
    onTest: {
      execute: async () => {
        await formControls.current.validateAndSubmit(async formData => {
          try {
            setIsSubmittingForm(true);

            const payload: TestPolicyPayload = getTestPolicyPayload(formData);
            const result = await testPolicy(payload);

            showTestPolicyResults(result);
          } catch ({ message }) {
            console.error(`An error has occurred: ${message}`);
            notificationService.error(t('messages.testPolicyError'));
          } finally {
            setIsSubmittingForm(false);
          }
        });
      },
    },
    onSave: {
      execute: async () => {
        await formControls.current.validateAndSubmit(async formData => {
          try {
            setIsSubmittingForm(true);

            const payload: CreatePolicyPayload = getCreatePolicyPayload(formData);

            await createPolicy(payload);
            notificationService.success(t('messages.createPolicySuccess', { policyName: payload.name }));
            onClose();
          } catch ({ message }) {
            console.error(`An error has occurred: ${message}`);
            notificationService.error(t('messages.createPolicyError'));
          } finally {
            setIsSubmittingForm(false);
          }
        });
      },
    },
  };

  useEffect(() => {
    if (dialogControls) {
      onDialogControlsChange(dialogFormControls);
      dialogControls.current = dialogFormControls;
    }
  });

  return (
    <Root data-aid={generateDataAid(dataAid, ['form'])}>
      <BigidForm
        stateAndHandlersRef={formControls}
        onChange={handleFormChange}
        controlButtons={false}
        fields={fields}
        initialValues={policyFormState}
        validateOn={BigidFormValidateOnTypes.SUBMIT}
      />
    </Root>
  );
};
