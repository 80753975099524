import {
  BigidBody1,
  BigidColors,
  BigidFieldRenderProps,
  BigidFormFieldSingleCheckbox,
  compareObjectsExceptFunctions,
  generateFieldId,
} from '@bigid-ui/components';
import { FormControl } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React, { FC, memo } from 'react';

const useStyles = makeStyles({
  extraFieldWrapper: {
    padding: 0,
    width: '100%',
  },
  labelWrapper: ({ labelWidth }: Partial<BigidFieldRenderProps>) => ({
    minWidth: labelWidth,
    alignItems: 'center',
    display: 'inline-flex',
    paddingLeft: 8,
    margin: 0,
    color: BigidColors.gray[700],
  }),
  labelText: {
    color: BigidColors.gray[700],
  },
  root: {
    marginBottom: 20,
  },
});

export const BigidFormPaddedCheckbox: FC<BigidFieldRenderProps> = memo(
  ({ value, setValue, onBlur, disabled, misc, label, name, labelWidth, tooltipText, isRequired }) => {
    const classes = useStyles({ labelWidth });

    const htmlId = generateFieldId(name);
    return (
      <div className={classes.root}>
        <label className={classes.labelWrapper} htmlFor={htmlId}>
          <BigidBody1 className={classes.labelText}>{}</BigidBody1>
        </label>

        <FormControl fullWidth={misc?.fullWidth}>
          <div id={htmlId} data-aid={`BigidFormExtraField-name-${name}`} className={classes.extraFieldWrapper}>
            <BigidFormFieldSingleCheckbox
              value={value}
              fieldProps={{ label }}
              disabled={disabled ?? misc.disabled}
              onBlur={onBlur}
              setValue={newValue => setValue(newValue)}
            />
          </div>
        </FormControl>
      </div>
    );
  },
  compareObjectsExceptFunctions,
);

BigidFormPaddedCheckbox.displayName = 'BigidFormPaddedCheckbox';
