import React, { useCallback, useState, useEffect, ReactNode } from 'react';
import {
  BigidColorsV2,
  BigidDialog,
  BigidDropZone,
  BigidLink,
  BigidTextField,
  BigidTooltip,
  PrimaryButton,
  SecondaryButton,
} from '@bigid-ui/components';
import { FormLabel, Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { DsarSettingsTrackingEvents, trackDsar } from '../analyticsUtils';
import {
  createDictionary,
  downloadDictionary as downloadDictionaryService,
  downloadDictionaryTemplate as downloadDictionaryTemplateService,
  updateDictionary,
} from '../dsarService';
import { notificationService } from '../../../services/notificationService';
import { DsarDictionary } from '../DsarTypes';
import { BigidHelpIcon } from '@bigid-ui/icons';
import { SSE_EVENTS } from '../../../services/sseService';

const useStyles = makeStyles((theme: Theme) => ({
  wrapper: {
    display: 'flex',
    justifyContent: 'center',
    flexFlow: 'column nowrap',
    padding: theme.spacing(0, 1),
    gap: '26px',
  },
  asterisk: {
    color: BigidColorsV2.red[600],
    width: '10px',
    display: 'inline-block',
    textAlign: 'center',
  },
  error: {
    color: BigidColorsV2.red[600],
    marginTop: '-5px',
  },
  flexWrapper: {
    display: 'flex',
  },
}));

export interface DsarEditDictionaryDialogProps {
  selectedDictionary: DsarDictionary;
  isOpen: boolean;
  isNewDictionary: boolean;
  onSave?: () => void;
  onClose?: () => void;
}

interface Error {
  name: string;
  message: string;
}

const defaultErrorMessage = 'Mandatory field';
const requiredFields: Partial<keyof DsarDictionary>[] = ['name', 'fileName'];
const validate = (dictionary: DsarDictionary) => {
  return dictionary ? requiredFields.filter(field => !dictionary[field]) : requiredFields;
};

export const DsarEditDictionaryDialog = ({
  selectedDictionary,
  isOpen,
  isNewDictionary,
  onSave,
  onClose,
}: DsarEditDictionaryDialogProps) => {
  const [isLoading, setIsLoading] = useState(false);
  const { dictionary, file, errors, handleOnError, handleResetState, handleSetDictionary, handleSetFile, callApi } =
    useDictionary(selectedDictionary, isNewDictionary);

  if (!isOpen) {
    return null;
  }

  const handleOnSave = () => {
    try {
      callApi(() => {
        setIsLoading(true);
      })
        .then(() => {
          onSave();
          handleOnClose();
        })
        .catch(err => {
          handleOnError(err);
          setIsLoading(false);
        });
    } catch (err) {
      console.error(err);
    }
  };

  const handleOnClose = () => {
    onClose();
    setIsLoading(false);
    handleResetState();
  };

  return (
    <BigidDialog
      title={isNewDictionary ? 'Upload a new dictionary' : 'Edit Dictionary'}
      isOpen={isOpen}
      onClose={handleOnClose}
      borderTop={true}
      maxWidth="xs"
      buttons={[
        {
          component: props => (
            <SecondaryButton
              {...props}
              bi={{
                eventType: DsarSettingsTrackingEvents.DICTIONARY_EDIT_DIALOG_CANCEL_ACTION,
                eventData: { isNewDictionary },
              }}
            />
          ),
          onClick: handleOnClose,
          text: 'Cancel',
        },
        {
          component: props => (
            <PrimaryButton
              {...props}
              bi={{
                eventType: DsarSettingsTrackingEvents.DICTIONARY_EDIT_DIALOG_SAVE_ACTION,
                eventData: { isNewDictionary },
              }}
            />
          ),
          onClick: handleOnSave,
          text: isNewDictionary ? 'Upload' : 'Update',
        },
      ]}
      isLoading={isLoading}
    >
      <UploadDictionary
        dictionary={dictionary}
        file={file}
        errors={errors}
        onSetDictionary={handleSetDictionary}
        onSetFile={handleSetFile}
      />
    </BigidDialog>
  );
};

export const useDictionary = (selectedDictionary: DsarDictionary, isNewDictionary: boolean) => {
  const [dictionary, setDictionary] = useState(selectedDictionary);
  const [file, setFile] = useState(null);
  const [errors, setErrors] = useState<Error[]>([]);

  useEffect(() => {
    setDictionary(selectedDictionary);
    setFile((file: File) => (selectedDictionary && !file ? { name: selectedDictionary.fileName } : null));
  }, [selectedDictionary]);

  const handleSetDictionary = useCallback((newState: Partial<DsarDictionary>) => {
    setDictionary(dictionary => ({ ...dictionary, ...newState }));
    setErrors([]);
  }, []);

  const handleSetFile = useCallback((file: File) => {
    setFile(file);
  }, []);

  const handleResetState = useCallback(() => {
    setDictionary(null);
    setFile(null);
    setErrors([]);
  }, []);

  const handleOnError = useCallback(
    (err: { response: { data: { errors: Error[] } } }) => {
      const errorMessage = `Failed to ${isNewDictionary ? 'create' : 'update'} dictionary "${dictionary.name}"`;
      notificationService.error(errorMessage);
      console.error(`${errorMessage}: ${JSON.stringify(err?.response)}`);
      if (err?.response?.data?.errors) {
        setErrors(err?.response?.data?.errors);
      }
    },
    [dictionary?.name, isNewDictionary],
  );

  const callApi = useCallback(
    (callback?: () => void) => {
      const invalidFields = validate(dictionary);
      if (invalidFields.length) {
        setErrors(invalidFields.map(field => ({ name: field, message: defaultErrorMessage })));
        throw new Error('Invalid fields');
      }
      callback?.();
      const apiEndpoint = isNewDictionary ? createDictionary : updateDictionary;
      const dictionaryFormData = new FormData();
      dictionaryFormData.append('file', file);
      dictionaryFormData.append('name', dictionary?.name);
      return apiEndpoint(SSE_EVENTS.DSAR_UPLOAD_DICTIONARY, dictionaryFormData, dictionary.id);
    },
    [dictionary, isNewDictionary, file],
  );

  return {
    dictionary,
    file,
    errors,
    handleOnError,
    handleSetDictionary,
    handleResetState,
    handleSetFile,
    callApi,
  };
};

export const UploadDictionary = ({
  dictionary,
  file,
  errors,
  onSetDictionary,
  onSetFile,
}: {
  dictionary: DsarDictionary;
  file: File;
  errors: Error[];
  onSetDictionary: (dictionary: Partial<DsarDictionary>) => void;
  onSetFile: (file: File) => void;
}) => {
  const classes = useStyles({});

  const handleOnDownloadClick = (file: File) => {
    downloadDictionary(dictionary?.id, file.name);
  };

  const handleOnDrop = (files: File[], isRejectedFilesFound: boolean) => {
    if (isRejectedFilesFound) return;

    const [file] = files;
    onSetFile(file);
    onSetDictionary({ fileName: file?.name });
  };

  const getError = (field: string) => errors.find(error => error.name === field);
  const fileError = getError('fileName') || getError('csv');
  const nameError = getError('name');
  const genericError = errors.find(error => ![...requiredFields, 'csv'].includes(error.name));

  return (
    <div className={classes.wrapper}>
      <div>
        <p>
          {'Fill in '}
          <BigidLink text="this template" onClick={downloadDictionaryTemplate} underline="none" />
          {' and upload it.'}
        </p>
        <BigidDropZone
          accept={['.csv']}
          files={file ? [file] : []}
          onDownloadClick={handleOnDownloadClick}
          onDrop={handleOnDrop}
        />
        {fileError && <p className={classes.error}>{fileError.message || 'Add a file'}</p>}
      </div>
      <div>
        <Label text="Descriptive Name" />
        <BigidTextField
          defaultValue={dictionary?.name}
          errorMessage={nameError && nameError.message}
          onChange={e => onSetDictionary({ name: e.target.value })}
          placeholder="For communications"
          size="large"
          type="text"
          required
        />
      </div>
      {genericError && <p className={classes.error}>{genericError.message}</p>}
    </div>
  );
};

const Label = ({ text, tooltip }: { text: ReactNode; tooltip?: string }) => {
  const classes = useStyles({});
  return (
    <FormLabel className={classes.flexWrapper}>
      {text}
      <span className={classes.asterisk}>*</span>
      {tooltip && (
        <BigidTooltip title={tooltip} placement="top" width="240px">
          <div className={classes.flexWrapper}>
            <BigidHelpIcon size="medium" />
          </div>
        </BigidTooltip>
      )}
    </FormLabel>
  );
};

export interface DsarEditDictionaryDialog {
  dialogProps: DsarEditDictionaryDialogProps;
  openDialog: (selectedDictionary?: DsarDictionary) => Promise<boolean>;
}

const initialState: DsarEditDictionaryDialogProps = {
  selectedDictionary: null,
  isOpen: false,
  isNewDictionary: false,
};

export const useDsarEditDictionaryDialog = (): DsarEditDictionaryDialog => {
  const [dialogProps, setDialogProps] = useState<DsarEditDictionaryDialogProps>(initialState);

  const openDialog = useCallback<DsarEditDictionaryDialog['openDialog']>(selectedDictionary => {
    return new Promise<boolean>(resolve => {
      setDialogProps({
        selectedDictionary: selectedDictionary,
        isOpen: true,
        isNewDictionary: !selectedDictionary,
        onSave: () => {
          setDialogProps(initialState);
          resolve(true);
        },
        onClose: () => setDialogProps(initialState),
      });
    });
  }, []);

  return {
    openDialog,
    dialogProps,
  };
};

export const downloadDictionary = (id: string, name: string) => {
  try {
    downloadDictionaryService(id);
    trackDsar(DsarSettingsTrackingEvents.DICTIONARY_DOWNLOAD_ACTION);
  } catch (err) {
    notificationService.error(`Failed to download dictionary "${name}"`);
    console.error(`Failed to download dictionary: ${JSON.stringify(err?.response)}`);
  }
};

export const downloadDictionaryTemplate = () => {
  try {
    downloadDictionaryTemplateService();
    trackDsar(DsarSettingsTrackingEvents.DICTIONARY_DOWNLOAD_TEMPLATE_ACTION);
  } catch (err) {
    notificationService.error(`Failed to download dictionary template`);
    console.error(`Failed to download dictionary template: ${JSON.stringify(err?.response)}`);
  }
};
