import React, { useState } from 'react';

export enum OAuthStatus {
  CONNECTING = 'connecting',
  IDLE = 'idle',
  DISCONNECTING = 'disconnecting',
}

export enum OAuthAction {
  CONNECTING = OAuthStatus.CONNECTING,
  DISCONNECTING = OAuthStatus.DISCONNECTING,
}

export enum OAuthErrorStatuses {
  SUBMISSION_FAILED_PRE_CONNECTION = 'submissionFailedPreConnection',
  SUBMISSION_FAILED_PRE_DISCONNECTION = 'submissionFailedPreDisconnection',
  CONNECTION_FAILED = 'connectionFailed',
  DISCONNECTION_FAILED = 'disconnectionFailed',
}

export enum OAuthSuccessStatuses {
  SUBMITTED_PRE_CONNECTION = 'submittedPreConnection',
  SUBMITTED_PRE_DISCONNECTION = 'submittedPreDisconnection',
  SUCCESSFULLY_DISCONNECTED = 'successfullyDisconnected',
  REDIRECTING_TO_OAUTH = 'redirectingToOAuth',
}

type UseOAuthProps = {
  oauthSubmitHandler: (oauthAction?: OAuthAction) => Promise<boolean>;
  validationFunctions?: (() => Promise<boolean>)[];
  onSuccess?: (successStatus: OAuthSuccessStatuses) => void;
  onError?: (errorStatus: OAuthErrorStatuses) => void;
};

export type OAuthHandlers = {
  oAuthHandlers?: {
    onStartConnection?: () => Promise<boolean>;
    onFinishConnection?: (isError?: boolean) => Promise<void>;
    onStartDisconnection?: () => Promise<boolean>;
    onFinishDisconnection?: (isError?: boolean) => Promise<void>;
  };
};

const actionToFlowStatusMap = {
  [OAuthAction.CONNECTING]: {
    successStatus: OAuthSuccessStatuses.SUBMITTED_PRE_CONNECTION,
    errorStatus: OAuthErrorStatuses.SUBMISSION_FAILED_PRE_CONNECTION,
    oauthStatus: OAuthStatus.CONNECTING,
  },
  [OAuthAction.DISCONNECTING]: {
    successStatus: OAuthSuccessStatuses.SUBMITTED_PRE_DISCONNECTION,
    errorStatus: OAuthErrorStatuses.SUBMISSION_FAILED_PRE_DISCONNECTION,
    oauthStatus: OAuthStatus.DISCONNECTING,
  },
};

export const useOAuth = ({ oauthSubmitHandler, validationFunctions = [], onError, onSuccess }: UseOAuthProps) => {
  const [oauthStatus, setOAuthStatus] = useState<OAuthStatus>(OAuthStatus.IDLE);

  const errorHandler = (errorStatus: OAuthErrorStatuses) => {
    setOAuthStatus(OAuthStatus.IDLE);
    onError?.(errorStatus);
  };

  const submitHandler = async (action: OAuthAction) => {
    const { successStatus, errorStatus, oauthStatus } = actionToFlowStatusMap[action];
    setOAuthStatus(oauthStatus);
    const isSuccess = await oauthSubmitHandler(action);
    isSuccess ? onSuccess?.(successStatus) : errorHandler(errorStatus);
    return isSuccess;
  };

  const onStartConnection = async () => {
    const results = await Promise.all(validationFunctions?.map(func => func()));
    const isValidationPassed = results.every(result => result);
    if (!isValidationPassed) return false;
    return await submitHandler(OAuthAction.CONNECTING);
  };

  const onFinishConnection = async (isError = false) => {
    setOAuthStatus(OAuthStatus.IDLE);
    isError ? onError?.(OAuthErrorStatuses.CONNECTION_FAILED) : onSuccess?.(OAuthSuccessStatuses.REDIRECTING_TO_OAUTH);
  };

  const onStartDisconnection = async () => {
    return await submitHandler(OAuthAction.DISCONNECTING);
  };

  const onFinishDisconnection = async (isError = false) => {
    setOAuthStatus(OAuthStatus.IDLE);
    isError
      ? onError?.(OAuthErrorStatuses.DISCONNECTION_FAILED)
      : onSuccess?.(OAuthSuccessStatuses.SUCCESSFULLY_DISCONNECTED);
  };

  return {
    oAuthHandlers: {
      onStartConnection,
      onFinishConnection,
      onStartDisconnection,
      onFinishDisconnection,
    },
    oauthStatus,
  };
};
