import { BigidColorSchemeTokens, BigidColorSchemesVars } from '@bigid-ui/components';

export interface CellData {
  riskLevelLabel: string;
  color: string;
}

export interface KeyValueString {
  [key: number]: string;
}

export interface KeyValueCellData {
  [key: number]: CellData;
}

export interface RiskMatrixMetadata {
  matrixSize: number;
  impactLabel: string;
  probabilityLabel: string;
  impactLabels: KeyValueString;
  probabilityLabels: KeyValueString;
  cellData: KeyValueCellData;
}

const cellData3: KeyValueCellData = {
  1: {
    color: BigidColorSchemeTokens.light.positiveStrong,
    riskLevelLabel: 'Low',
  },
  2: {
    color: BigidColorSchemeTokens.light.positiveStrong,
    riskLevelLabel: 'Low',
  },
  3: {
    color: BigidColorSchemeTokens.light.warning,
    riskLevelLabel: 'Medium',
  },
  4: {
    color: BigidColorSchemeTokens.light.warning,
    riskLevelLabel: 'Medium',
  },
  6: {
    color: BigidColorSchemeTokens.light.warning,
    riskLevelLabel: 'Medium',
  },
  9: {
    color: BigidColorSchemeTokens.light.negative,
    riskLevelLabel: 'Critical',
  },
};

const cellData4: KeyValueCellData = {
  1: {
    color: BigidColorSchemeTokens.light.positiveStrong,
    riskLevelLabel: 'Low',
  },
  2: {
    color: BigidColorSchemeTokens.light.positiveStrong,
    riskLevelLabel: 'Low',
  },
  3: {
    color: BigidColorSchemeTokens.light.positiveStrong,
    riskLevelLabel: 'Low',
  },
  4: {
    color: BigidColorSchemeTokens.light.warning,
    riskLevelLabel: 'Medium',
  },
  6: {
    color: BigidColorSchemeTokens.light.warning,
    riskLevelLabel: 'Medium',
  },
  8: {
    color: BigidColorSchemeTokens.light.warning,
    riskLevelLabel: 'Medium',
  },
  9: {
    color: BigidColorSchemeTokens.light.warning,
    riskLevelLabel: 'Medium',
  },
  12: {
    color: BigidColorSchemesVars.light.orange600,
    riskLevelLabel: 'High',
  },
  16: {
    color: BigidColorSchemeTokens.light.negative,
    riskLevelLabel: 'Critical',
  },
};

const cellData5: KeyValueCellData = {
  1: {
    color: BigidColorSchemeTokens.light.positiveStrong,
    riskLevelLabel: 'Very low',
  },
  2: {
    color: BigidColorSchemeTokens.light.positiveStrong,
    riskLevelLabel: 'Very low',
  },
  3: {
    color: BigidColorSchemesVars.light.green600,
    riskLevelLabel: 'Low',
  },
  4: {
    color: BigidColorSchemesVars.light.green600,
    riskLevelLabel: 'Low',
  },
  5: {
    color: BigidColorSchemeTokens.light.warning,
    riskLevelLabel: 'Medium',
  },
  6: {
    color: BigidColorSchemeTokens.light.warning,
    riskLevelLabel: 'Medium',
  },
  8: {
    color: BigidColorSchemeTokens.light.warning,
    riskLevelLabel: 'Medium',
  },
  9: {
    color: BigidColorSchemeTokens.light.warning,
    riskLevelLabel: 'Medium',
  },
  10: {
    color: BigidColorSchemeTokens.light.warning,
    riskLevelLabel: 'Medium',
  },
  12: {
    color: BigidColorSchemeTokens.light.warning,
    riskLevelLabel: 'Medium',
  },
  15: {
    color: BigidColorSchemesVars.light.orange600,
    riskLevelLabel: 'High',
  },
  16: {
    color: BigidColorSchemesVars.light.orange600,
    riskLevelLabel: 'High',
  },
  20: {
    color: BigidColorSchemeTokens.light.negative,
    riskLevelLabel: 'Critical',
  },
  25: {
    color: BigidColorSchemeTokens.light.negative,
    riskLevelLabel: 'Critical',
  },
};

export const cellData = new Map<number, KeyValueCellData>([
  [3, cellData3],
  [4, cellData4],
  [5, cellData5],
]);

export const yAxis3: KeyValueString = {
  1: 'Insignificant',
  2: 'Moderate',
  3: 'Catastrophic',
};

export const yAxis4: KeyValueString = {
  1: 'Insignificant',
  2: 'Moderate',
  3: 'Major',
  4: 'Catastrophic',
};

export const yAxis5: KeyValueString = {
  1: 'Insignificant',
  2: 'Minor',
  3: 'Moderate',
  4: 'Major',
  5: 'Catastrophic',
};

export const yAxis = new Map<number, KeyValueString>([
  [3, yAxis3],
  [4, yAxis4],
  [5, yAxis5],
]);

const xAxis3: KeyValueString = {
  1: 'Rare',
  2: 'Possible',
  3: 'Very likely',
};

const xAxis4: KeyValueString = {
  1: 'Rare',
  2: 'Possible',
  3: 'Likely',
  4: 'Very likely',
};

const xAxis5: KeyValueString = {
  1: 'Rare',
  2: 'Unlikely',
  3: 'Possible',
  4: 'Likely',
  5: 'Very likely',
};

export const xAxis = new Map<number, KeyValueString>([
  [3, xAxis3],
  [4, xAxis4],
  [5, xAxis5],
]);

export const getCellProps = (level: number, size: number, cellData: KeyValueCellData) => {
  const val = level;
  switch (size) {
    case 3:
      if (val <= 3)
        return {
          color: cellData[1].color,
          label: cellData[1].riskLevelLabel,
        };
      else if (val <= 6)
        return {
          color: cellData[2].color,
          label: cellData[2].riskLevelLabel,
        };
      else
        return {
          color: cellData[3].color,
          label: cellData[3].riskLevelLabel,
        };
    case 4:
      if (val <= 3)
        return {
          color: cellData[1].color,
          label: cellData[1].riskLevelLabel,
        };
      else if (val <= 9)
        return {
          color: cellData[2].color,
          label: cellData[2].riskLevelLabel,
        };
      else if (val <= 12)
        return {
          color: cellData[3].color,
          label: cellData[3].riskLevelLabel,
        };
      else
        return {
          color: cellData[4].color,
          label: cellData[4].riskLevelLabel,
        };
    case 5:
      if (val <= 2)
        return {
          color: cellData[1].color,
          label: cellData[1].riskLevelLabel,
        };
      else if (val <= 4)
        return {
          color: cellData[2].color,
          label: cellData[2].riskLevelLabel,
        };
      else if (val <= 12)
        return {
          color: cellData[3].color,
          label: cellData[3].riskLevelLabel,
        };
      else if (val <= 16)
        return {
          color: cellData[4].color,
          label: cellData[4].riskLevelLabel,
        };
      else
        return {
          color: cellData[5].color,
          label: cellData[5].riskLevelLabel,
        };
    default:
      return { color: BigidColorSchemeTokens.light.backgroundActive, label: '' };
  }
};
