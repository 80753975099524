import { BigidDialog, BigidTextField, PrimaryButton, SecondaryButton } from '@bigid-ui/components';
import React, { FC, useEffect, useState } from 'react';
import { notificationService } from '../../../../services/notificationService';
import { FormControl, FormLabel } from '@mui/material';
import { ActionType, createLabel, duplicateLabel, getLabelById } from './LabelConfigurationService';

const SAME_NAME_ERROR_MESSAGE = 'This label name already exists, please enter a new name';
const NAME_INVALID_ERROR_MESSAGE = 'The label can only contain letters, numbers and spaces';
const isValidName = (name: string): boolean => {
  const regex = /^[a-zA-Z0-9 ]*$/;
  return regex.test(name);
};

export interface LabelDialogProps {
  isOpen: boolean;
  labelsNames: string[];
  actionType?: ActionType;
  labelId?: string;
  onClose: () => void;
  onActionSuccess?: (labelName: string) => void;
}

export const NewLabelDialog: FC<LabelDialogProps> = ({
  isOpen,
  labelsNames,
  labelId,
  actionType = 'Add',
  onClose,
  onActionSuccess,
}) => {
  const [title, setTitle] = useState<string>('');
  const [newName, setNewName] = useState<string>('');
  const [isUpdating, setIsUpdating] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');

  const handleActionSuccess = (labelName: string) => {
    setNewName('');
    setErrorMessage('');
    onActionSuccess?.(labelName);
  };

  const handleAddNewLabel = async () => {
    try {
      if (newName === '') {
        return;
      }
      setIsUpdating(true);
      if (actionType === 'Add') {
        await createLabel(newName);
      } else if (actionType === 'Duplicate') {
        const labelToDuplicate = await getLabelById(labelId);
        await duplicateLabel({ ...labelToDuplicate, label_name: newName });
      }
      handleActionSuccess(newName);
    } catch (error) {
      console.error(error);
      notificationService.error(error);
    } finally {
      setIsUpdating(false);
    }
  };

  const handleNameChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const newName = event?.target?.value.trim();
    const isNameTaken = labelsNames.includes(newName);

    if (isNameTaken) {
      setErrorMessage(SAME_NAME_ERROR_MESSAGE);
      setNewName('');
    } else if (!isValidName(newName)) {
      setErrorMessage(NAME_INVALID_ERROR_MESSAGE);
      setNewName('');
    } else {
      setErrorMessage('');
      setNewName(newName);
    }
  };

  const handleCloseDialog = () => {
    setNewName('');
    setErrorMessage('');
    onClose();
  };

  useEffect(() => {
    const currTitle = actionType === 'Add' ? 'Create new label' : `Duplicate '${labelId}' label`;
    setTitle(currTitle);
  }, [labelId, actionType]);

  return (
    <BigidDialog
      isOpen={isOpen}
      onClose={onClose}
      title={title}
      buttons={[
        { component: SecondaryButton, onClick: handleCloseDialog, text: 'Cancel' },
        { component: PrimaryButton, onClick: handleAddNewLabel, text: 'Save', disabled: !newName },
      ]}
      isLoading={isUpdating}
    >
      <FormControl fullWidth margin="normal" style={{ minHeight: 80 }}>
        <FormLabel style={{ marginTop: 5 }}>{isOpen}</FormLabel>
        <BigidTextField autoFocus required onChange={handleNameChange} errorMessage={errorMessage} />
      </FormControl>
    </BigidDialog>
  );
};
