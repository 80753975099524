import React, { FC, useState } from 'react';
import { BigidDialog } from '@bigid-ui/components';
import { ActionsDialogTypes } from './hooks/useCaseActions';
import { AssignUser, RemediateCase, SilenceCase, AcknowledgeCase, TicketModal } from './ModalContent';
import { useLocalTranslation } from '../../translations';
import { CaseModel, ViewType } from '../../actionableInsightsService';
import { Filters } from '../../../../types/actionableInsights';

export interface CaseActionsModalProps {
  type: ActionsDialogTypes;
  closeModal: () => void;
  onCaseAction?: (assignee?: string) => void;
  isOpen: boolean;
  rowsData: CaseModel[];
  viewType?: ViewType;
  filters?: Filters;
}

export const CaseActionsModal: FC<CaseActionsModalProps> = ({
  type,
  isOpen,
  closeModal,
  rowsData,
  onCaseAction,
  viewType,
  filters,
}) => {
  const { t } = useLocalTranslation('Action');
  const [jiraDialogTitle, setJiraDialogTitle] = useState('');
  const getModalContent = () => {
    switch (type) {
      case ActionsDialogTypes.ASSIGN:
        return (
          <AssignUser
            closeModal={closeModal}
            rowsData={rowsData}
            viewType={viewType}
            filters={filters}
            onAssignCase={onCaseAction}
          />
        );
      case ActionsDialogTypes.SILENCE:
        return (
          <SilenceCase
            onSilenceCase={onCaseAction}
            closeModal={closeModal}
            rowsData={rowsData}
            viewType={viewType}
            filters={filters}
          />
        );
      case ActionsDialogTypes.ACKNOWLEDGE:
        return (
          <AcknowledgeCase
            onAcknowledgeCase={onCaseAction}
            closeModal={closeModal}
            rowsData={rowsData}
            viewType={viewType}
            filters={filters}
          />
        );
      case ActionsDialogTypes.REMEDIATE:
        return (
          <RemediateCase
            onRemediateCase={onCaseAction}
            closeModal={closeModal}
            rowsData={rowsData}
            viewType={viewType}
            filters={filters}
          />
        );
      case ActionsDialogTypes.JIRA:
        return (
          <TicketModal
            closeModal={closeModal}
            rowsData={rowsData[0]}
            updateDialogTitle={setJiraDialogTitle}
            filters={filters}
          />
        );
      default:
        return null;
    }
  };

  return (
    <BigidDialog
      onClose={closeModal}
      showCloseIcon={true}
      title={type === ActionsDialogTypes.JIRA ? jiraDialogTitle : t(`title.${type}`)}
      isOpen={isOpen}
      borderBottom={false}
      maxWidth="sm"
    >
      {getModalContent()}
    </BigidDialog>
  );
};
