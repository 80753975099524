import { isPermitted, getAllRoles } from '../../../services/userPermissionsService';
import { isBoolean, isNil } from 'lodash';
import { $state, localStorageService } from '../../../services/angularServices';
import { CONFIG } from '../../../../config/common';
import { DS_COLLABORATOR_ROLE_NAME } from '../../DataSources/DataSourceConnectionModal/constants/constants';
import type { LoginOverride } from '../types';
import {
  openEaProgramModal,
  shouldDisplayEaProgramModal,
  isEaProgramEnabled,
} from '../../../components/EaProgramModal/eaProgramModalService';
import { isDsCollaborationEnabled } from '../../../utilities/featureFlagUtils';

/**
 * @info Conditional navigation overrides triggered after logging in
 * executed on first come first serve basis based on the 'isPermitted' flag
 **/

const resolveAsyncFunctions = async (arr: LoginOverride[]) => {
  const resolvedArray = await Promise.all(
    arr.map(async obj => {
      const { isPermitted } = obj;
      const isResolvedPermitted = await isPermitted();
      return { ...obj, isPermitted: isResolvedPermitted };
    }),
  );
  return resolvedArray;
};

const LOGIN_NAVIGATION_OVERRIDES: LoginOverride[] = [
  {
    name: 'SHOW_COLLABORATION_GUIDED_TOUR',
    isPermitted: () => {
      const isDsCollaborator = isDsCollaborationEnabled() && isPermitted(DS_COLLABORATOR_ROLE_NAME);
      const skipGuidedTour = localStorageService.get('skipGuidedTour');
      const shouldDisplayGuidedTour = isBoolean(skipGuidedTour) ? !skipGuidedTour : skipGuidedTour !== 'true';

      return isDsCollaborator && shouldDisplayGuidedTour;
    },
    isFinal: true,
    action: () => {
      // postpone other guided tour until next login or indefinitely
      localStorageService.set('guidedTourPostponed', true);
      $state.go(CONFIG.states.DATA_SOURCE_CONNECTIONS, {
        shouldDisplayGuidedTour: true,
      });
    },
  },
  {
    name: 'SHOW_EA_PROGRAM_DIALOG',
    isPermitted: async () => isEaProgramEnabled() && (await shouldDisplayEaProgramModal()),
    isFinal: false,
    action: () => {
      setTimeout(() => {
        openEaProgramModal();
      }, 3000);
    },
  },
  {
    name: 'ENABLE_NAVIGATION_GUIDED_TOUR_AFTER_COLLABORATION_TOUR',
    isPermitted: () => !isNil(localStorageService.get('guidedTourPostponed')),
    isFinal: false,
    action: () => {
      const isDsCollaborator = isDsCollaborationEnabled() && isPermitted(DS_COLLABORATOR_ROLE_NAME);
      const hasSingleRole = !Object.keys(getAllRoles()).length;
      const isExternalCollaborator = isDsCollaborator && hasSingleRole;
      // never show other tours for collaborators with only `ds-collaborator` role
      !isExternalCollaborator && localStorageService.set('guidedTourPostponed', false);
    },
  },
];

const overrides = [...LOGIN_NAVIGATION_OVERRIDES];

export const getLoginOverrides = async () => await resolveAsyncFunctions(overrides);
