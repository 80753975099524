import React, { useState, FC, useCallback, useMemo, ReactNode } from 'react';
import { Theme } from '@mui/material/styles';
import { BigidApplyFilledIcon, BigidClearFilledIcon, BigidClockIcon, BigidInProgressIcon } from '@bigid-ui/icons';
import {
  AsyncOperationProcessingWidget,
  AsyncOperationMap,
} from '../../../components/AsyncOperationProcessingWidget/AsyncOperationProcessingWidget';
import { useTagBulkAssignment } from './operations/TagBulkAssignment';
import { useCusterBusinessAttrAssignment } from './operations/ClusterBusinessAttrAssignment';
import { useRulesBulkApplying } from './operations/RulesBulkApplying';
import { DataCatalogAsyncOperationStatus } from './DataCatalogAsyncOpsTypes';
import { getFixedT } from '../translations';

interface DataCatalogAsyncOpsProcessingWidgetProps {
  onUpdate: () => void;
}

const getStatusIcon = (status: DataCatalogAsyncOperationStatus, dataAid: string, theme: Theme): ReactNode => {
  const operationIconMapping: { [status in DataCatalogAsyncOperationStatus]?: ReactNode } = {
    [DataCatalogAsyncOperationStatus.COMPLETED]: (
      <BigidApplyFilledIcon size="medium" color="positive" dataAid={`${dataAid}-success`} />
    ),
    [DataCatalogAsyncOperationStatus.ERROR]: (
      <BigidClearFilledIcon size="medium" color="negative" dataAid={`${dataAid}-failure`} />
    ),
    [DataCatalogAsyncOperationStatus.NOT_STARTED]: (
      <BigidClockIcon size="medium" color={theme.vars.palette.bigid.gray300} dataAid={`${dataAid}-pending`} />
    ),
    [DataCatalogAsyncOperationStatus.RUNNING]: (
      <BigidInProgressIcon size="medium" color="insight" dataAid={`${dataAid}-running`} />
    ),
  };

  return operationIconMapping[status];
};

export const getStatusIconTooltipText = (status: DataCatalogAsyncOperationStatus): string => {
  const t = getFixedT('asyncOperations.tagging.status');

  switch (status) {
    case DataCatalogAsyncOperationStatus.COMPLETED:
      return t('completed');
    case DataCatalogAsyncOperationStatus.ERROR:
      return t('failed');
    case DataCatalogAsyncOperationStatus.NOT_STARTED:
      return t('pending');
    case DataCatalogAsyncOperationStatus.RUNNING:
      return t('running');
    default:
      return status;
  }
};

export const DataCatalogAsyncOpsProcessingWidget: FC<DataCatalogAsyncOpsProcessingWidgetProps> = ({ onUpdate }) => {
  const [runningOperationsMap, setRunningOperationsMap] = useState<AsyncOperationMap>(new Map());

  const onOperationRun = useCallback((sseRoute: string, isOperationRan: boolean) => {
    setRunningOperationsMap(prevRunningOperationsMap => {
      return prevRunningOperationsMap.set(sseRoute, isOperationRan);
    });
  }, []);

  const { operations: tagsBulkOperations } = useTagBulkAssignment({ onOperationCompleted: onUpdate, onOperationRun });
  const { operations: clusterBusinessAttrOperations } = useCusterBusinessAttrAssignment({ onOperationRun });
  const { operations: rulesBulkApplyingOperations } = useRulesBulkApplying({ onOperationRun });

  const operations = useMemo(() => {
    return [...tagsBulkOperations, ...clusterBusinessAttrOperations, ...rulesBulkApplyingOperations];
  }, [tagsBulkOperations, clusterBusinessAttrOperations, rulesBulkApplyingOperations]);

  return (
    <AsyncOperationProcessingWidget
      dataAid="Data-catalog-processing"
      operations={operations}
      operationsMap={runningOperationsMap}
      getStatusIcon={getStatusIcon}
      getStatusTooltipText={getStatusIconTooltipText}
    />
  );
};
