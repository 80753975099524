import { module } from 'angular';
const app = module('app');

app.factory('emailSetupService', [
  '$http',
  'appSettings',
  function ($http, appSettings) {
    return {
      getMailerData: () => {
        return $http.get(appSettings.serverPath + '/' + appSettings.version + '/mailers').then(function (response) {
          return response;
        });
      },
      updateMailer: (data, id) => {
        return $http
          .put(appSettings.serverPath + '/' + appSettings.version + '/mailers/' + id, data)
          .then(function (response) {
            return response;
          });
      },
      createMailer: data => {
        return $http
          .post(appSettings.serverPath + '/' + appSettings.version + '/mailers', data)
          .then(function (response) {
            return response;
          });
      },
      deleteMailer: id => {
        return $http
          .delete(appSettings.serverPath + '/' + appSettings.version + '/mailers/' + id)
          .then(function (response) {
            return response;
          });
      },
      testMailer: data => {
        return $http
          .post(appSettings.serverPath + '/' + appSettings.version + '/mailers/testSendMail', data)
          .then(function (response) {
            return response;
          });
      },
    };
  },
]);
