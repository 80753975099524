import { BigidGridColumn, BigidGridColumnTypes } from '@bigid-ui/grid';
import { SimilarTablesResult } from '../services/dataCatalogSimilarTablesService';
import { getPercentageValue } from './utils';

export const getSimilarTablesGridColumns = (): BigidGridColumn<SimilarTablesResult>[] => {
  return [
    {
      name: 'similarity',
      title: 'Similarity',
      getCellValue: row => {
        return getPercentageValue(row.similarity);
      },
      type: BigidGridColumnTypes.TEXT,
      sortingEnabled: false,
      width: 100,
    },
    {
      name: 'tableName',
      title: 'Table Name',
      getCellValue: row => row.tableName,
      type: BigidGridColumnTypes.TEXT,
      sortingEnabled: false,
      width: 200,
    },
    {
      name: 'containerName',
      title: 'Container Name',
      getCellValue: row => row.containerName,
      type: BigidGridColumnTypes.TEXT,
      sortingEnabled: false,
      width: 200,
    },
    {
      name: 'dataSource',
      title: 'Data Source',
      getCellValue: row => row.dataSourceName,
      type: BigidGridColumnTypes.TEXT,
      sortingEnabled: false,
      width: 200,
    },
    {
      name: 'numberOfFindings',
      title: '# of Findings',
      getCellValue: row => row.numberOfFindings,
      type: BigidGridColumnTypes.NUMBER,
      sortingEnabled: false,
      width: 100,
    },
    {
      name: 'attributes',
      title: 'Attributes',
      getCellValue: ({ attributes }) => {
        return {
          chips: {
            value: attributes
              ? attributes.map(attr => ({
                  label: attr,
                }))
              : [],
            isDisabled: true,
          },
        };
      },
      type: BigidGridColumnTypes.CHIPS,
      sortingEnabled: false,
      width: 300,
    },
    {
      name: 'tags',
      title: 'Tags',
      getCellValue: ({ tags }) => {
        return {
          tags: {
            value: tags
              ? tags.map(tag => ({
                  name: tag.tagName,
                  value: tag.tagValue,
                }))
              : [],
            isDisabled: true,
          },
        };
      },
      type: BigidGridColumnTypes.TAGS,
      sortingEnabled: false,
      width: 300,
    },
    {
      name: 'owner',
      title: 'Owner',
      getCellValue: ({ owner }) => owner,
      type: BigidGridColumnTypes.TEXT,
      sortingEnabled: false,
    },
  ];
};

export const getGridEntityName = (numberOfRows: number, totalResults: number) => {
  let entityName = 'items';
  if (numberOfRows < totalResults) {
    entityName = `/ ${totalResults} items`;
  }
  return entityName;
};

export const getGridEntityTooltip = (numberOfRows: number, totalResults: number) => {
  let entityTooltip = '';
  if (numberOfRows < totalResults) {
    entityTooltip = 'Top results order by similarity score';
  }
  return entityTooltip;
};
