import React, { FC, useState, useEffect, RefObject, createRef, useCallback, useMemo } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import {
  BigidDialog,
  BigidColors,
  PrimaryButton,
  SecondaryButton,
  BigidIconSourceType,
  BigidIconProps,
  objectToQueryString,
} from '@bigid-ui/components';
import {
  BigidLineageDiagram,
  BigidLineageDiagramNode,
  BigidLineageDiagramLink,
  BigidLineageDiagramNodeType,
  BigidLineageDiagramSearchResultObject,
  BigidLineageDiagramConfig,
} from '@bigid-ui/visualisation';
import { notificationService } from '../../../../services/notificationService';
import { DataCatalogRecord } from '../../DataCatalogService';
import {
  CollectionLineage,
  CollectionLineageManualConnection,
  getCollectionLineage,
  getColumnsByObjectName,
  getCollectionsByName,
  addLineageManualConnections,
  removeLineageManualConnections,
} from '../DataCatalogColumnsService';
import { uniq, isEqual } from 'lodash';
import CollectionIcon from '../../../../assets/icons/BigidRDB.svg';
import ColumnIcon from '../../../../assets/icons/BigidColumn.svg';
import { CatalogEventsEnum } from '../../events';
import { analyticsService } from '../../../../services/analyticsService';

const useStyles = makeStyles({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '65vh',
  },
  headerWrapper: {
    flex: '0 0 50px',
    fontSize: '0.875rem',
    color: BigidColors.gray[700],
    fontWeight: 400,
  },
  diagramWrapper: {
    flex: '1 0 auto',
  },
});

export interface LinkedColumnsDialogProps extends Pick<DataCatalogRecord, 'fullyQualifiedName'> {
  title: string;
  columnName?: string;
  isOpen: boolean;
  onSubmit?: (linkedColumnsNumber: number) => void;
  onClose?: () => void;
}

interface DiagramData {
  nodes: BigidLineageDiagramNode[];
  links: BigidLineageDiagramLink[];
}

const getNodes = (
  lineage: CollectionLineage[],
  linkedColumns: string[],
  selectedCollectionName: string,
  selectedColumnName: string,
): BigidLineageDiagramNode[] => {
  return lineage.reduce((nodes, { name, fields }, nodeIndex) => {
    return fields?.length > 0
      ? [
          ...nodes,
          {
            id: name,
            name,
            displayName: name.split('.').pop(),
            type: BigidLineageDiagramNodeType.regular,
            isStatic: name === selectedCollectionName,
            ports: (name === selectedCollectionName && typeof selectedColumnName !== 'undefined'
              ? fields.filter(({ fieldName }) => fieldName === selectedColumnName)
              : fields
            ).map(({ fieldName }) => {
              const isLinked = linkedColumns.includes(`${name}.${fieldName}`);
              const isAbleToConnectManually =
                typeof selectedColumnName !== 'undefined'
                  ? nodeIndex === 0 && `${name}.${fieldName}` === `${name}.${selectedColumnName}`
                  : name === selectedCollectionName;
              return {
                id: `${name}.${fieldName}`,
                nodeId: name,
                name: fieldName,
                isLinked,
                isVisibleByDefault: isLinked,
                isAbleToConnectManually,
              };
            }),
          },
        ]
      : nodes;
  }, []);
};

const getLinks = (lineage: CollectionLineage[]): BigidLineageDiagramLink[] => {
  return lineage.reduce((links, { name, connectionDetails }) => {
    if (connectionDetails.length > 0) {
      return [
        ...links,
        ...connectionDetails.reduce((connections, { linkedCollection, originFields, destinationField, type }) => {
          return [
            ...connections,
            {
              id: `${originFields}-${destinationField}`,
              from: {
                id: originFields,
                nodeId: name,
                displayName: originFields.split('.').pop(),
              },
              to: {
                id: destinationField,
                nodeId: linkedCollection,
                displayName: destinationField.split('.').pop(),
              },
              isRemovable: type === 'manual',
              type,
            },
          ];
        }, []),
      ];
    } else {
      return links;
    }
  }, []);
};

const getDiagramData = (
  lineage: CollectionLineage[],
  selectedCollectionName: string,
  selectedColumnName: string,
): DiagramData => {
  let lineageNodes: BigidLineageDiagramNode[] = [];
  let lineageLinks: BigidLineageDiagramLink[] = [];

  lineageLinks = getLinks(lineage);
  lineageNodes = getNodes(
    lineage,
    uniq(lineageLinks.reduce((linkedColumns, { from, to }) => [...linkedColumns, from.id, to.id], [])),
    selectedCollectionName,
    selectedColumnName,
  );

  return { nodes: lineageNodes, links: lineageLinks };
};

const COLLECTION_ICON_CONFIG: BigidIconProps = {
  icon: CollectionIcon,
  type: BigidIconSourceType.CUSTOM,
};
const COLUMN_ICON_CONFIG: BigidIconProps = {
  icon: ColumnIcon,
  type: BigidIconSourceType.CUSTOM,
};

export const LinkedColumnsDialog: FC<LinkedColumnsDialogProps> = ({
  title,
  columnName,
  isOpen,
  onClose,
  onSubmit,
  fullyQualifiedName,
}) => {
  const classes = useStyles({});
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [nodes, setNodes] = useState<BigidLineageDiagramNode[]>();
  const [links, setLinks] = useState<BigidLineageDiagramLink[]>();
  const [collectionsSearchResults, setCollectionsSearchResults] = useState<BigidLineageDiagramSearchResultObject[]>();
  const [columnsSearchResults, setColumnsSearchResults] = useState<BigidLineageDiagramSearchResultObject[]>();
  const [columnsSearchQuery, setColumnsSearchQuery] = useState<string>();
  const [addedConnections, setAddedConnections] = useState<CollectionLineageManualConnection[]>([]);
  const [removedConnections, setRemovedConnections] = useState<CollectionLineageManualConnection[]>([]);
  const [containerRef] = useState<RefObject<HTMLDivElement>>(createRef());

  useEffect(() => {
    analyticsService.trackManualEvent(CatalogEventsEnum.CATALOG_VIEW_SIMILAR_COLUMNS_LINKED_COLUMNS);
  }, []);

  const initialiseDiagram = useCallback(async () => {
    try {
      setIsLoading(true);

      const { data } = await getCollectionLineage([fullyQualifiedName], columnName ? [columnName] : []);

      if (data.length > 0) {
        const { nodes, links } = getDiagramData(data, fullyQualifiedName, columnName);
        setNodes(nodes);
        setLinks(links);
      } else {
        //TODO: remove everything as soon as API is fixed and always returs requested object, no more empty array case
        if (columnName) {
          setNodes([
            {
              id: fullyQualifiedName,
              name: fullyQualifiedName,
              displayName: fullyQualifiedName.split('.').pop(),
              type: BigidLineageDiagramNodeType.regular,
              isStatic: true,
              ports: [
                {
                  id: `${fullyQualifiedName}.${columnName}`,
                  nodeId: fullyQualifiedName,
                  name: `${fullyQualifiedName}.${columnName}`,
                  displayName: columnName,
                  isVisibleByDefault: true,
                  isAbleToConnectManually: true,
                },
              ],
            },
          ]);
          setLinks([]);
        } else {
          const query = objectToQueryString({
            object_name: fullyQualifiedName,
            requireTotalCount: false,
          });
          const { data } = await getColumnsByObjectName(query);
          setNodes([
            {
              id: fullyQualifiedName,
              name: fullyQualifiedName,
              displayName: fullyQualifiedName.split('.').pop(),
              type: BigidLineageDiagramNodeType.regular,
              isStatic: true,
              ports: data.map(({ column_name }, index) => ({
                id: `${fullyQualifiedName}.${column_name}`,
                nodeId: fullyQualifiedName,
                name: `${fullyQualifiedName}.${column_name}`,
                displayName: column_name,
                isVisibleByDefault: index <= BigidLineageDiagramConfig.nodeConfig.maxPortsVisible - 1,
                isAbleToConnectManually: true,
              })),
            },
          ]);
          setLinks([]);
        }
      }
    } catch ({ message }) {
      notificationService.error('An error has occurred');
      console.error(message);
    } finally {
      setIsLoading(false);
    }
  }, [columnName, fullyQualifiedName]);

  useEffect(() => {
    isOpen && initialiseDiagram();
  }, [isOpen, initialiseDiagram]);

  const closeDialog = (): void => {
    setIsLoading(false);
    setNodes(undefined);
    setLinks(undefined);
    setColumnsSearchResults(undefined);
    setCollectionsSearchResults(undefined);
    setAddedConnections([]);
    setRemovedConnections([]);
  };

  const handleOnClose = (): void => {
    closeDialog();
    onClose?.();
  };

  const handleOnDialogSave = async () => {
    try {
      setIsLoading(true);

      let removeConnectionsStatusCode = 200;
      let addConnectionsStatusCode = 200;

      if (removedConnections.length) {
        const removeConnectionsResponse = await removeLineageManualConnections(removedConnections);
        removeConnectionsStatusCode = removeConnectionsResponse.statusCode;
      }

      if (addedConnections.length) {
        const addConnectionsResponse = await addLineageManualConnections(addedConnections);
        addConnectionsStatusCode = addConnectionsResponse.statusCode;
      }

      const isSavingSucceeded = addConnectionsStatusCode === 200 && removeConnectionsStatusCode === 200;

      if (isSavingSucceeded) {
        closeDialog();

        if (onSubmit) {
          const directLinksInitialAmount = links.reduce(
            (amount, { from, to }) =>
              from.id === `${fullyQualifiedName}.${columnName}` || to.id === `${fullyQualifiedName}.${columnName}`
                ? amount + 1
                : amount,
            0,
          );
          const removedDirectLinksCount = removedConnections.reduce(
            (amount, { local_field, foreign_field }) =>
              local_field === `${fullyQualifiedName}.${columnName}` ||
              foreign_field === `${fullyQualifiedName}.${columnName}`
                ? amount + 1
                : amount,
            0,
          );

          onSubmit(directLinksInitialAmount + addedConnections.length - removedDirectLinksCount);
        }
      } else {
        notificationService.error('An error has occurred');
      }
    } catch ({ message }) {
      notificationService.error('An error has occurred');
      console.error(`An error has occurred: ${message}`);
    } finally {
      setIsLoading(false);
    }
  };

  const handleOnDialogCancel = (): void => {
    closeDialog();
    onClose();
  };

  const handleCollectionsSearch = async (query: string) => {
    try {
      if (query !== undefined) {
        const { data } = await getCollectionsByName(query);
        setCollectionsSearchResults(
          data
            .filter(collection => collection.fullyQualifiedName !== fullyQualifiedName)
            .map(({ objectName, fullyQualifiedName }) => ({
              id: fullyQualifiedName,
              displayName: objectName,
              name: fullyQualifiedName,
            })),
        );
      } else {
        setCollectionsSearchResults(collections => collections);
      }
    } catch ({ message }) {
      setCollectionsSearchResults([]);
      notificationService.error('An error has occurred');
      console.error(`An error has occurred: ${message}`);
    }
  };

  const handleCollectionSelected = async ({ id }: BigidLineageDiagramSearchResultObject) => {
    try {
      const { data } = await getColumnsByObjectName(`object_name=${id}`);
      setColumnsSearchResults(
        data.map(({ column_name }) => ({
          id: `${id}.${column_name}`,
          displayName: column_name,
          name: `${id}.${column_name}`,
        })),
      );
    } catch ({ message }) {
      setColumnsSearchResults([]);
      notificationService.error('An error has occurred');
      console.error(`An error has occurred: ${message}`);
    }
  };

  const handleColumnsSearch = (query: string): void => {
    setColumnsSearchQuery(query);
  };

  const handleConnectionAdded = async (link: BigidLineageDiagramLink) => {
    const { from, to } = link;
    setAddedConnections(connections => [
      ...connections,
      {
        lineage_node: from.nodeId.toString(),
        local_field: from.id.toString(),
        linked_collection: to.nodeId.toString(),
        foreign_field: to.id.toString(),
      },
    ]);
  };

  const handleConnectionRemoved = async (link: BigidLineageDiagramLink) => {
    const { from, to } = link;
    const removedConnection = {
      lineage_node: from.nodeId.toString(),
      local_field: from.id.toString(),
      linked_collection: to.nodeId.toString(),
      foreign_field: to.id.toString(),
    };

    const recentlyAddedConnectionIdx = addedConnections.findIndex(addedConnection =>
      isEqual(addedConnection, removedConnection),
    );

    recentlyAddedConnectionIdx >= 0 &&
      setAddedConnections(addedConnections => [
        ...addedConnections.slice(0, recentlyAddedConnectionIdx),
        ...addedConnections.slice(recentlyAddedConnectionIdx + 1, addedConnections.length),
      ]);

    recentlyAddedConnectionIdx < 0 &&
      setRemovedConnections(connections => [
        ...connections,
        {
          lineage_node: from.nodeId.toString(),
          local_field: from.id.toString(),
          linked_collection: to.nodeId.toString(),
          foreign_field: to.id.toString(),
        },
      ]);
  };

  const columnsSearchResultsFiltered = useMemo(
    () =>
      columnsSearchQuery
        ? columnsSearchResults?.filter(({ name }) => name.toLowerCase().includes(columnsSearchQuery.toLowerCase()))
        : columnsSearchResults,
    [columnsSearchQuery, columnsSearchResults],
  );

  return (
    <BigidDialog
      title={title}
      isOpen={isOpen}
      onClose={handleOnClose}
      isLoading={isLoading}
      maxWidth="xl"
      borderBottom
      borderTop
      buttons={[
        {
          component: SecondaryButton,
          onClick: handleOnDialogCancel,
          text: 'Cancel',
        },
        {
          component: PrimaryButton,
          onClick: handleOnDialogSave,
          text: 'Save',
          disabled: isLoading,
        },
      ]}
    >
      <div className={classes.wrapper}>
        <div className={classes.headerWrapper}>
          Linked columns indicate from where the data is drawn and imported into the current column. You can add or
          remove links to columns.
        </div>
        {links && nodes && (
          <div ref={containerRef} className={classes.diagramWrapper} data-aid="LinkedColumnsDialogContent">
            {containerRef.current && (
              <BigidLineageDiagram
                nodes={nodes}
                links={links}
                subjectsSearchResults={collectionsSearchResults}
                subjectEntitiesSearchResults={columnsSearchResultsFiltered}
                canvasContainer={containerRef.current}
                nodeSubjectIconPath="/images/catalogLinkedTables/BigidRDB.svg"
                nodeSubjectEntityIconPath="/images/catalogLinkedTables/BigidColumn.svg"
                nodeSubjectIconSvg={COLLECTION_ICON_CONFIG}
                nodeSubjectEntityIconSvg={COLUMN_ICON_CONFIG}
                onSubjectsSearch={handleCollectionsSearch}
                onSubjectEntitiesSearch={handleColumnsSearch}
                onSubjectSelected={handleCollectionSelected}
                onConnectionAdded={handleConnectionAdded}
                onConnectionRemoved={handleConnectionRemoved}
                isFilterEnabled
                defaultFilter="manual"
              />
            )}
          </div>
        )}
      </div>
    </BigidDialog>
  );
};
