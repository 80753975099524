import React, { FC, useCallback, useMemo, useRef, useState } from 'react';
import { styled } from '@mui/material';
import {
  BigidButtonIcon,
  BigidDialog,
  BigidDialogButtonType,
  BigidDropdown,
  BigidDropdownOption,
  BigidDropdownValue,
  BigidTooltip,
  PrimaryButton,
  SecondaryButton,
} from '@bigid-ui/components';
import { generateDataAid } from '@bigid-ui/utils';
import { BigidDeleteIcon, BigidSaveIcon } from '@bigid-ui/icons';
import { showConfirmationDialog } from '../../../../../services/confirmationDialogService';
import { UseCatalogDiscoveryResponse } from '../../../useCatalogDiscovery';
import { useLocalTranslation } from './translations';
import { SaveQueryForm, SaveQueryFormStateAndControls } from './SaveQueryForm';
import { deleteSavedFilter } from './saveQueryService';
import { noop } from 'lodash';
import { notificationService } from '../../../../../services/notificationService';

export interface SaveQueryProps
  extends Pick<
    UseCatalogDiscoveryResponse,
    | 'savedQueries'
    | 'currentSavedQuery'
    | 'onSavedQueryCreate'
    | 'onSavedQueryUpdate'
    | 'onSavedQueryDelete'
    | 'onSavedQuerySelect'
    | 'isFilterChanged'
    | 'filter'
    | 'query'
  > {
  dataAid?: string;
  isToolbarPreferencesReady: boolean;
  isPageInitialised: boolean;
}

const Root = styled('div')`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const List = styled('div')`
  width: 300px;
`;

export const SaveQuery: FC<SaveQueryProps> = ({
  dataAid = 'SaveQuery',
  query,
  filter,
  isFilterChanged,
  isPageInitialised,
  isToolbarPreferencesReady,
  savedQueries,
  currentSavedQuery,
  onSavedQueryCreate,
  onSavedQueryDelete,
  onSavedQuerySelect,
  onSavedQueryUpdate,
}) => {
  const { t } = useLocalTranslation();
  const [isFormBusy, setIsFormBusy] = useState<boolean>(false);
  const [isDialogShown, setIsDialogShown] = useState<boolean>(false);
  const dialogControls = useRef<SaveQueryFormStateAndControls>();
  const { savedQueriesListOptions, savedQueriesListValue } = useMemo(() => {
    const savedQueriesListOptions: BigidDropdownOption[] = savedQueries.map(savedQuery => {
      const { id, name } = savedQuery;

      return {
        id,
        value: savedQuery,
        displayValue: name,
        annotation: currentSavedQuery?.id === id && isFilterChanged ? t('list.modifiedAnnotation') : undefined,
      };
    });
    const savedQueriesListValue: BigidDropdownValue = currentSavedQuery
      ? [savedQueriesListOptions.find(({ id }) => id === currentSavedQuery.id)]
      : [];

    return {
      savedQueriesListOptions,
      savedQueriesListValue,
    };
  }, [currentSavedQuery, isFilterChanged, savedQueries, t]);

  const handleDialogControlsChange = ({ isBusy }: SaveQueryFormStateAndControls) => {
    setIsFormBusy(isBusy);
  };

  const handleDialogClose = () => {
    setIsDialogShown(false);
  };

  const handleOnQuerySelect = ([option]: BigidDropdownOption[]): void => {
    //NOTE: 500 ms delay in order to provide a bit smoother UX: a dropdown should be collapsed by the moment of all the async processed the selection causes
    setTimeout(() => {
      onSavedQuerySelect(option ? option.value : null);
    }, 500);
  };

  const handleToolbarSaveIconClick = (): void => {
    setIsDialogShown(true);
  };

  const handleToolbarDeleteIconClick = useCallback(async () => {
    try {
      if (currentSavedQuery) {
        const isSavedFilterDeletionConfirmed = await showConfirmationDialog({
          entityNameSingular: '',
          actionName: t('actions.deleteQuery.confirmationDialog.title'),
          actionButtonName: t('actions.deleteQuery.confirmationDialog.confirmBtn'),
          customDescription: t('actions.deleteQuery.confirmationDialog.body', {
            queryName: currentSavedQuery.name,
          }),
        });

        if (isSavedFilterDeletionConfirmed) {
          await deleteSavedFilter(currentSavedQuery);
          onSavedQueryDelete(currentSavedQuery);
          notificationService.success(
            t('actions.deleteQuery.notifications.successDeletion', { name: currentSavedQuery.name }),
          );
        }
      }
    } catch ({ message }) {
      console.error(`An error has occurred: ${message}`);
      notificationService.error(
        t('actions.deleteQuery.notifications.failureDeletion', { name: currentSavedQuery.name }),
      );
    }
  }, [currentSavedQuery, onSavedQueryDelete, t]);

  const buttons: BigidDialogButtonType[] = useMemo(() => {
    return [
      {
        dataAid: generateDataAid(dataAid, ['dialog', 'button', 'cancel']),
        text: t('actions.saveQuery.dialog.buttons.cancel'),
        component: SecondaryButton,
        alignment: 'left',
        onClick: noop,
        isClose: true,
      },
      {
        dataAid: generateDataAid(dataAid, ['dialog', 'button', 'save']),
        text: t('actions.saveQuery.dialog.buttons.save'),
        component: PrimaryButton,
        alignment: 'right',
        disabled: isFormBusy,
        onClick: () => {
          dialogControls.current?.onSave.callback();
        },
      },
    ];
  }, [dataAid, isFormBusy, t]);

  const isReady = isPageInitialised && isToolbarPreferencesReady;
  const isSaveIconEnabled = isFilterChanged && isReady && filter?.length > 0;
  const isDeleteIconEnabled = currentSavedQuery && isReady;

  return (
    <>
      <Root data-aid={dataAid}>
        <List>
          <BigidDropdown
            value={savedQueriesListValue}
            dataAid={generateDataAid(dataAid, ['list'])}
            options={savedQueriesListOptions}
            onSelect={handleOnQuerySelect}
            placeholder={t('list.placeholder')}
            isDisabled={!isReady}
            isErasable
          />
        </List>
        <BigidTooltip title={t('actions.saveQuery.name')}>
          <BigidButtonIcon
            icon={BigidSaveIcon}
            dataAid={generateDataAid(dataAid, ['icon', 'save'])}
            disabled={!isSaveIconEnabled}
            onClick={isSaveIconEnabled ? handleToolbarSaveIconClick : undefined}
          />
        </BigidTooltip>
        <BigidTooltip title={t('actions.deleteQuery.name')}>
          <BigidButtonIcon
            icon={BigidDeleteIcon}
            dataAid={generateDataAid(dataAid, ['icon', 'delete'])}
            disabled={!isDeleteIconEnabled}
            onClick={isDeleteIconEnabled ? handleToolbarDeleteIconClick : undefined}
          />
        </BigidTooltip>
      </Root>
      {isDialogShown && (
        <BigidDialog
          borderTop
          showCloseIcon
          isContentScrollable={false}
          maxWidth="xs"
          title={t('actions.saveQuery.dialog.title')}
          isOpen={isDialogShown}
          onClose={handleDialogClose}
          buttons={buttons}
          isLoading={isFormBusy}
        >
          <SaveQueryForm
            dataAid={generateDataAid(dataAid, ['form'])}
            query={query}
            filter={filter}
            savedQueries={savedQueries}
            currentSavedQuery={currentSavedQuery}
            dialogControls={dialogControls}
            onDialogControlsChange={handleDialogControlsChange}
            onClose={handleDialogClose}
            onSavedQueryCreate={onSavedQueryCreate}
            onSavedQueryUpdate={onSavedQueryUpdate}
          />
        </BigidDialog>
      )}
    </>
  );
};
