import { userPreferencesService } from '../../services/userPreferencesService';
import { userPreferencesEventEmitter, UserPreferencesEvents } from '../../services/eventEmitters/userPreferencesEvents';
import { sessionStorageService } from '../../../common/services/sessionStorageService';
import { BigidNavigationItemType } from '@bigid-ui/components';
import { getSelectedWorkspaceId } from '../../services/workspacesService';
import { isWorkspacesEnabled } from '../../utilities/featureFlagUtils';
import { doesPageAllowedForWorkspace, WorkspaceId } from '../../utilities/workspacesUtils';

export const FAVORITES_PAGE_USER_PREFERENCE_NAME = 'favoritePagesManagement';
export const FAVORITES_PAGE_USER_PREFERENCE_NAME_BY_WORKSPACE = 'favoritePagesManagementByWorkspace';

export interface FavoritePageProps {
  link: string;
  dateAdded: Date;
}

export interface FavoritePagePreferences {
  favorites?: FavoritePageProps[];
  workspaceId?: string;
}

export const filterOutdatedFavoritePages = async (
  favorites: FavoritePagePreferences,
): Promise<FavoritePagePreferences> => {
  if (!favorites?.favorites) return favorites;
  const promises = favorites.favorites.map(favorite =>
    doesPageAllowedForWorkspace(favorite.link, favorites.workspaceId as WorkspaceId),
  );
  const results = await Promise.all(promises);
  return {
    ...favorites,
    favorites: favorites.favorites.filter((_, index) => results[index]),
  };
};

export const getFavoritePagePreferencesByWorkspace = async (workspaceId: string): Promise<FavoritePagePreferences> => {
  if (isWorkspacesEnabled()) {
    const preferencesForAllWorkspaces = await userPreferencesService.get<FavoritePagePreferences[]>(
      FAVORITES_PAGE_USER_PREFERENCE_NAME_BY_WORKSPACE,
    );
    const preferences = preferencesForAllWorkspaces?.data?.find(p => p.workspaceId === workspaceId);
    return await filterOutdatedFavoritePages(preferences);
  } else {
    const preferences = await userPreferencesService.get<FavoritePagePreferences>(FAVORITES_PAGE_USER_PREFERENCE_NAME);
    return preferences?.data;
  }
};

export const updateFavoritePagePreferencesByWorkspace = async (workspaceId: string, data: FavoritePagePreferences) => {
  if (isWorkspacesEnabled()) {
    const prevPreferences =
      (await userPreferencesService.get<FavoritePagePreferences[]>(FAVORITES_PAGE_USER_PREFERENCE_NAME_BY_WORKSPACE))
        ?.data || [];
    await userPreferencesService.update<FavoritePagePreferences[]>({
      preference: FAVORITES_PAGE_USER_PREFERENCE_NAME_BY_WORKSPACE,
      data: [{ ...data, workspaceId }, ...prevPreferences.filter(p => p.workspaceId !== workspaceId)],
    });
  } else {
    await userPreferencesService.update<FavoritePagePreferences>({
      preference: FAVORITES_PAGE_USER_PREFERENCE_NAME,
      data,
    });
  }
};

export const onSelectFavoritePage = async (link: string) => {
  const selectedWorkspaceId = getSelectedWorkspaceId();
  const preference = await getFavoritePagePreferencesByWorkspace(selectedWorkspaceId);
  if (!preference) {
    await updateFavoritePagePreferencesByWorkspace(selectedWorkspaceId, {
      favorites: [{ link, dateAdded: new Date() }],
    });
  } else {
    const { favorites = [], ...preferenceData } = preference;
    const favoritesIndex = favorites.findIndex(favorite => favorite.link === link);
    if (favoritesIndex === -1) {
      await updateFavoritePagePreferencesByWorkspace(selectedWorkspaceId, {
        favorites: [...favorites, { link, dateAdded: new Date() }],
        ...preferenceData,
      });
    } else {
      favorites.splice(favoritesIndex, 1);
      await updateFavoritePagePreferencesByWorkspace(selectedWorkspaceId, {
        favorites,
        ...preferenceData,
      });
    }
  }
  userPreferencesEventEmitter.emit(UserPreferencesEvents.UPDATE_FAVORITES_MANAGEMENT_PREFERENCES);
};

export const getFavoritePages = async (initialNavigationItems: BigidNavigationItemType[]) => {
  const selectedWorkspaceId = getSelectedWorkspaceId();
  const data = (await getFavoritePagePreferencesByWorkspace(selectedWorkspaceId)) || {};

  if (!data.favorites) {
    sessionStorageService.set('FAVORITE_PAGES', []);
    return { favoriteItems: [], favoritesOriginal: [] };
  }

  const favoriteItems: BigidNavigationItemType[] = [];
  const favoriteLinks = new Set();

  data.favorites.forEach(favoriteElement => {
    const mainItemElement = initialNavigationItems?.find(navEl => navEl.link === favoriteElement.link);

    if (mainItemElement && !favoriteLinks.has(mainItemElement.link)) {
      favoriteItems.push(mainItemElement);
      favoriteLinks.add(mainItemElement.link);
    } else {
      initialNavigationItems.forEach(element => {
        const readySubElement = element?.quickViewSchema?.menuItems?.find(
          (navEl: any) => navEl.link === favoriteElement.link,
        );

        if (readySubElement && !favoriteLinks.has(readySubElement.link)) {
          favoriteItems.push(readySubElement as BigidNavigationItemType);
          favoriteLinks.add(readySubElement.link);
        }
      });
    }
  });
  sessionStorageService.set('FAVORITE_PAGES', favoriteItems);
  return { favoriteItems, favoritesOriginal: data?.favorites };
};
