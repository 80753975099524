import { BigidLoader } from '@bigid-ui/components';
import { generateDataAid } from '@bigid-ui/utils';
import { styled } from '@mui/material';
import React, { ReactNode } from 'react';
import { ListEntityItem } from '../../types';
import { ListItem } from './ListItem';

const Container = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-grow: 1;
`;

const List = styled('ul')`
  list-style: none;
  display: flex;
  flex-direction: column;
  padding: 8px 0 0 0;
  margin: 0;
  gap: 14px;
  width: 100%;
`;

type EntityListProps = {
  entities: ListEntityItem[];
  hasSeparator?: boolean;
  dataAid?: string;
  listItemMaxWidth?: string;
  noDataComponent?: ReactNode;
  isLoaderShown: boolean;
};

export const EntityList = ({
  entities,
  hasSeparator,
  dataAid = 'EntityList',
  listItemMaxWidth,
  noDataComponent,
  isLoaderShown,
}: EntityListProps) => {
  return (
    <Container data-aid={generateDataAid(dataAid, ['container'])}>
      {isLoaderShown ? (
        <BigidLoader position="relative" />
      ) : entities.length > 0 ? (
        <List data-aid={generateDataAid(dataAid, ['items'])}>
          {entities.map(({ name, icon, onClick, tooltipText, additionalInfo }, index, arr) => (
            <ListItem
              onClick={onClick}
              icon={icon}
              additionalInfo={additionalInfo}
              name={name}
              tooltipText={tooltipText}
              key={name}
              maxWidth={listItemMaxWidth}
              dataAid={generateDataAid(dataAid, ['item'])}
              showUnderline={hasSeparator && index !== arr.length - 1}
            />
          ))}
        </List>
      ) : (
        noDataComponent
      )}
    </Container>
  );
};
