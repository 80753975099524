import React, { FC, ReactText, useCallback, useEffect, useState, ComponentType, useMemo, memo } from 'react';
import { BigidSkeletonGenerator } from '@bigid-ui/components';
import { generateDataAid } from '@bigid-ui/utils';
import { IconComponentProps } from '@bigid-ui/icons';
import { UseCatalogDiscoveryResponse } from '../../useCatalogDiscovery';
import { getSummaryWidgetValue, getAggregationIcon } from '../../utils/widgets';
import { SummaryWidgetWrapper } from '../../utils/SummaryWidgetWrapper';
import { getAggregatedData, GetAggregatedDataPayload, GetAggregatedDataResponse } from '../../catalogDiscoveryService';
import { useLocalTranslation } from './translations';
import { AggregationType } from '../../catalogDiscoveryTypes';
import { SummaryWidget } from '../../widgets/SummaryWidget/SummaryWidget';
import { useFetchDataCancelable } from '../../config/useFetchDataCancelable';
import { summaryWidgetSkeletonConfig } from '../../config/skeleton';
import { CatalogDiscoveryWidget } from '../../config/widgets';

export interface FindingsSummaryProps extends Pick<UseCatalogDiscoveryResponse, 'query' | 'onDataFetchStatusChange'> {
  dataAid?: string;
  isPageInitialised: boolean;
}

export const FindingsSummary: FC<FindingsSummaryProps> = memo(
  ({ dataAid = 'FindingsSummary', query, onDataFetchStatusChange, isPageInitialised }) => {
    const { fetchFindingsCancelable } = useFetchDataCancelable();
    const { t } = useLocalTranslation();
    const icon: ComponentType<IconComponentProps> = useMemo(() => getAggregationIcon(AggregationType.HAS_FINDINGS), []);
    const [widgetValue, setWidgetValue] = useState<ReactText>();
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const fetchAggregationData = useCallback(async () => {
      try {
        const payload: GetAggregatedDataPayload = {
          filter: query,
          aggregations: [
            {
              aggName: AggregationType.HAS_FINDINGS,
              isTotalRequired: true,
            },
          ],
        };

        setIsLoading(true);
        onDataFetchStatusChange(CatalogDiscoveryWidget.FINDINGS_SUMMARY, true);

        const { aggregations } = await fetchFindingsCancelable(
          getAggregatedData(payload) as Promise<GetAggregatedDataResponse>,
        );

        const value = getSummaryWidgetValue(aggregations?.[0], {
          value: 'findings',
        });

        setWidgetValue(value);
      } catch ({ isCanceled, message }) {
        if (!isCanceled) {
          console.error(`An error has occurred: ${message}`);
        }
      } finally {
        setIsLoading(false);
        onDataFetchStatusChange(CatalogDiscoveryWidget.FINDINGS_SUMMARY, false);
      }
    }, [fetchFindingsCancelable, onDataFetchStatusChange, query]);

    useEffect(() => {
      if (isPageInitialised) {
        fetchAggregationData();
      }
    }, [fetchAggregationData, isPageInitialised]);

    return (
      <SummaryWidgetWrapper dataAid={dataAid}>
        {isPageInitialised ? (
          <SummaryWidget
            dataAid={generateDataAid(dataAid, ['widget'])}
            label={t('title')}
            tooltipText={t('tooltip.text')}
            value={widgetValue}
            icon={icon}
            isBusy={isLoading}
          />
        ) : (
          <BigidSkeletonGenerator dataAid={generateDataAid(dataAid, ['skeleton'])} {...summaryWidgetSkeletonConfig} />
        )}
      </SummaryWidgetWrapper>
    );
  },
  (prevProps, newProps) =>
    prevProps.query === newProps.query && prevProps.isPageInitialised === newProps.isPageInitialised,
);
