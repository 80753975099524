import { isNil } from 'lodash';
import { openSystemDialog, BigidSystemDialogOptions } from '../../services/systemDialogService';
import { BigidCarouselLottieSlide } from '@bigid-ui/components';
import { EaProgramModalContent } from './EaProgramModalContent';
import { getApplicationPreference, initApplicationPreferences } from '../../services/appPreferencesService';
import { userPreferencesService } from '../../services/userPreferencesService';
import { notificationService } from '../../services/notificationService';
import { httpService } from '../../services/httpService';
import { docsUrls } from '../../config/publicUrls';
import { getFixedT } from './translations';
import { ServiceConfigurationsModel } from '../../views/AdvancedTools/ServicesConfiguration/ServicesConfiguration';
import { EaProgramEvents, trackEaProgramEvent } from './eaProgramEventsUtil';
import onboardingLottie from './carouselItems/onboardingLottie.json';
import workspacesLottie from './carouselItems/workspacesLottie.json';
import collaborationLottie from './carouselItems/collaborationLottie.json';

export enum ONBOARDING_MODAL_STATE {
  NOT_SHOWED = 'NOT_SHOWED',
  SHOWED = 'SHOWED',
  DO_NOT_SHOW_AGAIN = 'DO_NOT_SHOW_AGAIN',
}

export const CUSTOMER_EA_PROGRAM_ENABLED_FF = 'CUSTOMER_EA_PROGRAM_ENABLED';
export const CUSTOMER_OPT_IN_EA_PROGRAM_FF = 'CUSTOMER_OPT_IN_EA_PROGRAM';
export const USE_SAAS_FF = 'USE_SAAS';
export const EA_PROGRAM_USER_PREFERENCES = 'eaProgram';
export const EA_PROGRAM_DIALOG_REMINDER_PERIOD_IN_DAYS = 90;
const t = getFixedT('');

export interface EaCarouselItems extends BigidCarouselLottieSlide {
  title: string;
  body: string;
  learnMoreUrl: string;
}

export const carouselItems: EaCarouselItems[] = [
  {
    title: t('carouselItem.workspaces.title'),
    body: t('carouselItem.workspaces.body'),
    json: workspacesLottie,
    type: 'lottie',
    learnMoreUrl: docsUrls.WORKSPACES_DOC,
  },
  {
    title: t('carouselItem.onboardingAssistant.title'),
    body: t('carouselItem.onboardingAssistant.body'),
    json: onboardingLottie,
    type: 'lottie',
    learnMoreUrl: docsUrls.DATA_SOURCES_ONBOARDING,
  },
  {
    title: t('carouselItem.collaboration.title'),
    body: t('carouselItem.collaboration.body'),
    json: collaborationLottie,
    type: 'lottie',
    learnMoreUrl: docsUrls.COLLABORATION_DOC,
  },
];

export const getOptedInToEaProgramValue = async () => {
  try {
    const { data } = await httpService.fetch<ServiceConfigurationsModel[]>(`services-configuration`);
    return data.find(param => param.name === CUSTOMER_OPT_IN_EA_PROGRAM_FF)?.value;
  } catch (e) {
    return getApplicationPreference(CUSTOMER_OPT_IN_EA_PROGRAM_FF);
  }
};

export const isEaProgramEnabled = () => {
  const useSaasEnv = getApplicationPreference(USE_SAAS_FF);
  const eaProgramEnabled = getApplicationPreference(CUSTOMER_EA_PROGRAM_ENABLED_FF);
  return useSaasEnv && eaProgramEnabled;
};

const generateEaProgramModalOptions = (): BigidSystemDialogOptions => {
  return {
    title: t('modalTitle'),
    onClose: () => trackEaProgramEvent(EaProgramEvents.EA_PROGRAM_CLOSE_DIALOG_CLICK),
    content: EaProgramModalContent,
    maxWidth: 'sm',
  };
};

const isSelectionExpired = (selectionDate: string) => {
  const expireationDate = new Date(selectionDate);
  const currentDate = new Date();
  expireationDate.setDate(expireationDate.getDate() + EA_PROGRAM_DIALOG_REMINDER_PERIOD_IN_DAYS);

  return currentDate.getTime() > expireationDate.getTime();
};

export const setEaProgramOptInValue = async (isAccepted: boolean) => {
  try {
    const configuration = {
      name: CUSTOMER_OPT_IN_EA_PROGRAM_FF,
      id: CUSTOMER_OPT_IN_EA_PROGRAM_FF,
      isEditable: false,
      isRestartRequired: false,
      markAsSensitiveKey: false,
      type: 'boolean',
      value: isAccepted,
    };
    const webPromise = httpService.put('services-configuration', {
      configuration: { ...configuration, service: 'web' },
    });
    const orchPromise = httpService.put('services-configuration', {
      configuration: { ...configuration, service: 'orchestrator' },
    });
    const orch2Promise = httpService.put('services-configuration', {
      configuration: { ...configuration, service: 'orchestrator2' },
    });

    await Promise.all([webPromise, orchPromise, orch2Promise]);
    await initApplicationPreferences();
  } catch {
    notificationService.error(t('errorMessages.serviceConfiguration'));
  }
};

export const updateEaProgramUserSelection = async (isAccepted: boolean, currentProgramStatus: boolean) => {
  if (isAccepted !== currentProgramStatus) {
    await setEaProgramOptInValue(isAccepted);
    notificationService.success(t(`joinedProgramMessage.${isAccepted}`));
  }
  await userPreferencesService.update({ preference: EA_PROGRAM_USER_PREFERENCES, data: { isAccepted } });
};

export const shouldDisplayEaProgramModal = async () => {
  const isOptIn = getApplicationPreference(CUSTOMER_OPT_IN_EA_PROGRAM_FF);
  if (!isOptIn) {
    const { updated_at, data } = (await userPreferencesService.get(EA_PROGRAM_USER_PREFERENCES)) || {};
    if (!updated_at) {
      await userPreferencesService.add({ preference: EA_PROGRAM_USER_PREFERENCES });
      return true;
    }
    if (!data?.isDismissed && isNil(data?.isAccepted)) {
      return true;
    }

    return isSelectionExpired(updated_at);
  }
  return false;
};

export const openEaProgramModal = () => {
  const eaProgramModalOptions = generateEaProgramModalOptions();
  openSystemDialog(eaProgramModalOptions);
};
