import './consentConnections.component.scss';
import template from './consentConnections.component.html';
import { CONSENT_GOVERNANCE_PERMISSIONS } from '@bigid/permissions';
import { module } from 'angular';
import { isPermitted } from '../../react/services/userPermissionsService';
const app = module('app');

app.component('consentCommon', {
  require: {
    consentGovernanceCtrl: '^^consentGovernance',
  },
  template,
  controller: function (
    $scope,
    $state,
    uiGridConstants,
    consentConnectionsService,
    DeleteConfirmation,
    notificationService,
  ) {
    'ngInject';
    this.idsorSelection = [];

    $scope.allIdSelected = false;

    this.$onInit = () => {
      $scope.isCreatePermitted = isPermitted(CONSENT_GOVERNANCE_PERMISSIONS.CREATE_CONSENT_SOURCES.name);
      $scope.isEditPermitted = isPermitted(CONSENT_GOVERNANCE_PERMISSIONS.EDIT_CONSENT_SOURCES.name);
      $scope.isDeletePermitted = isPermitted(CONSENT_GOVERNANCE_PERMISSIONS.DELETE_CONSENT_SOURCES.name);
      $scope.isReadPermitted = isPermitted(CONSENT_GOVERNANCE_PERMISSIONS.READ_CONSENT_SOURCES.name);

      getConsentConnectionsData();
      prepareConsentConnectionsModel();
    };

    const getConsentConnectionsData = filterValue => {
      this.idsorSelection = this.idsorlist;
      this.onLoading();
      consentConnectionsService
        .getConsentConnectionsData(filterValue)
        .then(result => {
          this.consentConnectionsModel.data = result;
          if (this.idsorSelection) {
            for (let i = 0; i < this.consentConnectionsModel.data.length; i++) {
              if (this.idsorSelection.indexOf(this.consentConnectionsModel.data[i].name) != -1) {
                this.consentConnectionsModel.data[i].selection = true;
              }
            }
          }
        })
        .finally(() => {
          this.onLoaded();
        });
    };

    const prepareConsentConnectionsModel = () => {
      const availableTypeOptions = [
        { value: 'mysql', label: 'MySQL' },
        { value: 'postgres', label: 'Postgres' },
        { value: 'ms-sql', label: 'MS-SQL' },
        { value: 'oracle', label: 'Oracle' },
        { value: 'salesforce', label: 'Salesforce' },
      ];

      const availableEnableOptions = [
        { value: 'yes', label: 'Yes' },
        { value: 'no', label: 'No' },
      ];

      const is_success = [
        { value: 'true', label: 'Succeed' },
        { value: 'false', label: 'Failed' },
      ];

      this.consentConnectionsModel = {
        enableSorting: true,
        enableFiltering: true,
        enablePaginationControls: false,
      };
      this.consentConnectionsModel.columnDefs = [
        {
          name: 'Name',
          width: this.displaytype == 'extended' ? '29%' : '70%',
          field: 'name',
          cellTemplate: `<div style="padding: 8px 0 0 10px;">
            ${
              $scope.isReadPermitted
                ? '<a ui-sref="newConsentConnection({id:' + ' row.entity.id})">{{row.entity.name}}</a>'
                : '<div>{{row.entity.name}}</div>'
            }
          </div>`,
          filter: {
            condition: uiGridConstants.filter.CONTAINS,
            type: uiGridConstants.filter.INPUT,
            flags: { caseSensitive: false },
          },
        },
        {
          name: 'Data Source',
          field: 'dsConnection',
          width: this.displaytype == 'extended' ? '14%' : '25%',
          filter: { type: uiGridConstants.filter.SELECT, selectOptions: availableTypeOptions },
        },
      ];

      if (this.displaytype == 'compact') {
        this.consentConnectionsModel.columnDefs.push({
          cellClass: 'grid-align',
          name: 'Select',
          field: 'selection',
          enableFiltering: false,
          enableCellEdit: true,
          width: '10%',
          visible: true,
          cellTooltip: row => 'Select columns',
          headerCellTemplate: `<div style="height: auto" class="ui-grid-cell-contents">Select</div>
            <input
              style="z-index: 99999; margin-left: 6px"
              title="Select all columns"
              type="checkbox"
              ng-change="grid.appScope.selectionChanged()"
              ng-model="this.allIdSelected"/>`,
          cellTemplate: `<input
            style="z-index: 99999;"
            title="Select columns"
            type="checkbox"
            ng-change="grid.appScope.selectionChanged(row.entity)"
            ng-model="row.entity.selection"/>`,
        });
      } else if (this.displaytype == 'extended') {
        this.consentConnectionsModel.columnDefs.push(
          {
            name: 'Table Name',
            field: 'db_table',
            width: '20%',
            filter: {
              condition: uiGridConstants.filter.CONTAINS,
              type: uiGridConstants.filter.INPUT,
              flags: { caseSensitive: false },
            },
          },
          {
            name: 'Last Test',
            width: '14%',
            field: 'connectionStatusTest.is_success',
            enableSorting: false,
            enableFiltering: true,
            enableCellEdit: false,
            cellTemplate: `<div class="ui-grid-contents last-connection-col">
              <div
                ng-if="row.entity.connectionStatusTest.is_success === true"
                class="icon-success"
              ></div>
              <div
                ng-if="row.entity.connectionStatusTest.is_success === false"
                class="icon-failed"
              ></div>
              <div>
                {{row.entity.connectionStatusTest.last_connection | date: "dd.MM.yyyy,
                HH:mm:ss"}}
              </div>
            </div>`,
            filter: { type: uiGridConstants.filter.SELECT, selectOptions: is_success },
          },
          {
            name: 'Enabled',
            field: 'enabled',
            displayName: 'Enabled',
            enableCellEdit: false,
            cellTemplate: `<div class="ui-grid-contents" style="text-align: left;">
              <input
                style="z-index: 99999;"
                ng-attr-title="{{row.entity.enabled ? 'Disable' : 'Enable'}}"
                type="checkbox"
                ng-if="grid.appScope.isEditPermitted"
                ng-change="grid.appScope.enabledChange(row.entity)"
                ng-model="row.entity.enabled"
                ng-true-value="'yes'"
                ng-false-value="'no'"
              />
            </div>`,
            width: '8%',
            filter: { type: uiGridConstants.filter.SELECT, selectOptions: availableEnableOptions },
          },
          {
            name: 'Actions',
            enableFiltering: false,
            enableSorting: false,
            field: '',
            width: '10%',
            headerCellTemplate: '<div></div>',
            cellTemplate: `<div class="actions-container" ng-if="grid.appScope.isEditPermitted || grid.appScope.isCreatePermitted || grid.appScope.isDeletePermitted">
              <div class="three-points"></div>
              <div
                ng-init="currRowIndex = grid.renderContainers.body.visibleRowCache.indexOf(row)"
                class="img-container">
                <div
                  class="img-button img-edit-button tooltip"
                  ng-class="{bottom: currRowIndex == 0, top: currRowIndex > 0}"
                  ng-if="grid.appScope.isEditPermitted"
                  ng-click="grid.appScope.edit(row.entity.id)">
                  <div class="tooltip-container">
                    <span class="tooltip-text">Edit</span>
                  </div>
                </div>
                <div
                  class="img-button img-delete-button tooltip"
                  ng-class="{bottom: currRowIndex == 0, top: currRowIndex > 0}"
                  ng-if="grid.appScope.isDeletePermitted"
                  ng-click="grid.appScope.delete(row.entity.name, row.entity.id)">
                  <div class="tooltip-container">
                    <span class="tooltip-text">Delete</span>
                  </div>
                </div>
                <div
                  class="img-button img-duplicate-button tooltip"
                  ng-class="{bottom: currRowIndex == 0, top: currRowIndex > 0}"
                  ng-if="grid.appScope.isCreatePermitted"
                  ng-click="grid.appScope.copy(row.entity)">
                  <div class="tooltip-container">
                    <span class="tooltip-text">Duplicate</span>
                  </div>
                </div>
                <div><div></div></div>
              </div>
            </div>`,
          },
        );
      }

      this.consentConnectionsModel.onRegisterApi = gridApi => {
        $scope.gridApi = gridApi;
        gridApi.edit.on.afterCellEdit($scope, (rowEntity, colDef, newValue) => {
          if (colDef.name === 'Enabled') {
            updateConsentConnections(rowEntity, newValue);
          }
          $scope.$evalAsync();
        });
      };
    };

    $scope.enabledChange = entity => {
      updateConsentConnections(entity, entity.enabled);
    };

    const updateConsentConnections = (rowEntity, newValue) => {
      $scope.consent_connection = {};
      $scope.consent_connection.consent_connection = {};
      $scope.consent_connection.consent_connection.enabled = newValue;
      consentConnectionsService.updateConsentConnection(rowEntity.id, $scope.consent_connection).then(
        () => {
          notificationService.success(`${rowEntity.name} Consent connections updated successfully`);
        },
        () => {
          notificationService.error(`${rowEntity.name} Consent connections updated failed`);
        },
      );
    };

    $scope.selectionChanged = entity => {
      if (!entity) {
        $scope.allIdSelected = !$scope.allIdSelected;
        this.idsorSelection = [];
        this.consentConnectionsModel.data.forEach(entity => {
          if ($scope.allIdSelected) {
            this.idsorSelection.push(entity.name);
            entity.selection = true;
          } else {
            entity.selection = false;
          }
        });
      } else {
        if (!this.idsorSelection) {
          this.idsorSelection = [];
        }
        const idsorPosition = this.idsorSelection.indexOf(entity.name);
        if (idsorPosition > -1) {
          this.idsorSelection.splice(idsorPosition, 1);
        } else {
          this.idsorSelection.push(entity.name);
        }
      }
      this.onselect({ value: this.idsorSelection });
    };

    $scope.edit = consentId => {
      $state.go('newConsentConnection', { id: consentId });
    };

    $scope.delete = (name, consentId) => {
      const modalOptions = {
        closeButtonText: 'Close',
        actionButtonText: 'Delete',
        headerText: 'Delete consent connection',
        bodyText: `Are you sure you want to delete ${name}?`,
      };
      DeleteConfirmation.showModal({}, modalOptions).then(result => {
        consentConnectionsService.deleteConsentConnectionsDataByID(consentId).then(
          () => {
            getConsentConnectionsData();
            notificationService.success(`${name} Deleted Successfully! `);
          },
          () => {
            notificationService.error(`Delete ${name} Failed! `);
          },
        );
      });
    };

    $scope.copy = consentConnection => {
      consentConnectionsService.copyConsent(consentConnection).then(
        () => {
          notificationService.success('Copied Successfully!');
          getConsentConnectionsData();
        },
        () => {
          notificationService.error('An error has occurred!');
        },
      );
    };

    this.onLoading = () => {
      this.consentGovernanceCtrl.onContentDataLoading();
    };

    this.onLoaded = () => {
      this.consentGovernanceCtrl.onContentDataLoaded();
    };
  },
  bindings: {
    displaytype: '@',
    onselect: '&',
    idsorlist: '=',
  },
});
