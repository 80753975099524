import { getSupportedDataSources } from '../../../../utilities/dataSourcesUtils';
import { DataSourcesUITrackingEvent, trackEventDataSources } from '../../DataSourcesEventTrackerUtils';
import { NEW_CONNECTION_EXCLUDED_DATA_SOURCES } from '../../constants/newConnectionFlowConstants';
import { getApplicationPreference } from '../../../../services/appPreferencesService';
import { $state } from '../../../../services/angularServices';
import { CONFIG } from '../../../../../config/common';
import { isPermitted } from '../../../../services/userPermissionsService';
import { DATA_SOURCES_PERMISSIONS } from '@bigid/permissions';
import { DataSourceOnboardingTypesProvider, OnSelectOnboardingType } from '../types';

const fetchTypes = async () => {
  const items = await getSupportedDataSources(true);

  return items.map(
    ({ type, name, displayName, keywords, modelProp, nameInDocs, status, templateName, isPermitted }) => ({
      type,
      status,
      displayName,
      keywords,
      name,
      modelProp,
      nameInDocs,
      isPermitted,
      templateName,
    }),
  );
};

const onSelectType: OnSelectOnboardingType = ({ type, name, templateName, displayName, nameInDocs }) => {
  trackEventDataSources(DataSourcesUITrackingEvent.DATA_SOURCES_INIT_SELECT_DS_CLICK, {
    selectedType: templateName,
  });

  const dataSourceType = name.toLowerCase();
  const supportsNewConnectionFlow = !NEW_CONNECTION_EXCLUDED_DATA_SOURCES.includes(dataSourceType as any);
  const isNewConnectivityEnabled = getApplicationPreference('CONNECTIVITY_EXPERIENCE_ENABLED');
  const isNewConnectionFlow = isNewConnectivityEnabled && supportsNewConnectionFlow;

  if (isNewConnectionFlow) {
    return $state.go(CONFIG.states.DATA_SOURCE_CONNECTIONS, {
      shouldDisplayNewConnectionModal: true,
      sourceType: dataSourceType,
      dsLabel: displayName,
      nameInDocs: nameInDocs,
    });
  }

  if (getApplicationPreference('DS_TYPES_TEMPLATES_SUPPORTED') && templateName) {
    return $state.go(`configDataSourceNew`, {
      selectedType: type.toLowerCase(),
    });
  }
  $state.go('newDataSourceConnection', { selectedType: type, id: null });
};
const isPermittedType = () => isPermitted(DATA_SOURCES_PERMISSIONS.ACCESS.name);

export const deepDiscoveryProvider: DataSourceOnboardingTypesProvider = {
  fetch: fetchTypes,
  onSelectType,
  isPermitted: isPermittedType,
};
