import { createTag, updateTag, deleteTag, TagEntity, TagCompositionPartType } from '../TagsManagementService';
import { TagsManagementGridRecordExtended } from '../TagsManagement';
import { ExtraFieldItemData } from '@bigid-ui/components';

export interface EditTagPayloadValues {
  newTagValues?: ExtraFieldItemData[];
  updatedTagValues?: ExtraFieldItemData[];
  deletedTagValues?: ExtraFieldItemData[];
}

export interface EditTagResponse {
  newTagValues: TagEntity[];
  updatedTagValues: TagEntity[];
  deletedTagValues: TagEntity[];
}

export interface EditTagPayload {
  tagId?: TagsManagementGridRecordExtended['tagId'];
  tagName: TagsManagementGridRecordExtended['tagName'];
  tagValues: EditTagPayloadValues;
  isMutuallyExclusive?: boolean;
  shouldUpdateTag?: boolean;
}

export const addTag = async ({ tagName, tagValues, isMutuallyExclusive }: EditTagPayload): Promise<TagEntity[]> => {
  const tagsAdded: TagEntity[] = [];

  const { data: tagCreatedResponse } = await createTag({
    name: tagName,
    type: TagCompositionPartType.tag,
    description: `${tagName} description`,
    isMutuallyExclusive,
  });
  const tagNameIdToAttach = tagCreatedResponse[0]._id;
  const { newTagValues } = tagValues;

  for (const tagValue of newTagValues) {
    const { value } = tagValue;
    const { data: valueCreatedResponse } = await createTag({
      name: value,
      type: TagCompositionPartType.value,
      description: `${value} description`,
      parentId: tagNameIdToAttach,
    });

    tagsAdded.push({
      tagId: tagNameIdToAttach,
      tagName: tagName,
      valueId: valueCreatedResponse[0]._id,
      tagValue: value,
    });
  }

  return tagsAdded;
};

export const editTag = async ({
  tagId,
  tagName,
  tagValues,
  isMutuallyExclusive,
  shouldUpdateTag,
}: EditTagPayload): Promise<EditTagResponse> => {
  const tagsUpdated: EditTagResponse = {
    newTagValues: [],
    updatedTagValues: [],
    deletedTagValues: [],
  };

  if (shouldUpdateTag) {
    await updateTag(tagId, {
      name: tagName,
      isMutuallyExclusive,
    });
  }

  const { newTagValues = [], updatedTagValues = [], deletedTagValues = [] } = tagValues;

  for (const tagValue of deletedTagValues) {
    const { value, valueId } = tagValue;
    await deleteTag(valueId);

    tagsUpdated.deletedTagValues.push({
      tagId,
      tagName: tagName,
      valueId,
      tagValue: value,
    });
  }

  for (const tagValue of updatedTagValues) {
    const { value, valueId } = tagValue;
    await updateTag(valueId, {
      name: value,
    });

    tagsUpdated.updatedTagValues.push({
      tagId,
      tagName: tagName,
      valueId,
      tagValue: value,
    });
  }

  for (const tagValue of newTagValues) {
    const { value } = tagValue;

    const { data: valueCreatedResponse } = await createTag({
      name: value,
      type: TagCompositionPartType.value,
      description: `${value} description`,
      parentId: tagId,
    });

    const valueId = valueCreatedResponse[0]._id;

    tagsUpdated.newTagValues.push({
      tagId,
      tagName: tagName,
      valueId,
      tagValue: value,
    });
  }

  return tagsUpdated;
};
