import { module } from 'angular';
import { httpService } from '../../react/services/httpService';
import { getDataCatalogRecordsAsCSV } from '../../react/views/DataCatalog/DataCatalogService';
import { downloadFile } from '../../react/services/downloadFile';
import { notificationService } from '../../react/services/notificationService';
const app = module('app');

app.factory('scansService', function ($http, appSettings, downloadFileService) {
  'ngInject';
  const multiPartConfig = {
    transformRequest: angular.identity,
    headers: {
      'Content-Type': undefined,
    },
  };
  const scanTypes = {
    DS_SCAN: {
      id: 'dsScan',
      name: 'Data Source Scan',
      type: 'full_scan',
    },
    LINEAGE_SCAN: {
      id: 'lineageScan',
      name: 'Lineage Scan',
      type: 'lineageScan',
    },
    METADATA_SCAN: {
      id: 'metadataScan',
      name: 'Metadata Scan',
      type: 'metadataScan',
    },
    DS_TAG: {
      id: 'dsTag',
      name: 'Labeling',
      type: 'dsTag',
    },
    DATA_IN_MOTION: {
      id: 'dataInMotion',
      name: 'Data in Motion',
      type: 'dataInMotion',
    },
    SCAN_API: {
      id: 'scan_api',
      name: 'Scan Api',
      type: 'scan_api',
    },
    HYPER_SCAN: {
      id: 'hyper_scan',
      name: 'Hyperscan',
      type: 'hyper_scan',
    },
    HOTSPOTS_SCAN: {
      id: 'hotspots_scan',
      name: 'Hotspots',
      type: 'hotspots_scan',
    },
    ASSESSMENT_SCAN: {
      id: 'assessment_scan',
      name: 'Assessment Scan',
      type: 'assessment_scan',
    },
  };
  const scanTypesMap = Object.values(scanTypes).reduce((map, obj) => {
    map[obj.id] = obj.name;
    return map;
  }, {});

  return {
    runScan: type =>
      $http.post(`${appSettings.serverPath}/${appSettings.version}/scans`, type).then(response => response.data),

    getScansData: (size, fetchFilter = 'active') =>
      $http
        .get(`${appSettings.serverPath}/${appSettings.version}/scans?filter=${fetchFilter}&size=${size}`)
        .then(response => response.data),

    getScanData: id =>
      $http.get(`${appSettings.serverPath}/${appSettings.version}/scans/${id}`).then(response => response.data),

    getScanProfilesData: () =>
      $http.get(`${appSettings.serverPath}/${appSettings.version}/scanProfiles`).then(response => response.data),

    getPiiFindings: () =>
      $http
        .get(`${appSettings.serverPath}/${appSettings.version}/piiFindings/count`)
        .then(response => response.data.pii_findings_count),

    createScanProfile: data =>
      $http
        .post(`${appSettings.serverPath}/${appSettings.version}/scanProfiles/`, data, multiPartConfig)
        .then(response => response),

    updateScanProfile: (id, data) =>
      $http
        .put(`${appSettings.serverPath}/${appSettings.version}/scanProfiles/${id}`, data, multiPartConfig)
        .then(response => response),

    deleteScanProfile: id =>
      $http.delete(`${appSettings.serverPath}/${appSettings.version}/scanProfiles/${id}`).then(response => response),

    getScanProfile: id => httpService.fetch(`scanProfiles/${id}`).then(response => response),

    stopSubScan: scanId =>
      $http
        .put(`${appSettings.serverPath}/${appSettings.version}/scans/${scanId}/requestStop`)
        .then(response => response.data),

    stopLabelingScan: scanId =>
      $http
        .put(`${appSettings.serverPath}/${appSettings.version}/labeler/${scanId}/request-stop`)
        .then(response => response.data),

    getScanInsights: scanId =>
      $http
        .get(`${appSettings.serverPath}/${appSettings.version}/scan-insights/${scanId}`)
        .then(response => response.data),

    retryScan: scanId =>
      $http
        .put(`${appSettings.serverPath}/${appSettings.version}/scans/${scanId}/requestRetry`)
        .then(response => response.data),

    pauseScan: scanId =>
      $http
        .put(`${appSettings.serverPath}/${appSettings.version}/scans/${scanId}/requestPause`)
        .then(response => response.data),

    resumeScan: scanId =>
      $http
        .put(`${appSettings.serverPath}/${appSettings.version}/scans/${scanId}/requestResume`)
        .then(response => response.data),

    archiveScan: scanId =>
      $http
        .post(`${appSettings.serverPath}/${appSettings.version}/scans/archived`, { scan_ids: [scanId] })
        .then(response => response.data),

    getFailedObjectReport: async (subscanId = '') => {
      try {
        const url = `file-download/failed-object-report/${subscanId}`;
        return await httpService.downloadFile(url);
      } catch (error) {
        console.error(error);
        notificationService.error('Download failed. See logs for more information');
      }
    },

    exportMetadata: async query => {
      downloadFile(
        await getDataCatalogRecordsAsCSV(query.length > 0 && `&${query}`),
        'attachment/octet-stream',
        `data-catalog-${new Date().toISOString()}.csv`,
      );
    },

    applyPredictor: scanProfileName =>
      httpService.post(`hyper-scan/predict`, { scanProfileName }).then(response => response.data),

    retryPredict: scanId =>
      $http
        .post(`${appSettings.serverPath}/${appSettings.version}/scans/${scanId}/retryPredict`)
        .then(response => response.data),

    downloadJitScanFailedObjectReport: id => {
      return httpService
        .fetch(`sar/reports/${encodeURIComponent(id)}/failed-objects?format=csv`)
        .then(({ headers, data }) => {
          if (data !== null) {
            const csv = data;
            const contentDispositionHeader = headers['content-disposition'];
            const fileName = contentDispositionHeader
              ? contentDispositionHeader.split('filename=').pop().replace(/'"/, '')
              : `failed-object-report-${id}.csv`;
            downloadFileService.download(csv, 'text/csv; charset=utf-8', fileName);
          }
        })
        .catch(err => {
          $translate('API:MESSAGE:COMMON_ERROR').then(translation => {
            notificationService.error(translation);
          });
          window.console.error('Error while downloading jit scan parent failed object report', err);
          throw err;
        });
    },

    scanTypes,

    getScanTypeNameById: scanTypeId => {
      return scanTypesMap[scanTypeId];
    },
  };
});
