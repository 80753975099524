import React, {
  FC,
  useState,
  ChangeEvent,
  useMemo,
  useCallback,
  useEffect,
  KeyboardEvent,
  ClipboardEvent,
  useRef,
} from 'react';
import {
  BigidTextField,
  BigidHeading6,
  BigidDropdown,
  BigidDropdownOption,
  BigidDropdownValue,
} from '@bigid-ui/components';
import makeStyles from '@mui/styles/makeStyles';
import { MetadataSearchFilterComponentBaseProps } from '../../MetadataSearchFiltersTypes';
import {
  MetadataSearchFilterNumberOperatorLabelMap,
  getIsValueValidPositiveNumber,
} from './MetadataSearchFilterNumberUtils';
import { debounce } from 'lodash';

export type MetadataSearchFilterNumberProps = MetadataSearchFilterComponentBaseProps;

const useStyles = makeStyles({
  root: {
    width: '100%',
    display: 'flex',
  },
  control: {
    display: 'flex',
    flexDirection: 'column',
    padding: '8px',
    width: '50%',
    justifyContent: 'flex-end',
  },
  label: {
    marginBottom: '8px',
  },
});

export const MetadataSearchFilterNumber: FC<MetadataSearchFilterNumberProps> = ({
  dataAid,
  config,
  value: initialValue,
  onFilterChange,
  isFiltersFetching,
  focused,
}) => {
  const classes = useStyles();
  const { fieldName, fieldType, displayName } = config;
  const label = displayName || fieldName;
  const operatorOptions: BigidDropdownOption[] = useMemo(
    () =>
      Object.entries(MetadataSearchFilterNumberOperatorLabelMap).map(([operator, label]) => ({
        id: String(operator),
        displayValue: label,
        value: operator,
      })),
    [],
  );
  const [operatorSelected, setOperatorSelected] = useState<BigidDropdownValue>([]);
  const inputRef = useRef<HTMLTextAreaElement>();

  useEffect(() => {
    setOperatorSelected(prevOperatorSelected => {
      return prevOperatorSelected.length > 0
        ? prevOperatorSelected
        : [operatorOptions.find(({ value }) => value === initialValue?.operator) || operatorOptions[0]];
    });
  }, [initialValue, operatorOptions]);

  const handleOnOperatorSelect = useCallback(
    (selectedValue: BigidDropdownOption[]): void => {
      setOperatorSelected(selectedValue);

      if (initialValue?.value) {
        const operator = selectedValue[0].value;

        onFilterChange({
          field: fieldName,
          value: initialValue.value,
          operator,
          fieldType,
          fieldDisplayName: label,
        });
      }
    },
    [fieldName, fieldType, initialValue, label, onFilterChange],
  );

  const handleOnValueChange = useCallback(
    ({ target }: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void => {
      const operator = operatorSelected?.[0].value || 'equal';

      onFilterChange({
        field: fieldName,
        value: target.value,
        operator,
        fieldType,
        fieldDisplayName: label,
      });
    },
    [fieldName, fieldType, label, onFilterChange, operatorSelected],
  );

  const handleOnPaste = useCallback((event: ClipboardEvent<HTMLDivElement>) => {
    const value = event.clipboardData.getData('Text');

    if (!getIsValueValidPositiveNumber(value)) {
      event.preventDefault();
    }
  }, []);

  const handleOnKeyPress = useCallback((event: KeyboardEvent<HTMLDivElement>) => {
    const { key } = event;
    if (!getIsValueValidPositiveNumber(key)) {
      event.preventDefault();
    }
  }, []);

  const handleOnValueChangeDebounced = useMemo(() => debounce(handleOnValueChange, 500), [handleOnValueChange]);

  useEffect(() => {
    if (!isFiltersFetching && focused) {
      setTimeout(() => {
        inputRef.current?.focus();
      }, 500);
    }
  }, [focused, isFiltersFetching]);

  return (
    <div className={classes.root}>
      <div className={classes.control}>
        <BigidHeading6 data-aid={`${dataAid}-label`} className={classes.label}>
          {label}
        </BigidHeading6>
        <BigidDropdown
          options={operatorOptions}
          value={operatorSelected}
          onSelect={handleOnOperatorSelect}
          isDisabled={isFiltersFetching}
        />
      </div>
      <div className={classes.control}>
        <BigidTextField
          autoFocus={focused}
          dataAid={`${dataAid}-field`}
          placeholder={`Enter ${label}`}
          defaultValue={initialValue?.value.toString()}
          onChange={handleOnValueChangeDebounced}
          onPaste={handleOnPaste}
          disabled={isFiltersFetching}
          onKeyPress={handleOnKeyPress}
          inputRef={inputRef}
        />
      </div>
    </div>
  );
};
