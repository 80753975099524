import { noop } from 'lodash';
import { dataSourceConnectionsService, $state, localStorageService } from '../../services/angularServices';
import { openSystemDialog, BigidSystemDialogOptions } from '../../services/systemDialogService';
import { SecondaryButton, PrimaryButton } from '@bigid-ui/components';
import { StartOnboardingModalContent } from './StartOnboardingModalContent';
import { DATA_SOURCES_INIT_VIEWS } from '../../views/DataSources/DataSources';
import { DATA_SOURCES_PERMISSIONS } from '@bigid/permissions';
import { isPermitted } from '../../services/userPermissionsService';
import { getDsConnectionsQuery } from '../../utilities/dataSourcesUtils';

export enum ONBOARDING_MODAL_STATE {
  NOT_SHOWED = 'NOT_SHOWED',
  SHOWED = 'SHOWED',
  DO_NOT_SHOW_AGAIN = 'DO_NOT_SHOW_AGAIN',
}

const OnBoardingDialogOptions: BigidSystemDialogOptions = {
  title: '',
  onClose: noop,
  content: StartOnboardingModalContent,
  maxWidth: 'xs',
  buttons: [
    {
      text: `Don't Show Again`,
      component: SecondaryButton,
      onClick: () => {
        localStorageService.set('onboardingModalShowed', ONBOARDING_MODAL_STATE.DO_NOT_SHOW_AGAIN);
      },
      isClose: true,
    },
    {
      text: 'Continue',
      component: PrimaryButton,
      onClick: () => {
        $state.go('dataSourcesInit', { path: DATA_SOURCES_INIT_VIEWS.init.path });
      },
    },
  ],
};

const openOnBoardingModalAndSetStorage = () => {
  openSystemDialog(OnBoardingDialogOptions);
  localStorageService.set('onboardingModalShowed', ONBOARDING_MODAL_STATE.SHOWED);
};

interface PageWhereModalCall {
  name: string;
}

export const openOnBoardingModal = (forcedOrPageWhereModalCall?: boolean | PageWhereModalCall) => {
  const isAlreadyOnOnboardingPage =
    typeof forcedOrPageWhereModalCall !== 'boolean' && forcedOrPageWhereModalCall?.name === 'dataSourcesInit';
  const canBeOpened =
    (localStorageService.get('onboardingModalShowed') === ONBOARDING_MODAL_STATE.NOT_SHOWED ||
      forcedOrPageWhereModalCall === true) &&
    !isAlreadyOnOnboardingPage &&
    isPermitted(DATA_SOURCES_PERMISSIONS.CREATE.name);

  if (canBeOpened) {
    const query = getDsConnectionsQuery({ maxDS: 1, requireTotalCount: true, onlyCSSupported: false });
    dataSourceConnectionsService.getDSConnectionDataByQuery(query).then(({ data }: any) => {
      if (data?.totalCount < 1) {
        openOnBoardingModalAndSetStorage();
      }
    });
  }
};
