import { DataSourceConfigurationContextInterface } from '../DataSourceConfigurationContext';
import { httpService } from '../../../../services/httpService';
import { notificationService } from '../../../../services/notificationService';
import { openGenericCloudOauthWindow } from '../../../../../administration/oauthWindow/genericCloudOauthWindow';
import { OauthActionType, isReadyForOauthAction } from './oauthActions';
import {
  closeSystemDialog,
  openSystemDialog,
  SystemDialogContentProps,
} from '../../../../services/systemDialogService';
import { GenericCloudDialogProps } from '../../../GenericCloudConnectorLoginPage/genericCloudConnectorTypes';
import { GenericCloudConnectorLoginDialog } from '../../../GenericCloudConnectorLoginPage/genericCloudConnectorLoginDialog';

export const connectGenericCloudOauth: OauthActionType = async (
  oauthConnectionType: string,
  stateAndHandlers: DataSourceConfigurationContextInterface,
) => {
  let { id } = stateAndHandlers;

  if (!(await isReadyForOauthAction(oauthConnectionType, stateAndHandlers))) {
    return;
  }

  const { getValuesContainer, createSetValueFunction, updateState } = stateAndHandlers;
  const values = getValuesContainer.current();
  let ds_connection: any;
  let access_token: string;

  async function genericCloudAuthCallback(configData: { authId: any; ds_connection: any }) {
    console.log('in generic cloud connector oauth callback');
    updateState({ isLoading: true });
    const values = getValuesContainer.current();

    if (!configData.authId) {
      ds_connection.authenticationProperties.isOauthConfigured = 'false';
    } else {
      ds_connection.auth_id = configData.authId;
      ds_connection.authenticationProperties.isOauthConfigured = 'true';
    }

    id = values.name;
    console.debug('going to update db with oauth response with auth id: ' + configData.authId);

    delete ds_connection._id;

    const datasourceInstance = {
      connectorId: ds_connection.connectionProperties.connectionData.connectorsDefinitionId,
      authenticationId: configData.authId,
      access_token,
    };

    updateState({ isSaveInProgress: true });
    const data = {
      datasourceInstance,
      ds_connection,
    };
    httpService
      .post('oauth/generic-cloud/callback/', data)
      .then(httpResponse => {
        notificationService.success(
          'OAuth configuration for custom cloud connector ' + values.name + ' set successfully!',
        );
        ds_connection.authenticationProperties.isOauthConfigured = 'true';
      })
      .catch(error => {
        console.log('failed to create custom cloud connector instance, error: ' + error);
        ds_connection.authenticationProperties.isOauthConfigured = 'false';
        notificationService.error('OAuth configuration for custom cloud connector ' + values.name + ' has failed');
      })
      .finally(() => {
        updateState({ isSaveInProgress: false });
        updateState({ isLoading: false });
        window.location.reload();
      });
  }

  async function handleDialogAuthIdResponse(authId: string) {
    closeSystemDialog();
    await genericCloudAuthCallback({ authId: authId, ds_connection: null });
  }

  async function handleDialogError() {
    closeSystemDialog();
    authError();
  }

  function authError() {
    console.log('in custom cloud connector oauth error callback');
    ds_connection.authenticationProperties.isOauthConfigured = 'false';
    notificationService.error('OAuth configuration for custom cloud connector ' + values.name + ' did not complete');
  }

  //case of insert, or oauth not set
  if (!id || !values.isOauthConfigured) {
    const response = await httpService.fetch(`ds_connections/` + values.name);
    ds_connection = response.data.ds_connection;
    const genericCloudOauthParams = new GenericCloudOauthParams(
      ds_connection.connectionProperties.connectionData.connectorsDefinitionId,
      ds_connection.authenticationProperties.clientId,
      ds_connection.authenticationProperties.client_secret_enc,
      ds_connection.authenticationProperties.url,
    );
    try {
      const genericCloudOauthResponse = await httpService.post('oauth/generic-cloud/', genericCloudOauthParams);
      access_token = genericCloudOauthResponse.data.access_token;
      if (genericCloudOauthResponse.data.authenticationType === 'oauth') {
        openGenericCloudOauthWindow(genericCloudOauthResponse.data.url, genericCloudAuthCallback, authError);
      }
      if (genericCloudOauthResponse.data.authenticationType === 'static') {
        const contentProps: GenericCloudDialogProps = {
          connectorId: ds_connection.connectionProperties.connectionData.connectorsDefinitionId,
          onSuccessfulAuthId: handleDialogAuthIdResponse,
          onError: handleDialogError,
        };

        openSystemDialog({
          title: 'Login',
          onClose: () => {
            return;
          },
          contentProps: contentProps,
          content: GenericCloudConnectorLoginDialog,
        });
      }
    } catch (error) {
      console.log('failed to complete cloud connector oauth flow, error: ' + error);
      ds_connection.authenticationProperties.isOauthConfigured = 'false';
      notificationService.error('OAuth configuration for custom cloud connector ' + values.name + ' did not complete');
    }
  }

  return true;
};

export class GenericCloudOauthParams {
  constructor(
    readonly connectorsDefinitionId: string,
    readonly clientId: string,
    readonly clientSecret: string,
    readonly oauthURL: string,
  ) {}
}

export class OAuth0Payload {
  constructor(
    readonly client_id: string,
    readonly client_secret: string,
    readonly audience: string,
    readonly grant_type: string,
    readonly tenant_id: string,
  ) {}
}
