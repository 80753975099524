import './explorationBarChart.component.scss';
import { convertToReact } from '../../common/services/convertToReact';
import * as template from './explorationBarChart.component.html';
import { notificationService } from '../../react/services/notificationService';

const componentDefinitionObject = {
  template,
  bindings: {
    type: '<',
    header: '<',
    totalIdentities: '<',
    totalIdentitiesAtRisk: '<',
    totalSystems: '<',
    querystring: '<',
    minimumHeight: '<',
    clickable: '<',
    showSubHeader: '<',
    totalSystemsUnmanaged: '<',
    totalApplications: '<',
    totalApplicationsAtRisk: '<',
    // if this callback provided, it will be used instead $rootScope pub-sub mechanism
    onCallToFilterSearchHeader: '<',
    // if this Set() provided, it will be used to mark active query objects as active
    externalActiveObjectsSet: '<',
    useExperimentalQuery: '<',
  },
  controllerAs: 'explorationBarChartModel',
  controller: function ($rootScope, $scope, $window, explorationBarChartService, localStorageService) {
    'ngInject';

    const QUERY_FILTER_SYNC_EVENT_INITIATOR = 'chart';

    const applicationType = 'Applications';
    const systemType = 'Systems';
    const attributeType = 'Attributes';
    const identityType = 'Identities';

    const backgroundColors = [
      '#66BB6A',
      '#FFA726',
      '#EF5350',
      '#42A5F5',
      '#EC407A',
      '#AB47BC',
      '#26A69A',
      '#29B6F6',
      '#7E57C2',
      '#FF7043',
    ];
    const backgroundColorsIdentites = [
      '#26A69A',
      '#EC407A',
      '#AB47BC',
      '#FFA726',
      '#EF5350',
      '#66BB6A',
      '#29B6F6',
      '#7E57C2',
      '#FF7043',
      '#42A5F5',
    ];
    const backgroundColorsAttributes = [
      '#29B6F6',
      '#7E57C2',
      '#EF5350',
      '#FFA726',
      '#FF7043',
      '#66BB6A',
      '#26A69A',
      '#EC407A',
      '#AB47BC',
      '#42A5F5',
    ];
    const OthersName = 'Others';
    const maxIndex = 9;
    const maxLength = 10;
    const { format: numberFormat } = new Intl.NumberFormat();

    const explorationBarChartModel = this;
    explorationBarChartModel.initialDataLoaded = false;
    explorationBarChartModel.showSubHeader = true;

    explorationBarChartModel.showLegend = false;
    explorationBarChartModel.identityTypeDisplayName = 'Entities';

    explorationBarChartModel.getTooltipHtml = model => {
      return `<span>
      <div>${model.type !== 'Identities' ? model.type : explorationBarChartModel.identityTypeDisplayName} :
      ${model.tooltipName}</div>
      ${model.risk ? `<div>Risk: ${model.risk}</div>` : ''}
      PII Count: ${numberFormat(model.piiCount)}
      ${model.identifiability ? `<div> Identifiability: ${model.identifiability}%</div>` : ''}
    </span>`;
    };

    explorationBarChartModel.$onChanges = function (changes) {
      const type = explorationBarChartModel.type;
      explorationBarChartModel.typeName = type;
      explorationBarChartModel.totalIdentities = explorationBarChartModel.totalIdentities;
      explorationBarChartModel.totalIdentitiesAtRisk = explorationBarChartModel.totalIdentitiesAtRisk;
      explorationBarChartModel.totalSystems = explorationBarChartModel.totalSystems;
      explorationBarChartModel.totalSystemsUnmanaged = explorationBarChartModel.totalSystemsUnmanaged;
      explorationBarChartModel.totalApplications = explorationBarChartModel.totalApplications;
      explorationBarChartModel.totalApplicationsAtRisk = explorationBarChartModel.totalApplicationsAtRisk;
      explorationBarChartModel.clickable = explorationBarChartModel.clickable;
      explorationBarChartModel.showSubHeader = explorationBarChartModel.showSubHeader === false ? false : true;
      explorationBarChartModel.minimumHeight = explorationBarChartModel.minimumHeight;

      const isFirstChange = typeof changes.querystring != 'undefined' ? changes.querystring.isFirstChange() : false;

      setExplorationBarChartData(type, explorationBarChartModel.querystring, isFirstChange);

      explorationBarChartModel.showLegend = explorationBarChartModel.type === attributeType;
    };

    explorationBarChartModel.sum = function (items, prop) {
      return items.reduce(function (a, b) {
        return a + b[prop];
      }, 0);
    };

    explorationBarChartModel.clearFilter = function () {
      explorationBarChartModel.showClearFilter = false;
      removeSessionStorageVariablesByObjectType(explorationBarChartModel.type);
      setExploarationObjNotActive(explorationBarChartModel.identitiesObject.objectsData);
    };

    explorationBarChartModel.isObjectActive = model => {
      if (
        !explorationBarChartModel.externalActiveObjectsSet ||
        explorationBarChartModel.clickable === false ||
        (model.filter_name === OthersName && model.tooltipName === OthersName)
      ) {
        return false;
      }
      return explorationBarChartModel.externalActiveObjectsSet.has(model.filter_name);
    };

    explorationBarChartModel.filterNgRepeat = model => {
      return explorationBarChartModel.externalActiveObjectsSet && explorationBarChartModel.externalActiveObjectsSet.size
        ? explorationBarChartModel.externalActiveObjectsSet.has(model.filter_name)
        : true;
    };

    explorationBarChartModel.setActive = function (model) {
      if (explorationBarChartModel.clickable === false) return;
      if (model.filter_name !== OthersName && model.tooltipName !== OthersName) {
        const exploarationObj = explorationBarChartModel.identitiesObject.objectsData;
        let selectedFilteredCountries = explorationBarChartModel.identitiesObject.selectedFilteredObjects;
        const removedFilteredCountries = explorationBarChartModel.identitiesObject.removedFilteredObjects;

        for (let i = 0; i < exploarationObj.length; i++) {
          if (exploarationObj[i].tooltipName === model.tooltipName) {
            exploarationObj[i].active = !model.active;
            //if the user check for filter
            if (model.active == true) {
              model.marginTop = '6px';
              // model.lineHeight = "44px";
              model.transition = 'height .3s linear';
              model.backgroundColor = exploarationObj[i].backgroundColor;

              setBackgorundColorByObjectType(explorationBarChartModel.type, exploarationObj[i].backgroundColor);

              selectedFilteredCountries = [];
              selectedFilteredCountries.push(
                explorationBarChartModel.useExperimentalQuery ? `"${model.filter_name}"` : model.filter_name,
              );

              if (explorationBarChartModel.onCallToFilterSearchHeader) {
                explorationBarChartModel.onCallToFilterSearchHeader(
                  explorationBarChartModel.type,
                  selectedFilteredCountries,
                  [],
                );
              } else {
                $rootScope.$broadcast(
                  'callToFilterSearchHeader',
                  explorationBarChartModel.type,
                  selectedFilteredCountries,
                  [],
                  QUERY_FILTER_SYNC_EVENT_INITIATOR,
                );
              }
            }
            //if the user remove the filter
            else {
              setExploarationObjNotActive(model, removedFilteredCountries);
            }
          } else {
            if (exploarationObj[i].active) {
              exploarationObj[i].active = false;
              setExploarationObjNotActive(exploarationObj, removedFilteredCountries, i);
            }
          }
        }
      }
    };

    function setExploarationObjNotActive(objectsData) {
      const removedFilteredCountries = [];

      switch (Object.prototype.toString.call(objectsData)) {
        case '[object Array]':
          for (let i = 0; i < objectsData.length; i++) {
            objectsData[i].marginTop = '30px';
            objectsData[i].lineHeight = 'normal';
            objectsData[i].transition = 'linear .3s linear';
            objectsData[i].active = false;
            removedFilteredCountries[i] = explorationBarChartModel.useExperimentalQuery
              ? `"${objectsData[i].filter_name}"`
              : objectsData[i].filter_name;
          }
          break;
        case '[object Object]':
          objectsData.marginTop = '30px';
          objectsData.lineHeight = 'normal';
          objectsData.transition = 'linear .3s linear';
          objectsData.active = false;
          removedFilteredCountries[0] = explorationBarChartModel.useExperimentalQuery
            ? `"${objectsData.filter_name}"`
            : objectsData.filter_name;
          break;
      }

      if (explorationBarChartModel.onCallToFilterSearchHeader) {
        explorationBarChartModel.onCallToFilterSearchHeader(
          explorationBarChartModel.type,
          [],
          removedFilteredCountries,
        );
      } else {
        $rootScope.$broadcast(
          'callToFilterSearchHeader',
          explorationBarChartModel.type,
          [],
          removedFilteredCountries,
          QUERY_FILTER_SYNC_EVENT_INITIATOR,
        );
      }
    }

    function checkSelected(type, filterValue) {
      return filterValue.indexOf(type) != -1 ? true : false;
    }

    function headerQueryFilter(filterValue) {
      switch (explorationBarChartModel.type) {
        case 'Identities': {
          getResidencyData(filterValue);
          if (!checkSelected('country', filterValue)) {
            explorationBarChartModel.showClearFilter = false;
          }
          break;
        }
        case 'Systems': {
          getSystemsData(filterValue);
          if (!checkSelected('system', filterValue)) {
            explorationBarChartModel.showClearFilter = false;
          }
          break;
        }
        case 'Applications': {
          getApplicationsData(filterValue);
          getSubHeaderData(explorationBarChartModel.type, filterValue);
          if (!checkSelected('application', filterValue)) {
            explorationBarChartModel.showClearFilter = false;
          }
          break;
        }
        case 'Attributes': {
          getAttributesData(filterValue);
          if (!checkSelected('field', filterValue)) {
            explorationBarChartModel.showClearFilter = false;
          }
          break;
        }
      }
    }

    function getResidencyData(queryString, isInitialCall = false) {
      explorationBarChartService
        .getResidencyData(queryString)
        .then(function ({ identity_locations }) {
          const identities = identity_locations ?? [];
          const identitiesObject = GetDataObject(identities, false, identityType);
          explorationBarChartModel.identitiesObject = identitiesObject;
          $rootScope.$broadcast('identitiesLength', identitiesObject.objectsData.length);
          if (isInitialCall) {
            $scope.$emit('populate-sidebar-filter', {
              identities: {
                title: 'Top Residencies',
                data: GetDataObjectForSideFilter(identities, false, identityType, identitiesObject).objectsData,
              },
            });
          }
        })
        .catch(({ status }) => {
          if (status === 422) {
            const errMessage = `Invalid filter query. Please, use the following format: ${
              isNewQueryFilterEnabled ? 'key = "value"' : 'key = value'
            }`;
            notificationService.error(errMessage);
          }
        });
    }

    function getSystemsData(queryString, isInitialCall = false) {
      explorationBarChartService
        .getSourceRisks(queryString)
        .then(function ({ source_risks }) {
          const sources = source_risks ?? [];
          const identitiesObject = GetDataObject(sources, false, systemType);
          explorationBarChartModel.identitiesObject = identitiesObject;
          $rootScope.$broadcast('systemLength', identitiesObject.objectsData.length);
          if (isInitialCall) {
            $scope.$emit('populate-sidebar-filter', {
              systems: {
                title: 'Top Data Sources',
                data: GetDataObjectForSideFilter(sources, false, systemType, identitiesObject).objectsData,
              },
            });
          }
        })
        .catch(({ status }) => {
          if (status === 422) {
            const errMessage = `Invalid filter query. Please, use the following format: ${
              isNewQueryFilterEnabled ? 'key = "value"' : 'key = value'
            }`;
            notificationService.error(errMessage);
          }
        });
    }

    function getApplicationsData(queryString, isInitialCall = false) {
      explorationBarChartService
        .getApplicationRisks(queryString)
        .then(function ({ application_risks }) {
          const applications = application_risks ?? [];
          const identitiesObject = GetDataObject(applications, false, applicationType);
          explorationBarChartModel.identitiesObject = identitiesObject;
          $rootScope.$broadcast('applicationLength', identitiesObject.objectsData.length);
          if (isInitialCall) {
            $scope.$emit('populate-sidebar-filter', {
              applications: {
                title: 'Top Assets',
                data: GetDataObjectForSideFilter(applications, false, applicationType, identitiesObject).objectsData,
              },
            });
          }
        })
        .catch(({ status }) => {
          if (status === 422) {
            const errMessage = `Invalid filter query. Please, use the following format: ${
              isNewQueryFilterEnabled ? 'key = "value"' : 'key = value'
            }`;
            notificationService.error(errMessage);
          }
        });
    }

    function getAttributesData(queryString, isInitialCall = false) {
      explorationBarChartService
        .getAttributesRisks(queryString)
        .then(function ({ attribute_risks }) {
          const attributes = attribute_risks ?? [];
          const identitiesObject = GetDataObject(attributes, true, attributeType);

          const ENRICHMENT_IDENTIFIER_REGEX = /enrich_field\./;
          const CLASSIFIER_IDENTIFIER_REGEX = /classifier\./;

          const { correlationsCount, classificationsCount, enrichmentCount } = attributes.reduce(
            (aggregator, attribute) => {
              const { id } = attribute;

              if (id.match(ENRICHMENT_IDENTIFIER_REGEX)) {
                aggregator.enrichmentCount++;
              } else if (id.match(CLASSIFIER_IDENTIFIER_REGEX)) {
                aggregator.classificationsCount++;
              } else {
                aggregator.correlationsCount++;
              }

              return aggregator;
            },
            { correlationsCount: 0, classificationsCount: 0, enrichmentCount: 0 },
          );

          explorationBarChartModel.identitiesObject = identitiesObject;
          explorationBarChartModel.subHeaderData = `
          Total Attributes: ${attributes.length}\u00A0\u00A0
          Correlations: ${correlationsCount}\u00A0\u00A0
          Classifications: ${classificationsCount}\u00A0\u00A0
          Enrichments: ${enrichmentCount}
        `;

          $rootScope.$broadcast('attributeLength', identitiesObject.objectsData.length);

          if (isInitialCall) {
            $scope.$emit('populate-sidebar-filter', {
              attributes: {
                title: 'Top Attributes',
                data: GetDataObjectForSideFilter(attributes, true, attributeType, identitiesObject).objectsData,
              },
            });
          }
        })
        .catch(({ status }) => {
          if (status === 422) {
            const errMessage = `Invalid filter query. Please, use the following format: ${
              isNewQueryFilterEnabled ? 'key = "value"' : 'key = value'
            }`;
            notificationService.error(errMessage);
          }
        });
    }

    function GetDataObject(resultData, isToUseID, typeName) {
      const objectBarChartData = initializeIdentityObject();
      let resultDataLocal = JSON.parse(JSON.stringify(resultData));
      const searchTextBoxValue = localStorageService.get('filterValue');
      objectBarChartData.summaryCount = explorationBarChartModel.sum(resultDataLocal, 'count');
      objectBarChartData.othersCount = 0;

      resultDataLocal.sort(compareCount);

      if (resultDataLocal.length >= 1) {
        let useMinWidth = true;
        if (resultDataLocal.length > 100) {
          useMinWidth = false;
        }
        const length = resultDataLocal.length > maxLength ? maxLength : resultDataLocal.length;

        if (length === maxLength) {
          const othersArray = resultDataLocal.slice(maxLength); //take the items from 10 to the end
          objectBarChartData.othersCount = explorationBarChartModel.sum(othersArray, 'count');
          if (!resultDataLocal.find(item => item.name === 'Others' && item.count === objectBarChartData.othersCount)) {
            resultDataLocal[maxIndex] = {};
            resultDataLocal[maxIndex].name = OthersName;
            resultDataLocal[maxIndex].short_name = OthersName;
            resultDataLocal[maxIndex].id = OthersName;
            resultDataLocal[maxIndex].count = objectBarChartData.othersCount;
            resultDataLocal = resultDataLocal.slice(0, maxLength);
            resultDataLocal.sort(compareCount);
          }
        }

        for (let i = 0; i < length; i++) {
          let width = (resultDataLocal[i].count / objectBarChartData.summaryCount) * 100;

          if (useMinWidth) {
            if (width < 3) {
              width = 1;
            }
          }

          const index = i % 10;
          const name = isToUseID ? resultDataLocal[i].short_name : resultDataLocal[i].name;
          const filter_name = isToUseID ? resultDataLocal[i].id : resultDataLocal[i].name;
          const identifiability = isToUseID ? resultDataLocal[i].id_score : undefined;
          let systemName = '';
          if (resultDataLocal[i].system) {
            systemName = resultDataLocal[i].system;
          }
          let objectData = {
            name: width > 3 ? name : '',
            tooltipName: name,
            filter_name: filter_name,
            width: width < 1 ? parseFloat('1') : parseFloat(width + ''),
            piiCount: parseFloat(resultDataLocal[i].count),
            risk: Math.round(resultDataLocal[i].avg),
            borderRadius: '',
            cursor: name === 'Others' ? 'default' : 'pointer',
            active: false,
            marginTop: '30px',
            lineHeight: 'normal',
            system: systemName,
            transition: '',
            identifiability: identifiability,
            type: typeName,
          };
          objectData = setBackgroundColor(objectData, index);

          if (i == 0 && resultDataLocal.length == 1) {
            objectData = setActiveFilter(searchTextBoxValue, objectData);
          }

          objectBarChartData.objectsData.push(objectData);
        }
      } else {
        explorationBarChartModel.showClearFilter = false;
      }
      if (objectBarChartData.objectsData.length >= 1) {
        setResultExplortionBarByType(typeName, objectBarChartData);
      }
      return objectBarChartData;
    }

    function GetDataObjectForSideFilter(resultData, isToUseID, typeName, identitiesObject) {
      const objectBarChartData = initializeIdentityObject();
      const resultDataLocal = JSON.parse(JSON.stringify(resultData));
      objectBarChartData.summaryCount = explorationBarChartModel.sum(resultDataLocal, 'count');
      resultDataLocal.sort(compareCount);

      for (let i = 0; i < resultDataLocal.length; i++) {
        const name = isToUseID ? resultDataLocal[i].short_name : resultDataLocal[i].name;
        const filter_name = isToUseID ? resultDataLocal[i].id : resultDataLocal[i].name;
        let systemName = '';
        if (resultDataLocal[i].system) {
          systemName = resultDataLocal[i].system;
        }
        const isObjectDataExists = identitiesObject.objectsData.find(objectI => objectI.filter_name === filter_name);
        const backgroundColor =
          identitiesObject.objectsData && (isObjectDataExists ? isObjectDataExists.backgroundColor : '');

        const objectData = {
          name: name,
          risk: Math.round(resultDataLocal[i].avg),
          filter_name: filter_name,
          system: systemName,
          type: typeName,
          backgroundColor: backgroundColor,
        };
        objectBarChartData.objectsData.push(objectData);
      }

      return objectBarChartData;
    }

    function compareCount(a, b) {
      if (a.count > b.count) return -1;
      if (a.count < b.count) return 1;
      return 0;
    }

    function setBackgroundColor(objectData, index) {
      switch (explorationBarChartModel.type) {
        case 'Identities':
          objectData.backgroundColor = backgroundColorsIdentites[index];
          break;
        case 'Attributes':
          objectData.backgroundColor = backgroundColorsAttributes[index];
          break;
        case 'Systems':
          objectData.backgroundColor = backgroundColors[index];
          break;
        case 'Applications':
          objectData.backgroundColor = backgroundColors[index];
          break;
      }

      return objectData;
    }

    function initializeIdentityObject() {
      const identitiesObject = {};
      identitiesObject.summaryCount = 0;
      identitiesObject.objectsData = [];
      identitiesObject.selectedFilteredObjects = [];
      identitiesObject.removedFilteredObjects = [];

      return identitiesObject;
    }

    function setExplorationBarChartData(type, queryString = '', isFirstChange = false) {
      switch (type) {
        // /identityLocations
        case identityType:
          getResidencyData(queryString, isFirstChange);
          getSubHeaderData(type);
          break;
        // /attributeRisks ?filter=field=ssn
        case attributeType:
          getAttributesData(queryString, isFirstChange);
          getSubHeaderData(type);
          break;
        // /sourceRisks ?filter=system=SQL1
        case systemType:
          getSystemsData(queryString, isFirstChange);
          getSubHeaderData(type);
          break;
        // /applicationRisks ?filter=application=N/A
        case applicationType:
          getApplicationsData(queryString, isFirstChange);
          getSubHeaderData(type);
          break;
      }
    }

    function getSubHeaderData(type) {
      switch (type) {
        case identityType:
          explorationBarChartModel.subHeaderData = `Total ${explorationBarChartModel.identityTypeDisplayName}: ${explorationBarChartModel.totalIdentities}`;
          break;
        case attributeType:
          const totalAttributes = 0;
          explorationBarChartModel.subHeaderData = `Total ${attributeType}: ${totalAttributes}`;
          break;
        case systemType:
          explorationBarChartModel.subHeaderData = `Total Data Sources: ${explorationBarChartModel.totalSystems} With PI Records: ${explorationBarChartModel.totalSystemsUnmanaged}`;
          break;
        case applicationType:
          explorationBarChartModel.subHeaderData = `Total Assets: ${explorationBarChartModel.totalApplications} At Risk: ${explorationBarChartModel.totalApplicationsAtRisk}`;
          break;
      }
    }

    function setActiveFilter(searchTextBoxValue, objectData) {
      if (searchTextBoxValue) {
        switch (explorationBarChartModel.type) {
          case 'Identities': {
            if (searchTextBoxValue.includes('country')) {
              setObjectDataAsActive(objectData, identityType);
            }
            break;
          }
          case 'Systems': {
            if (searchTextBoxValue.includes('system')) {
              setObjectDataAsActive(objectData, systemType);
            }
            break;
          }
          case 'Applications': {
            if (searchTextBoxValue.includes('application')) {
              setObjectDataAsActive(objectData, applicationType);
            }
            break;
          }
          case 'Attributes': {
            if (searchTextBoxValue.includes('field')) {
              setObjectDataAsActive(objectData, attributeType);
            }
            break;
          }
        }
      }
      return objectData;
    }

    function setObjectDataAsActive(objectData, objectType) {
      objectData.active = true;
      objectData.marginTop = '6px';
      objectData.lineHeight = '44px';
      objectData.transition = 'height .3s linear';

      const result = getResultExplortionBarByType(objectType);
      const isObjectDataExists = result.objectsData.find(objectI => objectI.filter_name === objectData.filter_name);

      const backgroundColor =
        result.objectsData && (isObjectDataExists ? isObjectDataExists.backgroundColor : '#E91E63');
      if (backgroundColor) {
        objectData.backgroundColor = backgroundColor;
      }
      explorationBarChartModel.showClearFilter = true;
    }

    function getBackgorundColorByObjectType(objectType) {
      let backgroundColor = null;

      switch (objectType) {
        case 'Identities': {
          backgroundColor = $window.sessionStorage.getItem('selectIdentitiesBackgroundColor');
          break;
        }
        case 'Systems': {
          backgroundColor = $window.sessionStorage.getItem('selectSystemBackgroundColor');
          break;
        }
        case 'Applications': {
          backgroundColor = $window.sessionStorage.getItem('selectApplicationsBackgroundColor');
          break;
        }
        case 'Attributes': {
          backgroundColor = $window.sessionStorage.getItem('selectAttributesBackgroundColor');
          break;
        }
      }

      return backgroundColor;
    }

    function setBackgorundColorByObjectType(objectType, backgroundColor) {
      switch (objectType) {
        case 'Identities': {
          $window.sessionStorage.setItem('selectIdentitiesBackgroundColor', backgroundColor);
          break;
        }
        case 'Systems': {
          $window.sessionStorage.setItem('selectSystemBackgroundColor', backgroundColor);
          break;
        }
        case 'Applications': {
          $window.sessionStorage.setItem('selectApplicationsBackgroundColor', backgroundColor);
          break;
        }
        case 'Attributes': {
          $window.sessionStorage.setItem('selectAttributesBackgroundColor', backgroundColor);
          break;
        }
      }
    }

    function setResultExplortionBarByType(objectType, result) {
      switch (objectType) {
        case 'Identities': {
          $window.sessionStorage.setItem('selectIdentitiesExplorationBarObject', JSON.stringify(result));
          break;
        }
        case 'Systems': {
          $window.sessionStorage.setItem('selectSystemExplorationBarObject', JSON.stringify(result));
          break;
        }
        case 'Applications': {
          $window.sessionStorage.setItem('selectApplicationsExplorationBarObject', JSON.stringify(result));
          break;
        }
        case 'Attributes': {
          $window.sessionStorage.setItem('selectAttributesExplorationBarObject', JSON.stringify(result));
          break;
        }
      }
    }

    function getResultExplortionBarByType(objectType) {
      switch (objectType) {
        case 'Identities': {
          return JSON.parse($window.sessionStorage.getItem('selectIdentitiesExplorationBarObject'));
        }
        case 'Systems': {
          return JSON.parse($window.sessionStorage.getItem('selectSystemExplorationBarObject'));
        }
        case 'Applications': {
          return JSON.parse($window.sessionStorage.getItem('selectApplicationsExplorationBarObject'));
        }
        case 'Attributes': {
          return JSON.parse($window.sessionStorage.getItem('selectAttributesExplorationBarObject'));
        }
      }
    }

    function removeSessionStorageVariablesByObjectType(objectType) {
      switch (objectType) {
        case 'Identities': {
          $window.sessionStorage.removeItem('selectIdentitiesBackgroundColor');
          break;
        }
        case 'Systems': {
          $window.sessionStorage.removeItem('selectSystemBackgroundColor');
          break;
        }
        case 'Applications': {
          $window.sessionStorage.removeItem('selectApplicationsBackgroundColor');
          break;
        }
        case 'Attributes': {
          $window.sessionStorage.removeItem('selectAttributesBackgroundColor');
          break;
        }
      }
    }

    const listenerCleanFilter = $rootScope.$on('cleanFilter', function (event) {
      explorationBarChartModel.showClearFilter = false;
      setExplorationBarChartData(explorationBarChartModel.type);
    });

    const listenerCallToHeaderQueryFilter = $rootScope.$on(
      'callToHeaderQueryFilter',
      function (event, filterValue, listeners) {
        if (listeners && listeners[QUERY_FILTER_SYNC_EVENT_INITIATOR]) {
          if (!filterValue) {
            explorationBarChartModel.showClearFilter = false;
          }
          headerQueryFilter(filterValue);
        }
      },
    );

    // Unregister listeners
    $scope.$on('$destroy', function () {
      listenerCallToHeaderQueryFilter();
      listenerCleanFilter();
    });
  },
};

export const ExplorationBarChart = convertToReact('explorationBarChart', componentDefinitionObject);
