import { CONFIG } from '../../../config/common';
import { userPreferencesService } from '../../services/userPreferencesService';
import { DataCatalogPagePreferences, RecentlyViewedCatalogItem } from './DataCatalogService';

const LIMIT = 10;

export const setRecentlyViewedCatalogItem = async (catalogDetails: RecentlyViewedCatalogItem) => {
  try {
    const preferences = await userPreferencesService.get<DataCatalogPagePreferences>(CONFIG.states.CATALOG);
    if (preferences) {
      // 1. Create a new array of recently viewed items, with the new item at the top
      // 2. Remove the new item from the array if it already exists
      // 3. Take only the first 10 items
      const baseItems = preferences.data?.recentlyViewedItems ?? [];
      const firstTenItems = baseItems.slice(0, LIMIT);
      const filteredItems = firstTenItems.filter(item => item.fullyQualifiedName !== catalogDetails.fullyQualifiedName);
      const recentlyViewedCatalogItems = [catalogDetails, ...filteredItems];

      await userPreferencesService.update<DataCatalogPagePreferences>({
        ...preferences,
        data: {
          recentlyViewedItems: recentlyViewedCatalogItems,
        },
      });
      return;
    }
  } catch (error) {
    console.error(`An error has occurred: ${error}`);
  }
};

export const getRecentlyViewedCatalogItems = async () => {
  const response = await userPreferencesService.get<DataCatalogPagePreferences>(CONFIG.states.CATALOG);

  if (!response) {
    throw new Error('Unable to retrieve recently viewed catalog items');
  }

  return response;
};
