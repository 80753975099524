import React from 'react';
import { SecondaryButton } from '@bigid-ui/components';
import { DisclaimerModalContent } from './DisclaimerModalContent';
import { BigidSystemDialogOptions, openSystemDialog } from '../../../services/systemDialogService';
import { setBigchatLocalStorage } from '../../../services/bigchat/bigchatStorageService';
import { getFixedT } from '../translations';
import { DISCLAIMER_MODAL_STATE } from '../BigChatTypes';

export const openBigChatDisclaimerModal = (setShowChat: React.Dispatch<React.SetStateAction<boolean>>) => {
  const disclaimerDialogOptions: BigidSystemDialogOptions = {
    title: '',
    onClose: () => {
      setShowChat(prev => !prev);
    },
    content: DisclaimerModalContent,
    maxWidth: 'xs',
    buttons: [
      {
        text: `${getFixedT('disclaimer.modal')('button')}`,
        component: SecondaryButton,
        onClick: () => {
          setBigchatLocalStorage(DISCLAIMER_MODAL_STATE.DO_NOT_SHOW_AGAIN);
        },
        isClose: true,
      },
    ],
  };
  openSystemDialog(disclaimerDialogOptions);
};
