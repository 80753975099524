import React, { FC } from 'react';
import { useLocalTranslation } from '../translations';
import { CatalogDiscoveryGuidedTourStep } from '../CatalogDiscoveryGuidedTourStep';

interface InsightsProps {
  dataAid: string;
}

export const Insights: FC<InsightsProps> = ({ dataAid }) => {
  const { t } = useLocalTranslation('steps.Insights');

  return <CatalogDiscoveryGuidedTourStep dataAid={dataAid} body={t('body')} />;
};
