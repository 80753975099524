import { BigidBody1, BigidCategoryChip, BigidChip, BigidChipProps, BigidColorsV2 } from '@bigid-ui/components';
import { BigidAnyIcon, BigidAttributeIcon, BigidColumnsIcon, BigidTagIcon } from '@bigid-ui/icons';
import { styled, useTheme } from '@mui/material';
import { partition } from 'lodash';
import React, { FC } from 'react';
import { useDataCatalogSearchResultsContext } from '../../../../contexts/dataCatalogSearchResultsContext';
import { CatalogSearchGridRow, DataComponentProps, HightlightedType } from '../../../../types';
import { getHighlightedValue, removeHtmlTags } from '../../../../utils';
import { TagReason } from './common/TagReason';
import { Description } from './common/Description';
import { ReasonProcessor } from './ReasonProcessor';

const Container = styled('div')`
  gap: 6px;
  display: flex;
  flex-direction: column;
`;

const List = styled('div')`
  flex-grow: 1;
  display: flex;
  gap: 12px;
  flex-wrap: wrap;
  padding: 0 0 0 15px;
  margin: 0;
`;

const ColumnsContainer = styled('div')<{ maxWidth: string }>`
  text-align: center;
  max-width: ${({ maxWidth }) => maxWidth || '100%'};
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;

  strong {
    vertical-align: bottom;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
`;

type ReasonsProps = Pick<CatalogSearchGridRow, 'highlights'>;

const getDataComponent = (
  fieldName: HightlightedType,
  searchTerm: string,
  maxWidth: string,
): FC<DataComponentProps> => {
  switch (fieldName) {
    case 'tags': {
      return ({ elementToShow }) => <TagReason maxWidth={maxWidth} elementToProcess={elementToShow} />;
    }

    case 'attributes': {
      return ({ elementToShow }) => {
        const sanitizedValue = removeHtmlTags(elementToShow);

        return (
          <BigidCategoryChip
            categoryColor=""
            maxWidth={maxWidth}
            highlight={searchTerm}
            categoryName={sanitizedValue}
            tooltipProps={{ title: '', isDisabled: true }}
          />
        );
      };
    }

    case 'category': {
      return ({ elementToShow }) => {
        const sanitizedValue = removeHtmlTags(elementToShow);

        return (
          <BigidCategoryChip
            key={elementToShow}
            maxWidth={maxWidth}
            categoryColor={BigidColorsV2.purple[500]}
            categoryName={sanitizedValue}
            highlight={searchTerm}
            tooltipProps={{ title: '', isDisabled: true }}
          />
        );
      };
    }

    case 'columns': {
      return ({ elementToShow }) => {
        return <ColumnsContainer maxWidth={maxWidth}>{getHighlightedValue(elementToShow)}</ColumnsContainer>;
      };
    }

    default:
      return (): null => null;
  }
};

const getIcon = (fieldName: HightlightedType) => {
  switch (fieldName) {
    case 'tags':
      return <BigidTagIcon size="small" />;

    case 'attributes': {
      return <BigidAttributeIcon size="small" />;
    }

    case 'category': {
      return <BigidAnyIcon size="small" />;
    }

    case 'columns': {
      return <BigidColumnsIcon size="small" />;
    }

    default:
      return null;
  }
};

const getTooltipContent = (fieldName: HightlightedType, highlightedValue: string[]) => {
  switch (fieldName) {
    case 'tags':
      return highlightedValue.map(tag => <TagReason maxWidth="" key={tag} elementToProcess={tag} />);
    case 'attributes':
    case 'category':
    case 'columns': {
      return highlightedValue.map(tag => {
        return <BigidBody1 key={tag}>{getHighlightedValue(tag)}</BigidBody1>;
      });
    }
    default:
      return null;
  }
};

const getShowMoreItemsComponent = (fieldName: HightlightedType): FC<BigidChipProps> => {
  switch (fieldName) {
    case 'tags':
      return ({ label }) => (
        <BigidChip label={label} size="small" outline="solid" tooltipProps={{ title: '', isDisabled: true }} />
      );

    case 'attributes':
    case 'category':
    case 'columns': {
      return ({ label }) => (
        <BigidChip
          label={label}
          size="small"
          outline="solid"
          outlineColor={BigidColorsV2.gray[150]}
          bgColor={BigidColorsV2.white}
          variant="outlined"
          tooltipProps={{ title: '', isDisabled: true }}
        />
      );
    }

    default:
      return (): null => null;
  }
};

export const Reasons: FC<ReasonsProps> = ({ highlights }) => {
  const { query } = useDataCatalogSearchResultsContext();
  const [[description], restReasons] = partition(highlights, ({ fieldName }) => fieldName === 'description');
  const { breakpoints } = useTheme();
  const reasonNameOffset = 70;
  const contentWidth = highlights.length !== 0 ? breakpoints.values.lg / highlights.length - reasonNameOffset : 1;

  const maxWidth = `${contentWidth}px`;

  return (
    <Container>
      <List>
        {restReasons.map(highlight => {
          return (
            <ReasonProcessor
              maxWidth={maxWidth}
              key={highlight.fieldName}
              {...highlight}
              DataComponent={props => {
                return getDataComponent(props.fieldName, query, maxWidth)(props);
              }}
              Icon={({ fieldName }) => getIcon(fieldName)}
              ShowMoreComponent={({ fieldName, excessiveLength }) => {
                const MoreItemsComponent = getShowMoreItemsComponent(fieldName);
                return <MoreItemsComponent label={`+${excessiveLength}`} />;
              }}
              getTooltipContent={({ fieldName, highlightedValue }) => {
                return getTooltipContent(fieldName, highlightedValue);
              }}
            />
          );
        })}
      </List>
      {description && <Description {...description} />}
    </Container>
  );
};
