/**
 * A notification justifying delay will be shown once this threshold is overcome
 */
export const dataFetchingTimeoutThreshold = 5000;

/**
 * A temporal solution until BE returns categories with colors
 */
export const categoriesArbitraryColors = [
  '#8D57F8',
  '#B489FF',
  '#FF72CF',
  '#FF7E7C',
  '#FFBB6C',
  '#FFE76C',
  '#70EFA7',
  '#6CD4E0',
  '#6C9EFF',
  '#2B72FF',
];
