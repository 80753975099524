import { APPLICATIONS_PERMISSIONS, CUSTOM_DASHBOARD_PERMISSIONS } from '@bigid/permissions';
const TPA_PERMISSION_LENGTH = 4;

const getActionDependencies = (allPermissions: string[], action: string) =>
  allPermissions.filter(
    permission => permission.split('.').length === TPA_PERMISSION_LENGTH && permission.includes(action),
  );

const getTpaPermissionDependency = (allPermissions: string[]) => {
  const tpaBaseReadDependencyPermissions = getActionDependencies(
    allPermissions,
    APPLICATIONS_PERMISSIONS.READ_TPA_CUSTOM_APPS.getAction(),
  );

  const tpaBaseManageDependencyPermissions = getActionDependencies(
    allPermissions,
    APPLICATIONS_PERMISSIONS.MANAGE_TPA_CUSTOM_APPS.getAction(),
  );

  const tpaBaseManageDependencyPermissionsExtended = [
    ...tpaBaseReadDependencyPermissions,
    ...tpaBaseManageDependencyPermissions,
    APPLICATIONS_PERMISSIONS.READ_TPA_CUSTOM_APPS.name,
  ];

  const tpaBaseCreateDeleteDependencyPermissions = [
    APPLICATIONS_PERMISSIONS.MANAGE_TPA_CUSTOM_APPS.name,
    ...tpaBaseManageDependencyPermissionsExtended,
  ];

  const individualDependency = tpaBaseManageDependencyPermissions.reduce((acc, permission) => {
    const readPermission = tpaBaseReadDependencyPermissions.find(readPermission => {
      const arr = readPermission.split('.');
      arr.pop();
      return permission.includes(arr.join('.'));
    });

    acc[permission] = [readPermission];
    return acc;
  }, {} as Record<string, string[]>);

  return {
    [APPLICATIONS_PERMISSIONS.READ_TPA_CUSTOM_APPS.name]: tpaBaseReadDependencyPermissions,
    [APPLICATIONS_PERMISSIONS.MANAGE_TPA_CUSTOM_APPS.name]: tpaBaseManageDependencyPermissionsExtended,
    [APPLICATIONS_PERMISSIONS.DELETE_AND_ADD_CUSTOM_APPS.name]: tpaBaseCreateDeleteDependencyPermissions,
    ...individualDependency,
  };
};

const getCustomDashboardDependencyPermissions = (allPermissions: string[]) => {
  const customDashboardReadDependencyPermissions = getActionDependencies(
    allPermissions,
    CUSTOM_DASHBOARD_PERMISSIONS.READ_ALL_CUSTOM_DASHBOARDS.getAction(),
  );

  const customDashboardManageDependencyPermission = getActionDependencies(
    allPermissions,
    CUSTOM_DASHBOARD_PERMISSIONS.MANAGE_ALL_CUSTOM_DASHBOARDS.getAction(),
  );

  const customDashboardManageDependencyPermissionExtended = [
    ...customDashboardManageDependencyPermission,
    ...customDashboardReadDependencyPermissions,
    CUSTOM_DASHBOARD_PERMISSIONS.READ_ALL_CUSTOM_DASHBOARDS.name,
    CUSTOM_DASHBOARD_PERMISSIONS.ACCESS.name,
  ];

  const individualCustomDashboardPermissionsDependency = customDashboardManageDependencyPermission.reduce(
    (acc, permission) => {
      const readPermission = customDashboardReadDependencyPermissions.find(readPermission => {
        const arr = readPermission.split('.');
        arr.pop();
        return permission.includes(arr.join('.'));
      });

      acc[permission] = [readPermission, CUSTOM_DASHBOARD_PERMISSIONS.ACCESS.name];
      return acc;
    },
    {} as Record<string, string[]>,
  );

  return {
    [CUSTOM_DASHBOARD_PERMISSIONS.READ_ALL_CUSTOM_DASHBOARDS.name]: customDashboardReadDependencyPermissions,
    [CUSTOM_DASHBOARD_PERMISSIONS.MANAGE_ALL_CUSTOM_DASHBOARDS.name]: customDashboardManageDependencyPermissionExtended,
    ...individualCustomDashboardPermissionsDependency,
  };
};

export const getCustomPermissionsDependency = (allPermissions: string[]) => {
  return {
    ...getTpaPermissionDependency(allPermissions),
    ...getCustomDashboardDependencyPermissions(allPermissions),
  };
};

export const isTpaReadPermission = (permission: string) => {
  return (
    permission.split('.').length === TPA_PERMISSION_LENGTH &&
    permission.includes(APPLICATIONS_PERMISSIONS.READ_TPA_CUSTOM_APPS.getAction()) &&
    permission.includes(APPLICATIONS_PERMISSIONS.READ_TPA_CUSTOM_APPS.getModule())
  );
};

export const isTpaManagePermission = (permission: string) => {
  return (
    permission.split('.').length === TPA_PERMISSION_LENGTH &&
    permission.includes(APPLICATIONS_PERMISSIONS.MANAGE_TPA_CUSTOM_APPS.getAction()) &&
    permission.includes(APPLICATIONS_PERMISSIONS.MANAGE_TPA_CUSTOM_APPS.getModule())
  );
};

const getParentPermissionOfCustomScenario = (
  childPermission: string,
  customPermissionsDependency: Record<string, string[]>,
) =>
  Object.keys(customPermissionsDependency).filter(parentPermission =>
    customPermissionsDependency[parentPermission]?.includes(childPermission),
  );

export const shouldDisablePermissionOfCustomScenario = (
  permissionName: string,
  customPermissionsDependency: Record<string, string[]>,
  isPermissionSelected: (permission: string) => boolean,
) => {
  const parentPermissionOfCustomScenario = getParentPermissionOfCustomScenario(
    permissionName,
    customPermissionsDependency,
  );
  return parentPermissionOfCustomScenario.some(permission => isPermissionSelected(permission));
};

export const getCustomDependedPermissions = (
  permissionName: string,
  customPermissionsDependency: Record<string, string[]>,
) => customPermissionsDependency[permissionName] || [];
