import React, { FC } from 'react';
import { Typography } from '@mui/material';
import { SystemDialogContentProps } from '../services/systemDialogService';
import { GenericConfirmationDialogOptions } from '../services/genericConfirmationDialog';

export type GenericConfirmationDialogProps = { text?: string } & Pick<GenericConfirmationDialogOptions, 'title'>;

export const ConfirmationDialogContent: FC<SystemDialogContentProps<GenericConfirmationDialogProps>> = ({ text }) => {
  return <Typography variant={'body1'}>{text}</Typography>;
};
