import { BigidLineageDiagramLinkConnector, BigidLineageDiagramSearchResultObject } from '@bigid-ui/visualisation';
import { CorrelationSetModelPartial, SystemAttribute } from '../../Types';
import { sourceToNodeId, attributeNameToPortId } from './idsMapper';

export interface CorrelationSetsSearchResult extends BigidLineageDiagramSearchResultObject {
  correlationSet: CorrelationSetModelPartial;
}

export interface AttributesSearchResult extends BigidLineageDiagramSearchResultObject {
  systemAttribute: SystemAttribute;
}

export function correlationSetsToSearchResults(
  correlationSets: CorrelationSetModelPartial[],
  addedConnections: { to: BigidLineageDiagramLinkConnector }[],
): CorrelationSetsSearchResult[] {
  const usedEs = new Set(addedConnections?.map(({ to: { nodeId } }) => nodeId));
  return correlationSets
    .filter(({ id }) => {
      return !usedEs.has(id);
    })
    .map(correlationSet => {
      const { name } = correlationSet;
      return {
        id: sourceToNodeId(name),
        name,
        displayName: name,
        correlationSet,
      };
    });
}

export function systemAttributesToSearchResults(
  source: string,
  systemAttributes: SystemAttribute[],
): AttributesSearchResult[] {
  return systemAttributes
    .filter(({ attributeOriginalName }) => !attributeOriginalName?.match(/^classifier\./i))
    .map(attr => {
      const { attributeName, attributeOriginalName } = attr;
      const id = attributeNameToPortId(source, attributeOriginalName);
      return {
        id,
        name: attributeOriginalName,
        displayName: attributeName,
        isAbleToConnectManually: true,
        systemAttribute: attr,
      };
    });
}
