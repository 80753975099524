import React from 'react';
import { BigidGridColumn, BigidGridColumnTypes, BigidGridWithToolbarProps } from '@bigid-ui/grid';
import { DataSourceModel } from '../../DataSourceConnectionTypes';
import { DataSourceOnBoardingModel, DsStatuses } from '../types';
import { BigidStatusBadge, BigidStatusBadgeSize } from '@bigid-ui/components';
import { dsStatusToChipProps } from '../mappers/dataSourceStatuses';
import { getFixedT } from '../../translations';
import { getGridFilterOverrides as getInitiativeFilters } from './assistant';
import { getCellValueForConnectionStatus } from '../../../../DSAR/SarProfileSettings/gridCellValues';

export const getDataSourceOnBoardingColumns = (): BigidGridColumn<DataSourceOnBoardingModel>[] => {
  const tColumns = getFixedT('DsGrid.columns');
  const tStatuses = getFixedT('DsGrid.statuses');
  const tLabels = getFixedT('DsGrid.labels');

  return [
    {
      title: tColumns('status'),
      name: 'enrichmentFields.statusDetails',
      sortingEnabled: false,
      width: 180,
      type: BigidGridColumnTypes.CUSTOM,
      getCellValue: ({ enrichmentFields }) => {
        const status = enrichmentFields?.statusDetails?.status;
        return (
          <>
            {status ? (
              <BigidStatusBadge
                label={tStatuses(status)}
                type={dsStatusToChipProps[status]}
                size={BigidStatusBadgeSize.SMALL}
              />
            ) : null}
          </>
        );
      },
    },
    {
      title: tColumns('enabled'),
      name: 'enabled',
      sortingEnabled: true,
      isHiddenByDefault: true,
      width: 150,
      type: BigidGridColumnTypes.STATUS,
      getCellValue: ({ enabled }) => {
        return {
          text: enabled === 'no' ? tLabels('disabled') : tLabels('enabled'),
          status: enabled === 'no' ? 'warning' : undefined,
        };
      },
    },
    {
      title: tColumns('lastDeletion'),
      name: 'connectionStatusDeleteFindings',
      sortingEnabled: true,
      isHiddenByDefault: true,
      width: 150,
      getCellValue: ({ connectionStatusDeleteFindings }) =>
        getCellValueForConnectionStatus(connectionStatusDeleteFindings),
      type: BigidGridColumnTypes.STATUS,
    },
    {
      title: tColumns('archived'),
      name: 'archived',
      sortingEnabled: true,
      isHiddenByDefault: true,
      width: 150,
      type: BigidGridColumnTypes.STATUS,
      getCellValue: ({ archived }) => {
        return {
          text: archived ? tLabels('yes') : tLabels('no'),
          status: archived ? 'warning' : undefined,
        };
      },
    },
    {
      title: tColumns('dataRightsFulfillment'),
      name: 'isDsarOnly',
      sortingEnabled: true,
      isHiddenByDefault: true,
      width: 210,
      type: BigidGridColumnTypes.STATUS,
      getCellValue: ({ isDsarOnly }) => {
        return {
          text: isDsarOnly ? tLabels('yes') : tLabels('no'),
          status: isDsarOnly ? 'warning' : undefined,
        };
      },
    },
    {
      title: tColumns('comment'),
      name: 'comment',
      width: 150,
      type: BigidGridColumnTypes.TEXT,
      isHiddenByDefault: true,
      getCellValue: ({ comment }) => comment,
    },
  ];
};

export const getDataSourceOnBoardingFilters = (): BigidGridWithToolbarProps<DataSourceModel>['filterToolbarConfig'] => {
  const tColumns = getFixedT('DsGrid.columns');
  const tStatuses = getFixedT('DsGrid.statuses');

  return {
    filters: [
      {
        title: tColumns('enabled'),
        field: 'enabled',
        operator: 'in',
        disabled: true,
        single: true,
        value: [],
        options: [
          {
            label: 'Enabled',
            value: 'yes',
            isSelected: false,
          },
          {
            label: 'Disabled',
            value: 'no',
            isSelected: false,
          },
        ],
      },
      {
        title: tColumns('status'),
        field: 'enrichmentFields.statusDetails.status',
        operator: 'in',
        disabled: true,
        single: false,
        value: [],
        options: [
          {
            label: tStatuses(DsStatuses.DRAFT),
            value: DsStatuses.DRAFT,
            isSelected: false,
          },
          {
            label: tStatuses(DsStatuses.CONNECTED),
            value: DsStatuses.CONNECTED,
            isSelected: false,
          },
          {
            label: tStatuses(DsStatuses.CONNECTION_ERROR),
            value: DsStatuses.CONNECTION_ERROR,
            isSelected: false,
          },
          {
            label: tStatuses(DsStatuses.SCAN_COMPLETED),
            value: DsStatuses.SCAN_COMPLETED,
            isSelected: false,
          },
          {
            label: tStatuses(DsStatuses.SCAN_COMPLETED_WITH_FAILURES),
            value: DsStatuses.SCAN_COMPLETED_WITH_FAILURES,
            isSelected: false,
          },
          {
            label: tStatuses(DsStatuses.SCAN_FAILED),
            value: DsStatuses.SCAN_FAILED,
            isSelected: false,
          },
          {
            label: tStatuses(DsStatuses.SCAN_IN_PROGRESS),
            value: DsStatuses.SCAN_IN_PROGRESS,
            isSelected: false,
          },
          {
            label: tStatuses(DsStatuses.SCAN_PAUSED),
            value: DsStatuses.SCAN_PAUSED,
            isSelected: false,
          },
          {
            label: tStatuses(DsStatuses.SCAN_STOPPED),
            value: DsStatuses.SCAN_STOPPED,
            isSelected: false,
          },
        ],
      },
      ...getInitiativeFilters(),
    ],
  };
};
