import React, { FC, useState, useEffect } from 'react';
import {
  PrimaryButton,
  SecondaryButton,
  BigidDialog,
  BigidSelectOption,
  BigidEditableList,
  BigidEditableListItemType,
  BigidEditableListItemProps,
  BigidFormFieldErrorHelper,
} from '@bigid-ui/components';
import { getSystemAttributes, SystemAttribute, DataCatalogRecord } from '../../DataCatalogService';
import { ManualField, updateManualFields, deleteManualFields, ManualFieldType } from '../DataCatalogColumnsService';
import makeStyles from '@mui/styles/makeStyles';
import { notificationService } from '../../../../services/notificationService';
import { CatalogEventsEnum } from '../../events';
import { analyticsService } from '../../../../services/analyticsService';

const useStyles = makeStyles({
  wrapper: {
    display: 'flex',
    justifyContent: 'center',
  },
});
export interface AttributeMappingDialogProps extends Pick<DataCatalogRecord, 'fullyQualifiedName' | 'scannerType'> {
  //FIXME: only temrorarily due to API issues
  // attributes: BigidEditableChipsAreaValue;
  columnName: string;
  isOpen: boolean;
  onClose?: () => void;
  //FIXME: only temrorarily due to API issues
  // onSubmit?: (addedAttributes: ManualField[], deletedAttributes: ManualField[], shouldGridReload?: boolean) => void;
  onSubmit?: (addedAttributes?: ManualField[], deletedAttributes?: ManualField[], shouldGridReload?: boolean) => void;
  dialogTitle?: string;
}

type AddedSystemAttribute = SystemAttribute & { isNewAttribute?: boolean };

export const AttributeMappingDialog: FC<AttributeMappingDialogProps> = ({
  fullyQualifiedName,
  columnName,
  isOpen,
  onClose,
  onSubmit,
  dialogTitle,
  scannerType,
}) => {
  const classes = useStyles({});
  const [systemAttributes, setSystemAttributes] = useState<SystemAttribute[]>();
  const [userAttributes, setUserAttributes] = useState<SystemAttribute[]>();
  const [listItems, setListItems] = useState<BigidEditableListItemProps[]>([]);
  const [addedSystemAttributes, setAddedSystemAttributes] = useState<AddedSystemAttribute[]>([]);
  const [deletedSystemAttributes, setDeletedSystemAttributes] = useState<SystemAttribute[]>([]);
  const [listItemsSource, setListItemsSource] = useState<BigidSelectOption[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>(null);

  useEffect(() => {
    const isLongInput = addedSystemAttributes.some(({ attribute_name }) => {
      return attribute_name.length > 100;
    });

    setErrorMessage(isLongInput ? 'Attribute name is limited to 100 characters. Please rename it.' : null);
  }, [addedSystemAttributes]);

  const updateFields = async () => {
    try {
      setIsLoading(true);

      const manualFieldsAdded: ManualField[] = addedSystemAttributes
        //FIXME: only temrorarily due to API issues
        // .filter(({ attribute_id }) => attributes.findIndex(({ id }) => attribute_id === id) < 0)
        .map(({ attribute_id, attribute_original_name, attribute_type, isNewAttribute, attribute_original_type }) => ({
          attribute_id,
          fullyQualifiedName,
          isNewAttribute,
          type: ManualFieldType.ATTRIBUTE,
          value: attribute_original_name,
          attribute_type,
          attribute_original_type,
          fieldName: columnName,
        }));

      const manualFieldsDeleted: ManualField[] = deletedSystemAttributes
        //FIXME: only temrorarily due to API issues
        // .filter(({ attribute_id }) => attributes.findIndex(({ id }) => attribute_id === id) >= 0)
        .map(({ attribute_id, attribute_original_name, attribute_type, attribute_original_type }) => ({
          attribute_id,
          fullyQualifiedName,
          type: ManualFieldType.ATTRIBUTE,
          value: attribute_original_name,
          attribute_type,
          attribute_original_type,
          fieldName: columnName,
        }));

      manualFieldsAdded.length > 0 &&
        (await updateManualFields(manualFieldsAdded.map(({ attribute_id, ...field }) => field)));
      manualFieldsDeleted.length > 0 &&
        (await deleteManualFields(manualFieldsDeleted.map(({ attribute_id, ...field }) => field)));

      return { manualFieldsAdded, manualFieldsDeleted };
    } catch ({ message }) {
      notificationService.error('An error has occurred');
      console.error(`An error has occurred: ${message}`);
    } finally {
      setIsLoading(false);
    }
  };

  const mapSystemAttrToListItems = ({
    attribute_id,
    attribute_name,
    attribute_original_name,
    attribute_type,
    attribute_original_type,
  }: SystemAttribute): BigidSelectOption => ({
    id: attribute_id,
    value: attribute_original_name,
    label: attribute_name,
    subLabel: `${attribute_original_name} - ${attribute_original_type || attribute_type}`,
  });

  useEffect(() => {
    if (isOpen) {
      setIsLoading(true);
      getSystemAttributes(columnName, fullyQualifiedName)
        .then(({ attributesList, objectAttributeList }) => {
          setUserAttributes(objectAttributeList);
          setSystemAttributes(attributesList);
          setListItems(
            objectAttributeList.map(mapSystemAttrToListItems).map((listItem: BigidSelectOption) => ({
              attributes: [listItem],
            })),
          );
          setListItemsSource([...attributesList, ...objectAttributeList].map(mapSystemAttrToListItems));
        })
        .catch(() => {
          console.error('An error has occurred');
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  }, [isOpen, columnName, fullyQualifiedName]);
  //FIXME: only temrorarily due to API issues
  // }, [isOpen, attributes, columnName, fullyQualifiedName]);

  const handleListItemAdd = (addedAttribute: BigidEditableListItemType) => {
    const { id: addedAttributeId, __isNew__: isNewAttribute = false, value } = addedAttribute[0];
    const systemAttribute = systemAttributes.find(({ attribute_id }) => attribute_id === addedAttributeId);
    const attributeToAdd = isNewAttribute
      ? {
          attribute_name: value.trim(),
          attribute_original_name: value.trim(),
        }
      : systemAttribute;

    if (attributeToAdd) {
      (addedSystemAttributes.findIndex(({ attribute_id }) => attribute_id === addedAttributeId) < 0 ||
        isNewAttribute) &&
        setAddedSystemAttributes([...addedSystemAttributes, { ...attributeToAdd, isNewAttribute }]);

      if (!isNewAttribute) {
        const deletedAttrIndex = deletedSystemAttributes.findIndex(
          ({ attribute_id }) => attribute_id === addedAttributeId,
        );
        deletedAttrIndex >= 0 &&
          setDeletedSystemAttributes([
            ...deletedSystemAttributes.slice(0, deletedAttrIndex),
            ...deletedSystemAttributes.slice(deletedAttrIndex + 1, deletedSystemAttributes.length),
          ]);
      }

      const trackData = {
        fullyQualifiedName,
        attributeName: attributeToAdd.attribute_name,
        columnName,
        dsType: scannerType,
      };

      analyticsService.trackManualEvent(CatalogEventsEnum.CATALOG_ADD_ATTRIBUTE, trackData);
    }
  };

  const handleListItemDelete = (deletedAttribute: BigidEditableListItemType) => {
    const { id: deletedAttributeId, __isNew__: isNewAttribute, value } = deletedAttribute[0];

    if (!isNewAttribute) {
      const attributeToDelete = userAttributes.find(({ attribute_id }) => attribute_id === deletedAttributeId);

      if (attributeToDelete && deletedSystemAttributes.findIndex(({ id }) => id === deletedAttributeId) < 0) {
        setDeletedSystemAttributes([...deletedSystemAttributes, attributeToDelete]);
      }
    }

    const addedAttrIndex = addedSystemAttributes.findIndex(({ attribute_id, attribute_name }) =>
      !isNewAttribute ? attribute_id === deletedAttributeId : attribute_name === value.trim(),
    );

    addedAttrIndex >= 0 &&
      setAddedSystemAttributes([
        ...addedSystemAttributes.slice(0, addedAttrIndex),
        ...addedSystemAttributes.slice(addedAttrIndex + 1, addedSystemAttributes.length),
      ]);

    const trackData = {
      fullyQualifiedName,
      attributeName: value,
      columnName,
      dsType: scannerType,
    };

    analyticsService.trackManualEvent(CatalogEventsEnum.CATALOG_REMOVE_ATTRIBUTE_EVENT, trackData);
  };

  const resetState = () => {
    setListItems([]);
    setListItemsSource([]);
    setUserAttributes([]);
    setDeletedSystemAttributes([]);
    setAddedSystemAttributes([]);
    setErrorMessage(null);
  };

  const handleOnDialogSave = () => {
    if (!errorMessage) {
      updateFields().then(({ manualFieldsAdded, manualFieldsDeleted }) => {
        const containsNewAttribute = !!addedSystemAttributes.find(({ isNewAttribute }) => isNewAttribute);

        setAddedSystemAttributes([]);
        setDeletedSystemAttributes([]);

        resetState();
        onSubmit(manualFieldsAdded, manualFieldsDeleted, containsNewAttribute);
      });
    }
  };

  const handleOnClose = () => {
    resetState();
    onClose();
  };

  const isValidNewOption = (inputValue: string) => {
    const duplicatesInSystemAttributes = systemAttributes.filter(
      ({ attribute_name }) => attribute_name.toLowerCase().trim() === inputValue.toLowerCase().trim(),
    );
    const duplicatesInAddSystemAttributes = addedSystemAttributes.filter(
      ({ attribute_name }) => attribute_name.toLowerCase().trim() === inputValue.toLowerCase().trim(),
    );
    const duplicatesInListItems = listItems.filter(
      ({ attributes }) => attributes[0].label.toLowerCase().trim() === inputValue.toLowerCase().trim(),
    );

    if (
      duplicatesInSystemAttributes.length > 0 ||
      duplicatesInAddSystemAttributes.length > 0 ||
      duplicatesInListItems.length > 0
    ) {
      return false;
    } else {
      return true;
    }
  };

  return (
    <BigidDialog
      title={dialogTitle || `${columnName} - Edit Attribute Mapping`}
      isOpen={isOpen}
      onClose={handleOnClose}
      buttons={[
        {
          component: SecondaryButton,
          onClick: handleOnClose,
          text: 'Cancel',
        },
        {
          component: PrimaryButton,
          onClick: handleOnDialogSave,
          text: 'Save',
          disabled: !!errorMessage,
        },
      ]}
      isLoading={isLoading}
      maxWidth="xs"
      borderTop
    >
      <div className={classes.wrapper}>
        <BigidEditableList
          isCreatable
          inlineEditDisabled
          listItems={listItems}
          title="Attribute name"
          subTitle="Original name - Type"
          sourceOptions={listItemsSource}
          onAdd={handleListItemAdd}
          onDelete={handleListItemDelete}
          noOptionsMessage={'Attribute with this name already exists. Please use another name.'}
          isValidNewOption={isValidNewOption}
        />
      </div>
      <BigidFormFieldErrorHelper errorMessage={errorMessage} />
    </BigidDialog>
  );
};
