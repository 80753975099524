import React, { ReactNode } from 'react';
import { BigidChip, BigidColors, BigidIcon, BigidIconSize, QueryParams } from '@bigid-ui/components';
import {
  BigidDataSourceOnlyIcon,
  BigidDataSourceViewIcon,
  BigidFileIcon,
  DataSourceIconByDsType,
  BigidModelIcon,
  BigidDatasetIcon,
  BigidVectorDbIcon,
} from '@bigid-ui/icons';
import ErrorIcon from '@mui/icons-material/Error';

import {
  DataCatalogExtendedRecord,
  DataCatalogGridDataFetchResult,
  DataCatalogRecord,
  NewDataCatalogPagePreferences,
} from './types';
import {
  DataCatalogObjectType,
  FetchDataCatalogRecordsPayload,
  getDataCatalogRecordsCount,
  getDataCatalogRecordsPost,
} from '../DataCatalogService';
import { getDataSourcesOwnerShip, isPermitted } from '../../../services/userPermissionsService';
import { CATALOG_PERMISSIONS, TAGS_PERMISSIONS } from '@bigid/permissions';
import {
  CONTAINS_PI,
  HAS_DUPLICATES,
  HAS_DUPLICATES_CHIP_TYPE,
  NO,
  NO_DUPLICATES,
  NO_OPEN_ACCESS,
  OPEN_ACCESS,
  OPEN_ACCESS_CHIP_TYPE,
  TOTAL_COUNT_THRESHOLD,
  YES,
} from '../DataCatalogConsts';
import { getApplicationPreference } from '../../../services/appPreferencesService';
import {
  BigidGridColumn,
  BigidGridColumnTypes,
  ChipFormatterProps,
  ChipsFormatterProps,
  TagsFormatterProps,
} from '@bigid-ui/grid';
import { TagAssignmentTarget } from '../../TagsManagement/TagsManagementService';
import { getTagFormattedName, getTagIcon } from '../../TagsManagement/TagsManagementUtils';
import { httpService } from '../../../services/httpService';
import { analyticsService } from '../../../services/analyticsService';
import { CatalogEventsEnum } from '../events';
import { userPreferencesService } from '../../../services/userPreferencesService';
import { notificationService } from '../../../services/notificationService';
import { getIconByObjectType, getObjectTypeName } from '../utils';
import { processStringifiedDateRangeFilterToDateObject } from './newDataCatalogUtils';
import { CONFIG } from '../../../../config/common';

export const hasIndicatorMapping = new Map([
  [
    OPEN_ACCESS_CHIP_TYPE,
    new Map<string, ChipFormatterProps>([
      [
        YES,
        {
          chip: {
            icon: <BigidIcon color={BigidColors.red[700]} icon={ErrorIcon} size={BigidIconSize.MEDIUM} />,
            bgColor: BigidColors.red[100],
            size: 'small',
            label: OPEN_ACCESS,
          },
        },
      ],
      [
        NO,
        {
          chip: {
            bgColor: BigidColors.gray[50],
            size: 'small',
            label: NO_OPEN_ACCESS,
          },
        },
      ],
    ]),
  ],
  [
    HAS_DUPLICATES_CHIP_TYPE,
    new Map<string, ChipFormatterProps>([
      [
        YES,
        {
          chip: {
            bgColor: BigidColors.red[100],
            size: 'small',
            label: HAS_DUPLICATES,
          },
        },
      ],
      [
        NO,
        {
          chip: {
            bgColor: BigidColors.gray[50],
            size: 'small',
            label: NO_DUPLICATES,
          },
        },
      ],
    ]),
  ],
]);

const detailsIconMapping: Record<DataCatalogObjectType, ReactNode> = {
  [DataCatalogObjectType.APP_LEAF]: <BigidDataSourceOnlyIcon staticMode={true} />,
  [DataCatalogObjectType.STRUCTURED_LEAF]: <BigidDataSourceOnlyIcon staticMode={true} />,
  [DataCatalogObjectType.STRUCTURED_FILE_LEAF]: <BigidFileIcon staticMode={true} />,
  [DataCatalogObjectType.UNSTRUCTURED_LEAF]: <BigidFileIcon staticMode={true} />,
  [DataCatalogObjectType.PARTITIONED_TABLE_LEAF]: <BigidFileIcon staticMode={true} />,
  [DataCatalogObjectType.TABLE]: <BigidDataSourceOnlyIcon staticMode={true} />,
  [DataCatalogObjectType.TABLE_VIEW]: <BigidDataSourceViewIcon staticMode={true} />,
  [DataCatalogObjectType.FILE]: <BigidFileIcon staticMode={true} />,
  [DataCatalogObjectType.MODEL]: <BigidModelIcon staticMode={true} />,
  [DataCatalogObjectType.DATASET]: <BigidDatasetIcon staticMode={true} />,
  [DataCatalogObjectType.VECTOR]: <BigidVectorDbIcon staticMode={true} />,
};

const getGridData = (results: DataCatalogRecord[], permittedDs: Record<string, boolean>, isRootScope?: boolean) => {
  return results.map(record => {
    const {
      open_access = NO,
      total_pii_count = 0,
      duplicate_id = null,
      attribute = [],
      application_name = [],
      tags = [],
    } = record;
    const generalTags = [];
    const icon = detailsIconMapping[record.extendedObjectType];

    if (open_access.toLowerCase() === YES && Boolean(getApplicationPreference('DISABLE_LEGACY_ACL_FF') !== true)) {
      const { chip } = hasIndicatorMapping.get(OPEN_ACCESS_CHIP_TYPE).get(YES);
      generalTags.push(<BigidChip icon={chip.icon} bgColor={chip.bgColor} size={chip.size} label={chip.label} />);
    }

    if (total_pii_count > 0) {
      generalTags.push(<BigidChip bgColor={BigidColors.red[100]} size="small" label={CONTAINS_PI} />);
    }

    if (duplicate_id) {
      generalTags.push(<BigidChip bgColor={BigidColors.red[100]} size="small" label={HAS_DUPLICATES} />);
    }

    const attributes: ChipsFormatterProps = {
      chips: {
        value: attribute.map(attr => ({
          label: attr,
        })),
        isDisabled: true,
        isAutoFit: false,
      },
    };

    const applications: ChipsFormatterProps = {
      chips: {
        value: application_name.map(app => ({
          label: app,
        })),
        isDisabled: true,
        isAutoFit: false,
      },
    };

    const manualTags: TagsFormatterProps = {
      tags: {
        value: tags
          .reduce((tagsFiltered, tag) => {
            if (tag.tagType === TagAssignmentTarget.object) {
              return [tag, ...tagsFiltered];
            } else {
              return tagsFiltered.find(t => t.tagName === tag.tagName && t.tagValue === tag.tagValue)
                ? tagsFiltered
                : [...tagsFiltered, tag];
            }
          }, [])
          .filter(({ properties }) => !properties?.hidden)
          .map(({ tagName, tagValue, tagType, properties }) => ({
            name: getTagFormattedName(tagName),
            value: tagValue,
            iconDescription: tagType === TagAssignmentTarget.column ? tagType : undefined,
            icon: getTagIcon(properties, tagType),
          })),
        isDisabled: true,
        isAutoFit: false,
        entityMaxWidth: 150,
        tagBgColor: BigidColors.purple[50],
      },
    };

    const isPermittedToPreview =
      (permittedDs?.[record.source] || isRootScope) && isPermitted(CATALOG_PERMISSIONS.PREVIEW_FILE_INVESTIGATION.name);

    return {
      ...record,
      generalTags,
      icon,
      attributes,
      applications,
      manualTags,
      isPermittedToPreview,
    };
  });
};

export const fetchGridData = async (
  queryComponents: QueryParams,
): Promise<DataCatalogGridDataFetchResult<DataCatalogRecord>> => {
  const isNewQueryFilterEnabled = Boolean(getApplicationPreference('NEW_QUERY_FILTER_ENABLED'));

  try {
    //NOTE: temporarily disabled until filtering flow is designed
    // const filter = parseFieldFiltersToSearchQuery(queryComponents.filter);
    // const filterString = mapDropdownFiltersToFilterString(queryComponents.filter);
    const filterString = queryComponents.filter;
    const payload = {
      ...queryComponents,
      requireTotalCount: 'requireTotalCount=false',
    } as FetchDataCatalogRecordsPayload;

    const { results, totalRowsCounter } = await getDataCatalogRecordsPost(payload);

    const { permittedDs, isRootScope } = await getDataSourcesOwnerShip(
      CATALOG_PERMISSIONS.PREVIEW_FILE_INVESTIGATION.name,
    );

    const result: DataCatalogGridDataFetchResult<DataCatalogRecord> = {
      totalCount: queryComponents.requireTotalCount ? totalRowsCounter : undefined,
      data: getGridData(results, permittedDs, isRootScope),
      fetchCount: undefined,
    };

    if (filterString) {
      const trackData = {
        Search_Query_Input: filterString,
        totalHits: result.totalCount,
      };

      analyticsService.trackManualEvent(CatalogEventsEnum.CATALOG_SEARCH, trackData);
      userPreferencesService.setSharedBooleanUserPreference(
        userPreferencesService.SHARED_PREFERENCES.CATALOG_WAS_USED_AT_LEAST_ONCE,
        true,
      );
    }

    return result;
  } catch (err) {
    const { message, response } = err;
    let errMessage;
    switch (response.status) {
      case 524:
        errMessage = 'This query is taking too long. Add more filters and try again.';
        break;
      case 422:
        errMessage = `Invalid filter query. Please, use the following format: ${
          isNewQueryFilterEnabled ? 'key = "value"' : 'key = value'
        }`;
        break;
      default:
        errMessage = 'An error has occurred while fetching the data';
    }

    notificationService.error(errMessage);
    console.error(`An error has occurred: ${message}`);

    return {
      totalCount: 0,
      data: [],
    };
  }
};

export const fetchGridDataCount = async (filter: string) => {
  try {
    const encodedFilter = encodeURIComponent(filter);
    const { count } = await getDataCatalogRecordsCount(`filter=${encodedFilter || ''}`);

    return count;
  } catch (e) {
    let totalCount = 0;
    if (httpService.isAxiosError(e)) {
      totalCount = e.response?.status === 408 || e.response?.status === 524 ? TOTAL_COUNT_THRESHOLD : 0;

      return totalCount;
    }
  }
};

const tagsColumnIfEnabled: BigidGridColumn<DataCatalogExtendedRecord>[] = [
  {
    title: 'Tags',
    name: 'manualTags',
    type: BigidGridColumnTypes.TAGS,
    width: 400,
    getCellValue: ({ manualTags }) => ({
      tags: {
        ...manualTags.tags,
        value: manualTags.tags.value.map(tagDetails => ({
          ...tagDetails,
          name: tagDetails.name,
        })),
      },
    }),
  },
];

export const getColumns = (): BigidGridColumn<DataCatalogExtendedRecord>[] => {
  const disableLegacyACL = Boolean(getApplicationPreference('DISABLE_LEGACY_ACL_FF'));
  const isTagsReadPermitted = isPermitted(TAGS_PERMISSIONS.READ.name);
  const columns: BigidGridColumn<DataCatalogExtendedRecord>[] = [
    {
      title: 'Object Name',
      name: 'objectName',
      isListColumn: true,
      type: BigidGridColumnTypes.TEXT,
      getCellValue: ({ objectName }) => objectName,
    },
    {
      title: 'Type',
      name: 'objectType',
      type: BigidGridColumnTypes.ICON,
      getCellValue: ({ extendedObjectType }) => ({
        icon: {
          icon: getIconByObjectType(extendedObjectType),
          label: getObjectTypeName(extendedObjectType),
        },
      }),
    },
    {
      title: 'Data Source Name',
      name: 'source',
      type: BigidGridColumnTypes.TEXT,
      getCellValue: ({ source }) => source,
    },
    {
      title: 'Data Source Type',
      name: 'type',
      type: BigidGridColumnTypes.ICON,
      getCellValue: ({ type }) => ({
        icon: {
          icon: () => <DataSourceIconByDsType dataSourceType={type} width={24} height={24} />,
          label: type,
        },
      }),
    },
    {
      title: 'Full Object Name',
      name: 'fullObjectName',
      type: BigidGridColumnTypes.TEXT,
      getCellValue: ({ fullObjectName }) => fullObjectName,
    },
    {
      title: 'Attributes',
      name: 'attributes',
      type: BigidGridColumnTypes.CHIPS,
      width: 400,
      getCellValue: ({ attributes }) => attributes,
    },
    ...(isTagsReadPermitted ? tagsColumnIfEnabled : []),
    {
      getCellValue: ({ modified_date }) => modified_date,
      title: 'Last Modified',
      name: 'modified_date',
      type: BigidGridColumnTypes.DATE,
      width: 180,
    },
    {
      getCellValue: ({ created_date }) => created_date,
      title: 'Created Date',
      name: 'created_date',
      type: BigidGridColumnTypes.DATE,
      width: 180,
    },
    {
      title: 'Assets',
      name: 'applications',
      type: BigidGridColumnTypes.CHIPS,
      width: 400,
      getCellValue: ({ applications }) => applications,
    },
    {
      title: 'Has Duplicates',
      name: 'has_duplicates',
      type: BigidGridColumnTypes.CHIP,
      getCellValue: ({ has_duplicates }) =>
        hasIndicatorMapping.get(HAS_DUPLICATES_CHIP_TYPE).get(has_duplicates.toLowerCase()),
    },
  ];
  if (disableLegacyACL !== true) {
    columns.push({
      title: 'Open Access',
      name: 'open_access',
      width: 400,
      type: BigidGridColumnTypes.CHIP,
      getCellValue: ({ open_access }) => hasIndicatorMapping.get(OPEN_ACCESS_CHIP_TYPE).get(open_access.toLowerCase()),
    });
  }
  return columns;
};

export const updateNewCatalogFilterPreferences = async (newFilterPreferences: NewDataCatalogPagePreferences) => {
  try {
    const preferences = await userPreferencesService.get<NewDataCatalogPagePreferences>(CONFIG.states.NEW_DATA_CATALOG);

    if (preferences) {
      const { filterString = '', overrideValues = [] } = newFilterPreferences;
      await userPreferencesService.update<NewDataCatalogPagePreferences>({
        ...preferences,
        data: {
          filterString,
          overrideValues,
        },
      });
    } else {
      const { filterString = '', overrideValues = [] } = newFilterPreferences;
      await userPreferencesService.add({
        preference: CONFIG.states.NEW_DATA_CATALOG,
        data: {
          filterString,
          overrideValues,
        },
      });
    }
  } catch ({ message }) {
    console.error(`An error has occurred: ${message}`);
    notificationService.error(`An error has occurred`);
  }
};

export const getNewDataCatalogFilterPreferences = async (): Promise<NewDataCatalogPagePreferences | null> => {
  try {
    const preferences = await userPreferencesService.get<NewDataCatalogPagePreferences>(CONFIG.states.NEW_DATA_CATALOG);

    const processedFilters = preferences?.data?.overrideValues.map(processStringifiedDateRangeFilterToDateObject);

    if (preferences?.data) {
      return {
        ...preferences.data,
        overrideValues: processedFilters,
      };
    }
    return {
      filterString: '',
      overrideValues: [],
    };
  } catch ({ message }) {
    console.error(`An error has occurred: ${message}`);
    return {
      filterString: '',
      overrideValues: [],
    };
  }
};
