import { BigidDialog, BigidTextField, PrimaryButton, SecondaryButton } from '@bigid-ui/components';
import noop from 'lodash/noop';
import React, { FC, useRef, useState } from 'react';
import { notificationService } from '../../services/notificationService';
import { createObjectIssue } from './DataCatalogService';

const ERROR_MESSAGE = 'Please add a comment before submitting the issue.';

const cleanComment = (comment: string) => comment.trim();
const isValidComment = (comment: string) => comment?.length > 0;

export interface DataCatalogObjectIssueDialogProps {
  isOpen: boolean;
  fullyQualifiedName?: string;
  onClose?: () => void;
  onSubmit?: () => void;
}

export const DataCatalogObjectIssueDialog: FC<DataCatalogObjectIssueDialogProps> = ({
  isOpen,
  onClose,
  onSubmit,
  fullyQualifiedName,
}) => {
  const commentInputRef = useRef<HTMLTextAreaElement>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isInvalid, setIsInvalid] = useState<boolean>(false);

  const errorMessage = isInvalid ? ERROR_MESSAGE : undefined;

  const submitIssue = async (comment: string) => {
    try {
      if (fullyQualifiedName) {
        await createObjectIssue(fullyQualifiedName, comment);
        notificationService.success('The issue you reported was submitted successfully.');
      }
    } catch (e) {
      notificationService.error('An error has occurred');
      console.error(`An error has occurred: ${e?.message}`);
      throw e;
    }
  };

  const resetState = () => {
    setIsLoading(false);
    setIsInvalid(false);
  };

  const handleOnDialogSave = async () => {
    const cleanedComment = cleanComment(commentInputRef.current.value || '');
    const shouldSubmit = isValidComment(cleanedComment);

    if (!shouldSubmit) {
      setIsInvalid(true);
      return commentInputRef.current.focus?.();
    }

    setIsLoading(true);
    try {
      await submitIssue(cleanedComment);
      onSubmit();
    } finally {
      resetState();
    }
  };

  const handleOnDialogClose = () => {
    onClose?.();
    resetState();
  };

  return (
    <BigidDialog
      title="Report Issue"
      isOpen={isOpen}
      onClose={handleOnDialogClose}
      borderTop
      buttons={[
        {
          component: SecondaryButton,
          onClick: handleOnDialogClose,
          text: 'Cancel',
        },
        {
          component: PrimaryButton,
          onClick: handleOnDialogSave,
          text: 'Submit',
        },
      ]}
      isLoading={isLoading}
      maxWidth="sm"
    >
      <div>
        <BigidTextField
          autoFocus
          required
          placeholder="Description of the issue..."
          onChange={noop}
          multiline
          rows={5}
          inputRef={commentInputRef}
          errorMessage={errorMessage}
        />
      </div>
    </BigidDialog>
  );
};
