import React, { FC, useEffect, useState, useCallback, useMemo } from 'react';
import { cloneDeep, isEmpty, takeRight } from 'lodash';
import makeStyles from '@mui/styles/makeStyles';
import { BigidColors, BigidColorsV2 } from '@bigid-ui/components';
import { APPLICATIONS_PERMISSIONS, getManageBasePermissionNameByTpaName } from '@bigid/permissions';

import { AppInfo } from '../../utils/CustomAppTypes';
import { CustomAppParam } from '../EditCustomApp/EditCustomApp';
import { ActionCard, getLatestExecutionsObjectKey } from '../../components/ActionCard';
import { SchedulerModal } from './SchedulerModal';
import { customAppService } from '../../../../services/customAppService';
import { notificationService } from '../../../../services/notificationService';
import { tpaStatusService } from '../../../../services/tpaStatusService';
import { areSomePermitted } from '../../../../services/userPermissionsService';
import { $state } from '../../../../services/angularServices';
import { ActionsEmptyState } from './ActionsEmptyState';
import { AttachmentMetadata, ExecutionsSSE } from '../ActivityLog/ActivityLog';
import { ExecutionData, ExecutionStatus } from '../../components/PresetCard/PresetLatestExecutionStatus';
import { calculateDuration, formatTimeToVerbal } from '../../utils/time.util';
import { SSE_EVENTS, SSEDataMessage, subscribeToRepeatedSSEEventById } from '../../../../services/sseService';
import { EventEmitterDeregistrator } from '@bigid-ui/utils';
import { CONFIG } from '../../../../../config/common';
import { TPATrackingEvents, trackTPAEvent } from '../../customAppEventsTrackerUtils';
import { getApplicationPreference } from '../../../../services/appPreferencesService';

export interface CustomAppActionsProps {
  appInfo: AppInfo;
  actions: CustomAppAction[];
  reloadActions: (tpaId: string) => void;
  onEditPreset: (action: CustomAppAction, preset: Preset) => void;
  onAddNewPreset: (action: CustomAppAction) => void;
}

export interface CustomAppAction {
  id: string;
  name: string;
  friendlyName?: string;
  description?: string;
  params: CustomAppParam[];
  value?: string;
  tpaId: string;
  presets?: Preset[];
  isScheduled?: boolean;
  cronExpression?: string;
  isEnabled?: boolean;
  sequentialExecute?: boolean;
}

export interface Preset extends PresetPayload {
  _id: string;
  actionId: string;
  isDefaultPreset: boolean;
}

export interface PresetPayload {
  name?: string;
  description?: string;
  isScheduled?: boolean;
  cronExpression?: string;
  paramsKeyValue?: Record<string, string>;
  overrideGlobalParams?: boolean;
  globalParamsKeyValue?: Record<string, string>;
  is_default?: boolean;
  globalPresetId?: string;
}

export interface ExecutionPayload {
  _id: string;
  created_at: string;
  updated_at: string;
  attachment_ids: string[];
  user_name: string;
  attachments: AttachmentMetadata[];
  preset_id: string;
  message: string;
  status_enum: ExecutionStatus;
  tpa_action_id: string;
}

const useStyles = makeStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: '16px',
    backgroundColor: BigidColors.oldBackground,
    padding: '8px',
    minHeight: '100%',
  },
  presetParamsWrapper: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    padding: '16px',
    backgroundColor: BigidColorsV2.white,
  },
});

let isCloning = false;

const formatExecutionDuration = ({ createdAt, updatedAt, status }: Partial<ExecutionData>) => {
  if (status === ExecutionStatus.IN_PROGRESS) return 'N/A';
  return calculateDuration(createdAt, updatedAt);
};

const mapExecutionPayloadToUsable = (executionPayload: ExecutionPayload): ExecutionData => {
  const {
    created_at: createdAt,
    updated_at: updatedAt,
    user_name: userName,
    attachments = [],
    message: info,
    status_enum: status,
  } = executionPayload;

  return {
    createdAt: formatTimeToVerbal(createdAt),
    originalCreatedAt: createdAt,
    userName,
    updatedAt,
    info,
    attachments,
    duration: formatExecutionDuration({ createdAt, updatedAt, status }),
    status,
  };
};

export const reloadActionsPage = (appInfo: AppInfo) => {
  const { id } = appInfo;
  $state.go(CONFIG.states.CUSTOM_APP_ACTIONS, { id, appInfo }, { reload: true });
};

export const convertToParamsValuesObject = (params: CustomAppParam[]): Record<string, string> =>
  params.reduce((keyValues: Record<string, string>, actionParam) => {
    keyValues[actionParam.name] = actionParam.value;
    return keyValues;
  }, {});

const getLatestExecutionsMap = (latestExecutions: ExecutionPayload[]): Record<string, ExecutionData> =>
  latestExecutions.reduce((acc: Record<string, ExecutionData>, execution: ExecutionPayload) => {
    const { preset_id, tpa_action_id } = execution;
    const latestExecutionKey = getLatestExecutionsObjectKey(tpa_action_id, preset_id);
    acc[latestExecutionKey] = mapExecutionPayloadToUsable(execution);
    return acc;
  }, {});

export const generatedUniquePresetName = (name: string, existingNames: string[], counter = 1): string => {
  if (!existingNames.includes(name)) return name;
  const cleanName = name.split(' #')[0];
  const newName = `${cleanName} #${counter}`;
  return generatedUniquePresetName(newName, existingNames, ++counter);
};

const updateExecutionMapObject = (currentObj: Record<string, ExecutionData>, executionsUpdates: ExecutionsSSE[]) => {
  const latestExecutionsMapClone = cloneDeep(currentObj);
  executionsUpdates.forEach(({ execution, attachmentsToUpdate }) => {
    const { tpa_action_id, preset_id } = execution;
    const key = getLatestExecutionsObjectKey(tpa_action_id, preset_id);
    let attachments: AttachmentMetadata[] = [];
    if (latestExecutionsMapClone[key]) {
      attachments =
        latestExecutionsMapClone[key].originalCreatedAt !== execution.created_at
          ? []
          : latestExecutionsMapClone[key]?.attachments;
    }
    latestExecutionsMapClone[key] = { ...mapExecutionPayloadToUsable(execution), attachments };

    if (!isEmpty(attachmentsToUpdate)) {
      const attachmentsArr = latestExecutionsMapClone[key].attachments;
      const updatedAttachments = concatUniqueAttachmentsToArr(attachmentsArr, attachmentsToUpdate);
      latestExecutionsMapClone[key].attachments = takeRight(updatedAttachments, 3);
    }
  });

  return latestExecutionsMapClone;
};

const concatUniqueAttachmentsToArr = (
  currentAttachmentsArr: AttachmentMetadata[],
  attachmentsToUpdate: AttachmentMetadata[],
): AttachmentMetadata[] => {
  const currentAttachmentsIds = new Set(currentAttachmentsArr.map(({ id }) => id));
  return [
    ...currentAttachmentsArr,
    ...attachmentsToUpdate.filter(attachment => !currentAttachmentsIds.has(attachment.id)),
  ];
};

export const CustomAppActions: FC<CustomAppActionsProps> = ({
  actions = [],
  reloadActions,
  appInfo,
  onEditPreset,
  onAddNewPreset,
}) => {
  const classes = useStyles({});
  const [selectedPresetItem, setSelectedPresetItem] = useState<Preset>(null);
  const [isScheduleDialogOpen, setIsScheduleDialogOpen] = useState<boolean>(false);
  const [latestExecutionsMap, setLatestExecutionsMap] = useState<Record<string, ExecutionData>>({});
  const actionsIds = useMemo(() => new Set(actions.map(action => action.id)), [actions]);

  const handleExecutionSSEMessage = useCallback(
    ({ results }: SSEDataMessage) => {
      const executionsToUpdate = (results as ExecutionsSSE[]).reduce((acc, executionUpdate) => {
        if (actionsIds.has(executionUpdate.execution.tpa_action_id)) acc.push(executionUpdate);
        return acc;
      }, []);
      setLatestExecutionsMap(state => updateExecutionMapObject(state, executionsToUpdate));
    },
    [actionsIds],
  );

  useEffect(() => {
    const deregisterSSE: EventEmitterDeregistrator = subscribeToRepeatedSSEEventById(
      SSE_EVENTS.TPA_ACTIONS_EXECUTIONS,
      handleExecutionSSEMessage,
    );
    return () => deregisterSSE?.();
  }, [handleExecutionSSEMessage]);

  useEffect(() => {
    const getExecutions = async () => {
      const executionRes = await customAppService.getCustomAppLastExecutions(appInfo.id);
      const executions = executionRes.data as ExecutionPayload[];
      const executionsMap = getLatestExecutionsMap(executions);
      setLatestExecutionsMap(executionsMap);
    };
    getExecutions();
  }, [appInfo]);

  const handleAddNewPreset = useCallback(
    (action: CustomAppAction) => {
      trackTPAEvent(TPATrackingEvents.TPA_ACTION_ADD_PRESET_CLICK, {
        appName: appInfo.name,
        actionName: action.name,
      });
      onAddNewPreset(action);
    },
    [appInfo.name, onAddNewPreset],
  );

  const handleDeletePreset = useCallback(
    async (preset: Preset) => {
      const { _id, name, actionId } = preset;
      try {
        await customAppService.deleteActionPreset(appInfo.id, actionId, _id);
        trackTPAEvent(TPATrackingEvents.TPA_ACTION_DELETE_PRESET_CLICK, {
          appName: appInfo.name,
          presetName: name,
        });
        reloadActionsPage(appInfo);
        notificationService.success(`${name} Preset - Deleted`, { shouldCloseOnTransition: false });
      } catch (e) {
        const {
          data: { message },
        } = e.response;
        notificationService.error(message, { shouldCloseOnTransition: false });
      }
    },
    [appInfo],
  );

  const handleOnRunPreset = useCallback(
    async ({ actionId, name, _id: presetId }: Preset, actionName: string) => {
      try {
        await customAppService.runCustomAppPreset(actionId, presetId, appInfo.id);
        trackTPAEvent(TPATrackingEvents.TPA_ACTION_RUN_CLICK, {
          appName: appInfo.name,
          actionName,
          presetName: name,
        });
        notificationService.success(`Running Action ${actionName} With Preset - ${name}`);
      } catch (e) {
        const {
          data: { message },
        } = e.response;
        notificationService.error(message);
      }
    },
    [appInfo.id, appInfo.name],
  );

  const handleOnEditPreset = (action: CustomAppAction, preset: Preset) => {
    trackTPAEvent(TPATrackingEvents.TPA_ACTION_EDIT_PRESET_CLICK, {
      appName: appInfo.name,
      actionName: action.name,
      presetName: preset.name,
    });
    onEditPreset(action, preset);
  };

  const handleUpdateSchedule = useCallback(
    async (preset: Preset, isScheduled: boolean, cronExpression: string) => {
      const { _id, actionId } = preset;
      await customAppService.editActionPreset(appInfo.id, actionId, _id, { isScheduled, cronExpression });
      trackTPAEvent(TPATrackingEvents.TPA_ACTION_SCHEDULE_CLICK, {
        appName: appInfo.name,
        actionName: actions.find(({ id }) => id === actionId).name,
        presetName: preset.name,
        isScheduled,
      });
      setIsScheduleDialogOpen(false);
      await reloadActions(appInfo.id);
    },
    [appInfo.id, appInfo.name, actions, reloadActions],
  );

  const handleScheduleDialogClose = useCallback(() => {
    setIsScheduleDialogOpen(false);
  }, []);

  const handleOnClonePreset = useCallback(
    async (action: CustomAppAction, preset: Preset) => {
      const existingNames = action.presets.map(p => p.name);
      const name = generatedUniquePresetName(`Clone - ${preset.name}`, existingNames);
      const { description, paramsKeyValue, overrideGlobalParams, globalParamsKeyValue } = preset;
      const presetData: PresetPayload = {
        name,
        description,
        paramsKeyValue,
        overrideGlobalParams,
        globalParamsKeyValue: overrideGlobalParams ? globalParamsKeyValue : undefined,
      };
      if (!isCloning) {
        //We are preventing double cloning in case of doubleClick
        isCloning = true;
        try {
          await customAppService.addActionPreset(appInfo.id, action.id, presetData);
          trackTPAEvent(TPATrackingEvents.TPA_ACTION_CLONE_PRESET_CLICK, {
            appName: appInfo.name,
            actionName: action.name,
            presetName: preset.name,
          });
          notificationService.success(`Cloned preset successfully`, { shouldCloseOnTransition: false });
          reloadActionsPage(appInfo);
        } catch (err) {
          const message = 'There is a problem cloning this preset';
          notificationService.error(message);
        }
        isCloning = false;
      }
    },
    [appInfo],
  );

  const isAppOnline = !tpaStatusService.isTpaOffline(appInfo.status);
  const hasManagerPermission = areSomePermitted([
    APPLICATIONS_PERMISSIONS.MANAGE_TPA_CUSTOM_APPS.name,
    APPLICATIONS_PERMISSIONS.DELETE_AND_ADD_CUSTOM_APPS.name,
    getManageBasePermissionNameByTpaName(appInfo.name),
  ]);

  const handleOnScheduleButtonClick = useCallback(
    (preset: Preset) => {
      if (hasManagerPermission) {
        setSelectedPresetItem(preset);
        setIsScheduleDialogOpen(true);
        trackTPAEvent(TPATrackingEvents.TPA_ACTION_SCHEDULE_MODAL_CLICK, {
          appName: appInfo.name,
          presetName: preset.name,
        });
      }
    },
    [appInfo.name, hasManagerPermission],
  );

  return isEmpty(actions) ? (
    <ActionsEmptyState />
  ) : (
    <>
      <div className={classes.root}>
        {isScheduleDialogOpen && (
          <SchedulerModal
            preset={selectedPresetItem}
            onUpdateSchedule={handleUpdateSchedule}
            onClose={handleScheduleDialogClose}
          />
        )}
        {actions.map((action: CustomAppAction) => (
          <ActionCard
            key={action.id}
            appGlobalParams={appInfo.globalParams}
            action={action}
            onAddNewPreset={action => handleAddNewPreset(action)}
            onDeletePreset={handleDeletePreset}
            onEditPreset={preset => handleOnEditPreset(action, preset)}
            onRunPreset={handleOnRunPreset}
            onSchedule={handleOnScheduleButtonClick}
            isAppOnline={isAppOnline}
            hasManagerPermission={hasManagerPermission}
            onClonePreset={preset => handleOnClonePreset(action, preset)}
            latestExecutionsMap={latestExecutionsMap}
            globalPresets={appInfo.globalPresets}
          />
        ))}
      </div>
    </>
  );
};
