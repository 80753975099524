import React, { FC } from 'react';
import { BigidBody1, BigidSearch } from '@bigid-ui/components';
import makeStyles from '@mui/styles/makeStyles';
import classnames from 'classnames';

const useStyles = makeStyles(theme => ({
  container: {
    color: theme.vars.palette.bigid.gray700,
    width: 269,
  },
  searchBoxContainer: {
    '& > :first-child': {
      height: 40,
      '& input': {
        fontSize: 14,
      },
    },
  },
}));

export interface SearchBoxProps {
  title?: string;
  placeholder: string;
  onSearch: (text: string) => void;
  className?: string;
}

export const SearchBox: FC<SearchBoxProps> = ({ title, placeholder, onSearch, className }) => {
  const { container, searchBoxContainer } = useStyles({});

  return (
    <div className={classnames(container, className)}>
      {title && <BigidBody1 color="inherit">{title}</BigidBody1>}
      <div className={searchBoxContainer}>
        <BigidSearch size="large" onSubmit={onSearch} onChange={onSearch} placeholder={placeholder} />
      </div>
    </div>
  );
};
