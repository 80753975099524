import React, { FC, useEffect, useState } from 'react';
import { ExternalAppWidget } from '@bigid-ui/custom-dashboard';
import makeStyles from '@mui/styles/makeStyles';
import { customAppService } from '../../../services/customAppService';
import { AppInfo } from '../utils/CustomAppTypes';
import { CustomAppUI } from '../views/CustomAppUI/CustomAppUI';
import { tpaStatusService } from '../../../services/tpaStatusService';
import { WidgetNotAvailablePlaceHolder } from './WidgetNotAvailablePlaceHolder';
import { parseAppInfo } from '../utils/CustomAppUtils';

const useStyles = makeStyles({
  root: {
    display: 'flex',
    alignItems: 'stretch',
    justifyContent: 'stretch',
    height: '100%',
  },
  customAppUiWrapper: {
    flexGrow: 1,
  },
});
export const ExternalAppForCustomDashboard: FC<Omit<ExternalAppWidget, 'height'>> = ({ appName, routeToWidget }) => {
  const [appInfo, setAppInfo] = useState<AppInfo>(null);
  const [isErrorGettingAppInfo, setIsErrorGettingAppInfo] = useState<boolean>(false);
  const classes = useStyles();

  useEffect(() => {
    const init = async () => {
      if (!!appName) {
        try {
          const {
            data: [info],
          } = await customAppService.getCustomAppByName(appName);
          const tpaInfo = parseAppInfo(info);
          const isTpaOffline = tpaStatusService.isTpaOffline(tpaInfo.status);
          isTpaOffline && setIsErrorGettingAppInfo(true);
          setAppInfo(tpaInfo);
        } catch (err) {
          console.warn(`Can't retrieve Custom App: ${appName} for custom dashboard`, err);
          setIsErrorGettingAppInfo(true);
        }
      } else {
        setIsErrorGettingAppInfo(true);
      }
    };

    init();
  }, [appName]);

  return (
    <div className={classes.root}>
      {!isErrorGettingAppInfo && appInfo?.uiUrl && (
        <div className={classes.customAppUiWrapper}>
          <CustomAppUI appInfo={appInfo} isWidget={true} appRoute={routeToWidget} />
        </div>
      )}
      {isErrorGettingAppInfo && <WidgetNotAvailablePlaceHolder appName={appInfo?.name || appName} />}
    </div>
  );
};
