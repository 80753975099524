import React, { FC, Fragment, useEffect } from 'react';
import { BigidGridRow, BigidGridProps, FetchDataFunction, BigidGridQueryComponents } from '@bigid-ui/grid';
import { BigidLayout, BigidLayoutConfig, LayoutContentType, parseFieldFiltersToSearchQuery } from '@bigid-ui/layout';
import { httpService } from '../../../services/httpService';
import { notificationService } from '../../../services/notificationService';
import { pageHeaderService } from '../../../../common/services/pageHeaderService';
import { queryService } from '../../../services/queryService';
import { $state, $stateParams } from '../../../services/angularServices';
import { CONFIG } from '../../../../config/common';
import { isPermitted } from '../../../services/userPermissionsService';
import { DATA_MINIMIZATION_PERMISSIONS } from '@bigid/permissions';
import { omit } from 'lodash';
import {
  ActionData,
  BigidFilter,
  BigidLoader,
  objectToQueryString,
  ToolbarActionType,
} from '@bigid-ui/components';
import makeStyles from '@mui/styles/makeStyles';
import {
  dataMinimizationObjectsDefaultColumns,
  getDataDeletionGlobalSettingsObjectsFilterConfig,
} from './DataDeletionGlobalSettingsGridConfiguration';
import { useUserPreferences } from '../../../components/hooks/useUserPrefrences';
import { actionTypes } from '../DataMinimizationObjects/DataMinimizationObjects';
import { DialogResponse, openEditDialog } from '../DataMinimizationObjects/Diaolgs/EditDialog';

const useStyles = makeStyles({
  gridWrapper: {
    width: '100%',
    display: 'flex',
    position: 'relative',
    height: '100%',
    maxHeight: '100%',
    overflow: 'hidden',
    paddingBottom: 10,
  },
  actionsButton: {
    minWidth: 180,
  },
});

export interface DataCatalogForDeletionModel extends BigidGridRow {
  _id?: string;
  objectName: string;
  containerName: string;
  source: string;
  fullyQualifiedName: string;
  type: string;
  decidedUser?: string;
  markedAs?: actionTypes;
  reason?: string;
}

interface DataCatalogAppAnnotationsResponse {
  data: { settings: DataCatalogForDeletionModel[]; count: number };
}

export const DataDeletionGlobalSettings: FC<DataCatalogForDeletionModel> = () => {
  const { id: requestId } = $stateParams;

  const { isReady, preferences, gridColumns, filterToolbarConfig, updatePreferences } = useUserPreferences({
    stateName: $state.$current.name,
    initialGridColumns: dataMinimizationObjectsDefaultColumns,
    getInitialFilterToolbarConfig: getDataDeletionGlobalSettingsObjectsFilterConfig,
  });

  useEffect(() => {
    pageHeaderService.setTitle({
      showBackButton: true,
      defaultFrom: { state: CONFIG.states.DATA_MINIMIZATION, params: {} },
      breadcrumbs: [
        { label: 'Data Deletion Requests', onClick: () => $state.go(CONFIG.states.DATA_MINIMIZATION) },
        { label: `Global Settings` },
      ],
    });
  }, [requestId]);

  const { actionsButton } = useStyles({});

  const gridConfig: BigidGridProps<DataCatalogForDeletionModel> = {
    customRowIdName: 'fullyQualifiedName',
    columns: gridColumns,
    showSortingControls: true,
    defaultSorting: preferences?.grid?.sort || [{ field: 'name', order: 'asc' }],
  };

  const buildFilterAndLookupFilterQuery = (queryComponents: BigidGridQueryComponents) => {
    const lookupFilter: BigidFilter = [];

    const hasLookup = queryComponents.filter.find(({ field }) => field === 'annotations.markedAs');

    const filter: BigidFilter = queryComponents.filter
      .map(filter => {
        if (hasLookup && (filter.field == 'scannerType' || filter.field == 'objectName')) {
          lookupFilter.push(filter);
          filter = null;
        }
        return filter;
      })
      .filter(filter => filter);

    let filterQuery = parseFieldFiltersToSearchQuery(filter);
    filterQuery = !hasLookup ? (filterQuery ? filterQuery + ' AND type = "rdb"' : 'type = "rdb"') : filterQuery;
    let lookupFilterQuery = parseFieldFiltersToSearchQuery(lookupFilter);
    lookupFilterQuery = hasLookup
      ? lookupFilterQuery
        ? lookupFilterQuery + ' AND type = "rdb"'
        : 'type = "rdb"'
      : undefined;

    const gridConfigQuery = queryService.getGridConfigQuery(omit(queryComponents, ['filter']));
    const query = objectToQueryString({
      filter: filterQuery,
      ...(lookupFilterQuery && { lookup_filter: lookupFilterQuery }),
    });

    return gridConfigQuery + '&' + query;
  };

  const fetchGridData: FetchDataFunction<DataCatalogForDeletionModel> = async queryComponents => {
    let data: DataCatalogForDeletionModel[] = [];
    let totalCount: number;
    try {
      const query = buildFilterAndLookupFilterQuery(queryComponents);
      const {
        data: { settings, count },
      } = await getAppAnnotationWithFilterRecords(query.length > 0 ? `&${query}` : '');

      totalCount = count;
      data = settings;
    } catch (e) {
      console.error(e);
      notificationService.error('Could not fetch data. See logs for more information');
    }
    return { data, totalCount };
  };

  const getAppAnnotationWithFilterRecords = (query?: string) => {
    return httpService
      .fetch<DataCatalogAppAnnotationsResponse>(`data-minimization/settings/objects?${query || ''}`)
      .then(({ data }) => data);
  };

  const executeActionForRequest = async (actionData: ActionData, actionType: actionTypes) => {
    let shouldExecuteAction = false;
    try {
      const { reason, shouldExecute }: DialogResponse = await openEditDialog();
      shouldExecuteAction = shouldExecute;
      if (shouldExecuteAction) {
        const { selectedRowIds, allSelected, filter } = actionData;
        const filterQuery: BigidGridQueryComponents = { filter };
        const query = buildFilterAndLookupFilterQuery(filterQuery);
        const selectedIds = selectedRowIds.join(',');
        const queryParams =
          !allSelected && selectedRowIds.length ? `filter=fullyQualifiedName IN (${selectedIds})` : query;

        await httpService.post(
          `data-minimization/settings/objects?${queryParams.length > 0 ? `&${queryParams}` : ''}`,
          {
            actionType,
            reason,
          },
        );

        /*queryParams =
          !allSelected && selectedRowIds.length
            ? `filter=fully_qualified_name IN (${selectedIds})&lookup_filter=type = "rdb"`
            : query;

        const {
          data: { settings },
        } = await getAppAnnotationWithFilterRecords(queryParams.length > 0 ? `&${queryParams}` : '');

        entityEventsEmitter.emit(EntityEvents.UPDATE, settings);*/
        notificationService.success(`Action saved successfully`);
      }
    } catch (e) {
      console.error(e);
      notificationService.error('Could not save action. See logs for more information');
    } finally {
      return Promise.resolve({ shouldGridReload: shouldExecuteAction, shouldClearSelection: shouldExecuteAction });
    }
  };

  const deleteAppAnnotation = async (actionData: ActionData) => {
    try {
      const { selectedRowIds, allSelected, filter } = actionData;
      const filterQuery: BigidGridQueryComponents = { filter };
      const query = buildFilterAndLookupFilterQuery(filterQuery);
      const selectedIds = selectedRowIds.join(',');
      const queryParams =
        !allSelected && selectedRowIds.length ? `filter=fullyQualifiedName IN (${selectedIds})` : query;
      await httpService.delete(`data-minimization/settings/objects?${queryParams.length > 0 ? `&${queryParams}` : ''}`);
      notificationService.success(`Action saved successfully`);
    } catch (e) {
      console.error(e);
      notificationService.error('Could not save action. See logs for more information');
    } finally {
      return Promise.resolve({ shouldGridReload: true, shouldClearSelection: true });
    }
  };

  const layoutConfig: BigidLayoutConfig = {
    content: {
      entityName: 'objects',
      contentTypes: [LayoutContentType.GRID],
      viewConfig: {
        filterToolbarConfig,
        onGridStateChange: ({ filter, ...gridState }) => updatePreferences({ filterState: { filter }, gridState }),
        fetchGridData,
        gridConfig,
      },
      toolbarActions: [
        {
          label: 'Assign An Action',
          type: ToolbarActionType.DROPDOWN,
          show: ({ selectedRowIds }) =>
            isPermitted(DATA_MINIMIZATION_PERMISSIONS.SETTINGS.name) && !!selectedRowIds.length,
          dropdownProps: {
            placeholder: 'More Actions',
            clearOnSelect: true,
            className: actionsButton,
            options: [
              {
                label: 'Delete',
                value: 'delete automatically',
                show: () => true,
                execute: async (actionData: ActionData) => executeActionForRequest(actionData, actionTypes.DELETE_AUTO),
              },
              {
                label: 'Mark For Delete Manually',
                value: 'delete manually',
                show: () => true,
                execute: async (actionData: ActionData) =>
                  executeActionForRequest(actionData, actionTypes.DELETE_MANUAL),
              },
              {
                label: 'Retain',
                value: 'retain',
                show: () => true,
                execute: async (actionData: ActionData) => executeActionForRequest(actionData, actionTypes.RETAINED),
              },
              {
                label: 'Clear Action',
                value: 'clear',
                show: () => true,
                execute: async (actionData: ActionData) => deleteAppAnnotation(actionData),
              },
            ],
          },
        },
      ],
    },
  };

  return (
    <Fragment>
      {!isReady && <BigidLoader />}
      {isReady && <BigidLayout config={layoutConfig} />}
    </Fragment>
  );
};
