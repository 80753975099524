import React, { FC } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import classNames from 'classnames';
import { BigidColors } from '@bigid-ui/components';

const useStyles = makeStyles({
  dotsWrapper: {
    position: 'absolute',
    bottom: '4px',
    left: '0',
    display: 'flex',
    width: '100%',
    justifyContent: 'center',
    paddingRight: '4px',
  },
  dot: {
    border: '1px solid #333333',
    background: BigidColors.white,
    margin: '5px',
    width: '8px',
    height: '8px',
    borderRadius: '100%',
    transition: 'background-color 0.3s linear',
  },
  dotActive: {
    background: '#333333',
  },
});

interface DashboardInsightsNavigationProps {
  currentViewPartIndex: number;
  viewPartsLength: number;
  setCurrentViewPartIndex: (index: number) => void;
}

const createDotsArrayByLength = (length: number, currentViewPartIndex: number) =>
  Array.from({ length }, (_, index) => ({
    partIndex: index + 1,
    isActive: index + 1 === currentViewPartIndex,
  }));

export const DashboardInsightsNavigation: FC<DashboardInsightsNavigationProps> = ({
  currentViewPartIndex,
  viewPartsLength,
  setCurrentViewPartIndex,
}) => {
  const { dot, dotsWrapper, dotActive } = useStyles({});

  return (
    <div className={dotsWrapper}>
      {viewPartsLength > 1
        ? createDotsArrayByLength(viewPartsLength, currentViewPartIndex).map(({ partIndex, isActive }) => (
            <span
              key={partIndex}
              role="button"
              onClick={() => {
                if (!isActive) {
                  setCurrentViewPartIndex(partIndex);
                }
              }}
              className={classNames(dot, isActive && dotActive)}
            />
          ))
        : null}
    </div>
  );
};
