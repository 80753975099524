import {
  AbstractQueryConditionNode,
  AbstractQueryTree,
  BigidValueType,
  parseAbstractQueryTreeIntoString,
  QueryConditionOperation,
  QueryExpressionOperator,
} from '@bigid/query-object-serialization';
import { ContextState, GridConfig } from '../../../../contexts/dataCatalogSearchResultsContext';
import { parseCatalogFilterOutputToQueryString } from '../../../../queryHelpers';

export const parseFqnsToFilterString = (fqns: string[], isExclude: boolean): string => {
  if (fqns.length === 0) {
    return '';
  }

  const node: AbstractQueryConditionNode = {
    operator: QueryExpressionOperator.UNDEFINED,
    name: 'fullyQualifiedName',
    bigidName: 'fullyQualifiedName',
    value: fqns,
    type: BigidValueType.STRING,
    arrayFieldName: null,
    operation: QueryConditionOperation.IN,
    isNegativeOperation: isExclude,
    isTagsNegativeOperation: false,
    isIgnored: false,
  };

  const abstractTree: AbstractQueryTree = {
    root: node,
  };

  return parseAbstractQueryTreeIntoString(abstractTree);
};

type GetActionFilterStringParams = Pick<GridConfig, 'isSelectAll' | 'selectedRowsIds' | 'unselectedRowsIds'> & {
  filter: string;
};

export const getActionFilterString = ({
  isSelectAll,
  selectedRowsIds,
  unselectedRowsIds,
  filter,
}: GetActionFilterStringParams): string => {
  const idsToMap = isSelectAll ? unselectedRowsIds : selectedRowsIds;
  const fqnsString = parseFqnsToFilterString(idsToMap, isSelectAll);
  const filterParts = [fqnsString, filter].filter(Boolean);

  return filterParts.length ? `${filterParts.join(' AND ')}` : '';
};
