import React, { useCallback, useEffect } from 'react';
import styled from '@emotion/styled';
import {
  BigidColorsV2,
  BigidDropdown,
  BigidErrorBoundary,
  BigidHeading3,
  BigidPageLayout,
  BigidPaper,
  EntityEvents,
  SecondaryButton,
  entityEventsEmitter,
} from '@bigid-ui/components';
import { BigidAddIcon, DataSourceIconByDsType } from '@bigid-ui/icons';
import { pageHeaderService } from '../../../common/services/pageHeaderService';
import { $state } from '../../services/angularServices';
import { CONFIG } from '../../../config/common';
import { VendorSummaryContentWithTabs } from './VendorsSummaryContentWithTabs';
import { VendorSummaryTabPaths, VendorsStatus } from './VendorsTypes';
import { VendorProjectContextProvider, useVendorProjectContext } from './VendorProjectContext';
import { getVendorProject, updateVendorProject } from './vendorsService';
import { notificationService } from '../../services/notificationService';
import { StatusToTrackingEventMapper, VendorsTrackingEvents, trackVendors } from './utils/analytics';
import { VENDORS_PERMISSIONS } from '@bigid/permissions';
import { isPermitted } from '../../services/userPermissionsService';
import { VendorEvents, vendorsEventEmitter } from './utils/VendorEvents';

export const ContentWrapper = styled.div`
  height: calc(100% - 116px);
  display: flex;
  max-width: 100vw;
`;

export const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const DropdownWrapper = styled.div`
  max-width: 140px;
`;

const IconWrapper = styled.div`
  display: flex;
  width: 32px;
  height: 32px;
  border-radius: 6px;
  border: 1px solid ${BigidColorsV2.gray[200]};
  background-color: ${BigidColorsV2.gray[50]};
  justify-content: center;
  align-items: center;
`;

const STATUS_OPTIONS = Object.values(VendorsStatus).map(status => ({
  id: status,
  value: status,
  displayValue: status,
}));

export const VendorSummaryLayout = () => {
  const { vendorProject, projectId, update, tab } = useVendorProjectContext();

  useEffect(() => {
    pageHeaderService.setIsHidden(true);
    return () => {
      pageHeaderService.setIsHidden(false);
    };
  }, []);

  const openEditVendorDialog = useCallback(async () => {
    trackVendors(VendorsTrackingEvents.EDIT_VENDOR_DIALOG_OPEN_CLICK);
    vendorsEventEmitter.emit(VendorEvents.OPEN_EDIT_VENDOR_DIALOG);
  }, []);

  const openVendorImportFileDialog = useCallback(async () => {
    trackVendors(VendorsTrackingEvents.IMPORT_ATTACHED_FILE_DIALOG_OPEN_CLICK);
    vendorsEventEmitter.emit(VendorEvents.OPEN_ATTACHED_FILE_IMPORT_DIALOG);
  }, []);

  const bigidPageLayoutActionDecider = useCallback(
    (activeTab: VendorSummaryTabPaths) => {
      const actionArray = [
        <DropdownWrapper key="1">
          <BigidDropdown
            options={STATUS_OPTIONS.filter(option => option.value !== vendorProject?.status)}
            onSelect={async ([option]) => {
              await changeVendorProjectStatus(projectId, option?.value);
              update();
            }}
            value={STATUS_OPTIONS.filter(option => option.value === vendorProject?.status)}
            placeholder="Select"
            listWidth={140}
            isDisabled={!isPermitted(VENDORS_PERMISSIONS.VENDOR_EDIT.name)}
          />
        </DropdownWrapper>,
      ];
      switch (activeTab) {
        case VendorSummaryTabPaths.SUMMARY:
          return actionArray.concat(
            isPermitted(VENDORS_PERMISSIONS.VENDOR_EDIT.name) && (
              <SecondaryButton
                key="2"
                onClick={openEditVendorDialog}
                size="medium"
                text="Edit Details"
                bi={{ disabled: true }}
              />
            ),
          );
        case VendorSummaryTabPaths.FILES:
          return actionArray.concat(
            isPermitted(VENDORS_PERMISSIONS.VENDOR_EDIT.name) && (
              <SecondaryButton
                key="3"
                onClick={openVendorImportFileDialog}
                startIcon={<BigidAddIcon />}
                size="medium"
                text="New File"
                bi={{ disabled: true }}
              />
            ),
          );
        case VendorSummaryTabPaths.LOGS:
          return actionArray;
        default:
          return [];
      }
    },
    [vendorProject?.status, projectId, update, openEditVendorDialog, openVendorImportFileDialog],
  );

  return (
    <BigidPageLayout
      actions={bigidPageLayoutActionDecider(tab).filter(Boolean)}
      breadcrumbs={{
        breadcrumbs: [
          {
            label: 'Vendors',
            onClick: () => {
              $state.go(CONFIG.states.VENDORS);
            },
          },
          {
            label: vendorProject?.name,
          },
        ],
      }}
      dataAid="vendors"
      details={
        <TitleWrapper>
          <IconWrapper>
            <DataSourceIconByDsType dataSourceType={vendorProject?.vendorType} width={20} />
          </IconWrapper>
          <BigidHeading3>{vendorProject?.name}</BigidHeading3>
        </TitleWrapper>
      }
      pageBackgroundColor="transparent"
      showHeaderBackground={false}
    >
      <ContentWrapper>
        <BigidPaper>
          <VendorSummaryContentWithTabs />
        </BigidPaper>
      </ContentWrapper>
    </BigidPageLayout>
  );
};

export const VendorSummary = () => (
  <BigidErrorBoundary>
    <VendorProjectContextProvider>
      <VendorSummaryLayout />
    </VendorProjectContextProvider>
  </BigidErrorBoundary>
);

const changeVendorProjectStatus = async (projectId: string, newStatus: VendorsStatus) => {
  try {
    trackVendors(StatusToTrackingEventMapper[newStatus]);
    await updateVendorProject(projectId, { status: newStatus });
    entityEventsEmitter.emit(EntityEvents.RELOAD);
  } catch (err) {
    notificationService.error(`Failed to update Vendor project status`);
    console.error(`Failed to update Vendor project status: ${JSON.stringify(err?.response)}`);
  }
};

export const fetchVendorProject = async (projectId: string) => {
  try {
    const vendorProject = await getVendorProject(projectId);
    return vendorProject;
  } catch (err) {
    notificationService.error(`Failed to fetch Vendor project`);
    console.error(`Failed to fetch Vendor project: ${JSON.stringify(err?.response)}`);
  }
};
