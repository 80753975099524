import React, { FC, useContext, useState } from 'react';
import { BigidFormField, StringOrBooleanMap } from '@bigid-ui/components';
import { AutoDiscoveryPresetConfigTabsEnum, AutoDiscoveryWizardContext } from '../../autoDiscoveryWizardContext';
import { AutoDiscoverWizardAccordionItem } from '../../autoDiscoveryComponents/AutoDiscoverWizardAccordionItem';
import { AutoDiscoveryPresetDetailsFieldsWrapper, AutoDiscoveryPresetDetailsTabStyled } from '../styled';
import {
  DEFAULT_OPENED_SECTION_IN_DETAILS,
} from '../../../constants';
import { getFieldConditionState } from '../../autoDiscoveryWizardFormUtils';

export interface AutoDiscoveryPresetDetailsTabProps {
  values?: Record<string, any>;
  fieldsBySection: Record<string, BigidFormField[]>;
  renderField: (fieldName: string, additionalProps?: Record<string, any>) => any;
  errors: StringOrBooleanMap;
}

export const AutoDiscoveryPresetDetailsTab: FC<AutoDiscoveryPresetDetailsTabProps> = ({
  values,
  fieldsBySection,
  renderField,
  errors,
}) => {
  const { discoveryConfigData, discoveryConfigDataRef } = useContext(AutoDiscoveryWizardContext);
  const [connectionOpened, setConnectionOpened] = useState(true);

  return (
    <AutoDiscoveryPresetDetailsTabStyled
      data-aid={'AutoDiscoveryPresetDetailsTabContent'}
      visible={discoveryConfigData.tab === AutoDiscoveryPresetConfigTabsEnum.discovery}
    >
      {Object.entries(fieldsBySection).map(([section, sectionFields]) => {
        const controlProps =
          section === DEFAULT_OPENED_SECTION_IN_DETAILS
            ? { isExpanded: connectionOpened, onChange: () => setConnectionOpened(current => !current) }
            : {};
        return (
          <AutoDiscoverWizardAccordionItem key={section} title={section} {...controlProps}>
            <AutoDiscoveryPresetDetailsFieldsWrapper>
              {sectionFields.map(field => {
                const { disabled, isVisible } = getFieldConditionState(field, values, discoveryConfigDataRef);

                return (
                  isVisible &&
                  renderField(field.name, {
                    disabled,
                    error: errors?.[field.name],
                    errorIsShown: !!errors?.[field.name],
                  })
                );
              })}
            </AutoDiscoveryPresetDetailsFieldsWrapper>
          </AutoDiscoverWizardAccordionItem>
        );
      })}
    </AutoDiscoveryPresetDetailsTabStyled>
  );
};
