import React, { FC, useCallback, useEffect, useRef, useState } from 'react';
import { EventEmitterDeregistrator } from '@bigid-ui/utils';
import { SSE_EVENTS, SSEDataMessage, subscribeToRepeatedSSEEventById } from '../../services/sseService';
import { getPreviousQuestions, resetBigchatStorage, saveMessages } from '../../services/bigchat/bigchatStorageService';
import { AnswerWidget, BIGCHAT_TRACKING_EVENTS, BIGCHAT_WIDGETS, EVENT_STATUS, PreviousQuestion } from './BigChatTypes';
import { checkIsFollowUpQuestion, sendUserQuestion } from '../../services/bigchat/bigchatService';
import { getActualQuestion, isFollowupCandidate, updatedPreviousQuestions } from './BigChatUtils';
import { IMessage } from 'react-chatbot-kit/src/interfaces/IMessages';
import { createCustomMessage } from 'react-chatbot-kit';
import { notificationService } from '../../services/notificationService';
import { v4 as uuid } from 'uuid';
import { getInitialMessage } from './config';
import { SELECTOR } from './BigChatStyles';
import { getFixedT } from './translations';
import { analyticsService } from '../../services/analyticsService';

const DEFAULT_STATE_ANSWER_WIDGET = {
  shouldTriggerWidget: false,
};
export const ActionProvider: FC<any> = ({ createChatBotMessage, setState, children }) => {
  const [answerWidgetDetails, setAnswerWidgetDetails] = useState<AnswerWidget>(DEFAULT_STATE_ANSWER_WIDGET);
  const [uniqueBroadcastEvent, setUniqueBroadcastEvent] = useState<string>('');
  const broadcastEventChunkIds = useRef<string[]>([]);

  const shouldAddBroadcastEventChunkId = (chunkIds?: string[], currentChunkId?: string): boolean => {
    return chunkIds?.every?.(chunkId => chunkId !== currentChunkId);
  };

  const updateMessagesByEventState = useCallback(
    (data: SSEDataMessage) => {
      const {
        results: [{ lines, status, id }],
      } = data;
      if (shouldAddBroadcastEventChunkId(broadcastEventChunkIds.current, id)) {
        switch (status) {
          case EVENT_STATUS.STARTED:
            const emptyMessage = createChatBotMessage('');
            addMessageToState({ ...emptyMessage, loading: false });
            break;
          case EVENT_STATUS.IN_PROGRESS:
            if (lines) {
              updateAnswerMessageInState(lines);
            }
            break;
          case EVENT_STATUS.END:
            const messageOptionsPayload = { question: answerWidgetDetails.question };
            updateAnswerMessageInState('', messageOptionsPayload, true);
            setAnswerWidgetDetails(prev => ({ ...prev, shouldTriggerWidget: true }));
            break;
        }
        broadcastEventChunkIds.current.push(id);
      }
    },
    [answerWidgetDetails],
  );

  useEffect(() => {
    if (uniqueBroadcastEvent) {
      const deregisterSSE: EventEmitterDeregistrator = subscribeToRepeatedSSEEventById(
        uniqueBroadcastEvent,
        updateMessagesByEventState,
      );
      return () => deregisterSSE?.();
    }
  }, [uniqueBroadcastEvent]);

  useEffect(() => {
    if (answerWidgetDetails.shouldTriggerWidget && answerWidgetDetails.links) {
      const linksMessage = createCustomMessage('', BIGCHAT_WIDGETS.LINKS, {
        payload: { resourcesLinks: answerWidgetDetails.links },
      });
      addMessageToState(linksMessage, true);
      resetMessageAndStates();
    }
  }, [answerWidgetDetails]);

  const addMessageToState = (message: IMessage, saveMessagesToStorage = false): void => {
    setState((prevState: any) => {
      const currentMessages = [...prevState.messages, message];
      if (saveMessagesToStorage) {
        saveMessages([...currentMessages]);
      }
      return {
        ...prevState,
        messages: [...currentMessages],
      };
    });
  };

  const resetMessageAndStates = (): void => {
    const resetMessage = createCustomMessage('', BIGCHAT_WIDGETS.RESET, {});
    addMessageToState(resetMessage, true);
    initialSetup(true, false);
  };

  const initMessages = (): void => {
    setState((prevState: any) => {
      return { ...prevState, messages: [getInitialMessage()] };
    });
    initialSetup(true, false);
    resetBigchatStorage();
  };

  const updateAnswerMessageInState = (
    lines: string,
    options: Pick<AnswerWidget, 'question'> = null,
    saveMessagesToStorage = false,
  ): void => {
    setState((prevState: any) => {
      const lastMessageIndex = prevState.messages.length - 1;
      const messageToUpdate = prevState.messages[lastMessageIndex];
      const currentMessages = prevState.messages?.slice(0, -1);

      if (saveMessagesToStorage) {
        const fullAnswer = messageToUpdate.message;
        messageToUpdate.widget = BIGCHAT_WIDGETS.FEEDBACK;
        messageToUpdate.payload = {
          ...options,
          answer: fullAnswer,
          approved: false,
          rejected: false,
          index: lastMessageIndex,
        };
        saveMessages([...currentMessages, messageToUpdate]);
      } else {
        messageToUpdate.message += lines;
      }
      return {
        ...prevState,
        messages: [...currentMessages, messageToUpdate],
      };
    });
  };

  const initialSetup = (shouldSetDisabledStateForButtons = false, newState = false): void => {
    setUniqueBroadcastEvent('');
    broadcastEventChunkIds.current = [];
    setAnswerWidgetDetails(DEFAULT_STATE_ANSWER_WIDGET);

    if (shouldSetDisabledStateForButtons) {
      const sendButtonElement = document.querySelector(SELECTOR) as HTMLButtonElement;
      if (sendButtonElement) {
        sendButtonElement.disabled = newState;
      }
      const setStateDisableOpenChat = children?.props?.children?.props?.state?.setDisableOpenChat;
      setStateDisableOpenChat?.(newState);
    }
  };
  const verifyFollowUpQuestion = async (message: string, previousQuestions: PreviousQuestion[]): Promise<boolean> => {
    if (isFollowupCandidate(previousQuestions)) {
      const questionsArray = previousQuestions.map(prevQuestion => {
        return prevQuestion.question;
      });
      const result = await checkIsFollowUpQuestion({
        question: message,
        previousQuestions: questionsArray,
      });
      return result?.isFollowUp;
    } else {
      return false;
    }
  };
  const handleUserAskQuestion = async (message: string): Promise<void> => {
    try {
      const previousQuestions: PreviousQuestion[] = getPreviousQuestions();
      const isFollowUpQuestion = await verifyFollowUpQuestion(message, previousQuestions);
      const actualQuestion = getActualQuestion(message, previousQuestions, isFollowUpQuestion);
      const currentUniqueBroadcastEvent = `${SSE_EVENTS.BIGCHAT_ANSWER_QUESTION_STREAM_EVENT}_${uuid()}`;
      setAnswerWidgetDetails(prev => ({ ...prev, question: actualQuestion }));
      setUniqueBroadcastEvent(currentUniqueBroadcastEvent);

      const { links, context } = await sendUserQuestion({
        question: actualQuestion,
        uniqueBroadcastEvent: currentUniqueBroadcastEvent,
      });
      analyticsService.trackManualEvent(BIGCHAT_TRACKING_EVENTS.BIGCHAT_SEND_QUESTION_COMPLETED);
      const questionDetailsToUpdate = {
        question: message,
        shortAnswer: context ? context : '',
        updatedAt: Date.now(),
      };

      setAnswerWidgetDetails(prev => {
        if (prev?.question) {
          updatedPreviousQuestions(previousQuestions, isFollowUpQuestion, questionDetailsToUpdate);
        }
        return { ...prev, links: links };
      });
    } catch (error) {
      console.error(error);
      const errorMessage = error?.response?.data?.message || error.message;
      notificationService.error(`${getFixedT('chatbot')('errorMessage')} ${errorMessage}`);
      analyticsService.trackManualEvent(BIGCHAT_TRACKING_EVENTS.BIGCHAT_SEND_QUESTION_FAILED);
      resetMessageAndStates();
    }
  };

  return (
    <div>
      {React.Children.map(children, child => {
        return React.cloneElement(child, {
          actions: { handleUserAskQuestion, initMessages, initialSetup },
        });
      })}
    </div>
  );
};
