import { module } from 'angular';
import { $injector } from 'ngimport';
const app = module('app');

export let applicationSetupService;

app.factory('applicationSetupService', [
  '$http',
  'appSettings',
  function ($http, appSettings) {
    return {
      getApplicationItems: () =>
        $http.get(`${appSettings.serverPath}/${appSettings.version}/applications`).then(response => response.data),

      getAllApplications: () =>
        $http
          .get(`${appSettings.serverPath}/${appSettings.version}/systems/applications`)
          .then(response => response.data),

      createApplicationItem: data =>
        $http
          .post(`${appSettings.serverPath}/${appSettings.version}/applications`, data)
          .then(response => response.data),

      updateApplicationItem: (id, data) =>
        $http
          .put(`${appSettings.serverPath}/${appSettings.version}/applications/${id}`, data)
          .then(response => response.data),

      deleteApplicationItem: id =>
        $http
          .delete(`${appSettings.serverPath}/${appSettings.version}/applications/${id}`)
          .then(response => response.data),

      assignApplication: data =>
        $http
          .post(`${appSettings.serverPath}/${appSettings.version}/activities/assign-application`, data)
          .then(response => response.data),

      getApplicationDetailsByName: id =>
        $http
          .get(`${appSettings.serverPath}/${appSettings.version}/systems/applications/${id}`)
          .then(response => response.data),

      getActivities: (queryString = '') =>
        $http
          .get(`${appSettings.serverPath}/${appSettings.version}/activities${queryString}`)
          .then(response => response.data),
    };
  },
]);

app.run(() => {
  applicationSetupService = $injector.get('applicationSetupService');
});
