import React, { FC, useEffect, useMemo, useState } from 'react';
import { useUserPreferences } from '../../../components/hooks/useUserPrefrences';
import { $state } from '../../../services/angularServices';
import { BigidGridQueryComponents, BigidGridWithToolbar, BigidGridWithToolbarProps } from '@bigid-ui/grid';
import { ServiceConfigurationsModel } from './ServicesConfiguration';
import { BigidLoader, BigidPaper } from '@bigid-ui/components';
import makeStyles from '@mui/styles/makeStyles';
import { groupServicesConfigurationData, localSortAndFilterGridData } from './servicesConfigurationService';
import {
  getServiceConfigurationFilterConfig,
  getServiceConfigurationGridColumns,
} from './servicesConfigurationGridConfig';

const useStyles = makeStyles({
  gridWrapper: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flex: '1 1 auto',
    flexFlow: 'column nowrap',
    overflow: 'hidden',
    paddingBottom: 10,
  },
});

interface ServicesConfigurationFlatGridProps {
  rows: ServiceConfigurationsModel[];
  onEditConfiguration: (configuration: ServiceConfigurationsModel) => void;
  isGrouped: boolean;
}

export const ServicesConfigurationGrid: FC<ServicesConfigurationFlatGridProps> = ({
  rows,
  onEditConfiguration,
  isGrouped,
}) => {
  const classes = useStyles({});
  const stateName = `${$state.$current.name}.services-configurations`;
  const [groupedRows, setGroupedRows] = useState<ServiceConfigurationsModel[]>();

  useEffect(() => {
    if (isGrouped) {
      const groupedData = groupServicesConfigurationData(rows);
      setGroupedRows(groupedData);
    }
  }, [rows, isGrouped]);

  const initialGridColumns = useMemo(() => {
    return getServiceConfigurationGridColumns(configurationToEdit => onEditConfiguration(configurationToEdit));
  }, [onEditConfiguration]);

  const {
    isReady,
    filterToolbarConfig,
    gridColumns: columns,
  } = useUserPreferences({
    stateName,
    initialGridColumns,
    getInitialFilterToolbarConfig: getServiceConfigurationFilterConfig,
  });

  const gridWithToolbarConfig: BigidGridWithToolbarProps<ServiceConfigurationsModel> = useMemo(() => {
    return {
      pageSize: 100,
      columns,
      entityName: 'configurations',
      showSelectionCheckboxes: true,
      showSortingControls: true,
      hideColumnChooser: true,
      defaultSorting: [{ field: 'name', order: 'asc' }],
      filterToolbarConfig,
      fetchData: async (queryComponents: BigidGridQueryComponents) => {
        const rowsToFilter = isGrouped ? groupedRows : rows;
        return localSortAndFilterGridData(rowsToFilter, queryComponents);
      },
    };
  }, [columns, filterToolbarConfig, groupedRows, isGrouped, rows]);

  return (
    <div className={classes.gridWrapper}>
      <BigidPaper>
        {!isReady && <BigidLoader />}
        {isReady && <BigidGridWithToolbar {...gridWithToolbarConfig} />}
      </BigidPaper>
    </div>
  );
};
