import React, { FC, memo, ChangeEvent, useRef } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { BigidTextField, BigidHeading6 } from '@bigid-ui/components';
import { MetadataSearchFilterComponentBaseProps } from '../../MetadataSearchFiltersTypes';
import { debounce } from 'lodash';

export type MetadataSearchFilterStringProps = MetadataSearchFilterComponentBaseProps;

const useStyles = makeStyles({
  root: {
    width: '100%',
  },
  label: {
    marginBottom: '8px',
  },
  control: {
    padding: '8px',
  },
});

export const MetadataSearchFilterString: FC<MetadataSearchFilterStringProps> = memo(
  ({ dataAid, config, value, onFilterChange, isFiltersFetching, focused }) => {
    const classes = useStyles();
    const { fieldName, fieldType, displayName } = config;
    const label = displayName || fieldName;
    const inputRef = useRef<HTMLTextAreaElement>();

    const handleOnChange = ({ target }: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void => {
      onFilterChange({
        field: fieldName,
        value: target.value,
        operator: 'contains',
        fieldType,
        fieldDisplayName: label,
      });
    };

    const handleOnChangeDebounced = debounce(handleOnChange, 500);

    if (!isFiltersFetching && focused) {
      setTimeout(() => {
        inputRef.current?.focus();
      }, 500);
    }

    return (
      <div className={classes.root}>
        <div className={classes.control}>
          <BigidHeading6 data-aid={`${dataAid}-label`} className={classes.label}>
            {label}
          </BigidHeading6>
          <BigidTextField
            autoFocus={focused}
            inputRef={inputRef}
            defaultValue={value ? value.value.toString() : ''}
            dataAid={`${dataAid}-field`}
            placeholder={`Type ${label}`}
            onChange={handleOnChangeDebounced}
            disabled={isFiltersFetching}
          />
        </div>
      </div>
    );
  },
);

MetadataSearchFilterString.displayName = 'MetadataSearchFilterString';
