import './identityConnections.component.scss';
import template from './identityConnections.component.html';
import { module } from 'angular';
import { CORRELATION_SET_PERMISSIONS } from '@bigid/permissions';
import { isPermitted } from '../../react/services/userPermissionsService';
import { getApplicationPreference } from '../../react/services/appPreferencesService';
const app = module('app');

app.component('identitycommon', {
  template,
  controllerAs: 'identityConnectionsModel',
  controller: function (
    $http,
    appSettings,
    identityConnectionsService,
    $scope,
    $state,
    DeleteConfirmation,
    notificationService,
    uiGridConstants,
  ) {
    'ngInject';

    const identityConnectionsModel = this;

    identityConnectionsModel.idsorSelection = [];
    $scope.entitySourceExportEnabled = false;

    $scope.allIdSelected = false;

    identityConnectionsModel.$onInit = () => {
      $scope.isCreatePermitted = isPermitted(CORRELATION_SET_PERMISSIONS.CREATE.name);
      $scope.isEditPermitted = isPermitted(CORRELATION_SET_PERMISSIONS.EDIT.name);
      $scope.isDeletePermitted = isPermitted(CORRELATION_SET_PERMISSIONS.DELETE.name);
      $scope.isReadPermitted = isPermitted(CORRELATION_SET_PERMISSIONS.READ.name);

      $scope.entitySourceExportEnabled = getApplicationPreference('ENTITY_SOURCE_EXPORT_ENABLED');
      getIdentityConnectionsData();
      prepareIdentityConnectionsModel();
    };

    const getIdentityConnectionsData = filterValue => {
      const archiveFilter = identityConnectionsModel.useArchiveFilter || false;
      if (identityConnectionsModel.idsorlist) {
        identityConnectionsModel.idsorSelection = identityConnectionsModel.idsorlist;
      }

      identityConnectionsService.getIdentityConnectionsData(filterValue, archiveFilter).then(result => {
        const results = result.id_connections;

        $scope.identityConnectionsModel.data = results.map(item => {
          const { name } = item;
          if (identityConnectionsModel.idsorSelection.includes(name)) {
            return { ...item, selection: true };
          }
          return item;
        });
      });
    };

    const prepareIdentityConnectionsModel = () => {
      const availableTypeOptions = [
        { value: 'mysql', label: 'MySQL' },
        { value: 'postgres', label: 'Postgres' },
        { value: 'ms-sql', label: 'MS-SQL' },
        { value: 'oracle', label: 'Oracle' },
        { value: 'salesforce', label: 'Salesforce' },
      ];

      const availableEnableOptions = [
        { value: 'yes', label: 'Yes' },
        { value: 'no', label: 'No' },
      ];

      const is_success = [
        { value: 'true', label: 'Succeed' },
        { value: 'false', label: 'Failed' },
      ];

      const EDIT_ENTITY_SOURCE_TEMPLATE = `
        <div
          class="img-button img-edit-button tooltip"
          ng-class="{bottom: currRowIndex == 0, top: currRowIndex > 0}"
          ng-click="grid.appScope.edit(row.entity.name)"
          ng-if="grid.appScope.isEditPermitted"
        >
          <div class="tooltip-container">
            <span class="tooltip-text">Edit</span>
          </div>
        </div>
      `;

      const DELETE_ENTITY_SOURCE_TEMPLATE = `
        <div
          class="img-button img-delete-button tooltip"
          ng-class="{bottom: currRowIndex == 0, top: currRowIndex > 0}"
          ng-click="grid.appScope.delete(row.entity.name)"
          ng-if="grid.appScope.isDeletePermitted"
        >
          <div class="tooltip-container">
            <span class="tooltip-text">Delete</span>
          </div>
        </div>
      `;

      const COPY_ENTITY_SOURCE_TEMPLATE = `
        <div
          class="img-button img-duplicate-button tooltip"
          ng-class="{bottom: currRowIndex == 0, top: currRowIndex > 0}"
          ng-click="grid.appScope.copy(row.entity)"
          ng-if="grid.appScope.isCreatePermitted"
        >
          <div class="tooltip-container">
            <span class="tooltip-text">Duplicate</span>
          </div>
        </div>
      `;

      const getNameCellTemplate = () => {
        return `<div
                  title="{{COL_FIELD}}"
                  class="cell-template-overflow"
                >
                  ${
                    $scope.isReadPermitted
                      ? '<a ui-sref="newEntityConnection({id: row.entity.name})">{{row.entity.name}}</a>'
                      : '<span>{{row.entity.name}}</span>'
                  }
                </div>`;
      };

      const getActionsCellTemplate = () => {
        const hasNoActions = !$scope.isEditPermitted && !$scope.isDeletePermitted && !$scope.isCreatePermitted;
        return `<div class="actions-container" ng-if="${!hasNoActions}">
                  <div class="three-points"></div>
                  <div
                    class="img-container"
                    ng-init="currRowIndex = grid.renderContainers.body.visibleRowCache.indexOf(row)">
                    ${EDIT_ENTITY_SOURCE_TEMPLATE}
                    ${DELETE_ENTITY_SOURCE_TEMPLATE}
                    ${COPY_ENTITY_SOURCE_TEMPLATE}
                  <div>
                <div>`;
      };

      $scope.identityConnectionsModel = {
        enableSorting: true,
        enableFiltering: true,
        enablePaginationControls: false,
        enableCellEdit: false,
      };

      $scope.identityConnectionsModel.columnDefs = [
        {
          name: 'Name',
          width: identityConnectionsModel.displaytype == 'extended' ? '20%' : '70%',
          field: 'name',
          cellTemplate: getNameCellTemplate(),
          filter: {
            condition: uiGridConstants.filter.CONTAINS,
            type: uiGridConstants.filter.INPUT,
            flags: { caseSensitive: false },
          },
        },
        {
          name: 'Type',
          field: 'type',
          visible: identityConnectionsModel.displaytype == 'extended' ? false : true,
          width: identityConnectionsModel.displaytype == 'extended' ? '8%' : '20%',
          filter: { type: uiGridConstants.filter.SELECT, selectOptions: availableTypeOptions },
          cellTemplate: '<div title="{{COL_FIELD}}" class="cell-template-overflow">{{row.entity.type}}</div>',
        },
      ];

      if (identityConnectionsModel.displaytype == 'compact') {
        const selectColumn = {
          cellClass: 'grid-align',
          name: 'Select',
          field: 'selection',
          enableFiltering: false,
          enableCellEdit: true,
          width: 50,
          visible: true,
          cellTooltip: row => 'Select columns',
          headerCellTemplate:
            '<div style="height: auto" class="ui-grid-cell-contents">Select</div>' +
            '<input style="z-index: 99999; margin-left: 24px" title="Select all columns" type="checkbox" ng-change="grid.appScope.selectionChanged()" ng-model="$scope.allIdSelected">',
          cellTemplate:
            '<input style="z-index: 99999;" title="Select columns" type="checkbox" ng-change="grid.appScope.selectionChanged(row.entity)" ng-model="row.entity.selection" data-aid="correlation-sets-{{row.entity.name}}">',
        };

        $scope.identityConnectionsModel.columnDefs = [selectColumn, ...$scope.identityConnectionsModel.columnDefs];
      } else if (identityConnectionsModel.displaytype == 'extended') {
        $scope.identityConnectionsModel.columnDefs.push(
          {
            name: 'Origin',
            displayName: 'Origin',
            field: 'origin',
            visible: false,
            filter: {
              condition: uiGridConstants.filter.CONTAINS,
              type: uiGridConstants.filter.INPUT,
              flags: { caseSensitive: false },
            },
            cellTemplate: '<div title="{{COL_FIELD}}" class="cell-template-overflow">{{row.entity.origin}}</div>',
          },
          {
            name: 'DS Name',
            displayName: 'DS Name',
            field: 'dsConnection',
            filter: {
              condition: uiGridConstants.filter.CONTAINS,
              type: uiGridConstants.filter.INPUT,
              flags: { caseSensitive: false },
            },
            cellTemplate: '<div title="{{COL_FIELD}}" class="cell-template-overflow">{{row.entity.dsConnection}}</div>',
          },
          {
            name: 'DB Name',
            displayName: 'DB Name',
            field: 'db_name',
            filter: {
              condition: uiGridConstants.filter.CONTAINS,
              type: uiGridConstants.filter.INPUT,
              flags: { caseSensitive: false },
            },
            cellTemplate: '<div title="{{COL_FIELD}}" class="cell-template-overflow">{{row.entity.db_name}}</div>',
          },
          {
            name: 'Table Name',
            field: 'table_name',
            filter: {
              condition: uiGridConstants.filter.CONTAINS,
              type: uiGridConstants.filter.INPUT,
              flags: { caseSensitive: false },
            },
            cellTemplate: '<div title="{{COL_FIELD}}" class="cell-template-overflow">{{row.entity.table_name}}</div>',
          },
          {
            name: 'Total entities',
            displayName: 'Total Entities',
            field: 'total_identities',
            filter: {
              condition: uiGridConstants.filter.CONTAINS,
              type: uiGridConstants.filter.INPUT,
              flags: { caseSensitive: false },
            },
            type: 'number',
            cellTemplate:
              '<div title="{{COL_FIELD}}" class="cell-template-overflow">{{row.entity.total_identities}}</div>',
          },
          {
            name: 'Location',
            field: 'location',
            filter: {
              condition: uiGridConstants.filter.CONTAINS,
              type: uiGridConstants.filter.INPUT,
              flags: { caseSensitive: false },
            },
            cellTemplate: '<div title="{{COL_FIELD}}" class="cell-template-overflow">{{row.entity.location}}</div>',
          },
          {
            name: 'Last Scan',
            width: '16%',
            field: 'connectionStatusScan.is_success',
            enableSorting: false,
            enableFiltering: true,
            enableCellEdit: false,
            cellTemplate:
              '<div class="ui-grid-contents last-connection-col">' +
              '<div ng-if="row.entity.connectionStatusScan.is_success === true" class="icon-success"></div>' +
              '<div ng-if="row.entity.connectionStatusScan.is_success === false" class="icon-failed"></div>' +
              '<div>{{row.entity.connectionStatusScan.last_connection | date: "dd.MM.yyyy, HH:mm:ss"}}</div></div>',
            filter: {
              type: uiGridConstants.filter.SELECT,
              selectOptions: is_success,
              condition: uiGridConstants.filter.CONTAINS,
            },
          },
          {
            name: 'Last Test',
            width: '16%',
            field: 'connectionStatusTest.is_success',
            enableSorting: false,
            enableFiltering: true,
            enableCellEdit: false,
            cellTemplate:
              '<div class="ui-grid-contents last-connection-col">' +
              '<div ng-if="row.entity.connectionStatusTest.is_success === true" class="icon-success"></div>' +
              '<div ng-if="row.entity.connectionStatusTest.is_success === false" class="icon-failed"></div>' +
              '<div>{{row.entity.connectionStatusTest.last_connection | date: "dd.MM.yyyy, HH:mm:ss"}}</div></div>',
            filter: { type: uiGridConstants.filter.SELECT, selectOptions: is_success },
          },
          {
            name: 'Enabled',
            field: 'enabled',
            displayName: 'Enabled',
            enableCellEdit: false,
            cellTemplate: `<div class="ui-grid-contents" style="text-align: left;"><input style="z-index: 99999;"
              ng-attr-title="{{row.entity.enabled ? \'Disable\' : \'Enable\'}}" type="checkbox"
              ng-change="grid.appScope.enabledChange(row.entity)"
              ng-disabled="!grid.appScope.isEditPermitted"
              ng-model="row.entity.enabled" ng-true-value="\'yes\'" ng-false-value="\'no\'"></div>`,
            width: '6%',
            filter: { type: uiGridConstants.filter.SELECT, selectOptions: availableEnableOptions },
          },
          {
            name: 'Actions',
            enableFiltering: false,
            enableSorting: false,
            field: '',
            width: '8%',
            headerCellTemplate: '<div></div>',
            cellTemplate: getActionsCellTemplate(),
          },
        );
      }

      $scope.identityConnectionsModel.onRegisterApi = gridApi => {
        $scope.gridApi = gridApi;
        gridApi.edit.on.afterCellEdit($scope, (rowEntity, colDef, newValue, oldValue) => {
          if (colDef.name === 'Enabled') {
            updateIdentityConnections(rowEntity, newValue);
          }
          $scope.$apply();
        });
      };
    };

    $scope.enabledChange = entity => {
      updateIdentityConnections(entity, entity.enabled);
    };

    const updateIdentityConnections = (rowEntity, newValue) => {
      $scope.id_connection = {};
      $scope.id_connection.id_connection = {};
      $scope.id_connection.id_connection.enabled = newValue;

      identityConnectionsService.updateIdentityConnectionsDataByID($scope.id_connection, rowEntity.id);
    };

    $scope.selectionChanged = entity => {
      if (!entity) {
        $scope.allIdSelected = !$scope.allIdSelected;
        identityConnectionsModel.idsorSelection = [];
        $scope.identityConnectionsModel.data.forEach(entity => {
          if ($scope.allIdSelected) {
            identityConnectionsModel.idsorSelection.push(entity.name);
            entity.selection = true;
          } else {
            entity.selection = false;
          }
        });
      } else {
        if (!identityConnectionsModel.idsorSelection) {
          identityConnectionsModel.idsorSelection = [];
        }
        const idsorPosition = identityConnectionsModel.idsorSelection.indexOf(entity.name);
        if (idsorPosition > -1) {
          identityConnectionsModel.idsorSelection.splice(idsorPosition, 1);
        } else {
          identityConnectionsModel.idsorSelection.push(entity.name);
        }
      }
      identityConnectionsModel.onselect({ value: identityConnectionsModel.idsorSelection });
    };

    $scope.edit = name => {
      $state.go('newEntityConnection', { id: name });
    };

    $scope.delete = name => {
      const modalOptions = {
        closeButtonText: 'Close',
        actionButtonText: 'Delete',
        headerText: 'Delete Correlation Learning Set',
        bodyText: 'Are you sure you want to delete ' + name + '?',
      };

      DeleteConfirmation.showModal({}, modalOptions).then(result => {
        identityConnectionsService.deleteIdentityConnectionsDataByID(name).then(
          result => {
            getIdentityConnectionsData();
            notificationService.success(name + ' Deleted Successfully! ');
          },
          result => {
            notificationService.error('Delete ' + name + ' Failed! ');
          },
        );
      });
    };

    $scope.copy = entity => {
      $http
        .post(appSettings.serverPath + '/' + appSettings.version + '/id_connections', {
          id_connection: entity,
          isClone: true,
        })
        .then(
          () => {
            notificationService.success('Copied Successfully!');
            getIdentityConnectionsData();
          },
          () => {
            notificationService.error('An error has occurred!');
          },
        );
    };
  },
  bindings: {
    displaytype: '@',
    onselect: '&',
    idsorlist: '=',
    useArchiveFilter: '=',
  },
});
