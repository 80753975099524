import React, { FC, MouseEvent, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import {
  BigidGridColumn,
  BigidGridColumnTypes,
  BigidGridQueryComponents,
  BigidGridWithToolbar,
  BigidGridWithToolbarProps,
} from '@bigid-ui/grid';
import { $state } from '../../../services/angularServices';
import { notificationService } from '../../../services/notificationService';
import {
  EtlStageName,
  ETLStatusMode,
  executeEtl,
  getEtlStatusForIcon,
  getEtlTitle,
  getReportingETlMonitoringData,
  GroupedEtlMonitoringData,
  handleTestConnection,
  isETLProcessRunning,
  isSelectedAdapterRunning,
  ReportingEtlMonitoringModel,
  RunningAdapters,
  stopRunningETL,
} from '../reportingEtlMonitoringUtils';
import {
  BigidDialog,
  BigidFormStateAndHandlers,
  BigidIconSize,
  BigidLoader,
  BigidPaper,
  BigidStateProgressBarStatus,
  EntityEvents,
  entityEventsEmitter,
  PrimaryButton,
  SecondaryButton,
  useInterval,
} from '@bigid-ui/components';
import { pageHeaderService } from '../../../../common/services/pageHeaderService';
import { useUserPreferences } from '../../../components/hooks/useUserPrefrences';
import { queryService } from '../../../services/queryService';
import { ConfigurationFormProps, ReportingEtlConfigurationForm } from '../Settings/ReportintEtlConfigurationForm';
import { debounce } from 'lodash';
import { REPORTS_PERMISSIONS } from '@bigid/permissions';
import { isPermitted } from '../../../services/userPermissionsService';
import {
  BigidAdministrationIcon,
  BigidPlayIcon,
  BigidScheduleIcon,
  BigidStopIcon,
  BigidSyncIcon,
} from '@bigid-ui/icons';
import { subscribeToRepeatedSSEEventById } from '../../../services/sseService';
import { dateTimeService } from '@bigid-ui/i18n';
import { LookerButton } from '../DisWelcomePage/LookerButton';
import { DATA_INSIGHTS_STUDIO } from '../consts/ReportingEtlConsts';
import { appsLicenseService } from '../../../services/appsLicenseService';
import { EtlGroupRowContentComponent } from './GroupedEtlMonitorRow';
import styled from '@emotion/styled';
import { EtlNoData } from './EtlNoData';
import { getApplicationPreference } from '../../../services/appPreferencesService';
import { useLocalTranslation } from '../translations';
import { ReportingEtlTrackingEvents } from '../consts/ReportingEtlEventsConsts';
import { analyticsService } from '../../../services/analyticsService';
import { tConfigurationDialog } from '../translations/EtlFixedTranslations';

const GridWrapper = styled('div')`
  width: 100%;
  height: 100%;
  display: flex;
  flex: 1 1 auto;
  flex-flow: column nowrap;
  overflow: hidden;
  padding-bottom: 10px;
`;

const REFRESH_ETL_STATUS_INTERVAL = 10 * 1000;

export const ReportingEtlMonitoring: FC = () => {
  const { t } = useLocalTranslation();
  const [isConfigurationFormOpen, setIsConfigurationFormOpen] = useState(false);
  const [isSaveEnabled, setIsSaveEnabled] = useState(false);
  const [isTestConnectionEnabled, setIsTestConnectionEnabled] = useState(false);
  const [isActionInProgress, setIsActionInProgress] = useState(false);
  const [runningAdapters, setRunningAdapters] = useState<RunningAdapters>({});
  const [isCurrentSelectedAdapterRunning, setIsCurrentSelectedAdapterRunning] = useState(false);
  const formControls = useRef<BigidFormStateAndHandlers>();

  useEffect(() => {
    (async () => {
      pageHeaderService.setIsHidden(false);
      pageHeaderService.setTitle({
        ...getEtlTitle(),
      });
      const { runningAdapters, currentActiveAdapter } = await isETLProcessRunning();
      setRunningAdapters(runningAdapters);
      setIsCurrentSelectedAdapterRunning(runningAdapters[currentActiveAdapter]);
    })();
    const unregister = subscribeToRepeatedSSEEventById<ReportingEtlMonitoringModel & { isCreate: boolean }>(
      'reporting-etl-progress-update',
      debounce(data => {
        const {
          results: [updatedModel],
        } = data;
        const { _id, isCreate } = updatedModel;
        const eventType = isCreate ? EntityEvents.ADD : EntityEvents.UPDATE_BY_ID;
        entityEventsEmitter.emit(eventType, _id, updatedModel);
      }, 500),
    );

    return () => unregister();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useInterval(async () => {
    const { runningAdapters, currentActiveAdapter } = await isETLProcessRunning();
    setRunningAdapters(runningAdapters);
    const isCurrentSelectedAdapterIsRunning = runningAdapters[currentActiveAdapter] || false;
    setIsCurrentSelectedAdapterRunning(isCurrentSelectedAdapterIsRunning);
    setIsActionInProgress(false);
  }, REFRESH_ETL_STATUS_INTERVAL);

  const columns: BigidGridColumn<ReportingEtlMonitoringModel>[] = useMemo(
    () => [
      {
        name: 'started_at',
        title: t('main.grid.columns.startedAt'),
        width: 200,
        getCellValue: row => ({
          icon: {
            icon: BigidScheduleIcon,
            label: dateTimeService.formatDate(row.started_at),
            size: BigidIconSize.MEDIUM,
          },
        }),
        type: BigidGridColumnTypes.ICON,
      },
      {
        name: 'source_table',
        title: t('main.grid.columns.sourceCollection'),
        width: 200,
        getCellValue: row => row.source_table,
        type: BigidGridColumnTypes.TEXT,
      },
      {
        name: 'target_table',
        title: t('main.grid.columns.targetTable'),
        width: 200,
        getCellValue: row => row.target_table,
        type: BigidGridColumnTypes.TEXT,
      },
      {
        name: 'etl_mode',
        width: 150,
        title: t('main.grid.columns.mode'),
        getCellValue: row => row.etl_mode,
        type: BigidGridColumnTypes.TEXT,
      },
      {
        name: 'status',
        width: 150,
        title: t('main.grid.columns.status'),
        getCellValue: ({ status, stage }) => {
          const statusToShow =
            status === ETLStatusMode.IN_PROGRESS && stage === EtlStageName.CLOUD
              ? ETLStatusMode.CLOUD_IN_PROGRESS
              : status === ETLStatusMode.STOPPED
              ? ETLStatusMode.CANCELLED
              : status;
          return {
            text: statusToShow,
            status: getEtlStatusForIcon(status),
          };
        },
        type: BigidGridColumnTypes.STATUS,
      },
      {
        name: 'progress',
        title: t('main.grid.columns.progress'),
        sortingEnabled: false,
        width: 230,
        getCellValue: ({ status: etlStatus, total_row_count, total_row_processed }) => {
          let status = BigidStateProgressBarStatus.REGULAR;
          let percentage = total_row_count > 0 ? Math.round((100 * total_row_processed) / total_row_count) : 100;

          if (percentage === 100 && etlStatus === ETLStatusMode.IN_PROGRESS) {
            percentage = 99;
          } else if (etlStatus === ETLStatusMode.CALCULATING) {
            percentage = 0;
          }

          if (etlStatus === ETLStatusMode.STOPPED) {
            status = BigidStateProgressBarStatus.PAUSED;
          } else if (etlStatus === ETLStatusMode.FAILED) {
            status = BigidStateProgressBarStatus.STUCK;
          }

          return {
            progressBar: {
              percentage,
              status,
              width: '150px',
              size: 'large',
              rightFilter: `${percentage}%`,
            },
          };
        },
        type: BigidGridColumnTypes.PROGRESS_BAR,
      },
      {
        name: 'duration',
        width: 150,
        title: t('main.grid.columns.duration'),
        sortingEnabled: false,
        getCellValue: row => {
          const { started_at, completed_at, updated_at, status: etlStatus } = row;
          const isFailedOrStopped = etlStatus === ETLStatusMode.FAILED || etlStatus === ETLStatusMode.STOPPED;
          const notCompletedEndedAt = isFailedOrStopped ? new Date(updated_at) : new Date();
          const endDate = completed_at ? new Date(completed_at) : notCompletedEndedAt;
          return dateTimeService.formatDistance(new Date(started_at), endDate, { includeSeconds: true });
        },
        type: BigidGridColumnTypes.TEXT,
      },
      {
        name: 'completed_at',
        title: t('main.grid.columns.completedAt'),
        width: 200,
        getCellValue: row => ({
          icon: {
            icon: BigidScheduleIcon,
            label: dateTimeService.formatDate(row.completed_at, { emptyDateText: 'N/A' }),
            size: BigidIconSize.MEDIUM,
          },
        }),
        type: BigidGridColumnTypes.ICON,
      },
      {
        name: 'num_of_parts',
        title: t('main.grid.columns.partsCount'),
        width: 150,
        getCellValue: row => row.num_of_parts,
        type: BigidGridColumnTypes.NUMBER,
      },
      {
        name: 'total_row_processed',
        title: t('main.grid.columns.processedRows'),
        width: 150,
        getCellValue: row => row.total_row_processed,
        type: BigidGridColumnTypes.NUMBER,
      },
      {
        name: 'total_row_count',
        title: t('main.grid.columns.estimatedTotalRows'),
        width: 150,
        getCellValue: row => row.total_row_count,
        type: BigidGridColumnTypes.NUMBER,
      },
      {
        name: 'adapter_type',
        width: 150,
        title: t('main.grid.columns.adapterType'),
        getCellValue: row => row.adapter_type,
        type: BigidGridColumnTypes.TEXT,
      },
      {
        name: 'container_name',
        width: 200,
        title: t('main.grid.columns.containerName'),
        getCellValue: row => row.container_name,
        type: BigidGridColumnTypes.TEXT,
      },
      {
        name: 'global_etl_run_id',
        width: 200,
        title: t('main.grid.columns.runId'),
        getCellValue: row => row.global_etl_run_id,
        type: BigidGridColumnTypes.TEXT,
      },
    ],
    [t],
  );

  const { isReady, gridColumns, preferences, updatePreferences } = useUserPreferences({
    stateName: `reportingEtl.monitoring`,
    initialGridColumns: columns,
  });

  const gridWithToolbarConfig: BigidGridWithToolbarProps<ReportingEtlMonitoringModel> = useMemo(() => {
    return {
      pageSize: 5,
      columns: gridColumns,
      entityName: 'Rows',
      showSortingControls: true,
      defaultSorting: preferences?.grid?.sort || [{ field: 'started_at', order: 'desc' }],
      groupingConfig: {
        grouping: [{ columnName: 'global_etl_run_id' }],
        groupRowComponent: EtlGroupRowContentComponent,
      },
      onGridStateChange: ({ filter, ...gridState }) => updatePreferences({ filterState: { filter }, gridState }),
      fetchData: async (queryComponents: BigidGridQueryComponents) => {
        let data: GroupedEtlMonitoringData[] = [];
        let totalCount = 0;
        try {
          const gridConfigQuery = queryService.getGridConfigQuery({
            ...queryComponents,
          });
          const {
            data: { monitoring, totalCount: count },
          } = await getReportingETlMonitoringData(gridConfigQuery, $state.params.isDataInsightsStudio);
          data = monitoring;
          totalCount = Math.trunc(count / 10);
        } catch (e) {
          console.error(e);
          notificationService.error(t('main.grid.fetchError'));
        }

        return {
          data,
          totalCount,
        };
      },
      toolbarActions: [
        {
          label: t('main.grid.toolbarActions.execute'),
          icon: BigidPlayIcon,
          isGlobal: true,
          execute: async () => {
            setIsActionInProgress(true);
            await executeEtl($state.params.isDataInsightsStudio);
            setIsActionInProgress(false);
            return { shouldGridReload: false };
          },
          disable: () => isActionInProgress || isCurrentSelectedAdapterRunning,
          show: () => isPermitted(REPORTS_PERMISSIONS.MANAGE_REPORTING_ETL.name),
          bi: {
            eventType: ReportingEtlTrackingEvents.REPORTING_ETL_TOOLBAR_EXECUTE_CLICK,
          },
        },
        {
          label: t('main.grid.toolbarActions.cancel'),
          icon: BigidStopIcon,
          isGlobal: true,
          execute: async () => {
            setIsActionInProgress(true);
            await stopRunningETL();
            setIsActionInProgress(false);
            return { shouldGridReload: false };
          },
          disable: () => isActionInProgress || Object.keys(runningAdapters).length === 0,
          show: () => isPermitted(REPORTS_PERMISSIONS.MANAGE_REPORTING_ETL.name),
          bi: {
            eventType: ReportingEtlTrackingEvents.REPORTING_ETL_TOOLBAR_STOP_CLICK,
          },
        },
        {
          label: t('main.grid.toolbarActions.refresh'),
          icon: BigidSyncIcon,
          isGlobal: true,
          execute: async () => {
            return { shouldGridReload: true };
          },
          disable: () => false,
          show: () => true,
          bi: {
            eventType: ReportingEtlTrackingEvents.REPORTING_ETL_TOOLBAR_REFRESH_CLICK,
          },
        },
        {
          label: t('main.grid.toolbarActions.settings'),
          icon: BigidAdministrationIcon,
          isGlobal: true,
          execute: async () => {
            setIsConfigurationFormOpen(true);
            return { shouldGridReload: false };
          },
          disable: () => false,
          show: () => true,
          bi: {
            eventType: ReportingEtlTrackingEvents.REPORTING_ETL_TOOLBAR_CONFIGURATION_CLICK,
          },
        },
      ],
      pagingMode: true,
      ...(getApplicationPreference('ENABLE_NEW_ETL_LANDING_PAGE') &&
        !$state.params.isDataInsightsStudio && {
          noDataContent: (
            <EtlNoData
              isDataInsightsStudio={$state.params.isDataInsightsStudio}
              openConfigurationForm={setIsConfigurationFormOpen}
              setExecuteInProgress={setIsActionInProgress}
            />
          ),
        }),
    };
  }, [
    gridColumns,
    preferences?.grid?.sort,
    t,
    updatePreferences,
    isActionInProgress,
    isCurrentSelectedAdapterRunning,
    runningAdapters,
  ]);

  const handleCloseForm = () => {
    setIsConfigurationFormOpen(false);
    setIsSaveEnabled(false);
  };

  const buttons = useMemo(
    () => [
      {
        text: tConfigurationDialog('configurationButtons.close'),
        component: SecondaryButton,
        onClick: () => {
          analyticsService.trackManualEvent(ReportingEtlTrackingEvents.REPORTING_ETL_CONFIGURATION_DIALOG_CLOSE);
        },
        isClose: true,
      },
      {
        text: tConfigurationDialog('configurationButtons.testConnection'),
        component: PrimaryButton,
        onClick: handleTestConnection(formControls),
        disabled: !isPermitted(REPORTS_PERMISSIONS.MANAGE_REPORTING_ETL.name) || !isTestConnectionEnabled,
      },
      {
        text: tConfigurationDialog('configurationButtons.save'),
        component: PrimaryButton,
        onClick: (event: MouseEvent) => {
          formControls?.current?.validateAndSubmit(async () => {
            await formControls?.current?.submit(event);
          });
        },
        disabled: !isPermitted(REPORTS_PERMISSIONS.MANAGE_REPORTING_ETL.name) || !isSaveEnabled,
      },
    ],
    [isSaveEnabled, isTestConnectionEnabled],
  );

  const onFormChange = useCallback<ConfigurationFormProps['onChange']>(
    ({ isDirty, selectedAdapter }) => {
      const isCurrentSelectedAdapterIsRunning = isSelectedAdapterRunning(runningAdapters, selectedAdapter);
      setIsCurrentSelectedAdapterRunning(isCurrentSelectedAdapterIsRunning);
      setIsSaveEnabled(isDirty && !isCurrentSelectedAdapterIsRunning);
    },
    [runningAdapters],
  );

  const onAdapterChange = useCallback((isDirty: boolean) => {
    setIsTestConnectionEnabled(isDirty);
  }, []);

  return (
    <GridWrapper>
      <BigidPaper>
        {!isReady && <BigidLoader />}
        {isReady && <BigidGridWithToolbar {...gridWithToolbarConfig} />}
      </BigidPaper>
      <BigidDialog
        isOpen={isConfigurationFormOpen}
        onClose={handleCloseForm}
        showCloseIcon
        isContentScrollable
        title={t('main.configurationDialog.title')}
        buttons={buttons}
      >
        <ReportingEtlConfigurationForm
          formControls={formControls}
          onChange={onFormChange}
          onAdapterChange={onAdapterChange}
          runningAdapters={runningAdapters}
          onSubmitSuccess={handleCloseForm}
        />
      </BigidDialog>
    </GridWrapper>
  );
};
