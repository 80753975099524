import { module } from 'angular';
const app = module('app');

app.component('selectw', {
  controllerAs: 'selectWrapModel',
  controller: [
    '$document',
    'uiGridEditConstants',
    '$scope',
    function ($document, uiGridEditConstants, $scope) {
      $document.on('click', docClick);

      function docClick(evt) {
        if (evt.target && evt.target.closest('.tomer')) {
          $scope.$emit(uiGridEditConstants.events.END_CELL_EDIT);
          $document.off('click', docClick);
        }
      }
    },
  ],
});
