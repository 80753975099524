import { BigidFormField, BigidFormFieldTypes } from '@bigid-ui/components';
import React, { FC, useMemo } from 'react';
import { BigidFormFileUpload } from '../FormSection/BigidFormFileUpload';
import { BigidFormPaddedCheckbox } from '../FormSection/BigidFormPaddedCheckbox';
import { FormSection, FormSectionInitialStateProps } from '../FormSection/FormSection';
import { useReportTemplateContext } from '../hooks/ReportTemplateContext';

export const GeneralInformationSection: FC = () => {
  const sectionName = 'companyInfo';
  const description = 'Display company name, address and contact details.';

  const contextState = useReportTemplateContext();
  const { initialTemplateData } = contextState;

  const formSectionInitProps: FormSectionInitialStateProps = useMemo(() => {
    const {
      companyInfo: { ...initialValues },
    } = initialTemplateData;

    const fields: BigidFormField[] = [
      {
        type: BigidFormFieldTypes.TEXT,
        name: 'name',
        label: 'Company Name',
        misc: {
          placeholder: 'The Company',
          fullWidth: true,
        },
      },
      {
        render: BigidFormPaddedCheckbox,
        name: 'showCompanyNameInHeaders',
        labelWidth: '170px',
        label: 'Show on page headers',
        misc: {
          fullWidth: false,
        },
      },
      {
        render: BigidFormFileUpload,
        name: 'logo',
        label: 'Company Logo',
        misc: {
          fullWidth: true,
          logo: initialValues.logo,
        },
      },
      {
        type: BigidFormFieldTypes.TEXT,
        name: 'address',
        label: 'Address',
        misc: {
          placeholder: '4517 Washington Ave. Manchester, Kentucky 39495',
          fullWidth: true,
        },
      },
      {
        type: BigidFormFieldTypes.TEXT,
        name: 'phoneNumber',
        label: 'Company Phone',
        misc: {
          placeholder: '(704) 555-0127',
          fullWidth: true,
        },
      },
      {
        type: BigidFormFieldTypes.TEXT,
        name: 'email',
        label: 'Email',
        misc: {
          placeholder: 'info_it68@company.com',
          fullWidth: true,
        },
      },
    ];

    return {
      fields,
      initialValues,
    };
  }, [initialTemplateData]);

  return <FormSection sectionName={sectionName} description={description} {...formSectionInitProps} />;
};
