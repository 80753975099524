import React, { FC, useContext, useMemo } from 'react';
import {
  AccordionSummarySizeEnum,
  BigidAccordion,
  BigidAccordionDetails,
  BigidAccordionSummary,
  BigidConnectionInProgressAnimation,
  BigidFieldRenderProps,
  BigidFormField,
  BigidHeading6,
  StringOrBooleanMap,
} from '@bigid-ui/components';
import { AutoDiscoveryWizardContext } from '../../autoDiscoveryWizardContext';
import { fieldConditionCheck } from '../../../../DataSources/DataSourceConfiguration/utils/conditionUtils';
import { getDiscoverAuthTypeString, getFieldConditionState } from '../../autoDiscoveryWizardFormUtils';
import { PREREQUISITES_PLACEMENT_BY_TYPE, PrerequisitesPlacement } from '../../../constants';
import { useLocalTranslation } from '../../../translations';
import { AutoDiscoveryConditionalSectionStyled } from '../AutoDiscoveryConditionalSectionStyled';
import { getApplicationPreference } from '../../../../../services/appPreferencesService';
import { AutoDiscoveryWizardSideScanningSection } from './AutoDiscoveryWizardSideScanningSection';
import { AutoDiscoveryPrerequisitesContent } from '../../AutoDiscoveryWizardPrerequisitesModal/AutoDiscoveryPrerequisitesContent';
import styled from '@emotion/styled';

const AutoDiscoveryAuthAndPermissionsLoading = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 8px;
`;

export interface AutoDiscoveryWizardAuthSectionProps {
  values?: Record<string, any>;
  visibleFields?: string[];
  fields: BigidFormField[];
  getFieldProps: (fieldName: string) => BigidFieldRenderProps<any, any>;
  renderField: (fieldName: string, additionalProps?: Record<string, any>) => any;
  errors: StringOrBooleanMap;
  sideScanFields: BigidFormField[];
  connectionSection?: BigidFormField[];
}

const accordionCommonStyle = {
  backgroundColor: 'transparent!important',
  width: '100%',
  gap: '0',
  display: 'flex',
  flexDirection: 'column',
  borderColor: 'transparent!important',
  boxShadow: 'none!important',
};

const accordionDetailsStyle = {
  padding: '0 0 20px !important',
  backgroundColor: 'transparent!important',
};

const accordionStyle = {
  gap: '0px',
  boxShadow: 'none!important',
  border: `none`,
  backgroundColor: 'transparent!important',
  borderColor: 'transparent!important',
};

const accordionSummary = {
  padding: '0!important',
  '& .MuiAccordionSummary-content': {
    marginLeft: '4px !important',
    backgroundColor: 'transparent!important',
    borderColor: 'transparent!important',
    boxShadow: 'none!important',
  },
};

const AccordionInfoPanelStyled = styled.div`
  width: 100%;
  .MuiAccordion-root {
    border-color: transparent !important;
    box-shadow: none !important;
  }
`;

export const AutoDiscoveryWizardAuthSection: FC<AutoDiscoveryWizardAuthSectionProps> = ({
  values,
  fields,
  renderField,
  getFieldProps,
  errors,
  sideScanFields,
  connectionSection,
}) => {
  const { discoveryConfigData, discoveryConfigDataRef } = useContext(AutoDiscoveryWizardContext);
  const { t } = useLocalTranslation('AutoDiscovery');
  const fieldsNamesByAuthTypeFields: string[] = [];
  const authTypeValue = getDiscoverAuthTypeString(discoveryConfigData.formData?.authType);
  const showPrerequisites =
    PREREQUISITES_PLACEMENT_BY_TYPE[discoveryConfigData.type][authTypeValue] === PrerequisitesPlacement.MAIN;

  const isSideScanningEnabled = getApplicationPreference('SIDE_SCANNING_IN_ONBOARDING_UI_ENABLED');
  const sideScanSectionRendered = useMemo(
    () =>
      isSideScanningEnabled && (
        <AutoDiscoveryWizardSideScanningSection
          errors={errors}
          renderField={renderField}
          getFieldProps={discoveryConfigDataRef.current.getFieldProps}
          values={values}
          fields={sideScanFields}
        />
      ),
    [discoveryConfigDataRef, errors, isSideScanningEnabled, renderField, sideScanFields, values],
  );

  const showAdvanced = discoveryConfigData.isEdit;

  return (
    authTypeValue && (
      <>
        <AutoDiscoveryConditionalSectionStyled showPrerequisites={showPrerequisites}>
          {discoveryConfigData.isCheck ? (
            <AutoDiscoveryAuthAndPermissionsLoading data-aid={'AutoDiscoveryAuthAndPermissionsLoading'}>
              <BigidConnectionInProgressAnimation width={96} height={96} />
              <BigidHeading6>{t('configModal.loaders.checkingPermissions')}</BigidHeading6>
            </AutoDiscoveryAuthAndPermissionsLoading>
          ) : (
            <>
              {showPrerequisites && (
                <AutoDiscoveryPrerequisitesContent authType={authTypeValue} type={discoveryConfigData.type} />
              )}
              {fields.map(({ name, misc: { visibleIf } }) => {
                const isVisible = fieldConditionCheck(false, visibleIf, field => ({
                  value: values[field],
                  type: getFieldProps(field).misc?.typeForConditionCheck,
                }));
                if (isVisible) {
                  fieldsNamesByAuthTypeFields.push(name);
                }
                return (
                  isVisible &&
                  renderField(name, {
                    error: errors?.[name],
                    errorIsShown: !!errors?.[name],
                  })
                );
              })}
            </>
          )}
          {!discoveryConfigData.isEdit && sideScanSectionRendered}
        </AutoDiscoveryConditionalSectionStyled>
        {showAdvanced && (
          <AccordionInfoPanelStyled data-aid={'auto-discovery-advanced'}>
            <BigidAccordion
              dataAid={'AutoDiscoverAdvancedSectionPanel'}
              sx={{ ...accordionCommonStyle, ...accordionStyle }}
            >
              <BigidAccordionSummary sx={{ ...accordionSummary }} size={AccordionSummarySizeEnum.large}>
                <BigidHeading6 sx={{ width: '100%' }}>{t('configAdvanced.advancedSection')}</BigidHeading6>
              </BigidAccordionSummary>
              <BigidAccordionDetails sx={{ ...accordionCommonStyle, ...accordionDetailsStyle }}>
                {sideScanSectionRendered}
                {connectionSection?.map(field => {
                  const { disabled, isVisible } = getFieldConditionState(field, values, discoveryConfigDataRef);

                  return (
                    isVisible &&
                    renderField(field.name, {
                      disabled,
                      error: errors?.[field.name],
                      errorIsShown: !!errors?.[field.name],
                    })
                  );
                })}
              </BigidAccordionDetails>
            </BigidAccordion>
          </AccordionInfoPanelStyled>
        )}
      </>
    )
  );
};

