import { isEqual } from 'lodash';
import { useCallback, useState } from 'react';
import {
  DataSourceConnectionFormField,
  DataSourceConnectionTemplateField,
  DataSourceTemplateCondition,
  DataSourceOption,
  DsScanTypeEnum,
  DsTypesEnum,
} from '../types';
import { DataSourceTestConnectionStatusEnum } from './useTestConnection';
import { DataSourceTestConnectionRow } from '../../DataSourcesTestConnectionGrid/DataSourcesTestConnectionGrid';
import { DataSourceTemplateStatus } from '../../../../utilities/dataSourcesUtils';
import { TestConnectionErrorResolution } from '../../DataSourceConnectionModal/hooks/useTestConnection';

export interface DataSourceConfigurationState {
  isLoading: boolean;
  isLoadingData: boolean;
  isSaveInProgress?: boolean;
  isFormDirty?: boolean;
  fields: DataSourceConnectionFormField[];
  initialValues?: Record<string, string | number | string[] | Record<string, any>>;
  id?: string;
  type?: string;
  connectionType?: string;
  selectedType?: string;
  testStatus?: DataSourceTestConnectionStatusEnum;
  testInProgress: boolean;
  testError?: string;
  lastTestDate?: string;
  isTestAvailable: boolean;
  tablesResult?: DataSourceTestConnectionRow[];
  scanType?: DsScanTypeEnum;
  scanInProgress: boolean;
  isScanSuccess?: boolean;
  scanStartDate?: string;
  scanCompleteDate?: string;
  objectsFound?: number;
  isNewPassword?: boolean;
  encryptFields?: string[];
  saveError?: string;
  errorMessage?: string;
  wasScanExecuted?: boolean;
  notFoundMessage?: string;
  isCredentialDeleted?: boolean;
  callToFillState?: Record<string, boolean>;
  isScopeSelected?: boolean;
  enabled?: boolean;
  scanResults?: Record<string, any>[] | null;
  errorResolution?: TestConnectionErrorResolution | null;
  scanResultsIsLoading?: boolean;
  status?: DataSourceTemplateStatus;
}

export const DEFAULT_CONFIG_DS_STATE: DataSourceConfigurationState = {
  isLoading: true,
  isLoadingData: true,
  isFormDirty: false,
  fields: [],
  id: '',
  isTestAvailable: false,
  testStatus: DataSourceTestConnectionStatusEnum.notStarted,
  testInProgress: false,
  scanInProgress: false,
  callToFillState: {},
};

export type getStatePartBasedOnCurrentState = (
  state: DataSourceConfigurationState,
) => Partial<DataSourceConfigurationState>;
export type UpdateDataSourceConfigState = (
  changes: Partial<DataSourceConfigurationState> | getStatePartBasedOnCurrentState,
) => void;

export const normalizeInitValueByType = (
  type: DsTypesEnum,
  value: any,
  filterArrayByFields: DataSourceTemplateCondition[] | null,
  options: DataSourceOption[],
) => {
  if (type === DsTypesEnum.options) {
    return String(value);
  }
  if (type === DsTypesEnum.stringSelect) {
    const byOptions = options?.filter(({ value: optionValue }) =>
      typeof value === 'string' ? optionValue === value : isEqual(optionValue, value),
    );
    return value ? (byOptions?.length && byOptions) || [{ value: value, id: value, label: String(value) }] : value;
  }
  if (type === DsTypesEnum.stringBoolean || type === DsTypesEnum.multiStringBoolean) {
    return value === 'true' || value === true;
  }
  if (type === DsTypesEnum.array && filterArrayByFields) {
    return value
      ? value.filter((item: Record<string, any>) =>
          filterArrayByFields.every(condition => item[condition?.field] === condition?.value),
        )
      : [];
  }
  return value;
};

export const resolveDefaultValue = (defaultValue: any, objectFields: DataSourceConnectionTemplateField[]) => {
  if (objectFields && typeof defaultValue === 'object') {
    return objectFields.reduce(
      (extendedDefaultValueAcc, { apiName, defaultValue }) => {
        return apiName !== undefined && defaultValue !== undefined
          ? { ...extendedDefaultValueAcc, [apiName]: defaultValue }
          : extendedDefaultValueAcc;
      },
      { ...defaultValue },
    );
  }
  return defaultValue;
};

export const useDataSourceConfigState = () => {
  const [configDataSourceState, setConfigDataSourceState] =
    useState<DataSourceConfigurationState>(DEFAULT_CONFIG_DS_STATE);

  const updateState = useCallback<UpdateDataSourceConfigState>(
    changesOrFunction =>
      setConfigDataSourceState(currentState => {
        const changes = typeof changesOrFunction === 'function' ? changesOrFunction(currentState) : changesOrFunction;
        return (Object.keys(changes) as (keyof typeof currentState)[]).some(
          key => !isEqual(currentState[key], changes[key]),
        )
          ? { ...currentState, ...changes }
          : currentState;
      }),
    [],
  );
  const generateInitValues = useCallback(
    (fieldsConfig: DataSourceConnectionTemplateField[]) =>
      updateState({
        initialValues: fieldsConfig.reduce(
          (
            initStateAcc,
            { name, defaultValue, type, options, objectFields }: Partial<DataSourceConnectionTemplateField>,
          ) => ({
            ...initStateAcc,
            [name]: normalizeInitValueByType(type, resolveDefaultValue(defaultValue, objectFields), null, options),
          }),
          {},
        ),
      }),
    [updateState],
  );

  return {
    configDataSourceState,
    updateState,
    generateInitValues,
  };
};
