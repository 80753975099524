import { useMemo } from 'react';
import { DsConnectionFieldSectionsEnum, DataSourceConnectionFormField } from '../types';
import { DataSourceTestConnectionStatusEnum } from './useTestConnection';
import { BigidWizardNavigationStep, useSelectWizardStep } from '@bigid-ui/components';
import { BigidConnectionIcon, BigidInstantScanIcon, BigidListIcon } from '@bigid-ui/icons';

interface UseStepsWithDataProps {
  lastTestDate?: string;
  testStatus?: string;
  scanCompleteDate?: string;
  isScanSuccess?: boolean;
  fields?: DataSourceConnectionFormField[];
  initialStep?: DsConnectionFieldSectionsEnum;
}
export type BigidWizardNavigationStepWithCalcProps = BigidWizardNavigationStep & {
  getStepData?: (data: UseStepsWithDataProps) => Partial<BigidWizardNavigationStep>;
};

export const STEPS: BigidWizardNavigationStepWithCalcProps[] = [
  {
    id: DsConnectionFieldSectionsEnum.connection,
    done: false,
    icon: BigidConnectionIcon,
    title: 'Connection',
    getStepData: ({ lastTestDate, testStatus }) => ({
      hoverTitle:
        testStatus === DataSourceTestConnectionStatusEnum.success
          ? `Connection test successfully on: \n ${lastTestDate}`
          : undefined,
      done: testStatus === DataSourceTestConnectionStatusEnum.success,
    }),
  },
  {
    id: DsConnectionFieldSectionsEnum.details,
    done: false,
    icon: BigidListIcon,
    title: 'Details',
  },
  {
    id: DsConnectionFieldSectionsEnum.scanSettings,
    done: false,
    icon: BigidInstantScanIcon,
    title: 'Scan Settings',
    getStepData: ({ scanCompleteDate, isScanSuccess }) => ({
      hoverTitle: scanCompleteDate ? `Last scanned on \n ${scanCompleteDate}` : undefined,
      done: Boolean(scanCompleteDate) && isScanSuccess,
    }),
  },
];

const getStepData = ({
  id,
  getStepData,
  done,
  icon,
  title,
  ...restProps
}: BigidWizardNavigationStepWithCalcProps & UseStepsWithDataProps) => ({
  id,
  done,
  icon,
  title,
  ...((getStepData && getStepData(restProps)) || {}),
});

export const useStepsWithData = ({
  lastTestDate,
  testStatus,
  scanCompleteDate,
  isScanSuccess,
  fields,
  initialStep,
}: UseStepsWithDataProps) => {
  const stepIds = useMemo(() => STEPS.map(({ id }) => id), []);
  const initialStepIndex = STEPS.findIndex(({ id }) => id === initialStep);
  const { activeStep, setActiveStep, goToNext, goToPrev, noPrev, noNext } = useSelectWizardStep(
    STEPS,
    STEPS[initialStepIndex].id,
  );
  const emptySteps = useMemo(
    () => stepIds.filter(sectionId => !fields.some(({ misc }) => misc?.section === sectionId)),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [fields],
  );

  const steps = useMemo(
    () =>
      STEPS.map(step =>
        getStepData({
          ...step,
          lastTestDate,
          testStatus,
          scanCompleteDate,
          isScanSuccess,
        }),
      ).filter(({ id }) => !emptySteps.includes(id)),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [lastTestDate, testStatus, scanCompleteDate, isScanSuccess, emptySteps],
  );

  return {
    activeStep,
    setActiveStep,
    goToNext,
    goToPrev,
    noPrev,
    noNext,
    steps,
  };
};
