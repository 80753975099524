import React, { FC, memo } from 'react';
import { InputBase } from '@mui/material';
import { useStyles } from './MetadataSearchInputStyles';
import { UseMetadataSearchStateResponse } from '../useMetadataSearchState';
import { MetadataSearchProps } from '../MetadataSearch';

interface MetadataSearchInputProps
  extends Pick<
      UseMetadataSearchStateResponse,
      'query' | 'isInputFocused' | 'onSearchInputBlur' | 'onSearchInputChange' | 'onSearchInputKeyPress'
    >,
    Pick<MetadataSearchProps, 'placeholder' | 'isDisabled'> {
  dataAid: string;
}

export const MetadataSearchInput: FC<MetadataSearchInputProps> = memo(
  ({
    dataAid,
    isInputFocused,
    query,
    placeholder,
    onSearchInputBlur,
    onSearchInputChange,
    onSearchInputKeyPress,
    isDisabled,
  }) => {
    const classes = useStyles();

    return (
      <InputBase
        fullWidth
        disabled={isDisabled}
        autoFocus={isInputFocused}
        classes={classes}
        placeholder={placeholder}
        value={query}
        onBlur={onSearchInputBlur}
        onChange={onSearchInputChange}
        onKeyPress={onSearchInputKeyPress}
        inputProps={{
          'data-aid': dataAid,
          'aria-label': placeholder,
        }}
      />
    );
  },
);
