import { CaseReportTabs } from './CaseReport/CaseRemediationSteps/CaseReportTabs';
import { ObjectPreview } from './index';

export enum CaseSidePanelViewsIds {
  CASE_REPORT = 'caseReport',
  OBJECT_PREVIEW = 'objectPreview',
}

export interface CaseSidePanelView {
  id: string;
  component: React.ComponentType<any>;
}

export type CaseSidePanelFlowViewsData = CaseSidePanelView[];

export const CaseSidePanelViews: CaseSidePanelFlowViewsData = [
  {
    id: CaseSidePanelViewsIds.CASE_REPORT,
    component: CaseReportTabs,
  },
  {
    id: CaseSidePanelViewsIds.OBJECT_PREVIEW,
    component: ObjectPreview,
  },
];
