import { BigidSelect, BigidSelectOption } from '@bigid-ui/components';
import React, { FC } from 'react';
import { getEmail } from './DataCatalogOwnersDialog';

interface DetailsOwnerSelectorProps {
  onChange: (option: BigidSelectOption[]) => void;
  value: BigidSelectOption[];
  usersList: BigidSelectOption[];
}

export const DataCatalogDetailsOwnerSelector: FC<DetailsOwnerSelectorProps> = ({ onChange, value, usersList }) => {
  const isValidNewOption = (inputValue: string) => {
    const email = getEmail(inputValue);
    return !!email;
  };

  const handleOnSelectChange = (option: BigidSelectOption[]) => {
    onChange(option);
  };

  return (
    <BigidSelect
      displayLimit={5}
      isSearchable
      isCreatable
      placeholder={'Search by name or email - Example: john smith (john@gmail.com)'}
      options={usersList}
      value={value}
      menuPlacement="bottom"
      menuPosition="fixed"
      onChange={handleOnSelectChange}
      dataAid={`select-new-owner-${value?.[0]?.label}`}
      isValidNewOption={isValidNewOption}
      noOptionsMessage={'Please provide a valid email'}
      isClearable={true}
    />
  );
};
