import React, { FC, ReactText, ReactElement, memo } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { BigidColorsV2, BigidNotes1, BigidBody1 } from '@bigid-ui/components';
import { generateDataAid } from '@bigid-ui/utils';
import classNames from 'classnames';

export interface ColumnProfileValueProps {
  dataAid?: string;
  name: string;
  value?: ReactText;
  valuePlaceholder?: string;
  valueColor?: string;
  widget?: ReactElement;
}

const useStyles = makeStyles({
  root: {
    backgroundColor: BigidColorsV2.white,
    boxShadow: '0px 0px 5px 0px #00000026',
    borderRadius: '4px',
    padding: '12px 12px',
    height: '64px',
    display: 'flex',
    boxSizing: 'border-box',
  },
  leftContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    flex: '1 0 auto',
    maxWidth: 'calc(100% - 55px)',
  },
  leftContainerExtended: {
    maxWidth: '100%',
  },
  rightContainer: {
    display: 'flex',
    alignItems: 'center',
    width: '42px',
    marginLeft: '10px',
    flex: '0 0 42px',
  },
  entityName: {
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  },
  entityValue: ({ valueColor }: Pick<ColumnProfileValueProps, 'valueColor'>) => ({
    color: valueColor,
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  }),
});

export const ColumnProfileValue: FC<ColumnProfileValueProps> = memo(
  ({
    dataAid = 'ColumnProfileValue',
    name,
    value,
    valueColor = BigidColorsV2.gray[700],
    valuePlaceholder = '-',
    widget,
  }) => {
    const classes = useStyles({ valueColor });

    return (
      <div className={classes.root} data-aid={dataAid}>
        <div className={classNames(classes.leftContainer, !widget && classes.leftContainerExtended)}>
          <BigidNotes1 className={classes.entityName} data-aid={generateDataAid(dataAid, ['name'])}>
            {name}
          </BigidNotes1>
          <BigidBody1
            className={classes.entityValue}
            title={value && value.toString()}
            data-aid={generateDataAid(dataAid, ['value'])}
          >
            {typeof value === 'number' || typeof value === 'string' ? value : valuePlaceholder}
          </BigidBody1>
        </div>
        {widget && (
          <div className={classes.rightContainer} data-aid={generateDataAid(dataAid, ['widget'])}>
            {widget}
          </div>
        )}
      </div>
    );
  },
);
