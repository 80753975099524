import React, { FC, Fragment, useMemo, useCallback } from 'react';
import { some, uniq } from 'lodash';
import Grid from '@mui/material/Grid';
import { ApplicationCard } from '../Components/ApplicationCard';
import {
  ApplicationsManagementTabProps,
  applicationManagementService,
  onSelectFavorite,
  CustomApps,
} from '../applicationManagementService';
import makeStyles from '@mui/styles/makeStyles';
import { BigidColorsV2, BigidDropdownOption, BigidHeading5 } from '@bigid-ui/components';
import { appsLicenseService } from '../../../services/appsLicenseService';
import { tpaStatusService } from '../../../services/tpaStatusService';
import { CustomAppIcon } from '../Components/CustomAppIcon';
import { getCustomAppNormalizedFriendlyName, getNormalizedAppNameForLabel } from '../../CustomApp/utils/CustomAppUtils';

const useStyles = makeStyles({
  root: {
    height: '100%',
    overflow: 'scroll',
    padding: 16,
  },
  categoryTitle: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: 8,
    fontSize: '0.875rem',
    color: BigidColorsV2.gray[700],
    '&:not(:first-child)': {
      marginTop: 24,
    },
  },
});

export const getUniqueCustomApps = (customApps: CustomApps[], mustHaveTpaId?: string): CustomApps[] => {
  const uniqueApps = customApps.reduce(
    ({ uniqueAppsArray, uniqueAppsNames }, currentCustomApp) => {
      const currentAppNameValue = currentCustomApp.manifest_name || currentCustomApp.tpa_name;
      if (!uniqueAppsNames.has(currentAppNameValue)) {
        uniqueAppsArray.push(currentCustomApp);
        uniqueAppsNames.add(currentAppNameValue);
      }
      return { uniqueAppsArray, uniqueAppsNames };
    },
    { uniqueAppsArray: [], uniqueAppsNames: new Set() },
  ).uniqueAppsArray;

  return mustHaveTpaId ? getTpasWithMustHaveTpaIncluded(customApps, uniqueApps, mustHaveTpaId) : uniqueApps;
};

const getTpasWithMustHaveTpaIncluded = (
  allApps: CustomApps[],
  uniqueApps: CustomApps[],
  selectedTpaId: string,
): CustomApps[] => {
  const selectedTpa = allApps.find(({ _id }) => _id === selectedTpaId);
  return selectedTpa
    ? [
        ...uniqueApps.filter(
          (app: CustomApps) =>
            getNormalizedAppNameForLabel(allApps, app._id, app.tpa_name) !==
            getNormalizedAppNameForLabel(allApps, selectedTpa._id, selectedTpa.tpa_name),
        ),
        ...(selectedTpa && [selectedTpa]),
      ]
    : [];
};

export const InstalledApps: FC<ApplicationsManagementTabProps> = ({
  apps,
  selectedCategory,
  customApps = [],
  favorites = [],
  fetchUserPreferences,
}) => {
  const classes = useStyles({});

  const categoriesToDisplay = useMemo(
    () =>
      applicationManagementService.getCategoriesToDisplay(selectedCategory, uniq(customApps.map(app => app.category))),
    [customApps, selectedCategory],
  );

  const selectFavorite = useCallback(
    (id: string) => async () => {
      await onSelectFavorite(id);
      await fetchUserPreferences();
    },
    [fetchUserPreferences],
  );

  return (
    <div className={classes.root}>
      {categoriesToDisplay.map((mainCategory, idx) => {
        const appsForCategory = apps.filter(({ category }) => category === mainCategory);
        const customAppsForCategory = getUniqueCustomApps(
          customApps.filter(({ category }) => category === mainCategory),
        );
        const emptyCategory = appsForCategory.length === 0 && customAppsForCategory.length === 0;
        return (
          <Fragment key={idx}>
            {!emptyCategory && (
              <div className={classes.categoryTitle}>
                <BigidHeading5 color={'inherit'}>{mainCategory}</BigidHeading5>
              </div>
            )}
            <Grid container spacing={2} alignItems="stretch">
              {appsForCategory.map(
                ({ id, name, description, to, link, icon, onClick, dataAid, isCertified, isPaid }) => {
                  const favorite = some(favorites, { appId: id });
                  return (
                    <Grid item key={id} xs={12} sm={6} md={4} lg={3}>
                      <ApplicationCard
                        name={name}
                        description={description}
                        icon={icon}
                        to={to}
                        link={link}
                        onClick={onClick}
                        onSelectFavorite={selectFavorite(id)}
                        favorite={favorite}
                        dataAid={dataAid}
                        isCertified={isCertified}
                        isPaid={isPaid}
                      />
                    </Grid>
                  );
                },
              )}
              {customAppsForCategory.map(
                ({
                  logo,
                  tpa_name: name,
                  friendlyName,
                  description,
                  license_type,
                  installation_details,
                  _id: id,
                  vendor,
                  version,
                  status,
                  license_verification_key,
                  manifest_name,
                  settings,
                }) => {
                  status = tpaStatusService.permitTpaUpdateStatus(status, name);
                  const favorite = some(favorites, { appId: id });
                  const isPaid = license_type === 'PAID';
                  const displayName = friendlyName
                    ? getCustomAppNormalizedFriendlyName(friendlyName, settings?.instanceId)
                    : name;
                  return (
                    <Grid item key={id} xs={12} sm={6} md={4} lg={3}>
                      <ApplicationCard
                        id={id}
                        custom
                        name={displayName}
                        tpaName={name}
                        hasManifestName={!!manifest_name}
                        description={description}
                        logo={logo && <CustomAppIcon logo={logo} />}
                        onClick={() =>
                          appsLicenseService.showAppExpirationNotificationForCustomAppAndGoToCustomAppPage(
                            id,
                            name,
                            vendor,
                            manifest_name,
                          )
                        }
                        onSelectFavorite={selectFavorite(id)}
                        favorite={favorite}
                        version={version}
                        status={status}
                        installationDetails={installation_details}
                        dataAid={`open-app-${name}-${vendor}`}
                        isCertified={!!license_verification_key}
                        isPaid={isPaid}
                      />
                    </Grid>
                  );
                },
              )}
            </Grid>
          </Fragment>
        );
      })}
    </div>
  );
};
