import React, { FC, useEffect, useState, ReactNode, useCallback } from 'react';
import styled from '@emotion/styled';
import { BigidBody1, BigidColorsV2, useInterval, BigidTooltip, BigidLoader, BigidLink } from '@bigid-ui/components';
import { generateGuidedTourId } from '@bigid-ui/utils';
import { dateTimeService } from '@bigid-ui/i18n';
import { MetadataSearchIndexingStatus } from '../MetadataSearch/MetadataSearchTypes';
import { useLocalTranslation } from './translations';
import { Trans } from 'react-i18next';
import { getMetadataSearchIndexingStatus, startMetadataSearchIndexing } from '../MetadataSearch/MetadataSearchService';

export interface MetadataSearchCatalogIndexingStatusProps {
  dataAid?: string;
  dataTourId?: string;
}

const Root = styled('div')`
  display: flex;
  gap: 8px;
  align-items: center;
`;

const StatusIndicatorWrapper = styled('div')`
  display: flex;
  align-items: center;
`;

const StatusIndicator = styled('div')<{ bgColor: string }>`
  border-radius: 50%;
  width: 12px;
  height: 12px;
  background-color: ${({ bgColor }) => bgColor};
`;

export const MetadataSearchCatalogIndexingStatus: FC<MetadataSearchCatalogIndexingStatusProps> = ({
  dataAid = 'MetadataSearchCatalogIndexingStatus',
  dataTourId = 'MetadataSearchCatalogIndexingStatus',
}) => {
  const { t } = useLocalTranslation();
  const [isReady, setIsReady] = useState<boolean>(false);
  const [lastIndexedTimestamp, setLastIndexedTimestamp] = useState<string>();
  const [nextIndexingTimestamp, setNextIndexingTimestamp] = useState<string>();
  const [indexingStatus, setIndexingStatus] = useState<MetadataSearchIndexingStatus>();

  const getIndexingStatusString = useCallback(() => {
    let statusString = '';

    if (lastIndexedTimestamp) {
      statusString += `${t('lastIndexed')}: ${lastIndexedTimestamp}`;
    }

    if (lastIndexedTimestamp && nextIndexingTimestamp) {
      statusString += ' | ';
    }

    if (nextIndexingTimestamp) {
      statusString += `${t('nextIndexing')}: ${nextIndexingTimestamp}`;
    }

    return statusString;
  }, [lastIndexedTimestamp, nextIndexingTimestamp, t]);

  const fetchStatus = useCallback(async () => {
    try {
      const { statusPerType = [] } = await getMetadataSearchIndexingStatus();
      const catalogTypeStatus = statusPerType.find(({ entityName }) => entityName === 'catalog');

      if (catalogTypeStatus) {
        const { finishedIndexingAt, nextIndexingAt } = catalogTypeStatus;

        if (finishedIndexingAt) {
          const lastIndexedComputed = dateTimeService.formatDate(finishedIndexingAt);

          setLastIndexedTimestamp(
            catalogTypeStatus.status === MetadataSearchIndexingStatus.INDEXING ? t('inProgress') : lastIndexedComputed,
          );
        } else {
          setLastIndexedTimestamp(undefined);
        }

        if (nextIndexingAt) {
          const nextIndexingComputed = dateTimeService.formatDate(nextIndexingAt);

          setNextIndexingTimestamp(nextIndexingComputed);
        } else {
          setNextIndexingTimestamp(undefined);
        }

        setIndexingStatus(catalogTypeStatus.status);
        setIsReady(true);
      } else {
        setIsReady(false);
      }
    } catch ({ message }) {
      console.error(`An error has occurred: ${message}`);
      setIsReady(false);
    }
  }, [t]);

  const handleRetryClick = useCallback(async () => {
    try {
      await startMetadataSearchIndexing('catalog');
      fetchStatus();
    } catch ({ message }) {
      console.error(`An error has occurred: ${message}`);
    }
  }, [fetchStatus]);

  const getStatusIndicator = useCallback(
    (status: MetadataSearchIndexingStatus): ReactNode => {
      let component;
      let tooltip;

      switch (status) {
        case MetadataSearchIndexingStatus.UPDATED:
          component = <StatusIndicator bgColor={BigidColorsV2.green[600]} />;
          tooltip = t('status.updated');
          break;
        case MetadataSearchIndexingStatus.CANCELLED:
          component = <StatusIndicator bgColor={BigidColorsV2.gray[600]} />;
          tooltip = t('status.cancelled');
          break;
        case MetadataSearchIndexingStatus.FAILED:
          component = <StatusIndicator bgColor={BigidColorsV2.red[600]} />;
          tooltip = (
            <Trans
              t={t}
              i18nKey="status.failed"
              components={{ link: <BigidLink text={t('retry')} onClick={handleRetryClick} underline="none" /> }}
            />
          );
          break;
        case MetadataSearchIndexingStatus.INDEXING:
          component = <BigidLoader size={16} thickness={3} position="relative" />;
          tooltip = t('status.indexing');
          break;
        default:
          component = <StatusIndicator bgColor={BigidColorsV2.gray[400]} />;
      }

      return (
        <BigidTooltip title={tooltip} isDisabled={!tooltip}>
          <StatusIndicatorWrapper>{component}</StatusIndicatorWrapper>
        </BigidTooltip>
      );
    },
    [handleRetryClick, t],
  );

  useEffect(() => {
    fetchStatus();
  }, [fetchStatus]);

  useInterval(() => {
    fetchStatus();
  }, 300000);

  return (
    isReady && (
      <Root data-aid={dataAid} data-tour-id={generateGuidedTourId(dataTourId, ['system', 'health'])}>
        <BigidBody1>{getIndexingStatusString()}</BigidBody1>
        {indexingStatus && getStatusIndicator(indexingStatus)}
      </Root>
    )
  );
};
