import React, { FC, useMemo } from 'react';
import { BigidGridWithToolbar, BigidGridWithToolbarProps } from '@bigid-ui/grid';
import { BigidLoader, BigidPaper } from '@bigid-ui/components';
import makeStyles from '@mui/styles/makeStyles';
import { useUserPreferences } from '../../../components/hooks/useUserPrefrences';
import { $state } from '../../../services/angularServices';
import { getInitialScanProfileFilters, getInitialScanProfileGridColumns } from './ScanProfilesGridConfiguration';
import { ScanProfileActionType, ScanProfileGridRow } from './ScanProfileTypes';
import { fetchScansForGrid, runActionDialog } from './scanProfileService';
import { BigidDeleteIcon, BigidDuplicateIcon, BigidDataSearchIcon } from '@bigid-ui/icons';
import { isScanIsTemplateBasedFFOff } from '../ScanUtils';

const useStyles = makeStyles({
  gridWrapper: {
    width: '100%',
    display: 'flex',
    position: 'relative',
    height: '97%',
    maxHeight: '97%',
    overflow: 'hidden',
  },
});

export const ScanProfiles: FC = () => {
  const classes = useStyles();

  const initialScanProfileGridColumns = useMemo(getInitialScanProfileGridColumns, []);
  const getInitialFilterToolbarConfig = useMemo(() => getInitialScanProfileFilters, []);
  const { isReady, gridColumns, preferences, updatePreferences, filterToolbarConfig } = useUserPreferences({
    stateName: $state.$current.name,
    initialGridColumns: initialScanProfileGridColumns,
    getInitialFilterToolbarConfig,
  });

  const config: BigidGridWithToolbarProps<ScanProfileGridRow> = useMemo(
    () => ({
      pageSize: 100,
      entityName: 'Scan Profiles',
      defaultSorting: preferences?.grid?.sort || [{ field: 'name', order: 'desc' }],
      showSelectionColumn: true,
      showSortingControls: true,
      onGridStateChange: ({ filter, ...gridState }) => updatePreferences({ filterState: { filter }, gridState }),
      filterToolbarConfig,
      fetchData: fetchScansForGrid,
      columns: gridColumns,
      toolbarActions: [
        {
          label: 'Run Scan',
          execute: async actionData => {
            return runActionDialog(actionData, ScanProfileActionType.RUN_SCAN);
          },
          disable: () => false,
          show: ({ selectedRows: [{ isRbacPermitted }] }) => isRbacPermitted,
          icon: BigidDataSearchIcon,
          isInline: true,
          hideActionInToolBar: true,
        },
        {
          label: 'Delete',
          execute: async actionData => runActionDialog(actionData, ScanProfileActionType.DELETE),
          isInline: true,
          hideActionInToolBar: true,
          icon: BigidDeleteIcon,
          disable: () => false,
          show: ({ selectedRows: [{ isRbacPermitted }] }) => isRbacPermitted,
        },
        {
          label: 'Duplicate',
          execute: async actionData => {
            return runActionDialog(actionData, ScanProfileActionType.DUPLICATE);
          },
          disable: () => false,
          show: ({ selectedRows: [{ isRbacPermitted }] }) => isRbacPermitted,
          icon: BigidDuplicateIcon,
          isInline: true,
          hideActionInToolBar: true,
        },
      ],
      isRowDisabled: row => isScanIsTemplateBasedFFOff(row.scanTemplateId),
    }),
    [filterToolbarConfig, gridColumns, preferences?.grid?.sort, updatePreferences],
  );

  return (
    <div className={classes.gridWrapper}>
      <BigidPaper>
        {!isReady && <BigidLoader />}
        {isReady && <BigidGridWithToolbar {...config} />}
      </BigidPaper>
    </div>
  );
};
