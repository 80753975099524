import React, { FC, useContext, useMemo } from 'react';
import { DataSourceConfigurationContext } from '../DataSourceConfigurationContext';
import { useFieldsDetailsFilled } from '../hooks/useUpdateDetailsFilled';
import {
  DataSourceDetailsBoxItem,
  DataSourceDetailsBoxItemProps,
} from './DataSourceDetailsBoxItem/DataSourceDetailsBoxItem';
import { DataSourceConnectionFormField } from '../types';

const generateDetailsFilledItems = (
  callToFillState: Record<string, boolean>,
  fieldsDetailsFilled: DataSourceConnectionFormField[],
) =>
  fieldsDetailsFilled.map(({ name, misc: { callToFill } }) => {
    return {
      ...callToFill,
      done: callToFillState[name],
    };
  });

const mergeSameTitleItems = (detailsFilledItems: DataSourceDetailsBoxItemProps[]) =>
  detailsFilledItems.reduce((resultAcc, { title, done, ...rest }) => {
    const isInListIndex = resultAcc.findIndex(item => item.title === title);
    if (isInListIndex > -1) {
      resultAcc[isInListIndex] = {
        ...resultAcc[isInListIndex],
        done: resultAcc[isInListIndex].done && done,
      };
      return resultAcc;
    }
    return [...resultAcc, { title, done, ...rest }];
  }, []);

export const DataSourceDetailsBox: FC<any> = () => {
  const { callToFillState, fields } = useContext(DataSourceConfigurationContext);
  const fieldsDetailsFilled = useFieldsDetailsFilled(fields);
  const detailsFilledItems = useMemo(
    () => mergeSameTitleItems(generateDetailsFilledItems(callToFillState, fieldsDetailsFilled)),
    [callToFillState, fieldsDetailsFilled],
  );

  return (
    <section data-aid="dataSourceDetailsBox">
      {detailsFilledItems.map(props => (
        <DataSourceDetailsBoxItem key={props.title} {...props} />
      ))}
    </section>
  );
};
