import { BigidBody1 } from '@bigid-ui/components';
import { styled, useTheme } from '@mui/material';
import React, { FC } from 'react';
import { useLocalTranslation } from '../../../translations';
import { getHighlightedValue } from '../../../utils';

const Container = styled(BigidBody1)`
  max-width: 300px;
  overflow: hidden;
  text-overflow: ellipsis;
`;

type Props = {
  owners: string[];
};

export const Owners: FC<Props> = ({ owners }) => {
  const {
    vars: { palette },
  } = useTheme();
  const { t } = useLocalTranslation('owners');

  const ownersWithHighlight = owners.map(getHighlightedValue);
  const valueToDisplay = ownersWithHighlight.length > 0 ? ownersWithHighlight : t('noOwnersDescription');

  return (
    <Container data-aid="DataExplorerObjectOwners" color={palette.bigid.gray500}>
      {t('name')} {valueToDisplay}
    </Container>
  );
};
