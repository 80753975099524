import React, { ChangeEvent, Dispatch, FC, MutableRefObject, SetStateAction, useEffect, useState } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { BigidColorsV2, BigidHeading4, BigidTextField } from '@bigid-ui/components';
import { CustomAppParamsTable } from '../../components/CustomAppParamsTable/CustomAppParamsTable';
import { $state, $stateParams } from '../../../../services/angularServices';
import { InfoBox, MANDATORY_ERROR_MESSAGE, NAME_EXISTS_ERROR_MESSAGE } from '../SetActionPreset/EditActionPreset';
import { CONFIG } from '../../../../../config/common';
import { cloneDeep, isEmpty, isEqual } from 'lodash';
import { customAppService } from '../../../../services/customAppService';
import { notificationService } from '../../../../services/notificationService';
import { CustomAppParam } from '../EditCustomApp/EditCustomApp';
import { GlobalPreset } from '../../utils/CustomAppTypes';

export interface EditGlobalPresetProps {
  globalParams: CustomAppParam[];
  globalPreset: Partial<GlobalPreset>;
  readOnly: boolean;
  setIsFormValid?: Dispatch<SetStateAction<boolean>>;
  setIsValueChanged?: Dispatch<SetStateAction<boolean>>;
  onSaveMutableRef?: MutableRefObject<() => void>;
  onCancelMutableRef?: MutableRefObject<() => void>;
}

export interface GlobalPresetPayload {
  params_key_value?: Record<string, string>;
  name?: string;
  description?: string;
}

const useStyles = makeStyles({
  wrapper: {
    padding: '16px',
  },
  title: {
    color: BigidColorsV2.purple[500],
    paddingBottom: '24px',
  },
  subTitle: {
    paddingBottom: '12px',
  },
  infoContent: {
    paddingBottom: '16px',
    width: '50%',
    minWidth: '500px',
  },
  mandatorySection: {
    paddingLeft: '2px',
    color: BigidColorsV2.red[600],
  },
  presetProperties: {
    width: '25%',
  },
  globalParamsToggleWrapper: {
    color: BigidColorsV2.blue[700],
    display: 'flex',
    paddingBottom: '16px',
  },
  titleSpacing: {
    paddingRight: '8px',
    paddingLeft: '8px',
  },
  actionsParamsWrapper: {
    minHeight: '64px',
  },
});

const getInitialPresetState = (name: string, paramsKeyValue: Record<string, string>) => ({
  name,
  params_key_value: paramsKeyValue,
});

export const EditGlobalPreset: FC<EditGlobalPresetProps> = ({
  globalParams,
  globalPreset,
  readOnly,
  setIsFormValid,
  setIsValueChanged,
  onSaveMutableRef,
  onCancelMutableRef,
}) => {
  const classes = useStyles({});
  const { appInfo, globalPresetId } = $stateParams;
  const readOnlyFromStateOrProps = readOnly ?? $stateParams.readOnly;
  const globalPresetFromStateOrProps = globalPreset ?? $stateParams.globalPreset;

  const initialPresetPayload = getInitialPresetState(
    globalPresetFromStateOrProps?.name,
    globalPresetFromStateOrProps?.params_key_value ||
      appInfo.globalParams.reduce((acc: Record<string, string>, currGlobalParam: CustomAppParam) => {
        return { ...acc, [currGlobalParam.name]: currGlobalParam.value };
      }, {}),
  );

  const [presetData, setPresetData] = useState<GlobalPresetPayload>(initialPresetPayload);
  const [isNameValid, setIsNameValid] = useState<boolean>(false);
  const [isParametersValid, setIsParametersValid] = useState<boolean>(true);
  const [errorMessage, setErrorMessage] = useState<string>('');

  const navigateToEditAppPage = () => {
    $state.go(CONFIG.states.CUSTOM_APP_EDIT, { id: appInfo.id }, { reload: true });
  };
  if (onCancelMutableRef) {
    onCancelMutableRef.current = () => {
      navigateToEditAppPage();
    };
  }

  useEffect(() => setIsNameValid(!errorMessage), [errorMessage, setIsNameValid]);

  const validatePresetName = (name: string) => {
    if (isEmpty(name)) {
      setErrorMessage(MANDATORY_ERROR_MESSAGE);
      return;
    }

    const isNameExists =
      presetData.name !== name && appInfo.globalPresets.some((preset: GlobalPreset) => preset.name === name);
    setErrorMessage(isNameExists ? NAME_EXISTS_ERROR_MESSAGE : '');
  };

  const onTextInputFieldChanged =
    (key: string) =>
    ({ target: { value } }: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
      if (key === 'name') validatePresetName(value);
      onPresetChange({ [key]: value });
    };

  const addNewGlobalPreset = async (presetToSend: GlobalPresetPayload) => {
    try {
      await customAppService.addGlobalPreset(appInfo.id, presetToSend);
      notificationService.success(`Added new global preset successfully`, { shouldCloseOnTransition: false });
    } catch (err) {
      console.error(err);
      const {
        response: { data },
      } = err;
      notificationService.error(data.error, { shouldCloseOnTransition: false });
    }
  };

  const editExistingGlobalPreset = async (presetToSend: GlobalPresetPayload) => {
    try {
      await customAppService.editGlobalPreset(appInfo.id, globalPresetId, presetToSend);
      notificationService.success(`global preset updated successfully`, { shouldCloseOnTransition: false });
    } catch (err) {
      console.error(err);
      const {
        response: { data },
      } = err;
      notificationService.error(data.error, { shouldCloseOnTransition: false });
    }
  };

  if (onSaveMutableRef) {
    onSaveMutableRef.current = async () => {
      const presetToSent = cloneDeep(presetData);
      await (isEmpty(globalPresetFromStateOrProps)
        ? addNewGlobalPreset(presetToSent)
        : editExistingGlobalPreset(presetToSent));
      navigateToEditAppPage();
    };
  }

  const onPresetChange = (obj: any) => setPresetData(state => ({ ...state, ...obj }));

  useEffect(() => {
    if (!readOnlyFromStateOrProps) {
      setIsFormValid(isNameValid && isParametersValid && !!presetData.name);
    }
  }, [isNameValid, isParametersValid, presetData.name, setIsFormValid]);

  useEffect(() => {
    if (!readOnlyFromStateOrProps) {
      setIsValueChanged(!isEqual(presetData, initialPresetPayload));
    }
  }, [initialPresetPayload, presetData, setIsValueChanged]);

  const onPresetParamsChange = (key: string, value: string) => {
    onPresetChange({ params_key_value: { ...presetData.params_key_value, [key]: value } });
  };

  return (
    <div className={classes.wrapper}>
      {!readOnlyFromStateOrProps && (
        <>
          <div className={classes.title}>
            <BigidHeading4 color={'inherit'}>{`${appInfo.name} - Edit Global Preset`}</BigidHeading4>
          </div>
          <InfoBox title={'Preset Name'} isMandatory childClass={classes.presetProperties}>
            <BigidTextField
              errorMessage={errorMessage}
              defaultValue={globalPresetFromStateOrProps ? globalPresetFromStateOrProps.name : ''}
              onChange={onTextInputFieldChanged('name')}
            />
          </InfoBox>
          <InfoBox title={'Preset Description'}>
            <BigidTextField
              id={'description'}
              multiline
              rows={2}
              defaultValue={globalPresetFromStateOrProps?.description}
              onChange={onTextInputFieldChanged('description')}
            />
          </InfoBox>
        </>
      )}
      <div className={classes.actionsParamsWrapper}>
        {
          <InfoBox title={'Global Parameters'}>
            <CustomAppParamsTable
              readOnly={readOnlyFromStateOrProps}
              params={readOnlyFromStateOrProps ? globalParams : appInfo.globalParams}
              onParamChange={onPresetParamsChange}
              setIsValuesValid={setIsParametersValid}
              values={
                readOnlyFromStateOrProps ? globalPresetFromStateOrProps.params_key_value : presetData.params_key_value
              }
              isGlobalParams
            />
          </InfoBox>
        }
      </div>
    </div>
  );
};
