import { createContext } from 'react';
import { ScanTemplate, ScanTypes } from './scanTemplateTypes';

export interface ScanTemplateFormContext {
  scanTemplateFormData: ScanTemplate;
  setScanTemplateFormData: React.Dispatch<React.SetStateAction<ScanTemplate>>;
  setPrevScanType: React.Dispatch<React.SetStateAction<ScanTypes>>;
  isEditMode?: boolean;
  basicDetailsHandlersRef?: any;
}

export const ScanTemplateWizardContext = createContext<ScanTemplateFormContext>(null);
