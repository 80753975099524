import './missingPurpose.component.scss';
import template from './missingPurpose.component.html';
import { module } from 'angular';
import { headerEventEmitter, HeaderEvents } from '../../../react/services/eventEmitters/headerEvents';
import { sessionStorageService } from '../../../common/services/sessionStorageService';
const app = module('app');

app.component('missingpurpose', {
  template,
  controller: function ($http, $document, appSettings, dataMappingService, DeleteConfirmation, taskListService) {
    'ngInject';
    const commentBox = $document[0].getElementById('commentBox');

    const ctrl = this;
    let collaborationID = null;
    ctrl.commentsText = [];
    ctrl.mentionedUsers = [];

    this.$onInit = () => {
      if (this.entity.hasOpenTasks) {
        ctrl.actionName = 'Resolved';
        ctrl.currentEntity = this.entity;
        this.getCollaborationComments(this.task);
      } else {
        ctrl.actionName = 'Save';
      }
    };

    ctrl.getCollaborationComments = task => {
      dataMappingService.getCollaborationByEntityID(this.entity._id).then(result => {
        const collaboration = result.find(
          collaboration => collaboration.status === 'open' && collaboration._id === task._id,
        );
        if (collaboration) {
          collaborationID = collaboration._id;
          const comments = (collaboration.comments || []).sort(
            (b, a) => new Date(b.created_at) - new Date(a.created_at),
          );
          ctrl.commentsText = comments.map(comment => ({
            userName: sessionStorageService.get('userName'),
            text: comment.text,
          }));
        } else {
          collaborationID = null;
          ctrl.commentsText = [];
        }

        setTimeout(() => {
          commentBox.scrollTop = commentBox.scrollHeight;
        }, 500);
      });
    };

    //Save or Resolved Action
    ctrl.collaborationAction = commentText => {
      const data = this.setCollaborationData(commentText);
      //case of save the first collaboration
      if (!ctrl.currentEntity.hasOpenTasks) {
        dataMappingService.createCollaboration(data).then(() => {
          this.onCollaborationCreated({ entity: ctrl.currentEntity });
          headerEventEmitter.emit(HeaderEvents.UPDATE_UNREAD_TASKS);
        });
      }
      //case of resolved collaboration
      else {
        const modalOptions = {
          closeButtonText: 'Close',
          actionButtonText: 'Resolved',
          headerText: `Resolve Collaboration`,
          bodyText: `Are you sure you want to resolve the missing purpose"?`,
        };

        DeleteConfirmation.showModal({}, modalOptions).then(() => {
          dataMappingService.updateCollaborationToResolved({ status: 'resolved' }, collaborationID).then(() => {
            this.onCollaborationResolved({ entity: ctrl.currentEntity });
          });
        });
      }
    };

    ctrl.sendComment = () => {
      const data = this.setCommentData();

      taskListService.createNewComment(data, collaborationID).then(() => {
        ctrl.commentsText.push({
          userName: sessionStorageService.get('userName'),
          text: ctrl.comment,
        });
        ctrl.comment = '';

        setTimeout(() => {
          commentBox.scrollTop = commentBox.scrollHeight;
        }, 500);
      });
    };

    ctrl.createPartArrayForRenderingStyledText = commentText => {
      let isFirstCharSuitable = false;
      const parts = [...commentText].reduce((agg, nxt) => {
        if (nxt === '[') {
          isFirstCharSuitable = true;
          agg.push({ type: 'mention', value: nxt });
        } else if (agg.length === 0) {
          agg.push({ type: 'text', value: nxt });
        } else {
          const last = agg.pop();
          switch (last.type) {
            case 'text':
              last.value = last.value + nxt;
              agg.push(last);
              break;
            case 'mention':
              if (isFirstCharSuitable === true) {
                if (nxt === '~') {
                  last.value = last.value + nxt;
                  agg.push(last);
                  isFirstCharSuitable = false;
                }
              } else {
                if (nxt === ']') {
                  last.value = last.value + nxt;
                  agg.push(last);
                } else if (
                  nxt === '_' ||
                  nxt === '@' ||
                  nxt === '.' ||
                  (nxt >= 'a' && nxt <= 'z') ||
                  (nxt >= 'A' && nxt <= 'Z') ||
                  (nxt >= '0' && nxt <= '9')
                ) {
                  last.value = last.value + nxt;
                  agg.push(last);
                } else {
                  agg.push(last);
                  agg.push({ type: 'text', value: nxt });
                }
              }
              break;
            default:
              agg.push(last);
              break;
          }
        }
        return agg;
      }, []);

      return parts;
    };

    ctrl.setCollaborationData = commentText => {
      const data = {
        businessEntityId: ctrl.currentEntity._id,
        businessProcessId: ctrl.currentEntity.businessProcessId,
        status: 'open',
        type: 'attributeWithoutPurpose',
        initiator: sessionStorageService.get('userName'),
        comments: [
          {
            text: commentText,
            mentions: ctrl.mentionedUsers,
          },
        ],
      };

      return data;
    };

    ctrl.setCommentData = () => {
      const data = {
        user: sessionStorageService.get('userName'),
        text: ctrl.comment,
        mentions: ctrl.mentionedUsers,
      };

      return data;
    };

    ctrl.onMentionsTextareaSubmit = (value, mentions) => {
      ctrl.comment = value;
      ctrl.mentionedUsers = mentions;
      ctrl.sendComment();
    };

    ctrl.onMentionsTextareaChanged = (value, mentions) => {
      ctrl.comment = value;
      ctrl.mentionedUsers = mentions;
    };
  },
  bindings: {
    entity: '<',
    onCollaborationCreated: '&',
    onCollaborationResolved: '&',
    onCollaborationAddComment: '&',
    onClose: '&',
    task: '<',
  },
});
