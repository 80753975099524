import {
  ActionSidebarContent,
  ActionSidebarHeader,
  ActionSidebarItemHeader,
  ActionSidebarItemActions,
  ActionSidebarWrapper,
  ActionSidebarCard,
  ActionSidebarIndicator,
} from '../BigidSuggestedActionBoxStyles';

export const ActionSidebar = {
  Content: ActionSidebarContent,
  Header: ActionSidebarHeader,
  Wrapper: ActionSidebarWrapper,
  Card: ActionSidebarCard,
  Indicator: ActionSidebarIndicator,
};

export const ActionSidebarItem = {
  Header: ActionSidebarItemHeader,
  Actions: ActionSidebarItemActions,
};
