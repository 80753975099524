import React, { FC, Fragment } from 'react';
import { SystemDialogContentProps } from '../../../services/systemDialogService';
import { Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

interface ScanConfirmationDialogContentProps {
  bodyLine1: string;
  bodyLine2: string;
}

const useStyles = makeStyles({
  line1: {
    marginBottom: 20,
  },
  line2: {
    fontWeight: 700,
  },
});

export const ScanConfirmationDialogContent: FC<SystemDialogContentProps<ScanConfirmationDialogContentProps>> = ({
  bodyLine1,
  bodyLine2,
}) => {
  const { line1, line2 } = useStyles();

  return (
    <Fragment>
      <Typography className={line1} variant={'body1'}>
        {bodyLine1}
      </Typography>
      <Typography className={line2} variant={'body1'}>
        {bodyLine2}
      </Typography>
    </Fragment>
  );
};
