import { module } from 'angular';
const app = module('app');

app.factory('newDataSourceConnectionService', [
  '$http',
  'appSettings',
  function ($http, appSettings) {
    return {
      getHadoopConfiguration: function getHadoopConfiguration(hadoopConfUrl) {
        var request = { url: hadoopConfUrl };
        return $http.post(appSettings.serverPath + '/' + appSettings.version + '/hadoopConfig', request).then(
          function (response) {
            return response.data;
          },
          function errorCallback(response) {
            //todo error handling
            return;
          },
        );
      },
    };
  },
]);
