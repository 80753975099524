import { useMemo, useState } from 'react';
import {
  BigidSyncIcon,
  BigidTestIcon,
  BigidApplyIcon,
  BigidClearIcon,
  BigidDeleteIcon,
  BigidDisableIcon,
  BigidDuplicateIcon,
  BigidExternalLinkIcon,
  BigidCheckedViewIcon,
} from '@bigid-ui/icons';
import { generateDataAid } from '@bigid-ui/utils';
import { BigidMenuItemProps } from '@bigid-ui/components';
import { useLocalTranslation } from '../translations';
import { ClassifierGridRow } from '../types/ClassifierTypes';
import * as classifiersManagementUtils from '../utils/utils';
import { handleEnableDisable, handleDeleteClassifier, handleDuplicateClassifier } from '../ClassifiersActions';
import { useExcludeList } from './useExcludeList';
import {
  updateGridItemById,
  deleteGridItemById,
  isRequestSucceeded,
  addGridItem,
  isClassfierExcludeListFF,
  isClassifierTesterEnabled,
  getDocAppUrl,
  isClassifierNeedsToBeUpdated,
  isClassifierVersionDowngradeAvailable,
  getShouldShowClassifierTuning,
  goToClassifierTuning,
  getIsClassifiersTuningEnabled,
} from '../utils/utils';
import { notificationService } from '../../../services/notificationService';
import { usePermissions } from './usePermissions';
import { getEnabledDisabledEventName } from '../utils/eventsUtils';
import { ClassifiersEventsEnum, EnabledType } from '../types/Events';
import { analyticsService } from '../../../services/analyticsService';
import {
  openClassifierRestoreVersionDialog,
  openClassifierUpdatePreviewDialog,
} from '../../../components/ClassifierUpdatePreview/classifierUpdatePreviewService';
import { Classifier } from '../../../services/classifiersService';
import { openCreateDuplicateClassifierDialog } from '../../../../administration/classifiers/BigidDuplicateClassifierDialog/BigidDuplicateClassifierDialog';

const DATA_AID = 'drop-down-item';

interface useDropDownActionsProps {
  classifier: ClassifierGridRow;
  setActiveClassifier: (classifier: ClassifierGridRow) => void;
  setClassifiersToUpdateCount: (cb: (prevCount: number) => number) => void;
  onSidePanelClose: () => void;
  openClassifierTesterDialog: () => void;
  isDocMenuItemVisible: boolean;
  docAppId: string;
  updateActiveClassifierRef: (classifier: ClassifierGridRow) => void;
}

export const useDropDownActions = ({
  classifier,
  setActiveClassifier,
  setClassifiersToUpdateCount,
  onSidePanelClose,
  openClassifierTesterDialog,
  isDocMenuItemVisible,
  docAppId,
  updateActiveClassifierRef,
}: useDropDownActionsProps) => {
  const { t } = useLocalTranslation('actions');
  const [isLoading, setIsLoading] = useState(false);
  const { excludeListHandler } = useExcludeList();

  const {
    _id,
    classification_name,
    classifierType,
    isOutOfTheBox,
    enabled,
    totalFields,
    attributeType,
    friendly_name,
  } = classifier;
  const isMetadata = classifiersManagementUtils.isMetadata(classifierType);
  const isDoc = classifiersManagementUtils.isDoc(classifierType);
  const isRegex = classifiersManagementUtils.isRegex(classifierType);

  const { isDeletePermitted, isEditPermitted, isCreatePermitted } = usePermissions();

  const onEnableDisable = async (enabled: boolean) => {
    setIsLoading(true);
    const payload = { enabled };

    await handleEnableDisable({
      classifier,
      payload,
      successCallback: () => {
        const eventName = getEnabledDisabledEventName(enabled, EnabledType.sidePanel);
        analyticsService.trackManualEvent(eventName, {
          classifier: { ...classifier, ...payload },
        });

        updateGridItemById(_id, payload);
        setActiveClassifier({ ...classifier, ...payload });
      },
    });

    setIsLoading(false);
  };

  const handleDuplicate = async () => {
    try {
      const { duplicateClassifierName: duplicateClassificationName } = await openCreateDuplicateClassifierDialog(
        'xs',
        classifier.classification_name,
        true,
      );

      if (duplicateClassificationName) {
        setIsLoading(true);
        const newClassifier = await handleDuplicateClassifier(classifier, duplicateClassificationName);

        if (newClassifier?._id) {
          addGridItem({ ...newClassifier, id: newClassifier._id }, newClassifier._id);
          setActiveClassifier({ ...newClassifier, _id: newClassifier._id } as ClassifierGridRow);
          updateActiveClassifierRef({ ...newClassifier, _id: newClassifier._id });
        }
      }
    } catch (error) {
      notificationService.error(t('notifications.failedToDuplicate'));
    } finally {
      setIsLoading(false);
    }
  };

  const handleDelete = async () => {
    const response = await handleDeleteClassifier(classifier, setIsLoading);
    if (isRequestSucceeded(response) || response?.data?.status === 'success') {
      analyticsService.trackManualEvent(ClassifiersEventsEnum.DELETE_CLASSIFIER_SIDE_PANEL_ACTION, {
        classifier,
      });

      deleteGridItemById(classifier);
      onSidePanelClose();
    }
  };

  const handleUpdateVersion = async () => {
    openClassifierUpdatePreviewDialog(classifier as unknown as Classifier, updatedClassifierVersion => {
      const updatedClassifier = {
        ...classifier,
        ...updatedClassifierVersion,
        version_state: updatedClassifierVersion.versionState,
      } as ClassifierGridRow;

      setActiveClassifier(updatedClassifier);
      updateGridItemById(updatedClassifier._id, updatedClassifier);
      setClassifiersToUpdateCount((prev: number) => prev - 1);
    });
  };

  const handleRestorePreviousVersion = async () => {
    const updatedClassifierVersion = await openClassifierRestoreVersionDialog(classifier as unknown as Classifier);
    const updatedClassifier = {
      ...classifier,
      ...updatedClassifierVersion,
      version_state: updatedClassifierVersion.versionState,
    } as ClassifierGridRow;
    setActiveClassifier(updatedClassifier);
    setClassifiersToUpdateCount((prev: number) => prev + 1);
    updateGridItemById(updatedClassifier._id, updatedClassifier);
  };

  const getMenuItems = (): BigidMenuItemProps[] => {
    const items: BigidMenuItemProps[] = [];

    // Enable classifier
    if (isEditPermitted && !enabled) {
      items.push({
        label: t('enable'),
        startIcon: BigidApplyIcon,
        onClick: async () => onEnableDisable(true),
        dataAid: generateDataAid(DATA_AID, ['enable']),
      });
    }

    // Disable classifier
    if (isEditPermitted && enabled) {
      items.push({
        label: t('disable'),
        startIcon: BigidDisableIcon,
        onClick: async () => onEnableDisable(false),
        dataAid: generateDataAid(DATA_AID, ['disable']),
      });
    }

    // Classifier tuning
    if (getIsClassifiersTuningEnabled() && getShouldShowClassifierTuning(totalFields)) {
      items.push({
        label: t('classifierTuning'),
        startIcon: BigidCheckedViewIcon,
        onClick: async () => {
          goToClassifierTuning(classification_name, attributeType, friendly_name);
        },
        dataAid: generateDataAid(DATA_AID, ['classifier-tuning']),
      });
    }

    // Exclude
    if (isEditPermitted && isClassfierExcludeListFF() && !isMetadata && !isDoc) {
      items.push({
        label: t('exclude'),
        startIcon: BigidClearIcon,
        onClick: async () => {
          analyticsService.trackManualEvent(ClassifiersEventsEnum.EXCLUDE_LIST_SIDE_PANEL_ACTION, classifier);
          excludeListHandler(classifier);
        },
        dataAid: generateDataAid(DATA_AID, ['exclude']),
      });
    }

    // Duplicate classifier
    if (isCreatePermitted && isRegex) {
      items.push({
        label: t('duplicate'),
        startIcon: BigidDuplicateIcon,
        onClick: handleDuplicate,
        dataAid: generateDataAid(DATA_AID, ['duplicate']),
      });
    }

    // Test classifier
    if (
      isClassifierTesterEnabled() &&
      classifier.isClassifierTesterSupported !== false &&
      isCreatePermitted &&
      isRegex
    ) {
      items.push({
        label: t('testClassifier'),
        startIcon: BigidTestIcon,
        onClick: openClassifierTesterDialog,
        dataAid: generateDataAid(DATA_AID, ['testClassifier']),
      });
    }

    // Update version
    if (isDeletePermitted && isClassifierNeedsToBeUpdated(classifier)) {
      items.push({
        label: t('updateVersion'),
        startIcon: BigidSyncIcon,
        onClick: handleUpdateVersion,
        dataAid: generateDataAid(DATA_AID, ['update-version']),
      });
    }

    // Restore Previous Version
    if (isDeletePermitted && isClassifierVersionDowngradeAvailable(classifier)) {
      items.push({
        label: t('restorePreviousVersion'),
        startIcon: BigidSyncIcon,
        onClick: handleRestorePreviousVersion,
        dataAid: generateDataAid(DATA_AID, ['restore-previous-version']),
      });
    }

    // Delete classifier
    if (isDeletePermitted && !isOutOfTheBox && !isDoc) {
      items.push({
        label: t('delete'),
        startIcon: BigidDeleteIcon,
        onClick: handleDelete,
        dataAid: generateDataAid(DATA_AID, ['delete']),
      });
    }

    // Edit DOC classifier
    if (isDoc && isDocMenuItemVisible) {
      items.push({
        label: t('editDOCClassifier'),
        startIcon: BigidExternalLinkIcon,
        onClick: () => {
          analyticsService.trackManualEvent(ClassifiersEventsEnum.EDIT_DOC_CLASSIFIER_SIDE_PANEL_ACTION);
          window.open(getDocAppUrl(docAppId), '_blank');
        },
        dataAid: generateDataAid(DATA_AID, ['edit-doc']),
      });
    }

    return items;
  };

  const items = useMemo(() => getMenuItems(), [classifier]);

  return {
    isLoading,
    setIsLoading,
    items,
  };
};
