import { Category } from './ClassifierTypes';

export enum ASSIGN_CATEGORIES_TYPE {
  DEFAULT = 'default',
  BULK = 'bulk',
}

export type AssignCategories = {
  _id?: string;
  original_name?: string;
  categories: Category[];
};

export type CategoryAssignmentObject = Pick<Category, 'unique_name' | 'display_name'>;

export interface AssignCategoryResponse {
  category: CategoryAssignmentObject;
  original_name: string;
}

export interface AssignCategoryRequest {
  original_name: string;
  categories: CategoryAssignmentObject[];
}

export interface CreateCategoryResponse {
  success: boolean;
  glossary_id: string;
}
