import React, { FC } from 'react';
import { makeStyles } from '@mui/styles';
import { BigidColorsV2, BigidLink } from '@bigid-ui/components';

interface DocumentsTabProps {
  documentsLinks: string[];
}

const useStyles = makeStyles({
  root: {
    backgroundColor: BigidColorsV2.gray[200],
    minHeight: '152px',
    padding: '8px',
  },
  linkWrapper: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
});

export const DocumentsTab: FC<DocumentsTabProps> = ({ documentsLinks = [] }) => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      {documentsLinks.length
        ? documentsLinks.map(documentLink => (
            <div key={documentLink}>
              <div className={classes.linkWrapper}>
                <BigidLink text={documentLink} href={documentLink} />
              </div>
            </div>
          ))
        : 'No Documents Available'}
    </div>
  );
};
