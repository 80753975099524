import React, { FC } from 'react';
import { styled } from '@mui/material';
import { BigidBody1 } from '@bigid-ui/components';
import { generateDataAid } from '@bigid-ui/utils';
import { useLocalTranslation } from '../translations';

const Root = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 4px;
  width: 300px;
`;

const Body = styled('ul')`
  display: flex;
  flex-direction: column;
  padding-left: 15px;
`;

interface CurationGuidedTourMoreActionsProps {
  dataAid: string;
}

export const CurationGuidedTourReviewSample: FC<CurationGuidedTourMoreActionsProps> = ({ dataAid }) => {
  const { t } = useLocalTranslation('CurationGuidedTour.DataSource');

  return (
    <Root data-aid={dataAid}>
      <Body>
        <li>
          <BigidBody1 data-aid={generateDataAid(dataAid, ['sampleButton'])}>{t('sampleButtonContent')}</BigidBody1>
        </li>
        <li>
          <BigidBody1 data-aid={generateDataAid(dataAid, ['reviewButton'])}>{t('reviewButtonContent')}</BigidBody1>
        </li>
      </Body>
    </Root>
  );
};
