import { module } from 'angular';
const app = module('app');

app.factory('privacyComplianceService', function ($http, appSettings, commonMethods) {
  'ngInject';

  return {
    getComplianceSummaries: (filter = {}) => {
      const queryString = commonMethods.encodeUrlParams(filter);

      return $http
        .get(`${appSettings.serverPath}/${appSettings.version}/complianceSummaries${queryString}`)
        .then(response => response.data);
    },
  };
});
