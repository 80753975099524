import React, { FC, useEffect, useContext, useState, useRef } from 'react';
import { Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { BigidHeading5, BigidLoader } from '@bigid-ui/components';
import { generateDataAid } from '@bigid-ui/utils';
import { PreDiscoverContext, ReducerActions } from '../../../hooks';
import {
  fetchRegulations,
  selectAndSortRegulations,
  regulationsToCheckboxOptions,
  updateSelectionArray,
} from '../../../../fmsdServices';
import { RegulationsList, CountryItem, SearchBox } from '../../../../FmsdComponents';
import { FmsdPreDiscoverCustomizeTypes } from '../../FmsdPreDiscoverViews';
import { useLocalTranslation } from '../../../../translations';
import { generalRegulations } from '../FmsdCategories/FmsdCategoriesConfig';
import {
  FmsdPageViewsEvents,
  FmsdTrackingEvents,
  trackFmsdEvent,
  trackFmsdPageView,
} from '../../../../fmsdEventsTrackerUtils';
import { debounce } from 'lodash';

const useStyles = makeStyles(theme => ({
  title: {
    textAlign: 'center',
    marginBottom: 12,
  },
  subTitle: {
    textAlign: 'center',
    marginBottom: 40,
  },
  searchBar: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 29,
    justifyContent: 'space-between',
  },
  selections: {
    color: theme.vars.palette.bigid.primary900,
    fontSize: 14,
    marginLeft: 8,
    paddingLeft: 8,
    borderLeft: '1px solid #5C63F7',
    fontWeight: 400,
  },
  categoriesButton: {
    background: 'none',
    border: 'none',
    padding: 0,
    fontSize: 14,
    color: theme.vars.palette.bigid.primary400,
    marginBottom: 23,
  },
}));

export const FmsdRegulations: FC = () => {
  const {
    selectionsData: { regulations: selectedRegulations },
    wizardActions: { setIsNextButtonDisabled, disableNextSteps, isNextStepDisabled },
    dispatch,
  } = useContext(PreDiscoverContext);
  const [regulationsData, setRegulationsData] = useState<CountryItem[]>([]);
  const [searchText, setSearchText] = useState<string>('');
  const [isLoading, setIsLoading] = useState(true);
  const selectedRegulationsRef = useRef<string[]>();
  const { title, subTitle, searchBar, selections, categoriesButton } = useStyles();
  const { t } = useLocalTranslation('Regulations');

  selectedRegulationsRef.current = selectedRegulations;

  const updateSelectedRegulations = (regulations: string[]) => {
    dispatch({
      type: ReducerActions.UPDATE_SELECTED_REGULATIONS,
      payload: { selectionData: { regulations, classifiersMethodology: FmsdPreDiscoverCustomizeTypes.REGULATIONS } },
    });
  };

  useEffect(() => {
    const fetchData = async () => {
      const regData = await fetchRegulations();
      const filteredRegulations = regData.filter(
        ({ name }) => !generalRegulations.find(generalRegulation => name === generalRegulation),
      );

      const regulationsOptions = regulationsToCheckboxOptions(filteredRegulations);
      setRegulationsData(selectAndSortRegulations(regulationsOptions, selectedRegulations));
      setIsLoading(false);
    };

    if (regulationsData.length) {
      setRegulationsData(selectAndSortRegulations(regulationsData, selectedRegulations));
    } else {
      fetchData();
    }

    setIsNextButtonDisabled(!selectedRegulations.length);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedRegulations]);

  useEffect(() => {
    trackFmsdPageView(FmsdPageViewsEvents.FMSD_REGULATIONS_PAGE_VIEW);
  }, []);

  const onRegulationSelect = (id: React.ReactText, isSelected: boolean) => {
    const newSelection = updateSelectionArray(id, isSelected, selectedRegulationsRef.current);
    trackFmsdEvent(FmsdTrackingEvents.FMSD_REGULATIONS_REGULATION_CLICK, { [FmsdTrackingEvents.REGULATION_TYPE]: id });
    updateSelectedRegulations(newSelection);
    if (!isNextStepDisabled()) disableNextSteps();
  };

  const switchToCategories = () => {
    dispatch({
      type: ReducerActions.SWITCH_CUSTOMIZE_VIEW,
      payload: {
        selectionData: {
          classifiersMethodology: FmsdPreDiscoverCustomizeTypes.CATEGORIES,
        },
      },
    });
    trackFmsdEvent(FmsdTrackingEvents.FMSD_REGULATIONS_SWITCH_TO_CATEGORIES_CLICK);
  };

  const onSearch = debounce((searchText: string) => setSearchText(searchText), 100);

  return (
    <>
      {isLoading ? (
        <BigidLoader />
      ) : (
        <div data-aid={generateDataAid('FmsdRegulations', ['main', 'container'])}>
          <Typography variant="h1" className={title}>
            {t('title')}
          </Typography>
          <Typography variant="body1" className={subTitle}>
            {t('subTitle')}
          </Typography>
          <div className={searchBar}>
            <BigidHeading5>
              {t('regulationsTitle')}
              <span
                className={selections}
                data-aid={generateDataAid('FmsdRegulations', ['selected', 'regulations', 'indicator'])}
              >{`${selectedRegulations.length} ${t('selectedText')}`}</span>
            </BigidHeading5>
            <SearchBox onSearch={onSearch} placeholder={t('searchboxPlaceholder')} />
          </div>
          {regulationsData.length && (
            <RegulationsList
              columnCount={2}
              countriesList={regulationsData}
              dataAid="recommenedRegulations"
              onCheckboxChange={onRegulationSelect}
              searchText={searchText}
            />
          )}
          <button
            className={categoriesButton}
            onClick={switchToCategories}
            data-aid={generateDataAid('FmsdRegulations', ['switchToCategories', 'button'])}
          >
            {t('categoriesViewButtonText')}
          </button>
        </div>
      )}
    </>
  );
};
