import React from 'react';
import { BigidGridColumn, BigidGridColumnTypes, BigidGridWithToolbarProps, BigidGridRow } from '@bigid-ui/grid';
import { ExecutionPayload } from '../../CustomApp/views/CustomAppActions/CustomAppActions';
import { ActionItemPresetsEntity, ActionItemResponse } from '../../CustomApp/types';
import { cloudProviderLabel, STATUS_TO_LABEL, CloudProvider } from '../constants';
import { getTheRequiredColorsToChip, goToDataSourcesWithPresetFilter } from './autoDiscoveryGridUtils';
import { AutoDiscoveryConfigNameCell } from './AutoDiscoveryConfigNameCell';
import { convertCronToReadableString } from '../../../services/cronStringConversionService';
import { TFunction } from 'i18next';
import { ExecutionStatus } from '../../CustomApp/components/PresetCard/PresetLatestExecutionStatus';
import { $state } from '../../../services/angularServices';
import { CONFIG } from '../../../../config/common';
import { getApplicationPreference } from '../../../services/appPreferencesService';
import { AutoDiscoveryPresetConfigTabsEnum } from '../AutoDiscoveryWizard/autoDiscoveryWizardContext';
import { BigidFilterFunnelAddIcon } from '@bigid-ui/icons';
import { styled } from '@mui/material';
import { BigidLink } from '@bigid-ui/components';
import { generateDataAid } from '@bigid-ui/utils';

export interface AutoDiscoveryConfigModel extends BigidGridRow, ActionItemPresetsEntity {
  _id: string;
  type: CloudProvider;
  latestRun?: ExecutionPayload & { progress: number };
  dsCount?: number;
  appRunAction: ActionItemResponse;
  appStopAction: ActionItemResponse;
  appId: string;
  isMultiConfig?: boolean;
}

const DsCountCellContent = styled('div')`
  display: flex;
  justify-content: space-between;
  & > svg {
    visibility: hidden;
  }
  :hover {
    & > svg {
      visibility: visible;
    }
  }
`;

export const getAutoDiscoveryDefaultColumns: (t: TFunction) => BigidGridColumn<AutoDiscoveryConfigModel>[] = t => {
  const isSaas = getApplicationPreference('USE_SAAS');
  return [
    {
      title: t('columns.name'),
      name: 'name',
      sortingEnabled: true,
      width: 300,
      type: BigidGridColumnTypes.CUSTOM,
      getCellValue: row => <AutoDiscoveryConfigNameCell row={row} />,
    },
    {
      title: t('columns.type'),
      name: 'type',
      sortingEnabled: true,
      width: 120,
      type: BigidGridColumnTypes.TEXT,
      getCellValue: ({ type }) => cloudProviderLabel[type],
    },
    {
      title: t('columns.latestRunStatusEnum'),
      name: 'latestRun.status_enum',
      sortingEnabled: true,
      width: 180,
      getCellValue: ({ latestRun }) => {
        return {
          chip: {
            label: STATUS_TO_LABEL[latestRun?.status_enum],
            ...getTheRequiredColorsToChip(latestRun?.status_enum),
            tooltipProps: {
              title:
                latestRun?.status_enum === ExecutionStatus.IN_PROGRESS
                  ? `${latestRun.progress}%`
                  : STATUS_TO_LABEL[latestRun?.status_enum],
            },
          },
        };
      },
      type: BigidGridColumnTypes.CHIP,
    },
    {
      title: t('columns.dsCount'),
      name: 'dsCount',
      sortingEnabled: true,
      width: 128,
      type: BigidGridColumnTypes.CUSTOM,
      getCellValue: ({ dsCount, _id, name }) => {
        if (!dsCount) return dsCount;
        return (
          <DsCountCellContent
            data-aid={`DsCountCellContent-${name}`}
            onClick={() => goToDataSourcesWithPresetFilter(_id)}
          >
            <BigidLink
              text={String(dsCount || 0)}
              underline={'always'}
              onClick={() => goToDataSourcesWithPresetFilter(_id)}
            />
            <BigidFilterFunnelAddIcon />
          </DsCountCellContent>
        );
      },
    },
    {
      title: t('columns.latestRunUpdatedAt'),
      name: 'latestRun.updated_at',
      sortingEnabled: true,
      width: 150,
      type: BigidGridColumnTypes.DATE,
      getCellValue: ({ latestRun }) => latestRun?.updated_at,
    },
    {
      title: t('columns.scheduled'),
      name: 'scheduled',
      sortingEnabled: false,
      width: 190,
      type: BigidGridColumnTypes.TEXT,
      getCellValue: ({ isScheduled, cronExpression }) =>
        isScheduled ? convertCronToReadableString(cronExpression) : 'Not Scheduled',
    },
    ...(isSaas
      ? []
      : [
          {
            title: t('columns.activityLog'),
            name: 'activityLog',
            sortingEnabled: false,
            width: 190,
            type: BigidGridColumnTypes.LINK,
            formatter: {
              onClick: async ({ row: { type, _id } }) => {
                const params = {
                  type,
                  id: _id,
                  tab: AutoDiscoveryPresetConfigTabsEnum.audit,
                };
                $state.go(CONFIG.states.AUTO_DISCOVERY_PRESET_CONFIG, params);
                return {};
              },
            },
            getCellValue: () => ({
              link: {
                text: 'Go to Activity Log',
              },
            }),
          } as BigidGridColumn<AutoDiscoveryConfigModel>,
        ]),
  ];
};

const autoDiscoveryTypesOptions = [
  {
    label: cloudProviderLabel[CloudProvider.AWS],
    value: CloudProvider.AWS,
    isSelected: false,
  },
  {
    label: cloudProviderLabel[CloudProvider.AZURE],
    value: CloudProvider.AZURE,
    isSelected: false,
  },
  {
    label: cloudProviderLabel[CloudProvider.GCP],
    value: CloudProvider.GCP,
    isSelected: false,
  },
  {
    label: cloudProviderLabel[CloudProvider.ONTAP],
    value: CloudProvider.ONTAP,
    isSelected: false,
  },
];

const statusOptions = Object.entries(STATUS_TO_LABEL).map(([key, value]) => ({
  label: value,
  value: key,
  isSelected: false,
}));

export async function getAutoDiscoveryFilterConfig(
  t: TFunction,
): Promise<BigidGridWithToolbarProps<AutoDiscoveryConfigModel>['filterToolbarConfig']> {
  return {
    filters: [
      {
        title: t('toolbar.typeFilter'),
        field: 'type',
        operator: 'in',
        disabled: true,
        options: autoDiscoveryTypesOptions,
        value: [],
      },
      {
        title: t('toolbar.statusFilter'),
        field: 'latestRun.status_enum',
        operator: 'in',
        disabled: true,
        options: statusOptions,
        value: [],
      },
    ],
    searchConfig: {
      searchFilterKeys: ['name'],
      initialValue: '',
      operator: 'textSearch',
    },
  };
}
