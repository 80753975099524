import React, { FC, useContext, useEffect, useMemo, useState } from 'react';
import { AutoDiscoveryWizardContext } from '../../autoDiscoveryWizardContext';
import { AppInfo } from '../../../../CustomApp/utils/CustomAppTypes';
import { CustomAppAction } from '../../../../CustomApp/views/CustomAppActions/CustomAppActions';
import { getCustomAppActionsDetails } from '../../../../CustomApp/utils/CustomAppUtils';
import { BigidLoader } from '@bigid-ui/components';
import { AutoDiscoveryActivityLogGrid } from '../AutoDiscoveryActivityLogGrid/AutoDiscoveryActivityLogGrid';
import { AutoDiscoveryPresetConfigLogsTabStyledWrapper } from '../styled';

export const AutoDiscoveryPresetConfigLogsTab: FC = () => {
  const { discoveryConfigData } = useContext(AutoDiscoveryWizardContext);
  const [actions, setActions] = useState<CustomAppAction[]>(null);
  const appInfo = useMemo<AppInfo>(() => {
    return {
      id: discoveryConfigData?.configData?.appId,
      settings: { isRemoteApplication: false },
    } as AppInfo;
  }, [discoveryConfigData?.configData?.appId]);

  useEffect(() => {
    const updateActions = async () => {
      setActions(await getCustomAppActionsDetails(discoveryConfigData?.configData?.appId));
    };
    updateActions();
  }, [discoveryConfigData?.configData?.appId]);

  const isReady = discoveryConfigData?.configData?.appId && !!actions?.length;

  return (
    <AutoDiscoveryPresetConfigLogsTabStyledWrapper data-aid={'AutoDiscoveryPresetConfigLogsTab'}>
      {isReady ? (
        <AutoDiscoveryActivityLogGrid presetId={discoveryConfigData?.id} appInfo={appInfo} actions={actions} />
      ) : (
        <BigidLoader />
      )}
    </AutoDiscoveryPresetConfigLogsTabStyledWrapper>
  );
};
