import React, { FC, useState, useRef, PropsWithChildren } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { DashboardInsightsArrow } from './DashboardInsightsArrow';
import { DashboardInsightsNavigation } from './DashboardInsightsNavigation';

const useStyles = makeStyles({
  wrapper: {
    display: 'flex',
    paddingTop: '20px',
    overflow: 'hidden',
  },
  horizontalContainerOuter: {
    overflow: 'hidden',
  },
  horizontalContainerInner: {
    display: 'flex',
    transitionDuration: '0.5s',
  },
});

const MAX_ITEMS_ON_PART = 4;

//TODO Make it more flexible and move to bigid-ui/components
export const DashboardInsightsArrowsContainer: FC<PropsWithChildren> = ({ children }) => {
  const containerRef = useRef(null);
  const viewPartsLength = Math.ceil((children as []).filter(element => element !== null).length / MAX_ITEMS_ON_PART);
  const [currentViewPartIndex, setCurrentViewPartIndex] = useState(1);
  const { horizontalContainerInner, horizontalContainerOuter, wrapper } = useStyles({});
  const translateX = `translateX(-${
    (currentViewPartIndex - 1) * (containerRef.current ? containerRef.current.offsetWidth : 0)
  }px)`;

  return (
    <div className={wrapper}>
      <DashboardInsightsArrow
        show={viewPartsLength > 1 && currentViewPartIndex !== 1}
        onClick={() => {
          setCurrentViewPartIndex(current => current - 1);
        }}
        isLeft={true}
      />
      <div className={horizontalContainerOuter} ref={containerRef}>
        <div className={horizontalContainerInner} style={{ transform: translateX }}>
          {children}
        </div>
      </div>
      <DashboardInsightsArrow
        show={viewPartsLength > 1 && currentViewPartIndex !== viewPartsLength}
        onClick={() => {
          setCurrentViewPartIndex(current => current + 1);
        }}
      />
      <DashboardInsightsNavigation
        currentViewPartIndex={currentViewPartIndex}
        viewPartsLength={viewPartsLength}
        setCurrentViewPartIndex={setCurrentViewPartIndex}
      />
    </div>
  );
};
