import styled from '@emotion/styled';
import React, { FC } from 'react';
import { generateDataAid } from '@bigid-ui/utils';
import { BigidTriggerButton, BigidTriggerButtonSize } from '@bigid-ui/components';
import { BigidXIcon } from '@bigid-ui/icons';
import { BigChatSetInHeaderProps } from './BigChatTypes';

const Wrapper = styled('div')({
  position: 'fixed',
  bottom: '64px',
  zIndex: '9999',
  right: '26px',
});

export const BigChatCloseButton: FC<BigChatSetInHeaderProps> = ({ setShowBigChatInHeader }) => {
  return (
    <Wrapper data-aid={`${generateDataAid('BigChat', ['close', 'button'])}`}>
      <BigidTriggerButton
        variant="tertiary"
        size={BigidTriggerButtonSize.small}
        onClick={() => {
          setShowBigChatInHeader(true);
        }}
        icon={() => {
          return <BigidXIcon size="small" />;
        }}
      />
    </Wrapper>
  );
};
