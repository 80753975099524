import React, { useMemo } from 'react';
import { BigidColorsV2 } from '@bigid-ui/components';
import styled from '@emotion/styled';
import { BigidGridColumn, BigidGridColumnTypes, BigidGridWithToolbar, BigidGridWithToolbarProps } from '@bigid-ui/grid';
import { VendorProjectAuditLog, VendorStateNames } from './VendorsTypes';
import { useVendorProjectContext } from './VendorProjectContext';
import { BigidLayoutEmptyState } from '@bigid-ui/layout';
import { BigidNoDataIllustration } from '@bigid-ui/icons';
import { getVendorLogs } from './vendorsService';
import { useUserPreferences } from '../../components/hooks/useUserPrefrences';
import { notificationService } from '../../services/notificationService';
import { EmptyStateWrapper } from './VendorsSummaryStyles';

const MainWrapper = styled('div')`
  display: flex;
  background-color: ${BigidColorsV2.white};
  justify-content: center;
  height: 100%;
  width: 100%;
  overflow: hidden;
  padding: 8px;
`;

type VendorActivityLogGridType = BigidGridWithToolbarProps<
  Omit<VendorProjectAuditLog, 'vendorProjectId'> & { id: string }
>;

const columns: BigidGridColumn<Omit<VendorProjectAuditLog, 'vendorProjectId'> & { id: string }>[] = [
  {
    width: 280,
    title: 'Date',
    name: 'timestamp',
    type: BigidGridColumnTypes.DATE,
    getCellValue: ({ timestamp }) => timestamp,
    filteringEnabled: false,
    sortingEnabled: true,
  },
  {
    width: 170,
    title: 'Action',
    name: 'action',
    type: BigidGridColumnTypes.TEXT,
    getCellValue: ({ action }) => action,
    filteringEnabled: false,
    sortingEnabled: false,
  },
  {
    width: 280,
    title: 'Description',
    name: 'description',
    type: BigidGridColumnTypes.TEXT,
    getCellValue: ({ description }) => description,
    filteringEnabled: false,
    sortingEnabled: false,
  },
  {
    width: 170,
    title: 'Status',
    name: 'vendor_project_status',
    type: BigidGridColumnTypes.TEXT,
    getCellValue: ({ vendorProjectStatus }) => vendorProjectStatus,
    filteringEnabled: false,
    sortingEnabled: false,
  },
  {
    width: 170,
    title: 'User',
    name: 'user',
    type: BigidGridColumnTypes.TEXT,
    getCellValue: ({ user }) => user,
    filteringEnabled: false,
    sortingEnabled: false,
  },
];

const getFilterConfig = async (): Promise<VendorActivityLogGridType['filterToolbarConfig']> => {
  return {
    filters: [],
    searchConfig: {
      searchFilterKeys: ['description'],
      initialValue: '',
      operator: 'textSearch',
    },
  };
};

export const VendorSummaryActivityLog = () => {
  const vendorProjectId: string = useVendorProjectContext().projectId;
  const { isReady, gridColumns, filterToolbarConfig, preferences, updatePreferences } = useUserPreferences({
    stateName: VendorStateNames.VENDORS_SUMMARY_LOGS,
    initialGridColumns: columns,
    getInitialFilterToolbarConfig: getFilterConfig,
  });

  const gridWithToolbarConfig: VendorActivityLogGridType = useMemo(() => {
    const gridConfig: VendorActivityLogGridType = {
      columns: gridColumns,
      showSortingControls: true,
      showFilteringControls: true,
      entityName: 'events',
      defaultSorting: preferences?.grid?.sort || [{ field: 'timestamp', order: 'desc' }],
      onGridStateChange: ({ filter, ...gridState }) => updatePreferences({ filterState: { filter }, gridState }),
      filterToolbarConfig: filterToolbarConfig,
      fetchData: async gridQueryParams => {
        try {
          const { auditLogs, totalCount } = await getVendorLogs(vendorProjectId, gridQueryParams);
          return {
            data: auditLogs,
            totalCount: totalCount,
          };
        } catch (error) {
          notificationService.error(`Failed to fetch vendor activity details.`);
          console.error(`Failed to fetch vendor activity details': ${JSON.stringify(error)}`);
          return { data: [], totalCount: 0 };
        }
      },
      noDataContent: (
        <EmptyStateWrapper>
          <BigidLayoutEmptyState illustration={BigidNoDataIllustration} title="No Data" />
        </EmptyStateWrapper>
      ),
    };
    return gridConfig;
  }, [filterToolbarConfig, gridColumns, preferences?.grid?.sort, updatePreferences]);

  return (
    <>
      {isReady && (
        <MainWrapper>
          <BigidGridWithToolbar {...gridWithToolbarConfig} />
        </MainWrapper>
      )}
    </>
  );
};
