import { httpService } from '../../../services/httpService';
import { sendDataExplorerQuickSearchApiRequestBiAnalytics } from '../services/analytics';
import { SearchResponse } from './types';

export const fetchQuickSearchResults = async (searchText: string): Promise<SearchResponse> => {
  try {
    const response = await httpService.post<SearchResponse>(`data-explorer/objects/quick`, {
      searchText,
      fieldsToProject: ['source_type', 'source', 'fullyQualifiedName', 'name', '_es_mongoId'],
    });
    sendDataExplorerQuickSearchApiRequestBiAnalytics({ searchText });
    return response.data;
  } catch (error) {
    console.error(error);
    return {
      data: {
        catalog: {
          count: 0,
          results: [],
        },
        datasource: {
          count: 0,
          results: [],
        },
        policy: {
          count: 0,
          results: [],
        },
      },
    };
  }
};
