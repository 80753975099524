import React, { useEffect } from 'react';
import { sessionStorageService } from '../../../common/services/sessionStorageService';
import { $state } from '../../services/angularServices';
import { isPermitted } from '../../services/userPermissionsService';
import { DATA_SOURCES_PERMISSIONS } from '@bigid/permissions';
import { BigidLoader } from '@bigid-ui/components';
import { CONFIG } from '../../../config/common';
import {
  getIsAutoDiscoveryInProgress,
  isAutoDiscoveryAvailableInDs,
} from './DataSourceConnections/hooks/useAutoDiscoveryAppStatus';
import { notificationService } from '../../services/notificationService';
import { getDataSources } from '../../services/dataSourcesService';
import { getApplicationPreference } from '../../services/appPreferencesService';

export const DataSourceRouter: React.FC = () => {
  useEffect(() => {
    navigateToDSRoute();
  }, []);

  return <BigidLoader />;
};

function navigateToPage(page: string, shouldDisplayAutoDiscoveryWarning?: boolean) {
  $state.go(page, shouldDisplayAutoDiscoveryWarning ? { shouldDisplayAutoDiscoveryWarning: true } : null, {
    location: 'replace',
  });
}

async function navigateToDSRoute() {
  const uniqueBroadcastEventForImport = sessionStorageService.get('uniqueBroadcastEventForImportDataSource');
  let isAutoDiscoveryInProgress = false;
  if (isAutoDiscoveryAvailableInDs()) {
    try {
      isAutoDiscoveryInProgress = await getIsAutoDiscoveryInProgress();
    } catch (e) {
      console.log(e?.message);
    }
  }

  const isNewDiscoveryUI = getApplicationPreference('SHOW_NEW_AUTO_DISCOVERY_UI_FF');
  const shouldSkipInitPage =
    uniqueBroadcastEventForImport ||
    !isPermitted(DATA_SOURCES_PERMISSIONS.CREATE.name) ||
    isAutoDiscoveryInProgress ||
    isNewDiscoveryUI;

  if (shouldSkipInitPage) {
    return navigateToPage(CONFIG.states.DATA_SOURCE_CONNECTIONS, isAutoDiscoveryInProgress);
  }

  try {
    const data = await getDataSources('skip=0&limit=1&requireTotalCount=true');
    if (!data?.count) {
      return navigateToPage(CONFIG.states.DATA_SOURCE_INIT);
    }
  } catch (error) {
    notificationService.error('Error getting amount of data sources');
  }

  return navigateToPage(CONFIG.states.DATA_SOURCE_CONNECTIONS);
}
