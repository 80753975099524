import { ComponentType } from 'react';
import { BigidDropdownOption } from '@bigid-ui/components';
import {
  MetadataSearchFilterFieldType,
  MetadataSearchFilterComponentBaseProps,
  MetadataSearchFilterConfig,
  MetadataSearchFilterConfigPreprocessed,
  MetadataSearchFieldFilterOperator,
  MetadataSearchFilterConfigValue,
  MetadataSearchFilterConfigValuePreprocessed,
  MetadataSearchFieldFilter,
} from './MetadataSearchFiltersTypes';
import { MetadataSearchFilterBoolean } from './filters/MetadataSearchFilterBoolean/MetadataSearchFilterBoolean';
import { MetadataSearchFilterDate } from './filters/MetadataSearchFilterDate/MetadataSearchFilterDate';
import {
  getFromDateMidnight,
  getToDateMidnight,
} from './filters/MetadataSearchFilterDate/MetadataSearchFilterDateUtils';
import { MetadataSearchFilterNumber } from './filters/MetadataSearchFilterNumber/MetadataSearchFilterNumber';
import { MetadataSearchFilterObject } from './filters/MetadataSearchFilterObject/MetadataSearchFilterObject';
import { MetadataSearchFilterString } from './filters/MetadataSearchFilterString/MetadataSearchFilterString';
import { MetadataSearchFilterUser } from './filters/MetadataSearchFilterUser/MetadataSearchFilterUser';
import { MetadataSearchFilterTags } from './filters/MetadataSearchFilterTags/MetadataSearchFilterTags';
import { MetadataSearchFilterEntityType } from './filters/MetadataSearchFilterEntityType/MetadataSearchFilterEntityType';
import {
  formatDate,
  getEntityTypeConfigByTypeId,
  getIsSearchResultEntityEnabled,
  getMetadataSearchEntityTypeLabel,
  MetadataSearchEntityLabelMap,
  getMetadataSearchEntityIcon,
} from '../MetadataSearchUtils';
import {
  MetadataSearchEntityType,
  MetadataSearchEntityTypeIcons,
  MetadataSearchSupportedEntityTypes,
} from '../MetadataSearchTypes';
import { UseMetadataSearchEntityTypesConfig } from '../useMetadataSearchState';
import { getTagNameAndValueSplitted } from '../../../utilities/tags';

export const MetadataSearchFilterComponentMap: Record<
  MetadataSearchFilterFieldType,
  ComponentType<MetadataSearchFilterComponentBaseProps>
> = {
  [MetadataSearchFilterFieldType.STRING]: MetadataSearchFilterString,
  [MetadataSearchFilterFieldType.NUMBER]: MetadataSearchFilterNumber,
  [MetadataSearchFilterFieldType.BOOLEAN]: MetadataSearchFilterBoolean,
  [MetadataSearchFilterFieldType.DATE]: MetadataSearchFilterDate,
  [MetadataSearchFilterFieldType.OBJECT]: MetadataSearchFilterObject,
  [MetadataSearchFilterFieldType.USER]: MetadataSearchFilterUser,
  [MetadataSearchFilterFieldType.TAGS]: MetadataSearchFilterTags,
  [MetadataSearchFilterFieldType.ENTITY_TYPE]: MetadataSearchFilterEntityType,
  [MetadataSearchFilterFieldType.HASH_STRING]: MetadataSearchFilterString,
};

export const MetadataSearchFetchTriggerFilterList: MetadataSearchFilterFieldType[] = [
  MetadataSearchFilterFieldType.OBJECT,
  MetadataSearchFilterFieldType.DATE,
  MetadataSearchFilterFieldType.USER,
  MetadataSearchFilterFieldType.ENTITY_TYPE,
  MetadataSearchFilterFieldType.TAGS,
  MetadataSearchFilterFieldType.STRING,
  MetadataSearchFilterFieldType.NUMBER,
  MetadataSearchFilterFieldType.HASH_STRING,
];

export const MetadataSearchFilterOperatorMap: { [operator in MetadataSearchFieldFilterOperator]?: string } = {
  equal: '=',
  notEqual: '!=',
  lessThan: '<',
  lessThanOrEqual: '<=',
  greaterThan: '>',
  greaterThanOrEqual: '>=',
};

export const getFilterFieldComponent = (
  fieldType: MetadataSearchFilterFieldType,
): ComponentType<MetadataSearchFilterComponentBaseProps> => {
  switch (fieldType) {
    case MetadataSearchFilterFieldType.ENTITY_TYPE:
      return MetadataSearchFilterEntityType;
    case MetadataSearchFilterFieldType.TAGS:
      return MetadataSearchFilterTags;
    case MetadataSearchFilterFieldType.USER:
      return MetadataSearchFilterUser;
    default:
      return MetadataSearchFilterComponentMap[fieldType];
  }
};

export const preprocessFilterConfig = (
  filter: MetadataSearchFilterConfig,
  entityTypeConfigs: UseMetadataSearchEntityTypesConfig,
  supportedEntityTypesMap: MetadataSearchSupportedEntityTypes,
  iconMap: MetadataSearchEntityTypeIcons,
): MetadataSearchFilterConfigPreprocessed => {
  const { fieldType, fieldName, fieldSpecialValues = [], fieldValues = [], ...rest } = filter;

  let config: MetadataSearchFilterConfigPreprocessed = {
    ...rest,
    fieldName,
    fieldType,
  };

  if (Array.isArray(fieldValues)) {
    config = {
      ...config,
      values: preprocessFilterConfigValues(fieldType, fieldValues, entityTypeConfigs, supportedEntityTypesMap, iconMap),
      originalValues: fieldValues,
    };
  } else {
    config = {
      ...config,
      values: fieldValues,
      originalValues: [],
    };
  }

  if (Array.isArray(fieldSpecialValues)) {
    config = {
      ...config,
      specialValues: preprocessFilterConfigSpecialValues(fieldSpecialValues),
    };
  } else {
    config = {
      ...config,
      specialValues: [],
    };
  }

  return config;
};

export const preprocessFilterConfigSpecialValues = (
  values: MetadataSearchFilterConfigValue[],
): MetadataSearchFilterConfigValuePreprocessed[] => {
  return values.map(({ value, displayValue }) => ({
    id: String(value),
    value,
    displayValue: String(displayValue || value),
  }));
};

export const preprocessFilterConfigValues = (
  fieldType: MetadataSearchFilterFieldType,
  values: MetadataSearchFilterConfigValue[],
  entityTypeConfigs: UseMetadataSearchEntityTypesConfig,
  supportedEntityTypesMap: MetadataSearchSupportedEntityTypes,
  iconMap: MetadataSearchEntityTypeIcons,
): MetadataSearchFilterConfigValuePreprocessed[] => {
  switch (fieldType) {
    case MetadataSearchFilterFieldType.USER:
    case MetadataSearchFilterFieldType.OBJECT: {
      return values.map(({ value, displayValue }) => ({
        id: String(value),
        value,
        displayValue: String(displayValue || value),
      }));
    }
    case MetadataSearchFilterFieldType.TAGS: {
      const tagsMap = values.reduce((valuesReduced, { value }) => {
        const [tagName, tagValue] = getTagNameAndValueSplitted(String(value)).map(value => value.trim());

        if (valuesReduced.has(tagName)) {
          const tag = valuesReduced.get(tagName);
          valuesReduced.set(tagName, {
            id: tagName,
            value: tagName,
            displayValue: tagName,
            children: [
              ...tag.children,
              {
                id: String(value),
                parentId: tagName,
                value,
                displayValue: tagValue,
              },
            ],
          });
        } else {
          valuesReduced.set(tagName, {
            id: tagName,
            value: tagName,
            displayValue: tagName,
            children: [
              {
                id: String(value),
                parentId: tagName,
                value,
                displayValue: tagValue,
              },
            ],
          });
        }

        return valuesReduced;
      }, new Map<string, BigidDropdownOption>());

      return Array.from(tagsMap.values()).flat();
    }
    case MetadataSearchFilterFieldType.ENTITY_TYPE: {
      return values
        .filter(({ value }) => {
          return getIsSearchResultEntityEnabled(
            value as MetadataSearchEntityType,
            entityTypeConfigs.types,
            supportedEntityTypesMap,
          );
        })
        .map(({ value, displayValue }) => {
          const entityTypeConfig = getEntityTypeConfigByTypeId(
            entityTypeConfigs.types,
            value as MetadataSearchEntityType,
          );

          return {
            id: String(value),
            value,
            displayValue:
              getMetadataSearchEntityTypeLabel(
                value as MetadataSearchEntityType,
                MetadataSearchEntityLabelMap,
                entityTypeConfig,
                'singular',
              ) || String(displayValue || value),
            icon: getMetadataSearchEntityIcon(value as MetadataSearchEntityType, iconMap, entityTypeConfig).icon,
          };
        });
    }
  }
};

export const clearUndeterminedFilterValues = (filter: MetadataSearchFieldFilter[]): MetadataSearchFieldFilter[] => {
  return filter.filter(({ value }) => {
    if (Array.isArray(value)) {
      return value.length > 0;
    } else {
      return value !== 'ANY' || value !== null;
    }
  });
};

export const preprocessFilter = (
  filter: MetadataSearchFieldFilter[],
  isRequestPayload?: boolean,
): MetadataSearchFieldFilter[] => {
  return clearUndeterminedFilterValues(filter).reduce((filtersReduced, filter) => {
    if (isRequestPayload) {
      delete filter['originalValue'];
    }

    if (filter.isKeyword) {
      const { field, specialField, ...rest } = filter;

      return [
        ...filtersReduced,
        {
          ...rest,
          field: isRequestPayload ? specialField || field : field,
        },
      ];
    } else {
      switch (filter.fieldType) {
        case MetadataSearchFilterFieldType.DATE: {
          if (Array.isArray(filter.value)) {
            const [fromDate, toDate] = filter.value;

            if (isRequestPayload) {
              if (fromDate) {
                const fromDateFilter: MetadataSearchFieldFilter = {
                  ...filter,
                  operator: 'greaterThanOrEqual',
                  value: getFromDateMidnight(new Date(fromDate?.toString())).toISOString(),
                };

                filtersReduced.push(fromDateFilter);
              }

              if (toDate) {
                const toDateFilter: MetadataSearchFieldFilter = {
                  ...filter,
                  operator: 'lessThanOrEqual',
                  value: getToDateMidnight(new Date(toDate?.toString())).toISOString(),
                };

                filtersReduced.push(toDateFilter);
              }

              return filtersReduced;
            } else {
              return [
                ...filtersReduced,
                {
                  ...filter,
                  originalValue: [fromDate, toDate],
                  value: [
                    fromDate ? formatDate(new Date(fromDate?.toString())) : fromDate,
                    toDate ? formatDate(new Date(toDate?.toString())) : toDate,
                  ],
                },
              ];
            }
          } else {
            return [...filtersReduced, filter];
          }
        }
        case MetadataSearchFilterFieldType.NUMBER: {
          if (filter.operator === 'equal') {
            return [
              ...filtersReduced,
              {
                ...filter,
                value: Number(filter.value),
                operator: 'greaterThanOrEqual',
              },
              {
                ...filter,
                value: Number(filter.value),
                operator: 'lessThanOrEqual',
              },
            ];
          } else {
            return [
              ...filtersReduced,
              {
                ...filter,
                value: Number(filter.value),
              },
            ];
          }
        }
        default:
          return [...filtersReduced, filter];
      }
    }
  }, []);
};
