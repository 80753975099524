import { BigidBody1 } from '@bigid-ui/components';
import styled from '@emotion/styled';

export const CreateUserContainer = styled('div')({
  display: 'flex',
  overflow: 'hidden',
  height: '100%',
});

export const UserInfoContainer = styled('div')(({ theme }) => ({
  width: '507px',
  flexShrink: 0,
  borderRight: theme.vars.tokens.bigid.borderDefault,
  padding: '24px 0',
  display: 'flex',
  flexDirection: 'column',
  gap: '16px',
}));

export const UserInfoSection = styled('div')({
  padding: '0 24px',
});

export const UserInfoTitleSection = styled(UserInfoSection)({
  display: 'flex',
  alignItems: 'flex-start',
  justifyContent: 'space-between',
  gap: '16px',
});

export const UserInfoScrollableSection = styled(UserInfoSection)({
  flexGrow: 1,
  overflow: 'auto',
});

export const CreateUserFormWrapper = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  gap: '24px',

  '& fieldset': {
    margin: 0,
  },

  '& p[class*="helperTextError"]': {
    height: 'auto',
  },
});

export const CreateUserFormSection = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  gap: '16px',
});

export const CreateUserNameContainer = styled('div')({
  display: 'flex',
  gap: '16px',
});

export const CreateUserFormName = styled('div')({
  flexBasis: '50%',
});

export const CreateUserRolesContainer = styled('div')({
  padding: '24px 0',
  flexGrow: 1,
  display: 'flex',
  flexDirection: 'column',
  gap: '16px',
});

export const EmptyRolesStateWrapper = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  flexGrow: 1,
  height: '100%',
});

export const EmptyRolesStateContent = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  gap: '8px',
});

export const FormErrorText = styled(BigidBody1)(({ theme }) => ({
  color: theme.vars.palette.bigid.red700,
}));
