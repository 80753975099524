import { BigidFormField, BigidFormFieldTypes } from '@bigid-ui/components';
import React, { FC, useMemo } from 'react';
import { FormSection, FormSectionInitialStateProps } from '../FormSection/FormSection';
import { useReportTemplateContext } from '../hooks/ReportTemplateContext';
import { PersonalInformationSection, ReportTemplate } from '../Types';

export const PersonalInfoSection: FC = () => {
  const sectionName = 'personalInfo';
  const description = 'Display personal details using the configurations below.';

  const { initialTemplateData } = useReportTemplateContext();

  const formSectionInitProps: FormSectionInitialStateProps = useMemo(() => {
    const {
      personalInfo: { ...initialValues },
    } = initialTemplateData;

    const fields: (BigidFormField & { name: keyof PersonalInformationSection })[] = [
      {
        type: BigidFormFieldTypes.CHECKBOX,
        name: 'hideAttributePurposeOfUse',
        label: `Hide "Why we collect the data" column`,
        tooltipText: 'This settings has no effect if HIDE_ATTRIBUTE_PURPOSE_OF_USE is turned On globally',
      },
      {
        type: BigidFormFieldTypes.CHECKBOX,
        name: 'groupByCategory',
        label: 'Group by categories',
        tooltipText: 'This settings has no effect if DSAR_GROUP_ATTRIBUTE_BY_FRIENDLY_NAME is turned On globally',
      },
      {
        type: BigidFormFieldTypes.CHECKBOX,
        name: 'showCategoryColumn',
        label: `Show 'Category' column`,
      },
    ];

    return {
      fields,
      initialValues,
    };
  }, [initialTemplateData]);

  return (
    <FormSection
      shouldHideIsEnabledSwitch={true}
      sectionName={sectionName}
      description={description}
      {...formSectionInitProps}
    />
  );
};
