export enum OrderOption {
  ASC = 'asc',
  DESC = 'desc',
}

export const compareNumber = (a: number, b: number, order: OrderOption) => {
  if (order === OrderOption.ASC) {
    return a === b ? 0 : a > b ? -1 : 1;
  }
  return a === b ? 0 : a > b ? 1 : -1;
};
