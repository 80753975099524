import { BigidGridQueryComponents, BigidGridRow } from '@bigid-ui/grid';
import { CuratedAttributeType } from '../../Curation/curationService';

export enum CREATE_CLASSIFIER_DIALOG_EVENTS {
  SAVE = 'SAVE',
  CLOSE = 'CLOSE',
}

export enum CLASSIFIER_TYPES {
  NER = 'NER',
  DOC = 'DOC',
  CONTENT = 'data',
  METADATA = 'columnName',
  CONTENT_AND_METADATA = 'data-and-metadata',
  CORRELATION = 'correlation',
}

export enum ClassifierVersionState {
  NEW = 'New',
  NEEDS_UPDATE = 'NeedsUpdate',
  UP_TO_DATE = 'UpToDate',
  DOWNGRADE_AVAILABLE = 'DowngradeAvailable',
}

export enum RegexFormFields {
  classifierName = 'classification_name',
  description = 'description',
  classifierType = 'classifierType',
  classificationRegex = 'classification_regex',

  minLength = 'min_length',
  maxLength = 'max_length',

  validation = 'validation',

  supportTerm = 'support_term_value',
  proximityAfter = 'proximity_after',
  proximityBefore = 'proximity_before',

  negativeSupportTerm = 'negative_support_term_value',
  nstProximityAfter = 'nst_proximity_after',
  nstProximityBefore = 'nst_proximity_before',

  // Advanced options for Regex('data', 'data_and_metadata') only
  report_as_classification = 'report_as_classification',
  report_as_pii_finding = 'report_as_pii_finding',
  ignored = 'ignored',

  //doc
  categories = 'categories',
  originalName = 'original_name',
  friendlyName = 'friendly_name',

  //ner
  modelName = 'modelName',
  nerEntityMap = 'nerEntityMap',

  // correlation
  isOverrideSureMatch = 'isOverrideSureMatch',
}

export interface Category {
  _id?: string;
  color: string;
  display_name: string;
  unique_name: string;
  description: string;
  glossary_id?: string;
}

export interface ClassifierGridRow extends BigidGridRow {
  _id: string;
  classification_name: string;
  // for ner classifier
  classifierName?: string;
  description: string;
  classifierType: CLASSIFIER_TYPES;
  classification_regex: string;
  min_length: string;
  max_length: string;
  validation: string | null;
  support_term_value: string;
  proximity_after: string;
  proximity_before: string;
  negative_support_term_value: string;
  nst_proximity_after: string;
  nst_proximity_before: string;
  isOutOfTheBox: boolean;
  enabled: boolean;
  dataType: string[];
  categories?: Category[];
  regulations?: string[];
  attributeName: string; // should add
  version?: string;
  isSupervised?: any;
  isAdvanced?: boolean;
  performance?: number;
  original_name?: string;
  friendly_name?: string;
  isClassifierTesterSupported?: boolean | null;
  glossary_id?: string;
  //ner
  modelName?: string;
  nerEntityMap?: string;
  version_state?: ClassifierVersionState;

  // Advanced options for Regex('data', 'data_and_metadata') only
  report_as_classification?: boolean;
  report_as_pii_finding?: boolean;
  ignored?: boolean;

  // correlation
  isOverrideSureMatch?: boolean;
  identifiability?: number;
  avgValueLength?: number;
  distinctFieldValueCount?: number;
  distinctIdsAttValueCount?: number;
  isComposed?: boolean;
  isPartialCorrelationSet?: boolean;
  sources?: string[];

  //CuratedAttribute info
  totalFields?: number;
  attributeType?: CuratedAttributeType;
}

export interface FetchClassifiersReponse {
  data: ClassifierGridRow[];
  totalCount: number;
}

export interface NERPayload {
  classifierName: string;
  modelName: string;
  nerEntityMap: string;
}

export interface GeneralActionTypes {
  handleImport: () => void;
  handleReviewAvailableUpdates: () => void;
  isImportPermitted: boolean;
  isReviewAvailableUpdates: boolean;
}

export interface AddClassifierActionTypes {
  handleCreateRegex: () => void;
  handleCreateDOC: () => void;
  handleCreateNER: () => void;
  isDocMenuItemVisible: boolean;
  isCreatePermitted: boolean;
  isNerEnabled: boolean;
}

export const REGEX_FORM_TYPES = [
  CLASSIFIER_TYPES.CONTENT,
  CLASSIFIER_TYPES.METADATA,
  CLASSIFIER_TYPES.CONTENT_AND_METADATA,
];

export interface NerClassifier {
  _id: string;
  classifierName: string;
  modelName: string;
  category_name?: string[];
  country_code?: string[];
  created_at: Date;
  defaultWeightRisk?: number;
  description?: string;
  detection_sensitivity?: number;
  enabled: boolean;
  isOutOfTheBox: boolean;
  nerEntityMap: string;
  type: CLASSIFIER_TYPES.NER;
  updated_at: Date;
  version?: string;
}

export interface DocClassifier {
  _id: string;
  category_name?: string[];
  country_code?: string[];
  defaultWeightRisk?: number;
  description?: string;
  detection_sensitivity?: number;
  enabled: boolean;
  modelId: string;
  nerEntityName: string;
  type: CLASSIFIER_TYPES.DOC;
  updated_at: Date;
}

export interface ClassifierManagementInitialState {
  query: BigidGridQueryComponents;
}

export enum ActionTypes {
  fetch = 'fetch',
  update = 'update',
  add = 'add',
  updateIsFetchData = 'updateIsFetchData',
  delete = 'delete',
  setFilterQuery = 'setFilterQuery',
  updateBulkEnableDisable = 'updateBulk',
  updateBulkCategories = 'updateBulkCategories',
}

export interface Action {
  type: ActionTypes;
  payload: any;
}

export enum FILTER_KEYS {
  type = 'type',
  isOutOfTheBox = 'isOutOfTheBox',
  categories = 'categories',
  regulations = 'regulations',
  glossaryId = 'glossary_id',
  dataType = 'dataType',
  location = 'location',
  enabled = 'enabled',
  name = 'name',
}

export enum ActionModeOnClassifier {
  create = 'create',
  update = 'update',
}

export interface TPA {
  _id: string;
  tpa_name: string;
}

export interface AssignAttributePayload {
  original_name: string;
  friendly_name: string;
  glossary_id: string;
}

export interface RegulationsData {
  _id: string;
  name: string;
  friendlyName: string;
  flagCode: string;
  classifiers: string[];
  countries: string[];
  isActive: boolean;
  policies: string[];
}

export interface DuplicateClassifierRequestBody {
  originalClassificationName: string;
  duplicateClassificationName: string;
}
