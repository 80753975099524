import React, { useEffect } from 'react';
import styled from '@emotion/styled';
import { BigidAddIcon } from '@bigid-ui/icons';
import { BigidErrorBoundary, PrimaryButton, BigidPaper } from '@bigid-ui/components';
import { pageHeaderService } from '../../../common/services/pageHeaderService';
import { LegalEntitiesGrid } from './LegalEntitiesGrid';
import { LegalEntitiesEditDialog, useLegalEntitiesEditDialog } from './LegalEntitiesEditDialog';
import { useLegalEntitiesDeleteDialog, LegalEntitiesDeleteDialog } from './LegalEntitiesDeleteDialog';
import { isPermitted } from '../../services/userPermissionsService';
import { LEGAL_ENTITIES_PERMISSIONS } from '@bigid/permissions';
import { LegalEntitiesTrackingEvents, trackLegalEntities } from './utils/analytics';

export const ContentWrapper = styled.div`
  height: 100%;
  display: flex;
  max-width: 100vw;
`;
const RightSideWrapper = styled.div`
  display: flex;
  width: 100%;
  gap: 12px;
  padding: 8px 0px 0px 0px;
  align-items: flex-end;
`;

export const LegalEntities = () => {
  const { openLegalEntitiesEditDialog, legalEntitiesEditDialogProps, key } = useLegalEntitiesEditDialog();
  const { openDeleteDialog, deleteDialogProps } = useLegalEntitiesDeleteDialog();

  useEffect(() => {
    pageHeaderService.setTitle({
      pageTitle: 'Legal Entities',
      rightSideComponentsContainer: isPermitted(LEGAL_ENTITIES_PERMISSIONS.LEGAL_ENTITIES_CREATE.name) && (
        <RightSideWrapper>
          <PrimaryButton
            size="medium"
            text="Add Legal Entity"
            startIcon={<BigidAddIcon />}
            onClick={() => {
              trackLegalEntities(LegalEntitiesTrackingEvents.CREATE_LEGAL_ENTITY_ACTION);
              openLegalEntitiesEditDialog(false);
            }}
          />
        </RightSideWrapper>
      ),
    });
  }, [openLegalEntitiesEditDialog]);
  return (
    <BigidErrorBoundary>
      <ContentWrapper>
        <BigidPaper>
          <LegalEntitiesEditDialog {...legalEntitiesEditDialogProps} />
          <LegalEntitiesDeleteDialog {...deleteDialogProps} />
          <LegalEntitiesGrid
            openLegalEntitiesEditDialog={openLegalEntitiesEditDialog}
            openDeleteEntityDialog={openDeleteDialog}
            forceUpdateKeys={{
              forceUpdateAssetsKey: key.assetsKey,
              forceUpdateParentsKey: key.parentsKey,
            }}
          />
        </BigidPaper>
      </ContentWrapper>
    </BigidErrorBoundary>
  );
};
