import React, { FC, useContext, useMemo, useState } from 'react';
import { noop } from 'lodash';
import { BigidBody1, BigidDialog, PrimaryButton, SecondaryButton } from '@bigid-ui/components';
import { commonTranslate } from '../../../../translations';
import { AutoDiscoveryWizardContext } from '../autoDiscoveryWizardContext';
import { useLocalTranslation } from '../../translations';
import { isRunAndScanAvailable } from '../autoDiscoveryWizardUtils';
import { ASSESSMENT_SCAN_KEY_NAME } from '../../constants';

export interface RunAndScanWithConfirmProps {
  onRunDiscover: (values: Record<string, any>) => Promise<void>;
  isDisabled: boolean;
}

export const RunAndScanWithConfirm: FC<RunAndScanWithConfirmProps> = ({ onRunDiscover, isDisabled }) => {
  const { t } = useLocalTranslation('AutoDiscovery');
  const { setDiscoveryConfigData, discoveryConfigData } = useContext(AutoDiscoveryWizardContext);
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const dialogConfig = useMemo(
    () => ({
      title: t('configModal.buttons.runScan'),
      isOpen,
      onClose: () => setIsOpen(false),
      buttons: [
        {
          text: commonTranslate('Cancel'),
          component: SecondaryButton,
          onClick: noop,
          isClose: true,
        },
        {
          text: t('configModal.buttons.runScan'),
          component: PrimaryButton,
          disabled: false,
          onClick: () => {
            setDiscoveryConfigData(current => ({
              ...current,
              isScan: true,
            }));
            return onRunDiscover({ [ASSESSMENT_SCAN_KEY_NAME]: 'true' });
          },
          isClose: true,
        },
      ],
    }),
    [isOpen, onRunDiscover, setDiscoveryConfigData, t],
  );
  const isVisible = useMemo(
    () => isRunAndScanAvailable(discoveryConfigData),
    [discoveryConfigData?.formData?.authType, discoveryConfigData?.configData?.appRunAction?.params],
  );

  return (
    <>
      {isVisible && (
        <PrimaryButton
          size={'medium'}
          disabled={isDisabled}
          dataAid={'AutoDiscoverWizardFooterDiscoverAndScan'}
          onClick={() => setIsOpen(true)}
          text={t('configModal.buttons.runScan')}
        />
      )}
      <BigidDialog {...dialogConfig}>
        <BigidBody1>{t('configModal.scanWarning')}</BigidBody1>
      </BigidDialog>
    </>
  );
};
