import './selectTable.component.scss';
import { v4 as uuid } from 'uuid';
import { module } from 'angular';
import template from './selectTable.component.html';
const app = module('app');
import { CORRELATION_SET_PERMISSIONS } from '@bigid/permissions';
import { isPermitted } from '../../react/services/userPermissionsService';

app.component('selectTable', {
  template,
  controller: function (identityLineageService) {
    'ngInject';

    const ctrl = this;

    ctrl.modelContainer = [];
    ctrl.schemaNamesArray = [];
    ctrl.lineageGraphData = {};
    ctrl.lineageTree = {};
    ctrl.schemaColor = ['#3F5CEE', '#F95759', '#90C6FF', '#A694FA', '#E91E63', '#5EF2DA', '#1A424A', '#F28964'];
    ctrl.selectedTables = [];
    ctrl.allowedDsNames = [];
    ctrl.tables = [];
    ctrl.primaryData = [];
    ctrl.userQuery = '';

    ctrl.$onInit = () => {
      ctrl.isCreateCSPermitted = isPermitted(CORRELATION_SET_PERMISSIONS.CREATE.name);
    };

    ctrl.$onChanges = () => {
      ctrl.userQuery = '';
      ctrl.selectedTables = [];

      //Case of change in selected column data in table
      if (this.data && this.data.columnData && this.data.columnData.isClickable && this.show) {
        const tableName = this.data.columnData.tableName;
        const tableObject = ctrl.getTableObject(this.lineageTree, tableName);
        ctrl.attributeName = 'Column: ' + this.data.columnData.title;
        let tablesFound = 0;
        const tables = [];

        //get all tables connected to the column selected
        if (tableObject && tableObject.connection_details && tableObject.connection_details.length > 0) {
          tableObject.connection_details.forEach(connection => {
            const pathArray = connection.origin_fields.split('.');
            let isSelected = false;
            if (
              pathArray[pathArray.length - 2] === tableName &&
              pathArray[pathArray.length - 1] === this.data.columnData.title
            ) {
              tablesFound++;
              const destinationTableName = connection.destination_field.split('.');

              const existingNodeData = this.data.nodesData.filter(
                nodeData => nodeData.title === destinationTableName[destinationTableName.length - 2],
              );
              if (existingNodeData && existingNodeData.length > 0) {
                isSelected = true;
              }
              const table = {
                name: destinationTableName[destinationTableName.length - 2],
                fullTableName: connection.destination_field,
                selected: isSelected,
                isColumn: true,
                tableNameContainsColumn: tableName,
                columnSelected: this.data.columnData.title,
              };

              tables.push(table);
            }
          });
        }
        ctrl.length = tablesFound;
        ctrl.primaryData = tables;
        ctrl.tables = tables.length > 0 ? initializeTopNodes(ctrl.primaryData) : null;
      }
      //Case of change in tables connected to attribute
      else if (this.data && this.data.nodesData && this.show && !this.data.columnData) {
        if (this.data.nodesData[0]) {
          ctrl.attributeName = this.data.nodesData[0].title;
          ctrl.originType = this.data.nodesData[0].originType;
          if (this.data.nodesData[0].collections) {
            ctrl.length = this.data.nodesData[0].collections.length;
            ctrl.primaryData = ctrl.mapTablesNamesIntoCheckboxes(this.data.nodesData[0], this.data.linksData);
            ctrl.tables = initializeTopNodes(ctrl.primaryData);
          }
        }
      }
    };

    const initializeNode = (data, parent) => {
      let node;

      if (typeof parent != 'undefined') {
        let pathTail = data['fullTableName'].substring(parent.path.length);

        if (pathTail.charAt(0) === '.') pathTail = pathTail.slice(1);

        if (pathTail.charAt(pathTail.length - 1) === '.') pathTail = pathTail.slice(0, -1);

        const nodeName = pathTail.split('.')[0];

        node = {
          level: parent.level + 1,
          name: nodeName,
          path: `${parent.path}.${nodeName}`,
          parent: parent.name,
          parentId: parent.uuid,
          uuid: uuid(),
          isColumn: data['isColumn'],
        };

        if (!pathTail.includes('.') && data['fullTableName'].slice(-pathTail.length) === pathTail) {
          node['isSelected'] = false;
          node['fullPath'] = data['fullTableName'];
        } else {
          node['children'] = [];
          node['isExpanded'] = false;
        }
      } else {
        const pathArr = data['fullTableName'].split('.');

        node = {
          name: pathArr[0],
          path: pathArr[0],
          children: [],
          isExpanded: false,
          uuid: uuid(),
          level: 0,
          isColumn: data['isColumn'],
        };
      }

      return node;
    };

    const initializeTopNodes = data => {
      let trees = [];

      const dataLen = data.length;

      for (let i = 0; i < dataLen; i++) {
        const newTree = initializeNode(data[i]);
        if (trees.findIndex(tree => tree.name === newTree.name) == -1) {
          trees = [...trees, newTree];
        }
      }

      return trees;
    };

    const buildTrees = (data, searchMode = false) => {
      let disorderedNodes = [];
      const rootItems = [],
        lookup = {};

      // making disordered leaves
      data.forEach(dataItem => {
        disorderedNodes = [
          ...disorderedNodes,
          ...dataItem['fullTableName'].split('.').reduce((resultArr, item, idx, arr) => {
            const node = {
              uuid: uuid(),
              name: item,
              isColumn: dataItem['isColumn'],
            };

            switch (true) {
              case idx === 0:
                node['isExpanded'] = searchMode;
                node['path'] = item;
                node['children'] = [];
                node['level'] = 0;
                break;
              case idx === arr.length - 1:
                node['isSelected'] = false;
                node['parent'] = resultArr[idx - 1]['name'];
                node['parentId'] = resultArr[idx - 1]['uuid'];
                node['path'] = `${resultArr[idx - 1]['path']}.${item}`;
                node['level'] = resultArr[idx - 1]['level'] + 1;
                node['fullPath'] = dataItem['fullTableName'];
                break;
              default:
                node['isExpanded'] = searchMode;
                node['children'] = [];
                node['parent'] = resultArr[idx - 1]['name'];
                node['parentId'] = resultArr[idx - 1]['uuid'];
                node['path'] = `${resultArr[idx - 1]['path']}.${item}`;
                node['level'] = resultArr[idx - 1]['level'] + 1;
            }

            resultArr = [...resultArr, node];

            return resultArr;
          }, []),
        ];
      });

      // filtering equal nodes
      disorderedNodes = disorderedNodes.filter(
        (node, idx, arr) => idx === arr.findIndex(n => n.path === node.path && n.level === node.level),
      );

      // assigning same parent ID to brothers
      disorderedNodes.forEach((node, idx, arr) => {
        const nodePath = node.path.split('.').slice(0, node.level).join('.');

        arr
          .filter(n => {
            const nPath = n.path.split('.').slice(0, n.level).join('.');

            return nPath == nodePath && n.uuid !== node.uuid;
          })
          .map(n => (n['parentId'] = node.parentId));
      });

      // building trees
      disorderedNodes.forEach(node => {
        const itemId = node['uuid'];
        const parentId = typeof node['parentId'] != 'undefined' ? node['parentId'] : null;

        if (!Object.prototype.hasOwnProperty.call(lookup, itemId)) {
          lookup[itemId] = Object.assign({}, node);
        }

        const treeItem = lookup[itemId];

        if (parentId === null) {
          rootItems.push(treeItem);
        } else {
          if (!Object.prototype.hasOwnProperty.call(lookup, parentId)) {
            lookup[parentId] = Object.assign({}, node);
          }

          if (typeof lookup[parentId].children != 'undefined') {
            lookup[parentId].children.push(treeItem);
          }
        }
      });

      return rootItems;
    };

    ctrl.onNodeClick = node => {
      if (node.isExpanded) {
        node.isExpanded = false;
        node.children = [];
      } else {
        let potentialChildNodes = ctrl.primaryData.filter(
          table => table.fullTableName.substring(0, node.path.length + 1) === `${node.path}.`,
        );

        if (ctrl.userQuery != '') {
          potentialChildNodes = potentialChildNodes.filter(table =>
            table.fullTableName.toLowerCase().includes(ctrl.userQuery.toLowerCase()),
          );
        }

        let readyNodes = [],
          approvedNodes = [];

        potentialChildNodes.forEach(child => {
          const newNode = initializeNode(child, node);

          if (
            (!readyNodes.includes(newNode.name) && typeof newNode.children != 'undefined') ||
            typeof newNode.children == 'undefined'
          ) {
            approvedNodes = [...approvedNodes, newNode];
          }

          readyNodes = [...readyNodes, newNode.name];
        });

        node.children = approvedNodes;
        node.isExpanded = true;
      }
    };

    ctrl.createIdConnection = () => {
      if (ctrl.selectedTables.length == 0) return;

      const selectedTables = getLeaves(ctrl.tables)
        .filter(table => table.selected)
        .map(item => item.fullPath);
      this.onEvent({
        event: 'createIdConnection',
        data: selectedTables,
      });
    };

    ctrl.getTableObject = (lineageTree, tableName) => {
      if (lineageTree && lineageTree.length > 0) {
        for (let i = 0; i < lineageTree.length; i++) {
          if (lineageTree[i]._id.split('.').pop() === tableName) {
            return lineageTree[i];
          }
        }
      } else {
        if (lineageTree._id.split('.').pop() === tableName) {
          return lineageTree;
        }
      }

      if (lineageTree && lineageTree.length > 0) {
        for (let i = 0; i < lineageTree.length; i++) {
          //if (lineageTree && lineageTree[0] && lineageTree[0].childs && lineageTree[0].childs.length > 0) {
          for (let j = 0; j < lineageTree[i].childs.length; j++) {
            return ctrl.getTableObject(lineageTree[i].childs[j], tableName);
          }
        }
      } else {
        for (let i = 0; i < lineageTree.childs.length; i++) {
          return ctrl.getTableObject(lineageTree.childs[i], tableName);
        }
      }
    };

    ctrl.mapTablesNamesIntoCheckboxes = (nodeData, linksData) => {
      return nodeData.collections.map(item => {
        const obj = {};
        const arr = item.split('.');
        obj.name = arr ? arr[arr.length - 1] : '';
        obj.fullTableName = item;
        obj.selected = ctrl.isTableExistsInLinkData(obj.name, nodeData, linksData);
        obj.isColumn = false;
        return obj;
      });
    };

    ctrl.isTableExistsInLinkData = (toTable, nodeData, linksData) => {
      let isSelected = false;
      if (linksData && linksData.length > 0) {
        const item = linksData.find(
          linkData => linkData.fromAttribute === nodeData.title && linkData.toTable === toTable,
        );
        if (item) {
          isSelected = true;
        }
      }

      return isSelected;
    };

    ctrl.selectTable = table => {
      const index = ctrl.selectedTables.findIndex(fullPath => fullPath == table.fullPath);

      if (index == -1) {
        ctrl.selectedTables = [...ctrl.selectedTables, table.fullPath];
      } else {
        ctrl.selectedTables = [...ctrl.selectedTables.slice(0, index), ...ctrl.selectedTables.slice(index + 1)];
      }

      //Attribute Case
      if (!table.isColumn) {
        //if there is tables selected
        if (ctrl.selectedTables && ctrl.selectedTables.length > 0) {
          identityLineageService.getLineageTree(ctrl.selectedTables, ctrl.originType).then(result => {
            ctrl.lineageTree = result.lineageTree;
            const attribute = {
              name: ctrl.attributeName,
              originType: ctrl.originType,
              original_name: ctrl.attributeData[0].original_name ? ctrl.attributeData[0].original_name : '',
              isTheHeaderOfGroup: ctrl.attributeData[0].isTheHeaderOfGroup
                ? ctrl.attributeData[0].isTheHeaderOfGroup
                : false,
              physicalNames: ctrl.attributeData[0].physicalNames ? ctrl.attributeData[0].physicalNames : [],
              category: ctrl.attributeData[0].category,
              collections: ctrl.attributeData[0].collections,
            };
            const lineageGraphData = ctrl.createGraphObjects(attribute, result.lineageTree);
            ctrl.lineageGraphData = lineageGraphData;
            //case of header group for friendly name feature
            if (lineageGraphData.nodesData && lineageGraphData.nodesData.length > 0) {
              if (lineageGraphData.nodesData[0].type === 'attribute') {
                if (ctrl.data && ctrl.data.nodesData && ctrl.data.nodesData.length > 0) {
                  lineageGraphData.nodesData[0].isTheHeaderOfGroup = ctrl.data.nodesData[0].isTheHeaderOfGroup
                    ? ctrl.data.nodesData[0].isTheHeaderOfGroup
                    : false;
                  lineageGraphData.nodesData[0].physicalNames = ctrl.data.nodesData[0].physicalNames
                    ? ctrl.data.nodesData[0].physicalNames
                    : [];
                  lineageGraphData.nodesData[0].original_name = ctrl.data.nodesData[0].original_name
                    ? ctrl.data.nodesData[0].original_name
                    : [];
                }
              }
            }

            ctrl.onEvent({
              event: 'selectTable',
              data: {
                nodesData: lineageGraphData.nodesData,
                linksData: lineageGraphData.linksData,
                lineageTree: result.lineageTree,
              },
            });
          });
        }
        // in case there isn't tables selected
        else {
          ctrl.onEvent({
            event: 'selectTable',
            data: {
              nodesData: null,
              linksData: null,
              attributeType: this.attributeData[0].originType,
              attributeName: this.attributeData[0].title,
            },
          });
        }
      }
      //Column Case
      else {
        if (ctrl.selectedTables && ctrl.selectedTables.length > 0) {
          //*.  case of add table and link
          //*.  search in the connection_details of the selected table
          const tableObjectContainesTheSelectedColumn = ctrl.getTableObject(
            ctrl.lineageTree,
            table.tableNameContainsColumn,
          );
          //*.  get destination table name
          const tableDestinationName = table.name;
          let columnDestinationName = '';
          //*.  search in the origin_fields take the destination_field name
          tableObjectContainesTheSelectedColumn.connection_details.forEach(connectionDetail => {
            if (connectionDetail.origin_fields.split('.').pop(-1) === table.columnSelected) {
              columnDestinationName = connectionDetail.destination_field.split('.').pop(-1);
            }
          });
          //*.  search in the child's in the _id of the tables
          let detinationTableObject = {};
          tableObjectContainesTheSelectedColumn.childs.forEach(child => {
            if (child._id.split('.').pop(-1) === tableDestinationName) {
              detinationTableObject = child;
            }
          });
          //*.  build the table and the link
          ctrl.lineageGraphData = ctrl.addTableToExisitingGraph(
            ctrl.lineageGraphData,
            table.tableNameContainsColumn,
            detinationTableObject,
            columnDestinationName,
            tableDestinationName,
            table.columnSelected,
          );

          ctrl.onEvent({
            event: 'selectTable',
            data: {
              nodesData: ctrl.lineageGraphData.nodesData,
              linksData: ctrl.lineageGraphData.linksData,
              lineageTree: ctrl.lineageTree,
            },
          });
        } else {
          const removeTables = [];

          //array of removeTables
          const removeTablesArr = [];
          //array of tableToSpec
          const tabletoFindArr = [];
          let linksData = ctrl.lineageGraphData.linksData;
          tabletoFindArr.push(table.name);
          //while tableToSpec is not empty
          while (tabletoFindArr && tabletoFindArr.length > 0) {
            const itemToFind = tabletoFindArr.pop();
            //check with the item from the tableToFindArr if it exists on the linksData on the to Table
            removeTablesArr.push(itemToFind);
            linksData = linksData.filter(linkData => linkData.toTable != itemToFind);
            const itemFounded = linksData.find(linkData => linkData.fromTable === itemToFind);
            if (itemFounded) {
              //if it's exist, add the from table to the TableToFind and add the item to removeTables and remove the linkData
              tabletoFindArr.push(itemFounded.toTable);
            }
          }
          ctrl.lineageGraphData.linksData = JSON.parse(JSON.stringify(linksData));

          let nodesData = ctrl.lineageGraphData.nodesData;
          nodesData = nodesData.filter(item => removeTablesArr.indexOf(item.title) === -1);
          ctrl.lineageGraphData.nodesData = JSON.parse(JSON.stringify(nodesData));

          ctrl.onEvent({
            event: 'selectTable',
            data: {
              nodesData: ctrl.lineageGraphData.nodesData,
              linksData: ctrl.lineageGraphData.linksData,
              lineageTree: ctrl.lineageTree,
            },
          });
        }
      }
    };

    ctrl.getTableNameToSpec = lineageTreeToSpec => {
      return lineageTreeToSpec._id.split('.').pop(-1);
    };

    ctrl.addTableToExisitingGraph = (
      lineageGraphData,
      tableNameContainsColumn,
      detinationTableObject,
      detinationField,
      tableDestinationName,
      originColumnSelected,
    ) => {
      let existedNodeIndex = null;
      const existedLinkIndex = [];

      lineageGraphData.nodesData.some((node, index) => {
        if (node.title === tableDestinationName) {
          existedNodeIndex = index;
          return true;
        }
      });

      lineageGraphData.linksData.forEach((link, index) => {
        if (link.toTable === tableDestinationName) {
          existedLinkIndex.push(index);
        }
      });

      if (existedNodeIndex && existedLinkIndex) {
        lineageGraphData.nodesData.splice(existedNodeIndex, 1);
        existedLinkIndex.map(index => {
          lineageGraphData.linksData.splice(index, 1);
        });
      } else {
        let indexParent = '';
        let indexColumnParent = '';
        let lastIndex = '';

        lineageGraphData.nodesData.forEach(nodeData => {
          if (nodeData.title === tableNameContainsColumn) {
            indexParent = nodeData._id;
            nodeData.attributes.forEach((attribute, idx) => {
              if (attribute.name === originColumnSelected) {
                indexColumnParent = idx;
              }
            });
          }
        });
        lastIndex = lineageGraphData.nodesData[lineageGraphData.nodesData.length - 1]._id;
        //create the first layer nodes
        lineageGraphData.nodesData = lineageGraphData.nodesData.concat({
          _id: ++lastIndex,
          type: 'table',
          title: tableDestinationName,
          schema: `${detinationTableObject._id.split('.').slice(-3)[0]} (${
            detinationTableObject._id.split('.').slice(-2)[0]
          })`,
          schemaColor: ctrl.getSchemaColor(detinationTableObject._id.split('.').slice(-2)[0]),
          attributes: ctrl.getAttributesArray(detinationTableObject, lineageGraphData),
          hasAttribute: detinationTableObject.fields.map(field =>
            field.fieldAttribute && field.fieldAttribute.length > 0 ? 'hasAttribute' : '',
          ),
          attributesConnectedToTables: detinationTableObject.connection_details.map(connectorField =>
            connectorField.origin_fields.split('.').pop(),
          ),
          tooltip: detinationTableObject.fields.map(field =>
            ((field.fieldClassifications || []).length > 0 ? 'Classification: \n -------------------- \n ' : '')
              .concat((field.fieldClassifications || []).map(fc => fc.name).join(' \n '))
              .concat(' \n \n ')
              .concat(
                (field.fieldAttribute || []).filter(attr => !attr.name.includes('enrich')).length
                  ? ' Attributes: \n  --------------- \n '
                  : '',
              )
              .concat(
                (field.fieldAttribute || [])
                  .filter(attr => !attr.name.includes('enrich'))
                  .map(attr => attr.name)
                  .join(' \n '),
              )
              .concat('\n')
              .concat(
                (field.fieldAttribute || []).filter(attr => attr.name.includes('enrich')).length
                  ? ' Enrichments: \n ------------------- \n '
                  : '',
              )
              .concat(
                (field.fieldAttribute || [])
                  .filter(attr => attr.name.includes('enrich'))
                  .map(attr => attr.name)
                  .join(' \n '),
              ),
          ),
        });

        let destinationColumnIndex = '';
        lineageGraphData.nodesData[lineageGraphData.nodesData.length - 1].attributes.forEach((attribute, idx) => {
          if (attribute.name === detinationField) {
            destinationColumnIndex = idx;
          }
        });

        //index represent: TableID_ColumnID
        const indexParentS = `000${indexParent}`.slice(-3);
        const indexColumnParentS = `000${indexColumnParent + 1}`.slice(-3);
        //let indexColumnParentS = `000${indexColumnParent}`.slice(-3);
        const lastIndexS = `000${lastIndex}`.slice(-3);
        const destinationColumnIndexS = `000${destinationColumnIndex + 1}`.slice(-3);
        //let destinationColumnIndexS = `000${destinationColumnIndex}`.slice(-3);
        lineageGraphData.linksData.push({
          fromTable: tableNameContainsColumn,
          from: `${indexParentS}_${indexColumnParentS}`,
          to: `${lastIndexS}_${destinationColumnIndexS}`,
          toTable: tableDestinationName,
          type: `fromTableByCustomLink`,
          fromPort: `${indexParentS}_${indexColumnParentS}`,
          toPort: `${lastIndexS}_${destinationColumnIndexS}`,
        });
      }

      return lineageGraphData;
    };

    ctrl.getSchemaColor = schemaName => {
      const index = ctrl.schemaNamesArray.indexOf(schemaName);
      if (index > -1) {
        return ctrl.schemaColor[index];
      } else {
        ctrl.schemaNamesArray.push(schemaName);
        return ctrl.schemaColor[ctrl.schemaNamesArray.length - 1];
      }
    };

    ctrl.getAttributesArray = (table, lineageObject, index) => {
      let lineageObjectLocal;
      const fieldsToCompare = [];
      if (typeof index !== 'undefined') {
        lineageObjectLocal = lineageObject[index];
        if (lineageObjectLocal && lineageObjectLocal.connection_details) {
          lineageObjectLocal.connection_details.forEach(connection => {
            fieldsToCompare.push(connection.origin_fields.split('.').slice(-1)[0]);
          });
        }
      } else {
        if (lineageObject && lineageObject.connection_details) {
          lineageObject.connection_details.forEach(connection => {
            fieldsToCompare.push(connection.origin_fields.split('.').slice(-1)[0]);
          });
        }
      }
      const attributes = [];
      table.fields.forEach(field => {
        const fieldObject = {};
        fieldObject.name = field.fieldName;
        const fieldsToCompareCount = new Map(
          [...new Set(fieldsToCompare)].map(x => [x, fieldsToCompare.filter(y => y === x).length]),
        );
        const count = fieldsToCompareCount.get(field.fieldName);
        if (count > 0) {
          fieldObject.linkToOtherTables = count;
        }
        attributes.push(fieldObject);
      });
      return attributes;
    };

    const getRankCapitalized = rank => {
      const _rank = rank.toLowerCase();

      return _rank.charAt(0).toUpperCase() + _rank.substring(1);
    };

    const getConfidenceLevelLabelText = field => {
      const { confidence_level, rank } = field;

      let label;

      if (typeof confidence_level == 'undefined') {
        label = getRankCapitalized(rank);
      } else {
        label = `${getRankCapitalized(rank)} (${Math.floor(confidence_level * 100)}%)`;
      }

      return label;
    };

    ctrl.createGraphObjects = (attribute, lineageTree) => {
      let index = 0;
      const graphObjects = [];
      graphObjects.nodesData = [];
      graphObjects.linksData = [];

      //create the origin attribute
      graphObjects.nodesData.push({
        _id: ++index,
        type: 'attribute',
        category: attribute.category,
        originType: attribute.originType,
        collections: attribute.collections,
        tooltip: '',
        title: attribute.name,
        physicalName: attribute.original_name,
        hasAttribute: 'false',
      });
      //create the first layer nodes

      graphObjects.nodesData = graphObjects.nodesData.concat(
        lineageTree.map((table, idx) => ({
          _id: ++index,
          type: 'table',
          title: table._id.split('.').pop(-1),
          schema: `${table._id.split('.').slice(-3)[0]} (${table._id.split('.').slice(-2)[0]})`,
          schemaColor: ctrl.getSchemaColor(table._id.split('.').slice(-2)[0]),
          attributes: ctrl.getAttributesArray(table, lineageTree, idx),
          attachedIdConnectionName: table.attachedIdConnectionName,
          hasAttribute: table.fields.map(field =>
            field.fieldAttribute && field.fieldAttribute.length > 0 ? 'hasAttribute' : '',
          ),

          attributesConnectedToTables: table.connection_details.map(connectorField =>
            connectorField.origin_fields.split('.').pop(),
          ),

          tooltip: table.fields.map((field, idx) =>
            ((field.fieldClassifications || []).length > 0 ? 'Classification: \n -------------------- \n ' : '')
              .concat((field.fieldClassifications || []).map(fc => fc.name).join(' \n '))
              .concat(' \n \n ')
              .concat(
                (field.fieldAttribute || []).filter(attr => !attr.name.includes('enrich')).length
                  ? ' Attributes: \n  --------------- \n '
                  : '',
              )
              .concat(
                (field.fieldAttribute || [])
                  .filter(attr => !attr.name.includes('enrich'))
                  .map(attr => attr.name)
                  .join(' \n '),
              )
              .concat('\n')
              .concat(
                (field.fieldAttribute || []).filter(attr => attr.name.includes('enrich')).length
                  ? ' Enrichments: \n ------------------- \n '
                  : '',
              )
              .concat(
                (field.fieldAttribute || [])
                  .filter(attr => attr.name.includes('enrich'))
                  .map(attr => attr.name)
                  .join(' \n '),
              ),
          ),
        })),
      );

      for (let j = 0; j < graphObjects.nodesData.length; j++) {
        if (j >= 1) {
          const linkIndexes = [];
          const linkLabels = [];
          let index = 1;

          const fieldsProp = attribute.originType == 'classifications' ? 'fieldClassifications' : 'fieldAttribute';

          lineageTree[j - 1].fields.forEach(field => {
            if (field[fieldsProp] !== undefined && field[fieldsProp].length > 0) {
              //case of group header
              if (attribute.isTheHeaderOfGroup && attribute.physicalNames && attribute.physicalNames.length > 0) {
                attribute.physicalNames.forEach(name => {
                  const searchField = field[fieldsProp].find(innerField => name == innerField.name);
                  if (typeof searchField != 'undefined') {
                    linkIndexes.push(index);
                    linkLabels[index] = getConfidenceLevelLabelText(searchField);
                  }
                });
              } else {
                const searchField = field[fieldsProp].find(innerField => attribute.original_name == innerField.name);
                if (typeof searchField != 'undefined') {
                  linkIndexes.push(index);
                  linkLabels[index] = getConfidenceLevelLabelText(searchField);
                }
              }
            }
            index++;
          });

          if (linkIndexes.length > 0) {
            linkIndexes.forEach(index => {
              const jS = `000${j + 1}`.slice(-3);
              const indexS = `000${index}`.slice(-3);
              //index represent: TableID_ColumnID
              graphObjects.linksData.push({
                fromAttribute: graphObjects.nodesData[0].title,
                from: '001',
                to: `${jS}_${indexS}`,
                toTable: graphObjects.nodesData[j].title,
                type: `fromAttribute`,
                fromPort: '001',
                toPort: `${jS}_${indexS}`,
                text: linkLabels[index],
              });
            });
          }
        }
      }
      for (let i = 0; i < lineageTree.length; i++) {
        if (lineageTree[i].childs && lineageTree[i].childs.length > 0) {
          let child = lineageTree[i].childs[0];

          let father = lineageTree[i];
          while (child) {
            graphObjects.nodesData = graphObjects.nodesData.concat(
              [child].map(table => ({
                _id: ++index,
                type: 'table',
                title: table._id.split('.').pop(-1),
                schema: `${table._id.split('.').slice(-3)[0]} (${table._id.split('.').slice(-2)[0]})`,
                schemaColor: ctrl.getSchemaColor(table._id.split('.').slice(-2)[0]),
                attributes: ctrl.getAttributesArray(table, child),
                hasAttribute: table.fields.map(field =>
                  field.fieldAttribute && field.fieldAttribute.length > 0 ? 'hasAttribute' : '',
                ),
                attributesConnectedToTables: table.connection_details.map(connectorField =>
                  connectorField.origin_fields.split('.').pop(),
                ),
                tooltip: table.fields.map((field, idx) =>
                  ((field.fieldClassifications || []).length > 0 ? 'Classification: \n -------------------- \n ' : '')
                    .concat((field.fieldClassifications || []).map(fc => fc.name).join(' \n '))
                    .concat(' \n \n ')
                    .concat(
                      (field.fieldAttribute || []).filter(attr => !attr.name.includes('enrich')).length
                        ? ' Attributes: \n  --------------- \n '
                        : '',
                    )
                    .concat(
                      (field.fieldAttribute || [])
                        .filter(attr => !attr.name.includes('enrich'))
                        .map(attr => attr.name)
                        .join(' \n '),
                    )
                    .concat('\n')
                    .concat(
                      (field.fieldAttribute || []).filter(attr => attr.name.includes('enrich')).length
                        ? ' Enrichments: \n ------------------- \n '
                        : '',
                    )
                    .concat(
                      (field.fieldAttribute || [])
                        .filter(attr => attr.name.includes('enrich'))
                        .map(attr => attr.name)
                        .join(' \n '),
                    ),
                ),
              })),
            );
            let fatherTableIndex = null;
            const fatherTableNameArr = father._id.split('.');
            const fatherTableName = fatherTableNameArr[fatherTableNameArr.length - 1];
            const fatherTableNode = graphObjects.nodesData.find(node => node.title === fatherTableName);
            if (fatherTableNode) {
              fatherTableIndex = fatherTableNode._id;
            }

            for (let i = 0; i < father.connection_details.length; i++) {
              const originFieldNameArr = father.connection_details[i].origin_fields.split('.');
              const originFieldName = originFieldNameArr[originFieldNameArr.length - 1];

              const destinationFieldNameArr = father.connection_details[i].destination_field.split('.');
              const destinationFieldName = destinationFieldNameArr[destinationFieldNameArr.length - 1];

              const fatherColumnIndex = father.fields.findIndex(field => originFieldName == field.fieldName);
              const childColumnIndex = child.fields.findIndex(field => destinationFieldName == field.fieldName);

              if (fatherColumnIndex !== -1 && childColumnIndex !== -1) {
                //index represent: TableID_ColumnID
                const fatherTableIndexS = `000${fatherTableIndex}`.slice(-3);
                const fatherColumnIndexS = `000${fatherColumnIndex + 1}`.slice(-3);
                //let fatherColumnIndexS = `000${fatherColumnIndex}`.slice(-3);
                const indexS = `000${index}`.slice(-3);
                const childColumnIndexS = `000${childColumnIndex + 1}`.slice(-3);
                //let childColumnIndexS = `000${childColumnIndex}`.slice(-3);
                graphObjects.linksData.push({
                  fromTable: graphObjects.nodesData[fatherTableIndex - 1].title,
                  from: `${fatherTableIndexS}_${fatherColumnIndexS}`,
                  to: `${indexS}_${childColumnIndexS}`,
                  toTable: graphObjects.nodesData[index - 1].title,
                  type: `fromTable`,
                  fromPort: `${fatherTableIndexS}_${fatherColumnIndexS}`,
                  toPort: `${indexS}_${childColumnIndexS}`,
                  text: null,
                });
              }
            }

            if (child.childs && child.childs.length > 0) {
              father = child;
              child = child.childs[0];
            } else {
              child = null;
              father = null;
            }
          }
        }
      }

      return graphObjects;
    };

    ctrl.closeSelectTable = () => {
      ctrl.parent.isNodeSelected = false;
    };

    ctrl.onUserQueryChanged = query => {
      if (query === '') {
        ctrl.tables = initializeTopNodes(ctrl.primaryData);
      } else {
        const potentialNodes = ctrl.primaryData.filter(table =>
          table.fullTableName.toLowerCase().includes(query.toLowerCase()),
        );

        if (potentialNodes.length > 0) {
          ctrl.tables = buildTrees(potentialNodes, true);
        } else {
          ctrl.tables = [];
        }
      }
    };
  },
  bindings: {
    show: '<',
    attributeData: '=',
    lineageTree: '=',
    data: '<',
    onEvent: '&',
    isLoading: '<',
  },
  require: {
    parent: '^identityLineage',
  },
});

function getLeaves(tables) {
  const leaves = [];
  const iterateTree = nodes => {
    nodes.forEach(node => {
      if (node.isExpanded) {
        iterateTree(node.children);
      } else {
        leaves.push(node);
      }
    });
  };
  iterateTree(tables);
  return leaves;
}
