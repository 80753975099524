import { module } from 'angular';
const app = module('app');

app.factory('sarBulkRequestService', function ($http, appSettings, downloadFileService) {
  'ngInject';

  const SAR_BULK_DOWNLOAD_URL = appSettings.serverPath + '/' + appSettings.version + '/sar/reports/bulk/csv-format';
  const SAR_BULK_UPLOAD_URL = appSettings.serverPath + '/' + appSettings.version + '/sar/reports/bulk-from-csv';
  const DEFAULT_CSV_FILE_NAME = 'sar-bulk-request-template.csv';

  return {
    downloadCSVTemplate: profileId => {
      return $http({
        method: 'GET',
        url: `${SAR_BULK_DOWNLOAD_URL}?profileId=${profileId}`,
      }).then(response => {
        const csv = response.data;
        const contentDispositionHeader = response.headers('content-disposition');
        const fileName = contentDispositionHeader ? contentDispositionHeader.split('filename=').pop() : '';
        downloadFileService.download(csv, 'text/csv; charset=utf-8', fileName || DEFAULT_CSV_FILE_NAME);
      });
    },

    uploadFile: ({ file, profileId, profileName }) => {
      const formData = new FormData();
      formData.append('fileToImport', file);
      formData.append('profileId', profileId);
      formData.append('profileName', profileName);

      return $http
        .post(SAR_BULK_UPLOAD_URL, formData, {
          transformRequest: angular.identity,
          headers: { 'Content-Type': undefined },
        })
        .then(response => response.data);
    },
  };
});
