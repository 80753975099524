import React, { FC, memo, useRef, useEffect } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import {
  BigidHeading6,
  BigidBody3,
  BigidTooltip,
  BigidChip,
  BigidTrueTag,
  BigidColorsV2,
  BigidButtonIcon,
} from '@bigid-ui/components';
import { BigidXIcon } from '@bigid-ui/icons';
import {
  MetadataSearchResultEntity,
  MetadataSearchResultEntityFieldName,
} from '../../components/MetadataSearch/MetadataSearchTypes';
import { getMetadataSearchEntityFieldByName } from '../../components/MetadataSearch/MetadataSearchService';
import { getTagNameAndValueSplitted } from '../../utilities/tags';

const useStyles = makeStyles({
  root: {
    height: '100%',
    width: '100%',
    boxShadow: '0px 1px 4px rgba(0, 0, 0, 0.25)',
    borderRadius: '4px',
  },
  header: {
    display: 'flex',
    height: '40px',
    justifyContent: 'flex-end',
    padding: '0 4px',
  },
  closeIcon: {
    display: 'inline-flex',
    alignItems: 'center',
  },
  body: {
    height: 'calc(100% - 40px)',
  },
  assetsList: {
    padding: '0 21px',
    overflowY: 'auto',
    overflowX: 'hidden',
    flexDirection: 'column',
    flexWrap: 'nowrap',
    height: '100%',
    width: '100%',
  },
  assetItem: {
    marginBottom: '16px',
  },
  assetItemName: {
    marginBottom: '4px',
  },
  assetItemValue: {
    width: '100%',
    display: 'flex',
    flexWrap: 'wrap',
  },
  assetItemValueInner: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  assetItemValueEntity: {
    padding: '4px',
  },
});

export interface MetadataSearchAssetsPreviewProps {
  dataAid?: string;
  resultEntity: MetadataSearchResultEntity;
  onClose: () => void;
}

export const MetadataSearchAssetsPreview: FC<MetadataSearchAssetsPreviewProps> = memo(
  ({ dataAid = 'MetadataSearchAssetsPreview', resultEntity, onClose }) => {
    const classes = useStyles();

    const assetsListRef = useRef<HTMLDivElement>(null);

    const tagsFieldObject = getMetadataSearchEntityFieldByName(resultEntity, MetadataSearchResultEntityFieldName.TAGS);
    const tags = tagsFieldObject?.value;
    const highlightedTag = tagsFieldObject?.highlightedValue?.replace(/<\/?[^>]+(>|$)/g, '');

    const attributesFieldObject = getMetadataSearchEntityFieldByName(
      resultEntity,
      MetadataSearchResultEntityFieldName.ATTRIBUTES,
    );
    const attributes = attributesFieldObject?.value;
    const highlightedAttribute = attributesFieldObject?.highlightedValue?.replace(/<\/?[^>]+(>|$)/g, '');

    useEffect(() => {
      assetsListRef.current?.scrollTo(0, 0);
    }, [resultEntity]);

    return (
      <div className={classes.root} data-aid={dataAid}>
        <div className={classes.header}>
          <div className={classes.closeIcon}>
            <BigidButtonIcon icon={BigidXIcon} onClick={onClose} dataAid={`${dataAid}-close`} />
          </div>
        </div>
        <div className={classes.body}>
          <div className={classes.assetsList} ref={assetsListRef}>
            {resultEntity.assets?.map(({ name, value }) => {
              return (
                <div key={name} className={classes.assetItem} data-aid={`${dataAid}-${name}-asset`}>
                  <div className={classes.assetItemName}>
                    <BigidHeading6 data-aid={`${dataAid}-${name}-name`}>{name}</BigidHeading6>
                  </div>
                  <BigidTooltip
                    title={value}
                    placement="top"
                    enterDelay={1500}
                    isDisabled={typeof value === typeof true}
                  >
                    <div className={classes.assetItemValue}>
                      <div className={classes.assetItemValueInner}>
                        <BigidBody3 data-aid={`${dataAid}-${name}-${value}-value`}>{String(value)}</BigidBody3>
                      </div>
                    </div>
                  </BigidTooltip>
                </div>
              );
            })}
            {Array.isArray(tags) && tags?.length > 0 && (
              <div
                key={MetadataSearchResultEntityFieldName.TAGS}
                className={classes.assetItem}
                data-aid={`${dataAid}-tags-asset`}
              >
                <div className={classes.assetItemName}>
                  <BigidHeading6>Tags</BigidHeading6>
                </div>
                <div className={classes.assetItemValue}>
                  {tags
                    .sort((tagA, tagB) => {
                      return tagA === highlightedTag ? -1 : tagB === highlightedTag ? 1 : 0;
                    })
                    .map(tag => {
                      const tagStringified = tag.toString();
                      const shouldHighlight = tag === highlightedTag;
                      const bgColor = shouldHighlight ? '#FAF2C9' : BigidColorsV2.green[50];
                      const [name, value] = getTagNameAndValueSplitted(tagStringified);

                      return (
                        <div key={tagStringified} className={classes.assetItemValueEntity}>
                          <BigidTrueTag
                            dataAid={`${dataAid}-tag-${tagStringified}`}
                            name={name}
                            value={value}
                            bgColor={bgColor}
                            shadow
                          />
                        </div>
                      );
                    })}
                </div>
              </div>
            )}
            {Array.isArray(attributes) && attributes?.length > 0 && (
              <div
                key={MetadataSearchResultEntityFieldName.ATTRIBUTES}
                className={classes.assetItem}
                data-aid={`${dataAid}-attributes-asset`}
              >
                <div className={classes.assetItemName}>
                  <BigidHeading6>Attributes</BigidHeading6>
                </div>
                <div className={classes.assetItemValue}>
                  {attributes
                    .sort((attributeA, attributeB) => {
                      return attributeA === highlightedAttribute ? -1 : attributeB === highlightedAttribute ? 1 : 0;
                    })
                    .map(attribute => {
                      const attributeStringified = attribute.toString();
                      const shouldHighlight = attribute === highlightedAttribute;
                      const bgColor = shouldHighlight ? '#FAF2C9' : BigidColorsV2.white;

                      return (
                        <div key={attributeStringified} className={classes.assetItemValueEntity}>
                          <BigidChip
                            dataAid={`${dataAid}-attribute-${attributeStringified}`}
                            label={attributeStringified}
                            bgColor={bgColor}
                            shadow
                          />
                        </div>
                      );
                    })}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  },
);
