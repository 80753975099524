import React, { FC, useEffect, useState } from 'react';
import {
  BigidBody1,
  BigidHeading5,
  BigidStatusBadge,
  BigidStatusBadgeType,
  PrimaryButton,
  BigidStatusBadgeSize,
  SecondaryButton,
} from '@bigid-ui/components';
import { BigidSyncIcon, BigidApplyFilledIcon } from '@bigid-ui/icons';
import styled from '@emotion/styled';
import { useLocalTranslation } from '../translations';
import makeStyles from '@mui/styles/makeStyles';
import { useTheme } from '@mui/styles';
import { generateDataAid } from '@bigid-ui/utils';
import { GettingStartedStep } from '../types';

const Card = styled('div')<{ isActive?: boolean }>(({ theme, isActive }) => ({
  display: 'flex',
  flexDirection: 'column',
  position: 'relative',
  minWidth: '275px',
  maxHeight: '524px',
  padding: '72px 32px 32px 32px',
  justifyContent: 'space-between',
  alignItems: 'flex-start',
  borderRadius: '8px',
  border: isActive ? `1px solid ${theme.vars.palette.bigid.gray500}` : theme.vars.tokens.bigid.borderDefault,
  color: `${theme.vars.tokens.bigid.foregroundSecondary}`,
  background: `${theme.vars.palette.bigid.gray50}`,
  boxShadow: isActive ? theme.vars.tokens.bigid.shadow5 : 'none',
}));

const ActionButtonContainer = styled('div')(() => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: '100%',
  '& button': {
    maxWidth: '476px',
  },
}));

const ProcessingBadge = styled('div')(() => ({
  position: 'absolute',
  right: '32px',
  top: '32px',
}));

const StepTitleRow = styled('div')(({ theme }) => ({
  marginRight: 0,
  flexDirection: 'row',
  display: 'flex',
  alignItems: 'flex-start',
}));

const StepDescription = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  alignSelf: 'stretch',
  maxWidth: '476px',
  padding: '12px 0px',
}));

const useStyles = makeStyles({
  overflowImage: {
    position: 'absolute',
    top: '-22.5%',
    left: 0,
    width: '211px',
  },
});

export const GettingStartedCard: FC<GettingStartedStep> = ({
  id,
  isActive,
  title,
  description,
  activeIcon: ActiveIcon,
  disableIcon: DisableIcon,
  actionLabel,
  actionMethod,
  checkIsComplete,
  isComplete,
  checkInProgress,
  checkIsActive,
}) => {
  const { t } = useLocalTranslation('card');
  const theme = useTheme();
  const classes = useStyles({});
  const [isProcessing, setIsProcessing] = useState(false);
  const cardDataAid = `Card-${id}`;

  useEffect(() => {
    const processingStatus = checkInProgress();
    if (processingStatus !== isProcessing) {
      setIsProcessing(processingStatus);
    }
  }, []);

  return (
    <>
      <Card isActive={isActive} data-aid={cardDataAid}>
        {isActive || isComplete ? (
          <ActiveIcon className={classes.overflowImage} />
        ) : (
          <DisableIcon className={classes.overflowImage} />
        )}
        <ProcessingBadge>
          {isProcessing && (
            <BigidStatusBadge
              icon={<BigidSyncIcon />}
              label={t('processing')}
              size={BigidStatusBadgeSize.SMALL}
              type={BigidStatusBadgeType.INFO}
            />
          )}
        </ProcessingBadge>
        <StepTitleRow>
          <BigidApplyFilledIcon
            color={isComplete ? theme.vars.tokens.bigid.positive : theme.vars.tokens.bigid.pending}
            style={{
              marginTop: '3px',
            }}
          />
          <BigidHeading5
            color={theme.vars.tokens.bigid.foregroundSecondary}
            paddingLeft={'4px'}
            data-aid={generateDataAid(cardDataAid, [title])}
          >
            {title}
          </BigidHeading5>
        </StepTitleRow>
        <StepDescription>
          <BigidBody1 color={theme.vars.tokens.bigid.foregroundSecondary}>{description}</BigidBody1>
        </StepDescription>
        <ActionButtonContainer>
          {isActive ? (
            <PrimaryButton
              onClick={actionMethod}
              size="large"
              text={actionLabel}
              width={'fill'}
              dataAid={generateDataAid(cardDataAid, ['primary', 'button'])}
            />
          ) : (
            <SecondaryButton
              onClick={actionMethod}
              size="large"
              text={actionLabel}
              width={'fill'}
              dataAid={generateDataAid(cardDataAid, ['secondary', 'button'])}
            />
          )}
        </ActionButtonContainer>
      </Card>
    </>
  );
};
