import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { BigidHeading6, TertiaryButton, BigidSelectOption, BigidTooltip } from '@bigid-ui/components';
import { AutoDiscoverySelectAuthTypeField } from '../autoDiscoveryComponents/fields/AutoDiscoverySelectAuthTypeField';
import { styled } from '@mui/material';
import { TYPE_TO_AUTH_OPTIONS, CloudProvider } from '../../constants';
import { BigidCopyIcon } from '@bigid-ui/icons';
import { copyPrerequisitesInstructions } from '../autoDiscoveryWizardUtils';
import { AutoDiscoveryPrerequisitesContent } from './AutoDiscoveryPrerequisitesContent';
import { AutoDiscoveryWizardContextState } from '../autoDiscoveryWizardContext';
import { useLocalTranslation } from '../../translations';

const AutoDiscoverWizardPrerequisitesContainer = styled('div')`
  height: 568px;
  gap: 8px;
  display: flex;
  flex-direction: column;
  padding: 8px 16px;
  font-size: 14px;
  color: ${({ theme }) => theme.palette.bigid.gray700};
`;

const AutoDiscoverWizardControlWrapper = styled('div')`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-top: 8px;
`;

const AutoDiscoverWizardSelectWrapper = styled('div')`
  width: 276px;
`;

export interface AutoDiscoverWizardPrerequisitesModalProps {
  type: CloudProvider;
  discoveryConfigDataRef: React.MutableRefObject<AutoDiscoveryWizardContextState>;
}

export const AutoDiscoverWizardPrerequisitesModal: FC<AutoDiscoverWizardPrerequisitesModalProps> = ({
  type,
  discoveryConfigDataRef,
}) => {
  const { t } = useLocalTranslation('AutoDiscovery');
  const [authType, setAuthTypeState] = useState([TYPE_TO_AUTH_OPTIONS[type]?.[0]]);
  const setAuthType = useCallback(
    (option: any) => {
      setAuthTypeState(option);
      discoveryConfigDataRef.current = {
        ...discoveryConfigDataRef.current,
        formData: {
          authType: option.value ?? (option as unknown as BigidSelectOption[])?.[0]?.value,
        },
      };
    },
    [discoveryConfigDataRef],
  );
  useEffect(() => {
    setAuthType([TYPE_TO_AUTH_OPTIONS[type]?.[0]]);
  }, [setAuthType, type]);

  const autoDiscoverySelectAuthTypeFieldMisc = useMemo(
    () => ({
      type,
    }),
    [type],
  );

  return (
    <AutoDiscoverWizardPrerequisitesContainer>
      <div data-aid="AutoDiscoverWizardPrerequisitesHeader">
        Choose which{' '}
        <BigidHeading6
          sx={{
            display: 'inline-flex',
          }}
        >
          {t('authenticationType')}
        </BigidHeading6>{' '}
        to use for connecting to your data sources. Follow the instructions for configuring your selected authentication
        method and preparing your data sources for onboarding discovery.
      </div>
      <AutoDiscoverWizardControlWrapper>
        <AutoDiscoverWizardSelectWrapper>
          <AutoDiscoverySelectAuthTypeField
            value={authType}
            setValue={setAuthType}
            disabled={false}
            misc={autoDiscoverySelectAuthTypeFieldMisc}
          />
        </AutoDiscoverWizardSelectWrapper>
        <BigidTooltip title={t('prerequisites.buttons.copyInstructionsTooltip')}>
          <div>
            <TertiaryButton
              onClick={copyPrerequisitesInstructions}
              dataAid={'copy-auto-discovery-prerequisites-instructions'}
              size={'large'}
              startIcon={<BigidCopyIcon />}
              text={t('prerequisites.buttons.copyInstructions')}
            />
          </div>
        </BigidTooltip>
      </AutoDiscoverWizardControlWrapper>
      <AutoDiscoveryPrerequisitesContent type={type} authType={authType?.[0]?.value} />
    </AutoDiscoverWizardPrerequisitesContainer>
  );
};
