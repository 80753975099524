import {
  AdvancedToolbarOverrideValue,
  BigidAdvancedToolbarDateRangeFilter,
  BigidAdvancedToolbarDropdownFilter,
  BigidAdvancedToolbarFilterTypes,
  BigidAdvancedToolbarFilterUnion,
  BigidDropdownOption,
  BigidFieldFilterOperator,
  UseCancelablePromise,
} from '@bigid-ui/components';
import { Operators, parseGraphQueryNodes, RulesStructNode } from '@bigid/query-object-serialization';
import { capitalize, groupBy, partition, pick, uniqBy } from 'lodash';
import { getFixedT } from './translations';
import {
  Aggregation,
  AggregationFilterConfig,
  AggregationFilterOperand,
  AggregationItemBase,
  AggregationItemName,
  AggregationPossesionIdentifier,
  AggregationType,
  GetAggredatedDataRequestObject,
  GetAggregatedDataPayload,
  GetAggregatedDataResponse,
  NonAggregationType,
} from './types';
import { v4 as uuid } from 'uuid';
import {
  aggregationIrrelevantNumericFieldIdentifier,
  fetchAllFilterOptionsLimit,
  searchTextMinLength,
} from './constants';
import { formatNumberCompact } from '../../utilities/numericDataConverter';
import { getAggregatedData } from './explorerSearchResultsService';
import { parseDateFilterOutputToQueryString } from './dateHelpers';

type FetchFilterOptionsPreprocessorFunc = (items: BigidDropdownOption[]) => BigidDropdownOption[];

type GetFilterOptionsFetchMethodPayload = {
  aggName: AggregationType;
  filterOperand: AggregationFilterOperand;
  filter: BigidAdvancedToolbarFilterUnion[];
  value: string;
  shouldFetchEverything?: boolean;
  searchText?: string;
};

type FetchFilterOptionsTransformerFunc = (
  aggregation: Aggregation,
  itemsPreprocessor?: FetchFilterOptionsPreprocessorFunc,
) => BigidDropdownOption[];

export const mapAggregationToFilterTranslationKey: { [key in AggregationType | NonAggregationType]?: string } = {
  [AggregationType.DATA_SOURCE_NAME]: 'dataSourceName',
  [AggregationType.DATA_SOURCE_TYPE]: 'dataSourceType',
  [AggregationType.DATA_SOURCE_LOCATION]: 'dataSourceLocation',
  [AggregationType.OBJECT_OWNER]: 'objectOwner',
  [AggregationType.CLOUD_TYPE]: 'cloudType',
  [AggregationType.FILE_TYPE]: 'fileType',
  [AggregationType.VIOLATED_POLICY]: 'policy',
  [AggregationType.DATA_FORMAT]: 'dataFormat',
  [AggregationType.APPLICATION]: 'application',
  [AggregationType.TAGS]: 'tags',
  [AggregationType.ATTRIBUTE_CATEGORY]: 'attributeName',
  [AggregationType.ATTRIBUTE_NAME]: 'attributeName',
  [AggregationType.ATTRIBUTE_TYPE]: 'attributeType',
  [AggregationType.HAS_DUPLICATES]: 'hasDuplicates',
  [AggregationType.HAS_OPEN_ACCESS]: 'hasOpenAccess',
  [AggregationType.SENSITIVITY_FILTER]: 'sensitivityFilter',
  [AggregationType.OBJECT_STATUS]: 'objectStatus',
  [AggregationType.OBJECT_SCAN_STATUS]: 'objectScanStatus',
  [AggregationType.CONTAINER_NAME]: 'containerName',
  [AggregationType.RISK]: 'risk',
  [AggregationType.ACCESS_TYPE_FILTER]: 'accessTypeFilter',
  [NonAggregationType.SCAN_DATE]: 'scanDate',
  [NonAggregationType.LAST_ACCESS_DATE]: 'lastAccessDate',
  [NonAggregationType.MODIFIED_DATE]: 'modifiedDate',
};

export const aggregationFilterConfig: {
  [key in NonAggregationType | AggregationType]?: AggregationFilterConfig;
} = {
  [AggregationType.DATA_SOURCE_NAME]: {
    type: BigidAdvancedToolbarFilterTypes.DROPDOWN,
    id: AggregationType.DATA_SOURCE_NAME,
    field: AggregationFilterOperand.DATA_SOURCE_NAME,
    operator: 'in',
    options: [],
    disabled: true,
  },
  [AggregationType.DATA_SOURCE_TYPE]: {
    type: BigidAdvancedToolbarFilterTypes.DROPDOWN,
    id: AggregationType.DATA_SOURCE_TYPE,
    field: AggregationFilterOperand.DATA_SOURCE_TYPE,
    operator: 'in',
    options: [],
    disabled: false,
  },
  [AggregationType.DATA_SOURCE_LOCATION]: {
    type: BigidAdvancedToolbarFilterTypes.DROPDOWN,
    id: AggregationType.DATA_SOURCE_LOCATION,
    field: AggregationFilterOperand.DATA_SOURCE_LOCATION,
    operator: 'in',
    options: [],
    disabled: true,
  },
  [AggregationType.OBJECT_OWNER]: {
    type: BigidAdvancedToolbarFilterTypes.DROPDOWN,
    id: AggregationType.OBJECT_OWNER,
    field: AggregationFilterOperand.OBJECT_OWNER,
    operator: 'in',
    options: [],
    disabled: true,
  },
  [AggregationType.OBJECT_STATUS]: {
    type: BigidAdvancedToolbarFilterTypes.DROPDOWN,
    id: AggregationType.OBJECT_STATUS,
    field: AggregationFilterOperand.OBJECT_STATUS,
    operator: 'equal',
    options: [],
    disabled: true,
    isSearchable: false,
    isBoolean: true,
  },
  [AggregationType.CLOUD_TYPE]: {
    type: BigidAdvancedToolbarFilterTypes.DROPDOWN,
    id: AggregationType.CLOUD_TYPE,
    field: AggregationFilterOperand.CLOUD_TYPE,
    operator: 'in',
    options: [],
    disabled: false,
  },
  [AggregationType.FILE_TYPE]: {
    type: BigidAdvancedToolbarFilterTypes.DROPDOWN,
    id: AggregationType.FILE_TYPE,
    field: AggregationFilterOperand.FILE_TYPE,
    operator: 'in',
    options: [],
    disabled: true,
  },
  [AggregationType.DATA_FORMAT]: {
    type: BigidAdvancedToolbarFilterTypes.DROPDOWN,
    id: AggregationType.DATA_FORMAT,
    field: AggregationFilterOperand.DATA_FORMAT,
    operator: 'in',
    options: [],
    disabled: true,
    isSearchable: false,
    getFilterOptionsPreprocessed: capitaliseFilterOptionDisplayValue,
  },
  [AggregationType.APPLICATION]: {
    type: BigidAdvancedToolbarFilterTypes.DROPDOWN,
    id: AggregationType.APPLICATION,
    field: AggregationFilterOperand.APPLICATION,
    operator: 'in',
    options: [],
    disabled: true,
  },
  [AggregationType.TAGS]: {
    type: BigidAdvancedToolbarFilterTypes.DROPDOWN,
    id: AggregationType.TAGS,
    field: AggregationFilterOperand.TAGS,
    operator: 'in',
    options: [],
    optionsDisplayLimit: fetchAllFilterOptionsLimit,
    disabled: false,
    isSearchable: false,
    leftOperandComposerFunc: getTagsLeftOperand,
    hasNestedItems: true,
  },
  [AggregationType.SENSITIVITY_FILTER]: {
    type: BigidAdvancedToolbarFilterTypes.DROPDOWN,
    id: AggregationType.SENSITIVITY_FILTER,
    field: AggregationFilterOperand.SENSITIVITY_FILTER,
    operator: 'in',
    options: [],
    disabled: false,
    isSearchable: false,
    leftOperandComposerFunc: getSensitivityClassificationLeftOperand,
    hasNestedItems: true,
  },
  [AggregationType.ATTRIBUTE_CATEGORY]: {
    type: BigidAdvancedToolbarFilterTypes.DROPDOWN,
    id: AggregationType.ATTRIBUTE_CATEGORY,
    field: AggregationFilterOperand.ATTRIBUTE_CATEGORY,
    operator: 'in',
    options: [],
    disabled: true,
    getUserSearchQueryPreprocessed: (value: string) => {
      return value ? `/${value}/` : undefined;
    },
  },
  [AggregationType.HAS_DUPLICATES]: {
    type: BigidAdvancedToolbarFilterTypes.DROPDOWN,
    id: AggregationType.HAS_DUPLICATES,
    field: AggregationFilterOperand.HAS_DUPLICATES,
    operator: 'equal',
    options: [],
    disabled: true,
    isSearchable: false,
    isBoolean: true,
  },
  [AggregationType.OBJECT_SCAN_STATUS]: {
    type: BigidAdvancedToolbarFilterTypes.DROPDOWN,
    id: AggregationType.OBJECT_SCAN_STATUS,
    field: AggregationFilterOperand.OBJECT_SCAN_STATUS,
    operator: 'in',
    options: [],
    disabled: false,
    isSearchable: false,
  },
  [AggregationType.VIOLATED_POLICY]: {
    type: BigidAdvancedToolbarFilterTypes.DROPDOWN,
    id: AggregationType.VIOLATED_POLICY,
    field: AggregationFilterOperand.POLICY,
    operator: 'in',
    options: [],
    optionsDisplayLimit: fetchAllFilterOptionsLimit,
    isSearchable: false,
    disabled: true,
  },
  [NonAggregationType.SCAN_DATE]: {
    type: BigidAdvancedToolbarFilterTypes.DATE_RANGE,
    id: NonAggregationType.SCAN_DATE,
    field: AggregationFilterOperand.SCAN_DATE,
    operator: 'equal',
    shouldNotReloadOnChange: true,
    options: {
      currentRangeOptionSelected: 'none',
      pickersState: {
        dates: {
          from: null,
          until: null,
        },
        currentMode: 'from',
      },
    },
    disabled: true,
    parseFilterToQueryFunc: parseDateFilterOutputToQueryString,
  },
  [NonAggregationType.LAST_ACCESS_DATE]: {
    type: BigidAdvancedToolbarFilterTypes.DATE_RANGE,
    id: NonAggregationType.LAST_ACCESS_DATE,
    field: AggregationFilterOperand.LAST_ACCESS_DATE,
    operator: 'equal',
    shouldNotReloadOnChange: true,
    options: {
      currentRangeOptionSelected: 'none',
      pickersState: {
        dates: {
          from: null,
          until: null,
        },
        currentMode: 'from',
      },
    },
    disabled: true,
    parseFilterToQueryFunc: parseDateFilterOutputToQueryString,
  },
  [NonAggregationType.MODIFIED_DATE]: {
    type: BigidAdvancedToolbarFilterTypes.DATE_RANGE,
    id: NonAggregationType.MODIFIED_DATE,
    field: AggregationFilterOperand.MODIFIED_DATE,
    operator: 'equal',
    shouldNotReloadOnChange: true,
    options: {
      currentRangeOptionSelected: 'none',
      pickersState: {
        dates: {
          from: null,
          until: null,
        },
        currentMode: 'from',
      },
    },
    disabled: true,
    parseFilterToQueryFunc: parseDateFilterOutputToQueryString,
  },
  [AggregationType.CONTAINER_NAME]: {
    type: BigidAdvancedToolbarFilterTypes.DROPDOWN,
    id: AggregationType.CONTAINER_NAME,
    field: AggregationFilterOperand.CONTAINER_NAME,
    operator: 'in',
    options: [],
    disabled: true,
  },
  [AggregationType.RISK]: {
    type: BigidAdvancedToolbarFilterTypes.DROPDOWN,
    id: AggregationType.RISK,
    field: AggregationFilterOperand.RISK,
    operator: 'in',
    options: [],
    disabled: true,
    isSearchable: false,
    getFilterOptionsPreprocessed: capitaliseFilterOptionDisplayValue,
  },
  [AggregationType.ACCESS_TYPE_FILTER]: {
    type: BigidAdvancedToolbarFilterTypes.DROPDOWN,
    id: AggregationType.ACCESS_TYPE_FILTER,
    field: AggregationFilterOperand.ACCESS_TYPE_FILTER,
    operator: 'in',
    options: [],
    disabled: true,
    isSearchable: false,
    leftOperandComposerFunc: getTagsLeftOperand,
  },
};

const mapQuerySerialisationOperator: { [operator in BigidFieldFilterOperator]?: Operators } = {
  in: Operators.IN,
  equal: Operators.EQUAL,
};

export function getAggregationBooleanItemLabel(
  aggregationType: AggregationType,
  aggregationItemName: AggregationItemName,
): string {
  const t = getFixedT('filters');

  switch (aggregationItemName) {
    case AggregationPossesionIdentifier.WITH:
      return t(`${mapAggregationToFilterTranslationKey[aggregationType]}.values.yes`);
    case AggregationPossesionIdentifier.WITHOUT:
      return t(`${mapAggregationToFilterTranslationKey[aggregationType]}.values.no`);
    default:
      return aggregationItemName;
  }
}

export function parseObjectifiedQueryToString(filter: RulesStructNode): string {
  return filter?.rules.length > 0 ? parseGraphQueryNodes(filter) : '';
}

export function getIsBooleanNegationOperator({ aggItemName }: AggregationItemBase): boolean {
  return aggItemName === AggregationPossesionIdentifier.WITHOUT;
}

export function getObjectifiedQueryOperator(filter: BigidAdvancedToolbarDropdownFilter): Operators {
  const { operator } = filter;

  return mapQuerySerialisationOperator[operator] ?? Operators.IN;
}

export function getObjectifiedQueryLeftOperand(filter: BigidAdvancedToolbarDropdownFilter): string {
  const { id, field } = filter;
  const { leftOperandComposerFunc } = aggregationFilterConfig[id as AggregationType];

  return leftOperandComposerFunc ? leftOperandComposerFunc(filter) : field;
}

export function getObjectifiedQueryRightOperand(options: BigidDropdownOption[]): string | string[] {
  return options.map(({ value }) => {
    const { aggItemName } = value as AggregationItemBase;
    return aggItemName.toString();
  });
}

export function getAggregationBooleanItemQueryValue(): string {
  return 'true'; //NOTE: at this moment query language supports only "true"
}

export function getDropdownFilterQueryNodeWithNestedItems(
  parentFilterId: string,
  filter: BigidAdvancedToolbarDropdownFilter,
): RulesStructNode {
  const { id, options } = filter;

  return {
    id: id.toString(),
    parentId: parentFilterId,
    operator: Operators.OR,
    rules: Object.entries(groupBy(options, ({ parentId }) => parentId)).map(([id, options]) => {
      return {
        id,
        leftOperand: getObjectifiedQueryLeftOperand({ ...filter, options }),
        rightOperand: getObjectifiedQueryRightOperand(options),
        operator: getObjectifiedQueryOperator(filter),
      };
    }),
  };
}

export function getDropdownFilterQueryNodeWithFlatItems(
  parentFilterId: string,
  filter: BigidAdvancedToolbarDropdownFilter,
): RulesStructNode {
  const { id, options } = filter;

  return {
    id: id.toString(),
    parentId: parentFilterId,
    leftOperand: getObjectifiedQueryLeftOperand(filter),
    rightOperand: getObjectifiedQueryRightOperand(options),
    operator: getObjectifiedQueryOperator(filter),
  };
}

export function getDropdownFilterQueryNodeWithBooleanItems(
  parentFilterId: string,
  filter: BigidAdvancedToolbarDropdownFilter,
): RulesStructNode {
  const { id, options } = filter;

  return {
    id: id.toString(),
    operator: Operators.OR,
    parentId: parentFilterId,
    rules: options.map(option => {
      const { value } = option;

      return {
        id: id.toString(),
        parentId: parentFilterId,
        leftOperand: getObjectifiedQueryLeftOperand(filter),
        rightOperand: getAggregationBooleanItemQueryValue(),
        isNegation: getIsBooleanNegationOperator(value),
        operator: getObjectifiedQueryOperator(filter),
      };
    }),
  };
}

export function parseDropdownFilterToObjectifiedQueryNode(
  parentFilterId: string,
  filter: BigidAdvancedToolbarDropdownFilter,
): RulesStructNode {
  const { hasNestedItems, isBoolean } = aggregationFilterConfig[filter.id as AggregationType];

  if (hasNestedItems) {
    return getDropdownFilterQueryNodeWithNestedItems(parentFilterId, filter);
  } else {
    if (isBoolean) {
      return getDropdownFilterQueryNodeWithBooleanItems(parentFilterId, filter);
    } else {
      return getDropdownFilterQueryNodeWithFlatItems(parentFilterId, filter);
    }
  }
}

export function parseRegularFilterOutputToObjectifiedQuery(filter: BigidAdvancedToolbarFilterUnion[]): RulesStructNode {
  const rootNodeId = uuid();

  return filter.reduce(
    (objectifiedQuery, filter) => {
      //NOTE: extend with any further parseable filter preprocessor, especially with DATA_RANGE once it's parseable
      switch (filter.type) {
        case BigidAdvancedToolbarFilterTypes.DROPDOWN:
          return {
            ...objectifiedQuery,
            rules: [...objectifiedQuery.rules, parseDropdownFilterToObjectifiedQueryNode(rootNodeId, filter)],
          };
      }
    },
    {
      id: rootNodeId,
      operator: Operators.AND,
      rules: [],
    },
  );
}

export function parseRegularFilterOutputToQueryString(filter: BigidAdvancedToolbarFilterUnion[]): string {
  const objectifiedQuery = parseRegularFilterOutputToObjectifiedQuery(filter);
  return parseObjectifiedQueryToString(objectifiedQuery);
}

export function parseCatalogFilterOutputToQueryString(filter: BigidAdvancedToolbarFilterUnion[]): string {
  let query = '';

  if (filter?.length > 0) {
    const [specialFilters, regularFilters] = partition(filter, ({ id }) =>
      Boolean(aggregationFilterConfig[id as AggregationType].parseFilterToQueryFunc),
    ); //NOTE: once query parser can parse dates consider special vs parseable partition with swith/case DROPDOWN/DATE_RANGE afterwards

    const stringifiedDropdownFilters = parseRegularFilterOutputToQueryString(regularFilters);
    const stringifiedSpecialFilters = specialFilters.map(filter => {
      const { parseFilterToQueryFunc } = aggregationFilterConfig[filter.id as AggregationType];
      return parseFilterToQueryFunc([filter]);
    });

    query = [stringifiedDropdownFilters, ...stringifiedSpecialFilters]
      .filter(queryChunk => typeof queryChunk === 'string' && queryChunk.length > 0)
      .join(' AND ');
  }

  return query;
}

export function clearOutEmptyFilters(filter: BigidAdvancedToolbarFilterUnion[]): BigidAdvancedToolbarFilterUnion[] {
  return filter?.filter(filter => {
    switch (filter.type) {
      case BigidAdvancedToolbarFilterTypes.DROPDOWN: {
        const { options } = filter as BigidAdvancedToolbarDropdownFilter;
        return options.length > 0;
      }
      case BigidAdvancedToolbarFilterTypes.DATE_RANGE: {
        const { options } = filter as BigidAdvancedToolbarDateRangeFilter;
        return options.pickersState.dates.from && options.pickersState.dates.until;
      }
      default:
        return true;
    }
  });
}

export function getFilterOptionsFetchMethodPayload({
  aggName,
  filter,
  filterOperand,
  value,
  shouldFetchEverything,
  searchText,
}: GetFilterOptionsFetchMethodPayload): GetAggregatedDataPayload {
  const aggregation: Partial<GetAggredatedDataRequestObject> = {
    aggName,
    sorting: [
      {
        field: 'docCount',
        order: 'DESC',
      },
    ],
  };

  if (shouldFetchEverything) {
    aggregation.paging = {
      limit: fetchAllFilterOptionsLimit,
      skip: 0,
    };
  }

  let payloadBase: GetAggregatedDataPayload = {
    aggregations: [aggregation],
  };

  if (searchText?.trim().length >= searchTextMinLength) {
    payloadBase.searchText = searchText;
  }

  //NOTE: excluding the given filter when user performs an inner search
  const stringifiedQuery = parseCatalogFilterOutputToQueryString(
    value ? filter.filter(({ id }) => id !== aggName) : filter,
  );
  const payloadFilter = [stringifiedQuery, value ? `${filterOperand} = "${value}"` : null]
    .filter(expression => typeof expression === 'string' && expression.length > 0)
    .join(' AND ');

  if (payloadFilter.length > 0) {
    payloadBase = {
      ...payloadBase,
      filter: payloadFilter,
    };
  }

  return payloadBase;
}

export function getFilterByOperand(
  filters: BigidAdvancedToolbarFilterUnion[],
  operand: AggregationFilterOperand,
): BigidAdvancedToolbarFilterUnion {
  return filters?.find(({ field }) => field === operand);
}

export function getAppliedFilterValues(filter: BigidAdvancedToolbarFilterUnion[]): AdvancedToolbarOverrideValue[] {
  return filter.map(({ id, type, options }) => ({ id, type, options }));
}
//TODO: I need a special function for Categories widget with aggItemGroup only
export function getWidgetAppliedFilter(
  filterConfig: BigidAdvancedToolbarFilterUnion,
  items: AggregationItemBase[],
  shouldUndo?: boolean,
): AdvancedToolbarOverrideValue {
  return {
    ...pick(filterConfig, ['id', 'type']),
    options: shouldUndo
      ? []
      : items.map(item => {
          const { aggItemName, docCount = 0 } = item;

          return {
            id: getNestedFilterItemId(item), //NOTE: reconsider using uuid once we have tags on the page
            displayValue: aggItemName,
            value: item,
            annotation: String(docCount),
            isSelected: true,
          };
        }),
  };
}

export function capitaliseFilterOptionDisplayValue(options: BigidDropdownOption[]): BigidDropdownOption[] {
  return options.map(option => {
    return { ...option, displayValue: capitalize(option.displayValue) };
  });
}

export function parseAggregationBooleanItemsToFilterOptions(aggregation: Aggregation): BigidDropdownOption[] {
  const { aggData = [], aggName } = aggregation;

  return [AggregationPossesionIdentifier.WITH, AggregationPossesionIdentifier.WITHOUT].reduce((options, option) => {
    const aggItem = aggData.find(({ aggItemName }) => aggItemName === option);

    if (aggItem) {
      const { aggItemName, docCount } = aggItem;

      return [
        ...options,
        {
          id: aggItemName,
          value: aggItem,
          displayValue: getAggregationBooleanItemLabel(aggName, aggItemName),
          annotation:
            !isNaN(docCount) && docCount !== aggregationIrrelevantNumericFieldIdentifier
              ? String(formatNumberCompact(docCount))
              : undefined,
        },
      ];
    } else {
      return options;
    }
  }, []);
}

export function parseAggregationItemsToFilterOptions(
  aggregation: Aggregation,
  filterOptionsPreprocessorFunc?: FetchFilterOptionsPreprocessorFunc,
): BigidDropdownOption[] {
  const { aggData } = aggregation;
  const options = (aggData ?? []).map(aggItem => {
    const { aggItemName, docCount } = aggItem;

    return {
      id: aggItemName,
      value: aggItem,
      displayValue: aggItemName,
      annotation:
        !isNaN(docCount) && docCount !== aggregationIrrelevantNumericFieldIdentifier
          ? String(formatNumberCompact(docCount))
          : undefined,
    };
  });

  return filterOptionsPreprocessorFunc ? filterOptionsPreprocessorFunc(options) : options;
}

export async function fetchFilterOptions(
  payload: GetAggregatedDataPayload,
  aggItemsTransformerFunc: FetchFilterOptionsTransformerFunc,
  aggItemsPreprocessorFunc?: FetchFilterOptionsPreprocessorFunc,
): Promise<BigidDropdownOption[]> {
  const { aggregations } = await getAggregatedData(payload);
  if (!aggregations?.[0]) {
    return [];
  }

  return aggItemsTransformerFunc(aggregations?.[0], aggItemsPreprocessorFunc);
}

export function getTagsLeftOperand(filter: BigidAdvancedToolbarFilterUnion): string {
  const { field, options } = filter as BigidAdvancedToolbarDropdownFilter;
  const { aggItemGroup } = options[0].value;

  return `${field}.${aggItemGroup}`;
}

export function getSensitivityClassificationLeftOperand(filter: BigidAdvancedToolbarFilterUnion): string {
  const { field, options } = filter as BigidAdvancedToolbarDropdownFilter;
  const { aggItemGroup } = options[0].value;

  return `${field}.${aggItemGroup}`;
}

export const nestedFilterOptionIdDelimeter = '@#&';

export function getNestedFilterItemId(aggItem: AggregationItemBase) {
  const { aggItemName, aggItemGroup } = aggItem;

  return aggItemGroup ? `${aggItemGroup}${nestedFilterOptionIdDelimeter}${aggItemName}` : aggItemName;
}

export function parseAggregationNestedItemsToFilterOptions(aggregation: Aggregation): BigidDropdownOption[] {
  const { aggData = [] } = aggregation;

  const optionsBase: BigidDropdownOption[] = uniqBy(
    aggData.map(({ aggItemGroup, groupDocCount }) => ({
      id: aggItemGroup,
      value: aggItemGroup,
      displayValue: aggItemGroup,
      annotation:
        !isNaN(groupDocCount) && groupDocCount !== aggregationIrrelevantNumericFieldIdentifier
          ? String(formatNumberCompact(groupDocCount))
          : undefined,
      children: [],
    })),
    ({ id }) => id,
  );

  return aggData.reduce((options, aggItem) => {
    const { aggItemName, aggItemGroup, docCount } = aggItem;

    return options.map(option => {
      if (option.id === aggItemGroup) {
        return {
          ...option,
          children: [
            ...option.children,
            {
              id: getNestedFilterItemId(aggItem),
              parentId: aggItemGroup,
              value: aggItem,
              displayValue: aggItemName,
              annotation:
                !isNaN(docCount) && docCount !== aggregationIrrelevantNumericFieldIdentifier
                  ? String(formatNumberCompact(docCount))
                  : undefined,
            },
          ],
        };
      } else {
        return option;
      }
    });
  }, optionsBase);
}
