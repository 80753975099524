import { useEffect, useMemo, useState } from 'react';
import {
  BigidDropdownOption,
  BigidFormField,
  BigidFormFieldLabelPosition,
  BigidFormFieldTypes,
} from '@bigid-ui/components';
import { useLocalTranslation } from './translations';
import { getSystemUsersListOptions } from './assignCollaboratorService';

export interface UseAssignCollaboratorFormConfigReturnData {
  fields: BigidFormField[];
  isFormInitialised: boolean;
}

export function useAssignCollaboratorFormConfig(source: string): UseAssignCollaboratorFormConfigReturnData {
  const { t } = useLocalTranslation('AssignCollaboratorForm');

  const [isFormInitialised, setIsFormInitialised] = useState<boolean>(false);
  const [systemUserOptions, setSystemUserOptions] = useState<BigidDropdownOption[]>([]);

  useEffect(() => {
    async function initFormAssets() {
      setSystemUserOptions(await getSystemUsersListOptions(source));
      setIsFormInitialised(true);
    }

    initFormAssets();
  }, [source]);

  const { fields } = useMemo(() => {
    const fields: BigidFormField[] = [
      {
        type: BigidFormFieldTypes.DROP_DOWN,
        labelPosition: BigidFormFieldLabelPosition.top,
        name: 'email',
        label: t('fields.email.label'),
        dropDownOptions: systemUserOptions,
        misc: {
          fullWidth: true,
        },
        fieldProps: {
          isSearchable: true,
          placeholder: t('fields.email.placeholder'),
        },
        isRequired: true,
        validate: (value: BigidDropdownOption[]) => {
          if (value?.length > 0) {
            return false;
          } else {
            return t('fields.email.validation.empty');
          }
        },
      },
    ];

    return { fields };
  }, [systemUserOptions, t]);

  return { fields, isFormInitialised };
}
