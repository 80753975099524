import React, { FC, useContext, useEffect } from 'react';
import { userPreferencesService } from '../../../../services/userPreferencesService';
import { $state } from '../../../../services/angularServices';
import { BigidGuidedTour, BigidGuidedTourProps } from '@bigid-ui/guided-tour';
import { getStepsByName } from '../../GuidedTour/guidedTourSteps';
import { GuidedTourType } from '../../GuidedTour/stepTypes';
import { GuidedTourContext } from '../../ScanTemplates/GuidedTour/guidedTourContext';
import { getApplicationPreference } from '../../../../services/appPreferencesService';

export const PlannedScansGuidedTour: FC = () => {
  const { manualStart, setManualStart } = useContext(GuidedTourContext);

  const handleUserPreferenceManualStart = async () => {
    const scanInsightUserPreference = await userPreferencesService.get($state.$current.name);
    const isShowGuidedTour = getApplicationPreference('SHOW_GUIDED_TOUR_SAVED_SCANS');
    if (!scanInsightUserPreference?.data?.isPlannedScansGuidedTour && isShowGuidedTour) {
      setManualStart(true);
    }
  };

  const joyrideCallback = async () => {
    setManualStart(false);
    const scanTemplateUserPreference = await userPreferencesService.get($state.$current.name);
    if (!scanTemplateUserPreference?.data?.isPlannedScansGuidedTour) {
      await userPreferencesService.update({
        preference: $state.$current.name,
        data: { ...(scanTemplateUserPreference?.data || {}), isPlannedScansGuidedTour: true },
      });
    }
  };

  useEffect(() => {
    handleUserPreferenceManualStart();
  }, []);

  const BigidGuidedTourContent: BigidGuidedTourProps = {
    delay: 100,
    disableCloseOnEsc: false,
    disableOverlay: false,
    disableOverlayClose: true,
    disableScrolling: true,
    hideCloseButton: false,
    disableScrollParentFix: true,
    steps: getStepsByName(GuidedTourType.plannedScans),
  };

  return (
    <div data-tour="start">
      <BigidGuidedTour joyrideCallback={joyrideCallback} run={manualStart} {...BigidGuidedTourContent} />
    </div>
  );
};
