import React, { FC } from 'react';
import { styled } from '@mui/material';
import { CurationDefaultViewSwitcher, CurationDefaultViewSwitcherProps } from '../CurationDefaultViewSwitcher';
import { ReviewedEntitiesProgress, ReviewedEntitiesProgressProps } from '../ReviewedEntitiesProgress';

export type CuratedAttributeGridForeignElementProps = Pick<CurationDefaultViewSwitcherProps, 'stageId' | 'onSelect'> &
  Pick<ReviewedEntitiesProgressProps, 'curatedEntityName' | 'curationStatus' | 'translation'> & {
    hasSource?: boolean;
  };

const Root = styled('div')`
  display: flex;
  gap: 24px;
`;

export const CuratedAttributeGridForeignElement: FC<CuratedAttributeGridForeignElementProps> = ({
  stageId,
  onSelect,
  curatedEntityName,
  curationStatus,
  translation,
  hasSource,
}) => {
  return (
    <Root>
      {curationStatus && (
        <ReviewedEntitiesProgress
          curationStatus={curationStatus}
          curatedEntityName={curatedEntityName}
          translation={translation}
        />
      )}
      {!hasSource && <CurationDefaultViewSwitcher stageId={stageId} onSelect={onSelect} />}
    </Root>
  );
};
