import React, { FC, useEffect, useState } from 'react';
import {
  BigidHeading6,
  BigidButtonIcon,
  BigidLink,
  BigidNotes1,
  BigidPaper,
  BigidTooltip,
  LinkColor,
} from '@bigid-ui/components';
import { BigidCopyIcon, BigidDataSearchIcon } from '@bigid-ui/icons';
import makeStyles from '@mui/styles/makeStyles';
import { ScanProfileInfo } from '../ScanInsightTypes';
import { copyToClipboard, NOT_APPLICABLE, getScanTypeLabel, handleScanTemplateMoreDetailsClick } from '../../ScanUtils';
import { startCase, isEmpty } from 'lodash';
import { dateTimeService } from '@bigid-ui/i18n';
import { CompletedParentScanState, ProgressType, ScansGridRow, ScanUpdateSSE, SSEType } from '../../ScanTypes';
import { $state } from '../../../../services/angularServices';
import { CONFIG } from '../../../../../config/common';
import { ScansUITrackingEvent, trackEventScansView } from '../../ScansEventTrackerUtils';
import { subscribeToRepeatedSSEEventById } from '../../../../services/sseService';
import { notificationService } from '../../../../services/notificationService';
import { getApplicationPreference } from '../../../../services/appPreferencesService';
import { getTemplateById } from '../../ScanTemplates/scanTemplatesService';

enum ScanProfileInfoEnum {
  SCAN_ID = 'Scan ID',
  TYPE = 'Type',
  COMPLETE_RATE = 'Complete Rate',
  SUCCESS_RATE = 'Success Rate',
  DURATION = 'Running Time',
  START_DATE = 'Start Date',
  COMPLETED_DATE = 'Completed Time',
  PREVIOUS_SCAN_ENDED = 'Previous Scan Ended at',
  NEXT_RUN_TIME = 'Next Run Time at',
  DESCRIPTION = 'Description',
  SCAN_PARTS_REPORT = 'Scan Parts Report',
}

const useStyles = makeStyles({
  row: {
    marginLeft: '24px',
    marginRight: '24px',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginBottom: '24px',
  },
  title: {
    marginTop: '25px',
    marginLeft: '24px',
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
  },
  content: {
    alignContent: 'space-between',
    display: 'flex',
    minWidth: '25%',
    padding: '10px 10px 8px 10px',
    flexDirection: 'column',
    marginTop: '32px',
    gap: '8px',
  },
  item: {
    width: '100%',
  },
  progressBar: {
    flexDirection: 'column',
    width: '50%',
    alignContent: 'space-between',
    alignSelf: 'center',
    display: 'flex',
    marginTop: '32px',
    gap: '8px',
  },
  description: {
    flexDirection: 'column',
    gap: '8px',
    padding: '10px 10px 8px 10px',
    display: 'flex',
    minWidth: '75%',
    flexWrap: 'wrap',
    marginTop: '32px',
  },
  flex: {
    display: 'flex',
  },
});

export const ScanProfileInfoComponent: FC<ScanProfileInfo> = ({
  previousScanCompleted,
  type,
  startedAt,
  completedAt,
  _id,
  scanProfileId,
  description,
  nextScheduledRun,
  isActive,
  progress = {},
  runningTime,
  scanTemplateId,
  scanTemplateName,
  name,
}) => {
  const classes = useStyles({});
  const [scanProgress, setScanProgress] = useState<ProgressType>(progress as ProgressType);
  const isScanTemplatesEnabled = getApplicationPreference('ENABLE_SCAN_TEMPLATES');
  const hasScanTemplate = isScanTemplatesEnabled && scanTemplateId !== NOT_APPLICABLE;

  useEffect(() => {
    const unregister = subscribeToRepeatedSSEEventById<ScanUpdateSSE>(SSEType.PARENT, data => {
      const { results } = data;
      const result = results.find(({ id }) => id === _id);
      if (result?.payload != null) {
        const { progress: updatedProgress } = result.payload as ScansGridRow;
        setScanProgress(updatedProgress);
      }
    });
    return () => unregister();
  }, [_id]);

  const { state, complete, failed, total, isFailedObjects } = scanProgress;

  const copyClipboardHandler = (id: string) => {
    copyToClipboard(id);
    notificationService.info('Scan id copied to clipboard', {
      duration: 3000,
    });
  };

  const scanProfileContent = [
    {
      title: ScanProfileInfoEnum.SCAN_ID,
      info: _id,
      icon: () => (
        <BigidTooltip title={'Copy to clipboard'} enterDelay={500} placement="bottom">
          <span>
            <BigidButtonIcon icon={BigidCopyIcon} size="small" onClick={() => copyClipboardHandler(_id)} />{' '}
          </span>
        </BigidTooltip>
      ),
    },
    {
      title: ScanProfileInfoEnum.TYPE,
      info: getScanTypeLabel(type),
    },
    {
      title: ScanProfileInfoEnum.DURATION,
      info: runningTime,
    },
    {
      title: ScanProfileInfoEnum.START_DATE,
      info: dateTimeService.formatDate(startedAt),
    },
    {
      title: ScanProfileInfoEnum.COMPLETED_DATE,
      info: Object.values(CompletedParentScanState).includes(state as CompletedParentScanState)
        ? dateTimeService.formatDate(completedAt)
        : `Scan ${startCase(state)}`,
    },
    {
      title: ScanProfileInfoEnum.PREVIOUS_SCAN_ENDED,
      info: previousScanCompleted ? dateTimeService.formatDate(previousScanCompleted) : NOT_APPLICABLE,
    },
    {
      title: ScanProfileInfoEnum.NEXT_RUN_TIME,
      info: nextScheduledRun
        ? isActive
          ? dateTimeService.formatDate(nextScheduledRun)
          : `${dateTimeService.formatDate(nextScheduledRun)} - Disabled`
        : 'None',
    },
    {
      title: ScanProfileInfoEnum.DESCRIPTION,
      info: isEmpty(description) ? 'None' : description,
      isDescription: true,
    },
  ];
  return (
    <BigidPaper>
      <div className={classes.title}>
        <BigidDataSearchIcon />
        <BigidHeading6 data-aid={'scan-section-title'} color={'secondary'}>
          {`${hasScanTemplate ? 'Scan Template' : 'Scan Profile'} |`}{' '}
        </BigidHeading6>
        <BigidLink
          onClick={async () => {
            trackEventScansView(ScansUITrackingEvent.EDIT_SCAN_PROFILE_CLICK);
            if (hasScanTemplate) {
              try {
                const scanTemplate = await getTemplateById(scanTemplateId, true);
                handleScanTemplateMoreDetailsClick({ ...scanTemplate, shouldDisplayWarning: true }, 'Scan Template');
              } catch (e) {
                notificationService.error('This template was deleted.');
                console.error(e);
              }
            } else {
              $state.go(CONFIG.states.EDIT_SCAN_PROFILE, {
                id: scanProfileId,
                ...(isScanTemplatesEnabled && { isHideSave: true }),
                name,
              });
            }
          }}
          text={`${hasScanTemplate ? 'View Template' : 'View Profile'}`}
          color={LinkColor.BREADCRUMBS_TOPIC}
        />
      </div>
      <div data-aid={'scanProfileInfo'} className={classes.row}>
        {scanProfileContent.map((content, index) => {
          const Icon = content.icon;

          return (
            <div key={index} className={content.isDescription ? classes.description : classes.content}>
              <BigidNotes1 className={classes.item}>{content.title}</BigidNotes1>
              <div className={classes.flex}>
                <BigidHeading6
                  data-aid={`scanProfileContent-${content.title}`}
                  color={'secondary'}
                  className={classes.item}
                >
                  {content.info ?? NOT_APPLICABLE}
                </BigidHeading6>
                {Icon && <Icon />}
              </div>
            </div>
          );
        })}
      </div>
    </BigidPaper>
  );
};
