import React, { FC, useContext, useMemo } from 'react';
import { BigidFieldRenderProps, BigidFormField, StringOrBooleanMap } from '@bigid-ui/components';
import { SIDE_SCAN_ENABLE_FIELD_NAME } from '../../../constants/sideScanFields';
import { styled } from '@mui/material';
import { AutoDiscoveryWizardContext } from '../../autoDiscoveryWizardContext';
import { fieldConditionCheck } from '../../../../DataSources/DataSourceConfiguration/utils/conditionUtils';
export const SideScanEnableFieldWrapper = styled('div')`
  padding: 4px;
`;

export interface AutoDiscoveryWizardSideScanningSectionProps {
  values?: Record<string, any>;
  visibleFields?: string[];
  fields: BigidFormField[];
  getFieldProps: (fieldName: string) => BigidFieldRenderProps<any, any>;
  renderField: (fieldName: string, additionalProps?: Record<string, any>) => any;
  errors: StringOrBooleanMap;
}

export const AutoDiscoveryWizardSideScanningSection: FC<AutoDiscoveryWizardSideScanningSectionProps> = ({
  fields,
  renderField,
  errors,
  values,
  getFieldProps,
}) => {
  const { discoveryConfigData } = useContext(AutoDiscoveryWizardContext);
  const subFields = useMemo(
    () =>
      discoveryConfigData.isEdit &&
      fields
        .filter(({ name }) => name !== SIDE_SCAN_ENABLE_FIELD_NAME)
        .map(({ name, misc: { visibleIf } }) => {
          const isVisible = fieldConditionCheck(false, visibleIf, field => ({
            value: values[field],
            type: getFieldProps(field).misc?.typeForConditionCheck,
          }));
          return (
            isVisible &&
            renderField(name, {
              error: errors?.[name],
              errorIsShown: !!errors?.[name],
            })
          );
        }),
    [errors, fields, getFieldProps, renderField, values?.[SIDE_SCAN_ENABLE_FIELD_NAME]],
  );
  const showSubFields = String(values[SIDE_SCAN_ENABLE_FIELD_NAME]) === 'true' && !!subFields?.length;
  return (
    !!fields.length && (
      <>
        <SideScanEnableFieldWrapper>
          {renderField(SIDE_SCAN_ENABLE_FIELD_NAME, {
            error: errors?.[SIDE_SCAN_ENABLE_FIELD_NAME],
            errorIsShown: !!errors?.[SIDE_SCAN_ENABLE_FIELD_NAME],
          })}
          {showSubFields && subFields}
        </SideScanEnableFieldWrapper>
      </>
    )
  );
};
