import './identity-lineage.component.scss';
import angular from 'angular';
import template from './identity-lineage.component.html';
import { uniq } from 'lodash';
import { lazyLoadGoJs } from './identity-lineage-graph/identity-lineage-graph.component';
import { convertToReact } from '../common/services/convertToReact';

angular.module('app').component('identityLineage', {
  template,
  controller: [
    '$rootScope',
    '$translate',
    '$scope',
    '$timeout',
    'identityLineageService',
    'notificationService',
    '$state',
    'localStorageService',
    function (
      $rootScope,
      $translate,
      $scope,
      $timeout,
      identityLineageService,
      notificationService,
      $state,
      localStorageService,
    ) {
      const ctrl = this;
      ctrl.showAlertMessage = false;
      ctrl.showAttributeSideBar = true;
      ctrl.triggerGraphUpdate = false;
      ctrl.categoriesLoading = false;

      ctrl.$onInit = () => {
        // load go.js in parent component
        lazyLoadGoJs().then(() => {
          ctrl.goJsLoaded = true;
          $scope.$evalAsync();
        });

        // if we got the scans status in the past, so we don't need to show the message
        if (localStorageService.get('showInitializeErrorMessage') === false) {
          ctrl.showAlertMessage = false;
        }
        // go to the server and check the status scan
        else {
          identityLineageService.scansStatus().then(result => {
            if (result.data) {
              ctrl.showAlertMessage = true;
              ctrl.message = result.data.message;
              ctrl.urlRef = result.data.urlRef;
              ctrl.urlMessage = result.data.urlMessage;
              ctrl.type = result.data.type;
            }
          });
        }
        ctrl.isNodeSelected = false;

        $translate('ENTITY_CORRELATION').then(function (translation) {
          $rootScope.$broadcast('changePage', translation, false);
        });

        ctrl.closeMessageAlert = () => {
          if (!localStorageService.get('showInitializeErrorMessage')) {
            localStorageService.set('showInitializeErrorMessage', false);
            ctrl.showAlertMessage = false;
          }
        };

        ctrl.onAttributeClicked = data => {
          const {
            type,
            original_name = '',
            isTheHeaderOfGroup = false,
            physicalNames = [],
            friendly_name,
          } = data.attribute;

          ctrl.categoriesLoading = true;
          identityLineageService
            .getLineageAttributeCollections(isTheHeaderOfGroup ? friendly_name || original_name : original_name)
            .then(({ data }) => {
              const collections = uniq(
                data[0]?.collectionsInfo.reduce(
                  (collectionsAggr, attributeCollections) => [
                    ...collectionsAggr,
                    ...(attributeCollections?.collections || []),
                  ],
                  [],
                ),
              );
              ctrl.isNodeSelected = true;
              ctrl.nodesData = [];
              ctrl.linksData = [];
              ctrl.nodesData = [
                {
                  _id: 1,
                  type: 'attribute',
                  category: this.getAttributeType(type),
                  originType: type,
                  original_name,
                  isTheHeaderOfGroup,
                  physicalNames,
                  title: friendly_name ?? original_name,
                  collections,
                },
              ];

              ctrl.data = {
                columnData: null,
                nodesData: ctrl.nodesData,
                linksData: ctrl.linksData,
              };
            })
            .finally(() => {
              ctrl.categoriesLoading = false;
            })
            .catch(error => {
              window.console.error(`An error has occurred while fetching collections: ${error}`);
            });
        };

        ctrl.onTableActions = (action, data) => {
          $scope.$evalAsync(() => {
            switch (action) {
              case 'selectTable':
                if (data.nodesData && data.linksData) {
                  this.nodesData = angular.copy(data.nodesData);
                  this.linksData = angular.copy(data.linksData);
                  ctrl.lineageTree = data.lineageTree;
                } else {
                  ctrl.nodesData = [
                    {
                      _id: 1,
                      type: 'attribute',
                      category: this.getAttributeType(data.attributeType),
                      originType: data.attributeType,
                      original_name:
                        ctrl.data.nodesData && ctrl.data.nodesData[0] && ctrl.data.nodesData[0].original_name
                          ? ctrl.data.nodesData[0].original_name
                          : '',
                      isTheHeaderOfGroup:
                        ctrl.data.nodesData && ctrl.data.nodesData[0] && ctrl.data.nodesData[0].isTheHeaderOfGroup
                          ? ctrl.data.nodesData[0].isTheHeaderOfGroup
                          : false,
                      physicalNames:
                        ctrl.data.nodesData && ctrl.data.nodesData[0] && ctrl.data.nodesData[0].physicalNames
                          ? ctrl.data.nodesData[0].physicalNames
                          : [],
                      title: data.attributeName,
                    },
                  ];
                }
                break;
              case 'createIdConnection':
                identityLineageService
                  .createIdConnection({
                    fullyQualifiedTableNames: data,
                  })
                  .then(
                    data => {
                      if (data['status'] !== 'error') {
                        notificationService.success(
                          data['unsupportedTypes'] > 0
                            ? 'Inserted ' +
                                data['numberOfIdConnectionsCreated'] +
                                ' identity connections. ' +
                                data['unsupportedTypes'] +
                                ' tables with unsupported type'
                            : 'Inserted ' + data['numberOfIdConnectionsCreated'] + ' identity connections.',
                        );

                        $timeout(() => {
                          $state.go('entitySources');
                        }, 2000);
                      } else {
                        notificationService.error(
                          'An error has occurred during ID connection creating: ' + data['errorMessage'],
                        );
                      }
                    },
                    () => {
                      notificationService.error('An error has occurred during ID connection creating!');
                    },
                  );
                break;
            }
          });
        };

        ctrl.onGraphAction = (action, data, column) => {
          this.column = column;
          this.data = data;
          $scope.$evalAsync(() => {
            switch (action) {
              case 'openSelectTable':
                ctrl.isNodeSelected = true;
                ctrl.columnData = null;
                ctrl.data = {
                  columnData: null,
                  nodesData: ctrl.nodesData,
                  linksData: ctrl.linksData,
                };
                break;
              case 'openSelectTableByColumn':
                ctrl.data = {
                  columnData: this.data,
                  nodesData: ctrl.nodesData,
                  linksData: ctrl.linksData,
                };
                ctrl.attributeData = null;
                ctrl.isNodeSelected = true;
                break;
              default:
                break;
            }
          });
        };

        ctrl.getAttributeType = type => {
          let typeSelected = '';
          switch (type) {
            case 'idsor_attributes':
              typeSelected = 'attribute';
              break;
            case 'classifications':
              typeSelected = 'classifications';
              break;
            case 'enrichment_attributes':
              typeSelected = 'enrichment';
              break;
          }
          return typeSelected;
        };

        ctrl.toggleAttributeSideBar = () => {
          ctrl.showAttributeSideBar = !ctrl.showAttributeSideBar;
          ctrl.triggerGraphUpdate = !ctrl.showAttributeSideBar;
        };
      };
    },
  ],
});

/* identityLineage angular component was implemented
 * using child-parent dependencies on $ngController and we don't want to change the legacy files
 * so we have to use dedicated `template` to wrap the main component before converting to React
 */
export const IdentityLineageComponent = convertToReact('identityLineageComponent', { template: '<identity-lineage/>' });
