import React, { FC, useState } from 'react';
import { BigidCheckbox, PrimaryButton, SecondaryButton, BigidTooltip, BigidLink } from '@bigid-ui/components';
import { BigidInfoIcon } from '@bigid-ui/icons';
import { openSystemDialog, SystemDialogContentProps } from '../../../../../../services/systemDialogService';
import { $translate } from '../../../../../../services/angularServices';
import { CorrelationSetSelectionProps } from './correlationStepTypes';
import styled from '@emotion/styled';
import { publicUrls } from '../../../../../../config/publicUrls';
import { useLocalTranslation } from '../../../../translations';

interface AdvancedSettingsDialogContentProps {
  skipIdScan?: boolean;
  isClassificationsAsPiiFindings?: boolean;
  isReviewFindingsEnabled?: boolean;
  onSkipCorrelationSetsScan?: (skipIdScan: boolean) => void;
  onClassificationsAsPiiFindings?: (isClassificationsAsPiiFindings: boolean) => void;
  onEnableReviewFindings?: (isClassificationsAsPiiFindings: boolean) => void;
  isClassificationsAsPiiFindingsDisabled: boolean;
  isEditMode: boolean;
  isCorrelationSetDisabled?: boolean;
  isClassifierTuningDisabled?: boolean;
}

const SectionWrapper = styled('div')({
  display: 'flex',
});

const InfoIconWrapper = styled('div')({
  display: 'flex',
  alignItems: 'center',
});

export const AdvanceSettingsDialogContent: FC<SystemDialogContentProps<AdvancedSettingsDialogContentProps>> = ({
  skipIdScan,
  isClassificationsAsPiiFindings,
  isReviewFindingsEnabled,
  onClassificationsAsPiiFindings,
  onSkipCorrelationSetsScan,
  onEnableReviewFindings,
  isClassificationsAsPiiFindingsDisabled,
  isEditMode,
  isClassifierTuningDisabled,
  isCorrelationSetDisabled,
}) => {
  const isSkipIdScanChecked = isEditMode ? skipIdScan : skipIdScan ?? true;
  const [skipCorrelationScan, setSkipCorrelationSetsScan] = useState<boolean>(isSkipIdScanChecked);
  const [enabledReviewFindings, setEnabledReviewFindings] = useState<boolean>(isReviewFindingsEnabled);
  const [classificationsAsPiiFindings, setClassificationsAsPiiFindings] =
    useState<boolean>(isClassificationsAsPiiFindings);
  const { t } = useLocalTranslation('advancedSettingsDailog');

  return (
    <>
      <SectionWrapper>
        <BigidCheckbox
          label={$translate.instant('SCAN_PROFILES:CORRELATION_SET_SETTINGS:SKIP_CORRELATION_SET_SCAN')}
          onChange={(event, checked) => {
            onSkipCorrelationSetsScan(checked);
            setSkipCorrelationSetsScan(checked);
          }}
          dataAid={'advanced-settings-checkbox-skip-correlation-set-scan'}
          checked={skipCorrelationScan}
          disabled={isCorrelationSetDisabled}
        />
        <BigidTooltip
          title={
            <div>
              If the correlation sets were scanned in the last 30 days and there has been no change to the system, there
              is no need to rescan them. Select this option to reduce processing time. For more information, see the{' '}
              <BigidLink text="documentation." href={publicUrls.SCAN_TEMPLATES_CORRELATION} shouldOpenNewTab={true} />
            </div>
          }
        >
          <InfoIconWrapper>
            <BigidInfoIcon />
          </InfoIconWrapper>
        </BigidTooltip>
      </SectionWrapper>
      <SectionWrapper>
        <BigidCheckbox
          label={$translate.instant('SCAN_PROFILES:CORRELATION_SET_SETTINGS:CORRELATE_FINDINGS')}
          onChange={(event, checked) => {
            onClassificationsAsPiiFindings(checked);
            setClassificationsAsPiiFindings(checked);
          }}
          dataAid={'advanced-settings-checkbox-correlate-findings'}
          checked={classificationsAsPiiFindings}
          disabled={isClassificationsAsPiiFindingsDisabled}
        />
        {isClassificationsAsPiiFindingsDisabled && (
          <BigidTooltip
            title={
              <div>
                {t('isClassificationsAsPiiFindingsDisabledTooltip')}
                <BigidLink
                  text="documentation."
                  href={publicUrls.HANDLING_LARGE_SCALE_CORRELATION_SETS}
                  shouldOpenNewTab={true}
                />
              </div>
            }
          >
            <InfoIconWrapper>
              <BigidInfoIcon />
            </InfoIconWrapper>
          </BigidTooltip>
        )}
      </SectionWrapper>
      <BigidCheckbox
        label={$translate.instant('SCAN_PROFILES:CORRELATION_SET_SETTINGS:CLASSIFIER_TUNING_SAMPLING')}
        onChange={(event, checked) => {
          onEnableReviewFindings(checked);
          setEnabledReviewFindings(checked);
        }}
        dataAid={'advanced-settings-checkbox-review-findings'}
        checked={enabledReviewFindings}
        disabled={isClassifierTuningDisabled}
      />
    </>
  );
};

export const AdvancedSettingsDialog = ({
  scanTemplateFormData,
  setScanTemplateFormData,
  isClassificationsAsPiiFindingsDisabled,
  isEditMode,
  isCorrelationSetDisabled,
  isClassifierTuningDisabled,
}: CorrelationSetSelectionProps) => {
  let skipIdScan = scanTemplateFormData?.skipIdScan;
  let isClassificationsAsPiiFindings = scanTemplateFormData?.isClassificationsAsPiiFindings;
  let isReviewFindingsEnabled = scanTemplateFormData?.isReviewFindingsEnabled;
  const onSkipCorrelationSetsScan = (checked: boolean) => {
    skipIdScan = checked;
  };
  const onClassificationsAsPiiFindings = (checked: boolean) => {
    isClassificationsAsPiiFindings = checked;
  };

  const onEnableReviewFindings = (checked: boolean) => {
    isReviewFindingsEnabled = checked;
  };

  const contentProps: AdvancedSettingsDialogContentProps = {
    skipIdScan,
    isClassificationsAsPiiFindings,
    isReviewFindingsEnabled,
    onClassificationsAsPiiFindings,
    onSkipCorrelationSetsScan,
    onEnableReviewFindings,
    isClassificationsAsPiiFindingsDisabled,
    isEditMode,
    isCorrelationSetDisabled,
    isClassifierTuningDisabled,
  };

  return openSystemDialog({
    title: 'Advanced Settings',
    content: AdvanceSettingsDialogContent,
    contentProps,
    onClose: () => null,
    buttons: [
      {
        text: 'Cancel',
        component: SecondaryButton,
        onClick: () => {
          return;
        },
        isClose: true,
      },
      {
        text: 'Save',
        component: PrimaryButton,
        onClick: () => {
          setScanTemplateFormData({
            ...scanTemplateFormData,
            skipIdScan,
            isClassificationsAsPiiFindings,
            isReviewFindingsEnabled,
          });
        },
        isClose: true,
      },
    ],
  });
};
