import React, { FC } from 'react';
import classnames from 'classnames';
import { ConnectStatus, DataSourceInfo } from '../../../Fmsd/FmsdComponents/ConnectionsStatus/utils';
import { BigidBody1, BigidCheckbox, BigidColorsV2, BigidIcon, BigidTooltip } from '@bigid-ui/components';
import { generateDataAid } from '@bigid-ui/utils';
import { Lock, LockOpen } from '@mui/icons-material';
import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles({
  dot: {
    height: 6,
    width: 6,
    backgroundColor: BigidColorsV2.gray[400],
    borderRadius: '50%',
    display: 'inline-block',
    marginBottom: 10,
  },
  success: {
    backgroundColor: BigidColorsV2.green[600],
  },
  failed: {
    backgroundColor: BigidColorsV2.red[600],
  },
  item: {
    display: 'flex',
    paddingBottom: 8,
    alignItems: 'center',
    fontSize: 14,
  },
  itemName: {
    paddingLeft: 5,
    width: 100,
  },
});

export interface AutoDiscoveryConnectionTypeProps extends DataSourceInfo {
  selected: boolean;
  onSelect?: (id: string, checked: boolean) => void;
  disabled: boolean;
  dataAid: string;
}

export const AutoDiscoveryConnectionType: FC<AutoDiscoveryConnectionTypeProps> = ({
  id,
  dataAid,
  name,
  connected,
  onSelect,
  selected,
  disabled,
}) => {
  const { dot, success, failed, item, itemName } = useStyles({});
  return (
    <div key={id} className={item} data-aid={generateDataAid('ConnectionsItem', [id, selected])}>
      <BigidCheckbox
        disabled={disabled}
        checked={selected}
        dataAid={generateDataAid('ConnectionsItem-checkbox', [id, selected])}
        onChange={(event, checked) => {
          onSelect(id, checked);
        }}
      />

      <BigidBody1 data-aid={generateDataAid('ConnectionsItem-ds-name', [id])} className={itemName}>
        {name}
        <span
          className={classnames(dot, {
            [success]: connected === ConnectStatus.CONNECTED,
            [failed]: connected === ConnectStatus.FAILED,
          })}
          data-aid={`${dataAid}-ds-dot-${connected === ConnectStatus.CONNECTED ? 'permitted' : 'forbidden'}`}
        />
      </BigidBody1>
      <BigidTooltip isDisabled={connected !== ConnectStatus.FAILED} title={'No permissions'} placement={'right'}>
        <div>
          <BigidIcon icon={connected === ConnectStatus.FAILED ? Lock : LockOpen} />
        </div>
      </BigidTooltip>
    </div>
  );
};
