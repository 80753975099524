import React, { FC, useEffect, useState } from 'react';
import { BigidColorsV2, BigidDialog, PrimaryButton, SecondaryButton } from '@bigid-ui/components';
import makeStyles from '@mui/styles/makeStyles';
import { copyToClipboard } from '../../../../../fmsdServices';
import { useAwsPolicyGenerator } from './hooks/useAwsPolicyGenerator';
import { notificationService } from '../../../../../../../services/notificationService';
import { AwsConnection, AwsPolicyGenerator } from './AwsDialogViews';
import { FmsdTrackingEvents, trackFmsdEvent } from '../../../../../fmsdEventsTrackerUtils';
import { CloudProvider } from '../../../../../../AutoDiscoveryWizard/autoDiscoveryWizardServices';
import { useLocalTranslation } from '../../../../../translations';

const useStyles = makeStyles({
  dialogContent: {
    color: BigidColorsV2.gray[700],
    height: 448,
  },
});

export interface AwsInfoDialogProps {
  isOpen: boolean;
  onClose: () => void;
}

export const AwsInfoDialog: FC<AwsInfoDialogProps> = ({ isOpen, onClose }) => {
  const { dialogContent } = useStyles({});
  const { policiesSelection, onPolicySelection, awsPolicyJson } = useAwsPolicyGenerator();
  const [isGeneratorView, setIsGeneratorView] = useState<boolean>(false);
  const { t } = useLocalTranslation('Connect.Dialog');

  const onCopyPolicyHandler = () => {
    copyToClipboard(awsPolicyJson);
    onClose();
    notificationService.success(t('aws.onCopySuccessNotification'), { duration: 2000 });
  };

  const onGeneratePolicyClick = () => {
    trackFmsdEvent(FmsdTrackingEvents.FMSD_CONNECT_GENERATE_POLICY_CLICK, {
      [FmsdTrackingEvents.DS_TYPE]: CloudProvider.AWS,
    });
    setIsGeneratorView(true);
  };

  const onGoBackClick = () => {
    setIsGeneratorView(prevState => !prevState);
  };

  const mainButtons = [
    {
      component: PrimaryButton,
      onClick: onClose,
      text: t('saveButtonText'),
      dataAid: 'FmsdAwsInfoDialog-DialogButton-close',
      bi: {
        eventType: FmsdTrackingEvents.FMSD_CONNECT_SAVE_AND_CLOSE_CLICK,
        eventData: { [FmsdTrackingEvents.DS_TYPE]: CloudProvider.AWS },
      },
    },
  ];

  const generatorButtons = [
    {
      component: SecondaryButton,
      onClick: onGoBackClick,
      text: t('backButtonText'),
      dataAid: 'FmsdAwsInfoDialog-DialogButton-go-back',
      bi: {
        eventType: FmsdTrackingEvents.FMSD_CONNECT_GO_BACK_TO_CONNECT_VIEW_CLICK,
        eventData: {
          [FmsdTrackingEvents.DS_TYPE]: CloudProvider.AWS,
        },
      },
    },
    {
      component: PrimaryButton,
      onClick: onCopyPolicyHandler,
      text: t('copyButtonText'),
      dataAid: 'FmsdAwsInfoDialog-DialogButton-copy-and-close',
      bi: {
        eventType: FmsdTrackingEvents.FMSD_CONNECT_COPY_PERMISSIONS_JSON_CLICK,
        eventData: {
          [FmsdTrackingEvents.DS_TYPE]: CloudProvider.AWS,
          [FmsdTrackingEvents.SELECTED_DS_TYPES]: policiesSelection
            .filter(policy => policy.isSelected)
            .map(policy => policy.label),
        },
      },
    },
  ];

  useEffect(() => {
    isOpen && setIsGeneratorView(false);
  }, [isOpen]);

  return (
    <BigidDialog
      onClose={onClose}
      showCloseIcon={isGeneratorView}
      title={isGeneratorView && t('infoDialogTitle')}
      isOpen={isOpen || false}
      borderBottom={false}
      borderTop={isGeneratorView}
      buttons={isGeneratorView ? generatorButtons : mainButtons}
      maxWidth="xs"
    >
      <div className={dialogContent}>
        {isGeneratorView ? (
          <AwsPolicyGenerator policiesSelection={policiesSelection} onPolicySelection={onPolicySelection} />
        ) : (
          <AwsConnection onGeneratePolicyClick={onGeneratePolicyClick} />
        )}
      </div>
    </BigidDialog>
  );
};
