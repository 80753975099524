import angular from 'angular';
import React, { FunctionComponent } from 'react';
import { BigidProgressSteps, BigidProgressStepsProps } from '@bigid-ui/components';
import { ProgressStepDetails } from '../ProgressStepDetails/ProgressStepDetails';
import { convertToAngular } from '../../../common/services/convertToAngular';

interface StepInfo {
  stepType: string;
  stepInfoUnstructured: string[];
}

type BigidProgressStepsPropsExtended = BigidProgressStepsProps & { stepsInfo: StepInfo[] };

export const ProgressSteps: FunctionComponent<BigidProgressStepsPropsExtended> = ({
  expandableSteps,
  borderedHeader,
  steps,
  stepsInfo,
}) => {
  return (
    <BigidProgressSteps
      expandableSteps={expandableSteps}
      borderedHeader={borderedHeader}
      steps={steps.map(step => {
        const { stepType } = step;
        const stepInfoEntity = stepsInfo.find(info => info.stepType === stepType);
        step.stepInfo = stepInfoEntity ? <ProgressStepDetails details={stepInfoEntity.stepInfoUnstructured} /> : null;
        return step;
      })}
    />
  );
};

angular
  .module('app')
  .component(
    'progressSteps',
    convertToAngular<BigidProgressStepsPropsExtended>(ProgressSteps, [
      'expandableSteps',
      'borderedHeader',
      'steps',
      'stepsInfo',
    ]),
  );
