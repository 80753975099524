import React, { FC } from 'react';
import { useSummaryData } from '../hooks/useSummaryData';
import { usePolicyData } from '../hooks/usePolicyData';
import { useObjectsData } from '../hooks/useObjectsData';
import { DashboardUpperSectionCommon } from './DashboardUpperSectionCommon';

export const DashboardUpperSection: FC = React.memo(() => {
  const { sourceDisplayData, attributesDisplayData, entitiesDisplayData } = useSummaryData();
  const policyData = usePolicyData();
  const objectsData = useObjectsData();
  const allDisplayData = [sourceDisplayData, entitiesDisplayData, policyData, attributesDisplayData, objectsData];

  return <DashboardUpperSectionCommon displayData={allDisplayData} />;
});

DashboardUpperSection.displayName = 'DashboardUpperSection';
