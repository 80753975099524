import { DataCatalogObjectDetails } from '../../../DataCatalog/DataCatalogDetails/DataCatalogDetailsService';
import { getFilteredAttrForPolicies } from '../CaseReport/caseReportService';
import { DataCatalogAttribute, getAttributesByObjectName } from '../../../DataCatalog/DataCatalogAttributes';
import { ChipFormatterProps } from '@bigid-ui/grid';
import { BigidColorsV2, objectToQueryString, QueryParams } from '@bigid-ui/components';
import {
  DataCatalogRecord,
  transformRecordToObjectDetails,
} from '../../../AccessIntelligence/ACIDetails/ACIDetailsService';
import { $stateParams } from '../../../../services/angularServices';
import { getDataCatalogRecords } from '../../../DataCatalog/DataCatalogService';

export interface fetchAttributesResponse {
  filteredByPolicyAttrs: boolean;
  fullObjectAttributes?: DataCatalogAttribute[];
  policyAttributes?: string[];
}

const chipPriorityColor = ['#FFF2F2', '#FFF2E5', '#FFFCE0'];
const chipTextPriorityColor = [BigidColorsV2.red[900], '#BF4000', '#995900'];
const chipDefaultColor = '#F0FFF7';
const chipTextDefaultColor = '#138045';

const enum ObjectTypes {
  FOLDER = 'FOLDER',
  FILE = 'FILE',
}

export enum CatalogEntities {
  CONTAINERS = 'CONTAINERS',
  LEAVES = 'LEAVES',
}

export const fetchObjectDetails = async (fqn: string, objectType: string) => {
  const filter = {
    limit: 1,
    filter: `fullyQualifiedName IN ("${fqn ?? $stateParams.fullyQualifiedName}")`,
  };
  let catalogEntity;
  if (objectType?.toString() === ObjectTypes.FOLDER) {
    catalogEntity = CatalogEntities.CONTAINERS;
  } else {
    catalogEntity = CatalogEntities.LEAVES;
  }
  const catalogHeaders = { 'catalog-entity': catalogEntity };
  const query = objectToQueryString({ ...(filter as QueryParams) });
  const { results } = await getDataCatalogRecords(query, catalogHeaders);
  return transformRecordToObjectDetails(results?.[0] as DataCatalogRecord);
};

export const fetchObjectAttributes = async (
  objectName: string,
  policyName: string,
): Promise<fetchAttributesResponse> => {
  const { data } = await getAttributesByObjectName(objectName);
  const policyAttributes = await getFilteredAttrForPolicies(policyName);
  const fullObjectAttributes = data.filter(attr => policyAttributes.includes(attr?.attribute_original_name));
  if (!policyAttributes.length) {
    return { filteredByPolicyAttrs: false, fullObjectAttributes: data, policyAttributes };
  }
  return {
    filteredByPolicyAttrs: true,
    fullObjectAttributes,
    policyAttributes,
  };
};

export const getObjectSensitivityChipProps = (scMapping: Map<string, number>, sensitivity = ''): ChipFormatterProps => {
  if (!sensitivity) {
    return { chip: null };
  }
  return {
    chip: {
      label: sensitivity,
      bgColor: getChipColorByPriority(scMapping.get(sensitivity)),
      color: getChipTextColorByPriority(scMapping.get(sensitivity)),
    },
  };
};

const getChipColorByPriority = (priorityLevel: number): string => {
  return chipPriorityColor[priorityLevel] || chipDefaultColor;
};

const getChipTextColorByPriority = (priorityLevel: number): string => {
  return chipTextPriorityColor[priorityLevel] || chipTextDefaultColor;
};

export const getAttrExpiredStatus = (attributes: DataCatalogAttribute[]): boolean => {
  const isObjectExpired = attributes.some((attr: DataCatalogAttribute) => attr.is_expired === true);
  return isObjectExpired;
};
