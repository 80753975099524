import { module } from 'angular';
import './mapping-legend.component.scss';
import template from './mapping-legend.component.html';

import { BUSINESS_FLOWS_PERMISSIONS } from '@bigid/permissions';
import { isPermitted } from '../../../react/services/userPermissionsService';
const app = module('app');

app.component('mappingLegend', {
  template,
  controller: [
    '$uibModal',
    'dataMappingService',
    'DeleteConfirmation',
    function ($uibModal, dataMappingService, DeleteConfirmation) {
      const ctrl = this;

      this.$onChanges = () => {
        if (this.vm) {
          ctrl.parent.vm = this.vm;
        }
      };

      this.$onInit = () => {
        this.isManagePermitted = isPermitted(BUSINESS_FLOWS_PERMISSIONS.MANAGE.name);
      };

      this.editLegend = flow => {
        const model = angular.copy(flow);
        $uibModal
          .open({
            template: '<legend flow="$ctrl.flow" $close="$close(flow)" $dismiss="$dismiss()"></legend>',
            controllerAs: '$ctrl',
            controller: [
              'flow',
              function (flow) {
                this.flow = flow;
              },
            ],
            resolve: {
              flow: () => model,
            },
          })
          .result.then(
            flow => {
              this.vm && this.vm.saveFlow(flow);
            },
            () => {
              //dismiss
            },
          );
      };

      this.deleteLegend = flow => {
        if (this.vm && this.vm.businessFlows.length > 1) {
          const modalOptions = {
            closeButtonText: 'Close',
            actionButtonText: 'Delete',
            headerText: 'Delete Business Flow',
            bodyText: `Deleting a business flow deletes all of the steps associated with it. Are you sure you want to delete "${flow.name}"?`,
          };

          DeleteConfirmation.showModal({}, modalOptions).then(() => {
            this.vm && this.vm.deleteFlow(flow);
          });
        }
      };
    },
  ],
  bindings: {
    vm: '<',
    businessObject: '<',
    onClose: '&',
  },
  require: {
    parent: '^datamapping',
  },
});
