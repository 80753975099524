import { analyticsService } from '../../services/analyticsService';

export enum EmailTemplateTrackingEvents {
  EMAIL_TEMPLATES_BROWSE_ADD_TEMPLATE_CLICK = 'EMAIL_TEMPLATES_BROWSE_ADD_TEMPLATE_CLICK',
  EMAIL_TEMPLATES_BROWSE_EDIT_TEMPLATE_CLICK = 'EMAIL_TEMPLATES_BROWSE_EDIT_TEMPLATE_CLICK',
  EMAIL_TEMPLATES_BROWSE_VIEW_TEMPLATE_CLICK = 'EMAIL_TEMPLATES_BROWSE_VIEW_TEMPLATE_CLICK',
  EMAIL_TEMPLATES_DUPLICATE_TEMPLATE_CLICK = 'EMAIL_TEMPLATES_DUPLICATE_TEMPLATE_CLICK',
  EMAIL_TEMPLATES_DELETE_TEMPLATE_CLICK = 'EMAIL_TEMPLATES_DELETE_TEMPLATE_CLICK',
  EMAIL_TEMPLATES_BRANDS_ADD_BRAND_CLICK = 'EMAIL_TEMPLATES_BRANDS_ADD_BRAND_CLICK',
  EMAIL_TEMPLATES_BRANDS_SAVE_BRAND_CLICK = 'EMAIL_TEMPLATES_BRANDS_SAVE_BRAND_CLICK',
  EMAIL_TEMPLATES_BRANDS_DELETE_BRAND_CLICK = 'EMAIL_TEMPLATES_BRANDS_DELETE_BRAND_CLICK',
}

export const trackEmailTemplateEvent = (eventType: EmailTemplateTrackingEvents, data?: Record<string, unknown>) => {
  analyticsService.trackManualEvent(eventType as any, data);
};
