import { generateDataAid } from '@bigid-ui/utils';

import { BigidSystemDialogOptions, openSystemDialog } from '../../../../../services/systemDialogService';
import { CredentialsDialogContent } from '../../../../Credentials/CredentialsDialogContent';
import { PrimaryButton, TertiaryButton } from '@bigid-ui/components';
import { getFixedT } from '../translations';
import { noop } from 'lodash';

interface ConfigureUnsavedChangesDialogArgs {
  onCancelClick: () => void;
  onSaveClick: () => Promise<boolean>;
}

export const configureUnsavedChangesDialog = ({ onCancelClick, onSaveClick }: ConfigureUnsavedChangesDialogArgs) => {
  const t = getFixedT('dialogs.confirmationDialog');

  return new Promise<boolean>(resolve => {
    const dialogOptions: BigidSystemDialogOptions = {
      title: t('title'),
      onClose: noop,
      maxWidth: 'xs',
      buttons: [
        {
          component: TertiaryButton,
          dataAid: generateDataAid('CredentialsActions', ['cancel', 'submit']),
          isClose: true,
          onClick: () => {
            onCancelClick();
            resolve(true);
          },
          text: t('no'),
        },
        {
          component: PrimaryButton,
          dataAid: generateDataAid('CredentialsActions', ['confirm', 'submit']),
          isClose: true,
          disabled: false,
          onClick: async () => {
            await onSaveClick();
            resolve(true);
          },
          text: t('yes'),
        },
      ],
      content: CredentialsDialogContent,
      contentProps: { body: t('body') },
      borderTop: true,
    };

    openSystemDialog(dialogOptions);
  });
};

export const showUnsavedChangesDialog = (onSaveConfirmed: () => void) =>
  configureUnsavedChangesDialog({
    onCancelClick: () => {
      return Promise.resolve(true);
    },
    onSaveClick: () => {
      onSaveConfirmed();
      return Promise.resolve(false);
    },
  });
