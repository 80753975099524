import React, { FC, useContext } from 'react';
import { GettingStartedContext } from '../hooks/gettingStartedContext';
import styled from '@emotion/styled';
import { GettingStartMenuItem } from './GettingStartedSideMenuItem';

const MenuWrapper = styled.div({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  paddingLeft: '32px',
  gap: '24px',
});

export const GettingStartedSideMenu: FC = () => {
  const { items } = useContext(GettingStartedContext);
  return <MenuWrapper>{items.map(GettingStartMenuItem)}</MenuWrapper>;
};
