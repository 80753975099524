import React from 'react';
import { CollapsibleTextareaField } from '../../../../../components/CollapsibleTextareaField/CollapsibleTextareaField';
import type { BigidFieldRenderProps } from '@bigid-ui/components';
import { useLocalTranslation } from '../../../translations';

export const AutoDiscoveryCollaborationTextField = ({
  dataAid = 'AutoDiscoveryCollaborationTextField',
  setValue,
  label,
  ...props
}: BigidFieldRenderProps<unknown, string> & { dataAid?: string }): JSX.Element => {
  const { t } = useLocalTranslation('AutoDiscovery');

  return (
    <CollapsibleTextareaField
      dataAid={dataAid}
      setValue={setValue}
      message={t('collaborateModal.message')}
      link={t('collaborateModal.link')}
      {...props}
    />
  );
};
