import { createContext } from 'react';
import { GettingStartedReducerAction } from './GettingStartedReducer';
import { GettingStartedItem, GettingStartedItems } from '../GettingStartedItems';

export interface GettingStartedContextData {
  currentStep: keyof GettingStartedItems;
  items: GettingStartedItems;
  dispatch: React.Dispatch<GettingStartedReducerAction>;
  currentStepObject: GettingStartedItem;
}

export const GettingStartedContext = createContext<GettingStartedContextData>(null);
