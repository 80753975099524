import { useEffect, useCallback, useMemo, useState } from 'react';
import { AdvancedToolbarOverrideValue, advancedToolbarFilterMinifier, useHashQueryParams } from '@bigid-ui/components';

import { userPreferencesService } from '../../../services/userPreferencesService';
import { $state } from '../../../services/angularServices';
import { filtersConfig as initialToolbarFilterConfig, searchConfig, stateName } from './constants';

export function useFilter() {
  const [isReady, setIsReady] = useState(false);
  const { filter: queryFilterString = '', search: querySearch = '' } = useHashQueryParams();
  const queryFilter: AdvancedToolbarOverrideValue[] = useMemo(() => {
    try {
      if (!queryFilterString) return [];
      const minifiedOverrideValues = JSON.parse(decodeURI(queryFilterString)) || [];
      return minifiedOverrideValues.map(advancedToolbarFilterMinifier.getOverrideValueUnminified);
    } catch (err) {
      console.error('Error parsing queryFilterString', err);
      return [];
    }
  }, [queryFilterString]);

  useEffect(() => {
    userPreferencesService
      .get<{ filter: AdvancedToolbarOverrideValue[]; search: string }>(stateName)
      .then(preferences => {
        const prefFilter = preferences?.data?.filter || [];

        const collectedFilter = initialToolbarFilterConfig.reduce<AdvancedToolbarOverrideValue[]>((acc, defFilter) => {
          const newFilter =
            queryFilter.find(p => p.id === defFilter.id) ||
            prefFilter.find(p => p.id === defFilter.id) ||
            (!defFilter.disabled && advancedToolbarFilterMinifier.getFilterCompressedToOverrideValue(defFilter));
          return newFilter ? [...acc, newFilter] : acc;
        }, []);
        const filterString = JSON.stringify(
          collectedFilter.map(advancedToolbarFilterMinifier.getOverrideValueMinified),
        );

        const newSearch = querySearch || preferences?.data?.search || searchConfig.value || '';

        $state.go('.', { ...$state.params, filter: filterString, search: newSearch }, { notify: false, reload: false });
      })
      .finally(() => setIsReady(true));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const udpateFilter = useCallback(
    async (newFilter: AdvancedToolbarOverrideValue[]) => {
      const filterString = JSON.stringify(newFilter.map(advancedToolbarFilterMinifier.getOverrideValueMinified));
      $state.go('.', { ...$state.params, filter: filterString }, { notify: false, reload: false });

      userPreferencesService.update({
        preference: stateName,
        data: { filter: newFilter, search: querySearch },
      });

      return newFilter;
    },
    [querySearch],
  );

  const updateSearch = useCallback(
    async (newSearch: string) => {
      $state.go('.', { ...$state.params, search: newSearch }, { notify: false, reload: false });
      userPreferencesService.update({
        preference: stateName,
        data: { search: newSearch, filter: queryFilter },
      });

      return newSearch;
    },
    [queryFilter],
  );

  return {
    isReady,
    filter: queryFilter,
    udpateFilter,
    search: querySearch,
    updateSearch,
  };
}
