import React, { FC, useRef, useLayoutEffect, useMemo } from 'react';
import { BigidBody2, BigidHeading4, BigidColors } from '@bigid-ui/components';
import makeStyles from '@mui/styles/makeStyles';
import { ShowFailedTestDialogOptions } from '../../utils/scannerLogsUtils';

export const useStyles = makeStyles({
  wrapper: {
    display: 'flex',
    position: 'relative',
    flexDirection: 'column',
  },
  dateWrapper: {
    marginBottom: '20px',
  },
  textWrapper: {
    height: '385px',
    overflowY: 'scroll',
    border: `1px solid ${BigidColors.gray[100]}`,
    borderRadius: '6px',
    whiteSpace: 'pre-wrap',
    padding: '6px 8px',
  },
  highlightedTextWrapper: {
    border: `1px solid ${BigidColors.green[700]}`,
    borderRadius: 4,
    backgroundColor: BigidColors.green[50],
    margin: '1px',
  },
});

const startHighlightedText = '<--Highlights_START-->';
const endHighlightedText = '<--Highlights_END-->';
const useGetHighlightedText = (text: string, getHighlightedWords: () => string[], highlightedClassName: string) =>
  useMemo(
    () =>
      getHighlightedWords()
        .reduce((textAcc, part) => {
          try {
            return textAcc.replace(
              new RegExp(part, 'gi'),
              match => `${startHighlightedText}${match}${endHighlightedText}`,
            );
          } catch (error) {
            console.warn(error);
            return textAcc;
          }
        }, text)
        .split(startHighlightedText)
        .map((str, index) => {
          const end = str.indexOf(endHighlightedText);
          return index !== 0 ? (
            <React.Fragment key={index}>
              <span className={highlightedClassName}>{str.slice(0, end)}</span>
              {str.slice(end).replace(new RegExp(endHighlightedText, 'gi'), '')}
            </React.Fragment>
          ) : (
            str
          );
        }),
    [getHighlightedWords, highlightedClassName, text],
  );

export const TestConnectionLogsModalContent: FC<ShowFailedTestDialogOptions> = ({
  logText,
  date,
  getHighlightedWordsForLog,
}) => {
  const { wrapper, dateWrapper, textWrapper, highlightedTextWrapper } = useStyles({});
  const textWrapperElementRef = useRef(null);
  useLayoutEffect(() => {
    textWrapperElementRef?.current?.children?.[0]?.scrollIntoView(false);
  }, []);
  const textWithHighlights = useGetHighlightedText(logText, getHighlightedWordsForLog, highlightedTextWrapper);

  return (
    <div data-aid="TestConnectionLogsModalContent" className={wrapper}>
      <BigidHeading4 className={dateWrapper}>{date}</BigidHeading4>
      <div ref={textWrapperElementRef} className={textWrapper}>
        <BigidBody2 data-aid="FailedTestDialogContent">{textWithHighlights}</BigidBody2>
      </div>
    </div>
  );
};
