import React, { FC, useState, useEffect } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { BigidColors } from '@bigid-ui/components';
import classNames from 'classnames';

export interface ColumnProfilingPercentageChartProps {
  value: number;
  color?: string;
}

interface Circle {
  /* X coordinate of circle centre */
  x: number;
  /* Y coordinate of circle centre */
  y: number;
  /* radius */
  r: number;
  /* circumference */
  c: number;
}

const viewBoxSize = 100;
const baseCircleStrokeWidth = 9;
const valueCircleStrokeWidth = 8;

const useStyles = makeStyles({
  chart: {
    display: 'block',
    height: '100%',
    width: '100%',
  },
  circle: {
    fill: 'none',
    stroke: BigidColors.gray[100],
    strokeWidth: baseCircleStrokeWidth,
  },
  segment: {
    fill: 'none',
    strokeWidth: valueCircleStrokeWidth,
    strokeLinecap: 'round',
    transformOrigin: 'center center',
  },
  valueSegment: {
    transform: 'rotate(-90deg)',
  },
});

export const ColumnProfilingPercentageChart: FC<ColumnProfilingPercentageChartProps> = ({
  color = BigidColors.blue[600],
  value = 0,
}) => {
  const [circle, setCircle] = useState<Circle>({
    x: 0,
    y: 0,
    r: 0,
    c: 0,
  });

  const classes = useStyles({});

  useEffect(() => {
    const r = viewBoxSize / 2 - baseCircleStrokeWidth / 2;
    const x = viewBoxSize / 2;
    const y = viewBoxSize / 2;
    const c = 2 * Math.PI * r;

    setCircle({ x, y, r, c });
  }, []);

  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox={`0 0 ${viewBoxSize} ${viewBoxSize}`} className={classes.chart}>
      <circle className={classes.circle} cx={circle.x} cy={circle.y} r={circle.r} />
      <circle
        className={classNames(classes.segment, classes.valueSegment)}
        strokeDasharray={circle.c}
        strokeDashoffset={circle.c * (1 - value / 100)}
        stroke={color}
        cx={circle.x}
        cy={circle.y}
        r={circle.r}
      />
      <title>{value}%</title>
    </svg>
  );
};
