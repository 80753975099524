import React, { FC } from 'react';
import { BigidLayoutEmptyState } from '@bigid-ui/layout';
import { AddBoxOutlined } from '@mui/icons-material';
import { BigidProxyIllustration } from '@bigid-ui/icons';
import { useLocalTranslation } from './translations';
import { PROXIES_PERMISSIONS } from '@bigid/permissions';
import { isPermitted } from '../../services/userPermissionsService';

interface ProxiesEmptyStateProps {
  handleProxyAdd: () => void;
}

export const ProxiesEmptyState: FC<ProxiesEmptyStateProps> = ({ handleProxyAdd }) => {
  const { t } = useLocalTranslation();

  const emptyStateActions = [
    {
      execute: async () => {
        handleProxyAdd();
        return { shouldGridReload: false };
      },
      icon: AddBoxOutlined,
      label: t('buttons.addProxy'),
      show: () => isPermitted(PROXIES_PERMISSIONS.CREATE.name),
    },
  ];

  return (
    <BigidLayoutEmptyState
      actions={emptyStateActions}
      illustration={BigidProxyIllustration}
      description={t('message.manageProxies')}
    />
  );
};
