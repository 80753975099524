import React, { FC, ReactElement } from 'react';
import { BigidColorsV2, BigidHeading1, BigidHeading6, BigidIconType, BigidPaper } from '@bigid-ui/components';
import makeStyles from '@mui/styles/makeStyles';
import styled from '@emotion/styled';
import { generateDataAid } from '@bigid-ui/utils';
import { BigidHelpIcon } from '@bigid-ui/icons';
import { BigidTooltipContainer } from '../../ScanDetails/ScanDetails';

const useStyles = makeStyles({
  bigidPaperContainer: {
    height: '255px',
    padding: '24px',
    display: 'grid',
    gridTemplateRows: 'auto 1fr auto',
    boxShadow: 'none',
    border: `1px solid ${BigidColorsV2.gray[200]}`,
  },
});

const StyledBigidHeading6 = styled(BigidHeading6)`
  display: flex;
  align-items: center;
  gap: 6px;
`;

const HeaderSection = styled('div')``;

const TitleSection = styled('div')`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
`;

const MainSection = styled('div')`
  margin-top: 12px;
`;

const BottomSection = styled('div')`
  display: flex;
  align-items: center;
  gap: 14px;
`;

export interface ScanOverviewBoxProps {
  title: string;
  subTitle: string;
  icon: BigidIconType;
  dataAid?: string;
  mainContent: ReactElement;
  bottomContent?: ReactElement;
  tooltip?: string;
}

export const ScanOverviewBox: FC<ScanOverviewBoxProps> = ({
  dataAid = 'ScanOverviewBox',
  title,
  icon: Icon,
  subTitle,
  tooltip,
  mainContent: MainContent,
  bottomContent: BottomContent,
}) => {
  const classes = useStyles({});
  return (
    <BigidPaper
      dataAid={generateDataAid(dataAid, ['bigidPaperContainer'])}
      classes={{ root: classes.bigidPaperContainer }}
    >
      <HeaderSection data-aid={generateDataAid(dataAid, ['bigidPaperContainer', 'HeaderSection'])}>
        <TitleSection>
          <BigidHeading1>{title}</BigidHeading1>
          <Icon width={30} height={30} />
        </TitleSection>
        <StyledBigidHeading6>
          {subTitle}
          {tooltip && (
            <>
              <BigidTooltipContainer title={tooltip} enterDelay={500} placement="right">
                <span>
                  <BigidHelpIcon />
                </span>
              </BigidTooltipContainer>
            </>
          )}
        </StyledBigidHeading6>
      </HeaderSection>
      <MainSection data-aid={generateDataAid(dataAid, ['bigidPaperContainer', 'MainSection'])}>
        {MainContent}
      </MainSection>
      {BottomContent && (
        <BottomSection data-aid={generateDataAid(dataAid, ['bigidPaperContainer', 'BottomSection'])}>
          {BottomContent}
        </BottomSection>
      )}
    </BigidPaper>
  );
};
