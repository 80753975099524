import React, { useState } from 'react';
import { Content, Image, Overlay, Actions } from './DataSourceConnectionOverlayStyles';
import { SecondaryButton, BigidConnectionInProgressAnimation } from '@bigid-ui/components';
import { Typography } from '@mui/material';
import { useTimeout } from '../hooks/useTimeout';
import { useLocalTranslation } from '../translations';
import { useDataSourceModalContext } from '../hooks/useDataSourceModalContext';
import { DEFAULT_TEST_CONNECTION_TIMEOUT } from '../constants/constants';

export type DataSourceConnectionOverlayInProgressProps = {
  headerText?: string;
  onSave?: () => void;
  dataAid?: string;
};

const styles = {
  marginTop: '24px',
};

export const DataSourceConnectionOverlayInProgress = ({
  headerText,
  dataAid = 'DataSourceConnectionOverlayInProgress',
  onSave,
}: DataSourceConnectionOverlayInProgressProps): JSX.Element => {
  const [isTimeout, setTimeout] = useState(false);
  const { t } = useLocalTranslation();
  const { isStandalone } = useDataSourceModalContext();
  const header = headerText ?? t('connectionInProgressOverlay.header');
  useTimeout(() => setTimeout(true), DEFAULT_TEST_CONNECTION_TIMEOUT);

  return (
    <Overlay data-aid={dataAid}>
      <Image>
        <BigidConnectionInProgressAnimation width={159} height={155} />
      </Image>
      <Content>
        <Typography variant="h6">{header}</Typography>
        {isTimeout && (
          <>
            <Typography variant="h6">{t('connectionInProgressOverlay.content')}</Typography>
            <Typography variant="body1">{t('connectionInProgressOverlay.sub')}</Typography>
            {onSave && !isStandalone && (
              <Actions style={styles}>
                <SecondaryButton size="large" onClick={onSave} text={t('buttons.saveAndClose')} />
              </Actions>
            )}
          </>
        )}
      </Content>
    </Overlay>
  );
};
