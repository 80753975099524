import { PreviousQuestion } from './BigChatTypes';
import { setPreviousQuestions } from '../../services/bigchat/bigchatStorageService';

const TIME_LIMIT = 3 * 60 * 1000; // 30 minutes
const INPUT_MAX_LENGTH = 100;

export const isFollowupCandidate = (previousQuestions: PreviousQuestion[]): boolean => {
  if (previousQuestions?.length) {
    return Date.now() - previousQuestions[previousQuestions.length - 1].updatedAt < TIME_LIMIT;
  }
  return false;
};

export const getActualQuestion = (
  message: string,
  previousQuestions: PreviousQuestion[],
  isFollowUp: boolean,
): string => {
  let actualQuestion = message;
  if (isFollowUp) {
    actualQuestion += ' PREVIOUS CONTEXT: ';
    for (const prevQuestion of previousQuestions) {
      actualQuestion += prevQuestion.question + ' ' + prevQuestion.shortAnswer;
    }
  }
  return actualQuestion;
};

export const updatedPreviousQuestions = (
  previousQuestions: PreviousQuestion[],
  isFollowUp: boolean,
  questionToAdd: PreviousQuestion,
): void => {
  const previousQuestionsToUpdate = isFollowUp ? previousQuestions : [];
  previousQuestionsToUpdate.push(questionToAdd);
  setPreviousQuestions(previousQuestionsToUpdate);
};

export const validateInput = (message: string): boolean => {
  return message?.trim().length > 0 && message?.length < INPUT_MAX_LENGTH;
};
