import { ChangeEvent, useState } from 'react';
import { AwsPolicyType } from '../iamRoles';
import { getAwsPolicyJson } from '../utils';
import { getFixedT } from '../../../../../../translations';

const t = getFixedT('General');
export interface AwsPoliciesSelection {
  label: string;
  value: string;
  isSelected: boolean;
  isDisabled: boolean;
}

const policies: AwsPoliciesSelection[] = [
  { label: t('dsTypes.all'), value: AwsPolicyType.ALL, isSelected: true, isDisabled: false },
  { label: t('dsTypes.s3'), value: AwsPolicyType.S3, isSelected: true, isDisabled: true },
  { label: t('dsTypes.athena'), value: AwsPolicyType.ATHENA, isSelected: true, isDisabled: true },
  { label: t('dsTypes.dynamodb'), value: AwsPolicyType.DYNAMODB, isSelected: true, isDisabled: true },
  { label: t('dsTypes.kinesis'), value: AwsPolicyType.KINESIS, isSelected: true, isDisabled: true },
];

export const useAwsPolicyGenerator = () => {
  const [policiesSelection, setPoliciesSelection] = useState<AwsPoliciesSelection[]>(policies);
  const [awsPolicyJson, setAwsPolicyJson] = useState<string>(getAwsPolicyJson(['all']));

  const onPolicySelection = (event: ChangeEvent<HTMLInputElement>, checked: boolean) => {
    const newSelection = [...policiesSelection];
    if (event.target.value === AwsPolicyType.ALL) {
      newSelection.forEach(policy => {
        policy.isSelected = checked;
        if (policy.value !== AwsPolicyType.ALL) {
          policy.isDisabled = checked;
        }
      });
    } else {
      const index = newSelection.findIndex(policy => policy.value === event.target.value);
      newSelection[index] = { ...newSelection[index], isSelected: checked };
    }

    const selectedTypesJson = getAwsPolicyJson(
      newSelection.filter(policy => policy.isSelected && !policy.isDisabled).map(policy => policy.value),
    );
    setAwsPolicyJson(selectedTypesJson);
    setPoliciesSelection(newSelection);
  };

  return {
    policiesSelection,
    onPolicySelection,
    awsPolicyJson,
  };
};
