import './existingBusinessProcess.component.scss';
import template from './existingBusinessProcess.component.html';
import { module } from 'angular';
import { isPermitted } from '../../../react/services/userPermissionsService';
import { BUSINESS_FLOWS_PERMISSIONS } from '@bigid/permissions';
const app = module('app');

app.component('existingBusinessProcess', {
  template,
  controller: function (
    $http,
    $document,
    appSettings,
    dataMappingService,
    DeleteConfirmation,
    $scope,
    $rootScope,
    uiGridConstants,
    $uibModal,
    notificationService,
  ) {
    'ngInject';
    const ctrl = this;

    $scope.loading = false;

    const getProcesses = () => {
      $scope.loading = true;

      dataMappingService
        .getBusinessProcesses()
        .then(
          businessProcesses => {
            $scope.existingBusinessProcessModel.data = businessProcesses;
            $scope.loading = false;
          },
          () => {
            $scope.loading = false;
          },
        )
        .catch(() => {
          $scope.loading = false;
        });
    };

    this.$onInit = () => {
      $scope.isManagePermitted = isPermitted(BUSINESS_FLOWS_PERMISSIONS.MANAGE.name);
      getProcesses();
    };

    $scope.existingBusinessProcessModel = {
      enableColumnMenus: false,
      enableSorting: true,
      enablePaginationControls: false,
      columnDefs: [
        {
          name: 'Name',
          field: 'title',
          cellEditableCondition: false,
          cellTemplate: "<div title='{{row.entity.title}}'>{{row.entity.title.substring(0,30)}}</div>",
          cellClass: 'text-cell',
          //minWidth: 100
        },
        {
          name: 'Owner',
          field: 'ownerEmail',
          cellTemplate: "<div title='{{row.entity.ownerEmail}}'>{{row.entity.ownerEmail}}</div>",
          cellEditableCondition: false,
          cellClass: 'text-cell',
          //minWidth: 100
        },
        {
          name: 'Actions',
          field: '',
          cellEditableCondition: false,
          cellTemplate:
            '<div style="margin-left: 30px !important;margin-top: -5px !important;">' + //style="float:right !important;"
            '<button class="btn primary btnAction btnActionsEdit"  ng-click="grid.appScope.open(row.entity)">Open</button>' +
            '<button class="btn primary btnActionsDelete" ng-click="grid.appScope.deleteBusinessProcess(row.entity)"' +
            ' ng-if="grid.appScope.isManagePermitted">Delete</button>',
          cellClass: 'actions-cell',
          maxWidth: 240,
        },
      ],
    };

    $scope.existingBusinessProcessModel.onRegisterApi = function (gridApi) {
      $scope.gridApi = gridApi;
    };

    $scope.deleteBusinessProcess = function (businessProcess) {
      const id = businessProcess._id;

      const modalOptions = {
        closeButtonText: 'Close',
        actionButtonText: 'Delete',
        headerText: 'Delete Business Process',
        bodyText: `Are you sure you want to delete "${businessProcess.title}"?`,
      };
      DeleteConfirmation.showModal({}, modalOptions).then(() => {
        dataMappingService
          .deleteBusinessProcess(id)
          .then(() => {
            notificationService.success(`${businessProcess.title} deleted successfully!`);
            ctrl.onDelete({ businessProcess: businessProcess });
            getProcesses();
          })
          .catch(() => {
            notificationService.error(`Failed to delete "${businessProcess.title}"`);
          });
      });
    };

    $scope.open = function (businessProcess) {
      ctrl.onOpen({ businessProcess: businessProcess });
    };
  },
  bindings: {
    onDelete: '&',
    onClose: '&',
    onOpen: '&',
    vm: '<',
  },
});
