import React, { FC, useEffect, useState, useCallback, useMemo } from 'react';
import {
  BigidEditableList,
  BigidLoader,
  BigidEditableListItemType,
  PrimaryButton,
  SecondaryButton,
} from '@bigid-ui/components';
import { generateDataAid } from '@bigid-ui/utils';
import makeStyles from '@mui/styles/makeStyles';
import { AdditionalAttribute, getAdditionalAttributes } from '../../../../curationService';
import { getSystemAttributes, SystemAttribute } from '../../../../../DataCatalog/DataCatalogService';
import {
  ManualField,
  updateManualFields,
  deleteManualFields,
  ManualFieldType,
} from '../../../../../DataCatalog/DataCatalogColumns';
import { remove, uniqBy, uniqueId } from 'lodash';
import { notificationService } from '../../../../../../services/notificationService';
import { SystemDialogContentProps } from '../../../../../../services/systemDialogService';
import {
  getIsNewAttributeUnique,
  mapSystemAttributeToListItem,
  mapSystemAttributeToAdditionalAttribute,
} from './additionalAttributeUtils';
import { useLocalTranslation } from '../../../../translations';
import { CurationEvents, trackEventCurationView } from '../../../../curationEventTrackerUtils';
import { AdditionalAttributesObjectIdentifier } from '../../../useCurationFieldsConfig';

export type ModifyAdditionalAttributesProps = SystemDialogContentProps<{
  dataAid: string;
  orderAfterSave?: boolean;
  objectsSelected: AdditionalAttributesObjectIdentifier[];
  isBulkMode?: boolean;
  onSave: (addedAttributes: Partial<AdditionalAttribute>[], deletedAttributes: Partial<AdditionalAttribute>[]) => void;
}>;

const useStyles = makeStyles({
  root: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
  },
  content: {
    flex: 1,
  },
  footer: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: '8px',
    gap: '8px',
  },
});

export type AddedSystemAttribute = SystemAttribute & { isNewAttribute?: boolean };

type ModifiedAttributes = {
  addedAttributes: AddedSystemAttribute[];
  deletedAttributes: SystemAttribute[];
};

/**
 * in this func we remove the temp id's we gave to attributes that are not on the database yet.
 * those id's are needed for saving more than 1 new attribute in an action but we should not send them to the server.
 */
function getAttributesWithoutTempId(modifiedAttributes: AddedSystemAttribute[] | SystemAttribute[]) {
  return modifiedAttributes.map(attribute => {
    if (attribute.hasOwnProperty('__isNew__')) {
      delete attribute.attribute_id;
    }
    return attribute;
  });
}

export const ModifyAdditionalAttributes: FC<ModifyAdditionalAttributesProps> = ({
  dataAid,
  objectsSelected,
  onSave,
  onClose,
  orderAfterSave = true,
  isBulkMode = false,
}) => {
  const classes = useStyles({});
  const { t } = useLocalTranslation('CuratedFields.common.AdditionalAttributes.ModifyAttributesDialog');

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [systemAttributes, setSystemAttributes] = useState<SystemAttribute[]>([]);
  const [objectAttributes, setObjectAttributes] = useState<AdditionalAttribute[]>([]);
  const [modifiedAttributes, setModifiedAttributes] = useState<ModifiedAttributes>({
    addedAttributes: [],
    deletedAttributes: [],
  });

  const { listItems, listItemsSource } = useMemo(() => {
    return {
      listItems: systemAttributes
        .filter(({ attribute_id }) => !!objectAttributes.find(({ attributeId }) => attributeId === attribute_id))
        .map((attribute: SystemAttribute) => ({
          attributes: [mapSystemAttributeToListItem(attribute)],
        })),
      listItemsSource: systemAttributes.map(mapSystemAttributeToListItem),
    };
  }, [objectAttributes, systemAttributes]);

  useEffect(() => {
    const fetchAttributes = async () => {
      try {
        setIsLoading(true);

        const { fieldName, fullyQualifiedName } = objectsSelected[0];

        if (!isBulkMode) {
          const {
            data: { fields },
          } = await getAdditionalAttributes({ fieldName, fullyQualifiedName });
          const { attributesList, objectAttributeList } = await getSystemAttributes(fieldName, fullyQualifiedName);

          setSystemAttributes([...attributesList, ...objectAttributeList]);
          setObjectAttributes(fields?.[0]?.attributes || []);
        } else {
          const { attributesList, objectAttributeList } = await getSystemAttributes();
          setSystemAttributes([...attributesList, ...objectAttributeList]);
        }
      } catch ({ message }) {
        notificationService.error(t('errors.fetchingSystemAttributes'));
        console.error(`${t('errors.generic')}: ${message}`);
      } finally {
        setIsLoading(false);
      }
    };
    fetchAttributes();
  }, [isBulkMode, objectsSelected, t]);

  const handleListItemAdd = useCallback(
    ([addedAttribute]: BigidEditableListItemType) => {
      const event = !isBulkMode
        ? CurationEvents.CURATION_FIELDS_ADDTIONAL_ATTRIBUTES_MODIFY_ATRRIBUTE_ADD
        : CurationEvents.CURATION_FIELDS_ADDTIONAL_ATTRIBUTES_MODIFY_ATRRIBUTE_ADD_BULK;
      trackEventCurationView(event);

      const { id: addedAttributeId, __isNew__: isNewAttribute, value } = addedAttribute;

      const attributeToAdd = isNewAttribute
        ? {
            attribute_name: value.trim(),
            attribute_original_name: value.trim(),
            attribute_id: uniqueId(),
          }
        : value;

      if (attributeToAdd) {
        setModifiedAttributes(({ addedAttributes, deletedAttributes }) => {
          remove(deletedAttributes, ({ attribute_id }) => attribute_id === addedAttributeId);

          return {
            addedAttributes: uniqBy([...addedAttributes, { ...attributeToAdd, isNewAttribute }], 'attribute_id'),
            deletedAttributes,
          };
        });
      }
    },
    [isBulkMode],
  );

  const handleListItemDelete = useCallback(
    ([deletedAttribute]: BigidEditableListItemType) => {
      const event = !isBulkMode
        ? CurationEvents.CURATION_FIELDS_ADDTIONAL_ATTRIBUTES_MODIFY_ATRRIBUTE_DELETE
        : CurationEvents.CURATION_FIELDS_ADDTIONAL_ATTRIBUTES_MODIFY_ATRRIBUTE_DELETE_BULK;
      trackEventCurationView(event);
      const { id: deletedAttributeId, __isNew__: isNewAttribute, value } = deletedAttribute;

      if (isNewAttribute) {
        setModifiedAttributes(({ addedAttributes, ...rest }) => {
          remove(addedAttributes, ({ attribute_name }) => attribute_name === value.trim());

          return {
            ...rest,
            addedAttributes,
          };
        });
      } else {
        setModifiedAttributes(({ addedAttributes, deletedAttributes }) => {
          remove(addedAttributes, ({ attribute_id }) => attribute_id === deletedAttributeId);

          return {
            addedAttributes,
            deletedAttributes: uniqBy([...deletedAttributes, value], 'attribute_id'),
          };
        });
      }
    },
    [isBulkMode],
  );

  const handleOnCancelClick = () => {
    onClose();
  };

  const handleOnSaveClick = useCallback(async () => {
    setIsLoading(true);

    try {
      let { addedAttributes, deletedAttributes } = modifiedAttributes;

      if (addedAttributes.length > 0) {
        addedAttributes = getAttributesWithoutTempId(addedAttributes);

        const addedAttributesPayload: ManualField[] = addedAttributes.flatMap(
          ({ attribute_original_name, attribute_type, isNewAttribute, attribute_original_type }) => {
            return objectsSelected.map(({ fieldName, fullyQualifiedName }, index) => {
              return {
                fullyQualifiedName,
                isNewAttribute: isNewAttribute && index == 0,
                type: ManualFieldType.ATTRIBUTE,
                value: attribute_original_name,
                attribute_type,
                attribute_original_type,
                fieldName,
              };
            });
          },
        );

        await updateManualFields(addedAttributesPayload);

        if (isBulkMode) {
          notificationService.success(t('success.bulkAddAttributes'));
        }
      }

      if (!isBulkMode && deletedAttributes.length > 0) {
        const { fieldName, fullyQualifiedName } = objectsSelected[0];

        deletedAttributes = getAttributesWithoutTempId(deletedAttributes);

        const deletedAttributesPayload: ManualField[] = deletedAttributes.map(
          ({ attribute_original_name, attribute_type, attribute_original_type }) => ({
            fullyQualifiedName,
            type: ManualFieldType.ATTRIBUTE,
            value: attribute_original_name,
            attribute_type,
            attribute_original_type,
            fieldName,
          }),
        );

        await deleteManualFields(deletedAttributesPayload);
      }
      if (orderAfterSave) {
        onSave(
          addedAttributes.map(mapSystemAttributeToAdditionalAttribute),
          deletedAttributes.map(mapSystemAttributeToAdditionalAttribute),
        );
      }

      onClose();
    } catch ({ message }) {
      const notificationMessage = isBulkMode ? t('errors.bulkAddAttributes') : t('errors.modifyingAttributes');
      notificationService.error(notificationMessage);
      console.error(`${notificationMessage}: ${message}`);
    } finally {
      setIsLoading(false);
    }
  }, [isBulkMode, modifiedAttributes, objectsSelected, onClose, onSave, orderAfterSave, t]);

  // fixed implicit any during react 18 migration
  const handleOnNewOptionValidating = useCallback((inputValue: string, _value: any, options: any) => {
    return getIsNewAttributeUnique({ inputValue, options });
  }, []);

  return (
    <div className={classes.root} data-aid={dataAid}>
      <div className={classes.content} data-aid={generateDataAid(dataAid, ['content'])}>
        {isLoading ? (
          <BigidLoader dataAid={generateDataAid(dataAid, ['loader'])} />
        ) : (
          <BigidEditableList
            dataAid={generateDataAid(dataAid, ['list'])}
            isCreatable
            inlineEditDisabled
            listItems={listItems}
            title={t('list.title')}
            subTitle={t('list.subTitle')}
            sourceOptions={listItemsSource}
            onAdd={handleListItemAdd}
            onDelete={handleListItemDelete}
            noOptionsMessage={t('list.noOptions')}
            isValidNewOption={handleOnNewOptionValidating}
          />
        )}
      </div>
      <div className={classes.footer} data-aid={generateDataAid(dataAid, ['footer'])}>
        <SecondaryButton
          dataAid={generateDataAid(dataAid, ['cancel'])}
          size="medium"
          disabled={isLoading}
          onClick={handleOnCancelClick}
          bi={{
            eventType: !isBulkMode
              ? CurationEvents.CURATION_FIELDS_ADDTIONAL_ATTRIBUTES_MODIFY_ATRRIBUTE_CANCEL
              : CurationEvents.CURATION_FIELDS_ADDTIONAL_ATTRIBUTES_MODIFY_ATRRIBUTE_CANCEL_BULK,
          }}
          text={t('dialog.cancel')}
        />
        <PrimaryButton
          dataAid={generateDataAid(dataAid, ['save'])}
          size="medium"
          disabled={isLoading}
          onClick={handleOnSaveClick}
          bi={{
            eventType: !isBulkMode
              ? CurationEvents.CURATION_FIELDS_ADDTIONAL_ATTRIBUTES_MODIFY_ATRRIBUTE_SAVE
              : CurationEvents.CURATION_FIELDS_ADDTIONAL_ATTRIBUTES_MODIFY_ATRRIBUTE_SAVE_BULK,
          }}
          text={t('dialog.save')}
        />
      </div>
    </div>
  );
};
