import { module } from 'angular';
const app = module('app');

app.factory('scopesService', [
  '$http',
  'appSettings',
  function ($http, appSettings) {
    return {
      getScopes: () => $http.get(`${appSettings.serverPath}/${appSettings.version}/scopes`).then(response => response),
      getScopeById: id =>
        $http.get(`${appSettings.serverPath}/${appSettings.version}/scopes/${id}`).then(response => response),
      createScope: data =>
        $http.post(`${appSettings.serverPath}/${appSettings.version}/scopes`, data).then(response => response),
      updateScope: (id, data) =>
        $http.put(`${appSettings.serverPath}/${appSettings.version}/scopes/${id}`, data).then(response => response),
      deleteScope: id =>
        $http.delete(`${appSettings.serverPath}/${appSettings.version}/scopes/${id}`).then(response => response),
    };
  },
]);
