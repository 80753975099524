import angular from 'angular';
import { BigidDropdown, BigidDropdownProps } from '@bigid-ui/components';
import { convertToAngular } from '../../../common/services/convertToAngular';

angular
  .module('app')
  .component(
    'classifiersFilterDropdown',
    convertToAngular<BigidDropdownProps>(BigidDropdown, [
      'value',
      'dataAid',
      'size',
      'placeholder',
      'options',
      'onSelect',
      'placeholder',
      'isMulti',
    ]),
  );
