import { useReducer } from 'react';
import { CustomDashboard } from './CustomDashboardManagement';

export interface ReducerState {
  originalDashboardName: string;
  originalDashboardDescription: string;
  originalJsonAsString: string;
  dashboardNameToPreview: string;
  dashboardDescriptionToPreview: string;
  jsonAsStringToPreview: string;
  isLoading: boolean;
  isEditMode: boolean;
  dashboardId: string;
  defaultDashboardId: string;
  dashboards: CustomDashboard[];
}

const initialState: ReducerState = {
  originalDashboardName: '',
  originalDashboardDescription: '',
  originalJsonAsString: '',
  dashboardNameToPreview: '',
  dashboardDescriptionToPreview: '',
  jsonAsStringToPreview: '',
  isLoading: false,
  isEditMode: false,
  dashboardId: '',
  defaultDashboardId: '',
  dashboards: [],
};

export interface Action {
  type?: string;
  payload: Partial<ReducerState>;
}

export const useCustomDashboardGeneralManagerState = () => {
  const [state, dispatch]: [ReducerState, any] = useReducer((state: ReducerState, action: Action) => {
    const { payload } = action;
    return { ...state, ...payload };
  }, initialState);

  return [state, dispatch];
};
