import { BigidFormProps, BigidForm, BigidHeading5 } from '@bigid-ui/components';
import styled from '@emotion/styled';
import React, { FC } from 'react';
import { useLocalTranslation } from '../../translations';

export interface TemplateDetailsFormProps {
  formProps: BigidFormProps;
}

const MainContainer = styled('div')(({ theme }) => ({
  width: '30%',
  display: 'flex',
  flexDirection: 'column',
  borderRight: `solid 1px ${theme.palette.bigid.gray200}`,
}));

const FormWrapper = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  padding: '24px 16px',
  position: 'sticky',
  top: 0,
});

export const TemplateDetailsForm: FC<TemplateDetailsFormProps> = ({ formProps }) => {
  const { t } = useLocalTranslation();
  return (
    <MainContainer>
      <FormWrapper>
        <BigidHeading5 paddingBottom="6px">{t('formHeaders.templateDetails')}</BigidHeading5>
        <BigidForm {...formProps} />
      </FormWrapper>
    </MainContainer>
  );
};
