import { module } from 'angular';
const app = module('app');

app.factory('recordsService', [
  '$http',
  'appSettings',
  function ($http, appSettings) {
    let that = this;
    that.getRecordsDataRequest = {};

    return {
      getRecordsData: function getRecordesData(queryString, isClearCache) {
        queryString = queryString || '';
        if (isClearCache) {
          that.getRecordsDataRequest[queryString] = null;
        }
        if (!that.getRecordsDataRequest[queryString]) {
          that.getRecordsDataRequest[queryString] = $http
            .get(appSettings.serverPath + '/' + appSettings.version + '/piiRecords' + queryString)
            .then(function (response) {
              return response.data;
            });
        }
        return that.getRecordsDataRequest[queryString];
      },

      clearRecordsCache: function () {
        that.getRecordsDataRequest = {};
      },
    };
  },
]);
