import React, { FC } from 'react';
import { BigidBody1, BigidIconType } from '@bigid-ui/components';
import styled from '@emotion/styled';
import { generateDataAid } from '@bigid-ui/utils';

export interface CorrelationSetsRowProps {
  icon: BigidIconType;
  text: string;
  button: any;
  dataAid?: string;
}

const CorrelationSetsRowCard = styled('div')`
  border-radius: 8px;
  height: 64px;
  box-sizing: border-box;
  padding: 16px 24px;
  display: grid;
  grid-template-columns: 35px 1fr 35px;
  align-items: center;
  box-shadow: 0px 0px 5px 0px rgba(67, 38, 77, 0.15);
  gap: 16px;
`;

export const CorrelationSetsRow: FC<CorrelationSetsRowProps> = ({
  icon: Icon,
  text,
  button: Button,
  dataAid = 'CorrelationSetsRow',
}) => {
  return (
    <CorrelationSetsRowCard data-aid={generateDataAid(dataAid, ['container'])}>
      <Icon />
      <BigidBody1 fontWeight={700}>{text}</BigidBody1>
      <Button />
    </CorrelationSetsRowCard>
  );
};
