import { BigidTabsAndContent, BigidTabsAndContentProps } from '@bigid-ui/components';
import makeStyles from '@mui/styles/makeStyles';
import React, { useEffect, useState } from 'react';
import { pageHeaderService } from '../../../../common/services/pageHeaderService';
import { CONFIG } from '../../../../config/common';
import { $state } from '../../../services/angularServices';
import { ActivityFeed } from './ActivityFeed';
import { FollowedObjectsGrid } from './FollowedObjectsGrid';

type RouteType = 'feed' | 'objects';
const ROUTES = ['feed', 'objects'];

const getTabIndex = (path: RouteType) => {
  const tabIndex = ROUTES.indexOf(path);
  return tabIndex > 0 ? tabIndex : 0;
};

const useStyles = makeStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    width: '100%',
  },
  content: {
    minHeight: 0,
  },
});

export const DataCatalogFollowedObjects = () => {
  const classes = useStyles();

  const [selectedTabIndex, setSelectedTabIndex] = useState(getTabIndex($state.params.selectedTab));

  useEffect(() => {
    pageHeaderService.setTitle({
      showBackButton: true,
      breadcrumbs: [
        {
          label: 'Catalog',
          onClick: () => {
            $state.go(CONFIG.states.CATALOG);
          },
        },
        {
          label: 'My Followed Objects',
        },
      ],
    });
  }, []);

  const tabsAndContentProps: BigidTabsAndContentProps = {
    tabProps: {
      selectedIndex: selectedTabIndex,
      tabs: [
        {
          label: 'Activity Feed',
          data: { component: ActivityFeed },
        },
        {
          label: 'Manage Objects',
          data: { component: FollowedObjectsGrid },
        },
      ],
      onChange: tabIndex => {
        $state.go('.', { selectedTab: ROUTES[tabIndex] }, { reload: false, notify: false, inherit: true });
        setSelectedTabIndex(tabIndex);
      },
    },
  };

  return (
    <div className={classes.root}>
      <BigidTabsAndContent {...tabsAndContentProps} classes={{ contentContainer: classes.content }} />
    </div>
  );
};
