import React, { FC } from 'react';
import { BigidInfoIcon, BigidDuplicateIcon, BigidScan3Icon } from '@bigid-ui/icons';
import { BigidButtonIcon, BigidHeading6, BigidTooltip, SecondaryButton } from '@bigid-ui/components';
import { generateDataAid } from '@bigid-ui/utils';
import { ScanTemplateActionType, ScanTemplateGridRow } from '../../../ScanTemplates/scanTemplateTypes';
import makeStyles from '@mui/styles/makeStyles';
import { handleScanTemplateMoreDetailsClick } from '../../../ScanUtils';
import { runCardAction } from '../../../ScanTemplates/scanTemplatesService';
import { isPermitted } from '../../../../../services/userPermissionsService';
import { SCANS_PERMISSIONS } from '@bigid/permissions';
import { ScanTemplateFavoritesIcon } from '../../../ScanTemplates/ScanTemplateFavoritesIcon';

interface CardProps {
  row: ScanTemplateGridRow;
}

const useStyles = makeStyles({
  cardHeader: {
    display: 'flex',
    width: '100%',
    height: '100%',
    flexDirection: 'column',
  },
  cardHeaderInfo: {
    display: 'flex',
    width: '100%',
    height: '100%',
    justifyContent: 'space-between',
    paddingBottom: 8,
  },
  templateName: {
    height: 23,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  cardFooter: {
    height: '100%',
    width: '100%',
    display: 'flex',
    alignItems: 'flex-end',
    '& button': {
      flexGrow: 1,
    },
  },
  cardContentSection: {
    paddingBottom: 12,
    display: 'flex',
    flexDirection: 'column',
  },
  topIcon: {
    display: 'flex',
    height: 32,
    width: 32,
    alignItems: 'center',
  },
  topIconsWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
});

export const CardHeader: FC<CardProps> = ({ row }) => {
  const {
    name,
    scanType,
    description,
    classifiers,
    createdBy,
    updated_at,
    idConnectionList,
    allEnabledIdSor,
    isFavorite,
    _id,
  } = row;
  const { cardHeader, cardHeaderInfo, cardContentSection, templateName, topIcon, topIconsWrapper } = useStyles();
  const handleMoreClick = (event?: React.MouseEvent<Element, MouseEvent>) => {
    event.stopPropagation(); //prevent card select
    handleScanTemplateMoreDetailsClick({
      name,
      scanType,
      description,
      classifiers,
      createdBy,
      updated_at,
      idConnectionList,
      allEnabledIdSor,
      shouldDisplayWarning: true,
    });
  };

  return (
    <div data-aid={generateDataAid('CardHeader', [name])} className={cardHeader}>
      <div className={cardHeaderInfo}>
        <div className={topIcon}>
          <BigidScan3Icon size="xlarge" staticMode />
        </div>
        <div className={topIconsWrapper}>
          <div className={topIcon}>
            <BigidButtonIcon
              icon={BigidInfoIcon}
              size="small"
              dataAid={generateDataAid('templateCardDetails', [name])}
              onClick={handleMoreClick}
            />
          </div>
          {isPermitted(SCANS_PERMISSIONS.CREATE_SCAN_PROFILES.name) && (
            <div className={topIcon}>
              <BigidButtonIcon
                icon={BigidDuplicateIcon}
                dataAid={`duplicate-template-${name}`}
                onClick={event => {
                  event.stopPropagation();
                  return runCardAction(row, ScanTemplateActionType.DUPLICATE);
                }}
                size="small"
              />
            </div>
          )}
          <div className={topIcon}>
            <ScanTemplateFavoritesIcon isFavorite={isFavorite} _id={_id} name={name} />
          </div>
        </div>
      </div>
      <div className={cardContentSection}>
        <BigidTooltip title={name} dataAid={generateDataAid('templateHeaderName', ['tooltip'])}>
          <BigidHeading6 className={templateName}>{name}</BigidHeading6>
        </BigidTooltip>
      </div>
    </div>
  );
};

export const CardFooter: FC<CardProps> = ({ row: { name } }) => {
  const { cardFooter } = useStyles();
  return (
    <div className={cardFooter}>
      <SecondaryButton
        onClick={() => {
          return;
        }}
        size="medium"
        dataAid={generateDataAid('SelectTemplate', [name])}
        text="Select Template"
      />
    </div>
  );
};
