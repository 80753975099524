import React, { MutableRefObject, useEffect, useState } from 'react';
import { BigidTabs, BigidTabsItem } from '@bigid-ui/components';
import styled from '@emotion/styled';
import { getFixedT } from '../translations';
import { CLASSIFIER_TYPES, ClassifierGridRow, REGEX_FORM_TYPES } from '../types/ClassifierTypes';
import { RegexClassifierForm } from '../RegexForm/ClassifiersForm';
import { OOTBChecksumValidation } from '../../../components/ClassifiersChecksumValidation/ClassifiersChecksumValidationDialog/ChecksumValidationTypes';
import { DOCForm } from '../DOCForm/DOCForm';
import { NERForm } from '../NERForm/NERForm';
import { CorrelationDetailsTab } from '../CorrelationForm/CorrelationDetailsTab';
import { CorrelationSetsTab } from '../CorrelationForm/CorrelationSetsTab';
import { isEmpty } from 'lodash';

const translate = getFixedT('sidePanel');

const TabsContainer = styled('div')`
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const TabsBox = styled('div')`
  margin-bottom: 12px;
  padding: 16px 16px 0;
`;

const TabsContentContainer = styled('div')`
  flex: 1;
  overflow: scroll;
`;

const TabsContent = styled('div')`
  padding: 16px 16px 36px 16px;
`;

const DETAILS_TAB: BigidTabsItem = { label: translate('detailTab'), disabled: false };
const CORRELATION_SETS_TAB: BigidTabsItem = { label: 'Correlation Sets', disabled: false };
const TABS = [DETAILS_TAB];

interface ContentProps {
  classifier: ClassifierGridRow;
  formControlsRef: MutableRefObject<any>;
  checksumValidations: OOTBChecksumValidation[];
}

export const Content = ({ classifier, formControlsRef, checksumValidations }: ContentProps) => {
  const [tabs, setTabs] = useState(TABS);
  const [selectedTab, setSelectedTab] = useState(DETAILS_TAB);
  const { classifierType, isOutOfTheBox } = classifier;

  useEffect(() => {
    if (classifier.classifierType === CLASSIFIER_TYPES.CORRELATION) {
      const updatedTabs = [...TABS];
      updatedTabs.push({
        ...CORRELATION_SETS_TAB,
        disabled: isEmpty(classifier.sources) ? true : false,
      });

      setTabs(updatedTabs);
    } else {
      setTabs(TABS);
    }
    setSelectedTab(DETAILS_TAB);
  }, [classifier._id]);

  const onChangeTab = (_tabIndex: number, tab: { label: string }) => {
    setSelectedTab(tab);
  };

  const getSselectedTab = () => {
    return tabs.findIndex(tab => tab.label === selectedTab.label);
  };

  const regexClassifierFormKey = `${classifier.classification_name}_${classifier.version}`;

  return (
    <TabsContainer>
      <TabsBox>
        <BigidTabs tabs={tabs} orientation="horizontal" selectedIndex={getSselectedTab()} onChange={onChangeTab} />
      </TabsBox>
      <TabsContentContainer>
        <TabsContent>
          {selectedTab.label === CORRELATION_SETS_TAB.label && <CorrelationSetsTab classifier={classifier} />}
          {selectedTab.label === DETAILS_TAB.label && (
            <div>
              {REGEX_FORM_TYPES.includes(classifierType) && (
                <RegexClassifierForm
                  key={regexClassifierFormKey}
                  isEdit={true}
                  isOOTB={isOutOfTheBox}
                  formControlsRef={formControlsRef}
                  initialData={classifier}
                  checksumValidations={checksumValidations}
                />
              )}

              {classifierType === CLASSIFIER_TYPES.DOC && (
                <DOCForm key={classifier._id} initialData={classifier} formControlsRef={formControlsRef} />
              )}

              {classifierType === CLASSIFIER_TYPES.CORRELATION && (
                <CorrelationDetailsTab
                  key={classifier._id}
                  initialData={classifier}
                  formControlsRef={formControlsRef}
                />
              )}

              {classifierType === CLASSIFIER_TYPES.NER && (
                <NERForm
                  key={classifier._id}
                  formControlsRef={formControlsRef}
                  initialData={classifier}
                  isEdit={true}
                  isOOTB={isOutOfTheBox}
                />
              )}
            </div>
          )}
        </TabsContent>
      </TabsContentContainer>
    </TabsContainer>
  );
};
