import { APPLICATIONS_PERMISSIONS, getManageBasePermissionNameByTpaName } from '@bigid/permissions';
import { isEqual } from 'lodash';
import { areSomePermitted } from './userPermissionsService';
import { appsLicenseService, AppStatus } from './appsLicenseService';

enum tpaStatuses {
  online = 'online',
  offline = 'offline',
  updateAvailable = 'updateAvailable',
}

interface tpaStatusService {
  isTpaOnline: (status: string) => boolean;
  isTpaOffline: (status: string) => boolean;
  isUpdateAvailable: (status: string) => boolean;
  permitTpaUpdateStatus: (status: string, tpaName: string) => string;
  isTpaLicenseExpired: (status: string) => boolean;
}

const isTpaOnline = (status: string) => isEqual(status, tpaStatuses.online);

const isTpaOffline = (status: string) => isEqual(status, tpaStatuses.offline);

const isTpaLicenseExpired = (appName: string) => {
  return [AppStatus.EXPIRED, AppStatus.TRIAL_EXPIRED].includes(
    appsLicenseService.getAppsLicense().find(appLicense => appLicense.name === appName)?.status,
  );
};

const isUpdateAvailable = (status: string) => isEqual(status, tpaStatuses.updateAvailable);

const permitTpaUpdateStatus = (status: string, tpaName: string) => {
  const managerPermissions = [
    APPLICATIONS_PERMISSIONS.MANAGE_TPA_CUSTOM_APPS.name,
    getManageBasePermissionNameByTpaName(tpaName),
  ];
  return tpaStatusService.isUpdateAvailable(status) && !areSomePermitted(managerPermissions)
    ? tpaStatuses.online
    : status;
};

export const tpaStatusService: tpaStatusService = {
  isTpaOnline,
  isTpaOffline,
  isUpdateAvailable,
  permitTpaUpdateStatus,
  isTpaLicenseExpired,
};
