import {
  BigidExtraField,
  BigidExtraFieldLabelPosition,
  UseExtraFieldControl,
  useExtraFieldControl,
} from '@bigid-ui/components';
import { HeaderFields } from './HeaderFields';
import React, { FC } from 'react';

export const HashiCorpHeaders: FC<UseExtraFieldControl> = ({ initialData, onChange, createNewItem }) => {
  const { onAddNew, onDelete, onChangeItem, data } = useExtraFieldControl({
    initialData,
    createNewItem,
    onChange,
  });

  return (
    <BigidExtraField
      fieldComponent={HeaderFields}
      data={data}
      labelPosition={BigidExtraFieldLabelPosition.top}
      fieldsBottomOffset={12}
      onChangeItem={onChangeItem}
      onDelete={onDelete}
      onAddNew={onAddNew}
      alignCenter
    />
  );
};
