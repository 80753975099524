import React, { FC, memo } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { BigidDropdown, BigidDropdownOption, BigidDropdownValue, BigidHeading6 } from '@bigid-ui/components';
import { MetadataSearchFilterComponentBaseProps } from '../../MetadataSearchFiltersTypes';
import { getMetadataSearchMapKeys } from '../../../MetadataSearchService';
import {
  MetadataSearchFilterObjectModifier,
  MetadataSearchFilterObjectModifierLabelMap,
} from './MetadataSearchFilterObjectUtils';

export type MetadataSearchFilterObjectProps = MetadataSearchFilterComponentBaseProps;

const useStyles = makeStyles({
  root: {
    width: '100%',
  },
  control: {
    padding: '8px',
    width: '100%',
  },
  label: {
    marginBottom: '8px',
  },
});

export const MetadataSearchFilterObject: FC<MetadataSearchFilterObjectProps> = memo(props => {
  const classes = useStyles();
  const { dataAid, config } = props;
  const { multiple, displayName, fieldName } = config;
  const label = displayName || fieldName;

  return (
    <div className={classes.root}>
      <div className={classes.control}>
        <BigidHeading6 data-aid={`${dataAid}-label`} className={classes.label}>
          {label}
        </BigidHeading6>
        {multiple ? <MetadataSearchFilterObjectMulti {...props} /> : <MetadataSearchFilterObjectSingle {...props} />}
      </div>
    </div>
  );
});

export const MetadataSearchFilterObjectSingle: FC<Partial<MetadataSearchFilterObjectProps>> = memo(
  ({ dataAid, config, value: initialValue, onFilterChange, onFilterSearch, isFiltersFetching }) => {
    const { fieldName, fieldType, displayName, values: options } = config;
    const label = displayName || fieldName;
    const isDisabled = options?.length === 0 && !initialValue;
    const genericOptions: BigidDropdownOption[] =
      options?.length === 0 && initialValue
        ? []
        : getMetadataSearchMapKeys(MetadataSearchFilterObjectModifierLabelMap).map(
            (modifier: MetadataSearchFilterObjectModifier) => ({
              id: modifier,
              displayValue: MetadataSearchFilterObjectModifierLabelMap[modifier],
              value: modifier,
            }),
          );
    const selectedValue: BigidDropdownValue = isDisabled
      ? []
      : [
          initialValue?.value
            ? {
                id: String(initialValue.value),
                value: initialValue.value,
                displayValue: String(initialValue.value),
              }
            : genericOptions[0],
        ];
    const isErasable = !isDisabled && selectedValue[0]?.id !== genericOptions[0]?.id;

    const handleOnSelect = (selectedOption: BigidDropdownOption[]): void => {
      if (selectedOption.length > 0) {
        const { value } = selectedOption[0];
        const isAnyValue = value === MetadataSearchFilterObjectModifier.ANY;

        onFilterChange({
          field: fieldName,
          value: isAnyValue ? null : value,
          operator: 'equal',
          fieldType,
          fieldDisplayName: label,
        });
      } else {
        onFilterChange({
          field: fieldName,
          value: null,
          operator: 'equal',
          fieldType,
          fieldDisplayName: label,
        });
      }
    };

    const handleFetchOptions = (query: string) => {
      return onFilterSearch(query, config);
    };

    return (
      <BigidDropdown
        isAsync
        isSearchable
        isErasable={isErasable}
        isDisabled={isDisabled || isFiltersFetching}
        dataAid={`${dataAid}-field`}
        options={[...genericOptions, ...(options || [])]}
        value={selectedValue}
        onSelect={handleOnSelect}
        fetchOptions={handleFetchOptions}
        placeholder={isDisabled ? 'No value' : `Select ${label}`}
        noOptionsMessage="Current selection does not match other filters"
        displayLimit={100}
      />
    );
  },
);

export const MetadataSearchFilterObjectMulti: FC<Partial<MetadataSearchFilterObjectProps>> = memo(
  ({ dataAid, config, value: initialValue, onFilterChange, onFilterSearch, isFiltersFetching }) => {
    const { fieldName, fieldType, displayName, values: options } = config;
    const label = displayName || fieldName;
    const isDisabled = options?.length === 0 && !initialValue;
    const isSearchable = options?.length > 0;
    const selectedValue: BigidDropdownValue = Array.isArray(initialValue?.value)
      ? initialValue.value.map(value => {
          return {
            id: String(value),
            value: value,
            displayValue: String(value),
          };
        })
      : [];

    const handleOnSelect = (selectedOptions: BigidDropdownOption[]): void => {
      onFilterChange({
        field: fieldName,
        value: selectedOptions.map(({ value }) => value),
        operator: 'in',
        fieldType,
        fieldDisplayName: label,
      });
    };

    const handleFetchOptions = (query: string) => {
      return onFilterSearch(query, config);
    };

    return (
      <BigidDropdown
        isAsync
        isSearchable={isSearchable}
        isMulti
        isDisabled={isDisabled || isFiltersFetching}
        dataAid={`${dataAid}-field`}
        options={options}
        value={selectedValue}
        onSelect={handleOnSelect}
        fetchOptions={handleFetchOptions}
        placeholder={isDisabled ? 'No value' : `Select ${label}`}
        noOptionsMessage="Current selection does not match other filters"
        displayLimit={100}
      />
    );
  },
);

MetadataSearchFilterObjectSingle.displayName = 'MetadataSearchFilterObjectSingle';
MetadataSearchFilterObjectMulti.displayName = 'MetadataSearchFilterObjectMulti';
