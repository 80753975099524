import React, { FC, useContext } from 'react';
import { styled } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import { BigidColorsV2, BigidIcon, BigidIconSize } from '@bigid-ui/components';
import { AutoDiscoveryWizardContext } from '../../../autoDiscoveryWizardContext';
import { DsOptionInWizard } from '../../../autoDiscoveryWizardFormUtils';

const AutoDiscoverAsideDsListItem = styled('div')`
  display: flex;
  align-items: center;
  gap: 6px;
`;

const AutoDiscoverAsideDsListWrapper = styled('div')`
  gap: 6px;
  display: flex;
  flex-direction: column;
  padding: 0 16px 16px 16px;
  border-bottom: 1px solid  color: ${BigidColorsV2.gray[500]};
`;

export const AutoDiscoverAsideDsList: FC = () => {
  const { discoveryConfigData } = useContext(AutoDiscoveryWizardContext);

  return (
    <AutoDiscoverAsideDsListWrapper>
      {discoveryConfigData.formData?.dsList
        ?.sort(({ isPermitted }: DsOptionInWizard) => (isPermitted ? 1 : 0))
        .filter((item: DsOptionInWizard) => item.isPermitted)
        .map((item: DsOptionInWizard) => (
          <AutoDiscoverAsideDsListItem key={item.id}>
            <BigidIcon
              size={BigidIconSize.REGULAR_PLUS}
              icon={CheckIcon}
              color={BigidColorsV2.green[600]}
              dataAid={`${item.id}-success`}
            />
            {item.label}
          </AutoDiscoverAsideDsListItem>
        ))}
    </AutoDiscoverAsideDsListWrapper>
  );
};
