import { IMessage } from 'react-chatbot-kit/src/interfaces/IMessages';
import { sessionStorageService } from '../../../common/services/sessionStorageService';
import { DISCLAIMER_MODAL_STATE, PreviousQuestion } from '../../components/Bigchat/BigChatTypes';
import { localStorageService } from '../angularServices';
import { getInitialMessage } from '../../components/Bigchat/config';

const BIGCHAT = 'bigchat';

const BIGCHAT_DISCLAIMER_MODAL_STATE = 'BigChatDisclaimerModalState';

export interface BigchatStorageObject {
  messagesHistory?: IMessage[];
  previousQuestions: PreviousQuestion[];
}

export const loadMessages = () => {
  const bigchatStorageObject: BigchatStorageObject = getBigchatSessionStorage();
  return bigchatStorageObject.messagesHistory;
};

export const saveMessages = (messages: IMessage[]) => {
  const bigchatStorageObject: BigchatStorageObject = getBigchatSessionStorage();
  bigchatStorageObject.messagesHistory = messages;
  sessionStorageService.set(BIGCHAT, JSON.stringify(bigchatStorageObject));
};

export const resetBigchatStorage = () => {
  const bigchatStorageObject: BigchatStorageObject = {
    previousQuestions: [],
    messagesHistory: [getInitialMessage()],
  };
  sessionStorageService.set(BIGCHAT, JSON.stringify(bigchatStorageObject));
};

export const getPreviousQuestions = () => {
  const bigchatStorageObject: BigchatStorageObject = getBigchatSessionStorage();
  return bigchatStorageObject.previousQuestions;
};

export const setPreviousQuestions = (previousQuestions: PreviousQuestion[]) => {
  const bigchatStorageObject: BigchatStorageObject = getBigchatSessionStorage();
  bigchatStorageObject.previousQuestions = previousQuestions;
  sessionStorageService.set(BIGCHAT, JSON.stringify(bigchatStorageObject));
};

export const initBigchatSessionAndLocalStorage = () => {
  const bigchatStorageObject: BigchatStorageObject = getBigchatSessionStorage();
  if (!bigchatStorageObject?.previousQuestions) {
    resetBigchatStorage();
  }
  if (!localStorageService.get(BIGCHAT_DISCLAIMER_MODAL_STATE)) {
    localStorageService.set(BIGCHAT_DISCLAIMER_MODAL_STATE, DISCLAIMER_MODAL_STATE.NOT_SHOWED);
  }
};

export const getBigchatSessionStorage = () => {
  return JSON.parse(sessionStorageService.get(BIGCHAT)) || {};
};

export const getBigchatLocalStorage = () => {
  return localStorageService.get(BIGCHAT_DISCLAIMER_MODAL_STATE);
};

export const setBigchatLocalStorage = (newDisclaimerModalState: DISCLAIMER_MODAL_STATE) => {
  return localStorageService.set(BIGCHAT_DISCLAIMER_MODAL_STATE, newDisclaimerModalState);
};

export const getShouldShowBigChatInHeader = () => {
  let result = sessionStorageService.get('SHOULD_SHOW_BIGCHAT_IN_HEADER');
  if (result == null) {
    sessionStorageService.set('SHOULD_SHOW_BIGCHAT_IN_HEADER', false);
    result = false;
  }
  return result;
};

export const setShouldShowBigChatInHeader = (value: boolean) => {
  return sessionStorageService.set('SHOULD_SHOW_BIGCHAT_IN_HEADER', value);
};
