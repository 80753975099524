import React, { FunctionComponent, useCallback, useEffect, useMemo, useState } from 'react';
import { capitalize } from 'lodash';
import makeStyles from '@mui/styles/makeStyles';
import { LocalOfferOutlined } from '@mui/icons-material';
import { useLocalTranslation } from '../translations';
import {
  BigidIcon,
  BigidPaper,
  QueryParams,
  BigidIconSize,
  BigidContentItem,
  BigidTagBaseProps,
  objectToQueryString,
  BigidEditableChipAreaEntity,
} from '@bigid-ui/components';
import {
  BigidGridRow,
  BigidGridColumn,
  TagsFormatterProps,
  ChipsFormatterProps,
  BigidGridColumnTypes,
  BigidGridWithToolbar,
  BigidGridWithToolbarProps,
  EntitiesCounterFormatterProps,
} from '@bigid-ui/grid';
import { AttributeRanked, getColumnsByObjectName, DataCatalogObjectColumn } from './DataCatalogFieldsService';
import { DataCatalogRecord } from '../DataCatalogService';
import { isPermitted } from '../../../services/userPermissionsService';
import { CATALOG_PERMISSIONS, TAGS_PERMISSIONS } from '@bigid/permissions';
import { notificationService } from '../../../services/notificationService';
import { AttributeMappingDialog, AttributeMappingDialogProps } from './modalDialogEditors/AttributeMappingDialog';
import { ConfidenceLevelExplanation } from '../../../components/ConfidenceLevelExplanation/ConfidenceLevelExplanation';
import { TagsDialog, TagsDialogProps } from './modalDialogEditors/TagsDialog';
import { getApplicationPreference } from '../../../services/appPreferencesService';
import { TagAssignmentTarget } from '../../TagsManagement/TagsManagementService';
import { getTagFormattedName, getTagIcon } from '../../TagsManagement/TagsManagementUtils';
import { getFieldNameDisplayValue } from './utils';

const useStyles = makeStyles({
  root: {
    display: 'flex',
    flexFlow: 'column nowrap',
    width: '100%',
  },
  gridWrapper: {
    display: 'flex',
    padding: '0 5px 5px 5px',
    height: 'calc(100% - 45px)',
  },
  grid: {
    display: 'flex',
    width: '100%',
  },
  columnName: {
    display: 'flex',
    justifyContent: 'space-between',
  },
});

export type ColumnsGridColumn = Omit<DataCatalogObjectColumn, 'tags'> &
  BigidGridRow & {
    columnTags?: TagsFormatterProps;
    attributes?: ChipsFormatterProps;
    linkedColumnsIndicator?: EntitiesCounterFormatterProps;
    columnBusinessAttribute?: ChipsFormatterProps;
  };

export const DataCatalogFields: FunctionComponent<BigidContentItem & DataCatalogRecord> = ({
  fullyQualifiedName,
  source,
  scannerType,
  scanner_type_group,
}) => {
  const { t } = useLocalTranslation('DataCatalogColumns');
  const classes = useStyles({});

  const [attributeMappingDialogState, setAttributeMappingDialogState] = useState<AttributeMappingDialogProps>({
    fullyQualifiedName,
    columnName: undefined,
    isOpen: false,
    scannerType,
  });
  const [tagsDialogState, setTagsDialogState] = useState<TagsDialogProps>({
    columnName: undefined,
    tags: [],
    fullyQualifiedName,
    source,
    isOpen: false,
  });

  const {
    isReadManualAttributesPermitted,
    isEditManualAttributesPermitted,
    isReadTagsPermitted,
    isColumnTagsAssignmentPermitted,
    confidenceLevelExplainTooltipEnabled,
    clusteringEnabled,
  } = useMemo(
    () => ({
      isReadManualAttributesPermitted: isPermitted(CATALOG_PERMISSIONS.READ_MANUAL_FIELDS.name),
      isEditManualAttributesPermitted: isPermitted(CATALOG_PERMISSIONS.EDIT_MANUAL_FIELDS.name),
      isReadTagsPermitted: isPermitted(TAGS_PERMISSIONS.READ.name),
      isColumnTagsAssignmentPermitted: isPermitted(CATALOG_PERMISSIONS.ASSIGN_TAG.name),
      confidenceLevelExplainTooltipEnabled: getApplicationPreference('CONFIDENCE_LEVEL_EXPLAIN_TOOLTIP_ENABLED'),
      clusteringEnabled: getApplicationPreference('CLUSTERING_ENABLED'),
    }),
    [],
  );

  const excludedAttributeTypes = ['ClassificationMd', 'Classification', 'Manual', 'Enrichment Attribute'];

  const getGridData = (data: DataCatalogObjectColumn[], fullyQualifiedName: string): ColumnsGridColumn[] => {
    return data.map((column: DataCatalogObjectColumn, index: number) => {
      const { column_name, attribute_list = [], isPrimary = false, tags = [] } = column;

      const attributes: ChipsFormatterProps = {
        chips: {
          value: attribute_list.map((attribute: AttributeRanked) => {
            const { attribute_name, rank, attribute_id, calc_confidence_level } = attribute;
            const confidenceLevelScore = calc_confidence_level ? (calc_confidence_level * 100).toFixed(0) + '%' : '';
            const confidenceLevelIndication = confidenceLevelScore
              ? `${capitalize(rank)} - ${confidenceLevelScore}`
              : capitalize(rank);
            const chipLabel = confidenceLevelScore ? `${attribute_name} (${confidenceLevelScore})` : attribute_name;
            const shouldDisplayTooltip = confidenceLevelExplainTooltipEnabled && clusteringEnabled;
            const entity: BigidEditableChipAreaEntity = {
              id: attribute_id,
              label: chipLabel,
              title: attribute_name,
              ...(shouldDisplayTooltip && {
                tooltipProps:
                  !excludedAttributeTypes.includes(attribute.attribute_type) &&
                  attribute.calc_confidence_level !== undefined
                    ? {
                        width: '400px',
                        title: (
                          <ConfidenceLevelExplanation
                            item={{
                              fullyQualifiedName,
                              fieldName: column.column_name,
                              attribute_type: attribute.attribute_type,
                              attribute_name: attribute.attribute_name,
                              confidence_level: attribute.calc_confidence_level,
                            }}
                          />
                        ),
                      }
                    : undefined,
              }),
            };

            return entity;
          }),
          //FIXME: only temporarily due to API will support adding a new attributes for Unstructured
          // isDisabled: !isEditManualAttributesPermitted,s
          isDisabled: true,
          placeholder: 'Add attributes',
          showPlaceholderOnlyOnHover: true,
        },
      };

      const columnTags: TagsFormatterProps = {
        tags: {
          value: tags.map(({ tagName, tagValue, tagType, properties }) => ({
            name: getTagFormattedName(tagName),
            value: tagValue,
            iconDescription: tagType === TagAssignmentTarget.column ? tagType : undefined,
            icon: getTagIcon(properties, tagType),
          })),
          isDisabled: !isColumnTagsAssignmentPermitted,
          isAutoFit: false,
          placeholder: 'Add tag',
          showPlaceholderOnlyOnHover: true,
        },
      };

      return {
        id: index,
        attribute_list,
        column_name,
        attributes,
        isPrimary,
      };
    });
  };

  const closeEditAttributeMappingDialog = useCallback(() => {
    setAttributeMappingDialogState(prevState => ({
      ...prevState,
      columnName: undefined,
      title: undefined,
      isOpen: false,
    }));
  }, []);

  const closeTagsDialog = useCallback(() => {
    setTagsDialogState(prevState => ({
      ...prevState,
      columnName: undefined,
      isOpen: false,
    }));
  }, []);

  const attributesColumnIfPermitted: BigidGridColumn<ColumnsGridColumn>[] = isReadManualAttributesPermitted
    ? [
        {
          name: 'attributes',
          title: 'Attributes',
          getCellValue: ({ attributes }) => attributes,
          type: BigidGridColumnTypes.CHIPS,
          width: 400,
          formatter: {
            onClick: ({ value, row, column }) => {
              return new Promise((resolve, reject) => {
                setAttributeMappingDialogState(prevState => {
                  return {
                    ...prevState,
                    columnName: row.column_name,
                    attributes: value.chips.value,
                    isOpen: true,
                    onSubmit: () => {
                      closeEditAttributeMappingDialog();
                      resolve({ row: { ...row, [column.name]: value }, shouldGridReload: true });
                    },
                    onClose: () => {
                      reject();
                    },
                  };
                });
              });
            },
          },
        },
      ]
    : [];

  const columnTagsIfPermitted: BigidGridColumn<ColumnsGridColumn>[] = isReadTagsPermitted
    ? [
        {
          name: 'columnTags',
          title: 'Tags',
          getCellValue: ({ columnTags }) => columnTags,
          type: BigidGridColumnTypes.TAGS,
          width: 400,
          formatter: {
            onClick: ({ value, column, row }) => {
              return new Promise((resolve, reject) => {
                setTagsDialogState(prevState => {
                  return {
                    ...prevState,
                    columnName: row.column_name,
                    tags: row.tags,
                    isOpen: true,
                    onSubmit: (updatedTags: BigidTagBaseProps[], postSubmitMessage: string) => {
                      const newCellValue = {
                        ...value,
                        chips: {
                          ...value.chips,
                          value: updatedTags.map(({ name, value }) => ({
                            label: `${getTagFormattedName(name)} : ${value}`,
                            icon: <BigidIcon icon={LocalOfferOutlined} size={BigidIconSize.REGULAR_PLUS} />,
                          })),
                        },
                      };

                      if (postSubmitMessage) {
                        notificationService.success(postSubmitMessage);
                      }

                      closeTagsDialog();
                      resolve({ row: { ...row, [column.name]: newCellValue }, shouldGridReload: true });
                    },
                    onClose: () => {
                      reject();
                    },
                  };
                });
              });
            },
          },
        },
      ]
    : [];

  const gridConfig: BigidGridWithToolbarProps<ColumnsGridColumn> = useMemo(
    () => ({
      fetchData: async queryComponents => {
        const query = objectToQueryString({
          ...(queryComponents as QueryParams),
          object_name: fullyQualifiedName,
          requireTotalCount: false,
        });
        const { data } = await getColumnsByObjectName(query);

        return {
          totalCount: data?.length,
          data: getGridData(data, fullyQualifiedName),
        };
      },
      entityName: 'items',
      showSortingControls: false,
      columns: [
        {
          name: 'column_name',
          title: 'Field name',
          type: BigidGridColumnTypes.CUSTOM,
          disableTooltip: true,
          getCellValue: ({ column_name }) => {
            return getFieldNameDisplayValue(column_name);
          },
        },

        ...attributesColumnIfPermitted,
      ],
    }),
    [fullyQualifiedName],
  );

  const attributeMappingDialogConfig: AttributeMappingDialogProps = useMemo(
    () => ({
      ...attributeMappingDialogState,
      onClose: closeEditAttributeMappingDialog,
    }),
    [attributeMappingDialogState, closeEditAttributeMappingDialog],
  );

  const tagsDialogConfig: TagsDialogProps = useMemo(
    () => ({
      ...tagsDialogState,
      onClose: closeTagsDialog,
    }),
    [closeTagsDialog, tagsDialogState],
  );

  useEffect(() => {
    setAttributeMappingDialogState({
      fullyQualifiedName,
      columnName: '',
      isOpen: false,
      scannerType,
    });
  }, [fullyQualifiedName, source, scannerType, scanner_type_group]);

  useEffect(() => {
    setTagsDialogState({
      fullyQualifiedName,
      tags: [],
      columnName: undefined,
      isOpen: false,
      source,
    });
  }, [fullyQualifiedName, source]);

  return (
    <div className={classes.root}>
      <div className={classes.gridWrapper}>
        <div className={classes.grid}>
          <BigidPaper>
            <BigidGridWithToolbar key={fullyQualifiedName} {...gridConfig} />
          </BigidPaper>
        </div>
      </div>
      <AttributeMappingDialog {...attributeMappingDialogConfig} />
      <TagsDialog {...tagsDialogConfig} />
    </div>
  );
};
