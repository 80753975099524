import { ChangeEvent, useCallback, useState } from 'react';
import { AzurePolicyType } from './azureRoles';
import { getAzurePolicyJson } from './utils';
import { getFixedT } from '../../../../../../../translations';

const t = getFixedT('General');

export interface AzurePoliciesSelection {
  label: string;
  value: string;
  isSelected: boolean;
  isDisabled: boolean;
}

const policies: AzurePoliciesSelection[] = [
  { label: t('dsTypes.all'), value: AzurePolicyType.ALL, isSelected: true, isDisabled: false },
  { label: t('dsTypes.storageBlobFiles'), value: AzurePolicyType.STORAGE, isSelected: true, isDisabled: true },
  { label: t('dsTypes.db'), value: AzurePolicyType.DB, isSelected: true, isDisabled: true },
  {
    label: t('dsTypes.databricks'),
    value: AzurePolicyType.DATABRICKS,
    isSelected: true,
    isDisabled: true,
  },
];

export const useAzurePolicyGenerator = () => {
  const [policiesSelection, setPoliciesSelection] = useState<AzurePoliciesSelection[]>(policies);
  const [subscriptionId, setSubscriptionId] = useState('');
  const [azurePolicyJson, setAzurePolicyJson] = useState<string>(
    getAzurePolicyJson([AzurePolicyType.ALL], subscriptionId),
  );

  const onPolicySelection = (event: ChangeEvent<HTMLInputElement>, checked: boolean) => {
    const newSelection = [...policiesSelection];
    if (event.target.value === AzurePolicyType.ALL) {
      newSelection.forEach(policy => {
        policy.isSelected = checked;
        if (policy.value !== AzurePolicyType.ALL) {
          policy.isDisabled = checked;
        }
      });
    } else {
      const index = newSelection.findIndex(policy => policy.value === event.target.value);
      newSelection[index] = { ...newSelection[index], isSelected: checked };
    }

    const selectedTypesJson = getAzurePolicyJson(
      newSelection.filter(policy => policy.isSelected && !policy.isDisabled).map(policy => policy.value),
      subscriptionId,
    );
    setAzurePolicyJson(selectedTypesJson);
    setPoliciesSelection(newSelection);
  };

  const updateSubscriptionId = useCallback(
    (newId: string) => {
      setSubscriptionId(newId);
      const updatedJson = getAzurePolicyJson(
        policiesSelection.filter(policy => policy.isSelected && !policy.isDisabled).map(policy => policy.value),
        newId,
      );
      setAzurePolicyJson(updatedJson);
    },
    [policiesSelection],
  );

  return {
    policiesSelection,
    onPolicySelection,
    azurePolicyJson,
    updateSubscriptionId,
    subscriptionId,
  };
};
