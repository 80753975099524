import React, { FC } from 'react';
import { BigidPrimaryCheckbox, BigidBody2 } from '@bigid-ui/components';
import { ExportSupportPackageContentProps } from './types';
import { useLocalTranslation } from './translations';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles({
  note: {
    paddingTop: 10,
  },
});

export const ExportSupportPackageContent: FC<ExportSupportPackageContentProps> = ({
  dialogOptions,
  setDialogOptions,
}) => {
  const classes = useStyles({});
  const { t } = useLocalTranslation('supportPackageDialog');
  const {
    isCheckedSensitiveLogs,
    isCheckedScanRelatedCollections,
    isCheckedScanResultDetails,
    isCheckedConfigurationInfo,
  } = dialogOptions;
  const supportPackageOptions = [
    { name: t('dialogLabels.servicesLogs'), disabled: true, checked: true },
    {
      name: t('dialogLabels.sensitiveLogs'),
      disabled: false,
      checked: isCheckedSensitiveLogs,
      option: 'isCheckedSensitiveLogs',
    },
    {
      name: t('dialogLabels.systemHealth'),
      disabled: true,
      checked: true,
    },
    {
      name: t('dialogLabels.scanRelatedCollections'),
      disabled: false,
      checked: isCheckedScanRelatedCollections,
      option: 'isCheckedScanRelatedCollections',
    },
    {
      name: t('dialogLabels.ScanResult'),
      disabled: false,
      checked: isCheckedScanResultDetails,
      option: 'isCheckedScanResultDetails',
    },
    {
      name: t('dialogLabels.configurationInfo'),
      disabled: false,
      checked: isCheckedConfigurationInfo,
      option: 'isCheckedConfigurationInfo',
    },
  ];

  return (
    <>
      {supportPackageOptions.map(supportOption => (
        <BigidPrimaryCheckbox
          key={supportOption.name}
          disabled={supportOption.disabled}
          label={supportOption.name}
          checked={supportOption.checked}
          onChange={(event, checked) => {
            setDialogOptions({ ...dialogOptions, [supportOption.option]: checked });
          }}
        />
      ))}
      <BigidBody2 className={classes.note}>
        <i>
          <b>{t('note')}:</b> {t('noteContent')}
        </i>
      </BigidBody2>
    </>
  );
};
