import React, { FC } from 'react';
import {
  getInputsNeeded,
  MINIMUM_APPROVAL_NEEDED,
  MINIMUM_REJECTION_NEEDED,
  TuningData,
} from '../../classifierModelUtils';
import ModelIcon from '../../../../../../../assets/icons/ModelIcon.svg';
import { useLocalTranslation } from '../../translations';
import { PrimaryButton, BigidBody1 } from '@bigid-ui/components';
import { generateDataAid } from '@bigid-ui/utils';
import { styled } from '@mui/material';
import { $state } from '../../../../../../../services/angularServices';
import { CONFIG } from '../../../../../../../../config/common';
import { CURATION_ATTRIBUTE_PREFIX } from '../../../../../../Curation/useCurationState';
import { CuratedAttributeType } from '../../../../../../Curation/curationService';

const Card = styled('div')`
  display: flex;
  width: 266px;
  height: 372px;
  padding: 0 16px;
  flex-direction: column;
  align-items: center;
  box-shadow: ${({ theme }) => theme.vars.tokens.bigid.shadow10};
  border-radius: 4px;
  background: ${({ theme }) => theme.vars.palette.bigid.gray50};
  gap: 24px;
`;

const TextWrapper = styled('div')`
  margin-left: 2px;
  text-align: center;
`;

const NewInputs = styled(BigidBody1)`
  color: ${({ theme }) => theme.vars.tokens.bigid.foregroundSecondary};
  font-size: 12px;
  text-align: center;
`;

const ButtonWrapper = styled('div')`
  margin-top: 20px;
  width: 240px;
`;

const MinimumInputs = styled(BigidBody1)`
  font-weight: 700;
`;

const RemainderNeeded = styled(BigidBody1)`
  font-size: 12px;
  font-weight: 700;
`;

const Icon = styled('div')`
  margin-top: 16px;
`;

export interface ClassifierModelInputsNeededProps {
  tuningData: TuningData;
  dataAid?: string;
}

export const ClassifierModelInputsNeeded: FC<ClassifierModelInputsNeededProps> = ({
  tuningData,
  dataAid = 'ClassifierModelInputsNeeded',
}) => {
  const { t } = useLocalTranslation('main');

  const handleClassifierReviewClick = () => {
    $state.go(CONFIG.states.CLASSIFIER_FINDINGS, {
      attributeName: `${CURATION_ATTRIBUTE_PREFIX}${tuningData.classificationName}`,
      attributeType: CuratedAttributeType.CLASSIFIER,
      displayName: tuningData.displayName,
      structuredOnly: true,
    });
  };

  return (
    <Card data-aid={generateDataAid(dataAid, ['inputs-needed', 'card'])}>
      <Icon>
        <ModelIcon />
      </Icon>
      <TextWrapper data-aid={generateDataAid(dataAid, ['card', 'text', 'summary'])}>
        <BigidBody1>
          {t('inputsNeededCard.newModelRequirements.firstPart')}
          <MinimumInputs>{t('inputsNeededCard.newModelRequirements.secondPart')}</MinimumInputs>
          {t('inputsNeededCard.newModelRequirements.thirdPart')}
        </BigidBody1>
      </TextWrapper>

      <>
        <NewInputs>
          {t('inputsNeededCard.currentRemainderNeeded.firstPart')}
          <RemainderNeeded>
            {t('inputsNeededCard.currentRemainderNeeded.secondPart', {
              approvedCount: getInputsNeeded(tuningData.newApproves, MINIMUM_APPROVAL_NEEDED),
              rejectedCount: getInputsNeeded(tuningData.newRejects, MINIMUM_REJECTION_NEEDED),
            })}
          </RemainderNeeded>
          {t('inputsNeededCard.currentRemainderNeeded.thirdPart')}
        </NewInputs>
        <ButtonWrapper data-aid={generateDataAid(dataAid, ['button', 'review'])}>
          <PrimaryButton onClick={handleClassifierReviewClick} size="medium" text={t('buttons.review')} width="fill" />
        </ButtonWrapper>
      </>
    </Card>
  );
};
