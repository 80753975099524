import React, { FC } from 'react';
import { styled } from '@mui/material';
import { BigidTooltip } from '@bigid-ui/components';
import { generateDataAid } from '@bigid-ui/utils';
import { DataSourceGridRecord } from './DataSourcesLayout';
import { DataSourceIconByDsType } from '@bigid-ui/icons';

interface DataSourceLayoutNameCellProps {
  dataAid?: string;
  row: DataSourceGridRecord;
}

const Root = styled('div')`
  width: 100%;
  height: 100%;
`;

const CellContent = styled('div')`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
`;

const DataSourceName = styled('div')`
  flex: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-left: 8px;
`;

export const DataSourceLayoutNameCell: FC<DataSourceLayoutNameCellProps> = ({
  dataAid = 'DataSourceLayoutNameCell',
  row,
}) => {
  const { id, aggItemName, sourceType } = row;

  return (
    <Root data-aid={generateDataAid(dataAid, ['sourceType', id, sourceType])}>
      <BigidTooltip title={aggItemName} isDisabled={!sourceType}>
        <CellContent>
          <DataSourceIconByDsType
            data-aid={generateDataAid(dataAid, ['icon', sourceType])}
            dataSourceType={sourceType}
            width={32}
            height={32}
          />
          <DataSourceName data-aid={generateDataAid(dataAid, ['name', sourceType])}>{aggItemName}</DataSourceName>
        </CellContent>
      </BigidTooltip>
    </Root>
  );
};
