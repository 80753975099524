import React, { FC, useEffect, useMemo } from 'react';
import { useLocalTranslation } from '../translations';
import { BigidBody1, BigidHeading6 } from '@bigid-ui/components';
import { Collaboration } from '../mappers/styles';
import { BigidGrid } from '@bigid-ui/grid';
import { useLayout } from '@bigid-ui/layout';
import { CollaborationFieldAction, createGridConfig } from '../config/collaboration';
import { mapDataSourceCollaboratorToBigidGridDataFetchResult } from '../mappers/collaboration';
import { DataSourcesUITrackingEvent, trackEventDataSources } from '../../DataSourcesEventTrackerUtils';
import type { CollaboratorGridRow, DataSourceCollaborator } from '../types';
import { GetDsCollaboratorsQueryError } from '../hooks/useGetDsCollaborators';
import { useGetUserRolesWithScope } from '../hooks/ueGetUserRoles';
import { QueryObserverResult, RefetchOptions, RefetchQueryFilters } from 'react-query';

const NO_ROWS = 0;
const style = { display: 'inline-flex', alignItems: 'center', gap: '0.1rem' };

export interface CollaboratorsListProps {
  dsTypeLabel: string;
  collaborators: DataSourceCollaborator[];
  sourceId?: string;
  isLoadingCollaborators: boolean;
  selectedTemplateName?: string;
  selectedBrand?: string;
  refetchCollaborators: <TPageData>(
    options?: RefetchOptions & RefetchQueryFilters<TPageData>,
  ) => Promise<QueryObserverResult<DataSourceCollaborator[], GetDsCollaboratorsQueryError>>;
}

export const CollaboratorsList: FC<CollaboratorsListProps> = ({
  sourceId,
  dsTypeLabel,
  collaborators,
  refetchCollaborators,
  isLoadingCollaborators,
  selectedTemplateName,
  selectedBrand,
}) => {
  const { t } = useLocalTranslation();
  const { data: roles } = useGetUserRolesWithScope();

  const {
    gridId,
    rows,
    isLoading,
    apiRef,
    refresh: refreshGrid,
    ...rest
  } = useLayout<CollaboratorGridRow>('DataSourceCollaboration', {
    fetchDataFunction: async () => mapDataSourceCollaboratorToBigidGridDataFetchResult(collaborators),
    initialSorting: [{ field: 'name', order: 'asc' }],
  });

  const handleGridAction = () => refetchCollaborators();

  const handleRevoke = (...args: unknown[]) => {
    const [email] = args ?? [];

    trackEventDataSources(DataSourcesUITrackingEvent.DS_COLLABORATION_DELETE_COLLABORATOR_CLICK, {
      collaborators: email ? [email] : [],
    });
    handleGridAction();
  };

  const gridActions = {
    [CollaborationFieldAction.RESEND]: handleGridAction,
    [CollaborationFieldAction.UPDATE]: handleGridAction,
    [CollaborationFieldAction.REVOKE]: handleRevoke,
  } as const;

  const gridConfig = useMemo(
    () =>
      createGridConfig(
        gridId,
        sourceId,
        dsTypeLabel,
        {
          ...rest,
          rows,
          isLoading: isLoadingCollaborators || isLoading,
          apiRef,
        },
        roles,
        t,
        gridActions,
        selectedTemplateName,
        selectedBrand,
      ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [gridId, sourceId, dsTypeLabel, isLoading, isLoadingCollaborators, roles, selectedTemplateName, selectedBrand],
  );

  useEffect(() => {
    const shouldRefreshGrid = !isLoadingCollaborators;
    shouldRefreshGrid && refreshGrid();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoadingCollaborators]);

  useEffect(() => {
    sourceId && refetchCollaborators({ cancelRefetch: false });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Collaboration.List>
      <BigidHeading6 style={style}>
        {t('collaboration.grid.header')}
        <BigidBody1>{t('collaboration.grid.count', { count: rows?.length ?? NO_ROWS })}</BigidBody1>
      </BigidHeading6>
      <Collaboration.Grid className="DataSourceConnectionCollaborationGrid">
        <BigidGrid {...gridConfig} />
      </Collaboration.Grid>
    </Collaboration.List>
  );
};
