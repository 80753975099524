import { BigidApplicationsIcon, BigidDataSource2Icon, BigidFileIcon, BigidTableColumnsIcon } from '@bigid-ui/icons';
import { compareNumber, OrderOption } from '../../../utilities/compareNumber';
import { CuratedDataSource, SamplingStatus, ScannerTypeGroup } from '../curationService';
import { EntityEventPayload } from '@bigid-ui/components';
import { BigidGridUpdateRowByIdPayload } from '@bigid-ui/grid';
import { CuratedDataSourcesGridRecord } from './useCuratedDataSourcesConfig';
import React from 'react';

enum SortingCategory {
  SOURCES_WITH_CURATED_ATTRIBUTES = 0,
  SOURCES_WITHOUT_CURATED_ATTRIBUTES = 1,
  SOURCES_WITHOUT_TOTAL_ATTRIBUTES = 2,
  SAMPLING_STATUS_NEVER_SAMPLED = 3,
  SAMPLING_STATUS_RUNNING = 4,
  SAMPLING_STATUS_NOT_STARTED = 5,
  SAMPLING_STATUS_ERROR = 6,
  OTHER_STATUSES = 7,
  NOT_SUPPORTED = 8,
}

export const getCuratedDataSourcesSorted = (sources: CuratedDataSource[]) => {
  const getCategoryDisplayPlace = (source: CuratedDataSource) => {
    if (!source.isSupported) {
      return SortingCategory.NOT_SUPPORTED;
    }

    switch (source.samplingStatus) {
      case SamplingStatus.COMPLETED:
        if (source.curatedAttributes > 0) {
          return SortingCategory.SOURCES_WITH_CURATED_ATTRIBUTES;
        } else if (source.totalAttributes === 0) {
          return SortingCategory.SOURCES_WITHOUT_TOTAL_ATTRIBUTES;
        } else if (source.curatedAttributes === 0) {
          return SortingCategory.SOURCES_WITHOUT_CURATED_ATTRIBUTES;
        }
        break;
      case SamplingStatus.NEVER_SAMPLED:
        return SortingCategory.SAMPLING_STATUS_NEVER_SAMPLED;
      case SamplingStatus.RUNNING:
        return SortingCategory.SAMPLING_STATUS_RUNNING;
      case SamplingStatus.NOT_STARTED:
        return SortingCategory.SAMPLING_STATUS_NOT_STARTED;
      case SamplingStatus.ERROR:
        return SortingCategory.SAMPLING_STATUS_ERROR;
      default:
        return SortingCategory.OTHER_STATUSES;
    }
  };

  const compareTwoSources = (source1: CuratedDataSource, source2: CuratedDataSource) => {
    const firstSource = getCategoryDisplayPlace(source1);
    const secondSource = getCategoryDisplayPlace(source2);

    if (firstSource === secondSource) {
      return compareNumber(source1.totalFindings, source2.totalFindings, OrderOption.ASC);
    }
    return compareNumber(firstSource, secondSource, OrderOption.DESC);
  };

  return sources.sort(compareTwoSources);
};

const BigidStructuredIcon = () => {
  return <BigidTableColumnsIcon size="large" />;
};

const BigidUnstructuredIcon = () => {
  return <BigidFileIcon size="large" />;
};

const BigidAppsIcon = () => {
  return <BigidApplicationsIcon size="large" />;
};

export const getDataSourceTypeAvatar = (scannerTypeGroup: ScannerTypeGroup) => {
  switch (scannerTypeGroup) {
    case ScannerTypeGroup.STRUCTURED:
      return {
        icon: BigidStructuredIcon,
        name: ScannerTypeGroup.STRUCTURED,
      };
    case ScannerTypeGroup.UNSTRUCTURED:
      return {
        icon: BigidUnstructuredIcon,
        name: ScannerTypeGroup.UNSTRUCTURED,
      };
    case ScannerTypeGroup.EMAIL:
      return {
        icon: BigidAppsIcon,
        name: 'Apps',
      };
    default:
      return {
        icon: BigidDataSource2Icon,
        name: 'Data Source',
      };
  }
};

export function getUpdateRowByIdEventPayload(
  gridId: string,
  rowId: string,
  payload: Partial<CuratedDataSourcesGridRecord>,
): EntityEventPayload<BigidGridUpdateRowByIdPayload> {
  return {
    entityId: gridId,
    payload: {
      rowId,
      row: payload,
    },
  };
}
