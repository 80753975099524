import { $state } from '../../services/angularServices';
import { isPermitted } from '../../services/userPermissionsService';
import {
  APPLICATIONS_PERMISSIONS,
  BUSINESS_FLOWS_PERMISSIONS,
  CATALOG_PERMISSIONS,
  CLASSIFIERS_PERMISSIONS,
  DASHBOARD_PERMISSIONS,
  DATA_SOURCES_PERMISSIONS,
  DSAR_PERMISSIONS,
  INVENTORY_PERMISSIONS,
  POLICIES_PERMISSIONS,
  SCANS_PERMISSIONS,
} from '@bigid/permissions';

import DataSourceIcon from '../../assets/icons/BigidDigestReportDS.svg';
import DSARIcon from '../../assets/icons/BigidDigestReportDSAR.svg';
import EntitiesIcon from '../../assets/icons/BigidDigestReportEntities.svg';
import PoliciesIcon from '../../assets/icons/BigidDigestReportPolicies.svg';
import RecordsIcon from '../../assets/icons/BigidDigestReportRecords.svg';
import ObjectsIcon from '../../assets/icons/BigidDigestReportObjects.svg';

const OVERVIEW_ICONS = [DataSourceIcon, ObjectsIcon, PoliciesIcon, DSARIcon, EntitiesIcon, RecordsIcon];

export const onReportItemClick = (to: string, params: any) => (): null => {
  $state.go(to, params || {});
  return null;
};

export const getOptionsForDigestReport = () => {
  const permitted = {
    'DASHBOARD_PERMISSIONS.ACCESS.name': isPermitted(DASHBOARD_PERMISSIONS.ACCESS.name),
    'DATA_SOURCES_PERMISSIONS.ACCESS.name': isPermitted(DATA_SOURCES_PERMISSIONS.ACCESS.name),
    'SCANS_PERMISSIONS.ACCESS.name': isPermitted(SCANS_PERMISSIONS.ACCESS.name),
    'BUSINESS_FLOWS_PERMISSIONS.ACCESS.name': isPermitted(BUSINESS_FLOWS_PERMISSIONS.ACCESS.name),
    'CLASSIFIERS_PERMISSIONS.ACCESS.name': isPermitted(CLASSIFIERS_PERMISSIONS.ACCESS.name),
    'APPLICATIONS_PERMISSIONS.READ_RISK_CONFIGURATION.name': isPermitted(
      APPLICATIONS_PERMISSIONS.READ_RISK_CONFIGURATION.name,
    ),
    'POLICIES_PERMISSIONS.ACCESS.name': isPermitted(POLICIES_PERMISSIONS.ACCESS.name),
    'CATALOG_PERMISSIONS.ACCESS.name': isPermitted(CATALOG_PERMISSIONS.ACCESS.name),
    'INVENTORY_PERMISSIONS.ACCESS.name': isPermitted(INVENTORY_PERMISSIONS.ACCESS.name),
    'DSAR_PERMISSIONS.ACCESS.READ_REQUEST':
      isPermitted(DSAR_PERMISSIONS.ACCESS.name) && isPermitted(DSAR_PERMISSIONS.READ_REQUEST.name),
  };

  return {
    permitted,
    isDataCatalogEnabled: isPermitted(CATALOG_PERMISSIONS.ACCESS.name),
    overviewIcons: OVERVIEW_ICONS,
  };
};
