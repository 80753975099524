import { analyticsService } from '../../services/analyticsService';

export enum CatalogRulesEvents {
  COMPOUND_ATTRIBUTE_NEW_RULE = 'COMPOUND_ATTRIBUTE_NEW_RULE ',
  COMPOUND_ATTRIBUTE_DELETE = 'COMPOUND_ATTRIBUTE_DELETE ',
  COMPOUND_ATTRIBUTE_SAVE = 'COMPOUND_ATTRIBUTE_SAVE ',
  COMPOUND_ATTRIBUTE_APPLY = 'COMPOUND_ATTRIBUTE_APPLY ',
  COMPOUND_ATTRIBUTE_ENABLED = 'COMPOUND_ATTRIBUTE_ENABLED ',
  COMPOUND_ATTRIBUTE_UPDATE_ATTRIBUTE_NAME = 'COMPOUND_ATTRIBUTE_UPDATE_ATTRIBUTE_NAME ',
  COMPOUND_ATTRIBUTE_UPDATE_RULE_NAME = 'COMPOUND_ATTRIBUTE_UPDATE_RULE_NAME ',
  COMPOUND_ATTRIBUTE_EDIT_RULE = 'COMPOUND_ATTRIBUTE_EDIT_RULE ',
  COMPOUND_ATTRIBUTE_DUPLICATE = 'COMPOUND_ATTRIBUTE_DUPLICATE ',
  COMPOUND_ATTRIBUTE_SELECT_RULE = 'COMPOUND_ATTRIBUTE_SELECT_RULE ',
}

export const trackEventCatalogRules = (eventType: CatalogRulesEvents, data?: Record<string, unknown>) => {
  analyticsService.trackManualEvent(eventType as any, data);
};
