import { useLocalTranslation } from '../translations';
import { BigidLayoutEmptyState } from '@bigid-ui/layout';
import { BigidRiskAlertIllustration } from '@bigid-ui/icons';
import { generateDataAid } from '@bigid-ui/utils';
import React from 'react';

export const RequestFailedEmptyState = ({ dataAid = 'similarTables' }) => {
  const { t } = useLocalTranslation();
  return (
    <BigidLayoutEmptyState
      illustration={BigidRiskAlertIllustration}
      description={t('emptyStates.requestFailed.description')}
      dataAid={`${generateDataAid(dataAid, ['requestFailed', 'emptyState'])}`}
    />
  );
};
