import { BigidGridRow } from '@bigid-ui/grid';
import { ParentScanType, ProgressType, ScanState } from '../ScanTypes';
import { ReactElement, ReactText } from 'react';
import { PieChartData } from '@bigid-ui/components';

export interface SubScanGridRow extends BigidGridRow {
  name: string;
  updatedAt: string;
  type: SubscanStage;
  state: ScanState;
  parts: Parts;
  isPauseAvailable: boolean;
  isStopAvailable: boolean;
  isResumeAvailable: boolean;
  isRetryAvailable: boolean;
  parentScanId: string;
  errorMessage?: string;
  startedAt: string;
  isFailedObjects?: boolean;
  isRbacPermitted: boolean;
  enumerationStatus?: string;
  isDifferential?: boolean;
}

export enum SubscanStage {
  IDENTITY_SUB_SCAN = 'identity_sub_scan',
  CALC_FIELD_IDENTIFIABILITY_SUB_SCAN = 'calc_field_identifiability_subscan',
  PREPARE_IDENTITY_FILES = 'prepare_identity_files',
  DATA_SOURCE_SCAN = 'sub_scan',
  CLUSTERING = 'clustering',
  LINEAGE_BASE_TREE = 'lineage_base_tree',
  PREDICTION_SCAN = 'prediction_scan',
  TAGGING_SCAN = 'tagging_scan',
  IMPORT_MIP_LABELS = 'import_mip_labels',
  HOTSPOTS = 'hotspots_scan',
}

export enum EnumerationStatuses {
  STARTED = 'Started',
  Failed = 'Failed',
  Completed = 'Completed',
  Stopped = 'Stopped',
}

export interface Attributes {
  total: number;
  newlyAdded: number;
  falseRemoved: number;
}

export interface Parts {
  total: number;
  complete: number;
  failed: number;
}
export interface BasicPartsStates {
  totalParts: number;
  partsCompleted: number;
  partsFailed: number;
  partsInProgress: number;
  partsNew: number;
  partsQueued: number;
}

export interface AllScanPartStates extends Omit<BasicPartsStates, 'partsNew'> {
  partsAborted: number;
  partsStopped: number;
}

export interface ScanDetailsParts extends AllScanPartStates {
  objectsScanned: number;
  objectsFailed: number;
  objectsSkipped: number;
  totalFindings: number;
}

export interface ScanProfileInfo {
  _id: string;
  name: string;
  progress: ProgressType;
  scanProfileId: ReactText;
  startedAt: string;
  completedAt: string;
  updatedAt: string;
  previousScanCompleted: string;
  runningTime: string;
  type: ParentScanType;
  nextScheduledRun?: string;
  isActive?: boolean;
  description?: string;
  isReviewFindingsEnabled?: boolean;
  scanTemplateId?: string;
  scanTemplateName?: string;
}

export interface ScanInsightsInfo {
  scannedDataSources: number;
  dataSourcesWithPi?: number;
  objectsWithPi?: number;
  failed: number;
  objectsScanned: number;
  objectsEnumerated?: number;
  objectsFailed?: number;
  objectsSkipped?: number;
  skipped?: number;
  changeFromPreviousScan: number;
  attributes?: Attributes;
  classifications?: Attributes;
  allEnabledDs?: boolean;
  dataSourceNames?: string[];
  csAttributeNames?: string[];
  classifierAttributeNames?: string[];
  dataSourceCompleted: number;
}

export interface SubScansInfo {
  completedSubScans: number;
  failedSubScans: number;
  totalSubScans: number;
  completedParts: number;
  failedParts: number;
  totalPartsScanned: number;
}

export interface ScanInsightsData {
  scanProfile: ScanProfileInfo;
  dataSources?: ScanInsightsInfo;
  subScans: SubScansInfo;
}

export interface ScanChildInfo {
  _id: string;
  name: string;
  updatedAt: string;
  parentScanId: string;
  scannerIds: string[];
  scannerGroup: string;
  startedAt: string;
  endedAt: string;
  nextScheduledRun?: string;
  enumerationScannerId: string;
  numberOfThreads: number;
  Scan: ScanDetailsParts;
  Classification: BasicPartsStates;
  Correlation: BasicPartsStates;
  Summary: BasicPartsStates;
  type: string;
  totalCount?: number;
  isFailedObjectReportEmpty: boolean;
  isScanPartsReportEmpty: boolean;
  enumerationDetails: {
    status: string;
    objectsEnumerated: number;
    lastUpdatedAt: string;
  };
  parentScanType: string;
  errorMessageStack: string[];
  errorMessage?: string;
  state?: string;
}

export type DatasourceScanInsightsInfo = ScanInsightsInfo &
  Pick<ScanProfileInfo, 'isReviewFindingsEnabled'> & {
    shouldShowAttributes: boolean;
    updatedAt: string;
    isScanComplete: boolean;
    scanId: string;
    scanProfileName: string;
  };

export interface DataSourceDetails {
  _id: string;
  source: string;
  state: string;
  totalObjects: number;
  totalObjectsWithPii: number;
  totalFailedObjects?: number;
  totalSkippedObjects?: number;
}

export interface AttributeDetails {
  _id: string;
  name: string;
  new?: number;
  removed?: string;
}

export interface GetDataSourcesDetailsByParentIdResponse {
  dataSourcesInsights: DataSourceDetails[];
  totalCount: number;
}

export interface GetAttributesDetailsByParentIdResponse {
  dataSourcesInsights: AttributeDetails[];
  totalCount: number;
}

export interface GetAttributesDetailsByParentIdParams {
  parentId: string;
  type?: string;
  gridConfigQuery: string;
}

export interface States {
  totalParts?: number;
  partsCompleted?: number;
  partsFailed?: number;
  partsInProgress?: number;
  completed?: number;
  failed?: number;
  inProgress?: number;
}

export interface PieChartSectionObject {
  type: string;
  title: string;
  data: PieChartData<string>[];
  total: number;
  disableFiltering: boolean;
}

export interface PieChartSectionData {
  dataAid?: string;
  data: PieChartData<string>[];
  total: number;
  title?: string;
  withTooltips: boolean;
  showZeroedTotal: boolean;
  showZeroedData: boolean;
  entityName: string;
  disableFiltering: boolean;
  dataAidLabels?: string;
  insightSubTitleContent?: ReactElement;
}

export interface IMetricsByDays {
  parentScansMap: {
    completed: number;
    failed: number;
    inProgress: number;
  };
  subScansMap: {
    completed: number;
    failed: number;
    inProgress: number;
  };
  dataSourcesMap: {
    completed: number;
    failed: number;
    inProgress: number;
  };
}
