import React from 'react';
import { BigidBody1, BigidDialog } from '@bigid-ui/components';
import { BigidInfoIcon } from '@bigid-ui/icons';
import { styled } from '@mui/material';
import { HiddenAxis, RiskMatrix } from './RiskMatrix';
import { RiskMatrixMetadata } from '../RiskMatrixDefaults';

const ImageContainer = styled('div')({
  display: 'flex',
});

export interface ImpactLevelInfoProps {
  isOpen: boolean;
  riskMatrix: RiskMatrixMetadata;
  onClose: () => void;
}

export const ImpactLevelInfo: React.FC<ImpactLevelInfoProps> = ({ isOpen, riskMatrix, onClose }) => {
  return (
    <BigidDialog
      isOpen={isOpen}
      onClose={onClose}
      title={riskMatrix.impactLabel}
      borderTop
      icon={BigidInfoIcon}
      maxWidth={'md'}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <BigidBody1>
          {`${riskMatrix.impactLabel} is often defined as the consequences or effects of a risk event on the project objectives.`}
        </BigidBody1>
        <ImageContainer>
          <RiskMatrix {...riskMatrix} hideAxis={HiddenAxis.Y} />
        </ImageContainer>
      </div>
    </BigidDialog>
  );
};
