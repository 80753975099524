import { notificationService } from '../../../../services/notificationService';
import { useGetDsCollaborators } from './useGetDsCollaborators';
import { useUpdateDsCollaborator } from './useUpdateDsCollaborator';
import { useLocalTranslation } from '../translations';
import { isDsCollaborationEnabled } from '../../../../utilities/featureFlagUtils';

export const useCollaboration = (sourceId: string) => {
  const { t } = useLocalTranslation();

  const query = useGetDsCollaborators(sourceId, null, {
    query: {
      select: response => ({ users: response?.data?.collaborators ?? [], owner: response?.data?.creator?.name }),
      enabled: !!sourceId && isDsCollaborationEnabled(),
    },
  });

  const mutation = useUpdateDsCollaborator({
    mutation: {
      onError: () => notificationService.error(t('collaboration.updateError')),
      onSuccess: () => notificationService.success(t('collaboration.updateSuccess')),
    },
  });

  return { query, mutation };
};
