import React, { FC } from 'react';
import angular from 'angular';
import { convertToAngular } from '../../../common/services/convertToAngular';
import { BannerComponent, BigidColorsV2 } from '@bigid-ui/components';
import { sessionStorageService } from '../../../common/services/sessionStorageService';
import { SystemEvents, systemEventsEmitter } from '../../services/systemEvents';

const onCloseClick = ({ sessionKey, eventName }: OnCloseClickProps) => {
  sessionStorageService.set(sessionKey, false);
  systemEventsEmitter.emit(eventName);
};

interface OnCloseClickProps {
  sessionKey: string;
  eventName: SystemEvents;
}

interface ChangeSecretKeyNoticeBarProps {
  sessionKey?: string;
  eventName?: SystemEvents;
  reason?: string;
  isAuthKey?: boolean;
}

const DEFAULT_KEY = 'SHOW_SECRET_KEY_BANNER';

const getMessage = (isAuthKey: boolean, reason?: string) =>
  `The system's ${isAuthKey ? 'AUTH ' : ''}SECRET KEY is ${
    !reason ? 'weak or is the default value' : reason === 'weak' ? 'weak' : 'default value'
  }. Change it before proceeding.`;

export const ChangeSecretKeyNoticeBar: FC<ChangeSecretKeyNoticeBarProps> = ({
  sessionKey = DEFAULT_KEY,
  eventName = SystemEvents.USER_CLOSED_SECRET_KEY_BAR,
  reason = '',
  isAuthKey = false,
}) => (
  <BannerComponent background={BigidColorsV2.red[900]} onClose={() => onCloseClick({ sessionKey, eventName })}>
    {getMessage(isAuthKey, reason)}
  </BannerComponent>
);

angular
  .module('app')
  .component(
    'changeSecretKeyNoticeBar',
    convertToAngular(ChangeSecretKeyNoticeBar, ['reason', 'isAuthKey', 'sessionKey', 'eventName']),
  );
