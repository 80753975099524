import React, { useState } from 'react';
import {
  AccordionSummarySizeEnum,
  BigidAccordion,
  BigidAccordionDetails,
  BigidAccordionSummary,
  BigidBody1,
  BigidFieldRenderProps,
  BigidLink,
  BigidTextFieldAutocomplete,
  BigidTextFieldAutocompleteProps,
} from '@bigid-ui/components';
import { useLocalTranslation } from '../../hooks/useLocalTranslation';
import styled from '@emotion/styled';

export type CollapsibleTextareaFieldProps = BigidFieldRenderProps<unknown, string> & {
  dataAid?: string;
  message?: string;
  link?: string;
  field?: Partial<BigidTextFieldAutocompleteProps>;
  onExpand?: () => void;
  onChange?: (content: string) => void;
};

export const Message = styled.div`
  width: 100%;

  & .MuiAccordionDetails-root {
    padding: 0;
  }

  & button {
    font-weight: normal;
  }
`;

export const Field = styled.div`
  & [data-aid='BigidDropdown-bar'] {
    & > div {
      min-height: 34px !important;
    }
  }
`;

const style = { boxShadow: 'none', border: 'none' };

export const CollapsibleTextareaField = ({
  dataAid = 'CollapsibleTextareaField',
  setValue,
  value,
  message,
  link,
  field: overrides,
  onExpand,
  onChange,
}: CollapsibleTextareaFieldProps): JSX.Element => {
  const [expanded, setExpanded] = useState(false);
  const { t } = useLocalTranslation();

  const handleChange = (content: string) => {
    setValue(content);
    onChange?.(content);
  };

  const handleExpand = () => {
    setExpanded(true);
    onExpand?.();
  };

  return (
    <Message>
      <BigidBody1 style={{ paddingLeft: '0.5rem' }}>
        {message ?? t('collaboration.message')}
        {link && <BigidLink underline="none" onClick={handleExpand} text={link ?? t('collaboration.link')} />}
      </BigidBody1>
      <BigidAccordion style={style} expanded={expanded}>
        <BigidAccordionSummary hidden size={AccordionSummarySizeEnum.normal} />
        <BigidAccordionDetails>
          <Field>
            <BigidTextFieldAutocomplete
              dataAid={dataAid}
              value={value}
              autocompleteListItems={[]}
              shouldEvalOnChangeWhenInit={false}
              rows={8}
              multiline
              onChange={handleChange}
              {...overrides}
            />
          </Field>
        </BigidAccordionDetails>
      </BigidAccordion>
    </Message>
  );
};
