import React, { FC, useCallback, useEffect, useState } from 'react';
import {
  BigidDialog,
  PrimaryButton,
  SecondaryButton,
  BigidDialogProps,
  BigidSelect,
  BigidSelectOption,
} from '@bigid-ui/components';
import { Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { debounce } from 'lodash';
import { systemUsersService } from '../../../services/angularServices';
import { getUsersQuery, convertUsersToOptions, User } from '../../../utilities/systemUsersUtils';

export interface ReassignDialogProps extends Pick<BigidDialogProps, 'isOpen' | 'onClose'> {
  onReassign: (assignee: string) => void;
  assignees: User[];
}

const useStyles = makeStyles({
  title: {
    marginBottom: 8,
  },
});

export const ReassignDialog: FC<ReassignDialogProps> = ({ isOpen, onClose, onReassign, assignees }) => {
  const classes = useStyles({});
  const [selectedAssignee, setSelectedAssignee] = useState<string>(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [initialAssignees, setInitialAssignees] = useState<BigidSelectOption[]>([]);

  const buttons = [
    { component: SecondaryButton, text: 'Cancel', onClick: onClose },
    {
      component: PrimaryButton,
      text: 'Reassign',
      onClick: () => {
        onReassign(selectedAssignee);
        onClose();
      },
      disabled: !selectedAssignee,
      dataAid: 'reassign-dialog-task-button',
    },
  ];

  const onChange = (selected: BigidSelectOption[]) => setSelectedAssignee(selected[0]?.value);

  useEffect(() => {
    async function getInitialAssignees() {
      try {
        const query = getUsersQuery({ maxUsers: 100 });
        const {
          data: { users },
        } = await systemUsersService.getAllSystemUsersByQuery(query);

        setInitialAssignees(convertUsersToOptions(users as User[]));
      } catch (e) {
        console.error('error getting assingees', e);
      } finally {
        setIsLoading(false);
      }
    }
    getInitialAssignees();
  }, []);

  const loadAsyncOptions = useCallback(
    debounce(async (inputType: string) => {
      if (!inputType) {
        return initialAssignees;
      }

      const query = getUsersQuery({
        maxUsers: 20,
        filter: ['name', 'firstName', 'email'].map(field => ({
          field,
          operator: 'textSearch',
          value: inputType,
        })),
      });

      const {
        data: { users },
      } = await systemUsersService.getAllSystemUsersByQuery(query);

      return convertUsersToOptions(users as User[]);
    }, 500),
    [],
  );

  return (
    <BigidDialog buttons={buttons} isOpen={isOpen} onClose={onClose} isLoading={isLoading} title="Reassign">
      <Typography variant={'subtitle1'} className={classes.title}>
        {'Choose Assignee:'}
      </Typography>
      <BigidSelect
        options={initialAssignees}
        loadOptions={loadAsyncOptions}
        isAsync
        isSearchable
        autoFocus
        placeholder="Select assignee"
        menuPosition="fixed"
        onChange={onChange}
        dataAid="tasks-select-assignee"
      />
    </BigidDialog>
  );
};
