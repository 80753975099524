import { PrimaryButton } from '@bigid-ui/components';
import React from 'react';
import { openSystemDialog } from '../../../services/systemDialogService';
import { ScanProfileConversionForm } from './ScanProfileConversionForm';
import { ScanProfileFields } from './scanProfileConversionTypes';

interface ConvertScanProfileButtonProps {
  scanProfileFields: ScanProfileFields;
}

const openConvertScanForm = (scanProfileFields: ScanProfileFields) => {
  openSystemDialog({
    title: 'Convert Scan Profile',
    contentProps: { scanProfileFields },
    content: ScanProfileConversionForm,
    buttons: [],
    maxWidth: 'sm',
    onClose: () => null,
    showCloseIcon: true,
  });
};

export const ConvertScanProfileButton: React.FC<ConvertScanProfileButtonProps> = ({ scanProfileFields }) => {
  return (
    <PrimaryButton
      onClick={() => openConvertScanForm(scanProfileFields)}
      size={'large'}
      dataAid={'convert-profile-to-template-button'}
      text="Convert"
    />
  );
};
