import { BigidTabsAndContent, BigidTabsAndContentProps, BigidTabsItem } from '@bigid-ui/components';
import { DATA_MINIMIZATION_PERMISSIONS } from '@bigid/permissions';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import { IconButton } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { UIView } from '@uirouter/react';
import React, { useEffect, useMemo, useState } from 'react';
import { pageHeaderService } from '../../../common/services/pageHeaderService';
import { CONFIG } from '../../../config/common';
import { $state, $transitions } from '../../services/angularServices';
import { getApplicationPreference } from '../../services/appPreferencesService';
import { isPermitted } from '../../services/userPermissionsService';

const pageBottomMargin = 10;
const tabHeight = 34;

const useStyles = makeStyles({
  content: {
    display: 'flex',
    flexDirection: 'column',
    height: `calc(100% - ${pageBottomMargin}px)`,
    width: '100%',
    overflow: 'hidden',
  },
  contentContainer: { overflow: 'hidden', display: 'none' },
  view: {
    height: `calc(100% - ${tabHeight}px)`,
  },
  buttonContainer: {
    display: 'flex',
    gap: '8px',
  },
});

export const DataMinimization: React.FC = () => {
  const showNewUI = getApplicationPreference('DATA_DELETION_DATA_SOURCES_PAGE_V2');
  const tabs: Array<BigidTabsItem & { name: string }> = useMemo(
    () => [
      {
        label: showNewUI ? 'Data sources' : 'All Objects',
        name: CONFIG.states.DATA_MINIMIZATION_DATA_SOURCES,
      },
      {
        label: 'DSAR Requests',
        name: CONFIG.states.DATA_MINIMIZATION_REQUESTS,
      },
    ],
    [showNewUI],
  );
  const [selectedTabIndex, setSelectedTabIndex] = useState<number>(
    Math.max(
      tabs.findIndex(tab => tab.name === $state.$current.name),
      0,
    ),
  );
  const { content, contentContainer, view, buttonContainer } = useStyles({});

  useEffect(() => {
    pageHeaderService.setTitle({
      pageTitle: 'Data Deletion',
      ...(isPermitted(DATA_MINIMIZATION_PERMISSIONS.SETTINGS.name) && {
        rightSideComponentsContainer: (
          <IconButton title={'Settings'} onClick={() => $state.go(CONFIG.states.DATA_DELETION_GLOBAL_SETTINGS)}>
            <SettingsOutlinedIcon />
          </IconButton>
        ),
      }),
    });

    const deregisterListener = $transitions.onBefore(
      {
        to: state => state.name.includes(CONFIG.states.DATA_MINIMIZATION_DATA_SOURCES),
      },
      transition => {
        const { name } = transition.to();
        if (name === CONFIG.states.DATA_MINIMIZATION) {
          return $state.target(CONFIG.states.DATA_MINIMIZATION_DATA_SOURCES);
        }
        const index = tabs.findIndex(tab => tab.name === name);
        setSelectedTabIndex(index === -1 ? 0 : index);
      },
    );

    if ($state.current.name === CONFIG.states.DATA_MINIMIZATION) {
      $state.go(CONFIG.states.DATA_MINIMIZATION_DATA_SOURCES);
    }
    return () => deregisterListener?.();
  }, [tabs, buttonContainer]);

  const tabsAndContentConfig: BigidTabsAndContentProps = {
    classes: { /*tabsContainer,*/ contentContainer },
    tabProps: {
      size: 'large',
      tabs: tabs,
      onChange: tabIndex => {
        $state.go(tabs[tabIndex].name);
        setSelectedTabIndex(tabIndex);
      },
      selectedIndex: selectedTabIndex,
    },
  };

  return (
    <div className={content}>
      <BigidTabsAndContent {...tabsAndContentConfig} />
      <UIView className={view} />
    </div>
  );
};
