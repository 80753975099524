import React, { FC, useMemo, useRef, useState } from 'react';
import { BigidForm, BigidScheduler, RecurringSchedule } from '@bigid-ui/components';
import { useScanProfileConversionFormProps } from './useScanProfileConversionFormProps';
import { getDefaultScheduleRecurrence } from '../ScanCreateWizard/Steps/SchedulerStep/SchedulerStep';
import makeStyles from '@mui/styles/makeStyles';
import {
  ScanProfileConversionFormData,
  ScanProfileConversionFormProps,
  ScanProfileConversionTypeEnum,
} from './scanProfileConversionTypes';
import { getConvertProfileToScanButtons } from './scanProfileConversionFields';
import { v4 as uuid } from 'uuid';

const useStyles = makeStyles({
  buttonsWrapper: {
    display: 'flex',
    paddingTop: 12,
    justifyContent: 'flex-end',
    gap: 12,
  },
});

export const ScanProfileConversionForm: FC<ScanProfileConversionFormProps> = ({ scanProfileFields }) => {
  const classes = useStyles({});
  const scanProfileConversionBasicHandlersAndRef = useRef<any>();
  const [schedule, setSchedule] = useState<RecurringSchedule>(getDefaultScheduleRecurrence());
  const [isSchedule, setIsSchedule] = useState<boolean>(false);
  const [scanProfileConversionFormData, setScanProfileConversionFormData] = useState<ScanProfileConversionFormData>({
    owners: scanProfileFields?.owners?.[0] ? [scanProfileFields?.owners?.[0]] : [],
    conversionType: ScanProfileConversionTypeEnum.SCAN,
    name: scanProfileFields?.name ? `${scanProfileFields?.name} (Converted ${uuid().substring(0, 4)})` : '',
    templateId: '',
  });

  const handleChange = (newSchedule: RecurringSchedule) => {
    setSchedule(newSchedule);
  };

  const formProps = useScanProfileConversionFormProps(
    scanProfileConversionBasicHandlersAndRef,
    scanProfileConversionFormData,
    setScanProfileConversionFormData,
  );

  const formMemoized = useMemo(() => <BigidForm {...formProps} />, [formProps]);

  return (
    <>
      {isSchedule && <BigidScheduler onChange={handleChange} scheduleData={schedule} />}
      {!isSchedule && formMemoized}
      <div className={classes.buttonsWrapper}>
        {getConvertProfileToScanButtons({
          setIsSchedule,
          scanProfileConversionFormData: { ...scanProfileConversionFormData, scanSchedule: schedule },
          scanProfileFields,
          isSchedule,
          scanProfileConversionBasicHandlersAndRef,
        })}
      </div>
    </>
  );
};
