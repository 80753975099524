import type { Dispatch } from 'react';

type ArgumentType<TFunction> = TFunction extends (arg: infer TArg) => any ? TArg : never;

export const bindActionCreators = <
  TKey extends string,
  TPayload extends [TKey, any],
>(
  dispatch: Dispatch<TPayload>,
  actions: Record<TKey, (arg: any) => TPayload>,
) => {
  return Object.entries<(arg: any) => TPayload>(actions).reduce((acc, [name, fn]) => {
    acc[name as TKey] = (arg: ArgumentType<typeof fn>) => dispatch(fn(arg));
    return acc;
  }, {} as Record<TKey, (arg: ArgumentType<typeof actions[TKey]>) => void>);
};
