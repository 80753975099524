import React from 'react';
import angular from 'angular';
import { convertToAngular } from '../../common/services/convertToAngular';
import { BigidDeleteIcon } from '@bigid-ui/icons';
//
// export const BigidDeleteIcon: FC<{ onClick: (event?: MouseEvent) => void }> = ({
//   onClick,
// }) => {
//   return (
//     <Icon onClick={onClick}>
//     </Icon>
//   );
// };

angular.module('app').component('bigidDeleteIcon', convertToAngular(BigidDeleteIcon));
