import './mapping-graph.component.scss';
import angular, { module } from 'angular';
const GRAPH_HEIGHT = 920;
const GRAPH_WIDTH = 1875;
import template from './mapping-graph.component.html';
import { isPermitted } from '../../../react/services/userPermissionsService';
import { BUSINESS_FLOWS_PERMISSIONS } from '@bigid/permissions';

/**
 *  comes from dataMappingDocxReport.service.js,
 *  I assume in order to fit A4
 */
const REPORT_IMG_WIDTH = 650;
const REPORT_IMG_HEIGHT = 600;

const app = module('app');
app.component('mappingGraph', {
  template,
  controllerAs: 'mappingGraphModel',
  controller: [
    '$translate',
    '$element',
    function ($translate, $element) {
      const $ = go.GraphObject.make;

      class EmptyCommandHandler extends go.CommandHandler {
        doKeyDown() {} // eslint-disable-line @typescript-eslint/no-empty-function
        doKeyUp() {} // eslint-disable-line @typescript-eslint/no-empty-function
      }

      class GraphGroupsLayout extends go.GridLayout {
        constructor() {
          super();
          this.cellSize = new go.Size(1, 1);
          this.wrappingColumn = Infinity;
          this.wrappingWidth = Infinity;
          this.spacing = new go.Size(0, 0);
          this.alignment = go.GridLayout.Position;
        }

        doLayout(coll) {
          const diagram = this.diagram;
          if (diagram === null) return;
          diagram.startTransaction('GraphGroupsLayout');
          diagram.fixedBounds = diagram.fixedBounds.width ? diagram.fixedBounds : diagram.viewportBounds;
          const actualHeight = diagram.fixedBounds.height;
          const actualWidth = diagram.fixedBounds.width;
          diagram.scale = actualWidth / GRAPH_WIDTH;
          const groups = diagram.findTopLevelGroups();
          let idx = 0;
          groups.each(group => {
            group.location = new go.Point((GRAPH_WIDTH / groups.count) * idx, 0);
            group.desiredSize = new go.Size(GRAPH_WIDTH / groups.count, Math.max(0, actualHeight / diagram.scale)); // TODO: Take maximnum between the group size and the graph height

            idx++;
          });
          super.doLayout(coll);
          diagram.commitTransaction('GraphGroupsLayout');
        }
      }

      const mappingGraphModel = this;

      mappingGraphModel.$onChanges = function (changes) {
        if (mappingGraphModel.diagram) {
          const data = mappingGraphModel.createGraphData(this.graphData);

          mappingGraphModel.diagram.model = new go.GraphLinksModel(...data);
          mappingGraphModel.diagram.layoutDiagram();
          mappingGraphModel.isReport = this.isReport;
          if (mappingGraphModel.isReport) {
            const image = mappingGraphModel.diagram.makeImageData({
              type: 'image/png',
              size: new go.Size(REPORT_IMG_WIDTH, REPORT_IMG_HEIGHT),
            });

            mappingGraphModel.onEvent({
              event: 'createReportPDF',
              data: {
                imageData: image,
              },
            });
            mappingGraphModel.isReport = false;
            mappingGraphModel.diagram.layoutDiagram();
            mappingGraphModel.diagram.model = new go.GraphLinksModel(...data);
          }
        }
      };

      mappingGraphModel.$onInit = function () {
        mappingGraphModel.isManagePermitted = isPermitted(BUSINESS_FLOWS_PERMISSIONS.MANAGE.name);

        mappingGraphModel.diagram = $(go.Diagram, $element[0].querySelector('.chart-div'), {
          contentAlignment: go.Spot.TopCenter,
          autoScrollRegion: 0,
          allowZoom: false,
          allowHorizontalScroll: false,
          allowVerticalScroll: true,

          layout: $(GraphGroupsLayout),
          'animationManager.isEnabled': false,
          'undoManager.isEnabled': false,
          commandHandler: new EmptyCommandHandler(),
          LinkDrawn: e => {
            mappingGraphModel.onEvent({
              event: 'createLink',
              data: {
                from: e.diagram.findPartForKey(e.subject.data.from).data.entity._id,
                to: e.diagram.findPartForKey(e.subject.data.to).data.entity._id,
              },
            });
          },
          LinkRelinked: e => {
            const connector = e.subject.data.connector;
            connector.from = e.subject.fromNode.data.entity._id;
            connector.to = e.subject.toNode.data.entity._id;
            mappingGraphModel.onEvent({ event: 'reLink', data: connector });
          },
          SelectionDeleting: e => {
            for (let iter = e.diagram.selection.iterator; iter.next(); ) {
              const part = iter.value;
              if (part instanceof go.Node) {
                mappingGraphModel.onEvent({
                  event: 'deleteEntity',
                  data: part.data.entity,
                });
              } else if (part instanceof go.Link) {
                const connector = part.data.connector;
                mappingGraphModel.onEvent({
                  event: 'deleteLink',
                  data: connector,
                });
              }
            }
          },
          TextEdited: e => {
            e.handled = true;
            mappingGraphModel.onEvent({
              event: 'updateClassifier',
              data: {
                classification: e.subject.classification,
                textNew: e.subject.text,
                textPrevious: e.parameter,
              },
            });
          },

          // obj.findObject("Delete").opacity = 1;
          // selectionMoved: relayoutDiagram,
          padding: 0,
        });
        onInit();
      };

      function onInit() {
        const chartDiv = $element[0].querySelector('.chart-div');
        const canvas = chartDiv?.querySelector('canvas');
        canvas?.setAttribute('tabindex', '-1');

        mappingGraphModel.diagram.groupTemplate = $(
          go.Group,
          'Vertical',
          {
            copyable: false,
            movable: false,
            deletable: false,
            selectionAdorned: false,
            layerName: 'Background',
            computesBoundsAfterDrag: true,
            handlesDragDropForMembers: true,
            click: function (event, group) {
              mappingGraphModel.onEvent({ event: 'cancelEdit' });
            },
            // mouseDrop: function(e, grp) { // TODO[DA]: handle dropping properly
            //   const success = grp.addMembers(grp.diagram.selection, true);
            // },
            layout: $(go.GridLayout, {
              wrappingColumn: 1,
              cellSize: new go.Size(1, 1),
              spacing: new go.Size(5, 100),
              alignment: go.GridLayout.Position,
              comparer: function (a, b) {
                // can re-order tasks within a lane
                const ay = a.location.y;
                const by = b.location.y;
                if (isNaN(ay) || isNaN(by)) return 0;
                if (ay < by) return -1;
                if (ay > by) return 1;
                return 0;
              },
            }),
          },
          new go.Binding('background'),
          $(
            go.Panel,
            'Table',
            {
              //background: "lightgray",
              background: 'transparent',
              desiredSize: new go.Size(300, 80),
              alignment: go.Spot.Center,
              cursor: 'pointer',
              alignmentFocus: go.Spot.Center,
              click: (e, obj) => {
                if (obj.part.data.dataMappingClassification > 1) {
                  obj.findObject('Delete').opacity = 1;
                }
                const textBlocktoSelect = obj.findObject('laneTextBlock');
                const canEdit =
                  mappingGraphModel.isManagePermitted &&
                  mappingGraphModel.diagram.commandHandler.canEditTextBlock(textBlocktoSelect);
                if (canEdit) {
                  textBlocktoSelect.classification = obj.part.data.dataMappingClassification;
                  mappingGraphModel.diagram.commandHandler.editTextBlock(textBlocktoSelect);
                }
              },
              mouseLeave: (e, obj, prev) => {
                obj.findObject('Delete').opacity = 0;
              },
              mouseEnter: (e, obj, prev) => {
                if (obj.part.data.dataMappingClassification > 1) {
                  obj.findObject('Delete').opacity = 1;
                }
              },
            },
            $(
              go.Picture,
              '/images/Bigid_Delete_DataMapping.svg',
              {
                margin: new go.Margin(0, 0, 0, 5),
                height: 10,
                width: 10,
                opacity: 0,
                background: 'transparent',
                alignment: go.Spot.Left,
                name: 'Delete',
                cursor: 'pointer',
                click: (e, obj) => {
                  e.handled = true;
                  mappingGraphModel.onEvent({
                    event: 'deleteLane',
                    data: {
                      laneNumber: obj.part.data.dataMappingClassification,
                    },
                  });
                },
              },
              new go.Binding('visible', 'openState', state => mappingGraphModel.isManagePermitted),
            ),
            $(
              go.TextBlock,
              {
                font: "400 24px 'Muli', sans-serif",
                margin: new go.Margin(4, 0, 0, 0),
                stroke: '#717d89',
                cursor: 'text',
                name: 'laneTextBlock',
                text: 'alignment: Center',
                alignment: go.Spot.Center,
                editable: mappingGraphModel.isManagePermitted,
                background: 'transparent',
                isMultiline: false,
                maxSize: new go.Size(250, 80),
              },
              new go.Binding('text', 'title'),
            ),
          ),
          $(
            go.Panel,
            'Vertical',
            {
              margin: new go.Margin(0, 0, 20, 0),
              width: 200,
              height: 80,
              click: (e, obj) => {
                const data = obj.part.data;
                const from = +data.openState || 0;
                const to = from > 0 ? 0 : 1;
                animateDataProperties(
                  data,
                  {
                    openState: { from, to },
                  },
                  200,
                );
              },
              mouseEnter: (e, obj, prev) => {
                const data = obj.part.data;
                const from = +data.hoverState || 0;
                const to = 1;
                animateDataProperties(
                  data,
                  {
                    hoverState: { from, to },
                  },
                  200,
                );
              },
              mouseLeave: (e, obj, prev) => {
                const data = obj.part.data;
                const from = +data.hoverState || 0;
                const to = 0;
                animateDataProperties(
                  data,
                  {
                    hoverState: { from, to },
                  },
                  200,
                );
              },
            },
            $(
              go.Panel,
              'Spot',
              { alignment: go.Spot.Center },
              //print case
              new go.Binding('opacity', '', () => (mappingGraphModel.isReport ? 0 : 1)),
              $(
                go.Shape,
                {
                  height: 50,
                  strokeWidth: 0,
                  alignment: go.Spot.Center,
                },
                new go.Binding('fill', 'hoverState', (state, obj) =>
                  lerpColor('rgb(113, 125, 137)', 'rgb(255, 94, 67)', state),
                ),
                new go.Binding('opacity', 'hoverState', (state, obj) => lerpNumber(0.2, 1, state)),
                new go.Binding('width', 'openState', state => lerpNumber(50, 170, state)),
                new go.Binding('geometryString', 'openState', state => {
                  const width = lerpNumber(0, 120, state);
                  return go.Geometry.fillPath(`M25,0 h${width} a25,25 0 0 1 0,50 h-${width} a25,25 0 0 1 0,-50 Z`);
                }),
                new go.Binding('visible', 'openState', state => mappingGraphModel.isManagePermitted),
              ),
              $(
                go.Shape,
                {
                  height: 43,
                  margin: 7,
                  strokeWidth: 0,
                  cursor: 'pointer',
                  fill: '#fff',
                  alignment: go.Spot.Center,
                },
                new go.Binding('opacity', 'openState', state => lerpNumber(0.5, 0, state)),
                new go.Binding('width', 'openState', state => lerpNumber(43, 167, state)),
                new go.Binding('geometryString', 'openState', state => {
                  const width = lerpNumber(0, 120, state);
                  return go.Geometry.fillPath(`M25,0 h${width} a25,25 0 0 1 0,50 h-${width} a25,25 0 0 1 0,-50 Z`);
                }),
                new go.Binding('visible', 'openState', state => mappingGraphModel.isManagePermitted),
              ),
              $(
                go.Shape,
                'ThickCross',
                {
                  height: 20,
                  width: 20,
                  strokeWidth: 0,
                  cursor: 'pointer',
                  alignment: go.Spot.Center,
                },
                new go.Binding('fill', 'hoverState', (state, obj) =>
                  lerpColor('rgb(113, 125, 137)', 'rgb(255, 94, 67)', state),
                ),
                new go.Binding('opacity', 'hoverState', state => lerpNumber(0.2, 1, state * 2)),
                new go.Binding('visible', 'openState', state => mappingGraphModel.isManagePermitted && state < 0.5),
              ),
              $(
                go.TextBlock,
                'Add a mockup',
                {
                  font: "10px 'Muli', sans-serif",
                  stroke: 'rgb(255, 94, 67)',
                  alignment: new go.Spot(0.5, 1, 0, 15),
                  textAlign: 'center',
                },
                new go.Binding('opacity', 'hoverState', state => Math.min(1, state * 5)),
                new go.Binding('visible', 'openState', state => mappingGraphModel.isManagePermitted && state < 0.2),
              ),
              $(
                go.Shape,
                'Ellipse',
                {
                  height: 43,
                  width: 43,
                  strokeWidth: 0,
                  fill: '#fff',
                  alignment: new go.Spot(0, 0.5, 6, 0),
                  alignmentFocus: go.Spot.Left,
                },
                new go.Binding('opacity', 'openState', state => lerpNumber(0, 0.5, state)),
                new go.Binding('visible', 'openState', state => state > 0.5),
              ),
              $(
                go.Picture,
                '/images/Bigid_DataMapping_Actor.png',
                {
                  height: 43,
                  width: 43,
                  imageStretch: go.GraphObject.UniformToFill,
                  alignment: new go.Spot(0, 0.5, 6, 0),
                  alignmentFocus: go.Spot.Left,
                  cursor: 'pointer',
                  click: (e, obj) => {
                    e.handled = true;
                    mappingGraphModel.onEvent({
                      event: 'createEntity',
                      data: {
                        type: 'actor',
                        dataMappingClassification: obj.part.data.dataMappingClassification,
                      },
                    });
                  },
                },
                new go.Binding('opacity', 'openState', state => lerpNumber(0, 1, state)),
                new go.Binding('visible', 'openState', state => state > 0.5),
              ),
              $(
                go.TextBlock,
                'Actor',
                {
                  font: "10px 'Muli', sans-serif",
                  stroke: 'rgb(255, 94, 67)',
                  alignment: new go.Spot(0, 1, 6, 15),
                  alignmentFocus: go.Spot.Left,
                  textAlign: 'center',
                  width: 43,
                },
                new go.Binding('opacity', 'openState', state => Math.min(1, (state - 0.8) * 5)),
                new go.Binding('visible', 'openState', state => state > 0.8),
              ),
              $(
                go.Shape,
                'Ellipse',
                {
                  height: 43,
                  width: 43,
                  strokeWidth: 0,
                  fill: '#fff',
                  alignment: go.Spot.Center,
                },
                new go.Binding('opacity', 'openState', state => lerpNumber(0, 0.5, state)),
                new go.Binding('visible', 'openState', state => state > 0.5),
              ),
              $(
                go.Picture,
                '/images/Bigid_DataMapping_DataSource.png',
                {
                  height: 43,
                  width: 43,
                  imageStretch: go.GraphObject.UniformToFill,
                  alignment: go.Spot.Center,
                  cursor: 'pointer',
                  click: (e, obj) => {
                    e.handled = true;
                    mappingGraphModel.onEvent({
                      event: 'createEntity',
                      data: {
                        type: 'datasource',
                        dataMappingClassification: obj.part.data.dataMappingClassification,
                      },
                    });
                  },
                },
                new go.Binding('opacity', 'openState', state => lerpNumber(0, 1, state)),
                new go.Binding('visible', 'openState', state => state > 0.5),
              ),
              $(
                go.TextBlock,
                'DB',
                {
                  font: "10px 'Muli', sans-serif",
                  stroke: 'rgb(255, 94, 67)',
                  alignment: new go.Spot(0.5, 1, 0, 15),
                  textAlign: 'center',
                  width: 43,
                },
                new go.Binding('opacity', 'openState', state => Math.min(1, (state - 0.8) * 5)),
                new go.Binding('visible', 'openState', state => state > 0.8),
              ),
              $(
                go.Shape,
                'Ellipse',
                {
                  height: 43,
                  width: 43,
                  strokeWidth: 0,
                  fill: '#fff',
                  alignment: new go.Spot(1, 0.5, -6, 0),
                  alignmentFocus: go.Spot.Right,
                },
                new go.Binding('opacity', 'openState', state => lerpNumber(0, 0.5, state)),
                new go.Binding('visible', 'openState', state => state > 0.5),
              ),
              $(
                go.Picture,
                '/images/Bigid_DataMapping_Application.png',
                {
                  height: 43,
                  width: 43,
                  imageStretch: go.GraphObject.UniformToFill,
                  alignment: new go.Spot(1, 0.5, -6, 0),
                  alignmentFocus: go.Spot.Right,
                  cursor: 'pointer',
                  click: (e, obj) => {
                    e.handled = true;
                    mappingGraphModel.onEvent({
                      event: 'createEntity',
                      data: {
                        type: 'application',
                        dataMappingClassification: obj.part.data.dataMappingClassification,
                      },
                    });
                  },
                },
                new go.Binding('opacity', 'openState', state => lerpNumber(0, 1, state)),
                new go.Binding('visible', 'openState', state => state > 0.5),
              ),
              $(
                go.TextBlock,
                'App',
                {
                  font: "10px 'Muli', sans-serif",
                  stroke: 'rgb(255, 94, 67)',
                  alignment: new go.Spot(1, 1, -6, 15),
                  alignmentFocus: go.Spot.Right,
                  textAlign: 'center',
                  width: 43,
                },
                new go.Binding('opacity', 'openState', state => Math.min(1, (state - 0.8) * 5)),
                new go.Binding('visible', 'openState', state => state > 0.8),
              ),
            ),
          ),
          $(go.Placeholder),
        );

        function lerpNumber(from, to, percent) {
          return from + (to - from) * Math.min(1, Math.max(0, percent));
        }

        function lerpColor(from, to, percent) {
          const [, fr, fg, fb] = from.match(/rgb\((\d+)\s*,\s*(\d+)\s*,\s*(\d+)\s*\)/);
          const [, tr, tg, tb] = to.match(/rgb\((\d+)\s*,\s*(\d+)\s*,\s*(\d+)\s*\)/);
          const result = `rgb(${lerpNumber(+fr, +tr, percent)}, ${lerpNumber(+fg, +tg, percent)}, ${lerpNumber(
            +fb,
            +tb,
            percent,
          )})`;
          return result;
        }

        function lerpColorAlpha(from, to, percent) {
          const [, fr, fg, fb, fa] = from.match(/rgba\((\d+)\s*,\s*(\d+)\s*,\s*(\d+)\s*,\s*((0?.)?\d+).*\)/);
          const [, tr, tg, tb, ta] = to.match(/rgba\((\d+)\s*,\s*(\d+)\s*,\s*(\d+)\s*,\s*((0?.)?\d+).*\)/);
          const result = `rgba(${lerpNumber(+fr, +tr, percent)}, ${lerpNumber(+fg, +tg, percent)}, ${lerpNumber(
            +fb,
            +tb,
            percent,
          )}, ${lerpNumber(+fa, +ta, percent)})`;
          return result;
        }

        function animateDataProperties(dataObj, propsToAnimate, duration) {
          const frameDuration = 20;
          const frames = duration / frameDuration;
          const percentage = 1 / frames;
          let percent = 0;
          const interval = setInterval(() => {
            percent += percentage;
            if (percent >= 1) {
              clearInterval(interval);
            }
            mappingGraphModel.diagram.model.startTransaction('Animate');
            Object.keys(propsToAnimate).forEach(key => {
              const { from, to } = propsToAnimate[key];
              const oldValue = dataObj[key];
              const newValue = lerpNumber(from, to, percent);
              dataObj[key] = newValue;
              mappingGraphModel.diagram.model.raiseDataChanged(dataObj, key, oldValue, newValue);
            });
            mappingGraphModel.diagram.model.commitTransaction('Animate');
          }, frameDuration);
        }

        function fillPic(entity) {
          switch (entity.type) {
            case 'actor':
              return '#FF5E43';
            case 'datasource':
              return '#2196F3';
            case 'application':
              return '#2ADE73';
            default:
              return '';
          }
        }

        function setStroke(entity, isReport) {
          if (entity.isSelected) {
            return 'rgb(255, 94, 67)';
          }
          if (isReport) {
            return 'rgba(0, 0, 0,1)';
          }
          return 'rgba(0, 0, 0,0)';
        }

        mappingGraphModel.diagram.nodeTemplate = $(
          go.Node,
          'Vertical',
          {
            layoutConditions: 0,
            movable: false, // TODO [DA]: remove and handle drag properly
            selectionAdorned: false,
            fromLinkable: true,
            toLinkable: true,
            click: function (event, part) {
              mappingGraphModel.diagram.model.startTransaction('editEntity');

              mappingGraphModel.onEvent({ event: 'editEntity', data: part.data.entity });

              if (mappingGraphModel.diagram.model.nodeDataArray) {
                mappingGraphModel.diagram.model.nodeDataArray.forEach(node => {
                  if (node.entity && node.entity.isSelected) {
                    delete node.entity.isSelected;
                  }
                });
                mappingGraphModel.diagram.rebuildParts();
              }

              mappingGraphModel.diagram.model.commitTransaction('editEntity');
            },
          },
          new go.Binding('visible', 'entity', entity => !entity.hidden),
          //print case
          new go.Binding('height', '', () => (mappingGraphModel.isReport ? 110 : 100)), //was 80
          new go.Binding('width', '', () => (mappingGraphModel.isReport ? 240 : 200)),
          $(
            go.Panel,
            'Spot',
            {
              alignment: go.Spot.Center,
              mouseEnter: (e, obj, prev) => {
                const ports = obj.part.ports;
                obj.findObject('Down').opacity = 1;
                obj.findObject('Up').opacity = 1;
                ports.each(port => {
                  port.stroke = 'rgb(255, 94, 67)';
                  port.fill = 'rgb(255, 94, 67)';
                });
              },
              mouseLeave: (e, obj, prev) => {
                const ports = obj.part.ports;
                obj.findObject('Down').opacity = 0;
                obj.findObject('Up').opacity = 0;
                ports.each(port => {
                  port.stroke = null;
                  port.fill = null;
                });
              },
            },
            $(
              go.Shape,
              {
                fill: '#fff',
                stroke: 'rgb(0,0,0)',
                strokeWidth: 1,
                geometryString: go.Geometry.fillPath('M30,0 h135 a30,30 0 0 1 0,60 h-135 a30,30 0 0 1 0,-60 Z'),
              },
              //print case
              //new go.Binding('stroke', 'isSelected', (isSelected) => isSelected  ? 'rgb(255, 94, 67)' : mappingGraphModel.isReport ? 'rgba(0, 0, 0,1)' : 'rgba(0, 0, 0,0)').ofObject(),
              //new go.Binding('stroke', 'isSelected', (isSelected) => setStroke(isSelected)),
              new go.Binding('stroke', 'isSelected', isSelected =>
                isSelected ? 'rgb(255, 94, 67)' : 'rgba(0, 0, 0,0)',
              ).ofObject(),
              //new go.Binding('stroke', 'entity', (entity) => entity.isSelected ? 'rgb(255, 94, 67)' : 'rgba(0, 0, 0,0)' ),
              new go.Binding('stroke', 'entity', entity => setStroke(entity, mappingGraphModel.isReport)),

              //new go.Binding('stroke',mappingGraphModel.isReport ? 'rgba(0, 0, 0,1)' : 'rgba(0, 0, 0,0)').ofObject(),
              new go.Binding('height', '', () => (mappingGraphModel.isReport ? 80 : 60)), //the height of the node
              new go.Binding('width', '', () => (mappingGraphModel.isReport ? 215 : 195)), //the width of the node
            ),
            $(
              go.Shape,
              'Ellipse',
              {
                height: 48,
                width: 48,
                strokeWidth: 0,
                alignment: new go.Spot(0, 0.5, 8, 0),
                alignmentFocus: go.Spot.Left,
              },
              //new go.Binding('fill', 'isSelected', (isSelected) => isSelected ? 'rgb(255, 94, 67)' : 'rgb(113, 125, 137)' ).ofObject(),
              //'rgb(255, 94, 67)' :
              new go.Binding('fill', 'entity', entity => fillPic(entity)),
            ),
            $(
              go.Picture,
              {
                height: 48,
                width: 48,
                imageStretch: go.GraphObject.UniformToFill,
                alignment: new go.Spot(0, 0.5, 8, 0),
                alignmentFocus: go.Spot.Left,
              },
              new go.Binding('source', 'entity', entity => {
                switch (entity.type) {
                  case 'actor':
                    return '/images/Bigid_DataMapping_Actor.png';
                  case 'datasource':
                    return '/images/Bigid_DataMapping_DataSource.png';
                  case 'application':
                    return '/images/Bigid_DataMapping_Application.png';
                  default:
                    return '';
                }
              }),
            ),
            $(
              go.Panel,
              'Vertical',
              {
                alignment: new go.Spot(0, 0.5, 65, 0),
                alignmentFocus: go.Spot.Left,
              },
              new go.Binding('stroke', 'entity', entity => (entity.isSelected ? 'rgb(255, 94, 67)' : '#000')),

              //print case
              new go.Binding('height', '', () => (mappingGraphModel.isReport ? 60 : 40)),
              $(
                go.TextBlock,
                {
                  alignment: go.Spot.Left,
                  margin: new go.Margin(0, 0, 5, 0),
                  overflow: go.TextBlock.OverflowEllipsis,
                  wrap: go.TextBlock.None,
                  width: 120,
                },
                new go.Binding('text', 'entity', entity => entity.name),
                new go.Binding('stroke', 'isSelected', isSelected =>
                  isSelected ? 'rgb(255, 94, 67)' : '#000',
                ).ofObject(),
                new go.Binding('stroke', 'entity', entity => (entity.isSelected ? 'rgb(255, 94, 67)' : '#000')),
                //print case
                new go.Binding('font', '', () =>
                  mappingGraphModel.isReport ? "28px 'Muli', sans-serif" : "19px 'Muli', sans-serif",
                ),
              ),
              $(
                go.TextBlock,
                {
                  alignment: go.Spot.Left,
                  stroke: 'rgb(113, 125, 137)',
                },
                new go.Binding('text', 'category'),
                //print case
                new go.Binding('font', '', () =>
                  mappingGraphModel.isReport ? "18px 'Muli', sans-serif" : "11px 'Muli', sans-serif",
                ),
              ),
            ),
            $(
              go.Shape,
              'Ellipse',
              {
                height: 15,
                width: 15,
                strokeWidth: 6,
                stroke: 'rgb(255, 0, 0)',
                fill: 'rgb(255, 94, 67)',
                alignment: go.Spot.TopLeft,
                alignmentFocus: go.Spot.TopLeft,
                click: (event, shape) => {
                  const entity = shape.part.data.entity;

                  mappingGraphModel.onEvent({
                    event: 'openTaskList',
                    data: entity,
                  });
                },
              },
              new go.Binding('visible', 'entity', entity => {
                return entity.hasOpenTasks || entity.hasActiveCollaborationTask;
              }),
            ),
            $(go.Picture, '/images/Bigid_Arrow_Up.svg', {
              height: 20,
              width: 20,
              opacity: 0,
              alignment: new go.Spot(0.5, -0.15, 0, 0),
              alignmentFocus: go.Spot.Center,
              name: 'Up',
              cursor: 'pointer',
              click: (e, obj) => {
                if (mappingGraphModel.isManagePermitted) {
                  e.handled = true;
                  mappingGraphModel.onEvent({
                    event: 'updatePosition',
                    data: {
                      direction: 'up',
                      entity: obj.part.data,
                    },
                  });
                }
              },
            }),
            $(go.Picture, '/images/Bigid_Arrow_Down.svg', {
              height: 20,
              width: 20,
              opacity: 0,
              alignment: new go.Spot(0.5, 1.2, 0, -3),
              alignmentFocus: go.Spot.Center,
              name: 'Down',
              cursor: 'pointer',
              click: (e, obj) => {
                if (mappingGraphModel.isManagePermitted) {
                  e.handled = true;
                  mappingGraphModel.onEvent({
                    event: 'updatePosition',
                    data: {
                      direction: 'down',
                      entity: obj.part.data,
                    },
                  });
                }
              },
            }),
            $(go.Shape, 'Ellipse', {
              height: 8,
              width: 8,
              toLinkable: true,
              fromLinkable: true,
              portId: 'T',
              cursor: 'pointer',
              alignment: new go.Spot(0.5, 0, 0, 3),
              stroke: null,
              fill: null,
            }),
            $(go.Shape, 'Ellipse', {
              height: 8,
              width: 8,
              toLinkable: true,
              fromLinkable: true,
              portId: 'R',
              cursor: 'pointer',
              alignment: new go.Spot(1, 0.5, -3, 0),
              stroke: null,
              fill: null,
            }),
            $(go.Shape, 'Ellipse', {
              height: 8,
              width: 8,
              toLinkable: true,
              fromLinkable: true,
              portId: 'B',
              cursor: 'pointer',
              alignment: new go.Spot(0.5, 1, 0, -3),
              stroke: null,
              fill: null,
            }),
            $(go.Shape, 'Ellipse', {
              height: 8,
              width: 8,
              toLinkable: true,
              fromLinkable: true,
              portId: 'L',
              cursor: 'pointer',
              alignment: new go.Spot(0, 0.5, 3, 0),
              stroke: null,
              fill: null,
            }),
          ),
        );

        mappingGraphModel.diagram.linkTemplate = $(
          go.Link,
          {
            // routing: go.Link.AvoidsNodes,
            click: (e, obj) => {
              mappingGraphModel.onEvent({ event: 'editLink', data: obj.data.connector });
            },
            layoutConditions: 0,
            selectionAdorned: false,
            selectable: true,
            relinkableTo: true,
            relinkableFrom: true,
            curve: go.Link.Bezier,
          },
          new go.Binding('visible', 'connector', connector => !connector.hidden),
          $(
            go.Shape,
            { strokeWidth: 3 },
            new go.Binding('stroke', '', object =>
              object.isSelected
                ? 'rgb(255, 94, 67)'
                : mappingGraphModel.graphData.flows.find(flow => flow._id === object.part.data.connector.flowId).color,
            ).ofObject(),
          ),
          $(
            go.Shape,
            { fromArrow: '' },
            new go.Binding('fill', '', object =>
              object.isSelected
                ? 'rgb(255, 94, 67)'
                : mappingGraphModel.graphData.flows.find(flow => flow._id === object.part.data.connector.flowId).color,
            ).ofObject(),
            new go.Binding('stroke', '', object =>
              object.isSelected
                ? 'rgb(255, 94, 67)'
                : mappingGraphModel.graphData.flows.find(flow => flow._id === object.part.data.connector.flowId).color,
            ).ofObject(),
            new go.Binding('fromArrow', '', object => object.part.data.connector.fromArrow).ofObject(),
          ),
          $(
            go.Shape,
            { toArrow: '' },
            new go.Binding('fill', '', object =>
              object.isSelected
                ? 'rgb(255, 94, 67)'
                : mappingGraphModel.graphData.flows.find(flow => flow._id === object.part.data.connector.flowId).color,
            ).ofObject(),
            new go.Binding('stroke', '', object =>
              object.isSelected
                ? 'rgb(255, 94, 67)'
                : mappingGraphModel.graphData.flows.find(flow => flow._id === object.part.data.connector.flowId).color,
            ).ofObject(),
            new go.Binding('toArrow', '', object => object.part.data.connector.toArrow).ofObject(),
          ),
          $(
            go.Panel,
            'Spot',
            {
              segmentIndex: 1,
              segmentFraction: 0.85,
            },
            $(
              go.Shape,
              'Ellipse',
              {
                height: 25,
                width: 25,
              },
              new go.Binding('strokeWidth', 'isSelected', isSelected => (isSelected ? 2 : 0)).ofObject(),
              new go.Binding(
                'fill',
                'connector',
                connector => mappingGraphModel.graphData.flows.find(flow => flow._id === connector.flowId).color,
              ),
              new go.Binding('stroke', 'isSelected', isSelected =>
                isSelected ? 'rgb(255, 94, 67)' : 'rgba(0, 0, 0, 0)',
              ).ofObject(),
            ),
            $(
              go.TextBlock,
              {
                stroke: '#fff',
                font: "400 12px 'Muli', sans-serif",
                alignment: go.Spot.Center,
                textAlign: 'center',
              },
              new go.Binding('text', 'connector', connector => connector.stepNumber),
            ),
          ),
        );
        if (mappingGraphModel.graphData) {
          mappingGraphModel.diagram.model = new go.GraphLinksModel(
            ...mappingGraphModel.createGraphData(mappingGraphModel.graphData),
          );
        }
      }

      mappingGraphModel.createGraphData = function (data) {
        const { entities, connectors, classifiers } = data || {};
        const nodes = [
          ...(classifiers || [])
            .map(classifier => classifier.name)
            .map((groupName, idx) => ({
              key: `GROUP_${idx + 1}`,
              title: groupName,
              isGroup: true,
              background: `rgba(255, 255, 255, ${idx % 2 !== 0 ? 0.2 : 0})`,
              dataMappingClassification: idx + 1,
              hoverState: 0,
              openState: 0,
            })),
          ...(entities || []).map((entity, idx) => ({
            key: `ENTITY_${entity._id}`,
            group: `GROUP_${entity.dataMappingClassification}`,
            category: (entity.type || '').toUpperCase(),
            entity: angular.copy(entity),
          })),
        ];
        const links = (connectors || []).map((connector, idx) => ({
          key: `CONNECTOR_${connector._id}`,
          from: `ENTITY_${connector.from}`,
          to: `ENTITY_${connector.to}`,
          connector: angular.copy(connector),
        }));
        return [nodes, links];
      };
    },
  ],
  bindings: {
    reloadFlag: '<',
    graphData: '<',
    onEvent: '&',
    isReport: '<',
  },
  require: {
    parent: '^datamapping',
  },
});
