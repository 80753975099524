import React, { FunctionComponent, useCallback, useEffect, useState } from 'react';
import { Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { BigidContentItem, BigidLoader } from '@bigid-ui/components';
import { DataCatalogRecord } from '../DataCatalogService';
import { getLineageNeighborsGraph } from './DataCatalogLineageService';
import { LineageNodeView } from './lineage-node-view';
import { BigidGraph, BigidGraphDirection, BigidGraphGraph, BigidGraphNodePort } from '@bigid-ui/visualisation';

const useStyles = makeStyles({
  root: {
    display: 'flex',
    flexFlow: 'column nowrap',
    width: '100%',
  },
});

export const DataCatalogLineage: FunctionComponent<BigidContentItem & DataCatalogRecord> = ({
  fullyQualifiedName,
  source,
  objectType,
}) => {
  const classes = useStyles();

  const [data, setData] = useState<BigidGraphGraph>();
  const [error, setError] = useState();

  const getGraph = useCallback(async (): Promise<void> => {
    try {
      const graph = await getLineageNeighborsGraph(source, objectType, fullyQualifiedName);
      setData(graph);
    } catch (error) {
      setError(error);
    }
  }, [fullyQualifiedName, objectType, source]);

  useEffect(() => {
    setData(null);
    setError(null);
    getGraph().then();
  }, [getGraph]);

  return (
    <div className={classes.root}>
      {data ? (
        <BigidGraph
          data={data}
          nodeOptions={{
            view: LineageNodeView,
          }}
          edgeOptions={{
            nodePorts: {
              source: BigidGraphNodePort.RIGHT,
              target: BigidGraphNodePort.LEFT,
            },
          }}
          gridOptions={{
            size: { x: 0, y: 0, width: 200, height: 54 },
            groupBy: [
              {
                direction: BigidGraphDirection.HORIZONTAL,
                property: 'column',
              },
            ],
          }}
        />
      ) : error ? (
        <Typography>Error loading data...</Typography>
      ) : (
        <BigidLoader />
      )}
    </div>
  );
};
