import React, { FC, useState, useRef, useEffect } from 'react';
import {
  SecondaryButton,
  ToolbarAction,
  entityEventsEmitter,
  EntityEvents,
  BigidSnake,
  BigidDropdown,
  BigidDropdownOption,
  BigidColorsV2,
} from '@bigid-ui/components';
import { BigidGridColumnTypes, BigidGridProps, BigidGridColumn, FetchDataFunction, BigidGridRow } from '@bigid-ui/grid';
import { BigidLayout, BigidLayoutConfig, BigidMasterDetailsContentProps, LayoutContentType } from '@bigid-ui/layout';
import makeStyles from '@mui/styles/makeStyles';
import { httpService } from '../../../services/httpService';
import { ProfileManagement, SarProfileType, ProfileManagementProps } from './ProfileManagement/ProfileManagement';
import { convertToAngular } from '../../../../common/services/convertToAngular';
import angular from 'angular';
import { EntitySources } from './DataSource/EntitySources';
import { DataSources } from './DataSource/DataSources';
import { FieldsSettings } from './SarFields/FieldsSettings';
import { PROFILE_ACTION } from './ProfileManagement/ProfileManagementDialog';
import { PlaceHolderComponent } from './PlaceHolderComponent';
import { $state } from '../../../services/angularServices';
import DeleteOutlineRoundedIcon from '@mui/icons-material/DeleteOutlineRounded';
import FilterNoneRoundedIcon from '@mui/icons-material/FilterNoneRounded';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { isPermitted } from '../../../services/userPermissionsService';
import { DSAR_PERMISSIONS } from '@bigid/permissions';
import { CONFIG } from '../../../../config/common';
import { SarProfileTabNames } from './SarProfileSettingsTypes';
import { getApplicationPreference } from '../../../services/appPreferencesService';
import { $rootScope } from 'ngimport';

export interface NormalizedSarProfile extends SarProfileType {
  id: string;
}

const WIDTH = 250;

const useStyles = makeStyles({
  root: {
    backgroundColor: BigidColorsV2.white,
    borderRadius: '4px',
    border: `1px solid ${BigidColorsV2.gray[300]}`,
    height: '100%',
  },
  contentContainer: {
    display: 'flex',
    overflow: 'hidden',
    borderRadius: '4px 4px 0px 0px',
    boxShadow: '0 0 6px rgba(0, 0, 0, 0.16)',
    flexFlow: 'column nowrap',
    flex: '1 1 auto',
  },
  noPadding: {
    padding: 0,
  },
  dropdown: {
    marginLeft: '-30px',
    '@media screen and (min-width: 960px)': {
      position: 'absolute',
      width: '296px',
      left: '105px',
      top: '5px',
      marginLeft: '0',
    },
  },
});

const getSarProfiles = async (): Promise<NormalizedSarProfile[]> => {
  try {
    const {
      data: { profiles },
    } = await httpService.fetch<{ profiles: SarProfileType[] }>('sar/profiles');
    return profiles.map(profile => ({
      id: profile._id,
      ...profile,
    }));
  } catch (error) {
    console.error('could not get sar profiles');
    return [];
  }
};

const columns: BigidGridColumn<NormalizedSarProfile>[] = [
  {
    width: WIDTH,
    title: 'Profile name',
    name: 'objectName',
    isListColumn: true,
    type: BigidGridColumnTypes.TEXT,
    getCellValue: ({ name }) => name,
  },
];

export const SarProfileSettings: FC = () => {
  const resultsCacheRef = useRef<NormalizedSarProfile[]>([]);
  const [selectedTabIndex, setSelectedTabIndex] = useState<number>(
    Object.values(SarProfileTabNames).indexOf($state.params.tab) || 0,
  );
  const [profileManagementState, setProfileManagementState] = useState<ProfileManagementProps>(null);
  const [isSnakeOpen, setIsSnakeOpen] = useState<boolean>();

  const showDsarSnake = !!getApplicationPreference('SHOW_DSAR_SNAKE');
  const classes = useStyles({});

  const masterDetailsConfig: BigidMasterDetailsContentProps = {
    isPersistentListMode: true,
    setSelectedItemInFetch: true,
    placeholderComponent: (
      <PlaceHolderComponent>
        <div>
          Select a profile or
          <span style={{ padding: 12 }}>
            <SecondaryButton
              size={'small'}
              onClick={() => openProfileManagementDialog([], PROFILE_ACTION.CREATE)}
              disabled={!isPermitted(DSAR_PERMISSIONS.CREATE_PROFILE_SETTINGS.name)}
              text="Add new Profile"
            />
          </span>
        </div>
      </PlaceHolderComponent>
    ),
    tabsAndContent: {
      classes: {
        contentContainer: classes.contentContainer,
      },
      tabProps: {
        onChange: tabIndex => {
          $state.go(
            CONFIG.states.ACCESS_REQUEST_MANAGEMENT_PROFILE,
            { ...$state.params, tab: Object.values(SarProfileTabNames)[tabIndex] },
            { reload: false, notify: false, inherit: true },
          );
          setSelectedTabIndex(tabIndex);
        },
        selectedIndex: selectedTabIndex,
        tabs: [
          {
            label: 'Correlation Sets',
            data: {
              component: EntitySources,
            },
          },
          {
            label: 'Data Sources',
            data: {
              component: DataSources,
            },
          },
          {
            label: 'Fields',
            data: {
              component: FieldsSettings,
            },
          },
        ],
      },
    },
  };

  const profilesGridConfig: BigidGridProps<NormalizedSarProfile> = {
    showSelectionColumn: false,
    columns,
  };

  const fetchGridData: FetchDataFunction<NormalizedSarProfile> = async (query, { setSelectedItem }) => {
    const sarProfiles = await getSarProfiles();
    if (!resultsCacheRef.current) {
      resetDialogState();
    }
    resultsCacheRef.current = sarProfiles;

    const currentProfile = getCurrentProfile(sarProfiles);
    setSelectedItem(currentProfile);

    setConfig(config => {
      config.content.customToolbarComponent = <Dropdown setProfile={setSelectedItem} profiles={sarProfiles} />;
      return {
        ...config,
      };
    });

    return {
      totalCount: sarProfiles.length,
      // Sort first by isCustom: false to put Default profile upfront and then by name alphabetically
      data: sarProfiles.sort((p1, p2) =>
        isDefaultProfile(p1) === isDefaultProfile(p2)
          ? p1.name?.localeCompare(p2.name)
          : +isDefaultProfile(p2) - +isDefaultProfile(p1),
      ),
    };
  };

  const toolbarActions: ToolbarAction[] = [
    {
      label: 'Add new Profile',
      isGlobal: true,
      execute: async ({ selectedRowIds }) => {
        const actionResult = await openProfileManagementDialog([], PROFILE_ACTION.CREATE);
        if (actionResult) {
          return { shouldGridReload: true, shouldClearSelection: true };
        }
      },
      disable: () => false,
      show: () => isPermitted(DSAR_PERMISSIONS.CREATE_PROFILE_SETTINGS.name),
      placement: 'end',
    },
    {
      label: 'Edit',
      icon: EditOutlinedIcon,
      execute: async ({ selectedRowIds, selectedItem }) => {
        const actionResult = !!(await openProfileManagementDialog(selectedRowIds as string[], PROFILE_ACTION.EDIT));
        return { shouldGridReload: actionResult, shouldClearSelection: actionResult };
      },
      disable: ({ selectedRowIds }) => {
        return selectedRowIds.length > 1;
      },
      show: ({ selectedRowIds }) => {
        return selectedRowIds.length === 1 && isPermitted(DSAR_PERMISSIONS.EDIT_PROFILE_SETTINGS.name);
      },
      placement: 'end',
    },
    {
      label: 'Duplicate',
      icon: FilterNoneRoundedIcon,
      placement: 'end',
      execute: async ({ selectedRowIds }) => {
        const actionResult = !!(await openProfileManagementDialog(
          selectedRowIds as string[],
          PROFILE_ACTION.DUPLICATE,
        ));
        return { shouldGridReload: actionResult, shouldClearSelection: actionResult };
      },
      disable: ({ selectedRowIds }) => selectedRowIds.length > 1,
      show: ({ selectedRowIds }) =>
        selectedRowIds.length === 1 && isPermitted(DSAR_PERMISSIONS.CREATE_PROFILE_SETTINGS.name),
    },
    {
      label: 'Delete',
      placement: 'end',
      icon: DeleteOutlineRoundedIcon,
      execute: async ({ selectedRowIds }) => {
        const actionResult = !!(await openProfileManagementDialog(selectedRowIds as string[], PROFILE_ACTION.DELETE));
        return { shouldGridReload: actionResult, shouldClearSelection: actionResult };
      },
      disable: ({ selectedRowIds, selectedItem }) => {
        return selectedRowIds.length > 1 || isDefaultProfile(selectedItem as any);
      },
      show: ({ selectedRowIds, selectedItem }) => {
        return (
          selectedRowIds.length > 0 &&
          !isDefaultProfile(selectedItem as any) &&
          isPermitted(DSAR_PERMISSIONS.DELETE_PROFILE_SETTINGS.name)
        );
      },
    },
    {
      label: 'Snake',
      isGlobal: true,
      placement: 'end',
      execute: () => {
        setIsSnakeOpen(true);
        return null;
      },
      show: () => showDsarSnake,
    },
  ];

  const layoutConfig: BigidLayoutConfig = {
    content: {
      entityName: 'Profiles',
      getCustomRowDescription: () => 'Select Profile',
      toolbarActions,
      noPadding: true,
      contentTypes: [LayoutContentType.MASTER_DETAILS],
      viewConfig: {
        fetchGridData,
        gridConfig: profilesGridConfig,
        masterDetailsConfig,
      },
    },
  };

  function resetDialogState() {
    setProfileManagementState(null);
  }

  async function openProfileManagementDialog(selectedIds: string[], actionType: PROFILE_ACTION): Promise<boolean> {
    return new Promise(resolve => {
      if (actionType !== PROFILE_ACTION.CREATE && !selectedIds.length) {
        resolve(false);
      }
      const [profileId] = selectedIds;
      setProfileManagementState({
        profileId,
        profiles: resultsCacheRef.current,
        isOpen: true,
        actionType,
        onActionSuccess: (actionType, newProfileId) => {
          resolve(true);
          resetDialogState();
          // must have this to select the profile in the master details + update the deep link
          if (newProfileId !== $state.params.profileId) {
            $state.go('.', { ...$state.params, profileId: newProfileId }, { reload: false, notify: false });
          }
          entityEventsEmitter.emit(EntityEvents.RELOAD);
        },
      });
    });
  }

  const [config, setConfig] = useState(layoutConfig);

  return (
    <div className={classes.root}>
      <BigidLayout config={config} />
      {profileManagementState && <ProfileManagement {...profileManagementState} />}
      {showDsarSnake && <BigidSnake isOpen={isSnakeOpen} onClose={() => setIsSnakeOpen(false)} />}
    </div>
  );
};

interface DropdownProps {
  profiles: NormalizedSarProfile[];
  setProfile: (item: BigidGridRow) => void;
}

const Dropdown = ({ profiles, setProfile }: DropdownProps) => {
  const classes = useStyles({});
  const [value, setValue] = useState(() => getCurrentProfile(profiles));

  useEffect(() => {
    const deregister = $rootScope.$on('$locationChangeSuccess', () => {
      const currentProfile = getCurrentProfile(profiles);
      setValue(currentProfile);
    });

    return () => {
      deregister();
    };
  }, [profiles]);

  return (
    <div className={classes.dropdown}>
      <BigidDropdown
        size="large"
        isSearchable
        options={profiles.map(toDropdownValue)}
        value={[toDropdownValue(value)]}
        onSelect={([options]: BigidDropdownOption[]) => {
          setProfile(profiles.find(p => p.id === options.id));
        }}
      />
    </div>
  );
};

const getCurrentProfile = (profiles: NormalizedSarProfile[]) =>
  profiles?.find(({ id }) => id === $state.params.profileId) || profiles?.[0];

const toDropdownValue = (profile: NormalizedSarProfile) => ({
  id: profile.id,
  value: profile.name,
  displayValue: profile.name,
});

angular.module('app').component('sarProfileSettings', convertToAngular(SarProfileSettings));

function isDefaultProfile(profile: NormalizedSarProfile) {
  return !profile?.isCustom;
}
