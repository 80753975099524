import { module } from 'angular';
const app = module('app');

app.factory('breachResponseService', [
  '$http',
  'appSettings',
  function ($http, appSettings) {
    return {
      getAllResponses: function getAllResponses() {
        return $http
          .get(appSettings.serverPath + '/' + appSettings.version + '/breach-investigations')
          .then(function (response) {
            return response;
          });
      },
      getBreachResponseById: function getBreachResponseById(id) {
        return $http
          .get(appSettings.serverPath + '/' + appSettings.version + '/breach-investigations/' + id)
          .then(function (response) {
            return response;
          });
      },
      createBreachResponse: function createBreachResponse(data) {
        return $http
          .post(appSettings.serverPath + '/' + appSettings.version + '/breach-investigations', data)
          .then(function (response) {
            return response;
          })
          .catch(function (error) {
            return Promise.reject(error);
          });
      },
      updateBreachResponse: function updateBreachResponse(data) {
        return $http
          .put(appSettings.serverPath + '/' + appSettings.version + '/breach-investigations/' + data._id, data)
          .then(function (response) {
            return response;
          });
      },
      deleteBreachResponse: function deleteBreachResponse(id) {
        return $http
          .delete(appSettings.serverPath + '/' + appSettings.version + '/breach-investigations/' + id)
          .then(function (response) {
            return response;
          });
      },
      fetchSimilaritiesData: function fetchSimilaritiesData(dsName) {
        return $http
          .get(appSettings.serverPath + '/' + appSettings.version + '/breach-investigations/' + dsName + '/similar_ds')
          .then(function (response) {
            return response.data;
          });
      },
    };
  },
]);
