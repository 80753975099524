import React from 'react';
import { CLASSIFIER_TYPES, ClassifierGridRow } from '../types/ClassifierTypes';
import { useLocalTranslation } from '../translations';
import { BigidColorsV2, BigidHighlightIndicator, BigidStyledBadge, BigidTooltip } from '@bigid-ui/components';
import { styled } from '@mui/material';
import { generateDataAid } from '@bigid-ui/utils';
import { getClassifierTypeValue, getIsClassifiersAutoUpgradeDisable, isRegex } from '../utils/utils';
import { isEmpty } from 'lodash';

interface BigidStyledBadgeWrapperProps {
  enabled: boolean;
}

const BigidStyledBadgeWrapper = styled('div')`
  opacity: ${(props: BigidStyledBadgeWrapperProps) => (props.enabled ? 1 : 0.3)};

  & > div {
    padding: 0;
    height: auto;
  }
`;

const StyledSidePanelBasicInfo = styled('div')`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const StyledBasicInfo = styled('div')`
  display: flex;
  align-items: center;
  gap: 16px;
  font-size: 14px;
`;

const StyledTypeWrapper = styled('div')`
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 90px;
  white-space: nowrap;
`;

interface SidePanelBasicInfoProps {
  classifier: ClassifierGridRow;
  children: React.ReactNode;
}

export const SidePanelBasicInfo = ({ classifier, children }: SidePanelBasicInfoProps) => {
  const { t } = useLocalTranslation('sidePanel');
  const { isOutOfTheBox, enabled, classifierType, version } = classifier;

  return (
    <>
      {!isEmpty(classifier) && (
        <StyledSidePanelBasicInfo>
          <StyledBasicInfo>
            {classifierType !== CLASSIFIER_TYPES.CORRELATION && (
              <BigidStyledBadgeWrapper enabled={enabled}>
                <BigidStyledBadge
                  label={enabled ? t('enabled') : t('disabled')}
                  hasBackground={false}
                  backgroundColor=""
                  size="large"
                  icon={
                    <BigidHighlightIndicator
                      diameter={8}
                      color={enabled ? BigidColorsV2.green[600] : BigidColorsV2.gray[700]}
                    />
                  }
                />
              </BigidStyledBadgeWrapper>
            )}
            {isRegex(classifierType) ? (
              <StyledTypeWrapper data-aid={generateDataAid('SidePanelBasicInfo', ['type'])}>
                {t('type')}:&nbsp;
                <BigidTooltip placement="bottom" title={getClassifierTypeValue(classifierType)}>
                  <span>{getClassifierTypeValue(classifierType)}</span>
                </BigidTooltip>
              </StyledTypeWrapper>
            ) : (
              <span data-aid={generateDataAid('SidePanelBasicInfo', ['type'])}>
                {t('type')}: {getClassifierTypeValue(classifierType)}
              </span>
            )}
            <span data-aid={generateDataAid('SidePanelBasicInfo', ['origin'])}>
              {t('origin')}: {isOutOfTheBox ? t('ootb') : t('custom')}
            </span>
            {getIsClassifiersAutoUpgradeDisable() && version && (
              <span data-aid={generateDataAid('SidePanelBasicInfo', ['version'])}>
                {t('version')}: {version}
              </span>
            )}
          </StyledBasicInfo>
          <div>{children}</div>
        </StyledSidePanelBasicInfo>
      )}
    </>
  );
};
