import { BigidAdvancedToolbarFilterUnion, BigidDropdownOption } from '@bigid-ui/components';
import { RiskAggregationItem } from '../../CatalogDiscovery/catalogDiscoveryTypes';
import { ResultsEntityType } from './results';

export enum AggregationType {
  DATA_SOURCE_NAME = 'source',
  DATA_SOURCE_EXTENDED = 'sourceExtended',
  DATA_SOURCE_TYPE = 'source.type',
  DATA_SOURCE_LOCATION = 'source.location',
  DATA_SOURCE_CARDINALITY = 'sourceCardinality',
  OBJECT_OWNER = 'owner',
  OBJECT_STATUS = 'objectStatus',
  OBJECT_SCAN_STATUS = 'objectScanStatus',
  FILE_TYPE = 'fileType',
  DATA_FORMAT = 'dataFormat',
  APPLICATION = 'application',
  TAGS = 'tags',
  ATTRIBUTE_NAME = 'attribute',
  ATTRIBUTE_CATEGORY = 'category',
  ATTRIBUTE_TYPE = 'attributeType',
  ATTRIBUTE_CARDINALITY = 'attributeCardinality',
  HAS_DUPLICATES = 'duplicateFiles',
  HAS_FINDINGS = 'findings',
  HAS_OPEN_ACCESS = 'openAccess',
  VIOLATED_POLICY = 'violatedPolicies',
  SENSITIVITY = 'sensitivity',
  SENSITIVITY_FILTER = 'sensitivityFilter',
  CLOUD_TYPE = 'cloudType',
  SCANNED_VOLUME = 'scannedVolume',
  TOTAL_VOLUME = 'totalSizeInBytes',
  VIOLATED_POLICY_CARDINALITY = 'violatedPoliciesCardinality',
  CONTAINER_NAME = 'containerName',
  RISK = 'riskFilter',
  ACCESS_TYPE_FILTER = 'accessTypeFilter',
}

export type FetchFilterOptionsPreprocessorFunc = (items: BigidDropdownOption[]) => BigidDropdownOption[];

export type GetAggregatedDataResponse = {
  aggregations: Aggregation[];
};

export enum AggregationPossesionIdentifier {
  WITH = 'with',
  WITHOUT = 'without',
}

export enum SensitivityClassificationAggregationItem {
  CONFIDENTIAL = 'Confidential',
  RESTRICTED = 'Restricted',
  INTERNAL_USE = 'Internal Use',
  PUBLIC = 'Public',
}

export type AggregationItemBase = {
  aggItemName?: string;
  aggItemGroup?: string;
  findings?: number;
  docCount?: number;
  groupDocCount?: number;
};

export type AttributesGridAggregationItem = AggregationItemBase & {
  friendlyName: string;
  dataSourceCount: number;
  categories: (string | AttributesGridAggregationItemOtherCategory)[];
};

export type AttributesGridAggregationItemOtherCategory = 'Other';

export enum DataFormatAggregationItem {
  STRUCTURED = 'structured',
  UNSTRUCTURED = 'unstructured',
  EMAIL = 'email',
  OTHER = 'other',
}

export type AggregationItemName =
  | RiskAggregationItem
  | SensitivityClassificationAggregationItem
  | AggregationPossesionIdentifier
  | DataFormatAggregationItem
  | string;

export type AggregationFilterConfig = Omit<BigidAdvancedToolbarFilterUnion, 'asyncOptionsFetch' | 'title' | 'value'> & {
  hasNestedItems?: boolean;
  isBoolean?: boolean;
  getUserSearchQueryPreprocessed?: (value: string) => string;
  getFilterOptionsPreprocessed?: (options: BigidDropdownOption[]) => BigidDropdownOption[];
  leftOperandComposerFunc?: (filter: BigidAdvancedToolbarFilterUnion) => string;
  parseFilterToQueryFunc?: (filter: BigidAdvancedToolbarFilterUnion[]) => string;
};

export type Paging = {
  limit: number;
  skip: number;
};

export type Sorting = {
  field: string;
  order: 'DESC' | 'ASC';
};

export type GetAggredatedDataRequestObject = {
  aggName: AggregationType;
  sorting?: Sorting[];
  paging?: Paging;
  isGrid?: boolean;
  isTotalRequired?: boolean;
};

export type GetAggregatedDataPayload = {
  filter?: string;
  aggregations: Partial<GetAggredatedDataRequestObject>[];
  searchText?: string;
  entityType?: ResultsEntityType;
};

export enum NonAggregationType {
  SCAN_DATE = 'scanDate',
  LAST_ACCESS_DATE = 'last_opened',
  MODIFIED_DATE = 'modified_date',
}

export enum AggregationFilterOperand {
  DATA_SOURCE_NAME = 'system',
  DATA_SOURCE_TYPE = 'source.type',
  DATA_SOURCE_LOCATION = 'source.location',
  SCAN_DATE = 'scanDate',
  OBJECT_OWNER = 'owner',
  CLOUD_TYPE = 'source.type',
  FILE_TYPE = 'fileType',
  DATA_FORMAT = 'scanner_type_group',
  APPLICATION = 'application',
  TAGS = 'catalog_tag',
  ATTRIBUTE_CATEGORY = 'field',
  ATTRIBUTE_NAME = 'field',
  ATTRIBUTE_TYPE = 'attribute_details.type',
  HAS_DUPLICATES = 'has_duplicates',
  HAS_OPEN_ACCESS = 'metadata.access_rights',
  SENSITIVITY_FILTER = 'catalog_tag.system.sensitivityClassification',
  POLICY = 'policyName',
  OBJECT_STATUS = 'contains_pi',
  OBJECT_SCAN_STATUS = 'scanStatus',
  CONTAINER_NAME = 'containerName',
  LAST_ACCESS_DATE = 'last_opened',
  MODIFIED_DATE = 'modified_date',
  RISK = 'catalog_tag.system.risk.riskGroup',
  ACCESS_TYPE_FILTER = 'catalog_tag',
}

export type Aggregation<AggrItem = AggregationItemBase> = {
  aggName: AggregationType;
  aggData: AggrItem[];
  aggTotal: number;
};

export type FetchOptionsAggregationRequest = {
  id: AggregationType;
  title: string;
};

export type FetchOptionsAggregationResponse = {
  id: AggregationType;
};

export type FetchAllOptionsAggregationsResponse = {
  aggregations: FetchOptionsAggregationResponse[];
};
