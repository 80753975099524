import { getSupportedDataSources as getSupportedDataSourceTypes } from '../../../../utilities/dataSourcesUtils';
import { QueryFunction, QueryKey, UseQueryOptions, UseQueryResult, useQuery } from 'react-query';
import type { AsyncReturnType } from '../../DataSourceConnectionModal/types';
import type { ErrorType } from '../../../../config/request';

export type GetSupportedDataSourceTypesQueryResult = NonNullable<AsyncReturnType<typeof getSupportedDataSourceTypes>>;

export type GetSupportedDataSourceTypesQueryError = ErrorType<unknown>;

export type GetSupportedDataSourceTypesData = any;

export const useGetScanTypes = (
  dataSourceType: string,
  options?: {
    query?: UseQueryOptions<
      AsyncReturnType<typeof getSupportedDataSourceTypes>,
      GetSupportedDataSourceTypesQueryError,
      GetSupportedDataSourceTypesData
    >;
  },
) => {
  const results = useGetSupportedDataSourceTypes({
    ...options,
    query: {
      ...options?.query,
      select: dataSources => dataSources?.find(({ type }) => type === dataSourceType)?.supportedScanTypes ?? [],
      enabled: options?.query?.enabled ?? !!dataSourceType,
    },
  });

  return results;
};

export const getSupportedDataSourceTypesQueryKey = (): Array<unknown> => ['getSupportedDataSourceTypes'];

export const useGetSupportedDataSourceTypes = <
  TData = AsyncReturnType<typeof getSupportedDataSourceTypes>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: UseQueryOptions<AsyncReturnType<typeof getSupportedDataSourceTypes>, TError, TData>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options || {};

  const queryKey = queryOptions?.queryKey ?? getSupportedDataSourceTypesQueryKey();
  const queryFn: QueryFunction<AsyncReturnType<typeof getSupportedDataSourceTypes>> = async () =>
    getSupportedDataSourceTypes(true);
  const query = useQuery<AsyncReturnType<typeof getSupportedDataSourceTypes>, TError, TData>(queryKey, queryFn, {
    ...queryOptions,
  });

  return {
    queryKey,
    ...query,
  };
};
