import React, { FC } from 'react';
import { BigidDialog, BigidDialogProps, BigidBody1, PrimaryButton, SecondaryButton } from '@bigid-ui/components';

interface NoVerficiationDialogProps extends Pick<BigidDialogProps, 'isOpen' | 'onClose'> {
  onContinue: () => void;
  hasEmptyVerificationKey: boolean;
}

export const NoVerificationDialog: FC<NoVerficiationDialogProps> = ({
  isOpen,
  onClose,
  onContinue,
  hasEmptyVerificationKey,
}) => {
  const buttons = [
    { component: SecondaryButton, text: 'Continue', onClick: onContinue, dataAid: 'onContinueNoVerify' },
    { component: PrimaryButton, text: 'Close', onClick: onClose, dataAid: 'onCloseNoVerify' },
  ];

  return (
    <BigidDialog
      isOpen={isOpen}
      onClose={onClose}
      title={`App Verification Code is ${hasEmptyVerificationKey ? 'Missing' : 'Invalid'}`}
      buttons={buttons}
    >
      <BigidBody1>Click continue only if you trust this app.</BigidBody1>
    </BigidDialog>
  );
};
