import React from 'react';
import { BigidButtonIcon, SecondaryButton, TertiaryButton } from '@bigid-ui/components';
import { BigidSaveIcon } from '@bigid-ui/icons';
import { RunAndScanWithConfirm } from '../AutoDiscoveryWizard/autoDiscoveryComponents/RunAndScanWithConfirm';
import { useActionHandlers } from './useActionHandlers';
import { AutoDiscoveryWizardContextInterface } from '../AutoDiscoveryWizard/autoDiscoveryWizardContext';
import { $state } from '../../../services/angularServices';
import { CONFIG } from '../../../../config/common';
import { DuplicatePresetWithConfirm } from '../AutoDiscoveryWizard/autoDiscoveryComponents/DuplicatePresetWithConfirm';
import { SchedulePreset } from '../AutoDiscoveryWizard/autoDiscoveryComponents/SchedulePreset';
import { Divider } from '@mui/material';

export const useActions = ({
  discoveryConfigData,
  setDiscoveryConfigData,
  discoveryConfigDataRef,
}: AutoDiscoveryWizardContextInterface) => {
  const { isAssessmentScanEnabled, isDisabled, onSavePreset, onRunDiscover, isDiscoveryReady } = useActionHandlers({
    discoveryConfigData,
    setDiscoveryConfigData,
    discoveryConfigDataRef,
  });

  if (
    discoveryConfigData.isLoadingPreset ||
    discoveryConfigData.isTypeNotFound ||
    discoveryConfigData.isLoadingPreset === undefined
  ) {
    return [];
  }

  return [
    <RunAndScanWithConfirm
      key="1"
      onRunDiscover={onRunDiscover}
      isDisabled={!isDiscoveryReady || !isAssessmentScanEnabled}
    />,
    <SecondaryButton
      dataAid={'Run-discovery'}
      key="2"
      size="medium"
      text="Run"
      onClick={() => onRunDiscover()}
      disabled={isDisabled}
    />,
    <TertiaryButton
      key="3"
      onClick={() => {
        $state.go(CONFIG.states.DATA_SOURCE_CONNECTIONS, { tab: 'discovery' });
      }}
      size="medium"
      text="Close"
    />,
    <div key="Divider" style={{ height: '20px' }}>
      <Divider orientation="vertical" />
    </div>,
    <BigidButtonIcon
      key="4"
      icon={BigidSaveIcon}
      disabled={!discoveryConfigData?.formData?.name}
      onClick={async () => {
        const presetId = await onSavePreset();
        if (presetId) {
          $state.transitionTo(
            CONFIG.states.AUTO_DISCOVERY_PRESET_CONFIG,
            { ...$state.params, id: presetId },
            { location: true, inherit: true, relative: $state.$current, notify: false, reload: false },
          );
        }
      }}
      dataAid="savePreset"
    />,
    ...(discoveryConfigData.isEdit && !discoveryConfigData.isTypeNotFound && !discoveryConfigData.isIdNotFound
      ? [<SchedulePreset key="5" />, <DuplicatePresetWithConfirm key="6" />]
      : []),
  ];
};
