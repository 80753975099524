import {
  BigidLineChart,
  BigidLineChartDataItem,
  BigidChartLegendPosition,
  BigidLineChartProps,
} from '@bigid-ui/components';
import { module, IAngularEvent } from 'angular';
import { $rootScope } from 'ngimport';
import React, { FC, useEffect, useState } from 'react';
import { riskTrendService } from '../../services/angularServices';
import { convertToAngular } from '../../../common/services/convertToAngular';
import { isPermitted } from '../../services/userPermissionsService';
import { APPLICATIONS_PERMISSIONS } from '@bigid/permissions';
interface Risk extends BigidLineChartDataItem {
  x: number;
  y: number;
  piiCount: number;
  currentRisk?: number;
}
interface BigidSplineProps {
  showRiskLine: boolean;
  showRiskScoreLine: boolean;
}

const mergeHighLow = (riskData: Risk[] = [], highLowData: Risk[] = []) => {
  const newData = [...riskData];
  highLowData.forEach(item => {
    const exists = newData.find(risk => risk.date == item.date);
    if (exists) {
      exists.currentRisk = item.y;
    }
  });
  return newData;
};

const extractRiskData = (snapshot: Risk[] = []) => {
  const newData: Risk[] = [];
  snapshot.forEach(snap => {
    const dateTemp = new Date(snap.date);
    const x = Date.UTC(dateTemp.getUTCFullYear(), dateTemp.getUTCMonth(), dateTemp.getUTCDate());

    const risk: Risk = {
      date: snap.date,
      x,
      y: Math.round(Number(snap.risk)),
      piiCount: Number(snap.count),
    };

    if (newData.length) {
      const existRisk = newData.find(r => r.date === risk.date);

      if (existRisk) {
        existRisk.y = Math.round(risk.y);
      } else {
        newData.push(risk);
      }
    } else {
      newData.push(risk);
    }
  });
  return newData;
};

const extractHighLowScore = (snapshot: Risk[] = [], riskScore: number) => {
  let lowest = Number.POSITIVE_INFINITY;
  let highest = Number.NEGATIVE_INFINITY;
  let tmp = 0;
  const lowestObject = {} as Risk;
  const highestObject = {} as Risk;

  for (let i = snapshot.length - 1; i >= 0; i--) {
    tmp = snapshot[i].x;
    if (tmp < lowest) {
      lowest = tmp;
      lowestObject.x = snapshot[i].x;
      lowestObject.y = Math.round(snapshot[i].y);
      lowestObject.piiCount = snapshot[i].piiCount;
      lowestObject.date = snapshot[i].date;
    }
    if (tmp > highest) {
      highest = tmp;
      highestObject.x = snapshot[i].x;
      highestObject.y = Math.round(snapshot[i].y);
      highestObject.piiCount = snapshot[i].piiCount;
      highestObject.date = snapshot[i].date;
    }
  }

  highestObject.y = Math.round(Number(riskScore));
  lowestObject.y = Math.round(Number(riskScore));

  return [lowestObject, highestObject];
};

interface LooseObject {
  [key: string]: any;
}

const DASHBOARD_LINE_CHART_DATES_INTERVAL: BigidLineChartProps['dateInterval'] = [
  { timeUnit: 'day', count: 1 },
  { timeUnit: 'day', count: 2 },
  { timeUnit: 'day', count: 3 },
  { timeUnit: 'day', count: 4 },
  { timeUnit: 'day', count: 5 },
  { timeUnit: 'day', count: 6 },
  { timeUnit: 'week', count: 1 },
  { timeUnit: 'week', count: 2 },
  { timeUnit: 'week', count: 3 },
  { timeUnit: 'week', count: 4 },
  { timeUnit: 'month', count: 1 },
  { timeUnit: 'month', count: 2 },
  { timeUnit: 'month', count: 3 },
  { timeUnit: 'month', count: 4 },
  { timeUnit: 'month', count: 5 },
  { timeUnit: 'month', count: 6 },
  { timeUnit: 'year', count: 1 },
];

export const BigidSpline: FC<BigidSplineProps> = ({ showRiskLine = true, showRiskScoreLine = true }) => {
  const [riskData, setRiskData] = useState<Risk[]>([]);
  const [titles, setTitles] = useState<LooseObject>({});
  const [series, setSeries] = useState<string[]>([]);

  const dataForChart = async (queryString: string, isRiskConfiguration: boolean, shouldFetchRisk: boolean) => {
    if (shouldFetchRisk) {
      const { risk_snapshots } = await riskTrendService.getRiskSnapShots(queryString, isRiskConfiguration);
      return risk_snapshots;
    }
    return [];
  };

  useEffect(() => {
    const onQueryRiskScore = async (
      event: IAngularEvent,
      queryRiskScore: number,
      queryString: string,
      isRiskConfiguration: boolean,
    ) => {
      const shouldFetchRisk = isPermitted(APPLICATIONS_PERMISSIONS.READ_RISK_CONFIGURATION.name);
      const dataToDisplay = await dataForChart(queryString, isRiskConfiguration, shouldFetchRisk);
      const legends: LooseObject = {};
      const series: string[] = [];

      if (dataToDisplay) {
        let newData: Risk[] = [];

        if (showRiskLine) {
          series.push('y');
          legends.y = 'Risk';
          newData = extractRiskData(dataToDisplay);
        }

        if (showRiskScoreLine) {
          series.push('currentRisk');
          legends.currentRisk = 'Current Risk Score';
          const highLowScore = extractHighLowScore(newData, queryRiskScore);
          newData = mergeHighLow(newData, highLowScore);
        }

        setTitles(legends);
        setSeries(series);
        setRiskData(newData);
      }
    };

    const queryRiskScoreListener = $rootScope.$on('getQueryRiskScore', onQueryRiskScore);

    return () => {
      queryRiskScoreListener();
    };
  });

  return (
    <BigidLineChart
      data={riskData}
      series={series}
      titles={titles}
      colors={{ y: '#ff872e', currentRisk: '#e91e63' }}
      minValue={0}
      notShowBulletIfSameValue={false}
      skipEmptyPeriods={true}
      tooltip
      hideValuesLabels={false}
      dateInterval={DASHBOARD_LINE_CHART_DATES_INTERVAL}
    />
  );
};

module('app').component(
  'bigidSpline',
  convertToAngular<BigidSplineProps>(BigidSpline, ['showRiskLine', 'showRiskScoreLine']),
);
