import React, { FC } from 'react';
import angular from 'angular';
import { BigidImportDialog, BigidImportDialogProps } from '@bigid-ui/components';
import { $state } from '../../../services/angularServices';
import { sendSSERequestWithActionCallback, SSEDataMessage, SSEOperationStatus } from '../../../services/sseService';
import { notificationService } from '../../../services/notificationService';
import { convertToAngular } from '../../../../common/services/convertToAngular';
import { sessionStorageService } from '../../../../common/services/sessionStorageService';
import { getApplicationPreference } from '../../../services/appPreferencesService';

export interface Props extends Pick<BigidImportDialogProps, 'isOpen' | 'onClose'> {}

export const CorrelationSetsImportDialog: FC<Props> = ({ isOpen, onClose }) => {
  const importFile = async (file: File) => {
    const sseActionCallback = ({ operationStatus, message }: SSEDataMessage) => {
      if (operationStatus === SSEOperationStatus.BLOCKED) {
        notificationService.warning(message);
      } else {
        $state.reload().then(() => {
          notificationService.success(message);
        });
      }
    };

    const {
      data: { message },
    } = await sendSSERequestWithActionCallback<{ message: string }>(
      'post',
      `id_connections/import`,
      {
        files: { file },
      },
      {},
      sseActionCallback,
    );

    notificationService.success(message);
  };

  const maxSize: number = getApplicationPreference('MAX_UPLOAD_LIMITATION');

  return (
    <BigidImportDialog
      isOpen={isOpen}
      onClose={onClose}
      onImportClicked={importFile}
      title={'Import Correlation Learning Sets Connections File'}
      description={`You will need to run a test connection for each new correlation set. A valid data source must be defined for each correlation set.`}
      accept={['.json']}
      maxSize={maxSize}
    />
  );
};

angular
  .module('app')
  .component('entitySourcesImportDialog', convertToAngular(CorrelationSetsImportDialog, ['isOpen', 'onClose']));
