import React from 'react';
import { BigidHeading6 } from '@bigid-ui/components';

export type BigidSuggestedActionBoxHeaderProps = {
  title: string;
};

export const BigidSuggestedActionBoxHeader = ({ title }: BigidSuggestedActionBoxHeaderProps): JSX.Element => (
  <>
    <BigidHeading6 padding={'0px 8px'} flexGrow={1}>
      {title}
    </BigidHeading6>
  </>
);
