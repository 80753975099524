import { useState, useEffect, useRef } from 'react';
import {
  BigidDropdownOption,
  BigidFormField,
  BigidFormFieldTypes,
  BigidFormProps,
  BigidFormStateAndHandlers,
  BigidFormValidateOnTypes,
  BigidFormValues,
} from '@bigid-ui/components';
import { isNameValid } from '../../utilities/validation';
import { getFixedT } from './translations';
import { EmailTemplateData, EmailTemplateType } from './emailEditorService';

const INITIAL_VALUES = {
  templateName: '',
  templateCategory: [
    {
      value: '',
    },
  ],
  templateDescription: '',
};

const tLables = getFixedT('formLables');
const tTemplateCategory = getFixedT('formLables.templateCategories');
const tMessages = getFixedT('messages');

const generateTemplateDetailsFields = (
  editStoredTemplateMode: boolean,
  emailTypes: EmailTemplateType[],
  viewOnlyMode: boolean,
): BigidFormField[] => {
  return [
    {
      name: 'templateName',
      label: tLables('templateName') as string,
      type: BigidFormFieldTypes.TEXT,
      validate: (name: string) => {
        if (!isNameValid(name)) {
          return tMessages('invalidTemplateName') as string;
        }
        return false;
      },
      ...(viewOnlyMode && { fieldProps: { readOnly: true } }),
    },
    {
      name: 'templateCategory',
      label: tLables('templateCategory') as string,
      type: BigidFormFieldTypes.DROP_DOWN,
      dropDownOptions: emailTypes.map(type => {
        return { id: type, value: type, displayValue: tTemplateCategory(type) };
      }),
      fieldProps: { placeholder: 'Select email category' },
      validate: (selectedOption: BigidDropdownOption[]) => {
        if (!selectedOption?.length || !selectedOption[0]?.value) {
          return tMessages('missingCategorySelection') as string;
        }
        return false;
      },
      disabled: editStoredTemplateMode,
    },
    {
      name: 'templateDescription',
      label: tLables('templateDescription') as string,
      type: BigidFormFieldTypes.TEXT,
      misc: {
        multiline: true,
        rows: 3,
        category: 'templateDetails',
      },
      ...(viewOnlyMode && { fieldProps: { readOnly: true } }),
    },
  ];
};

const generateInitialValues = (templateData: EmailTemplateData) => {
  const { name, type, description } = templateData;
  return {
    templateName: name,
    templateCategory: [
      {
        id: type,
        value: type,
        displayValue: tTemplateCategory(type),
      },
    ],
    templateDescription: description,
  };
};

export const useTemplateDetailsFields = (
  templateData: EmailTemplateData,
  editStoredTemplateMode: boolean,
  emailTypes: EmailTemplateType[],
  viewOnlyMode: boolean,
) => {
  const [fields, setFields] = useState<BigidFormField[]>([]);
  const [initialValues, setInitialValues] = useState<BigidFormValues>({});
  const [selectedCategory, setSelectedCategory] = useState<EmailTemplateType>(null);
  const templateDetailsFormControls = useRef<BigidFormStateAndHandlers>();

  const templateDetailsFormProps: BigidFormProps = {
    controlButtons: false,
    validateOn: BigidFormValidateOnTypes.SUBMIT,
    stateAndHandlersRef: templateDetailsFormControls,
    initialValues,
    onChange: values => {
      const categorySelection = values?.templateCategory?.[0]?.value as EmailTemplateType;
      if (categorySelection) {
        setSelectedCategory(categorySelection);
      }
    },
    displayFormLevelError: true,
    fields,
  };

  useEffect(() => {
    setFields(generateTemplateDetailsFields(editStoredTemplateMode, emailTypes, viewOnlyMode));
    if (templateData && editStoredTemplateMode) {
      const newInitialValues = generateInitialValues(templateData);
      setInitialValues(newInitialValues);
    }
  }, [emailTypes, editStoredTemplateMode, templateData, viewOnlyMode]);

  const resetTemplateDetailsFields = () => {
    if (templateData) {
      templateDetailsFormControls.current.clear();
    }
    setSelectedCategory(null);
    setInitialValues(INITIAL_VALUES);
  };

  return {
    templateDetailsFormProps,
    templateDetailsFormControls,
    selectedCategory,
    resetTemplateDetailsFields,
  };
};
