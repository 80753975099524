import React, { FC, ReactElement } from 'react';
import { noop, truncate } from 'lodash';
import { openSystemDialog } from '../../../services/systemDialogService';
import {
  BigidAdditionalItemsChip,
  BigidBody1,
  BigidChip,
  BigidColorsV2,
  BigidFieldFilterOperator,
  BigidHeading6,
  BigidLink,
  PrimaryButton,
  SecondaryButton,
} from '@bigid-ui/components';
import { fetchActionCenterMetadata, getWorkflows } from '../ActionWorkflow/actionWorkflowService';
import { showConfirmationDialog } from '../../../services/confirmationDialogService';
import makeStyles from '@mui/styles/makeStyles';
import { getApplicationPreference } from '../../../services/appPreferencesService';
import { EntityType } from '../ActionWorkflow/actionWorkflowTypes';

const WORKFLOWS_LIMIT = 5;

interface DeleteEntityDialogContentProps {
  workflowNames: string[];
  totalCount: number;
  single: boolean;
  link?: string;
  entityNameSingular: string;
  entityNamePlural?: string;
  customDescription?: string;
  ContentComponent?: ReactElement;
}

const useStyles = makeStyles({
  chipsWrapper: { display: 'flex', alignItems: 'center', margin: '16px 0', flexWrap: 'wrap', gap: '6px' },
  chip: {
    '&:not(:last-child)': {
      marginRight: 8,
    },
  },
  bottomTextWrapper: { display: 'flex', alignItems: 'center', marginTop: 16 },
  link: { fontSize: '0.875rem', marginLeft: 6 },
});

export const DeleteEntityDialogContent: FC<DeleteEntityDialogContentProps> = ({
  workflowNames,
  totalCount,
  link,
  single,
  entityNameSingular,
  entityNamePlural,
  customDescription,
}) => {
  const { chipsWrapper, chip, link: linkStyle, bottomTextWrapper } = useStyles({});
  const shouldItemsChip = totalCount > WORKFLOWS_LIMIT;
  const actionSuffix = totalCount > 1 ? 's' : '';
  const entityName = single ? entityNameSingular : entityNamePlural;

  return (
    <>
      <BigidBody1>{`The ${entityName} ${
        single ? 'is' : 'are'
      } triggered by the following action${actionSuffix}:`}</BigidBody1>
      <div className={chipsWrapper}>
        {workflowNames.map((workflowName, key) => (
          <div className={chip} key={key}>
            <BigidChip
              label={truncate(workflowName, { length: 20 })}
              bgColor={BigidColorsV2.purple[200]}
              color={BigidColorsV2.gray[700]}
              size="small"
              dataAid={`BigidChip-${workflowName}`}
            />
          </div>
        ))}
        {shouldItemsChip && <BigidAdditionalItemsChip isTooltipDisabled total={totalCount - WORKFLOWS_LIMIT} />}
      </div>
      <BigidBody1>{`Deleting ${
        single ? 'this' : 'these'
      } ${entityName} will remove it from the above action${actionSuffix}.`}</BigidBody1>
      <div className={bottomTextWrapper}>
        {customDescription || (
          <BigidHeading6>{`Are you sure you want to delete ${
            single ? 'this' : 'these'
          } ${entityName}? `}</BigidHeading6>
        )}
        {link && <BigidLink href={link} text="Read more" shouldOpenNewTab underline="always" classNames={linkStyle} />}
      </div>
    </>
  );
};

const getQuery = async (ids: string[], entityType: EntityType, allSelected?: boolean) => {
  let filter = [
    {
      field: entityType === EntityType.RECEIVERS ? 'receivers.username' : 'entityIds',
      value: ids,
      operator: 'in' as BigidFieldFilterOperator,
    },
  ];

  if (allSelected) {
    const {
      data: { data: actionCenterMetadata },
    } = await fetchActionCenterMetadata();

    const eventsWithEntity = actionCenterMetadata.events.reduce((events, { supportedEvents }) => {
      return [...events, ...supportedEvents.filter(event => event.includes(entityType))];
    }, []);

    filter = [
      {
        field: 'event',
        value: eventsWithEntity,
        operator: 'in' as BigidFieldFilterOperator,
      },
    ];
  }

  return {
    filter,
    skip: 0,
    limit: WORKFLOWS_LIMIT,
    requireTotalCount: true,
  };
};

interface ShowDeleteEntityDialog
  extends Pick<
    DeleteEntityDialogContentProps,
    'link' | 'entityNameSingular' | 'entityNamePlural' | 'customDescription' | 'ContentComponent'
  > {
  ids: string[];
  entityType: EntityType;
  totalRows?: number;
  allSelected?: boolean;
}

const cancelButtonConfiguration = {
  text: 'Cancel',
  component: SecondaryButton,
  onClick: noop,
  isClose: true,
  dataAid: 'DeleteEntityDialog-CancelButton',
};

const deleteButtonConfiguration = {
  text: 'Delete',
  component: PrimaryButton,
  onClick: noop,
  isClose: true,
  dataAid: 'DeleteEntityDialog-DeleteButton',
};

const getWorkflowData = async ({
  ids,
  entityType,
  allSelected,
}: Pick<ShowDeleteEntityDialog, 'ids' | 'entityType' | 'allSelected'>) => {
  const query = await getQuery(ids, entityType, allSelected);
  const data = await getWorkflows(query);
  const workflowNames = data.workflows.map(({ name }) => name);
  const totalCount = data.totalCount;
  return { workflowNames, totalCount };
};

export const showDeleteACEntityDialog = async ({
  ids = [],
  entityType,
  link,
  entityNameSingular,
  entityNamePlural,
  totalRows,
  customDescription,
  allSelected,
  ContentComponent,
}: ShowDeleteEntityDialog): Promise<boolean> => {
  const entitiesCount = totalRows || ids.length;
  const title = `Delete ${entitiesCount === 1 ? entityNameSingular : entityNamePlural}`;
  if (!getApplicationPreference('ENABLE_ACTION_CENTER')) {
    return showConfirmationDialog({
      entitiesCount,
      entityNameSingular,
      entityNamePlural,
      actionButtonName: 'Delete',
      customDescription,
      ContentComponent,
    });
  }

  openSystemDialog({
    title,
    buttons: [cancelButtonConfiguration, deleteButtonConfiguration],
    onClose: () => null,
    content: null,
    borderTop: true,
    isLoading: true,
  });

  const { workflowNames = [], totalCount = 0 } = await getWorkflowData({ ids, allSelected, entityType });

  if (totalCount === 0) {
    return showConfirmationDialog({
      entitiesCount,
      entityNameSingular,
      entityNamePlural,
      actionButtonName: 'Delete',
      customDescription,
      ContentComponent,
    });
  }

  return new Promise(resolve => {
    openSystemDialog<DeleteEntityDialogContentProps>({
      title,
      onClose: () => resolve(false),
      content: DeleteEntityDialogContent,
      contentProps: {
        workflowNames,
        totalCount,
        link,
        single: entitiesCount === 1,
        entityNameSingular,
        entityNamePlural,
        customDescription,
        ContentComponent,
      },
      borderTop: true,
      isLoading: false,
      buttons: [
        { ...cancelButtonConfiguration, onClick: () => resolve(false) },
        { ...deleteButtonConfiguration, onClick: () => resolve(true) },
      ],
    });
  });
};
