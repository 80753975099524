import React, { FC } from 'react';
import { BigidColors } from '@bigid-ui/components';
import { Avatar } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import LockIcon from '../../assets/icons/Lock.svg';

interface LockItemProps {
  relative?: boolean;
  dataAid: string;
  size?: 'sm' | 'md';
  background?: string;
}

type LockItemStyle = Pick<LockItemProps, 'relative' | 'size' | 'background'>;

const useLockStyles = makeStyles({
  root: ({ relative, size, background }: LockItemStyle) => ({
    background,
    ...(size === 'md' ? { width: 26, height: 26 } : { width: 18, height: 18 }),
    ...(relative && { position: 'absolute', top: 0, right: 8 }),
  }),
  icon: ({ size }: LockItemStyle) => ({
    stroke: BigidColors.gray[600],
    ...(size === 'md' ? { width: 16, height: 16 } : { width: 12, height: 12 }),
  }),
});

export const LockItem: FC<LockItemProps> = ({ relative, dataAid, size = 'sm', background = BigidColors.gray[200] }) => {
  const classes = useLockStyles({ relative, size, background });
  return (
    <Avatar classes={{ root: classes.root }} data-aid={dataAid}>
      <LockIcon className={classes.icon} />
    </Avatar>
  );
};
