import React from 'react';
import { SuggestedActionItem } from './mappers/styles';
import { BigidBody1, BigidInlineNotification } from '@bigid-ui/components';
import { BigidBookIcon } from '@bigid-ui/icons';
import type { SuggestedActionButtonType } from './types';

export type DataSourceSuggestedActionItemProps = {
  dataAid?: string;
  label: string;
  actions?: SuggestedActionButtonType[];
  tooltip?: string;
};

type SuggestedActionTooltipProps = {
  dataAid: string;
  text: string;
};

const SuggestedActionTooltip = ({ dataAid, text }: SuggestedActionTooltipProps): JSX.Element => (
  <BigidInlineNotification dataAid={dataAid} icon={BigidBookIcon} open type="info" text={[{ subText: text }]} />
);

export const DataSourceSuggestedActionItem = ({
  dataAid = 'DataSourceSuggestedActionItem',
  label,
  tooltip,
}: DataSourceSuggestedActionItemProps): JSX.Element => {
  return (
    <SuggestedActionItem.Wrapper data-aid={dataAid}>
      <SuggestedActionItem.Content>
        <BigidBody1>{label}</BigidBody1>
        {tooltip && <SuggestedActionTooltip dataAid={`${dataAid}-tooltip`} text={tooltip} />}
      </SuggestedActionItem.Content>
    </SuggestedActionItem.Wrapper>
  );
};
