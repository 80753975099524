import React, { FC, useContext, useState } from 'react';
import styled from '@emotion/styled';
import { Divider } from '@mui/material';
import { ConvertScanProfileOverviewContent } from './ConvertScanProfileOverviewContent';
import { ConvertScanProfileOverviewHeader } from './ConvertScanProfileOverviewHeader/ConvertScanProfileOverviewHeader';
import { BigidLoader, BigidTooltip, EntityEvents, PrimaryButton, entityEventsEmitter } from '@bigid-ui/components';
import { convertScanProfiles } from '../../ScanProfiles/scanProfileService';
import { ConvertScanProfileContext } from './convertScanProfileContext';
import { closeSystemDialog } from '../../../../services/systemDialogService';
import { BigidHelpIcon } from '@bigid-ui/icons';
import { useLocalTranslation } from '../../translations';

const Container = styled('div')`
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const ButtonsSection = styled('div')`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-top: 16px;
  gap: 12px;
`;

const IconContainer = styled('div')`
  align-self: center;
  display: flex;
`;

const DividerHeader = styled(Divider)`
  margin-bottom: 24px;
  margin-left: -12px;
  margin-right: -12px;
`;

const DividerFooter = styled(Divider)`
  margin-top: 24px;
  margin-left: -12px;
  margin-right: -12px;
`;

export const ConvertScanProfileOverview: FC = () => {
  const [isLoading, setIsLoading] = useState(false);
  const { scanProfileData, isForReview } = useContext(ConvertScanProfileContext);
  const { t } = useLocalTranslation('convertScanProfile.overview');

  const handleConvert = async () => {
    try {
      setIsLoading(true);
      await convertScanProfiles({ ids: [scanProfileData._id] });
      closeSystemDialog();
      entityEventsEmitter.emit(EntityEvents.RELOAD);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Container>
      {isLoading && <BigidLoader />}
      <ConvertScanProfileOverviewHeader />
      <DividerHeader orientation="horizontal" />
      <ConvertScanProfileOverviewContent />
      <DividerFooter orientation="horizontal" />
      {!isForReview && (
        <ButtonsSection>
          <BigidTooltip title={t('convertTolltip')}>
            <IconContainer>
              <BigidHelpIcon size="medium" />
            </IconContainer>
          </BigidTooltip>
          <PrimaryButton size="medium" onClick={handleConvert} text={t('convert')} dataAid="handle-convert" />
        </ButtonsSection>
      )}
    </Container>
  );
};
