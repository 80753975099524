import { BigidToolbar, ToolbarAction, ToolbarActionType } from '@bigid-ui/components';
import { BigidAddTagIcon, BigidExportIcon, BigidRemoveTagIcon } from '@bigid-ui/icons';
import { styled } from '@mui/material';
import React, { FC, useState } from 'react';
import { getDataExplorerSearchResultsCSV } from '../../../../explorerSearchResultsService';
import { useDataCatalogSearchResultsContext } from '../../../../contexts/dataCatalogSearchResultsContext';
import { useLocalTranslation } from '../../../../translations';
import { WithDataAid } from '../../../../types';
import { DataExplorerBulkTagAssignmentDialog } from './DataExplorerBulkTagAssignmentDialog';
import { TagAssignmentMode } from './actionsService';
import {
  sendDataExplorerResultsPageAddTagCloseBiAnalytics,
  sendDataExplorerResultsPageAddTagOpenBiAnalytics,
  sendDataExplorerResultsPageAddTagSubmitBiAnalytics,
  sendDataExplorerResultsPageRemoveTagCloseBiAnalytics,
  sendDataExplorerResultsPageRemoveTagOpenBiAnalytics,
  sendDataExplorerResultsPageRemoveTagSubmitBiAnalytics,
  sendDataExplorerResultsPageExportSubmitBiAnalytics,
  sendDataExplorerResultsPageShareCopyBiAnalytics,
} from '../../../../../DataExplorer/services/analytics';
import { getActionFilterString } from './actionUtils';

const Container = styled('div')`
  border-bottom: 1px solid ${({ theme }) => theme.palette.bigid.gray200};
`;

type BulkTagDialogConfig = {
  isOpen: boolean;
  type: TagAssignmentMode;
};

export const Actions: FC<WithDataAid> = ({ dataAid = 'Actions' }) => {
  const [bulkTagDialogConfig, setBulkTagDialogConfig] = useState<BulkTagDialogConfig>({
    isOpen: false,
    type: null,
  });
  const {
    state: { gridConfig, savedFiltersMap },
    entityType,
    query,
  } = useDataCatalogSearchResultsContext();
  const { selectedRowsIds, isSelectAll, totalCount, unselectedRowsIds } = gridConfig;
  const filter = getActionFilterString({
    filter: savedFiltersMap[entityType],
    isSelectAll,
    selectedRowsIds,
    unselectedRowsIds,
  });

  const { t } = useLocalTranslation('actions');

  const handleExportButtonClick = () => {
    getDataExplorerSearchResultsCSV({
      searchText: query,
      filter,
    });

    sendDataExplorerResultsPageExportSubmitBiAnalytics();
  };

  const handleAssignTagButtonClick = () => {
    setBulkTagDialogConfig({
      isOpen: true,
      type: 'assign',
    });

    sendDataExplorerResultsPageAddTagOpenBiAnalytics();
  };

  const handleUnassignTagButtonClick = () => {
    setBulkTagDialogConfig({
      isOpen: true,
      type: 'unassign',
    });

    sendDataExplorerResultsPageRemoveTagOpenBiAnalytics();
  };
  const areActionsHidden = selectedRowsIds.length === 0 && !isSelectAll;
  const areTagsActionsDisabled =
    (!Boolean(query) && isSelectAll && unselectedRowsIds.length === 0 && !filter) || areActionsHidden;

  const handleBulkTagDialogClose = (mode: TagAssignmentMode) => {
    switch (mode) {
      case 'assign':
        sendDataExplorerResultsPageAddTagCloseBiAnalytics();
        break;
      case 'unassign':
        sendDataExplorerResultsPageRemoveTagCloseBiAnalytics();
        break;
    }

    setBulkTagDialogConfig(prev => ({
      ...prev,
      isOpen: false,
    }));
  };

  const handleBulkTagDialogSubmit = (mode: TagAssignmentMode) => {
    switch (mode) {
      case 'assign':
        sendDataExplorerResultsPageAddTagSubmitBiAnalytics();
        break;
      case 'unassign':
        sendDataExplorerResultsPageRemoveTagSubmitBiAnalytics();
        break;
    }

    setBulkTagDialogConfig(prev => ({
      ...prev,
      isOpen: false,
    }));
  };

  const handleShareUrlCopy = () => {
    sendDataExplorerResultsPageShareCopyBiAnalytics();
  };

  const toolbarActions: ToolbarAction[] = [
    {
      label: t('tagAssignment.assign.title'),
      type: ToolbarActionType.TERTIARY,
      icon: BigidAddTagIcon,
      execute: async () => {
        handleAssignTagButtonClick();
        return {};
      },
      disable: () => areTagsActionsDisabled,
      show: () => true,
      placement: 'start',
      tooltip: areTagsActionsDisabled
        ? t('tagAssignment.assign.toolTipWhenDisabled')
        : t('tagAssignment.assign.toolTipWhenNotDisabled'),
    },
    {
      label: t('tagAssignment.unassign.title'),
      type: ToolbarActionType.TERTIARY,
      icon: BigidRemoveTagIcon,
      execute: async () => {
        handleUnassignTagButtonClick();
        return {};
      },
      disable: () => areTagsActionsDisabled,
      show: () => true,
      placement: 'start',
      tooltip: areTagsActionsDisabled
        ? t('tagAssignment.unassign.toolTipWhenDisabled')
        : t('tagAssignment.unassign.toolTipWhenNotDisabled'),
    },
    {
      label: t('export'),
      type: ToolbarActionType.TERTIARY,
      icon: BigidExportIcon,
      execute: async () => {
        handleExportButtonClick();
        return {};
      },
      disable: () => false,
      show: () => !areActionsHidden,
      placement: 'start',
    },
  ];

  const areRowsSelected = selectedRowsIds.length > 0 || isSelectAll;
  const count = selectedRowsIds.length || totalCount;
  const entityName = areRowsSelected ? t('countEntityNames.selected') : t('countEntityNames.items');

  return (
    <Container>
      <BigidToolbar
        toolbarActions={toolbarActions}
        totalRows={count}
        entityName={entityName}
        shareIconConfig={{
          getIsDisabled: () => false,
          getIsShown: () => !areRowsSelected,
          getShareUrl: () => {
            handleShareUrlCopy();
            return window.location.href;
          },
        }}
      />
      <DataExplorerBulkTagAssignmentDialog
        isOpen={bulkTagDialogConfig.isOpen}
        onClose={handleBulkTagDialogClose}
        onSubmit={handleBulkTagDialogSubmit}
        filter={filter}
        searchText={query}
        mode={bulkTagDialogConfig.type}
      />
    </Container>
  );
};
