import { useState } from 'react';
import { BigidDropdownOption } from '@bigid-ui/components';
import { CredentialsTypesMetadata, CredentialType as MetadataType } from '../credentialsFormUtils';
import { CredentialGridRow, CredentialTypes, EnginesTypes } from '../types';
import { AppPreferences, getApplicationPreference } from '../../../services/appPreferencesService';
import { useLocalTranslation } from '../translations';
import { buildAuthenticationTypesToIdentifiersMap } from '../utils';

interface UseRenderFields {
  row: CredentialGridRow;
  metadata: CredentialsTypesMetadata[];
}

interface FieldData {
  name: string;
  additionalProps?: (cb: (fieldname: string) => (value: any) => void) => Record<string, any>;
}

export const useRenderFields = ({ row, metadata }: UseRenderFields) => {
  const { type, secret_engine, subType } = row;
  const [credentialType, setCredentialType] = useState<CredentialTypes>(type);
  const [secretEngine, setSecretEngine] = useState<EnginesTypes>(secret_engine);
  const [metadataType, setMetadataType] = useState<MetadataType>(subType ?? MetadataType.BASIC);
  const [selectedAppId, setSelectedAppId] = useState<string>(row.apps?.find(v => v)?.value);
  const [selectedInstanceId, setSelectedInstanceId] = useState<string>(row.instances?.find(v => v)?.value);
  const { t } = useLocalTranslation();

  const getPreferenceFlag = (flag: keyof AppPreferences) => {
    return getApplicationPreference(flag) === true || getApplicationPreference(flag) === 'true';
  };

  const commonFields: FieldData[] = [
    { name: 'credential_id' },
    {
      name: 'type',
      additionalProps: setValue => ({
        setValue: (option: BigidDropdownOption[]) => {
          setCredentialType(option[0].value);
          setValue('type')(option);
        },
      }),
    },
    { name: 'isScannerOnly' },
    { name: 'scopes' },
  ];

  const cloudFields = [{ name: 'ttl' }];

  const AuthenticationTypesToIdentifiersMap = buildAuthenticationTypesToIdentifiersMap(metadata);

  const secretEngineFieldEntity: FieldData = {
    name: 'secret_engine',
    additionalProps: setValue => ({
      setValue: (option: BigidDropdownOption[]) => {
        setSecretEngine(option[0].value);
        setValue('secret_engine')(option);
      },
    }),
  };

  const subTypeField: FieldData = {
    name: 'subType',
    additionalProps: setValue => ({
      setValue: (option: BigidDropdownOption[]) => {
        setMetadataType(option[0].value);
        setValue('subType')(option);
      },
    }),
  };
  const isGcp = secretEngine === EnginesTypes.GoogleCloud;
  const secretEngineFields = isGcp ? cloudFields : [];
  const additionalFields = isGcp ? secretEngineFields : AuthenticationTypesToIdentifiersMap[metadataType];

  function getCredentialsFields(credentialType: string, isGcp?: boolean) {
    const baseFields = [...commonFields, { name: 'url' }];
    const isNewCredentialsFlow = getApplicationPreference('NEW_CREDENTIALS_FLOW_ENABLED');
    if (credentialType === CredentialTypes.RemoteHashiCorp || credentialType === CredentialTypes.HashiCorp) {
      baseFields.push(secretEngineFieldEntity);
      if (isGcp) {
        baseFields.push(...cloudFields);
      }
      if (!isGcp && !isNewCredentialsFlow) {
        baseFields.push(subTypeField);
        if (metadataType === MetadataType.BASIC) baseFields.push(...additionalFields);
      }
    }
    if (!isGcp && isNewCredentialsFlow) {
      baseFields.push(subTypeField);
      baseFields.push(...additionalFields);
    }
    if (credentialType === CredentialTypes.RemoteHashiCorp || credentialType === CredentialTypes.RemoteThycotic) {
      baseFields.push({ name: 'scanner_group' });
    }

    if (
      !isNewCredentialsFlow &&
      (credentialType === CredentialTypes.Thycotic || credentialType === CredentialTypes.RemoteThycotic)
    )
      baseFields.push(...additionalFields);

    return [...baseFields];
  }

  const getFieldsToRender = (): FieldData[] => {
    if (credentialType === CredentialTypes.BigID) {
      if (getApplicationPreference('ENABLE_CREDENTIAL_CUSTOM_TYPES')) {
        const metadataFields =
          metadata.find(({ type }) => type === metadataType)?.fields.map(({ name }) => ({ name })) ?? [];
        return [...commonFields, subTypeField, ...metadataFields];
      }
      return [...commonFields, { name: 'username' }, { name: 'password' }];
    }
    if (credentialType === CredentialTypes.CyberArk) {
      const isMultiFlag = getPreferenceFlag('ENABLE_UNIFIED_VAULTS');
      return [
        ...commonFields,
        ...(isMultiFlag ? [{ name: 'cyberArkVaultId' }] : []),
        { name: 'query', additionalProps: () => ({ label: t('form.cyberArkQuery') }) },
      ];
    }
    if (credentialType === CredentialTypes.RemoteCyberArk) {
      return [
        ...commonFields,
        { name: 'query', additionalProps: () => ({ label: t('form.cyberArkQuery') }) },
        { name: 'scanner_group' },
      ];
    }
    if (credentialType === CredentialTypes.CustomApplicationVault) {
      const customBaseFields = [
        ...commonFields.filter(field => field.name !== 'isScannerOnly' && field.name !== 'scopes'),
        {
          name: 'apps',
          additionalProps: (setValue: any) => ({
            setValue: (option: BigidDropdownOption[]) => {
              setSelectedAppId(option[0].value);
              setValue('apps')(option);
              getApplicationPreference('TPA_MULTIPLE_DEPLOYMENTS_ENABLED') && setValue('instances')([]);
              setValue('actions')([]);
              setValue('globalPreset')([]);
            },
          }),
        },
        ...(getApplicationPreference('TPA_MULTIPLE_DEPLOYMENTS_ENABLED')
          ? [
              {
                name: 'instances',
                additionalProps: (setValue: any) => ({
                  setValue: (option: BigidDropdownOption[]) => {
                    setSelectedInstanceId(option[0].value);
                    setValue('instances')(option);
                    setValue('actions')([]);
                  },
                }),
              },
            ]
          : []),
        { name: 'isScannerOnly' },
        { name: 'actions' },
        {
          name: 'globalPreset',
        },
        { name: 'customQuery' },
        { name: 'scopes' },
      ];
      if (!getApplicationPreference('NEW_CREDENTIALS_FLOW_ENABLED')) {
        return customBaseFields;
      }
      customBaseFields.push(subTypeField);
      customBaseFields.push(...AuthenticationTypesToIdentifiersMap[metadataType]);
      return [...customBaseFields];
    }
    if (
      credentialType === CredentialTypes.HashiCorp ||
      credentialType === CredentialTypes.RemoteHashiCorp ||
      credentialType === CredentialTypes.RemoteThycotic ||
      credentialType === CredentialTypes.Thycotic
    ) {
      return getCredentialsFields(credentialType, isGcp);
    }

    if (credentialType === CredentialTypes.RemoteCustom) {
      return [...commonFields, { name: 'scanner_group' }];
    }
    return commonFields;
  };

  return {
    fieldsToRender: getFieldsToRender(),
    credentialType,
    secretEngine,
    metadataType,
    selectedAppId,
    selectedInstanceId,
  };
};
