import { module } from 'angular';
import './business-properties.component.scss';

import { isPermitted } from '../../../react/services/userPermissionsService';
import { BUSINESS_FLOWS_PERMISSIONS } from '@bigid/permissions';
import template from './business-properties.component.html';
const app = module('app');

app.component('businessProperties', {
  template,
  controller: [
    '$state',
    'dataMappingService',
    'DeleteConfirmation',
    function ($state, dataMappingService, DeleteConfirmation) {
      this.tab = 'settings';
      const ctrl = this;

      ctrl.$onInit = () => {
        ctrl.hasManagePermission = isPermitted(BUSINESS_FLOWS_PERMISSIONS.MANAGE.name);
      };
    },
  ],
  bindings: {
    vm: '<',
    entity: '<',
  },
});
