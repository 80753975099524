import {
  BigidFilterOptionType,
  BigidSelectOption,
  RequestType,
  ApiCallForm,
  BigidIconType,
} from '@bigid-ui/components';
import {
  BigidAppLogoRopaIcon,
  BigidAppLogoRemediationIcon,
  BigidDataSearchIcon,
  BigidPolicyIcon,
} from '@bigid-ui/icons';
import { ConfigurationFormField } from '../ConfigurationManagement/configurationManagementTypes';

export enum ACCategory {
  POLICY = 'policy',
  SCAN = 'scan',
  DSAR = 'dsar',
}

export enum ACEventType {
  POLICY_VIOLATION = 'policyViolation',
  SCAN_PROFILE_PII_SUMMARY = 'scanProfilePiiSummary',
  DATA_SOURCE_PII_SUMMARY = 'dataSourcePiiSummary',
  SCAN_PROFILE_SCANNER_STARTED = 'scanProfileScannerStarted',
  SCAN_PROFILE_SCANNER_COMPLETED = 'scanProfileScannerCompleted',
  SCAN_PROFILE_SCANNER_STOPPED = 'scanProfileScannerStopped',
  SCAN_PROFILE_SCANNER_FAILED = 'scanProfileScannerFailed',
  DATA_SOURCE_SCANNER_COMPLETED = 'dataSourceScannerCompleted',
  DATA_SOURCE_SCANNER_STOPPED = 'dataSourceScannerStopped',
  DATA_SOURCE_SCANNER_FAILED = 'dataSourceScannerFailed',
  DATA_SOURCE_SCANNER_STARTED = 'dataSourceScannerStarted',
}

export enum ACEventGroup {
  POLICY_VIOLATION = 'policyViolation',
  PII_SUMMARY = 'PiiSummary',
  SCANNER = 'Scanner',
  GENERAL_COMMAND = 'generalCommand',
}

export enum ACStatusType {
  STARTED = 'started',
  IN_PROGRESS = 'in_progress',
  COMPLETED = 'completed',
  FAILED = 'failed',
}

export enum EntityType {
  POLICY = 'policy',
  DATA_SOURCE = 'dataSource',
  SCAN_PROFILE = 'scanProfile',
  RECEIVERS = 'receivers',
}

export enum ACActionType {
  API_CALL = 'apiCall',
  SEND_EMAIL = 'sendEmail',
  OPEN_TASK = 'openTask',
  OPEN_SERVICE_TICKET = 'openServiceTicket',
  RUN_TPA_ACTION = 'runTpaAction',
}

interface EventMetadataOptionMapping {
  label: string;
  value: string;
}

export interface EventMetadataSelectField {
  name: string;
  displayName: string;
  resourceName: string;
  optionsMapping: EventMetadataOptionMapping;
  pathToData?: string;
  options?: BigidSelectOption[];
}

export interface EventMetadataEntity extends EventMetadataSelectField {
  name: EntityType;
  autocompleteListItems: string[];
}

export interface EventMetadata {
  name: ACEventGroup;
  displayName: string;
  category: ACCategory;
  supportedEvents: ACEventType[];
  entities: EventMetadataEntity[];
  selectionDynamicFields?: EventMetadataSelectField[];
  eventFieldOrder?: string;
  eventToEntityMap: Partial<Record<ACEventType, EntityType>>;
}

export interface GenericType {
  name: string;
  displayName: string;
  isDisabled: boolean;
}

export interface CategoryMetadata extends GenericType {
  name: ACCategory;
  iconName: string;
  entities: EventMetadataEntity[];
  events?: ACEventGroup[];
  entityName?: string;
}

export interface ActionMetadata extends GenericType {
  name: ACActionType;
  description: string;
}

export interface ActionCenterMetadata {
  events: EventMetadata[];
  categories: CategoryMetadata[];
  actions: ActionMetadata[];
}

export const iconNameToIcon: Record<string, BigidIconType> = {
  BigidScan2Icon: BigidDataSearchIcon,
  BigidPolicy3Icon: BigidPolicyIcon,
  BigidRopaIcon: BigidAppLogoRopaIcon,
  BigidRemediationIcon: BigidAppLogoRemediationIcon,
};

export interface ActionConfiguration {
  enabled: boolean;
}

export interface EmailActionConfiguration extends ActionConfiguration {
  from: string;
  subject: string;
  text: string;
}

export interface TaskActionConfiguration extends ActionConfiguration {}

export interface JiraActionConfiguration {
  summary: string;
  issuetype: string;
  description?: string;
  assignee?: string;
  reporter?: string;

  [key: string]: any;
}

export interface OpenServiceTicketActionConfiguration extends ActionConfiguration {
  configurationId: string;
  fields: JiraActionConfiguration;
}

export type ApiCallCertificateConfiguration = {
  useCertificate: boolean;
  allowSelfSignCertificate?: boolean;
  clientCert?: string;
  clientKeyCert?: string;
};

export interface ApiCallActionConfiguration extends ActionConfiguration, ApiCallCertificateConfiguration {
  url: string;
  type: RequestType;
  queryParams?: Record<string, string>;
  headers?: Record<string, string>;
  payload?: Record<string, any>;
}

export type ApiCallActionConfigurationForm = ActionConfiguration & ApiCallForm & ApiCallCertificateConfiguration;

export interface RunTpaActionConfiguration extends ActionConfiguration {
  tpaName?: string;
  tpaId: string;
  actionId: string;
  presetId: string;
}

export type ActionConfigurationFormTypes =
  | ApiCallActionConfigurationForm
  | OpenServiceTicketActionConfiguration
  | RunTpaActionConfiguration;

export type ActionConfigurationTypes =
  | EmailActionConfiguration
  | TaskActionConfiguration
  | OpenServiceTicketActionConfiguration
  | ApiCallActionConfiguration
  | RunTpaActionConfiguration;

export interface Action {
  type: ACActionType;
  configuration: ActionConfigurationTypes;
}

export interface Receiver {
  username: string;
  email: string;
  displayName: string;
}

export interface Workflow {
  id: string;
  name: string;
  event: ACEventType;
  entityIds: string[];
  receivers: Receiver[];
  actions: Action[];
  created_at: Date;
  updated_at: Date;
}

export interface WorkflowResponse extends Omit<Workflow, 'entityIds'> {
  entityIds: BigidSelectOption[];
}

export type WorkflowConfiguration = Omit<WorkflowResponse, 'id' | 'created_at' | 'updated_at'>;

export type WorkflowsTotal = Record<ACEventGroup, number>;

export interface FetchWorkflowsResponse {
  workflows: WorkflowResponse[];
  totalCount: number;
}

export interface DeleteWorkflowsResponse {
  id: string;
  message: string;
  ok: boolean;
}

export interface GetWorkflowsTotalByEventsResponse {
  workflowsTotal: WorkflowsTotal;
  message: string;
}

export interface InitialFiltersReceivers {
  receivers: BigidFilterOptionType[];
  receiversConfigureOptions: BigidSelectOption[];
}

export interface OpenServiceTicketMetadata {
  fields: ConfigurationFormField[];
  fieldsForIssueTypeMap: Record<string, ConfigurationFormField[]>;
  dropDownOptions?: Record<string, any[]>;
  issueName?: string;
}

export interface GetJiraUsersInput {
  configurationId: string;
  query?: string;
  skip?: number;
  limit?: number;
  ids?: string[];
}

export interface GetJiraUsersResponse {
  users: BigidSelectOption[];
}
