import React, { MouseEvent } from 'react';
import Link from '@mui/material/Link';
import { BigidColorsV2 } from '@bigid-ui/components';

export interface GridLinkDsarProps {
  text: string;
  href?: string;
  onClick?: (event?: MouseEvent) => void;
  title?: string;
}

export const GridLinkDsar = ({ text, href, onClick, title }: GridLinkDsarProps) => {
  const handleKeyDown =
    href == undefined
      ? undefined
      : (e: React.KeyboardEvent) => {
          if (e.key === 'Enter' || e.key === ' ') {
            e?.preventDefault();
            if (onClick) {
              onClick();
            }
          }
        };

  return (
    <Link
      tabIndex={0}
      onClick={(event: MouseEvent) => onClick?.(event)}
      onKeyDown={handleKeyDown}
      underline="always"
      href={href}
      title={title}
      color="inherit"
      sx={{
        cursor: 'pointer',
        color: BigidColorsV2.blue[700],
        '&:hover,&:focus': { color: `${BigidColorsV2.blue[600]}` },
      }}
    >
      {text}
    </Link>
  );
};
