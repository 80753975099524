import angular from 'angular';
import { convertToAngular } from '../../../common/services/convertToAngular';
import { BigidLoader } from '@bigid-ui/components';

interface LoaderProps {
  size?: number;
  color?: string;
  thickness?: number;
}

angular
  .module('app')
  .component('bigidLoader', convertToAngular<LoaderProps>(BigidLoader, ['size', 'color', 'thickness']));
