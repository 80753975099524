import React, { ComponentType } from 'react';
import { generateDataAid } from '@bigid-ui/utils';
import { FormControl, styled } from '@mui/material';
import { BigidAddIcon, BigidDeleteIcon } from '@bigid-ui/icons';
import { TertiaryButton, BigidButtonIcon, ExtraFieldItemData, ValidationResult } from '@bigid-ui/components';

const FieldsWrapper = styled('div')`
  width: 100%;
  height: 100%;
  position: relative;
  margin-bottom: 16px;
`;

const DeleteButtonWrapper = styled('div')`
  position: absolute;
  top: 5px;
  right: 16px;
`;

const AddMoreButtonBox = styled('div')`
  & button {
    padding: 0;
  }
`;

export interface BigidExtraFieldProps {
  fieldComponent: ComponentType<any>;
  fieldsBottomOffset?: number;
  data: ExtraFieldItemData[];
  onAddNew: () => void;
  onDelete: (id: string) => void;
  onChangeItem: (id: string, value: any) => void;
  addLabel?: string;
  disabled?: boolean;
  fieldProps?: Record<string, any>;
  allowToDeleteAll?: boolean;

  error?: ValidationResult;
  errorIsShown?: boolean;
  isValidated?: boolean;
  addNewButtonVisible?: boolean;
}

const DATA_AID_NAME = 'ExtraFields';

export const ExtraFields = React.memo(
  ({
    fieldComponent: Field,
    data,
    onAddNew,
    onDelete,
    onChangeItem,
    addLabel = 'Add another',
    disabled = false,
    allowToDeleteAll,
    error,
    errorIsShown,
    isValidated,
    addNewButtonVisible,
    fieldProps,
  }: BigidExtraFieldProps) => {
    const toDisplayDeleteIcon = (index: number) => (allowToDeleteAll ? true : data.length === 1 ? index !== 0 : true);

    return (
      <FormControl data-aid={generateDataAid(DATA_AID_NAME, ['holder'])} component="fieldset">
        {data.map(({ id, value, ...restProps }, index) => (
          <FieldsWrapper key={id} data-aid={generateDataAid(DATA_AID_NAME, ['field', `item-${id}`])}>
            <Field
              {...restProps}
              id={id}
              value={value}
              disabled={disabled}
              data={data}
              error={error}
              isValidated={isValidated}
              errorIsShown={errorIsShown}
              fieldProps={fieldProps}
              onChange={(value: any) => {
                onChangeItem(id, value);
              }}
            />
            {!fieldProps?.readOnly && toDisplayDeleteIcon(index) && (
              <DeleteButtonWrapper>
                <BigidButtonIcon
                  icon={BigidDeleteIcon}
                  onClick={() => onDelete(id)}
                  dataAid={generateDataAid(DATA_AID_NAME, ['delete-button', `item-${id}`])}
                />
              </DeleteButtonWrapper>
            )}
          </FieldsWrapper>
        ))}
        {addNewButtonVisible && (
          <AddMoreButtonBox>
            <TertiaryButton
              size="small"
              text={addLabel}
              onClick={onAddNew}
              disabled={fieldProps?.readOnly}
              startIcon={<BigidAddIcon />}
              dataAid={generateDataAid(DATA_AID_NAME, ['add-more-button'])}
            />
          </AddMoreButtonBox>
        )}
      </FormControl>
    );
  },
);
