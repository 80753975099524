import {
  BigidBody3,
  BigidCaption,
  BigidColors,
  BigidEditableTextArea,
  BigidForm,
  BigidFormField,
  BigidFormProps,
  BigidFormValues,
  BigidSwitch,
  BigidSwitchProps,
} from '@bigid-ui/components';
import makeStyles from '@mui/styles/makeStyles';
import classNames from 'classnames';
import { debounce, isEqual } from 'lodash';
import React, { FC, useEffect, useMemo, useRef, useState } from 'react';
import { LABEL_POSITION, LABEL_WIDTH } from '../consts';
import { useReportTemplateContext } from '../hooks/ReportTemplateContext';
import { ReducerActions } from '../hooks/ReportTemplateReducer';
import { ReportTemplate } from '../Types';
import { apiToFieldValuesMapper, fieldsValuesToApiDefaultMapper } from './mappers';

export type SectionName = keyof ReportTemplate;

export interface FormSectionProps {
  sectionName: SectionName;
  isReadOnly?: boolean;
  fields?: BigidFormField[];
  initialValues: Record<string, any>;
  formToApiMapper?: FormToApiMapper;
  description?: string;
  bottomDescription?: string;
  shouldHideIsEnabledSwitch?: boolean;
  shouldHideHeaderSection?: boolean;
  shouldHideLeftBorder?: boolean;
  customFieldsToRender?: () => any;
  dynamicRichTextOnChangeHandler?: (values: BigidFormValues) => void;
  isFirstDynamicRichTextLoad?: React.MutableRefObject<any>;
  isEnabledChanged?: (enabled: boolean) => void;
}

export type FormToApiMapper = (args: {
  sectionName: SectionName;
  isEnabled: boolean;
  readonly values: Record<string, any>;
  readonly fields: BigidFormField[];
}) => Partial<ReportTemplate>;

export type ApiToFormMapper = (args: {
  sectionName: SectionName;
  readonly initialValues: Record<string, any>;
  readonly fields: BigidFormField[];
}) => Record<string, any>;

export type FormSectionInitialStateProps = Pick<FormSectionProps, 'initialValues' | 'fields' | 'formToApiMapper'>;

const { gray, purple } = BigidColors;

const useStyles = makeStyles(theme => ({
  root: {
    width: '90%',
    borderRadius: '0 4px 4px 0',
    padding: theme.spacing(4),
    minWidth: '100px',
    boxShadow: `0px 1px 4px rgba(0, 0, 0, 0.25)`,
    display: 'flex',
    gap: '12px',
    flexDirection: 'column',
    flexWrap: 'nowrap',
  },
  rootLeftBorder: {
    borderLeft: `8px solid ${purple[300]}`,
  },
  sectionDisabled: {
    borderLeftColor: gray[400],
  },
  titleWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'baseline',
    flexDirection: 'row',
  },
  title: {
    color: '#000',
  },
  description: {
    color: gray[600],
    paddingBottom: 8,
  },
  content: {
    maxWidth: 800,
    minWidth: 200,
  },
  switchWrapper: {
    display: 'flex',
    alignItems: 'center',
  },
}));

export const FormSection: FC<FormSectionProps> = ({
  isReadOnly,
  description,
  bottomDescription,
  sectionName,
  initialValues,
  fields,
  formToApiMapper,
  shouldHideIsEnabledSwitch,
  shouldHideHeaderSection,
  shouldHideLeftBorder,
  customFieldsToRender,
  dynamicRichTextOnChangeHandler,
  isFirstDynamicRichTextLoad,
  isEnabledChanged,
}) => {
  const classes = useStyles({});

  const { templateId, dispatch, hasEditPermission } = useReportTemplateContext();
  const [isEnabled, setIsEnabled] = useState(!!initialValues?.isEnabled);
  const lastSavedFieldsValues = useRef(initialValues);

  const isReadOnlyMode = isReadOnly ?? !hasEditPermission;

  let debouncedDynamicOnChangeHandler: (values: BigidFormValues) => void;
  if (dynamicRichTextOnChangeHandler) {
    debouncedDynamicOnChangeHandler = debounce(dynamicRichTextOnChangeHandler, 1000);
  }

  const handleOnSwitchChange: BigidSwitchProps['onChange'] = (_e, checked) => {
    if (dynamicRichTextOnChangeHandler) {
      dynamicRichTextOnChangeHandler({ isEnabled: checked });
    }
    setIsEnabled(checked);
    isEnabledChanged?.(checked);
    dispatch({
      type: ReducerActions.UPDATE_IS_ENABLED,
      payload: { sectionName, isEnabled: checked },
    });
    lastSavedFieldsValues.current.isEnabled = checked;
  };

  useEffect(() => {
    setIsEnabled(!!initialValues?.isEnabled);
  }, [initialValues]);

  const formProps: BigidFormProps = useMemo(() => {
    lastSavedFieldsValues.current = initialValues;
    if (!fields) {
      return null;
    }
    let isFirstOnChangeCall = true;
    const initialValuesForFields = apiToFieldValuesMapper({ initialValues, fields, sectionName });
    const formProps: BigidFormProps = fields && {
      fields: fields.map(({ labelWidth, labelPosition, ...field }) => {
        return {
          ...field,
          labelWidth: labelWidth ?? LABEL_WIDTH,
          labelPosition: labelPosition ?? LABEL_POSITION,
          ...(isReadOnlyMode && {
            disabled: isReadOnlyMode,
            misc: {
              ...field.misc,
              disabled: isReadOnlyMode,
              placeholder: '',
            },
          }),
        };
      }),
      initialValues: initialValuesForFields,
      controlButtons: false,
      validate: () => false,
      onChange: values => {
        // TODO: it is being called even when no changes -> due to setFieldValue infinite loop
        // TODO: we have a dedicated PR in bigid-ui, after fix need to integrate the changes here
        // don't save on first onChange call, it is a bug without workaround for now
        if (isFirstDynamicRichTextLoad?.current && dynamicRichTextOnChangeHandler) {
          debouncedDynamicOnChangeHandler({ content: values.content.markdown });
          return;
        }
        if (isFirstOnChangeCall && !isFirstDynamicRichTextLoad?.current) {
          isFirstOnChangeCall = false;
          if (isFirstDynamicRichTextLoad && !isFirstDynamicRichTextLoad.current) {
            isFirstDynamicRichTextLoad.current = true;
          }
          return;
        }
        // don't save when the values are empty object
        if (!Object.keys(values).length) {
          return;
        }
        const { isEnabled, ...savedValues } = lastSavedFieldsValues.current;
        if (!isEqual(values, savedValues)) {
          lastSavedFieldsValues.current = { ...values, isEnabled };
          const mapperFn = formToApiMapper ?? fieldsValuesToApiDefaultMapper;
          const data = mapperFn({ sectionName, isEnabled, values, fields });
          dispatch({
            type: ReducerActions.UPDATE_SECTION,
            payload: {
              data,
              sectionName,
            },
          });
        }
      },
    };

    return formProps;
  }, [
    initialValues,
    fields,
    sectionName,
    isReadOnlyMode,
    isFirstDynamicRichTextLoad,
    dynamicRichTextOnChangeHandler,
    debouncedDynamicOnChangeHandler,
    formToApiMapper,
    dispatch,
  ]);

  // due to BigidForm bugs need a fix that re-render the component without reason
  const MemoizedForm = useMemo(() => {
    const isReadyToRenderForm = templateId && Boolean(formProps?.fields);
    return isReadyToRenderForm ? <BigidForm key={templateId} {...formProps} /> : null;
  }, [formProps, templateId]);

  const handleTitleChange = (value: string) => {
    if (dynamicRichTextOnChangeHandler) {
      dynamicRichTextOnChangeHandler({ sectionTitle: value });
      return;
    }
    const data = { [sectionName]: { sectionTitle: value } };
    dispatch({
      type: ReducerActions.UPDATE_SECTION,
      payload: {
        data,
        sectionName,
      },
    });
  };

  return (
    <section
      className={classNames(
        classes.root,
        !shouldHideLeftBorder && classes.rootLeftBorder,
        !isEnabled && classes.sectionDisabled,
      )}
      data-aid={`TemplateFormSection-${sectionName}`}
    >
      {!shouldHideHeaderSection && (
        <div className={classes.titleWrapper}>
          <div className={classes.title} id={`section-title-${sectionName}`}>
            <BigidEditableTextArea
              value={initialValues.sectionTitle}
              isRequired
              isEditPencilAlwaysVisible={hasEditPermission}
              showControls={hasEditPermission}
              controlsPlacement={['right']}
              enlargedControls
              customFontWeight="bold"
              onSubmit={handleTitleChange}
              isInlineEdit={hasEditPermission}
            />
          </div>
          {!shouldHideIsEnabledSwitch && (
            <div className={classes.switchWrapper}>
              <BigidSwitch
                dataAid={`section-${sectionName}`}
                checked={isEnabled}
                value={String(isEnabled)}
                disabled={isReadOnlyMode}
                onChange={handleOnSwitchChange}
                label={'Include in Report'}
              />
            </div>
          )}
        </div>
      )}
      {description && (
        <div className={classes.description}>
          <BigidCaption>{description}</BigidCaption>
        </div>
      )}
      {!customFieldsToRender && <div className={classes.content}>{MemoizedForm}</div>}
      {customFieldsToRender && customFieldsToRender()}
      {bottomDescription && (
        <div className={classes.description}>
          <BigidBody3 gutterBottom>{bottomDescription}</BigidBody3>
        </div>
      )}
    </section>
  );
};
