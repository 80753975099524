import { BigidColors, BigidColorsV2 } from '@bigid-ui/components';
import { ChipFormatterProps, StatusFormatterProps } from '@bigid-ui/grid';
import { ConnectionStatus, MaskOptions, MaskOptionsValue } from './ProfileSettingsTypes';
import { dateTimeService } from '@bigid-ui/i18n';

const CORRELATION = 'Correlation';

export const getCellValueForIncludeExclude = (isIncluded: boolean): ChipFormatterProps => {
  const label = isIncluded ? 'Included' : 'Excluded';
  const color = isIncluded ? BigidColors.white : undefined;
  const bgColor = isIncluded ? BigidColors.successGreen : BigidColors.gray[100];
  return { chip: { label, color, bgColor } };
};

export const getCellValueForIncludeExcludeV2 = (isIncluded: boolean, defaultValue?: string): ChipFormatterProps => {
  const label = defaultValue ? defaultValue : isIncluded ? 'Include' : 'Exclude';
  const color = isIncluded && !defaultValue ? BigidColorsV2.green[900] : BigidColorsV2.gray[500];
  const bgColor = isIncluded && !defaultValue ? BigidColorsV2.green[100] : BigidColorsV2.gray[150];
  return { chip: { label, color, bgColor } };
};

export const getCellValueForMaskValue = (mask: MaskOptions): string => {
  let maskValue;
  switch (mask) {
    case MaskOptions.FULL:
      maskValue = MaskOptionsValue.FULL_MASKING;
      break;
    case MaskOptions.PARTIAL:
      maskValue = MaskOptionsValue.PARTIAL_MASKING;
      break;
    case MaskOptions.NO:
      maskValue = MaskOptionsValue.NO_MASKING;
      break;
    default:
      maskValue = MaskOptionsValue.NO_MASKING;
  }
  return maskValue;
};

export const getCellValueForIncludeInProximityCategory = (
  isIncluded: boolean,
  attributeType: string,
): ChipFormatterProps => {
  const chipValue = getCellValueForIncludeExclude(isIncluded);
  const label = attributeType === CORRELATION ? chipValue.chip.label : 'N/A';
  return { chip: { ...chipValue.chip, label } };
};

export const getCellValueForConnectionStatus = (connectionStatus: ConnectionStatus): StatusFormatterProps => {
  const lastConnection = connectionStatus?.last_connection;
  let status: StatusFormatterProps['status'];
  switch (connectionStatus?.is_success) {
    case true:
      status = 'success';
      break;
    case false:
      status = 'failed';
      break;
  }

  return {
    status,
    text: dateTimeService.formatDate(lastConnection),
  };
};
