import { sortBy } from 'lodash';
import { Theme } from '@mui/material';
import { BigidChip, BigidStatusBadge, BigidStatusBadgeSize, BigidStatusBadgeType } from '@bigid-ui/components';
import ClassifierModelArrowUpWeak from '../../../../../assets/icons/ClassifierModelArrowUpWeak.svg';
import ClassifierModelArrowUpStrong from '../../../../../assets/icons/ClassifierModelArrowUpStrong.svg';
import React from 'react';
import { DateISO8601 } from '../../../../../types/types';

export enum ProcessStatus {
  PENDING_EVALUATION = 'PENDING_EVALUATION',
  EVALUATION_STARTED = 'EVALUATION_STARTED',
  EVALUATION_COMPLETED = 'EVALUATION_COMPLETED',
  APPLY_STARTED = 'APPLY_STARTED',
  APPLY_COMPLETED = 'APPLY_COMPLETED',
}

export enum ClassifierModelState {
  NOT_ENOUGH_INPUTS = 'NOT_ENOUGH_INPUTS',
  WAITING_FOR_SESSION = 'WAITING_FOR_SESSION',
  GENERATING_MODEL = 'GENERATING_MODEL',
  MODEL_IS_OFFERED = 'MODEL_IS_OFFERED',
  MODEL_APPLYING = 'MODEL_APPLYING',
  MODEL_APPLIED = 'MODEL_APPLIED',
}

export type ClassifierModelStage = {
  state: ClassifierModelState;
  /**
   IsModelBased is true if currently a model is applied for said classifier - so our findings are currently based on the model and not on the regex
   */
  isModelBased: boolean;
};

export enum ApplyResultStatus {
  IN_PROGRESS = 'IN_PROGRESS',
  COMPLETED = 'COMPLETED',
  ERROR = 'ERROR',
}

export type ApplyResult = {
  status: ApplyResultStatus;
  mlStatus?: string;
  scanResultStatus?: string;
};

export type TuningData = {
  newApproves: number;
  newRejects: number;
  totalApproves: number;
  totalRejects: number;
  classifierModelStage: ClassifierModelStage;
  classificationName: string;
  displayName?: string;
};

export type RegexData = {
  regexAccuracyText: string;
  showRevertBtn?: boolean;
};

export type ModelData = {
  modelId: string;
  classificationName: string;
  sourceList: string[];
  status: ProcessStatus;
  evaluationNumApproved: number;
  evaluationNumRejected: number;
  isImproved?: boolean;
  lastApprovedOrRejectedTime?: string;
  evaluationStartedAt?: DateISO8601;
  evaluationCompletedAt?: DateISO8601;
  evaluationScore?: number;
  applyStartedAt?: DateISO8601;
  applyCompletedAt?: DateISO8601;
  applyResult?: ApplyResult;
  accuracyDiff?: number;
  classifierModelStage?: ClassifierModelStage;
};

export type ApplyBody = {
  modelId: string;
  classificationName: string;
};

export type ApplyResponse = {
  classification_name: string;
  model_id: string;
  scan_id?: string;
  cutoff: number;
  sample_set: [
    {
      fully_qualified_name: string;
      field_name: string;
      estimation: number;
    },
  ];
};

export enum SSE_TYPES {
  MODEL_EVALUATION_STARTED_SSE = 'catalog.supervised-learning.model.evaluation.started',
  MODEL_EVALUATION_COMPLETED_SSE = 'catalog.supervised-learning.model.evaluation.completed',
  MODEL_APPLY_COMPLETED_SSE = 'catalog.supervised-learning.model.apply.completed',
}

export type EvaluationStartedData = {
  classification_name: string;
};

export type EvaluationCompletedData = {
  classification_name: string;
  evaluation_score: string;
  model_id: string;
};

export type ApplyCompletedData = {
  classification_name: string;
  model_id: string;
};

export const APPLY_COMPLETED_SSE = 'ApplyCompleted';

export const MODEL_DELTA_THRESHOLD = 2;
export const MODEL_ACCURACY_THRESHOLD = 70;

export const MINIMUM_REJECTION_NEEDED = 30;
export const MINIMUM_APPROVAL_NEEDED = 30;

export function getClassifierModelState(
  modelData: ModelData[],
  isModelBased: boolean,
  isEnoughInputs: boolean,
): ClassifierModelStage {
  const newestModelStatus = modelData[0]?.status;

  if (newestModelStatus) {
    switch (newestModelStatus) {
      case ProcessStatus.APPLY_STARTED:
        return {
          state: ClassifierModelState.MODEL_APPLYING,
          isModelBased,
        };
      case ProcessStatus.EVALUATION_COMPLETED:
        return {
          state: ClassifierModelState.MODEL_IS_OFFERED,
          isModelBased,
        };
      case ProcessStatus.EVALUATION_STARTED:
        return {
          state: ClassifierModelState.GENERATING_MODEL,
          isModelBased,
        };
      case ProcessStatus.PENDING_EVALUATION:
        if (isEnoughInputs) {
          return {
            state: ClassifierModelState.WAITING_FOR_SESSION,
            isModelBased,
          };
        }
    }
  }
  return {
    state: ClassifierModelState.NOT_ENOUGH_INPUTS,
    isModelBased,
  };
}

export function getComputedModelData(modelData: ModelData[], regexAccuracy: number): ModelData[] {
  let isModelBased, isEnoughInputs, currentState;
  const [newerModel, olderModel] = modelData;
  const numOfProccesses = newerModel && olderModel ? 2 : newerModel ? 1 : 0;

  if (numOfProccesses === 0) {
    return [];
  } else {
    isModelBased = getIsModelBased([newerModel, olderModel]);
    isEnoughInputs = getIsEnoughInputs(newerModel?.evaluationNumRejected, newerModel?.evaluationNumApproved);
    currentState = getClassifierModelState([newerModel, olderModel], isModelBased, isEnoughInputs);

    const newerComputedModel: ModelData = {
      ...newerModel,
      classifierModelStage: currentState,
      isImproved: olderModel?.status === ProcessStatus.APPLY_COMPLETED,
      evaluationScore: getEvaluationAccuracy(newerModel?.evaluationScore),
      accuracyDiff: getAccuracyDiff([newerModel, olderModel], regexAccuracy),
    };

    if (numOfProccesses === 2 && newerComputedModel.isImproved) {
      const olderComputedModel: ModelData = {
        ...olderModel,
        evaluationScore: getEvaluationAccuracy(olderModel?.evaluationScore),
        classifierModelStage: {
          isModelBased: true,
          state: ClassifierModelState.MODEL_APPLIED,
        },
      };

      return [newerComputedModel, olderComputedModel];
    }
    return [newerComputedModel];
  }
}

export function getRegexAccuracy(approvedCount: number, rejectedCount: number) {
  return Number(((approvedCount / (approvedCount + rejectedCount)) * 100).toFixed(0));
}

export function getInputsNeeded(count: number, threshold: number) {
  return Math.max(0, threshold - count);
}

export function getIsWeakModel(modelAccuracy: number, accuracyDiff: number) {
  return modelAccuracy < MODEL_ACCURACY_THRESHOLD && accuracyDiff < MODEL_DELTA_THRESHOLD;
}

export function sortModelsByEvaluationTime(models: ModelData[]): ModelData[] {
  return sortBy(models, model => new Date(model.evaluationStartedAt || '').getTime()).reverse();
}

export function getIsModelBased(models: ModelData[]) {
  return models[0]?.status === ProcessStatus.APPLY_COMPLETED || models[1]?.status === ProcessStatus.APPLY_COMPLETED;
}

export function getIsEnoughInputs(newApprovals: number, newRejections: number) {
  return newRejections >= MINIMUM_REJECTION_NEEDED && newApprovals >= MINIMUM_APPROVAL_NEEDED;
}

export function getAccuracyDiff(modelData: ModelData[], regexAccuracy: number): number {
  regexAccuracy = isNaN(regexAccuracy) ? 0 : regexAccuracy;
  let accuracyDiff: number;

  if (modelData[0]?.isImproved) {
    accuracyDiff = modelData[0]?.evaluationScore - getEvaluationAccuracy(modelData[1]?.evaluationScore);
  } else if (modelData[0]?.evaluationScore) {
    accuracyDiff = modelData[0]?.evaluationScore - regexAccuracy;
  }
  return isNaN(accuracyDiff) ? 0 : Number(accuracyDiff.toFixed(0));
}

export function getEvaluationAccuracy(evaluationScore = 0) {
  return evaluationScore;
}

export const getStatusBadge = (accuracyDiff: number, isWeakModel: boolean, theme: Theme) => {
  return isWeakModel ? (
    <BigidStatusBadge
      icon={<ClassifierModelArrowUpWeak />}
      label={`${accuracyDiff}%`}
      type={BigidStatusBadgeType.WARNING}
      size={BigidStatusBadgeSize.SMALL}
    />
  ) : (
    <BigidChip
      icon={<ClassifierModelArrowUpStrong />}
      label={`${accuracyDiff}%`}
      size="small"
      color={theme.vars.tokens.bigid.positiveStrong}
      bgColor={theme.vars.tokens.bigid.backgroundPrimary}
      shadow
    />
  );
};
