import React, { FC, useEffect } from 'react';
import { BigidErrorPage } from '@bigid-ui/components';
import { pageHeaderService } from '../../../common/services/pageHeaderService';

export const UnderMaintenance: FC = () => {
  useEffect(() => {
    pageHeaderService.setIsHidden(true);
    return () => pageHeaderService.setIsHidden(false);
  }, []);

  return <BigidErrorPage type="maintenance" />;
};
