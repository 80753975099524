import { Box } from '@mui/material';
import {
  ErrorInsightsWrapper,
  ErrorInsightsContent,
  ErrorInsightsFooter,
  ErrorInsightsDetails,
  ErrorInsightsTitle,
  ErrorInsightsSupport,
  ErrorInsightsBottomSection,
  ErrorInsightsOption,
  ErrorInsightsLogs,
  ErrorInsightsControls,
  ErrorInsightsRatingControl,
  MessageWrapper,
  MessageContent,
  MessageLink,
  AsideWrapper,
  AsideContent,
  AsideDescription,
  HeaderContent,
  HeaderImage,
  HeaderCheckbox,
  ConnectionGuideImage,
  ConnectionGuideTitle,
  ConnectionGuideLoader,
  ConnectionGuideWrapper,
  ConnectionGuideTab,
  ConnectionGuideTabNoContent,
  CollaborationList,
  CollaborationContent,
  CollaborationForm,
  CollaborationActions,
  CollaborationMessage,
  CollaborationField,
  CollaborationTitle,
  CollaborationNotification,
  CollaborationGrid,
  CollaborationOption,
  CollaborationGuidedTour,
  GlobalCollaborationStyles,
  CollaborationFooterContent,
  Footer as FooterContent,
  FooterAction,
  FooterLeftAction,
} from '../DataSourceConnectionModalStyles';
import styled from '@emotion/styled';

const VerticalObjectNoStyling = styled(Box)``;

const OAuth2AuthenticationVerticalObjectField = styled(VerticalObjectNoStyling)`
  & button {
    margin-left: auto !important;
  }
`;

const styleOverrideComponentMapperForDs: Record<
  string,
  Record<string, React.ElementType | ((view: React.ElementType) => React.ElementType)>
> = {
  'sharepoint-online-v2': {
    oAuth2Authentication: OAuth2AuthenticationVerticalObjectField,
  },
  'o365-outlook-v2': {
    oAuth2Authentication: OAuth2AuthenticationVerticalObjectField,
  },
};

// @info temp fix for verticalObject fields without padding
export const getNonResizeAwareFields = (): Record<string, string[]> => ({
  'sharepoint-online-v2': ['oAuth2Authentication'],
  'o365-outlook-v2': ['oAuth2Authentication'],
});

export const ErrorInsights = {
  Wrapper: ErrorInsightsWrapper,
  Content: ErrorInsightsContent,
  Footer: ErrorInsightsFooter,
  Details: ErrorInsightsDetails,
  Title: ErrorInsightsTitle,
  Support: ErrorInsightsSupport,
  BottomSection: ErrorInsightsBottomSection,
  Option: ErrorInsightsOption,
  Logs: ErrorInsightsLogs,
  Controls: ErrorInsightsControls,
  Rating: ErrorInsightsRatingControl,
};

export const Message = {
  Wrapper: MessageWrapper,
  Content: MessageContent,
  Link: MessageLink,
};

export const Aside = {
  Wrapper: AsideWrapper,
  Content: AsideContent,
  Description: AsideDescription,
};

export const Header = {
  Content: HeaderContent,
  Image: HeaderImage,
  Checkbox: HeaderCheckbox,
};

export const Footer = {
  Content: FooterContent,
  Action: FooterAction,
  LeftAction: FooterLeftAction,
};

export const Guide = {
  Wrapper: ConnectionGuideWrapper,
  Title: ConnectionGuideTitle,
  Image: ConnectionGuideImage,
  Loader: ConnectionGuideLoader,
  Tab: ConnectionGuideTab,
  TabNoContent: ConnectionGuideTabNoContent,
};

export const Tab = {
  Wrapper: ConnectionGuideWrapper,
  Title: ConnectionGuideTitle,
  Image: ConnectionGuideImage,
  Loader: ConnectionGuideLoader,
  Content: ConnectionGuideTab,
  TabNoContent: ConnectionGuideTabNoContent,
};

export const Collaboration = {
  Content: CollaborationContent,
  Form: CollaborationForm,
  List: CollaborationList,
  Actions: CollaborationActions,
  Message: CollaborationMessage,
  Field: CollaborationField,
  Title: CollaborationTitle,
  Notification: CollaborationNotification,
  Grid: CollaborationGrid,
  Option: CollaborationOption,
  GuidedTour: CollaborationGuidedTour,
  Global: GlobalCollaborationStyles,
  FooterContent: CollaborationFooterContent,
};

export const getFieldStyleOverridesForDataSourceType = (type: string) => styleOverrideComponentMapperForDs[type] ?? {};
