import { ComponentType } from 'react';
import '../components/BigidSystemDialog/BigidSystemDialog';
import { SystemEvents, systemEventsEmitter } from './systemEvents';
import { BigidDialogProps } from '@bigid-ui/components';

export type SystemDialogContentProps<T> = T & {
  onClose?: () => void;
};

export type BigidSystemDialogOptions<T = any> = Omit<BigidDialogProps, 'isOpen'> & {
  content: ComponentType<SystemDialogContentProps<T>>;
  contentProps?: T;
  withCloseButton?: boolean;
};

export const openSystemDialog = <T = any>(options: BigidSystemDialogOptions<T>): void => {
  systemEventsEmitter.emit(SystemEvents.SYSTEM_DIALOG, [options]);
};

export const closeSystemDialog = (): void => {
  systemEventsEmitter.emit(SystemEvents.CLOSE_SYSTEM_DIALOG);
};
