import {
  ActionData,
  ActionExecuteFunction,
  BigidDropdownOption,
  BigidFilter,
  BigidFilterOptionValueType,
} from '@bigid-ui/components';
import { BigidGridSorting, FetchDataFunction } from '@bigid-ui/grid';

import { analyticsService } from '../../../services/analyticsService';
import { $state, locationService } from '../../../services/angularServices';
import { showConfirmationDialog } from '../../../services/confirmationDialogService';
import { httpService } from '../../../services/httpService';
import { queryService } from '../../../services/queryService';
import { notificationService } from '../../../services/notificationService';
import { DataSource } from '../../../utilities/dataSourcesUtils';
import { CountryData } from '../../Fmsd/fmsdServices';

import { getFixedT } from '../translations';
import { DataSourceDeletionModel, DeletionDataSourceResponse, States } from './types';
import { events } from './constants';

const t = getFixedT('DataSource');

let dsTypesCache: BigidDropdownOption[] = null;
export const fetchDSTypes = async (search?: string): Promise<BigidDropdownOption[]> => {
  if (!dsTypesCache) {
    try {
      const {
        data: { data },
      } = await httpService.fetch<{ data: DataSource[] }>('ds-connections-types');

      if (!data) throw new Error('No data found for connection-types!');

      dsTypesCache = data.map(type => ({
        id: type.id,
        displayValue: type.displayName,
        value: type.type,
        isSelected: false,
      }));
    } catch (err) {
      console.error(err);
      notificationService.error(t('couldNotFetchTypes'));
      return [];
    }
  }

  if (search) {
    return dsTypesCache.filter(item => {
      return item.displayValue?.toLowerCase().includes(search.toLowerCase());
    });
  }

  return dsTypesCache;
};

let locationsCache: BigidDropdownOption[] = null;
export const fetchLocations = async (search?: string): Promise<BigidDropdownOption[]> => {
  if (!locationsCache) {
    try {
      const data: CountryData[] = await locationService.getCountriesList();

      if (!data) throw new Error('No data found for countries!');

      locationsCache = data.map(country => ({
        id: country.displayName,
        displayValue: country.displayName,
        value: country.name,
        isSelected: false,
      }));
    } catch (err) {
      console.error(err);
      notificationService.error(t('couldNotFetchLocations'));
      return [];
    }
  }

  if (search) {
    return locationsCache.filter(item => {
      return item.displayValue?.toLowerCase().includes(search.toLowerCase());
    });
  }

  return locationsCache;
};

export const fetchInsights = async (oldFilter: BigidFilter) => {
  const filter = modifyFilter(oldFilter);
  try {
    const {
      data: {
        data: { states },
      },
    } = await httpService.fetch<{ data: { states: States } }>(
      `/data-minimization/datasources/insights?filter=${JSON.stringify(filter)}`,
    );
    return states;
  } catch (e) {
    console.error(e);
    notificationService.error(t('couldNotFetchInsights'));
    return {};
  }
};

let sortCache: BigidGridSorting[] = [];
export const fetchGridData: FetchDataFunction<DataSourceDeletionModel> = async queryComponents => {
  try {
    sortCache = queryComponents.sort;
    const newFilter = modifyFilter(queryComponents.filter);
    const gridConfigQuery = queryService.getGridConfigQuery({ ...queryComponents, filter: newFilter });

    const {
      data: {
        data: { data_minimization_data_sources, total_count },
      },
    } = await httpService.fetch<{ data: DeletionDataSourceResponse }>(
      `data-minimization/datasources?${gridConfigQuery}`,
    );
    return { data: data_minimization_data_sources, totalCount: total_count };
  } catch (e) {
    console.error(e);
    notificationService.error(t('couldNotFetchAlert'));
    return { data: [], totalCount: 0 };
  }
};

const modifyFilter = (oldFilter: BigidFilter = []) => {
  const newFilter = oldFilter.reduce((acc, curr) => {
    if (curr.field !== 'states') return [...acc, curr];

    const value = curr.value as BigidFilterOptionValueType[];
    const newStatesFilters: BigidFilter = value.map(item => ({
      field: `states.${item}`,
      operator: 'greaterThan',
      value: 0,
    }));

    return [...acc, ...newStatesFilters];
  }, []);

  return newFilter;
};
const getFilterForExecution = (actionData: ActionData) => {
  const { filter: oldFilter = [], allSelected = false, selectedRowIds = [] } = actionData;

  const filter: BigidFilter = oldFilter ? [...oldFilter] : [];
  if (!allSelected && selectedRowIds?.length) {
    filter.push({ field: 'name', operator: 'in', value: selectedRowIds });
  }

  return modifyFilter(filter);
};

export const executeDS: ActionExecuteFunction = async actionData => {
  const [id] = actionData.selectedRowIds || [];

  $state.go('configDataSource', { id });

  return {};
};

export const executeDelete: ActionExecuteFunction = async actionData => {
  let shouldExecute = false;
  analyticsService.trackManualEvent(events.DATA_DELETION_DELETE_CLICK);
  try {
    const deletionFilter = getFilterForExecution(actionData);
    const {
      data: {
        data: { count },
      },
    } = await httpService.post(`/data-minimization/datasources/objects/count`, {
      query: { filter: deletionFilter, requireTotalCount: false },
    });

    shouldExecute = await showConfirmationDialog({
      entityNameSingular: 'Data Deletion Object',
      customDescription: t('deletionAlert', { count }),
    });
    if (shouldExecute) {
      await httpService.post(`/data-minimization/datasources/execute`, {
        query: { filter: deletionFilter },
      });
      notificationService.success(t('executeRequestsConfirmed'));
    }
  } catch (e) {
    console.error(e);
    notificationService.error(t('couldNotExecuteAlert'));
  } finally {
    return Promise.resolve({ shouldGridReload: shouldExecute, shouldClearSelection: shouldExecute });
  }
};

export const executeCancel = async (actionData: ActionData) => {
  analyticsService.trackManualEvent(events.DATA_DELETION_CANCEL_CLICK);
  let shouldExecuteAction = false;
  try {
    const count = actionData?.selectedRows?.length || 0;
    shouldExecuteAction = await showConfirmationDialog({
      actionName: '',
      actionButtonName: t('cancelRequest', { count }),
      entityNameSingular: t('cancelRequest', { count }),
      customDescription: t('cancelAlert_one', { count }),
    });
    if (shouldExecuteAction) {
      const deletionFilter = getFilterForExecution(actionData);
      await httpService.delete(`data-minimization/datasources`, {
        query: { filter: deletionFilter },
      });
      notificationService.success(t('requestsCanceledSuccessfully'));
    }
  } catch (e) {
    console.error(e);
    notificationService.error(t('couldNotCancelAlert'));
  }
  return { shouldGridReload: shouldExecuteAction, shouldClearSelection: shouldExecuteAction };
};

export const executeExportObjects: ActionExecuteFunction = async actionData => {
  analyticsService.trackManualEvent(events.DATA_DELETION_EXPORT_OBJECTS_CLICK);
  try {
    const filter = getFilterForExecution(actionData);
    const gridConfigQuery = queryService.getGridConfigQuery({ filter, sort: sortCache });

    httpService.downloadFile(`/data-minimization/datasources/objects/file-download/export?${gridConfigQuery}`);
  } catch (e) {
    console.error(e);
    notificationService.error(t('couldNotExportAlert'));
  } finally {
    return Promise.resolve({ shouldGridReload: false });
  }
}

export const executeExportDS: ActionExecuteFunction = async actionData => {
  analyticsService.trackManualEvent(events.DATA_DELETION_EXPORT_DS_CLICK);
  try {
    const filter = getFilterForExecution(actionData);
    const gridConfigQuery = queryService.getGridConfigQuery({ filter, sort: sortCache });
    httpService.downloadFile(`data-minimization/datasources/file-download/export?${gridConfigQuery}`);
  } catch (e) {
    console.error(e);
    notificationService.error(t('couldNotExportAlert'));
  } finally {
    return Promise.resolve({ shouldGridReload: false });
  }
};
