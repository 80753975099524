import React, { FC } from 'react';
import styled from '@emotion/styled';
import { BigidLayoutEmptyState } from '@bigid-ui/layout';
import { useLocalTranslation } from '../../../../translations';
import { BigidFiltersIllustration } from '@bigid-ui/icons';

const Container = styled('div')`
  width: 100%;
  height: 100%;
  position: absolute;
`;

export const NoDataClassificationStep: FC = () => {
  const { t } = useLocalTranslation('noDataClassificationStep');
  return (
    <Container>
      <BigidLayoutEmptyState description={t('description')} illustration={BigidFiltersIllustration} />
    </Container>
  );
};
