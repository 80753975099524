import React, { FC, memo, useCallback, useEffect, useRef, useState } from 'react';
import {
  BigidFieldRenderProps,
  BigidFormField,
  BigidFormFieldSideLabelWrapper,
  BigidLoader,
  compareObjectsExceptFunctions,
  BigidFormRenderProps,
} from '@bigid-ui/components';
import makeStyles from '@mui/styles/makeStyles';
import { generateFields } from '../generateFields';
import { fieldConditionCheck } from '../utils/conditionUtils';
import { isObject } from 'lodash';
import { DsTypesEnum } from '../types';
import classNames from 'classnames';
import { getFieldPropsFunc } from '../utils/fieldUtils';

const useStyles = makeStyles({
  wrapper: {
    display: 'inline-flex',
    width: '100%',
    alignItems: 'center',
  },
  containerWrapper: {
    display: 'flex',
    width: '100%',
    flexWrap: 'wrap',
    justifyItems: 'center',
    flexDirection: 'row',
  },
});

export const FormVerticalObjectField: FC<
  BigidFieldRenderProps & {
    nestedDisabled?: string[];
    getFieldProps: BigidFormRenderProps['getFieldProps'];
  }
> = memo(
  ({
    setValue,
    name,
    label = '',
    value,
    misc,
    isRequired,
    labelWidth,
    tooltipText,
    disabled,
    touched,
    isSubmitted,
    isValidated,
    wasSubmitted,
    errorIsShown,
    error,
    fieldProps,
    nestedDisabled,
    getFieldProps,
  }) => {
    const valueRef = useRef(value);
    const [objectFieldProps, setObjectFieldProps] = useState<BigidFormField[]>();
    valueRef.current = value;
    const { wrapper, containerWrapper } = useStyles({});
    const objectFieldId = `bigid-form-field-object-${name}`;
    const updateFieldProps = useCallback(async () => {
      if (misc?.objectFields) {
        setObjectFieldProps(await generateFields(misc?.objectFields));
      }
    }, [misc?.objectFields]);

    useEffect(() => {
      updateFieldProps();
    }, [updateFieldProps]);

    return (
      <BigidFormFieldSideLabelWrapper
        id={objectFieldId}
        name={name}
        label={String(label)}
        isRequired={isRequired}
        labelWidth={labelWidth}
        tooltipText={tooltipText}
        noIndent
        isError={error && errorIsShown}
      >
        <div className={classNames(containerWrapper, 'FormVerticalObjectContainerdOverride')}>
          {objectFieldProps ? (
            (objectFieldProps as BigidFormField[])
              .filter(({ misc }) => !misc.hidden)
              .filter(
                ({ misc: { hidden, visibleIf, type } }) =>
                  fieldConditionCheck(hidden, visibleIf, getFieldPropsFunc(type, valueRef.current, getFieldProps)) ??
                  true,
              )
              .map(({ render: ObjectFieldRender, options, ...restProps }, index) => {
                const fieldError = isObject(error) ? error[restProps.name] : false;
                return (
                  <div key={restProps.name} className={wrapper}>
                    <ObjectFieldRender
                      name={`${name}-subfield-${restProps.name}`}
                      label={restProps.label}
                      error={fieldError}
                      disabled={
                        disabled ||
                        //TODO In theory, it can be activated for all types, but this will require manual retesting all templates
                        nestedDisabled?.includes(restProps.name)
                      }
                      touched={touched}
                      isSubmitted={isSubmitted}
                      isRequired={restProps.isRequired}
                      isValidated={isValidated}
                      errorIsShown={errorIsShown}
                      wasSubmitted={wasSubmitted}
                      value={valueRef.current?.[restProps.name] ?? restProps?.misc?.defaultValue}
                      setValue={(newValue: any) => {
                        const newValueCurrent = { ...(valueRef.current || {}) };
                        newValueCurrent[restProps.name] = newValue;
                        setValue(newValueCurrent);
                      }}
                      options={options}
                      misc={restProps?.misc || {}}
                      tooltipText={restProps?.tooltipText || ''}
                      fieldProps={fieldProps}
                    />
                  </div>
                );
              })
          ) : (
            <BigidLoader />
          )}
        </div>
      </BigidFormFieldSideLabelWrapper>
    );
  },
  compareObjectsExceptFunctions,
);

FormVerticalObjectField.displayName = 'FormVerticalObjectField';
