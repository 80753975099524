import './complianceTask.component.scss';

import { module } from 'angular';
import { objectToQueryString } from '../../react/utilities/urlUtilities';
import template from './complianceTask.component.html';
import { isPermitted } from '../../react/services/userPermissionsService';
import { INVENTORY_PERMISSIONS, CATALOG_PERMISSIONS } from '@bigid/permissions';

const app = module('app');

app.component('complianceTask', {
  template,
  controllerAs: 'complianceTaskModel',
  controller: function (
    taskListService,
    notificationService,
    $state,
    downloadFileService,
    queryStringService,
    objectsService,
    $translate,
  ) {
    'ngInject';

    const OBJECTS_REPORT_FILENAME = 'Objects.csv';
    const OBJECTS_REPORT_MIME_TYPE = 'attachment/csv';

    const LINK_TO_INVENTORY_PROP = 'includeLinkToInventory';
    const LINK_TO_CATALOG_PROP = 'includeLinkToCatalog';
    const OBJECTS_REPORT_PROP = 'includeObjectsReport';

    const INVENTORY_ROUTE = 'exploration';
    const INVENTORY_ROUTE_ALIAS = 'ROUTE:INVENTORY';

    const CATALOG_ROUTE = 'dataCatalog';
    const CATALOG_ROUTE_ALIAS = 'ROUTE:CATALOG';

    const INVESTIGATE_DROPDOWN_OPTIONS_MAPPING = {
      [LINK_TO_INVENTORY_PROP]: {
        name: 'Show in Inventory',
        action: 'onGoToInventory',
        targetAlias: INVENTORY_ROUTE_ALIAS,
        target: INVENTORY_ROUTE,
      },
      [LINK_TO_CATALOG_PROP]: {
        name: 'Show in Catalog',
        action: 'onGoToCatalog',
        targetAlias: CATALOG_ROUTE_ALIAS,
        target: CATALOG_ROUTE,
      },
      [OBJECTS_REPORT_PROP]: {
        name: 'Download Objects Report',
        action: 'onDownloadObjectsReport',
        targetAlias: INVENTORY_ROUTE_ALIAS,
        target: INVENTORY_ROUTE,
      },
    };

    const downloadByRuleType = {
      access_governance: downloadObjectsFromCatalogWithPI,
      catalog: downloadObjectsFromCatalog,
      privacy: downloadObjectsFromInventory,
    };

    async function downloadObjectsFromCatalogWithPI(filter) {
      console.log('hello world?');
      return await downloadObjectsFromCatalog(`${filter} AND contains_pi = true`);
    }

    async function downloadObjectsFromCatalog(filter) {
      console.log('hello world?1');
      console.log('downloading objects data from catalog');

      const query = objectToQueryString({
        filter,
      });

      const response = await objectsService.getObjectsExportFromCatalog(query);

      downloadFileService.download(response, OBJECTS_REPORT_MIME_TYPE, OBJECTS_REPORT_FILENAME);
    }

    async function downloadObjectsFromInventory(filter) {
      console.log('downloading object data from inventory');

      await objectsService.getObjectsExportFromInventory(encodeURIComponent(filter));
    }

    const complianceTaskModel = this;

    complianceTaskModel.rulesAction = ['None', 'Reviewed and Addressed'].map((action, idx) => ({
      id: idx + 1,
      name: action,
    }));

    complianceTaskModel.investigateDropdownOptions = [];
    complianceTaskModel.isInvestigateDropdownVisible = false;
    complianceTaskModel.investigateDropdownTitle = 'Investigate';

    complianceTaskModel.$onChanges = () => {
      complianceTaskModel.currentTask = this.task;
      complianceTaskModel.currentPolicyData = this.currentRuleData;

      if (typeof this.currentRuleData.taskSettings !== 'undefined') {
        const settings = Object.keys(this.currentRuleData.taskSettings);

        complianceTaskModel.investigateDropdownOptions = [];

        if (settings.length == 0) {
          complianceTaskModel.isInvestigateDropdownVisible = false;
        } else {
          complianceTaskModel.isInvestigateDropdownVisible = settings.some(
            option => this.currentRuleData.taskSettings[option],
          );

          settings.forEach(option => {
            if (
              this.currentRuleData.taskSettings[option] &&
              typeof INVESTIGATE_DROPDOWN_OPTIONS_MAPPING[option] !== 'undefined'
            ) {
              const investigateDropdownOption = {
                name: INVESTIGATE_DROPDOWN_OPTIONS_MAPPING[option]['name'],
                action: INVESTIGATE_DROPDOWN_OPTIONS_MAPPING[option]['action'],
              };

              if (typeof INVESTIGATE_DROPDOWN_OPTIONS_MAPPING[option]['targetAlias'] != 'undefined') {
                investigateDropdownOption.isDisabled = !isPermitted(INVENTORY_PERMISSIONS.ACCESS.name);
              }

              complianceTaskModel.investigateDropdownOptions = [
                ...complianceTaskModel.investigateDropdownOptions,
                investigateDropdownOption,
              ];
            }
          });
        }
      }

      if (complianceTaskModel.currentPolicyData && complianceTaskModel.currentPolicyData.type === 'Postpone') {
        complianceTaskModel.currentPolicyData.type = 'Reviewed and Addressed';
      }
    };

    complianceTaskModel.datePickPopUp = {
      opened: false,
    };
    complianceTaskModel.ruleActionDate = new Date();

    complianceTaskModel.openDatePicker = () => {
      complianceTaskModel.datePickPopUp.opened = true;
    };

    const createRuleDataFields = data => {
      if (complianceTaskModel.currentPolicyData.ruleActionDate) {
        data.fields.push({
          fieldName: 'postponeToDate',
          fieldValue: complianceTaskModel.currentPolicyData.ruleActionDate,
          type: 'complianceRulePostpone',
        });
      }
      if (complianceTaskModel.currentPolicyData.type) {
        data.fields.push({
          fieldName: 'ruleAction',
          fieldValue:
            complianceTaskModel.currentPolicyData.type === 'Reviewed and Addressed'
              ? 'POSTPONE'
              : complianceTaskModel.currentPolicyData.type.toUpperCase(),
          type: 'complianceRulePostpone',
        });
      }
      if (complianceTaskModel.currentPolicyData.resolutionDescription) {
        data.fields.push({
          fieldName: 'resolutionDescription',
          fieldValue: complianceTaskModel.currentPolicyData.resolutionDescription,
          type: 'complianceRulePostpone',
        });
      }
    };

    complianceTaskModel.updateRuleAction = (data = {}) => {
      if (!data.fields) {
        data.fields = [];
      }
      data.isEdit = true;
      createRuleDataFields(data);

      taskListService.updateTask(data, complianceTaskModel.currentTask._id).then(
        () => {
          notificationService.success('Task has successfully been updated!');
        },
        () => {
          notificationService.error('An error has occurred!');
        },
      );
    };

    complianceTaskModel.onGoToInventory = () => {
      const query = complianceTaskModel.currentPolicyData.complianceRuleCalc.bigidQuery;

      $state.go(INVENTORY_ROUTE, {
        searchTextBox: query,
        filter: queryStringService.getQueryStringFilter(query, null),
      });
    };

    complianceTaskModel.onGoToCatalog = () => {
      const ruleType = complianceTaskModel.currentPolicyData.type;
      let query = complianceTaskModel.currentPolicyData.complianceRuleCalc.bigidQuery;

      if (ruleType === 'access_governance') {
        query = `${query} AND contains_pi = true`;
      }

      $state.go(CATALOG_ROUTE, {
        filter: query,
      });
    };

    complianceTaskModel.onDownloadObjectsReport = async () => {
      const ruleType = complianceTaskModel.currentPolicyData.type;
      const ruleName = complianceTaskModel.currentPolicyData.name;
      const commonError = await $translate('API:MESSAGE:COMMON_ERROR');

      if (!downloadByRuleType[ruleType]) {
        console.error(`could not find proper objects download fuction for rule of type: "${ruleType}"`);

        notificationService.error(commonError);
      } else {
        try {
          downloadByRuleType[ruleType](complianceTaskModel.currentPolicyData.complianceRuleCalc.bigidQuery);
        } catch (error) {
          console.error(
            `could not download objects of rule of type: "${ruleType}" with name: "${ruleName}". Error: ${error.message}`,
          );

          notificationService.error(commonError);
        }
      }
    };

    complianceTaskModel.onInvestigateDropdownOptionClick = item => {
      if (typeof item.action != 'undefined' && typeof this[item.action] == 'function') {
        this[item.action]();
      }
    };
  },
  bindings: {
    task: '<',
    currentRuleData: '<',
  },
});
