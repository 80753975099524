import React, { useRef, useState } from 'react';
import { generateDataAid } from '@bigid-ui/utils';
import { BigidMoreActionIcon } from '@bigid-ui/icons';
import { BigidMenu, BigidMenuProps, BigidButtonIcon, BigidMenuItemProps } from '@bigid-ui/components';

interface DropdownActionsProps {
  items: BigidMenuItemProps[];
}

export const DropdownActions = ({ items }: DropdownActionsProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const iconRef = useRef<HTMLButtonElement>(null);

  const menuProps: BigidMenuProps = {
    open: isOpen,
    onMenuClose: () => setIsOpen(false),
    anchorEl: iconRef.current,
    items,
  };

  return (
    <>
      <BigidButtonIcon
        ref={iconRef}
        selected={isOpen}
        variant="tertiary"
        icon={BigidMoreActionIcon}
        onClick={() => setIsOpen(!isOpen)}
        dataAid={generateDataAid('BigidButtonIcon', ['drop-down-actions-opener'])}
      />
      <BigidMenu {...menuProps} paperStyle={{ marginTop: '8px' }} />
    </>
  );
};
