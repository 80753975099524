import React from 'react';
import { PrimaryButton, SecondaryButton, BigidBody1 } from '@bigid-ui/components';
import { openSystemDialog } from '../../../../services/systemDialogService';
import { getFixedT } from '../../translations';
import { noop } from 'lodash';
import { generateDataAid } from '@bigid-ui/utils';
import { styled } from '@mui/material';

const ModalContent = styled(BigidBody1)`
  white-space: pre-line;
`;

export const confirmationExportDialog = async () => {
  const t = getFixedT('confirmationExportDialog');

  return new Promise<any>(resolve => {
    openSystemDialog({
      title: t('title'),
      content: () => <ModalContent size="medium">{t('description') as React.ReactNode}</ModalContent>,
      maxWidth: 'xs',
      onClose: noop,
      buttons: [
        {
          text: t('cancelBtn'),
          component: SecondaryButton,
          onClick: noop,
          isClose: true,
        },
        {
          text: t('exportBtn'),
          component: PrimaryButton,
          onClick: () => {
            resolve(true);
          },
          isClose: true,
          dataAid: generateDataAid('confirmationExportDialog', ['export-button']),
        },
      ],
    });
  });
};
