import { getFixedT } from './../../../DataSourceSuggestedActions/translations';
import type { BigidFilterOptionType } from '@bigid-ui/components';

const t = getFixedT('');

export const getDsEnrichmentInitiativeOptions = (): BigidFilterOptionType[] => [
  {
    label: t('initiative.connectionError.chip'),
    value: 'connectionError',
    isSelected: false,
  },
  {
    label: t('initiative.maintainDataCoverage.chip'),
    value: 'maintainDataCoverage',
    isSelected: false,
  },
];
