import { module } from 'angular';
const app = module('app');

app.factory('consentLogService', [
  '$http',
  'appSettings',
  function ($http, appSettings) {
    return {
      getConsentLogData: function getConsentLogData(queryString) {
        queryString = queryString || '';
        return $http
          .get(appSettings.serverPath + '/' + appSettings.version + '/consent-logs?user_id=' + queryString)
          .then(function (response) {
            return response.data;
          });
      },
    };
  },
]);
