import { analyticsService } from '../../services/analyticsService';

export enum TPATrackingEvents {
  // Management Screen:
  TPA_MANAGEMENT_FAVORITE_CLICK = 'TPA_MANAGEMENT_FAVORITE_CLICK',
  TPA_MANAGEMENT_CARD_CLICK = 'TPA_MANAGEMENT_CARD_CLICK',
  TPA_MANAGEMENT_MORE_INFO_CLICK = 'TPA_MANAGEMENT_MORE_INFO_CLICK',
  TPA_MANAGEMENT_DELETE_CLICK = 'TPA_MANAGEMENT_DELETE_CLICK',
  TPA_MANAGEMENT_ABOUT_CLICK = 'TPA_MANAGEMENT_ABOUT_CLICK',
  TPA_MANAGEMENT_REFRESH_APP_CLICK = 'TPA_MANAGEMENT_REFRESH_APP_CLICK',

  // Actions screen:
  TPA_ACTION_RUN_CLICK = 'TPA_ACTION_RUN_CLICK',
  TPA_ACTION_CLONE_PRESET_CLICK = 'TPA_ACTION_CLONE_PRESET_CLICK',
  TPA_ACTION_ADD_PRESET_CLICK = 'TPA_ACTION_ADD_PRESET_CLICK',
  TPA_ACTION_EDIT_PRESET_CLICK = 'TPA_ACTION_EDIT_PRESET_CLICK',
  TPA_ACTION_DELETE_PRESET_CLICK = 'TPA_ACTION_DELETE_PRESET_CLICK',
  TPA_ACTION_PRESET_PREVIEW_CLICK = 'TPA_ACTION_PRESET_PREVIEW_CLICK',
  TPA_ACTION_SCHEDULE_MODAL_CLICK = 'TPA_ACTION_SCHEDULE_MODAL_CLICK',
  TPA_ACTION_SCHEDULE_CLICK = 'TPA_ACTION_SCHEDULE_CLICK',

  // Preset Screen:
  TPA_PRESET_SAVE_CLICK = 'TPA_PRESET_SAVE_CLICK',
  TPA_PRESET_CANCEL_CLICK = 'TPA_PRESET_CANCEL_CLICK',
  TPA_PRESET_OVERRIDE_GLOBAL_PARAM_CLICK = 'TPA_PRESET_OVERRIDE_GLOBAL_PARAM_CLICK',

  // Settings Screen:
  TPA_SETTINGS_PROXY_CLICK = 'TPA_SETTINGS_PROXY_CLICK',
  TPA_SETTINGS_ALLOW_DS_CREDENTIALS_CLICK = 'TPA_SETTINGS_ALLOW_DS_CREDENTIALS_CLICK',
  TPA_SETTINGS_SAVE_CLICK = 'TPA_SETTINGS_SAVE_CLICK',
  TPA_SETTINGS_UPDATE_CLICK = 'TPA_SETTINGS_UPDATE_CLICK',
}

export enum TPAPageViewsEvents {
  TPA_INSTALLED_APPLICATIONS_VIEW = 'TPA_INSTALLED_APPLICATIONS',
  TPA_PREFERENCES_APPLICATIONS_VIEW = 'TPA_PREFERENCES_APPLICATIONS',
  TPA_MARKETPLACE_APPLICATIONS_VIEW = 'TPA_MARKETPLACE_APPLICATIONS',
  TPA_REGISTER_VIEW = 'TPA_REGISTER',
  TPA_ACTIONS_VIEW = 'TPA_ACTIONS',
  TPA_UI_VIEW = 'TPA_UI',
  TPA_SETTINGS_VIEW = 'TPA_SETTINGS',
  TPA_ACTIVITY_LOG_VIEW = 'TPA_ACTIVITY_LOG',
  TPA_PRESET_VIEW = 'TPA_PRESET',
}

export const trackTPAPageView = (eventType: TPAPageViewsEvents) => {
  analyticsService.trackPageView(eventType as any);
};

export const trackTPAEvent = (eventType: TPATrackingEvents, data?: Record<string, unknown>) => {
  analyticsService.trackManualEvent(eventType as any, data);
};
