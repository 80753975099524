import React, { FC, ComponentType } from 'react';
import { StyledEngineProvider } from '@mui/material';
import { Experimental_CssVarsProvider as CssVarsProvider } from '@mui/material/styles';
import { BigidTheme } from '@bigid-ui/components';
// the line below is to enable theme css variables types
import type {} from '@mui/material/themeCssVarsAugmentation';

export function withTheme<Props>(Component: ComponentType<Props>): FC {
  return function wrapper(props) {
    return (
      <StyledEngineProvider injectFirst>
        <CssVarsProvider theme={BigidTheme}>
          <Component {...(props as Props)} />
        </CssVarsProvider>
      </StyledEngineProvider>
    );
  };
}
