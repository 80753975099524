import './friendlyNameModal.component.scss';
import template from './friendlyNameModal.component.html';
import angular from 'angular';

angular.module('app').component('friendlyNameModal', {
  template,
  controllerAs: '$ctrl',
  controller: [
    'identityLineageService',
    function (identityLineageService) {
      var ctrl = this;
      ctrl.attributes = [];

      ctrl.$onInit = () => {
        ctrl.attribute = this.attribute || {};
        ctrl.attribute.name = ctrl.attribute.friendly_name;
        ctrl.getAttributes(ctrl.attribute);
      };

      ctrl.getAttributes = attributeSelected => {
        identityLineageService.getAttributes().then(result => {
          ctrl.attributes = result;
          let attributeSelectedTemp = ctrl.attributes.filter(
            attributeLocal => attributeLocal.name === attributeSelected.friendly_name,
          );
          if (attributeSelectedTemp && attributeSelectedTemp.length > 0) {
            attributeSelected.isDescEnabled = true;
          }
          if (attributeSelected.name === attributeSelected.original_name) {
            ctrl.attributes.push({
              glossary_id: attributeSelected.glossary_id,
              type: 'Personal Data Item',
              name: attributeSelected.name,
              description: attributeSelected.description,
            });
          }
        });
      };

      ctrl.refreshResults = $select => {
        let search = $select.search;
        let list = angular.copy($select.items);
        let ID = -1;
        //remove last user input
        list = list.filter(function (item) {
          return item.id !== ID;
        });
        if (!search) {
          //use the predefined list
          $select.items = list;
        } else {
          //manually add user input and set selection
          var userInputItem = {
            id: ID,
            name: search,
          };
          $select.items = [userInputItem].concat(list);
          $select.selected = userInputItem;
        }
      };

      ctrl.clear = ($event, $select) => {
        $event.stopPropagation();
        ctrl.attribute.isDescEnabled = true;
        //to allow empty field, in order to force a selection remove the following line
        $select.selected = undefined;
        //reset search query
        $select.search = undefined;
        //focus and open dropdown
        $select.activate();
      };

      ctrl.setFriendlyName = (attribute, select) => {
        let attributeSelected = ctrl.attributes.filter(attributeLocal => attributeLocal.name === attribute.name);
        if (attributeSelected && attributeSelected.length > 0) {
          ctrl.attribute.friendly_name = attributeSelected[0].name || null;
          ctrl.attribute.glossary_id = attributeSelected[0].glossary_id || null;
          ctrl.attribute.description = attributeSelected[0].description || null;
          ctrl.attribute.isDescEnabled = true;
        } else {
          ctrl.attribute.friendly_name = select.search || null;
          ctrl.attribute.name = select.search;
          ctrl.attribute.glossary_id = null;
          ctrl.attribute.description = '';
          ctrl.attribute.isDescEnabled = false;
        }
      };
    },
  ],
  bindings: {
    attribute: '<',
    $close: '&',
    $dismiss: '&',
  },
});
