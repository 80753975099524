import React, { FC, lazy, Suspense } from 'react';
import { BigidLoader } from '@bigid-ui/components';

const FmsdMain = lazy(() => import(/* webpackChunkName: "FmsdMain" */ './FmsdMain'));

export const FmsdLazy: FC = () => {
  return (
    <Suspense fallback={<BigidLoader />}>
      <FmsdMain />
    </Suspense>
  );
};
