import React, { FC, useContext, useMemo } from 'react';
import styled from '@emotion/styled';
import { AutoDiscoveryWizardContext } from '../autoDiscoveryWizardContext';
import {
  BigidConnectionInProgressAnimation,
  BigidHeading4,
  EntityEvents,
  entityEventsEmitter,
  SecondaryButton,
} from '@bigid-ui/components';
import { Typography } from '@mui/material';
import { closeSystemDialog } from '../../../../services/systemDialogService';
import { useLocalTranslation } from '../../translations';
import {
  AutoDiscoveryWizardDs,
  AutoDiscoveryWizardDsProps,
} from '../autoDiscoveryComponents/AutoDiscoveryWizardForm/AutoDiscoveryWizardDsList/AutoDiscoveryWizardDs';

export const Content = styled.div`
  display: flex;
  gap: 0.5rem;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 480px;
`;

export const Image = styled.div`
  margin-bottom: 1rem;
`;

export const Overlay = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  background: ${({ theme }) => theme.vars.palette.bigid.white};
`;

export const OverlayInnerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
`;

export const Actions = styled.div`
  margin-top: 54px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  width: 100%;

  & button {
    width: 216px;
    border: none;
  }

  & button:hover {
    border: none;
  }
`;

export interface AutoDiscoveryWizardOverlayProps {
  onClick?: () => void;
}
export const AutoDiscoveryWizardOverlay: FC<AutoDiscoveryWizardOverlayProps> = ({ onClick }) => {
  const { discoveryConfigData } = useContext(AutoDiscoveryWizardContext);
  const { t } = useLocalTranslation('AutoDiscovery');
  const onClickHandler = useMemo(
    () =>
      onClick
        ? onClick
        : () => {
            closeSystemDialog();
            entityEventsEmitter.emit(EntityEvents.RELOAD);
          },
    [onClick],
  );
  const dsTypesList = discoveryConfigData.formData?.dsList
    ?.filter(({ isPermitted }: { isPermitted: boolean }) => isPermitted)
    .map(({ label }: AutoDiscoveryWizardDsProps) => label)
    .join(', ');

  return (
    discoveryConfigData.isDiscovery && (
      <Overlay>
        <OverlayInnerWrapper data-aid={'AutoDiscoveryWizardOverlay-Content'}>
          <Image>
            <BigidConnectionInProgressAnimation width={159} height={155} />
          </Image>
          <Content>
            <BigidHeading4>
              {!!dsTypesList?.length
                ? t('configModal.discoveringInProgress.line1WithDsList', { dsTypesList })
                : t('configModal.discoveringInProgress.line1')}
            </BigidHeading4>
            <Typography variant="body1">{t('configModal.discoveringInProgress.line2')}</Typography>
            <Typography variant="body1">
              {t(`configModal.discoveringInProgress.${discoveryConfigData?.isScan ? 'line3WithScan' : 'line3'}`)}
            </Typography>
            <Actions>
              <SecondaryButton
                dataAid={'AutoDiscoveryWizardOverlay-Save'}
                size="large"
                onClick={onClickHandler}
                text={t('configModal.buttons.saveAndClose')}
              />
            </Actions>
          </Content>
        </OverlayInnerWrapper>
      </Overlay>
    )
  );
};
