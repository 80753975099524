import React, { ReactNode } from 'react';
import {
  DataSourceConnectionDialogContextType,
  useDataSourceConnectionDialogContext,
} from './DataSourceConnectionDialogProvider';
import { DialogSlots } from '../DataSourceConfiguration/types';

type DataSourceConnectionDialogFooterProps = {
  render?: (props: DataSourceConnectionDialogContextType) => JSX.Element;
  children?: ReactNode;
};

export const DataSourceConnectionDialogFooter = ({
  render,
  children,
}: DataSourceConnectionDialogFooterProps): JSX.Element => {
  const context = useDataSourceConnectionDialogContext(DialogSlots.FOOTER);
  return <>{render ? render(context) : children}</>;
};
