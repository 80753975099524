import React, { FC, useEffect, useState } from 'react';
import {
  BigidAccordion,
  BigidAccordionDetails,
  BigidAccordionSummary,
  BigidColorsV2,
  BigidHeading6,
  BigidTooltip,
  FlexJustifyContent,
  PieChartData,
} from '@bigid-ui/components';
import { PieChartSectionData, ScanChildInfo, ScanDetailsParts, BasicPartsStates, SubscanStage } from '../ScanInsightTypes';
import styled from '@emotion/styled';
import { generateDataAid } from '@bigid-ui/utils';
import { fetchScanChildInfo } from '../ScanInsightService';
import { PieInsightsSection } from './PieInsightsSection';
import { buildPiiChartDataFF, InnerTotalTasks } from '../../ScanUtils';
import { add, isNil, mergeWith, sumBy } from 'lodash';
import { BigidHelpIcon } from '@bigid-ui/icons';
import makeStyles from '@mui/styles/makeStyles';
import { TotalFindingsContainer } from './TotalFindingsSection';

const useStyles = makeStyles({
  pieInsightsSectionClassName: {
    borderTop: `1px solid ${BigidColorsV2.gray[300]}`,
    paddingTop: '24px',
  },
});

const THIRTY_SECONEDS = 30000;
const TOTAL_TASKS_TOOLTIP =
  'In order for a process to be completed, all its tasks must be done. Once all the processes are done, the entire scan will be marked as completed.';

const SCANNER_TASKS_TOOLTIP =
  'Scanner tasks are done by the scanner when scanning your data source. The tasks are the parts of the data source that need to be scanned, such as tables, files, folders, and mail boxes, etc. A scan will be completed when all the scanner tasks and summary tasks are done.';

const SUMMRY_TASKS_TOOLTIP =
  'Summary tasks are all the tasks required to analyze, calculate and summarize attributes found in the scanned objects.A scan will be completed when all the summary tasks and scanner tasks are done.';

const InsightsContainer = styled('div')`
  display: flex;
  flex-direction: column;
`;

const CollapseTitle = styled(BigidHeading6)`
  font-size: 14px;
  font-weight: 400;
`;

const BigidAccordionSummaryContainer = styled(BigidAccordionSummary)`
  width: 120px;
  padding: 0 !important;
`;

const BigidAccordionContainer = styled(BigidAccordion)`
  box-shadow: none !important;
  margin-bottom: 26px !important;
  border: none !important;
`;

const BigidAccordionDetailsContainer = styled(BigidAccordionDetails)`
  border-top: none !important;
  padding: 0 !important;
`;

const SummarySection = styled('div')`
  display: flex;
  flex-direction: column;
`;

const SummaryContainer = styled('div')`
  padding: 16px 12px;
  border: 1px solid ${BigidColorsV2.gray[300]};
  border-radius: 5px;
  margin-top: 16px;
`;

const InsightSubTitleContainer = styled('div')`
  display: flex;
  align-items: center;
  gap: 6px;
`;

const IconContainer = styled('div')`
  display: flex;
`;

interface InsightsProps {
  dataAid?: string;
  scanDetails: Partial<ScanChildInfo>;
  onUpdateScanDetails: (scanDetails: Partial<ScanChildInfo>) => void;
}

enum ObjectsStates {
  OBJECTS_SCANNED = 'objectsScanned',
  OBJECTS_FAILED = 'objectsFailed',
  OBJECTS_SKIPPED = 'objectsSkipped',
}

enum PieChartCategories {
  COMPLETED = 'Completed',
  FAILED = 'Failed',
  IN_PROGRESS = 'In Progress',
  QUEUED = 'Queued',
  STOPPED = 'Stopped',
  SKIPPED = 'Skipped',
}

interface InsightSection {
  title?: string;
  dataAid?: string;
  data: PieChartSectionData[];
  summary?: string;
  datAidSummary?: string;
}
interface InsightSection2 {
  totalTasks: InsightSection;
  innerTotalTasks: InnerTotalTasks[];
  objectsScanned: any;
  totalFindings: any;
}

export const InsightsFFforLabeling: FC<InsightsProps> = ({
  scanDetails,
  onUpdateScanDetails,
  dataAid = 'Insights',
}) => {
  const [insightSectionsData, setInsightSectionsData] = useState<InsightSection2>();
  const classes = useStyles({});

  useEffect(() => {
    updatePartsData(scanDetails.Correlation, scanDetails.Scan, scanDetails.Summary, scanDetails.Classification);

    let interval: ReturnType<typeof setInterval>;
    const timeout = setTimeout(() => {
      interval = setInterval(handleUpdatePartsData, THIRTY_SECONEDS);
    }, THIRTY_SECONEDS);

    return () => {
      clearTimeout(timeout);
      clearInterval(interval);
    };
  }, [scanDetails]);

  const handleUpdatePartsData = async () => {
    const scanChildInfo = await fetchScanChildInfo(scanDetails._id);
    onUpdateScanDetails(scanChildInfo);
  };

  const updatePartsData = (
    correlation: BasicPartsStates,
    scan: ScanDetailsParts,
    summary: BasicPartsStates,
    classification: BasicPartsStates,
  ) => {
    const sumTotalParts = mergeWith({}, classification, correlation, scan, add);
    const totalPartsForLabeling = buildPiiChartDataFF(sumTotalParts);
    const totalTasksForLabeling: InsightSection = {
      data: [
        {
          dataAid: generateDataAid(dataAid, ['totalTasks']),
          data: totalPartsForLabeling,
          total: sumBy([scan, classification, correlation], 'totalParts'),
          withTooltips: true,
          showZeroedTotal: true,
          showZeroedData: true,
          entityName: 'Tasks',
          disableFiltering: sumBy([scan, classification, correlation], 'totalParts') === 0,
          dataAidLabels: generateDataAid(dataAid, ['totalTasks', 'labels']),
          insightSubTitleContent: (
            <InsightSubTitleContainer>
              <span>Total Tasks</span>
              <BigidTooltip title={TOTAL_TASKS_TOOLTIP} placement="right">
                <IconContainer>
                  <BigidHelpIcon />
                </IconContainer>
              </BigidTooltip>
            </InsightSubTitleContainer>
          ),
        },
      ],
    };

    const innerTotalTasksForLabeling: InnerTotalTasks[] = [
      {
        title: scan?.totalParts,
        subTitle: 'Scanner Tasks',
        subTitleTooltip: (
          <BigidTooltip title={SCANNER_TASKS_TOOLTIP} placement="right">
            <IconContainer>
              <BigidHelpIcon />
            </IconContainer>
          </BigidTooltip>
        ),
        progressItems: [
          {
            id: 1,
            value: scan.partsCompleted,
            legend: 'Completed',
            color: BigidColorsV2.green[300],
          },
          {
            id: 2,
            value: scan.partsFailed,
            legend: 'Failed',
            color: BigidColorsV2.red[300],
          },
          {
            id: 3,
            value: scan.partsInProgress,
            legend: 'In Progress',
            color: BigidColorsV2.blue[300],
          },
          {
            id: 4,
            value: scan.partsQueued,
            legend: 'Not Started',
            color: BigidColorsV2.gray[200],
          },
          {
            id: 5,
            value: scan.partsStopped,
            legend: 'Stopped',
            color: BigidColorsV2.gray[400],
          },
        ],
        highlighterFullwidth: false,
        highlighter: true,
        highlighterPosition: 'start' as FlexJustifyContent,
        isConvertValueToPercentage: true,
        isShowHighlighterWithValues: true,
        highlighterAutoWidth: true,
        dataAid: generateDataAid('Insights', ['ScannerTasks']),
      },
    ];

    const objectsScanned = {
      data: [
        {
          dataAid: generateDataAid(dataAid, ['objectsScanned']),
          data: getObjectsDataForPiiChart(scan),
          total: scan.objectsScanned + scan.objectsFailed + scan.objectsSkipped,
          title: scanDetails.type === SubscanStage.TAGGING_SCAN ? 'Objects Labeled' : 'Objects Scanned',
          withTooltips: true,
          showZeroedTotal: true,
          showZeroedData: true,
          entityName: 'Total',
          disableFiltering: scan.objectsScanned + scan.objectsFailed + scan.objectsSkipped === 0,
          dataAidLabels: generateDataAid(dataAid, ['objectsScanned', 'labels']),
        },
      ],
    };

    setInsightSectionsData({
      innerTotalTasks: innerTotalTasksForLabeling,
      totalTasks: totalTasksForLabeling,
      objectsScanned,
      totalFindings: scan.totalFindings,
    });
  };

  const getObjectsDataForPiiChart = (data: ScanDetailsParts) => {
    const objects: PieChartData<string>[] = [];
    for (const [key, value] of Object.entries(data)) {
      switch (key) {
        case ObjectsStates.OBJECTS_SCANNED:
          objects.push({ category: PieChartCategories.COMPLETED, value, color: BigidColorsV2.green[300] });
          break;
        case ObjectsStates.OBJECTS_FAILED:
          objects.push({ category: PieChartCategories.FAILED, value, color: BigidColorsV2.red[300] });
          break;
        case ObjectsStates.OBJECTS_SKIPPED:
          objects.push({ category: PieChartCategories.SKIPPED, value, color: BigidColorsV2.gray[500] });
          break;
        default:
          break;
      }
    }
    return objects;
  };

  return (
    <>
      {scanDetails && (
        <InsightsContainer>
          {insightSectionsData?.totalTasks && (
            <PieInsightsSection data={insightSectionsData.totalTasks.data} alignItems="center" />
          )}
          {insightSectionsData?.objectsScanned && (
            <PieInsightsSection
              data={insightSectionsData.objectsScanned.data}
              pieInsightsSectionClassName={classes.pieInsightsSectionClassName}
              alignItems="center"
            />
          )}
          {insightSectionsData &&
            !isNil(insightSectionsData.totalFindings) &&
            scanDetails.type !== SubscanStage.TAGGING_SCAN && (
              <TotalFindingsContainer totalFindings={insightSectionsData.totalFindings} />
            )}
        </InsightsContainer>
      )}
    </>
  );
};
