import './mapTreeMap.component.scss';
import template from './mapTreeMap.component.html';
import { setAmMapData } from '../../common/amMap/ammap.service';
import { convertToReact } from '../../common/services/convertToReact';

const componentDefinitionObject = {
  template,
  controllerAs: 'mapTreeMapModel',
  controller: function ($rootScope, $scope, $state, privacyRiskMapService) {
    'ngInject';

    const mapTreeMapModel = this;

    mapTreeMapModel.loading = false;

    mapTreeMapModel.mapContainerStyle =
      window.innerWidth >= 1200 && window.innerWidth <= 1600 ? "{'height': '500px'}" : "{'height': '590px'}";

    mapTreeMapModel.$onInit = function () {
      mapTreeMapModel.isClickedOnImageObject = this.isClickedOnImageObject;
      mapTreeMapModel.mapClass = 'imageMapSelected';
      mapTreeMapModel.mapTreeClass = 'imageTreeMapNotSelected';
      mapTreeMapModel.showMapDiv = true;
      getPrivacyRiskMapResults(mapTreeMapModel.useBindingsDataFlow ? this.queryString : undefined);
    };

    const getPrivacyRiskMapResults = queryString => {
      privacyRiskMapService.getPrivacyRiskMapResults(queryString).then(function (result) {
        setAmMapData(mapTreeMapModel, result);
      });
    };

    // new component operational mode -> will use bindings for query string input
    this.$onChanges = changesObj => {
      if (!mapTreeMapModel.useBindingsDataFlow) {
        return;
      }
      const { queryString } = changesObj;
      if (queryString && !queryString.isFirstChange()) {
        getPrivacyRiskMapResults(this.queryString);
      }
    };

    // START: legacy component operational mode -> will use rootScope events for query string input
    const listenerCallToHeaderQueryFilter = $rootScope.$on('callToHeaderQueryFilter', function (event, filterValue) {
      if (mapTreeMapModel.useBindingsDataFlow) {
        return;
      }
      mapTreeMapModel.querystring = filterValue;
      getPrivacyRiskMapResults(filterValue);
    });

    const listenerCleanFilter = $rootScope.$on('cleanFilter', function (event) {
      if (mapTreeMapModel.useBindingsDataFlow) {
        return;
      }
      getPrivacyRiskMapResults();
    });

    // Unregister listeners
    $scope.$on('$destroy', function () {
      listenerCallToHeaderQueryFilter();
      listenerCleanFilter();
    });
    // END: legacy component operational mode -> will use rootScope events for query string input

    mapTreeMapModel.showMap = function () {
      mapTreeMapModel.mapClass = 'imageMapSelected';
      mapTreeMapModel.mapTreeClass = 'imageTreeMapNotSelected';
      mapTreeMapModel.showMapDiv = true;
    };

    mapTreeMapModel.showTreeMap = function () {
      mapTreeMapModel.mapClass = 'imageMapNotSelected';
      mapTreeMapModel.mapTreeClass = 'imageTreeMapSelected';
      mapTreeMapModel.showMapDiv = false;
    };

    mapTreeMapModel.reviewResults = function () {
      $state.go('scanAnalysis');
    };

    mapTreeMapModel.onChartRenderCompleted = () => {
      mapTreeMapModel.loading = false;
    };

    mapTreeMapModel.onChartRenderStarted = () => {
      mapTreeMapModel.loading = true;
    };

    mapTreeMapModel.onZoomChanged = data => {
      mapTreeMapModel.onMapZoomChanged({ data });
    };
  },
  bindings: {
    mapZoomLevel: '<',
    mapZoomLatitude: '<',
    mapZoomLongitude: '<',
    isClickedOnImageObject: '<',
    onMapZoomChanged: '&',
    useBindingsDataFlow: '<',
    onAmmapFilterChange: '<',
    onAmmapClearFilter: '<',
    queryString: '<',
  },
};

export const MapTreeMap = convertToReact('mapTreeMap', componentDefinitionObject);
