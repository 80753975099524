import React from 'react';
import { BigidFormRenderProps, BigidHeading5 } from '@bigid-ui/components';
import { EditUserFormSection, EditUserBlock, EditUserForm } from './styles';
import { getFixedT } from '../translations';

export const renderEditUserForm =
  (isPasswordSectionEnabled: boolean) =>
  ({ renderField }: BigidFormRenderProps) => {
    const t = getFixedT('dialogs.createUser');
    const getTranslation = (key: string): string => t(key);

    return (
      <EditUserForm>
        <EditUserFormSection>
          <BigidHeading5>{getTranslation('detailsLabel')}</BigidHeading5>
          <EditUserBlock>
            {renderField('firstName')}
            {renderField('lastName')}
            {renderField('name')}
            {renderField('origin')}
          </EditUserBlock>
        </EditUserFormSection>
        {isPasswordSectionEnabled && (
          <EditUserFormSection>
            <BigidHeading5>{getTranslation('passwordLabel')}</BigidHeading5>
            <EditUserBlock>
              {renderField('password')}
              {renderField('passwordRepeat')}
            </EditUserBlock>
          </EditUserFormSection>
        )}
      </EditUserForm>
    );
  };
