import { useState, useEffect, useMemo } from 'react';
import { $rootScope } from 'ngimport';
import { $state } from '../../../services/angularServices';
import { debounce } from 'lodash';
import { getApplicationPreference } from '../../../services/appPreferencesService';

export const useLocationChange = () => {
  const [stateParams, setStateParams] = useState({ ...$state.params });
  const isNewDsarPage = getApplicationPreference('DSAR_USE_CATALOG_COLUMNS');

  useEffect(() => {
    const handleLocationChange = debounce(() => {
      if (isNewDsarPage) {
        setStateParams({ ...$state.params });
      }
    }, 100);
    return $rootScope.$on('$locationChangeSuccess', handleLocationChange);
  }, [isNewDsarPage]);

  return useMemo(
    () => ({
      stateParams,
    }),
    [stateParams],
  );
};
