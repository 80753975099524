import React, { ReactNode } from 'react';
import {
  BigidFileIcon,
  BigidOtherIcon,
  BigidTableColumnsIcon,
  BigidModelIcon,
  BigidDatasetIcon,
  BigidVectorDbIcon,
} from '@bigid-ui/icons';
import { DataCatalogAsyncOpsProcessingWidget } from './DataCatalogAsyncOps/DataCatalogAsyncOpsProcessingWidget';
import { DataCatalogObjectType } from './DataCatalogService';
import { $state } from '../../services/angularServices';
import { CONFIG } from '../../../config/common';
import { ScannerTypeGroup } from '../Curation/curationService';
import { getApplicationPreference } from '../../services/appPreferencesService';
import { isDataExplorerEnabled } from '../../utilities/featureFlagUtils';

export const getIsClassifyFileNamesEnabled = () => getApplicationPreference('CLASSIFY_FILE_NAMES_ENABLED');

export const getObjectTypeName = (type: DataCatalogObjectType) => {
  switch (type) {
    case DataCatalogObjectType.TABLE:
    case DataCatalogObjectType.STRUCTURED_LEAF:
      return 'RDB';
    case DataCatalogObjectType.STRUCTURED_FILE_LEAF:
      return 'Structured File';
    case DataCatalogObjectType.PARTITIONED_TABLE_LEAF:
      return 'Table';
    case DataCatalogObjectType.APP_LEAF:
      return 'APP';
    case DataCatalogObjectType.TABLE_VIEW:
      return 'RDB - View';
    case DataCatalogObjectType.FILE:
    case DataCatalogObjectType.UNSTRUCTURED_LEAF:
      return 'File';
    case DataCatalogObjectType.MODEL:
      return 'Model';
    case DataCatalogObjectType.DATASET:
      return 'Dataset';
    case DataCatalogObjectType.VECTOR:
      return 'Vector';
    default:
      return '';
  }
};

export const getIconByObjectType = (type: DataCatalogObjectType) => {
  switch (type) {
    case DataCatalogObjectType.TABLE:
    case DataCatalogObjectType.STRUCTURED_LEAF:
    case DataCatalogObjectType.APP_LEAF:
    case DataCatalogObjectType.TABLE_VIEW:
      return BigidTableColumnsIcon;
    case DataCatalogObjectType.STRUCTURED_FILE_LEAF:
    case DataCatalogObjectType.PARTITIONED_TABLE_LEAF:
    case DataCatalogObjectType.FILE:
    case DataCatalogObjectType.UNSTRUCTURED_LEAF:
      return BigidFileIcon;
    case DataCatalogObjectType.MODEL:
      return BigidModelIcon;
    case DataCatalogObjectType.DATASET:
      return BigidDatasetIcon;
    case DataCatalogObjectType.VECTOR:
      return BigidVectorDbIcon;
    default:
      return BigidOtherIcon;
  }
};

export const scannerTypeGroupToIconMap: Record<ScannerTypeGroup, ReactNode> = {
  [ScannerTypeGroup.UNSTRUCTURED]: <BigidFileIcon />,
  [ScannerTypeGroup.STRUCTURED]: <BigidTableColumnsIcon />,
  [ScannerTypeGroup.EMAIL]: <BigidOtherIcon />,
};

export const getTitleServiceConfig = (
  isTagsBulkAssignmentEnabled: boolean,
  isColumnClusteringEnabled: boolean,
  handleProcessingWidgetGotCompletedOperations: () => void,
) => {
  const basicConfig = {
    rightSideComponentsContainer:
      isTagsBulkAssignmentEnabled || isColumnClusteringEnabled ? (
        <DataCatalogAsyncOpsProcessingWidget onUpdate={handleProcessingWidgetGotCompletedOperations} />
      ) : undefined,
  };

  if (isDataExplorerEnabled()) {
    return {
      ...basicConfig,
      breadcrumbs: [
        {
          label: 'Data Explorer',
          onClick: () => $state.go(CONFIG.states.DATA_EXPLORER),
        },
        {
          label: 'Registry and Metadata Catalog',
          onClick: () =>
            $state.go($state.current.name, { filter: null, sideFilterConfig: null }, { reload: false, notify: false }),
        },
      ],
    };
  }

  return {
    ...basicConfig,
    pageTitle: 'Registry and Metadata Catalog',
  };
};
