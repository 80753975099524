import { BigidBody1, BigidLink } from '@bigid-ui/components';
import React from 'react';
import { $state } from '../../../services/angularServices';

export const ScanStartedDialog = (templateName: string, templateId?: string) => (
  <BigidBody1>
    {`Template: ${templateId ? '' : templateName}`}
    {templateId && (
      <BigidLink
        onClick={() => {
          $state.go('scanTemplate', {
            id: templateId,
            name: templateName,
          });
        }}
        text={templateName}
      />
    )}
  </BigidBody1>
);
