import { BigidColorsV2 } from '@bigid-ui/components';

export const TOTAL_OBJECTS = 'totalObjects';
export const TOTAL_OBJECTS_WITH_PII_AND_OPEN_ACCESS = 'totalObjectsWithPiiAndOpenAccess';
export const TOTAL_OBJECTS_WITH_PII = 'totalObjectsWithPii';

export const OPEN_ACCESS_FILTER = 'metadata.access_rights=OPEN ACCESS';
export const DATA_SOURCES_FILTER_PREFIX = 'system';

export const MAX_NUMBER_OF_ACCESS_TYPES = 5;
export const MAX_NUMBER_OF_DATA_SOURCES = 5;

export interface Objects {
  [TOTAL_OBJECTS]: number;
  [TOTAL_OBJECTS_WITH_PII]: number;
  [TOTAL_OBJECTS_WITH_PII_AND_OPEN_ACCESS]: number;
}

export interface DataSourcesAggregation {
  dataSourcesWithPii: number;
  dataSourcesWithPiiAndOpenAccess: number;
}

export type ObjectsByDataSource = Objects & {
  source: string;
};

export type ObjectsByType = Objects & {
  totalDataSources: number;
  type: string;
  totalObjectsWithPii?: number;
  totalObjects?: number;
  totalObjectsWithPiiAndOpenAccess?: number;
};

export interface AccessTypeSummary {
  _id: string;
  name: string;
  count: number;
}

export const dataSources = {
  dataSourcesWithPii: {
    label: 'Without Open Access',
    color: BigidColorsV2.orange[700],
  },
  dataSourcesWithPiiAndOpenAccess: {
    label: 'With Open Access',
    color: BigidColorsV2.red[600],
  },
};
