import { useCallback, useEffect, useRef, useState } from 'react';
import {
  fetchFavoriteTemplates,
  fetchTemplateFavoritePreferences,
  getDefaultScanTemplate,
} from '../../../Scans/ScanTemplates/scanTemplatesService';
import { getCurrentUser } from '../../../../utilities/systemUsersUtils';
import { isTemplatesEnabled } from '../../../Scans/ScanUtils';
import { isPermitted } from '../../../../services/userPermissionsService';
import { SCANS_PERMISSIONS } from '@bigid/permissions';
import { notificationService } from '../../../../services/notificationService';
import { ScanTemplateGridRow, ScanTypes } from '../../../Scans/ScanTemplates/scanTemplateTypes';
import { isEmpty, uniqBy } from 'lodash';
import { executeQuickScanForDataSource, getQuickScanOptions } from '../InstantScanUtils';

const INITIAL_STATE = {
  isScanTemplatesLoading: true,
  isScanTemplatesEnabled: false,
};
const SCAN_IDENTIFIER = '_id';

export type InstantScanExecuteQuickScanOpts = {
  filterByScanType: ScanTypes[];
};

export type UseInstantScanDataOpts = {
  enabled?: boolean;
};

export interface InstantScanOptionsState {
  isScanTemplatesLoading: boolean;
  isScanTemplatesEnabled: boolean;
  currentOwner?: { firstName: string; lastName: string; name: string; id: string };
  favoriteTemplates?: ScanTemplateGridRow[];
  defaultScanTemplates?: ScanTemplateGridRow[];
}

export const useInstantScanData = (opts?: UseInstantScanDataOpts) => {
  const [state, setState] = useState<InstantScanOptionsState>(INITIAL_STATE);
  const supportedTemplatesRef = useRef<any>();
  const isScanTemplatesEnabledRef = useRef(state.isScanTemplatesEnabled);
  const oneFavoriteScanTemplate = useRef<null | Record<string, any>>(null);
  const [updateCount, setUpdateCount] = useState(0);
  const { enabled = true } = opts ?? {};
  isScanTemplatesEnabledRef.current = state.isScanTemplatesEnabled;

  useEffect(() => {
    const init = async () => {
      try {
        const [favoriteScanTemplates, defaultScanTemplates, currentOwner] = await Promise.all([
          fetchTemplateFavoritePreferences(),
          getDefaultScanTemplate(),
          getCurrentUser(),
        ]);
        const { favoriteTemplates } = await fetchFavoriteTemplates(
          favoriteScanTemplates.map(({ scanTemplateId }) => scanTemplateId),
        );

        setState({
          isScanTemplatesLoading: false,
          isScanTemplatesEnabled: true,
          currentOwner,
          favoriteTemplates,
          defaultScanTemplates,
        });
      } catch (e) {
        notificationService.error('Failed to load favorite templates. See logs for more information.');
        console.error(e);
        setState({
          isScanTemplatesLoading: false,
          isScanTemplatesEnabled: false,
        });
      }
    };

    if (isTemplatesEnabled() && isPermitted(SCANS_PERMISSIONS.RUN_SCAN_PROFILES.name) && enabled) {
      init();
    } else {
      setState({
        isScanTemplatesLoading: false,
        isScanTemplatesEnabled: false,
      });
    }
  }, [enabled]);

  const updateFavoriteTemplates = useCallback(
    (supportedScanTypesForSelectedDs: ScanTypes[]) => {
      if (!state.defaultScanTemplates || !state.favoriteTemplates) {
        return;
      }
      const favoriteTemplatesWithSupportedScanTypes = uniqBy(
        [...(!isEmpty(state.defaultScanTemplates) ? state.defaultScanTemplates : []), ...state.favoriteTemplates],
        '_id',
      ).filter(template => supportedScanTypesForSelectedDs?.includes(template.scanType as ScanTypes));

      const scanTemplates = getQuickScanOptions(favoriteTemplatesWithSupportedScanTypes, state.currentOwner.name);
      const newOneFavoriteScanTemplate =
        scanTemplates?.length === 1 && scanTemplates[0]?.hasSeparator ? scanTemplates[0] : null;

      const isUpdate =
        newOneFavoriteScanTemplate?.value?.scanTemplateId !== oneFavoriteScanTemplate.current?.value?.scanTemplateId ||
        scanTemplates?.length !== supportedTemplatesRef?.current?.length;
      oneFavoriteScanTemplate.current = newOneFavoriteScanTemplate;
      supportedTemplatesRef.current = scanTemplates;

      if (isUpdate) {
        setUpdateCount(curr => curr + 1);
      }
      return scanTemplates;
    },
    [state],
  );
  const updateSupportedTemplatesRef = useRef(updateFavoriteTemplates);
  updateSupportedTemplatesRef.current = updateFavoriteTemplates;

  const executeQuickScan = (sourceId: string, templateId: string, opts?: InstantScanExecuteQuickScanOpts) => {
    const supported = getSupportedTemplates(opts?.filterByScanType);

    const templateSupported = supported?.find(({ _id: scanTemplateId }) => scanTemplateId === templateId);
    templateSupported
      ? executeQuickScanForDataSource(templateSupported, sourceId, state?.currentOwner?.name)
      : console.error('Quick scan for template not supported');
  };

  const getSupportedTemplates = (scanTypes?: ScanTypes[]) =>
    uniqBy([...(state?.defaultScanTemplates ?? []), ...(state?.favoriteTemplates ?? [])], SCAN_IDENTIFIER).filter(
      ({ scanType }) => (scanTypes ? scanTypes.includes(scanType as ScanTypes) : true),
    );

  return {
    updateCount,
    isScanTemplatesEnabledRef,
    supportedTemplatesRef,
    oneFavoriteScanTemplate,
    updateSupportedTemplates: updateSupportedTemplatesRef,
    getSupportedTemplates,
    executeQuickScan,
    ...state,
  };
};
