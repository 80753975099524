import React, { FC, useEffect, useState } from 'react';
import {
  BigidPrimaryCheckbox,
  BigidHeading6,
  BigidFormFieldSelect,
  BigidSelectOption,
  BigidTooltip,
  BigidLoader,
} from '@bigid-ui/components';
import { Collapse } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { ApiCallCertificateConfiguration } from '../../views/ActionCenter/ActionWorkflow/actionWorkflowTypes';
import { notificationService } from '../../services/notificationService';
import { CERTIFICATES_PERMISSIONS } from '@bigid/permissions';
import { isPermitted } from '../../services/userPermissionsService';
import { getCertificates } from '../../views/CertificatesManagement/CertificatesManagement';
import { BigidHelpIcon } from '@bigid-ui/icons';

const TOOLTIP_TEXT = `You must have right permissions in order to view the certificates, please ask your administrator for help`;

interface ApiCallCertificateFormProps extends ApiCallCertificateConfiguration {
  setValue: (name: string, value: any) => void;
}

const useStyles = makeStyles({
  wrapper: { display: 'flex', flexDirection: 'column', marginTop: 10 },
  selectWrapper: { marginTop: 14 },
  checkboxWrapper: { display: 'flex', alignItems: 'center' },
  helpIcon: { marginLeft: 4, display: 'flex', alignItems: 'center' },
});

export const ApiCallCertificateForm: FC<ApiCallCertificateFormProps> = ({
  useCertificate,
  allowSelfSignCertificate,
  clientCert,
  clientKeyCert,
  setValue,
}) => {
  const { wrapper, selectWrapper, checkboxWrapper, helpIcon } = useStyles({});
  const [certificateOptions, setCertificateOptions] = useState<BigidSelectOption[]>([]);
  const [isLoading, setIsLoading] = useState(true);

  const canViewCertificates = isPermitted(CERTIFICATES_PERMISSIONS.READ.name);

  const getCertificatesOptions = async () => {
    try {
      const { results = [] } = await getCertificates('');
      setCertificateOptions(
        results.filter(({ type }) => type === 'pem').map(({ name }) => ({ label: name, value: name, id: name })),
      );
      setIsLoading(false);
    } catch (error) {
      console.error(error);
      notificationService.error('Error fetching certificates');
    }
  };

  useEffect(() => {
    if (canViewCertificates) {
      (async () => await getCertificatesOptions())();
    }
  }, [canViewCertificates]);

  const certificateChosen = certificateOptions.filter(({ value }) => clientCert === value);
  const clientKeyChosen = certificateOptions.filter(({ value }) => clientKeyCert === value);

  return (
    <div className={wrapper}>
      <div className={checkboxWrapper}>
        <BigidPrimaryCheckbox
          checked={useCertificate}
          label={<BigidHeading6>Use Certificate</BigidHeading6>}
          onChange={(_e, checked: boolean) => setValue('useCertificate', checked)}
          dataAid="BigidCheckbox-APICallUseCertificate"
          disabled={!canViewCertificates}
        />
        {!canViewCertificates && (
          <BigidTooltip title={TOOLTIP_TEXT}>
            <div className={helpIcon}>
              <BigidHelpIcon />
            </div>
          </BigidTooltip>
        )}
      </div>
      <Collapse in={useCertificate}>
        <div className={wrapper}>
          <BigidPrimaryCheckbox
            checked={allowSelfSignCertificate}
            label={<BigidHeading6>Support Self-signed</BigidHeading6>}
            onChange={(_e, checked: boolean) => setValue('allowSelfSignCertificate', checked)}
            dataAid="BigidCheckbox-APICallAllowSelfSignCertificate"
            disabled={!canViewCertificates}
          />
          {isLoading ? (
            <BigidLoader />
          ) : (
            <div className={selectWrapper}>
              <BigidFormFieldSelect
                value={certificateChosen}
                name="clientCert"
                label="Client Certificate"
                options={certificateOptions}
                errorIsShown={false}
                error={null}
                setValue={([{ value }]) => setValue('clientCert', value)}
                isSubmitted={false}
                isValidated={false}
                isRequired={false}
                wasSubmitted={false}
                touched
                selectProps={{ menuPosition: 'fixed', placeholder: 'Select a Certificate' }}
                disabled={!canViewCertificates}
              />
              <BigidFormFieldSelect
                value={clientKeyChosen}
                label="Client Key"
                name="clientKeyCert"
                options={certificateOptions}
                errorIsShown={false}
                error={null}
                setValue={([{ value }]) => setValue('clientKeyCert', value)}
                isSubmitted={false}
                isValidated={false}
                isRequired={false}
                wasSubmitted={false}
                touched
                selectProps={{ menuPosition: 'fixed', placeholder: 'Select a Client Key' }}
                disabled={!canViewCertificates}
              />
            </div>
          )}
        </div>
      </Collapse>
    </div>
  );
};
