import React, { FC } from 'react';
import { BigidTag } from '@bigid-ui/components';
import styled from '@emotion/styled';
import { DataCatalogObjectDetails } from '../../../../DataCatalog/DataCatalogDetails/DataCatalogDetailsService';
import { CustomAccordeon } from './CustomAccordeon';

export interface ExtendedObjectDetailsProps extends DataCatalogObjectDetails {
  last_scanned?: string;
  detailedObjectType?: string;
  objectAttributes?: string[];
}

export interface ObjectDetailsProps {
  caseDetails?: ExtendedObjectDetailsProps;
  objectAttributes?: string[];
  objectType?: string;
  dataAid?: string;
}

export const ObjectDetailsWrapper = styled.div({
  display: 'flex',
  flexDirection: 'column',
  rowGap: 20,
});

export const ChipsContainer = styled.div({
  display: 'flex',
  flexWrap: 'wrap',
  columnGap: 8,
  rowGap: 8,
});

export const ObjectTags: FC<ObjectDetailsProps> = ({ caseDetails }) => {
  return (
    <ObjectDetailsWrapper>
      <CustomAccordeon header="Tags">
        <ChipsContainer>
          {caseDetails?.tags?.map(tag => (
            <BigidTag name={tag.tagName} value={tag.tagValue} key={tag.tagName} size="medium" />
          ))}
        </ChipsContainer>
      </CustomAccordeon>
    </ObjectDetailsWrapper>
  );
};
