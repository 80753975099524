import { GettingStartedStepState } from '../utils/gettingStartedUtils';
import { GettingStartedStepsIds } from '../gettingStartedItems';

export enum ReducerActions {
  'INIT',
  'SET_CURRENT_STEP',
  'UPDATE_ITEMS',
}

export interface GettingStartedReducerState {
  currentStepId?: GettingStartedStepsIds;
  items?: GettingStartedStepState[];
  isLoading: boolean;
  isProgressDisabled?: boolean;
}

export interface GettingStartedReducerAction {
  type: ReducerActions;
  payload: Partial<GettingStartedReducerState>;
}

export const gettingStartedReducer: React.Reducer<GettingStartedReducerState, GettingStartedReducerAction> = (
  state,
  { type, payload },
) => {
  switch (type) {
    case ReducerActions.UPDATE_ITEMS: {
      const { items } = payload;
      return { ...state, items };
    }

    case ReducerActions.SET_CURRENT_STEP: {
      const { currentStepId } = payload;
      return { ...state, currentStepId };
    }

    case ReducerActions.INIT: {
      return { ...payload, isLoading: false };
    }

    default:
      return state;
  }
};
