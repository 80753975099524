import React, { ChangeEvent, FunctionComponent, useMemo, useState } from 'react';
import {
  BigidColors,
  PrimaryButton,
  BigidProgressBarSimple,
  BigidToolbar,
  ToolbarAction,
  BigidLoader,
  BigidPrimaryCheckbox,
  BigidProgressBar,
  BigidProgressBarStatus,
  SecondaryButton,
  BigidPaper,
  BigidDropdown,
  BigidDropdownValue,
  BigidBody1,
} from '@bigid-ui/components';
import { BigidAuditIllustration } from '@bigid-ui/icons';
import makeStyles from '@mui/styles/makeStyles';
import { useFilePreview } from './useFilePreview';
import { PreviewFileContentChunk } from './PreviewFileContentChunk';
import classNames from 'classnames';
import { getApplicationPreference } from '../../../services/appPreferencesService';
import { CatalogEventsEnum } from '../events';
import { uniqBy } from 'lodash';
import { analyticsService } from '../../../services/analyticsService';

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    flexFlow: 'column nowrap',
    width: '100%',
    padding: '12px 5px 5px 5px',
  },
  pageWrapper: {
    width: '100%',
    height: '100%',
    overflowY: 'auto',
    padding: '0 24px 0 24px',
  },
  previewButtonContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '8px 24px 16px 24px',
    fontSize: '0.875rem',
    minWidth: '365px',
  },
  container: {
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: BigidColors.gray[50],
  },
  textContainer: {
    marginTop: '24px',
    maxWidth: '350px',
    textAlign: 'center',
    color: BigidColors.gray[600],
  },
  contentContainer: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    boxShadow: BigidColors.containerShadow,
    backgroundColor: BigidColors.white,
  },
  fileContentWrapper: {
    width: '100%',
    padding: '24px',
  },
  toolbarContainer: {
    borderBottom: `1px solid ${BigidColors.gray[100]}`,
  },
  filters: {
    display: 'flex',
    padding: '12px',
    alignItems: 'center',
    gap: '16px',
  },
  typeFilter: {
    flex: '0 1 400px',
  },
  withAttrFilter: {
    flex: 1,
  },
  chunksContainer: {
    overflowY: 'auto',
    flex: 1,
    borderTop: `1px solid ${BigidColors.gray[100]}`,
  },
  retryWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  controlsContainer: {
    paddingTop: '1px',
  },
  busy: {
    pointerEvents: 'none',
    opacity: 0.5,
  },
  unsupportedPreview: {
    height: '100%',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
}));

export interface PreviewFileContentProps {
  dataAid?: string;
  source: string;
  containerName: string;
  fullyQualifiedName: string;
  scannerType: string;
  attributeFilter?: string;
}

const DEFAULT_POLLING_PERMISSIBLE_TIMEOUT = 30000;

export const PreviewFileContent: FunctionComponent<PreviewFileContentProps> = ({
  dataAid = 'PreviewFileContent',
  fullyQualifiedName,
  source,
  scannerType,
}) => {
  const classes = useStyles({});
  const [attributeNameSelected, setAttributeNameSelected] = useState<BigidDropdownValue>([]);

  const { isPollingLimitedWithTimeout, pollingPermissibleTimeout } = useMemo(() => {
    const pollingPermissibleTimeout = getApplicationPreference('FILE_PREVIEW_TIMEOUT');

    return {
      pollingPermissibleTimeout: isNaN(pollingPermissibleTimeout)
        ? DEFAULT_POLLING_PERMISSIBLE_TIMEOUT
        : pollingPermissibleTimeout,
      isPollingLimitedWithTimeout: Boolean(getApplicationPreference('FILE_PREVIEW_TIMEOUT_ENABLED')),
    };
  }, []);

  const {
    isPreviewSupported,
    isInitiallyFetched,
    isFetchingFromCache,
    isFetchingFromScan,
    isScanFailed,
    isContentCached,
    isSegmentsWithAttributesOnly,
    isFileEmpty,
    isNoAttributesFile,
    isPollingTimeoutReached,
    chunks,
    totalChars,
    nextChunkIndex,
    onClearCacheClick,
    onLoadMoreClick,
    onPreviewClick,
    onSegmentsWithAttributesOnlyToggle,
  } = useFilePreview({
    fullyQualifiedName,
    isPollingLimitedWithTimeout,
    pollingPermissibleTimeout,
    scannerType,
    source,
  });

  const attributeNameFilterOptions = () => {
    const chunksAttributesFullList = chunks.flatMap(({ attributes }) => {
      return attributes.map(attribute => ({
        id: attribute.label,
        displayValue: `${attribute.label}  (${attribute.attributes[0].attributeType})`,
        value: attribute.label,
      }));
    });

    const uniqueAttribute = uniqBy(chunksAttributesFullList, 'id');
    return uniqueAttribute;
  };

  const toolbarActions: ToolbarAction[] = [
    {
      label: 'Clear from cache',
      isGlobal: true,
      execute: async () => {
        onClearCacheClick();
        return {};
      },
      disable: () => {
        return !isContentCached || isFetchingFromCache || isFetchingFromScan;
      },
      show: () => {
        return true;
      },
    },
  ];

  const handleSegmentsWithAttributesOnlyToggle = (_event: ChangeEvent<HTMLInputElement>, isChecked: boolean): void => {
    onSegmentsWithAttributesOnlyToggle(isChecked);
  };

  const isLoading = isFetchingFromCache || isFetchingFromScan;

  const onAttributeNameFilterChange = (option: BigidDropdownValue) => {
    analyticsService.trackManualEvent(CatalogEventsEnum.CATALOG_UNSTRUCTURED_PREVIEW_ATTRIBUTE_FILTER_NAME);
    setAttributeNameSelected(option);
  };

  return (
    typeof isPreviewSupported === 'boolean' && (
      <div className={classes.root}>
        <BigidPaper isOutlined={chunks.length > 0} caption={chunks.length > 0 ? 'File Preview' : ''} hasShadow={false}>
          {isPreviewSupported ? (
            <>
              <div className={classes.controlsContainer}>
                {chunks.length > 0 && isInitiallyFetched && (
                  <div className={classes.toolbarContainer}>
                    <BigidToolbar toolbarActions={toolbarActions} entityName="characters" totalRows={totalChars} />
                  </div>
                )}
                <div className={classes.filters}>
                  <div className={classNames(classes.withAttrFilter, isLoading && classes.busy)}>
                    <BigidPrimaryCheckbox
                      dataAid={`${dataAid}-request-attributes`}
                      label="Display only segments with attributes"
                      checked={isSegmentsWithAttributesOnly}
                      onChange={handleSegmentsWithAttributesOnlyToggle}
                      disabled={isLoading}
                      size="small"
                    />
                  </div>

                  <div className={classes.typeFilter}>
                    <BigidDropdown
                      dataAid={`${dataAid}-attributes-name-filter`}
                      value={attributeNameSelected}
                      options={attributeNameFilterOptions()}
                      onSelect={onAttributeNameFilterChange}
                      isDisabled={isLoading}
                      placeholder="Filter by attribute name"
                      isMulti
                    />
                  </div>
                </div>
              </div>
              {isFetchingFromCache && <BigidLoader />}
              {isInitiallyFetched && (
                <>
                  {!isFetchingFromCache &&
                    !isFileEmpty &&
                    !isNoAttributesFile &&
                    !isContentCached &&
                    chunks.length === 0 && (
                      <div className={classes.container}>
                        {!isFetchingFromScan && !isScanFailed && (
                          <div className={classes.previewButtonContainer}>
                            <PrimaryButton
                              dataAid={`${dataAid}-preview-file`}
                              size="medium"
                              onClick={onPreviewClick}
                              text="Preview file"
                            />
                            <div className={classes.textContainer} data-aid={`${dataAid}-preview-file-description`}>
                              To preview the file, a scan will be run and the first 2,000 characters of the file will be
                              returned.
                            </div>
                          </div>
                        )}
                        {isFetchingFromScan && (
                          <div className={classes.previewButtonContainer} data-aid={`${dataAid}-scanning`}>
                            <div className={classes.textContainer}>Scanning...</div>
                            <BigidProgressBarSimple type="indeterminate" />
                          </div>
                        )}
                        {!isFetchingFromScan && isScanFailed && (
                          <div className={classes.retryWrapper}>
                            <div className={classes.previewButtonContainer} data-aid={`${dataAid}-failure`}>
                              <div className={classes.textContainer}>
                                {isPollingTimeoutReached ? `Timeout has been reached` : 'Scan failed'}
                              </div>
                              <BigidProgressBar
                                percentage={100}
                                status={BigidProgressBarStatus.FAILED}
                                displayPercentage={false}
                              />
                            </div>
                            <div>
                              <SecondaryButton
                                onClick={onPreviewClick}
                                size="small"
                                data-aid={`${dataAid}-failure-retry`}
                                text="Retry"
                              />
                            </div>
                          </div>
                        )}
                      </div>
                    )}
                  {!isFetchingFromCache && !isFetchingFromScan && isFileEmpty && (
                    <div className={classes.container}>
                      <div className={classes.retryWrapper}>
                        <div className={classes.previewButtonContainer} data-aid={`${dataAid}-empty`}>
                          <div className={classes.textContainer}>File is blank</div>
                          <BigidProgressBar
                            percentage={100}
                            status={BigidProgressBarStatus.STOPPED}
                            displayPercentage={false}
                          />
                        </div>
                      </div>
                    </div>
                  )}
                  {!isFetchingFromCache && !isFetchingFromScan && isSegmentsWithAttributesOnly && isNoAttributesFile && (
                    <div className={classes.container}>
                      <div className={classes.retryWrapper}>
                        <div className={classes.previewButtonContainer}>
                          <div className={classes.textContainer} data-aid={`${dataAid}-no-segments`}>
                            No segments with attributes found
                          </div>
                          <BigidProgressBar
                            percentage={100}
                            status={BigidProgressBarStatus.STOPPED}
                            displayPercentage={false}
                          />
                        </div>
                      </div>
                    </div>
                  )}
                  {chunks.length > 0 && (
                    <div className={classes.pageWrapper}>
                      <div>
                        {chunks.map(({ content, attributes }, index) => {
                          const isLastChunk = index === chunks.length - 1;

                          return (
                            <PreviewFileContentChunk
                              key={index}
                              content={content}
                              attributes={attributes}
                              isLoadMoreEnabled={nextChunkIndex != null}
                              displayLoadMore={isLastChunk && nextChunkIndex != null}
                              displayProgressbar={isLastChunk && isFetchingFromScan}
                              displaySeparator={isSegmentsWithAttributesOnly}
                              isLoadMoreFailed={isScanFailed}
                              onLoadMoreClick={onLoadMoreClick}
                              attributeNameSelected={attributeNameSelected}
                            />
                          );
                        })}
                      </div>
                    </div>
                  )}
                </>
              )}
            </>
          ) : (
            <div className={classes.unsupportedPreview}>
              <BigidAuditIllustration />
              <BigidBody1>The data source &lsquo;{source}&rsquo; does not support preview</BigidBody1>
            </div>
          )}
        </BigidPaper>
      </div>
    )
  );
};
