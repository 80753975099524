import { ReactNode } from 'react';
import { $rootScope, $timeout } from 'ngimport';
import { Breadcrumb } from '@bigid-ui/components';
import { headerEventEmitter, HeaderEvents } from '../../react/services/eventEmitters/headerEvents';

type RequireOnlyOne<T, Keys extends keyof T = keyof T> = Pick<T, Exclude<keyof T, Keys>> &
  {
    [K in Keys]-?: Required<Pick<T, K>> & Partial<Record<Exclude<Keys, K>, undefined>>;
  }[Keys];

export interface PageState {
  state: string;
  params: { [key: string]: string };
}
interface PageHeaderServiceFull {
  pageTitle: string;
  titleHelperComponent?: ReactNode;
  showSearchHeader?: boolean;
  showBackButton?: boolean;
  defaultFrom?: PageState;
  breadcrumbs: Breadcrumb[];
  rightSideComponentsContainer?: ReactNode;
}

export type TitleObject = RequireOnlyOne<PageHeaderServiceFull, 'pageTitle' | 'breadcrumbs'>;

type PageHeaderService = {
  setTitle: (obj: TitleObject) => void;
  setIsHidden: (isHidden: boolean) => void;
  isHidden: boolean;
  getIsHidden: () => boolean;
};

export const pageHeaderService: PageHeaderService = {
  isHidden: false,
  setTitle: ({
    pageTitle = '',
    showSearchHeader = false,
    showBackButton = false,
    defaultFrom = null,
    breadcrumbs = [],
    rightSideComponentsContainer,
    titleHelperComponent,
  }) => {
    // FIXME:
    // this change use $timeout because for some reason bigid-header is not ready on page refresh
    // and in such case, event in useless ... ;( so we postpone this update and hope the header will be ready in
    // after this digest cycle

    //We are emitting the page title because is being used to decide rather to show widgets or not
    const pageTitleToEmit = pageTitle || breadcrumbs[breadcrumbs?.length - 1]?.label || '';
    $timeout(
      () =>
        $rootScope.$emit(
          'changePage',
          pageTitleToEmit,
          showSearchHeader,
          showBackButton,
          defaultFrom,
          breadcrumbs,
          rightSideComponentsContainer,
          titleHelperComponent,
        ),
      10,
    );
  },

  setIsHidden: isHidden => {
    pageHeaderService.isHidden = isHidden;
    headerEventEmitter.emit(HeaderEvents.HEADER_HIDE, { data: { isHidden } });
  },
  getIsHidden: () => {
    return pageHeaderService.isHidden;
  },
};
