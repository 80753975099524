import React, { FC } from 'react';
import { BigidAddIcon, BigidPortfolioIllustration } from '@bigid-ui/icons';
import { BigidBody1, SecondaryButton } from '@bigid-ui/components';
import { FormErrorText, EmptyRolesStateContent, EmptyRolesStateWrapper } from './styles';
import { useLocalTranslation } from '../translations';

interface EmptyRolesStateProps {
  actionMethod: () => void;
  isVisible: boolean;
  isCompact?: boolean;
  isWithError?: boolean;
}

export const EmptyRolesState: FC<EmptyRolesStateProps> = ({ actionMethod, isVisible, isWithError, isCompact }) => {
  const { t } = useLocalTranslation('dialogs.createUser');

  if (!isVisible) {
    return null;
  }

  return (
    <EmptyRolesStateWrapper>
      <BigidPortfolioIllustration size={isCompact ? 'small' : 'medium'} />
      <EmptyRolesStateContent>
        <BigidBody1>{t('emptyState.description')}</BigidBody1>
        {!isCompact && (
          <>
            <SecondaryButton
              onClick={actionMethod}
              size="medium"
              startIcon={<BigidAddIcon />}
              text={t('emptyState.addRole')}
            />
          </>
        )}
        {isWithError && <FormErrorText>{t('errors.noRolesAssigned')}</FormErrorText>}
      </EmptyRolesStateContent>
    </EmptyRolesStateWrapper>
  );
};
