import { Dispatch, Reducer } from 'react';
import { bindActionCreators } from '../utils/reducer';
import { ActionPayload, ActionType, Nullable, ReducerState } from '../types';
import type { DataSourceConnectionModalOverlaySteps } from '../DataSourceConnectionModalOverlay';
import type { DataSourceConnectionDialogLayout } from '../../DataSourceConnectionDialog/DataSourceConnectionDialog';

type ArgumentOf<TFunc> = TFunc extends (arg: infer TArg) => any ? TArg : never;
type ActionCreatorReturnType<TAction extends Record<string, any>> = {
  [Key in keyof TAction]: (state: ArgumentOf<TAction[Key]>) => void;
};

export type ReducerConfig = {
  actions: ReturnType<typeof getReducerActions>;
  state: ReducerState;
};

// @info modal action creators
const setStep = (step: Nullable<DataSourceConnectionModalOverlaySteps>): ActionPayload => [ActionType.SET_STEP, step];

const setLayout = (layout: Nullable<DataSourceConnectionDialogLayout>): ActionPayload => [ActionType.SET_LAYOUT, layout];

const setShowTestResults = (showTestResults: boolean): ActionPayload => [
  ActionType.SET_SHOW_TEST_RESULTS,
  showTestResults,
];

const setShowCollaborationSuccess = (showCollaborationSuccess: boolean): ActionPayload => [
  ActionType.SET_SHOW_COLLABORATION_SUCCESS,
  showCollaborationSuccess,
];

const setShowCollaboration = (showCollaboration: boolean): ActionPayload => [
  ActionType.SET_SHOW_COLLABORATION,
  showCollaboration,
];

const setShowSaveBeforeClose = (showSaveBeforeClose: boolean): ActionPayload => [
  ActionType.SET_SHOW_SAVE_BEFORE_CLOSE,
  showSaveBeforeClose,
];

const setIsFirstAttempt = (isFirstAttempt: boolean): ActionPayload => [ActionType.SET_IS_FIRST_ATTEMPT, isFirstAttempt];

export const DEFAULT_ACTIONS_CREATORS = {
  setStep,
  setLayout,
  setShowTestResults,
  setShowCollaborationSuccess,
  setShowCollaboration,
  setShowSaveBeforeClose,
  setIsFirstAttempt,
} as const;

export const DEFAULT_REDUCER: Reducer<ReducerState, ActionPayload> = (state, [type, payload]) => {
  switch (type) {
    case ActionType.SET_STEP:
      return { ...state, step: payload };
    case ActionType.SET_LAYOUT:
      return { ...state, layout: payload };
    case ActionType.SET_IS_FIRST_ATTEMPT:
      return { ...state, isFirstAttempt: payload };
    case ActionType.SET_SHOW_SAVE_BEFORE_CLOSE:
      return { ...state, showSaveBeforeClose: payload };
    case ActionType.SET_SHOW_COLLABORATION:
      return { ...state, showCollaboration: payload };
    case ActionType.SET_SHOW_COLLABORATION_SUCCESS:
      return { ...state, showCollaborationSuccess: payload };
    case ActionType.SET_SHOW_TEST_RESULTS:
      return { ...state, showTestResults: payload };
    default:
      return state;
  }
};

export const getReducerActions = (dispatch: Dispatch<ActionPayload>) =>
  bindActionCreators<ActionType, ActionPayload>(
    dispatch,
    DEFAULT_ACTIONS_CREATORS,
  ) as unknown as ActionCreatorReturnType<typeof DEFAULT_ACTIONS_CREATORS>;
