import html2canvas from 'html2canvas';
import { lazyLoadService } from './angularServices';
import { getBigIdIcon, getEmptyImage } from './pdfSharedDataReportService';
import { notificationService } from './notificationService';

const generateFileName = (name: string) => name.replace(/ /g, '_').toLowerCase();

export const generatePDFFromNodeElement = (
  nodeToCapture: Element,
  header: string,
  subHeader: string,
  fileName: string,
): Promise<string> => {
  return new Promise(async (resolve, reject) => {
    try {
      const canvas = await html2canvas(<HTMLElement>nodeToCapture);
      const base64Image = canvas.toDataURL('image/png');
      const docDefinition = {
        defaultStyle: lazyLoadService.setReportFont(),
        content: [
          {
            table: {
              widths: ['auto', '*'],
              body: [
                [
                  {
                    width: '*',
                    alignment: 'left',
                    stack: [
                      {
                        style: 'header',
                        text: header,
                      },
                      {
                        style: 'subHeader',
                        text: subHeader,
                      },
                    ],
                  },
                  {
                    image: getBigIdIcon(),
                    width: 46,
                    alignment: 'right',
                    margin: [0, 0, 0, 0],
                  },
                ],
              ],
            },
            layout: {
              hLineWidth: function () {
                return 0;
              },
              vLineWidth: function () {
                return 0;
              },
              paddingBottom: function () {
                return 5;
              },
            },
          },
          {
            image: getBase64Image(base64Image),
            width: 500,
            margin: [0, 5, 0, 0],
          },
        ],

        styles: {
          header: {
            bold: true,
            color: '#262D37',
            fontSize: 16,
          },
          subHeader: {
            bold: false,
            color: '#555555',
            fontSize: 12,
            margin: [0, 5, 0, 0],
          },
        },
      };
      const pdfMake = await lazyLoadService.loadPdfMake();
      const fixedFileName = generateFileName(fileName);
      pdfMake.createPdf(docDefinition).download(fixedFileName, () => {
        resolve(fixedFileName);
      });
    } catch (err) {
      notificationService.error('Download failed, please try again later');
    }
  });
};
const getBase64Image = (base64Image: string) => {
  return base64Image === `data:,` ? getEmptyImage() : base64Image;
};
