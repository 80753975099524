import {
  BigidBody1,
  BigidColors,
  BigidExtraField,
  BigidFieldRenderProps,
  BigidSelect,
  BigidSelectOption,
  compareObjectsExceptFunctions,
  generateFieldId,
  useExtraFieldControl,
} from '@bigid-ui/components';
import { FormControl } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React, { FC, memo, useCallback, useState, useEffect } from 'react';
import { v4 as uuid } from 'uuid';
import { CountryDefinition, getCopyOfCountries } from '../../../../../config/countries';
import { getApplicationPreference } from '../../../../services/appPreferencesService';

const useStyles = makeStyles({
  extraFieldWrapper: {
    padding: 0,
    width: '100%',
  },
  labelWrapper: ({ labelWidth }: Partial<BigidFieldRenderProps>) => ({
    minWidth: labelWidth,
    alignItems: 'center',
    display: 'inline-flex',
    paddingLeft: 8,
    margin: 0,
    color: BigidColors.gray[700],
  }),
  labelText: {
    color: BigidColors.gray[700],
  },
  root: {
    marginBottom: 20,
  },
});

export interface ListItem {
  id: string;
  value: string;
}

// TODO: move to bigid-ui in the next phase of Report Template development and after BigidForm changes merged

export const BigidFormExtraCountriesField: FC<BigidFieldRenderProps> = memo(
  ({ value, setValue, onBlur, disabled, misc, label, name, labelWidth, tooltipText, isRequired }) => {
    const [countries, setCountries] = useState<BigidSelectOption[]>([]);

    const createNew = useCallback(
      () => ({
        id: uuid(),
        value: '',
      }),
      [],
    );

    useEffect(() => {
      const getCountries = async () => {
        const countryDefinitions: CountryDefinition[] = await getCopyOfCountries(
          getApplicationPreference('DISPLAY_CHINA_REGULATIONS'),
        );

        if (countryDefinitions) {
          const defToSelectOptions = countryDefinitions.map(
            def => ({ label: def?.displayName, value: def?.name } as BigidSelectOption),
          );
          setCountries([...defToSelectOptions]);
        }
      };

      getCountries();
    }, []);

    const { onAddNew, onDelete, onChangeItem, data } = useExtraFieldControl({
      initialData:
        misc?.initialValues?.countries?.length > 0
          ? misc.initialValues.countries.map((val: string) => ({
              id: uuid(),
              value: [
                {
                  label: val,

                  value: val,
                } as BigidSelectOption,
              ],
            }))
          : [createNew()],
      createNewItem: createNew,
      onChange: newValue => {
        setValue([...newValue]);
      },
    });

    const handleOnChangeItem = (id: string, value: BigidSelectOption[]) => {
      onChangeItem(id, value);
    };

    const bigidSelectWrapper: React.ComponentType<any> = ({ onChange, value }) => {
      return <BigidSelect value={value} options={countries} placeholder="select" onChange={onChange} />;
    };

    const classes = useStyles({ labelWidth });
    const htmlId = generateFieldId(name);

    return (
      <div className={classes.root}>
        {label !== false && (
          <label className={classes.labelWrapper} htmlFor={htmlId}>
            <BigidBody1 className={classes.labelText}>{label}</BigidBody1>
          </label>
        )}

        <FormControl fullWidth={misc?.fullWidth}>
          <div id={htmlId} data-aid={`BigidFormExtraCountriesField-name-${name}`} className={classes.extraFieldWrapper}>
            <BigidExtraField
              disabled={disabled ?? misc.disabled}
              data={data}
              onAddNew={onAddNew}
              onBlurItem={onBlur}
              fieldComponent={bigidSelectWrapper}
              onDelete={onDelete}
              onChangeItem={handleOnChangeItem}
              alignCenter={true}
              isDataInAll={true}
              fieldsBottomOffset={misc.fieldsBottomOffset ?? '12px'}
              addLabel={misc.addLabel}
            />
          </div>
        </FormControl>
      </div>
    );
  },
  compareObjectsExceptFunctions,
);

BigidFormExtraCountriesField.displayName = 'BigidFormExtraCountriesField';
