import { ReactText } from 'react';
import { httpService } from '../../../services/httpService';
import { SystemAttribute, SystemAttributeType } from '../DataCatalogService';
import {
  TagResponseEntity,
  attachTags,
  detachTags,
  createTag,
  TagEntity,
  TagAssignmentTarget,
  TagCompositionPartType,
  UseTagResponseData,
} from '../../TagsManagement/TagsManagementService';
import { getTagEntityByName } from '../../TagsManagement/TagsManagementUtils';
import { BigidTagBaseProps } from '@bigid-ui/components';

export enum ManualFieldType {
  ATTRIBUTE = 'attribute',
}

const refreshHeaders = {
  'catalog-refresh-option': 'refresh',
};

export interface AttributeRanked extends SystemAttribute {
  rank: string;
  calc_confidence_level?: number;
}

export interface ManualField {
  hashId?: ReactText;
  attribute_id?: ReactText;
  fullyQualifiedName: string;
  fieldName?: string;
  value: string;
  attribute_type: SystemAttributeType;
  type: ManualFieldType;
  isNewAttribute?: boolean;
  confLevel?: string;
}

export interface DataCatalogObjectColumn {
  column_name: string;
  attribute_list: AttributeRanked[];
  fieldType?: string;
  isPrimary?: boolean;
  isProfiled?: boolean;
  businessAttribute?: ColumnBusinessAttribute;
  linkedColumns?: number;
  tags?: TagEntity[];
  clusterId?: string;
  order?: number;
  nullable?: boolean;
}

export interface UseColumnTagPayload {
  systemTags: TagEntity[];
  tags: BigidTagBaseProps[];
  fullyQualifiedName: string;
  source: string;
  columnName: string;
}

export enum ColumnBusinessAttributePopulatedBy {
  USER = 'USER',
  AUTO = 'AUTO',
}

export interface ColumnBusinessAttribute {
  friendlyName?: string;
  totalSuggestionsCount?: number;
  populatedBy?: ColumnBusinessAttributePopulatedBy;
}

export type DataCatalogObjectColumnsResponse = DataCatalogObjectColumn[];

export interface DataCatalogObjectColumnsCountResponse {
  count: number;
}

export interface DataCatalogObjectColumnUseTagResponseData {
  tags: TagEntity[];
  response?: UseTagResponseData[];
}

export const getColumnsByObjectName = (query?: string) => {
  return httpService
    .fetch<{ data: DataCatalogObjectColumnsResponse }>(`data-catalog/object-details/columns${query ? `?${query}` : ''}`)
    .then(({ data }) => data);
};

export const getColumnsCount = (objectName: string) => {
  const objectNameParsed = encodeURIComponent(objectName);
  return httpService
    .fetch<{ data: DataCatalogObjectColumnsCountResponse }>(
      `data-catalog/object-details/columns/count/?object_name=${objectNameParsed}`,
    )
    .then(({ data }) => data);
};

export const updateManualFields = (data: ManualField[]) => {
  return httpService.post(`data-catalog/manual-fields`, { data }, undefined, refreshHeaders).then(({ data }) => data);
};

export const deleteManualFields = (data: ManualField[]) => {
  return httpService.delete(`data-catalog/manual-fields`, { data }, refreshHeaders).then(({ data }) => data);
};

export const createAndAttachMultipleTags = async ({
  columnName,
  fullyQualifiedName,
  source,
  systemTags,
  tags: createdColumnTags,
}: UseColumnTagPayload): Promise<DataCatalogObjectColumnUseTagResponseData> => {
  const recentlyCreatedTagEntities: TagEntity[] = [];
  const recentlyCreatedTagResponses: UseTagResponseData[] = [];

  for (const createdColumnTag of createdColumnTags) {
    const { isNew, name, value } = createdColumnTag;
    let nameCreated: TagResponseEntity;
    let valueCreated: TagResponseEntity;
    let tagNameIdToAttach: TagResponseEntity['_id'];
    let tagValueIdToAttach: TagResponseEntity['_id'];
    const tagNameDescription = `${name} tag name`;
    const tagValueDescription = `${value} tag value`;

    if (isNew) {
      const { data: tagCreatedResponse } = await createTag({
        name,
        type: TagCompositionPartType.tag,
        description: tagNameDescription,
      });
      nameCreated = tagCreatedResponse[0];
      tagNameIdToAttach = nameCreated._id;
      const { data: valueCreatedResponse } = await createTag({
        name: value,
        type: TagCompositionPartType.value,
        description: tagValueDescription,
        parentId: tagNameIdToAttach,
      });
      valueCreated = valueCreatedResponse[0];
      tagValueIdToAttach = valueCreated._id;
    } else {
      tagNameIdToAttach = getTagEntityByName(
        [...systemTags, ...recentlyCreatedTagEntities],
        createdColumnTag.name,
      )?.tagId;
      const { data: valueCreatedResponse } = await createTag({
        name: value,
        type: TagCompositionPartType.value,
        description: tagValueDescription,
        parentId: tagNameIdToAttach,
      });
      valueCreated = valueCreatedResponse[0];
      tagValueIdToAttach = valueCreated._id;
    }

    if (tagNameIdToAttach && tagValueIdToAttach) {
      const { data } = await attachTags([
        {
          type: TagAssignmentTarget.column,
          fullyQualifiedName,
          context: columnName,
          source,
          tags: [
            {
              tagId: tagNameIdToAttach,
              valueId: tagValueIdToAttach,
            },
          ],
        },
      ]);

      recentlyCreatedTagEntities.push({
        tagId: tagNameIdToAttach,
        tagName: name,
        valueId: tagValueIdToAttach,
        tagValue: value,
      });
      recentlyCreatedTagResponses.push(data);
    } else {
      throw { message: 'Tag name or value does not exist' };
    }
  }

  return { tags: recentlyCreatedTagEntities, response: recentlyCreatedTagResponses };
};

export const attachMultipleTags = async ({
  columnName,
  fullyQualifiedName,
  source,
  systemTags,
  tags: attachedColumnTags,
}: UseColumnTagPayload): Promise<DataCatalogObjectColumnUseTagResponseData> => {
  const recentlyAttachedTagEntities: TagEntity[] = [];
  const recentlyAttachedTagResponses: UseTagResponseData[] = [];

  for (const attachedColumnTag of attachedColumnTags) {
    const { name, value } = attachedColumnTag;
    const tagToAttach = getTagEntityByName(systemTags, name, value);

    if (tagToAttach?.tagId && tagToAttach?.valueId) {
      const { tagId, valueId } = tagToAttach;
      const { data } = await attachTags([
        {
          type: TagAssignmentTarget.column,
          fullyQualifiedName,
          source,
          context: columnName,
          tags: [
            {
              tagId,
              valueId,
            },
          ],
        },
      ]);

      recentlyAttachedTagEntities.push({
        tagId,
        tagName: name,
        valueId,
        tagValue: value,
      });
      recentlyAttachedTagResponses.push(data);
    } else {
      throw { message: 'Tag name or value does not exist' };
    }
  }

  return { tags: recentlyAttachedTagEntities, response: recentlyAttachedTagResponses };
};

export const detachMultipleTags = async ({
  columnName,
  fullyQualifiedName,
  source,
  systemTags,
  tags: detachedColumnTags,
}: UseColumnTagPayload): Promise<DataCatalogObjectColumnUseTagResponseData> => {
  const recentlyDetachedTagEntities: TagEntity[] = [];

  for (const detachedColumnTag of detachedColumnTags) {
    const { name, value } = detachedColumnTag;
    const tagToDetach = getTagEntityByName(systemTags, name, value);

    if (tagToDetach?.tagId && tagToDetach?.valueId) {
      const { tagId, valueId } = tagToDetach;
      await detachTags([
        {
          type: TagAssignmentTarget.column,
          fullyQualifiedName,
          context: columnName,
          source,
          tags: [
            {
              tagId,
              valueId,
            },
          ],
        },
      ]);

      recentlyDetachedTagEntities.push({
        tagId,
        tagName: name,
        valueId,
        tagValue: value,
      });
    } else {
      throw { message: 'Tag name or value does not exist' };
    }
  }

  return { tags: recentlyDetachedTagEntities };
};
