import { useEffect, useCallback } from 'react';
import { DataSourceConnectionTemplateOverrides, generateFields } from '../generateFields';
import { notificationService } from '../../../../services/notificationService';
import { DataSourceConnectionTemplate, DataSourceConnectionTemplateField } from '../types';
import { getSupportedDataSources, DataSourceTemplateStatus } from '../../../../utilities/dataSourcesUtils';
import { fetchDsTemplate } from '../../DataSourcesService';

type FieldConfigOverride = (fields: DataSourceConnectionTemplateField[]) => DataSourceConnectionTemplateField[];

type UseSetTemplateConfigOverrides = { fieldConfig?: FieldConfigOverride } & DataSourceConnectionTemplateOverrides;
export interface UseSetTemplateConfigProps {
  selectedType: string;
  generateInitValues: any;
  updateState: any;
  id?: string;
  isBulkUpdate?: boolean;
  isEditable?: boolean;
  includeNestedFields?: boolean;
  disableCache?: boolean;
  overrides?: UseSetTemplateConfigOverrides;
}

export interface DataSourceConnectionTemplateConfigData
  extends Pick<DataSourceConnectionTemplate, 'fields' | 'scanType'> {
  status?: DataSourceTemplateStatus;
}

const naiveCache: Record<string, DataSourceConnectionTemplateConfigData & { documentationUrl: string; image: string }> =
  {};

export const useSetTemplateConfig = ({
  updateState,
  generateInitValues,
  selectedType,
  id,
  isBulkUpdate = false,
  includeNestedFields = false,
  disableCache = false,
  overrides,
}: UseSetTemplateConfigProps) => {
  const updateTemplateDataInState = useCallback(async () => {
    try {
      const data = disableCache ? null : naiveCache[selectedType];
      const {
        data: { fields: fieldConfig, scanType, documentationUrl, image },
      } = data ? { data } : await fetchDsTemplate(selectedType);
      const fieldConfigWithOverrides = overrides?.fieldConfig ? overrides.fieldConfig(fieldConfig) : fieldConfig;
      const { status } =
        (await getSupportedDataSources())?.find(({ templateName }) => selectedType === templateName) ?? {};

      if (!data) {
        naiveCache[selectedType] = { fields: fieldConfigWithOverrides, scanType, status, documentationUrl, image };
      }
      if (!id) {
        generateInitValues(fieldConfigWithOverrides);
      }
      const fields = await generateFields(fieldConfigWithOverrides, isBulkUpdate, includeNestedFields, {
        mapping: overrides?.mapping,
      });

      updateState({ fields, selectedType, scanType, status, documentationUrl, image });
    } catch (error) {
      notificationService.error('Get template error');
      console.error(error);
    } finally {
      updateState({ isLoading: false });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [generateInitValues, selectedType, updateState, id]);

  useEffect(() => {
    if (selectedType) {
      updateTemplateDataInState();
    }
  }, [selectedType, updateTemplateDataInState]);
};
