import { BigidLoader, BigidTextField, PrimaryButton, SecondaryButton } from '@bigid-ui/components';
import { FormLabel, Typography } from '@mui/material';
import React, { FC } from 'react';
import { openSystemDialog, SystemDialogContentProps } from '../../../../services/systemDialogService';

export const DELETION_ALERT =
  "Notice: When you click confirm, you're allowing BigID to delete the file/row automatically. Make sure you review it first as the deletion is irreversible";

export interface ProfileManagementDialogProps {
  initialValue: string;
  onNameChange: (name: string) => void;
}

export interface DialogResponse {
  shouldExecute: boolean;
  reason?: string;
}

export const NewFlowDialogContent: FC<SystemDialogContentProps<ProfileManagementDialogProps>> = ({
  initialValue,
  onNameChange,
}) => {
  const allNames = true;
  const handleNameChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const newName = event?.target?.value.trim().toLowerCase();
    const validName = newName.substr(0, 500);
    onNameChange(validName);
  };

  return !allNames ? (
    <BigidLoader />
  ) : (
    <div>
      <FormLabel>{'Reason'}</FormLabel>
      <BigidTextField autoFocus defaultValue={initialValue} onChange={handleNameChange} />
      <Typography variant={'body1'}>{DELETION_ALERT}</Typography>
    </div>
  );
};

export const openEditDialog: () => Promise<DialogResponse> = () => {
  let response: DialogResponse = { shouldExecute: false };
  return new Promise(res => {
    let reason: string;
    openSystemDialog({
      title: 'Edit Reason',
      maxWidth: 'xs',
      content: NewFlowDialogContent,
      contentProps: {
        initialValue: '',
        onNameChange: reasonFromUser => (reason = reasonFromUser),
      },
      onClose: () => null,
      buttons: [
        {
          text: 'Cancel',
          component: SecondaryButton,
          onClick: () => {
            res(response);
          },
          isClose: true,
        },
        {
          text: 'Confirm',
          component: PrimaryButton,
          onClick: async () => {
            response = { reason, shouldExecute: true };
            res(response);
          },
          isClose: true,
        },
      ],
    });
  });
};
