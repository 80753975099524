import React, { FC, useEffect, useState } from 'react';
import {
  BigidDialog,
  BigidDropZone,
  PrimaryButton,
  SecondaryButton,
  ToolbarActionExecuteResult,
} from '@bigid-ui/components';
import { FormControl, InputLabel, MenuItem, Select, TextField, Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import {
  CertificatePostModel,
  CertificatePutModel,
  CertificateType,
  CertificateUsage,
  certUsageNames,
} from './CertificatesManagement';
import { isMultiTenantModeEnabled } from '../../utilities/multiTenantUtils';

const CERTIFICATE_NAME = 'certificateName';
const CERTIFICATE_PWD = 'certificatePassword';

const useStyles = makeStyles((theme: Theme) => ({
  wrapper: {
    display: 'flex',
    justifyContent: 'center',
    padding: theme.spacing(0, 3),
    '& input': {
      height: '1em',
    },
    '& .MuiSelect-select': {
      height: '1em',
      minHeight: '1em',
    },
    '& legend': {
      display: 'none',
    },
    '& label': {
      top: -4,
      left: -13,
      fontSize: '1rem',
    },
  },
}));

export interface CertificateDialogProps {
  isOpen: boolean;
  isEditMode: boolean;
  isLoading?: boolean;
  isDisabled?: boolean;
  readonly origData?: CertificatePutModel;
  onSave: (outputState: {
    readonly origData: CertificatePutModel;
    formData: CertificatePostModel | CertificatePutModel;
    isChanged?: boolean;
    isEditMode: boolean;
    resolveOnExecute?: (value: ToolbarActionExecuteResult) => void;
  }) => void;
  onClose: () => void;
  resolveOnExecute?: (value: ToolbarActionExecuteResult) => void;
}

export const CertificateDialog: FC<CertificateDialogProps> = ({
  isOpen,
  isEditMode,
  isLoading,
  isDisabled,
  origData,
  onSave,
  onClose,
  resolveOnExecute,
}) => {
  const classes = useStyles({});
  const [formData, setFormData] = useState<CertificatePostModel>(getCleanFormState());

  const titleText = isEditMode ? 'Edit Certificate' : 'Create Certificate';
  const buttonText = isEditMode ? 'Update' : 'Create';
  useEffect(() => {
    if (isOpen && isEditMode) {
      setFormData({ ...origData });
    } else {
      cleanForm();
    }
  }, [isOpen, origData, isEditMode]);

  const getCertificateTypes = () => {
    let selectedTypes: CertificateType[] = [];
    switch (formData.usage) {
      case CertificateUsage.CLIENT_CERT:
        selectedTypes = [CertificateType.P12, CertificateType.P8, CertificateType.JKS];
        break;
      case CertificateUsage.JAVA_KEYSTORE:
        selectedTypes = [CertificateType.P12, CertificateType.JKS];
        if (!isMultiTenantModeEnabled()) {
          selectedTypes.push(CertificateType.KERBEROS);
        }
        break;
      case CertificateUsage.ROOT_CA:
        selectedTypes = [CertificateType.PEM];
        break;
      case CertificateUsage.CUSTOM:
        selectedTypes = [CertificateType.P12, CertificateType.JKS, CertificateType.PEM, CertificateType.SSO];
        break;
      case CertificateUsage.SECURE_BUNDLE:
        selectedTypes = [CertificateType.ZIP];
        break;
    }
    return selectedTypes;
  };

  const getUsageNameByType = (certUsage: CertificateUsage) => {
    switch (certUsage) {
      case CertificateUsage.CUSTOM:
        return certUsageNames.CUSTOM;
      case CertificateUsage.JAVA_KEYSTORE:
        return certUsageNames.JAVA_KEYSTORE;
      case CertificateUsage.CLIENT_CERT:
        return certUsageNames.CLIENT_CERT;
      case CertificateUsage.SECURE_BUNDLE:
        return certUsageNames.SECURE_BUNDLE;
      default:
        return certUsageNames.ROOT_CA;
    }
  };

  const handleTextFieldChanges = (event: any) => {
    const fieldName = event.target.name;
    switch (fieldName) {
      case CERTIFICATE_PWD:
        setFormData({ ...formData, password: event.target.value });
        break;
      case CERTIFICATE_NAME:
        setFormData({ ...formData, name: event.target.value });
        break;
      default:
        setFormData({ ...formData, [fieldName]: event.target.value });
    }
  };

  const handleOnDrop = (files: File[], fileType: string) => {
    const cert = files.length ? files[0] : undefined;
    setFormData({ ...formData, [fileType]: cert });
  };

  const handleOnSave = () => {
    if (isEditMode) {
      const diff = Object.entries(formData).filter(([key, val]) => origData[key as keyof CertificatePostModel] !== val);
      const diffData = diff.reduce((acc, [key, val]) => ({ ...acc, [key]: val }), {
        id: origData.id,
      } as CertificatePutModel);
      onSave({ isEditMode, origData, formData: diffData, isChanged: !!diff.length, resolveOnExecute });
    } else {
      onSave({ isEditMode, origData: null, formData, resolveOnExecute });
    }
  };

  const cleanForm = () => {
    setFormData(getCleanFormState());
  };

  // TODO: improve this temporary validator, replace with Formik & Yup once possible
  const isValidForm = () => {
    return (
      isValidName(formData.name) &&
      Object.entries(formData)
        .filter(([key]) =>
          key !== 'password' ? !(formData.type !== CertificateType.KERBEROS && key === 'configFile') : false,
        )
        .every(([, value]) => Boolean(value))
    );
  };

  const isValidName = (name: string): boolean => {
    const regex = /^\w[\w\s\-():]*$/;
    return Boolean(name.trim().length && regex.test(name));
  };

  const isKerberos = formData.type === CertificateType.KERBEROS;
  const isSecureBundle = formData.usage === CertificateUsage.SECURE_BUNDLE;

  return (
    <BigidDialog
      title={titleText}
      isOpen={isOpen}
      onClose={onClose}
      buttons={[
        { component: SecondaryButton, onClick: onClose, text: 'Cancel', dataAid: 'cancel-certificate-button' },
        {
          component: PrimaryButton,
          onClick: handleOnSave,
          text: buttonText,
          disabled: !isValidForm(),
          dataAid: `${buttonText}-certificate-button`,
        },
      ]}
      isLoading={isLoading}
    >
      <div className={classes.wrapper}>
        <form noValidate autoComplete="off">
          <fieldset disabled={isDisabled}>
            <FormControl fullWidth margin="normal">
              <TextField
                required
                error={!!formData.name && !isValidName(formData.name)}
                placeholder="Name"
                value={formData.name}
                onChange={handleTextFieldChanges}
                autoComplete="new-password"
                InputProps={{
                  name: CERTIFICATE_NAME,
                  readOnly: isEditMode,
                }}
                InputLabelProps={{ shrink: false }}
                sx={{
                  '& .MuiOutlinedInput-notchedOutline': {
                    top: 5,
                  },
                }}
              />
            </FormControl>
            {!isKerberos && !isSecureBundle && (
              <FormControl fullWidth margin="normal">
                <TextField
                  placeholder="Password"
                  type="password"
                  value={formData.password}
                  onChange={handleTextFieldChanges}
                  autoComplete="new-password"
                  InputProps={{
                    name: CERTIFICATE_PWD,
                  }}
                  InputLabelProps={{ shrink: false }}
                  sx={{
                    '& .MuiOutlinedInput-notchedOutline': {
                      top: 5,
                    },
                  }}
                />
              </FormControl>
            )}
            <FormControl fullWidth margin="normal">
              <InputLabel htmlFor="certUsage">Usage</InputLabel>
              <Select
                autoWidth
                value={formData.usage}
                onChange={handleTextFieldChanges}
                data-aid="cert-dialog-select-usage"
                inputProps={{
                  name: 'usage',
                }}
                sx={{
                  '& .MuiOutlinedInput-notchedOutline': {
                    top: 7,
                  },
                }}
              >
                {[
                  CertificateUsage.JAVA_KEYSTORE,
                  CertificateUsage.ROOT_CA,
                  CertificateUsage.CLIENT_CERT,
                  CertificateUsage.SECURE_BUNDLE,
                  CertificateUsage.CUSTOM,
                ].map((certUsage, index) => (
                  <MenuItem key={index} value={certUsage} data-aid={`cert-dialog-select-usage-item-${certUsage}`}>
                    {getUsageNameByType(certUsage)}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl fullWidth margin="normal">
              <InputLabel htmlFor="certType">Type</InputLabel>
              <Select
                data-aid="cert-dialog-select-type"
                autoWidth
                value={formData.type}
                onChange={handleTextFieldChanges}
                inputProps={{
                  name: 'type',
                }}
                sx={{
                  '& .MuiOutlinedInput-notchedOutline': {
                    top: 7,
                  },
                }}
              >
                {getCertificateTypes().map((certType, index) => (
                  <MenuItem key={index} value={certType} data-aid={`cert-dialog-select-type-item-${certType}`}>
                    {certType}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl fullWidth margin="normal">
              <BigidDropZone
                label={`Please provide the ${isKerberos ? 'keytab' : 'certificate'} file`}
                accept={['.p12', '.p8', '.pfx', '.jks', '.pem', '.keytab', '.sso', '.zip']}
                files={formData.cert ? [formData.cert] : []}
                onDrop={files => handleOnDrop(files, 'cert')}
              />
            </FormControl>
            {isKerberos && (
              <FormControl fullWidth margin="normal">
                <BigidDropZone
                  label={'Please provide the kerberos configuration file'}
                  accept={['.conf']}
                  files={formData.configFile ? [formData.configFile] : []}
                  onDrop={files => handleOnDrop(files, 'configFile')}
                />
              </FormControl>
            )}
          </fieldset>
        </form>
      </div>
    </BigidDialog>
  );
};

function getCleanFormState(): CertificatePostModel {
  return {
    name: '',
    type: CertificateType.P12,
    usage: CertificateUsage.JAVA_KEYSTORE,
    password: '',
    cert: undefined as File,
    configFile: undefined as File,
  };
}
