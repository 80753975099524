import React, { FC } from 'react';
import { Card, CardHeader, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { LineageNode } from './lineage-node';
import { BigidColors } from '@bigid-ui/components';
import { BigidGraphNodeViewProps } from '@bigid-ui/visualisation';
import { BigidArchiveIcon, BigidFileIcon, BigidTableColumnsIcon } from '@bigid-ui/icons';

const useStyles = makeStyles({
  lineageNode: {
    fill: BigidColors.white,
    opacity: 1,
    boxShadow: '0px 2px 6px rgba(0, 0, 0, 0.16)',
    borderRadius: 4,
  },
  lineageNodeHeaderRoot: {
    overflow: 'hidden',
    padding: 10,
  },
  lineageNodeHeaderContent: {
    overflow: 'hidden',
  },
  lineageNodeHeaderAvatar: {
    margin: 0,
  },
  lineageNodeAvatar: {
    background: 'white',
    width: 18,
    height: 20,
  },
  lineageNodeTitle: {
    fontSize: 15,
    fontWeight: 500,
    fill: BigidColors.gray[700],
    verticalAlign: 'middle',
    padding: 5,
  },
});

export const LineageNodeView: FC<BigidGraphNodeViewProps> = props => {
  const classes = useStyles();
  const { node } = props;
  const lineageNode = node as LineageNode;

  let fqnDelimiter = '.';
  let icon = <BigidArchiveIcon />;
  switch (lineageNode.type) {
    case 'file':
      fqnDelimiter = '/';
      icon = <BigidFileIcon />;
      break;
    case 'table':
    case 'rdb':
      icon = <BigidTableColumnsIcon />;
      break;
  }
  const fullName = lineageNode.fqn.substring(lineageNode.fqn.lastIndexOf(fqnDelimiter) + 1);

  return (
    <Card className={classes.lineageNode}>
      <CardHeader
        classes={{
          root: classes.lineageNodeHeaderRoot,
          content: classes.lineageNodeHeaderContent,
          avatar: classes.lineageNodeHeaderAvatar,
        }}
        avatar={icon}
        title={
          <Typography noWrap className={classes.lineageNodeTitle} align={'left'}>
            {fullName}
          </Typography>
        }
        subheader={
          <Typography noWrap align={'left'}>
            {lineageNode.dataSource}
          </Typography>
        }
      />
    </Card>
  );
};
