import { RequestStatus } from '../services/dataCatalogSimilarTablesService';
import { dateTimeService } from '@bigid-ui/i18n';
import { getApplicationPreference } from '../../../../services/appPreferencesService';

export const getPercentageValue = (value: number) => {
  return `${(Number(value) * 100).toFixed(0)}%`;
};

export const getPercentageAsNumber = (value: string) => {
  return Number(value) / 100;
};

export const isNumberFieldValid = (value: string) => {
  const regexPatternNumbersGreaterThen20 = /^(?:[2-9]\d|100)$/;
  return regexPatternNumbersGreaterThen20.test(value);
};

export const getIsRequestDetailsAvailableByStatus = (status: RequestStatus) => {
  if (!status) {
    return false;
  }
  return status === RequestStatus.COMPLETED || status === RequestStatus.FAILED;
};

export const getDateFormatted = (date: string) => {
  return dateTimeService.formatDate(date);
};

export const isSimilarTablesEnabled = (): boolean => {
  return !!getApplicationPreference('SIMILAR_TABLES_ENABLED');
};

export enum SIMILAR_TABLES_TRACKING_EVENTS {
  THRESHOLD_DIALOG_APPLY_CLICK = 'SIMILAR_TABLES_THRESHOLD_DIALOG_APPLY_CLICK',
  FIND_SIMILAR_TABLES_RESULT = 'FIND_SIMILAR_TABLES_RESULT',
}
