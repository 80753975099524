import { BigidBody1, BigidTooltip } from '@bigid-ui/components';
import { styled } from '@mui/material';
import React, { ComponentType, FC, ReactNode } from 'react';
import { useLocalTranslation } from '../../../../translations';
import { DataComponentProps, HightlightedValueResponse } from '../../../../types';

const Container = styled('div')`
  gap: 6px;
  align-items: center;
  display: flex;
  flex-direction: row;
  max-width: 100%;
`;

const TooltipContainer = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

const DataContainer = styled('div')`
  overflow: hidden;
  text-overflow: ellipsis;
`;

type ReasonProps = HightlightedValueResponse & {
  Icon: ComponentType<Pick<HightlightedValueResponse, 'fieldName'>>;
  DataComponent: FC<DataComponentProps>;
  ShowMoreComponent?: FC<
    Pick<HightlightedValueResponse, 'fieldName'> & {
      excessiveLength?: number;
    }
  >;
  getTooltipContent?: (props: Pick<HightlightedValueResponse, 'highlightedValue' | 'fieldName'>) => ReactNode;
  maxWidth: string;
};

export const ReasonProcessor: FC<ReasonProps> = ({
  fieldName,
  highlightedValue,
  DataComponent,
  Icon,
  ShowMoreComponent,
  getTooltipContent,
  value,
  maxWidth,
}) => {
  const { t } = useLocalTranslation('reasons');
  const [valueToShow, ...restValues] = highlightedValue;
  const shouldShowMore = restValues.length > 0;

  return (
    <Container>
      <Icon fieldName={fieldName} />
      <BigidBody1>{t(fieldName)}</BigidBody1>
      <DataContainer>
        {/* NOTE: temporarily disable until futher notice since no UX requirements provided for all the value */}
        {/* <BigidTooltip title={value}> */}
        <DataComponent maxWidth={maxWidth} fieldName={fieldName} elementToShow={valueToShow} value={value} />
        {/* </BigidTooltip> */}
      </DataContainer>
      {shouldShowMore && (
        <TooltipContainer>
          <BigidTooltip title={getTooltipContent({ fieldName, highlightedValue: restValues })}>
            <div>
              <ShowMoreComponent fieldName={fieldName} excessiveLength={restValues.length} />
            </div>
          </BigidTooltip>
        </TooltipContainer>
      )}
    </Container>
  );
};
