import { styled } from '@mui/material';
import React, { FC } from 'react';
import { Header } from '../Header';
import { Owners } from './Owners';

const Container = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 4px;
  max-width: 800px;
`;

type Props = {
  owners: string[];
  title: string;
};

export const AlienEntityTitle: FC<Props> = ({ owners = [], title }) => {
  return (
    <Container>
      <Header title={title} />
      <Owners owners={owners} />
    </Container>
  );
};
