import React, { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import angular from 'angular';
import axios from 'axios';
import { noop } from 'lodash';
import isEmail from 'validator/lib/isEmail';
import { FormControl } from '@mui/material';
import {
  BigidDialog,
  BigidDropZone,
  BigidForm,
  BigidFormFieldLabelPosition,
  BigidFormFieldTypes,
  BigidPrimaryCheckbox,
  PrimaryButton,
  SecondaryButton,
} from '@bigid-ui/components';
import { sessionStorageService } from '../../../../common/services/sessionStorageService';
import { licenseService } from '../../../services/licenseService';
import { convertToAngular } from '../../../../common/services/convertToAngular';
import { SystemEvents, systemEventsEmitter } from '../../../services/systemEvents';
import { notificationService } from '../../../services/notificationService';
import { $state, betaToolsService } from '../../../services/angularServices';
import { ResponseContent, ResponseContentProps } from './ResponseContent';
import { DialogHeading } from './DialogHeading';
import { ADVANCE_TOOLS_PERMISSIONS } from '@bigid/permissions';
import { isPermitted } from '../../../services/userPermissionsService';
import { PrivacyCheckbox } from './PrivacyCheckbox';
import { getApplicationPreference } from '../../../services/appPreferencesService';

interface CustomerFeedbackDialogProps {}

enum FeedbackType {
  BUG = 'bug',
  FEEDBACK_TASK = 'feedback task',
  FEEDBACK_BUG = 'feedback bug',
}

const feedbackTypeOptions = [
  { value: FeedbackType.FEEDBACK_TASK, label: 'Improve BigID' },
  { value: FeedbackType.FEEDBACK_BUG, label: 'Something went wrong' },
];

const CATEGORIES = [
  'Dashboard',
  'Inventory',
  'Correlation',
  'Classification',
  'Data Catalog',
  'Policies',
  'Reports',
  'Administration',
  'Applications',
  'Other',
];

const categoryOptions = CATEGORIES.map(category => ({ value: category, label: category }));

interface FeedbackForm {
  name: string;
  email: string;
  company: string;
  type: string;
  category?: string;
  description?: string;
}

interface TicketProperties extends FeedbackForm {
  errorEvent: ErrorEvent;
}

async function getAttachments(
  fileToImport: File,
  errorEvent: ErrorEvent,
  shouldAttachScreenshots: boolean,
  shouldAttachLogs: boolean,
) {
  let zipFile;

  if (errorEvent) {
    if (shouldAttachScreenshots || shouldAttachLogs) {
      const { default: JSZip } = await import(/* webpackChunkName: "jszip" */ 'jszip');
      const jsZip = new JSZip();

      if (shouldAttachScreenshots) {
        const { capture, OutputType } = await import(
          /* webpackChunkName:
       "html-screen-capture-js" */ 'html-screen-capture-js'
        );

        const htmlDocStr = capture(OutputType.STRING, window.document, {
          classesOfIgnoredDocBodyElements: ['MuiBackdrop-root', 'MuiDialog-container'],
        });
        jsZip.file('screen-capture.html', htmlDocStr as string);
      }

      if (shouldAttachLogs) {
        const logsData = await betaToolsService.getServicesLogs(['web']);
        const logsBlob = new Blob([logsData], { type: 'application/zip' });
        jsZip.file('web-logs.zip', logsBlob);
      }

      const blob = await jsZip.generateAsync({
        type: 'blob',
        compression: 'DEFLATE',
      });

      zipFile = await convertBlobToBase64(blob);
    }
  } else if (fileToImport) {
    zipFile = await convertBlobToBase64(fileToImport);
  }
  return zipFile;
}

function convertBlobToBase64(file: Blob | File): Promise<string | ArrayBuffer> {
  const reader = new FileReader();
  reader.readAsDataURL(file);
  return new Promise(resolve => {
    reader.onloadend = function () {
      const base64data = reader.result;
      resolve(base64data);
    };
  });
}

const generateTicketProperties = ({
  name,
  type,
  company,
  email,
  category = '',
  description = '',
  errorEvent,
}: TicketProperties) => ({
  summary: `${type} ${category ? 'in' : ''} ${category} opened by ${company}`,
  description: `
            username: ${name}
            email: ${email}
            company name: ${company}
            category: ${category}
            page: ${$state.$current.name}
            license data: 
            ${Object.entries(licenseService.getLicense()).reduce(
              (text, [key, value]) => text + '\n' + `${key}: ${JSON.stringify(value)}: `,
              '',
            )}
            ${description}
            ${generateErrorData(errorEvent)}
            `,
  ...(errorEvent && {
    error: {
      message: errorEvent?.message,
      stack: errorEvent?.error?.stack,
    },
  }),
});

function generateErrorData(errorEvent: ErrorEvent): string {
  return errorEvent
    ? `

            error information: ${errorEvent?.message}
            stack trace: ${errorEvent?.error?.stack}
            filename: ${errorEvent?.filename}
            timestamp: ${errorEvent?.timeStamp}
            
            `
    : '';
}

const BigidCustomerFeedbackDialog: FC<CustomerFeedbackDialogProps> = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [errorEvent, setErrorEvent] = useState<ErrorEvent>();
  const [fileToImport, setFileToImport] = useState<File>(null);
  const [shouldAttachScreenshots, setShouldAttachScreenshots] = useState<boolean>(false);
  const [shouldAttachLogs, setShouldAttachLogs] = useState<boolean>(false);
  const [checkPrivacyPolicy, setCheckPrivacyPolicy] = useState<boolean>(false);
  const [privacyPolicyError, setPrivacyPolicyError] = useState<boolean>(false);
  const [formResponse, setFormResponse] = useState<ResponseContentProps>();
  const submitFunction = useRef<any>(noop);

  const defaultValues = {
    name: getUserDisplayName(),
    email: (sessionStorageService.get('userEmail') as string) || '',
    company: licenseService.getLicense()?.owner || '',
    type: feedbackTypeOptions[0].value,
  };

  const labelWidth = '120px';
  const labelPosition = BigidFormFieldLabelPosition.left;
  const isLogsPermitted = isPermitted(ADVANCE_TOOLS_PERMISSIONS.EXPORT_SERVICES_LOGS.name);

  const form = useRef<FeedbackForm>(defaultValues);

  const handleClose = () => setIsOpen(false);

  function getUserDisplayName(): string {
    return sessionStorageService.get('displayName') || sessionStorageService.get('userName');
  }

  function isOnline() {
    return navigator.onLine;
  }

  const onExited = () => {
    setIsLoading(false);
    setErrorEvent(null);
    setFileToImport(null);
    setFormResponse(null);
    setShouldAttachScreenshots(false);
    setShouldAttachLogs(false);
    setCheckPrivacyPolicy(false);
    setPrivacyPolicyError(false);
    form.current = {
      name: getUserDisplayName(),
      email: sessionStorageService.get('userEmail') || '',
      company: licenseService.getLicense()?.owner || '',
      type: feedbackTypeOptions[0].value,
    };
  };

  useEffect(() => {
    function handleEvent(event: { errorEvent: ErrorEvent }) {
      setErrorEvent(event?.errorEvent);
      setIsOpen(true);
    }

    const unregister = systemEventsEmitter.addEventListener(SystemEvents.FEEDBACK_DIALOG, handleEvent);
    const unregisterLogout = systemEventsEmitter.addEventListener(SystemEvents.LOGOUT, handleClose);

    return function cleanup() {
      unregister();
      unregisterLogout();
      handleClose();
    };
  }, []);

  const handleOnDrop = async (files: File[]) => {
    const file = files.length ? files[0] : null;
    setFileToImport(file);
  };

  const onChange = useCallback(
    (values: any) => {
      const { type = [], category = [], description = '', email, ...res } = values;
      form.current = {
        ...(errorEvent ? form.current : res),
        type: type[0]?.value,
        category: category[0]?.value,
        description,
        email,
      };
    },
    [errorEvent],
  );

  const formProps = useMemo(
    () => ({
      onChange,
      controlButtons: ({ validateAndSubmit }: any) => {
        submitFunction.current = validateAndSubmit;
        return null;
      },
      initialValues: {
        ...(!errorEvent && {
          name: getUserDisplayName(),
          company: licenseService.getLicense()?.owner || '',
          type: [feedbackTypeOptions[0]],
        }),
        email: sessionStorageService.get('userEmail') || '',
        description: errorEvent?.message,
      },
      fields: [
        ...(errorEvent
          ? []
          : [
              {
                name: 'name',
                label: 'Name',
                isRequired: true,
                validate: (name: string) => {
                  if (!name) {
                    return 'Please enter your name';
                  }
                  return false;
                },
                labelWidth,
                labelPosition,
              },
              {
                name: 'type',
                type: BigidFormFieldTypes.SELECT,
                options: feedbackTypeOptions,
                labelWidth,
                labelPosition,
                label: 'Type',
                disabled: !!errorEvent,
              },
              {
                name: 'category',
                type: BigidFormFieldTypes.SELECT,
                options: categoryOptions,
                labelWidth,
                labelPosition,
                label: 'Topic',
                misc: {
                  placeholder: 'Choose an Area',
                },
              },
            ]),
        {
          name: 'email',
          label: 'Email',
          isRequired: true,
          validate: (email: string) => {
            if (!isEmail(email)) {
              return 'Please enter your email address';
            }
            return false;
          },
          labelWidth,
          labelPosition,
        },
        {
          name: 'description',
          type: BigidFormFieldTypes.TEXT,
          labelPosition,
          labelWidth,
          label: 'Description',
          validateOnChange: false,
          isRequired: true,
          validate: (description: string) => {
            if (!description || description === '') {
              return 'Please enter details';
            }
            return false;
          },
          misc: {
            multiline: true,
            rows: 3,
          },
        },
      ],
    }),
    [errorEvent, labelPosition, onChange],
  );

  const dialogButtons =
    formResponse || !isOnline()
      ? [
          {
            text: 'Close',
            component: PrimaryButton,
            onClick: handleClose,
            isClose: true,
            dataAid: 'BigidButton-Close',
          },
        ]
      : [
          {
            text: 'Cancel',
            component: SecondaryButton,
            onClick: handleClose,
            isClose: true,
          },
          {
            text: 'Submit',
            dataAid: 'BigidButton-Submit',
            component: PrimaryButton,
            onClick: async () => {
              const onItemsValidated = async () => {
                if (!checkPrivacyPolicy) {
                  return setPrivacyPolicyError(true);
                }
                setIsLoading(true);
                try {
                  const { type } = form.current;
                  const attachment = await getAttachments(
                    fileToImport,
                    errorEvent,
                    shouldAttachScreenshots,
                    shouldAttachLogs,
                  );

                  const ticketType = errorEvent ? FeedbackType.BUG : type;
                  const properties = {
                    ...generateTicketProperties({ ...form.current, errorEvent, type: ticketType }),
                    ...(attachment && { attachment }),
                    license: 'placeholder',
                  };

                  const {
                    data: { properties: response },
                  } = await axios.post(getApplicationPreference('FEEDBACK_REPORTER_URL'), {
                    type: ticketType,
                    properties,
                  });
                  setFormResponse(JSON.parse(response));
                } catch (error) {
                  console.error(error);
                  const errorMessage = error?.response?.data?.message || error.message;
                  notificationService.error(`Error: ${errorMessage}`);
                }
                setIsLoading(false);
              };
              await submitFunction?.current(onItemsValidated);
            },
          },
        ];

  function getDialogTitle() {
    if (!isOnline()) {
      return 'No Internet Connection';
    }

    return formResponse ? 'All Set' : errorEvent ? 'Error' : 'Provide Feedback to BigID';
  }

  return (
    <BigidDialog
      title={getDialogTitle()}
      isOpen={isOpen}
      onClose={handleClose}
      onExited={onExited}
      buttons={dialogButtons}
      borderTop
      isLoading={isLoading}
    >
      {isOnline() ? (
        <>
          {!formResponse && (
            <>
              <DialogHeading isError={!!errorEvent} />
              <BigidForm {...formProps} />
              {!errorEvent && (
                <FormControl fullWidth margin="normal">
                  <BigidDropZone
                    label="Upload a screenshot (Recommended)"
                    accept={['.jpeg', '.png', '.jpg']}
                    maxSize={10}
                    files={fileToImport ? [fileToImport] : []}
                    onDrop={handleOnDrop}
                  />
                </FormControl>
              )}
              {errorEvent && (
                <BigidPrimaryCheckbox
                  checked={shouldAttachScreenshots}
                  onChange={(e, val) => setShouldAttachScreenshots(val)}
                  label={'Attach a screenshot'}
                />
              )}
              {errorEvent && isLogsPermitted && (
                <BigidPrimaryCheckbox
                  checked={shouldAttachLogs}
                  onChange={(e, val) => setShouldAttachLogs(val)}
                  label={'Attach an error log'}
                />
              )}
              <PrivacyCheckbox
                checked={checkPrivacyPolicy}
                setChecked={setCheckPrivacyPolicy}
                error={privacyPolicyError}
              />
            </>
          )}
          {formResponse && <ResponseContent {...formResponse} />}
        </>
      ) : (
        'Please check your network connection and try again'
      )}
    </BigidDialog>
  );
};

angular
  .module('app')
  .component('bigidCustomerFeedbackDialog', convertToAngular<CustomerFeedbackDialogProps>(BigidCustomerFeedbackDialog));
