import { ObjectAttributeDetails, DataCatalogAttribute } from './hook/DsObjectFilePreviewService';

export const selectedTableAttribute = (objectAttributes: DataCatalogAttribute[], attribute: string): string[] => {
  const foundedAttributes = objectAttributes?.find(
    (attr: DataCatalogAttribute) => attr?.attribute_original_name === attribute,
  );
  const allColumnsForTable = foundedAttributes?.column_list?.map(column => column?.column_name);
  return allColumnsForTable;
};

export const findAttributesCount = (attributeName: string, attribute_details: ObjectAttributeDetails[]): number => {
  const attributeCount = attribute_details?.find((attr: ObjectAttributeDetails) => attr?.name === attributeName);
  return attributeCount ? attributeCount?.count : 0;
};
