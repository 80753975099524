import React, { FC, useEffect, useState } from 'react';
import { styled } from '@mui/material';
import { BigidCaption, BigidDropdown, BigidDropdownOption } from '@bigid-ui/components';
import { httpService } from '../../../../../services/httpService';
import { InfoHelper } from './InfoHelper';
import { PrivacyPortalRoleOptions, SystemRole } from '../../../types';

interface PrivacyPortalOptionsProps {
  privacyPortalDepartments?: string;
  privacyPortalPermissions?: string;
  updateRoleInfo: (roleProps: Partial<SystemRole>) => void;
}

const Root = styled('div')({
  display: 'flex',
  marginLeft: 5,
  marginTop: -10,
});

const FieldWrapper = styled('div')({
  marginRight: 20,
});

const LabelWrapper = styled('div')({
  display: 'flex',
  alignItems: 'center',
  marginTop: 10,
  marginBottom: 5,
});

const Label = styled(BigidCaption)({
  fontWeight: 400,
});

export const PrivacyPortalOptions: FC<PrivacyPortalOptionsProps> = ({
  privacyPortalDepartments,
  privacyPortalPermissions,
  updateRoleInfo,
}) => {
  const [privacyPortalOptions, setPrivacyPortalOptions] = useState<PrivacyPortalRoleOptions>({
    isPPInstalled: false,
    permissionGroups: [],
    scopes: [],
  });

  const handlePrivacyPortalDepartmentsChange = (value: BigidDropdownOption[]) => {
    updateRoleInfo({ privacyPortalDepartments: value[0].value });
  };

  const handlePrivacyPortalPermissionsChange = (value: BigidDropdownOption[]) => {
    updateRoleInfo({ privacyPortalPermissions: value[0].value });
  };

  const privacyPortalPermissionsOptions = privacyPortalOptions.permissionGroups.map(permissionGroup => ({
    displayValue: permissionGroup,
    id: permissionGroup,
    value: permissionGroup,
  }));

  const privacyPortalDepartmentsOptions = privacyPortalOptions.scopes.map(scope => ({
    displayValue: scope,
    id: scope,
    value: scope,
  }));

  const privacyPortalPermissionsValue = privacyPortalPermissionsOptions.find(
    option => option.value === privacyPortalPermissions,
  );

  const privacyPortalDepartmentsValue = privacyPortalDepartmentsOptions.find(
    option => option.value === privacyPortalDepartments,
  );

  useEffect(() => {
    const loadPrivacyPortalRoleOptions = async () => {
      const ppOptionsRes = await httpService.fetch('privacy-portal/role-options');
      setPrivacyPortalOptions(ppOptionsRes.data.data);
    };
    loadPrivacyPortalRoleOptions();
  }, []);

  if (!privacyPortalOptions.isPPInstalled) {
    return null;
  }

  return (
    <Root>
      <FieldWrapper>
        <LabelWrapper>
          <Label>Permissions</Label>
          <InfoHelper tooltip="Achieve highly-granular permission control for users through Permissions." />
        </LabelWrapper>
        <BigidDropdown
          value={[privacyPortalPermissionsValue]}
          onSelect={handlePrivacyPortalPermissionsChange}
          options={privacyPortalPermissionsOptions}
        />
      </FieldWrapper>
      <FieldWrapper>
        <LabelWrapper>
          <Label>Department</Label>
          <InfoHelper tooltip="Control visibility and access to relevant data source instances with Departments." />
        </LabelWrapper>
        <BigidDropdown
          value={[privacyPortalDepartmentsValue]}
          onSelect={handlePrivacyPortalDepartmentsChange}
          options={privacyPortalDepartmentsOptions}
        />
      </FieldWrapper>
    </Root>
  );
};
