import React, { FC, useCallback, useContext, useEffect, useState } from 'react';
import { BarChartDataItemType, BigidBarChart, BigidChartLegendPosition } from '@bigid-ui/components';
import { Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { MAX_NUMBER_OF_ACCESS_TYPES } from './consts';
import { AccessIntelligenceDashboardContext } from './AccessIntelligenceDashboardContext';
import { LabelConfiguration } from '../Settings/Configuration/LabelConfigurationService';

const useStyles = makeStyles(() => ({
  wrapper: {
    backgroundColor: '#fff',
    borderRadius: 12,
    padding: '15px 20px',
    display: 'flex',
    flexDirection: 'column',
    height: '150%',
    alignItems: 'center',
  },
  chartContainer: {
    marginTop: 50,
    height: '100%',
    width: '100%',
  },
}));

const defineAccessTypesChartData = (accessTypesSummary: LabelConfiguration[]): BarChartDataItemType[] => {
  const topAccessTypes = accessTypesSummary.slice(0, MAX_NUMBER_OF_ACCESS_TYPES);
  return topAccessTypes.map(accessType => ({
    category: accessType.label_name,
    'Number of Objects': accessType.objects_count,
  }));
};

export const TopAccessTypesWithSensitiveData: FC = () => {
  const classes = useStyles({});
  const { dashboardData } = useContext(AccessIntelligenceDashboardContext);
  const [chartData, setChartData] = useState<BarChartDataItemType[]>([]);
  const { aciLabels } = dashboardData;

  const areAllLabelsEmpty = useCallback(() => {
    if (!aciLabels) {
      return true;
    }
    return aciLabels.every(label => label?.objects_count === 0);
  }, [aciLabels]);

  useEffect(() => {
    if (!areAllLabelsEmpty()) {
      const chartData = defineAccessTypesChartData(aciLabels);
      setChartData(chartData);
    } else {
      setChartData([]);
    }
  }, [aciLabels, areAllLabelsEmpty]);

  return (
    <div className={classes.wrapper}>
      <Typography variant={'h4'} data-aid="ImportDataSourcesTitle">
        Total Findings by Access Type
      </Typography>
      <div className={classes.chartContainer}>
        <BigidBarChart
          withLegend={!areAllLabelsEmpty()}
          disableColumnsShadow
          legendPosition={BigidChartLegendPosition.TOP}
          data={chartData}
          showBarTotal
          maxColumnLabelWidth={150}
          disableTooltip
        />
      </div>
    </div>
  );
};
