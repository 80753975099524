import React from 'react';
import { BigidGridColumn, BigidGridColumnTypes, BigidGridWithToolbarProps } from '@bigid-ui/grid';
import { CLASSIFIER_TYPES, ClassifierGridRow, FILTER_KEYS } from '../types/ClassifierTypes';
import { BigidColorsV2, BigidHighlightIndicator, BigidStyledBadge } from '@bigid-ui/components';
import { BigidHelpIcon } from '@bigid-ui/icons';
import { getFixedT } from '../translations';
import {
  getCategoriesFilterDropdown,
  getClassifierTypeValue,
  getIsClassifyFileNamesEnabled,
  getIsNerClassifierFFEnabled,
  getNerClassifierTitle,
  isClassifierNeedsToBeUpdated,
  getIsClassifiersAutoUpgradeDisable,
  getRegulationsFilterDropdown,
  getAttributesFilterDropdown,
} from './utils';

import styled from '@emotion/styled';

const gridText = getFixedT('grid');
const formText = getFixedT('form');

function getVersionColumnIfEnabled(): BigidGridColumn<ClassifierGridRow>[] {
  const isClassifiersAutoUpgradeDisable = getIsClassifiersAutoUpgradeDisable();

  return isClassifiersAutoUpgradeDisable
    ? [
        {
          name: 'Version',
          width: 100,
          title: gridText('version'),
          sortingEnabled: false,
          getCellValue: row => {
            return isClassifierNeedsToBeUpdated(row) ? `${row.version} - Update available` : row.version;
          },
          type: BigidGridColumnTypes.TEXT,
        },
      ]
    : [];
}

export const getInitialClassifiersFilters = async (
  countryFilterOptions: { label: string; value: string; isSelected: boolean }[],
): Promise<BigidGridWithToolbarProps<ClassifierGridRow>['filterToolbarConfig']> => {
  const categoriesFilterDropdown = await getCategoriesFilterDropdown();
  const attributesFilterDropdown = await getAttributesFilterDropdown();

  // TODO: @Chofni Sharoni please uncomment when regulation will be added to 'all-classifications'
  // const regulationsFilterDropdown = await getRegulationsFilterDropdown();

  return {
    filters: [
      {
        title: 'Type',
        field: FILTER_KEYS.type,
        operator: 'in',
        options: [
          {
            label: 'Regex (Values)',
            value: CLASSIFIER_TYPES.CONTENT,
            isSelected: false,
          },
          {
            label: 'Regex (Metadata)',
            value: CLASSIFIER_TYPES.METADATA,
            isSelected: false,
          },
          ...(getIsClassifyFileNamesEnabled()
            ? [
                {
                  label: 'Regex (Values + Metadata)',
                  value: CLASSIFIER_TYPES.CONTENT_AND_METADATA,
                  isSelected: false,
                },
              ]
            : []),
          ...(getIsNerClassifierFFEnabled()
            ? [
                {
                  label: 'DOC',
                  value: CLASSIFIER_TYPES.DOC,
                  isSelected: false,
                },
              ]
            : []),
          ...(getIsNerClassifierFFEnabled()
            ? [
                {
                  label: 'NLP/NER',
                  value: CLASSIFIER_TYPES.NER,
                  isSelected: false,
                },
              ]
            : []),
          {
            label: 'Correlation',
            value: CLASSIFIER_TYPES.CORRELATION,
            isSelected: false,
          },
        ],
        value: [],
      },
      {
        title: 'Origin',
        field: FILTER_KEYS.isOutOfTheBox,
        operator: 'in',
        options: [
          {
            label: 'BigID',
            value: true,
            isSelected: false,
          },
          {
            label: 'Custom',
            value: false,
            isSelected: false,
          },
        ],
        value: [],
      },
      {
        title: 'Categories',
        field: FILTER_KEYS.categories,
        operator: 'in',
        options: categoriesFilterDropdown,
        value: [],
        listWidth: 400,
        displayLimit: categoriesFilterDropdown?.length,
      },
      // TODO: @Chofni Sharoni please uncomment when regulation will be added to 'all-classifications'
      // {
      //   title: 'Regulations',
      //   field: FILTER_KEYS.regulations,
      //   operator: 'in',
      //   options: regulationsFilterDropdown,
      //   value: [],
      //   listWidth: 400,
      // },
      {
        title: 'Global list',
        field: FILTER_KEYS.enabled,
        operator: 'in',
        options: [
          {
            label: 'Included',
            value: true,
            isSelected: false,
          },
          {
            label: 'Not included',
            value: false,
            isSelected: false,
          },
        ],
        value: [],
      },
      {
        title: 'Attribute name',
        field: FILTER_KEYS.glossaryId,
        operator: 'in',
        options: attributesFilterDropdown,
        value: [],
        listWidth: 400,
        displayLimit: attributesFilterDropdown?.length,
      },
      {
        title: 'Supported data type',
        field: FILTER_KEYS.dataType,
        operator: 'in',
        options: [
          {
            label: 'Structured',
            value: 'Structured',
            isSelected: false,
          },
          {
            label: 'Unstructured',
            value: 'Unstructured',
            isSelected: false,
          },
        ],
        value: [],
      },
      {
        title: 'Location',
        field: 'country_code',
        operator: 'in',
        options: [{ value: 'Global', label: 'Global', isSelected: false }, ...countryFilterOptions],
        value: [],
        displayLimit: countryFilterOptions.length,
        listWidth: 400,
      },
    ],
    searchConfig: {
      searchFilterKeys: ['classification_name', 'description', 'classifierName', 'nerEntityName'],
      initialValue: '',
      operator: 'textSearch',
      autoSubmit: true,
    },
  };
};

interface BigidStyledBadgeWrapperProps {
  enabled: boolean;
}

const BigidStyledBadgeWrapper = styled('div')`
  opacity: ${(props: BigidStyledBadgeWrapperProps) => (props.enabled ? 1 : 0.3)};
`;
export const getClassifiersInitialGridColumns = (): BigidGridColumn<ClassifierGridRow>[] => {
  return [
    {
      name: 'classifierName',
      title: gridText('classifierName'),
      sortingEnabled: false,
      getCellValue: row =>
        row.classifierType === CLASSIFIER_TYPES.NER
          ? getNerClassifierTitle(row.classification_name, row.modelName)
          : row.classification_name,

      type: BigidGridColumnTypes.TEXT,
      width: 280,
    },
    {
      name: 'isOutOfTheBox',
      title: gridText('origin'),
      sortingEnabled: false,
      getCellValue: row => (row.isOutOfTheBox ? 'BigID' : 'Custom'),
      type: BigidGridColumnTypes.TEXT,
      width: 90,
    },
    {
      name: 'type',
      title: gridText('type'),
      sortingEnabled: false,
      getCellValue: row => getClassifierTypeValue(row.classifierType),
      type: BigidGridColumnTypes.TEXT,
      width: 120,
    },
    {
      name: 'dataType',
      title: gridText('dataType'),
      sortingEnabled: false,
      getCellValue: row => (
        <>
          {row?.dataType?.length > 0 &&
            row.dataType.map((dataType, index) => (
              <span key={index}>
                {dataType}
                {index + 1 !== row.dataType.length ? ', ' : ''}
              </span>
            ))}
        </>
      ),
      type: BigidGridColumnTypes.CUSTOM,
      tooltip: {
        value: gridText('dataTypeTooltip') as string,
        icon: BigidHelpIcon,
      },
      width: 200,
    },
    {
      name: 'category',
      title: gridText('categories'),
      sortingEnabled: false,
      getCellValue: ({ categories }) => {
        return {
          categories: {
            value: categories?.map(category => ({
              categoryName: category.display_name,
              categoryColor: category.color,
            })),
          },
        };
      },
      type: BigidGridColumnTypes.CATEGORIES,
      tooltip: {
        value: formText('tooltips.categories') as string,
        icon: BigidHelpIcon,
      },
      width: 220,
    },
    {
      name: 'attributeName',
      title: gridText('attributeName'),
      sortingEnabled: false,
      getCellValue: row => {
        if (!row?.friendly_name) {
          return null;
        }
        return {
          chips: {
            value: [{ label: row.friendly_name ?? row.original_name, id: row._id }],
            isDisabled: true,
            entityMaxWidth: 200,
          },
        };
      },
      type: BigidGridColumnTypes.CHIPS,
      tooltip: {
        value: formText('tooltips.attributes') as string,
        icon: BigidHelpIcon,
      },
      width: 220,
    },

    // TODO: @Chofni Sharoni please uncomment when regulation will be added to 'all-classifications'
    // {
    //   name: 'regulations',
    //   title: gridText('regulations'),
    //   sortingEnabled: false,
    //   getCellValue: ({ regulations }) => {
    //     return {
    //       chips: {
    //         value: regulations?.map(regulation => ({ label: regulation })),
    //       },
    //     };
    //   },
    //   type: BigidGridColumnTypes.CHIPS,
    // },
    {
      name: 'enabled',
      title: gridText('global'),
      sortingEnabled: false,
      width: 170,
      getCellValue: row => {
        return (
          <>
            {row.classifierType !== CLASSIFIER_TYPES.CORRELATION && (
              <BigidStyledBadgeWrapper enabled={row.enabled}>
                <BigidStyledBadge
                  label={row.enabled ? 'Included' : 'Not included'}
                  hasBackground={false}
                  backgroundColor=""
                  icon={
                    <BigidHighlightIndicator
                      diameter={8}
                      color={row.enabled ? BigidColorsV2.green[600] : BigidColorsV2.gray[700]}
                    />
                  }
                />
              </BigidStyledBadgeWrapper>
            )}
          </>
        );
      },
      type: BigidGridColumnTypes.CUSTOM,
      tooltip: {
        value: gridText('globalTooltip') as string,
        icon: BigidHelpIcon,
      },
    },
    {
      name: 'Description',
      title: gridText('description'),
      sortingEnabled: false,
      width: 145,
      getCellValue: row => row?.description,
      type: BigidGridColumnTypes.TEXT,
    },
    ...getVersionColumnIfEnabled(),
  ];
};
