import { CuratedAttributeFriendlyName } from '../../../curationService';

export enum FriendlyNameReducerActionTypes {
  SET_FRIENDLY_NAME_OBJECT = 'SET_FRIENDLY_NAME_OBJECT',
  SET_NAME = 'SET_NAME',
  SET_DESCRIPTION = 'SET_DESCRIPTION',
}

interface SetFriendlyNameAttribute {
  type: FriendlyNameReducerActionTypes.SET_FRIENDLY_NAME_OBJECT;
  payload: CuratedAttributeFriendlyName;
}

interface SetFriendlyNameFieldValue {
  type: FriendlyNameReducerActionTypes.SET_NAME | FriendlyNameReducerActionTypes.SET_DESCRIPTION;
  payload: string;
}

type SetFriendlyNameAction = SetFriendlyNameAttribute | SetFriendlyNameFieldValue;

export const friendlyNameReducer = (
  state: CuratedAttributeFriendlyName,
  action: SetFriendlyNameAction,
): CuratedAttributeFriendlyName => {
  switch (action.type) {
    case FriendlyNameReducerActionTypes.SET_NAME:
      return {
        ...state,
        friendlyName: action.payload,
      };
    case FriendlyNameReducerActionTypes.SET_DESCRIPTION:
      return {
        ...state,
        description: action.payload,
      };
    case FriendlyNameReducerActionTypes.SET_FRIENDLY_NAME_OBJECT:
      return { ...action.payload, description: action.payload.description || '' };
    default:
      return state;
  }
};
