import React, { FC, useState, Fragment, useEffect, ChangeEvent, useCallback } from 'react';
import isEmail from 'validator/lib/isEmail';
import { every, values, debounce, find } from 'lodash';
import {
  PrimaryButton,
  SecondaryButton,
  BigidToggleButton,
  BigidToggleButtonProps,
  BigidDialog,
  BigidLoader,
} from '@bigid-ui/components';
import { PAGE_TITLE } from '../DigestReport';
import { httpService } from '../../../services/httpService';
import styles from '../DigestReport.scss';
import { sessionStorageService } from '../../../../common/services/sessionStorageService';
import { notificationService } from '../../../services/notificationService';
import { $stateParams } from '../../../services/angularServices';
import { emailSetupService } from '../../../services/emailSetupService';
import { SubscriptionDialogContent } from './SubscriptionDialogContent';
import { $location } from 'ngimport';
import { activityHighlightsService } from '../../../services/activityHighlightsService';
import { getApplicationPreference } from '../../../services/appPreferencesService';

const SUBSCRIBE_BUTTON = 'Subscribe';
const UNSUBSCRIBE_BUTTON = 'Unsubscribe';
const EVENT = 'weekly-report';

interface Subscription {
  email: string;
  enabled: boolean;
  schedule?: string;
}

export interface TrendsAvailability {
  day: string;
  week: string;
  month: string;
  [key: string]: string;
}

interface ReportSubscriptionProps {
  trendsAvailability: TrendsAvailability;
  selectedTrendOption: string;
  onChangeSelectedTrendOption: (selectedTrendOption: string) => void;
  initialSelectedTrend: string;
}

const getToggleButtons = ({ day, week, month }: TrendsAvailability): Array<BigidToggleButtonProps> => [
  {
    value: 'day',
    label: 'Daily',
    isDisabled: !day,
  },
  {
    value: 'week',
    label: 'Weekly',
    isDisabled: !week,
  },
  {
    value: 'month',
    label: 'Monthly',
    isDisabled: !month,
  },
];

const isEmailValid = (email: string) => (email ? isEmail(email) : false);
const defaultEmail = sessionStorageService.get('userEmail') as string;

export const ReportSubscription: FC<ReportSubscriptionProps> = ({
  trendsAvailability,
  selectedTrendOption,
  onChangeSelectedTrendOption,
  initialSelectedTrend,
}) => {
  const [isDialogOpen, setDialogState] = useState(false);
  const [isSubscribed, setIsSubscribed] = useState(false);
  const [isEmailConfigured, setIsEmailConfigured] = useState<boolean>(null);
  const [subscription, setSubscription] = useState<Subscription>(null);
  const [email, setEmail] = useState(defaultEmail);
  const [schedule, setSchedule] = useState<string>(null);
  const [isScheduleInputValid, setIsScheduleInputValid] = useState(true);
  const [isLoading, setIsLoading] = useState(true);

  const openDialog = () => setDialogState(true);
  const closeDialog = () => setDialogState(false);

  const onSubscribeClick = () => {
    isSubscribed ? updateSubscription(false) : openDialog();
  };

  const onConfirmClick = () => async () => {
    await updateSubscription(true);
    closeDialog();
  };

  const updateSubscription = useCallback(
    async (isSubscribeRequest: boolean) => {
      const userSubscription = {
        email,
        event: EVENT,
        enabled: isSubscribeRequest,
        schedule,
      };

      try {
        await (subscription
          ? httpService.put('subscriptions', userSubscription)
          : httpService.post('subscriptions', userSubscription));

        setSubscription(userSubscription);
        setIsSubscribed(userSubscription.enabled);
        setSchedule(userSubscription.schedule);

        notificationService.success(
          isSubscribeRequest ? `You've subscribed successfully` : `You've unsubscribed successfully`,
        );
      } catch (error) {
        notificationService.error('There was a problem updating the subscription');
      }
    },
    [email, subscription, schedule],
  );

  useEffect(() => {
    const init = async () => {
      try {
        setIsLoading(true);
        const {
          data: { data: [userSubscription] = [] },
        } = await httpService.fetch<{ data: Subscription[] }>('subscriptions', {
          event: EVENT,
        });

        const { enabled = false, email = defaultEmail, schedule } = userSubscription;
        setSubscription(userSubscription);
        setIsSubscribed(enabled);
        setEmail(email);

        if (schedule) {
          setSchedule(schedule);
        } else {
          const { data } = await httpService.fetch(`services-configuration`, {
            configurations: JSON.stringify(['SUBSCRIPTION_DEFAULT_TIME_SCHEDULE']),
          });
          setSchedule(find(data, { name: 'SUBSCRIPTION_DEFAULT_TIME_SCHEDULE' })?.value);
        }

        const { data } = await emailSetupService.getMailerData();
        setIsEmailConfigured(data.enabled);
      } catch (error) {
        notificationService.error('There was a problem fetching user subscription');
      } finally {
        setIsLoading(false);
      }
    };

    if (isDialogOpen) {
      init();
    }
  }, [isDialogOpen]);

  useEffect(() => {
    const unsubscribeUserIfNeeded = async () => {
      if (subscription) {
        const shouldUnsubscribeUser = $stateParams.unsubscribe === 'true' && subscription.enabled;
        if (shouldUnsubscribeUser) {
          await updateSubscription(false);
        }
        $location.search({});
      }
    };
    unsubscribeUserIfNeeded();
  }, [subscription, updateSubscription]);

  const isSubscribeDisabled = !isScheduleInputValid || !isEmailConfigured || !isEmailValid(email);

  const dialogButtons = [
    { text: 'Cancel', component: SecondaryButton, onClick: closeDialog },
    {
      text: 'Subscribe',
      component: PrimaryButton,
      onClick: onConfirmClick(),
      disabled: isSubscribeDisabled,
    },
  ];

  const shouldShowTrendsToggle = !every(values(trendsAvailability), v => !v);
  const onEmailChange = debounce(value => {
    setEmail(value);
  }, 150);

  return (
    <Fragment>
      <BigidDialog
        title="Activity Highlights Report Subscription"
        isOpen={isDialogOpen}
        onClose={closeDialog}
        buttons={dialogButtons}
      >
        {isLoading && <BigidLoader />}
        {!isLoading && (
          <SubscriptionDialogContent
            scheduleInitialValue={schedule}
            onScheduleChange={setSchedule}
            setIsScheduleInputValid={setIsScheduleInputValid}
            errorMessage={!isEmailValid(email) && 'Invalid email address'}
            emailDefaultValue={email}
            onEmailChange={({ target: { value } }: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) =>
              onEmailChange(value)
            }
            isEmailConfigured={isEmailConfigured}
          />
        )}
      </BigidDialog>
      <div className={styles.headerWrapper}>
        <div className={styles.headerFirstLineWrapper}>
          {shouldShowTrendsToggle ? (
            <BigidToggleButton
              toggleButtons={getToggleButtons(trendsAvailability)}
              initialSelected={initialSelectedTrend}
              onChange={(event: React.MouseEvent<HTMLElement>, value: string) => onChangeSelectedTrendOption(value)}
              enforceValue
            />
          ) : (
            <div className={styles.title}>{PAGE_TITLE}</div>
          )}
          <div className={styles.headerButtonsWrapper}>
            {getApplicationPreference('ENABLE_ACTIVITY_HIGHLIGHTS_PDF') && (
              <PrimaryButton
                onClick={() => activityHighlightsService.downloadActivityHighlightPdf(selectedTrendOption)}
                size="medium"
                dataAid="download-digest-report-action"
                margin="8px"
                text="Download PDF"
              />
            )}
            <PrimaryButton
              onClick={onSubscribeClick}
              size="medium"
              dataAid="subscribe-action"
              text={isSubscribed ? UNSUBSCRIBE_BUTTON : SUBSCRIBE_BUTTON}
            />
          </div>
        </div>
        {shouldShowTrendsToggle && (
          <div className={styles.trendsTimeline}>{trendsAvailability[selectedTrendOption]}</div>
        )}
      </div>
    </Fragment>
  );
};
