import React, { FC, memo, useRef, useState, useEffect, useCallback } from 'react';
import {
  compareObjectsExceptFunctions,
  BigidFormFieldSideLabelWrapper,
  BigidFieldRenderProps,
  BigidFormField,
  BigidLoader,
} from '@bigid-ui/components';
import makeStyles from '@mui/styles/makeStyles';
import { generateFields } from '../generateFields';
import classNames from 'classnames';

const useStyles = makeStyles({
  wrapper: {
    display: 'inline-flex',
    width: '100%',
    alignItems: 'center',
  },
  wrapperFirstItem: {
    display: 'inline-flex',
    width: '100%',
    alignItems: 'center',
    marginLeft: '-8px',
  },
});

export const FormObjectField: FC<BigidFieldRenderProps> = memo(
  ({
    setValue,
    name,
    label = '',
    value,
    misc,
    isRequired,
    labelWidth,
    tooltipText,
    disabled,
    touched,
    isSubmitted,
    isValidated,
    wasSubmitted,
    errorIsShown,
    error,
  }) => {
    const valueRef = useRef(value);
    const [objectFieldProps, setObjectFieldProps] = useState<BigidFormField[]>();
    valueRef.current = value;
    const { wrapper, wrapperFirstItem } = useStyles({});
    const objectFieldId = `bigid-form-field-object-${name}`;
    const updateFieldProps = useCallback(async () => {
      if (misc?.objectFields) {
        setObjectFieldProps(await generateFields(misc?.objectFields));
      }
    }, [misc?.objectFields]);

    useEffect(() => {
      updateFieldProps();
    }, [misc?.objectFields, updateFieldProps]);

    return (
      <BigidFormFieldSideLabelWrapper
        id={objectFieldId}
        name={name}
        label={String(label)}
        isRequired={isRequired}
        labelWidth={labelWidth}
        tooltipText={tooltipText}
        noIndent
        isError={error && errorIsShown}
      >
        {objectFieldProps ? (
          (objectFieldProps as BigidFormField[])
            .filter(({ misc }) => !misc.hidden)
            .map(({ render: ObjectFieldRender, options, ...restProps }, index) => {
              return (
                <div
                  key={restProps.name}
                  className={classNames(index === 0 ? wrapperFirstItem : wrapper, 'FormObjectFieldFirstItem')}
                >
                  <ObjectFieldRender
                    labelWidth={restProps?.misc?.labelWidth ?? 'auto'}
                    name={`${name}-subfield-${restProps.name}`}
                    label={restProps.label}
                    error={false}
                    disabled={disabled}
                    touched={touched ?? true}
                    isSubmitted={isSubmitted}
                    isValidated={isValidated}
                    errorIsShown={false}
                    wasSubmitted={wasSubmitted}
                    value={valueRef.current?.[restProps.name]}
                    setValue={(newValue: any) => {
                      const newValueCurrent = { ...(valueRef.current || {}) };
                      newValueCurrent[restProps.name] = newValue;
                      setValue(newValueCurrent);
                    }}
                    options={options}
                    misc={restProps?.misc || {}}
                    tooltipText={restProps?.tooltipText || ''}
                  />
                </div>
              );
            })
        ) : (
          <BigidLoader />
        )}
      </BigidFormFieldSideLabelWrapper>
    );
  },
  compareObjectsExceptFunctions,
);

FormObjectField.displayName = 'FormObjectField';
