import { httpService } from '../../services/httpService';
import { ReactText } from 'react';
import { QueryNode } from '@bigid-ui/components';
import { DateISO8601 } from '../../types/types';
import { SystemAttributesResponse, SystemAttributeType } from '../DataCatalog/DataCatalogService';

export interface CatalogRule {
  id?: ReactText;
  name: string;
  type?: CatalogRuleType;
  bigidQuery?: string;
  bigidQueryObject?: QueryNode;
  action?: CatalogRuleAction;
  description?: string;
  isEnabled?: boolean;
  isPredefined?: boolean;
  updatedAt?: DateISO8601;
  createdAt?: DateISO8601;
  sseRoutingKey?: string;
  lastAppliedAllDate?: DateISO8601;
  lastAppliedDate?: DateISO8601;
  attributeFriendlyName?: string;
}

export interface CatalogRuleAction {
  type: CatalogRuleType;
  value: string;
}

export interface CatalogAppliedRuleTask {
  sseRoutingKey: string;
  taskId: string;
}

export enum CatalogRuleType {
  COMPLEX_ATTRIBUTE = 'complexAttribute',
  CREATE_NEW_MANUAL_ATTRIBUTE = 'CREATE_NEW_MANUAL_ATTRIBUTE',
  APPLY_MANUAL_ATTRIBUTE = 'APPLY_MANUAL_ATTRIBUTE',
}

export type FetchCatalogRulesResponse = { data: CatalogRule[] };
export type FetchCatalogRuleResponse = { data: CatalogRule };

export type CreateCatalogRulePayload = Partial<CatalogRule>;
export type CreateCatalogRuleResponse = { data: CatalogRule };

export type UpdateCatalogRulePayload = Pick<CatalogRule, 'name' | 'description'>;
export type UpdateCatalogRuleResponse = { data: CatalogRule };

export type ApplyCatalogRuleResponse = { data: CatalogAppliedRuleTask };

export type GlossaryAttribute = {
  attributeId: string;
  attributeName: string;
  attributeType: SystemAttributeType;
};
export type GlossaryAttributesListResponse = SystemAttributesResponse & {
  glossaryOOTBAttributesList: GlossaryAttribute[];
};

export const getCatalogRulesList = () => {
  return httpService.fetch<FetchCatalogRulesResponse>('data-catalog/rules').then(({ data }) => data.data);
};

export const getCatalogRuleById = (id: ReactText) => {
  return httpService.fetch<FetchCatalogRuleResponse>(`data-catalog/rules/${id}`).then(({ data }) => data.data);
};

export const createCatalogRule = (data: CreateCatalogRulePayload) => {
  return httpService
    .post<CreateCatalogRuleResponse, CreateCatalogRulePayload>(`data-catalog/rules`, data)
    .then(({ data }) => data.data);
};
export const updateCatalogRule = (id: CatalogRule['id'], data: UpdateCatalogRulePayload) => {
  return httpService
    .put<UpdateCatalogRuleResponse, UpdateCatalogRulePayload>(`data-catalog/rules/${id}`, data)
    .then(({ data }) => data.data);
};

export const deleteCatalogRule = (id: CatalogRule['id']) => {
  return httpService.delete(`data-catalog/rules/${id}`);
};

export const applyCatalogRule = (id: CatalogRule['id']) => {
  return httpService.post<ApplyCatalogRuleResponse>(`data-catalog/rules/apply/${id}`).then(({ data }) => data.data);
};

export const getGlossaryAttributesList = () => {
  return httpService
    .fetch<{ data: GlossaryAttributesListResponse[] }>('data-catalog/manual-fields/glossary-attributes-list')
    .then(({ data }) => data.data[0]);
};
