import { noop } from 'lodash';
import { BigidSystemDialogOptions, openSystemDialog } from '../../../../../services/systemDialogService';
import { CuratedAttribute } from '../../../curationService';
import { getFixedT } from '../../../translations';
import { AssignFriendlyNameDialogContent } from './AssignFriendlyNameDialogContent';

export const showAssignFriendlyNameDialog = (attribute: CuratedAttribute, gridId: string): Promise<boolean> => {
  const t = getFixedT(`AssignFriendlyNameDialog`);
  return new Promise(resolve => {
    const options: BigidSystemDialogOptions = {
      title: t('dialogTitle'),
      maxWidth: 'xs',
      isContentScrollable: false,
      onClose: noop,
      showCloseIcon: true,
      borderTop: true,
      content: AssignFriendlyNameDialogContent,
      contentProps: {
        attribute,
        gridId,
      },
    };
    openSystemDialog(options);
  });
};
