import './exploration.scss';
import { mapService } from '../common/services/mapService';
import template from './exploration.component.html';

import { module } from 'angular';
import { setAmMapData } from '../common/amMap/ammap.service';
import { headerEventEmitter, HeaderEvents } from '../react/services/eventEmitters/headerEvents';
import { isPermitted } from '../react/services/userPermissionsService';
import { TAGS_SAVED_QUERIES_PERMISSIONS, BUSINESS_FLOWS_PERMISSIONS, POLICIES_PERMISSIONS } from '@bigid/permissions';
import { getApplicationPreference } from '../react/services/appPreferencesService';
import { sessionStorageService } from '../common/services/sessionStorageService';
const app = module('app');

const MAP_CONFIG_ID = 'inventoryMap';

app.component('exploration', {
  template,
  controllerAs: '$ctrl',
  controller: function (
    $scope,
    $stateParams,
    $state,
    localStorageService,
    $rootScope,
    entitySummaryService,
    $window,
    riskTrendService,
    privacyRiskTrendService,
    explorationBarChartService,
    privacyRiskMapService,
    $uibModal,
    explorationService,
    $translate,
    notificationService,
    commonMethods,
  ) {
    'ngInject';

    const TRANSLATION_REQUIRED = [
      'INVENTORY',
      'INVENTORY:MORE_BUTTON:GENERATE_DATA_FLOW',
      'INVENTORY:MORE_BUTTON:DEFINE_TAG',
      'INVENTORY:MORE_BUTTON:DEFINE_POLICY',
    ];

    const DYNAMIC_FILTER_SECTIONS_AMOUNT = 4;

    const DEFAULT_MAP_ZOOM_LEVEL = 1;

    let sideFilterDataAggregator = {};
    this.filterOpened = true;
    this.sideFilterData = null;

    this.sectionOrdering = {
      country: 'identities',
      field: 'attributes',
      application: 'applications',
      system: 'systems',
    };
    this.defineTagModalInstance = {};

    this.moreButtonItems = [];

    const mapSettings = mapService.getConfig(MAP_CONFIG_ID) || {};

    this.initialMapZoomLevel = mapSettings.zoomLevel ? mapSettings.zoomLevel : DEFAULT_MAP_ZOOM_LEVEL;

    this.initialMapZoomLatitude = mapSettings.zoomLatitude;

    this.initialMapZoomLongitude = mapSettings.zoomLongitude;

    this.useExperimentalQuery = getApplicationPreference('NEW_QUERY_FILTER_ENABLED');

    this.$onInit = () => {
      $translate(TRANSLATION_REQUIRED).then(translations => {
        $rootScope.$broadcast('changePage', translations['INVENTORY'], true);

        this.moreButtonItems = [
          {
            name: translations['INVENTORY:MORE_BUTTON:GENERATE_DATA_FLOW'],
            action: 'generateDataMap',
            isPermitted: isPermitted(BUSINESS_FLOWS_PERMISSIONS.MANAGE.name),
          },
          {
            name: translations['INVENTORY:MORE_BUTTON:DEFINE_TAG'],
            action: 'defineTag',
            isPermitted: isPermitted(TAGS_SAVED_QUERIES_PERMISSIONS.CREATE.name),
          },
          {
            name: translations['INVENTORY:MORE_BUTTON:DEFINE_POLICY'],
            action: 'createPolicy',
            isPermitted: isPermitted(POLICIES_PERMISSIONS.ADD_POLICY.name),
          },
        ].filter(({ isPermitted }) => isPermitted);

        const userGroup = sessionStorageService.get('userRoles');
        this.isAdmin = userGroup === 'admin' ? true : false;

        headerEventEmitter.emit(HeaderEvents.UPDATE_UNREAD_TASKS_AND_RISK);
        riskTrendService.clearGetRiskSnapShotsDataRequest();
        privacyRiskTrendService.clearGetRiskTrendDataRequest();
        explorationBarChartService.clearGetResidencyDataRequest();
        explorationBarChartService.clearGetSourceRisksRequest();
        explorationBarChartService.clearGetApplicationRisksRequest();
        explorationBarChartService.clearGetAttributesRisksRequest();

        if ($stateParams.filter) {
          this.filter = decodeURIComponent($stateParams.filter);

          const filterValue = localStorageService.get('filterValue');
          if (filterValue) {
            this.cleanFilter = filterValue;
          }

          localStorageService.set('filterValueForQueryString', this.filter);
          localStorageService.set('filterValue', $stateParams.filter);

          this.urlfilter = $stateParams.filter;

          this.querystring = $stateParams.filter;

          this.searchtextbox = $stateParams.searchTextBox;
          this.style = "{'height': '500px','width': '97%','margin-left': '30px'}";
          this.mapContainerStyle = "{'height': '500px'}";
          getPrivacyRiskMapResults(this.querystring);
        } else {
          this.style = "{'height': '500px','width': '97%','margin-left': '30px'}";
          this.mapContainerStyle = "{'height': '500px'}";
          getPrivacyRiskMapResults();
        }
      });
    };

    this.$onChanges = () => {
      $window.sessionStorage.removeItem('callFilter.urlfilter');
      entitySummaryService.getPanelData().then(result => {
        for (let i = 0; i < result.entity_summaries.length; i++) {
          switch (result.entity_summaries[i].entity) {
            case 'user':
              this.totalIdentities = commonMethods.getAbbrNum(result.entity_summaries[i].totalInIDSoR, 1);
              this.totalIdentitiesAtRisk = result.entity_summaries[i].sub_counter;
              break;
            case 'record':
              //do nothing...
              break;
            case 'source':
              this.totalSystems = result.entity_summaries[i].counter;
              this.totalSystemsUnmanaged = result.entity_summaries[i].sub_counter;
              break;
            case 'application':
              this.totalApplications = result.entity_summaries[i].counter;
              this.totalApplicationsAtRisk = result.entity_summaries[i].sub_counter;
              break;
          }
        }
      });
    };

    this.onMoreButtonItemClick = item => {
      if (typeof item.action != 'undefined' && typeof this[item.action] == 'function') {
        this[item.action]();
      }
    };

    this.generateDataMap = () => {
      const queryFilter = this.querystring ? this.querystring.replace('?filter=', '') : '';

      this.editModalInstance = $uibModal
        .open({
          animation: true,
          template:
            '<div class="modal-header" style="border: 1px solid #323a45;border-radius: 4px 4px 0 0; height: 66px;background-color: #262d37;">' +
            '<span class="modal-title" style="color: white;font-size: 18px;margin: 5px 0 0 0;position:absolute;">Generate Data Flow</span>' +
            '<img ng-click="$ctrl.cancel()" class="close-image" ng-src="/images/close_icon.svg" src="/images/close_icon.svg">' +
            '</div>' +
            '<div ng-class="{\'spinner-widget-target\': $ctrl.loading}" class="modal-body">' +
            '<div class="row">' +
            '<form name="businessProcessNameForm" novalidate>' +
            '<div class="col-sm-12">' +
            '<div class="form-group">' +
            '<label for="bpName">Data Flow Map name</label>' +
            '<input name="bpName" id="bpName" required type="text" ng-model="$ctrl.newBusinessProcessName"' +
            ' class="form-control" ng-class="{invalid: businessProcessNameForm.bpName.$error.required}">' +
            '<div style="color: red;"' +
            ' ng-show="businessProcessNameForm.bpName.$error.required && businessProcessNameForm.bpName.$touched">' +
            'This field is required' +
            '</div>' +
            '</div>' +
            '</div>' +
            '</form>' +
            '</div>' +
            '<div class="spinnerWidget" ng-show="$ctrl.loading"></div>' +
            '</div>' +
            "<div class='modal-footer'>" +
            '<button ng-disabled="$ctrl.loading" class="btn btn-primary" type="button" ng-click="$ctrl.create(businessProcessNameForm.$valid)"' +
            'style="width:75px;font-size: 12px;font-weight: 500;;height: 34px">Create</button>' +
            '<button class="btn btn-primary" type="button" ng-click="$ctrl.cancel()" style="height: 34px;' +
            'width: 75px;font-size: 12px;font-weight: 500;;">Close</button>' +
            '</div>',
          controllerAs: '$ctrl',
          controller: [
            'queryFilter',
            '$uibModalInstance',
            function (queryFilter, $uibModalInstance) {
              this.queryFilter = queryFilter;
              this.loading = false;
              this.isRejected = false;

              this.create = isValid => {
                if (!isValid) return;

                this.loading = true;

                explorationService.generateDataMap(this.queryFilter, this.newBusinessProcessName).then(result => {
                  if (result.err) {
                    notificationService.error(result.err);
                    this.isRejected = true;
                    $uibModalInstance.close({ isRejected: this.isRejected });
                  } else {
                    this.loading = false;
                    let businessProcessTabsIDs = JSON.parse(localStorageService.get('businessProcessesTabs'));
                    businessProcessTabsIDs = [...(businessProcessTabsIDs || []), { _id: result.businessProcessId }];
                    localStorageService.set('businessProcessesTabs', JSON.stringify(businessProcessTabsIDs));
                    $uibModalInstance.close({
                      businessProcessId: result.businessProcessId,
                      isRejected: this.isRejected,
                    });
                  }
                });
              };

              this.cancel = () => {
                this.isRejected = true;
                $uibModalInstance.close({ isRejected: this.isRejected });
              };
            },
          ],
          size: 'new-data-map',
          backdrop: 'static',
          keyboard: false,
          resolve: {
            queryFilter: () => {
              return queryFilter;
            },
          },
        })
        .result.then(result => {
          if (result.isRejected !== true && result.businessProcessId) {
            $state.go('dataMapping', { businessProcessId: result.businessProcessId });
          }
        });
    };

    this.getSavedQueries = () => {
      explorationService.getSavedQueries().then(response => {
        this.savedQueries = response;
      });
    };

    this.defineTag = () => {
      const adaptedString = decodeURIComponent(this.querystring ? this.querystring.replace('?filter=', '') : '');

      let formData;

      if (adaptedString !== '') {
        formData = {
          query: adaptedString,
        };
      }

      this.defineTagModalInstance = $uibModal
        .open({
          animation: true,
          template:
            "<saved-queries on-form-closed='$ctrl.onFormClosed()' initial-data='$ctrl.formData' " +
            "on-form-submitted='$ctrl.onFormSubmitted(isUpdated)' form-only='true'></saved-queries>",
          controllerAs: '$ctrl',
          controller: [
            'formData',
            '$uibModalInstance',
            function (formData, $uibModalInstance) {
              this.formData = formData;

              this.onFormSubmitted = isUpdated => {
                $uibModalInstance.close(isUpdated);
              };

              this.onFormClosed = () => {
                $uibModalInstance.close();
              };
            },
          ],
          size: 'lg',
          backdrop: 'static',
          keyboard: false,
          resolve: {
            formData: () => {
              return formData;
            },
          },
        })
        .result.then(isUpdated => {
          if (isUpdated) this.getSavedQueries();
        });
    };

    this.createPolicy = () => {
      const bigidQuery = decodeURIComponent(this.querystring ? this.querystring.replace('?filter=', '') : '');

      const owner = sessionStorageService.get('userName');

      const initialData = {
        owner,
        complianceRuleCalc: {
          bigidQuery,
        },
      };

      $uibModal
        .open({
          animation: true,
          template:
            "<policies on-form-closed='$ctrl.onFormClosed()' initial-data='$ctrl.initialData' " +
            "on-form-submitted='$ctrl.onFormSubmitted(isUpdated, policyId)' form-only='true'></policies>",
          controllerAs: '$ctrl',
          controller: [
            'initialData',
            '$uibModalInstance',
            function (initialData, $uibModalInstance) {
              this.initialData = initialData;

              this.onFormSubmitted = (isUpdated, policyId) => {
                $uibModalInstance.close({ isUpdated, policyId });
              };

              this.onFormClosed = () => {
                $uibModalInstance.close();
              };
            },
          ],
          size: 'lg',
          backdrop: 'static',
          keyboard: false,
          resolve: {
            initialData: () => initialData,
          },
        })
        .result.then(result => {
          const { isUpdated, policyId } = result;

          if (isUpdated && typeof policyId != 'undefined') {
            $state.go('rules', { policyId });
          }
        });
    };

    const getPrivacyRiskMapResults = queryString => {
      const isClearCache = true;
      privacyRiskMapService.getPrivacyRiskMapResults(queryString, isClearCache).then(result => {
        setAmMapData(this, result);
      });
    };

    const listenerCallToHeaderQueryFilter = $rootScope.$on('callToHeaderQueryFilter', (event, filterValue) => {
      this.querystring = filterValue;
      getPrivacyRiskMapResults(filterValue);
    });

    const listenerCleanFilter = $rootScope.$on('cleanFilter', event => {
      getPrivacyRiskMapResults();
      this.querystring = '';

      sideFilterDataAggregator = {};
    });

    const listenerPopulateSidebarFilter = $scope.$on('populate-sidebar-filter', (event, data) => {
      const type = Object.keys(data)[0];

      if (!sideFilterDataAggregator[type]) {
        const _data = data[type]['data'].sort((a, b) => (a['risk'] > b['risk'] ? -1 : a['risk'] < b['risk'] ? 1 : 0));

        sideFilterDataAggregator[type] = {
          title: data[type]['title'],
          total: _data.length,
          data: _data.slice(0, 5),
          restOfData: _data.slice(5),
        };
      }

      if (Object.keys(sideFilterDataAggregator).length === DYNAMIC_FILTER_SECTIONS_AMOUNT) {
        this.sideFilterData = sideFilterDataAggregator;
      }
    });

    const listenerSidebarFilterReady = $scope.$on('sidebar-filter-ready', (event, data) => {
      this.sideFilterForbiddenToUse = data.permitted;
    });

    const listenerToggleSidebarFilter = $rootScope.$on('toggle-sidebar-filter', (event, data) => {
      this.filterOpened = data;
    });

    this.onTreeMapZoomChanged = data => {
      mapService.setConfig(MAP_CONFIG_ID, data);
    };

    //Unregister listeners
    $scope.$on('$destroy', () => {
      listenerCallToHeaderQueryFilter();
      listenerCleanFilter();
      listenerPopulateSidebarFilter();
      listenerSidebarFilterReady();
      listenerToggleSidebarFilter();
    });
  },
});
app.directive('loading', [
  '$http',
  $http => {
    return {
      restrict: 'A',
      link: $scope => {
        $scope.isLoading = () => {
          return $http.pendingRequests.length > 0;
        };

        $scope.$watch($scope.isLoading, v => {
          $scope.$emit('sidebar-filter-ready', { permitted: !v });
        });
      },
    };
  },
]);
