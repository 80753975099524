import React, { FC, PropsWithChildren, useCallback, useContext, useMemo } from 'react';
import { BigidPaper, BigidTabs, BigidTabsItem } from '@bigid-ui/components';
import { WizardLayout } from '../../../DataSources/DataSourceConfiguration/mappers/styles';
import { AutoDiscoveryPresetConfigTabsEnum, AutoDiscoveryWizardContext } from '../autoDiscoveryWizardContext';
import { $state } from '../../../../services/angularServices';
import { CONFIG } from '../../../../../config/common';
import { useLocalTranslation } from '../../translations';
import { TFunction } from 'react-i18next';
import { getApplicationPreference } from '../../../../services/appPreferencesService';

export const getTabs = (t: TFunction) => [
  {
    id: AutoDiscoveryPresetConfigTabsEnum.connection,
    label: t('configAdvanced.tabs.connection'),
  },
  {
    id: AutoDiscoveryPresetConfigTabsEnum.discovery,
    label: t('configAdvanced.tabs.discovery'),
  },
  ...(getApplicationPreference('USE_SAAS')
    ? []
    : [
        {
          id: AutoDiscoveryPresetConfigTabsEnum.audit,
          label: t('configAdvanced.tabs.logs'),
        },
      ]),
];

export const AutoDiscoveryPresetConfigTabs: FC<PropsWithChildren> = ({ children }) => {
  const { t } = useLocalTranslation('AutoDiscovery');
  const { discoveryConfigData, setDiscoveryConfigData } = useContext(AutoDiscoveryWizardContext);
  const tabs = useMemo(() => getTabs(t), [t]);

  const handleTabChange = useCallback(
    (_value: number, { id }: BigidTabsItem) => {
      setDiscoveryConfigData(current => ({ ...current, tab: id as AutoDiscoveryPresetConfigTabsEnum }));
      $state.transitionTo(
        CONFIG.states.AUTO_DISCOVERY_PRESET_CONFIG,
        { ...$state.params, tab: id },
        { location: true, inherit: true, relative: $state.$current, notify: false, reload: false },
      );
    },
    [setDiscoveryConfigData],
  );
  const selectedIndex = useMemo(
    () => tabs.findIndex(({ id }) => id === discoveryConfigData.tab),
    [discoveryConfigData.tab],
  );

  return (
    <BigidPaper dataAid={'AutoDiscoveryPresetConfigTabs'}>
      <WizardLayout.Wrapper>
        <WizardLayout.Tabs>
          <BigidTabs selectedIndex={selectedIndex} tabs={tabs} onChange={handleTabChange} variant="standard" />
        </WizardLayout.Tabs>
        <WizardLayout.Content>{children}</WizardLayout.Content>
      </WizardLayout.Wrapper>
    </BigidPaper>
  );
};
