import React, { FC, useEffect } from 'react';
import { BigidBody1, BigidHeading1, BigidLoader } from '@bigid-ui/components';
import { useMediaQuery } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useTheme } from '@mui/styles';
import { $state } from '../../../services/angularServices';
import {
  BigidDataExplorerIllustration,
  BigidDataManagementIllustration,
  BigidDataSourceIllustration,
} from '@bigid-ui/icons';
import { DataSourcesInitCard } from './DataSourcesInitCard/DataSourcesInitCard';
import { DATA_SOURCES_INIT_VIEWS } from '../DataSources';
import { smallIdLicenseService } from '../../../services/smallIdLicenseService';
import { DATA_SOURCES_PERMISSIONS } from '@bigid/permissions';
import { isPermitted } from '../../../services/userPermissionsService';
import { getApplicationPreference } from '../../../services/appPreferencesService';
import { HEADER_HEIGHT } from '../../../components/BigidHeader/BigidHeader';
import { pageHeaderService } from '../../../../common/services/pageHeaderService';
import {
  isAutoDiscoveryAvailableInDs,
  useAutoDiscoveryAppStatus,
} from '../DataSourceConnections/hooks/useAutoDiscoveryAppStatus';
import {
  AutoDiscoveryUITrackingEvent,
  trackEventAutoDiscoveryView,
} from '../../AutoDiscovery/AutoDiscoveryEventTrackerUtils';
import { useAutoDiscoverWizard } from '../../AutoDiscovery/hooks/useAutoDiscoverWizard';
import { goToAutoDiscovery } from '../../AutoDiscovery/utils';
import { openSelectDataSourceOnboarding } from '../SelectDataSourceOnboardingType/selectDataSourceOnboardingTypeService';

interface ClassProps {
  isSmallScreen: boolean;
}

const INSTALL_AUTO_DISCOVERY_DISABLED_MESSAGE =
  'Autodiscovery applications are not installed. Please contact your system admin.';
const BOTTOM_PADDING = 8;

const useStyles = makeStyles({
  dataSourcesInitContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    height: `calc(100% - ${BOTTOM_PADDING}px - ${HEADER_HEIGHT}px)`,
    overflowY: 'scroll',
  },
  title: {
    textAlign: 'center',
  },
  text: {
    marginTop: 4,
    marginBottom: 72,
    textAlign: 'center',
  },
  cardsContainer: ({ isSmallScreen }: ClassProps) => ({
    display: 'grid',
    gridAutoFlow: 'column',
    gridColumnGap: isSmallScreen ? 32 : 40,
  }),
});

export const DataSourcesInit: FC = () => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));
  const classes = useStyles({ isSmallScreen });
  const { openAutoDiscoveryModal } = useAutoDiscoverWizard();

  const { isAutoDiscoveryAvailable, isLoadingAutoDiscoveryAvailability } = useAutoDiscoveryAppStatus();
  const automaticallyDiscoverHandler = () => {
    trackEventAutoDiscoveryView(AutoDiscoveryUITrackingEvent.DATA_SOURCES_OPEN_AUTO_DISCOVERY_CLICK);
    goToAutoDiscovery(openAutoDiscoveryModal);
  };

  useEffect(() => {
    pageHeaderService.setIsHidden(true);
    return () => pageHeaderService.setIsHidden(false);
  }, []);

  return (
    <>
      {isLoadingAutoDiscoveryAvailability && <BigidLoader dataAid="DataSourcesInitLoader" />}
      {!isLoadingAutoDiscoveryAvailability && (
        <div className={classes.dataSourcesInitContainer} data-aid="DataSourcesInit">
          <div>
            <BigidHeading1 className={classes.title} data-aid="DataSourcesInitTitle">
              Connect Your Data
            </BigidHeading1>
            <BigidBody1 className={classes.text} data-aid="DataSourcesInitText">
              Create new connections to your data sources.
            </BigidBody1>
          </div>
          <div className={classes.cardsContainer} data-aid="DataSourcesInitCards">
            {isPermitted(DATA_SOURCES_PERMISSIONS.IMPORT.name) && (
              <DataSourcesInitCard
                Icon={() => <BigidDataManagementIllustration size="small" />}
                title="Import"
                text={`Import a list of data source connection parameters from a JSON${
                  getApplicationPreference('DATA_SOURCE_CSV_IMPORT_VISIBLE') ? ' or CSV' : ''
                } file.`}
                onClick={() => {
                  if (!smallIdLicenseService.goToUpgradeIfLimitedSmallID()) {
                    $state.go(`dataSourcesInit`, {
                      path: DATA_SOURCES_INIT_VIEWS.importDataSources.path,
                    });
                  }
                }}
                dataAid="DataSourcesInitStartImport"
                locked={smallIdLicenseService.isSmallIDLimitedLicense()}
                size={isSmallScreen ? 'sm' : 'lg'}
              />
            )}
            <DataSourcesInitCard
              Icon={() => <BigidDataSourceIllustration size="small" />}
              title="Add a Data Source"
              text="Select from the supported data source list and set up a new connection."
              onClick={openSelectDataSourceOnboarding}
              dataAid="DataSourcesInitStartSelect"
              size={isSmallScreen ? 'sm' : 'lg'}
            />
            {isAutoDiscoveryAvailableInDs() && (
              <DataSourcesInitCard
                Icon={() => <BigidDataExplorerIllustration size="small" />}
                title="Cloud Services Discovery"
                text="Automatically discover AWS, GCP, Azure and NetApp Ontap data source"
                onClick={automaticallyDiscoverHandler}
                dataAid="DataSourcesInitStartAutoDiscovery"
                disabled={!isAutoDiscoveryAvailable}
                size={isSmallScreen ? 'sm' : 'lg'}
                disabledText={INSTALL_AUTO_DISCOVERY_DISABLED_MESSAGE}
              />
            )}
          </div>
        </div>
      )}
    </>
  );
};
