import React, { FunctionComponent } from 'react';
import angular from 'angular';
import styles from './BigidTooltipHelper.scss';
import { convertToAngular } from '../../../common/services/convertToAngular';
import { BigidHelpIcon } from '@bigid-ui/icons';

interface ToolTipProps {
  text: string;
}

export const BigidTooltipHelper: FunctionComponent<ToolTipProps> = ({ text }) => (
  <div className={styles.tooltip}>
    <BigidHelpIcon size="small" />
    <span className={styles.tooltiptext}>{text}</span>
  </div>
);

angular.module('app').component('bigidTooltipHelper', convertToAngular<ToolTipProps>(BigidTooltipHelper, ['text']));
