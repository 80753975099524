import { useCallback, useMemo, useRef, useEffect } from 'react';
import { v4 as uuid } from 'uuid';
import { dataSourceConnectionsService, dateUtils } from '../../../../services/angularServices';
import { UpdateDataSourceConfigState } from './useDataSourceConfigState';
import { DataSourceConnectionFormField, DataSourceConnectionTemplateFieldMisc, DsTypesEnum } from '../types';
import { prepareDataSourceFormValueDataForSend } from '../utils/prepareDataSourceFormValueDataForSend';
import { difference, uniq } from 'lodash';
import { DataSourceTestConnectionRow } from '../../DataSourcesTestConnectionGrid/DataSourcesTestConnectionGrid';
import { checkIsFieldVisible, checkIsMandatoryForTest } from '../utils/conditionUtils';
import { BigidFormRenderProps, FormValue } from '@bigid-ui/components';
import { AxiosError } from 'axios';
import type { QueryParamsAndDataObject } from '../../../../services/httpService';
import { EventEmitterDeregistrator } from '@bigid-ui/utils';
import { getFieldPropsFunc } from '../utils/fieldUtils';

export enum DataSourceTestConnectionStatusEnum {
  success = 'SUCCESS',
  failed = 'FAILURE',
  notStarted = 'notStarted',
}

export interface UseTestConnectionProps {
  updateState: UpdateDataSourceConfigState;
  fields: DataSourceConnectionFormField[];
  getValuesContainer: any;
  getFieldPropsFunction: React.MutableRefObject<BigidFormRenderProps['getFieldProps']>;
  isNewPassword?: boolean;
  encryptFields?: string[];
  initialValues: Record<string, FormValue>;
  config?: { query: QueryParamsAndDataObject };
  addListenerToTestConnectionEvent?: (sseEventId: string) => EventEmitterDeregistrator;
  getApplicationPreference: any;
  sseEventEmitter: any;
}

export interface TestConnectionResponseData {
  tablesResult?: DataSourceTestConnectionRow[];
  operationStatus: DataSourceTestConnectionStatusEnum;
  errMessage?: string;
  error?: string;
}

export interface TestConnectionResponse {
  data: TestConnectionResponseData;
}

interface CheckVerticalObjectFieldsChanged {
  values: Record<string, FormValue>;
  initialValues: Record<string, FormValue>;
  parentFieldName: string;
  misc: DataSourceConnectionTemplateFieldMisc;
}

export type UseTestConnectionHandlerConfig = {
  onSuccess?: (data?: unknown) => void;
  onError?: (errors?: unknown[], data?: unknown) => void;
  query?: { body?: Record<string, unknown> };
};

export type TestHandlerOptions = {
  shouldTriggerNotification?: boolean;
};

function checkIfValueReadyForTest(value: any, defaultValue: any, isNotReadyForTestIfValueDefault = false) {
  return value !== undefined && value !== '' && (!isNotReadyForTestIfValueDefault || value !== defaultValue);
}

function checkIfNestedFieldsReadyForTest(
  prevFilled: boolean,
  misc: DataSourceConnectionTemplateFieldMisc,
  value: any,
  getFieldPropsFunction: React.MutableRefObject<BigidFormRenderProps['getFieldProps']>,
) {
  return (
    prevFilled &&
    misc?.objectFields
      .filter(({ mandatoryForTest }) => mandatoryForTest)
      .every(nestedField => {
        const { name, type, mandatoryForTest, defaultValue, isNotReadyForTestIfValueDefault } = nestedField;
        const isVisible =
          nestedField?.ignoreVisibleInCheck ||
          checkIsFieldVisible({
            misc: nestedField,
            getFieldProps: getFieldPropsFunc(type, value, getFieldPropsFunction?.current),
          });

        return isVisible && mandatoryForTest
          ? checkIfValueReadyForTest(value && value[name], defaultValue, isNotReadyForTestIfValueDefault)
          : true;
      })
  );
}

const isNestedPassword = (misc: DataSourceConnectionTemplateFieldMisc): boolean => {
  if (misc.type === DsTypesEnum.verticalObject) {
    return misc.objectFields.some(element => element?.type === DsTypesEnum.password || element?.isSensitive);
  }
  return false;
};

const isAssembleConnectionMode = (values: Record<string, any>) => 'snapshot' === values?.connectionType?.[0]?.value;

export const useTestConnection = ({
  updateState,
  fields,
  getValuesContainer,
  getFieldPropsFunction,
  isNewPassword,
  encryptFields,
  initialValues,
  config,
  getApplicationPreference,
  sseEventEmitter,
}: UseTestConnectionProps) => {
  const fieldsMandatory = useMemo(
    () => fields.filter(({ misc }) => misc?.mandatoryForTest || misc?.mandatoryForTestIf),
    [fields],
  );
  const fieldsPassword = useMemo(
    () =>
      fields.filter(({ misc }) => misc?.type === DsTypesEnum.password || misc?.isSensitive || isNestedPassword(misc)),
    [fields],
  );

  const fieldsRelatedRef = useRef({ fieldsMandatory, fieldsPassword });
  const updateStateRef = useRef(updateState);
  const removeSSEListenerRef = useRef<() => void>();
  fieldsRelatedRef.current = { fieldsMandatory, fieldsPassword };
  updateStateRef.current = updateState;

  const updateTestAvailable = useCallback(
    (values: Record<string, any>) => {
      const isTestAvailable = fieldsMandatory.reduce((prevFilled, { name, misc }) => {
        if (isAssembleConnectionMode(values)) {
          return false;
        }

        const isMandatory = checkIsMandatoryForTest({ misc, getFieldProps: getFieldPropsFunction?.current });
        const value = values[name];
        const isVisible =
          misc?.ignoreVisibleInCheck || checkIsFieldVisible({ misc, getFieldProps: getFieldPropsFunction?.current });
        if (isVisible && isMandatory && misc.type === DsTypesEnum.verticalObject) {
          return checkIfNestedFieldsReadyForTest(prevFilled, misc, value, getFieldPropsFunction);
        }
        return (
          prevFilled &&
          (isVisible && isMandatory
            ? checkIfValueReadyForTest(value, misc?.defaultValue, misc?.isNotReadyForTestIfValueDefault)
            : true)
        );
      }, true);

      const nestedEncryptedFields: Record<string, string[]> = {};

      const { isNewPasswordUpdated, fieldsRequiresEncrypt } = fieldsRelatedRef.current?.fieldsPassword
        ?.filter(({ name, misc }) => {
          const isChanged =
            values[name] !== null && values[name] !== undefined && getFieldPropsFunction?.current(name)?.touched;
          if (isChanged && misc.type === DsTypesEnum.verticalObject) {
            const nestedChangedFields = checkIfNestedPasswordFieldsChanged({
              values,
              initialValues,
              parentFieldName: name,
              misc,
            });
            nestedEncryptedFields[misc.apiName] = uniq([
              ...(nestedEncryptedFields[misc.apiName] ?? []),
              ...nestedChangedFields.map(({ name }) => name),
            ]);
            return !!nestedChangedFields?.length;
          }
          return isChanged;
        })
        .reduce(
          (prevValue, { misc }) => {
            const nestedEncryptFields = nestedEncryptedFields[misc.apiName]?.map?.(name => `${misc.apiName}::${name}`);
            if (nestedEncryptFields?.length) {
              const nestedFieldsForAdd = difference(nestedEncryptFields, prevValue.fieldsRequiresEncrypt);
              return {
                isNewPasswordUpdated: true,
                fieldsRequiresEncrypt: [...prevValue.fieldsRequiresEncrypt, ...nestedFieldsForAdd],
              };
            }
            return {
              isNewPasswordUpdated: true,
              fieldsRequiresEncrypt: prevValue.fieldsRequiresEncrypt.includes(misc.apiName)
                ? prevValue.fieldsRequiresEncrypt
                : [...prevValue.fieldsRequiresEncrypt, misc.apiName],
            };
          },
          { isNewPasswordUpdated: isNewPassword, fieldsRequiresEncrypt: encryptFields || [] },
        );

      updateStateRef.current({
        isTestAvailable,
        isNewPassword: isNewPasswordUpdated,
        encryptFields: fieldsRequiresEncrypt,
      });
    },
    [fieldsMandatory, getFieldPropsFunction, isNewPassword, encryptFields, initialValues],
  );

  const typeToResultMap = useMemo(
    () => ({
      [DataSourceTestConnectionStatusEnum.failed]: (response: TestConnectionResponse) => {
        updateStateRef.current({
          testStatus: DataSourceTestConnectionStatusEnum.failed,
          lastTestDate: dateUtils.formatDate(new Date().toISOString()),
          testError: response?.data?.error,
          testInProgress: false,
          tablesResult: undefined,
          isScanSuccess: undefined,
          scanCompleteDate: undefined,
          scanStartDate: undefined,
          objectsFound: undefined,
        });
      },
      [DataSourceTestConnectionStatusEnum.success]: (response: TestConnectionResponse) => {
        updateStateRef.current({
          testStatus: DataSourceTestConnectionStatusEnum.success,
          lastTestDate: dateUtils.formatDate(new Date().toISOString()),
          testError: '',
          testInProgress: false,
          tablesResult: response?.data?.tablesResult,
          isScanSuccess: undefined,
          scanCompleteDate: undefined,
          scanStartDate: undefined,
          objectsFound: undefined,
        });
      },
      [DataSourceTestConnectionStatusEnum.notStarted]: () => void 0,
    }),
    [],
  );

  const testSync = useCallback(
    // fixed implicit any during react 18 migration
    (dsConnection: any, { onError, onSuccess }: UseTestConnectionHandlerConfig = {}) => {
      updateStateRef.current({ testInProgress: true });
      dataSourceConnectionsService
        .testDataSourceConnectionSync(
          {
            ds_connection: dsConnection,
            isNewPassword,
            isNewSessionToken: false,
            encryptFields,
            isScanSuccess: undefined,
            scanCompleteDate: undefined,
          },
          config?.query,
        )
        .then((response: TestConnectionResponse) => {
          const status = response?.data?.operationStatus || DataSourceTestConnectionStatusEnum.failed;
          typeToResultMap[status](response);

          status === DataSourceTestConnectionStatusEnum.failed
            ? onError?.([response?.data?.error], response?.data)
            : onSuccess?.(response.data);
        })
        .catch((error: AxiosError<any>) => {
          const errorMessage =
            error.response.data.errMessage ||
            error.response.data.error ||
            error.response.data.message ||
            'Test Connection Failed!';

          updateStateRef.current({
            testStatus: DataSourceTestConnectionStatusEnum.failed,
            lastTestDate: dateUtils.formatDate(new Date().toISOString()),
            testError: errorMessage,
          });
          onError?.([errorMessage]);
        })
        .finally(() => {
          updateStateRef.current({ testInProgress: false });
        });
    },
    [isNewPassword, encryptFields, config?.query, typeToResultMap],
  );

  const processTestConnectionResponse = useCallback(
    (results: TestConnectionResponseData | TestConnectionResponseData[], config: UseTestConnectionHandlerConfig) => {
      const testConnectionResponse = Array.isArray(results) ? results[0] : results;

      const status = testConnectionResponse?.operationStatus || DataSourceTestConnectionStatusEnum.failed;

      typeToResultMap[status]({ data: testConnectionResponse });

      status === DataSourceTestConnectionStatusEnum.failed
        ? config.onError?.([testConnectionResponse?.error], testConnectionResponse)
        : config.onSuccess?.(testConnectionResponse);
    },
    [typeToResultMap],
  );

  const testSSE = useCallback(
    // fixed implicit any during react 18 migration
    (requestData: any, handlers: UseTestConnectionHandlerConfig = {}, options: TestHandlerOptions = {}) => {
      updateStateRef.current({ testInProgress: true, testError: '' });
      dataSourceConnectionsService
        .testDataSourceConnection(
          requestData,
          (results: TestConnectionResponseData | TestConnectionResponseData[]) => {
            processTestConnectionResponse(results, handlers);
          },
          config?.query,
          options,
        )
        .then((removeListener: () => void) => {
          removeSSEListenerRef.current = removeListener;
        });
    },
    [config?.query, processTestConnectionResponse],
  );

  const addListenerToTestConnectionEvent = useCallback(
    (sseEventId: string, handlers: UseTestConnectionHandlerConfig = {}) => {
      updateStateRef.current({ testInProgress: true, testError: '' });

      const unregister = sseEventEmitter.addEventListener(sseEventId, ({ data: { results } }: any) => {
        unregister();
        processTestConnectionResponse(results, handlers);
      });
      removeSSEListenerRef.current = unregister;
      return unregister;
    },
    [processTestConnectionResponse],
  );

  useEffect(() => {
    return () => {
      removeSSEListenerRef?.current && removeSSEListenerRef?.current();
    };
  }, []);

  const getHighlightedWordsForLog = useCallback(() => {
    const values = getValuesContainer?.current();
    return uniq(
      fieldsRelatedRef.current?.fieldsMandatory.reduce(
        (acc, { misc: { apiName }, name }) => [
          ...acc,
          ...[(typeof values[name] !== 'object' && String(values[name])) || '', name || '', apiName || ''].filter(
            value => value?.length > 3,
          ),
        ],
        [],
      ),
    );
  }, [getValuesContainer]);

  const onTestHandler = useCallback(
    // fixed implicit any during react 18 migration
    (
      dsConnection: any,
      config?: UseTestConnectionHandlerConfig,
      options: TestHandlerOptions = { shouldTriggerNotification: true },
    ) => {
      const dsConnectionPrepared = prepareDataSourceFormValueDataForSend({
        fields,
        values: dsConnection,
        getFieldPropsFunction: getFieldPropsFunction.current,
        isTest: true,
      });
      if (getApplicationPreference('SSE_TEST_CONNECTION')) {
        testSSE(
          {
            ds_connection: dsConnectionPrepared,
            broadcastEvent: `${dsConnectionPrepared?.name}-test-connection-complete-${uuid()}`,
            isNewPassword,
            isNewSessionToken: false,
            isFirstAttempt: dsConnection?.isFirstAttempt,
            errorResolution: dsConnection?.errorResolution,
            preConnectionData: dsConnection?.preConnectionData,
            encryptFields,
            ...config?.query?.body,
            uiBaseUrl: window.location.origin,
          },
          config,
          options,
        );
      } else {
        testSync(dsConnectionPrepared, config);
      }
    },
    [fields, getFieldPropsFunction, isNewPassword, testSSE, testSync, encryptFields],
  );

  return {
    onTestHandler,
    updateTestAvailable,
    getHighlightedWordsForLog,
    addListenerToTestConnectionEvent,
  };
};

function checkIfNestedPasswordFieldsChanged({
  values,
  initialValues,
  parentFieldName,
  misc,
}: CheckVerticalObjectFieldsChanged) {
  return misc.objectFields.filter(({ type, isSensitive, name }) => {
    const isPasswordField = type === DsTypesEnum.password || isSensitive;
    return (
      isPasswordField &&
      values[parentFieldName][name] !== (initialValues?.[parentFieldName] as Record<string, FormValue>)?.[name] &&
      name
    );
  });
}
