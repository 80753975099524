import React, { FC, useState, useEffect } from 'react';
import { intersection, keys, isEmpty, isEqual, pick, differenceWith } from 'lodash';
import styled from '@emotion/styled';
import {
  PrimaryButton,
  BigidLoader,
  ExtraFieldItemData,
  TertiaryButton,
  BigidButtonTypography,
} from '@bigid-ui/components';
import { APPLICATIONS_PERMISSIONS } from '@bigid/permissions';
import { HashiCorpData, hashiCorpService } from '../../services/hashiCorpService';
import { ConfigInputItem } from './ConfigInputItem';
import { appsLicenseService } from '../../services/appsLicenseService';
import { isPermitted } from '../../services/userPermissionsService';
import { HashiCorpHeaders } from './HashiCorpHeaders';
import { closeSystemDialog } from '../../services/systemDialogService';
import { getCertificates } from '../CertificatesManagement/CertificatesManagement';
import { BigidConnectionIcon } from '@bigid-ui/icons';
import { notificationService } from '../../services/notificationService';
import { useLocalTranslation } from './translations';

const MANDATORY_TLS_SUFFIX = '/v1/auth/cert/login';

const FooterWrapper = styled('div')`
  padding-top: 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const RightButtons = styled('div')`
  display: flex;
`;

const TestConnectionButton = styled('div')`
  margin-right: 6px;
`;

const HeadersWrapper = styled('div')`
  max-height: 130px;
  overflow-y: auto;
`;

interface HashiCorpState {
  errorItems: Array<string>;
  oldConfiguration: HashiCorpData;
  newConfiguration: HashiCorpData;
  changedFields: Array<string>;
  [propName: string]: any;
}

enum HashiCopAuthMethods {
  APP_ROLE = 'appRole',
  TLS = 'tls',
}

const vaultUrlName = 'url';

const HASHICORP_BASE_ITEMS: ConfigurationFields[] = [
  { key: 'name', title: 'Connection Name' },
  {
    key: 'auth_method',
    title: 'Authentication Method',
    options: [
      { label: HashiCopAuthMethods.APP_ROLE, value: HashiCopAuthMethods.APP_ROLE },
      { label: 'TLS', value: HashiCopAuthMethods.TLS },
    ],
    isMultiSelect: true,
  },
  { key: vaultUrlName, title: 'Vault URL' },
];

const HASHCORP_APP_ROLE_ITEMS: ConfigurationFields[] = [
  { key: 'role_id', title: 'Role ID', isPassword: false },
  { key: 'secret_id', title: 'Secret ID', isPassword: true, isNotRequired: true },
];

const HASHICORP_TLS_ITEMS: ConfigurationFields[] = [
  { key: 'client_cert', title: 'Client Certificate', isMultiSelect: true },
  { key: 'client_cert_key', title: 'Client Certificate Key', isMultiSelect: true },
  { key: 'certificate_role_name', title: 'Client Role Name', isNotRequired: true },
];

interface ConfigurationFields {
  key: string;
  title: string;
  options?: { label: string; value: HashiCopAuthMethods }[];
  isPassword?: boolean;
  isNotRequired?: boolean;
  isMultiSelect?: boolean;
}

interface Certs {
  label: string;
  value: string;
}

const createNewItem = () => ({
  id: String(new Date().getTime()),
  value: {
    field_name: '',
    field_value: '',
  },
});

const initialState: HashiCorpState = {
  newConfiguration: {
    name: '',
    auth_method: 'appRole',
    url: '',
    bind_secret_id: false,
    secret_id: '',
    role_id: '',
    client_cert: '',
    client_cert_key: '',
    certificate_role_name: '',
  },
  errorItems: [],
  oldConfiguration: null,
  changedFields: [],
};

export const HashiCorpDialogComponent: FC = () => {
  const { t } = useLocalTranslation('HashiCorpForm');
  const [state, setState] = useState<HashiCorpState>(initialState);
  const [shouldFetchState, setShouldFetchState] = useState(true);
  const [isFetching, setIsFetching] = useState(false);
  const [certificates, setCertificates] = useState<Certs[]>([]);
  const [configurationFields, setConfigurationFields] = useState<ConfigurationFields[]>([
    ...HASHICORP_BASE_ITEMS,
    ...HASHCORP_APP_ROLE_ITEMS,
  ]);
  const [headerFields, setHeaderFields] = useState<ExtraFieldItemData[]>([createNewItem()]);
  const [savedHeaderFields, setSavedHeaderFields] = useState<ExtraFieldItemData[]>(headerFields);

  const handleHeaderFieldChange = (headerFieldsValue: ExtraFieldItemData[]): void => {
    setHeaderFields([...headerFieldsValue]);
  };

  const isHeaderFieldsChange = () => {
    return differenceWith(savedHeaderFields, headerFields, isHeaderFieldsEqual).length !== 0;
  };

  const isHeaderFieldsEqual = (a: ExtraFieldItemData, b: ExtraFieldItemData) => {
    const {
      id: oldId,
      value: { field_name: oldFieldName, field_value: oldFieldValue },
    } = a;
    const {
      id: newId,
      value: { field_name: newFieldName, field_value: newFieldValue },
    } = b;
    return oldId === newId && oldFieldName === newFieldName && oldFieldValue === newFieldValue;
  };

  useEffect(() => {
    appsLicenseService.showAppExpirationNotification('hashiCorp');
  }, []);

  useEffect(() => {
    const fetchInitialData = async () => {
      try {
        setIsFetching(true);
        const configuration = await hashiCorpService.getConfiguration();

        const headerFieldsToUpdate = configuration?.header_fields;
        if (headerFieldsToUpdate) {
          setHeaderFields(headerFieldsToUpdate);
          setSavedHeaderFields(headerFieldsToUpdate);
        }

        setState({
          ...state,
          ...(configuration && { oldConfiguration: configuration, newConfiguration: configuration }),
        });

        const { results = [] } = await getCertificates('');
        const systemCertificates = results
          .filter(({ type }) => type === 'pem')
          .map(({ name }) => ({ label: name, value: name }));

        setCertificates(systemCertificates);
      } catch (error) {
        notificationService.error(t('fetchError'));
      }
      setShouldFetchState(false);
      setIsFetching(false);
    };

    const shouldFetchInitialData = shouldFetchState && !isFetching && isEqual(state, initialState);
    if (shouldFetchInitialData) {
      fetchInitialData();
    }
  }, [state, shouldFetchState, isFetching, t]);

  useEffect(() => {
    const { newConfiguration } = state;
    const appRoleFields = [...HASHICORP_BASE_ITEMS, ...HASHCORP_APP_ROLE_ITEMS];
    const tlsFields = [...HASHICORP_BASE_ITEMS, ...HASHICORP_TLS_ITEMS];

    const isAppRole = newConfiguration.auth_method === HashiCopAuthMethods.APP_ROLE;

    setConfigurationFields(isAppRole ? appRoleFields : tlsFields);
  }, [state]);

  const updateChangedFields = (): void => {
    const changedFields = configurationFields
      .filter(item => {
        return state.oldConfiguration
          ? state.newConfiguration[item.key] !== state.oldConfiguration[item.key]
          : state.newConfiguration[item.key] !== initialState.newConfiguration[item.key];
      })
      .map(({ key }) => key);
    if (changedFields.includes('secret_id')) {
      changedFields.push('bind_secret_id');
    }
    if (!isEqual(changedFields, state.changedFields)) {
      setState({ ...state, changedFields });
    }
  };

  useEffect(updateChangedFields, [configurationFields, state]);

  const isFormValid = () => {
    const errorItems = getFieldsWithErrors();
    setState({ ...state, errorItems });
    return errorItems.length === 0;
  };

  const getFieldsWithErrors = () => {
    return configurationFields
      .filter(({ isNotRequired, key }) => !isNotRequired && !state.newConfiguration[key])
      .map(({ key }) => key);
  };

  const addHeaderFieldsToConf = (conf: any) => {
    if (conf?.auth_method === HashiCopAuthMethods.TLS) {
      return { ...conf, header_fields: headerFields };
    }
    return { ...conf };
  };

  const saveConfiguration = () => {
    const { newConfiguration, oldConfiguration } = state;
    const confToSave = removeIrrelevantFieldsBeforeSave(newConfiguration);

    if (isFormValid()) {
      if (oldConfiguration) {
        const updatedFields = intersection(state.changedFields, keys(confToSave));
        updatedFields.push('auth_method');
        const configuration = pick(confToSave, updatedFields);
        const configurationToProceed = { ...confToSave, id: newConfiguration._id };
        hashiCorpService.updateConfiguration({
          configuration: addHeaderFieldsToConf(configuration),
          id: newConfiguration._id,
        });
        setState({ ...state, errorItems: [], changedFields: [], oldConfiguration: configurationToProceed });
        setSavedHeaderFields(headerFields);
      } else {
        hashiCorpService.createConfiguration(addHeaderFieldsToConf(confToSave), id => {
          const configuration = { ...confToSave, _id: id };
          setState({
            ...state,
            errorItems: [],
            changedFields: [],
            oldConfiguration: configuration,
            newConfiguration: configuration,
          });
          setSavedHeaderFields(headerFields);
        });
      }
    }
  };

  const removeIrrelevantFieldsBeforeSave = (newConfiguration: HashiCorpData) => {
    const isAppRole = newConfiguration.auth_method === HashiCopAuthMethods.APP_ROLE;
    const authConfFields = isAppRole
      ? [...HASHCORP_APP_ROLE_ITEMS, { key: 'bind_secret_id', title: '' }]
      : HASHICORP_TLS_ITEMS;
    return Object.entries(newConfiguration).reduce((result, [key, value]) => {
      if (isKeyInConfType(HASHICORP_BASE_ITEMS, key) || isKeyInConfType(authConfFields, key)) {
        result[key] = value;
      }
      return result;
    }, {} as HashiCorpData);
  };

  const isKeyInConfType = (conf: ConfigurationFields[], keyToFind: string) => {
    return conf.find(({ key }) => key === keyToFind);
  };

  const testConnection = () => {
    const {
      newConfiguration: { _id, ...configuration },
    } = state;
    if (isFormValid()) {
      hashiCorpService.testConfiguration({ ...addHeaderFieldsToConf(configuration) });
      setState({ ...state, errorItems: [] });
    }
  };

  const handleChange = (name: string, value: string) => {
    if (name === 'secret_id') {
      const hasSecretId = value !== '';
      setState({
        ...state,
        newConfiguration: { ...state.newConfiguration, [name]: value, bind_secret_id: hasSecretId },
      });
    } else {
      setState({ ...state, newConfiguration: { ...state.newConfiguration, [name]: value } });
    }
    updateChangedFields();
  };

  const isSavePermitted = state.oldConfiguration
    ? isPermitted(APPLICATIONS_PERMISSIONS.EDIT_HASHICORP.name)
    : isPermitted(APPLICATIONS_PERMISSIONS.CREATE_HASHICORP.name);

  const isAuthenticationModeTLS = state.newConfiguration.auth_method === HashiCopAuthMethods.TLS;

  return (
    <>
      {isFetching ? (
        <BigidLoader position="relative" />
      ) : (
        <>
          {configurationFields.map(({ key, title, isPassword, isMultiSelect, isNotRequired, options }) => (
            <ConfigInputItem
              key={key}
              title={title}
              field={key}
              hasError={state.errorItems.includes(key)}
              onChange={handleChange}
              value={state.newConfiguration[key]}
              isPassword={isPassword}
              isRequired={!isNotRequired}
              options={isMultiSelect ? options || certificates : null}
              isMultiSelect={isMultiSelect}
              subText={
                isAuthenticationModeTLS &&
                key === vaultUrlName &&
                `${t('vaultUrlDescription')} "${MANDATORY_TLS_SUFFIX}"`
              }
            />
          ))}
          {isAuthenticationModeTLS && (
            <>
              <BigidButtonTypography>Request Headers</BigidButtonTypography>
              <HeadersWrapper>
                <HashiCorpHeaders
                  initialData={savedHeaderFields}
                  onChange={handleHeaderFieldChange}
                  createNewItem={createNewItem}
                />
              </HeadersWrapper>
            </>
          )}
        </>
      )}
      <FooterWrapper>
        <TertiaryButton dataAid={'hashi-corp-dialog-cancel'} onClick={closeSystemDialog} size="medium" text="Cancel" />
        <RightButtons>
          {isPermitted(APPLICATIONS_PERMISSIONS.TEST_CONNECTION_HASHICORP.name) && (
            <TestConnectionButton>
              <PrimaryButton
                dataAid={'hashi-corp-dialog-test-connection'}
                onClick={testConnection}
                size="medium"
                startIcon={<BigidConnectionIcon />}
                text="Test Connection"
              />
            </TestConnectionButton>
          )}
          {isSavePermitted && (
            <PrimaryButton
              dataAid={'hashi-corp-dialog-save'}
              onClick={saveConfiguration}
              size="medium"
              disabled={(isEmpty(state.changedFields) && !isHeaderFieldsChange()) || getFieldsWithErrors().length !== 0}
              text="Save"
            />
          )}
        </RightButtons>
      </FooterWrapper>
    </>
  );
};
