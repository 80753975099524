import styled from '@emotion/styled';
import { BigidColorsV2 } from '@bigid-ui/components';

export const HeaderIcon = styled.div({
  height: 26,
  width: 24,
});

export const ButtonsWrapper = styled.div({
  paddingLeft: 80,
  alignItems: 'center',
  display: 'flex',
  cursor: 'pointer',
});

export const NoData = styled('div')<{ width: number }>`
  background-color: ${BigidColorsV2.gray['100']};
  height: 64px;
  width: ${({ width }) => width}px;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  color: ${BigidColorsV2.gray['600']};
`;
