import React, { FC, useContext, useMemo, useRef, useState } from 'react';
import {
  BigidBody1,
  BigidButtonIcon,
  BigidDialog,
  BigidDialogProps,
  BigidFormFieldText,
  EntityEvents,
  PrimaryButton,
  TertiaryButton,
} from '@bigid-ui/components';
import { BigidDuplicateIcon } from '@bigid-ui/icons';
import { AutoDiscoveryConfigModel } from '../../AutoDiscoveryGrid/AutoDiscoveryGridConfiguration';
import { generatedUniquePresetName } from '../../../CustomApp/views/CustomAppActions/CustomAppActions';
import { customAppService } from '../../../../services/customAppService';
import { notificationService } from '../../../../services/notificationService';
import { AutoDiscoveryWizardContext } from '../autoDiscoveryWizardContext';
import { commonTranslate } from '../../../../translations';
import { noop } from 'lodash';
import { useLocalTranslation } from '../../translations';
import { TFunction } from 'react-i18next';

export const DuplicatePresetWithConfirm: FC = () => {
  const { t } = useLocalTranslation('AutoDiscovery');
  const { discoveryConfigData } = useContext(AutoDiscoveryWizardContext);
  const [name, setName] = useState('');
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const nameRef = useRef(name);
  nameRef.current = name;

  const dialogConfig = useMemo(
    () => ({
      title: 'Duplicate Onboarding',
      isOpen,
      maxWidth: 'xs' as BigidDialogProps['maxWidth'],
      onClose: () => setIsOpen(false),
      buttons: [
        {
          text: commonTranslate('Cancel'),
          component: TertiaryButton,
          onClick: noop,
          isClose: true,
        },
        {
          text: 'Duplicate',
          component: PrimaryButton,
          disabled: !name || name === discoveryConfigData.preset.name,
          onClick: () =>
            duplicateAction(
              {
                appId: discoveryConfigData.configData.appId,
                name: discoveryConfigData.preset.name,
                description: discoveryConfigData.preset.description,
                paramsKeyValue: discoveryConfigData.preset.paramsKeyValue,
                overrideGlobalParams: discoveryConfigData.preset.overrideGlobalParams,
                globalParamsKeyValue: discoveryConfigData.preset.globalParamsKeyValue,
                appRunAction: discoveryConfigData.preset.appRunAction,
              } as AutoDiscoveryConfigModel,
              nameRef.current,
              t,
            ),
          isClose: true,
        },
      ],
    }),
    [
      discoveryConfigData.configData.appId,
      discoveryConfigData.preset.appRunAction,
      discoveryConfigData.preset.description,
      discoveryConfigData.preset.globalParamsKeyValue,
      discoveryConfigData.preset.name,
      discoveryConfigData.preset.overrideGlobalParams,
      discoveryConfigData.preset.paramsKeyValue,
      isOpen,
      name,
      t,
    ],
  );
  const text = t('configAdvanced.duplicate.dialogBody', { name: discoveryConfigData?.preset?.name });

  return (
    <>
      <BigidButtonIcon icon={BigidDuplicateIcon} onClick={() => setIsOpen(true)} dataAid="duplicate" />
      <BigidDialog {...dialogConfig}>
        <div>
          <BigidBody1 sx={{ marginBottom: '16px' }}>{text}</BigidBody1>
          <BigidFormFieldText setValue={setName} value={name} label={'New preset name'} placeholder={'Preset name'} />
        </div>
      </BigidDialog>
    </>
  );
};

export const duplicateAction = async (
  {
    appId,
    name,
    description,
    paramsKeyValue,
    overrideGlobalParams,
    globalParamsKeyValue,
    appRunAction,
  }: AutoDiscoveryConfigModel,
  inputName: string,
  t: TFunction,
) => {
  const nameForCloning = name !== inputName ? inputName : generatedUniquePresetName(`Clone - ${name}`, []);
  try {
    const presetData = {
      name: nameForCloning,
      description,
      paramsKeyValue,
      overrideGlobalParams,
      globalParamsKeyValue: overrideGlobalParams ? globalParamsKeyValue : undefined,
    };
    await customAppService.addActionPreset(appId, appRunAction._id, presetData);

    notificationService.success(t('configAdvanced.duplicate.success', { name }), { shouldCloseOnTransition: false });
  } catch (err) {
    console.warn(err);
    notificationService.error(t('configAdvanced.duplicate.error'));
  }
  return {
    shouldGridReload: true,
    shouldClearSelection: true,
    entityEventToEmit: EntityEvents.RELOAD,
  };
};
