import React, { FC, memo, useContext, useEffect, useState } from 'react';
import {
  BigidFieldRenderProps,
  BigidFormRenderProps,
  compareObjectsExceptFunctions,
  BigidFormFieldSideSelect,
} from '@bigid-ui/components';
import { fieldConditionCheck, getOneFieldDataForCondition } from '../utils/conditionUtils';
import { DataSourceOption } from '../types';
import { DataSourceConfigurationContext } from '../DataSourceConfigurationContext';

type BigidAuthenticationTypeFieldRenderProps = Omit<BigidFieldRenderProps, 'options'> & {
  options: DataSourceOption[];
  getFieldProps: BigidFormRenderProps['getFieldProps'];
};

type SelectProps = {
  value?: DataSourceOption[];
  options?: DataSourceOption[];
};

const serializeSelectProps = (props: SelectProps) => btoa(JSON.stringify(props));

export const FormAuthenticationTypeField: FC<BigidFieldRenderProps> = memo(props => {
  const { options, getFieldProps, value, setValue } = props as BigidAuthenticationTypeFieldRenderProps;
  const state = useContext(DataSourceConfigurationContext);
  const [selectProps, setSelectProps] = useState<SelectProps>({});

  useEffect(() => {
    const selectOptions = options.filter(option =>
      fieldConditionCheck(true, option.visibleIf, field => getOneFieldDataForCondition(field, getFieldProps)),
    );
    const selectValue = selectOptions.find(option => option.value === value?.[0]?.value) ?? selectOptions[0];

    if (value?.[0]?.value !== selectValue.value) {
      setValue([selectValue]);
    }
    setSelectProps({ options: selectOptions, value: selectValue ? [selectValue] : [selectOptions[0]] });
  }, [state.connectionType, value]);

  return (
    <BigidFormFieldSideSelect
      key={serializeSelectProps(selectProps)}
      {...props}
      options={selectProps.options}
      value={selectProps.value}
    />
  );
}, compareObjectsExceptFunctions);

FormAuthenticationTypeField.displayName = 'FormAuthenticationTypeField';
