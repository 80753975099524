import { DsConnectionFieldSectionsEnum } from '../types';
import { mapScanTemplatesToScanActions } from '../mappers/wizard';
import { runCustomScanProfile, runDefaultScanProfile } from '../../DataSourceConnectionModal/services/scan';
import { useInstantScanData } from '../../DataSourceConnections/hooks/useInstantScanData';
import type { BigidTabsItem } from '@bigid-ui/components';
import type { ScanTemplate, ScanTypes } from '../../../Scans/ScanTemplates/scanTemplateTypes';
import type { UseLocalTranslationGetter } from '../../DataSourceConnectionModal/translations';

type UseInstantScanDataReturnType = ReturnType<typeof useInstantScanData>;
type UseInstantScanExecutor = UseInstantScanDataReturnType['executeQuickScan'];
type ScanProfileActionOpts = {
  onSuccess?: () => void;
  onError?: () => void;
};

export type ScanAction = ReturnType<typeof generateScanActions> extends Array<infer T> ? T :never;

export const getWizardLayoutTabs = (t: UseLocalTranslationGetter): BigidTabsItem[] => [
  {
    id: DsConnectionFieldSectionsEnum.connection,
    label: t('tabs.connection'),
  },
  {
    id: DsConnectionFieldSectionsEnum.details,
    label: t('tabs.details'),
  },
  {
    id: DsConnectionFieldSectionsEnum.scanSettings,
    label: t('tabs.scanSettings'),
  },
];

export const generateScanActions = (
  sourceId: string,
  templates: ScanTemplate[],
  execute: UseInstantScanExecutor,
  scanTypes: ScanTypes[],
) => {
  return mapScanTemplatesToScanActions(templates).map(({ id, ...rest }) => ({
    id,
    execute: () => execute(sourceId, id, { filterByScanType: scanTypes }),
    ...rest,
  }));
};

export const generateScanProfileActions = (
  dataSourceName: string,
  dataSourceType: string,
  { onError, onSuccess }: ScanProfileActionOpts,
) => [
  {
    id: 'default',
    label: 'default',
    execute: () => runDefaultScanProfile(dataSourceName, dataSourceType, { onError, onSuccess }),
  },
  { id: 'custom', label: 'custom', execute: () => runCustomScanProfile(dataSourceName, dataSourceType) },
];
