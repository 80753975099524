import React from 'react';
import { BigidBody1, BigidLink } from '@bigid-ui/components';
import { docsUrls } from '../../../../config/publicUrls';

export const ViewDetailsDocumentation = () => {
  return (
    <div>
      <BigidBody1>Scan insights can&apos;t be retrieved.</BigidBody1>
      <BigidBody1>
        {' '}
        For more information, see{' '}
        <BigidLink shouldOpenNewTab href={docsUrls.SCANS_BEST_PRACTICE} text="Scanning best practices" />
      </BigidBody1>
    </div>
  );
};
