import { module } from 'angular';
import { CredentialTypes } from '../credentials/credentials.component';
import { $injector } from 'ngimport';
const app = module('app');

export let cyberArkService;

app.service('cyberArkService', function ($http, appSettings) {
  'ngInject';

  const multiPartConfig = {
    transformRequest: angular.identity,
    headers: {
      'Content-Type': undefined,
    },
  };

  return {
    getCyberArkConfigItem: id => {
      const query = id ? `/${id}` : `?type=${CredentialTypes.CyberArk}&limit=1`;
      return $http
        .get(`${appSettings.serverPath}/${appSettings.version}/credentials-providers${query}`)
        .then(response => response.data);
    },

    getAllCyberArkCredentialProviders: () => {
      return $http
        .get(`${appSettings.serverPath}/${appSettings.version}/credentials-providers?type=${CredentialTypes.CyberArk}`)
        .then(response => response.data);
    },

    createCyberArkConfigItem: formData => {
      return $http
        .post(`${appSettings.serverPath}/${appSettings.version}/credentials-providers`, formData, multiPartConfig)
        .then(response => response.data);
    },

    updateCyberArkConfigItem: (id, formData) => {
      return $http
        .put(`${appSettings.serverPath}/${appSettings.version}/credentials-providers/${id}`, formData, multiPartConfig)
        .then(response => response.data);
    },

    testCyberArkConnection: formData =>
      $http
        .post(
          `${appSettings.serverPath}/${appSettings.version}/credentials-providers/connection/test`,
          formData,
          multiPartConfig,
        )
        .then(response => response.data),
  };
});

app.run(() => {
  cyberArkService = $injector.get('cyberArkService');
});
