import React, { FC, memo } from 'react';
import {
  BigidFieldRenderProps,
  compareObjectsExceptFunctions,
  BigidLoader,
  BigidFormFieldSideSelect,
} from '@bigid-ui/components';
import { ACCESS_MANAGEMENT_PERMISSIONS } from '@bigid/permissions';
import { isPermitted } from '../../../../services/userPermissionsService';
import { useScopeOptions } from '../hooks/useScopeOptions';

export const FormScopeField: FC<BigidFieldRenderProps> = memo(props => {
  const { isLoading, options, value, showOnCreateTooltip, setValueAndUpdateFilled } = useScopeOptions(props.setValue);
  const isCanEdit = isPermitted(ACCESS_MANAGEMENT_PERMISSIONS.MANAGE.name);
  const tooltipText = isCanEdit
    ? (showOnCreateTooltip &&
        'If no scopes are selected, all of the scopes that are available to you by your roles assigned automatically') ||
      ''
    : `You don\'t have the required permissions to view this field, please contact the administrator to gain access`;

  return isLoading ? (
    <BigidLoader />
  ) : (
    <BigidFormFieldSideSelect
      {...props}
      misc={{ ...props.misc, isMulti: true }}
      options={options}
      value={value}
      setValue={setValueAndUpdateFilled}
      disabled={props.disabled || !isPermitted(ACCESS_MANAGEMENT_PERMISSIONS.MANAGE.name)}
      tooltipText={tooltipText}
    />
  );
}, compareObjectsExceptFunctions);

FormScopeField.displayName = 'FormScopeField';
