import React, { FC, Suspense, lazy, useEffect, useState, useTransition } from 'react';
import { CallBackProps, STATUS } from 'react-joyride';
import { BigidLoader } from '@bigid-ui/components';
import { HeaderEvents, headerEventEmitter } from '../../../services/eventEmitters/headerEvents';

const NavigationGuidedTourComponent = lazy(() =>
  import(/* webpackChunkName: "NavigationGuidedTour" */ './NavigationGuidedTour').then(module => ({
    default: module.NavigationGuidedTour,
  })),
);

export const NavigationGuidedTourLazy: FC = () => {
  const [, startTransition] = useTransition();
  const [isComponentLoaded, setIsComponentLoaded] = useState(false);
  const [isTourRunning, setIsTourRunning] = useState(false);

  useEffect(() => {
    const unregister = headerEventEmitter.addEventListener(HeaderEvents.SHOW_NAVIGATION_GUIDED_TOUR, () => {
      if (!isComponentLoaded) {
        startTransition(() => {
          setIsComponentLoaded(true);
        });
      }

      setIsTourRunning(true);
    });

    return () => {
      unregister();
    };
  }, [isComponentLoaded]);

  const joyrideCallback = ({ status }: CallBackProps) => {
    if (status === STATUS.FINISHED || status === STATUS.SKIPPED) {
      setIsTourRunning(false);
    }
  };

  return (
    <>
      {isComponentLoaded && (
        <Suspense fallback={<BigidLoader position="fixed" />}>
          <NavigationGuidedTourComponent isTourRunning={isTourRunning} joyrideCallback={joyrideCallback} />
        </Suspense>
      )}
    </>
  );
};
