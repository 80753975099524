import styled from '@emotion/styled';
import { BigidBody1 } from '@bigid-ui/components';

export const AutoDiscoveryInfoList = styled('ul')`
  padding-inline-start: 16px;
  padding-top: 12px;
`;

export const AutoDiscoveryInfoListOrdered = styled('ol')`
  display: flex;
  flex-direction: column;
  padding-inline-start: 16px;
  gap: 4px;
`;

export const AutoDiscoveryInfoListOrderedSpecial = styled('ol')`
  margin: 0;
  padding: 0;
  list-style-type: none;
`;

export const AutoDiscoveryInfoListItemSpecial = styled('li')`
  counter-increment: step-counter;
  margin-bottom: 0;
  margin-top: 12px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  ::before {
    content: counter(step-counter);
    margin-right: 6px;
    background-color: ${({ theme }) => theme.palette.bigid.primary100};
    color: ${({ theme }) => theme.palette.bigid.primary900};
    min-height: 24px;
    min-width: 24px;
    border-radius: 4px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    font-weight: 600;
    font-size: 12px;
  }
`;

export const AutoDiscoveryInfoListItem = styled('li')`
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0;
  text-align: left;
  padding-bottom: 4px;
`;

export const AutoDiscoverWizardAsideItemTitle = styled.div`
  min-height: 54px;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  gap: 0.25rem;
  align-items: center;
`;

export const AutoDiscoveryInfoListItemText = styled(BigidBody1)`
  display: inline;
  margin-right: 4px;
  white-space: pre-wrap;
`;
