// [DA]: This sample covers most of the functional needs for this tasks: http://gojs.net/latest/samples/decisionTree.html
import './identity-lineage-graph.component.scss';
import { module } from 'angular';
import template from './identity-lineage-graph.component.html';

const app = module('app');
app.component('identityLineageGraph', {
  template,
  controllerAs: 'identityLineageModel',
  controller: [
    '$element',
    '$state',
    function ($element, $state) {
      const $ = go.GraphObject.make;

      const NODES_MINIMUM_DISTANCE = 100;

      const identityLineageModel = this;

      identityLineageModel.triggerGraphUpdate = false;

      const goToIdConnection = (e, obj) => {
        const { attachedIdConnectionName } = obj.part.data;

        if (attachedIdConnectionName) {
          $state.go('newEntityConnection', { id: attachedIdConnectionName });
        }
      };

      identityLineageModel.$onChanges = () => {
        if (identityLineageModel.diagram && this.nodesData) {
          identityLineageModel._initDiagramModel(this.nodesData);

          if (identityLineageModel.triggerGraphUpdate) {
            identityLineageModel.diagram.requestUpdate();
          }
        }
      };

      identityLineageModel.$onInit = () => {
        identityLineageModel.diagram = $(go.Diagram, $element[0].querySelector('.chart-div'), {
          initialContentAlignment: go.Spot.Left,
          allowSelect: false,
          layout: $(go.TreeLayout, {
            layerSpacing: NODES_MINIMUM_DISTANCE,
            sorting: go.TreeLayout.SortingAscending,
            comparer: (a, b) => {
              if (a.node.data.isGroup && b.node.data.isGroup) {
                const [al] = a.node
                  .findSubGraphParts()
                  .toArray()
                  .map(node => node.findLinksInto().first())
                  .filter(x => x);
                const [bl] = b.node
                  .findSubGraphParts()
                  .toArray()
                  .map(node => node.findLinksInto().first())
                  .filter(x => x);
                return al && bl ? al.data.from.localeCompare(bl.data.from) : al ? 1 : bl ? -1 : 0;
              } else {
                return 0;
              }
            },
          }),
        });

        identityLineageModel.diagram.nodeTemplateMap.add('attribute', identityLineageModel._attributeNodeTemplate());
        identityLineageModel.diagram.groupTemplateMap.add('table', identityLineageModel._tableGroupTemplate());
        identityLineageModel.diagram.nodeTemplateMap.add(
          'table-column',
          identityLineageModel._tableColumnNodeTemplate(),
        );
      };

      identityLineageModel._initDiagramModel = data => {
        const nodes = [
          ...data.map(item => ({
            key: `000${item._id}`.slice(-3),
            category: item.type,
            categoryName: item.category,
            title: item.title,
            schema: item.schema,
            schemaColor: item.schemaColor,
            isGroup: item.type === 'table',
            attributeCount: item.type === 'table' ? item.attributes.length : undefined,
            isExpanded: item.type === 'table' ? false : undefined,
            attachedIdConnectionName: item.attachedIdConnectionName ? item.attachedIdConnectionName : '',
          })),
          ...data
            .filter(item => item.type === 'table')
            .map(table =>
              table.attributes.map((attribute, idx) => ({
                key: `${`000${table._id}`.slice(-3)}_${`000${idx + 1}`.slice(-3)}`,
                category: 'table-column',
                title: attribute.name,
                tableName: table.title,
                isClickable:
                  table.attributesConnectedToTables &&
                  table.attributesConnectedToTables.length > 0 &&
                  table.attributesConnectedToTables.indexOf(attribute.name) > -1,
                numberOfLinkForTables: attribute.linkToOtherTables ? attribute.linkToOtherTables : 0,
                tooltip:
                  table.tooltip[idx] && table.tooltip[idx].trim().length > 0
                    ? table.tooltip[idx].trim()
                    : 'There are no attributes',
                hasAttribute: table.hasAttribute[idx],
                group: `${`000${table._id}`.slice(-3)}`,
                isShown: idx < 10,
              })),
            )
            .reduce((agg, arr) => [...agg, ...arr], []),
        ];

        const links = identityLineageModel.linksData || [{}];

        this.diagram.linkTemplate = $(
          go.Link,
          {
            routing: go.Link.Normal,
            curve: go.Link.JumpGap,
          },
          $(go.Shape, {
            stroke: '#ff5e43',
            strokeWidth: 1.5,
          }),
          $(go.Shape, { toArrow: 'Standard', stroke: '#ff5e43', fill: '#ff5e43' }),
          $(go.Shape, { fromArrow: 'BackwardTriangle', stroke: '#ff5e43', fill: '#ff5e43' }),
          $(
            go.Panel,
            'Auto',
            new go.Binding('visible', 'text', text => text !== null),
            $(go.Shape, 'RoundedRectangle', { fill: '#ffffff', stroke: '#ff5e43', strokeWidth: 1.5 }),
            $(go.TextBlock, { margin: 5, font: "14px 'Muli', sans-serif" }, new go.Binding('text', 'text')),
          ),
        );

        identityLineageModel.diagram.model = $(go.GraphLinksModel, {
          linkFromPortIdProperty: 'fromPort', // required information:
          linkToPortIdProperty: 'toPort', // identifies data property names
          nodeDataArray: nodes,
          linkDataArray: links,
        });

        identityLineageModel.diagram.model.addChangedListener(e => {
          if (e.propertyName === 'search') {
            this.diagram.startTransaction('Search');
            const data = e.object;
            const value = e.newValue;
            const group = this.diagram.findPartForData(data);
            const columns = group.findSubGraphParts().toArray();
            group.data.isExpanded = false;
            columns.forEach((column, idx) => {
              column.data.isShown =
                column.findLinksConnected().count > 0 ||
                !value ||
                column.data.title.toLowerCase().indexOf(value.toLowerCase()) !== -1;
            });
            this.diagram.commitTransaction('Search');
            this.diagram.updateAllTargetBindings(); // TODO: [DA]: Figure out why commiting the transaction doesn't update bindings.
          }
        });
        identityLineageModel.diagram.layoutDiagram();
      };

      //Attribute
      identityLineageModel._attributeNodeTemplate = () => {
        return $(
          go.Node,
          'Vertical',
          {
            width: 195,
            height: 60,
            selectionAdorned: false,
            isShadowed: true,
            shadowColor: '#3371b3',
            shadowBlur: 10,
            shadowOffset: go.Point.parse('3 3'),
            mouseEnter: (e, shape, prev) => {
              shape.part.shadowColor = '#ff5e43';
              shape.part.shadowBlur = 20;

              shape.findObject('nodeWrapper').stroke = '#ff5e43';
              shape.findObject('nodeImageBase').fill = '#ff5e43';
              shape.findObject('nodeImageShape').fill = '#ffffff';
              shape.findObject('nodeImageShape').stroke = '#ffffff';
              shape.findObject('nodeTextPrimary').stroke = '#ff5e43';
              shape.findObject('nodeTextSecondary').stroke = '#ff5e43';
              shape.cursor = 'pointer';
            },
            mouseLeave: (e, shape, prev) => {
              shape.part.shadowColor = '#3371b3';
              shape.part.shadowBlur = 10;

              shape.findObject('nodeWrapper').stroke = '#ffffff';
              shape.findObject('nodeImageBase').fill = '#f1f3f7';
              shape.findObject('nodeImageShape').fill = '#ff5e43';
              shape.findObject('nodeImageShape').stroke = '#ff5e43';
              shape.findObject('nodeTextPrimary').stroke = '#262D37';
              shape.findObject('nodeTextSecondary').stroke = '#262D37';
            },
            click: (event, shape) => {
              const entity = shape.part.data;

              this.diagram.findTopLevelGroups().each(function (g) {
                g.memberParts.each(function (p) {
                  if (p instanceof go.Node) {
                    const tb = p.findObject('columnTable');
                    if (tb !== null && tb.isUnderline) {
                      tb.stroke = 'black';
                      tb.font = "12px 'Muli', sans-serif";
                    }
                  }
                });
              });

              //this.diagram.requestUpdate(); // Needed!
              this.onEvent({
                event: 'openSelectTable',
                data: entity,
              });
            },
          },
          $(
            go.Panel,
            'Spot',
            $(go.Shape, {
              name: 'nodeWrapper',
              width: 193,
              height: 58,
              fill: 'white',
              stroke: '#ffffff',
              strokeWidth: 2,
              alignment: go.Spot.Center,
              geometryString: go.Geometry.fillPath('M30,0 h135 a30,30 0 0 1 0,60 h-135 a30,30 0 0 1 0,-60 Z'),
            }),
            $(go.Shape, 'Ellipse', {
              name: 'nodeImageBase',
              height: 50,
              width: 50,
              fill: '#f1f3f7',
              stroke: null,
              alignment: new go.Spot(0, 0.5, 6, 0),
              alignmentFocus: go.Spot.Left,
            }),
            $(go.Shape, {
              name: 'nodeImageShape',
              width: 24,
              height: 24,
              fill: '#ff5e43',
              stroke: '#ff5e43',
              strokeWidth: 1,
              alignment: new go.Spot(0, 0.5, 35, 0),
              geometryString: go.Geometry.fillPath('M22.5,15 h50 l-10,20 l10,20 h-50 Z M15,10 v80 Z'),
            }),
            $(
              go.Panel,
              'Vertical',
              {
                height: 40,
                alignment: new go.Spot(0, 0.55, 60, 0),
                alignmentFocus: go.Spot.Left,
              },
              $(
                go.TextBlock,
                {
                  name: 'nodeTextPrimary',
                  stroke: '#262D37',
                  alignment: go.Spot.Left,
                  font: "19px 'Muli', sans-serif",
                  margin: new go.Margin(0, 0, 5, 0),
                  overflow: go.TextBlock.OverflowEllipsis,
                  wrap: go.TextBlock.None,
                  width: 120,
                },
                new go.Binding('text', 'title'),
              ),
              $(
                go.TextBlock,
                {
                  name: 'nodeTextSecondary',
                  alignment: go.Spot.Left,
                  font: "12px 'Muli', sans-serif",
                  stroke: '#262D37',
                },
                new go.Binding(
                  'text',
                  'categoryName',
                  categoryName => `${categoryName[0].toUpperCase()}${categoryName.slice(1)}`,
                ),
              ),
            ),
          ),
        );
      };

      //Table
      identityLineageModel._tableGroupTemplate = () => {
        return $(
          go.Group,
          'Vertical',
          {
            background: 'white',
            width: 200,
            isShadowed: true,
            shadowColor: '#3371b3',
            shadowBlur: 10,
            shadowOffset: go.Point.parse('3 3'),
            layout: $(go.GridLayout, {
              wrappingColumn: 1,
              cellSize: new go.Size(1, 1),
              spacing: new go.Size(5, 5),
              alignment: go.GridLayout.Position,
              comparer: (a, b) => {
                const aLinks = a.findLinksConnected().count;
                const bLinks = b.findLinksConnected().count;
                const linksDiff = (bLinks - aLinks) * 100;

                const aKey = a.data.key;
                const bKey = b.data.key;
                const keyDiff = aKey < bKey ? -1 : aKey > bKey ? 1 : 0;

                return Math.sign(linksDiff + keyDiff);
              },
            }),
          },
          $(
            go.Panel,
            'Spot',
            {
              margin: new go.Margin(10, 0, 10, 0),
            },
            $(
              go.Shape,
              'Ellipse',
              {
                height: 50,
                width: 50,
                stroke: null,
                alignmentFocus: go.Spot.Center,
                cursor: 'default',
                click: goToIdConnection,
              },
              new go.Binding('fill', 'schemaColor'),
              new go.Binding('cursor', 'attachedIdConnectionName', v => (v ? 'pointer' : 'default')),
              new go.Binding('stroke', 'attachedIdConnectionName', v => (v ? '#ff5e43' : null)),
              new go.Binding('strokeWidth', 'attachedIdConnectionName', v => (v ? 5 : 0)),
              {
                toolTip: $(
                  go.Adornment,
                  'Auto',
                  $(go.Shape, { fill: '#f1f3f7', stroke: '#cdd1e4' }),
                  $(
                    go.TextBlock,
                    { margin: 8 },
                    new go.Binding('text', 'attachedIdConnectionName', v =>
                      v ? 'ID source connected: ' + v : 'No ID source connected',
                    ),
                  ),
                ),
              },
            ),
            $(
              go.Picture,
              '/images/Bigid_table.png',
              {
                height: 24,
                width: 24,
                imageStretch: go.GraphObject.UniformToFill,
                alignmentFocus: go.Spot.Center,
                cursor: 'defailt',
                click: goToIdConnection,
              },
              new go.Binding('cursor', 'attachedIdConnectionName', v => (v ? 'pointer' : 'default')),
              {
                toolTip: $(
                  go.Adornment,
                  'Auto',
                  $(go.Shape, { fill: '#f1f3f7', stroke: '#cdd1e4' }),
                  $(
                    go.TextBlock,
                    { margin: 8 },
                    new go.Binding('text', 'attachedIdConnectionName', v =>
                      v ? 'ID source connected: ' + v : 'No ID source connected',
                    ),
                  ),
                ),
              },
            ),
            $(
              go.Panel,
              'Vertical',
              {
                height: 40,
                alignment: new go.Spot(0, 0.5, 60, 0),
                alignmentFocus: go.Spot.Left,
              },
              $(
                go.TextBlock,
                {
                  alignment: go.Spot.Left,
                  font: "12px 'Muli', sans-serif",
                  margin: new go.Margin(0, 0, 5, 0),
                  overflow: go.TextBlock.OverflowEllipsis,
                  wrap: go.TextBlock.None,
                  width: 120,
                },
                new go.Binding('text', 'schema'),
                {
                  // define a tooltip for each node that displays the color as text
                  toolTip: $(
                    go.Adornment,
                    'Auto',
                    $(go.Shape, { fill: '#f1f3f7', stroke: '#cdd1e4' }),
                    $(go.TextBlock, { margin: 8 }, new go.Binding('text', 'schema')),
                  ), // end of Adornment
                },
              ),
              $(
                go.TextBlock,
                {
                  alignment: go.Spot.Left,
                  font: "19px 'Muli', sans-serif",
                  margin: new go.Margin(0, 0, 5, 0),
                  overflow: go.TextBlock.OverflowEllipsis,
                  wrap: go.TextBlock.None,
                  width: 120,
                },
                new go.Binding('text', 'title'),
                {
                  // define a tooltip for each node that displays the color as text
                  toolTip: $(
                    go.Adornment,
                    'Auto',
                    $(go.Shape, { fill: '#f1f3f7', stroke: '#cdd1e4' }),
                    $(go.TextBlock, { margin: 8 }, new go.Binding('text', 'title')),
                  ), // end of Adornment
                },
              ),
              $(
                go.TextBlock,
                {
                  alignment: go.Spot.Left,
                  font: "12px 'Muli', sans-serif",
                  stroke: 'rgb(113, 125, 137)',
                },
                new go.Binding(
                  'text',
                  'categoryName',
                  categoryName => `${categoryName[0].toUpperCase()}${categoryName.slice(1)}`,
                ),
              ),
            ),
          ),
          $(
            go.Panel,
            'Position',
            {
              width: 170,
              height: 30,
              click: (e, node) => {
                this.diagram.commandHandler.editTextBlock(node.part.findObject('search-bar'));
              },
            },
            $(go.Shape, {
              width: 150,
              height: 28,
              fill: '#f1f3f7',
              stroke: '#cdd1e4',
              strokeWidth: 1,
              geometryString: go.Geometry.fillPath('M25,0 h120 a15,15 0 0 1 0,30 h-120 a15,15 0 0 1 0,-30 Z'),
            }),
            $(
              go.TextBlock,
              {
                name: 'search-bar',
                width: 110,
                height: 16,
                position: new go.Point(25, 9),
                textAlign: 'left',
                font: "12px 'Muli', sans-serif",
                editable: true,
                isMultiline: false,
                text: 'Search',
              },
              new go.Binding('text', 'search', search => search || 'Search').makeTwoWay(text =>
                text === 'Search' ? '' : text,
              ),
            ),
          ),
          $(
            go.TextBlock,
            {
              width: 150,
              height: 30,
              textAlign: 'left',
              margin: new go.Margin(30, 0, 0, 0),
              font: "14px bold 'Muli', sans-serif",
            },
            new go.Binding('text', 'attributeCount', count => `${count} Attributes`),
          ),
          $(go.Placeholder),
          $(
            go.TextBlock,
            {
              margin: new go.Margin(10, 20, 0, 20),
              height: 30,
              alignment: go.Spot.Left,
              font: "14px 'Muli', sans-serif",
              stroke: '#2196F3',
              click: (e, node) => {
                this.diagram.startTransaction('TableExpandCollapse');
                const group = node.part;
                const columns = group.findSubGraphParts().toArray();
                group.data.isExpanded = !group.data.isExpanded;
                group.data.search = '';
                columns.forEach((column, idx) => {
                  column.data.isShown = column.findLinksConnected().count > 0 || group.data.isExpanded || idx < 10;
                });
                this.diagram.commitTransaction('TableExpandCollapse');
                this.diagram.updateAllTargetBindings(); // TODO [DA]: Figure out why commiting the transaction doesn't update bindings.
              },
            },
            new go.Binding('text', 'isExpanded', expanded => (expanded ? 'Collapse...' : 'Expand All...')),
            new go.Binding('visible', 'attributeCount', count => count > 10),
          ),
        );
      };

      //Table Column
      identityLineageModel._tableColumnNodeTemplate = () => {
        return $(
          go.Node,
          'Vertical',
          {
            height: 40,
            width: 200,
          },
          $(
            go.Panel,
            'Spot',
            $(
              go.TextBlock,
              {
                name: 'columnTable',
                width: 150,
                height: 30,
                font: " 12px 'Muli', sans-serif",
                click: (event, shape) => {
                  const entity = shape.part.data;
                  //this.diagram.requestUpdate(); // Needed!
                  const text = shape.part.findObject('columnTable');
                  if (text.isUnderline) {
                    this.diagram.findTopLevelGroups().each(function (g) {
                      g.memberParts.each(function (p) {
                        if (p instanceof go.Node) {
                          const tb = p.findObject('columnTable');
                          if (tb !== null && tb.isUnderline) {
                            tb.stroke = 'black';
                            tb.font = "12px 'Muli', sans-serif";
                          }
                        }
                      });
                    });

                    if (text.isUnderline) {
                      text.stroke = 'blue';
                      text.font = "bold 12px 'Muli', sans-serif";
                    } else if (text.isUnderline && text.stroke === 'blue') {
                      text.stroke = 'black';
                    }
                  }

                  this.onEvent({
                    event: 'openSelectTableByColumn',
                    data: entity,
                    column: entity.title,
                  });
                },
              },
              new go.Binding('cursor', 'isClickable', function (s) {
                return s ? 'pointer' : 'default';
              }),
              new go.Binding('isUnderline', 'isClickable'),
              new go.Binding('text', 'title'),
              new go.Binding('portId', 'key'),
              {
                // define a tooltip for each node that displays the color as text
                toolTip: $(
                  go.Adornment,
                  'Auto',
                  $(go.Shape, { fill: '#f1f3f7', stroke: '#cdd1e4' }),
                  $(go.TextBlock, { margin: 8 }, new go.Binding('text', 'tooltip')),
                ), // end of Adornment
              },
            ),
            $(
              go.TextBlock,
              {
                name: 'numberOfLinkForTables',
                width: 20,
                height: 30,
                alignmentFocus: go.Spot.Center,
                alignment: new go.Spot(1, 0.5, -15, 0),
                font: " 12px 'Muli', sans-serif",
              },
              new go.Binding('text', 'numberOfLinkForTables', function (s) {
                return s > 0 ? '(' + s + ')' : '';
              }),
            ),
            $(
              go.Picture,
              '/images/BigId_Orange_Flag.svg',
              {
                width: 14,
                imageStretch: go.GraphObject.UniformToFill,
                alignment: new go.Spot(1, 0.5, 0, -10),
                alignmentFocus: go.Spot.Center,
              },
              new go.Binding('height', 'hasAttribute', function (s) {
                return s === 'hasAttribute' ? '14' : '0';
              }),
            ),
          ),
          $(go.Shape, {
            width: 200,
            height: 0.1,
            fill: '#e7ebf2',
            stroke: '#e7ebf2',
            margin: new go.Margin(0, 0, 7, 0),
            strokeWidth: 0.1,
          }),
          new go.Binding('visible', 'isShown'),
        );
      };
    },
  ],
  bindings: {
    go: '<',
    nodesData: '<',
    linksData: '<',
    triggerGraphUpdate: '<',
    onEvent: '&',
  },
});

export function lazyLoadGoJs() {
  return import(/* webpackChunkName: "lib-gojs" */ '../../../lib/gojs/gojs.js')
    .then(() => {
      const GO_LICENSE_KEY =
        '73f943e1b66228a800ca0d71113f69eb5ef62e31dc940ce00b0346a4ec0b38137599ea7d538082c7d7a847ae1e7ac6dfd9956e79954f563df521d58913e486abe23661a21709448cbb0039cb';
      // go is a global object on window ... legacy code
      window.go.licenseKey = GO_LICENSE_KEY;
      return window.go;
    })
    .catch(error => {
      window.console.log(`An error occurred while lazy loading gojs, error: ${error}`);
      throw error;
    });
}
