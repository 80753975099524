import React, { FC, memo } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { BigidDropdown, BigidDropdownOption, BigidDropdownValue, BigidHeading6 } from '@bigid-ui/components';
import { MetadataSearchFilterComponentBaseProps } from '../../MetadataSearchFiltersTypes';
import { getMetadataSearchMapKeys } from '../../../MetadataSearchService';
import {
  MetadataSearchFilterEntityTypeModifier,
  MetadataSearchFilterEntityTypeModifierLabelMap,
  MetadataSearchFilterEntityTypeModifierIconMap,
} from './MetadataSearchFilterEntityTypeUtils';

export type MetadataSearchFilterEntityTypeProps = MetadataSearchFilterComponentBaseProps;

const useStyles = makeStyles({
  root: {
    width: '100%',
  },
  control: {
    padding: '8px',
    width: '100%',
  },
  label: {
    marginBottom: '8px',
  },
});

export const MetadataSearchFilterEntityType: FC<MetadataSearchFilterEntityTypeProps> = memo(
  ({ dataAid, config, value, onFilterChange, onFilterSearch, isFiltersFetching }) => {
    const classes = useStyles();
    const { fieldName, fieldType, displayName, values: options = [] } = config;
    const label = displayName || fieldName;

    const additionalOptions: BigidDropdownOption[] = getMetadataSearchMapKeys(
      MetadataSearchFilterEntityTypeModifierLabelMap,
    ).map((modifier: MetadataSearchFilterEntityTypeModifier) => ({
      id: modifier,
      displayValue: MetadataSearchFilterEntityTypeModifierLabelMap[modifier],
      value: modifier,
      icon: MetadataSearchFilterEntityTypeModifierIconMap[modifier].icon,
    }));

    const selectedValue: BigidDropdownValue = [
      options.find(option => option.value === value?.value) || additionalOptions[0],
    ];

    const handleOnSelect = ([selectedOption]: BigidDropdownOption[]): void => {
      const { value } = selectedOption;
      const isAnyValue = value === MetadataSearchFilterEntityTypeModifier.ANY;

      onFilterChange({
        field: fieldName,
        value: isAnyValue ? null : value,
        operator: 'equal',
        fieldType,
        fieldDisplayName: label,
      });
    };

    const handleFetchOptions = (query: string) => {
      return onFilterSearch(query, config);
    };

    return (
      <div className={classes.root}>
        <div className={classes.control}>
          <BigidHeading6 data-aid={`${dataAid}-label`} className={classes.label}>
            {label}
          </BigidHeading6>
          <BigidDropdown
            dataAid={`${dataAid}-field`}
            options={[...additionalOptions, ...options]}
            value={selectedValue}
            onSelect={handleOnSelect}
            fetchOptions={handleFetchOptions}
            isDisabled={isFiltersFetching}
          />
        </div>
      </div>
    );
  },
);

MetadataSearchFilterEntityType.displayName = 'MetadataSearchFilterEntityType';
