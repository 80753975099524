import React, { FC } from 'react';
import { BigidGridWithToolbar } from '@bigid-ui/grid';
import { BigidLoader } from '@bigid-ui/components';
import { UseCurationStateResponse } from '../useCurationState';
import { useCuratedDataSourcesConfig } from './useCuratedDataSourcesConfig';

export interface CuratedDataSourcesProps
  extends Pick<UseCurationStateResponse, 'onProceedToAttributeList' | 'onCurationDefaultInitialStageSelect'> {
  scanId: string;
}

export const CuratedDataSources: FC<CuratedDataSourcesProps> = ({
  scanId,
  onProceedToAttributeList,
  onCurationDefaultInitialStageSelect,
}) => {
  const { isReady, gridConfig } = useCuratedDataSourcesConfig({
    scanId,
    onProceedToAttributeList,
    onCurationDefaultInitialStageSelect,
  });

  return isReady ? <BigidGridWithToolbar {...gridConfig} /> : <BigidLoader />;
};
