import React, { FC, Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import { BigidColors, BigidIconSize, BigidLoader, BigidPaper } from '@bigid-ui/components';

import {
  BigidGridColumn,
  BigidGridColumnTypes,
  BigidGridWithToolbar,
  BigidGridWithToolbarProps,
  ChipsFormatterProps,
  IconFormatterProps,
} from '@bigid-ui/grid';
import { $state } from '../../../services/angularServices';
import {
  AccountType,
  CategoryDetails,
  Counter,
  getCategoriesDetails,
  getCategoriesOfOpenAccessFiles,
  getDistinctDs,
  getUsersRecords,
  remediateParticularUsers,
  Visibility,
} from './UsersPermissionsService';
import { isPermitted } from '../../../services/userPermissionsService';
import { APPLICATIONS_PERMISSIONS } from '@bigid/permissions';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import RequestedIcon from '@mui/icons-material/Mail';
import { notificationService } from '../../../services/notificationService';
import { useUserPreferences } from '../../../components/hooks/useUserPrefrences';
import { cloneDeep, isArray } from 'lodash';

export interface UsersPermissionsRecord {
  id: string;
  name: string;
  email?: string;
  fileAccess?: number;
  dataSource: string;
  visibility: Visibility;
  accountType: AccountType;
  username: string;
  remediationTaskId?: string;
  remediationTaskStatus?: 'open' | 'resolved';
  openAccess?: string;
  counters: Counter[];
}

export const remediationIndicatorMapping = new Map<string, IconFormatterProps>([
  [
    'open',
    {
      icon: {
        icon: RequestedIcon,
        label: 'Open',
        size: BigidIconSize.MEDIUM,
      },
    },
  ],
  [
    'resolved',
    {
      icon: {
        icon: CheckCircleIcon,
        color: BigidColors.green[600],
        label: 'Resolved',
        size: BigidIconSize.MEDIUM,
      },
    },
  ],
]);

const goToUserData = (userId: string) => {
  $state.go(`userPermissions`, { userId });
};

export const DIRECT_USER_ACCESS_TAB_EMPTY_FILTERS: DirectUserAccessTabFilters = {
  searchString: '',
  visibility: [],
  accountType: [],
  category: [],
  dataSource: [],
};

export interface DirectUserAccessTabFilters {
  searchString?: string;
  visibility?: string[];
  accountType?: string[];
  category?: string[];
  dataSource?: string[];
}

const getFilterConfig = async () => {
  const dsList = await getDistinctDs();
  const categoriesOfOpenAccessFilesList = await getCategoriesOfOpenAccessFiles();

  const filterConfig: BigidGridWithToolbarProps<UsersPermissionsRecord>['filterToolbarConfig'] = {
    filters: [
      {
        title: 'Data Sources',
        field: 'dataSource',
        operator: 'in',
        disabled: true,
        options: dsList.map(({ value }) => ({
          label: value,
          value: value,
          isSelected: false,
        })),
        value: [],
      },
      {
        title: 'Visibility',
        field: 'visibility',
        operator: 'in',
        disabled: true,
        options: Object.values(Visibility).map(type => ({
          label: type.toString(),
          value: type.toString(),
          isSelected: false,
        })),
        value: [],
      },
      {
        title: 'Account Type',
        field: 'accountType',
        operator: 'in',
        disabled: true,
        options: Object.values(AccountType).map(type => ({
          label: type.toString(),
          value: type.toString(),
          isSelected: false,
        })),
        value: [],
      },
      {
        title: 'Categories',
        field: 'category',
        operator: 'in',
        disabled: true,
        options: categoriesOfOpenAccessFilesList.map(category => ({
          label: category.name,
          value: category.name,
          isSelected: false,
        })),
        value: [],
      },
    ],
    searchConfig: {
      searchFilterKeys: ['name'],
      placeholder: 'Name',
      initialValue: '',
      operator: 'contains',
    },
  };
  return filterConfig;
};

const withFiltersFromRouter = (
  filterToolbarConfig: BigidGridWithToolbarProps<UsersPermissionsRecord>['filterToolbarConfig'],
  filtersFromRouter: DirectUserAccessTabFilters,
) => {
  const filterToolbarConfigClone = cloneDeep(filterToolbarConfig);

  if (filterToolbarConfigClone?.searchConfig && filtersFromRouter?.searchString) {
    filterToolbarConfigClone.searchConfig.initialValue = filtersFromRouter.searchString;
  }

  filterToolbarConfigClone?.filters?.forEach(filter => {
    const filterName = filter.field;
    const filterValues = (filtersFromRouter as Record<string, any>)?.[filterName];
    if (isArray(filterValues)) {
      filter.options.forEach(option => {
        option.isSelected = filterValues.includes(option.value as string);
      });
      filter.value = filter.options.filter(option => option.isSelected).map(option => option.value + '');

      filter.disabled = !(filter.value?.length > 0);
    }
  });
  return filterToolbarConfigClone;
};

export const UsersPermissions: FC = () => {
  const [allCategories, setAllCategories] = useState<CategoryDetails[]>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const getCategoriesChips = useCallback(
    (counters: Counter[]): ChipsFormatterProps => {
      const categories = counters?.filter(counter => {
        const { type, source, count } = counter;
        return type === 'category' && source === 'ALL' && count > 0;
      });

      return {
        chips: {
          value: categories?.map(category => {
            const { name, count } = category;
            return {
              label: name,
              title: `${count} Files`,
              outlineColor: allCategories?.find(categoryInfo => categoryInfo.name === name).color,
              variant: 'outlined',
              outline: 'solid',
            };
          }),
          isDisabled: true,
        },
      };
    },
    [allCategories],
  );

  const columns = useMemo<BigidGridColumn<UsersPermissionsRecord>[]>(
    () => [
      {
        title: 'User',
        name: 'name',
        width: 200,
        sortingEnabled: true,
        type: BigidGridColumnTypes.LINK,
        formatter: {
          onClick: async ({ value }) => {
            const { linkParams } = value;
            goToUserData(linkParams.id);
            return {};
          },
        },
        getCellValue: ({ id, name }) => ({
          link: {
            text: name,
          },
          linkParams: {
            id,
          },
        }),
      },
      {
        title: 'Data Source',
        name: 'dataSource',
        sortingEnabled: true,
        width: 350,
        type: BigidGridColumnTypes.TEXT,
        getCellValue: ({ dataSource }) => dataSource,
      },
      {
        title: 'Visibility',
        name: 'visibility',
        sortingEnabled: true,
        width: 170,
        type: BigidGridColumnTypes.TEXT,
        getCellValue: ({ visibility }) => visibility,
      },
      {
        title: 'Account Type',
        name: 'accountType',
        sortingEnabled: true,
        width: 170,
        type: BigidGridColumnTypes.TEXT,
        getCellValue: ({ accountType }) => accountType,
      },
      {
        title: 'Email',
        name: 'email',
        width: 300,
        sortingEnabled: false,
        type: BigidGridColumnTypes.TEXT,
        getCellValue: ({ email }) => email,
      },
      {
        title: 'File Access',
        name: 'sharedObjectsCount',
        sortingEnabled: true,
        type: BigidGridColumnTypes.TEXT,
        width: 150,
        getCellValue: ({ fileAccess }) => fileAccess,
      },
      {
        title: 'Categories',
        name: 'counters',
        sortingEnabled: false,
        type: BigidGridColumnTypes.CHIPS,
        getCellValue: ({ counters }) => getCategoriesChips(counters),
      },
      {
        title: 'Audit status',
        name: 'remediationTaskStatus',
        sortingEnabled: true,
        width: 150,
        type: BigidGridColumnTypes.ICON,
        getCellValue: ({ remediationTaskStatus }) => {
          return remediationIndicatorMapping.get(remediationTaskStatus);
        },
      },
    ],
    [getCategoriesChips],
  );

  const { isReady, preferences, gridColumns, filterToolbarConfig, updatePreferences } = useUserPreferences({
    stateName: `${$state.$current.name}.${$state.params.selectedTab}`,
    initialGridColumns: columns,
    getInitialFilterToolbarConfig: getFilterConfig,
  });

  useEffect(() => {
    const getAllCategories = async () => {
      setIsLoading(true);
      try {
        const categories = await getCategoriesDetails();
        setAllCategories(categories.data);
      } catch ({ message }) {
        console.error(`An error has occurred: ${message}`);
        notificationService.error('An error has occurred');
      } finally {
        setIsLoading(false);
      }
    };
    getAllCategories();
  }, []);

  const gridWithToolbarConfig = useMemo<BigidGridWithToolbarProps<UsersPermissionsRecord>>(
    () => ({
      columns: gridColumns,
      filterToolbarConfig: withFiltersFromRouter(filterToolbarConfig, $state.params?.filters),
      entityName: 'Users',
      showSortingControls: true,
      onGridStateChange: ({ filter, ...gridState }) => updatePreferences({ filterState: { filter }, gridState }),
      defaultSorting: [{ field: 'sharedObjectsCount', order: 'desc' }],
      fetchData: async queryComponents => {
        const response = await getUsersRecords(queryComponents);
        const users: UsersPermissionsRecord[] = response.users.map(user => ({
          visibility: user.external
            ? Visibility.External
            : user.external == false
            ? Visibility.Internal
            : Visibility.Other,
          accountType: user.headless
            ? AccountType.HeadlessAccount
            : user.headless == false
            ? AccountType.UserAccount
            : undefined,
          fileAccess: user.sharedObjectsCount,
          dataSource: user.dataSource,
          name: user.name,
          email: user.email,
          username: user.username,
          id: user._id,
          remediationTaskId: user.remediationTask?.taskId,
          remediationTaskStatus: user.remediationTask?.status,
          counters: user.counters,
        }));

        return {
          totalCount: response.totalCount,
          data: users,
        };
      },
      toolbarActions: [
        {
          label: 'Audit',
          execute: async ({ selectedRowIds: userIdsToRemediate }) => {
            if (userIdsToRemediate.length > 0) {
              try {
                const { created, skipped, updated, dsWithNoOwner } = await remediateParticularUsers(
                  userIdsToRemediate as string[],
                );
                notificationService.success(`created: ${created}, updated: ${updated}, skipped: ${skipped}`);
                if (dsWithNoOwner?.length) {
                  setTimeout(() => {
                    notificationService.warning(
                      `The following data sources have been skipped as no data source owner is defined: ${dsWithNoOwner}`,
                      { showCloseIcon: true, shouldAutoHide: false },
                    );
                  }, 2000);
                }
              } catch (err) {
                notificationService.error('Failed to remediate users');
                console.error(err);
              }
            }

            return {
              shouldGridReload: true,
            };
          },
          disable: () => false,
          // todo: add write permissions
          show: ({ selectedRowIds }) =>
            selectedRowIds.length > 0 && isPermitted(APPLICATIONS_PERMISSIONS.READ_ACCESS_INTELLIGENCE.name),
        },
      ],
    }),
    [gridColumns, filterToolbarConfig, updatePreferences],
  );

  return (
    <Fragment>
      {!isReady || isLoading ? (
        <BigidLoader />
      ) : (
        <BigidPaper>
          <BigidGridWithToolbar {...gridWithToolbarConfig} />
        </BigidPaper>
      )}
    </Fragment>
  );
};
