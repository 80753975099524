import { noop } from 'lodash';
import { AssignCategoryDialogContent, AssignCategoryDialogContentProps } from './AssignCategoryDialogContent';
import { BigidSystemDialogOptions, openSystemDialog } from '../../../../services/systemDialogService';
import { ASSIGN_CATEGORIES_TYPE, AssignCategories } from '../../types/AssignCategories';
import { getFixedT } from '../../translations';
import { ActionData } from '@bigid-ui/components';

export const showAssignCategoryDialog = (
  actionData: ActionData,
  assignType: ASSIGN_CATEGORIES_TYPE,
  handleAssignCreateSucceeded?: (categories: AssignCategories[]) => void,
): Promise<boolean> => {
  const t = getFixedT(`AssignCategoryDialog`);

  return new Promise(resolve => {
    const options: BigidSystemDialogOptions = {
      title: t('dialogTitle'),
      maxWidth: 'xs',
      isContentScrollable: false,
      onClose: noop,
      showCloseIcon: true,
      borderTop: true,
      content: AssignCategoryDialogContent,
      contentProps: {
        actionData,
        assignType,
        handleAssignCreateSucceeded,
        resolve,
      },
    };
    openSystemDialog<AssignCategoryDialogContentProps>(options);
  });
};
