import {
  BigidDropdownOption,
  BigidFormField,
  BigidFormFieldLabelPosition,
  BigidFormFieldTypes,
  BigidFormValues,
  BooleanMap,
} from '@bigid-ui/components';
import { CredentialProviderRow, HashiCorpAuthenticationType, ProviderType } from './types';
import { ALLOWED_NAME_REGEX } from '../../config/consts';
import { getFixedT } from './translations';
import { getApplicationPreference } from '../../services/appPreferencesService';

export const t = (key: string): string => getFixedT('')(key);

export const cyberArkFieldNames = [
  'name',
  'url',
  'type',
  'trustSelfSigned',
  'certPassword',
  'appId',
  'cert',
  'useCustomConnectivityURI',
];
export const hashicorpTlsFieldNames = [
  'name',
  'type',
  'client_cert_key',
  'client_cert',
  'certificate_role_name',
  'auth_method',
  'url',
];
export const hashicorpAppRoleFieldNames = ['name', 'url', 'type', 'auth_method', 'secret_id', 'role_id'];
export const thycoticFieldNames = ['name', 'type', 'auth_method', 'role_id', 'secret_id', 'url'];

const hashicorpAuthenticationMethods = [
  { id: 'tls', displayValue: t('form.authenticationMethod.tls'), value: HashiCorpAuthenticationType.TLS },
  {
    id: 'appRole',
    displayValue: t('form.authenticationMethod.appRole'),
    value: HashiCorpAuthenticationType.APP_ROLE,
  },
];

const thycoticAuthenticationMethods = [
  { id: 'userPassword', displayValue: t('form.authenticationMethod.userPassword'), value: 'userPassword' },
  {
    id: 'sdkClient',
    displayValue: t('form.authenticationMethod.sdkClient'),
    value: 'sdkClient',
  },
];

const hashicorpFields = [...hashicorpTlsFieldNames, ...hashicorpAppRoleFieldNames];

const requiredFieldValidation = (value: string) => {
  if (!value) {
    return t('message.requiredField');
  }
  return false;
};

const validateCertificateName = (value: string) => {
  if (!value) {
    return t('message.requiredField');
  }

  if (!value.match(ALLOWED_NAME_REGEX)) {
    return t('message.invalidCredentialIdPattern');
  }

  return false;
};

const validateHashicorpTlsUrl = (value: string) => {
  if (!value) {
    return t('message.requiredField');
  }
  if (!value.includes('/v1/auth/cert/login')) {
    return 'Invalid url, must contain "/v1/auth/cert/login"';
  }
  return false;
};

const validateRequiredDropdownField = (value: BigidDropdownOption[]) => {
  if (!(value?.length > 0)) {
    return t('message.requiredField');
  }
  return false;
};

export const getFormFields = ({
  isEditMode,
  certificateOptions,
  authenticationMethod,
  providerType,
  useCustomConnectivityURI,
}: {
  isEditMode?: boolean;
  authenticationMethod?: HashiCorpAuthenticationType;
  certificateOptions: BigidDropdownOption[];
  providerType: ProviderType;
  useCustomConnectivityURI?: boolean;
}): BigidFormField[] => {
  const commonFields = [
    {
      name: 'name',
      type: BigidFormFieldTypes.TEXT,
      validate: validateCertificateName,
      isRequired: true,
      label: t('form.connectionName'),
      disabled: isEditMode,
      fieldProps: {
        size: 'large',
      },
    },
    {
      name: 'type',
      type: BigidFormFieldTypes.DROP_DOWN,
      isRequired: true,
      label: 'Type',
      disabled: isEditMode,
      dropDownOptions: [
        { id: ProviderType.CYBERARK, displayValue: ProviderType.CYBERARK, value: ProviderType.CYBERARK },
        { id: ProviderType.HASHICORP, displayValue: ProviderType.HASHICORP, value: ProviderType.HASHICORP },
        ...(getApplicationPreference('SHOW_REMOTE_THYCOTIC_CREDENTIALS_TYPE')
          ? [{ id: ProviderType.THYCOTIC, displayValue: ProviderType.THYCOTIC, value: ProviderType.THYCOTIC }]
          : []),
      ],
      fieldProps: {
        size: 'large',
      },
    },
    {
      name: 'useCustomConnectivityURI',
      type: BigidFormFieldTypes.CHECKBOX,
      labelPosition: BigidFormFieldLabelPosition.left,
      isRequired: false,
      label: t('form.useCustomConnectivityURI'),
      disabled: false,
    },
    {
      name: 'url',
      type: BigidFormFieldTypes.TEXT,
      isRequired: true,
      label:
        providerType === ProviderType.CYBERARK && useCustomConnectivityURI ? t('form.customURI') : t('form.vaultUrl'),
      disabled: false,
      ...(authenticationMethod === HashiCorpAuthenticationType.TLS
        ? {
            tooltipText: t('form.tooltipText.hashicorp_tls_url'),
            validate: validateHashicorpTlsUrl,
          }
        : { validate: requiredFieldValidation }),
      fieldProps: {
        size: 'large',
      },
    },
  ];
  const hashicorpFields: BigidFormField[] = [
    {
      name: 'auth_method',
      type: BigidFormFieldTypes.DROP_DOWN,
      isRequired: true,
      label: t('form.auth_method'),
      disabled: false,
      validate:
        providerType === ProviderType.HASHICORP || providerType === ProviderType.THYCOTIC
          ? validateRequiredDropdownField
          : () => false,
      dropDownOptions:
        providerType === ProviderType.HASHICORP ? hashicorpAuthenticationMethods : thycoticAuthenticationMethods,
      fieldProps: {
        size: 'large',
      },
    },
    {
      name: 'role_id',
      type: BigidFormFieldTypes.TEXT,
      isRequired: true,
      label: providerType === ProviderType.HASHICORP ? t('form.role_id') : t('form.username'),
      disabled: false,
      fieldProps: {
        size: 'large',
      },
    },
    {
      name: 'secret_id',
      type: BigidFormFieldTypes.PASSWORD,
      isRequired: false,
      label: providerType === ProviderType.HASHICORP ? t('form.secret_id') : t('form.password'),
      disabled: false,
      fieldProps: {
        showValueOnlyIfDirty: true,
        size: 'large',
      },
    },
    {
      name: 'bind_secret_id',
      type: BigidFormFieldTypes.CHECKBOX,
      isRequired: false,
      label: t('form.bind_secret_id'),
      labelPosition: BigidFormFieldLabelPosition.left,
      disabled: false,
      misc: { hidden: true },
      fieldProps: {
        hidden: true,
      },
    },
    {
      name: 'certificate_role_name',
      type: BigidFormFieldTypes.TEXT,
      isRequired: false,
      label: t('form.certificate_role_name'),
      disabled: false,
      fieldProps: {
        size: 'large',
      },
    },
    {
      name: 'client_cert',
      type: BigidFormFieldTypes.DROP_DOWN,
      dropDownOptions: certificateOptions,
      isRequired: true,
      label: t('form.client_cert'),
      disabled: false,
      fieldProps: {
        size: 'large',
      },
    },
    {
      name: 'client_cert_key',
      type: BigidFormFieldTypes.DROP_DOWN,
      dropDownOptions: certificateOptions,
      isRequired: true,
      label: t('form.client_cert_key'),
      disabled: false,
      fieldProps: {
        size: 'large',
      },
    },
  ];

  const cyberArkFields: BigidFormField[] = [
    {
      name: 'appId',
      type: BigidFormFieldTypes.TEXT,
      isRequired: true,
      label: t('form.appID'),
      disabled: false,
      validate: requiredFieldValidation,
      fieldProps: {
        size: 'large',
      },
    },
    {
      name: 'certPassword',
      type: BigidFormFieldTypes.PASSWORD,
      isRequired: false,
      label: t('form.certPassword'),
      disabled: false,
      fieldProps: {
        size: 'large',
      },
    },
    {
      name: 'trustSelfSigned',
      type: BigidFormFieldTypes.CHECKBOX,
      labelPosition: BigidFormFieldLabelPosition.left,
      isRequired: false,
      label: t('form.trustSelfSigned'),
      disabled: false,
    },
  ];

  return [
    ...commonFields,
    ...(providerType === ProviderType.CYBERARK ? cyberArkFields : []),
    ...(providerType === ProviderType.HASHICORP ? hashicorpFields : []),
  ];
};

const getDropdownInitialValue = (field: BigidFormField, row: CredentialProviderRow): BigidDropdownOption[] => {
  const { name, dropDownOptions } = field;
  return dropDownOptions.filter(option => option?.value === row[name as keyof CredentialProviderRow]);
};

export const getInitialValues = (fields: BigidFormField[], row: CredentialProviderRow): BigidFormValues => {
  return fields.reduce<BigidFormValues>((acc, field) => {
    const { name, type } = field;
    const fieldName = name as keyof CredentialProviderRow;
    const isDropDownType = type === BigidFormFieldTypes.DROP_DOWN;

    const getValue = () => {
      if (isDropDownType) {
        return getDropdownInitialValue(field, row);
      }
      if (name === 'name') {
        return row.isPending ? '' : row.name;
      }

      return row[fieldName];
    };

    return {
      ...acc,
      [name]: getValue(),
    };
  }, {});
};

export const createNewHeaderFieldItem = () => ({
  id: String(new Date().getTime()),
  value: {
    field_name: '',
    field_value: '',
  },
});

const mapFormFieldToRequestData: Partial<
  Record<keyof CredentialProviderRow, (options: BigidDropdownOption[]) => ProviderType>
> = {
  type: (options: BigidDropdownOption[]) => options[0].value,
  auth_method: (options: BigidDropdownOption[]) => options[0]?.value,
  client_cert: (options: BigidDropdownOption[]) => options[0]?.value || '',
  client_cert_key: (options: BigidDropdownOption[]) => options[0]?.value || '',
};

const mapCredentialProviderTypeToFields: Record<ProviderType, string[]> = {
  [ProviderType.CYBERARK]: cyberArkFieldNames,
  [ProviderType.HASHICORP]: hashicorpFields,
  [ProviderType.THYCOTIC]: thycoticFieldNames,
};

export const normalizeFormData = (values: BigidFormValues): CredentialProviderRow => {
  let accumulatorObject = {};
  const providerType = mapFormFieldToRequestData['type'](values.type);

  if (providerType === ProviderType.HASHICORP || providerType === ProviderType.THYCOTIC) {
    if (values['secret_id']) {
      accumulatorObject = { ['bind_secret_id']: true };
    }
  }

  return Object.keys(values).reduce((accumulator, fieldName) => {
    const getValue = mapFormFieldToRequestData[fieldName as keyof CredentialProviderRow];
    const value = getValue ? getValue(values[fieldName]) : values[fieldName];
    return mapCredentialProviderTypeToFields[providerType].includes(fieldName)
      ? {
          ...accumulator,
          [fieldName]: value,
        }
      : accumulator;
  }, accumulatorObject as CredentialProviderRow);
};

export const filterOnlyUpdatedFields = (data: CredentialProviderRow, updatedFields: BooleanMap) => {
  const updatedFieldKeys = new Set(Object.keys(updatedFields));
  const updatedFieldsToReturn = Object.keys(data).reduce<CredentialProviderRow>((accumulator, fieldName) => {
    const isFieldUpdated = updatedFieldKeys.has(fieldName);
    return {
      ...accumulator,
      ...(isFieldUpdated && { [fieldName]: data[fieldName as keyof CredentialProviderRow] }),
    };
  }, {} as CredentialProviderRow);

  return updatedFieldsToReturn;
};
