import { openSystemDialog, SystemDialogContentProps } from '../../services/systemDialogService';
import { licenseService } from '../../services/licenseService';
import { $translate } from '../../services/angularServices';
import React, { FC } from 'react';
import BigidAboutLogo from '../../assets/icons/BigidAboutLogo.svg';
import { Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { PrimaryButton, SecondaryButton } from '@bigid-ui/components';
import { httpService } from '../../services/httpService';
import { noop } from 'lodash';
import { dateTimeService } from '@bigid-ui/i18n';
interface AboutDialogOptions {
  isProductTypeVisible: boolean;
  supportLink: string;
  isSAPProductType: boolean;
}

interface AboutDialogProps extends AboutDialogOptions {
  gitHash?: string;
  releaseDate?: string;
  releaseVersion?: string;
}

export const showAboutDialog = (dialogOptions: AboutDialogOptions) => async () => {
  let aboutProps: AboutDialogProps = { ...dialogOptions };

  try {
    const {
      data: {
        data: { about },
      },
    } = await httpService.fetch<{ data: { about: AboutDialogProps } }>('about');

    const { gitHash, releaseVersion, releaseDate } = about;
    aboutProps = { ...aboutProps, gitHash, releaseVersion, releaseDate };
  } catch (error) {
    console.warn('Could not get about data');
  }

  openSystemDialog<AboutDialogProps>({
    title: 'About',
    onClose: noop,
    content: BigidAboutDialogContent,
    contentProps: { ...aboutProps },
    borderTop: true,
    buttons: [
      {
        text: $translate.instant('BIGID_HEADER:SUPPORT_MENU:ABOUT:SUPPORT'),
        component: SecondaryButton,
        onClick: () => {
          window.open(dialogOptions.supportLink, '_blank');
        },
      },
      {
        text: 'Close',
        component: PrimaryButton,
        onClick: noop,
        isClose: true,
      },
    ],
  });
};

const useStyles = makeStyles({
  root: {
    display: 'flex',
    paddingTop: 16,
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  icon: {
    display: 'flex',
    alignItems: 'center',
  },
  aboutData: {
    display: 'flex',
    flexDirection: 'column',
    width: '79%',
  },
  itemWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  itemLabel: {
    display: 'flex',
    lineHeight: 1.8,
  },
  itemValue: {
    display: 'flex',
    width: '68%',
    fontWeight: 500,
    lineHeight: 1.8,
  },
});

const BigidAboutDialogContent: FC<SystemDialogContentProps<AboutDialogProps>> = ({
  releaseDate,
  releaseVersion,
  gitHash,
  isProductTypeVisible,
}) => {
  const { root, aboutData, icon, itemWrapper, itemLabel, itemValue } = useStyles({});
  const expirationDate = licenseService.hasLicense()
    ? dateTimeService.formatDate(licenseService.getLicense().expirationDate, { formatString: 'MM/dd/yyyy' })
    : 'No License';

  const data = [
    {
      label: $translate.instant('BIGID_HEADER:SUPPORT_MENU:ABOUT:PRODUCT_NAME'),
      value: $translate.instant('BIGID_PRODUCT_TYPE'),
      isVisible: isProductTypeVisible,
    },
    {
      label: $translate.instant('BIGID_HEADER:SUPPORT_MENU:ABOUT:RELEASE_ID'),
      value: gitHash,
      isVisible: true,
      dataAid: 'about-git-hash',
    },
    {
      label: $translate.instant('BIGID_HEADER:SUPPORT_MENU:ABOUT:RELEASE_DATE'),
      value: releaseDate,
      isVisible: true,
      dataAid: 'about-release-date',
    },
    {
      label: $translate.instant('BIGID_HEADER:SUPPORT_MENU:ABOUT:VERSION_NUMBER'),
      value: releaseVersion,
      isVisible: true,
      dataAid: 'about-version-number',
    },
    {
      label: $translate.instant('BIGID_HEADER:SUPPORT_MENU:ABOUT:LICENSE_VALID'),
      value: expirationDate,
      isVisible: !!expirationDate,
      dataAid: 'about-expiration',
    },
  ];

  return (
    <div className={root}>
      <div className={icon}>
        <BigidAboutLogo />
      </div>
      <div className={aboutData}>
        {data
          .filter(({ isVisible }) => isVisible)
          .map(({ label, value = '', dataAid }, index) => (
            <div key={index} className={itemWrapper}>
              <Typography variant={'body1'} className={itemLabel}>
                {label}:
              </Typography>
              <Typography variant={'body2'} className={itemValue} data-aid={dataAid}>
                {value.trim()}
              </Typography>
            </div>
          ))}
      </div>
    </div>
  );
};
