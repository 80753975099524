import { analyticsService } from '../../../services/analyticsService';
import { VendorsStatus } from '../VendorsTypes';

export const trackVendors = (eventType: string, eventData?: Record<string, unknown>) => {
  analyticsService.trackManualEvent(eventType, eventData);
};

export enum VendorsTrackingEvents {
  SET_STATUS_ACTIVE_CLICK = 'VENDORS.SET_STATUS_ACTIVE_CLICK',
  SET_STATUS_INACTIVE_CLICK = 'VENDORS.SET_STATUS_INACTIVE_CLICK',
  SET_STATUS_CANDIDATE_CLICK = 'VENDORS.SET_STATUS_CANDIDATE_CLICK',
  EDIT_VENDOR_DIALOG_OPEN_INLINE_ACTION = 'VENDORS.EDIT_VENDOR_DIALOG_OPEN_INLINE_ACTION',
  VENDORS_DUPLICATE_INLINE_ACTION = 'VENDORS.VENDORS_DUPLICATE_INLINE_ACTION',
  DELETE_VENDOR_INLINE_ACTION = 'VENDORS.DELETE_VENDOR_INLINE_ACTION',
  EDIT_VENDOR_DIALOG_CANCEL_CLICK = 'VENDORS.EDIT_VENDOR_DIALOG_CANCEL_CLICK',
  EDIT_VENDOR_DIALOG_SAVE_CLICK = 'VENDORS.EDIT_VENDOR_DIALOG_SAVE_CLICK',
  EDIT_VENDOR_DIALOG_OPEN_CLICK = 'VENDORS.EDIT_VENDOR_DIALOG_OPEN_CLICK',
  IMPORT_VENDORS_DIALOG_OPEN_CLICK = 'VENDORS.IMPORT_VENDORS_DIALOG_OPEN_CLICK',
  IMPORT_VENDORS_DIALOG_CANCEL_CLICK = 'VENDORS.IMPORT_VENDORS_DIALOG_CANCEL_CLICK',
  IMPORT_VENDORS_DIALOG_UPLOAD_CLICK = 'VENDORS.IMPORT_VENDORS_DIALOG_UPLOAD_CLICK',
  IMPORT_VENDORS_TEMPLATE_DOWNLOAD_CLICK = 'VENDORS.IMPORT_VENDORS_TEMPLATE_DOWNLOAD_CLICK',
  CREATE_VENDOR_DIALOG_OPEN_CLICK = 'VENDORS.CREATE_VENDOR_DIALOG_OPEN_CLICK',
  CREATE_VENDOR_DIALOG_OPEN_ACTION = 'VENDORS.CREATE_VENDOR_DIALOG_OPEN_ACTION',
  CREATE_VENDOR_DIALOG_CANCEL_CLICK = 'VENDORS.CREATE_VENDOR_DIALOG_CANCEL_CLICK',
  CREATE_VENDOR_DIALOG_SAVE_CLICK = 'VENDORS.CREATE_VENDOR_DIALOG_SAVE_CLICK',
  DELETE_VENDOR_DIALOG_DELETE_CLICK = 'VENDORS.DELETE_VENDOR_DIALOG_DELETE_CLICK',
  DELETE_VENDOR_DIALOG_CANCEL_CLICK = 'VENDORS.DELETE_VENDOR_DIALOG_CANCEL_CLICK',

  // Attached files events
  IMPORT_ATTACHED_FILE_DIALOG_OPEN_CLICK = 'VENDORS.IMPORT_ATTACHED_FILE_DIALOG_OPEN_CLICK',
  IMPORT_ATTACHED_FILE_DIALOG_CLOSE_CLICK = 'VENDORS.IMPORT_ATTACHED_FILE_DIALOG_CLOSE_CLICK',
  IMPORT_ATTACHED_FILE_DIALOG_UPLOAD_CLICK = 'VENDORS.IMPORT_ATTACHED_FILE_DIALOG_UPLOAD_CLICK',
  DELETE_ATTACHED_FILE_ACTION = 'VENDORS.DELETE_ATTACHED_FILE_ACTION',
  DELETE_ATTACHED_FILE_INLINE_ACTION = 'VENDORS.DELETE_ATTACHED_FILE_INLINE_ACTION',
  DELETE_ATTACHED_FILE_DIALOG_DELETE_CLICK = 'VENDORS.DELETE_ATTACHED_FILE_DIALOG_DELETE_CLICK',
  DELETE_ATTACHED_FILE_DIALOG_CANCEL_CLICK = 'VENDORS.DELETE_ATTACHED_FILE_DIALOG_CANCEL_CLICK',
  DOWNLOAD_ATTACHED_FILE_ACTION = 'VENDORS.DOWNLOAD_ATTACHED_FILE_ACTION',
  DOWNLOAD_ATTACHED_FILE_INLINE_ACTION = 'VENDORS.DOWNLOAD_ATTACHED_FILE_INLINE_ACTION',

  // Assessments PIA
  ADD_NEW_ASSESSMENT_CLICK = 'VENDORS.PIA_ADD_NEW_ASSESSMENT_CLICK',
  VIEW_ASSESSMENT_CLICK = 'VENDORS.PIA_VIEW_ASSESSMENT_CLICK',
  VIEW_ALL_ASSESSMENTS_CLICK = 'VENDORS.PIA_VIEW_ALL_ASSESSMENTS_CLICK',
  // Assessments RoPA
  ADD_NEW_PROCESS_CLICK = 'VENDORS.ROPA_ADD_NEW_PROCESS_CLICK',
  VIEW_PROCESS_CLICK = 'VENDORS.ROPA_VIEW_PROCESS_CLICK',
  VIEW_ALL_PROCESSES_CLICK = 'VENDORS.ROPA_VIEW_ALL_PROCESSES_CLICK',
}

export const StatusToTrackingEventMapper = {
  [VendorsStatus.ACTIVE]: VendorsTrackingEvents.SET_STATUS_ACTIVE_CLICK,
  [VendorsStatus.INACTIVE]: VendorsTrackingEvents.SET_STATUS_INACTIVE_CLICK,
  [VendorsStatus.CANDIDATE]: VendorsTrackingEvents.SET_STATUS_CANDIDATE_CLICK,
};
