export interface AwsPolicy {
  Version: string;
  Statement: AwsPolicyStatementItem[];
}

export interface AwsPolicyStatementItem {
  Sid: string;
  Effect: string;
  Action: string[];
  Resource: string;
}

export enum AwsPolicyType {
  ALL = 'all',
  BASIC = 'basic',
  ATHENA = 'athena',
  KINESIS = 'kinesis',
  DYNAMODB = 'dynamodb',
  S3 = 's3',
}

export const iamRoles: Record<string, string[]> = {
  [AwsPolicyType.BASIC]: [
    'iam:ListPoliciesGrantingServiceAccess',
    'iam:SimulatePrincipalPolicy',
    'glue:GetTables',
    'glue:GetDatabases',
    'glue:GetTable',
    'iam:GetUser',
  ],
  [AwsPolicyType.ATHENA]: [
    's3:PutObject',
    's3:GetObject',
    's3:ListBucketMultipartUploads',
    's3:AbortMultipartUpload',
    's3:ListBucket',
    's3:GetBucketLocation',
    's3:ListMultipartUploadParts',
    'athena:GetTableMetadata',
    'athena:StartQueryExecution',
    'athena:ListDataCatalogs',
    'athena:GetQueryResults',
    'lambda:InvokeAsync',
    'athena:StopQueryExecution',
    'athena:GetQueryResultsStream',
    'lambda:InvokeFunction',
    's3:GetBucketAcl',
    'athena:ListDatabases',
    's3:ListAllMyBuckets',
    'athena:GetQueryExecution',
    'athena:ListTableMetadata',
  ],
  [AwsPolicyType.KINESIS]: [
    'cloudwatch:PutMetricData',
    'dynamodb:PutItem',
    'kinesis:ListShards',
    'dynamodb:DeleteItem',
    'kinesis:GetShardIterator',
    'dynamodb:Scan',
    'kinesis:DescribeStream',
    'dynamodb:UpdateItem',
    'dynamodb:DeleteTable',
    'dynamodb:CreateTable',
    'kinesis:ListStreams',
    'dynamodb:DescribeTable',
    'dynamodb:GetItem',
    'kinesis:GetRecords',
    'dynamodb:UpdateTable',
  ],
  [AwsPolicyType.DYNAMODB]: ['dynamodb:DescribeTable', 'dynamodb:ListTables'],
  [AwsPolicyType.S3]: [
    's3:ListAllMyBuckets',
    's3:ListBucket',
    'cloudwatch:GetMetricStatistics',
    's3:GetBucketAcl',
    'cloudwatch:ListMetrics',
    's3:GetBucketLocation',
  ],
};
