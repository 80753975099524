import { DataSourceConfigurationContextInterface } from '../DataSourceConfigurationContext';
import { publicUrls } from '../../../../config/publicUrls';

export async function getAccessCodeButton(value: string, stateAndHandlers: DataSourceConfigurationContextInterface) {
  const { getValuesContainer } = stateAndHandlers;
  const formValues = getValuesContainer.current();
  const appKey = formValues.oAuth2Authentication.clientId;
  window.open(
    `${publicUrls.DROPBOX_ACCESS_CODE_URL}?client_id=${appKey}&token_access_type=offline&response_type=code`,
    '_blank',
  );
}
