import React, { FC, useCallback, useEffect } from 'react';
import {
  ClassifierModelState,
  EvaluationCompletedData,
  EvaluationStartedData,
  SSE_TYPES,
  TuningData,
} from '../../classifierModelUtils';
import ModelIcon from '../../../../../../../assets/icons/ModelIcon.svg';
import { useLocalTranslation } from '../../translations';
import { BigidBody1 } from '@bigid-ui/components';
import { generateDataAid } from '@bigid-ui/utils';
import { styled } from '@mui/material';
import { SSEDataMessage, subscribeToRepeatedSSEEventById } from '../../../../../../../services/sseService';

const Card = styled('div')`
  display: flex;
  width: 266px;
  height: 372px;
  padding: 0 16px;
  flex-direction: column;
  align-items: center;
  box-shadow: ${({ theme }) => theme.vars.tokens.bigid.shadow10};
  border-radius: 4px;
  background: ${({ theme }) => theme.vars.palette.bigid.gray50};
  gap: 56px;
`;

const TextWrapper = styled('div')`
  margin-left: 2px;
  text-align: center;
`;

const Icon = styled('div')`
  margin-top: 16px;
`;

export interface ClassifierModelPreparationCardProps {
  tuningData: TuningData;
  onRender: () => void;
  dataAid?: string;
}

export const ClassifierModelPreparationCard: FC<ClassifierModelPreparationCardProps> = ({
  tuningData,
  onRender,
  dataAid = 'ClassifierModelPreparationCard',
}) => {
  const { t } = useLocalTranslation('main');

  const handleEvaluationStarted = useCallback(
    ({ message, broadcastEvent }: SSEDataMessage) => {
      const data: EvaluationStartedData = JSON.parse(message);

      if (
        data?.classification_name === tuningData.classificationName &&
        broadcastEvent === SSE_TYPES.MODEL_EVALUATION_STARTED_SSE
      ) {
        onRender();
      }
    },
    [onRender, tuningData.classificationName],
  );

  useEffect(() => {
    const unregisterSSEEvent = subscribeToRepeatedSSEEventById(
      SSE_TYPES.MODEL_EVALUATION_STARTED_SSE,
      handleEvaluationStarted,
    );

    return () => {
      unregisterSSEEvent();
    };
  }, [handleEvaluationStarted]);

  const handleEvaluationCompleted = useCallback(
    ({ message, broadcastEvent }: SSEDataMessage) => {
      const data: EvaluationCompletedData = JSON.parse(message);
      const isEvaluationCompletedForClassifier =
        data?.classification_name === tuningData.classificationName &&
        data?.model_id &&
        data?.evaluation_score &&
        broadcastEvent === SSE_TYPES.MODEL_EVALUATION_COMPLETED_SSE;

      if (isEvaluationCompletedForClassifier) {
        onRender();
      }
    },
    [onRender, tuningData.classificationName],
  );

  useEffect(() => {
    const unregisterSSEEvent = subscribeToRepeatedSSEEventById(
      SSE_TYPES.MODEL_EVALUATION_COMPLETED_SSE,
      handleEvaluationCompleted,
    );

    return () => {
      unregisterSSEEvent();
    };
  }, [handleEvaluationCompleted]);

  return (
    <Card data-aid={generateDataAid(dataAid, ['preparation', 'card'])}>
      <Icon>
        <ModelIcon />
      </Icon>
      <TextWrapper data-aid={generateDataAid(dataAid, ['card', 'text', 'summary'])}>
        {tuningData.classifierModelStage.state === ClassifierModelState.WAITING_FOR_SESSION && (
          <BigidBody1>{t('preparationCard.waitingForSessionEnd')}</BigidBody1>
        )}

        {tuningData.classifierModelStage.state === ClassifierModelState.GENERATING_MODEL && (
          <>
            <BigidBody1>{t('preparationCard.generationInProgress.firstPart')}</BigidBody1>
            <BigidBody1>{t('preparationCard.generationInProgress.secondPart')}</BigidBody1>
          </>
        )}
      </TextWrapper>
    </Card>
  );
};
