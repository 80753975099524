import React, { forwardRef, memo, useRef } from 'react';
import {
  BigidForm,
  BigidFormProps,
  BigidFormRenderProps,
  BigidFormStateAndHandlers,
  BigidFormValidateOnTypes,
  BigidHeading6,
} from '@bigid-ui/components';
import { Content, FormWrapper, getFormFields, getInitialValues, normalizeFormValues } from './utils';
import { BrandGridRow, ForwardFormRef } from './brandsTypes';
import { useLocalTranslation } from '../translations';
import { isMatch } from 'lodash';
import styled from '@emotion/styled';

const Row = styled('div')({
  gap: '24px',
  marginBottom: '24px',
});

const RowBoldLabel = styled('div')({
  gap: '24px',
  marginBottom: '24px',
  '& label p': {
    fontWeight: 700,
    paddingBottom: '4px',
  },
});

const headingStyles = { marginBottom: '8px' };

interface BrandsFormProps {
  dataAid?: string;
  row: BrandGridRow;
  formControls: React.MutableRefObject<BigidFormStateAndHandlers>;
  setFormIsTouched: (isTouched: boolean) => void;
}

export const BrandsForm = forwardRef<ForwardFormRef, BrandsFormProps>(
  ({ dataAid = 'BrandsForm', row, formControls, setFormIsTouched }, ref) => {
    const setFieldValueRef = useRef<(fieldName: string, value: any) => void>();
    const fields = getFormFields();
    const initialValues = getInitialValues(fields, row);
    const formElementRef = useRef<HTMLDivElement>();
    const { t } = useLocalTranslation();

    const formProps: BigidFormProps = {
      controlButtons: false,
      validateOn: BigidFormValidateOnTypes.SUBMIT,
      stateAndHandlersRef: formControls,
      initialValues,
      displayFormLevelError: true,
      fields,
      onChange: (values, { setFieldValue }) => {
        setFieldValueRef.current = setFieldValue;
        setFormIsTouched(!isMatch(row, normalizeFormValues(values)));
      },
      renderForm: ({ renderField, formLevelError, formLevelErrorDisplayed }: BigidFormRenderProps) => (
        <>
          <RowBoldLabel>{renderField('name')}</RowBoldLabel>
          <BigidHeading6 sx={headingStyles}>{t('form.logoTitle')}</BigidHeading6>
          <Row>{renderField('logo')}</Row>
          <BigidHeading6 sx={headingStyles}>{t('form.colorTitle')}</BigidHeading6>
          <Row>{renderField('color')}</Row>
          <BigidHeading6 sx={headingStyles}>{t('form.fontTitle')}</BigidHeading6>
          <Row>{renderField('font')}</Row>
          <BigidHeading6 sx={headingStyles}>{t('form.footerTitle')}</BigidHeading6>
          <Row>{renderField('footer')}</Row>
          <div>{formLevelErrorDisplayed && formLevelError}</div>
        </>
      ),
    };

    return (
      <Content data-aid={dataAid} ref={formElementRef}>
        <FormWrapper>
          <BigidForm {...formProps} />
        </FormWrapper>
      </Content>
    );
  },
);

export const BrandsFormMemo = memo(BrandsForm);
