import React, { useState } from 'react';
import { styled } from '@mui/material';
import { BigidFunnelChart, ChartType, FunnelChartData, BigidLoader, TertiaryButton } from '@bigid-ui/components';
import { BigidChevronDownIcon, BigidChevronRightIcon } from '@bigid-ui/icons';

type DsStateFunnelProps = {
  enabled?: boolean;
  loading?: boolean;
  chartData?: FunnelChartData[];
  onClick?: (id: string, chartType: ChartType) => void;
};

const Container = styled('div')<{
  isMinimized: boolean;
}>(({ isMinimized }) => ({
  height: isMinimized ? '53px' : '142px',
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'start',
  alignItems: 'center',
  padding: '16px 16px 0px 16px',
  transition: 'height 0.3s ease',
  overflow: 'hidden',
}));

const ChartWrapper = styled('div')`
  height: 110px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const ButtonContainer = styled('div')`
  position: absolute;
  top: 13px;
  right: 16px;
`;

const DsStateFunnelLoading = () => {
  return (
    <Container isMinimized={true}>
      <BigidLoader position="relative" />
    </Container>
  );
};

export const DsStateFunnel = ({ loading, chartData, onClick }: DsStateFunnelProps): JSX.Element => {
  const [isMinimized, setIsMinimized] = useState(false);
  return loading ? (
    <DsStateFunnelLoading />
  ) : (
    <Container isMinimized={isMinimized}>
      <ButtonContainer>
        <TertiaryButton
          endIcon={isMinimized ? <BigidChevronDownIcon /> : <BigidChevronRightIcon />}
          text={isMinimized ? 'Show more' : 'Show less'}
          onClick={() => setIsMinimized(!isMinimized)}
          size={'small'}
        />
      </ButtonContainer>

      <ChartWrapper>
        <BigidFunnelChart isMinimized={isMinimized} chartData={chartData} onChartClick={onClick} />
      </ChartWrapper>
    </Container>
  );
};
