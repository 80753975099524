import styled from '@emotion/styled';

export const Content = styled.div`
  display: flex;
  justify-content: center;
  padding: 16px 26px;
  max-width: 100%;

  form {
    max-width: 40%;
    flex: 1;

    > fieldset {
      margin: 0 0 24px;
    }

    [data-aid*='BigidFormFieldLabelWrapper-bigid-form-field-http'] {
      margin-top: 0px;
    }

    [data-aid*='checkBoxesWrapper'],
    [data-aid*='urlWrapper'] {
      display: flex;
      align-items: baseline;
      gap: 16px;
    }

    [data-aid*='BigidFormFieldLabelWrapper-select-either-to-use-http,-https,-or-both-bigid-form-field-protocolslabel'] {
      margin: 0;
    }

    [data-aid*='BigidFormFieldLabelWrapper-select-either-to-use-http,-https,-or-both-bigid-form-field-protocolslabel']
      > div:has(div) {
      display: none;
    }

    [data-aid*='BigidFormFieldLabelWrapper-bigid-form-field-certificateId'] {
      padding-top: 8px;
    }
  }
`;

export const Wrapper = styled.div`
  position: relative; 
  box-sizing: border-box;
  margin-left; 1rem;
  height: calc(100% - 2rem);
  `;

export const FormWrapper = styled.div`
  flex-grow: 1;
`;

export const LoaderWrapper = styled.div`
  height: 100%;
  position: relative;
`;
