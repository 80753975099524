import { createContext, Dispatch } from 'react';
import { ClassificationLevel, SensitivityClassificationData } from './SensitivityClassification';
import { PriorityChange } from './SensitivityClassificationForm';
import { ErrorAction, SensitivityClassificationFormErrors } from './formErrorReducer';

export interface SensitivityClassificationsContextInterface {
  sc: SensitivityClassificationData;
  setLevel: (level: ClassificationLevel) => void;
  onDeleteLevel: (level: ClassificationLevel) => void;
  onPriorityChangeLevel: (currentPriority: number, direction: PriorityChange) => void;
  onDuplicateLevel: (level: ClassificationLevel) => void;
  dispatchError: Dispatch<ErrorAction>;
  errorState: SensitivityClassificationFormErrors;
}

export const SensitivityClassificationsContext = createContext<SensitivityClassificationsContextInterface>(null);
