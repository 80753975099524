import React, { Dispatch, FC, MutableRefObject, SetStateAction, useCallback, useEffect, useState } from 'react';
import { EditActionPreset } from './EditActionPreset';
import { cloneDeep, isEmpty, isEqual } from 'lodash';
import { CustomAppAction, Preset, PresetPayload, reloadActionsPage } from '../CustomAppActions/CustomAppActions';
import { CustomAppParam } from '../EditCustomApp/EditCustomApp';
import { customAppService } from '../../../../services/customAppService';
import { notificationService } from '../../../../services/notificationService';
import { AppInfo } from '../../utils/CustomAppTypes';
import { TPATrackingEvents, trackTPAEvent } from '../../customAppEventsTrackerUtils';
import { $state, $stateParams } from '../../../../services/angularServices';

export interface EditActionPresetWrapperProps {
  appInfo: AppInfo;
  setIsValueChanged: Dispatch<SetStateAction<boolean>>;
  setIsFormValid: Dispatch<SetStateAction<boolean>>;
  onSaveMutableRef: MutableRefObject<() => void>;
  onCancelMutableRef: MutableRefObject<() => void>;
  action: CustomAppAction;
  preset?: Preset;
}

const getInitialPresetState = (globalParams: CustomAppParam[], actionParams: CustomAppParam[], preset: Preset) => ({
  name: preset?.name || '',
  description: preset?.description || '',
  paramsKeyValue: preset?.paramsKeyValue || {},
  overrideGlobalParams: !!preset?.overrideGlobalParams,
  globalParamsKeyValue: preset?.overrideGlobalParams ? preset?.globalParamsKeyValue : {},
  globalPresetId: preset?.globalPresetId,
});

export const EditActionPresetWrapper: FC<EditActionPresetWrapperProps> = ({
  appInfo,
  setIsValueChanged,
  setIsFormValid,
  onSaveMutableRef,
  onCancelMutableRef,
  action,
  preset,
}) => {
  const initialPresetPayload = getInitialPresetState(appInfo.globalParams, action.params, preset);
  const [presetData, setPresetData] = useState<PresetPayload>(initialPresetPayload);
  const [isNameValid, setIsNameValid] = useState<boolean>(true);
  const [isParametersValid, setIsParametersValid] = useState<boolean>(true);
  const [isGlobalParamsValid, setIsGlobalParamsValid] = useState<boolean>(true);

  const { onReturnState } = $stateParams;

  const onReturnCustomHandler = useCallback(() => {
    if (!onReturnState) {
      reloadActionsPage(appInfo);
    } else {
      try {
        const { url, params } = JSON.parse(onReturnState);
        return $state.go(url, params);
      } catch (err) {
        console.warn('Problem with redirect after edit with state:', onReturnState, err);
      }
    }
  }, [appInfo, onReturnState]);

  useEffect(
    () => setIsValueChanged(!isEqual(presetData, initialPresetPayload)),
    [initialPresetPayload, presetData, setIsValueChanged],
  );
  useEffect(
    () => setIsFormValid(isNameValid && isParametersValid && isGlobalParamsValid && !!presetData.name),
    [isNameValid, isParametersValid, isGlobalParamsValid, presetData.name, setIsFormValid],
  );

  const editPreset = useCallback(
    async (presetToSent: PresetPayload) => {
      try {
        await customAppService.editActionPreset(appInfo.id, action.id, preset?._id, presetToSent);
        notificationService.success(`Updated preset successfully`, { shouldCloseOnTransition: false });
      } catch (err) {
        console.error(err);
        const {
          response: { data },
        } = err;
        notificationService.error(data.error, { shouldCloseOnTransition: false });
      }
    },
    [action.id, appInfo.id, preset?._id],
  );

  const addNewPreset = useCallback(
    async (presetToSent: PresetPayload) => {
      try {
        await customAppService.addActionPreset(appInfo.id, action.id, presetToSent);
        notificationService.success(`Added new preset successfully`, { shouldCloseOnTransition: false });
      } catch (err) {
        console.error(err);
        const {
          response: { data },
        } = err;
        notificationService.error(data.error, { shouldCloseOnTransition: false });
      }
    },
    [action.id, appInfo.id],
  );

  onSaveMutableRef.current = useCallback(async () => {
    const presetToSent = cloneDeep(presetData);
    if (!presetToSent.globalParamsKeyValue) {
      presetToSent.globalParamsKeyValue = {};
    }
    presetToSent.overrideGlobalParams = !isEmpty(presetToSent.globalParamsKeyValue);
    await (isEmpty(preset) ? addNewPreset(presetToSent) : editPreset(presetToSent));
    trackTPAEvent(TPATrackingEvents.TPA_PRESET_SAVE_CLICK, {
      appName: appInfo.name,
      actionName: action.name,
      presetName: presetToSent.name,
    });
    onReturnCustomHandler();
  }, [action.name, addNewPreset, appInfo, editPreset, onReturnCustomHandler, preset, presetData]);
  onCancelMutableRef.current = () => {
    trackTPAEvent(TPATrackingEvents.TPA_PRESET_CANCEL_CLICK, {
      appName: appInfo.name,
      actionName: action.name,
    });
    onReturnCustomHandler();
  };

  const onPresetChange = (obj: PresetPayload) => setPresetData(state => ({ ...state, ...obj }));

  return (
    <EditActionPreset
      action={action}
      globalParams={appInfo.globalParams}
      preset={initialPresetPayload}
      onPresetChange={onPresetChange}
      setIsNameValid={setIsNameValid}
      setIsParametersValid={setIsParametersValid}
      setIsGlobalParamsValid={setIsGlobalParamsValid}
      appName={appInfo.name}
    />
  );
};
