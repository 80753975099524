import { httpService } from '../../services/httpService';

const daysStrValues: Record<number, string> = {
  0: 'Sunday',
  1: 'Monday',
  2: 'Tuesday',
  3: 'Wednesday',
  4: 'Thursday',
  5: 'Friday',
  6: 'Saturday',
};
const allDay = 'All Day';

export interface ScanWindowModel {
  _id?: string;
  created_at?: string;
  updated_at?: string;
  scanWindowName?: string;
  scanWindows?: ScanWindow[];
}

export interface ScanWindow {
  end: string;
  start: string;
  isAllDay: boolean;
}

type CronWindowParser = {
  minuteValuesStart: number;
  hourValuesStart: number;
  dayValuesStart: number;
  minuteValuesEnd: number;
  hourValuesEnd: number;
  dayValuesEnd: number;
};

type ScanWindowsResponse = ScanWindowModel[];
export const NONE = 'None';

export const scanWindowService = {
  getAllScanWindow: async (): Promise<ScanWindowsResponse> => {
    const { data } = await httpService.fetch<{ data: ScanWindowsResponse }>(`scan-windows`);
    return data.data;
  },

  getScanWindowsNames: async (): Promise<string[]> => {
    const { data } = await httpService.fetch<{ data: ScanWindowsResponse }>(`scan-windows`);
    const defaultName = ['None'];
    return defaultName.concat(data.data.map(sw => sw.scanWindowName));
  },

  getScanWindowByName: async (scanWindowName: string): Promise<ScanWindowsResponse> => {
    const response = await httpService.fetch<{ data: ScanWindowsResponse }>(
      `scan-windows/${encodeURIComponent(scanWindowName)}`,
    );
    return response.data.data;
  },

  createScanWindow: async (sw: ScanWindowModel): Promise<ScanWindowsResponse> => {
    const response = await httpService.post<ScanWindowsResponse, ScanWindowModel>(`scan-windows`, {
      scanWindows: sw.scanWindows,
      scanWindowName: sw.scanWindowName,
    });
    return response.data;
  },

  updateScanWindow: async (scanWindowName: string, sw: ScanWindowModel): Promise<ScanWindowModel> => {
    return (await httpService.put(`scan-windows/${encodeURIComponent(scanWindowName)}`, sw)).data;
  },

  deleteScanWindow: async (scanWindowName: string): Promise<ScanWindowModel> => {
    return (await httpService.delete(`scan-windows/${encodeURIComponent(scanWindowName)}`)).data;
  },
};

export const parseScanWindowExpression = (value: ScanWindow) => {
  const { start, end, isAllDay } = value;
  const cronStart = start.replace(/\s+/g, ' ').split(' ');
  const cronEnd = end.replace(/\s+/g, ' ').split(' ');
  let outputStr = '';
  const frequency = {
    minuteValuesStart: -1,
    hourValuesStart: -1,
    dayValuesStart: -1,
    minuteValuesEnd: -1,
    hourValuesEnd: -1,
    dayValuesEnd: -1,
  } as CronWindowParser;

  if (cronStart[0] !== '*') {
    frequency.minuteValuesStart = parseInt(cronStart[0]);
  }
  if (cronStart[1] !== '*') {
    frequency.hourValuesStart = parseInt(cronStart[1]);
  }
  if (cronStart[4] !== '*') {
    frequency.dayValuesStart = parseInt(cronStart[4]);
  }

  if (cronEnd[0] !== '*') {
    frequency.minuteValuesEnd = parseInt(cronEnd[0]);
  }
  if (cronEnd[1] !== '*') {
    frequency.hourValuesEnd = parseInt(cronEnd[1]);
  }
  if (cronEnd[4] !== '*') {
    frequency.dayValuesEnd = parseInt(cronEnd[4]);
  }

  if (frequency.dayValuesStart !== undefined) {
    const { dayValuesStart } = frequency;
    outputStr += daysStrValues[dayValuesStart];
    outputStr += ' - ';
  }

  if (isAllDay) {
    return outputStr + allDay;
  }
  const hourStart = frequency.hourValuesStart > 9 ? frequency.hourValuesStart : '0' + frequency.hourValuesStart;
  const hourEnd = frequency.hourValuesEnd > 9 ? frequency.hourValuesEnd : '0' + frequency.hourValuesEnd;
  const minsStart = frequency.minuteValuesStart > 9 ? frequency.minuteValuesStart : '0' + frequency.minuteValuesStart;
  const minsEnd = frequency.minuteValuesEnd > 9 ? frequency.minuteValuesEnd : '0' + frequency.minuteValuesEnd;

  const startTime = hourStart + ':' + minsStart;
  const endTime = hourEnd + ':' + minsEnd;

  return outputStr + startTime + ' - ' + endTime;
};
