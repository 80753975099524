import { module } from 'angular';
import { httpService } from '../react/services/httpService';

const app = module('app');

app.factory('betaToolsService', [
  '$http',
  'appSettings',
  function ($http, appSettings) {
    return {
      retryCorrelations: () =>
        $http
          .post(appSettings.serverPath + '/' + appSettings.version + '/correlation-rerun')
          .then(response => response.data),

      generateData: () =>
        $http
          .post(appSettings.serverPath + '/' + appSettings.version + '/generateData')
          .then(response => response.data),

      deleteData: stopAllRunningScans =>
        $http
          .post(appSettings.serverPath + '/' + appSettings.version + '/deleteData', {
            stopAllRunningScans: stopAllRunningScans,
          })
          .then(response => response.data),

      deleteFindings: () =>
        $http
          .post(appSettings.serverPath + '/' + appSettings.version + '/deleteFindings')
          .then(response => response.data),

      deleteClassificationFindings: () =>
        httpService.post('deleteClassificationFindings', {}).then(response => response.data),

      getScanResults: collectionName => {
        const urlSuffix = collectionName ? '/' + collectionName : '';
        return $http({
          method: 'GET',
          responseType: 'arraybuffer',
          url: appSettings.serverPath + '/' + appSettings.version + '/download-scan-results-files' + urlSuffix,
        }).then(response => response.data);
      },
      deleteClusters: () =>
        $http
          .post(appSettings.serverPath + '/' + appSettings.version + '/clusters/delete_clusters', {})
          .then(response => response.data),
      getScanLogByName: name =>
        $http
          .get(appSettings.serverPath + '/' + appSettings.version + '/logs/' + name + '?len=102400') //bring ~1024 * 100 chars
          .then(response => response.data),

      getServicesLogs: (servicesList = [], isJsonLogs) =>
        $http({
          method: 'GET',
          responseType: 'arraybuffer',
          url:
            appSettings.serverPath +
            '/' +
            appSettings.version +
            '/services-logs' +
            '?servicesList=' +
            JSON.stringify(servicesList) +
            '&jsonLogs=' +
            isJsonLogs,
        }).then(response => response.data),

      getScannerState: () =>
        $http
          .get(appSettings.serverPath + '/' + appSettings.version + '/health-checks/services-status')
          .then(response => response.data),

      getSystemState: () =>
        $http
          .get(appSettings.serverPath + '/' + appSettings.version + '/health-checks/system-state')
          .then(response => response.data),

      clearScannerHealthMap: () =>
        $http
          .delete(appSettings.serverPath + '/' + appSettings.version + '/health-checks/services-status')
          .then(response => response.data),

      getServicesConfiguration: () =>
        $http
          .get(`${appSettings.serverPath}/${appSettings.version}/services-configuration`)
          .then(response => response.data),

      downloadMongoCurrentOps: () => httpService.downloadFile('/file-download/mongodb-current-ops'),

      downloadSupportPackage: query => httpService.downloadFile(`/file-download/support-package?${query}`),

      restartServer: body =>
        $http
          .post(`${appSettings.serverPath}/${appSettings.version}/restart-service`, body)
          .then(response => response.data),
      editConfiguration: entity => httpService.put('services-configuration', entity),

      clearEntitiesCache: () => httpService.post('clear-entities-cache', {}),

      reloadEntitiesCache: () => httpService.post('reload-entities-cache', {}),

      invalidateRefreshTokenUUID: () => httpService.post('reload-refresh-token-uuid', {}),
    };
  },
]);
