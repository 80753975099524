import { APPLICATIONS_PERMISSIONS, generateCustomAppBasePermissions } from '@bigid/permissions';
import { httpService } from './httpService';
import { NavigationMenuItem } from '../views/ApplicationsManagement/applicationManagementService';
import { isPermitted } from './userPermissionsService';
import { isWorkspacesEnabled, isPrivacyPortalIntegrationEnabled } from '../utilities/featureFlagUtils';
import { sessionStorageService } from '../../common/services/sessionStorageService';

const privacyPortalReadPermissionName = generateCustomAppBasePermissions('Privacy_Portal').find(
  permission => permission.getAction() === APPLICATIONS_PERMISSIONS.READ_TPA_CUSTOM_APPS.getAction(),
).name;

const getPrivacyPortalNavLinks = async (): Promise<NavigationMenuItem[]> => {
  const isFeatureEnabled = isWorkspacesEnabled() && isPrivacyPortalIntegrationEnabled();
  const doesUserHasPermission =
    isPermitted(APPLICATIONS_PERMISSIONS.READ_TPA_CUSTOM_APPS.name) ||
    isPermitted(APPLICATIONS_PERMISSIONS.MANAGE_TPA_CUSTOM_APPS.name) ||
    isPermitted(privacyPortalReadPermissionName);
  if (isFeatureEnabled && doesUserHasPermission) {
    const { data } = await httpService.fetch(`privacy-portal/nav-links`);
    return data.data;
  }
  return [];
};

const logoutPrivacyPortal = async (): Promise<void> => {
  try {
    if (
      (isPrivacyPortalIntegrationEnabled() && isPermitted(APPLICATIONS_PERMISSIONS.READ_TPA_CUSTOM_APPS.name)) ||
      isPermitted(APPLICATIONS_PERMISSIONS.MANAGE_TPA_CUSTOM_APPS.name) ||
      isPermitted(privacyPortalReadPermissionName)
    ) {
      const token: string = sessionStorageService.get('privacyPortalToken');

      if (token) {
        await httpService.fetch('privacy-portal/logout', null, {
          token,
        });
      }
    }
  } catch (error) {
    console.error('Failed to logout from Privacy Portal:', error);
  }
};

export const privacyPortalService = {
  getPrivacyPortalNavLinks,
  logoutPrivacyPortal,
};
