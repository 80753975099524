import React from 'react';
import { BigidAvatar } from '@bigid-ui/components';
import { BigidAiRobotIcon } from '@bigid-ui/icons';
import { generateDataAid } from '@bigid-ui/utils';
import styled from '@emotion/styled';

export const BigChatBotAvatar = () => {
  return (
    <BigidAvatar
      dataAid={`${generateDataAid('BigChat', ['bot', 'avatar'])}`}
      size="medium"
      color="light_gray"
      icon={BigidAiRobotIcon}
    />
  );
};

export const BigChatUserAvatar = () => {
  return (
    <BigidAvatar data-aid={`${generateDataAid('BigChat', ['user', 'avatar'])}`} size="medium" color="light_purple" />
  );
};

const Wrapper = styled('div')(({ theme }) => ({
  '& [data-aid="BigChat-header-avatar-icon-type"]': {
    backgroundColor: 'transparent',
    border: `1px solid transparent !important`,
  },
}));

export const BigChatHeaderAvatar = () => {
  return (
    <Wrapper>
      <BigidAvatar
        dataAid={`${generateDataAid('BigChat', ['header', 'avatar'])}`}
        size="medium"
        color="light_gray"
        icon={BigidAiRobotIcon}
      />
    </Wrapper>
  );
};
