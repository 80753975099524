import React, { memo, useState } from 'react';
import { BigidInlineNotification, BigidInlineNotificationProps, BigidLink, LinkColor } from '@bigid-ui/components';
import { useLocalTranslation } from '../../../../translations';
import { PreviewComplexity } from '../../../../curationService';
import { BigidXIcon } from '@bigid-ui/icons';
import { styled } from '@mui/material';
import { docsUrls } from '../../../../../../config/publicUrls';

const NotificationWrapper = styled('div')`
  margin-bottom: 20px;
`;

interface PreviewInfoNotificationProps {
  previewComplexity: PreviewComplexity;
}

export const PreviewInfoNotification = memo(({ previewComplexity }: PreviewInfoNotificationProps) => {
  const { t } = useLocalTranslation('CuratedFields.previewInfoNotification');
  const [isOpen, setIsOpen] = useState(true);

  const handleClose = () => setIsOpen(false);

  const notificationContent = (
    <>
      {t('description')}
      <BigidLink
        color={LinkColor.PRIMARY}
        href={docsUrls.CLASSIFIER_TUNING_PREVIEW_LIMITATION}
        text={t('linkText')}
        underline="hover"
        shouldOpenNewTab
      />
    </>
  );

  const props: BigidInlineNotificationProps = {
    type: 'info',
    title: t('title'),
    dataAid: 'classifiers-tuning-info',
    actionButton: {
      icon: BigidXIcon,
      onClick: handleClose,
    },
    text: [
      {
        subText: notificationContent,
      },
    ],
  };

  const isShouldBeShown = (!previewComplexity || previewComplexity === PreviewComplexity.HIGH) && isOpen;

  return (
    isShouldBeShown && (
      <NotificationWrapper>
        <BigidInlineNotification {...props} open={true} />
      </NotificationWrapper>
    )
  );
});
