import React, { FunctionComponent, useEffect, useState, ReactText } from 'react';
import { BigidEntitiesNavigation, objectToQueryString, QueryParams } from '@bigid-ui/components';
import makeStyles from '@mui/styles/makeStyles';
import { pageHeaderService } from '../../../../common/services/pageHeaderService';
import { $stateParams, $state } from '../../../services/angularServices';
import { FileDetails } from './FileDetails';
import { notificationService } from '../../../services/notificationService';
import { getDataCatalogRecords, DataCatalogRecord } from '../DataCatalogService';

type GroupType = 'cluster' | 'duplicate';
type FileOrigin = 'overview' | 'object';

interface PreviewNavigationState {
  groupType: GroupType;
  groupId: string;
  objectId: ReactText;
  totalObjects: number;
  skip: number;
  objectName: string;
  index: number;
  fullyQualifiedName: string;
  fullObjectName: string;
  dsName: string;
  containerName: string;
  objectType: string;
  scannerType: string;
  fileOrigin?: FileOrigin;
}

const useStyles = makeStyles({
  contentWrapper: {
    display: 'flex',
    flexDirection: 'column',
    height: 'calc(100% - 32px)',
  },
  navigatorContainer: {
    display: 'flex',
    height: '32px',
    justifyContent: 'flex-end',
    margin: '0 32px 0 0',
  },
  navigationContent: {
    flex: '1 1 auto',
    height: '100%',
  },
});

export const PreviewFileWithNavigation: FunctionComponent = () => {
  const classes = useStyles({});
  const { totalObjects, groupType, groupId, skip, index, objectId, state, fileOrigin } = $stateParams;
  const [currState, setCurrState] = useState<PreviewNavigationState>({
    totalObjects,
    groupType,
    groupId,
    skip,
    objectId,
    index,
    objectName: '',
    fullyQualifiedName: '',
    fullObjectName: '',
    dsName: '',
    containerName: '',
    objectType: 'file',
    scannerType: '',
    fileOrigin,
  });
  const [objects, setObjects] = useState<DataCatalogRecord[]>([]);
  const [lastIndex, setLastIndex] = useState<number>(totalObjects);
  const [currObjectName, setCurrObjectName] = useState<string>('');

  const getStateByGroupType = (groupType: GroupType) => {
    return groupType === 'cluster' ? 'clusterObjectsPreview' : 'duplicateObjectsPreview';
  };

  const handleNavigationClick = (index: number) => {
    const selectedObject = objects[index - 1];
    $state.go(
      getStateByGroupType(groupType),
      {
        ...currState,
        objectId: selectedObject.id,
      },
      { reload: false, notify: false, inherit: true },
    );

    const { id: objectId, source: dsName, type: scannerType } = selectedObject;

    setCurrState({ ...currState, ...selectedObject, scannerType, dsName, objectId });
  };

  useEffect(() => {
    if (!currState.objectName && Array.isArray(objects) && objects.length > 0) {
      const selectedObject = objects[index - 1];
      setCurrState({
        ...currState,
        ...selectedObject,
        dsName: selectedObject.source,
        scannerType: selectedObject.type,
      });
    }

    const isFromCluster = groupType === 'cluster';
    pageHeaderService.setTitle({
      showBackButton: true,
      defaultFrom: isFromCluster
        ? { state: 'clustering', params: { clusterId: currState.groupId, selectedTab: fileOrigin } }
        : { state: 'dataCatalog', params: {} },
      breadcrumbs: isFromCluster
        ? [{ label: 'Cluster Analysis', onClick: () => $state.go('clustering') }, { label: currState.objectName }]
        : [
            { label: 'Data Catalog', onClick: () => $state.go('dataCatalog') },
            { label: 'Object Preview', onClick: () => $state.go('dataCatalog') },
            { label: currState.objectName },
          ],
    });
  }, [currState, fileOrigin, groupType, index, objects]);

  useEffect(() => {
    const getFilterByGroupType = (groupType: GroupType, id: string) => {
      return groupType === 'cluster' ? `cluster_id = ${id}` : `duplicate_id = ${id}`;
    };

    const getGroupObjects = async () => {
      try {
        const filter = {
          skip: String(skip) || '',
          limit: totalObjects,
          filter: getFilterByGroupType(groupType, groupId),
        };
        const query = objectToQueryString({ ...(filter as QueryParams) });
        const { results } = await getDataCatalogRecords(`&${query}`);
        setObjects(results);
      } catch (err) {
        notificationService.error('An error has occurred');
        console.error(`An error has occurred: ${err.message}`);
      }
    };
    getGroupObjects();
  }, [groupId, groupType, skip, totalObjects]);

  useEffect(() => {
    if (Array.isArray(objects) && objects.length > 0) {
      setLastIndex(Math.min(totalObjects, objects.length));
      const currObject = objects.find(obj => obj.id === objectId);
      if (currObject) {
        setCurrObjectName(currObject.objectName);
      }
    }
  }, [objectId, lastIndex, totalObjects, objects]);

  return (
    <div className={classes.contentWrapper}>
      <div className={classes.navigatorContainer}>
        <BigidEntitiesNavigation
          total={lastIndex}
          defaultIndex={Number(currState.index)}
          onNextClick={handleNavigationClick}
          onPrevClick={handleNavigationClick}
        />
      </div>
      <div className={classes.navigationContent}>
        <FileDetails {...currState} />
      </div>
    </div>
  );
};
