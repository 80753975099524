import React from 'react';
import {
  BigidDropdownOption,
  BigidFormField,
  BigidFormFieldLabelPosition,
  BigidFormFieldTypes,
  BigidFormValues,
  BooleanMap,
} from '@bigid-ui/components';
import { BigidGridColumnTypes, BigidGridProps, useFetch } from '@bigid-ui/grid';
import {
  BigidLayoutMasterDetails,
  BigidLayoutMasterDetailsConfig,
  BigidLayoutMasterDetailsGridRowLayouts as BigidGridRowLayouts,
  BigidLayoutMasterDetailsGridSearchTypes as BigidGridSearchType,
} from '@bigid-ui/layout';
import { ProxiesNoData } from './ProxiesNoData';
import { getFixedT } from './translations';
import { CredentialsPermissions, ProtocolType, ProxyGridRow, ProxyItemResponse, ProxyType, proxyUrl } from './types';
import { isPermitted } from '../../services/userPermissionsService';
import { PROXIES_PERMISSIONS } from '@bigid/permissions';
import { ALLOWED_NAME_REGEX } from '../../config/consts';
import { CredentialItemResponse } from '../Credentials/types';
import { CertificateModel } from '../CertificatesManagement/CertificatesManagement';

interface PrepareTestConnectionData {
  values: BigidFormValues;
  renderedFields: string[];
}

interface GetFormFields {
  credentialsOptions: CredentialItemResponse[];
  certificatesOptions: CertificateModel[];
}

const t = (key: string): string => getFixedT('')(key);

const validateProxyName = (value: string) => {
  if (!value) {
    return t('message.requiredField');
  }
  if (value.length > 50) {
    return t('message.tooLongName');
  }
  if (!value.match(ALLOWED_NAME_REGEX) || value.length > 50) {
    return t('message.invalidProxyIdPattern');
  }

  return false;
};

export function validatePortField(num: string) {
  const regexExp =
    /^((6553[0-5])|(655[0-2][0-9])|(65[0-4][0-9]{2})|(6[0-4][0-9]{3})|([1-5][0-9]{4})|([0-5]{0,5})|([0-9]{1,4}))$/gi;
  return !num || regexExp.test(num);
}

export const getFormFields = ({ credentialsOptions, certificatesOptions }: GetFormFields): BigidFormField[] => [
  {
    name: 'name',
    label: t('form.name'),
    isRequired: true,
    validate: validateProxyName,
  },
  {
    name: 'description',
    label: t('form.description'),
    type: BigidFormFieldTypes.TEXTAREA,
    misc: {
      fullWidth: true,
      rows: 5,
    },
    fieldProps: {
      placeholder: t('form.description placeholder'),
      multiline: true,
    },
  },
  {
    name: 'credentialsId',
    label: t('form.credentialsId'),
    type: BigidFormFieldTypes.DROP_DOWN,
    dropDownOptions: getCredentialsDropDownOptions(credentialsOptions),
  },
  {
    name: 'protocolsLabel',
    label: t('form.label'),
    type: BigidFormFieldTypes.TEXT,
    isRequired: true,
  },
  {
    name: 'http',
    label: t('form.http'),
    type: BigidFormFieldTypes.CHECKBOX,
    fieldProps: {
      dataAid: 'http-checkbox-dataAid',
    },
    labelPosition: BigidFormFieldLabelPosition.left,
  },
  {
    name: 'https',
    label: t('form.https'),
    type: BigidFormFieldTypes.CHECKBOX,
    labelPosition: BigidFormFieldLabelPosition.left,
  },
  {
    name: 'certificateId',
    label: t('form.certificateId'),
    type: BigidFormFieldTypes.DROP_DOWN,
    dropDownOptions: getCertificatesDropdownOptions(certificatesOptions),
  },
  {
    name: 'httpProxyHost',
    isRequired: true,
    label: t('form.httpProxyHost'),
    type: BigidFormFieldTypes.TEXT,
  },
  {
    name: 'httpProxyPort',
    label: t('form.httpProxyPort'),
    type: BigidFormFieldTypes.TEXT,
  },
  {
    name: 'httpsProxyHost',
    isRequired: true,
    label: t('form.httpsProxyHost'),
    type: BigidFormFieldTypes.TEXT,
  },
  {
    name: 'httpsProxyPort',
    label: t('form.httpsProxyPort'),
    type: BigidFormFieldTypes.TEXT,
  },
];

const getCredentialsDropDownOptions = (credentialsOptions: CredentialItemResponse[]): BigidDropdownOption[] => {
  return credentialsOptions.map(credential => {
    return {
      id: credential._id.toString(),
      value: credential.credential_id,
      displayValue: credential.credential_id,
    };
  });
};

const getCertificatesDropdownOptions = (certificatesOptions: CertificateModel[]): BigidDropdownOption[] => {
  return certificatesOptions.map(certificate => {
    return {
      id: certificate.name,
      value: { name: certificate.name, type: certificate.type },
      displayValue: `${certificate.name} (${certificate.type})`,
    };
  });
};

const getDropdownInitialValue = (field: BigidFormField, row: ProxyGridRow): BigidDropdownOption[] => {
  const { name, dropDownOptions } = field;
  return dropDownOptions.filter(option => [option.value, option.id].includes(row[name as keyof ProxyGridRow]));
};

const getUrlValue = (row: ProxyGridRow, protocol: ProtocolType, hostOrPort: keyof proxyUrl): string | number => {
  const urlObject = row.proxyUrls.find(url => url.protocol === protocol);
  return urlObject ? urlObject[hostOrPort] : '';
};

export const getInitialValues = (formFields: BigidFormField[], row: ProxyGridRow): BigidFormValues =>
  formFields.reduce<BigidFormValues>((accumulator, field) => {
    const { name, type } = field;
    const fieldName = name as keyof ProxyGridRow;
    const isDropDownType = type === BigidFormFieldTypes.DROP_DOWN;
    const isCheckBox = type === BigidFormFieldTypes.CHECKBOX;

    const getValue = () => {
      if (isDropDownType) {
        return getDropdownInitialValue(field, row);
      }

      if (isCheckBox && ['http', 'https'].includes(name)) {
        return row.proxyUrls.some(obj => obj.protocol === name);
      }

      if (['httpProxyHost', 'httpProxyPort', 'httpsProxyHost', 'httpsProxyPort'].includes(name)) {
        const protocol = name.startsWith('https') ? ProtocolType.HTTPS : ProtocolType.HTTP;
        const hostOrPort = name.endsWith('Host') ? 'host' : 'port';
        return getUrlValue(row, protocol, hostOrPort);
      }

      return row[fieldName as keyof ProxyGridRow];
    };

    return {
      ...accumulator,
      [name]: getValue(),
    };
  }, {} as BigidFormValues);

export const createLayoutConfig = (
  gridConfig: BigidGridProps<ProxyGridRow>,
): BigidLayoutMasterDetailsConfig<ProxyGridRow> => ({
  grid: gridConfig,
  search: {
    fields: ['name'],
    placeholder: t('search.placeholder'),
    type: BigidGridSearchType.INTEGRATED,
  },
});

export const createGridConfig = (
  id: string,
  config: ReturnType<typeof useFetch<ProxyGridRow>>,
): BigidGridProps<ProxyGridRow> => ({
  gridId: id,
  rows: config.rows,
  totalRowsCount: config.totalRowsCount,
  skip: config.skip,
  onPagingChanged: config.onPagingChanged,
  onSortingChanged: config.onSortingChanged,
  onFiltersChange: config.onFiltersChanged,
  defaultSorting: config.defaultSorting,
  loading: config.isLoading,
  pageSize: 1000,
  noDataContent: <ProxiesNoData message={t('message.noProxies')} />,
  columns: [
    {
      name: 'name',
      title: 'name',
      width: 'auto',
      getCellValue: row => {
        return <BigidLayoutMasterDetails.Row title={row.name} layout={BigidGridRowLayouts.TWO_ROW} />;
      },
      type: BigidGridColumnTypes.CUSTOM,
    },
  ],
});

export const getPermissions = (): CredentialsPermissions => ({
  isDeletePermitted: isPermitted(PROXIES_PERMISSIONS.DELETE.name),
  isEditPermitted: isPermitted(PROXIES_PERMISSIONS.EDIT.name),
  isCreatePermitted: isPermitted(PROXIES_PERMISSIONS.CREATE.name),
  isTestConnectionPermitted: isPermitted(PROXIES_PERMISSIONS.TEST_CONNECTION.name),
});

export const prepareRequestData = (values: BigidFormValues): Partial<ProxyItemResponse> => {
  const {
    name,
    description,
    http,
    https,
    httpProxyHost,
    httpProxyPort,
    httpsProxyHost,
    httpsProxyPort,
    credentialsId,
    certificateId,
  } = values;

  const proxyUrls = buildProxyUrlsArr(http, https, httpProxyHost, httpProxyPort, httpsProxyHost, httpsProxyPort);

  return {
    name,
    description,
    proxyType: ProxyType.DATASOURCE_PROXY,
    proxyUrls,
    ...(credentialsId.length && { credentialsId: credentialsId[0].value }),
    isUsingCertificate: certificateId.length > 0,
    ...(certificateId.length && {
      certificateId: certificateId[0].value.name,
      certificateType: certificateId[0].value.type,
    }),
  };
};

const buildProxyUrlsArr = (
  http: boolean,
  https: boolean,
  httpProxyHost: string,
  httpProxyPort: string,
  httpsProxyHost: string,
  httpsProxyPort: string,
): proxyUrl[] => {
  return [
    ...(http
      ? [
          {
            protocol: ProtocolType.HTTP,
            host: httpProxyHost,
            port: parseInt(httpProxyPort),
          },
        ]
      : []),
    ...(https
      ? [
          {
            protocol: ProtocolType.HTTPS,
            host: httpsProxyHost,
            port: parseInt(httpsProxyPort),
          },
        ]
      : []),
  ];
};

export const prepareUpdateRequestData = (values: BigidFormValues, updatedFields: BooleanMap) => {
  const shouldUpdateProxyUrls = Object.keys(updatedFields).some(field => field.includes('http'));

  const fields = Object.keys(values).reduce<BigidFormValues>((accumulator, fieldName) => {
    const isFieldUpdated = Object.keys(updatedFields).includes(fieldName);
    return {
      ...accumulator,
      ...(isFieldUpdated && { [fieldName]: values[fieldName] }),
    };
  }, {});

  const result: BigidFormValues = {
    ...fields,
    ...(shouldUpdateProxyUrls && { proxyUrls: values.proxyUrls }),
  };

  if (Object.keys(updatedFields).includes('certificateId')) {
    result.certificateType = values.certificateType;
  }

  return result;
};

export const prepareTestConnectionData = ({ values, renderedFields }: PrepareTestConnectionData) => {
  return prepareRequestData({ values, includeRequiredFields: false, renderedFields });
};
