import { BigidGridQueryComponents } from '@bigid-ui/grid';

export const getEncodedArray = (array: any[]): string => encodeURIComponent(JSON.stringify(array));

const isNumeric = (value: unknown) => !isNaN(parseInt(value as string));

const getGridConfigQuery = ({
  skip,
  limit,
  requireTotalCount = false,
  sort,
  filter,
  grouping,
}: BigidGridQueryComponents) => {
  const searchParams = new URLSearchParams();
  isNumeric(skip) && searchParams.set('skip', String(skip));
  isNumeric(limit) && searchParams.set('limit', String(limit));
  searchParams.set('requireTotalCount', String(requireTotalCount));
  filter?.length && searchParams.set('filter', JSON.stringify(filter));
  sort?.length && searchParams.set('sort', JSON.stringify(sort));
  grouping?.length && searchParams.set('grouping', JSON.stringify(grouping));
  const searchParamsString = searchParams.toString();
  return searchParamsString;
};

export const queryService = { getGridConfigQuery };
