import React, { FC, useRef, useState, useEffect, useCallback, MutableRefObject, useMemo } from 'react';
import { styled } from '@mui/material';
import { BigidForm, BigidFormStateAndHandlers, BigidFormValidateOnTypes, BigidFormValues } from '@bigid-ui/components';
import { notificationService } from '../../../../../services/notificationService';
import { useAssignCollaboratorFormConfig } from './useAssignCollaboratorFormConfig';
import { AssignCollaboratorProps } from './AssignCollaborator';
import { useLocalTranslation } from './translations';
import { assignCollaborator } from './assignCollaboratorService';

export type DialogFormControl = {
  execute: () => Promise<BigidFormValues>;
};

export type AssignCollaboratorFormStateAndControls = {
  isBusy?: boolean;
  onInvite?: DialogFormControl;
};

export interface AssignCollaboratorFormProps extends Pick<AssignCollaboratorProps, 'curatedDataSource'> {
  dataAid?: string;
  dialogControls: MutableRefObject<AssignCollaboratorFormStateAndControls>;
  onDialogControlsChange: (dialogControls: AssignCollaboratorFormStateAndControls) => void;
}

const Root = styled('div')`
  height: 70vh;
  flex: 1;
  position: relative;
`;

export const AssignCollaboratorForm: FC<AssignCollaboratorFormProps> = ({
  dataAid = 'AssignCollaboratorForm',
  curatedDataSource,
  dialogControls,
  onDialogControlsChange,
}) => {
  const { t } = useLocalTranslation('AssignCollaboratorForm');
  const formControls = useRef<BigidFormStateAndHandlers>();
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const { fields, isFormInitialised } = useAssignCollaboratorFormConfig(curatedDataSource.source);

  const initialValues = useMemo(() => {
    return {
      email: curatedDataSource.collaborator
        ? [
            {
              id: curatedDataSource.collaborator,
              displayValue: curatedDataSource.collaborator,
              value: curatedDataSource.collaborator,
              isSelected: true,
            },
          ]
        : [],
    };
  }, [curatedDataSource.collaborator]);

  const submitForm = useCallback(
    async (formData: BigidFormValues): Promise<BigidFormValues> => {
      const { email } = formData;
      const collaborator = email[0]?.value;

      try {
        setIsSubmitting(true);

        const isSuccessfullyAssigned = await assignCollaborator({ collaborator, curatedDataSource });

        if (isSuccessfullyAssigned) {
          notificationService.success(
            t('notifications.success', { dataSourceName: curatedDataSource.source, collaborator }),
          );
        } else {
          notificationService.error(
            t('notifications.error', {
              dataSourceName: curatedDataSource.source,
              collaborator,
            }),
          );
        }

        return isSuccessfullyAssigned ? formData : null;
      } catch ({ message }) {
        console.error(`An error has occurred: ${message}`);
        notificationService.error(
          t('notifications.error', {
            dataSourceName: curatedDataSource.source,
            collaborator: email,
          }),
        );

        return null;
      } finally {
        setIsSubmitting(false);
      }
    },
    [curatedDataSource, t],
  );

  const dialogFormControls: AssignCollaboratorFormStateAndControls = {
    isBusy: isSubmitting || !isFormInitialised,
    onInvite: {
      execute: async () => {
        return new Promise(resolve => {
          formControls.current.validateAndSubmit(async values => {
            const formData = await submitForm(values);

            resolve(formData);
          });
        });
      },
    },
  };

  useEffect(() => {
    if (dialogControls) {
      onDialogControlsChange(dialogFormControls);
      dialogControls.current = dialogFormControls;
    }
  });

  return (
    <Root data-aid={dataAid}>
      <BigidForm
        stateAndHandlersRef={formControls}
        controlButtons={false}
        fields={fields}
        initialValues={initialValues}
        validateOn={BigidFormValidateOnTypes.SUBMIT}
      />
    </Root>
  );
};
