import React, { FC, useState, useMemo } from 'react';
import { BigidDownloadIcon } from '@bigid-ui/icons';
import {
  PrimaryButton,
  SecondaryButton,
  TertiaryButton,
  StyledButtonType,
  BigidTooltip,
  BigidButtonIcon,
} from '@bigid-ui/components';
import { notificationService } from '../../services/notificationService';
import { generatePDFFromNodeElement } from '../../services/DomToPDFService';

export enum ButtonType {
  PRIMARY = 'primary',
  SECONDARY = 'secondary',
  TERTIARY = 'tertiary',
}

export interface DomToPdfButtonProps {
  selectorQueryToFindElementToCapture: string;
  header: string;
  subHeader?: string;
  fileName: string;
  useButton?: boolean;
  buttonText?: string;
  buttonType?: ButtonType;
  buttonSize?: 'small' | 'medium' | 'large';
}

const getButtonElement = (buttonType: ButtonType) => {
  switch (buttonType) {
    case ButtonType.SECONDARY:
      return SecondaryButton;
    case ButtonType.TERTIARY:
      return TertiaryButton;
    default:
      return PrimaryButton;
  }
};

export const DomToPdfButton: FC<DomToPdfButtonProps> = ({
  selectorQueryToFindElementToCapture = '',
  header = '',
  subHeader = '',
  fileName = 'pdf_capture',
  useButton = false,
  buttonText = 'Export as PDF',
  buttonType = ButtonType.PRIMARY,
  buttonSize = 'medium',
}) => {
  const [isInProcess, setIsInProcess] = useState<boolean>(false);
  const ButtonElement: FC<StyledButtonType> = getButtonElement(buttonType);
  const elementToCapture = useMemo(
    () => document.querySelector(selectorQueryToFindElementToCapture),
    [selectorQueryToFindElementToCapture],
  );

  const handleDownloadPdf = () => {
    if (isInProcess) return;
    try {
      setIsInProcess(true);
      notificationService.success('Download will start in few seconds');
      setTimeout(async () => {
        await generatePDFFromNodeElement(elementToCapture, header, subHeader, fileName);
        setIsInProcess(false);
      }, 0);
    } catch (err) {
      notificationService.error('Download failed, please try again later');
    }
  };

  return (
    <>
      {!useButton && (
        <BigidTooltip title={buttonText} placement={'bottom'}>
          <span>
            <BigidButtonIcon
              icon={BigidDownloadIcon}
              onClick={handleDownloadPdf}
              disabled={isInProcess}
              dataAid="export-to-pdf-button"
            />
          </span>
        </BigidTooltip>
      )}
      {useButton && (
        <ButtonElement
          size={buttonSize}
          onClick={handleDownloadPdf}
          disabled={isInProcess || !elementToCapture}
          text={buttonText}
        />
      )}
    </>
  );
};
