import React, { FC } from 'react';
import { BigidDialog, PrimaryButton, SecondaryButton } from '@bigid-ui/components';
import { generateDataAid } from '@bigid-ui/utils';
import { FmsdTrackingEvents } from '../../fmsdEventsTrackerUtils';
import { useLocalTranslation } from '../../translations';

export interface OverwriteSettingsDialogProps {
  onConfirm: () => void;
  isOpen: boolean;
  updateIsDialogOpen: (newValue: boolean) => void;
}

export const OverwriteSettingsDialog: FC<OverwriteSettingsDialogProps> = ({
  onConfirm,
  isOpen,
  updateIsDialogOpen,
}) => {
  const { t } = useLocalTranslation('ConfirmOverwriteDialog');

  const onCloseClick = () => {
    updateIsDialogOpen(false);
  };

  const onConfirmClick = () => {
    onConfirm();
    updateIsDialogOpen(false);
  };

  const buttons = [
    {
      text: t('cancel'),
      onClick: onCloseClick,
      component: SecondaryButton,
      dataAid: generateDataAid('FmsdPreDiscover', ['confirmOverwriteCategoriesDialog', 'No']),
      bi: {
        eventType: FmsdTrackingEvents.FMSD_CATEGORIES_CATEGORY_OVERWRITE_DIALOG_NO_CLICK,
      },
    },
    {
      text: t('confirm'),
      onClick: onConfirmClick,
      component: PrimaryButton,
      disabled: false,
      dataAid: generateDataAid('FmsdPreDiscover', ['confirmOverwriteCategoriesDialog', 'Yes']),
      bi: {
        eventType: FmsdTrackingEvents.FMSD_CATEGORIES_CATEGORY_OVERWRITE_DIALOG_YES_CLICK,
      },
    },
  ];
  return (
    <BigidDialog
      showCloseIcon
      isOpen={isOpen}
      onClose={onCloseClick}
      buttons={buttons}
      title={t('title')}
      maxWidth="sm"
    >
      {t('body')}
    </BigidDialog>
  );
};
