import React, { FC, ReactNode, useMemo } from 'react';
import { styled } from '@mui/material';
import { BigidColorsV2, BigidLoader, BigidProgressBarSimple, BigidBody1, BigidTooltip } from '@bigid-ui/components';
import { BigidInfoIcon } from '@bigid-ui/icons';
import { generateDataAid } from '@bigid-ui/utils';
import { AggregationItemBase } from '../../catalogDiscoveryTypes';
import { formatNumberCompact } from '../../../../utilities/numericDataConverter';
import { maxBy } from 'lodash';

export type BarChartWidgetItem<ItemType = AggregationItemBase> = {
  name: string;
  value: number;
  color: string;
  aggItem: ItemType;
  isActive?: boolean;
};

export interface BarChartWidgetProps<ItemType = AggregationItemBase> {
  dataAid?: string;
  dataTourId?: string;
  title: string;
  noDataText: string;
  data: BarChartWidgetItem<ItemType>[];
  height?: string;
  width?: string;
  isBusy?: boolean;
  tooltipText?: ReactNode;
  onItemClick?: (item: BarChartWidgetItem<ItemType>) => void;
  itemNameWidth?: string;
  isSidePanelWidget?: boolean;
}

const Root = styled('div')<Pick<BarChartWidgetProps, 'height' | 'width'>>`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 18px;
  height: ${({ height }) => height};
  width: ${({ width }) => width};
`;

const Header = styled('div')`
  display: flex;
  flex: 0 0 24px;
  align-items: center;
`;

const Title = styled(BigidBody1)`
  font-weight: bold;
`;

const Info = styled('div')`
  display: flex;
  margin-left: 8px;
`;

const Items = styled('div')<{ isTransparent: boolean }>`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
`;

const Item = styled('div')<{ isInactive: boolean; isClickable: boolean }>`
  height: 20px;
  display: flex;
  opacity: ${({ isInactive }) => (isInactive ? '0.5' : 1)};
  cursor: ${({ isClickable }) => (isClickable ? 'pointer' : 'default')};
`;

const ItemName = styled(BigidBody1)<{ isHighlighted: boolean; itemNameWidth: string }>`
  width: ${({ itemNameWidth }) => itemNameWidth};
  text-overflow: ellipsis;
  overflow: hidden;
  font-weight: ${({ isHighlighted }) => (isHighlighted ? 'bold' : 'normal')};
  white-space: nowrap;
`;

const ItemBar = styled('div')`
  display: flex;
  align-items: center;
  flex: 1;
`;

const ItemValue = styled(BigidBody1)<{ isHighlighted: boolean }>`
  width: 60px;
  font-weight: ${({ isHighlighted }) => (isHighlighted ? 'bold' : 'normal')};
  text-align: end;
`;

const ItemBarWrapper = styled('div')`
  width: calc(100% - 60px);
  padding-left: 12px;
`;

const EmptyState = styled('div')`
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
`;

const spinnerSize = 42;
const spinnerThickness = 6;

export const BarChartWidget: FC<BarChartWidgetProps> = ({
  dataAid = 'BarChartWidget',
  dataTourId = 'BarChartWidget',
  title,
  noDataText,
  data = [],
  isBusy,
  tooltipText,
  height = '100%',
  width = '100%',
  onItemClick,
  itemNameWidth = '25%',
  isSidePanelWidget = false,
}: BarChartWidgetProps) => {
  const { maxValue, isEmpty, hasActiveItem } = useMemo(() => {
    return {
      maxValue: maxBy(data, ({ value }) => value)?.value ?? 0,
      isEmpty: data.length === 0,
      hasActiveItem: Boolean(data.find(({ isActive }) => isActive)),
    };
  }, [data]);

  return (
    <Root height={height} width={width} data-aid={dataAid} data-tour-id={dataTourId}>
      {isBusy && (
        <BigidLoader data-aid={generateDataAid(dataAid, ['loader'])} size={spinnerSize} thickness={spinnerThickness} />
      )}
      {!isBusy && (
        <>
          <Header>
            <Title data-aid={generateDataAid(dataAid, ['title'])}>{title}</Title>
            <BigidTooltip title={tooltipText} isDisabled={!tooltipText}>
              <Info>
                <BigidInfoIcon dataAid={generateDataAid(dataAid, ['info', 'icon'])} staticMode />
              </Info>
            </BigidTooltip>
          </Header>
          {isEmpty ? (
            <EmptyState>
              <BigidBody1 data-aid={generateDataAid(dataAid, ['no-data'])}>{noDataText}</BigidBody1>
            </EmptyState>
          ) : (
            <Items isTransparent={isBusy}>
              {data.map((item, index) => {
                const { name, value, color, isActive } = item;
                const valueFormatted = formatNumberCompact(value);
                const isClickable = value > 0 && Boolean(onItemClick);

                return (
                  <Item
                    key={index}
                    data-aid={generateDataAid(dataAid, ['item', name])}
                    isInactive={isSidePanelWidget ? value === 0 : (hasActiveItem && !isActive) || !isClickable}
                    isClickable={isClickable}
                    onClick={() => isClickable && onItemClick?.(item)}
                  >
                    <BigidTooltip title={name} followCursor>
                      <ItemName
                        data-aid={generateDataAid(dataAid, ['item', name, 'name'])}
                        isHighlighted={isActive}
                        itemNameWidth={itemNameWidth}
                      >
                        {name}
                      </ItemName>
                    </BigidTooltip>
                    <ItemBar data-aid={generateDataAid(dataAid, ['item', name, 'bar'])}>
                      <ItemValue data-aid={generateDataAid(dataAid, ['item', name, 'value'])} isHighlighted={isActive}>
                        {valueFormatted}
                      </ItemValue>
                      <ItemBarWrapper>
                        <BigidProgressBarSimple
                          max={maxValue}
                          value={value > 0 ? value : maxValue}
                          color={color}
                          bgColor={BigidColorsV2.white}
                          hasRoundedCorners
                          isCurrentValueHidden
                        />
                      </ItemBarWrapper>
                    </ItemBar>
                  </Item>
                );
              })}
            </Items>
          )}
        </>
      )}
    </Root>
  );
};
