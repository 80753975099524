import React, { createContext } from 'react';
import { DataSourceConnectionFormField } from '../types';

export interface DataSourceBulkUpdateContextInterface {
  bulkDataSourceUpdateHandlersRef: any;
  setDataSourceBulkUpdateState: SetDataSourceBulkUpdateState;
  dataSourceBulkUpdateState: DataSourceBulkUpdateState;
  isSingleDataSourceType: boolean;
  selectedRowIds: string[];
  isShowMultipleTypesSelectedMessage?: boolean;
}

export interface BulkUpdateDataSourceFormField extends DataSourceConnectionFormField {
  isAddDivider?: boolean;
}

export interface DataSourceBulkUpdateState {
  fields: BulkUpdateDataSourceFormField[];
  defaultFields: BulkUpdateDataSourceFormField[];
}

export type SetDataSourceBulkUpdateState = React.Dispatch<React.SetStateAction<DataSourceBulkUpdateState>>;

export const DataSourceBulkUpdateContext = createContext<DataSourceBulkUpdateContextInterface>(null);
