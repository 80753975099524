import React, { FC, useCallback, useMemo } from 'react';
import {
  BigidSkeletonGenerator,
  BigidAdvancedToolbarDropdownFilter,
  BigidAdvancedToolbarFilter,
  BigidAdvancedToolbarFilterUnion,
  AdvancedToolbarOverrideValueStrategy,
  SelectedOptionsConfig,
  advancedToolbarFilterMinifier,
} from '@bigid-ui/components';
import { useQuery } from 'react-query';
import {
  SectionName,
  loadAccessRightsFilter,
  loadAccessType,
  loadApplications,
  loadAttributes,
  loadCloudTypes,
  loadClusters,
  loadCreatedDateFilter,
  loadDataSources,
  loadDuplicatesFilters,
  loadExtendedFileTypes,
  loadFileTypes,
  loadFindingsFilter,
  loadIntegrations,
  loadModifiedDateFilter,
  loadPolicies,
  loadQuickFilters,
  loadSavedQueryFilters,
  loadScanStatus,
  loadTags,
} from './NewDataCatalogFilter';
import { getApplicationPreference } from '../../../services/appPreferencesService';
import { $stateParams } from '../../../services/angularServices';
import { UserPreference } from '../../../services/userPreferencesService';
import { isPermitted } from '../../../services/userPermissionsService';
import { CLUSTER_ANALYSIS_PERMISSIONS, TAGS_PERMISSIONS } from '@bigid/permissions';
import { mapCatalogFiltersToFilterString, mapSideFilterToAdvancedDropdownFilter } from './newDataCatalogUtils';
import { DataCatalogContext, useDataCatalogContext } from './newDataCatalogContext';
import { styled } from '@mui/material';
import { updateNewCatalogFilterPreferences } from './newDataCatalogService';
import { NewDataCatalogPagePreferences, WithDataAid } from './types';
import { generateDataAid } from '@bigid-ui/utils';

const ToolbarContainer = styled('div')`
  padding: 12px 16px;
  border-bottom: 1px solid ${({ theme }) => theme.palette.grey[300]};
`;

export const NewDataCatalogToolbar: FC<WithDataAid> = ({ dataAid = 'DataCatalog' }) => {
  const { filter } = $stateParams;
  const { dispatch, overrideFilterSelections } = useDataCatalogContext();
  let preferences: UserPreference<NewDataCatalogPagePreferences>;

  const {
    isTagsReadPermitted,
    isClusteringEnabled,
    isDataCatalogFileTypeEnabled,
    isDataCatalogExtendedFileTypeEnabled,
    disableLegacyACL,
  } = useMemo(
    () => ({
      isTagsReadPermitted: isPermitted(TAGS_PERMISSIONS.READ.name),
      isClusteringEnabled:
        getApplicationPreference('CLUSTERING_ENABLED') && isPermitted(CLUSTER_ANALYSIS_PERMISSIONS.ACCESS.name),
      isDataCatalogFileTypeEnabled: Boolean(getApplicationPreference('DATA_CATALOG_FILE_TYPE_ENABLED')),
      isDataCatalogExtendedFileTypeEnabled: Boolean(
        getApplicationPreference('DATA_CATALOG_EXTENDED_FILE_TYPE_ENABLED'),
      ),
      disableLegacyACL: Boolean(getApplicationPreference('DISABLE_LEGACY_ACL_FF')),
    }),
    [],
  );

  // Dynamically construct the array of promises based on conditions
  const fetchFilters = async () => {
    const advancedFilters: BigidAdvancedToolbarFilterUnion[] = [
      loadFindingsFilter(),
      loadModifiedDateFilter(),
      loadCreatedDateFilter(),
      loadDuplicatesFilters(),
    ];

    if (!disableLegacyACL) {
      advancedFilters.push(loadAccessRightsFilter());
    }

    const promises = [
      loadAttributes({ filter, preferences }).then(filter => {
        advancedFilters.push(mapSideFilterToAdvancedDropdownFilter({ ...filter, isEnabled: true }));
      }),
      loadDataSources({ filter, preferences }).then(filter => {
        advancedFilters.push(mapSideFilterToAdvancedDropdownFilter({ ...filter, isEnabled: true }));
      }),
      loadScanStatus({ filter, preferences }).then(filter => {
        advancedFilters.push(mapSideFilterToAdvancedDropdownFilter(filter));
      }),
      loadApplications({ filter, preferences }).then(filter => {
        advancedFilters.push(mapSideFilterToAdvancedDropdownFilter(filter));
      }),
      loadCloudTypes({ filter, preferences }).then(filter => {
        advancedFilters.push(mapSideFilterToAdvancedDropdownFilter(filter));
      }),
      loadPolicies({ filter, preferences }).then(filter =>
        advancedFilters.push(mapSideFilterToAdvancedDropdownFilter(filter)),
      ),
      // Add conditional promises
      ...(isDataCatalogFileTypeEnabled
        ? [
            loadFileTypes({ filter, preferences }).then(filter => {
              advancedFilters.push(mapSideFilterToAdvancedDropdownFilter(filter));
            }),
          ]
        : []),
      ...(isDataCatalogExtendedFileTypeEnabled
        ? [
            loadExtendedFileTypes({ filter, preferences }).then(filter =>
              advancedFilters.push(mapSideFilterToAdvancedDropdownFilter(filter)),
            ),
          ]
        : []),
      ...(isTagsReadPermitted
        ? [
            loadTags({ filter, preferences }, SectionName.TAGS).then(tagFilters => {
              tagFilters.forEach(filter => {
                const mappedFilter = mapSideFilterToAdvancedDropdownFilter(
                  { ...filter, isEnabled: true },
                  { isTagFilter: true },
                );
                advancedFilters.push(mappedFilter);
              });
            }),
            loadTags({ filter, preferences }, SectionName.DS_TAGS).then(tagFilters => {
              tagFilters.forEach(filter => {
                advancedFilters.push(mapSideFilterToAdvancedDropdownFilter(filter, { isTagFilter: true }));
              });
            }),
            loadIntegrations({ filter, preferences }).then(filters => {
              if (Array.isArray(filters)) {
                filters.forEach(filter => advancedFilters.push(mapSideFilterToAdvancedDropdownFilter(filter)));
              }
            }),
            loadAccessType({ filter, preferences }, SectionName.ACCESS_TYPE).then(filter =>
              advancedFilters.push(mapSideFilterToAdvancedDropdownFilter(filter)),
            ),
          ]
        : []),
      ...(isClusteringEnabled
        ? [
            loadClusters({ filter, preferences }).then(filter =>
              advancedFilters.push(mapSideFilterToAdvancedDropdownFilter(filter)),
            ),
          ]
        : []),
      loadSavedQueryFilters().then(filters => advancedFilters.push(...filters)),
    ];

    await Promise.allSettled(promises);

    return advancedFilters;
  };

  // Use the useQuery hook with the dynamically constructed promise array
  const { data: filters, isLoading } = useQuery(['dataCatalogFetching'], fetchFilters, {
    // You can add options here
    placeholderData: [],
  });

  const handleFilterChange = useCallback(
    async (filters: BigidAdvancedToolbarFilterUnion[]) => {
      const filterString = mapCatalogFiltersToFilterString(filters as BigidAdvancedToolbarDropdownFilter[]);

      const payload: Partial<DataCatalogContext> = {
        selectedFilters: filters,
        isCustomQuery: false,
        filterString,
      };

      dispatch({
        type: 'UPDATE_STATE',
        payload,
      });

      const overrideValues = filters.map(advancedToolbarFilterMinifier.getFilterCompressedToOverrideValue);

      await updateNewCatalogFilterPreferences({
        filterString,
        overrideValues,
      });
    },
    [dispatch],
  );

  const selectedOptionsConfig = useMemo<SelectedOptionsConfig>(() => {
    if (overrideFilterSelections.length > 0) {
      return {
        strategy: AdvancedToolbarOverrideValueStrategy.INIT_WITHOUT_RELOAD_OVERRIDE,
        values: overrideFilterSelections,
      };
    }
  }, [overrideFilterSelections]);

  const componentToRender = useMemo(() => {
    if (isLoading || !filters.length) {
      return (
        <BigidSkeletonGenerator
          alignment={{ horizontal: 'flex-start', type: 'flex' }}
          items={[
            {
              height: '30px',
              multiplicator: 5,
              width: '180px',
            },
          ]}
        />
      );
    }

    if (filters.length > 0) {
      return (
        <BigidAdvancedToolbarFilter
          shouldAsyncFiltersReload
          dataAid={generateDataAid(dataAid, ['toolbar'])}
          filters={filters}
          onFiltersChange={handleFilterChange}
          selectedOptionsConfig={selectedOptionsConfig}
        />
      );
    }

    return null;
  }, [filters, isLoading, selectedOptionsConfig, handleFilterChange, dataAid]);

  return <ToolbarContainer>{componentToRender}</ToolbarContainer>;
};
