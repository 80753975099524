import { queryService } from '../../../services/queryService';
import { notificationService } from '../../../services/notificationService';
import { CorrelationSetModel, CorrelationSetsResponse } from './CorrelationSetConnectionTypes';
import { httpService } from '../../../services/httpService';
import { BigidGridQueryComponents } from '@bigid-ui/grid';
import { uniqBy } from 'lodash';

const CORRELATION_SET_CONNECTION_ROUTE = 'correlation-set-connections';

export const fetchCorrelationSets = async (
  gridConfigQuery: string,
): Promise<{ correlation_sets: CorrelationSetModel[]; count: number }> => {
  const {
    data: {
      data: { correlation_sets, totalCount: count },
    },
  } = await httpService.fetch<{ data: CorrelationSetsResponse }>(
    `${CORRELATION_SET_CONNECTION_ROUTE}?${gridConfigQuery}`,
  );
  return { correlation_sets, count };
};

export const fetchCorrelationSetsData = async (queryComponents: BigidGridQueryComponents) => {
  try {
    const gridConfigQuery = queryService.getGridConfigQuery({ ...queryComponents });

    const { correlation_sets, count } = await fetchCorrelationSets(gridConfigQuery);

    return {
      data: correlation_sets,
      totalCount: count,
    };
  } catch (e) {
    console.error(e);
    notificationService.error('Could not fetch correlation set data. See logs for more information');
    return {
      data: [],
      totalCount: 0,
    };
  }
};

export async function fetchUniqueDataSourcesWithCorrelationSets() {
  const {
    data: {
      data: { correlation_sets = [] },
    },
  } = await httpService.fetch<{ data: CorrelationSetsResponse }>(CORRELATION_SET_CONNECTION_ROUTE, {
    fields: JSON.stringify(['dsConnection']),
  });

  return uniqBy(
    correlation_sets.map(({ dsConnection }) => ({
      label: dsConnection,
      value: dsConnection,
      isSelected: false,
    })),
    'value',
  );
}

export const getPartialCorrelationsIds = (correlations: CorrelationSetModel[]) => {
  const correlationSetsWithPartial = correlations.filter(({ attributes }) =>
    attributes.some(attr => attr?.partialCorrelationSet),
  );
  return correlationSetsWithPartial.map(({ name }) => name);
};
