import './newIdentityConnection.component.scss';
import { v4 as uuid } from 'uuid';
import { module } from 'angular';
import template from './newIdentityConnection.component.html';
import { CORRELATION_SET_PERMISSIONS } from '@bigid/permissions';
import { isPermitted } from '../../../../react/services/userPermissionsService';
import { getDsConnectionsQuery, getDataSourcesListWithSelection } from '../../../../react/utilities/dataSourcesUtils';
import { getApplicationPreference } from '../../../../react/services/appPreferencesService';
const app = module('app');

app.component('newidentity', {
  template,
  controllerAs: 'newIdentityConnectionModel',
  controller: function (
    $http,
    appSettings,
    $scope,
    $state,
    $stateParams,
    notificationService,
    identityConnectionsService,
    $rootScope,
    uiGridConstants,
    dataSourceConnectionsService,
    DeleteConfirmation,
    $translate,
  ) {
    'ngInject';
    const availableTypeOptions = [
      { id: '1', name: 'DS-Connection', modelProp: 'isDsConnection' },
      { id: '2', name: 'MySQL', modelProp: 'isMySql' },
      { id: '3', name: 'Postgres', modelProp: 'isPostgres' },
      { id: '4', name: 'MS-SQL', modelProp: 'isMsSql' },
      { id: '5', name: 'Oracle', modelProp: 'isOracle' },
      { id: '6', name: 'Salesforce', modelProp: 'isSalesForce' },
    ];

    const availableEnableOptions = [
      { id: '1', name: 'Yes' },
      { id: '2', name: 'No' },
    ];

    const newIdentityConnectionModel = this;

    const COMPOSITE_KEY_MIN_COUNT = 2;
    const COMPOSITE_KEY_MAX_COUNT = 4;
    const RESET_RESIDENCY = 'None';
    const UNIQUE_ID_DEFAULT_DELIMETER = ',';
    const SAMPLE_SIZE_SAFE_EDGE = Math.pow(10, 5);

    const DS_SUPPORTED_COMPOSITE_UNIQUE_ID = [
      'cassandra',
      'cassandra-v2',
      'rdb-db2',
      'rdb-db2iseries',
      'rdb-hana',
      'hive-jdbc',
      'rdb-mysql',
      'rdb-mssql',
      'rdb-oracle',
      'rdb-postgresql',
      'presto',
      'rdb-sybase',
      'rdb-teradata',
    ];

    const HYBRID_CONNECTORS = ['adls-gen2', 'google-cloud-storage-v2', 's3-v2', 'azure-synapse'];

    const FLAT_CONNECTORS = [
      'sas',
      'vertica-v2',
      'mysql-v2',
      'rds-mysql',
      'rds-mariadb',
      'rds-aurora-mysql',
      'workday-v2',
      'workday-v2-wql',
      'athena-v2',
      'oracle-v2',
      'rds-oracle',
    ];

    const SUB_FLAT_CONNECTORS = [
      'mssql-v2',
      'rds-mssql',
      'postgresql-v2',
      'rds-postgresql',
      'rds-aurora-postgresql',
      'snowflake-v2',
    ];

    const NON_LEGACY_CONNECTORS = [...FLAT_CONNECTORS, ...SUB_FLAT_CONNECTORS];

    let sampleSizeChangeIsConfirmed = false;

    newIdentityConnectionModel.isTestConnectionSucceeded = false;
    newIdentityConnectionModel.isNewPassword = false;
    newIdentityConnectionModel.realPass = '';
    newIdentityConnectionModel.fields = {
      custom_query_string: 'SELECT * FROM table',
      identities_force_sample: true,
      identities_sample_size: '',
      skip_sample_start: false,
      skip_sample_size: 1000,
      use_random_sampling: false,
    };

    newIdentityConnectionModel.compositeKeyMin = COMPOSITE_KEY_MIN_COUNT;
    newIdentityConnectionModel.compositeKeyMax = COMPOSITE_KEY_MAX_COUNT;

    newIdentityConnectionModel.isUniqueIdCompositeMode = false;

    newIdentityConnectionModel.fieldsDDL = {};
    newIdentityConnectionModel.attributes = [];

    newIdentityConnectionModel.availableDsConnections = [];

    newIdentityConnectionModel.isCustomQueryMode = false;
    newIdentityConnectionModel.customQuerySelection = [];

    newIdentityConnectionModel.selectedDataSource = {};
    newIdentityConnectionModel.isExtendedSamplingMode = false;

    newIdentityConnectionModel.$onInit = () => {
      newIdentityConnectionModel.isCreatePermitted = isPermitted(CORRELATION_SET_PERMISSIONS.CREATE.name);
      newIdentityConnectionModel.isEditPermitted = isPermitted(CORRELATION_SET_PERMISSIONS.EDIT.name);
      newIdentityConnectionModel.isSavePermitted = $stateParams.id
        ? newIdentityConnectionModel.isEditPermitted
        : newIdentityConnectionModel.isCreatePermitted;
      newIdentityConnectionModel.isDeletePermitted = isPermitted(CORRELATION_SET_PERMISSIONS.DELETE.name);
      newIdentityConnectionModel.isTestConnectionPermitted = isPermitted(
        CORRELATION_SET_PERMISSIONS.TEST_CONNECTION.name,
      );

      newIdentityConnectionModel.clickedToAction = false;
      if (newIdentityConnectionModel.isDsConnection) {
        newIdentityConnectionModel.update('DS-Connection');
      }
    };

    newIdentityConnectionModel.dsType = '';
    newIdentityConnectionModel.MIN_SECONDS = 10;
    newIdentityConnectionModel.MAX_SECONDS = 7200;

    newIdentityConnectionModel.compositeAttributesSource = [];
    newIdentityConnectionModel.compositeAttributes = [];

    newIdentityConnectionModel.removeTestConnectionListener = null;

    newIdentityConnectionModel.$onDestroy = () => {
      if (newIdentityConnectionModel.removeTestConnectionListener) {
        newIdentityConnectionModel.removeTestConnectionListener();
      }
    };

    const selectedType = $stateParams.id;
    //case of update
    if (selectedType) {
      identityConnectionsService.getIdentityConnectionsDataByID(selectedType).then(function (result) {
        if (result && result.id_connection) {
          if (result.id_connection.type === 'ds-connection') {
            initDataSourcesSelection(result.id_connection.dsConnection);
          }

          newIdentityConnectionModel.realPass = angular.copy(result.id_connection.db_pw);
          newIdentityConnectionModel.fields.name = result.id_connection.name;
          if (result.id_connection.type) {
            newIdentityConnectionModel.fieldsDDL.selectedTypeOption = getSelectedObject(
              result.id_connection.type,
              availableTypeOptions,
            ); //result.id_connection.selectedTypeOption;
          }
          if (result.id_connection.enabled) {
            newIdentityConnectionModel.fieldsDDL.selectedEnabledOption = getSelectedObject(
              result.id_connection.enabled,
              availableEnableOptions,
            );
          }
          if (typeof result.id_connection.identities_force_sample != 'undefined') {
            newIdentityConnectionModel.fields.identities_force_sample = result.id_connection.identities_force_sample;
          }
          if (typeof result.id_connection.identities_sample_size != 'undefined') {
            newIdentityConnectionModel.fields.identities_sample_size = result.id_connection.identities_sample_size;
          }
          if (typeof result.id_connection.skip_sample_start != 'undefined') {
            newIdentityConnectionModel.fields.skip_sample_start = result.id_connection.skip_sample_start;
          }
          if (typeof result.id_connection.skip_sample_size != 'undefined') {
            newIdentityConnectionModel.fields.skip_sample_size = result.id_connection.skip_sample_size;
          }
          if (typeof result.id_connection.use_random_sampling != 'undefined') {
            newIdentityConnectionModel.fields.use_random_sampling = result.id_connection.use_random_sampling;
          }
          if (result.id_connection.scanConnectionTimeoutInSeconds) {
            newIdentityConnectionModel.fields.scanConnectionTimeoutInSeconds =
              result.id_connection.scanConnectionTimeoutInSeconds;
          }

          newIdentityConnectionModel.fields.projectId = result.id_connection.projectId;
          newIdentityConnectionModel.fields.containerName = result.id_connection.containerName;
          newIdentityConnectionModel.fields.subContainerName = result.id_connection.subContainerName;

          newIdentityConnectionModel.update(result.id_connection.type);
          newIdentityConnectionModel.fields.custom_url = result.id_connection.custom_url; // salesforce
          newIdentityConnectionModel.fields.db_host = result.id_connection.db_host;
          newIdentityConnectionModel.fields.db_name = result.id_connection.db_name;
          newIdentityConnectionModel.fields.db_table = result.id_connection.db_table;
          newIdentityConnectionModel.fields.db_user = result.id_connection.db_user;
          newIdentityConnectionModel.fields.dsConnection = result.id_connection.dsConnection;
          newIdentityConnectionModel.fields.custom_query_string = result.id_connection.custom_query_string
            ? result.id_connection.custom_query_string
            : 'SELECT * FROM ' + (result.id_connection.db_table ? result.id_connection.db_table : 'table');
          newIdentityConnectionModel.fields.unique_id_delimeter =
            result.id_connection.unique_id_delimeter || UNIQUE_ID_DEFAULT_DELIMETER;
          newIdentityConnectionModel.fields.unique_id = result.id_connection.unique_id || '';
          newIdentityConnectionModel.fields.unique_id_composite = result.id_connection.unique_id_composite || [];
          newIdentityConnectionModel.isUniqueIdCompositeMode =
            newIdentityConnectionModel.fields.unique_id_composite.length > 0;
          newIdentityConnectionModel.fields.residency = result.id_connection.residency;
          newIdentityConnectionModel.fields.display_name = result.id_connection.display_name;
          newIdentityConnectionModel.fields.composite_attributes = result.id_connection.composite_attributes || [];
          newIdentityConnectionModel.compositeAttributes = newIdentityConnectionModel.fields.composite_attributes.map(
            attrs => ({ attributes: attrs.map(attr => ({ label: attr, value: attr })) }),
          );

          if (result.id_connection.connectionStatusScan) {
            newIdentityConnectionModel.fields.scan_is_success = result.id_connection.connectionStatusScan.is_success;
            newIdentityConnectionModel.fields.scan_timestamp =
              result.id_connection.connectionStatusScan.last_connection;
            newIdentityConnectionModel.fields.scan_num_of_objects =
              result.id_connection.connectionStatusScan.num_of_object;
          }

          if (result.id_connection.connectionStatusTest) {
            newIdentityConnectionModel.fields.test_is_success = result.id_connection.connectionStatusTest.is_success;
            newIdentityConnectionModel.fields.test_timestamp =
              result.id_connection.connectionStatusTest.last_connection;
          }

          // MSSQL
          newIdentityConnectionModel.fields.isIntegratedAuthentication =
            result.id_connection.isIntegratedAuthentication;
          newIdentityConnectionModel.fields.mssqlServicePrincipalName = result.id_connection.mssqlServicePrincipalName;

          if (newIdentityConnectionModel.fieldsDDL.selectedTypeOption.name == 'MS-SQL') {
            newIdentityConnectionModel.fields.fetchIdentitiesWithScanner =
              result.id_connection.fetchIdentitiesWithScanner;
          }

          newIdentityConnectionModel.fields.db_pw = '********';

          newIdentityConnectionModel.isCustomQueryMode = isSelectCustomQueryString(result.id_connection);

          newIdentityConnectionModel.newIdentityConnectionModelGrid.columnDefs[3]['visible'] =
            !newIdentityConnectionModel.isCustomQueryMode;

          if (result.id_connection.attributes) {
            newIdentityConnectionModel.newIdentityConnectionModelGrid.data = result.id_connection.attributes;
            newIdentityConnectionModel.isTestConnectionSucceeded = true;
            newIdentityConnectionModel.attributes = [
              {
                name: RESET_RESIDENCY,
                isSelected: true,
              },
            ];
            result.id_connection.attributes.forEach(({ columnName: name }) => {
              newIdentityConnectionModel.attributes.push({ name, isSelected: false });
            });

            newIdentityConnectionModel.compositeAttributesSource = result.id_connection.attributes.map(
              ({ columnName }) => ({
                label: columnName,
                value: columnName,
              }),
            );

            if (!newIdentityConnectionModel.isCustomQueryMode) {
              result.id_connection.attributes.forEach(column => {
                if (column.selection) {
                  newIdentityConnectionModel.customQuerySelection.push(column.columnName);
                }
              });
              setCustomQueryString();
            }
          }
        }
      });
    }
    //case of insert
    else {
      newIdentityConnectionModel.fields.db_pw = '';
      newIdentityConnectionModel.isDsConnection = true;
      newIdentityConnectionModel.fields.unique_id_delimeter = UNIQUE_ID_DEFAULT_DELIMETER;
    }

    $translate('ENTITY_SOURCE_CONNECTIONS').then(breadcrumb => {
      const pageTitle = $stateParams.id ?? 'New Correlation Set';

      $rootScope.$broadcast('changePage', pageTitle, false, true, null, [
        { label: breadcrumb, onClick: () => $state.go('entitySources') },
        { label: pageTitle },
      ]);
    });

    newIdentityConnectionModel.onCompositeUniqueIdSelected = () => {
      newIdentityConnectionModel.fields.unique_id = newIdentityConnectionModel.fields.unique_id_composite.join(
        newIdentityConnectionModel.fields.unique_id_delimeter,
      );
    };

    newIdentityConnectionModel.onUniqueIdModeChange = () => {
      newIdentityConnectionModel.fields.unique_id_composite = newIdentityConnectionModel.isUniqueIdCompositeMode
        ? new Array(COMPOSITE_KEY_MIN_COUNT).fill('')
        : [];
      newIdentityConnectionModel.fields.unique_id = '';
    };

    newIdentityConnectionModel.addUniqueIdComponent = () => {
      newIdentityConnectionModel.fields.unique_id_composite.length < COMPOSITE_KEY_MAX_COUNT &&
        newIdentityConnectionModel.fields.unique_id_composite.push('');
    };

    newIdentityConnectionModel.removeUniqueIdComponent = index => {
      if (newIdentityConnectionModel.fields.unique_id_composite.length <= COMPOSITE_KEY_MIN_COUNT) return;

      newIdentityConnectionModel.fields.unique_id_composite.splice(index, 1);
      newIdentityConnectionModel.fields.unique_id = newIdentityConnectionModel.fields.unique_id_composite.join(
        newIdentityConnectionModel.fields.unique_id_delimeter,
      );
    };

    newIdentityConnectionModel.onUniqueIdDelimeterChange = () => {
      newIdentityConnectionModel.fields.unique_id = newIdentityConnectionModel.fields.unique_id_composite.join(
        newIdentityConnectionModel.fields.unique_id_delimeter,
      );
    };

    newIdentityConnectionModel.type = {
      availableOptions: availableTypeOptions,
    };

    newIdentityConnectionModel.enabled = {
      availableOptions: availableEnableOptions,
    };

    newIdentityConnectionModel.fieldsDDL.selectedEnabledOption = {
      id: '1',
      name: 'Yes', //This sets the default value of the select in the ui
    };

    newIdentityConnectionModel.fieldsDDL.selectedTypeOption = {
      id: '1',
      name: 'MySQL', //This sets the default value of the select in the ui
    };

    async function initDataSourcesSelection(dsConnection) {
      try {
        const { selectedDataSource, availableDsConnections } = await getDataSourcesListWithSelection(dsConnection);

        $scope.$applyAsync(() => {
          if (selectedDataSource) {
            newIdentityConnectionModel.selectedDataSource = selectedDataSource;
            newIdentityConnectionModel.dsType = selectedDataSource.type;
          }
          newIdentityConnectionModel.availableDsConnections = availableDsConnections;
        });
      } catch (e) {
        notificationService.error('could not get list of data sources');
        console.error(e);
      }
    }

    const unsetModelTypes = () => {
      for (const i in availableTypeOptions) {
        newIdentityConnectionModel[availableTypeOptions[i]['modelProp']] = false;
      }
    };

    function isSelectCustomQueryString(idConnection) {
      if (idConnection.hasOwnProperty('isCustomQueryString')) {
        return idConnection.isCustomQueryString;
      } else {
        // backward compatible
        if (idConnection.custom_query_string) {
          return true;
        } else {
          return false;
        }
      }
    }

    const setModelType = prop => {
      unsetModelTypes();
      newIdentityConnectionModel[prop] = true;
    };

    newIdentityConnectionModel.update = type => {
      const accordance = {
        mysql: 'isMySql',
        postgres: 'isPostgres',
        'ms-sql': 'isMsSql',
        oracle: 'isOracle',
        salesforce: 'isSalesForce',
        'ds-connection': 'isDsConnection',
      };

      newIdentityConnectionModel.isFormSubmitted = false;

      setModelType(accordance[type.toLowerCase()]);
      const selectedTypeOption = newIdentityConnectionModel.type.availableOptions.find(
        availableOption => availableOption.name === type,
      );
      if (selectedTypeOption) {
        newIdentityConnectionModel.fieldsDDL.selectedTypeOption = selectedTypeOption;
      }

      if (type === 'DS-Connection') {
        initDataSourcesSelection();
      }
    };

    newIdentityConnectionModel.onCompositeAttributesListChange = attributes => {
      newIdentityConnectionModel.fields.composite_attributes = attributes.map(attribute =>
        attribute.map(({ value }) => value),
      );
    };

    newIdentityConnectionModel.onDsConnectionSelected = dsConnection => {
      newIdentityConnectionModel.selectedDataSource = dsConnection;
      newIdentityConnectionModel.dsType = dsConnection.type;
    };

    $scope.selectInputChange = async searchString => {
      try {
        if (
          !newIdentityConnectionModel.initialAvailableDSConnections &&
          newIdentityConnectionModel.availableDsConnections
        ) {
          newIdentityConnectionModel.initialAvailableDSConnections = newIdentityConnectionModel.availableDsConnections;
        }

        if (!searchString) {
          newIdentityConnectionModel.availableDsConnections = newIdentityConnectionModel.initialAvailableDSConnections;
        } else {
          const query = getDsConnectionsQuery({ maxDS: 50, searchString });
          const { data } = await dataSourceConnectionsService.getDSConnectionDataByQuery(query);

          $scope.$applyAsync(() => {
            newIdentityConnectionModel.availableDsConnections = data.ds_connections;
          });
        }
      } catch (e) {
        notificationService.error('failed to search data sources');
        console.error(e);
      }
    };

    newIdentityConnectionModel.isCustomQueryModeChange = value => {
      newIdentityConnectionModel.newIdentityConnectionModelGrid.columnDefs[3]['visible'] = !value;
      if (newIdentityConnectionModel.gridApi) {
        newIdentityConnectionModel.gridApi.core.notifyDataChange(uiGridConstants.dataChange.COLUMN);
      }
    };

    newIdentityConnectionModel.onSampleCheckboxChanged = isSampleDataEnabled => {
      if (!isSampleDataEnabled) {
        newIdentityConnectionModel.fields.identities_force_sample = true;
        showDisableSampleConfirmationModal();
      }
    };

    newIdentityConnectionModel.onSampleSizeInputChanged = (sampleSizeNewValue, sampleSizeOldValue) => {
      if (sampleSizeNewValue > SAMPLE_SIZE_SAFE_EDGE && !sampleSizeChangeIsConfirmed) {
        showSampleSizeChangeConfirmationModal(sampleSizeOldValue);
      }
    };

    const showSampleSizeChangeConfirmationModal = fallbackValue => {
      const modalOptions = {
        closeButtonText: 'Cancel',
        actionButtonText: 'Confirm',
        headerText: `Sample Size change`,
        bodyText:
          'Sampling more than 100k entities may cause performance and storage issues, please verify that your system has enough resources to support this operation',
      };

      DeleteConfirmation.showModal({}, modalOptions).then(
        () => {
          sampleSizeChangeIsConfirmed = true;
        },
        () => {
          newIdentityConnectionModel.fields.identities_sample_size = fallbackValue;
        },
      );
    };

    const showDisableSampleConfirmationModal = () => {
      const modalOptions = {
        closeButtonText: 'Cancel',
        actionButtonText: 'Confirm',
        headerText: `Unchecking Use Sample`,
        bodyText:
          'Note: Unchecking Use Sample instructs the scanner to run a full scan of this Correlation Set, which takes significantly longer than sampling. BigID’s advanced sampling is sufficient for discovering PI locations and correlations. Use full scans only for extracting all PI data.',
      };

      DeleteConfirmation.showModal({}, modalOptions).then(() => {
        newIdentityConnectionModel.fields.identities_force_sample = false;
      });
    };

    newIdentityConnectionModel.submit = function (isValid) {
      newIdentityConnectionModel.isSave = true;
      if (
        isValid &&
        newIdentityConnectionModel.fields.unique_id &&
        newIdentityConnectionModel.isTestConnectionSucceeded
      ) {
        newIdentityConnectionModel.clickedToAction = true;
        set_id_connections();

        //case of update
        if ($stateParams.id) {
          $http
            .put(
              appSettings.serverPath + '/' + appSettings.version + '/id_connections/' + $stateParams.id,
              $scope.id_connection,
            )
            .then(
              function (response) {
                newIdentityConnectionModel.clickedToAction = false;
                notificationService.success('Updated ' + $stateParams.id + ' Successfully! ');
                $state.go('entitySources');
              },
              function (response) {
                newIdentityConnectionModel.clickedToAction = false;
                newIdentityConnectionModel.fields.db_pw = '********';
                if (response.data) {
                  notificationService.error(response.data, 'Update Id Connection Failed!');
                }
              },
            );
        }
        //case of insert
        else {
          $http.post(appSettings.serverPath + '/' + appSettings.version + '/id_connections', $scope.id_connection).then(
            function (response) {
              newIdentityConnectionModel.clickedToAction = false;
              notificationService.success('Saved Successfully! ');
              $state.go('entitySources');
            },
            function (response) {
              newIdentityConnectionModel.clickedToAction = false;
              newIdentityConnectionModel.fields.db_pw = '********';
              if (response.data) {
                notificationService.error(response.data, 'Saved Id Connection Failed!');
              }
            },
          );
        }
      } else {
        newIdentityConnectionModel.inputNotValid = true;
      }
    };

    function set_id_connections(isTest) {
      if (newIdentityConnectionModel.dsType === 'workday') {
        newIdentityConnectionModel.fields.db_table = 'Get_Workers';
      }

      $scope.id_connection = {};

      if (!angular.isDefined(isTest)) {
        if (newIdentityConnectionModel.fields.db_pw === '********') {
          newIdentityConnectionModel.fields.db_pw = null;
        } else if (newIdentityConnectionModel.fields.db_pw === '') {
          delete newIdentityConnectionModel.fields.db_pw;
        }
      }

      if (newIdentityConnectionModel.newIdentityConnectionModelGrid.data) {
        newIdentityConnectionModel.fields.attributes = [];

        for (let i = 0; i < newIdentityConnectionModel.newIdentityConnectionModelGrid.data.length; i++) {
          newIdentityConnectionModel.fields.attributes.row = {};
          newIdentityConnectionModel.fields.attributes.row.columnName =
            newIdentityConnectionModel.newIdentityConnectionModelGrid.data[i].columnName;
          newIdentityConnectionModel.fields.attributes.row.overrideSureMatch =
            newIdentityConnectionModel.newIdentityConnectionModelGrid.data[i].overrideSureMatch;
          newIdentityConnectionModel.fields.attributes.row.partialCorrelationSet =
            newIdentityConnectionModel.newIdentityConnectionModelGrid.data[i].partialCorrelationSet;
          newIdentityConnectionModel.fields.attributes.row.columnIdentifiability =
            newIdentityConnectionModel.newIdentityConnectionModelGrid.data[i].columnIdentifiability;
          newIdentityConnectionModel.fields.attributes.push(newIdentityConnectionModel.fields.attributes.row);

          if (!newIdentityConnectionModel.isCustomQueryMode) {
            newIdentityConnectionModel.fields.attributes.row.selection =
              newIdentityConnectionModel.newIdentityConnectionModelGrid.data[i].selection;
          }
        }
      }

      $scope.id_connection.id_connection = angular.copy(newIdentityConnectionModel.fields);

      $scope.id_connection.id_connection.type =
        newIdentityConnectionModel.fieldsDDL.selectedTypeOption.name.toLowerCase();
      if ($scope.id_connection.id_connection.type == 'salesforce') {
        newIdentityConnectionModel.isCustomQueryMode = true;
      }
      if ($scope.id_connection.id_connection.type != 'ds-connection') {
        delete newIdentityConnectionModel.fields.dsConnection;
      }
      if ($scope.id_connection.id_connection.residency === RESET_RESIDENCY) {
        $scope.id_connection.id_connection.residency = '';
      }

      if (!$scope.id_connection.id_connection.display_name) {
        $scope.id_connection.id_connection.display_name = $scope.id_connection.id_connection.unique_id;
      }

      if (!newIdentityConnectionModel.isCustomQueryMode) {
        $scope.id_connection.id_connection.custom_query_string = '';
      }

      if (angular.isDefined(isTest)) {
        $scope.id_connection.isNewPassword = newIdentityConnectionModel.isNewPassword;
        if (selectedType) {
          $scope.id_connection.id_connection.db_pw = !newIdentityConnectionModel.isNewPassword
            ? newIdentityConnectionModel.realPass
            : newIdentityConnectionModel.fields.db_pw;
        } else {
          $scope.id_connection.id_connection.db_pw = newIdentityConnectionModel.fields.db_pw;
        }
      }

      $scope.id_connection.id_connection.enabled =
        newIdentityConnectionModel.fieldsDDL.selectedEnabledOption.name.toLowerCase();

      if (newIdentityConnectionModel.dsType === 'cims') {
        $scope.id_connection.id_connection.db_server_id = newIdentityConnectionModel.selectedDataSource.rdb_server_id;
        $scope.id_connection.id_connection.isCustomQueryString = newIdentityConnectionModel.isCustomQueryMode;
      } else {
        $scope.id_connection.id_connection.isCustomQueryString = newIdentityConnectionModel.isCustomQueryMode;
      }

      if (newIdentityConnectionModel.columnsTypes) {
        $scope.id_connection.id_connection.columnsTypes = newIdentityConnectionModel.columnsTypes;
      }

      if (newIdentityConnectionModel.dsType === 'gcp-big-query') {
        $scope.id_connection.id_connection.projectId = newIdentityConnectionModel.fields.projectId;
      }
      if (newIdentityConnectionModel.isNonLegacyConnector()) {
        $scope.id_connection.id_connection.containerName = newIdentityConnectionModel.fields.containerName;
      }
      if (newIdentityConnectionModel.isSubFlatConnector()) {
        $scope.id_connection.id_connection.subContainerName = newIdentityConnectionModel.fields.subContainerName;
      }
    }

    function getSelectedObject(typeName, availableTypeOptions) {
      let id;
      let name;
      for (let i = 0; i < availableTypeOptions.length; i++) {
        if (availableTypeOptions[i].name.toLowerCase() === typeName.toLowerCase()) {
          id = availableTypeOptions[i].id;
          name = availableTypeOptions[i].name;
          break;
        }
      }

      return { id: id, name: name };
    }

    function testConnection() {
      newIdentityConnectionModel.piiGridLoading = true;
      return $http
        .post(appSettings.serverPath + '/' + appSettings.version + '/id_connection_test/', $scope.id_connection)
        .then(function (response) {
          $scope.updateTestTimeStamp();
          return response;
        });
    }

    $scope.updateTestTimeStamp = () => {
      if (selectedType) {
        identityConnectionsService.getIdentityConnectionsDataByID(selectedType).then(function (result) {
          if (result && result.id_connection) {
            if (result.id_connection.connectionStatusTest) {
              newIdentityConnectionModel.fields.test_is_success = result.id_connection.connectionStatusTest.is_success;
              newIdentityConnectionModel.fields.test_timestamp =
                result.id_connection.connectionStatusTest.last_connection;
            }
          }
        });
      }
    };

    newIdentityConnectionModel.deleteEntities = () => {
      const modalOptions = {
        closeButtonText: 'Cancel',
        actionButtonText: 'Delete',
        headerText: 'Delete Correlation Learning Set',
        bodyText: 'Are you sure you want to delete the correlation set?',
      };

      DeleteConfirmation.showModal({}, modalOptions).then(() => {
        identityConnectionsService
          .deleteScannedIdentitiesBySourceName(newIdentityConnectionModel.fields.name)
          .then(() => notificationService.success('Cached entities have been deleted successfully'))
          .catch(err => notificationService.error(`Failed to delete cached entities. ${err.message}`));
      });
    };

    newIdentityConnectionModel.newIdentityConnectionModelGrid = {
      enableSorting: true,
      enableFiltering: true,
      enableRowSelection: true,
      multiSelect: false,
      enableRowHeaderSelection: false,
      selectionRowHeaderWidth: 35,
      rowEditWaitInterval: -1,
      columnDefs: [
        {
          name: 'Column Name',
          field: 'columnName',
          width: '20%',
          enableCellEdit: false,
          cellTemplate:
            '<div style="margin-left:4px"> {{row.entity.columnName}}<span style="font-size:13px !important;"></span></div>',
        },
        {
          name: 'Data Preview',
          enableFiltering: false,
          enableCellEdit: false,
          field: 'columnData',
          cellTemplate:
            '<div style="margin-left:4px"> {{row.entity.columnData}}<span style="font-size:13px !important;"></span></div>',
        },
        {
          name: 'Identifiability ',
          enableFiltering: false,
          enableCellEdit: false,
          width: '13%',
          field: 'columnIdentifiability ',
          cellTemplate:
            '<div style="margin-left:4px"> {{row.entity.columnIdentifiability}}<span ng-if="row.entity.columnIdentifiability">%</span><span style="font-size:13px !important;"></span></div>',
        },
        {
          cellClass: 'grid-align',
          name: 'Select',
          field: 'selection',
          enableFiltering: false,
          enableCellEdit: true,
          width: '8%',
          visible: false,
          cellTooltip: row => 'Select columns',
          cellTemplate:
            '<input style="z-index: 99999;" title="Select columns" type="checkbox" ng-change="grid.appScope.selectionChanged(row.entity)" ng-model="row.entity.selection">',
        },
        {
          cellClass: 'grid-align',
          name: 'Override Sure Match',
          field: 'overrideSureMatch',
          enableFiltering: false,
          enableCellEdit: true,
          width: '10%',
          cellTooltip: row =>
            'Selected attributes will not be used to correlate a record to an identity unless found in the proximity of another highly identifiable attribute',
          cellTemplate:
            '<input style="z-index: 99999;" title="Selected attributes will not be used to correlate a record to an identity unless found in the proximity of another highly identifiable attribute" type="checkbox" ng-model="row.entity.overrideSureMatch">',
        },
      ],
    };
    const isPartialCorrelationSetFlag = getApplicationPreference('PARTIAL_CORRELATION_SET');
    if (isPartialCorrelationSetFlag === true) {
      newIdentityConnectionModel.newIdentityConnectionModelGrid.columnDefs.push({
        cellClass: 'grid-align',
        name: 'Scan Filter',
        field: 'partialCorrelationSet',
        enableFiltering: false,
        enableCellEdit: true,
        width: '10%',
        cellTooltip: row => 'Do not send to scanner',
        cellTemplate:
          '<input style="z-index: 99999;" title="Do not send to scanner" type="checkbox" ng-model="row.entity.partialCorrelationSet">',
      });
    }

    newIdentityConnectionModel.newIdentityConnectionModelGrid.onRegisterApi = gridApi => {
      newIdentityConnectionModel.gridApi = gridApi;
    };

    const setCustomQueryString = () => {
      let customQueryString = 'SELECT ';
      if (newIdentityConnectionModel.customQuerySelection.length > 0) {
        customQueryString += newIdentityConnectionModel.customQuerySelection.join(', ');
      } else {
        customQueryString += '*';
      }
      customQueryString += ' FROM ';
      customQueryString += getFullTableName(newIdentityConnectionModel.fields);
      newIdentityConnectionModel.fields.custom_query_string = customQueryString;
    };

    function getFullTableName(idConnection) {
      if (!idConnection.db_table) return 'table';
      let fullTableName = idConnection.db_table;
      if (
        newIdentityConnectionModel.fieldsDDL.selectedTypeOption.name.toLowerCase() == 'oracle' &&
        idConnection.db_name != null &&
        idConnection.db_name.trim().length > 0
      ) {
        fullTableName = idConnection.db_name + '.' + fullTableName;
      }
      return fullTableName;
    }

    $scope.selectionChanged = entity => {
      const index = newIdentityConnectionModel.customQuerySelection.indexOf(entity.columnName);
      if (index > -1) {
        newIdentityConnectionModel.customQuerySelection.splice(index, 1);
      } else {
        newIdentityConnectionModel.customQuerySelection.push(entity.columnName);
      }

      setCustomQueryString();
    };

    newIdentityConnectionModel.tableNameChanged = () => {
      setCustomQueryString();
    };

    const testESConnectionSync = () =>
      testConnection().then(
        function (response) {
          newIdentityConnectionModel.clickedToAction = false;
          if (response.data.isSuccessful) {
            if (response.data.columns) {
              newIdentityConnectionModel.piiGridLoading = false;
              newIdentityConnectionModel.attributes = [
                {
                  name: RESET_RESIDENCY,
                  isSelected: true,
                },
              ];
              response.data.columns.forEach(({ columnName: name }) => {
                newIdentityConnectionModel.attributes.push({ name, isSelected: false });
              });

              newIdentityConnectionModel.compositeAttributesSource = response.data.columns.map(({ columnName }) => ({
                label: columnName,
                value: columnName,
              }));

              newIdentityConnectionModel.newIdentityConnectionModelGrid.data = response.data.columns;

              if (response.data.columnsTypes) {
                newIdentityConnectionModel.columnsTypes = response.data.columnsTypes;
              }

              notificationService.success('Test Connection Success!');

              newIdentityConnectionModel.testConnectionSucceeded = true;
              newIdentityConnectionModel.inputNotValid = true;
              newIdentityConnectionModel.isTestConnectionSucceeded = true;
              newIdentityConnectionModel.newIdentityConnectionModelGrid.columnDefs[3]['visible'] =
                !newIdentityConnectionModel.isCustomQueryMode;
              $rootScope.$emit('$scrollTop');
            }
          } else {
            handleTestConnectionError(response);
          }
        },
        function (response) {
          newIdentityConnectionModel.clickedToAction = false;
          handleTestConnectionError(response);
        },
      );

    const handleTestConnectionError = ({ data: { err } } = { data: { err: {} } }) => {
      failedTestConnection();
      let errorMsg = 'Test Connection Failed!';
      if (err?.code) {
        errorMsg = err.code;
      } else if (err?.message) {
        errorMsg = err.message;
      } else if (typeof err === 'string') {
        errorMsg = err;
      }
      notificationService.error(errorMsg);
    };

    const testESConnectionSSE = esConnection =>
      identityConnectionsService
        .testEsConnection(esConnection, (results, errors) => {
          $scope.updateTestTimeStamp();
          newIdentityConnectionModel.clickedToAction = false;

          if (errors.length > 0 || results.length === 0) {
            failedTestConnection();
            $rootScope.$applyAsync();
            return;
          }

          const testConnectionResponse = results[0];
          if (!testConnectionResponse.isSuccessful) {
            failedTestConnection();
            $rootScope.$applyAsync();
            return;
          }

          if (testConnectionResponse.columns) {
            newIdentityConnectionModel.piiGridLoading = false;
            newIdentityConnectionModel.attributes = [
              {
                name: RESET_RESIDENCY,
                isSelected: true,
              },
            ];
            testConnectionResponse.columns.forEach(({ columnName: name }) => {
              newIdentityConnectionModel.attributes.push({ name, isSelected: false });
            });

            newIdentityConnectionModel.compositeAttributesSource = testConnectionResponse.columns.map(
              ({ columnName }) => ({
                label: columnName,
                value: columnName,
              }),
            );

            newIdentityConnectionModel.newIdentityConnectionModelGrid.data = testConnectionResponse.columns;

            if (testConnectionResponse.columnsTypes) {
              newIdentityConnectionModel.columnsTypes = testConnectionResponse.columnsTypes;
            }

            newIdentityConnectionModel.testConnectionSucceeded = true;
            newIdentityConnectionModel.inputNotValid = true;
            newIdentityConnectionModel.isTestConnectionSucceeded = true;
            newIdentityConnectionModel.newIdentityConnectionModelGrid.columnDefs[3]['visible'] =
              !newIdentityConnectionModel.isCustomQueryMode;
            $rootScope.$emit('$scrollTop');
          }
        })
        .then(removeListener => (newIdentityConnectionModel.removeTestConnectionListener = removeListener));

    newIdentityConnectionModel.testConnection = function (isValid) {
      newIdentityConnectionModel.isSave = false;

      if (isValid) {
        newIdentityConnectionModel.clickedToAction = true;
        newIdentityConnectionModel.piiGridLoading = true;
        set_id_connections(true);
        //set tables name in DDL
        const esConnectionName = $scope.id_connection.id_connection.name;
        const esConnection = {
          ...$scope.id_connection,
          broadcastEvent: `${esConnectionName}-test-connection-complete-${uuid()}`,
        };

        if (getApplicationPreference('SSE_TEST_CONNECTION')) {
          testESConnectionSSE(esConnection);
        } else {
          testESConnectionSync();
        }
      } else {
        newIdentityConnectionModel.inputNotValid = true;
      }
    };

    function failedTestConnection() {
      newIdentityConnectionModel.testConnectionSucceeded = false;
      newIdentityConnectionModel.isTestConnectionSucceeded = false;
      newIdentityConnectionModel.piiGridLoading = false;
    }

    newIdentityConnectionModel.getAttributes = (search, shouldRemoveNone) => {
      const attributes = newIdentityConnectionModel.attributes.slice();
      if (search && attributes.indexOf(search) === -1) {
        attributes.unshift(search);
      }
      return shouldRemoveNone && attributes.length > 0 ? attributes.slice(1, attributes.length) : attributes;
    };

    newIdentityConnectionModel.isDsSupportedCompUniqueId = () =>
      DS_SUPPORTED_COMPOSITE_UNIQUE_ID.includes(newIdentityConnectionModel.dsType);

    newIdentityConnectionModel.isHybridConnector = () => HYBRID_CONNECTORS.includes(newIdentityConnectionModel.dsType);

    newIdentityConnectionModel.isNonLegacyConnector = () =>
      NON_LEGACY_CONNECTORS.includes(newIdentityConnectionModel.dsType);

    newIdentityConnectionModel.isSubFlatConnector = () =>
      SUB_FLAT_CONNECTORS.includes(newIdentityConnectionModel.dsType);
  },
});
