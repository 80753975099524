import { httpService } from '../../../services/httpService';
import {
  DataElement,
  MainWidgets,
  MainWidgetsResponse,
  TopDataSources,
  UserWidgets,
  UserWidgetsResponse,
} from './ACIDashboardWrapper';
import { notificationService } from '../../../services/notificationService';
import { Policies } from '../../DataCatalog/DataCatalogService';

export type FileTypeCount = {
  [key: string]: FileTypeCountElement[];
};

export interface FileTypeCountElement {
  count: number;
  label: string;
}

export interface DashboardDsSummaryDto {
  createdAt: Date;
  modifiedAt: Date;
  readonly source: string;
  readonly status: 'DONE' | 'IN_PROGRESS' | 'DELETED' | 'ERROR';
  accessTypeCount: {
    labelName: string;
    objectsCount: number;
  }[];
  fileTypeCount: FileTypeCount;
  userAccess: {
    name: string;
    fileCount: number;
  }[];
  actionableInsights: {
    caseLabel: string;
    policyLastTriggered: string;
    caseType: string;
    dataSourceName: string;
    dataSourceType: string;
    dataSourceOwner: string;
    assignee: string;
    status: string;
    policyName: string;
    severity: string;
    policyOwner: string;
    policyType: string;
    compliance: string | null;
    numberOfAffectedObjects: number;
    investigateQuery: string;
    isIgnored: boolean;
    created_at: string;
    updated_at: string;
    id: string;
  }[];
  policiesCount: {
    objectsCount: number;
  };
}

export const getLatestDsSummaries = async (): Promise<Partial<DashboardDsSummaryDto>[]> => {
  try {
    const { data } = await httpService.fetch('aci/dashboard/latest-ds-summaries', {
      projection: JSON.stringify({
        source: 1,
      }),
    });
    return data?.data;
  } catch (e) {
    console.error(e);
    notificationService.error('Error fetching latest data sources');
    return [];
  }
};

export const areThereAnyDatasourcesInProgress = async (): Promise<boolean> => {
  try {
    const { data } = await httpService.fetch('aci/dashboard/any-ds-in-progress');
    return data?.data.length ? data?.data[0] : false;
  } catch (e) {
    console.error(e);
    notificationService.error('Error checking if dashboard generation in progress');
    return false;
  }
};

const DASHBOARD_EMPTY_STATE: MainWidgetsResponse = {
  policiesTriggered: {
    totalObjectsFoundByAccessPolicies: 0,
    triggeredAccessPolicies: [],
  },
  topAccessTypesByFileCount: [],
  topCasesByAccessType: [],
  topDataSources: {},
};

const USER_WIDGETS_EMPTY_STATE: UserWidgetsResponse = {
  topUsersAccessWithFileCount: [],
  topExternalDomains: [],
  topExternalUsers: [],
};

type AnyObject = { [key: string]: any };

const removeNullValues = <T,>(arr: (T | null)[]): T[] => {
  return arr.filter(item => item !== null) as T[];
};

function validateObjectFields<T>(obj: AnyObject, requiredType: T): void {
  const requiredKeys = Object.keys(requiredType) as (keyof T)[];
  const missingKeys = requiredKeys.filter(key => !obj.hasOwnProperty(key));

  if (missingKeys.length > 0) {
    throw new Error(`Missing fields: ${missingKeys.join(', ')}`);
  }
}

// export interface CatalogFileMapping {
//   value: string; // Spreadsheets
//   type: string; // xlsx
// }

export const getPolicies = async () => {
  try {
    const { data } = await httpService.fetch<Policies[]>(`compliance-rules`);
    return data;
  } catch (error) {
    console.error(error);
    return [];
  }
};

export const getDashboard = async (datasources: string[]): Promise<MainWidgets> => {
  try {
    const { data } = await httpService.fetch<{ data: MainWidgetsResponse }>('aci/dashboard', {
      data_sources: (datasources ?? []).join(','),
    });
    const res = data.data;
    validateObjectFields<MainWidgetsResponse>(res, {} as MainWidgetsResponse);

    return transformMainPart({ ...DASHBOARD_EMPTY_STATE, ...res });
  } catch (e) {
    console.error(e);
    notificationService.error('Incomplete response from the server');
    return transformMainPart(DASHBOARD_EMPTY_STATE);
  }
};

export const getUserWidgets = async (datasources: string[]): Promise<UserWidgets> => {
  try {
    const { data } = await httpService.fetch<{ data: UserWidgetsResponse }>('aci/dashboard/userWidgets', {
      data_sources: (datasources ?? []).join(','),
    });
    const res = data.data;

    validateObjectFields<UserWidgetsResponse>(res, {} as UserWidgetsResponse);
    return transformUserWidgets(res);
  } catch (e) {
    console.error(e);
    notificationService.error('Incomplete response from the server');

    return transformUserWidgets(USER_WIDGETS_EMPTY_STATE);
  }
};

export const transformMainPart = (res: MainWidgetsResponse): MainWidgets => {
  return {
    policiesTriggered: res.policiesTriggered,
    topAccessTypesByFileCount: removeNullValues(res.topAccessTypesByFileCount).map(element => ({
      label: element.labelName,
      count: element.objectsCount,
    })),
    totalPoliciesWithAccessType: 0,
    topCasesByAccessType: removeNullValues(res.topCasesByAccessType).map(element => ({
      label: element.name,
      count: element.count,
    })),
    topDataSources: Object.entries(res.topDataSources).reduce((acc, [key, value]) => {
      acc[key] = value.map(val => ({ label: val.datasource, count: val.count }));
      return acc;
    }, {} as TopDataSources),
  };
};

export const transformUserWidgets = (res: UserWidgetsResponse): UserWidgets => {
  const transformData = <T extends { [key: string]: any }>(
    value: T[],
    labelKey: keyof T,
    countKey: keyof T,
  ): DataElement[] =>
    Array.isArray(value)
      ? removeNullValues(value).map(element => ({
          label: element[labelKey] as string, // TypeScript needs assurance that labelKey will be a string
          count: element[countKey],
        }))
      : value;

  return {
    topUsersAccessWithFileCount: transformData(res.topUsersAccessWithFileCount, 'name', 'fileCount'),
    topExternalDomains: transformData(res.topExternalDomains, 'domain', 'sharedObjectsCount'),
    topExternalUsers: transformData(res.topExternalUsers, 'name', 'sharedObjectsCount'),
  };
};
