import React, { FC, useState, useEffect, useMemo, useCallback, Fragment } from 'react';
import { BigidPaper, BigidFilter, BigidLoader, BigidCaption } from '@bigid-ui/components';
import { BigidGridWithToolbar, BigidGridWithToolbarProps, BigidGridColumnTypes, BigidGridColumn } from '@bigid-ui/grid';
import {
  DsObjectPreviewTableProps,
  FetchStatus,
  getPreviewTableData,
  getPreviewTableDataCached,
  DataCatalogPreviewTableField,
  DataCatalogPreviewTableRecord,
} from '../hook/DsObjectFilePreviewService';
import styled from '@emotion/styled';
import { notificationService } from '../../../../../../services/notificationService';
import { AxiosError } from 'axios';

const RowWrapper = styled.div`
  background-color: ${({ theme }) => theme.vars.palette.bigid.blue150};
  border-radius: 4px;
  margin: 1px;
`;

const Main = styled.div`
  display: flex;
  flex-column: column nowrap;
  position: relative;
  width: 100%;
  height: 100%;
  padding: '1px';
`;

const GridWrapper = styled.div`
  display: flex;
  height: 100%;
`;

const GridContainer = styled.div`
  width: 100%;
  height: 90%;
`;

const TextCaption = styled.div`
  width: 100%;
  height: 10%;
  display: flex;
  align-items: center;
  padding-left: 24px;
`;

const COLUMNS_AMOUNT_LIMIT = 50;

export const DsObjectPreviewTable = ({
  source,
  fullyQualifiedName,
  type,
  filteredColumn,
  handleScanStatus,
  handleVisibilityCacheButton,
}: DsObjectPreviewTableProps) => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [fetchStatus, setFetchStatus] = useState<FetchStatus | null>(FetchStatus.FETCHED_FROM_CACHE);
  const [externalFilter, setExternalFilter] = useState<BigidFilter>([]);
  const [rows, setRows] = useState<DataCatalogPreviewTableRecord[]>([]);
  const [columns, setColumns] = useState<BigidGridColumn<DataCatalogPreviewTableRecord>[]>([]);
  const [wasInitialFetchAttempt, setWasInitialFetchAttempt] = useState<boolean>(true);
  const [configForGrid, setConfigForGrid] = useState<any>();

  const getGridRows = (tableRecords: DataCatalogPreviewTableField[][]): DataCatalogPreviewTableRecord[] => {
    return tableRecords.reduce((rows, tableRecord, index) => {
      return [
        ...rows,
        {
          ...tableRecord.reduce(
            (row, { fieldName, fieldValue }) => {
              if (fieldName === 'id') {
                return row;
              } else {
                return { ...row, [fieldName]: fieldValue };
              }
            },
            { id: index.toString() },
          ),
        },
      ];
    }, [] as DataCatalogPreviewTableRecord[]);
  };

  const getGridColumns = (rows: DataCatalogPreviewTableRecord[]): BigidGridColumn<DataCatalogPreviewTableRecord>[] => {
    const [row] = rows;
    return Object.keys(row).reduce((columns, columnName) => {
      if (columnName === 'id') return columns;

      return [
        ...columns,
        {
          name: columnName,
          title: columnName,
          getCellValue: (row: DataCatalogPreviewTableRecord) =>
            filteredColumn.includes(columnName) ? <RowWrapper>{row[columnName]}</RowWrapper> : row[columnName],
          getCustomCellTooltipValue: (row: DataCatalogPreviewTableRecord) =>
            filteredColumn.includes(columnName) ? filteredColumn : row[columnName],
          type: BigidGridColumnTypes.TEXT,
        },
      ];
    }, []);
  };

  const resetGrid = (): void => {
    setRows([]);
    setColumns([]);
    setFetchStatus(FetchStatus.FETCHED_FAILURE);
  };

  const gridConfig = useMemo<BigidGridWithToolbarProps<DataCatalogPreviewTableRecord>>(
    () => ({
      externalFilter,
      showSortingControls: false,
      hideColumnChooser: true,
      fetchData: async () => {
        return {
          totalCount: rows.length,
          data: rows,
        };
      },
      columns,
    }),
    [columns, rows, externalFilter, fullyQualifiedName],
  );

  const handleFetchError = ({ message, response }: AxiosError<{ message: string }>): void => {
    if ((response.status === 501 || response.status === 503) && response.data) {
      const { data } = response;
      notificationService.error(data.message);
    } else {
      notificationService.error('An error has occurred during fetch structured data');
    }

    console.error(`An error has occurred: ${message}`);
  };

  const setGridConfig = (previewData: DataCatalogPreviewTableField[][]): void => {
    const rows = getGridRows(previewData);
    const columns = getGridColumns(rows);
    if (columns.length > COLUMNS_AMOUNT_LIMIT) {
      setColumns(columns.slice(0, COLUMNS_AMOUNT_LIMIT));
    } else {
      setColumns(columns);
    }
    setRows(rows);
    setFetchStatus(FetchStatus.FETCHED_SUCCESS);
  };

  const fetchPreviewCachedData = useCallback(
    async (fullyQualifiedName: string) => {
      try {
        setIsLoading(true);
        const {
          data: { results, amountOfFields },
        } = await getPreviewTableDataCached(fullyQualifiedName);
        if (amountOfFields > 0 && results.length > 0) {
          handleVisibilityCacheButton(true);
          setConfigForGrid(results);
          setFetchStatus(FetchStatus.FETCHED_FROM_CACHE);
        } else {
          setFetchStatus(null);
        }
      } catch (error) {
        handleFetchError(error);
        setFetchStatus(null);
        handleScanStatus(true);
      } finally {
        setIsLoading(false);
        setWasInitialFetchAttempt(true);
      }
    },
    [source],
  );

  const fetchPreviewData = useCallback(
    async (fullyQualifiedName: string, limit: number) => {
      try {
        setIsLoading(true);
        const {
          data: { results },
        } = await getPreviewTableData({ fullyQualifiedName, limit });
        if (results.length > 0) {
          setConfigForGrid(results);
          handleVisibilityCacheButton(true);
        }
      } catch (error) {
        handleFetchError(error);
        handleScanStatus(true);
      } finally {
        setIsLoading(false);
      }
    },
    [source],
  );

  useEffect(() => {
    setExternalFilter([]);
    resetGrid();
    fetchPreviewCachedData(fullyQualifiedName);
  }, [fetchPreviewCachedData, fullyQualifiedName]);

  useEffect(() => {
    fetchStatus === null && fetchPreviewData(fullyQualifiedName, 20);
  }, [fetchPreviewData, fetchStatus]);

  useEffect(() => {
    configForGrid && setGridConfig(configForGrid);
  }, [filteredColumn, configForGrid]);

  return (
    <Main>
      {!isLoading && wasInitialFetchAttempt ? (
        <BigidPaper>
          <Fragment>
            {fetchStatus === FetchStatus.FETCHED_SUCCESS && (
              <GridContainer>
                <GridWrapper>
                  <BigidGridWithToolbar {...gridConfig} />
                </GridWrapper>
              </GridContainer>
            )}
            <TextCaption>
              <BigidCaption>Number of columns in the preview is limited to {COLUMNS_AMOUNT_LIMIT}</BigidCaption>
            </TextCaption>
          </Fragment>
        </BigidPaper>
      ) : (
        <BigidLoader />
      )}
    </Main>
  );
};
