import React, { FC, PropsWithChildren, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { BigidEditIcon } from '@bigid-ui/icons';
import makeStyles from '@mui/styles/makeStyles';
import {
  BigidWizardLayout,
  BigidLoader,
  BigidForm,
  BigidFormRenderProps,
  PrimaryButton,
  BigidFormStateAndHandlers,
  BigidDialog,
  SecondaryButton,
  BigidPageLayout,
  BigidSplitButton,
  StyledButtonType,
} from '@bigid-ui/components';
import { DataSourceConfigurationSection } from './DataSourceConfigurationSection/DataSourceConfigurationSection';
import { DataSourceTestConnectionBox } from './DataSourceTestConnection/DataSourceTestConnectionBox';
import { DataSourceConfigurationContext } from './DataSourceConfigurationContext';
import {
  DataSourceConfigurationNoData,
  DEFAULT_NOT_FOUND_DS_TITLE,
  DEFAULT_NOT_FOUND_REASONS,
} from './DataSourceConfigurationNoData/DataSourceConfigurationNoData';
import {
  BigidSplitMenuButton,
  BigidSplitMenuButtonProps,
} from '../../../components/BigidSplitMenuButton/BigidSplitMenuButton';
import { DataSourceDetailsBox } from './DataSourceDetailsBox/DataSourceDetailsBox';
import { DataSourceScanBox } from './DataSourceScanBox/DataSourceScanBox';
import { ConnectionSectionAdvancedInfo } from './DataSourceConfigurationSection/SectionsInfo/ConnectionSection/ConnectionSectionAdvancedInfo';
import { ConnectionSectionTopInfo } from './DataSourceConfigurationSection/SectionsInfo/ConnectionSection/ConnectionSectionTopInfo';
import { DataSourceEnabledStatus } from './DataSourceEnabledStatus/DataSourceEnabledStatus';

import { useDataSourceConfigState } from './hooks/useDataSourceConfigState';
import { useChangesMonitoring } from './hooks/useChangesMonitoring';
import { useSubmitDataSource } from './hooks/useSubmitDataSource';
import { MapTypeValueNormalize } from './utils/prepareDataSourceFormValueDataForSend';
import { DataSourceTestConnectionStatusEnum, useTestConnection } from './hooks/useTestConnection';
import { useStepsWithData } from './hooks/useStepsWithData';
import { useAfterValuesChange } from './hooks/useAfterValuesChange';
import { useSetTemplateConfigWithStateOverrides } from './hooks/useSetTemplateConfigWithStateOverrides';
import { useUpdateDetailsFilled } from './hooks/useUpdateDetailsFilled';
import { useGetDataSourceByName } from './hooks/useGetDataSourceByName';
import { useUpdateEnabledStatus } from './hooks/useUpdateEnabledStatus';

import { pageHeaderService } from '../../../../common/services/pageHeaderService';
import { isPermitted } from '../../../services/userPermissionsService';
import {
  DsConnectionFieldSectionsEnum,
  DataSourceConnectionFormField,
  DataSourceTemplateCondition,
  DsTypesEnum,
  DataSourceTypes,
  DataSourceTabAction,
  BigidTabActionButtonType,
} from './types';
import { $state } from '../../../services/angularServices';

import { CONFIG } from '../../../../config/common';
import { DATA_SOURCES_PERMISSIONS } from '@bigid/permissions';
import { DATA_SOURCES_INIT_VIEWS } from '../DataSources';
import { DetailsSectionTopInfo } from './DataSourceConfigurationSection/SectionsInfo/DetailsSection/DetailsSectionTopInfo';
import { resetDsWizardUsersCache } from './systemUsersCacheForFields';
import { useCustomNotification } from './hooks/useCustomNotification';
import { QueryClientProvider } from 'react-query';
import { getQueryClient } from '../../../../react/config/query';
import {
  DataSourceConnectionModal,
  DataSourceConnectionModalAction,
} from '../DataSourceConnectionModal/DataSourceConnectionModal';
import { DataSource, getSupportedDataSources } from '../../../../react/utilities/dataSourcesUtils';
import { useLocalTranslation } from '../DataSourceConnectionModal/translations';
import { useKey } from './hooks/useKey';
import { DataSourceConnectionStatus } from './DataSourceConnectionStatus';
import { sessionStorageService } from '../../../../common/services/sessionStorageService';
import { isNewConnectivityExperienceEnabled } from './utils/isNewConnectivityExperienceEnabled';
import { MainStepConnectionTab } from './DataSourceNewConnectivityConfiguration/DataSourceConnectionTab/MainStepConnectionTab';
import { SideStepConnectionTab } from './DataSourceNewConnectivityConfiguration/DataSourceConnectionTab/SideStepConnectionTab';
import { useTestConnection as useTestConnectionV2 } from '../../DataSources/DataSourceConnectionModal/hooks/useTestConnection';
import { useDataSourceModalNotification } from '../DataSourceConnectionModal/hooks/useDataSourceModalNotification';
import { useModal } from '../DataSourceConnectionModal/hooks/useModal';
import { useGetScanTypes } from './hooks/useGetScanTypes';
import { useInstantScanData } from '../DataSourceConnections/hooks/useInstantScanData';
import { usePrevious } from '../DataSourceConnectionModal/hooks/usePrevious';
import { getApplicationPreference } from '../../../services/appPreferencesService';
import { sseEventEmitter } from '../../../services/sseService';
import { ScanAction, generateScanActions, generateScanProfileActions } from './config/wizard';
import { mapTestConnectionStatusToWizardActions, mapTestStatusToStatusBadge } from './mappers/wizard';
import { generateDataAid } from '@bigid-ui/utils';
import { useConnectionTypeChanged } from './hooks/useConnectionTypeChanged';
import { DataSourceConfigurationTabWizard } from './DataSourceConfigurationTabWizard';
import { openSelectDataSourceOnboarding } from '../SelectDataSourceOnboardingType/selectDataSourceOnboardingTypeService';

interface DataSourcesConfigUrlParams {
  selectedType?: string;
  id?: string;
  isAfterSave?: boolean;
  redirectToNewConnectivity?: string;
  shouldOpenNewModal?: string;
  wizardStep?: DsConnectionFieldSectionsEnum;
  dsLabel?: string;
}

interface DataSourceConfigurationProps {
  $stateParams: DataSourcesConfigUrlParams;
}

interface DataSourceConfigurationStyleProps {
  isFullWidth?: boolean;
  isNewPageHeader?: boolean;
}

const MAX_LAYOUT_WIDTH = '1360px';
const DEFAULT_TAB_SECTION = DsConnectionFieldSectionsEnum.connection;

const useStyles = makeStyles({
  dataSourceConfigurationWrapper: ({ isFullWidth, isNewPageHeader = false }: DataSourceConfigurationStyleProps) => ({
    display: 'flex',
    height: isNewPageHeader ? 'calc(100% - 128px)' : 'calc(100% - 4px)',
    maxWidth: isFullWidth ? 'unset' : MAX_LAYOUT_WIDTH,
    '& > form': {
      width: '100%',
    },
    '& .FormCertificateField > div': {
      width: '100%',
      '& label': {
        minWidth: '250px',
      },
    },
  }),
  actionWrapper: {
    position: 'absolute',
    display: 'inline-flex',
    justifyContent: 'flex-end',
    background: 'var(--bigid-tokens-bigid-pageBackground)',
    alignItems: 'center',
    gap: '0.5rem',
    top: '2px',
    right: '0',
    zIndex: '1',
    width: '400px',
  },
  tabActionWrapper: {
    position: 'absolute',
    display: 'inline-flex',
    justifyContent: 'flex-end',
    background: 'var(--bigid-tokens-bigid-pageBackground)',
    alignItems: 'center',
    gap: '0.5rem',
    top: '100px',
    right: '24px',
    zIndex: '1',
    width: '400px',
  },
  buttonWrapper: {
    position: 'relative',
  },
});

const queryClient = getQueryClient();

const getDataSourceByType = async (type: string): Promise<DataSource> => {
  const supportedDataSources = await getSupportedDataSources();
  const dsTypeRecord = supportedDataSources.filter(({ type: dsType }) => dsType === type);
  const [dataSourceInfo] = dsTypeRecord ?? [{} as DataSource];

  return dataSourceInfo;
};

export const DataSourceConfiguration: FC<DataSourceConfigurationProps> = ({ ...props }) => (
  <QueryClientProvider client={queryClient}>
    <DataSourceConfigurationBase {...props} />
  </QueryClientProvider>
);

const Loader = ({ children, isLoading }: PropsWithChildren & { isLoading: boolean }): JSX.Element => {
  const { buttonWrapper } = useStyles({});
  return (
    <div className={buttonWrapper}>
      {children}
      {isLoading && <BigidLoader size={20} />}
    </div>
  );
};

const BigidLoaderButton = ({
  isLoading = false,
  as,
  ...rest
}: StyledButtonType & { isLoading?: boolean; as?: string }) => {
  const isPrimary = as === 'primary';
  return (
    <Loader isLoading={isLoading}>{isPrimary ? <PrimaryButton {...rest} /> : <SecondaryButton {...rest} />}</Loader>
  );
};

const BigidSplitButtonBase = ({
  dataAid = 'BigidSplitButtonBase',
  onClick,
  actions,
  ...rest
}: BigidSplitMenuButtonProps) => {
  const [{ onClick: onClickIconButton }] = actions ?? [{}];

  return (
    <BigidSplitButton
      onClickButton={onClick}
      onClickIconButton={onClickIconButton}
      size="medium"
      endIcon={BigidEditIcon}
      dataAid={dataAid}
      {...rest}
    />
  );
};

export const DataSourceConfigurationBase: FC<DataSourceConfigurationProps> = ({ $stateParams }) => {
  const control = useRef<BigidFormStateAndHandlers>();
  const [showModal, setShowModal] = useState(false);
  const [formKey, resetForm] = useKey();
  const [editKey, resetEditForm] = useKey();
  const [showSaveBeforeEdit, setShowSaveBeforeEdit] = useState(false);
  const [documentationUrl, setDocumentationUrl] = useState<string>();
  const [dsDisplayName, setDsDisplayName] = useState<string>();
  const { updateState, generateInitValues, configDataSourceState } = useDataSourceConfigState();
  const { t } = useLocalTranslation();
  const [shouldAutoConnect, setShouldAutoConnect] = useState(
    $stateParams?.redirectToNewConnectivity === 'true' ?? false,
  );

  const {
    fields,
    initialValues,
    isLoading,
    isLoadingData,
    id,
    testStatus,
    selectedType,
    type,
    isSaveInProgress,
    isFormDirty,
    lastTestDate,
    testInProgress,
    isNewPassword,
    encryptFields,
    scanCompleteDate,
    saveError,
    isScanSuccess,
    wasScanExecuted,
    isScopeSelected,
    notFoundMessage,
    enabled,
  } = configDataSourceState;

  const sourceType = $stateParams.selectedType || type;
  const prevTestStatus = usePrevious(testStatus);

  const isPageLoading = !sourceType;
  const isNewExperienceEnabled = isNewConnectivityExperienceEnabled(sourceType);
  const isNewTabsExp = isNewExperienceEnabled && getApplicationPreference('NEW_CONNECTION_TAB_ENABLED');

  const getFieldPropsFunction = useRef<BigidFormRenderProps['getFieldProps']>();
  const resetValuesToInitFunction = useRef<BigidFormRenderProps['clear']>();
  const createSetValueFunction = useRef<BigidFormRenderProps['setValue']>();
  const { dataSourceConfigurationWrapper, actionWrapper } = useStyles({
    isFullWidth: !!notFoundMessage,
    isNewPageHeader: isNewTabsExp,
  });

  const isEditAvailable = $stateParams?.id
    ? isPermitted(DATA_SOURCES_PERMISSIONS.EDIT.name)
    : isPermitted(DATA_SOURCES_PERMISSIONS.CREATE.name);
  const isConfigLoading = isLoading || isLoadingData || isSaveInProgress || (!isNewExperienceEnabled && testInProgress);
  const isFormReady = !isLoading && initialValues && !notFoundMessage;

  const sourceId = id || $stateParams?.id;
  const shouldAutoOpenModalAndConnect = isNewExperienceEnabled && shouldAutoConnect;

  const isDraftConnectionSuccess =
    prevTestStatus === DataSourceTestConnectionStatusEnum.notStarted &&
    testStatus === DataSourceTestConnectionStatusEnum.success;

  const shouldShowDetachedSuccess = useMemo(
    () => isNewTabsExp && (testStatus === DataSourceTestConnectionStatusEnum.notStarted || isDraftConnectionSuccess),
    [isNewTabsExp, testStatus, prevTestStatus],
  );

  const {
    control: modal,
    trigger,
    execute,
    state,
  } = useModal({
    enabled: isNewTabsExp,
    onError: () => updateState({ testStatus: DataSourceTestConnectionStatusEnum.failed }),
    onSuccess: () => {
      shouldShowDetachedSuccess && execute(DataSourceConnectionModalAction.SHOW_SUCCESS_MESSAGE);
      updateState({ testStatus: DataSourceTestConnectionStatusEnum.success });
    },
  });

  const { data: scanTypes, isLoading: isLoadingScanTypes } = useGetScanTypes($stateParams.selectedType || type, {
    query: { enabled: !!sourceType && isNewTabsExp },
  });
  const { executeQuickScan, getSupportedTemplates, isScanTemplatesLoading, isScanTemplatesEnabled } =
    useInstantScanData({
      enabled: isNewTabsExp,
    });
  const {
    emitTestConnectionError,
    emitTestConnectionSucceeded,
    displayScanFailedNotification,
    displayScanStartedNotification,
  } = useDataSourceModalNotification({
    sourceId,
    source: $stateParams?.selectedType,
    control,
  });

  const { onTestHandler: onTestHandlerV2, addListenerToTestConnectionEvent } = useTestConnectionV2(
    { updateState, generateInitValues, configDataSourceState },
    control,
    {
      onSuccess() {
        emitTestConnectionSucceeded();
      },
      onError() {
        setShowModal(true);
        emitTestConnectionError();
      },
    },
  );

  const refreshData = useGetDataSourceByName({
    updateState,
    name: $stateParams?.id,
    fields,
  });

  const { activeStep, setActiveStep, goToNext, goToPrev, noPrev, noNext, steps } = useStepsWithData({
    lastTestDate,
    testStatus,
    scanCompleteDate,
    isScanSuccess,
    fields,
    initialStep:
      isNewExperienceEnabled && $stateParams?.wizardStep
        ? $stateParams?.wizardStep
        : DsConnectionFieldSectionsEnum.connection,
  });

  const { onSubmitHandler, validateAndSubmitRef } = useSubmitDataSource({
    fields,
    id: id || $stateParams?.id,
    selectedType: selectedType || $stateParams?.selectedType,
    updateState,
    getFieldPropsFunction,
    refreshData,
    initialValues,
    shouldTriggerNotification: !isNewExperienceEnabled,
  });

  const { isTouched, getValuesContainer } = useChangesMonitoring(
    initialValues,
    onSubmitHandler,
    getFieldPropsFunction,
    fields,
    saveError,
  );

  const { updateTestAvailable, onTestHandler, getHighlightedWordsForLog } = useTestConnection({
    fields,
    updateState,
    getValuesContainer,
    getFieldPropsFunction,
    isNewPassword,
    encryptFields,
    initialValues,
    getApplicationPreference,
    sseEventEmitter,
  });

  const updateDetailsFilled = useUpdateDetailsFilled({ fields, updateState, isScopeSelected });
  const updateEnabledStatus = useUpdateEnabledStatus(updateState);
  const handleConnectionTypeChanged = useConnectionTypeChanged(updateState);
  const { additionalNotification: notificationOcr } = useCustomNotification(fields, 'enabledOcr', 'ocrParameters');
  const { additionalNotification: extractArchives } = useCustomNotification(
    fields,
    'extractArchives',
    'typeRequestProperties',
  );
  const { additionalNotification: notificationMipDecryption } = useCustomNotification(fields, 'enableMipDecryption');

  const onFormValuesChange = useAfterValuesChange([
    notificationOcr,
    extractArchives,
    notificationMipDecryption,
    updateDetailsFilled,
    (values: Record<string, any>) => {
      !!getFieldPropsFunction.current && updateTestAvailable(values);
    },
    updateEnabledStatus,
    handleConnectionTypeChanged,
  ]);

  const pageTitle = `${$stateParams.id ? $stateParams.id : 'New'} ${
    dsDisplayName || $stateParams.selectedType || selectedType || ''
  } Connection`;

  const breadcrumbs = useMemo(
    () => [
      {
        label: $stateParams?.id ? 'Data Sources' : 'Select a Data Source',
        onClick: () =>
          $stateParams?.id ? $state.go(CONFIG.states.DATA_SOURCE_CONNECTIONS) : openSelectDataSourceOnboarding(),
      },
      {
        label: pageTitle,
      },
    ],
    [$stateParams.id, pageTitle],
  );

  const statusBadge = mapTestStatusToStatusBadge(testStatus, t ?? ((key: string) => key));

  const handleShowPageHeader = () => {
    pageHeaderService.setTitle({
      defaultFrom: { state: CONFIG.states.DATA_SOURCE_ROUTER, params: {} },
      showBackButton: true,
      breadcrumbs,
      titleHelperComponent: null,
      rightSideComponentsContainer: isNewExperienceEnabled ? (
        <DataSourceConnectionStatus status={testStatus} />
      ) : (
        <DataSourceEnabledStatus enabled={enabled} />
      ),
    });

    pageHeaderService.setIsHidden(false);
  };

  useEffect(() => {
    if (shouldAutoOpenModalAndConnect || $stateParams?.shouldOpenNewModal) {
      setShowModal(true);
    }
  }, [$stateParams, shouldAutoOpenModalAndConnect]);

  useEffect(() => {
    if (!isLoading && !isPageLoading) {
      isNewTabsExp ? pageHeaderService.setIsHidden(true) : handleShowPageHeader();
    } else {
      pageHeaderService.setIsHidden(true);
    }
  }, [
    $stateParams,
    isLoading,
    isPageLoading,
    selectedType,
    $stateParams.id,
    enabled,
    isNewExperienceEnabled,
    testStatus,
    dsDisplayName,
    isNewTabsExp,
  ]);

  // @info add field overrides for new connectivity  experience
  useSetTemplateConfigWithStateOverrides(
    {
      updateState,
      generateInitValues,
      selectedType: $stateParams.selectedType || type,
      id: id || $stateParams?.id,
    },
    isNewExperienceEnabled,
  );

  useEffect(() => {
    resetDsWizardUsersCache();
  }, []);

  useEffect(() => {
    (async () => {
      try {
        const { nameInDocs, displayName } = await getDataSourceByType($stateParams.selectedType || type);

        setDocumentationUrl(nameInDocs);
        setDsDisplayName(displayName);
      } catch {}
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [type]);

  // useTemplateStatusNotification hook has been temporary reverted BDT-65440

  useEffect(() => {
    return () => pageHeaderService.setIsHidden(false);
  }, []);

  const formRenderDepsRef = useRef<Record<string, any>>();
  // for prevent form re-render
  formRenderDepsRef.current = {
    activeStep,
    fields,
    getValuesContainer,
    goToNext,
    goToPrev,
    isTouched,
    noNext,
    noPrev,
    onSubmitHandler,
    onTestHandler,
    setActiveStep,
    steps,
    validateAndSubmitRef,
    getHighlightedWordsForLog,
    getFieldPropsFunction,
    isEditAvailable,
    saveError,
    wasScanExecuted,
    onTestHandlerV2,
  };

  const DataSourceConfigFormRender = useMemo(
    () =>
      ({
        setValue,
        renderField,
        getFieldProps,
        getValues,
        formTouched,
        validateAndSubmit,
        clear,
      }: BigidFormRenderProps) => {
        const {
          activeStep,
          fields,
          getValuesContainer,
          goToNext,
          goToPrev,
          isTouched,
          noNext,
          noPrev,
          onSubmitHandler,
          onTestHandler,
          setActiveStep,
          steps,
          validateAndSubmitRef,
          getHighlightedWordsForLog,
          isEditAvailable,
          saveError,
          wasScanExecuted,
          onTestHandlerV2,
        } = formRenderDepsRef.current;
        const renderSectionFields = (
          sectionName: DsConnectionFieldSectionsEnum,
          StartComponent?: FC<any>,
          expandsTitle?: string,
        ) => (
          <React.Fragment>
            <DataSourceConfigurationSection
              renderField={renderField}
              getFieldProps={getFieldProps}
              sectionName={sectionName}
              StartComponent={StartComponent}
              expandsTitle={expandsTitle}
              isEditAvailable={isEditAvailable}
              wasScanExecuted={wasScanExecuted}
            />
          </React.Fragment>
        );
        const isFieldsReadyToSave = (fields as DataSourceConnectionFormField[]).every(({ name, misc }) => {
          let isReady = true;
          if (!$stateParams?.id && misc?.mandatory) {
            const value = getFieldProps(name)?.value;
            isReady = value !== undefined && value !== '';
          }
          if (misc?.blockSaveIf) {
            const { blockSaveIf } = misc;
            isReady = !(blockSaveIf as DataSourceTemplateCondition[]).every(({ field, value }) => {
              const valueNormalized = MapTypeValueNormalize[getFieldProps(field)?.misc?.type as DsTypesEnum]?.(
                getFieldProps(field)?.value,
              );
              return valueNormalized === value;
            });
          }

          return isReady;
        });

        const isSaveAvailable = isEditAvailable && (formTouched || saveError) && isFieldsReadyToSave;
        isTouched.current = formTouched;
        getValuesContainer.current = getValues;
        validateAndSubmitRef.current = validateAndSubmit;
        getFieldPropsFunction.current = getFieldProps;
        resetValuesToInitFunction.current = clear;
        createSetValueFunction.current = setValue;

        const handleEditConnection = async () => {
          try {
            if (control.current.formTouched) {
              await control.current.validate();
              const isSuccess = await onSubmitHandler();

              if (isSuccess) {
                resetEditForm();
                setShowModal(true);
              }
              return;
            }

            resetEditForm();
            setShowModal(true);
          } catch {}
        };

        const connectionBoxRendered = isNewExperienceEnabled ? (
          <SideStepConnectionTab handleEditConnection={handleEditConnection} />
        ) : (
          <DataSourceTestConnectionBox
            onTest={() => {
              onTestHandler(getValues());
            }}
            getHighlightedWordsForLog={getHighlightedWordsForLog}
            isNotSaved={isSaveAvailable}
          />
        );

        const scanBoxRendered = (
          <DataSourceScanBox isNotSaved={isSaveAvailable} getHighlightedWordsForLog={getHighlightedWordsForLog} />
        );

        const mainContentSteps = {
          [DsConnectionFieldSectionsEnum.connection]: isNewExperienceEnabled ? (
            <QueryClientProvider client={queryClient}>
              <MainStepConnectionTab
                onTestAgain={() => {
                  onTestHandlerV2(getValues());
                }}
                handleEditConnection={handleEditConnection}
                addListenerToTestConnectionEvent={addListenerToTestConnectionEvent}
              />
            </QueryClientProvider>
          ) : (
            <React.Fragment>
              {renderSectionFields(DsConnectionFieldSectionsEnum.connection, ConnectionSectionTopInfo)}
              {renderSectionFields(
                DsConnectionFieldSectionsEnum.connectionAdvanced,
                ConnectionSectionAdvancedInfo,
                t('tabs.advanced'),
              )}
            </React.Fragment>
          ),
          [DsConnectionFieldSectionsEnum.details]: renderSectionFields(
            DsConnectionFieldSectionsEnum.details,
            DetailsSectionTopInfo,
          ),
          [DsConnectionFieldSectionsEnum.scanSettings]: renderSectionFields(DsConnectionFieldSectionsEnum.scanSettings),
        };

        const sideContentSteps = {
          [DsConnectionFieldSectionsEnum.connection]: connectionBoxRendered,
          [DsConnectionFieldSectionsEnum.details]: <DataSourceDetailsBox />,
          [DsConnectionFieldSectionsEnum.scanSettings]: scanBoxRendered,
        };

        return (
          <div style={{ position: 'relative' }}>
            {isNewExperienceEnabled && (
              <div className={actionWrapper}>
                <PrimaryButton
                  dataAid="DataSourceConfiguration-save"
                  disabled={!isSaveAvailable}
                  text={t('buttons.connectionSave')}
                  onClick={() => onSubmitHandler()}
                  size="large"
                />
              </div>
            )}
            <BigidWizardLayout
              stepsMainContent={mainContentSteps}
              stepsSideContent={sideContentSteps}
              steps={steps}
              onNext={goToNext}
              onPrev={goToPrev}
              hideNext={noNext}
              hidePrev={noPrev}
              onSelect={setActiveStep}
              activeId={activeStep}
              onClose={() => {
                $state.go(CONFIG.states.DATA_SOURCE_ROUTER);
              }}
              onSubmit={isSaveAvailable && onSubmitHandler}
            />
          </div>
        );
      },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [$stateParams?.id, isNewExperienceEnabled],
  );

  const handleUpdateEditPageLocation = async (sourceId: string) => {
    await $state.go(
      CONFIG.states.CONFIG_DATA_SOURCE,
      { id: sourceId, shouldOpenNewModal: false },
      {
        location: 'replace',
      },
    );

    const history: History[] = sessionStorageService.get('history') || [];
    sessionStorageService.set('history', history.slice(0, -1));
  };

  const handleCloseModal = async (
    shouldReload?: boolean,
    opts?: { wizardStep?: DsConnectionFieldSectionsEnum; sourceId: string },
  ) => {
    const { sourceId, wizardStep } = opts ?? {};
    setShowModal(false);
    wizardStep && setActiveStep(wizardStep);
    if (shouldReload) {
      handleUpdateEditPageLocation(sourceId);
    } else {
      await refreshData();
      resetForm();
    }
  };

  const handleSubmit = async () => {
    try {
      await control.current.validate();
      const isSuccess = await onSubmitHandler();

      if (isSuccess) {
        setShowModal(true);
      }
    } catch {}
  };

  const handleEditWithoutSaving = () => {
    control.current.setAllTouched(false);
    resetForm();
    setShowModal(true);
    setShowSaveBeforeEdit(false);
  };

  const handleTriggerStateChange = () => trigger();

  const formProps = useMemo(
    () => ({
      fields,
      initialValues,
      renderForm: DataSourceConfigFormRender,
      onChange: onFormValuesChange,
      stateAndHandlersRef: control,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [fields, initialValues, DataSourceConfigFormRender, onFormValuesChange],
  );

  const handleSaveBeforeExecuteScanAction = ({ execute: executeAction, ...rest }: ScanAction): ScanAction => ({
    execute: async () => {
      await execute(DataSourceConnectionModalAction.SUBMIT);
      executeAction();
    },
    ...rest,
  });

  const {
    isTestAvailable: isTestAvailableFromModal,
    testInProgress: testInProgressFromModal,
    isSaveInProgress: isSaveInProgressFromModal,
  } = state;

  const isLoadingScanActions = isLoadingScanTypes || isScanTemplatesLoading;

  const scanProfileActions: ScanAction[] = generateScanProfileActions(
    id || $stateParams?.id,
    $stateParams.selectedType || type,
    {
      onSuccess: () => displayScanStartedNotification(),
      onError: () => displayScanFailedNotification(),
    },
  )?.map(handleSaveBeforeExecuteScanAction);

  const scanActions: ScanAction[] = (
    !isLoadingScanActions
      ? generateScanActions(id || $stateParams?.id, getSupportedTemplates(scanTypes), executeQuickScan, scanTypes)
      : []
  )?.map(handleSaveBeforeExecuteScanAction);

  const [mainScanAction] = scanActions ?? [];
  const [defaultScanProfileAction] = scanProfileActions ?? [];

  const tabActionList: BigidTabActionButtonType[] = [
    {
      id: DataSourceTabAction.TEST_CONNECTION,
      component: [DataSourceTestConnectionStatusEnum.notStarted].includes(testStatus) ? PrimaryButton : SecondaryButton,
      dataAid: 'DataSourceConfiguration-testConnection',
      onClick: () => execute(DataSourceConnectionModalAction.TEST),
      disabled: !isTestAvailableFromModal || testInProgressFromModal,
      text:
        testStatus === DataSourceTestConnectionStatusEnum.notStarted ? t('buttons.connect') : t('buttons.testAgain'),
    },
    {
      id: DataSourceTabAction.SAVE,
      component: (props: StyledButtonType): JSX.Element => {
        const type = testStatus === DataSourceTestConnectionStatusEnum.failed ? 'primary' : 'secondary';
        return <BigidLoaderButton as={type} isLoading={isSaveInProgressFromModal} {...props} />;
      },
      dataAid: 'DataSourceConfiguration-save',
      disabled: isSaveInProgressFromModal || !isFormDirty,
      onClick: () => execute(DataSourceConnectionModalAction.SUBMIT),
      text: testStatus === DataSourceTestConnectionStatusEnum.notStarted ? t('buttons.save') : t('buttons.saveChanges'),
    },
    {
      id: DataSourceTabAction.SCAN,
      component: isScanTemplatesEnabled ? BigidSplitMenuButton : BigidSplitButtonBase,
      dataAid: generateDataAid('DataSourceConfiguration-scan', ['button']),
      disabled: isSaveInProgressFromModal || (isScanTemplatesEnabled ? isLoadingScanActions || !mainScanAction : false),
      onClick: () => (isScanTemplatesEnabled ? mainScanAction : defaultScanProfileAction)?.execute(),
      text: t('buttons.scan'),
      actions: (isScanTemplatesEnabled ? scanActions : scanProfileActions)
        ?.map(({ id, label, execute }) => ({
          id,
          text: label,
          label,
          dataAid: generateDataAid('DataSourceConfiguration-scan', [id]),
          onClick: execute,
        }))
        ?.filter((_, index) => index !== 0),
    },
  ];

  const saveBeforeEditActions = [
    {
      component: SecondaryButton,
      dataAid: 'DataSourceConfiguration-edit',
      isClose: true,
      onClick: handleEditWithoutSaving,
      text: t('buttons.discard'),
    },
    {
      component: PrimaryButton,
      dataAid: 'DataSourceConfiguration-save',
      isClose: false,
      onClick: handleSubmit,
      text: t('buttons.save'),
    },
  ];

  const tabActions = !isLoadingData ? mapTestConnectionStatusToWizardActions(testStatus, tabActionList) : [];
  const headerActions = tabActions.map(({ component: Component, ...rest }) => (
    <Component key={rest.id} size="large" {...rest} />
  ));

  return (
    <>
      {isPageLoading && !notFoundMessage && (
        <div data-aid="DataSourceConfiguration" className={dataSourceConfigurationWrapper}>
          <BigidLoader />
        </div>
      )}
      {isPageLoading && !notFoundMessage ? null : !isNewTabsExp ? (
        <DataSourceConfigurationContext.Provider
          value={{
            ...configDataSourceState,
            updateState,
            onSubmitHandler,
            getValuesContainer,
            refreshData,
            isTouched,
            resetValuesToInitFunction,
            createSetValueFunction,
          }}
        >
          <div data-aid="DataSourceConfiguration" className={dataSourceConfigurationWrapper}>
            <>
              {isConfigLoading && <BigidLoader />}
              {isFormReady && <BigidForm key={formKey} {...formProps} />}
            </>
            <DataSourceConfigurationNoData title={DEFAULT_NOT_FOUND_DS_TITLE} reasonList={DEFAULT_NOT_FOUND_REASONS} />
          </div>
        </DataSourceConfigurationContext.Provider>
      ) : (
        <BigidPageLayout
          title={pageTitle}
          pageBackgroundColor="transparent"
          showHeaderBackground={false}
          statusBadge={statusBadge}
          actions={headerActions}
          breadcrumbs={{ breadcrumbs: !isLoading ? breadcrumbs : [] }}
        >
          <div
            data-aid="DataSourceConfiguration"
            className={dataSourceConfigurationWrapper}
            style={{ maxWidth: '100vw' }}
          >
            <DataSourceConfigurationTabWizard
              source={($stateParams.selectedType || type) as unknown as DataSourceTypes}
              form={control}
              modal={modal}
              sourceId={id || $stateParams?.id}
              label={$stateParams?.dsLabel}
              showDetachedSuccess
              showError={testStatus === DataSourceTestConnectionStatusEnum.failed}
              shouldAutoOpenModalAndConnect={shouldAutoOpenModalAndConnect}
              initialStep={$stateParams?.wizardStep ?? DEFAULT_TAB_SECTION}
              setShouldAutoConnect={setShouldAutoConnect}
              updateState={updateState}
              documentationUrl={documentationUrl}
              onStateChange={handleTriggerStateChange}
              onChange={onFormValuesChange}
              onBack={() => execute(DataSourceConnectionModalAction.CLOSE)}
            />
            <DataSourceConfigurationNoData title={DEFAULT_NOT_FOUND_DS_TITLE} reasonList={DEFAULT_NOT_FOUND_REASONS} />
          </div>
        </BigidPageLayout>
      )}
      {isPageLoading
        ? null
        : !isNewTabsExp && (
            <DataSourceConnectionModal
              key={editKey}
              source={($stateParams.selectedType || type) as unknown as DataSourceTypes}
              sourceId={id || $stateParams?.id}
              dsName={$stateParams?.id || id}
              dsTypeLabel={$stateParams?.dsLabel}
              nameInDocs={documentationUrl}
              isOpen={showModal}
              isEdit
              onClose={handleCloseModal}
              shouldAutoConnect={shouldAutoOpenModalAndConnect}
              setShouldAutoConnect={setShouldAutoConnect}
            />
          )}
      {showSaveBeforeEdit && (
        <BigidDialog
          title={t('saveDataSourceBeforeEdit.header')}
          isOpen={showSaveBeforeEdit}
          buttons={saveBeforeEditActions}
          onClose={() => setShowSaveBeforeEdit(false)}
        >
          {t('saveDataSourceBeforeEdit.content')}
        </BigidDialog>
      )}
    </>
  );
};
