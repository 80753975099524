import { MutableRefObject, ReactText, useCallback, useEffect, useRef, useState } from 'react';
import { SSE_EVENTS, SSEDataMessage, subscribeToRepeatedSSEEventById } from '../../../../services/sseService';
import { getAutoDiscoveryAppInfo } from '../../../../services/autoDiscoveryService';
import { AsyncOperation } from '../../../../components/AsyncOperationProcessingWidget/AsyncOperationProcessingWidget';
import { CustomAppStatus } from '../../../CustomApp/views/ActivityLog/ActivityLog';
import { DataCatalogAsyncOperationStatus } from '../../../DataCatalog/DataCatalogAsyncOps/DataCatalogAsyncOpsTypes';
import { startCase } from 'lodash';
import { notificationService } from '../../../../services/notificationService';
import { CloudProvider } from '../../../AutoDiscoveryWizard/autoDiscoveryWizardServices';

interface AutoDiscoveryResponse {
  execution: {
    message: string;
    progress: number;
    status_enum: CustomAppStatus;
    tpa_action_id: ReactText;
  };
}

interface UpdateOperationsParams {
  updatedOperations: AsyncOperation[];
  operationOptions: MutableRefObject<AsyncOperation<any, any>[]>;
}

interface DsDiscoveryProgress {
  status: AutoDiscoveryStatus;
  scanned: number;
  total: number;
  dsType: string;
  progress: number;
}

enum AutoDiscoveryStatus {
  DISCOVERING = 'discover',
  INITIAL = 'initial',
  COMPLETED = 'complete',
  CREATING = 'creating',
  UPDATING = 'updating',
}

const convertStatusType = (status: AutoDiscoveryStatus): DataCatalogAsyncOperationStatus => {
  switch (status) {
    case AutoDiscoveryStatus.COMPLETED:
      return DataCatalogAsyncOperationStatus.COMPLETED;
    case AutoDiscoveryStatus.DISCOVERING:
      return DataCatalogAsyncOperationStatus.RUNNING;
    case AutoDiscoveryStatus.CREATING:
      return DataCatalogAsyncOperationStatus.RUNNING;
    case AutoDiscoveryStatus.UPDATING:
      return DataCatalogAsyncOperationStatus.RUNNING;
    case AutoDiscoveryStatus.INITIAL:
      return DataCatalogAsyncOperationStatus.RUNNING;
    default:
      return DataCatalogAsyncOperationStatus.RUNNING;
  }
};

type DataSourceAsyncOperationListenerParams = {
  onOperationRun?: (sseRoute: string, isOperationRunning: boolean) => void;
  onOperationCompleted?: (dsType: string) => void;
};

const dsNames = ['S3', 'Athena', 'DynamoDB', 'Kinesis', 'EMR', 'Redshift', 'RDS'];

const dsValueToName: Record<string, string> = {
  s3: 'S3',
  athena: 'Athena',
  dynamodb: 'DynamoDB',
  kinesis: 'Kinesis',
  emr: 'EMR',
  redshift: 'Redshift',
  rds: 'RDS',
};

const generateInitialOperations = (): AsyncOperation[] => {
  return dsNames.map(dsName => {
    return {
      name: 'Discovering in AWS',
      percentage: 0,
      entityName: dsName,
      description: 'Pending',
    };
  });
};

const parseAutoDiscoveryMessageIntoOperation = (message: string): AsyncOperation[] => {
  try {
    const { results } = message.startsWith('{') ? JSON.parse(message) : { results: [] };
    const dsDiscoveryProgressArray = results[0].dsDiscoveryProgress;
    return dsDiscoveryProgressArray.map(({ scanned, status, dsType, total, progress }: DsDiscoveryProgress) => {
      return {
        description: `${startCase(status)} ${scanned}/${total}`,
        entityName: dsValueToName[dsType] || dsType,
        status: convertStatusType(status),
        percentage: progress ? Math.round(progress) : 0,
      };
    });
  } catch (e) {
    console.warn('Failed to parse message from discovery app', e);
    return [];
  }
};

const insertUpdatedOperationsToListOfOperations = ({
  updatedOperations,
  operationOptions,
}: UpdateOperationsParams): void => {
  operationOptions.current =
    updatedOperations?.map(updatedOperation => {
      return {
        name: 'Discovering in AWS',
        ...updatedOperation,
        percentage:
          updatedOperation.status === DataCatalogAsyncOperationStatus.COMPLETED
            ? undefined
            : updatedOperation.percentage,
      };
    }) ?? [];
};

const updateOperationCompleted = (
  updatedOperations: AsyncOperation[],
  onOperationCompleted: (dsType: string) => void,
  globalStatus: CustomAppStatus,
) => {
  let completedOperationsList = '';
  updatedOperations?.forEach(({ status, entityName }) => {
    if (status === DataCatalogAsyncOperationStatus.COMPLETED) {
      completedOperationsList = `${
        completedOperationsList !== '' ? completedOperationsList + ', ' : completedOperationsList
      }${entityName}`;
    }
  });
  updatedOperations?.every(({ status }) => status === DataCatalogAsyncOperationStatus.COMPLETED) &&
    onOperationCompleted(completedOperationsList);
  if (globalStatus === CustomAppStatus.COMPLETED) {
    notificationService.success('Auto discovery completed!');
  }
};

export const useAutoDiscoveryWizardAwsOperation = ({
  onOperationRun,
  onOperationCompleted,
}: DataSourceAsyncOperationListenerParams) => {
  const [operations, setOperations] = useState<AsyncOperation[]>([]);
  const [isAssignmentInProcess, setIsAssignmentInProcess] = useState<boolean>(false);
  const operationOptions = useRef<AsyncOperation[]>(generateInitialOperations());

  useEffect(() => {
    onOperationRun(SSE_EVENTS.TPA_ACTIONS_EXECUTIONS, true);
  }, [onOperationRun]);

  const handleAutoDiscoveryWizardBroadcastEventReceived = useCallback(
    async ({ results = [] }: SSEDataMessage<AutoDiscoveryResponse>) => {
      const { actionId } = await getAutoDiscoveryAppInfo(CloudProvider.AWS);
      const { message, progress, tpa_action_id, status_enum } = results[0].execution || {};

      if (actionId !== tpa_action_id) {
        return operations;
      }

      const updatedOperations = parseAutoDiscoveryMessageIntoOperation(message);
      insertUpdatedOperationsToListOfOperations({
        updatedOperations,
        operationOptions,
      });

      updateOperationCompleted(updatedOperations, onOperationCompleted, status_enum);

      setOperations(operationOptions.current);
      setIsAssignmentInProcess(!isAssignmentInProcess);
    },
    [isAssignmentInProcess, onOperationCompleted, operations],
  );

  useEffect(() => {
    const unsubscribe = subscribeToRepeatedSSEEventById(
      SSE_EVENTS.TPA_ACTIONS_EXECUTIONS,
      handleAutoDiscoveryWizardBroadcastEventReceived,
    );

    return () => {
      unsubscribe();
    };
  }, [handleAutoDiscoveryWizardBroadcastEventReceived]);

  return { operations };
};
