import React, { useCallback, useEffect, useState } from 'react';
import {
  BigidTextField,
  PrimaryButton,
  toastNotificationService,
  BigidDialog,
  BigidBody1,
  TertiaryButton,
} from '@bigid-ui/components';
import { styled } from '@mui/material';
import { cloneDeep } from 'lodash';
import { RiskControl } from './types';
import { httpService } from '../../services/httpService';
import { trackManualEvent, RisksAndControlsTrackingEvents } from './risksAnalytics';
import { isPermitted } from '../../services/userPermissionsService';
import { RISK_CONTROLS_PERMISSIONS } from '@bigid/permissions';

const { RISK_CONTROL_ADD_CONTROL } = RisksAndControlsTrackingEvents;

const FormContainer = styled('div')({
  justifyContent: 'space-between',
  display: 'flex',
  flexDirection: 'column',
  gap: 16,
  padding: '16px 16px 24px 16px',
});

export interface CreateControlDialogProps {
  isOpen: boolean;
  onClose?: () => void;
  setShowDialog?: (param: boolean) => void;
}

export const CreateControlDialog: React.FC<CreateControlDialogProps> = ({ isOpen, onClose, setShowDialog }) => {
  const emptyRiskControl: Partial<RiskControl> = { name: '', description: '', category: '' };
  const [riskControl, setRiskControl] = useState<RiskControl>(emptyRiskControl as RiskControl);
  const [isValidate, setIsValidate] = useState(false);

  const validateRisk = useCallback((): boolean => {
    return !riskControl?.name?.trim().length || !riskControl?.category;
  }, [riskControl]);

  const onSave = useCallback(async () => {
    try {
      setIsValidate(true);
      if (!riskControl || validateRisk()) {
        return;
      }

      let response;

      response = await httpService.post(`risk-controls`, riskControl);
      trackManualEvent(RISK_CONTROL_ADD_CONTROL, { ...riskControl });

      onClose?.();
      if (response)
        toastNotificationService.success('Created successfully', {
          shouldCloseOnTransition: true,
        });

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (err: any) {
      if (err.code === 11000) {
        // todo: change errors
        toastNotificationService.error(err.message, {
          shouldCloseOnTransition: true,
        });
        console.log(err);
      } else {
        console.log(err);
        toastNotificationService.error(err.message, {
          shouldCloseOnTransition: true,
        });
      }
    }
  }, [onClose, riskControl, validateRisk]);

  return (
    <BigidDialog
      borderTop
      borderBottom
      showCloseIcon
      isOpen={isOpen}
      title="Add new control to library"
      onClose={() => {
        setShowDialog(false);
      }}
      hideContentPadding
      buttons={[
        {
          onClick: () => {
            setShowDialog(false);
          },
          component: TertiaryButton,
          text: 'Cancel',
        },
        isPermitted(RISK_CONTROLS_PERMISSIONS.EDIT.name) && {
          component: PrimaryButton,
          text: 'Add Control',
          onClick: () => {
            onSave();
          },
        },
      ].filter(Boolean)}
    >
      <FormContainer>
        <BigidBody1>
          {' '}
          The risk control process is an essential part of risk management. It involves evaluating potential losses and
          reducing or eliminating them.
        </BigidBody1>
        <BigidTextField
          label="Friendly Name"
          placeholder="Enter control name"
          required
          dataAid="control-library-friendly-name"
          errorMessage={isValidate && !riskControl?.name?.length ? 'Required' : undefined}
          isError={isValidate && !riskControl?.name?.length}
          onChange={event => {
            if (riskControl) {
              const riskClone = cloneDeep(riskControl);
              riskClone.name = event.target.value;
              setRiskControl(riskClone);
            }
          }}
          value={riskControl?.name}
        />
        <BigidTextField
          label="Description"
          dataAid="control-library-description"
          placeholder="Enter description"
          multiline
          rows={1}
          onChange={event => {
            if (riskControl) {
              const riskClone = cloneDeep(riskControl);
              riskClone.description = event.target.value;
              setRiskControl(riskClone);
            }
          }}
          value={riskControl?.description}
        />
        <BigidTextField
          label="Standard / Category"
          required
          dataAid="control-library-category"
          placeholder="Enter standard/category"
          errorMessage={isValidate && !riskControl?.category?.length ? 'Required' : undefined}
          isError={isValidate && !riskControl?.category?.length}
          onChange={event => {
            if (riskControl) {
              const riskClone = cloneDeep(riskControl);
              riskClone.category = event.target.value;
              setRiskControl(riskClone);
            }
          }}
          value={riskControl?.category}
        />
      </FormContainer>
    </BigidDialog>
  );
};
