import { useState, MutableRefObject, useLayoutEffect } from 'react';
import { useResizeObserver } from '@bigid-ui/components';

type UseBoundingClientRectReturnType<T> = [Partial<DOMRectReadOnly>, (node: T) => void];

const ELEMENT_INITIAL_STATE = {
  width: 0,
  height: 0,
};

export const useBoundingClientRect = <T extends HTMLElement = HTMLDivElement>(
  ref: MutableRefObject<T>,
): UseBoundingClientRectReturnType<T> => {
  const [size, setSize] = useState<Partial<DOMRectReadOnly>>(ELEMENT_INITIAL_STATE);

  const [rect, ...rest] = useResizeObserver<T>(ref);

  useLayoutEffect(() => {
    setSize({
      width: rect?.width ?? 0,
      height: rect?.height ?? 0,
    });
  }, [rect?.width, rect?.height]);

  return [size, ...rest];
};
