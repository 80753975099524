import React, { FC, memo, ReactText, Dispatch, SetStateAction } from 'react';
import styled from '@emotion/styled';
import { BigidBody1, BigidMenuItem } from '@bigid-ui/components';
import { generateDataAid } from '@bigid-ui/utils';
import { BigidUndoExcludeIcon } from '@bigid-ui/icons';
import { FindingDetails, CuratedFieldStatus, MatchType, FindingCurateProps } from '../../curationService';
import { CurationEvents, trackEventCurationView } from '../../curationEventTrackerUtils';
import { useLocalTranslation } from '../../translations';

const UndoExcludeIcon = styled(BigidUndoExcludeIcon)`
  cursor: pointer;
`;

const TooltipButtonContainer = styled('span')`
  display: flex;
  align-items: center;
  cursor: pointer;
`;

const ExcludeList = styled('ul')`
  list-style-type: none;
  margin: 0;
  padding: 0;
`;

interface CuratedFieldValuePreviewTooltipProps {
  dataAid: string;
  finding: FindingDetails;
  onFindingCurate: (findingCurateProps: FindingCurateProps) => void;
  onDialogStateChange: (isOpen: boolean) => void;
  onExcludeOptionSelected: (option: MatchType) => void;
  dispatchGridReload?: Dispatch<SetStateAction<any[]>>;
  fieldId?: ReactText;
}

export const CuratedFieldValuePreviewTooltip: FC<CuratedFieldValuePreviewTooltipProps> = memo(
  ({ dataAid, finding, onFindingCurate, onDialogStateChange, onExcludeOptionSelected, fieldId }) => {
    const { reviewStatus } = finding;
    const { t } = useLocalTranslation('CuratedFields.CuratedFieldValuePreviewTooltip');

    const excludeOptions = {
      [MatchType.EXACT]: `${t('excludeExactMatch')}`,
      [MatchType.CONTAINS]: `${t('excludeContains')}`,
      [MatchType.STARTS_WITH]: `${t('excludeStartsWith')}`,
      [MatchType.ENDS_WITH]: `${t('excludeEndsWith')}`,
    };

    const excludeOptionsEntries = Object.entries(excludeOptions) as [MatchType, string][];

    const onUnreviewClick = (): void => {
      trackEventCurationView(CurationEvents.CURATION_FIELDS_ATTRIBUTE_PREVIEW_UNREVIEW_VALUE);
      const findingCurateProps = { finding, status: CuratedFieldStatus.UNCURATED, fieldId };
      onFindingCurate(findingCurateProps);
    };

    const handleOnListOptionClick = (option: MatchType) => {
      if (option === MatchType.EXACT) {
        trackEventCurationView(CurationEvents.CURATION_FIELDS_ATTRIBUTE_PREVIEW_EXCLUDE_VALUE);
        const findingCurateProps = {
          finding,
          status: CuratedFieldStatus.REJECTED,
          fieldId,
          matchType: MatchType.EXACT,
        };
        onFindingCurate(findingCurateProps);
      } else {
        onExcludeOptionSelected(option);
        onDialogStateChange(true);
      }
    };

    return (
      <>
        {reviewStatus !== CuratedFieldStatus.UNCURATED && (
          <TooltipButtonContainer onClick={onUnreviewClick} data-aid={generateDataAid(dataAid, ['unreview-icon'])}>
            <>
              <UndoExcludeIcon size="small" />
              <BigidBody1>{t('undoExclude')}</BigidBody1>
            </>
          </TooltipButtonContainer>
        )}

        {reviewStatus !== CuratedFieldStatus.REJECTED && (
          <ExcludeList>
            {excludeOptionsEntries.map(([matchType, menuItemValue]) => (
              <BigidMenuItem
                key={matchType}
                dataAid={generateDataAid(dataAid, [`${menuItemValue}-menu-item`])}
                onClick={() => handleOnListOptionClick(matchType)}
              >
                {menuItemValue}
              </BigidMenuItem>
            ))}
          </ExcludeList>
        )}
      </>
    );
  },
);

CuratedFieldValuePreviewTooltip.displayName = 'CuratedFieldValuePreviewTooltip';
