import { styled } from '@mui/material';
import React, { FC } from 'react';
import { getApplicationPreference } from '../../../services/appPreferencesService';
import { useLocalTranslation } from '../translations';
import { DataExplorerQuickSearch } from '../DataExplorerQuickSearch/DataExplorerQuickSearch';

const SearchBarContainer = styled('div')`
  width: 100%;
  height: 44px;
`;

type SearchBarProps = {
  dataAid?: string;
};

export const SearchBar: FC<SearchBarProps> = ({ dataAid = 'DataExplorerSearch' }) => {
  const { t } = useLocalTranslation('SearchBar');

  const isMetadataSearchEnabled = getApplicationPreference('METADATA_SEARCH_ENABLED');
  const placeholder = isMetadataSearchEnabled ? t('placeholder') : t('placeholderDisabled');

  return (
    <SearchBarContainer>
      <DataExplorerQuickSearch placeholder={placeholder} isDisabled={!isMetadataSearchEnabled} />
    </SearchBarContainer>
  );
};
