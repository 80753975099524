import { httpService } from './httpService';
import { notificationService } from './notificationService';
import { SystemEvents, systemEventsEmitter } from './systemEvents';
import { loginService } from '../../authentication/login/login.service';
import { BigidFieldFilter, BigidFilter, BigidSideFilterSectionIdentifier } from '@bigid-ui/components';
import { BigidGridSorting } from '@bigid-ui/grid';
import { ReactText } from 'react';

export interface PageFilterPreferences {
  filter?: BigidFilter;
  sideFilterSelection?: BigidFieldFilter[];
  optionalSideFilterSections?: BigidSideFilterSectionIdentifier[];
  searchQuery?: string;
}

export interface PageGridPreferences {
  filter?: BigidFilter;
  sort?: BigidGridSorting[];
  hiddenColumnNames?: string[];
  columnsOrder?: string[];
  columnsWidth?: { columnName: string; width: ReactText }[];
}

export interface UserPreference<T> {
  preference: string;
  data?: T;
  filter?: PageFilterPreferences;
  grid?: PageGridPreferences;
  updated_at?: string;
  created_at?: string;
}

let isInitialized = false;
const userPreferences = new Map<string, UserPreference<any>>();

export const initUserPreferences = async () => {
  try {
    const {
      data: { data = [] },
    } = await httpService.fetch<{ data: UserPreference<any>[] }>('user-preferences');

    data.forEach(({ preference, ...rest }) => {
      userPreferences.set(preference, { preference, ...rest });
    });
    isInitialized = true;
  } catch (error) {
    notificationService.error('Error getting user preferences');
  }
};

const add = async <T = any>(preferenceToAdd: UserPreference<T>, isSharedPreference = false) => {
  try {
    const path = `user-preferences/${isSharedPreference ? 'shared' : ''}`;
    const {
      data: {
        data: [updatedPreference],
      },
    } = await httpService.post<any, UserPreference<T>>(path, preferenceToAdd);
    const { preference } = updatedPreference;
    userPreferences.set(preference, updatedPreference);
  } catch (error) {
    notificationService.error(`Error adding ${preferenceToAdd.preference || 'user preferences'}`);
  }
};

const update = async <T = any>(preferenceToUpdate: UserPreference<T>) => {
  try {
    const { updated_at, created_at, ...rest } = preferenceToUpdate;

    const {
      data: {
        data: [updatedPreference],
      },
    } = await httpService.put<any, UserPreference<T>>('user-preferences', rest);

    const { preference } = updatedPreference;
    userPreferences.set(preference, updatedPreference);
  } catch (error) {
    notificationService.error(`Error updating ${preferenceToUpdate.preference || 'user preferences'}`);
  }
};

const initIfNeeded = async () => {
  if (loginService.isLoggedIn() && !isInitialized) {
    await initUserPreferences();
  }
};

const initUserPreferencesListener = systemEventsEmitter.addEventListener(SystemEvents.LOGIN, initIfNeeded);

const cleanUserPreferencesListener = systemEventsEmitter.addEventListener(SystemEvents.LOGOUT, () => {
  userPreferences.clear();
  isInitialized = false;
});

window.addEventListener('unload', () => {
  initUserPreferencesListener();
  cleanUserPreferencesListener();
});

const getAll = async (): Promise<Map<string, UserPreference<any>>> => {
  await initIfNeeded();
  return userPreferences;
};

const get = async <T = any>(preference: string): Promise<UserPreference<T>> => {
  await initIfNeeded();
  return userPreferences.get(preference);
};

const setSharedBooleanUserPreference = async <T = any>(key: SHARED_PREFERENCES, value: boolean) => {
  const existingValue = await userPreferencesService.get(key);
  if (!existingValue) {
    const valueToSet = { preference: key, data: { value } };
    userPreferencesService.add(valueToSet, true);
  }
};

enum SHARED_PREFERENCES {
  CATALOG_WAS_USED_AT_LEAST_ONCE = 'shared.dataCatalogWasUsedAtLeastOnce',
  ACTIVITY_HIGHLIGHTS_REPORT_WAS_VISITED = 'shared.activityHighlightsReportWasVisited',
  RISK_ASSESSMENT_REPORT_WAS_VISITED = 'shared.riskAssessmentReportWasVisited',
}

export const userPreferencesService = { get, update, add, getAll, setSharedBooleanUserPreference, SHARED_PREFERENCES };
