import React, { FC } from 'react';
import { makeStyles } from '@mui/styles';
import { BigidLayout } from '@bigid-ui/layout';
import { BigidBody1, BigidFieldFilter, BigidLoader, BigidPaper } from '@bigid-ui/components';
import { generateDataAid } from '@bigid-ui/utils';
import { UseCurationStateResponse } from '../useCurationState';
import { useCuratedFieldsConfig } from './useCurationFieldsConfig';
import { useLocalTranslation } from '../translations';
import { CuratedAttribute, CuratedDataSource, CurationStatus } from '../curationService';
import { ReviewedEntitiesProgress } from '../ReviewedEntitiesProgress';
import { CuratedFieldsVariant } from './curatedFieldsUtils';

export interface CuratedFieldsProps {
  dataAid?: string;
  curationStatus?: CurationStatus;
  curatedEntityName?: string;
  currentCuratedAttribute: Pick<CuratedAttribute, 'attributeName' | 'attributeType' | 'displayName'>;
  currentCuratedDataSource?: Pick<CuratedDataSource, 'source'>;
  onFieldReviewed?: UseCurationStateResponse['onFieldReviewed'];
  additionalFilter?: BigidFieldFilter[];
  variant?: CuratedFieldsVariant;
  structuredOnly?: boolean;
}

const useStyles = makeStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    width: '100%',
  },
  headerWrapper: {
    height: '20px',
    padding: '8px 12px 12px 12px',
    boxSizing: 'content-box',
    display: 'flex',
    justifyContent: 'space-between',
  },
  content: {
    display: 'flex',
    height: 'calc(100% - 40px)',
    width: '100%',
    padding: '0 12px 0px 12px',
  },
});

export const CuratedFields: FC<CuratedFieldsProps> = ({
  dataAid = 'CuratedFields',
  currentCuratedDataSource,
  currentCuratedAttribute,
  onFieldReviewed,
  curationStatus,
  curatedEntityName,
  variant = CuratedFieldsVariant.CURATED_FIELDS,
  structuredOnly = false,
}) => {
  const classes = useStyles();
  const translation = 'CuratedFields';
  const { t } = useLocalTranslation(translation);

  const { isReady, layoutConfig } = useCuratedFieldsConfig({
    currentCuratedDataSource,
    currentCuratedAttribute,
    onFieldReviewed,
    curationStatus,
    variant,
    structuredOnly,
  });

  return isReady ? (
    <div className={classes.root} data-aid={dataAid}>
      <div className={classes.headerWrapper} data-aid={generateDataAid(dataAid, ['headerWrapper'])}>
        <BigidBody1 data-aid={generateDataAid(dataAid, ['header'])}>
          {t('approveOrReject', {
            attributeName: currentCuratedAttribute.displayName ?? currentCuratedAttribute.attributeName,
          })}
        </BigidBody1>
        {curationStatus && (
          <ReviewedEntitiesProgress
            curationStatus={curationStatus}
            curatedEntityName={curatedEntityName}
            translation={translation}
          />
        )}
      </div>
      <div className={classes.content} data-aid={generateDataAid(dataAid, ['content'])}>
        <BigidPaper>
          <BigidLayout config={layoutConfig} />
        </BigidPaper>
      </div>
    </div>
  ) : (
    <BigidLoader dataAid={generateDataAid(dataAid, ['loader'])} />
  );
};
