import { BigidGridColumn, BigidGridColumnTypes } from '@bigid-ui/grid';
import { startCase } from 'lodash';
import { MapScanStatusToProgressStatus, ScansGridRow } from '../ScanTypes';
import {
  calculateParentScanRightFilter,
  calculateRightFilterColor,
  getScanOwnerChips,
  getProgressPercentage,
  getScanTemplateRowIfEnabled,
  isScanNameClickable,
  isScanStillRunningByDate,
  getChipPropsByState,
} from '../ScanUtils';
import { $state } from '../../../services/angularServices';
import { CONFIG } from '../../../../config/common';
import { ScansUITrackingEvent, trackEventScansView } from '../ScansEventTrackerUtils';

export const getInitialActiveScansGridColumns: () => BigidGridColumn<ScansGridRow>[] = () => [
  {
    name: 'name',
    title: 'Scan Name',
    sortingEnabled: true,
    width: 350,
    formatter: {
      onClick: async ({ value, row }) => {
        const { link } = value;
        trackEventScansView(ScansUITrackingEvent.ACTIVE_SCANS_SCAN_INSIGHT_LINK_CLICK, { ...row });
        $state.go(CONFIG.states.SCANS_SCAN_INSIGHTS, {
          scanProfileName: link.linkParams.scanProfileName,
          scanId: link.linkParams.scanId,
        });
        return {};
      },
    },
    getCellValue: row => ({
      link: {
        text: row.name,
        disabled: !isScanNameClickable(row),
        linkParams: {
          scanProfileName: row.name,
          scanId: row._id,
        },
      },
    }),
    type: BigidGridColumnTypes.LINK,
  },
  {
    name: 'type',
    title: 'Type',
    sortingEnabled: true,
    getCellValue: row => startCase(row.type),
    type: BigidGridColumnTypes.TEXT,
    width: 200,
  },
  {
    name: 'state',
    title: 'Status',
    sortingEnabled: false,
    getCellValue: ({ state }) => {
      return {
        chip: {
          ...getChipPropsByState(state),
        },
      };
    },
    type: BigidGridColumnTypes.CHIP,
    width: 250,
  },
  {
    name: 'progress',
    title: 'Complete Rate',
    width: 400,
    getCellValue: ({
      type,
      piisummary_completed_dt,
      scan_management_complete_dt,
      progress: { failed, complete, total, state },
      isFailedObjects,
    }) => {
      return {
        //TODO: solution for metadata/labeling to show indeterminate progress
        progressBar: {
          percentage: getProgressPercentage({
            complete,
            total,
            isScanRunning: isScanStillRunningByDate(piisummary_completed_dt, scan_management_complete_dt),
            isSubScan: false,
          }),
          status: MapScanStatusToProgressStatus[state],
          width: '200px',
          size: 'large',
          color: calculateRightFilterColor(state),
          rightFilter: calculateParentScanRightFilter({
            complete,
            failed,
            total,
            state,
            type,
            isFailedObjects,
          }),
        },
      };
    },
    type: BigidGridColumnTypes.PROGRESS_BAR,
  },
  {
    name: 'startedAt',
    title: 'Started At',
    getCellValue: row => row.startTime,
    sortingEnabled: true,
    type: BigidGridColumnTypes.DATE,
    width: 200,
  },
  {
    name: 'updated_at',
    title: 'Updated At',
    getCellValue: row => row.updated_at,
    sortingEnabled: false,
    type: BigidGridColumnTypes.DATE,
    width: 200,
  },
  {
    name: '_id',
    title: 'Scan ID',
    getCellValue: row => row._id,
    type: BigidGridColumnTypes.TEXT,
    isHiddenByDefault: true,
    width: 300,
  },
  {
    name: 'owners',
    title: 'Scan Owners',
    getCellValue: ({ owners }) => {
      if (!owners?.length) {
        return;
      }
      return {
        chips: getScanOwnerChips(owners),
      };
    },
    type: BigidGridColumnTypes.CHIPS,
    sortingEnabled: false,
    isHiddenByDefault: true,
    width: 200,
  },
  {
    name: 'origin',
    title: 'Origin',
    getCellValue: row => row.origin,
    type: BigidGridColumnTypes.TEXT,
    isHiddenByDefault: true,
    width: 300,
  },
  {
    name: 'executedBy',
    title: 'Executed By',
    getCellValue: row => row.executedBy,
    type: BigidGridColumnTypes.TEXT,
    isHiddenByDefault: true,
    width: 300,
  },
  ...getScanTemplateRowIfEnabled(),
];
