import React, { FC } from 'react';
import { BigidLink, BigidPrimaryCheckbox } from '@bigid-ui/components';
import makeStyles from '@mui/styles/makeStyles';
import { AwsPoliciesSelection } from '../hooks/useAwsPolicyGenerator';
import { publicUrls } from '../../../../../../../../config/publicUrls';
import { useLocalTranslation } from '../../../../../../translations';
import { trackFmsdEvent, FmsdTrackingEvents } from '../../../../../../fmsdEventsTrackerUtils';
import { CloudProvider } from '../../../../../../../AutoDiscoveryWizard/autoDiscoveryWizardServices';

const useStyles = makeStyles({
  dialogText: {
    paddingLeft: 0,
  },
  checkboxContainer: {
    paddingTop: 8,
    flexDirection: 'column',
    display: 'flex',
  },
});

export interface AwsInfoDialogProps {
  policiesSelection: AwsPoliciesSelection[];
  onPolicySelection: (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => void;
}

export const AwsPolicyGenerator: FC<AwsInfoDialogProps> = ({ policiesSelection, onPolicySelection }) => {
  const { dialogText, checkboxContainer } = useStyles({});
  const { t } = useLocalTranslation('Connect.Dialog');

  return (
    <div>
      <span className={dialogText}>{t('aws.policyGenerator.bodyText')}</span>{' '}
      <BigidLink
        text={t('learnMoreLinkText')}
        href={publicUrls.DOCS_AWS_IAM_USERGUIDE}
        shouldOpenNewTab={true}
        onClick={() => {
          trackFmsdEvent(FmsdTrackingEvents.FMSD_CONNECT_POLICY_GENERATOR_LEARN_MORE_CLICK, {
            [FmsdTrackingEvents.DS_TYPE]: CloudProvider.AWS,
          });
        }}
      />
      <br />
      <div className={checkboxContainer}>
        {policiesSelection.map(({ label, value, isSelected, isDisabled }, index) => {
          return (
            <BigidPrimaryCheckbox
              key={index}
              onChange={onPolicySelection}
              checked={isSelected}
              label={label}
              value={value}
              disabled={isDisabled}
              dataAid={`fmsdAwsInfoDialog-checkbox-${value}${isSelected ? '-selected' : ''}${
                isDisabled ? '-disabled' : ''
              }`}
            />
          );
        })}
      </div>
    </div>
  );
};
