import React, { useState, FC, useEffect, useCallback, ReactNode, useRef, ReactText } from 'react';
import { Theme, useTheme } from '@mui/material/styles';
import { Popper, ClickAwayListener } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { CheckCircle, Cancel } from '@mui/icons-material';
import {
  BigidLoader,
  BigidColors,
  BigidPaper,
  BigidBody2,
  BigidIcon,
  BigidIconSize,
  BigidTooltip,
} from '@bigid-ui/components';
import { DataCatalogAsyncOperationStatus } from '../../views/DataCatalog/DataCatalogAsyncOps/DataCatalogAsyncOpsTypes';

const LOADER_SIZE = 20;

const useStyles = makeStyles({
  root: {
    width: '130px',
    height: LOADER_SIZE,
  },
  loaderWrapper: {
    cursor: 'pointer',
    display: 'flex',
  },
  loader: {
    position: 'relative',
    width: '100%',
    marginLeft: '10px',
  },
  processing: {
    color: BigidColors.purple[700],
  },
  arrow: {
    top: '-8px',
    marginTop: '-8px',
    width: '0px',
    height: '0px',
    position: 'absolute',
    zIndex: 1,
    right: '50px',
    '&::before': {
      width: '0px',
      height: '0px',
      margin: 'auto',
      content: '""',
      display: 'block',
      borderStyle: 'solid',
      borderColor: `transparent transparent ${BigidColors.white} transparent`,
      borderWidth: '8px',
    },
  },
  list: {
    minHeight: '35px',
    minWidth: '380px',
    padding: '0 15px',
    maxHeight: '192.5px',
    overflowY: 'auto',
    overflowX: 'hidden',
  },
  listItem: {
    height: '35px',
    display: 'flex',
    alignItems: 'center',
    '&:not(:last-child)': {
      borderBottom: `1px solid ${BigidColors.borderLight}`,
    },
  },
  operationType: {
    paddingRight: '5px',
  },
  operationName: {
    fontWeight: 700,
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    paddingRight: '5px',
  },
  operationDescr: {
    paddingRight: '5px',
    whiteSpace: 'nowrap',
  },
  operationStatus: {
    display: 'flex',
    marginLeft: 'auto',
  },
  operationStatusIcon: {
    display: 'flex',
  },
  noTasks: {
    textAlign: 'center',
    width: '100%',
  },
});

const getStatusDefaultIcon = (status: DataCatalogAsyncOperationStatus, dataAid: string): ReactNode => {
  const operationIconMapping: { [status in DataCatalogAsyncOperationStatus]?: ReactNode } = {
    [DataCatalogAsyncOperationStatus.COMPLETED]: (
      <BigidIcon
        size={BigidIconSize.REGULAR_PLUS}
        icon={CheckCircle}
        color={BigidColors.successGreen}
        dataAid={`${dataAid}-success`}
      />
    ),
    [DataCatalogAsyncOperationStatus.ERROR]: (
      <BigidIcon
        size={BigidIconSize.REGULAR_PLUS}
        icon={Cancel}
        color={BigidColors.failureRed}
        dataAid={`${dataAid}-failure`}
      />
    ),
  };

  return operationIconMapping[status];
};

export type AsyncOperation<OperationStatus = any, OperationName = any> = {
  id?: ReactText;
  status?: OperationStatus;
  name?: OperationName;
  entityName?: string;
  description?: string;
  percentage?: number;
};

export type AsyncOperationMap = Map<string, boolean>;

export interface AsyncOperationProcessingWidgetProps<OperationStatus = any, OperationName = any> {
  dataAid?: string;
  operations: AsyncOperation<OperationStatus, OperationName>[];
  operationsMap: AsyncOperationMap;
  getStatusIcon?: (status: OperationStatus, dataAid: string, theme: Theme) => ReactNode;
  getStatusTooltipText?: (status: OperationStatus) => string;
}

export const AsyncOperationProcessingWidget: FC<AsyncOperationProcessingWidgetProps> = ({
  dataAid = 'AsyncOperationProcessingWidget',
  operations,
  operationsMap,
  getStatusIcon,
  getStatusTooltipText,
}) => {
  const classes = useStyles();
  const theme = useTheme();

  const anchorElRef = useRef<HTMLDivElement>();
  const arrowElRef = useRef<HTMLElement>();
  const onClickTimestampRef = useRef(Date.now());
  const [isProcessingShown, setIsProcessingShown] = useState<boolean>(false);
  const [isPopperTriggered, setIsPopperTriggered] = useState<boolean>(false);

  useEffect(() => {
    const hasOperationsRunning = operations.some(({ status }) => status === DataCatalogAsyncOperationStatus.RUNNING);
    const isAtLeastOneOperationEverRan = Array.from(operationsMap.values()).some(operationStatus => operationStatus);

    const isProcessingShownComputed = isPopperTriggered ? isAtLeastOneOperationEverRan : hasOperationsRunning;
    setIsProcessingShown(isProcessingShownComputed);

    if (!isProcessingShownComputed) {
      setIsPopperTriggered(false);
    }
  }, [isPopperTriggered, operations, operationsMap]);

  const handleLoaderClick = useCallback((): void => {
    onClickTimestampRef.current = Date.now();
    setIsPopperTriggered(isPopperTriggeredPrev => !isPopperTriggeredPrev);
  }, []);

  const handlePopperClose = useCallback((): void => {
    if (Date.now() - onClickTimestampRef.current > 100) {
      setIsPopperTriggered(false);

      const hasOperationsRunning = operations.some(({ status }) => status === DataCatalogAsyncOperationStatus.RUNNING);

      if (!hasOperationsRunning) {
        setIsProcessingShown(false);
      }
    }
  }, [operations]);

  return (
    <div className={classes.root} data-aid={`${dataAid}-widget`}>
      {isProcessingShown && (
        <div
          className={classes.loaderWrapper}
          onClick={handleLoaderClick}
          data-aid={`${dataAid}-loader`}
          ref={anchorElRef}
        >
          <div className={classes.loader}>
            <BigidLoader size={LOADER_SIZE} labelPosition="right" labelColor={BigidColors.purple[700]} />
          </div>
          <div className={classes.processing}>Processing</div>
        </div>
      )}
      <ClickAwayListener onClickAway={handlePopperClose}>
        <Popper
          id={`${dataAid}-popper`}
          placement="bottom-end"
          anchorEl={anchorElRef.current}
          open={isPopperTriggered}
          modifiers={[
            {
              name: 'offset',
              options: {
                offset: [0, 10],
              },
            },
            {
              name: 'flip',
              enabled: true,
            },
            {
              name: 'preventOverflow',
              enabled: true,
              options: {
                boundariesElement: 'scrollParent',
              },
            },
            {
              name: 'arrow',
              enabled: true,
              options: {
                element: arrowElRef.current,
              },
            },
          ]}
        >
          <span className={classes.arrow} ref={arrowElRef} />
          <BigidPaper overflowHidden={false}>
            <div className={classes.list}>
              {operations.length > 0 ? (
                operations.map(({ percentage, status, entityName, name, description }, index) => {
                  const operationDataAid = `${dataAid}-popper-item-${index}`;

                  return (
                    <div key={index} className={classes.listItem} data-aid={operationDataAid}>
                      <BigidBody2 id={`${operationDataAid}-type-${name}`} className={classes.operationType}>
                        {name}
                      </BigidBody2>
                      {entityName && (
                        <BigidBody2
                          id={`${operationDataAid}-name-${entityName}`}
                          className={classes.operationName}
                          title={entityName}
                        >
                          {entityName}
                        </BigidBody2>
                      )}
                      {description && (
                        <BigidBody2 id={`${operationDataAid}-count-${description}`} className={classes.operationDescr}>
                          {description}
                        </BigidBody2>
                      )}
                      <BigidTooltip
                        title={getStatusTooltipText(status)}
                        isDisabled={typeof getStatusTooltipText !== 'function'}
                      >
                        <div className={classes.operationStatus}>
                          {!isNaN(percentage) ? (
                            <BigidBody2 id={`${operationDataAid}-percentage-${percentage}`}>{percentage}%</BigidBody2>
                          ) : (
                            <div className={classes.operationStatusIcon}>
                              {typeof getStatusIcon === 'function'
                                ? getStatusIcon(status, `${operationDataAid}-status`, theme)
                                : getStatusDefaultIcon(status, `${operationDataAid}-status`)}
                            </div>
                          )}
                        </div>
                      </BigidTooltip>
                    </div>
                  );
                })
              ) : (
                <div className={classes.listItem} data-aid={`${dataAid}-popper-item-empty`}>
                  <BigidBody2 className={classes.noTasks}>No tasks</BigidBody2>
                </div>
              )}
            </div>
          </BigidPaper>
        </Popper>
      </ClickAwayListener>
    </div>
  );
};
