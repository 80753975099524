import React, { ChangeEvent, MutableRefObject, useEffect, useRef, useState } from 'react';
import { useLocalTranslation } from '../../translations';
import {
  BigidDialog,
  BigidDialogButtonType,
  BigidDialogProps,
  BigidTextField,
  PrimaryButton,
} from '@bigid-ui/components';
import { useValidator } from '../../../../../hooks/useValidator';
import { isDataSourceExist } from '../../../../../services/dataSourcesService';
import { CollaborationFieldAction } from '../../config/collaboration';
import { generateDataAid } from '@bigid-ui/utils';
import type { ValidationRule } from '../../../../../utilities/validation';

type DataSourceConnectionCollaborationSaveModalProps = {
  dataAid?: string;
  action?: string;
  source?: string;
  onSave: (sourceName: string, action?: string) => Promise<void>;
} & Partial<BigidDialogProps>;

type DataSourceConnectionCollaborationSaveContentProps = {
  control: MutableRefObject<string>;
  label: string;
  defaultValue?: string;
  placeholder: string;
  errorMessage?: string;
  onChange?: () => void;
};

const DataSourceConnectionCollaborationSaveContent = ({
  control,
  label,
  defaultValue,
  errorMessage,
  placeholder,
  onChange,
}: DataSourceConnectionCollaborationSaveContentProps) => {
  const handleChange = (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    control.current = event.target.value;
    onChange?.();
  };

  useEffect(() => {
    if (defaultValue) {
      control.current = defaultValue;
    }
  }, []);

  return (
    <BigidTextField
      defaultValue={defaultValue}
      errorMessage={errorMessage}
      isError={!!errorMessage}
      placeholder={placeholder}
      label={label}
      size="large"
      onChange={handleChange}
    />
  );
};

export const DataSourceConnectionCollaborationSaveModal = ({
  dataAid = 'DataSourceConnectionCollaborationSaveModal',
  action = CollaborationFieldAction.INVITE,
  source: dataSourceName,
  onSave,
  onClose,
  isOpen,
}: DataSourceConnectionCollaborationSaveModalProps) => {
  const control = useRef<string>();
  const [error, setError] = useState<string>();
  const [isDisabled, setDisabled] = useState(false);
  const { t } = useLocalTranslation();

  const rules: ValidationRule<string>[] = [
    {
      id: 'UNIQUE',
      message: t('collaboration.unique'),
      validate: async (value: string) => {
        try {
          const isAlreadyInUse = await isDataSourceExist(value);

          return !isAlreadyInUse;
        } catch {
          return false;
        }
      },
    },
    {
      id: 'NOT_NULL',
      message: t('collaboration.notNull'),
      validate: (value: string) => !!value,
    },
    {
      id: 'DS_NAME',
      message: t('collaboration.invalidDsName'),
      validate: (value: string) => {
        try {
          const regex = '^[\\w\\-\\s\\(\\):]+$';
          return new RegExp(regex).test(value);
        } catch {
          return false;
        }
      },
    },
  ];

  const { validate } = useValidator(rules);

  const clearError = () => setError(null);

  const handleOnSave = async () => {
    setDisabled(true);

    const { isValid, errors } = await validate(control.current, ['DS_NAME', 'NOT_NULL', 'UNIQUE']);
    const [{ message }] = errors ?? [{}];

    isValid ? await onSave(control.current, action) : setError(message);
    setDisabled(false);
  };

  const handleNameChange = () => clearError();

  const actions: BigidDialogButtonType[] = [
    {
      component: PrimaryButton,
      dataAid: generateDataAid(dataAid, ['action', 'save']),
      isClose: false,
      disabled: isDisabled,
      onClick: handleOnSave,
      text: action === CollaborationFieldAction.INVITE ? t('buttons.saveAndInvite') : t('buttons.saveAndRevoke'),
    },
  ];

  return (
    <BigidDialog
      data-aid={dataAid}
      title={t('collaboration.save.header')}
      isOpen={isOpen}
      onClose={onClose}
      buttons={actions}
    >
      <DataSourceConnectionCollaborationSaveContent
        defaultValue={dataSourceName}
        onChange={handleNameChange}
        errorMessage={error}
        placeholder={t('collaboration.save.placeholder')}
        control={control}
        label={t('collaboration.save.label')}
      />
    </BigidDialog>
  );
};
