import React, { FC } from 'react';
import { BigidBody1, BigidHeading1, PrimaryButton } from '@bigid-ui/components';
import makeStyles from '@mui/styles/makeStyles';
import DiscoveryImage from '../../../assets/icons/AutoDiscovery.svg';
import { sessionStorageService } from '../../../../common/services/sessionStorageService';
import { $state } from '../../../services/angularServices';
import { DATA_SOURCES_INIT_VIEWS } from '../../DataSources/DataSources';
import { getApplicationPreference } from '../../../services/appPreferencesService';

const useStyles = makeStyles({
  mainContainer: {
    display: 'flex',
    padding: '30px 50px 0 50px',
    width: '100%',
    height: '100%',
    flexDirection: 'row',
    alignItems: 'center',
  },
  contentContainer: {
    width: '70%',
    maxWidth: 520,
    paddingLeft: 30,
  },
  imageContainer: {
    width: '30%',
    height: '100%',
  },
  discoveryImage: {
    width: '100%',
    height: '100%',
  },
  mainText: {
    paddingTop: 20,
    paddingBottom: 20,
  },
});

export const AutoDiscoveryNoData: FC = () => {
  const classes = useStyles({});

  const onTryAgainClick = () => {
    $state.go(`dataSourcesInit`, {
      path: DATA_SOURCES_INIT_VIEWS.smallIdInit.path,
    });
  };

  return (
    <div className={classes.mainContainer}>
      <div className={classes.imageContainer}>
        <DiscoveryImage className={classes.discoveryImage} />
      </div>
      <div className={classes.contentContainer}>
        <BigidHeading1>No Data Sources Were Discovered.</BigidHeading1>
        <BigidBody1 className={classes.mainText}>
          {getApplicationPreference('USE_SAAS')
            ? 'Check your account authentication credentials and try again.'
            : 'No data sources were discovered based on the provided IAM role authorization.'}
        </BigidBody1>
        {getApplicationPreference('USE_SAAS') && (
          <>
            <PrimaryButton size="medium" text="Try Again" color="purple" onClick={onTryAgainClick} />
          </>
        )}
      </div>
    </div>
  );
};
