import { defaultsDeep } from 'lodash';
import { httpService } from '../../../services/httpService';
import { notificationService } from '../../../services/notificationService';
import { DynamicRichTextSubSection, ReportTemplate, ReportTemplateWithoutId } from './Types';

export type UpdateOrWriteResult = {
  id: string;
};

export type DeleteResult = {
  id: string;
  isDeleted: boolean;
};

export function getAllTemplates(fields?: (keyof ReportTemplate)[]) {
  return httpService
    .fetch<{ data: ReportTemplate[] }>('sar/report-templates', fields ? { fields: JSON.stringify(fields) } : null)
    .then(({ data: { data } }) => data);
}

export function getTemplateById(id: string, fields?: (keyof ReportTemplate)[]) {
  return httpService
    .fetch<{ data: ReportTemplate }>(
      `sar/report-templates/${encodeURIComponent(id)}`,
      fields ? { fields: JSON.stringify(fields) } : null,
    )
    .then(({ data: { data } }) => data);
}

export function createTemplate(data: ReportTemplateWithoutId) {
  return httpService
    .post<{ data: UpdateOrWriteResult }, ReportTemplateWithoutId>('sar/report-templates', data)
    .then(({ data: { data } }) => {
      const { id } = data;
      return { id };
    });
}

export async function updateTemplate(id: string, data: Partial<ReportTemplate>) {
  return httpService.put(`sar/report-templates/${encodeURIComponent(id)}`, data);
}

export function deleteTemplate(id: string) {
  return httpService.delete(`sar/report-templates/${encodeURIComponent(id)}`);
}

export function getDefaultTemplateModel(): ReportTemplateWithoutId {
  const template: ReportTemplateWithoutId = {
    templateName: '',
    templateTitle: '',
    templateDescription: '',
    companyInformationPage: { sectionTitle: 'Company Information' },
    informationCollectionPage: { sectionTitle: 'Information Collection' },
    generalInformationPage: { sectionTitle: 'General Information' },
    personalInfoPage: { sectionTitle: 'Personal Information' },
    companyInfo: { isEnabled: true, sectionTitle: 'General Company Information' },
    contactInfo: { isEnabled: true, sectionTitle: 'Company Contact Information' },
    userDisplayInfo: {
      isEnabled: true,
      sectionTitle: 'Subject Request Information',
      displayRequestId: true,
      displayProfileName: true,
      displayDisplayName: true,
      requestIdLabel: 'Request ID',
      profileNameLabel: 'Profile Name',
      displayNameLabel: 'Display name',
    },
    geoDataTransfer: { isEnabled: true, sectionTitle: 'Geographical Data Transfer' },
    purposes: { isEnabled: true, sectionTitle: 'Why We Collect The Data' },
    thirdParties: { isEnabled: true, sectionTitle: 'Third Party Data Transfer' },
    mechanisms: { isEnabled: true, sectionTitle: 'Collection Mechanisms' },
    rights: { isEnabled: true, sectionTitle: 'Know Your Rights' },
    consents: { isEnabled: true, sectionTitle: 'Preference Records' },
    dataSources: { isEnabled: true, sectionTitle: 'Data Sources' },
    personalInfo: { isEnabled: true, sectionTitle: 'Personal Details' },
  };
  return template;
}

export function fillReportTemplateWithDefaults(template: Partial<ReportTemplate>) {
  const fixedObj = defaultsDeep(template, getDefaultTemplateModel());
  return fixedObj;
}

export async function saveChanges(id: string, data: Partial<ReportTemplate>) {
  try {
    const putData = stripReadonlyProps(data);
    await updateTemplate(id, putData);
    notificationService.success(`Changes saved.`);
  } catch (e) {
    notificationService.error(`Failed to save changes`);
    console.error(`An error has occurred while saving general info: ${e}`);
  }
}

function stripReadonlyProps(data: Partial<ReportTemplate>) {
  const obj = { ...data };
  ['_id', 'id', 'updated_at', 'created_at'].forEach(key => {
    delete (obj as any)[key];
  });
  [
    'companyInformationPageDynamicRichText',
    'generalInformationPageDynamicRichText',
    'informationCollectionPageDynamicRichText',
    'personalInfoPageDynamicRichText',
  ].forEach(key => {
    (obj as any)[key]?.forEach((dynamicRichTextItem: DynamicRichTextSubSection) => {
      delete dynamicRichTextItem.dynamicRichTextId;
    });
  });
  return obj;
}
