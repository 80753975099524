import { analyticsService } from '../../services/analyticsService';

export enum FmsdTrackingEvents {
  // Welcome Screen:
  FMSD_WELCOME_START_CLICK = 'FMSD_WELCOME_START_CLICK',

  // Categories screen:
  FMSD_CATEGORIES_CATEGORY_CLICK = 'FMSD_CATEGORIES_CATEGORY_CLICK',
  FMSD_CATEGORIES_SWITCH_TO_REGULATIONS_CLICK = 'FMSD_CATEGORIES_SWITCH_TO_REGULATIONS_CLICK',
  CATEGORY_TYPE = 'CATEGORY_TYPE',
  FMSD_CATEGORIES_CATEGORY_OVERWRITE_DIALOG_YES_CLICK = 'FMSD_CATEGORIES_CATEGORY_OVERWRITE_DIALOG_YES_CLICK',
  FMSD_CATEGORIES_CATEGORY_OVERWRITE_DIALOG_NO_CLICK = 'FMSD_CATEGORIES_CATEGORY_OVERWRITE_DIALOG_NO_CLICK',

  // Regulations Screen:
  FMSD_REGULATIONS_REGULATION_CLICK = 'FMSD_REGULATIONS_REGULATION_CLICK',
  FMSD_REGULATIONS_SWITCH_TO_CATEGORIES_CLICK = 'FMSD_REGULATIONS_SWITCH_TO_CATEGORIES_CLICK',
  REGULATION_TYPE = 'REGULATION_TYPE',

  // Navigation Buttons: !!!
  BACK_CLICK = 'BACK_CLICK',
  NEXT_CLICK = 'NEXT_CLICK',
  SKIP_CLICK = 'SKIP_CLICK',

  // Connection Screen:
  FMSD_CONNECT_SIGNIN_CLICK = 'FMSD_CONNECT_SIGNIN_CLICK',
  FMSD_CONNECT_EDIT_CLICK = 'FMSD_CONNECT_EDIT_CLICK',
  FMSD_CONNECT_HELP_SERVICE_CLICK = 'FMSD_CONNECT_HELP_SERVICE_CLICK',
  FMSD_CONNECT_TEST_CONNECTION_CLICK = 'FMSD_CONNECT_TEST_CONNECTION_CLICK',
  FMSD_CONNECT_SAVE_AND_CLOSE_CLICK = 'FMSD_CONNECT_SAVE_AND_CLOSE_CLICK',
  FMSD_CONNECT_GENERATE_POLICY_CLICK = 'FMSD_CONNECT_GENERATE_POLICY_CLICK',
  FMSD_CONNECT_COPY_PERMISSIONS_JSON_CLICK = 'FMSD_CONNECT_COPY_PERMISSIONS_JSON_CLICK',
  FMSD_CONNECT_GO_BACK_TO_CONNECT_VIEW_CLICK = 'FMSD_CONNECT_GO_BACK_TO_CONNECT_VIEW_CLICK',
  FMSD_CONNECT_POLICY_GENERATOR_LEARN_MORE_CLICK = 'FMSD_CONNECT_POLICY_GENERATOR_LEARN_MORE_CLICK',
  DS_TYPE = 'DS_TYPE',
  TEST_CONNECTION_STATUS = 'TEST_CONNECTION_STATUS',
  SELECTED_DS_TYPES = 'SELECTED_DS_TYPES',

  // Results Screen:
  FMSD_DISCOVER_SCAN_CLICK = 'FMSD_DISCOVER_SCAN_CLICK',
  FMSD_DISCOVER_VIEW_DASHBOARD_CLICK = 'FMSD_DISCOVER_VIEW_DASHBOARD_CLICK',
  FMSD_DISCOVER_DS_TYPE_CLICK = 'FMSD_DISCOVER_DS_TYPE_CLICK',
  FMSD_DISCOVER_BACK_CLICK = 'FMSD_DISCOVER_BACK_CLICK',
  SELECTED_DS = 'SELECTED_DS',
  DS_NAMES = 'DS_NAMES',
}

export enum FmsdPageViewsEvents {
  FMSD_WELCOME_PAGE_VIEW = 'FMSD_WELCOME',
  FMSD_CATEGORIES_PAGE_VIEW = 'FMSD_CATEGORIES',
  FMSD_COUNTRIES_PAGE_VIEW = 'FMSD_COUNTRIES',
  FMSD_REGULATIONS_PAGE_VIEW = 'FMSD_REGULATIONS',
  FMSD_CONNECT_PAGE_VIEW = 'FMSD_CONNECT',
  FMSD_DISCOVER_PAGE_VIEW = 'FMSD_DISCOVER',
}

export const trackFmsdPageView = (eventType: FmsdPageViewsEvents) => {
  analyticsService.trackPageView(eventType as any);
};

export const trackFmsdEvent = (eventType: FmsdTrackingEvents, data?: Record<string, unknown>) => {
  analyticsService.trackManualEvent(eventType as any, data);
};
