import React, { FC } from 'react';
import { styled } from '@mui/material';
import { AutoDiscoverWizardAsideGuideLazy } from './AutoDiscoverWizardAsideGuideLazy';
import { AutoDiscoverWizardAsideErrors } from './AutoDiscoverWizardAsideErrors';
import { AutoDiscoverAsidePermissionsSummary } from './AutoDiscoverAsidePermissionsSummary/AutoDiscoverAsidePermissionsSummary';

const AutoDiscoverWizardAsideWrapper = styled('div')`
  display: flex;
  padding: 24px 28px;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;
  height: 100%;
  max-height: 100%;
  overflow-y: auto;
`;

export const AutoDiscoverWizardAside: FC = () => {
  return (
    <AutoDiscoverWizardAsideWrapper>
      <AutoDiscoverWizardAsideGuideLazy />
      <AutoDiscoverAsidePermissionsSummary />
      <AutoDiscoverWizardAsideErrors />
    </AutoDiscoverWizardAsideWrapper>
  );
};
