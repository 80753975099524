import React, { FC, Fragment, useEffect, useState } from 'react';
import { $state, $stateParams } from '../../services/angularServices';
import { CustomAppAction, Preset } from './views/CustomAppActions/CustomAppActions';
import { AppInfo, CustomAppPagesEnum } from './utils/CustomAppTypes';
import { withApplicationForm } from './ApplicationForm';
import { EditActionPresetWrapper } from './views/SetActionPreset/EditActionPresetWrapper';
import { CustomAppPageWrapper } from './CustomAppPageWrapper';
import { CONFIG } from '../../../config/common';
import { getCustomAppActionAndPreset, getCustomAppInfo } from './utils/CustomAppUtils';
import { isEmpty } from 'lodash';

export const CustomAppEditPresetPage: FC = () => {
  const [appInfo, setAppInfo] = useState<AppInfo>(null);
  const [action, setAction] = useState<CustomAppAction>(null);
  const [preset, setPreset] = useState<Preset>(null);

  useEffect(() => {
    const updateInfo = async () => {
      if (appInfo && action) return;
      const fetchedAppInfo = $stateParams.appInfo || (await getCustomAppInfo($stateParams.id));
      if (!fetchedAppInfo) {
        $state.go(CONFIG.states.CUSTOM_APP);
      }
      let fetchedAction = $stateParams.action;
      let fetchedPreset = $stateParams.preset;

      if (!fetchedAction) {
        const { action, preset } = await getCustomAppActionAndPreset(
          $stateParams.id,
          $stateParams.actionId,
          $stateParams.presetId,
        );
        if (!action) {
          $state.go(CONFIG.states.APPLICATIONS_MANAGEMENT);
          return;
        }
        fetchedAction = action;
        fetchedPreset = preset;
      }
      setAppInfo(fetchedAppInfo);
      setAction(fetchedAction);
      setPreset(fetchedPreset);
    };
    updateInfo();
  }, [action, appInfo]);

  return (
    <CustomAppPageWrapper appInfo={appInfo} isReady={!isEmpty(appInfo && action)}>
      {withApplicationForm(EditActionPresetWrapper, action?.friendlyName || action?.name, appInfo, {
        action,
        preset,
      })}
    </CustomAppPageWrapper>
  );
};
