/* eslint-disable import/no-named-as-default-member */
import { FC, ReactElement, ReactNode, Children } from 'react';
import type { DataSourceConnectionDialogContextType } from '../DataSourceConnectionDialog/DataSourceConnectionDialogProvider';

export type DataSourceConnectionDialogSlotComponentProps = {
  render?: (props: DataSourceConnectionDialogContextType) => JSX.Element;
  children?: ReactNode;
};

type SlotComponentType = FC<DataSourceConnectionDialogSlotComponentProps>;

export const mapNodeTypeToComponentSlot = (
  children: ReactNode,
  slot: string,
  mapping: Partial<Record<string, SlotComponentType>>,
) => Children.toArray(children).find(child => (child as ReactElement)?.type === mapping[slot]) ?? null;
