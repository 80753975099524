import React, { FC } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { BigidColors, BigidChip } from '@bigid-ui/components';
import { DataSourceTestConnectionStatusEnum } from './hooks/useTestConnection';
import { useLocalTranslation } from '../DataSourceConnectionModal/translations';
import { BigidChipColors } from '../constants/chipColors';

type DataSourceConnectionStatusProps = {
  status?: DataSourceTestConnectionStatusEnum;
};

const useStyles = makeStyles({
  wrapper: {
    display: 'flex',
    alignItems: 'center',
    width: '100vw',
    textAlign: 'left',
    padding: '0 14px',
    margin: '2px 0 0 0px',
    height: '28px',
  },
});

const mapStatusToChipComponentFn = (t: (label: string) => string): Record<DataSourceTestConnectionStatusEnum, FC> => ({
  [DataSourceTestConnectionStatusEnum.failed]: () => (
    <BigidChip
      size="medium"
      label={t('chips.failed')}
      color={BigidChipColors.failed.color}
      bgColor={BigidChipColors.failed.bgColor}
    />
  ),
  [DataSourceTestConnectionStatusEnum.success]: () => (
    <BigidChip
      size="medium"
      label={t('chips.success')}
      color={BigidChipColors.success.color}
      bgColor={BigidChipColors.success.bgColor}
    />
  ),
  [DataSourceTestConnectionStatusEnum.notStarted]: () => <BigidChip size="medium" label={t('chips.notStarted')} />,
});

export const DataSourceConnectionStatus = ({ status }: DataSourceConnectionStatusProps): JSX.Element => {
  const { wrapper } = useStyles({});
  const { t } = useLocalTranslation();
  const mapStatusToChipComponent = mapStatusToChipComponentFn(t);
  const Component = mapStatusToChipComponent[status];
  return (
    <div className={wrapper}>
      <Component />
    </div>
  );
};
