import React from 'react';
import { BigidGridColumnTypes, BigidGridProps, useFetch } from '@bigid-ui/grid';
import { CertificatesManagementNoDataView } from '../CertificatesManagement/CertificatesManagementWithLayout/CertificatesManagementNoDataView';
import {
  BigidLayoutMasterDetails,
  BigidLayoutMasterDetailsConfig,
  BigidLayoutMasterDetailsGridRowLayouts as BigidGridRowLayouts,
  BigidLayoutMasterDetailsGridSearchTypes as BigidGridSearchType,
} from '@bigid-ui/layout';
import { BigidStatusBadge, BigidStatusBadgeSize, BigidStatusBadgeType } from '@bigid-ui/components';
import { getFixedT } from './translations';
import { ConversionURIContentType, CredentialProviderRow, CredentialProvidersModel } from './types';

export const t = (key: string): string => getFixedT('')(key);
// export const getPermissions = () => {};
export const pageSize = 500;

export const createGridConfig = (
  id: string,
  config: ReturnType<typeof useFetch<CredentialProviderRow>>,
): BigidGridProps<CredentialProviderRow> => ({
  gridId: id,
  rows: config.rows,
  customRowIdName: 'name',
  totalRowsCount: config.totalRowsCount,
  skip: config.skip,
  onPagingChanged: config.onPagingChanged,
  onSortingChanged: config.onSortingChanged,
  onFiltersChange: config.onFiltersChanged,
  defaultSorting: config.defaultSorting,
  loading: config.isLoading,
  pageSize,
  noDataContent: <CertificatesManagementNoDataView message={t('message.noCredentialProvider')} />,
  columns: [
    {
      name: 'name',
      title: 'name',
      type: BigidGridColumnTypes.CUSTOM,
      width: 'auto',
      getCellValue: row => {
        return (
          <BigidLayoutMasterDetails.Row
            title={row.isPending ? t('message.newCredentialProvider') : row.name}
            subtitle={
              row.isPending ? (
                <BigidStatusBadge
                  label={t('statusText')}
                  type={BigidStatusBadgeType.DARK}
                  size={BigidStatusBadgeSize.SMALL}
                />
              ) : (
                row.name
              )
            }
            layout={BigidGridRowLayouts.TWO_ROW}
          />
        );
      },
    },
  ],
});

export const createLayoutConfig = (
  gridConfig: BigidGridProps<CredentialProviderRow>,
): BigidLayoutMasterDetailsConfig<CredentialProviderRow> => ({
  grid: gridConfig,
  search: {
    fields: ['name'],
    placeholder: t('search.placeholder'),
    type: BigidGridSearchType.INTEGRATED,
  },
});

export function encodeDecodeURIContent(type: ConversionURIContentType, data: Record<string, any>) {
  const updatedData: Record<string, any> = structuredClone(data);
  for (const key in data) {
    if (typeof data[key] === 'string') {
      switch (type) {
        case ConversionURIContentType.ENCODED:
          updatedData[key] = encodeURIComponent(data[key]);
          break;
        case ConversionURIContentType.DECODED:
          updatedData[key] = decodeURIComponent(data[key]);
          break;
        default:
          updatedData[key] = data[key];
      }
    }
  }

  return updatedData as CredentialProvidersModel;
}
