import React, { FC, useState, useMemo } from 'react';
import angular from 'angular';
import { convertToAngular } from '../../../common/services/convertToAngular';
import makeStyles from '@mui/styles/makeStyles';
import { BigidColors, BigidSelect, BigidSelectOption, PrimaryButton, SecondaryButton } from '@bigid-ui/components';
import { BigidCheckTool } from '../../components/BigidCheckTool/BigidCheckTool';
import { isPermitted } from '../../services/userPermissionsService';
import { TASKS_PERMISSIONS } from '@bigid/permissions';
import { ReassignDialog } from './ActionDialog/ReassignDialog';
import { ResolveDialog } from './ActionDialog/ResolveDialog';
import { User } from '../../utilities/systemUsersUtils';

interface Task {
  _id: string;
  checked?: boolean;
}

interface TasksHeaderProps {
  onFilterSelected: (filter: string, status: string) => void;
  onReassign: (assignee: string) => void;
  assignees: User[];
  onResolve: () => void;
  tasks: Task[];
  onCheckToolClick: (type: string) => void;
}

const ASSIGNED_TO_ME = 'Assigned to me';
const INITIATED_BY_ME = 'Initiated by me';
const ALL = 'All';
const OPEN = 'Open';
const RESOLVED = 'Resolved';

const useStyles = makeStyles({
  root: {
    width: '100%',
    height: 70,
    background: BigidColors.gray[150],
    borderTop: `1px solid ${BigidColors.gray[200]}`,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '0 14px',
  },
  left: { display: 'flex', alignItems: 'center' },
  right: { display: 'flex', alignItems: 'center' },
  taskInfo: { fontSize: '0.875rem', marginRight: 16, color: BigidColors.gray[900] },
  selectTaskFilter: { marginRight: 8, width: 150 },
  selectTaskStatus: { marginRight: 8, width: 110 },
  reassignButton: { marginRight: 10 },
  selectedText: { fontWeight: 'bold' },
});

const taskStatusOptions = [
  { label: OPEN, value: OPEN },
  { label: RESOLVED, value: RESOLVED },
];

export const TasksHeader: FC<TasksHeaderProps> = ({
  onFilterSelected,
  onReassign,
  assignees,
  onResolve,
  tasks = [],
  onCheckToolClick,
}) => {
  const classes = useStyles({});
  const taskFilterOptions = useMemo(
    () => [
      { label: ASSIGNED_TO_ME, value: ASSIGNED_TO_ME },
      { label: INITIATED_BY_ME, value: INITIATED_BY_ME },
      ...(isPermitted(TASKS_PERMISSIONS.READ_ALL_TASKS.name) ? [{ label: ALL, value: ALL }] : []),
    ],
    [],
  );

  const [filterSelected, setFilterSelected] = useState<BigidSelectOption>(taskFilterOptions[0]);
  const [statusSelected, setStatusSelected] = useState<BigidSelectOption>(taskStatusOptions[0]);
  const [isReassignDialogOpen, setIsReassignDialogOpen] = useState(false);
  const [isResolveDialogOpen, setIsResolveDialogOpen] = useState(false);

  const onChangeFilter = (selected: BigidSelectOption[]) => {
    setFilterSelected(selected[0]);
    onFilterSelected(selected[0]?.value, statusSelected.value);
  };

  const onChangeStatus = (selected: BigidSelectOption[]) => {
    setStatusSelected(selected[0]);
    onFilterSelected(filterSelected.value, selected[0]?.value);
  };

  const onReassignDialogOpen = () => setIsReassignDialogOpen(true);
  const onReassignDialogClose = () => setIsReassignDialogOpen(false);

  const onResolveDialogOpen = () => setIsResolveDialogOpen(true);
  const onResolveDialogClose = () => setIsResolveDialogOpen(false);

  const tasksSelectedAmount = tasks.filter(({ checked }) => checked).length;
  const isAllDisabled = tasks.length === tasksSelectedAmount;
  const isNoneDisabled = tasksSelectedAmount === 0;
  const areButtonsDisabled = statusSelected.value === RESOLVED || tasks.length === 0;

  return (
    <div className={classes.root}>
      <ReassignDialog
        onReassign={onReassign}
        assignees={assignees}
        isOpen={isReassignDialogOpen}
        onClose={onReassignDialogClose}
      />
      <ResolveDialog
        isOpen={isResolveDialogOpen}
        onClose={onResolveDialogClose}
        tasksAmount={tasksSelectedAmount}
        onResolve={onResolve}
      />
      <div className={classes.left}>
        <div className={classes.taskInfo}>
          {`${tasks.length} Tasks `}
          <span className={classes.selectedText}>{`${tasksSelectedAmount} Selected`}</span>
        </div>
        <BigidCheckTool
          onCheckAll={() => onCheckToolClick('all')}
          onCheckNone={() => onCheckToolClick('none')}
          disabled={{ all: isAllDisabled, none: isNoneDisabled }}
        />
        <div className={classes.reassignButton}>
          {isPermitted(TASKS_PERMISSIONS.REASSIGN.name) && (
            <SecondaryButton
              onClick={onReassignDialogOpen}
              dataAid="reassign-task-button"
              size="medium"
              disabled={areButtonsDisabled}
              text="Reassign"
            />
          )}
        </div>
        <div>
          <PrimaryButton
            onClick={onResolveDialogOpen}
            dataAid="resolve-task-button"
            size="medium"
            disabled={areButtonsDisabled}
            text="Resolve"
          />
        </div>
      </div>
      <div className={classes.right}>
        <div className={classes.taskInfo}>Show:</div>
        <div className={classes.selectTaskFilter} data-aid="tasks-filter-dropdown">
          <BigidSelect
            options={taskFilterOptions}
            onChange={onChangeFilter}
            menuPlacement="bottom"
            menuPosition="fixed"
            value={[filterSelected]}
            dataAid="tasks-filter-dropdown"
          />
        </div>
        <div className={classes.selectTaskStatus} data-aid="tasks-status-dropdown">
          <BigidSelect
            options={taskStatusOptions}
            onChange={onChangeStatus}
            menuPlacement="bottom"
            menuPosition="fixed"
            value={[statusSelected]}
            dataAid="tasks-status-dropdown"
          />
        </div>
      </div>
    </div>
  );
};

angular
  .module('app')
  .component(
    'tasksHeader',
    convertToAngular(TasksHeader, [
      'onFilterSelected',
      'onReassign',
      'assignees',
      'onResolve',
      'tasks',
      'onCheckToolClick',
    ]),
  );
