import React, { FC, useEffect, useReducer } from 'react';
import { BigidLoader } from '@bigid-ui/components';
import styled from '@emotion/styled';
import { pageHeaderService } from '../../../common/services/pageHeaderService';
import { gettingStartedReducer, ReducerActions } from './hooks/gettingStartedReducer';
import { GettingStartedContext } from './hooks/gettingStartedContext';
import { initState } from './utils/gettingStartedUtils';
import { GettingStartedCard } from './GettingStartedCard/GettingStartedCard';
import { GettingStartedSideMenu } from './GettingStartedSideMenu/GettingStartedSideMenu';
import { GettingStartedHeader } from './GettingStartedHeader/GettingStartedHeader';

const PageContentWrapper = styled.div({
  width: '100%',
  marginTop: '84px',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
});

const PageMiddleSection = styled.div(({ theme }) => ({
  width: '872px',
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  gap: '32px',
  height: '368px',
  background: theme.palette.bigid.white,
  boxShadow: '0px 0px 5px rgba(0, 0, 0, 0.15)',
  borderRadius: '8px',
  flex: 'none',
  order: 0,
  flexGrow: 0,
  justifyContent: 'space-between',
}));

export const GettingStarted: FC = () => {
  const [state, dispatch] = useReducer(gettingStartedReducer, { isLoading: true });
  useEffect(() => {
    const initGettingStartedItems = async () => {
      const state = await initState();
      dispatch({ type: ReducerActions.INIT, payload: state });
    };
    pageHeaderService.setTitle({ pageTitle: 'Getting Started' });
    pageHeaderService.setIsHidden(true);

    initGettingStartedItems();
    return () => {
      pageHeaderService.setIsHidden(false);
    };
  }, []);

  return (
    <GettingStartedContext.Provider
      value={{
        ...state,
        dispatch,
      }}
    >
      <PageContentWrapper data-aid="GettingStarted">
        {state.isLoading ? (
          <BigidLoader />
        ) : (
          <>
            <GettingStartedHeader />
            <PageMiddleSection>
              <GettingStartedSideMenu />
              <GettingStartedCard />
            </PageMiddleSection>
          </>
        )}
      </PageContentWrapper>
    </GettingStartedContext.Provider>
  );
};
