import React, { Fragment, FunctionComponent, useEffect, useState } from 'react';
import { BigidColors, BigidPaper, BigidTabsAndContent, BigidTabsAndContentProps } from '@bigid-ui/components';
import makeStyles from '@mui/styles/makeStyles';
import { OpenAccessFiles } from '../Files/OpenAccessFiles';
import { $state, $stateParams } from '../../../services/angularServices';
import { AciEntitySummarize } from '../InsightCharts/AciEntitySummarize';
import { pageHeaderService } from '../../../../common/services/pageHeaderService';
import { getUserById, UserPermission } from './UsersPermissionsService';
import { AciEntityType } from '../InsightCharts/AccessIntelligenceInsightsService';

const useStyles = makeStyles(() => ({
  paper: {
    margin: '16px 32px',
    height: 'calc(100% - 64px)',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
  },
  content: {
    padding: '8px 24px 16px 24px',
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  },
  contentContainer: {
    display: 'flex',
    overflow: 'hidden',
    borderRadius: '4px 4px 0px 0px',
    boxShadow: BigidColors.containerShadow,
    flexFlow: 'column nowrap',
    flex: '1 1 auto',
    padding: '6px',
  },
}));
export const SingleUser: FunctionComponent = () => {
  const classes = useStyles({});
  const [user, setUser] = useState<Partial<UserPermission>>(null);
  const { external, email, sharedObjectsCount, dataSource } = user || {};
  const { userId } = $stateParams;

  useEffect(() => {
    async function getUserData() {
      const user = await getUserById(userId);
      setUser(user);
    }
    getUserData();
  }, [userId]);

  useEffect(() => {
    pageHeaderService.setTitle({
      showBackButton: true,
      defaultFrom: {
        state: 'accessGovernance',
        params: { selectedTab: 'users' },
      },
      breadcrumbs: [
        {
          label: 'Users View',
          onClick: () => $state.go('accessGovernance', { selectedTab: 'users' }),
        },
        { label: user?.name },
      ],
    });
  }, [user, userId]);

  const tabsAndContentConfig: BigidTabsAndContentProps = {
    classes: {
      contentContainer: classes.contentContainer,
    },
    tabProps: {
      tabs: [
        {
          label: 'Files',
          data: {
            component: OpenAccessFiles,
            customProps: { aciEntityId: userId, aciEntityType: AciEntityType.Users },
          },
        },
      ],
    },
  };

  return (
    <Fragment>
      <AciEntitySummarize
        external={external}
        dataSource={dataSource}
        email={email}
        sharedObjectsCount={sharedObjectsCount}
      />
      <BigidPaper classes={{ root: classes.paper }}>
        <div className={classes.content}>
          <BigidTabsAndContent {...tabsAndContentConfig} />
        </div>
      </BigidPaper>
    </Fragment>
  );
};
