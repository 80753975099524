import React, { useCallback, useMemo, useState } from 'react';
import styled from '@emotion/styled';
import { BigidDropdownOption } from '@bigid-ui/components';
import { BigidLayoutMasterDetails, BigidLayoutMasterDetailsConfig, useLayout } from '@bigid-ui/layout';
import { BigidGridProps, BigidGridRow } from '@bigid-ui/grid';
import { LegacyApplicationSetup } from '../../../administration/applicationSetup/applicationSetup.component';
import { applicationSetupService } from '../../../administration/applicationSetup/applicationSetup.service';
import { getApplicationPreference } from '../../services/appPreferencesService';
import { ApplicationEntity, ApplicationGridRow } from './types';
import { ApplicationHeader } from './ApplicationHeader';
import { usePageHeader } from './hooks/usePageHeader';
import {
  alphaSort,
  convertCountriesToDropdownOptions,
  convertDataSourcesToDropdownOptions,
  createGridConfig,
  createLayoutConfig,
  getFormFields,
} from './utils';
import { useLocalTranslation } from './translations';
import { dataSourceConnectionsService, locationService } from '../../services/angularServices';
import { ApplicationFormMemo } from './ApplicationForm';
import { useFormActions } from './hooks/useFormActions';
import { AppllicationEmptyState } from './AppllicationEmptyState';
import { useRouteLeavingListener } from '../../components/RouteLeaving/hooks/useRouteLeavingListener';

type BigidLayoutMasterDetailsRenderProps<GridRow extends BigidGridRow> = {
  config: BigidLayoutMasterDetailsConfig<GridRow>;
  selectedItem: GridRow;
};

export const Wrapper = styled.div`
  position: relative;
  box-sizing: border-box;
  margin-left; 1rem;
  height: calc(100% - 2rem);
  `;

export const LoaderWrapper = styled.div`
  height: 100%;
  position: relative;
`;

export const ApplicationSetup = () => {
  const [dataSourcesOptions, setDataSourcesOptions] = useState<BigidDropdownOption[]>([]);
  const [countriesOptions, setCountriesOptions] = useState<BigidDropdownOption[]>([]);
  const isLegacy = useMemo(() => getApplicationPreference('SHOW_LEGACY_MASTER_DETAILS_VIEWS'), []);
  const { t } = useLocalTranslation();
  const layout = useLayout<ApplicationGridRow>('BigidLayoutMasterDetails', {
    pageSize: 1000,
    fetchDataFunction: async () => {
      const promises = [
        applicationSetupService.getApplicationItems(),
        !dataSourcesOptions.length && dataSourceConnectionsService.getAllSystems(),
        !countriesOptions.length && locationService.getCountriesList(),
      ];
      const [{ applications }, dataSources, countries] = await Promise.all(promises);

      dataSources && setDataSourcesOptions(convertDataSourcesToDropdownOptions(dataSources.systems));
      countries && setCountriesOptions(convertCountriesToDropdownOptions(countries));

      return {
        data:
          applications
            .filter((app: ApplicationEntity) => typeof app.name != 'undefined')
            .sort(alphaSort)
            .map((item: ApplicationEntity) => ({
              ...item,
              id: item._id,
            })) ?? [],
        totalCount: applications.length ?? 0,
      };
    },
    initialSorting: [{ field: 'credential_id', order: 'asc' }],
  });
  const { gridId, rows, isLoading, apiRef, getSelectedRow, ...rest } = layout;
  const hasNoApps = !rows?.length && !isLoading;
  const gridConfig: BigidGridProps<ApplicationGridRow> = useMemo(
    () => createGridConfig(gridId, { ...rest, rows, isLoading, apiRef }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [rows, isLoading],
  );
  const config: BigidLayoutMasterDetailsConfig<ApplicationGridRow> = useMemo(
    () => createLayoutConfig(gridConfig),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [gridConfig],
  );
  const selectedRow = getSelectedRow();
  const fields = useMemo(
    () =>
      getFormFields({
        dataSourcesOptions,
        countriesOptions,
        isDatasourceListEnabled: getApplicationPreference('APPLICATIONS_DATA_SOURCE_LIST_ENABLED'),
      }),
    [countriesOptions, dataSourcesOptions],
  );
  const {
    isBusy,
    formControls,
    handleApplicationAdd,
    handleSubmit,
    handleDelete,
    handleSelectionChange,
    handleSearchChange,
    showUnsavedChangesDialog,
  } = useFormActions({
    row: selectedRow,
    layout,
    fields,
  });
  usePageHeader({ handleApplicationAdd, shouldShowAction: !!rows?.length && !isLegacy });
  const { updateIsRouteLeavingEnabled } = useRouteLeavingListener(showUnsavedChangesDialog);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const updateIsRouteLeavingEnabledMemo = useCallback(updateIsRouteLeavingEnabled, []);

  return isLegacy ? (
    <LegacyApplicationSetup />
  ) : (
    <Wrapper>
      <BigidLayoutMasterDetails
        disabled={isBusy || hasNoApps}
        onSelectionChange={handleSelectionChange}
        onSearchSubmit={handleSearchChange}
        onSearchChange={handleSearchChange}
        config={config as unknown as BigidLayoutMasterDetailsConfig<BigidGridRow>}
      >
        <BigidLayoutMasterDetails.Header
          render={({ selectedItem }: BigidLayoutMasterDetailsRenderProps<ApplicationGridRow>): JSX.Element => {
            const { isPending, name } = selectedItem ?? {};
            return (
              selectedItem && (
                <ApplicationHeader
                  title={isPending ? t('header.addNewApplication') : name}
                  row={selectedItem}
                  handleSubmit={handleSubmit}
                  handleDelete={handleDelete}
                />
              )
            );
          }}
        />
        <BigidLayoutMasterDetails.Content
          render={({ selectedItem }: BigidLayoutMasterDetailsRenderProps<ApplicationGridRow>) =>
            selectedItem ? (
              <ApplicationFormMemo
                key={selectedItem.id}
                formControls={formControls}
                row={selectedItem}
                fields={fields}
                setFormIsTouched={updateIsRouteLeavingEnabledMemo}
              />
            ) : (
              hasNoApps && <AppllicationEmptyState handleApplicationAdd={handleApplicationAdd} />
            )
          }
        />
      </BigidLayoutMasterDetails>
    </Wrapper>
  );
};
