import React, { FC } from 'react';
import { BigidToastNotification } from '@bigid-ui/components';
import { $transitions } from '../../services/angularServices';

export const BigidNotifications: FC = () => {
  const isLogout = (transition: any) => transition.to().url.includes('login');

  return <BigidToastNotification isLoggedOut={isLogout} onTransitionSuccess={$transitions.onSuccess} />;
};

/* Conversion modules section */
import angular from 'angular';
import { convertToAngular } from '../../../common/services/convertToAngular';

angular.module('app').component('bigidNotifications', convertToAngular(BigidNotifications));
