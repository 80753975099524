import { compact, slice } from 'lodash';
import { CustomApps, getApps } from '../../views/ApplicationsManagement/applicationManagementService';
import { appsLicenseService } from '../../services/appsLicenseService';
import { appToNewIcon } from '../Sidebar/SidebarUtils';
import { CUSTOM_APP_PAGE } from './NavigationSidebar';
import { getCustomAppNormalizedFriendlyName } from '../../views/CustomApp/utils/CustomAppUtils';
import { getApplicationPreference } from '../../services/appPreferencesService';

export interface AppPreferencesPopular {
  appId: string;
}

const POPULAR_INSTALLED_APP = [
  {
    appId: '2dba75cb-34dd-467f-949f-02b0b127a2d2',
  },
  {
    appId: 'cd07f2cf-dce8-4e35-8e2b-fed4185f1284',
  },
  {
    appId: 'd6a589f-189a-4cdb-9064-04294c7dce4f',
  },
];

const MAX_POPULAR_APPS_IN_BALLOON = 16;

export const getPopularApps = (customApps: CustomApps[], isLimitNumberOfPopular?: boolean) => {
  const apps = getApps();

  const popularApps = POPULAR_INSTALLED_APP.filter(({ appId }) => {
    const app = apps.find(({ id }) => id === appId);
    return app;
  });

  const popularCustomApps = customApps?.map(customApp => ({ ...customApp, appId: customApp._id }));

  let popularAppsToAdd = [...popularApps, ...popularCustomApps];

  if (isLimitNumberOfPopular) {
    popularAppsToAdd = slice(popularAppsToAdd, 0, MAX_POPULAR_APPS_IN_BALLOON);
  }

  const popularMenuItems = compact(
    popularAppsToAdd.map(({ appId }) => {
      const app = apps.find(app => app.id === appId);
      const customApp = customApps.find(customApp => customApp._id === appId);
      if (app) {
        const { name: title, icon, to: link, onClick, id } = app;
        const sideBarAppIcons = appToNewIcon;
        const sideBarIcon = sideBarAppIcons.find(({ appId: sidebarIconId }) => sidebarIconId === id)?.icon || icon;
        return {
          title,
          icon: sideBarIcon,
          ...(link && { link, selectedStates: [link] }),
          ...(onClick && { onClick }),
        };
      }
      if (customApp) {
        const { tpa_name, friendlyName, manifest_name, _id: id, vendor, side_bar_icon, settings }: any = customApp;
        const title = friendlyName ? getCustomAppNormalizedFriendlyName(friendlyName, settings?.instanceId) : tpa_name;
        return {
          title,
          icon: side_bar_icon,
          onClick: () =>
            appsLicenseService.showAppExpirationNotificationForCustomAppAndGoToCustomAppPage(
              id,
              tpa_name,
              vendor,
              manifest_name,
            ),
          selectedStates: [`${CUSTOM_APP_PAGE}/${id}/actions`, `${CUSTOM_APP_PAGE}/${id}`],
        };
      }
    }),
  );

  return popularMenuItems;
};
