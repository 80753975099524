import { BigidFieldRenderProps, BigidFormFieldSelect } from '@bigid-ui/components';
import React, { FC, useMemo } from 'react';
import { TYPE_TO_AUTH_OPTIONS, CloudProvider } from '../../../constants';

export const AutoDiscoverySelectAuthTypeField: FC<BigidFieldRenderProps> = ({ misc, value, disabled, setValue }) => {
  const options = useMemo(() => TYPE_TO_AUTH_OPTIONS[misc.type as CloudProvider], [misc.type]);

  return (
    <BigidFormFieldSelect
      label={'Authentication Type'}
      options={options}
      value={value}
      disabled={disabled}
      isClearable={false}
      setValue={setValue}
    />
  );
};
