import { MutableRefObject, useRef, useState } from 'react';
import { DsTypesEnum } from '../../DataSourceConfiguration/types';
import { isObject } from 'lodash';
import { NESTED_FIELD_DELIMITER } from '../constants/constants';
import type {
  BigidFieldRenderProps,
  BigidFormStateAndHandlers,
  BooleanMap,
  ValidationResult,
} from '@bigid-ui/components';
import { UseValidationOpts, useValidation as useNameFieldValidation} from './useValidation';

export const EMPTY_ERROR_STATE = {};

// @info Nested types supporting delimited syntax
const supportedNestedFieldTypes = [DsTypesEnum.verticalObject];

const isDelimitedField = (field: string): boolean => field.includes(NESTED_FIELD_DELIMITER);

const isObjectField = (fieldProps: BigidFieldRenderProps): boolean =>
  supportedNestedFieldTypes.includes(fieldProps.misc?.type);

const convertToNestedErrors = (
  errors: Record<string, ValidationResult>,
  control: MutableRefObject<BigidFormStateAndHandlers>,
) =>
  Object.entries(errors).reduce((acc, [field, result]) => {
    const [parentField, nestedField] = field.split(NESTED_FIELD_DELIMITER);
    const parentFieldProps = control.current?.getFieldProps?.(parentField);
    const parentAcc = (acc as Record<string, Record<string, string>>)[parentField];

    return isDelimitedField(field) && isObjectField(parentFieldProps)
      ? { ...acc, [parentField]: { ...parentAcc, [nestedField]: result } }
      : { ...acc, [field]: result };
  }, {}) ?? null;

const useWatch = (control: MutableRefObject<BigidFormStateAndHandlers>) => {
  const [, setTrigger] = useState(0);

  const trigger = () => setTrigger(prev => prev + 1);

  const watch = (fieldName: string) => {
    const values = control.current?.getValues?.() ?? {};

    return values[fieldName];
  };

  return { trigger, watch };
};

export const useForm = <T = unknown>(formStateRef?: MutableRefObject<BigidFormStateAndHandlers>) => {
  const ref = useRef<BigidFormStateAndHandlers>();
  const control = formStateRef ?? ref;

  const setTouched = (fields: BooleanMap) =>
    Object.entries(fields).forEach(([field, isTouched]) => isTouched && control.current.setTouched(field));

  const clearErrors = () => control.current?.setErrors?.(EMPTY_ERROR_STATE);

  const setErrors = (errors: Record<string, ValidationResult>) => {
    // @info workaround for 'verticalObject' type fields to allow error handling of nested fields
    control.current?.setErrors?.(convertToNestedErrors(errors, control));
  };

  return {
    control,
    useWatch: () => useWatch(control),
    disable: (fieldName: string) => {
      control.current?.setDisabled?.(fieldName);
    },
    setFieldError: (field: string, error: ValidationResult) => {
      const isDelimited = isDelimitedField(field);

      if (isDelimited) {
        const [parentField, nestedField] = field.split(NESTED_FIELD_DELIMITER);
        const parentFieldProps = control.current.getFieldProps?.(parentField);
        const parentFieldErrors = control.current.errors[parentField];
        const isNestedObjectField = isObjectField(parentFieldProps);

        isNestedObjectField
          ? control.current?.setFieldError?.(parentField, {
              ...(isObject(parentFieldErrors) ? parentFieldErrors : EMPTY_ERROR_STATE),
              [nestedField]: error,
            } as unknown as string)
          : control.current?.setFieldError?.(field, error);
        return;
      }

      control.current?.setFieldError(field, error);
    },
    setErrors,
    getValues: () => control.current?.getValues?.() as T,
    // @info validate function with validation result
    validate: async (fieldName?: string, showErrors = true): Promise<boolean> => {
      const touched = control.current?.touchedFields ?? {};

      const isValid = await new Promise<boolean>(resolve => {
        control.current.validateAndSubmit(
          () => {
            resolve(true);
          },
          errors => {
            // @info 'validateAndSubmit' triggers validation on all fields and needs to be set to a single field if one is supplied
            const isError = fieldName ? errors?.[fieldName] : true;
            const showSingleError = fieldName;

            showSingleError && control.current.setErrors({ [fieldName]: errors?.[fieldName] });
            !showErrors && clearErrors();

            resolve(!isError);
          },
        );
      });

      setTouched(touched);

      return isValid;
    },
    setTouched,
    clearErrors,
    // @info uses data from external form
    isCached: !!formStateRef,
  };
};
