import React, { useState, useCallback, useEffect } from 'react';
import styled from '@emotion/styled';
import {
  PrimaryButton,
  SecondaryButton,
  BigidLink,
  BigidDropZone,
  BigidDialog,
  entityEventsEmitter,
  EntityEvents,
  BigidColorsV2,
} from '@bigid-ui/components';
import { downloadVendorsTemplate, uploadVendors } from './vendorsService';
import { VendorsTrackingEvents, trackVendors } from './utils/analytics';
import { notificationService } from '../../services/notificationService';
import {
  SSE_EVENTS,
  SSEDataMessage,
  SSEMessageTypes,
  subscribeToRepeatedSSEEventById,
} from '../../services/sseService';

const ContentWrapper = styled.div({
  display: 'flex',
  flexDirection: 'column',
  flexWrap: 'nowrap',
  justifyContent: 'center',
  gap: '32px',
  padding: '8px 24px 0px 24px',
});
const DescriptionWrapper = styled.p({
  fontSize: '14px',
  lineHeight: '22px',
});

const ErrorTextWrapper = styled.p({
  color: BigidColorsV2.red[600],
  marginTop: '-30px',
});

export interface VendorsImportDialogProps {
  isOpen: boolean;
  onClose?: () => void;
  onUpload?: () => void;
}

export interface VendorsImportDialog {
  importDialogProps: VendorsImportDialogProps;
  openImportDialog: () => Promise<boolean>;
}

interface Error {
  name: string;
  message: string;
}

const importInitialState: VendorsImportDialogProps = {
  isOpen: false,
};

export const useVendorsImportDialog = (): VendorsImportDialog => {
  const [importDialogProps, setImportDialogProps] = useState<VendorsImportDialogProps>(importInitialState);

  const openImportDialog = useCallback<VendorsImportDialog['openImportDialog']>(() => {
    return new Promise<boolean>(resolve => {
      setImportDialogProps({
        isOpen: true,
        onUpload: () => {
          setImportDialogProps(importInitialState);
          resolve(true);
        },
        onClose: () => setImportDialogProps(importInitialState),
      });
    });
  }, []);

  return {
    openImportDialog,
    importDialogProps,
  };
};

export const downloadImportVendorsTemplate = () => {
  try {
    trackVendors(VendorsTrackingEvents.IMPORT_VENDORS_TEMPLATE_DOWNLOAD_CLICK);
    downloadVendorsTemplate();
  } catch (err) {
    notificationService.error(`Failed to download vendors template`);
    console.error(`Failed to download vendors template: ${JSON.stringify(err?.response)}`);
  }
};

export const VendorsImportDialog = ({ isOpen, onClose, onUpload }: VendorsImportDialogProps) => {
  const [fileToUpload, setFileToUpload] = useState<File | undefined>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [errors, setErrors] = useState<Error[]>([]);
  const getError = (field: string) => errors.find(error => error.name === field);
  const fileError = getError('csv');

  const getImportDetailsMessage = ({ imported, skipped, duplicates }: Record<string, unknown>): string => {
    const result: string[] = [];
    imported && result.push(`Imported - ${imported}.`);
    duplicates && result.push(`Detected duplicates - ${duplicates}.`);
    skipped && result.push(`Skipped - ${skipped}.`);
    return result.join(' ');
  };

  useEffect(() => {
    const handleRepeatedUploadSSEEvent = ({ message, type, results }: SSEDataMessage) => {
      if (type === SSEMessageTypes.IN_PROGRESS) {
        notificationService.success(message);
        return;
      }
      const notification = type === SSEMessageTypes.FAILURE ? notificationService.error : notificationService.success;
      const importDetails = getImportDetailsMessage(results[0] || {});
      notification(`${message}. ${importDetails}`);
      entityEventsEmitter.emit(EntityEvents.RELOAD);
    };
    const unregisterSSEEvent = subscribeToRepeatedSSEEventById(
      SSE_EVENTS.VENDORS_UPLOAD_EVENT,
      handleRepeatedUploadSSEEvent,
    );
    return () => {
      unregisterSSEEvent();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleOnDrop = useCallback((files: File[], isRejectedFilesFound: boolean) => {
    if (isRejectedFilesFound) {
      return;
    }
    const [file] = files;
    setFileToUpload(file);
    setErrors([]);
  }, []);

  const resetStates = useCallback(() => {
    setFileToUpload(undefined);
    setErrors([]);
  }, []);

  const onCloseButtonClicked = () => {
    onClose();
    resetStates();
  };

  const onUploadButtonClicked = useCallback(async () => {
    setIsLoading(true);
    try {
      const vendorsFormData = new FormData();
      vendorsFormData.append('file', fileToUpload);
      vendorsFormData.append('name', fileToUpload?.name);
      await uploadVendors(SSE_EVENTS.VENDORS_UPLOAD_EVENT, vendorsFormData);
      onUpload();
      resetStates();
    } catch (err) {
      const errorMessage = `Failed to upload ${fileToUpload?.name}`;
      notificationService.error(errorMessage);
      console.error(`${errorMessage}: ${JSON.stringify(err?.response)}`);
      const { errors, message } = err?.response?.data || {};
      if (errors?.length || message) {
        setErrors(errors?.length ? errors : [{ name: 'csv', message }]);
      }
    } finally {
      setIsLoading(false);
    }
  }, [fileToUpload, onUpload, resetStates]);

  return (
    <BigidDialog
      isOpen={isOpen}
      title="Import Vendors"
      onClose={onCloseButtonClicked}
      maxWidth="xs"
      borderTop={true}
      isLoading={isLoading}
      buttons={[
        {
          component: props => (
            <SecondaryButton
              {...props}
              bi={{
                eventType: VendorsTrackingEvents.IMPORT_VENDORS_DIALOG_CANCEL_CLICK,
              }}
            />
          ),
          onClick: onCloseButtonClicked,
          text: 'Cancel',
        },
        {
          component: props => (
            <PrimaryButton
              {...props}
              bi={{
                eventType: VendorsTrackingEvents.IMPORT_VENDORS_DIALOG_UPLOAD_CLICK,
              }}
            />
          ),
          onClick: onUploadButtonClicked,
          text: 'Upload',
          disabled: !fileToUpload,
        },
      ]}
    >
      <ContentWrapper>
        <div>
          <DescriptionWrapper>
            {'Fill in '}
            <BigidLink text="this template" onClick={downloadImportVendorsTemplate} underline="none" />
            {' and upload it.'}
          </DescriptionWrapper>
          <BigidDropZone
            accept={['.csv']}
            files={fileToUpload ? [fileToUpload] : []}
            multiple={false}
            onDrop={handleOnDrop}
          />
        </div>
        {fileError && <ErrorTextWrapper>{fileError.message}</ErrorTextWrapper>}
      </ContentWrapper>
    </BigidDialog>
  );
};
