import React from 'react';
import { capitalize } from 'lodash';
import { DS_ENRICHMENT_STATUS_FIELD, DS_ENRICHMENT_SUPPORTED_STATUS } from '../constants/enrichment';
import { BigidFilter, ChartType, FunnelChartData } from '@bigid-ui/components';
import type { DsFunnelStatus, GetDsConnectionEnrichmentStatusItemResponse } from '../types';
import { FunnelTooltip } from '../components/FunnelTooltip';

const NO_DATA: Partial<GetDsConnectionEnrichmentStatusItemResponse> = {};
export interface FunnelItem {
  category: string;
  totalCount: number;
}

export type Tooltips = Pick<FunnelChartData, 'mainTooltip' | 'dropTooltip' | 'infoTooltipText'>;

export const generateTooltips = (
  funnelItems: FunnelItem[],
  currentIndex: number,
  t: (key: string, ...args: any[]) => string,
) => {
  const tooltips: Tooltips = {};
  const category = funnelItems[currentIndex].category;
  const currentItemTotalCount = funnelItems[currentIndex].totalCount;
  const prevItemTotalCount = funnelItems[currentIndex - 1]?.totalCount || currentItemTotalCount;
  tooltips.mainTooltip = (
    <FunnelTooltip
      dsAmount={currentItemTotalCount}
      dsPercentage={Math.round((currentItemTotalCount / prevItemTotalCount) * 100)}
      stepNumber={currentIndex + 1}
      category={category}
    />
  );
  if (currentIndex) {
    const dropItemsCount = prevItemTotalCount - currentItemTotalCount;
    tooltips.dropTooltip = (
      <FunnelTooltip
        isDropOff={true}
        dsAmount={dropItemsCount}
        dsPercentage={Math.round((dropItemsCount / prevItemTotalCount) * 100)}
        stepNumber={currentIndex + 1}
        category={category}
      />
    );
  }

  return tooltips;
};

export const mapDsConnectionEnrichmentStatusItemResponseToChartData = (
  groupedStatusesResponse: GetDsConnectionEnrichmentStatusItemResponse,
  t: (key: string, ...args: any[]) => string,
): FunnelChartData[] => {
  const { data } = groupedStatusesResponse ?? NO_DATA;
  const funnelItems: FunnelItem[] = Object.entries(data).map(([key, value]) => ({ category: key, ...value }));

  return funnelItems
    ?.map(({ category, totalCount }, index) => ({
      id: category,
      label: capitalize(category),
      value: totalCount,
      ...generateTooltips(funnelItems, index, t),
    }))
    .filter(({ id }) => DS_ENRICHMENT_SUPPORTED_STATUS.includes(id as unknown as DsFunnelStatus));
};

export const mapFunnelStatusToBigidFilter = (
  status: DsFunnelStatus,
  chartType: ChartType,
  item: GetDsConnectionEnrichmentStatusItemResponse,
): BigidFilter => {
  const { data } = item ?? NO_DATA;
  const isCurrentStatus = chartType === ChartType.MAIN_CHART;
  const dsConnectionEnrichmentStatus = isCurrentStatus ? data?.[status]?.currentStatus : data?.[status]?.previousStatus;

  return [{ field: DS_ENRICHMENT_STATUS_FIELD, operator: 'in', value: dsConnectionEnrichmentStatus }];
};
