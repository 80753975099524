import { v4 as uuid } from 'uuid';
import { module } from 'angular';
import './annotations.component.scss';
import template from './annotations.component.html';

import { isPermitted } from '../../../react/services/userPermissionsService';
import { BUSINESS_FLOWS_PERMISSIONS } from '@bigid/permissions';

const app = module('app');

app.component('annotations', {
  template,
  controller: [
    '$scope',
    'dataMappingService',
    'notificationService',
    'downloadFileService',
    function ($scope, dataMappingService, notificationService, downloadFileService) {
      this.permittedFileList =
        '.pdf' +
        ',.doc,.dot,.docx,.dotx,.dotm,.docm,' +
        '.txt,' +
        '.json,' +
        '.csv,' +
        '.ppt,.pot,.pps,.ppa,.pptx,.potx,.ppsx,.ppam,.pptm,.potm,.ppsm,' +
        '.xls,.xlt,.xla,.xlsx,.xltx,.xlsm,.xltm,.xlam,.xlsb,' +
        '.bmp,.cod,.gif,.ief,.jpe,.jpg,.jpeg,.jtif,.svg,.tif,.tiff,.ras,.cmx,.ico,.pnm,.pbm,.pgm,.ppm,.rgb,.xbm,.xpm,.xwd,.png';

      const setUuid = () => {
        if (this.mappingObject && this.mappingObject.annotation) {
          this.mappingObject.annotations.map(annotation => {
            annotation['uuid'] = uuid();
          });
        }
      };

      this.hasManagePermission = isPermitted(BUSINESS_FLOWS_PERMISSIONS.MANAGE.name);

      this.$onChanges = changes => {
        if (changes.mappingObject) {
          this.selectedCategory = null;
          this.selectedAnnotation = null;
          this.editorOpen = null;
          if (this.isTask) {
            this.annotationStatus = this.taskStatus;
          }
          setUuid();
        }
      };

      this.filterCategory = category => {
        if (this.mappingObject && this.mappingObject.annotations) {
          return this.mappingObject.annotations.filter(annotation => annotation.category === category).length > 0;
        }
      };

      this.editAnnotation = annotation => {
        if (this.isAnnotationStatusResolved()) {
          return;
        }
        this.selectedAnnotation = angular.copy(annotation);
        this.editorOpen = true;
      };

      this.isAnnotationStatusResolved = () => {
        if (this.annotationStatus && this.annotationStatus === 'resolved') {
          return true;
        }
        return false;
      };
      this.createAnnotation = () => {
        if (this.isAnnotationStatusResolved()) {
          return;
        }
        this.selectedAnnotation = {
          uuid: uuid(),
          attachments: [],
        };
        this.editorOpen = true;
      };

      this.deleteAnnotation = annotation => {
        if (this.isAnnotationStatusResolved()) {
          return;
        }
        this.mappingObject.annotations = this.mappingObject.annotations.filter(
          original =>
            original.category !== annotation.category ||
            original.description !== annotation.description ||
            original.title !== annotation.title,
        );
        if (this.isTask) {
          this.onSaveAnnotationChanged({ selectedEntity: this.mappingObject.annotations, vm: this.vm.businessProcess });
          notificationService.success('Annotation Was Deleted!!');
          return;
        }
        this.onSaveAnnotationChanged({ CloseSideEntityWindow: false, message: 'Annotation Was Deleted!' });
      };

      this.fileAdded = files => {
        if (!angular.isDefined(this.selectedAnnotation['attachmentsDummy'])) {
          this.selectedAnnotation['attachmentsDummy'] = [];
        }
        this.selectedAnnotation['attachmentsDummy'] = this.selectedAnnotation['attachmentsDummy'].concat(
          files.map(file => {
            file['isDummy'] = true;
            return file;
          }),
        );
      };

      this.fileRemoved = id => {
        let found = false;
        for (const i in this.selectedAnnotation['attachments']) {
          if (this.selectedAnnotation['attachments'][i]['attachmentId'] == id) {
            this.selectedAnnotation['attachments'].splice(i, 1);
            found = true;
            break;
          }
        }
        if (!found) {
          for (const i in this.selectedAnnotation['attachmentsDummy']) {
            if (this.selectedAnnotation['attachmentsDummy'][i]['attachmentId'] == id) {
              this.selectedAnnotation['attachmentsDummy'].splice(i, 1);
              break;
            }
          }
        }
      };

      const _base64ToArrayBuffer = base64 => {
        const binary_string = window.atob(base64);
        const len = binary_string.length;
        const bytes = new Uint8Array(len);
        for (let i = 0; i < len; i++) {
          bytes[i] = binary_string.charCodeAt(i);
        }
        return bytes.buffer;
      };

      this.downloadAttachment = (id, attachment) => {
        dataMappingService.downloadAttachment(id).then(
          response => {
            downloadFileService.download(
              _base64ToArrayBuffer(
                response.fileContent.replace(new RegExp('^data:' + response.fileType + '+;base64,', 'gi'), ''),
              ),
              response.fileType,
              response.filename,
            );
            attachment.downloading = false;
          },
          () => {
            attachment.downloading = false;
            this.notificationService.error('An error has occurred during downloading!');
          },
        );
      };

      this.saveSelectedAnnotation = () => {
        if (!this.mappingObject.annotations) {
          this.mappingObject.annotations = [];
        }

        if (!this.vm.businessProcess.annotation_categories) {
          this.vm.businessProcess.annotation_categories = [];
        }

        const modified = angular.copy(this.selectedAnnotation);

        if (angular.isDefined(modified['attachmentsDummy']))
          modified['attachments'] = modified['attachments'].concat(modified['attachmentsDummy']);

        if (!modified.title || !modified.category) return;

        const idx = this.mappingObject.annotations.findIndex(annotation => annotation.uuid === modified.uuid);

        this.mappingObject.annotations =
          idx !== -1
            ? [
                ...this.mappingObject.annotations.slice(0, idx),
                modified,
                ...this.mappingObject.annotations.slice(idx + 1),
              ]
            : [...this.mappingObject.annotations, modified];

        if (this.vm.businessProcess.annotation_categories.indexOf(modified.category) === -1) {
          this.vm.businessProcess.annotation_categories.push(modified.category);
        }

        this.selectedCategory = modified.category;
        this.selectedAnnotation = null;
        this.editorOpen = false;
        if (this.isTask) {
          this.onSaveAnnotationChanged({ selectedEntity: this.mappingObject.annotations, vm: this.vm.businessProcess });
          return;
        }
        this.onSaveAnnotationChanged({ CloseSideEntityWindow: false, message: 'Annotation was Inserted!' });
      };
    },
  ],
  bindings: {
    vm: '<',
    mappingObject: '<',
    isTask: '<',
    taskStatus: '<',
    onSaveAnnotationChanged: '&',
  },
});
