import React, { FC } from 'react';
import { BigidBody1 } from '@bigid-ui/components';
import {
  initialDataSourceList,
  ConnectStatus,
  normalizeDataSourceList,
} from '../../Fmsd/FmsdComponents/ConnectionsStatus/utils';
import makeStyles from '@mui/styles/makeStyles';
import { CredsStatus } from '../../Fmsd/FmsdComponents';
import { CloudProvider } from '../../AutoDiscoveryWizard/autoDiscoveryWizardServices';
import { cloudProviderLabel } from '../../Fmsd/FmsdDiscover/hooks/useAutodiscoveryApp';
import { AutoDiscoveryConnectionType } from './AutoDiscoveryConnectionType/AutoDiscoveryConnectionType';

const useStyles = makeStyles({
  connectionsWrapper: {
    paddingTop: 24,
    paddingBottom: 16,
  },
  title: {
    paddingBottom: 12,
  },
});

export interface AutoDiscoveryConnectionListProps {
  cloudProvider: CloudProvider;
  permissions: string[];
  credentialsStatus: CredsStatus;
  dataAid?: string;
  onSelect?: (id: string, checked: boolean) => void;
  selectedIds?: string[];
}

export const AutoDiscoveryConnectionList: FC<AutoDiscoveryConnectionListProps> = ({
  cloudProvider,
  permissions,
  credentialsStatus,
  dataAid,
  selectedIds,
  onSelect,
}) => {
  const { connectionsWrapper, title } = useStyles({});
  const dsList =
    credentialsStatus === CredsStatus.VALID
      ? normalizeDataSourceList(cloudProvider, permissions)
      : initialDataSourceList[cloudProvider];

  return (
    <div className={connectionsWrapper}>
      <BigidBody1 className={title}>
        Available {cloudProviderLabel[cloudProvider]} connections for this account:{' '}
      </BigidBody1>
      <div>
        {dsList.map(({ id, name, connected }) => (
          <AutoDiscoveryConnectionType
            dataAid={dataAid}
            key={id}
            id={id}
            name={name}
            connected={connected}
            onSelect={onSelect}
            disabled={connected !== ConnectStatus.CONNECTED}
            selected={selectedIds.includes(id)}
          />
        ))}
      </div>
    </div>
  );
};
