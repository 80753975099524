import React, { createContext, useContext, useState, useEffect, PropsWithChildren, useMemo, useCallback } from 'react';
import { getLegalEntity } from './LegalEntitiesService';
import { notificationService } from '../../services/notificationService';
import { LegalEntitiesInterface, LegalEntityPageTabsPath } from './LegalEntitiesTypes';
import { useLocationChange } from '../DSAR/SarProfileSettings/useLocationChange';

interface LegalEntitiesContextType {
  legalEntity: LegalEntitiesInterface;
  tab: LegalEntityPageTabsPath;
  id: string;
  update: () => void;
}

const LegalEntityContext = createContext<LegalEntitiesContextType>(undefined);

const useLegalEntityContext = () => {
  const context = useContext(LegalEntityContext);

  if (context === undefined) {
    throw new Error('useLegalEntityContext was used outside of its Provider');
  }

  return context;
};

const LegalEntityContextProvider = ({ children }: PropsWithChildren) => {
  const [entityData, setEntityData] = useState<LegalEntitiesInterface>();
  const { stateParams } = useLocationChange();

  const fetchData = useCallback(async () => {
    try {
      const entity = await getLegalEntity(stateParams.entityId);
      setEntityData(entity);
    } catch (err) {
      notificationService.error(`Failed to fetch Legal Entity`);
      console.error(`Failed to fetch Legal Entity: ${JSON.stringify(err?.response)}`);
    }
  }, [stateParams.entityId]);

  const update = useCallback(() => fetchData(), [fetchData]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const contextValue = useMemo(
    () => ({
      legalEntity: entityData,
      tab: stateParams.tab,
      id: stateParams.entityId,
      update,
    }),
    [entityData, stateParams, update],
  );

  return <LegalEntityContext.Provider value={contextValue}>{children}</LegalEntityContext.Provider>;
};

export { LegalEntityContextProvider, useLegalEntityContext };
