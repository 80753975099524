import React, { FC } from 'react';
import { styled } from '@mui/material';
import { generateDataAid } from '@bigid-ui/utils';
import { BigidNoDataIllustration } from '@bigid-ui/icons';
import { useLocalTranslation } from './translations';
import { BigidLayoutEmptyState } from '@bigid-ui/layout';

export interface GridBasedWidgetNoDataProps {
  dataAid?: string;
}

const Root = styled('div')`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
  position: absolute;
`;

export const GridBasedWidgetNoData: FC<GridBasedWidgetNoDataProps> = ({ dataAid = 'GridBasedWidgetNoData' }) => {
  const { t } = useLocalTranslation();

  return (
    <Root data-aid={dataAid}>
      <BigidLayoutEmptyState
        data-aid={generateDataAid(dataAid, ['emptyState'])}
        illustration={BigidNoDataIllustration}
        description={t('noData')}
      />
    </Root>
  );
};
