import { $uibModal } from './angularServices';

const showDialog = (modalParam?: any) =>
  $uibModal.open({
    animation: true,
    backdrop: 'static',
    windowClass: `bigid__uibmodal--centered cyberark-config__modal`,
    template: `<cyber-ark-config provider=${modalParam} on-close-click="$dismiss()" pattern="[^':]*$"/>`,
  });

export const cyberArkService = { showDialog };
