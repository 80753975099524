import React, { useMemo } from 'react';
import isEmail from 'validator/lib/isEmail';
import { BigidDropdownOption, BigidDropdownProps, BigidFieldRenderProps } from '@bigid-ui/components';
import { EmailPickerField } from '../../../../../components/EmailPickerField/EmailPickerField';
import type { ValidationRule } from '../../../../../utilities/validation';
import { useLocalTranslation } from '../../../translations';

type AutoDiscoveryCollaborationEmailFields = {
  dataAid?: string;
  data: BigidDropdownOption[];
  onChange?: (values: BigidDropdownOption[]) => void;
  onInputChange?: (value: string) => void;
} & Partial<BigidFieldRenderProps<unknown, BigidDropdownOption[]>>;

export const AutoDiscoveryCollaborationEmailFields = ({
  dataAid = 'AutoDiscoveryCollaborationEmailFields',
  ...props
}: AutoDiscoveryCollaborationEmailFields) => {
  const { t } = useLocalTranslation('AutoDiscovery');
  const rules: ValidationRule<string, BigidDropdownOption[]>[] = useMemo(
    () => [
      {
        id: 'EMAIL',
        validate: term => isEmail(term),
        message: 'invalid email',
      },
      {
        id: 'NO_DUPLICATES',
        validate(term, options) {
          return !options?.map(({ value }) => value).includes(term);
        },
        message: 'email added',
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const overrides = useMemo<Partial<BigidDropdownProps>>(
    () => ({
      dataAid,
      applyOnChange: true,
      createOptionText: t('collaborateModal.createOption'),
      entityNamePlural: t('collaborateModal.email'),
      entityNameSingular: t('collaborateModal.email'),
      noOptionsMessage: t('collaborateModal.noOptions'),
      noSearchResultsMessage: t('collaborateModal.noSearchResults'),
      placeholder: t('collaborateModal.emailPlaceholder'),
      displayLimit: 20,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  return <EmailPickerField dataAid={dataAid} rules={rules} field={overrides} {...props} />;
};
