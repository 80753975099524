import React, { FC, ReactNode, useCallback, useMemo } from 'react';
import styled from '@emotion/styled';
import { DateISO8601 } from '../../../../types/types';
import { RequestStatus, SimilarTablesRequest } from '../services/dataCatalogSimilarTablesService';
import { generateDataAid } from '@bigid-ui/utils';
import {
  BigidBody1,
  BigidColorsV2,
  BigidEditableTagsArea,
  BigidTooltip,
  PrimaryButton,
  TertiaryButton,
} from '@bigid-ui/components';
import { BigidSyncIcon } from '@bigid-ui/icons';
import { getDateFormatted, getPercentageValue, getIsRequestDetailsAvailableByStatus } from '../utilities/utils';
import { useLocalTranslation } from '../translations';

const Wrapper = styled('div')({
  display: 'flex',
  height: '32px',
  justifyContent: 'space-between',
  alignItems: 'center',
  alignSelf: 'stretch',
});

const ContentContainer = styled('div')({
  display: 'flex',
  alignItems: 'center',
  gap: '24px',
});

const ActionsContainer = styled('div')({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  gap: '8px',
  flex: '1 0 0',
});
const StatusIndicatorWrapper = styled('div')({
  display: 'flex',
  alignItems: 'center',
  gap: '4px',
});

const StatusIndicator = styled('div')<{ bgColor: string }>(({ bgColor }) => ({
  borderRadius: '50%',
  width: '8px',
  height: '8px',
  backgroundColor: bgColor,
}));

const ThresholdWrapper = styled('div')({
  display: 'flex',
  alignItems: 'center',
  paddingRight: '10px',
});

const TagsWrapper = styled('div')({
  display: 'flex',
  alignItems: 'center',
  whiteSpace: 'nowrap',
  gap: '8px',
});

export interface RequestHeaderProps {
  requestDetails: SimilarTablesRequest;
  handleOpenSimilarityThresholdDialog: () => void;
  handleClickRecalculate: (fullyQualifiedName: string, threshold: number) => void;
  dataAid?: string;
}

export const RequestContent: FC<RequestHeaderProps> = ({
  requestDetails,
  handleOpenSimilarityThresholdDialog,
  handleClickRecalculate,
  dataAid = 'similarTables',
}) => {
  const { t } = useLocalTranslation();

  const getStatusIndicator = useCallback((status: RequestStatus, lastUpdate: DateISO8601): ReactNode => {
    let component;
    let tooltip;

    switch (status) {
      case RequestStatus.COMPLETED:
        component = <StatusIndicator bgColor={BigidColorsV2.green[600]} />;
        tooltip = t('request.status.tooltip.completed');
        break;
      case RequestStatus.FAILED:
        component = <StatusIndicator bgColor={BigidColorsV2.red[600]} />;
        tooltip = t('request.status.tooltip.failed');
        break;
    }

    return (
      <StatusIndicatorWrapper>
        <BigidTooltip title={tooltip} isDisabled={!tooltip} placement={'bottom-start'}>
          {component}
        </BigidTooltip>
        <BigidBody1> {`${t('request.lastUpdate')} ${getDateFormatted(lastUpdate)}`}</BigidBody1>
      </StatusIndicatorWrapper>
    );
  }, []);

  const { isReady, assignedTags } = useMemo(
    () => ({
      isReady: getIsRequestDetailsAvailableByStatus(requestDetails?.status),
      assignedTags: requestDetails?.tags
        ? requestDetails.tags.map(tag => ({
            name: tag.tagName,
            value: tag.tagValue,
          }))
        : [],
    }),
    [requestDetails],
  );

  return (
    <Wrapper data-aid={`${generateDataAid(dataAid, ['request', 'content'])}`}>
      <ThresholdWrapper>
        {requestDetails?.threshold && (
          <BigidBody1 fontWeight="700">{`${t('request.similarity')} ${getPercentageValue(
            requestDetails?.threshold,
          )}`}</BigidBody1>
        )}
      </ThresholdWrapper>
      {isReady && (
        <ContentContainer>
          {getStatusIndicator(requestDetails.status, requestDetails.updatedAt)}
          {assignedTags?.length > 0 && (
            <TagsWrapper>
              <BigidBody1>{t('request.assignedTags')}</BigidBody1>
              <BigidEditableTagsArea
                value={assignedTags}
                isDisabled={true}
                isAutoFit={false}
                isEditMode={false}
                entityMaxWidth={300}
                showPlaceholderOnlyOnHover
              />
            </TagsWrapper>
          )}
        </ContentContainer>
      )}
      <ActionsContainer>
        {isReady && !requestDetails.isAssignTag && (
          <TertiaryButton
            onClick={() => {
              return handleClickRecalculate(requestDetails?.fullyQualifiedName, requestDetails?.threshold);
            }}
            size="medium"
            startIcon={<BigidSyncIcon />}
            text={t('request.actions.recalculate')}
            dataAid={`${generateDataAid(dataAid, ['request', 'actions', 'recalculate'])}`}
          />
        )}
        <PrimaryButton
          onClick={() => {
            return handleOpenSimilarityThresholdDialog();
          }}
          size="medium"
          text={t('request.actions.setThreshold')}
          dataAid={`${generateDataAid(dataAid, ['request', 'actions', 'setThreshold'])}`}
        />
      </ActionsContainer>
    </Wrapper>
  );
};
