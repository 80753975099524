import React, { FC } from 'react';
import { styled } from '@mui/material';
import { generateDataAid } from '@bigid-ui/utils';
import { DataSourceIconByDsType } from '@bigid-ui/icons';
import { getApplicationPreference } from '../../../../services/appPreferencesService';
import { getSupportedDataSources } from '../../../../utilities/dataSourcesUtils';
import { $state } from '../../../../services/angularServices';
import { notificationService } from '../../../../services/notificationService';
import { isNewConnectivityExperienceEnabled } from '../../../DataSources/DataSourceConfiguration/utils/isNewConnectivityExperienceEnabled';
import { DataSourceModel } from '../../../DataSources/DataSourceConnections/DataSourceConnectionTypes';
import { GridLinkDsar } from './GridLinkDsar';

interface DataSourceLayoutNameCellDsarProps {
  dataAid?: string;
  row: DataSourceModel;
  iconWidth?: number;
}

const CellContent = styled('div')`
  width: 100%;
  height: 100%;
  display: flex;
  gap: 8px;
  align-items: center;
`;

const DataSourceName = styled(GridLinkDsar)`
  flex: 1;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const DataSourceLayoutNameCellDsar: FC<DataSourceLayoutNameCellDsarProps> = ({
  dataAid = 'DataSourceLayoutNameCell',
  row,
  iconWidth = 32,
}) => {
  const { id, type, archived } = row;

  const onDataSourceNameClick = async (event: React.MouseEvent<Element>) => {
    event?.stopPropagation();

    if (archived) {
      notificationService.warning('Data source cannot be edited because it is archived');
      return {};
    }

    const supportedDataSources = await getSupportedDataSources();
    const isNewDsConfigPage =
      getApplicationPreference('DS_TYPES_TEMPLATES_SUPPORTED') &&
      supportedDataSources.some(
        ({ displayName, templateName, type }) => (row.displayType === displayName || type === row.type) && templateName,
      );

    const { displayName } = supportedDataSources.find(({ type }) => type === row.type) ?? {};

    const params =
      isNewConnectivityExperienceEnabled(type) && isNewDsConfigPage
        ? { id, shouldOpenNewModal: !row?.connectionStatusTest?.is_success, dsLabel: displayName }
        : { id, dsLabel: displayName };

    $state.go(isNewDsConfigPage ? 'configDataSource' : `dataSourceConnection`, params);
    return {};
  };

  return (
    <CellContent>
      <DataSourceIconByDsType
        data-aid={generateDataAid(dataAid, ['dsIcon', type])}
        dataSourceType={type}
        width={iconWidth}
      />
      <DataSourceName text={id as string} onClick={onDataSourceNameClick} />
    </CellContent>
  );
};
