import React, { useState, useMemo, useEffect, ReactNode, useCallback } from 'react';
import {
  BigidGridColumn,
  BigidGridQueryComponents,
  BigidGridRow,
  BigidGridWithToolbarProps,
  FetchDataFunction,
  BigidGridUpdateRowByIdPayload,
} from '@bigid-ui/grid';
import {
  BigidColorsV2,
  BigidFilter,
  EntityEvents,
  entityEventsEmitter,
  ToolbarAction,
  ToolbarActionType,
  BigidFilterType,
  BigidTabsItem,
  ActionData,
  EntityEventPayload,
} from '@bigid-ui/components';
import { CATALOG_PERMISSIONS, REVIEW_FINDINGS_PERMISSIONS } from '@bigid/permissions';
import { BigidLayoutConfig, LayoutContentType, parseFieldFiltersToSearchQuery } from '@bigid-ui/layout';
import {
  BigidDislikeIcon,
  BigidLikeIcon,
  BigidSearchQueryIcon,
  BigidDislikeFilledIcon,
  BigidLikeFilledIcon,
  BigidNoteIcon,
} from '@bigid-ui/icons';
import {
  CuratedAttribute,
  CuratedField,
  CuratedFieldStatus,
  ConfidenceLevel,
  curateField,
  CurateFieldPayload,
  getCuratedFields,
  getSystemUsers,
  DetailedObjectType,
  structuredObjectsTypes,
  curateFieldsAsync,
  getObjectsFields,
  CurationFieldsAsyncFilters,
  StatusEvent,
  AddNoteModalType,
  Note,
} from '../curationService';
import { CuratedFieldsProps } from './CuratedFields';
import { queryService } from '../../../services/queryService';
import { notificationService } from '../../../services/notificationService';
import { makeStyles } from '@mui/styles';
import {
  getCuratedFieldMasterDetailsIcon,
  getCuratedFieldDisplayName,
  getStatusDisplayName,
  getAdditionalAttributesDialogProps,
  curationStatusEventsEmitter,
  CurationStatusEvents,
  isNoteEnabledForReject,
} from '../curationUtils';
import { AttributePreview as AttributePreviewStructured } from './details/structured/AttributePreview/AttributePreview';
import { AttributePreview as AttributePreviewUnstructured } from './details/unstructured/AttributePreview/AttributePreview';
import { ColumnProfileComponent } from './details/structured/ColumnProfile/ColumnProfile';
import { omit, capitalize, partition, includes, isEmpty } from 'lodash';
import { AdditionalAttributes } from './details/common/AdditionalAttributes/AdditionalAttributes';
import { useLocalTranslation } from '../translations';
import { isPermitted } from '../../../services/userPermissionsService';
import { v4 as uuid } from 'uuid';
import { getApplicationPreference } from '../../../services/appPreferencesService';
import { CurationEvents, trackEventCurationView } from '../curationEventTrackerUtils';
import { openModifyAdditionalAttributesDialog } from './details/common/AdditionalAttributes/additionalAttributeService';
import {
  getAdditionalStaticRequestFilterByVariant,
  getColumnsConfigByVariant,
  getSearchFilterOriginalNames,
} from './curatedFieldsUtils';
import { addNoteDialog } from './details/common/AddNoteDialog/AddNoteDialog';
import { sessionStorageService } from '../../../../common/services/sessionStorageService';

export type CuratedFieldRecord = CuratedField &
  BigidGridRow & {
    displayFieldName: string;
    icon?: ReactNode;
  };

export type CuratedAttributeRecord = CuratedAttribute &
  BigidGridRow & {
    displayFieldName: string;
    icon?: ReactNode;
  };

export type CuratedAttributeRowUpdatePayload = BigidGridUpdateRowByIdPayload<CuratedAttributeRecord>;

export type UseCuratedFieldsConfigState = {
  isReady: boolean;
  layoutConfig: BigidLayoutConfig;
};

export type UseCuratedAttributesConfigProps = CuratedFieldsProps;

type CuratedFieldRowUpdatePayload = BigidGridUpdateRowByIdPayload<CuratedFieldRecord>;

export type AdditionalAttributesObjectIdentifier = {
  fieldName: string;
  fullyQualifiedName: string;
};

export type AdditionalAttributesDialogConfig = {
  objectsSelected: AdditionalAttributesObjectIdentifier[];
  gridId: string;
  dataAid: string;
  orderAfterSave: boolean;
  isBulkMode?: boolean;
};

/**
 As we have in this page 'field name' and 'object name' filters AND search bar that is searching for them, we give this 2 fields in the search bar
 different names ('fieldNameFromSearchBar', 'objectNameFromSearchBar') so we can distinguish later who came from the filter and who came from the search bar
 And before sending the request we change to the original names.
 */

const searchFilterKeys = ['fieldNameFromSearchBar', 'objectNameFromSearchBar'];

const LIMIT_FOR_SYNC_OPERATION = 100;
const PAGE_SIZE = 15;

export const useStyles = makeStyles({
  contentContainer: {
    display: 'flex',
    overflow: 'hidden',
    flex: '1 1 auto',
    flexDirection: 'column',
  },
  wrapperContainer: {
    display: 'flex',
    overflow: 'hidden',
    flex: '1 1 auto',
    border: `1px solid ${BigidColorsV2.purple[200]}`,
    borderRadius: '8px',
  },
});

export const useCuratedFieldsConfig = ({
  currentCuratedAttribute,
  currentCuratedDataSource,
  onFieldReviewed,
  variant,
  structuredOnly,
}: UseCuratedAttributesConfigProps): UseCuratedFieldsConfigState => {
  const classes = useStyles();
  const { t } = useLocalTranslation('CuratedFields.CurationFieldsConfig');
  const [isReady, setIsReady] = useState<boolean>(false);
  const [allFields, setAllFields] = useState<CuratedFieldRecord[]>([]);
  const [filterToolbarConfig, setFilterToolbarConfig] =
    useState<BigidGridWithToolbarProps<CuratedFieldRecord>['filterToolbarConfig']>();
  const currentUser = sessionStorageService.get('userName') as string;

  const fetchToolbarFilterConfig = useCallback(async () => {
    const requestFilter: BigidFilter = [
      {
        field: 'attributeName',
        value: currentCuratedAttribute.attributeName,
        operator: 'in',
      },
      {
        field: 'attributeType',
        value: currentCuratedAttribute.attributeType,
        operator: 'in',
      },
      ...getAdditionalStaticRequestFilterByVariant(variant, currentCuratedDataSource?.source, structuredOnly),
    ];

    const containers = await getObjectsFields(requestFilter, CurationFieldsAsyncFilters.CONTAINER);
    const objectNames = await getObjectsFields(requestFilter, CurationFieldsAsyncFilters.OBJECT_NAME);
    const fieldNames = await getObjectsFields(requestFilter, CurationFieldsAsyncFilters.FIELD_NAME);

    const fieldNameFilter = {
      title: t('filters.fieldName'),
      field: 'fieldName',
      operator: 'in',
      value: [],
      disabled: true,
      isSearchAsync: true,
      options: fieldNames,
      loadSearchOptions: async (inputValue?: string) => {
        return await getObjectsFields(requestFilter, CurationFieldsAsyncFilters.FIELD_NAME, inputValue);
      },
    } as BigidFilterType;

    const objectNameFilter = {
      title: t('filters.objectName'),
      field: 'objectName',
      operator: 'in',
      value: [],
      disabled: true,
      isSearchAsync: true,
      options: objectNames,
      loadSearchOptions: async (inputValue?: string) => {
        return await getObjectsFields(requestFilter, CurationFieldsAsyncFilters.OBJECT_NAME, inputValue);
      },
    } as BigidFilterType;

    const containerNameFilter = {
      title: t('filters.containerName'),
      field: 'container',
      operator: 'in',
      value: [],
      disabled: true,
      isSearchAsync: true,
      options: containers,
      loadSearchOptions: async (inputValue?: string) => {
        return await getObjectsFields(requestFilter, CurationFieldsAsyncFilters.CONTAINER, inputValue);
      },
    } as BigidFilterType;

    const filterToolbarConfig: BigidGridWithToolbarProps<CuratedFieldRecord>['filterToolbarConfig'] = {
      searchConfig: {
        searchFilterKeys,
        operator: 'equal',
      },
      filters: [
        fieldNameFilter,
        objectNameFilter,
        containerNameFilter,
        {
          title: t('filters.reviewStatus'),
          field: 'reviewStatus',
          operator: 'in',
          options: Object.values(CuratedFieldStatus).map(status => {
            const statusDisplayName = getStatusDisplayName(status);

            return {
              label: capitalize(statusDisplayName),
              value: status,
              isSelected: false,
            };
          }),
          value: [],
          disabled: true,
        },
      ],
    };

    filterToolbarConfig.filters.push(
      {
        title: t('filters.confidenceLevel'),
        field: 'confidenceLevel',
        operator: 'in',
        options: Object.values(ConfidenceLevel).map(level => ({
          label: capitalize(level),
          value: level,
          isSelected: false,
        })),
        value: [],
        disabled: true,
      } as BigidFilterType,
      {
        title: t('filters.updatedConfidenceLevel'),
        field: 'updatedConfidenceLevel',
        operator: 'in',
        options: Object.values(ConfidenceLevel).map(level => ({
          label: capitalize(level),
          value: level,
          isSelected: false,
        })),
        value: [],
        disabled: true,
      } as BigidFilterType,
    );

    try {
      const systemUsersOptions = await getSystemUsers();

      filterToolbarConfig.filters.push({
        title: t('filters.curatedByUser'),
        field: 'curatedByUser',
        operator: 'in',
        value: [],
        disabled: true,
        isSearchAsync: true,
        options: systemUsersOptions,
        loadSearchOptions: getSystemUsers,
      } as BigidFilterType);

      setFilterToolbarConfig(filterToolbarConfig);
      setIsReady(true);
    } catch ({ message }) {
      console.error(`An error has occurred: ${message}`);
      notificationService.error(t('errors.buildingTheFilters'));
    }
  }, [
    currentCuratedAttribute.attributeName,
    currentCuratedAttribute.attributeType,
    variant,
    currentCuratedDataSource?.source,
    structuredOnly,
    t,
  ]);

  const defaultFilter: BigidFilter = [
    {
      field: 'attributeName',
      value: encodeURIComponent(currentCuratedAttribute.attributeName),
      operator: 'in',
    },
    {
      field: 'attributeType',
      value: currentCuratedAttribute.attributeType,
      operator: 'in',
    },
  ];

  const getMappedFields = (fields: CuratedField[] = []) => {
    return fields?.map(curatedField => {
      const displayFieldName = getCuratedFieldDisplayName(curatedField);

      return {
        ...curatedField,
        displayFieldName,
        icon: getCuratedFieldMasterDetailsIcon(curatedField.detailedObjectType),
      };
    });
  };

  const getAllFields = async () => {
    const filterQuery = parseFieldFiltersToSearchQuery(
      defaultFilter,
      Boolean(getApplicationPreference('NEW_QUERY_FILTER_ENABLED')),
    );

    const { data } = await getCuratedFields({
      query: `filter=${filterQuery}`,
    });

    setAllFields(getMappedFields(data?.fields));
  };

  useEffect(() => {
    getAllFields();
  }, []);

  const { fetchGridData, columns, toolbarActions, actions, gridId } = useMemo(() => {
    const gridId = `CuratedFields-${uuid()}`;
    let filterQueryExtended: string;
    let totalFieldsCount = 0;

    const fetchGridData: FetchDataFunction<CuratedFieldRecord> = async (queryComponents: BigidGridQueryComponents) => {
      try {
        const [searchQueryFilter, queryComponentFilter] = partition(queryComponents.filter, ({ field }) =>
          includes(searchFilterKeys, field),
        );

        const filter: BigidFilter = [
          ...defaultFilter,
          ...queryComponentFilter,
          ...getAdditionalStaticRequestFilterByVariant(variant, currentCuratedDataSource?.source, structuredOnly),
        ];

        const updatedSearchQuery = getSearchFilterOriginalNames(searchQueryFilter, searchFilterKeys);

        const filterQuery = parseFieldFiltersToSearchQuery(
          filter,
          Boolean(getApplicationPreference('NEW_QUERY_FILTER_ENABLED')),
        );
        filterQueryExtended = isEmpty(updatedSearchQuery)
          ? filterQuery
          : `${filterQuery} AND (${updatedSearchQuery
              .map(({ field, value }) => `${field} = "${value}"`)
              .join(' OR ')})`;

        const { data } = await getCuratedFields({
          query: `${queryService.getGridConfigQuery(omit(queryComponents, ['filter']))}&filter=${filterQueryExtended}`,
        });

        const { fields, totalCount } = data;

        const statusEvent: StatusEvent = {
          id: 'fieldsStatus',
          filter: filterQueryExtended,
        };

        curationStatusEventsEmitter.emit(CurationStatusEvents.UPDATE_STATUS, statusEvent);
        totalFieldsCount = totalCount;

        return {
          totalCount,
          data: getMappedFields(fields),
        };
      } catch ({ message }) {
        notificationService.error(t('errors.fetchingCuratedFields'));
        console.error(`An error has occurred: ${message}`);

        return {
          totalCount: 0,
          data: [],
        };
      }
    };

    const shouldShowSourceColumn = !Boolean(currentCuratedDataSource?.source);
    const columns: BigidGridColumn<CuratedFieldRecord>[] = getColumnsConfigByVariant(variant, shouldShowSourceColumn);

    const toolbarHasAccessibleActions = isPermitted(REVIEW_FINDINGS_PERMISSIONS.REVIEW.name);

    const handleBulkAddNote = async (selectedRowIds: string[] = [], allSelected?: boolean) => {
      try {
        const addNoteProps = {
          ids: selectedRowIds,
          note: '',
          modalType: AddNoteModalType.BULK,
          ...(allSelected ? { filter: decodeURIComponent(filterQueryExtended) } : {}),
          totalFieldsCount: allSelected ? totalFieldsCount : selectedRowIds.length,
        };

        const result = await addNoteDialog(addNoteProps);

        if (result?.isNoteSaved) {
          return {
            shouldGridReload: true,
            shouldClearSelection: true,
          };
        }
      } catch ({ message }) {
        notificationService.error(t('errors.genericError'));
        console.error(`${t('errors.genericError')}: ${message}`);
      }

      return {
        shouldGridReload: false,
        shouldClearSelection: false,
      };
    };

    const handleAddOrEditNote = async (
      selectedRow: CuratedFieldRecord,
      isRejectBtnPressed?: boolean,
      isCalledFromFieldDetails?: boolean,
    ) => {
      const { id, fieldName, reviewStatus, displayFieldName, note } = selectedRow;
      const isForceNoteRejectedEnabled = isNoteEnabledForReject();
      const isRejectType = isRejectBtnPressed
        ? isRejectBtnPressed
        : isForceNoteRejectedEnabled && reviewStatus === CuratedFieldStatus.REJECTED && note?.text?.length;
      const editOrAddType = note?.text ? AddNoteModalType.EDIT : AddNoteModalType.ADD;

      const addNoteProps = {
        ids: [id] as string[],
        fieldName: fieldName || displayFieldName,
        note: note?.text,
        modalType: isRejectType ? AddNoteModalType.REJECT : editOrAddType,
      };

      const result = await addNoteDialog(addNoteProps);
      if (result?.isNoteSaved) {
        if (isCalledFromFieldDetails) {
          // we need to do this when we add note or reject with note on fields details page
          const eventPayload: EntityEventPayload<CuratedFieldRowUpdatePayload> = {
            entityId: gridId,
            payload: {
              rowId: id,
              row: {
                note: {
                  text: result?.note,
                } as Note,
              },
            },
          };
          entityEventsEmitter.emit(EntityEvents.UPDATE_BY_ID, eventPayload);
        }

        !isRejectBtnPressed && entityEventsEmitter.emit(EntityEvents.RELOAD);
        return {
          shouldGridReload: true,
        };
      }

      return {
        shouldGridReload: false,
      };
    };

    const toolbarActionApproveProps: ToolbarAction = {
      label: t('approve'),
      execute: async ({ selectedRows }) => {
        trackEventCurationView(CurationEvents.CURATION_FIELDS_INLINE_ACTION_APPROVE);
        const { id, fieldName, reviewStatus } = selectedRows[0] || ({} as CuratedFieldRecord);

        try {
          const payload: CurateFieldPayload = {
            ids: [id],
            reviewStatus: CuratedFieldStatus.APPROVED,
          };

          const {
            data: { changedNumber },
          } = await curateField(payload);

          if (changedNumber === 1 && reviewStatus === CuratedFieldStatus.UNCURATED) {
            onFieldReviewed?.();
          }

          entityEventsEmitter.emit(EntityEvents.RELOAD);

          return {
            shouldGridReload: false,
            shouldClearSelection: false,
          };
        } catch ({ message }) {
          const notificationMessage = t('errors.fieldApprovalFailed', { fieldName });

          console.error(`${notificationMessage}: ${message}`);
          notificationService.error(`${notificationMessage}.`);

          return {
            shouldGridReload: false,
            shouldClearSelection: false,
          };
        }
      },
      disable: () => {
        return false;
      },
      isInline: true,
      hideActionInToolBar: true,
    };

    const toolbarActionRejectProps: ToolbarAction = {
      label: t('reject'),
      execute: async ({ selectedRows }) => {
        trackEventCurationView(CurationEvents.CURATION_FIELDS_INLINE_ACTION_REJECT);
        const { id, fieldName, reviewStatus, note } = selectedRows[0] || ({} as CuratedFieldRecord);
        const isForceNoteRejectedEnabled = isNoteEnabledForReject();

        if (isForceNoteRejectedEnabled) {
          await handleAddOrEditNote(selectedRows[0], true);
        }

        try {
          const payload: CurateFieldPayload = {
            ids: [id],
            reviewStatus: CuratedFieldStatus.REJECTED,
          };
          const {
            data: { changedNumber },
          } = await curateField(payload);

          if (changedNumber === 1 && reviewStatus === CuratedFieldStatus.UNCURATED) {
            onFieldReviewed?.();
          }

          entityEventsEmitter.emit(EntityEvents.RELOAD);

          return {
            shouldGridReload: true,
            shouldClearSelection: true,
          };
        } catch ({ message }) {
          const notificationMessage = t('errors.fieldRejectionFailed', { fieldName });

          console.error(`${notificationMessage}: ${message}`);
          notificationService.error(`${notificationMessage}.`);

          return {
            shouldGridReload: false,
            shouldClearSelection: false,
          };
        }
      },
      disable: () => {
        return false;
      },
      isInline: true,
      hideActionInToolBar: true,
    };

    const toolbarActions: ToolbarAction[] = [
      {
        label: t('actions'),
        isGlobal: false,
        type: ToolbarActionType.DROPDOWN,
        dropdownProps: {
          placeholder: t('actions'),
          options: [
            {
              label: t('approve'),
              value: 'approve',
              execute: async ({ allSelected, selectedRowIds }) => {
                trackEventCurationView(CurationEvents.CURATION_FIELDS_BULK_ACTION_APPROVE);
                try {
                  let payload: CurateFieldPayload;

                  if (allSelected || (!allSelected && selectedRowIds.length > LIMIT_FOR_SYNC_OPERATION)) {
                    payload = {
                      filter: decodeURIComponent(filterQueryExtended),
                      reviewStatus: CuratedFieldStatus.APPROVED,
                    };

                    notificationService.success(t('bulkApprove'));
                    await curateFieldsAsync(payload);
                  } else {
                    payload = {
                      ids: selectedRowIds,
                      reviewStatus: CuratedFieldStatus.APPROVED,
                    };

                    notificationService.success(t('bulkApprove'));
                    await curateField(payload);
                  }

                  onFieldReviewed?.(true);
                  notificationService.success(t('bulkApproveCompleted'));

                  return {
                    shouldGridReload: true,
                    shouldClearSelection: true,
                  };
                } catch ({ message }) {
                  const notificationMessage = t('errors.approvingSelectedFields');

                  console.error(`${notificationMessage}: ${message}`);
                  notificationService.error(`${notificationMessage}.`);

                  return {
                    shouldGridReload: false,
                    shouldClearSelection: false,
                  };
                }
              },
              show: () => {
                return isPermitted(REVIEW_FINDINGS_PERMISSIONS.REVIEW.name);
              },
            },
            {
              label: t('reject'),
              value: 'reject',
              execute: async ({ allSelected, selectedRowIds }) => {
                trackEventCurationView(CurationEvents.CURATION_FIELDS_BULK_ACTION_REJECT);
                const isForceNoteRejectedEnabled = isNoteEnabledForReject();
                const filter = decodeURIComponent(filterQueryExtended);

                try {
                  let payload: CurateFieldPayload;

                  if (isForceNoteRejectedEnabled) {
                    const addNoteProps = {
                      ids: selectedRowIds as string[],
                      note: '',
                      modalType: AddNoteModalType.BULK,
                      ...(allSelected ? { filter } : {}),
                      totalFieldsCount: allSelected ? totalFieldsCount : selectedRowIds.length,
                    };

                    await addNoteDialog(addNoteProps);
                  }

                  if (allSelected || (!allSelected && selectedRowIds.length > LIMIT_FOR_SYNC_OPERATION)) {
                    payload = {
                      filter,
                      reviewStatus: CuratedFieldStatus.REJECTED,
                    };

                    notificationService.success(t('bulkReject'));
                    await curateFieldsAsync(payload);
                  } else {
                    payload = {
                      ids: selectedRowIds,
                      reviewStatus: CuratedFieldStatus.REJECTED,
                    };

                    notificationService.success(t('bulkReject'));
                    await curateField(payload);
                  }

                  onFieldReviewed?.(true);
                  notificationService.success(t('bulkRejectCompleted'));

                  return {
                    shouldGridReload: true,
                    shouldClearSelection: true,
                  };
                } catch ({ message }) {
                  const notificationMessage = t('errors.rejectingSelectedField');

                  console.error(`${notificationMessage}: ${message}`);
                  notificationService.error(`${notificationMessage}.`);

                  return {
                    shouldGridReload: false,
                    shouldClearSelection: false,
                  };
                }
              },
              show: () => {
                return isPermitted(REVIEW_FINDINGS_PERMISSIONS.REVIEW.name);
              },
            },
            {
              label: t('addAttributes'),
              value: 'addAttributes',
              execute: async ({ selectedRowIds }) => {
                trackEventCurationView(CurationEvents.CURATION_FIELDS_ADDTIONAL_ATTRIBUTES_MODIFY_ATRRIBUTE_VIEW_BULK);

                const selectedRows = allFields?.filter(field => selectedRowIds.includes(field.id));
                try {
                  const objectsSelected: AdditionalAttributesObjectIdentifier[] = selectedRows.map(
                    ({ displayFieldName, fullyQualifiedName }) => ({
                      fieldName: displayFieldName,
                      fullyQualifiedName,
                    }),
                  );

                  const additionalAttributesDialogConfigSetup: AdditionalAttributesDialogConfig = {
                    objectsSelected,
                    gridId,
                    dataAid: 'ModifyAttributesBulk',
                    orderAfterSave: false,
                    isBulkMode: true,
                  };

                  openModifyAdditionalAttributesDialog(
                    getAdditionalAttributesDialogProps(additionalAttributesDialogConfigSetup),
                  );
                } catch ({ message }) {
                  notificationService.error(t('errors.modifyingAttributesError'));
                  console.error(`${t('errors.genericError')}: ${message}`);
                }
                return {
                  shouldGridReload: false,
                  shouldClearSelection: false,
                };
              },
              isInline: true,
              show: () => {
                return isPermitted(CATALOG_PERMISSIONS.EDIT_MANUAL_FIELDS.name);
              },
            },
            {
              label: t('addNote'),
              value: 'addNote',
              execute: async ({ selectedRowIds, allSelected }) =>
                handleBulkAddNote(selectedRowIds as string[], allSelected),
              isInline: true,
              show: () => isPermitted(CATALOG_PERMISSIONS.EDIT_MANUAL_FIELDS.name),
            },
          ],
          clearOnSelect: true,
        },
        disable: () => {
          return false;
        },
        show: ({ selectedRowIds }) => {
          return selectedRowIds.length > 0 && toolbarHasAccessibleActions;
        },
      },
      {
        ...toolbarActionApproveProps,
        show: ({ selectedRows }) =>
          !(selectedRows[0].reviewStatus === CuratedFieldStatus.APPROVED) &&
          isPermitted(REVIEW_FINDINGS_PERMISSIONS.REVIEW.name),
        icon: BigidLikeIcon,
      },
      {
        ...toolbarActionApproveProps,
        show: ({ selectedRows }) =>
          selectedRows[0].reviewStatus === CuratedFieldStatus.APPROVED &&
          isPermitted(REVIEW_FINDINGS_PERMISSIONS.REVIEW.name),
        icon: () => {
          return <BigidLikeFilledIcon color="positive" />;
        },
      },
      {
        ...toolbarActionRejectProps,
        show: ({ selectedRows }) =>
          !(selectedRows[0].reviewStatus === CuratedFieldStatus.REJECTED) &&
          isPermitted(REVIEW_FINDINGS_PERMISSIONS.REVIEW.name),
        icon: BigidDislikeIcon,
      },
      {
        ...toolbarActionRejectProps,
        show: ({ selectedRows }) =>
          selectedRows[0].reviewStatus === CuratedFieldStatus.REJECTED &&
          isPermitted(REVIEW_FINDINGS_PERMISSIONS.REVIEW.name),
        icon: () => {
          return <BigidDislikeFilledIcon color="negative" />;
        },
      },
      {
        label: t('addNote'),
        execute: ({ selectedRows }) => handleAddOrEditNote(selectedRows[0]),
        disable: () => false,
        isInline: true,
        hideActionInToolBar: true,
        icon: BigidNoteIcon,
        show: () => isPermitted(REVIEW_FINDINGS_PERMISSIONS.REVIEW.name),
      },
      {
        label: t('modifyAttributes'),
        execute: async ({ selectedRows }) => {
          const objectsSelected: AdditionalAttributesObjectIdentifier[] = [
            {
              fieldName: selectedRows[0].fieldName,
              fullyQualifiedName: selectedRows[0].fullyQualifiedName,
            },
          ];

          const additionalAttributesDialogConfigSetup: AdditionalAttributesDialogConfig = {
            objectsSelected,
            gridId,
            dataAid: 'ModifyAttributes',
            orderAfterSave: false,
          };

          trackEventCurationView(CurationEvents.CURATION_FIELDS_ADDTIONAL_ATTRIBUTES_MODIFY_ATRRIBUTE_VIEW);

          try {
            openModifyAdditionalAttributesDialog(
              getAdditionalAttributesDialogProps(additionalAttributesDialogConfigSetup),
            );
          } catch ({ message }) {
            notificationService.error(t('errors.modifyingAttributesError'));
            console.error(`${t('errors.genericError')}: ${message}`);
          }
          return {
            shouldGridReload: false,
          };
        },

        disable: () => {
          return false;
        },
        isInline: true,
        hideActionInToolBar: true,
        icon: BigidSearchQueryIcon,
        show: () => {
          return isPermitted(CATALOG_PERMISSIONS.EDIT_MANUAL_FIELDS.name);
        },
      },
    ];

    const approveObjectCommonProps: ToolbarAction = {
      label: t('approveObject'),
      type: ToolbarActionType.TERTIARY,
      isGlobal: true,
      execute: async ({ selectedItem }) => {
        trackEventCurationView(CurationEvents.CURATION_FIELDS_ATTRIBUTE_PREVIEW_APRROVE_ACTION);
        const { id, fieldName, reviewStatus } = selectedItem || ({} as CuratedFieldRecord);

        try {
          const payload: CurateFieldPayload = {
            ids: [id],
            reviewStatus: CuratedFieldStatus.APPROVED,
          };
          const {
            data: { changedNumber },
          } = await curateField(payload);

          if (changedNumber === 1 && reviewStatus === CuratedFieldStatus.UNCURATED) {
            onFieldReviewed?.();
          }

          const eventPayload: EntityEventPayload<CuratedFieldRowUpdatePayload> = {
            entityId: gridId,
            payload: {
              rowId: id,
              row: {
                reviewStatus: CuratedFieldStatus.APPROVED,
                updatedConfidenceLevel: ConfidenceLevel.HIGH,
                curatedByUser: currentUser,
              },
            },
          };
          entityEventsEmitter.emit(EntityEvents.UPDATE_BY_ID, eventPayload);
          entityEventsEmitter.emit(EntityEvents.RELOAD);

          return {
            shouldGridReload: false,
            shouldClearSelection: false,
          };
        } catch ({ message }) {
          const notificationMessage = t('errors.fieldApprovalFailed', { fieldName });

          console.error(`${notificationMessage}: ${message}`);
          notificationService.error(`${notificationMessage}.`);

          return {
            shouldGridReload: false,
            shouldClearSelection: false,
          };
        }
      },
    };

    const rejectObjectCommonProps: ToolbarAction = {
      label: t('rejectObject'),
      type: ToolbarActionType.TERTIARY,
      isGlobal: true,
      execute: async ({ selectedItem }) => {
        trackEventCurationView(CurationEvents.CURATION_FIELDS_ATTRIBUTE_PREVIEW_REJECT_ACTION);
        const { id, fieldName, reviewStatus } = selectedItem || ({} as CuratedFieldRecord);
        const isForceNoteRejectedEnabled = isNoteEnabledForReject();

        if (isForceNoteRejectedEnabled) {
          await handleAddOrEditNote(selectedItem as unknown as CuratedFieldRecord, true);
        }

        try {
          const payload: CurateFieldPayload = {
            ids: [id],
            reviewStatus: CuratedFieldStatus.REJECTED,
          };
          const {
            data: { changedNumber },
          } = await curateField(payload);

          if (changedNumber === 1 && reviewStatus === CuratedFieldStatus.UNCURATED) {
            onFieldReviewed?.();
          }

          const eventPayload: EntityEventPayload<CuratedFieldRowUpdatePayload> = {
            entityId: gridId,
            payload: {
              rowId: id,
              row: {
                reviewStatus: CuratedFieldStatus.REJECTED,
                updatedConfidenceLevel: ConfidenceLevel.LOW,
                curatedByUser: currentUser,
              },
            },
          };
          entityEventsEmitter.emit(EntityEvents.UPDATE_BY_ID, eventPayload);
          entityEventsEmitter.emit(EntityEvents.RELOAD);

          return {
            shouldGridReload: false,
            shouldClearSelection: false,
          };
        } catch ({ message }) {
          const notificationMessage = t('errors.fieldRejectionFailed', { fieldName });

          console.error(`${notificationMessage}: ${message}`);
          notificationService.error(`${notificationMessage}.`);

          return {
            shouldGridReload: false,
            shouldClearSelection: false,
          };
        }
      },
    };

    const actions: ToolbarAction[] = [
      {
        ...approveObjectCommonProps,
        icon: BigidLikeIcon,
        show: ({ selectedItem }: ActionData) => {
          const { reviewStatus } = selectedItem;
          return isPermitted(REVIEW_FINDINGS_PERMISSIONS.REVIEW.name) && reviewStatus !== CuratedFieldStatus.APPROVED;
        },
      },
      {
        ...approveObjectCommonProps,
        icon: () => {
          return <BigidLikeFilledIcon color="positive" />;
        },
        show: ({ selectedItem }: ActionData) => {
          const { reviewStatus } = selectedItem;
          return isPermitted(REVIEW_FINDINGS_PERMISSIONS.REVIEW.name) && reviewStatus === CuratedFieldStatus.APPROVED;
        },
      },
      {
        ...rejectObjectCommonProps,
        icon: BigidDislikeIcon,
        show: ({ selectedItem }) => {
          const { reviewStatus } = selectedItem;
          return isPermitted(REVIEW_FINDINGS_PERMISSIONS.REVIEW.name) && reviewStatus !== CuratedFieldStatus.REJECTED;
        },
      },
      {
        ...rejectObjectCommonProps,
        icon: () => {
          return <BigidDislikeFilledIcon color="negative" />;
        },
        show: ({ selectedItem }) => {
          const { reviewStatus } = selectedItem;
          return isPermitted(REVIEW_FINDINGS_PERMISSIONS.REVIEW.name) && reviewStatus === CuratedFieldStatus.REJECTED;
        },
      },

      {
        label: '',
        tooltip: t('addNote'),
        type: ToolbarActionType.TERTIARY,
        isGlobal: true,
        execute: ({ selectedItem }) => handleAddOrEditNote(selectedItem as unknown as CuratedFieldRecord, false, true),
        icon: () => <BigidNoteIcon />,
        show: () => isPermitted(REVIEW_FINDINGS_PERMISSIONS.REVIEW.name),
      },
    ];

    return {
      gridId,
      fetchGridData,
      columns,
      toolbarActions,
      actions,
    };
  }, [
    currentCuratedDataSource?.source,
    variant,
    t,
    currentCuratedAttribute.attributeName,
    currentCuratedAttribute.attributeType,
    structuredOnly,
    onFieldReviewed,
    allFields,
  ]);

  useEffect(() => {
    fetchToolbarFilterConfig();
  }, [fetchToolbarFilterConfig]);

  return {
    isReady,
    layoutConfig: {
      content: {
        entityName: 'Fields',
        toolbarActions,
        contentTypes: [LayoutContentType.MASTER_DETAILS],
        viewConfig: {
          fetchGridData,
          gridConfig: {
            gridId,
            pageSize: PAGE_SIZE,
            showSelectAll: true,
            columns,
            showSortingControls: false,
            defaultSorting: [
              { field: 'objectName', order: 'asc' },
              { field: 'hash_id', order: 'asc' },
            ],
            pagingMode: true,
            forceSelectAll: true,
          },
          selectedItemPropsMapping: {
            id: 'id',
            name: 'displayFieldName',
            fieldName: 'fieldName',
            displayFieldName: 'displayFieldName',
            icon: 'icon',
            reviewStatus: 'reviewStatus',
            previewComplexity: 'previewComplexity',
            fullyQualifiedName: 'fullyQualifiedName',
            detailedObjectType: 'detailedObjectType',
            note: 'note',
          },
          masterDetailsConfig: {
            tabsAndContent: {
              classes: {
                contentContainer: classes.contentContainer,
              },
              tabProps: {
                selectedIndex: 0,
                onChange: (value: number, tab: BigidTabsItem) => {
                  trackEventCurationView(CurationEvents.CURATION_FIELDS_TAB_CLICK, {
                    tab: tab.label,
                  });
                },
                tabs: [
                  {
                    label: t('preview'),
                    data: {
                      component: AttributePreviewStructured,
                      customProps: {
                        attributeName: currentCuratedAttribute.attributeName,
                        attributeType: currentCuratedAttribute.attributeType,
                        source: currentCuratedDataSource?.source,
                        curatedFieldsGridId: gridId,
                        contentContainerClassName: classes.wrapperContainer,
                      },
                    },
                    getIsAvailable: ({ detailedObjectType }) => {
                      return (
                        structuredObjectsTypes.includes(detailedObjectType) &&
                        !getApplicationPreference('DATA_PREVIEW_DISABLED')
                      );
                    },
                  },
                  {
                    label: t('columnProfile'),
                    data: {
                      component: ColumnProfileComponent,
                      customProps: {
                        contentContainerClassName: classes.wrapperContainer,
                      },
                    },
                    getIsAvailable: ({ detailedObjectType }) => structuredObjectsTypes.includes(detailedObjectType),
                  },
                  {
                    label: t('preview'),
                    data: {
                      component: AttributePreviewUnstructured,
                      customProps: {
                        attributeName: currentCuratedAttribute.attributeName,
                        attributeType: currentCuratedAttribute.attributeType,
                        source: currentCuratedDataSource?.source,
                        curatedFieldsGridId: gridId,
                        contentContainerClassName: classes.wrapperContainer,
                      },
                    },
                    getIsAvailable: ({ detailedObjectType }) => {
                      const objectsTypes = [DetailedObjectType.UNSTRUCTURED, DetailedObjectType.APP];
                      return (
                        objectsTypes.includes(detailedObjectType) && !getApplicationPreference('DATA_PREVIEW_DISABLED')
                      );
                    },
                  },
                  {
                    label: t('additionalAttributes'),
                    data: {
                      component: AdditionalAttributes,
                      customProps: {
                        contentContainerClassName: classes.wrapperContainer,
                      },
                    },
                  },
                ],
              },
            },
            actions,
          },
          filterToolbarConfig,
        },
      },
    },
  };
};
