import { getApplicationPreference } from '../../../../services/appPreferencesService';
import { NEW_CONNECTION_EXCLUDED_DATA_SOURCES } from '../../constants/newConnectionFlowConstants';
import { DataSourceTypes } from '../types';

export const isNewConnectivityExperienceEnabled = (sourceType: string) => {
  return sourceType
    ? getApplicationPreference('CONNECTIVITY_EXPERIENCE_ENABLED') &&
        !NEW_CONNECTION_EXCLUDED_DATA_SOURCES.includes(sourceType as DataSourceTypes)
    : false;
};
