import React, { FC, useRef, useState, useMemo, MouseEvent, useCallback, useEffect } from 'react';
import { noop } from 'lodash';
import {
  BigidButtonIcon,
  BigidDialog,
  BigidDropdownOption,
  BigidForm,
  BigidFormField,
  BigidFormFieldTypes,
  BigidFormRenderProps,
  BigidFormStateAndHandlers,
  BigidFormValidateOnTypes,
  BigidFormValues,
  BigidHeading6,
  BigidLoader,
  BigidTooltip,
  PrimaryButton,
  SecondaryButton,
} from '@bigid-ui/components';
import { ACActionType, Action, RunTpaActionConfiguration } from '../../../../actionWorkflowTypes';
import { Collapse } from '@mui/material';
import { BigidChevronCircleDownIcon, BigidChevronCircleUpIcon } from '@bigid-ui/icons';
import makeStyles from '@mui/styles/makeStyles';
import {
  findById,
  convertValuesToObjects,
  getLabel,
  FormStateProps,
  emptyForm,
  fetchAppActions,
  fetchInstalledApps,
  getActionPresets,
  fieldsNames,
  getSelectedValueFromName,
  TpaApiPayloadOptions,
  areObjectsEqual,
} from './runTpaActionUtils';
import { CustomAppIcon } from '../../../../../../ApplicationsManagement/Components/CustomAppIcon';
import BigidAppGenericIcon from '../../../../../../../assets/icons/BigidAppGeneric.svg';
import { CustomAppParamsTable } from '../../../../../../CustomApp/components/CustomAppParamsTable/CustomAppParamsTable';
import { CustomApps } from '../../../../../../ApplicationsManagement/applicationManagementService';
import { getUniqueCustomApps } from '../../../../../../ApplicationsManagement/Tabs/InstalledApps';
import { getApplicationPreference } from '../../../../../../../services/appPreferencesService';
import { getAppInstances, getCustomAppNormalizedFriendlyName } from '../../../../../../CustomApp/utils/CustomAppUtils';

interface RunTpaActionProps {
  isOpen: boolean;
  onClose: () => void;
  saveActionConfiguration: (actionToSave: Action) => void;
  initialValue: RunTpaActionConfiguration;
}

const useStyles = makeStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
    padding: '4px',
    maxHeight: '460px',
  },
  loader: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '50px',
  },
  card: {
    display: 'flex',
    flexDirection: 'column',
    boxShadow: `0px 0px 5px rgba(0, 0, 0, 0.15)`,
    borderRadius: 4,
    marginBottom: 6,
    marginTop: 24,
    overflow: 'hidden',
    padding: '4px 4px 4px 16px',
  },
  quickViewTitle: {
    justifyContent: 'space-between',
    display: 'flex',
    alignItems: 'center',
  },
  arrow: {
    verticalAlign: 'middle',
  },
});

const getLogo = (option: TpaApiPayloadOptions | CustomApps) => {
  return () => {
    return option.logo ? <CustomAppIcon logo={option.logo} isSidebar /> : <BigidAppGenericIcon />;
  };
};

const convertToOptions = (data: TpaApiPayloadOptions[] | CustomApps[], isApp?: boolean) => {
  return data.map((tpaObject: TpaApiPayloadOptions | CustomApps) => ({
    id: tpaObject._id,
    displayValue:
      getApplicationPreference('TPA_MULTIPLE_DEPLOYMENTS_ENABLED') && isApp
        ? getCustomAppNormalizedFriendlyName(getLabel(tpaObject), (tpaObject as CustomApps).settings?.instanceId)
        : getLabel(tpaObject),
    value: tpaObject,
    ...(isApp ? { icon: getLogo(tpaObject) } : { tooltip: tpaObject.description }),
  })) as BigidDropdownOption[];
};

export const RunTpaActionDialog: FC<RunTpaActionProps> = ({
  isOpen,
  onClose,
  saveActionConfiguration,
  initialValue,
}) => {
  const classes = useStyles({});

  const formControls = useRef<BigidFormStateAndHandlers>();
  const [isQuickViewVisible, setIsQuickViewVisible] = useState<boolean>(false);

  const [formState, setFormState] = useState<FormStateProps>(emptyForm);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const buttons = [
    {
      text: 'Cancel',
      component: SecondaryButton,
      onClick: noop,
      isClose: true,
    },
    {
      text: 'Save',
      component: PrimaryButton,
      onClick: (event: MouseEvent) => {
        formControls?.current?.validateAndSubmit(() => {
          formControls?.current?.submit(event);
        });
      },
    },
  ];

  const fields: BigidFormField[] = useMemo(() => {
    return [
      {
        type: BigidFormFieldTypes.DROP_DOWN,
        name: fieldsNames.APPS,
        label: 'Select App',
        validateOn: BigidFormValidateOnTypes.SUBMIT,
        validate: () => (formState.selectedApp ? false : 'Please select an app'),
      },
      ...(getApplicationPreference('TPA_MULTIPLE_DEPLOYMENTS_ENABLED')
        ? [
            {
              type: BigidFormFieldTypes.DROP_DOWN,
              name: fieldsNames.INSTANCES,
              label: 'Select Instance',
              validateOn: BigidFormValidateOnTypes.SUBMIT,
              validate: () => (formState.selectedInstance ? false : 'Please select an instance'),
            },
          ]
        : []),
      {
        type: BigidFormFieldTypes.DROP_DOWN,
        name: fieldsNames.ACTIONS,
        label: 'Select Action',
        validateOn: BigidFormValidateOnTypes.SUBMIT,
        validate: () => (formState.selectedAction ? false : 'Please select an action'),
      },
      {
        type: BigidFormFieldTypes.DROP_DOWN,
        name: fieldsNames.PRESETS,
        label: 'Preset Name',
        validateOn: BigidFormValidateOnTypes.SUBMIT,
        validate: () => (formState.selectedPreset ? false : 'Please select a preset'),
      },
    ];
  }, [formState]);

  const getRestoredForm = useCallback(async () => {
    if (isLoading) return;
    setIsLoading(true);
    const restoredForm = { ...emptyForm };
    const installedApps = await fetchInstalledApps();
    restoredForm.apps = convertToOptions(
      getApplicationPreference('TPA_MULTIPLE_DEPLOYMENTS_ENABLED')
        ? getUniqueCustomApps(installedApps, initialValue?.tpaId)
        : installedApps,
      true,
    );
    if (initialValue) {
      restoredForm.selectedApp = findById(restoredForm.apps, initialValue.tpaId);
      if (restoredForm.selectedApp) {
        if (getApplicationPreference('TPA_MULTIPLE_DEPLOYMENTS_ENABLED')) {
          const selectedAppObject = installedApps.find((app: CustomApps) => app._id === initialValue.tpaId);
          restoredForm.instances = convertToOptions(getAppInstances(selectedAppObject, installedApps));
          restoredForm.selectedInstance = findById(restoredForm.instances, initialValue.tpaId);
        }
        restoredForm.actions = convertToOptions(await fetchAppActions(initialValue.tpaId));
        restoredForm.selectedAction = findById(restoredForm.actions, initialValue.actionId);
        if (restoredForm.selectedAction) {
          restoredForm.presets = convertToOptions(getActionPresets(restoredForm.selectedAction));
          restoredForm.selectedPreset = findById(restoredForm.presets, initialValue.presetId);
        }
      }
    }
    setFormState(restoredForm as FormStateProps);
    setIsLoading(false);
  }, [isLoading, initialValue]);

  const handleFormSubmit = useCallback(() => {
    saveActionConfiguration({
      type: ACActionType.RUN_TPA_ACTION,
      configuration: {
        ...initialValue,
        ...(formState.selectedApp || formState.selectedAction
          ? { tpaId: (formState.selectedInstance || formState.selectedApp).id }
          : {}),
        ...(formState.selectedAction ? { actionId: formState.selectedAction.id } : {}),
        ...(formState.selectedPreset ? { presetId: formState.selectedPreset.id } : {}),
      },
    });
    onClose();
  }, [saveActionConfiguration, initialValue, onClose, formState]);

  useEffect(() => {
    if (isOpen && areObjectsEqual(formState, emptyForm)) {
      setIsQuickViewVisible(false);
      getRestoredForm();
    } else if (!isOpen && !areObjectsEqual(formState, emptyForm)) {
      setFormState(emptyForm);
      formControls.current.clear();
    }
  }, [formState, getRestoredForm, isOpen]);

  const handleQuickView = () => {
    setIsQuickViewVisible(!isQuickViewVisible);
  };

  const renderForm = useCallback(
    ({ renderField, getValues }: BigidFormRenderProps) => {
      const showParams = formState.selectedAction && formState.selectedPreset;

      const areParamsExists = showParams
        ? Object.keys(formState.selectedPreset?.value?.paramsKeyValue).length > 0
        : false;

      return (
        <>
          {fields.map(({ name }) => {
            if ((name === fieldsNames.INSTANCES && formState.instances?.length > 1) || name !== fieldsNames.INSTANCES)
              return renderField(name, {
                dropDownOptions: formState[name as keyof FormStateProps],
                value: [getSelectedValueFromName(formState, name)],
              });
          })}
          {showParams && (
            <div className={classes.card}>
              <div className={classes.quickViewTitle}>
                <BigidHeading6 className={classes.arrow}>
                  {areParamsExists ? 'Parameters' : 'No prarameters to show'}
                </BigidHeading6>
                {areParamsExists && (
                  <BigidTooltip title={'Quick View'}>
                    <span>
                      {!isQuickViewVisible && (
                        <BigidButtonIcon
                          icon={BigidChevronCircleDownIcon}
                          className={classes.arrow}
                          onClick={handleQuickView}
                          dataAid="quick_view"
                        />
                      )}
                      {isQuickViewVisible && (
                        <BigidButtonIcon
                          icon={BigidChevronCircleUpIcon}
                          className={classes.arrow}
                          onClick={handleQuickView}
                          dataAid="quick_view"
                        />
                      )}
                    </span>
                  </BigidTooltip>
                )}
              </div>
              <Collapse in={isQuickViewVisible}>
                <CustomAppParamsTable
                  params={formState.selectedAction.value.params}
                  onParamChange={() => noop}
                  setIsValuesValid={() => noop}
                  values={formState.selectedPreset.value.paramsKeyValue}
                  readOnly
                />
              </Collapse>
            </div>
          )}
        </>
      );
    },
    [classes.arrow, classes.card, classes.quickViewTitle, fields, handleQuickView, isQuickViewVisible, formState],
  );

  const handleChange = async (values: BigidFormValues) => {
    const { apps, instances, actions, presets } = convertValuesToObjects(values);
    const changedFormState = { ...formState };

    const hasAppChanged = apps && apps?.id != formState.selectedApp?.id;
    const hasInstanceChanged =
      instances &&
      instances?.id != formState.selectedInstance?.id &&
      formState.instances.some(instance => instance.id === instances.id);
    const isMultipleDeploymentsEnabled = getApplicationPreference('TPA_MULTIPLE_DEPLOYMENTS_ENABLED');

    if (hasAppChanged) {
      changedFormState.selectedApp = apps;
      if (isMultipleDeploymentsEnabled) {
        changedFormState.instances = convertToOptions(getAppInstances(apps.value, await fetchInstalledApps()));
        changedFormState.selectedInstance = undefined;
        changedFormState.actions = undefined;
      } else {
        changedFormState.actions = convertToOptions(await fetchAppActions(apps?.id));
      }
    }

    if (isMultipleDeploymentsEnabled && (hasInstanceChanged || changedFormState.instances?.length <= 1)) {
      changedFormState.selectedInstance = hasInstanceChanged ? instances : apps;
      changedFormState.actions = convertToOptions(await fetchAppActions(changedFormState.selectedInstance?.id));
    }

    if (hasInstanceChanged || hasAppChanged) {
      changedFormState.presets = undefined;
      changedFormState.selectedAction = undefined;
      changedFormState.selectedPreset = undefined;
      setFormState(changedFormState);
      formControls.current.validateField(fieldsNames.APPS, changedFormState.selectedApp);
      return;
    }

    if (
      actions &&
      actions?.id != formState.selectedAction?.id &&
      formState.actions.some(item => item.id === actions.id)
    ) {
      changedFormState.selectedAction = actions;
      changedFormState.presets = convertToOptions(actions.value.presets);
      changedFormState.selectedPreset = undefined;
      setFormState(changedFormState);
      formControls.current.validateField(fieldsNames.ACTIONS, changedFormState.selectedAction);
      return;
    }

    if (
      presets &&
      presets?.id != formState.selectedPreset?.id &&
      formState.presets.some(item => item.id === presets.id)
    ) {
      changedFormState.selectedPreset = presets;
      setFormState(changedFormState);
      formControls.current.validateField(fieldsNames.PRESETS, changedFormState.selectedPreset);
      return;
    }
  };

  return (
    <BigidDialog title="App Call Settings" isOpen={isOpen} onClose={onClose} showCloseIcon buttons={buttons}>
      {isLoading ? (
        <div className={classes.loader}>
          <BigidLoader />
        </div>
      ) : (
        <div className={classes.root}>
          <BigidForm
            controlButtons={false}
            fields={fields}
            stateAndHandlersRef={formControls}
            renderForm={renderForm}
            onChange={handleChange}
            onSubmit={handleFormSubmit}
          />
        </div>
      )}
    </BigidDialog>
  );
};
