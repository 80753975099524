import React, { FC } from 'react';
import ClassifierModelRegexIcon from '../../../../../../../assets/icons/ClassifierModelRegexIcon.svg';
import { useLocalTranslation } from '../../translations';
import { generateDataAid } from '@bigid-ui/utils';
import { styled } from '@mui/material';
import { BigidBody1, BigidHeading4, BigidHeading1, BigidHeading5 } from '@bigid-ui/components';

const Card = styled('div')`
  display: flex;
  width: 266px;
  height: 372px;
  padding: 0 16px;
  flex-direction: column;
  align-items: flex-start;
  border-radius: 4px;
  border: 1px solid ${({ theme }) => theme.vars.palette.bigid.gray200};
  gap: 24px;
`;

const Headline = styled(BigidHeading4)`
  display: flex;
  justify-content: center;
  align-items: end;
  gap: 8px;
  margin-top: 24px;
`;

const AccuracyText = styled(BigidBody1)`
  margin-top: 10px;
`;

const ExplanationText = styled(BigidBody1)`
  color: ${({ theme }) => theme.vars.tokens.bigid.foregroundTertiary};
`;

const RegexAccuracyStats = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
`;

export interface ClassifierModelRegexCardProps {
  regexAccuracy: string;
  dataAid?: string;
}

export const ClassifierModelRegexCard: FC<ClassifierModelRegexCardProps> = ({
  regexAccuracy,
  dataAid = 'ClassifierModelRegexCard',
}) => {
  const { t } = useLocalTranslation('main');

  return (
    <Card data-aid={generateDataAid(dataAid, ['regex', 'card'])}>
      <Headline data-aid={generateDataAid(dataAid, ['regex', 'headline'])}>
        <ClassifierModelRegexIcon />
        <BigidHeading5>{t('regexCard.header')}</BigidHeading5>
      </Headline>
      <RegexAccuracyStats>
        <AccuracyText>{t('structuredAccuracy')}</AccuracyText>
        <BigidHeading1 data-aid={generateDataAid(dataAid, ['regex', 'accuracy'])}>{regexAccuracy}</BigidHeading1>
      </RegexAccuracyStats>
      <ExplanationText>{t('regexCard.explanation')}</ExplanationText>
    </Card>
  );
};
