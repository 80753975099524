import React, { PropsWithChildren, useMemo, createContext, useContext } from 'react';

export type DataSourceConnectionDialogContextType = Record<string, string>;
type DataSourceConnectionDialogProviderPartialProps = {
  config: DataSourceConnectionDialogContextType;
};

export const DataSourceConnectionDialogContext = createContext<DataSourceConnectionDialogContextType>(null);

export const DataSourceConnectionDialogProvider = ({
  config,
  children,
}: PropsWithChildren<DataSourceConnectionDialogProviderPartialProps>): JSX.Element => {
  const value = useMemo(() => config, [config]);
  return (
    <DataSourceConnectionDialogContext.Provider value={value as DataSourceConnectionDialogContextType}>
      {children}
    </DataSourceConnectionDialogContext.Provider>
  );
};

export const useDataSourceConnectionDialogContext = (slot: string) => {
  const context = useContext(DataSourceConnectionDialogContext);

  if (!context) {
    throw new Error(
      `DataSourceConnectionDialog.${slot} component cannot be used outside of DataSourceConnectionDialog parent component`,
    );
  }

  return context;
};
