import React, { FC, useEffect, useState } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { ActionData, BigidFilter } from '@bigid-ui/components';
import {
  BigidGridColumn,
  BigidGridColumnTypes,
  BigidGridQueryComponents,
  BigidGridWithToolbar,
  BigidGridWithToolbarProps,
} from '@bigid-ui/grid';
import { httpService } from '../../../../../services/httpService';
import { notificationService } from '../../../../../services/notificationService';
import {
  DataSourceModel,
  DataSourcesResponse,
} from '../../../../DataSources/DataSourceConnections/DataSourceConnectionTypes';

interface DataSourcesGridProps {
  dataSourceNames: string[];
  onRemove: (value: string[]) => void;
}

const useStyles = makeStyles({ gridWrapper: { display: 'flex', overflow: 'hidden', height: 250 } });

const columns: BigidGridColumn<DataSourceModel>[] = [
  {
    name: 'name',
    title: 'Data Source',
    getCellValue: ({ name }) => name,
    filteringEnabled: true,
    sortingEnabled: true,
    type: BigidGridColumnTypes.TEXT,
  },
  {
    name: 'type',
    title: 'Type',
    getCellValue: ({ type }) => type,
    filteringEnabled: true,
    sortingEnabled: true,
    type: BigidGridColumnTypes.TEXT,
  },
  {
    name: 'location',
    title: 'Location',
    getCellValue: ({ location }) => location,
    filteringEnabled: true,
    sortingEnabled: true,
    type: BigidGridColumnTypes.TEXT,
  },
];
const fields = [...columns.map(({ name }) => name), 'displayName'];

export const SingleSelectedDataSourcesGrid: FC<DataSourcesGridProps> = ({ dataSourceNames, onRemove }) => {
  const classes = useStyles();
  const [externalFilter, setExternalFilter] = useState<BigidFilter>([
    { field: 'name', operator: 'in', value: dataSourceNames },
  ]);

  useEffect(() => {
    setExternalFilter([{ field: 'name', operator: 'in', value: dataSourceNames }]);
  }, [dataSourceNames]);

  const gridWithToolbarConfig: BigidGridWithToolbarProps<DataSourceModel> = {
    columns,
    pageSize: 200,
    entityName: 'Data Sources',
    customRowIdName: 'id',
    externalFilter,
    hideColumnChooser: true,
    fetchData: async (queryComponents: BigidGridQueryComponents) => {
      let data: DataSourceModel[] = [];
      let totalCount = 0;

      try {
        const {
          data: {
            data: { ds_connections, totalCount: count },
          },
        } = await httpService.post<{ data: DataSourcesResponse }>(`ds-connections`, {
          query: { ...queryComponents, fields },
        });

        data = ds_connections;
        totalCount = count;
      } catch (e) {
        console.error(e);
        notificationService.error('Could not fetch data. See logs for more information');
      }

      return {
        data,
        totalCount,
      };
    },
    showSortingControls: true,
    toolbarActions: [
      {
        label: 'Delete',
        execute: async ({ selectedRows = [] }: ActionData) => {
          onRemove(selectedRows.map(({ name }) => name));

          return {
            shouldGridReload: false,
            shouldClearSelection: true,
          };
        },
        show: ({ selectedRowIds }) => selectedRowIds.length > 0,
      },
    ],
  };
  return (
    <div className={classes.gridWrapper}>
      <BigidGridWithToolbar {...gridWithToolbarConfig} />
    </div>
  );
};
