import React, { FC, useCallback, useEffect, useState } from 'react';
import { SystemEvents, systemEventsEmitter } from '../../services/systemEvents';
import { BigidDialog, BigidDialogProps, SecondaryButton } from '@bigid-ui/components';
import { $transitions } from '../../services/angularServices';
import { BigidSystemDialogOptions } from '../../services/systemDialogService';
import { noop } from 'lodash';

const defaultOptions: BigidSystemDialogOptions = {
  title: '',
  onClose: noop,
  content: null,
};

export const BigidSystemDialog: FC = () => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [options, setOptions] = useState<BigidSystemDialogOptions>(defaultOptions);

  const handleClose = useCallback(() => {
    setIsOpen(false);
    setTimeout(() => {
      if (options === defaultOptions) return;
      const { onClose } = options;
      onClose();
      setOptions(defaultOptions);
    }, 100);
  }, [options]);

  useEffect(() => {
    function handleEvent(event: [BigidSystemDialogOptions]) {
      const [options] = event;
      setOptions({ ...options });
      setIsOpen(true);
    }

    const unregisterOpen = systemEventsEmitter.addEventListener(SystemEvents.SYSTEM_DIALOG, handleEvent);
    const unregisterClose = systemEventsEmitter.addEventListener(SystemEvents.CLOSE_SYSTEM_DIALOG, handleClose);

    return function cleanup() {
      unregisterOpen();
      unregisterClose();
    };
  }, []);

  useEffect(() => {
    const deregister = $transitions.onSuccess({}, () => {
      handleClose();
    });
    return function cleanup() {
      deregister();
    };
  }, [handleClose]);

  const dialogConfig: BigidDialogProps = {
    ...options,
    isOpen,
    onClose: handleClose,
    buttons: [...(options.buttons || []), ...(options.withCloseButton ? [{ component: SecondaryButton, onClick: handleClose, text: 'Close' }] : [])],
  };

  const { content: Content, contentProps = {} } = options;
  return <BigidDialog {...dialogConfig}>{Content && <Content onClose={handleClose} {...contentProps} />}</BigidDialog>;
};

/* Conversion modules section */
import angular from 'angular';
import { convertToAngular } from '../../../common/services/convertToAngular';

angular.module('app').component('bigidSystemDialog', convertToAngular(BigidSystemDialog));
