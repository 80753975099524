import React, { useContext, useRef, useMemo, useState, useEffect } from 'react';
import styled from '@emotion/styled';
import { uniqueId } from 'lodash';
import {
  BigidLayoutMasterDetails,
  BigidLayoutMasterDetailsConfig,
  useLayout,
  BigidLayoutMasterDetailsGridSearchTypes,
  BigidLayoutsMasterDetailsTypes,
} from '@bigid-ui/layout';
import { BigidGridProps, BigidGridRow, BigidGridColumnTypes } from '@bigid-ui/grid';
import { BigidHeading6, BigidTooltip, SecondaryButton } from '@bigid-ui/components';
import { DsObjectPreviewWrapper } from './DsObjectPreviewWrapper/DsObjectPreviewWrapper';
import { DsObjectPreviewTable } from './DsObjectPreviewTable/DsObjectPreviewTable';
import { findAttributesCount, selectedTableAttribute } from './utils';
import { DsObjectPreviewFile, FilePreviewRefProps } from './DsObjectPreviewFile/DsObjectPreviewFile';
import { DataCatalogObjectDetails } from '../../../../DataCatalog/DataCatalogDetails/DataCatalogDetailsService';
import { DataCatalogAttribute } from '../../../../DataCatalog/DataCatalogAttributes';
import { getAttrExpiredStatus } from '../objectPreviewService';
import { generateDataAid } from '@bigid-ui/utils';
import { ObjectPreviewDSPMEvents, trackManualEvent } from './events';
import { ActionableInsightsContext } from '../../../hooks/ActionableInsightsContext';
import { CustomAccordeon } from '../ObjectPreviewComponents/CustomAccordeon';
import { deletePreviewTableData } from './hook/DsObjectFilePreviewService';
import { notificationService } from '../../../../../services/notificationService';

interface BigidDsObjectPreviewProps {
  objectAttributes: DataCatalogAttribute[];
  objectPreviewData: DataCatalogObjectDetails | any;
  caseId: string;
  dataAid?: string;
}

export interface SelectionAttributeProps {
  name: string;
  count: number;
  filterValue: string;
  id: string;
}

enum DsTypes {
  STRUCTURED = 'structured',
  UNSTRUCTURED = 'unstructured',
}

const Main = styled.div`
  background-color: transparent;
  border-radius: 4px;
  height: 560px;
`;

const Content = styled.div<{ withoutMargin?: boolean }>`
  display: flex;
  height: 485px;
  justify-content: center;
  padding: ${({ withoutMargin }) => (withoutMargin ? '0' : '1rem')};
  & > form {
    flex: 1;
  }
`;

const Header = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-left: 1.5rem;
  padding-top: 1.5rem;
`;

const SelectionWrapper = styled.div`
  display: grid;
  grid-template-columns: 70% 25%;
  column-gap: 5%;
  justify-content: space-between;
  width: 100%;
`;

const TextCutter = styled.div`
  text-overflow: ellipsis;
  overflow: hidden;
  width: 100%;
`;

export const BigidDsObjectPreview = ({
  objectAttributes,
  objectPreviewData,
  caseId,
  dataAid = 'DSObjectPreview',
}: BigidDsObjectPreviewProps) => {
  const { listUnsupportedDs } = useContext(ActionableInsightsContext);
  const [isClearCahceButtonShown, setIsClearCahceButtonShown] = useState(false);
  const [isScanFailed, setIsScanFailed] = useState<boolean>(false);
  const [isSegmentsExists, setIsSegmentExists] = useState<boolean>(true);
  const filePreviewRef = useRef<FilePreviewRefProps>();
  const dsType: string = objectPreviewData.extendedObjectType;
  const isDsUnsupported = listUnsupportedDs.includes(dsType);

  const handleScanStatus = (value: boolean) => {
    setIsScanFailed(value);
  };

  const handleSegmnetsExists = (value: boolean) => {
    setIsSegmentExists(value);
  };

  const handleCrearCache = () => {
    if (objectPreviewData?.scanner_type_group === DsTypes.UNSTRUCTURED) {
      filePreviewRef.current.clearCache();
    } else if (objectPreviewData?.scanner_type_group === DsTypes.STRUCTURED) {
      deletePreviewTableData(objectPreviewData?.fullyQualifiedName);
      notificationService.success('Cached content deleted successfully');
    }
  };

  const handleVisibilityCacheButton = (value: boolean) => {
    setIsClearCahceButtonShown(value);
  };

  const handleClearCache = (e: React.MouseEvent) => {
    e.stopPropagation();
    handleVisibilityCacheButton(false);
    handleCrearCache();
    clearSelection();
  };

  const handleResetEmptyState = () => {
    setIsScanFailed(false);
    setIsSegmentExists(true);
  };

  const selection: BigidGridRow[] = objectAttributes.map((attribute: DataCatalogAttribute) => ({
    name: attribute?.attribute_name,
    count: findAttributesCount(attribute?.attribute_original_name, objectPreviewData?.attribute_details),
    filterValue:
      objectPreviewData?.scanner_type_group === 'structured'
        ? selectedTableAttribute(objectAttributes, attribute?.attribute_original_name)
        : attribute?.attribute_original_name,
    id: uniqueId(),
  }));

  const args = {
    searchable: true,
    searchType: BigidLayoutMasterDetailsGridSearchTypes.INTEGRATED,
  };

  const { searchType, ...rest } = args;

  const { gridId, rows, isLoading, totalRowsCount, skip, onPagingChanged, defaultSorting, clearSelection } =
    useLayout<any>(BigidLayoutsMasterDetailsTypes.MASTER_DETAILS, {
      pageSize: 100,
      fetchDataFunction: async () => {
        return { data: selection, totalCount: selection.length };
      },
      initialSorting: [{ field: 'name', order: 'asc' }],
    });
  const HeaderComponent = ({ title }: { title: string }): JSX.Element => {
    const styles = {
      marginRight: '1rem',
    };
    return (
      <Header>
        <BigidHeading6 sx={styles} noWrap height={47}>
          {title}
        </BigidHeading6>
      </Header>
    );
  };

  const isObjectScanExpired = getAttrExpiredStatus(objectAttributes);

  const ContentComponent = ({ row }: { row: SelectionAttributeProps }) => {
    return (
      <Content
        data-aid={generateDataAid(dataAid, ['Wrapper'])}
        withoutMargin={objectPreviewData?.scanner_type_group === DsTypes.STRUCTURED}
      >
        <DsObjectPreviewWrapper
          selectedRow={row}
          isNotSupported={isDsUnsupported}
          isExpired={isObjectScanExpired}
          isScanFailed={isScanFailed}
          isSegmentsExists={isSegmentsExists}
          dataAid={dataAid}
        >
          {objectPreviewData?.scanner_type_group === DsTypes.UNSTRUCTURED ? (
            <DsObjectPreviewFile
              source={objectPreviewData?.dataSourceName}
              fullyQualifiedName={objectPreviewData?.fullyQualifiedName}
              scannerType={objectPreviewData?.scanner_type_group}
              key={row?.filterValue}
              filteredAttribute={row?.filterValue}
              handleScanStatus={handleScanStatus}
              handleSegmnetsExists={handleSegmnetsExists}
              handleVisibilityCacheButton={handleVisibilityCacheButton}
              caseId={caseId}
              objectPreviewData={objectPreviewData}
              dataAid={generateDataAid(dataAid, ['File'])}
              ref={filePreviewRef}
            />
          ) : (
            <DsObjectPreviewTable
              source={objectPreviewData?.dataSourceName}
              fullyQualifiedName={objectPreviewData?.fullyQualifiedName}
              type={objectPreviewData?.scanner_type_group}
              filteredColumn={row?.filterValue}
              handleScanStatus={handleScanStatus}
              dataAid={generateDataAid(dataAid, ['Table'])}
              handleVisibilityCacheButton={handleVisibilityCacheButton}
            />
          )}
        </DsObjectPreviewWrapper>
      </Content>
    );
  };

  const RenderContentComponent = ({ selectedItem }: any) => {
    if (selectedItem) {
      trackManualEvent({
        appInfo: ObjectPreviewDSPMEvents.DSPM_ATTRIBUTE_SAMPLE_VIEWED,
        appData: {
          caseId,
          objectId: objectPreviewData?._id,
          attributeName: selectedItem?.name,
          timeStamp: Date.now(),
        },
      });
    }

    useEffect(() => {
      handleResetEmptyState();
    }, [selectedItem]);

    return <ContentComponent row={selectedItem} />;
  };

  const memoizedRender = useMemo(() => RenderContentComponent, [isScanFailed, isSegmentsExists]);

  const gridConfig: BigidGridProps<any> = useMemo(
    () => ({
      gridId,
      rows,
      totalRowsCount,
      skip,
      onPagingChanged,
      defaultSorting,
      loading: isLoading,
      pageSize: 10000,
      columns: [
        {
          name: 'name',
          title: 'Name',
          width: 'auto',
          getCellValue: row => (
            <SelectionWrapper>
              <TextCutter data-aid={`object-Preview-Attribute-${row.name}`}>
                <BigidTooltip dataAid="tests" title={row.name} placement="bottom-start">
                  <span>{row.name}</span>
                </BigidTooltip>
              </TextCutter>
              <TextCutter style={{ textAlign: 'right' }}>
                <BigidTooltip title={row.count} placement="bottom-start">
                  <span>{row.count}</span>
                </BigidTooltip>
              </TextCutter>
            </SelectionWrapper>
          ),
          type: BigidGridColumnTypes.CUSTOM,
        },
      ],
    }),
    [rows],
  );

  const config: BigidLayoutMasterDetailsConfig<any> = useMemo<BigidLayoutMasterDetailsConfig<any>>(
    () => ({
      grid: gridConfig,
      search: { fields: ['name'], placeholder: `Search from ${selection.length} attribute(s)`, type: searchType },
    }),
    [gridConfig],
  );

  return (
    <CustomAccordeon
      header="Affected Attributes"
      customButton={
        isClearCahceButtonShown && <SecondaryButton size="medium" onClick={handleClearCache} text="Clear from cache" />
      }
    >
      <Main>
        <BigidLayoutMasterDetails
          key="ObjectAttributes"
          {...rest}
          config={config as unknown as BigidLayoutMasterDetailsConfig<BigidGridRow>}
        >
          <BigidLayoutMasterDetails.Header
            render={({ selectedItem }) => {
              const itemRow: any = selectedItem;
              return <HeaderComponent title={itemRow?.name ?? ''} />;
            }}
          />
          <BigidLayoutMasterDetails.Content render={memoizedRender} />
        </BigidLayoutMasterDetails>
      </Main>
    </CustomAccordeon>
  );
};
