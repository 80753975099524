import React, { useEffect, useState, useContext } from 'react';
import styled from '@emotion/styled';
import { BigidBody1, BigidDropdown, BigidDropdownValue, BigidLoader } from '@bigid-ui/components';
import { v4 as uuid } from 'uuid';
import { BigidTasksIllustration } from '@bigid-ui/icons';
import { fetchRemediationSteps } from '../../../actionableInsightsService';
import { VerificationComponent } from './VerificationComponent';
import { CaseSidePanelContext } from '../../hooks/CaseSidePanelContext';
import {
  ActionableInsightsTrackingEvents,
  trackActionableInsightsEvent,
} from '../../../actionableInsightsTrackingUtil';
import { useLocalTranslation } from '../../../translations';
import { generateDataAid } from '@bigid-ui/utils';
import { CaseRemediationMarkdown } from './CaseRemediationMarkdown';
import { CaseRemediationVote } from './CaseRemediationVote';

export interface RemediationStepsProps extends BigidDropdownValue {
  tool: string;
  solution: string;
  verified: boolean;
}

export interface CaseRemediationStepsProps {
  dataAid?: string;
}

const Main = styled.div({
  display: 'flex',
  flexDirection: 'column',
  padding: '12px 24px 24px',
  rowGap: '24px',
});

const Header = styled.div({
  display: 'flex',
  justifyContent: 'space-between',
});

const DropdownContainer = styled.div({
  display: 'flex',
  width: '100%',
  columnGap: '16px',
  alignItems: 'center',
});

const DropdownWrapper = styled.div({
  width: '279px',
});

const EmptyStateWrapper = styled.div({
  display: 'flex',
  flexDirection: 'column',
  rowGap: '24px',
  justifyContent: 'center',
  alignItems: 'center',
  height: 'calc(100vh - 282px)',
});

const EmptyStateText = styled.div({
  width: '396px',
  textAlign: 'center',
});

const StatusWrapper = styled.div({
  display: 'flex',
  columnGap: '20px',
});

export const CaseRemediationSteps = ({ dataAid = 'RemediationSteps' }: CaseRemediationStepsProps) => {
  const {
    caseSidePanelData: { policyName, dataSourceType },
  } = useContext(CaseSidePanelContext);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [options, setOptions] = useState([]);
  const { t } = useLocalTranslation('RemediationSteps');
  const handleDropdown = (value: Partial<RemediationStepsProps>[]) => {
    setSelectedOptions(value);
    trackActionableInsightsEvent(
      ActionableInsightsTrackingEvents.DATA_RISK_MANAGEMENT_REMEDIATION_STEPS_PLATFORM_CLICK,
      { policyName, dataSourceType, platform: value[0].tool },
    );
  };
  useEffect(() => {
    const fetchingRemediationSteps = async () => {
      const response = await fetchRemediationSteps(policyName, dataSourceType);
      const result = response.map((element: RemediationStepsProps, index: number) => ({
        ...element,
        id: uuid(),
        value: index,
        displayValue: element.tool,
      }));
      setOptions(result);
      setIsLoading(false);
    };
    fetchingRemediationSteps();
  }, []);

  return (
    <Main data-aid={generateDataAid(dataAid, ['container'])}>
      {isLoading ? (
        <BigidLoader />
      ) : (
        <>
          <Header>
            <DropdownContainer>
              <BigidBody1 fontWeight={600}>{t('platform.target')}</BigidBody1>
              <DropdownWrapper>
                <BigidDropdown
                  value={selectedOptions}
                  listWidth={100}
                  onSelect={handleDropdown}
                  options={options}
                  placeholder={t('platform.placeholder')}
                  hasIconBefore={true}
                  dataAid={generateDataAid(dataAid, ['dropdown'])}
                />
              </DropdownWrapper>
            </DropdownContainer>
            <StatusWrapper>
              <CaseRemediationVote
                platform={selectedOptions[0]?.tool}
                policyName={policyName}
                dataSourceType={dataSourceType}
                dataAid={dataAid}
              />
            </StatusWrapper>
          </Header>
          <div>
            {selectedOptions.length > 0 ? (
              <CaseRemediationMarkdown generatedText={selectedOptions[0]?.solution} dataAid={dataAid} />
            ) : (
              <EmptyStateWrapper data-aid={generateDataAid(dataAid, ['emptystate'])}>
                <BigidTasksIllustration size="medium" />
                <EmptyStateText>
                  <BigidBody1>{t('platform.emptystate')}</BigidBody1>
                </EmptyStateText>
              </EmptyStateWrapper>
            )}
          </div>
        </>
      )}
    </Main>
  );
};
