import React, { FC } from 'react';
import { noop } from 'lodash';
import { SecondaryButton, PrimaryButton } from '@bigid-ui/components';
import { BigidFormBox } from '../../../../../components/BigidFormBox/BigidFormBox';
import { openSystemDialog } from '../../../../../services/systemDialogService';
import { BigidDeleteIcon } from '@bigid-ui/icons';

export interface AppInstanceSectionProps {
  instanceName: string;
  onDelete: () => void;
}

export const AppInstanceSection: FC<AppInstanceSectionProps> = ({ onDelete, instanceName }) => {
  const openDeleteApprovalModal = () => {
    openSystemDialog({
      title: `${instanceName} instance delete`,
      onClose: noop,
      content: () => <div>Are you sure you want to delete?</div>,
      maxWidth: 'xs',
      borderTop: true,
      buttons: [
        {
          text: 'Yes',
          onClick: () => onDelete(),
          component: PrimaryButton,
        },
        {
          text: 'No',
          onClick: noop,
          component: SecondaryButton,
          isClose: true,
        },
      ],
    });
  };

  return (
    <BigidFormBox title="Application Instance" description={instanceName}>
      <SecondaryButton
        onClick={openDeleteApprovalModal}
        dataAid={'DeleteInstance'}
        size="small"
        startIcon={<BigidDeleteIcon />}
        text={'Delete instance'}
      />
    </BigidFormBox>
  );
};
