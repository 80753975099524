import React, { FunctionComponent, ReactText, useState, useEffect } from 'react';
import { BigidTags, BigidTagBaseProps, BigidTagWizardDictionary, getTagsDictionary } from '@bigid-ui/components';
import { TagModel } from '../../../services/tagsService';
import { areAllPermitted } from '../../../services/userPermissionsService';
import { TAGS_SAVED_QUERIES_PERMISSIONS, CLUSTER_ANALYSIS_PERMISSIONS } from '@bigid/permissions';

export interface ClusterTagsListProps {
  clusterId: ReactText;
  tags?: TagModel[];
  onAttach?: (tag: BigidTagBaseProps) => void;
  onDetach?: (tag: BigidTagBaseProps) => void;
}

export const ClusterTagsList: FunctionComponent<ClusterTagsListProps> = ({
  tags = [],
  clusterId,
  onAttach,
  onDetach,
}: ClusterTagsListProps) => {
  const [clusterTags, setClusterTags] = useState<BigidTagBaseProps[]>();
  const [dictionary, setDictionary] = useState<BigidTagWizardDictionary>();
  const [isCreateTagPermitted] = useState<boolean>(
    areAllPermitted([CLUSTER_ANALYSIS_PERMISSIONS.EDIT.name, TAGS_SAVED_QUERIES_PERMISSIONS.CREATE.name]),
  );
  const [isEditTagPermitted] = useState<boolean>(
    areAllPermitted([CLUSTER_ANALYSIS_PERMISSIONS.EDIT.name, TAGS_SAVED_QUERIES_PERMISSIONS.EDIT.name]),
  );
  const [isDeleteTagPermitted] = useState<boolean>(
    areAllPermitted([CLUSTER_ANALYSIS_PERMISSIONS.EDIT.name, TAGS_SAVED_QUERIES_PERMISSIONS.DELETE.name]),
  );

  useEffect(() => {
    if (tags) {
      const clusterTags = tags
        .filter(tagModel => (tagModel.cluster_ids || []).includes(clusterId))
        .map(({ tag_name, tag_value }) => ({
          name: tag_name,
          value: tag_value,
        }));
      setClusterTags(clusterTags);
      const dictionary = getTagsDictionary(
        tags.map(({ tag_name, tag_value }) => ({ name: tag_name, value: tag_value })),
      );
      setDictionary(dictionary);
    }
  }, [clusterId, tags]);

  return (
    <BigidTags
      useMinHeight
      tags={clusterTags}
      tagsDictionary={dictionary}
      isAbleToCreate={isCreateTagPermitted}
      isAbleToEdit={isEditTagPermitted}
      isAbleToDelete={isDeleteTagPermitted}
      onAdd={onAttach}
      onCreate={onAttach}
      onDelete={onDetach}
    />
  );
};
