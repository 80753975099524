import React, { FC, useEffect, useState } from 'react';
import { BigidBody1 } from '@bigid-ui/components';
import { generateDataAid } from '@bigid-ui/utils';
import { styled } from '@mui/material';
import { CurationStatus, getAttributeCurationStatus, getFieldsCurationStatus, StatusEvent } from './curationService';
import { useLocalTranslation } from './translations';
import { CurationStatusEvents, curationStatusEventsEmitter, getReviewedEntitiesProgressIcon } from './curationUtils';

export interface ReviewedEntitiesProgressProps {
  curationStatus: CurationStatus;
  curatedEntityName: string;
  translation: string;
}

const ReviewedEntitiesProgressContainer = styled('div')`
  display: flex;
  align-items: center;
`;

const IconWrapper = styled(BigidBody1)`
  padding-right: 5px;
  padding-top: 5px;
`;

export const ReviewedEntitiesProgress: FC<ReviewedEntitiesProgressProps> = ({
  curationStatus,
  curatedEntityName,
  translation,
}) => {
  const { t } = useLocalTranslation(translation);
  const [updatedStatus, setUpdatedStatus] = useState<CurationStatus>(curationStatus);
  const { curatedCount = 0, totalCount = 0 } = updatedStatus || {};

  useEffect(() => {
    const handleUpdatingStatusData = async (statusEvent: StatusEvent) => {
      if (statusEvent.id === 'fieldsStatus') {
        const fieldsStatus = await getFieldsCurationStatus(statusEvent.filter);
        setUpdatedStatus({
          curatedCount: fieldsStatus?.data?.curationStatus?.curatedCount || 0,
          totalCount: fieldsStatus?.data?.curationStatus?.totalCount || 0,
        });
      }
      if (statusEvent.id === 'attributesStatus') {
        const attributesStatus = await getAttributeCurationStatus(statusEvent.filter);
        setUpdatedStatus({
          curatedCount: attributesStatus?.data?.curationStatus?.curatedCount || 0,
          totalCount: attributesStatus?.data?.curationStatus?.totalCount || 0,
        });
      }
    };

    curationStatusEventsEmitter.addEventListener(CurationStatusEvents.UPDATE_STATUS, handleUpdatingStatusData);

    return () => {
      curationStatusEventsEmitter.removeEventListener(CurationStatusEvents.UPDATE_STATUS, handleUpdatingStatusData);
    };
  }, []);

  return (
    <ReviewedEntitiesProgressContainer>
      <IconWrapper>{getReviewedEntitiesProgressIcon(curatedCount, totalCount)}</IconWrapper>
      <BigidBody1 data-aid={generateDataAid('reviewed-progress', [])}>
        {curatedCount}/{totalCount} {curatedEntityName} {t('reviewed')}
      </BigidBody1>
    </ReviewedEntitiesProgressContainer>
  );
};
