import React from 'react';
import { BigidTooltip } from '@bigid-ui/components';
import { SuggestedActionItem } from './mappers/styles';
import { useLocalTranslation } from './translations';
import type { SuggestedActionButtonType } from './types';

type DataSourceSuggestedActionItemFooterProps = {
  actions: SuggestedActionButtonType[];
};

export const DataSourceSuggestedActionItemFooter = ({
  actions,
}: DataSourceSuggestedActionItemFooterProps): JSX.Element => {
  const { t } = useLocalTranslation();

  return (
    <SuggestedActionItem.Actions>
      {actions?.map(({ component: Button, disabled, ...rest }, index) => (
        <>
          {index ? <SuggestedActionItem.Separator /> : null}
          <BigidTooltip isDisabled={!disabled} title={t('actions.notSupported')}>
            <div>
              <Button size="small" disabled={disabled} {...rest} />
            </div>
          </BigidTooltip>
        </>
      ))}
    </SuggestedActionItem.Actions>
  );
};
