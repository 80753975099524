import React, { FC, useState, useContext } from 'react';
import { BigidTabs, BigidTabsItem } from '@bigid-ui/components';
import styled from '@emotion/styled';
import { generateDataAid } from '@bigid-ui/utils';
import { ActionableInsightsContext } from '../hooks/ActionableInsightsContext';
import { ReducerActions } from '../hooks/ActionableInsightsReducer';

export interface ActionableInsightTabsProps {
  tabs: BigidTabsItem[];
}

const TabsContainer = styled.div({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'space-between',
  '& .MuiTab-root': {
    width: 'auto',
  },
});

export const ActionableInsightTabs: FC<ActionableInsightTabsProps> = ({ tabs }) => {
  const [selectedTabIndex, setSelectedTabIndex] = useState<number>(0);
  const { dispatch } = useContext(ActionableInsightsContext);

  const onTabChange = (value: number, tab: BigidTabsItem) => {
    setSelectedTabIndex(value);
    dispatch({
      type: ReducerActions.UPDATE_ACTIONABLE_INSIGHTS_DATA,
      payload: { caseState: tab.data },
    });
  };

  return (
    <TabsContainer data-aid={generateDataAid('ActionableInsightTabs', ['container'])}>
      <BigidTabs variant="standard" size="small" selectedIndex={selectedTabIndex} tabs={tabs} onChange={onTabChange} />
    </TabsContainer>
  );
};
