import { iamRoles, AwsPolicyType, AwsPolicyStatementItem } from './iamRoles';
import { uniq } from 'lodash';

export const generatePolicyActions = (dsTypes: string[]): string[] => {
  const actions: string[] = [];
  if (dsTypes.includes(AwsPolicyType.ALL)) {
    for (const key in iamRoles) {
      actions.push(...iamRoles[key]);
    }
  } else {
    actions.push(...iamRoles[AwsPolicyType.BASIC]);
    dsTypes.forEach(type => {
      if (iamRoles[type]) {
        actions.push(...iamRoles[type]);
      }
    });
  }

  return uniq(actions);
};

export const getAwsPolicyJson = (dsTypes: string[]): string => {
  const statmentItem: AwsPolicyStatementItem = {
    Sid: 'VisualEditor0',
    Effect: 'Allow',
    Action: generatePolicyActions(dsTypes),
    Resource: '*',
  };

  return JSON.stringify(
    {
      Version: '2012-10-17',
      Statement: [statmentItem],
    },
    null,
    2,
  );
};
