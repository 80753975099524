import { BigidBody1, BigidColorsV2, BigidTextField } from '@bigid-ui/components';
import { styled } from '@mui/material';
import React, { ChangeEvent, Dispatch, FC } from 'react';
import { ColorComponent } from './ColorComponent';
import { useLocalTranslation } from './translations';
import { CategoryDetailsActions, CreateCategory, DetailsActionsTypes } from './types';
import { generateDataAid } from '@bigid-ui/utils';

const FormItem = styled('div')`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const StyledColorInput = styled('input')`
  width: 310px;
`;

interface CreateNewCategoryProps {
  categoryState: CreateCategory;
  dispatchCategoryAction: Dispatch<CategoryDetailsActions>;
  disabled: boolean;
  dataAid?: string;
}

export const CreateNewCategory: FC<CreateNewCategoryProps> = ({
  categoryState,
  dispatchCategoryAction,
  disabled,
  dataAid = 'CreateNewCategory',
}) => {
  const { t } = useLocalTranslation('NewCategory');

  const handleChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    dispatchCategoryAction({
      type: DetailsActionsTypes.SET_CATEGORY_DETAILS_FIELD,
      fieldName: e.target.name as keyof CreateCategory,
      value: e.target.value,
    });
  };

  return (
    <>
      <BigidTextField
        size="medium"
        placeholder={t('textInputPlaceholder')}
        onChange={handleChange}
        name="name"
        disabled={disabled}
        value={categoryState.name}
        dataAid={generateDataAid(dataAid, ['name'])}
      />
      <BigidTextField
        multiline
        disabled={disabled}
        placeholder={t('description')}
        name="description"
        onChange={handleChange}
        value={categoryState.description}
        dataAid={generateDataAid(dataAid, ['description'])}
      />
      <FormItem>
        <BigidBody1 color={BigidColorsV2.gray[700]} alignSelf="center">
          {t('color')}
        </BigidBody1>
        <ColorComponent color={categoryState.color} />
        <StyledColorInput
          type="color"
          value={categoryState.color}
          disabled={disabled}
          name="color"
          onChange={handleChange}
        />
      </FormItem>
    </>
  );
};
