import React, { FunctionComponent, useCallback, useEffect, useMemo, useState } from 'react';
import { BigidLoader, BigidPaper } from '@bigid-ui/components';
import { notificationService } from '../../../services/notificationService';
import {
  BigidGridColumn,
  BigidGridColumnTypes,
  BigidGridWithToolbar,
  BigidGridWithToolbarProps,
  ChipFormatterProps,
  FetchDataFunction,
} from '@bigid-ui/grid';
import { queryService } from '../../../services/queryService';
import { getGroupMembers, GroupMember } from './GroupsService';
import { getCellValueForVisibility } from './Groups';
import { v4 as uuid } from 'uuid';
import { cloneDeep } from 'lodash';

export interface GroupMembersProps {
  name: string;
  email: string;
  dataSource: string;
  entityType: string;
}

export const GroupMembers: FunctionComponent<GroupMembersProps> = ({ name, email, dataSource }) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const columns = useMemo<BigidGridColumn<GroupMember>[]>(
    () => [
      {
        name: 'name',
        title: 'Name',
        getCellValue: ({ name }) => name,
        type: BigidGridColumnTypes.TEXT,
      },
      {
        name: 'email',
        title: 'Email',
        getCellValue: ({ email }) => email,
        type: BigidGridColumnTypes.TEXT,
      },
      {
        name: 'visibility',
        title: 'Visibility',
        getCellValue: ({ external }) => getCellValueForVisibility(external),
        type: BigidGridColumnTypes.CHIP,
      },
      {
        name: 'type',
        title: 'Type',
        getCellValue: ({ entityType }) => getCellValueForMemberType(entityType),
        type: BigidGridColumnTypes.CHIP,
      },
    ],
    [],
  );

  useEffect(() => {
    setIsLoading(true);
    if (email && name) {
      setIsLoading(false);
    }
  }, [email, name]);

  const fetchGridData: FetchDataFunction<GroupMember> = useCallback(
    async queryComponents => {
      try {
        const updatedQueryComp = cloneDeep(queryComponents);
        updatedQueryComp.filter.push({ field: 'dataSource', operator: 'in', value: [dataSource] });
        const gridConfigQuery = queryService.getGridConfigQuery({ ...updatedQueryComp });
        const { result, totalCount } = await getGroupMembers(name, email, gridConfigQuery);

        return {
          data: result.map(member => ({ id: uuid(), ...member })),
          totalCount,
        };
      } catch (err) {
        notificationService.error(`Failed to get group members.`);
        console.error(err);
      }
    },
    [dataSource, email, name],
  );

  const gridWithToolbarConfig: BigidGridWithToolbarProps<any> = useMemo(() => {
    return {
      columns: columns,
      entityName: 'Members',
      fetchData: fetchGridData,
    };
  }, [columns, fetchGridData]);

  return isLoading ? <BigidLoader /> : <BigidGridWithToolbar {...gridWithToolbarConfig} />;
};

export const getCellValueForMemberType = (entityType: string): ChipFormatterProps => {
  const label = entityType === 'GROUP' ? 'Group' : 'User';
  return { chip: { label } };
};
