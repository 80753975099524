import React, { FunctionComponent, useState, useRef, Fragment } from 'react';
import { BigidMenu, BigidMenuItemProps } from '@bigid-ui/components';
import { BigidMenuIcon } from '@bigid-ui/icons';
import { IconButton } from '@mui/material';
import { CustomAppPagesEnum } from '../utils/CustomAppTypes';

type CustomAppMenuProps = {
  onItemSelect?: (item: BigidMenuItemProps) => void;
  className?: string;
  isInteractive: boolean;
  uiTabName: string;
  popMenuDown: boolean;
};

interface MenuItem {
  id: CustomAppPagesEnum;
  label: string;
}

const Menu: FunctionComponent<CustomAppMenuProps> = ({
  onItemSelect,
  className,
  isInteractive,
  uiTabName,
  popMenuDown,
}) => {
  const menuIconRef = useRef(null);
  const [open, setOpen] = useState<boolean>(false);

  const handleMenuItemSelect = (item: BigidMenuItemProps) => {
    onItemSelect?.(item);
    setOpen(false);
  };

  const handleMenuIconClick = () => {
    setOpen(!open);
  };

  const handleOnMenuClose = () => {
    setOpen(false);
  };

  const items: MenuItem[] = [
    {
      id: CustomAppPagesEnum.ACTIONS,
      label: 'Actions',
    },
    {
      id: CustomAppPagesEnum.EDIT_APP,
      label: 'Edit app',
    },
    {
      id: CustomAppPagesEnum.ACTIVITY_LOG,
      label: 'Activity Log',
    },
  ];

  if (isInteractive) {
    items.unshift({
      id: CustomAppPagesEnum.UI,
      label: uiTabName,
    });
  }

  return (
    <Fragment>
      <IconButton
        onClick={handleMenuIconClick}
        className={className}
        data-aid="customAppMenuButton"
        size="large"
        sx={{ height: 'auto' }}
        ref={menuIconRef}
      >
        <BigidMenuIcon />
      </IconButton>
      <BigidMenu
        open={open}
        anchorEl={menuIconRef.current}
        onItemSelect={handleMenuItemSelect}
        items={items}
        onMenuClose={handleOnMenuClose}
        anchorOrigin={{ vertical: popMenuDown ? 'bottom' : 'top', horizontal: 'right' }}
        transformOrigin={{ vertical: popMenuDown ? 'top' : 'bottom', horizontal: 'right' }}
      />
    </Fragment>
  );
};

export const CustomAppMenu = React.memo(Menu);
