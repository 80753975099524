import { noop } from 'lodash';
import { AssignCategoryDialogContent, AssignCategoryDialogContentProps } from './AssignCategoryDialogContent';
import { CuratedAttribute } from '../../../curationService';
import { BigidSystemDialogOptions, openSystemDialog } from '../../../../../services/systemDialogService';
import { getFixedT } from '../../../translations';
export const showAssignCategoryDialog = (attribute: CuratedAttribute, gridId: string): Promise<boolean> => {
  const t = getFixedT(`AssignCategoryDialogContent`);
  return new Promise(resolve => {
    const options: BigidSystemDialogOptions = {
      title: t('dialogTitle'),
      maxWidth: 'xs',
      isContentScrollable: false,
      onClose: noop,
      showCloseIcon: true,
      borderTop: true,
      content: AssignCategoryDialogContent,
      contentProps: {
        attribute,
        gridId,
      },
    };
    openSystemDialog<AssignCategoryDialogContentProps>(options);
  });
};
