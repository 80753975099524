import styled from '@emotion/styled';
import { getFindingIndicatorColorByStatus } from '../../../../curationUtils';
import { CuratedFieldStatus } from '../../../../curationService';

interface AttributePreviewFindingStatusIndicatorProps {
  status: CuratedFieldStatus;
  findingsCount: number;
}

function getFindingIndicatorShadowColorByStatus(reviewStatus: CuratedFieldStatus): string {
  let shadow: string;

  switch (reviewStatus) {
    case CuratedFieldStatus.UNCURATED:
      shadow = 'rgba(0, 17, 255, 0.5)';
      break;
    case CuratedFieldStatus.APPROVED:
      shadow = 'rgba(49, 186, 115, 0.5)';
      break;
    case CuratedFieldStatus.REJECTED:
      shadow = 'rgba(255, 64, 0, 0.5)';
      break;
  }

  return shadow;
}

export const AttributePreviewFindingStatusIndicator = styled('div')<AttributePreviewFindingStatusIndicatorProps>`
  background-color: ${({ status, findingsCount }) =>
    findingsCount > 0 ? getFindingIndicatorColorByStatus(status) : 'transparent'};
  width: 8px;
  height: 8px;
  border: ${({ status }) => `1px solid ${getFindingIndicatorColorByStatus(status)}`};
  border-radius: 50%;
  box-shadow: ${({ status }) => `0px 0px 4px ${getFindingIndicatorShadowColorByStatus(status)}`};
`;
