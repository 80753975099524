import { DateISO8601 } from '../../../../types/types';
import { TagEntity } from '../../../TagsManagement/TagsManagementService';
import { httpService } from '../../../../services/httpService';

export type PartialTagsRecord = Pick<TagEntity, 'tagName' | 'tagId' | 'valueId' | 'tagValue'>;

export enum RequestStatus {
  NEW = 'New',
  CALCULATING = 'Calculating',
  COMPLETED = 'Completed',
  FAILED = 'Failed',
}

export interface SimilarTablesRequest {
  requestId: string;
  fullyQualifiedName: string;
  threshold: number;
  status: RequestStatus;
  isAssignTag: boolean;
  updatedAt: DateISO8601;
  tags: PartialTagsRecord[];
}

export interface SimilarTablesResult {
  id: string;
  fullyQualifiedName: string;
  tableName: string;
  containerName: string;
  numberOfFindings: number;
  attributes: string[];
  tags: TagEntity[];
  dataSourceName: string;
  owner: string;
  similarity: number;
}

export interface SimilarTablesResponse {
  request: SimilarTablesRequest;
  results: SimilarTablesResult[];
  totalResults: number;
}

export interface FindSimilarTablesPayload {
  fullyQualifiedName: string;
  threshold: number;
}

export interface TagSimilarTablesPayload {
  requestId: string;
  catalogTag: PartialTagsRecord;
  totalResults?: number;
}

export const findSimilarTables = (payload: FindSimilarTablesPayload, query?: string) => {
  return httpService
    .post<
      {
        data: SimilarTablesResponse[];
      },
      FindSimilarTablesPayload
    >(`similar-tables/find-similar-tables${query ? `?${query}` : ''}`, payload)
    .then(({ data }) => data?.data?.[0]);
};

export const getLatestSimilarTablesData = (fullyQualifiedName: string) => {
  return httpService
    .fetch<{ data: SimilarTablesResponse[] }>(`similar-tables/${fullyQualifiedName}/latest`)
    .then(({ data }) => data?.data?.[0]);
};

export const tagSimilarTables = (payload: TagSimilarTablesPayload) => {
  return httpService.post(`similar-tables/tag`, payload).then(({ data }) => data);
};
