import React, { createContext, PropsWithChildren, useMemo } from 'react';
import { noop } from 'lodash';
import {
  DEFAULT_CONFIG_DS_STATE,
  useDataSourceConfigState,
} from '../DataSourceConfiguration/hooks/useDataSourceConfigState';
import type { OAuthHandlers } from './hooks/useOAuth';

export type DataSourceConnectionModalContextType = ReturnType<typeof useDataSourceConfigState> & {
  isDsCollaborator: boolean;
  sourceId?: string;
  dsTypeLabel?: string;
  dsType?: string;
  isStandalone?: boolean;
} & OAuthHandlers;

type DataSourceConnectionModalProviderProps = {
  config: ReturnType<typeof useDataSourceConfigState> & OAuthHandlers;
  isDsCollaborator?: boolean;
  sourceId?: string;
  dsTypeLabel?: string;
  dsType?: string;
  isStandalone?: boolean;
} & PropsWithChildren;

export const DataSourceConnectionModalContext = createContext<DataSourceConnectionModalContextType>({
  configDataSourceState: DEFAULT_CONFIG_DS_STATE,
  updateState: noop,
  generateInitValues: noop,
  isDsCollaborator: false,
  isStandalone: false,
  oAuthHandlers: {},
});

export const DataSourceConnectionModalProvider = ({
  children,
  config,
  isDsCollaborator = false,
  isStandalone = false,
  sourceId,
  dsTypeLabel,
  dsType,
}: DataSourceConnectionModalProviderProps): JSX.Element => {
  const value = useMemo(
    () => ({ ...config, isDsCollaborator, isStandalone, sourceId, dsTypeLabel, dsType }),
    [config, dsType, dsTypeLabel, isDsCollaborator, isStandalone, sourceId],
  );
  return (
    <DataSourceConnectionModalContext.Provider value={value}>{children}</DataSourceConnectionModalContext.Provider>
  );
};
