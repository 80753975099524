import React, { FC } from 'react';
import { styled } from '@mui/material';
import { TertiaryButton } from '@bigid-ui/components';
import { generateDataAid } from '@bigid-ui/utils';
import { BigidDataSourceOnlyIcon, BigidAttributeIcon } from '@bigid-ui/icons';
import { CurationStageId } from './useCurationState';
import { useLocalTranslation } from './translations';

export interface CurationDefaultViewSwitcherProps {
  dataAid?: string;
  stageId: CurationStageId.CURATED_SOURCES | CurationStageId.CURATED_ATTRIBUTES;
  onSelect: (stageId: CurationStageId.CURATED_SOURCES | CurationStageId.CURATED_ATTRIBUTES) => void;
}

const Root = styled('div')`
  display: flex;
  background-color: ${({ theme }) => theme.vars.tokens.bigid.backgroundSecondary};
  border: 1px solid ${({ theme }) => theme.vars.tokens.bigid.borderDefault};
  border-radius: 4px;
  padding: 4px;
  gap: 4px;
  align-items: center;
`;

const ButtonContainer = styled('div')<{ isActive: boolean }>`
  background-color: ${({ isActive, theme }) => (isActive ? theme.vars.palette.bigid.white : 'transparent')};
  border-radius: 4px;
`;

export const CurationDefaultViewSwitcher: FC<CurationDefaultViewSwitcherProps> = ({
  dataAid = 'CurationDefaultViewSwitcher',
  stageId = CurationStageId.CURATED_SOURCES,
  onSelect,
}) => {
  const { t } = useLocalTranslation('CurationDefaultViewSwitcher');

  const handleDataSourcesButtonClick = (): void => {
    onSelect(CurationStageId.CURATED_SOURCES);
  };

  const handleAttributesButtonClick = (): void => {
    onSelect(CurationStageId.CURATED_ATTRIBUTES);
  };

  return (
    <Root data-aid={dataAid}>
      <ButtonContainer isActive={stageId === CurationStageId.CURATED_SOURCES}>
        <TertiaryButton
          dataAid={generateDataAid(dataAid, [
            'dataSources',
            stageId === CurationStageId.CURATED_SOURCES ? 'active' : '',
          ])}
          size="small"
          text={t('dataSources')}
          startIcon={<BigidDataSourceOnlyIcon />}
          onClick={handleDataSourcesButtonClick}
        />
      </ButtonContainer>
      <ButtonContainer isActive={stageId === CurationStageId.CURATED_ATTRIBUTES}>
        <TertiaryButton
          dataAid={generateDataAid(dataAid, [
            'attributes',
            stageId === CurationStageId.CURATED_ATTRIBUTES ? 'active' : '',
          ])}
          size="small"
          text={t('attributes')}
          startIcon={<BigidAttributeIcon />}
          onClick={handleAttributesButtonClick}
        />
      </ButtonContainer>
    </Root>
  );
};
