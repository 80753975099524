import { capitalize, isEmpty, uniqBy } from 'lodash';
import { convertNewLineWithHtmlTag, hash } from '../utils/utils';
import { DataSourcesUITrackingEvent } from '../../DataSourcesEventTrackerUtils';
import type { BigidDropdownOption } from '@bigid-ui/components';
import {
  type CollaboratorGridRow,
  type CreateDsCollaboratorItemRequest,
  type DataSourceCollaborator,
  type DeleteDsCollaboratorItemRequest,
  CollaborationEventType,
  DataSourceCollaboratorType,
} from '../types';
import type { BigidGridDataFetchResult } from '@bigid-ui/grid';
import type { UserRole } from '../hooks/ueGetUserRoles';
import type { User } from '../../../../utilities/systemUsersUtils';
import type { CollaborationFieldValues } from '../config/collaboration';
import {
  DataTourStatus,
  type CallbackProps,
  DataTourEvent,
  DataTourAction,
} from '../DataSourceConnectionCollaboration/DataSourceCollaborationGuidedTour';

type GuidedTourEventMapper = {
  condition: (props: CallbackProps) => boolean;
  event: DataSourcesUITrackingEvent;
};

const collaborationGuidedTourEventMapper: GuidedTourEventMapper[] = [
  {
    condition: ({ action, index, status }) =>
      status === DataTourStatus.RUNNING && action === DataTourAction.UPDATE && index === 1,
    event: DataSourcesUITrackingEvent.DS_COLLABORATION_TOUR_START_CLICK,
  },
  {
    condition: ({ action, type, status }) =>
      status === DataTourStatus.FINISHED && action === DataTourAction.CLOSE && type === DataTourEvent.TOUR_END,
    event: DataSourcesUITrackingEvent.DS_COLLABORATION_TOUR_COMPLETE_CLICK,
  },
  {
    condition: ({ action, type, status }) =>
      status === DataTourStatus.SKIPPED && action === DataTourAction.SKIP && type === DataTourEvent.TOUR_END,
    event: DataSourcesUITrackingEvent.DS_COLLABORATION_TOUR_SKIP_CLICK,
  },
];

export const mapDataSourceCollaboratorToBigidGridDataFetchResult = (
  collaborators: DataSourceCollaborator[],
): BigidGridDataFetchResult<CollaboratorGridRow> => ({
  data:
    collaborators?.map(collaborator => ({
      ...collaborator,
      key: collaborator.email,
      childRows: null,
      type: DataSourceCollaboratorType.COLLABORATOR,
      id: collaborator.id ?? hash(collaborator.email),
    })) ?? [],
  totalCount: collaborators?.length ?? 0,
});

export const mapUserToBigidDropdownOption = (users: User[]): BigidDropdownOption[] =>
  users?.map(({ name, id }) => ({ id, displayValue: name, value: name })) ?? [];

export const mapUserRoleToBigidDropdownOption = (
  roles: (UserRole & { description?: string })[],
): BigidDropdownOption[] =>
  roles?.map(({ name, _id, description }) => ({
    displayValue: capitalize(name),
    value: name,
    id: _id ?? name,
    tooltip: description,
  })) ?? [];

export const mapFieldValuesToCreateDsCollaboratorPayload = (
  { collaborators, message }: CollaborationFieldValues,
  dsTypeLabel: string,
  templateName?: string,
  brandName?: string,
): CreateDsCollaboratorItemRequest => {
  const options = uniqBy(collaborators, 'value'); // @info secure against potential duplicate submit
  const emailContent = !isEmpty(message) ? convertNewLineWithHtmlTag(message) : undefined;

  return {
    eventType: CollaborationEventType.ADD_COLLABORATORS,
    additionalProperties: {
      templateName,
      brandName,
      uiBaseUrl: window.location.origin,
      dsTypeLabel: dsTypeLabel,
      collaboratorsInfo:
        options?.map(({ value }) => ({
          email: value,
          emailContent,
        })) ?? [],
    },
  };
};

export const mapFieldValuesToDeleteDsCollaboratorPayload = ({
  collaborators,
}: CollaborationFieldValues): DeleteDsCollaboratorItemRequest => {
  return {
    eventType: CollaborationEventType.DELETE_COLLABORATORS,
    additionalProperties: {
      collaboratorsInfo: collaborators?.map(({ value }) => value) ?? [],
    },
  };
};

export const mapRowToDeleteDsCollaboratorPayload = ({
  email,
}: Partial<CollaboratorGridRow>): DeleteDsCollaboratorItemRequest => {
  return {
    eventType: CollaborationEventType.DELETE_COLLABORATORS,
    additionalProperties: {
      collaboratorsInfo: [email],
    },
  };
};

export const getGuidedTourDataSourceEvent = (props: CallbackProps): DataSourcesUITrackingEvent =>
  collaborationGuidedTourEventMapper.find(({ condition }) => condition(props))?.event;
