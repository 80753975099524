import React, { FC, Fragment, ReactText } from 'react';
import { BigidGridProps, BigidGridRow, FetchDataFunction } from '@bigid-ui/grid';
import { BigidLayout, BigidLayoutConfig, LayoutContentType } from '@bigid-ui/layout';
import { httpService } from '../../../services/httpService';
import { queryService } from '../../../services/queryService';
import { notificationService } from '../../../services/notificationService';
import { $state } from '../../../services/angularServices';
import { isPermitted } from '../../../services/userPermissionsService';
import { dataMinimizationRequestsDefaultColumns } from './DataMinimizationRequestsGridConfiguration';
import { DATA_MINIMIZATION_PERMISSIONS } from '@bigid/permissions';
import { showConfirmationDialog } from '../../../services/confirmationDialogService';
import { FilterParams, showDeletionConfirmationDialog } from '../DataMinimizationObjects/DataMinimizationObjects';
import { useUserPreferences } from '../../../components/hooks/useUserPrefrences';
import { ActionData, BigidFilter, BigidLoader } from '@bigid-ui/components';
import { getApplicationPreference } from '../../../services/appPreferencesService';
import { getDataMinimizationInsights, InsightsType } from '../DataMinimizationInsights/DataMinimizationInsights';

export interface DsarDeletionModel extends BigidGridRow {
  displayName: string;
  uniqueId: string;
  profileName: string;
  requestId: string;
  created_at: string;
  states_str?: string;
  state: string;
  dueInDays: string;
}

export interface DeletionValidationsResponse {
  dataMinimizationRequests: DsarDeletionModel[];
  totalCount: number;
}

export const DataMinimizationRequests: FC = () => {
  const { isReady, preferences, gridColumns /*, filterToolbarConfig*/ } = useUserPreferences({
    stateName: $state.$current.name,
    initialGridColumns: dataMinimizationRequestsDefaultColumns,
  });

  const gridConfig: BigidGridProps<DsarDeletionModel> = {
    customRowIdName: 'requestId',
    columns: gridColumns,
    showSortingControls: true,
    showFilteringControls: false,
    defaultSorting: preferences?.grid?.sort || [{ field: 'created_at', order: 'desc' }],
  };

  const getFilterForDeletion = ({ actionData, queryComponents }: FilterParams) => {
    const { filter: oldFilter = [], allSelected = false, selectedRowIds = [] } = { ...actionData, ...queryComponents };
    const filter: BigidFilter = buildDeletionFilter(oldFilter, allSelected, selectedRowIds);
    const gridConfigQuery = queryService.getGridConfigQuery({ ...queryComponents, filter });
    return { filter, gridConfigQuery };
  };

  const buildDeletionFilter = (filter: BigidFilter = [], allSelected = false, selectedRowIds: ReactText[] = []) => {
    const deletionFilter: BigidFilter = filter ? [...filter] : [];
    if (!allSelected && selectedRowIds?.length) {
      deletionFilter.push({ field: 'requestId', operator: 'in', value: selectedRowIds });
    }
    if (allSelected) {
      deletionFilter.push({ field: 'requestType', value: 'Dsar', operator: 'equal' });
    }
    return deletionFilter;
  };

  const fetchData: FetchDataFunction<DsarDeletionModel> = async queryComponents => {
    let data: DsarDeletionModel[] = [];
    let totalCount = 0;

    try {
      const { gridConfigQuery } = getFilterForDeletion({ queryComponents });
      const {
        data: {
          data: { dataMinimizationRequests, totalCount: count },
        },
      } = await httpService.fetch<{ data: DeletionValidationsResponse }>(
        `data-minimization/requests?${gridConfigQuery}`,
      );

      data = dataMinimizationRequests;
      totalCount = count;
    } catch (e) {
      console.error(e);
      notificationService.error('Could not fetch data. See logs for more information');
    }

    return {
      data,
      totalCount,
    };
  };

  const layoutConfig: BigidLayoutConfig = {
    filter: {
      search: { isQueryLanguage: false, getFreeSearchField: () => 'requestId' },
    },
    content: {
      entityName: 'requests',
      contentTypes: [LayoutContentType.GRID],
      defaultContentType: LayoutContentType.GRID,
      viewConfig: {
        fetchGridData: fetchData,
        gridConfig,
      },
      toolbarActions: [
        {
          label: 'Export Objects',
          execute: async (actionData: ActionData) => {
            try {
              const { gridConfigQuery } = getFilterForDeletion({ actionData });
              httpService.downloadFile(`data-minimization/objects/file-download/export?${gridConfigQuery}`);
            } catch (e) {
              console.error(e);
              notificationService.error('Could not export. See logs for more information');
            } finally {
              return { shouldGridReload: false };
            }
          },
          disable: ({ selectedRowIds }) => selectedRowIds.length === 0,
          show: () => isPermitted(DATA_MINIMIZATION_PERMISSIONS.EXPORT.name),
        },
        {
          label: 'Execute Delete',
          execute: async (actionData: ActionData) => {
            let shouldExecute = false;
            try {
              const { filter, gridConfigQuery } = getFilterForDeletion({ actionData });
              const {
                data: {
                  data: { count: objectsCount },
                },
              } = await httpService.post(`data-minimization/objects/execute/count?${gridConfigQuery}`, {
                query: { filter },
              });

              shouldExecute = await showDeletionConfirmationDialog(objectsCount);
              if (shouldExecute) {
                await httpService.post(`data-minimization/objects/execute-async?${gridConfigQuery}`, {
                  query: { filter },
                });
                notificationService.success(`Execute requests confirmed`);
              }
            } catch (e) {
              console.error(e);
              notificationService.error('Could not execute. See logs for more information');
            } finally {
              return { shouldGridReload: shouldExecute, shouldClearSelection: shouldExecute };
            }
          },
          disable: ({ selectedRowIds }) => selectedRowIds.length === 0,
          show: () => isPermitted(DATA_MINIMIZATION_PERMISSIONS.EXECUTE.name),
        },
        {
          label: 'Download DSAR Report',
          execute: async ({ selectedRowIds }) => {
            try {
              const selectedItem = selectedRowIds[0];
              httpService.downloadFile(
                `/sar/reports/file-download/${selectedItem}?format=csv&filterByScopes=true&excludeObjects=true`,
              );
            } catch (e) {
              console.error(e);
              notificationService.error('Could not download full report. See logs for more information');
            } finally {
              return { shouldGridReload: false, shouldClearSelection: false };
            }
          },
          disable: ({ selectedRowIds }) => selectedRowIds.length !== 1,
          show: () => isPermitted(DATA_MINIMIZATION_PERMISSIONS.EXPORT.name),
        },
        {
          label: 'Cancel Request',
          execute: async ({ selectedRowIds }) => {
            let shouldExecute = false;
            try {
              shouldExecute = await showConfirmationDialog({
                entityNameSingular: 'Data Deletion Request',
                entityNamePlural: 'Data Deletion Requests',
                actionName: 'Cancel Request',
              });

              if (shouldExecute) {
                const selectedItem = selectedRowIds[0];
                await httpService.delete(`data-minimization/requests/${selectedItem}`);
                notificationService.success(`Cancel request running successfully`);
              }
            } catch (e) {
              console.error(e);
              notificationService.error('Could not delete. See logs for more information');
            } finally {
              return { shouldGridReload: shouldExecute, shouldClearSelection: shouldExecute };
            }
          },
          disable: ({ selectedRowIds }) => selectedRowIds.length !== 1,
          show: () => {
            return (
              isPermitted(DATA_MINIMIZATION_PERMISSIONS.CANCEL_REQUEST.name) &&
              getApplicationPreference('ENABLE_CANCEL_DATA_DELETION_REQUEST')
            );
          },
        },
      ],
    },
    ...(getApplicationPreference('SHOW_DATA_DELETION_HIGHLIGHTS') && {
      insights: { component: getDataMinimizationInsights(InsightsType.REQUESTS) },
    }),
  };

  return (
    <Fragment>
      {!isReady && <BigidLoader />}
      {isReady && <BigidLayout config={layoutConfig} />}
    </Fragment>
  );
};
