import { BigidGridColumn, BigidGridColumnTypes, BigidGridWithToolbarProps } from '@bigid-ui/grid';
import { PlannedScanGridRow } from './PlannedScanTypes';
import { fetchDsConnections, getScanOwnerChips, getScanTypeLabel, getScanTypesOptions } from '../ScanUtils';
import { BigidFieldFilterOperator, BigidFilterOptionValue } from '@bigid-ui/components';
import { LoadSearchOptions, loadUserSearchOptions } from '../../ActionCenter/ActionWorkflow/actionWorkflowUtils';
import { ScanScheduleCell } from './ScanScheduleCell/ScanScheduleCell';

export const getPlannedScansInitialGridColumns: () => BigidGridColumn<PlannedScanGridRow>[] = () => [
  {
    name: 'name',
    title: 'Name',
    sortingEnabled: true,
    getCellValue: row => row.name,
    type: BigidGridColumnTypes.TEXT,
  },
  {
    name: 'scanTemplateName',
    title: 'Scan Template Name',
    sortingEnabled: false,
    getCellValue: row => row.scanTemplateName,
    type: BigidGridColumnTypes.TEXT,
  },
  {
    name: 'type',
    title: 'Scan Type',
    sortingEnabled: false,
    getCellValue: row => getScanTypeLabel(row.scanType),
    type: BigidGridColumnTypes.TEXT,
  },
  {
    name: 'scanOwner',
    title: 'Scan Owner',
    sortingEnabled: false,
    getCellValue: ({ owners }) => {
      const filteredOwners = owners?.filter(owner => typeof owner === 'string');
      if (!filteredOwners?.length) {
        return;
      }
      return {
        chips: getScanOwnerChips(filteredOwners),
      };
    },
    type: BigidGridColumnTypes.CHIPS,
    isHiddenByDefault: true,
  },

  //TODO: add next run time of scheduled scan as column
  {
    name: 'schedule',
    title: 'Schedule',
    sortingEnabled: true,
    getCellValue: ScanScheduleCell,
    type: BigidGridColumnTypes.CUSTOM,
  },
];

export const getInitialPlannedScansFilterConfiguration = async (): Promise<
  BigidGridWithToolbarProps<PlannedScanGridRow>['filterToolbarConfig']
> => {
  const dataSources = await fetchDsConnections();

  return {
    filters: [
      {
        title: 'Type',
        field: 'scanType',
        operator: 'in',
        options: getScanTypesOptions(),
        isSelected: true,
        value: [],
        listWidth: 400,
      },
      {
        title: 'Scan Owner',
        field: 'owners',
        operator: 'in' as BigidFieldFilterOperator,
        value: [] as BigidFilterOptionValue,
        isSearchAsync: true,
        loadSearchOptions: loadUserSearchOptions({ isFilter: true }) as LoadSearchOptions,
        options: [],
        disabled: true,
        shouldLoadInitialOptions: true,
        listWidth: 400,
      },
      {
        title: 'Data Sources',
        field: 'dataSourceList',
        operator: 'in',
        options: dataSources,
        isSearchAsync: true,
        loadSearchOptions: fetchDsConnections,
        listWidth: 400,
        value: [],
      },
      {
        title: 'Scheduled',
        field: 'active',
        operator: 'in',
        disabled: true,
        single: true,
        value: [],
        options: [
          {
            label: 'Scheduled',
            value: 'Scheduled',
            isSelected: false,
          },
          {
            label: 'Paused',
            value: 'Paused',
            isSelected: false,
          },
          {
            label: 'Not Scheduled',
            value: 'Not Scheduled',
            isSelected: false,
          },
        ],
        listWidth: 400,
      },
    ],
    //TODO: add option to filter by next running scan
    searchConfig: {
      searchFilterKeys: ['name'],
      initialValue: '',
      placeholder: 'Scan Name',
      operator: 'textSearch',
      autoSubmit: true,
    },
  };
};
