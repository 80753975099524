import {
  BigidFormField,
  BigidFormFieldTypes,
  BigidFormValues,
  BigidSelectOption,
  BooleanMap,
  StringOrBooleanMap,
} from '@bigid-ui/components';
import { DsTypesEnum } from '../DataSources/DataSourceConfiguration/types';

export enum CredentialType {
  BASIC = 'basic',
  JSON = 'json',
  PERSONAL_ACCESS_TOKEN = 'personalAccessToken',
  KERBEROS = 'kerberos',
  KEY = 'key',
  ACCOUNT = 'account',
  OAUTH2 = 'OAuth2',
  ROLE = 'role',
  AAD = 'AAD',
  CREDENTIAL = 'credential',
  STS = 'STS',
}

export const DEFAULT_CREDENTIAL_INITIAL_VALUE = { subType: CredentialType.BASIC, credentialFields: {} };
export const ENCRYPTED_CREDENTIALS_FORMAT = '******';

export interface CredentialField {
  name: string;
  displayName: string;
  type: DsTypesEnum;
  tooltipText?: string;
  isMasked?: boolean;
}

export interface CredentialsTypesMetadata {
  type: CredentialType;
  displayName: string;
  fields: CredentialField[];
}

export interface IdentifiersMapValuesType {
  name: string;
}

export interface CredentialTypeInitialValue {
  subType: CredentialType;
  credentialFields: BigidFormValues;
}

export const dsTypeToFormFieldType: Partial<Record<DsTypesEnum, BigidFormFieldTypes>> = {
  [DsTypesEnum.string]: BigidFormFieldTypes.TEXT,
  [DsTypesEnum.json]: BigidFormFieldTypes.PASSWORD,
  [DsTypesEnum.password]: BigidFormFieldTypes.PASSWORD,
};

export const generateSelectOptions = (metadata: CredentialsTypesMetadata[]): BigidSelectOption[] =>
  metadata.map(({ type, displayName }) => ({ label: displayName, value: type }));

export const getSubTypeSelectOption = (subType: CredentialType, metadata: CredentialsTypesMetadata[]) =>
  generateSelectOptions(metadata).find(({ value }) => value === subType);

const hasJSONError = (displayName: string, payload: string): string | boolean => {
  try {
    if (!payload || payload === ENCRYPTED_CREDENTIALS_FORMAT) {
      return false;
    }

    JSON.parse(payload);
    return false;
  } catch (e) {
    return `${displayName} should be a valid JSON, ${e.message}`;
  }
};

export const generateFormFieldsFromMetadata = (
  selectedType: CredentialType,
  metadata: CredentialsTypesMetadata[],
): BigidFormField[] =>
  metadata
    .find(({ type }) => type === selectedType)
    ?.fields.map(({ name, displayName, type, tooltipText, isMasked }) => {
      const isTextArea = type === DsTypesEnum.json;
      const isPassword = type === DsTypesEnum.password || isMasked;

      return {
        name,
        label: displayName,
        type: dsTypeToFormFieldType[type],
        isRequired: true,
        tooltipText,
        fieldProps: {
          ...(isTextArea && {
            multiline: true,
            rows: 5,
          }),
          ...(isPassword && { showValueOnlyIfDirty: true }),
        },
        validate: (value: string) => {
          if (value === '') {
            return `${displayName} is required`;
          }

          if (isTextArea) {
            return hasJSONError(displayName, value);
          }

          return false;
        },
      };
    });

export const getFormInitialValues = (
  metaData: CredentialsTypesMetadata[],
  initialValues: BigidFormValues,
): BigidFormValues =>
  metaData.reduce<BigidFormValues>((initialValues, { fields }) => {
    const valuesToAdd = fields.reduce<Record<string, string>>((valuesToAdd, { name }) => {
      return { ...valuesToAdd, [name]: '' };
    }, {});
    initialValues = { ...valuesToAdd, ...initialValues };
    return initialValues;
  }, initialValues);

export const hasFormErrors = (errors: StringOrBooleanMap = {}, touchedFields: BooleanMap) => {
  const touchedFieldKeyNames = touchedFields ? Object.keys(touchedFields).filter(key => touchedFields[key]) : [];
  return Object.keys(errors).some(error => touchedFieldKeyNames.includes(error) && !!errors[error]);
};
