import React from 'react';
import { notificationService } from '../../services/notificationService';
import { httpService } from '../../services/httpService';
import { sessionStorageService } from '../../../common/services/sessionStorageService';
import { CountryItem } from './FmsdComponents';
import { FmsdViewsIds } from './FmsdViews';
import { SelectionData, ConnectionsState, ConnectionInfo } from './FmsdPreDiscover/hooks/PreDiscoverReducer';
import { FmsdPreDiscoverCustomizeTypes, FmsdPreDiscoverViewsIds } from './FmsdPreDiscover/FmsdPreDiscoverViews';
import { regulationsConfig } from './FmsdPreDiscover/FmsdPreDiscoverViews/FmsdCustomize/FmsdCategories/FmsdCategoriesConfig';
import { getFixedT } from './translations';
import { MetadataConnectionsResponse } from '../AutoDiscoveryWizard/MetadataConnectionTypes';
import { OAuthConnectionType } from './FmsdPreDiscover/hooks/useOAuthFlowConnection';
import { DataSourcesResponse } from '../DataSources/DataSourceConnections/DataSourceConnectionTypes';

const t = getFixedT('General');
const FMSD_DEFAULT_ERROR_MESSAGE = t('apiErrors.default');
const FMSD_CONFIG_ERROR_MESSAGE = t('apiErrors.config');
const FMSD_COUNTRIES_SESSION_ITEM = 'fmsdCountries';
const FMSD_REGULATIONS_SESSION_ITEM = 'fmsdRegulations';
const REGUALTIONS_FIELDS = JSON.stringify(['name', 'countries', 'flagCode']);

export interface CountryData {
  name: string;
  groupedName: string;
  code: string;
  groupedCode: string;
  displayName: string;
}

export interface RegulationsData {
  name: string;
  _id: string;
  flagCode: string;
  classifiers: string[];
  countries: string[];
  isActive: boolean;
  policies: string[];
}

export interface FmsdState {
  isFirstWizardUsage?: boolean;
  fmsdActiveViewId: string;
  fmsdPreDiscoverActiveViewId: string;
  fmsdPreDiscoverSelectionData: SelectionData;
  fmsdPreDiscoverConnectionsState: ConnectionsState;
}

export enum FmsdConfigKeys {
  ACTIVE_VIEW_ID = 'fmsdActiveViewId',
  PRE_DISCOVER_SELECTION_DATA = 'fmsdPreDiscoverSelectionData',
  PRE_DISCOVER_ACTIVE_VIEW_ID = 'fmsdPreDiscoverActiveViewId',
  PRE_DISCOVER_CONNECTIONS_STATE = 'fmsdPreDiscoverConnectionsState',
}

export type ConfigBaseValues = string | number | boolean | string[] | ConnectionInfo;
export type FmsdConfigValue = ConfigBaseValues | Record<string, ConfigBaseValues>;

export interface FmsdConfigItem {
  name: string;
  value: FmsdConfigValue;
}

export type FmsdConfig = {
  [key in FmsdConfigKeys]?: FmsdConfigValue;
};

export type PoliciesAndClassifiers = Pick<RegulationsData, 'policies' | 'classifiers'>;
export type NamesAndIds = Pick<RegulationsData, 'name' | '_id'>;

const displayFmsdDefaultErrorNotifcation = (e: Error, notificationMessage: string = null) => {
  notificationService.error(notificationMessage ?? FMSD_DEFAULT_ERROR_MESSAGE);
  console.error(e.message);
};

export const fetchCountries = async (): Promise<CountryItem[]> => {
  try {
    let fmsdCountries: CountryItem[] = sessionStorageService.get(FMSD_COUNTRIES_SESSION_ITEM);
    if (!fmsdCountries) {
      const {
        data: { data: options },
      } = await httpService.fetch('countries');
      fmsdCountries = options.map((country: CountryData) => ({
        alpha2: country.groupedCode.toLowerCase().slice(0, 2),
        label: country.groupedName,
        isSelected: false,
        id: country.groupedCode,
      }));
      sessionStorageService.set(FMSD_COUNTRIES_SESSION_ITEM, fmsdCountries);
    }
    return fmsdCountries;
  } catch (e) {
    displayFmsdDefaultErrorNotifcation(e);
  }
};

export const fetchRegulations = async (): Promise<Partial<RegulationsData>[]> => {
  try {
    let fmsdRegulations: Partial<RegulationsData>[] = sessionStorageService.get(FMSD_REGULATIONS_SESSION_ITEM);
    if (!fmsdRegulations) {
      const {
        data: { data },
      } = await httpService.fetch(`regulations?fields=${REGUALTIONS_FIELDS}`);
      fmsdRegulations = data;
      sessionStorageService.set(FMSD_REGULATIONS_SESSION_ITEM, fmsdRegulations);
    }
    return fmsdRegulations;
  } catch (e) {
    displayFmsdDefaultErrorNotifcation(e);
  }
};

export const regulationsToCheckboxOptions = (regulations: Partial<RegulationsData>[]): CountryItem[] => {
  return regulations.map((regulation: Partial<RegulationsData>) => ({
    alpha2: regulation.flagCode?.toLowerCase().slice(0, 2),
    id: regulation.name,
    isSelected: false,
    label: regulation.name,
  }));
};

export const selectAndSortRegulations = (regulations: CountryItem[], selectedRegulations: string[]): CountryItem[] =>
  regulations
    .map(regulation => ({
      ...regulation,
      isSelected: selectedRegulations.includes(`${regulation.id}`),
    }))
    .sort((a, b) => a.label.localeCompare(b.label))
    .sort((a, b) => Number(b.isSelected) - Number(a.isSelected));

export const updateSelectionArray = (id: React.ReactText, checked: boolean, currentSelections: string[]): string[] => {
  const idString = id.toString();
  return checked ? [...currentSelections, idString] : currentSelections.filter(regilation => regilation !== idString);
};

export const persistFmsdState = async (newConfig: FmsdConfig) => {
  try {
    const config: FmsdConfigItem[] = [];
    for (const [key, value] of Object.entries(newConfig)) {
      config.push({ name: key, value });
    }
    const response = await httpService.post('fmsd-config', { config });
    if (response?.status !== 200) {
      throw new Error(FMSD_CONFIG_ERROR_MESSAGE);
    }
  } catch (e) {
    displayFmsdDefaultErrorNotifcation(e);
  }
};

export const fetchFmsdState = async (): Promise<FmsdState> => {
  const defaultSelections: SelectionData = {
    categories: [regulationsConfig[0].name, regulationsConfig[2].name],
    classifiersMethodology: FmsdPreDiscoverCustomizeTypes.CATEGORIES,
    regulations: [],
    uniqueSelectedRegulations: [],
  };
  try {
    const {
      data: {
        data: {
          fmsdActiveViewId = FmsdViewsIds.WELCOME,
          fmsdPreDiscoverActiveViewId = FmsdPreDiscoverViewsIds.CUSTOMIZE,
          fmsdPreDiscoverSelectionData,
          fmsdPreDiscoverConnectionsState,
        },
      },
    } = await httpService.fetch('fmsd-config');
    return {
      isFirstWizardUsage: fmsdPreDiscoverSelectionData ? false : true,
      fmsdActiveViewId,
      fmsdPreDiscoverActiveViewId,
      fmsdPreDiscoverSelectionData: { ...defaultSelections, ...fmsdPreDiscoverSelectionData },
      fmsdPreDiscoverConnectionsState,
    };
  } catch (e) {
    return {
      fmsdActiveViewId: FmsdViewsIds.WELCOME,
      fmsdPreDiscoverActiveViewId: FmsdPreDiscoverViewsIds.CUSTOMIZE,
      fmsdPreDiscoverSelectionData: defaultSelections,
      fmsdPreDiscoverConnectionsState: {},
    };
  }
};

export const resetFmsdProgress = async () => {
  try {
    const config: FmsdConfigItem[] = [
      { name: FmsdConfigKeys.ACTIVE_VIEW_ID, value: FmsdViewsIds.WELCOME },
      { name: FmsdConfigKeys.PRE_DISCOVER_ACTIVE_VIEW_ID, value: FmsdPreDiscoverViewsIds.CUSTOMIZE },
    ];
    const response = await httpService.post('fmsd-config', { config });
    if (response?.status !== 200) {
      throw new Error(FMSD_CONFIG_ERROR_MESSAGE);
    }
  } catch (e) {
    displayFmsdDefaultErrorNotifcation(e, t('apiErrors.restartWizard'));
    throw new Error(e);
  }
};

export const setFmsdActiveView = async (activeViewId: FmsdViewsIds) => {
  try {
    const config: FmsdConfigItem[] = [
      { name: FmsdConfigKeys.ACTIVE_VIEW_ID, value: activeViewId },
      { name: FmsdConfigKeys.PRE_DISCOVER_ACTIVE_VIEW_ID, value: FmsdPreDiscoverViewsIds.CUSTOMIZE },
    ];
    const response = await httpService.post('fmsd-config', { config });
    if (response?.status !== 200) {
      throw new Error(FMSD_CONFIG_ERROR_MESSAGE);
    }
  } catch (e) {
    displayFmsdDefaultErrorNotifcation(e, t('apiErrors.setWizardActiveView'));
    throw new Error(e);
  }
};

export const activateRegulations = async (regulations: string[]) => {
  try {
    if (regulations.length) {
      const response = await httpService.put(`regulations/activate/${encodeURIComponent(JSON.stringify(regulations))}`);
      if (response?.status !== 200) {
        throw new Error(t('apiErrors.activateRegulations'));
      }
    }
  } catch (e) {
    displayFmsdDefaultErrorNotifcation(e);
    throw new Error(e);
  }
};

export const deactivateRegulations = async (regulations: string[]) => {
  try {
    if (regulations.length) {
      const response = await httpService.put(
        `regulations/deactivate/${encodeURIComponent(JSON.stringify(regulations))}`,
      );
      if (response?.status !== 200) {
        throw new Error(t('apiErrors.deactivateRegulations'));
      }
    }
  } catch (e) {
    displayFmsdDefaultErrorNotifcation(e);
    throw new Error(e);
  }
};

export const hasDsConnections = async (): Promise<boolean> => {
  try {
    const {
      data: {
        data: { totalCount },
      },
    } = await httpService.fetch<{ data: DataSourcesResponse }>(`ds-connections?limit=1`);
    return totalCount > 0;
  } catch (e) {
    displayFmsdDefaultErrorNotifcation(e);
    throw new Error(e);
  }
};

export const copyToClipboard = (text: string) => {
  navigator.clipboard.writeText(text).catch(err => {
    console.error(t('apiErrors.copy'), err);
  });
};

export const getDataCategoriesRegulations = async (regulations: string[], fields: string[]): Promise<NamesAndIds[]> => {
  try {
    const response = await httpService.fetch(
      `regulations?fields=${JSON.stringify(fields)}&names=${encodeURIComponent(JSON.stringify(regulations))}`,
    );
    if (response?.status !== 200) {
      throw new Error(t('apiErrors.fetchRegulations'));
    }

    const {
      data: { data },
    } = response;

    data.sort((a: NamesAndIds, b: NamesAndIds) => regulations.indexOf(a.name) - regulations.indexOf(b.name));

    return data;
  } catch (e) {
    displayFmsdDefaultErrorNotifcation(e);
  }
};

export const connectionsFmsdRequest = async () => {
  try {
    const {
      data: {
        data: { dsConnections },
      },
    } = await httpService.fetch<{ data: MetadataConnectionsResponse }>('ds-metadata?&includeScans=true');

    return dsConnections;
  } catch (e) {
    console.error('Failed DsMetadata response', e);
    notificationService.error(t('apiErrors.DsMetadataResponse'));
  }
};

export const checkFmdsState = async () => {
  try {
    const { fmsdPreDiscoverConnectionsState } = await fetchFmsdState();
    return fmsdPreDiscoverConnectionsState;
  } catch {
    console.error('Error while fetching FMSD state');
    notificationService.error(t('apiErrors.fetchingFMSDstate'));
  }
};

export const createDsManualOnFmsd = async (dsName: string, connectionType: OAuthConnectionType) => {
  try {
    await httpService.post('ds_connections', {
      ds_connection: {
        name: dsName,
        type: connectionType,
      },
    });
  } catch (error) {
    console.log(t('apiErrors.createDS'), error);
    notificationService.error(t('apiErrors.createDS'));
  }
};

export const redirectToUrlFromFmsd = async (redirectUrl: string) => {
  try {
    const res = await httpService.fetch(redirectUrl);
    window.open(res.data.url, '_self');
    return true;
  } catch (error) {
    console.log(t('apiErrors.fetchDestinationUrl'));
    notificationService.error(t('apiErrors.fetchDestinationUrl'));
    return false;
  }
};
