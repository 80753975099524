import './deletion-validation-modal.scss';
import template from './deletion-validation-modal.html';

class DeletionValidationModalComponent {
  $onInit() {
    this.formData = {
      isEnabled: false,
      days: this.days || 1,
    };

    this.isDaysValueValid = true;
  }

  onDaysChanged() {
    this.isDaysValueValid = Number.isInteger(this.formData.days) && this.formData.days > 0;
  }

  onSubmit() {
    this.$close({ userSelection: this.formData });
  }
}

angular.module('app').component('deletionValidationModalComponent', {
  template,
  controller: DeletionValidationModalComponent,
  bindings: {
    $close: '&',
    $dismiss: '&',
    days: '<',
  },
});
