import React, { useEffect } from 'react';
import { PrimaryButton } from '@bigid-ui/components';
import { pageHeaderService } from '../../../../common/services/pageHeaderService';
import { useLocalTranslation } from '../translations';
import { getPermissions } from '../utils';
import { AddBoxOutlined } from '@mui/icons-material';
import { generateDataAid } from '@bigid-ui/utils';

interface UsePageHeader {
  handleProxyAdd: () => void;
  shouldShowAction: boolean;
}

export const usePageHeader = ({ handleProxyAdd, shouldShowAction }: UsePageHeader) => {
  const { t } = useLocalTranslation();
  const { isCreatePermitted, isEditPermitted } = getPermissions();

  useEffect(() => {
    pageHeaderService.setTitle({
      pageTitle: t('title'),
      rightSideComponentsContainer: shouldShowAction && (
        <PrimaryButton
          disabled={!isCreatePermitted || !isEditPermitted}
          text={t('buttons.addProxy')}
          size="large"
          dataAid={generateDataAid('BigidLayoutMasterDetails', ['add-new-proxy'])}
          onClick={handleProxyAdd}
          startIcon={<AddBoxOutlined />}
        />
      ),
    });
  }, [handleProxyAdd, isCreatePermitted, isEditPermitted, shouldShowAction, t]);
};
