import { EventEmitter } from '@bigid-ui/utils';
import { DS_ENRICHMENT_INITIATIVE_FIELD, DS_ENRICHMENT_INITIATIVE_FIELD_TITLE } from '../constants/enrichment';
import { getDsEnrichmentInitiativeOptions } from './enrichment';
import type { BigidFilterType } from '@bigid-ui/components';
import type { OnboardingEvent } from '../types';

const options = getDsEnrichmentInitiativeOptions();
const emitter = new EventEmitter<OnboardingEvent>();

export const getGridFilterOverrides = (): BigidFilterType[] =>
  [
    {
      title: DS_ENRICHMENT_INITIATIVE_FIELD_TITLE,
      field: DS_ENRICHMENT_INITIATIVE_FIELD,
      operator: 'equal',
      single: true,
      disabled: true,
      value: null,
      options,
    },
  ] as BigidFilterType[];

export const getOnboardingEventEmitter = () => emitter;
