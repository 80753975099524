import { addNewResources, i18next } from '@bigid-ui/i18n';
import { useTranslation } from 'react-i18next';
import resource from '../translations/locales/en.json';

export const NAMESPACE = 'bigid/common';

export const useLocalTranslation = (keyPrefix?: string) => useTranslation(NAMESPACE, { keyPrefix });

export const getFixedT = (key: string) => i18next.getFixedT(null, NAMESPACE, key);

addNewResources(resource, NAMESPACE, 'en');
