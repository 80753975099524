import { useState } from 'react';
import { uniqueId } from 'lodash';

type UseKeyReturnType = [string, () => void];

export const useKey = (): UseKeyReturnType => {
  const [key, setKey] = useState(() => uniqueId());
  const resetKey = () => setKey(uniqueId());
  return [key, resetKey];
};
