import React, { FC, useEffect, useMemo, useState } from 'react';
import { BigidLayout, BigidLayoutConfig, LayoutContentType } from '@bigid-ui/layout';
import makeStyles from '@mui/styles/makeStyles';
import {
  BigidGridColumn,
  BigidGridColumnTypes,
  BigidGridQueryComponents,
  BigidGridWithToolbarProps,
  ChipFormatterProps,
} from '@bigid-ui/grid';
import { getGroups, GroupRecord, GroupType, remediateParticularGroups } from './GroupsService';
import { queryService } from '../../../services/queryService';
import { BigidColors, BigidLoader } from '@bigid-ui/components';
import { notificationService } from '../../../services/notificationService';
import { isPermitted } from '../../../services/userPermissionsService';
import { APPLICATIONS_PERMISSIONS } from '@bigid/permissions';
import { getDistinctDs, Visibility } from '../UsersPermissions/UsersPermissionsService';
import { remediationIndicatorMapping } from '../UsersPermissions';
import { $state } from '../../../services/angularServices';
import { getAccessIntelligenceInsight } from '../InsightCharts/AccessIntelligenceInsight';
import { AciEntityType } from '../InsightCharts/AccessIntelligenceInsightsService';

const useStyles = makeStyles({
  root: {
    width: '100%',
  },
  contentContainer: {
    display: 'flex',
    overflow: 'hidden',
    borderRadius: '4px 4px 0px 0px',
    boxShadow: BigidColors.containerShadow,
    flexFlow: 'column nowrap',
    flex: '1 1 auto',
    padding: '6px',
  },
});

const goToSingleGroupView = (groupId: string) => {
  $state.go(`groupPermissions`, { id: groupId });
};

export const getCellValueForVisibility = (isExternal: boolean): ChipFormatterProps => {
  if (isExternal === undefined || isExternal === null) {
    return undefined;
  }
  const label = isExternal ? 'External' : 'Internal';
  return { chip: { label } };
};

export const getCellValueForGroupType = (groupType?: string): ChipFormatterProps => {
  if (groupType == undefined) {
    return undefined;
  }
  const label = groupType == 'EMAIL' ? 'Explicit' : GroupType[groupType as keyof typeof GroupType];
  return { chip: { label } };
};

export const GroupsWrapper: FC = () => {
  const classes = useStyles();
  const [filterToolbarConfig, setFilterToolbarConfig] =
    useState<BigidGridWithToolbarProps<GroupRecord>['filterToolbarConfig']>();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const columns: BigidGridColumn<GroupRecord>[] = useMemo(
    () => [
      {
        title: 'Group',
        name: 'name',
        sortingEnabled: true,
        width: 300,
        type: BigidGridColumnTypes.LINK,
        getCellValue: ({ _id, name }) => ({
          link: {
            text: name,
          },
          linkParams: {
            groupId: _id,
          },
        }),
        formatter: {
          onClick: async ({ value }) => {
            const { linkParams } = value;
            goToSingleGroupView(linkParams.groupId);
            return {};
          },
        },
      },
      {
        title: 'Number of Members',
        name: 'membersNumber',
        sortingEnabled: true,
        width: 150,
        type: BigidGridColumnTypes.NUMBER,
        getCellValue: ({ membersCount }) => membersCount,
      },
      {
        title: 'Number of Files',
        name: 'filesNumber',
        sortingEnabled: true,
        width: 150,
        type: BigidGridColumnTypes.NUMBER,
        getCellValue: ({ sharedObjectsCount }) => sharedObjectsCount,
      },
      {
        title: 'Data Source',
        name: 'dataSource',
        width: 200,
        type: BigidGridColumnTypes.TEXT,
        getCellValue: ({ dataSource }) => dataSource,
      },
      {
        title: 'Visibility',
        name: 'visibility',
        sortingEnabled: true,
        width: 150,
        type: BigidGridColumnTypes.CHIP,
        getCellValue: ({ external }) => getCellValueForVisibility(external),
      },
      {
        title: 'Type',
        name: 'groupType',
        sortingEnabled: true,
        width: 150,
        type: BigidGridColumnTypes.CHIP,
        getCellValue: ({ type }) => getCellValueForGroupType(type),
      },
      {
        title: 'Audit status',
        name: 'remediationTask',
        sortingEnabled: true,
        width: 150,
        type: BigidGridColumnTypes.ICON,
        getCellValue: ({ remediationTask }) => {
          return remediationIndicatorMapping.get(remediationTask?.status);
        },
      },
    ],
    [],
  );

  useEffect(() => {
    const getFilterToolbarConfig = async () => {
      setIsLoading(true);
      try {
        const dsList = await getDistinctDs();
        const filterConfig: BigidGridWithToolbarProps<GroupRecord>['filterToolbarConfig'] = {
          searchConfig: {
            searchFilterKeys: ['name'],
            placeholder: 'Name',
            initialValue: '',
            operator: 'contains',
          },
          filters: [
            {
              title: 'Data Sources',
              field: 'dataSource',
              operator: 'in',
              disabled: true,
              options: dsList.map(({ value }) => ({
                label: value,
                value: value,
                isSelected: false,
              })),
              value: [],
            },
            {
              title: 'Visibility',
              field: 'visibility',
              operator: 'in',
              disabled: true,
              options: Object.values(Visibility).map(type => ({
                label: type.toString(),
                value: type.toString(),
                isSelected: false,
              })),
              value: [],
            },
            {
              title: 'Type',
              field: 'type',
              operator: 'in',
              disabled: true,
              options: Object.values(GroupType).map(type => ({
                label: type.toString() == GroupType.EMAIL ? 'Explicit' : type.toString(),
                value: type.toString().toUpperCase(),
                isSelected: false,
              })),
              value: [],
            },
          ],
        };
        setFilterToolbarConfig(filterConfig);
      } catch ({ message }) {
        console.error(`An error has occurred: ${message}`);
        notificationService.error('An error has occurred');
      } finally {
        setIsLoading(false);
      }
    };
    getFilterToolbarConfig();
  }, []);

  const fetchGridData = async (queryComponents: BigidGridQueryComponents) => {
    const query = queryService.getGridConfigQuery(queryComponents);
    const { data } = await getGroups(query);
    return { totalCount: data.totalCount, data: data.groups.map(group => ({ id: group._id, ...group })) };
  };

  const layoutConfig = useMemo(() => {
    return {
      content: {
        entityName: 'Groups',
        contentTypes: [LayoutContentType.GRID],
        viewConfig: {
          fetchGridData,
          filterToolbarConfig,
          gridConfig: {
            columns,
            defaultSorting: [{ field: 'name', order: 'asc' }],
          },
          selectedItemPropsMapping: {
            id: 'id',
            name: 'name',
            email: 'email',
          },
        },
        toolbarActions: [
          {
            label: 'Audit',
            execute: async ({ selectedRowIds: groupIdsToRemediate }) => {
              if (groupIdsToRemediate.length > 0) {
                try {
                  const { created, skipped, updated, dsWithNoOwner } = await remediateParticularGroups(
                    groupIdsToRemediate as string[],
                  );
                  notificationService.success(`created: ${created}, updated: ${updated}, skipped: ${skipped}`);
                  if (dsWithNoOwner?.length) {
                    setTimeout(() => {
                      notificationService.warning(
                        `The following data sources have been skipped as no data source owner is defined: ${dsWithNoOwner}`,
                        { showCloseIcon: true, shouldAutoHide: false },
                      );
                    }, 2000);
                  }
                } catch (err) {
                  notificationService.error('Failed to remediate groups');
                  console.error(err);
                }
              }

              return {
                shouldGridReload: true,
              };
            },
            disable: () => false,
            // todo: add write permissions
            show: ({ selectedRowIds }) =>
              selectedRowIds.length > 0 && isPermitted(APPLICATIONS_PERMISSIONS.READ_ACCESS_INTELLIGENCE.name),
          },
        ],
      },
      insights: {
        component: getAccessIntelligenceInsight(AciEntityType.Groups),
      },
    } as BigidLayoutConfig;
  }, [columns, filterToolbarConfig]);

  return <div className={classes.root}>{isLoading ? <BigidLoader /> : <BigidLayout config={layoutConfig} />}</div>;
};
