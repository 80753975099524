import { httpService } from '../../../services/httpService';
import { AccessTypeSummary, ObjectsByDataSource, ObjectsByType } from './consts';
import { UserPermission } from '../UsersPermissions/UsersPermissionsService';

interface VisibilityInsightResponse {
  data: {
    external: number;
    internal: number;
    na: number;
  };
}

interface AccountTypeInsightResponse {
  data: {
    userAccount: number;
    headlessAccount: number;
    na: number;
  };
}

interface DataSourceInsightResponse {
  data: {
    [name: string]: number;
  };
}

export interface ObjectsByDataSourceResponse {
  summaries: ObjectsByDataSource[];
}

export interface ObjectsByTypeResponse {
  summaryByTypes: ObjectsByType[];
}

export interface ObjectsByAccessTypeSummaryResponse {
  summary: AccessTypeSummary[];
}

export interface TopUsersWithSharedObjectsCountResponse {
  data: UserPermission[];
}

export enum AciEntityType {
  Groups = 'groups',
  Users = 'users',
}

export const getAciEntitiesByVisibilityInsight = (aciEntityType: AciEntityType) => {
  return httpService
    .fetch<VisibilityInsightResponse>(`aci/${aciEntityType}/insights/byVisibility`)
    .then(({ data }) => data.data);
};

export const getAciEntitiesByDataSourceInsight = (aciEntityType: AciEntityType) => {
  return httpService
    .fetch<DataSourceInsightResponse>(`aci/${aciEntityType}/insights/byDataSource`)
    .then(({ data }) => data.data);
};

export const getObjectsByDataSourceInsight = () => {
  return httpService
    .fetch<ObjectsByDataSourceResponse>(`object-summaries/by-data-source?sort=totalObjectsWithPiiAndOpenAccess:-1`)
    .then(({ data }) => data.summaries);
};

export const getObjectsByDataTypeInsight = () => {
  return httpService
    .fetch<ObjectsByTypeResponse>(`object-summaries/by-type`)
    .then(({ data }) => data.summaryByTypes.find(({ type }) => type === 'unstructured'));
};

export const getTopAciEntitiesWithSharedObjects = (aciEntityType: AciEntityType, limit: number) => {
  return httpService
    .fetch<TopUsersWithSharedObjectsCountResponse>(`aci/${aciEntityType}/insights/top-users?limit=${limit}`)
    .then(({ data }) => data.data);
};
