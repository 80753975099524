import { ReactText } from 'react';
import { MetadataSearchResultEntityField } from '../MetadataSearchTypes';

type GetCellContentReturnValue = {
  cellValue: string;
  cellExtraValueCount?: number;
  tooltipValue?: string[];
};

export const getGridCellContentSingular = (
  entity: MetadataSearchResultEntityField,
  noHighlight?: boolean,
): GetCellContentReturnValue => {
  if (!entity) {
    return {
      cellValue: null,
      tooltipValue: [],
    };
  } else {
    const { highlightedValue, value } = entity;

    let cellValue: ReactText;
    const tooltipValue = Array.isArray(value) ? value[0] : value;

    if (highlightedValue && !noHighlight) {
      cellValue = String(highlightedValue);
    } else {
      cellValue = Array.isArray(value) ? value[0] : value;
    }

    return {
      cellValue: cellValue ? String(cellValue) : null,
      tooltipValue: tooltipValue ? [String(tooltipValue)] : [],
    };
  }
};

export const getGridCellContentPlural = (
  entity: MetadataSearchResultEntityField,
  title: string,
  noHighlight?: boolean,
): GetCellContentReturnValue => {
  if (!entity) {
    return {
      cellValue: null,
      cellExtraValueCount: null,
      tooltipValue: [],
    };
  } else {
    const { highlightedValue, value } = entity;

    let cellValue: string;
    let cellExtraValueCount: number;
    let tooltipValue: string[];

    if (highlightedValue && !noHighlight) {
      cellValue = highlightedValue;
      if (Array.isArray(value)) {
        if (value.length > 1) {
          cellExtraValueCount = value.length - 1;
        }
        tooltipValue = value.filter(valueEntity => Boolean(valueEntity)).map(valueEntity => String(valueEntity));
      } else {
        tooltipValue = value ? [String(value)] : [];
      }
    } else {
      if (Array.isArray(value)) {
        if (value.length > 1) {
          cellValue = `${value.length} ${title}`;
        } else {
          cellValue = value[0] ? String(value[0]) : null;
        }
        tooltipValue = value.filter(valueEntity => Boolean(valueEntity)).map(valueEntity => String(valueEntity));
      } else {
        cellValue = value ? String(value) : null;
      }
    }

    return { cellValue, cellExtraValueCount, tooltipValue };
  }
};
