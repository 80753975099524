import { getAllRoles } from '../../services/userPermissionsService';
import { analyticsService } from '../../services/analyticsService';
import { TestConnectionErrorResolution, PreConnectionData } from './DataSourceConnectionModal/hooks/useTestConnection';
import { DsConnectionDocPreConnectionType } from './DataSourceConnectionModal/types';

const PRE_CONNECTION_TYPE_FIELD_NAME = 'preConnectionType';

interface SanitizedDataSource {
  name?: string;
  enabled?: boolean;
  tags?: Array<string>;
  description?: string;
  aws_region?: string;
  type?: string;
  is_idsor_supported?: boolean;
  ignore_types?: string;
  scanner_group?: string;
  rdb_is_scan_views?: boolean;
  location?: string;
  security_tier?: number;
  rdb_sample_data_max_size?: number;
  numberOfParsingThreads?: number;
  enrichment_is_enabled?: boolean;
  metadataAclScanEnabled?: boolean;
  classification_is_enabled?: boolean;
  structured_clustering_enabled?: boolean;
  differential?: boolean;
  is_modified_in_x_days?: boolean;
  scanWindowName?: string;
  enableAcl?: boolean;
  dsAclScanEnabled?: boolean;
  isSample?: boolean;
  Is_sample_files?: boolean;
  Is_sample_folders?: boolean;
  rdb_is_sample_data?: boolean;
  testConnectionSuccess?: boolean;
  creationSuccess?: boolean;
  authStrategy?: string;
  numOfTroubleshootingOptionsReturned?: number;
  userRoles: string[];
}

interface DsData {
  broadcastEvent: string;
  ds_connection: SanitizedDataSource;
  isNewConnection: boolean;
  isNewPassword: boolean;
  isNewSessionToken: boolean;
  preConnectionData?: PreConnectionData[];
  isFirstAttempt?: boolean;
  errorResolution?: TestConnectionErrorResolution;
  numOfTroubleshootingOptionsAvailable: number;
}

interface SanitizedTestConnectionData {
  dsType: string;
  authType: string;
  preRequistesAvailable: boolean;
  howToConnectAvailable: boolean;
  testConnectionSuccess: boolean;
  isFirstAttempt: boolean;
  numOfTroubleshootingOptionsReturned: number;
  numOfTroubleshootingOptionsAvailable: number;
  userRoles: string[];
}

export enum DataSourcesUITrackingEvent {
  CONFIG_DATA_SOURCE_TEST_CONNECTION_CLICK = 'CONFIG_DATA_SOURCE_TEST_CONNECTION_CLICK',
  ADMINISTRATION_DATA_SOURCES_BULK_TEST_CONNECTION_CLICK = 'ADMINISTRATION_DATA_SOURCES_BULK_TEST_CONNECTION_CLICK',
  ADMINISTRATION_DATA_SOURCES_ADD_DATA_SOURCE_CLICK = 'ADMINISTRATION_DATA_SOURCES_ADD_DATA_SOURCE_CLICK',
  ADMINISTRATION_DATA_SOURCES_NEW_LAYOUT_PAGE_VIEW = 'ADMINISTRATION_DATA_SOURCES_NEW_LAYOUT_PAGE_VIEW',
  DATA_SOURCES_INIT_SELECT_DS_CLICK = 'DATA_SOURCES_INIT_SELECT_DS_CLICK',
  CONFIG_DATA_SOURCE_NEW_SAVE_CLICK = 'CONFIG_DATA_SOURCE_NEW_SAVE_CLICK',
  DATA_SOURCES_NEW_CONNECTION_PAGE_VIEW = 'DATA_SOURCES_NEW_CONNECTION_PAGE_VIEW',
  DATA_SOURCES_NEW_CONNECTION_CONNECT_CLICK = 'DATA_SOURCES_NEW_CONNECTION_CONNECT_CLICK',
  DATA_SOURCES_NEW_CONNECTION_SAVE_AS_DRAFT_CLICK = 'DATA_SOURCES_NEW_CONNECTION_SAVE_AS_DRAFT_CLICK',
  DATA_SOURCES_NEW_CONNECTION_CLOSE_SAVE_AS_DRAFT_CLICK = 'DATA_SOURCES_NEW_CONNECTION_CLOSE_SAVE_AS_DRAFT_CLICK',
  DATA_SOURCES_NEW_CONNECTION_CLOSE_CLICK = 'DATA_SOURCES_NEW_CONNECTION_CLOSE_CLICK',
  DATA_SOURCES_NEW_CONNECTION_SEE_DOCS_CLICK = 'DATA_SOURCES_NEW_CONNECTION_SEE_DOCS_CLICK',
  DATA_SOURCES_NEW_CONNECTION_SEE_LOGS_CLICK = 'DATA_SOURCES_NEW_CONNECTION_SEE_LOGS_CLICK',
  DATA_SOURCES_NEW_CONNECTION_CONTACT_SUPPORT_CLICK = 'DATA_SOURCES_NEW_CONNECTION_CONTACT_SUPPORT_CLICK',
  DATA_SOURCES_NEW_CONNECTION_SUCCESS_PAGE_VIEW = 'DATA_SOURCES_NEW_CONNECTION_SUCCESS_PAGE_VIEW',
  DATA_SOURCES_NEW_CONNECTION_SUCCESS_VIEW_RESULTS_CLICK = 'DATA_SOURCES_NEW_CONNECTION_SUCCESS_VIEW_RESULTS_CLICK',
  DATA_SOURCES_NEW_CONNECTION_SUCCESS_CREATE_CUSTOM_SCAN_CLICK = 'DATA_SOURCES_NEW_CONNECTION_SUCCESS_CREATE_CUSTOM_SCAN_CLICK',
  DATA_SOURCES_NEW_CONNECTION_SUCCESS_SCAN_NOW_CLICK = 'DATA_SOURCES_NEW_CONNECTION_SUCCESS_SCAN_NOW_CLICK',
  DATA_SOURCES_NEW_CONNECTION_SUCCESS_CLOSE_CLICK = 'DATA_SOURCES_NEW_CONNECTION_SUCCESS_CLOSE_CLICK',
  DATA_SOURCES_NEW_CONNECTION_TROUBLESHOOTING_RATE = 'DATA_SOURCES_NEW_CONNECTION_TROUBLESHOOTING_RATE',
  DATA_SOURCES_NEW_CONNECTION_TOAST_NOTIFICATION_VIEW_DETAILS_CLICK = 'DATA_SOURCES_NEW_CONNECTION_TOAST_NOTIFICATION_VIEW_DETAILS_CLICK',
  DATA_SOURCES_NEW_CONNECTION_TOAST_NOTIFICATION_SCAN_NOW_CLICK = 'DATA_SOURCES_NEW_CONNECTION_TOAST_NOTIFICATION_SCAN_NOW_CLICK',
  DATA_SOURCES_NEW_CONNECTION_TAB_ERROR_TEST_AGAIN_CLICK = 'DATA_SOURCES_NEW_CONNECTION_TAB_ERROR_TEST_AGAIN_CLICK',
  DATA_SOURCES_NEW_CONNECTION_TAB_ERROR_VIEW_DETAILS_CLICK = 'DATA_SOURCES_NEW_CONNECTION_TAB_ERROR_VIEW_DETAILS_CLICK',
  DATA_SOURCES_NEW_CONNECTION_TAB_CONNECTED_TEST_AGAIN_CLICK = 'DATA_SOURCES_NEW_CONNECTION_TAB_CONNECTED_TEST_AGAIN_CLICK',
  DATA_SOURCES_NEW_CONNECTION_TAB_CONNECTED_VIEW_CONNECTION_DETAILS_CLICK = 'DATA_SOURCES_NEW_CONNECTION_TAB_CONNECTED_VIEW_CONNECTION_DETAILS_CLICK',
  DATA_SOURCES_NEW_CONNECTION_TAB_CONNECTED_VIEW_EDIT_CONNECTION_CLICK = 'DATA_SOURCES_NEW_CONNECTION_TAB_CONNECTED_VIEW_EDIT_CONNECTION_CLICK',
  DATA_SOURCES_NEW_CONNECTION_TAB_DRAFT_CONNECT_CLICK = 'DATA_SOURCES_NEW_CONNECTION_TAB_DRAFT_CONNECT_CLICK',
  DS_COLLABORATION_ASSIST_WITH_COLLABORATION_CLICK = 'DS_COLLABORATION_ASSIST_WITH_COLLABORATION_CLICK',
  DS_COLLABORATION_INVITE_CLICK = 'DS_COLLABORATION_INVITE_CLICK',
  DS_COLLABORATION_CUSTOMIZE_NOTE_CLICK = 'DS_COLLABORATION_CUSTOMIZE_NOTE_CLICK',
  DS_COLLABORATION_NAVIGATE_TO_ACCESS_MANAGEMENT_CLICK = 'DS_COLLABORATION_NAVIGATE_TO_ACCESS_MANAGEMENT_CLICK',
  DS_COLLABORATION_NAVIGATE_TO_EMAIL_SETUP_CLICK = 'DS_COLLABORATION_NAVIGATE_TO_EMAIL_SETUP_CLICK',
  DS_COLLABORATION_RESEND_INVITATION_CLICK = 'DS_COLLABORATION_RESEND_INVITATION_CLICK',
  DS_COLLABORATION_DELETE_COLLABORATOR_CLICK = 'DS_COLLABORATION_DELETE_COLLABORATOR_CLICK',
  DS_COLLABORATION_TOUR_START_CLICK = 'DS_COLLABORATION_TOUR_START_CLICK',
  DS_COLLABORATION_TOUR_COMPLETE_CLICK = 'DS_COLLABORATION_TOUR_COMPLETE_CLICK',
  DS_COLLABORATION_TOUR_SKIP_CLICK = 'DS_COLLABORATION_TOUR_SKIP_CLICK',
  DS_FUNNEL_CHART_CLICK = 'DS_FUNNEL_CHART_CLICK',
  SUGGESTED_ACTIONS_FILTER_CLICK = 'SUGGESTED_ACTIONS_FILTER_CLICK',
  SUGGESTED_ACTIONS_ACTION_CLICK = 'SUGGESTED_ACTIONS_ACTION_CLICK',
  SUGGESTED_ACTIONS_GRID_ACTION_CLICK = 'SUGGESTED_ACTIONS_GRID_ACTION_CLICK',
  SUGGESTED_ACTIONS_VIEW_SUGGESTIONS_CLICK = 'SUGGESTED_ACTIONS_VIEW_SUGGESTIONS_CLICK',
}

export const getSanitizedDataSource = (
  dsConnection: Partial<SanitizedDataSource>,
  userRoles?: string[],
): SanitizedDataSource => {
  return {
    name: dsConnection?.name,
    enabled: dsConnection?.enabled,
    tags: dsConnection?.tags,
    description: dsConnection?.description,
    aws_region: dsConnection?.aws_region,
    type: dsConnection?.type,
    is_idsor_supported: dsConnection?.is_idsor_supported,
    ignore_types: dsConnection?.ignore_types,
    scanner_group: dsConnection?.scanner_group,
    rdb_is_scan_views: dsConnection?.rdb_is_scan_views,
    location: dsConnection?.location,
    security_tier: dsConnection?.security_tier,
    rdb_sample_data_max_size: dsConnection?.rdb_sample_data_max_size,
    numberOfParsingThreads: dsConnection?.numberOfParsingThreads,
    enrichment_is_enabled: dsConnection?.enrichment_is_enabled,
    metadataAclScanEnabled: dsConnection?.metadataAclScanEnabled,
    classification_is_enabled: dsConnection?.classification_is_enabled,
    structured_clustering_enabled: dsConnection?.structured_clustering_enabled,
    differential: dsConnection?.differential,
    is_modified_in_x_days: dsConnection?.is_modified_in_x_days,
    scanWindowName: dsConnection?.scanWindowName,
    enableAcl: dsConnection?.enableAcl,
    dsAclScanEnabled: dsConnection?.dsAclScanEnabled,
    isSample: dsConnection?.rdb_is_sample_data || dsConnection?.Is_sample_folders || dsConnection?.Is_sample_files,
    creationSuccess: dsConnection?.creationSuccess,
    testConnectionSuccess: dsConnection?.testConnectionSuccess,
    userRoles,
  };
};

export const hasObjectWithPropertyValue = (arrayOfObjects: PreConnectionData[], property: string, value: string) => {
  return arrayOfObjects.some(obj => obj.hasOwnProperty(property) && obj[property] === value);
};

export const getSanitizedTestConnectionData = (dsData: Partial<DsData>): SanitizedTestConnectionData => {
  return {
    dsType: dsData?.ds_connection?.type,
    authType: dsData?.ds_connection?.authStrategy,
    testConnectionSuccess: dsData?.ds_connection?.testConnectionSuccess,
    isFirstAttempt: dsData?.isFirstAttempt,
    preRequistesAvailable: hasObjectWithPropertyValue(
      dsData?.preConnectionData || [],
      PRE_CONNECTION_TYPE_FIELD_NAME,
      DsConnectionDocPreConnectionType.PREREQUISITES,
    ),
    howToConnectAvailable: hasObjectWithPropertyValue(
      dsData?.preConnectionData || [],
      PRE_CONNECTION_TYPE_FIELD_NAME,
      DsConnectionDocPreConnectionType.HOW_TO_CONNECT,
    ),
    numOfTroubleshootingOptionsReturned: dsData?.ds_connection?.numOfTroubleshootingOptionsReturned,
    numOfTroubleshootingOptionsAvailable: dsData?.numOfTroubleshootingOptionsAvailable,
    userRoles: Object.keys(getAllRoles()) ?? [],
  };
};

export const trackEventDataSources = (eventType: DataSourcesUITrackingEvent, data?: Record<string, any>) => {
  analyticsService.trackManualEvent(eventType as any, data);
};

export const trackDataSourceTestConnectionBiEvent = (dsConnection: Partial<SanitizedDataSource>) => {
  trackEventDataSources(
    DataSourcesUITrackingEvent.CONFIG_DATA_SOURCE_TEST_CONNECTION_CLICK,
    getSanitizedDataSource(dsConnection, Object.keys(getAllRoles()) ?? []),
  );
};

export const trackTestConnectionNewExpBiEvent = (dsData: Partial<DsData>) => {
  trackEventDataSources(
    DataSourcesUITrackingEvent.DATA_SOURCES_NEW_CONNECTION_CONNECT_CLICK,
    getSanitizedTestConnectionData(dsData),
  );
};

export const trackCreateNewDataSourceBiEvent = (dsConnection: Partial<SanitizedDataSource>) => {
  trackEventDataSources(
    DataSourcesUITrackingEvent.CONFIG_DATA_SOURCE_NEW_SAVE_CLICK,
    getSanitizedDataSource(dsConnection),
  );
};
