import '../../react/views/DSAR/SarProfileSettings/SarScansDialog/SarScansDialog';
import './pendingSubjectRequests.component.scss';
import template from './pendingSubjectRequests.component.html';
import { module } from 'angular';
import { openSearchAttributesInfoDialog } from '../../react/components/SearchAttributesInfo/searchAttributesInfoService';
import { isPermitted } from '../../react/services/userPermissionsService';
import { DSAR_PERMISSIONS } from '@bigid/permissions';
const app = module('app');

const getCell = (link = '') => `<div class="ui-grid-cell-contents ">
    ${link}
    <span title=""
    tooltip-class="bigid-uib-tooltip-styles"
    tooltip-placement="center top"
    tooltip-append-to-body="true"
    uib-tooltip="{{COL_FIELD}}"
  >{{COL_FIELD}}</span>
</div>`;

app.component('pendingSubjectRequests', {
  template,
  controller: function ($scope, subjectAccessRequestService, DeleteConfirmation, notificationService) {
    'ngInject';

    const updateJitResults = () => {
      this.onScanrefreshdate();
      this.showGrid = true;

      if (this.jitScanResults && this.profiles) {
        this.grid.data = this.jitScanResults.map(item => {
          item.profileNameToDisplay = item.profile_name;
          if (item.profile_name) {
            const profile = this.profiles.find(profile => profile._id === item.profile_id);
            item.profileNameToDisplay = profile ? item.profile_name : '';
          }
          return item;
        });
      }
    };

    this.$onInit = () => {
      this.showGrid = false;
      $scope.hasDownloadPermission = isPermitted(DSAR_PERMISSIONS.DOWNLOAD_REQUEST_REPORT.name);
      $scope.hasStopPermission = isPermitted(DSAR_PERMISSIONS.STOP_REQUEST.name);
      $scope.hasDeletePermission = isPermitted(DSAR_PERMISSIONS.DELETE_REQUEST.name);
      $scope.shouldShowResume = entity =>
        isPermitted(DSAR_PERMISSIONS.SUBMIT_REQUEST.name) && !entity?.bulkId && entity?.statuses?.Paused;
      $scope.shouldShowPause = entity =>
        isPermitted(DSAR_PERMISSIONS.SUBMIT_REQUEST.name) && !entity?.bulkId && !entity?.statuses?.Paused;

      this.initExistingSubjectAccessRequestModel();

      this.grid.onRegisterApi = gridApi => {
        $scope.gridApi = gridApi;
        gridApi.edit.on.afterCellEdit($scope, () => {
          $scope.$apply();
        });
      };
    };

    this.$onChanges = changesObj => {
      const { jitScanResults, profiles } = changesObj;
      if (jitScanResults || profiles) {
        updateJitResults();
      }
    };

    this.initExistingSubjectAccessRequestModel = () => {
      //unique_id, name, residency
      this.grid = {
        enableSorting: true,
        enablePaginationControls: false,
        columnDefs: [
          {
            name: 'Display name',
            field: 'identity_display_name',
            cellTemplate: getCell(),
            width: '*',
          },
          {
            name: 'Unique Id',
            displayName: 'Unique ID',
            field: 'identity_unique_id',
            cellTemplate: getCell(),
            width: '*',
          },
          {
            name: 'Profile',
            displayName: 'Profile',
            field: 'profileNameToDisplay',
            cellTemplate: getCell(),
            maxWidth: 200,
          },
          {
            name: 'Request Id',
            displayName: 'Request ID',
            field: 'request_id',
            cellTemplate: getCell(
              `<a href ng-click="grid.appScope.$ctrl.openSearchAttributesInfo(row.entity.identity_display_name ,row.entity.request_id)">`,
            ),
            maxWidth: 200,
            width: 200,
          },
          {
            name: 'Submit Date',
            field: 'created_at',
            cellTemplate: getCell(),
            maxWidth: 200,
          },
          {
            name: 'Progress',
            field: 'statuses_str',
            filterHeaderTemplate: `<ui-grid-bigid-text-filter/>`,
            minWidth: 170,
            width: '*',
            cellTemplate: getCell(`<a href ng-click="grid.appScope.$ctrl.openSarScansDialog(row.entity)">`),
          },
          {
            name: 'Actions',
            enableSorting: false,
            enableCellEdit: false,
            enableFiltering: false,
            enableColumnMenu: false,
            width: '14%',
            maxWidth: 140,
            cellTemplate: `
              <div class="actions-container">
                <div class="three-points"></div>
                <div class="img-container">
                  <div
                    class="img-button img-stop-button"
                    ng-if="grid.appScope.hasStopPermission"
                    ng-click="grid.appScope.stop(row.entity)"
                    tooltip-class="bigid-uib-tooltip-styles"
                    tooltip-placement="center top"
                    tooltip-append-to-body="true"
                    uib-tooltip="Stop"></div>
                  <div
                    class="img-button img-pause-button"
                    ng-if="grid.appScope.shouldShowPause(row.entity)"
                    ng-click="grid.appScope.pause(row.entity)"
                    tooltip-class="bigid-uib-tooltip-styles"
                    tooltip-placement="center top"
                    tooltip-append-to-body="true"
                    uib-tooltip="Pause"></div>
                  <div
                    class="img-button img-resume-button"
                    ng-if="grid.appScope.shouldShowResume(row.entity)"
                    ng-click="grid.appScope.resume(row.entity)"
                    tooltip-class="bigid-uib-tooltip-styles"
                    tooltip-placement="center top"
                    tooltip-append-to-body="true"
                    uib-tooltip="Resume"></div>
                  <div
                    class="img-button img-download-button"
                    ng-if="grid.appScope.hasDownloadPermission"
                    ng-click="grid.appScope.download(row.entity)"
                    tooltip-class="bigid-uib-tooltip-styles"
                    tooltip-placement="center top"
                    tooltip-append-to-body="true"
                    uib-tooltip="Download"></div>
                  <div
                    class="img-button img-delete-button"
                    ng-if="grid.appScope.hasDeletePermission"
                    ng-click="grid.appScope.delete(row.entity)"
                    tooltip-class="bigid-uib-tooltip-styles"
                    tooltip-placement="center top"
                    tooltip-append-to-body="true"
                    uib-tooltip="Delete"></div>
                  <div><div></div></div>
                </div>
              </div>`,
          },
        ],
      };
    };

    $scope.download = entity => {
      subjectAccessRequestService.getJITCsvFile(entity.request_id).catch(() => {
        notificationService.error('Download File Failed! ');
      });
    };

    $scope.stop = entity => {
      const modalOptions = {
        closeButtonText: 'Close',
        actionButtonText: 'Stop',
        headerText: 'Stop Existing Data Subject Access Request',
        bodyText: 'Are you sure you want to stop scan related to userID: ' + entity.request_id + '?',
      };

      DeleteConfirmation.showModal({}, modalOptions).then(() => {
        subjectAccessRequestService.stopJitScan(entity.request_id).then(
          () => {
            this.getJitScans();
            notificationService.success(`Request ${entity.request_id} Stopped Successfully! `);
          },
          () => {
            notificationService.error(`Stop Scan Failed For Request ${entity.request_id}!`);
          },
        );
      });
    };

    $scope.pause = entity => {
      const modalOptions = {
        closeButtonText: 'Cancel',
        actionButtonText: 'Pause',
        headerText: `Pause request ${entity.request_id}`,
        bodyText: 'Are you sure that you want to initiate a pause process for the request?',
      };

      DeleteConfirmation.showModal({}, modalOptions).then(() => {
        subjectAccessRequestService.pauseJitScan(entity.request_id).then(
          ({ data: { pausedPartsNumber } }) => {
            this.getJitScans();
            notificationService.success(`Paused ${pausedPartsNumber} Objects for Request ${entity.request_id}`);
          },
          () => {
            notificationService.error(`Paused Failed For Request ${entity.request_id}!`);
          },
        );
      });
    };

    $scope.resume = entity => {
      const modalOptions = {
        closeButtonText: 'Cancel',
        actionButtonText: 'Resume',
        headerText: `Resume request ${entity.request_id}`,
        bodyText: 'Are you sure that you want to initiate a resume process for the request?',
      };

      DeleteConfirmation.showModal({}, modalOptions).then(() => {
        subjectAccessRequestService.resumeJitScan(entity.request_id).then(
          ({ data: { resumePartsNumber } }) => {
            this.getJitScans();
            notificationService.success(`Resume ${resumePartsNumber} Objects for Request ${entity.request_id}`);
          },
          () => {
            notificationService.error(`Resume Failed For Request ${entity.request_id}!`);
          },
        );
      });
    };

    $scope.delete = entity => this.deleteScanItem({ entity });

    this.closeSarScansDialog = () => {
      $scope.$evalAsync(() => {
        this.sarScansDialog = {
          autoRefresh: false,
          isOpen: false,
          onClose: () => {
            return;
          },
          parentScanId: null,
        };
      });
    };

    this.openSarScansDialog = scanObject => {
      this.sarScansDialog = {
        autoRefresh: true,
        isOpen: true,
        parentScanId: scanObject.request_id,
        onClose: () => {
          this.closeSarScansDialog();
        },
        displayName: scanObject.identity_display_name,
      };
    };

    this.openSearchAttributesInfo = (name, requestId) => {
      openSearchAttributesInfoDialog(name, requestId);
    };
  },
  bindings: {
    onScanrefreshdate: '&',
    profiles: '<',
    getJitScans: '&',
    deleteScanItem: '&',
    jitScanResults: '<',
  },
});
