import React from 'react';
import { BigidBody1, PrimaryButton, SecondaryButton } from '@bigid-ui/components';
import { openSystemDialog } from '../../../../services/systemDialogService';
import { $state } from '../../../../services/angularServices';
import * as dsarService from '../../dsarService';
import { CONFIG } from '../../../../../config/common';
import { openRetrievedFilesDialog, RetrieveFileStatus } from './RetrievedFilesDialog';
import { DsarScanResultEntity } from '../dsarRequestsTypes';

export interface Part {
  total: number;
  queue: number;
  inProgress: number;
  failed: number;
  completed: number;
}

export type PartsInfo = {
  [key: string]: {
    partsInfo: Part;
    state: RetrieveFileStatus;
  };
};

export interface RetrievedFiles {
  partsInfo: PartsInfo;
}

export interface RetrieveFilesProgress {
  total: number;
  percentage: number;
}

const getRetrievedFiles = (requestId: string) => {
  dsarService.getRetrieveFiles(requestId);
};

const openRetrieveFilesDialog = (request: any) => {
  openSystemDialog({
    maxWidth: 'xs',
    title: 'Retrieve Files',
    content: () => (
      <BigidBody1>
        You are about to retrieve files containing personal information of {request.identity_display_name} to the target
        Data Source.
        <br />
        Are you sure you wish to proceed?
      </BigidBody1>
    ),
    buttons: [
      {
        text: 'Cancel',
        component: SecondaryButton,
        onClick: () => null,
        isClose: true,
      },
      {
        text: 'Retrieve',
        dataAid: 'DSAR-file-retrieval-retrieve',
        component: PrimaryButton,
        onClick: () => {
          getRetrievedFiles(request.request_id);
        },
        isClose: true,
      },
    ],
    onClose: () => null,
  });
};

const navToSettings = () => {
  openSystemDialog({
    maxWidth: 'xs',
    title: 'Missing Destination Repository',
    content: () => (
      <BigidBody1>
        To retrieve files, first you need to select a destination repository and enter a destination path.
      </BigidBody1>
    ),
    buttons: [
      {
        text: 'Cancel',
        component: SecondaryButton,
        onClick: () => null,
        isClose: true,
      },
      {
        dataAid: 'DSAR-file-retrieval-go-to-settings',
        text: 'Go to settings',
        component: PrimaryButton,
        onClick: () => $state.go(CONFIG.states.ACCESS_REQUEST_MANAGEMENT, { path: 'files-retrieval' }),
        isClose: true,
      },
    ],
    onClose: () => null,
  });
};

export const openCollectFilesFlowDialog = async (
  request: DsarScanResultEntity,
  retrievedFiles: PartsInfo | undefined,
) => {
  const isValid = await dsarService.isFileRetrievalIsValid();

  if (!isValid) {
    navToSettings();
    return;
  }

  if (retrievedFiles) {
    openRetrievedFilesDialog(retrievedFiles, request.request_id);
    return;
  }

  openRetrieveFilesDialog(request);
};
