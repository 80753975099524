import React, { FC } from 'react';
import { BigidLoader } from '@bigid-ui/components';
import makeStyles from '@mui/styles/makeStyles';
import { BigidWarningIcon, BigidClearFilledIcon } from '@bigid-ui/icons';
import { AutoDiscoveryConnectionList } from './AutoDiscoveryConnectionList';
import { getWarningBody, getWarningTitle } from '../../Fmsd/FmsdComponents/ConnectionsStatus/utils';
import { CloudProvider } from '../../AutoDiscoveryWizard/autoDiscoveryWizardServices';
import { CredsStatus } from '../../Fmsd/FmsdComponents';

const useStyles = makeStyles({
  statusWrapper: {
    paddingTop: 16,
    display: 'flex',
    fontSize: 12,
  },
  status: {
    display: 'inline-grid',
    padding: 4,
    width: 324,
  },
  statusTitle: {
    paddingBottom: 6,
  },
  loaderWrapper: {
    position: 'relative',
    paddingTop: 120,
  },
});

export interface AutoDiscoveryConnectionStatusProps {
  cloudProvider: CloudProvider;
  isLoading: boolean;
  credentialsStatus: CredsStatus;
  permissions?: string[];
  dataAid?: string;
  onSelect?: (id: string, checked: boolean) => void;
  selectedIds?: string[];
}

export const AutoDiscoveryConnectionStatus: FC<AutoDiscoveryConnectionStatusProps> = ({
  cloudProvider,
  isLoading,
  credentialsStatus,
  permissions = [],
  dataAid = 'ConnectionsStatus',
  onSelect,
  selectedIds,
}) => {
  const { statusWrapper, loaderWrapper, status, statusTitle } = useStyles({});
  const statusIcon =
    credentialsStatus === CredsStatus.CANT_RUN || credentialsStatus === CredsStatus.WRONG_CREDS ? (
      <BigidClearFilledIcon dataAid={dataAid} color="negative" />
    ) : (
      <BigidWarningIcon dataAid={dataAid} />
    );

  return (
    <div data-aid={`${dataAid}-status-wrapper`}>
      {isLoading ? (
        <div className={loaderWrapper}>
          <BigidLoader />
        </div>
      ) : credentialsStatus === CredsStatus.VALID || credentialsStatus === CredsStatus.INITIAL ? (
        <AutoDiscoveryConnectionList
          onSelect={onSelect}
          cloudProvider={cloudProvider}
          dataAid={dataAid}
          permissions={permissions}
          credentialsStatus={credentialsStatus}
          selectedIds={selectedIds}
        />
      ) : (
        <div className={statusWrapper}>
          <div>{statusIcon}</div>
          <div className={status}>
            <b className={statusTitle} data-aid={`${dataAid}-status-title`}>
              {getWarningTitle(credentialsStatus)}
            </b>
            <div data-aid={`${dataAid}-status-body`}>{getWarningBody(credentialsStatus, cloudProvider)}</div>
          </div>
        </div>
      )}
    </div>
  );
};
