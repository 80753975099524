import { BigidCheckbox } from '@bigid-ui/components';
import { CloudProvider } from '../../constants';
import React, { useState } from 'react';
import { onDoNotShowAgainPrerequisites } from '../autoDiscoveryWizardUtils';
import { notificationService } from '../../../../services/notificationService';
import { styled } from '@mui/material';
import { useLocalTranslation } from '../../translations';

export interface DoNotShowAgainPrerequisitesProps {
  onCheck: (value: boolean) => void;
  type: CloudProvider;
}

const DoNotShowAgainPrerequisitesWrapper = styled('div')`
  width: 370px;
  transform: translateX(-16px);
`;

export const DoNotShowAgainPrerequisites: React.FC<DoNotShowAgainPrerequisitesProps> = ({ type, onCheck }) => {
  const [doNotShowAgain, setDoNotShowAgain] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { t } = useLocalTranslation('AutoDiscovery');

  return (
    <DoNotShowAgainPrerequisitesWrapper>
      <BigidCheckbox
        label={t('prerequisites.buttons.dontShowItAgain')}
        onChange={async (event, checked) => {
          setIsLoading(true);
          setDoNotShowAgain(checked);
          try {
            await onDoNotShowAgainPrerequisites(type, checked);
            onCheck(checked);
          } catch (err) {
            notificationService.error(t('prerequisites.errors.saveNotShow'));
            setDoNotShowAgain(!checked);
          } finally {
            setIsLoading(false);
          }
        }}
        dataAid={'not-show-again-prerequisites'}
        checked={doNotShowAgain}
        disabled={isLoading}
      />
    </DoNotShowAgainPrerequisitesWrapper>
  );
};
