import React, { FC, useReducer, useEffect, useState } from 'react';
import { useLocalTranslation } from './translations';
import { getApplicationPreference } from '../../services/appPreferencesService';
import { CONFIG } from '../../../config/common';
import { $state, $stateParams } from '../../services/angularServices';
import { pageHeaderService } from '../../../common/services/pageHeaderService';
import { ActionableInsightsGroupedCases } from './ActionableInsightsGridViews/ActionableInsightsGroupedCases';
import { ActionableInsightsAllCases } from './ActionableInsightsGridViews/ActionableInsightsAllCases';
import { actionableInsightsReducer, getInitialReducerState, ReducerActions } from './hooks/ActionableInsightsReducer';
import { ActionableInsightsContext } from './hooks/ActionableInsightsContext';
import { generateDataAid } from '@bigid-ui/utils';
import { ActionableInsightTabs } from './ActionableInsightsTabs/ActionableInsightTabs';
import {
  fetchSystemUsers,
  fetchUnsupportedDsList,
} from './ActionableInsightsGridViews/CaseActionsModal/caseActionsService';
import {
  generateTabs,
  ViewType,
  getJiraConfigurationMetaData,
  getJiraConfigurations,
  getSensitivityClassificationMapping,
  getCaseData,
  STORED_SERVICE_NAME,
} from './actionableInsightsService';
import { styled } from '@mui/styles';
import { Divider } from '@mui/material';
import { BigidPaper, BigidLoader } from '@bigid-ui/components';

export const Wrapper = styled('div')({
  width: '100%',
  display: 'flex',
  position: 'relative',
  height: '100%',
  maxHeight: '100%',
  overflow: 'hidden',
  paddingBottom: 10,
});

export const MainContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  width: '100%',
  overflow: 'hidden',
});

export const GridWrapper = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  flex: 1,
  height: '100%',
  overflow: 'hidden',
});

export const CustomDivider = styled(Divider)({
  marginLeft: 10,
  marginRight: 10,
});

export const ActionableInsights: FC = () => {
  const { viewType: queryViewType, caseId: queryCaseId } = $stateParams;
  const viewType = Object.values(ViewType).includes(queryViewType) ? queryViewType : ViewType.GROUPED;
  const [state, dispatch] = useReducer(actionableInsightsReducer, { viewType }, getInitialReducerState);
  const [initialCaseReportData, setInitialCaseReportData] = useState<any>();
  const [isLoading, setIsLoading] = useState(true);
  const { t } = useLocalTranslation('Case');

  useEffect(() => {
    const fetchActionableInsightsData = async () => {
      const tabs = await generateTabs();
      const jiraConfigurationsMetadata = await getJiraConfigurationMetaData();
      const jiraConfigurations = await getJiraConfigurations();
      const systemUsers = await fetchSystemUsers();
      const listUnsupportedDs = await fetchUnsupportedDsList();
      const scMapping = await getSensitivityClassificationMapping();
      const defaultSelectedProject = jiraConfigurations.length ? jiraConfigurations[0] : undefined;
      dispatch({
        type: ReducerActions.UPDATE_ACTIONABLE_INSIGHTS_DATA,
        payload: {
          tabs,
          jiraConfigurationsMetadata,
          listUnsupportedDs,
          jiraConfigurations,
          systemUsers,
          ...(scMapping && { scMapping }),
        },
      });
      const storedSelectedService = JSON.parse(localStorage.getItem(STORED_SERVICE_NAME));

      dispatch({
        type: ReducerActions.UPDATE_LATEST_TICKETING_SERVICE,
        payload: { selectedTicketingService: storedSelectedService ?? defaultSelectedProject },
      });
      setIsLoading(false);
    };
    if (getApplicationPreference('ACTIONABLE_INSIGHTS_ENABLED')) {
      pageHeaderService.setTitle({
        showBackButton: false,
        breadcrumbs: [
          {
            label: t('common.navigation.securityPosture'),
          },
        ],
      });
      fetchActionableInsightsData();
    } else {
      $state.go(CONFIG.states.FORBIDDEN);
    }
  }, [t]);

  useEffect(() => {
    if (queryCaseId) {
      setIsLoading(true);
      const fetchCaseData = async () => {
        const data = await getCaseData(queryCaseId);
        if (data?.length) {
          setInitialCaseReportData(data[0]);
        }
        setIsLoading(false);
      };
      fetchCaseData();
    }
  }, [queryCaseId]);

  return (
    <ActionableInsightsContext.Provider
      value={{
        caseState: state.caseState,
        jiraConfigurationsMetadata: state.jiraConfigurationsMetadata,
        jiraConfigurations: state.jiraConfigurations,
        viewType: state.viewType,
        systemUsers: state.systemUsers,
        tabs: state.tabs,
        scMapping: state.scMapping,
        selectedTicketingService: state.selectedTicketingService,
        listUnsupportedDs: state.listUnsupportedDs,
        dispatch,
      }}
    >
      <Wrapper
        data-aid={generateDataAid('ActionableInsightsWrapper', [`${state.viewType}-view`, `${state.caseState}-status`])}
      >
        <MainContainer>
          {isLoading ? (
            <BigidLoader />
          ) : (
            <>
              <ActionableInsightTabs tabs={state.tabs} />
              <BigidPaper>
                <GridWrapper>
                  {state.viewType === ViewType.FLAT ? (
                    <ActionableInsightsAllCases
                      key={state.caseState}
                      initialCaseSidePanelData={initialCaseReportData}
                    />
                  ) : (
                    <ActionableInsightsGroupedCases
                      key={state.caseState}
                      initialCaseReportData={initialCaseReportData}
                    />
                  )}
                </GridWrapper>
              </BigidPaper>
            </>
          )}
        </MainContainer>
      </Wrapper>
    </ActionableInsightsContext.Provider>
  );
};
