import { notificationService } from '../../../services/notificationService';
import { httpService } from '../../../services/httpService';
import { EmailTemplate, GetEmailTemplatesResponse } from '../types/emailTemplatesTypes';
import { getFixedT } from '../translations';

const EMAIL_TEMPLATES_ENDPOINT = 'email-templates';
const tMessages = getFixedT('messages');

export const getEmailTemplatesTypes = async (): Promise<string[]> => {
  const {
    data: { data = [] },
  } = await httpService.fetch<{ data: string[] }>(`${EMAIL_TEMPLATES_ENDPOINT}/types`, {});
  return data;
};

export const getEmailTemplates = async (gridAggregationQuery: string): Promise<EmailTemplate[]> => {
  try {
    const {
      data: { data },
    } = await httpService.fetch<{ data: GetEmailTemplatesResponse }>(
      `${EMAIL_TEMPLATES_ENDPOINT}${gridAggregationQuery ? `?${gridAggregationQuery}` : ''}`,
      {},
    );
    return data?.emailTemplates || [];
  } catch (error) {
    const fetchTemplatesError = tMessages('unableToLoadTemplates');
    console.error(`${fetchTemplatesError} ${error}`);
    notificationService.error(fetchTemplatesError);
  }
};

export const duplicateTemplate = async (templateId: string, newTemplateName: string): Promise<any> => {
  const {
    data: { data = [] },
  } = await httpService.post(`${EMAIL_TEMPLATES_ENDPOINT}/duplicate`, {
    templateId,
    newTemplateName,
  });
  return data;
};

export const deleteTemplateService = async (templateId: string): Promise<any> => {
  const {
    data: { data = [] },
  } = await httpService.delete<{ data: any }>(`${EMAIL_TEMPLATES_ENDPOINT}/${templateId}`);
  return data;
};
