import React, { FC } from 'react';
import { BigidColorsV2, BigidDialog, BigidLink, BigidPrimaryCheckbox, PrimaryButton } from '@bigid-ui/components';
import makeStyles from '@mui/styles/makeStyles';
import { copyToClipboard } from '../Fmsd/fmsdServices';
import { useAwsPolicyGenerator } from '../Fmsd/FmsdPreDiscover/FmsdPreDiscoverViews/FmsdConnect/ConnectionDialogs/AwsInfoDialog/hooks/useAwsPolicyGenerator';
import { notificationService } from '../../services/notificationService';
import { publicUrls } from '../../config/publicUrls';

const useStyles = makeStyles({
  dialogContent: {
    color: BigidColorsV2.gray[700],
    height: 50,
    fontSize: 14,
  },
  dialogText: {
    paddingLeft: 6,
  },
  checkboxContainer: {
    paddingTop: 8,
    display: 'flex',
  },
});

export interface AutoDiscoveryCredsDialogProps {
  isOpen: boolean;
  onClose: () => void;
}

export const AutoDiscoveryCredsDialog: FC<AutoDiscoveryCredsDialogProps> = ({ isOpen, onClose }) => {
  const { dialogContent, dialogText, checkboxContainer } = useStyles({});
  const { policiesSelection, onPolicySelection, awsPolicyJson } = useAwsPolicyGenerator();

  const handleOnClose = () => {
    copyToClipboard(awsPolicyJson);
    onClose();
    notificationService.success('AWS IAM JSON copied to clipboard.', { duration: 2000 });
  };

  return (
    <BigidDialog
      onClose={onClose}
      title="User Role Settings"
      isOpen={isOpen}
      borderBottom={false}
      buttons={[
        {
          component: PrimaryButton,
          onClick: handleOnClose,
          text: 'Copy to Clipboard & Close',
          dataAid: 'fmsdAwsInfoDialog-button-copy-and-close',
        },
      ]}
    >
      <div className={dialogContent}>
        <span className={dialogText}> Copy this policy to the clipboard and create a user with these permissions.</span>{' '}
        <BigidLink text="Learn more." href={publicUrls.DOCS_AWS_IAM_USERGUIDE} shouldOpenNewTab={true} />
        <br />
        <div className={checkboxContainer}>
          {policiesSelection.map(({ label, value, isSelected, isDisabled }, index) => {
            return (
              <BigidPrimaryCheckbox
                key={index}
                onChange={onPolicySelection}
                checked={isSelected}
                label={label}
                value={value}
                disabled={isDisabled}
                dataAid={`fmsdAwsInfoDialog-checkbox-${value}${isSelected ? '-selected' : ''}${
                  isDisabled ? '-disabled' : ''
                }`}
              />
            );
          })}
        </div>
      </div>
    </BigidDialog>
  );
};
