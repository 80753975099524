import React, { FC, useCallback, useEffect } from 'react';
import ClassifierModelIcon from '../../../../../../../assets/icons/ClassifierModelIcon.svg';
import ClassifierModelIconOnApply from '../../../../../../../assets/icons/ClassifierModelIconOnApply.svg';
import { useLocalTranslation } from '../../translations';
import { generateDataAid } from '@bigid-ui/utils';
import { styled, useTheme } from '@mui/material';
import {
  BigidBody1,
  BigidHeading1,
  BigidHeading4,
  BigidLink,
  BigidLoader,
  BigidStatusBadge,
  BigidStatusBadgeSize,
  BigidStatusBadgeType,
  PrimaryButton,
  SecondaryButton,
} from '@bigid-ui/components';
import {
  ClassifierModelState,
  getIsWeakModel,
  ModelData,
  getStatusBadge,
  SSE_TYPES,
  ApplyCompletedData,
  APPLY_COMPLETED_SSE,
} from '../../classifierModelUtils';
import { triggerApplyModel } from '../../classifierModelService';
import { notificationService } from '../../../../../../../services/notificationService';
import { closeSystemDialog, openSystemDialog } from '../../../../../../../services/systemDialogService';
import { isPermitted } from '../../../../../../../services/userPermissionsService';
import { SUPERVISED_LEARNING_PERMISSIONS } from '@bigid/permissions';
import { $state } from '../../../../../../../services/angularServices';
import { CONFIG } from '../../../../../../../../config/common';
import { CURATION_ATTRIBUTE_PREFIX } from '../../../../../../Curation/useCurationState';
import { SSEDataMessage, subscribeToRepeatedSSEEventById } from '../../../../../../../services/sseService';
import { CuratedAttributeType } from '../../../../../../Curation/curationService';

interface CardProps {
  isWeakModel: boolean;
  isRightCard?: boolean;
}

interface ContentProps {
  isApplying?: boolean;
}

const Card = styled('div')<CardProps>`
  display: flex;
  width: 266px;
  height: 372px;
  padding: 0 16px;
  box-shadow: ${({ isRightCard, theme }) => (isRightCard ? theme.vars.tokens.bigid.shadow10 : 'none')};
  border-radius: 4px;
  flex-direction: column;
  align-items: flex-start;
  background: ${({ isRightCard, isWeakModel, theme }) =>
    !isWeakModel && isRightCard ? theme.vars.tokens.bigid.backgroundHoverSubtle : theme.vars.palette.bigid.gray50};
  gap: ${({ isWeakModel }) => (!isWeakModel ? '144px' : '54px')};
`;

const ContentWrapper = styled('div')<ContentProps>`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
`;

const Headline = styled(BigidHeading4)`
  display: flex;
  justify-content: center;
  align-items: end;
  gap: 8px;
  margin-top: 24px;
`;

const AccuracyText = styled(BigidBody1)<ContentProps>`
  margin-top: 10px;
  color: ${({ isApplying, theme }) => (isApplying ? theme.vars.palette.bigid.gray400 : 'inherit')};
`;

const AccuracyValue = styled(BigidHeading1)<ContentProps>`
  display: flex;
  align-items: center;
  gap: 8px;
  color: ${({ isApplying, theme }) => (isApplying ? theme.vars.palette.bigid.gray400 : 'inherit')};
`;

const ModelAccuracyHeadline = styled(BigidHeading4)<ContentProps>`
  font-size: 16px;
  color: ${({ isApplying, theme }) => (isApplying ? theme.vars.palette.bigid.gray400 : 'inherit')};
`;

const ModelAccuracyStats = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
`;

const ButtonWrapper = styled('div')`
  width: 234px;
  display: flex;
  gap: 8px;
  flex-direction: column;
  justify-content: center;
`;

const BadgeWrapper = styled('div')<ContentProps>`
  opacity: ${({ isApplying }) => (isApplying ? 0.3 : 1)};
`;

const ApplyStartedWrapper = styled('div')`
  && > * {
    margin: 0;
  }
  margin: 6px 0;
  display: flex;
  gap: 8px;
  flex-direction: row;
  justify-content: center;
`;

const ApplyStartedText = styled(BigidBody1)`
  font-weight: 600;
`;

export interface ClassifierModelCardWithModelProps {
  modelData: ModelData;
  onRender: () => void;
  isRightCard?: boolean;
  dataAid?: string;
  displayName?: string;
}

export const ClassifierModelCardWithModel: FC<ClassifierModelCardWithModelProps> = ({
  modelData,
  isRightCard = true,
  onRender,
  dataAid = 'ClassifierModelCardWithModel',
  displayName,
}) => {
  const { t } = useLocalTranslation('main');
  const theme = useTheme();

  const isWeakModel = getIsWeakModel(Number(modelData.evaluationScore), modelData.accuracyDiff);
  const isApplying = modelData.classifierModelStage.state === ClassifierModelState.MODEL_APPLYING;

  const handleApplyCompleted = useCallback(
    ({ message, broadcastEvent, operationStatus }: SSEDataMessage) => {
      const data: ApplyCompletedData = JSON.parse(message);

      const isApplyCompletedForClassifier =
        data?.classification_name === modelData.classificationName &&
        operationStatus.toString() === APPLY_COMPLETED_SSE &&
        broadcastEvent === SSE_TYPES.MODEL_APPLY_COMPLETED_SSE;

      if (isApplyCompletedForClassifier) {
        const notificationMessage = t('notifications.success.apply');
        notificationService.success(`${notificationMessage}.`);
        onRender();
      }
    },
    [onRender, modelData.classificationName],
  );

  useEffect(() => {
    const unregisterSSEEvent = subscribeToRepeatedSSEEventById(
      SSE_TYPES.MODEL_APPLY_COMPLETED_SSE,
      handleApplyCompleted,
    );

    return () => {
      unregisterSSEEvent();
    };
  }, [handleApplyCompleted]);

  const ApplyModelDialogContent: FC = () => {
    return (
      <>
        <BigidBody1>{t('dialogs.apply.body.firstPart')}</BigidBody1>
        <br />
        <BigidBody1>{t('dialogs.apply.body.secondPart')}</BigidBody1>
      </>
    );
  };

  const handleOnApplyModelClick = async () => {
    try {
      await triggerApplyModel(modelData.classificationName, modelData.modelId);
      closeSystemDialog();
      onRender();
    } catch ({ message }) {
      const notificationMessage = t('notifications.failure.apply');
      console.error(`${notificationMessage}: ${message}`);
      notificationService.error(`${notificationMessage}.`);
    }
  };

  const triggerApplyDialog = () => {
    openSystemDialog({
      title: `${t('dialogs.apply.title')}`,
      buttons: [
        {
          text: `${t('dialogs.apply.secondaryBtn')}`,
          component: SecondaryButton,
          onClick: closeSystemDialog,
          isClose: true,
          dataAid: generateDataAid(dataAid, ['apply-dialog-cancel']),
        },
        {
          text: `${t('dialogs.apply.primaryBtn')}`,
          component: PrimaryButton,
          onClick: handleOnApplyModelClick,
          dataAid: generateDataAid(dataAid, ['apply-dialog-apply']),
        },
      ],
      onClose: closeSystemDialog,
      content: ApplyModelDialogContent,
      maxWidth: 'xs',
    });
  };

  const handleClassifierReviewClick = () => {
    $state.go(CONFIG.states.CLASSIFIER_FINDINGS, {
      attributeName: `${CURATION_ATTRIBUTE_PREFIX}${modelData.classificationName}`,
      attributeType: CuratedAttributeType.CLASSIFIER,
      structuredOnly: true,
      displayName,
    });
  };

  const handleClassifierPreviewClick = () => {
    $state.go(CONFIG.states.CLASSIFIER_PREDICTIONS, {
      attributeName: `${CURATION_ATTRIBUTE_PREFIX}${modelData.classificationName}`,
      attributeType: CuratedAttributeType.CLASSIFIER,
      displayName,
    });
  };

  return (
    <Card isWeakModel={isWeakModel} isRightCard={isRightCard} data-aid={generateDataAid(dataAid, ['model', 'card'])}>
      <ContentWrapper isApplying={isApplying}>
        <Headline data-aid={generateDataAid(dataAid, ['model', 'headline'])}>
          {isApplying ? <ClassifierModelIconOnApply /> : <ClassifierModelIcon />}
          <ModelAccuracyHeadline isApplying={isApplying}>
            {isRightCard
              ? modelData.isImproved
                ? t('modelCard.improvedHeader')
                : t('modelCard.header')
              : t('modelCard.currentModelHeader')}
          </ModelAccuracyHeadline>
          {isRightCard && (
            <BadgeWrapper isApplying={isApplying}>
              <BigidStatusBadge
                label={t('modelCard.newModelIndication')}
                type={BigidStatusBadgeType.DARK}
                size={BigidStatusBadgeSize.SMALL}
              />
            </BadgeWrapper>
          )}
        </Headline>
        <ModelAccuracyStats>
          <AccuracyText isApplying={isApplying}>{t('structuredAccuracy')}</AccuracyText>
          <AccuracyValue isApplying={isApplying} data-aid={generateDataAid(dataAid, ['model', 'accuracy'])}>
            {`${modelData.evaluationScore}%`}
            {isRightCard && getStatusBadge(Math.round(modelData.accuracyDiff), isWeakModel, theme)}
          </AccuracyValue>
        </ModelAccuracyStats>
        {isWeakModel && isRightCard && (
          <BigidBody1>
            {t('modelCard.weakModelIndication.firstPart')}
            <BigidLink
              text={t('modelCard.weakModelIndication.secondPart')}
              underline="none"
              onClick={handleClassifierReviewClick}
            />
          </BigidBody1>
        )}
      </ContentWrapper>
      {isRightCard && modelData.classifierModelStage.state === ClassifierModelState.MODEL_IS_OFFERED && (
        <ButtonWrapper>
          <SecondaryButton
            dataAid={generateDataAid(dataAid, ['button', 'preview'])}
            text={t('buttons.preview')}
            size="medium"
            width="fill"
            disabled={!isPermitted(SUPERVISED_LEARNING_PERMISSIONS.PREVIEW.name)}
            onClick={handleClassifierPreviewClick}
          />
          <PrimaryButton
            dataAid={generateDataAid(dataAid, ['button', 'apply'])}
            width="fill"
            size="medium"
            onClick={triggerApplyDialog}
            disabled={!isPermitted(SUPERVISED_LEARNING_PERMISSIONS.APPLY.name)}
            text={t('buttons.apply')}
          />
        </ButtonWrapper>
      )}
      {isRightCard && modelData.classifierModelStage.state === ClassifierModelState.MODEL_APPLYING && (
        <ButtonWrapper>
          <SecondaryButton
            dataAid={generateDataAid(dataAid, ['button', 'preview'])}
            text={t('buttons.preview')}
            size="medium"
            width="fill"
            disabled={true}
            onClick={null}
          />
          <ApplyStartedWrapper data-aid={generateDataAid(dataAid, ['model', 'apply', 'in-progress'])}>
            <BigidLoader position="static" size={20} />
            <ApplyStartedText>{t('modelCard.applying')}</ApplyStartedText>
          </ApplyStartedWrapper>
        </ButtonWrapper>
      )}
    </Card>
  );
};
