import React from 'react';
import { MetadataSearchCatalogIndexingStatus } from '../../../components/MetadataSearchCatalogIndexingStatus/MetadataSearchCatalogIndexingStatus';

export const getTitleServiceConfig = (isElasticEnabled: boolean) => {
  return {
    rightSideComponentsContainer: isElasticEnabled ? (
      <MetadataSearchCatalogIndexingStatus dataAid="DataCatalogIndexingStatus" />
    ) : undefined,
    pageTitle: 'Registry and Metadata Catalog',
  };
};
