import './activitiesAndRecords.scss';

import { module } from 'angular';
import { isPermitted } from '../../react/services/userPermissionsService';
import { INVENTORY_PERMISSIONS } from '@bigid/permissions';
const app = module('app');
import template from './activitiesAndRecords.component.html';

app.component('activitiesAndRecords', {
  template,
  controllerAs: 'activitiesAndRecordsModel',
  controller: [
    '$scope',
    '$rootScope',
    '$window',
    '$uibModal',
    'activitiesAndRecordsService',
    '$interval',
    'DeleteConfirmation',
    'queryStringService',
    'notificationService',
    'downloadFileService',
    function (
      $scope,
      $rootScope,
      $window,
      $uibModal,
      activitiesAndRecordsService,
      $interval,
      DeleteConfirmation,
      queryStringService,
      notificationService,
      downloadFileService,
    ) {
      const activitiesAndRecordsModel = this;

      const availableTypeOptions = [{ id: '1', name: 'PII Records' }];

      activitiesAndRecordsModel.hasExportPermission = isPermitted(INVENTORY_PERMISSIONS.EXPORT_ATTRIBUTE.name);

      activitiesAndRecordsModel.selectedTypeOption = {
        id: '1',
        name: 'PII Records', //This sets the default value of the select in the ui
      };

      activitiesAndRecordsModel.type = {
        availableOptions: availableTypeOptions,
      };

      activitiesAndRecordsModel.openProcessModal = function () {
        $window.sessionStorage.removeItem('piiInvestigationId');
        openProcessModalFunc();
      };

      function openProcessModalFunc() {
        const modalInstance = $uibModal.open({
          animation: true,
          windowClass: 'inventory-cached-data-window',
          template:
            "<div class='modal-header' style='border: 1px solid #323a45;border-radius: 4px 4px 0 0;" +
            "height: 66px;background-color: #262d37;'>" +
            "<span class='modal-title' style='color: white;font-size: 18px;margin: 5px 0 0 0;position:absolute;'>Cached Data</span>" +
            '</div>' +
            "<div class='modal-body'>" +
            "<div id='grid1' ui-grid='investigationModel' style='width: 100%; height: 250px;'></div>" +
            '</div>' +
            "<div class='modal-footer'>" +
            "<button class='btn btn-primary' type='button' ng-click='refresh()' style=' font-size: 12px;font-weight: 500;;height: 34px !important'>Refresh</button>" +
            "<button class='btn btn-primary' type='button' ng-click='deleteAllProcess()' style=' font-size: 12px;font-weight: 500;;height: 34px !important'>Delete all</button>" +
            "<button class='btn btn-primary' type='button' ng-click='generate()' style=' font-size: 12px;font-weight: 500;;height: 34px !important'>Collect report data</button>" +
            "<button class='btn btn-primary' type='button' ng-click='close()' style='float: left;width: 74px;height: 34px;background-color: #e7ebf2 !important;" +
            "border: 1px solid #cdd1e4 !important;border-radius: 1px;color: #4d595e !important;font-size: 12px;font-weight: 500;;'>Close</button>" +
            '</div>',
          controller: 'ModalInvestigationInstanceCtrl',
        });
      }

      activitiesAndRecordsModel.$onInit = function () {
        activitiesAndRecordsModel.showRecords = true;
      };

      activitiesAndRecordsModel.$onChanges = function () {
        activitiesAndRecordsModel.urlfilter = activitiesAndRecordsModel.urlfilter;
        activitiesAndRecordsModel.filteruser = activitiesAndRecordsModel.filteruser;
        activitiesAndRecordsModel.querystring = activitiesAndRecordsModel.querystring;
        activitiesAndRecordsModel.searchtextbox = activitiesAndRecordsModel.searchtextbox;
      };

      const listenerIdentityLength = $rootScope.$on('identitiesLength', function (event, length) {
        activitiesAndRecordsModel.identitiesLength = length;
      });

      const listenerApplicationLength = $rootScope.$on('systemLength', function (event, length) {
        activitiesAndRecordsModel.systemLength = length;
      });

      const listenerAttributeLength = $rootScope.$on('applicationLength', function (event, length) {
        activitiesAndRecordsModel.applicationsLength = length;
      });

      const listenerSystemLength = $rootScope.$on('attributeLength', function (event, length) {
        activitiesAndRecordsModel.attributesLength = length;
      });

      const listenerGetPiiInvestigationsCount = $rootScope.$on(
        'callGetPiiInvestigationsCount',
        function (event, length) {
          getPiiInvestigationsCount();
        },
      );

      getPiiInvestigationsCount();

      function getPiiInvestigationsCount() {
        activitiesAndRecordsService.getPiiInvestigationsCount().then(function (result) {
          activitiesAndRecordsModel.processLength = result;
        });
      }

      const listenerOpenPiiInvestigationProcessModal = $rootScope.$on(
        'openPiiInvestigationProcessModal',
        function (event, piiInvestigationId) {
          $window.sessionStorage.removeItem('piiInvestigationId');
          $window.sessionStorage.setItem('piiInvestigationId', piiInvestigationId);
          openProcessModalFunc();
        },
      );

      const stop = $interval(function () {
        getPiiInvestigationsCount();
      }, 60000);

      //Unregister listeners
      //Unregister listeners
      $scope.$on('$destroy', function () {
        listenerIdentityLength();
        listenerApplicationLength();
        listenerAttributeLength();
        listenerSystemLength();
        $interval.cancel(stop);
        listenerOpenPiiInvestigationProcessModal();
        listenerGetPiiInvestigationsCount();
      });

      activitiesAndRecordsModel.update = function (selectedTypeOptionName) {
        switch (selectedTypeOptionName) {
          case 'PII Records': {
            activitiesAndRecordsModel.showRecords = true;
            break;
          }
        }
      };

      $scope.exportPiiRecords = function () {
        const modalOptions = {
          closeButtonText: 'Close',
          actionButtonText: 'Export',
          headerText: 'PII Records Export',
          bodyText: 'You are exporting personal information of these data subjects. Do you want to proceed?',
        };

        const ids = activitiesAndRecordsModel.filteruser ? [activitiesAndRecordsModel.filteruser] : null;
        let filter = null;
        if (!ids) {
          filter = $window.sessionStorage.getItem('callFilter.urlfilter');
        }

        DeleteConfirmation.showModal({}, modalOptions).then(function () {
          activitiesAndRecordsService.getPiiRecordsExport(filter, ids).then(
            function (response) {
              downloadFileService.download(response, 'attachment/csv', 'pii_records.csv');
            },
            function () {
              notificationService.error('An error has occurred');
            },
          );
        });
      };
    },
  ],
  bindings: {
    urlfilter: '=',
    querystring: '<',
    searchtextbox: '<',
    filteruser: '<',
  },
});

app.controller('ModalInvestigationInstanceCtrl', [
  '$scope',
  '$uibModalInstance',
  'activitiesAndRecordsService',
  '$rootScope',
  '$window',
  'DeleteConfirmation',
  function ($scope, $uibModalInstance, activitiesAndRecordsService, $rootScope, $window, DeleteConfirmation) {
    $scope.$on('modal.closing', function (event, reason, closed) {
      const filterValue = $window.sessionStorage.getItem('callFilter.urlfilter');
      if (filterValue) {
        $rootScope.$emit('callGetPiiInvestigationsCount', filterValue);
        $rootScope.$emit('callFilter', filterValue);
      } else {
        $rootScope.$emit('callGetPiiInvestigationsCount', '');
        $rootScope.$emit('callFilter', '');
      }
    });

    $scope.investigationModel = {
      enableSorting: true,
      enablePaginationControls: false,
      columnDefs: [
        {
          name: 'User',
          field: 'piiRecord.identity_name',
          cellTemplate: '<div style="margin-top: 5px;">{{row.entity.piiRecord.identity_name}}</div>',
          enableHiding: false,
        },
        {
          name: 'Attribute',
          field: 'piiRecord.attribute',
          cellTemplate: '<div style="margin-top: 5px;">{{row.entity.piiRecord.attribute}}</div>',
          enableHiding: false,
        },
        {
          name: 'System',
          field: 'piiRecord.source',
          cellTemplate: '<div style="margin-top: 5px;">{{row.entity.piiRecord.source}}</div>',
          enableHiding: false,
        },
        {
          name: 'DS Value',
          displayName: 'DS Value',
          field: 'ds_value',
          cellTemplate:
            '' +
            '<div ng-class="{\'fetchData\':true, \'fetchRegular\':row.entity.ds_value_exact===\'\' }">{{row.entity.ds_value_exact == "" ? "fetching data..." : row.entity.ds_value_exact}}</div>',
          enableHiding: false,
        },
        {
          name: 'IDSOR Value',
          displayName: 'IDSOR Value',
          field: 'idsor_value',
          cellTemplate:
            '' +
            '<div ng-class="{\'fetchData\':true, \'fetchRegular\':row.entity.idsor_value===\'\' }">{{row.entity.idsor_value == "" ? "fetching data..." : row.entity.idsor_value}}</div>',
          enableHiding: false,
        },
        {
          name: 'Created At',
          field: 'created_at',
          cellTemplate: '<div style="margin-top: 5px;">{{row.entity.created_at}}</div>',
          enableHiding: false,
        },
        {
          name: 'Updated At',
          field: 'updated_at',
          cellTemplate: '<div style="margin-top: 5px;">{{row.entity.updated_at}}</div>',
          enableHiding: false,
        },
        {
          name: 'Actions',
          field: '',
          cellTemplate:
            '<table border="0" style="margin-top: 5px;">' +
            '<tr>' +
            '<td>' +
            '<button style="display:block;" ng-click="grid.appScope.deleteProcess(row.entity)" class="btn primary btnAction btnActionsEdit" >Delete</button></td><td>' +
            '<button style="float: right;display:block;" ng-click="grid.appScope.goToDetails(row.entity)" class="btn primary btnAction btnActionsEdit" >Details</button></td></tr></table>',
          enableHiding: false,
        },
      ],
    };

    $scope.deleteProcess = function (rowEntity) {
      activitiesAndRecordsService.deletePiiInvestigations(rowEntity._id).then(function (result) {
        getPiiInvestigationsData();
      });
    };

    $scope.deleteAllProcess = function () {
      const modalOptions = {
        closeButtonText: 'Cancel',
        actionButtonText: 'Delete',
        headerText: 'Delete PII investigation data',
        bodyText: 'Are you sure you want to delete all PII investigation data ?',
      };

      DeleteConfirmation.showModal({}, modalOptions).then(function () {
        activitiesAndRecordsService.deleteAllPiiInvestigations().then(function (result) {
          getPiiInvestigationsData();
        });
      });
    };

    $scope.goToDetails = function (rowEntity) {
      $rootScope.$emit('openPiiRecordDetailsModal', rowEntity, true);
      $uibModalInstance.close();
    };

    function getPiiInvestigationsData() {
      const piiInvestigationId = $window.sessionStorage.getItem('piiInvestigationId');
      activitiesAndRecordsService.getPiiInvestigationsData(piiInvestigationId).then(function (result) {
        $scope.investigationModel.data = result;
      });
    }

    getPiiInvestigationsData();
    // activitiesAndRecordsService.getPiiInvestigationsData().then(function(result){
    //     $scope.investigationModel.data = result;
    // });

    $scope.refresh = function () {
      getPiiInvestigationsData();
    };

    $scope.generate = function () {
      activitiesAndRecordsService.createMultiplePiiInvestigation().then(function (result) {
        getPiiInvestigationsData();
      });
    };

    $scope.close = function () {
      $uibModalInstance.close();
    };
  },
]);
