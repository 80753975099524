import React, { FC } from 'react';
import { BigidColorsV2 } from '@bigid-ui/components';
import makeStyles from '@mui/styles/makeStyles';
import { BigidApplyIcon, BigidClearIcon, BigidInProgressIcon } from '@bigid-ui/icons';
import { PresetExecutionInfoPopOver } from './PresetExecutionInfoPopOver';
import { AttachmentMetadata } from '../../views/ActivityLog/ActivityLog';

export enum ExecutionStatus {
  COMPLETED = 'COMPLETED',
  ERROR = 'ERROR',
  IN_PROGRESS = 'IN_PROGRESS',
  STOPPED = 'STOPPED',
}

export interface ExecutionData {
  createdAt: string;
  originalCreatedAt: string;
  updatedAt: string;
  userName: string;
  attachments: AttachmentMetadata[];
  duration: string;
  info: string;
  status: ExecutionStatus;
}

export interface PresetLatestExecutionStatusProps {
  latestExecution: ExecutionData;
}

const statusMap = {
  [ExecutionStatus.COMPLETED]: {
    text: 'Success',
    icon: <BigidApplyIcon color="positive" />,
    color: BigidColorsV2.green[700],
  },
  [ExecutionStatus.ERROR]: {
    text: 'Failed',
    icon: <BigidClearIcon color="negative" />,
    color: BigidColorsV2.red[600],
  },
  [ExecutionStatus.IN_PROGRESS]: {
    text: 'In Progress',
    icon: <BigidInProgressIcon />,
    color: BigidColorsV2.purple[900],
  },
  [ExecutionStatus.STOPPED]: {
    text: 'Stopped',
    icon: <BigidClearIcon />,
    color: BigidColorsV2.red[600],
  },
};

const useStyles = makeStyles({
  root: {
    display: 'flex',
    columnGap: '16px',
    color: BigidColorsV2.gray[600],
    fontSize: '14px',
    alignContent: 'center',
    alignItems: 'center',
  },
  text: {
    display: 'flex',
    columnGap: '8px',
    alignContent: 'center',
    alignItems: 'center',
  },
  statusText: ({ status }: Partial<ExecutionData>) => ({
    color: statusMap[status].color,
  }),
});

export const PresetLatestExecutionStatus: FC<PresetLatestExecutionStatusProps> = ({ latestExecution }) => {
  const { status } = latestExecution;
  const classes = useStyles({ status });
  return (
    <div className={classes.root}>
      {statusMap[status].icon}
      <div className={classes.text}>
        <div>Last Run:</div>
        <div className={classes.statusText}>{statusMap[status].text}</div>
        {latestExecution && <PresetExecutionInfoPopOver latestExecution={latestExecution} />}
      </div>
    </div>
  );
};
