import React, { useCallback, useMemo, useState } from 'react';
import {
  ActionData,
  BigidBody1,
  BigidCheckbox,
  BigidDialog,
  BigidLoader,
  BigidTextField,
  EntityEvents,
  LayoutContentType,
  PrimaryButton,
  SecondaryButton,
  entityEventsEmitter,
} from '@bigid-ui/components';
import {
  BigidGridColumn,
  BigidGridColumnTypes,
  BigidGridDataFetchResult,
  BigidGridProps,
  BigidGridQueryComponents,
} from '@bigid-ui/grid';
import { BigidLayout, BigidLayoutConfig, LayoutContentState } from '@bigid-ui/layout';
import { styled } from '@mui/material';
import { cloneDeep, omit } from 'lodash';
import { ControlsEmptyGrid } from './components/ControlsEmptyGrid';
import { RiskControl } from './types';
import { ControlComponent } from './ControlComponent';
import { pageHeaderService } from '../../../common/services/pageHeaderService';
import { useTranslation } from 'react-i18next';
import { httpService } from '../../services/httpService';
import { BigidAddIcon } from '@bigid-ui/icons';
import { trackManualEvent, RisksAndControlsTrackingEvents } from './risksAnalytics';
import { v4 as uuid } from 'uuid';
import { isPermitted } from '../../services/userPermissionsService';
import { RISK_CONTROLS_PERMISSIONS } from '@bigid/permissions';
import { CreateControlDialog } from './CreateControlDialog';

const { RISK_CONTROL_DELETE_CONTROL, RISK_CONTROL_DUPLICATE_CONTROL } = RisksAndControlsTrackingEvents;

const ControlsLibraryContainer = styled('div')({
  height: '100%',
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
});

export const NAMESPACE = 'bigid/riskControls';

export const useLocalTranslation = (keyPrefix?: string) => useTranslation(NAMESPACE, { keyPrefix });

export const ControlsLibrary: React.FC = () => {
  const [showDialog, setShowDialog] = useState<boolean>(false);
  const [dialogAction, setDialogAction] = useState<'create' | 'duplicate' | 'delete'>('create');
  const [selectedRiskControl, setSelectedRiskControl] = useState<RiskControl[] | undefined>(undefined);
  const [selectedRiskControlId, setSelectedRiskControlId] = useState<string | undefined>(undefined);
  const [checked, setChecked] = useState(false);
  const [duplicateOriginalRiskControl, setDuplicateOriginalRiskControl] = useState<RiskControl | undefined>(undefined);
  const [layoutKey, setLayoutKey] = useState(uuid());

  pageHeaderService.setTitle({
    showBackButton: false,
    breadcrumbs: [
      {
        label: 'Risk Controls',
      },
    ],
    rightSideComponentsContainer: isPermitted(RISK_CONTROLS_PERMISSIONS.CREATE.name) && (
      <PrimaryButton
        dataAid="BigidToolbarAction-row-create-new-control"
        startIcon={<BigidAddIcon />}
        onClick={async () => {
          setDialogAction('create');
          setShowDialog(true);
          return Promise.resolve({
            shouldGridReload: false,
            shouldClearSelection: false,
            shouldClearSelectedItem: false,
          });
        }}
        size={'medium'}
      >
        {'Add Control'}
      </PrimaryButton>
    ),
  });

  const fetchGridData = useCallback(
    async (
      queryComponents: BigidGridQueryComponents,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      customData: any,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    ): Promise<BigidGridDataFetchResult<any>> => {
      console.log(`fetchData called with following args`, { queryComponents, customData });
      let totalCount = 0;

      const controlsResults = await httpService.fetch<{
        data: RiskControl[];
        totalCount: number;
      }>('risk-controls');

      if (controlsResults && controlsResults.data.data) {
        totalCount = controlsResults.data.data.length;
      }

      return {
        totalCount,
        data: controlsResults.data.data ?? [],
        fetchCount: undefined,
      };
    },
    [],
  );

  const columns: BigidGridColumn<RiskControl>[] = useMemo(
    () => [
      {
        title: 'Friendly Name',
        name: 'name',
        getCellValue: row => row.name,
        type: BigidGridColumnTypes.TEXT,
        isListColumn: true,
      },
      {
        title: 'Description',
        name: 'description',
        getCellValue: row => row.description,
        type: BigidGridColumnTypes.TEXT,
      },
      {
        title: 'Standard / Category',
        name: 'category',
        getCellValue: row => row.category,
        type: BigidGridColumnTypes.TEXT,
      },
    ],
    [],
  );

  const getGridConfig = useMemo(() => {
    return {
      columns,
      hiddenColumnNames: ['userAgent', 'id'],
      defaultSorting: [{ field: 'name', order: 'desc' as const }],
      isRowDisabled: () => {
        return false;
      },
      gridId: 'riskControlsGrid',
      selectedRowIds: [selectedRiskControlId],
      rowClickShouldKeepSelection: true,
      onRowClick: row => console.log(row),
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } as BigidGridProps<any>;
  }, [columns, selectedRiskControlId]);

  const layoutConfig: BigidLayoutConfig = useMemo(() => {
    return {
      content: {
        contentTypes: [LayoutContentType.MASTER_DETAILS],
        defaultContentType: LayoutContentType.MASTER_DETAILS,
        selectedItem: selectedRiskControl?.[0],
        viewConfig: {
          fetchGridData: fetchGridData,
          gridConfig: getGridConfig,
          selectedItemPropsMapping: {
            id: 'id',
            name: 'name',
            description: 'description',
            category: 'category',
            controlId: 'controlId',
            status: 'status',
          },
          masterDetailsConfig: {
            placeholderComponent: <BigidLoader />,
            actions: [],
            selectedItem: selectedRiskControl?.[0],
            setSelectedItemInFetch: true,
            shouldReloadGridOnClose: true,
            isEditableHeader: true,
            isHeaderHidden: true,
            tabsAndContent: {
              hideTabs: true,
              tabProps: {
                tabs: [
                  {
                    label: '',
                    data: {
                      component: ControlComponent,
                      customProps: {
                        onClose: () => {
                          setSelectedRiskControl(undefined);
                          setSelectedRiskControlId(undefined);
                          entityEventsEmitter.emit(EntityEvents.RELOAD, {
                            entityId: layoutConfig.content.viewConfig.gridConfig.gridId,
                            shouldEntityReload: true,
                          });
                          setLayoutKey(uuid());
                        },
                      },
                    },
                  },
                ],
                selectedIndex: 0,
              },
            },
          },
          toolbarConfig: {
            showSelectAllColumnChooser: true,
            hideToolbar: false,
          },
        },
        toolbarActions: [
          ...(isPermitted(RISK_CONTROLS_PERMISSIONS.DELETE.name)
            ? [
                {
                  label: 'Delete',
                  execute: async (props: ActionData) => {
                    setSelectedRiskControl(cloneDeep(props.selectedRows));
                    setDialogAction('delete');
                    setShowDialog(true);
                    return Promise.resolve({
                      shouldGridReload: true,
                      shouldClearSelection: true,
                      shouldClearSelectedItem: true,
                    });
                  },
                  show: (props: ActionData) => !!(props.selectedRowIds as string[])?.length,
                },
              ]
            : []),
          ...(isPermitted(RISK_CONTROLS_PERMISSIONS.CREATE.name)
            ? [
                {
                  label: 'Duplicate',
                  execute: async (props: ActionData) => {
                    setSelectedRiskControl(cloneDeep(props.selectedRows));
                    setDuplicateOriginalRiskControl(
                      props.selectedRows?.length === 1 ? cloneDeep(props.selectedRows[0]) : undefined,
                    );

                    setDialogAction('duplicate');
                    setShowDialog(true);

                    return Promise.resolve({
                      shouldGridReload: false,
                      shouldClearSelection: false,
                      shouldClearSelectedItem: false,
                    });
                  },
                  show: (props: ActionData) => (props.selectedRowIds as string[])?.length === 1,
                },
              ]
            : []),
        ],
      },
      emptyState: {
        illustration: ControlsEmptyGrid,
        title: `The library doesn't contain any controls`,
        actions: [
          {
            label: 'Create New Control',
            execute: async () => {
              setDialogAction('create');
              setShowDialog(true);
              return Promise.resolve({
                shouldGridReload: true,
                layoutEntities: [{}],
              });
            },
          },
        ],
        show: (layoutState: LayoutContentState) => {
          return (
            !layoutState.useFetchState.isLoading &&
            (!layoutState.isContentReady ||
              !layoutState.useFetchState.rows ||
              !layoutState?.useFetchState?.rows?.length)
          );
        },
      },
    };
  }, [fetchGridData, getGridConfig, selectedRiskControl]);

  return (
    <ControlsLibraryContainer>
      <BigidLayout key={layoutKey} config={layoutConfig} />
      <BigidDialog
        isOpen={showDialog && dialogAction === 'duplicate'}
        onClose={() => {
          setSelectedRiskControl(undefined);
          setShowDialog(false);
        }}
        title={'Duplicate Control'}
        borderTop
        buttons={[
          {
            component: SecondaryButton,
            text: 'Cancel',
            onClick: () => {
              setSelectedRiskControl(undefined);
              setShowDialog(false);
            },
          },
          ...(isPermitted(RISK_CONTROLS_PERMISSIONS.CREATE.name)
            ? [
                {
                  component: PrimaryButton,
                  text: 'Duplicate',
                  onClick: async () => {
                    if (selectedRiskControl?.length === 1) {
                      const payload = omit(selectedRiskControl[0], ['id', 'created_at', 'updated_at']);
                      await httpService.post(`risk-controls`, payload);
                      trackManualEvent(RISK_CONTROL_DUPLICATE_CONTROL, payload);
                      // setSelectedRiskControlId(res);
                    }
                    setSelectedRiskControl(undefined);
                    setShowDialog(false);
                    entityEventsEmitter.emit(EntityEvents.RELOAD, {
                      entityId: layoutConfig.content.viewConfig.gridConfig.gridId,
                      shouldEntityReload: true,
                    });
                  },
                  disabled:
                    duplicateOriginalRiskControl && duplicateOriginalRiskControl.name === selectedRiskControl?.[0].name,
                },
              ]
            : []),
        ]}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            gap: 14,
          }}
        >
          <BigidTextField
            label="Control Name"
            required
            value={selectedRiskControl?.length ? selectedRiskControl[0].name : ''}
            onChange={event => {
              if (selectedRiskControl?.length === 1) {
                const controlClone = cloneDeep(selectedRiskControl);
                controlClone[0].name = event.target.value;
                setSelectedRiskControl(controlClone);
              }
            }}
          ></BigidTextField>
          <BigidTextField
            label="Description"
            value={selectedRiskControl?.length ? selectedRiskControl[0].description : ''}
            onChange={event => {
              if (selectedRiskControl?.length === 1) {
                const controlClone = cloneDeep(selectedRiskControl);
                controlClone[0].description = event.target.value;
                setSelectedRiskControl(controlClone);
              }
            }}
          ></BigidTextField>
        </div>
      </BigidDialog>

      <BigidDialog
        isOpen={showDialog && dialogAction === 'delete'}
        onClose={() => {
          setSelectedRiskControl(undefined);
          setShowDialog(false);
        }}
        title={'Control Deletion'}
        borderTop
        buttons={[
          {
            component: SecondaryButton,
            text: 'Cancel',
            onClick: () => {
              setSelectedRiskControl(undefined);
              setShowDialog(false);
            },
          },
          {
            component: PrimaryButton,
            text: 'Delete Control',
            onClick: async () => {
              if (selectedRiskControl && checked) {
                selectedRiskControl
                  .map(control => control.id)
                  .forEach(async id => {
                    await httpService.delete(`risk-controls/${id}`);
                  });
                selectedRiskControl.forEach(risk => {
                  trackManualEvent(RISK_CONTROL_DELETE_CONTROL, { ...risk });
                });
              }
              setSelectedRiskControl(undefined);
              setShowDialog(false);
              setChecked(false);
              entityEventsEmitter.emit(EntityEvents.RELOAD, {
                entityId: layoutConfig.content.viewConfig.gridConfig.gridId,
                shouldEntityReload: true,
              });
            },
            disabled: !checked,
          },
        ]}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            gap: 14,
          }}
        >
          {selectedRiskControl?.length === 1 && (
            <BigidBody1>
              {' You are about to delete the Control'} <b>{`“${selectedRiskControl[0].name}”`}</b>
              {'.'}
            </BigidBody1>
          )}

          {selectedRiskControl && selectedRiskControl?.length > 1 && (
            <BigidBody1>
              {`You are about to delete ${selectedRiskControl?.length} Controls. As a result, all risks using these
              controls will be updated, and the risk summary may be affected.`}
            </BigidBody1>
          )}
          <BigidBody1>
            {
              'As a result, all risks using this control will be updated, and the risk summary may be affected. This action can&lsquo;t be undone.'
            }
          </BigidBody1>
          <BigidBody1>{'This action can&lsquo;t be undone'}</BigidBody1>
          <BigidBody1>{'Are you sure you want to delete the control?'}</BigidBody1>
          <BigidCheckbox
            checked={checked}
            onChange={(_event, value) => setChecked(value)}
            label="I understand that multiple risks using this control may be affected"
          />
        </div>
      </BigidDialog>

      {showDialog && dialogAction === 'create' && (
        <CreateControlDialog
          isOpen={showDialog && dialogAction === 'create'}
          setShowDialog={setShowDialog}
          onClose={() => {
            setShowDialog(false);
            entityEventsEmitter.emit(EntityEvents.RELOAD, {
              entityId: layoutConfig.content.viewConfig.gridConfig.gridId,
              shouldEntityReload: true,
            });
          }}
        />
      )}
    </ControlsLibraryContainer>
  );
};
