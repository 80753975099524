import React from 'react';
import { Step } from '@bigid-ui/guided-tour';
import { BigidScanIllustration } from '@bigid-ui/icons';
import { BigidHeading6, BigidHeading5, BigidBody1 } from '@bigid-ui/components';
import { useStyles } from './BigidGuidedToursStyles';

type stepsTypes = 'smallIdDashboard';

export const useBigIdGuidedTourSteps = (typeOfSteps: stepsTypes) => {
  const smallIDTourClasses = useStyles();

  const smallIdDashboardGuidedProps: Step[] = [
    {
      content: (
        <div className={smallIDTourClasses.firstStepWrapper}>
          <BigidHeading5 className={smallIDTourClasses.heading4}>Your first scan is in progress.</BigidHeading5>
          <BigidScanIllustration />
          <BigidBody1 className={smallIDTourClasses.body1}>
            As the scan progresses, the results will be displayed on your Dashboard.
            <br />
            Let us guide you through how to get the most out of SmallID!
          </BigidBody1>
        </div>
      ),
      title: 'Way To Go!',
      target: '#app',
      disableBeacon: true,
      hideBackButton: true,
      placement: 'center',
      spotlightPadding: -4,
      styles: {
        spotlight: {
          marginLeft: 4,
        },
      },
    },
    {
      content: (
        <BigidHeading6 className={smallIDTourClasses.heading6}>
          Use your personalized dashboard to find your scanned data sources, where your policy findings are, which data
          policies were triggered, and more.
          <br />
          <br />
          You can deep-dive into each of the tiles by clicking on it.
        </BigidHeading6>
      ),
      target: '.custom-dashboard-wrapper > div:nth-of-type(1) > div > div > div:nth-of-type(1)',
      disableBeacon: true,
      hideBackButton: true,
      placement: 'right-start',
      spotlightPadding: -4,
      styles: {
        spotlight: {
          marginLeft: 4,
        },
      },
    },
    {
      content: (
        <BigidHeading6 className={smallIDTourClasses.heading6}>
          SmallID’s Sensitivity Classification automatically tags your data according to default sensitivity levels. You
          can also define your own for your specific needs.
          <br />
          <br />
          It gives you an overview of the level of risk across your data, to prioritize areas of focus for security
          controls and comply with regulatory mandates.
        </BigidHeading6>
      ),
      target: '.custom-dashboard-wrapper > div:nth-of-type(1) > div > div > div:nth-of-type(2)',
      disableBeacon: true,
      hideBackButton: false,
      placement: 'right-start',
      spotlightPadding: -4,
      styles: {
        spotlight: {
          marginLeft: 4,
        },
      },
    },
    {
      content:
        'You can instantly see which PIIs appear the most frequently in your data and which data sources have the most sensitive, hence highly vulnerable data.',
      target: '.custom-dashboard-wrapper > div:nth-of-type(1) > div > div > div:nth-of-type(3)',
      disableBeacon: true,
      hideBackButton: false,
      placement: 'right-start',
      spotlightPadding: -4,
      styles: {
        spotlight: {
          marginLeft: 4,
        },
      },
    },
    {
      content:
        'You can also go directly to the classification tab and explore your findings by residencies, data sources, or applications.',
      target: '[data-aid="BigidNavigationItem-data-explorer"]',
      disableBeacon: true,
      hideBackButton: false,
      placement: 'right-start',
      spotlightPadding: 0,
    },
    {
      content: (
        <BigidHeading6 className={smallIDTourClasses.heading6}>
          Dive deeper into the details of your data objects with the Metadata Catalog.
          <br />
          <br />
          From what type of data is in each object, who’s the owner, advanced filtering and exporting abilities, and
          further actions you can take to reduce risks in seconds.
        </BigidHeading6>
      ),
      target: '[data-aid="BigidNavigationItem-data-explorer"]',
      disableBeacon: true,
      hideBackButton: false,
      placement: 'right-start',
      spotlightPadding: 0,
    },
    {
      content:
        'You can always click the support icon to learn more or speak with one of our product experts for further assistance - we’ll be happy to help at any time!',
      target: '[data-aid="BigidHeaderAction-help"]',
      disableBeacon: true,
      hideBackButton: false,
      placement: 'auto',
    },
  ];

  switch (typeOfSteps) {
    case 'smallIdDashboard':
      return smallIdDashboardGuidedProps;
    default:
      return [];
  }
};
