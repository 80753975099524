import { styled } from '@mui/material';
import React, { useMemo } from 'react';
import { BasicDetails } from './BasicDetails';
import { useQueries } from 'react-query';
import {
  getSystemUsers,
  GetSystemUsersResponse,
} from '../../../../../../DataCatalog/DataCatalogDetails/DataCatalogDetailsService';
import { BigidLoader } from '@bigid-ui/components';
import { getEmail } from '../../../../../../DataCatalog/DataCatalogDetails/DataCatalogOwnersDialog';
import { isPermitted } from '../../../../../../../services/userPermissionsService';
import { CATALOG_PERMISSIONS, TAGS_PERMISSIONS } from '@bigid/permissions';
import { OverviewTags } from './OverviewTags';
import { OverviewCategories } from './OverviewCategories';
import { Category, getAttributesByObjectName } from '../../../../../../DataCatalog/DataCatalogAttributes';
import { uniqBy } from 'lodash';
import { useGetObjectDetails } from '../hooks/useGetObjectDetails';
import { getDataSourceDetailsByName } from '../../../../../explorerSearchResultsService';
import { CatalogSidePanelProps } from '../CatalogSidePanel';

const Root = styled('div')`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  gap: 32px;
`;

export const Overview = ({ selectedItem }: CatalogSidePanelProps) => {
  const { data, id } = selectedItem;
  const fqn = String(id);
  const { isCreateTagsPermitted, isReadTagsPermitted, isObjectTagsAssignmentPermitted } = useMemo(
    () => ({
      isCreateTagsPermitted: isPermitted(TAGS_PERMISSIONS.CREATE.name),
      isReadTagsPermitted: isPermitted(TAGS_PERMISSIONS.READ.name),
      isObjectTagsAssignmentPermitted: isPermitted(CATALOG_PERMISSIONS.ASSIGN_TAG.name),
    }),
    [],
  );

  const objectDetailsResponse = useGetObjectDetails(fqn);

  const [usersListResponse, objectCategoriesResponse, dataSourceDetailsResponse] = useQueries([
    {
      queryKey: 'usersList',
      queryFn: async () => {
        try {
          const systemUsers = await getSystemUsers();
          const filterSystemUsersWithMail = systemUsers.filter(({ id }) => getEmail(id));
          const systemUsersList = filterSystemUsersWithMail.map((user: GetSystemUsersResponse) => {
            return { label: user.firstName, subLabel: user.id, value: user._id };
          });
          return systemUsersList;
        } catch ({ message }) {
          console.error(`An error has occurred: ${message}`);
          return [];
        }
      },
    },
    {
      queryKey: 'getObjectCategories',
      queryFn: async () => {
        try {
          const attributes = await getAttributesByObjectName(fqn);
          const categories: Category[] = [];
          attributes.data.forEach(attribute => {
            if (attribute.categories) {
              categories.push(...attribute.categories);
            }
          });

          return uniqBy(categories, 'unique_name');
        } catch ({ message }) {
          console.error(`An error has occurred: ${message}`);
          return [];
        }
      },
      placeholderData: [],
    },
    {
      queryKey: ['getDataSourceDetails', data.datasource?.value],
      queryFn: async () => getDataSourceDetailsByName(data.datasource?.value),
      enabled: !!data.datasource?.value,
    },
  ]);

  const { data: objectDetails, refetch: refetchDetails, isLoading } = objectDetailsResponse;
  const { data: usersList, refetch: refetchUsers } = usersListResponse;
  const { data: objectCategories } = objectCategoriesResponse;
  const { data: dataSourceDetails } = dataSourceDetailsResponse;

  const refetchData = () => {
    refetchDetails();
    refetchUsers();
  };
  return (
    <Root>
      {isLoading ? (
        <BigidLoader />
      ) : (
        <>
          <OverviewTags
            isReadTagsPermitted={isReadTagsPermitted}
            isCreateTagsPermitted={isCreateTagsPermitted}
            objectDetails={objectDetails.data}
            isObjectTagsAssignmentPermitted={isObjectTagsAssignmentPermitted}
            refetchDetails={refetchDetails}
          />
          <OverviewCategories categories={objectCategories} />
          <BasicDetails
            details={objectDetails?.data}
            refetchData={refetchData}
            usersList={usersList}
            dsOwners={dataSourceDetails?.owners_v2}
          />
        </>
      )}
    </Root>
  );
};
