import { httpService } from '../../services/httpService';
import { MetadataSearchResultsGridRow } from './MetadataSearchResults';
import {
  MetadataSearchEntityType,
  MetadataSearchEntityTypeConfig,
  MetadataSearchEntityTypeIcons,
  MetadataSearchQuery,
  MetadataSearchResultEntity,
  MetadataSearchResultEntityFieldName,
  MetadataSearchResultsPerType,
  MetadataSearchSupportedEntityTypes,
} from '../../components/MetadataSearch/MetadataSearchTypes';
import {
  MetadataSearchFieldFilter,
  MetadataSearchFilterCommonFieldName,
  MetadataSearchFilterFieldType,
} from '../../components/MetadataSearch/MetadataSearchFilters/MetadataSearchFiltersTypes';
import {
  formatDate,
  MetadataSearchEntityLabelMap,
  getSearchResultEntitiesPreprocessed,
  getMetadataSearchEntityTypeLabel,
  getMetadataSearchEntityIcon,
  getEntityQuantityModifierByCount,
  getEntityTypeConfigByTypeId,
} from '../../components/MetadataSearch/MetadataSearchUtils';
import {
  getGridCellContentPlural,
  getGridCellContentSingular,
} from '../../components/MetadataSearch/MetadataSearchResultsGrid/MetadataSearchResultsGridUtils';
import { getMetadataSearchEntityFieldByName } from '../../components/MetadataSearch/MetadataSearchService';
import { AxiosError } from 'axios';
import { BigidCardListItemValue, BigidCardListItemAssets, BigidColorsV2, BigidChipProps } from '@bigid-ui/components';
import {
  clearUndeterminedFilterValues,
  preprocessFilter,
} from '../../components/MetadataSearch/MetadataSearchFilters/MetadataSearchFiltersUtils';
import { formatNumberCompact } from '../../utilities/numericDataConverter';
import { getTagConvertedToDisplayForm } from '../../utilities/tags';

export interface PagingObject {
  skip: number;
  limit: number;
}

export interface SortingObject {
  field: string;
  order: 'asc' | 'desc';
}

export type MetadataFetchFullSearchPayload = {
  text: MetadataSearchQuery;
  filter?: MetadataSearchFieldFilter[];
  paging?: PagingObject;
  sorting?: SortingObject[];
};

export type MetadataFetchFullSearchResponse = {
  results: MetadataSearchResultEntity[];
  isTimeoutReached?: true;
};

export const getSearchResults = async (
  payload: MetadataFetchFullSearchPayload,
): Promise<MetadataFetchFullSearchResponse> => {
  try {
    const { data } = await httpService.post<MetadataFetchFullSearchResponse, MetadataFetchFullSearchPayload>(
      'metadata-search/search/full',
      payload,
    );

    return data;
  } catch (error) {
    const { response } = error as AxiosError;

    if (response.status === 408) {
      return {
        results: [],
        isTimeoutReached: true,
      };
    } else {
      return {
        results: [],
      };
    }
  }
};

export const formatResults = (
  results: MetadataSearchResultEntity[],
  entityTypeConfigs: MetadataSearchEntityTypeConfig[],
  supportedEntityTypes: MetadataSearchSupportedEntityTypes,
  iconMap: MetadataSearchEntityTypeIcons,
): MetadataSearchResultsGridRow[] => {
  return getSearchResultEntitiesPreprocessed(results, entityTypeConfigs, supportedEntityTypes).map(resultEntity => {
    const { type } = resultEntity;
    const entityIdFieldObject = getMetadataSearchEntityFieldByName(
      resultEntity,
      MetadataSearchResultEntityFieldName.ENTITY_ID,
    );
    const entityId = getGridCellContentSingular(entityIdFieldObject, true).cellValue;

    const nameFieldObject = getMetadataSearchEntityFieldByName(resultEntity, MetadataSearchResultEntityFieldName.NAME);
    const nameFieldObjectPreprocessed = getGridCellContentSingular(nameFieldObject);
    let name: BigidCardListItemValue;

    if (nameFieldObjectPreprocessed.cellValue) {
      const { cellValue, tooltipValue } = nameFieldObjectPreprocessed;
      name = {
        value: cellValue,
        tooltipValue,
        tooltipTitle: nameFieldObject?.originalField || 'Name',
      };
    }

    const containerFieldObject = getMetadataSearchEntityFieldByName(
      resultEntity,
      MetadataSearchResultEntityFieldName.CONTAINER,
    );
    const containerFieldObjectPreprocessed = getGridCellContentSingular(containerFieldObject);
    let container: BigidCardListItemValue = {
      label: 'Container',
      value: 'N/A',
    };

    if (containerFieldObjectPreprocessed.cellValue) {
      const { cellValue, tooltipValue } = containerFieldObjectPreprocessed;
      container = {
        ...container,
        value: cellValue,
        tooltipValue,
        tooltipTitle: containerFieldObject?.originalField || 'Container',
      };
    }

    let owner: BigidCardListItemValue = null;

    if (type !== MetadataSearchEntityType.FILE) {
      const ownerFieldObject = getMetadataSearchEntityFieldByName(
        resultEntity,
        MetadataSearchResultEntityFieldName.OWNER,
      );
      const ownerFieldObjectPreprocessed = getGridCellContentPlural(ownerFieldObject, 'Owner Name');
      owner = {
        label: 'Owner Name',
        value: 'N/A',
      };

      if (ownerFieldObjectPreprocessed.cellValue) {
        const { cellValue, tooltipValue } = ownerFieldObjectPreprocessed;
        owner = {
          ...owner,
          value: cellValue,
          tooltipValue,
          tooltipTitle: ownerFieldObject?.originalField || 'Owner Name',
        };
      }
    }

    const updateDateFieldObject = getMetadataSearchEntityFieldByName(
      resultEntity,
      MetadataSearchResultEntityFieldName.UDPATE_DATE,
    );
    const updateDateFieldObjectPreprocessed = getGridCellContentSingular(updateDateFieldObject);
    let updateDate: BigidCardListItemValue = {
      label: 'Modified Date',
      value: 'N/A',
    };

    if (updateDateFieldObjectPreprocessed.cellValue) {
      const { cellValue, tooltipValue } = updateDateFieldObjectPreprocessed;

      updateDate = {
        ...updateDate,
        value: formatDate(cellValue),
        tooltipValue: tooltipValue.map(value => formatDate(value)).filter(value => value),
        tooltipTitle: updateDateFieldObject?.originalField || 'Modified Date',
      };
    }

    const attributesFieldObject = getMetadataSearchEntityFieldByName(
      resultEntity,
      MetadataSearchResultEntityFieldName.ATTRIBUTES,
    );

    const attributesFieldObjectPreprocessed = getGridCellContentPlural(attributesFieldObject, 'Attributes');

    const attributes: BigidCardListItemAssets = {
      label: 'Attributes',
      value: [],
    };

    if (attributesFieldObjectPreprocessed.cellValue) {
      const { tooltipValue } = attributesFieldObjectPreprocessed;
      const highlightedAttribute = attributesFieldObject?.highlightedValue?.replace(/<\/?[^>]+(>|$)/g, '');

      attributes.value = tooltipValue
        .sort((attributeA, attributeB) => {
          return attributeA === highlightedAttribute ? -1 : attributeB === highlightedAttribute ? 1 : 0;
        })
        .map(attribute => {
          const shouldHighlight = attribute === highlightedAttribute;
          const bgColor = shouldHighlight ? '#FAF2C9' : BigidColorsV2.white;

          return {
            id: attribute,
            label: attribute,
            bgColor,
          };
        });
    }

    const entityTypeConfig = getEntityTypeConfigByTypeId(entityTypeConfigs, type);
    const quantityModifier = getEntityQuantityModifierByCount(1);
    const { icon, name: iconName } = getMetadataSearchEntityIcon(type, iconMap, entityTypeConfig);
    const iconText =
      getMetadataSearchEntityTypeLabel(type, MetadataSearchEntityLabelMap, entityTypeConfig, quantityModifier) ||
      type.toString();

    return {
      id: entityId,
      entityId,
      entity: {
        ...resultEntity,
        assets: resultEntity.assets?.filter(
          ({ name, value }) =>
            (typeof value === 'string' || typeof value === 'number' || typeof value === 'boolean') && name,
        ),
      },
      name,
      icon,
      iconName,
      iconText,
      container,
      owner,
      updateDate,
      attributes,
    };
  });
};

export const getTotalCount = (countByType: MetadataSearchResultsPerType[]) => {
  return countByType?.map(({ count }) => count).reduce((total, count) => total + count, 0) || 0;
};

export const parseFiltersToChips = (
  filters: MetadataSearchFieldFilter[],
  entityTypeConfigs: MetadataSearchEntityTypeConfig[],
): BigidChipProps[] => {
  const filterPreprocessed = preprocessFilter(filters);

  return clearUndeterminedFilterValues(filterPreprocessed)
    .reduce((filtersFlatten, filter) => {
      if (Array.isArray(filter.value)) {
        return filter.fieldType === MetadataSearchFilterFieldType.DATE
          ? [...filtersFlatten, filter]
          : [...filtersFlatten, ...filter.value.map(value => ({ ...filter, value }))];
      } else {
        return [...filtersFlatten, filter];
      }
    }, [] as MetadataSearchFieldFilter[])
    .reduce((chips, { field, value, originalValue, fieldType, fieldDisplayName, fieldDisplayValue }) => {
      switch (fieldType) {
        case MetadataSearchFilterFieldType.DATE: {
          if (Array.isArray(value)) {
            const [fromDate, toDate] = value;

            return [
              ...chips,
              {
                id: `${field}||${JSON.stringify(originalValue || value)}`,
                label: `${fieldDisplayName || field} : ${fromDate} - ${toDate}`,
                bgColor: BigidColorsV2.gray[400],
              },
            ];
          } else {
            return [
              ...chips,
              {
                id: `${field}||${JSON.stringify(originalValue || value)}`,
                label: `${fieldDisplayName || field} : ${value}`,
                bgColor: BigidColorsV2.gray[400],
              },
            ];
          }
        }
        case MetadataSearchFilterFieldType.TAGS: {
          const tags = Array.isArray(value) ? value : [value];

          return [
            ...chips,
            ...tags.map(tag => ({
              id: `${field}||${JSON.stringify(originalValue || value)}`,
              label: `${fieldDisplayName || field} : ${getTagConvertedToDisplayForm(String(tag))}`,
              bgColor: BigidColorsV2.gray[400],
            })),
          ];
        }
        case MetadataSearchFilterFieldType.ENTITY_TYPE: {
          const customEntityType = entityTypeConfigs.find(
            ({ entityTypeId }) => entityTypeId === value || entityTypeId === originalValue,
          );

          return [
            {
              id: `${field}||${JSON.stringify(originalValue || value)}`,
              label: `${fieldDisplayName || field} : ${
                getMetadataSearchEntityTypeLabel(
                  value as MetadataSearchEntityType,
                  MetadataSearchEntityLabelMap,
                  customEntityType,
                  'singular',
                ) || value
              }`,
              bgColor: BigidColorsV2.gray[400],
            },
            ...chips,
          ];
        }
        default: {
          return [
            ...chips,
            {
              id: `${field}||${JSON.stringify(originalValue || value)}`,
              label: `${fieldDisplayName || field} : ${fieldDisplayValue || value}`,
              bgColor: BigidColorsV2.gray[400],
            },
          ];
        }
      }
    }, []);
};

export const getListTotalLabel = (
  totalCount: number,
  filters: MetadataSearchFieldFilter[],
  entityTypeConfigs: MetadataSearchEntityTypeConfig[],
): string => {
  let label: string;

  const entityTypeFilter = filters?.find(({ field }) => field === MetadataSearchFilterCommonFieldName.ENTITY_TYPE);

  if (entityTypeFilter) {
    const quantityModifier = getEntityQuantityModifierByCount(totalCount);
    const type = entityTypeFilter.value as MetadataSearchEntityType;
    const entityTypeConfig = getEntityTypeConfigByTypeId(entityTypeConfigs, type);

    label =
      getMetadataSearchEntityTypeLabel(type, MetadataSearchEntityLabelMap, entityTypeConfig, quantityModifier) ||
      entityTypeFilter.value.toString();
  } else {
    label = totalCount === 1 ? 'result' : 'results';
  }

  return `${formatNumberCompact(totalCount)} ${label}`;
};
