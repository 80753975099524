import React, { FC, ComponentType } from 'react';
import { styled } from '@mui/material';
import { BigidBody1 } from '@bigid-ui/components';
import { generateDataAid } from '@bigid-ui/utils';
import { IconComponentProps } from '@bigid-ui/icons';

const Root = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

const Text = styled('div')`
  text-align: left;
`;

const Body = styled(BigidBody1)`
  margin-top: 4px;
`;

const IconContainer = styled('div')`
  margin-top: 16px;
  display: flex;
  justify-content: center;
`;

interface CatalogDiscoveryGuidedTourStepProps {
  dataAid: string;
  icon?: ComponentType<IconComponentProps>;
  body: string;
}

export const CatalogDiscoveryGuidedTourStep: FC<CatalogDiscoveryGuidedTourStepProps> = ({
  dataAid = 'CatalogDiscoveryGuidedTourStep',
  body,
  icon: Icon,
}) => {
  return (
    <Root data-aid={dataAid}>
      <Text>
        <Body data-aid={generateDataAid(dataAid, ['body'])}>{body}</Body>
      </Text>
      {Icon && (
        <IconContainer>
          <Icon dataAid={generateDataAid(dataAid, ['icon'])} />
        </IconContainer>
      )}
    </Root>
  );
};
