import React, { FC, useEffect } from 'react';
import { BigidSuggestedActionBoxHeader } from './BigidSuggestedActionBoxHeader';
import { ActionSidebar } from './mappers/styles';
import {
  BigidButtonIconProps,
  BigidContentCard,
  BigidHighlightIndicator,
  BigidLoader,
  BigidPaper,
} from '@bigid-ui/components';
import makeStyles from '@mui/styles/makeStyles';
import { generateDataAid } from '@bigid-ui/utils';
import { useTheme } from '@mui/styles';
import {
  DataSourcesUITrackingEvent,
  trackEventDataSources,
} from '../../views/DataSources/DataSourcesEventTrackerUtils';

const useStyles = makeStyles(theme => ({
  paper: {
    height: '100%',
    width: '100%',
    flex: 'none',
    border: `1px solid ${theme.vars.palette.bigid.gray200}`,
    boxShadow: '0px 1px 12px 0px rgba(0, 0, 0, 0.05)',
    display: 'flex',
    borderRadius: '8px',
    padding: '12px',
  },
}));

export type BigidSuggestedActionBoxAction = {
  id: string;
  dataAid?: string;
  content: FC;
  footer?: FC;
  label: string;
  showIndicator?: boolean;
  actions?: BigidSuggestedActionBoxToolbarAction[];
};

export type BigidSuggestedActionBoxProps = {
  dataAid?: string;
  title: string;
  actions?: BigidSuggestedActionBoxAction[];
  selected?: string;
  loading?: boolean;
  showExpandIcon?: boolean;
};

export type BigidSuggestedActionBoxToolbarAction = BigidButtonIconProps & {
  onClick: (action: string, ...args: any[]) => void;
  id: string;
};

// @info add visible offset when the card is collapsed
const CARD_COLLAPSE_OFFSET = 0;

export const BigidSuggestedActionBox = ({
  dataAid = 'BigidSuggestedActionBox',
  title,
  actions,
  loading = false,
  selected: action,
  showExpandIcon = false,
}: BigidSuggestedActionBoxProps): JSX.Element => {
  const { paper } = useStyles();
  const theme = useTheme();

  useEffect(() => {
    if (!loading) {
      const suggestedActionsTypes = actions.map(item => item.id);
      trackEventDataSources(DataSourcesUITrackingEvent.SUGGESTED_ACTIONS_VIEW_SUGGESTIONS_CLICK, {
        suggestedActionsCount: actions?.length,
        suggestedActionsTypes,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [actions]);

  return (
    <BigidPaper classes={{ root: paper }}>
      <ActionSidebar.Wrapper showExpandIcon={showExpandIcon} data-aid={dataAid}>
        <ActionSidebar.Header>{title && <BigidSuggestedActionBoxHeader title={title} />}</ActionSidebar.Header>
        <ActionSidebar.Content>
          {loading ? (
            <BigidLoader />
          ) : (
            actions?.map(({ content: Content, footer: Footer, id, actions, label, showIndicator }) => (
              <ActionSidebar.Card key={id} offset={CARD_COLLAPSE_OFFSET}>
                {showIndicator && (
                  <ActionSidebar.Indicator>
                    <BigidHighlightIndicator color={theme.vars.palette.bigid.red600} diameter={6} />
                  </ActionSidebar.Indicator>
                )}
                <BigidContentCard
                  dataAid={generateDataAid(dataAid, ['card'])}
                  expandable={{ expandOnHover: false, defaultExpanded: true }}
                  headerTitle={label}
                  isSelected={action === id}
                  headerActions={actions}
                  footerLeftItem={<Footer />}
                >
                  <Content />
                </BigidContentCard>
              </ActionSidebar.Card>
            ))
          )}
        </ActionSidebar.Content>
      </ActionSidebar.Wrapper>
    </BigidPaper>
  );
};
