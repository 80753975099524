import { loginService } from '../../authentication/login/login.service';
import { SystemEvents, systemEventsEmitter } from './systemEvents';
import { sessionStorageService } from '../../common/services/sessionStorageService';
import { getApplicationPreference } from './appPreferencesService';
import { showConfirmationDialog } from './confirmationDialogService';
import { createNativeNotification } from './nativeNotificationService';

const MIN_TIMEOUT_IN_MINUTES = 3;
const DEFAULT_TIMEOUT_IN_MINUTES = 15;
const INTERVAL_TIMEOUT = 30000;
const UI_IDLE_CONFIRMATION_DIALOG_TIMEOUT = 30000;

class HeartBeat {
  readonly events = ['touchstart', 'mousedown', 'click', 'keydown', 'keypress', 'resize', 'wheel', 'scroll', 'zoom'];

  private intervalId: number;
  private lastActivityTimestamp: number;
  private removeWindowListeners = noob;

  constructor() {
    this.activate = this.activate.bind(this);
    this.deactivate = this.deactivate.bind(this);

    systemEventsEmitter.addEventListener(SystemEvents.LOGIN, () => {
      this.activate();
    });
  }

  public activate() {
    this.deactivate();

    const idleTimeoutMs = getValidTimeout(parseInt(getApplicationPreference<string>('UI_IDLE_TIMEOUT_IN_MINUTES'), 10));

    this.lastActivityTimestamp = getTimestamp();

    const isExpired = () => {
      const timeInactive = getTimestamp() - this.lastActivityTimestamp;
      return timeInactive >= idleTimeoutMs;
    };

    const deactivateAndLogout = async () => {
      this.deactivate();
      return loginService.logout(true);
    };

    const openActivityTimeoutDialog = async () => {
      await createNativeNotification({
        text: 'modals.activityTimeoutDialog.customDescription',
        hideIfTabInFocus: true,
        onClick: () => {
          this.lastActivityTimestamp = getTimestamp();
        },
      });

      const shouldKeepSessionActive = await showConfirmationDialog({
        entityNameSingular: '',
        entityNamePlural: '',
        actionName: 'modals.activityTimeoutDialog.actionName',
        customDescription: `modals.activityTimeoutDialog.customDescription`,
        actionButtonName: 'modals.activityTimeoutDialog.actionButtonName',
        cancelButtonName: 'modals.activityTimeoutDialog.cancelButtonName',
        showCloseIcon: false,
      });

      if (shouldKeepSessionActive) {
        this.lastActivityTimestamp = getTimestamp();
      } else {
        return deactivateAndLogout();
      }
    };

    /**
     * the tick should be shorter than a minute due to known issues with long intervals / timeouts
     * on each tick, we check if there was an activity and user still logged in
     */
    const onTick = () => {
      if (!this.isUserLoggedIn()) {
        this.deactivate();
        return;
      }

      if (isExpired()) {
        const timeoutAfter = (ms: number) => {
          return new Promise((resolve, reject) => {
            setTimeout(() => {
              reject(new Error());
            }, ms);
          });
        };

        const handleTimeoutDialog = async () => {
          try {
            return await Promise.race([timeoutAfter(UI_IDLE_CONFIRMATION_DIALOG_TIMEOUT), openActivityTimeoutDialog()]);
          } catch {
            console.log('Session timed out');
            return deactivateAndLogout();
          }
        };

        return handleTimeoutDialog();
      }
    };

    const activityListener = (event?: Event) => {
      // exit here if already expired
      if (isExpired()) {
        return;
      }
      this.lastActivityTimestamp = getTimestamp();
    };

    const onVisibilityChange = () => {
      if (!document.hidden) {
        // execute the tick after returning from background
        onTick();
      }
    };

    document.addEventListener('visibilitychange', onVisibilityChange, false);

    this.events.forEach(eventName => {
      window.addEventListener(eventName, activityListener, {
        capture: true,
        passive: true,
      });
    });

    systemEventsEmitter.addEventListener(SystemEvents.IFRAME_HEARTBEAT, activityListener);

    this.removeWindowListeners = () => {
      this.events.forEach(eventName =>
        window.removeEventListener(eventName, activityListener, {
          capture: true,
        }),
      );
      systemEventsEmitter.removeEventListener(SystemEvents.IFRAME_HEARTBEAT, activityListener);
      document.removeEventListener('visibilitychange', onVisibilityChange, false);
      this.removeWindowListeners = noob;
    };

    this.intervalId = window.setInterval(() => onTick(), INTERVAL_TIMEOUT);
  }

  public deactivate() {
    this.stopInterval();
    this.removeWindowListeners();
  }

  private stopInterval() {
    window.clearInterval(this.intervalId);
    this.intervalId = undefined;
  }

  /** Temporary solution, since we don't have similar method in the app */
  private isUserLoggedIn() {
    return !!sessionStorageService.get('bigIdTokenID');
  }
}

function getTimestamp() {
  return Date.now();
}

// eslint-disable-next-line @typescript-eslint/no-empty-function
function noob() {}

function getValidTimeout(uiIdleTimeoutInMinutes: number) {
  if (isNaN(uiIdleTimeoutInMinutes)) {
    console.warn(`Session timeout is invalid '${uiIdleTimeoutInMinutes}', falling back to default value.`);
    uiIdleTimeoutInMinutes = DEFAULT_TIMEOUT_IN_MINUTES;
  }
  if (uiIdleTimeoutInMinutes < MIN_TIMEOUT_IN_MINUTES) {
    console.warn(
      `Session timeout is too small '${uiIdleTimeoutInMinutes}', must be at least ${MIN_TIMEOUT_IN_MINUTES}',` +
        ` falling back to default value.`,
    );
    uiIdleTimeoutInMinutes = DEFAULT_TIMEOUT_IN_MINUTES;
  }
  if (uiIdleTimeoutInMinutes > 36e5) {
    console.warn(`Session timeout is larger than 1 Hour. Long timeouts may have unexpected results...`);
  }

  return uiIdleTimeoutInMinutes * 60 * 1000;
}

export const heartBeatService = new HeartBeat();
