import React, { FC, useEffect, useState } from 'react';
import { BigidTextField, PrimaryButton, BigidLoader } from '@bigid-ui/components';
import { FormControl } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import angular from 'angular';
import { convertToAngular } from '../../../common/services/convertToAngular';
import { httpService } from '../../services/httpService';
import { notificationService } from '../../services/notificationService';

export interface BigidMeConfig {
  account: string;
  username: string;
  password: string;
  protocol: string;
  tenant: string;
  host: string;
  port: string;
  version: number;
  timestamp: string;
}

export interface BigidmeConfigData {
  account: string;
  username: string;
  password: string;
}

export interface BigidmeConfigRequest {
  config: BigidmeConfigData;
}

interface BigidmeConfigResponse {
  config: BigidmeConfigData;
}

const useStyles = makeStyles({
  container: {
    display: 'flex',
    flexGrow: 1,
    flexFlow: 'column nowrap',
    height: '50%',
    padding: '20px 30px',
    overflow: 'auto',
  },
  btnContainer: {
    display: 'flex',
  },
  saveBtn: {
    marginRight: '12px',
  },
});

export const DsarBigidmeConfig: FC = () => {
  const classes = useStyles({});

  const [formData, setFormData] = useState<BigidmeConfigData>(null);

  useEffect(() => {
    async function initialSetup() {
      try {
        const { account, username } = await getConfigurations();
        setFormData({ account, password: '', username });
      } catch (e) {
        const errMsg = `Failed to fetch agent configuration, ${e}`;
        notificationService.error(errMsg);
      }
    }
    initialSetup();
  }, []);

  const saveConfiguration = async () => {
    try {
      await saveConfigurations(formData);
      const msg = `Connection is saved succesfully`;
      notificationService.success(msg);
    } catch (error) {
      const errMsg = `Can't save configuration`;
      notificationService.error(errMsg);
      console.error(`${errMsg} ${error}`);
    }
  };

  const testConfiguration = async () => {
    try {
      await testConnection(formData);
      const msg = `Connection test is succeeded`;
      notificationService.success(msg);
    } catch (error) {
      const errMsg = `Connection test is failed`;
      notificationService.error(errMsg);
      console.error(`${errMsg} ${error}`);
    }
  };

  const handleTextFieldChanges = (fieldName: keyof BigidmeConfigData, value: string) => {
    // TODO: validation + error handling
    setFormData({ ...formData, [fieldName]: value });
  };

  const isFormValid = () => {
    return Object.values(formData).every(value => typeof value === 'string' && value.length);
  };

  return !formData ? (
    <BigidLoader />
  ) : (
    <div className={classes.container}>
      <div>
        <FormControl fullWidth margin="normal">
          <BigidTextField
            errorMessage=""
            label="Account"
            onChange={event => handleTextFieldChanges('account', event.target.value)}
            defaultValue={formData.account}
            placeholder="ip:port"
            type="text"
            required={true}
          />
        </FormControl>
        <FormControl fullWidth margin="normal">
          <BigidTextField
            errorMessage=""
            label="User Name"
            onChange={event => handleTextFieldChanges('username', event.target.value)}
            defaultValue={formData.username}
            placeholder="User Name"
            type="text"
            required={true}
          />
        </FormControl>
        <FormControl fullWidth margin="normal">
          <BigidTextField
            errorMessage=""
            label="Password"
            onChange={event => handleTextFieldChanges('password', event.target.value)}
            defaultValue=""
            placeholder="Password"
            type="password"
            required={true}
          />
        </FormControl>
      </div>
      <div className={classes.btnContainer}>
        <div className={classes.saveBtn}>
          <PrimaryButton onClick={saveConfiguration} size="medium" disabled={!isFormValid()} text="Save" />
        </div>
        <div>
          <PrimaryButton onClick={testConfiguration} size="medium" disabled={!isFormValid()} text="Test Connection" />
        </div>
      </div>
    </div>
  );
};

function saveConfigurations(configData: BigidmeConfigData) {
  return httpService
    .post<BigidmeConfigResponse, BigidmeConfigRequest>(`bigidme-agent/configurations`, {
      config: configData,
    })
    .then(response => response.data);
}

export async function getConfigurations() {
  return httpService.fetch<BigidMeConfig>(`bigidme-agent/configurations`).then(response => response.data);
}

function testConnection(configData: BigidmeConfigData) {
  return httpService
    .post<BigidmeConfigResponse, BigidmeConfigRequest>(`bigidme-agent/configurations/test-connection`, {
      config: configData,
    })
    .then(response => response.data);
}

angular.module('app').component('dsarMeSettings', convertToAngular(DsarBigidmeConfig, null));
