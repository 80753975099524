import React, { FC } from 'react';
import { InteractiveDashboardCard } from './InteractiveDashboardCard';
import { InteractiveDashboardHeadingWithDropdown } from './InteractiveDashboardWidgetHeader';
import { InteractiveDashboardNoData } from './InteractiveDashboardLoading';

export interface InteractiveDashboardLoadingDataProps {
  title: string;
}

export const InteractiveDashboardLoadingData: FC<InteractiveDashboardLoadingDataProps> = ({ title }) => {
  return (
    <InteractiveDashboardCard>
      <InteractiveDashboardHeadingWithDropdown rowsWithActions={[]} titleActions={[]} title={title} />
      <InteractiveDashboardNoData />
    </InteractiveDashboardCard>
  );
};
