import { module } from 'angular';
import { httpService } from '../react/services/httpService';
import { queryService } from '../react/services/queryService';
import { ENTITY_TYPE } from '../react/types/commentsTypes';
const app = module('app');

app.factory('taskListService', [
  '$http',
  'appSettings',
  function ($http, appSettings) {
    return {
      getAllTask: function getAllTask(status, filterBy, type) {
        let queryParameters = '';
        if (status || filterBy) {
          queryParameters = `?status=${status}&role=${filterBy}${type ? `&type=${type}` : ''}`;
        }
        return $http
          .get(appSettings.serverPath + '/' + appSettings.version + '/tasks' + queryParameters)
          .then(function (response) {
            return response;
          });
      },
      getTaskByID: function getTaskByID(id) {
        return $http.get(appSettings.serverPath + '/' + appSettings.version + '/tasks/' + id).then(function (response) {
          return response.data;
        });
      },
      getCountOfUnreadTask: function getAllTask() {
        return $http.get(appSettings.serverPath + '/' + appSettings.version + '/tasks/count').then(function (response) {
          return response;
        });
      },
      getAllTaskByUserId: function getAllTaskByUserId(userId) {
        return $http
          .get(appSettings.serverPath + '/' + appSettings.version + '/tasks?owner=' + userId)
          .then(function (response) {
            return response;
          });
      },
      getAllCommentsByTaskId: function getAllCommentsByTaskId(taskId) {
        const filter = [
          { field: 'entityId', operator: 'equal', value: taskId },
          { field: 'entityType', operator: 'equal', value: ENTITY_TYPE.TASK },
        ];
        const sort = [{ field: 'updated_at', order: 'desc' }];

        const query = queryService.getGridConfigQuery({ filter, sort });
        return httpService.fetch(`comments?${query}`).then(({ data }) => data);
      },
      createNewTask: function createNewTask(data) {
        return $http
          .post(appSettings.serverPath + '/' + appSettings.version + '/tasks', data)
          .then(function (response) {
            return response;
          });
      },
      createNewComment: function createNewComment(data, taskId) {
        return httpService.post('comments', { ...data, entityId: taskId, entityType: ENTITY_TYPE.TASK });
      },
      updateTask: function updateTask(data, taskId) {
        return $http
          .put(appSettings.serverPath + '/' + appSettings.version + '/tasks/' + taskId, data)
          .then(function (response) {
            return response;
          });
      },
      getRuleData: function getRuleData(ruleId) {
        return $http
          .get(`${appSettings.serverPath}/${appSettings.version}/compliance-rules/${ruleId}`)
          .then(function (response) {
            return response;
          });
      },
      updateTasksBulk: data => httpService.put('tasks', data),
    };
  },
]);
