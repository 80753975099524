import {
  ActionsFormatterAlignment,
  BigidGridColumn,
  BigidGridColumnTypes,
  BigidGridWithToolbarProps,
  FormatterOnClickHandlerPayloadData,
} from '@bigid-ui/grid';
import { ServiceConfigurationsModel } from './ServicesConfiguration';
import { BigidEditIcon } from '@bigid-ui/icons';
import { ADVANCE_TOOLS_PERMISSIONS } from '@bigid/permissions';
import { isPermitted } from '../../../services/userPermissionsService';

type editConfigurationCallback = (configurationToEdit: ServiceConfigurationsModel) => void;

export function getServiceConfigurationGridColumns(
  editFunction: editConfigurationCallback,
): BigidGridColumn<ServiceConfigurationsModel>[] {
  const showEditValue = isPermitted(ADVANCE_TOOLS_PERMISSIONS.EDIT_SERVICES_CONFIGURATION.name);
  return [
    {
      name: 'name',
      title: 'Variable Name',
      width: 500,
      getCellValue: row => row.name,
      type: BigidGridColumnTypes.TEXT,
    },
    {
      name: 'service',
      title: 'Service Name',
      width: 300,
      getCellValue: row => row.service,
      type: BigidGridColumnTypes.TEXT,
    },
    {
      name: 'value',
      title: 'Variable Value',
      width: 200,
      type: BigidGridColumnTypes.STATUS,
      getCellValue: ({ hasConflicts = false, value = '' }) => {
        return {
          text: value?.toString() || value,
          status: hasConflicts ? 'warning' : undefined,
        };
      },
    },
    {
      name: 'actions',
      title: 'Actions',
      sortingEnabled: false,
      isNotDraggable: true,
      width: 100,
      getCellValue: row => {
        if (!showEditValue) {
          return null;
        }
        const { hasConflicts, isEditable } = row;
        return {
          alignment: ActionsFormatterAlignment.CENTER,
          actions: [
            {
              name: 'edit',
              iconConfig: {
                icon: BigidEditIcon,
                isVisible: !!isEditable && !hasConflicts,
              },
              execute: async ({ row }: FormatterOnClickHandlerPayloadData) => {
                editFunction(row);
                return {
                  shouldGridReload: false,
                  row,
                };
              },
            },
          ],
        };
      },
      type: BigidGridColumnTypes.ACTIONS,
    } as BigidGridColumn<ServiceConfigurationsModel>,
  ];
}

export async function getServiceConfigurationFilterConfig(): Promise<
  BigidGridWithToolbarProps<ServiceConfigurationsModel>['filterToolbarConfig']
> {
  return {
    filters: [],
    searchConfig: {
      searchFilterKeys: ['name', 'value'],
      initialValue: '',
      placeholder: 'Variable Name or Value',
      operator: 'textSearch',
      autoSubmit: true,
    },
  };
}
