import React, { FC } from 'react';
import { BigidChip } from '@bigid-ui/components';
import { ChipsContainer, ObjectDetailsProps, ObjectDetailsWrapper } from './ObjectTags';
import { CustomAccordeon } from './CustomAccordeon';

export const ObjectAttributes: FC<ObjectDetailsProps> = ({
  objectAttributes,
  dataAid = 'OldObjectAttributes-preview',
}) => {
  return (
    <ObjectDetailsWrapper data-aid={dataAid}>
      <CustomAccordeon header="Attributes">
        <ChipsContainer>
          {objectAttributes?.map((attribute: string) => (
            <BigidChip fontSize="13px" label={attribute} size="medium" shadow={true} bgColor="white" key={attribute} />
          ))}
        </ChipsContainer>
      </CustomAccordeon>
    </ObjectDetailsWrapper>
  );
};
