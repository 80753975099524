import React, { useEffect, useState } from 'react';
import {
  BigidDialog,
  BigidSelectOption,
  BigidRadio,
  PrimaryButton,
  SecondaryButton,
  BigidSelect,
} from '@bigid-ui/components';
import { FormControl, Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { OptionDto, SarFieldsSettingsV2 } from '../ProfileSettingsTypes';
import { GlossaryItemType } from '../../../../../administration/generalSettings/businessGlossary/businessGlossary.service';
import { GlossaryItemSelect } from './GlossaryItemSelect';
import { generateDataAid } from '@bigid-ui/utils';
import { UpdateProfileFieldsPayload, sarConfigService } from '../sarConfigService';
import { notificationService } from '../../../../services/notificationService';
import { DsarSettingsTrackingEvents, trackDsar } from '../../analyticsUtils';

const useStyles = makeStyles((theme: Theme) => ({
  wrapper: {
    display: 'flex',
    justifyContent: 'center',
    flexFlow: 'column nowrap',
    padding: theme.spacing(0, 1),
  },
  dropdownWrapper: {
    padding: theme.spacing(1, 0, 1, 3),
  },
}));

export enum ColumnName {
  FRIENDLY_NAME = 'Friendly Name',
  PURPOSE_OF_USE = 'Purpose of Use',
  CATEGORY = 'Category',
}

const COLUMN_NAME_TO_GLOSSARY_MAPPER = {
  [ColumnName.FRIENDLY_NAME]: GlossaryItemType.PersonalDataItem,
  [ColumnName.PURPOSE_OF_USE]: GlossaryItemType.PurposeOfProcessing,
  [ColumnName.CATEGORY]: GlossaryItemType.PersonalDataCategory,
};

export const COLUMN_NAME_TO_WARNING_MAPPER: Record<ColumnName, keyof SarFieldsSettingsV2> = {
  [ColumnName.FRIENDLY_NAME]: 'isFriendlyNameWarning',
  [ColumnName.PURPOSE_OF_USE]: 'isPurposeOfUseWarning',
  [ColumnName.CATEGORY]: 'isCategoryWarning',
};

const COLUMN_NAME_TO_PROPS_MAPPER: Record<ColumnName, Record<string, keyof SarFieldsSettingsV2>> = {
  [ColumnName.FRIENDLY_NAME]: {
    customId: 'customFriendlyNameId',
    customValue: 'customFriendlyNameValue',
    defaultId: 'defaultFriendlyNameId',
    defaultValue: 'defaultFriendlyNameValue',
    collection: 'friendlyNames',
  },
  [ColumnName.PURPOSE_OF_USE]: {
    customId: 'customPurposeOfUseId',
    customValue: 'customPurposeOfUseValue',
    defaultId: 'defaultPurposeOfUseId',
    defaultValue: 'defaultPurposeOfUseValue',
    collection: 'purposesOfUse',
  },
  [ColumnName.CATEGORY]: {
    customId: 'customCategoryId',
    customValue: 'customCategoryValue',
    defaultId: 'defaultCategoryId',
    defaultValue: 'defaultCategoryValue',
    collection: 'categories',
  },
};

interface EditFieldsCellDialogProps {
  isOpen: boolean;
  columnName: ColumnName;
  field: SarFieldsSettingsV2;
  onClose?: () => void;
  onSubmit?: (val: BigidSelectOption[]) => void;
}

enum EditFieldsCellDialogRadioOptions {
  DEFAULT = 'default',
  CUSTOM = 'custom',
  CLEAR = 'clear',
}

export const EditFieldsCellDialog = ({ isOpen, columnName, field, onSubmit, onClose }: EditFieldsCellDialogProps) => {
  const classes = useStyles({});
  const [isLoading, setIsLoading] = useState(false);
  const { fieldName, id, dsarProfileId } = field;
  const [customId, customValue, defaultId, defaultValue, defaultCollection] = getEntityByColumnName(columnName, field);
  const [selectedRadioOption, setRadioOption] = useState<EditFieldsCellDialogRadioOptions | undefined>();
  const [defaultDropdownOption, setDefaultDropdownOption] = useState<BigidSelectOption[]>([]);
  const [customDropdownOption, setCustomDropdownOption] = useState<BigidSelectOption[]>([]);
  const glossaryItemType = COLUMN_NAME_TO_GLOSSARY_MAPPER[columnName];

  useEffect(() => {
    if (isOpen) {
      setDefaultDropdownOption([{ value: defaultId, label: defaultValue }]);
      setCustomDropdownOption([{ value: customId, label: customValue }]);
      const radioOption =
        defaultId && !customId ? EditFieldsCellDialogRadioOptions.DEFAULT : EditFieldsCellDialogRadioOptions.CUSTOM;
      setRadioOption(radioOption);
    }
  }, [customId, customValue, defaultId, defaultValue, isOpen]);

  const handleOnSave = async () => {
    setIsLoading(true);
    const payload: UpdateProfileFieldsPayload = {
      [COLUMN_NAME_TO_PROPS_MAPPER[columnName].customId]: null,
      [COLUMN_NAME_TO_PROPS_MAPPER[columnName].defaultId]: null,
    };
    let dropdownOption = [];
    if (selectedRadioOption === EditFieldsCellDialogRadioOptions.DEFAULT) {
      payload[COLUMN_NAME_TO_PROPS_MAPPER[columnName].defaultId as keyof UpdateProfileFieldsPayload] =
        defaultDropdownOption[0].value;
      dropdownOption = defaultDropdownOption.map(({ value }) => value);
    } else if (selectedRadioOption === EditFieldsCellDialogRadioOptions.CUSTOM) {
      payload[COLUMN_NAME_TO_PROPS_MAPPER[columnName].customId as keyof UpdateProfileFieldsPayload] =
        customDropdownOption[0].value;
      dropdownOption = customDropdownOption.map(({ value }) => value);
    }
    trackDsar(DsarSettingsTrackingEvents.PROFILE_FIELDS_EDIT_CELL_DIALOG_SAVE_ACTION, {
      column_name: columnName,
      selected_radio_option: selectedRadioOption,
    });

    try {
      await sarConfigService.updateProfileField(dsarProfileId, id, payload);
    } catch (err) {
      notificationService.error(`Could not update field`);
      console.error(err);
    } finally {
      onSubmit(dropdownOption);
      setIsLoading(false);
    }
  };

  const handleOnClose = () => {
    trackDsar(DsarSettingsTrackingEvents.PROFILE_FIELDS_EDIT_CELL_DIALOG_CANCEL_ACTION);
    onClose();
  };

  const handleRadioButtonChange = (option: EditFieldsCellDialogRadioOptions) => {
    setRadioOption(option);
  };

  return (
    <BigidDialog
      title={`Apply ${columnName} for ${fieldName}`}
      isOpen={isOpen}
      onClose={handleOnClose}
      borderTop
      buttons={[
        {
          component: props => <SecondaryButton {...props} bi={{ disabled: true }} />,
          onClick: handleOnClose,
          text: 'Cancel',
        },
        {
          component: props => <PrimaryButton {...props} bi={{ disabled: true }} />,
          onClick: handleOnSave,
          text: 'Apply To Field',
          disabled: !selectedRadioOption,
        },
      ]}
      isLoading={isLoading}
    >
      <div className={classes.wrapper}>
        <form noValidate autoComplete="off">
          <fieldset>
            <FormControl fullWidth margin="normal">
              <div>{`Select, manually specify or clear ${columnName}`}</div>
            </FormControl>
            <FormControl fullWidth margin="normal" required>
              <BigidRadio
                label={`Select suggested ${columnName}`}
                dataAid={generateDataAid('EditFieldsCellDialog', [columnName, 'default'])}
                checked={selectedRadioOption === EditFieldsCellDialogRadioOptions.DEFAULT}
                onChange={() => handleRadioButtonChange(EditFieldsCellDialogRadioOptions.DEFAULT)}
              />
              <div className={classes.dropdownWrapper}>
                <BigidSelect
                  menuPosition="fixed"
                  name={fieldName}
                  options={defaultCollection}
                  placeholder={`Select a ${columnName}`}
                  value={defaultDropdownOption.filter(option => option?.label && option?.value)}
                  onChange={(options: BigidSelectOption[]) => {
                    const defaultOptions = options?.map(selectedOption => {
                      const value = selectedOption?.value ?? selectedOption;
                      const label = selectedOption?.label ?? value;
                      return { label: label, value: value };
                    });
                    setDefaultDropdownOption(defaultOptions);
                  }}
                  isSearchable={true}
                  isCreatable={false}
                  isClearable={true}
                  size="large"
                />
              </div>
            </FormControl>
            <FormControl fullWidth margin="normal">
              <BigidRadio
                label={`Manually specify ${columnName}`}
                dataAid={generateDataAid('EditFieldsCellDialog', [columnName, 'custom'])}
                checked={selectedRadioOption === EditFieldsCellDialogRadioOptions.CUSTOM}
                onChange={() => handleRadioButtonChange(EditFieldsCellDialogRadioOptions.CUSTOM)}
              />
              <div className={classes.dropdownWrapper}>
                <GlossaryItemSelect
                  title={''}
                  type={glossaryItemType}
                  fieldName={'friendly_name_glossary_id'}
                  onSelectionChange={ids => {
                    setCustomDropdownOption(ids.map(id => ({ label: id, value: id })));
                  }}
                  value={customDropdownOption.map(({ value }) => value)}
                  margin="none"
                  size="large"
                />
              </div>
            </FormControl>
            <FormControl fullWidth margin="normal">
              <BigidRadio
                label={`Clear ${columnName}`}
                dataAid={generateDataAid('EditFieldsCellDialog', [columnName, 'clear'])}
                checked={selectedRadioOption === EditFieldsCellDialogRadioOptions.CLEAR}
                onChange={() => handleRadioButtonChange(EditFieldsCellDialogRadioOptions.CLEAR)}
              />
            </FormControl>
          </fieldset>
        </form>
      </div>
    </BigidDialog>
  );
};

type ReturnValue = [string, string, string, string, BigidSelectOption[]];

const toSelectOption = (collection: OptionDto[] = []) =>
  collection.map(({ glossaryId, glossaryValue }) => ({ value: glossaryId, label: glossaryValue }));

export const getEntityByColumnName = (columnName: ColumnName, field: SarFieldsSettingsV2): ReturnValue => {
  const column = COLUMN_NAME_TO_PROPS_MAPPER[columnName];
  return [
    field[column?.customId] as keyof SarFieldsSettingsV2,
    field[column?.customValue] as keyof SarFieldsSettingsV2,
    field[column?.defaultId] as keyof SarFieldsSettingsV2,
    field[column?.defaultValue] as keyof SarFieldsSettingsV2,
    toSelectOption(field[column?.collection] as OptionDto[]),
  ];
};
