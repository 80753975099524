import './audit.component.scss';
import template from './audit.component.html';
import { isPermitted } from '../../react/services/userPermissionsService';
import { AUDIT_PERMISSIONS } from '@bigid/permissions';
import { convertToReact } from '../../common/services/convertToReact';

const AUDIT_LOG_COLS_IN_LINE = 8;
export const LegacyAuditPage = convertToReact('audit', {
  template,
  controller: function ($translate, $rootScope, $filter, auditService) {
    'ngInject';
    const TRANSLATION_REQUIRED = [
      'AUDIT',
      'AUDIT:GRID:TIMESTAMP',
      'AUDIT:GRID:USER',
      'AUDIT:GRID:SOURCE_IP',
      'AUDIT:GRID:ACTION',
      'AUDIT:GRID:API_ENDPOINT',
      'AUDIT:GRID:RESOURCE',
      'AUDIT:GRID:USER_AGENT',
      'AUDIT:GRID:ADDITIONAL_DATA',
    ];

    const DEFAULT_PAGE_SIZE = 50;

    const rowFilter = rows => {
      const gridFilterKeys = Object.keys(this.gridFilter);

      if (gridFilterKeys.length > 0) {
        rows.forEach(row => {
          const match = [];

          gridFilterKeys.forEach(col => {
            if (row.entity[col] && row.entity[col].toLowerCase().match(new RegExp(this.gridFilter[col].toLowerCase())))
              match.push(true);
            else match.push(false);
          });

          row.visible = match.every(item => item);
        });
      }

      return rows;
    };

    const getAuditData = () => {
      this.loading = true;

      auditService
        .getAuditData()
        .then(result => {
          const gridData = auditLogToGridData(result);
          this.lastUpdate = new Date(
            Array.isArray(gridData) && gridData.length ? gridData[0].timeStamp : undefined,
          ).toLocaleString();
          this.gridConfig.data = gridData;
          this.data = gridData;
        })
        .finally((this.loading = false));
    };

    const auditLogToGridData = log => {
      try {
        return log
          .trim()
          .split(/\s*\n\s*/)
          .reverse()
          .map((line, i) => {
            const cols = line.replace(/^\[|\]$/g, '').split(/\]\s+\[/);
            if (cols.length !== AUDIT_LOG_COLS_IN_LINE) {
              window.console.warn(`Audit log parse failure on line ${i}, line = ${line}`);
              // on parse failure, return the whole row as last column
              return 'N/A '
                .repeat(AUDIT_LOG_COLS_IN_LINE - 1)
                .trim()
                .split(' ')
                .concat(line);
            }
            return cols;
          })
          .filter(cols => cols)
          .map(([timeStamp, user, ipSource, action, api, resourceObject, userAgent, additionalData]) => ({
            timeStamp,
            user,
            ipSource,
            action,
            api,
            resourceObject,
            userAgent,
            additionalData,
          }));
      } catch {
        return [
          {
            timeStamp: 'Failed to parse log',
          },
        ];
      }
    };

    this.loading = false;
    this.gridFilter = {};
    this.lastUpdate = '';
    this.data = [];
    this.gridConfig = {
      enableSorting: true,
      enablePaginationControls: true,
      paginationPageSizes: [25, 50, 75, 100],
      paginationPageSize: DEFAULT_PAGE_SIZE,
      enableRowSelection: true,
      enableSelectAll: true,
      data: [],
      columnDefs: [],
      onRegisterApi: gridApi => {
        this.gridApi = gridApi;
        this.gridApi.grid.registerRowsProcessor(rowFilter, 200);
      },
    };

    this.$onInit = () => {
      this.isDownloadPermitted = isPermitted(AUDIT_PERMISSIONS.DOWNLOAD_REPORT.name);
      $translate(TRANSLATION_REQUIRED).then(translations => {
        $rootScope.$broadcast('changePage', translations['AUDIT'], false);
        this.gridConfig.columnDefs = [
          {
            name: translations['AUDIT:GRID:TIMESTAMP'],
            headerTooltip: translations['AUDIT:GRID:TIMESTAMP'],
            field: 'timeStamp',
            maxWidth: 200,
            cellTemplate:
              "<div aria-label='" +
              translations['AUDIT:GRID:TIMESTAMP'] +
              " {{row.entity.timeStamp}}' class='ui-grid-cell-contents' title='{{row.entity.timeStamp}}'>{{row.entity.timeStamp}}</div>",
          },
          {
            name: translations['AUDIT:GRID:USER'],
            headerTooltip: translations['AUDIT:GRID:USER'],
            field: 'user',
            maxWidth: 100,
            cellTemplate:
              "<div aria-label='" +
              translations['AUDIT:GRID:USER'] +
              " {{row.entity.user}}' class='ui-grid-cell-contents' title='{{row.entity.user}}'>{{row.entity.user}}</div>",
          },
          {
            name: translations['AUDIT:GRID:SOURCE_IP'],
            headerTooltip: translations['AUDIT:GRID:SOURCE_IP'],
            displayName: translations['AUDIT:GRID:SOURCE_IP'],
            field: 'ipSource',
            maxWidth: 120,
            cellTemplate:
              "<div aria-label='" +
              translations['AUDIT:GRID:SOURCE_IP'] +
              " {{row.entity.ipSource}}' class='ui-grid-cell-contents' title='{{row.entity.ipSource}}'>{{row.entity.ipSource}}</div>",
          },
          {
            name: translations['AUDIT:GRID:ACTION'],
            headerTooltip: translations['AUDIT:GRID:ACTION'],
            field: 'action',
            maxWidth: 120,
            cellTemplate:
              "<div aria-label='" +
              translations['AUDIT:GRID:ACTION'] +
              " {{row.entity.action}}' class='ui-grid-cell-contents' title='{{row.entity.action}}'>{{row.entity.action}}</div>",
          },
          {
            name: translations['AUDIT:GRID:API_ENDPOINT'],
            headerTooltip: translations['AUDIT:GRID:API_ENDPOINT'],
            field: 'api',
            cellTemplate:
              "<div aria-label='" +
              translations['AUDIT:GRID:API_ENDPOINT'] +
              " {{row.entity.api}}' class='ui-grid-cell-contents' title='{{row.entity.api}}'>{{row.entity.api}}</div>",
          },
          {
            name: translations['AUDIT:GRID:RESOURCE'],
            headerTooltip: translations['AUDIT:GRID:RESOURCE'],
            field: 'resourceObject',
            maxWidth: 200,
            cellTemplate:
              "<div aria-label='" +
              translations['AUDIT:GRID:RESOURCE'] +
              " {{row.entity.resourceObject}}' class='ui-grid-cell-contents' title='{{row.entity.resourceObject}}'>{{row.entity.resourceObject}}</div>",
          },
          {
            name: translations['AUDIT:GRID:USER_AGENT'],
            headerTooltip: translations['AUDIT:GRID:USER_AGENT'],
            field: 'userAgent',
            cellTemplate:
              "<div aria-label='" +
              translations['AUDIT:GRID:USER_AGENT'] +
              " {{row.entity.userAgent}}' class='ui-grid-cell-contents' title='{{row.entity.userAgent}}'>{{row.entity.userAgent}}</div>",
          },
          {
            name: translations['AUDIT:GRID:ADDITIONAL_DATA'],
            headerTooltip: translations['AUDIT:GRID:ADDITIONAL_DATA'],
            field: 'additionalData',
            cellTemplate:
              "<div aria-label='" +
              translations['AUDIT:GRID:ADDITIONAL_DATA'] +
              " {{row.entity.additionalData}}' class='ui-grid-cell-contents' title='{{row.entity.additionalData}}'>{{row.entity.additionalData}}</div>",
          },
        ].map(cell => ({ headerCellClass: 'darker', cellTooltip: true, ...cell }));

        getAuditData();
      });
    };

    this.onSearchCriteriaChanged = query => {
      this.gridConfig.data = $filter('filter')(this.data, query);
    };

    this.refreshData = () => {
      getAuditData();
    };

    this.downloadAuditData = () => {
      auditService.downloadAuditData();
    };
  },
});
