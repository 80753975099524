import { module } from 'angular';
const app = module('app');

app.factory('agreementsService', ($http, appSettings) => {
  'ngInject';

  return {
    getAgreements: () =>
      $http.get(`${appSettings.serverPath}/${appSettings.version}/agreements`).then(response => response),

    getClassifier: id =>
      $http.get(`${appSettings.serverPath}/${appSettings.version}/agreements/${id}`).then(response => response),

    createAgreement: data =>
      $http.post(`${appSettings.serverPath}/${appSettings.version}/agreements`, data).then(response => response),

    updateAgreement: (id, data) =>
      $http.put(`${appSettings.serverPath}/${appSettings.version}/agreements/${id}`, data).then(response => response),

    deleteAgreement: id =>
      $http.delete(`${appSettings.serverPath}/${appSettings.version}/agreements/${id}`).then(response => response),

    getAttributes: () =>
      $http
        .get(`${appSettings.serverPath}/${appSettings.version}/agreements/attributes`)
        .then(response => response.data),

    getConsentConnectionsData: () =>
      $http.get(`${appSettings.serverPath}/${appSettings.version}/consent/connections`).then(response => response.data),
  };
});
