import { BigidFilterToolbarProps } from '@bigid-ui/components';
import { fetchAvailableFilterOptions } from '../autoDiscoveryWizardServices';

export type ExplorerGridFilterToolbar = Pick<BigidFilterToolbarProps, 'searchConfig' | 'filters'>;

export type ExplorerGridFiltersMap = Map<string, ExplorerGridFilterToolbar>;

type FilteringFields = Record<string, string[]> & {
  default: string[];
};

const DS_FILTERING_FIELDS: FilteringFields = {
  default: ['region'],
  s3: ['region', 'ownerName'],
};

const FIELDS_LABEL: Record<string, string> = {
  region: 'Region',
  ownerName: 'Owner',
};

const createFilterOptionsArray = (values: string[]) => {
  return values.map(value => ({ label: value, value, isSelected: false }));
};

export const generateFilterToolbarConfigMap = (): Promise<ExplorerGridFiltersMap> => {
  return new Promise(async (resolve, reject) => {
    try {
      const baseFilterConfig: ExplorerGridFilterToolbar = {
        searchConfig: {
          searchFilterKeys: ['name'],
          operator: 'contains',
          placeholder: 'Data Source Name',
        },
      };
      const availableFilterOptions = await fetchAvailableFilterOptions(['region', 'ownerName']);
      if (Array.isArray(availableFilterOptions)) {
        const filtersMap = new Map<string, ExplorerGridFilterToolbar>([['base', baseFilterConfig]]);
        for (const ds of availableFilterOptions) {
          const dsFilterConfig: ExplorerGridFilterToolbar = { ...baseFilterConfig, filters: [] };
          const fieldsArr = DS_FILTERING_FIELDS[ds.type] || DS_FILTERING_FIELDS['default'];
          for (const field of fieldsArr) {
            if (ds[field]) {
              dsFilterConfig.filters.push({
                title: FIELDS_LABEL[field],
                field,
                operator: 'in',
                value: [],
                disabled: true,
                options: createFilterOptionsArray(ds[field]),
              });
            }
          }
          filtersMap.set(ds.type, dsFilterConfig);
        }
        resolve(filtersMap);
      } else {
        throw new Error("Auto Discovery Error - can't create filter values");
      }
    } catch (e) {
      reject(e);
    }
  });
};
