import { openSystemDialog } from '../../services/systemDialogService';
import React, { FC } from 'react';
import { PrimaryButton, SecondaryButton } from '@bigid-ui/components';
import { betaToolsService } from '../../services/angularServices';
import { notificationService } from '../../services/notificationService';

type DisableClearEntitiesCacheFunction = (disabled: boolean) => void;

const ClearEntitiesCacheDialogContent: FC = () => {
  return (
    <>
      <div className="clear-entities-cache-warning">Are you sure you want to clear entities cache?</div>
      <div>
        Correlation Sets intended for use in DSAR searches must be re-scanned before searchable attributes will be
        available. Proceed only if you are able to re-scan Correlation Sets prior to initiating DSAR reports.
      </div>
    </>
  );
};

const clearAndNotify = async (setDisableClearEntitiesCache: DisableClearEntitiesCacheFunction) => {
  setDisableClearEntitiesCache(true);
  try {
    await betaToolsService.clearEntitiesCache();
    notificationService.success('Entities Cache cleared successfully! ');
  } catch {
    notificationService.error('An error has occurred! ');
  } finally {
    setDisableClearEntitiesCache(false);
  }
};

export function clearEntitiesCacheDialog(setDisableClearEntitiesCache: DisableClearEntitiesCacheFunction) {
  return openSystemDialog({
    title: 'Clear Entities Cache',
    onClose: () => null,
    content: ClearEntitiesCacheDialogContent,
    buttons: [
      {
        text: 'Close',
        component: SecondaryButton,
        onClick: () => null,
        isClose: true,
      },
      {
        text: 'Clear',
        component: PrimaryButton,
        onClick: () => clearAndNotify(setDisableClearEntitiesCache),
        isClose: true,
      },
    ],
  });
}
