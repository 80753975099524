import { useQuery, UseQueryOptions, QueryFunction, UseQueryResult, QueryKey } from 'react-query';
import { userPreferencesService } from '../services/userPreferencesService';
import type { AsyncReturnType } from '../types/types';
import type { ErrorType } from '../config/request';

const getUserPreferences = userPreferencesService.get;

export type GetUserPreferencesQueryResult = NonNullable<AsyncReturnType<typeof getUserPreferences>>;

export type GetUserPreferencesQueryError = ErrorType<unknown>;

export const getUserPreferencesQueryKey = (name: string): Array<unknown> => ['getUserPreferences', name];

export const useGetUserPreferences = <
  TPayload = any,
  TData = AsyncReturnType<typeof getUserPreferences<TPayload>>,
  TError = GetUserPreferencesQueryError,
>(
  name: string,
  options?: { query?: UseQueryOptions<AsyncReturnType<typeof getUserPreferences<TPayload>>, TError, TData> },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options || {};

  const queryKey = queryOptions?.queryKey ?? getUserPreferencesQueryKey(name);
  const queryFn: QueryFunction<AsyncReturnType<typeof getUserPreferences<TPayload>>> = async () =>
    getUserPreferences(name);
  const query = useQuery<AsyncReturnType<typeof getUserPreferences<TPayload>>, TError, TData>(queryKey, queryFn, {
    ...queryOptions,
  });

  return {
    queryKey,
    ...query,
  };
};
