import React, { FC } from 'react';
import { styled } from '@mui/material';
import { BigidBody1 } from '@bigid-ui/components';
import { generateDataAid } from '@bigid-ui/utils';
import { useLocalTranslation } from '../../translations';

const ContentStartTourStepContainer = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

interface ContentStartTourStepProps {
  dataAid?: string;
}

export const ContentStartTourStep: FC<ContentStartTourStepProps> = ({ dataAid = 'ContentStartTourStep' }) => {
  const { t } = useLocalTranslation('guidedTour.plannedScans.start');
  return (
    <ContentStartTourStepContainer data-aid={generateDataAid(dataAid, [])}>
      <BigidBody1>{t('content1')}</BigidBody1>
      <BigidBody1>{t('content2')}</BigidBody1>
      <BigidBody1>{t('content3')}</BigidBody1>
    </ContentStartTourStepContainer>
  );
};
