import styled from '@emotion/styled';
import {
  BigidAccordion,
  BigidAccordionDetails,
  BigidAccordionSummary,
  BigidColorsV2,
  BigidHeading6,
} from '@bigid-ui/components';

export const BigidAccordionContainer = styled(BigidAccordion)`
  box-shadow: none !important;
  margin-bottom: 26px !important;
  border: none !important;
`;

export const BigidAccordionSummaryContainer = styled(BigidAccordionSummary)`
  width: 120px;
  padding: 0 !important;
`;

export const BigidAccordionDetailsContainer = styled(BigidAccordionDetails)`
  border-top: none !important;
  padding: 0 !important;
`;

export const SummarySection = styled('div')`
  display: flex;
  flex-direction: column;
`;

export const SummaryContainer = styled('div')`
  padding: 16px 12px;
  border: 1px solid ${BigidColorsV2.gray[300]};
  border-radius: 5px;
  margin-top: 16px;
`;

export const InsightSubTitleContainer = styled('div')`
  display: flex;
  align-items: center;
  gap: 6px;
`;

export const IconContainer = styled('div')`
  display: flex;
`;

export const IconContainerRowGrid = styled('div')`
  display: flex;
  flex-direction: row;
`;

export const TooltipRowGrid = styled('span')`
  display: flex;
  margin-right: 8px;
`;

export const InsightsContainer = styled('div')`
  display: flex;
  flex-direction: column;
`;

export const CollapseTitle = styled(BigidHeading6)`
  font-size: 14px;
  font-weight: 400;
`;
