import React, { FC, useCallback, useMemo } from 'react';
import { BigidBody1, BigidColorSchemeTokens, BigidColorSchemesVars } from '@bigid-ui/components';
import { styled } from '@mui/material';
import { RiskMatrixMetadata } from '../RiskMatrixDefaults';
import { generateDataAid } from '@bigid-ui/utils';
import { v4 as uuid } from 'uuid';
import { BigidWarningFilledIcon } from '@bigid-ui/icons';

const Cell = styled('div', {
  shouldForwardProp: prop => !['color', 'width', 'height', 'setOpacity'].includes(prop.toString()),
})<{
  color?: string;
  width: number;
  height: number;
  setOpacity?: boolean;
}>(({ color, width, height, setOpacity }) => ({
  width: `${width}px`,
  backgroundColor: color,
  height: `${height}px`,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  opacity: setOpacity ? '0.25' : 'unset',
}));

const EmptyCell = styled(Cell)({
  backgroundColor: BigidColorSchemesVars.light.gray150,
});

const Grid = styled('div', { shouldForwardProp: prop => !['size', 'cellWidth'].includes(prop.toString()) })<{
  size: number;
  cellWidth: number;
}>(({ size, cellWidth }) => ({
  display: 'grid',
  gridTemplateColumns: `auto repeat(${size}, ${cellWidth}px)`,
  gap: '8px',
}));

const GridWrapperRow = styled('div')({
  width: 'fit-content',
  display: 'flex',
  alignItems: 'start',
  flexDirection: 'row',
});

const GridWrapperCol = styled(GridWrapperRow)({
  flexDirection: 'column',
  alignItems: 'end',
});

const GridLabel = styled('div', {
  shouldForwardProp: prop => !['setOpacity'].includes(prop.toString()),
})<{
  setOpacity?: boolean;
}>(({ setOpacity }) => ({
  textAlign: 'center',
  width: 'auto',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  opacity: setOpacity ? '0.25' : 'unset',
}));

const XAxisLabel = styled(BigidBody1)({
  color: BigidColorSchemesVars.light.gray400,
  width: '562px',
  textAlign: 'center',
  padding: '4px',
});
const LevelLabel = styled(XAxisLabel)();

const YAxisLabel = styled(BigidBody1)({
  writingMode: 'vertical-lr',
  transform: 'rotate(180deg)',
  color: BigidColorSchemesVars.light.gray400,
  height: '256px',
  textAlign: 'center',
  padding: '4px',
});

const GridRoot = styled(BigidBody1)({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  gap: '24px',
});

export interface RiskMatrixProps extends RiskMatrixMetadata {
  oldGridSize?: number;
  hideAxis?: HiddenAxis;
}

export enum HiddenAxis {
  X = 1,
  Y = 2,
}

export const RiskMatrix: FC<RiskMatrixProps> = ({
  matrixSize: size,
  impactLabel,
  impactLabels,
  probabilityLabel,
  probabilityLabels,
  cellData,
  oldGridSize,
  hideAxis,
}) => {
  const gridWidth = 562;
  const gridHight = 256;
  const gridGap = 8;

  const getCellPropsCallback = useCallback(
    (col: number, row: number) => {
      const val = col * row;
      return cellData[val];
    },
    [cellData],
  );

  const NEW_SIZE = useMemo(() => (oldGridSize && oldGridSize > size ? oldGridSize : size), [size, oldGridSize]);

  const getCellWidth = useMemo(() => {
    const neSize = oldGridSize && oldGridSize > size ? oldGridSize : size;
    return (gridWidth - gridGap * (neSize - 1)) / neSize;
  }, [gridWidth, gridGap, size, oldGridSize]);

  const getCellHight = useMemo(() => {
    const neSize = oldGridSize && oldGridSize > size ? oldGridSize : size;
    return (gridHight - gridGap * (neSize - 1)) / neSize;
  }, [gridHight, gridGap, size, oldGridSize]);

  const Columns = useCallback(
    (row: number) => {
      const columns: Array<JSX.Element> = [];
      for (let index = 0; index < size; index++) {
        const { color, riskLevelLabel: label } = getCellPropsCallback(index + 1, row + 1);
        columns.push(
          <Cell
            data-aid={generateDataAid('riskMatrixGrid', ['label', label, (row + 1) * (index + 1)])}
            key={uuid()}
            color={color}
            height={getCellHight}
            width={getCellWidth}
            setOpacity={(hideAxis === HiddenAxis.Y && row >= 1) || (hideAxis === HiddenAxis.X && index >= 1)}
          >
            <BigidBody1> {(row + 1) * (index + 1)}</BigidBody1>
            <BigidBody1> {label}</BigidBody1>
          </Cell>,
        );
      }

      return columns;
    },
    [size, getCellPropsCallback, getCellHight, getCellWidth, hideAxis],
  );

  const getRows = useCallback(() => {
    const rows: Array<JSX.Element> = [];

    if (oldGridSize && oldGridSize > size) {
      for (let index = oldGridSize - size; index > 0; index--) {
        rows.push(<div key={uuid()} />);
        for (let index = oldGridSize; index > 0; index--) {
          rows.push(<EmptyCell key={uuid()} height={getCellHight} width={getCellWidth} />);
        }
      }
    }

    for (let index = size; index > 0; index--) {
      rows.push(
        <GridLabel
          data-aid={generateDataAid('riskMatrixGrid', ['impact-labels', impactLabels[index]])}
          key={uuid()}
          setOpacity={hideAxis === HiddenAxis.Y}
        >
          <BigidBody1>{impactLabels[index]}</BigidBody1>
          <BigidBody1>{`(${index})`}</BigidBody1>
        </GridLabel>,
      );

      rows.push(...Columns(index - 1));

      if (oldGridSize && oldGridSize > size) {
        for (let index = oldGridSize - size; index > 0; index--) {
          rows.push(<EmptyCell key={uuid()} height={getCellHight} width={getCellWidth} />);
        }
      }
    }

    rows.push(<div key={uuid()} />);
    Object.keys(probabilityLabels).forEach((label, i) => {
      rows.push(
        <GridLabel
          setOpacity={hideAxis === HiddenAxis.X}
          data-aid={generateDataAid('riskMatrixGrid', ['probability-labels', probabilityLabels[i + 1]])}
          key={uuid()}
        >
          <BigidBody1>{probabilityLabels[Number(label)]}</BigidBody1>
          <BigidBody1>{`(${i + 1})`}</BigidBody1>
        </GridLabel>,
      );
    });

    return rows;
  }, [Columns, getCellHight, getCellWidth, hideAxis, impactLabels, oldGridSize, probabilityLabels, size]);

  return (
    <GridRoot>
      <GridWrapperRow>
        <YAxisLabel
          data-aid={generateDataAid('riskMatrixGrid', ['impact-label'])}
          size="small"
        >{`${impactLabel} (y)`}</YAxisLabel>
        <GridWrapperCol>
          <LevelLabel>{'Level'}</LevelLabel>
          <Grid size={NEW_SIZE} cellWidth={getCellWidth}>
            {getRows()}
          </Grid>
          <XAxisLabel
            data-aid={generateDataAid('riskMatrixGrid', ['probability-label'])}
            size="small"
          >{`${probabilityLabel} (x)`}</XAxisLabel>
        </GridWrapperCol>
      </GridWrapperRow>

      {oldGridSize && (
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'start',
            gap: '8px',
          }}
        >
          <BigidWarningFilledIcon color={BigidColorSchemeTokens.light.pendingStrong} />
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              gap: '4px',
            }}
          >
            <BigidBody1 fontWeight={700}>{'Configuration Implications'}</BigidBody1>
            <BigidBody1>
              {
                'Adjusting the matrix size will change the impact & probability levels. Please note that this may affect the severity of any existing risks, conserving the ratio'
              }
            </BigidBody1>
          </div>
        </div>
      )}
    </GridRoot>
  );
};
