import React, { FC, useEffect, useRef, useState } from 'react';
import styled from '@emotion/styled';
import { useMediaQuery } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import {
  BigidBody1,
  BigidColorsV2,
  BigidForm,
  BigidFormFieldTypes,
  BigidFormProps,
  BigidFormStateAndHandlers,
  BigidFormValidateOnTypes,
  BigidFormValues,
  BigidHeading3,
  BigidLink,
  BigidLoader,
  BigidPaper,
  PASSWORD_MASK_VIEW_VALUE,
  PrimaryButton,
  SecondaryButton,
} from '@bigid-ui/components';
import { PreDiscoverProps } from '../Fmsd';
import { AutoDiscoveryCredsDialog } from './AutoDiscoveryCredsDialog';
import { notificationService } from '../../services/notificationService';
import { HEADER_HEIGHT } from '../../components/BigidHeader/BigidHeader';
import { pageHeaderService } from '../../../common/services/pageHeaderService';
import { isAutoDiscoveryAvailableInDs } from '../DataSources/DataSourceConnections/hooks/useAutoDiscoveryAppStatus';
import { $state } from '../../services/angularServices';
import { CONFIG } from '../../../config/common';
import {
  getAutoDiscoveryAppIds,
  getCurrentConfig,
  getDiscoveryAppPermisions,
  getDiscoveryAppPermisionsWithoutCreds,
  getLastRunInfo,
  startAutoDiscoveryApp,
  TestConnectionData,
} from '../../services/autoDiscoveryService';
import { CloudProvider } from '../AutoDiscoveryWizard/autoDiscoveryWizardServices';
import AwsLogo from '../../assets/icons/AwsSmallLogo.svg';
import { CustomAppStatus } from '../CustomApp/views/ActivityLog/ActivityLog';
import { AutoDiscoveryUITrackingEvent } from './AutoDiscoveryEventTrackerUtils';
import { noop } from 'lodash';
import { AutoDiscoveryConnectionStatus } from './AutoDiscoveryConnectionStatus/AutoDiscoveryConnectionStatus';
import { CredsStatus } from '../Fmsd/FmsdComponents/ConnectionsStatus/ConnectionsStatus';

export interface AutoDiscoveryAppIdsType {
  id: string;
  tpaId: string;
}

interface ClassProps {
  isSmallScreen: boolean;
  isLoading?: boolean;
}

const BOTTOM_PADDING = 8;

const ALL_DS_TYPES_SELECTED = 'all';
const AWS_DS_TYPE_PREFIX = 'aws_auto_';

const Loader = styled(BigidLoader)`
  flex-grow: 1;
`;

const startAutoDiscovery = async (dsTypes: string, appIds?: AutoDiscoveryAppIdsType) => {
  const ids = appIds || (await getAutoDiscoveryAppIds(CloudProvider.AWS));
  await getDiscoveryAppPermisionsWithoutCreds(ids, noop, CloudProvider.AWS, { dsTypes });
  await startAutoDiscoveryApp(CloudProvider.AWS);
  $state.go(CONFIG.states.DATA_SOURCE_CONNECTIONS, { shouldDisplayAutoDiscoveryWarning: true });
};

const updateDiscoveryButtonByCredStatus = (
  credentialsStatus: CredsStatus,
  permissions: string[],
  dsTypesSelected: boolean,
  setIsDiscoveryAvailable: (isAvailable: boolean) => void,
) => {
  const isDisabledStatus = credentialsStatus === CredsStatus.WRONG_CREDS || credentialsStatus === CredsStatus.INITIAL;
  const isNotPermitted = !permissions?.length && credentialsStatus !== CredsStatus.CANT_EVALUATE;
  if (isDisabledStatus || isNotPermitted || !dsTypesSelected) {
    setIsDiscoveryAvailable(false);
  } else {
    setIsDiscoveryAvailable(true);
  }
};

const convertSelectedDsToArray = (dsTypes: string) =>
  dsTypes.split(',').map(type => type.replace(AWS_DS_TYPE_PREFIX, ''));

const convertSelectedDsToString = (dsTypes: string[]) =>
  dsTypes
    .filter(type => type !== ALL_DS_TYPES_SELECTED)
    .map(type => `${AWS_DS_TYPE_PREFIX}${type}`)
    .join(',');

const useStyles = makeStyles({
  autoDiscoveryMainContainer: ({ isSmallScreen }: ClassProps) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    height: isSmallScreen ? 'calc(100% - 6px)' : `calc(100% - ${BOTTOM_PADDING}px - ${HEADER_HEIGHT}px)`,
    overflowY: 'scroll',
  }),
  paper: ({ isLoading }: ClassProps) => ({
    padding: 24,
    borderRadius: 6,
    minHeight: 500,
    ...(isLoading && { display: 'flex', flexDirection: 'column' }),
  }),
  wrapper: {
    display: 'flex',
    width: '100%',
    justifyContent: 'center',
  },
  headerContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    paddingBottom: 8,
  },
  logoContainer: {
    height: 72,
    width: 72,
    border: `1px solid ${BigidColorsV2.gray[600]}`,
    borderRadius: 50,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '& svg': {
      marginTop: -6,
    },
  },
  titleContainer: {
    display: 'flex',
    flexDirection: 'column',
    color: BigidColorsV2.gray[700],
    paddingLeft: 16,
  },
  buttonContainer: {
    paddingTop: 14,
    alignItems: 'center',
    flexDirection: 'column',
    display: 'flex',
    '& button': {
      maxWidth: 'unset',
    },
  },
});

export const AutoDiscovery: FC<PreDiscoverProps> = () => {
  const isSmallScreen = useMediaQuery('(max-height:900px)');
  const [credentialsStatus, setCredentialsStatus] = useState<CredsStatus>(CredsStatus.INITIAL);
  const [permissions, setPermissions] = useState<string[]>([]);
  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);
  const formControls = useRef<BigidFormStateAndHandlers>();
  const [isLoading, setIsLoading] = useState(true);
  const [accessKeyIdInitialValue, setAccessKeyIdInitialValue] = useState('');
  const [secretAccessKeyInitialValue, setSecretAccessKeyInitialValue] = useState('');
  const [appIds, setAppIds] = useState<AutoDiscoveryAppIdsType>();
  const [isPermissionsLoading, setIsPermissionsLoading] = useState(false);
  const [isDiscoveryAvailable, setIsDiscoveryAvailable] = useState(false);
  const [selectedDsTypes, setSelectedDsTypes] = useState([]);
  const classes = useStyles({ isSmallScreen, isLoading });
  const labelWidth = '150px';
  const dataAid = 'auto-discovery';

  const removeLoaders = () => {
    setIsLoading(false);
    setIsPermissionsLoading(false);
  };

  const onSubmit = () => {
    formControls.current.validateAndSubmit(async (values: BigidFormValues) => {
      try {
        setIsPermissionsLoading(true);
        setIsDiscoveryAvailable(false);
        const { status_enum } = await getLastRunInfo(CloudProvider.AWS);
        if (status_enum === CustomAppStatus.IN_PROGRESS) {
          notificationService.warning('Discovery already in progress.');
          removeLoaders();
        } else {
          const { accessKeyId, secretAccessKey } = values;
          let connectedPermissions: TestConnectionData;
          if (accessKeyId === PASSWORD_MASK_VIEW_VALUE && secretAccessKey === PASSWORD_MASK_VIEW_VALUE) {
            connectedPermissions = await getDiscoveryAppPermisionsWithoutCreds(appIds, removeLoaders);
          } else {
            connectedPermissions = await getDiscoveryAppPermisions(
              CloudProvider.AWS,
              { accessKeyId, secretAccessKey },
              removeLoaders,
            );
          }
          notificationService.success('Connection successful!');
          setPermissions(connectedPermissions.permissions);
          setCredentialsStatus(connectedPermissions.credsStatus);
        }
      } catch (e) {
        notificationService.error(e.message);
        removeLoaders();
      }
    });
  };

  const formProps: BigidFormProps = {
    initialValues: {
      accessKeyId: accessKeyIdInitialValue,
      secretAccessKey: secretAccessKeyInitialValue,
    },
    validateOn: BigidFormValidateOnTypes.SUBMIT,
    stateAndHandlersRef: formControls,
    onSubmit,
    controlButtons: false,
    fields: [
      {
        name: 'accessKeyId',
        label: 'Access Key',
        isRequired: true,
        labelWidth,
        misc: {
          showValueOnlyIfDirty: true,
        },
        type: BigidFormFieldTypes.PASSWORD,
        validate: (accessKeyId: string) => {
          if (!accessKeyId) {
            return 'Please enter AWS Access Key ID';
          }
          if (accessKeyId.length < 16 || accessKeyId.length > 128) {
            return 'Please enter a valid AWS Access Key ID';
          }
          return false;
        },
      },
      {
        name: 'secretAccessKey',
        label: 'Secret Access Key',
        isRequired: true,
        labelWidth,
        type: BigidFormFieldTypes.PASSWORD,
        misc: {
          showValueOnlyIfDirty: true,
        },
        validate: (secretAccessKey: string) => {
          if (!secretAccessKey) {
            return 'Please enter AWS Secret Access Key';
          }
          return false;
        },
      },
    ],
  };

  const onSelectDataSourceType = (dataSourceType: string, checked: boolean) => {
    setSelectedDsTypes(selectedDsTypes => {
      if (checked) {
        return [...selectedDsTypes, dataSourceType];
      }
      return selectedDsTypes?.filter(dsType => dsType !== dataSourceType && dsType !== ALL_DS_TYPES_SELECTED);
    });
  };

  useEffect(() => {
    async function fetchData() {
      try {
        const appIds = await getAutoDiscoveryAppIds(CloudProvider.AWS, removeLoaders);
        const config = await getCurrentConfig(CloudProvider.AWS);
        setSelectedDsTypes(convertSelectedDsToArray(config?.dsTypes ?? ''));
        if (appIds) {
          setAccessKeyIdInitialValue(PASSWORD_MASK_VIEW_VALUE);
          setSecretAccessKeyInitialValue(PASSWORD_MASK_VIEW_VALUE);
        }

        setAppIds(appIds);
        setIsLoading(false);
      } catch (e) {
        notificationService.error(e.message);
      }
    }
    fetchData();
  }, []);

  useEffect(() => {
    const dsTypesSelected =
      selectedDsTypes.length && !(selectedDsTypes.length === 1 && selectedDsTypes[0] === ALL_DS_TYPES_SELECTED);
    updateDiscoveryButtonByCredStatus(credentialsStatus, permissions, dsTypesSelected, setIsDiscoveryAvailable);
  }, [credentialsStatus, permissions, selectedDsTypes, selectedDsTypes.length]);

  useEffect(() => {
    if (!isAutoDiscoveryAvailableInDs()) {
      $state.go(CONFIG.states.FORBIDDEN);
    }
    pageHeaderService.setIsHidden(true);
    return () => pageHeaderService.setIsHidden(false);
  }, []);

  return (
    <>
      <div className={classes.autoDiscoveryMainContainer}>
        <BigidPaper classes={{ root: classes.paper }} hasShadow={true}>
          <div className={classes.headerContainer}>
            <div className={classes.logoContainer}>
              <AwsLogo />
            </div>
            <div className={classes.titleContainer}>
              <BigidHeading3>{'Discover and Connect AWS Services'}</BigidHeading3>
              <BigidBody1>{'Enter credentials to get started'}</BigidBody1>
              <BigidLink
                dataAid={`${dataAid}-test-connection-open-iam-modal`}
                onClick={() => setIsDialogOpen(true)}
                text={'Need credentials?'}
              />
            </div>
          </div>
          {isLoading ? (
            <Loader position="relative" />
          ) : (
            <>
              <BigidForm {...formProps} />
              <div className={classes.buttonContainer}>
                <SecondaryButton
                  dataAid={`${dataAid}-wizard-test-connection-test-button`}
                  text="Test Connection"
                  color="grey"
                  size="medium"
                  width="fill"
                  disabled={isPermissionsLoading}
                  onClick={formProps.onSubmit}
                />
              </div>
              <AutoDiscoveryConnectionStatus
                cloudProvider={CloudProvider.AWS}
                dataAid={`${dataAid}-wizard-test-connection-status`}
                credentialsStatus={credentialsStatus}
                permissions={permissions}
                isLoading={isPermissionsLoading}
                onSelect={onSelectDataSourceType}
                selectedIds={selectedDsTypes}
              />
              <div className={classes.buttonContainer}>
                <PrimaryButton
                  onClick={() => startAutoDiscovery(convertSelectedDsToString(selectedDsTypes), appIds)}
                  disabled={!isDiscoveryAvailable}
                  size="medium"
                  text="Start Discovery"
                  color="grey"
                  width="fill"
                  dataAid={`${dataAid}-start-discovery-button`}
                  bi={{
                    eventType: AutoDiscoveryUITrackingEvent.DATA_SOURCES_START_AUTO_DISCOVERY_CLICK,
                  }}
                />
              </div>
            </>
          )}
          <AutoDiscoveryCredsDialog isOpen={isDialogOpen} onClose={() => setIsDialogOpen(false)} />
        </BigidPaper>
      </div>
    </>
  );
};
