import { IconButton } from '@mui/material';
import { BigidMoreActionIcon } from '@bigid-ui/icons';
import { BigidMenu, BigidMenuItemProps, BigidMenuProps } from '@bigid-ui/components';
import React, { FC, useCallback, useMemo, useRef, useState } from 'react';
import { isArray } from 'lodash';
import styled from '@emotion/styled';
import { generateDataAid } from '@bigid-ui/utils';

export interface InteractiveDashboardMenuProps {
  titleActions?: BigidMenuItemProps[] | { [key: string]: BigidMenuItemProps[] };
  isHovered: boolean;
  setIsHovered: React.Dispatch<React.SetStateAction<boolean>>;
  dataAid?: string;
}

const Visibility = styled('div')<{ isVisible: boolean }>`
  opacity: ${props => (!props.isVisible ? 0 : 1)};
  transition: opacity 0.2s ease;
`;

export const InteractiveDashboardMenu: FC<InteractiveDashboardMenuProps> = ({
  titleActions,
  setIsHovered,
  isHovered,
  dataAid,
}) => {
  const [open, setOpen] = useState(false);
  const anchorRef = useRef();
  const handleClick = useCallback(() => setOpen(!open), [open]);
  const menuProps: BigidMenuProps = useMemo(() => {
    return {
      open,
      onMenuClose: () => {
        setOpen(false);
        setIsHovered(false);
      },
      anchorEl: anchorRef.current,
      anchorOrigin: {
        vertical: 'bottom',
        horizontal: 'right',
      },
      transformOrigin: {
        vertical: 'top',
        horizontal: 'right',
      },
    };
  }, [setIsHovered, open]);

  const actionsWithCloseHovering: BigidMenuItemProps[] = useMemo(() => {
    return isArray(titleActions)
      ? titleActions.map(action => ({
          ...action,
          onClick: () => {
            action.onClick();
          },
        }))
      : [];
  }, [titleActions]);

  return (
    <Visibility isVisible={isHovered} data-aid={generateDataAid(dataAid, ['menu-button'])}>
      {titleActions?.length > 0 && (
        <div ref={anchorRef}>
          <IconButton onClick={handleClick}>
            <BigidMoreActionIcon />
          </IconButton>
          <BigidMenu {...menuProps} items={actionsWithCloseHovering} />
        </div>
      )}
    </Visibility>
  );
};
