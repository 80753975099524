import React, { FC, useRef } from 'react';
import { closeSystemDialog, SystemDialogContentProps } from '../../../services/systemDialogService';
import {
  BigidBody1,
  PrimaryButton,
  TertiaryButton,
  BigidForm,
  BigidFormField,
  BigidFormValues,
  BigidFormStateAndHandlers,
} from '@bigid-ui/components';
import { EmailTemplate } from '../types/emailTemplatesTypes';
import { useLocalTranslation } from '../translations';
import styled from '@emotion/styled';
import { ALLOWED_NAME_REGEX } from '../../../config/consts';
import { duplicateTemplate } from './emailTemplatesService';
import { trackEmailTemplateEvent, EmailTemplateTrackingEvents } from '../emailTemplateTrackingUtils';
import { generateDataAid } from '@bigid-ui/utils';

const EmailTemplateDuplicateModal = styled('div')({
  flexDirection: 'column',
  gap: '8px',
  display: 'flex',

  '& p': {
    fontSize: '14px !important',
  },
});

const ButtonsContainer = styled('div')({
  flexDirection: 'row',
  justifyContent: 'right',
  gap: '8px',
  display: 'flex',
});

interface EmailTemplateDuplicateProps {
  dataAid?: string;
  template: EmailTemplate;
  onSuccessfulDuplication: (emailTemplate: EmailTemplate) => void;
}

export const EmailTemplateDuplicateDialog: FC<SystemDialogContentProps<EmailTemplateDuplicateProps>> = ({
  dataAid = 'EmailTemplateDuplicateDialog',
  template,
  onSuccessfulDuplication,
}) => {
  const { t } = useLocalTranslation('TemplatesManager.duplicate');

  const validateTemplateName = (value: string): string | boolean => {
    if (!value) {
      return t('requiredField');
    }
    if (value.length > 50) {
      return t('templateNameTooLong');
    }
    if (!value.match(ALLOWED_NAME_REGEX)) {
      return t('templateNameNotAllowed');
    }
    return false;
  };

  const fields: BigidFormField[] = [
    {
      name: 'newTemplateName',
      label: t('newTemplateName'),
      validate: name => validateTemplateName(name),
      misc: {
        placeholder: t('templateName'),
      },
      fieldProps: {
        size: 'large',
      },
    },
  ];

  const stateAndHandlersRef = useRef<BigidFormStateAndHandlers>();
  const handleDuplicateClick = async () => {
    await stateAndHandlersRef.current.validateAndSubmit(async (values: BigidFormValues) => {
      try {
        const duplicateResponse = await duplicateTemplate(template._id, values.newTemplateName);
        onSuccessfulDuplication(duplicateResponse);
        trackEmailTemplateEvent(EmailTemplateTrackingEvents.EMAIL_TEMPLATES_DUPLICATE_TEMPLATE_CLICK, {
          originalTemplateName: template.name,
          duplicationTemplateName: values.newTemplateName,
        });
        closeSystemDialog();
      } catch (err) {
        let errMessage = err?.response?.data?.message || t('internalErrorOccurred');
        if (err?.response?.status === 409) {
          errMessage = t('templateNameAlreadyExists');
        }
        stateAndHandlersRef.current.setFieldError(fields[0].name, errMessage);
      }
    });
  };
  return (
    <>
      <EmailTemplateDuplicateModal data-aid={dataAid}>
        <BigidBody1>{t('body', { entityName: template.name })}</BigidBody1>
        <BigidForm fields={fields} stateAndHandlersRef={stateAndHandlersRef} controlButtons={false} />
        <ButtonsContainer>
          <TertiaryButton
            dataAid={generateDataAid(dataAid, ['cancel'])}
            onClick={() => {
              closeSystemDialog();
            }}
            size="medium"
            text={t('cancel')}
          />
          <PrimaryButton
            dataAid={generateDataAid(dataAid, ['duplicate'])}
            onClick={handleDuplicateClick}
            size="medium"
            text={t('duplicate')}
            bi={{ disabled: true }}
          />
        </ButtonsContainer>
      </EmailTemplateDuplicateModal>
    </>
  );
};
