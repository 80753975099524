import { MutableRefObject, useCallback } from 'react';
import { DataSourceConfigurationState, getStatePartBasedOnCurrentState, useDataSourceConfigState as useDataSourceConfigStateV1 } from '../../DataSourceConfiguration/hooks/useDataSourceConfigState';
import type { DataSourceConnectionTemplateField } from '../../DataSourceConfiguration/types';
import type { BigidFormStateAndHandlers } from '@bigid-ui/components';
import { isFunction } from 'lodash';

type UseDataSourceConfigStateOpts = { loadInitialDataFromTemplate?: boolean; onChange?: (props: any) => void };

export const useDataSourceConfigState = (
  control?: MutableRefObject<BigidFormStateAndHandlers>,
  { loadInitialDataFromTemplate = true, onChange }: UseDataSourceConfigStateOpts = {},
) => {
  const { generateInitValues: generateInitValuesFromTemplate, updateState, ...rest } = useDataSourceConfigStateV1();

  const updateStateOverride = useCallback(
    (propsOrFunction: Partial<DataSourceConfigurationState> | getStatePartBasedOnCurrentState) => {
      const handleStateUpdateFunction = (prevState: DataSourceConfigurationState) => {
        const state = (propsOrFunction as getStatePartBasedOnCurrentState)({
          ...prevState,
        });

        onChange?.(state);
        return state;
      }

      // @info include state change callback
      updateState(
        isFunction(propsOrFunction)
          ? handleStateUpdateFunction
          : {
              ...propsOrFunction,
            },
      );

      !isFunction(propsOrFunction) && onChange?.({
        ...propsOrFunction,
      })
      
    },
    [updateState],
  );

  const generateInitValuesOverride = useCallback(
    (fieldsConfig: DataSourceConnectionTemplateField[]) => {
      if (loadInitialDataFromTemplate) {
        generateInitValuesFromTemplate(fieldsConfig);
        return;
      }

      const values = control?.current.getValues?.() ?? {};

      // @info load initial values from the passed form not from template
      updateState({
        initialValues: fieldsConfig.reduce(
          (acc, { name }: Partial<DataSourceConnectionTemplateField>) => ({
            ...acc,
            [name]: values[name],
          }),
          {},
        ),
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [updateState],
  );

  return { generateInitValues: generateInitValuesOverride, updateState: updateStateOverride, ...rest };
};
