import { module } from 'angular';
import template from './scanProfile.component.html';
import './scanProfile.component.scss';
import { v4 as uuid } from 'uuid';
import { hasRootScope } from '../../../react/services/userPermissionsService';
import '../../../react/components/ScanProfileClassifiers/ScanProfileClassifiers';
import '../../../react/components/DataSourceSelectionGrid/DataSourceSelectionGrid';
import { showGenericConfirmationDialog } from '../../../react/services/genericConfirmationDialog';
import { httpService } from '../../../react/services/httpService';
import { queryService } from '../../../react/services/queryService';
import { getApplicationPreference } from '../../../react/services/appPreferencesService';
import { sessionStorageService } from '../../../common/services/sessionStorageService';
import { navigateToScansPage } from '../../../react/views/Scans/ScanUtils';
import { CONFIG } from '../../../config/common';
import { parseCronExpression } from '../../../common/services/localizationHelper';
import { ConvertScanProfileButton } from '../../../react/views/Scans/ScanProfileConverter/ConvertScanProfileButton';
import '../../../react/components/ScanProfileLegacyPageTooltip/ScanProfileLegacyPageTooltip';
import { isMultiTenantModeEnabled } from '../../../react/utilities/multiTenantUtils';
import { getBigIdHelpIcon } from './GuidedTour/ConvertScanProfileDialogGuide';

const app = module('app');

app.component('editScanProfile', {
  template,
  controllerAs: 'scanProfileModel',
  controller: function ($scope, $state, $stateParams, $rootScope, $translate, notificationService, scansService) {
    'ngInject';

    //NOTE: required for Scans -> Scan Profiles section state
    sessionStorageService.set('isScanProfileEdited', true);

    const scanProfileModel = this;
    const SCAN_API_PROFILE_SUFFIX = '-profile';
    scanProfileModel.isPredictionClicked = false;
    scanProfileModel.scanClassifiersConfig = { classifiers: [] };
    scanProfileModel.isScanProfileClassifiersEnabled = getApplicationPreference('CLASSIFIERS_ON_PROFILE_FF');
    scanProfileModel.isCurationFeatureEnabled = getApplicationPreference('CLASSIFIER_TUNING_ENABLED');
    scanProfileModel.isDataLoading = false;
    scanProfileModel.isHideSave = $stateParams.isHideSave;
    scanProfileModel.isShowDialogGuide = false;
    function removePostfix(str, suffix) {
      const hasPrefix = str.endsWith(suffix);
      return hasPrefix ? str.substr(0, str.length - suffix.length) : str.toString();
    }

    function filterDataSourceListForScanProfile(dataSourceList) {
      const profileName = $scope._data.name;
      const scanTypeName = $scope._data.type;
      const isEditingScanApiProfile = scanTypeName === scansService.scanTypes.SCAN_API.name;
      if (!profileName || !isEditingScanApiProfile) {
        return dataSourceList;
      } else {
        const datasourceForScanProfile = removePostfix(profileName, SCAN_API_PROFILE_SUFFIX) + '-ds';
        return dataSourceList.filter(ds => ds === datasourceForScanProfile);
      }
    }

    const initializeData = data => {
      $scope._data = angular.copy(data);
      scanProfileModel.scanClassifiersConfig.classifiers = $scope._data.classifiers || [];
      scanProfileModel.emailOwners = emailOwnersToFormData($scope._data.owners);
      scanProfileModel.dataSourceList = $scope._data.dataSourceList;
      scanProfileModel.scanApiDsRelatedToScanProfile = filterDataSourceListForScanProfile($scope._data.dataSourceList);
      scanProfileModel.idsorList = $scope._data.idsorList;
      scanProfileModel.isCustomScanProfile = $scope._data.isCustomScanProfile;
      scanProfileModel.skipIdScan = $scope._data.skipIdScan;
      scanProfileModel.isClassificationsAsPiiFindings = $scope._data.isClassificationsAsPiiFindings;
      scanProfileModel.isReviewFindingsEnabled = $scope._data.isReviewFindingsEnabled;
      scanProfileModel.identityResolutionScan = $scope._data.identityResolutionScan;
      scanProfileModel.stayOnThisPageAfterSaving = false;
      scanProfileModel.isDefaultDsProfile = $scope._data.isDefaultDsProfile;

      if ($scope._data.filenameToImport) {
        scanProfileModel.updateFileUploader($scope._data.filenameToImport);
      }

      $scope._data.scanType = scanProfileModel.scanTypes.availableOptions.find(
        option => option.id === $scope._data.scanType,
      );
    };

    scanProfileModel.scanTypes = {
      availableOptions: isMultiTenantModeEnabled()
        ? [scansService.scanTypes.DS_SCAN, scansService.scanTypes.METADATA_SCAN]
        : [scansService.scanTypes.DS_SCAN, scansService.scanTypes.LINEAGE_SCAN, scansService.scanTypes.METADATA_SCAN],
    };

    if (getApplicationPreference('ASSESSMENT_SCAN_ENABLED')) {
      scanProfileModel.scanTypes.availableOptions.push(scansService.scanTypes.ASSESSMENT_SCAN);
    }

    if (getApplicationPreference('LABELER_ENABLED')) {
      scanProfileModel.scanTypes.availableOptions.push(scansService.scanTypes.DS_TAG);
    }

    if (getApplicationPreference('DIM_ENABLED')) {
      scanProfileModel.scanTypes.availableOptions.push(scansService.scanTypes.DATA_IN_MOTION);
    }

    if (getApplicationPreference('SCAN_API_ENABLED')) {
      scanProfileModel.scanTypes.availableOptions.push(scansService.scanTypes.SCAN_API);
    }

    if (getApplicationPreference('CLUSTERING_ENABLED')) {
      scanProfileModel.scanTypes.availableOptions.push(scansService.scanTypes.HYPER_SCAN);
    }

    scanProfileModel.labelingFramework = [{ id: 'mip', name: 'Labels' }];

    $scope._data = {
      scanType: scanProfileModel.scanTypes.availableOptions[0],
    };

    $scope.submitted = false;
    $scope.refreshDsList = ($item, $model) => {
      if ($scope.dataSourceCommonApi.loadComponent) {
        $scope.dataSourceCommonApi.loadComponent();
      }
    };

    $scope.dataSourceCommonApi = {
      loadComponent: undefined,
    };

    const saveChangesWithDisabledClassifier = async data => {
      const shouldSaveWithDisabledClassifiers = await showGenericConfirmationDialog({
        title: await $translate('SCANS:SCAN_PROFILES:SAVE_WITH_DISABLED_CLASSIFIERS_MODAL:TITLE'),
        actionButton: await $translate('BUTTONS:SAVE'),
        closeButton: await $translate('BUTTONS:CANCEL'),
        text: await $translate('SCANS:SCAN_PROFILES:SAVE_WITH_DISABLED_CLASSIFIERS_MODAL:CONFIRMATION_TEXT'),
      });
      if (shouldSaveWithDisabledClassifiers) {
        applyChangesOnSubmit(data);
      }
    };

    const saveConfirmationMessage = async (data, totalCount) => {
      const allEnabledDS = $scope._data.allEnabledDs;
      let numberOfChosenDS = 0;
      if (allEnabledDS === true) {
        numberOfChosenDS = totalCount;
      } else {
        numberOfChosenDS = $scope._data.dataSourceList.length;
      }
      const confirmationMessage = await showGenericConfirmationDialog({
        title: await $translate('SCANS:SCAN_PROFILES:ON_SAVE_CONFIRMATION_MESSAGE:TITLE'),
        actionButton: await $translate('BUTTONS:YES'),
        closeButton: await $translate('BUTTONS:NO'),
        text: await $translate('SCANS:SCAN_PROFILES:ON_SAVE_CONFIRMATION_MESSAGE:TEXT', {
          number: numberOfChosenDS,
        }),
      });
      if (confirmationMessage) {
        applyChangesOnSubmit(data);
      }
    };
    scanProfileModel.selectedClassifiersEmpty = isEmpty => {
      scanProfileModel.showEmptyClassifiersMessage = isEmpty;
    };

    scanProfileModel.submit = isValid => {
      scanProfileModel.stayOnThisPageAfterSaving = false;
      const formIsValid =
        isValid && scanProfileModel.scanSchedulerIsValid && !scanProfileModel.showEmptyClassifiersMessage;
      if (formIsValid) {
        const isScheduleTimeSelected = $scope._data.active && $scope._data.schedule;
        const scheduleStr = isScheduleTimeSelected ? parseCronExpression($scope._data.schedule) : '';
        const schedule = isScheduleTimeSelected ? $scope._data.schedule : '';
        $scope._data.scheduleStr = scheduleStr;
        $scope._data.schedule = schedule;

        const data = angular.copy($scope._data);
        const gridConfigQuery = queryService.getGridConfigQuery({
          filter: [
            { field: 'classification_is_enabled', operator: 'in', value: [false, null] },
            { field: 'name', operator: 'in', value: data.dataSourceList || [] },
          ],
        });

        httpService.fetch(`ds-connections?${gridConfigQuery}`).then(
          httpService.fetch('ds-connections').then(
            ({
              data: {
                data: { totalCount },
              },
            }) => {
              if ($scope._data.allEnabledDs === true) {
                saveConfirmationMessage(data, totalCount);
              } else if ($scope._data.dataSourceList.length === 0) {
                applyChangesOnSubmit(data);
              } else if ($scope._data.allEnabledDs === false && $scope._data.dataSourceList.length > 0) {
                saveConfirmationMessage(data, totalCount);
              }
            },
          ),
        );
      } else {
        scanProfileModel.inputNotValid = true;
      }
    };

    scanProfileModel.onClassifierSelect = selectedItems => {
      scanProfileModel.selectedClassifiersList = selectedItems;
    };

    scanProfileModel.isUserNotAuthorized = () => {
      return !hasRootScope();
    };

    scanProfileModel.onClassifierDelete = () => {
      scanProfileModel.stayOnThisPageAfterSaving = true;
      const data = angular.copy($scope._data);
      applyChangesOnSubmit(data);
    };

    const applyChangesOnSubmit = data => {
      data.owners = emailOwnersFromFromData(scanProfileModel.emailOwners);
      data.classifiers = scanProfileModel.selectedClassifiersList;
      const formData = new FormData();
      data.scanType.id === 'hyper_scan' && formData.append('scanProfileHyperScanModel', $scope._data.modelFile);

      if ($stateParams.data || $stateParams.id) {
        if (data.isCustomScanProfile) {
          data.scanType = data.scanType.id;
        } else {
          delete data.scanType;
        }

        formData.append('data', JSON.stringify(data));
        formData.append('scanProfileName', data.name);
        formData.append('scanProfileScheduleStr', data.scheduleStr);
        scansService
          .updateScanProfile(data._id, formData)
          .then(() => {
            $translate('API:MESSAGE:COMMON_PUT_SUCCESS').then(translation => {
              notificationService.success(translation);
            });
            if (!scanProfileModel.stayOnThisPageAfterSaving) {
              navigateToScansPage(CONFIG.states.SCANS_SAVED_PROFILES);
            }
          })
          .catch(err => {
            const message = processError(err, 'Name');
            notificationService.error(message);
          });
      } else {
        data.isCustomScanProfile = true;
        data.scanType = data.scanType.id;
        formData.append('data', JSON.stringify(data));
        formData.append('scanProfileName', data.name);
        formData.append('scanProfileScanType', data.scanType);
        scansService
          .createScanProfile(formData)
          .then(() => {
            $translate('API:MESSAGE:COMMON_POST_SUCCESS').then(translation => {
              notificationService.success(translation);
            });

            navigateToScansPage(CONFIG.states.SCANS_SAVED_PROFILES);
          })
          .catch(err => {
            const message = processError(err, 'Name');
            notificationService.error(message);
          });
      }
    };

    scanProfileModel.dataSourceSelectionChanged = value => {
      $scope._data.dataSourceList = value;
    };

    scanProfileModel.idsorSelectionChanged = value => {
      $scope._data.idsorList = value;
    };

    scanProfileModel.alphaSort = prop => {
      return (a, b) => {
        const aName = a[prop].toLowerCase(),
          bName = b[prop].toLowerCase();

        if (aName < bName) {
          return -1;
        } else if (aName > bName) {
          return 1;
        } else {
          return 0;
        }
      };
    };

    function isScanTypeSelected(scanType) {
      const currentScanType = $scope._data.scanType;
      return currentScanType ? currentScanType.id === scanType.id : false;
    }

    function buildExportMetadataBigidQuery(value) {
      let query = 'scanStatus = Completed';
      if (Array.isArray(value) && value.length > 0) {
        query += ` AND system IN (${value.join(',')})`;
      }
      return encodeURIComponent(query);
    }

    function processError(err, name) {
      const apiError = err.data || err.data.error || '';
      const isProfileWithNoDsNoCs = apiError.includes('Cannot save empty scan.');
      if (isProfileWithNoDsNoCs) {
        return apiError;
      }
      if (apiError.includes('Scan name')) {
        return $translate.instant('API:MESSAGE:COMMON_DUPLICATE_KEY_ERROR', {
          name,
        });
      } else {
        return $translate.instant('API:MESSAGE:COMMON_ERROR');
      }
    }

    scanProfileModel.exportMetadata = () => {
      const query = buildExportMetadataBigidQuery($scope._data.dataSourceList.map(({ name }) => name));
      scansService
        .exportMetadata(`&filter=${query}`)
        .then(() => {
          notificationService.success('Export metadata run successfully');
        })
        .catch(() => {
          notificationService.error('Export metadata failed');
        });
    };

    scanProfileModel.applyPredictor = () => {
      scanProfileModel.isPredictionClicked = true;
      scansService
        .applyPredictor($scope._data.name)
        .then(() => {
          notificationService.success('Prediction triggered successfully');
        })
        .catch(() => {
          notificationService.error('Prediction triggered failed');
          scanProfileModel.isPredictionClicked = false;
        });
    };

    scanProfileModel.isScanApi = () => {
      return isScanTypeSelected(scansService.scanTypes.SCAN_API);
    };

    scanProfileModel.isDataInMotion = () => {
      return isScanTypeSelected(scansService.scanTypes.DATA_IN_MOTION);
    };

    scanProfileModel.isScheduledVisible = () => {
      return !this.isDataInMotion() && !this.isScanApi() && !scanProfileModel.isDefaultDsProfile;
    };

    scanProfileModel.getVisibleScanTypes = () => {
      const availableOptions = scanProfileModel.scanTypes.availableOptions;
      if (!availableOptions) {
        return availableOptions;
      }
      return availableOptions.filter(scanType => scanType.id !== scansService.scanTypes.SCAN_API.id);
    };

    scanProfileModel.setIsShowDialogGuide = value => {
      $rootScope.$apply(() => {
        scanProfileModel.isShowDialogGuide = Boolean(value);
      });
    };

    scanProfileModel.$onInit = () => {
      scanProfileModel.isNewProfile = !$stateParams.data && !$stateParams.id;

      const setScanProfileHeader = () => {
        const TRANSLATION_REQUIRED = ['SCANS', 'SCANS:NEW_SCAN_PROFILE', 'SCANS:EDIT_SCAN_PROFILE'];
        $translate(TRANSLATION_REQUIRED).then(translations => {
          const nameInEditMode = $stateParams.name ?? translations['SCANS:EDIT_SCAN_PROFILE'];
          const pageTitle = scanProfileModel.isNewProfile ? translations['SCANS:NEW_SCAN_PROFILE'] : nameInEditMode;
          const breadcrumb = translations['SCANS'];
          $rootScope.pageHeaderService.setTitle({
            pageTitle,
            showBackButton: true,
            rightSideComponentsContainer: scanProfileModel.isNewProfile
              ? null
              : getApplicationPreference('ENABLE_SCAN_TEMPLATES') &&
                ConvertScanProfileButton({
                  scanProfileFields: { ...$scope._data, scanType: $scope._data?.scanType?.id },
                }),
            breadcrumbs: [{ label: breadcrumb, onClick: navigateToScansPage }, { label: 'Edit ' + pageTitle }],
            titleHelperComponent: getApplicationPreference('ENABLE_SCAN_TEMPLATES')
              ? getBigIdHelpIcon({ onClick: () => scanProfileModel.setIsShowDialogGuide(true) })
              : null,
          });
        });
      };
      scanProfileModel.scanSchedulerIsValid = true;
      if ($stateParams.data) {
        initializeData($stateParams.data);
        setScanProfileHeader();
      } else if ($stateParams.id) {
        scanProfileModel.isDataLoading = true;
        scansService
          .getScanProfile($stateParams.id)
          .then(({ data: { scanProfile } }) => {
            initializeData(scanProfile);
          })
          .catch(error => {
            notificationService.error('There was an error fetching the scan profile, see logs for more info.');
            console.error(error);
            initializeNewScanProfile();
          })
          .finally(() => {
            scanProfileModel.isDataLoading = false;
            setScanProfileHeader();
          });
      } else {
        initializeNewScanProfile($stateParams?.prefill);
        setScanProfileHeader();
      }
    };

    scanProfileModel.addEmailOwner = () => {
      scanProfileModel.emailOwners.push(getEmailOwnerModel());
    };

    scanProfileModel.deleteEmailOwner = itemId => {
      const filteredEmails = scanProfileModel.emailOwners.filter(({ id }) => id !== itemId);
      scanProfileModel.emailOwners = filteredEmails.length ? filteredEmails : [getEmailOwnerModel()];
    };

    scanProfileModel.onChangeScanScheduler = ({ cronExpressionStart, isValid }) => {
      if (isValid) {
        $scope._data.schedule = cronExpressionStart;
        scanProfileModel.scanSchedulerIsValid = true;
      } else {
        scanProfileModel.scanSchedulerIsValid = false;
      }
    };

    scanProfileModel.isShowConvertScanProfileDialogGuide = () => {
      return getApplicationPreference('ENABLE_SCAN_TEMPLATES') && getApplicationPreference('SHOW_NEW_SCANS_PAGE');
    };

    scanProfileModel.updateFileUploader = name => {
      const dummyFile = {
        name,
        isDummyFile: true,
        type: 'text/xml',
        attachmentId: `file__placeholder__for__${name}`,
      };

      $scope._data.modelFile = dummyFile;
      $scope._data.filenameToImport = name;
      scanProfileModel.fileList = [dummyFile];
    };

    scanProfileModel.fileSelected = files => {
      $scope._data.modelFile = files;
      $scope._data.filenameToImport = files.name;
    };

    scanProfileModel.fileRemoved = file => {
      $scope._data.modelFile = undefined;
      $scope._data.filenameToImport = undefined;
      scanProfileModel.fileList = [];
    };

    const emailOwnersToFormData = emailOwners => {
      return Array.isArray(emailOwners) && emailOwners.length
        ? emailOwners.map(emailOwner => getEmailOwnerModel(emailOwner))
        : [getEmailOwnerModel()];
    };

    const emailOwnersFromFromData = emailOwners => {
      return Array.isArray(emailOwners) && emailOwners.length
        ? emailOwners
            .filter(({ ngValue }) => typeof ngValue === 'string' && ngValue.length)
            .map(({ ngValue }) => ngValue)
        : [];
    };

    const getEmailOwnerModel = ngValue => {
      return {
        id: uuid(),
        ngValue,
      };
    };

    const initializeNewScanProfile = prefillParams => {
      const isPrefilledProfile = !!prefillParams;

      scanProfileModel.isCustomScanProfile = true;
      scanProfileModel.mtCorrelation = Boolean(getApplicationPreference('MT_CORRELATION_FF_ENABLED'));
      scanProfileModel.isMultiTenantModeEnabled = isMultiTenantModeEnabled();
      scanProfileModel.emailOwners = emailOwnersToFormData([]);
      $scope._data.allEnabledIdSor = hasRootScope();
      $scope._data.allEnabledDs = false;
      $scope._data.skipIdScan = true;
      $scope._data.isClassificationsAsPiiFindings = false;
      $scope._data.isReviewFindingsEnabled = scanProfileModel.isCurationFeatureEnabled;
      $scope._data.labelFramework = scanProfileModel.labelingFramework.find(framework => framework.id === 'mip');

      if (isPrefilledProfile) {
        $scope._data.name = `Custom Scan Profile For ${prefillParams.name}`;
        scanProfileModel.dataSourceList = [prefillParams.name];
      }
    };
  },
});
