import { BigidGridColumnTypes, BigidGridProps, useFetch } from '@bigid-ui/grid';
import {
  BigidLayoutMasterDetails,
  BigidLayoutMasterDetailsConfig,
  BigidLayoutMasterDetailsGridRowLayouts as BigidGridRowLayouts,
  BigidLayoutMasterDetailsGridSearchTypes as BigidGridSearchType,
} from '@bigid-ui/layout';
import React from 'react';
import { BrandGridRow, BrandItemResponse, CredentialsPermissions, Font } from './brandsTypes';
import { BrandsNoData } from './BrandsNoData';
import { getFixedT } from '../translations';
import { isPermitted } from '../../../services/userPermissionsService';
import { BRANDS_PERMISSIONS } from '@bigid/permissions';
import {
  BigidDropdownOption,
  BigidFormField,
  BigidFormFieldTypes,
  BigidFormValues,
  BooleanMap,
} from '@bigid-ui/components';
import { ALLOWED_NAME_REGEX } from '../../../config/consts';
import styled from '@emotion/styled';
import { FormRichTextEditor } from '../../../components/CustomFormFields/FormRichTextEditor';
import { FormColorPicker } from '../../../components/CustomFormFields/FormColorPicker';
import { isValidWebURL } from '../../../utilities/validation';
import { omitBy, isUndefined } from 'lodash';

const t = (key: string): string => getFixedT('')(key);
const DEFAULT_BRAND_COLOR = '#0000FF';
const BRAND_COLOR_FIELD_NAME = 'color';

export const createGridConfig = (
  id: string,
  config: ReturnType<typeof useFetch<BrandGridRow>>,
): BigidGridProps<BrandGridRow> => ({
  gridId: id,
  rows: config.rows,
  totalRowsCount: config.totalRowsCount,
  skip: config.skip,
  onPagingChanged: config.onPagingChanged,
  onSortingChanged: config.onSortingChanged,
  onFiltersChange: config.onFiltersChanged,
  defaultSorting: config.defaultSorting,
  loading: config.isLoading,
  pageSize: 1000,
  noDataContent: <BrandsNoData message={t('message.noBrands')} />,
  columns: [
    {
      name: 'name',
      title: 'name',
      width: 'auto',
      getCellValue: row => {
        return <BigidLayoutMasterDetails.Row title={row.name} layout={BigidGridRowLayouts.SINGLE_ROW} />;
      },
      type: BigidGridColumnTypes.CUSTOM,
    },
  ],
});

export const createLayoutConfig = (
  gridConfig: BigidGridProps<BrandGridRow>,
): BigidLayoutMasterDetailsConfig<BrandGridRow> => ({
  grid: gridConfig,
  search: {
    fields: ['name'],
    placeholder: t('search.placeholder'),
    type: BigidGridSearchType.INTEGRATED,
  },
});

export const getPermissions = (): CredentialsPermissions => ({
  isDeletePermitted: isPermitted(BRANDS_PERMISSIONS.DELETE.name),
  isEditPermitted: isPermitted(BRANDS_PERMISSIONS.EDIT.name),
  isCreatePermitted: isPermitted(BRANDS_PERMISSIONS.CREATE.name),
});

const validateBrandName = (value: string) => {
  if (!value) {
    return t('message.requiredField');
  }
  if (value.length > 50) {
    return t('message.tooLongName');
  }
  if (!value.match(ALLOWED_NAME_REGEX) || value.length > 50) {
    return t('message.brandNameNotAllowed');
  }

  return false;
};

export const getFormFields = (): BigidFormField[] => [
  {
    name: 'name',
    label: t('form.name'),
    isRequired: true,
    validate: validateBrandName,
    type: BigidFormFieldTypes.TEXT,
  },
  {
    name: 'logo',
    label: t('form.logo'),
    type: BigidFormFieldTypes.TEXT,
    misc: { placeholder: t('form.logoPlaceholder') },
    validate: (value: string) => {
      if (value && !isValidWebURL(value)) {
        return t('form.invalidUrl');
      }
      return false;
    },
  },
  {
    name: BRAND_COLOR_FIELD_NAME,
    label: t('form.objectFillColor'),
    render: FormColorPicker,
  },
  {
    name: 'font',
    label: t('form.font'),
    type: BigidFormFieldTypes.DROP_DOWN,
    dropDownOptions: getFontsDropdownOptions(),
    fieldProps: {
      isSearchable: true,
      size: 'large',
    },
  },
  {
    name: 'footer',
    label: t('form.footer'),
    render: FormRichTextEditor,
    misc: {
      fullWidth: true,
    },
  },
];
const getDropdownInitialValue = (field: BigidFormField, row: BrandGridRow): BigidDropdownOption[] => {
  const { name, dropDownOptions } = field;
  return dropDownOptions.filter(option => [option.value, option.id].includes(row[name as keyof BrandGridRow]));
};

export const getInitialValues = (formFields: BigidFormField[], row: BrandGridRow): BigidFormValues =>
  formFields.reduce<BigidFormValues>((accumulator, field) => {
    const { name, type } = field;
    const fieldName = name as keyof BrandGridRow;
    const isDropDownType = type === BigidFormFieldTypes.DROP_DOWN;
    const getValue = () => {
      if (isDropDownType) {
        return getDropdownInitialValue(field, row);
      }
      if (name === BRAND_COLOR_FIELD_NAME && !row?.color) {
        return DEFAULT_BRAND_COLOR;
      }

      return row[fieldName as keyof BrandGridRow];
    };

    return {
      ...accumulator,
      [name]: getValue(),
    };
  }, {} as BigidFormValues);

export const prepareRequestData = (values: BigidFormValues): Partial<BrandItemResponse> => {
  const { name, logo, color, font, footer } = values;

  return {
    name,
    logo,
    color,
    font: font[0]?.value,
    footer,
  };
};

export const prepareUpdateRequestData = (values: BigidFormValues, updatedFields: BooleanMap) => {
  return Object.keys(values).reduce<BigidFormValues>((accumulator, fieldName) => {
    const isFieldUpdated = Object.keys(updatedFields).includes(fieldName);
    return {
      ...accumulator,
      ...(isFieldUpdated && { [fieldName]: values[fieldName] }),
    };
  }, {});
};

export const getFontsDropdownOptions = (): BigidDropdownOption[] => {
  return Object.values(Font).map(value => ({ displayValue: fonts[value].displayName, value, id: value }));
};

const fonts = {
  [Font.MULISH]: {
    displayName: 'Mulish',
    value: 'mulish',
    id: 'mulish',
  },
  [Font.ARIAL]: {
    displayName: 'Arial',
    value: 'arial',
    id: 'arial',
  },
};

export const Wrapper = styled('div')({
  position: 'relative',
  boxSizing: 'border-box',
  marginLeft: '1rem',
  height: 'calc(100% - 2rem)',
});

export const FormWrapper = styled('div')({
  flexGrow: 1,
});

export const Content = styled('div')({
  display: 'flex',
  justifyContent: 'center',
  padding: '16px 26px',
  maxWidth: '100%',
  form: {
    maxWidth: '50%',
    flex: 1,
    '> fieldset': {
      margin: '0 0 24px',
    },
    "[data-aid*='BigidFormFieldLabelWrapper-bigid-form-field-http']": {
      marginTop: '0px',
    },
    "[data-aid*='checkBoxesWrapper'], [data-aid*='urlWrapper']": {
      display: 'flex',
      alignItems: 'baseline',
      gap: '16px',
    },
    "[data-aid*='BigidFormFieldLabelWrapper-select-either-to-use-http,-https,-or-both-bigid-form-field-protocolslabel']":
      {
        margin: '0',
      },
    "[data-aid*='BigidFormFieldLabelWrapper-select-either-to-use-http,-https,-or-both-bigid-form-field-protocolslabel'] > div:has(div)":
      {
        display: 'none',
      },
    "[data-aid*='BigidFormFieldLabelWrapper-bigid-form-field-certificateId']": {
      paddingTop: '8px',
    },
  },
});

export const alphaSort = (a: BrandItemResponse, b: BrandItemResponse) => {
  const aName = a.name.toLowerCase();
  const bName = b.name.toLowerCase();

  if (aName < bName) return -1;
  if (aName > bName) return 1;
  return 0;
};

export const normalizeFormValues = (values: BigidFormValues) => {
  const normalizedValues = { ...values, font: values?.font?.[0]?.value };
  return omitBy(normalizedValues, isUndefined);
};
