import React from 'react';
import { BigidGridColumn, BigidGridColumnTypes, BigidGridWithToolbarProps } from '@bigid-ui/grid';
import { getCellValueForConnectionStatus } from '../../DSAR/SarProfileSettings/gridCellValues';
import { DataSourceModel, OwnerV2 } from './DataSourceConnectionTypes';
import { DataSourceOnBoardingModel } from './OnboardingAssistant/types';
import { DataSource, getSupportedDataSources } from '../../../utilities/dataSourcesUtils';
import {
  BigidFilterType,
  BigidFilterOptionType,
  BigidGroupedAvatar,
  BigidColorsV2,
  BigidEditableTagsArea,
} from '@bigid-ui/components';
import { getTagsAllPairs, TagEntity } from '../../TagsManagement/TagsManagementService';
import { isPermitted } from '../../../services/userPermissionsService';
import { TAGS_PERMISSIONS } from '@bigid/permissions';
import { getApplicationPreference } from '../../../services/appPreferencesService';
import { OwnerFieldNameEnum } from '../DataSourceConfiguration/types';
import { getItOwnersOptions } from '../DataSourcesService';
import { DiscoveryAppTypeToDisplayName, isAutoDiscoveryAvailableInDs } from './hooks/useAutoDiscoveryAppStatus';
import { DataSourceLayoutNameCell } from './DataSourceLayoutNameCell';
import { generateDataAid } from '@bigid-ui/utils';
import { DataSourceGridCellWithDelayedRender } from './DataSourceGridCellWithDelayedRender';
import { TypeWithLoadingCell } from './TypeWithLoadingCell';
import { getTagFormattedName } from '../../TagsManagement/TagsManagementUtils';
import {
  getDataSourceOnBoardingColumns,
  getDataSourceOnBoardingFilters,
} from './OnboardingAssistant/config/DsOnboardingConfigurations';
import { uniqBy } from 'lodash';
import { isOnboardingAssistantEnabled } from '../../../utilities/featureFlagUtils';
import { findActionByType, findAllAutoDiscoveryApps } from '../../../services/autoDiscoveryService';
import { customAppService } from '../../../services/customAppService';
import { MULTI_ACTION, RUN_ACTION } from '../../AutoDiscovery/constants';
import { ActionItemPresetsEntity } from '../../CustomApp/types';

function shouldUpdateTags(
  prevProps: Readonly<Partial<DataSourceModel>>,
  nextProps: Readonly<Partial<DataSourceModel>>,
) {
  return prevProps?.id === nextProps?.id && prevProps?.tags?.length === nextProps?.tags?.length;
}

const TagsCellValueMemorized = React.memo<Partial<DataSourceModel>>(({ tags, id }) => {
  const value =
    tags?.map(({ tagName, tagValue, properties }) => ({
      name: properties?.displayName ?? getTagFormattedName(tagName),
      value: tagValue,
    })) ?? [];

  return (
    <DataSourceGridCellWithDelayedRender
      content={
        <BigidEditableTagsArea
          dataAid={generateDataAid('BigidEditableTagsArea', [id, 'tags'])}
          value={value}
          isDisabled={true}
          isAutoFit={false}
          entityMaxWidth={150}
          showPlaceholderOnlyOnHover
        />
      }
    />
  );
}, shouldUpdateTags);

function shouldUpdateOwners(
  prevProps: Readonly<Partial<DataSourceModel>>,
  nextProps: Readonly<Partial<DataSourceModel>>,
) {
  return prevProps?.name === nextProps?.name && prevProps?.owners_v2?.length === nextProps?.owners_v2?.length;
}

const OwnersCellValueMemorized = React.memo<Partial<DataSourceModel>>(({ owners_v2: owners, name }) => {
  if (!owners?.length) {
    return;
  }

  return (
    <DataSourceGridCellWithDelayedRender
      content={
        <BigidGroupedAvatar
          dataAid={generateDataAid('it-owners-avatars', [name])}
          avatars={getItOwnersAvatars(owners)}
          size={'small'}
        />
      }
    />
  );
}, shouldUpdateOwners);

export const getDataSourceConnectionDefaultColumns: (
  isGridExperimentalPerformanceFeaturesEnabled: boolean,
) => BigidGridColumn<DataSourceOnBoardingModel>[] = isGridExperimentalPerformanceFeaturesEnabled => {
  return uniqBy(
    [
      {
        title: 'Name',
        name: 'name',
        type: BigidGridColumnTypes.CUSTOM,
        getCellValue: row => {
          return <DataSourceLayoutNameCell row={row} />;
        },
        width: 400,
        filteringEnabled: true,
        sortingEnabled: true,
      },
      {
        title: 'Friendly Name',
        name: 'friendly_name',
        sortingEnabled: true,
        isHiddenByDefault: true,
        width: 200,
        type: BigidGridColumnTypes.TEXT,
        getCellValue: ({ friendly_name }) => friendly_name,
      },
      {
        title: 'Type',
        name: 'type',
        sortingEnabled: true,
        width: 200,
        ...(isGridExperimentalPerformanceFeaturesEnabled
          ? {
              type: BigidGridColumnTypes.CUSTOM,
              getCellValue: ({ displayType }) => <TypeWithLoadingCell displayType={displayType} />,
            }
          : {
              type: BigidGridColumnTypes.TEXT,
              getCellValue: ({ displayType }) => displayType,
            }),
      },

      ...(isOnboardingAssistantEnabled() ? getDataSourceOnBoardingColumns() : []),
      {
        title: 'Auto Discovery',
        name: 'discoveryAppType',
        sortingEnabled: true,
        isHiddenByDefault: true,
        width: 150,
        type: BigidGridColumnTypes.TEXT,
        getCellValue: ({ isFromDiscoveryApp, discoveryAppType }) => (isFromDiscoveryApp ? discoveryAppType : ''),
      },

      {
        title: 'Last Test',
        name: 'connectionStatusTest',
        sortingEnabled: true,
        width: 200,
        type: BigidGridColumnTypes.STATUS,
        getCellValue: ({ connectionStatusTest }) => getCellValueForConnectionStatus(connectionStatusTest),
      },
      {
        title: 'Last Scan',
        name: 'connectionStatusScan',
        sortingEnabled: true,
        width: 200,
        type: BigidGridColumnTypes.STATUS,
        getCellValue: ({ connectionStatusScan }) => getCellValueForConnectionStatus(connectionStatusScan),
      },
      {
        name: 'connectionStatusDeleteFindings',
        title: 'Last Deletion',
        sortingEnabled: true,
        width: 150,
        getCellValue: ({ connectionStatusDeleteFindings }) =>
          getCellValueForConnectionStatus(connectionStatusDeleteFindings),
        type: BigidGridColumnTypes.STATUS,
      },
      {
        title: 'Created At',
        name: 'created_at',
        sortingEnabled: true,
        width: 200,
        type: BigidGridColumnTypes.DATE,
        getCellValue: ({ created_at }) => created_at,
      },
      {
        title: 'Status',
        name: 'enabled',
        sortingEnabled: true,
        width: 150,
        type: BigidGridColumnTypes.STATUS,
        getCellValue: ({ enabled }) => {
          return {
            text: enabled === 'no' ? 'Disabled' : 'Enabled',
            status: enabled === 'no' ? 'warning' : undefined,
          };
        },
      },
      {
        title: 'Archived',
        name: 'archived',
        sortingEnabled: true,
        width: 150,
        type: BigidGridColumnTypes.STATUS,
        getCellValue: ({ archived }) => {
          return {
            text: archived ? 'Yes' : 'No',
            status: archived ? 'warning' : undefined,
          };
        },
      },
      {
        title: 'Data Rights Fulfillment Only',
        name: 'isDsarOnly',
        sortingEnabled: true,
        width: 210,
        type: BigidGridColumnTypes.STATUS,
        getCellValue: ({ isDsarOnly }) => {
          return {
            text: isDsarOnly ? 'Yes' : 'No',
            status: isDsarOnly ? 'warning' : undefined,
          };
        },
      },
      {
        title: 'DB/Schema Name',
        name: 'rdb_name',
        sortingEnabled: true,
        isHiddenByDefault: true,
        width: 150,
        type: BigidGridColumnTypes.TEXT,
        getCellValue: ({ rdb_name = '' }) => rdb_name,
      },
      {
        title: 'Table(s) to scan',
        name: 'rdb_comma_sep_table_list',
        isHiddenByDefault: true,
        width: 300,
        type: BigidGridColumnTypes.CHIPS,
        getCellValue: ({ rdb_comma_sep_table_list }) => {
          if (!rdb_comma_sep_table_list) {
            return;
          }

          return {
            chips: {
              value: rdb_comma_sep_table_list.split(',').map(label => ({
                id: label,
                label,
              })),
              isDisabled: true,
            },
          };
        },
      },
      {
        title: 'Comment',
        name: 'comment',
        width: 150,
        type: BigidGridColumnTypes.TEXT,
        getCellValue: ({ comment }) => comment,
      },
      ...(shouldShowTags()
        ? [
            {
              title: 'Tags',
              name: 'tags',
              disableTooltip: true,
              width: 300,
              type: BigidGridColumnTypes.CUSTOM,
              getCellValue: ({ tags, id }) => <TagsCellValueMemorized id={id} tags={tags} />,
            } as BigidGridColumn<DataSourceModel>,
          ]
        : []),
      ...(shouldShowOwners()
        ? [
            {
              title: 'IT Data Owners',
              name: 'owners_v2',
              sortingEnabled: false,
              width: 300,
              type: BigidGridColumnTypes.CUSTOM,
              getCellValue: row => {
                return <OwnersCellValueMemorized {...row} />;
              },
            } as BigidGridColumn<DataSourceModel>,
          ]
        : []),
    ] as BigidGridColumn<DataSourceOnBoardingModel>[],
    element => element.name,
  );
};

function convertDsToOptions(dataSources: DataSource[]): BigidFilterOptionType[] {
  const dsTypeOptionWithoutDuplicatesMap = dataSources.reduce<Record<string, any>>((acc, { name, displayName }) => {
    const label = displayName || name;
    const value = name.toLowerCase();

    const sameLabelDsType = acc.get(label);
    acc.set(label, {
      label,
      value: sameLabelDsType ? [...sameLabelDsType?.value, value] : value,
      isSelected: false,
    });

    return acc;
  }, new Map());

  return Array.from(dsTypeOptionWithoutDuplicatesMap.values());
}

async function getAutoDiscoveryPresetsOptions() {
  const appsData = await findAllAutoDiscoveryApps();
  const actions = (
    await Promise.all(
      appsData.map(async ({ _id, type }) => {
        const { data } = await customAppService.getCustomAppActions(_id);
        const appRunAction = findActionByType(RUN_ACTION, type, data);
        const appRunMultiAction = findActionByType(MULTI_ACTION, type, data);
        return [appRunAction, appRunMultiAction].filter(p => p);
      }),
    )
  ).flat();

  return actions?.flatMap(({ presets, description }: Record<string, any>) =>
    presets
      ?.filter(({ is_default }: ActionItemPresetsEntity) => !is_default)
      .map(({ name, _id }: ActionItemPresetsEntity) => ({
        label: `${description} - ${name}`,
        value: _id,
        isSelected: false,
      })),
  );
}

export async function getDataSourceConnectionFilterConfig(
  initalFilter: Record<string, any>[] = [],
): Promise<BigidGridWithToolbarProps<DataSourceModel>['filterToolbarConfig']> {
  const defaultOwnerFilter = initalFilter.find(({ field }) => field === 'owners_v2.id')?.value;
  const defaultPresetFilter = initalFilter.find(({ field }) => field === 'discoveryAppPresetId')?.value;
  const [dataSources, tagsAllPairs, initialItOwnersOptions, autoDiscoveryPresetsOptions] = await Promise.all([
    getSupportedDataSources(),
    getTags(),
    getItOwnersOptions(),
    getAutoDiscoveryPresetsOptions(),
  ]);

  return {
    filters: [
      {
        title: 'Type',
        field: 'type',
        operator: 'in',
        disabled: true,
        displayLimit: 500,
        options: convertDsToOptions(dataSources),
        value: [],
      },
      ...(isOnboardingAssistantEnabled() ? getDataSourceOnBoardingFilters().filters : []),

      ...(!isOnboardingAssistantEnabled()
        ? [
            {
              title: 'Status',
              field: 'enabled',
              operator: 'in',
              disabled: true,
              single: true,
              value: [],
              options: [
                {
                  label: 'Enabled',
                  value: 'yes',
                  isSelected: false,
                },
                {
                  label: 'Disabled',
                  value: 'no',
                  isSelected: false,
                },
              ],
            } as BigidFilterType,
          ]
        : []),

      {
        title: 'Archived',
        field: 'archived',
        operator: 'in',
        disabled: true,
        single: true,
        value: [],
        options: [
          {
            label: 'Yes',
            value: true,
            isSelected: false,
          },
          {
            label: 'No',
            value: [false, null],
            isSelected: false,
          },
        ],
      },
      {
        title: 'Data Rights Fulfillment Only',
        field: 'isDsarOnly',
        operator: 'in',
        disabled: true,
        single: true,
        value: [],
        options: [
          {
            label: 'Include',
            value: true,
            isSelected: false,
          },
          {
            label: 'Exclude',
            value: [false, null],
            isSelected: false,
          },
        ],
      },
      {
        title: 'Last Test',
        field: 'connectionStatusTest.is_success',
        operator: 'in',
        disabled: true,
        single: true,
        value: [],
        options: [
          {
            label: 'Success',
            value: true,
            isSelected: false,
          },
          {
            label: 'Failed',
            value: false,
            isSelected: false,
          },
        ],
      },
      {
        title: 'Last Scan',
        field: 'connectionStatusScan.is_success',
        operator: 'in',
        disabled: true,
        single: true,
        value: [],
        options: [
          {
            label: 'Success',
            value: true,
            isSelected: false,
          },
          {
            label: 'Failed',
            value: false,
            isSelected: false,
          },
        ],
      },
      ...(shouldShowTags()
        ? [
            {
              title: 'Tags',
              field: 'tags',
              operator: 'in',
              disabled: true,
              options: tagsAllPairs.map(({ tagName, tagValue, tagId, valueId, properties }: TagEntity) => ({
                label: `${properties?.displayName ?? getTagFormattedName(tagName)} : ${tagValue}`,
                value: JSON.stringify({ valueId, tagId }),
                isSelected: false,
              })),
              value: [],
            } as BigidFilterType,
          ]
        : []),
      ...(shouldShowOwners()
        ? [
            {
              title: 'Data Owner',
              field: 'owners_v2.id',
              operator: 'in',
              disabled: !Boolean(defaultOwnerFilter),
              isSearchAsync: true,
              loadSearchOptions: getItOwnersOptions,
              options: initialItOwnersOptions,
              value: defaultOwnerFilter ?? [],
            } as BigidFilterType,
          ]
        : []),
      ...(isAutoDiscoveryAvailableInDs()
        ? ([
            {
              title: 'Auto Discovered',
              field: 'isFromDiscoveryApp',
              operator: 'in',
              disabled: true,
              single: true,
              value: [],
              options: [
                {
                  label: 'Yes',
                  value: true,
                  isSelected: false,
                },
                {
                  label: 'No',
                  value: [false, null],
                  isSelected: false,
                },
              ],
            },
            {
              title: 'Auto Discovery Type',
              field: 'discoveryAppType',
              operator: 'in',
              disabled: true,
              options: (
                Object.keys(DiscoveryAppTypeToDisplayName) as (keyof typeof DiscoveryAppTypeToDisplayName)[]
              ).map(discoveryAppType => ({
                label: DiscoveryAppTypeToDisplayName[discoveryAppType],
                value: discoveryAppType,
                isSelected: false,
              })),
              value: [],
            },
            {
              title: 'Auto Discovery Configuration',
              field: 'discoveryAppPresetId',
              operator: 'in',
              options: autoDiscoveryPresetsOptions,
              disabled: !Boolean(defaultPresetFilter),
              value: defaultPresetFilter ?? [],
            },
          ] as BigidFilterType[])
        : []),
    ],
    searchConfig: {
      searchFilterKeys: [
        'name',
        'friendly_name',
        'comment',
        'rdb_comma_sep_table_list',
        'rdb_name',
        'discoveryAppPresetId',
      ],
      initialValue: '',
      operator: 'textSearch',
    },
  };
}

function shouldShowTags(): boolean {
  return isPermitted(TAGS_PERMISSIONS.READ.name) && getApplicationPreference('SHOW_DS_TAGS');
}

function shouldShowOwners(): boolean {
  return getApplicationPreference('DATA_SOURCE_CSV_IMPORT_VISIBLE');
}

async function getTags() {
  let results: TagEntity[] = [];
  try {
    if (shouldShowTags()) {
      results = (await getTagsAllPairs()).filter(({ properties }) => !properties?.isExplicit);
    }
  } catch (e) {
    // in case catalog is down
    console.warn(e);
  }

  return results;
}

function getItOwnersAvatars(owners: OwnerV2[]) {
  const avatars = owners.reduce((ownersAvatars, { id, email, type, origin }) => {
    return type === OwnerFieldNameEnum.itOwners && (typeof email === 'string' || typeof id === 'string')
      ? [
          ...ownersAvatars,
          {
            userName: email || id,
            ...(origin === 'legacy' ? { color: BigidColorsV2.gray[300] } : {}),
          },
        ]
      : ownersAvatars;
  }, []);

  return avatars;
}

export const FIRST_RENDER_FIELDS = ['name', 'type'];
