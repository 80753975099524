import { httpService } from '../../../services/httpService';
import { getApplicationPreference } from '../../../services/appPreferencesService';

const ENABLE_LEGACY_STRUCTURED_PREVIEW = 'ENABLE_LEGACY_STRUCTURED_PREVIEW';

export interface DataCatalogPreviewTableRecord {
  id: string;
  [fieldName: string]: string;
}

export interface DataCatalogPreviewTableField {
  fieldName: string;
  fieldValue: string;
  fieldType: string;
}

export interface DataCatalogPreviewTableDataPayload {
  fullyQualifiedName: string;
  limit: number;
}

export interface DataCatalogPreviewTableDataResponse {
  amountOfFields: number;
  results: DataCatalogPreviewTableField[][];
}

export const getPreviewTableData = (data: DataCatalogPreviewTableDataPayload) => {
  if (getApplicationPreference(ENABLE_LEGACY_STRUCTURED_PREVIEW)) {
    return httpService
      .post<{ data: DataCatalogPreviewTableDataResponse }, DataCatalogPreviewTableDataPayload>(
        `data-catalog/table-preview`,
        data,
      )
      .then(({ data }) => data);
  }
  return httpService
    .post<{ data: DataCatalogPreviewTableDataResponse }, DataCatalogPreviewTableDataPayload>(
      `data-catalog/structured-preview`,
      data,
    )
    .then(({ data }) => data);
};

export const getPreviewTableDataCached = (fullyQualifiedName: string) => {
  fullyQualifiedName = encodeURIComponent(fullyQualifiedName);

  if (getApplicationPreference(ENABLE_LEGACY_STRUCTURED_PREVIEW)) {
    return httpService
      .fetch<{ data: DataCatalogPreviewTableDataResponse }>(`data-catalog/table-preview/${fullyQualifiedName}`)
      .then(({ data }) => data);
  }

  return httpService
    .fetch<{ data: DataCatalogPreviewTableDataResponse }>(`data-catalog/structured-preview/${fullyQualifiedName}`)
    .then(({ data }) => data);
};

export const deletePreviewTableData = (objectName: string) => {
  if (getApplicationPreference(ENABLE_LEGACY_STRUCTURED_PREVIEW)) {
    return httpService.delete(`data-catalog/table-preview/${encodeURIComponent(objectName)}`).then(({ data }) => data);
  }
  return httpService
    .delete(`data-catalog/structured-preview/${encodeURIComponent(objectName)}`)
    .then(({ data }) => data);
};
