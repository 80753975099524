import React, { FC, useState } from 'react';
import { makeStyles } from '@mui/styles';
import {
  BigidDialog,
  BigidDialogButtonType,
  BigidRecurringEventPicker,
  PrimaryButton,
  SecondaryButton,
} from '@bigid-ui/components';
import { notificationService } from '../../../../services/notificationService';
import { Preset } from './CustomAppActions';

interface Props {
  preset: Preset;
  onClose: () => void;
  onUpdateSchedule: (preset: Preset, isSchedule: boolean, cronExpression: string) => void;
}

const useStyles = makeStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: '40px',
  },
});

const emptyCronExpression = '0 * * * * *';

export const SchedulerModal: FC<Props> = ({ preset, onClose, onUpdateSchedule }) => {
  const classes = useStyles();
  const [cronExpression, setCronExpression] = useState<string>(
    preset.isScheduled ? preset.cronExpression : emptyCronExpression,
  );
  const [isCronValid, setIsCronValid] = useState<boolean>(false);

  const handleSaveScheduleButtonClick = async () => {
    const successMessage = `Set schedule to preset ${preset.name}`;
    try {
      await onUpdateSchedule(preset, true, cronExpression);
      notificationService.success(successMessage);
    } catch (err) {
      const {
        data: { error },
      } = err.response;
      notificationService.error(error);
    }
  };

  const handleRemoveScheduleButtonClick = async () => {
    const successMessage = `Schedule have been removed for preset: ${preset.name}`;
    try {
      await onUpdateSchedule(preset, false, emptyCronExpression);
      notificationService.success(successMessage);
    } catch (err) {
      const {
        data: { error },
      } = err.response;
      notificationService.error(error);
    }
  };

  const handleEventPickerChange = (cronExpression: string, isValid: boolean): void => {
    setCronExpression(cronExpression);
    setIsCronValid(isValid);
  };

  const isSaveButtonDisabled = (): boolean => !isCronValid || cronExpression === preset.cronExpression;

  const buttons: BigidDialogButtonType[] = [
    { component: SecondaryButton, onClick: onClose, text: 'Cancel' },
    {
      component: PrimaryButton,
      onClick: handleSaveScheduleButtonClick,
      text: 'Save',
      disabled: isSaveButtonDisabled(),
    },
  ];

  if (preset.isScheduled) {
    const removeScheduleButton: BigidDialogButtonType = {
      component: SecondaryButton,
      onClick: handleRemoveScheduleButtonClick,
      text: 'Remove Schedule',
      alignment: 'left',
    };
    buttons.push(removeScheduleButton);
  }

  return (
    <BigidDialog
      isOpen={true}
      onClose={onClose}
      title={'Schedule ' + preset.name}
      buttons={buttons}
      borderBottom={true}
    >
      <div className={classes.root}>
        <span>Enable schedule execution for preset {preset.name}. Choose your recurring schedule.</span>
        <BigidRecurringEventPicker initialValue={cronExpression} onChange={handleEventPickerChange} />
      </div>
    </BigidDialog>
  );
};
