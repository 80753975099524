import React, { FC, memo } from 'react';
import { makeStyles } from '@mui/styles';
import { generateDataAid } from '@bigid-ui/utils';
import { BigidBody1 } from '@bigid-ui/components';
import { getFindingIndicatorColorByStatus } from '../../../../curationUtils';
import { CuratedFieldStatus } from '../../../../curationService';
import { useLocalTranslation } from '../../../../translations';

export type AttributePreviewFindingLegendProps = {
  dataAid?: string;
  approvedFindingsCount: number;
  rejectedFindingsCount: number;
  uncuratedFindingsCount: number;
};

const useStyles = makeStyles({
  root: {
    display: 'flex',
  },
  delimeter: {
    margin: '0px 4px',
  },
});

export const AttributePreviewFindingLegend: FC<AttributePreviewFindingLegendProps> = memo(
  ({ dataAid = 'AttributePreviewFindingLegend', uncuratedFindingsCount, rejectedFindingsCount }) => {
    const classes = useStyles();
    const { t } = useLocalTranslation('CuratedFields');

    return (
      <div className={classes.root}>
        {/* NOTE: temporarilt disabled */}
        {/* <BigidBody1
          data-aid={generateDataAid(dataAid, ['approved-findings-count'])}
          sx={{ color: getFindingIndicatorColorByStatus(CuratedFieldStatus.APPROVED) }}
        >
          {approvedFindingsCount} {t('approved')}
        </BigidBody1>
        <BigidBody1 className={classes.delimeter}>|</BigidBody1> */}
        <BigidBody1
          data-aid={generateDataAid(dataAid, ['rejected-findings-count'])}
          sx={{ color: getFindingIndicatorColorByStatus(CuratedFieldStatus.REJECTED) }}
        >
          {rejectedFindingsCount} {t('rejected')}
        </BigidBody1>
        <BigidBody1 className={classes.delimeter}>|</BigidBody1>
        <BigidBody1
          data-aid={generateDataAid(dataAid, ['uncurated-findings-count'])}
          sx={{ color: getFindingIndicatorColorByStatus(CuratedFieldStatus.UNCURATED) }}
        >
          {uncuratedFindingsCount} {t('unreviewed')}
        </BigidBody1>
      </div>
    );
  },
);
