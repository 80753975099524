import React from 'react';
import { Chip, styled } from '@mui/material';
import { BigidColorsV2 } from '@bigid-ui/components';

const IconWrapper = styled('span')`
  &.MuiChip-icon {
    font-size: unset;
    margin-right: -13px;
    margin-top: 8px;
  }
`;

interface GridChipProps {
  label: React.ReactNode;
  icon?: JSX.Element;
  color?: string;
  bgColor?: string;
}

export const GridChip = ({ label, icon, color, bgColor }: GridChipProps) => {
  return (
    <Chip
      label={label}
      title={label as string}
      size="small"
      variant="outlined"
      icon={icon && <IconWrapper>{icon}</IconWrapper>}
      sx={{
        backgroundColor: bgColor || BigidColorsV2.white,
        ...(color ? { color } : {}),
        ...(color ? { border: 0 } : { border: `1px solid ${BigidColorsV2.gray[200]}` }),
        cursor: 'inherit',
      }}
    />
  );
};
