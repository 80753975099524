import React, { useEffect, useState } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import {
  BigidStatusBadge,
  BigidStatusBadgeSize,
  BigidStatusBadgeType,
  BigidLink,
  LinkColor,
  TertiaryButton,
  BigidButtonIcon,
  BigidIconType,
  BigidHeading6,
  BigidBody1,
  BigidCaption,
} from '@bigid-ui/components';
import { format } from 'date-fns';
import { BigidAddIcon, BigidConnectIllustration, BigidErrorIllustration } from '@bigid-ui/icons';
import {
  AssessmentApplicationStatus,
  VendorAssessment,
  VendorAssessmentAppName,
  VendorAssessmentStatus,
} from './VendorsTypes';
import { $state } from '../../services/angularServices';
import { CONFIG } from '../../../config/common';
import {
  AssessmentEmptyStateWrapper,
  AssessmentSectionContainer,
  AssessmentListItemWrapper,
  AssessmentSectionLabelWrapper,
  AssessmentListWrapper,
  AssessmentsWrapper,
  AssessmentWrapper,
  AssessmentListItemTitleWrapper,
  LabelWithEllipsis,
  ErrorAssessmentWrapper,
  ErrorAssessmentTextWrapper,
} from './VendorsSummaryStyles';
import { VendorsTrackingEvents, trackVendors } from './utils/analytics';
import { getVendorAssessments } from './vendorsService';
import { notificationService } from '../../services/notificationService';
import { useLocationChange } from '../DSAR/SarProfileSettings/useLocationChange';

const useStyles = makeStyles({
  link: { fontSize: '0.875rem' },
});

export const VendorSummaryAssessments = () => {
  const [vendorAssessmentDetails, setVendorAssessment] = useState<VendorAssessment[]>([]);
  const { stateParams } = useLocationChange();

  useEffect(() => {
    (async () => {
      try {
        const assessments = await getVendorAssessments(stateParams.projectId);
        setVendorAssessment(assessments);
      } catch (error) {
        setVendorAssessment([]);
        notificationService.error(`Failed to fetch Vendor assessment details`);
        console.error(`Failed to fetch Vendor assessment details: ${JSON.stringify(error?.response)}`);
      }
    })();
  }, [stateParams.projectId]);

  return (
    <AssessmentsWrapper>
      {vendorAssessmentDetails.map(
        ({
          tpaId,
          appName,
          assessmentList,
          addNewAssessmentPath,
          redirectToAssessmentGridPath,
          totalAssessmentCount,
          errorMessage,
          appStatus,
        }) => {
          const { skipRendering, errorComponent } = checkForAppError(appStatus, errorMessage);

          if (skipRendering) {
            return null;
          }

          const { entityName, entityNamePlural, biEvents } = getAppLabels(appName);
          return (
            <AssessmentWrapper key={tpaId}>
              <AssessmentSectionContainer>
                <AssessmentSectionLabelWrapper>
                  <BigidHeading6>{`Latest ${entityNamePlural}`}</BigidHeading6>
                  {!!assessmentList.length && !errorComponent && (
                    <AddNewAssessmentButton
                      appId={tpaId}
                      addNewAssessmentPath={addNewAssessmentPath}
                      entityName={entityName}
                      biEvent={biEvents.ADD_NEW}
                    />
                  )}
                </AssessmentSectionLabelWrapper>
                <AssessmentListWrapper>
                  {errorComponent ? (
                    errorComponent
                  ) : assessmentList.length ? (
                    assessmentList
                      .map(assessment => (
                        <VendorAssessmentListItem
                          key={assessment.assessmentId}
                          appId={tpaId}
                          name={assessment.name}
                          path={assessment.redirectToAssessmentPath}
                          statusName={assessment.status}
                          statusType={assessment.statusType}
                          createdAt={assessment.createdAt}
                          biEvent={biEvents.VIEW_SINGLE}
                        />
                      ))
                      .concat(
                        totalAssessmentCount > 3 ? (
                          <VendorAssessmentListItem
                            key="see-more"
                            appId={tpaId}
                            name={`View All (${totalAssessmentCount})`}
                            path={redirectToAssessmentGridPath}
                            biEvent={biEvents.VIEW_ALL}
                          />
                        ) : (
                          []
                        ),
                      )
                  ) : (
                    <EmptyAssessment
                      appId={tpaId}
                      addNewAssessmentPath={addNewAssessmentPath}
                      entityName={entityName}
                      entityNamePlural={entityNamePlural}
                      biEvent={biEvents.ADD_NEW}
                    />
                  )}
                </AssessmentListWrapper>
              </AssessmentSectionContainer>
            </AssessmentWrapper>
          );
        },
      )}
    </AssessmentsWrapper>
  );
};

const AddNewAssessmentButton = ({
  appId,
  addNewAssessmentPath,
  entityName,
  biEvent,
}: {
  appId: string;
  addNewAssessmentPath: string;
  entityName: string;
  biEvent: string;
}) => (
  <BigidButtonIcon
    onClick={navigateTo(addNewAssessmentPath, appId, biEvent)}
    size="medium"
    tooltipText={`New ${entityName}`}
    bi={{
      disabled: true,
    }}
    icon={BigidAddIcon}
  />
);

export const EmptyAssessment = ({
  appId,
  addNewAssessmentPath,
  entityName,
  entityNamePlural,
  biEvent,
}: {
  appId: string;
  addNewAssessmentPath: string;
  entityName: string;
  entityNamePlural: string;
  biEvent: string;
}) => {
  return (
    <AssessmentEmptyStateWrapper>
      <BigidCaption>{`There are no ${entityNamePlural?.toLowerCase()} related to this vendor.`}</BigidCaption>
      <TertiaryButton
        onClick={navigateTo(addNewAssessmentPath, appId, biEvent)}
        size="medium"
        text={`New ${entityName}`}
        bi={{
          disabled: true,
        }}
        startIcon={<BigidAddIcon />}
      />
    </AssessmentEmptyStateWrapper>
  );
};

export const VendorAssessmentListItem = ({
  name,
  path,
  appId,
  statusType,
  statusName,
  createdAt,
  biEvent,
}: {
  name: string;
  path: string;
  appId: string;
  biEvent: string;
  statusType?: string;
  statusName?: string;
  createdAt?: Date;
}) => {
  const { link } = useStyles({});
  return (
    <AssessmentListItemWrapper>
      <AssessmentListItemTitleWrapper>
        <BigidLink
          text={name}
          underline="none"
          color={LinkColor.PRIMARY}
          shouldOpenNewTab={false}
          isBold={false}
          rel="undefined"
          onClick={navigateTo(path, appId, biEvent)}
          classNames={link}
        />
        <LabelWithEllipsis size="small">{toDate(createdAt)}</LabelWithEllipsis>
      </AssessmentListItemTitleWrapper>
      {statusType && (
        <BigidStatusBadge size={BigidStatusBadgeSize.SMALL} type={getStatusBadgeType(statusType)} label={statusName} />
      )}
    </AssessmentListItemWrapper>
  );
};

type ErrorAssessmentType = { title: string; description: string; icon: BigidIconType };

const ErrorAssessment = ({ title, description, icon: Icon }: ErrorAssessmentType) => {
  return (
    <ErrorAssessmentWrapper>
      <Icon size="small" />
      <ErrorAssessmentTextWrapper>
        <BigidHeading6>{title}</BigidHeading6>
        <BigidBody1 size="small">{description}</BigidBody1>
      </ErrorAssessmentTextWrapper>
    </ErrorAssessmentWrapper>
  );
};

const getStatusBadgeType = (status: string) => {
  switch (status) {
    case VendorAssessmentStatus.COMPLETED:
      return BigidStatusBadgeType.SUCCESS;
    case VendorAssessmentStatus.IN_PROGRESS:
      return BigidStatusBadgeType.INFO;
    default:
      return BigidStatusBadgeType.PENDING;
  }
};

const toDate = (date: Date) => (date ? format(new Date(date), 'MMM dd, yyyy hh:mm') : null);

const appNameToLabelValuesMapper = {
  [VendorAssessmentAppName.PIA]: {
    entityName: 'Assessment',
    entityNamePlural: 'Assessments',
    biEvents: {
      ADD_NEW: VendorsTrackingEvents.ADD_NEW_ASSESSMENT_CLICK,
      VIEW_SINGLE: VendorsTrackingEvents.VIEW_ASSESSMENT_CLICK,
      VIEW_ALL: VendorsTrackingEvents.VIEW_ALL_ASSESSMENTS_CLICK,
    },
  },
  [VendorAssessmentAppName.ROPA]: {
    entityName: 'Process',
    entityNamePlural: 'Process',
    biEvents: {
      ADD_NEW: VendorsTrackingEvents.ADD_NEW_PROCESS_CLICK,
      VIEW_SINGLE: VendorsTrackingEvents.VIEW_PROCESS_CLICK,
      VIEW_ALL: VendorsTrackingEvents.VIEW_ALL_PROCESSES_CLICK,
    },
  },
};

const getAppLabels = (tpaName: VendorAssessmentAppName) => {
  return appNameToLabelValuesMapper[tpaName || VendorAssessmentAppName.PIA];
};

const removeStartingSlash = (path: string) => (path?.startsWith('/') ? path.slice(1) : path);

const navigateTo = (path: string, appId?: string, biEvent?: string) => () => {
  if (biEvent) {
    trackVendors(biEvent);
  }
  $state.go(CONFIG.states.CUSTOM_APP, {
    id: appId,
    appRoute: removeStartingSlash(path),
  });
};

const appStatusToErrorMapper: Record<Partial<AssessmentApplicationStatus>, ErrorAssessmentType> = {
  [AssessmentApplicationStatus.OFFLINE]: {
    title: 'Temporarily Unavailable',
    description: 'This application is currently offline. Please try again in a few moments.',
    icon: BigidConnectIllustration,
  },
  [AssessmentApplicationStatus.OUTDATED]: {
    title: 'Incompatible Version',
    description:
      "Your application version isn't compatible with the vendor's integration. Please update to the latest version to continue.",
    icon: BigidErrorIllustration,
  },
  [AssessmentApplicationStatus.ONLINE]: null,
};

const checkForAppError = (appStatus: AssessmentApplicationStatus, errorMessage: string) => {
  const errorDetails = appStatusToErrorMapper[appStatus];

  if (errorMessage) {
    console.error(errorMessage);
  }

  return {
    skipRendering: !errorDetails && !!errorMessage,
    errorComponent: !!errorDetails ? <ErrorAssessment {...errorDetails} /> : null,
  };
};
