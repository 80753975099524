import { BigidGridRow } from '@bigid-ui/grid';

interface ConnectionStatus {
  is_success: boolean;
  last_connection: string;
  num_of_object: number;
}

export enum ScanState {
  IN_PROGRESS = 'InProgress',
  COMPLETED = 'Completed',
  FAILED = 'Failed',
  PAUSED = 'Paused',
  STOPPED = 'Stopped',
}

export interface ScanProgressData {
  percentage: string;
}

export interface ScanData {
  breakdown_progress_state: ScanProgressData;
  state: ScanState;
}

export interface MetadataModel extends BigidGridRow {
  name: string;
  type: string;
  enabled: string;
  archived?: boolean;
  comment?: boolean;
  connectionStatusScan?: ConnectionStatus;
  connectionStatusTest?: ConnectionStatus;
  connectionStatusDeleteFindings?: ConnectionStatus;
  updatedAt: string;
  location?: string;
  createdAt?: string;
  created_at: string;
  lastWriteTime?: string;
  lastAccessTime?: string;
  region?: string;
  sizeInBytes?: number;
  ownerName?: string;
  objectsCount?: number;
  bucket_name?: string;
  scan?: ScanData;
}

export interface MetadataConnectionsResponse {
  dsConnections: MetadataModel[];
  availableDsTypes: string[];
  totalCount: number;
}
