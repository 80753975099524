import { countries } from './countries';

export const CONFIG = {
  /**
   *  default product language
   */
  defaultLocale: 'en',
  /**
   *  supported data sources types
   */
  countries,
  customContent: {
    forbidden: {
      content: `<div class="access-denied">
                        <div class="access-denied__inner"></div>
                     </div>`,
      title: 'ACCESS_DENIED',
    },
  },
  /**
   * material colors palette
   */
  materialColors: {
    red: {
      300: '#E57373',
      400: '#EF5350',
    },
    pink: {
      300: '#F06292',
      400: '#EC407A',
    },
    purple: {
      300: '#BA68C8',
      400: '#AB47BC',
    },
    deepPurple: {
      300: '#9575CD',
      400: '#7E57C2',
    },
    indigo: {
      300: '#7986CB',
      400: '#5C6BC0',
    },
    blue: {
      300: '#64B5F6',
      400: '#42A5F5',
    },
    lightBlue: {
      300: '#4FC3F7',
      400: '#29B6F6',
    },
    cyan: {
      300: '#4DD0E1',
      400: '#26C6DA',
    },
    teal: {
      300: '#4DB6AC',
      400: '#26A69A',
    },
    green: {
      300: '#81C784',
      400: '#66BB6A',
    },
    lightGreen: {
      300: '#AED581',
      400: '#9CCC65',
    },
    lime: {
      300: '#DCE775',
      400: '#D4E157',
    },
    yellow: {
      300: '#FFF176',
      400: '#FFEE58',
    },
    amber: {
      300: '#FFD54F',
      400: '#FFCA28',
    },
    orange: {
      300: '#FFB74D',
      400: '#FFA726',
    },
    deepOrange: {
      300: '#FF8A65',
      400: '#FF7043',
    },
    brown: {
      300: '#A1887F',
      400: '#8D6E63',
    },
    gray: {
      300: '#E0E0E0',
      400: '#BDBDBD',
    },
    blueGray: {
      300: '#90A4AE',
      400: '#78909C',
    },
  },
  states: {
    CUSTOM_DASHBOARD: 'customDashboard',
    CUSTOM_DASHBOARD_PREVIEW: 'customDashboardPreview',
    DASHBOARD: 'dashboard',
    DASHBOARD_SECURITY: 'dashboard/security',
    INVENTORY: 'exploration',
    CORRELATION: 'entityCorrelation',
    CLASSIFIERS: 'classifiers',
    CLASSIFIERS_MANAGEMENT: 'classifiersManagement',
    CLASSIFICATION: 'classification',
    CLUSTERING: 'clustering',
    CLUSTERING_OBJECTS_PREVIEW: 'clusterObjectsPreview',
    CLUSTER_PREVIEW: 'clusterPreview',
    CATALOG: 'dataCatalog',
    CATALOG_PREVIEW: 'dataCatalogPreview',
    CATALOG_FOLLOWED_OBJECTS: 'dataCatalogFollowedObjects',
    DUPLICATE_OBJECTS_PREVIEW: 'duplicateObjectsPreview',
    POLICIES: 'rules',
    DATA_OVERVIEW: 'data-overview',
    SCAN_RESULT: 'scanResult',
    SCAN_ANALYSIS: 'scanAnalysis',
    DATA_SOURCE_ROUTER: 'dataSourceRouter',
    DATA_SOURCE_CONNECTIONS: 'dataSourceConnections',
    EDIT_DATA_SOURCE_CONNECTION: 'dataSourceConnection',
    NEW_DATA_SOURCE_CONNECTION: 'newDataSourceConnection',
    DATA_SOURCE_CONNECTIONS_INIT: 'dataSourcesInit',
    IDENTITY_SOURCE_CONNECTIONS: 'entitySources',
    NEW_IDENTITY_SOURCE_CONNECTION: 'newEntityConnection',
    SECONDARY_CONNECTIONS: 'dlpConnections',
    NEW_SECONDARY_CONNECTION: 'newDLPConnection',
    APPLICATIONS_SETUP: 'applicationSetup',
    CREDENTIALS: 'credentials',
    CERTIFICATES_MANAGEMENT: 'certificatesManagement',
    SAVED_QUERIES: 'savedQueries',
    IDENTITY_ACCESS_MANAGEMENT: 'identityAccessManagement',
    AUDIT: 'audit',
    GENERAL_SETTINGS: 'generalSettings',
    SCANS: 'scans',
    ADVANCED_TOOLS: 'advancedTools',
    RULES: 'catalogRules',
    LOGIN: 'login',
    PRIVACY_COMPLIANCE: 'privacyCompliance',
    UPDATE_PASSWORD: 'updatePassword',
    USERS: 'users',
    EDIT_SCAN_PROFILE: 'editScanProfile',
    CREATE_SCAN_PROFILE: 'createScanProfile',
    RISK_CONFIGURATION: 'riskConfiguration',
    BUSINESS_FLOWS: 'dataMapping',
    BUSINESS_FLOWS_ROPA: 'dataMappingRopa',
    TASKS_LIST: 'taskList',
    BREACH_RESPONSE: 'breachResponse',
    ACCESS_REQUEST_MANAGEMENT: 'subjectAccessRequest',
    DATA_MINIMIZATION: 'dataMinimization',
    DATA_MINIMIZATION_REQUESTS: 'dataMinimization.dataMinimizationRequests',
    DATA_MINIMIZATION_DATA_SOURCES: 'dataMinimization.dataMinimizationDataSources',
    DATA_MINIMIZATION_OBJECT: 'dataMinimizationObject',
    DATA_DELETION_GLOBAL_SETTINGS: 'dataDeletionGlobalSettings',
    ACCESS_REQUEST_MANAGEMENT_PROFILE: 'subjectAccessRequest.profile',
    ACCESS_REQUEST_MANAGEMENT_JOINT_CORRELATION: 'subjectAccessRequest.correlations-mapping',
    ACCESS_REQUEST_MANAGEMENT_REPORT_TEMPLATES: 'subjectAccessRequest.report-templates',
    ACCESS_REQUEST_MANAGEMENT_FILES_RETRIEVAL: 'subjectAccessRequest.files-retrieval',
    ACCESS_REQUEST_MANAGEMENT_DICTIONARY: 'subjectAccessRequest.dictionary',
    PERSONAL_INFO_REPORT: 'personalInfoReport',
    NEW_BREACH_RESPONSE: 'newBreachResponse',
    EXPLORE_BREACH_RESPONSE: 'exploreBreachResponse',
    FORBIDDEN: 'forbidden',
    NOT_FOUND: 'notFound',
    CONNECTION_LOST: 'connectionLost',
    UNDER_MAINTENANCE: 'underMaintenance',
    AZURE_INFORMATION_PROTECTION: 'labeling',
    ACCESS_GOVERNANCE: 'accessGovernance',
    ACCESS_GOVERNANCE_SETTINGS: 'accessGovernanceSettings',
    CONSENT_GOVERNANCE: 'consentGovernance',
    CONSENT_GOVERNANCE_LIST: 'consentGovernance.list',
    CONSENT_GOVERNANCE_AGREEMENTS: 'consentGovernance.agreements',
    CONSENT_GOVERNANCE_REPORTS: 'consentGovernance.reports',
    NEW_CONSENT_CONNECTION: 'newConsentConnection',
    DIGEST_REPORT: 'digestReport',
    DATA_INSIGHTS_STUDIO: 'dataInsightsStudio',
    DATA_PIPELINE: 'dataPipeline',
    DATA_SOURCE_INIT: 'dataSourcesInit',
    APPLICATIONS_MANAGEMENT: 'appManagement',
    CUSTOM_APP_CREATE: 'createCustomApp',
    CUSTOM_APP: 'customApp',
    CUSTOM_APP_ACTIONS: 'customAppActionsPage',
    CUSTOM_APP_ACTIVITY_LOG: 'customAppActivityLogPage',
    CUSTOM_APP_EDIT: 'customAppEditPage',
    CUSTOM_APP_EDIT_PRESET: 'customAppEditPresetPage',
    CUSTOM_APP_EDIT_GLOBAL_PRESET: 'customAppEditGlobalPresetPage',
    CUSTOM_APP_DEEP_LINKING: 'customAppDeepLinking',
    CONFIG_DATA_SOURCE: 'configDataSource',
    CONFIG_DATA_SOURCE_NEW: 'configDataSourceNew',
    PRIVACY_PORTAL: 'privacyPortalDummyState',
    USER_PERMISSIONS: 'userPermissions',
    GROUP_PERMISSIONS: 'groupPermissions',
    LABELS_CONFIGURATION: 'labelsConfigurations',
    TAGS: 'tags',
    SCAN_WINDOW: 'scanWindows',
    FAILED_OBJECT_REPORT: 'failedObjectReport',
    ACTION_CENTER: 'actionCenter',
    ACTION_CENTER_AUDIT: 'actionCenter.audit',
    ACTION_CENTER_ACTIONS: 'actionCenter.actions',
    ACTION_CENTER_OVERVIEW: 'actionCenter.overview',
    ACTION_CENTER_CONFIGURATIONS: 'actionCenter.configurations',
    METADATA_SEARCH_RESULTS: 'metadataSearchResults',
    ACCESS_INTELLIGENCE_SETTINGS: 'accessIntelligenceSettings',
    SENSITIVITY_CLASSIFICATION: 'sensitivityClassification',
    SENSITIVITY_CLASSIFICATION_NEW: 'sensitivityClassificationNew',
    SENSITIVITY_CLASSIFICATION_EDIT: 'sensitivityClassificationEdit',
    SCANS_CONTROLLER: 'scansController',
    SCANS_NEW: 'scansNew',
    SCANS_NEW_SCANS: 'scansNew.scans',
    SCANS_NEW_SCANS_IN_PROGRESS: 'scansNew.scans.inProgress',
    SCANS_NEW_SCANS_COMPLETED: 'scansNew.scans.completed',
    SCANS_SAVED_PROFILES: 'scansNew.savedProfiles',
    SCANS_SCAN_TEMPLATES: 'scansNew.scanTemplates',
    SCANS_SCAN_INSIGHTS: 'scanInsights',
    SCANS_PLANNED_SCANS: 'scansNew.plannedScans',
    FMSD: 'fmsd',
    AUTO_DISCOVERY: 'autoDiscovery',
    SMALLID_FOR_DEVELOPERS: 'sfd',
    EXECUTIVE_REPORT: 'executiveReport',
    HOTSPOTS_REPORT: 'hotspotsReport',
    HOTSPOTS_REPORT_NEW: 'hotspotsReportNew',
    CURATION: 'curation',
    CLASSIFIER_FINDINGS: 'classifierFindings',
    CLASSIFIER_PREDICTIONS: 'classifierPredictions',
    SCAN_TEMPLATE: 'scanTemplate',
    CREATE_SCAN: 'createScan',
    CATALOG_DISCOVERY: 'catalogDiscovery',
    GETTING_STARTED: 'gettingStarted',
    GETTING_STARTED_SECURITY: 'gettingStartedSecurity',
    ACTIONABLE_INSIGHTS: 'actionableInsights',
    ACTIONABLE_INSIGHTS_CASE: 'actionableInsightsCase',
    RISK_ASSESSMENT: 'riskAssessment',
    DATA_EXPLORER: 'dataExplorer',
    CREDENTIAL_PROVIDERS: 'credentialProviders',
    CATALOG_SEARCH_RESULTS: 'catalogSearchResults',
    PROXIES: 'proxies',
    APP_DATA_STEWARDSHIP: 'dataStewardship',
    VENDORS: 'vendors',
    VENDOR_SUMMARY: 'vendor-summary',
    NEW_DATA_CATALOG: 'newDataCatalog',
    AUTO_DISCOVERY_PRESET_CONFIG: 'autoDiscoveryPresetConfig',
    EMAIL_TEMPLATES: 'emailTemplates',
    EMAIL_TEMPLATES_BROWSE: 'emailTemplates.browse',
    LEGAL_ENTITIES: 'legalEntities',
    LEGAL_ENTITIES_OVERVIEW: 'legalEntities-overview',
    EMAIL_TEMPLATES_BRANDS: 'emailTemplates.brands',
    RISK_LIBRARY: 'risksLibrary',
    RISK_CONTROLS: 'risksControls',
    FRAMEWORKS: 'frameWorksCompliance',
    FRAMEWORKS_CONTROLS: 'frameWorksControls',
  },
  permissionDisplayNames: {
    admin: 'Root Admin',
    api: 'API Admin',
    'non-admin': 'Inventory Analyst',
    collaborator: 'Associate',
    ops: 'Data Admin',
    'corp-id': 'Inventory Admin',
    'IAM-admin': 'IAM Manager',
    'it-non-admin': 'Scans Manager',
    'business-admin': 'Data Analyst',
    scanner: 'Root Scanner',
    'data-insights-studio': 'Data Insights Studio',
    'ds-collaborator': 'Data Source Collaborator',
  },
  // eslint-disable-next-line no-restricted-syntax
  AMPLITUDE_PROXY_URL: 'https://amplitude-api.bigid.cloud/2/httpapi',
  DATADOG_SITE: 'datadoghq.com',
  DATADOG_SERVICE_NAME: 'bigid_ui',
};

export const FULL_WIDTH_PAGES = [
  CONFIG.states.SCAN_TEMPLATE,
  CONFIG.states.CREATE_SCAN,
  CONFIG.states.CATALOG_DISCOVERY,
  CONFIG.states.DATA_EXPLORER,
  CONFIG.states.CATALOG_SEARCH_RESULTS,
];
