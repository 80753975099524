import React, { FC, PropsWithChildren } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { BigidColors, BigidHeading6 } from '@bigid-ui/components';

interface FormBoxProps extends PropsWithChildren {
  title: string;
  description?: string;
}

const useStyles = makeStyles({
  wrapper: {
    border: `1px solid ${BigidColors.gray[50]}`,
    background: BigidColors.gray[50],
    borderRadius: 4,
    padding: 16,
  },
  description: ({ hasChildren }: { hasChildren: boolean }) => ({
    color: BigidColors.gray[900],
    fontSize: '0.875rem',
    paddingBottom: hasChildren ? 16 : 0,
  }),
});

export const BigidFormBox: FC<FormBoxProps> = ({ title, description, children }) => {
  const hasChildren = React.Children.count(children) > 0;
  const classes = useStyles({ hasChildren });
  return (
    <div className={classes.wrapper}>
      <BigidHeading6>{title}</BigidHeading6>
      {description && <div className={classes.description}>{description}</div>}
      {children}
    </div>
  );
};
