import React, { FC } from 'react';
import angular from 'angular';
import { convertToAngular } from '../../../common/services/convertToAngular';
import { BannerComponent, BigidColorsV2 } from '@bigid-ui/components';
import { sessionStorageService } from '../../../common/services/sessionStorageService';

interface ApplicationBannerProps {
  isExpired: boolean;
}

export const ApplicationBanner: FC<ApplicationBannerProps> = ({ isExpired }) => {
  const userRoles: string[] = sessionStorageService.get('userRoles');
  const isAdmin: boolean = userRoles.includes('admin');
  const bannerText = `Application ${
    isExpired
      ? `license has expired. ${
          isAdmin
            ? 'Add an application license key or contact our team for assistance'
            : 'Contact your administrator for assistance'
        }.`
      : 'is offline.'
  }`;
  return (
    <BannerComponent data-aid={'BigidApplicationBanner'} background={BigidColorsV2.red[900]}>
      {bannerText}
    </BannerComponent>
  );
};

angular.module('app').component('applicationBanner', convertToAngular(ApplicationBanner, ['isExpired']));
