import { module } from 'angular';
const app = module('app');

app.factory('activitiesAndRecordsService', [
  '$http',
  'appSettings',
  function ($http, appSettings) {
    return {
      getPiiInvestigationsData: function getPiiInvestigationsData(piiInvestigationId) {
        if (piiInvestigationId == null) {
          piiInvestigationId = '';
        }
        return $http
          .get(appSettings.serverPath + '/' + appSettings.version + '/piiInvestigations/' + piiInvestigationId)
          .then(function (response) {
            if (Array.isArray(response.data)) {
              return response.data;
            }
            return [response.data];
          });
      },
      createPiiInvestigation: function createPiiInvestigation(pii_record_id_object) {
        var scanRequestPayload = pii_record_id_object;
        scanRequestPayload.scanType = 'investigationScan';
        return $http
          .post(appSettings.serverPath + '/' + appSettings.version + '/scans', scanRequestPayload)
          .then(function (response) {
            return response.data;
          });
      },
      createMultiplePiiInvestigation: function createMultiplePiiInvestigation() {
        var scanRequestPayload = {};
        scanRequestPayload.scanType = 'multipleInvestigationScan';
        return $http
          .post(appSettings.serverPath + '/' + appSettings.version + '/scans', scanRequestPayload)
          .then(function (response) {
            return response.data;
          });
      },
      getPiiInvestigationsCount: function getPiiInvestigationsCount() {
        return $http
          .get(appSettings.serverPath + '/' + appSettings.version + '/piiInvestigationsCount')
          .then(function (response) {
            return response.data.piiInvestigationsCount;
          });
      },
      deletePiiInvestigations: function deletePiiInvestigations(piiInvestigationID) {
        return $http
          .delete(appSettings.serverPath + '/' + appSettings.version + '/piiInvestigations/' + piiInvestigationID)
          .then(function (response) {
            return response.data;
          });
      },

      deleteAllPiiInvestigations: function deleteAllPiiInvestigations() {
        return $http
          .delete(appSettings.serverPath + '/' + appSettings.version + '/piiInvestigations')
          .then(function (response) {
            return response.data;
          });
      },

      getPiiRecordsExport: function getPiiRecordsExport(filter, ids) {
        var params = '';
        if (filter) {
          filter = decodeURIComponent(filter);
          params = '?filter=' + filter;
        }
        if (ids) {
          ids = decodeURIComponent(ids);
          if (params.length > 1) {
            params = params + '&';
          } else {
            params = '?';
          }
          params = params + 'ids=' + ids;
        }
        return $http
          .get(appSettings.serverPath + '/' + appSettings.version + '/pii_records/export' + params)
          .then(function (response) {
            return response.data;
          });
      },
    };
  },
]);
