import { analyticsService } from '../../../services/analyticsService';
import { BIGID_BI_EVENTS } from '../../../config/BigIdBiEvents';
import { AdvancedToolbarOverrideValue } from '@bigid-ui/components';

export type DataExplorerSearchSubmitBiPayload = {
  filter?: string;
  searchText?: string;
};

export type DataExplorerSearchTabSwitchBiPayload = {
  tab: string;
};

export type DataExplorerSearchFilterSelectBiPayload = {
  filter: AdvancedToolbarOverrideValue[];
};

export type DataExplorerSelectAllToggleBiPayload = {
  isSelectAllToggled: boolean;
};

export type DataExplorerQuickSearchListItemClickPayload = {
  entityName: string;
  id?: string;
};

export function sendDataExplorerSystemHeaderBarSubmitBiAnalytics(biPayload: DataExplorerSearchSubmitBiPayload): void {
  analyticsService.trackManualEvent(BIGID_BI_EVENTS.DATA_EXPLORER_SYSTEM_HEADER_BAR_SUBMIT, biPayload);
}

export function sendDataExplorerLandingPageBarSubmitBiAnalytics(biPayload: DataExplorerSearchSubmitBiPayload): void {
  analyticsService.trackManualEvent(BIGID_BI_EVENTS.DATA_EXPLORER_LANDING_PAGE_BAR_SUBMIT, biPayload);
}

export function sendDataExplorerLandingPageClusteringLinkBiAnalytics(): void {
  analyticsService.trackManualEvent(BIGID_BI_EVENTS.DATA_EXPLORER_LANDING_PAGE_LINK_CLUSTERING);
}

export function sendDataExplorerLandingPageCorrelationLinkBiAnalytics(): void {
  analyticsService.trackManualEvent(BIGID_BI_EVENTS.DATA_EXPLORER_LANDING_PAGE_LINK_CORRELATION);
}

export function sendDataExplorerLandingPageClassificationLinkBiAnalytics(): void {
  analyticsService.trackManualEvent(BIGID_BI_EVENTS.DATA_EXPLORER_LANDING_PAGE_LINK_CLASSIFICATION);
}

export function sendDataExplorerLandingPageDataOverviewLinkBiAnalytics(): void {
  analyticsService.trackManualEvent(BIGID_BI_EVENTS.DATA_EXPLORER_LANDING_PAGE_LINK_DATA_OVERVIEW);
}

export function sendDataExplorerLandingPagePoliciesLinkBiAnalytics(): void {
  analyticsService.trackManualEvent(BIGID_BI_EVENTS.DATA_EXPLORER_LANDING_PAGE_LINK_POLICIES);
}

export function sendDataExplorerLandingPageDiscoveredDataSourceLinkBiAnalytics(): void {
  analyticsService.trackManualEvent(BIGID_BI_EVENTS.DATA_EXPLORER_LANDING_PAGE_LINK_RECENTLY_DISC_DS);
}

export function sendDataExplorerLandingPageViewedObjectLinkBiAnalytics(): void {
  analyticsService.trackManualEvent(BIGID_BI_EVENTS.DATA_EXPLORER_LANDING_PAGE_LINK_RECENTLY_VIEW_OBJ);
}

export function sendDataExplorerLandingPageFollowedObjectLinkBiAnalytics(): void {
  analyticsService.trackManualEvent(BIGID_BI_EVENTS.DATA_EXPLORER_LANDING_PAGE_LINK_FOLLOWED_OBJ);
}

export function sendDataExplorerLandingPageBrowseCatalogLinkBiAnalytics(): void {
  analyticsService.trackManualEvent(BIGID_BI_EVENTS.DATA_EXPLORER_LANDING_PAGE_LINK_BROWSE_CATALOG);
}

export function sendDataExplorerResultsPageBarSubmitBiAnalytics(biPayload: DataExplorerSearchSubmitBiPayload): void {
  analyticsService.trackManualEvent(BIGID_BI_EVENTS.DATA_EXPLORER_RESULTS_PAGE_BAR_SUBMIT, biPayload);
}

export function sendDataExplorerResultsPageSelectAllBiAnalytics(biPayload: DataExplorerSelectAllToggleBiPayload): void {
  analyticsService.trackManualEvent(BIGID_BI_EVENTS.DATA_EXPLORER_RESULTS_PAGE_SELECT_ALL, biPayload);
}

export function sendDataExplorerResultsPageAddTagOpenBiAnalytics(): void {
  analyticsService.trackManualEvent(BIGID_BI_EVENTS.DATA_EXPLORER_RESULTS_PAGE_ACTION_ADD_TAG_OPEN);
}

export function sendDataExplorerResultsPageAddTagCloseBiAnalytics(): void {
  analyticsService.trackManualEvent(BIGID_BI_EVENTS.DATA_EXPLORER_RESULTS_PAGE_ACTION_ADD_TAG_CLOSE);
}

export function sendDataExplorerResultsPageAddTagSubmitBiAnalytics(): void {
  analyticsService.trackManualEvent(BIGID_BI_EVENTS.DATA_EXPLORER_RESULTS_PAGE_ACTION_ADD_TAG_SUBMIT);
}

export function sendDataExplorerResultsPageRemoveTagOpenBiAnalytics(): void {
  analyticsService.trackManualEvent(BIGID_BI_EVENTS.DATA_EXPLORER_RESULTS_PAGE_ACTION_REMOVE_TAG_OPEN);
}

export function sendDataExplorerResultsPageRemoveTagCloseBiAnalytics(): void {
  analyticsService.trackManualEvent(BIGID_BI_EVENTS.DATA_EXPLORER_RESULTS_PAGE_ACTION_REMOVE_TAG_CLOSE);
}

export function sendDataExplorerResultsPageRemoveTagSubmitBiAnalytics(): void {
  analyticsService.trackManualEvent(BIGID_BI_EVENTS.DATA_EXPLORER_RESULTS_PAGE_ACTION_REMOVE_TAG_SUBMIT);
}

export function sendDataExplorerResultsPageExportSubmitBiAnalytics(): void {
  analyticsService.trackManualEvent(BIGID_BI_EVENTS.DATA_EXPLORER_RESULTS_PAGE_ACTION_EXPORT_SUBMIT);
}

export function sendDataExplorerResultsPageShareOpenBiAnalytics(): void {
  analyticsService.trackManualEvent(BIGID_BI_EVENTS.DATA_EXPLORER_RESULTS_PAGE_ACTION_SHARE_URL_OPEN);
}

export function sendDataExplorerResultsPageShareCopyBiAnalytics(): void {
  analyticsService.trackManualEvent(BIGID_BI_EVENTS.DATA_EXPLORER_RESULTS_PAGE_ACTION_SHARE_URL_COPY);
}

export function sendDataExplorerResultsPageShareCloseBiAnalytics(): void {
  analyticsService.trackManualEvent(BIGID_BI_EVENTS.DATA_EXPLORER_RESULTS_PAGE_ACTION_SHARE_URL_CLOSE);
}

export function sendDataExplorerResultsPageTabSwitchBiAnalytics(biPayload: DataExplorerSearchTabSwitchBiPayload): void {
  analyticsService.trackManualEvent(BIGID_BI_EVENTS.DATA_EXPLORER_RESULTS_PAGE_TAB_SWITCH, biPayload);
}

export function sendDataExplorerResultsPageFilterSelectBiAnalytics(
  biPayload: DataExplorerSearchFilterSelectBiPayload,
): void {
  analyticsService.trackManualEvent(BIGID_BI_EVENTS.DATA_EXPLORER_RESULTS_PAGE_FILTER_SELECT, biPayload);
}

export function sendDataExplorerQuickSearchApiRequestBiAnalytics(biPayload: DataExplorerSearchSubmitBiPayload): void {
  analyticsService.trackManualEvent(BIGID_BI_EVENTS.DATA_EXPLORER_QUICK_SEARCH_API_REQUEST, biPayload);
}

export function sendDataExplorerQuickSearchViewAllClickBiAnalytics(
  biPayload: DataExplorerQuickSearchListItemClickPayload,
): void {
  analyticsService.trackManualEvent(BIGID_BI_EVENTS.DATA_EXPLORER_QUICK_SEARCH_VIEW_ALL_CLICK, biPayload);
}

export function sendDataExplorerQuickSearchListItemClickBiAnalytics(
  biPayload: DataExplorerQuickSearchListItemClickPayload,
): void {
  analyticsService.trackManualEvent(BIGID_BI_EVENTS.DATA_EXPLORER_QUICK_SEARCH_LIST_ITEM_CLICK, biPayload);
}
