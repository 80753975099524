import React, { FC } from 'react';
import { makeStyles, useTheme } from '@mui/styles';
import {
  BigidFlexibleListCardContentProps,
  BigidBody1,
  BigidCategoriesArea,
  BigidCategoryChipProps,
  BigidTooltip,
  BigidChip,
} from '@bigid-ui/components';
import { generateDataAid, generateGuidedTourId } from '@bigid-ui/utils';
import { CuratedAttributesGridRecord } from './useCuratedAttributesConfig';
import { useLocalTranslation } from '../translations';
import { formatNumberCompact } from '../../../utilities/numericDataConverter';
import { getAttributeStatusBadge, getPercentageValueFormatted, getReviewedTextCalculated } from '../curationUtils';
import { BigidHelpIcon } from '@bigid-ui/icons';

const useStyles = makeStyles({
  root: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    width: '100%',
    height: '100%',
    paddingRight: '16px',
  },
  reviewed: {
    marginLeft: '4px',
    display: 'flex',
  },
  helpIconWrapper: {
    display: 'flex',
    marginLeft: '3px',
  },
  totalFindings: {
    width: '100%',
  },
  summary: {
    display: 'flex',
    gap: '16px',
    justifyContent: 'space-between',
    width: '100%',
  },
  summaryPiece: {
    display: 'flex',
    alignItems: 'center',
  },
  categories: {
    width: '100%',
    marginRight: '40px',
  },
  accuracy: {
    width: '100%',
  },
});

type CuratedAttributeContentProps = BigidFlexibleListCardContentProps<unknown, CuratedAttributesGridRecord>;

export const CuratedAttributeContent: FC<CuratedAttributeContentProps> = ({ cardData }) => {
  const classes = useStyles();
  const {
    curatedFields,
    totalFields,
    totalFindings,
    categories = [],
    approvedCount,
    rejectedCount,
  } = cardData.originalData;
  const { t } = useLocalTranslation('CuratedAttributes');
  const theme = useTheme();

  const accuracy = approvedCount / (approvedCount + rejectedCount);
  const accuracyFormatted = !isNaN(accuracy) ? getPercentageValueFormatted(accuracy) : `N/A`;

  const categoryChips: BigidCategoryChipProps[] = categories.map(({ displayName, color }) => {
    return {
      categoryName: displayName,
      categoryColor: color,
      dataAid: generateDataAid('CuratedAttributeContent', [cardData.id, 'categories']),
    };
  });

  return (
    <div className={classes.root} data-aid={generateDataAid('CuratedAttributeContent', [cardData.id])}>
      <div
        className={classes.accuracy}
        data-aid={generateDataAid('CuratedAttributeContent', [cardData.id, 'accuracy'])}
      >
        <BigidChip
          size="small"
          label={t('CuratedAttributesAccuracy.accuracyText', { accuracyValue: accuracyFormatted })}
          bgColor={theme.vars.tokens.bigid.backgroundPrimary}
          shadow
        />
      </div>
      <BigidBody1
        className={classes.totalFindings}
        data-aid={generateDataAid('CuratedAttributeContent', [cardData.id, 'total-findings'])}
        data-tour-id={generateGuidedTourId('CuratedAttributeContent', [String(cardData.title), 'total-findings'])}
      >
        {formatNumberCompact(totalFindings, 2)} {t('totalFindings')}
      </BigidBody1>
      <div className={classes.summary} data-aid={generateDataAid('CuratedAttributeContent', [cardData.id, 'summary'])}>
        <div className={classes.summaryPiece}>
          <BigidBody1
            className={classes.reviewed}
            data-aid={generateDataAid('CuratedAttributeContent', [cardData.id, 'summary-reviewed-count'])}
            data-tour-id={generateDataAid('CuratedAttributeContent', [cardData.id, 'summary-reviewed-count'])}
          >
            {getReviewedTextCalculated(totalFields, curatedFields, 'attribute')}
            <BigidTooltip title={t('reviewedToolTip')} placement="top">
              <span className={classes.helpIconWrapper}>
                <BigidHelpIcon />
              </span>
            </BigidTooltip>
          </BigidBody1>
        </div>
      </div>
      <div className={classes.categories}>
        <BigidCategoriesArea
          dataAid={generateDataAid('CuratedAttributeContent', [cardData.id, 'categories'])}
          value={categoryChips}
          valueAlignment="end"
        />
      </div>
      <div>
        <BigidBody1 data-aid={generateDataAid('CuratedAttributeContent', [cardData.id, 'status-badge-attribute'])}>
          {getAttributeStatusBadge(cardData.originalData)}
        </BigidBody1>
      </div>
    </div>
  );
};
