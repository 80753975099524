import React, { FC } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { BigidList } from '@bigid-ui/components';
import { SystemDialogContentProps } from '../../services/systemDialogService';

export interface SearchAttributesInfoProps {
  attributes: Attribute[];
}

export interface Attribute {
  name: string;
  value: string;
}

const useStyles = makeStyles({
  list: {
    paddingRight: 80,
    paddingTop: 20,
  },
});

export const SearchAttributesInfo: FC<SystemDialogContentProps<SearchAttributesInfoProps>> = ({ attributes }) => {
  const classes = useStyles({});
  return (
    <div className={classes.list}>
      <BigidList
        listItems={attributes.map(({ name, value }) => ({
          id: name,
          name,
          value,
        }))}
      />
    </div>
  );
};
