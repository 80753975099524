import styled from '@emotion/styled';

export const Content = styled.div`
  display: flex;
  justify-content: center;
  padding: 16px 26px;
  max-width: 600px;

  form {
    flex: 1;

    > fieldset {
      margin: 0 0 24px;

      &[data-aid*='usernameIsConstant'] {
        margin: 0;
      }
    }

    label {
      margin-bottom: 4px;
    }

    [data-aid*='scopes-bar-chips'] {
      min-height: 38px;
      display: flex;
      align-items: center;

      button {
        bottom: 2px;
      }
    }
  }
`;

export const Wrapper = styled.div`
  position: relative;
  box-sizing: border-box;
  margin-left; 1rem;
  height: calc(100% - 2rem);
  `;

export const FormWrapper = styled.div`
  flex-grow: 1;
`;

export const LoaderWrapper = styled.div`
  height: 100%;
  position: relative;
`;
