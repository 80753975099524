import React, { useEffect, useCallback, useState, useMemo } from 'react';
import {
  BigidGridColumn,
  BigidGridColumnTypes,
  BigidGridQueryComponents,
  BigidGridRow,
  BigidGridWithToolbarProps,
  FetchDataFunction,
} from '@bigid-ui/grid';
import { BigidCollaborationIcon } from '@bigid-ui/icons';
import {
  BigidFilterType,
  BigidFlexibleListCardActionType,
  BigidFlexibleListCardArea,
  BigidFlexibleListCardData,
  BigidFlexibleListCardProps,
  BigidFormValues,
  EntityEvents,
  entityEventsEmitter,
  BigidFieldFilter,
  BigidFilterOptionType,
} from '@bigid-ui/components';
import {
  CuratedDataSource,
  DataSourcesFiltersNames,
  getCuratedDataSources,
  getSystemUsers,
  getUserPreferences,
  resampleCurationDs,
  SamplingStatus,
  SavedFilter,
  setUserPreferences,
} from '../curationService';
import { CuratedDataSourcesProps } from './CuratedDataSources';
import { omit } from 'lodash';
import { queryService } from '../../../services/queryService';
import { notificationService } from '../../../services/notificationService';
import { CuratedDataSourceContent } from './CuratedDataSourceContent';
import { parseFieldFiltersToSearchQuery } from '@bigid-ui/layout';
import { useLocalTranslation } from '../translations';
import { getApplicationPreference } from '../../../services/appPreferencesService';
import { CurationEvents, trackEventCurationView } from '../curationEventTrackerUtils';
import {
  getCuratedDataSourcesSorted,
  getDataSourceTypeAvatar,
  getUpdateRowByIdEventPayload,
} from './CuratedDataSourcesUtils';
import { showAssignCollaboratorDialog } from './actions/AssignCollaborator/assignCollaboratorService';
import { AssigneeIndicator } from './actions/AssignCollaborator/AssigneeIndicator';
import { v4 as uuid } from 'uuid';
import { CurationDefaultViewSwitcher } from '../CurationDefaultViewSwitcher';
import { CurationStageId } from '../useCurationState';
import {
  getSelectedFilterOptions,
  getFilterSelectedValues,
  getFilterFromToolBar,
  getFilterFromUserPreference,
} from '../curationUtils';

export type CuratedDataSourcesGridRecord = BigidGridRow & CuratedDataSource;

export type DataSourcesGridToolbarFilterConfig =
  BigidGridWithToolbarProps<CuratedDataSourcesGridRecord>['filterToolbarConfig'];

export type UseCuratedDataSourcesConfigState = {
  isReady: boolean;
  gridConfig: BigidGridWithToolbarProps<CuratedDataSourcesGridRecord>;
};

export type UseCuratedDataSourcesConfigProps = CuratedDataSourcesProps;

const getOptions = (isScanEntry: boolean) =>
  isScanEntry
    ? ({
        dsNameKey: 'dsConnectionName',
        searchOperator: 'equal',
        ownerField: 'owners',
        getQueryString(queryComponents: BigidGridQueryComponents) {
          const query = queryService.getGridConfigQuery(omit(queryComponents, ['filter']));
          return `${query}&filter=${parseFieldFiltersToSearchQuery(
            queryComponents.filter,
            Boolean(getApplicationPreference('NEW_QUERY_FILTER_ENABLED')),
          )}`;
        },
      } as const)
    : ({
        dsNameKey: 'name',
        searchOperator: 'textSearch',
        ownerField: 'owners_v2.id',
        getQueryString(queryComponents: BigidGridQueryComponents) {
          return queryService.getGridConfigQuery(queryComponents);
        },
      } as const);

export const useCuratedDataSourcesConfig = ({
  scanId,
  onProceedToAttributeList,
  onCurationDefaultInitialStageSelect,
}: UseCuratedDataSourcesConfigProps): UseCuratedDataSourcesConfigState => {
  const [isReady, setIsReady] = useState<boolean>(false);
  const [filterToolbarConfig, setFilterToolbarConfig] = useState<DataSourcesGridToolbarFilterConfig>();
  const { t } = useLocalTranslation('CuratedDataSources');

  const isScanEntry = !!scanId;
  const opts = useMemo(() => getOptions(isScanEntry), [isScanEntry]);
  const gridId = useMemo(() => `CuratedDataSources-${uuid()}`, []);

  const { fetchData, columns } = useMemo(() => {
    const fetchData: FetchDataFunction<CuratedDataSourcesGridRecord> = async (
      queryComponents: BigidGridQueryComponents,
    ) => {
      try {
        const searchBarFromToolBar = getFilterFromToolBar(queryComponents?.filter, DataSourcesFiltersNames.SEARCH);
        const ownerFilterFromToolBar = getFilterFromToolBar(queryComponents?.filter, DataSourcesFiltersNames.OWNER);

        const payloadForUserPreference: SavedFilter[] = [
          {
            filterName: (ownerFilterFromToolBar?.field as DataSourcesFiltersNames) ?? DataSourcesFiltersNames.OWNER,
            values: (ownerFilterFromToolBar?.value as string[]) ?? [],
          },
          {
            filterName: (searchBarFromToolBar?.field as DataSourcesFiltersNames) ?? DataSourcesFiltersNames.SEARCH,
            values: searchBarFromToolBar?.value ? [searchBarFromToolBar?.value as string] : [],
          },
        ];

        await setUserPreferences({ dataSourcesFilters: payloadForUserPreference });

        const query = opts.getQueryString(queryComponents);

        const { data } = await getCuratedDataSources({
          scanId,
          query,
        });
        const { sources, totalCount } = data;
        const sortedSources = getCuratedDataSourcesSorted(sources);

        return {
          totalCount,
          data: sortedSources.map(source => ({
            ...source,
            id: source.source,
            owner: source.owner || 'N/A',
            scannerTypeGroup: source.scannerTypeGroup,
          })),
        };
      } catch ({ message }) {
        notificationService.error(t('fetchingCuratedDataSourceFailed'));
        console.error(`An error has occurred: ${message}`);

        return {
          totalCount: 0,
          data: [],
        };
      }
    };

    const columns: BigidGridColumn<CuratedDataSourcesGridRecord>[] = [
      {
        name: 'source',
        title: 'source',
        width: 'auto',
        getCellValue: data => {
          const { id, source, owner, scannerTypeGroup } = data;
          const cardData: BigidFlexibleListCardData<CuratedDataSourcesGridRecord> = {
            id,
            title: source,
            subTitle: `Data Owner: ${owner}`,
            originalData: data,
            avatar: getDataSourceTypeAvatar(scannerTypeGroup),
          };

          return {
            card: {
              cardData,
              content: CuratedDataSourceContent,
              disableActionsTransformation: true,
              config: {
                [BigidFlexibleListCardArea.ACTIONS]: {
                  hasDelimeter: true,
                },
              },
              actions: [
                {
                  label: t('reassign'),
                  name: 'reassign',
                  type: BigidFlexibleListCardActionType.CUSTOM,
                  getTooltipText: ({ originalData }: BigidFlexibleListCardData<CuratedDataSource>) => {
                    return originalData.collaborator;
                  },
                  getActionIcon: () => {
                    return <AssigneeIndicator />;
                  },
                  getIsDisabled({ originalData }: BigidFlexibleListCardData<CuratedDataSource>) {
                    const { samplingStatus, totalAttributes } = originalData;

                    return samplingStatus !== SamplingStatus.COMPLETED || !totalAttributes;
                  },
                  getIsShown({ originalData }: BigidFlexibleListCardData<CuratedDataSource>) {
                    const { samplingStatus, isSupported, collaborator } = originalData;

                    return (
                      (![SamplingStatus.ERROR, SamplingStatus.NEVER_SAMPLED].includes(samplingStatus) ||
                        !isSupported) &&
                      Boolean(collaborator)
                    );
                  },
                  onExecute: ({ originalData }: BigidFlexibleListCardData<CuratedDataSource>) => {
                    const { samplingStatus, totalAttributes } = originalData;
                    const isDisabled = samplingStatus !== SamplingStatus.COMPLETED || !totalAttributes; //NOTE: a hack, for reason unknown disabled icon still propagates a click event

                    if (!isDisabled) {
                      showAssignCollaboratorDialog({
                        curatedDataSource: originalData,
                        onSubmit: (data: BigidFormValues) => {
                          const { email = [] } = data;

                          entityEventsEmitter.emit(
                            EntityEvents.UPDATE_BY_ID,
                            getUpdateRowByIdEventPayload(gridId, originalData.source, {
                              collaborator: email[0]?.value,
                            }),
                          );
                        },
                      });
                    }
                  },
                },
                {
                  label: t('assign'),
                  name: 'assign',
                  type: BigidFlexibleListCardActionType.ICON,
                  getActionIcon: () => {
                    return BigidCollaborationIcon;
                  },
                  getIsDisabled({ originalData }: BigidFlexibleListCardData<CuratedDataSource>) {
                    const { samplingStatus, totalAttributes } = originalData;

                    return samplingStatus !== SamplingStatus.COMPLETED || !totalAttributes;
                  },
                  getIsShown({ originalData }: BigidFlexibleListCardData<CuratedDataSource>) {
                    const { samplingStatus, isSupported, collaborator } = originalData;

                    return (![SamplingStatus.ERROR].includes(samplingStatus) || !isSupported) && !Boolean(collaborator);
                  },
                  onExecute: ({ originalData }: BigidFlexibleListCardData<CuratedDataSource>) => {
                    const { samplingStatus, totalAttributes } = originalData;
                    const isDisabled = samplingStatus !== SamplingStatus.COMPLETED || !totalAttributes; //NOTE: a hack, for reason unknown disabled icon still propagates a click event

                    if (!isDisabled) {
                      showAssignCollaboratorDialog({
                        curatedDataSource: originalData,
                        onSubmit: (data: BigidFormValues) => {
                          const { email = [] } = data;

                          entityEventsEmitter.emit(
                            EntityEvents.UPDATE_BY_ID,
                            getUpdateRowByIdEventPayload(gridId, originalData.source, {
                              collaborator: email[0]?.value,
                            }),
                          );
                        },
                      });
                    }
                  },
                },
                {
                  label: t('review'),
                  name: 'review',
                  getIsDisabled({ originalData }: BigidFlexibleListCardData<CuratedDataSource>) {
                    const { samplingStatus, totalAttributes } = originalData;

                    return samplingStatus !== SamplingStatus.COMPLETED || !totalAttributes;
                  },
                  onExecute: ({ originalData }: BigidFlexibleListCardData<CuratedDataSource>) => {
                    trackEventCurationView(CurationEvents.CURATION_DATA_SOURCES_REVIEW);
                    onProceedToAttributeList(originalData);
                  },
                  getIsShown({ originalData }: BigidFlexibleListCardData<CuratedDataSource>) {
                    const { samplingStatus, isSupported, isSamplingStuck } = originalData;
                    return (
                      (![SamplingStatus.ERROR, SamplingStatus.NEVER_SAMPLED].includes(samplingStatus) ||
                        !isSupported) &&
                      !isSamplingStuck
                    );
                  },
                },
                {
                  label: t('resample'),
                  name: 'resample',
                  onExecute: async ({ originalData }: BigidFlexibleListCardData<CuratedDataSource>) => {
                    const { scanId } = originalData;
                    trackEventCurationView(CurationEvents.CURATION_DATA_SOURCES_RESAMPLE);
                    await resampleCurationDs(scanId);
                    notificationService.success(`${t('resamplingStarted')}`);
                    entityEventsEmitter.emit(EntityEvents.RELOAD);
                  },
                  getIsShown({ originalData }: BigidFlexibleListCardData<CuratedDataSource>) {
                    const { samplingStatus, isSupported, isSamplingStuck } = originalData;
                    return (
                      ([SamplingStatus.ERROR, SamplingStatus.NEVER_SAMPLED].includes(samplingStatus) ||
                        isSamplingStuck) &&
                      isSupported
                    );
                  },
                },
              ],
            } as BigidFlexibleListCardProps,
          };
        },
        type: BigidGridColumnTypes.FLEXIBLE_CARD,
      },
    ];

    return {
      fetchData,
      columns,
    };
  }, [opts, scanId, t, gridId, onProceedToAttributeList]);

  const fetchToolbarFilterInitialOptions = useCallback(async () => {
    const systemUsersOptions = await getSystemUsers();

    const userPreference = await getUserPreferences();
    const savedFiltersFromUserPreference = userPreference?.dataSourcesFilters;

    const searchBarFromUserPreference = getFilterFromUserPreference(
      savedFiltersFromUserPreference,
      DataSourcesFiltersNames.SEARCH,
    );
    const ownerFilterFromUserPreference = getFilterFromUserPreference(
      savedFiltersFromUserPreference,
      DataSourcesFiltersNames.OWNER,
    );

    let updatedSystemUsersOptions: BigidFilterOptionType[],
      updatedSystemUsersSelectedValues: string[] = [];

    if (ownerFilterFromUserPreference) {
      updatedSystemUsersOptions = getSelectedFilterOptions(systemUsersOptions, ownerFilterFromUserPreference);
      updatedSystemUsersSelectedValues = getFilterSelectedValues(updatedSystemUsersOptions);
    }

    setFilterToolbarConfig({
      filters: [
        {
          title: t('owner'),
          field: opts.ownerField,
          operator: 'in',
          value: updatedSystemUsersSelectedValues,
          disabled: !updatedSystemUsersSelectedValues.length,
          isSearchAsync: true,
          options: updatedSystemUsersOptions || systemUsersOptions,
          loadSearchOptions: getSystemUsers,
          isSelected: false,
          listWidth: 400,
        } as BigidFilterType,
        // Sampling status filtering is disabled and waiting for BE support.
        // {
        //   title: t('status'),
        //   field: 'status',
        //   operator: 'in',
        //   value: [],
        //   disabled: true,
        //   isSearchAsync: true,
        //   options: statusOptions.map(value => ({
        //     label: startCase(value.toLowerCase()),
        //     value: value,
        //     isSelected: false,
        //   })),
        // } as BigidFilterType,
      ],
      searchConfig: {
        searchFilterKeys: [opts.dsNameKey],
        initialValue: searchBarFromUserPreference?.values[0] ?? '',
        operator: opts.searchOperator,
      },
    });

    setIsReady(true);
  }, [t, opts.ownerField, opts.dsNameKey, opts.searchOperator]);

  useEffect(() => {
    fetchToolbarFilterInitialOptions();
  }, [fetchToolbarFilterInitialOptions]);

  const handleDefaultViewSwitcherSelect = (
    stageId: CurationStageId.CURATED_SOURCES | CurationStageId.CURATED_ATTRIBUTES,
  ): void => {
    onCurationDefaultInitialStageSelect(stageId);
  };

  return {
    isReady,
    gridConfig: {
      gridId,
      pageSize: 500,
      entityName: t('entityName'),
      showSortingControls: false,
      showFilteringControls: false,
      hideColumnChooser: true,
      cardListMode: true,
      filterToolbarConfig,
      columns,
      fetchData,
      defaultSorting: [
        { field: 'source', order: 'asc' },
        { field: 'curatedAttributes', order: 'asc' },
      ],
      foreignElement: (
        <CurationDefaultViewSwitcher
          stageId={CurationStageId.CURATED_SOURCES}
          onSelect={handleDefaultViewSwitcherSelect}
        />
      ),
    },
  };
};
