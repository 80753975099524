import { BigidFormField, BigidFormRenderProps } from '@bigid-ui/components';
import {
  DataSourceConnectionTemplateField,
  DataSourceTemplateCondition,
  DataSourceTemplateConditionObject,
  DataSourceTemplateConditionOperatorsEnum,
  DataSourceTemplateConditionWithOperator,
  DataSourceTemplateFieldConditionalType,
  DsTypesEnum,
} from '../types';
import { cloneDeep } from 'lodash';
interface CalculateNestedDisabled {
  name: string;
  misc: BigidFormField['misc'];
  getFieldProps: BigidFormRenderProps['getFieldProps'];
}

const isEqualFieldValues = (oneValue: any, value: any) => oneValue === (value ?? null);
const MAP_OPERATOR_TO_FUNC: Record<DataSourceTemplateConditionOperatorsEnum, (arr: any[], value: any) => boolean> = {
  [DataSourceTemplateConditionOperatorsEnum.or]: (arr, value) => arr.some(cond => isEqualFieldValues(cond, value)),
  [DataSourceTemplateConditionOperatorsEnum.and]: (arr, value) => arr.every(cond => isEqualFieldValues(cond, value)),
  [DataSourceTemplateConditionOperatorsEnum.not]: (arr, value) => !arr.some(cond => isEqualFieldValues(cond, value)),
};

const MAP_OPERATOR_TO_FIELDS_FUNC: Record<DataSourceTemplateConditionOperatorsEnum, (arr: any[], fn: any) => boolean> =
  {
    [DataSourceTemplateConditionOperatorsEnum.or]: (arr, fn) => arr.some(fn),
    [DataSourceTemplateConditionOperatorsEnum.and]: (arr, fn) => arr.every(fn),
    [DataSourceTemplateConditionOperatorsEnum.not]: (arr, fn) => !arr.some(fn),
  };

const fieldConditionValidator = (
  { field, value: valueForMatch }: DataSourceTemplateCondition,
  getFunction: (fieldName?: string) => { value: any; type?: DsTypesEnum },
) => {
  const { value, type } = getFunction(field);
  const extractedValue = Array.isArray(value) ? value[0]?.value : value;
  const { operator, values } = (valueForMatch as DataSourceTemplateConditionWithOperator) || {};
  if (operator in DataSourceTemplateConditionOperatorsEnum) {
    return MAP_OPERATOR_TO_FUNC[operator](values, extractedValue);
  }
  return extractedValue === (type === DsTypesEnum.options ? String(valueForMatch) : valueForMatch);
};

export const fieldConditionCheck = (
  trueByDefault: boolean,
  conditionsArray: DataSourceTemplateFieldConditionalType,
  getFunction: (fieldName?: string) => { value: any; type?: DsTypesEnum },
) =>
  (trueByDefault && !conditionsArray) ||
  (conditionsArray && conditionsArray.every(val => fieldConditionValidator(val, getFunction)));

export type CheckByConditionBaseType = ({
  misc,
  getFieldProps,
}: Pick<BigidFormField, 'misc'> & Pick<BigidFormRenderProps, 'getFieldProps'>) => boolean;

export const fieldConditionCheckObject = (
  trueByDefault: boolean,
  conditionObject: DataSourceTemplateConditionObject,
  getFunction: (fieldName?: string) => { value: any; type?: DsTypesEnum },
) => {
  if (trueByDefault && !conditionObject) {
    return true;
  }
  const { operator, fields } = conditionObject;
  return MAP_OPERATOR_TO_FIELDS_FUNC[operator](fields, (val: DataSourceTemplateCondition) =>
    fieldConditionValidator(val, getFunction),
  );
};

export const getOneFieldDataForCondition = (field: string, getFieldProps: BigidFormRenderProps['getFieldProps']) => {
  const {
    value,
    misc: { type },
  } = getFieldProps(field);
  return { value, type };
};

export const checkIsFieldVisible: CheckByConditionBaseType = ({
  misc: { hidden, visibleIf, visibleIfObject },
  getFieldProps,
}) => {
  if (visibleIf) {
    return fieldConditionCheck(!hidden, visibleIf, field => getOneFieldDataForCondition(field, getFieldProps));
  }
  if (visibleIfObject) {
    return fieldConditionCheckObject(!hidden, visibleIfObject, field =>
      getOneFieldDataForCondition(field, getFieldProps),
    );
  }
  return !hidden;
};

export const checkIsEnabled: CheckByConditionBaseType = ({ misc: { enabled, enabledIf }, getFieldProps }) =>
  fieldConditionCheck(enabled, enabledIf, field => getOneFieldDataForCondition(field, getFieldProps));

export const checkIsMandatoryForTest: CheckByConditionBaseType = ({
  misc: { mandatoryForTest, mandatoryForTestIf },
  getFieldProps,
}) =>
  fieldConditionCheck(mandatoryForTest, mandatoryForTestIf, field => getOneFieldDataForCondition(field, getFieldProps));

export function calculateNestedDisabled({ name, misc, getFieldProps }: CalculateNestedDisabled) {
  if (misc?.type !== DsTypesEnum.verticalObject || !misc?.objectFields?.length) {
    return [];
  }

  return misc.objectFields
    .map((item: DataSourceConnectionTemplateField) => {
      if (!item?.enabledIf) {
        return false;
      }
      const isEnabled = fieldConditionCheck(false, item?.enabledIf, fieldName => {
        try {
          return getFieldProps(fieldName);
        } catch (e) {
          return {
            value: getFieldProps(name)?.value?.[fieldName],
            type: misc.type,
          };
        }
      });
      return !isEnabled && item.name;
    })
    .filter((item: string) => item);
}

const getFieldsFromCondition = (conditionalFields: DataSourceTemplateFieldConditionalType) =>
  conditionalFields?.map(condition => condition.field).filter(Boolean) || [];

const getListOfVisibleByForField = (field: BigidFormField): string[] => [
  ...getFieldsFromCondition(field?.misc?.visibleIf),
  ...getFieldsFromCondition(field?.misc?.visibleIfObject?.fields),
];

export const markChildFieldsOverride = (fields: BigidFormField[]): BigidFormField[] => {
  return (
    fields?.map(currentField => {
      const copyField = cloneDeep(currentField);
      if (getListOfVisibleByForField(currentField).length > 0) {
        copyField.misc.isChildField = true;
      }
      return copyField;
    }) ?? []
  );
};

export const mapFieldNameToVisibilityDependencies = (fields: BigidFormField[]): Record<string, string[]> =>
  fields?.reduce<Record<string, string[]>>((visibilityMap, currentField) => {
    getListOfVisibleByForField(currentField).forEach(parentField => {
      visibilityMap[parentField] = visibilityMap[parentField] ?? [];
      visibilityMap[parentField].push(currentField.name);
    });
    return visibilityMap;
  }, {}) ?? {};
