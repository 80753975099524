import './users.scss';
import template from './users.component.html';
import { module } from 'angular';
import { $state, $stateParams, $translate } from '../react/services/angularServices';
import { mapService } from '../common/services/mapService';
import { pageHeaderService } from '../common/services/pageHeaderService';
import { setAmMapData } from '../common/amMap/ammap.service';

const app = module('app');

const MAP_CONFIG_ID = 'entityDetailsMap';

app.component('users', {
  template,
  controllerAs: 'usersModel',
  controller: function (privacyRiskMapService, queryStringService) {
    'ngInject';

    const usersModel = this;

    usersModel.id = decodeURIComponent($stateParams.id);
    usersModel.download = decodeURIComponent($stateParams.download);
    usersModel.style = "{'height': '490px','width': '98.5%'}";
    usersModel.mapContainerStyle = "{'height': '490px'}";

    const DEFAULT_MAP_ZOOM_LEVEL = 1;

    const mapSettings = mapService.getConfig(MAP_CONFIG_ID) || {};

    usersModel.initialMapZoomLevel = mapSettings.zoomLevel ? mapSettings.zoomLevel : DEFAULT_MAP_ZOOM_LEVEL;

    usersModel.initialMapZoomLatitude = mapSettings.zoomLatitude;

    usersModel.initialMapZoomLongitude = mapSettings.zoomLongitude;

    usersModel.$onInit = () => {
      const TRANSLATION_REQUIRED = ['ENTITY_DETAILS', 'INVENTORY'];
      $translate(TRANSLATION_REQUIRED).then(translations => {
        const pageTitle = translations['ENTITY_DETAILS'];
        const breadcrumb = translations['INVENTORY'];
        pageHeaderService.setTitle({
          showBackButton: true,
          breadcrumbs: [{ label: breadcrumb, onClick: () => $state.go('exploration') }, { label: pageTitle }],
        });
      });
    };

    const queryString = queryStringService.getQueryStringFilter(null, [usersModel.id]);
    privacyRiskMapService.getPrivacyRiskMapResults(queryString, true).then(result => setAmMapData(usersModel, result));

    usersModel.backToInventory = () => {
      const params = {};
      if ($stateParams.filter) {
        params.filter = $stateParams.filter;
      } else if ($stateParams.sideFilterValue) {
        params.sideFilterValue = $stateParams.sideFilterValue;
      }
      $state.go('exploration', params);
    };

    usersModel.onMapZoomChanged = data => {
      mapService.setConfig(MAP_CONFIG_ID, data);
    };
  },
});
