import React, { FC, Dispatch, SetStateAction, useEffect, useState, useCallback } from 'react';
import styled from '@emotion/styled';
import {
  BigidButtonIcon,
  BigidColorsV2,
  BigidToolbarAction,
  BigidTooltip,
  ToolbarAction,
  ToolbarActionType,
} from '@bigid-ui/components';
import { BigidUploadIcon } from '@bigid-ui/icons';
import {
  BigidContentContainer,
  BigidFilterContainer,
  BigidHotspotsChartFieldSettings,
  LayoutContentType,
} from '@bigid-ui/layout';
import { useLocalTranslation } from './translations';
import { generateDataAid } from '@bigid-ui/utils';
import { getAttributesListAsCSV } from './attributesLayoutService';
import { notificationService } from '../../../../services/notificationService';
import { isUndefined } from 'lodash';

const Root = styled('div')`
  display: flex;
  gap: 8px;
  margin-right: 8px;
  padding-right: 8px;
  align-items: center;
  border-right: 1px solid ${BigidColorsV2.gray[400]};
  height: 30px;
`;

const ActionsWrapper = styled('div')`
  display: flex;
  justify-content: flex-end;
  margin: 0 12px;
`;

const ExportIcon = styled('div')`
  display: flex;
`;

type BigidContentContainerWithCustomViewProp = Omit<
  ReturnType<typeof BigidContentContainer.useContainer>,
  'customViewProp' | 'setCustomViewProp'
> & {
  customViewProp: BigidHotspotsChartFieldSettings;
  setCustomViewProp: Dispatch<SetStateAction<BigidHotspotsChartFieldSettings>>;
};

interface AttributesHotspotToolbarProps {
  dataAid?: string;
}

export const pageSizeAvailableOptions = [12, 24, 36];

export const AttributesHotspotToolbar: FC<AttributesHotspotToolbarProps> = ({
  dataAid = 'AttributesHotspotToolbar',
}) => {
  const { t } = useLocalTranslation('toolbar');
  const {
    contentView: { currentView },
    setCustomViewProp,
  } = BigidContentContainer.useContainer() as BigidContentContainerWithCustomViewProp;
  const { searchQuery } = BigidFilterContainer.useContainer();
  const [memoisedQuery, setMemoisedQuery] = useState<string>();
  const actions: ToolbarAction[] = [
    {
      label: t('actions.view'),
      type: ToolbarActionType.DROPDOWN,
      show: () => true,
      dropdownProps: {
        placeholder: t('actions.view'),
        options: pageSizeAvailableOptions.map(option => ({
          label: String(option),
          value: option,
          show: () => true,
          execute: async () => {
            setCustomViewProp(prevState => ({
              ...prevState,
              hotspotsChartViewSize: option,
            }));
            return {};
          },
        })),
      },
    },
  ];

  const handleExportIconClick = useCallback(async () => {
    try {
      notificationService.info(t('notifications.export.info'));
      await getAttributesListAsCSV(memoisedQuery);
    } catch ({ message }) {
      notificationService.error(t('notifications.export.error'));
      console.error(`An error has occurred: ${message}`);
    }
  }, [memoisedQuery, t]);

  useEffect(() => {
    setMemoisedQuery(searchQuery);
  }, [searchQuery]);

  return (
    <Root>
      {currentView === LayoutContentType.HOTSPOTS_CHART && (
        <ActionsWrapper>
          {actions.map((action, i) => (
            <BigidToolbarAction key={i} {...action} />
          ))}
        </ActionsWrapper>
      )}
      <BigidTooltip title={t('actions.export')} placement="top">
        <ExportIcon>
          <BigidButtonIcon
            icon={BigidUploadIcon}
            dataAid={generateDataAid(dataAid, ['export'])}
            onClick={handleExportIconClick}
            disabled={isUndefined(memoisedQuery)}
          />
        </ExportIcon>
      </BigidTooltip>
    </Root>
  );
};
