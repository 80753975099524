import React, { useState } from 'react';
import { httpService } from '../../../../services/httpService';
import { createDsManualOnFmsd, redirectToUrlFromFmsd } from '../../fmsdServices';

export type OAuthConnectionType = 'salesforce';

export const useOAuthFlowConnection = () => {
  const [oAuthIsLoading, setOAuthIsLoading] = useState<boolean>(false);

  const oAuthConnectionFlow = async (oAuthConnectionType: OAuthConnectionType) => {
    setOAuthIsLoading(true);
    const dsConnectionName = `data-discovery-wizard-${oAuthConnectionType}-connection`;
    const destinationUrl =
      'oauth/' +
      oAuthConnectionType +
      '/' +
      dsConnectionName +
      '?callbackBaseUrl=' +
      window.location.origin +
      '&flowType=fmsd';

    createDsManualOnFmsd(dsConnectionName, oAuthConnectionType);
    const loaderStatus = await redirectToUrlFromFmsd(destinationUrl);
    setOAuthIsLoading(loaderStatus);
  };

  return { oAuthIsLoading, oAuthConnectionFlow };
};
