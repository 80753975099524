import { BigidBody2, BigidTooltip } from '@bigid-ui/components';
import { DataSourceIconByDsType } from '@bigid-ui/icons';
import { styled } from '@mui/material';
import React, { FC } from 'react';
import { CatalogGridCardBaseData, CatalogSearchGridRow } from '../../../types';
import { getHighlightedValue } from '../../../utils';

const Container = styled('div')`
  display: flex;
  align-items: center;
  gap: 8px;
  width: 100%;
  justify-content: flex-end;
`;

const StyledName = styled(BigidBody2)`
  max-width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const DsNameContainer = styled('div')`
  display: flex;
  align-items: center;
`;

type DataSourceNameProps = Pick<CatalogGridCardBaseData, 'datasource'>;

export const DataSourceName: FC<DataSourceNameProps> = ({ datasource }) => {
  const { value = '', scannerType = '' } = datasource || {};
  const highlightedDsName = getHighlightedValue(value);

  return (
    <Container>
      <BigidTooltip title={scannerType}>
        <DsNameContainer>
          <DataSourceIconByDsType height={22} dataSourceType={scannerType} />
        </DsNameContainer>
      </BigidTooltip>
      <BigidTooltip title={highlightedDsName}>
        <StyledName>{highlightedDsName}</StyledName>
      </BigidTooltip>
    </Container>
  );
};
