import React, { ChangeEvent, FC, ReactText, useEffect, useRef, useState } from 'react';
import { BigidGridRow } from '@bigid-ui/grid';
import { BigidLoader, BigidSwitch, EntityEvents, entityEventsEmitter } from '@bigid-ui/components';
import angular from 'angular';
import { convertToAngular } from '../../../../common/services/convertToAngular';
import { ServicesConfigurationGrid } from './ServicesConfigurationGrid';
import { editServiceConfiguration, getServicesConfiguration } from './servicesConfigurationService';
import { ServicesConfigurationDialog } from './ServicesConfigurationDialog';

export type ServiceConfigurationValue = boolean | ReactText;

export interface ServiceConfigurationsModel extends BigidGridRow {
  isEditable: boolean;
  isRestartRequired: boolean;
  markAsSensitiveKey: boolean;
  name: string;
  service: string;
  isGrouped?: boolean;
  type: string;
  value: any;
  hasConflicts?: boolean;
  services?: string[];
}

interface ServicesConfiguration {
  onServiceVariableValueChanged: (variable: ReactText, value: ServiceConfigurationValue) => void;
}

const ServicesConfiguration: FC<ServicesConfiguration> = ({ onServiceVariableValueChanged }) => {
  const [isGrouped, setIsGrouped] = useState(true);
  const [servicesData, setServicesData] = useState<ServiceConfigurationsModel[]>();
  const [editedConfiguration, setEditedConfiguration] = useState<ServiceConfigurationsModel>();

  useEffect(() => {
    getServicesConfiguration().then(results => {
      setServicesData(results);
    });
  }, []);

  function handleEditCancelled() {
    setEditedConfiguration(null);
  }

  async function handleSubmit(configurationValue: ServiceConfigurationValue) {
    await editServiceConfiguration(editedConfiguration, configurationValue);
    entityEventsEmitter.emit(EntityEvents.UPDATE_BY_ID, editedConfiguration.id, { value: configurationValue });

    const updatedResults = await getServicesConfiguration();
    setServicesData(updatedResults);
    setEditedConfiguration(null);

    onServiceVariableValueChanged(editedConfiguration.id, configurationValue);
  }

  function handleGroupingChanged(isGrouped: boolean) {
    setIsGrouped(isGrouped);
    entityEventsEmitter.emit(EntityEvents.RELOAD);
  }

  return (
    <>
      <BigidSwitch
        rightLabel={'Grouped View'}
        dataAid={`services-configurations-switch`}
        checked={isGrouped}
        onChange={(event: ChangeEvent<HTMLInputElement>, checked: boolean) => handleGroupingChanged(checked)}
      />
      {servicesData && (
        <ServicesConfigurationGrid
          rows={servicesData}
          onEditConfiguration={setEditedConfiguration}
          isGrouped={isGrouped}
        />
      )}
      {!servicesData && <BigidLoader label={'loading configurations...'} labelPosition={'bottom'} />}
      {editedConfiguration && (
        <ServicesConfigurationDialog
          onCancel={handleEditCancelled}
          onSubmit={handleSubmit}
          configuration={editedConfiguration}
        />
      )}
    </>
  );
};

angular
  .module('app')
  .component('servicesConfigurationReact', convertToAngular(ServicesConfiguration, ['onServiceVariableValueChanged']));
