import { useEffect, useMemo } from 'react';
import { ScanCreateData, SetScanWizardState } from '../createScanContext';
import { useSupportedDataSources } from '../../../DataSources/DataSourceConnections/hooks/useSupportedDataSources';
import {
  ALL_SCAN_TYPES_VALUES,
  ScanTypes,
  SCAN_TYPES_SUPPORTED_CLASSIFICATION,
  SCAN_TYPES_REQUIRED_CLASSIFICATION,
  SCAN_TYPES_SUPPORTED_CORRELATION,
  DISABLED_DS_SUPPORTED_TYPES,
} from '../../ScanTemplates/scanTemplateTypes';
import { intersection } from 'lodash';
import { DataSource } from '../../../../utilities/dataSourcesUtils';
import { DataSourceModel } from '../../../DataSources/DataSourceConnections/DataSourceConnectionTypes';

export type DisplayDsTypeToSupportedScansMap = Record<string, ScanTypes[]>;

export const addDataSourceNameToTypeMap = (acc: Record<string, string>, { name, type }: Partial<DataSourceModel>) => ({
  ...acc,
  [name]: type,
});

const addScanTypesToMap = (acc: DisplayDsTypeToSupportedScansMap, { type, supportedScanTypes }: DataSource) => ({
  ...acc,
  [type]: supportedScanTypes,
});

export const useGetDataSourceTypeToSupportedScansMap = (supportedDataSourcesMetaData?: DataSource[]) => {
  const { supportedDataSources } = useSupportedDataSources();
  return useMemo(
    () => (supportedDataSourcesMetaData ?? supportedDataSources)?.reduce(addScanTypesToMap, {}),
    [supportedDataSources, supportedDataSourcesMetaData],
  );
};

export const checkClassificationAndCorrelationByScanTypes = (scanTypes: ScanTypes[]) => {
  const result = {
    classificationSupported: false,
    classificationRequired: false,
    correlationSupported: false,
  };
  if (!scanTypes.length) {
    return result;
  }
  result.classificationSupported = scanTypes.every(type => SCAN_TYPES_SUPPORTED_CLASSIFICATION.includes(type));
  result.classificationRequired = scanTypes.every(type => SCAN_TYPES_REQUIRED_CLASSIFICATION.includes(type));
  result.correlationSupported = scanTypes.every(type => SCAN_TYPES_SUPPORTED_CORRELATION.includes(type));

  return result;
};

export const useUpdateSupportedScanTypesBySelectedDs = (
  setScanWizardState: SetScanWizardState,
  { dataSourceNameToTypeMap, dataSourcesIds, isDisabledDataSourcesSelected, allEnabledDs }: ScanCreateData,
) => {
  const displayDsTypeToSupportedScansMap = useGetDataSourceTypeToSupportedScansMap();

  useEffect(() => {
    if (displayDsTypeToSupportedScansMap) {
      const supportedScanTypes = getSupportedScanTypesByDsNames(
        { dataSourceNameToTypeMap, dataSourcesIds },
        displayDsTypeToSupportedScansMap,
        isDisabledDataSourcesSelected,
        allEnabledDs,
      );
      const { classificationSupported, classificationRequired, correlationSupported } =
        checkClassificationAndCorrelationByScanTypes(supportedScanTypes);

      setScanWizardState(state => ({
        ...state,
        supportedScanTypes,
        correlationSupported,
        classificationSupported,
        classificationRequired,
      }));
    }
  }, [
    allEnabledDs,
    dataSourceNameToTypeMap,
    dataSourcesIds,
    displayDsTypeToSupportedScansMap,
    isDisabledDataSourcesSelected,
    setScanWizardState,
  ]);
};

export function getSupportedScanTypesByDsNames(
  { dataSourcesIds, dataSourceNameToTypeMap }: Pick<ScanCreateData, 'dataSourcesIds' | 'dataSourceNameToTypeMap'>,
  displayDsTypeToSupportedScansMap: DisplayDsTypeToSupportedScansMap,
  isDisabledSelected: boolean,
  allEnabledDs: boolean,
) {
  if (allEnabledDs) {
    return ALL_SCAN_TYPES_VALUES;
  }
  if (!dataSourcesIds?.length) {
    return [];
  }
  return dataSourcesIds?.reduce(
    (accScanTypesSupported, dataSourceName) => {
      const dataSourceType = dataSourceNameToTypeMap[dataSourceName];
      return intersection(accScanTypesSupported, displayDsTypeToSupportedScansMap[dataSourceType]);
    },
    isDisabledSelected ? DISABLED_DS_SUPPORTED_TYPES : ALL_SCAN_TYPES_VALUES,
  );
}
