import React from 'react';
import styled from '@emotion/styled';
import { Theme } from '@mui/material';
import { BigidBody1 } from '@bigid-ui/components';

type AutoDiscoveryCollaborationFormProps = {
  isReInvite: boolean;
} & { theme: Theme };

export const AutoDiscoveryCollaborationForm = styled.div`
  ${({ isReInvite }: AutoDiscoveryCollaborationFormProps) =>
    `
border-bottom: none;
padding-bottom: 0;
min-height: 71px;
& form fieldset:nth-child(1) {
    width: 100%;
    max-width: ${isReInvite ? '76%' : '84%'};
    flex: 1 1;
  }

  & form fieldset:nth-child(2) {
    width: 30%;
  }
`}

  & form fieldset:nth-child(3) {
    width: 100%;
    height: 200px;
  }

  & form > div {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    column-gap: 0.5rem;
    row-gap: 1rem;
  }
`;

export const AutoDiscoveryCollaborationPlaceholder = styled(BigidBody1)`
  position: absolute;
  top: 56px;
  left: 28px;
`;
