import React, { FC, PropsWithChildren } from 'react';
import { styled } from '@mui/material';

export interface BarChartWidgetWrapperProps extends PropsWithChildren {
  dataAid?: string;
}

const Root = styled('div')`
  height: 100%;
  width: 100%;
  padding: 24px;
`;

export const BarChartWidgetWrapper: FC<BarChartWidgetWrapperProps> = ({
  dataAid = 'BarChartWidgetWrapper',
  children,
}) => {
  return <Root data-aid={dataAid}>{children}</Root>;
};
