import React, { ChangeEvent, FC, MouseEvent, MutableRefObject, useState } from 'react';
import { closeSystemDialog, openSystemDialog, SystemDialogContentProps } from '../../../services/systemDialogService';
import { CHECKSUM_VALIDATION_TYPE, ChecksumValidation, OOTBChecksumValidation } from './ChecksumValidationTypes';
import {
  BigidFormStateAndHandlers,
  BigidFormValues,
  BigidRadioGroup,
  PrimaryButton,
  SecondaryButton,
} from '@bigid-ui/components';
import { ChecksumValidationCustomForm, ChecksumValidationCustomFormProps } from './ChecksumValidationCustomForm';
import {
  convertInitialChecksumToFormFieldsValues,
  getInitialOutOfTheBoxChecksum,
  normalizeFormValues,
} from './ChecksumValidationUtils';
import {
  createClassifiersChecksumValidation,
  updateClassifiersChecksumValidation,
} from '../../../services/classifiersService';
import { notificationService } from '../../../services/notificationService';
import {
  ClassifierChecksumValidationOOTBDropdown,
  ClassifierChecksumValidationOOTBProps,
} from '../ClassifierChecksumValidationOOTBDropdown/ClassifierChecksumValidationOOTBDropdown';
import styled from '@emotion/styled';
import { $translate } from '../../../services/angularServices';
import { generateDataAid } from '@bigid-ui/utils';
import { isEmpty } from 'lodash';

const Wrapper = styled('div')({
  paddingTop: '15px',
});

export interface ClassifierChecksumValidationDialogProps extends ClassifierChecksumValidationOOTBProps {
  initialChecksumType: string;
  initialCustomChecksumFormValues: BigidFormValues;
  initialOOTBChecksum: OOTBChecksumValidation[];
  handleChecksumTypeOnSelect: (checksumType: string) => void;
  dialogFormControls: MutableRefObject<BigidFormStateAndHandlers>;
}

export const ClassifierChecksumValidationDialog: FC<
  SystemDialogContentProps<ClassifierChecksumValidationDialogProps>
> = ({
  initialCustomChecksumFormValues,
  initialOOTBChecksum,
  ootbChecksumsNamesList,
  initialChecksumType,
  handleChecksumNameOnSelect,
  handleChecksumTypeOnSelect,
  dialogFormControls,
}) => {
  const [value, setValue] = useState(initialChecksumType);
  const formProps: ChecksumValidationCustomFormProps = {
    formControls: dialogFormControls,
    initialChecksumFormValues: initialCustomChecksumFormValues,
  };
  const dropDowmProps: ClassifierChecksumValidationOOTBProps = {
    handleChecksumNameOnSelect: handleChecksumNameOnSelect,
    ootbChecksumsNamesList: ootbChecksumsNamesList,
    initialOOTBChecksum: initialOOTBChecksum,
  };
  return (
    <div>
      <BigidRadioGroup
        defaultValue={initialChecksumType}
        onChange={(event: ChangeEvent<HTMLInputElement>) => {
          handleChecksumNameOnSelect(null);
          handleChecksumTypeOnSelect(event.target.value);
          setValue(event.target.value);
        }}
        options={[
          {
            label: 'None',
            value: 'none',
            dataAid: `${generateDataAid('checksumDialogRadio', ['none'])}`,
          },
          {
            label: 'BigID (Out of the box)',
            value: 'ootb',
            dataAid: `${generateDataAid('checksumDialogRadio', ['ootb'])}`,
          },
          {
            label: 'Custom',
            value: 'custom',
            dataAid: `${generateDataAid('checksumDialogRadio', ['custom'])}`,
          },
        ]}
      />
      <Wrapper>
        {value === 'ootb' && <ClassifierChecksumValidationOOTBDropdown {...dropDowmProps} />}
        {value === 'custom' && <ChecksumValidationCustomForm {...formProps} />}
      </Wrapper>
    </div>
  );
};
export const openClassifierChecksumValidationDialog = async (
  classificationName: string,
  checksumValidation: ChecksumValidation,
  ootbChecksumsNamesList: OOTBChecksumValidation[] = [],
  initialChecksumType: string,
) => {
  let selectedChecksumName: string = null;
  let selectedChecksumType: string = initialChecksumType;
  const dialogFormControls: MutableRefObject<BigidFormStateAndHandlers> = { current: null };

  const handleChecksumNameOnSelect = (checksumName: string) => {
    selectedChecksumName = checksumName;
  };
  const handleChecksumTypeOnSelect = (checksumType: string) => {
    selectedChecksumType = checksumType;
  };
  const initialCustomChecksumFormValues: BigidFormValues = convertInitialChecksumToFormFieldsValues(checksumValidation);
  const initialOOTBChecksum: OOTBChecksumValidation[] = getInitialOutOfTheBoxChecksum(
    initialChecksumType,
    checksumValidation,
    ootbChecksumsNamesList,
  );
  const initialChecksumIsNew = isEmpty(initialCustomChecksumFormValues?.validation);
  const contentProps: ClassifierChecksumValidationDialogProps = {
    initialChecksumType,
    initialCustomChecksumFormValues,
    initialOOTBChecksum,
    ootbChecksumsNamesList: ootbChecksumsNamesList,
    handleChecksumNameOnSelect,
    handleChecksumTypeOnSelect,
    dialogFormControls,
  };

  return new Promise<{ checksumName: string; isCancelOccurred: boolean }>(resolve => {
    openSystemDialog({
      title: `Checksum Validation For Classifier: ${classificationName}`,
      maxWidth: 'sm',
      content: ClassifierChecksumValidationDialog,
      contentProps,
      onClose: () => resolve({ checksumName: '', isCancelOccurred: true }),
      buttons: [
        {
          text: 'Cancel',
          component: SecondaryButton,
          dataAid: 'cancel-classifier-checksum-validation',
          onClick: () => resolve({ checksumName: '', isCancelOccurred: true }),
          isClose: true,
        },
        {
          text: 'Save',
          component: PrimaryButton,
          dataAid: 'save-classifier-checksum-validation',
          onClick: (event: MouseEvent) => {
            if (selectedChecksumType === CHECKSUM_VALIDATION_TYPE.CUSTOM) {
              dialogFormControls?.current?.validateAndSubmit(async () => {
                await dialogFormControls?.current?.submit(event);
                try {
                  const normalizeFormValuesResult = normalizeFormValues(dialogFormControls.current.getValues());
                  if (initialChecksumIsNew) {
                    await createClassifiersChecksumValidation(normalizeFormValuesResult);
                  } else {
                    await updateClassifiersChecksumValidation(normalizeFormValuesResult);
                  }
                  notificationService.success(
                    $translate.instant('CLASSIFIERS:CHECKSUM:VALIDATION:API:MESSAGE:PUT_SUCCESS'),
                  );
                  resolve({
                    checksumName: normalizeFormValuesResult.validation,
                    isCancelOccurred: false,
                  });
                  closeSystemDialog();
                } catch (error) {
                  let errorMsg = $translate.instant('API:MESSAGE:COMMON_ERROR');
                  if (error.response?.data?.message?.includes('E11000 duplicate key error')) {
                    errorMsg = $translate.instant('API:MESSAGE:COMMON_DUPLICATE_KEY_ERROR', {
                      name: 'checksum validation name',
                    });
                  }
                  console.error(error);
                  notificationService.error(errorMsg);
                }
              });
            } else {
              resolve({
                checksumName: selectedChecksumName,
                isCancelOccurred: false,
              });
              closeSystemDialog();
            }
          },
          isClose: false,
        },
      ],
    });
  });
};
