import { useEffect, useMemo, useState } from 'react';
import {
  BigidDropdownOption,
  BigidFormField,
  BigidFormFieldLabelPosition,
  BigidFormFieldTypes,
  BigidFormValues,
} from '@bigid-ui/components';
import { useLocalTranslation } from './translations';
import { getSystemUsers } from './definePolicyService';
import { getIsPolicyDataCatalogLinkable, getIsPolicyInventoryLinkable, getPolicyTypeFieldOptions } from './utils';

export interface UseDefinePolicyFormConfigProps {
  query: string;
  policyFormState: BigidFormValues;
}

export interface UseDefinePolicyFormConfigReturnData {
  fields: BigidFormField[];
  isInitialisingForm: boolean;
  systemUserOptions: BigidDropdownOption[];
}

export function useDefinePolicyFormConfig({
  query,
  policyFormState,
}: UseDefinePolicyFormConfigProps): UseDefinePolicyFormConfigReturnData {
  const { t } = useLocalTranslation('dialog.form.fields');

  const [isInitialisingForm, setIsInitialisingForm] = useState<boolean>(false);
  const [systemUserOptions, setSystemUserOptions] = useState<BigidDropdownOption[]>([]);

  useEffect(() => {
    async function initFormAssets() {
      setIsInitialisingForm(true);
      setSystemUserOptions(await getSystemUsers());
      setIsInitialisingForm(false);
    }

    initFormAssets();
  }, []);

  const { fields } = useMemo(() => {
    const includeLinkToCatalog: BigidFormField[] = getIsPolicyDataCatalogLinkable(policyFormState.type?.[0]?.value)
      ? [
          {
            name: 'includeLinkToCatalog',
            type: BigidFormFieldTypes.CHECKBOX,
            label: t('includeLinkToCatalog.label'),
            labelPosition: BigidFormFieldLabelPosition.left,
          },
        ]
      : [];
    const includeLinkToInventory: BigidFormField[] = getIsPolicyInventoryLinkable(policyFormState.type?.[0]?.value)
      ? [
          {
            name: 'includeLinkToInventory',
            type: BigidFormFieldTypes.CHECKBOX,
            label: t('includeLinkToInventory.label'),
            labelPosition: BigidFormFieldLabelPosition.left,
          },
        ]
      : [];

    const fields: BigidFormField[] = [
      {
        type: BigidFormFieldTypes.TEXT,
        labelPosition: BigidFormFieldLabelPosition.top,
        name: 'name',
        label: t('policyName.label'),
        misc: {
          fullWidth: true,
        },
        isRequired: true,
        validate: (value: string) => {
          if (value?.trim().length > 0) {
            const regex = new RegExp(/^[A-Za-z0-9\s]*$/);

            if (regex.test(value.trim())) {
              return false;
            } else {
              return t('policyName.validation.alphanumeric');
            }
          } else {
            return t('policyName.validation.empty');
          }
        },
      },
      {
        type: BigidFormFieldTypes.DROP_DOWN,
        labelPosition: BigidFormFieldLabelPosition.top,
        name: 'type',
        label: t('policyType.label'),
        tooltipText: t('policyType.tooltip'),
        dropDownOptions: getPolicyTypeFieldOptions(),
        misc: {
          fullWidth: true,
        },
        isRequired: true,
        validate: (value: BigidDropdownOption[]) => {
          if (value?.length > 0) {
            return false;
          } else {
            return t('policyType.validation.empty');
          }
        },
      },
      {
        type: BigidFormFieldTypes.TEXTAREA,
        labelPosition: BigidFormFieldLabelPosition.top,
        name: 'description',
        label: t('description.label'),
        misc: {
          fullWidth: true,
          rows: 3,
        },
        fieldProps: {
          multiline: true,
        },
        isRequired: true,
        validate: (value: string) => {
          if (value?.trim().length > 0) {
            return false;
          } else {
            return t('description.validation.empty');
          }
        },
      },
      {
        type: BigidFormFieldTypes.DROP_DOWN,
        labelPosition: BigidFormFieldLabelPosition.top,
        name: 'owner',
        label: t('owner.label'),
        dropDownOptions: systemUserOptions,
        misc: {
          fullWidth: true,
        },
        fieldProps: {
          isSearchable: true,
        },
        isRequired: true,
        validate: (value: BigidDropdownOption[]) => {
          if (value?.length > 0) {
            return false;
          } else {
            return t('owner.validation.empty');
          }
        },
      },
      {
        type: BigidFormFieldTypes.TEXTAREA,
        labelPosition: BigidFormFieldLabelPosition.top,
        name: 'bigidQuery',
        label: t('triggeringQuery.label'),
        misc: {
          fullWidth: true,
          rows: 3,
        },
        fieldProps: {
          multiline: true,
          disabled: Boolean(query),
        },
        isRequired: true,
        validate: (value: string) => {
          if (value?.trim().length > 0) {
            return false;
          } else {
            return t('triggeringQuery.validation.empty');
          }
        },
      },
      {
        type: BigidFormFieldTypes.TEXT,
        labelPosition: BigidFormFieldLabelPosition.top,
        name: 'maxFindings',
        label: t('triggeringThreshold.label'),
        misc: {
          fullWidth: true,
        },
        isRequired: true,
        validate: (value: string) => {
          if (value?.trim().length > 0) {
            const regex = new RegExp(/^\d+$/);

            if (regex.test(value.trim())) {
              return false;
            } else {
              return t('triggeringThreshold.validation.numeric');
            }
          } else {
            return t('triggeringThreshold.validation.empty');
          }
        },
      },
      ...includeLinkToInventory,
      ...includeLinkToCatalog,
      {
        name: 'includeObjectsReport',
        type: BigidFormFieldTypes.CHECKBOX,
        label: t('includeObjectsReport.label'),
        labelPosition: BigidFormFieldLabelPosition.left,
      },
    ];

    return { fields };
  }, [policyFormState, query, systemUserOptions, t]);

  return { fields, systemUserOptions, isInitialisingForm };
}
