import React, { FC, useEffect, useState } from 'react';
import * as scanService from '../ScanService';
import { ConvertScanProfileOverview } from './ConvertScanProfileOverview/ConvertScanProfileOverview';
import { ScanProfileFields } from '../ScanProfileConverter/scanProfileConversionTypes';
import { ConvertScanProfileContext } from './ConvertScanProfileOverview/convertScanProfileContext';
import { ConvertScanProfileProps } from './ConvertScanProfileTypes';

export const ConvertScanProfile: FC<ConvertScanProfileProps> = ({ scanProfileId, isForReview = false, scanType }) => {
  const [scanProfileData, setScanProfileData] = useState<ScanProfileFields>();

  useEffect(() => {
    getScanProfileById(scanProfileId);
  }, [scanProfileId]);

  const getScanProfileById = async (scanProfileId: string) => {
    const data = await scanService.getScanProfileById(scanProfileId);
    setScanProfileData(data?.scanProfile);
  };

  return (
    <ConvertScanProfileContext.Provider value={{ scanProfileData, isForReview, scanType }}>
      {scanProfileData && <ConvertScanProfileOverview />}
    </ConvertScanProfileContext.Provider>
  );
};
