import React, { FunctionComponent, ReactText, useCallback, useEffect, useState } from 'react';
import {
  BigidScheduleEventPicker,
  BigidScheduleEventPickerProps,
  ScheduledIdentifierOptions,
  BigidColors,
  BigidBody2,
  BigidSchedulerOnChangePayload,
} from '@bigid-ui/components';
import makeStyles from '@mui/styles/makeStyles';

export type OnChangeResponse = {
  id: ReactText;
  cronExpressionStart: string;
  isValid: boolean;
  cronExpressionEnd?: string;
  allDay?: boolean;
};

type WrapperInterface = Omit<BigidScheduleEventPickerProps, 'onChange'> & {
  intervalId?: ReactText;
  onChange: (response: OnChangeResponse) => void;
  isAllDay: boolean;
};

const DEFAULT_START = '0 8 * * 2';
const DEFAULT_END = '0 22 * * 2';

export const useStyles = makeStyles({
  errorRoot: {
    marginBottom: '12px',
  },
  errorMsg: {
    position: 'absolute',
    color: BigidColors.failureRed,
    fontSize: '12px',
  },
  root: {
    paddingBottom: '10px',
    width: '500px',
  },
});

export const ScanWindowFieldFill: FunctionComponent<WrapperInterface> = ({
  onChange,
  intervalId,
  startTime,
  endTime,
  isAllDay = false,
  dataAid,
}) => {
  const [errorMsg, setErrorMsg] = useState<string>('');
  const [valid, setValidation] = useState<boolean>(false);
  const classes = useStyles({});

  const handleOnChange = useCallback(
    ({ cronExpressionStart, isValid, cronExpressionEnd, allDay }: BigidSchedulerOnChangePayload) => {
      setValidation(isValid);
      onChange?.({ id: intervalId, cronExpressionStart, isValid, cronExpressionEnd, allDay });
    },
    [intervalId, onChange],
  );

  const handleOnError = useCallback((message: string) => {
    setErrorMsg(message);
  }, []);

  useEffect(() => {
    setErrorMsg(valid ? '' : errorMsg);
  }, [endTime, errorMsg, startTime, valid]);

  return (
    <div className={classes.root}>
      <BigidScheduleEventPicker
        includedIdentifiers={[ScheduledIdentifierOptions.WEEKLY]}
        startTime={startTime ? startTime : DEFAULT_START}
        endTime={endTime ? endTime : DEFAULT_END}
        onChange={handleOnChange}
        range={true}
        onError={handleOnError}
        allDay={isAllDay}
        selectProps={{ menuPosition: 'absolute', isSearchable: true }}
        dataAid={dataAid}
      />
      <div className={classes.errorRoot}>
        <BigidBody2 className={classes.errorMsg}>{errorMsg}</BigidBody2>
      </div>
    </div>
  );
};
