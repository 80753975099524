import React, { FunctionComponent, useEffect, useMemo } from 'react';
import {
  BigidBody1,
  BigidColorsV2,
  BigidHeading5,
  BigidPaper,
  BigidTabsAndContent,
  BigidTabsAndContentProps,
} from '@bigid-ui/components';
import { IconButton, Paper } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { $state } from '../../services/angularServices';
import { pageHeaderService } from '../../../common/services/pageHeaderService';
import { UsersPermissionsWrapper } from './UsersPermissions/UsersPermissionsWrapper';
import { DataManager } from './DataManager/DataManager';
import { GroupsWrapper } from './Groups/Groups';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import { CONFIG } from '../../../config/common';
import { ACIDashboardWrapper } from './Dashboard/ACIDashboardWrapper';
import { getApplicationPreference } from '../../services/appPreferencesService';
import { useSelectedTabIndex } from './useSelectedTabIndex';
import { AccessIntelligenceDashboard } from './Dashboard/AccessIntelligenceDashboard';
import { isPermitted } from '../../services/userPermissionsService';
import { APPLICATIONS_PERMISSIONS } from '@bigid/permissions';
import { BigidDisconnectedIllustration } from '@bigid-ui/icons';

const useStyles = makeStyles(() => ({
  paper: {
    height: 'calc(100% - 4px)',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    //NOTE: backgroundColor is temporal solution according to Alex tim request, this wrap an old angular.js component
    backgroundColor: 'transparent',
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    width: '100%',
  },
  contentContainer: {
    display: 'flex',
    overflow: 'hidden',
    borderRadius: '4px 4px 0px 0px',
    flexFlow: 'nowrap',
    flex: '1 1 auto',
    padding: '6px',
  },
  placeholderPaper: {
    height: 'calc(100% - 64px)',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    rowGap: '20px',
  },
  placeholderHeader: {
    color: BigidColorsV2.purple[400],
  },
}));

const AppNotAvailablePlaceholder: FunctionComponent = () => {
  const classes = useStyles();
  return (
    <BigidPaper classes={{ root: classes.placeholderPaper }}>
      <BigidDisconnectedIllustration />
      <BigidHeading5 className={classes.placeholderHeader}>
        Access Intelligence is disabled in your system
      </BigidHeading5>
      <BigidBody1>
        To enable it, verify that Access Intelligence is included in your license, <br />
        and that the Access Intelligence service is installed and running.
      </BigidBody1>
    </BigidPaper>
  );
};

export const AccessIntelligence: FunctionComponent = () => {
  const classes = useStyles({});

  // The aci app is currently not enabled for MT
  const isACIEnabled: boolean = getApplicationPreference('ACI_ENABLED');
  const isAciDashboardUiV2Enabled: boolean = getApplicationPreference('ACI_DASHBOARD_UI_V2_ENABLED');
  const areGroupsEnabled: boolean = getApplicationPreference('ACI_GROUPS_ENABLED');
  const ACI_TABS = useMemo(
    () => ['dashboard', ...(areGroupsEnabled ? ['groups'] : []), 'users', 'dataManager'],
    [areGroupsEnabled],
  );

  const { selectedTabIndex, setSelectedTabIndex } = useSelectedTabIndex(ACI_TABS);

  useEffect(() => {
    if (isPermitted(APPLICATIONS_PERMISSIONS.READ_ACCESS_TYPES_IN_ACCESS_INTELLIGENCE.name)) {
      pageHeaderService.setTitle({
        pageTitle: 'Access Intelligence',
        rightSideComponentsContainer: (
          <IconButton
            data-aid={'access-intelligence-configuration'}
            title={'Settings'}
            onClick={() => $state.go(CONFIG.states.ACCESS_INTELLIGENCE_SETTINGS)}
            size="large"
            sx={{ height: 'auto' }}
          >
            <SettingsOutlinedIcon />
          </IconButton>
        ),
      });
    }
  }, []);

  const tabsAndContentConfig: BigidTabsAndContentProps = {
    classes: {
      contentContainer: classes.contentContainer,
    },
    tabProps: {
      tabs: [
        {
          label: 'Dashboard',
          data: { component: isAciDashboardUiV2Enabled ? ACIDashboardWrapper : AccessIntelligenceDashboard },
        },
        {
          label: 'Groups',
          data: { component: GroupsWrapper },
          getIsAvailable: () => areGroupsEnabled,
        },
        {
          label: 'Users View',
          data: { component: UsersPermissionsWrapper },
        },
        {
          label: 'Data View',
          data: { component: DataManager },
        },
      ],
      onChange: tabIndex => {
        $state.go(
          CONFIG.states.ACCESS_GOVERNANCE,
          { selectedTab: ACI_TABS[tabIndex] },
          { reload: false, notify: false, inherit: true },
        );
        setSelectedTabIndex(tabIndex);
      },
      selectedIndex: selectedTabIndex,
    },
  };

  if (!isACIEnabled) {
    return <AppNotAvailablePlaceholder />;
  }

  return (
    <Paper elevation={0} className={classes.paper}>
      <div className={classes.content}>
        <BigidTabsAndContent {...tabsAndContentConfig} />
      </div>
    </Paper>
  );
};
