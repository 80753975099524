import { FC } from 'react';
import {
  DataSourceConnectionTemplateField,
  DataSourceOption,
  DataSourceTemplateCondition,
  DataSourceTemplateFieldConditionalType,
  DataSourceTemplateFieldValidationInterface,
  DsConnectionFieldSectionsEnum,
  DsTypesEnum,
} from './types';
import { BigidFormField, BigidFormFieldLabelPosition, FormValue } from '@bigid-ui/components';
import { MapTypeValueNormalize } from './utils/prepareDataSourceFormValueDataForSend';
import { SOURCE_TO_COMPONENT_MAP, TYPE_TO_COMPONENT_MAP } from './constants';
import { getOptionsAsync } from './utils/getOptionsAsync';
import { BULK_UPDATE_FIELD_COMPONENT_MAP } from './DataSourceBulkUpdate/BulkUpdateFormFieldMapping';

export type DataSourceConnectionTemplateOverrides = {
  mapping?: {
    source?: Record<string, FC<any>>;
    type?: Partial<Record<DsTypesEnum, FC<any>>>;
  };
};

const TYPE_TO_CONVERT_PROPS: Record<string, FormValue> = {
  [DsTypesEnum.stringLong]: async (misc: BigidFormField['misc']) => ({
    misc: {
      ...misc,
      multiline: true,
      rows: 3, //TODO get from template
    },
  }),
  [DsTypesEnum.json]: async (misc: BigidFormField['misc']) => ({
    misc: {
      ...misc,
      multiline: true,
      rows: 5, //TODO get from template
    },
  }),
  [DsTypesEnum.options]: async (_misc: BigidFormField['misc'], options: any) => ({
    options: options?.map((option: any) => ({
      ...option,
      value: String(option?.value),
    })),
  }),
  [DsTypesEnum.password]: async (misc: BigidFormField['misc']) => ({
    misc: {
      ...misc,
      showValueOnlyIfDirty: true,
    },
  }),
  [DsTypesEnum.number]: async (misc: BigidFormField['misc']) => ({
    misc: {
      ...misc,
      min: misc?.validation?.find(({ min }: DataSourceTemplateFieldValidationInterface) => min)?.min,
      max: misc?.validation?.find(({ max }: DataSourceTemplateFieldValidationInterface) => max)?.max,
    },
  }),
  [DsTypesEnum.stringSelect]: async (
    misc: BigidFormField['misc'],
    _options?: DataSourceOption[],
    section?: DsConnectionFieldSectionsEnum,
  ) => {
    const extraProps: Record<string, any> = {
      misc: {
        ...misc,
        ...(section === DsConnectionFieldSectionsEnum.connection ||
        section === DsConnectionFieldSectionsEnum.connectionAdvanced // TODO Tom Matsliah, Maciej Kaminski - Here a render was made in the body so that dropdowns would always fit in, but in the modal it didn’t work for some fields due to z-index, so I turned it off for the connection sessions. We need to find another solution
          ? {}
          : {
              menuPortalTarget: document.body,
            }),
      },
    };
    if (misc?.asyncSource?.enabled) {
      const { url, fieldsMapping, filter } = misc?.asyncSource;
      extraProps.options = await getOptionsAsync(url, fieldsMapping, filter);
    }
    return extraProps;
  },
  [DsTypesEnum.boolean]: async (misc: BigidFormField['misc']) => {
    misc.defaultValue = Boolean(misc.defaultValue);
  },
};

const validateRequired = (mandatory: boolean, value: any) => {
  if (mandatory && (value === undefined || value === '')) return 'Please fill mandatory field';
  return false;
};

const validateIsNotBlocked = (
  fieldsConfig: DataSourceConnectionTemplateField[],
  values: Record<string, any>,
  blockSaveIf?: DataSourceTemplateFieldConditionalType,
) =>
  blockSaveIf &&
  (blockSaveIf as DataSourceTemplateCondition[]).every(({ field, value }) => {
    const valueNormalized = MapTypeValueNormalize[
      fieldsConfig.find(({ name }) => name === field)?.type as DsTypesEnum
    ]?.(values[field]);
    return valueNormalized === value;
  })
    ? ['Please fill field']
    : [];

const validateByRules = (value: any, validationRules?: DataSourceTemplateFieldValidationInterface[]) =>
  value !== undefined && validationRules
    ? validationRules.reduce((errorsAcc, { regex, errorText, min, max, minLength, maxLength }) => {
        const isFailed =
          (regex && value && !RegExp(decodeURIComponent(regex)).test(value)) ||
          (min && value !== '' && value !== null && value < min) ||
          (max && value !== '' && value !== null && value > max) ||
          (minLength && value?.length < minLength) ||
          (maxLength && value?.length > maxLength);
        return isFailed ? [...errorsAcc, errorText] : errorsAcc;
      }, [])
    : [];

const validateDataSourceField = (
  mandatory: boolean,
  value: any,
  fieldsConfig: DataSourceConnectionTemplateField[],
  values: Record<string, any>,
  validationRules?: DataSourceTemplateFieldValidationInterface[],
  blockSaveIf?: DataSourceTemplateFieldConditionalType,
  objectFields?: DataSourceConnectionTemplateField[],
) => {
  const reqError = validateRequired(mandatory, value);
  const nestedErrors = objectFields?.reduce<null | Record<string, string[]>>((acc, { name, validation }) => {
    const error = validateByRules(value?.[name], validation);
    return error?.length ? { ...(acc || {}), [name]: error } : acc;
  }, null);
  const errors = reqError
    ? [reqError]
    : [...validateByRules(value, validationRules), ...validateIsNotBlocked(fieldsConfig, values, blockSaveIf)];
  return errors.length ? errors.join('; ') : nestedErrors || false;
};

export const generateOneField = async (
  { name, displayName, type, options = [], tooltipText, ...restProps }: DataSourceConnectionTemplateField,
  isBulkUpdate?: boolean,
  _index?: number,
  fieldsConfig?: DataSourceConnectionTemplateField[],
  overrides?: DataSourceConnectionTemplateOverrides,
) => {
  const misc = { ...restProps, type, fullWidth: !restProps?.inline };
  const { mapping } = overrides ?? {};
  return {
    validate: (value: any, values: Record<string, any>) =>
      validateDataSourceField(
        restProps?.mandatory,
        value,
        fieldsConfig,
        values,
        restProps?.validation,
        restProps?.blockSaveIf,
        misc?.objectFields,
      ),
    name,
    options,
    isRequired: restProps?.mandatory || restProps?.mandatoryForTest,
    label: displayName,
    tooltipText,
    misc,
    fullWidth: true,
    labelPosition: isBulkUpdate ? BigidFormFieldLabelPosition.top : BigidFormFieldLabelPosition.left,
    render: isBulkUpdate
      ? { ...SOURCE_TO_COMPONENT_MAP, ...mapping?.source }[restProps?.source] ||
        { ...BULK_UPDATE_FIELD_COMPONENT_MAP, ...mapping?.type }[type]
      : { ...SOURCE_TO_COMPONENT_MAP, ...mapping?.source }[restProps?.source] ||
        { ...TYPE_TO_COMPONENT_MAP, ...mapping?.type }[type],
    ...((await TYPE_TO_CONVERT_PROPS?.[type]?.(misc, options, restProps?.section)) || {}),
  };
};

const generateNestedFields = (
  fieldsConfig: DataSourceConnectionTemplateField[],
  isBulkUpdate: boolean,
  overrides?: DataSourceConnectionTemplateOverrides,
) => {
  const result = fieldsConfig.reduce<any>(async (accPromise, field) => {
    const nested: BigidFormField[] = await Promise.resolve(
      field?.objectFields
        ? generateFields(
            field.objectFields.map(field => ({ ...field, section: field.section })),
            isBulkUpdate,
            false,
          )
        : [],
    );
    const fields = nested.map(({ misc, ...rest }) => ({
      ...rest,
      name: `${field.name}.${rest.name}`,
      misc: { ...misc, nested: true, nestedParentName: field.name },
    }));
    const acc = await accPromise;

    return [
      ...acc,
      ...(field.type === DsTypesEnum.verticalObject
        ? [...fields, ...(await generateFields([field], isBulkUpdate, false, overrides))]
        : [...(await generateFields([field], isBulkUpdate, false, overrides))]),
    ];
  }, Promise.resolve([]) as Promise<BigidFormField[]>);
  return result;
};

export const generateFields = async (
  fieldsConfig: DataSourceConnectionTemplateField[],
  isBulkUpdate = false,
  includeNestedFields = false,
  overrides?: DataSourceConnectionTemplateOverrides,
): Promise<BigidFormField[]> => {
  if (includeNestedFields) {
    return generateNestedFields(fieldsConfig, isBulkUpdate, overrides);
  }

  return await Promise.all(
    fieldsConfig.map((fieldConfig, index) =>
      generateOneField(fieldConfig, isBulkUpdate, index, fieldsConfig, overrides),
    ),
  );
};
