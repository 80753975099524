import React, { FC } from 'react';
import { useLocalTranslation } from '../translations';
import { CatalogDiscoveryGuidedTourStep } from '../CatalogDiscoveryGuidedTourStep';

interface DataSourcesInventoryProps {
  dataAid: string;
}

export const DataSourcesInventory: FC<DataSourcesInventoryProps> = ({ dataAid }) => {
  const { t } = useLocalTranslation('steps.DataSourcesInventory');

  return <CatalogDiscoveryGuidedTourStep dataAid={dataAid} body={t('body')} />;
};
