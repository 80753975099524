import React, { useEffect, useMemo, useState } from 'react';
import {
  ActionData,
  BigidColorsV2,
  EntityEvents,
  ToolbarAction,
  ToolbarActionType,
  entityEventsEmitter,
} from '@bigid-ui/components';
import styled from '@emotion/styled';
import { BigidGridColumn, BigidGridColumnTypes, BigidGridWithToolbar, BigidGridWithToolbarProps } from '@bigid-ui/grid';
import { VendorAttachedFile, VendorStateNames } from './VendorsTypes';
import { useVendorProjectContext } from './VendorProjectContext';
import { BigidLayoutEmptyState } from '@bigid-ui/layout';
import { BigidAddIcon, BigidDeleteIcon, BigidDownloadIcon, BigidNoDataIllustration } from '@bigid-ui/icons';
import { downloadAttachedFile, downloadAttachedFiles, getVendorAttachedFiles } from './vendorsService';
import { useUserPreferences } from '../../components/hooks/useUserPrefrences';
import { notificationService } from '../../services/notificationService';
import { EmptyStateWrapper } from './VendorsSummaryStyles';
import { isPermitted } from '../../services/userPermissionsService';
import { VENDORS_PERMISSIONS } from '@bigid/permissions';
import { VendorAttachedFilesImportDialog, useVendorAttachedFilesImportDialog } from './VendorAttachedFilesImportDialog';
import { VendorEvents, vendorsEventEmitter } from './utils/VendorEvents';
import { VendorsTrackingEvents, trackVendors } from './utils/analytics';
import {
  VendorAttachedFilesDeleteDialog,
  getQuery,
  useVendorAttachedFilesDeleteDialog,
} from './VendorAttachedFilesDeleteDialog';

const MainWrapper = styled('div')`
  display: flex;
  background-color: ${BigidColorsV2.white};
  justify-content: center;
  height: 100%;
  width: 100%;
  overflow: hidden;
  padding: 8px;
`;

type VendorAttachedFilesGridType = BigidGridWithToolbarProps<VendorAttachedFile & { id: string }>;
type VendorAttachedFileGridColumn = VendorAttachedFile & { id: string };

const columns: BigidGridColumn<VendorAttachedFileGridColumn>[] = [
  {
    width: 170,
    title: 'File Name',
    name: 'file_name',
    type: BigidGridColumnTypes.TEXT,
    getCellValue: ({ fileName }) => fileName,
    filteringEnabled: false,
    sortingEnabled: false,
  },
  {
    width: 170,
    title: 'File Type',
    name: 'type',
    type: BigidGridColumnTypes.TEXT,
    getCellValue: ({ type }) => type,
    filteringEnabled: false,
    sortingEnabled: false,
  },
  {
    width: 170,
    title: 'Description',
    name: 'description',
    type: BigidGridColumnTypes.TEXT,
    getCellValue: ({ description }) => description,
    filteringEnabled: false,
    sortingEnabled: false,
  },
  {
    width: 280,
    title: 'Date',
    name: 'created_at',
    type: BigidGridColumnTypes.DATE,
    getCellValue: ({ updatedAt }) => updatedAt,
    filteringEnabled: false,
    sortingEnabled: true,
  },
  {
    width: 280,
    title: 'User',
    name: 'user',
    type: BigidGridColumnTypes.TEXT,
    getCellValue: ({ user }) => user,
    filteringEnabled: false,
    sortingEnabled: false,
  },
];

const getFilterConfig = async (): Promise<VendorAttachedFilesGridType['filterToolbarConfig']> => {
  return {
    filters: [],
    searchConfig: {
      searchFilterKeys: ['description', 'file_name'],
      initialValue: '',
      operator: 'textSearch',
    },
  };
};

export const VendorAttachedFilesGrid = () => {
  const [showInlineActions, setShowInlineActions] = useState(true);
  const { projectId: vendorProjectId } = useVendorProjectContext();
  const { importDialogProps, openImportDialog } = useVendorAttachedFilesImportDialog();
  const { deleteDialogProps, openDeleteDialog } = useVendorAttachedFilesDeleteDialog();
  const { isReady, gridColumns, filterToolbarConfig, preferences, updatePreferences } = useUserPreferences({
    stateName: VendorStateNames.VENDORS_SUMMARY_LOGS,
    initialGridColumns: columns,
    getInitialFilterToolbarConfig: getFilterConfig,
  });

  useEffect(() => {
    const eventHandler = async () => {
      await openImportDialog(vendorProjectId);
      entityEventsEmitter.emit(EntityEvents.RELOAD);
    };
    vendorsEventEmitter.addEventListener(VendorEvents.OPEN_ATTACHED_FILE_IMPORT_DIALOG, eventHandler);

    return () => {
      vendorsEventEmitter.removeEventListener(VendorEvents.OPEN_ATTACHED_FILE_IMPORT_DIALOG, eventHandler);
    };
  }, [openImportDialog, vendorProjectId]);

  const gridWithToolbarConfig: VendorAttachedFilesGridType = useMemo(() => {
    const deleteExecutor = (isInlineAction?: boolean) => async (actionData: ActionData) => {
      const eventType = isInlineAction
        ? VendorsTrackingEvents.DELETE_ATTACHED_FILE_INLINE_ACTION
        : VendorsTrackingEvents.DELETE_ATTACHED_FILE_ACTION;
      trackVendors(eventType);
      await openDeleteDialog(vendorProjectId, actionData, isInlineAction);
      return { shouldClearSelection: true, shouldGridReload: true };
    };

    const downloadExecutor = (isInlineAction?: boolean) => async (actionData: ActionData) => {
      try {
        const eventType = isInlineAction
          ? VendorsTrackingEvents.DOWNLOAD_ATTACHED_FILE_INLINE_ACTION
          : VendorsTrackingEvents.DOWNLOAD_ATTACHED_FILE_ACTION;
        trackVendors(eventType);
        if (isInlineAction) {
          downloadAttachedFile(vendorProjectId, actionData.selectedRows[0].id);
        } else {
          downloadAttachedFiles(vendorProjectId, getQuery(actionData));
        }
      } catch (err) {
        notificationService.error(`Deleting attached file failed!`);
        console.error(`Could not delete attached files ${actionData.selectedRowIds.join(', ')}`);
      }
      return { shouldClearSelection: true, shouldGridReload: true };
    };

    const gridConfig: VendorAttachedFilesGridType = {
      columns: gridColumns,
      showSortingControls: true,
      showFilteringControls: true,
      entityName: 'files',
      defaultSorting: preferences?.grid?.sort || [{ field: 'timestamp', order: 'desc' }],
      onGridStateChange: ({ filter, ...gridState }) => {
        setShowInlineActions(!gridState.selectedRows.length);
        updatePreferences({ filterState: { filter }, gridState });
      },
      filterToolbarConfig: filterToolbarConfig,
      toolbarActions: [
        {
          label: 'Delete',
          tooltip: 'Delete selected attached files',
          icon: BigidDeleteIcon,
          type: ToolbarActionType.TERTIARY,
          execute: deleteExecutor(),
          show: ({ selectedRows }) => !!selectedRows.length,
        },
        {
          label: 'Download',
          tooltip: 'Download selected attached files',
          icon: BigidDownloadIcon,
          type: ToolbarActionType.TERTIARY,
          execute: downloadExecutor(),
          show: ({ selectedRows }) => !!selectedRows.length,
        },
        ...(showInlineActions
          ? ([
              {
                label: 'Delete',
                tooltip: 'Delete attached file',
                icon: BigidDeleteIcon,
                type: ToolbarActionType.ACTION_ICON,
                execute: deleteExecutor(true),
                show: () => true,
                isInline: true,
                hideActionInToolBar: true,
              },
              {
                label: 'Download',
                tooltip: 'Download attached file',
                icon: BigidDownloadIcon,
                type: ToolbarActionType.ACTION_ICON,
                execute: downloadExecutor(true),
                show: () => true,
                isInline: true,
                hideActionInToolBar: true,
              },
            ] as ToolbarAction[])
          : []),
      ],
      fetchData: async gridQueryParams => {
        try {
          const { vendorProjectFiles, totalCount } = await getVendorAttachedFiles(vendorProjectId, gridQueryParams);
          return {
            data: vendorProjectFiles,
            totalCount: totalCount,
          };
        } catch (error) {
          notificationService.error(`Failed to fetch vendor activity details.`);
          console.error(`Failed to fetch vendor activity details': ${JSON.stringify(error)}`);
          return { data: [], totalCount: 0 };
        }
      },
      noDataContent: (
        <EmptyStateWrapper>
          <BigidLayoutEmptyState
            actions={[
              {
                label: 'New file',
                icon: BigidAddIcon,
                execute: async () => {
                  trackVendors(VendorsTrackingEvents.IMPORT_ATTACHED_FILE_DIALOG_OPEN_CLICK);
                  vendorsEventEmitter.emit(VendorEvents.OPEN_ATTACHED_FILE_IMPORT_DIALOG);
                  return { shouldGridReload: false };
                },
                show: () => isPermitted(VENDORS_PERMISSIONS.VENDOR_CREATE.name),
              },
            ]}
            illustration={BigidNoDataIllustration}
            description={'No files uploaded yet'}
          />
        </EmptyStateWrapper>
      ),
    };
    return gridConfig;
  }, [
    filterToolbarConfig,
    gridColumns,
    openDeleteDialog,
    preferences?.grid?.sort,
    showInlineActions,
    updatePreferences,
    vendorProjectId,
  ]);

  return (
    <>
      {isReady && (
        <MainWrapper>
          <BigidGridWithToolbar {...gridWithToolbarConfig} />
          <VendorAttachedFilesImportDialog {...importDialogProps} />
          <VendorAttachedFilesDeleteDialog {...deleteDialogProps} />
        </MainWrapper>
      )}
    </>
  );
};
