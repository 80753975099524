import './idp.component.scss';
import { module } from 'angular';
import template from './idp.component.html';
import { getApplicationPreference } from '../../../react/services/appPreferencesService';

const app = module('app');

app.component('idp', {
  template,
  controller: function () {
    this.isSaas = getApplicationPreference('USE_SAAS');
    this.isActiveDirectoryEnable = getApplicationPreference('SAAS_ACTIVE_DIRECTORY_ENABLE_FOR_LABELING');
  },
});
