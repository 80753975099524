import { useCallback } from 'react';
import { UpdateDataSourceConfigState } from './useDataSourceConfigState';

export const useUpdateEnabledStatus = (updateState: UpdateDataSourceConfigState) =>
  useCallback(
    (values: Record<string, any>) => {
      updateState(currentState =>
        currentState.enabled === (values.enabled?.[0].value === 'yes')
          ? currentState
          : {
              ...currentState,
              enabled: values.enabled?.[0].value === 'yes',
            },
      );
    },
    [updateState],
  );
