import { module, identity, IRequestShortcutConfig } from 'angular';
const app = module('app');

import { $http } from 'ngimport';
import { appSettings } from '../../../common/services/app.common.services';
import { httpService } from '../../../react/services/httpService';

export interface GlossaryItem {
  id: string;
  _id: string;
  glossary_id: string;
  type: GlossaryItemType;
  name: string;
  description?: null | string;
  is_ootb?: boolean;
  update_date: string;
  report_order?: number;
}

export interface GlossaryItemPostResponse {
  _id: string;
  updated: boolean;
  is_new: boolean;
  glossary_id: string;
  type: string;
}

export enum GlossaryItemType {
  PersonalDataCategory = 'Personal Data Category',
  PersonalDataItem = 'Personal Data Item',
  PurposeOfProcessing = 'Purpose Of Processing',
}

export const businessGlossaryService = {
  getGlossaryItems: (params?: { type: string }) =>
    httpService.fetch<GlossaryItem[]>('business_glossary_items', params).then(response => response.data),

  createGlossaryItem: (data: any) =>
    httpService.post<GlossaryItemPostResponse>(`business_glossary_items`, data).then(response => response.data),

  updateGlossaryItem: (id: string, data: any) =>
    $http
      .put(`${appSettings.serverPath}/${appSettings.version}/business_glossary_items/${id}`, data)
      .then(response => response.data),

  deleteGlossaryItem: (id: string) =>
    httpService.delete<{ num_deleted: number }>(`business_glossary_items/${id}`).then(response => response.data),

  importFromFile: (data: any) => {
    const config: IRequestShortcutConfig = {
      transformRequest: identity,
      headers: {
        'Content-Type': undefined,
      },
    };

    return $http
      .post(`${appSettings.serverPath}/${appSettings.version}/business_glossary_items/import_from_file`, data, config)
      .then(response => response.data);
  },

  getGlossaryDataExport: () =>
    $http
      .get(`${appSettings.serverPath}/${appSettings.version}/business_glossary_items/export`)
      .then(response => response.data),

  isValidType: (type: GlossaryItemType) => {
    return [
      GlossaryItemType.PersonalDataCategory,
      GlossaryItemType.PersonalDataItem,
      GlossaryItemType.PurposeOfProcessing,
    ].includes(type);
  },
};

app.factory('businessGlossaryService', () => {
  return businessGlossaryService;
});
