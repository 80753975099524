import angular from 'angular';
import './addCommentModal.component.scss';
import template from './addCommentModal.component.html';

angular.module('app').component('addCommentModal', {
  template,
  controller: function () {
    'ngInject';
    this.comment = '';
  },
  bindings: {
    comment: '<',
    isCommentIdenticalForAll: '<',
    $close: '&',
    $dismiss: '&',
  },
});
