import React from 'react';
import { TertiaryButton } from '@bigid-ui/components';
import { Collaboration } from '../../mappers/styles';
import { BigidClearIcon } from '@bigid-ui/icons';
import { useLocalTranslation } from '../../translations';

type DataSourceCollaborationRemoveOptionProps = {
  dataAid?: string;
  onClick: () => void;
};

export const DataSourceCollaborationRemoveOption = ({
  dataAid = 'DataSourceCollaborationRemoveOption',
  onClick,
}: DataSourceCollaborationRemoveOptionProps) => {
  const { t } = useLocalTranslation();

  return (
    <Collaboration.Option>
      <TertiaryButton
        size="large"
        dataAid={dataAid}
        onClick={onClick}
        text={t('buttons.revoke')}
        startIcon={<BigidClearIcon color="negative" />}
      />
    </Collaboration.Option>
  );
};
