import { styled } from '@mui/styles';
import { Divider } from '@mui/material';
import { BigidColorsV2 } from '@bigid-ui/components';

export const CustomDivider = styled(Divider)({
  marginLeft: 10,
  marginRight: 10,
  paddingLeft: 10,
  height: '100%',
  border: 'none',
  width: 30,
  borderLeft: `1px solid ${BigidColorsV2.gray[500]}`,
  display: 'flex',
  alignItems: 'center',
  '&:before': {
    color: BigidColorsV2.gray[500],
    content: '"View"',
    fontSize: 13,
  },
});

export const LoaderWrapper = styled('div')({
  marginTop: 104,
  width: '100%',
  height: '100%',
  position: 'relative',
});
