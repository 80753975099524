import classNames from 'classnames';
import React, { FunctionComponent } from 'react';
import styles from './AttributeInfo.scss';

export interface AttributeInfoProps {
  label: string;
  values?: string[];
  lighter?: boolean;
}

export const AttributeInfo: FunctionComponent<AttributeInfoProps> = ({ lighter, label, values = [] }) => {
  return (
    <div className={styles.wrapper}>
      <div className={classNames(styles.label, lighter && styles.lighter)} title={label}>
        {label}
      </div>
      <div className={styles.values}>
        {values.map((value, i) => {
          return (
            <div className={styles.value} title={value} key={i}>
              {value}
            </div>
          );
        })}
      </div>
    </div>
  );
};
