import { getBigIdIcon, getBase64Image } from './dataMappingConsts.js';

import { module } from 'angular';
const app = module('app');

app.factory('dataMappingDocxReportService', function (downloadFileService, lazyLoadService) {
  'ngInject';

  return {
    createDocxReport: function createDocxReport(vm, dataImage) {
      let currentdate = new Date();
      let content = `<!DOCTYPE html>
          <html>
          <head>
          <meta charset="UTF-8">
          <style>
            .header{
            font-size: 13px;
             }
             .sub-header{
            font-size: 11px;
             }
             .description{
            font-size: 10px;
             }
            </style>
          </head>
          <body>
           <table>
            <tr>
            <td style="width:90%">
              <span style="font-size:18px;color:#262D37;font-weight: bold;">
                Business Process Report : ${vm.businessProcess.title}
              </span>
            </td>
            <td style="float:right;">
              <img style="float: right;" width="52" height="52" src="${getBigIdIcon()}" alt="" />
            </td>
            </tr>
            <tr style="height:5px;">
              <td colspan="2">
                <h5 style="text-decoration: underline;">${currentdate.toLocaleString()}&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;                        &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;                            &nbsp; &nbsp; &nbsp;&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp; &nbsp; &nbsp; &nbsp;&nbsp;&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp; &nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </h5>
              </td>
            </tr>
            <tr>
            <tr>
              <td>&nbsp;</td>
            </tr>
           </table>
           <table>
            <tr>
              <td>
                <img style="float:left;" src="${dataImage}" alt="" />
              </td>
            </tr>
           </table>

            ${getLegendPropertiesTable(vm)}

            <br/>
            <br/>
            ${getFlowsTable(vm)}
            <p style="page-break-before: always;">&nbsp;</p>
            ${getUnconnectedEntities(vm)}
          </body>
          </html>`;

      lazyLoadService.loadHtmlDocX().then(htmlDocX => {
        const converted = htmlDocX.asBlob(content);

        const date = currentdate.getDate();
        const month = currentdate.getMonth() + 1;
        const fullYear = currentdate.getFullYear();
        const hours = currentdate.getHours();
        const minutes = currentdate.getMinutes();
        const seconds = currentdate.getSeconds();
        const fileName = `dataFlowReport_Docx_${date}_${month}_${fullYear}_${hours}:${minutes}:${seconds}.docx`;

        downloadFileService.download(converted, 'attachment/docx', fileName);
      });
    },
  };

  function getLegendPropertiesTable(vm) {
    return `

      <table width="620;" border="1">
        <tbody>
        <tr>
          <td>
            <table width="620" border="0">
             <tr>
              <td>
                <h5>
                  Legend:
                </h5>
              </td>
             </tr>
             <!--<tr style="height: 1px;">-->
              <!--<td colspan="2" style="height: 1px;">&nbsp;</td>-->
             <!--</tr>-->
             <tr>
              <td style="line-height: 0.5cm">
                ${getLegendData(vm)}
              </td>
             </tr>
            </table>
          </td>
        </tr>
        </tbody>
      </table>
      <p style="page-break-before: always;">&nbsp;</p>
      <table width="620" border="1" style="page-break-before: always;">
        <tbody>
        <tr>
          <td>
            <table width="620" border="0">
             <tr>
              <td colspan="4">
                <h5>
                  Properties:
                </h5>
              </td>
             </tr>
             <!--<tr style="height: 1px;">-->
              <!--<td colspan="2" style="height: 1px;">&nbsp;</td>-->
             <!--</tr>-->
             <tr>
               <td class="header"><p>Description:</p></td>
               <td class="header""><p>${vm.businessProcess.description}</p></td>
             </tr>
              <tr>
               <td class="header"><p>Owner Cell:</p></td>
               <td class="header"><p>${vm.businessProcess.ownerCell}</p></td>
             </tr>
             <tr>
               <td class="header"><p>Owner Email:</p></td>
               <td class="header"><p>${vm.businessProcess.ownerEmail}</p></td>
             </tr>
             <tr>
               <td class="header"><p>Annotations:</p></td>
               <td class="header"><p>${getAnnotationsData(vm.businessProcess)}</p></td>
             </tr>
            </table>
          </td>
        </tr>
        </tbody>
      </table>
      <p style="page-break-before: always;">&nbsp;</p>`;
  }

  function getLegendData(vm) {
    let legendData = '';
    let activeFlows = vm.businessFlows.filter(flow => !flow.hidden);
    let activeFlowsLength = activeFlows.length;
    if (activeFlows) {
      legendData += "<table width='615'>";
      for (let i = 0; i < activeFlows.length; i = i + 2) {
        if (i % 2 === 0) {
          legendData += "<tr style='line-height: 0.5cm !important;'>";
        }
        legendData += `
            <td style="background-color: ${activeFlows[i].color};height: 1%;line-height: 0.5cm;max-height: 5px;">
              <span style="height: 1%;line-height: 0.5cm;max-height: 5px;"></span>
            </td>
            <td style="width: 48%;line-height: 0.5cm;" class="header">${activeFlows[i].name}</td>`;

        if (i + 1 < activeFlowsLength) {
          legendData += `
              <td style="background-color: ${
                activeFlows[i + 1].color
              }; width: 2%;line-height: 0.5cm;max-height: 5px;color: transparent;">
                <span style="height: 1%;line-height: 0.5cm;max-height: 5px;"></span>
              </td>
              <td class="header"  style="width: 48%;line-height: 0.5cm;">${activeFlows[i + 1].name}</td>
            `;
        }

        if (i % 2 === 0) {
          legendData += '</tr>';
        }
      }
      legendData += '</table>';
    }
    return legendData;
  }

  function getAnnotationsData(entity) {
    let annotationsData = [];

    if (entity.annotations && entity.annotations.length > 0) {
      let uniqueCategories = [...new Set(entity.annotations.map(annotation => annotation.category))];
      if (uniqueCategories && uniqueCategories.length > 0) {
        uniqueCategories.forEach(category => {
          let categoryItems = entity.annotations.filter(annotation => annotation.category === category);
          annotationsData.push(`<span class="header" style="color:#717d89;">${category}</span>`);
          categoryItems.forEach((categoryItem, idx) => {
            annotationsData.push(`<span class="sub-header">${idx + 1}.   ${categoryItem.title}</span>`);
            annotationsData.push(
              `<span class="description" style="color:#717d89;">${categoryItem.description || ''}</span>`,
            );
          });
        });
      }
    }
    if (annotationsData.length == 0) {
      annotationsData.push('');
    }

    return annotationsData.join(' <br /> ');
  }

  function getFlowsTable(vm) {
    let flowData = [];
    let flows = vm.businessFlows.filter(flow => !flow.hidden);
    flows.forEach(flow => {
      flowData.push(buildFlowTable(vm, flow));
    });
    return flowData.join('<br/>');
  }

  function buildFlowTable(vm, flow) {
    let flowData = '';
    //let drawingObjectsArray = [];
    let connectorsRelatedToFlow = vm.connectors
      .filter(connector => connector.flowId === flow._id)
      .sort((a, b) => a.stepNumber - b.stepNumber);

    flowData += "<table border='0' width='100%'>";

    flowData += getHeadlinesOfTheFlow(flow);

    flowData += getConnectedEntities(vm, connectorsRelatedToFlow);

    flowData += '</table>';

    return flowData;
  }

  function getHeadlinesOfTheFlow(flow) {
    return `<tr>
                <td style="width:33%;height: 5px;font-weight: bold;border-right: 1px  solid black;"><span>${flow.name}:</span></td>
                <td style="width:33%;height: 5px;font-weight: bold;border-right: 1px  solid black;"><span>Annotations:</span></td>
                <td style="width:33%;height: 5px;font-weight: bold;"><span>Personal Data Used:</span></td>
              </tr>`;
  }

  function getUnconnectedEntities(vm) {
    let entityData = `<table width="100%">
                          <tr>
                            <td style="width:33%;height: 5px;font-weight: bold;border-right: 1px  solid black;">
                              <div style="margin: 5px;">Unconnected Entities:</div>
                            </td>
                            <td style="width:33%;height: 5px;font-weight: bold;border-right: 1px  solid black;">
                              <div style="margin: 5px;">Annotations:</div>
                            </td>
                            <td style="width:33%;height: 5px;font-weight: bold;">
                              <div style="margin: 5px;">Personal Data Used:</div>
                            </td>
                          </tr>`;
    let connectedEntitiesID = [];
    //1.Go through the connectors
    if (vm.connectors) {
      vm.connectors.forEach(connector => {
        let attributesToCheck = ['from', 'to'];
        //1.1 in each connectors collect the from and to object and check if they are not already exists in the array of the ids we built.
        attributesToCheck.forEach(attributeToCheck => {
          if (!connectedEntitiesID.find(item => item === connector[attributeToCheck])) {
            connectedEntitiesID.push(connector[attributeToCheck]);
          }
        });
      });
    }
    //2.After we created the ids array we will find the entities that are not exist in the array and returned from the entities array.
    if (vm.entities) {
      vm.entities.forEach(entity => {
        if (!connectedEntitiesID.find(connectedEntityID => connectedEntityID === entity._id)) {
          //3.built a function that build the object with the annotations and attributes belong to the entity.
          entityData += buildSingleEntity(entity);
        }
      });
    }
    entityData += '</table>';
    return entityData;
  }

  function buildSingleEntity(entity, numberOfNewLines) {
    let description = getDescription(entity);
    let newLines = '';
    if (numberOfNewLines) {
      for (let i = 0; i < numberOfNewLines; i++) {
        newLines += `<tr><td colspan="2">&nbsp;</td></tr>`;
      }
    }

    return `<tr>
                  <td style="width:33%;border-right: 1px  solid black;">
                    <table  style=" background-color: #f1f3f7;border: 2px  solid dimgray;" width="95%">
                      <tr>
                        <td>
                          <div style="margin:5px;">
                            <img style="float: right;" width="25" height="25" src="${getBase64Image(entity)}" alt="" />
                          </div>
                        </td>
                        <td>
                          <span class="header">${getEntityName(entity)}</span>
                        </td>
                      </tr>
                      ${newLines}
                      <tr>
                        <td colspan="2">
                          <span class="sub-header">${description}</span>
                        </td>
                      </tr>
                    </table>
                  </td>
                  <td style="width:33%;border-right: 1px  solid black;">
                    ${getAnnotationsData(entity)}
                  </td>
                  <td style="width:33%">
                    ${getPersonalDataList(entity)}
                  </td>
                </tr>`;
  }

  function getDescription(entity) {
    if (entity && entity.description) {
      return `\n\n ${entity.description}`;
    }
    return '';
  }

  function getEntityName(entity) {
    if (entity.name) {
      if (entity.name.length > 20) {
        return `${entity.name.substring(0, 20)}...`;
      }
      return entity.name;
    }
  }

  function getConnectedEntities(vm, connectorsRelatedToFlow) {
    let connectedEntities = '';
    let toObjectConnectorArray = [];
    connectorsRelatedToFlow.forEach(connector => {
      let numberOfNewLines = 0;
      //From Object
      if (!toObjectConnectorArray.find(toObjectConnector => toObjectConnector === connector.from)) {
        let selectedEntityFrom = vm.entities.find(entity => connector.from === entity._id);
        if (selectedEntityFrom) {
          numberOfNewLines = getNumberOfNewLines(selectedEntityFrom);
          connectedEntities += buildSingleEntity(selectedEntityFrom, numberOfNewLines);
        }
      }

      //Connector Object
      let connectorColor = vm.businessFlows.find(businessFlow => businessFlow._id === connector.flowId).color;
      let stepNumber = connector.stepNumber + '.';
      connectedEntities += buildConnector(connector, connectorColor, stepNumber);

      //To Object
      let selectedEntityTo = vm.entities.find(entity => connector.to === entity._id);
      if (selectedEntityTo) {
        numberOfNewLines = getNumberOfNewLines(selectedEntityTo);
        connectedEntities += buildSingleEntity(selectedEntityTo, numberOfNewLines);
      }
    });

    return connectedEntities;
  }

  function getNumberOfNewLines(entity) {
    let sumOfNewLinesForAttributes = 0;
    let sumOfNewLinesForAnnotations = 0;
    let result = '';
    if (entity && entity.attributes && entity.attributes.length > 0) {
      sumOfNewLinesForAttributes = entity.attributes.length;
    }
    if (entity && entity.attributesFromSystem && entity.attributesFromSystem.length > 0) {
      sumOfNewLinesForAttributes += entity.attributesFromSystem.length;
    }
    if (entity && entity.annotations && entity.annotations.length > 0) {
      sumOfNewLinesForAnnotations = entity.annotations.length * 1;
    }
    if (sumOfNewLinesForAnnotations > 0 || sumOfNewLinesForAttributes > 0) {
      if (sumOfNewLinesForAnnotations > sumOfNewLinesForAttributes) {
        return sumOfNewLinesForAnnotations + 1;
      } else {
        return sumOfNewLinesForAttributes + 1;
      }
    }
    return result;
  }

  function buildConnector(connector, connectorColor, stepNumber) {
    return `
        <tr>
          <td style="width:33%;border-right: 1px  solid black;">
            <table width="95%" border="0">
              <tr>
                <td style="width:15%;">
                  <span style="font-size: 40px; color: ${connectorColor};">&dArr;</span>
                </td>
                <td>
                  <span class="header" style="color: ${connectorColor};">${stepNumber}</span><span class="header"> ${connector.description || ''}</span>
                </td>
              </tr>
            </table>
          </td>
          <td style="width:33%;border-right: 1px  solid black;"></td>
        </tr>`;
  }

  function getPersonalDataList(entity) {
    let entityClone = JSON.parse(JSON.stringify(entity));
    let personalDataList = '<table>';
    let existingAttribute = { text: '' };
    let attributeList = [];
    let categoryList = [];
    let excluded_attributes = [];

    let attributesListFromSystemArrayForOrdering = [];
    let customAttributesListArrayForOrdering = [];

    if (entityClone.attributes && entityClone.attributes.length > 0) {
      excluded_attributes = entityClone.attributes.filter(attribute => attribute.is_excluded);
      entityClone.attributes = entityClone.attributes.filter(attribute => !attribute.is_excluded);
      entityClone.attributes.forEach(attribute => {
        if (entityClone.attributesFromSystem && entityClone.attributesFromSystem.length > 0) {
          let attributeFromSystem = entityClone.attributesFromSystem.find(
            attributeFromSystem => attributeFromSystem._id === attribute.attribute,
          );
          if (attributeFromSystem) {
            attribute.is_fromSystem = true;
            attributesListFromSystemArrayForOrdering.push(attribute);
          }
        }
        if (!attribute.is_fromSystem) {
          customAttributesListArrayForOrdering.push(attribute);
        }
      });
    }
    if (entityClone.attributesFromSystem && entityClone.attributesFromSystem.length > 0) {
      entityClone.attributesFromSystem.forEach(attributeFromSystem => {
        if (
          !entityClone.attributes.find(attributeItem => attributeItem.attribute === attributeFromSystem) &&
          !excluded_attributes.find(attributeItem => attributeItem.attribute === attributeFromSystem._id)
        ) {
          attributesListFromSystemArrayForOrdering.push(attributeFromSystem);
        }
      });
    }

    //TODO: move these methods to a generic method (move to)
    if (attributesListFromSystemArrayForOrdering && attributesListFromSystemArrayForOrdering.length > 0) {
      attributesListFromSystemArrayForOrdering.sort((a, b) => {
        const stringToCompare1 = a.attribute || a.friendly_name;
        const stringToCompare2 = b.attribute || b.friendly_name;
        return stringToCompare1.localeCompare(stringToCompare2);
      });
      attributesListFromSystemArrayForOrdering.forEach(attributeItem => {
        personalDataList += setAttributeItemArray(attributeItem, attributeList, true);
        attributeList = setAttributeList(existingAttribute, attributeItem, attributeList);
      });
    }
    //TODO: move these methods to a generic method (move to orderAlphabeticAttributesList)
    if (customAttributesListArrayForOrdering && customAttributesListArrayForOrdering.length > 0) {
      customAttributesListArrayForOrdering.sort((a, b) => {
        const stringToCompare1 = a.attribute || a._id;
        const strongToCompare2 = b.attribute || b._id;
        return stringToCompare1.localeCompare(strongToCompare2);
      });
      customAttributesListArrayForOrdering.forEach(attributeItem => {
        personalDataList += setAttributeItemArray(attributeItem, attributeList, false);
        attributeList = setAttributeList(existingAttribute, attributeItem, attributeList);
      });
    }

    if (entityClone.categories && entityClone.categories.length > 0) {
      entityClone.categories.forEach(category => {
        personalDataList += setCategoryItemArray(category, categoryList);
      });
    }

    personalDataList += '</table>';

    return personalDataList;
  }

  function orderAlphabeticAttributesList(attributesList, existingAttribute, isAttributeFromSystem, personalDataList) {
    if (attributesList && attributesList.length > 0) {
      attributesList.sort((a, b) => {
        const stringToCompare1 = a.attribute || a._id;
        const strongToCompare2 = b.attribute || b._id;
        return stringToCompare1.localeCompare(strongToCompare2);
      });
      attributesList.forEach(attributeItem => {
        personalDataList += setAttributeItemArray(attributeItem, attributesList, isAttributeFromSystem);
        attributesList = setAttributeList(existingAttribute, attributeItem, attributesList);
      });
    }
    return personalDataList;
  }

  function setAttributeList(existingAttribute, attribute, attributeList) {
    let existingAttributeLocal = JSON.parse(JSON.stringify(existingAttribute));
    existingAttributeLocal.text = attribute.attribute || attribute.friendly_name || attribute._id || '';
    attributeList.push(existingAttributeLocal);
    return attributeList;
  }

  function setAttributeItemArray(attribute, attributeList, isAttributeFromSystem) {
    let attributeItem = '';
    let isAttributeExist = false;
    if (attributeList.length === 0) {
      attributeItem += `<tr><td colspan="2"><span class="header" style="color: #717d89">Attributes:</span></td></tr>`;
      //return attributeItem;
    } else {
      let attributesItemArrayLocal = JSON.parse(JSON.stringify(attributeList));
      attributesItemArrayLocal.forEach(existAttribute => {
        if (
          existAttribute.text === attribute.attribute ||
          existAttribute.text === attribute.friendly_name ||
          existAttribute.text === attribute._id
        ) {
          attributesItemArrayLocal.length = 0;
          isAttributeExist = true;
        }
      });
    }
    if (!isAttributeExist) {
      let purpose = '';
      if (!isAttributeFromSystem) {
        purpose = attribute.purpose || '';
      }
      attributeItem += `
          <tr>
            <td>
              <table>
                <tr>
                  <td>
                    <table style="border: 2px  solid #717d89";">
                      <tr>
                        <td>
                          <span class="sub-header" style="${
                            attribute.is_fromSystem ? 'color: #717d89' : 'color: #2196F3'
                          }">${attribute.attribute || attribute.friendly_name || attribute._id || ''}</span>
                        </td>
                      </tr>
                    </table>
                  </td>
                  <td>
                    <span class="sub-header" style="color: #717d89">${purpose}</span>
                  </td>
                </tr>
              </table>
            </td>
          </tr>`;
    }
    return attributeItem;
  }

  function setCategoryItemArray(category, categoryList) {
    let categoryItem = '';
    let isCategoryExist = false;
    if (categoryList.length === 0) {
      categoryItem += `<tr><td colspan="2"><span>Categories:</span></td></tr>`;
    } else {
      let categoriesItemArrayLocal = JSON.parse(JSON.stringify(categoryList));
      categoriesItemArrayLocal.forEach(existingCategory => {
        if (existingCategory.text === category.category || existingCategory.text === category._id) {
          categoriesItemArrayLocal.length = 0;
          isCategoryExist = true;
        }
      });
    }
    if (!isCategoryExist) {
      let purpose = category.purpose || '';

      categoryItem += `
          <tr>
            <td>
              <table>
                <tr>
                  <td>
                    <table style="border: 2px  solid dimgray;">
                      <tr>
                        <td>
                          <span>${category.category || category._id || ''}</span>
                        </td>
                      </tr>
                    </table>
                  </td>
                  <td>
                    ${purpose}
                  </td>
                </tr>
              </table>
            </td>
          </tr>`;
    }
    return categoryItem;
  }
});
