import { BigidBody1 } from '@bigid-ui/components';
import { BigidObjectsIllustration } from '@bigid-ui/icons';
import { styled } from '@mui/material';
import React, { FC, ReactNode } from 'react';

const Container = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 24px;
  align-items: center;
`;

const ActionWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-items: center;
`;

type NoDataProps = {
  description: string;
  dataAid?: string;
  actionComponent?: ReactNode;
};

export const NoData: FC<NoDataProps> = ({ description, dataAid = 'NoData', actionComponent }) => {
  return (
    <Container data-aid={dataAid}>
      <BigidObjectsIllustration size="small" />
      <ActionWrapper>
        <BigidBody1>{description}</BigidBody1>
        {actionComponent}
      </ActionWrapper>
    </Container>
  );
};
