import React, { FC, useState, Fragment, useMemo, useCallback, useEffect, useRef, ReactText } from 'react';
import { format } from 'date-fns';
import { BigidRemoveIcon, BigidEditIcon, BigidExportIcon, BigidSyncIcon, BigidAddContainedIcon } from '@bigid-ui/icons';
import { styled } from '@mui/material';
import {
  BigidImportDialog,
  BigidFieldFilter,
  ActionData,
  entityEventsEmitter,
  EntityEvents,
  BigidColorsV2,
  ToolbarActionType,
  BigidProgressBarStatus,
  BigidProgressBar,
  ToolbarAction,
} from '@bigid-ui/components';
import { BigidGridColumnTypes, BigidGridWithToolbar, BigidGridWithToolbarProps, BigidGridColumn } from '@bigid-ui/grid';
import { notificationService } from '../../../../services/notificationService';
import {
  SarFieldsSettingsV2 as SarFieldsSettings,
  SarProfile,
  DataSource,
  FieldsEnrichmentActionType,
  SynchronizationEntity,
} from '../ProfileSettingsTypes';
import { SSEDataMessage, SSEOperationStatus, sendSSERequestWithActionCallback } from '../../../../services/sseService';
import { EditFieldsDialog, getQueryFilter, updateSources } from './EditFieldsDialogV2';
import { useEditFieldsDialogV2 as useEditFieldsDialog } from './useEditFieldsDialog';
import {
  getCellValueForIncludeExcludeV2 as getCellValueForIncludeExclude,
  getCellValueForIncludeInProximityCategory,
  getCellValueForMaskValue,
} from '../gridCellValues';
import { isPermitted } from '../../../../services/userPermissionsService';
import { DSAR_PERMISSIONS } from '@bigid/permissions';
import { FieldType, NEW_DSAR_SETTINGS_STATE_NAMES } from '../SarProfileSettingsTypes';
import { useUserPreferences } from '../../../../components/hooks/useUserPrefrences';
import { getApplicationPreference } from '../../../../services/appPreferencesService';
import { getDictionaries, getFields, getProfileById } from '../../dsarService';
import * as dsarService from '../../dsarService';
import { ColumnName, EditFieldsCellDialog } from './EditFieldsCellDialog';
import { DataSourceLayoutNameCellDsar } from '../GridHelpers/DataSourceLayoutNameCellDsar';
import { DataSourceModel } from '../../../DataSources/DataSourceConnections/DataSourceConnectionTypes';
import { DsarSettingsTrackingEvents, trackDsar } from '../../analyticsUtils';
import { DsarEvents, dsarEventEmitter } from '../dsarEvents';
import { GridChip } from '../GridHelpers/GridChipDsar';
import { GridEditableChipAreaDsar } from '../GridHelpers/GridEditableChipAreaDsar';
import { DsarDictionary } from '../../DsarTypes';
import { useFetchEntity } from '../GridHelpers/useFetchEntity';

const GridWrapper = styled('div')`
  background-color: ${BigidColorsV2.white};
  border-radius: 4px;
  border: 1px solid ${BigidColorsV2.gray[300]};
  width: 100%;
  display: flex;
  position: relative;
  height: 100%;
  max-height: 100%;
  overflow: hidden;
`;

const MASKING_VALUE = '*******';

type EditCellDialogState = {
  isOpen: boolean;
  columnName: ColumnName;
  field: SarFieldsSettings;
  onSubmit?: () => void;
  onClose?: () => void;
};
type Grid = BigidGridWithToolbarProps<SarFieldsSettings>;
type FieldsProps = Pick<SarProfile, '_id' | 'dsConnections'>;
type DsNameTypeMapper = Record<DataSource['name'], DataSource['type']>;
const getChipsCellValueOptimized = (name: ColumnName, row: SarFieldsSettings) => (
  <GridEditableChipAreaDsar columnName={name} row={row} />
);

const getColumns = (dsNameTypeMapper: DsNameTypeMapper): BigidGridColumn<SarFieldsSettings>[] => [
  {
    width: 170,
    title: 'Field Name',
    name: 'field_name',
    type: BigidGridColumnTypes.TEXT,
    getCellValue: ({ fieldName }) => fieldName,
    filteringEnabled: false,
    sortingEnabled: true,
    disableTooltip: true,
  },
  {
    width: 120,
    title: 'Selection',
    name: 'is_included_in_report',
    type: BigidGridColumnTypes.CUSTOM,
    getCellValue: ({ isIncludedInReport }) => {
      const { chip } = getCellValueForIncludeExclude(isIncludedInReport);
      return <GridChip {...chip} />;
    },
    filteringEnabled: false,
    sortingEnabled: false,
    disableTooltip: true,
  },
  {
    width: 290,
    title: ColumnName.FRIENDLY_NAME,
    name: 'customFriendlyNameValue',
    type: BigidGridColumnTypes.CUSTOM,
    getCellValue: row => getChipsCellValueOptimized(ColumnName.FRIENDLY_NAME, row),
    filteringEnabled: false,
    sortingEnabled: false,
    disableTooltip: true,
  },
  {
    width: 170,
    title: 'Dictionary',
    name: 'dictionaryName',
    type: BigidGridColumnTypes.TEXT,
    getCellValue: ({ dictionaryName }) => dictionaryName,
    filteringEnabled: false,
    sortingEnabled: false,
    disableTooltip: true,
  },
  {
    width: 290,
    title: ColumnName.PURPOSE_OF_USE,
    name: 'custom_purpose_of_use_value',
    type: BigidGridColumnTypes.CUSTOM,
    getCellValue: row => getChipsCellValueOptimized(ColumnName.PURPOSE_OF_USE, row),
    filteringEnabled: false,
    sortingEnabled: false,
    disableTooltip: true,
  },
  {
    width: 170,
    title: 'Data Source',
    name: 'data_source',
    type: BigidGridColumnTypes.CUSTOM,
    getCellValue: ({ dataSource: name }) => {
      const type = dsNameTypeMapper?.[name];
      return (
        <DataSourceLayoutNameCellDsar
          row={{ id: name, type: type, displayType: name } as DataSourceModel}
          iconWidth={24}
        />
      );
    },
    filteringEnabled: false,
    sortingEnabled: false,
    disableTooltip: true,
  },
  {
    width: 210,
    title: 'Full Object Name',
    name: 'full_object_name',
    type: BigidGridColumnTypes.TEXT,
    getCellValue: ({ fullObjectName }) => fullObjectName,
    filteringEnabled: false,
    sortingEnabled: true,
    disableTooltip: true,
  },
  {
    width: 290,
    title: ColumnName.CATEGORY,
    name: 'custom_category_value',
    type: BigidGridColumnTypes.CUSTOM,
    getCellValue: row => getChipsCellValueOptimized(ColumnName.CATEGORY, row),
    filteringEnabled: false,
    sortingEnabled: false,
    disableTooltip: true,
  },
  {
    width: 160,
    title: 'Sample Values',
    name: 'sample_values',
    type: BigidGridColumnTypes.TEXT,
    getCellValue: ({ sampleValues, maskIt }) =>
      maskIt && sampleValues.length ? MASKING_VALUE : sampleValues.slice(0, 3).join(', '),
    filteringEnabled: false,
    sortingEnabled: false,
    disableTooltip: true,
  },
  {
    width: 150,
    title: 'Field Type',
    name: 'attribute_type',
    type: BigidGridColumnTypes.TEXT,
    getCellValue: ({ attributeType }) => attributeType,
    filteringEnabled: false,
    sortingEnabled: false,
    disableTooltip: true,
  },
  {
    width: 140,
    title: 'Mask',
    name: 'mask_operation',
    type: BigidGridColumnTypes.TEXT,
    getCellValue: ({ maskOperation }) => getCellValueForMaskValue(maskOperation),
    filteringEnabled: false,
    sortingEnabled: false,
    disableTooltip: true,
  },
  {
    width: 210,
    title: 'Include in Proximity Category',
    name: 'is_included_in_proximity_category',
    type: BigidGridColumnTypes.CHIP,
    getCellValue: ({ isIncludedInProximityCategory, attributeType }) =>
      getCellValueForIncludeInProximityCategory(isIncludedInProximityCategory, attributeType),
    filteringEnabled: false,
    sortingEnabled: false,
    disableTooltip: true,
  },
];

export const FieldsSettings: FC<FieldsProps> = ({ _id: profileId, dsConnections }) => {
  const [isExportInProgress, setIsExportInProgress] = useState<boolean>(false);
  const [isImportDialogOpen, setImportDialogOpen] = useState<boolean>(false);
  const [syncInProgress, setSyncInProgress] = useState(false);
  const [syncData, setSyncData] = useState<SynchronizationEntity>();
  const [gridCount, setGridCount] = useState(0);
  const [gridSelectedRowsCount, setGridSelectedRowsCount] = useState(0);
  const timeoutRef = useRef<ReturnType<typeof setTimeout>>(null);
  const gridFilter = useRef(null);
  const dictionaries = useFetchEntity(fetchDictionaries, { skipFetching: !dsConnections });
  const [editCellDialogState, setEditCellDialogState] = useState<EditCellDialogState>({
    isOpen: false,
    columnName: '' as ColumnName,
    field: {} as SarFieldsSettings,
  });
  const dsNameTypeMapper = useMemo(() => {
    return dsConnections?.reduce((acc, ds) => ((acc[ds.name] = ds.type), acc), {} as DsNameTypeMapper);
  }, [dsConnections]);
  const editFieldsDialogHook = useEditFieldsDialog();

  const getInitialFilterToolbarConfig = useCallback(
    () => getFilterConfig(dsConnections, dictionaries),
    [dsConnections, dictionaries],
  );
  const initialColumns = useMemo(() => getColumns(dsNameTypeMapper), [dsNameTypeMapper]);
  const { isReady, preferences, gridColumns, filterToolbarConfig, key, updatePreferences } = useUserPreferences({
    stateName: NEW_DSAR_SETTINGS_STATE_NAMES.FIELDS,
    initialGridColumns: initialColumns,
    getInitialFilterToolbarConfig,
  });

  const handleOnImportClicked = () => {
    setImportDialogOpen(true);
  };

  const handleImportDialogClose = () => {
    setImportDialogOpen(false);
  };

  const triggerSync = useCallback(async () => {
    const { synchronization = {} as SynchronizationEntity, name } = await fetchSyncData(profileId);
    setSyncData(synchronization);
    const isInProgress = [FieldsEnrichmentActionType.ON_PROGRESS, FieldsEnrichmentActionType.START].includes(
      synchronization?.status,
    );
    setSyncInProgress(syncInProgress => {
      if (syncInProgress && !isInProgress) {
        const syncFailed = isSyncFailed(synchronization?.status);
        const statusResult = getStatusMessage(synchronization?.status);
        const notification = syncFailed ? notificationService.error : notificationService.success;
        notification(`Synchronization of profile "${name}" from the data catalog ${statusResult}`);
      }
      return isInProgress;
    });
    if (isInProgress) {
      timeoutRef.current = setTimeout(triggerSync, 10000);
      entityEventsEmitter.emit(EntityEvents.RELOAD);
    }
  }, [profileId]);

  useEffect(() => {
    const eventHandler = async (row: SarFieldsSettings, columnName: string) => {
      const closeDialog = () =>
        setEditCellDialogState(state => ({
          ...state,
          isOpen: false,
        }));
      setEditCellDialogState({
        isOpen: true,
        columnName: columnName as ColumnName, // i.e. Friendly Name / Purpose of use / Category
        field: row,
        onSubmit: () => {
          closeDialog();
          entityEventsEmitter.emit(EntityEvents.RELOAD);
        },
        onClose: () => {
          closeDialog();
        },
      });
    };
    dsarEventEmitter.addEventListener(DsarEvents.OPEN_EDIT_CELL_DIALOG, eventHandler);

    const onImportDialogOpen = () => {
      handleOnImportClicked();
      trackDsar(DsarSettingsTrackingEvents.PROFILE_FIELDS_IMPORT_ACTION);
    };

    dsarEventEmitter.addEventListener(DsarEvents.OPEN_IMPORT_FIELDS_DIALOG, onImportDialogOpen);

    triggerSync();

    return () => {
      dsarEventEmitter.removeEventListener(DsarEvents.OPEN_EDIT_CELL_DIALOG, eventHandler);
      dsarEventEmitter.removeEventListener(DsarEvents.OPEN_IMPORT_FIELDS_DIALOG, onImportDialogOpen);
      clearTimeout(timeoutRef.current);
    };
  }, [profileId, triggerSync]);

  const maxSize: number = getApplicationPreference('MAX_UPLOAD_LIMITATION');

  const importFile = async (file: File) => {
    setIsExportInProgress(false);

    const sseActionCallback = ({ operationStatus, message }: SSEDataMessage) => {
      if (operationStatus === SSEOperationStatus.BLOCKED_STRING) {
        notificationService.warning(message);
      } else if (operationStatus === SSEOperationStatus.ERROR_STRING) {
        notificationService.error(message);
      } else {
        entityEventsEmitter.emit(EntityEvents.RELOAD, [], true);
        notificationService.success(message);
      }
    };

    const {
      data: { message },
    } = await sendSSERequestWithActionCallback<{ message: string }>(
      'post',
      `/sar/profiles/${profileId}/fields/import`,
      {
        files: { file },
      },
      {},
      sseActionCallback,
    );

    notificationService.success(message);
  };

  const gridWithToolbarConfig: BigidGridWithToolbarProps<SarFieldsSettings> = useMemo(() => {
    const handleOnExportConfigClicked = async ({ allSelected, filter: gridFilter, selectedRowIds }: ActionData) => {
      try {
        setIsExportInProgress(true);
        const filter = getQueryFilter(allSelected, gridFilter, selectedRowIds);
        await dsarService.exportFields(profileId, filter);
      } catch (e) {
        notificationService.error(`Could not download file ${e.message}`);
        console.error(e);
      } finally {
        setIsExportInProgress(false);
      }
    };

    const includeExcludeExecutor =
      (isIncludedInReport: boolean) =>
      async ({ allSelected, filter, selectedRowIds }: ActionData) => {
        if (!selectedRowIds.length) return {};

        const isIncludedInProximityCategory = isIncludedInReport ? undefined : false;
        await updateSources(
          profileId,
          { isIncludedInReport, isIncludedInProximityCategory },
          selectedRowIds.map(id => ({ id: id as string })),
          allSelected,
          filter as BigidFieldFilter[],
        );
        return { shouldGridReload: true, shouldClearSelection: true };
      };

    const handleSyncClick = async ({ allSelected, filter: gridFilter, selectedRowIds }: ActionData) => {
      if (syncInProgress) {
        return;
      }

      setSyncInProgress(true);
      setSyncData(syncData => ({ ...syncData, completed: 0, failed: 0, total: 1 }));
      try {
        const filter = getQueryFilter(allSelected, gridFilter, selectedRowIds);
        const {
          data: { message },
        } = await dsarService.syncFieldsWithCatalog(profileId, filter);
        triggerSync();
        notificationService.success(message);
      } catch (err) {
        notificationService.error('Failed to synchronize DSAR with data catalog');
        console.error(`Failed to synchronize DSAR with data catalog: ${JSON.stringify(err)}`);
        setSyncInProgress(false);
      }
      return { shouldClearSelection: true };
    };

    const getExportAction = (isLeftAction = false): ToolbarAction => ({
      label: 'Export',
      icon: BigidExportIcon,
      tooltip: `Export Results (${isLeftAction ? gridSelectedRowsCount : gridCount})`,
      type: isLeftAction ? ToolbarActionType.TERTIARY : ToolbarActionType.ACTION_ICON,
      execute: async (actionData: ActionData) => {
        handleOnExportConfigClicked(actionData);
        return { shouldGridReload: false, shouldClearSelection: true };
      },
      disable: () => isExportInProgress,
      show: ({ totalRows, selectedRowIds }: ActionData) =>
        (isLeftAction ? hasSelectedRows(selectedRowIds) : !hasSelectedRows(selectedRowIds)) &&
        isPermitted(DSAR_PERMISSIONS.EXPORT_PROFILE_SETTINGS.name) &&
        !isGridEmpty(totalRows),
      placement: isLeftAction ? 'start' : 'end',
      bi: {
        eventType: DsarSettingsTrackingEvents.PROFILE_FIELDS_EXPORT_ACTION,
        eventData: {
          with_filter: !!gridFilter.current?.length,
        },
      },
    });

    const isGridEmpty = (totalRows: number) => totalRows === 0;
    const hasSelectedRows = (selectedRowIds: ReactText[]) => !!selectedRowIds.length;
    const isAllowed = isPermitted(DSAR_PERMISSIONS.EDIT_PROFILE_SETTINGS.name);

    const gridWithToolbarConfig: BigidGridWithToolbarProps<SarFieldsSettings> = {
      showFilteringControls: true,
      columns: gridColumns,
      entityName: 'fields',
      pageSize: 150,
      defaultSorting: preferences?.grid?.sort || [{ field: 'name', order: 'asc' }],
      onGridStateChange: ({ filter, ...gridState }) => {
        updatePreferences({ filterState: { filter }, gridState });
        setGridSelectedRowsCount(gridState.selectedRowIds.length);
      },
      toolbarActions: [
        {
          label: 'Include',
          type: ToolbarActionType.TERTIARY,
          icon: BigidAddContainedIcon,
          execute: includeExcludeExecutor(true),
          show: ({ totalRows, selectedRowIds }) =>
            hasSelectedRows(selectedRowIds) && !isGridEmpty(totalRows) && isAllowed,
          bi: {
            eventType: DsarSettingsTrackingEvents.PROFILE_FIELDS_INCLUDE_ACTION,
          },
        },
        {
          label: 'Exclude',
          type: ToolbarActionType.TERTIARY,
          icon: BigidRemoveIcon,
          execute: includeExcludeExecutor(false),
          show: ({ totalRows, selectedRowIds }) =>
            hasSelectedRows(selectedRowIds) && !isGridEmpty(totalRows) && isAllowed,
          bi: {
            eventType: DsarSettingsTrackingEvents.PROFILE_FIELDS_EXCLUDE_ACTION,
          },
        },
        {
          label: 'Edit',
          type: ToolbarActionType.TERTIARY,
          icon: BigidEditIcon,
          execute: async ({ selectedRowIds, selectedRows, allSelected, totalRows, filter }) => {
            if (!selectedRowIds.length) return {};
            // fix BigidGrid bug related to `rowsCache`
            selectedRows = selectedRowIds.map(rowId => {
              return (selectedRows as SarFieldsSettings[]).find(({ id }) => id === rowId) || { id: rowId };
            });
            const actionResult = await editFieldsDialogHook.openDialog(
              profileId,
              selectedRows,
              allSelected,
              totalRows,
              filter as BigidFieldFilter[],
            );
            return { shouldClearSelection: actionResult, shouldGridReload: actionResult };
          },
          show: ({ selectedRowIds }) => isAllowed && hasSelectedRows(selectedRowIds),
          bi: {
            eventType: DsarSettingsTrackingEvents.PROFILE_FIELDS_EDIT_ACTION,
          },
        },
        getExportAction(false),
        getExportAction(true),
        {
          isGlobal: true,
          icon: BigidSyncIcon,
          type: ToolbarActionType.TERTIARY,
          label: 'Sync from Catalog',
          tooltip: syncInProgress ? 'Sync already in progress' : getSyncLastScanLabel(syncData),
          execute: handleSyncClick,
          disable: () => syncInProgress,
          show: ({ totalRows, selectedRowIds }) =>
            hasSelectedRows(selectedRowIds) && !!syncData && !isGridEmpty(totalRows),
          bi: {
            eventType: DsarSettingsTrackingEvents.PROFILE_FIELDS_SYNC_ACTION,
            eventData: {
              with_filter: !!gridFilter.current?.length,
            },
          },
        },
      ],
      filterToolbarConfig,
      fetchData: async gridQueryParams => {
        try {
          gridFilter.current = gridQueryParams.filter;
          const { objects, totalCount } = await getFields(profileId, gridQueryParams);
          setGridCount(totalCount);
          return {
            data: objects,
            totalCount,
          };
        } catch (err) {
          notificationService.error(`Failed to get Fields Settings.`);
          console.error(`Failed to get SAR Request profile by id '${profileId}': ${JSON.stringify(err)}`);
          return { data: [], totalCount: 0 };
        }
      },
      syncButtonProps: {
        isSyncEnabled: !!syncData,
        onSyncClick: handleSyncClick,
        show: ({ totalRows, selectedRows }) =>
          (selectedRows?.length === 0 && !isGridEmpty(totalRows)) || syncInProgress,
        isSyncControlled: true,
        isSyncing: syncInProgress,
        syncIdleModeTooltip: getSyncLastScanLabel(syncData),
        syncProgressModeTooltip: (
          <BigidProgressBar
            percentage={getSyncProgress(syncData)}
            label="Sync progress"
            status={BigidProgressBarStatus.ACTING}
          />
        ),
        bi: {
          actionType: 'action',
          eventType: DsarSettingsTrackingEvents.PROFILE_FIELDS_SYNC_ACTION,
          eventData: {
            with_filter: !!gridFilter.current?.length,
          },
        },
      },
    };

    return gridWithToolbarConfig;
  }, [
    gridColumns,
    preferences?.grid?.sort,
    syncInProgress,
    syncData,
    filterToolbarConfig,
    profileId,
    triggerSync,
    gridCount,
    isExportInProgress,
    updatePreferences,
    editFieldsDialogHook,
  ]);

  return (
    <Fragment>
      <BigidImportDialog
        isOpen={isImportDialogOpen}
        onClose={handleImportDialogClose}
        onImportClicked={importFile}
        title={'Import Report Settings'}
        description={'Imported Items will be added to the list. Existing Items will not be affected.'}
        accept={['.csv']}
        maxSize={maxSize}
      />
      <EditFieldsCellDialog {...editCellDialogState} />
      <EditFieldsDialog {...editFieldsDialogHook.dialogProps} />
      {isReady && (
        <GridWrapper>
          <BigidGridWithToolbar key={key} {...gridWithToolbarConfig} />
        </GridWrapper>
      )}
    </Fragment>
  );
};

const getFilterConfig = async (
  dsConnections: DataSource[] = [],
  dictionaries: DsarDictionary[] = [],
): Promise<Grid['filterToolbarConfig']> => {
  return {
    filters: [
      {
        title: 'Field Type',
        field: 'attribute_type',
        operator: 'in',
        value: [],
        options: [
          { value: FieldType.CLASSIFICATION, label: FieldType.CLASSIFICATION, isSelected: false },
          { value: FieldType.ENTITY_SOURCE, label: FieldType.CORRELATION, isSelected: false },
          { value: FieldType.PROXIMITY, label: FieldType.PROXIMITY, isSelected: false },
        ],
      },
      {
        title: 'Data Source',
        field: 'data_source',
        operator: 'in',
        listWidth: 400,
        value: [],
        options: dsConnections.map(ds => ({ value: ds.name, label: ds.name, isSelected: false })),
      },
      {
        title: 'Selection',
        field: 'is_included_in_report',
        operator: 'equal',
        single: true,
        value: [],
        options: [
          { value: true, label: 'Included', isSelected: false },
          { value: false, label: 'Excluded', isSelected: false },
        ],
      },
      {
        title: 'Dictionary',
        field: 'dictionary_id',
        operator: 'in',
        listWidth: 400,
        value: [],
        options: dictionaries.map(dict => ({ value: dict.id, label: dict.name, isSelected: false })),
      },
    ],
    searchConfig: {
      searchFilterKeys: ['field_name', 'fully_qualified_object_name'],
      initialValue: '',
      operator: 'textSearch',
    },
  };
};

const fetchDictionaries = async () => {
  try {
    const response = await getDictionaries({ skip: 0, limit: 10000 });
    return response.objects;
  } catch (err) {
    const errorMessage = 'Failed to fetch Dictionaries';
    notificationService.error(errorMessage);
    console.error(`${errorMessage}: ${JSON.stringify(err)}`);
    return [];
  }
};

const fetchSyncData = async (profileId: string) => {
  try {
    const response = await getProfileById(
      profileId,
      {},
      {
        excludeEsConnections: true,
        excludeDsConnections: true,
        exposeSyncData: true,
      },
    );
    return { synchronization: response.synchronization, name: response.name };
  } catch (err) {
    const errorMessage = 'Failed to fetch profile';
    notificationService.error(errorMessage);
    console.error(`${errorMessage}: ${JSON.stringify(err)}`);
    return {};
  }
};

const getSyncProgress = (syncData: SynchronizationEntity) => {
  if (!syncData) {
    return 0;
  }
  const { completed = 0, failed = 0, total = 1 } = syncData;
  const percentage = Math.round(((completed + failed) / total) * 100);
  return percentage;
};

const toDate = (date: Date) => format(new Date(date), 'MMM dd, yyyy hh:mm');

const getSyncLastScanLabel = (syncData: SynchronizationEntity) => {
  const label = 'Sync from Catalog';
  if (!syncData || !syncData.completedAt) {
    return label;
  }
  const lastScanTimestamp = syncData.completedAt;
  const status = getStatusMessage(syncData.status);
  return `${label}
  --------------------
  The last sync ${status} on: ${toDate(lastScanTimestamp)}
  `;
};

const isSyncFailed = (status: string) => status === FieldsEnrichmentActionType.FAILURE;

const getStatusMessage = (status: string) => (isSyncFailed(status) ? 'failed' : 'completed');
