import React, { FC, useReducer, useState } from 'react';
import {
  BigidColorsV2,
  BigidDropdown,
  BigidDropdownOption,
  BigidDropdownValue,
  BigidRadio,
  PrimaryButton,
  SecondaryButton,
} from '@bigid-ui/components';
import { generateDataAid } from '@bigid-ui/utils';
import { CreateCategory, CategoryAssignModes, DetailsActionsTypes, CategoryDetailsActions } from './types';
import { CreateNewCategory } from './CreateNewCategory';
import { SystemDialogContentProps } from '../../services/systemDialogService';
import { useLocalTranslation } from './translations';
import { styled } from '@mui/material';

const FormContainer = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding: 8px 8px 0 8px;
`;

const ButtonContainer = styled('div')`
  display: flex;
  gap: 8px;
  align-self: flex-end;
`;

const defaultCategoryDetails: CreateCategory = {
  color: BigidColorsV2.yellow[500],
  description: '',
  name: '',
};

const categoryDetailsReducer = (state: CreateCategory, action: CategoryDetailsActions) => {
  switch (action.type) {
    case DetailsActionsTypes.SET_CATEGORY_DETAILS_FIELD:
      return {
        ...state,
        [action.fieldName]: action.value,
      };
    case DetailsActionsTypes.SET_CATEGORY_DETAILS:
      return action.payload;
    case DetailsActionsTypes.RESET_CATEGORY_DETAILS:
      return defaultCategoryDetails;
    default:
      return state;
  }
};

export interface AssignCategoryDialogProps {
  initialSelectedCategories: BigidDropdownValue;
  categories: BigidDropdownOption[];
  onSave: (
    selectedCategories: BigidDropdownValue,
    categoryAssignMode: CategoryAssignModes,
    formData: CreateCategory,
  ) => void;
}

export const AssignCategoryDialog: FC<SystemDialogContentProps<AssignCategoryDialogProps>> = ({
  initialSelectedCategories = [],
  categories,
  onClose,
  onSave,
}) => {
  const { t } = useLocalTranslation();
  const [selectedCategories, setSelectedCategories] = useState<BigidDropdownValue>(initialSelectedCategories);
  const [categoryAssignMode, setCategoryAssignMode] = useState<CategoryAssignModes>(CategoryAssignModes.EXISTING);

  const [state, dispatchState] = useReducer(categoryDetailsReducer, defaultCategoryDetails);

  const handleSave = () => {
    onSave(selectedCategories, categoryAssignMode, state);
  };

  const isAssignExisting = categoryAssignMode === CategoryAssignModes.EXISTING;
  const isAssignNew = categoryAssignMode === CategoryAssignModes.NEW;
  const shouldSubmitDisabled = isAssignNew && !state.name.trim();

  return (
    <FormContainer>
      <BigidRadio
        label={t('ExistingCategory.radioActionLabel')}
        dataAid={generateDataAid('AssignCategoryDialogContent', ['existingCategoryRadio'])}
        checked={isAssignExisting}
        onChange={() => setCategoryAssignMode(CategoryAssignModes.EXISTING)}
      />
      <BigidDropdown
        value={selectedCategories}
        onSelect={setSelectedCategories}
        options={categories}
        isMulti
        isDisabled={isAssignNew}
        placeholder={t('ExistingCategory.dropdownPlaceholder')}
      />
      <BigidRadio
        label={t('NewCategory.radioActionLabel')}
        dataAid={generateDataAid('AssignCategoryDialogContent', ['newCategoryRadio'])}
        checked={isAssignNew}
        onChange={() => setCategoryAssignMode(CategoryAssignModes.NEW)}
      />
      <CreateNewCategory disabled={isAssignExisting} categoryState={state} dispatchCategoryAction={dispatchState} />
      <ButtonContainer>
        <SecondaryButton size="medium" onClick={onClose} text={t('closeButtonText')} />
        <PrimaryButton
          size="medium"
          onClick={handleSave}
          disabled={shouldSubmitDisabled}
          text={t('submitButtonText')}
        />
      </ButtonContainer>
    </FormContainer>
  );
};
