import './alertMessage.component.scss';
import template from './alertMessage.component.html';
import { module } from 'angular';
const app = module('app');

app.component('alertMessage', {
  template,
  controllerAs: 'alertMessageModel',
  controller: [
    function () {
      let alertMessageModel = this;

      alertMessageModel.$onInit = () => {
        alertMessageModel.textMessage = this.textMessage;
        alertMessageModel.urlRef = this.urlRef;
        alertMessageModel.urlMessage = this.urlMessage;
        alertMessageModel.type = this.type;

        alertMessageModel.pickStyleType(alertMessageModel.type);
      };

      alertMessageModel.pickStyleType = type => {
        switch (type) {
          case 'info':
            alertMessageModel.typeClassName = 'alert-info';
            break;
          case 'warning':
            alertMessageModel.typeClassName = 'alert-warning';
            break;
          case 'error':
            alertMessageModel.typeClassName = 'alert-error';
            break;
        }
      };

      alertMessageModel.closeMessageAlert = () => {
        alertMessageModel.closeMessageAlert();
      };
    },
  ],
  bindings: {
    textMessage: '<',
    urlRef: '<',
    urlMessage: '<',
    type: '<',
    closeMessageAlert: '&',
  },
});
