import React, { FC } from 'react';
import { BigidLayoutEmptyState } from '@bigid-ui/layout';
import { AddBoxOutlined } from '@mui/icons-material';
import { BigidVaultIllustration } from '@bigid-ui/icons';
import { useLocalTranslation } from './translations';
import { isPermitted } from '../../services/userPermissionsService';
import { CREDENTIAL_PROVIDERS_PERMISSIONS } from '@bigid/permissions';

interface CertificatesManagementEmptyStateProps {
  handleAddCredentialProvider: () => void;
}

export const CredentialProvidersEmptyState: FC<CertificatesManagementEmptyStateProps> = ({
  handleAddCredentialProvider,
}) => {
  const { t } = useLocalTranslation();

  const emptyStateActions = [
    {
      execute: async () => {
        handleAddCredentialProvider();
        return { shouldGridReload: false };
      },
      icon: AddBoxOutlined,
      label: t('buttons.addProvider'),
      show: () => isPermitted(CREDENTIAL_PROVIDERS_PERMISSIONS.CREATE.name),
    },
  ];

  return (
    <BigidLayoutEmptyState
      actions={emptyStateActions}
      illustration={BigidVaultIllustration}
      description={t('message.manageCredentialProviders')}
    />
  );
};
