import React, { ChangeEvent, useEffect, useState } from 'react';
import { BigidBody1, BigidDialog, BigidTextField, PrimaryButton, SecondaryButton } from '@bigid-ui/components';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles({
  timePeriodContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: 8,
    justifyContent: 'start',
    width: '165px',
    marginTop: 8,
  },
  scheduleMargin: {
    marginTop: 16,
  },
});

export interface ActivateDeletionValidationDialogProps {
  isOpen: boolean;
  onClose: () => void;
  scheduleDurationInitialValue: number;
  onDeletionValidationSubmit: (duration: number) => void;
}

export const ActivateDeletionValidationDialog: React.FC<ActivateDeletionValidationDialogProps> = ({
  isOpen,
  onClose,
  scheduleDurationInitialValue,
  onDeletionValidationSubmit,
}) => {
  const classes = useStyles();
  const [durationValue, setDurationValue] = useState(scheduleDurationInitialValue);

  useEffect(() => {
    setDurationValue(scheduleDurationInitialValue);
  }, [scheduleDurationInitialValue]);

  const onDurationValueChange = ({ target }: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    setDurationValue(parseInt(target.value));
  };

  return (
    <BigidDialog
      title={'Activate Deletion Validation'}
      isOpen={isOpen}
      onClose={() => {
        setDurationValue(scheduleDurationInitialValue);
        onClose();
      }}
      borderTop
      borderBottom
      buttons={[
        {
          text: 'Cancel',
          component: SecondaryButton,
          onClick: () => {
            setDurationValue(scheduleDurationInitialValue);
            onClose();
          },
          isClose: true,
          dataAid: 'DsarDeletionValidationDialogCancelButton',
        },
        {
          text: 'Submit',
          component: PrimaryButton,
          onClick: () => {
            onDeletionValidationSubmit(durationValue);
          },
          disabled: !durationValue,
          dataAid: 'DsarDeletionValidationDialogSubmitButton',
        },
      ]}
    >
      <BigidBody1>
        Deletion Validation re-runs deletion scripts periodically to verify that BigID removes residual personal data
        for deleted individuals. By activating this feature you agree that BigID will preserve some encrypted personal
        information to enable this process.
      </BigidBody1>
      <span className={classes.scheduleMargin}>Schedule</span>
      <div className={classes.timePeriodContainer}>
        <span>Every</span>
        <BigidTextField type={'number'} value={durationValue.toString()} onChange={onDurationValueChange} />
        <span>Days</span>
      </div>
    </BigidDialog>
  );
};
