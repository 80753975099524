import React, { useContext, useMemo, useCallback } from 'react';
import { isEqual } from 'lodash';
import { ScanTemplateWizardContext } from '../../../scanTemplateContext';
import { ScanTemplate, ScanTypes } from '../../../scanTemplateTypes';
import { getFields } from './fields';
import { getScanTypeLabel } from '../../../../ScanUtils';
import {
  BigidFormRenderProps,
  BigidFormValues,
  BigidFormFieldTypes,
  BigidFormFieldLabelPosition,
} from '@bigid-ui/components';
import { convertUsersToFilterOptions } from '../../../../../../utilities/systemUsersUtils';
import { ScanTypeDescription } from './ScanTypeDescription';
import styled from '@emotion/styled';

const ContainerLabelingCheckboxes = styled('div')`
  display: flex;
  gap: 24px;
  margin-bottom: 12px;
`;

const DEFAULT_SCAN_TYPE = {
  label: getScanTypeLabel(ScanTypes.DS_SCAN),
  value: ScanTypes.DS_SCAN,
};

const convertFormValueToScanTemplateValue = (values: BigidFormValues) => {
  if (values?.scanType) {
    values.scanType = values?.scanType?.[0]?.value;
  }
  if (values?.labelFramework?.[0]) {
    const { value: id, label: name } = values?.labelFramework?.[0] ?? {};
    values.labelFramework = { id, name };
  }
  if (values?.owner?.[0]?.value) {
    values.owner = values.owner?.[0]?.value;
  }

  return values;
};

const convertScanTemplateValueToFormValue = (
  values: ScanTemplate,
  supportedScanTypes: ScanTypes[],
): BigidFormValues => {
  const initValues: BigidFormValues = { ...values };

  if (values?.scanType) {
    initValues.scanType = [
      {
        label: getScanTypeLabel(values.scanType),
        value: values.scanType,
      },
    ];
  } else {
    initValues.scanType = [DEFAULT_SCAN_TYPE];
  }
  if (supportedScanTypes && !supportedScanTypes.includes(initValues.scanType[0].value)) {
    initValues.scanType = [
      {
        label: getScanTypeLabel(supportedScanTypes[0]),
        value: supportedScanTypes[0],
      },
    ];
  }
  if (initValues.owner) {
    initValues.owner = convertUsersToFilterOptions([{ ...initValues.owner, name: initValues.owner.username }], true);
  }

  if (values?.labelFramework) {
    const { id, name } = values?.labelFramework;
    initValues.labelFramework = [{ value: id, label: name }];
  }
  return initValues;
};

export const useBasicDetailsFormProps = (supportedScanTypes?: ScanTypes[]) => {
  const { scanTemplateFormData, setScanTemplateFormData, basicDetailsHandlersRef, setPrevScanType } =
    useContext(ScanTemplateWizardContext);
  const fields = useMemo(() => getFields(supportedScanTypes), [supportedScanTypes]);
  const fieldsForLabeling = useMemo(
    () => [
      {
        name: 'isOverwriteTag',
        label: 'Overwrite existing labels',
        type: BigidFormFieldTypes.CHECKBOX,
        labelPosition: BigidFormFieldLabelPosition.left,
      },
      {
        name: 'isOverwritePrivilegedLabels',
        label: 'Overwrite privileged labels',
        type: BigidFormFieldTypes.CHECKBOX,
        labelPosition: BigidFormFieldLabelPosition.left,
      },
    ],
    [],
  );

  const renderForm = useCallback(
    ({ renderField, getValues }: BigidFormRenderProps) => {
      const { scanType } = getValues();
      const scanTypeSelected = scanType?.[0].value;

      return (
        <>
          {fields.map(({ name }) => renderField(name))}
          {scanTypeSelected === ScanTypes.LABELING && (
            <ContainerLabelingCheckboxes data-aid="ContainerLabelingCheckboxes">
              {fieldsForLabeling.map(({ name }) => renderField(name))}
            </ContainerLabelingCheckboxes>
          )}
          <ScanTypeDescription scanType={scanTypeSelected} />
        </>
      );
    },
    [fields, fieldsForLabeling],
  );

  return useMemo(
    () => ({
      fields: [...fields, ...fieldsForLabeling],
      renderForm,
      initialValues: convertScanTemplateValueToFormValue(scanTemplateFormData, supportedScanTypes),
      controlButtons: false,
      stateAndHandlersRef: basicDetailsHandlersRef,
      onChange: (newValues: BigidFormValues) => {
        const templateUpdated = {
          ...scanTemplateFormData,
          ...convertFormValueToScanTemplateValue(newValues),
        };
        if (!isEqual(templateUpdated, scanTemplateFormData)) {
          setScanTemplateFormData(templateUpdated);
          setPrevScanType(scanTemplateFormData?.scanType as ScanTypes);
        }
      },
    }),
    [
      basicDetailsHandlersRef,
      fields,
      fieldsForLabeling,
      renderForm,
      scanTemplateFormData,
      setPrevScanType,
      setScanTemplateFormData,
      supportedScanTypes,
    ],
  );
};
