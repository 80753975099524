import React, { FC, useState, useEffect, Suspense, lazy, startTransition } from 'react';
import { ACTIONS, CallBackProps, EVENTS, STATUS, LIFECYCLE } from 'react-joyride';
import { BigidLoader } from '@bigid-ui/components';
import { actionableWorkspacesTourSelectors } from './WorkspacesGuidedTour';
import { analyticsService } from '../../../services/analyticsService';
import { BIGID_BI_EVENTS } from '../../../config/BigIdBiEvents';
import { userPreferencesService } from '../../../services/userPreferencesService';
import { useGuidedTourManager } from '../../../hooks/useGuidedTourManager';
import { isWorkspacesEnabled } from '../../../utilities/featureFlagUtils';

const isGuidedTourStepStarted = (data: CallBackProps) => {
  const { action, lifecycle } = data;
  return action === ACTIONS.START && lifecycle === LIFECYCLE.INIT;
};

const isGuidedTourSkipped = (data: CallBackProps) => {
  const { action } = data;
  return action === ACTIONS.SKIP;
};

const isGuidedTourStepFinished = (data: CallBackProps) => {
  const { action, lifecycle, status } = data;
  return action === ACTIONS.CLOSE && lifecycle === LIFECYCLE.INIT && status === STATUS.FINISHED;
};

const isGuidedTourGoBack = (data: CallBackProps) => {
  const { action, lifecycle, status } = data;
  return action === ACTIONS.PREV && lifecycle === LIFECYCLE.COMPLETE && status === STATUS.RUNNING;
};

const shouldLogGuidedTourEvent = (data: CallBackProps) => {
  return (
    isGuidedTourStepStarted(data) ||
    isGuidedTourSkipped(data) ||
    isGuidedTourStepFinished(data) ||
    isGuidedTourGoBack(data)
  );
};

const WORKSPACES_GUIDED_TOUR_USER_PREFERENCE = 'workspacesGuidedTour';

const WorkspacesGuidedTourComponent = lazy(() =>
  import(/* webpackChunkName: "WorkspacesGuidedTour" */ './WorkspacesGuidedTour').then(module => ({
    default: module.WorkspacesGuidedTour,
  })),
);

export const WORKSPACE_TOUR_LOCAL_STORAGE_KEY = 'WorkspaceGuidedTourCompleted';

const dispatchClickEventOnElementByQuerySelector = (selector: string): void => {
  const targetElement = document.querySelector(selector) as HTMLElement;

  const clickEvent = new MouseEvent('click', { bubbles: true });
  targetElement?.dispatchEvent(clickEvent);
};

export const WorkspacesGuidedTourLazy: FC<{ currentTour: string }> = ({ currentTour }) => {
  const [isTourRunning, setIsTourRunning] = useState(false);
  const [isComponentLoaded, setIsComponentLoaded] = useState(false);
  const [stepIndex, setStepIndex] = useState<number>(0);
  const { startTour, endTour } = useGuidedTourManager();
  const isCurrentTour = currentTour === 'WorkspacesGuidedTour';

  useEffect(() => {
    const startTourIfRequirementsSatisfied = async (): Promise<void> => {
      const preference = await userPreferencesService.get(WORKSPACES_GUIDED_TOUR_USER_PREFERENCE);
      const isTourCompleted = preference?.data;

      if (!isComponentLoaded) {
        startTransition(() => {
          setIsComponentLoaded(true);
        });
      }

      if (isWorkspacesEnabled() && isTourCompleted !== true) {
        setIsTourRunning(true);
        startTour('WorkspacesGuidedTour');
      }
    };

    startTourIfRequirementsSatisfied();
  }, [isComponentLoaded, startTour, isCurrentTour]);

  const getNextStepIndex = ({ index, action }: CallBackProps) => {
    const nextStepIndex = index + (action === ACTIONS.PREV ? -1 : 1);

    if (nextStepIndex === 1) {
      setIsTourRunning(false);
      setTimeout(() => {
        dispatchClickEventOnElementByQuerySelector(actionableWorkspacesTourSelectors.WORKSPACE_BUTTON);
      }, 400);

      setTimeout(() => {
        setStepIndex(nextStepIndex);
        setIsTourRunning(true);
      }, 600);
      return nextStepIndex;
    } else if (nextStepIndex === 2) {
      setTimeout(() => {
        dispatchClickEventOnElementByQuerySelector(actionableWorkspacesTourSelectors.NAVIGATION_BAR_BUTTON);
      }, 100);

      setIsTourRunning(false);
      setTimeout(() => {
        setStepIndex(nextStepIndex);
        setIsTourRunning(true);
      }, 700);
      return nextStepIndex;
    } else if (nextStepIndex === 3) {
      setTimeout(() => {
        dispatchClickEventOnElementByQuerySelector(actionableWorkspacesTourSelectors.NAVIGATION_BAR_BUTTON);
      }, 100);

      setIsTourRunning(false);
      setTimeout(() => {
        setStepIndex(nextStepIndex);
        setIsTourRunning(true);
      }, 700);
      return nextStepIndex;
    }

    return nextStepIndex;
  };

  const joyrideCallback = (data: CallBackProps) => {
    const { status, type, action, lifecycle, index, step } = data;
    if (shouldLogGuidedTourEvent(data)) {
      analyticsService.trackManualEvent(BIGID_BI_EVENTS.WORKSPACES_GUIDED_TOUR, {
        status,
        type,
        action,
        lifecycle,
        index,
        title: step?.title,
      });
    }
    if (status === STATUS.FINISHED || status === STATUS.SKIPPED) {
      setIsTourRunning(false);
      userPreferencesService.update({
        preference: WORKSPACES_GUIDED_TOUR_USER_PREFERENCE,
        data: true,
      });
      endTour();
    } else if (type === EVENTS.STEP_AFTER || type === EVENTS.TARGET_NOT_FOUND) {
      setStepIndex(getNextStepIndex(data));
    }
  };

  return (
    <>
      {isComponentLoaded && (
        <Suspense fallback={<BigidLoader position="fixed" />}>
          <WorkspacesGuidedTourComponent
            isTourRunning={isTourRunning && isCurrentTour}
            joyrideCallback={joyrideCallback}
            stepIndex={stepIndex}
          />
        </Suspense>
      )}
    </>
  );
};
