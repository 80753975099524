import React, { createContext } from 'react';

export type SetGuidedTourState = React.Dispatch<React.SetStateAction<boolean>>;

interface GuidedTourFromContext {
  manualStart: boolean;
  setManualStart: SetGuidedTourState;
}

export const GuidedTourContext = createContext<GuidedTourFromContext>(null);
