import { useCallback, useEffect, useRef, useState } from 'react';
import { DiscoveryMenuData, DiscoveryMenuOption } from '../FmsdDiscover';
import { dsTypes, updateOptionsWithProgress } from '../fmsdDiscoverServices';
import { CustomAppStatus } from '../../../CustomApp/views/ActivityLog/ActivityLog';
import { getLastRunInfo } from '../../../../services/autoDiscoveryService';
import { CloudProvider, SaaSProvider } from '../../../AutoDiscoveryWizard/autoDiscoveryWizardServices';
import { useFmsdDsWithStatus } from '../components/FmsdDiscoverExplorer/hooks/useFmsdDsWithStatus';
import { getFixedT } from '../../translations';

const t = getFixedT();

const PENDING_MESSAGE = t('Discover.pendingText');

export const cloudProviderLabel: Record<CloudProvider, string> = {
  [CloudProvider.AWS]: t('General.awsLabel'),
  [CloudProvider.AZURE]: t('General.azureLabel'),
};

export const saasConnectorsLabel: Record<SaaSProvider, string> = {
  [SaaSProvider.SAAS_CONNECTORS]: 'SaaS Connectors',
};

export interface AutodiscoveryAppData {
  discoveryAppIsLoading: boolean;
  activeOption: DiscoveryMenuOption;
  updateActiveOption: (options: string) => void;
  discoveryMenuData: DiscoveryMenuData;
}

export const useAutodiscoveryApp = (): AutodiscoveryAppData => {
  const [activeOption, setActiveOption] = useState<DiscoveryMenuOption>();
  const [discoveryAppIsLoading, setDiscoveryAppIsLoading] = useState<boolean>(true);
  const awsOptions = useRef<DiscoveryMenuOption[]>();
  const azureOptions = useRef<DiscoveryMenuOption[]>();
  const saasOptions = useRef<DiscoveryMenuOption[]>();
  const interval = useRef(null);
  const [discoveryMenuData, setDiscoveryMenuData] = useState<DiscoveryMenuData>({
    sections: [
      { sectionTitle: cloudProviderLabel[CloudProvider.AWS], provider: CloudProvider.AWS, options: [] },
      { sectionTitle: cloudProviderLabel[CloudProvider.AZURE], provider: CloudProvider.AZURE, options: [] },
      {
        sectionTitle: saasConnectorsLabel[SaaSProvider.SAAS_CONNECTORS],
        provider: SaaSProvider.SAAS_CONNECTORS,
        options: [],
      },
    ],
  });
  const { loadedDsWithStatus } = useFmsdDsWithStatus();

  const updateActiveOption = useCallback(
    (newValue: string) => {
      const newOption = [...awsOptions.current, ...azureOptions.current, ...saasOptions.current].find(
        option => option.value === newValue,
      );
      if (newOption) {
        setActiveOption(newOption);
      }
    },
    [awsOptions, azureOptions, saasOptions],
  );

  const generateDiscoverOptions = (type: CloudProvider | SaaSProvider): DiscoveryMenuOption[] => {
    return dsTypes[type].map(ds => {
      if (type === SaaSProvider.SAAS_CONNECTORS) {
        return { ...ds, progress: false };
      }
      return { ...ds, progress: PENDING_MESSAGE };
    });
  };

  const checkAndActivateAvailbleType = () => {
    const allOptions = [];

    for (const key in loadedDsWithStatus) {
      switch (key) {
        case 'salesforce':
          loadedDsWithStatus[key] && allOptions.push(...saasOptions.current);
          break;
        case 'azure':
          loadedDsWithStatus[key] && allOptions.push(...azureOptions.current);
          break;
        case 'aws':
          loadedDsWithStatus[key] && allOptions.push(...awsOptions.current);
          break;
      }
    }

    const preselectedOption = allOptions.find(option => option.progress === false);

    if (preselectedOption) setActiveOption(preselectedOption);
  };

  const updateProgress = useCallback(async () => {
    const awsUpdatedOptions = await updateOptionsWithProgress(CloudProvider.AWS, awsOptions.current);
    const azureUpdatedOptions = await updateOptionsWithProgress(CloudProvider.AZURE, azureOptions.current);
    awsOptions.current = awsUpdatedOptions;
    azureOptions.current = azureUpdatedOptions;
    setDiscoveryMenuData({
      sections: [
        {
          sectionTitle: cloudProviderLabel[CloudProvider.AWS],
          provider: CloudProvider.AWS,
          options: awsUpdatedOptions,
        },
        {
          sectionTitle: cloudProviderLabel[CloudProvider.AZURE],
          provider: CloudProvider.AZURE,
          options: azureUpdatedOptions,
        },
        {
          sectionTitle: saasConnectorsLabel[SaaSProvider.SAAS_CONNECTORS],
          provider: SaaSProvider.SAAS_CONNECTORS,
          options: saasOptions.current,
        },
      ],
    });
  }, []);

  useEffect(() => {
    const fetchState = async () => {
      awsOptions.current = generateDiscoverOptions(CloudProvider.AWS);
      azureOptions.current = generateDiscoverOptions(CloudProvider.AZURE);
      saasOptions.current = generateDiscoverOptions(SaaSProvider.SAAS_CONNECTORS);
      await updateProgress();
      setDiscoveryAppIsLoading(false);
      const awsLastRun = await getLastRunInfo(CloudProvider.AWS);
      const azureLastRun = await getLastRunInfo(CloudProvider.AZURE);
      if (interval.current) {
        clearInterval(interval.current);
      }
      if ([awsLastRun.status_enum, azureLastRun.status_enum].indexOf(CustomAppStatus.IN_PROGRESS) > -1) {
        interval.current = setInterval(() => updateProgress(), 5000);
      }
    };
    fetchState();
    return () => clearInterval(interval.current);
  }, [activeOption, updateProgress]);

  useEffect(() => {
    if (!activeOption && !discoveryAppIsLoading) checkAndActivateAvailbleType();
  }, [loadedDsWithStatus, discoveryAppIsLoading, activeOption, checkAndActivateAvailbleType]);

  return {
    discoveryAppIsLoading,
    activeOption,
    updateActiveOption,
    discoveryMenuData,
  };
};
