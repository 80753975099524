import React, { FC } from 'react';
import { BigidPaper } from '@bigid-ui/components';
import { OpenAccessFiles } from '../Files/OpenAccessFiles';

export const DataManager: FC = () => {
  return (
    <BigidPaper>
      <OpenAccessFiles />
    </BigidPaper>
  );
};
