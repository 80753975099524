import { BigidFormValues } from '@bigid-ui/components';
import {
  CHECKSUM_VALIDATION_TYPE,
  ChecksumValidation,
  FIELDS_NAMES,
  OOTBChecksumValidation,
  VALIDATION_TYPE,
} from './ChecksumValidationTypes';
import { isEmpty, omit } from 'lodash';
import { notificationService } from '../../../services/notificationService';
import { createClassifiersChecksumValidation } from '../../../services/classifiersService';
import { $translate } from '../../../services/angularServices';

const DUPLICATE_CHECKSUM_SUFFIX = 'ChecksumValidation';

export const duplicateChecksumByClassifierName = async (
  classificationName: string,
  checksumValidationName: string,
  checksumData: ChecksumValidation,
) => {
  const duplicateChecksumName = `${classificationName}_${DUPLICATE_CHECKSUM_SUFFIX}`;
  const checksumToDuplicate: ChecksumValidation = { ...omit(checksumData, ['_id']), validation: duplicateChecksumName };
  try {
    await createClassifiersChecksumValidation(checksumToDuplicate);
    notificationService.success($translate.instant('CLASSIFIERS:CHECKSUM:VALIDATION:API:MESSAGE:PUT_SUCCESS'));
    return duplicateChecksumName;
  } catch (error) {
    let errorMsg = $translate.instant('API:MESSAGE:COMMON_ERROR');
    if (error.response?.data?.message?.includes('E11000 duplicate key error')) {
      errorMsg = $translate.instant('API:MESSAGE:COMMON_DUPLICATE_KEY_ERROR', {
        name: 'checksum validation name',
      });
    }
    console.error(error);
    notificationService.error(errorMsg);
  }
  return null;
};

export const isNumberFieldValid = (value: string, isEmptyValid: boolean) => {
  if (isEmptyValid && (value === '' || value === null || value === undefined)) {
    return true;
  }
  const numericValue = parseInt(value);
  return numericValue >= 0;
};

export const isJsonFormatValid = (value: string, fieldName: string) => {
  if (fieldName === FIELDS_NAMES.CHECK_DIGIT_TABLE) {
    return value.match(
      /{(?:\s*['"]\s*[^'"]{1,10}\s*['"]\s*:\s*['"]\s*[^'"]{1,10}\s*['"]\s*,)*\s*['"][^'"]{1,10}\s*['"]\s*:\s*['"]\s*[^'"]{1,10}\s*['"]\s*}/gi,
    );
  } else
    return value.match(
      /{(?:\s*['"]\s*[^'"]{1,10}\s*['"]\s*:\s*[^'"]{1,10}\s*\s*,)*\s*['"]\s*[^'"]{1,10}\s*['"]\s*:\s*\s*[^'"]{1,10}\s*}/gi,
    );
};

export const getInitialOutOfTheBoxChecksum = (
  initialChecksumType: string,
  initialChecksum: ChecksumValidation,
  ootbChecksumsNamesList: OOTBChecksumValidation[],
) => {
  if (initialChecksum && initialChecksumType === CHECKSUM_VALIDATION_TYPE.OOTB) {
    const res = ootbChecksumsNamesList.find(checksum => checksum.value === initialChecksum.validation);
    return res !== undefined ? [res] : [];
  }
  return [];
};

export const convertInitialChecksumToFormFieldsValues = (initialChecksum: ChecksumValidation) => {
  let checkDigitConversionTable = '';
  let idNumberConversionTable = '';
  const checksumValidationName = initialChecksum?.algorithm ? initialChecksum?.validation : '';

  try {
    checkDigitConversionTable = JSON.stringify(initialChecksum?.checkDigitConversionTable);
    idNumberConversionTable = JSON.stringify(initialChecksum?.idNumberConversionTable);
  } catch (error) {
    console.error(error);
    notificationService.error(`Failed to convert JSON to string for checksum table form field. Returning empty value`);
  }
  const {
    algorithm = '',
    weights = '',
    nonValidCharsRegEx = '',
    validRegEx = '',
    moduloValue = 0,
    checkDigitLocation = 0,
    numberOfCheckDigits = 1,
    sumDigits = false,
    paddingToLength = 0,
    calculationConstant = 0,
    idNumberMapDimension = 0,
  } = initialChecksum || {};
  return {
    validation: checksumValidationName,
    algorithm,
    weights: weights.toString(),
    nonValidCharsRegEx: nonValidCharsRegEx,
    validRegEx: validRegEx,
    checkDigitConversionTable: checkDigitConversionTable,
    idNumberConversionTable: idNumberConversionTable,
    moduloValue: Number(moduloValue),
    checkDigitLocation: Number(checkDigitLocation),
    numberOfCheckDigits: Number(numberOfCheckDigits),
    sumDigits: sumDigits,
    paddingToLength: Number(paddingToLength),
    calculationConstant: Number(calculationConstant),
    idNumberMapDimension: Number(idNumberMapDimension),
  };
};

export const normalizeFormValues = (values: BigidFormValues): ChecksumValidation => {
  const isWeights = values?.algorithm === VALIDATION_TYPE.WeightedModulo;
  return Object.entries(values).reduce((result, [fieldName, fieldValue]) => {
    switch (fieldName) {
      case FIELDS_NAMES.CHECK_DIGIT_TABLE:
      case FIELDS_NAMES.ID_NUMBER_TABLE: {
        result[fieldName] = JSON.parse(fieldValue);
        break;
      }
      case FIELDS_NAMES.WEIGHTS:
        result[fieldName] = isWeights ? fieldValue.split(',').map((weight: string) => Number(weight)) : [];
        break;
      case FIELDS_NAMES.SUM_DIGITS:
        result[fieldName] = typeof fieldValue === 'boolean' ? fieldValue : Boolean(fieldValue === 'true');
        break;
      case FIELDS_NAMES.MODULO_VALUE:
      case FIELDS_NAMES.CHECK_DIGIT_LOCATION:
      case FIELDS_NAMES.NUMBER_OF_CHECK_DIGITS:
      case FIELDS_NAMES.PADDING_TO_LENGTH:
      case FIELDS_NAMES.CALCULATION_CONSTANT:
      case FIELDS_NAMES.ID_NUMBER_DIMENSION:
        result[fieldName] = Number(fieldValue);
        break;
      case FIELDS_NAMES.VALIDATION_NAME:
      case FIELDS_NAMES.ALGORITHM:
      case FIELDS_NAMES.NON_VALID_REGEX:
      case FIELDS_NAMES.VALID_REGEX:
        result[fieldName] = isEmpty(fieldValue) ? ' ' : fieldValue;
      default:
        if (!isEmpty(fieldValue)) {
          result[fieldName] = fieldValue;
        }
    }
    return result;
  }, {} as any);
};
