import React, { FC } from 'react';
import styled from '@emotion/styled';
import { CertificatesManagementActions, CertificateManagementFooterProps } from './CertificatesManagementActions';
import { BigidHeading5 } from '@bigid-ui/components';

export type CertificatesManagementHeaderProps = CertificateManagementFooterProps & {
  title: string;
};

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 24px;
  gap: 16px;
  height: 100%;
`;

const styles = {
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  fontWeight: 700,
};

export const CertificatesManagementHeader: FC<CertificatesManagementHeaderProps> = ({
  title,
  row,
  handleSubmit,
  handleDelete,
}) => (
  <Header>
    <BigidHeading5 sx={styles}>{title}</BigidHeading5>
    <CertificatesManagementActions row={row} handleSubmit={handleSubmit} handleDelete={handleDelete} />
  </Header>
);
