import React, { FC } from 'react';
import makeStyles from '@mui/styles/makeStyles';

interface CustomAppIconProps {
  logo: string;
  isSidebar?: boolean;
}

const useStyles = makeStyles({
  cardLogo: {
    height: 40,
    width: 40,
  },
  sidebarLogo: {
    height: 20,
    width: 20,
  },
});

const getImageType = (logo: string) => (atob(logo).includes('<svg') ? 'svg+xml' : 'jpeg');

export const CustomAppIcon: FC<CustomAppIconProps> = ({ logo, isSidebar = false }) => {
  const classes = useStyles({});
  return (
    <img
      src={`data:image/${getImageType(logo)};base64, ${logo}`}
      className={isSidebar ? classes.sidebarLogo : classes.cardLogo}
    />
  );
};
