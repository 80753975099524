import React, { FC, memo, useCallback, useMemo, useState, useEffect } from 'react';

import {
  BigidFieldRenderProps,
  compareObjectsExceptFunctions,
  BigidExtraField,
  useExtraFieldControl,
  BigidExtraFieldLabelPosition,
} from '@bigid-ui/components';
import { SOURCE_TO_COMPONENT_MAP, TYPE_TO_COMPONENT_MAP } from '../constants';
import { DsTypesEnum } from '../types';
import makeStyles from '@mui/styles/makeStyles';
import { useSystemUserSelfDelete } from './FormSystemUserSelectField';
import { generateOneField } from '../generateFields';

const useStyles = makeStyles({
  extraFieldWrapper: {
    padding: '0 0 0 8px',
    width: '100%',
  },
});

const mapExtraFieldItemDataToBigidSelectOption = (data: any[]) => data?.map(props => props.value?.[0]?.value as any);

const FieldComponentItemHOC = (WrappedComponent: FC<any>, fieldConfig: Record<string, any>) => (props: any) => {
  const { onChange, id, name } = props;
  return (
    <WrappedComponent
      {...props}
      {...fieldConfig}
      setValue={onChange}
      name={fieldConfig?.name && id ? `${fieldConfig.name}-${id}` : name}
    />
  );
};

export const FormArrayField: FC<BigidFieldRenderProps> = memo(({ value, setValue, disabled, misc, label, name }) => {
  const { extraFieldWrapper } = useStyles({});
  const isSelectLike = [DsTypesEnum.stringSelect, DsTypesEnum.objectSelect].includes(misc?.arrayFields?.type);
  const createNew = useCallback(
    () => ({
      id: String(new Date().getTime()),
      value: isSelectLike
        ? [
            {
              placeholder: misc?.arrayFields?.placeholder || misc?.placeholder,
              parentName: name,
            },
          ]
        : '',
    }),

    [isSelectLike, misc?.arrayFields?.placeholder, misc?.placeholder, name],
  );

  const { onAddNew, onDelete, onChangeItem, data } = useExtraFieldControl({
    initialData: value?.length
      ? value.map((item: string | Record<string, any>, index: number) => ({
          id: typeof item === 'object' && item?.id ? item.id : `${name}-${index}`,
          value:
            isSelectLike && typeof item === 'object'
              ? [
                  {
                    ...item,
                    value: item,
                    id: item?.id ?? `${item}-${index}`,
                    parentName: name,
                  },
                ]
              : item,
        }))
      : [createNew()],
    createNewItem: createNew,
    onChange: value => {
      setValue(isSelectLike ? mapExtraFieldItemDataToBigidSelectOption(value) : value);
    },
  });
  const FieldComponent =
    SOURCE_TO_COMPONENT_MAP[misc?.arrayFields?.source] || TYPE_TO_COMPONENT_MAP[misc?.arrayFields?.type as DsTypesEnum];
  const [fieldConfig, setFieldConfig] = useState();
  const updateFieldConfig = useCallback(async () => {
    const isNestedObject = [DsTypesEnum.object, DsTypesEnum.verticalObject].includes(misc?.arrayFields?.type);
    setFieldConfig(isNestedObject ? await generateOneField(misc?.arrayFields) : {});
  }, [misc?.arrayFields]);
  const WrappedComponent: FC<any> = useMemo(
    () => FieldComponentItemHOC(FieldComponent, fieldConfig),
    [FieldComponent, fieldConfig],
  );
  const { deleteUser, changeUser } = useSystemUserSelfDelete(onDelete, onChangeItem, data);

  useEffect(() => {
    updateFieldConfig();
  }, [updateFieldConfig]);

  return (
    <div data-aid={`bigid-form-field-array-${name}`} className={extraFieldWrapper}>
      <BigidExtraField
        disabled={disabled}
        data={data}
        onAddNew={onAddNew}
        label={label || ''}
        labelPosition={misc.labelPosition || BigidExtraFieldLabelPosition.left}
        fieldComponent={WrappedComponent}
        onDelete={misc?.arrayFields?.source === 'systemUsers' ? deleteUser : onDelete}
        onChangeItem={misc?.arrayFields?.source === 'systemUsers' ? changeUser : onChangeItem}
        labelWidth={misc.labelPosition === BigidExtraFieldLabelPosition.top ? 'auto' : '218px'}
        alignCenter={true}
        isDataInAll={true}
      />
    </div>
  );
}, compareObjectsExceptFunctions);

FormArrayField.displayName = 'FormArrayField';
