import { BigidBIAnalyticsService } from '@bigid-ui/utils';
import { sessionStorageService } from '../../common/services/sessionStorageService';
import { CONFIG } from '../../config/common';
import { $state, $stateParams, $transitions } from './angularServices';
import { licenseService } from './licenseService';
import { httpService } from './httpService';
import { SystemEvents, systemEventsEmitter } from './systemEvents';
import { BIGID_BI_EVENTS } from '../config/BigIdBiEvents';
import { getApplicationPreference } from './appPreferencesService';

export enum BiEventsTypes {
  FMSD_WIZARD = 'fmsd_wizard',
  APPS_SDK = 'apps_sdk',
  CATALOG_UI = 'catalog_ui',
}

/**
 *
 * @deprecated use the AnalyticsService.trackManualEvent/trackPageView instead
 */
export const legacySendBiEvent = (event_type: BiEventsTypes, data?: Record<string, unknown>) => {
  httpService.post('/bi-events', { event_type, data }).catch(err => {
    console.log(`Error: ${event_type} Bi event couldn't be sent`, err);
  });
};

type EventKeys = keyof typeof BIGID_BI_EVENTS;
export type BiEvents = typeof BIGID_BI_EVENTS[EventKeys];

const getUserProperties = () => [
  {
    property: 'company_name',
    value: getApplicationPreference('COMPANY_NAME'),
  },
  {
    property: 'is_trial',
    value: licenseService.isTrial(),
  },
  {
    property: 'screen_width',
    value: screen?.width,
  },
  {
    property: 'screen_height',
    value: screen?.height,
  },
  {
    property: 'screen_avail_height',
    value: screen?.availHeight,
  },
  {
    property: 'screen_avail_width',
    value: screen?.availWidth,
  },
  {
    property: 'screen_color_depth',
    value: screen?.colorDepth,
  },
  {
    property: 'screen_pixel_depth',
    value: screen?.pixelDepth,
  },
  {
    property: 'screen_orientation_type',
    value: screen?.orientation?.type,
  },
  {
    property: 'screen_orientation_angle',
    value: screen?.orientation?.angle,
  },
  {
    property: 'window_inner_width',
    value: window?.innerWidth,
  },
  {
    property: 'window_inner_height',
    value: window?.innerHeight,
  },
];

class AnalyticsService extends BigidBIAnalyticsService {
  eventListenerAdded: boolean;

  init() {
    super.init({
      apiKey: getApplicationPreference('AMPLITUDE_API_KEY'),
      userName: sessionStorageService.get<string>('userName'),
      serverUrl: CONFIG.AMPLITUDE_PROXY_URL,
    });

    this.setUserGroup('Accounts', getApplicationPreference('COMPANY_NAME'));

    if (!this.eventListenerAdded) {
      $transitions.onSuccess({}, () => {
        const transitionPageName = $state?.current?.data?.analyticsPageName || $state?.current?.name || '';
        this.trackPageView(transitionPageName);
      });

      this.eventListenerAdded = true;
    }
  }

  trackPageView(pageName: string) {
    super.trackPageView(pageName, $stateParams);
  }
}

export const analyticsService = new AnalyticsService({
  userProperties: getUserProperties,
  eventFixedData: () => ({
    product_type: 'BigID',
    saas_env: getApplicationPreference('SAAS_ENV'),
    multi_tenant_mode_enabled: getApplicationPreference('MULTI_TENANT_MODE_ENABLED'),
    is_customer_smtp_configured: getApplicationPreference('IS_SMTP_CONFIGURED'),
    idp_type: sessionStorageService.get('LOGIN_STRATEGY'),
  }),
  moduleName: () => $state?.current?.data?.analyticsPageName || $state?.current?.name || '',
});

systemEventsEmitter.addEventListener(SystemEvents.LOGIN, () => {
  analyticsService.init();
});

systemEventsEmitter.addEventListener(SystemEvents.LOGOUT, () => {
  analyticsService.unregister();
});
