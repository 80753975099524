import { BigidAdvancedToolbarFilterTypes, BigidAdvancedToolbarFilterUnion } from '@bigid-ui/components';
import { httpService } from '../../../../../../services/httpService';
import { fetchAllFilterOptionsLimit, searchTextMinLength } from '../../../../constants';
import { parseAggregationItemsToFilterOptions } from '../../../../queryHelpers';
import { AggregationType, GetAggregatedDataResponse, ResultsEntityType } from '../../../../types';
import { entityTypeToApiType } from '../../../../utils';
import { mapDropdownFiltersToFilterString } from '../../../../../../utilities/toolbarFilterToBIQL/toolbarFilterToBIQL';

const fieldTypes = ['DATE', 'OBJECT'] as const;

type FieldType = typeof fieldTypes[number];

type FilterField = {
  fieldName: AggregationType;
  fieldType: FieldType;
  displayName: string;
  filterFieldNameForQL?: string;
};

type SearchOptionPayload = {
  searchOptionValue: string;
  field: string;
};

type AggregationItem = {
  aggName: string;
  filter?: string;
  sorting: {
    field: string;
    order: string;
  }[];
  paging?: {
    limit: number;
    skip: number;
  };
};

type EntityTypeResponse = {
  defaultTypeMapping: {
    filterFields: FilterField[];
  };
};

type GetAggregatedDataPayload = {
  filter?: string;
  aggregations: AggregationItem[];
  searchText?: string;
  entityType: string;
};

type GetFilterOptionsPayload = {
  filter: string;
  searchOptionValue?: string;
  entityType: string;
  aggName: string;
  searchText: string;
};

type GetFilterOptionsFetchMethodPayload = {
  aggName: string;
  shouldFetchEverything?: boolean;
  searchText?: string;
  entityType: string;
  filterQuery: string;
};

const getFilterType = (fieldType: FieldType) => {
  switch (fieldType) {
    case 'DATE':
      return BigidAdvancedToolbarFilterTypes.DATE_RANGE;
    case 'OBJECT':
      return BigidAdvancedToolbarFilterTypes.DROPDOWN;
    default:
      return BigidAdvancedToolbarFilterTypes.DROPDOWN;
  }
};

export function getFilterOptionsFetchMethodPayload({
  aggName,
  shouldFetchEverything,
  searchText,
  entityType,
  filterQuery,
}: GetFilterOptionsFetchMethodPayload): GetAggregatedDataPayload {
  const aggregation: AggregationItem = {
    aggName,
    sorting: [
      {
        field: 'docCount',
        order: 'DESC',
      },
    ],
  };

  if (shouldFetchEverything) {
    aggregation.paging = {
      limit: fetchAllFilterOptionsLimit,
      skip: 0,
    };
  }

  const payloadBase: GetAggregatedDataPayload = {
    aggregations: [aggregation],
    entityType,
  };

  if (searchText?.trim().length >= searchTextMinLength) {
    payloadBase.searchText = searchText;
  }

  if (filterQuery) {
    payloadBase.filter = filterQuery;
  }

  return payloadBase;
}

export function getAggregatedData(payload: GetAggregatedDataPayload) {
  const { filter, searchText, entityType, aggregations } = payload;
  const query = filter ? `?filter=${encodeURIComponent(filter)}` : '';
  const endpoint = `inventory/${entityType}`;

  const updatedPayload: GetAggregatedDataPayload = {
    entityType,
    filter,
    searchText: searchText?.trim().length >= searchTextMinLength ? searchText?.trim() : undefined,
    aggregations,
  };

  return httpService.post<GetAggregatedDataResponse>(`${endpoint}${query}`, updatedPayload).then(({ data }) => data);
}

const fetchDropdownOptions = async (payload: GetFilterOptionsPayload) => {
  const payloadProcessed = getFilterOptionsFetchMethodPayload({
    aggName: payload.aggName,
    entityType: payload.entityType,
    filterQuery: payload.filter,
    searchText: payload.searchText,
  });
  const { aggregations } = await getAggregatedData(payloadProcessed);
  if (!aggregations?.[0]) {
    return [];
  }

  return parseAggregationItemsToFilterOptions(aggregations[0]);
};

type MapResponseFiltersToToolbarFiltersProps = {
  filters: FilterField[];
  entityType: string;
};

const mapResponseFiltersToToolbarFilters = ({
  entityType,
  filters,
}: MapResponseFiltersToToolbarFiltersProps): BigidAdvancedToolbarFilterUnion[] => {
  const supportedFilters = filters.filter(({ fieldType }) => fieldTypes.includes(fieldType));

  return supportedFilters.reduce<BigidAdvancedToolbarFilterUnion[]>(
    (acc, { fieldName, displayName, fieldType, filterFieldNameForQL }) => {
      const type = getFilterType(fieldType);
      const field = filterFieldNameForQL || fieldName;

      switch (type) {
        case BigidAdvancedToolbarFilterTypes.DATE_RANGE:
          acc.push({
            field,
            id: field,
            operator: 'equal',
            title: displayName,
            type,
            options: {
              currentRangeOptionSelected: 'none',
              pickersState: {
                currentMode: 'from',
                dates: {
                  from: null,
                  until: null,
                },
              },
            },
          });

          return acc;

        case BigidAdvancedToolbarFilterTypes.DROPDOWN:
          acc.push({
            field: field,
            id: field,
            operator: 'equal',
            options: [],
            isSearchable: true,
            title: displayName,
            asyncOptionsFetch: async (filters, searchOptionValue, searchText) => {
              const filteredFilters = filters.filter(({ id }) => id !== fieldName);
              const searchOptionPayload: SearchOptionPayload = {
                searchOptionValue,
                field,
              };

              const filterQuery = mapDropdownFiltersToFilterString(filteredFilters, searchOptionPayload);
              const response = await fetchDropdownOptions({
                entityType,
                aggName: fieldName,
                filter: filterQuery,
                searchText,
              });

              return response;
            },
            type,
          });

          return acc;
        default:
          return acc;
      }
    },
    [],
  );
};

export const fetchEntityFilters = async (entityType: ResultsEntityType) => {
  const normalizedEntityType = entityTypeToApiType[entityType];
  try {
    const response = await httpService.fetch<EntityTypeResponse>(
      `metadata-search/entity-types/${normalizedEntityType}`,
    );
    const { defaultTypeMapping } = response.data;
    const { filterFields } = defaultTypeMapping;

    return mapResponseFiltersToToolbarFilters({
      filters: filterFields,
      entityType: normalizedEntityType,
    });
  } catch (error) {
    console.error(error);
    throw new Error('Failed to fetch entity filters');
  }
};
