import React, { FC, useRef } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { BigidFilter } from '@bigid-ui/components';
import {
  BigidGrid,
  BigidGridColumn,
  BigidGridColumnTypes,
  BigidGridProps,
  BigidGridQueryComponents,
  useFetch,
} from '@bigid-ui/grid';
import { queryService } from '../../../../../services/queryService';
import { httpService } from '../../../../../services/httpService';
import { notificationService } from '../../../../../services/notificationService';
import {
  DataSourceModel,
  DataSourcesResponse,
} from '../../../../DataSources/DataSourceConnections/DataSourceConnectionTypes';

interface DataSourcesGridProps {
  prefix: string;
}

const useStyles = makeStyles({ gridWrapper: { display: 'flex', overflow: 'hidden', height: 250 } });

const columns: BigidGridColumn<DataSourceModel>[] = [
  {
    name: 'name',
    title: 'Data Source',
    getCellValue: ({ name }) => name,
    filteringEnabled: true,
    sortingEnabled: true,
    type: BigidGridColumnTypes.TEXT,
  },
  {
    name: 'type',
    title: 'Type',
    getCellValue: ({ type }) => type,
    filteringEnabled: true,
    sortingEnabled: true,
    type: BigidGridColumnTypes.TEXT,
  },
  {
    name: 'location',
    title: 'Location',
    getCellValue: ({ location }) => location,
    filteringEnabled: true,
    sortingEnabled: true,
    type: BigidGridColumnTypes.TEXT,
  },
];

export const PrefixDataSourceGrid: FC<DataSourcesGridProps> = ({ prefix }) => {
  const classes = useStyles();
  const { current: externalFilter } = useRef<BigidFilter>([
    { field: 'name', operator: 'startsWith', value: prefix, caseSensitive: true },
  ]);

  const useFetchState = useFetch({
    externalFilter,
    pageSize: 200,
    customRowIdName: 'id',
    initialSorting: [{ field: 'name', order: 'asc' }],
    fetchDataFunction: async (queryComponents: BigidGridQueryComponents) => {
      let data: DataSourceModel[] = [];
      let totalCount = 0;

      try {
        const gridConfigQuery = queryService.getGridConfigQuery(queryComponents);
        const {
          data: {
            data: { ds_connections, totalCount: count },
          },
        } = await httpService.fetch<{ data: DataSourcesResponse }>(`ds-connections?${gridConfigQuery}`);

        data = ds_connections;
        totalCount = count;
      } catch (e) {
        console.error(e);
        notificationService.error('Could not fetch data. See logs for more information');
      }

      return {
        data,
        totalCount,
      };
    },
  });

  const gridConfig: BigidGridProps<DataSourceModel> = {
    columns,
    rows: useFetchState.rows as DataSourceModel[],
    skip: useFetchState.skip,
    onPagingChanged: useFetchState.onPagingChanged,
    onSortingChanged: useFetchState.onSortingChanged,
    totalRowsCount: useFetchState.totalRowsCount,
    onFiltersChange: useFetchState.onFiltersChanged,
    defaultSorting: useFetchState.defaultSorting,
    apiRef: useFetchState.apiRef,
    loading: useFetchState.isLoading,
  };

  return (
    <div className={classes.gridWrapper}>
      <BigidGrid {...gridConfig} />
    </div>
  );
};
