import React, { ReactElement } from 'react';
import {
  BigidCredentialsIllustration,
  BigidNoDataIllustration,
  BigidCalendarIllustration,
  BigidAuditIllustration,
} from '@bigid-ui/icons';
import styled from '@emotion/styled';
import { BigidBody1 } from '@bigid-ui/components';
import { licenseService } from '../../../../../../services/licenseService';
import { isPermitted } from '../../../../../../services/userPermissionsService';
import { useLocalTranslation } from '../translations';
import { CATALOG_PERMISSIONS } from '@bigid/permissions';
import { SelectionAttributeProps } from '../DsObjectPreview';
import { generateDataAid } from '@bigid-ui/utils';

const Main = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: auto;
`;

const MainWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 100%;
  height: 100%;
  row-gap: 12px;
  column-gap: 12px;
`;

interface DsObjectPreviewEmptyStatesProps {
  children: ReactElement;
  selectedRow: SelectionAttributeProps;
  isNotSupported: boolean;
  isExpired: boolean;
  isScanFailed: boolean;
  isSegmentsExists: boolean;
  dataAid: string;
}

export const DsObjectPreviewWrapper = ({
  children,
  selectedRow,
  isNotSupported,
  isExpired,
  isScanFailed,
  isSegmentsExists,
  dataAid,
}: DsObjectPreviewEmptyStatesProps) => {
  const isFilePermitted = (permission: string) =>
    !licenseService.shouldBlockUserWithLicenseExpired() && isPermitted(permission);
  const hasPermission = isFilePermitted(CATALOG_PERMISSIONS.PREVIEW_FILE_INVESTIGATION?.name);
  const { t } = useLocalTranslation('ObjectPreviewStates');

  switch (true) {
    case !hasPermission:
      return (
        <MainWrapper data-aid={generateDataAid(dataAid, ['NoAccess'])}>
          <BigidCredentialsIllustration size="small" />
          <BigidBody1 width={'200px'}>{t('AccessStateMessage')}</BigidBody1>
        </MainWrapper>
      );
    case !isSegmentsExists:
      return (
        <MainWrapper data-aid={generateDataAid(dataAid, ['NoSegment'])}>
          <BigidNoDataIllustration size="small" />
          <BigidBody1 width={'170px'}>{t('NoSegments')}</BigidBody1>
        </MainWrapper>
      );
    case isScanFailed:
      return (
        <MainWrapper data-aid={generateDataAid(dataAid, ['ScanFailed'])}>
          <BigidNoDataIllustration size="small" />
          <BigidBody1 width={'170px'}>{t('ScanFailed')}</BigidBody1>
        </MainWrapper>
      );
    case isNotSupported:
      return (
        <MainWrapper data-aid={generateDataAid(dataAid, ['NoAttr'])}>
          <BigidAuditIllustration size="small" />
          <BigidBody1 width={'165px'}>{t('UnavailableStateMessage')}</BigidBody1>
        </MainWrapper>
      );
    case isExpired:
      return (
        <MainWrapper data-aid={generateDataAid(dataAid, ['Expired'])}>
          <BigidCalendarIllustration size="small" />
          <BigidBody1 width={'200px'}>{t('ExpiredStateMessage')}</BigidBody1>
        </MainWrapper>
      );
    case Boolean(selectedRow):
      return <Main>{children}</Main>;
    default:
      return (
        <MainWrapper data-aid={generateDataAid(dataAid, ['Select'])}>
          <BigidAuditIllustration size="small" />
          <BigidBody1>{t('SelectStateMessage')}</BigidBody1>
        </MainWrapper>
      );
  }
};
