import styled from '@emotion/styled';
import { BigidColorsV2 } from '@bigid-ui/colors';

/*
 * Grid empty state styles
 */

export const EmptyStateWrapper = styled('div')`
  width: 100%;
  height: calc(100% - 42px);
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 16px;
  font-size: 14px;
`;

/**
 * Main
 */
export const EntityMainWrapper = styled('div')<{ fullWidth?: boolean }>`
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: stretch;
  overflow: auto;
  padding: 24px 24px 0 24px;
  gap: 16px;
  width: ${p => (p?.fullWidth ? '100%' : 'calc(100% - 365px)')};
  border-right: 1px solid ${BigidColorsV2.gray[200]};
`;

export const EntityDetailsWrapper = styled('div')<{ fullWidth?: boolean }>(({ theme }) => ({
  display: 'flex',
  flexFlow: 'row wrap',
  marginBottom: theme.spacing(3),
  padding: `${theme.spacing(2)} ${theme.spacing(3)}`,
  gap: theme.spacing(2),
  backgroundColor: BigidColorsV2.gray[125],
}));

export const EntityDetailsSectionWrapper = styled.div`
  display: inline-flex;
  flex-direction: column;
  width: calc((100% / 4) - (48px / 4));
  padding: 8px 0;
`;

export const EntityDetailsSectionWrapperFullWidth = styled(EntityDetailsSectionWrapper)<{ buttonWidth?: number }>`
  width: ${p => (p?.buttonWidth ? `calc(100% - ${p.buttonWidth}px` : '100%')};
`;

export const DetailsBold = styled.span`
  font-size: 0.875rem;
  font-weight: 600;
  line-height: 1.5;
`;

export const EntityDetailsSectionDescriptionWrapper = styled(DetailsBold)`
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  text-overflow: ellipsis;
`;

/**
 * Aside
 */

export const FlexWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const FlexWrapperHidden = styled(FlexWrapper)`
  overflow: hidden;
`;

export const EntityDetailsAsideItemWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  min-height: 30px;
  overflow: hidden;
`;

export const EntityDetailsAsideWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: stretch;
  overflow: auto;
  padding: 24px;
  width: 365px;
  gap: 16px;
`;

export const AsideEmptyStateWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(2),
  padding: `${theme.spacing(2)} ${theme.spacing(3)}`,
  alignItems: 'center',
  justifyContent: 'center',
  height: '170px',
  width: '100%',
}));

export const AsideSectionHeaderWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(1),
  alignItems: 'center',
  justifyContent: 'space-between',
}));

export const AsideSectionLabelWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(1),
}));
export const AsideSectionContentWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  overflow: 'auto',
  padding: `${theme.spacing(1)} ${theme.spacing(1.5)}`,
  width: '100%',
}));

export const LabelBadgeGroupWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  borderBottom: `1px solid ${BigidColorsV2.gray[200]}`,
  padding: `${theme.spacing(1)} ${theme.spacing(1.5)}`,
  cursor: 'pointer',
  '&:hover': {
    backgroundColor: BigidColorsV2.gray[100],
  },
}));

export const LabelBadgeGroupWrapperNoBorder = styled(LabelBadgeGroupWrapper)`
  border-bottom: 0;
  &:hover {
    background-color: transparent;
  }
`;

export const TabsWrapper = styled.div`
  padding: 0px 16px 0px 16px;
  min-height: 48px;
  display: flex;
  align-items: end;
`;

export const ContentWrapper = styled.div`
  display: flex;
  border-top: 1px solid ${BigidColorsV2.gray[200]};
  height: calc(100% - 32px);

  & * {
    -ms-overflow-style: none !important;
  }

  & *::-webkit-scrollbar {
    width: 0 !important;
  }
`;

export const FitContentWrapper = styled.div`
  width: fit-content;
`;

export const FlexWithGap = styled.div`
  display: flex;
  gap: 8px;
`;

export const TruncatedText = styled.span`
  font-weight: 600;
  font-size: 14px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const TruncatedTextAside = styled(TruncatedText)`
  display: block;
  padding-right: 16px;
  font-size: 16px;
  font-weight: 400;
`;
