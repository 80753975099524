import { BigidLoader, BigidPaper } from '@bigid-ui/components';
import makeStyles from '@mui/styles/makeStyles';
import { DSAR_PERMISSIONS } from '@bigid/permissions';
import { debounce } from 'lodash';
import React, { FC, useEffect, useMemo, useReducer } from 'react';
import { notificationService } from '../../../services/notificationService';
import { isPermitted } from '../../../services/userPermissionsService';
import { ReportTemplateContext, ReportTemplateContextState } from './hooks/ReportTemplateContext';
import { getInitialReducerState, ReducerActions, reportTemplateFormReducer } from './hooks/ReportTemplateReducer';
import { getTemplateById, saveChanges } from './reportTemplatesService';
import { CollectionMechanismSection } from './TemplateSections/CollectionMechanismSection';
import { ConsentsSection } from './TemplateSections/ConsentsSection';
import { ContactInformationSection } from './TemplateSections/ContactInformationSection';
import { DataSourcesSection } from './TemplateSections/DataSourcesSection';
import { GeneralInformationSection } from './TemplateSections/GeneralInformationSection';
import { UserDisplayInformationSection } from './TemplateSections/UserDisplayInformationSection';
import { GeoDataTransferSection } from './TemplateSections/GeoDataSection';
import { PersonalInfoSection } from './TemplateSections/PersonalInfoSection';
import { PurposesSection } from './TemplateSections/PurposesSection';
import { RightsSection } from './TemplateSections/RightsSection';
import { SectionsWrapper } from './FormSection/SectionsWrapper';
import { ThirdPartySection } from './TemplateSections/ThirdPartySection';
import { ReportTitleSection } from './TemplateSections/ReportTitleSection';

export const DEFAULT_FLOW_NAME = 'Default';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexFlow: 'column nowrap',
    flex: '1 1 auto',
    height: '100%',
    padding: theme.spacing(4),
    gap: theme.spacing(3),
    overflow: 'auto',
  },
  noPadding: {
    padding: 0,
  },
}));

export const ReportTemplateForm: FC<{ id: string }> = ({ id: templateId }) => {
  const classes = useStyles({});
  const [state, dispatch] = useReducer(reportTemplateFormReducer, {}, getInitialReducerState);

  const hasEditPermission = useMemo(() => isPermitted(DSAR_PERMISSIONS.EDIT_REPORT_TEMPLATES.name), []);

  useEffect(() => {
    const { templateId, data, timeout } = state.dataPendingSave || {};
    if (!templateId || !data) {
      return;
    }
    // TODO: currently we use debounce to save data but we need to use BLUR
    // TODO: so we need to rewrite this logic once we have onBlur support from BigidForm
    const debouncedSave = debounce(async () => {
      await saveChanges(templateId, data);
      dispatch({
        type: ReducerActions.RESET_PENDING_SAVE_DATA,
        payload: {},
      });
    }, timeout ?? 1000);
    debouncedSave();

    return () => {
      debouncedSave.cancel();
    };
  }, [state.dataPendingSave]);

  useEffect(() => {
    const init = async () => {
      dispatch({
        type: ReducerActions.RESET,
        payload: {},
      });
      try {
        const initialTemplateData = await getTemplateById(templateId);
        dispatch({
          type: ReducerActions.INIT,
          payload: { templateId, hasEditPermission, initialTemplateData },
        });
      } catch (err) {
        console.error('Failed to get Report Templates, err:', JSON.stringify(err));
        notificationService.error(`Failed to get Report Templates, err: ${err}`);
      }
    };
    init();
  }, [hasEditPermission, templateId]);

  const isReadyToRender = templateId && state?.templateId && templateId === state?.data?.id;
  if (!isReadyToRender) {
    return <BigidLoader />;
  }

  const contextValue: ReportTemplateContextState = {
    dispatch,
    hasEditPermission: state.hasEditPermission,
    initialTemplateData: state.initialTemplateData,
    templateId: state.templateId,
  };

  return (
    <ReportTemplateContext.Provider value={contextValue} key={state.templateId}>
      <BigidPaper classes={{ root: classes.root }}>
        <ReportTitleSection />
        <SectionsWrapper sectionName="companyInformationPage">
          <GeneralInformationSection />
          <ContactInformationSection />
        </SectionsWrapper>
        <SectionsWrapper sectionName="generalInformationPage">
          <UserDisplayInformationSection />
        </SectionsWrapper>
        <SectionsWrapper sectionName="informationCollectionPage">
          <PurposesSection />
          <ThirdPartySection />
          <CollectionMechanismSection />
          <GeoDataTransferSection />
          <RightsSection />
          <DataSourcesSection />
          <ConsentsSection />
        </SectionsWrapper>
        <SectionsWrapper sectionName="personalInfoPage">
          <PersonalInfoSection />
        </SectionsWrapper>
      </BigidPaper>
    </ReportTemplateContext.Provider>
  );
};
