import './taskList.component.scss';
import template from './taskList.component.html';
import angular from 'angular';

angular.module('app').component('tasklist', {
  template,
  controller: [
    '$http',
    '$document',
    'appSettings',
    'localStorageService',
    'dataMappingService',
    function ($http, $document, appSettings, localStorageService, dataMappingService) {
      var ctrl = this;
      ctrl.commentsText = [];
      ctrl.mentionedUsers = [];

      this.$onInit = () => {
        if (this.entity.hasActiveCollaborationTask || this.entity.hasOpenTasks) {
          ctrl.getCollaborations();
        }
        ctrl.entity.collaborations = ctrl.entity.collaborations;
      };

      ctrl.getCollaborations = () => {
        dataMappingService.getCollaborationByEntityID(this.entity._id).then(result => {
          ctrl.collaborations = result.filter(collaboration => collaboration.status === 'open');
        });
      };

      ctrl.openTaskWindow = task => {
        switch (task.type) {
          case 'collaboration':
            ctrl.vm.openCollaborationWindow(this.entity, false, task);
            ctrl.vm.closeTaskListWindow();
            break;
          case 'attributeWithoutPurpose':
            ctrl.vm.openMissingPurposeWindow(this.entity, task);
            ctrl.vm.closeTaskListWindow();
            break;
        }
      };
    },
  ],
  bindings: {
    vm: '<',
    entity: '<',
    onClose: '&',
  },
});
