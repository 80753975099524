import { MutableRefObject, useRef, useEffect } from 'react';
import { isString } from 'lodash';
import {
  DataSourceTestConnectionStatusEnum,
  TestConnectionResponseData,
  UseTestConnectionHandlerConfig,
  useTestConnection as useTestConnectionV1,
} from '../../DataSourceConfiguration/hooks/useTestConnection';
import { useDataSourceConfigState } from '../../DataSourceConfiguration/hooks/useDataSourceConfigState';
import { useLocalTranslation } from '../translations';
import type { BigidFormStateAndHandlers } from '@bigid-ui/components';
import type { DataSourceConnectionConfigurationState } from '../DataSourceConnectionConfiguration';
import { getApplicationPreference } from '../../../../services/appPreferencesService';
import { sseEventEmitter } from '../../../../services/sseService';

export type TestConnectionError = boolean | string | Record<string, boolean | string>;

type UseTestConnectionReturnType = ReturnType<typeof useTestConnectionV1> & {
  isLoading: boolean;
  isError: boolean;
  isErrorResolutionCached: boolean;
  isSuccess: boolean;
  status: DataSourceTestConnectionStatusEnum;
  errors?: TestConnectionError;
  errorSteps?: { title: string; content: string }[];
  error?: string;
  clear: () => void;
  data: TestConnectionResponseDataV2;
  enabled: boolean;
};

type UseTestConnectionConfig<TData, TError> = {
  onSuccess?: (data?: TData) => void;
  onError?: (error?: TError, errorType?: string) => void;
};

export type TestConnectionResolutionStep = {
  stepHeader: string;
  stepText: string;
  stepID: string;
};

export type TestConnectionErrorResolution = {
  summarizedErrorMessage: string;
  dataSourceType: string;
  errorType: string;
  errorCode?: string;
  detailedErrorMessage: string;
  resolutionSteps: TestConnectionResolutionStep[];
};

export interface PreConnectionData {
  [index: string]: string;
}

export type TestConnectionResponseDataV2 = TestConnectionResponseData & {
  errorResolution?: TestConnectionErrorResolution;
};

type DataSourceConnectionConfigurationStateV2 = DataSourceConnectionConfigurationState & {
  errorResolution: TestConnectionErrorResolution;
};

const DEFAULT_ERROR_MESSAGE = 'connectionErrorDefaultMessage';

export const useTestConnection = <TData = TestConnectionResponseDataV2, TError = string>(
  { configDataSourceState, updateState }: ReturnType<typeof useDataSourceConfigState>,
  control: MutableRefObject<BigidFormStateAndHandlers>,
  { onError, onSuccess }: UseTestConnectionConfig<TData, TError> = {},
): UseTestConnectionReturnType => {
  const { t } = useLocalTranslation();
  const getValuesContainerOverride = useRef<BigidFormStateAndHandlers['getValues']>(() => control.current.getValues());
  const getFieldPropsFunctionOverride = useRef<BigidFormStateAndHandlers['getFieldProps']>((fieldName: string) =>
    control.current?.getFieldProps(fieldName),
  );

  const { testError, testStatus, errorResolution } = configDataSourceState as DataSourceConnectionConfigurationStateV2;

  const errors = testError as TestConnectionError;

  const result = useTestConnectionV1({
    ...configDataSourceState,
    updateState,
    getValuesContainer: getValuesContainerOverride,
    getFieldPropsFunction: getFieldPropsFunctionOverride,
    initialValues: configDataSourceState?.initialValues,
    getApplicationPreference,
    sseEventEmitter,
  });

  const handleErrors = (errors: unknown[], data: unknown) => {
    const { errorResolution } = (data ?? {}) as TestConnectionResponseDataV2;
    updateState({
      errorResolution,
    } as unknown as DataSourceConnectionConfigurationStateV2);
    const [error] = errors ?? [];
    onError?.((error ?? t(DEFAULT_ERROR_MESSAGE)) as TError, errorResolution?.errorType);
  };

  const wrapAddListenerToTestConnectionEvent = (sseEventId: string) => {
    return result.addListenerToTestConnectionEvent(sseEventId, {
      onError: (...props) => handleErrors(...props),
      onSuccess: (data: unknown) => onSuccess?.(data as TData),
    });
  };

  const handleTestConnection = (connection: unknown) => {
    result.onTestHandler(
      connection,
      {
        query: {
          body: {
            isNewConnection: true,
            numOfTroubleshootingOptionsAvailable: configDataSourceState.errorResolution?.resolutionSteps?.length || 0,
          },
        },
        onError: handleErrors,
        onSuccess: (data: unknown) => onSuccess?.(data as TData),
      },
      { shouldTriggerNotification: false },
    );
  };

  const data = {
    error: configDataSourceState.testError,
    errMessage: configDataSourceState.testError,
    operationStatus: configDataSourceState.testStatus,
    tablesResult: configDataSourceState.tablesResult,
    errorResolution,
  };

  useEffect(() => {
    !testError &&
      testStatus === DataSourceTestConnectionStatusEnum.failed &&
      updateState({ testError: t(DEFAULT_ERROR_MESSAGE) });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [testStatus, testError]);

  return {
    ...result,
    addListenerToTestConnectionEvent: wrapAddListenerToTestConnectionEvent,
    onTestHandler: handleTestConnection,
    isLoading: configDataSourceState.testInProgress,
    isError: configDataSourceState.testStatus === DataSourceTestConnectionStatusEnum.failed,
    isErrorResolutionCached: configDataSourceState.errorResolution != null,
    isSuccess: configDataSourceState.testStatus === DataSourceTestConnectionStatusEnum.success,
    status: configDataSourceState.testStatus,
    enabled: configDataSourceState.isTestAvailable,
    data,
    clear: () => updateState({ testError: null }),
    error: isString(errors) ? errors : null,
    errors,
  };
};
