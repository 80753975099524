import { BigidGridRow, BigidGridSorting } from '@bigid-ui/grid';
import { NormalizedClassifier } from '../../Classification/ClassifiersService';
import { BulkClassifierSelectionGridRow } from './ScanTemplateWizard/Steps/ClassificationStep/classificationStepTypes';

export interface CreatedByUser {
  id: string;
  username: string;
  firstName?: string;
  lastName?: string;
}

export interface ScanTemplate {
  _id: string;
  name: string;
  allEnabledIdSor?: boolean;
  created_at?: Date;
  updated_at?: Date;
  skipIdScan?: boolean;
  scanType: string;
  isClassificationsAsPiiFindings?: boolean;
  description?: string;
  classifiers?: Classifier[];
  labelFramework?: {
    id: string;
    name?: string;
  };
  owner?: CreatedByUser;
  idConnectionList?: string[];
  isReviewFindingsEnabled?: boolean;
  createdBy?: CreatedByUser;
  isOOTB?: boolean;
  bulkClassifierSelection?: BulkClassifierSelectionGridRow[];
  bulkClassifierSelectionMethod?: BulkClassifierSelectionMethod;
  isUseGlobalClassifiers?: boolean;
  isOverwritePrivilegedLabels?: boolean;
  isOverwriteTag?: boolean;
}

export enum BulkClassifierSelectionMethod {
  CATEGORY = 'Category',
  REGULATION = 'Regulation',
}

export interface ActiveViewProps {
  templateToEdit?: ScanTemplate;
}

export interface CreateScanTemplateResponse {
  _id?: string;
  matchScanTemplateName?: string;
  matchingCount?: number;
}

export interface UpsertScanTemplateType {
  scanTemplate: ScanTemplateWithoutId;
  isAllowDuplicate?: boolean;
}

export type ScanTemplateWithoutId = Omit<ScanTemplate, '_id'>;

export type Classifier = Pick<NormalizedClassifier, 'type' | 'name'>;

export enum ScanTypes {
  METADATA_SCAN = 'metadataScan',
  LINEAGE_SCAN = 'lineageScan',
  DS_SCAN = 'dsScan',
  LABELING = 'dsTag',
  DATA_IN_MOTION = 'dataInMotion',
  HYPER_SCAN = 'hyper_scan',
  ASSESSMENT_SCAN = 'assessment_scan',
}

export enum ScanTemplateActionType {
  EDIT = 'edit',
  DELETE = 'delete',
  DUPLICATE = 'duplicate',
}

export interface ScanTemplateGridRow extends BigidGridRow, ScanTemplate {
  isFavorite?: boolean;
  _id: string;
}
export const ALL_SCAN_TYPES_VALUES = Object.values(ScanTypes);

export const DISABLED_DS_SUPPORTED_TYPES = [ScanTypes.METADATA_SCAN, ScanTypes.HYPER_SCAN];

export const SCAN_TYPES_SUPPORTED_CLASSIFICATION = [
  ScanTypes.HYPER_SCAN,
  ScanTypes.LINEAGE_SCAN,
  ScanTypes.DS_SCAN,
  ScanTypes.ASSESSMENT_SCAN,
  ScanTypes.DATA_IN_MOTION,
  ScanTypes.METADATA_SCAN,
];

export const SCAN_TYPES_REQUIRED_CLASSIFICATION = [ScanTypes.HYPER_SCAN, ScanTypes.LINEAGE_SCAN];

export const SCAN_TYPES_SUPPORTED_CORRELATION = [
  ScanTypes.HYPER_SCAN,
  ScanTypes.LINEAGE_SCAN,
  ScanTypes.DS_SCAN,
  ScanTypes.DATA_IN_MOTION,
  ScanTypes.ASSESSMENT_SCAN,
];

export const DEFAULT_SCAN_TEMPLATES_SORTING: BigidGridSorting[] = [
  { field: 'updated_at', order: 'desc' },
  { field: 'name', order: 'asc' },
];

export interface ScanTemplateFavorite {
  scanTemplateId: string;
  scanTemplateName: string;
}

export interface ScanTemplateUserPreferences {
  favorites: ScanTemplateFavorite[];
}

export const ScanTypesToDescriptions = {
  [ScanTypes.HYPER_SCAN]:
    'Scan very large datasets in a fraction of the time via a machine-learning predictive model built using the metadata of a sample (approximately 10% of the dataset, depending on the data’s size and complexity). Hyperscan discovers attributes of personal and sensitive data and uncovers correlation patterns.',
  [ScanTypes.LINEAGE_SCAN]:
    'The Lineage scan assesses various data sources, classifies the data, and correlates it based on your selections in the template. Additionally, this scan builds lineage connections to create a visual understanding of how the data flows. Please note that this scan operates exclusively in sampling mode and requires the use of classifiers.',
  [ScanTypes.DS_SCAN]:
    "The Full scan is a comprehensive, detailed scan that assesses diverse data sources, classifies data and correlates it according to the selection in the template. It's used to identify high-value, sensitive, and at-risk data on multiple levels by examining data content and metadata. Full scan can operate in full mode - scanning every object, sampling mode - scanning a representative sample of objects, and differential mode - scanning only objects that have changed since the last scan, depending on the data source configuration.",
  [ScanTypes.ASSESSMENT_SCAN]:
    'The Assessment scan is a light-weight full scan that surfaces sensitive and personal data. The scan is completed when it reaches a preset threshold number of findings, thus providing a quick view of sensitive and at-risk information contained in the data sources. This scan’s predefined configuration overrides the data source’s scan settings, such as sampling size, number of rows etc.',
  [ScanTypes.LABELING]:
    'Label files to identify types of sensitive and confidential data. Integrates with third-party security management systems - Microsoft Information Protection (MIP) and Google Drive.',
  [ScanTypes.METADATA_SCAN]:
    'The Metadata scan examines object metadata to extract information such as access levels and owners. For structured objects, you can select Metadata classifiers, which identify additional attributes found in column names, such as passwords and emails. This type of scan provides an understanding of the data landscape, identifies high-risk data, and helps implement regulatory compliance, data governance, and security measures.',
  [ScanTypes.DATA_IN_MOTION]:
    'Scan continuously while performing correlation every minute and updating the Inventory continuously with findings in the data stream. This scan type only scans Amazon Kinesis and Apache Kafka data sources.',
};
export const DEFAULT_SCAN_TEMPLATE = {
  isReviewFindingsEnabled: true,
  skipIdScan: true,
} as ScanTemplate;
