import { useMemo } from 'react';
import { useAutoDiscoverWizard } from '../../../AutoDiscovery/hooks/useAutoDiscoverWizard';
import { goToAutoDiscovery } from '../../../AutoDiscovery/utils';
import { openSelectDataSourceOnboarding } from '../../SelectDataSourceOnboardingType/selectDataSourceOnboardingTypeService';
import { getApplicationPreference } from '../../../../services/appPreferencesService';

export const useEmptyStateActions = (isAutoDiscoveryAvailable: boolean) => {
  const { openAutoDiscoveryModal } = useAutoDiscoverWizard();

  return useMemo(
    () => [
      {
        execute: async () => {
          await goToAutoDiscovery(openAutoDiscoveryModal);
          return { shouldGridReload: false, shouldClearSelection: false };
        },
        label: 'Start Onboarding',
        show: () => isAutoDiscoveryAvailable && !getApplicationPreference('NEW_SELECT_DS_VIEW_ENABLED_FF'),
      },
      {
        execute: async () => {
          openSelectDataSourceOnboarding();
          return { shouldGridReload: false, shouldClearSelection: false };
        },
        label: 'Connect Data Source',
        show: () => true,
      },
    ],
    [isAutoDiscoveryAvailable, openAutoDiscoveryModal],
  );
};
