import React, { FC } from 'react';
import styled from '@emotion/styled';
import { BigidHeading5 } from '@bigid-ui/components';
import { BrandsActions, BrandsActionsProps } from './BrandsActions';

export type BrandsHeaderProps = BrandsActionsProps & {
  title: string;
};

const Header = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: '10px 24px',
  gap: '16px',
  height: '100%',
});

const styles = {
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  fontWeight: 700,
};

export const BrandsHeader: FC<BrandsHeaderProps> = ({ title, row, isBusy, handleSubmit, handleDelete }) => (
  <Header>
    <BigidHeading5 sx={styles}>{title}</BigidHeading5>
    <BrandsActions row={row} isBusy={isBusy} handleSubmit={handleSubmit} handleDelete={handleDelete} />
  </Header>
);
