import { ReactElement } from 'react';
import { smallIdLicenseService } from './smallIdLicenseService';
import { licenseService } from './licenseService';
import { ConfirmationDialogOptions, showConfirmationDialog } from './confirmationDialogService';
import { downloadFile } from './downloadFile';
import { notificationService } from './notificationService';
import { commonTranslate } from '../translations';
import { noop } from 'lodash';

export const downloadReport = async ({
  headerToTranslate,
  bodyToTranslate,
  getReport,
  fileName,
  downloadReport,
  fileExtension = 'csv',
  contentComponent,
}: {
  headerToTranslate: string;
  bodyToTranslate?: string;
  getReport?: any;
  fileName?: string;
  downloadReport?: () => Promise<void>;
  fileExtension?: string;
  contentComponent?: ReactElement;
}) => {
  if (smallIdLicenseService.isSmallIDLicense() && !smallIdLicenseService.isSmallIDEnabledReport(fileName)) {
    return licenseService.openLicenseUpgradeFlow();
  }

  const cancelButtonName = 'Cancel',
    actionButtonName = 'Download';

  const modalOptions: ConfirmationDialogOptions = {
    entityNameSingular: headerToTranslate,
    customDescription: bodyToTranslate,
    cancelButtonName: cancelButtonName,
    actionButtonName: actionButtonName,
    actionName: '',
    ...(!bodyToTranslate ? { ContentComponent: contentComponent } : {}),
  };

  await showConfirmationDialog(modalOptions)
    .then(async shouldDownload => {
      if (shouldDownload) {
        try {
          if (downloadReport) {
            await downloadReport();
            return;
          }
          const { data } = await getReport();
          if (data) {
            downloadFile(data, 'attachment/octet-stream', `${fileName}-${new Date().toISOString()}.${fileExtension}`);
          }
        } catch (err) {
          notificationService.error(commonTranslate('api.message.commonError'));
        }
      }
    })
    .catch(() => /** in case of cancel transaction **/ noop());
};
