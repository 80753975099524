import { BigidGridColumn, BigidGridColumnTypes, BigidGridProps, FetchDataFunction } from '@bigid-ui/grid';
import { BigidLayout, BigidLayoutConfig, LayoutContentType } from '@bigid-ui/layout';
import { orderBy, pick } from 'lodash';
import React, { useCallback, useMemo, useRef } from 'react';
import { dateTimeService } from '@bigid-ui/i18n';
import { $state } from '../../../services/angularServices';
import { notificationService } from '../../../services/notificationService';
import {
  getEntityPreviewRoute,
  scannerTypeToMetadataSearchEntityType,
} from '../DataCatalogEntityPreview/DataCatalogEntityPreviewUtils';
import { deleteFollowedObjects, getFollowedObjects } from '../DataCatalogService';
import { FollowedObjectModel } from './FollowedObjectTypes';

const gridColumns: BigidGridColumn<FollowedObjectModel>[] = [
  {
    name: 'objectName',
    title: 'Object Name',
    width: 850,
    type: BigidGridColumnTypes.LINK,
    formatter: {
      onClick: async ({ row }) => {
        const { objectName, fullyQualifiedName, scannerTypeGroup } = row;
        const metadataEntityType = scannerTypeToMetadataSearchEntityType(scannerTypeGroup);
        const { route: entityPreviewRoute, params } = getEntityPreviewRoute(metadataEntityType, {
          fullyQualifiedName,
          objectName,
        });
        $state.go(entityPreviewRoute, params);

        return {};
      },
    },
    getCellValue: ({ objectName, fullyQualifiedName }) => ({
      link: {
        text: objectName || fullyQualifiedName,
      },
    }),
  },
  {
    name: 'startFollowAt',
    title: 'Followed Since',
    type: BigidGridColumnTypes.TEXT,
    getCellValue: ({ startFollowAt }) => dateTimeService.formatRelativeToNow(startFollowAt),
  },
];

const gridConfig: BigidGridProps<FollowedObjectModel> = {
  pageSize: 1000,
  showSortingControls: false,
  columns: gridColumns,
};

export const FollowedObjectsGrid = () => {
  const cachedGridData = useRef<FollowedObjectModel[] | null>(null);

  const fetchGridData: FetchDataFunction<FollowedObjectModel> = useCallback(async ({ filter }) => {
    try {
      if (!cachedGridData.current) {
        const result = await getFollowedObjects();
        cachedGridData.current = result.map(row => ({
          id: row.fullyQualifiedName,
          startFollowAt: row.followerDetails.startFollowAt,
          ...pick(row, ['fullyQualifiedName', 'objectName', 'scannerTypeGroup']),
        }));
        cachedGridData.current = orderBy(cachedGridData.current, 'startFollowAt', 'desc');
      }

      let data = cachedGridData.current;
      const filterValue = (filter?.[0]?.value as string)?.toLowerCase();
      if (filterValue) {
        data = data.filter(({ fullyQualifiedName }) => fullyQualifiedName.toLowerCase().includes(filterValue));
      }

      return {
        data,
        totalCount: data.length,
      };
    } catch ({ message }) {
      notificationService.error('An error has occurred');
      console.error(`An error has occured: ${message}`);

      return {
        totalCount: 0,
        data: [],
      };
    }
  }, []);

  const layoutConfig = useMemo(
    () =>
      ({
        filter: {
          hasInitialFilter: false,
          search: {
            isQueryLanguage: false,
          },
        },
        content: {
          entityName: 'objects',
          contentTypes: [LayoutContentType.GRID],
          viewConfig: {
            fetchGridData,
            gridConfig,
            toolbarConfig: {
              hideToolbar: false,
              hideColumnChooser: true,
            },
          },
          toolbarActions: [
            {
              label: 'Unfollow',
              execute: async ({ selectedRowIds }) => {
                try {
                  await deleteFollowedObjects(selectedRowIds as string[]);
                  cachedGridData.current = null;
                  return { shouldClearSelection: true, shouldGridReload: true };
                } catch (e) {
                  return;
                }
              },
              show: ({ selectedRowIds }) => {
                return !!selectedRowIds.length;
              },
            },
          ],
        },
      } as BigidLayoutConfig),
    [fetchGridData],
  );

  return <BigidLayout config={layoutConfig} />;
};
