import { DataSourceModel } from '../DataSourceConnectionTypes';
import type { BigidGridQueryComponents } from '@bigid-ui/grid';
import type { Step } from '@bigid-ui/guided-tour';

export { STATUS as GuidedTourStatus } from 'react-joyride';

export enum CardType {
  NONE = 'none',
  OPEN_ISSUES = 'openIssues',
  SUGGESTED_ACTIONS = 'suggestedActions',
}

export enum DsStatuses {
  DRAFT = 'Draft',
  CONNECTION_ERROR = 'ConnectionError',
  CONNECTED = 'Connected',
  SCAN_IN_PROGRESS = 'InProgress',
  SCAN_STOPPED = 'Stopped',
  SCAN_PAUSED = 'Paused',
  SCAN_FAILED = 'Failed',
  SCAN_COMPLETED = 'Completed',
  SCAN_COMPLETED_WITH_FAILURES = 'CompletedWithFailures',
}
export interface StatusDetails {
  status: DsStatuses;
  updated_at: Date;
}

export interface EnrichmentFields {
  statusDetails: StatusDetails;
}

export interface DataSourceOnBoardingModel extends DataSourceModel {
  enrichmentFields?: EnrichmentFields;
}

export enum DsFunnelStatus {
  CONNECTED = 'connected',
  SCANNED = 'scanned',
  LISTED = 'listed',
}

type DsConnectionEnrichmentStatusUnion = 'connected' | 'scanned' | 'listed';

export type DsConnectionEnrichmentStatusData = {
  [key in DsConnectionEnrichmentStatusUnion]: {
    totalCount: number;
    currentStatus: DsStatuses[];
    previousStatus: DsStatuses[];
  };
};

export type FilterQueryItemRequest = {
  query: BigidGridQueryComponents;
};

export type GetDsConnectionEnrichmentStatusItemRequest = FilterQueryItemRequest;

export type GetDsConnectionEnrichmentStatusItemResponse = {
  status: string;
  statusCode: number;
  data: DsConnectionEnrichmentStatusData;
  message: string;
};

export enum Initiative {
  MAINTAIN_DATA_COVERAGE = 'maintainDataCoverage',
  CONNECTION_ERROR = 'connectionError',
}

export interface DataSourcesEnrichmentResponse {
  ds_connections: DataSourceOnBoardingModel[];
  totalCount: number;
}

export enum OnboardingEvent {
  RELOAD_FUNNEL_CHART,
}

export interface GuidedTourCallbackProps {
  action: string;
  controlled: boolean;
  index: number;
  lifecycle: string;
  size: number;
  status: string;
  step: Step;
  type: string;
}

export type UpdateUserPreferencesData = { isPlannedGuidedTour: boolean };
