import React from 'react';
import {
  BigidFormField,
  BigidFormFieldTypes,
  PrimaryButton,
  TextFieldIconPositionEnum,
  TextFieldProps,
  TertiaryButton,
} from '@bigid-ui/components';
import { $state } from '../../../services/angularServices';
import { BigidEditIcon, BigidSaveIcon, BigidScheduleIcon, BigidHelpIcon } from '@bigid-ui/icons';
import {
  createScan,
  createScanTemplateFromProfileFields,
  fetchScanTemplateNames,
  getScanTemplateId,
} from './scanProfileConversionUtils';
import { GetConvertProfileToScanButtonsParams, ScanProfileConversionTypeEnum } from './scanProfileConversionTypes';
import { CONFIG } from '../../../../config/common';
import { notificationService } from '../../../services/notificationService';
import { fetchTemplate } from '../ScanCreateWizard/createScanService';
import { ParentScanType } from '../ScanTypes';
import { nameValidator, runScanProfileAndNavigateToActiveScans } from '../ScanUtils';
import { SAVE_SCAN_BUTTON, SCHEDULE_SCAN_BUTTON, SCAN_NOW_BUTTON } from '../ScanCreateWizard/ScheduleButtons';

const CREATE_SCAN_BUTTON = 'Create Scan';
const CREATE_TEMPLATE_BUTTON = 'Create Template';

const iconProp = {
  icon: {
    Icon: () => (
      <div style={{ marginRight: 12 }}>
        <BigidEditIcon size={'small'} staticMode />
      </div>
    ),
    position: TextFieldIconPositionEnum.right,
  } as TextFieldProps['icon'],
};

export const scanNameField = {
  name: 'name',
  label: 'Name your new scan',
  isRequired: true,
  type: BigidFormFieldTypes.TEXT,
  fieldProps: { placeholder: 'Scan Name', ...iconProp },
  validate: nameValidator,
};

export const selectExistingTemplateField: BigidFormField = {
  name: 'templateId',
  label: 'Select an existing template',
  type: BigidFormFieldTypes.SELECT,
  isRequired: true,
  fieldProps: {
    isSearchable: true,
    placeholder: 'Select Existing Template',
    isMulti: false,
    isAsync: true,
    loadOptions: fetchScanTemplateNames,
    shouldLoadInitialOptions: true,
    menuPosition: 'fixed',
  },
  validate: (val: string) => {
    if (!val) return 'Must select a template.';
    return false;
  },
};

export const conversionTypeField: BigidFormField = {
  name: 'conversionType',
  type: BigidFormFieldTypes.RADIO,
  misc: {
    fullWidth: false,
    column: true,
  },
  fieldProps: {
    horizontal: false,
  },
  options: [
    {
      label: 'Create a new scan and a new template',
      value: ScanProfileConversionTypeEnum.SCAN,
      icon: BigidHelpIcon,
      tooltip:
        'The new scan preserves the profile’s data sources and scheduling. The new template preserves the profile’s scan type, classifiers, and correlation sets.',
      id: 'create-new-scan-and-new-template',
    },
    {
      label: 'Create a new scan using an existing template',
      value: ScanProfileConversionTypeEnum.SCAN_WITH_EXISTING_TEMPLATE,
      icon: BigidHelpIcon,
      tooltip:
        'The new scan preserves the profile’s data sources and scheduling. ' +
        'The selected template defines the scan type, classifiers, and correlation sets for the scan.',
      id: 'create-new-scan-using-existing-template',
    },
    {
      label: 'Create a new template only',
      value: ScanProfileConversionTypeEnum.TEMPLATE,
      icon: BigidHelpIcon,
      tooltip:
        'The template preserves the profile’s scan type, classifiers, and correlation sets. ' +
        'Data source and scheduling settings will be lost',
      id: 'create-new-template',
    },
  ],
};

export const scanTemplateNameField: BigidFormField = {
  name: 'templateName',
  label: 'Name your new template',
  isRequired: true,
  type: BigidFormFieldTypes.TEXT,
  fieldProps: { placeholder: 'Template Name', ...iconProp },
  validate: nameValidator,
};

export const getConvertProfileToScanButtons = ({
  setIsSchedule,
  scanProfileConversionFormData,
  isSchedule,
  scanProfileFields,
  scanProfileConversionBasicHandlersAndRef,
}: GetConvertProfileToScanButtonsParams) => {
  const getIsConversionFormValid = async () => {
    return new Promise(resolve =>
      scanProfileConversionBasicHandlersAndRef.current?.validateAndSubmit(
        () => resolve(true),
        () => resolve(false),
      ),
    );
  };

  const createScanTemplateAndNavigateToTab = async () => {
    const isValid = await getIsConversionFormValid();
    if (!isValid) return;
    const templateResponse = await createScanTemplateFromProfileFields(
      scanProfileFields,
      scanProfileConversionFormData.templateName,
    );
    templateResponse && $state.go(CONFIG.states.SCANS_SCAN_TEMPLATES);
  };

  const updateScheduleState = () => setIsSchedule(!isSchedule);

  const convertProfileToScan = async (isSingleRunScan?: boolean) => {
    const isValid = await getIsConversionFormValid();
    if (!isValid) return;
    const scanTemplateId = await getScanTemplateId(scanProfileConversionFormData, scanProfileFields);
    if (!scanTemplateId) {
      return;
    }
    const isScanProfileCreationSuccess = await createScan(
      { scanProfileFields, scanProfileConversionFormData, isSchedule },
      scanTemplateId,
      isSingleRunScan,
    );
    if (!isScanProfileCreationSuccess) {
      return;
    }
    if (isSingleRunScan) {
      try {
        const scanTemplate = await fetchTemplate(scanTemplateId);
        await runScanProfileAndNavigateToActiveScans({
          scanProfileName: scanProfileConversionFormData.name,
          scanType: scanTemplate.scanType as ParentScanType,
        });
        return;
      } catch (e) {
        console.error(e);
        notificationService.error('Failed to run scan. see logs for more information');
      }
    }
    $state.go(CONFIG.states.SCANS_PLANNED_SCANS);
  };

  return scanProfileConversionFormData.conversionType === ScanProfileConversionTypeEnum.TEMPLATE ? (
    <PrimaryButton
      dataAid={'convert-profile-create-template-button'}
      onClick={createScanTemplateAndNavigateToTab}
      size="large"
      text={CREATE_TEMPLATE_BUTTON}
    />
  ) : (
    <>
      {isSchedule && (
        <>
          <TertiaryButton
            onClick={updateScheduleState}
            size="large"
            dataAid="convert-profile-cancel-button"
            text="Cancel"
          />
          <PrimaryButton
            onClick={() => convertProfileToScan()}
            size="large"
            dataAid={'convert-profile-create-scan-button'}
            text={CREATE_SCAN_BUTTON}
          />
        </>
      )}
      {!isSchedule && (
        <>
          <TertiaryButton
            size="large"
            startIcon={<BigidSaveIcon />}
            onClick={() => convertProfileToScan()}
            dataAid="convert-profile-save-scan-button"
            text={SAVE_SCAN_BUTTON}
          />
          <TertiaryButton
            onClick={updateScheduleState}
            size="large"
            dataAid="convert-profile-schedule-scan-button"
            startIcon={<BigidScheduleIcon />}
            text={SCHEDULE_SCAN_BUTTON}
          />
          <PrimaryButton
            onClick={() => convertProfileToScan(true)}
            size="large"
            dataAid={'convert-profile-scan-now-button'}
            text={SCAN_NOW_BUTTON}
          />
        </>
      )}
    </>
  );
};
