import React, { FC } from 'react';
import { EmptyTokensStateWrapper } from './styles';
import { BigidTokenIllustration } from '@bigid-ui/icons';
import { BigidBody1 } from '@bigid-ui/components';
import { useLocalTranslation } from '../translations';

export const TokenEmptyState: FC = () => {
  const { t } = useLocalTranslation('dialogs.createUser.tokenEmptyState');

  return (
    <EmptyTokensStateWrapper>
      <BigidTokenIllustration size="small" />
      <BigidBody1>{t('message')}</BigidBody1>
    </EmptyTokensStateWrapper>
  );
};
