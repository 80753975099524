import React, { FC } from 'react';
import { ClassifierModelState, RegexData, ModelData, TuningData } from '../classifierModelUtils';
import { ClassifierModelRegexCard } from './Cards components/ClassifierModelRegexCard';
import { ClassifierModelInputsNeeded } from './Cards components/ClassifierModelInputsNeeded';
import { generateDataAid } from '@bigid-ui/utils';
import { styled } from '@mui/material';
import { ClassifierModelCardWithModel } from './Cards components/ClassifierModelCardWithModel';
import { ClassifierModelPreparationCard } from './Cards components/ClassifierModelPreparationCard';

const CardsWrapper = styled('div')`
  display: flex;
  justify-content: space-around;
  padding: 24px;
`;

export interface ClassifierModelCardsProps {
  regexData: RegexData;
  tuningData: TuningData;
  modelData: ModelData[];
  onRender?: () => void;
  dataAid?: string;
}

export const ClassifierModelCards: FC<ClassifierModelCardsProps> = ({
  regexData,
  tuningData,
  modelData,
  onRender,
  dataAid = 'ClassifierModelCards',
}) => {
  const currentState = modelData[0]?.classifierModelStage?.state || ClassifierModelState.NOT_ENOUGH_INPUTS;
  const isModelBased = modelData[0]?.classifierModelStage?.isModelBased;
  const [newerModel, olderModel] = modelData;
  const { displayName } = tuningData;

  switch (currentState) {
    case ClassifierModelState.NOT_ENOUGH_INPUTS:
    case ClassifierModelState.WAITING_FOR_SESSION:
    case ClassifierModelState.GENERATING_MODEL:
      return (
        <CardsWrapper data-aid={generateDataAid(dataAid, ['cards', 'wrapper'])}>
          {isModelBased ? (
            <ClassifierModelCardWithModel
              modelData={olderModel ? olderModel : newerModel}
              isRightCard={false}
              onRender={onRender}
              displayName={displayName}
            />
          ) : (
            <ClassifierModelRegexCard regexAccuracy={regexData.regexAccuracyText} />
          )}
          {currentState == ClassifierModelState.NOT_ENOUGH_INPUTS ? (
            <ClassifierModelInputsNeeded tuningData={tuningData} />
          ) : (
            <ClassifierModelPreparationCard tuningData={tuningData} onRender={onRender} />
          )}
        </CardsWrapper>
      );

    case ClassifierModelState.MODEL_IS_OFFERED:
      return (
        <CardsWrapper data-aid={generateDataAid(dataAid, ['cards', 'wrapper'])}>
          {isModelBased ? (
            <ClassifierModelCardWithModel
              modelData={olderModel}
              isRightCard={false}
              onRender={onRender}
              displayName={displayName}
            />
          ) : (
            <ClassifierModelRegexCard regexAccuracy={regexData.regexAccuracyText} />
          )}
          <ClassifierModelCardWithModel modelData={newerModel} onRender={onRender} displayName={displayName} />
        </CardsWrapper>
      );

    case ClassifierModelState.MODEL_APPLYING:
      return (
        <CardsWrapper data-aid={generateDataAid(dataAid, ['cards', 'wrapper'])}>
          {isModelBased ? (
            <ClassifierModelCardWithModel
              modelData={olderModel}
              isRightCard={false}
              onRender={onRender}
              displayName={displayName}
            />
          ) : (
            <ClassifierModelRegexCard regexAccuracy={regexData.regexAccuracyText} />
          )}
          <ClassifierModelCardWithModel modelData={newerModel} onRender={onRender} displayName={displayName} />
        </CardsWrapper>
      );
  }
};
