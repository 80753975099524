import React, { useState, useContext } from 'react';
import { BigidTabs, BigidTabsItem } from '@bigid-ui/components';
import { CaseReportContainer } from '../CaseReportContainer';
import styled from '@emotion/styled';
import { MetaDataWidget } from '../CaseReportWidgets';
import { CaseRemediationSteps } from './CaseRemediationSteps';
import { ActionableInsightsTrackingEvents } from '../../../actionableInsightsTrackingUtil';
import { CaseSidePanelContext } from '../../hooks/CaseSidePanelContext';
import { useLocalTranslation } from '../../../translations';
import { getApplicationPreference } from '../../../../../services/appPreferencesService';

const InfoPanel = styled.div({
  height: 48,
  display: 'flex',
  alignItems: 'center',
  boxShadow: '0px 0px 5px rgba(0, 0, 0, 0.15)',
});

export const TabContent = ({ tab }: any) => {
  switch (true) {
    case tab === 0:
      return <CaseReportContainer />;
    case tab === 1:
      return <CaseRemediationSteps />;
    default:
      return null;
  }
};

export const CaseReportTabs = () => {
  const {
    caseSidePanelData: { policyName, dataSourceType },
  } = useContext(CaseSidePanelContext);
  const [selectedIndex, setSelectedIndex] = useState<number>(0);
  const handleTabchange = (value: number) => {
    setSelectedIndex(value);
  };
  const { t } = useLocalTranslation('RemediationSteps');
  const isRemediationStepsEnabled = getApplicationPreference('DSPM_REMEDIATION_STEPS_ENABLED');

  const tabs: BigidTabsItem[] = [
    {
      label: t('tabs.insights'),
    },
    ...(isRemediationStepsEnabled
      ? [
          {
            label: t('tabs.steps'),
            bi: {
              eventType: ActionableInsightsTrackingEvents.DATA_RISK_MANAGEMENT_REMEDIATION_STEPS_CLICK,
              eventData: { policyName, dataSourceType },
            },
          },
        ]
      : []),
  ];

  return (
    <>
      <InfoPanel>
        <MetaDataWidget />
      </InfoPanel>
      <div style={{ padding: '12px 24px', display: 'flex', alignItems: 'center' }}>
        <BigidTabs tabs={tabs} onChange={handleTabchange} selectedIndex={selectedIndex} />
      </div>
      <TabContent tab={selectedIndex} />
    </>
  );
};
