import React, { ReactNode } from 'react';
import { get } from 'lodash';
import ErrorIcon from '@mui/icons-material/Error';

import { parseFieldFiltersToSearchQuery } from '@bigid-ui/layout';

import {
  BigidGridColumnTypes,
  ChipFormatterProps,
  ChipsFormatterProps,
  IconFormatterProps,
  BigidGridWithToolbarProps,
} from '@bigid-ui/grid';

import { DataCatalogRecord, DetailedObjectType, getDataCatalogRecords } from '../DataCatalog/DataCatalogService';

import {
  BigidChip,
  BigidColors,
  BigidIcon,
  BigidIconSize,
  objectToQueryString,
  QueryParams,
} from '@bigid-ui/components';

import RDBIcon from '../../assets/icons/BigidRDB.svg';
import FileIcon from '../../assets/icons/BigidFile.svg';

export interface DataCatalogExtendedRecord extends Omit<DataCatalogRecord, 'tags'> {
  attributes?: ChipsFormatterProps;
  icon?: ReactNode;
  tags?: ReactNode[];
}

const RDB = 'rdb';
const FILE = 'file';
const YES = 'yes';
const NO = 'no';
const CONTAINS_PI = 'Contains PI';

const OPEN_ACCESS_CHIP_TYPE = 'open_access';
const OPEN_ACCESS = 'Open Access';
const NO_OPEN_ACCESS = 'No Open Access';

const HAS_DUPLICATES_CHIP_TYPE = 'has_duplicates';
const HAS_DUPLICATES = 'Has Duplicates';
const NO_DUPLICATES = 'No Duplicates';

const detailsIconMapping = new Map<string, ReactNode>([
  [RDB, <BigidIcon key={RDB} icon={RDBIcon} color={BigidColors.purple[300]} size={BigidIconSize.MEDIUM} />],
  [FILE, <BigidIcon key={FILE} icon={FileIcon} color={BigidColors.purple[300]} size={BigidIconSize.MEDIUM} />],
]);

const hasIndicatorMapping = new Map([
  [
    OPEN_ACCESS_CHIP_TYPE,
    new Map<string, ChipFormatterProps>([
      [
        YES,
        {
          chip: {
            icon: <BigidIcon color={BigidColors.red[700]} icon={ErrorIcon} size={BigidIconSize.MEDIUM} />,
            bgColor: BigidColors.red[100],
            size: 'small',
            label: OPEN_ACCESS,
          },
        },
      ],
      [
        NO,
        {
          chip: {
            bgColor: BigidColors.gray[50],
            size: 'small',
            label: NO_OPEN_ACCESS,
          },
        },
      ],
    ]),
  ],
  [
    HAS_DUPLICATES_CHIP_TYPE,
    new Map<string, ChipFormatterProps>([
      [
        YES,
        {
          chip: {
            bgColor: BigidColors.red[100],
            size: 'small',
            label: HAS_DUPLICATES,
          },
        },
      ],
      [
        NO,
        {
          chip: {
            bgColor: BigidColors.gray[50],
            size: 'small',
            label: NO_DUPLICATES,
          },
        },
      ],
    ]),
  ],
]);

const objectTypeIndicatorMapping = new Map<string, IconFormatterProps>([
  [
    RDB,
    {
      icon: {
        icon: RDBIcon,
        color: BigidColors.purple[300],
        label: 'RDB',
        size: BigidIconSize.MEDIUM,
      },
    },
  ],
  [
    DetailedObjectType.STRUCTURED,
    {
      icon: {
        icon: RDBIcon,
        color: BigidColors.purple[300],
        label: 'RDB',
        size: BigidIconSize.MEDIUM,
      },
    },
  ],
  [
    DetailedObjectType.STRUCTURED_FILE,
    {
      icon: {
        icon: FileIcon,
        color: BigidColors.purple[300],
        label: 'Structured File',
        size: BigidIconSize.MEDIUM,
      },
    },
  ],
  [
    DetailedObjectType.APP,
    {
      icon: {
        icon: RDBIcon,
        color: BigidColors.purple[300],
        label: 'APP',
        size: BigidIconSize.MEDIUM,
      },
    },
  ],
  [
    DetailedObjectType.UNSTRUCTURED,
    {
      icon: {
        icon: FileIcon,
        color: BigidColors.purple[300],
        label: 'UNSTRUCTURED',
        size: BigidIconSize.MEDIUM,
      },
    },
  ],
  [
    FILE,
    {
      icon: {
        icon: FileIcon,
        color: BigidColors.purple[300],
        label: 'File',
        size: BigidIconSize.MEDIUM,
      },
    },
  ],
]);

export const mapGridResults = (results: any[]) => {
  return results.map(record => {
    const { open_access = NO, total_pii_count = 0, duplicate_id = null } = record;
    const tags = [];
    const icon = detailsIconMapping.get(record.objectType);

    if (open_access.toLowerCase() === YES) {
      const { chip } = hasIndicatorMapping.get(OPEN_ACCESS_CHIP_TYPE).get(YES);
      tags.push(<BigidChip icon={chip.icon} bgColor={chip.bgColor} size={chip.size} label={chip.label} />);
    }

    if (total_pii_count > 0) {
      tags.push(<BigidChip bgColor={BigidColors.red[100]} size="small" label={CONTAINS_PI} />);
    }

    if (duplicate_id) {
      tags.push(<BigidChip bgColor={BigidColors.red[100]} size="small" label={HAS_DUPLICATES} />);
    }

    const attributes: ChipsFormatterProps = {
      chips: {
        value: record.attribute
          ? record.attribute.map((attr: string) => ({
              label: attr,
            }))
          : [],
        isDisabled: true,
      },
    };

    return {
      ...record,
      tags,
      icon,
      attributes,
    };
  });
};

export const gridConfig: BigidGridWithToolbarProps<DataCatalogExtendedRecord> = {
  showSortingControls: false,
  fetchData: async queryComponents => {
    const isSearchQuery = get(queryComponents, 'filter[0].id') === '_searchQuery_';
    const filter = isSearchQuery
      ? (get(queryComponents, 'filter[0].value') as string)
      : parseFieldFiltersToSearchQuery(queryComponents.filter);

    const query = objectToQueryString({
      ...(queryComponents as QueryParams),
      filter,
    });
    const { results, estimatedCount, total } = await getDataCatalogRecords(query.length > 0 ? `&${query}` : '');

    return {
      totalCount: total || estimatedCount,
      data: mapGridResults(results),
    };
  },
  columns: [
    {
      title: 'Object Name',
      name: 'objectName',
      isListColumn: true,
      type: BigidGridColumnTypes.TEXT,
      getCellValue: ({ objectName }) => objectName,
    },
    {
      title: 'Type',
      name: 'objectType',
      type: BigidGridColumnTypes.ICON,
      getCellValue: ({ objectType }) => objectTypeIndicatorMapping.get(objectType?.toLowerCase()),
    },
    {
      title: 'Data Source Name',
      name: 'source',
      type: BigidGridColumnTypes.TEXT,
      getCellValue: ({ source }) => source,
    },
    {
      title: 'Data Source Type',
      name: 'type',
      type: BigidGridColumnTypes.TEXT,
      getCellValue: ({ type }) => type,
    },
    {
      title: 'Full Object Name',
      name: 'fullObjectName',
      type: BigidGridColumnTypes.TEXT,
      getCellValue: ({ fullObjectName }) => fullObjectName,
    },
    {
      title: 'Attributes',
      name: 'attributes',
      type: BigidGridColumnTypes.CHIPS,
      width: 400,
      getCellValue: ({ attributes }) => attributes,
    },
    {
      title: 'Open Access',
      name: 'open_access',
      type: BigidGridColumnTypes.CHIP,
      getCellValue: ({ open_access }) => hasIndicatorMapping.get(OPEN_ACCESS_CHIP_TYPE).get(open_access.toLowerCase()),
    },
    {
      title: 'Has Duplicates',
      name: 'has_duplicates',
      type: BigidGridColumnTypes.CHIP,
      getCellValue: ({ has_duplicates }) =>
        hasIndicatorMapping.get(HAS_DUPLICATES_CHIP_TYPE).get(has_duplicates.toLowerCase()),
    },
  ],
};
