import { BigidGridQueryComponents, BigidGridRow } from '@bigid-ui/grid';
import { httpService } from '../../../services/httpService';
import { queryService } from '../../../services/queryService';

export type RiskLevel = 'High' | 'Medium' | 'Low';
export type Correlation = 'Customer' | 'Employee';
export type RemediationStatus = 'Requested' | 'In progress' | 'Completed';

export enum Visibility {
  External = 'External',
  Internal = 'Internal',
  Other = 'Other',
}

export enum AccountType {
  UserAccount = 'User Account',
  HeadlessAccount = 'Headless Account',
  Other = 'Other',
}

export interface UserSensitiveFileCategory {
  name: string;
  color: string;
  value: number;
}

export interface Counter {
  type: string;
  name: string;
  source: string;
  count: number;
}

export interface UserPermission extends BigidGridRow {
  _id: string;
  dataSource: string;
  name: string;
  username: string;
  email?: string;
  external?: boolean;
  headless?: boolean;
  sharedObjectsCount?: number;
  remediationTask?: {
    taskId: string;
    status: 'open' | 'resolved';
  };
  access?: string[];
  counters?: Counter[];
  subType?: string;
}

export interface UsersPermissionResponse {
  data: UserPermission;
}

export interface UsersPermissionsResponse {
  data: {
    totalCount?: number;
    offset: number;
    users: UserPermission[];
  };
}

export interface DistinctDataSource {
  value: string;
}

export interface UsersRemediationSummary {
  data: {
    created: number;
    updated: number;
    skipped: number;
    dsWithNoOwner: string[];
  };
}

export interface CategoryDetails {
  _id: string;
  type: string;
  name: string;
  color: string;
  display_name: string;
}

export interface CategoryWithCount {
  name: string;
  count: string;
}

export interface CategoryWithCountResponse {
  data: Array<CategoryWithCount>;
}

export const getUsersRecords = (bigidGridQuery: BigidGridQueryComponents) => {
  const gridConfigQuery = queryService.getGridConfigQuery(bigidGridQuery);

  return httpService.fetch<UsersPermissionsResponse>(`aci/users?${gridConfigQuery}`).then(({ data }) => data.data);
};

export const getDistinctDs = () => {
  return httpService
    .fetch<{ sources: DistinctDataSource[] }>(`aci/objects/distinct-sources`)
    .then(({ data }) => data.sources.filter(({ value }) => value));
};

export const getUserById = (userId: string) => {
  return httpService.fetch<UsersPermissionResponse>(`aci/users/${userId}`).then(({ data }) => data.data);
};

export const getCategoriesDetails = () => {
  return httpService.fetch<CategoryDetails[]>(`data_categories`).then(data => data);
};

export const getCategoriesOfOpenAccessFiles = () => {
  return httpService.fetch<CategoryWithCountResponse>(`aci/categories`).then(({ data }) => data.data);
};

export const remediateParticularUsers = (userIds: string[]) => {
  return httpService
    .post<UsersRemediationSummary, { ids: string[] }>(`aci/users/remediation`, {
      ids: userIds,
    })
    .then(({ data }) => data.data);
};
