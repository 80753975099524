import React, { MutableRefObject, useMemo } from 'react';
import { BigidFormField, BigidFormValues } from '@bigid-ui/components';
import {
  conversionTypeField,
  scanNameField,
  scanTemplateNameField,
  selectExistingTemplateField,
} from './scanProfileConversionFields';
import { ScanProfileConversionFormData, ScanProfileConversionTypeEnum } from './scanProfileConversionTypes';
import { isEqual } from 'lodash';
import { getOwnerField } from '../ScanUtils';

const fieldsByConversionTypeMap: Record<ScanProfileConversionTypeEnum, BigidFormField[]> = {
  [ScanProfileConversionTypeEnum.TEMPLATE]: [conversionTypeField, scanTemplateNameField],
  [ScanProfileConversionTypeEnum.SCAN_WITH_EXISTING_TEMPLATE]: [
    conversionTypeField,
    scanNameField,
    selectExistingTemplateField,
    getOwnerField('owners', false),
  ],
  [ScanProfileConversionTypeEnum.SCAN]: [
    conversionTypeField,
    scanNameField,
    scanTemplateNameField,
    getOwnerField('owners', false),
  ],
};

const convertFormValueToScanValue = (values: BigidFormValues) => {
  if (values?.owners?.[0]) {
    values.owners = [values?.owners?.[0]?.value];
  }
  if (values?.templateId) {
    values.templateId = values?.templateId?.[0]?.value;
  }

  return values;
};

export const useScanProfileConversionFormProps = (
  scanProfileConversionBasicHandlersAndRef: MutableRefObject<any>,
  scanProfileConversionFormData: ScanProfileConversionFormData,
  setScanProfileConversionFormData: React.Dispatch<React.SetStateAction<ScanProfileConversionFormData>>,
) => {
  return useMemo(() => {
    return {
      fields: fieldsByConversionTypeMap[scanProfileConversionFormData.conversionType],
      initialValues: scanProfileConversionBasicHandlersAndRef?.current?.getValues() || scanProfileConversionFormData,
      onChange: (newValues: BigidFormValues) => {
        const updatedConversionFormData = {
          ...scanProfileConversionFormData,
          ...convertFormValueToScanValue(newValues),
        };
        if (!isEqual(updatedConversionFormData, scanProfileConversionFormData)) {
          setScanProfileConversionFormData(updatedConversionFormData);
        }
      },
      controlButtons: false,
      stateAndHandlersRef: scanProfileConversionBasicHandlersAndRef,
    };
  }, [scanProfileConversionFormData, scanProfileConversionBasicHandlersAndRef, setScanProfileConversionFormData]);
};
