import React from 'react';
import { OverviewSection } from './OverviewSection';
import { useLocalTranslation } from '../../../../../translations';
import { Category } from '../../../../../../DataCatalog/DataCatalogAttributes';
import { BigidCategoryChip } from '@bigid-ui/components';
import { ShowMore } from './ShowMore';

type OverviewCategoriesProps = {
  categories: Category[];
};
export const OverviewCategories = ({ categories }: OverviewCategoriesProps) => {
  const [isExpanded, setIsExpanded] = React.useState(false);
  const { t } = useLocalTranslation('sidePanel.overview');

  const CATEGORIES_TO_DISPLAY_LIMIT = 6;
  const numOfExceededItems = categories.length - CATEGORIES_TO_DISPLAY_LIMIT;
  const categoriesToDisplay = isExpanded ? categories : categories.slice(0, CATEGORIES_TO_DISPLAY_LIMIT);
  return (
    <OverviewSection title={t('categories.title')} contentOrientation="horizontal" gap={8}>
      {categoriesToDisplay.map(category => {
        return (
          <BigidCategoryChip
            key={category.unique_name}
            categoryColor={category.color}
            categoryName={category.display_name}
          />
        );
      })}
      {categories.length > CATEGORIES_TO_DISPLAY_LIMIT && (
        <ShowMore isExpanded={isExpanded} setIsExpanded={setIsExpanded} numOfExceededItems={numOfExceededItems} />
      )}
    </OverviewSection>
  );
};
