import { BigidSkeletonGeneratorProps } from '@bigid-ui/components';

export const filterSkeletonConfig: BigidSkeletonGeneratorProps = {
  alignment: {
    type: 'flex',
    direction: 'row',
    vertical: 'center',
  },
  items: [
    {
      height: '32px',
      width: '200px',
      padding: '0 16px 0 0',
    },
    {
      height: '24px',
      width: '100px',
    },
    {
      height: '24px',
      width: '100px',
    },
    {
      height: '24px',
      width: '100px',
    },
  ],
};

export const summaryWidgetSkeletonConfig: BigidSkeletonGeneratorProps = {
  alignment: {
    type: 'grid',
    horizontal: 'center',
    vertical: 'center',
    areas: `
    'value value value icon'
    'title title title title'
    'primaryAction primaryAction secondaryAction secondaryAction'
  `,
  },
  items: [
    {
      area: 'value',
      height: '32px',
      width: '160px',
    },
    {
      area: 'icon',
      height: '32px',
      width: '40px',
    },
    {
      area: 'title',
      height: '32px',
      width: '208px',
    },
    {
      area: 'primaryAction',
      height: '24px',
      width: '100px',
    },
    {
      area: 'secondaryAction',
      height: '24px',
      width: '100px',
    },
  ],
};

export const pieChartWidgetSkeletonConfig: BigidSkeletonGeneratorProps = {
  alignment: {
    type: 'grid',
    horizontal: 'center',
    vertical: 'center',
    areas: `
    'title legend'
    'chart legend'
  `,
  },
  items: [
    {
      area: 'chart',
      height: '95px',
      width: '135px',
    },
    {
      area: 'title',
      height: '25px',
      width: '135px',
    },
    {
      area: 'legend',
      height: '130px',
      width: '85px',
    },
  ],
};

export const gridWidgetSkeletonConfig: BigidSkeletonGeneratorProps = {
  alignment: {
    type: 'flex',
    direction: 'column',
  },
  items: [
    {
      width: '100%',
      height: '32px',
      multiplicator: 10,
      padding: '8px',
    },
  ],
};

export const barChartWidgetSkeletonConfig: BigidSkeletonGeneratorProps = {
  alignment: {
    type: 'flex',
    direction: 'column',
    gap: 12,
  },
  items: [
    {
      width: '100px',
      height: '32px',
      padding: '0 20px',
    },
    {
      width: '100%',
      height: '12px',
      multiplicator: 3,
      padding: '0px 20px',
    },
  ],
};
