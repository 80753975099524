import { analyticsService } from '../../services/analyticsService';

export enum CurationEvents {
  // scan profile result page
  SCAN_PROFILE_REVIEW_FINDINGS_SELECT_BUTTON = 'SCAN_PROFILE_REVIEW_FINDINGS_SELECT_BUTTON',

  // select data source page
  CURATION_DATA_SOURCES_REVIEW = 'CURATION_DATA_SOURCES_REVIEW',
  CURATION_DATA_SOURCES_RESAMPLE = 'CURATION_DATA_SOURCES_RESAMPLE',

  // attribute list page
  CURATION_ATTRIBUTES_REVIEW = 'CURATION_ATTRIBUTES_REVIEW',
  CURATION_ATTRIBUTES_SELECT_ACTION_REMOVE_ATTRIBUTE = 'CURATION_ATTRIBUTES_SELECT_ACTION_REMOVE_ATTRIBUTE',
  CURATION_ATTRIBUTES_SELECT_ACTION_ASSIGN_FRIENDLY_NAMES = 'CURATION_ATTRIBUTES_SELECT_ACTION_ASSIGN_FRIENDLY_NAMES',
  CURATION_ATTRIBUTES_SELECT_ACTION_ASSIGN_CATEGORIES = 'CURATION_ATTRIBUTES_SELECT_ACTION_ASSIGN_CATEGORIES',
  CURATION_ATTRIBUTES_SELECT_ACTION_GO_TO_CLASSIFIER_PAGE = 'CURATION_ATTRIBUTES_SELECT_ACTION_GO_TO_CLASSIFIER_PAGE',
  CURATION_ATTRIBUTES_SELECT_ACTION_GO_TO_IGNORED_LIST = 'CURATION_ATTRIBUTES_SELECT_ACTION_GO_TO_IGNORED_LIST',

  // review fields page
  CURATION_FIELDS_INLINE_ACTION_APPROVE = 'CURATION_FIELDS_INLINE_ACTION_APPROVE',
  CURATION_FIELDS_INLINE_ACTION_REJECT = 'CURATION_FIELDS_INLINE_ACTION_REJECT',
  CURATION_FIELDS_BULK_ACTION_APPROVE = 'CURATION_FIELDS_BULK_ACTION_APPROVE',
  CURATION_FIELDS_BULK_ACTION_REJECT = 'CURATION_FIELDS_BULK_ACTION_REJECT',
  CURATION_FIELDS_ATTRIBUTE_PREVIEW_APRROVE_ACTION = 'CURATION_FIELDS_ATTRIBUTE_PREVIEW_APRROVE',
  CURATION_FIELDS_ATTRIBUTE_PREVIEW_REJECT_ACTION = 'CURATION_FIELDS_ATTRIBUTE_PREVIEW_REJECT',
  CURATION_FIELDS_ROW_CLICK = 'CURATION_FIELDS_ROW_CLICK',
  CURATION_FIELDS_TAB_CLICK = 'CURATION_FIELDS_TAB_CLICK',
  CURATION_FIELDS_ATTRIBUTE_PREVIEW_EXCLUDE_VALUE = 'CURATION_FIELDS_ATTRIBUTE_PREVIEW_EXCLUDE_VALUE',
  CURATION_FIELDS_ATTRIBUTE_PREVIEW_UNREVIEW_VALUE = 'CURATION_FIELDS_ATTRIBUTE_PREVIEW_UNREVIEW_VALUE',
  CURATION_FIELDS_ATTRIBUTE_PREVIEW_MORE_BUTTON_CLICK = 'CURATION_FIELDS_ATTRIBUTE_PREVIEW_MORE_BUTTON_CLICK',
  CURATION_FIELDS_ADDTIONAL_ATTRIBUTES_MODIFY_ATRRIBUTE_VIEW = 'CURATION_FIELDS_ADDTIONAL_ATTRIBUTES_MODIFY_ATRRIBUTE_VIEW',
  CURATION_FIELDS_ADDTIONAL_ATTRIBUTES_MODIFY_ATRRIBUTE_VIEW_BULK = 'CURATION_FIELDS_ADDTIONAL_ATTRIBUTES_MODIFY_ATRRIBUTE_VIEW_BULK',
  CURATION_FIELDS_ADDTIONAL_ATTRIBUTES_MODIFY_ATRRIBUTE_CANCEL = 'CURATION_FIELDS_ADDTIONAL_ATTRIBUTES_MODIFY_ATRRIBUTE_CANCEL',
  CURATION_FIELDS_ADDTIONAL_ATTRIBUTES_MODIFY_ATRRIBUTE_CANCEL_BULK = 'CURATION_FIELDS_ADDTIONAL_ATTRIBUTES_MODIFY_ATRRIBUTE_CANCEL_BULK',
  CURATION_FIELDS_ADDTIONAL_ATTRIBUTES_MODIFY_ATRRIBUTE_SAVE = 'CURATION_FIELDS_ADDTIONAL_ATTRIBUTES_MODIFY_ATRRIBUTE_SAVE',
  CURATION_FIELDS_ADDTIONAL_ATTRIBUTES_MODIFY_ATRRIBUTE_SAVE_BULK = 'CURATION_FIELDS_ADDTIONAL_ATTRIBUTES_MODIFY_ATRRIBUTE_SAVE_BULK',
  CURATION_FIELDS_ADDTIONAL_ATTRIBUTES_MODIFY_ATRRIBUTE_ADD = 'CURATION_FIELDS_ADDTIONAL_ATTRIBUTES_MODIFY_ATRRIBUTE_ADD',
  CURATION_FIELDS_ADDTIONAL_ATTRIBUTES_MODIFY_ATRRIBUTE_ADD_BULK = 'CURATION_FIELDS_ADDTIONAL_ATTRIBUTES_MODIFY_ATRRIBUTE_ADD_BULK',
  CURATION_FIELDS_ADDTIONAL_ATTRIBUTES_MODIFY_ATRRIBUTE_DELETE = 'CURATION_FIELDS_ADDTIONAL_ATTRIBUTES_MODIFY_ATRRIBUTE_DELETE',
  CURATION_FIELDS_ADDTIONAL_ATTRIBUTES_MODIFY_ATRRIBUTE_DELETE_BULK = 'CURATION_FIELDS_ADDTIONAL_ATTRIBUTES_MODIFY_ATRRIBUTE_DELETE_BULK',

  CURATION_FIELDS_BULK_ACTION_LOAD_PREVIEW = 'CURATION_FIELDS_BULK_ACTION_LOAD_PREVIEW',
}

export const trackEventCurationView = (eventType: CurationEvents, data?: Record<string, unknown>) => {
  analyticsService.trackManualEvent(eventType as any, data);
};
