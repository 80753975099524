import React, { ChangeEvent, FunctionComponent, useCallback, useEffect, useState } from 'react';
import { smallIdLicenseService } from '../../services/smallIdLicenseService';
import {
  BigidBody1,
  BigidDropZone,
  BigidHeading5,
  BigidLoader,
  BigidPaper,
  BigidSelect,
  BigidSelectOption,
  BigidTextField,
  BigidTooltip,
  PrimaryButton,
} from '@bigid-ui/components';
import { pageHeaderService } from '../../../common/services/pageHeaderService';
import { Divider, FormControl } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { getSensitivityClassificationsWithNoPagination } from '../SensitivityClassification/SensitivityClassificationService';
import { notificationService } from '../../services/notificationService';
import {
  downloadExecutiveReport,
  getCompanyLogo,
  getLatestReportDate,
  getReportConfig,
  saveCompanyLogo,
  saveCompanyName,
  updateReportConfig,
} from './ExecutiveReportService';
import { base64ToFile, toBase64 } from '../DSAR/DsarReportTemplates/FormSection/mappers';
import { BigidDownloadIcon, BigidInfoIcon, BigidReportsIllustration } from '@bigid-ui/icons';
import { isEqual } from 'lodash';

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    width: '100%',
    height: '100%',
    maxHeight: '100%',
    overflow: 'scroll',
    paddingBottom: 10,
  },
  paper: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: '100%',
    maxHeight: '100%',
    overflow: 'scroll',
  },
  header: {
    padding: 20,
    display: 'flex',
    flexDirection: 'column',
  },
  download: {
    display: 'flex',
    alignItems: 'center',
  },
  reportScheduleInfo: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: 15,
  },
  title: {
    marginBottom: 20,
  },
  lastReportDate: {
    marginBottom: 20,
  },
  reportLoaderWrapper: {
    position: 'relative',
    marginLeft: 40,
  },
  formWrapper: {
    width: '100%',
    padding: 20,
  },
  formPaper: {
    padding: '20px',
    paddingRight: '40vw',
  },
  formControl: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  saveButton: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },
  dropZoneWrapper: {
    width: '100%',
  },
  label: {
    marginRight: 40,
    width: 300,
  },
}));

const NO_CONFIG_MESSAGE = 'Please set a report configuration';

const delay = (ms: number) => new Promise(res => setTimeout(res, ms));
const acceptedFileTypes = ['.jpeg', '.png', '.jpg'];

const formatLatestReportDate = (reportDate: string) => {
  let formattedDate: string;
  if (!reportDate) {
    formattedDate = 'No available report was found';
  } else {
    const date = new Date(reportDate);
    formattedDate = `${date?.toLocaleDateString()} ${date?.toLocaleTimeString()}`;
  }
  return `Last generated report : ${formattedDate}`;
};

interface ExecutiveReportSettingsModel {
  companyName: string;
  companyLogo: File;
  primarySc: BigidSelectOption[];
  secondarySc: BigidSelectOption[];
  latestReportDate: string;
  monthsToCompare: number;
  isSmallid: boolean;
}

const getCleanFormState = (): ExecutiveReportSettingsModel => {
  return {
    companyName: '',
    companyLogo: undefined as File,
    primarySc: [],
    secondarySc: [],
    latestReportDate: '',
    monthsToCompare: null,
    isSmallid: false,
  };
};

export const ExecutiveReport: FunctionComponent = () => {
  const classes = useStyles({});

  const [formData, setFormData] = useState<ExecutiveReportSettingsModel>(getCleanFormState());
  const [initialFormData, setInitialFormData] = useState<ExecutiveReportSettingsModel>(formData);
  const [logoPreview, setLogoPreview] = useState<string>('');
  const [availableScConfigs, setAvailableScConfigs] = useState<BigidSelectOption[]>([]);
  const [scConfigs, setScConfigs] = useState<BigidSelectOption[]>([]);
  const [isFormLoading, setIsFormLoading] = useState<boolean>(false);
  const [isReportLoading, setIsReportLoading] = useState<boolean>(false);

  useEffect(() => {
    pageHeaderService.setTitle({ pageTitle: 'Executive Report' });

    const fetchData = async () => {
      setIsFormLoading(true);
      try {
        const { scConfigs } = await getSensitivityClassificationsWithNoPagination();
        const { companyName, primarySc, secondarySc, monthsToCompare, isSmallid } = await getReportConfig();
        const { logo } = await getCompanyLogo();
        const { latestReportDate } = await getLatestReportDate();
        const logoFile = await base64ToFile(logo, 'company-logo');
        const configsAsOptions = scConfigs.map(({ name }) => ({ label: name, value: name }));
        setLogoPreview(logo);
        const fetchedFormData = {
          ...getCleanFormState(),
          companyName,
          monthsToCompare,
          companyLogo: logoFile,
          latestReportDate,
          secondarySc: [{ label: secondarySc, value: secondarySc }],
          primarySc: [{ label: primarySc, value: primarySc }],
          isSmallid,
        };
        setFormData(fetchedFormData);
        setInitialFormData(fetchedFormData);
        setAvailableScConfigs(configsAsOptions);
        setScConfigs(configsAsOptions);
      } catch ({ message }) {
        console.error(`An error has occurred: ${message}`);
        notificationService.error('An error has occurred');
      } finally {
        setIsFormLoading(false);
      }
    };
    fetchData();
  }, []);

  const handleOnDrop = useCallback(async (files: File[]) => {
    if (!files || !files.length || !files[0]) {
      setLogoPreview(null);
      setFormData(formData => ({ ...formData, companyLogo: null }));
      return;
    }
    const file = files[0];
    const fileAsBase64 = await toBase64(file);
    setLogoPreview(fileAsBase64);
    setFormData(formData => ({ ...formData, companyLogo: file }));
  }, []);

  const handleNameChange = useCallback(({ target }: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>): void => {
    setFormData(prevFormData => ({ ...prevFormData, companyName: target.value }));
  }, []);

  const handlePrimaryChange = useCallback(
    (selectedOption: BigidSelectOption[]) => {
      setFormData(prevFormData => ({ ...prevFormData, primarySc: selectedOption }));
      setAvailableScConfigs(scConfigs.filter(config => config.value !== selectedOption[0].value));
    },
    [scConfigs],
  );

  const handleSecondaryChange = useCallback(
    (selectedOption: BigidSelectOption[]) => {
      setFormData(prevFormData => ({ ...prevFormData, secondarySc: selectedOption }));
      setAvailableScConfigs(scConfigs.filter(config => config.value !== selectedOption[0].value));
    },
    [scConfigs],
  );

  const handleConfigSave = async () => {
    const { companyName, companyLogo, primarySc, secondarySc } = formData;
    const isSmallid = smallIdLicenseService.isSmallIDLicense() || false;
    let primaryScValue = primarySc?.[0]?.value || '';
    let secondaryScValue = secondarySc?.[0]?.value || '';
    if (isSmallid) {
      primaryScValue = primaryScValue === '' ? 'Sensitivity' : primaryScValue;
      secondaryScValue = secondaryScValue === '' ? 'Priority' : secondaryScValue;
    }
    if (!companyName || !companyLogo) {
      notificationService.warning('Please fill in all the required fields');
      return;
    }
    try {
      await saveCompanyLogo(logoPreview);
      await saveCompanyName(companyName);
      await updateReportConfig({
        primarySc: primaryScValue,
        secondarySc: secondaryScValue,
        monthsToCompare: 3,
        isSmallid: isSmallid,
      });
      setInitialFormData(formData);
      notificationService.success('Executive report configuration saved');
    } catch (err) {
      console.error(err);
      notificationService.error('Could not create report');
    }
  };

  const handleDownloadReport = async () => {
    setIsReportLoading(true);
    try {
      await downloadExecutiveReport();
      await delay(3000);
    } catch (err) {
      console.error(err);
      notificationService.error('Could not create report');
    } finally {
      setIsReportLoading(false);
    }
  };

  return (
    <div className={classes.root}>
      {isFormLoading ? (
        <BigidLoader />
      ) : (
        <BigidPaper classes={{ root: classes.paper }}>
          <div className={classes.header}>
            <BigidHeading5 className={classes.title}>Download Report</BigidHeading5>
            <BigidBody1 className={classes.lastReportDate}>
              {formatLatestReportDate(formData.latestReportDate)}
            </BigidBody1>
            <BigidReportsIllustration />
            <div className={classes.download}>
              <BigidTooltip placement={'top'} isDisabled={!!formData?.monthsToCompare} title={NO_CONFIG_MESSAGE}>
                <div>
                  <PrimaryButton
                    disabled={isReportLoading || !formData.latestReportDate || !formData.monthsToCompare}
                    dataAid="download-executive-report-pdf-button"
                    size="medium"
                    onClick={handleDownloadReport}
                    startIcon={<BigidDownloadIcon />}
                    text="Download Last Report"
                  />
                </div>
              </BigidTooltip>
              <div className={classes.reportLoaderWrapper}>{isReportLoading && <BigidLoader />}</div>
            </div>
          </div>
          <Divider variant={'fullWidth'} orientation="horizontal" />
          <div className={classes.formWrapper}>
            <BigidHeading5 className={classes.title}>Report Configuration</BigidHeading5>
            <div className={classes.reportScheduleInfo}>
              <BigidInfoIcon />
              <BigidBody1>
                The report is scheduled to run daily. Configuration changes will apply to the next run
              </BigidBody1>
            </div>
            <BigidPaper classes={{ root: classes.formPaper }}>
              <FormControl classes={{ root: classes.formControl }} fullWidth margin="normal">
                <BigidBody1 className={classes.label}>Company Name</BigidBody1>
                <BigidTextField onChange={handleNameChange} required value={formData?.companyName} placeholder="Name" />
              </FormControl>
              <FormControl classes={{ root: classes.formControl }} fullWidth margin="normal">
                <BigidBody1 className={classes.label}>Company Logo</BigidBody1>
                <div className={classes.dropZoneWrapper}>
                  <BigidDropZone
                    multiple={false}
                    accept={acceptedFileTypes}
                    files={formData.companyLogo ? [formData.companyLogo] : []}
                    onDrop={handleOnDrop}
                    isMandatory
                  />
                </div>
              </FormControl>
              <FormControl classes={{ root: classes.formControl }} fullWidth margin="normal">
                <BigidBody1 className={classes.label}>Primary sensitivity-classification group</BigidBody1>
                <BigidSelect
                  dataAid="executive-report-select-primary-sc"
                  onChange={handlePrimaryChange}
                  options={availableScConfigs}
                  value={formData?.primarySc}
                />
              </FormControl>
              <FormControl classes={{ root: classes.formControl }} fullWidth margin="normal">
                <BigidBody1 className={classes.label}>Secondary sensitivity-classification group</BigidBody1>
                <BigidSelect
                  dataAid="executive-report-select-secondary-sc"
                  onChange={handleSecondaryChange}
                  options={availableScConfigs}
                  value={formData?.secondarySc}
                />
              </FormControl>
              <FormControl classes={{ root: classes.saveButton }} fullWidth margin="normal">
                <PrimaryButton
                  dataAid={'executive-report-save-form'}
                  disabled={isEqual(initialFormData, formData)}
                  onClick={handleConfigSave}
                  size={'medium'}
                  text="Save"
                />
              </FormControl>
            </BigidPaper>
          </div>
        </BigidPaper>
      )}
    </div>
  );
};
