import { module } from 'angular';
import template from './correspondence.component.html';
const app = module('app');

app.component('correspondence', {
  template,
  controllerAs: 'correspondenceModel',
  controller: [
    'taskListService',
    '$timeout',
    '$document',
    'notificationService',
    function (taskListService, $timeout, $document, notificationService) {
      let correspondenceModel = this;

      correspondenceModel.sendBtnDisabled = true;
      correspondenceModel.comment = '';

      const commentsContainer = $document[0].getElementById('taskCommentsWrapper');

      correspondenceModel.$onChanges = () => {
        correspondenceModel.currentTask = this.task;
        correspondenceModel.comment = '';
      };

      correspondenceModel.onMentionsTextareaChanged = (value, mentions) => {
        correspondenceModel.sendBtnDisabled = value === '';
        correspondenceModel.comment = value;
        correspondenceModel.mentionedUsers = mentions;
      };

      let prepareCommentsSection = () => {
        let prevAlignment = '';
        correspondenceModel.comments.forEach((item, index, arr) => {
          if (index == 0) {
            item['alignment'] = 'left-aligned';
          } else {
            arr[index - 1]['user'] == item.user
              ? (item['alignment'] = prevAlignment)
              : prevAlignment == 'left-aligned'
              ? (item['alignment'] = 'right-aligned')
              : (item['alignment'] = 'left-aligned');
          }
          prevAlignment = item['alignment'];
        });

        setTimeout(() => correspondenceModel.scrollToBottom, 500);
      };

      let sortDates = (a, b) => new Date(b.created_at) - new Date(a.created_at);

      let getAllCommentsByTaskId = id => {
        taskListService.getAllCommentsByTaskId(id).then(
          result => {
            correspondenceModel.currentTask.comments = result.data.comments.sort(sortDates).reverse();
            prepareCommentsSection();
          },
          () => {
            notificationService.error('An error has occurred!');
          },
        );
      };

      let createNewComment = (data, id) => {
        taskListService.createNewComment(data, id).then(
          () => {
            correspondenceModel.mentionedUsers = [];
            correspondenceModel.comment = '';
            correspondenceModel.currentTask.comment = '';
            getAllCommentsByTaskId(correspondenceModel.currentTask._id);
            notificationService.success('New comment has successfully been added');
          },
          () => {
            correspondenceModel.sendBtnDisabled = false;
            notificationService.error('An error has occurred!');
          },
        );
      };

      let send = () => {
        correspondenceModel.sendBtnDisabled = true;
        createNewComment(
          {
            text: correspondenceModel.comment,
            mentions: correspondenceModel.mentionedUsers,
          },
          correspondenceModel.currentTask._id,
        );
      };

      correspondenceModel.onMentionsTextareaSubmit = (value, mentions) => {
        correspondenceModel.comment = value;
        correspondenceModel.mentionedUsers = mentions;
        send();
      };

      correspondenceModel.onAssigneeTextareaChanged = (value, mentions) => {
        correspondenceModel.reassignInput = mentions[0];
      };

      correspondenceModel.addComment = () => {
        if (
          (correspondenceModel.currentTask && correspondenceModel.currentTask.status === 'resolved') ||
          correspondenceModel.sendBtnDisabled
        ) {
          return;
        }
        send();
      };

      correspondenceModel.handleInput = event => {
        if (event.which == 13 && !event.shiftKey) {
          event.preventDefault();
          if (!correspondenceModel.sendBtnDisabled) {
            send();
          }
        }
        correspondenceModel.mentionPopupInput = event;
      };

      // placeholder during initialization
      correspondenceModel.scrollToBottom = () => void 0;

      correspondenceModel.onTaskCommentsScrollbarReady = ({ ps, scrollToBottom }) => {
        correspondenceModel.scrollToBottom = () => {
          $timeout(
            () => {
              ps.update();
              scrollToBottom();
            },
            10,
            false,
          );
        };
      };
    },
  ],
  bindings: {
    task: '<',
  },
});
