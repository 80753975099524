import { useState, useEffect, useCallback } from 'react';
import { $transitions } from '../../../services/angularServices';
import { useOnLeavingHandler } from './useOnLeavingHandler';
import { $q } from 'ngimport';

export interface RouteLeavingContent {
  header?: string;
  body?: string;
}

export type OnRouteLeaveHandler = () => Promise<boolean>;
type OnCancelLeaveDialogHandler = () => void;

export const useRouteLeavingListener = (
  onLeaveHandler?: OnRouteLeaveHandler,
  onCancelLeaveDialog?: OnCancelLeaveDialogHandler,
) => {
  const [isRouteLeavingEnabled, setIsRouteLeavingEnabled] = useState<boolean>(false);
  const [dialogHeader, setDialogHeader] = useState<string>('Leaving');
  const [dialogBody, setDialogBody] = useState<string>('Do you want to leave this page');
  const onLeavingComp = useOnLeavingHandler();
  const updateIsRouteLeavingEnabled = (isEnabled: boolean) => {
    if (onLeaveHandler) {
      return setIsRouteLeavingEnabled(isEnabled);
    }
    if (isEnabled !== isRouteLeavingEnabled) {
      setIsRouteLeavingEnabled(isEnabled);
    }
  };

  const updateRouteLeavingPrompt = useCallback((content: RouteLeavingContent) => {
    const { header, body } = content;
    if (header) {
      setDialogHeader(header);
    }
    if (body) {
      setDialogBody(body);
    }
  }, []);

  useEffect(() => {
    const beforeUnloadListener = (event: BeforeUnloadEvent) => {
      event.preventDefault();
      event.returnValue = '';
      return dialogBody;
      // This is for the browser warning box message, eventhough latest browser versions will display default message which can't be modified.
    };

    if (isRouteLeavingEnabled) {
      window.addEventListener('beforeunload', beforeUnloadListener, true);
    } else {
      window.removeEventListener('beforeunload', beforeUnloadListener, true);
    }

    const deregisterLeaveFormInterceptor = $transitions.onBefore({}, transition => {
      return new $q(resolve => {
        if (isRouteLeavingEnabled && transition.params()?.abortDisabled !== 'true') {
          if (onLeaveHandler) {
            onLeaveHandler().then(resolve);
          } else {
            onLeavingComp({
              onLeave: () => {
                resolve();
              },
              onClose: () => {
                onCancelLeaveDialog();
                resolve(false);
              },
              header: dialogHeader,
              body: dialogBody,
            });
          }
        } else {
          resolve();
        }
      }) as Promise<boolean>;
    });

    return () => {
      deregisterLeaveFormInterceptor();
      window.removeEventListener('beforeunload', beforeUnloadListener, true);
    };
  }, [dialogBody, dialogHeader, isRouteLeavingEnabled, onLeaveHandler, onLeavingComp]);

  return {
    updateIsRouteLeavingEnabled,
    updateRouteLeavingPrompt,
  };
};
