import React, { forwardRef, memo, useImperativeHandle, useMemo, useRef, useState } from 'react';
import {
  BigidDropZone,
  BigidForm,
  BigidFormProps,
  BigidFormRenderProps,
  BigidFormStateAndHandlers,
  BigidFormValidateOnTypes,
  BigidFormValues,
} from '@bigid-ui/components';
import { CertificateFormData, ForwardFormRef } from './types';
import { ACCEPTED_FILE_TYPES, getCertificateFileTypes, getFormFields, getInitialValues } from './utils';
import { useLocalTranslation } from './translations';
import { CertificateType } from '../CertificatesManagement';
import { Content, FormWrapper } from '../../Credentials/CredentialsStyles';

interface CertificateFormProps {
  row: CertificateFormData;
  formControls: React.MutableRefObject<BigidFormStateAndHandlers>;
  setFormIsTouched: (isTouched: boolean) => void;
}

export const CertificatesManagementForm = forwardRef<ForwardFormRef, CertificateFormProps>(
  ({ row, formControls, setFormIsTouched }, ref) => {
    const { isPending } = row;
    const setFieldValueRef = useRef<(fieldname: string, value: BigidFormValues) => void>();
    const formElementRef = useRef<HTMLDivElement>();
    const [certFileFieldFormValue, setCertFileFieldFormValue] = useState<File>(null);
    const [configFileFieldFormValue, setConfigCertFileFieldFormValue] = useState<File>(null);
    const [fileType, setFileType] = useState<CertificateType>(null);
    const { t } = useLocalTranslation();
    const isKerberos = fileType === CertificateType.KERBEROS;

    const fields = getFormFields(row, !isPending);

    useImperativeHandle(
      ref,
      () => ({
        formElement: formElementRef.current,
      }),
      [],
    );

    const initialValues = useMemo(() => getInitialValues(fields, row), [row, fields]);

    const handleOnDrop = (files: File[], fileType: 'cert' | 'configFile') => {
      const cert = files?.length ? files[0] : undefined;
      if (fileType === 'cert') {
        setCertFileFieldFormValue(cert);
      } else {
        setConfigCertFileFieldFormValue(cert);
      }
      setFieldValueRef.current?.(fileType, cert);
    };

    const formProps: BigidFormProps = useMemo(
      () => ({
        controlButtons: false,
        validateOn: BigidFormValidateOnTypes.SUBMIT,
        stateAndHandlersRef: formControls,
        initialValues,
        displayFormLevelError: true,
        fields,
        onChange: (values, { setFieldValue }) => {
          setFieldValueRef.current = setFieldValue;
          const currentCertificateType = values.usage[0]?.value;
          const certificateTypesToDisplay = getCertificateFileTypes(currentCertificateType);
          if (!certificateTypesToDisplay.map(({ value }) => value).includes(values.type[0]?.value)) {
            setFieldValue('type', certificateTypesToDisplay);
          }
          setFileType(values.type[0]?.value);

          setTimeout(() => {
            setFormIsTouched(formControls.current?.formTouched);
          }, 0);
        },
        renderForm: ({ renderField, formLevelError, formLevelErrorDisplayed, getValues }: BigidFormRenderProps) => {
          const currentFormValues = getValues();
          const currentCertificateType = currentFormValues.usage?.[0].value;
          const certificateTypesToDisplay = getCertificateFileTypes(currentCertificateType);
          return (
            <>
              {fields
                .filter(({ name }) => {
                  const typeValue = getValues().type;
                  return !(name === 'configFile' && typeValue !== CertificateType.KERBEROS);
                })
                .map(({ name }) => {
                  if (name === 'type') {
                    return renderField(name, { dropDownOptions: certificateTypesToDisplay });
                  } else {
                    return renderField(name);
                  }
                })}
              <div data-aid={'Certificate-management-form-level-error'}>
                {formLevelErrorDisplayed && formLevelError}
              </div>
            </>
          );
        },
      }),
      [fields, formControls, initialValues, setFormIsTouched],
    );

    return (
      <Content ref={formElementRef}>
        <FormWrapper>
          <BigidForm {...formProps} />
          <BigidDropZone
            label={isKerberos ? t('form.certFileKerberos') : t('form.certFile')}
            accept={isKerberos ? ['.keytab'] : ACCEPTED_FILE_TYPES}
            files={certFileFieldFormValue ? [certFileFieldFormValue] : []}
            onDrop={files => handleOnDrop(files, 'cert')}
          />
          {isKerberos && (
            <BigidDropZone
              label={t('form.configFile')}
              accept={['.conf']}
              files={configFileFieldFormValue ? [configFileFieldFormValue] : []}
              onDrop={files => handleOnDrop(files, 'configFile')}
            />
          )}
        </FormWrapper>
      </Content>
    );
  },
);

export const CertificatesManagementFormMemo = memo(CertificatesManagementForm);
