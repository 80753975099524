import React, { FC, useContext, useEffect, useState } from 'react';
import {
  BigidChartChip,
  BigidColors,
  BigidColorsV2,
  BigidPieChart,
  PieChartData,
  SecondaryButton,
} from '@bigid-ui/components';
import { Divider, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { ObjectsByType, TOTAL_OBJECTS_WITH_PII, TOTAL_OBJECTS_WITH_PII_AND_OPEN_ACCESS } from './consts';
import { AccessIntelligenceDashboardContext } from './AccessIntelligenceDashboardContext';

const useStyles = makeStyles(() => ({
  wrapper: {
    backgroundColor: '#fff',
    borderRadius: 12,
    padding: '15px 20px',
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    alignItems: 'center',
  },
  chartContainer: {
    display: 'flex',
    marginTop: 60,
  },
  chipsWrapper: {
    width: '12vw',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    marginLeft: 32,
  },
  divider: {
    background: BigidColors.borderLight,
    width: '100%',
    margin: '17px 0 17px 0',
  },
  inventoryInfo: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: 20,
  },
  dataSourcesWithPiiAndOpenAccessValue: {
    marginLeft: 5,
    marginRight: 5,
    color: BigidColorsV2.red[600],
  },
}));

const convertRawDataToPieCategories = (objectsByType: ObjectsByType): PieChartData<string>[] => {
  const realTotalObjectsWithPii =
    objectsByType[TOTAL_OBJECTS_WITH_PII] - objectsByType[TOTAL_OBJECTS_WITH_PII_AND_OPEN_ACCESS]; //FIXME: backend fix is required here instead
  return [
    {
      category: 'Without Open Access',
      value: realTotalObjectsWithPii,
      color: BigidColorsV2.orange[700],
    },
    {
      category: 'With Open Access',
      value: objectsByType[TOTAL_OBJECTS_WITH_PII_AND_OPEN_ACCESS],
      color: BigidColorsV2.red[600],
    },
  ];
};

export const ObjectsWithSensitiveDataChart: FC = () => {
  const classes = useStyles({});
  const { dashboardData, goToInventoryWithOpenAccessFilter } = useContext(AccessIntelligenceDashboardContext);
  const [chartData, setChartData] = useState<PieChartData<string>[] | null>([]);
  const [totalCount, setTotalCount] = useState<number>(0);
  const { objectsByType } = dashboardData;

  useEffect(() => {
    if (objectsByType) {
      const chartData = convertRawDataToPieCategories(objectsByType);
      setChartData(chartData);
      setTotalCount(objectsByType[TOTAL_OBJECTS_WITH_PII]);
    } else {
      setChartData([]);
      setTotalCount(0);
    }
  }, [objectsByType]);

  const isDataEmpty = () => {
    if (!objectsByType) {
      return true;
    }
    return objectsByType[TOTAL_OBJECTS_WITH_PII_AND_OPEN_ACCESS] === 0;
  };

  return (
    <div className={classes.wrapper} data-aid={'sensitiveByObjectsInsight'}>
      <Typography variant={'h4'} data-aid="ImportDataSourcesTitle">
        Objects With Sensitive Data
      </Typography>
      <div className={classes.chartContainer}>
        <BigidPieChart data={chartData} entityName={'Objects'} total={totalCount} />
        <div className={classes.chipsWrapper}>
          {chartData.map(({ color, category, value }, key) => (
            <BigidChartChip color={color} label={category} value={value} key={key} />
          ))}
        </div>
      </div>
    </div>
  );
};
