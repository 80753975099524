import { module } from 'angular';
const app = module('app');

app.factory('labelingService', [
  '$http',
  'appSettings',
  function ($http, appSettings) {
    return {
      getLabels: () =>
        $http
          // .get(`${appSettings.serverPath}/${appSettings.version}/labeler`)
          .get(`${appSettings.serverPath}/${appSettings.version}/labels`)
          .then(response => response),

      getLabelByName: id =>
        $http
          // .get(`${appSettings.serverPath}/${appSettings.version}/labels/${id}`)
          .get(`${appSettings.serverPath}/${appSettings.version}/labeler/${id}`)
          .then(response => response),

      createLabel: data =>
        $http
          // .post(`${appSettings.serverPath}/${appSettings.version}/labeler/`, data)
          .post(`${appSettings.serverPath}/${appSettings.version}/labels/`, data)
          .then(response => response),

      updateLabel: (id, data) =>
        $http
          // .put(`${appSettings.serverPath}/${appSettings.version}/labeler/${id}`, data)
          .put(`${appSettings.serverPath}/${appSettings.version}/labels/${id}`, data)
          .then(response => response),

      deleteLabel: id =>
        $http
          // .delete(`${appSettings.serverPath}/${appSettings.version}/labeler/${id}`)
          .delete(`${appSettings.serverPath}/${appSettings.version}/labels/${id}`)
          .then(response => response),

      importLabels: (data = {}) =>
        $http
          .post(`${appSettings.serverPath}/${appSettings.version}/labeler/mip-fetch`, data)
          .then(response => response),

      getLabelerSettings: () =>
        $http.get(`${appSettings.serverPath}/${appSettings.version}/labeler/mip-settings`).then(response => response),

      setLabelerSettings: data =>
        $http
          .post(`${appSettings.serverPath}/${appSettings.version}/labeler/mip-settings`, data)
          .then(response => response),
    };
  },
]);
