import { EntityEvents, entityEventsEmitter } from '@bigid-ui/components';
import { $state } from '../../../services/angularServices';
import { openCreateReportTemplateDialog } from './Dialogs/CreateReportTemplateDialog';

export const createNewTemplateExecuter = async (triggeredBy: 'action' | 'externally') => {
  const { newTemplateId, wasCreated } = await openCreateReportTemplateDialog();
  if (wasCreated) {
    $state.go('.', { ...$state.params, templateId: newTemplateId }, { reload: false, notify: false });
  }
  if (triggeredBy === 'action') {
    return { shouldGridReload: wasCreated, shouldClearSelection: wasCreated };
  } else if (triggeredBy === 'externally') {
    entityEventsEmitter.emit(EntityEvents.RELOAD);
  }
};
