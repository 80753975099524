import { module } from 'angular';
import { httpService } from '../../react/services/httpService';
const app = module('app');

app.factory('trendSnapshotsService', () => {
  return {
    getRiskTrendSnapshots: () => {
      return httpService.fetch('risk_snapshots').then(response => response.data);
    },
    getPiisTrendSnapshots: () => {
      return httpService.fetch('pii-summary-snapshots').then(response => response.data);
    },
    getAttributesTrendSnapshots: () => {
      return httpService.fetch('attributes-summary-snapshots').then(response => response.data);
    },
    getDataSourcesTrendSnapshots: () => {
      return httpService.fetch('datasources-summary-snapshots').then(response => response.data);
    },
    getActivityHighlightsTrendSnapshots: () => {
      return httpService.fetch('activity-highlights-snapshots').then(response => response.data);
    },
  };
});
