import { useQuery } from 'react-query';
import { getDetailsByObjectName } from '../../../../../../DataCatalog/DataCatalogDetails/DataCatalogDetailsService';

export const useGetObjectDetails = (fullyQualifiedName: string) => {
  return useQuery(['getObjectDetails', fullyQualifiedName], async () => {
    try {
      return getDetailsByObjectName(fullyQualifiedName);
    } catch ({ message }) {
      console.error(`An error has occurred: ${message}`);
    }
  });
};
