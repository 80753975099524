import React, { FC } from 'react';
import { EntitySection } from './EntitySection/EntitySection';
import { useQuery } from 'react-query';
import { fetchQuickSearchResults } from '../dataExplorerQuickSearchService';
import { BigidLoader } from '@bigid-ui/components';
import { CatalogListItem } from './EntitySection/ListItems/CatalogListItem';
import { GenericListItem } from './EntitySection/ListItems/GenericListItem';
import { styled } from '@mui/material';
import { $state } from '../../../../services/angularServices';
import { CONFIG } from '../../../../../config/common';
import {
  sendDataExplorerQuickSearchListItemClickBiAnalytics,
  sendDataExplorerQuickSearchViewAllClickBiAnalytics,
} from '../../services/analytics';
import { mapFieldValueToBiqlString } from '../../../../services/filtersToBiqlService';
import { generateDataAid } from '@bigid-ui/utils';

const SHOW_ALL_THRESHOLD = 2;

const SectionContainer = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

type QuickSearchPopperProps = {
  searchText: string;
};

export const QuickSearchResults: FC<QuickSearchPopperProps> = ({ searchText }) => {
  const {
    data: { data },
    isLoading,
    isFetching,
  } = useQuery(['quickSearch', searchText], () => fetchQuickSearchResults(searchText), {
    enabled: searchText.length > 2,
    placeholderData: {
      data: {
        catalog: { count: 0, results: [] },
        datasource: { count: 0, results: [] },
        policy: { count: 0, results: [] },
      },
    },
    keepPreviousData: true,
  });

  const handleShowAllClick = (activeTab: string) => {
    sendDataExplorerQuickSearchViewAllClickBiAnalytics({ entityName: activeTab });
    $state.go(CONFIG.states.CATALOG_SEARCH_RESULTS, { activeTab, query: searchText });
  };

  const handlePolicyClick = (name: string) => {
    const filter = mapFieldValueToBiqlString('policy', name);
    sendDataExplorerQuickSearchListItemClickBiAnalytics({
      entityName: 'policy',
      id: name,
    });
    $state.go(CONFIG.states.CATALOG, { filter, shouldSelectFirstItem: true, filterSelections: null });
  };

  const handleDatasourceClick = (id: string) => {
    const filter = mapFieldValueToBiqlString('system', id);
    sendDataExplorerQuickSearchListItemClickBiAnalytics({
      entityName: 'datasource',
      id,
    });
    $state.go(CONFIG.states.CATALOG, { filter, shouldSelectFirstItem: true, filterSelections: null });
  };

  const catalogListItems = data.catalog?.results.map((result, index) => (
    <CatalogListItem key={result.id} dataAid={generateDataAid('CatalogListItem', [index])} result={result} />
  ));

  const datasourceListItems = data.datasource?.results.map((result, index) => (
    <GenericListItem
      handleOnClick={() => handleDatasourceClick(result.id)}
      dataAid={generateDataAid('DataSourceListItem', [index])}
      key={result.id}
      result={result}
    />
  ));

  const policyListItems = data.policy?.results.map((result, index) => (
    <GenericListItem
      handleOnClick={() => handlePolicyClick(result.name)}
      key={result.id}
      dataAid={generateDataAid('PolicyListItem', [index])}
      result={result}
    />
  ));

  return isLoading || isFetching ? (
    <BigidLoader size={36} />
  ) : (
    <SectionContainer>
      <EntitySection
        sectionName="catalog"
        handleShowAllClick={() => handleShowAllClick('catalog')}
        count={data.catalog?.count || 0}
        shouldShowViewAll={data.catalog?.count > SHOW_ALL_THRESHOLD}
        listItems={catalogListItems}
      />
      <EntitySection
        sectionName="datasource"
        listItems={datasourceListItems}
        count={data.datasource?.count || 0}
        shouldShowViewAll={data.datasource?.count > SHOW_ALL_THRESHOLD}
        handleShowAllClick={() => handleShowAllClick('datasource')}
      />
      <EntitySection
        sectionName="policy"
        listItems={policyListItems}
        count={data.policy?.count || 0}
        shouldShowViewAll={data.policy?.count > SHOW_ALL_THRESHOLD}
        handleShowAllClick={() => handleShowAllClick('policy')}
      />
    </SectionContainer>
  );
};
