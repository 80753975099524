import React, { useCallback, useMemo } from 'react';
import {
  AutoDiscoverWizardAsideExpandedStates,
  AutoDiscoveryWizardContextInterface,
  AutoDiscoveryWizardContextState,
} from '../AutoDiscoveryWizard/autoDiscoveryWizardContext';
import { useLocalTranslation } from '../translations';
import { getDiscoverAuthTypeString, runDiscoveryCheck } from '../AutoDiscoveryWizard/autoDiscoveryWizardFormUtils';
import { getApplicationPreference } from '../../../services/appPreferencesService';
import {
  upsertAutoDiscoveryConfig,
  findErrorKeyFromMessage,
  runAutoDiscovery,
} from '../AutoDiscoveryWizard/autoDiscoveryWizardUtils';
import { notificationService } from '../../../services/notificationService';
import { BigidFieldFilterOperator, BigidFormValues } from '@bigid-ui/components';
import {
  CREATED_PRESET_STATUS,
  DiscoveryAuthTypes,
  TYPE_TO_AVAILABLE_CHECK_PERMISSIONS_BY_AUTH_FIELDS,
} from '../constants';
import { getAutoDiscoveryAppsConfigsList } from '../../../services/autoDiscoveryService';
import { TFunction } from 'i18next';

export const useActionHandlers = ({
  discoveryConfigData,
  setDiscoveryConfigData,
  discoveryConfigDataRef,
}: AutoDiscoveryWizardContextInterface) => {
  const { t } = useLocalTranslation('AutoDiscovery');

  const isAssessmentScanEnabled = useMemo(() => {
    return (
      getApplicationPreference('ASSESSMENT_SCAN_ENABLED') && getApplicationPreference('DISCOVERY_RUN_AND_SCAN_ENABLED')
    );
  }, []);

  const setLoading = useCallback(
    (isLoading: boolean) => {
      setDiscoveryConfigData(current => ({
        ...current,
        isDiscovery: isLoading,
      }));
    },
    [setDiscoveryConfigData],
  );

  const onRunDiscover = useCallback(
    async (overrideValues?: Record<string, any>) => {
      const handleRunErrors = (err: any) => {
        setLoading(false);
        const errorMessageKey = findErrorKeyFromMessage(err?.data?.error || err?.response?.data?.error);
        setDiscoveryConfigData(current => ({
          ...current,
          errorMessageKey,
        }));
        notificationService.error(t('configModal.errors.discovery'));
        console.warn(err);
      };
      try {
        const authTypeValue = getDiscoverAuthTypeString(discoveryConfigDataRef.current.formData?.authType);
        const isCheckAvailable =
          TYPE_TO_AVAILABLE_CHECK_PERMISSIONS_BY_AUTH_FIELDS[discoveryConfigDataRef.current.type]?.[
            authTypeValue as DiscoveryAuthTypes
          ];
        const isPermitted = isCheckAvailable
          ? await runDiscoveryCheck({ discoveryConfigDataRef, setDiscoveryConfigData })
          : true;
        if (!isPermitted) {
          return setLoading(false);
        }
        setLoading(true);
        await discoveryConfigDataRef.current?.validateAndSubmit?.(
          async (values: BigidFormValues) => {
            try {
              await runAutoDiscovery({ ...values, ...(overrideValues ?? {}) }, discoveryConfigDataRef);
            } catch (err) {
              handleRunErrors(err);
            }
          },
          () => setLoading(false),
        );
      } catch (err) {
        handleRunErrors(err);
      }
    },
    [setDiscoveryConfigData, setLoading, t],
  );

  const onSavePreset = useCallback(async () => {
    return savePreset(setDiscoveryConfigData, discoveryConfigDataRef, t);
  }, [setDiscoveryConfigData]);

  const isDiscoveryReady = discoveryConfigData?.formData?.name;

  const isDisabled = !isDiscoveryReady || discoveryConfigData.isDiscovery || discoveryConfigData.isCheck;

  return {
    isAssessmentScanEnabled,
    isDisabled,
    onSavePreset,
    onRunDiscover,
    isDiscoveryReady,
  };
};

export async function savePreset(
  setDiscoveryConfigData: React.Dispatch<React.SetStateAction<AutoDiscoveryWizardContextState>>,
  discoveryConfigDataRef: React.MutableRefObject<AutoDiscoveryWizardContextState>,
  t?: TFunction,
) {
  try {
    const { result } = await upsertAutoDiscoveryConfig(discoveryConfigDataRef);

    if ((result as Record<string, any>)?.data?.status?.includes(CREATED_PRESET_STATUS)) {
      notificationService.success(t?.('configAdvanced.updated'), { shouldCloseOnTransition: false });
      const {
        data: [preset],
      } = await getAutoDiscoveryAppsConfigsList({
        filter: [
          {
            field: 'name',
            value: discoveryConfigDataRef.current.formData.name,
            operator: 'equal' as BigidFieldFilterOperator,
          },
        ],
      });
      setDiscoveryConfigData(current => ({
        ...current,
        preset,
        initialValues: current.formData,
        id: preset?._id,
        isEdit: true,
      }));

      return preset?._id;
    } else {
      setDiscoveryConfigData(current => ({
        ...current,
        initialValues: current.formData,
      }));
      notificationService.success(t?.('configAdvanced.updated'), { shouldCloseOnTransition: false });
      return '';
    }
  } catch (err) {
    const errorMessageKey = findErrorKeyFromMessage(err?.response?.data?.error);
    setDiscoveryConfigData(current => ({
      ...current,
      asideExpandedState: AutoDiscoverWizardAsideExpandedStates.OPENED_ERROR,
      errorMessageKey,
    }));
    notificationService.error(t?.('configModal.errors.save'));
    console.warn(err);
    return false;
  }
}
