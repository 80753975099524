import { UseCatalogDiscoveryWidgetDataFetchStatus } from '../useCatalogDiscovery';
import { analyticsService } from '../../../services/analyticsService';
import { BIGID_BI_EVENTS } from '../../../config/BigIdBiEvents';
import { AggregationType } from '../catalogDiscoveryTypes';
import { CatalogDiscoveryWidget } from '../config/widgets';

export const widgetAssociatedAggregationsMap: {
  [key in CatalogDiscoveryWidget]?: AggregationType[];
} = {
  [CatalogDiscoveryWidget.TOOLBAR_OBJECTS_LIST_REPORT]: [AggregationType.OBJECT_STATUS],
  [CatalogDiscoveryWidget.FINDINGS_SUMMARY]: [AggregationType.HAS_FINDINGS],
  [CatalogDiscoveryWidget.DUPLICATE_FILES_SUMMARY]: [AggregationType.HAS_DUPLICATES],
  [CatalogDiscoveryWidget.SCANNED_VOLUME_SUMMARY]: [AggregationType.SCANNED_VOLUME],
  [CatalogDiscoveryWidget.VIOLATED_POLICIES_SUMMARY]: [AggregationType.VIOLATED_POLICY_CARDINALITY],
  [CatalogDiscoveryWidget.OBJECT_STATUS_PIE_CHART]: [AggregationType.OBJECT_STATUS],
  [CatalogDiscoveryWidget.DATA_FORMAT_PIE_CHART]: [AggregationType.DATA_FORMAT],
  [CatalogDiscoveryWidget.SENSITIVITY_PIE_CHART]: [AggregationType.SENSITIVITY],
  [CatalogDiscoveryWidget.DATA_SOURCES_LAYOUT]: [AggregationType.DATA_SOURCE_NAME],
  [CatalogDiscoveryWidget.ATTRIBUTES_LAYOUT]: [AggregationType.ATTRIBUTE_NAME],
};

export type CatalogDiscoveryBiWidgetPayload = {
  widgetId?: CatalogDiscoveryWidget;
  associatedAggregations?: AggregationType[];
  dataLoadingTime: number;
};

export function sendWidgetFilteredDataLoadingTimeBiAnalytics(
  prevStatus: UseCatalogDiscoveryWidgetDataFetchStatus,
  currStatus: UseCatalogDiscoveryWidgetDataFetchStatus,
): void {
  (Object.keys(currStatus) as Array<CatalogDiscoveryWidget>).forEach(widgetId => {
    if (
      !currStatus[widgetId]?.isBusy &&
      prevStatus[widgetId]?.isBusy &&
      prevStatus[widgetId]?.dataFetchStatusChangeTimestamp
    ) {
      const associatedAggregations = widgetAssociatedAggregationsMap[widgetId];

      const biPayload: CatalogDiscoveryBiWidgetPayload = {
        widgetId,
        dataLoadingTime:
          currStatus[widgetId].dataFetchStatusChangeTimestamp - prevStatus[widgetId].dataFetchStatusChangeTimestamp,
      };

      if (associatedAggregations) {
        biPayload.associatedAggregations = associatedAggregations;
      }

      analyticsService.trackManualEvent(BIGID_BI_EVENTS.DATAINSIGHTS_WIDGET_LOADING_TIME, biPayload);
    }
  });
}

export function sendPageInitialisationTimeBiAnalytics(pageInitDuration: number): void {
  const biPayload: CatalogDiscoveryBiWidgetPayload = {
    dataLoadingTime: pageInitDuration,
  };

  analyticsService.trackManualEvent(BIGID_BI_EVENTS.DATAINSIGHTS_PAGE_INITIALISATION_TIME, biPayload);
}
