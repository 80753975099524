import { CustomDashboardConfig, OPERATORS } from '@bigid-ui/custom-dashboard';
export const riskTemplate: CustomDashboardConfig = {
  data: {},
  endpoints: [
    {
      id: 'riskSnapshots',
      api: {
        url: 'api/v1/risk_snapshots',
        method: 'get',
      },
    },
    {
      id: 'riskObjectSummary',
      api: {
        url: 'api/v1/data-catalog/risk/object/summary',
        method: 'get',
      },
    },
    {
      id: 'riskDsSummary',
      api: {
        url: 'api/v1/data-catalog/risk/ds/summary',
        method: 'get',
      },
    },
  ],
  dataManipulations: [],
  widgets: [
    {
      id: 'riskOverTimeLineChart',
      header: {
        title: 'Risk Over Time',
      },
      showFrame: true,
      widget: {
        lineChart: {
          height: '320px',
          data: {
            pathToArray: 'data.riskSnapshots.risk_snapshots',
            pathToDate: 'date',
            keys: [{ keyName: 'risk', label: 'Risk Score' }],
          },
        },
      },
    },
    {
      id: 'dsRiskPieChart',
      header: {
        title: 'Data Source Risk',
      },
      showFrame: true,
      widget: {
        pieChart: {
          minimized: false,
          data: {
            pathToArray: 'data.riskDsSummary.data.groups',
            pathToKey: 'name',
            pathToValue: 'count',
          },
        },
      },
    },
    {
      id: 'objectRiskPieChart',
      header: {
        title: 'Object Risk',
      },
      showFrame: true,
      widget: {
        pieChart: {
          minimized: false,
          data: {
            pathToArray: 'data.riskObjectSummary.data.groups',
            pathToKey: 'name',
            pathToValue: 'count',
          },
        },
      },
    },
  ],
  layout: {
    direction: 'row',
    justifyContent: 'center',
    alignContent: 'center',
    alignItems: 'center',
    spacing: 1,
    layoutSlots: [
      {
        size: 10,
        layoutGrid: {
          justifyContent: 'space-between',
          alignItems: 'flex-start',
          spacing: 2,
          layoutSlots: [
            {
              widgetId: 'riskOverTimeLineChart',
              size: 12,
            },
          ],
        },
      },
      {
        size: 10,
        layoutGrid: {
          justifyContent: 'space-between',
          alignItems: 'center',
          spacing: 2,
          layoutSlots: [
            {
              widgetId: 'dsRiskPieChart',
              size: 6,
            },
            {
              widgetId: 'objectRiskPieChart',
              size: 6,
            },
          ],
        },
      },
    ],
  },
};
