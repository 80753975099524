import { BigidDropdown, BigidDropdownOption, BigidHeading5, BigidSearch } from '@bigid-ui/components';
import React, { FC, memo, useContext, useMemo } from 'react';
import { SelectDataSourceOnboardingTypeContext } from '../../SelectDataSourceOnboardingTypeContext';
import { DATA_SOURCE_ONBOARDING_CATEGORIES_CONFIG } from '../../config';
import { CategoriesState, DataSourceOnboardingCategoriesKeys } from '../../types';
import { useLocalTranslation } from '../../translations';
import styled from '@emotion/styled';
import { useSetInObjectState } from '../../hooks/useSetInObjectState';

export const DataSourceOnboardingHeaderContainer = styled('div')`
  display: flex;
`;

export const DataSourceOnboardingHeaderFiltersWrapper = styled('div')`
  display: flex;
  width: 520px;
  flex: 0 1 auto;
  gap: 8px;
  & > :first-child {
    max-width: 210px;
  }
  & > :last-child {
    width: 300px;
    border: 1px solid ${({ theme }) => theme.vars.palette.bigid.gray200};
  }
`;
export const DataSourceOnboardingHeader: FC = memo(() => {
  const { setState, categoriesState, selectedCategory } = useContext(SelectDataSourceOnboardingTypeContext);
  const { t } = useLocalTranslation('');
  const updateSearchFilter = useSetInObjectState(setState, searchString => ({ searchString }));
  const onSelect = useSetInObjectState(setState, ([{ value }]: BigidDropdownOption[]) => ({
    selectedCategory: value,
  }));
  const options = useMemo(() => transformCategoryStateToDropdownItems(categoriesState), [categoriesState]);
  const value = useMemo(() => transformSelectedCategoryToDropdownValue(selectedCategory), [selectedCategory]);

  return (
    <DataSourceOnboardingHeaderContainer>
      <BigidHeading5 sx={{ flex: '1 0 auto' }}>{t('grid.title')}</BigidHeading5>
      <DataSourceOnboardingHeaderFiltersWrapper>
        <BigidDropdown
          size={'large'}
          dataAid={`select-data-source-onboarding-category`}
          options={options}
          value={value}
          onSelect={onSelect}
        />
        <BigidSearch size={'large'} onChange={updateSearchFilter} onSubmit={updateSearchFilter} placeholder="Search" />
      </DataSourceOnboardingHeaderFiltersWrapper>
    </DataSourceOnboardingHeaderContainer>
  );
});

function transformCategoryStateToDropdownItems(categoriesState: CategoriesState) {
  return categoriesState
    ? Object.entries(categoriesState).map(([name, { isLoading }]) => ({
        value: name,
        displayValue: DATA_SOURCE_ONBOARDING_CATEGORIES_CONFIG[name as DataSourceOnboardingCategoriesKeys]?.label,
        id: name,
        disabled: isLoading,
      }))
    : [];
}

function transformSelectedCategoryToDropdownValue(selectedCategory: DataSourceOnboardingCategoriesKeys) {
  return selectedCategory
    ? [
        {
          id: selectedCategory,
          value: selectedCategory,
          displayValue: DATA_SOURCE_ONBOARDING_CATEGORIES_CONFIG[selectedCategory].label,
        },
      ]
    : [];
}
