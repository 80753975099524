import React, { FC } from 'react';
import { withApplicationForm } from './ApplicationForm';
import { EditGlobalPreset } from './views/SetGlobalPreset/EditGlobalPreset';
import { $stateParams } from '../../services/angularServices';
import { isEmpty } from 'lodash';
import { CustomAppPageWrapper } from './CustomAppPageWrapper';

export const CustomAppEditGlobalPresetPage: FC = () => {
  return (
    <CustomAppPageWrapper appInfo={$stateParams.appInfo} isReady={!isEmpty($stateParams.appInfo)}>
      {withApplicationForm(EditGlobalPreset, 'Add global preset', $stateParams.appInfo)}
    </CustomAppPageWrapper>
  );
};
