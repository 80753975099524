import React, { FC, useState, useEffect, useContext } from 'react';
import {
  BigidDropdown,
  BigidDropdownOption,
  PrimaryButton,
  SecondaryButton,
  BigidLoader,
  BigidBody1,
  BigidColorsV2,
} from '@bigid-ui/components';
import { updateCaseAssignee, ModalContentProps } from '../caseActionsService';
import { generateDataAid } from '@bigid-ui/utils';
import styled from '@emotion/styled';
import { useLocalTranslation } from '../../../translations';
import {
  ActionableInsightsTrackingEvents,
  trackActionableInsightsEvent,
} from '../../../actionableInsightsTrackingUtil';
import { ActionableInsightsContext } from '../../../hooks/ActionableInsightsContext';

const MainContainer = styled.div({
  height: 160,
  display: 'flex',
  flexDirection: 'column',
});

const SelectionWrapper = styled.div({
  paddingTop: 12,
  display: 'flex',
});

const ButtonsContainer = styled.div({
  display: 'flex',
  flexDirection: 'row-reverse',
  marginTop: 'auto',
  '& > :first-child': {
    marginLeft: 8,
  },
});

export const AssignUser: FC<ModalContentProps> = ({ closeModal, rowsData, viewType, filters, onAssignCase }) => {
  const { t } = useLocalTranslation('Action');
  const [isLoading, setIsLoading] = useState(false);
  const [selectedUser, setSelectedUser] = useState<BigidDropdownOption[]>();
  const { systemUsers } = useContext(ActionableInsightsContext);
  const [isSelectedUserChanged, setIsSelectedUserChanged] = useState(false);
  const [isInvalidSelection, setIsInvalidSelection] = useState(false);

  useEffect(() => {
    if (rowsData.length === 1 && rowsData[0]?.assignee) {
      const initialUser = systemUsers.find(user => user.value === rowsData[0].assignee);
      initialUser && setSelectedUser([initialUser]);
    }
  }, [rowsData, systemUsers]);

  const onSelectUser = (selectedOption: BigidDropdownOption[]): void => {
    setSelectedUser(selectedOption);
    setIsSelectedUserChanged(true);
  };

  const onAssignUserClick = () => {
    if (!selectedUser) {
      setIsInvalidSelection(true);
    } else {
      setIsInvalidSelection(false);
      setIsLoading(true);

      updateCaseAssignee(
        { caseIds: filters.caseIds, policies: filters?.policies, status: filters.status },
        selectedUser[0].value,
        onAssignCase,
      );

      trackActionableInsightsEvent(
        ActionableInsightsTrackingEvents.DATA_RISK_MANAGEMENT_ASSIGN_USER_DIALOG_ASSIGN_CLICK,
        { USER: selectedUser[0].value, VIEW_TYPE: viewType },
      );
      closeModal?.();
    }
  };

  return (
    <MainContainer>
      {isLoading ? (
        <BigidLoader />
      ) : (
        <>
          <BigidBody1>{t('body.assignCaseInfo')}</BigidBody1>
          <SelectionWrapper>
            <BigidDropdown
              dataAid={generateDataAid('AssignUser', ['dropdown'])}
              value={selectedUser}
              options={systemUsers}
              onSelect={onSelectUser}
              placeholder={t('label.chooseUser')}
              isSearchable={true}
            />
          </SelectionWrapper>
          {isInvalidSelection && <BigidBody1 color={BigidColorsV2.red[600]}>{t('validation.selectUser')}</BigidBody1>}
          <ButtonsContainer>
            {isSelectedUserChanged && (
              <PrimaryButton
                size="medium"
                text={t('button.assign')}
                onClick={onAssignUserClick}
                bi={{ disabled: true }}
              />
            )}
            <SecondaryButton
              size="medium"
              text={t('button.cancel')}
              onClick={closeModal}
              bi={{
                eventType: ActionableInsightsTrackingEvents.DATA_RISK_MANAGEMENT_ASSIGN_USER_DIALOG_CANCEL_CLICK,
                eventData: { VIEW_TYPE: viewType },
              }}
            />
          </ButtonsContainer>
        </>
      )}
    </MainContainer>
  );
};
