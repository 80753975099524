import { module } from 'angular';
const app = module('app');

app.factory('identitiesService', [
  '$http',
  'appSettings',
  function ($http, appSettings) {
    let that = this;
    that.getIdentitiesFromIDSoRRequest = {};
    that.getgetIdentitiesDataRequest = {};

    return {
      getIdentitiesData: function getIdentitiesData(filterValue, isClearCache) {
        filterValue = filterValue || '';
        if (isClearCache) {
          that.getgetIdentitiesDataRequest[filterValue] = null;
        }
        if (!that.getgetIdentitiesDataRequest[filterValue]) {
          that.getgetIdentitiesDataRequest[filterValue] = $http
            .get(appSettings.serverPath + '/' + appSettings.version + '/userRisks?filter=' + filterValue)
            .then(function (response) {
              return response.data;
            });
        }
        return that.getgetIdentitiesDataRequest[filterValue];
      },
      getIdentitiesDataExport: function getIdentitiesDataExport(filterValue) {
        filterValue = filterValue ? filterValue.replace('?filter=', '') : '';
        return $http
          .get(appSettings.serverPath + '/' + appSettings.version + '/userRisks/export/?filter=' + filterValue)
          .then(function (response) {
            return response.data;
          });
      },
      getIdentitiesFromIDSoR: function getIdentitiesFromIDSoR(filterValue, isClearCache) {
        filterValue = filterValue || '';
        if (isClearCache) {
          that.getIdentitiesFromIDSoRRequest[filterValue] = null;
        }
        if (!that.getIdentitiesFromIDSoRRequest[filterValue]) {
          that.getIdentitiesFromIDSoRRequest[filterValue] = $http
            .get(
              appSettings.serverPath +
                '/' +
                appSettings.version +
                '/id_connections_tasks/getUsers?filter=' +
                filterValue,
            )
            .then(function (response) {
              return response.data;
            });
        }
        return that.getIdentitiesFromIDSoRRequest[filterValue];
      },
      cleargetIdentitiesFromIDSoRRequest: function cleargetIdentitiesFromIDSoRRequest() {
        that.getIdentitiesFromIDSoRRequest = {};
      },
      clearGetgetIdentitiesDataRequest: function () {
        that.getgetIdentitiesDataRequest = {};
      },
    };
  },
]);
