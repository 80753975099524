import { MutableRefObject, useEffect } from 'react';
import { dsModalNotificationService } from '../services/dsModalNotificationService';
import { BigidFormStateAndHandlers } from '@bigid-ui/components';

type UseDataSourceModalNotificationListenerProps = {
  isOpen: boolean;
  sourceId: string;
  control: MutableRefObject<BigidFormStateAndHandlers>;
};

export const useDataSourceModalNotificationListener = ({
  isOpen,
  sourceId,
  control,
}: UseDataSourceModalNotificationListenerProps) => {
  useEffect(() => {
    isOpen
      ? dsModalNotificationService.removeNotificationListener(sourceId)
      : dsModalNotificationService.addNotificationListener(sourceId);
  }, [isOpen, sourceId]);

  useEffect(() => {
    return () => {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      const currentDsName = sourceId || control?.current?.getValues()?.name;
      dsModalNotificationService.addNotificationListener(currentDsName);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};
