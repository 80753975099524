import React, { FC } from 'react';
import { BigidDisconnectedIllustration } from '@bigid-ui/icons';
import { useLocalTranslation } from '../../../../DataSourceConnectionModal/translations';
import { BigidHeading5 } from '@bigid-ui/components';
import { styled } from '@mui/material';

const Wrapper = styled('div')`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 168px;
`;

export interface SideContentConnectionErrorProps {}

export const SideContentConnectionError: FC<SideContentConnectionErrorProps> = () => {
  const { t } = useLocalTranslation();

  return (
    <Wrapper>
      <BigidDisconnectedIllustration width={144} height={144} />
      <BigidHeading5 textAlign={'center'}>{t('connectionTab.connectionFailed')}</BigidHeading5>
    </Wrapper>
  );
};
