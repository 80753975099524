import { CloudProvider } from './types';
import { BigidFormField, BigidFormFieldLabelPosition, BigidFormFieldTypes } from '@bigid-ui/components';
import { DataSourceTemplateConditionOperatorsEnum } from '../../DataSources/DataSourceConfiguration/types';

export const SIDE_SCAN_ENABLE_FIELD_NAME = 'is_snapshot_scanning_enabled';

const assembleOptions = [
  {
    id: 'Always create snapshot',
    value: 'Always create snapshot',
    displayValue: 'Always create the snapshot',
  },

  {
    id: 'Never create snapshot',
    value: 'Never create snapshot',
    displayValue: 'Never create the snapshot',
  },
];

const disassembleOptions = [
  {
    id: 'Always delete snapshot',
    value: 'Always delete snapshot',
    displayValue: 'Always delete snapshot',
  },
  {
    id: 'Never delete snapshot',
    value: 'Never delete snapshot',
    displayValue: 'Never delete snapshot',
  },
];

const visibleIfSideScanEnabled = [
  {
    field: SIDE_SCAN_ENABLE_FIELD_NAME,
    value: {
      operator: DataSourceTemplateConditionOperatorsEnum.or,
      values: ['true', true],
    },
  },
];

const SIDE_SCAN_ENABLE_FIELD: BigidFormField = {
  name: SIDE_SCAN_ENABLE_FIELD_NAME,
  label: 'Snapshot Scanning',
  isRequired: false,
  type: BigidFormFieldTypes.CHECKBOX,
  labelPosition: BigidFormFieldLabelPosition.left,
  misc: {},
};

const ASSEMBLE_FIELD: BigidFormField = {
  name: 'snapshot_scanning_preparation',
  label: 'Snapshot Scanning Preparation',
  isRequired: false,
  type: BigidFormFieldTypes.DROP_DOWN,
  dropDownOptions: assembleOptions,
  misc: {
    ...(visibleIfSideScanEnabled ? { visibleIf: visibleIfSideScanEnabled } : {}),
  },
  tooltipText:
    'If you would like to manage the snapshots taking with your own means - select the ‘Never create the snapshot’ option',
};

const DISASSEMBLE_FIELD: BigidFormField = {
  name: 'snapshot_scanning_cleanup',
  label: 'Snapshot Scanning Cleanup',
  isRequired: false,
  type: BigidFormFieldTypes.DROP_DOWN,
  dropDownOptions: disassembleOptions,
  tooltipText:
    'If you would like to manage the snapshots deletion with your own means - select the ‘Never delete the snapshot’ option',
  misc: {
    ...(visibleIfSideScanEnabled ? { visibleIf: visibleIfSideScanEnabled } : {}),
  },
};

export const TYPE_TO_SIDE_SCAN_FIELDS: Record<CloudProvider, BigidFormField[]> = {
  [CloudProvider.AWS]: [SIDE_SCAN_ENABLE_FIELD, ASSEMBLE_FIELD, DISASSEMBLE_FIELD],
  [CloudProvider.AZURE]: [],
  [CloudProvider.GCP]: [],
  [CloudProvider.ONTAP]: [],
};
