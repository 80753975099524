import { noop } from 'lodash';
import React, { FC, PropsWithChildren, createContext, useContext, useReducer } from 'react';

interface GridState {
  searchQuery: string;
  filterString: string;
}

interface GridAction {
  type: string;
  payload: string;
}

const initialState: GridState = {
  searchQuery: '',
  filterString: '',
};

const gridReducer = (state: GridState, action: GridAction): GridState => {
  switch (action.type) {
    case 'SET_SEARCH_QUERY':
      return {
        ...state,
        searchQuery: action.payload,
      };

    case 'SET_FILTER_STRING':
      return {
        ...state,
        filterString: action.payload,
      };
    default:
      return state;
  }
};

interface GridContextProps {
  state: GridState;
  dispatch: React.Dispatch<GridAction>;
}

export const GridContext = createContext<GridContextProps>({
  state: initialState,
  dispatch: noop,
});

export const DataCatalogGridContextProvider: FC<PropsWithChildren> = ({ children }) => {
  const [state, dispatch] = useReducer(gridReducer, initialState);

  return <GridContext.Provider value={{ state, dispatch }}>{children}</GridContext.Provider>;
};

export const useAuditTrialGridContext = (): GridContextProps => {
  if (!GridContext) {
    throw new Error('useAuditTrialGridContext must be used within GridProvider');
  }
  return useContext(GridContext);
};
