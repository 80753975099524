import { useEffect, useRef, useState } from 'react';
import { BigidFormStateAndHandlers, BigidFormValues, PrimaryButton, SecondaryButton } from '@bigid-ui/components';
import { isNull, noop, uniqueId } from 'lodash';
import {
  getPermissions,
  prepareRequestData,
  prepareTestConnectionData,
  prepareUpdateRequestData,
  validatePortField,
} from '../utils';
import { notificationService } from '../../../services/notificationService';
import { useLocalTranslation } from '../translations';
import { ForwardFormRef, ProxyGridRow } from '../types';
import { BigidLayoutMasterDetailsEvent, BigidLayoutMasterDetailsGridEvents, useLayout } from '@bigid-ui/layout';
import { BigidGridRow } from '@bigid-ui/grid';
import { deleteProxy, createProxy, updateProxy } from '../../../services/proxiesService';
import { generateDataAid } from '@bigid-ui/utils';
import { BigidSystemDialogOptions, openSystemDialog } from '../../../services/systemDialogService';
import { ProxiesDialogContent } from '../ProxiesDialogContent';

interface UseFormActions {
  row: ProxyGridRow;
  layout: ReturnType<typeof useLayout<ProxyGridRow>>;
  proxiesFormRef: React.MutableRefObject<ForwardFormRef>;
}

interface ShowUnsavedChangesDialog {
  onClickCancel: () => void;
  onClickSave: () => Promise<void>;
}

export const useFormActions = ({ row, layout, proxiesFormRef }: UseFormActions) => {
  const [isBusy, setIsBusy] = useState(false);
  const pendingSearchTerm = useRef(null);
  const selectedRowNameRef = useRef(null);
  const formControls = useRef<BigidFormStateAndHandlers>();
  const { isEditPermitted } = getPermissions();
  const { t } = useLocalTranslation();

  const {
    refresh,
    addRow,
    deleteRow,
    setSelectedRowById,
    clearSearch,
    updateRowById,
    emit,
    rows,
    EmitterType,
    isLoading,
    apiRef,
  } = layout;

  useEffect(() => {
    if (!isLoading) {
      apiRef.current.clearVirtualTabelCache?.();
      const hasPendingSearch = !isNull(pendingSearchTerm.current);
      const selectedRow = selectedRowNameRef.current
        ? rows?.find(({ name }) => name === selectedRowNameRef.current)
        : false;
      const firstRowId = rows.length && [rows[0].id];
      const selectedId = selectedRow && [selectedRow.id];

      hasPendingSearch &&
        emit(EmitterType.LAYOUT, BigidLayoutMasterDetailsGridEvents.SEARCH_STATE_CHANGE, pendingSearchTerm.current);
      setSelectedRowById(selectedId || firstRowId || []);

      selectedRowNameRef.current = null;
      pendingSearchTerm.current = null;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading]);

  const createNewGridRow = (): string => {
    const rowId = uniqueId('');
    const row: ProxyGridRow = {
      name: t('message.newProxies'),
      proxyUrls: [],
      hostsToBypassProxy: [],
      description: '',
      certificateId: '',
      certificateType: '',
      credentialsId: '',
      http: false,
      https: false,
      isPending: true,
      id: rowId,
      _id: null,
    };
    addRow(row);
    return rowId;
  };

  const resetForm = () => {
    formControls.current?.clear();
    formControls.current?.setAllTouched(false);
  };

  const handleSubmit = (shouldRefreshGrid = true) =>
    new Promise<void>(resolve => {
      formControls.current?.validateAndSubmit(
        async (values: BigidFormValues) => {
          try {
            const { isPending, _id } = row ?? {};
            const isNameDuplicated = rows.slice(1).find(({ name: name }) => name === values.name);
            if (isPending && isNameDuplicated) {
              formControls.current?.setFieldError('name', t('message.duplicatedName'));
              return;
            }

            const { http, https, httpProxyHost, httpProxyPort, httpsProxyHost, httpsProxyPort, certificateId } = values;

            const validUrls = isValidUrls(http, https, httpProxyHost, httpProxyPort, httpsProxyHost, httpsProxyPort);
            if (!validUrls) {
              return;
            }

            const data = prepareRequestData(values);
            const updateData = prepareUpdateRequestData(data, formControls.current?.touchedFields);
            setIsBusy(true);

            isPending ? await createProxy(data) : await updateProxy(_id, updateData);

            const hasPendingSearch = !isNull(pendingSearchTerm.current);
            const prevSelectedRowNameRef = selectedRowNameRef.current;
            formControls.current?.setAllTouched(false);
            selectedRowNameRef.current = hasPendingSearch ? null : prevSelectedRowNameRef ?? data.name;
            notificationService.success(
              isPending ? t('message.createdSuccessfully') : t('message.updatedSuccessfully'),
              {
                ...(isPending && { title: t('message.createdSuccessfullyTitle') }),
                shouldCloseOnTransition: false,
              },
            );
            if (shouldRefreshGrid) {
              refresh();
            } else {
              updateRowById(row.id, { ...row, ...updateData });
            }
            resolve();
          } catch (e) {
            const message = e?.response?.data?.message;
            notificationService.error(message);
            pendingSearchTerm.current = null;
            selectedRowNameRef.current = null;
          } finally {
            setIsBusy(false);
          }
        },
        () => {
          selectedRowNameRef.current = null;
          return proxiesFormRef.current?.formElement.scrollTo({ top: 0, behavior: 'smooth' });
        },
      );
    });

  const isValidUrls = (
    http: boolean,
    https: boolean,
    httpProxyHost: string,
    httpProxyPort: string,
    httpsProxyHost: string,
    httpsProxyPort: string,
  ): boolean => {
    if (!http && !https) {
      formControls.current?.setFieldError('protocolsLabel', t('message.urlValidation'));
      return false;
    }
    formControls.current?.setFieldError('protocolsLabel', '');

    if (http) {
      if (!httpProxyHost) {
        formControls.current?.setFieldError('httpProxyHost', t('message.httpHostValidation'));
        return false;
      }
      if (!validatePortField(httpProxyPort)) {
        formControls.current?.setFieldError('httpProxyPort', t('message.invalidPort'));
        return false;
      }
    }

    if (https) {
      if (!httpsProxyHost) {
        formControls.current?.setFieldError('httpsProxyHost', t('message.httpsHostValidation'));
        return false;
      }
      if (!validatePortField(httpsProxyPort)) {
        formControls.current?.setFieldError('httpsProxyPort', t('message.invalidPort'));
        return false;
      }
    }

    return true;
  };

  const handleDelete = async () => {
    try {
      const { isPending, _id } = row;
      if (isPending) {
        resetForm();
        deleteRow(row);
        formControls.current.formTouched = false;
        const remainingRows = rows.filter(({ id }) => id !== row.id);
        if (remainingRows.length) {
          setSelectedRowById([remainingRows[0].id]);
        }
        return;
      }

      setIsBusy(true);
      await deleteProxy(_id);
      notificationService.success(t('message.commonDeleteSuccess'));

      refresh();
    } catch ({ data }) {
      notificationService.error(data?.message ?? t('message.commonError'));
    } finally {
      setIsBusy(false);
    }
  };

  const handleTestConnection = () => {
    formControls.current?.validateAndSubmit(
      async (values: BigidFormValues) => {
        try {
          setIsBusy(true);
          const data = prepareTestConnectionData({
            values,
            renderedFields: proxiesFormRef.current?.fieldsToRender,
          });
          notificationService.success(t('message.testConnectionSuccess'));
        } catch (e) {
          notificationService.error(t('message.testConnectionFailure'));
          console.warn(e);
        } finally {
          setIsBusy(false);
        }
      },
      () => {
        return proxiesFormRef.current?.formElement.scrollTo({ top: 0, behavior: 'smooth' });
      },
    );
  };

  const handleSubmitCancel = () => {
    const { isPending } = row ?? {};
    const hasPendingSearch = !isNull(pendingSearchTerm.current);

    isPending ? deleteRow(row) : resetForm();
    emit(EmitterType.LAYOUT, BigidLayoutMasterDetailsGridEvents.SELECT_STATE_CHANGE, [
      rows?.find(row => row.name === selectedRowNameRef.current)?.id,
    ]);
    hasPendingSearch &&
      emit(EmitterType.LAYOUT, BigidLayoutMasterDetailsGridEvents.SEARCH_STATE_CHANGE, pendingSearchTerm.current);

    pendingSearchTerm.current = null;
    selectedRowNameRef.current = null;
  };

  const configureUnsavedChangesDialog = ({ onClickCancel, onClickSave }: ShowUnsavedChangesDialog) =>
    new Promise<boolean>(resolve => {
      const dialogOptions: BigidSystemDialogOptions = {
        title: t('modal.unsavedChanges.title'),
        onClose: noop,
        maxWidth: 'xs',
        buttons: [
          {
            component: SecondaryButton,
            dataAid: generateDataAid('ProxiesActions', ['cancel', 'submit']),
            isClose: true,
            onClick: () => {
              onClickCancel();
              resolve(true);
            },
            text: t('buttons.no'),
          },
          {
            component: PrimaryButton,
            dataAid: generateDataAid('ProxiesActions', ['confirm', 'submit']),
            isClose: true,
            disabled: !isEditPermitted,
            onClick: async () => {
              await onClickSave();
              resolve(true);
            },
            text: t('buttons.yes'),
          },
        ],
        content: ProxiesDialogContent,
        contentProps: { body: t('modal.unsavedChanges.body') },
        borderTop: true,
      };

      openSystemDialog(dialogOptions);
    });

  const showUnsavedChangesDialog = () => {
    if (formControls.current?.formTouched && rows?.length !== 0) {
      return configureUnsavedChangesDialog({
        onClickCancel: () => {
          handleSubmitCancel();
          row.isPending && refresh();
        },
        onClickSave: async () => {
          await handleSubmit();
        },
      });
    }
    return Promise.resolve(true);
  };

  const addGridRow = () => {
    const hasPendingRows = rows.find(({ isPending }) => isPending);

    if (hasPendingRows) {
      notificationService.warning(t('message.pendingProxy'));
      return;
    }

    clearSearch();
    const rowId = createNewGridRow();
    setSelectedRowById([rowId]);
  };

  const handleProxyAdd = () => {
    if (formControls.current?.formTouched) {
      return configureUnsavedChangesDialog({
        onClickCancel: addGridRow,
        onClickSave: async () => {
          await handleSubmit(false);
          addGridRow();
        },
      });
    }

    addGridRow();
  };

  const handleSelectionChange = ({ selectedRowIds = [] }: BigidLayoutMasterDetailsEvent<BigidGridRow>): boolean => {
    const isDirty = formControls.current?.formTouched;
    const [id] = selectedRowIds;
    const { isPending, id: prevSelectedId } = row ?? {};
    const hasSelectedRow = !!row;
    const shouldChangeSelection = !hasSelectedRow ? true : !isPending && !isDirty && !isBusy;

    if (id === prevSelectedId) {
      return;
    }

    if (!shouldChangeSelection) {
      selectedRowNameRef.current = rows?.find(row => row.id === id)?.name;
      showUnsavedChangesDialog();
    }

    return shouldChangeSelection;
  };

  const handleSearchChange = ({ searchTerm }: BigidLayoutMasterDetailsEvent<BigidGridRow>) => {
    const { isPending, name } = row ?? {};
    const isDirty = formControls.current?.formTouched;
    const shouldChangeSearchTerm = !isPending && !isDirty && !isBusy;

    if (!shouldChangeSearchTerm) {
      selectedRowNameRef.current = name;
      pendingSearchTerm.current = searchTerm;
      showUnsavedChangesDialog();
    }

    return shouldChangeSearchTerm;
  };

  return {
    isBusy,
    formControls,
    handleSubmit,
    handleDelete,
    handleTestConnection,
    handleSubmitCancel,
    handleProxyAdd,
    handleSelectionChange,
    handleSearchChange,
    showUnsavedChangesDialog,
  };
};
