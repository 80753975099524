import React, { FC, ReactNode } from 'react';
import { BigidHeading6, BigidIcon, BigidIconSize, BigidIconType } from '@bigid-ui/components';
import styled from '@emotion/styled';

interface TitleProps {
  title: string;
  icon?: BigidIconType;
  rightSectionContent?: ReactNode;
}

const StyledTitle = styled('div')`
  display: flex;
  padding: 24px 18px 0 18px;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 24px;
`;
StyledTitle.displayName = 'StyledTitle';

const StyledSection = styled('div')`
  display: flex;
  gap: 8px;
`;
StyledSection.displayName = 'StyledSection';

export const Title: FC<TitleProps> = ({ title, icon, rightSectionContent }) => {
  return (
    <StyledTitle>
      <StyledSection>
        <BigidIcon icon={icon} size={BigidIconSize.MEDIUM} />
        <BigidHeading6>{title}</BigidHeading6>
      </StyledSection>
      <StyledSection>{rightSectionContent}</StyledSection>
    </StyledTitle>
  );
};

Title.displayName = 'Title';
