import React, { FC, memo, useCallback, useMemo, useRef } from 'react';
import { CloudProvider, DISCOVERY_STATUS_TO_STATUS_BADGE_TYPE_MAP, STATUS_TO_LABEL } from '../../constants';
import { BigidFullPageDialogContainer, BigidLoader, BigidPageLayout, BigidStatusBadgeSize } from '@bigid-ui/components';
import {
  AutoDiscoveryPresetConfigContainer,
  AutoDiscoveryPresetConfigFormWrapper,
  DiscoveryTitleIconWrapper,
} from './styled';
import { AutoDiscoveryPresetConfigTabs } from './AutoDiscoveryPresetConfigTabs';
import {
  AutoDiscoveryPresetConfigTabsEnum,
  AutoDiscoveryWizardContext,
  AutoDiscoveryWizardContextState,
} from '../autoDiscoveryWizardContext';
import { useDiscoveryConfigState } from '../../hooks/useDiscoveryConfigState';
import { AutoDiscoverWizardForm } from '../autoDiscoveryComponents/AutoDiscoveryWizardForm/AutoDiscoverWizardForm';
import { useScrollToDsListAfterCheck } from '../../hooks/useScrollToDsListAfterCheck';
import { AutoDiscoverWizardAside } from '../autoDiscoveryComponents/AutoDiscoveryWizardAside/AutoDiscoverWizardAside';
import { AutoDiscoveryWizardOverlay } from '../AutoDiscoveryWizardNewPreset/AutoDiscoveryWizardOverlay';
import { useActions } from '../../hooks/useActions';
import { useAutoDiscoveryConfigHeaderProps } from '../../hooks/useAutoDiscoveryConfigHeaderProps';
import { AutoDiscoveryPresetConfigLogsTab } from './tabs/AutoDiscoveryPresetConfigLogsTab';
import { $state } from '../../../../services/angularServices';
import { CONFIG } from '../../../../../config/common';
import { AutoDiscoveryNotFound } from './AutoDiscoveryNotFound';
import { useLocalTranslation } from '../../translations';

export interface AutoDiscoveryPresetConfigUrlParams {
  type: CloudProvider;
  id?: string;
  tab?: AutoDiscoveryPresetConfigTabsEnum;
}
interface AutoDiscoveryWizardAdvancedProps {
  $stateParams: AutoDiscoveryPresetConfigUrlParams;
}
export const AutoDiscoveryWizardAdvanced: FC<AutoDiscoveryWizardAdvancedProps> = memo(
  ({ $stateParams }) => {
    const discoveryConfigDataRef = useRef<AutoDiscoveryWizardContextState>({
      type: $stateParams.type,
      tab: $stateParams.tab ?? AutoDiscoveryPresetConfigTabsEnum.connection,
      id: $stateParams.id,
      isEdit: !!$stateParams.id,
    });
    const { discoveryConfigData, setDiscoveryConfigData, isConfigLoaded } = useDiscoveryConfigState({
      type: $stateParams?.type,
      discoveryConfigDataRef,
    });
    const { t } = useLocalTranslation('AutoDiscovery');
    const formWrapperRef = useRef(null);

    const isNotFound = discoveryConfigData?.isTypeNotFound || ($stateParams.id && discoveryConfigData?.isIdNotFound);
    const isAuditTabSelected = discoveryConfigData.tab === AutoDiscoveryPresetConfigTabsEnum.audit;

    const { pageTitle, Icon, breadcrumbs } = useAutoDiscoveryConfigHeaderProps($stateParams, discoveryConfigData, t);

    const actions = useActions({ discoveryConfigData, setDiscoveryConfigData, discoveryConfigDataRef });

    const formRendered = useMemo(() => {
      if (isNotFound) {
        return null;
      }
      return isConfigLoaded ? (
        <AutoDiscoveryPresetConfigFormWrapper ref={formWrapperRef}>
          <AutoDiscoverWizardForm
            setDiscoveryConfigData={setDiscoveryConfigData}
            discoveryConfigDataRef={discoveryConfigDataRef}
            isAllFieldsMode={true}
          />
        </AutoDiscoveryPresetConfigFormWrapper>
      ) : (
        <BigidLoader />
      );
    }, [isNotFound, isConfigLoaded]);

    const firstTwoTabContentAlwaysRenderedForPreventSecondLoading = useMemo(
      () => (
        <div
          data-aid={'AutoDiscoveryPresetAdvanced-firstTabHideShowWrapper'}
          style={{
            display: discoveryConfigData.tab === AutoDiscoveryPresetConfigTabsEnum.audit ? 'none' : 'initial',
          }}
        >
          {formRendered}
        </div>
      ),
      [discoveryConfigData.tab, formRendered],
    );

    const getAsideContent = useCallback((tab: AutoDiscoveryPresetConfigTabsEnum) => {
      switch (tab) {
        case AutoDiscoveryPresetConfigTabsEnum.connection:
        case AutoDiscoveryPresetConfigTabsEnum.discovery:
          return <AutoDiscoverWizardAside />;
        default:
          return null;
      }
    }, []);

    const headerStatusBadge = useMemo(
      () => ({
        label: STATUS_TO_LABEL[discoveryConfigData?.preset?.latestRun?.status_enum],
        size: BigidStatusBadgeSize.SMALL,
        type: DISCOVERY_STATUS_TO_STATUS_BADGE_TYPE_MAP[discoveryConfigData?.preset?.latestRun?.status_enum],
      }),
      [discoveryConfigData?.preset?.latestRun?.status_enum],
    );

    useScrollToDsListAfterCheck(formWrapperRef, discoveryConfigData?.formData?.dsList?.length);

    return (
      <AutoDiscoveryWizardContext.Provider
        value={{
          discoveryConfigData,
          setDiscoveryConfigData,
          discoveryConfigDataRef,
        }}
      >
        {isNotFound === undefined && <BigidLoader />}

        {isNotFound === true && <AutoDiscoveryNotFound />}

        {isNotFound === false && (
          <BigidPageLayout
            title={pageTitle}
            titleStartIcons={
              <DiscoveryTitleIconWrapper>
                <Icon />
              </DiscoveryTitleIconWrapper>
            }
            pageBackgroundColor="transparent"
            showHeaderBackground={false}
            statusBadge={headerStatusBadge}
            breadcrumbs={breadcrumbs}
            actions={actions}
          >
            <AutoDiscoveryPresetConfigContainer>
              <AutoDiscoveryPresetConfigTabs>
                <BigidFullPageDialogContainer
                  content={
                    <>
                      {firstTwoTabContentAlwaysRenderedForPreventSecondLoading}
                      {discoveryConfigData.tab === AutoDiscoveryPresetConfigTabsEnum.audit ? (
                        <AutoDiscoveryPresetConfigLogsTab />
                      ) : null}
                    </>
                  }
                  footer={null}
                  aside={getAsideContent(discoveryConfigData.tab)}
                  overlay={
                    discoveryConfigData.isDiscovery && (
                      <AutoDiscoveryWizardOverlay
                        onClick={() =>
                          $state.go(CONFIG.states.DATA_SOURCE_CONNECTIONS, {
                            tab: AutoDiscoveryPresetConfigTabsEnum.discovery,
                          })
                        }
                      />
                    )
                  }
                  isSplitView={!isAuditTabSelected}
                  isFullWidth={isAuditTabSelected}
                  hideScroll={!isAuditTabSelected}
                  dataAid={'AutoDiscoveryWizardAdvancedView'}
                />
              </AutoDiscoveryPresetConfigTabs>
            </AutoDiscoveryPresetConfigContainer>
          </BigidPageLayout>
        )}
      </AutoDiscoveryWizardContext.Provider>
    );
  },
  () => true,
);
