import React, { MutableRefObject, useCallback, useMemo } from 'react';
import { BigidForm, BigidFormField, BigidFormRenderProps } from '@bigid-ui/components';
import { getInitialFormData } from './initialFormConfig';
import { ClassifierGridRow, RegexFormFields } from '../types/ClassifierTypes';
import { styled } from '@mui/material';

interface RenderFormProps extends BigidFormRenderProps {
  fields: BigidFormField[];
}

const ClassifierFormHolder = styled('div')`
  fieldset {
    margin: 0 0 16px;
  }
`;

const RenderForm = ({ renderField }: RenderFormProps) => {
  return (
    <ClassifierFormHolder>
      {renderField(RegexFormFields.classifierName)}
      {renderField('attribute')}
      {renderField(RegexFormFields.categories)}
      {renderField(RegexFormFields.description)}
    </ClassifierFormHolder>
  );
};

interface RegexClassifierFormProps {
  formControlsRef: MutableRefObject<any>;
  initialData?: ClassifierGridRow;
}

export const DOCForm = ({ initialData, formControlsRef }: RegexClassifierFormProps) => {
  const transformedInitialData = {
    ...initialData,
    attribute: {
      friendly_name: initialData?.friendly_name || '',
      glossary_id: initialData?.glossary_id || '',
    },
  };
  const { initialValues, fields } = useMemo(() => getInitialFormData(transformedInitialData), []);

  const memoizedRenderForm = useCallback(
    (props: BigidFormRenderProps) => <RenderForm {...props} fields={fields} />,
    [],
  );

  return (
    <BigidForm
      initialValues={initialValues}
      fields={fields}
      renderForm={memoizedRenderForm}
      controlButtons={false}
      stateAndHandlersRef={formControlsRef}
    />
  );
};
