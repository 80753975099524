import React, { FC } from 'react';
import styled from '@emotion/styled';
import { Divider } from '@mui/material';
import { BigidBody1, BigidBody3 } from '@bigid-ui/components';
import { HeaderData } from '../../ConvertScanProfileTypes';
import { generateDataAid } from '@bigid-ui/utils';

const BoxInfoContainer = styled('div')`
  height: 100%;
  display: flex;
  align-items: center;
`;

const BoxInfoTitleBox = styled(BigidBody3)`
  display: flex;
  flex-direction: column;
`;
const BoxTitleContent = styled(BigidBody1)`
  font-size: 14px !important;
  font-weight: 600;
`;
const DividerStyled = styled(Divider)`
  margin-left: 30px;
`;

export const BoxInfo: FC<HeaderData> = ({ title, content, hasDivider, dataAid = 'BoxInfo' }) => {
  return (
    <BoxInfoContainer data-aid={generateDataAid(dataAid, ['container', title])}>
      <BoxInfoTitleBox>
        <BigidBody3>{title}</BigidBody3>
        <BoxTitleContent>{content}</BoxTitleContent>
      </BoxInfoTitleBox>
      {hasDivider && <DividerStyled orientation="vertical" />}
    </BoxInfoContainer>
  );
};
