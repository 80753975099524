import styled from '@emotion/styled';

export const EditUserPanelWrapper = styled('div')({
  height: '100%',
  padding: '16px',
  overflow: 'auto',
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  gap: '24px',
});

export const EditUserForm = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  gap: '24px',
});

export const EditUserFormSection = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  gap: '16px',
});

export const EditUserWrapper = styled('div')<{ justifyContent: 'space-between' | 'flex-end' }>(
  ({ justifyContent }) => ({
    width: '100%',
    alignItems: 'center',
    display: 'flex',
    justifyContent,
    gap: '8px',
  }),
);

export const EditUserMainControlsWrapper = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  gap: '8px',
});

export const EditUserBlock = styled('div')({
  display: 'flex',
  gap: '16px',
  flexWrap: 'wrap',

  '& fieldset': {
    flex: '1 0 calc(50% - 8px)',
    margin: '0',
  },

  '& p[class*="helperTextError"]': {
    height: 'auto',
  },
});

export const EmptyTokensStateWrapper = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  maxWidth: '302px',
  margin: '0 auto',
  textAlign: 'center',
});
