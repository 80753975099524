import React, { FC } from 'react';
import { BigidBody1 } from '@bigid-ui/components';
import styled from '@emotion/styled';
import { useLocalTranslation } from '../translations';
import { CaseStatus } from '../actionableInsightsService';
import { generateDataAid } from '@bigid-ui/utils';
import { useTheme } from '@mui/styles';
import { Theme } from '@mui/material/styles';

export interface CaseStatusIndicatorProps {
  status: CaseStatus;
  fontSize?: number;
}

export const getDotColorByStatus = (status: CaseStatus, theme: Theme) => {
  switch (status) {
    case CaseStatus.OPEN:
      return theme.vars.palette.bigid.blue900;
    case CaseStatus.REMEDIATED:
      return theme.vars.palette.bigid.green900;
    default:
      return theme.vars.palette.bigid.gray600;
  }
};

export const getTextColorByStatus = (status: CaseStatus, theme: Theme) => {
  switch (status) {
    case CaseStatus.OPEN:
      return theme.vars.palette.bigid.blue900;
    case CaseStatus.REMEDIATED:
      return theme.vars.palette.bigid.green900;
    default:
      return theme.vars.palette.bigid.gray700;
  }
};

const ColoredStatusDot = styled('div')<CaseStatusIndicatorProps>`
  background-color: ${({ status, theme }) => getDotColorByStatus(status, theme)};
  height: 8px;
  width: 8px;
  border-radius: 10px;
  margin-right: 8px;
`;

const Wrapper = styled.div({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
});

export const CaseStatusIndicator: FC<CaseStatusIndicatorProps> = ({ status, fontSize = 13 }) => {
  const { t } = useLocalTranslation('Case.status');
  const theme = useTheme();

  return (
    <>
      {status && (
        <Wrapper data-aid={generateDataAid('CaseStatusIndicator', [status])}>
          <ColoredStatusDot status={status}></ColoredStatusDot>
          <BigidBody1 fontSize={fontSize} color={getTextColorByStatus(status, theme)}>
            {t(status)}
          </BigidBody1>
        </Wrapper>
      )}
    </>
  );
};
