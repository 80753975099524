import { groupBy } from 'lodash';
import { isValidWebURL } from '../../utilities/validation';
import { DEFAULT_SECTION } from './config';
import type { BigidFormStateAndHandlers, BigidFormValues } from '@bigid-ui/components';
import type { EmailEditorFieldType } from './types';
import type { EmailTemplateData } from './emailEditorService';
import type { MutableRefObject } from 'react';

const MAX_SUBJECT_CHARACTERS = 80;

export const validateAllTouched = (values: { [key: string]: unknown }) =>
  Object.keys(values)?.every(key => !values[key]) || Object.keys(values)?.every(field => !!values[field]);

export const groupBySection = (
  values: BigidFormValues,
  predicate: (field: string) => boolean,
  form: MutableRefObject<BigidFormStateAndHandlers>,
) => {
  const fields = Object.keys(values).reduce<string[]>((acc, key) => (predicate(key) ? [...acc, key] : [...acc]), []);
  const predicateFieldsBySection = groupBy(fields, field => {
    const { misc } = form?.current?.getFieldProps(field) ?? {};
    return misc?.section ?? DEFAULT_SECTION;
  });
  return predicateFieldsBySection;
};

export const isFieldType = (
  field: string,
  types: EmailEditorFieldType[],
  form: MutableRefObject<BigidFormStateAndHandlers>,
) => {
  const { misc } = form?.current?.getFieldProps(field) ?? {};
  return types.includes(misc?.fieldType);
};

export const isAllowedVariable = (value: string, template: EmailTemplateData) =>
  template?.allowedVariables?.includes(value);

export const isValidUrlTemplateField = (value: string, template: EmailTemplateData) =>
  value ? (isAllowedVariable(value, template) ? true : isValidWebURL(value)) : true;

export const isValidEmailSubject = (value: string) => {
  return !!value?.trim().length && value.length <= MAX_SUBJECT_CHARACTERS;
};
