import React, { FC, ReactElement, ReactText, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import {
  BigidPaper,
  BigidChip,
  BigidIconType,
  BigidCardListItemAssets,
  BigidCardListItemValue,
  BigidChipProps,
  BigidLink,
  BigidBody3,
  BigidLoader,
} from '@bigid-ui/components';
import makeStyles from '@mui/styles/makeStyles';
import {
  MetadataSearchEntityType,
  MetadataSearchResultEntity,
  MetadataSearchEvents,
  MetadataSearchError,
  MetadataSearchQuery,
  MetadataSearchTransitionPayload,
  MetadataSearchEntityTypeConfig,
  MetadataSearchSupportedEntityTypes,
  MetadataSearchResultEntityFieldName,
} from '../../components/MetadataSearch/MetadataSearchTypes';
import {
  handleTransitionPayloadSend,
  metadataSearchEventEmitter,
  handleTransitionPayloadReceive,
  getSearchResultEntitiesPerTypePreprocessed,
  previewSearchResultEntity,
  MetadataSearchTypeIconSourceMap,
  getEntityTypeConfigByTypeId,
  getIsPredefinedEntityType,
} from '../../components/MetadataSearch/MetadataSearchUtils';
import {
  getEntityTypeConfigs,
  getMetadataSearchEntityFieldByName,
  getMetadataSearchQuickResults,
} from '../../components/MetadataSearch/MetadataSearchService';
import {
  MetadataSearchFieldFilter,
  MetadataSearchFilterCommonFieldName,
  MetadataSearchFilterFieldType,
} from '../../components/MetadataSearch/MetadataSearchFilters/MetadataSearchFiltersTypes';
import { preprocessFilter } from '../../components/MetadataSearch/MetadataSearchFilters/MetadataSearchFiltersUtils';
import {
  formatResults,
  getSearchResults,
  getTotalCount,
  MetadataFetchFullSearchPayload,
  parseFiltersToChips,
  getListTotalLabel,
} from './metadataSearchResultsService';
import {
  BigidGrid,
  BigidGridColumn,
  BigidGridColumnTypes,
  FetchTotalCount,
  useFetch,
  BigidGridQueryComponents,
  FormatterOnClickHandlerPayloadData,
  FormatterOnClickHandlerReturnData,
} from '@bigid-ui/grid';
import { $state, $stateParams } from '../../services/angularServices';
import { pageHeaderService } from '../../../common/services/pageHeaderService';
import { MetadataSearchNoResultsHandler } from './MetadataSearchNoResultsHandler';
import { MetadataSearchNoCriteriaHandler } from './MetadataSearchNoCriteriaHandler';
import { MetadataSearchErrorHandler } from './MetadataSearchErrorHandler';
import { MetadataSearchAssetsPreview } from './MetadataSearchAssetsPreview';
import { countBy, isEqual } from 'lodash';
import { AxiosError } from 'axios';
import { MetadataSearchIndexingIndicator } from './MetadataSearchIndexingIndicator';
import classNames from 'classnames';
import { getApplicationPreference } from '../../services/appPreferencesService';

export type MetadataSearchResultsGridRow = {
  id: ReactText;
  entity: MetadataSearchResultEntity;
  entityId: string;
  type?: MetadataSearchEntityType;
  icon?: BigidIconType;
  iconName?: string;
  iconText?: string;
  name?: BigidCardListItemValue;
  owner: BigidCardListItemValue;
  container?: BigidCardListItemValue;
  updateDate: BigidCardListItemValue;
  attributes: BigidCardListItemAssets;
};

const DEFAULT_VISIBLE_CHIPS_LIST_MAX_HEIGHT = 102;

const useStyles = makeStyles({
  root: {
    height: 'calc(100% - 10px)',
    width: '100%',
  },
  wrapper: {
    height: '100%',
    width: '100%',
    padding: '16px 24px 0px 8px',
  },
  wrapperInner: {
    height: '100%',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  wrapperInnerMessage: {
    justifyContent: 'center',
  },
  headerContainer: {
    width: '100%',
    marginBottom: '16px',
    paddingLeft: '24px',
  },
  filters: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    '& .MuiChip-root': {
      margin: '4px 4px 4px 0px',
      flex: '0 0 auto',
    },
  },
  filtersCollapsed: {
    maxHeight: `${DEFAULT_VISIBLE_CHIPS_LIST_MAX_HEIGHT}px`,
    overflow: 'hidden',
  },
  removeFilters: {
    display: 'flex',
    '& .MuiLink-root': {
      fontSize: '0.75rem',
    },
  },
  toggleMoreFilters: {
    '& .MuiLink-root': {
      fontSize: '0.75rem',
    },
  },
  listTotalContainer: {
    display: 'flex',
    paddingRight: '12px',
    marginTop: '16px',
  },
  contentContainer: {
    overflow: 'hidden',
    display: 'flex',
    flex: 1,
  },
  listWrapper: {
    width: '100%',
    display: 'flex',
    flexFlow: 'column nowrap',
    overflowY: 'hidden',
    paddingBottom: '8px',
    position: 'relative',
  },
  listWrapperShrinked: {
    width: 'calc(100% - 370px)',
  },
  assetsPanelWrapper: {
    width: '370px',
    marginLeft: '16px',
    paddingTop: '5px',
  },
});

function getFilterFromURLParams(): MetadataSearchFieldFilter[] {
  const filter = handleTransitionPayloadReceive($stateParams).filter;
  return filter || [];
}

function getSearchQueryFromURLParams(): MetadataSearchQuery {
  const query = handleTransitionPayloadReceive($stateParams).text;
  return query || '';
}

function updateStateParamsDueToFilterChange(payload: MetadataSearchTransitionPayload) {
  $state.go($state.current.name, handleTransitionPayloadSend(payload), {
    reload: false,
    notify: false,
  });
}

async function fetchSearchResults(
  payload: MetadataFetchFullSearchPayload,
  entityTypeConfigs: MetadataSearchEntityTypeConfig[],
  supportedEntityTypes: MetadataSearchSupportedEntityTypes,
): Promise<MetadataSearchResultsGridRow[]> {
  const { results } = await getSearchResults(payload);
  return formatResults(results, entityTypeConfigs, supportedEntityTypes, MetadataSearchTypeIconSourceMap);
}

export const MetadataSearchResults: FC = () => {
  const classes = useStyles();

  const query = getSearchQueryFromURLParams();
  const filters = getFilterFromURLParams();

  const filtersListRef = useRef<HTMLDivElement>();

  const [noSearchApplied, setNoSearchApplied] = useState<boolean>(query.length === 0 && filters.length === 0);
  const [externalFilter, setExternalFilter] = useState<MetadataSearchFieldFilter[]>(filters);
  const [searchQuery] = useState<MetadataSearchQuery>(query);
  const [chipFilters, setChipFilters] = useState<BigidChipProps[]>([]);
  const [selectedItem, setSelectedItem] = useState<MetadataSearchResultsGridRow>(null);
  const [isFilterListHeightExceededLimit, setIsFilterListHeightExceededLimit] = useState<boolean>(false);
  const [isFilterListToggled, setIsFilterListToggled] = useState<boolean>(false);
  const [isEntityTypeConfigsFetched, setIsEntityTypeConfigsFetched] = useState<boolean>(false);
  const [entityTypeConfigs, setEntityTypeConfigs] = useState<MetadataSearchEntityTypeConfig[]>([]);
  const [totalLabel, setTotalLabel] = useState<string>();

  const supportedEntityTypesMap: MetadataSearchSupportedEntityTypes = useMemo(
    () => ({
      [MetadataSearchEntityType.TABLE]: true,
      [MetadataSearchEntityType.FILE]: true,
      [MetadataSearchEntityType.DATA_SOURCE]: true,
      [MetadataSearchEntityType.POLICY]: true,
      [MetadataSearchEntityType.OTHER_CATALOG]: true,
      [MetadataSearchEntityType.CLASSIFICATION_FINDINGS]: getApplicationPreference('USE_METADATA_SEARCH_FOR_DSAR'),
    }),
    [],
  );

  const useFetchState = useFetch({
    pageSize: 20,
    externalFilter,
    initialSorting: [{ field: 'name', order: 'asc' }],
    fetchDataFunction: async (queryComponents: BigidGridQueryComponents) => {
      const { skip, limit } = queryComponents;
      const filterPreprocessed = preprocessFilter(externalFilter, true);
      const searchResultData = await fetchSearchResults(
        {
          text: getSearchQueryFromURLParams(),
          filter: filterPreprocessed,
          paging: { skip, limit },
        },
        entityTypeConfigs,
        supportedEntityTypesMap,
      );
      const { cancel, promise } = getMetadataSearchQuickResults({
        text: getSearchQueryFromURLParams(),
        filter: filterPreprocessed,
      });

      const fetchCount: FetchTotalCount = {
        fetchCountCanceler: cancel,
        fetchCountFunction: async () => {
          const { data } = await promise;
          const typeResults = getSearchResultEntitiesPerTypePreprocessed(
            data?.typeResults || [],
            entityTypeConfigs,
            supportedEntityTypesMap,
          );
          const typeFilter = filterPreprocessed.find(
            ({ field }) => field === MetadataSearchFilterCommonFieldName.ENTITY_TYPE,
          );
          const typeFilterValue = typeFilter?.value;
          const totalCount = typeFilterValue
            ? typeResults.find(({ type }) => type === typeFilterValue)?.count
            : getTotalCount(typeResults);

          return {
            totalCount,
          };
        },
      };

      return {
        data: searchResultData,
        totalCount: queryComponents.requireTotalCount
          ? (await fetchCount.fetchCountFunction(queryComponents)).totalCount
          : undefined,
      };
    },
    isContentReady: isEntityTypeConfigsFetched,
  });

  const handleAssetsPreviewClose = () => {
    setSelectedItem(null);
  };

  const handleMetadataSearchResultEntityClick = useCallback(
    (entity: MetadataSearchResultEntity): void => {
      const type = getMetadataSearchEntityFieldByName(entity, MetadataSearchResultEntityFieldName.TYPE)
        ?.value as MetadataSearchEntityType;
      const entityTypeConfig = getEntityTypeConfigByTypeId(entityTypeConfigs, type);
      const transitionPayload: MetadataSearchTransitionPayload = {
        text: getSearchQueryFromURLParams(),
        filter: externalFilter,
      };

      previewSearchResultEntity(entity, transitionPayload, entityTypeConfig, true);
    },
    [entityTypeConfigs, externalFilter],
  );

  const handleFilterDelete = useCallback(
    (chip: BigidChipProps): void => {
      const [fieldName, value] = chip.id
        .toString()
        .split(/\|\|(.+)/)
        .map(part => part.trim());

      const filter = externalFilter.find(({ field }) => field === fieldName);

      let filtersUpdated: MetadataSearchFieldFilter[];

      if (Array.isArray(filter?.value) && filter.fieldType !== MetadataSearchFilterFieldType.DATE) {
        const filterUpdated = {
          ...filter,
          value: filter.value.filter(valueEntity => JSON.stringify(valueEntity) !== value),
        };

        if (filterUpdated.value.length > 0) {
          metadataSearchEventEmitter.emit(MetadataSearchEvents.FIELD_FILTER_UPDATE, filterUpdated);
        } else {
          metadataSearchEventEmitter.emit(MetadataSearchEvents.FIELD_FILTER_DELETE, filterUpdated);
        }

        filtersUpdated =
          filterUpdated.value.length > 0
            ? externalFilter.map(filter => {
                return filter.field === fieldName ? filterUpdated : filter;
              })
            : externalFilter.filter(({ field }) => field !== fieldName);
      } else {
        filtersUpdated = externalFilter.filter(({ field }) => field !== fieldName);
        metadataSearchEventEmitter.emit(MetadataSearchEvents.FIELD_FILTER_DELETE, filter);
      }

      setExternalFilter(filtersUpdated);
      setNoSearchApplied(filtersUpdated.length === 0 && searchQuery.length === 0);

      const chips = parseFiltersToChips(filtersUpdated, entityTypeConfigs);
      setChipFilters(chips);

      updateStateParamsDueToFilterChange({
        text: searchQuery,
        filter: filtersUpdated,
      });
    },
    [entityTypeConfigs, externalFilter, searchQuery],
  );

  const resetFilters = useCallback(() => {
    setExternalFilter([]);
    setChipFilters([]);
    setNoSearchApplied(searchQuery.length === 0);
    updateStateParamsDueToFilterChange({
      text: searchQuery,
      filter: [],
    });
  }, [searchQuery]);

  const handleFilterDeleteAll = useCallback((): void => {
    metadataSearchEventEmitter.emit(MetadataSearchEvents.FIELD_FILTER_DELETE_ALL);
    resetFilters();
  }, [resetFilters]);

  const handleFiltersListToggled = useCallback((): void => {
    setIsFilterListToggled(prevIsFilterListToggled => !prevIsFilterListToggled);
  }, []);

  const getErrorConfig = useCallback((error: AxiosError): MetadataSearchError => {
    let errorConfig = null;

    if (error) {
      const { response } = error;

      if (response?.status === 408) {
        errorConfig = {
          title: 'Your search timed out',
          description: 'Reset your filters or shorten your search term and try again.',
        };
      } else {
        errorConfig = {};
      }
    }

    return errorConfig;
  }, []);

  useEffect(() => {
    const filters: MetadataSearchFieldFilter[] = getFilterFromURLParams() || [];
    const hasCustomEntityTypeAmongFilters = !!filters.find(
      ({ value, fieldType }) =>
        fieldType === MetadataSearchFilterFieldType.ENTITY_TYPE &&
        !getIsPredefinedEntityType(value as MetadataSearchEntityType),
    );

    if (!hasCustomEntityTypeAmongFilters) {
      const chips = parseFiltersToChips(filters, []);
      setChipFilters(chips);
    }

    getEntityTypeConfigs()
      .then(typeConfigs => {
        setEntityTypeConfigs(typeConfigs);

        if (hasCustomEntityTypeAmongFilters) {
          const chips = parseFiltersToChips(filters, typeConfigs);
          setChipFilters(chips);
        }
      })
      .catch(({ message }) => {
        console.error(`An error has occurred: ${message}`);
      })
      .finally(() => {
        setIsEntityTypeConfigsFetched(true);
      });

    pageHeaderService.setTitle({
      rightSideComponentsContainer: <MetadataSearchIndexingIndicator />,
      showBackButton: false,
      breadcrumbs: [
        {
          label: 'Search Results',
        },
      ],
    });
  }, []);

  useEffect(() => {
    setIsFilterListHeightExceededLimit(filtersListRef.current?.scrollHeight > DEFAULT_VISIBLE_CHIPS_LIST_MAX_HEIGHT);
  }, [useFetchState]);

  useEffect(() => {
    const unregisterOnFiltersReset = metadataSearchEventEmitter.addEventListener(
      MetadataSearchEvents.FIELD_FILTER_DELETE_ALL,
      resetFilters,
    );

    return () => {
      unregisterOnFiltersReset();
    };
  }, [resetFilters]);

  useEffect(() => {
    if (useFetchState.isCountFetched) {
      const label = getListTotalLabel(useFetchState.totalRowsCount, externalFilter, entityTypeConfigs);
      setTotalLabel(label);
    }
  }, [entityTypeConfigs, externalFilter, useFetchState.totalRowsCount, useFetchState.isCountFetched]);

  const columns = useMemo<BigidGridColumn<MetadataSearchResultsGridRow>[]>(
    () => [
      {
        title: 'Results',
        name: 'results',
        isListColumn: false,
        disableTooltip: true,
        noHeader: true,
        isNotDraggable: true,
        width: 'auto',
        formatter: {
          onClick: ({ row }: FormatterOnClickHandlerPayloadData): Promise<FormatterOnClickHandlerReturnData> => {
            const newSelection = !isEqual(selectedItem, row) ? row : null;
            setSelectedItem(newSelection as MetadataSearchResultsGridRow);
            return Promise.resolve({ shouldGridReload: false });
          },
        },
        getCellValue: ({ id, icon, iconName, iconText, name, owner, updateDate, container, attributes, entity }) => {
          return {
            card: {
              id,
              icon,
              iconName,
              iconText,
              title: name,
              subtitle: updateDate || container,
              description: owner,
              assets: attributes,
              isPreviewed: selectedItem?.id === id,
              onClick: () => handleMetadataSearchResultEntityClick(entity),
            },
          };
        },
        type: BigidGridColumnTypes.CARD,
      },
    ],
    [handleMetadataSearchResultEntityClick, selectedItem],
  );

  const gridConfig = useMemo(
    () => ({
      columns,
      pageSize: useFetchState.pageSize,
      rows: isEntityTypeConfigsFetched ? useFetchState.rows || [] : [],
      showSelectAll: false,
      skip: useFetchState.skip,
      onPagingChanged: useFetchState.onPagingChanged,
      onSortingChanged: useFetchState.onSortingChanged,
      totalRowsCount: useFetchState.totalRowsCount,
      onFiltersChange: useFetchState.onFiltersChanged,
      defaultSorting: useFetchState.defaultSorting,
      apiRef: useFetchState.apiRef,
      loading: useFetchState.isLoading || !isEntityTypeConfigsFetched,
      cardListMode: true,
      isContentReady: isEntityTypeConfigsFetched,
    }),
    [columns, isEntityTypeConfigsFetched, useFetchState],
  );

  const messageComponent = useMemo((): ReactElement => {
    const showEmptyState = !useFetchState.isLoading && !useFetchState.rows?.length && !useFetchState.error;
    const errorConfig = getErrorConfig(useFetchState.error as AxiosError);

    switch (true) {
      case noSearchApplied:
        return <MetadataSearchNoCriteriaHandler />;
      case Boolean(errorConfig):
        return <MetadataSearchErrorHandler {...errorConfig} />;
      case showEmptyState:
        return <MetadataSearchNoResultsHandler />;
      default:
        return null;
    }
  }, [getErrorConfig, noSearchApplied, useFetchState]);

  const canDeleteFilters = useFetchState.isInitiallyFetched && !useFetchState.isLoading;

  //TODO: figure out how to fetch entity type config before grid data
  return (
    <div className={classes.root}>
      <BigidPaper classes={{ root: classes.wrapper }}>
        <div className={classNames(classes.wrapperInner, messageComponent && classes.wrapperInnerMessage)}>
          {messageComponent || (
            <>
              <div className={classes.headerContainer}>
                {chipFilters.length > 0 && (
                  <>
                    <div
                      ref={filtersListRef}
                      className={classNames(classes.filters, !isFilterListToggled && classes.filtersCollapsed)}
                      data-aid="MetadataSearchResults-filters"
                    >
                      {chipFilters.map((chip, index, { length }) => {
                        const [fieldName] = chip.id
                          .toString()
                          .split(/\|\|(.+)/)
                          .map(part => part.trim());
                        const isEntityTypeChipAndIsNotLast =
                          index !== length - 1 && fieldName === MetadataSearchFilterCommonFieldName.ENTITY_TYPE;

                        return (
                          <BigidChip
                            {...chip}
                            key={index}
                            size="small"
                            disabled={isEntityTypeChipAndIsNotLast || !canDeleteFilters}
                            onDelete={() => handleFilterDelete(chip)}
                            dataAid={`MetadataSearchResults-filters-${chip.label}`}
                          />
                        );
                      })}
                      <div className={classes.removeFilters}>
                        <BigidLink
                          dataAid="MetadataSearchResults-remove-filters"
                          text="Remove filters"
                          underline="none"
                          disabled={!canDeleteFilters}
                          onClick={handleFilterDeleteAll}
                          title="Remove filters"
                        />
                      </div>
                    </div>
                    {isFilterListHeightExceededLimit && (
                      <div className={classes.toggleMoreFilters}>
                        <BigidLink
                          dataAid="MetadataSearchResults-toggle-filters"
                          text={isFilterListToggled ? 'Show less' : 'Show all'}
                          underline="none"
                          onClick={handleFiltersListToggled}
                          title={isFilterListToggled ? 'Show less' : 'Show all'}
                        />
                      </div>
                    )}
                  </>
                )}
                <div className={classes.listTotalContainer} data-aid="MetadataSearchResults-total">
                  <BigidBody3>{totalLabel}</BigidBody3>
                </div>
              </div>

              <div className={classes.contentContainer}>
                <div className={classNames(classes.listWrapper, !!selectedItem && classes.listWrapperShrinked)}>
                  {isEntityTypeConfigsFetched ? (
                    <BigidGrid {...gridConfig} dataAid="MetadataSearchResults-grid" />
                  ) : (
                    <BigidLoader />
                  )}
                </div>
                {!!selectedItem && (
                  <div className={classes.assetsPanelWrapper}>
                    <MetadataSearchAssetsPreview
                      dataAid={`MetadataSearchAssetsPreview-${selectedItem.id}`}
                      resultEntity={selectedItem.entity}
                      onClose={handleAssetsPreviewClose}
                    />
                  </div>
                )}
              </div>
            </>
          )}
        </div>
      </BigidPaper>
    </div>
  );
};
