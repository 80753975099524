import React, { ChangeEvent, Dispatch, FC, ReactElement, SetStateAction, useEffect, useState } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { CustomAppParamsTable } from '../../components/CustomAppParamsTable/CustomAppParamsTable';
import { CustomAppAction, PresetPayload } from '../CustomAppActions/CustomAppActions';
import {
  BigidBody1,
  BigidButtonIcon,
  BigidColorsV2,
  BigidDropdown,
  BigidHeading5,
  BigidHeading4,
  BigidTextField,
  BigidDropdownOption,
} from '@bigid-ui/components';
import { isEmpty, noop, cloneDeep } from 'lodash';
import { CustomAppParam } from '../EditCustomApp/EditCustomApp';
import {
  BigidAdvanceSearchIcon,
  BigidChevronCircleDownIcon,
  BigidChevronCircleUpIcon,
  BigidChevronUpIcon,
  BigidChevronDownIcon,
} from '@bigid-ui/icons';
import { TPATrackingEvents, trackTPAEvent } from '../../customAppEventsTrackerUtils';
import { $stateParams } from '../../../../services/angularServices';
import { getApplicationPreference } from '../../../../services/appPreferencesService';
import { GlobalPreset } from '../../utils/CustomAppTypes';

export interface EditActionPresetProps {
  action: CustomAppAction;
  globalParams: CustomAppParam[];
  preset: PresetPayload;
  onPresetChange?: (obj: Record<string, any>) => void;
  setIsNameValid?: Dispatch<SetStateAction<boolean>>;
  setIsParametersValid?: Dispatch<SetStateAction<boolean>>;
  setIsGlobalParamsValid?: Dispatch<SetStateAction<boolean>>;
  readOnly?: boolean;
  appName?: string;
  globalPresets?: GlobalPreset[];
}

const useStyles = makeStyles({
  wrapper: {
    padding: '16px',
  },
  title: {
    color: BigidColorsV2.purple[500],
    paddingBottom: '24px',
  },
  subTitle: {
    paddingBottom: '12px',
  },
  infoContent: {
    paddingBottom: '16px',
    width: '50%',
    minWidth: '500px',
  },
  mandatorySection: {
    paddingLeft: '2px',
    color: BigidColorsV2.red[600],
  },
  presetProperties: {
    width: '25%',
  },
  globalParamsToggleWrapper: {
    color: BigidColorsV2.blue[700],
    display: 'flex',
    paddingBottom: '16px',
  },
  titleSpacing: {
    paddingRight: '8px',
    paddingLeft: '8px',
  },
  actionsParamsWrapper: {
    minHeight: '64px',
  },
  buttonAndHeaderRow: {
    display: 'flex',
    alignItems: 'center',
  },
});

interface InfoBoxProps {
  title: string;
  isMandatory?: boolean;
  children: ReactElement;
  childClass?: string;
}

export const MANDATORY_ERROR_MESSAGE = 'Missing mandatory field';
export const NAME_EXISTS_ERROR_MESSAGE = 'Name already in use';

export const InfoBox: FC<InfoBoxProps> = ({ title, isMandatory = false, children, childClass }) => {
  const classes = useStyles({});
  return (
    <>
      <div className={classes.subTitle}>
        <BigidHeading5>
          {title}
          {isMandatory && <span className={classes.mandatorySection}>*</span>}
        </BigidHeading5>
      </div>
      <div className={`${classes.infoContent} ${childClass}`}>{children}</div>
    </>
  );
};

export const EditActionPreset: FC<EditActionPresetProps> = ({
  action,
  globalParams,
  preset,
  setIsNameValid = noop,
  setIsParametersValid = noop,
  setIsGlobalParamsValid = noop,
  onPresetChange = noop,
  readOnly = false,
  appName,
  globalPresets,
}) => {
  const globalPresetsFromStateOrProps = globalPresets || $stateParams?.appInfo?.globalPresets || [];
  const initAttachedGlobalPreset =
    (
      globalPresetsFromStateOrProps.find((globalPreset: GlobalPreset) => globalPreset._id === preset.globalPresetId) ||
      globalPresetsFromStateOrProps.find((globalPreset: GlobalPreset) => globalPreset.is_default === true)
    )?.name || '';
  const [selectedGlobalPreset, setSelectedGlobalPreset] = useState<string>(initAttachedGlobalPreset);
  const [presetParams, setPresetParams] = useState<Record<string, string>>(preset.paramsKeyValue);
  const [presetGlobalParams, setPresetGlobalParams] = useState<Record<string, Record<string, string>>>({
    [initAttachedGlobalPreset]: cloneDeep(preset.globalParamsKeyValue),
  });
  const [showGlobalParams, setShowGlobalParams] = useState<boolean>(!!preset.overrideGlobalParams);
  const [showGlobalPresets, setShowGlobalPresets] = useState<boolean>(!!preset.globalPresetId);

  const [errorMessage, setErrorMessage] = useState<string>('');
  const classes = useStyles();

  useEffect(() => setIsNameValid(!errorMessage), [errorMessage, setIsNameValid]);

  const validatePresetName = (name: string) => {
    if (isEmpty(name)) {
      setErrorMessage(MANDATORY_ERROR_MESSAGE);
      return;
    }

    const isNameExists = preset.name !== name && action.presets.some(preset => preset.name === name);
    setErrorMessage(isNameExists ? NAME_EXISTS_ERROR_MESSAGE : '');
  };

  const onTextInputFieldChanged =
    (key: string) =>
    ({ target: { value } }: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
      if (key === 'name') validatePresetName(value);
      onPresetChange({ [key]: value });
    };

  const onPresetParamsChange = (key: string, value: string) => {
    setPresetParams(state => ({ ...state, [key]: value }));
    onPresetChange({ paramsKeyValue: { ...presetParams, [key]: value } });
  };

  const onShowGlobalParams = () => {
    setShowGlobalParams(!showGlobalParams);
  };

  const onShowGlobalPresets = () => {
    setShowGlobalPresets(!showGlobalPresets);
  };

  const onGlobalParamsChange = (key: string, value: string) => {
    setPresetGlobalParams(state => ({
      ...state,
      [selectedGlobalPreset]: { ...state[selectedGlobalPreset], [key]: value },
    }));
    onPresetChange({ globalParamsKeyValue: { ...presetGlobalParams[selectedGlobalPreset], [key]: value } });
  };

  const toggleGlobalParamValue = (paramKey: string, paramValue: string, enabled: boolean) => {
    if (enabled) {
      trackTPAEvent(TPATrackingEvents.TPA_PRESET_OVERRIDE_GLOBAL_PARAM_CLICK, {
        appName,
        actionName: action.name,
        presetName: preset.name,
      });
      onGlobalParamsChange(paramKey, paramValue);
    } else {
      const globalParamsAfterRemoval = cloneDeep(presetGlobalParams[selectedGlobalPreset]);
      delete globalParamsAfterRemoval[paramKey];
      setPresetGlobalParams(prevState => {
        return { ...prevState, [selectedGlobalPreset]: globalParamsAfterRemoval };
      });
      onPresetChange({ globalParamsKeyValue: globalParamsAfterRemoval });
    }
  };

  const isGlobalPresetsEnabled = () => {
    return getApplicationPreference('TPA_GLOBAL_PRESETS_ENABLED');
  };

  const globalPresetDropDownOptions: BigidDropdownOption[] = globalPresetsFromStateOrProps.map(
    (globalPreset: GlobalPreset) => {
      return {
        id: globalPreset._id,
        value: globalPreset.name,
        displayValue: globalPreset.name,
        isSelected: globalPreset.name === selectedGlobalPreset,
      };
    },
  );

  const getGlobalParamsForOverrideFromGlobalPreset = (): CustomAppParam[] => {
    return globalParams.map((globalParam: CustomAppParam) => {
      return {
        ...globalParam,
        value: globalPresetsFromStateOrProps.find(
          (globalPreset: GlobalPreset) => globalPreset.name === selectedGlobalPreset,
        )?.params_key_value[globalParam.name],
      };
    });
  };

  const onSelectGlobalPreset = (option: BigidDropdownOption[]) => {
    const newSelectedGlobalPreset = option[0].value;
    onPresetChange({
      globalPresetId: option[0].id,
      globalParamsKeyValue: presetGlobalParams[newSelectedGlobalPreset],
    });
    setSelectedGlobalPreset(newSelectedGlobalPreset);
  };

  return (
    <div className={classes.wrapper}>
      {!readOnly && (
        <>
          <div className={classes.title}>
            <BigidHeading4 color={'inherit'}>{`${action.friendlyName || action.name} - Edit Preset`}</BigidHeading4>
          </div>
          <InfoBox title={'Action Description'}>
            <BigidBody1>{action.description}</BigidBody1>
          </InfoBox>
          <InfoBox title={'Preset Name'} isMandatory childClass={classes.presetProperties}>
            <BigidTextField
              defaultValue={preset?.name}
              onChange={onTextInputFieldChanged('name')}
              errorMessage={errorMessage}
            />
          </InfoBox>

          <InfoBox title={'Preset Description'}>
            <BigidTextField
              id={'description'}
              multiline
              rows={2}
              defaultValue={preset?.description}
              onChange={onTextInputFieldChanged('description')}
            />
          </InfoBox>
        </>
      )}
      <div className={classes.actionsParamsWrapper}>
        {!!action.params?.length && (
          <InfoBox title={'Parameters'}>
            <CustomAppParamsTable
              params={action.params}
              onParamChange={onPresetParamsChange}
              setIsValuesValid={setIsParametersValid}
              values={presetParams}
              readOnly={readOnly}
            />
          </InfoBox>
        )}
      </div>
      {!!globalParams?.length && (
        <>
          <div>
            {isGlobalPresetsEnabled() && (
              <>
                <div className={classes.buttonAndHeaderRow}>
                  <BigidButtonIcon
                    icon={showGlobalPresets ? BigidChevronUpIcon : BigidChevronDownIcon}
                    onClick={onShowGlobalPresets}
                    dataAid="quick_view"
                  />
                  <BigidHeading5>{'Global Parameters'}</BigidHeading5>
                </div>
                {showGlobalPresets && (
                  <>
                    <InfoBox title={''}>
                      <>
                        <BigidDropdown
                          isDisabled={readOnly}
                          value={globalPresetDropDownOptions.filter(option => option.isSelected)}
                          onSelect={onSelectGlobalPreset}
                          options={globalPresetDropDownOptions}
                        />
                        <CustomAppParamsTable
                          params={getGlobalParamsForOverrideFromGlobalPreset()}
                          setIsValuesValid={setIsGlobalParamsValid}
                          onParamChange={onGlobalParamsChange}
                          onParamToggle={toggleGlobalParamValue}
                          values={presetGlobalParams[selectedGlobalPreset]}
                          readOnly={readOnly}
                          shouldEnableOverriding={true}
                          isGlobalParams={true}
                        />
                      </>
                    </InfoBox>
                  </>
                )}
              </>
            )}
          </div>

          {!isGlobalPresetsEnabled() && (
            <>
              <div className={classes.globalParamsToggleWrapper}>
                <BigidAdvanceSearchIcon />
                <BigidBody1 color={'inherit'} className={classes.titleSpacing}>
                  Override Global Parameters
                </BigidBody1>
                <BigidButtonIcon
                  icon={showGlobalParams ? BigidChevronCircleUpIcon : BigidChevronCircleDownIcon}
                  onClick={onShowGlobalParams}
                  dataAid="quick_view"
                />
              </div>
              {showGlobalParams && (
                <InfoBox title={'Global Parameters'}>
                  <CustomAppParamsTable
                    params={globalParams}
                    setIsValuesValid={setIsGlobalParamsValid}
                    onParamChange={onGlobalParamsChange}
                    onParamToggle={toggleGlobalParamValue}
                    values={presetGlobalParams[selectedGlobalPreset]}
                    readOnly={readOnly}
                    shouldEnableOverriding={true}
                    isGlobalParams={true}
                  />
                </InfoBox>
              )}
            </>
          )}
        </>
      )}
    </div>
  );
};
