import React from 'react';
import { dateTimeService } from '@bigid-ui/i18n';
import { BigidDeleteIcon, BigidUserIcon } from '@bigid-ui/icons';
import {
  BigidEntity,
  BigidFilterToolbarProps,
  BigidFilterType,
  ToolbarAction,
  ToolbarActionType,
} from '@bigid-ui/components';
import { BigidGridColumnTypes, BigidGridWithToolbarProps } from '@bigid-ui/grid';
import { DeleteUserCallback, SystemRole, SystemUser } from '../../../types';
import { getUserName } from '../../../utils';
import { UserOrigin } from '../styles';
import { DATE_FORMAT } from '../constants';
import { getFixedT } from '../translations';

interface GetToolbarConfigArgs {
  onDeleteUser: DeleteUserCallback;
  isManagePermitted?: boolean;
  isBatchActionsEnbaled?: boolean;
  authUser: SystemUser;
}

interface GetGridInlineActions {
  onClickDelete: DeleteUserCallback;
  isManagePermitted?: boolean;
  authUser: SystemUser;
}

const t = getFixedT('grid');

export const getFilterTollbarConfig = (
  roles: SystemRole[],
  onFiltersChange: BigidFilterToolbarProps['onFiltersChange'],
): BigidFilterToolbarProps => ({
  filters: [
    ...(!!roles?.length
      ? [
          {
            title: t('columns.roles'),
            field: 'roleIds',
            operator: 'in',
            value: [],
            options: roles.map(({ name, _id }) => ({ isSelected: false, label: name, value: _id })),
          } as BigidFilterType,
        ]
      : []),
    {
      title: t('columns.origin'),
      field: 'origin',
      operator: 'in',
      value: [],
      options: [
        {
          label: 'Local',
          value: 'local',
          isSelected: false,
        },
        {
          label: 'SAML',
          value: 'saml',
          isSelected: false,
        },
        {
          label: 'Active Directory',
          value: 'idp',
          isSelected: false,
        },
      ],
    },
  ],
  searchConfig: {
    searchFilterKeys: ['name', 'email', 'firstName', 'lastName'],
    placeholder: t('searchFieldPlaceholderSuffix'),
    initialValue: '',
    operator: 'textSearch',
    autoSubmit: true,
  },
  onFiltersChange,
});

export const getToolbarConfig = ({
  onDeleteUser,
  isManagePermitted,
  isBatchActionsEnbaled,
  authUser,
}: GetToolbarConfigArgs): Pick<BigidGridWithToolbarProps<SystemUser>, 'toolbarActions'> => {
  const baseActions: ToolbarAction[] = [
    {
      disable: () => false,
      hideActionInToolBar: true,
      execute: async ({ selectedRows }) => {
        const { firstName, lastName, name, id } = selectedRows[0];
        const userName = getUserName({ firstName, lastName, name });
        onDeleteUser(userName, id);

        return {
          shouldClearSelection: true,
        };
      },
      icon: BigidDeleteIcon,
      label: t('actions.inline.deleteUser'),
      show: ({ selectedRows }) => {
        const { name } = selectedRows[0];
        return isManagePermitted && authUser.name !== name;
      },
    },
  ];

  const batchActions: ToolbarAction[] = [
    {
      label: t('actions.bulk.deleteUser.text'),
      icon: BigidDeleteIcon,
      tooltip: t('actions.bulk.deleteUser.tooltip'),
      type: ToolbarActionType.TERTIARY,
      execute: async () => {
        return {
          shouldGridReload: true,
          shouldClearSelection: true,
        };
      },
      disable: () => true,
      show: ({ selectedRowIds }) => isManagePermitted && selectedRowIds.length > 0,
    },
    {
      label: t('actions.bulk.assignRoles.text'),
      icon: BigidUserIcon,
      tooltip: t('actions.bulk.assignRoles.tooltip'),
      type: ToolbarActionType.TERTIARY,
      execute: async () => {
        return Promise.resolve({
          shouldGridReload: true,
          shouldClearSelection: true,
        });
      },
      disable: () => true,
      show: ({ selectedRowIds }) => isManagePermitted && selectedRowIds.length > 0,
    },
  ];

  const toolbarActions = isBatchActionsEnbaled ? [...baseActions, ...batchActions] : baseActions;

  const config: Pick<BigidGridWithToolbarProps<SystemUser>, 'toolbarActions'> = {
    toolbarActions,
  };

  return config;
};

export const getColumnsConfig = (roles: SystemRole[]): Pick<BigidGridWithToolbarProps<SystemUser>, 'columns'> => ({
  columns: [
    {
      name: 'User',
      title: t('columns.user'),
      getCellValue: ({ firstName, lastName, name }) => {
        return (
          <BigidEntity
            name={getUserName({ firstName, lastName, name })}
            orientation="horizontal"
            size="small"
            isNameBold={false}
          />
        );
      },
      type: BigidGridColumnTypes.CUSTOM,
      sortingEnabled: true,
      filteringEnabled: true,
    },
    {
      name: 'Email',
      title: t('columns.email'),
      getCellValue: ({ name }) => name,
      type: BigidGridColumnTypes.TEXT,
      sortingEnabled: true,
      filteringEnabled: true,
    },
    {
      name: 'Origin',
      title: t('columns.origin'),
      getCellValue: ({ origin }) => <UserOrigin>{origin}</UserOrigin>,
      type: BigidGridColumnTypes.TEXT,
      sortingEnabled: true,
      filteringEnabled: true,
    },
    {
      name: 'Roles',
      title: t('columns.roles'),
      getCellValue: ({ roleIds = [] }) => ({
        chips: {
          value: !!roles.length ? roleIds.map(role => ({ label: roles.find(({ _id }) => role === _id)?.name })) : [],
          isEditMode: false,
          isDisabled: true,
        },
      }),
      type: BigidGridColumnTypes.CHIPS,
      sortingEnabled: false,
    },
    {
      name: 'LastLogin',
      title: t('columns.lastLogin'),
      getCellValue: ({ last_successful_login_at }) => dateTimeService.formatDate(last_successful_login_at, DATE_FORMAT),
      type: BigidGridColumnTypes.CUSTOM,
      sortingEnabled: false,
      filteringEnabled: true,
    },
  ],
});

export const getGridInlineActions = ({
  onClickDelete,
  isManagePermitted,
  authUser,
}: GetGridInlineActions): ToolbarAction[] => [
  {
    disable: () => false,
    execute: async ({ selectedRows }) => {
      const { firstName, lastName, name, id } = selectedRows[0];
      const userName = getUserName({ firstName, lastName, name });
      onClickDelete(userName, id);

      return {
        shouldClearSelection: true,
      };
    },
    icon: BigidDeleteIcon,
    label: t('actions.inline.deleteUser'),
    show: ({ selectedRows }) => {
      const { name } = selectedRows[0];
      return isManagePermitted && authUser?.name !== name;
    },
  },
];
