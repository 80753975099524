import React, { FunctionComponent, Fragment, useState, useEffect } from 'react';
import { DataSource } from './ClusterDetails/ClusterDetails';
import { BigidPieChart, BigidChartChip, BigidColors, PieChartData } from '@bigid-ui/components';
import makeStyles from '@mui/styles/makeStyles';

const DATA_SOURCES_PIE_CHART_COLORS = ['#FF805A', '#7B91ED', '#9CCE6A', '#F4DA13', '#6B4279', '#EF9797', '#777777'];

interface DataSourcesChartProps {
  dataSources: DataSource[];
}

const useStyles = makeStyles({
  wrapper: {
    display: 'flex',
  },
  chipsWrapper: { display: 'flex', flexDirection: 'column', justifyContent: 'center', marginLeft: 32 },
  emptyMessage: {
    color: BigidColors.gray[500],
  },
});

function getDSChartData(sources: DataSource[]): PieChartData<string>[] {
  if (Array.isArray(sources) && sources.length > 0) {
    return sources.map((ds, index) => ({
      category: ds.dataSourceName,
      value: ds.count,
      color: DATA_SOURCES_PIE_CHART_COLORS[index],
    }));
  } else {
    return [];
  }
}

export const ClustersDataSourcesChart: FunctionComponent<DataSourcesChartProps> = ({ dataSources }) => {
  const classes = useStyles({});
  const [chartDataSources, setChartDataSources] = useState<any[]>([]);
  const [totalObjects, setTotalObjects] = useState<number>(0);

  useEffect(() => {
    setChartDataSources(getDSChartData(dataSources));
    setTotalObjects(dataSources.reduce((agg: number, ds) => agg + ds.count, 0));
  }, [dataSources]);

  return (
    <Fragment>
      {chartDataSources && (
        <div className={classes.wrapper}>
          <div>
            <BigidPieChart
              data={chartDataSources}
              total={totalObjects}
              entityName="Objects"
              onlyShowOnViewport={false}
            />
          </div>
          <div className={classes.chipsWrapper}>
            {chartDataSources.map(({ color, category, value }, key) => (
              <div key={key}>
                <BigidChartChip color={color} label={category} value={value} />
              </div>
            ))}
          </div>
        </div>
      )}
    </Fragment>
  );
};
