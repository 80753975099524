import React, { FC, useMemo } from 'react';
import {
  BigidGridColumn,
  BigidGridQueryComponents,
  BigidGridWithToolbar,
  BigidGridWithToolbarProps,
} from '@bigid-ui/grid';
import { CustomAppAction, ExecutionPayload } from '../../../../CustomApp/views/CustomAppActions/CustomAppActions';
import { useUserPreferences } from '../../../../../components/hooks/useUserPrefrences';
import { customAppService } from '../../../../../services/customAppService';
import {
  ExecutionModel,
  useActivityLogGridAttachments,
  useActivityLogGridColumns,
} from '../../../../CustomApp/views/ActivityLog/ActivityLog';
import { AppInfo } from '../../../../CustomApp/utils/CustomAppTypes';
import makeStyles from '@mui/styles/makeStyles';
import styled from '@emotion/styled';
import { BigidImportIcon } from '@bigid-ui/icons';
import { ToolbarActionType } from '@bigid-ui/components';

const AutoDiscoveryActivityLogGridWrapper = styled.div`
  flex: 1 1 auto;
  display: flex;
  flex-flow: column nowrap;
  overflow-x: hidden;
  overflow-y: auto;
  padding-right: 2px;
`;
interface AutoDiscoveryActivityLogGridProps {
  appInfo: AppInfo;
  actions: CustomAppAction[];
  presetId?: string | React.ReactText;
}

const useStyles = makeStyles({
  attachment: {
    paddingRight: '4px',
  },
});

const GRID_PAGE_SIZE = 50;

export const AutoDiscoveryActivityLogGrid: FC<AutoDiscoveryActivityLogGridProps> = ({ appInfo, actions, presetId }) => {
  const classes = useStyles({});
  const attachments = useActivityLogGridAttachments(actions, appInfo.id);
  const columns: BigidGridColumn<ExecutionModel>[] = useActivityLogGridColumns(attachments, classes, actions);

  const { isReady, gridColumns, updatePreferences } = useUserPreferences({
    stateName: `autoDiscovery.activityLog`,
    initialGridColumns: columns,
  });

  const gridConfig: BigidGridWithToolbarProps<ExecutionModel> = useMemo(() => {
    return {
      pageSize: GRID_PAGE_SIZE,
      pagingMode: false,
      showSortingControls: false,
      columns: gridColumns,
      defaultSorting: [{ field: 'created_at', order: 'desc' }],
      onGridStateChange: ({ filter, ...gridState }) => updatePreferences({ filterState: { filter }, gridState }),
      fetchData: async (queryComponents: BigidGridQueryComponents) => {
        let totalCount;
        if (!presetId) {
          return {
            data: [],
            totalCount: 0,
          };
        }
        const result = await customAppService.getCustomAppExecutions(appInfo.id, queryComponents);
        const data = result.data?.filter?.(({ preset_id }: ExecutionPayload) => preset_id === presetId);
        totalCount = data.length;

        if (queryComponents.requireTotalCount) {
          const { data: count } = await customAppService.getCustomAppExecutionsCount(appInfo.id);
          totalCount = count;
        }

        const isNotPossibleToShowOnlyPresetRelated = totalCount > GRID_PAGE_SIZE * 2;

        return isNotPossibleToShowOnlyPresetRelated
          ? {
              data: result.data,
              totalCount,
            }
          : {
              data,
              totalCount: data.length,
            };
      },
      toolbarActions: [
        {
          label: '',
          execute: async () => {
            await customAppService.downloadLogs(appInfo.id);
            return Promise.resolve({
              shouldGridReload: false,
              shouldClearSelection: false,
              shouldClearSelectedItem: false,
            });
          },
          show: () => true,
          placement: 'end',
          icon: () => <BigidImportIcon />,
          isGlobal: true,
          disable: () => false,
          type: ToolbarActionType.ACTION_ICON,
        },
      ],
    };
  }, [appInfo.id, gridColumns, presetId, updatePreferences]);

  return (
    <AutoDiscoveryActivityLogGridWrapper data-aid="AutoDiscoveryActivityLogGrid">
      {isReady && <BigidGridWithToolbar {...gridConfig} />}
    </AutoDiscoveryActivityLogGridWrapper>
  );
};
