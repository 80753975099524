import { useQuery, UseQueryOptions, QueryFunction, UseQueryResult, QueryKey } from 'react-query';
import { getSuggestedActions } from '../services/actions';
import { SUGGESTED_ACTIONS_ENDPOINT } from '../constants/actions';
import type { AsyncReturnType } from '../../DataSourceConnectionModal/types';
import type { ErrorType } from '../../../../config/request';
import type { BigidFilter } from '@bigid-ui/components';

export type GetSuggestedActionsQueryResult = NonNullable<AsyncReturnType<typeof getSuggestedActions>>;

export type GetSuggestedActionsQueryError = ErrorType<unknown>;

export const getSuggestedActionsQueryKey = (filters: BigidFilter): Array<unknown> => [
  'post',
  `${SUGGESTED_ACTIONS_ENDPOINT}`,
  ...(filters ? [...filters] : []),
];

export const useGetSuggestedActions = <
  TData = AsyncReturnType<typeof getSuggestedActions>,
  TError = GetSuggestedActionsQueryError,
>(
  filter: BigidFilter,
  options?: { query?: UseQueryOptions<AsyncReturnType<typeof getSuggestedActions>, TError, TData> },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options || {};

  const queryKey = queryOptions?.queryKey ?? getSuggestedActionsQueryKey(filter);
  const queryFn: QueryFunction<AsyncReturnType<typeof getSuggestedActions>> = async () =>
    getSuggestedActions({ query: { filter } });
  const query = useQuery<AsyncReturnType<typeof getSuggestedActions>, TError, TData>(queryKey, queryFn, {
    ...queryOptions,
  });

  return {
    queryKey,
    ...query,
  };
};
