import { DataSourceBulkUpdateForm } from './DataSourceBulkUpdateForm';
import { DataSourceBulkUpdateContext, DataSourceBulkUpdateState } from './DataSourceBulkUpdateContext';
import React, { useCallback, useEffect, useState } from 'react';
import { httpService } from '../../../../services/httpService';
import { generateFields } from '../generateFields';
import { notificationService } from '../../../../services/notificationService';
import { BigidLoader } from '@bigid-ui/components';
import { startCase } from 'lodash';
import { getFieldsToDisplay, prepareFieldsForGeneration } from './dsBulkUpdateUtils';
import { getDefaultFields } from './defaultBullkUpdateFields';
import { fetchDsTemplate } from '../../DataSourcesService';

interface DataSourceBulkUpdateProps {
  selectedType: string;
  isSingleDataSourceType: boolean;
  selectedRowIds: string[];
  bulkDataSourceUpdateHandlersRef: React.MutableRefObject<any>;
  bulkDataSourceFieldsToModifyRef: React.MutableRefObject<any>;
}

export const DataSourceBulkUpdate: React.FC<DataSourceBulkUpdateProps> = ({
  selectedType,
  isSingleDataSourceType,
  selectedRowIds,
  bulkDataSourceUpdateHandlersRef,
  bulkDataSourceFieldsToModifyRef,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [dataSourceBulkUpdateState, setDataSourceBulkUpdateState] = useState<DataSourceBulkUpdateState>({
    fields: [],
    defaultFields: getDefaultFields(),
  });

  const updateTemplateDataInState = useCallback(async () => {
    try {
      setIsLoading(true);
      const {
        data: { fields: fieldsConfig },
      } = await fetchDsTemplate(selectedType);

      const fieldsToGenerate = prepareFieldsForGeneration(fieldsConfig);

      const generatedFields = await generateFields(fieldsToGenerate, true);

      const fieldsToDisplay = getFieldsToDisplay(generatedFields);

      setDataSourceBulkUpdateState(prevState => {
        const formFields = fieldsToDisplay.map(({ name, label, misc, ...rest }) => {
          const isFieldsBySelection = !misc.visibleIfObject && !misc.visibleIf;
          return {
            name,
            label: isFieldsBySelection ? label || startCase(name) : label,
            isFieldsBySelection,
            misc,
            ...rest,
          };
        });
        bulkDataSourceFieldsToModifyRef.current = [...dataSourceBulkUpdateState.defaultFields, ...formFields];

        return {
          ...prevState,
          fields: formFields,
          isShowMultipleTypesSelectedMessage: false,
        };
      });
    } catch (error) {
      notificationService.error('Failed to fetch fields for this data source, see logs for more information.');
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  }, [bulkDataSourceFieldsToModifyRef, dataSourceBulkUpdateState.defaultFields, selectedType]);

  useEffect(() => {
    if (selectedType && isSingleDataSourceType) {
      updateTemplateDataInState();
    } else {
      bulkDataSourceFieldsToModifyRef.current = dataSourceBulkUpdateState.defaultFields;
      setDataSourceBulkUpdateState(prevState => ({
        ...prevState,
        isShowMultipleTypesSelectedMessage: true,
      }));
    }
  }, [
    bulkDataSourceFieldsToModifyRef,
    dataSourceBulkUpdateState.defaultFields,
    isSingleDataSourceType,
    selectedType,
    updateTemplateDataInState,
  ]);

  return (
    <DataSourceBulkUpdateContext.Provider
      value={{
        dataSourceBulkUpdateState,
        setDataSourceBulkUpdateState,
        bulkDataSourceUpdateHandlersRef,
        isSingleDataSourceType,
        selectedRowIds,
      }}
    >
      {isLoading && <BigidLoader />}
      {!isLoading && <DataSourceBulkUpdateForm />}
    </DataSourceBulkUpdateContext.Provider>
  );
};
