import React, { SyntheticEvent, useState, useEffect } from 'react';
import { Guide } from '../mappers/styles';
import {
  BigidAccordion,
  BigidAccordionSummary,
  AccordionSummarySizeEnum,
  BigidAccordionDetails,
  BigidBody1,
  BigidDropdownOption,
} from '@bigid-ui/components';
import { BigidMessageIcon } from '@bigid-ui/icons';
import styled from '@emotion/styled';
import { useGetDsCollaborators } from '../hooks/useGetDsCollaborators';
import { notificationService } from '../../../../services/notificationService';
import { isDsCollaborationEnabled } from '../../../../utilities/featureFlagUtils';
import { useDataSourceModalContext } from '../hooks/useDataSourceModalContext';
import { CollaboratorsList } from './CollboratorsList';
import { DataSourceConnectionCollaborationFooter } from './components/DataSourceConnectionCollaborationFooter';
import { DataSourceCollaborationForm } from './DataSourceCollaborationForm';
import { useLocalTranslation } from '../translations/index';

export const CollaborationSectionTitle = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  cursor: pointer;
`;

export const CollaborationSectionMainContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-bottom: 30;
`;

export const CollaborationFormContainer = styled.div`
  display: flex;
  padding: 8px 0 12px;
  width: 100%;
  & form {
    width: 100%;
  }
`;

export const CollaborationListConainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 16px;
  padding-top: 12px;
  border-top: ${({ theme }) => `1px solid ${theme.vars.palette.bigid.gray150}`};
`;

const style = {
  padding: '0px 32px 16x !important',
  overflow: 'hidden',
  wordBreak: 'break-word',
};

export interface DataSourceCollaborationSectionProps {
  dataAid?: string;
  className?: string;
  id: string;
  isOpen: boolean;
  onSaveDataSource: (dataSourceName: string) => Promise<boolean>;
  dataSourceName: string;
}

export const DataSourceCollaborationSection = ({
  dataAid = 'DataSourceCollaborationSection',
  className = 'DataSourceConnectionGuideTab',
  id,
  isOpen = false,
  onSaveDataSource,
  dataSourceName,
}: DataSourceCollaborationSectionProps) => {
  const [isExpanded, setTabExpanded] = useState(isOpen);
  const [selectedEmailTemplate, setSelectedEmailTemplate] = useState<BigidDropdownOption[]>([]);
  const [selectedBrand, setSelectedBrand] = useState<BigidDropdownOption[]>([]);
  const { t } = useLocalTranslation();
  const handleTabChange = (_: SyntheticEvent, expanded: boolean) => setTabExpanded(expanded);
  const { sourceId, dsTypeLabel } = useDataSourceModalContext();

  const {
    data: collaborators,
    isFetching: isLoadingCollaborators,
    isLoading: isLoadingInitialCollaborators,
    refetch: refetchCollaborators,
  } = useGetDsCollaborators(sourceId, null, {
    query: {
      select: response => response?.data?.collaborators ?? [],
      onError: () => notificationService.error(t('collaboration.fetchError')),
      enabled: !!sourceId && isDsCollaborationEnabled(),
    },
  });

  useEffect(() => {
    setTabExpanded(isOpen);
  }, [isOpen]);

  const onInvitationSuccess = () => {
    refetchCollaborators();
  };

  const onTemplateSelection = (values: BigidDropdownOption[]) => {
    setSelectedEmailTemplate(values);
  };

  const onBrandSelection = (values: BigidDropdownOption[]) => {
    setSelectedBrand(values);
  };

  return (
    <BigidAccordion
      expanded={isExpanded}
      className={className}
      dataAid={dataAid}
      onChange={handleTabChange}
      sx={{ borderRadius: '8px !important' }}
      key={id}
    >
      <BigidAccordionSummary
        sx={{
          width: '100%',
        }}
        size={AccordionSummarySizeEnum.large}
      >
        <Guide.Tab>
          <CollaborationSectionTitle>
            <BigidMessageIcon />
            <BigidBody1 fontWeight={700} paddingLeft={'4px'}>
              {t('collaboration.tabMessageAssistance')}
            </BigidBody1>
            <BigidBody1 paddingLeft={'4px'}>{t('collaboration.tabMessageInvite')}</BigidBody1>
          </CollaborationSectionTitle>
        </Guide.Tab>
      </BigidAccordionSummary>
      <BigidAccordionDetails sx={style}>
        <CollaborationFormContainer>
          <DataSourceCollaborationForm
            updateTemplateSelection={onTemplateSelection}
            selectedEmailTemplate={selectedEmailTemplate}
            updateBrandSelection={onBrandSelection}
            selectedBrand={selectedBrand}
            onInvitationSuccess={onInvitationSuccess}
            collaborators={collaborators}
            onSaveDataSource={onSaveDataSource}
            dataSourceName={dataSourceName}
          />
        </CollaborationFormContainer>
        {!isLoadingInitialCollaborators && !!collaborators?.length && (
          <CollaborationListConainer>
            <CollaboratorsList
              isLoadingCollaborators={isLoadingCollaborators}
              collaborators={collaborators}
              sourceId={sourceId}
              refetchCollaborators={refetchCollaborators}
              dsTypeLabel={dsTypeLabel}
              selectedTemplateName={selectedEmailTemplate[0]?.displayValue}
              selectedBrand={selectedBrand[0]?.value}
            />
          </CollaborationListConainer>
        )}
      </BigidAccordionDetails>
      <DataSourceConnectionCollaborationFooter isAbsolutePositioned={false} />
    </BigidAccordion>
  );
};
