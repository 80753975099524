import React, { FC, ReactText, useCallback, useEffect, useState, ComponentType, useMemo, memo } from 'react';
import { BigidSkeletonGenerator } from '@bigid-ui/components';
import { generateDataAid } from '@bigid-ui/utils';
import { IconComponentProps } from '@bigid-ui/icons';
import { UseCatalogDiscoveryResponse } from '../../useCatalogDiscovery';
import { getAggregationIcon } from '../../utils/widgets';
import { SummaryWidgetWrapper } from '../../utils/SummaryWidgetWrapper';
import { getAggregatedData, GetAggregatedDataPayload, GetAggregatedDataResponse } from '../../catalogDiscoveryService';
import { useLocalTranslation } from './translations';
import { AggregationType } from '../../catalogDiscoveryTypes';
import { SummaryWidget, SummaryWidgetAction } from '../../widgets/SummaryWidget/SummaryWidget';
import { useFetchDataCancelable } from '../../config/useFetchDataCancelable';
import { $state } from '../../../../services/angularServices';
import { CONFIG } from '../../../../../config/common';
import { summaryWidgetSkeletonConfig } from '../../config/skeleton';
import { CatalogDiscoveryWidget } from '../../config/widgets';

export interface ViolatedPoliciesSummaryProps
  extends Pick<UseCatalogDiscoveryResponse, 'query' | 'onDataFetchStatusChange'> {
  dataAid?: string;
  isPageInitialised: boolean;
}

export const ViolatedPoliciesSummary: FC<ViolatedPoliciesSummaryProps> = memo(
  ({ dataAid = 'ViolatedPoliciesSummary', query, onDataFetchStatusChange, isPageInitialised }) => {
    const { fetchViolatedPoliciesCancelable } = useFetchDataCancelable();
    const { t } = useLocalTranslation();
    const icon: ComponentType<IconComponentProps> = useMemo(
      () => getAggregationIcon(AggregationType.VIOLATED_POLICY_CARDINALITY),
      [],
    );
    const [widgetValue, setWidgetValue] = useState<ReactText>();
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const fetchAggregationData = useCallback(async () => {
      try {
        const payload: GetAggregatedDataPayload = {
          filter: query,
          aggregations: [
            {
              aggName: AggregationType.VIOLATED_POLICY_CARDINALITY,
              isTotalRequired: true,
            },
          ],
        };

        setIsLoading(true);
        onDataFetchStatusChange(CatalogDiscoveryWidget.VIOLATED_POLICIES_SUMMARY, true);

        const { aggregations } = await fetchViolatedPoliciesCancelable(
          getAggregatedData(payload) as Promise<GetAggregatedDataResponse>,
        );

        setWidgetValue(aggregations[0]?.aggTotal);
      } catch ({ isCanceled, message }) {
        if (!isCanceled) {
          console.error(`An error has occurred: ${message}`);
        }
      } finally {
        setIsLoading(false);
        onDataFetchStatusChange(CatalogDiscoveryWidget.VIOLATED_POLICIES_SUMMARY, false);
      }
    }, [fetchViolatedPoliciesCancelable, onDataFetchStatusChange, query]);

    useEffect(() => {
      if (isPageInitialised) {
        fetchAggregationData();
      }
    }, [fetchAggregationData, isPageInitialised]);

    const { primaryAction } = useMemo(() => {
      const primaryAction: SummaryWidgetAction = {
        text: t('primaryActionText'),
        type: 'primary',
        onClick: () => {
          $state.go(CONFIG.states.POLICIES);
        },
      };

      return { primaryAction };
    }, [t]);

    return (
      <SummaryWidgetWrapper data-aid={dataAid}>
        {isPageInitialised ? (
          <SummaryWidget
            dataAid={generateDataAid(dataAid, ['widget'])}
            label={t('title')}
            tooltipText={t('tooltip.text')}
            value={widgetValue}
            icon={icon}
            rightAction={primaryAction}
            isBusy={isLoading}
          />
        ) : (
          <BigidSkeletonGenerator dataAid={generateDataAid(dataAid, ['skeleton'])} {...summaryWidgetSkeletonConfig} />
        )}
      </SummaryWidgetWrapper>
    );
  },
  (prevProps, newProps) =>
    prevProps.query === newProps.query && prevProps.isPageInitialised === newProps.isPageInitialised,
);
