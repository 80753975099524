import styled from '@emotion/styled';
import React, { FC, PropsWithChildren } from 'react';

export interface HexagonStyledProps extends PropsWithChildren {
  size: string;
  dataAid?: string;
}
export const HexagonStyledWrapper = styled('div')<HexagonStyledProps>`
  position: relative;
  width: ${({ size }) => size};
  height: ${({ size }) => size};
  background-color: transparent;
  ::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #d9d9d9;
    clip-path: polygon(50% 0%, 93% 25%, 93% 75%, 50% 100%, 7% 75%, 7% 25%);
  }
`;

export const HexagonStyledInnerWrapper = styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: calc(100% - 1px);
  height: calc(100% - 1px);
  margin: 0.5px;
  background-color: white;
  clip-path: polygon(50% 0%, 93% 25%, 93% 75%, 50% 100%, 7% 75%, 7% 25%);
`;

export const HexagonStyled: FC<HexagonStyledProps> = ({ size, children, dataAid }) => {
  return (
    <HexagonStyledWrapper data-aid={dataAid || 'HexagonStyled'} size={size}>
      <HexagonStyledInnerWrapper>{children}</HexagonStyledInnerWrapper>
    </HexagonStyledWrapper>
  );
};
