import React, { FC } from 'react';
import styled from '@emotion/styled';
import { BigidBody1, BigidHeading3 } from '@bigid-ui/components';
import { generateDataAid } from '@bigid-ui/utils';

const ContainerInfo = styled('div')``;
const Title = styled(BigidHeading3)`
  font-size: 16px;
  font-weight: 700;
  margin-bottom: 8px;
`;
const BottomContent = styled('div')`
  margin-top: 16px;
`;

const List = styled('ol')`
  font-size: 13px;
  padding-left: 22px;
`;

const ListItem: FC<{ text: string }> = ({ text }) => {
  return (
    <li>
      <BigidBody1>{text}</BigidBody1>
    </li>
  );
};

const ListDescription: FC = () => {
  return (
    <List>
      <ListItem text="The scan template handles: Scan type, Classifiers, Correlation sets." />
      <ListItem text="Scans manage Data sources and scheduling." />
    </List>
  );
};

interface ConversionChoicesProps {
  dataAid?: string;
}

export const ConversionChoices: FC<ConversionChoicesProps> = ({ dataAid = 'ConversionChoices' }) => {
  return (
    <>
      <ContainerInfo>
        <Title data-aid={generateDataAid(dataAid, ['title'])}>Conversion Choices</Title>
        <BigidBody1 data-aid={generateDataAid(dataAid, ['subTitle'])}>
          Explore various options for converting your old scan profile into a template.
        </BigidBody1>
      </ContainerInfo>
      <BottomContent data-aid={generateDataAid(dataAid, ['bottomSection'])}>
        <BigidBody1>Scan templates split old scan profile configurations into two parts:</BigidBody1>
        <ListDescription />
      </BottomContent>
    </>
  );
};
