import React, { FC, useEffect } from 'react';
import { BigidErrorPage } from '@bigid-ui/components';
import { convertToAngular } from '../../../common/services/convertToAngular';
import angular from 'angular';
import { pageHeaderService } from '../../../common/services/pageHeaderService';

export const ConnectionLost: FC = () => {
  useEffect(() => {
    pageHeaderService.setIsHidden(true);
    return () => pageHeaderService.setIsHidden(false);
  }, []);

  return <BigidErrorPage type="connectionLost" onLinkClick={() => window.location.reload()} />;
};

angular.module('app').component('connectionLost', convertToAngular(ConnectionLost));
