import React, { useState, useRef } from 'react';
import { BigidMenu, BigidMenuItemProps } from '@bigid-ui/components';

interface ButtonWithMenuProps {
  render: (onClick: () => void) => React.ReactNode;
  menuItems: BigidMenuItemProps[];
}

export const ButtonWithMenu = ({ render, menuItems }: ButtonWithMenuProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const ref = useRef<HTMLDivElement>(null);

  const menuProps = {
    open: isOpen,
    onMenuClose: () => setIsOpen(false),
    anchorEl: ref.current as Element,
  };

  const onClickHandler = () => setIsOpen(state => !state);

  return (
    <div ref={ref}>
      {render(onClickHandler)}
      <BigidMenu {...menuProps} items={menuItems} paperStyle={{ marginTop: '4px' }} />
    </div>
  );
};
