import { DataSourceTemplateStatus } from '../../../utilities/dataSourcesUtils';

export enum DataSourceOnboardingTypes {
  'AutomaticOnboarding' = 'AutomaticOnboarding',
  'DeepDiscovery' = 'DeepDiscovery',
  'AutoScanning' = 'AutoScanning',
  'Manual' = 'Manual',
}
export enum AggregatedCategories {
  Popular = 'Popular',
  AllCategories = 'AllCategories',
}

export type DataSourceOnboardingCategoriesKeys = DataSourceOnboardingTypes | AggregatedCategories;

export interface CategoryState {
  active: boolean;
  isLoading: boolean;
  isEmpty: boolean;
}

export interface DataSourceOnboardingItem extends Record<string, any> {
  nameInDocs?: string;
  displayName?: string;
  keywords?: string[];
  type: string;
  status?: DataSourceTemplateStatus;
  isPermitted?: boolean;
  templateName?: string;
}

export type CategoriesState = {
  [key in DataSourceOnboardingCategoriesKeys]?: CategoryState;
};
export interface CreateDataSourceOnboardingState {
  searchString?: string;
  selectedCategory?: DataSourceOnboardingCategoriesKeys;
  categoriesState?: CategoriesState;
  isInit: boolean;
}

export interface SelectOnboardingTypeCardProps extends DataSourceOnboardingItem {
  onSelectType: () => void;
  isLazyRender?: boolean;
}

export type OnSelectOnboardingType = (itemData: DataSourceOnboardingItem) => void;

export type DataSourceCategoryFetch = (search?: string) => Promise<DataSourceOnboardingItem[]>;

export interface CreateDataSourceCategoryConfig {
  fetch?: DataSourceCategoryFetch;
  label: string;
  onSelectType?: OnSelectOnboardingType;
  isPermitted?: () => boolean;
  isClientSearch?: boolean;
  emptyMessage?: string;
  cardConfig?: {
    render?: React.FC<SelectOnboardingTypeCardProps>;
    width?: string;
    isLazyRender?: boolean;
  };
}

export type DataSourceOnboardingTypesProvider = Pick<
  CreateDataSourceCategoryConfig,
  'fetch' | 'onSelectType' | 'isPermitted'
>;
