import { useEffect, useState } from 'react';
import { checkFmdsState } from '../../../../fmsdServices';
import { CredsStatus } from '../../../../FmsdComponents';

export const useFmsdDsWithStatus = () => {
  const [loadedDsWithStatus, setLoadedDsWithStatus] = useState<Record<string, boolean>>({
    aws: false,
    azure: false,
    salesforce: false,
  });

  useEffect(() => {
    const allDsConnectionsWithStatuses = async () => {
      const allFmsdStates = await checkFmdsState();
      const { aws, azure, salesforce } = allFmsdStates;
      setLoadedDsWithStatus({
        aws: aws?.credsStatus === CredsStatus.VALID,
        azure: azure?.credsStatus === CredsStatus.VALID,
        salesforce: salesforce?.credsStatus === CredsStatus.VALID,
      });
    };

    allDsConnectionsWithStatuses();

    return () => {
      setLoadedDsWithStatus({
        aws: false,
        azure: false,
        salesforce: false,
      });
    };
  }, []);

  return { loadedDsWithStatus };
};
