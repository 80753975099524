import React, { FC, useContext } from 'react';
import { BigidNoDataIllustration } from '@bigid-ui/icons';
import { styled } from '@mui/material';
import { useTheme } from '@mui/styles';

import { DataSourceConfigurationContext } from '../../../DataSourceConfigurationContext';
import { BigidHeading5, BigidNotes1, SecondaryButton, PrimaryButton } from '@bigid-ui/components';
import { useLocalTranslation } from '../../../../DataSourceConnectionModal/translations';
import { generateDataAid } from '@bigid-ui/utils';
import { DataSourcesUITrackingEvent } from '../../../../DataSourcesEventTrackerUtils';

interface MainContentConnectionErrorProps {
  onTestAgain: () => void;
  onViewDetails: () => void;
}

const Wrapper = styled('div')`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const ButtonsWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  width: 110px;
`;

export const MainContentConnectionError: FC<MainContentConnectionErrorProps> = ({ onTestAgain, onViewDetails }) => {
  const { lastTestDate } = useContext(DataSourceConfigurationContext);
  const { t } = useLocalTranslation();
  const theme = useTheme();

  return (
    <Wrapper>
      <BigidNoDataIllustration width={144} height={144} />
      <BigidHeading5 margin="24px 0px 8px 0px" color={theme.vars.palette.bigid.red700} textAlign={'center'}>
        {t('connectionTab.testConnectionFaild')}
      </BigidHeading5>
      <BigidNotes1 color={theme.vars.palette.bigid.gray500}>{lastTestDate}</BigidNotes1>
      <ButtonsWrapper>
        <PrimaryButton
          width="fill"
          margin="32px 0px 8px 0px"
          dataAid={generateDataAid('DataSourceConnectionTab', ['error', 'view', 'details'])}
          onClick={onViewDetails}
          text={t('buttons.viewDetails')}
          size="medium"
          bi={{ eventType: DataSourcesUITrackingEvent.DATA_SOURCES_NEW_CONNECTION_TAB_ERROR_VIEW_DETAILS_CLICK }}
        />
        <SecondaryButton
          width="fill"
          margin="0px 0px 0px 0px"
          dataAid={generateDataAid('DataSourceConnectionTab', ['error', 'test', 'again'])}
          text={t('buttons.testAgain')}
          onClick={onTestAgain}
          size="medium"
          bi={{ eventType: DataSourcesUITrackingEvent.DATA_SOURCES_NEW_CONNECTION_TAB_ERROR_TEST_AGAIN_CLICK }}
        />
      </ButtonsWrapper>
    </Wrapper>
  );
};
