import { getNewUISupportedApps } from '../../../../services/autoDiscoveryService';
import { CloudProvider, cloudProviderLabel, DS_LIST_BY_TYPE } from '../../../AutoDiscovery/constants';
import { DataSourceOnboardingTypesProvider, OnSelectOnboardingType } from '../types';
import { getApplicationPreference } from '../../../../services/appPreferencesService';

const fetchTypes = async () => {
  const items = await getNewUISupportedApps();

  return items.map(({ type }) => {
    const keywords = Object.values(DS_LIST_BY_TYPE[type as CloudProvider])
      ?.map(({ label, value }) => [label, value])
      .flat();
    return {
      type,
      keywords,
      isPermitted: true,
      displayName: cloudProviderLabel[type as CloudProvider],
    };
  });
};

export const onSelectType: OnSelectOnboardingType = ({ type, openAutoDiscoveryModal }) => {
  openAutoDiscoveryModal(type);
};

const isPermitted = () => getApplicationPreference('SHOW_AUTO_DISCOVERY_WIZARD');

export const automaticOnboardingProvider: DataSourceOnboardingTypesProvider = {
  fetch: fetchTypes,
  onSelectType,
  isPermitted,
};
