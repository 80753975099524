import { module } from 'angular';
import { httpService } from '../../../react/services/httpService';
const app = module('app');

app.factory('rolesService', [
  '$http',
  'appSettings',
  function ($http, appSettings) {
    return {
      getRoles: () => $http.get(`${appSettings.serverPath}/${appSettings.version}/roles`).then(response => response),
      getRBACRolesUsersScopesAndPermissions: () =>
        $http.get(`${appSettings.serverPath}/${appSettings.version}/roles/rbac`).then(response => response),
      getRoleById: id =>
        $http
          .get(`${appSettings.serverPath}/${appSettings.version}/roles/${encodeURIComponent(id)}`)
          .then(response => response),
      createRole: data =>
        $http.post(`${appSettings.serverPath}/${appSettings.version}/roles`, data).then(response => response),
      updateRole: (id, data) =>
        $http
          .put(`${appSettings.serverPath}/${appSettings.version}/roles/${encodeURIComponent(id)}`, data)
          .then(response => response),
      deleteRole: id =>
        $http
          .delete(`${appSettings.serverPath}/${appSettings.version}/roles/${encodeURIComponent(id)}`)
          .then(response => response),
      duplicateRole: roleName => httpService.post('roles/duplicate', { roleName }),
      getRBACUsers: query =>
        $http
          .get(`${appSettings.serverPath}/${appSettings.version}/access-management/users${query ? `?${query}` : ''}`)
          .then(response => response?.data),
      getRBACRoles: query =>
        $http
          .get(`${appSettings.serverPath}/${appSettings.version}/access-management/roles${query ? `?${query}` : ''}`)
          .then(response => response?.data),
      getRBACScopes: query =>
        $http
          .get(`${appSettings.serverPath}/${appSettings.version}/access-management/scopes${query ? `?${query}` : ''}`)
          .then(response => response?.data),
    };
  },
]);
