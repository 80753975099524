import React, { FC } from 'react';
import { BigidBody1, BigidButtonIcon, BigidColorsV2 } from '@bigid-ui/components';
import { BigidCopyIcon } from '@bigid-ui/icons';
import { copyToClipboard } from '../../ScanUtils';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles({
  dialog: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    '& > :nth-child(2n)': {
      backgroundColor: BigidColorsV2.gray[100],
    },
    '&contentRoot': {
      padding: '0px 0px 0px 0px',
    },
  },
  dialogItem: {
    display: 'flex',
    justifyContent: 'space-between',
    paddingTop: '6px',
    paddingBottom: '5px',
    width: '100%',
    borderBottom: '1px',
  },
});

export const ScannerDialogContent: FC<{ scanners: string[] }> = ({ scanners }) => {
  const classes = useStyles({});
  return (
    <div className={classes.dialog}>
      {scanners.map((scanner, index) => (
        <div className={classes.dialogItem} key={index}>
          <BigidBody1>{scanner}</BigidBody1>
          <BigidButtonIcon icon={BigidCopyIcon} size="small" onClick={() => copyToClipboard(scanner)} />
        </div>
      ))}
    </div>
  );
};
