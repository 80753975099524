import { BigidGridColumn, BigidGridColumnTypes, BigidGridWithToolbarProps } from '@bigid-ui/grid';
import { DsarDeletionModel } from './DataMinimizationRequests';
import { $state } from '../../../services/angularServices';
import { CONFIG } from '../../../../config/common';
import { dateTimeService } from '@bigid-ui/i18n';

export const dataMinimizationRequestsDefaultColumns: BigidGridColumn<DsarDeletionModel>[] = [
  {
    title: 'Request Id',
    name: 'requestId',
    width: 220,
    type: BigidGridColumnTypes.LINK,
    isListColumn: true,
    sortingEnabled: true,
    formatter: {
      onClick: async ({ value }) => {
        $state.go(CONFIG.states.DATA_MINIMIZATION_OBJECT, { requestId: value.link.text });
        return {};
      },
    },
    getCellValue: ({ requestId }) => ({
      link: {
        text: requestId,
      },
    }),
  },
  {
    title: 'Display Name',
    name: 'displayName',
    width: 200,
    type: BigidGridColumnTypes.TEXT,
    filteringEnabled: true,
    sortingEnabled: true,
    getCellValue: ({ displayName }) => displayName,
  },
  {
    title: 'Unique Id',
    name: 'uniqueId',
    width: 150,
    type: BigidGridColumnTypes.TEXT,
    filteringEnabled: true,
    sortingEnabled: true,
    getCellValue: ({ uniqueId }) => uniqueId,
  },
  {
    title: 'Profile',
    name: 'profileName',
    width: 150,
    type: BigidGridColumnTypes.TEXT,
    sortingEnabled: true,
    getCellValue: ({ profileName }) => profileName,
  },
  {
    title: 'Submit Date',
    name: 'created_at',
    width: 150,
    type: BigidGridColumnTypes.TEXT,
    sortingEnabled: true,
    getCellValue: ({ created_at }) => dateTimeService.formatDate(created_at),
  },
  {
    title: 'Due In (Days)',
    name: 'dueInDays',
    width: 150,
    type: BigidGridColumnTypes.TEXT,
    sortingEnabled: false,
    getCellValue: ({ dueInDays }) => dueInDays,
  },
  {
    title: 'Progress (# of objects)',
    name: 'states_str',
    width: 300,
    type: BigidGridColumnTypes.TEXT,
    sortingEnabled: false,
    getCellValue: ({ states_str }) => states_str,
  },
];

export async function getDataMinimizationRequestsFilterConfig(): Promise<
  BigidGridWithToolbarProps<DsarDeletionModel>['filterToolbarConfig']
> {
  return {
    filters: null,
    searchConfig: {
      searchFilterKeys: ['requestId'],
      initialValue: '',
      placeholder: 'Request Id',
      operator: 'textSearch',
    },
  };
}
