import { useSubmitDataSource as useSubmitDataSourceV2 } from './useSubmitDataSource';

type UseUpdateDataSourceProps = typeof useSubmitDataSourceV2 extends (...props: infer TProps) => unknown
  ? TProps
  : never;

export const useUpdateDataSource = (id: string, ...props: UseUpdateDataSourceProps) => {
  const [configState, control, config] = props;
  const {
    onSubmitHandler: onUpdateHandler,
    isSubmitted: isUpdated,
    ...rest
  } = useSubmitDataSourceV2(configState, control, { ...config, canUpsert: true, upsertId: id });

  const handleUpdate = async (saveAsDraft = true) => {
    if (!id) {
      // short circuit to prevent updating a data source with an undefined [id]
      return false;
    }

    const isSuccess = await onUpdateHandler(saveAsDraft);

    return isSuccess;
  };
  return {
    ...rest,
    onUpdateHandler: handleUpdate,
    isUpdated,
    data: isUpdated ? true : null,
  };
};
