import React, { FC, useContext, useEffect, useState } from 'react';
import { BarChartDataItemType, BigidBarChart, BigidChartLegendPosition } from '@bigid-ui/components';
import { Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { MAX_NUMBER_OF_DATA_SOURCES, ObjectsByDataSource, TOTAL_OBJECTS_WITH_PII_AND_OPEN_ACCESS } from './consts';
import { AccessIntelligenceDashboardContext } from './AccessIntelligenceDashboardContext';

const useStyles = makeStyles(() => ({
  wrapper: {
    backgroundColor: '#fff',
    borderRadius: 12,
    padding: '15px 20px',
    display: 'flex',
    flexDirection: 'column',
    height: '150%',
    alignItems: 'center',
  },
  chartContainer: {
    marginTop: 50,
    height: '100%',
    width: '100%',
  },
}));

const defineTopSourcesChartData = (objectsByDataSource: ObjectsByDataSource[]): BarChartDataItemType[] => {
  const topDataSources = objectsByDataSource
    .filter(dataSourceInfo => dataSourceInfo[TOTAL_OBJECTS_WITH_PII_AND_OPEN_ACCESS] > 0)
    .slice(0, MAX_NUMBER_OF_DATA_SOURCES);
  return topDataSources.map(dataSourceInfo => ({
    category: dataSourceInfo.source,
    'Number of Objects': dataSourceInfo[TOTAL_OBJECTS_WITH_PII_AND_OPEN_ACCESS],
  }));
};

export const TopDataSourcesWithSensitiveData: FC = () => {
  const classes = useStyles({});
  const [chartData, setChartData] = useState<BarChartDataItemType[]>([]);
  const { dashboardData } = useContext(AccessIntelligenceDashboardContext);
  const { objectsByDataSource } = dashboardData;

  useEffect(() => {
    if (objectsByDataSource) {
      const chartData = defineTopSourcesChartData(objectsByDataSource);
      setChartData(chartData);
    } else {
      setChartData([]);
    }
  }, [objectsByDataSource]);

  return (
    <div className={classes.wrapper}>
      <Typography variant={'h4'} data-aid="ImportDataSourcesTitle">
        Top 5 Data Sources Containing Sensitive Data with Open Access
      </Typography>
      <div className={classes.chartContainer}>
        <BigidBarChart
          data={chartData}
          disableColumnsShadow
          showBarTotal
          withLegend
          legendPosition={BigidChartLegendPosition.TOP}
          maxColumnLabelWidth={150}
          disableTooltip
        />
      </div>
    </div>
  );
};
