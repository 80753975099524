import React, { FC, CSSProperties, ComponentType, HTMLAttributes } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { BigidDashboardBox, BigidBypassButton } from '@bigid-ui/components';

const useStyles = makeStyles({
  wrapper: {
    display: 'flex',
    flexWrap: 'wrap',
    width: '100%',
    justifyContent: 'center',
  },
});

interface DashboardBoxIconProps {
  disabled?: boolean;
  staticMode?: boolean;
  size?: string;
  className?: string;
  style?: CSSProperties;
}

export interface DashboardUpperSectionItemData {
  value: string;
  onClick: () => void;
  description: string;
  Icon: ComponentType<HTMLAttributes<SVGElement>> | React.ComponentType<any>;
  iconProps?: DashboardBoxIconProps;
  disabled: boolean;
  id: string;
  isLoading?: boolean;
}

export interface DashboardUpperSectionCommonProps {
  displayData: DashboardUpperSectionItemData[];
}

export const DashboardUpperSectionCommon: FC<DashboardUpperSectionCommonProps> = React.memo(({ displayData }) => {
  const classes = useStyles({});

  return (
    <>
      <BigidBypassButton bypassTo="dashboard-shortcuts" ariaLabel="Skip to shortcuts" className="bypass-dashboard" />
      <div className={classes.wrapper} data-aid="DashboardUpperSectionBoxWrapper">
        {displayData.map(props => (
          <BigidDashboardBox {...props} key={props.id} id={props.id} />
        ))}
      </div>
    </>
  );
});
