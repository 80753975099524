import React from 'react';
import { Guide } from '../mappers/styles';
import { Box } from '@mui/material';
import { BigidBody1, BigidLoader } from '@bigid-ui/components';
import { useLocalTranslation } from '../translations';

type DataSourceConnectionGuideLoaderProps = {
  dataAid?: string;
  translationKey?: string;
};

export const DataSourceConnectionGuideLoader = ({
  dataAid = 'DataSourceConnectionGuideLoader',
  translationKey = 'connectionGuide.setup',
}: DataSourceConnectionGuideLoaderProps): JSX.Element => {
  const { t } = useLocalTranslation();
  return (
    <Guide.Loader>
      <Box height="48px">
        <BigidLoader dataAid={dataAid} />
      </Box>
      <BigidBody1 style={{ width: '200px', textAlign: 'center' }}>{t(translationKey)}</BigidBody1>
    </Guide.Loader>
  );
};
