import makeStyles from '@mui/styles/makeStyles';
import { BigidColorsV2 } from '@bigid-ui/components';
import { MetadataSearchResultsGridProps } from './MetadataSearchResultsGrid';

type MetadataSearchResultsGridStyles = Pick<MetadataSearchResultsGridProps, 'rowHeight'>;

export const useStyles = makeStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
  },
  row: ({ rowHeight }: MetadataSearchResultsGridStyles) => ({
    position: 'relative',
    display: 'flex',
    justifyContent: 'space-between',
    height: rowHeight,
    width: '100%',
    borderRadius: '4px',
    boxSizing: 'content-box',
    '&:hover': {
      backgroundColor: BigidColorsV2.gray[100],
      cursor: 'pointer',
    },
  }),
  rowOdd: {
    backgroundColor: BigidColorsV2.gray[50],
  },
  rowEven: {
    backgroundColor: BigidColorsV2.white,
  },
  column: {
    position: 'relative',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    padding: '0px 4px',
    justifyContent: 'flex-start',
  },
  autoWidthColumn: {
    flexGrow: 1,
    flexBasis: 0,
  },
  fixedWidthColumn: {
    flexGrow: 0,
  },
});
