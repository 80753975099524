import React from 'react';
import { Step } from '@bigid-ui/guided-tour';
import { StartStep, ActStep, InvestigateStep, ManageRisksStep } from '.';
import { ActionableInsightsMouseClickToolTip } from '../tooltips';
import { SelectorModifier, getGuidedTourIdSelector } from '@bigid-ui/utils';
import ActionableInsightsTourStartStep from '../../../../../assets/icons/ActionableInsightsTourStartStep.svg';
import { getFixedT } from '../../../translations';

export const actionableInsightsTourSelectors = {
  DETAIL_CASE_CONTAINER: getGuidedTourIdSelector('ActionableInsightsDetailedCase', ['container']),
  SINGLE_DETAIL_CASE: `tbody tbody ${getGuidedTourIdSelector('BigidGridRow-', [], SelectorModifier.CONTAINS)}`,
  CASE_REPORT: getGuidedTourIdSelector('caseReport', ['card']),
  CASE_REPORT_HEADER: getGuidedTourIdSelector('MetaDataWidget', ['main-container']),
  OPEN_GRID_ROW_BUTTON: getGuidedTourIdSelector('BigidGridDetailRowIconComponent', ['collapsed']),
  CLOSE_CASE_REPORT_BUTTON: getGuidedTourIdSelector('caseReport', ['close']),
  CLOSE_GRID_ROW_BUTTON: getGuidedTourIdSelector('BigidGridDetailRowIconComponent', ['expanded']),
};

export const ACTIONABLE_INSIGHTS_TOUR_LOCAL_STORAGE_KEY = 'ActionableInsightsGuidedTourCompleted';

const t = getFixedT('Tour.steps');
const getStepTitle = (key: string): string => t(`${key}.title`);

export const actionableInsightsTourSteps = () => {
  const dataAid = 'ActionableInsightsGuidedTour';
  const steps: Step[] = [
    {
      title: getStepTitle('start'),
      carouselItems: [
        {
          type: 'node',
          content: <ActionableInsightsTourStartStep />,
          styles: { background: 'linear-gradient(257deg, #1737a7 8.06%, #7446d0 100%)' },
        },
      ],
      width: 541,
      content: <StartStep dataAid={dataAid} />,
      target: '#app',
      disableBeacon: true,
      placement: 'center',
      inverseXButtonColor: true,
    },
    {
      content: null,
      target: actionableInsightsTourSelectors.OPEN_GRID_ROW_BUTTON,
      disableBeacon: true,
      hideBackButton: true,
      hideCloseButton: true,
      spotlightClicks: true,
      tooltipComponent: ActionableInsightsMouseClickToolTip,
      placement: 'right',
      styles: {
        tooltipContainer: {
          position: 'relative',
          left: -40,
          top: 20,
        },
      },
    },
    {
      title: getStepTitle('manageRisks'),
      content: <ManageRisksStep dataAid={dataAid} />,
      target: actionableInsightsTourSelectors.DETAIL_CASE_CONTAINER,
      placement: 'top-end',
      disableBeacon: true,
    },
    {
      content: null,
      target: actionableInsightsTourSelectors.SINGLE_DETAIL_CASE,
      disableBeacon: true,
      hideBackButton: true,
      hideCloseButton: true,
      spotlightClicks: true,
      tooltipComponent: ActionableInsightsMouseClickToolTip,
      styles: {
        tooltipContainer: {
          position: 'relative',
          left: 450,
        },
      },
      placement: 'left',
    },
    {
      title: getStepTitle('investigate'),
      content: <InvestigateStep dataAid={dataAid} />,
      target: actionableInsightsTourSelectors.CASE_REPORT,
      placement: 'left-start',
      disableBeacon: true,

      spotlightClicks: false,
    },
    {
      title: getStepTitle('act'),
      content: <ActStep dataAid={dataAid} />,
      target: actionableInsightsTourSelectors.CASE_REPORT_HEADER,
      placement: 'bottom-start',
      disableBeacon: true,
      spotlightClicks: false,
    },
  ];
  return steps;
};
