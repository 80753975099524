import React, { FC, MutableRefObject, useCallback, useMemo, useState } from 'react';
import {
  BigidForm,
  BigidFormField,
  BigidFormFieldLabelPosition,
  BigidFormFieldTypes,
  BigidFormPropsOnChange,
  BigidFormStateAndHandlers,
  BigidFormValues,
} from '@bigid-ui/components';
import { FIELDS_NAMES, VALIDATION_TYPE } from './ChecksumValidationTypes';
import { isJsonFormatValid, isNumberFieldValid } from './ChecksumValidationUtils';
import { generateDataAid } from '@bigid-ui/utils';
import { isEmpty } from 'lodash';

export interface ChecksumValidationCustomFormProps {
  formControls: MutableRefObject<BigidFormStateAndHandlers>;
  initialChecksumFormValues: BigidFormValues;
}
export const ChecksumValidationCustomForm: FC<ChecksumValidationCustomFormProps> = ({
  formControls,
  initialChecksumFormValues,
}) => {
  const [isWeightsDisabled, setIsWeightsDisabled] = useState(false);
  const fields: BigidFormField[] = useMemo(() => {
    return [
      {
        fieldProps: {
          size: 'medium',
        },
        isRequired: true,
        label: 'Validation Name',
        labelPosition: BigidFormFieldLabelPosition.top,
        labelWidth: '120px',
        name: FIELDS_NAMES.VALIDATION_NAME,
        type: BigidFormFieldTypes.TEXT,
        disabled: !isEmpty(initialChecksumFormValues.validation),
        validate: (validationName: string) => {
          if (!validationName) {
            return 'Please enter validation name';
          } else if (!validationName.match(/^[A-Za-z0-9_-]+$/)) {
            return 'Please enter a unique validation name using [A-Za-z0-9_-] characters only.';
          }
          return false;
        },
      },
      {
        isRequired: true,
        label: 'Algorithm',
        labelPosition: BigidFormFieldLabelPosition.top,
        labelWidth: '120px',
        misc: {
          fullWidth: false,
        },
        name: FIELDS_NAMES.ALGORITHM,
        type: BigidFormFieldTypes.RADIO,
        options: [
          {
            label: 'WeightedModulo',
            value: VALIDATION_TYPE.WeightedModulo,
            dataAid: `${generateDataAid('checksumFormAlgorithmRadioField', ['WeightedModulo'])}`,
          },
          {
            label: 'NonWeightedModulo',
            value: VALIDATION_TYPE.NonWeightedModulo,
            dataAid: `${generateDataAid('checksumFormAlgorithmRadioField', ['NonWeightedModulo'])}`,
          },
        ],
        validate: (algorithm: string) => {
          if (!algorithm) {
            return 'Please select an algorithm';
          }
          return false;
        },
      },
      {
        label: 'Weights For WeightedModulo Algorithm',
        tooltipText:
          'List of numbers separated by commas that represent the weights for WeightedModulo algorithm. e.g: \n ' +
          '1,2,1,2,1,2,1',
        labelPosition: BigidFormFieldLabelPosition.top,
        labelWidth: '120px',
        misc: {
          fullWidth: true,
        },
        disabled: isWeightsDisabled,
        name: FIELDS_NAMES.WEIGHTS,
        type: BigidFormFieldTypes.TEXT,
        validate: (weights: string) => {
          if (!weights && !isWeightsDisabled) {
            return 'Please enter weights';
          } else if (weights && !weights.match(/^[0-9,]+$/)) {
            return 'Please enter only numbers separated by commas';
          }
          return false;
        },
      },
      {
        fieldProps: {
          size: 'medium',
        },
        isRequired: true,
        label: 'Non Valid Characters Regex',
        tooltipText: 'Which characters are not included in the regex',
        labelPosition: BigidFormFieldLabelPosition.top,
        labelWidth: '120px',
        name: FIELDS_NAMES.NON_VALID_REGEX,
        type: BigidFormFieldTypes.TEXT,
        validate: (nonValidCharsRegEx: string) => {
          if (!nonValidCharsRegEx) {
            return 'Please enter non valid characters regex';
          }
          return false;
        },
      },
      {
        fieldProps: {
          size: 'medium',
        },
        isRequired: true,
        label: 'Valid Characters Regex',
        tooltipText: 'Which characters are included in the regex',
        labelPosition: BigidFormFieldLabelPosition.top,
        labelWidth: '120px',
        name: FIELDS_NAMES.VALID_REGEX,
        type: BigidFormFieldTypes.TEXT,
        validate: (validRegEx: string) => {
          if (!validRegEx) {
            return 'Please enter valid characters regex';
          }
          return false;
        },
      },
      {
        fieldProps: {
          multiline: true,
        },
        isRequired: true,
        label: 'Map checkDigitConversationTable',
        tooltipText:
          'Json represents a map(keys: positive numbers , values: string) between the check digit number of the id into its string value. e.g: \n' +
          '{"0":"0","1":"9","2":"8","3":"7","4":"6","5":"5","6":"4"}',
        labelPosition: BigidFormFieldLabelPosition.top,
        labelWidth: '120px',
        misc: {
          placeholder: '{"0":"0","1":"9","2":"8","3":"7","4":"6","5":"5","6":"4","7":"3","8":"2","9":"1"}',
          fullWidth: true,
        },
        name: FIELDS_NAMES.CHECK_DIGIT_TABLE,
        type: BigidFormFieldTypes.TEXTAREA,
        validate: (checkDigitConversionTable: string) => {
          if (!checkDigitConversionTable) {
            return 'Please enter checkDigitConversionTable';
          }
          try {
            JSON.parse(checkDigitConversionTable);
            if (!isJsonFormatValid(checkDigitConversionTable, FIELDS_NAMES.CHECK_DIGIT_TABLE)) {
              return 'Invalid format for checkDigitConversionTable expected Json elements to be "key":"value"';
            }
            return false;
          } catch (error) {
            return `checkDigitConversionTable field should be a valid JSON, ${error.message}`;
          }
        },
      },
      {
        fieldProps: {
          multiline: true,
        },
        isRequired: true,
        label: 'Map idNumberConversionTable',
        tooltipText:
          'Json represents a map(keys:string , values:  positive numbers) between the digits or characters of the id into their numerical value. e.g: \n' +
          '{"0":0,"1":1,"2":2,"3":3,"4":4,"5":5,"6":6,"7":7,"8":8,"9":9}',
        labelPosition: BigidFormFieldLabelPosition.top,
        labelWidth: '300px',
        misc: {
          placeholder: '{"0":0,"1":1,"2":2,"3":3,"4":4,"5":5,"6":6,"7":7,"8":8,"9":9}',
          fullWidth: true,
        },
        name: FIELDS_NAMES.ID_NUMBER_TABLE,
        type: BigidFormFieldTypes.TEXTAREA,
        validate: (idNumberConversionTable: string) => {
          if (!idNumberConversionTable) {
            return 'Please enter idNumberConversionTable';
          }
          try {
            JSON.parse(idNumberConversionTable);
            if (!isJsonFormatValid(idNumberConversionTable, FIELDS_NAMES.ID_NUMBER_TABLE)) {
              return 'Invalid format for idNumberConversionTable expected Json elements to be "key":value';
            }
            return false;
          } catch (error) {
            return `idNumberConversionTable field should be a valid JSON, ${error.message}`;
          }
        },
      },
      {
        fieldProps: {
          size: 'medium',
          min: 0,
        },
        label: 'Modulo Value',
        tooltipText: 'Numerical value of the modulo',
        labelPosition: BigidFormFieldLabelPosition.top,
        labelWidth: '120px',
        name: FIELDS_NAMES.MODULO_VALUE,
        type: BigidFormFieldTypes.NUMBER,
        validate: (moduloValue: string) => {
          if (!isNumberFieldValid(moduloValue, true)) {
            return `Modulo value should be a number greater than or equal 0`;
          }
          return false;
        },
      },
      {
        fieldProps: {
          size: 'medium',
          min: 0,
        },
        label: 'Check Digit Location',
        tooltipText: 'Check digit location in the id',
        labelPosition: BigidFormFieldLabelPosition.top,
        labelWidth: '120px',
        name: FIELDS_NAMES.CHECK_DIGIT_LOCATION,
        type: BigidFormFieldTypes.NUMBER,
        validate: (checkDigitLocation: string) => {
          if (!isNumberFieldValid(checkDigitLocation, true)) {
            return `Check digit location should be a number greater than or equal 0`;
          }
          return false;
        },
      },
      {
        fieldProps: {
          size: 'medium',
          min: 0,
        },
        isRequired: true,
        label: 'Number Of Check Digits',
        tooltipText: 'The number of check digits in the id',
        labelPosition: BigidFormFieldLabelPosition.top,
        labelWidth: '120px',
        name: FIELDS_NAMES.NUMBER_OF_CHECK_DIGITS,
        type: BigidFormFieldTypes.NUMBER,
        validate: (numberOfCheckDigits: string) => {
          if (numberOfCheckDigits === '') {
            return 'Please enter number of check digits';
          } else if (!isNumberFieldValid(numberOfCheckDigits, false)) {
            return `Number of check digits should be a number greater than or equal 0`;
          }
          return false;
        },
      },
      {
        label: 'Sum Digits',
        tooltipText: 'If true - the checksum sums the digits of the product to get a single digit number',
        labelPosition: BigidFormFieldLabelPosition.top,
        labelWidth: '120px',
        misc: {
          fullWidth: false,
        },
        name: FIELDS_NAMES.SUM_DIGITS,
        type: BigidFormFieldTypes.RADIO,
        options: [
          {
            label: 'True',
            value: true,
            dataAid: `${generateDataAid('checksumFormSumDigitsRadioField', ['true'])}`,
          },
          {
            label: 'False',
            value: false,
            dataAid: `${generateDataAid('checksumFormSumDigitsRadioField', ['false'])}`,
          },
        ],
      },
      {
        fieldProps: {
          size: 'medium',
          min: 0,
        },
        label: 'Padding To Length',
        tooltipText: 'Adds the necessary number of zeros according to the specified value',
        labelPosition: BigidFormFieldLabelPosition.top,
        labelWidth: '120px',
        name: FIELDS_NAMES.PADDING_TO_LENGTH,
        type: BigidFormFieldTypes.NUMBER,
        validate: (paddingToLength: string) => {
          if (!isNumberFieldValid(paddingToLength, true)) {
            return `Padding to length should be a number greater than or equal 0`;
          }
          return false;
        },
      },
      {
        fieldProps: {
          size: 'medium',
          min: 0,
        },
        label: 'Calculation Constant number',
        tooltipText: 'Adds a constant number to the final sum',
        labelPosition: BigidFormFieldLabelPosition.top,
        labelWidth: '120px',
        name: FIELDS_NAMES.CALCULATION_CONSTANT,
        type: BigidFormFieldTypes.NUMBER,
        validate: (calculationConstant: string) => {
          if (!isNumberFieldValid(calculationConstant, true)) {
            return `Calculation constant number should be a number greater than or equal 0`;
          }
          return false;
        },
      },
      {
        fieldProps: {
          size: 'medium',
          min: 0,
        },
        label: 'Id Number Map Dimension',
        tooltipText:
          'Maps the value of the digits or characters of the id into their numerical value according to their position in the id',
        labelPosition: BigidFormFieldLabelPosition.top,
        labelWidth: '120px',
        name: FIELDS_NAMES.ID_NUMBER_DIMENSION,
        type: BigidFormFieldTypes.NUMBER,
        validate: (idNumberMapDimension: string) => {
          if (!isNumberFieldValid(idNumberMapDimension, true)) {
            return `Id number map dimension should be a number greater than or equal 0`;
          }
          return false;
        },
      },
    ];
  }, [isWeightsDisabled]);

  const handleChange = useCallback<BigidFormPropsOnChange>((values: BigidFormValues) => {
    setIsWeightsDisabled(values?.algorithm === VALIDATION_TYPE.NonWeightedModulo);
  }, []);

  return (
    <div>
      <BigidForm
        controlButtons={false}
        fields={fields}
        stateAndHandlersRef={formControls}
        initialValues={initialChecksumFormValues}
        onChange={handleChange}
      />
    </div>
  );
};
