import './queryFilter.component.scss';
import { module } from 'angular';
import template from './queryFilter.component.html';
import { sortAlphabetically } from '../../common/services/localizationHelper';
const app = module('app');

app.component('queryFilter', {
  template,
  controllerAs: 'queryFilterModel',
  controller: function ($scope, $element) {
    'ngInject';
    const queryFilterModel = this;

    const DEMO_ATTR_KEY = 'Attribute Name';
    const DEMO_ATTR_VALUE = 'Enter a value to search by';

    const DEMO_ATTR = {
      key: DEMO_ATTR_KEY,
      value: DEMO_ATTR_VALUE,
      isDemo: true,
    };

    const getStepTwoAvailability = scannedAttributes => {
      return scannedAttributes.length > 0 ? true : false;
    };

    const addDisplayNameToScannedAttributes = scannedAttributes => {
      scannedAttributes.forEach(att => {
        const displayName = att.friendlyName ? `${att.friendlyName} (${att.field_name})` : `${att.field_name}`;
        att.displayName = displayName;
        return att;
      });
    };

    const getStepThreeAvailability = attrArr => {
      const atLeastOneNotDemo = attrArr.find(item => typeof item.isDemo == 'undefined');

      return typeof atLeastOneNotDemo != 'undefined' ? true : false;
    };

    const getStepFourAvailability = attrArr => {
      const atLeastOneNotEmpty = attrArr.find(item => item.value != '' && typeof item.isDemo == 'undefined');

      return typeof atLeastOneNotEmpty != 'undefined' ? true : false;
    };

    const getFilteredAttributes = (source, selected) => {
      const selectedAttributes = selected.map(attribute => {
        if (attribute.key !== DEMO_ATTR_KEY) {
          return attribute.key;
        }
      });

      const sortedSource = source.sort((a, b) => {
        return sortAlphabetically('' + a.displayName, b.displayName);
      });

      return selectedAttributes.length == 0
        ? sortedSource
        : sortedSource.filter(attribute => !selectedAttributes.includes(attribute.displayName));
    };

    queryFilterModel.scannedAttributes = [];
    queryFilterModel.identitySources = [];
    queryFilterModel.scannedAttributesFiltered = [];

    queryFilterModel.search = '';
    queryFilterModel.advancedSearchQuery = '';

    queryFilterModel.isAdvancedRequestToggled = false;
    queryFilterModel.isSearchInputDisabled = false;
    queryFilterModel.isAddAttributeBtnEnabled = false;

    queryFilterModel.advancedRequestForm = {};
    queryFilterModel.advancedRequestAttrs = [DEMO_ATTR];

    queryFilterModel.selectedAttribute = null;
    queryFilterModel.selectedIdentitySource = null;

    queryFilterModel.fetchingAttributes = false;
    queryFilterModel.noAttributesFound = false;
    queryFilterModel.attributesFetched = false;

    queryFilterModel.isStepTwoEnabled = false;
    queryFilterModel.isStepThreeEnabled = false;
    queryFilterModel.isStepFourEnabled = false;

    queryFilterModel.newAttributeValue = '';

    queryFilterModel.onProfileSelect = profileSelected => queryFilterModel.setSelectedProfile({ profileSelected });

    queryFilterModel.$onChanges = () => {
      queryFilterModel.isStepTwoEnabled = getStepTwoAvailability(queryFilterModel.scannedAttributes);
      addDisplayNameToScannedAttributes(queryFilterModel.scannedAttributes);

      if (queryFilterModel.selectedIdentitySource !== null) {
        if (queryFilterModel.fetchingAttributes) {
          queryFilterModel.noAttributesFound = false;
        } else {
          queryFilterModel.noAttributesFound = queryFilterModel.scannedAttributes.length == 0 ? true : false;
        }
      }

      queryFilterModel.scannedAttributesFiltered = getFilteredAttributes(
        queryFilterModel.scannedAttributes,
        queryFilterModel.advancedRequestAttrs,
      );

      if (queryFilterModel.newSarProfileSelected) {
        queryFilterModel.queryFromInventoryIdentifier();
      }
    };

    queryFilterModel.queryFromInventoryIdentifier = () => {
      if (queryFilterModel.userQuery) {
        queryFilterModel.search = queryFilterModel.userQuery;
        queryFilterModel.clickAction();
        queryFilterModel.userQuery = '';
      }
    };

    queryFilterModel.clickFilter = () => {
      const onSearchEventData = {
        search: queryFilterModel.search,
        profileId: queryFilterModel.newSarProfileSelected?._id,
      };

      if (queryFilterModel.advancedSearchQuery != '') {
        onSearchEventData.idSourceName = queryFilterModel.selectedIdentitySource;
      }

      queryFilterModel.onSearch(onSearchEventData);
    };

    queryFilterModel.clickAction = showPulse => {
      if (queryFilterModel.isSearchInputDisabled && !showPulse) return;

      if (showPulse === true) {
        queryFilterModel.cleanTextBox();
      } else {
        queryFilterModel.clickFilter();
      }
      queryFilterModel.toggleAdvancedRequest(false);
    };

    queryFilterModel.cleanTextBox = () => {
      queryFilterModel.search = '';
      queryFilterModel.advancedSearchQuery = '';
      queryFilterModel.onClear();
      if (!queryFilterModel.isAdvancedRequestToggled) {
        queryFilterModel.isSearchInputDisabled = false;
      }
      queryFilterModel.advancedRequestAttrs.length = 0;
      queryFilterModel.advancedRequestAttrs = [DEMO_ATTR];
      queryFilterModel.scannedAttributesFiltered = getFilteredAttributes(
        queryFilterModel.scannedAttributes,
        queryFilterModel.advancedRequestAttrs,
      );
      queryFilterModel.isStepThreeEnabled = false;
    };

    queryFilterModel.toggleAdvancedRequest = isToggled => {
      queryFilterModel.isAdvancedRequestToggled = isToggled;

      if (queryFilterModel.isAdvancedRequestToggled) {
        queryFilterModel.isSearchInputDisabled = true;
      } else {
        queryFilterModel.isStepFourEnabled = getStepFourAvailability(queryFilterModel.advancedRequestAttrs);
        queryFilterModel.isSearchInputDisabled = queryFilterModel.advancedSearchQuery != '' ? true : false;
      }
    };

    queryFilterModel.onAdvancedRequestSubmit = () => {
      const queryString = queryFilterModel.advancedRequestAttrs
        .filter(attr => attr.value != '')
        .map(({ key, value }) => `${key}=${value}`)
        .join(' AND ');

      queryFilterModel.search = queryString;
      queryFilterModel.advancedSearchQuery = queryString;

      queryFilterModel.onSearch({
        search: queryString,
        idSourceName: queryFilterModel.selectedIdentitySource,
        profileId: queryFilterModel.newSarProfileSelected._id,
      });

      queryFilterModel.toggleAdvancedRequest(false);
    };

    queryFilterModel.onAddAttribute = attr => {
      if (attr === null) return;
      const { key, displayName } = attr;
      if (
        queryFilterModel.advancedRequestAttrs.length == 1 &&
        typeof queryFilterModel.advancedRequestAttrs[0]['isDemo'] != 'undefined'
      ) {
        queryFilterModel.advancedRequestAttrs = [
          {
            key,
            displayName,
            value: queryFilterModel.newAttributeValue,
          },
        ];
      } else {
        queryFilterModel.advancedRequestAttrs = [
          ...queryFilterModel.advancedRequestAttrs,
          {
            key,
            displayName,
            value: queryFilterModel.newAttributeValue,
          },
        ];
      }

      queryFilterModel.isStepThreeEnabled = true;
      queryFilterModel.isAddAttributeBtnEnabled = false;
      queryFilterModel.selectedAttribute = null;
      queryFilterModel.newAttributeValue = '';
      queryFilterModel.isStepFourEnabled = getStepFourAvailability(queryFilterModel.advancedRequestAttrs);
      queryFilterModel.scannedAttributesFiltered = getFilteredAttributes(
        queryFilterModel.scannedAttributes,
        queryFilterModel.advancedRequestAttrs,
      );
    };

    queryFilterModel.removeAttribute = index => {
      queryFilterModel.advancedRequestAttrs = [
        ...queryFilterModel.advancedRequestAttrs.slice(0, index),
        ...queryFilterModel.advancedRequestAttrs.slice(index + 1),
      ];

      if (queryFilterModel.advancedRequestAttrs.length == 0) {
        queryFilterModel.advancedRequestAttrs = [DEMO_ATTR];
      }

      queryFilterModel.isStepThreeEnabled = getStepThreeAvailability(queryFilterModel.advancedRequestAttrs);
      queryFilterModel.isStepFourEnabled = getStepFourAvailability(queryFilterModel.advancedRequestAttrs);

      queryFilterModel.scannedAttributesFiltered = getFilteredAttributes(
        queryFilterModel.scannedAttributes,
        queryFilterModel.advancedRequestAttrs,
      );
    };

    queryFilterModel.onAttributeValueChanged = () => {
      queryFilterModel.isStepFourEnabled = getStepFourAvailability(queryFilterModel.advancedRequestAttrs);
    };

    queryFilterModel.onSearchQueryChanged = () => {
      if (queryFilterModel.advancedSearchQuery !== '') queryFilterModel.advancedSearchQuery = '';
    };

    queryFilterModel.onIdentitySourceSelect = () => {
      queryFilterModel.noAttributesFound = false;
      queryFilterModel.selectedAttribute = null;
      queryFilterModel.isAddAttributeBtnEnabled = false;
      queryFilterModel.advancedRequestAttrs = [DEMO_ATTR];
      queryFilterModel.isStepTwoEnabled = false;
      queryFilterModel.isStepThreeEnabled = false;
      queryFilterModel.isStepFourEnabled = false;
      queryFilterModel.scannedAttributes = [];
      queryFilterModel.scannedAttributesFiltered = [];
      queryFilterModel.onIdentitySourceSelected({ idSourceName: queryFilterModel.selectedIdentitySource });
    };

    queryFilterModel.onAttributeSelect = () => {
      queryFilterModel.isAddAttributeBtnEnabled = true;
    };
    queryFilterModel.onAttributeKeyUp = (keyEvent, att) => {
      if (keyEvent.key === 'Enter') {
        queryFilterModel.onAddAttribute(att);
      }
    };
  },
  bindings: {
    fetchingAttributes: '<',
    identitySources: '<',
    scannedAttributes: '<',
    showPulse: '<',
    userQuery: '<',
    onSearch: '&',
    onAdvancedRequest: '&',
    onIdentitySourceSelected: '&',
    onClear: '&',
    profiles: '<',
    setSelectedProfile: '&',
    newSarProfileSelected: '<',
  },
});
