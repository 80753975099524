import React from 'react';
import angular from 'angular';
import styled from '@emotion/styled';
import { convertToAngular } from '../../../../common/services/convertToAngular';
import { BigidBody1, BigidButtonIcon, BigidChip, BigidTooltip, SecondaryButton } from '@bigid-ui/components';
import { BigidInfoSmallIcon, BigidAddIcon, BigidEditIcon, BigidResetIcon } from '@bigid-ui/icons';
import { MappedControlsTooltip } from './MappedControlsTooltip';
import { useTheme } from '@mui/styles';
import { frameworkIconGenerator } from '../../../../react/views/Frameworks/utils';
import { useLocalTranslation } from '../translations';

export interface MappedControlsProps {
  category: string | number;
  description: string;
  framework_name: string;
  id: string;
  name: string;
}

interface ControlsWidgetProps {
  mappedControls: NormalizedMappedControlsProps[];
}

export interface NormalizedMappedControlsProps {
  widgetFrameworkName: string;
  controls: MappedControlsProps[];
}

const Main = styled.div({
  width: '100%',
  padding: '24px 0',
});

const WidgetWrapper = styled.div(({ theme }) => ({
  display: 'flex',
  width: 'calc(100% - 16px)',
  minHeight: '100px',
  backgroundColor: theme.palette.bigid.gray100,
  border: `1px solid ${theme.palette.bigid.gray200}`,
  borderRadius: '4px',
}));

const WidgetView = styled.div({
  width: '100%',
  minHeight: '60px',
  padding: '24px',
  display: 'flex',
  columnGap: '8px',
  rowGap: '8px',
  justifyItems: 'center',
  flexDirection: 'column',
});

const WidgetAdd = styled.div({
  minHeight: '60px',
  padding: '24px',
  display: 'flex',
  flexDirection: 'column',
  rowGap: '8px',
  columnGap: '8px',
  justifyContent: 'center',
});

const WidgetAddTitle = styled.div({
  height: '32px',
  display: 'flex',
  alignItems: 'center',
});

const WidgetAddButton = styled.div({
  width: '160px',
});

const WidgetViewTitle = styled.div({
  display: 'flex',
  justifyContent: 'space-between',
});

const WidgetViewTitleWrapper = styled.div({
  display: 'flex',
  columnGap: '8px',
  alignItems: 'center',
});

const WidgetViewFrameworks = styled.div({
  display: 'flex',
  columnGap: '4px',
  rowGap: '4px',
  flexWrap: 'wrap',
  width: '100%',
});

const ChipContent = styled.div({
  display: 'flex',
  columnGap: '8px',
  alignItems: 'center',
});

const ChipContentTitle = styled(BigidBody1)({
  width: '54px',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
});

const ChipControls = styled.div(({ theme }) => ({
  width: '18px',
  height: '18px',
  backgroundColor: theme.palette.bigid.gray150,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

const IconWrapper = styled.div({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
});

export const MappedControlsWidget = ({ mappedControls = [] }: ControlsWidgetProps) => {
  const Theme = useTheme();
  const { t } = useLocalTranslation();
  return (
    <Main>
      <WidgetWrapper>
        {mappedControls.length > 0 ? (
          <WidgetView>
            <WidgetViewTitle>
              <WidgetViewTitleWrapper>
                <BigidBody1>{t('title')}</BigidBody1>
                <BigidTooltip title={t('description')} placement="top">
                  <IconWrapper>
                    <BigidInfoSmallIcon />
                  </IconWrapper>
                </BigidTooltip>
              </WidgetViewTitleWrapper>
              <WidgetViewTitleWrapper>
                <BigidButtonIcon icon={BigidEditIcon} onClick={() => console.log('edit')} />
                <BigidButtonIcon icon={BigidResetIcon} onClick={() => console.log('reset')} />
              </WidgetViewTitleWrapper>
            </WidgetViewTitle>
            <WidgetViewFrameworks>
              {mappedControls.map((MappedControl: NormalizedMappedControlsProps) => {
                const Icon = frameworkIconGenerator(MappedControl.widgetFrameworkName);
                return (
                  <BigidChip
                    key={MappedControl.widgetFrameworkName}
                    label={
                      <ChipContent>
                        <ChipContentTitle>{MappedControl.widgetFrameworkName}</ChipContentTitle>
                        <ChipControls>{MappedControl.controls?.length || 0}</ChipControls>
                      </ChipContent>
                    }
                    icon={<Icon size="small" width={24} height={24} />}
                    iconPlacement="left"
                    bgColor="white"
                    outlineColor={Theme.palette.bigid.gray200}
                    outline="solid"
                    shadow={true}
                    tooltipProps={{
                      title: (
                        <MappedControlsTooltip
                          widgetFrameworkName={MappedControl.widgetFrameworkName}
                          controls={MappedControl.controls || []}
                        />
                      ),
                      placement: 'top',
                    }}
                  />
                );
              })}
            </WidgetViewFrameworks>
          </WidgetView>
        ) : (
          <WidgetAdd>
            <WidgetAddTitle>
              <BigidBody1>{t('title')}</BigidBody1>
            </WidgetAddTitle>
            <BigidBody1>{t('description')}</BigidBody1>
            <WidgetAddButton>
              <SecondaryButton
                size="medium"
                onClick={() => console.log('add')}
                startIcon={<BigidAddIcon />}
                text="Connect controls"
                width="auto"
              />
            </WidgetAddButton>
          </WidgetAdd>
        )}
      </WidgetWrapper>
    </Main>
  );
};

angular.module('app').component('mappedControlsWidget', convertToAngular(MappedControlsWidget, ['mappedControls']));
