import { module } from 'angular';
const app = module('app');

app.factory('riskTrendService', [
  '$http',
  'appSettings',
  function ($http, appSettings) {
    let that = this;
    that.getRiskSnapShotsDataRequest = {};

    return {
      getRiskSnapShots: function getRiskSnapShots(queryString, isClearCache) {
        queryString = queryString || '';
        let queryStringValue = queryString || 'none';
        if (isClearCache) {
          that.getRiskSnapShotsDataRequest[queryStringValue] = null;
        }
        if (!that.getRiskSnapShotsDataRequest[queryStringValue]) {
          that.getRiskSnapShotsDataRequest[queryStringValue] = $http
            .get(appSettings.serverPath + '/' + appSettings.version + '/risk_snapshots' + queryString)
            .then(function (response) {
              return response.data;
            });
        }
        return that.getRiskSnapShotsDataRequest[queryStringValue];
      },

      clearGetRiskSnapShotsDataRequest: function clearGetRiskSnapShotsDataRequest() {
        that.getRiskSnapShotsDataRequest = {};
      },
    };
  },
]);
