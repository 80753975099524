import React, { FC, useRef, useState } from 'react';
import { noop } from 'lodash';
import {
  BigidDialog,
  BigidForm,
  entityEventsEmitter,
  EntityEvents,
  BigidFormValues,
  BigidHeading5,
  SecondaryButton,
  TertiaryButton,
  PrimaryButton,
  BigidDialogButtonType,
} from '@bigid-ui/components';
import { BigidAddIcon } from '@bigid-ui/icons';
import { RolePicker } from '../../../components/SystemUsers/RolePicker';
import { CreateUserModel, RolePickerSelectedRoles, SystemRole } from '../../../types';
import { renderCreateUserForm } from './renderCreateUserForm';
import { RolesPreview } from '../RolesPreview';
import {
  CreateUserContainer,
  CreateUserRolesContainer,
  FormErrorText,
  UserInfoContainer,
  UserInfoScrollableSection,
  UserInfoTitleSection,
} from './styles';
import { EmptyRolesState } from './EmptyRolesState';
import { useLocalTranslation } from '../translations';
import { getRolesIdArray, getFormState } from '../../../utils';
import { useAccessManagementContext } from '../../../AccessManagementContext';
import { useCreateUserFormFields } from './useCreateUserFormFields';

interface CreateUserDialogProps {
  isOpen: boolean;
  onCreate: (userData: CreateUserModel) => void;
  onClose: () => void;
}

export const CreateUserDialog: FC<CreateUserDialogProps> = ({ isOpen, onCreate, onClose }) => {
  const [isRolesPickerVisible, setRolesPickerVisible] = useState(false);
  const [isFormDirty, setIsFormDirty] = useState(false);
  const [roles, setRoles] = useState<SystemRole[]>([]);
  const createUserFormRef = useRef<any>();
  const { serviceConfigurations } = useAccessManagementContext();
  const { t } = useLocalTranslation();
  const shouldRenderPasswordFields = !serviceConfigurations.useSaas;
  const formFields = useCreateUserFormFields({
    shouldRenderPasswordFields,
    enforceStrongPasswordSecurity: serviceConfigurations.enforceStrongPasswordSecurity,
  });

  const hasRoles = !!roles.length;

  const handleFormValidateAndSubmit = (newUserData: BigidFormValues) => {
    delete newUserData.passwordRepeat;
    delete newUserData.origin;
    if (!newUserData.password) {
      delete newUserData.password;
    }

    const newUserModel = {
      ...newUserData,
      roleIds: getRolesIdArray(roles),
    };
    onCreate(newUserModel as CreateUserModel);
  };

  const handleFormErrorBeforeSubmit = () => {
    setIsFormDirty(true);
  };

  const handleFormChange = () => {
    const isNotRenderedOrNotSubmitted = !createUserFormRef.current || !createUserFormRef.current.wasSubmitted;

    if (isNotRenderedOrNotSubmitted) {
      return;
    }

    const { isOkToCleanUp, isOkToMarkAsDirty } = getFormState(createUserFormRef.current.errors, hasRoles, isFormDirty);

    if (isOkToCleanUp) {
      setIsFormDirty(false);
    }

    if (isOkToMarkAsDirty) {
      setIsFormDirty(true);
    }
  };

  const handleFormSave = () => {
    createUserFormRef.current?.validateAndSubmit(handleFormValidateAndSubmit, handleFormErrorBeforeSubmit);
  };

  const handleDeleteRole = (id: string) => {
    entityEventsEmitter.emit(EntityEvents.DELETE, [{ id }]);
    setRoles(roles => {
      const newRoles = roles.filter(role => role._id !== id);
      return newRoles;
    });
  };

  const handleModalClose = () => {
    setRoles([]);
    setIsFormDirty(false);
    onClose();
  };

  const handleOpenRolesPickerModal = () => {
    setRolesPickerVisible(true);
  };

  const handleCloseRolesPickerModal = () => {
    setRolesPickerVisible(false);
  };

  const handleAddRoles = (roles: RolePickerSelectedRoles) => {
    setRoles(oldRolesState => {
      const nextRoles = roles as SystemRole[];
      const newRoles = [...oldRolesState, ...nextRoles];
      return newRoles;
    });
  };

  const handleModalExit = () => {
    setRoles([]);
    setIsFormDirty(false);
  };

  const initialFormValues: BigidFormValues = {
    name: '',
    firstName: '',
    lastName: '',
    origin: 'local',
    ...(shouldRenderPasswordFields && {
      password: '',
      passwordRepeat: '',
    }),
  };

  return (
    <BigidDialog
      isOpen={isOpen}
      onExit={handleModalExit}
      onClose={handleModalClose}
      title={t('dialogs.createUser.dialogTitle')}
      maxWidth="xl"
      fixedHeight
      hideContentPadding
      borderBottom
      buttons={[
        {
          component: TertiaryButton,
          text: t('dialogs.createUser.actions.cancel'),
          onClick: handleModalClose,
        },
        {
          component: PrimaryButton,
          text: t('dialogs.createUser.actions.save'),
          onClick: handleFormSave,
        },
        ...(isFormDirty
          ? [
              {
                component: ({ children }) => <FormErrorText>{children}</FormErrorText>,
                text: t('dialogs.createUser.errors.formNotValid'),
                onClick: noop,
                alignment: 'left',
              } as BigidDialogButtonType,
            ]
          : []),
      ]}
    >
      <CreateUserContainer>
        <UserInfoContainer>
          <UserInfoTitleSection>
            <BigidHeading5>{t('dialogs.createUser.detailsLabel')}</BigidHeading5>
          </UserInfoTitleSection>
          <UserInfoScrollableSection>
            <BigidForm
              stateAndHandlersRef={createUserFormRef}
              controlButtons={false}
              fields={formFields}
              renderForm={renderCreateUserForm(shouldRenderPasswordFields)}
              initialValues={initialFormValues}
              validate={() => !hasRoles}
              onChange={handleFormChange}
            />
          </UserInfoScrollableSection>
        </UserInfoContainer>
        <CreateUserRolesContainer>
          <UserInfoTitleSection>
            <BigidHeading5>{t('dialogs.createUser.rolesLabel')}</BigidHeading5>
            {hasRoles && (
              <SecondaryButton
                onClick={handleOpenRolesPickerModal}
                size="medium"
                text={t('dialogs.createUser.actions.addRole')}
                startIcon={<BigidAddIcon />}
              />
            )}
          </UserInfoTitleSection>
          <UserInfoScrollableSection>
            <RolesPreview roles={roles} isEditable onDeleteRole={handleDeleteRole}>
              <EmptyRolesState
                actionMethod={handleOpenRolesPickerModal}
                isVisible={!hasRoles}
                isWithError={isFormDirty && !hasRoles}
              />
            </RolesPreview>
          </UserInfoScrollableSection>
          <RolePicker
            isOpen={isRolesPickerVisible}
            onClose={handleCloseRolesPickerModal}
            onConnectRoles={handleAddRoles}
            selectedRoles={getRolesIdArray(roles)}
            returnOnlyIds={false}
          />
        </CreateUserRolesContainer>
      </CreateUserContainer>
    </BigidDialog>
  );
};
