export interface SessionStorageItem {
  name: string;
  value: any;
}

export const set = (name: string, value: any): void => {
  window.sessionStorage.setItem(name, JSON.stringify(value));
};

export const setArray = (items: Array<SessionStorageItem>): void => {
  items.forEach(item => window.sessionStorage.setItem(item.name, JSON.stringify(item.value)));
};

export const get = <T>(name: string, disableShowError?: boolean): T => {
  const json = window.sessionStorage.getItem(name);

  try {
    return JSON.parse(json);
  } catch (e) {
    !disableShowError &&
      window.console.error(`Error during JSON.parse in sessionStorageService, for name: ${name}', json: ${json}`);
    return undefined;
  }
};

export const remove = (name: string): void => {
  window.sessionStorage.removeItem(name);
};

export const removeAll = (): void => {
  window.sessionStorage.clear();
};

export const sessionStorageService = {
  set,
  setArray,
  get,
  remove,
  removeAll,
};
