import React, { FC } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { BigidBody1 } from '@bigid-ui/components';

interface PreviewFileFindingsTooltipProps {
  text: string;
}

const useStyles = makeStyles({
  root: {
    overflowY: 'auto',
    textOverflow: 'ellipsis',
  },
  text: {
    maxHeight: '150px',
  },
});

export const PreviewFileFindingsTooltip: FC<PreviewFileFindingsTooltipProps> = ({ text }) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <BigidBody1 className={classes.text}>{text}</BigidBody1>
    </div>
  );
};
