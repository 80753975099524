import { CatalogRule } from './catalogRulesService';
import { CatalogRuleDetailsValidationState } from './CatalogRuleDetails/CatalogRuleDetails';
import { omit } from 'lodash';
import { v4 as uuid } from 'uuid';

export const getRuleId = () => {
  return uuid();
};

export const getRuleNameSuffix = (id?: string) => {
  return (id || uuid()).substr(0, 6);
};

export const getDuplicateRuleName = (name: string, id?: string) => {
  return `Copy of (${name}) - ${getRuleNameSuffix(id)}`;
};

export const getDuplicateRule = (rule: CatalogRule): Partial<CatalogRule> => {
  return omit(rule, [
    'id',
    'name',
    'createdAt',
    'updatedAt',
    'sseRoutingKey',
    'action',
    'lastAppliedDate',
    'lastAppliedTotalObjects',
    'lastAppliedAllDate',
    'isPredefined',
    'attributeFriendlyName',
  ]);
};

export function validateSpecialChars(input: string) {
  const regexAllowedChars = /^[a-zA-Z0-9\s_.-]*$/;
  return regexAllowedChars.test(input);
}

export const getIsQueryContainsEmptyAttributes = (query: string): boolean => {
  return query?.includes('= ""') || query?.includes('undefined');
};

const getQueryValidation = (rule: CatalogRule): string => {
  const queryContainsEmptyAttr = getIsQueryContainsEmptyAttributes(rule?.bigidQuery);
  const emptyQueryMessage = 'Each rule must have a query.';
  const emptyAttributeMessage =
    'Rule cannot contain empty attribute. Please select an attribute or delete it and save again.';

  switch (true) {
    case !rule?.bigidQuery && !rule?.bigidQueryObject:
      return emptyQueryMessage;
    case queryContainsEmptyAttr:
      return emptyAttributeMessage;
    default:
      return;
  }
};

const getNameValidation = (rule: CatalogRule): string => {
  const valueExistWithspecialChars = rule?.action?.value && !validateSpecialChars(rule?.action?.value);
  const friendlyExistWithSpecialChars =
    rule?.attributeFriendlyName && !validateSpecialChars(rule.attributeFriendlyName);
  const emptyAttributeMessage = 'Please fill this field with a unique name.';
  const specialCharsMessage =
    'Invalid value. Please use alphanumeric characters, spaces, dots, underscores and dashes.';

  switch (true) {
    case !rule?.action?.value:
      return emptyAttributeMessage;
    case rule?.attributeFriendlyName === '':
      return emptyAttributeMessage;
    case valueExistWithspecialChars:
      return specialCharsMessage;
    case friendlyExistWithSpecialChars:
      return specialCharsMessage;
    default:
      return;
  }
};

export const getRuleValidationState = (rule: CatalogRule): CatalogRuleDetailsValidationState => {
  const validationState: CatalogRuleDetailsValidationState = {};

  const resultQueryValidation = getQueryValidation(rule);
  const resultNameValidation = getNameValidation(rule);

  if (resultQueryValidation) {
    validationState.query = resultQueryValidation;
  }

  if (resultNameValidation) {
    validationState.attributeName = resultNameValidation;
  }

  return Object.keys(validationState).length ? validationState : null;
};
