import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { BigidTooltip, EntityEvents, entityEventsEmitter, PrimaryButton, SecondaryButton } from '@bigid-ui/components';
import {
  BigidGridColumnTypes,
  BigidGridColumn,
  BigidGridWithToolbar,
  BigidGridWithToolbarProps,
  BigidGridRow,
  BigidGridQueryComponents,
} from '@bigid-ui/grid';
import DownloadIcon from '../../../../assets/icons/Download.svg';
import GetAppIcon from '@mui/icons-material/GetApp';
import { AppInfo } from '../../utils/CustomAppTypes';
import { customAppService } from '../../../../services/customAppService';
import { httpService } from '../../../../services/httpService';
import { tpaStatusService } from '../../../../services/tpaStatusService';
import { formatDuration, intervalToDuration } from 'date-fns';
import { CustomAppAction, ExecutionPayload, Preset } from '../CustomAppActions/CustomAppActions';
import { cloneDeep, isEmpty, truncate } from 'lodash';
import { SSE_EVENTS, SSEDataMessage, subscribeToRepeatedSSEEventById } from '../../../../services/sseService';
import { EventEmitterDeregistrator } from '@bigid-ui/utils';
import { useUserPreferences } from '../../../../components/hooks/useUserPrefrences';
import { $state } from '../../../../services/angularServices';
import { getApplicationPreference } from '../../../../services/appPreferencesService';
import { formatTimeToActivityLogs } from '../../utils/time.util';
import { notificationService } from '../../../../services/notificationService';
import { BigidStopIcon } from '@bigid-ui/icons';
import { ClassNameMap } from '@mui/styles';

export enum CustomAppStatus {
  IN_PROGRESS = 'IN_PROGRESS',
  ERROR = 'ERROR',
  COMPLETED = 'COMPLETED',
  STOPPED = 'STOPPED',
}

export const executionEnumToText: Record<CustomAppStatus, string> = {
  IN_PROGRESS: 'In Progress',
  ERROR: 'Failed',
  COMPLETED: 'Completed',
  STOPPED: 'Stopped',
};

export type ExecutionsSSE = {
  execution: ExecutionPayload;
  isCreate: boolean;
  attachmentsToUpdate: AttachmentMetadata[];
};

interface ActivityLogProps {
  appInfo: AppInfo;
  actions: CustomAppAction[];
}

export interface ExecutionModel extends BigidGridRow {
  updated_at: string;
  created_at: string;
  tpa_action_id: string;
  status_enum: CustomAppStatus;
  progress: number;
  message: string;
  user_name: string;
  preset_id: string;
}

export interface AttachmentMetadata {
  id: string;
  filename: string;
  fileType: string;
}

export type ExecutionsAttachmentsMap = Record<string, AttachmentMetadata[]>;

interface ExecutionAttachmentNode {
  executionId: string;
  attachmentsMetadata: AttachmentMetadata[];
}

const useStyles = makeStyles({
  logsButtonWrapper: {
    marginBottom: 25,
  },
  icon: {
    marginRight: 6,
    height: 20,
    width: 20,
  },
  activityLogsWrapper: {
    height: '100%',
    padding: '16px',
    display: 'flex',
    flex: '1 1 auto',
    flexFlow: 'column nowrap',
  },
  attachment: {
    paddingRight: '4px',
  },
});

export const downloadFileByAttachmentId = async (attachmentId: string) => {
  await httpService.downloadFile(`attachments/${attachmentId}`);
};

const getValidAttachments = (attachments: AttachmentMetadata[], isFailedAttachments: boolean): AttachmentMetadata[] =>
  isFailedAttachments ? attachments.filter(attachment => attachment?.id) : attachments;

const MAX_ATTACHMENT_NAME_SIZE = 30;

export const getDownloadAttachmentButtons =
  (attachments: ExecutionsAttachmentsMap, classes: any) => (row: ExecutionModel) => {
    if (!attachments[row._id]) return;
    const isFailedAttachments = attachments[row._id].some(attachment => !attachment);
    const attachmentsButtons = getValidAttachments(attachments[row._id], isFailedAttachments).map(
      ({ id, filename }) => (
        <BigidTooltip key={id} title={filename} isDisabled={filename.length <= MAX_ATTACHMENT_NAME_SIZE}>
          <span className={classes.attachment}>
            <SecondaryButton
              key={id}
              onClick={() => downloadFileByAttachmentId(id)}
              size="small"
              startIcon={<DownloadIcon />}
              text={truncate(filename, { length: MAX_ATTACHMENT_NAME_SIZE })}
            />
          </span>
        </BigidTooltip>
      ),
    );
    if (isFailedAttachments) attachmentsButtons.push(<span>Some attachments could not be found</span>);
    return attachmentsButtons;
  };

const shouldShowDownloadLogsButton = (): boolean =>
  !(getApplicationPreference('USE_SAAS') || getApplicationPreference('MULTI_TENANT_MODE_ENABLED'));

const shouldShowStopExecutionButton = (): boolean => getApplicationPreference('TPA_ACTION_EXECUTION_FORCE_STOP');

export const getDurationTime = (createdAtTime: string, updatedAtTime: string) => {
  const duration = intervalToDuration({ start: 0, end: Date.parse(updatedAtTime) - Date.parse(createdAtTime) });
  if (duration.months > 0) {
    duration.days += 30 * duration.months;
    duration.months = 0;
  }
  if (duration.years > 0) {
    duration.days += 365 * duration.years;
    duration.years = 0;
  }
  if (duration.days > 0 || duration.hours > 0 || duration.minutes > 0) {
    duration.seconds = 0;
  } else if (duration.seconds < 1) {
    return 'less than a second';
  }

  return formatDuration(duration, { delimiter: ' and ' });
};

export const useActivityLogGridColumns = (
  attachments: ExecutionsAttachmentsMap,
  classes: ClassNameMap,
  actions: CustomAppAction[],
) => {
  return useMemo<BigidGridColumn<ExecutionModel>[]>(
    () => [
      {
        name: 'action',
        title: 'Action',
        getCellValue: (row: ExecutionModel) => {
          const { name } = actions.find((action: CustomAppAction) => action.id === row.tpa_action_id);
          return name;
        },
        type: BigidGridColumnTypes.TEXT,
      },
      {
        name: 'preset',
        title: 'Preset',
        width: 150,
        getCellValue: (row: ExecutionModel) => {
          if (!row.preset_id) return 'Default';
          const { presets } = actions.find((action: CustomAppAction) => action.id === row.tpa_action_id);
          const preset = presets?.find((preset: Preset) => preset._id === row.preset_id);
          return preset ? preset.name : 'Deleted';
        },
        type: BigidGridColumnTypes.TEXT,
      },
      {
        name: 'created_at',
        title: 'Start Time',
        getCellValue: (row: ExecutionModel) => formatTimeToActivityLogs(row.created_at),
        type: BigidGridColumnTypes.TEXT,
      },
      {
        name: 'updated_at',
        title: 'End Time',
        getCellValue: (row: ExecutionModel) =>
          [CustomAppStatus.COMPLETED, CustomAppStatus.ERROR].includes(row.status_enum)
            ? formatTimeToActivityLogs(row.updated_at)
            : '',
        type: BigidGridColumnTypes.TEXT,
      },
      {
        name: 'duration',
        title: 'Duration',
        width: 150,
        getCellValue: (row: ExecutionModel) =>
          [CustomAppStatus.COMPLETED, CustomAppStatus.ERROR].includes(row.status_enum)
            ? getDurationTime(row.created_at, row.updated_at)
            : '',
        type: BigidGridColumnTypes.TEXT,
      },
      {
        name: 'status',
        title: 'Status',
        width: 100,
        getCellValue: (row: ExecutionModel) => executionEnumToText[row.status_enum],
        type: BigidGridColumnTypes.TEXT,
      },
      {
        name: 'progress',
        title: 'Progress',
        width: 100,
        getCellValue: (row: ExecutionModel) => `${row.progress * 100}%`,
        type: BigidGridColumnTypes.TEXT,
      },
      {
        name: 'userName',
        title: 'User Name',
        width: 100,
        getCellValue: (row: ExecutionModel) => row.user_name,
        type: BigidGridColumnTypes.TEXT,
      },
      {
        name: 'info',
        title: 'Info',
        getCellValue: (row: ExecutionModel) => row.message,
        type: BigidGridColumnTypes.TEXT,
      },
      {
        name: 'downloads',
        title: 'Downloads',
        getCellValue: getDownloadAttachmentButtons(attachments, classes),
        type: BigidGridColumnTypes.CUSTOM,
      },
    ],
    [attachments, classes, actions],
  );
};

export const useActivityLogGridAttachments = (actions: CustomAppAction[], appId: string) => {
  const actionsIds = useMemo(() => {
    return new Set(actions.map(action => action.id));
  }, [actions]);
  const [attachments, setAttachments] = useState<ExecutionsAttachmentsMap>({});
  const updateExecutionAttachments = (executionId: string, attachmentsToUpdate: AttachmentMetadata[]) =>
    setAttachments(attachmentsState => {
      const updatedAttachments = cloneDeep(attachmentsState);
      attachmentsToUpdate.forEach(attachmentToUpdate => {
        if (isEmpty(updatedAttachments[executionId])) updatedAttachments[executionId] = [];
        else if (updatedAttachments[executionId].length === 3) updatedAttachments[executionId].shift();
        updatedAttachments[executionId].push(attachmentToUpdate);
      });
      return updatedAttachments;
    });

  const addOrUpdateExecutions = useCallback(
    ({ results }: SSEDataMessage) => {
      const executionsByType: Map<EntityEvents, ExecutionPayload[]> = new Map<EntityEvents, ExecutionPayload[]>();
      (results as ExecutionsSSE[]).forEach(({ execution, isCreate, attachmentsToUpdate }) => {
        if (!actionsIds.has(execution.tpa_action_id)) return;

        if (!isEmpty(attachmentsToUpdate)) updateExecutionAttachments(execution._id, attachmentsToUpdate);
        const eventType = isCreate ? EntityEvents.ADD : EntityEvents.UPDATE;

        if (!executionsByType.has(eventType)) executionsByType.set(eventType, []);
        const currTypeExecutions = executionsByType.get(eventType);
        currTypeExecutions.push(execution);
      });
      executionsByType.forEach((executions, eventType) => entityEventsEmitter.emit(eventType, executions.reverse()));
    },
    [actionsIds],
  );

  useEffect(() => {
    const updateExecutionAttachments = async () => {
      const { data } = await customAppService.getCustomAppExecutionsAttachments<ExecutionAttachmentNode[]>(appId);
      const attachmentMetadataMap = data.reduce((result, { executionId, attachmentsMetadata }) => {
        result[executionId] = attachmentsMetadata;
        return result;
      }, {} as ExecutionsAttachmentsMap);
      setAttachments(attachmentMetadataMap);
    };
    updateExecutionAttachments();
  }, [appId]);

  useEffect(() => {
    const deregisterSSE: EventEmitterDeregistrator = subscribeToRepeatedSSEEventById(
      SSE_EVENTS.TPA_ACTIONS_EXECUTIONS,
      addOrUpdateExecutions,
    );
    return () => deregisterSSE && deregisterSSE();
  }, [addOrUpdateExecutions]);

  return attachments;
};

export const ActivityLog: FC<ActivityLogProps> = ({ appInfo, actions }) => {
  const classes = useStyles({});
  const attachments = useActivityLogGridAttachments(actions, appInfo.id);
  const columns: BigidGridColumn<ExecutionModel>[] = useActivityLogGridColumns(attachments, classes, actions);

  const { isReady, gridColumns, updatePreferences } = useUserPreferences({
    stateName: `${$state.$current.name}.activityLog`,
    initialGridColumns: columns,
  });

  const gridConfig: BigidGridWithToolbarProps<ExecutionModel> = useMemo(() => {
    return {
      pageSize: 50,
      pagingMode: false,
      showSortingControls: false,
      columns: gridColumns,
      defaultSorting: [{ field: 'created_at', order: 'desc' }],
      toolbarActions: [
        {
          label: 'Stop execution',
          icon: BigidStopIcon,
          execute: async ({ selectedRows: [row] }) => {
            await handleClickOnStop(row);
            return { shouldGridReload: true };
          },
          isInline: true,
          hideActionInToolBar: true,
          show: ({ selectedRows: [row] }) => {
            return shouldShowStopExecutionButton() && row.status_enum === CustomAppStatus.IN_PROGRESS;
          },
        },
      ],
      onGridStateChange: ({ filter, ...gridState }) => updatePreferences({ filterState: { filter }, gridState }),
      fetchData: async (queryComponents: BigidGridQueryComponents) => {
        let totalCount;
        const { data } = await customAppService.getCustomAppExecutions(appInfo.id, queryComponents);
        if (queryComponents.requireTotalCount) {
          const { data: count } = await customAppService.getCustomAppExecutionsCount(appInfo.id);
          totalCount = count;
        }

        return {
          data,
          totalCount,
        };
      },
    };
  }, [appInfo.id, gridColumns, updatePreferences]);

  const handleClick = async () => {
    await customAppService.downloadLogs(appInfo.id);
  };

  const handleClickOnStop = async (execution: ExecutionModel) => {
    try {
      const stoppedExecution = { ...execution, status_enum: CustomAppStatus.STOPPED };
      await customAppService.editTpaExecution(stoppedExecution);
    } catch (err) {
      notificationService.error(`Could not stop execution, ${err.message}`);
    }
  };

  return (
    <div className={classes.activityLogsWrapper} data-aid="BigidGrid">
      {shouldShowDownloadLogsButton() && (
        <div className={classes.logsButtonWrapper} data-aid="download-logs">
          <BigidTooltip
            title={'Currently downloading logs of remote application unavailable'}
            isDisabled={!appInfo.settings?.isRemoteApplication}
          >
            <span>
              <PrimaryButton
                onClick={handleClick}
                size="medium"
                disabled={tpaStatusService.isTpaOffline(appInfo.status) || appInfo.settings?.isRemoteApplication}
                startIcon={<GetAppIcon />}
                text="Download Logs"
              />
            </span>
          </BigidTooltip>
        </div>
      )}
      {isReady && <BigidGridWithToolbar {...gridConfig} />}
    </div>
  );
};
