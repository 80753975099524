import React, { FC } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { BigidColors } from '@bigid-ui/components';
import classNames from 'classnames';

const useStyles = makeStyles({
  arrowContainer: {
    minWidth: '26px',
    minHeight: '100%',
    paddingRight: '6px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    transition: 'height 0.3s ease',
  },
  arrow: {
    width: '12px',
    height: '12px',
    border: `2px solid ${BigidColors.gray[700]}`,
    borderLeft: 0,
    borderTop: 0,
    display: 'inline-block',
    transform: 'rotate(-45deg)',
  },
  arrowLeft: {
    transform: 'rotate(-225deg)',
  },
});

export interface DashboardInsightsArrowProps {
  onClick: (event: React.MouseEvent) => any;
  isLeft?: boolean;
  show: boolean;
}
export const DashboardInsightsArrow: FC<DashboardInsightsArrowProps> = ({ onClick, isLeft, show }) => {
  const { arrow, arrowLeft, arrowContainer } = useStyles({});

  return (
    <div className={arrowContainer} onClick={show ? onClick : null} role="button">
      {show && <span className={classNames(arrow, isLeft && arrowLeft)} />}
    </div>
  );
};
