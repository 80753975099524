import './ignoredList.component.scss';
import template from './ignoredList.component.html';
import { module } from 'angular';
import { isPermitted } from '../../../react/services/userPermissionsService';
const app = module('app');
import { GENERAL_SETTINGS_PERMISSIONS } from '@bigid/permissions';

app.component('ignoredList', {
  template,
  controllerAs: 'ignoredListModel',
  controller: [
    '$scope',
    'notificationService',
    '$rootScope',
    '$translate',
    '$location',
    'ignoredListService',
    'DeleteConfirmation',
    'uiGridConstants',
    '$timeout',
    function (
      $scope,
      notificationService,
      $rootScope,
      $translate,
      $location,
      ignoredListService,
      DeleteConfirmation,
      uiGridConstants,
      $timeout,
    ) {
      const ignoredListModel = this;
      ignoredListModel.resultsFromServer = [];

      ignoredListModel.$onInit = () => {
        ignoredListModel.isCreatePermitted = isPermitted(GENERAL_SETTINGS_PERMISSIONS.CREATE_IGNORED_LISTS.name);
        ignoredListModel.isDeletePermitted = isPermitted(GENERAL_SETTINGS_PERMISSIONS.DELETE_IGNORED_LISTS.name);
        ignoredListModel.isEditPermitted = isPermitted(GENERAL_SETTINGS_PERMISSIONS.EDIT_IGNORED_LISTS.name);
      };

      ignoredListModel.$onChanges = function () {
        if (!this.loadData) {
          ignoredListModel.setUIGridForAddingNewRow();
        }
        ignoredListModel.loading = true;
        ignoredListModel.getIgnoredListData();
      };

      ignoredListModel.setUIGridForAddingNewRow = () => {
        $scope.ignoredListModel.grid.onRegisterApi = function (gridApi) {
          $scope.gridApi = gridApi;

          gridApi.edit.on.afterCellEdit($scope, function (rowEntity) {
            const ignoredListItem = {};
            //create new row
            if (rowEntity.status && rowEntity.status === 'new') {
              if (rowEntity.ignore_name && rowEntity.ignore_regex) {
                if (ignoredListModel.setIgnoredListItem(rowEntity, ignoredListItem)) {
                  ignoredListService.createIgnoredListItem(ignoredListItem).then(
                    () => {
                      ignoredListModel.getIgnoredListData();
                      notificationService.success('Row Ignored List Created Successfully! ');
                    },
                    () => {
                      notificationService.error('Creating Row Ignored List Failed');
                    },
                  );
                }
              }
            }
            //edit exist row
            else if (ignoredListModel.isEditPermitted) {
              if (ignoredListModel.setIgnoredListItem(rowEntity, ignoredListItem)) {
                ignoredListService.updateIgnoredListItem(ignoredListItem._id, ignoredListItem).then(
                  () => {
                    ignoredListModel.getIgnoredListData();
                    notificationService.success('Row Ignored List Updated Successfully! ');
                  },
                  () => {
                    notificationService.error('Updating Row Ignored List Failed');
                  },
                );
                $scope.$apply();
              }
            }
          });
        };
      };

      ignoredListModel.checkIsRegex = value => {
        try {
          new RegExp(value);
        } catch (e) {
          return false;
        }
        return true;
      };

      ignoredListModel.setIgnoredListItem = (rowEntity, ignoredListItem) => {
        if (ignoredListModel.checkIsRegex(rowEntity.ignore_regex)) {
          ignoredListItem.ignore_regex = rowEntity.ignore_regex;
        } else {
          if (ignoredListModel.resultsFromServer) {
            const result = ignoredListModel.resultsFromServer.find(item => item._id === rowEntity._id);
            if (result) {
              rowEntity.ignore_regex = result.ignore_regex;
            }
          }
          notificationService.error('Please Insert a valid Regex');
          return false;
        }

        ignoredListItem.ignore_name = rowEntity.ignore_name;
        ignoredListItem._id = rowEntity._id;
        return true;
      };

      ignoredListModel.getIgnoredListData = () => {
        ignoredListService.getIgnoredListItems().then(results => {
          ignoredListModel.resultsFromServer = JSON.parse(JSON.stringify(results.data));
          $scope.ignoredListModel.grid.data = results.data;
          ignoredListModel.loading = false;
        }),
          err => {
            ignoredListModel.loading = false;
          };
      };

      $scope.delete = function (item_id) {
        const modalOptions = {
          closeButtonText: 'Cancel',
          actionButtonText: 'Delete',
          headerText: 'Delete Ignored List Row',
          bodyText: 'Are you sure you want to delete Ignored List Row ?',
        };

        DeleteConfirmation.showModal({}, modalOptions).then(function () {
          ignoredListService.deleteIgnoredListItem(item_id).then(
            () => {
              ignoredListModel.getIgnoredListData();
              notificationService.success('Row Ignored List Deleted Successfully! ');
            },
            () => {
              notificationService.error('Deleting Row Ignored List Failed');
            },
          );
        });
      };

      ignoredListModel.addNewIgnoredListRow = () => {
        $scope.ignoredListModel.grid.data.push({ ignore_name: '', status: 'new' });
        ignoredListModel.goToBottom();
      };

      ignoredListModel.goToBottom = () => {
        $timeout(function () {
          // Do this after the columns and rows processors have finished and it is all rendered.
          if ($scope.gridApi) {
            const rowIndex = $scope.gridApi.grid.renderContainers.body.visibleRowCache.indexOf(
              $scope.gridApi.grid.rows[$scope.ignoredListModel.grid.data.length - 1],
            );
            $scope.gridApi.grid.element[0].getElementsByClassName('ui-grid-viewport')[0].scrollTop =
              rowIndex * $scope.gridApi.grid.options.rowHeight;
          }
        }, 500);
      };

      $scope.ignoredListModel.grid = {
        enableSorting: true,
        enableFiltering: true,
        enablePaginationControls: false,
        columnDefs: [
          {
            name: 'ignoredList Name',
            displayName: 'Name',
            field: 'ignore_name',
            enableCellEdit: true,
            cellTemplate: '<input placeholder="Please Insert Ignored List Name" ng-model="row.entity.ignore_name">',
            filter: {
              condition: uiGridConstants.filter.CONTAINS,
              type: uiGridConstants.filter.INPUT,
              flags: { caseSensitive: false },
            },
          },
          {
            name: 'ignoredList Regex',
            displayName: 'Regex',
            field: 'ignore_regex',
            enableCellEdit: true,
            cellTemplate: '<input placeholder="Please Insert Ignored List Regex" ng-model="row.entity.ignore_regex">',
            filter: {
              condition: uiGridConstants.filter.CONTAINS,
              type: uiGridConstants.filter.INPUT,
              flags: { caseSensitive: false },
            },
          },
          {
            name: 'Actions',
            width: '20%',
            enableSorting: false,
            enableFiltering: false,
            cellTemplate: `<button class="btn primary btnActionsDelete" ng-if="grid.appScope.ignoredListModel.isDeletePermitted" ng-show="row.entity._id" ng-click="grid.appScope.delete(row.entity._id)">Delete</button>`,
          },
        ],
      };
    },
  ],
  bindings: {
    loadData: '<',
  },
});
