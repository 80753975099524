import React, { FC, ReactNode, useRef, MouseEvent, useState, ComponentType } from 'react';
import { styled } from '@mui/material';
import { BigidHelpIcon, IconComponentProps } from '@bigid-ui/icons';
import { generateDataAid } from '@bigid-ui/utils';
import { BigidButtonIcon, BigidPopper, PrimaryButton, SecondaryButton, TertiaryButton } from '@bigid-ui/components';

export enum BigidPageTitleHelperButtonType {
  PRIMARY,
  SECONDARY,
  TERTIARY,
}

export type BigidPageTitleHelperButton = {
  type: BigidPageTitleHelperButtonType;
  text?: string;
  endIcon?: ReactNode;
  startIcon?: ReactNode;
  onClick: (event?: MouseEvent) => void;
};

export interface BigidPageTitleHelperProps {
  dataAid?: string;
  togglerIcon?: ComponentType<IconComponentProps>;
  title?: string;
  body: ReactNode;
  buttons?: BigidPageTitleHelperButton[];
  contentWidth?: number;
  contentHeight?: number;
}

const Content = styled('div')`
  width: 100%;
  padding: 16px;
`;

const Body = styled('div')`
  overflow-y: auto;
  flex: 1 1 auto;
`;

const Buttons = styled('div')`
  display: flex;
  margin-top: 8px;
  justify-content: flex-end;
  align-items: center;
  gap: 16px;
`;

export const BigidPageTitleHelper: FC<BigidPageTitleHelperProps> = ({
  dataAid = 'BigidPageTitleHelper',
  togglerIcon: TogglerIcon,
  title,
  body,
  buttons,
}) => {
  const togglerRef = useRef<HTMLDivElement>();
  const [isPopperOpen, setIsPopperOpen] = useState<boolean>(false);

  const handleTogglerClick = (event: MouseEvent<HTMLDivElement>) => {
    event.stopPropagation();
    setIsPopperOpen(isPopperOpenPrev => !isPopperOpenPrev);
  };

  const handlePopperClose = (): void => {
    if (isPopperOpen) {
      setIsPopperOpen(false);
    }
  };

  const getButtonComponent = (button: BigidPageTitleHelperButton, key: number) => {
    const { type, text, endIcon, startIcon, onClick } = button;

    switch (type) {
      case BigidPageTitleHelperButtonType.PRIMARY:
        return (
          <PrimaryButton
            key={key}
            dataAid={generateDataAid(dataAid, [text])}
            text={text}
            startIcon={startIcon}
            endIcon={endIcon}
            onClick={() => {
              setIsPopperOpen(false);
              onClick();
            }}
            size="medium"
          />
        );
      case BigidPageTitleHelperButtonType.SECONDARY:
        return (
          <SecondaryButton
            key={key}
            dataAid={generateDataAid(dataAid, [text])}
            text={text}
            startIcon={startIcon}
            endIcon={endIcon}
            onClick={() => {
              setIsPopperOpen(false);
              onClick();
            }}
            size="medium"
          />
        );
      case BigidPageTitleHelperButtonType.TERTIARY:
        return (
          <TertiaryButton
            key={key}
            dataAid={generateDataAid(dataAid, [text])}
            text={text}
            startIcon={startIcon}
            endIcon={endIcon}
            onClick={() => {
              setIsPopperOpen(false);
              onClick();
            }}
            size="medium"
          />
        );
    }
  };

  return (
    <>
      <div ref={togglerRef}>
        <BigidButtonIcon
          data-aid={generateDataAid(dataAid, ['toggler'])}
          onClick={handleTogglerClick}
          icon={TogglerIcon ? TogglerIcon : BigidHelpIcon}
        />
      </div>
      <BigidPopper
        title={title}
        open={isPopperOpen}
        anchorEl={togglerRef.current}
        placement="bottom"
        onClose={handlePopperClose}
      >
        <Content data-aid={generateDataAid(dataAid, ['content'])}>
          <Body data-aid={generateDataAid(dataAid, ['body'])}>{body}</Body>
          {buttons?.length > 0 && (
            <Buttons data-aid={generateDataAid(dataAid, ['buttons'])}>
              {buttons.map((button, index) => {
                return getButtonComponent(button, index);
              })}
            </Buttons>
          )}
        </Content>
      </BigidPopper>
    </>
  );
};
