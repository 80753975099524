import { Action, ActionTypes, ClassifierGridRow, ClassifierManagementInitialState } from '../types/ClassifierTypes';

export const classifierManagementInitialState: ClassifierManagementInitialState = {
  query: null,
};

export const classifierManagementReducer = (state = classifierManagementInitialState, action: Action) => {
  switch (action.type) {
    case ActionTypes.setFilterQuery:
      return {
        ...state,
        query: action.payload,
      };
    default:
      return state;
  }
};
