import React, { ComponentType, FC, SVGProps, useState } from 'react';
import { BigidListWithActionsRow } from './BigidListWithActionsRow';
import { BigidMenuItemProps } from '@bigid-ui/components';
import { IconComponentProps } from '@bigid-ui/icons';
import styled from '@emotion/styled';

export interface BigidHorizontalBarWithActionsItem {
  name: string;
  value: number;
  valueLabel?: string;
  color?: string;
  tooltip?: {
    left?: string;
    right?: string;
    bottom?: string;
  };
}

export interface RowWithActions {
  row: BigidHorizontalBarWithActionsItem;
  actions: BigidMenuItemProps[];
}

export interface BigidListWithActionsProps {
  rowsWithActions: RowWithActions[];
  icon?: ComponentType<IconComponentProps & SVGProps<SVGSVGElement>>;
}

const GridWrapper = styled('div')`
  display: grid;
  grid-template-columns: minmax(36px, auto) 1fr auto;
  gap: 10px;
  align-items: center;
  padding: 24px;
`;

export const BigidListWithActions: FC<BigidListWithActionsProps> = ({ rowsWithActions, icon }) => {
  const [hoveredElement, setHoveredElement] = useState<number>(-1);
  return (
    <GridWrapper>
      {rowsWithActions?.length > 0 &&
        rowsWithActions.map((rowWithActions, index) => (
          <BigidListWithActionsRow
            rowWithActions={rowWithActions}
            key={index}
            hoveredElement={hoveredElement}
            setHoveredElement={setHoveredElement}
            index={index}
            icon={icon}
          />
        ))}
    </GridWrapper>
  );
};
