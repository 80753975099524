import React, { createContext, useContext, useState, useEffect, PropsWithChildren, useMemo, useCallback } from 'react';
import { getVendorLogs, getVendorProject } from './vendorsService';
import { notificationService } from '../../services/notificationService';
import { useLocationChange } from '../DSAR/SarProfileSettings/useLocationChange';
import { VendorProject, VendorProjectAuditLog, VendorSummaryTabPaths } from './VendorsTypes';

interface VendorProjectContextType {
  vendorProject: VendorProject;
  vendorLogs: VendorProjectAuditLog[];
  tab: VendorSummaryTabPaths;
  projectId: string;
  update: () => void;
}

const VendorProjectContext = createContext<VendorProjectContextType>(undefined);

const useVendorProjectContext = () => {
  const context = useContext(VendorProjectContext);

  if (context === undefined) {
    throw new Error('useVendorProjectContext was used outside of its Provider');
  }

  return context;
};

const VendorProjectContextProvider = ({ children }: PropsWithChildren) => {
  const [vendorProject, setVendorProject] = useState<VendorProject>();
  const [vendorLogs, setVendorLogs] = useState<VendorProjectAuditLog[]>();
  const { stateParams } = useLocationChange();

  const fetchData = useCallback(async () => {
    try {
      const vendorProject = await getVendorProject(stateParams.projectId);
      setVendorProject(vendorProject);
    } catch (err) {
      notificationService.error(`Failed to fetch Vendor project`);
      console.error(`Failed to fetch Vendor project: ${JSON.stringify(err?.response)}`);
    }
    try {
      const { auditLogs } = await getVendorLogs(stateParams.projectId, {
        limit: 5,
        sort: [{ field: 'created_at', order: 'desc' }],
      });
      setVendorLogs(auditLogs);
    } catch (err) {
      notificationService.error(`Failed to fetch Vendor logs`);
      console.error(`Failed to fetch Vendor logs: ${JSON.stringify(err?.response)}`);
    }
  }, [stateParams.projectId]);

  const update = useCallback(() => fetchData(), [fetchData]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const contextValue = useMemo(
    () => ({
      vendorProject,
      vendorLogs,
      tab: stateParams.tab,
      projectId: stateParams.projectId,
      update,
    }),
    [vendorProject, vendorLogs, stateParams.tab, stateParams.projectId, update],
  );

  return <VendorProjectContext.Provider value={contextValue}>{children}</VendorProjectContext.Provider>;
};

export { VendorProjectContextProvider, useVendorProjectContext };
