import { BigidFormValues, BigidFormField } from '@bigid-ui/components';
import { CLASSIFIER_TYPES, ClassifierGridRow } from '../types/ClassifierTypes';
import { CHECKSUM_VALIDATION_TYPE } from '../../../components/ClassifiersChecksumValidation/ClassifiersChecksumValidationDialog/ChecksumValidationTypes';

interface ClassifierChecksumValidation {
  algorithm?: string;
}
export const getChecksumValueType = (classifierChecksumValidation: ClassifierChecksumValidation) => {
  if (!classifierChecksumValidation) {
    return CHECKSUM_VALIDATION_TYPE.NONE;
  }

  if (classifierChecksumValidation?.algorithm) {
    return CHECKSUM_VALIDATION_TYPE.CUSTOM;
  }

  return CHECKSUM_VALIDATION_TYPE.OOTB;
};

export const getMatchedInitialState = (defaultFormFields: Partial<ClassifierGridRow>, data: BigidFormValues) => {
  const keys = Object.keys(defaultFormFields);

  return Object.fromEntries(keys.map(key => [key, data?.[key as keyof ClassifierGridRow]])) as ClassifierGridRow;
};

export const getDefaultClassifierType = (initialData: ClassifierGridRow) => {
  return initialData ? initialData?.classifierType : CLASSIFIER_TYPES.CONTENT;
};

export const getDefaultIsSupportTermFormVisible = (initialData: Partial<ClassifierGridRow> = {}) => {
  const { support_term_value, proximity_before, proximity_after } = initialData;

  return !!(support_term_value && proximity_before && proximity_after);
};

export const getDefaultIsNegativeSupportTermFormVisible = (initialData: Partial<ClassifierGridRow> = {}) => {
  const { negative_support_term_value, nst_proximity_before, nst_proximity_after } = initialData;

  return !!(negative_support_term_value && nst_proximity_before && nst_proximity_after);
};

export const isFieldExist = (fields: BigidFormField[], fieldName: string) =>
  fields?.some(({ name }) => name === fieldName);
