import React, { useEffect, useState, FC } from 'react';
import { BigidTextField, advancedToolbarEventEmitter } from '@bigid-ui/components';
import { styled } from '@mui/material';
import { useLocalTranslation } from '../translations';
import { getIsValidQueryString } from '@bigid/query-object-serialization';
import { useDataCatalogContext } from './newDataCatalogContext';
import { updateNewCatalogFilterPreferences } from './newDataCatalogService';
import { generateDataAid } from '@bigid-ui/utils';
import { WithDataAid } from './types';

const SearchbarContainer = styled('div')`
  position: absolute;
  width: 640px;
`;

export const NewDataCatalogSearchbar: FC<WithDataAid> = ({ dataAid = 'DataCatalog' }) => {
  const { dispatch, filterString } = useDataCatalogContext();
  const [internalFilterString, setInternalFilterString] = useState(filterString);
  const [isInvalidQuery, setIsInvalidQuery] = useState(!getIsValidQueryString(filterString));
  const { t } = useLocalTranslation();

  useEffect(() => {
    if (isInvalidQuery) {
      return;
    }

    setInternalFilterString(filterString);
  }, [filterString, isInvalidQuery]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setInternalFilterString(event.target.value);
  };

  const handleTextSubmit = async (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === 'Enter' && internalFilterString !== filterString) {
      let isInvalid;
      try {
        isInvalid = !getIsValidQueryString(internalFilterString);
      } catch (error) {
        isInvalid = true;
      }
      setIsInvalidQuery(isInvalid);

      if (!isInvalid) {
        dispatch({
          type: 'UPDATE_STATE',
          payload: {
            isCustomQuery: true,
            filterString: internalFilterString,
            selectedFilters: [],
          },
        });
        advancedToolbarEventEmitter.emit('AdvancedToolbar::RESET_FILTERS');
        await updateNewCatalogFilterPreferences({ filterString: internalFilterString, overrideValues: [] });
      }
    }
  };

  const handleClear = async () => {
    setInternalFilterString('');
    setIsInvalidQuery(false);
    dispatch({
      type: 'UPDATE_STATE',
      payload: {
        isCustomQuery: false,
        filterString: '',
        selectedFilters: [],
      },
    });
    advancedToolbarEventEmitter.emit('AdvancedToolbar::RESET_FILTERS');
    await updateNewCatalogFilterPreferences({ filterString: '', overrideValues: [] });
  };

  return (
    <SearchbarContainer>
      <BigidTextField
        size="large"
        onChange={handleChange}
        value={internalFilterString}
        onClear={handleClear}
        isError={isInvalidQuery}
        errorMessage={isInvalidQuery && t('SearchBar.invalidSyntax')}
        onKeyPress={handleTextSubmit}
        dataAid={generateDataAid(dataAid, ['searchBar'])}
      />
    </SearchbarContainer>
  );
};
