import React, { FC } from 'react';
import { generateDataAid } from '@bigid-ui/utils';
import { BigidLayoutEmptyState } from '@bigid-ui/layout';
import { useLocalTranslation } from '../translations';
import { BigidTablesIllustration } from '@bigid-ui/icons';

export interface NoDataContentEmptyStateProps {
  handleOpenSimilarityThresholdDialog: () => void;
  dataAid?: string;
}

export const NoDataContentEmptyState: FC<NoDataContentEmptyStateProps> = ({
  handleOpenSimilarityThresholdDialog,
  dataAid = 'similarTables',
}) => {
  const { t } = useLocalTranslation();

  const emptyStateActions = [
    {
      execute: async () => {
        return handleOpenSimilarityThresholdDialog();
      },
      label: t('emptyStates.noDataContent.actionButton'),
      show: () => true,
    },
  ];

  return (
    <BigidLayoutEmptyState
      actions={emptyStateActions}
      illustration={BigidTablesIllustration}
      description={t('emptyStates.noDataContent.description')}
      dataAid={`${generateDataAid(dataAid, ['noData', 'emptyState'])}`}
    />
  );
};
