import './breachResponse.component.scss';
import { module } from 'angular';
import template from './breachResponse.component.html';
import { APPLICATIONS_PERMISSIONS } from '@bigid/permissions';
import { isPermitted } from '../../react/services/userPermissionsService';
const app = module('app');

app.component('breachResponse', {
  template,
  controller: function (
    $http,
    appSettings,
    breachResponseService,
    $scope,
    $state,
    notificationService,
    $rootScope,
    DeleteConfirmation,
    $translate,
    $document,
    $uibModal,
  ) {
    'ngInject';
    const ctrl = this;

    ctrl.editBreachResponseModalInstance = {};

    ctrl.$onInit = () => {
      ctrl.isCreatePermitted = isPermitted(APPLICATIONS_PERMISSIONS.CREATE_BREACH_RESPONSE.name);
      ctrl.isEditPermitted = isPermitted(APPLICATIONS_PERMISSIONS.EDIT_BREACH_RESPONSE.name);
      ctrl.isDeletePermitted = isPermitted(APPLICATIONS_PERMISSIONS.DELETE_BREACH_RESPONSE.name);
      ctrl.isExplorePermitted = isPermitted(APPLICATIONS_PERMISSIONS.EXPLORE_BREACH_RESPONSE.name);

      ctrl.getAllResponses();
    };

    ctrl.getAllResponses = () => {
      breachResponseService.getAllResponses().then(
        result => {
          ctrl.dumpFiles = result.data;
          ctrl.dumpFiles &&
            ctrl.dumpFiles.forEach(dumFile => {
              dumFile.state = dumFile.state.charAt(0).toUpperCase() + dumFile.state.slice(1);
            });
        },
        () => {
          notificationService.error('An error has occurred!');
        },
      );
    };

    ctrl.deleteBreachResponse = dumpFile => {
      const modalOptions = {
        closeButtonText: 'Close',
        actionButtonText: 'Delete',
        headerText: 'Delete Existing Breach Response',
        bodyText: `Are you sure you want to delete ${dumpFile.title} ?`,
      };

      DeleteConfirmation.showModal({}, modalOptions).then(function () {
        breachResponseService.deleteBreachResponse(dumpFile._id).then(
          function () {
            ctrl.getAllResponses();
            notificationService.success(name + ' Deleted Successfully! ');
          },
          () => {
            notificationService.error('Delete User Data Failed! ');
          },
        );
      });
    };

    ctrl.actionBreachResponse = dumpFile => {
      ctrl.dumpFile = dumpFile || ctrl.dumpFile;
      dumpFile ? (ctrl.dumpFileAction = 'Update') : (ctrl.dumpFileAction = 'Connect');

      ctrl.editBreachResponseModalInstance = $uibModal
        .open({
          animation: true,
          size: 'md',
          template:
            "<breach-response-modal dump-file='$ctrl.dumpFile' action='$ctrl.action' $close='$close(dumpFile)' $dismiss='$dismiss()'></breach-response-modal>",
          controllerAs: '$ctrl',
          controller: [
            'dumpFile',
            'action',
            '$uibModalInstance',
            function (dumpFile, action, $uibModalInstance) {
              this.dumpFile = dumpFile;
              this.action = action;
            },
          ],

          resolve: {
            dumpFile: () => {
              return dumpFile;
            },
            action: () => {
              return ctrl.dumpFileAction ? ctrl.dumpFileAction : null;
            },
          },
        })
        .result.then(
          dumpFile => {
            if (dumpFile) {
              breachResponseService
                .createBreachResponse(dumpFile)
                .then(() => {
                  ctrl.dumpFileAction === 'Update'
                    ? notificationService.success('Updated Successfully!')
                    : notificationService.success('A new breach response has been created successfully!');
                  ctrl.getAllResponses();
                })
                .catch(function () {
                  notificationService.error('An error has occurred!');
                });
            }
          },
          () => {
            //dismiss
          },
        );
    };

    ctrl.exploreBreachResponse = file => {
      const params = { fileID: file._id, file: file };
      $state.go('exploreBreachResponse', params);
    };

    $translate('BREACH_RESPONSE').then(translation => {
      $rootScope.$broadcast('changePage', translation, false);
    });
  },
});
