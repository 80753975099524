import React, { FC, useEffect, useState } from 'react';
import { $state, $stateParams } from '../../services/angularServices';
import { CustomAppAction, CustomAppActions, Preset } from './views/CustomAppActions/CustomAppActions';
import { AppInfo } from './utils/CustomAppTypes';
import { CustomAppPageWrapper } from './CustomAppPageWrapper';
import { CONFIG } from '../../../config/common';
import { getCustomAppActionsDetails, setCustomAppInfo } from './utils/CustomAppUtils';
import { isEmpty, isNull, isEqual, cloneDeep } from 'lodash';
import { httpService } from '../../services/httpService';

export const CustomAppActionsPage: FC = () => {
  const [appInfo, setAppInfo] = useState<AppInfo>(null);
  const [actions, setActions] = useState<CustomAppAction[]>(null);
  const [licenseActions, setLicenseActions] = useState<string[]>(null);

  const reloadActions = async () => {
    setActions(await getCustomAppActionsDetails($stateParams.id));
  };

  useEffect(() => {
    const updateAppInfo = async () => {
      if (appInfo) return;
      setCustomAppInfo($stateParams.appInfo, $stateParams.id, setAppInfo);
      reloadActions();
    };
    updateAppInfo();
  }, [appInfo]);

  useEffect(() => {
    if (!appInfo || licenseActions) return;
    const getLicense = async () => {
      const {
        data: { supportedApps },
      } = await httpService.fetch('license');
      const appLicense = supportedApps.find(
        (license: { name: string; vendor: string }) =>
          appInfo.name === license.name && appInfo.vendor === license.vendor,
      );
      setLicenseActions(appLicense?.actions);
    };
    getLicense();
  }, [appInfo, licenseActions]);

  useEffect(() => {
    const updateActionsPermissions = () => {
      const newActions = cloneDeep(actions);

      newActions.forEach(action => {
        const actionPermission = appInfo.actionsPermissions.find(permission => permission.actionName === action.name);
        action.isEnabled = !actionPermission?.isPremium;
        if (licenseActions && !action.isEnabled) action.isEnabled = licenseActions.includes(action.name);
      });

      if (!isEqual(newActions, actions)) {
        setActions(newActions);
      }
    };
    if (actions && appInfo?.actionsPermissions) updateActionsPermissions();
  }, [actions, appInfo?.actionsPermissions, licenseActions]);

  const handleOnSetPreset = (action: CustomAppAction, preset?: Preset) => {
    const params = {
      appInfo,
      action,
      preset,
      id: appInfo.id,
      actionId: action.id,
      presetId: preset ? preset._id : '',
    };
    $state.go(CONFIG.states.CUSTOM_APP_EDIT_PRESET, params);
  };

  return (
    <CustomAppPageWrapper
      appInfo={appInfo}
      pageName={appInfo?.isInteractive && !appInfo.hideUI ? 'Actions' : ''}
      isReady={!isEmpty(appInfo) && !isNull(actions)}
    >
      <CustomAppActions
        appInfo={appInfo}
        actions={actions}
        onAddNewPreset={handleOnSetPreset}
        onEditPreset={handleOnSetPreset}
        reloadActions={reloadActions}
      />
    </CustomAppPageWrapper>
  );
};
