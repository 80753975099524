import { getFixedT } from './translations';
import {
  AggregatedCategories,
  CreateDataSourceCategoryConfig,
  DataSourceOnboardingCategoriesKeys,
  DataSourceOnboardingTypes,
} from './types';
import {
  AUTO_DISCOVERY_TYPE_SELECT_CARD_SIZE,
  AutoDiscoveryTypeSelectCard,
} from './components/selectCards/AutoDiscoveryTypeSelectCard';
import { automaticOnboardingProvider } from './onboardingTypeProviders/automaticOnboardingProvider';
import { deepDiscoveryProvider } from './onboardingTypeProviders/deepDiscoveryProvider';

const t = getFixedT('');

export const DATA_SOURCE_ONBOARDING_CATEGORIES_CONFIG: Record<
  DataSourceOnboardingCategoriesKeys,
  CreateDataSourceCategoryConfig
> = {
  [AggregatedCategories.Popular]: {
    fetch: async () => [],
    label: t('categories.labels.mostPopular'),
    isPermitted: () => false,
  },
  [AggregatedCategories.AllCategories]: {
    label: t('categories.labels.all'),
    isPermitted: () => true,
    isClientSearch: true,
  },
  [DataSourceOnboardingTypes.AutomaticOnboarding]: {
    label: t('categories.labels.automaticOnboarding'),
    isClientSearch: true,
    cardConfig: { render: AutoDiscoveryTypeSelectCard, width: AUTO_DISCOVERY_TYPE_SELECT_CARD_SIZE.width },
    emptyMessage: t('grid.autoDiscoveryEmpty'),
    ...automaticOnboardingProvider,
  },
  [DataSourceOnboardingTypes.DeepDiscovery]: {
    label: t('categories.labels.deepDiscovery'),
    isClientSearch: true,
    ...deepDiscoveryProvider,
  },
  [DataSourceOnboardingTypes.AutoScanning]: {
    fetch: async () => [],
    label: t('categories.labels.autoScanning'),
    isPermitted: () => false,
  },
  [DataSourceOnboardingTypes.Manual]: {
    fetch: async () => [],
    label: t('categories.labels.manual'),
    isPermitted: () => false,
    isClientSearch: true,
    cardConfig: { isLazyRender: true },
  },
};
