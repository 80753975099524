import React, { useEffect, useMemo } from 'react';
import { BigidButtonIconSize, BigidFieldFilter, BigidFilter } from '@bigid-ui/components';
import { SuggestedActions } from './mappers/styles';
import {
  BigidSuggestedActionBox,
  BigidSuggestedActionBoxAction,
  BigidSuggestedActionBoxToolbarAction,
} from '../../../components/BigidSuggestedActionBox/BigidSuggestedActionBox';
import { BigidApplyIcon, BigidFilterFunnelAddIcon } from '@bigid-ui/icons';
import { useGetSuggestedActions } from './hooks/useGetSuggestedActions';
import { useLocalTranslation } from './translations';
import { useCreateSuggestedAction as useTriggerSuggestedAction } from './hooks/useCreateSuggestedAction';
import { mapActionInitiativeToBigidSuggestedActionBoxActions } from './mappers/actions';
import { INITIATIVE_FIELD_LABEL } from './constants/actions';
import { generateDataAid } from '@bigid-ui/utils';
import { DataSourcesUITrackingEvent, trackEventDataSources } from '../DataSourcesEventTrackerUtils';
import {
  ActionInitiative,
  ActionInitiativeAggregate,
  InitiativeType,
  SuggestedAction,
  SuggestedActionType,
  ToolbarAction,
} from './types';

type DataSourceSuggestedActionsProps = {
  dataAid?: string;
  selected?: string;
  title?: string;
  filters?: BigidFilter;
  onFilter?: (filters: BigidFilter) => void;
  onMount?: () => void;
};

export const DataSourceSuggestedActions = ({
  dataAid = 'DataSourceSuggestedActions',
  selected,
  title,
  filters,
  onFilter,
  onMount,
}: DataSourceSuggestedActionsProps): JSX.Element => {
  const { t } = useLocalTranslation();
  const { data: initiative, isLoading } = useGetSuggestedActions(filters, {
    query: {
      select: ({ data }) =>
        [
          ...(data?.suggestedActions?.map(props => ({ ...props, type: InitiativeType.SUGGESTED_ACTION })) ?? []),
          ...(data?.openIssues?.map(props => ({ ...props, type: InitiativeType.OPEN_ISSUE })) ?? []),
        ] as ActionInitiativeAggregate[],
    },
  });

  const { mutateAsync: triggerSuggestedAction } = useTriggerSuggestedAction();

  const handleDone = (initiative: ActionInitiative) => {
    return;
  }; // @todo

  const handleFilterGrid = ({ initiativeLabel, count }: ActionInitiative) => {
    const isActive = initiativeLabel === selected;

    !isActive &&
      trackEventDataSources(DataSourcesUITrackingEvent.SUGGESTED_ACTIONS_FILTER_CLICK, {
        suggestedAction: initiativeLabel,
        count,
      });

    const activeFilters: BigidFilter = [
      ...filters?.filter(({ field }) => field !== INITIATIVE_FIELD_LABEL),
      isActive
        ? null
        : ({
            field: INITIATIVE_FIELD_LABEL,
            value: initiativeLabel,
            operator: 'equal',
          } as BigidFieldFilter),
    ].filter(Boolean);

    onFilter?.(activeFilters);
  };

  const handleAction = ({
    actionType,
    redirectTo,
    actionLabel,
    initiativeLabel,
    isEnabled,
    count,
  }: SuggestedAction & { initiativeLabel: string; count: number }) => {
    const isRedirect = actionType === SuggestedActionType.REDIRECT;

    if (!isEnabled) return;

    trackEventDataSources(DataSourcesUITrackingEvent.SUGGESTED_ACTIONS_ACTION_CLICK, {
      suggestedAction: initiativeLabel,
      action: actionLabel,
      actionType,
      count,
    });

    isRedirect
      ? location.assign(redirectTo)
      : triggerSuggestedAction({
          params: { filters: filters?.filter(({ field }) => field !== INITIATIVE_FIELD_LABEL) },
          actionType: actionLabel,
          initiativeType: initiativeLabel,
        });
  };

  const toolbarConfig = (initiative: ActionInitiative): BigidSuggestedActionBoxToolbarAction[] => {
    const { initiativeLabel: initiativeId } = initiative;

    return [
      {
        id: ToolbarAction.FILTER,
        icon: BigidFilterFunnelAddIcon,
        onClick: () => handleFilterGrid(initiative),
        size: 'small' as BigidButtonIconSize,
        dataAid: generateDataAid('BigidSuggestedActionBoxToolbarAction', [initiativeId, ToolbarAction.FILTER]),
      },
      {
        id: ToolbarAction.DONE,
        icon: BigidApplyIcon,
        onClick: () => handleDone(initiative),
        size: 'small' as BigidButtonIconSize,
        dataAid: generateDataAid('BigidSuggestedActionBoxToolbarAction', [initiativeId, ToolbarAction.DONE]),
      },
    ].filter(({ id }) => id !== ToolbarAction.DONE); // @info temporarily disable done action for POC
  };

  const actions: BigidSuggestedActionBoxAction[] = useMemo(
    () =>
      !isLoading
        ? mapActionInitiativeToBigidSuggestedActionBoxActions(
            initiative ?? [],
            toolbarConfig,
            selected,
            t,
            handleAction,
          )
        : [],
    [isLoading, filters, initiative, selected],
  );

  useEffect(() => {
    onMount?.();
  }, []);

  return (
    <SuggestedActions.Wrapper>
      <BigidSuggestedActionBox
        dataAid={dataAid}
        loading={isLoading}
        selected={selected}
        title={title}
        actions={actions}
      />
    </SuggestedActions.Wrapper>
  );
};
