import { useState, useEffect } from 'react';

export const useCopy = () => {
  const [hasClicked, setHasClicked] = useState(false);
  const [label, setLabel] = useState('copy');

  useEffect(() => {
    if (!hasClicked) {
      setLabel('copy');
    } else {
      setLabel('copied');
    }
  }, [hasClicked]);

  const handleHasClicked = () => {
    setHasClicked(true);
    setTimeout(() => {
      setHasClicked(false);
    }, 2000);
  };

  return { handleHasClicked, label };
};
