import './savedQueriesWrapper.component.scss';
import template from './savedQueriesWrapper.component.html';

import { module } from 'angular';
import { convertToReact } from '../../common/services/convertToReact';
const app = module('app');

const config = {
  template,
  controller: [
    '$rootScope',
    '$scope',
    '$translate',
    function ($rootScope, $scope, $translate) {
      $translate('SAVED_QUERIES').then(translation => {
        $rootScope.$broadcast('changePage', translation, false);
      });

      this.isLoading = false;

      this.onLoading = () => {
        this.isLoading = true;
      };

      this.onLoaded = () => {
        $scope.$evalAsync(() => {
          this.isLoading = false;
        });
      };
    },
  ],
};

app.component('savedQueriesWrapper', {
  ...config,
});

export const LegacySavedQueriesPage = convertToReact('savedQueriesPage', config);
