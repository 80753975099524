import { notificationService } from '../../../../services/notificationService';
import { format } from 'date-fns';
import { rolesService } from '../../../../services/angularServices';
import {
  BigidDropdownOption,
  EntityEvents,
  entityEventsEmitter,
  BigidFormFieldTypes,
  BigidFieldFilterOperator,
  BigidFormField,
  BigidFormValues,
  BigidFormFieldLabelPosition,
  BigidSelectOption,
} from '@bigid-ui/components';
import { httpService } from '../../../../services/httpService';
import { getFixedT } from '../../translations';
import { CaseModel, CaseStatus, ViewType } from '../../actionableInsightsService';
import { OpenServiceTicketMetadata } from '../../../ActionCenter/ActionWorkflow/actionWorkflowTypes';
import {
  loadJiraUsersSearchOptions,
  ISSUE_TYPE_FIELD_NAME,
} from '../../../ActionCenter/ActionWorkflow/ActionWorkflowContent/ConfigureActionWorkflow/ActionForms/OpenServiceTicket/openServiceTicketUtils';
import { queryService } from '../../../../services/queryService';
import { Filters } from '../../../../types/actionableInsights';
import { ActionsDialogTypes } from './hooks/useCaseActions';
import { ServiceTicketingType } from './ModalContent/TicketModal/TicketContent';

export type RBACUser = {
  _id: string;
  name: string;
  firstName: string;
  lastName: string;
};

export interface ModalContentProps {
  closeModal: () => void;
  rowsData: CaseModel[];
  onRemediateCase?: () => void;
  onAcknowledgeCase?: () => void;
  onSilenceCase?: () => void;
  onAssignCase?: (assignee: string) => void;
  viewType?: ViewType;
  filters?: Filters;
}

export interface OptionalFields {
  dueDate?: boolean;
  priority?: boolean;
}

export interface JiraTicketData {
  configurationId: string;
  summary: string;
  issueType: string;
  description: string;
  assignee: string;
  priority?: string;
  duedate?: string;
  dueDate?: string; // due to BCO-9894 - Jira fields convention issue
}

export enum JiraValidationErrorType {
  CANT_VERIFY = 'cantVerify',
  REQUEST_FAILURE = 'requestFailure',
}

export interface JiraValidationResult {
  isValid: boolean;
  errorType?: JiraValidationErrorType;
  title?: string;
  message?: string;
}

const tErrorMessages = getFixedT('Action.common.errors');
const tNotificationMessages = getFixedT('Action.common.notifications');
const tActionLabels = getFixedT('Action.label');
const tFields = getFixedT('Action.fields');
const tJiraForm = getFixedT('Action.jira.form');
const tPriorityOptions = getFixedT('Action.jira.priorityOptions');
const tTicketDesc = getFixedT('Action.jira.ticketDescription');

export enum JiraProirity {
  HIGHEST = '1',
  HIGH = '2',
  MEDIUM = '3',
  LOW = '4',
  LOWEST = '5',
}

export const jiraPriortyTypesDropdownOptions = [
  {
    value: JiraProirity.HIGHEST,
    displayValue: tPriorityOptions('highest'),
    id: JiraProirity.HIGHEST,
  },
  {
    value: JiraProirity.HIGH,
    displayValue: tPriorityOptions('high'),
    id: JiraProirity.HIGH,
  },
  {
    value: JiraProirity.MEDIUM,
    displayValue: tPriorityOptions('medium'),
    id: JiraProirity.MEDIUM,
  },
  {
    value: JiraProirity.LOW,
    displayValue: tPriorityOptions('low'),
    id: JiraProirity.LOW,
  },
  {
    value: JiraProirity.LOWEST,
    displayValue: tPriorityOptions('lowest'),
    id: JiraProirity.LOWEST,
  },
];

const delay = (ms: number) => new Promise(res => setTimeout(res, ms));

type CaseActionBody = {
  type: string;
  subType: string;
  additionalProperties: {
    field: string;
    newValue: string;
    casesFilters?: {
      filterField: string;
      filterValues: string[];
    }[];
    auditReason?: string;
    allCases?: boolean;
  };
};

const buildFilters = (filters: Omit<Filters, 'callback'>) => {
  const casesFilters = [];

  if (filters.caseIds?.length) {
    casesFilters.push({
      filterField: '_id',
      filterValues: filters.caseIds,
    });
  }

  if (filters.policies?.length && !filters?.caseIds) {
    casesFilters.push({
      filterField: 'policyName',
      filterValues: filters.policies.map(policy => policy.policyName),
    });
  }

  if (filters.status) {
    casesFilters.push({
      filterField: 'caseStatus',
      filterValues: [filters.status],
    });
  }

  return casesFilters;
};

export const updateCaseAssignee = async (
  filters: Omit<Filters, 'callback'>,
  assignee: string,
  onAssignCase: (assignee: string, filters: Filters, modalType: ActionsDialogTypes) => void,
) => {
  const { caseIds, policies, status } = filters;
  const additionalProperties: any = {
    field: 'assignee',
    newValue: assignee,
    casesFilters: buildFilters({ caseIds, policies, status }),
  };

  try {
    const body: CaseActionBody = {
      type: 'CasesDB',
      subType: 'updateCases',
      additionalProperties,
    };

    await httpService.patch(`actionable-insights/cases:action`, body);
    onAssignCase(assignee, filters, ActionsDialogTypes.ASSIGN);
    notificationService.success(tNotificationMessages('caseSucessfullyAssigned', { assignee }));
  } catch (e) {
    console.error(e);
    notificationService.error(tErrorMessages('updatingCaseAssignee'));
  }
};

export const fetchSystemUsers = async (): Promise<BigidDropdownOption[]> => {
  try {
    const {
      data: { users },
    } = await rolesService.getRBACUsers();
    return users.map((user: RBACUser) => ({
      id: user._id,
      value: user.name,
      displayValue: user.firstName && user.lastName ? `${user.firstName} ${user.lastName} (${user.name})` : user.name,
    }));
  } catch (e) {
    console.error(e);
    notificationService.error(tErrorMessages('fetchingUsersInfo'));
  }
};

export const fetchUnsupportedDsList = async () => {
  try {
    const {
      data: { data },
    } = await httpService.fetch<any>('data-sources-scan-features/unsupported-data-sources/HasPreview');
    return data?.unsupportedDataSourceTypes;
  } catch (e) {
    console.error(e);
    notificationService.error(tErrorMessages('errorUnsupportedDsList'));
  }
};

export const updateCaseStatus = async (
  filters: Omit<Filters, 'callback'>,
  caseStatus: CaseStatus,
  auditReason?: string,
  closeCaseCallback?: (caseStatus: CaseStatus) => void,
) => {
  const notifcactionStatus = tActionLabels(caseStatus);
  try {
    const additionalProperties: any = {
      field: 'caseStatus',
      newValue: caseStatus,
      casesFilters: buildFilters(filters),
      userName: 'TEST',
      ...(auditReason && { auditReason }),
    };

    await httpService.patch(`actionable-insights/cases:action`, {
      type: 'CasesDB',
      subType: 'updateCases',
      additionalProperties,
    });

    notificationService.success(tNotificationMessages('caseStatusUpdatedSucessfully', { notifcactionStatus }));
    closeCaseCallback?.(caseStatus);
  } catch (e) {
    console.error(e);
    notificationService.error(tErrorMessages('updateCaseStatus', { notifcactionStatus }));
  }
};

export const generateDropdownOptionsForIssueTypes = (
  data: OpenServiceTicketMetadata,
  fieldName: string,
): BigidDropdownOption[] => {
  const issueTypeField = data.fields.find(field => field.name === fieldName);
  return issueTypeField.options.map(option => {
    return { value: option.value, displayValue: option.label, id: option.value };
  });
};

export const generateSelectOptions = (data: OpenServiceTicketMetadata, fieldName: string): BigidSelectOption[] => {
  const issueTypeField = data.fields.find(field => field.name === fieldName);
  return issueTypeField.options;
};

export const formatDataFromSelectToDropDown = (options: BigidSelectOption[]): BigidDropdownOption[] => {
  const result: any = options.map((option: any) => ({ ...option, id: option?.displayValue }));
  return result;
};

export const generateJiraConfigurationFormFields = (
  data: OpenServiceTicketMetadata,
  configurationId: string,
  optionalFields: OptionalFields = {},
): BigidFormField[] => {
  return [
    {
      name: 'issueType',
      label: tJiraForm('issueType') as string,
      type: BigidFormFieldTypes.DROP_DOWN,
      dropDownOptions: generateDropdownOptionsForIssueTypes(data, ISSUE_TYPE_FIELD_NAME),
      isRequired: true,
      validate: (value: BigidDropdownOption[]) => {
        if (!value?.length) {
          return tJiraForm('issueTypeValidation') as string;
        }
        return false;
      },
    },
    {
      name: 'summary',
      label: tJiraForm('summary') as string,
      type: BigidFormFieldTypes.TEXT,
      isRequired: true,
      validate: (summary: string) => {
        if (!summary) {
          return tJiraForm('summaryValidation') as string;
        }
        return false;
      },
    },
    {
      name: 'assignee',
      label: tJiraForm('assignee') as string,
      type: BigidFormFieldTypes.SELECT,
      fieldProps: {
        isAsync: true,
        shouldLoadInitialOptions: true,
        loadOptions: loadJiraUsersSearchOptions(configurationId),
      },
      isRequired: true,
      validate: (assignee: string) => {
        if (!assignee) {
          return tJiraForm('assigneeValidation') as string;
        }
        return false;
      },
    },
    ...(optionalFields.priority
      ? [
          {
            name: 'priority',
            label: tJiraForm('priority') as string,
            type: BigidFormFieldTypes.DROP_DOWN,
            dropDownOptions: jiraPriortyTypesDropdownOptions,
          },
        ]
      : []),
    ...(optionalFields.dueDate
      ? [
          {
            name: 'dueDate',
            label: tJiraForm('dueDate') as string,
            type: BigidFormFieldTypes.DATEPICKER,
            fieldProps: {
              placeholder: tJiraForm('dueDatePlaceHolder'),
            },
          },
        ]
      : []),
    {
      name: 'description',
      label: tJiraForm('description') as string,
      misc: {
        rows: 6,
      },
      fieldProps: {
        multiline: true,
      },
      type: BigidFormFieldTypes.TEXTAREA,
      isRequired: false,
    },
  ];
};

const checkTicketingField = (data: OpenServiceTicketMetadata, fieldName: string, fieldProp: string) => {
  const currentField: any = data?.fields.find(field => field.name === fieldName);
  if (currentField) {
    return currentField[fieldProp];
  } else {
    return undefined;
  }
};

export const generateTicketConfigurationFormFields = (data: OpenServiceTicketMetadata): BigidFormField[] => {
  return [
    {
      name: 'opened_by',
      label: 'Opened by',
      type: BigidFormFieldTypes.DROP_DOWN,
      dropDownOptions: formatDataFromSelectToDropDown(data.dropDownOptions.usersList),
      isRequired: checkTicketingField(data, 'opened_by', 'required'),
      validate: (opened_by: BigidDropdownOption[]) => {
        if (!opened_by?.length && checkTicketingField(data, 'opened_by', 'required')) {
          return tFields('openedByValidationField') as string;
        }
        return false;
      },
    },
    {
      name: 'short_description',
      label: 'Name',
      type: BigidFormFieldTypes.TEXT,
      isRequired: checkTicketingField(data, 'short_description', 'required'),
      validate: (short_description: string) => {
        if (!short_description && checkTicketingField(data, 'short_description', 'required')) {
          return tFields('nameValidationField') as string;
        }
        return false;
      },
    },
    {
      name: 'issue_type',
      label: 'Issue type',
      type: BigidFormFieldTypes.DROP_DOWN,
      isRequired: checkTicketingField(data, 'issue_type', 'required'),
      disabled: false,
      dropDownOptions: generateDropdownOptionsForIssueTypes(data, 'issue_type'),
      labelPosition: BigidFormFieldLabelPosition.top,
      labelWidth: '120px',
      validate: (issue_type: BigidDropdownOption[]) => {
        if (!issue_type?.length && checkTicketingField(data, 'issue_type', 'required')) {
          return tFields('issueTypeValidation') as string;
        }
        return false;
      },
      misc: {
        isCreatable: false,
      },
    },
    {
      name: 'state',
      label: 'State',
      type: BigidFormFieldTypes.DROP_DOWN,
      disabled: false,
      isRequired: checkTicketingField(data, 'state', 'required'),
      dropDownOptions: generateDropdownOptionsForIssueTypes(data, 'state'),
      labelPosition: BigidFormFieldLabelPosition.top,
      labelWidth: '120px',
      validate: (state: BigidDropdownOption[]) => {
        if (!state?.length && checkTicketingField(data, 'state', 'required')) {
          return tFields('stateValidationField') as string;
        }
        return false;
      },
      misc: {
        isCreatable: false,
      },
    },
    {
      name: 'assigned_to',
      label: 'Triage owner',
      type: BigidFormFieldTypes.DROP_DOWN,
      dropDownOptions: formatDataFromSelectToDropDown(data.dropDownOptions.usersList),
      isRequired: checkTicketingField(data, 'assigned_to', 'required'),
      validate: (assigned_to: BigidDropdownOption[]) => {
        if (!assigned_to?.length && checkTicketingField(data, 'assigned_to', 'required')) {
          return tFields('triageOwnerValidationField') as string;
        }
        return false;
      },
    },
    {
      name: 'priority',
      label: 'Priority',
      type: BigidFormFieldTypes.DROP_DOWN,
      disabled: false,
      isRequired: checkTicketingField(data, 'priority', 'required'),
      dropDownOptions: generateDropdownOptionsForIssueTypes(data, 'priority'),
      labelPosition: BigidFormFieldLabelPosition.top,
      labelWidth: '120px',
      validate: (priority: BigidDropdownOption[]) => {
        if (!priority?.length && checkTicketingField(data, 'priority', 'required')) {
          return tFields('priorityValidationField') as string;
        }
        return false;
      },
      misc: {
        isCreatable: false,
      },
    },
    {
      name: 'description',
      label: tJiraForm('description') as string,
      misc: {
        rows: 6,
      },
      fieldProps: {
        multiline: true,
      },
      type: BigidFormFieldTypes.TEXTAREA,
      isRequired: checkTicketingField(data, 'description', 'required'),
      validate: (description: BigidDropdownOption[]) => {
        if (!description?.length && checkTicketingField(data, 'description', 'required')) {
          return tFields('descriptionValidationField') as string;
        }
        return false;
      },
    },
  ];
};

export const generateServiceTicketDataFromFormValues = (values: BigidFormValues, configurationId?: string): any => {
  const formValues: Record<string, any> = {};
  for (const key in values) {
    if (values[key]) {
      if (typeof values[key] === 'string') {
        formValues[key] = values[key];
      }
      if (values[key] instanceof Array) {
        formValues[key] = values[key][0]?.value;
      }
    }
  }
  if (configurationId) {
    formValues.configurationId = configurationId;
  }
  return formValues;
};

export const createTicket = async (ticketData: JiraTicketData, caseId: string, type: ServiceTicketingType) => {
  try {
    if (ticketData.duedate) {
      ticketData.dueDate = format(new Date(ticketData.duedate), 'yyyy-MM-dd');
      delete ticketData.duedate; // due to BCO-9894 - Jira fields convention issue
    }
    const reqBody = {
      type: type === ServiceTicketingType.JIRA ? 'jira' : 'service_now',
      subType: 'createTicket',
      additionalProperties: ticketData,
    };
    const response = await httpService.post(`actionable-insights/cases/${caseId}:action`, reqBody);
    if (response?.status !== 200) {
      throw new Error(`Jira ticket creation fail with status code ${response?.status}`);
    }
  } catch (e) {
    throw new Error(e?.response?.data?.message || e);
  }
};

export const generateTicketDescription = (data: Record<string, any>, amountOfFindings: number): string => {
  const ticketDesc = [];
  data.policyName && ticketDesc.push(`${tTicketDesc('dataRiskType')}: ${data.policyName}\n`);
  data.dataSourceName && ticketDesc.push(`${tTicketDesc('dataSourceName')}: ${data.dataSourceName}\n`);
  data.dataSourceOwner && ticketDesc.push(`${tTicketDesc('dataSourceOwner')}: ${data.dataSourceOwner}\n`);
  data.numberOfAffectedObjects &&
    ticketDesc.push(`${tTicketDesc('numberOfAffectedObjects')}: ${data.numberOfAffectedObjects}\n`);
  Boolean(amountOfFindings) && ticketDesc.push(`${tTicketDesc('amountOfFindings')}: ${amountOfFindings}\n`);
  data.policyDescription && ticketDesc.push(`${tTicketDesc('description')}: ${data.policyDescription}\n`);
  ticketDesc.push(`Case: ${window.location.origin}/#/data-risk-management?caseId=${data.id}`);

  return ticketDesc.join('');
};

export const getTicketWithRetryAndDelay = async (query: string, retries: number, delayInMs: number) => {
  let success = false;

  while (retries && !success) {
    const {
      data: {
        data: { cases },
      },
    } = await httpService.fetch(`actionable-insights/all-cases?${query}`);
    if (cases.length && cases[0].ticketUrl?.includes('http')) {
      const jiraTicketNumber = cases[0].ticketMetadata?.key;
      notificationService.success(tNotificationMessages('jiraTicketSuccessfullyCreated', { jiraTicketNumber }));
      entityEventsEmitter.emit(EntityEvents.UPDATE_BY_ID, cases[0].id, {
        ticketUrl: cases[0].ticketUrl,
        ticketMetadata: cases[0].ticketMetadata,
      });
      success = true;
      return success;
    }

    retries--;
    await delay(delayInMs);
  }
  return success;
};

export const validateTicketCreationAndUpdateGrid = async (
  caseData: Record<string, any>,
): Promise<JiraValidationResult> => {
  try {
    const query = queryService.getGridConfigQuery({
      filter: [
        {
          field: 'dataSourceName',
          operator: 'equal' as BigidFieldFilterOperator,
          value: caseData.dataSourceName,
        },
        {
          field: 'policyName',
          operator: 'equal' as BigidFieldFilterOperator,
          value: caseData.policyName,
        },
      ],
      requireTotalCount: false,
      limit: 1,
    });
    const isJiraTicketCreated = await getTicketWithRetryAndDelay(query, 3, 2000);
    if (isJiraTicketCreated) {
      return { isValid: true };
    } else {
      return {
        isValid: false,
        errorType: JiraValidationErrorType.CANT_VERIFY,
        title: "Can't verify ticket creation",
        message: 'Try again',
      };
    }
  } catch (e) {
    return {
      isValid: false,
      message: e.message,
      title: 'Error in creating the Jira ticket',
      errorType: JiraValidationErrorType.REQUEST_FAILURE,
    };
  }
};
