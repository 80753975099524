import { notificationService } from '../../../../services/notificationService';
import { EventEmitter, EventEmitterDeregistrator } from '@bigid-ui/utils';
import { SSE_EVENTS, SSEDataMessage, subscribeToRepeatedSSEEventById } from '../../../../services/sseService';
import { isSimilarTablesEnabled } from '../utilities/utils';
import { getNotificationContent } from '../Notifications/NotificationContent';

export const similarTablesEventEmitter = new EventEmitter();

let unregister: EventEmitterDeregistrator;

const handleFindSimilarTablesSSE = (data: SSEDataMessage) => {
  const {
    results: [{ fullyQualifiedName }],
  } = data;
  similarTablesEventEmitter.emit(SSE_EVENTS.FIND_SIMILAR_TABLES_EVENT, fullyQualifiedName);
  notificationService.info('', {
    shouldAutoHide: false,
    contentElement: getNotificationContent(fullyQualifiedName),
  });
};

const initListener = () => {
  if (isSimilarTablesEnabled()) {
    unregister = subscribeToRepeatedSSEEventById(SSE_EVENTS.FIND_SIMILAR_TABLES_EVENT, handleFindSimilarTablesSSE);
  }
  return () => {
    unregister?.();
  };
};

export const similarTablesEventService = {
  initListener,
};
