import { showRouteLeaving, RouteLeavingResult } from '../../../services/routeLeavingDialogService';
import { useCallback, useRef } from 'react';

export interface OnLeavingComponentConfigOptions {
  onLeave: () => void;
  onClose: () => void;
  header: string;
  body: string;
}

export const useOnLeavingHandler = () => {
  const isModalOpened = useRef(false);

  const onLeavingComponent = useCallback(({ onLeave, onClose, header, body }: OnLeavingComponentConfigOptions) => {
    if (isModalOpened.current) return;

    isModalOpened.current = true;
    showRouteLeaving({
      onClose: () => {
        onClose();
        isModalOpened.current = false;
      },
      header,
      body,
    })
      .then(selection => {
        if (selection !== RouteLeavingResult.leave) return;

        onLeave();
      })
      .finally(() => {
        isModalOpened.current = false;
      });
  }, []);

  return onLeavingComponent;
};
