import { BigidGridColumn, BigidGridColumnTypes, BigidGridWithToolbarProps } from '@bigid-ui/grid';
import { $state } from '../../../services/angularServices';
import { getCellValueForConnectionStatus } from '../../DSAR/SarProfileSettings/gridCellValues';
import { CorrelationSetModel } from './CorrelationSetConnectionTypes';
import { fetchUniqueDataSourcesWithCorrelationSets } from './correlationSetUtils';
import { BigidColorsV2 } from '@bigid-ui/components';

export const correlationSetConnectionDefaultColumns: BigidGridColumn<CorrelationSetModel>[] = [
  {
    title: 'Name',
    name: 'name',
    width: 220,
    type: BigidGridColumnTypes.LINK,
    sortingEnabled: true,
    formatter: {
      onClick: async ({ value }) => {
        $state.go(`newEntityConnection`, { id: value.link.text });
        return {};
      },
    },
    getCellValue: ({ name }) => ({
      link: {
        text: name,
      },
    }),
  },
  {
    title: 'Data Source Name',
    name: 'dsConnection',
    sortingEnabled: true,
    width: 220,
    type: BigidGridColumnTypes.TEXT,
    getCellValue: ({ dsConnection }) => dsConnection,
  },
  {
    title: 'DB Name',
    name: 'db_name',
    sortingEnabled: true,
    width: 120,
    type: BigidGridColumnTypes.TEXT,
    getCellValue: ({ db_name }) => db_name,
  },
  {
    title: 'Table Name',
    name: 'table_name',
    sortingEnabled: true,
    width: 130,
    type: BigidGridColumnTypes.TEXT,
    getCellValue: ({ table_name }) => table_name,
  },
  {
    title: 'Total Entities',
    name: 'total_identities',
    sortingEnabled: true,
    width: 150,
    type: BigidGridColumnTypes.NUMBER,
    getCellValue: ({ total_identities }) => total_identities,
  },
  {
    title: 'Last Test',
    name: 'connectionStatusTest.is_success',
    width: 200,
    type: BigidGridColumnTypes.STATUS,
    getCellValue: ({ connectionStatusTest }) => getCellValueForConnectionStatus(connectionStatusTest),
  },
  {
    title: 'Last Scan',
    name: 'connectionStatusScan.is_success',
    width: 200,
    type: BigidGridColumnTypes.STATUS,
    getCellValue: ({ connectionStatusScan }) => getCellValueForConnectionStatus(connectionStatusScan),
  },
  {
    title: 'Status',
    name: 'enabled',
    sortingEnabled: true,
    width: 150,
    type: BigidGridColumnTypes.CHIP,
    getCellValue: ({ enabled }) => {
      const isNotEnabled = enabled === 'no';
      return {
        chip: {
          label: isNotEnabled ? 'Disabled' : 'Enabled',
          bgColor: isNotEnabled ? BigidColorsV2.gray[150] : BigidColorsV2.green[100],
          color: isNotEnabled ? BigidColorsV2.gray[500] : BigidColorsV2.green[900],
        },
      };
    },
  },
];

export async function getCorrelationSetConnectionFilterConfig(
  isScanTemplateWizard?: boolean,
): Promise<BigidGridWithToolbarProps<CorrelationSetModel>['filterToolbarConfig']> {
  const uniqDataSourceNames = await fetchUniqueDataSourcesWithCorrelationSets();

  return {
    filters: [
      {
        title: 'Data Source Name',
        field: 'dsConnection',
        operator: 'in',
        disabled: true,
        options: uniqDataSourceNames,
        value: [],
      },
      {
        isSelected: isScanTemplateWizard,
        title: 'Status',
        field: 'enabled',
        operator: 'in',
        disabled: !isScanTemplateWizard,
        single: true,
        value: isScanTemplateWizard ? ['yes'] : [],
        options: [
          {
            label: 'Enabled',
            value: 'yes',
            isSelected: isScanTemplateWizard,
          },
          {
            label: 'Disabled',
            value: 'no',
            isSelected: false,
          },
        ],
      },
    ],
    searchConfig: {
      searchFilterKeys: ['name'],
      placeholder: 'Name',
      initialValue: '',
      operator: 'contains',
      autoSubmit: true,
    },
  };
}
