import React, { FC, memo } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { BigidColorsV2 } from '@bigid-ui/components';
import classNames from 'classnames';

export interface ColumnProfilePercentageProps {
  dataAid?: string;
  value: number;
  color?: string;
}

type Circle = {
  /* X coordinate of circle centre */
  x: number;
  /* Y coordinate of circle centre */
  y: number;
  /* radius */
  r: number;
  /* circumference */
  c: number;
};

const viewBoxSize = 100;
const baseCircleStrokeWidth = 9;
const valueCircleStrokeWidth = 8;

const useStyles = makeStyles({
  chart: {
    display: 'block',
    height: '100%',
    width: '100%',
  },
  circle: {
    fill: 'none',
    stroke: BigidColorsV2.gray[100],
    strokeWidth: baseCircleStrokeWidth,
  },
  segment: {
    fill: 'none',
    strokeWidth: valueCircleStrokeWidth,
    strokeLinecap: 'round',
    transformOrigin: 'center center',
  },
  valueSegment: {
    transform: 'rotate(-90deg)',
  },
});

function getCircle(): Circle {
  const r = viewBoxSize / 2 - baseCircleStrokeWidth / 2;
  const x = viewBoxSize / 2;
  const y = viewBoxSize / 2;
  const c = 2 * Math.PI * r;

  return { x, y, r, c };
}

export const ColumnProfilePercentage: FC<ColumnProfilePercentageProps> = memo(
  ({ dataAid = 'ColumnProfilePercentage', color = BigidColorsV2.cyan[900], value = 0 }) => {
    const classes = useStyles({});

    const circle: Circle = getCircle();

    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox={`0 0 ${viewBoxSize} ${viewBoxSize}`}
        className={classes.chart}
        data-aid={dataAid}
      >
        <circle className={classes.circle} cx={circle.x} cy={circle.y} r={circle.r} />
        <circle
          className={classNames(classes.segment, classes.valueSegment)}
          strokeDasharray={circle.c}
          strokeDashoffset={circle.c * (1 - value / 100)}
          stroke={color}
          cx={circle.x}
          cy={circle.y}
          r={circle.r}
        />
        <title>{value}%</title>
      </svg>
    );
  },
);
