import React, { FC, useEffect, useRef, useMemo, useCallback } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { PrimaryButton, SecondaryButton } from '@bigid-ui/components';
import { CustomDashboardConfig } from '@bigid-ui/custom-dashboard';
import { CUSTOM_DASHBOARD_PERMISSIONS } from '@bigid/permissions';
import { $state } from '../../../services/angularServices';
import { pageHeaderService } from '../../../../common/services/pageHeaderService';
import { CustomDashboardContainer } from './CustomDashboardContainer';
import { isPermitted } from '../../../services/userPermissionsService';
import {
  CUSTOM_DASHBOARD_WRAPPER_CLASS_NAME,
  getCustomDashboardManagePermission,
} from './../../../services/customDashboardService';
import { DomToPdfButton, ButtonType, DomToPdfButtonProps } from '../../../components/DomToPdfButton';
import { isSecurityWorkSpaceDashboard } from '../../../utilities/workspacesUtils';

export interface CustomDashboardPreviewModeProps {
  dashboardConfig: CustomDashboardConfig;
  name: string;
  description: string;
  dashboardId: string;
  onSave: () => void;
  onEdit: () => void;
  onCancel: () => void;
}

const useStyles = makeStyles({
  buttonsWrapper: {
    display: 'flex',
    justifyContent: 'flex-end',
    gap: '10px',
  },
  customDashboardGeneratorWrapper: {
    overflowY: 'auto',
  },
});

const getPropsForDomToPdfButton = (name: string, description: string): DomToPdfButtonProps => {
  return {
    selectorQueryToFindElementToCapture: `.${CUSTOM_DASHBOARD_WRAPPER_CLASS_NAME}`,
    header: name,
    subHeader: description,
    fileName: name,
    useButton: true,
    buttonType: ButtonType.SECONDARY,
    buttonSize: 'large',
  };
};

export const CustomDashboardPreviewMode: FC<CustomDashboardPreviewModeProps> = ({
  dashboardConfig,
  name = '',
  description = '',
  dashboardId,
  onSave,
  onEdit,
  onCancel,
}) => {
  const customDashboardGeneratorContainerRef = useRef();
  const { buttonsWrapper, customDashboardGeneratorWrapper } = useStyles({});

  const handleEditButtonClick = useCallback(() => {
    onEdit();
  }, [onEdit]);

  const handleCancelButtonClick = useCallback(() => {
    onCancel();
  }, [onCancel]);

  const handleSaveButtonClick = useCallback(() => {
    onSave();
  }, [onSave]);

  const showEditButton = useMemo(
    () =>
      (isPermitted(CUSTOM_DASHBOARD_PERMISSIONS.MANAGE_ALL_CUSTOM_DASHBOARDS.name) ||
        isPermitted(getCustomDashboardManagePermission(dashboardId, name))) &&
      !isSecurityWorkSpaceDashboard(name),
    [dashboardId, name],
  );

  const domToPdfDownloadProps = useMemo(() => getPropsForDomToPdfButton(name, description), [name, description]);

  const rightSideComponentsContainer = useMemo(
    () => (
      <div className={buttonsWrapper}>
        {dashboardId === 'new' && <SecondaryButton size="large" onClick={handleCancelButtonClick} text="Cancel" />}
        {showEditButton && <SecondaryButton size="large" onClick={handleEditButtonClick} text="Edit" />}

        <DomToPdfButton {...domToPdfDownloadProps} />

        {dashboardId === 'new' && <PrimaryButton size="large" onClick={handleSaveButtonClick} text="Save" />}
      </div>
    ),
    [
      handleSaveButtonClick,
      handleEditButtonClick,
      handleCancelButtonClick,
      domToPdfDownloadProps,
      buttonsWrapper,
      dashboardId,
      showEditButton,
    ],
  );

  useEffect(() => {
    pageHeaderService.setTitle({
      showBackButton: true,
      defaultFrom: {
        state: 'customDashboard',
        params: {},
      },
      breadcrumbs: [{ label: 'Dashboard Manager', onClick: () => $state.go('customDashboard') }, { label: name }],
      rightSideComponentsContainer,
    });
  }, [name, rightSideComponentsContainer]);

  return (
    <>
      <div className={customDashboardGeneratorWrapper} ref={customDashboardGeneratorContainerRef}>
        <CustomDashboardContainer config={dashboardConfig} />
      </div>
    </>
  );
};

// eslint-disable-next-line import/no-default-export
export default CustomDashboardPreviewMode;
