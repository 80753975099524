import React, { FunctionComponent } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { BigidNotes1 } from '@bigid-ui/components';
import { BigidHelpIcon } from '@bigid-ui/icons';
import angular from 'angular';
import { convertToAngular } from '../../../common/services/convertToAngular';

const SCAN_STEPS_INFO =
  'Important: This data represents the scan progress and counts API calls received. \n For scanned objects and findings, see the catalog.';

const useStyles = makeStyles({
  info: {
    display: 'flex',
    marginBottom: 4,
  },
  caption: { marginLeft: 4, display: 'flex', flexDirection: 'column' },
});

export const InfoForProgressSteps: FunctionComponent = () => {
  const classes = useStyles({});

  return (
    <div className={classes.info}>
      <div>
        <BigidHelpIcon data-aid={BigidHelpIcon.name}></BigidHelpIcon>
      </div>

      <div className={classes.caption} data-aid="BigidApiCallFormBodyInformation">
        {SCAN_STEPS_INFO.split('\n').map((text, key) => (
          <BigidNotes1 key={key}>{text}</BigidNotes1>
        ))}
      </div>
    </div>
  );
};

angular.module('app').component('infoForProgressSteps', convertToAngular(InfoForProgressSteps));
