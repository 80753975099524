import React, { ReactElement } from 'react';
import { Overlay } from './DataSourceConnectionModalStyles';
import { DataSourceConnectionOverlay } from './DataSourceConnectionOverlay';
import type { DataSourceConnectionOverlayInProgressProps } from './DataSourceConnectionOverlay/DataSourceConnectionOverlayInProgress';
import type { DataSourceConnectionOverlaySuccessProps } from './DataSourceConnectionOverlay/DataSourceConnectionOverlaySuccess';

type DataSourceConnectionOverlayStepProps = DataSourceConnectionOverlayInProgressProps &
  DataSourceConnectionOverlaySuccessProps;

type DataSourceConnectionModalOverlayProps = {
  step?: DataSourceConnectionModalOverlaySteps;
  isBackgroundTransparent: boolean;
} & DataSourceConnectionOverlayStepProps;

export enum DataSourceConnectionModalOverlaySteps {
  IN_PROGRESS = 'inProgress',
  SUCCESS = 'success',
  PENDING = 'pending',
}

const DEFAULT_STEP_COMPONENT: ReactElement = null;

const dataSourceConnectionModalOverlayStepMapperFn = (
  props?: Record<string, unknown>,
): Record<DataSourceConnectionModalOverlaySteps, ReactElement> => ({
  [DataSourceConnectionModalOverlaySteps.IN_PROGRESS]: <DataSourceConnectionOverlay.InProgress {...props} />,
  [DataSourceConnectionModalOverlaySteps.SUCCESS]: <DataSourceConnectionOverlay.Success {...props} />,
  [DataSourceConnectionModalOverlaySteps.PENDING]: null,
});

const mapConnectionStepToComponent = (step: DataSourceConnectionModalOverlaySteps, props?: Record<string, unknown>) => {
  const dataSourceConnectionModalOverlayStepMapper = dataSourceConnectionModalOverlayStepMapperFn(props);
  return dataSourceConnectionModalOverlayStepMapper[step] ?? DEFAULT_STEP_COMPONENT;
};

export const DataSourceConnectionModalOverlay = ({
  step,
  isBackgroundTransparent,
  ...rest
}: DataSourceConnectionModalOverlayProps): JSX.Element => {
  const component = mapConnectionStepToComponent(step, { ...rest });
  return <>{component && <Overlay isBackgroundTransparent={isBackgroundTransparent}>{component}</Overlay>}</>;
};
