import React, { FC, useMemo, useRef } from 'react';
import { BigidFullPageDialogContainer, BigidLoader } from '@bigid-ui/components';
import { CloudProvider } from '../../constants';
import { AutoDiscoveryWizardContext, AutoDiscoveryWizardContextState } from '../autoDiscoveryWizardContext';
import { AutoDiscoverWizardForm } from '../autoDiscoveryComponents/AutoDiscoveryWizardForm/AutoDiscoverWizardForm';
import { AutoDiscoveryWizardFooter } from './AutoDiscoveryWizardFooter';
import { AutoDiscoveryWizardOverlay } from './AutoDiscoveryWizardOverlay';
import { AutoDiscoverWizardAside } from '../autoDiscoveryComponents/AutoDiscoveryWizardAside/AutoDiscoverWizardAside';
import { useDiscoveryConfigState } from '../../hooks/useDiscoveryConfigState';
import { useScrollToDsListAfterCheck } from '../../hooks/useScrollToDsListAfterCheck';

export interface AutoDiscoveryWizardPresetProps {
  type: CloudProvider;
  discoveryConfigDataRef: React.MutableRefObject<AutoDiscoveryWizardContextState>;
}

export const AutoDiscoveryWizardNewPreset: FC<AutoDiscoveryWizardPresetProps> = ({ type, discoveryConfigDataRef }) => {
  const { discoveryConfigData, setDiscoveryConfigData, isConfigLoaded } = useDiscoveryConfigState({
    type,
    discoveryConfigDataRef,
  });
  const formWrapperRef = useRef(null);
  const formRendered = useMemo(
    () =>
      isConfigLoaded ? (
        <div ref={formWrapperRef}>
          <AutoDiscoverWizardForm
            setDiscoveryConfigData={setDiscoveryConfigData}
            discoveryConfigDataRef={discoveryConfigDataRef}
          />
        </div>
      ) : (
        <BigidLoader />
      ),
    [discoveryConfigDataRef, isConfigLoaded, setDiscoveryConfigData],
  );

  useScrollToDsListAfterCheck(formWrapperRef, discoveryConfigData?.formData?.dsList?.length);

  return (
    <AutoDiscoveryWizardContext.Provider
      value={{
        discoveryConfigData,
        setDiscoveryConfigData,
        discoveryConfigDataRef,
      }}
    >
      <BigidFullPageDialogContainer
        content={formRendered}
        footer={<AutoDiscoveryWizardFooter />}
        aside={<AutoDiscoverWizardAside />}
        overlay={discoveryConfigData.isDiscovery && <AutoDiscoveryWizardOverlay />}
        isSplitView={true}
        dataAid={'AutoDiscoveryWizardMainModal'}
      />
    </AutoDiscoveryWizardContext.Provider>
  );
};
