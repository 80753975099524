import { httpService } from '../../../services/httpService';
import { ServiceConfigurationsModel, ServiceConfigurationValue } from './ServicesConfiguration';
import { BigidFilter } from '@bigid-ui/components';
import { BigidGridQueryComponents, BigidGridRow, BigidGridSorting, BigidGridDataFetchResult } from '@bigid-ui/grid';
import { groupBy, orderBy } from 'lodash';
import { initApplicationPreferences } from '../../../services/appPreferencesService';
import { $translate } from '../../../services/angularServices';
import { notificationService } from '../../../services/notificationService';
import { $rootScope } from 'ngimport/index';

export async function getServicesConfiguration() {
  try {
    const { data } = await httpService.fetch<ServiceConfigurationsModel[]>(`services-configuration`);
    return data;
  } catch (e) {
    const errMsg = `an error occurred in fetching the services configuration`;
    notificationService.error(errMsg);
    console.error(`${errMsg} ${e}`);
    return [];
  }
}

export async function editServiceConfiguration(
  configuration: ServiceConfigurationsModel,
  newConfigurationValue: ServiceConfigurationValue,
) {
  if (!configuration) {
    return;
  }

  const { isRestartRequired, name: configurationName, service: serviceName, isGrouped } = configuration;

  try {
    const updatedConfigurations = getConfigurationsToUpdate(configuration, newConfigurationValue, isGrouped);
    const updatePromises = updatedConfigurations.map(configuration =>
      httpService.put('services-configuration', { configuration }),
    );
    await Promise.all(updatePromises);

    const successMessage = isRestartRequired ? 'SUCCESS_RESTART' : 'SUCCESS';
    const translation = await $translate(`BETA_TOOLS:EDIT_CONFIGURATION:${successMessage}`, {
      configurationName,
      serviceName,
    });

    notificationService.success(translation, { shouldAutoHide: !isRestartRequired });
    ($rootScope as any).loaded = false;
    await initApplicationPreferences();
  } catch (e) {
    $translate('BETA_TOOLS:EDIT_CONFIGURATION:ERROR', { configurationName }).then(translation => {
      notificationService.error(translation);
    });
    console.error(e);
  } finally {
    ($rootScope as any).loaded = true;
    $rootScope.$applyAsync();
  }
}

export function groupServicesConfigurationData(rows: ServiceConfigurationsModel[]) {
  return Object.entries(groupBy(rows, 'name')).map(([name, values]) => {
    const [first] = values;
    const { isEditable, isRestartRequired, markAsSensitiveKey, type, value: firstValue } = first;
    const valueGroups = groupBy(values, 'value');
    const hasConflicts = Object.keys(valueGroups).length > 1;
    const value = hasConflicts
      ? values.reduce((result, { service, value }) => {
          const serviceValue = `${service}: ${value}`;
          return result === '' ? `${serviceValue}` : `${result}, ${serviceValue}`;
        }, '')
      : firstValue;
    const services = values.reduce((result, { service }) => {
      result.push(service);
      return result;
    }, []);

    return {
      id: name,
      name,
      isEditable,
      isRestartRequired,
      markAsSensitiveKey,
      service: values.reduce((result, { service }) => {
        return result === '' ? `${service}` : `${result}, ${service}`;
      }, ''),
      services,
      type,
      value,
      hasConflicts,
      isGrouped: true,
    };
  });
}

export function localSortAndFilterGridData<T extends BigidGridRow>(
  data: T[],
  queryComponents: BigidGridQueryComponents,
): BigidGridDataFetchResult<T> {
  const { skip, limit, sort, filter, requireTotalCount } = queryComponents;
  const [selectors, directions] = getSortingConfig(sort);

  const filteredData = Object.keys(filter).length > 0 ? gridFilteredConfigurations(data, filter) : data;
  const sortedData = orderBy(filteredData, selectors, directions);
  const dataSlice = sortedData.slice(skip, limit + skip);
  const totalCount = requireTotalCount ? filteredData.length : undefined;

  return {
    totalCount,
    data: dataSlice,
  };
}

function getSortingConfig(sorting: BigidGridSorting[]): [string[], Array<'desc' | 'asc'>] {
  const fields: string[] = [];
  const directions: Array<'desc' | 'asc'> = [];

  sorting.forEach(({ field, order }) => {
    fields.push(field);
    directions.push(order);
  });
  return [fields, directions];
}

function gridFilteredConfigurations<T extends BigidGridRow>(configurations: T[], filter: BigidFilter) {
  if (filter?.length) {
    const valueToFind = new RegExp(filter[0].value.toString(), 'i');
    return configurations.filter(field => {
      const rowValuesString = Object.values(field).toString();
      return rowValuesString.match(valueToFind);
    });
  }
  return configurations;
}

function getConfigurationsToUpdate(
  configuration: ServiceConfigurationsModel,
  newConfigurationValue: ServiceConfigurationValue,
  isGrouped: boolean,
) {
  const { services = [] } = configuration;
  const confWithUpdatedValue = { ...configuration, value: newConfigurationValue };
  let configurationToUpdate = [confWithUpdatedValue];

  if (isGrouped) {
    configurationToUpdate = services.map(service => ({ ...confWithUpdatedValue, service }));
  }

  return configurationToUpdate;
}
