import React, { FC, useState } from 'react';
import { BigidDropdown, BigidDropdownOption } from '@bigid-ui/components';
import { OOTBChecksumValidation } from '../ClassifiersChecksumValidationDialog/ChecksumValidationTypes';
import { generateDataAid } from '@bigid-ui/utils';

export interface ClassifierChecksumValidationOOTBProps {
  handleChecksumNameOnSelect: (checksumName: string) => void;
  ootbChecksumsNamesList: OOTBChecksumValidation[];
  initialOOTBChecksum: OOTBChecksumValidation[];
}
export const ClassifierChecksumValidationOOTBDropdown: FC<ClassifierChecksumValidationOOTBProps> = ({
  handleChecksumNameOnSelect,
  ootbChecksumsNamesList,
  initialOOTBChecksum,
}) => {
  const [selectedValue, setSelectedValue] = useState(initialOOTBChecksum);

  const handleOnSelect = (selectedOption: BigidDropdownOption[]): void => {
    setSelectedValue(selectedOption);
    const [requiredOOTBChecksumName] = selectedOption;
    handleChecksumNameOnSelect(requiredOOTBChecksumName.value);
  };

  return (
    <div>
      <BigidDropdown
        value={selectedValue}
        onSelect={handleOnSelect}
        options={ootbChecksumsNamesList}
        isSearchable={true}
        placeholder={'Select or search a checksum validation in the list'}
        dataAid={generateDataAid('BigidDropdown-checksum', [])}
      />
    </div>
  );
};
