import React from 'react';
import styled from '@emotion/styled';
import { BigidHeading6, BigidTooltip } from '@bigid-ui/components';
import { generateDataAid } from '@bigid-ui/utils';
import { useLocalTranslation } from '../translations';
import { BigidHelpIcon } from '@bigid-ui/icons';

const Wrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  padding: '16px 0px 16px 24px',
  justifyContent: 'flex-start',
  alignItems: 'center',
  border: theme.vars.tokens.bigid.borderDefault,
  background: theme.vars.palette.bigid.white,
  height: '66px',
  gap: '6px',
}));

const HelpIconContainer = styled('div')(({ theme }) => ({
  display: 'flex',
}));
export const BigChatHeader = () => {
  const { t } = useLocalTranslation('chatbot.header');

  return (
    <Wrapper data-aid={`${generateDataAid('BigChat', ['header'])}`}>
      <BigidHeading6>{`${t('text')}`}</BigidHeading6>
      <BigidTooltip fontSize="medium" placement="top" title={`${t('tooltip')}`}>
        <HelpIconContainer>
          <BigidHelpIcon />
        </HelpIconContainer>
      </BigidTooltip>
    </Wrapper>
  );
};
