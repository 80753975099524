export interface ReportTemplate {
  id: string;
  _id: string;
  templateName: string;
  templateTitle?: string;
  templateDescription?: string;
  companyInformationPage?: CompanyInformationPage;
  informationCollectionPage?: InformationCollectionPage;
  generalInformationPage?: generalInformationPageSection;
  personalInfoPage?: PersonalInfoPage;
  reportTitle?: ReportTitleSection;
  companyInfo?: CompanyInfoSection;
  contactInfo?: ContactInfoSection;
  personalInfo?: PersonalInformationSection;
  userDisplayInfo?: UserDisplayInformationSection;
  geoDataTransfer?: InfoCollectionGeoDataTransferSection;
  purposes?: InfoCollectionPurposesSection;
  thirdParties?: InfoCollectionThirdPartiesSection;
  mechanisms?: InfoCollectionMechanismsSection;
  rights?: InfoCollectionRightsSection;
  dataSources?: InfoCollectionDataSourcesSection;
  consents?: InfoCollectionConsentsSection;
  companyInformationPageDynamicRichText?: DynamicRichTextSubSection[];
  generalInformationPageDynamicRichText?: DynamicRichTextSubSection[];
  informationCollectionPageDynamicRichText?: DynamicRichTextSubSection[];
  personalInfoPageDynamicRichText?: DynamicRichTextSubSection[];
}

export type ReportTemplateWithoutId = Omit<ReportTemplate, 'id' | '_id'>;

export interface ReportTemplateMetaData {
  sectionTitle?: string;
  sectionSubTitle?: string;
  sectionDescription?: string;
  isEnabled?: boolean;
}
export type ReportTemplateSectionDetails = ReportTemplateMetaData;

export interface ReportTitleSection extends ReportTemplateSectionDetails {
  reportMainTitle?: string;
  reportSubTitle?: string;
}

export interface CompanyInfoSection extends ReportTemplateSectionDetails {
  name?: string;
  address?: string;
  phoneNumber?: string;
  email?: string;
  logo?: string;
  showCompanyNameInHeaders?: boolean;
}

export interface ContactInfoSection extends ReportTemplateSectionDetails {
  name?: string;
  position?: string;
  phoneNumber?: string;
  email?: string;
}

export interface InfoCollectionGeoDataTransferSection extends ReportTemplateSectionDetails {
  countries?: string[];
}

export interface InfoCollectionPurposesSection extends ReportTemplateSectionDetails {
  purposes?: string[];
}

export interface InfoCollectionThirdPartiesSection extends ReportTemplateSectionDetails {
  parties?: string[];
}

export interface InfoCollectionMechanismsSection extends ReportTemplateSectionDetails {
  mechanisms?: string[];
}

export interface InfoCollectionRightsSection extends ReportTemplateSectionDetails {
  dataSubjectRights?: string;
}

export interface DynamicRichTextSubSection extends ReportTemplateSectionDetails {
  content?: string;
  dynamicRichTextId?: string;
}

export interface PersonalInformationSection extends ReportTemplateSectionDetails {
  hideAttributePurposeOfUse?: boolean;
  shouldDeduplicateValues?: boolean;
  groupByCategory?: boolean;
  showCategoryColumn?: boolean;
}
export interface UserDisplayInformationSection extends ReportTemplateSectionDetails {
  displayRequestId?: boolean;
  displayProfileName?: boolean;
  displayDisplayName?: boolean;
  requestIdLabel?: string;
  profileNameLabel?: string;
  displayNameLabel?: string;
}

export enum SummaryReportType {
  CSV = 'csv',
  PDF = 'pdf',
}

export interface InfoCollectionDataSourcesSection extends ReportTemplateSectionDetails {}

export interface InfoCollectionConsentsSection extends ReportTemplateSectionDetails {}

export interface generalInformationPageSection extends ReportTemplateSectionDetails {}

export interface CompanyInformationPage extends ReportTemplateMetaData {}

export interface InformationCollectionPage extends ReportTemplateMetaData {}

export interface PersonalInfoPage extends ReportTemplateMetaData {}
