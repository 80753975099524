import { httpService } from '../../../../../../services/httpService';
import { notificationService } from '../../../../../../services/notificationService';
import { BulkClassifierSelectionGridRow, Category, ClassifiersByCategoryResponse } from './classificationStepTypes';
import { RegulationsData } from '../../../../../Fmsd/fmsdServices';
import { BulkClassifierSelectionMethod, Classifier } from '../../../scanTemplateTypes';
import { remove, unionBy, uniqBy } from 'lodash';
import { getCopyOfCountries } from '../../../../../../../config/countries';
import { getApplicationPreference } from '../../../../../../services/appPreferencesService';
import { BigidFieldFilterOperator } from '@bigid-ui/components';
import { queryService } from '../../../../../../services/queryService';
import {
  getNormalizedClassifiersViaPost,
  NormalizedClassifier,
} from '../../../../../Classification/ClassifiersService';
import { ScanTemplateFormContext } from '../../../scanTemplateContext';
import { BigidGridQueryComponents, BigidGridSorting } from '@bigid-ui/grid';

const COUNTRY_NAME = 'name';
const COUNTRY_CODE = 'alpha-2';

export const CLASSIFIERS_LIMIT = 25;
export const DEFAULT_SORTING: BigidGridSorting[] = [{ field: 'name', order: 'asc' }];
export const DEFAULT_CATEGORY_SORT: BigidGridSorting[] = [{ field: '_id', order: 'asc' }];
export const TEXT_SEARCH_FILTER = 'textSearch';

export const fetchRegulations = async (
  gridConfigQuery: string,
): Promise<{ regulations: BulkClassifierSelectionGridRow[]; totalCount: number }> => {
  try {
    const {
      data: {
        data: { regulations, totalCount },
      },
    } = await httpService.fetch<{ data: { regulations: RegulationsData[]; totalCount: number } }>(
      `regulations-aggregation?${gridConfigQuery}`,
    );
    const gridRegulations = convertBulkClassifiersToBulkSelectionRows(regulations);

    return { regulations: gridRegulations, totalCount };
  } catch (e) {
    notificationService.error('Failed to fetch regulations, see logs for more information.');
    return { regulations: [], totalCount: 0 };
  }
};

export const getGlobalEnabledClassifierCount = async (): Promise<number> => {
  let classifiersAmount = 0;
  try {
    const { data } = await httpService.fetch(`classifications?enabled`);
    classifiersAmount = data.length;
  } catch (e) {
    console.error('Failed to fetch amount of enabled classifiers, see logs for more information.');
  }
  return classifiersAmount;
};

export const convertBulkClassifiersToBulkSelectionRows = (
  regulations: RegulationsData[] | Category[],
): BulkClassifierSelectionGridRow[] => {
  return regulations.map(({ _id, name, classifiers }) => ({
    _id,
    name,
    selectedClassifiers: classifiers,
    id: _id,
  }));
};

export const getClassifierIds = (classifierList: Classifier[]) => classifierList?.map(({ name }) => name);

export const removeBulkSelectionByName = (bulkSelections: BulkClassifierSelectionGridRow[], name: string) =>
  remove(bulkSelections, bulkSelection => bulkSelection.name === name);

export const getClassifierIdsOfBulkSelected = (bulkSelections: BulkClassifierSelectionGridRow[]) => {
  return bulkSelections.flatMap(templateBulkSelection => templateBulkSelection.selectedClassifiers);
};

export const getListOfCountriesForFilter = () => {
  return getCopyOfCountries(getApplicationPreference('DISPLAY_CHINA_REGULATIONS'))
    .filter(country => !country.isState)
    .map(country => ({
      label: country[COUNTRY_NAME],
      value: country[COUNTRY_CODE],
      isSelected: false,
    }));
};

export const fetchClassifiersByNames = async (names: string[]) => {
  const selectedFilter = [
    {
      field: 'name',
      value: names,
      operator: 'in' as BigidFieldFilterOperator,
    },
  ];

  const queryObject = {
    filter: [...selectedFilter],
  };

  return getNormalizedClassifiersViaPost(queryObject);
};

export const onSelectBulkClassifiers = async ({
  isBulkClassifiersSelected,
  isPartialSelection,
  bulkSelectionData,
  classifiersFromBulkSelection,
  setScanTemplateFormData,
  isCategoryView,
}: {
  isBulkClassifiersSelected: boolean;
  isPartialSelection: boolean;
  classifiersFromBulkSelection: string[];
  bulkSelectionData: BulkClassifierSelectionGridRow;
  setScanTemplateFormData: ScanTemplateFormContext['setScanTemplateFormData'];
  isCategoryView: boolean;
}) => {
  const isAdded = !isBulkClassifiersSelected;

  if (isAdded) {
    try {
      const fetchedClassifiersFromBulkSelection = await fetchClassifiersByNames(classifiersFromBulkSelection);
      const classifiersForProfile = convertClassifierToScanClassifier(fetchedClassifiersFromBulkSelection.classifiers);

      setScanTemplateFormData(({ classifiers = [], bulkClassifierSelection = [], ...rest }) => {
        return {
          ...rest,
          classifiers: uniqBy([...classifiers, ...classifiersForProfile], 'name'),
          bulkClassifierSelection: [
            ...bulkClassifierSelection,
            {
              ...bulkSelectionData,
              isPartial: isPartialSelection,
            },
          ],
        };
      });
      notificationService.info(
        `${
          isCategoryView ? BulkClassifierSelectionMethod.CATEGORY : BulkClassifierSelectionMethod.REGULATION
        } added Successfully!`,
      );
    } catch (e) {
      notificationService.error('Failed to update regulation. See logs for more information');
    }
  } else {
    setScanTemplateFormData(prevState => ({
      ...prevState,
      classifiers: remove(
        prevState.classifiers,
        classifier => !Boolean(classifiersFromBulkSelection.find(classifierName => classifierName === classifier.name)),
      ),
      bulkClassifierSelection: remove(
        prevState.bulkClassifierSelection,
        bulkSelection => bulkSelectionData.name !== bulkSelection.name,
      ),
    }));
    notificationService.info(
      `${
        isCategoryView ? BulkClassifierSelectionMethod.CATEGORY : BulkClassifierSelectionMethod.REGULATION
      } removed Successfully!`,
    );
  }
};

export const convertClassifierToScanClassifier = (classifiers: NormalizedClassifier[]) =>
  classifiers.map(classifier => {
    return { name: getNormalizedName(classifier), type: classifier.type, id: classifier.id };
  });

export const getFullRegulationsByIds = async (selectedRegulations: BulkClassifierSelectionGridRow[]) => {
  const { regulations } = await fetchRegulations(
    queryService.getGridConfigQuery({
      sort: DEFAULT_SORTING,
      filter: [
        {
          field: 'name',
          value: selectedRegulations.map(({ name }) => name),
          operator: 'in' as BigidFieldFilterOperator,
        },
      ],
    }),
  );
  return regulations;
};

export const getClassifiersByCategory = async (queryComponents: BigidGridQueryComponents) => {
  try {
    const query = queryService.getGridConfigQuery(queryComponents);
    const classifierOnly = query ? `classificationOnly=true&${query}` : 'classificationOnly=true';

    const {
      data: {
        data: { classifiers, totalCount },
      },
    } = await httpService.fetch<{ data: ClassifiersByCategoryResponse }>(`classifiers-categories?${classifierOnly}`);
    return { classifiers, totalCount };
  } catch (e) {
    notificationService.error('Failed to fetch classifiers by category. See logs for more information.');
    console.error(e);
  }
};

export const getClassifiersByCategoryForGrid = async (
  bulkSelectedClassifiers: BulkClassifierSelectionGridRow[],
  queryComponents: BigidGridQueryComponents,
) => {
  try {
    const { classifiers, totalCount } = await getClassifiersByCategory(queryComponents);
    const categoriesForGrid = convertBulkClassifiersToBulkSelectionRows(classifiers);
    return { data: categoriesForGrid, totalCount };
  } catch (e) {
    notificationService.error('Failed to fetch classifiers by category. See logs for more information');
    console.error(e);
  }
};

export const getClassifiersByRegulationForGrid = async (
  bulkSelectedClassifiers: BulkClassifierSelectionGridRow[],
  queryComponents: BigidGridQueryComponents,
  saveTextFilterValue: (textSearchFilterValue: string) => void,
) => {
  try {
    queryComponents.filter = queryComponents.filter.concat([
      { field: 'hasClassifications', operator: 'equal', value: true },
    ]);
    const gridConfigQuery = queryService.getGridConfigQuery({
      ...queryComponents,
    });

    const textSearchFilter = queryComponents.filter.find(filter => filter.operator === TEXT_SEARCH_FILTER);
    saveTextFilterValue(textSearchFilter?.value as string);

    if (bulkSelectedClassifiers.length > 0) {
      bulkSelectedClassifiers = await getFullRegulationsByIds(bulkSelectedClassifiers);
    }

    const { regulations, totalCount } = await fetchRegulations(gridConfigQuery);
    return {
      data: regulations,
      totalCount,
    };
  } catch (error) {
    notificationService.error(`Failed to retrieve regulations. See logs for more information.`);
  }
};

export const getNormalizedName = (classifier: NormalizedClassifier) => {
  const { name, type, originalData } = classifier;
  const nerTitle = originalData?.[0]?.name;
  return type === 'ner' ? nerTitle : name;
};
