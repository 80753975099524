import React, { FC, ReactNode } from 'react';
import { EntityType } from '../../types';
import { styled } from '@mui/material/styles';
import { BigidBody1, BigidLink } from '@bigid-ui/components';
import { mapEntityTypeToIcon } from './utils';
import { formatNumberCompact } from '../../../../../utilities/numericDataConverter';
import { useLocalTranslation } from '../../../translations';
import { isFinite } from 'lodash';
import { generateDataAid } from '@bigid-ui/utils';

const SectionContainer = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const SectionHeader = styled('div')`
  display: flex;
  gap: 8px;
  align-items: center;
`;

const ListContainer = styled('ul')`
  display: flex;
  flex-direction: column;
  padding: 0;
  margin: 0;
  gap: 12px;
`;

type EntitySectionProps = {
  sectionName: EntityType;
  listItems: ReactNode[];
  handleShowAllClick: () => void;
  count?: number;
  shouldShowViewAll?: boolean;
  dataAid?: string;
};
export const EntitySection: FC<EntitySectionProps> = ({
  sectionName,
  count,
  listItems,
  handleShowAllClick,
  shouldShowViewAll,
  dataAid = 'EntitySection',
}) => {
  const { t } = useLocalTranslation('QuickSearchResults.Sections');
  const Icon = mapEntityTypeToIcon(sectionName);

  const title = t(`Titles.${sectionName}`);
  return (
    <SectionContainer data-aid={generateDataAid(dataAid, [sectionName, 'container'])}>
      <SectionHeader data-aid={generateDataAid(dataAid, [sectionName, 'header'])}>
        <Icon />
        {isFinite(count) && (
          <BigidBody1 data-aid={generateDataAid(dataAid, [sectionName, 'counter'])}>
            {formatNumberCompact(count)}
          </BigidBody1>
        )}
        <BigidBody1 data-aid={generateDataAid(dataAid, [sectionName, 'title'])}>{title}</BigidBody1>
        {shouldShowViewAll && (
          <BigidBody1 size="small" data-aid={generateDataAid(dataAid, [sectionName, 'view-all'])}>
            <BigidLink text={t('viewAll')} onClick={handleShowAllClick} underline="hover" />
          </BigidBody1>
        )}
      </SectionHeader>
      <ListContainer data-aid={generateDataAid(dataAid, [sectionName, 'list'])}>{listItems}</ListContainer>
    </SectionContainer>
  );
};
