export const YES = 'yes';
export const NO = 'no';
export const CONTAINS_PI = 'Contains PI';

export const OPEN_ACCESS_CHIP_TYPE = 'open_access';
export const OPEN_ACCESS = 'Open Access';
export const NO_OPEN_ACCESS = 'No Open Access';

export const HAS_DUPLICATES_CHIP_TYPE = 'has_duplicates';
export const HAS_DUPLICATES = 'Has Duplicates';
export const NO_DUPLICATES = 'No Duplicates';

export const TOTAL_COUNT_THRESHOLD = 20e9;

export const EXCLUDED_TAGS = ['Risk', 'Top Entity Sources'];

export const OWNER_OPERAND_MANUALLY_SET =
  'scanner_type_group = "structured" AND owner > 0 AND NOT object_owners_struct.origin';

export const OWNER_OPERAND_IM_PREDICTED =
  'scanner_type_group = "structured" AND owner > 0 AND object_owners_struct.origin';

export const OWNER_OPERAND_NO_OWNER = 'scanner_type_group = "structured" AND NOT owner';
