import { useEffect, useState } from 'react';
import { getAppDetails } from '../services/classifiersManagementService';
import { TPA } from '../types/ClassifierTypes';
import { getIsNerClassifierFFEnabled } from '../utils/utils';

export const useTPADetails = (appName: string) => {
  const isNerEnabled = getIsNerClassifierFFEnabled();
  const [appData, setAppData] = useState<TPA>({} as TPA);

  useEffect(() => {
    const fetchTpa = async () => {
      try {
        const response = await getAppDetails(appName);
        if (response?.data?.length) {
          setAppData(response.data[0]);
        }
      } catch (e) {
        setAppData({} as TPA);
      }
    };

    isNerEnabled && fetchTpa();
  }, []);

  return {
    appData,
  };
};
