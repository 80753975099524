import { BigidAdvancedToolbarFilterUnion, BigidFormValues } from '@bigid-ui/components';
import { SavedFilter } from './saveQueryService';
import { v4 as uuid } from 'uuid';
import { getAppliedFilterValues } from '../../../filter/utils';

export function getCreateSavedFilterPayload(
  saveQueryFormState: BigidFormValues,
  filter: BigidAdvancedToolbarFilterUnion[],
  id?: string,
): SavedFilter {
  const { name, description, query } = saveQueryFormState;

  const payload: SavedFilter = {
    name: name.trim(),
    description: description?.trim(),
    query,
    filter: getAppliedFilterValues(filter),
  };

  if (id) {
    payload.id = id;
  } else {
    payload.id = uuid();
  }

  return payload;
}

export function getDefaultSavedFilterFormState(query: string, filter: SavedFilter): BigidFormValues {
  const formState: BigidFormValues = {
    name: filter?.name ?? '',
    query,
    description: filter?.description ?? '',
  };

  if (filter) {
    formState.saveAsNew = true;
  }

  return formState;
}
