import React, { FC } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { BigidHeading4, SecondaryButton, StyledButtonType } from '@bigid-ui/components';

export interface RouteLeavingContentProps {
  body: string;
}

const useStyles = makeStyles({
  wrapper: {
    marginRight: 'auto',
  },
  textWrapper: {
    paddingBottom: '40px',
  },
});

export const CancelButton: FC<StyledButtonType> = props => (
  <div className={useStyles({}).wrapper}>
    <SecondaryButton {...props} />
  </div>
);

export const RouteLeavingContent: FC<RouteLeavingContentProps> = ({ body }) => (
  <BigidHeading4 data-aid="DataSourceConfCloseModalBody" className={useStyles({}).textWrapper}>
    {body}
  </BigidHeading4>
);
