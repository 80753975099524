import { useLayoutEffect, useState } from 'react';
import { CredentialTypes } from '../types';
import { CustomApps } from '../../ApplicationsManagement/applicationManagementService';
import { getAppInstances } from '../../CustomApp/utils/CustomAppUtils';
import { customAppService } from '../../../services/customAppService';
import { BigidDropdownOption } from '@bigid-ui/components';

interface UseInstances {
  credentialType: CredentialTypes;
  allApplications: CustomApps[];
  selectedAppId: string;
}

export const useGetMetadataForApplicationCredential = ({
  credentialType,
  allApplications,
  selectedAppId,
}: UseInstances) => {
  const [instances, setInstances] = useState<CustomApps[]>([]);
  const [globalPresets, setGlobalPresets] = useState<BigidDropdownOption[]>([]);

  useLayoutEffect(() => {
    const fetchInstances = async () => {
      try {
        const selectedApp = allApplications.find(({ _id }) => _id === selectedAppId);
        const {
          data: { data },
        } = await customAppService.getAppGlobalPresets(selectedApp._id);
        setGlobalPresets(data.map(({ name, _id }) => ({ displayValue: name, value: _id, id: _id })));
        setInstances(getAppInstances(selectedApp, allApplications));
      } catch (e) {
        console.error('Error fetching instances or global presets:', e);
      }
    };

    if (credentialType === CredentialTypes.CustomApplicationVault && selectedAppId) {
      fetchInstances();
    }
  }, [credentialType, allApplications, selectedAppId]);

  return { instances, globalPresets };
};
