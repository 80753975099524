import { analyticsService } from '../../services/analyticsService';

export const trackDsar = (eventType: string, eventData?: Record<string, unknown>) => {
  analyticsService.trackManualEvent(eventType, eventData);
};

export enum DsarSettingsTrackingEvents {
  // Objects page
  PROFILE_OBJECTS_INCLUDE_ACTION = 'SUBJECTACCESSREQUEST.PROFILE_OBJECTS_INCLUDE_ACTION',
  PROFILE_OBJECTS_EXCLUDE_ACTION = 'SUBJECTACCESSREQUEST.PROFILE_OBJECTS_EXCLUDE_ACTION',
  PROFILE_OBJECTS_COLUMNS_INCLUDE_ACTION = 'SUBJECTACCESSREQUEST.PROFILE_OBJECTS_COLUMNS_INCLUDE_ACTION',
  PROFILE_OBJECTS_COLUMNS_EXCLUDE_ACTION = 'SUBJECTACCESSREQUEST.PROFILE_OBJECTS_COLUMNS_EXCLUDE_ACTION',
  PROFILE_OBJECTS_COLUMNS_INCLUDE_INLINE_ACTION = 'SUBJECTACCESSREQUEST.PROFILE_OBJECTS_COLUMNS_INCLUDE_INLINE_ACTION',
  PROFILE_OBJECTS_COLUMNS_EXCLUDE_INLINE_ACTION = 'SUBJECTACCESSREQUEST.PROFILE_OBJECTS_COLUMNS_EXCLUDE_INLINE_ACTION',
  PROFILE_OBJECTS_COLUMNS_ATTRIBUTE_MANAGEMENT_INLINE_ACTION = 'SUBJECTACCESSREQUEST.PROFILE_OBJECTS_COLUMNS_ATTRIBUTE_MANAGEMENT_INLINE_ACTION',
  PROFILE_OBJECTS_COLUMNS_ATTRIBUTE_MANAGEMENT_SAVE_ACTION = 'SUBJECTACCESSREQUEST.PROFILE_OBJECTS_COLUMNS_ATTRIBUTE_MANAGEMENT_SAVE_ACTION',
  PROFILE_OBJECTS_COLUMNS_ATTRIBUTE_MANAGEMENT_CANCEL_ACTION = 'SUBJECTACCESSREQUEST.PROFILE_OBJECTS_COLUMNS_ATTRIBUTE_MANAGEMENT_CANCEL_ACTION',

  // Fields page
  PROFILE_FIELDS_INCLUDE_ACTION = 'SUBJECTACCESSREQUEST.PROFILE_FIELDS_INCLUDE_ACTION',
  PROFILE_FIELDS_EXCLUDE_ACTION = 'SUBJECTACCESSREQUEST.PROFILE_FIELDS_EXCLUDE_ACTION',
  PROFILE_FIELDS_IMPORT_ACTION = 'SUBJECTACCESSREQUEST.PROFILE_FIELDS_IMPORT_ACTION',
  PROFILE_FIELDS_EXPORT_ACTION = 'SUBJECTACCESSREQUEST.PROFILE_FIELDS_EXPORT_ACTION',
  PROFILE_FIELDS_SYNC_ACTION = 'SUBJECTACCESSREQUEST.PROFILE_FIELDS_SYNC_ACTION',
  PROFILE_FIELDS_EDIT_ACTION = 'SUBJECTACCESSREQUEST.PROFILE_FIELDS_EDIT_ACTION',
  PROFILE_FIELDS_EDIT_CANCEL_ACTION = 'SUBJECTACCESSREQUEST.PROFILE_FIELDS_EDIT_CANCEL_ACTION',
  PROFILE_FIELDS_EDIT_SAVE_ACTION = 'SUBJECTACCESSREQUEST.PROFILE_FIELDS_EDIT_SAVE_ACTION',
  PROFILE_FIELDS_EDIT_CELL_DIALOG_CANCEL_ACTION = 'SUBJECTACCESSREQUEST.PROFILE_FIELDS_EDIT_CELL_DIALOG_CANCEL_ACTION',
  PROFILE_FIELDS_EDIT_CELL_DIALOG_SAVE_ACTION = 'SUBJECTACCESSREQUEST.PROFILE_FIELDS_EDIT_CELL_DIALOG_SAVE_ACTION',

  // Manual Records page
  PROFILE_MANUAL_RECORDS_INCLUDE_ACTION = 'SUBJECTACCESSREQUEST.PROFILE_MANUAL_RECORDS_INCLUDE_ACTION',
  PROFILE_MANUAL_RECORDS_EXCLUDE_ACTION = 'SUBJECTACCESSREQUEST.PROFILE_MANUAL_RECORDS_EXCLUDE_ACTION',
  PROFILE_MANUAL_RECORDS_INCLUDE_INLINE_ACTION = 'SUBJECTACCESSREQUEST.PROFILE_MANUAL_RECORDS_INCLUDE_INLINE_ACTION',
  PROFILE_MANUAL_RECORDS_EXCLUDE_INLINE_ACTION = 'SUBJECTACCESSREQUEST.PROFILE_MANUAL_RECORDS_EXCLUDE_INLINE_ACTION',
  PROFILE_MANUAL_RECORDS_DELETE_INLINE_ACTION = 'SUBJECTACCESSREQUEST.PROFILE_PROFILE_MANUAL_RECORDS_DELETE_INLINE_ACTION',
  PROFILE_MANUAL_RECORDS_EDIT_ACTION = 'SUBJECTACCESSREQUEST.PROFILE_MANUAL_RECORDS_EDIT_ACTION',
  PROFILE_MANUAL_RECORDS_EDIT_INLINE_ACTION = 'SUBJECTACCESSREQUEST.PROFILE_MANUAL_RECORDS_EDIT_INLINE_ACTION',
  PROFILE_MANUAL_RECORDS_EDIT_CANCEL_ACTION = 'SUBJECTACCESSREQUEST.PROFILE_MANUAL_RECORDS_EDIT_CANCEL_ACTION',
  PROFILE_MANUAL_RECORDS_EDIT_SAVE_ACTION = 'SUBJECTACCESSREQUEST.PROFILE_MANUAL_RECORDS_EDIT_SAVE_ACTION',

  // Dictionary page
  DICTIONARY_UPLOAD_DICTIONARY_ACTION = 'SUBJECTACCESSREQUEST.DICTIONARY_UPLOAD_DICTIONARY_ACTION',
  DICTIONARY_EDIT_ACTION = 'SUBJECTACCESSREQUEST.DICTIONARY_EDIT_ACTION',
  DICTIONARY_EDIT_DIALOG_CANCEL_ACTION = 'SUBJECTACCESSREQUEST.DICTIONARY_EDIT_DIALOG_CANCEL_ACTION',
  DICTIONARY_EDIT_DIALOG_SAVE_ACTION = 'SUBJECTACCESSREQUEST.DICTIONARY_EDIT_DIALOG_SAVE_ACTION',
  DICTIONARY_DELETE_DIALOG_CANCEL_ACTION = 'SUBJECTACCESSREQUEST.DICTIONARY_DELETE_DIALOG_CANCEL_ACTION',
  DICTIONARY_DELETE_DIALOG_SAVE_ACTION = 'SUBJECTACCESSREQUEST.DICTIONARY_DELETE_DIALOG_SAVE_ACTION',
  DICTIONARY_DELETE_ACTION = 'SUBJECTACCESSREQUEST.DICTIONARY_DELETE_ACTION',
  DICTIONARY_DOWNLOAD_ACTION = 'SUBJECTACCESSREQUEST.DICTIONARY_DOWNLOAD_ACTION',
  DICTIONARY_DOWNLOAD_TEMPLATE_ACTION = 'SUBJECTACCESSREQUEST.DICTIONARY_DOWNLOAD_TEMPLATE_ACTION',
}
