import React, { FC, useCallback, useState, useEffect, memo } from 'react';
import { BigidSkeletonGenerator } from '@bigid-ui/components';
import { generateDataAid, generateGuidedTourId } from '@bigid-ui/utils';
import { UseCatalogDiscoveryResponse } from '../../useCatalogDiscovery';
import { BarChartWidget, BarChartWidgetItem } from '../../widgets/BarChartWidget/BarChartWidget';
import { getAggregatedData, GetAggregatedDataPayload, GetAggregatedDataResponse } from '../../catalogDiscoveryService';
import { AggregationItemBase, AggregationType } from '../../catalogDiscoveryTypes';
import { getBarChartWidgetUnknownData } from '../../utils/widgets';
import { BarChartWidgetWrapper } from '../../utils/BarChartWidgetWrapper';
import { useLocalTranslation } from './translations';
import { useFetchDataCancelable } from '../../config/useFetchDataCancelable';
import { barChartWidgetSkeletonConfig } from '../../config/skeleton';
import { TopCategoriesBarChartTooltip } from './TopCategoriesBarChartTooltip';
import { CatalogDiscoveryWidget, topCategoriesColors } from '../../config/widgets';
import { getNTopUniqueAggItemGroups } from '../../utils/common';

export interface TopCategoriesBarChartProps
  extends Pick<UseCatalogDiscoveryResponse, 'query' | 'onWidgetFilterChange' | 'onDataFetchStatusChange' | 'filter'> {
  dataAid?: string;
  dataTourId?: string;
  isPageInitialised: boolean;
}

const topCategoriesToShowCount = 4;

export const TopCategoriesBarChart: FC<TopCategoriesBarChartProps> = memo(
  ({
    dataAid = 'TopCategoriesBarChart',
    dataTourId = 'TopCategoriesBarChart',
    query,
    filter,
    onWidgetFilterChange,
    onDataFetchStatusChange,
    isPageInitialised,
  }) => {
    const { fetchTopCategoriesCancelable } = useFetchDataCancelable();
    const { t } = useLocalTranslation();
    const [aggregationData, setAggregationData] = useState<AggregationItemBase[]>([]);
    const [chartData, setChartData] = useState<BarChartWidgetItem[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const fetchAggregationData = useCallback(async () => {
      try {
        const payload: GetAggregatedDataPayload = {
          filter: query,
          aggregations: [
            {
              aggName: AggregationType.ATTRIBUTE_CATEGORY_EXTENDED,
            },
          ],
        };

        setIsLoading(true);
        onDataFetchStatusChange(CatalogDiscoveryWidget.TOP_CATEGORIES_PIE_CHART, true);

        const { aggregations } = await fetchTopCategoriesCancelable(
          getAggregatedData(payload) as Promise<GetAggregatedDataResponse>,
        );
        const aggregationData = getNTopUniqueAggItemGroups(aggregations?.[0]?.aggData ?? [], topCategoriesToShowCount);
        const data = getBarChartWidgetUnknownData({
          aggregationData,
          type: AggregationType.ATTRIBUTE_CATEGORY,
          filter,
          useCount: 'groupDocCount',
          groupBy: 'aggItemGroup',
          colors: topCategoriesColors,
        });

        setChartData(data);
        setAggregationData(aggregations?.[0]?.aggData ?? []);
      } catch ({ isCanceled, message }) {
        if (!isCanceled) {
          console.error(`An error has occurred: ${message}`);
        }
      } finally {
        setIsLoading(false);
        onDataFetchStatusChange(CatalogDiscoveryWidget.TOP_CATEGORIES_PIE_CHART, false);
      }
    }, [fetchTopCategoriesCancelable, filter, onDataFetchStatusChange, query]);

    const handleChartItemClick = useCallback(
      ({ aggItem, isActive }: BarChartWidgetItem): void => {
        const aggregationItems = aggregationData.filter(({ aggItemGroup }) => aggItemGroup === aggItem.aggItemGroup);

        onWidgetFilterChange(AggregationType.ATTRIBUTE_CATEGORY, aggregationItems, isActive);
      },
      [aggregationData, onWidgetFilterChange],
    );

    useEffect(() => {
      if (isPageInitialised) {
        fetchAggregationData();
      }
    }, [fetchAggregationData, isPageInitialised]);

    return (
      <BarChartWidgetWrapper dataAid={dataAid}>
        {isPageInitialised ? (
          <BarChartWidget
            dataAid={generateDataAid(dataAid, ['widget'])}
            dataTourId={generateGuidedTourId(dataTourId, ['widget'])}
            title={t('title')}
            noDataText={t('noData')}
            data={chartData}
            isBusy={isLoading}
            tooltipText={<TopCategoriesBarChartTooltip />}
            onItemClick={onWidgetFilterChange ? handleChartItemClick : undefined}
            itemNameWidth="30%"
          />
        ) : (
          <BigidSkeletonGenerator dataAid={generateDataAid(dataAid, ['skeleton'])} {...barChartWidgetSkeletonConfig} />
        )}
      </BarChartWidgetWrapper>
    );
  },
  (prevProps, newProps) =>
    prevProps.query === newProps.query && prevProps.isPageInitialised === newProps.isPageInitialised,
);
