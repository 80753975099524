import './generalSettings.component.scss';
import '../../react/views/GeneralSettings/Licenses/Licenses';
import { $state } from '../../react/services/angularServices';
import template from './generalSettings.component.html';
import { licenseService, LICENSE_TAB } from '../../react/services/licenseService';
import { GENERAL_SETTINGS_PERMISSIONS } from '@bigid/permissions';
import { isPermitted } from '../../react/services/userPermissionsService';
import '../../react/views/GeneralSettings/BusinessGlossary/BusinessGlossaryLazy';
import { module } from 'angular';
import { getApplicationPreference } from '../../react/services/appPreferencesService';
const app = module('app');

export const DEFAULT_ACTIVE_TAB = 'businessGlossary';

app.component('generalSettings', {
  template,
  controller: function ($rootScope, $scope, $translate, $stateParams) {
    'ngInject';

    const TRANSLATION_REQUIRED = [
      'GENERAL_SETTINGS',
      'GENERAL_SETTINGS:TABS:BUSINESS_GLOSSARY',
      'GENERAL_SETTINGS:TABS:EMAIL_SETUP',
      'GENERAL_SETTINGS:TABS:IGNORED_LIST',
      'GENERAL_SETTINGS:TABS:LICENSE',
    ];

    const showLicenseTabOnly = licenseService.shouldBlockUserWithLicenseExpired();

    this.tabs = {};
    this.currentTab = '';
    this.dataLoading = false;

    const addToTabsIfPermitted = (tab, translation) => {
      if (this.tabPermissions[tab]) {
        this.tabs[tab] = translation;
      }
    };

    const setSelectedTabIfPermitted = (tabs, setWithFirstPermittedTab = false) => {
      const tabToSet = tabs.find(tab => this.tabPermissions[tab]);
      if (tabToSet) {
        this.currentTab = tabToSet;
      } else if (setWithFirstPermittedTab) {
        this.currentTab = Object.keys(this.tabPermissions).find(key => this.tabPermissions[key]);
      }
    };

    this.$onInit = () => {
      this.isShowOldBusinessGlossaryPage = getApplicationPreference('SHOW_OLD_BUSINESS_GLOSSARY_PAGE');

      this.tabPermissions = {
        businessGlossary: isPermitted(GENERAL_SETTINGS_PERMISSIONS.READ_BUSINESS_GLOSSARY.name),
        emailSetup: isPermitted(GENERAL_SETTINGS_PERMISSIONS.READ_EMAIL_SETUP.name),
        ignoredList: isPermitted(GENERAL_SETTINGS_PERMISSIONS.READ_IGNORED_LISTS.name),
        [LICENSE_TAB]: isPermitted(GENERAL_SETTINGS_PERMISSIONS.READ_LICENSE.name),
      };

      $translate(TRANSLATION_REQUIRED).then(translations => {
        const pageTitle = translations['GENERAL_SETTINGS'];
        $rootScope.pageHeaderService.setTitle({
          pageTitle,
          showBackButton: $stateParams.showBackButton,
        });

        if (showLicenseTabOnly) {
          addToTabsIfPermitted(LICENSE_TAB, translations['GENERAL_SETTINGS:TABS:LICENSE']);
          setSelectedTabIfPermitted([LICENSE_TAB]);
        } else {
          addToTabsIfPermitted('businessGlossary', translations['GENERAL_SETTINGS:TABS:BUSINESS_GLOSSARY']);
          addToTabsIfPermitted('emailSetup', translations['GENERAL_SETTINGS:TABS:EMAIL_SETUP']);
          addToTabsIfPermitted('ignoredList', translations['GENERAL_SETTINGS:TABS:IGNORED_LIST']);
          addToTabsIfPermitted(LICENSE_TAB, translations['GENERAL_SETTINGS:TABS:LICENSE']);

          setSelectedTabIfPermitted([$stateParams.currentTab, DEFAULT_ACTIVE_TAB], true);
        }
      });
    };

    this.onComponentDataLoading = () => {
      this.dataLoading = true;
    };

    this.onComponentDataLoaded = () => {
      $scope.$evalAsync(() => {
        this.dataLoading = false;
      });
    };

    this.onTabSelected = name => $state.go('generalSettings', { currentTab: name });
  },
});
