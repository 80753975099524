import React, { FC } from 'react';
import { TertiaryButton } from '@bigid-ui/components';
import {
  BigidSuggestedActionBoxAction,
  BigidSuggestedActionBoxToolbarAction,
} from '../../../../components/BigidSuggestedActionBox/BigidSuggestedActionBox';
import { DataSourceSuggestedActionItem, DataSourceSuggestedActionItemProps } from '../DataSourceSuggestedActionItem';
import { DataSourceSuggestedActionItemFooter } from '../DataSourceSuggestedActionItemFooter';
import { generateDataAid } from '@bigid-ui/utils';
import {
  SuggestedAction,
  SuggestedActionButtonType,
  SuggestedActionType,
  ToolbarAction,
  ActionInitiativeAggregate,
  InitiativeType,
} from '../types';
import { BigidExternalLinkIcon } from '@bigid-ui/icons';

type ActionHandler = (action: SuggestedAction & { initiativeLabel: string; count: number }) => void;

const DEFAULT_INITIATIVE_COMPONENT = DataSourceSuggestedActionItem;
const DEFAULT_INITIATIVE_TOOLTIP_LABEL: string = null;

const initiativeTypeToSuggestedActionComponentMapper: Record<string, FC<DataSourceSuggestedActionItemProps>> = {};
const initiativeTypeToTooltipLabelMapper: Record<string, string> = {};

export const mapInitiativeTypeToSuggestedActionItem = (initiativeType: string) =>
  initiativeTypeToSuggestedActionComponentMapper[initiativeType] ?? DEFAULT_INITIATIVE_COMPONENT;

export const mapSuggestedActionToSuggestedActionButtonType = (
  actions: SuggestedAction[],
  initiativeLabel: string,
  count: number,
  t: (key: string, ...args: any[]) => string,
  onAction: ActionHandler,
): SuggestedActionButtonType[] =>
  actions.map(({ actionLabel, isEnabled, actionType, ...rest }) => ({
    text: t(`buttons.${actionLabel}`),
    dataAid: generateDataAid('BigidActionSidebarActionButton', [actionLabel]),
    component: TertiaryButton,
    endIcon: actionType === SuggestedActionType.REDIRECT && <BigidExternalLinkIcon />,
    disabled: !isEnabled,
    onClick: () => onAction({ initiativeLabel, actionLabel, actionType, isEnabled, count, ...rest }),
  }));

// @info add default initiative tooltip until further stated
export const mapInitiativeTypeToTooltipLabel = (initiativeType: string) =>
  initiativeTypeToTooltipLabelMapper[initiativeType] ?? DEFAULT_INITIATIVE_TOOLTIP_LABEL;

export const mapActionInitiativeToBigidSuggestedActionBoxActions = (
  actions: ActionInitiativeAggregate[],
  toolbarConfig: (initiative: ActionInitiativeAggregate) => BigidSuggestedActionBoxToolbarAction[],
  selected: string,
  t: (key: string, ...args: any[]) => string,
  onAction: ActionHandler,
): BigidSuggestedActionBoxAction[] => {
  return actions.map(({ initiativeLabel, actions, count, type, ...rest }) => ({
    id: initiativeLabel,
    dataAid: generateDataAid('BigidSuggestedActionBoxAction', [initiativeLabel]),
    label: t(`initiative.${initiativeLabel}.title`, { count }),
    showIndicator: type === InitiativeType.OPEN_ISSUE,
    actions: toolbarConfig({ initiativeLabel, actions, count, type, ...rest })?.map(props => {
      const { id, ...rest } = props;
      const isFilterActive = id === ToolbarAction.FILTER && selected === initiativeLabel;

      return isFilterActive
        ? {
            style: { backgroundColor: 'var(--bigid-tokens-bigid-backgroundHoverTertiary)' },
            id,
            ...rest,
          }
        : { ...props };
    }),
    footer: () => {
      const buttons = mapSuggestedActionToSuggestedActionButtonType(actions, initiativeLabel, count, t, onAction);

      return <DataSourceSuggestedActionItemFooter actions={buttons} />;
    },
    content: () => {
      const Component = mapInitiativeTypeToSuggestedActionItem(initiativeLabel);
      const tooltip = mapInitiativeTypeToTooltipLabel(initiativeLabel);

      return (
        <Component
          dataAid={generateDataAid('BigidSuggestedActionBoxActionContent', [initiativeLabel])}
          tooltip={tooltip && t(tooltip, { count })}
          label={t(`initiative.${initiativeLabel}.content`, { count })}
          actions={mapSuggestedActionToSuggestedActionButtonType(actions, initiativeLabel, count, t, onAction)}
        />
      );
    },
  }));
};
