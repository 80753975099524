import { BigidDropdownOption, BigidFieldFilterOperator } from '@bigid-ui/components';
import { queryService } from '../../../../services/queryService';
import { EmailTemplateType } from '../../../../components/EmailEditor/emailEditorService';
import { notificationService } from '../../../../services/notificationService';
import { getEmailTemplates } from '../../../EmailTemplates/EmailTemplates/emailTemplatesService';
import { userPreferencesService } from '../../../../services/userPreferencesService';
import { getBrands } from '../../../EmailTemplates/Brands/brandsService';

export const DATA_SOURCE_COLLABORATION_PREFERENCES = 'dataSourceCollaboration';
export const SYSTEM_TEMPLATE_VALUE = 'systemTemplate';
export const BIGID_BRAND_LABEL = 'BigID';
export const BIGID_BRAND_VALUE = 'bigid';

export interface DsCollaborationUserPreferences {
  lastTemplateSelected?: string;
  lastBrandSelected?: string;
}

export const getEmailTemplatesOptions = async (): Promise<BigidDropdownOption[]> => {
  try {
    const query = queryService.getGridConfigQuery({
      filter: [
        {
          field: 'type',
          operator: 'equal' as BigidFieldFilterOperator,
          value: EmailTemplateType.COLLABORATOR_INVITATION,
        },
      ],
    });
    const emailTemplates = await getEmailTemplates(query);
    return emailTemplates.map(template => {
      return {
        id: template._id,
        value: template.isSystem ? SYSTEM_TEMPLATE_VALUE : template.name,
        displayValue: template.name,
      };
    });
  } catch {
    notificationService.error('Error during fetching email templates data');
  }
};

export const getBrandsOptions = async (): Promise<BigidDropdownOption[]> => {
  try {
    const defaultBrand: BigidDropdownOption = {
      id: BIGID_BRAND_VALUE,
      value: BIGID_BRAND_VALUE,
      displayValue: BIGID_BRAND_LABEL,
    };

    const brands = await getBrands();
    const fetchedBrandsOptions = brands.map(brand => {
      return {
        id: brand.name,
        value: brand.name,
        displayValue: brand.name,
      };
    });
    return [defaultBrand, ...fetchedBrandsOptions];
  } catch {
    notificationService.error('Error during fetching brands data');
  }
};

export const getDsCollaborationUserPreferences = async () => {
  return await userPreferencesService.get<DsCollaborationUserPreferences>(DATA_SOURCE_COLLABORATION_PREFERENCES);
};

export const updateDsCollaborationUserPreferences = (updatedPref: DsCollaborationUserPreferences) => {
  userPreferencesService.update({
    preference: DATA_SOURCE_COLLABORATION_PREFERENCES,
    data: updatedPref,
  });
};
