import { BigidFormField } from '@bigid-ui/components';
import React, { FC, useMemo } from 'react';
import { FormSection, FormSectionInitialStateProps } from '../FormSection/FormSection';
import { useReportTemplateContext } from '../hooks/ReportTemplateContext';
import { FormRichTextField } from '../../../../views/GeneralSettings/BusinessGlossary/BusinessGlossaryDialog/FormRichTextField';

export const RightsSection: FC = () => {
  const sectionName = 'rights';
  const description = '';

  const { initialTemplateData } = useReportTemplateContext();

  const formSectionInitProps: FormSectionInitialStateProps = useMemo(() => {
    const {
      rights: { ...initialValues },
    } = initialTemplateData;

    const fields: BigidFormField[] = [
      {
        render: FormRichTextField,
        name: 'dataSubjectRights',
        isRequired: false,
        label: '',
        misc: {
          fullWidth: true,
        },
      },
    ];
    const newInitProps: FormSectionInitialStateProps = {
      fields,
      initialValues,
    };

    return newInitProps;
  }, [initialTemplateData]);

  return <FormSection sectionName={sectionName} description={description} {...formSectionInitProps} />;
};
