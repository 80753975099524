import React, { FC } from 'react';
import { BigidDialog, PrimaryButton, SecondaryButton, BigidDialogProps } from '@bigid-ui/components';
import { Typography } from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';

export interface ResolveDialogProps extends Pick<BigidDialogProps, 'isOpen' | 'onClose'> {
  tasksAmount: number;
  onResolve: () => void;
}

const useStyles = makeStyles({
  title: {
    marginBottom: 8,
  },
});

export const ResolveDialog: FC<ResolveDialogProps> = ({ isOpen, onClose, tasksAmount, onResolve }) => {
  const classes = useStyles({});

  const buttons = [
    { component: SecondaryButton, text: 'Cancel', onClick: onClose },
    {
      component: PrimaryButton,
      text: 'Resolve',
      onClick: () => {
        onResolve();
        onClose();
      },
      dataAid: 'resolve-dialog-task-button',
    },
  ];

  return (
    <BigidDialog buttons={buttons} isOpen={isOpen} onClose={onClose} title="Resolve">
      <Typography variant={'subtitle1'} className={classes.title}>
        {tasksAmount > 1
          ? `Are you sure you want to resolve these ${tasksAmount} tasks?`
          : `Are you sure you want to resolve this task?`}
      </Typography>
    </BigidDialog>
  );
};
