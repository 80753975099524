import React, { FC, memo } from 'react';
import { TertiaryButton } from '@bigid-ui/components';
import { BigidDashboardIcon } from '@bigid-ui/icons';
import { $state } from '../../../../../services/angularServices';
import { CONFIG } from '../../../../../../config/common';
import { useLocalTranslation } from './translations';

export interface GoToInventoryProps {
  dataAid?: string;
}

export const GoToInventory: FC<GoToInventoryProps> = memo(({ dataAid = 'GoToInventory' }) => {
  const { t } = useLocalTranslation();

  const handleGoToInventoryClick = (): void => {
    $state.go(CONFIG.states.INVENTORY);
  };

  return (
    <TertiaryButton
      dataAid={dataAid}
      size="medium"
      startIcon={<BigidDashboardIcon />}
      onClick={handleGoToInventoryClick}
      text={t('text')}
    />
  );
});
