import React, { useState, FC, useCallback } from 'react';
import {
  AsyncOperationProcessingWidget,
  AsyncOperationMap,
} from '../../../components/AsyncOperationProcessingWidget/AsyncOperationProcessingWidget';
import { useCatalogRuleDelete } from './operations/useCatalogRuleDelete';

export const CatalogRulesAsyncOpsProcessingWidget: FC = () => {
  const [runningOperationsMap, setRunningOperationsMap] = useState<AsyncOperationMap>(new Map());

  const onOperationRun = useCallback((sseRoute: string, isOperationRan: boolean) => {
    setRunningOperationsMap(prevRunningOperationsMap => {
      return prevRunningOperationsMap.set(sseRoute, isOperationRan);
    });
  }, []);

  const { operations } = useCatalogRuleDelete({ onOperationRun });

  return (
    <AsyncOperationProcessingWidget
      dataAid="Catalog-rules-processing"
      operations={operations}
      operationsMap={runningOperationsMap}
    />
  );
};
