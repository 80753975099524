import { HeaderActionItem } from '@bigid-ui/components';
import { BigidAppLogoDefaultIcon, BigidAppLogoPrivacyPortalIcon, IconComponentProps } from '@bigid-ui/icons';
import { debounce } from 'lodash';
import { headerEventEmitter, HeaderEvents } from './eventEmitters/headerEvents';
import { httpService } from './httpService';

export interface SusbcriptionResponse {
  products: SubscriptionItem[];
  info_url: string;
}

export interface SubscriptionHeaderItem {
  isLoading: boolean;
  hasError: boolean;
  headerActionItem: HeaderActionItem[];
}

export interface SubscriptionItem {
  category: string;
  code: string;
  description: string;
  icon: string;
  name: string;
  url: string;
}

export interface DrawerItem {
  id: string;
  text: string;
  icon: React.ComponentType<IconComponentProps>;
  link?: string;
  shouldOpenNewTab?: boolean;
  isNew?: boolean;
  badgeText?: string;
  onItemClick?: (id: string) => void;
}

export interface DrawerContent {
  items: DrawerItem[];
  id?: string;
  categoryTitle?: string;
}

let subscriptions: DrawerContent[] = [];
let allServicesLink = '';

export const getSubscriptions = (): DrawerContent[] => subscriptions;
export const getAllServicesLink = (): string => allServicesLink;

const emitLoadingDone = debounce(
  ({ isScroll = false, hasError = false }) =>
    headerEventEmitter.emit(HeaderEvents.UPDATE_CLOUD_SUBSCRIPTIONS, {
      data: { isLoading: false, isScroll, hasError },
    }),
  500,
);

const getSubscriptionIcon = (iconName: string) => {
  switch (iconName) {
    case 'BigidSubscriptionsCookiesIcon':
      return BigidAppLogoDefaultIcon;
    case 'BigidSubscriptionsDevToolsIcon':
      return BigidAppLogoDefaultIcon;
    case 'BigidSubscriptionsPrivacyIcon':
      return BigidAppLogoPrivacyPortalIcon;
    default:
      return BigidAppLogoDefaultIcon;
  }
};

const subscriptionsToDrawerItems = (subscriptions: SubscriptionItem[]): DrawerContent[] => {
  return [
    {
      items: subscriptions.map(subscription => ({
        id: subscription.code,
        text: subscription.name,
        icon: getSubscriptionIcon(subscription.icon),
        link: subscription.url,
        onItemClick: id => console.log('Item clicked', id), // Placeholder for task BCO-4151-events sending
      })),
      id: 'cloudSubscriptions',
    },
  ];
};

const fetchSubscriptions = async () => {
  const auth0Token = sessionStorage.getItem('auth0Token')?.split('"').join('');
  const {
    data: { data },
  } = await httpService.fetch<{ data: SusbcriptionResponse }>(
    `cloud-portal/subscription`,
    { getToken: 'true' },
    { 'auth0-token': auth0Token },
  );

  return data;
};

export const getNewSubscriptions = async () => {
  if (!subscriptions.length) {
    headerEventEmitter.emit(HeaderEvents.UPDATE_CLOUD_SUBSCRIPTIONS, { data: { isLoading: true } });
    try {
      const { products, info_url } = await fetchSubscriptions();
      allServicesLink = info_url;
      subscriptions = subscriptionsToDrawerItems(products);
      emitLoadingDone({ loading: false });
    } catch (error) {
      console.error(`Fetching Cloud Portal subscriptions fail with the following error: `, error);
      emitLoadingDone({ hasError: true });
    }
  }
};

export const getUpdatedSubscriptionsHeaderItem = ({
  isLoading,
  hasError,
  headerActionItem,
}: SubscriptionHeaderItem): HeaderActionItem[] => {
  const subscriptionsIndex = headerActionItem?.findIndex(action => action.itemsDrawerConfiguration);
  const newHeaderItem = [...headerActionItem];
  if (subscriptionsIndex > -1) {
    const currentItemsDrawerConfiguration = newHeaderItem[subscriptionsIndex].itemsDrawerConfiguration;
    if (hasError) {
      newHeaderItem[subscriptionsIndex].itemsDrawerConfiguration = {
        ...currentItemsDrawerConfiguration,
        hasError,
        isLoading: false,
      };
    }
    if (isLoading && !hasError) {
      newHeaderItem[subscriptionsIndex].itemsDrawerConfiguration = {
        ...currentItemsDrawerConfiguration,
        isLoading: true,
        hasError: false,
      };
    } else {
      newHeaderItem[subscriptionsIndex].itemsDrawerConfiguration = {
        ...currentItemsDrawerConfiguration,
        isLoading: false,
        hasError: false,
        content: getSubscriptions(),
        linkProps: {
          href: getAllServicesLink(),
          text: 'See All Services',
          shouldOpenNewTab: false,
        },
      };
    }
  }

  return newHeaderItem;
};
