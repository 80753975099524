import React, { MutableRefObject } from 'react';
import { notificationService } from '../../../../services/notificationService';
import { $state } from '../../../../services/angularServices';
import { DsConnectionFieldSectionsEnum } from '../../DataSourceConfiguration/types';
import { runDefaultScanProfile } from '../services/scan';
import { CONFIG } from '../../../../../config/common';
import { dsModalNotificationService } from '../services/dsModalNotificationService';
import { BigidFormStateAndHandlers, BigidToastNotificationType } from '@bigid-ui/components';
import { DataSourceNotificationSingleAction } from '../DataSourceNotifications/DataSourceNotificationSingleAction';
import { useLocalTranslation } from '../translations';
import { DataSourcesUITrackingEvent, trackEventDataSources } from '../../DataSourcesEventTrackerUtils';

type UseDataSourceModalNotificationProps = {
  sourceId?: string;
  source: string;
  control?: MutableRefObject<BigidFormStateAndHandlers>;
};
export const useDataSourceModalNotification = ({ sourceId, source, control }: UseDataSourceModalNotificationProps) => {
  const { t } = useLocalTranslation('notifications');

  const handleScanFromNotification = () => {
    trackEventDataSources(DataSourcesUITrackingEvent.DATA_SOURCES_NEW_CONNECTION_TOAST_NOTIFICATION_SCAN_NOW_CLICK, {
      dsType: source,
    });
    const dsName = sourceId || control?.current?.getValues()?.name;
    runDefaultScanProfile(dsName, source, {
      onSuccess: () => displayScanStartedNotification(),
      onError: () => displayScanFailedNotification(),
    });
  };
  const handleEditConnectionFromNotification = (shouldOpenNewModal = false) => {
    trackEventDataSources(
      DataSourcesUITrackingEvent.DATA_SOURCES_NEW_CONNECTION_TOAST_NOTIFICATION_VIEW_DETAILS_CLICK,
      {
        dsType: source,
      },
    );
    const dsName = sourceId || control?.current?.getValues()?.name;
    $state.go(CONFIG.states.CONFIG_DATA_SOURCE, {
      id: dsName,
      isAfterSave: true,
      wizardStep: DsConnectionFieldSectionsEnum.connection,
      shouldOpenNewModal,
    });
  };

  const emitTestConnectionSucceeded = () => {
    const dsName = sourceId || control?.current?.getValues()?.name;
    dsModalNotificationService.emitNotification(dsName, {
      type: BigidToastNotificationType.SUCCESS,
      message: '',
      options: {
        shouldCloseOnTransition: false,
        contentElement: (
          <DataSourceNotificationSingleAction
            translationKey="testConnectionSuccess"
            onAction={handleScanFromNotification}
            dsName={dsName}
          />
        ),
      },
    });
  };

  const emitTestConnectionError = () => {
    const dsName = sourceId || control?.current?.getValues()?.name;
    dsModalNotificationService.emitNotification(dsName, {
      type: BigidToastNotificationType.ERROR,
      message: '',
      options: {
        shouldCloseOnTransition: false,
        contentElement: (
          <DataSourceNotificationSingleAction
            translationKey="testConnectionError"
            onAction={() => {
              handleEditConnectionFromNotification(true);
            }}
            dsName={dsName}
          />
        ),
      },
    });
  };

  const emitSaveDataSourceError = (error?: string) => {
    notificationService.error('', {
      title: t(error ? 'saveFailed.extended' : 'saveFailed.title', { error }),
      shouldCloseOnTransition: false,
    });
  };

  const displayTestConnectionInProgressNotification = () => {
    const dsName = sourceId || control?.current?.getValues()?.name;
    notificationService.info(t('testConnectionInProgress.message'), {
      title: t('testConnectionInProgress.title', { dsName }),
      shouldCloseOnTransition: false,
    });
  };

  const displayScanStartedNotification = () => {
    const dsName = sourceId || control?.current?.getValues()?.name;
    notificationService.info('', { title: t('scanStarted.title', { dsName }), shouldCloseOnTransition: false });
  };

  const displayScanFailedNotification = () => {
    const dsName = sourceId || control?.current?.getValues()?.name;
    notificationService.error(t('scanFailed.message'), {
      title: t('scanFailed.title', { dsName }),
      shouldCloseOnTransition: false,
    });
  };

  const displaySaveSucceededNotification = () => {
    const dsName = sourceId || control?.current?.getValues()?.name;
    notificationService.success('', {
      title: t('saveSuccess.title', { dsName }),
      shouldCloseOnTransition: false,
    });
  };

  return {
    emitTestConnectionSucceeded,
    emitTestConnectionError,
    emitSaveDataSourceError,
    displayScanFailedNotification,
    displayScanStartedNotification,
    displayTestConnectionInProgressNotification,
    displaySaveSucceededNotification,
  };
};
