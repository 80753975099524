import React, { useState, FC, ReactNode, ChangeEvent, useEffect, useRef, useCallback } from 'react';
import styled from '@emotion/styled';
import {
  BigidDialog,
  SecondaryButton,
  PrimaryButton,
  BigidTooltip,
  BigidColorsV2,
  BigidTextField,
  TertiaryButton,
  BigidDropdown,
  BigidDropdownOption,
  entityEventsEmitter,
  EntityEvents,
  BigidDropdownNewOptionValidation,
} from '@bigid-ui/components';
import { BigidGridQueryComponents } from '@bigid-ui/grid';
import {
  LegalEntitiesEditDialogProps,
  LegalEntitiesCreateUpdateType,
  LegalEntityLevel,
  LegalEntitiesInterface,
  MultiValueEntity,
  NewlyAddedOptionsType,
} from './LegalEntitiesTypes';
import { LegalEntitiesTrackingEvents, trackLegalEntities } from './utils/analytics';
import { FormLabel } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { BigidAddIcon, BigidDefaultIcon, BigidInfoSmallIcon, IconMatcher } from '@bigid-ui/icons';
import { applicationSetupService } from '../../../administration/applicationSetup/applicationSetup.service';
import { notificationService } from '../../services/notificationService';
import { ApplicationEntity } from '../ApplicationSetup/types';
import { getCountries } from '../DSAR/dsarService';
import { getLevels, createLegalEntity, updateLegalEntity, getLegalEntities, createAsset } from './LegalEntitiesService';

const FormSectionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 6px, 16px, 24px, 16px;
  justify-content: space-between;
  gap: 16px;
`;

const LabelInputGroupWrapper = styled('div')<{ fullWidth?: boolean }>(({ fullWidth = true }) => ({
  display: 'flex',
  width: fullWidth ? '100%' : 'calc(50% - 8px)',
  flexDirection: 'column',
  justifyContent: 'space-between',
}));

const FlexWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
`;

const AddIconWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 4px;
`;

const useStyles = makeStyles(() => ({
  asterisk: {
    color: BigidColorsV2.red[600],
    width: '10px',
    display: 'inline-block',
    textAlign: 'center',
  },
  flexWrapper: {
    display: 'flex',
  },
}));

type FormState = Record<FormNames, unknown>;

enum FormNames {
  name = 'name',
  description = 'description',
  level = 'level',
  assets = 'assets',
  parent = 'parent',
  operatingLocations = 'operatingLocations',
  mainContactName = 'mainContactName',
  mainContactEmail = 'mainContactEmail',
}

const initialFormState = Object.values(FormNames).reduce(
  (state, key) => ({ ...state, [key]: undefined }),
  {} as FormState,
);

const APIS_COUNT = 4;

export const LegalEntitiesEditDialog: FC<LegalEntitiesEditDialogProps> = ({
  isEditDialogOpen,
  isNewLegalEntity,
  isNewSubEntity = false,
  onLegalEntitiesEditDialogClose,
  onLegalEntitiesEditDialogSave,
  legalEntityDetails,
}) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [errors, setErrors] = useState([]);
  const [formState, setFormState] = useState<FormState>(initialFormState);
  const [fetchedApis, setFetchedApis] = useState<number>(isNewLegalEntity ? 1 : 0);
  const [newOptionsCreated, setNewOptionsCreated] = useState<NewlyAddedOptionsType>({
    updatedParent: false,
    addedNewAssets: false,
  });
  const topLevelRef = useRef<string>(null);
  const isDataReady = fetchedApis === APIS_COUNT;

  useEffect(() => {
    if (!legalEntityDetails || !isDataReady) {
      return;
    }
    const markSelectedOptions = (options: BigidDropdownOption[], newValues: (string | MultiValueEntity)[] = []) => {
      const final = options?.map(option => {
        const values = newValues.map(value => (typeof value === 'string' ? value : value?.id));
        return {
          ...option,
          isSelected: !!values.includes(option.value),
        };
      });
      return final;
    };
    setFormState(prevState => ({
      ...prevState,
      name: legalEntityDetails?.name,
      description: legalEntityDetails?.description,
      parent: markSelectedOptions(prevState.parent as BigidDropdownOption[], [legalEntityDetails?.parentEntity]),
      level: markSelectedOptions(prevState.level as BigidDropdownOption[], [legalEntityDetails.level]),
      assets: markSelectedOptions(prevState.assets as BigidDropdownOption[], legalEntityDetails?.assets),
      operatingLocations: markSelectedOptions(
        prevState.operatingLocations as BigidDropdownOption[],
        legalEntityDetails?.operatingLocations,
      ),
      mainContactName: legalEntityDetails?.mainContactName,
      mainContactEmail: legalEntityDetails?.mainContactEmail,
    }));
  }, [legalEntityDetails, isDataReady]);

  useEffect(() => {
    if (isEditDialogOpen && !isNewSubEntity) {
      fetchDropdownOptions(fetchLevelsOptions, FormNames.level)().then(levels => {
        topLevelRef.current = levels[0].id;
        if (!isNewLegalEntity) {
          const oneIndexAbove = levels.findIndex(level => level.id === legalEntityDetails?.level?.id) - 1;
          oneIndexAbove >= 0
            ? fetchDropdownOptions(
                () => fetchParentsOptions(levels[oneIndexAbove].id, '', legalEntityDetails?.id),
                FormNames.parent,
              )()
            : setFetchedApis(prevState => prevState + 1);
        }
      });
    } else if (isEditDialogOpen && isNewSubEntity) {
      setFormState(prevState => ({
        ...prevState,
        level: [
          {
            displayValue: legalEntityDetails?.level?.name,
            id: legalEntityDetails?.level?.id,
            isSelected: true,
            value: legalEntityDetails?.level?.id,
          },
        ],
        parent: [
          {
            displayValue: legalEntityDetails?.parentEntity?.name,
            id: legalEntityDetails?.parentEntity?.id,
            isSelected: true,
            value: legalEntityDetails?.parentEntity?.id,
          },
        ],
      }));
      setFetchedApis(prevState => prevState + 2);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEditDialogOpen, isNewLegalEntity, isNewSubEntity]);

  const getSelectedLevelValue = () => getDropdownValue(FormNames.level)[0]?.value;
  const isNewAssetValid = (value: string): Promise<BigidDropdownNewOptionValidation> => {
    const trimmedValue = value.trim();
    const isInValidValue = !trimmedValue || /[!@#$%^&*()\\=+]/gi.test(trimmedValue);
    if (isInValidValue) {
      notificationService.error(
        `Failed to create new asset. Asset name cannot be blank or contain special characters: !@#$%^&*()=+`,
      );
    }
    return Promise.resolve({
      isValid: !isInValidValue,
      message: 'Invalid Asset',
    });
  };
  const fetchDropdownOptions =
    (callback: (searchItem?: string, level?: string) => Promise<BigidDropdownOption[]>, formField: FormNames) =>
    async (searchItem?: string, level?: string) => {
      try {
        const options = await callback(searchItem, level);
        if (topLevelRef.current === null && formField === FormNames.level) {
          topLevelRef.current = options[0].id;
        }
        handleChange(formField, options);
        return options;
      } catch (error) {
        return [];
      } finally {
        setFetchedApis(prev => prev + 1);
      }
    };

  const handleOnClose = () => {
    onLegalEntitiesEditDialogClose(newOptionsCreated);
    setFormState(initialFormState);
    setIsLoading(false);
    setErrors([]);
    setFetchedApis(0);
    setNewOptionsCreated({
      addedNewAssets: false,
      updatedParent: false,
    });
    trackLegalEntities(
      isNewSubEntity
        ? LegalEntitiesTrackingEvents.CREATE_SUB_ENTITY_DIALOG_CANCEL_CLICK
        : isNewLegalEntity
        ? LegalEntitiesTrackingEvents.CREATE_LEGAL_ENTITY_DIALOG_CANCEL_CLICK
        : LegalEntitiesTrackingEvents.EDIT_LEGAL_ENTITY_DIALOG_CANCEL_CLICK,
    );
    topLevelRef.current = null;
  };
  const handleOnSave = async () => {
    try {
      const selectedOptionsState = filterSelectedOptions(formState);
      const invalidFields = validate(selectedOptionsState);
      if (invalidFields.length) {
        setErrors(
          invalidFields.map(field => ({
            fieldName: field,
            message: defaultErrorMessage,
          })),
        );
        return;
      }
      if (selectedOptionsState?.mainContactEmail) {
        const isInvalidEmailValid = validateEmail((selectedOptionsState?.mainContactEmail as string) || '');
        isInvalidEmailValid &&
          setErrors(prev => [...prev, { fieldName: FormNames.mainContactEmail, message: isInvalidEmailValid }]);
        if (isInvalidEmailValid) {
          return;
        }
      }
      const state: FormState = Object.keys(selectedOptionsState).reduce((acc, key) => {
        const currentValue = selectedOptionsState[key as keyof FormState];
        return {
          ...acc,
          [key]: ([undefined, null, ''] as Array<unknown>).includes(currentValue)
            ? isNewLegalEntity || isNewSubEntity
              ? undefined
              : null
            : currentValue,
        };
      }, {} as FormState);
      const getOptionsValue = (options: BigidDropdownOption[] = []) => options?.map(option => option.value);
      const getParentOptionValue = (options: BigidDropdownOption[] = []) => {
        const value = getOptionsValue(options)?.[0];
        return value === undefined ? (isNewLegalEntity || isNewSubEntity ? undefined : null) : value;
      };
      const getInputString = (value: string) =>
        value?.trim() ? value.trim() : isNewLegalEntity || isNewSubEntity ? undefined : null;
      const payload: LegalEntitiesCreateUpdateType = {
        name: getInputString(state.name as string),
        description: getInputString(state.description as string),
        levelId: getOptionsValue(state.level as BigidDropdownOption[])?.[0],
        parentId: getParentOptionValue(state.parent as BigidDropdownOption[]),
        assetIds: getOptionsValue(state.assets as BigidDropdownOption[]),
        operatingLocations: getOptionsValue(state.operatingLocations as BigidDropdownOption[]),
        mainContactName: getInputString(state.mainContactName as string),
        mainContactEmail: getInputString(state.mainContactEmail as string),
      };
      setIsLoading(true);
      if (isNewLegalEntity || isNewSubEntity) {
        await createLegalEntity(payload);
        trackLegalEntities(
          isNewSubEntity
            ? LegalEntitiesTrackingEvents.CREATE_SUB_ENTITY_DIALOG_ADD_CLICK
            : LegalEntitiesTrackingEvents.CREATE_LEGAL_ENTITY_DIALOG_ADD_CLICK,
        );
      } else {
        await updateLegalEntity(legalEntityDetails.id, payload);
        trackLegalEntities(LegalEntitiesTrackingEvents.EDIT_LEGAL_ENTITY_DIALOG_ADD_CLICK);
      }
      onLegalEntitiesEditDialogSave();
      handleOnClose();
    } catch (err) {
      const errorMessage = `Failed to ${isNewLegalEntity ? 'create' : 'update'} Legal Entity "${formState.name}"`;
      notificationService.error(errorMessage);
      console.error(`${errorMessage}: ${JSON.stringify(err)}`);
      if (err?.response?.data?.errors?.length) {
        setErrors(err?.response?.data?.errors);
      } else if (err?.response?.data?.status === 'error' && err?.response?.data?.message) {
        setErrors([{ fieldName: 'generic', message: err?.response?.data?.message }]);
      }
      setIsLoading(false);
    }
  };
  const getErrorMessage = (prop: keyof FormState) => {
    const error = errors.find(error => error.fieldName === prop);
    return error ? error.message || defaultErrorMessage : '';
  };
  const handleDropdownChange = (formField: FormNames) => (values: BigidDropdownOption[]) => {
    const ids = values.map(val => val.id);
    const toSelected = (val: BigidDropdownOption) => ({
      ...val,
      isSelected: ids.includes(val.id),
    });
    setFormState(state => ({
      ...state,
      [formField]: [
        ...((state[formField] as BigidDropdownOption[]) || []).map(toSelected),
        ...values.filter(val => val.isNew).map(toSelected),
      ],
    }));
    setErrors([]);
  };

  const onInputChange = (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>, formField: FormNames) => {
    setFormState(state => ({ ...state, [formField]: event.target.value }));
    setErrors([]);
  };
  const handleChange = (formField: FormNames, value: unknown) => {
    setFormState(state => ({ ...state, [formField]: value }));
  };

  const getDropdownValue = (fieldName: FormNames) => {
    return ((formState[fieldName] as BigidDropdownOption[]) || []).filter(value => value.isSelected);
  };

  const updateParentsBasedOnLevel = async (formState: FormState, options: BigidDropdownOption[]) => {
    const selectedIndex = (formState.level as BigidDropdownOption[])?.findIndex(option => option.id === options[0].id);
    selectedIndex &&
      selectedIndex > 0 &&
      (await fetchDropdownOptions(
        () =>
          fetchParentsOptions(
            (formState[FormNames.level] as BigidDropdownOption[])[selectedIndex - 1]?.value,
            '',
            legalEntityDetails?.id,
          ),
        FormNames.parent,
      )());
  };
  return (
    <BigidDialog
      isOpen={isEditDialogOpen}
      onClose={handleOnClose}
      onExit={handleOnClose}
      borderBottom
      title={isNewLegalEntity ? 'Add Legal Entity' : 'Edit Legal Entity'}
      showCloseIcon
      isLoading={isLoading}
      buttons={[
        {
          component: props => <SecondaryButton {...props} />,
          onClick: handleOnClose,
          text: 'Cancel',
        },
        {
          component: props => <PrimaryButton {...props} />,
          onClick: handleOnSave,
          text: 'Add',
        },
      ]}
    >
      <FormSectionWrapper>
        <LabelInputGroupWrapper>
          <Label text="Name" required />
          <BigidTextField
            placeholder="Enter Name"
            size="large"
            onChange={event => onInputChange(event, FormNames.name)}
            value={(formState.name as string) || ''}
            isError={!!getErrorMessage(FormNames.name)}
            errorMessage={getErrorMessage(FormNames.name)}
          />
          {typeof formState.description === 'undefined' && (
            <AddIconWrapper>
              <TertiaryButton
                onClick={() => {
                  setFormState(state => ({
                    ...state,
                    [FormNames.description]: '',
                  }));
                }}
                size="small"
                startIcon={<BigidAddIcon />}
                text="Add description"
              />
            </AddIconWrapper>
          )}
        </LabelInputGroupWrapper>
        {typeof formState.description !== 'undefined' && (
          <LabelInputGroupWrapper>
            <Label text="Description" />
            <BigidTextField
              placeholder="Entity Description"
              size="large"
              rows={2}
              onChange={event => onInputChange(event, FormNames.description)}
              value={(formState.description as string) || ''}
              errorMessage={''}
              multiline
            />
          </LabelInputGroupWrapper>
        )}
        <FlexWrapper>
          <LabelInputGroupWrapper fullWidth={false}>
            <Label
              text="Level"
              required
              tooltip={
                !legalEntityDetails?.subEntities?.length
                  ? 'Select the level to define its position in the hierarchy'
                  : 'The level cannot be changed for this entity due to existing relationships. Please remove or update the related entities before proceeding further.'
              }
            />
            <BigidDropdown
              placeholder="Select Level"
              isDisabled={isNewSubEntity || !!legalEntityDetails?.subEntities?.length}
              options={(formState.level as BigidDropdownOption[]) || []}
              onSelect={async options => {
                const isTopLevel = topLevelRef.current === options[0].id;
                isTopLevel
                  ? setFormState(prevState => ({
                      ...prevState,
                      [FormNames.parent]: [],
                    }))
                  : updateParentsBasedOnLevel(formState, options);
                return handleDropdownChange(FormNames.level)(options);
              }}
              value={getDropdownValue(FormNames.level)}
              fetchOptions={fetchDropdownOptions(fetchLevelsOptions, FormNames.level)}
              size="large"
              isAsync
              isSearchable
              noOptionsMessage="No levels found"
              defaultServerErrorMessage="An Error occurred while fetching levels"
              errorMessage={getErrorMessage(FormNames.level)}
              isError={!!getErrorMessage(FormNames.level)}
            />
          </LabelInputGroupWrapper>
          {getDropdownValue(FormNames.level) && getSelectedLevelValue() !== topLevelRef.current && (
            <LabelInputGroupWrapper fullWidth={false}>
              <Label
                text="Parent"
                tooltip="Select the parent to establish the parent-child relationship in the hierarchy."
              />
              <BigidDropdown
                placeholder="Select Parent"
                isDisabled={isNewSubEntity}
                options={(formState.parent as BigidDropdownOption[]) || []}
                onSelect={async options => {
                  await handleDropdownChange(FormNames.parent)(options);
                  setNewOptionsCreated(prev => ({
                    ...prev,
                    updatedParent: true,
                  }));
                }}
                value={getDropdownValue(FormNames.parent)}
                fetchOptions={fetchDropdownOptions(searchText => {
                  const oneLevelAboveIndex =
                    ((formState[FormNames.level] as BigidDropdownOption[]) || []).findIndex(value => value.isSelected) -
                    1;
                  return fetchParentsOptions(
                    (formState[FormNames.level] as BigidDropdownOption[])[oneLevelAboveIndex]?.value,
                    searchText,
                    legalEntityDetails?.id,
                  );
                }, FormNames.parent)}
                size="large"
                isSearchable
                isAsync
                noOptionsMessage="No parent found"
                defaultServerErrorMessage="An Error occurred while fetching parents"
              />
            </LabelInputGroupWrapper>
          )}
        </FlexWrapper>
        <FlexWrapper>
          <LabelInputGroupWrapper fullWidth={false}>
            <Label text="Operating Location(s)" />
            <BigidDropdown
              dropDownListHeight={150}
              displayLimit={300}
              placeholder="Select or add location(s)"
              applyOnChange
              options={(formState.operatingLocations as BigidDropdownOption[]) || []}
              onSelect={options => handleDropdownChange(FormNames.operatingLocations)(options)}
              value={getDropdownValue(FormNames.operatingLocations)}
              fetchOptions={fetchDropdownOptions(fetchLocationOptions, FormNames.operatingLocations)}
              size="large"
              isSearchable
              isValueDisplayedAsChips
              isMulti
              hasIconBefore
              isAsync
              isSelectAll
              withChipsBar
              shouldLoadInitialOptions
              noOptionsMessage="No locations found"
              defaultServerErrorMessage="An error occurred while fetching locations"
            />
          </LabelInputGroupWrapper>
          <LabelInputGroupWrapper fullWidth={false}>
            <Label text="Assets" />
            <BigidDropdown
              placeholder="Select"
              dropDownListHeight={120}
              options={(formState.assets as BigidDropdownOption[]) || []}
              onSelect={options => handleDropdownChange(FormNames.assets)(options)}
              value={getDropdownValue(FormNames.assets)}
              fetchOptions={fetchDropdownOptions(fetchAssetsOptions, FormNames.assets)}
              onCreate={async value => {
                const options = await createAssetsOptions(value);
                setNewOptionsCreated(prev => ({
                  ...prev,
                  updatedAssets: true,
                }));
                return options;
              }}
              isCreatable
              isAsync
              isMulti
              withChipsBar
              shouldLoadInitialOptions
              defaultServerErrorMessage="An error has occurred"
              defaultInvalidNewOptionMessage="Invalid new asset"
              size="large"
              isSearchable
              getIsNewOptionValid={isNewAssetValid}
              noOptionsMessage="No assets found"
            />
          </LabelInputGroupWrapper>
        </FlexWrapper>
        <FlexWrapper>
          <LabelInputGroupWrapper fullWidth={false}>
            <Label text="Owner Name" />
            <BigidTextField
              type="text"
              placeholder="Enter owner name"
              size="large"
              onChange={event => onInputChange(event, FormNames.mainContactName)}
              value={(formState.mainContactName as string) || ''}
            />
          </LabelInputGroupWrapper>
          <LabelInputGroupWrapper fullWidth={false}>
            <Label text="Owner Email" />
            <BigidTextField
              type="email"
              placeholder="Enter owner email"
              size="large"
              onChange={event => onInputChange(event, FormNames.mainContactEmail)}
              value={(formState.mainContactEmail as string) || ''}
              isError={!!getErrorMessage(FormNames.mainContactEmail)}
              errorMessage={getErrorMessage(FormNames.mainContactEmail)}
            />
          </LabelInputGroupWrapper>
        </FlexWrapper>
      </FormSectionWrapper>
    </BigidDialog>
  );
};

const defaultErrorMessage = 'Mandatory field';

const requiredFields: Partial<keyof FormState>[] = [FormNames.name, FormNames.level];

const Label = ({
  text,
  tooltip,
  required,
  bold,
}: {
  text: ReactNode;
  tooltip?: string;
  required?: boolean;
  bold?: boolean;
}) => {
  const classes = useStyles();
  return (
    <FormLabel className={classes.flexWrapper}>
      {bold ? <strong>{text}</strong> : text}
      {required && <span className={classes.asterisk}>*</span>}
      {tooltip && (
        <BigidTooltip title={tooltip} placement="top" width="240px">
          <div className={classes.flexWrapper}>
            <BigidInfoSmallIcon />
          </div>
        </BigidTooltip>
      )}
    </FormLabel>
  );
};

const filterSelectedOptions = (formField: FormState) =>
  Object.keys(formField).reduce((acc, prop) => {
    const existingValue = formField[prop as keyof FormState];
    const value = Array.isArray(existingValue) ? existingValue.filter(option => option.isSelected) : existingValue;
    acc[prop as keyof FormState] = value;
    return acc;
  }, {} as FormState);

const validate = (selectedOptions: FormState) => {
  return requiredFields.filter(field => {
    const value = selectedOptions[field];
    return Array.isArray(value) ? !value.length : !value;
  });
};

const validateEmail = (email = '') => {
  const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return regex.test(email) ? '' : 'Invalid Email';
};

const fetchAssetsOptions = async () => {
  try {
    const { applications } = await applicationSetupService.getApplicationItems();
    const options = applications.map((application: ApplicationEntity) => {
      return {
        displayValue: application.name,
        value: application._id,
        id: application._id,
      };
    });
    return options;
  } catch (err) {
    notificationService.error(`Failed to fetch Assets`);
    console.error(`Failed to fetch Assets: ${JSON.stringify(err?.response)}`);
    return [];
  }
};

const fetchLocationOptions = async () => {
  try {
    const countries = await getCountries();
    const countryOptions = countries.map(c => ({
      id: c.name,
      value: c.name,
      displayValue: c.displayName,
      code: c.groupedCode.slice(0, 2),
      icon: () => (
        <IconMatcher
          iconLabel={c.groupedCode.slice(0, 2)}
          matchFunction="equals"
          size="small"
          viewBox="0 0 24 24"
          defaultIcon={BigidDefaultIcon}
          key={c.name}
        />
      ),
      isSelected: false,
    }));
    return countryOptions;
  } catch (err) {
    notificationService.error(`Failed to fetch Locations`);
    console.error(`Failed to fetch Locations: ${JSON.stringify(err?.response)}`);
    return [];
  }
};

const fetchLevelsOptions = async (searchText?: string) => {
  try {
    const { legalEntitiesLevel } = await getLevels(searchText);
    return legalEntitiesLevel.map((level: LegalEntityLevel) => ({
      id: level.id,
      value: level.id,
      displayValue: level.name,
      isSelected: false,
    }));
  } catch (error) {
    notificationService.error(`Failed to fetch Levels`);
    console.error(`Failed to fetch Levels: ${JSON.stringify(error?.response)}`);
    return [];
  }
};

const fetchParentsOptions = async (levelId: string, searchText?: string, activeEntityId?: string) => {
  try {
    const query = {
      filter: [
        {
          field: 'name',
          value: searchText,
          operator: 'textSearch',
        },
        {
          field: 'level.id',
          value: [levelId],
          operator: 'in',
        },
      ] as BigidGridQueryComponents['filter'],
    };

    const { legalEntities } = await getLegalEntities(query);
    const parents = legalEntities
      ?.filter((entity: LegalEntitiesInterface) => entity.id !== activeEntityId)
      ?.map((entity: LegalEntitiesInterface) => {
        return {
          id: entity.id,
          value: entity.id,
          displayValue: entity.name,
          isSelected: false,
        };
      });
    return parents;
  } catch (error) {
    notificationService.error(`Failed to fetch Parents`);
    console.error(`Failed to fetch Parents: ${JSON.stringify(error?.response)}`);
    return [];
  }
};
export const createAssetsOptions = async (value: string) => {
  try {
    const { _id } = await createAsset({ name: value });
    const option: BigidDropdownOption = {
      id: _id,
      value: _id,
      displayValue: value,
      isNew: true,
    };
    return option;
  } catch (err) {
    notificationService.error(`Failed to create Asset`);
    console.error(`Failed to create Asset: ${JSON.stringify(err?.response)}`);
    return {} as BigidDropdownOption;
  }
};

export interface LegalEntityEditDialog {
  openLegalEntitiesEditDialog: (isSubEntity: boolean, legalEntityDetails?: LegalEntitiesInterface) => Promise<boolean>;
  legalEntitiesEditDialogProps: LegalEntitiesEditDialogProps;
  key: { parentsKey: number; assetsKey: number };
}

const initialState: LegalEntitiesEditDialogProps = {
  isEditDialogOpen: false,
  isNewLegalEntity: false,
  isNewSubEntity: false,
  legalEntityDetails: null,
};

export const useLegalEntitiesEditDialog = () => {
  const [dialogProps, setDialogProps] = useState(initialState);
  const [key, setKey] = useState<{ parentsKey: number; assetsKey: number }>({
    parentsKey: 0,
    assetsKey: 0,
  });
  const onLegalEntitiesEditDialogClose = (newOptionsCreated: NewlyAddedOptionsType) => {
    setDialogProps(initialState);
    setKey(key => ({
      parentsKey: newOptionsCreated.updatedParent ? ++key.parentsKey : key.parentsKey,
      assetsKey: newOptionsCreated.addedNewAssets ? ++key.assetsKey : key.assetsKey,
    }));
  };

  const onLegalEntitiesEditDialogSave = () => {
    setDialogProps(initialState);
    entityEventsEmitter.emit(EntityEvents.RELOAD);
  };
  const openLegalEntitiesEditDialog = useCallback<LegalEntityEditDialog['openLegalEntitiesEditDialog']>(
    (isNewSubEntity, legalEntityDetails) => {
      return new Promise<boolean>(resolve => {
        setDialogProps({
          isEditDialogOpen: true,
          isNewLegalEntity: !legalEntityDetails,
          isNewSubEntity: isNewSubEntity,
          legalEntityDetails: legalEntityDetails,
          onLegalEntitiesEditDialogSave: () => {
            onLegalEntitiesEditDialogSave();
            resolve(true);
          },
        });
      });
    },
    [],
  );

  return {
    openLegalEntitiesEditDialog,
    legalEntitiesEditDialogProps: {
      isEditDialogOpen: dialogProps.isEditDialogOpen,
      onLegalEntitiesEditDialogClose: onLegalEntitiesEditDialogClose,
      onLegalEntitiesEditDialogSave: dialogProps.onLegalEntitiesEditDialogSave,
      isNewLegalEntity: dialogProps.isNewLegalEntity,
      isNewSubEntity: dialogProps.isNewSubEntity,
      legalEntityDetails: dialogProps.legalEntityDetails,
    },
    key: key,
  };
};
