import { BigidBody1, BigidDropdown, BigidDropdownOption } from '@bigid-ui/components';
import { styled } from '@mui/material';
import { useTheme } from '@mui/styles';
import React from 'react';
import { TFunction } from 'react-i18next';
import {
  alienEntitySortOptions,
  catalogSortOptions,
  useDataCatalogSearchResultsContext,
} from '../../contexts/dataCatalogSearchResultsContext';
import { useLocalTranslation } from '../../translations';
import { ResultsEntityType } from '../../types';
import { $state } from '../../../../services/angularServices';

const Container = styled('div')`
  width: 200px;
  display: flex;
  gap: 4px;
  align-items: center;
  white-space: nowrap;
`;

const getOptions = (entityType: ResultsEntityType, t: TFunction): BigidDropdownOption[] => {
  switch (entityType) {
    case 'catalog': {
      return catalogSortOptions.map(option => ({
        displayValue: t(`options.${option}`),
        value: option,
        id: option,
      }));
    }

    default: {
      return alienEntitySortOptions.map(option => ({
        displayValue: t(`options.${option}`),
        value: option,
        id: option,
      }));
    }
  }
};

export const SortingControl = () => {
  const { t } = useLocalTranslation('gridControls.sorting');
  const { entityType, sortBy } = useDataCatalogSearchResultsContext();
  const {
    vars: { palette },
  } = useTheme();

  const handleSelectChange = (options: BigidDropdownOption[]) => {
    $state.go($state.current.name, { sort: options[0].value });
  };
  const options = getOptions(entityType, t);
  const selectedOption = options.find(option => option.value === sortBy);

  const isCatalog = entityType === 'catalog';

  // NOTE: we don't want to show sorting control for alien entities because we don't have sorting for them
  if (!isCatalog) {
    return null;
  }

  return (
    <Container>
      <BigidBody1 color={palette.bigid.gray500}>{t('placeholder')}</BigidBody1>
      <BigidDropdown options={options} onSelect={handleSelectChange} value={[selectedOption]} dataAid="SortDropdown" />
    </Container>
  );
};
