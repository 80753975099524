import { BigidColorsV2 } from '@bigid-ui/components';
import { BigidGridSorting } from '@bigid-ui/grid';
import { Sorting } from '../catalogDiscoveryService';
import {
  Aggregation,
  AggregationType,
  AggregationItemName,
  SensitivityClassificationAggregationItem,
  DataFormatAggregationItem,
  AggregationPossesionIdentifier,
  AggregationItemBase,
  SourceCategory,
} from '../catalogDiscoveryTypes';
import { getFixedT as getObjectStatusPieChartT } from '../content/ObjectStatusPieChart/translations';
import { getFixedT as getSensitivityPieChartT } from '../content/SensitivityPieChart/translations';
import { getFixedT as getDataFormatPieChartT } from '../content/DataFormatPieChart/translations';
import { categoriesArbitraryColors } from '../config/common';

export function getMapKeys<T>(map: T) {
  return Object.keys(map) as Array<keyof T>;
}

export function getAggregatedDataByType(data: Aggregation[], type: AggregationType): Aggregation {
  return (data ?? []).find(({ aggName }) => aggName === type);
}

export function getObjectStatusAggregationValueColor(aggregationItemName: AggregationItemName): string {
  switch (aggregationItemName) {
    case AggregationPossesionIdentifier.WITH:
      return '#6CD4E0';
    case AggregationPossesionIdentifier.WITHOUT:
      return '#6C9EFF';
    default:
      return BigidColorsV2.gray[100];
  }
}

export function getSensitivityAggregationValueColor(
  aggregationItemName: AggregationItemName,
  isDefaultOptions?: boolean,
  index?: number,
): string {
  if (isDefaultOptions) {
    switch (aggregationItemName) {
      case SensitivityClassificationAggregationItem.RESTRICTED:
        return BigidColorsV2.red[400];
      case SensitivityClassificationAggregationItem.CONFIDENTIAL:
        return BigidColorsV2.orange[400];
      case SensitivityClassificationAggregationItem.INTERNAL_USE:
        return BigidColorsV2.yellow[400];
      case SensitivityClassificationAggregationItem.PUBLIC:
        return BigidColorsV2.green[400];
      default:
        return BigidColorsV2.gray[400];
    }
  } else {
    switch (index) {
      case 0:
        return BigidColorsV2.blue[900];
      case 1:
        return BigidColorsV2.magenta[300];
      case 2:
        return BigidColorsV2.blue[300];
      case 3:
        return BigidColorsV2.cyan[300];
      case 4:
        return BigidColorsV2.blue[600];
      default:
        return BigidColorsV2.purple[300];
    }
  }
}

export function getDataFormatAggregationValueColor(aggregationItemName: AggregationItemName): string {
  switch (aggregationItemName) {
    case DataFormatAggregationItem.STRUCTURED:
      return '#6CD4E0';
    case DataFormatAggregationItem.UNSTRUCTURED:
      return '#6C9EFF';
    case DataFormatAggregationItem.EMAIL:
      return '#B489FF';
    case DataFormatAggregationItem.OTHER:
      return '#FF72CF';
    default:
      return BigidColorsV2.gray[100];
  }
}

export function getObjectStatusAggregationValueLabel(aggregationItemName: AggregationItemName): string {
  const t = getObjectStatusPieChartT('values');

  switch (aggregationItemName) {
    case AggregationPossesionIdentifier.WITH:
      return t('withFindings');
    case AggregationPossesionIdentifier.WITHOUT:
      return t('withoutFindings');
    default:
      return aggregationItemName;
  }
}

export function getSensitivityAggregationValueLabel(aggregationItemName: AggregationItemName): string {
  const t = getSensitivityPieChartT('values');

  switch (aggregationItemName) {
    case SensitivityClassificationAggregationItem.CONFIDENTIAL:
      return t('confidential');
    case SensitivityClassificationAggregationItem.RESTRICTED:
      return t('restricted');
    case SensitivityClassificationAggregationItem.INTERNAL_USE:
      return t('internalUse');
    case SensitivityClassificationAggregationItem.PUBLIC:
      return t('public');
    default:
      return aggregationItemName;
  }
}

export function getDataFormatAggregationValueLabel(aggregationItemName: AggregationItemName): string {
  const t = getDataFormatPieChartT('values');

  switch (aggregationItemName) {
    case DataFormatAggregationItem.STRUCTURED:
      return t('structured');
    case DataFormatAggregationItem.UNSTRUCTURED:
      return t('unstructured');
    case DataFormatAggregationItem.EMAIL:
      return t('email');
    case DataFormatAggregationItem.OTHER:
      return t('other');
    default:
      return aggregationItemName;
  }
}

export const gridSortingToApiFormatMapping: Record<BigidGridSorting['order'], Sorting['order']> = {
  asc: 'ASC',
  desc: 'DESC',
};

export function mapGridSortingToApiFormat(sorting: BigidGridSorting[]): Sorting[] {
  return sorting.map(sortingItem => ({
    ...sortingItem,
    order: gridSortingToApiFormatMapping[sortingItem.order],
  }));
}

export function getNTopUniqueAggItemGroups(data: AggregationItemBase[], amount: number): AggregationItemBase[] {
  let increment = 0;
  const topNAggItemGroupsArray: AggregationItemBase[] = [];

  for (const aggItem of data) {
    if (increment >= amount) {
      break;
    } else if (!topNAggItemGroupsArray.find(({ aggItemGroup }) => aggItem.aggItemGroup === aggItemGroup)) {
      topNAggItemGroupsArray.push(aggItem);
      increment++;
    }
  }

  return topNAggItemGroupsArray;
}

export function getAggregationTotalReducedBy(
  data: AggregationItemBase[],
  reduceByProp: 'findings' | 'docCount',
): number {
  return data.reduce((total, aggItem) => {
    return total + (aggItem[reduceByProp] ?? 0);
  }, 0);
}

export function getSourceCategoriesExtendedWithArbitraryColors(categories: string[]): SourceCategory[] {
  let colorIndex = 0;

  return (categories ?? []).reduce((extendedCategories, categoryName) => {
    let color = categoriesArbitraryColors[colorIndex];

    if (!color) {
      colorIndex = 0;
      color = categoriesArbitraryColors[colorIndex];
    }

    colorIndex++;

    return [
      ...extendedCategories,
      {
        name: categoryName.toString(),
        color,
      },
    ];
  }, []);
}
