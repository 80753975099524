import { useEffect, useState } from 'react';
import { EventEmitter } from '@bigid-ui/utils';
import {
  DEFAULT_WORKSPACE,
  resolveNextWorkspace,
  WorkspaceId,
  workspaces,
  getWorkspacesByStateAndPreferences,
  canPageBeLastVisited,
  doesAppPageAllowedForWorkspace,
  doesPageAllowedForWorkspace,
} from '../utilities/workspacesUtils';
import { userPreferencesService } from './userPreferencesService';
import { sessionStorageService } from '../../common/services/sessionStorageService';
import { isWorkspacesEnabled } from '../utilities/featureFlagUtils';

export enum WorkspacesEvents {
  REFRESH_SELECTED_WORKSPACE = 'refreshSelectedWorkspace',
}

export interface WorkspacesSettingsPreferences {
  workspaceId: string;
  lastVisitedPage: string;
  appRoute?: string;
  appId?: string;
}

export const workspacesEventsEmitter = new EventEmitter<WorkspacesEvents>();

const WORKSPACES_SETTINGS = 'workspacesSettings';
const WORKSPACE_USER_PREFERENCE = 'workspaceUserPreference';

export const getSelectedWorkspaceSettings = async (workspaceId?: string): Promise<WorkspacesSettingsPreferences> => {
  if (!isWorkspacesEnabled()) {
    return;
  }

  const selectedWorkspaceId = workspaceId || getSelectedWorkspaceId();

  const workspacesLastPages = await userPreferencesService.get<WorkspacesSettingsPreferences[]>(WORKSPACES_SETTINGS);

  const workspaceSettings = workspacesLastPages?.data?.find(w => w.workspaceId === selectedWorkspaceId);

  if (workspaceSettings?.lastVisitedPage && canPageBeLastVisited(workspaceSettings.lastVisitedPage)) {
    if (workspaceSettings?.appId) {
      if (
        await doesAppPageAllowedForWorkspace(
          selectedWorkspaceId as WorkspaceId,
          workspaceSettings?.appId,
          workspaceSettings?.appRoute,
        )
      ) {
        return workspaceSettings;
      }
    } else if (
      await doesPageAllowedForWorkspace(workspaceSettings.lastVisitedPage, selectedWorkspaceId as WorkspaceId)
    ) {
      return workspaceSettings;
    }
  }

  return {
    ...(workspaceSettings || {}),
    workspaceId: selectedWorkspaceId,
    lastVisitedPage: workspaces.find(workspace => workspace.id === selectedWorkspaceId)?.getHomePage(),
    appId: undefined,
    appRoute: undefined,
  };
};

export const updateWorkspaceSettingsByWorkspaceId = async ({
  workspaceId,
  lastVisitedPage,
  appRoute,
  appId,
}: WorkspacesSettingsPreferences) => {
  if (canPageBeLastVisited(lastVisitedPage) && isWorkspacesEnabled()) {
    try {
      const prevPreferences =
        (await userPreferencesService.get<WorkspacesSettingsPreferences[]>(WORKSPACES_SETTINGS))?.data || [];

      const preferenceForUpdate = [
        { lastVisitedPage, workspaceId, appRoute, appId },
        ...prevPreferences.filter(p => p.workspaceId !== workspaceId),
      ];

      await userPreferencesService.update<WorkspacesSettingsPreferences[]>({
        preference: WORKSPACES_SETTINGS,
        data: preferenceForUpdate,
      });
    } catch (err) {
      console.error(err);
      return false;
    }
  }
};

export const selectWorkspace = (workspaceId: string, saveToPreferences = true) => {
  const prevWorkspaceId = getSelectedWorkspaceId();

  if (prevWorkspaceId !== workspaceId) {
    sessionStorageService.set('selectedWorkspaceId', workspaceId);
    workspacesEventsEmitter.emit(WorkspacesEvents.REFRESH_SELECTED_WORKSPACE);

    if (saveToPreferences) {
      userPreferencesService.update({
        preference: WORKSPACE_USER_PREFERENCE,
        data: workspaceId,
      });
    }
  }
};

export const getSelectedWorkspaceId = (): WorkspaceId =>
  sessionStorageService.get('selectedWorkspaceId') || DEFAULT_WORKSPACE;

export const getSelectedWorkspace = () => {
  const selectedWorkspaceId = getSelectedWorkspaceId();
  return workspaces.find(w => w.id === selectedWorkspaceId);
};

export const syncWorkspacesWithUserPreferences = async () => {
  const preferences = await userPreferencesService.get<string>(WORKSPACE_USER_PREFERENCE);
  if (preferences?.data) {
    selectWorkspace(preferences.data, false);
  }
};

export const updateWorkspaceOnTransition = async (nextState: string) => {
  const workspacesToChoose = await getWorkspacesByStateAndPreferences(nextState);
  const nextWorkspace = resolveNextWorkspace(workspacesToChoose, getSelectedWorkspaceId());
  selectWorkspace(nextWorkspace);
};

export const useWorkspaces = () => {
  const [, setRefreshNumber] = useState(0);

  useEffect(() => {
    const triggerComponentRefresh = () => {
      setRefreshNumber(prevState => prevState + 1);
    };

    return workspacesEventsEmitter.addEventListener(
      WorkspacesEvents.REFRESH_SELECTED_WORKSPACE,
      triggerComponentRefresh,
    );
  }, []);

  return {
    selectedWorkspaceId: getSelectedWorkspaceId(),
    selectedWorkspace: getSelectedWorkspace(),
    selectWorkspace,
    workspaces,
  };
};
