import React, { FC, useEffect, useRef, useMemo, ReactText, useCallback, ReactNode } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { AddBoxOutlined } from '@mui/icons-material';
import {
  BigidLayout,
  BigidLayoutConfig,
  LayoutContentType,
  BigidMasterDetailsContentCallbackParams,
  BigidLayoutEmptyState,
} from '@bigid-ui/layout';
import {
  BigidContentItem,
  EntityEvents,
  entityEventsEmitter,
  ToolbarActionType,
  BigidChip,
  BigidColorsV2,
} from '@bigid-ui/components';
import { BigidGridColumn, BigidGridColumnTypes, FetchDataFunction, BigidGridRow } from '@bigid-ui/grid';
import { BigidDeleteIcon, BigidDuplicateIcon, BigidRulesIllustration } from '@bigid-ui/icons';
import { ADVANCE_TOOLS_PERMISSIONS, COMPOUND_ATTRIBUTE_PERMISSIONS } from '@bigid/permissions';
import { CatalogRuleDetails } from './CatalogRuleDetails/CatalogRuleDetails';
import { CatalogRulesAsyncOpsProcessingWidget } from './CatalogRulesAsyncOps/CatalogRulesAsyncOpsProcessingWidget';
import { CatalogRulesLayoutCell } from './CatalogRulesLayoutCell';
import {
  getDuplicateRuleName,
  getRuleNameSuffix,
  getRuleId,
  getDuplicateRule,
  validateSpecialChars,
} from './catalogRulesUtils';
import {
  deleteCatalogRule,
  getCatalogRuleById,
  getCatalogRulesList,
  updateCatalogRule,
  CatalogRule,
} from './catalogRulesService';
import { showConfirmationDialog } from '../../services/confirmationDialogService';
import { notificationService } from '../../services/notificationService';
import { pageHeaderService } from '../../../common/services/pageHeaderService';
import { isPermitted } from '../../services/userPermissionsService';
import { omit } from 'lodash';
import { CatalogRulesEvents, trackEventCatalogRules } from './CatalogRulesEventTrackerUtils';
import { CatalogRulesNoDataView } from './CatalogRulesNoDataView';

interface CatalogRuleGridRecord extends BigidGridRow, CatalogRule {
  id: ReactText;
  tags?: ReactNode[];
}

export interface CatalogRulesLayoutEntityActions {
  handleOnSave: () => Promise<boolean>;
  handleOnApply: () => Promise<boolean>;
}

const useStyles = makeStyles({
  root: {
    height: '100%',
  },
  contentContainer: {
    display: 'flex',
    overflowY: 'auto',
    overflowX: 'hidden',
    borderRadius: '4px 4px 0px 0px',
    flexFlow: 'column nowrap',
    flex: '1 1 auto',
  },
  noData: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: '100px',
  },
  noDataMessage: {
    paddingTop: '10px',
  },
});

export const CatalogRulesLayout: FC = () => {
  const classes = useStyles({});

  useEffect(() => {
    pageHeaderService.setTitle({
      pageTitle: 'Compound Attribute Rules',
      rightSideComponentsContainer: <CatalogRulesAsyncOpsProcessingWidget />,
    });
  }, []);

  useEffect(() => {
    const handleUpdateName = async (id: string, { name }: BigidContentItem, { selectedItem }: any) => {
      if (validateSpecialChars(name)) {
        if (selectedItem?.name !== name) {
          if (selectedItem?.createdAt) {
            try {
              const data = await getCatalogRuleById(id);
              await updateCatalogRule(id, omit({ ...data, name }, ['updatedAt']));
              trackEventCatalogRules(CatalogRulesEvents.COMPOUND_ATTRIBUTE_UPDATE_RULE_NAME);
              entityEventsEmitter.emit(EntityEvents.UPDATE_BY_ID, id, { name });
              notificationService.success(`${selectedItem.name} rule successfully has been renamed to ${name}.`);
            } catch ({ message, response }) {
              let notificationMessage = 'An error has occurred during the rule name changing.';

              if (response.status === 400) {
                notificationMessage = 'A rule with this name already exists. Please rename and save again.';
              }

              notificationService.error(notificationMessage);
            }
          } else {
            entityEventsEmitter.emit(EntityEvents.UPDATE_BY_ID, id, { name });
          }
        }
      } else {
        notificationService.error(
          'Invalid value. Please use alphanumeric characters, spaces, dots, underscores and dashes.',
        );
      }
    };

    const updateNameEventListenerUnreg = entityEventsEmitter.addEventListener(
      EntityEvents.ENTITY_UPDATED,
      handleUpdateName,
    );

    return () => {
      return updateNameEventListenerUnreg();
    };
  }, []);

  const fetchGridData: FetchDataFunction<CatalogRuleGridRecord> = useCallback(async ({ filter }) => {
    try {
      const searchQuery = filter.length > 0 ? filter[0]?.value.toString().toLowerCase() : '';
      const rules = (await getCatalogRulesList()) as CatalogRuleGridRecord[];

      //TODO: remove once API supports backend filtering
      const filteredRules = rules.filter(({ name }) => name.toLowerCase().includes(searchQuery));

      return {
        totalCount: filteredRules.length,
        data: filteredRules.map(rule => {
          if (rule.isPredefined) {
            return {
              ...rule,
              tags: [
                <BigidChip key={rule.id} bgColor={BigidColorsV2.purple[100]} label="BigID Predefined" size="small" />,
              ],
            };
          } else {
            return rule;
          }
        }),
      };
    } catch {
      console.error('Error while fetching the rules');

      return {
        totalCount: 0,
        data: [],
      };
    }
  }, []);

  const columns: BigidGridColumn<CatalogRuleGridRecord>[] = useMemo(
    () => [
      {
        title: 'Rules',
        name: 'name',
        isListColumn: true,
        type: BigidGridColumnTypes.CUSTOM,
        getCellValue: ({ name, isEnabled }) => <CatalogRulesLayoutCell name={name} isEnabled={isEnabled} />,
      },
    ],
    [],
  );

  const actionsRef = useRef<CatalogRulesLayoutEntityActions>({
    handleOnSave: async () => {
      return false;
    },
    handleOnApply: async () => {
      return false;
    },
  });

  const layoutConfig: BigidLayoutConfig = useMemo(() => {
    const createNewRule = [
      {
        label: 'New Rule',
        execute: async () => {
          const id = getRuleId();
          const ruleNameSuffix = getRuleNameSuffix(id);
          trackEventCatalogRules(CatalogRulesEvents.COMPOUND_ATTRIBUTE_NEW_RULE);

          entityEventsEmitter.emit(EntityEvents.ADD, [
            {
              id,
              name: `Rule${ruleNameSuffix}`,
            },
          ]);

          return {
            shouldGridReload: false,
            shouldClearSelection: false,
          };
        },
        icon: AddBoxOutlined,
        show: () => {
          return (
            isPermitted(ADVANCE_TOOLS_PERMISSIONS.MANAGE_CLEAR_ENTITIES_CACHE.name) &&
            isPermitted(COMPOUND_ATTRIBUTE_PERMISSIONS.CREATE.name)
          );
        },
      },
    ];

    return {
      content: {
        entityName: 'Rules',
        toolbarActions: [
          {
            ...createNewRule[0],
            isGlobal: false,
            disable: () => {
              return false;
            },
          },
        ],
        contentTypes: [LayoutContentType.MASTER_DETAILS],
        viewConfig: {
          fetchGridData,
          gridConfig: {
            hideHeadersRow: true,
            showSelectionColumn: false,
            columns,
            noDataContent: <CatalogRulesNoDataView message="No Rules were defined" />,
          },
          masterDetailsConfig: {
            isPersistentListMode: true,
            placeholderComponent: (
              <BigidLayoutEmptyState
                illustration={BigidRulesIllustration}
                description="No rule was selected"
                actions={createNewRule}
              />
            ),
            tabsAndContent: {
              classes: {
                contentContainer: classes.contentContainer,
              },
              hideTabs: true,
              tabProps: {
                selectedIndex: 0,
                tabs: [
                  {
                    label: '',
                    data: {
                      component: CatalogRuleDetails,
                      customProps: { actionsRef },
                    },
                  },
                ],
              },
            },
            isEditableHeader: true,
            isEditableHeaderDisabled: ({ selectedItem }: BigidMasterDetailsContentCallbackParams) => {
              return selectedItem?.isPredefined;
            },
            isHeaderHidden: ({ selectedItem }: BigidMasterDetailsContentCallbackParams) => {
              return !Boolean(selectedItem);
            },
            actions: [
              {
                label: 'Duplicate',
                icon: BigidDuplicateIcon,
                type: ToolbarActionType.ACTION_ICON,
                execute: async ({ selectedItem }) => {
                  const { id, name } = selectedItem || ({} as CatalogRule);
                  trackEventCatalogRules(CatalogRulesEvents.COMPOUND_ATTRIBUTE_DUPLICATE);

                  try {
                    const rule = await getCatalogRuleById(id);

                    const newRuleId = getRuleId();
                    const newRule = {
                      id: newRuleId,
                      copySourceId: id,
                      name: getDuplicateRuleName(name, newRuleId),
                    };

                    entityEventsEmitter.emit(EntityEvents.ADD, [
                      {
                        ...newRule,
                        ...getDuplicateRule(rule),
                      },
                    ]);

                    return {
                      shouldGridReload: false,
                      shouldClearSelection: false,
                    };
                  } catch ({ message }) {
                    const notificationMessage = `An error has occurred while copying ${name} rule`;

                    console.error(`${notificationMessage}: ${message}`);
                    notificationService.error(`${notificationMessage}.`);

                    return {
                      shouldGridReload: false,
                      shouldClearSelection: false,
                    };
                  }
                },
                disable: () => {
                  return false;
                },
                show: ({ selectedItem }) => {
                  return (
                    selectedItem?.createdAt &&
                    isPermitted(ADVANCE_TOOLS_PERMISSIONS.MANAGE_CLEAR_ENTITIES_CACHE.name) &&
                    isPermitted(COMPOUND_ATTRIBUTE_PERMISSIONS.CREATE.name)
                  );
                },
              },
              {
                label: 'Delete',
                icon: BigidDeleteIcon,
                type: ToolbarActionType.ACTION_ICON,
                execute: async ({ selectedItem }) => {
                  const { id, name } = selectedItem || ({} as CatalogRule);

                  if (selectedItem?.createdAt) {
                    const shouldDeleteRule = await showConfirmationDialog({
                      entityNameSingular: name,
                      actionName: 'Delete',
                      actionButtonName: 'Delete',
                      customDescription: `Are you sure you want to delete this rule?`,
                    });

                    if (shouldDeleteRule) {
                      trackEventCatalogRules(CatalogRulesEvents.COMPOUND_ATTRIBUTE_DELETE);
                      try {
                        await deleteCatalogRule(id);
                        entityEventsEmitter.emit(EntityEvents.DELETE, [selectedItem]);
                        notificationService.success(`${name} rule successfully has been deleted.`);
                      } catch ({ message, response }) {
                        let notificationMessage;

                        if (response.status === 400) {
                          notificationMessage = `Rule ${name} cannot be deleted now as it is currently running`;
                          notificationService.warning(`${notificationMessage}.`);
                        } else {
                          notificationMessage = `An error has occurred while deleting ${name} rule`;
                          notificationService.error(`${notificationMessage}.`);
                        }

                        console.error(`${notificationMessage}: ${message}`);
                      } finally {
                        return {
                          shouldGridReload: false,
                        };
                      }
                    }
                  } else {
                    entityEventsEmitter.emit(EntityEvents.DELETE, [selectedItem]);
                  }
                },
                disable: () => {
                  return false;
                },
                show: ({ selectedItem }) => {
                  return (
                    !selectedItem?.isPredefined &&
                    isPermitted(ADVANCE_TOOLS_PERMISSIONS.MANAGE_CLEAR_ENTITIES_CACHE.name) &&
                    isPermitted(COMPOUND_ATTRIBUTE_PERMISSIONS.DELETE.name)
                  );
                },
              },
              {
                type: ToolbarActionType.SWITCH,
                label: 'Enabled',
                switchProps: {
                  isChecked: actionData => {
                    return actionData?.selectedItem?.isEnabled;
                  },
                },
                execute: async ({ selectedItem }) => {
                  const { id, name, isEnabled } = selectedItem || ({} as CatalogRule);
                  trackEventCatalogRules(CatalogRulesEvents.COMPOUND_ATTRIBUTE_ENABLED);

                  try {
                    const data = await getCatalogRuleById(id);

                    if (data) {
                      data.isEnabled = !data.isEnabled;
                      await updateCatalogRule(id, omit(data, ['updatedAt']));
                      entityEventsEmitter.emit(EntityEvents.UPDATE_BY_ID, selectedItem.id, {
                        isEnabled: data.isEnabled,
                      });
                      notificationService.success(
                        `${name} rule successfully has been turned ${isEnabled ? 'off' : 'on'}.`,
                      );
                    }
                  } catch ({ message }) {
                    const notificationMessage = `An error has occured while switching ${name} rule ${
                      isEnabled ? 'off' : 'on'
                    }`;

                    console.error(`${notificationMessage}: ${message}`);
                    notificationService.error(`${notificationMessage}.`);
                  } finally {
                    return {
                      shouldGridReload: false,
                    };
                  }
                },
                show: ({ selectedItem }) => {
                  return (
                    selectedItem?.createdAt &&
                    isPermitted(ADVANCE_TOOLS_PERMISSIONS.MANAGE_CLEAR_ENTITIES_CACHE.name) &&
                    isPermitted(COMPOUND_ATTRIBUTE_PERMISSIONS.DELETE.name)
                  );
                },
              },
              {
                label: 'Apply',
                isGlobal: true,
                execute: async () => {
                  await actionsRef.current?.handleOnApply();

                  return {
                    shouldGridReload: false,
                    shouldClearSelection: false,
                  };
                },
                disable: ({ selectedItem }) => {
                  return !Boolean(selectedItem?.createdAt);
                },
                show: ({ selectedItem }) => {
                  return (
                    selectedItem?.createdAt &&
                    isPermitted(ADVANCE_TOOLS_PERMISSIONS.MANAGE_CLEAR_ENTITIES_CACHE.name) &&
                    isPermitted(COMPOUND_ATTRIBUTE_PERMISSIONS.APPLY.name)
                  );
                },
              },
              {
                label: 'Save',
                execute: async () => {
                  await actionsRef.current?.handleOnSave();
                  trackEventCatalogRules(CatalogRulesEvents.COMPOUND_ATTRIBUTE_SAVE);

                  return {
                    shouldGridReload: false,
                    shouldClearSelection: false,
                  };
                },
                show: ({ selectedItem }) => {
                  return (
                    !selectedItem?.isPredefined &&
                    isPermitted(ADVANCE_TOOLS_PERMISSIONS.MANAGE_CLEAR_ENTITIES_CACHE.name) &&
                    isPermitted(COMPOUND_ATTRIBUTE_PERMISSIONS.EDIT.name)
                  );
                },
              },
            ],
          },
        },
      },
      filter: {
        search: {
          isQueryLanguage: false,
          getFreeSearchField: () => {
            return 'name';
          },
        },
      },
    };
  }, [fetchGridData, columns, classes.contentContainer]);

  return (
    <div className={classes.root}>
      <BigidLayout config={layoutConfig} />
    </div>
  );
};
