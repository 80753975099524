import React, { FC, useRef, useState } from 'react';
import { BigidTextField, BigidBody1, PrimaryButton, SecondaryButton, BigidLoader } from '@bigid-ui/components';
import styled from '@emotion/styled';
import { ModalContentProps, updateCaseStatus } from '../caseActionsService';
import { useLocalTranslation } from '../../../translations';
import {
  ActionableInsightsTrackingEvents,
  trackActionableInsightsEvent,
} from '../../../actionableInsightsTrackingUtil';
import { CaseStatus } from '../../../actionableInsightsService';

const MainContainer = styled.div({
  height: 270,
  display: 'flex',
  flexDirection: 'column',
});

const TextField = styled.div({
  paddingBottom: 24,
  paddingTop: 16,
});

const ButtonsContainer = styled.div({
  display: 'flex',
  flexDirection: 'row-reverse',
  marginTop: 'auto',
  '& > :first-child': {
    marginLeft: 8,
  },
});

export const RemediateCase: FC<ModalContentProps> = ({ closeModal, rowsData, onRemediateCase, viewType, filters }) => {
  const { t } = useLocalTranslation('Action');
  const reasonInputRef = useRef<HTMLTextAreaElement>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isInvalid, setIsInvalid] = useState<boolean>(false);
  const errorMessage = isInvalid ? t('validation.fillCaseRemediateReason') : undefined;

  const onRemediateCaseClick = async () => {
    if (!reasonInputRef.current.value) {
      setIsInvalid(true);
    } else {
      setIsLoading(true);

      updateCaseStatus(
        { caseIds: filters.caseIds, policies: filters?.policies, status: filters.status },
        CaseStatus.REMEDIATED,
        reasonInputRef.current.value,
        onRemediateCase,
      );
      closeModal?.();
      trackActionableInsightsEvent(
        ActionableInsightsTrackingEvents.DATA_RISK_MANAGEMENT_REMEDIATE_CASE_DIALOG_CLOSE_CLICK,
        {
          AUDIT_REASON: reasonInputRef.current.value,
          VIEW_TYPE: viewType,
        },
      );      
    }
  };

  const onChangeInputText = () => {
    if (isInvalid && reasonInputRef.current.value) {
      setIsInvalid(false);
    }
  };

  const onCancelClick = () => {
    trackActionableInsightsEvent(
      ActionableInsightsTrackingEvents.DATA_RISK_MANAGEMENT_REMEDIATE_CASE_DIALOG_CANCEL_CLICK,
      {
        VIEW_TYPE: viewType,
      },
    );
    closeModal();
  };

  return (
    <MainContainer>
      {isLoading ? (
        <BigidLoader />
      ) : (
        <>
          <BigidBody1>{t('body.remediateCaseInfo')}</BigidBody1>
          <TextField>
            <BigidTextField
              autoFocus
              required
              placeholder={t('label.remediateCaseReasonPlaceholder')}
              onChange={onChangeInputText}
              multiline
              rows={4}
              label={t('label.reason')}
              inputRef={reasonInputRef}
              errorMessage={errorMessage}
            />
          </TextField>
          <ButtonsContainer>
            <PrimaryButton size="medium" text={t('button.apply')} onClick={onRemediateCaseClick} />
            <SecondaryButton size="medium" text={t('button.cancel')} onClick={onCancelClick} />
          </ButtonsContainer>
        </>
      )}
    </MainContainer>
  );
};
