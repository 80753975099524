import React, { FC, lazy, Suspense } from 'react';
import { BigidLoader } from '@bigid-ui/components';

const InfoForDev = lazy(() => import(/* webpackChunkName: "InfoForDev" */ './InfoForDev'));

export const InfoForDevLazy: FC = () => {
  return (
    <Suspense fallback={<BigidLoader />}>
      <InfoForDev />
    </Suspense>
  );
};
