import React from 'react';
import { getAggregatedData, GetAggregatedDataPayload } from '../../../CatalogDiscovery/catalogDiscoveryService';
import {
  AggregationItemBase,
  AggregationType,
  AggregationItemName,
} from '../../../CatalogDiscovery/catalogDiscoveryTypes';
import { TagEntity, TagAssignmentTarget } from '../../../TagsManagement/TagsManagementService';
import { mapGridSortingToApiFormat } from '../../../CatalogDiscovery/utils/common';
import {
  getDataCatalogRecords,
  DataCatalogRecord,
  DataCatalogObjectType,
  getDataCatalogRecordsCount,
  Policies,
  DataCatalogCountResponse,
} from '../../../DataCatalog/DataCatalogService';
import { BigidGridQueryComponents, BigidGridRow, TagsFormatterProps, ChipsFormatterProps } from '@bigid-ui/grid';
import {
  BigidColors,
  BigidDropdownOption,
  BigidSensitivityBadgeLevel,
  objectToQueryString,
  QueryParams,
} from '@bigid-ui/components';
import { getTagFormattedName, getTagIcon } from '../../../TagsManagement/TagsManagementUtils';
import {
  createHotspotsReport,
  getHotspotsCounters,
  Hotspot,
  HotspotChartRow,
} from '../../../HotspotsReport/HotspotsReportChart/HotspotsReportChartService';
import { hotspotToHotspotChartRow, performScan } from '../../../HotspotsReport/HotspotsReportChart/utils';
import { httpService } from '../../../../services/httpService';
import { CaseStatus } from '../../actionableInsightsService';
import { ActionsDialogTypes } from '../../ActionableInsightsGridViews/CaseActionsModal/hooks/useCaseActions';
import { getFixedT } from '../../translations';
import { rolesService } from '../../../../services/angularServices';
import { getUsersQuery, User } from '../../../../utilities/systemUsersUtils';
import { DataSourceModel } from '../../../DataSources/DataSourceConnections/DataSourceConnectionTypes';
import { AppPreferences, getApplicationPreference } from '../../../../services/appPreferencesService';

const catalogCountTimeoutQueryParam = `&objectsCountTimeoutSec=60`;

const tCaseStatus = getFixedT('Case.status');

export const enum SensitivityLevels {
  RESTRICTED = 'Restricted',
  CONFIDENTIAL = 'Confidential',
  INTERNAL_USE = 'Internal Use',
  PUBLIC = 'Public',
  NONE = 'None',
  DISABLED = 'disabled',
}

export interface CaseQuery {
  dataSourceName: string;
  policyName: string;
  queryComponents?: BigidGridQueryComponents;
  requireTotalCount?: boolean;
}

export interface DataSourceConnection extends DataSourceModel {
  owners: any[];
}

export interface AggregationResponseWithCount {
  aggData: AggregationItemBase[];
  totalCount: number;
}

export interface SensitivityQueryConfigurations extends CaseQuery {
  scMapping: Map<string, number>;
  numberOfAffectedObjects: number;
}

export interface ResponseFromCatalogWithCount {
  data: DataCatalogRecord[];
  totalCount: number;
}

export interface HotspotData extends HotspotChartRow {
  percentage: number;
}

export interface TopViolationsLocationData {
  data: HotspotData[];
}

export interface SensitivityRatioData {
  aggItemName: AggregationItemName;
  docCount: number;
  percentage: number;
}

export interface CaseReportAffectedObjects extends BigidGridRow {
  owner: string;
  path: string;
  name: string;
  attributes: ChipsFormatterProps;
  sensitivity: string;
  type: DataCatalogObjectType;
  tags: TagsFormatterProps;
  fqn: string;
}

const MAX_VIOLATIONS_PERCENTAGE = 80;
const MAX_LOCATIONS_TO_DISPLAY = 2;
export const SENSITIVITY_CLASSIFICATION_TAG_NAME = 'system.sensitivityClassification.Sensitivity';

export const fetchNumOfFindings = async ({ dataSourceName, policyName }: CaseQuery): Promise<number> => {
  const query = getCaseInventoryQuery(dataSourceName, policyName);
  const payload: GetAggregatedDataPayload = {
    filter: query,
    aggregations: [
      {
        aggName: AggregationType.HAS_FINDINGS,
        isTotalRequired: true,
      },
    ],
  };
  const { aggregations } = await getAggregatedData(payload);
  const data = aggregations?.[0];
  const aggregationData = data?.aggData ?? [];
  const valueComputed = !isNaN(aggregationData[0]?.['findings']) ? Number(aggregationData[0]?.['findings']) : 0;
  return valueComputed;
};

const getSensitivityLevelObjectsCount = async (key: string, query: string, numberOfAffectedObjects: number) => {
  const countQuery = `filter=${query} AND ${getClassificationCatalogFilter(key)}`;
  const countResponse = await getDataCatalogRecordsCount(
    `${countQuery}${catalogCountTimeoutQueryParam}`,
    getDataCatalogHeaders(),
  ).then(data => data);
  const percentage = calculatePercentage(countResponse?.count, numberOfAffectedObjects);
  return { aggItemName: key, docCount: countResponse?.count, percentage };
};

export const fetchSensitivityRatio = async ({
  dataSourceName,
  policyName,
  scMapping,
  numberOfAffectedObjects,
}: SensitivityQueryConfigurations): Promise<SensitivityRatioData[]> => {
  const query = getCaseQuery(dataSourceName, policyName);
  const getScCountOperations = Array.from(scMapping.entries()).map(([key, value]) =>
    getSensitivityLevelObjectsCount(key, query, numberOfAffectedObjects),
  );
  const aggData = await Promise.all(getScCountOperations);
  return aggData.filter(scData => scData.percentage > 0);
};

export const fetchCaseAttributes = async ({
  dataSourceName,
  policyName,
  queryComponents,
}: CaseQuery): Promise<AggregationResponseWithCount> => {
  const query = getCaseInventoryQuery(dataSourceName, policyName);
  const { skip, limit } = queryComponents;

  const payload: GetAggregatedDataPayload = {
    filter: query,
    aggregations: [
      {
        aggName: AggregationType.ATTRIBUTE_NAME,
        sorting: mapGridSortingToApiFormat([{ field: 'docCount', order: 'desc' }]),
        paging: {
          limit,
          skip,
        },
        isGrid: true,
      },
    ],
  };

  const { aggregations } = await getAggregatedData(payload);

  if (!aggregations?.[0]) {
    return {
      aggData: [],
      totalCount: 0,
    };
  }

  const { aggData } = aggregations[0];
  return {
    aggData,
    totalCount: aggData.length,
  };
};

export const fetchCaseAffectedObjects = async ({
  dataSourceName,
  policyName,
  queryComponents,
  requireTotalCount,
}: CaseQuery): Promise<ResponseFromCatalogWithCount> => {
  const gridFilters = queryComponents.filter;
  const caseFilter = getCaseQuery(dataSourceName, policyName, gridFilters);
  const query = objectToQueryString({
    ...(queryComponents as QueryParams),
    requireTotalCount,
    filter: caseFilter,
    sort: JSON.stringify(queryComponents.sort),
  });
  const catalogHeaders = getDataCatalogHeaders();
  const catalogQueryResult = await getDataCatalogRecords(`&${query}${catalogCountTimeoutQueryParam}`, catalogHeaders);
  const { results, estimatedCount, totalRowsCounter } = catalogQueryResult;
  return {
    data: results,
    totalCount: estimatedCount || totalRowsCounter,
  };
};

export const fetchCountForObjects = async ({ dataSourceName, policyName, queryComponents }: any) => {
  const caseFilter = getCaseQuery(dataSourceName, policyName);
  const query = objectToQueryString({
    ...(queryComponents as QueryParams),
    filter: caseFilter,
    requireTotalCount: false,
    sort: JSON.stringify(queryComponents.sort),
  });
  const catalogHeaders = getDataCatalogHeaders();
  const count: DataCatalogCountResponse = await getDataCatalogRecordsCount(query, catalogHeaders);
  return count;
};

const getCaseQuery = (dataSourceName: string, policyName: string, filters?: any): string => {
  const DEFAULT_QUERRY = `SYSTEM IN ("${dataSourceName}") AND policy IN ("${policyName}")`;
  if (filters?.length > 0) {
    let queryWithFilters = DEFAULT_QUERRY;
    filters?.map((filter: any) => {
      const { field, value } = filter;
      switch (field) {
        case 'owner':
          queryWithFilters += ` AND owner IN("${value.join('","')}")`;
          break;
        case 'sensitivity':
          queryWithFilters += ` AND catalog_tag.system.sensitivityClassification.Sensitivity IN("${value}")`;
          break;
      }
      return filter;
    });
    return queryWithFilters;
  } else {
    return DEFAULT_QUERRY;
  }
};

const getCaseInventoryQuery = (dataSourceName: string, policyName: string): string => {
  return `(system IN ("${dataSourceName}") AND policyName IN ("${policyName}"))`;
};

const getClassificationCatalogFilter = (levelName: string): string => {
  return `catalog_tag.${SENSITIVITY_CLASSIFICATION_TAG_NAME} IN ("${levelName}")`;
};

export const adjustAffectedObjectsDataToGrid = (results: DataCatalogRecord[]): CaseReportAffectedObjects[] => {
  return results.map(record => {
    const { id, attribute = [], tags = [], objectType, owner, fullObjectName, objectName, fullyQualifiedName } = record;
    let sensitivity = '';

    const attributes: ChipsFormatterProps = {
      chips: {
        value: attribute.map(attr => ({
          label: attr,
        })),
        isDisabled: true,
        isAutoFit: false,
      },
    };

    const manualTags: TagsFormatterProps = {
      tags: {
        value: tags
          .reduce((tagsFiltered, tag) => {
            if (tag.tagName == SENSITIVITY_CLASSIFICATION_TAG_NAME) {
              sensitivity = tag.tagValue;
            }
            if (tag.tagType === TagAssignmentTarget.object) {
              return [tag, ...tagsFiltered];
            } else {
              return tagsFiltered.find(t => t.tagName === tag.tagName && t.tagValue === tag.tagValue)
                ? tagsFiltered
                : [...tagsFiltered, tag];
            }
          }, [])
          .filter(({ properties }) => !properties?.hidden)
          .map(({ tagName, tagValue, tagType, properties }) => ({
            name: getTagFormattedName(tagName),
            value: tagValue,
            iconDescription: tagType === TagAssignmentTarget.column ? tagType : undefined,
            icon: getTagIcon(properties, tagType),
          })),
        isDisabled: true,
        isAutoFit: false,
        entityMaxWidth: 150,
        tagBgColor: BigidColors.purple[50],
      },
    };

    return {
      id,
      name: objectName,
      path: fullObjectName,
      type: objectType,
      sensitivity,
      owner,
      attributes,
      tags: manualTags,
      fqn: fullyQualifiedName,
    };
  });
};

export const fetchViolationLocation = async (
  dataSourceName: string,
  policyName: string,
  intervalNumber: React.MutableRefObject<number>,
): Promise<TopViolationsLocationData> => {
  try {
    const data: HotspotData[] = [];
    const attributesArray = await getFilteredAttrForPolicies(policyName);
    const attributesSet = new Set(attributesArray);

    if (isFeatureFlagEnabled('ENABLE_HOTSPOTS_REPORT')) {
      await createHotspotsReport({ data_sources: [dataSourceName] });
    }
    const { scanResult, attributes } = await performScan(intervalNumber, [dataSourceName], true);
    const scanAttributesFilteredByPolicy = attributes.filter(attribute => attributesSet.has(attribute));

    if (scanAttributesFilteredByPolicy.length !== 0) {
      const query = createHotspotFilterQuery(scanAttributesFilteredByPolicy);
      const hotspots: Hotspot[] = await getHotspotsCounters(scanResult.id, query);
      const hotspotChartRows: HotspotChartRow[] = hotspots.map(hotspot => hotspotToHotspotChartRow(hotspot));
      const hotspotChartRowsFilteredByAttr: HotspotChartRow[] = hotspotChartRows.map(hotspotChartRow => ({
        ...hotspotChartRow,
        attributesArray,
      }));
      const totalViolations = hotspotChartRowsFilteredByAttr.reduce(
        (currSum, hotspot) => currSum + hotspot.totalAttrTag,
        0,
      );
      for (let i = 0; i < MAX_LOCATIONS_TO_DISPLAY + 1; i++) {
        if (hotspotChartRows.length > i && calculateTotalPercentage(i, data) < MAX_VIOLATIONS_PERCENTAGE) {
          data.push(calculateTopViolationsLocations(hotspotChartRows[i], totalViolations));
        }
      }
    }

    return { data };
  } catch (e) {
    console.error(`ERROR when creating a report ${e}`);
    throw new Error(e);
  }
};

const createHotspotFilterQuery = (attributes: string[]): string => {
  const attributesList = attributes.map(attribute => `"${attribute}"`).join(', ');
  return objectToQueryString({
    filter: `attributes IN (${attributesList})`,
  });
};

const calculatePercentage = (value: number, sum: number): number => {
  return Math.floor((value * 100) / sum);
};

const calculateTotalPercentage = (currPos: number, data: HotspotData[]): number => {
  return data.slice(0, currPos).reduce((acc, { percentage }) => acc + percentage, 0);
};

const calculateTopViolationsLocations = (hotspotChartRow: HotspotChartRow, sum: number) => {
  const percentage = calculatePercentage(hotspotChartRow.totalAttrTag, sum);
  const hotspotNameSplit = hotspotChartRow.hotspotName.split('/');
  const hotspotNameWithoutDS = hotspotNameSplit.length > 1 ? hotspotNameSplit.slice(1).join('/') : hotspotNameSplit[0];
  return {
    ...hotspotChartRow,
    hotspotName: hotspotNameWithoutDS,
    percentage: percentage,
  };
};

export const getFilteredAttrForPolicies = async (policyName: string): Promise<any> => {
  const { data } = await httpService.fetch(`get-query-filter-attribute-list?filter=policy="${policyName}"`);
  return data?.data?.attributeList || [];
};

export const getActionTypeForStatus = (status: CaseStatus) => {
  switch (status) {
    case CaseStatus.SILENCED:
      return ActionsDialogTypes.SILENCE;
    case CaseStatus.REMEDIATED:
      return ActionsDialogTypes.REMEDIATE;
    case CaseStatus.ACKNOWLEDGED:
      return ActionsDialogTypes.ACKNOWLEDGE;
  }
};

export const getDisallowedOptionsForCurrentStatus = (status: CaseStatus): CaseStatus[] => {
  switch (status) {
    case CaseStatus.SILENCED:
    case CaseStatus.ACKNOWLEDGED:
      return [CaseStatus.SILENCED, CaseStatus.ACKNOWLEDGED, CaseStatus.REMEDIATED];
    case CaseStatus.OPEN:
      return [CaseStatus.OPEN];
    case CaseStatus.REMEDIATED:
      return [CaseStatus.SILENCED, CaseStatus.ACKNOWLEDGED, CaseStatus.REMEDIATED, CaseStatus.OPEN];
  }
};

export const getSelectionOptionsForSelectedType = (
  currentSelection: CaseStatus,
  isolationMode?: boolean,
): BigidDropdownOption[] => {
  const selectionOptions = Object.keys(CaseStatus).map(key => {
    return {
      displayValue: tCaseStatus(CaseStatus[key as keyof typeof CaseStatus]),
      value: CaseStatus[key as keyof typeof CaseStatus],
      id: CaseStatus[key as keyof typeof CaseStatus],
    };
  });

  if (isolationMode) {
    return [selectionOptions.find(option => option.value === currentSelection)];
  } else {
    const disallowedOptions = getDisallowedOptionsForCurrentStatus(currentSelection);
    return selectionOptions.filter(option => !disallowedOptions.includes(option.value));
  }
};

export const getCaseActions = async (policyName: string, dataSourceType: string, caseId: string): Promise<any> => {
  const {
    data: { data },
  } = await httpService.fetch(`actionable-insights/actions`, {
    caseId,
    policyName,
    dataSourceType,
  });
  return { data };
};

export const getDsOwners = async (dsConnection: DataSourceConnection): Promise<any> => {
  const mapUsers = (allUsers: string[] | User[]) => {
    const listOfOwners = allUsers.map((user: string | User) => {
      if (typeof user === 'string') {
        return user;
      }
      if (typeof user === 'object') {
        return user?.id;
      }
    });

    return listOfOwners;
  };
  const isOwnersExist = dsConnection?.owners?.length > 0 || dsConnection?.owners_v2?.length > 0;
  if (isOwnersExist) {
    const mappedUsers = mapUsers(dsConnection?.owners || dsConnection?.owners_v2);
    const {
      data: { users },
    } = await rolesService.getRBACUsers(
      getUsersQuery({ filter: [{ field: 'name', operator: 'in', value: mappedUsers }] }),
    );
    const allUsers = mappedUsers
      .map((mappedUser: string) => {
        const receivedUser = users.find((user: User) => user?.id === mappedUser);
        return receivedUser;
      })
      .filter((user: User) => Boolean(user));
    return allUsers;
  } else {
    return [];
  }
};

export const getInfoForUser = (user: User): string => {
  if (!Boolean(user?.firstName) && !Boolean(user?.lastName)) {
    return user?.id;
  }
  return `${user?.firstName ?? ''} ${user?.lastName ?? ''}`;
};

export const mapTags = (allPairs: TagEntity[], tags: TagEntity[]): TagEntity[] => {
  const dsTags = tags
    .map((tag: TagEntity) => {
      const tagValue = allPairs.find((pair: TagEntity) => pair.valueId === tag.valueId)?.tagValue;
      const tagObjectWithName = allPairs.find((pair: TagEntity) => pair.tagId === tag.tagId);
      return {
        ...tagObjectWithName,
        tagValue,
      };
    })
    .filter((tag: TagEntity) => tag?.tagName && tag?.tagValue);

  return dsTags;
};

export const getSensitivityLevel = (sensitivity: string): BigidSensitivityBadgeLevel => {
  const sensitivityMap: Record<SensitivityLevels, BigidSensitivityBadgeLevel> = {
    [SensitivityLevels.RESTRICTED]: 'critical',
    [SensitivityLevels.CONFIDENTIAL]: 'high',
    [SensitivityLevels.INTERNAL_USE]: 'medium',
    [SensitivityLevels.PUBLIC]: 'low',
    [SensitivityLevels.NONE]: 'none',
    [SensitivityLevels.DISABLED]: 'disabled',
  };

  return sensitivityMap[sensitivity as keyof Record<SensitivityLevels, BigidSensitivityBadgeLevel>] || 'none';
};

export const getDsConnectionsInfo = (DsName: string) => {
  return httpService.fetch(`ds_connections/${DsName}`);
};

const getDataCatalogHeaders = () => {
  const headers = {};
  const supportCatalogFoldersEnabled = isFeatureFlagEnabled('DSPM_SUPPORT_CATALOG_FOLDERS');
  return supportCatalogFoldersEnabled ? { ...headers, 'catalog-entity': 'ALL' } : headers;
};

export const isFeatureFlagEnabled = (featureFlagName: keyof AppPreferences): boolean => {
  const featureFlagValueFromConfig = getApplicationPreference(featureFlagName);
  return featureFlagValueFromConfig === true || featureFlagValueFromConfig === 'true';
};
