import {
  DashboardCommonContainer,
  BigidHeading6,
  BigidHorizontalBar,
  BigidBody1,
  BigidLoader,
} from '@bigid-ui/components';
import React, { useEffect, useState, useMemo } from 'react';
import { httpService } from '../../../../react/services/httpService';
import makeStyles from '@mui/styles/makeStyles';
import { $state } from '../../../../react/services/angularServices';
import { notificationService } from '../../../../react/services/notificationService';
import { CONFIG } from '../../../../config/common';
import { capitalizeFirstLetter } from '../../../../react/utilities/textualConverter';
import { generateDataAid } from '@bigid-ui/utils';
import { ACTIONABLE_INSIGHTS_PREFERENCE_IDENTIFIER_PREFIX } from '../../ActionableInsights/actionableInsightsService';
import { PageFilterPreferences, userPreferencesService } from '../../../services/userPreferencesService';
import {
  ActionableInsightsTrackingEvents,
  trackActionableInsightsEvent,
} from '../../ActionableInsights/actionableInsightsTrackingUtil';
import { useTheme } from '@mui/styles';

const useStyles = makeStyles({
  main: {
    minHeight: 245,
    padding: '12px 16px',
    display: 'grid',
    gridTemplateRows: '24px 1fr',
    rowGap: 20,
  },
  contentWrapper: {
    display: 'grid',
    gridTemplateColumns: '100%',
    alignItems: 'center',
    justifyItems: 'stretch',
    rowGap: 16,
    columnGap: 0,
  },
  caseWrapper: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around',
    cursor: 'pointer',

    '& > div': {
      display: 'grid',
      gridTemplateColumns: 'auto minmax(8px, 40px) 60px',
    },
    '& > div > div': {
      height: '12px',
    },
    '& > div > div > div': {
      height: '12px',
    },
  },
  noData: {
    justifySelf: 'center',
    paddingBottom: 20,
  },
});

export enum SeverityLabelCases {
  CRITICAL = 'critical',
  HIGH = 'high',
  MEDIUM = 'medium',
  LOW = 'low',
}

export type SeverityWidgetProps = {
  severityLevel: SeverityLabelCases;
  value: number;
  color: string;
};

export const ListCasesBySeverityWidget = () => {
  const classes = useStyles();
  const theme = useTheme();

  const defaultWidgetSchema = useMemo(
    (): SeverityWidgetProps[] => [
      { severityLevel: SeverityLabelCases.CRITICAL, value: 0, color: theme.vars.palette.bigid.red500 },
      { severityLevel: SeverityLabelCases.HIGH, value: 0, color: theme.vars.palette.bigid.orange400 },
      { severityLevel: SeverityLabelCases.MEDIUM, value: 0, color: theme.vars.palette.bigid.yellow500 },
      { severityLevel: SeverityLabelCases.LOW, value: 0, color: theme.vars.palette.bigid.green200 },
    ],
    [theme],
  );

  const [severityCases, setSeverityCases] = useState<SeverityWidgetProps[]>(defaultWidgetSchema);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const redirectHandler = async (severity: SeverityLabelCases) => {
    const newFilter: PageFilterPreferences = {
      filter: [{ field: 'severityLevel', value: severity, operator: 'in' }],
    };
    const toUpdate = {
      preference: `${ACTIONABLE_INSIGHTS_PREFERENCE_IDENTIFIER_PREFIX}.open`,
      filter: newFilter,
    };
    trackActionableInsightsEvent(ActionableInsightsTrackingEvents.SMALLID_DASHBOARD_CASES_BY_SEVERITY_WIDGET_CLICK, {
      data: severityCases,
    });
    await userPreferencesService.update(toUpdate);
    $state.go(CONFIG.states.ACTIONABLE_INSIGHTS);
  };

  const totalCasesSummary: number = severityCases.reduce((acc, el) => (acc += el.value), 0);

  const MainContent =
    totalCasesSummary !== 0 ? (
      <>
        {severityCases.map((el: SeverityWidgetProps) => (
          <div
            data-aid={generateDataAid('ListCasesBySeverityWidget', [el.severityLevel])}
            onClick={() => redirectHandler(el.severityLevel)}
            className={classes.caseWrapper}
            key={el.severityLevel}
          >
            <BigidHorizontalBar
              forceTotal={totalCasesSummary}
              values={[
                {
                  type: capitalizeFirstLetter(el.severityLevel),
                  value: el.value,
                  color: el.color,
                },
              ]}
              isBarCentered={false}
            />
          </div>
        ))}
      </>
    ) : (
      <BigidBody1 className={classes.noData}>No open cases available</BigidBody1>
    );

  useEffect(() => {
    const requestCasesBySeverity = async () => {
      setIsLoading(true);
      try {
        const {
          data: { data },
        } = await httpService.fetch('actionable-insights/cases-by-severity?caseStatus=open');
        const modifiedData: SeverityWidgetProps[] = defaultWidgetSchema.map(el => {
          const currentElement = data.find(
            (element: SeverityWidgetProps) => element.severityLevel === el.severityLevel,
          );

          return { ...currentElement, color: el.color } || el;
        });
        setSeverityCases(modifiedData);
        setIsLoading(false);
      } catch (error) {
        notificationService.error('Error in fetching cases by severity');
        console.log('Error in fetching cases by severity');
        setIsLoading(false);
      }
    };
    requestCasesBySeverity();
  }, [defaultWidgetSchema]);

  return (
    <DashboardCommonContainer
      dataAid={generateDataAid('BigidDashboardWidget', ['severity-cases'])}
      className={classes.main}
    >
      <BigidHeading6>Open Data Risks</BigidHeading6>
      <div className={classes.contentWrapper}>{isLoading ? <BigidLoader position="relative" /> : MainContent}</div>
    </DashboardCommonContainer>
  );
};
