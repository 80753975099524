import { rolesService, systemUsersService } from '../../services/angularServices';
import { showGenericApiErrorNotification } from './utils';
import { CreateUserModel, SystemUsersData, SystemUser, SystemRole, UserToken } from './types';

interface GenerateRefreshTokenArgs {
  userId: string;
  expiresInDays: string;
}

interface CreateUserResponse {
  config: {
    data: SystemUser;
  };
  data: {
    message?: string;
  };
}

interface GenerateRefreshTokenResponse {
  token: UserToken;
  expiresInDays: string;
}

export const fetchUsers = async (query: string): Promise<SystemUsersData> => {
  try {
    const {
      data: { users, totalCount },
    } = await rolesService.getRBACUsers(query);

    return { data: users, totalCount };
  } catch (error) {
    showGenericApiErrorNotification();

    return { totalCount: 0, data: [] };
  }
};

export const fetchAuthUser = async (): Promise<SystemUser> => {
  try {
    const { data } = await systemUsersService.getAuthUser();

    return data;
  } catch (error) {
    showGenericApiErrorNotification();
  }
};

export const fetchRoles = async (): Promise<SystemRole[]> => {
  try {
    const {
      data: { roles },
    } = await rolesService.getRBACRoles();

    return roles;
  } catch (error) {
    showGenericApiErrorNotification();

    return [];
  }
};

export const deleteUser = (id: string) => systemUsersService.deleteSystemUserById(id);

export const updateUser = (user: SystemUser) => systemUsersService.updateSystemUserById(user.name, user);

export const createUser = (newUser: CreateUserModel): Promise<CreateUserResponse> =>
  systemUsersService.createSystemUser(newUser);

export const generateRefreshToken = async ({
  userId,
  expiresInDays,
}: GenerateRefreshTokenArgs): Promise<GenerateRefreshTokenResponse> => {
  try {
    const { data } = await systemUsersService.generateRefreshToken(userId, expiresInDays);

    return { token: data, expiresInDays };
  } catch (error) {
    showGenericApiErrorNotification();
  }
};
