import { trendSnapshotsService, privacyRiskMapService } from '../../../services/angularServices';
import { DashboardLineChartContainer } from './DashboardLineChartContainer/DashboardLineChartContainer';
import { DashboardMapChartContainer } from './DashboardMapChartContainer/DashboardMapChartContainer';
import { getAmMapData } from './dashboardMapService';
import { orderBy, take, dropRight, forEach } from 'lodash';
import {
  CATALOG_PERMISSIONS,
  POLICIES_PERMISSIONS,
  APPLICATIONS_PERMISSIONS,
  DATA_SOURCES_PERMISSIONS,
} from '@bigid/permissions';
import { differenceInDays } from 'date-fns';

interface DashboardChartDataMapInterface {
  value: string;
}

const RANGES = [10, 100, 1000, 10000, 100000, 1000000, 10000000];
const getMaxRangeValue = (maxValue: number): number =>
  RANGES.reduce(
    (acc, range, index) => (range < maxValue ? RANGES[Math.min(index + 1, RANGES.length - 1)] : acc),
    RANGES[0],
  );

const MAX_DAYS_BACK = 90;
const isInDataRange = (date: Date, startDate: string) =>
  differenceInDays(new Date(startDate), new Date(date)) < MAX_DAYS_BACK;

const normalizeTrendChartData = (data: any, mapAttributes: DashboardChartDataMapInterface) => {
  const result = data.reduce(
    (dataAcc: { max: number; data: any[] }, item: any) => {
      const pointEntity: any = {};
      pointEntity.date = new Date(item?.date).toDateString();
      const isInRange = isInDataRange(
        pointEntity.date,
        new Date(data[data.length - 1]?.date ? data[data.length - 1]?.date : new Date()).toDateString(),
      );
      let max: number = dataAcc.max;
      Object.entries(mapAttributes).map(([key, itemKey]) => {
        pointEntity[key] = item[itemKey];
        max = isInRange && item[itemKey] > max ? item[itemKey] : max;
      });

      return {
        max,
        data: isInRange ? [...dataAcc.data, pointEntity] : dataAcc.data,
      };
    },
    { max: 0, data: [] },
  );

  const startDate = result?.data?.length && result.data[0]?.date;
  if (startDate && differenceInDays(new Date(result.data[result.data.length - 1]?.date), new Date(startDate)) < 30) {
    const diff = 31 - result.data.length;
    for (let daysForAdd = 1; daysForAdd < diff; daysForAdd++) {
      result.data = [
        {
          date: new Date(new Date(startDate).setDate(new Date(startDate).getDate() - daysForAdd)).toDateString(),
          [Object.keys(mapAttributes)[0]]: null,
        },
        ...result.data,
      ];
    }
  }
  return result;
};

const fetchRiskTrendData = async () => {
  const result = await trendSnapshotsService.getRiskTrendSnapshots();
  const riskSnapshots = result.risk_snapshots.map((riskSnapshot: any) => {
    riskSnapshot.risk = Math.round(riskSnapshot.risk);
    return riskSnapshot;
  });
  return { data: normalizeTrendChartData(riskSnapshots, { value: 'risk' }).data, maxValue: 100 };
};

const fetchAttributeTrendData = async () => {
  const result = await trendSnapshotsService.getAttributesTrendSnapshots();
  const attributesSnapshots = result.attributesSnapshots.reverse();
  const { data, max } = normalizeTrendChartData(attributesSnapshots, { value: 'count' });
  return { data, maxValue: getMaxRangeValue(max) };
};

const MAX_POLICY_ITEMS = 4;
const POLICY_COLORS = ['#EA2165', '#FF9253', '#667EE3', '#4A4A4A'];
const fetchPolicyTrendData = async () => {
  const result = await trendSnapshotsService.getActivityHighlightsTrendSnapshots();
  const policyData = result.data.reduce(
    (acc: any, item: any) => {
      if (item?.snapshot?.detailedInformation?.policies?.triggeredPolicyByCategory?.length) {
        const series = new Set([...acc.series]);
        const pointEntity: any = {};
        pointEntity.date = new Date(item?.date).toDateString();
        const isInRange = isInDataRange(pointEntity.date, new Date(result.data[0]?.date).toDateString());
        const isUpdatePrev = pointEntity.date === acc.data[acc.data.length - 1]?.date;
        const maxFindings = take(
          orderBy(item?.snapshot?.detailedInformation?.policies?.triggeredPolicyByCategory, ['findings'], ['asc']),
          MAX_POLICY_ITEMS,
        );
        const maxValue: Record<any, number> = {};
        forEach(maxFindings, ({ policyCategory, findings }) => {
          series.add(policyCategory);
          pointEntity[policyCategory] = findings;
          maxValue[policyCategory] =
            !acc?.maxValue[policyCategory] || findings > acc?.maxValue[policyCategory]
              ? findings
              : acc?.maxValue[policyCategory];
        });

        return {
          ...acc,
          data: isInRange ? [...(isUpdatePrev ? dropRight(acc.data, 1) : acc.data), pointEntity] : acc.data,
          series,
          maxValue,
        };
      }
      return acc;
    },
    {
      series: new Set(),
      data: [],
      maxValue: {},
    },
  );

  const series: string[] = Array.from(policyData.series);
  const { colorsMap, titlesMap } = series.reduce(
    ({ colorsMap, titlesMap }, name: string, index) => {
      return { colorsMap: { ...colorsMap, [name]: POLICY_COLORS[index] }, titlesMap: { ...titlesMap, [name]: name } };
    },
    {
      colorsMap: {},
      titlesMap: {},
    },
  );

  const maxValue = series.reduce((acc, name) => ({ ...acc, [name]: getMaxRangeValue(policyData.maxValue[name]) }), {});

  return {
    data: policyData.data,
    titlesMap,
    colorsMap,
    series,
    maxValue,
  };
};

const fetchMapData = async () => {
  const result = await privacyRiskMapService.getPrivacyRiskMapResults(null, true);
  return getAmMapData(result);
};

export const RISK_DASHBOARD_CHART_OPTIONS = {
  id: 'RISK_DASHBOARD_CHART',
  titlesMap: {
    header: 'Risk Over Time',
    main: 'Risk Level',
    value: 'Risk',
  },
  colorsMap: {
    value: '#667EE3',
  },
  series: ['value'],
  fetchData: fetchRiskTrendData,
  Component: DashboardLineChartContainer,
  permission: APPLICATIONS_PERMISSIONS.READ_RISK_CONFIGURATION.name,
  maxValue: 100,
};

export const ATTRIBUTES_DASHBOARD_CHART_OPTIONS = {
  id: 'ATTRIBUTES_DASHBOARD_CHART',
  titlesMap: {
    header: 'Attributes Over Time',
    main: 'Number of attributes',
    value: 'Attributes',
  },
  colorsMap: {
    value: '#EA2165',
  },
  series: ['value'],
  fetchData: fetchAttributeTrendData,
  Component: DashboardLineChartContainer,
  permission: CATALOG_PERMISSIONS.ACCESS.name,
};

export const POLICY_DASHBOARD_CHART_OPTIONS = {
  id: 'POLICY_DASHBOARD_CHART',
  titlesMap: {
    header: 'Policies Over Time',
    main: 'Number of findings',
  },
  fetchData: fetchPolicyTrendData,
  Component: DashboardLineChartContainer,
  permission: POLICIES_PERMISSIONS.ACCESS.name,
};

export const MAP_DASHBOARD_CHART_OPTIONS = {
  id: 'MAP_DASHBOARD_CHART',
  fetchData: fetchMapData,
  Component: DashboardMapChartContainer,
  imageSeries: [
    {
      title: 'Systems & Data Sources',
      color: '#D269DA',
      id: 'system',
    },
    {
      title: 'Assets',
      color: '#667EE3',
      id: 'application',
    },
    {
      title: 'Individual Residences',
      color: '#6DB548',
      id: 'user',
    },
  ],
  lineSeries: [
    {
      color: 'rgb(130, 91, 246)',
      id: 'system',
      width: 0.3,
    },
  ],
  height: 330,
  permission: DATA_SOURCES_PERMISSIONS.READ.name,
};
