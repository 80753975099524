import './testConnection.scss';
import template from './testConnection.html';
import { module } from 'angular';
const app = module('app');

class BigidButtonTestConnection {
  constructor() {
    this.btnConnectionStatus = undefined;
    this.btnIsDisabled = undefined;
    this.btnOnClick = undefined;
  }

  showConnectionStatusIcon() {
    return typeof this.btnConnectionStatus === 'boolean';
  }

  getTestConnectionWrapperClass() {
    switch (this.btnConnectionStatus) {
      case true:
        return 'status--success';
      case false:
        return 'status--failed';
      default:
        return '';
    }
  }
}

/**
 *  Reusable generic button to perform test with SUCCESS and FAIL statuses
 * For more info see bindings options below
 */
app.component('bigidButtonTestConnection', {
  template,
  transclude: true,
  controller: BigidButtonTestConnection,
  /**
   * Please use prefix in this component bindings,
   * to avoid attribute names collisions with Native and Popular UI frameworks such as ui-bootstrap etc...
   */
  bindings: {
    btnBright: '<',
    btnConnectionStatus: '<',
    btnIsDisabled: '<',
    btnClasses: '<',
    btnOnClick: '&',
  },
});
