import { useState, useCallback } from 'react';
import { EditDataSourceDialogProps } from './EditDataSourcesDialog';

interface UseEditDataSourceDialog {
  dialogProps: EditDataSourceDialogProps;
  openDialog: (
    dialogProps: Pick<EditDataSourceDialogProps, 'profileId' | 'sources' | 'isSelectionDisabled'>,
  ) => Promise<boolean>;
}

export const useEditDataSourceDialog = (): UseEditDataSourceDialog => {
  const [dialogProps, setDialogProps] = useState<EditDataSourceDialogProps>(getClearDialogState());

  const openDialog = useCallback<UseEditDataSourceDialog['openDialog']>(
    ({ profileId, sources, isSelectionDisabled }) => {
      return new Promise<boolean>(resolve => {
        setDialogProps({
          profileId,
          sources,
          isSelectionDisabled,
          isOpen: true,
          isLoading: false,
          onSave: onSaveResult => {
            const { status } = onSaveResult;
            if (status.isOk) {
              setDialogProps(getClearDialogState());
            }
            resolve(status.isOk);
          },
          onClose: () => {
            setDialogProps(getClearDialogState());
            resolve(false);
          },
        });
      });
    },
    [],
  );

  return {
    openDialog,
    dialogProps,
  };
};

const getClearDialogState = (): EditDataSourceDialogProps => {
  return {
    profileId: null,
    isOpen: false,
    isLoading: false,
    sources: [],
  };
};
