import React, { useEffect, useRef } from 'react';
import { BigidPageLayout, BigidTabsItem, PrimaryButton } from '@bigid-ui/components';
import { BigidAddIcon } from '@bigid-ui/icons';
import { Users } from './tabs/users/Users';
import { pageHeaderService } from '../../../common/services/pageHeaderService';
import { useLocalTranslation } from './translations';
import { $state } from '../../services/angularServices';
import { useUpdatedStateParams } from '../../hooks/useUpdatedStateParams';
import { CONFIG } from '../../../config/common';
import { BigidPageFavoriteToggler } from '../../components/BigidHeader/BigidPageTitle/BigidPageFavoriteToggler';
import { UsersForwardActions } from './types';
import { useAccessManagementContext } from './AccessManagementContext';

export const IdentityAccessManagementComponent = () => {
  const usersComponentRef = useRef<UsersForwardActions>(null);
  const { t } = useLocalTranslation();
  const { permissions, serviceConfigurations } = useAccessManagementContext();

  useEffect(() => {
    pageHeaderService.setIsHidden(true);
    return () => {
      pageHeaderService.setIsHidden(false);
    };
  }, []);

  const openCreateUserDialog = () => {
    usersComponentRef.current.openCreateUserDialog?.();
  };

  const tabs = [
    {
      label: t('tabs.users.label'),
      subRoute: 'systemUsers',
      component: <Users ref={usersComponentRef} />,
      layoutActions: [
        permissions.manage && (
          <PrimaryButton onClick={openCreateUserDialog} key="createUser" size="large" startIcon={<BigidAddIcon />}>
            {t('tabs.users.actions.createUser')}
          </PrimaryButton>
        ),
      ],
      enabled: permissions.read,
    },
    {
      label: t('tabs.roles.label'),
      subRoute: 'roles',
      enabled: permissions.read && !serviceConfigurations.enableCloudManager,
    },
    {
      label: t('tabs.scopes.label'),
      subRoute: 'scopes',
      enabled: permissions.read && !serviceConfigurations.enableCloudManager,
    },
    {
      label: t('tabs.idp.label'),
      subRoute: 'idp',
      enabled: permissions.readIDP && serviceConfigurations.enableCustomerIdp,
    },
  ];

  const enabledTabs = tabs.filter(tab => tab.enabled);
  const { currentTab } = useUpdatedStateParams();
  const currentTabIndex = enabledTabs.findIndex(tab => tab.subRoute === currentTab);
  const selectedTab = enabledTabs[currentTabIndex];

  const onNavigationChanged = (_: number, changeTab: BigidTabsItem) => {
    const nextTab = enabledTabs.find(({ label }) => label === changeTab.label);
    $state.go(CONFIG.states.IDENTITY_ACCESS_MANAGEMENT, { currentTab: nextTab.subRoute });
  };

  return (
    <BigidPageLayout
      title={t('title')}
      showHeaderBackground={false}
      pageBackgroundColor="auto"
      titleIcons={[<BigidPageFavoriteToggler key="BigidPageFavoriteToggler" />]}
      actions={selectedTab?.layoutActions}
      tabs={{
        selectedIndex: currentTabIndex,
        onChange: onNavigationChanged,
        tabs: enabledTabs.map(({ label }) => ({ label })),
      }}
    >
      {selectedTab?.component}
    </BigidPageLayout>
  );
};
