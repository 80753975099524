import {
  BigidAppLogoAwsIcon,
  BigidAppLogoAzureIcon,
  BigidAppLogoGcpIcon,
  BigidAppLogoNetappOntapIcon,
} from '@bigid-ui/icons';
import { ExecutionStatus } from '../../CustomApp/components/PresetCard/PresetLatestExecutionStatus';
import { AUTH_TYPE_FIELD_NAME, DiscoveryAuthTypes } from './auth';
import { CloudProvider } from './types';
import { BigidStatusBadgeType } from '@bigid-ui/components';

export const ASSESSMENT_SCAN_KEY_NAME = 'run_scan';
export const cloudProviderLabel: Record<CloudProvider, string> = {
  [CloudProvider.AWS]: 'AWS',
  [CloudProvider.AZURE]: 'Azure',
  [CloudProvider.GCP]: 'GCP',
  [CloudProvider.ONTAP]: 'NetApp ONTAP',
};

export const AUTH_SECTION_NAME = 'auth';

export const CONNECTION_SECTION_NAME = 'Connection Configuration';

export const AUTO_DISCOVER_PREREQUISITES_CONTENT_ID = 'autoDiscoverPrerequisitesContentIdForCopy';

export const STATUS_TO_LABEL: Record<any, string> = {
  [ExecutionStatus.COMPLETED]: 'Success',
  [ExecutionStatus.ERROR]: 'Failed',
  [ExecutionStatus.IN_PROGRESS]: 'In progress ',
  [ExecutionStatus.STOPPED]: 'Stopped',
  undefined: 'Draft',
};

export const TYPE_TO_ICON = {
  [CloudProvider.AWS]: BigidAppLogoAwsIcon,
  [CloudProvider.AZURE]: BigidAppLogoAzureIcon,
  [CloudProvider.GCP]: BigidAppLogoGcpIcon,
  [CloudProvider.ONTAP]: BigidAppLogoNetappOntapIcon,
};

export interface DiscoveryDsTypeOption {
  label: string;
  value: string;
}

export const DS_LIST_BY_TYPE: Record<CloudProvider, Record<string, DiscoveryDsTypeOption>> = {
  [CloudProvider.AWS]: {
    aws_auto_s3: { label: 'S3', value: 's3' },
    aws_auto_athena: { label: 'Athena', value: 'athena' },
    aws_auto_dynamoDb: { label: 'DynamoDB', value: 'dynamodb' },
    aws_auto_kinesis: { label: 'Kinesis', value: 'kinesis' },
    aws_auto_redshift: { label: 'Redshift', value: 'redshift' },
    aws_auto_rds: { label: 'RDS', value: 'rds' },
    aws_auto_documentDb: { label: 'DocumentDB', value: 'documentdb' },
    aws_auto_efs: { label: 'Amazon EFS', value: 'efs' },
    aws_auto_neptune: { label: 'Amazon Neptune', value: 'neptune' },
  },
  [CloudProvider.AZURE]: {
    Azure_Blob_Storage: { label: 'Azure-Blob', value: 'blob' },
    Azure_File_Storage: { label: 'Azure File Storage', value: 'storage' },
    Azure_ADLS_Gen2: { label: 'Azure Data Lake - Gen2', value: 'adls2' },
    Azure_Databricks: { label: 'Databricks', value: 'databricks' },
    Azure_MongoDB: { label: 'MongoDB', value: 'mongodb' },
    Azure_Cassandra: { label: 'Cassandra', value: 'cassandra' },
    Azure_CosmosDB: { label: 'Azure CosmosDB', value: 'cosmosdb' },
    Azure_ADLS_Gen1: { label: 'Azure Data Lake - Gen1', value: 'adls1' },
    Azure_SQL: { label: 'Azure SQL Server', value: 'sqlserver' },
    Azure_Synapse: { label: 'Azure Synapse', value: 'synapse' },
    Azure_MySQL: { label: 'Azure MySQL Server', value: 'sqlserver' },
    Azure_PostgreSQL: { label: 'Postgres SQL', value: 'sqlserver' },
    Azure_OpenAI: { label: 'Azure OpenAI', value: 'openai' },
  },
  [CloudProvider.GCP]: {
    google_cloud_auto_big_query: { label: 'Google Cloud BigQuery', value: 'bigquery' },
    google_cloud_auto_big_table: { label: 'Google Cloud BigTable', value: 'bigtable' },
    google_cloud_auto_datastore: { label: 'Google Datastore', value: 'datastore' },
    google_cloud_auto_memorystore_redis: {
      label: 'Redis',
      value: 'memorystore-redis',
    },
    google_cloud_auto_storage: { label: 'Google Cloud Storage', value: 'cloud storage' },
    google_cloud_auto_spanner: { label: 'Google Spanner', value: 'spanner' },
    google_cloud_auto_sql: { label: 'Google Cloud SQL', value: 'cloud sql' },
    google_cloud_auto_firestore: { label: 'Google Cloud Firestore', value: 'firestore' },
  },
  [CloudProvider.ONTAP]: {
    ontap_auto_nfs_v2: { label: 'NFS', value: 'nfs_v2' },
    ontap_auto_smb_v2: { label: 'SMB', value: 'smb_v2' },
  },
};

export const DEFAULT_FORM_FIELDS_NAMES = ['name', AUTH_TYPE_FIELD_NAME];

export const RUN_ACTION = 'runAction';
export const MULTI_ACTION = 'runMultiAction';

export const DISCOVER_ACTION_TYPE_BY_AUTH_TYPE: Record<
  CloudProvider,
  {
    [key in DiscoveryAuthTypes]?: string;
  }
> = {
  [CloudProvider.AWS]: {
    [DiscoveryAuthTypes.CREDENTIALS]: RUN_ACTION,
    [DiscoveryAuthTypes.CREDENTIALS_MULTI]: MULTI_ACTION,
    [DiscoveryAuthTypes.IAM_ROLE]: RUN_ACTION,
    [DiscoveryAuthTypes.IAM_ROLE_MULTI]: MULTI_ACTION,
  },
  [CloudProvider.AZURE]: {
    [DiscoveryAuthTypes.CREDENTIALS]: MULTI_ACTION,
    [DiscoveryAuthTypes.CREDENTIALS_MULTI]: MULTI_ACTION,
    [DiscoveryAuthTypes.IAM_ROLE]: MULTI_ACTION,
    [DiscoveryAuthTypes.IAM_ROLE_MULTI]: MULTI_ACTION,
  },
  [CloudProvider.GCP]: {
    [DiscoveryAuthTypes.CREDENTIALS]: RUN_ACTION,
  },
  [CloudProvider.ONTAP]: {
    [DiscoveryAuthTypes.CREDENTIALS]: RUN_ACTION,
  },
};

export const CREATED_PRESET_STATUS = 'Preset Was Created';

export const DISCOVERY_STATUS_TO_STATUS_BADGE_TYPE_MAP: Record<any, BigidStatusBadgeType> = {
  [ExecutionStatus.COMPLETED]: BigidStatusBadgeType.SUCCESS,
  [ExecutionStatus.ERROR]: BigidStatusBadgeType.ERROR,
  [ExecutionStatus.STOPPED]: BigidStatusBadgeType.DARK,
  [ExecutionStatus.IN_PROGRESS]: BigidStatusBadgeType.INFO,
  undefined: BigidStatusBadgeType.PENDING,
};

export const ALL_DS_TYPES_SELECTED = 'all';

export const DEFAULT_OPENED_SECTION_IN_DETAILS = 'Connection Configuration';

export enum PrerequisitesPlacement {
  MAIN = 'main',
  SIDE = 'side',
}
export const PREREQUISITES_PLACEMENT_BY_TYPE: Record<
  CloudProvider,
  {
    [key in DiscoveryAuthTypes]?: PrerequisitesPlacement;
  }
> = {
  [CloudProvider.AWS]: {
    [DiscoveryAuthTypes.CREDENTIALS]: PrerequisitesPlacement.MAIN,
    [DiscoveryAuthTypes.CREDENTIALS_MULTI]: PrerequisitesPlacement.MAIN,
    [DiscoveryAuthTypes.IAM_ROLE]: PrerequisitesPlacement.MAIN,
    [DiscoveryAuthTypes.IAM_ROLE_MULTI]: PrerequisitesPlacement.MAIN,
  },
  [CloudProvider.AZURE]: {
    [DiscoveryAuthTypes.CREDENTIALS]: PrerequisitesPlacement.SIDE,
    [DiscoveryAuthTypes.CREDENTIALS_MULTI]: PrerequisitesPlacement.SIDE,
    [DiscoveryAuthTypes.IAM_ROLE]: PrerequisitesPlacement.SIDE,
    [DiscoveryAuthTypes.IAM_ROLE_MULTI]: PrerequisitesPlacement.SIDE,
  },
  [CloudProvider.GCP]: {
    [DiscoveryAuthTypes.CREDENTIALS]: PrerequisitesPlacement.SIDE,
  },
  [CloudProvider.ONTAP]: {
    [DiscoveryAuthTypes.CREDENTIALS]: PrerequisitesPlacement.SIDE,
  },
};
