import React, { PropsWithChildren, createContext, useContext } from 'react';
import { isPermitted } from '../../services/userPermissionsService';
import { ACCESS_MANAGEMENT_PERMISSIONS } from '@bigid/permissions';
import { getApplicationPreference } from '../../services/appPreferencesService';

interface AccessManagementPermissions {
  manageIDP: boolean;
  readIDP: boolean;
  manage: boolean;
  read: boolean;
  access: boolean;
}

interface AccessManagementServiceConfigurations {
  enableCloudManager: boolean;
  enableCustomerIdp: boolean;
  useSaas: boolean;
  enforceStrongPasswordSecurity: boolean;
}

interface AccessManagementContextData {
  permissions: AccessManagementPermissions;
  serviceConfigurations: AccessManagementServiceConfigurations;
}

const initialContextValue: AccessManagementContextData = {
  permissions: {
    manageIDP: false,
    readIDP: false,
    manage: false,
    read: false,
    access: false,
  },
  serviceConfigurations: {
    enableCloudManager: false,
    enableCustomerIdp: false,
    useSaas: false,
    enforceStrongPasswordSecurity: false,
  },
};

const AccessManagementContext = createContext<AccessManagementContextData>(initialContextValue);

export const AccessManagementContextProvider = ({ children }: PropsWithChildren) => {
  const permissions: AccessManagementPermissions = {
    manageIDP: isPermitted(ACCESS_MANAGEMENT_PERMISSIONS.MANAGE_IDP.name),
    readIDP: isPermitted(ACCESS_MANAGEMENT_PERMISSIONS.READ_IDP.name),
    manage: isPermitted(ACCESS_MANAGEMENT_PERMISSIONS.MANAGE.name),
    read: isPermitted(ACCESS_MANAGEMENT_PERMISSIONS.READ.name),
    access: isPermitted(ACCESS_MANAGEMENT_PERMISSIONS.ACCESS.name),
  };

  const serviceConfigurations: AccessManagementServiceConfigurations = {
    enableCloudManager: getApplicationPreference('ENABLE_CLOUD_MERGER'),
    enableCustomerIdp: getApplicationPreference('ENABLE_CUSTOMER_IDP'),
    useSaas: getApplicationPreference('USE_SAAS'),
    enforceStrongPasswordSecurity: getApplicationPreference('ENFORCE_STRONG_PASSWORD_SECURITY'),
  };

  return (
    <AccessManagementContext.Provider value={{ permissions, serviceConfigurations }}>
      {children}
    </AccessManagementContext.Provider>
  );
};

export const useAccessManagementContext = () => {
  const context = useContext(AccessManagementContext);

  if (!context) {
    throw new Error('useAccessManagementContextProvider must be used within a AccessManagementContextProvider');
  }

  return context;
};
