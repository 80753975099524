import React, { FC, useCallback, useEffect, useState } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { BigidLogin } from '@bigid-ui/components';
import { loginService, RemoteUserConfig, SAMLConfig } from '../../../authentication/login/login.service';
import {
  getErrorDisplayMessage,
  goToRequestedPage,
  loginWithSSO,
  setLoginStrategyInSessionStorage,
  validateStringAndCompare,
  shouldDenySamlLogin,
} from './loginUtils';
import { appSettings } from '../../../common/services/app.common.services';
import { sessionStorageService } from '../../../common/services/sessionStorageService';
import { SystemEvents, systemEventsEmitter } from '../../services/systemEvents';
import { publicUrls } from '../../config/publicUrls';
import Cookies from 'js-cookie';

export type CredentialsType = {
  username: string;
  password: string;
};

export interface LoginProps {
  abortDisabled: boolean;
  auth: string;
  error: string;
  logout: boolean;
  userInfoRespond: string;
  samlConf: SAMLConfig;
  remoteUserConf: RemoteUserConfig;
}

const useStyles = makeStyles(
  ({
    vars: {
      palette: { bigid: bigidVars },
    },
  }) => ({
    background: {
      animation: '$login-theme 21s linear infinite',
      position: 'fixed',
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: bigidVars.primary900,
      backgroundImage: `linear-gradient(135deg, ${bigidVars.primary400}, ${bigidVars.primary900})`,
      '&::after, &::before': {
        content: '""',
        display: 'block',
        position: 'fixed',
        zIndex: -1,
        top: 0,
        width: '100vw',
        height: '100vh',
        background: 'rgba(0,0,0,0.05)',
        animation: '$login-background 90s linear infinite',
      },
      '&::after': {
        left: '15vw',
      },
      '&::before': {
        right: '15vw',
        animationDelay: '-30s',
        animationDirection: 'reverse',
      },
    },
    '@keyframes login-theme': {
      '0%': {
        background: bigidVars.primary400,
      },
      '16%': {
        background: bigidVars.primary500,
      },
      '33%': {
        background: bigidVars.primary600,
      },
      '50%': {
        background: bigidVars.primary900,
      },
      '66%': {
        background: bigidVars.primary600,
      },
      '83%': {
        background: bigidVars.primary500,
      },
      '100%': {
        background: bigidVars.primary400,
      },
    },
    '@keyframes login-background': {
      '0%': {
        transform: 'rotate(0deg)',
      },
      '100%': {
        transform: 'rotate(360deg)',
      },
    },
  }),
);

export const Login: FC<LoginProps> = ({ auth, error, logout, userInfoRespond, samlConf, remoteUserConf }) => {
  const classes = useStyles({});
  const PRIVACY_POLICY_URL = publicUrls.BIGID_PRIVACY_NOTICE;
  const baseSamlURL = `${appSettings.serverPath}/${appSettings.version}`;
  const samlIdpURL = `${baseSamlURL}/saml?type=idp`;
  const isCloudIdpEnabled = samlConf?.isCloudIdpEnabled;
  const cloudIdpUrl = `${baseSamlURL}${samlConf?.cloudIdpUrlSuffix}`;
  const isRemoteUserAuthEnabled = remoteUserConf?.isEnabled;
  const isSamlIdpEnabled = samlConf?.idp?.isEnabled;
  const showOnlyIdpLogin = samlConf?.idp?.showOnlyIdpLogin;
  const companyName = (samlConf?.idp?.companyName?.length > 0 ? samlConf.idp.companyName : 'SSO').toUpperCase();
  const showCloudBigidLogin = isCloudIdpEnabled && (!isSamlIdpEnabled || (isSamlIdpEnabled && auth === 'true'));

  const [showAuthentication, setShowAuthentication] = useState<boolean>(false);
  const [showSeparator, setShowSeparator] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [isAuthorizing, setIsAuthorizing] = useState<boolean>(false);
  const [isLoginAttempt, setIsLoginAttempt] = useState<boolean>(false);
  const [denyLogin, setDenyLogin] = useState<boolean>(false);

  setLoginStrategyInSessionStorage(isSamlIdpEnabled, isCloudIdpEnabled);

  const handleSsoAuthorization = useCallback(() => {
    setIsAuthorizing(true);
    setErrorMessage('');
    if (validateStringAndCompare(auth, 'OKTA')) {
      location.replace(cloudIdpUrl);
    } else {
      location.replace(isSamlIdpEnabled ? samlIdpURL : cloudIdpUrl);
    }
  }, [auth, isSamlIdpEnabled, samlIdpURL, cloudIdpUrl]);

  const handleCloudBigidAuthorization = useCallback(() => {
    setIsAuthorizing(true);
    setErrorMessage('');
    location.replace(cloudIdpUrl);
  }, [cloudIdpUrl]);

  const handleSubmit = useCallback(async (credentials?: CredentialsType) => {
    setIsLoading(!!credentials);
    setErrorMessage('');
    setIsLoginAttempt(true);
    try {
      const userInfo = await loginService.authenticateUser(credentials);
      await loginService.performUserLoginFlow(userInfo);
      await goToRequestedPage();
    } catch (e) {
      setErrorMessage(e.response.data.message || e.response.data);
      setIsLoading(false);
      setIsAuthorizing(false);
    }
  }, []);

  const loginUsingRemoteUser = useCallback(async () => {
    setIsAuthorizing(true);
    await handleSubmit(null);
  }, [handleSubmit]);

  const shouldRedirectAndAuthorizeWithSso =
    !sessionStorageService.get('bigIdTokenID') &&
    !userInfoRespond &&
    (isSamlIdpEnabled || isCloudIdpEnabled) &&
    !logout &&
    (!auth || validateStringAndCompare(auth, 'OKTA')) &&
    !error;

  useEffect(() => {
    if (isRemoteUserAuthEnabled && !auth && !logout) {
      loginUsingRemoteUser();
    } else if (shouldRedirectAndAuthorizeWithSso) {
      handleSsoAuthorization();
    }
  }, [
    auth,
    error,
    handleSsoAuthorization,
    isRemoteUserAuthEnabled,
    isSamlIdpEnabled,
    isCloudIdpEnabled,
    loginUsingRemoteUser,
    logout,
    shouldRedirectAndAuthorizeWithSso,
    userInfoRespond,
  ]);

  useEffect(() => {
    const handleSsoResponse = () => {
      const tokenFromCookie = Cookies.get('authToken');
      if ((userInfoRespond || tokenFromCookie) && !error) {
        setIsAuthorizing(true);
        if (tokenFromCookie) {
          loginWithSSO(tokenFromCookie);
        } else {
          loginWithSSO(userInfoRespond);
        }
      } else if (error) {
        if (shouldDenySamlLogin(error)) {
          setDenyLogin(true);
        } else {
          const errorToDisplay = getErrorDisplayMessage(error);
          setErrorMessage(errorToDisplay);
        }
      } else if (sessionStorageService.get('bigIdTokenID')) {
        setIsAuthorizing(true);
        goToRequestedPage();
      }
    };
    handleSsoResponse();
  }, [userInfoRespond, error]);

  useEffect(() => {
    const handleLoginDisplay = () => {
      setIsLoading(false);
      if (isRemoteUserAuthEnabled && !auth) return;

      const showUsernameAndPasswordForm = showOnlyIdpLogin ? false : auth;
      const showUsernameAndPasswordFormWithIdp = isSamlIdpEnabled ? showUsernameAndPasswordForm : true;
      const showAuth = isCloudIdpEnabled ? false : showUsernameAndPasswordFormWithIdp;
      setShowAuthentication(!!showAuth);
      setShowSeparator(showAuth && isSamlIdpEnabled);
    };

    handleLoginDisplay();
  }, [isRemoteUserAuthEnabled, auth, showOnlyIdpLogin, isSamlIdpEnabled, isCloudIdpEnabled]);

  useEffect(() => {
    const handleSystemErrorEvent = (event: { errorMessage: string }) => {
      if (isLoginAttempt) {
        setErrorMessage(event?.errorMessage);
        setIsLoading(false);
      }
    };

    const unregisterErrorEventListener = systemEventsEmitter.addEventListener(
      SystemEvents.SYSTEM_ERROR,
      handleSystemErrorEvent,
    );

    return () => {
      unregisterErrorEventListener();
    };
  }, [isLoginAttempt]);

  return (
    <div className={classes.background}>
      <BigidLogin
        onLoginSubmit={handleSubmit}
        showCredentialsLogin={showAuthentication}
        showCredentialsSsoSeparator={showSeparator}
        showSsoLogin={isSamlIdpEnabled}
        ssoOnClick={handleSsoAuthorization}
        ssoCompanyName={companyName}
        isLoading={isLoading}
        isAuthorizing={isAuthorizing}
        errorMessage={errorMessage}
        showRemoteUserLogin={isRemoteUserAuthEnabled && logout}
        remoteUserOnClick={loginUsingRemoteUser}
        privacyPolicyHref={PRIVACY_POLICY_URL}
        showCloudBigidLogin={showCloudBigidLogin}
        cloudBigidLoginOnClick={handleCloudBigidAuthorization}
        denyLogin={denyLogin}
      />
    </div>
  );
};
