import { BigidSkeletonGenerator, BigidSkeletonGeneratorProps } from '@bigid-ui/components';
import React, { FC, useContext, useEffect, useState } from 'react';
import { DataSourceConnectionsContext } from './DataSourceConnectionsContext';
import { requestIdleCallbackWithFallback } from '../../../utilities/requestIdleCallbackWithFallback';

export const cellSkeletonConfig: BigidSkeletonGeneratorProps = {
  alignment: {
    type: 'flex',
    direction: 'column',
  },
  items: [
    {
      width: '100%',
      height: '16px',
      multiplicator: 1,
      padding: '4px',
    },
  ],
};

export const DataSourceGridCellSkeleton = (
  <BigidSkeletonGenerator
    dataAid={'DataSourceGridCellSkeleton'}
    alignment={cellSkeletonConfig.alignment}
    items={cellSkeletonConfig.items}
  />
);

interface DataSourceGridCellWithHeavyRenderProps {
  content: JSX.Element;
  fallback?: JSX.Element;
}
export const DataSourceGridCellWithDelayedRender: FC<DataSourceGridCellWithHeavyRenderProps> = ({
  content,
  fallback,
}) => {
  const [isReady, setIsReady] = useState(false);
  const { isGridIdle } = useContext(DataSourceConnectionsContext);

  useEffect(() => {
    if (!isGridIdle) {
      setIsReady(false);
    } else {
      requestIdleCallbackWithFallback(() => {
        setIsReady(true);
      });
    }
  }, [isGridIdle]);

  return isReady ? content : fallback || DataSourceGridCellSkeleton;
};
