import { User } from '../../../utilities/systemUsersUtils';
import { BigidFormFieldTypes, BigidFormValidateOnTypes } from '@bigid-ui/components';
import { mapUserToBigidDropdownOption } from '../../DataSources/DataSourceConnectionModal/mappers/collaboration';
import { generateDataAid } from '@bigid-ui/utils';
import React from 'react';
import { BigidDataFieldWithDataAid } from '../../DataSources/DataSourceConnectionModal/config/collaboration';
import { AutoDiscoveryCollaborationEmailFields } from '../AutoDiscoveryWizard/autoDiscoveryComponents/fields/AutoDiscoveryCollaborationEmailFields';
import { AutoDiscoveryCollaborationTextField } from '../AutoDiscoveryWizard/autoDiscoveryComponents/fields/AutoDiscoveryCollaborationTextField';

export const createFieldConfig = (
  label: string,
  users: User[],
  onSearch: (value: string) => void,
): BigidDataFieldWithDataAid[] => [
  {
    name: 'collaborators',
    validate: value => {
      const isValid = Array.isArray(value) ? value.length : value;
      return isValid ? false : 'Collaboration Required';
    },
    validateOn: BigidFormValidateOnTypes.SUBMIT,
    render: props => {
      const options = mapUserToBigidDropdownOption(users);

      return (
        <AutoDiscoveryCollaborationEmailFields
          dataAid={generateDataAid('AutoDiscoveryCollaboration', ['field', 'collaborators'])}
          onInputChange={onSearch}
          data={options}
          {...props}
        />
      );
    },
  },
  {
    name: 'message',
    type: BigidFormFieldTypes.TEXTAREA,
    render: props => (
      <AutoDiscoveryCollaborationTextField
        {...props}
        dataAid={generateDataAid('AutoDiscoveryCollaboration', ['field', 'message'])}
        label={label}
      />
    ),
  },
];
