import { notificationService } from '../../../services/notificationService';
import { getFixedT } from '../translations';

export function showPageInitTimeoutNotification(): void {
  const t = getFixedT('notifications.initTimeout');

  notificationService.info(t('body'), {
    showCloseIcon: true,
  });
}

export function showFilterRestorationFailureNotification(): void {
  const t = getFixedT('notifications.filterRestorationFailure');

  notificationService.warning(t('body'), {
    title: t('title'),
    showCloseIcon: true,
  });
}
