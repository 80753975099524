import React, { Ref, forwardRef, useCallback, useImperativeHandle, useState } from 'react';
import { useMutation, useQueryClient, useQueries } from 'react-query';
import { toastNotificationService } from '@bigid-ui/components';

import {
  ApiErrorType,
  CreateUserModel,
  DeleteUserCallback,
  SystemRole,
  SystemUser,
  UserToken,
  UsersForwardActions,
} from '../../types';
import { DeleteUserDialog } from './DeleteUserDialog';

import {
  createUser,
  deleteUser,
  fetchRoles,
  fetchUsers,
  fetchAuthUser,
  updateUser,
} from '../../accessManagementService';
import { useLocalTranslation } from './translations';
import { UsersContentWarapper } from './styles';
import { CreateUserDialog } from './CreateUserDialog/CreateUserDialog';
import { EditUserPanel } from './EditUserPanel/EditUserPanel';
import { SUCCESS_NOTIFIFCATION_SETTINGS } from './constants';
import { UsersGridWithToolbar } from './UsersGridWithToolbar/UsersGridWithToolbar';
import { getUserName, showGenericApiErrorNotification } from '../../utils';
import { useAccessManagementContext } from '../../AccessManagementContext';

interface UserPanelState {
  isOpen: boolean;
  userToEdit?: SystemUser;
  userRoles: SystemRole[];
  userTokens: UserToken[];
}

export const UsersComponent = (props: unknown, ref: Ref<UsersForwardActions>) => {
  const queryClient = useQueryClient();
  const [filtersQuery, setFiltersQuery] = useState('');
  const [deleteUserDialogData, setDeleteUserDialogData] = useState({
    isVisible: false,
    userName: '',
    userId: '',
  });
  const [isCreateModalOpened, setIsCreateModalOpened] = useState(false);
  const [editUserPanelState, setEditUserPanelState] = useState<UserPanelState>({
    isOpen: false,
    userToEdit: null,
    userRoles: [],
    userTokens: [],
  });
  const { permissions } = useAccessManagementContext();
  const { t } = useLocalTranslation();

  const initialData = useQueries([
    {
      queryKey: ['fetchIdentityAccessManagementUsers', filtersQuery],
      queryFn: () => fetchUsers(filtersQuery),
      keepPreviousData: true,
      placeholderData: {
        data: [],
        totalCount: 0,
      },
    },
    {
      queryKey: ['fetchIdentityAccessManagementAuthUser'],
      queryFn: fetchAuthUser,
    },
    {
      queryKey: ['fetchIdentityAccessManagementRoles'],
      queryFn: fetchRoles,
      placeholderData: [],
    },
  ]);
  const [{ data: systemUsers }, { data: authUser }, { data: roles }] = initialData;
  const isLoading = initialData.some(({ isLoading, isFetching }) => isLoading || isFetching);

  const onCloseDeleteUserDialog = () => {
    setDeleteUserDialogData({ ...deleteUserDialogData, isVisible: false });
  };

  const onEditUser = (userToEdit: SystemUser) => {
    const tempTokens = userToEdit.refreshTokens || [];
    const readyTokens = tempTokens.map(token => {
      return {
        ...token,
        id: token.tokenName,
      };
    });
    const userRoles = userToEdit.roleIds.map(roleId => {
      const role = roles.find(({ _id }) => _id === roleId);
      return role;
    });
    setEditUserPanelState({ isOpen: true, userToEdit, userRoles, userTokens: readyTokens });
  };

  const onCloseEditUserPanel = () => {
    setEditUserPanelState({ isOpen: false, userToEdit: null, userRoles: [], userTokens: [] });
  };

  const openCreateUserDialog = useCallback(() => {
    setIsCreateModalOpened(true);
  }, []);

  const onUpdateUser = (user: SystemUser) => {
    updateUserMutation.mutate(user);
  };

  const onDeleteUser: DeleteUserCallback = (userName, id) => {
    setDeleteUserDialogData({ isVisible: true, userName, userId: id });
    return false;
  };

  const closeCreateUserDialog = () => {
    setIsCreateModalOpened(false);
  };

  const deleteUserMutation = useMutation({
    mutationFn: deleteUser,
    onError: () => showGenericApiErrorNotification(),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['fetchIdentityAccessManagementUsers'] });
      const deleteMessage = t('notifications.deletedUser', { userName: deleteUserDialogData.userName });
      toastNotificationService.success(deleteMessage, SUCCESS_NOTIFIFCATION_SETTINGS);
      if (editUserPanelState.isOpen) {
        setEditUserPanelState({
          isOpen: false,
          userToEdit: null,
          userRoles: [],
          userTokens: [],
        });
      }
    },
  });

  const updateUserMutation = useMutation({
    mutationFn: updateUser,
    onMutate: () => {
      setEditUserPanelState({
        isOpen: false,
        userToEdit: null,
        userRoles: [],
        userTokens: [],
      });
    },
    onSuccess: () => {
      const {
        userToEdit: { firstName, lastName, name },
      } = editUserPanelState;
      const updateMessage = t('notifications.updatedUser', {
        userName: getUserName({ firstName, lastName, name }),
      });
      queryClient.invalidateQueries({ queryKey: ['fetchIdentityAccessManagementUsers'] });
      toastNotificationService.success(updateMessage, SUCCESS_NOTIFIFCATION_SETTINGS);
    },
    onError: (err: ApiErrorType) => {
      showGenericApiErrorNotification(err?.data?.message);
    },
  });

  const createUserMutation = useMutation({
    mutationFn: createUser,
    onError: (err: ApiErrorType) => {
      showGenericApiErrorNotification(err?.data?.message);
    },
    onSuccess: ({
      config: {
        data: { firstName, lastName, name },
      },
    }) => {
      const message = t('notifications.createdUser', { userName: getUserName({ firstName, lastName, name }) });
      toastNotificationService.success(message, SUCCESS_NOTIFIFCATION_SETTINGS);
      closeCreateUserDialog();
      queryClient.invalidateQueries({ queryKey: ['fetchIdentityAccessManagementUsers'] });
    },
  });

  const onDeletUser = () => {
    onCloseDeleteUserDialog();
    const { userId } = deleteUserDialogData;
    deleteUserMutation.mutate(userId);
  };

  const onCreateUser = (userData: CreateUserModel) => {
    createUserMutation.mutate(userData);
  };

  useImperativeHandle(
    ref,
    () => ({
      openCreateUserDialog,
    }),
    [openCreateUserDialog],
  );

  return (
    <UsersContentWarapper>
      <DeleteUserDialog
        isOpen={deleteUserDialogData.isVisible}
        userName={deleteUserDialogData.userName}
        onDelete={onDeletUser}
        onClose={onCloseDeleteUserDialog}
      />
      {isCreateModalOpened && <CreateUserDialog isOpen onClose={closeCreateUserDialog} onCreate={onCreateUser} />}
      {editUserPanelState.isOpen && (
        <EditUserPanel
          onClose={onCloseEditUserPanel}
          onDeleteUser={onDeleteUser}
          user={editUserPanelState.userToEdit}
          authUser={authUser}
          onUpdateUser={onUpdateUser}
          userRoles={editUserPanelState.userRoles}
          userTokens={editUserPanelState.userTokens}
          isOpen
        />
      )}
      <UsersGridWithToolbar
        roles={roles}
        systemUsers={systemUsers}
        onFiltersChange={setFiltersQuery}
        isLoading={isLoading}
        onRowClick={onEditUser}
        onClickDelete={onDeleteUser}
        onOpenCreateUserDialog={openCreateUserDialog}
        showEmptyFilterState={filtersQuery.includes('filter') && systemUsers.totalCount === 0}
        isManagePermitted={permissions.manage}
        authUser={authUser}
      />
    </UsersContentWarapper>
  );
};

export const Users = forwardRef(UsersComponent);
