import { BigidSystemDialogOptions, openSystemDialog } from '../../../../services/systemDialogService';
import { noop } from 'lodash';
import React from 'react';
import { ScannerDialogContent } from './ScannerDialogContent';

export const openScannersDialog = (title: string, scanners: string[]) => {
  const scannersDialogProps: BigidSystemDialogOptions = {
    title: `${title}s`,
    onClose: noop,
    borderTop: true,
    maxWidth: 'xs',
    withCloseButton: true,
    isContentScrollable: true,
    content: () => <ScannerDialogContent scanners={scanners} />,
  };
  openSystemDialog({ ...scannersDialogProps });
};
