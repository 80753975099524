import { openSystemDialog } from '../../services/systemDialogService';
import React, { FC } from 'react';
import { PrimaryButton, SecondaryButton } from '@bigid-ui/components';
import { betaToolsService } from '../../services/angularServices';
import { notificationService } from '../../services/notificationService';
import { noop } from 'lodash';

type DisabledInvalidateRefreshTokenUUID = (disabled: boolean) => void;

const InvalidateRefreshTokenUUIDDialogContent: FC = () => {
  return (
    <div data-aid="reload-entities-cache-warning">
      Are you sure you want to invalidate Orchestrator Refresh Token UUID? Existing scans will be aborted
    </div>
  );
};
const reloadAndNotify = async (setDisabledInvalidateRefreshTokenUUID: DisabledInvalidateRefreshTokenUUID) => {
  setDisabledInvalidateRefreshTokenUUID(true);
  try {
    await betaToolsService.invalidateRefreshTokenUUID();
    notificationService.success('Refresh Token UUID invalidated successfully!');
  } catch {
    notificationService.error('An error has occurred! ');
  } finally {
    setDisabledInvalidateRefreshTokenUUID(false);
  }
};

export function invalidateRefreshTokenUUIDDialog(
  setDisabledInvalidateRefreshTokenUUID: DisabledInvalidateRefreshTokenUUID,
) {
  return openSystemDialog({
    title: 'Invalidate Refresh Token UUID',
    onClose: noop,
    content: InvalidateRefreshTokenUUIDDialogContent,
    buttons: [
      {
        text: 'Close',
        component: SecondaryButton,
        onClick: noop,
        isClose: true,
      },
      {
        text: 'Invalidate',
        component: PrimaryButton,
        onClick: () => reloadAndNotify(setDisabledInvalidateRefreshTokenUUID),
        isClose: true,
      },
    ],
  });
}
