import React, { forwardRef, memo, useImperativeHandle, useMemo, useRef, useState } from 'react';
import { CredentialProviderRow, ForwardFormRef, HashiCorpAuthenticationType, ProviderType } from './types';
import { useLocalTranslation } from './translations';
import {
  BigidButtonTypography,
  BigidDropdownOption,
  BigidDropZone,
  BigidForm,
  BigidFormFieldErrorHelper,
  BigidFormProps,
  BigidFormRenderProps,
  BigidFormStateAndHandlers,
  BigidFormValidateOnTypes,
  ExtraFieldItemData,
} from '@bigid-ui/components';
import {
  createNewHeaderFieldItem,
  cyberArkFieldNames,
  getFormFields,
  getInitialValues,
  hashicorpAppRoleFieldNames,
  hashicorpTlsFieldNames,
  thycoticFieldNames,
} from './credentialProvidersFormUtils';
import { Content, FormWrapper } from './CredentialProvidersStyles';
import { HashiCorpHeaders } from '../HashiCorpConfig/HashiCorpHeaders';

interface CredentialProvidersFormProps {
  row: CredentialProviderRow;
  certificateOptions: BigidDropdownOption[];
  formControls: React.MutableRefObject<BigidFormStateAndHandlers>;
  setFormIsTouched: (isTouched: boolean) => void;
  isCertErrorShown?: boolean;
}

export const CredentialProvidersForm = forwardRef<ForwardFormRef, CredentialProvidersFormProps>(
  ({ row, certificateOptions, formControls, setFormIsTouched, isCertErrorShown }, ref) => {
    const { isPending, cert, type } = row;
    const { t } = useLocalTranslation();
    const setFieldValueRef = useRef<(fieldname: string, value: any) => void>();
    const formElementRef = useRef<HTMLDivElement>();
    const [certFileFieldFormValue, setCertFileFieldFormValue] = useState<File>(
      cert ? ({ name: cert.originalname || '' } as unknown as File) : null,
    );
    const [providerType, setProviderType] = useState<ProviderType>(type || ProviderType.CYBERARK);
    const [authenticationMethod, setAuthenticationMethod] = useState<HashiCorpAuthenticationType>(row.auth_method);
    const [headerFields, setHeaderFields] = useState<ExtraFieldItemData[]>(
      row.header_fields || [createNewHeaderFieldItem()],
    );
    const [useCustomConnectivityURI, setCustomConnectivityURI] = useState(row.useCustomConnectivityURI || false);

    const fields = getFormFields({
      isEditMode: !isPending,
      certificateOptions,
      authenticationMethod,
      providerType,
      useCustomConnectivityURI,
    });
    const initialValues = useMemo(() => getInitialValues(fields, row), [row, fields]);

    const handleOnDrop = (files: File[], fileType: 'cert') => {
      const cert = files?.length ? files[0] : undefined;
      setCertFileFieldFormValue(cert);
      setFieldValueRef.current?.(fileType, cert);
    };

    const handleHeaderFieldChange = (headerFieldsValue: ExtraFieldItemData[]): void => {
      setHeaderFields([...headerFieldsValue]);
      setFieldValueRef.current?.('header_fields', headerFieldsValue);
    };

    useImperativeHandle(ref, () => ({ formElement: formElementRef.current }), []);

    const formProps: BigidFormProps = useMemo(
      () => ({
        controlButtons: false,
        validateOn: BigidFormValidateOnTypes.SUBMIT,
        stateAndHandlersRef: formControls,
        initialValues,
        displayFormLevelError: true,
        fields,
        onChange: (values, { setFieldValue }) => {
          setCustomConnectivityURI(values.useCustomConnectivityURI);
          setProviderType(values.type?.[0]?.value);
          setAuthenticationMethod(values.auth_method?.[0]?.value);
          setFieldValueRef.current = setFieldValue;
          setTimeout(() => {
            setFormIsTouched(formControls.current?.formTouched);
          }, 0);
        },

        renderForm: ({ renderField, formLevelError, formLevelErrorDisplayed, getValues }: BigidFormRenderProps) => {
          const formValues = getValues();
          const selectedProviderType = formValues.type?.[0]?.value;
          let fieldsToRender = fields;
          if (selectedProviderType === ProviderType.HASHICORP) {
            const selectedAuthenticationMethodType = formValues.auth_method?.[0]?.value;
            const fieldNamesToInclude =
              selectedAuthenticationMethodType === HashiCorpAuthenticationType.TLS
                ? hashicorpTlsFieldNames
                : hashicorpAppRoleFieldNames;
            fieldsToRender = fieldsToRender.filter(({ name }) => fieldNamesToInclude.includes(name));
          } else if (selectedProviderType === ProviderType.CYBERARK) {
            fieldsToRender = fieldsToRender.filter(({ name }) => cyberArkFieldNames.includes(name));
          } else if (selectedProviderType === ProviderType.THYCOTIC) {
            fieldsToRender = fieldsToRender.filter(({ name }) => thycoticFieldNames.includes(name));
          }
          return (
            <>
              {fieldsToRender.map(field => renderField(field.name))}
              <div data-aid={'Certificate-management-form-level-error'}>
                {formLevelErrorDisplayed && formLevelError}
              </div>
            </>
          );
        },
      }),
      [formControls, initialValues, fields, setFormIsTouched],
    );

    return (
      <Content ref={formElementRef}>
        <FormWrapper>
          <BigidForm {...formProps} />
          {providerType === ProviderType.CYBERARK && (
            <>
              <BigidDropZone
                label={t('form.cert')}
                accept={['.p12']}
                files={certFileFieldFormValue ? [certFileFieldFormValue] : []}
                onDrop={files => handleOnDrop(files, 'cert')}
              />
              {<BigidFormFieldErrorHelper errorMessage={formControls?.current?.errors['cert'] as string} />}
            </>
          )}
          {providerType === ProviderType.HASHICORP && authenticationMethod === HashiCorpAuthenticationType.TLS && (
            <>
              <BigidButtonTypography>{'Header Fields'}</BigidButtonTypography>
              <HashiCorpHeaders
                createNewItem={createNewHeaderFieldItem}
                initialData={headerFields}
                onChange={handleHeaderFieldChange}
              />
            </>
          )}
        </FormWrapper>
      </Content>
    );
  },
);

export const CredentialProvidersFormMemo = memo(CredentialProvidersForm);
