import React, { FC, useMemo } from 'react';
import { FormSection, FormSectionInitialStateProps } from '../FormSection/FormSection';
import { useReportTemplateContext } from '../hooks/ReportTemplateContext';

export const DataSourcesSection: FC = () => {
  const sectionName = 'dataSources';
  const description = 'Display the data sources details.';

  const { initialTemplateData } = useReportTemplateContext();

  const formSectionInitProps: FormSectionInitialStateProps = useMemo(() => {
    const {
      dataSources: { ...initialValues },
    } = initialTemplateData;

    const newInitProps: FormSectionInitialStateProps = {
      fields: null,
      initialValues,
    };

    return newInitProps;
  }, [initialTemplateData]);

  return <FormSection sectionName={sectionName} description={description} {...formSectionInitProps} />;
};
