import { module } from 'angular';
const app = module('app');

app.factory('explorationBarChartService', [
  '$timeout',
  '$http',
  'appSettings',
  function ($timeout, $http, appSettings) {
    let that = this;
    that.getResidencyDataRequest = {};
    that.getSourceRisksRequest = {};
    that.getApplicationRisksRequest = {};
    that.getAttributesRisksRequest = {};

    const UNKNOWN = 'Unknown';

    return {
      getResidencyData: function getResidencyData(queryString) {
        queryString = queryString || '';
        let queryStringValue = queryString || 'none';
        if (!that.getResidencyDataRequest[queryStringValue]) {
          that.getResidencyDataRequest[queryStringValue] = $http
            .get(appSettings.serverPath + '/' + appSettings.version + '/identityLocations' + queryString)
            .then(function (response) {
              response.data.identity_locations = response.data.identity_locations.filter(
                identity => identity.id !== UNKNOWN && identity.name !== UNKNOWN,
              );
              return response.data;
            });
        }
        return that.getResidencyDataRequest[queryStringValue];
      },

      clearGetResidencyDataRequest: function clearGetResidencyDataRequest() {
        that.getResidencyDataRequest = {};
      },

      getSourceRisks: function getSourceRisks(queryString) {
        queryString = queryString || '';
        let queryStringValue = queryString || 'none';
        if (!that.getSourceRisksRequest[queryStringValue]) {
          that.getSourceRisksRequest[queryStringValue] = $http
            .get(appSettings.serverPath + '/' + appSettings.version + '/sourceRisks' + queryString)
            .then(function (response) {
              return response.data;
            });
        }
        return that.getSourceRisksRequest[queryStringValue];
      },

      clearGetSourceRisksRequest: function clearGetSourceRisksRequest() {
        that.getSourceRisksRequest = {};
      },

      getApplicationRisks: function getApplicationRisks(queryString) {
        queryString = queryString || '';
        let queryStringValue = queryString || 'none';
        if (!that.getApplicationRisksRequest[queryStringValue]) {
          that.getApplicationRisksRequest[queryStringValue] = $http
            .get(appSettings.serverPath + '/' + appSettings.version + '/applicationRisks' + queryString)
            .then(function (response) {
              return response.data;
            });
        }
        return that.getApplicationRisksRequest[queryStringValue];
      },

      clearGetApplicationRisksRequest: function clearGetApplicationRisksRequest() {
        that.getApplicationRisksRequest = {};
      },

      getAttributesRisks: function getAttributesRisks(queryString) {
        queryString = queryString || '';
        let queryStringValue = queryString || 'none';
        if (!that.getAttributesRisksRequest[queryStringValue]) {
          that.getAttributesRisksRequest[queryStringValue] = $http
            .get(appSettings.serverPath + '/' + appSettings.version + '/attributeRisks' + queryString)
            .then(function (response) {
              return response.data;
            });
        }
        return that.getAttributesRisksRequest[queryStringValue];
      },

      clearGetAttributesRisksRequest: function clearGetAttributesRisksRequest() {
        that.getAttributesRisksRequest = {};
      },

      getResidenciesInSystemsSummary: function getResidenciesInSystemsSummary() {
        return $http
          .get(appSettings.serverPath + '/' + appSettings.version + '/identityLocationsExtended')
          .then(function (response) {
            return response.data;
          });
      },
    };
  },
]);
