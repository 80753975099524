import { BigidSkeletonGenerator, BigidSkeletonGeneratorProps } from '@bigid-ui/components';
import React, { useContext, useEffect, useState } from 'react';
import { DataSourceModel } from './DataSourceConnectionTypes';
import { DataSourceConnectionsContext } from './DataSourceConnectionsContext';
import { styled } from '@mui/material';

const CellWithLoadingPlaceholderForOtherCells = styled('div')`
  display: flex;
`;

const DataSourceFirstLoadingPlaceholderContainer = styled('div')`
  position: fixed;
  padding-left: 180px;
`;
export const cellSkeletonConfig: BigidSkeletonGeneratorProps = {
  alignment: {
    type: 'flex',
    direction: 'row',
  },
  items: [
    {
      width: '900px',
      height: '16px',
      multiplicator: 1,
      padding: '4px',
    },
  ],
};

export const DataSourceFirstLoadingPlaceholder = (
  <DataSourceFirstLoadingPlaceholderContainer>
    <BigidSkeletonGenerator
      dataAid={'DataSourceGridCellFirstRenderSkeleton'}
      alignment={cellSkeletonConfig.alignment}
      items={cellSkeletonConfig.items}
    />
  </DataSourceFirstLoadingPlaceholderContainer>
);

export const TypeWithLoadingCell = React.memo<Partial<DataSourceModel>>(
  ({ displayType }) => {
    const { isGridIdle } = useContext(DataSourceConnectionsContext);
    const [showPlaceholders, setShowPlaceholders] = useState(false);

    useEffect(() => {
      setShowPlaceholders(true);
    }, []);

    useEffect(() => {
      if (isGridIdle) {
        setTimeout(() => setShowPlaceholders(false), 50);
      }
    }, [isGridIdle]);

    return showPlaceholders ? (
      <CellWithLoadingPlaceholderForOtherCells>
        {displayType}
        {DataSourceFirstLoadingPlaceholder}
      </CellWithLoadingPlaceholderForOtherCells>
    ) : (
      <>{displayType}</>
    );
  },
  (prevProps, nextProps) => prevProps?.displayType === nextProps?.displayType,
);
