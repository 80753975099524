import { useCallback, useState, useEffect, useContext } from 'react';
import { BigidSelectOption } from '@bigid-ui/components';
import { DataSourceConfigurationContext } from '../DataSourceConfigurationContext';
import {
  ScanWindowModel,
  scanWindowService,
  NONE,
  parseScanWindowExpression,
} from '../../../ScanWindows/scanWindowService';
import { dataSourceConnectionsService } from '../../../../services/angularServices';

const bottomInfoDefault: Record<string, string[]> = { None: [] };

export interface UseScanWindowOptions {
  options: BigidSelectOption[];
  isLoading: boolean;
  showTooltip?: boolean;
  disabled: boolean;
  bottomInfo: Record<string, string[]>;
}

const DEFAULT_SW_OPTIONS_STATE: UseScanWindowOptions = {
  options: [],
  isLoading: true,
  showTooltip: false,
  disabled: false,
  bottomInfo: bottomInfoDefault,
};

const getScanWindowNames = async (): Promise<{ values: ScanWindowModel[] }> => {
  const names = await scanWindowService.getAllScanWindow();
  return {
    values: names,
  };
};

const getSupportedDs = async (dsType: string) => {
  const result = await dataSourceConnectionsService.getDSSupportTypeGroups(dsType);
  return typeof result === 'boolean' ? result : false;
};

const buildBottomInfo = (scanWindows: ScanWindowModel[]) => {
  let bottomInfoMap = bottomInfoDefault;
  scanWindows.forEach(({ scanWindowName, scanWindows }) => {
    const windowStrings = scanWindows.map(w => parseScanWindowExpression(w) + '\n');
    bottomInfoMap = { ...bottomInfoMap, [scanWindowName]: windowStrings };
  });
  return bottomInfoMap;
};

export const NONE_OPTION = { id: NONE, label: NONE, value: NONE };

export const useScanWindowOptions = (): UseScanWindowOptions => {
  const [{ options, isLoading, showTooltip, disabled, bottomInfo }, setScanWindowState] =
    useState<UseScanWindowOptions>(DEFAULT_SW_OPTIONS_STATE);
  const { selectedType } = useContext(DataSourceConfigurationContext);
  const updateOptions = useCallback(async () => {
    try {
      const isEnabled = await getSupportedDs(selectedType);
      const { values } = await getScanWindowNames();
      const bottomInfo = buildBottomInfo(values);
      const options = [NONE_OPTION].concat(values.map(({ scanWindowName: sw }) => ({ id: sw, label: sw, value: sw })));
      setScanWindowState({
        options,
        isLoading: false,
        showTooltip: !isEnabled,
        disabled: !isEnabled,
        bottomInfo: bottomInfo,
      });
    } catch (e) {
      setScanWindowState({
        ...DEFAULT_SW_OPTIONS_STATE,
        isLoading: false,
      });
      console.log(e);
      throw new Error('Error getting Scan Windows');
    }
  }, [selectedType]);

  useEffect(() => {
    updateOptions();
  }, [updateOptions]);

  return { options, isLoading, showTooltip, disabled, bottomInfo };
};
