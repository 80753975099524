import React, { FC, MutableRefObject, useState, useEffect, Dispatch, useCallback, useRef } from 'react';
import {
  BigidFormStateAndHandlers,
  BigidForm,
  BigidFormFieldTypes,
  BigidFormField,
  BigidFormRenderProps,
  BigidFormValues,
} from '@bigid-ui/components';
import makeStyles from '@mui/styles/makeStyles';
import {
  loadConfigurationSearchOptions,
  ticketFieldsMetadataToFormFields,
  configurationField,
  CONFIGURATION_FIELD_NAME,
  ISSUE_TYPE_FIELD_NAME,
  getFieldType,
  loadJiraUsersSearchOptions,
  getIssueTypeOptions,
  ISSUE_CUSTOM_FIELD_SEPARATOR,
  convertFormFormatToAction,
  convertOpenServiceTicketActionToFormFormat,
  hasSavedConfiguration,
  standardizeFieldsMetadata,
} from './openServiceTicketUtils';
import { fetchServiceTicketMetadata } from '../../../../actionWorkflowService';
import { notificationService } from '../../../../../../../services/notificationService';
import {
  ACActionType,
  Action,
  OpenServiceTicketActionConfiguration,
  OpenServiceTicketMetadata,
} from '../../../../actionWorkflowTypes';
import { FieldType } from '../../../../../ConfigurationManagement/configurationManagementTypes';

interface OpenServiceTicketFormProps {
  formControls: MutableRefObject<BigidFormStateAndHandlers>;
  setDialogLoading: Dispatch<boolean>;
  autocompleteListItems: string[];
  saveActionConfiguration: (actionToSave: Action) => void;
  initialValue: OpenServiceTicketActionConfiguration;
  isDialogOpen: boolean;
}

const useStyles = makeStyles({
  formWrapper: { display: 'flex', flexDirection: 'column', minHeight: 50 },
});

export const OpenServiceTicketForm: FC<OpenServiceTicketFormProps> = ({
  formControls,
  setDialogLoading,
  autocompleteListItems = [],
  saveActionConfiguration,
  initialValue,
  isDialogOpen,
}) => {
  const classes = useStyles();
  const [shouldReloadTicketMetadata, setShouldReloadTicketMetadata] = useState(false);
  const [isLoadingTicketMetadata, setIsLoadingTicketMetadata] = useState(false);
  const [selectedConfigurationId, setSelectedConfigurationId] = useState<string>();
  const [formFields, setFormFields] = useState<BigidFormField[]>([configurationField]);
  const [fields, setFields] = useState<OpenServiceTicketMetadata>();
  const [initialFormValues, setInitialFormValues] = useState<BigidFormValues>();
  const [isLoadingInitialFormValues, setIsLoadingInitialFormValues] = useState(false);

  const isMounted = useRef(true);
  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  const getTicketMetadata = useCallback(async () => {
    try {
      const isInitialLoading = hasSavedConfiguration(initialValue) && !initialFormValues;
      const configurationId = isInitialLoading ? initialValue.configurationId : selectedConfigurationId;
      setDialogLoading(true);
      isInitialLoading && setIsLoadingInitialFormValues(true);

      const fieldsMetadata = await fetchServiceTicketMetadata(configurationId);
      const standardizedMetadata = fieldsMetadata.dropDownOptions
        ? standardizeFieldsMetadata(fieldsMetadata)
        : fieldsMetadata;
      setFields(standardizedMetadata);
      const formFields = ticketFieldsMetadataToFormFields(standardizedMetadata, autocompleteListItems);
      setFormFields(formFields);

      if (isInitialLoading) {
        const formattedInitialValue = await convertOpenServiceTicketActionToFormFormat(initialValue, fieldsMetadata);
        setInitialFormValues(formattedInitialValue);
      } else {
        formFields.find(({ name }) => name === ISSUE_TYPE_FIELD_NAME) &&
          formControls.current?.setValue(ISSUE_TYPE_FIELD_NAME)([getIssueTypeOptions(formFields)?.[0]]);
      }
    } catch (error) {
      console.error(error);
      notificationService.error(
        'Could not fetch form fields for the configuration selected. See logs for more information.',
      );
    } finally {
      if (isMounted.current) {
        setIsLoadingInitialFormValues(false);
        setDialogLoading(false);
        setShouldReloadTicketMetadata(false);
        setIsLoadingTicketMetadata(false);
      }
    }
  }, [initialValue, initialFormValues, selectedConfigurationId, setDialogLoading, autocompleteListItems, formControls]);

  useEffect(() => {
    if (shouldReloadTicketMetadata && !isLoadingTicketMetadata) {
      setIsLoadingTicketMetadata(true);
      getTicketMetadata();
    }
  }, [shouldReloadTicketMetadata, getTicketMetadata, isLoadingTicketMetadata]);

  useEffect(() => {
    if (isDialogOpen && (hasSavedConfiguration(initialValue) || selectedConfigurationId)) {
      setShouldReloadTicketMetadata(true);
    }
  }, [isDialogOpen, initialValue, selectedConfigurationId, formFields]);

  useEffect(() => {
    const values = formControls.current?.getValues();
    const fieldsToClear = Object.keys(values).filter(
      fieldName => ![CONFIGURATION_FIELD_NAME, ISSUE_TYPE_FIELD_NAME].includes(fieldName),
    );
    fieldsToClear.forEach(fieldName => formControls.current?.clearField(fieldName));
    return () => {
      formFields.find(field => field.name === CONFIGURATION_FIELD_NAME).fieldProps.message = '';
    };
  }, [formControls, selectedConfigurationId]);

  const handleFormSubmit = useCallback(
    (values: BigidFormValues) => {
      saveActionConfiguration({
        type: ACActionType.OPEN_SERVICE_TICKET,
        configuration: {
          ...initialValue,
          ...convertFormFormatToAction(values, fields),
        },
      });
      formControls.current?.clear();
      setSelectedConfigurationId(null);
    },
    [saveActionConfiguration, initialValue, fields, formControls],
  );

  const handleFormChange = (values: BigidFormValues) => {
    const { configurationId: configurationSelection } = values;
    const configurationId = configurationSelection?.[0].value;

    const userChangedConfigurationId = configurationId !== selectedConfigurationId;
    if (formControls.current && userChangedConfigurationId) {
      setSelectedConfigurationId(configurationId);
    }
  };

  const renderForm = ({ renderField, getValues }: BigidFormRenderProps) => {
    if (!isDialogOpen) {
      return null;
    }
    const { configurationId, issuetype, ...rest } = getValues();
    const selectedConfiguration = configurationId?.[0]?.value;
    const selectedIssueType = issuetype?.[0]?.value || getIssueTypeOptions(formFields)?.[0].value;
    const fieldsForSelectedIssue = formFields.filter(
      ({ name }) =>
        name === CONFIGURATION_FIELD_NAME ||
        !name.includes(ISSUE_CUSTOM_FIELD_SEPARATOR) ||
        name.includes(`${selectedIssueType}${ISSUE_CUSTOM_FIELD_SEPARATOR}`),
    );

    return (
      <>
        {fieldsForSelectedIssue.map(({ name, type }) => {
          const isConfigurationField = name === CONFIGURATION_FIELD_NAME;

          if (isConfigurationField) {
            return renderField(CONFIGURATION_FIELD_NAME, {
              selectProps: {
                isSearchable: true,
                isAsync: true,
                loadOptions: loadConfigurationSearchOptions(selectedConfiguration),
                shouldLoadInitialOptions: true,
                menuPosition: 'fixed',
              },
            });
          }

          const fieldOriginType = getFieldType(name, selectedIssueType, fields);
          const isUserField = fieldOriginType === FieldType.USER;
          const isMultiSelectField = fieldOriginType === FieldType.MULTISELECT;
          const isSelectField = type === BigidFormFieldTypes.SELECT;
          const selectProps = {
            isSearchable: true,
            menuPosition: 'fixed',
            ...(isMultiSelectField && { isMulti: true }),
            ...(isUserField && {
              isAsync: true,
              shouldLoadInitialOptions: true,
              loadOptions: loadJiraUsersSearchOptions(
                selectedConfiguration,
                rest[name]?.length && [rest[name][0].value],
              ),
            }),
          };
          return renderField(name, isSelectField && { selectProps });
        })}
      </>
    );
  };

  return (
    <div className={classes.formWrapper}>
      {!isLoadingInitialFormValues && (
        <BigidForm
          controlButtons={false}
          initialValues={initialFormValues}
          fields={formFields}
          renderForm={renderForm}
          stateAndHandlersRef={formControls}
          onSubmit={handleFormSubmit}
          onChange={handleFormChange}
        />
      )}
    </div>
  );
};
