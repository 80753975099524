import React, { FC } from 'react';
import { BigidPrimaryCheckbox, BigidColors } from '@bigid-ui/components';
import makeStyles from '@mui/styles/makeStyles';
import { InfoHelper } from './InfoHelper';

interface PermissionsActionProps {
  checked: boolean;
  onChange: (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => void;
  title: string;
  disabled?: boolean;
  marginBottom?: boolean;
  tooltip?: string;
}

const useStyles = makeStyles({
  root: ({ marginBottom }: { marginBottom: boolean }) => ({
    display: 'flex',
    alignItems: 'center',
    border: `1px solid ${BigidColors.gray[100]}`,
    borderRadius: 6,
    paddingRight: 8,
    marginRight: 12,
    ...(marginBottom && { marginBottom: 12 }),
  }),
  title: { fontSize: '0.8125rem', color: BigidColors.gray[700] },
});

export const PermissionsAction: FC<PermissionsActionProps> = ({
  title,
  checked,
  onChange,
  disabled,
  marginBottom = true,
  tooltip,
}) => {
  const classes = useStyles({ marginBottom });

  return (
    <div className={classes.root} data-aid={`PermissionsAction-${title}`}>
      <BigidPrimaryCheckbox checked={checked} onChange={onChange} disabled={disabled} />
      <div className={classes.title}>{title}</div>
      {tooltip && <InfoHelper tooltip={tooltip} />}
    </div>
  );
};
