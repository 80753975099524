import React, { FC, HTMLAttributes } from 'react';
import { Avatar } from '@mui/material';
import { BigidWarningIcon, BigidFailedIcon, BigidSuccessIcon } from '@bigid-ui/icons';
import {
  PrimaryButton,
  BigidIconSourceType,
  BigidIcon,
  BigidIconSize,
  BigidIconType,
  BigidTooltip,
  SecondaryButton,
  BigidLoader,
} from '@bigid-ui/components';
import { useStyles } from './ConnectionCardStyles';
import AwsLogo from '../../../../assets/icons/AwsLogo.svg';
import AzureLogo from '../../../../assets/icons/AzureLogo.svg';
import SalesforceLogo from '../../../../assets/icons/SForceLogo.svg';
import { CloudProvider, SaaSProvider } from '../../../AutoDiscoveryWizard/autoDiscoveryWizardServices';
import { useLocalTranslation } from '../../translations';
import { cloudProviderLabel } from '../../FmsdDiscover/hooks/useAutodiscoveryApp';
import { FmsdTrackingEvents } from '../../fmsdEventsTrackerUtils';
import { useTheme } from '@mui/styles';

export enum ConnectionStatus {
  CANT_EVALUATE = 'cantEvaluate',
  VALID = 'valid',
  WRONG_CREDS = 'wrongCreds',
}

export const getConnectionMode = (status: string): ConnectionStatus => {
  switch (status) {
    case ConnectionStatus.VALID:
      return ConnectionStatus.VALID;
    case ConnectionStatus.CANT_EVALUATE:
      return ConnectionStatus.CANT_EVALUATE;
    default:
      return ConnectionStatus.WRONG_CREDS;
  }
};

export type ConnectionCardType = CloudProvider | SaaSProvider;

export interface ConnectionCardProps {
  id: string;
  status?: string;
  onCardClick: () => void;
  isLoading?: boolean;
  type: ConnectionCardType;
}

export interface ProviderData {
  icon: React.ComponentType<HTMLAttributes<SVGElement>>;
  label: string;
}

export interface CloudProviderData {
  [key: string]: ProviderData;
}

export interface SaasConnectorsData {
  [key: string]: ProviderData;
}

export interface ModeData {
  [key: string]: BigidIconType;
}

export const ConnectionCard: FC<ConnectionCardProps> = ({ id, status, onCardClick, isLoading, type }) => {
  const { t } = useLocalTranslation('Connect.ConnectionCard');
  const mode = getConnectionMode(status);
  const theme = useTheme();
  const { card, cardLabel, cardTitleContainer, iconContainer, statusIcon, signInButtonWrapper } = useStyles({ mode });
  const cloudProvidersData: CloudProviderData = {
    aws: { icon: AwsLogo, label: cloudProviderLabel[CloudProvider.AWS] },
    azure: { icon: AzureLogo, label: cloudProviderLabel[CloudProvider.AZURE] },
  };

  const saasConnectorsData: SaasConnectorsData = {
    salesforce: { icon: SalesforceLogo, label: 'Salesforce' },
  };

  const modeData: ModeData = {
    [ConnectionStatus.VALID]: BigidSuccessIcon,
    [ConnectionStatus.CANT_EVALUATE]: BigidWarningIcon,
    [ConnectionStatus.WRONG_CREDS]: BigidFailedIcon,
  };

  const Icon = cloudProvidersData[id]?.icon || saasConnectorsData[id].icon;

  const isWrongCreds = status === ConnectionStatus.WRONG_CREDS;
  const isProvider = Object.values(CloudProvider || SaaSProvider).includes(type);
  const shouldDisplayEditButton = isProvider || isWrongCreds ? status : false;

  return (
    <div>
      <div className={card} data-aid={`ConnectionCard-${id}-container`}>
        <div className={iconContainer}>
          <Icon />
        </div>
        <div className={cardTitleContainer}>
          {status && (
            <BigidTooltip
              placement="top"
              title={
                mode === ConnectionStatus.CANT_EVALUATE ? t('tooltip.cantEvaluateText') : t('tooltip.wrongCredsText')
              }
              isDisabled={mode === ConnectionStatus.VALID}
            >
              <Avatar className={statusIcon}>
                <BigidIcon type={BigidIconSourceType.CUSTOM} icon={modeData[mode]} size={BigidIconSize.REGULAR} />
              </Avatar>
            </BigidTooltip>
          )}
          <div className={cardLabel}>{cloudProvidersData[id]?.label || saasConnectorsData[id]?.label}</div>
        </div>
        {shouldDisplayEditButton ? (
          <div className={signInButtonWrapper}>
            <SecondaryButton
              width="fill"
              size="medium"
              onClick={() => {
                onCardClick();
              }}
              bi={{
                eventType: FmsdTrackingEvents.FMSD_CONNECT_EDIT_CLICK,
                eventData: { [FmsdTrackingEvents.DS_TYPE]: type },
              }}
            >
              {isLoading ? <BigidLoader size={24} color={theme.vars.palette.bigid.black} /> : t('editButtonText')}
            </SecondaryButton>
          </div>
        ) : (
          <div
            className={signInButtonWrapper}
            hidden={status === ConnectionStatus.VALID && type === SaaSProvider.SAAS_CONNECTORS}
          >
            <PrimaryButton
              width="fill"
              size="medium"
              onClick={() => {
                onCardClick();
              }}
              bi={{
                eventType: FmsdTrackingEvents.FMSD_CONNECT_SIGNIN_CLICK,
                eventData: { [FmsdTrackingEvents.DS_TYPE]: type },
              }}
              disabled={isLoading}
            >
              {isLoading ? <BigidLoader size={24} color={theme.vars.palette.bigid.white} /> : t('signinButtonText')}
            </PrimaryButton>
          </div>
        )}
      </div>
    </div>
  );
};
