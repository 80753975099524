import React, { FC, useEffect, useRef, useState } from 'react';
import {
  GenericCloudConnectorFieldList,
  GenericCloudConnectorLoginField,
  GenericCloudDialogProps,
} from './genericCloudConnectorTypes';
import {
  BigidForm,
  BigidFormField,
  BigidFormFieldTypes,
  BigidFormProps,
  BigidFormStateAndHandlers,
  BigidFormValidateOnTypes,
  BigidFormValues,
  BigidLoader,
  PrimaryButton,
} from '@bigid-ui/components';
import {
  getLWCLoginFieldsByConnectorId,
  submitLWCLoginFieldValuesForConnectorId,
} from '../../services/genericCloudConnectorService';
import { SystemDialogContentProps } from '../../services/systemDialogService';

export const GenericCloudConnectorLoginDialog: FC<SystemDialogContentProps<GenericCloudDialogProps>> = ({
  connectorId,
  onSuccessfulAuthId,
  onError,
}) => {
  const formControls = useRef<BigidFormStateAndHandlers>();
  const labelWidth = '150px';

  const [initialLWCFields, setLWCFields] = useState<GenericCloudConnectorFieldList>({ fields: [] });
  const [initialFields, setFields] = useState<BigidFormField[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const onSubmit = () => {
    formControls.current.validateAndSubmit(async (values: BigidFormValues) => {
      try {
        setIsLoading(true);
        const request: any = {
          fields: [],
        };
        initialLWCFields.fields.forEach(lwcField => {
          request.fields.push(lwcField);
          request[lwcField.name] = values[lwcField.name];
        });
        const authIdResponse = await submitLWCLoginFieldValuesForConnectorId(request, connectorId);
        onSuccessfulAuthId(authIdResponse.authenticationId);
        setIsLoading(false);
      } catch (e) {
        setIsLoading(false);
        console.error(e);
        onError();
      }
    });
  };

  const formProps: BigidFormProps = {
    initialValues: {},
    validateOn: BigidFormValidateOnTypes.SUBMIT,
    stateAndHandlersRef: formControls,
    onSubmit,
    controlButtons: false,
    fields: initialFields,
  };

  const lwcFieldToBigidFormField = (field: GenericCloudConnectorLoginField): BigidFormFieldTypes => {
    switch (field.type) {
      case 'text':
        return BigidFormFieldTypes.TEXT;
      case 'password':
        return BigidFormFieldTypes.PASSWORD;
    }
  };

  const fetchFields = async (connectorId: string) => {
    try {
      setIsLoading(true);
      const data = await getLWCLoginFieldsByConnectorId(connectorId);
      setLWCFields(data);
      const bigidFromFieldsArray = data.fields.map(field => {
        const bigidField = {
          name: field.name,
          label: field.label,
          isRequired: true,
          validateOnChange: true,
          validate: (val: string) => {
            if (!val || val === '') {
              return field.label + ' value cannot be empty';
            }
            return false;
          },
          labelWidth,
          misc: {
            showValueOnlyIfDirty: true,
          },
          type: lwcFieldToBigidFormField(field),
        };
        return bigidField;
      });
      setFields(bigidFromFieldsArray);
      setIsLoading(false);
    } catch (ex) {
      console.error(ex);
      onError();
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchFields(connectorId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {isLoading ? (
        <BigidLoader />
      ) : (
        <div>
          <BigidForm {...formProps} />
          <PrimaryButton size="medium" onClick={onSubmit} text="Submit" />
        </div>
      )}
    </>
  );
};
