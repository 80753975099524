import React, { ReactElement, ReactNode, useCallback, useEffect, useRef, useState } from 'react';
import styled from '@emotion/styled';
import { BigidChip, BigidColorsV2 } from '@bigid-ui/components';

export const Wrapper = styled.span`
  display: flex;
  margin: 0;
  overflow: hidden;
`;

export const InnerWrapper = styled.span`
  display: flex;
  margin: 0;
  gap: 4px;
  align-items: center;
  font-weight: 600;
  font-size: 0.875rem;
  line-height: 1.5;
`;
export const IconWithLabelWrapper = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  gap: 8px;
  margin-bottom: 4px;
`;
const TooltipTitleWrapper = styled.div`
  max-height: 200px;
  overflow: auto;
  width: calc(100% + 12px);
`;

export const DynamicChipsArea = ({
  values = [],
  withChips,
}: {
  values: { label: string; icon?: ReactElement }[];
  withChips?: boolean;
}) => {
  const wrapperRef = useRef<HTMLDivElement>(null);
  const innerWrapperRef = useRef<HTMLDivElement>(null);
  const [size, setSize] = useState(Math.min(1, values.length));
  const [calculatedSize, setCalculatedSize] = useState(-1);
  const [showHiddenItemsCount, setShowHiddenItemsCount] = useState(false);

  const maxSize = Math.min(values.length, size);
  const hasCalculatedSize = calculatedSize > -1;
  const valuesToRender = values.slice(0, hasCalculatedSize ? calculatedSize : maxSize);
  const hiddenValues = values.slice(calculatedSize, values.length);

  const checkWidth = useCallback(() => {
    const wrapperWidth = wrapperRef.current?.offsetWidth || 0;
    const chipsWrapperWidth = innerWrapperRef.current?.offsetWidth || 0;
    if (chipsWrapperWidth < wrapperWidth) {
      return setSize(Math.min(values.length, size + 1));
    } else if (!showHiddenItemsCount) {
      setShowHiddenItemsCount(true);
      return setSize(Math.min(values.length, size - 1));
    }
    setCalculatedSize(size - 1);
  }, [showHiddenItemsCount, size, values.length]);

  useEffect(() => {
    if (!hasCalculatedSize) {
      checkWidth();
    }
  }, [size, hasCalculatedSize, checkWidth]);

  return (
    <Wrapper ref={wrapperRef}>
      <InnerWrapper ref={innerWrapperRef}>
        {values.length
          ? withChips
            ? valuesToRender?.map((value, i) => {
                return <Chip key={`${value?.label}-${i}`} label={value?.label} icon={value?.icon} />;
              })
            : valuesToRender?.map(val => val.label)?.join(', ')
          : '-'}
        {showHiddenItemsCount && (
          <Chip
            label={`+${hiddenValues?.length}`}
            tooltipText={hiddenValues?.map((val, i) => (
              <IconWithLabelWrapper key={`${val?.label}-${i}`}>
                {val?.icon}
                <span>{val?.label}</span>
              </IconWithLabelWrapper>
            ))}
          />
        )}
      </InnerWrapper>
    </Wrapper>
  );
};

const Chip = ({ label, icon, tooltipText }: { label: string; icon?: ReactElement; tooltipText?: ReactNode }) => {
  return (
    <BigidChip
      label={label}
      variant="outlined"
      size="small"
      width="fill"
      maxWidth="fit-content"
      shadow={false}
      icon={icon}
      iconPlacement="left"
      outlineColor={BigidColorsV2.gray[200]}
      bgColor={BigidColorsV2.gray[50]}
      tooltipProps={
        tooltipText
          ? {
              title: <TooltipTitleWrapper>{tooltipText}</TooltipTitleWrapper>,
              PopperProps: {
                sx: {
                  marginRight: '-12px',
                },
              },
            }
          : undefined
      }
    />
  );
};
