import React, { FC } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { Typography } from '@mui/material';
import classnames from 'classnames';
import { BigidCheckbox, BigidHeading5 } from '@bigid-ui/components';
import { RegulationsDataMap } from './FmsdCategories';

export interface FmsdCategoriesItemProps extends Omit<RegulationsDataMap, '_id'> {
  id: string;
  onSelect: (id: string, name: string) => void;
}

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    width: 'calc(50% - 12px)',
    minHeight: 80,
    borderRadius: 4,
    boxShadow: '0px 0px 5px rgba(0, 0, 0, 0.15)',
    padding: '17px 15px 17px 15px',
    boxSizing: 'border-box',
    backgroundColor: theme.vars.palette.bigid.white,
    marginBottom: 24,
    border: '1px solid transparent',
  },
  selectedItem: {
    borderColor: theme.vars.palette.bigid.primary400,
  },
  icon: {
    flexShrink: 0,
    width: 26,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: 16,
  },
  content: {
    flexGrow: 1,
  },
  checkbox: {
    flexShrink: 0,
    marginLeft: 16,
  },
}));

export const FmsdCategoriesItem: FC<FmsdCategoriesItemProps> = ({
  id,
  name,
  classifiers,
  Icon,
  selected,
  onSelect,
}) => {
  const { root, icon, content, checkbox, selectedItem } = useStyles();

  return (
    <div className={classnames(root, { [selectedItem]: selected })}>
      {Icon && (
        <div className={icon}>
          <Icon />
        </div>
      )}
      <div className={content}>
        <BigidHeading5>{name}</BigidHeading5>
        {classifiers && <Typography variant="body1">{classifiers}</Typography>}
      </div>
      <div className={checkbox}>
        <BigidCheckbox
          checked={selected}
          onClick={() => onSelect(id, name)}
          dataAid={`FmsdCategories-checkbox-${name.toLowerCase().replace(' ', '-')}`}
        />
      </div>
    </div>
  );
};
