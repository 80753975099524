import { httpService } from '../../../services/httpService';
import { CONFIG } from '../../../../config/common';
import { LineageNode, ParsedFqn, parseFqn } from './lineage-node';
import { BigidGraphGraph, BigidGraphNode, BigidGraphMarkerShape } from '@bigid-ui/visualisation';
import { BigidColors } from '@bigid-ui/components';
import { $state } from '../../../services/angularServices';

export interface DataCatalogObjectExists {
  offset: number;
}

export interface DataCatalogObjectLineageNeighbor {
  fqn: string;
  type: string;
}

export interface DataCatalogObjectLineageNeighborList {
  fqn: string;
  connectsTo: DataCatalogObjectLineageNeighbor[];
  connectsFrom: DataCatalogObjectLineageNeighbor[];
}

enum NeighborDirectionality {
  FROM,
  BI_DIRECTIONAL,
  TO,
}

export async function objectExists(parsedFqn: ParsedFqn): Promise<boolean> {
  const { source, container, object } = parsedFqn;
  if (source && container && object) {
    return httpService
      .fetch<DataCatalogObjectExists>(
        `catalog/dataSources/${encodeURIComponent(source)}/containers/${encodeURIComponent(
          container,
        )}/objects/${encodeURIComponent(object)}`,
      )
      .then(data => data.data.offset > 0);
  } else {
    return false;
  }
}

export async function getNeighborsByObjectName(
  fullyQualifiedName: string,
): Promise<DataCatalogObjectLineageNeighborList[]> {
  return httpService
    .fetch<{ data: DataCatalogObjectLineageNeighborList[] }>(
      `data-lineage/traversal/neighbors/${encodeURIComponent(fullyQualifiedName)}?projection=type`,
    )
    .then(data => data.data.data);
}

function generateOriginNode(source: string, objectType: string, fqn: string): LineageNode {
  return {
    id: fqn,
    fqn: fqn,
    type: objectType,
    origin: true,
    column: 1,
    dataSource: source,
    highlight: { colors: [BigidColors.purple[500]] },
  };
}

function getLineageEntityPreviewRoute(fullyQualifiedName: string) {
  return {
    route: CONFIG.states.CATALOG_PREVIEW,
    params: {
      id: encodeURIComponent(fullyQualifiedName),
      selectedTab: 'lineage',
    },
  };
}

function handleObjectClick(node: BigidGraphNode, fullyQualifiedName: string): void {
  const fqn = (node as LineageNode).fqn;
  if (fqn !== fullyQualifiedName) {
    const { route: lineageEntityPreviewRoute, params } = getLineageEntityPreviewRoute(fqn);
    $state.go(lineageEntityPreviewRoute, params);
  }
}

async function addNeighbors(
  graph: BigidGraphGraph,
  originNode: LineageNode,
  neighbors: DataCatalogObjectLineageNeighbor[],
  directionality: NeighborDirectionality,
): Promise<void> {
  if (neighbors) {
    for (const connects of neighbors) {
      const isFrom = directionality === NeighborDirectionality.FROM;
      const isBiDirectional = directionality === NeighborDirectionality.BI_DIRECTIONAL;
      const fqn: string = connects.fqn;
      const type = connects.type;
      const parsedFqn = parseFqn(fqn, type);
      const connectsNode = {
        id: `${fqn}-${isFrom ? 'from' : isBiDirectional ? 'bi' : 'to'}`,
        fqn: fqn,
        type: type,
        origin: false,
        column: isFrom || isBiDirectional ? 0 : 2,
        dataSource: parsedFqn.source,
        onClick: (await objectExists(parsedFqn))
          ? (node: BigidGraphNode) => {
              handleObjectClick(node, originNode.fqn);
            }
          : null,
      };
      graph.nodes.push(connectsNode);
      const source = isFrom || isBiDirectional ? connectsNode : originNode;
      const target = isFrom || isBiDirectional ? originNode : connectsNode;
      const biDirectionalMarker = isBiDirectional
        ? {
            markers: {
              start: { show: true, size: 16, shape: BigidGraphMarkerShape.ARROW },
            },
          }
        : {};
      graph.edges.push({ source, target, ...biDirectionalMarker });
    }
  }
}

export async function getLineageNeighborsGraph(
  source: string,
  objectType: string,
  fullyQualifiedName: string,
): Promise<BigidGraphGraph> {
  const graph: BigidGraphGraph = { nodes: [], edges: [] };
  const neighbors: DataCatalogObjectLineageNeighborList[] = await getNeighborsByObjectName(fullyQualifiedName);
  const originNode = generateOriginNode(source, objectType, fullyQualifiedName);
  graph.nodes.push(originNode);
  if (neighbors.length > 0) {
    const from: DataCatalogObjectLineageNeighbor[] = [];
    const biDirectional: DataCatalogObjectLineageNeighbor[] = [];
    const to: DataCatalogObjectLineageNeighbor[] = neighbors[0].connectsTo || [];
    if (neighbors[0].connectsFrom) {
      neighbors[0].connectsFrom.forEach(fromNeighbor => {
        const toNeighbor = to.find(
          toNeighbor => toNeighbor.fqn === fromNeighbor.fqn && toNeighbor.type === fromNeighbor.type,
        );
        if (toNeighbor) {
          to.splice(to.indexOf(toNeighbor), 1);
          biDirectional.push(toNeighbor);
        } else {
          from.push(fromNeighbor);
        }
      });
    }
    await Promise.all([
      addNeighbors(graph, originNode, from, NeighborDirectionality.FROM),
      addNeighbors(graph, originNode, biDirectional, NeighborDirectionality.BI_DIRECTIONAL),
      addNeighbors(graph, originNode, to, NeighborDirectionality.TO),
    ]);
  }
  return graph;
}
