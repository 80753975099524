import './reportModal.component.scss';
import template from './reportModal.component.html';
import angular from 'angular';

angular.module('app').component('thirdPartySharedDataReportModal', {
  template,
  controller: [
    function () {
      this.types = [
        { id: '1', name: 'PDF' },
        { id: '2', name: 'Docx' },
      ];
      this.sharedReportTypes = [
        { id: '1', name: 'PDF' },
        { id: '2', name: 'CSV' },
      ];
      this.type = 'PDF';
      this.sharedReportType = 'PDF';

      this.handleDownloadSharedReport = () => {
        switch (this.sharedReportType) {
          case 'PDF':
            this.onDownloadSharedDataReport();
            break;
          case 'CSV':
            this.onDownloadSharedDataReportAsCsv();
            break;
        }
      };
    },
  ],
  bindings: {
    data: '<',
    dismiss: '&',
    onDownloadSharedDataReport: '&',
    onDownloadSharedDataReportAsCsv: '&',
    onDownloadBusinessProcessReport: '&',
  },
});
