import React from 'react';
import { BigidBody1, BigidChip, BigidColorsV2, BigidFormRenderProps, BigidTooltip } from '@bigid-ui/components';
import { ClassifierGridRow, RegexFormFields } from '../../types/ClassifierTypes';
import styled from '@emotion/styled';
import { BigidHelpIcon, BigidInfoSmallIcon } from '@bigid-ui/icons';
import { useLocalTranslation } from '../../translations';
import { generateDataAid } from '@bigid-ui/utils';
import { getApplicationPreference } from '../../../../services/appPreferencesService';

const StatisticsContainer = styled('div')`
  background-color: ${BigidColorsV2.gray[125]};
  padding: 16px;
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const StatisticsRow = styled('div')`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const RowItem = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const StatisticValue = styled(BigidBody1)`
  font-weight: 700;
  color: ${(props: { isIdentifiability: boolean }) => (props.isIdentifiability ? BigidColorsV2.green[900] : '#222222')};
  font-size: ${(props: { isIdentifiability: boolean }) => (props.isIdentifiability ? '26px' : '14px')};
`;

const BigidChipContainer = styled('div')`
  display: flex;
  gap: 12px;
  align-items: center;
`;

const SwitchBox = styled('div')`
  display: flex;
  align-items: center;
  fieldset {
    margin: 0;
    div {
      margin-top: 0px;
    }
  }
`;

const BigidInfoSmallIconBox = styled('div')`
  height: 20px;
`;

const RowTitle = styled('div')`
  display: flex;
  align-items: center;
  gap: 2px;
`;

const BigidHelpIconBox = styled('div')`
  display: flex;
`;
interface StatisticsCorrelationProps {
  renderField: BigidFormRenderProps['renderField'];
  classifier?: ClassifierGridRow;
}

export const StatisticsCorrelation: React.FC<StatisticsCorrelationProps> = ({ renderField, classifier }) => {
  const { t } = useLocalTranslation('statisticsCorrelation');

  const statisticsToShow = [
    {
      title: t('identifiability'),
      value: classifier.identifiability * 100 + '%',
      isIdentifiability: true,
      dataAid: 'identifiability',
      tooltip: t('identifiabilityTooltip'),
    },
    {
      title: t('avgValuesLength'),
      value: classifier.avgValueLength?.toFixed(2),
      isIdentifiability: false,
      dataAid: 'avgValuesLength',
      tooltip: t('avgValuesLengthTooltip'),
    },
    {
      title: t('distinctFieldsWithValue'),
      value: classifier.distinctFieldValueCount,
      isIdentifiability: false,
      dataAid: 'distinctFieldsWithValue',
      tooltip: t('distinctFieldsWithValueTooltip'),
    },
    {
      title: t('distinctIDsWithAttributeValue'),
      value: classifier.distinctIdsAttValueCount,
      isIdentifiability: false,
      dataAid: 'distinctIDsWithAttributeValue',
      tooltip: t('distinctIDsWithAttributeValueTooltip'),
    },
  ];

  const partialCorrelationSetFF = getApplicationPreference('PARTIAL_CORRELATION_SET');
  const isDisplayPartialLabel = partialCorrelationSetFF && classifier.isPartialCorrelationSet;
  const isDisplayComposeLabel = classifier.isComposed;

  return (
    <StatisticsContainer>
      <StatisticsRow>
        <BigidBody1 fontWeight={700}>{t('statistics')}</BigidBody1>
        <SwitchBox>
          <BigidBody1>{t('overrideSureMatch')}</BigidBody1>
          <BigidTooltip title={t('overrideSureMatchTooltip')}>
            <BigidInfoSmallIconBox>
              <BigidInfoSmallIcon />
            </BigidInfoSmallIconBox>
          </BigidTooltip>
          {renderField(RegexFormFields.isOverrideSureMatch)}
        </SwitchBox>
      </StatisticsRow>
      {statisticsToShow.map(
        (
          staticToShow: { title: string; value: any; isIdentifiability: boolean; dataAid: string; tooltip: string },
          index,
        ) => (
          <RowItem key={index} data-aid={generateDataAid(staticToShow.dataAid, [])}>
            <RowTitle>
              <BigidBody1 data-aid={generateDataAid(staticToShow.dataAid, ['title'])}>{staticToShow.title}</BigidBody1>
              <BigidTooltip title={staticToShow.tooltip}>
                <BigidHelpIconBox>
                  <BigidHelpIcon />
                </BigidHelpIconBox>
              </BigidTooltip>
            </RowTitle>
            <StatisticValue
              data-aid={generateDataAid(staticToShow.dataAid, ['value'])}
              isIdentifiability={staticToShow.isIdentifiability}
            >
              {staticToShow.value}
            </StatisticValue>
          </RowItem>
        ),
      )}
      {(isDisplayComposeLabel || isDisplayPartialLabel) && (
        <BigidChipContainer>
          {isDisplayComposeLabel && <BigidChip bgColor={BigidColorsV2.white} label={t('compositeIdentifier')} />}
          {isDisplayPartialLabel && <BigidChip bgColor={BigidColorsV2.white} label={t('partialCorrelationSet')} />}
        </BigidChipContainer>
      )}
    </StatisticsContainer>
  );
};
