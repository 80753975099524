import { module } from 'angular';
import { SystemEvents, systemEventsEmitter } from '../../react/services/systemEvents';
const app = module('app');

app.factory('privacyRiskTrendService', [
  '$http',
  'appSettings',
  function ($http, appSettings) {
    const that = this;
    that.getRiskTrendDataRequest = {};

    const privacyRiskTrendService = {
      getRiskTrendData: function getRiskTrendData(queryString) {
        queryString = queryString || '';
        const queryStringValue = queryString || 'none';
        if (!that.getRiskTrendDataRequest[queryStringValue]) {
          that.getRiskTrendDataRequest[queryStringValue] = $http
            .get(appSettings.serverPath + '/' + appSettings.version + '/riskTrends' + queryString)
            .then(function (response) {
              return response.data;
            });
        }
        return that.getRiskTrendDataRequest[queryStringValue];
      },

      clearGetRiskTrendDataRequest: function clearGetRiskTrendDataRequest() {
        that.getRiskTrendDataRequest = {};
      },
    };

    systemEventsEmitter.addEventListener(SystemEvents.LOGOUT, () =>
      privacyRiskTrendService.clearGetRiskTrendDataRequest(),
    );

    return privacyRiskTrendService;
  },
]);
