import { analyticsService } from '../../services/analyticsService';

export enum AutoDiscoveryUITrackingEvent {
  DATA_SOURCES_OPEN_AUTO_DISCOVERY_CLICK = 'DATA_SOURCES_OPEN_AUTO_DISCOVERY_CLICK',
  DATA_SOURCES_START_AUTO_DISCOVERY_CLICK = 'DATA_SOURCES_START_AUTO_DISCOVERY_CLICK',
}

export const trackEventAutoDiscoveryView = (eventType: AutoDiscoveryUITrackingEvent) => {
  analyticsService.trackManualEvent(eventType as any);
};
