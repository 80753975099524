import {
  BigidDropdownOption,
  BigidTagWizardDictionary,
  UseCancelablePromise,
  getTagsDictionary,
} from '@bigid-ui/components';
import { head } from 'lodash';
import { CONFIG } from '../../../config/common';
import { HeadersObject, httpService } from '../../services/httpService';
import { notificationService } from '../../services/notificationService';
import { userPreferencesService } from '../../services/userPreferencesService';
import { formatNumberCompact } from '../../utilities/numericDataConverter';
import { Aggregation } from '../CatalogDiscovery/catalogDiscoveryTypes';
import { DataExplorerPageUserPreferences } from '../DataExplorer/types';
import {
  aggregationIrrelevantNumericFieldIdentifier,
  fetchAllFilterOptionsLimit,
  searchTextMinLength,
} from './constants';
import { SearchResultsSortBy } from './contexts/dataCatalogSearchResultsContext';
import {
  AggregationItemBase,
  AggregationType,
  CatalogSearchGridRow,
  CatalogSearchResultsPayload,
  DataSourceBaseResponseData,
  FetchFilterOptionsPreprocessorFunc,
  FetchFilterOptionsTransformerFunc,
  GetAggregatedDataPayload,
  GetAggregatedDataResponse,
  ResultsEntityType,
  ResultsMappingFunc,
  SearchResult,
  SearchResultResponse,
} from './types';
import { TagEntity, getTagsAllPairs } from '../TagsManagement/TagsManagementService';

const DATA_EXPLORER_BASE_ENDPOINT = 'data-explorer';

const DATA_EXPLORER_OBJECTS_ENDPOINT = `${DATA_EXPLORER_BASE_ENDPOINT}/objects`;

const mapCatalogResponseToGridRow = (results: SearchResult<'catalog'>[]): CatalogSearchGridRow<'catalog'>[] => {
  return results.map(({ data, highlightedValue }) => {
    const title = head(highlightedValue?.find(({ fieldName }) => fieldName === 'name')?.highlightedValue) || data.name;
    const highlightedSource = head(highlightedValue?.find(({ fieldName }) => fieldName === 'source')?.highlightedValue);
    const datasourceValue = highlightedSource || data.source;
    const { fullyQualifiedName, scannerType, type, objectName, fullPath } = data;

    return {
      id: fullyQualifiedName,
      data: {
        datasource: {
          value: datasourceValue,
          scannerType: scannerType,
        },
        type,
        objectName,
        fullPath,
      },
      highlights: highlightedValue || [],
      title,
      entityType: 'catalog',
    };
  });
};

const mapDataSourcesResponseToGridRow = (
  results: SearchResult<'datasource'>[],
): CatalogSearchGridRow<'datasource'>[] => {
  return results.map(({ data: { scanDate, name, _es_mongoId, owner }, highlightedValue }) => {
    return {
      highlights: highlightedValue || [],
      id: name,
      title: name,
      data: {
        name: name,
        modifiedDate: scanDate,
        owners: owner || [],
        id: _es_mongoId,
      },
      entityType: 'datasource',
    };
  });
};

const mapAttributeResponseToGridRow = (
  results: SearchResult<'business_attribute'>[],
): CatalogSearchGridRow<'business_attribute'>[] => {
  return results.map(({ data: { scanDate, name, _es_mongoId, owner }, highlightedValue }) => {
    return {
      highlights: highlightedValue || [],
      id: name,
      title: name,
      data: {
        name: name,
        modifiedDate: scanDate,
        owners: owner,
        id: _es_mongoId,
      },
      entityType: 'business_attribute',
    };
  });
};

const mapPolicyResponseToGridRow = (results: SearchResult<'policy'>[]): CatalogSearchGridRow<'policy'>[] => {
  return results.map(({ data: { name, owner, _es_mongoId, _es_date }, highlightedValue }) => {
    return {
      highlights: highlightedValue || [],
      id: name,
      title: name,
      data: {
        name: name,
        owners: owner || [],
        modifiedDate: _es_date,
        id: _es_mongoId,
      },
      entityType: 'policy',
    };
  });
};

const mapTermResponseToGridRow = (
  results: SearchResult<'business_term'>[],
): CatalogSearchGridRow<'business_term'>[] => {
  return results.map(({ data: { name, scanDate, _es_mongoId, owner }, highlightedValue }) => {
    return {
      highlights: highlightedValue || [],
      id: name,
      title: name,
      data: {
        name: name,
        modifiedDate: scanDate,
        owners: owner,
        id: _es_mongoId,
      },
      entityType: 'business_term',
    };
  });
};

const resultEntityTypeToMappingFuncMap: {
  [K in ResultsEntityType]: ResultsMappingFunc<K>;
} = {
  catalog: mapCatalogResponseToGridRow,
  datasource: mapDataSourcesResponseToGridRow,
  business_attribute: mapAttributeResponseToGridRow,
  policy: mapPolicyResponseToGridRow,
  business_term: mapTermResponseToGridRow,
};

function getMappingFunction<K extends ResultsEntityType>(type: K): ResultsMappingFunc<K> {
  return resultEntityTypeToMappingFuncMap[type];
}

type FetchSearchResultParams = {
  entityType: ResultsEntityType;
  payload: CatalogSearchResultsPayload;
  sortBy: SearchResultsSortBy;
};
export const fetchSearchResults: ({
  entityType,
  payload,
  sortBy,
}: FetchSearchResultParams) => Promise<CatalogSearchGridRow[]> = async ({ entityType, payload }) => {
  try {
    const {
      data: { results },
    } = await httpService.post<SearchResultResponse, CatalogSearchResultsPayload>(
      `${DATA_EXPLORER_OBJECTS_ENDPOINT}/${entityType}`,
      payload,
    );
    const mapFunc = getMappingFunction(entityType);

    return mapFunc(results);
  } catch (error) {
    notificationService.error(`Failed to get search results.`);
    console.error(error);
    return [];
  }
};

export const updateDataCatalogSearchResultsPreferences = async (
  newPreferences: Partial<DataExplorerPageUserPreferences>,
) => {
  const preferences = await userPreferencesService.get<DataExplorerPageUserPreferences>(
    CONFIG.states.METADATA_SEARCH_RESULTS,
  );

  if (preferences) {
    await userPreferencesService.update({
      ...preferences,
      data: {
        ...preferences.data,
        ...newPreferences,
      },
    });
  } else {
    await userPreferencesService.add({
      preference: CONFIG.states.METADATA_SEARCH_RESULTS,
      data: newPreferences,
    });
  }
};

export function getAggregatedData<T = AggregationItemBase>({
  filter,
  aggregations,
  searchText,
  entityType,
}: GetAggregatedDataPayload) {
  const query = filter ? `?filter=${encodeURIComponent(filter)}` : '';
  const payload: GetAggregatedDataPayload = { aggregations };

  const endpoint = entityType ? `inventory/${entityType}` : 'inventory';

  if (searchText) {
    payload.searchText = searchText;
  }

  return httpService.post<GetAggregatedDataResponse>(`${endpoint}${query}`, payload).then(({ data }) => data);
}

export function parseAggregationItemsToFilterOptions(
  aggregation: Aggregation,
  filterOptionsPreprocessorFunc?: FetchFilterOptionsPreprocessorFunc,
): BigidDropdownOption[] {
  const { aggData } = aggregation;
  const options = (aggData ?? []).map(aggItem => {
    const { aggItemName, docCount } = aggItem;

    return {
      id: aggItemName,
      value: aggItem,
      displayValue: aggItemName,
      annotation:
        !isNaN(docCount) && docCount !== aggregationIrrelevantNumericFieldIdentifier
          ? String(formatNumberCompact(docCount))
          : undefined,
    };
  });

  return filterOptionsPreprocessorFunc ? filterOptionsPreprocessorFunc(options) : options;
}

export async function fetchFilterOptions(
  payload: GetAggregatedDataPayload,
  cancelable: UseCancelablePromise<GetAggregatedDataResponse>,
  aggItemsTransformerFunc: FetchFilterOptionsTransformerFunc,
  aggItemsPreprocessorFunc?: FetchFilterOptionsPreprocessorFunc,
): Promise<BigidDropdownOption[]> {
  const { aggregations } = await cancelable(getAggregatedData(payload) as Promise<GetAggregatedDataResponse>);

  if (!aggregations?.[0]) {
    return [];
  }

  return aggItemsTransformerFunc(aggregations?.[0], aggItemsPreprocessorFunc);
}

type ExportPayload = {
  filter?: string;
  searchText?: string;
};

export const getDataExplorerSearchResultsCSV = ({ filter, searchText }: ExportPayload) => {
  const updatedPayload: ExportPayload = {
    filter: filter?.trim(),
    searchText: searchText?.trim().length >= searchTextMinLength ? searchText.trim() : undefined,
  };

  const headers: HeadersObject = {
    'use-elastic': 'true',
  };

  return httpService.downloadFilePost(`data-catalog/file-download/export`, updatedPayload, '', headers);
};

type GetSearchResultCountParams = Pick<CatalogSearchResultsPayload, 'filter' | 'searchText'>;

export const getSearchResultCount = async (
  payload: GetSearchResultCountParams,
  entityType: ResultsEntityType,
): Promise<number> => {
  const cleanedPayload: GetSearchResultCountParams = {};

  if (payload.filter?.length >= searchTextMinLength) {
    cleanedPayload.filter = payload.filter;
  }

  if (payload.searchText?.trim().length >= searchTextMinLength) {
    cleanedPayload.searchText = payload.searchText;
  }

  try {
    const { data } = await httpService.post<{ count: number }>(
      `${DATA_EXPLORER_BASE_ENDPOINT}/count/${entityType}`,
      cleanedPayload,
    );
    return data.count;
  } catch (error) {
    console.error(error);
    return 0;
  }
};

type FetchUnAssignableTagsResponse = {
  systemTags: TagEntity[];
  dictionary: BigidTagWizardDictionary;
};
export const fetchRemoveableTags = async (
  filter: string,
  searchText: string,
): Promise<FetchUnAssignableTagsResponse> => {
  try {
    const { aggregations } = await getAggregatedData({
      filter: filter.trim(),
      searchText: searchText.trim(),
      aggregations: [
        {
          aggName: AggregationType.TAGS,
          isTotalRequired: true,
          paging: {
            limit: fetchAllFilterOptionsLimit,
            skip: 0,
          },
        },
      ],
    });
    if (!aggregations?.[0]) {
      return {
        systemTags: [],
        dictionary: new Map(),
      };
    }
    const { aggData } = aggregations[0];

    const systemTags = await getTagsAllPairs();

    const unAssignableTags = systemTags.filter(({ tagName }) => {
      return aggData.some(({ aggItemGroup }) => tagName === aggItemGroup);
    });

    const dictionary = getTagsDictionary(
      aggData.map(({ aggItemName, aggItemGroup }) => ({
        name: aggItemGroup,
        value: aggItemName,
      })),
    );

    return {
      systemTags: unAssignableTags,
      dictionary,
    };
  } catch (e) {
    console.error('Error during fetching un assignable tags: ', e);
    return {
      systemTags: [],
      dictionary: new Map(),
    };
  }
};

type GetDataSourceDetailsByNameResponse = {
  ds_connection: Pick<DataSourceBaseResponseData, 'owners_v2'>;
};

export const getDataSourceDetailsByName = async (name: string) => {
  const encodedName = encodeURIComponent(name);
  try {
    const { data } = await httpService.fetch<GetDataSourceDetailsByNameResponse>(`ds_connections/${encodedName}`, {});
    return data?.ds_connection;
  } catch (error) {
    console.error(error);
    return null;
  }
};
