import React, { FC, ReactText, useRef, useState, useEffect } from 'react';
import {
  BigidGridWithToolbar,
  BigidGridWithToolbarProps,
  BigidGridQueryComponents,
  BigidGridRow,
  NextGridState,
} from '@bigid-ui/grid';
import { BigidPauseIcon, BigidStopIcon, BigidCatalogIcon, BigidResumeIcon } from '@bigid-ui/icons';
import { ToolbarAction, BigidLoader } from '@bigid-ui/components';
import makeStyles from '@mui/styles/makeStyles';
import { queryService } from '../../../../../services/queryService';
import { httpService } from '../../../../../services/httpService';
import { notificationService } from '../../../../../services/notificationService';
import { cloneDeep } from 'lodash';
import {
  MetadataModel,
  MetadataConnectionsResponse,
  ScanState,
} from '../../../../AutoDiscoveryWizard/MetadataConnectionTypes';
import { FmsdExplorerColumns } from './fmsdExplorerColumnsManager';
import {
  ExplorerGridFiltersMap,
  generateFilterToolbarConfigMap,
} from '../../../../AutoDiscoveryWizard/AutoDiscoveryExplorer/explorerGridFiltersManager';
import { useScanProgressUpdater } from './hooks/useScanProgressUpdater';
import { $state } from '../../../../../services/angularServices';
import { CONFIG } from '../../../../../../config/common';
import { applyDsScanAction, DsScanActions } from '../../fmsdDiscoverServices';
import { userPreferencesService } from '../../../../../services/userPreferencesService';
import { useLocalTranslation } from '../../../translations';

const splitAbleDsTypes = ['s3', 'dynamodb', 'cassandra', 'mongodb', 'cosmosdb', 'databricks'];

export interface DsGridRow extends BigidGridRow {
  name: string;
}

export interface FmsdExplorerProps {
  dataSourceName: string;
  onSelection: (selectedRowIds: ReactText[]) => void;
  onRowsDataChange: (rowsData: DsGridRow[]) => void;
}

const useStyles = makeStyles({
  fmsdGridWrapper: {
    width: '100%',
    display: 'flex',
    position: 'relative',
    height: 'calc(100% - 58px)',
    maxHeight: '100%',
    overflowX: 'hidden',
    overflowY: 'scroll',
  },
  actionsButton: {
    minWidth: 160,
  },
});

export const FmsdExplorer: FC<FmsdExplorerProps> = ({ dataSourceName, onSelection, onRowsDataChange }) => {
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(true);
  const [gridFilters, setGridFilters] = useState<ExplorerGridFiltersMap>(new Map());
  const selectedIdsRef = useRef([]);
  const { t } = useLocalTranslation('Discover.toolbar');
  useScanProgressUpdater(dataSourceName);
  const stateChangeHandler = (state: NextGridState) => {
    const { selectedRowIds } = state;
    // Call onSelection only for real changes in selection
    if (selectedIdsRef.current.length !== selectedRowIds.length || selectedRowIds.length > 0) {
      selectedIdsRef.current = selectedRowIds;
      onSelection(selectedRowIds);
    }
  };

  const toolbarActions: ToolbarAction[] = [
    {
      label: t('pause'),
      icon: BigidPauseIcon,
      isGlobal: false,
      isInline: true,
      hideActionInToolBar: true,
      execute: ({ selectedRows }) => {
        applyDsScanAction(selectedRows[0]?.name, dataSourceName, DsScanActions.PAUSE);
        return Promise.resolve({ shouldGridReload: false });
      },
      disable: ({ selectedRowIds }) => {
        return selectedRowIds.length !== 1;
      },
      show: ({ selectedRows }) => {
        return (
          splitAbleDsTypes.includes(selectedRows[0]?.type) && selectedRows[0]?.scan?.state === ScanState.IN_PROGRESS
        );
      },
    },
    {
      label: t('resume'),
      icon: BigidResumeIcon,
      isGlobal: false,
      isInline: true,
      hideActionInToolBar: true,
      execute: ({ selectedRows }) => {
        applyDsScanAction(selectedRows[0]?.name, dataSourceName, DsScanActions.RESUME);
        return Promise.resolve({ shouldGridReload: false });
      },
      disable: ({ selectedRowIds }) => {
        return selectedRowIds.length !== 1;
      },
      show: ({ selectedRows }) => {
        return selectedRows[0]?.scan?.state === ScanState.PAUSED;
      },
    },
    {
      label: t('stop'),
      icon: BigidStopIcon,
      isGlobal: false,
      isInline: true,
      hideActionInToolBar: true,
      execute: ({ selectedRows }) => {
        applyDsScanAction(selectedRows[0]?.name, dataSourceName, DsScanActions.STOP);
        return Promise.resolve({ shouldGridReload: false });
      },
      disable: ({ selectedRowIds }) => {
        return selectedRowIds.length !== 1;
      },
      show: ({ selectedRows }) => {
        return selectedRows[0]?.scan?.state === ScanState.IN_PROGRESS;
      },
    },
    {
      label: t('info'),
      icon: BigidCatalogIcon,
      isGlobal: false,
      isInline: true,
      hideActionInToolBar: true,
      execute: () => {
        $state.go(CONFIG.states.SCANS_NEW_SCANS_IN_PROGRESS);
        return Promise.resolve({ shouldGridReload: false });
      },
      disable: ({ selectedRowIds }) => {
        return selectedRowIds.length !== 1;
      },
      show: ({ selectedRows }) => {
        return selectedRows[0]?.scan?.state !== undefined;
      },
    },
    {
      label: '',
      isGlobal: false,
      show: () => false,
      disable: () => {
        return false;
      },
    },
  ];

  const updateRows = (rows: DsGridRow[]) => {
    onRowsDataChange(rows);
  };

  const gridWithToolbarConfig: BigidGridWithToolbarProps<MetadataModel> = {
    columns: FmsdExplorerColumns.get(dataSourceName) || FmsdExplorerColumns.get('base'),
    pageSize: 200,
    entityName: t('entityName'),
    onGridStateChange: stateChangeHandler,
    toolbarActions,
    showSelectAll: false,
    filterToolbarConfig: gridFilters.get(dataSourceName) || gridFilters.get('base'),
    hideColumnChooser: true,
    fetchData: async (queryComponents: BigidGridQueryComponents) => {
      const updatedQueryComp = cloneDeep(queryComponents);
      updatedQueryComp.filter.push({ field: 'type', operator: 'in', value: [dataSourceName] });
      let data: MetadataModel[] = [];
      let totalCount = 0;

      try {
        const gridConfigQuery = queryService.getGridConfigQuery({ ...updatedQueryComp });
        const {
          data: {
            data: { dsConnections, totalCount: count },
          },
        } = await httpService.fetch<{ data: MetadataConnectionsResponse }>(
          `ds-metadata?&includeScans=true&activeScansOnly=false&${gridConfigQuery}`,
        );
        data = dsConnections;
        updateRows(dsConnections);
        totalCount = count;
      } catch (e) {
        console.error(e);
        notificationService.error(t('fetchDataError'));
      }
      return {
        data,
        totalCount,
      };
    },
    showSortingControls: true,
  };

  useEffect(() => {
    const updateFilters = async () => {
      setGridFilters(await generateFilterToolbarConfigMap());
      await userPreferencesService.update({
        preference: CONFIG.states.SCANS,
        data: { isNewScansPage: true },
      });
      setIsLoading(false);
    };
    updateFilters();
  }, []);

  return (
    <div className={classes.fmsdGridWrapper}>
      {isLoading ? <BigidLoader /> : <BigidGridWithToolbar key={dataSourceName} {...gridWithToolbarConfig} />}
    </div>
  );
};
