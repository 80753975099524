import React, { FC, useCallback, useMemo, useRef } from 'react';
import { BigidLayoutMasterDetails, BigidLayoutMasterDetailsConfig, useLayout } from '@bigid-ui/layout';
import { BigidGridProps, BigidGridRow } from '@bigid-ui/grid';
import { useLocalTranslation } from '../translations';
import { notificationService } from '../../../services/notificationService';
import { alphaSort, createGridConfig, createLayoutConfig } from './utils';
import { useFormActions } from './hooks/useFormActions';
import { useRouteLeavingListener } from '../../../components/RouteLeaving/hooks/useRouteLeavingListener';
import { getBrands } from './brandsService';
import { BrandGridRow, BrandItemResponse, ForwardFormRef } from './brandsTypes';
import { BrandsEmptyState } from './BrandsEmptyState';
import { BigidLayoutMasterDetailsRenderProps } from '../../../types/BigidLayoutMasterDetailsTypes';
import { BrandsHeader } from './BrandsHeader';
import { BrandsFormMemo } from './BrandsForm';
import { usePageHeader } from './hooks/usePageHeader';

type BrandsProps = {
  dataAid?: string;
};

export const Brands: FC<BrandsProps> = ({ dataAid = 'BrandsPage' }) => {
  const brandsFormRef = useRef<ForwardFormRef>();
  const { t } = useLocalTranslation();

  const layout = useLayout<BrandGridRow>('BigidLayoutMasterDetails', {
    pageSize: 1000,
    fetchDataFunction: async () => {
      try {
        const brandsData = await getBrands();
        return {
          data:
            brandsData.sort(alphaSort)?.map((item: BrandItemResponse) => ({
              ...item,
              id: item._id,
              key: item._id,
            })) ?? [],
          totalCount: brandsData.length ?? 0,
        };
      } catch (error) {
        notificationService.error(t('message.commonErrorMessage'));
      }
    },
    initialSorting: [{ field: 'name', order: 'asc' }],
  });
  const { gridId, rows, isLoading, apiRef, getSelectedRow, ...rest } = layout;
  const selectedRow = getSelectedRow();
  const hasNoBrands = !rows?.length && !isLoading;

  const gridConfig: BigidGridProps<BrandGridRow> = useMemo(
    () => createGridConfig(gridId, { ...rest, rows, isLoading, apiRef }),

    [gridId, rest, rows, isLoading, apiRef],
  );

  const {
    isBusy,
    formControls,
    handleSubmit,
    handleDelete,
    handleBrandAdd,
    handleSelectionChange,
    handleSearchChange,
    showUnsavedChangesDialog,
  } = useFormActions({
    row: selectedRow,
    layout,
    brandsFormRef: brandsFormRef,
  });

  usePageHeader({ handleBrandAdd, shouldShowAction: !!rows?.length });
  const config: BigidLayoutMasterDetailsConfig<BrandGridRow> = useMemo(
    () => createLayoutConfig(gridConfig),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [gridConfig],
  );

  const { updateIsRouteLeavingEnabled } = useRouteLeavingListener(showUnsavedChangesDialog);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const updateIsRouteLeavingEnabledMemo = useCallback(updateIsRouteLeavingEnabled, []);

  return (
    <BigidLayoutMasterDetails
      dataAid={dataAid}
      disabled={isBusy || hasNoBrands}
      onSelectionChange={handleSelectionChange}
      onSearchSubmit={handleSearchChange}
      onSearchChange={handleSearchChange}
      config={config as unknown as BigidLayoutMasterDetailsConfig<BigidGridRow>}
    >
      <BigidLayoutMasterDetails.Header
        render={({ selectedItem }: BigidLayoutMasterDetailsRenderProps<BrandGridRow>): JSX.Element => {
          const { isPending } = selectedItem ?? {};
          return (
            selectedItem && (
              <BrandsHeader
                title={isPending ? t('message.addBrandsHeader') : selectedItem?.name}
                row={selectedItem}
                isBusy={isBusy}
                handleSubmit={handleSubmit}
                handleDelete={handleDelete}
              />
            )
          );
        }}
      />
      <BigidLayoutMasterDetails.Content
        render={({ selectedItem }: BigidLayoutMasterDetailsRenderProps<BrandGridRow>) => {
          return selectedItem ? (
            <BrandsFormMemo
              key={selectedItem?.id}
              ref={brandsFormRef}
              row={selectedItem}
              formControls={formControls}
              setFormIsTouched={updateIsRouteLeavingEnabledMemo}
            />
          ) : (
            hasNoBrands && <BrandsEmptyState handleBrandAdd={handleBrandAdd} />
          );
        }}
      />
    </BigidLayoutMasterDetails>
  );
};
