import React, { FC, PropsWithChildren } from 'react';
import { MtMigrationEmptyState } from './MtMigrationEmptyState';
import { getApplicationPreference } from '../../services/appPreferencesService';
import { MtNotSupportedViews } from './mtMigrationEmptyStateUtils';

export interface MtMigrationWrapperProps extends PropsWithChildren {
  viewId: MtNotSupportedViews;
}

export const MtMigrationWrapper: FC<MtMigrationWrapperProps> = ({ children, viewId }) => {
  return (
    <>{getApplicationPreference('MULTI_TENANT_MODE_ENABLED') ? <MtMigrationEmptyState id={viewId} /> : children}</>
  );
};
