import { DsConnectionDocPreConnectionType, DsConnectionDocItemResource } from '../types';

export const decodeContent = (
  preConnectionType: DsConnectionDocPreConnectionType,
  data: DsConnectionDocItemResource[],
): string => {
  const { preConnectionData } = data.find(({ preConnectionType: type }) => type === preConnectionType) ?? {};

  return preConnectionData && typeof preConnectionData === 'string' ? atob(preConnectionData) : null;
};
