import React, { FC, useMemo, useRef, useState, memo } from 'react';
import {
  TertiaryButton,
  SecondaryButton,
  PrimaryButton,
  BigidDialog,
  BigidDialogButtonType,
} from '@bigid-ui/components';
import { generateDataAid } from '@bigid-ui/utils';
import { BigidPolicyIcon } from '@bigid-ui/icons';
import { noop } from 'lodash';
import { useLocalTranslation } from './translations';
import { UseCatalogDiscoveryResponse } from '../../../useCatalogDiscovery';
import { DefinePolicyForm, DefinePolicyFormStateAndControls } from './DefinePolicyForm';

export interface DefinePolicyProps extends Pick<UseCatalogDiscoveryResponse, 'query'> {
  dataAid?: string;
}

export const DefinePolicy: FC<DefinePolicyProps> = memo(
  ({ dataAid = 'DefinePolicy', query }) => {
    const { t } = useLocalTranslation();
    const [isFormBusy, setIsFormBusy] = useState<boolean>(false);
    const [isDialogShown, setIsDialogShown] = useState<boolean>(false);
    const dialogControls = useRef<DefinePolicyFormStateAndControls>();

    const handleDialogControlsChange = ({ isBusy }: DefinePolicyFormStateAndControls) => {
      setIsFormBusy(isBusy);
    };

    const handleDialogShow = () => {
      setIsDialogShown(true);
    };

    const handleDialogClose = () => {
      setIsDialogShown(false);
    };

    const buttons: BigidDialogButtonType[] = useMemo(() => {
      return [
        {
          dataAid: generateDataAid(dataAid, ['dialog', 'button', 'cancel']),
          text: t('dialog.buttons.cancel'),
          component: SecondaryButton,
          alignment: 'left',
          onClick: noop,
          isClose: true,
        },
        {
          dataAid: generateDataAid(dataAid, ['dialog', 'button', 'test']),
          text: t('dialog.buttons.test'),
          component: PrimaryButton,
          alignment: 'right',
          disabled: isFormBusy,
          onClick: () => {
            dialogControls.current?.onTest.execute();
          },
        },
        {
          dataAid: generateDataAid(dataAid, ['dialog', 'button', 'save']),
          text: t('dialog.buttons.save'),
          component: PrimaryButton,
          alignment: 'right',
          disabled: isFormBusy,
          onClick: () => {
            dialogControls.current?.onSave.execute();
          },
        },
      ];
    }, [dataAid, isFormBusy, t]);

    /**
    NOTE: BigidDialog is used directly due to the following reasons:
    1) An inner padding that mismatch the design once isContentScrollable is true
    2) A necessity to manage buttons availability based on the form state
   */

    return (
      <>
        {isDialogShown && (
          <BigidDialog
            borderTop
            showCloseIcon
            isContentScrollable
            maxWidth="xs"
            title={t('dialog.title')}
            isOpen={isDialogShown}
            onClose={handleDialogClose}
            buttons={buttons}
            isLoading={isFormBusy}
          >
            <DefinePolicyForm
              dataAid={generateDataAid(dataAid, ['form'])}
              query={query}
              dialogControls={dialogControls}
              onDialogControlsChange={handleDialogControlsChange}
              onClose={handleDialogClose}
            />
          </BigidDialog>
        )}
        <TertiaryButton
          dataAid={generateDataAid(dataAid, ['button'])}
          startIcon={<BigidPolicyIcon />}
          size="medium"
          onClick={handleDialogShow}
          disabled={!query}
          text={t('actionButton')}
        />
      </>
    );
  },
  (prevProps, newProps) => prevProps.query === newProps.query,
);
