import React, { FC, Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import { BigidColors, EntityEvents, entityEventsEmitter } from '@bigid-ui/components';
import {
  ActionsFormatterProps,
  BigidGridColumn,
  BigidGridColumnTypes,
  BigidGridProps,
  BigidGridRow,
  CheckboxFormatterProps,
  ChipsFormatterProps,
  FetchDataFunction,
  FormatterOnClickHandlerPayloadData,
  FormatterOnClickHandlerReturnData,
  LinkFormatterProps,
} from '@bigid-ui/grid';
import {
  BigidLayout,
  BigidLayoutConfig,
  BigidLayoutEmptyState,
  BigidLayoutEmptyStateAction,
  LayoutContentType,
} from '@bigid-ui/layout';
import { deleteTag, getTagsAllPairs, TagsManagementGridRecord, updateTag } from './TagsManagementService';
import { getUserDefinedTagsOnly, getTagsAggregatedById } from './TagsManagementUtils';
import { EditTagDialog, EditTagDialogProps } from './EditTagDialog/EditTagDialog';
import { notificationService } from '../../services/notificationService';
import { pageHeaderService } from '../../../common/services/pageHeaderService';
import { DeleteOutlined } from '@mui/icons-material';
import { showConfirmationDialog } from '../../services/confirmationDialogService';
import { isPermitted } from '../../services/userPermissionsService';
import { TAGS_PERMISSIONS } from '@bigid/permissions';
import makeStyles from '@mui/styles/makeStyles';
import { BigidTagIllustration } from '@bigid-ui/icons';
import { useTheme } from '@mui/styles';

export interface TagsManagementGridRecordExtended extends TagsManagementGridRecord, BigidGridRow {
  tagNameFormatted: LinkFormatterProps;
  tagValuesFormatted: ChipsFormatterProps;
  tagMutuallyExclusiveFormatted: CheckboxFormatterProps;
}

const useStyles = makeStyles({
  noData: {
    width: '100%',
    height: '100%',
    position: 'absolute',
  },
});

export const TagsManagement: FC = () => {
  const theme = useTheme();
  const classes = useStyles({});
  const [editTagDialogState, setEditTagDialogState] = useState<EditTagDialogProps>({
    title: '',
    isOpen: false,
  });

  const { isAddTagPermitted, isEditTagPermitted, isDeleteTagPermitted } = useMemo(
    () => ({
      isAddTagPermitted: isPermitted(TAGS_PERMISSIONS.CREATE.name),
      isEditTagPermitted: isPermitted(TAGS_PERMISSIONS.EDIT.name),
      isDeleteTagPermitted: isPermitted(TAGS_PERMISSIONS.DELETE.name),
    }),
    [],
  );

  const closeEditTagDialogState = useCallback(() => {
    setEditTagDialogState(prevState => ({
      ...prevState,
      title: '',
      tag: undefined,
      isOpen: false,
    }));
  }, []);

  const fetchGridData: FetchDataFunction<TagsManagementGridRecordExtended> = async ({ filter }) => {
    try {
      const searchQuery = filter.length > 0 ? filter?.[0].value.toString() : '';
      const tags = (await getTagsAllPairs(searchQuery))?.filter(({ properties }) => !properties?.hidden);
      const userDefinedTags = getUserDefinedTagsOnly(tags);
      const gridData = getTagsAggregatedById(userDefinedTags);

      const data = gridData.map(gridRecord => {
        const { tagId, tagName, tagValues, isMutuallyExclusive } = gridRecord;

        const tagNameFormatted: LinkFormatterProps = {
          link: {
            text: tagName,
            disabled: !isEditTagPermitted,
          },
          linkParams: {
            tagId,
            tagName,
            tagValues,
          },
        };

        const tagValuesFormatted: ChipsFormatterProps = {
          chips: {
            chipBgColor: theme.vars.tokens.bigid.backgroundSecondary,
            value: tagValues.map(({ tagValue }) => ({
              label: tagValue,
            })),
            isDisabled: true,
          },
        };

        const tagMutuallyExclusiveFormatted: CheckboxFormatterProps = {
          type: 'primary',
          checkbox: {
            checked: isMutuallyExclusive,
            disabled: !isAddTagPermitted || !isEditTagPermitted || !isDeleteTagPermitted,
          },
        };

        return {
          ...gridRecord,
          id: tagId,
          tagNameFormatted,
          tagValuesFormatted,
          tagMutuallyExclusiveFormatted,
        };
      });

      return {
        totalCount: data.length,
        data,
      };
    } catch ({ message }) {
      notificationService.error('An error has occured');
      console.error(`An error has occured: ${message}`);

      return {
        totalCount: 0,
        data: [],
      };
    }
  };

  const deleteColumnIfPermitted: BigidGridColumn<TagsManagementGridRecordExtended>[] = isDeleteTagPermitted
    ? [
        {
          name: 'actions',
          title: '',
          width: '150',
          noHeader: true,
          isNotDraggable: true,
          type: BigidGridColumnTypes.ACTIONS,
          getCellValue: () => {
            const actionsFormatted: ActionsFormatterProps = {
              actions: [
                {
                  name: 'delete',
                  iconConfig: {
                    icon: DeleteOutlined,
                    color: BigidColors.link,
                  },
                  execute: async ({ row }: FormatterOnClickHandlerPayloadData) => {
                    try {
                      const isTagDeletionConfirmed = await showConfirmationDialog({
                        entityNameSingular: 'Tag',
                        actionName: 'Delete',
                        actionButtonName: 'Delete',
                      });

                      if (isTagDeletionConfirmed) {
                        await deleteTag(row.tagId);
                        return {
                          row,
                          shouldGridReload: false,
                          entityEventToEmit: EntityEvents.DELETE,
                        };
                      } else {
                        return {};
                      }
                    } catch ({ message }) {
                      notificationService.error('An error has occured');
                      console.error(`An error has occured: ${message}`);
                      return {};
                    }
                  },
                },
              ],
            };

            return actionsFormatted;
          },
        },
      ]
    : [];

  const emptyStateActions: BigidLayoutEmptyStateAction[] = [
    {
      label: 'Add Tag',
      execute: async () => {
        return new Promise((resolve, reject) => {
          setEditTagDialogState(prevState => ({
            ...prevState,
            title: 'Add Tag',
            isOpen: true,
            onSubmit: () => {
              closeEditTagDialogState();
              resolve({ shouldGridReload: true });
              entityEventsEmitter.emit(EntityEvents.RELOAD);
            },
            onClose: () => {
              reject();
            },
          }));
        });
      },
      show: () => {
        return isAddTagPermitted;
      },
    },
  ];

  const gridConfig: BigidGridProps<TagsManagementGridRecordExtended> = {
    showSortingControls: false,
    showSelectionColumn: false,
    pageSize: 5000, // NOTE: until API supports pagination
    noDataContent: (
      <div className={classes.noData}>
        <BigidLayoutEmptyState
          illustration={BigidTagIllustration}
          actions={emptyStateActions}
          description="No tags created"
        />
      </div>
    ),
    columns: [
      {
        title: 'Tag Name',
        name: 'tagNameFormatted',
        type: BigidGridColumnTypes.LINK,
        width: 450,
        getCellValue: ({ tagNameFormatted }) => tagNameFormatted,
        formatter: {
          onClick: ({ row }) => {
            return new Promise((resolve, reject) => {
              setEditTagDialogState(prevState => ({
                ...prevState,
                title: 'Edit Tag',
                tag: row,
                isOpen: true,
                onSubmit: ({ shouldGridReload }) => {
                  closeEditTagDialogState();
                  resolve({
                    shouldGridReload,
                    row,
                    entityEventToEmit: EntityEvents.RELOAD,
                  });
                },
                onClose: () => {
                  reject();
                },
              }));
            });
          },
        },
      },
      {
        title: 'Tag Values',
        name: 'tagValuesFormatted',
        width: 1000,
        type: BigidGridColumnTypes.CHIPS,
        getCellValue: ({ tagValuesFormatted }) => tagValuesFormatted,
      },
      {
        title: 'Mutually Exclusive',
        name: 'tagMutuallyExclusiveFormatted',
        getCellValue: ({ tagMutuallyExclusiveFormatted }) => tagMutuallyExclusiveFormatted,
        type: BigidGridColumnTypes.CHECKBOX,
        width: 150,
        formatter: {
          onClick: async ({
            value: cellValue,
            row,
            column,
          }: FormatterOnClickHandlerPayloadData): Promise<FormatterOnClickHandlerReturnData> => {
            try {
              const isMutuallyExclusive = !cellValue.checkbox.checked;
              const newCellValue = {
                ...cellValue,
                checkbox: {
                  ...cellValue.checkbox,
                  checked: isMutuallyExclusive,
                },
              };

              await updateTag(row.tagId, { isMutuallyExclusive });

              return Promise.resolve({
                row: {
                  ...row,
                  [column.name]: newCellValue,
                },
                shouldGridReload: false,
              });
            } catch ({ message }) {
              notificationService.error('An error has occured');
              console.error(`An error has occured: ${message}`);

              return Promise.resolve({
                row: {
                  ...row,
                  [column.name]: cellValue,
                },
                shouldGridReload: false,
              });
            }
          },
        },
      },
      ...deleteColumnIfPermitted,
    ],
  };

  const layoutConfig = {
    filter: {
      hasInitialFilter: false,
      search: {
        isQueryLanguage: false,
      },
    },
    content: {
      entityName: 'tags',
      contentTypes: [LayoutContentType.GRID],
      viewConfig: {
        fetchGridData,
        gridConfig,
        toolbarConfig: {
          hideToolbar: false,
          hideColumnChooser: true,
        },
      },
      toolbarActions: [
        {
          label: 'Add New',
          execute: async () => {
            return new Promise((resolve, reject) => {
              setEditTagDialogState(prevState => ({
                ...prevState,
                title: 'Add New Tag',
                isOpen: true,
                onSubmit: ({ shouldGridReload }) => {
                  closeEditTagDialogState();
                  resolve({ shouldGridReload });
                },
                onClose: () => {
                  reject();
                },
              }));
            });
          },
          disable: () => false,
          show: () => isAddTagPermitted,
        },
      ],
    },
  } as BigidLayoutConfig;

  useEffect(() => {
    pageHeaderService.setTitle({ pageTitle: 'Tags Management' });
  }, []);

  const editTagDialogConfig: EditTagDialogProps = useMemo(
    () => ({
      ...editTagDialogState,
      onClose: closeEditTagDialogState,
    }),
    [editTagDialogState, closeEditTagDialogState],
  );

  return (
    <Fragment>
      <BigidLayout config={layoutConfig} />
      <EditTagDialog {...editTagDialogConfig} />
    </Fragment>
  );
};

export class TagsComponentClass extends React.Component {
  render() {
    return <TagsManagement />;
  }
}
