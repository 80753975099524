import React, { FC, memo, MouseEvent } from 'react';
import { MetadataSearchResultEntity } from '../MetadataSearchTypes';
import { MetadataSearchResultsGridRecord } from './MetadataSearchResultsGridTypes';
import { MetadataSearchResultsGridCell } from './MetadataSearchResultsGridCell';
import { UseMetadataSearchStateResponse } from '../useMetadataSearchState';
import { useStyles } from './MetadataSearchResultsGridStyles';
import classNames from 'classnames';

export interface MetadataSearchResultsGridProps
  extends Pick<UseMetadataSearchStateResponse, 'onSearchResultEntityClick'> {
  dataAid: string;
  data: MetadataSearchResultsGridRecord[];
  rowHeight?: number;
}

export const MetadataSearchResultsGrid: FC<MetadataSearchResultsGridProps> = memo(
  ({ dataAid, data = [], rowHeight = 36, onSearchResultEntityClick }) => {
    const classes = useStyles({ rowHeight });

    const handleResultEntityClick = (event: MouseEvent, originalRecord: MetadataSearchResultEntity): void => {
      event.stopPropagation();
      onSearchResultEntityClick(originalRecord);
    };

    return (
      <div className={classes.root} data-aid={dataAid}>
        {data.map((row, index) => {
          const { id, columns, originalRecord } = row;
          const rowDataAid = `${dataAid}-row-${id}`;
          const isRowEven = index % 2 === 1;

          return (
            <div
              key={index}
              data-aid={rowDataAid}
              className={classNames(classes.row, isRowEven ? classes.rowEven : classes.rowOdd)}
              onClick={event => handleResultEntityClick(event, originalRecord)}
            >
              {columns.map((column, index) => {
                const { width, ...columnConfig } = column;
                const columnDataAid = `${rowDataAid}-column-${columnConfig.name}`;

                return (
                  <div
                    key={index}
                    style={{ flexBasis: `${width}` }}
                    className={classNames(classes.column, width ? classes.fixedWidthColumn : classes.autoWidthColumn)}
                    data-aid={columnDataAid}
                  >
                    {columnConfig.value && <MetadataSearchResultsGridCell dataAid={columnDataAid} {...columnConfig} />}
                  </div>
                );
              })}
            </div>
          );
        })}
      </div>
    );
  },
);

MetadataSearchResultsGrid.displayName = 'MetadataSearchResultsGrid';
