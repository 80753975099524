import React, { FC, PropsWithChildren } from 'react';
import { styled } from '@mui/material';
import { gridWidgetHeight } from '../config/widgets';

export interface LayoutBasedWidgetWrapperProps extends PropsWithChildren {
  dataAid?: string;
  dataTourId?: string;
  isReady: boolean;
}

const Root = styled('div')<{ hasPadding: boolean }>`
  width: 100%;
  min-height: ${gridWidgetHeight}px;
  height: ${gridWidgetHeight}px;
  padding: ${({ hasPadding }) => (hasPadding ? '16px' : 0)};
`;

export const LayoutBasedWidgetWrapper: FC<LayoutBasedWidgetWrapperProps> = ({
  dataAid = 'LayoutBasedWidgetWrapper',
  dataTourId = 'LayoutBasedWidgetWrapper',
  isReady,
  children,
}) => {
  return (
    <Root data-aid={dataAid} data-tour-id={dataTourId} hasPadding={!isReady}>
      {children}
    </Root>
  );
};
