import './fileUploader.component.scss';
import template from './fileUploader.component.html';
import { module } from 'angular';
const app = module('app');

app.component('fileUploader', {
  template,
  controllerAs: 'fileUploaderModel',
  controller: [
    '$rootScope',
    '$scope',
    'Upload',
    '$q',
    function ($rootScope, $scope, Upload, $q) {
      var fileUploaderModel = this;

      var baseIconsPath = '/images/files/';

      fileUploaderModel.files = [];
      fileUploaderModel.storage = [];
      fileUploaderModel.counter = 0;

      let handleFilesEncoded = (encoded, files, replace) => {
        $q.all(encoded).then(data => {
          let _files = [];
          for (let i in data) {
            let file = {
              attachmentId: 'attachment-' + fileUploaderModel.counter,
              filename: files[i]['name'],
              fileContent: data[i],
              fileType: files[i]['type'],
              lastModified: files[i]['lastModified'],
            };

            if (replace) {
              fileUploaderModel.storage[i] = file;
            } else {
              fileUploaderModel.storage.push(file);
              fileUploaderModel.counter = fileUploaderModel.counter + 1;
            }

            _files.push(file);
          }

          fileUploaderModel.onFileAdded({ files: _files });
        });
      };

      fileUploaderModel.$onChanges = () => {
        fileUploaderModel.storage = [];
        if (!fileUploaderModel.errors) {
          fileUploaderModel.errors = [
            {
              type: 'limit',
              status: false,
              message: 'The maximum number of files reached. ' + fileUploaderModel.limit,
            },
            {
              type: 'pattern',
              status: false,
              message: 'Unacceptable file extension. Please select ' + fileUploaderModel.permitted,
            },
            {
              type: 'maxSize',
              status: false,
              message: 'File is too big. (' + fileUploaderModel.maxSize + ' max)',
            },
          ];
        }

        if (angular.isDefined(fileUploaderModel.data)) {
          const data = angular.copy(fileUploaderModel.data);
          for (let i in data) {
            fileUploaderModel.storage.push(data[i]);
          }
        }
      };

      fileUploaderModel.setError = (type, prop, value) => {
        for (let i in fileUploaderModel.errors) {
          if (fileUploaderModel.errors[i]['type'] == type) {
            fileUploaderModel.errors[i][prop] = value;
            break;
          }
        }
      };

      fileUploaderModel.resetErrors = () => {
        for (let i in fileUploaderModel.errors) {
          fileUploaderModel.errors[i]['status'] = false;
        }
      };

      fileUploaderModel.getIcon = type => {
        let accordance = {
          'text/csv': 'Bigid_File_Csv.svg',
          'text/plain': 'Bigid_File_Txt.svg',
          'text/xml': 'Bigid_File_Xml.svg',
          'video/mp4': 'Bigid_File_Mp4.svg',
          'video/3gpp': 'Bigid_File_3gp.svg',
          'video/mpeg': 'Bigid_File_Mpeg.svg',
          'audio/mp4': 'Bigid_File_Mp4.svg',
          'audio/mpeg': 'Bigid_File_Mpeg.svg',
          'application/xml': 'Bigid_File_Xml.svg',
          'application/vnd.openxmlformats-officedocument.wordprocessingml.document': 'Bigid_File_Docx.svg',
          'application/msword': 'Bigid_File_Doc.svg',
          'application/pdf': 'Bigid_File_Pdf.svg',
          'application/vnd.ms-excel': 'Bigid_File_Xls.svg',
          'application/zip': 'Bigid_File_Zip.svg',
          'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': 'Bigid_File_Xlsx.svg',
          'image/tiff': 'Bigid_File_Tif.svg',
          'image/jpeg': 'Bigid_File_Jpeg.svg',
          'image/pjpeg': 'Bigid_File_Jpeg.svg',
          'image/gif': 'Bigid_File_Gif.svg',
          'image/png': 'Bigid_File_Png.svg',
          'image/svg+xml': 'Bigid_File_Svg.svg',
        };

        let image = accordance[type];

        return image ? baseIconsPath + image : baseIconsPath + 'Bigid_File_Common.svg';
      };

      fileUploaderModel.removeFile = id => {
        for (let i in fileUploaderModel.storage) {
          if (fileUploaderModel.storage[i]['attachmentId'] == id) {
            fileUploaderModel.storage.splice(i, 1);
            fileUploaderModel.setError('limit', 'status', false);
            fileUploaderModel.counter = fileUploaderModel.counter - 1;
            fileUploaderModel.onFileRemoved({ id: id });
            break;
          }
        }
      };

      fileUploaderModel.filesSelected = ($files, $file, $newFiles, $duplicateFiles, $invalidFiles, $event) => {
        if ($invalidFiles.length > 0) {
          for (let i in $invalidFiles) {
            switch ($invalidFiles[i].$error) {
              case 'maxSize':
                fileUploaderModel.setError('maxSize', 'status', true);
                break;
              case 'pattern':
                fileUploaderModel.setError('pattern', 'status', true);
                break;
            }
          }
        }

        if ($files.length > 0) {
          var encoding = [];

          switch (true) {
            case fileUploaderModel.limit == 1 && fileUploaderModel.replace:
              if (fileUploaderModel.formData) {
                $files[0]['attachmentId'] = 'attachment-' + fileUploaderModel.counter;
                fileUploaderModel.storage[0] = $files[0];
                fileUploaderModel.onFileAdded({ files: $files[0] });
              } else {
                encoding.push(Upload.dataUrl($files[0], true));
                handleFilesEncoded(encoding, $files, true);
              }
              break;
            case fileUploaderModel.limit > 1 &&
              (!fileUploaderModel.replace || typeof fileUploaderModel.replace === 'undefined'):
              var files = [];

              var spaceLeft =
                fileUploaderModel.storage.length == 0
                  ? fileUploaderModel.limit
                  : fileUploaderModel.limit - fileUploaderModel.storage.length;

              for (let i in $files) {
                if (spaceLeft <= 0) {
                  fileUploaderModel.setError('limit', 'status', true);
                  break;
                } else {
                  files.push($files[i]);
                  spaceLeft = spaceLeft - 1;
                  encoding.push(Upload.dataUrl($files[i], true));
                }
              }

              if (fileUploaderModel.formData) {
                for (let i in files) {
                  fileUploaderModel.storage.push(files[i]);
                  fileUploaderModel.counter = fileUploaderModel.counter + 1;
                }
                fileUploaderModel.onFileAdded({ files: files });
              } else {
                handleFilesEncoded(encoding, files);
              }

              break;
          }

          fileUploaderModel.onFileInvalid({ errors: fileUploaderModel.errors });
        } else {
          if ($invalidFiles.length == 0) {
            fileUploaderModel.resetErrors();
          }
        }
      };
    },
  ],
  bindings: {
    filesList: '=',
    limit: '=',
    accept: '=' /* mime types filter ('text/plain', 'images/*' and etc)*/,
    permitted: '=' /* permitted files extension separated by coma ('.pdf,.jpg') */,
    maxSize: '@' /* max file size ('10MB', '1500KB' and etc)*/,
    mode: '@' /* 'drop' or 'select' */,
    data: '<',
    errorsList: '=',
    multiple: '=',
    onFileAdded: '&',
    onFileRemoved: '&',
    onFileInvalid: '&',
    formData: '=',
    btnLabel: '=',
    replace: '=',
  },
});
