import React from 'react';
import angular from 'angular';
import { convertToAngular } from '../../../../common/services/convertToAngular';
import { SarProfileSettings } from './SarProfileSettings';
import { SarProfileSettingsV2 } from './SarProfileSettingsV2';
import { getApplicationPreference } from '../../../services/appPreferencesService';
import { SarProfileSettingsDetailsPage } from './SarProfileSettingsDetailsPage';
import { useLocationChange } from './useLocationChange';

export const SarProfileSettingsWrapper = () => {
  const isNewProfileSettingsPage = getApplicationPreference('DSAR_USE_CATALOG_COLUMNS');
  const { stateParams } = useLocationChange();

  if (!isNewProfileSettingsPage) {
    return <SarProfileSettings />;
  }

  return <>{stateParams.profileId ? <SarProfileSettingsDetailsPage /> : <SarProfileSettingsV2 />}</>;
};

angular.module('app').component('sarProfileSettingsWrapper', convertToAngular(SarProfileSettingsWrapper));
