import React, { FC } from 'react';
import { BigidToggleButton, BigidToggleButtonProps, BigidToggleButtonGroupProps } from '@bigid-ui/components';
import { BigidFilterContainer } from '@bigid-ui/layout';
import { SarProfilePutModel } from '../ProfileSettingsTypes';
import { sarConfigService } from '../sarConfigService';
import { notificationService } from '../../../../services/notificationService';
import { DSAR_PERMISSIONS } from '@bigid/permissions';
import { isPermitted } from '../../../../services/userPermissionsService';
import { SarProfileSourceTypes } from '../SarProfileSettingsTypes';

export interface EnableAllProps {
  profileId: string;
  isAllEnabled: boolean;
  sourceType: SarProfileSourceTypes;
}

export const EnableAll: FC<EnableAllProps> = ({ profileId, sourceType, isAllEnabled }) => {
  const { filter, setFilter } = BigidFilterContainer.useContainer();

  const toggleButtons: BigidToggleButtonProps[] = [
    {
      value: 'true',
      ...(sourceType === SarProfileSourceTypes.DS ? { label: 'All enabled data sources' } : {}),
      ...(sourceType === SarProfileSourceTypes.ES ? { label: 'All enabled correlation sets' } : {}),
      isDisabled:
        !isPermitted(DSAR_PERMISSIONS.EDIT_PROFILE_SETTINGS.name) ||
        !isPermitted(DSAR_PERMISSIONS.MANAGE_ALL_ENABLED_PROFILE_SETTINGS.name),
    },
    {
      value: 'false',
      ...(sourceType === SarProfileSourceTypes.DS ? { label: 'Selected data sources' } : {}),
      ...(sourceType === SarProfileSourceTypes.ES ? { label: 'Selected correlation sets' } : {}),
      isDisabled:
        !isPermitted(DSAR_PERMISSIONS.EDIT_PROFILE_SETTINGS.name) ||
        !isPermitted(DSAR_PERMISSIONS.MANAGE_ALL_ENABLED_PROFILE_SETTINGS.name),
    },
  ];

  const toggleButtonGroup: BigidToggleButtonGroupProps = {
    toggleButtons,
    fullWidth: true,
    initialSelected: String(isAllEnabled),
    enforceValue: true,
    onChange: (event, value) => {
      const newAllEnabledDs = JSON.parse(value) ?? false;
      updateProfile(profileId, sourceType, newAllEnabledDs).then(() => {
        setFilter([...filter]);
      });
    },
  };

  return (
    <div style={{ padding: 20 }}>
      <BigidToggleButton {...toggleButtonGroup} />
    </div>
  );
};

function updateProfile(profileId: string, sourceType: EnableAllProps['sourceType'], value: boolean) {
  const newDbState: SarProfilePutModel = {
    ...(sourceType === SarProfileSourceTypes.DS ? { allEnabledDs: value } : {}),
    ...(sourceType === SarProfileSourceTypes.ES ? { allEnabledEs: value } : {}),
  };
  return sarConfigService
    .updateProfile(profileId, newDbState)
    .then(result => {
      if (result.isModified) {
        notificationService.success(`Changes saved`);
      }
    })
    .catch(err => {
      notificationService.error(`Failed to save changes`);
      console.error(`Failed to update sar_profile item with the new state:`, JSON.stringify(newDbState), err);
      throw err;
    });
}
