import { module } from 'angular';
const app = module('app');

app.factory('dataMappingService', [
  '$http',
  'appSettings',
  function ($http, appSettings) {
    return {
      getBusinessProcesses(type) {
        const queryParams = type ? `?type=${type}` : '';
        return $http
          .get(`${appSettings.serverPath}/${appSettings.version}/dataMapping/businessProcesses${queryParams}`)
          .then(response => response.data);
      },
      getBusinessProcess(id) {
        return $http
          .get(`${appSettings.serverPath}/${appSettings.version}/dataMapping/businessProcesses/${id}`)
          .then(response => response.data);
      },
      createBusinessProcess(data) {
        return $http
          .post(`${appSettings.serverPath}/${appSettings.version}/dataMapping/businessProcesses`, data)
          .then(response => response.data);
      },
      updateBusinessProcess(data, id) {
        return $http
          .put(`${appSettings.serverPath}/${appSettings.version}/dataMapping/businessProcesses/${id}`, data)
          .then(response => response.data)
          .catch(function (response) {
            return { err: response.data.message };
          });
      },
      deleteBusinessProcess(id) {
        return $http
          .delete(`${appSettings.serverPath}/${appSettings.version}/dataMapping/businessProcesses/${id}`)
          .then(response => response.data);
      },

      getBusinessEntities(businessProcessId) {
        return $http
          .get(
            `${appSettings.serverPath}/${appSettings.version}/dataMapping/businessEntities?businessProcessId=${businessProcessId}`,
          )
          .then(response => response.data);
      },
      getBusinessEntity(businessEntityId) {
        return $http
          .get(`${appSettings.serverPath}/${appSettings.version}/dataMapping/businessEntities/${businessEntityId}`)
          .then(response => response.data);
      },
      createBusinessEntity(data) {
        return $http
          .post(`${appSettings.serverPath}/${appSettings.version}/dataMapping/businessEntities`, data)
          .then(response => response.data);
      },
      updateBusinessEntity(data, id) {
        return $http
          .put(`${appSettings.serverPath}/${appSettings.version}/dataMapping/businessEntities/${id}`, data)
          .then(response => response.data);
      },
      updateBusinessEntities(data) {
        return $http
          .put(`${appSettings.serverPath}/${appSettings.version}/dataMapping/businessEntities`, data)
          .then(response => response.data);
      },
      deleteBusinessEntity(id) {
        return $http
          .delete(`${appSettings.serverPath}/${appSettings.version}/dataMapping/businessEntities/${id}`)
          .then(response => response.data);
      },

      getBusinessSteps(businessProcessId) {
        return $http
          .get(
            `${appSettings.serverPath}/${appSettings.version}/dataMapping/businessSteps?businessProcessId=${businessProcessId}`,
          )
          .then(response => response.data);
      },
      createBusinessStep(data) {
        return $http
          .post(`${appSettings.serverPath}/${appSettings.version}/dataMapping/businessSteps`, data)
          .then(response => response.data);
      },
      updateBusinessStep(data, id) {
        return $http
          .put(`${appSettings.serverPath}/${appSettings.version}/dataMapping/businessSteps/${id}`, data)
          .then(response => response.data);
      },
      deleteBusinessStep(id) {
        return $http
          .delete(`${appSettings.serverPath}/${appSettings.version}/dataMapping/businessSteps/${id}`)
          .then(response => response.data);
      },

      getBusinessFlows(businessProcessId) {
        return $http
          .get(
            `${appSettings.serverPath}/${appSettings.version}/dataMapping/businessFlows?businessProcessId=${businessProcessId}`,
          )
          .then(response => response.data);
      },
      createDataFlow(data) {
        return $http
          .post(`${appSettings.serverPath}/${appSettings.version}/dataMapping/businessFlows`, data)
          .then(response => response.data);
      },
      updateDataFlow(data, id) {
        return $http
          .put(`${appSettings.serverPath}/${appSettings.version}/dataMapping/businessFlows/${id}`, data)
          .then(response => response.data);
      },
      deleteDataFlow(id) {
        return $http
          .delete(`${appSettings.serverPath}/${appSettings.version}/dataMapping/businessFlows/${id}`)
          .then(response => response.data);
      },

      createCollaboration(data) {
        return $http
          .post(`${appSettings.serverPath}/${appSettings.version}/dataMapping/collaborations`, data)
          .then(response => response.data);
      },

      getCollaborationByEntityID(entityID) {
        return $http
          .get(
            `${appSettings.serverPath}/${appSettings.version}/dataMapping/collaborations?businessEntityId=${entityID}`,
          )
          .then(response => response.data);
      },

      getCollaborationByProcessId(processID) {
        return $http
          .get(
            `${appSettings.serverPath}/${appSettings.version}/dataMapping/collaborations?businessProcessId=${processID}`,
          )
          .then(response => response.data);
      },

      // PUT / collaborations/collab_id
      //update collaboration to resolved
      updateCollaborationToResolved(data, collab_id) {
        return $http
          .put(`${appSettings.serverPath}/${appSettings.version}/dataMapping/collaborations/${collab_id}`, data)
          .then(response => response.data);
      },

      downloadAttachment(id) {
        return $http
          .get(`${appSettings.serverPath}/${appSettings.version}/attachments/${id}`)
          .then(response => response.data);
      },
      uploadAttachment(data) {
        return $http
          .post(`${appSettings.serverPath}/${appSettings.version}/attachments`, data)
          .then(response => response.data);
      },

      // entityNames should be an array: ['DynamoDB', 'S3 Connection', 'SMB Server'], entityType == 'system' / 'application'
      getAttributes(entityNames, entityType) {
        let queryParams = '?entityType=' + entityType;
        for (let i = 0; i < entityNames.length; i++) {
          queryParams += '&entityNames=' + entityNames[i];
        }

        return $http
          .get(`${appSettings.serverPath}/${appSettings.version}/systems/attributes${queryParams}`)
          .then(response => response.data);
      },

      getAttributesForSystem(systemName) {
        return $http
          .get(`${appSettings.serverPath}/${appSettings.version}/systems/system_attributes/${systemName}`)
          .then(response => response.data);
      },
      getAttributesForApplication(applicationName) {
        return $http
          .get(`${appSettings.serverPath}/${appSettings.version}/systems/application_attributes/${applicationName}`)
          .then(response => response.data);
      },

      duplicateBusinessEntity(id) {
        return $http
          .post(`${appSettings.serverPath}/${appSettings.version}/dataMapping/businessEntities?resourceIdToClone=${id}`)
          .then(response => response.data);
      },

      duplicateBusinessProcess(id) {
        return $http
          .post(
            `${appSettings.serverPath}/${appSettings.version}/dataMapping/businessProcesses?resourceIdToClone=${id}`,
          )
          .then(response => response.data);
      },
    };
  },
]);
