import { useState, useCallback, useEffect } from 'react';
import {
  DataCatalogAsyncOperation,
  DataCatalogAsyncOperationRoutingKey,
  DataCatalogAsyncOperationListenerResponse,
  DataCatalogAsyncOperationListenerParams,
  DataCatalogAsyncOperationStatus,
} from '../../../DataCatalog/DataCatalogAsyncOps/DataCatalogAsyncOpsTypes';
import { subscribeToRepeatedSSEEventById, SSEDataMessage } from '../../../../services/sseService';
import { AsyncOperation } from '../../../../components/AsyncOperationProcessingWidget/AsyncOperationProcessingWidget';

export type CatalogRuleApplySseResult = DataCatalogAsyncOperation<never, any, string>;

export type UseCatalogRuleDeleteParams = DataCatalogAsyncOperationListenerParams;
export type UseCatalogRuleDeleteResponse = DataCatalogAsyncOperationListenerResponse<AsyncOperation>;

export const useCatalogRuleDelete = ({ onOperationRun }: UseCatalogRuleDeleteParams): UseCatalogRuleDeleteResponse => {
  const [operations, setOperations] = useState<AsyncOperation[]>([]);
  const [isDeletionInProcess, setIsDeletionInProcess] = useState<boolean>(false);

  useEffect(() => {
    onOperationRun(DataCatalogAsyncOperationRoutingKey.RULES_BULK_DELETING, isDeletionInProcess);
  }, [onOperationRun, isDeletionInProcess]);

  const handleSseEventReceived = useCallback(({ results = [] }: SSEDataMessage<CatalogRuleApplySseResult>) => {
    setIsDeletionInProcess(!!results.length);

    setOperations(prevOperations => {
      results.forEach(result => {
        const { sseData, percentage, totalObjects, status } = result;
        const isOperationExists = Boolean(prevOperations.find(({ id }) => sseData === id));
        const operationToUpdate: AsyncOperation = {
          id: sseData,
          description: `${totalObjects} objects are updated with ${sseData}`,
          percentage:
            status === DataCatalogAsyncOperationStatus.RUNNING
              ? isNaN(percentage)
                ? 0
                : Math.round(percentage)
              : undefined,
          status,
        };

        if (isOperationExists) {
          prevOperations = prevOperations.map(operation => {
            if (sseData === operation.id) {
              return operationToUpdate;
            } else {
              return operation;
            }
          });
        } else {
          prevOperations.push(operationToUpdate);
        }
      });

      return prevOperations;
    });
  }, []);

  useEffect(() => {
    const unsubscribe = subscribeToRepeatedSSEEventById(
      DataCatalogAsyncOperationRoutingKey.RULES_BULK_DELETING,
      handleSseEventReceived,
    );

    return () => {
      unsubscribe();
    };
  }, [handleSseEventReceived]);

  return { operations };
};
