import React from 'react';
import { BigidAddIcon } from '@bigid-ui/icons';
import { BigidBody2 } from '@bigid-ui/components';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
  container: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: '8px',
    cursor: 'pointer',
    userSelect: 'none',
    WebkitUserSelect: 'none',
  },
});
export interface AddButtonProps {
  label: string;
  onClick: () => void;
  dataAid?: string;
}
export const AddButton: React.FC<AddButtonProps> = ({ label, onClick, dataAid }) => {
  const classes = useStyles();
  return (
    <div
      className={classes.container}
      onClick={onClick}
      data-aid={`${label.replace(' ', '-')}${dataAid ? `-${dataAid}` : ''}`}
    >
      <BigidAddIcon />
      <BigidBody2>{label}</BigidBody2>
    </div>
  );
};
