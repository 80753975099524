import React, { FC, memo } from 'react';
import styled from '@emotion/styled';
import {
  BigidForm,
  BigidFormField,
  BigidFormProps,
  BigidFormRenderProps,
  BigidFormStateAndHandlers,
  BigidFormValidateOnTypes,
  BigidHeading6,
} from '@bigid-ui/components';
import { getInitialValues } from './utils';
import { useLocalTranslation } from './translations';
import { ApplicationGridRow } from './types';
import { getApplicationPreference } from '../../services/appPreferencesService';

interface ApplicationFormProps {
  fields: BigidFormField[];
  formControls: React.MutableRefObject<BigidFormStateAndHandlers>;
  row: ApplicationGridRow;
  setFormIsTouched: (isTouched: boolean) => void;
}

const Content = styled.div`
  display: flex;
  justify-content: center;
  padding: 26px 24px;
  & > form {
    flex: 1;
  }
`;

const Row = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 24px;
  flex-wrap: wrap;
  margin-bottom: 24px;
  max-width: 824px;

  @media (max-width: 1024px) {
    flex-direction: column;
  }

  [data-aid*='target_data_source-bar-chips'] {
    min-height: 38px;
    display: flex;
    align-items: center;

    button {
      bottom: 2px;
    }
  }

  & > fieldset {
    width: calc(50% - 12px);
    margin: 0;

    @media (max-width: 1024px) {
      width: 100%;
    }

    label {
      margin-bottom: 4px;

      span {
        line-height: 1.3;
      }
    }
  }
`;

const headingStyles = { marginBottom: '8px' };

const ApplicationForm: FC<ApplicationFormProps> = ({ fields, formControls, row, setFormIsTouched }) => {
  const { t } = useLocalTranslation();
  const initialValues = getInitialValues(
    fields,
    row,
    getApplicationPreference('APPLICATIONS_DATA_SOURCE_LIST_ENABLED'),
  );

  const formProps: BigidFormProps = {
    controlButtons: false,
    validateOn: BigidFormValidateOnTypes.SUBMIT,
    stateAndHandlersRef: formControls,
    initialValues,
    displayFormLevelError: true,
    fields,
    onChange: () => {
      setTimeout(() => {
        setFormIsTouched(formControls.current?.formTouched);
      }, 0);
    },
    renderForm: ({ renderField, formLevelError, formLevelErrorDisplayed }: BigidFormRenderProps) => (
      <div>
        <Row>{['name', 'app_account_name'].map(name => renderField(name))}</Row>
        <BigidHeading6 sx={headingStyles}>{t('form.dataSourceSection.title')}</BigidHeading6>
        <Row>{['source_ip', 'target_data_source'].map(name => renderField(name))}</Row>
        <BigidHeading6 sx={headingStyles}>{t('form.ownerDetailsSection.title')}</BigidHeading6>
        <Row>{['owner_name', 'owner_phone', 'owner_email'].map(name => renderField(name))}</Row>
        <BigidHeading6 sx={headingStyles}>{t('form.appDetailsSection.title')}</BigidHeading6>
        <Row>{['security_tier', 'location', 'data_mapping_classifier'].map(name => renderField(name))}</Row>
        <div>{formLevelErrorDisplayed && formLevelError}</div>
      </div>
    ),
  };

  return (
    <Content>
      <BigidForm {...formProps} />
    </Content>
  );
};

export const ApplicationFormMemo = memo(ApplicationForm);
