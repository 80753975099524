import React, { FC, useEffect, useState } from 'react';
import { BigidColorsV2, BigidDialog, PrimaryButton, SecondaryButton } from '@bigid-ui/components';
import makeStyles from '@mui/styles/makeStyles';
import { copyToClipboard } from '../../../../../fmsdServices';
import { useAzurePolicyGenerator } from './AzureDialogViews/hooks/useAzurePolicyGenerator';
import { notificationService } from '../../../../../../../services/notificationService';
import { AzureConnection, AzurePolicyGenerator } from './AzureDialogViews';
import { CloudProvider } from '../../../../../../AutoDiscoveryWizard/autoDiscoveryWizardServices';
import { FmsdTrackingEvents, trackFmsdEvent } from '../../../../../fmsdEventsTrackerUtils';
import { useLocalTranslation } from '../../../../../translations';

const useStyles = makeStyles({
  dialogContent: ({ isGeneratorView }: { isGeneratorView: boolean }) => {
    return {
      color: BigidColorsV2.gray[700],
      height: isGeneratorView ? 360 : 640,
    };
  },
});

export interface AzureInfoDialogProps {
  isOpen: boolean;
  onClose: () => void;
}

export const AzureInfoDialog: FC<AzureInfoDialogProps> = ({ isOpen, onClose }) => {
  const { policiesSelection, onPolicySelection, azurePolicyJson, subscriptionId, updateSubscriptionId } =
    useAzurePolicyGenerator();
  const [isGeneratorView, setIsGeneratorView] = useState<boolean>(false);
  const { dialogContent } = useStyles({ isGeneratorView });
  const { t } = useLocalTranslation('Connect.Dialog');
  const onCopyPolicyHandler = () => {
    copyToClipboard(azurePolicyJson);
    onClose();
    notificationService.success(t('azure.onCopySuccessNotification'), { duration: 2000 });
  };

  const onGeneratePolicyClick = () => {
    trackFmsdEvent(FmsdTrackingEvents.FMSD_CONNECT_GENERATE_POLICY_CLICK, {
      [FmsdTrackingEvents.DS_TYPE]: CloudProvider.AZURE,
    });
    setIsGeneratorView(true);
  };

  const onGoBackClick = () => {
    setIsGeneratorView(prevState => !prevState);
  };

  const mainButtons = [
    {
      component: PrimaryButton,
      onClick: onClose,
      text: t('saveButtonText'),
      dataAid: 'FmsdAzureInfoDialog-DialogButton-close',
      bi: {
        eventType: FmsdTrackingEvents.FMSD_CONNECT_SAVE_AND_CLOSE_CLICK,
        eventData: { [FmsdTrackingEvents.DS_TYPE]: CloudProvider.AZURE },
      },
    },
  ];

  const generatorButtons = [
    {
      component: SecondaryButton,
      onClick: onGoBackClick,
      text: t('backButtonText'),
      dataAid: 'FmsdAzureInfoDialog-DialogButton-go-back',
      bi: {
        eventType: FmsdTrackingEvents.FMSD_CONNECT_GO_BACK_TO_CONNECT_VIEW_CLICK,
        eventData: {
          [FmsdTrackingEvents.DS_TYPE]: CloudProvider.AZURE,
        },
      },
    },
    ...(subscriptionId && [
      {
        component: PrimaryButton,
        onClick: onCopyPolicyHandler,
        text: t('copyButtonText'),
        dataAid: 'FmsdAzureInfoDialog-DialogButton-copy-and-close',
        bi: {
          eventType: FmsdTrackingEvents.FMSD_CONNECT_COPY_PERMISSIONS_JSON_CLICK,
          eventData: {
            [FmsdTrackingEvents.DS_TYPE]: CloudProvider.AZURE,
            [FmsdTrackingEvents.SELECTED_DS_TYPES]: policiesSelection
              .filter(policy => policy.isSelected)
              .map(policy => policy.label),
          },
        },
      },
    ]),
  ];

  useEffect(() => {
    isOpen && setIsGeneratorView(false);
  }, [isOpen]);

  return (
    <BigidDialog
      onClose={onClose}
      showCloseIcon={isGeneratorView}
      title={isGeneratorView && t('infoDialogTitle')}
      isOpen={isOpen}
      borderBottom={false}
      borderTop={isGeneratorView}
      buttons={isGeneratorView ? generatorButtons : mainButtons}
      maxWidth="xs"
    >
      <div className={dialogContent}>
        {isGeneratorView ? (
          <AzurePolicyGenerator
            updateSubscriptionId={updateSubscriptionId}
            policiesSelection={policiesSelection}
            onPolicySelection={onPolicySelection}
          />
        ) : (
          <AzureConnection onGeneratePolicyClick={onGeneratePolicyClick} />
        )}
      </div>
    </BigidDialog>
  );
};
