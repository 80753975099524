import React, { FC, useContext } from 'react';
import styled from '@emotion/styled';
import { generateDataAid } from '@bigid-ui/utils';
import { BigidHeading5, BigidBody1, PrimaryButton, BigidTooltip } from '@bigid-ui/components';
import { GettingStartedContext } from '../hooks/gettingStartedContext';
import { GETTING_STARTED_STEPS } from '../gettingStartedItems';
import { useLocalTranslation } from '../translations';

const CardWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-end',
  alignItems: 'flex-start',
  padding: '32px 40px 60px 50px',
  width: '440px',
  height: '368px',
  background: theme.palette.bigid.primary50,
  boxShadow: '0px 0px 5px rgba(0, 0, 0, 0.15)',
  borderRadius: '8px',
  flex: 'none',
  order: 1,
  alignSelf: 'stretch',
  flexGrow: 0,
}));

const DescriptionWrapper = styled(BigidBody1)({
  margin: '16px 0',
});

export const GettingStartedCard: FC = () => {
  const { currentStepId, items } = useContext(GettingStartedContext);
  const { t } = useLocalTranslation('card');
  const {
    title,
    description,
    icon: MainIcon,
    actionLabel,
    actionMethod,
    menuTitle,
  } = GETTING_STARTED_STEPS[currentStepId];
  const { isDisabled } = items.find(({ id }) => currentStepId === id);

  return (
    <CardWrapper data-aid={generateDataAid('GettingStartedCard', [menuTitle])}>
      <MainIcon />
      <BigidHeading5 data-aid={generateDataAid('GettingStartedCard-title', [title])}>{title}</BigidHeading5>
      <DescriptionWrapper data-aid={generateDataAid('GettingStartedCard-description', [description])}>
        {description}
      </DescriptionWrapper>
      <BigidTooltip isDisabled={!isDisabled} title={t('disabledActionTooltip')} width="242px">
        <div>
          <PrimaryButton
            onClick={actionMethod}
            text={actionLabel}
            size="medium"
            data-aid={generateDataAid('GettingStartedCard-action', [title])}
            disabled={isDisabled}
          />
        </div>
      </BigidTooltip>
    </CardWrapper>
  );
};
