import { module } from 'angular';

const app = module('app');

export const DEFAULT_MODEL_NAME = 'default';

export const CLASSIFIER_ALGORITHMS = {
  NER: 'NER',
  DOC: 'DOC',
};

const NER_CLASSIFIERS_ENUM = {
  PHONE: 'phone',
  FULL_NAME: 'fullName',
  GPE: 'gpe',
  ZIP: 'zip',
  STREET: 'street',
  NORP: 'norp',
  OTHERS: 'others',
  CUSTOM_MODELS: 'customModels',
};

const DOC_CLASSIFIERS_ENUM = {
  BOARDING_PASS: 'boardingPass',
  HR_CV: 'hrCv',
  FINANCIAL_INVOICE: 'financialInvoice',
  LEGAL_RFP: 'legalRfp',
  MEDICAL_HEALTH_FORM: 'medicalHealthForm',
  MEDICAL_DISCHARGE_SUMMARY: 'medicalDischargeSummary',
  FINANCIAL_FINANCIAL_STATEMENT: 'financialFinancialStatement',
  FINANCIAL_MERGER_ACQUISITION: 'financialMergerAcquisition',
  LEGAL_RFI: 'legalRfi',
  LEGAL_RENTAL_AGREEMENT: 'legalRentalAgreement',
  IP_PATENT: 'ipPatent',
  LEGAL_INSURANCE_POLICY_APPLICATION_FORM: 'legalInsurancePolicyApplicationForm',
  HR_CRIMINAL_RECORD_CHECK: 'hrCriminalRecordCheck',
  LEGAL_NON_DISCLOSURE_AGREEMENT: 'legalNonDisclosureAgreement',
  HR_EMPLOYMENT_AGREEMENT: 'hrEmploymentAgreement',
  HR_STOCK_OPTION_AGREEMENT: 'hrStockOptionAgreement',
  FINANCIAL_CREDIT_CARD_APPLICATION_FORM: 'FinancialCreditCardApplicationForm',
  FINANCIAL_INCOME_TAX_RETURN: 'FinancialIncomeTaxReturn',
  EDUCATION_ACADEMIC_TRANSCRIPT: 'educationAcademicTranscript',
};

const PHONE_TEXT = 'Phone';
const GPE_TEXT = 'Country/City';
const FULL_NAME_TEXT = 'Full Name';
const ZIP_TEXT = 'Zip Code';
const STREET_TEXT = 'Street';
const NORP_TEXT = 'Nationality or Religion or Political Group';
const OTHERS_NAME_TEXT = 'Others';
const CUSTOM_MODELS_NAME_TEXT = 'Custom Models';

const BOARDING_PASS = 'Boarding Pass Document';
const HR_CV = 'CV Document';
const FINANCIAL_INVOICE = 'Invoice Document';
const LEGAL_RFP = 'Request for Proposal Document';
const MEDICAL_HEALTH_FORM = 'Health Form Document';
const MEDICAL_DISCHARGE_SUMMARY = 'Discharge Summary Document';
const FINANCIAL_FINANCIAL_STATEMENT = 'Financial Statement Document';
const FINANCIAL_MERGER_ACQUISITION = 'Merger & Acquisition Document';
const LEGAL_RFI = 'Request for Information Document';
const LEGAL_INSURANCE_POLICY_APPLICATION_FORM = 'Insurance Policy Application Form Document';
const LEGAL_RENTAL_AGREEMENT = 'Rental Agreement Document';
const IP_PATENT = 'Patent Document';
const HR_CRIMINAL_RECORD_CHECK = 'Criminal Record Check Document';
const LEGAL_NON_DISCLOSURE_AGREEMENT = 'Non Disclosure Agreement Document';
const HR_EMPLOYMENT_AGREEMENT = 'Employment Agreement Document';
const HR_STOCK_OPTION_AGREEMENT = 'Stock Option Agreement Document';
const FINANCIAL_CREDIT_CARD_APPLICATION_FORM = 'Financial Credit Card Application Form Document';
const FINANCIAL_INCOME_TAX_RETURN = 'Financial Income Tax Return Document';
const EDUCATION_ACADEMIC_TRANSCRIPT = 'Education Academic Transcript Document';

export const getClassifierNameById = classifierId => {
  switch (classifierId) {
    case NER_CLASSIFIERS_ENUM.PHONE:
      return PHONE_TEXT;
    case NER_CLASSIFIERS_ENUM.GPE:
      return GPE_TEXT;
    case NER_CLASSIFIERS_ENUM.FULL_NAME:
      return FULL_NAME_TEXT;
    case NER_CLASSIFIERS_ENUM.ZIP:
      return ZIP_TEXT;
    case NER_CLASSIFIERS_ENUM.STREET:
      return STREET_TEXT;
    case NER_CLASSIFIERS_ENUM.NORP:
      return NORP_TEXT;
    case NER_CLASSIFIERS_ENUM.OTHERS:
      return OTHERS_NAME_TEXT;
    case NER_CLASSIFIERS_ENUM.CUSTOM_MODELS:
      return CUSTOM_MODELS_NAME_TEXT;
    case DOC_CLASSIFIERS_ENUM.BOARDING_PASS:
      return BOARDING_PASS;
    case DOC_CLASSIFIERS_ENUM.HR_CV:
      return HR_CV;
    case DOC_CLASSIFIERS_ENUM.FINANCIAL_INVOICE:
      return FINANCIAL_INVOICE;
    case DOC_CLASSIFIERS_ENUM.LEGAL_RFP:
      return LEGAL_RFP;
    case DOC_CLASSIFIERS_ENUM.MEDICAL_HEALTH_FORM:
      return MEDICAL_HEALTH_FORM;
    case DOC_CLASSIFIERS_ENUM.MEDICAL_DISCHARGE_SUMMARY:
      return MEDICAL_DISCHARGE_SUMMARY;
    case DOC_CLASSIFIERS_ENUM.FINANCIAL_FINANCIAL_STATEMENT:
      return FINANCIAL_FINANCIAL_STATEMENT;
    case DOC_CLASSIFIERS_ENUM.FINANCIAL_MERGER_ACQUISITION:
      return FINANCIAL_MERGER_ACQUISITION;
    case DOC_CLASSIFIERS_ENUM.LEGAL_RFI:
      return LEGAL_RFI;
    case DOC_CLASSIFIERS_ENUM.LEGAL_INSURANCE_POLICY_APPLICATION_FORM:
      return LEGAL_INSURANCE_POLICY_APPLICATION_FORM;
    case DOC_CLASSIFIERS_ENUM.LEGAL_RENTAL_AGREEMENT:
      return LEGAL_RENTAL_AGREEMENT;
    case DOC_CLASSIFIERS_ENUM.IP_PATENT:
      return IP_PATENT;
    case DOC_CLASSIFIERS_ENUM.HR_CRIMINAL_RECORD_CHECK:
      return HR_CRIMINAL_RECORD_CHECK;
    case DOC_CLASSIFIERS_ENUM.LEGAL_NON_DISCLOSURE_AGREEMENT:
      return LEGAL_NON_DISCLOSURE_AGREEMENT;
    case DOC_CLASSIFIERS_ENUM.HR_EMPLOYMENT_AGREEMENT:
      return HR_EMPLOYMENT_AGREEMENT;
    case DOC_CLASSIFIERS_ENUM.HR_STOCK_OPTION_AGREEMENT:
      return HR_STOCK_OPTION_AGREEMENT;
    case DOC_CLASSIFIERS_ENUM.FINANCIAL_CREDIT_CARD_APPLICATION_FORM:
      return FINANCIAL_CREDIT_CARD_APPLICATION_FORM;
    case DOC_CLASSIFIERS_ENUM.FINANCIAL_INCOME_TAX_RETURN:
      return FINANCIAL_INCOME_TAX_RETURN;
    case DOC_CLASSIFIERS_ENUM.EDUCATION_ACADEMIC_TRANSCRIPT:
      return EDUCATION_ACADEMIC_TRANSCRIPT;

    default:
      return classifierId;
  }
};

app.factory('classifiersService', ($http, appSettings) => {
  'ngInject';
  return {
    CLASSIFIER_ALGORITHMS,
    NER_CLASSIFIERS_ENUM,
    DOC_CLASSIFIERS_ENUM,
    getClassifierNameById,

    getAllCurationAttributes: () =>
      $http
        .get(`${appSettings.serverPath}/${appSettings.version}/data-catalog/results-tuning/attributes`)
        .then(response => response),

    getAllCurationStructuredAttributes: () =>
      $http
        .get(
          `${appSettings.serverPath}/${appSettings.version}/data-catalog/results-tuning/attributes?isStructuredOnly=true`,
        )
        .then(response => response),

    getClassifiers: () =>
      $http.get(`${appSettings.serverPath}/${appSettings.version}/classifications`).then(response => response),

    getClassifier: id =>
      $http
        .get(`${appSettings.serverPath}/${appSettings.version}/classifications/${encodeURIComponent(id)}`)
        .then(response => response),

    createClassifier: data =>
      $http.post(`${appSettings.serverPath}/${appSettings.version}/classifications`, data).then(response => response),

    updateClassifier: (id, data) =>
      $http
        .put(`${appSettings.serverPath}/${appSettings.version}/classifications/${encodeURIComponent(id)}`, data)
        .then(response => response),

    deleteClassifier: id =>
      $http
        .delete(`${appSettings.serverPath}/${appSettings.version}/classifications/${encodeURIComponent(id)}`)
        .then(response => response),

    getChecksumValidations: () =>
      $http.get(`${appSettings.serverPath}/${appSettings.version}/classifications/supported_checksums`),

    getAdvancedClassifiers: () =>
      $http
        .get(`${appSettings.serverPath}/${appSettings.version}/classifier-algorithms?base_ner`)
        .then(response => response),

    getAdvancedClassifier: id =>
      $http
        .get(`${appSettings.serverPath}/${appSettings.version}/classifier-algorithms/${encodeURIComponent(id)}`)
        .then(response => response),

    updateAdvancedClassifier: (type, data) =>
      $http
        .put(`${appSettings.serverPath}/${appSettings.version}/classifier-algorithms/${type}`, data)
        .then(response => response),

    createCustomNerClassifier: data =>
      $http
        .post(`${appSettings.serverPath}/${appSettings.version}/classifier-algorithms/ner`, data)
        .then(response => response),

    deleteCustomNerClassifier: id =>
      $http
        .delete(`${appSettings.serverPath}/${appSettings.version}/classifier-algorithms/ner/${encodeURIComponent(id)}`)
        .then(response => response),

    classifyMetadata: data =>
      $http.post(`${appSettings.serverPath}/${appSettings.version}/scans`, data).then(response => response),

    getMdScanResultSize: id =>
      $http.get(`${appSettings.serverPath}/${appSettings.version}/metadata-size`).then(response => response),

    getClassifierExcludeListByName: (classificationName, type) =>
      $http.get(
        `${appSettings.serverPath}/${appSettings.version}/classifications/exclude-list/${encodeURIComponent(
          classificationName,
        )}?type=${type}`,
      ),
    upsertExcludeList: payload =>
      $http.post(`${appSettings.serverPath}/${appSettings.version}/classifications/exclude-list`, payload),
    removeExcludeValueById: excludeValueId =>
      $http.delete(`${appSettings.serverPath}/${appSettings.version}/classifications/exclude-list/${excludeValueId}`),
    deleteManyExcludeValues: payload =>
      $http.post(`${appSettings.serverPath}/${appSettings.version}/classifications/exclude-list/delete-many`, payload),
    getClassifierChecksumValidationByName: checksumName =>
      $http.get(`${appSettings.serverPath}/${appSettings.version}/checksum-validation/${checksumName}`),
  };
});
