import { DataSourceTypes, DialogSections } from '../DataSourceConfiguration/types';

export const DEFAULT_DIALOG_SECTIONS = [DialogSections.GENERAL, DialogSections.ADVANCED];

// @info Data sources excluded from the new connection flow
export const NEW_CONNECTION_EXCLUDED_DATA_SOURCES: string[] = [
  DataSourceTypes.AZURE_BLOB,
  DataSourceTypes.AZURE_FILE,
  DataSourceTypes.ASTRA_DB,
  DataSourceTypes.CONFLUENCE,
  DataSourceTypes.CUSTOM_CONNECTOR,
  DataSourceTypes.DYNAMO_DB,
  DataSourceTypes.EXCHANGE,
  DataSourceTypes.FILENET,
  DataSourceTypes.GENERIC_API,
  DataSourceTypes.GMAIL,
  DataSourceTypes.GOOGLE_CLOUD_STORAGE,
  DataSourceTypes.GOOGLE_BIG_TABLE_LEGACY,
  DataSourceTypes.GOOGLE_DRIVE_LEGACY,
  DataSourceTypes.HADOOP_V2,
  DataSourceTypes.HBASE,
  DataSourceTypes.HIVE,
  DataSourceTypes.OUTLOOK,
  DataSourceTypes.MARKLOGIC,
  DataSourceTypes.NFS,
  DataSourceTypes.O365_LEGACY,
  DataSourceTypes.ONEDRIVE_LEGACY,
  DataSourceTypes.SAP_LEGACY,
  DataSourceTypes.S3_LEGACY,
  DataSourceTypes.S3_STRUCTURED,
  DataSourceTypes.SHAREFILE_LEGACY,
  DataSourceTypes.SHAREPOINT,
  DataSourceTypes.SHAREPOINT_ONLINE_LEGACY,
  DataSourceTypes.SPANNER_LEGACY,
  DataSourceTypes.SMB_LEGACY,
  DataSourceTypes.WORKDAY,
  DataSourceTypes.ZENDESK,
];

export const DATA_SOURCE_CONNECTIONS_QUERY_KEY = 'ds-connections';
