import template from './column.component.html';
import { module } from 'angular';
const app = module('app');

app.component('resultColumn', {
  template,
  controllerAs: 'columnModel',
  controller: [
    '$rootScope',
    '$scope',
    function ($rootScope, $scope) {
      var columnModel = this;

      columnModel.$onChanges = function () {
        $scope.data = columnModel.data.data;
        $scope.meta = columnModel.data.meta;
      };
    },
  ],
  bindings: {
    data: '=',
  },
});
