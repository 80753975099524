export interface ErrorPayload {
  id?: string;
  name?: string;
  emptyQuery?: string;
  notValidQuery?: string;
  containsScQuery?: string;
}
export enum ErrorField {
  NAME,
  DESCRIPTION,
  CLASSIFICATION,
}

export interface ErrorAction {
  type: ErrorField;
  payload: ErrorPayload;
}

export interface SensitivityClassificationFormErrors {
  name?: ErrorPayload;
  description?: ErrorPayload;
  classifications: { [key: string]: ErrorPayload };
}

export const formErrorsReducer: React.Reducer<SensitivityClassificationFormErrors, ErrorAction> = (
  errors,
  action,
): SensitivityClassificationFormErrors => {
  const { type, payload } = action;
  switch (type) {
    case ErrorField.NAME:
      return {
        ...errors,
        name: payload,
      };
    case ErrorField.DESCRIPTION:
      return {
        ...errors,
        description: payload,
      };
    case ErrorField.CLASSIFICATION:
      const { id, ...restOfPayload } = payload;
      return {
        ...errors,
        classifications: {
          ...errors.classifications,
          [id]: { ...errors.classifications[payload.id], ...restOfPayload },
        },
      };
    default:
      return errors;
  }
};
