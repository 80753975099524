import { module } from 'angular';
const app = module('app');
import template from './userDetails.component.html';
import { HeaderEvents, headerEventEmitter } from '../../react/services/eventEmitters/headerEvents';
import { isPermitted } from '../../react/services/userPermissionsService';
import { DSAR_PERMISSIONS } from '@bigid/permissions';

app.component('userDetails', {
  template,
  controllerAs: 'userDetailsModel',
  controller: function (
    userDetailsService,
    $scope,
    notificationService,
    $rootScope,
    DeleteConfirmation,
    $uibModal,
    $state,
  ) {
    'ngInject';
    const userDetailsModel = this;

    userDetailsModel.hasSubmitSarRequestPermission = isPermitted(DSAR_PERMISSIONS.SUBMIT_REQUEST.name);

    userDetailsModel.$onChanges = function () {
      userDetailsModel.loading = true;
      userDetailsModel.jitAvailable = false;
      userDetailsService.getUserDetailsData(userDetailsModel.id).then(function (result) {
        userDetailsModel.loading = false;
        if (!angular.isDefined(result.user) || !angular.isDefined(result.user.name)) {
          notificationService.error('User not found!');
        } else {
          userDetailsModel.location = result.user.residency;
          userDetailsModel.attributes_data = result.user.all_attributes;
          userDetailsModel.systems_data = result.user.all_systems;
          userDetailsModel.risk = Math.round(result.user.risk);
          userDetailsModel.userName = result.user.name;
          userDetailsModel.jitAvailable = result.user.isJitAvailable;
        }
      });

      userDetailsModel.download = this.download;
      if (userDetailsModel.download != null && userDetailsModel.download == 'download') {
        userDetailsService.getJITCsvFile(userDetailsModel.id);
      }
    };

    $scope.launchJITScan = function () {
      const modalOptions = {
        closeButtonText: 'Cancel',
        actionButtonText: 'Confirm',
        headerText: 'Generate New Full Data Subject Report',
        bodyText: 'Are you sure you want to generate a new report ? Previous Data Subject records will be deleted',
      };

      DeleteConfirmation.showModal({}, modalOptions).then(function (result) {
        userDetailsService.launchJITScan(userDetailsModel.id).then(function (results) {
          if (results.result.jitFullScanId) {
            notificationService.success(
              'JIT Full Scan Id: ' + results.result.jitFullScanId,
              'JIT Scan launched successfully',
            );
          } else {
            notificationService.error('JIT Scan did not launched!');
          }
          headerEventEmitter.emit(HeaderEvents.UPDATE_UNREAD_TASKS_AND_RISK);
        });
      });
    };

    const cancelAssign = () => {
      userDetailsModel.assignModalInstance.close();
      userDetailsModel.assignModalInstance = {};
    };

    userDetailsModel.newSARRequest = () => {
      $state.go('subjectAccessRequest', { userId: userDetailsModel.id });
    };

    const launchDeleteUserTask = function () {
      userDetailsService
        .launchDeleteUserTask({
          userId: userDetailsModel.id,
          userName: userDetailsModel.userName,
          assignee: userDetailsModel.assignInput,
        })
        .then(function (results) {
          if (results.result.jitFullScanId) {
            cancelAssign();
            notificationService.success(
              'JIT Full Scan Id: ' + results.result.jitFullScanId,
              'Delete user task launched successfully',
            );
          } else {
            notificationService.error('Delete user task did not launched!');
          }
          headerEventEmitter.emit(HeaderEvents.UPDATE_UNREAD_TASKS_AND_RISK);
        });
    };

    userDetailsModel.assignTask = user => {
      launchDeleteUserTask();
    };

    userDetailsModel.showAssignModal = () => {
      userDetailsModel.assignModalInstance = $uibModal.open({
        templateUrl: 'assignModal.template.html',
        backdrop: 'static',
        animation: true,
        windowClass: 'reassign-task-modal',
        scope: $scope,
      });
      setTimeout(function () {
        document.getElementById('Input').focus();
      }, 500);
    };

    userDetailsModel.onAssigneeTextareaChanged = (value, mentions) => {
      userDetailsModel.assignInput = mentions[0];
    };

    userDetailsModel.cancelAssign = () => {
      cancelAssign();
    };
  },

  bindings: {
    id: '<',
    download: '<',
  },
});
