export enum ObjectPreviewEntityPossesionModified {
  YES = 'Yes',
  NO = 'No',
}

export enum ObjectPreviewEntityType {
  TABLE = 'rdb',
  TABLE_VIEW = 'rdb-view',
  FILE = 'file',
  STRUCTURED_LEAF = 'STRUCTURED-LEAF_DATA_OBJECT',
  STRUCTURED_FILE_LEAF = 'STRUCTURED_FILE-LEAF_DATA_OBJECT',
  UNSTRUCTURED_LEAF = 'UNSTRUCTURED-LEAF_DATA_OBJECT',
  PARTITIONED_TABLE_LEAF = 'PARTITIONED_TABLE-LEAF_DATA_OBJECT',
  APP_LEAF = 'APP-LEAF_DATA_OBJECT',
}

export interface ObjectPreviewEntity {
  id: string | number;
  type: string;
  owner?: string;
  objectName: string;
  containerName?: string;
  objectType?: ObjectPreviewEntityType;
  fullObjectName: string;
  open_access: ObjectPreviewEntityPossesionModified;
  has_duplicates: ObjectPreviewEntityPossesionModified;
}
