import React, { FC } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { BigidBody1, BigidColorsV2, BigidChip, BigidAdditionalItemsChip } from '@bigid-ui/components';
import { BigidApplyIcon } from '@bigid-ui/icons';
import { WorkflowResponse } from '../../actionWorkflowTypes';
import { ActionCenterMetadataContainer } from '../../../useActionCenterMetadata';

const TOTAL_CHIPS_TO_DISPLAY = 3;
const MORE_CHIP_TOOLTIP_PREFIX = 'Click on edit to see all the';

interface ActionWorkflowCollapseContentProps extends Pick<WorkflowResponse, 'receivers' | 'actions' | 'entityIds'> {
  entityName: string;
}

const useStyles = makeStyles({
  root: { padding: '17px 24px 9px 24px' },
  wrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    '&:not(:last-child)': {
      marginBottom: 30,
    },
    '&:last-child': {
      marginBottom: 14,
    },
  },
  actionWrapper: { display: 'flex', alignItems: 'center' },
  chip: {
    '&:not(:last-child)': {
      marginRight: 8,
    },
  },
  actionText: { marginRight: 10 },
  dataWrapper: { display: 'flex', alignItems: 'center' },
  blackChip: {
    width: 32,
    height: 18,
    background: BigidColorsV2.gray[700],
    borderRadius: 3,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  blackChipText: { color: BigidColorsV2.white },
});

const useHeadingStyles = makeStyles({
  wrapper: { display: 'flex', flexDirection: 'column' },
  description: { marginTop: 4 },
});

interface CollapseContentHeadingProps {
  title: string;
  description?: string;
}

interface CollapseContentChipItemProps {
  title: string;
}

const CollapseContentItem: FC<CollapseContentHeadingProps> = ({ title, description }) => {
  const classes = useHeadingStyles({});
  return (
    <div className={classes.wrapper}>
      <BigidBody1>{title}</BigidBody1>
      {description && <BigidBody1 className={classes.description}>{description}</BigidBody1>}
    </div>
  );
};

const CollapseContentChipItem: FC<CollapseContentChipItemProps> = ({ title }) => {
  const classes = useStyles({});
  return (
    <div className={classes.chip}>
      <BigidChip
        label={title}
        bgColor={BigidColorsV2.purple[200]}
        color={BigidColorsV2.gray[700]}
        size="small"
        dataAid={`BigidChip-${title}`}
      />
    </div>
  );
};

export const ActionWorkflowCollapseContent: FC<ActionWorkflowCollapseContentProps> = ({
  receivers = [],
  actions = [],
  entityIds = [],
  entityName,
}) => {
  const { actionCenterMetadata } = ActionCenterMetadataContainer.useContainer();
  const classes = useStyles({});

  return (
    <div className={classes.root}>
      <div className={classes.wrapper}>
        <CollapseContentItem title={`Affected ${entityName}`} />
        <div className={classes.dataWrapper}>
          {entityIds.slice(0, TOTAL_CHIPS_TO_DISPLAY).map(({ label, value }) => (
            <CollapseContentChipItem key={value} title={label} />
          ))}
          {entityIds.length > TOTAL_CHIPS_TO_DISPLAY && (
            <BigidAdditionalItemsChip
              total={entityIds.length - TOTAL_CHIPS_TO_DISPLAY}
              tooltipText={`${MORE_CHIP_TOOLTIP_PREFIX} ${entityName.toLowerCase()}`}
            />
          )}
        </div>
      </div>
      <div className={classes.wrapper}>
        <CollapseContentItem title="Selected Owners" />
        <div className={classes.dataWrapper}>
          {receivers.slice(0, TOTAL_CHIPS_TO_DISPLAY).map(({ username, displayName }) => (
            <CollapseContentChipItem key={username} title={displayName} />
          ))}
          {receivers.length > TOTAL_CHIPS_TO_DISPLAY && (
            <BigidAdditionalItemsChip
              total={receivers.length - TOTAL_CHIPS_TO_DISPLAY}
              tooltipText={`${MORE_CHIP_TOOLTIP_PREFIX} owners`}
            />
          )}
        </div>
      </div>
      <div className={classes.wrapper}>
        <CollapseContentItem title="Selected Actions" />
        <div className={classes.dataWrapper}>
          {actions
            .filter(({ configuration: { enabled } }) => enabled)
            .map(({ type }) => (
              <div key={type} className={classes.actionWrapper} data-aid={`BigidChip-${type}`}>
                <BigidApplyIcon color="positive" />
                <BigidBody1 className={classes.actionText}>
                  {actionCenterMetadata.actions.find(({ name }) => name === type).displayName}
                </BigidBody1>
              </div>
            ))}
        </div>
      </div>
    </div>
  );
};
