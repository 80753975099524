import React, { FC, useState, Fragment, ChangeEvent } from 'react';
import {
  BigidBody2,
  BigidColorsV2,
  BigidHeading5,
  BigidHeading6,
  BigidSwitch,
  PrimaryButton,
  ValidationResult,
  BigidCaption,
  BigidNotes1,
} from '@bigid-ui/components';
import { BigidWarningIcon } from '@bigid-ui/icons';
import {
  ACActionType,
  Action,
  ActionConfigurationTypes,
  ACEventType,
  ApiCallActionConfigurationForm,
  OpenServiceTicketActionConfiguration,
  RunTpaActionConfiguration,
} from '../../../actionWorkflowTypes';
import {
  isConfigurationEmpty,
  getConfigurationByActionType,
  getAutocompleteListItemsFromSelectedEvent,
} from '../../../actionWorkflowUtils';
import { Divider } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { ApiCallDialog } from './ApiCall/ApiCallDialog';
import { ActionCenterMetadataContainer } from '../../../../useActionCenterMetadata';
import { ConfigurationDialog, CONFIGURATIONS_WARNING } from '../../../../ConfigurationManagement/ConfigurationDialog';
import { OpenServiceTicketDialog } from './OpenServiceTicket/OpenServiceTicketDialog';
import { RunTpaActionDialog } from './RunTpaAction/RunTpaActionDialog';

interface ActionFormProps {
  actions: Action[];
  setActions: (value: Action[]) => void;
  error?: ValidationResult;
  selectedEvent: ACEventType;
  shouldAddConfigurations: boolean;
}

const useStyles = makeStyles({
  root: { width: '50%', padding: '24px 64px', overflow: 'scroll' },
  title: { marginBottom: 7 },
  error: { color: BigidColorsV2.red[600], marginBottom: 7 },
  wrapper: { display: 'flex', flexDirection: 'column', gap: '7px' },
  actionWrapper: { display: 'flex', alignItems: 'center', marginBottom: 8 },
  actionHeadingWrapper: { display: 'flex', flexDirection: 'column', marginTop: 9, flexGrow: 1 },
  settingsButtonWrapper: { marginTop: 9 },
  headingDescription: { color: BigidColorsV2.gray[600] },
  disabledHeading: { color: BigidColorsV2.gray[500] },
  switchWrapper: { margin: '-8px 0 0 -8px' },
  warning: { display: 'flex' },
  warningMessage: { marginLeft: 14, color: BigidColorsV2.gray[600] },
});

const AUTOCOMPLETE_ERROR = 'You must fill out the general settings fields in order to configure actions';

export const ActionForm: FC<ActionFormProps> = ({
  actions,
  setActions,
  error,
  selectedEvent,
  shouldAddConfigurations,
}) => {
  const { actionCenterMetadata } = ActionCenterMetadataContainer.useContainer();
  const classes = useStyles({});
  const [openConfigurationDialog, setOpenConfigurationDialog] = useState('');
  const [shouldDisplayTicketForm, setShouldDisplayTicketForm] = useState(!shouldAddConfigurations);

  const actionConfigurationsByType = getConfigurationByActionType(actions, actionCenterMetadata);

  const onChangeActionConfiguration =
    (actionType: ACActionType, initialConfiguration: ActionConfigurationTypes) =>
    (_e: ChangeEvent<HTMLInputElement>, checked: boolean) => {
      const actionIndex = actions.findIndex(({ type }) => type === actionType);
      if (actionIndex > -1) {
        const newActions = [...actions];
        newActions[actionIndex].configuration.enabled = checked;
        if (isConfigurationEmpty(actions[actionIndex])) {
          newActions.splice(actionIndex, 1);
        }
        return setActions(newActions);
      }
      const actionToSave = { type: actionType, configuration: { ...initialConfiguration, enabled: checked } };
      return setActions([...actions, actionToSave]);
    };

  const saveActionConfiguration = (actionToSave: Action) => {
    const actionIndex = actions.findIndex(({ type }) => type === actionToSave.type);
    if (actionIndex > -1) {
      const newActions = [...actions];
      newActions[actionIndex] = actionToSave;
      return setActions(newActions);
    }
    return setActions([...actions, actionToSave]);
  };

  const hasAutocompleteError = !selectedEvent;
  const handleCloseActionDialog = () => setOpenConfigurationDialog('');
  const autocompleteListItems =
    selectedEvent && getAutocompleteListItemsFromSelectedEvent(selectedEvent, actionCenterMetadata);

  return (
    <>
      <div className={classes.root}>
        <BigidHeading5 className={classes.title}>Actions</BigidHeading5>
        {error && (
          <BigidCaption className={classes.error} data-aid={'BigidTextFieldError-actions'}>
            {error}
          </BigidCaption>
        )}
        {hasAutocompleteError && (
          <BigidCaption className={classes.error} data-aid={`BigidTextFieldError-${name}`}>
            {AUTOCOMPLETE_ERROR}
          </BigidCaption>
        )}
        <div className={classes.wrapper}>
          {actionCenterMetadata.actions.map(({ name, isDisabled, displayName, description }, index) => {
            const isLast = index === actionCenterMetadata.actions.length - 1;
            const checked = actionConfigurationsByType[name]?.enabled || false;
            const shouldShowConfigurationWarning =
              name === ACActionType.OPEN_SERVICE_TICKET && !shouldDisplayTicketForm;

            return (
              <Fragment key={name}>
                <div className={classes.actionWrapper}>
                  <div className={classes.switchWrapper}>
                    <BigidSwitch
                      dataAid={`BigidSwitch-${name}`}
                      onChange={onChangeActionConfiguration(name, actionConfigurationsByType[name])}
                      disabled={isDisabled}
                      checked={checked}
                    />
                  </div>
                  <div className={classes.actionHeadingWrapper}>
                    <BigidHeading6 className={isDisabled ? classes.disabledHeading : undefined}>
                      {displayName}
                    </BigidHeading6>
                    <BigidBody2 className={isDisabled ? classes.disabledHeading : classes.headingDescription}>
                      {description}
                    </BigidBody2>
                  </div>
                  <div className={classes.settingsButtonWrapper}>
                    <PrimaryButton
                      onClick={() => setOpenConfigurationDialog(name)}
                      size="medium"
                      dataAid={`SettingsButton-${name}`}
                      disabled={isDisabled || hasAutocompleteError}
                      text="Settings"
                    />
                  </div>
                </div>
                {shouldShowConfigurationWarning && (
                  <div className={classes.warning}>
                    <span>
                      <BigidWarningIcon />
                    </span>
                    <BigidNotes1 className={classes.warningMessage}>{CONFIGURATIONS_WARNING}</BigidNotes1>
                  </div>
                )}
                {!isLast && <Divider />}
              </Fragment>
            );
          })}
        </div>
      </div>
      {selectedEvent && (
        <>
          <ApiCallDialog
            isOpen={openConfigurationDialog === ACActionType.API_CALL}
            onClose={handleCloseActionDialog}
            value={actionConfigurationsByType[ACActionType.API_CALL] as ApiCallActionConfigurationForm}
            saveActionConfiguration={saveActionConfiguration}
            autocompleteListItems={autocompleteListItems}
          />
          <ConfigurationDialog
            isOpen={openConfigurationDialog === ACActionType.OPEN_SERVICE_TICKET && !shouldDisplayTicketForm}
            onSubmit={() => setShouldDisplayTicketForm(true)}
            handleClose={handleCloseActionDialog}
            withWarning
          />
          <OpenServiceTicketDialog
            isOpen={openConfigurationDialog === ACActionType.OPEN_SERVICE_TICKET && shouldDisplayTicketForm}
            onClose={handleCloseActionDialog}
            autocompleteListItems={autocompleteListItems}
            saveActionConfiguration={saveActionConfiguration}
            initialValue={
              actionConfigurationsByType[ACActionType.OPEN_SERVICE_TICKET] as OpenServiceTicketActionConfiguration
            }
          />
          <RunTpaActionDialog
            isOpen={openConfigurationDialog === ACActionType.RUN_TPA_ACTION}
            onClose={handleCloseActionDialog}
            saveActionConfiguration={saveActionConfiguration}
            initialValue={actionConfigurationsByType[ACActionType.RUN_TPA_ACTION] as RunTpaActionConfiguration}
          />
        </>
      )}
    </>
  );
};
