import { GETTING_STARTED_STEPS, GettingStartedStepsIds } from '../gettingStartedItems';
import { notificationService } from '../../../services/notificationService';

export interface GettingStartedStepState {
  id: GettingStartedStepsIds;
  isComplete?: boolean;
  orderNumber: number;
  isDisabled: boolean;
}

export const initGetStartedItems = async () => {
  const itemsCompletePromise = Object.values(GETTING_STARTED_STEPS).map(
    async ({ checkIsComplete, id, checkIsEnabled }, index) => {
      const isDisabled = !checkIsEnabled();
      const isComplete = !isDisabled && (await checkIsComplete());

      return {
        id,
        isComplete,
        isDisabled,
        orderNumber: index + 1,
      };
    },
  );
  return Promise.all(itemsCompletePromise);
};

export const initState = async () => {
  try {
    const items = await initGetStartedItems();
    const currentStepId = items.find(({ isComplete }) => !isComplete)?.id || items[0].id;
    const isProgressDisabled = items.some(({ isDisabled }) => !!isDisabled);
    return {
      items,
      currentStepId,
      isProgressDisabled,
    };
  } catch (err) {
    notificationService.error('Failed to fetch Getting Started data');
    console.error(err);
  }
};
