import { BigidBody1, BigidTooltip } from '@bigid-ui/components';
import { styled } from '@mui/material';
import React, { FC } from 'react';
import { getHighlightedValue } from '../../utils';
import { useTheme } from '@mui/styles';

const Container = styled('div')`
  display: flex;
  overflow-y: hidden;
  text-overflow: ellipsis;
  padding-right: 20px;
  cursor: pointer;
  flex-direction: column;
`;

const elipsisMixin = `
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding: 2px 0;
  max-width: 850px;
  display: flex;
`;

const StyledTitle = styled(BigidBody1)`
  ${elipsisMixin}
`;

const TooltipContainer = styled('div')`
  display: flex;
  flex-wrap: wrap;
  overflow-wrap: anywhere;
`;

export type TitleProps = {
  title: string;
  subtitle?: string;
};

export const Header: FC<TitleProps> = ({ subtitle = '', title = '' }) => {
  const titleWithHighlight = getHighlightedValue(title);
  const subtitleWithHighlight = getHighlightedValue(subtitle);
  const {
    vars: { palette },
  } = useTheme();
  return (
    <Container>
      <BigidTooltip title={<TooltipContainer>{titleWithHighlight}</TooltipContainer>}>
        <StyledTitle data-aid="CardTitle" fontWeight={800}>
          {titleWithHighlight}
        </StyledTitle>
      </BigidTooltip>
      {subtitleWithHighlight && (
        <BigidTooltip title={<TooltipContainer>{subtitleWithHighlight}</TooltipContainer>}>
          <StyledTitle data-aid="CardSubtitle" color={palette.bigid.gray500}>
            {subtitleWithHighlight}
          </StyledTitle>
        </BigidTooltip>
      )}
    </Container>
  );
};
