import React, { FC, useMemo } from 'react';
import { Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import {
  BigidColors,
  BigidSearch,
  useStringFilter,
  normalizeSearchString,
  DashboardCommonContainer,
  BigidBypassButton,
} from '@bigid-ui/components';
import {
  DashboardShortcutsBlock,
  DashboardShortcutsBlockProps,
} from './DashboardShortcutsBlock/DashboardShortcutsBlock';

const useStyles = makeStyles({
  wrapper: {
    flexDirection: 'column',
    padding: '16px',
    position: 'relative',
    width: '100%',
    height: '100%',
    margin: 0,
  },
  shortcutsHeader: {
    display: 'flex',
    boxSizing: 'border-box',
    width: '100%',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '0px 10px 8px 0',
    backgroundColor: BigidColors.white,
    marginBottom: '8px',
  },
  shortcutsTitle: {
    fontSize: '16px',
    fontWeight: 600,
    color: BigidColors.gray[700],
    marginRight: '24px',
  },
  shortcutsList: {
    display: 'flex',
    boxSizing: 'border-box',
    flexDirection: 'column',
    overflow: 'scroll',
    '&::-webkit-scrollbar': {
      width: '5px',
    },
    '&::-webkit-scrollbar-track': {
      width: '5px',
    },
    '&::-webkit-scrollbar-thumb': {
      background: BigidColors.gray[100],
      borderRadius: '3px',
    },
  },
  searchWrapper: {
    width: '256px',
  },
});

const isMatched = (filterParts: string[], { title }: Partial<DashboardShortcutsBlockProps>) =>
  filterParts.some((token: string) => normalizeSearchString(title).startsWith(token));

const getMatchedShortcuts = (data: DashboardShortcutsBlockProps[], filterParts: string[]) => {
  const addMatchedShortcutsToResult = (
    resultArray: DashboardShortcutsBlockProps[],
    valueObject: DashboardShortcutsBlockProps,
  ) => {
    const matched = isMatched(filterParts, valueObject);
    const children = matched
      ? valueObject?.children
      : valueObject?.children?.map(valueObjectChildren => ({
          ...valueObjectChildren,
          hidden: !isMatched(filterParts, valueObjectChildren),
        }));
    const showByChildren = children?.some(({ hidden }) => !hidden);
    return [...resultArray, ...(matched || showByChildren ? [{ ...valueObject, children }] : [])];
  };

  return data.reduce(addMatchedShortcutsToResult, []);
};

function useFilterObjectArrayByString(data: DashboardShortcutsBlockProps[]) {
  const { filterParts, updateNameFilter } = useStringFilter();
  const availableData = useMemo(() => {
    return filterParts?.length === 0 ? data : getMatchedShortcuts(data, filterParts);
  }, [filterParts, data]);

  return { availableData, updateNameFilter };
}

export interface DashboardLeftSectionCommonProps {
  shortCuts: DashboardShortcutsBlockProps[];
}

export const DashboardLeftSectionCommon: FC<DashboardLeftSectionCommonProps> = React.memo(({ shortCuts }) => {
  const { wrapper, shortcutsList, searchWrapper, shortcutsHeader, shortcutsTitle } = useStyles({});
  const { availableData, updateNameFilter } = useFilterObjectArrayByString(shortCuts);

  return (
    <DashboardCommonContainer
      aria-label="Shortcuts"
      data-bypass="dashboard-shortcuts"
      className={`focusable ${wrapper}`}
      dataAid="DashboardLeftSectionBoxWrapper"
    >
      <BigidBypassButton
        bypassTo="dashborad-trends"
        ariaLabel="Skip to trends and status"
        className="bypass-dashboard"
      />
      <div className={shortcutsHeader}>
        <Typography className={shortcutsTitle} variant={'body2'}>
          Shortcuts
        </Typography>
        <div className={searchWrapper} data-aid="DashboardLeftSectionSearch">
          <BigidSearch
            onChange={updateNameFilter}
            onSubmit={updateNameFilter}
            placeholder="Search features, apps, or actions"
          />
        </div>
      </div>
      <div className={shortcutsList} data-aid="DashboardLeftSectionList">
        {availableData.map(item => (
          <DashboardShortcutsBlock key={item.title} {...item} />
        ))}
      </div>
    </DashboardCommonContainer>
  );
});
