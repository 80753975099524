import React from 'react';
import {
  BigidFieldRenderProps,
  BigidFormField,
  BigidFormFieldLabelPosition,
  BigidFormFieldTypes,
  BigidFormValues,
} from '@bigid-ui/components';
import { generateDataAid } from '@bigid-ui/utils';
import {
  isClassifierNameValid,
  regexPatternValidator,
  requiredFieldValidator,
  requiredNumberValidator,
} from './validationUtils';
import { CLASSIFIER_TYPES, ClassifierGridRow, RegexFormFields } from '../types/ClassifierTypes';
import { getFixedT } from '../translations';
import { CategoriesFieldDropdown } from '../components/CategoriesField/CategoriesFieldDropdown';
import { AttributesFieldDropdown } from '../components/AttributesField/AttributesFieldDropdown';
import { getValueOrDefault } from '../utils/utils';

const t = getFixedT('form');

export const initialFormValues = {
  [RegexFormFields.classifierName]: '',
  [RegexFormFields.description]: '',
  [RegexFormFields.classifierType]: CLASSIFIER_TYPES.CONTENT,
  [RegexFormFields.classificationRegex]: '',
  attribute: {
    friendly_name: '',
    glossary_id: '',
  },
};

const initialDataTypeValues: Partial<ClassifierGridRow> = {
  [RegexFormFields.minLength]: '',
  [RegexFormFields.maxLength]: '',
  [RegexFormFields.validation]: null,
};

const initialSupportTermValues = {
  [RegexFormFields.supportTerm]: '',
  [RegexFormFields.proximityAfter]: '',
  [RegexFormFields.proximityBefore]: '',
};

const initialNegativeSupportTermValues = {
  [RegexFormFields.negativeSupportTerm]: '',
  [RegexFormFields.nstProximityAfter]: '',
  [RegexFormFields.nstProximityBefore]: '',
};

const initialAdvancedOptionsValues = {
  [RegexFormFields.report_as_classification]: true,
  [RegexFormFields.report_as_pii_finding]: false,
  [RegexFormFields.ignored]: false,
};

export const getFieldsForSubmit = () => ({
  ...initialFormValues,
  ...initialDataTypeValues,
  ...initialSupportTermValues,
  ...initialNegativeSupportTermValues,
  ...initialAdvancedOptionsValues,
});

const initialNotSubmitedValues = {
  isSupportTermFormVisible: false,
  isNegativeSupportTermFormVisible: false,
};

const getDefaultFieldTypeNumberProps = (isOOTB: boolean, tooltipText?: string) => ({
  isRequired: true,
  fieldProps: {
    size: 'large',
    readOnly: isOOTB,
  },
  validate: requiredNumberValidator,
  tooltipText: tooltipText,
});

const getCheckboxFieldConfig = (name: string, label: string, tooltipText?: string) => ({
  name: name,
  label,
  type: BigidFormFieldTypes.CHECKBOX,
  labelPosition: BigidFormFieldLabelPosition.left,
  tooltipText: tooltipText ? tooltipText : undefined,
  fieldProps: {
    dataAid: `${generateDataAid('checkbox', [name])}`,
  },
});

const handleRegexValidator = (regex: string) => {
  const isRequiredValid = requiredFieldValidator(regex);
  const isRegexValid = regexPatternValidator(regex);

  if (isRequiredValid || isRegexValid) {
    return isRequiredValid || isRegexValid;
  }

  return false;
};

const getDataAndMetadataRadioButtonConfig = (isClassifyFileNamesEnabled: boolean) =>
  isClassifyFileNamesEnabled
    ? [
        {
          value: CLASSIFIER_TYPES.CONTENT_AND_METADATA,
          label: t('radioBoth'),
          dataAid: `${generateDataAid('classifierType', [CLASSIFIER_TYPES.CONTENT_AND_METADATA])}`,
        },
      ]
    : [];

const getInitialFormData = (isEdit: boolean, isOOTB: boolean, isClassifyFileNamesEnabled: boolean) => ({
  initialValues: initialFormValues,
  fields: [
    {
      name: RegexFormFields.classifierName,
      label: t('name'),
      isRequired: true,
      validate: (name: string) => {
        const isRequiredValid = requiredFieldValidator(name);
        const isNameValid = isClassifierNameValid(name);

        if (isRequiredValid) {
          return isRequiredValid;
        }

        if (!isEdit && isNameValid) {
          return isNameValid;
        }

        return false;
      },
      fieldProps: {
        size: 'large',
        readOnly: isOOTB || isEdit,
      },
    },
    {
      name: 'attribute',
      render: AttributesFieldDropdown,
      isRequired: false,
      fieldProps: {
        size: 'large',
      },
    },
    {
      name: RegexFormFields.categories,
      label: t('category'),
      render: (param: BigidFieldRenderProps) => <CategoriesFieldDropdown {...param} />,
      isRequired: false,
      fieldProps: {
        size: 'large',
      },
    },
    {
      name: RegexFormFields.description,
      label: t('description'),
      type: BigidFormFieldTypes.TEXTAREA,
      fieldProps: {
        multiline: true,
        readOnly: isOOTB,
      },
      misc: {
        rows: 2,
      },
    },
    {
      name: RegexFormFields.classifierType,
      type: BigidFormFieldTypes.RADIO,
      disabled: isOOTB,
      options: [
        {
          value: CLASSIFIER_TYPES.CONTENT,
          label: t('radioData'),
          dataAid: `${generateDataAid('classifierType', [CLASSIFIER_TYPES.CONTENT])}`,
        },
        {
          value: CLASSIFIER_TYPES.METADATA,
          label: t('radioMetadata'),
          dataAid: `${generateDataAid('classifierType', [CLASSIFIER_TYPES.METADATA])}`,
        },
        ...getDataAndMetadataRadioButtonConfig(isClassifyFileNamesEnabled),
      ],
    },
    {
      name: RegexFormFields.classificationRegex,
      type: BigidFormFieldTypes.TEXTAREA,
      validate: handleRegexValidator,
      fieldProps: {
        readOnly: isOOTB,
        multiline: true,
        placeholder: t('enterRegexHere'),
      },
      misc: {
        rows: 5,
      },
    },
  ],
});

const getClassifierDataForm = (isOOTB: boolean) => ({
  initialValues: initialDataTypeValues,
  fields: [
    {
      name: RegexFormFields.minLength,
      label: t('minLength'),
      ...getDefaultFieldTypeNumberProps(isOOTB, t('tooltips.minLength')),
    },
    {
      name: RegexFormFields.maxLength,
      label: t('maxLength'),
      ...getDefaultFieldTypeNumberProps(isOOTB, t('tooltips.maxLength')),
    },
    {
      name: 'isSupportTermFormVisible',
      label: t('supportTermCheckbox'),
      disabled: isOOTB,
      type: BigidFormFieldTypes.CHECKBOX,
      labelPosition: BigidFormFieldLabelPosition.left,
      tooltipText: t('tooltips.supportTermCheckbox'),
      fieldProps: {
        dataAid: `${generateDataAid('checkbox', ['is-support-term'])}`,
      },
    },
    {
      name: 'isNegativeSupportTermFormVisible',
      label: t('negativeSupportTermCheckbox'),
      disabled: isOOTB,
      type: BigidFormFieldTypes.CHECKBOX,
      labelPosition: BigidFormFieldLabelPosition.left,
      tooltipText: t('tooltips.negativeSupportTermCheckbox'),
      fieldProps: {
        dataAid: `${generateDataAid('checkbox', ['is-negative-support-term'])}`,
      },
    },
  ],
});

const getSupportTermForm = (isOOTB: boolean) => ({
  initialValues: initialSupportTermValues,
  fields: [
    {
      name: RegexFormFields.supportTerm,
      type: BigidFormFieldTypes.TEXTAREA,
      validate: handleRegexValidator,
      fieldProps: {
        readOnly: isOOTB,
        multiline: true,
      },
      misc: {
        rows: 5,
      },
    },
    {
      name: RegexFormFields.proximityBefore,
      label: t('proximityBefore'),
      ...getDefaultFieldTypeNumberProps(isOOTB),
    },
    {
      name: RegexFormFields.proximityAfter,
      label: t('proximityAfter'),
      ...getDefaultFieldTypeNumberProps(isOOTB),
    },
  ],
});

const getNegativeSupportTermForm = (isOOTB: boolean) => ({
  initialValues: initialNegativeSupportTermValues,
  fields: [
    {
      name: RegexFormFields.negativeSupportTerm,
      type: BigidFormFieldTypes.TEXTAREA,
      validate: handleRegexValidator,
      fieldProps: {
        readOnly: isOOTB,
        multiline: true,
      },
      misc: {
        rows: 5,
      },
    },
    {
      name: RegexFormFields.nstProximityBefore,
      label: t('proximityBefore'),
      ...getDefaultFieldTypeNumberProps(isOOTB),
    },
    {
      name: RegexFormFields.nstProximityAfter,
      label: t('proximityAfter'),
      ...getDefaultFieldTypeNumberProps(isOOTB),
    },
  ],
});

const getAdvancedOptionsForm = () => ({
  initialValues: initialAdvancedOptionsValues,
  fields: [
    getCheckboxFieldConfig(
      RegexFormFields.report_as_classification,
      t('classificationReportLabel'),
      t('tooltips.classificationReport'),
    ),
    getCheckboxFieldConfig(RegexFormFields.report_as_pii_finding, t('piiReportLabel'), t('tooltips.piiReport')),
    getCheckboxFieldConfig(RegexFormFields.ignored, t('ignoredLabel'), t('tooltips.ignored')),
  ],
});

interface InitialDataType {
  initialValues?: BigidFormValues;
  fields?: BigidFormField[];
}

interface getInitialDataProps {
  classifierType: CLASSIFIER_TYPES;
  isSupportTermFormVisible: boolean;
  isNegativeSupportTermFormVisible: boolean;
  isEdit?: boolean;
  isOOTB?: boolean;
  isClassifyFileNamesEnabled?: boolean;
  formData?: BigidFormValues;
}

export const getInitialData = ({
  classifierType,
  isSupportTermFormVisible,
  isNegativeSupportTermFormVisible,
  formData,
  isEdit,
  isOOTB,
  isClassifyFileNamesEnabled,
}: getInitialDataProps): InitialDataType => {
  const { fields } = getInitialFormData(isEdit, isOOTB, isClassifyFileNamesEnabled);
  const { fields: dataFields } = getClassifierDataForm(isOOTB);
  const { fields: supportFields } = getSupportTermForm(isOOTB);
  const { fields: negativeSupportFields } = getNegativeSupportTermForm(isOOTB);
  const { fields: advancedOptionsFields } = getAdvancedOptionsForm();
  const attribute = {
    friendly_name: formData?.attribute?.friendly_name || '',
    glossary_id: formData?.attribute?.glossary_id || '',
  };
  const initialAdvancedOptionsIfNotOOTB = !isEdit
    ? initialAdvancedOptionsValues
    : {
        ...initialAdvancedOptionsValues,
        [RegexFormFields.report_as_classification]: getValueOrDefault(formData.report_as_classification, true),
      };

  switch (classifierType) {
    case CLASSIFIER_TYPES.CONTENT:
    case CLASSIFIER_TYPES.CONTENT_AND_METADATA:
      return {
        initialValues: {
          ...initialFormValues,
          ...initialDataTypeValues,
          ...initialNotSubmitedValues,
          ...(isOOTB ? {} : initialAdvancedOptionsIfNotOOTB),
          ...(isSupportTermFormVisible ? initialSupportTermValues : {}),
          ...(isNegativeSupportTermFormVisible ? initialNegativeSupportTermValues : {}),
          ...(formData ? formData : {}),
          attribute,

          isSupportTermFormVisible,
          isNegativeSupportTermFormVisible,
        },
        fields: [
          ...fields,
          ...dataFields,
          ...advancedOptionsFields,
          ...(isSupportTermFormVisible ? supportFields : []),
          ...(isNegativeSupportTermFormVisible ? negativeSupportFields : []),
        ],
      };

    case CLASSIFIER_TYPES.METADATA:
      return {
        initialValues: {
          ...initialFormValues,
          ...(formData ? formData : {}),
          attribute,
        },
        fields: [...fields],
      };

    default:
      return {
        initialValues: {
          ...initialFormValues,
          ...(formData ? formData : {}),
          attribute,
        },
        fields: [...fields],
      };
  }
};
