import React, { FC, useRef, MouseEvent } from 'react';
import { Paper, Popper, ClickAwayListener } from '@mui/material';
import { useStyles } from './MetadataSearchStyles';
import { getMaxPopperHeight } from './MetadataSearchUtils';
import { MetadataSearchBar } from './MetadataSearchBar/MetadataSearchBar';
import { MetadataSearchSummary } from './MetadataSearchSummary/MetadataSearchSummary';
import { MetadataSearchFilters } from './MetadataSearchFilters/MetadataSearchFilters';
import { MetadataSearchRecentObjects } from './MetadataSearchRecentObjects/MetadataSearchRecentObjects';
import {
  UseMetadataSearchProps,
  useMetadataSearchState,
  UseMetadataSearchInitialState,
} from './useMetadataSearchState';
import classNames from 'classnames';

export enum MetadataSearchVariant {
  STANDARD = 'STANDARD',
  ENLARGED = 'ENLARGED',
}

export type MetadataSearchInitialState = UseMetadataSearchInitialState;
export interface MetadataSearchProps extends UseMetadataSearchProps {
  dataAid?: string;
  width?: number;
  placeholder?: string;
  topTypesToShow?: number;
  variant?: MetadataSearchVariant;
  baseBackgroundColor?: string;
  focusedBorderColor?: string;
  isDisabled?: boolean;
  isFilterHidden?: boolean;
  isQuickSearchDisabled?: boolean;
}

export const MetadataSearch: FC<MetadataSearchProps> = ({
  dataAid = 'MetadataSearch',
  initialState,
  width,
  minQueryLength = 3,
  topTypesToShow = 3,
  topTypeEntitiesToShow = 3,
  recentObjectMaxAmount = 7,
  placeholder,
  fetchResults,
  fetchRecentObjects,
  saveRecentObjects,
  fetchFilters,
  fetchFilterSuggestions,
  onSubmit,
  onResultEntityClick,
  fetchResultsIdleTime = 1000,
  fetchFiltersIdleTime = 1000,
  fetchFilterOptionAmount = 1000,
  isResultsRouteActive,
  baseBackgroundColor,
  focusedBorderColor,
  variant = MetadataSearchVariant.STANDARD,
  onQueryChange,
  isDisabled,
  onResetClick,
  isFilterHidden,
  isQuickSearchDisabled,
}) => {
  const anchorElRef = useRef<HTMLDivElement>();
  const onClickTimestampRef = useRef(Date.now());
  const {
    isReady,
    query,
    filters,
    filtersConfig,
    searchResults,
    recentObjects,
    isInputFocused,
    isPopperTriggered,
    isSummaryModeOn,
    isFiltersModeOn,
    isRecentObjectsModeOn,
    isResultsFetchingLingers,
    isFiltersFetchingLingers,
    isFiltersFetching,
    error,
    onSearchIconClick,
    onSearchInputFocus,
    onSearchInputBlur,
    onSearchInputChange,
    onSearchInputKeyPress,
    onResetIconClick,
    onFilterTriggerClick,
    onSearchResultEntityClick,
    onSearchResultEntityGroupClick,
    onSearchResultShowAllClick,
    onFilterChange,
    onFiltersSubmit,
    onFiltersReset,
    onFilterSearch,
    onPopperClose,
    viewportHeight,
    selectedField,
    supportedEntityTypesMap,
    entityTypeConfigs,
  } = useMetadataSearchState({
    initialState,
    minQueryLength,
    topTypeEntitiesToShow,
    recentObjectMaxAmount,
    fetchFilters,
    fetchFilterSuggestions,
    fetchRecentObjects,
    saveRecentObjects,
    fetchResults,
    onResultEntityClick,
    onSubmit,
    fetchResultsIdleTime,
    fetchFiltersIdleTime,
    fetchFilterOptionAmount,
    isResultsRouteActive,
    onQueryChange,
    onResetClick,
    isQuickSearchDisabled,
  });

  const classes = useStyles({
    width,
    variant,
    popperWidth: anchorElRef.current?.clientWidth,
    maxPopperHeight: getMaxPopperHeight(viewportHeight, anchorElRef),
  });

  const handleFiltersTriggerClick = (): void => {
    onClickTimestampRef.current = Date.now();
    onFilterTriggerClick();
  };

  const handlePopperClose = (): void => {
    if (Date.now() - onClickTimestampRef.current > 100) {
      onPopperClose();
    }
  };

  const handlePopperContainerClick = (event: MouseEvent<HTMLDivElement>): void => {
    event.stopPropagation();
  };

  const isPopperOpen = !isQuickSearchDisabled && isPopperTriggered;

  return (
    <div
      className={classNames(classes.root, {
        enlarged: variant === MetadataSearchVariant.ENLARGED,
      })}
    >
      <div className={classes.searchBarContainer}>
        <div className={classes.searchBar} ref={anchorElRef}>
          <MetadataSearchBar
            dataAid={`${dataAid}-search-bar`}
            isReady={isReady}
            query={query}
            filters={filters}
            placeholder={placeholder}
            isInputFocused={isInputFocused}
            onSearchInputBlur={onSearchInputBlur}
            onSearchInputFocus={onSearchInputFocus}
            onSearchInputChange={onSearchInputChange}
            onSearchInputKeyPress={onSearchInputKeyPress}
            isFiltersModeOn={isFiltersModeOn}
            onFilterTriggerClick={handleFiltersTriggerClick}
            onResetIconClick={onResetIconClick}
            onSearchIconClick={onSearchIconClick}
            baseBackgroundColor={baseBackgroundColor}
            focusedBorderColor={focusedBorderColor}
            variant={variant}
            isDisabled={isDisabled}
            isFilterHidden={isFilterHidden}
          />
        </div>
      </div>
      <ClickAwayListener onClickAway={handlePopperClose}>
        <Popper
          id={`${dataAid}-popper`}
          placement="bottom"
          anchorEl={anchorElRef.current}
          open={isPopperOpen}
          modifiers={[
            {
              name: 'preventOverflow',
              enabled: true,
              options: { boundariesElement: 'window' },
            },
          ]}
        >
          <Paper
            data-aid={`${dataAid}-dropdown-container`}
            classes={{ root: classes.popper }}
            onClick={handlePopperContainerClick}
          >
            {isRecentObjectsModeOn && variant === MetadataSearchVariant.STANDARD && (
              <MetadataSearchRecentObjects
                dataAid={`${dataAid}-recent-objects`}
                recentObjects={recentObjects}
                onSearchResultEntityClick={onSearchResultEntityClick}
                onSearchResultEntityGroupClick={onSearchResultEntityGroupClick}
                supportedEntityTypesMap={supportedEntityTypesMap}
                entityTypeConfigs={entityTypeConfigs}
              />
            )}
            {isSummaryModeOn && (
              <MetadataSearchSummary
                dataAid={`${dataAid}-summary`}
                searchResults={searchResults}
                topTypesToShow={topTypesToShow}
                topTypeEntitiesToShow={topTypeEntitiesToShow}
                onSearchResultEntityClick={onSearchResultEntityClick}
                onSearchResultEntityGroupClick={onSearchResultEntityGroupClick}
                onSearchResultShowAllClick={onSearchResultShowAllClick}
                isResultsFetchingLingers={isResultsFetchingLingers}
                error={error}
                entityTypeConfigs={entityTypeConfigs}
              />
            )}
            {isFiltersModeOn && (
              <MetadataSearchFilters
                dataAid={`${dataAid}-filters`}
                query={query}
                filters={filters}
                isFiltersFetching={isFiltersFetching}
                isFiltersFetchingLingers={isFiltersFetchingLingers}
                filtersConfig={filtersConfig}
                onFilterChange={onFilterChange}
                onFilterSearch={onFilterSearch}
                onFiltersReset={onFiltersReset}
                onFiltersSubmit={onFiltersSubmit}
                error={error}
                selectedField={selectedField}
              />
            )}
          </Paper>
        </Popper>
      </ClickAwayListener>
    </div>
  );
};
