import './emailSetup.component.scss';
import template from './emailSetup.component.html';
import { module } from 'angular';
const app = module('app');
import { GENERAL_SETTINGS_PERMISSIONS } from '@bigid/permissions';
import { isPermitted } from '../../../react/services/userPermissionsService';
import { sessionStorageService } from '../../../common/services/sessionStorageService';
import { credentialsService } from '../../../react/services/angularServices';
import { CredentialType } from '../../../react/views/Credentials/credentialsFormUtils';
import { getApplicationPreference } from '../../../react/services/appPreferencesService';
import { getCredentialForNewIntegrationDropdowns } from '../../../react/views/Credentials/utils';

const ENCRYPTED_FIELDS = ['password', 'clientSecret', 'clientId', 'refreshToken', 'tenantId', 'accessToken'];

const visibleFieldsByType = {
  basic: ['credentials', 'password', 'sslTls', 'protocol', 'port', 'server'],
  oauth2: ['username', 'clientSecret', 'clientId', 'refreshToken', 'accessToken', 'port', 'server'],
  graph: ['username', 'clientSecret', 'clientId', 'tenantId'],
};

app.component('emailSetup', {
  template,
  controllerAs: 'emailSetupModel',
  controller: function ($scope, notificationService, $rootScope, $translate, emailSetupService, $location) {
    'ngInject';

    const emailSetupModel = this;
    emailSetupModel.isNewCredentialsFlow = getApplicationPreference('NEW_CREDENTIALS_FLOW_ENABLED');
    emailSetupModel.isHardcodedCredentials = true;
    emailSetupModel.data = {};
    emailSetupModel.availableModeOptions = ['SSL', 'StartTLS'];
    emailSetupModel.availableProtocolsOptions = ['SSLv3', 'TLSv1.0', 'TLSv1.1', 'TLSv1.2', 'TLSv1.3'];
    emailSetupModel.availableTypeOptions = [
      { name: 'Basic', id: 'basic' },
      { name: 'OAuth2', id: 'oauth2' },
      { name: 'Graph', id: 'graph' },
    ];
    emailSetupModel.patterns = {
      digitsOnly: /^\d+$/,
    };

    const getFieldOriginName = fieldName => `original${fieldName}`;

    const getFieldChangedName = fieldName => `${fieldName}Changed`;

    const resetChanged = () => {
      ENCRYPTED_FIELDS.forEach(field => {
        emailSetupModel[getFieldChangedName(field)] = false;
      });
    };

    const updateOriginal = data => {
      ENCRYPTED_FIELDS.forEach(fieldName => {
        if (data[fieldName]) {
          emailSetupModel[getFieldOriginName(fieldName)] = data[fieldName];
        }
      });
    };

    const isCredentialsFieldSelected = () => {
      if (!emailSetupModel.data.isHardcodedCredentials && !emailSetupModel.credentialIds?.length > 0) {
        getCredentialForNewIntegrationDropdowns({ isScannerOnly: { $not: { $eq: true } } })
          .then(credOptions => {
            emailSetupModel.credentialIds = credOptions;
          })
          .finally(() => {
            $rootScope.$applyAsync();
          });
      }
    };

    const handleData = function (data) {
      const _data = {};
      for (const prop in data) {
        _data[prop] = data[prop];
      }
      updateOriginal(data);

      if (!angular.isDefined(data['enabled'])) {
        _data['enabled'] = true;
      }

      _data.type =
        emailSetupModel.availableTypeOptions.find(({ id }) => id === data?.type) ||
        emailSetupModel.availableTypeOptions[0];

      _data.isHardcodedCredentials = data.isHardcodedCredentials ?? true;
      return angular.copy(_data);
    };

    const getEmailSetupData = () => {
      emailSetupService.getMailerData().then(
        result => {
          emailSetupModel.data = handleData(result.data);

          if (!emailSetupModel.data.isHardcodedCredentials) {
            isCredentialsFieldSelected();
          }

          if (!angular.isDefined(emailSetupModel.data.uiBaseUrl)) {
            emailSetupModel.data.uiBaseUrl = $location.$$protocol + '://' + $location.$$host;
          }
          if (!emailSetupModel.data.sslTls) {
            emailSetupModel.data.sslTls = null;
          }
          if (!emailSetupModel.data.protocol) {
            emailSetupModel.data.protocol = null;
          }
        },
        () => {
          notificationService.error('An error has occurred!');
        },
      );
    };

    const prepareDataForSend = data => ({
      ...data,
      type: data.type.id,
    });

    const updateMailer = (data, id) => {
      const dataForSend = prepareDataForSend(data);
      emailSetupService.updateMailer(dataForSend, id).then(
        () => {
          sessionStorageService.set('IS_SMTP_CONFIGURED', !!dataForSend?.enabled);
          notificationService.success('Mailer has been successfully updated!');
          resetChanged();
          updateOriginal(data);
        },
        () => {
          notificationService.error('An error has occurred!');
        },
      );
    };

    const createMailer = data => {
      const dataForSend = prepareDataForSend(data);
      emailSetupService.createMailer(dataForSend).then(
        result => {
          notificationService.success('Mailer has been successfully created!');
          sessionStorageService.set('IS_SMTP_CONFIGURED', !!dataForSend?.enabled);
          emailSetupModel.data._id = result.data.mailer._id;
          resetChanged();
          updateOriginal(data);
        },
        () => {
          notificationService.error('An error has occurred!');
        },
      );
    };

    const testMailer = data => {
      emailSetupService
        .testMailer({
          ...data,
          mailer: {
            ...data.mailer,
            type: data.mailer.type.id,
          },
        })
        .then(
          result => {
            if (angular.isDefined(result.data.sent) && result.data.sent) {
              notificationService.success('Test mail has been sent!');
            } else {
              const {
                data: {
                  err: { message, code, errno, responseCode },
                },
              } = result;
              const errMessage = message || `Code: ${code}; Errno: ${errno ?? responseCode}`;
              notificationService.error(`Sending test mail failed! ${errMessage}`);
            }
          },
          () => {
            notificationService.error('An error has occurred!');
          },
        );
    };

    emailSetupModel.$onInit = () => {
      resetChanged();
      getEmailSetupData();
      emailSetupModel.isManageEmailPermitted = isPermitted(GENERAL_SETTINGS_PERMISSIONS.MANAGE_EMAIL_SETUP.name);
    };

    emailSetupModel.onIsEnabledChange = () => {
      $scope.emailSetupForm.$setPristine();
      $scope.emailSetupForm.$setUntouched();
    };

    const deleteEncryptedFieldsIfNotChanged = formData => {
      ENCRYPTED_FIELDS.forEach(field => {
        if (!emailSetupModel[getFieldChangedName(field)]) {
          delete formData[field];
        }
      });
    };

    emailSetupModel.submit = (isValid, isTest) => {
      if (!isValid) return;
      const formData = angular.copy(emailSetupModel.data);
      if (!formData.sslTls) {
        formData.sslTls = '';
      }
      if (!formData.protocol) {
        formData.protocol = 'TLSv1.2';
      }

      if (!isTest) {
        if (formData._id) {
          deleteEncryptedFieldsIfNotChanged(formData);
          updateMailer(formData, formData._id);
        } else {
          createMailer(formData);
        }
      } else {
        testMailer({ mailer: formData });
      }
    };

    $scope.passwordChanged = (newVal, name) => {
      if (emailSetupModel?.[getFieldOriginName(name)] !== newVal) {
        emailSetupModel[getFieldChangedName(name)] = true;
      }
    };

    $scope.shouldDisplayField = fieldName => {
      const currentType = emailSetupModel.data.type?.id || emailSetupModel.availableTypeOptions[0].id;
      return visibleFieldsByType[currentType].includes(fieldName);
    };

    $scope.isCredentialsFieldSelected = isCredentialsFieldSelected;

    emailSetupModel.showRequiredError = inputField => {
      return inputField.$error.required && (inputField.$dirty || $scope.emailSetupForm.$submitted);
    };
  },
});
