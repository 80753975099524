import React from 'react';
import { getApplicationPreference } from '../../services/appPreferencesService';
import { LegacyIdentityAccessManagementPage } from '../../../administration/identityAccessManagement/identityAccessManagement.component';

import { QueryClientProvider, QueryClient } from 'react-query';
import { IdentityAccessManagementComponent } from './IdentityAccessManagementComponent';
import { AccessManagementContextProvider } from './AccessManagementContext';

export const IdentityAccessManagement = () => {
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: { refetchOnWindowFocus: false },
    },
  });
  const isNewPage = getApplicationPreference('SHOW_NEW_ACCESS_MANAGEMENT');

  if (isNewPage) {
    return (
      <QueryClientProvider client={queryClient}>
        <AccessManagementContextProvider>
          <IdentityAccessManagementComponent />
        </AccessManagementContextProvider>
      </QueryClientProvider>
    );
  }

  return <LegacyIdentityAccessManagementPage />;
};
