import React, { FC, useCallback, useMemo, useRef } from 'react';
import { BigidGridQueryComponents, BigidGridWithToolbar, BigidGridWithToolbarProps } from '@bigid-ui/grid';
import { BigidCopyIcon, BigidDataSearchIcon, BigidEditIcon, BigidScheduleIcon, BigidStopIcon } from '@bigid-ui/icons';
import { useUserPreferences } from '../../../components/hooks/useUserPrefrences';

import {
  AutoDiscoveryConfigModel,
  getAutoDiscoveryDefaultColumns,
  getAutoDiscoveryFilterConfig,
} from './AutoDiscoveryGridConfiguration';
import { appsInfo, getAutoDiscoveryAppsConfigsList } from '../../../services/autoDiscoveryService';
import { notificationService } from '../../../services/notificationService';
import { BigidLoader, ToolbarActionType } from '@bigid-ui/components';
import {
  copyAction,
  deleteAction,
  discoverAction,
  editAction,
  stopAction,
  downloadReport,
  downloadLogs,
} from './autoDiscoveryGridUtils';
import { SchedulerModal } from '../../CustomApp/views/CustomAppActions/SchedulerModal';
import { useScheduleConfig } from '../hooks/useScheduleConfig';
import { useGridUpdateBySseEvents } from '../hooks/useGridUpdateBySseEvents';
import { AutoDiscoveryNoDataContent } from './AutoDiscoveryNoDataContent';
import { ExecutionStatus } from '../../CustomApp/components/PresetCard/PresetLatestExecutionStatus';
import { useLocalTranslation } from '../translations';
import { useCheckManagerPermissions } from '../hooks/useCheckManagerPermissions';
import { useAutoDiscoverWizard } from '../hooks/useAutoDiscoverWizard';
import { getApplicationPreference } from '../../../services/appPreferencesService';

const AUTO_DISCOVERY_GRID_SETTINGS = 'dataSourceAutoDiscovery';

export interface AutoDiscoveryGridProps {
  isAutoDiscoveryAvailable: boolean;
}

export const AutoDiscoveryGrid: FC<AutoDiscoveryGridProps> = ({ isAutoDiscoveryAvailable }) => {
  const { t } = useLocalTranslation('AutoDiscovery');

  const autoDiscoveryConfigDefaultColumns = useMemo(() => getAutoDiscoveryDefaultColumns(t), [t]);

  const getInitialFilterToolbarConfig = useCallback(() => {
    return getAutoDiscoveryFilterConfig(t);
  }, [t]);

  const { isReady, preferences, gridColumns, filterToolbarConfig, updatePreferences } = useUserPreferences({
    stateName: AUTO_DISCOVERY_GRID_SETTINGS,
    initialGridColumns: autoDiscoveryConfigDefaultColumns,
    getInitialFilterToolbarConfig,
  });

  const hasManagerPermission = useCheckManagerPermissions(appsInfo.aws.appName);
  const logsPermission = !(
    getApplicationPreference('USE_SAAS') || getApplicationPreference('MULTI_TENANT_MODE_ENABLED')
  );

  const {
    selectedPreset,
    isScheduleDialogOpen,
    handleScheduleDialogClose,
    handleUpdateSchedule,
    handleOnScheduleButtonClick,
  } = useScheduleConfig(hasManagerPermission);

  const configsListRef = useRef<AutoDiscoveryConfigModel[]>();

  const { openAutoDiscoveryModal } = useAutoDiscoverWizard();

  const gridWithToolbarConfig: BigidGridWithToolbarProps<AutoDiscoveryConfigModel> = useMemo(
    () => ({
      columns: gridColumns,
      filterToolbarConfig,
      showSelectionColumn: true,
      isSingleSelection: true,
      showSelectAll: false,
      pageSize: 200,
      showSortingControls: true,
      customRowIdName: '_id',
      entityName: t('entityName'),
      noDataContent: (
        <AutoDiscoveryNoDataContent
          isAutoDiscoveryAvailable={isAutoDiscoveryAvailable}
          configsListRef={configsListRef}
          openAutoDiscoveryModal={openAutoDiscoveryModal}
        />
      ),
      onGridStateChange: ({ filter, ...gridState }) => {
        return updatePreferences({ filterState: { filter }, gridState });
      },
      fetchData: async (queryComponents: BigidGridQueryComponents) => {
        let data = [];
        let totalCount = 0;
        try {
          const result = await getAutoDiscoveryAppsConfigsList(queryComponents);
          configsListRef.current = result.data;
          data = result.data.filter(({ is_default }) => !is_default);
          totalCount = result.totalCount - (result.data.length - data.length);
        } catch (e) {
          notificationService.error(e.message);
        }
        return {
          data,
          totalCount,
        };
      },
      showSelectionCheckboxes: true,
      defaultSorting: preferences?.grid?.sort || [{ field: 'name', order: 'asc' }],
      toolbarActions: [
        {
          label: t('toolbar.discover'),
          icon: BigidDataSearchIcon,
          type: ToolbarActionType.TERTIARY,
          tooltip: t('toolbar.discover'),
          execute: async ({ selectedRows }) => {
            return discoverAction(selectedRows?.[0] ?? {});
          },
          disable: () => !hasManagerPermission,
          show: ({ selectedRowIds, selectedRows }) =>
            selectedRowIds.length === 1 && selectedRows?.[0]?.latestRun?.status_enum !== ExecutionStatus.IN_PROGRESS,
        },
        {
          label: '',
          icon: BigidDataSearchIcon,
          type: ToolbarActionType.ACTION_ICON,
          tooltip: t('toolbar.discover'),
          execute: async ({ selectedRows }) => {
            return discoverAction(selectedRows?.[0] ?? {});
          },
          disable: () => !hasManagerPermission,
          show: ({ selectedRowIds, selectedRows }) =>
            selectedRowIds.length === 1 && selectedRows?.[0]?.latestRun?.status_enum !== ExecutionStatus.IN_PROGRESS,
          isInline: true,
          isGlobal: true,
          hideActionInToolBar: true,
        },
        {
          label: t('toolbar.stop'),
          icon: BigidStopIcon,
          type: ToolbarActionType.TERTIARY,
          tooltip: t('toolbar.stop'),
          execute: async ({ selectedRows }) => {
            return stopAction(selectedRows?.[0] ?? {});
          },
          disable: () => !hasManagerPermission,
          show: ({ selectedRowIds, selectedRows }) =>
            selectedRowIds.length === 1 && selectedRows?.[0]?.latestRun?.status_enum === ExecutionStatus.IN_PROGRESS,
        },
        {
          label: t('toolbar.schedule'),
          icon: BigidScheduleIcon,
          type: ToolbarActionType.TERTIARY,
          tooltip: t('toolbar.schedule'),
          execute: async ({ selectedRows }) => {
            return handleOnScheduleButtonClick(selectedRows?.[0] ?? {});
          },
          disable: () => !hasManagerPermission,
          show: ({ selectedRowIds }) => selectedRowIds.length === 1,
        },
        {
          label: '',
          icon: BigidScheduleIcon,
          type: ToolbarActionType.ACTION_ICON,
          tooltip: t('toolbar.schedule'),
          execute: async ({ selectedRows }) => {
            return handleOnScheduleButtonClick(selectedRows?.[0] ?? {});
          },
          disable: () => !hasManagerPermission,
          show: ({ selectedRowIds }) => selectedRowIds.length === 1,
          isInline: true,
          isGlobal: true,
          hideActionInToolBar: true,
        },
        {
          label: '',
          icon: BigidEditIcon,
          type: ToolbarActionType.ACTION_ICON,
          tooltip: t('toolbar.edit'),
          execute: async ({ selectedRows }) => {
            return editAction(selectedRows?.[0] ?? {});
          },
          disable: () => !hasManagerPermission,
          show: ({ selectedRowIds }) => selectedRowIds.length === 1,
          isInline: true,
          hideActionInToolBar: true,
        },
        {
          label: 'Edit',
          icon: BigidEditIcon,
          type: ToolbarActionType.TERTIARY,
          tooltip: t('toolbar.edit'),
          execute: async ({ selectedRows }) => {
            return editAction(selectedRows?.[0] ?? {});
          },
          disable: () => !hasManagerPermission,
          show: ({ selectedRowIds }) => selectedRowIds.length === 1,
        },
        {
          label: '',
          icon: BigidCopyIcon,
          type: ToolbarActionType.ACTION_ICON,
          tooltip: t('toolbar.cloneTooltip'),
          execute: async ({ selectedRows }) => {
            return copyAction(selectedRows?.[0] ?? {});
          },
          disable: () => !hasManagerPermission,
          show: ({ selectedRowIds }) => selectedRowIds.length === 1,
          isInline: true,
          hideActionInToolBar: true,
        },
        {
          label: 'More',
          type: ToolbarActionType.DROPDOWN,
          show: ({ selectedRowIds }) => !!selectedRowIds.length,
          dropdownProps: {
            placeholder: 'More',
            clearOnSelect: true,
            options: [
              {
                label: t('toolbar.clone'),
                value: 'duplicate',
                tooltip: t('toolbar.cloneTooltip'),
                execute: async ({ selectedRows }) => {
                  return copyAction(selectedRows?.[0] ?? {});
                },
                show: ({ selectedRowIds }) => hasManagerPermission && selectedRowIds.length === 1,
              },
              {
                label: t('toolbar.delete'),
                value: 'delete',
                tooltip: t('toolbar.deleteTooltip'),
                execute: async ({ selectedRows }) => {
                  return deleteAction(selectedRows?.[0] ?? {});
                },
                show: ({ selectedRowIds, selectedRows }) =>
                  hasManagerPermission &&
                  selectedRowIds.length === 1 &&
                  selectedRows?.[0]?.latestRun?.status_enum !== ExecutionStatus.IN_PROGRESS,
              },
              {
                label: t('toolbar.report'),
                value: 'report',
                execute: async ({ selectedRows }) => {
                  return downloadReport(selectedRows[0]);
                },
                show: ({ selectedRowIds, selectedRows }) =>
                  hasManagerPermission &&
                  selectedRowIds.length === 1 &&
                  selectedRows[0]?.latestRun?.attachments?.[0]?.id,
              },
              {
                label: t('toolbar.logs'),
                value: 'logs',
                execute: async ({ selectedRows }) => {
                  return downloadLogs(selectedRows[0]);
                },
                show: ({ selectedRowIds }) => hasManagerPermission && logsPermission && selectedRowIds.length === 1,
              },
            ],
          },
        },
      ],
    }),
    [
      gridColumns,
      filterToolbarConfig,
      t,
      isAutoDiscoveryAvailable,
      openAutoDiscoveryModal,
      preferences?.grid?.sort,
      updatePreferences,
      hasManagerPermission,
      handleOnScheduleButtonClick,
    ],
  );

  useGridUpdateBySseEvents(configsListRef);

  return isReady ? (
    <>
      {isScheduleDialogOpen && (
        <SchedulerModal
          preset={selectedPreset}
          onUpdateSchedule={handleUpdateSchedule}
          onClose={handleScheduleDialogClose}
        />
      )}
      <BigidGridWithToolbar key={'dataSourceAutoDiscovery'} {...gridWithToolbarConfig} />
    </>
  ) : (
    <BigidLoader />
  );
};
