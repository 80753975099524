import React, { FC } from 'react';
import { BigidBody1 } from '@bigid-ui/components';
import { generateDataAid } from '@bigid-ui/utils';

interface CertificatesDialogContentProps {
  body: string;
}

export const CertificatesManagementDialogContent: FC<CertificatesDialogContentProps> = ({ body }) => (
  <BigidBody1 data-aid={generateDataAid('DialogContentText', [])}>{body}</BigidBody1>
);
