import { set, get } from './sessionStorageService';

export const MAP_SETTINGS_STORAGE = 'mapsSettings';

export interface MapSettings {
  zoomLevel?: number;
  zoomLatitude?: number;
  zoomLongitude?: number;
}

export const setConfig = (mapId: string, props: MapSettings): void => {
  const mapsSettings = get<any>(MAP_SETTINGS_STORAGE) || {};

  mapsSettings[mapId] = { ...mapsSettings[mapId], ...props };

  set(MAP_SETTINGS_STORAGE, mapsSettings);
};

export const getConfig = <T>(mapId: string): T => {
  const mapsSettings = get<any>(MAP_SETTINGS_STORAGE) || {};

  return mapsSettings[mapId];
};

export const mapService = {
  setConfig,
  getConfig,
};
