import React from 'react';
import { Box } from '@mui/material';
import { BigidBody1, BigidLoader } from '@bigid-ui/components';
import { useLocalTranslation } from './translations';
import { ModalContentLoader } from './DataSourceConnectionModalStyles';

type DataSourceConnectionModalContentLoaderProps = {
  dataAid?: string;
  translationKey?: string;
};

export const DataSourceConnectionModalContentLoader = ({
  dataAid = 'DataSourceConnectionModalContentLoader',
  translationKey = 'insights',
}: DataSourceConnectionModalContentLoaderProps): JSX.Element => {
  const { t } = useLocalTranslation();
  return (
    <ModalContentLoader>
      <Box height="50px">
        <BigidLoader dataAid={dataAid} position={'relative'} />
      </Box>
      <BigidBody1 style={{ width: '200px', textAlign: 'center' }}>{t(translationKey)}</BigidBody1>
    </ModalContentLoader>
  );
};
