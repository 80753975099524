import './bigidSwitcher.component.scss';
import template from './bigidSwitcher.component.html';
import { convertToReact } from '../../common/services/convertToReact';

/**
 * @ngDoc component
 * @name bigidSwitcher
 */
export const BigidSwitcher = convertToReact('bigidSwitcher', {
  template,
  controller: function () {
    this.isEnabled = false;

    this.$onChanges = () => {
      if (typeof this.isEnabled != 'boolean') {
        this.isEnabled = false;
      }
    };

    this.onToggle = () => {
      this.isEnabled = !this.isEnabled;

      this.onToggled({ isEnabled: this.isEnabled });
      if (typeof this.onToggledOutput === 'function') {
        this.onToggledOutput(this.isEnabled);
      }
    };
  },
  bindings: {
    /**
     *  BOOLEAN
     *  initial value
     */
    isEnabled: '<',
    /**
     *  EVENT
     *  {isEnabled: BOOLEAN}
     *  in react please use `onToggledOutput`
     */
    onToggled: '&',
    /**
     *  `onToggled` is not working with angular2react (convertToReact) because it is not of type '<' but '&'.
     *  we can't refactor onToggled usages in the whole app due to "reasons"
     *  thus `onToggledOutput` should be used in react
     */
    onToggledOutput: '<',
  },
});
