import { DateISO8601 } from '../../../types/types';
import { AsyncOperation } from '../../../components/AsyncOperationProcessingWidget/AsyncOperationProcessingWidget';

export enum DataCatalogAsyncOperationRoutingKey {
  TAG_BULK_ASSIGNMENT = 'catalog.tasks.bulks.tags.assignment',
  CLUSTER_BULK_ASSIGNMENT = 'catalog.tasks.bulks.cluster.assignment',
  RULES_BULK_APPLYING = 'catalog.tasks.bulks.manual-attribute.apply.all.assignment',
  RULES_BULK_DELETING = 'catalog.tasks.bulks.manual-attribute.delete.assignment',
  TAG_BULK_UNASSIGNMENT = 'catalog.tasks.bulks.tags.unassignment',
}

export enum DataCatalogAsyncOperationStatus {
  NOT_STARTED = 'NOT_STARTED',
  COMPLETED = 'COMPLETED',
  ERROR = 'ERROR',
  RUNNING = 'RUNNING',
}

export enum DataCatalogAsyncOperationType {
  TAG_BY_QUERY = 'TagByQuery',
  UPDATE_BY_QUERY = 'UpdateByQuery',
  UNTAG_BY_QUERY = 'UntagByQuery',
  FIND_AND_DELEGATE_QUERY = 'FindAndDelegateQuery',
}

export interface DataCatalogAsyncOperation<Payload = any, QueryObject = any, SseData = any> {
  id: string;
  /** actual task status */
  taskStatus?: DataCatalogAsyncOperationStatus;
  /** status of the post-task indexing if relevant for the given operation */
  indexingStatus?: DataCatalogAsyncOperationStatus;
  /** computed status of the operation: taskStatus & indexingStatus */
  status?: DataCatalogAsyncOperationStatus;
  payload?: Payload;
  name?: DataCatalogAsyncOperationType;
  totalParts?: number;
  completedParts?: number;
  totalObjects?: number;
  find: QueryObject;
  nextRoutingKey: DataCatalogAsyncOperationRoutingKey;
  percentage?: number;
  sseRoute?: string;
  sseData?: SseData;
  updatedAt?: DateISO8601;
}

export type DataCatalogAsyncOperationNameMapping = { [operator in DataCatalogAsyncOperationType]?: string };

export type DataCatalogActingAsyncOperationsStorage = {
  [operation in DataCatalogAsyncOperationRoutingKey]?: DataCatalogAsyncOperation[];
};

export type DataCatalogAsyncOperationListenerParams = {
  onOperationRun?: (sseRoute: string, isOperationRunning: boolean) => void;
  onOperationCompleted?: () => void;
};

export type DataCatalogAsyncOperationListenerResponse<Operation = AsyncOperation> = {
  operations: Operation[];
};
