import { EventSourcePolyfill } from 'event-source-polyfill';
import { sessionStorageService } from '../../common/services/sessionStorageService';
import { getURL, httpService, MultipartRequestParams, QueryParamsAndDataObject } from './httpService';
import { SystemEvents, systemEventsEmitter } from './systemEvents';
import { loginService } from '../../authentication/login/login.service';
import { EventEmitter } from '@bigid-ui/utils';
import { IPromise } from 'angular';
import axios, { Axios, AxiosResponse } from 'axios';
import { $q } from 'ngimport';
import { v4 as uuid } from 'uuid';

export enum SSEMessageTypes {
  NOTIFICATION = 'notification',
  EVENT = 'event',
  START = 'start',
  PROGRESS = 'on progress',
  END = 'end',
  IN_PROGRESS = 'in progress',
  FAILURE = 'failure',
}

export enum SSEOperationStatus {
  BLOCKED,
  SUCCESS,
  ERROR,
  // The above three number based options seems obsolete, but changing them to string based variant
  // (like below) leads to few complications in other parts of the application which leverage sendSSERequestAndWait
  // function below (more specifically when operationStatus === SSEOperationStatus.ERROR leads to reject(data) which
  // triggers catch blocks that don't handle the error properly). That's why leaving the above intact and adding new
  // enum options below.
  BLOCKED_STRING = 'BLOCKED',
  SUCCESS_STRING = 'SUCCESS',
  ERROR_STRING = 'ERROR',
}

export enum SSE_EVENTS {
  SCAN_STATE_EVENT = 'scan-state-event',
  DATA_MINIMIZATION_OBJECT_EVENT = 'delete-object-event',
  NOTIFICATION = 'sse-notification',
  TPA_ACTIONS_EXECUTIONS = 'tpa-actions-executions-events',
  BIGCHAT_ANSWER_QUESTION_STREAM_EVENT = 'bigchat_answer_stream_event',
  DSAR_UPLOAD_DICTIONARY = 'dsar-dictionary-upload',
  VENDORS_UPLOAD_EVENT = 'vendors-upload-event',
  LEGAL_ENTITIES_UPLOAD_LOGO = 'legal-entities-upload-logo',
  FIND_SIMILAR_TABLES_EVENT = 'find-similar-tables-event',
}

interface SSEMessageEvent {
  data: string;
  type: string;
}

export interface SSEDataMessage<ResultEntity = any> {
  message: string;
  results: ResultEntity[];
  errors: any[];
  broadcastEvent: string;
  type: SSEMessageTypes;
  operationStatus: SSEOperationStatus;
}

let sseSource: any = null;

export const createSSEConnection = () => {
  if (loginService.isLoggedIn() && !sseSource) {
    sseSource = new EventSourcePolyfill(getURL('sse'), {
      headers: {
        Authorization: sessionStorageService.get('bigIdTokenID'),
      },
    });

    startSSEListener();
    console.log('Creating SSE connection');
  }
};

const startSSEListener = () => {
  sseSource.addEventListener('message', (event: SSEMessageEvent) => {
    const data = JSON.parse(event.data) as SSEDataMessage;
    const { message, broadcastEvent, type } = data;

    setTimeout(() => {
      if (type === SSEMessageTypes.NOTIFICATION) {
        sseEventEmitter.emit(SSE_EVENTS.NOTIFICATION, { data });
      } else if (broadcastEvent) {
        sseEventEmitter.emit(broadcastEvent, { data, type });
      }

      console.log(`Received SSE Message: ${message}`);
    });
  });

  sseSource.onerror = () => {
    if (sseSource.readyState === EventSource.CLOSED) {
      createSSEConnection();
    }
  };

  window.addEventListener('unload', () => {
    removeSSEConnection();
  });
};

const removeSSEConnection = () => {
  if (sseSource) {
    sseSource.close();
    sseSource = null;
    console.log('Closing SSE connection');
  }
};

systemEventsEmitter.addEventListener(SystemEvents.LOGIN, () => {
  if (!sseSource) {
    createSSEConnection();
  }
});

systemEventsEmitter.addEventListener(SystemEvents.LOGOUT, () => {
  removeSSEConnection();
});

export const sendSSERequestAndWait = <T = any, U = QueryParamsAndDataObject>(
  method: 'post' | 'put' | 'get',
  resourcePath: string,
  payload: QueryParamsAndDataObject | MultipartRequestParams<U> = {},
  requestParams: QueryParamsAndDataObject = {},
  sseEvent: string,
): IPromise<Partial<SSEDataMessage>> => {
  return new Promise((resolve, reject) => {
    const url = getURL(resourcePath);
    const uniqueBroadcastEvent = `${sseEvent}-${uuid()}`;
    const params = { ...requestParams, broadcastEvent: uniqueBroadcastEvent };

    const unregister = sseEventEmitter.addEventListener(uniqueBroadcastEvent, ({ data }) => {
      unregister();
      data.operationStatus === SSEOperationStatus.ERROR ? reject(data) : resolve(data);
    });

    const request = payload.files
      ? httpService.multipart(method as 'post' | 'put', resourcePath, payload, params)
      : $q.when(axios({ method, params, url, data: payload }));

    request.catch(e => {
      unregister();
      reject(e);
    });
  });
};

export const sendSSERequestWithActionCallback = async <T = any, U = QueryParamsAndDataObject>(
  method: 'post' | 'put' | 'get',
  resourcePath: string,
  payload: QueryParamsAndDataObject | MultipartRequestParams<U> = {},
  requestParams: QueryParamsAndDataObject = {},
  actionCallback: (sseMessage: SSEDataMessage) => any,
): Promise<AxiosResponse<T>> => {
  const url = getURL(resourcePath);
  const uniqueBroadcastEvent = uuid();
  const params = { ...requestParams, broadcastEvent: uniqueBroadcastEvent };

  const unregister = sseEventEmitter.addEventListener(uniqueBroadcastEvent, ({ data }) => {
    unregister();
    actionCallback(data);
  });

  try {
    return payload.files
      ? await httpService.multipart<T, U>(method as 'post' | 'put', resourcePath, payload, params)
      : await $q.when(axios({ method, params, url, data: payload }));
  } catch (e) {
    unregister();
    throw e;
  }
};

export const sendSSERequestWithEventId = async <T = any, U = QueryParamsAndDataObject>(
  method: 'post' | 'put' | 'get' | 'delete',
  resourcePath: string,
  payload: any | MultipartRequestParams<U> = {},
  requestParams: QueryParamsAndDataObject = {},
  uniqueBroadcastEvent: string,
  requestHeaders = {},
): Promise<AxiosResponse<T>> => {
  const url = getURL(resourcePath);
  const params = { ...requestParams, broadcastEvent: uniqueBroadcastEvent };

  try {
    return payload.files
      ? await httpService.multipart<T, U>(method as 'post' | 'put', resourcePath, payload, params)
      : await $q.when(axios({ method, params, url, data: payload, headers: requestHeaders }));
  } catch (e) {
    throw e;
  }
};

export const subscribeToRepeatedSSEEventById = <ResultEntity = any>(
  uniqueBroadcastEvent: string,
  actionCallback: (sseMessage: SSEDataMessage<ResultEntity>) => any,
) => {
  subscribeUserToSSEEvent(uniqueBroadcastEvent);
  const unregister = sseEventEmitter.addEventListener(uniqueBroadcastEvent, ({ data }) => {
    if (data?.results?.progress?.isFinished) {
      unregister();
    }
    actionCallback(data);
  });
  return () => {
    unsubscribeUserFromSSEEvent(uniqueBroadcastEvent);
    unregister();
  };
};

export const subscribeUserToSSEEvent = async (eventName: string) => {
  await httpService.post(`sse/subscribe/${eventName}`);
};

export const unsubscribeUserFromSSEEvent = async (eventName: string) => {
  await httpService.delete(`sse/subscribe/${eventName}`);
};

export const sseEventEmitter = new EventEmitter();
