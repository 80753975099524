import React, { FC, Fragment } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { AppStatus } from '../../../../services/appsLicenseService';
import { BigidColors, BigidTooltip } from '@bigid-ui/components';

const statusExpiredInfo = 'To purchase this app, please contact your BigID support team.';
interface StatusCellProps {
  status: string;
}

const useStyles = makeStyles({
  root: {
    display: 'flex',
    alignItems: 'center',
  },
  iconWrapper: {
    display: 'flex',
  },
  icon: {
    fill: BigidColors.gray[900],
    marginLeft: 8,
    height: 18,
    width: 18,
  },
});

export const StatusCell: FC<StatusCellProps> = ({ status }) => {
  const classes = useStyles({});
  const shouldShowInfo = status === AppStatus.EXPIRED || status === AppStatus.TRIAL_EXPIRED;

  return shouldShowInfo ? (
    <div className={classes.root}>
      <div>{status}</div>
      <BigidTooltip title={statusExpiredInfo} placement="top">
        <InfoOutlinedIcon className={classes.icon} />
      </BigidTooltip>
    </div>
  ) : (
    <BigidTooltip title={status}>
      <div>{status}</div>
    </BigidTooltip>
  );
};
