import React, { FC, useState, ChangeEvent, Dispatch, SetStateAction, useEffect } from 'react';
import { BigidFormBox } from '../../../../../components/BigidFormBox/BigidFormBox';
import { BigidTextField } from '@bigid-ui/components';
import { customAppService } from '../../../../../services/customAppService';
import { TpaGeneralValues } from '../EditCustomApp';

export interface BaseUrlSectionProps {
  bigidBaseUrl: string;
  setSectionValid: Dispatch<SetStateAction<boolean>>;
  onChange: (obj: TpaGeneralValues) => void;
}

const ERROR_MESSAGE = `This field is mandatory and shouldn't contain url params`;

export const BaseUrlSection: FC<BaseUrlSectionProps> = ({ bigidBaseUrl, onChange, setSectionValid }) => {
  const [isUrlValid, setIsUrlValid] = useState<boolean>(true);

  const handleBigidBaseUrl = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const key = 'bigid_base_url';
    const value = e.target.value;
    const isBigIdBaseUrlValid = customAppService.isValidURL(value.trim());
    setIsUrlValid(isBigIdBaseUrlValid);
    isBigIdBaseUrlValid && onChange({ [key]: value });
  };

  useEffect(() => {
    setSectionValid(isUrlValid);
  }, [isUrlValid, setSectionValid]);

  return (
    <BigidFormBox title="BigID Base URL" description={'Configure BigID endpoint, which the app sends its requests to.'}>
      <BigidTextField
        onChange={handleBigidBaseUrl}
        defaultValue={bigidBaseUrl}
        isError={!isUrlValid}
        errorMessage={isUrlValid ? '' : ERROR_MESSAGE}
        type="text"
        dataAid={'base-url-field'}
      />
    </BigidFormBox>
  );
};
