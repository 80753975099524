import { module } from 'angular';
const app = module('app');

app.factory('userAttributesAndSystemsService', [
  '$http',
  'appSettings',
  function ($http, appSettings) {
    return {
      getUserAttributesAndSystemsData: function getUserAttributesAndSystemsData() {
        return $http
          .get(appSettings.serverPath + '/' + appSettings.version + '/piiRecords?filter=')
          .then(function (response) {
            return response.data;
          });
      },
    };
  },
]);
