import React from 'react';
import { generateDataAid } from '@bigid-ui/utils';
import { BigidMarkdown, BigidBody1 } from '@bigid-ui/components';
import styled from '@emotion/styled';
import { useLocalTranslation } from '../../../translations';
import { useTheme } from '@mui/styles';

interface CaseRemediationMarkdownProps {
  dataAid: string;
  generatedText: string;
}

const MarkdownWrapper = styled.div({
  '& pre': {
    all: 'unset',
    display: 'block',
    unicodeBidi: 'isolate',
    whiteSpace: 'pre',
  },
});

export const CaseRemediationMarkdown = ({ dataAid, generatedText }: CaseRemediationMarkdownProps) => {
  const theme = useTheme();
  const { t } = useLocalTranslation('RemediationSteps');

  return (
    <MarkdownWrapper data-aid={generateDataAid(dataAid, ['markdown'])}>
      <BigidMarkdown
        componentSlot={
          <div>
            <hr />
            <BigidBody1 color={theme.palette.bigid.gray500}>{t('vote.description')}</BigidBody1>
          </div>
        }
      >
        {generatedText}
      </BigidMarkdown>
    </MarkdownWrapper>
  );
};
