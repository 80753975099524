import { $injector } from 'ngimport';
import { module } from 'angular';
const app = module('app');

export let savedQueriesService;

app.factory('savedQueriesService', [
  '$http',
  'appSettings',
  function ($http, appSettings) {
    return {
      getSavedQueries: () =>
        $http
          .get(`${appSettings.serverPath}/${appSettings.version}/saved-queries?editable=true`)
          .then(response => response.data),

      getSavedQuery: id =>
        $http
          .get(`${appSettings.serverPath}/${appSettings.version}/saved-queries/${id}`)
          .then(response => response.data),

      createSavedQuery: data =>
        $http
          .post(`${appSettings.serverPath}/${appSettings.version}/saved-queries`, data)
          .then(response => response.data),

      updateSavedQuery: (id, data) =>
        $http
          .put(`${appSettings.serverPath}/${appSettings.version}/saved-queries/${id}`, data)
          .then(response => response.data),

      deleteSavedQuery: id =>
        $http
          .delete(`${appSettings.serverPath}/${appSettings.version}/saved-queries/${id}`)
          .then(response => response.data),
    };
  },
]);

app.run(() => {
  savedQueriesService = $injector.get('savedQueriesService');
});
