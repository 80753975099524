import React, { FC, useCallback, useState, useEffect } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { useInterval, BigidBody1 } from '@bigid-ui/components';
import { MetadataSearchIndexingStatus } from '../../components/MetadataSearch/MetadataSearchTypes';
import { getMetadataSearchIndexingStatus } from '../../components/MetadataSearch/MetadataSearchService';

export interface MetadataSearchIndexingIndicatorProps {
  indexingCheckInterval?: number;
}

const useStyles = makeStyles({
  root: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  text: {
    paddingTop: '4px',
  },
});

export const MetadataSearchIndexingIndicator: FC<MetadataSearchIndexingIndicatorProps> = ({
  indexingCheckInterval = 30000,
}) => {
  const classes = useStyles();

  const [isIndexingInProgress, setIsIndexingInProgress] = useState<boolean>(false);

  const fetchStatus = useCallback(async () => {
    try {
      const { statusPerType } = await getMetadataSearchIndexingStatus();
      const isIndexingInProgress = !!statusPerType.find(
        ({ status }) => status === MetadataSearchIndexingStatus.INDEXING,
      );

      setIsIndexingInProgress(isIndexingInProgress);
    } catch ({ message }) {
      console.error(`An error has occurred: ${message}`);
    }
  }, []);

  useEffect(() => {
    fetchStatus();
  }, [fetchStatus]);

  useInterval(() => {
    fetchStatus();
  }, indexingCheckInterval);

  return isIndexingInProgress ? (
    <div className={classes.root}>
      <img src="/images/metadata-search-indexing.gif" alt="Indexing..." />
      <BigidBody1 className={classes.text}>Some results are still indexing</BigidBody1>
    </div>
  ) : null;
};
