import makeStyles from '@mui/styles/makeStyles';
import { BigidColorsV2 } from '@bigid-ui/components';

export const useStyles = makeStyles({
  icon: {
    display: 'flex',
    alignItems: 'center',
    marginRight: '4px',
  },
  text: {
    display: 'flex',
    alignItems: 'center',
    maxWidth: '100%',
    height: '100%',
    overflow: 'hidden',
  },
  textValue: {
    overflowX: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    flexGrow: 1,
    flexShrink: 0,
  },
  textValueShrinked: {
    flexShrink: 1,
    flexGrow: 1,
  },
  textValueHighlighted: {
    backgroundColor: BigidColorsV2.yellow[300],
  },
  extraValueIndicator: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    minWidth: '20px',
    maxWidth: '30px',
    height: '20px',
    borderRadius: '3px',
    backgroundColor: BigidColorsV2.gray[700],
    marginLeft: '4px',
  },
  extraValueIndicatorText: {
    color: BigidColorsV2.white,
  },
  tooltipTitle: {
    textAlign: 'center',
    marginBottom: '8px',
  },
  tooltipValue: {
    display: 'flex',
    flexDirection: 'column',
  },
});
