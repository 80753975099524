import { analyticsService } from '../../services/analyticsService';

export enum RiskAssessmentUITrackingEvent {
  RISK_ASSESSMENT_PDF_DOWNLOAD = 'RISK_ASSESSMENT_PDF_DOWNLOAD',
  RISK_ASSESSMENT_PAGE_VIEW = 'RISK_ASSESSMENT_PAGE_VIEW',
}

export const trackEventRiskAssessment = (eventType: RiskAssessmentUITrackingEvent, data?: Record<string, unknown>) => {
  analyticsService.trackManualEvent(eventType as any, data);
};
