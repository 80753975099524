import React, { FC } from 'react';
import styled from '@emotion/styled';
import { BigidArrowRightIcon } from '@bigid-ui/icons';
import { generateDataAid } from '@bigid-ui/utils';
import { BigidNotes1, BigidBody1, BigidHeading6 } from '@bigid-ui/components';
import { AuditTrailPropChangeDetails } from '../../dataCatalogAuditTrailService';
import { getPropertiesGrouped, getDisplayValue } from '../utils';
import { useLocalTranslation } from '../../translations';

export interface ModifiedProps {
  dataAid: string;
  previousDetails: AuditTrailPropChangeDetails[];
  currentDetails: AuditTrailPropChangeDetails[];
}

const Root = styled('div')`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  gap: 24px;
`;

const Section = styled('div')`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const SectionHeader = styled(BigidHeading6)`
  margin-bottom: 24px;
  width: 100%;
`;

const SectionBody = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 24px;
  width: 100%;
`;

const Prop = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const PropName = styled(BigidNotes1)`
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
`;

const PropValueContainer = styled('div')`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 8px;
`;

const PropValue = styled(BigidBody1)`
  overflow-wrap: break-word;
  overflow: auto;
`;

export const Modified: FC<ModifiedProps> = ({ dataAid, previousDetails, currentDetails }) => {
  const { t } = useLocalTranslation('details');
  const { modifiedProps, unaffectedProps } = getPropertiesGrouped(currentDetails, previousDetails);

  return (
    <Root data-aid={dataAid}>
      {modifiedProps.length > 0 && (
        <Section>
          <SectionHeader data-aid={generateDataAid(dataAid, ['section', 'modified', 'header'])}>
            {t('modified')}
          </SectionHeader>
          <SectionBody data-aid={generateDataAid(dataAid, ['section', 'modified', 'body'])}>
            {modifiedProps.map(([prev, curr], index) => {
              const propName = curr.name;
              const propCurrValue = getDisplayValue(curr.value);
              const propPrevValue = getDisplayValue(prev.value);

              return (
                <Prop key={`${propName}-${index}`}>
                  <PropName data-aid={generateDataAid(dataAid, [propName, 'name'])}>{propName}</PropName>
                  <PropValueContainer>
                    <PropValue data-aid={generateDataAid(dataAid, [propName, 'value', 'previous'])}>
                      {propCurrValue}
                    </PropValue>
                    <BigidArrowRightIcon />
                    <PropValue data-aid={generateDataAid(dataAid, [propName, 'value', 'current'])}>
                      {propPrevValue}
                    </PropValue>
                  </PropValueContainer>
                </Prop>
              );
            })}
          </SectionBody>
        </Section>
      )}
      {unaffectedProps.length > 0 && (
        <Section>
          <SectionHeader data-aid={generateDataAid(dataAid, ['section', 'unaffected', 'header'])}>
            {t('unaffected')}
          </SectionHeader>
          <SectionBody data-aid={generateDataAid(dataAid, ['section', 'unaffected', 'body'])}>
            {unaffectedProps.map((curr, index) => {
              const propName = curr.name;
              const propValue = getDisplayValue(curr.value);

              return (
                <Prop key={`${propName}-${index}`}>
                  <PropName data-aid={generateDataAid(dataAid, [propName, 'name'])}>{propName}</PropName>
                  <PropValueContainer>
                    <PropValue data-aid={generateDataAid(dataAid, [propName, 'value', 'previous'])}>
                      {propValue}
                    </PropValue>
                  </PropValueContainer>
                </Prop>
              );
            })}
          </SectionBody>
        </Section>
      )}
    </Root>
  );
};
