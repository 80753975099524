import { httpService } from '../httpService';

export type AnswerLinks = Array<{
  url: string;
  title: string;
}>;

export enum ActionTypes {
  ANSWER_QUESTION = 'answerQuestion',
  FOLLOW_UP_CHECK = 'followUpCheck',
}

export enum ActionSubTypes {
  LLM = 'llm',
}

export interface BigchatActionRequestPayload {
  type: ActionTypes;
  subType: ActionSubTypes;
  additionalProperties: AnswerQuestionPayload | FollowUpPayload;
}

export interface AnswerQuestionPayload {
  question: string;
  uniqueBroadcastEvent?: string;
}

export interface FollowUpPayload extends AnswerQuestionPayload {
  previousQuestions: string[];
}

export interface FollowUpResponse {
  isFollowUp: boolean;
}

export interface AnswerQuestionResponse {
  answer: string;
  context: string;
  links: AnswerLinks;
}

export const checkIsFollowUpQuestion = async (followUpPayload: FollowUpPayload) => {
  const {
    data: { data },
  } = await httpService.post<{ data: FollowUpResponse[] }, BigchatActionRequestPayload>(`bigchat:action`, {
    type: ActionTypes.FOLLOW_UP_CHECK,
    subType: ActionSubTypes.LLM,
    additionalProperties: {
      ...followUpPayload,
    },
  });
  return data?.length ? data[0] : null;
};

export const sendUserQuestion = async (answerQuestionPayload: AnswerQuestionPayload) => {
  const {
    data: { data },
  } = await httpService.post<{ data: AnswerQuestionResponse[] }, BigchatActionRequestPayload>(`bigchat:action`, {
    type: ActionTypes.ANSWER_QUESTION,
    subType: ActionSubTypes.LLM,
    additionalProperties: {
      ...answerQuestionPayload,
    },
  });
  return data?.length ? data[0] : null;
};
