import React, { FC, ReactText, useRef, useState, useEffect } from 'react';
import {
  BigidGridWithToolbar,
  BigidGridWithToolbarProps,
  BigidGridQueryComponents,
  BigidGridRow,
  NextGridState,
} from '@bigid-ui/grid';
import { BigidPaper } from '@bigid-ui/components';
import makeStyles from '@mui/styles/makeStyles';
import { queryService } from '../../../services/queryService';
import { httpService } from '../../../services/httpService';
import { notificationService } from '../../../services/notificationService';
import { cloneDeep } from 'lodash';
import { MetadataModel, MetadataConnectionsResponse } from '../MetadataConnectionTypes';
import { ExplorerGridDsColumns } from './explorerGridColumnsManager';
import { ExplorerGridFiltersMap } from './explorerGridFiltersManager';
import {
  useRouteLeavingListener,
  RouteLeavingContent,
} from '../../../components/RouteLeaving/hooks/useRouteLeavingListener';

interface ExplorerGridProps {
  dataSourceName: string;
  onSelection: (selectedRowIds: ReactText[], rows: BigidGridRow[]) => void;
  preSelectedIds: ReactText[];
  gridFilters: ExplorerGridFiltersMap;
}

const useStyles = makeStyles({
  gridWrapper: {
    width: '100%',
    display: 'flex',
    position: 'relative',
    height: '100%',
    maxHeight: '100%',
    overflow: 'hidden',
    paddingBottom: 10,
  },
  actionsButton: {
    minWidth: 160,
  },
});

const ROUTE_LEAVING_PROMPT_BODY: RouteLeavingContent = {
  body: 'Do you want to leave this page before finishing discovering data sources?',
};

export const ExplorerGrid: FC<ExplorerGridProps> = ({ dataSourceName, onSelection, preSelectedIds, gridFilters }) => {
  const classes = useStyles();
  const [currentGridRows, setCurrentGridRows] = useState<BigidGridRow[]>([]);
  const { updateIsRouteLeavingEnabled, updateRouteLeavingPrompt } = useRouteLeavingListener();
  const selectedIdsRef = useRef([]);

  useEffect(() => {
    updateRouteLeavingPrompt(ROUTE_LEAVING_PROMPT_BODY);
  }, [updateRouteLeavingPrompt]);

  const stateChangeHandler = (state: NextGridState) => {
    const { selectedRowIds } = state;
    // Call onSelection only for real changes in selection
    if (selectedIdsRef.current.length !== selectedRowIds.length || selectedRowIds.length > 0) {
      selectedIdsRef.current = selectedRowIds;
      onSelection(selectedRowIds, currentGridRows);
      updateIsRouteLeavingEnabled(true);
    }
  };

  const updateRows = (rows: BigidGridRow[]) => {
    setCurrentGridRows(rows);
  };

  const gridWithToolbarConfig: BigidGridWithToolbarProps<MetadataModel> = {
    columns: ExplorerGridDsColumns.get(dataSourceName) || ExplorerGridDsColumns.get('base'),
    pageSize: 200,
    entityName: 'Data Sources',
    onGridStateChange: stateChangeHandler,
    preSelectedIds,
    showSelectAll: false,
    filterToolbarConfig: gridFilters.get(dataSourceName) || gridFilters.get('base'),
    hideColumnChooser: true,
    fetchData: async (queryComponents: BigidGridQueryComponents) => {
      const updatedQueryComp = cloneDeep(queryComponents);
      updatedQueryComp.filter.push({ field: 'type', operator: 'in', value: [dataSourceName] });
      let data: MetadataModel[] = [];
      let totalCount = 0;

      try {
        const gridConfigQuery = queryService.getGridConfigQuery({ ...updatedQueryComp });
        const {
          data: {
            data: { dsConnections, totalCount: count },
          },
        } = await httpService.fetch<{ data: MetadataConnectionsResponse }>(`ds-metadata?${gridConfigQuery}`);
        data = dsConnections;
        updateRows(dsConnections);
        totalCount = count;
      } catch (e) {
        console.error(e);
        notificationService.error('Could not fetch data. See logs for more information');
      }

      return {
        data,
        totalCount,
      };
    },
    showSortingControls: true,
    toolbarActions: [
      {
        label: '',
        isGlobal: false,
        show: () => false,
        disable: () => {
          return false;
        },
      },
    ],
  };

  return (
    <div className={classes.gridWrapper}>
      <BigidPaper>
        <BigidGridWithToolbar key={dataSourceName} {...gridWithToolbarConfig} />
        {/* using the key allows to re render grid when there is a change in dataSourceName */}
      </BigidPaper>
    </div>
  );
};
