import React, { FC, useState, useEffect } from 'react';
import {
  BigidDialog,
  BigidApiCallForm,
  PrimaryButton,
  SecondaryButton,
  BigidCaption,
  BigidColorsV2,
} from '@bigid-ui/components';
import { ApiCallCertificateForm } from '../../../../../../../components/ApiCallCertificateForm/ApiCallCertificateForm';
import { ApiCallActionConfigurationForm, Action, ACActionType } from '../../../../actionWorkflowTypes';
import makeStyles from '@mui/styles/makeStyles';
import { convertFormFormatToApiCallAction, removeEmptyExtraField } from '../../../../actionWorkflowUtils';
import { validateAPICallForm } from '../../../../../../../utilities/apiCallFormUtilities';

interface ApiCallFormProps {
  isOpen: boolean;
  onClose: () => void;
  value: ApiCallActionConfigurationForm;
  saveActionConfiguration: (actionToSave: Action) => void;
  autocompleteListItems: string[];
}

const useStyles = makeStyles({
  errorsWrapper: {
    marginTop: 16,
    display: 'flex',
    flexDirection: 'column',
    background: BigidColorsV2.red[200],
    borderRadius: 4,
    padding: '8px 16px',
  },
  error: { color: BigidColorsV2.red[900] },
});

export const ApiCallDialog: FC<ApiCallFormProps> = ({
  isOpen,
  onClose,
  value,
  saveActionConfiguration,
  autocompleteListItems,
}) => {
  const [form, setForm] = useState<ApiCallActionConfigurationForm>(value);
  const [errors, setErrors] = useState<string[]>([]);
  const classes = useStyles({});

  useEffect(() => {
    setForm(value);
  }, [value]);

  const handleSetValue = (name: string, value: any) => setForm(prevValue => ({ ...prevValue, [name]: value }));
  const handleCloseDialog = () => {
    onClose();
    setErrors([]);
  };

  const buttons = [
    {
      text: 'Save',
      component: PrimaryButton,
      onClick: () => {
        const formToSave = removeEmptyExtraField(form);
        const errors = validateAPICallForm(formToSave);
        if (errors.length === 0) {
          setErrors([]);
          saveActionConfiguration({
            type: ACActionType.API_CALL,
            configuration: convertFormFormatToApiCallAction(formToSave),
          });
          handleCloseDialog();
        } else {
          setErrors(errors);
        }
      },
      dataAid: 'APICallSettingsSaveButton',
    },
    {
      text: 'Cancel',
      component: SecondaryButton,
      onClick: handleCloseDialog,
      dataAid: 'APICallSettingsCancelButton',
    },
  ];

  return (
    <BigidDialog title="API Call" isOpen={isOpen} onClose={handleCloseDialog} showCloseIcon buttons={buttons}>
      <BigidApiCallForm setValue={handleSetValue} value={form} autocompleteListItems={autocompleteListItems}>
        <>
          <ApiCallCertificateForm {...form} setValue={handleSetValue} />
          {errors.length > 0 && (
            <div className={classes.errorsWrapper}>
              {errors.map((error, index) => (
                <BigidCaption key={index} className={classes.error} data-aid={`ApiCallError-${index}`}>
                  {error}
                </BigidCaption>
              ))}
            </div>
          )}
        </>
      </BigidApiCallForm>
    </BigidDialog>
  );
};
