import React, { FC, useContext, useEffect, useState } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { Typography } from '@mui/material';
import { BigidLoader, BigidIconType } from '@bigid-ui/components';
import { generateDataAid } from '@bigid-ui/utils';
import { getDataCategoriesRegulations, NamesAndIds } from '../../../../fmsdServices';
import { FmsdCategoriesItem } from './FmsdCategoriesItem';
import { useLocalTranslation } from '../../../../translations';
import { FmsdPreDiscoverCustomizeTypes } from '../../FmsdPreDiscoverViews';
import { PreDiscoverContext, ReducerActions } from '../../../hooks';
import { regulationsConfig, mapRegulationsToData } from './FmsdCategoriesConfig';
import {
  FmsdPageViewsEvents,
  FmsdTrackingEvents,
  trackFmsdEvent,
  trackFmsdPageView,
} from '../../../../fmsdEventsTrackerUtils';

export interface RegulationsDataMap extends NamesAndIds {
  Icon?: BigidIconType;
  classifiers?: string;
  selected: boolean;
}

const useStyles = makeStyles(theme => ({
  title: {
    textAlign: 'center',
    margin: '0 0 8px',
  },
  subTitle: {
    textAlign: 'center',
    fontSize: 15,
    margin: '0 0 48px',
  },
  categoriesList: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    marginBottom: 61,
  },
  regulationsButton: {
    background: 'none',
    border: 'none',
    padding: 0,
    fontSize: 14,
    color: theme.vars.palette.bigid.primary400,
    marginBottom: 23,
  },
}));

export const FmsdCategories: FC = () => {
  const {
    selectionsData: { categories: preselectedCategoriesNames },
    wizardActions: { setIsNextButtonDisabled, disableNextSteps, isNextStepDisabled },
    dispatch,
  } = useContext(PreDiscoverContext);

  const [dataCategories, setDataCategories] = useState<RegulationsDataMap[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(null);
  const { t } = useLocalTranslation('Categories');
  const { title, subTitle, categoriesList, regulationsButton } = useStyles();

  useEffect(() => {
    trackFmsdPageView(FmsdPageViewsEvents.FMSD_CATEGORIES_PAGE_VIEW);
    const fetchData = async () => {
      const data = await getDataCategoriesRegulations(
        regulationsConfig.map(({ name }) => name),
        ['name'],
      );
      const categoriesData = mapRegulationsToData(data);
      const categories = categoriesData.map(category => ({
        ...category,
        selected: preselectedCategoriesNames.includes(category.name),
      }));
      setDataCategories(categories);
      setIsLoading(false);
      setIsNextButtonDisabled(!categories.some(({ selected }) => selected));
    };
    fetchData();
  }, [preselectedCategoriesNames, setIsNextButtonDisabled]);

  const updateSelectedCategories = (categories: RegulationsDataMap[]) => {
    dispatch({
      type: ReducerActions.UPDATE_SELECTED_CATEGORIES,
      payload: {
        selectionData: {
          categories: categories.filter(({ selected }) => selected).map(({ name }) => name),
          classifiersMethodology: FmsdPreDiscoverCustomizeTypes.CATEGORIES,
        },
      },
    });
  };

  const switchToRegulations = () => {
    dispatch({
      type: ReducerActions.SWITCH_CUSTOMIZE_VIEW,
      payload: {
        selectionData: {
          classifiersMethodology: FmsdPreDiscoverCustomizeTypes.REGULATIONS,
        },
      },
    });
    trackFmsdEvent(FmsdTrackingEvents.FMSD_CATEGORIES_SWITCH_TO_REGULATIONS_CLICK);
  };

  const toggleCategory = (id: string, name: string) => {
    const categories = dataCategories.map(category => ({
      ...category,
      ...(category._id === id && { selected: !category.selected }),
    }));
    trackFmsdEvent(FmsdTrackingEvents.FMSD_CATEGORIES_CATEGORY_CLICK, { [FmsdTrackingEvents.CATEGORY_TYPE]: name });
    setDataCategories(categories);
    setIsNextButtonDisabled(!categories.some(({ selected }) => selected));
    if (!isNextStepDisabled()) disableNextSteps();
    updateSelectedCategories(categories);
  };

  return (
    <>
      {isLoading ? (
        <BigidLoader />
      ) : (
        <div>
          <Typography variant="h1" className={title}>
            {t('title')}
          </Typography>
          <Typography variant="body1" className={subTitle}>
            {t('subTitle')}
          </Typography>
          <div className={categoriesList}>
            {dataCategories.map(({ name, classifiers, _id, Icon, selected }) => (
              <FmsdCategoriesItem
                key={_id}
                id={_id}
                name={name}
                classifiers={classifiers}
                Icon={Icon}
                selected={selected}
                onSelect={toggleCategory}
              />
            ))}
          </div>
          <button
            className={regulationsButton}
            onClick={switchToRegulations}
            data-aid={generateDataAid('FmsdCategories', ['switchToRegulations', 'button'])}
          >
            {t('regulationsViewButtonText')}
          </button>
        </div>
      )}
    </>
  );
};
