import { httpService } from './httpService';
import {
  GenericCloudConnectorFieldList,
  GenericCloudConnectorSubmitFieldsResponse,
} from '../views/GenericCloudConnectorLoginPage/genericCloudConnectorTypes';

const generateLWC = (id: string): string => {
  return `oauth/generic-cloud/${id}/fields`;
};

const sendGetLRequestForLWCLoginFields = async (id: string): Promise<GenericCloudConnectorFieldList> => {
  const response = await httpService.fetch(generateLWC(id));
  return response.data;
};

const sendPostRequestForLWCLoginFieldsValues = async (
  id: string,
  values: any,
): Promise<GenericCloudConnectorSubmitFieldsResponse> => {
  const response = await httpService.post(generateLWC(id), values);
  return response.data;
};

export const getLWCLoginFieldsByConnectorId = async (connectorId: string): Promise<GenericCloudConnectorFieldList> => {
  return sendGetLRequestForLWCLoginFields(connectorId);
};

export const submitLWCLoginFieldValuesForConnectorId = async (
  values: GenericCloudConnectorFieldList,
  connectorId: string,
): Promise<GenericCloudConnectorSubmitFieldsResponse> => {
  return sendPostRequestForLWCLoginFieldsValues(connectorId, values);
};
