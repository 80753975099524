import React, { useState } from 'react';
import { useUpdateDsCollaborator } from '../../hooks/useUpdateDsCollaborator';
import { BigidDropdownOption, BigidDropdownValue } from '@bigid-ui/components';
import { notificationService } from '../../../../../services/notificationService';
import { useLocalTranslation } from '../../translations';
import { DataSourceConnectionCollaborationTypeField } from './DataSourceConnectionCollaborationTypeField';
import { capitalize } from 'lodash';
import { CollaborationEventType } from '../../types';

type DataSourceConnectionCollaborationTypeCellProps = {
  dataAid?: string;
  options: BigidDropdownOption[];
  id: string;
  source: string;
  dsTypeLabel: string;
  defaultValue: string;
  onChange?: (options: BigidDropdownOption[]) => void;
};

export const DataSourceConnectionCollaborationTypeCell = ({
  dataAid = 'DataSourceConnectionCollaborationTypeCell',
  options,
  id,
  source,
  dsTypeLabel,
  defaultValue,
  onChange,
}: DataSourceConnectionCollaborationTypeCellProps): JSX.Element => {
  const [value, setValue] = useState<BigidDropdownValue>(() => [
    {
      id: defaultValue,
      value: defaultValue,
      displayValue: capitalize(defaultValue),
    },
  ]);
  const { t } = useLocalTranslation();

  const { mutateAsync: updateCollaborator } = useUpdateDsCollaborator({
    mutation: {
      onError: () => notificationService.error(t('collaboration.updateError')),
      onSuccess: () => notificationService.success(t('collaboration.updateSuccess')),
    },
  });

  const handleChangeCollaborationType = async (options: BigidDropdownOption[]) => {
    // @info noop
    await updateCollaborator(
      {
        dataSource: source,
        data: {
          eventType: CollaborationEventType.UPDATE_COLLABORATORS,
          additionalProperties: {
            uiBaseUrl: window.location.origin,
            dsTypeLabel: dsTypeLabel,
            collaboratorsInfo: [
              {
                email: id,
              },
            ],
          },
        },
      },
      { onSuccess: () => setValue(options) },
    );

    onChange?.(options);
  };
  return (
    <DataSourceConnectionCollaborationTypeField
      dataAid={dataAid}
      onSelect={handleChangeCollaborationType}
      options={options}
      value={value}
    ></DataSourceConnectionCollaborationTypeField>
  );
};
