import React, { FC } from 'react';
import { Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import BigidLogoNoText from '../../assets/icons/BigidLogoNoText.svg';

const useStyles = makeStyles({
  modalContent: {
    display: 'flex',
    justifyContent: 'center',
    fontSize: 16,
    paddingLeft: 48,
    paddingRight: 48,
    paddingBottom: 48,
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'center',
  },
  title: {
    fontSize: 22,
    padding: 16,
  },
});

export const StartOnboardingModalContent: FC = () => {
  const classes = useStyles({});

  return (
    <div className={classes.modalContent} data-aid="StartOnboardingModalContent">
      <BigidLogoNoText />
      <Typography variant={'h6'} className={classes.title}>
        Welcome to BigID.
      </Typography>
      To get started, please set up a connection between BigID and your data.
    </div>
  );
};
