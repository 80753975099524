import React from 'react';
import { MtMigrationWrapper } from '../react/components/MtMigrationEmptyState/MtMigrationWrapper';
import { MtNotSupportedViews } from '../react/components/MtMigrationEmptyState/mtMigrationEmptyStateUtils';
import { IdentityLineageComponent } from './identity-lineage.component';
import { getApplicationPreference } from '../react/services/appPreferencesService';

export const IdentityLineageMtWrapper = () => {
  const mtCorrelation = getApplicationPreference('MT_CORRELATION_FF_ENABLED');
  return (
    <>
      {mtCorrelation && <IdentityLineageComponent />}
      {!mtCorrelation && (
        <MtMigrationWrapper viewId={MtNotSupportedViews.CORRELATION}>
          <IdentityLineageComponent />
        </MtMigrationWrapper>
      )}
    </>
  );
};
