import React, { useEffect, useState } from 'react';
import {
  BigidTabsAndContent,
  BigidTabsAndContentProps,
  // BigidDropdownValue,
  // entityEventsEmitter,
  BigidHorizontalBarItem,
} from '@bigid-ui/components';
import { $state } from '../../services/angularServices';
import { CONFIG } from '../../../config/common';
import makeStyles from '@mui/styles/makeStyles';
import { UIView } from '@uirouter/react';
import { ScansUITrackingEvent, trackEventScansView } from './ScansEventTrackerUtils';
import { Card, styled } from '@mui/material';
import { OPTIONS_FILTER } from './ScansDashboard/ScansDashboard';
// import * as scanUtils from './ScanUtils';
// import { reduce } from 'lodash';
// import { generateDataAid } from '@bigid-ui/utils';
import { PieChartSectionData } from './ScanInsights/ScanInsightTypes';
// import { notificationService } from '../../../react/services/notificationService';
// import { userPreferencesService } from '../../../react/services/userPreferencesService';
import { useLocalTranslation } from './translations';

const pageBottomMargin = 10;
const topSectionHeight = 17;
// const topSectionHeight = 257;
const SCANS_DASHBOARD_PREFERENCE_NAME = 'scansDashboard';

const ScansContainerContent = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  height: `calc(100% - ${pageBottomMargin}px)`,
  width: '100%',
  overflow: 'hidden',
  boxShadow: theme.vars.tokens.bigid.shadow10,
  border: theme.vars.tokens.bigid.borderDefault,
}));

const ScansCardContent = styled(Card)({
  // height: `calc(100% - ${topSectionHeight}px)`,
  height: `calc(100% - ${topSectionHeight}px)`,
  boxShadow: 'none',
});

const useStyles = makeStyles({
  contentContainer: {
    overflow: 'hidden',
    display: 'none',
  },
  tabsContainer: {
    '& [class*="MuiTabs-root"]': {
      padding: '16px 0 0 24px',
    },
  },
});

// const preferencesDashboardScansToUpdate = (daysFilter: object) => ({
//   preference: SCANS_DASHBOARD_PREFERENCE_NAME,
//   data: { daysFilter },
// });

const getScansContainerTabs = () => {
  return [CONFIG.states.SCANS_NEW_SCANS_IN_PROGRESS, CONFIG.states.SCANS_NEW_SCANS_COMPLETED];
};

interface IInitScansDashboardsData {
  horizontalBarData: BigidHorizontalBarItem[];
  pieChartData: PieChartSectionData[];
  horizontalBarTitle: string;
  isLoading: boolean;
}

const initScansDashboardsData: IInitScansDashboardsData = {
  horizontalBarData: [],
  pieChartData: [],
  horizontalBarTitle: '',
  isLoading: true,
};

export const ScansContainer: React.FC = () => {
  const scanTabs = getScansContainerTabs();
  const [selectedTabIndex, setSelectedTabIndex] = useState<number>(scanTabs.indexOf($state.$current.name) || 0);
  // const [scansDashboardsData, setScansDashboardsData] = useState<IInitScansDashboardsData>(initScansDashboardsData);
  // const [daysFilter, setDaysFilter] = useState(OPTIONS_FILTER[0]);
  // const [isFinishedLoadUserPreferences, setIsFinishedLoadUserPreferences] = useState(false);
  const { contentContainer, tabsContainer } = useStyles({});
  const { t } = useLocalTranslation('scansContainer');

  // useEffect(() => {
  //   initFilterByUserPreferences();

  //   const unregister = entityEventsEmitter.addEventListener(scanUtils.SCANS_PAGE_UPDATED, async () => {
  //     const { data } = (await userPreferencesService.get(SCANS_DASHBOARD_PREFERENCE_NAME)) || {};
  //     onUpdateScanMetrics(data.daysFilter.value);
  //   });
  //   return () => unregister();
  // }, []);

  useEffect(() => {
    if ($state.current.name === CONFIG.states.SCANS_NEW_SCANS) {
      $state.go(CONFIG.states.SCANS_NEW_SCANS_IN_PROGRESS);
    }
  }, []);

  // useEffect(() => {
  //   if (isFinishedLoadUserPreferences) {
  //     onUpdateScanMetrics(daysFilter.value);
  //   }
  // }, [daysFilter, isFinishedLoadUserPreferences]);

  // const initFilterByUserPreferences = async () => {
  //   const { data } = (await userPreferencesService.get(SCANS_DASHBOARD_PREFERENCE_NAME)) || {};
  //   if (data && data.daysFilter) {
  //     setDaysFilter(data.daysFilter);
  //   } else {
  //     const preferencesToUpdate = preferencesDashboardScansToUpdate(daysFilter);
  //     await userPreferencesService.add(preferencesToUpdate);
  //   }
  //   setIsFinishedLoadUserPreferences(true);
  // };

  // const onUpdateScanMetrics = async (days: number) => {
  //   try {
  //     setScansDashboardsData((prevScansDashboard: IInitScansDashboardsData) => ({
  //       ...prevScansDashboard,
  //       isLoading: true,
  //     }));
  //     const scanMetrics: IMetricsByDays = await scanUtils.getScanMetricsByDays(days);

  //     const horizontalBarDataForParentScans = scanUtils.buildHorizontalBarData(scanMetrics.parentScansMap);
  //     const piiChartDataForSubScans = scanUtils.buildPiiChartData(scanMetrics.subScansMap);
  //     const piiChartDataForDataSources = scanUtils.buildPiiChartData(scanMetrics.dataSourcesMap);

  //     const pieChartSectionObjects: PieChartSectionObject[] = [
  //       {
  //         type: t('subScansType'),
  //         title: t('subScansTitle'),
  //         data: piiChartDataForSubScans,
  //         total: getTotalForDataCharts(piiChartDataForSubScans),
  //         disableFiltering: getTotalForDataCharts(piiChartDataForSubScans) === 0,
  //       },
  //       {
  //         type: t('dataSourceScansType'),
  //         title: t('dataSourceScansTitle'),
  //         data: piiChartDataForDataSources,
  //         total: getTotalForDataCharts(piiChartDataForDataSources),
  //         disableFiltering: getTotalForDataCharts(piiChartDataForDataSources) === 0,
  //       },
  //     ];

  //     const pieChartData: PieChartSectionData[] = pieChartSectionObjects.map(section => ({
  //       dataAid: generateDataAid('ScansDashboards', [section.type, 'states']),
  //       data: section.data,
  //       disableFiltering: section.disableFiltering,
  //       entityName: t('total'),
  //       showZeroedData: true,
  //       showZeroedTotal: true,
  //       total: section.total,
  //       title: section.title,
  //       withTooltips: true,
  //       dataAidLabels: generateDataAid('ScansDashboards', [section.type, 'states', 'labels']),
  //     }));

  //     setScansDashboardsData((prevScansDashboard: IInitScansDashboardsData) => ({
  //       ...prevScansDashboard,
  //       pieChartData,
  //       horizontalBarData: horizontalBarDataForParentScans,
  //       horizontalBarTitle: t('parentScans'),
  //       isLoading: false,
  //     }));
  //   } catch (error) {
  //     notificationService.error(error?.response?.data?.message || t('failedToLoad'));
  //     console.error(error);
  //     setScansDashboardsData((prevScansDashboard: IInitScansDashboardsData) => ({
  //       ...prevScansDashboard,
  //       pieChartData: [],
  //       horizontalBarData: [],
  //       isLoading: false,
  //     }));
  //   }
  // };

  // const onFilterChang = async ([daysFilterData]: BigidDropdownValue) => {
  //   setDaysFilter(daysFilterData);
  //   const preferencesToUpdate = preferencesDashboardScansToUpdate(daysFilterData);
  //   await userPreferencesService.update(preferencesToUpdate);
  // };

  // const getTotalForDataCharts = (data: any) => {
  //   return reduce(data, (total, item) => (item.value as number) + total, 0);
  // };

  const tabsAndContentConfig: BigidTabsAndContentProps = {
    classes: { tabsContainer, contentContainer },
    tabProps: {
      size: 'large',
      tabs: [
        {
          label: t('inProgressLabel'),
          bi: {
            eventType: ScansUITrackingEvent.SCAN_TAB_CLICK,
            eventData: {
              tabName: t('inProgressLabel'),
            },
          },
        },
        {
          label: t('completedLabel'),
          bi: {
            eventType: ScansUITrackingEvent.SCAN_TAB_CLICK,
            eventData: {
              tabName: t('completedLabel'),
            },
          },
        },
      ],
      onChange: tabIndex => {
        const state = scanTabs[tabIndex];
        trackEventScansView(ScansUITrackingEvent.SCAN_TAB_CLICK, { tabName: state });
        $state.go(state);
        setSelectedTabIndex(tabIndex);
      },
      selectedIndex: selectedTabIndex,
    },
  };

  return (
    <ScansContainerContent>
      <ScansCardContent>
        <BigidTabsAndContent {...tabsAndContentConfig} />
        <UIView />
      </ScansCardContent>
    </ScansContainerContent>
  );
};
