import {
  BigidAdvancedToolbarDateRangeFilter,
  BigidAdvancedToolbarDropdownFilter,
  BigidAdvancedToolbarFilterTypes,
} from '@bigid-ui/components';
import { omit } from 'lodash';
import {
  AggregationFilterConfig,
  AggregationFilterOperand,
  AggregationType,
  NonAggregationType,
} from '../catalogDiscoveryTypes';
import { parseDateFilterOutputToQueryString } from './scanDate';
import { getSensitivityClassificationLeftOperand } from './sensitivityClassification';
import { getTagsLeftOperand } from './tags';
import { capitaliseFilterOptionDisplayValue } from './utils';

/**
 * Max number of filter options to be fetched
 */
export const fetchAllFilterOptionsLimit = 1e3;

export const mapAggregationToFilterTranslationKey: { [key in AggregationType | NonAggregationType]?: string } = {
  [AggregationType.DATA_SOURCE_NAME]: 'dataSourceName',
  [AggregationType.DATA_SOURCE_TYPE]: 'dataSourceType',
  [AggregationType.DATA_SOURCE_LOCATION]: 'dataSourceLocation',
  [AggregationType.OBJECT_OWNER]: 'objectOwner',
  [AggregationType.CLOUD_TYPE]: 'cloudType',
  [AggregationType.FILE_TYPE]: 'fileType',
  [AggregationType.VIOLATED_POLICY]: 'policy',
  [AggregationType.DATA_FORMAT]: 'dataFormat',
  [AggregationType.APPLICATION]: 'application',
  [AggregationType.TAGS]: 'tags',
  [AggregationType.ATTRIBUTE_CATEGORY]: 'attributeName',
  [AggregationType.ATTRIBUTE_NAME]: 'attributeName',
  [AggregationType.ATTRIBUTE_TYPE]: 'attributeType',
  [AggregationType.HAS_DUPLICATES]: 'hasDuplicates',
  [AggregationType.HAS_OPEN_ACCESS]: 'hasOpenAccess',
  [AggregationType.SENSITIVITY_FILTER]: 'sensitivityFilter',
  [AggregationType.OBJECT_STATUS]: 'objectStatus',
  [AggregationType.OBJECT_SCAN_STATUS]: 'objectScanStatus',
  [AggregationType.CONTAINER_NAME]: 'containerName',
  [AggregationType.RISK]: 'risk',
  [AggregationType.ACCESS_TYPE_FILTER]: 'accessTypeFilter',
  [NonAggregationType.SCAN_DATE]: 'scanDate',
  [NonAggregationType.LAST_ACCESS_DATE]: 'lastAccessDate',
  [NonAggregationType.MODIFIED_DATE]: 'modifiedDate',
};

export const aggregationFilterConfig: {
  [key in NonAggregationType | AggregationType]?: AggregationFilterConfig;
} = {
  [AggregationType.DATA_SOURCE_NAME]: {
    type: BigidAdvancedToolbarFilterTypes.DROPDOWN,
    id: AggregationType.DATA_SOURCE_NAME,
    field: AggregationFilterOperand.DATA_SOURCE_NAME,
    operator: 'in',
    options: [],
    disabled: true,
  },
  [AggregationType.DATA_SOURCE_TYPE]: {
    type: BigidAdvancedToolbarFilterTypes.DROPDOWN,
    id: AggregationType.DATA_SOURCE_TYPE,
    field: AggregationFilterOperand.DATA_SOURCE_TYPE,
    operator: 'in',
    options: [],
    disabled: false,
  },
  [AggregationType.DATA_SOURCE_LOCATION]: {
    type: BigidAdvancedToolbarFilterTypes.DROPDOWN,
    id: AggregationType.DATA_SOURCE_LOCATION,
    field: AggregationFilterOperand.DATA_SOURCE_LOCATION,
    operator: 'in',
    options: [],
    disabled: true,
  },
  [AggregationType.OBJECT_OWNER]: {
    type: BigidAdvancedToolbarFilterTypes.DROPDOWN,
    id: AggregationType.OBJECT_OWNER,
    field: AggregationFilterOperand.OBJECT_OWNER,
    operator: 'in',
    options: [],
    disabled: true,
  },
  [AggregationType.OBJECT_STATUS]: {
    type: BigidAdvancedToolbarFilterTypes.DROPDOWN,
    id: AggregationType.OBJECT_STATUS,
    field: AggregationFilterOperand.OBJECT_STATUS,
    operator: 'equal',
    options: [],
    disabled: true,
    isSearchable: false,
    isBoolean: true,
  },
  [AggregationType.CLOUD_TYPE]: {
    type: BigidAdvancedToolbarFilterTypes.DROPDOWN,
    id: AggregationType.CLOUD_TYPE,
    field: AggregationFilterOperand.CLOUD_TYPE,
    operator: 'in',
    options: [],
    disabled: false,
  },
  [AggregationType.FILE_TYPE]: {
    type: BigidAdvancedToolbarFilterTypes.DROPDOWN,
    id: AggregationType.FILE_TYPE,
    field: AggregationFilterOperand.FILE_TYPE,
    operator: 'in',
    options: [],
    disabled: true,
  },
  [AggregationType.DATA_FORMAT]: {
    type: BigidAdvancedToolbarFilterTypes.DROPDOWN,
    id: AggregationType.DATA_FORMAT,
    field: AggregationFilterOperand.DATA_FORMAT,
    operator: 'in',
    options: [],
    disabled: true,
    isSearchable: false,
    getFilterOptionsPreprocessed: capitaliseFilterOptionDisplayValue,
  },
  [AggregationType.APPLICATION]: {
    type: BigidAdvancedToolbarFilterTypes.DROPDOWN,
    id: AggregationType.APPLICATION,
    field: AggregationFilterOperand.APPLICATION,
    operator: 'in',
    options: [],
    disabled: true,
  },
  [AggregationType.TAGS]: {
    type: BigidAdvancedToolbarFilterTypes.DROPDOWN,
    id: AggregationType.TAGS,
    field: AggregationFilterOperand.TAGS,
    operator: 'in',
    options: [],
    optionsDisplayLimit: fetchAllFilterOptionsLimit,
    disabled: false,
    isSearchable: false,
    leftOperandComposerFunc: getTagsLeftOperand,
    hasNestedItems: true,
  },
  [AggregationType.SENSITIVITY_FILTER]: {
    type: BigidAdvancedToolbarFilterTypes.DROPDOWN,
    id: AggregationType.SENSITIVITY_FILTER,
    field: AggregationFilterOperand.SENSITIVITY_FILTER,
    operator: 'in',
    options: [],
    disabled: false,
    isSearchable: false,
    leftOperandComposerFunc: getSensitivityClassificationLeftOperand,
    hasNestedItems: true,
  },
  [AggregationType.ATTRIBUTE_CATEGORY]: {
    type: BigidAdvancedToolbarFilterTypes.DROPDOWN,
    id: AggregationType.ATTRIBUTE_CATEGORY,
    field: AggregationFilterOperand.ATTRIBUTE_CATEGORY,
    operator: 'in',
    options: [],
    disabled: true,
    getUserSearchQueryPreprocessed: (value: string) => {
      return value ? `/${value}/` : undefined;
    },
  },
  //NOTE: temporarily disabled until further notice from @maorpichadze
  // [AggregationType.ATTRIBUTE_TYPE]: {
  //   type: BigidAdvancedToolbarFilterTypes.DROPDOWN,
  //   id: AggregationType.ATTRIBUTE_TYPE,
  //   field: AggregationFilterOperand.ATTRIBUTE_TYPE,
  //   operator: 'in',
  //   options: [],
  //   disabled: true,
  //   isSearchable: false,
  // },
  [AggregationType.HAS_DUPLICATES]: {
    type: BigidAdvancedToolbarFilterTypes.DROPDOWN,
    id: AggregationType.HAS_DUPLICATES,
    field: AggregationFilterOperand.HAS_DUPLICATES,
    operator: 'equal',
    options: [],
    disabled: true,
    isSearchable: false,
    isBoolean: true,
  },
  //NOTE: temporarily disabled until further notice from @mpichadze
  // [AggregationType.HAS_OPEN_ACCESS]: {
  //   type: BigidAdvancedToolbarFilterTypes.DROPDOWN,
  //   id: AggregationType.HAS_OPEN_ACCESS,
  //   field: AggregationFilterOperand.HAS_OPEN_ACCESS,
  //   operator: 'equal',
  //   options: [],
  //   disabled: true,
  //   isSearchable: false,
  //   isBoolean: true,
  // },
  [AggregationType.OBJECT_SCAN_STATUS]: {
    type: BigidAdvancedToolbarFilterTypes.DROPDOWN,
    id: AggregationType.OBJECT_SCAN_STATUS,
    field: AggregationFilterOperand.OBJECT_SCAN_STATUS,
    operator: 'in',
    options: [],
    disabled: false,
    isSearchable: false,
  },
  [AggregationType.VIOLATED_POLICY]: {
    type: BigidAdvancedToolbarFilterTypes.DROPDOWN,
    id: AggregationType.VIOLATED_POLICY,
    field: AggregationFilterOperand.POLICY,
    operator: 'in',
    options: [],
    optionsDisplayLimit: fetchAllFilterOptionsLimit,
    isSearchable: false,
    disabled: true,
  },
  [NonAggregationType.SCAN_DATE]: {
    type: BigidAdvancedToolbarFilterTypes.DATE_RANGE,
    id: NonAggregationType.SCAN_DATE,
    field: AggregationFilterOperand.SCAN_DATE,
    operator: 'equal',
    shouldNotReloadOnChange: true,
    options: {
      currentRangeOptionSelected: 'none',
      pickersState: {
        dates: {
          from: null,
          until: null,
        },
        currentMode: 'from',
      },
    },
    disabled: true,
    parseFilterToQueryFunc: parseDateFilterOutputToQueryString,
  },
  [NonAggregationType.LAST_ACCESS_DATE]: {
    type: BigidAdvancedToolbarFilterTypes.DATE_RANGE,
    id: NonAggregationType.LAST_ACCESS_DATE,
    field: AggregationFilterOperand.LAST_ACCESS_DATE,
    operator: 'equal',
    shouldNotReloadOnChange: true,
    options: {
      currentRangeOptionSelected: 'none',
      pickersState: {
        dates: {
          from: null,
          until: null,
        },
        currentMode: 'from',
      },
    },
    disabled: true,
    parseFilterToQueryFunc: parseDateFilterOutputToQueryString,
  },
  [NonAggregationType.MODIFIED_DATE]: {
    type: BigidAdvancedToolbarFilterTypes.DATE_RANGE,
    id: NonAggregationType.MODIFIED_DATE,
    field: AggregationFilterOperand.MODIFIED_DATE,
    operator: 'equal',
    shouldNotReloadOnChange: true,
    options: {
      currentRangeOptionSelected: 'none',
      pickersState: {
        dates: {
          from: null,
          until: null,
        },
        currentMode: 'from',
      },
    },
    disabled: true,
    parseFilterToQueryFunc: parseDateFilterOutputToQueryString,
  },
  [AggregationType.CONTAINER_NAME]: {
    type: BigidAdvancedToolbarFilterTypes.DROPDOWN,
    id: AggregationType.CONTAINER_NAME,
    field: AggregationFilterOperand.CONTAINER_NAME,
    operator: 'in',
    options: [],
    disabled: true,
  },
  [AggregationType.RISK]: {
    type: BigidAdvancedToolbarFilterTypes.DROPDOWN,
    id: AggregationType.RISK,
    field: AggregationFilterOperand.RISK,
    operator: 'in',
    options: [],
    disabled: true,
    isSearchable: false,
    getFilterOptionsPreprocessed: capitaliseFilterOptionDisplayValue,
  },
  [AggregationType.ACCESS_TYPE_FILTER]: {
    type: BigidAdvancedToolbarFilterTypes.DROPDOWN,
    id: AggregationType.ACCESS_TYPE_FILTER,
    field: AggregationFilterOperand.ACCESS_TYPE_FILTER,
    operator: 'in',
    options: [],
    disabled: true,
    isSearchable: false,
    leftOperandComposerFunc: getTagsLeftOperand,
  },
};

export function getAdvancedToolbarFilterConsumableConfig<
  T extends BigidAdvancedToolbarDateRangeFilter | BigidAdvancedToolbarDropdownFilter,
>(filterId: AggregationType | NonAggregationType): Omit<T, 'asyncOptionsFetch' | 'title' | 'value'> {
  return omit(aggregationFilterConfig[filterId], [
    'isBoolean',
    'hasNestedItems',
    'leftOperandComposerFunc',
    'parseFilterToQueryFunc',
    'getUserSearchQueryPreprocessed',
    'getFilterOptionsPreprocessed',
  ]) as T;
}
