import { TooltipRenderProps } from '@bigid-ui/guided-tour';
import { BigidTourMouseClickIcon } from '@bigid-ui/icons';
import { keyframes, styled } from '@mui/material';
import React, { FC } from 'react';

const scaleUpDown = keyframes`
  0%, 100% {
    transform: scale(0.8);
    opacity: 0.8;
  }
  50% {
    transform: scale(1);
    opacity: 1;
  }
`;

const TooltipContainer = styled('div')`
  animation: ${scaleUpDown} 2s ease-in-out infinite;
`;

export const ActionableInsightsMouseClickToolTip: FC<TooltipRenderProps> = ({
  step: {
    styles: { tooltipContainer },
  },
  tooltipProps,
}: TooltipRenderProps) => {
  return (
    <TooltipContainer style={{ ...tooltipContainer }} {...tooltipProps}>
      <BigidTourMouseClickIcon />
    </TooltipContainer>
  );
};
