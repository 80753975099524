import { HeadersObject, QueryParamsAndDataObject, httpService } from '../services/httpService';
import type { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import type { QueryFunctionContext } from 'react-query';
import type { IPromise } from 'angular';

export type ErrorType<Error> = AxiosError<Error>;

export type BodyType<BodyData> = BodyData;

export type HttpConfigParams = {
  query: QueryFunctionContext;
};

type HttpFetchMethodType = typeof httpService.fetch;

type HttpServiceMethodType = HttpFetchMethodType extends (...args: infer A) => unknown
  ? <T>(...args: [...A, QueryParamsAndDataObject]) => IPromise<AxiosResponse<T, unknown>>
  : never;

const DEFAULT_REQUEST_METHOD = httpService.fetch;

const httpServiceMethodMapper: Record<string, HttpServiceMethodType> = {
  get: httpService.fetch,
  post: async (resourcePath, params, _headers, _responseType, data) => httpService.post(resourcePath, data, params),
  put: async (resourcePath, params, _headers, _responseType, data) => httpService.put(resourcePath, data, params),
  delete: async (resourcePath, _params, _headers, _responseType, data) => httpService.delete(resourcePath, data),
};

const mapMethodToHttpServiceRequest = (method: string): HttpServiceMethodType =>
  httpServiceMethodMapper[method] ?? DEFAULT_REQUEST_METHOD;

export const httpRequest = async <T = unknown>(config: AxiosRequestConfig): Promise<T> => {
  const request = mapMethodToHttpServiceRequest(config.method);
  const { data } = await request<T>(
    config.url,
    config.params,
    config.headers as unknown as HeadersObject,
    config.responseType,
    config.data,
  );

  return data;
};
