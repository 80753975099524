import {
  BigidFormFieldText,
  BigidFormFieldDatePicker,
  BigidFormFieldRadio,
  BigidFormFieldNumber,
  BigidFormFieldSelect,
  BigidFormFieldSwitch,
  BigidFormFieldPassword,
} from '@bigid-ui/components';
import { FormCustomParametersField } from '../fields/FormCustomParametersField';
import { FormArrayField } from '../fields/FormArrayField';
import { FormObjectField } from '../fields/FormObjectField';
import { FormFieldAction } from '../fields/FormFieldAction';
import { FormJsonField } from '../fields/FormJsonField';
import { FormTagsField } from '../fields/FormTagsField';
import { FormVerticalObjectField } from '../fields/FormVerticalObjectField';
import { BigidFormFieldSwitchWithDynamicLabel } from '../fields/BigidFormFieldSwitchWithDynamicLabel';

import { DsTypesEnum } from '../types';

export const BULK_UPDATE_FIELD_COMPONENT_MAP: Record<DsTypesEnum, React.FC<any>> = {
  [DsTypesEnum.string]: BigidFormFieldText,
  [DsTypesEnum.date]: BigidFormFieldDatePicker,
  [DsTypesEnum.options]: BigidFormFieldRadio,
  [DsTypesEnum.number]: BigidFormFieldNumber,
  [DsTypesEnum.password]: BigidFormFieldPassword,
  [DsTypesEnum.boolean]: BigidFormFieldSwitchWithDynamicLabel,
  [DsTypesEnum.stringBoolean]: BigidFormFieldSwitch,
  [DsTypesEnum.multiStringBoolean]: BigidFormFieldSwitch,
  [DsTypesEnum.object]: FormObjectField,
  [DsTypesEnum.verticalObject]: FormVerticalObjectField,
  [DsTypesEnum.array]: FormArrayField,
  [DsTypesEnum.email]: BigidFormFieldText,
  [DsTypesEnum.stringSelect]: BigidFormFieldSelect,
  [DsTypesEnum.stringLong]: BigidFormFieldText,
  [DsTypesEnum.customFieldsArray]: FormCustomParametersField,
  [DsTypesEnum.objectSelect]: BigidFormFieldSelect,
  [DsTypesEnum.action]: FormFieldAction,
  [DsTypesEnum.json]: FormJsonField,
  [DsTypesEnum.tags]: FormTagsField,
};
