import React, { FC } from 'react';
import { SearchResult } from '../../../types';
import { styled } from '@mui/material/styles';
import { BigidBody1, BigidErrorBoundary } from '@bigid-ui/components';
import { DataSourceIconByDsType } from '@bigid-ui/icons';
import { CONFIG } from '../../../../../../../config/common';
import { $state } from '../../../../../../services/angularServices';
import { processHighlight } from '../utils';
import { sendDataExplorerQuickSearchListItemClickBiAnalytics } from '../../../../services/analytics';
import { mapFieldValueToBiqlString } from '../../../../../../services/filtersToBiqlService';
import { generateDataAid } from '@bigid-ui/utils';

export const CatalogListItemContainer = styled('li')`
  display: flex;
  list-style-type: none;
  gap: 16px;
  justify-content: space-between;
  cursor: pointer;
`;

export const DataSourceContainer = styled('div')`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const StyledName = styled(BigidBody1)`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const StyledDataSource = styled(BigidBody1)`
  white-space: nowrap;
`;

type CatalogListItemProps = {
  result: SearchResult;
  dataAid?: string;
};

export const CatalogListItem: FC<CatalogListItemProps> = ({ result, dataAid = 'CatalogListItem' }) => {
  const { fields, id, highlightedFields, name } = result;

  const nameField = highlightedFields.find(field => field.name === 'name');
  const sourceField = highlightedFields.find(field => field.name === 'source');

  const handleOnClick = () => {
    sendDataExplorerQuickSearchListItemClickBiAnalytics({ entityName: 'recentlyViewed', id });
    $state.go(CONFIG.states.CATALOG_PREVIEW, { id: encodeURIComponent(id), name });
  };

  const computedName = nameField ? processHighlight(nameField) : fields?.name;
  const computedSource = sourceField ? processHighlight(sourceField) : fields?.source;

  const handleOnError = (error: Error) => {
    console.error('Error in CatalogListItem', error);
  };

  return (
    <BigidErrorBoundary onError={handleOnError}>
      <CatalogListItemContainer onClick={handleOnClick} data-aid={generateDataAid(dataAid, ['container'])}>
        <StyledName>{computedName}</StyledName>
        <DataSourceContainer>
          <DataSourceIconByDsType dataSourceType={fields?.source_type} width={24} />
          <StyledDataSource>{computedSource}</StyledDataSource>
        </DataSourceContainer>
      </CatalogListItemContainer>
    </BigidErrorBoundary>
  );
};
