import { useState, useMemo } from 'react';
import { BigidTabsAndContentProps } from '@bigid-ui/components';
import makeStyles from '@mui/styles/makeStyles';
import { getApplicationPreference } from '../../../../services/appPreferencesService';
import { $state, $stateParams } from '../../../../services/angularServices';
import { CONFIG } from '../../../../../config/common';
import { SarProfileTabNames } from '../../../DSAR/SarProfileSettings/SarProfileSettingsTypes';

const useStyles = makeStyles({
  contentContainer: {
    overflow: 'hidden',
    display: 'none',
  },
  tabsContainer: {
    marginBottom: 0,
    '& [class*="MuiTabs-root"]': {
      padding: '16px 0 0 24px',
    },
  },
});

const TABS = [
  {
    urlParam: 'ds',
    label: 'Data Sources',
  },
  {
    urlParam: 'discovery',
    label: 'Automatic Onboarding ',
  },
];

export const NEW_AUTO_DISCOVERY_FF_NAME = 'SHOW_NEW_AUTO_DISCOVERY_UI_FF';

const getInitIndex = (tabInUrl: string) => {
  const indexFromUrl = TABS.findIndex(({ urlParam }) => tabInUrl === urlParam);
  return indexFromUrl > -1 ? indexFromUrl : 0;
};

export const useDataSourceTabs = () => {
  const { tab } = $stateParams;
  const { contentContainer, tabsContainer } = useStyles({});
  const [selectedTabIndex, setSelectedTabIndex] = useState<number>(getInitIndex(tab));

  const tabsAndContentConfig: BigidTabsAndContentProps = useMemo(
    () => ({
      classes: { tabsContainer, contentContainer },
      tabProps: {
        size: 'large',
        tabs: TABS,
        onChange: tabIndex => {
          $state.transitionTo(
            CONFIG.states.DATA_SOURCE_CONNECTIONS,
            { ...$state.params, tab: TABS[tabIndex].urlParam },
            { location: true, inherit: true, relative: $state.$current, notify: false },
          );
          setSelectedTabIndex(tabIndex);
        },
        selectedIndex: selectedTabIndex,
      },
    }),
    [contentContainer, selectedTabIndex, tabsContainer],
  );
  const isTabs = useMemo(() => getApplicationPreference(NEW_AUTO_DISCOVERY_FF_NAME), []);

  return {
    isTabs,
    tabsAndContentConfig,
    selectedTabIndex,
    setSelectedTabIndex,
  };
};
