import angular from 'angular';
import { convertToAngular } from '../../../common/services/convertToAngular';
import React, { FC, useRef, useState } from 'react';
import { BigidMenu, BigidMenuItem, SecondaryButton } from '@bigid-ui/components';

export interface BigidCreateClassifiersMenuProps {
  onCreateRegularClassifier: () => void;
  onCreateNerClassifier: () => void;
  isNerFeatureFlag: boolean;
}

export const BigidCreateClassifiersMenu: FC<BigidCreateClassifiersMenuProps> = ({
  onCreateRegularClassifier,
  onCreateNerClassifier,
  isNerFeatureFlag,
}) => {
  const [open, setOpen] = useState(false);
  const iconRef = useRef();
  const handleClick = () => {
    if (isNerFeatureFlag) {
      setOpen(!open);
    } else {
      onCreateRegularClassifier();
    }
  };
  const handleClose = () => setOpen(false);
  const menuProps = {
    open,
    onMenuClose: handleClose,
    anchorEl: iconRef.current,
  };

  const newRegexClassifierHandler = () => {
    handleClose();
    onCreateRegularClassifier();
  };

  const newNerClassifierHandler = () => {
    handleClose();
    onCreateNerClassifier();
  };

  return (
    <>
      <div ref={iconRef}>
        <SecondaryButton
          size="medium"
          onClick={handleClick}
          width="fill"
          dataAid="add-classifier-button"
          text="ADD CLASSIFIER"
        />
      </div>
      <BigidMenu {...menuProps}>
        <BigidMenuItem dataAid="new-regex-classifier" onClick={newRegexClassifierHandler}>
          Pattern Based Classifier
        </BigidMenuItem>
        <BigidMenuItem dataAid="new-ner-classifier" onClick={newNerClassifierHandler}>
          NLP/NER Classifier
        </BigidMenuItem>
      </BigidMenu>
    </>
  );
};

angular
  .module('app')
  .component(
    'classifiersCreateMenu',
    convertToAngular<BigidCreateClassifiersMenuProps>(BigidCreateClassifiersMenu, [
      'onCreateRegularClassifier',
      'onCreateNerClassifier',
      'isNerFeatureFlag',
    ]),
  );
