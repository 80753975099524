import React, { FC, useRef } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { BigidCountriesSelection } from '@bigid-ui/components';
import { generateDataAid } from '@bigid-ui/utils';

const useStyles = makeStyles({
  checkboxListContainer: {
    width: '100%',
    marginBottom: 34,
    height: 392,
    overflowY: 'scroll',
    background: 'linear-gradient(180deg, rgba(255, 255, 255, 0) 90%, rgba(61, 61, 61, 0.28) 100%)',
  },
});

export interface CountryItem {
  alpha2: string;
  label: string;
  isSelected: boolean;
  id?: React.ReactText;
}

export interface RegulationsListProps {
  countriesList: CountryItem[];
  onCheckboxChange: (id: React.ReactText, isSelected: boolean) => void;
  dataAid?: string;
  columnCount?: number;
  searchText: string;
}
export const RegulationsList: FC<RegulationsListProps> = ({
  countriesList,
  onCheckboxChange,
  dataAid,
  columnCount = 3,
  searchText,
}) => {
  const { checkboxListContainer } = useStyles();
  const filteredOptionsRef = useRef(countriesList);

  filteredOptionsRef.current = searchText
    ? countriesList.filter(regulation => regulation.label.toLowerCase().includes(searchText.toLowerCase()))
    : countriesList;

  const onCountryChange = (index: number, isSelected: boolean) => {
    onCheckboxChange(filteredOptionsRef.current[index].id, isSelected);
  };

  return (
    <div
      className={`${checkboxListContainer} bigid-scrollbar`}
      data-aid={generateDataAid('RegulationsList', [dataAid])}
    >
      <BigidCountriesSelection
        columnCount={columnCount}
        onChangeOption={onCountryChange}
        options={filteredOptionsRef.current}
      />
    </div>
  );
};
