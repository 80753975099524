import './deletion-validation-requests.scss';
import template from './deletion-validation-requests.html';
import { openSearchAttributesInfoDialog } from '../../react/components/SearchAttributesInfo/searchAttributesInfoService';
import { isPermitted } from '../../react/services/userPermissionsService';
import { DSAR_PERMISSIONS } from '@bigid/permissions';

const getCell = (link = '') => `<div class="ui-grid-cell-contents ">
    ${link}
    <span title=""
    tooltip-class="bigid-uib-tooltip-styles"
    tooltip-placement="center top"
    tooltip-append-to-body="true"
    uib-tooltip="{{COL_FIELD}}"
  >{{COL_FIELD}}</span>
</div>`;

class DeletionValidationRequestsComponent {
  constructor($http, appSettings, notificationService, $state, downloadReportService, $translate, DeleteConfirmation) {
    'ngInject';
    this.$http = $http;
    this.appSettings = appSettings;
    this.notificationService = notificationService;
    this.$state = $state;
    this.downloadReportService = downloadReportService;
    this.$translate = $translate;
    this.DeleteConfirmation = DeleteConfirmation;
  }

  initializeGrid() {
    this.isLoading = true;
    this.getDeletionValidationData()
      .then(gridData => this.prepareGridData(gridData))
      .then(updatedGraphData => this.setGridData(updatedGraphData))
      .catch(() => this.notificationService.error('An error has occurred during data retrieval'))
      .finally(() => (this.isLoading = false));
  }

  $onInit() {
    this.hasDownloadPermission = isPermitted(DSAR_PERMISSIONS.DOWNLOAD_REQUEST_REPORT.name);
    this.hasStopPermission = isPermitted(DSAR_PERMISSIONS.STOP_DELETION_VALIDATION.name);
    this.hasPersonalInfoReportPermission = isPermitted(DSAR_PERMISSIONS.READ_PERSONAL_INFO.name);
    this.initGrid([]);
    this.getDeletionValidationStatus().then(status => this.onDeletionValidationStatus({ status }));
  }

  $onChanges(changesObj) {
    const { profiles } = changesObj;
    if (profiles && profiles.currentValue) {
      this.initializeGrid();
    }
  }

  prepareGridData(graphData) {
    return this.$translate('ACCESS_REQUEST_MANAGEMENT:DELETION_VALIDATION:SCHEDULED_FOR_VALIDATION').then(
      translation => {
        graphData.forEach(row => {
          const { created_at, lastScan } = row;
          row.isScheduled = false;

          if (created_at === lastScan) {
            row.lastScan = translation;
            row.statuses_str = translation;
            row.isScheduled = true;
          } else if (!row.statuses_str) {
            row.isDeletionValidationInProgress = true;
          }
        });
        return graphData;
      },
    );
  }

  setGridData(data) {
    this.grid.data = data;
  }

  initGrid(data) {
    this.grid = {
      data,
      enableColumnMenus: false,
      enableSorting: true,
      enableHiding: false,
      enableFiltering: true,
      onRegisterApi: gridApi => {
        this.gridApi = gridApi;

        this.gridApi.core.on.filterChanged(null, () => {
          if (typeof this.uiGridScrollToTop === 'function') {
            this.uiGridScrollToTop();
          }
        });
      },
      columnDefs: [
        {
          name: 'Display Name',
          field: 'displayName',
          filterHeaderTemplate: `<ui-grid-bigid-text-filter/>`,
          cellTemplate: getCell(),
        },
        {
          name: 'Unique Id',
          displayName: 'Unique ID',
          field: 'uniqueId',
          filterHeaderTemplate: `<ui-grid-bigid-text-filter/>`,
          cellTemplate: getCell(),
        },
        {
          name: 'Profile',
          displayName: 'Profile',
          field: 'profileNameToDisplay',
          filterHeaderTemplate: `<ui-grid-bigid-text-filter/>`,
          cellTemplate: getCell(),
        },
        {
          name: 'Request Id',
          displayName: 'Request ID',
          field: 'requestId',
          minWidth: 200,
          filterHeaderTemplate: `<ui-grid-bigid-text-filter/>`,
          cellTemplate: getCell(
            `<a href ng-click="grid.appScope.$ctrl.openSearchAttributesInfo(row.entity.displayName ,row.entity.requestId)">`,
          ),
        },
        {
          name: 'Last Validated at',
          field: 'lastScan',
          filterHeaderTemplate: `<ui-grid-bigid-text-filter/>`,
          cellTemplate: getCell(),
        },
        {
          name: 'Status',
          field: 'statuses_str',
          filterHeaderTemplate: `<ui-grid-bigid-text-filter/>`,
          cellTemplate: getCell(),
        },
        {
          name: 'Records Found',
          field: 'record_count',
          width: 100,
          filterHeaderTemplate: `<ui-grid-bigid-text-filter/>`,
          cellTemplate: getCell(),
        },
        {
          name: 'Actions',
          enableSorting: false,
          enableCellEdit: false,
          enableFiltering: false,
          enableColumnMenu: false,
          width: '14%',
          maxWidth: 140,
          cellTemplate: `
              <div class="actions-container">
                <div class="three-points"></div>
                <div class="img-container"
                     ng-init="init(row.entity)" >
                  <div class="img-button img-download-button"
                       disabled="row.entity.isScheduled"
                       ng-if="!isActionDisabled && grid.appScope.$ctrl.hasDownloadPermission"
                       ng-click="grid.appScope.$ctrl.download(row.entity)"
                       tooltip-class="bigid-uib-tooltip-styles"
                       tooltip-placement="center top"
                       tooltip-append-to-body="true"
                       uib-tooltip="Download"></div>
                  <div class="img-button img-stop-validation-button"
                       ng-if="grid.appScope.$ctrl.hasStopPermission"
                       ng-click="grid.appScope.$ctrl.stopValidation(row.entity)"
                       tooltip-class="bigid-uib-tooltip-styles"
                       tooltip-placement="center top"
                       tooltip-append-to-body="true"
                       uib-tooltip="Stop Validation"></div>
                  <div class="img-button img-link-button"
                       ng-if="!isActionDisabled && grid.appScope.$ctrl.hasPersonalInfoReportPermission"
                       ng-click="grid.appScope.$ctrl.createPersonalInfoReport(row.entity)"
                       tooltip-class="bigid-uib-tooltip-styles"
                       tooltip-placement="center top"
                       tooltip-append-to-body="true"
                       uib-tooltip="{{'SUMMARY_REPORT'|translate}}"></div>
                </div>
              </div>`,
        },
      ],
    };

    this.grid.columnDefs.forEach(col => {
      col.cellTooltip = row => row.entity[col.field] || '';
    });

    this.openSearchAttributesInfo = (name, requestId) => {
      openSearchAttributesInfoDialog(name, requestId);
    };

    this.onScanrefreshdate();
  }

  onGridScrollContextReady(context) {
    if (context && context.viewport && typeof context.scrollToTop === 'function') {
      this.uiGridScrollToTop = context.scrollToTop;
    }
  }

  getDeletionValidationData() {
    return this.$http
      .get(`${this.appSettings.serverPath}/${this.appSettings.version}/sar/deletion-validations`)
      .then(result => {
        return result.data.data.sarDeletionValidation.map(item => {
          item.profileNameToDisplay = item.profileName;
          if (this.profiles && item.profileName) {
            const profile = this.profiles.find(profile => profile._id === item.profileId);
            item.profileNameToDisplay = profile ? item.profileName : '';
          }
          return item;
        });
      });
  }

  getDeletionValidationStatus() {
    return this.$http
      .get(`${this.appSettings.serverPath}/${this.appSettings.version}/sar/deletion-validations/config`)
      .then(result => result.data.configuration);
  }

  download(entity) {
    this.downloadReportService.downLoadReport(entity.requestId);
  }

  createPersonalInfoReport(entity) {
    this.$state.go('personalInfoReport', { userId: entity.requestId, targetTab: 'deletion-validation-requests' });
  }

  stopValidation(entity) {
    const { requestId } = entity;
    const modalOptions = {
      closeButtonText: 'Close',
      actionButtonText: 'Stop Validation',
      headerText: 'Stop Validation',
      bodyText: `Are you sure you wish to disable deletion validation for requestID: ${requestId}?`,
    };

    this.DeleteConfirmation.showModal({}, modalOptions).then(() => {
      return this.$http
        .delete(`${this.appSettings.serverPath}/${this.appSettings.version}/sar/deletion-validation/${requestId}`)
        .then(() => {
          this.notificationService.success('Deletion validation was disabled for this request');
          this.gridApi.grid.options.data = this.gridApi.grid.options.data.filter(row => row.requestId !== requestId);
        })
        .catch(() => this.notificationService.error('An error has occurred during data retrieval'));
    });
  }
}

angular.module('app').component('deletionValidationRequests', {
  template,
  controller: DeletionValidationRequestsComponent,
  bindings: {
    onScanrefreshdate: '&',
    onDeletionValidationStatus: '&',
    profiles: '<',
  },
});
