import 'angular-ui-bootstrap/src/modal';
import 'angular-ui-bootstrap/src/accordion';
import 'angular-ui-bootstrap/src/datepickerPopup';
import 'angular-ui-bootstrap/src/tooltip';
import 'angular-ui-bootstrap/src/popover';
import 'angular-ui-bootstrap/src/dropdown';
import 'angular-ui-bootstrap/src/tabs';
import 'angular-bootstrap-contextmenu';
import { module } from 'angular';
import { CONFIG } from './config/common';
import { convertedNgComponentsModuleName } from './common/services/convertToReact';
import { UI_ROUTER_REACT_HYBRID } from '@uirouter/react-hybrid';

const angularUiBootstrapDeps = [
  'ui.bootstrap.module.modal',
  'ui.bootstrap.module.accordion',
  'ui.bootstrap.module.datepickerPopup',
  'ui.bootstrap.module.tooltip',
  'ui.bootstrap.module.popover',
  'ui.bootstrap.module.dropdown',
  'ui.bootstrap.module.tabs',
  'ui.bootstrap.contextMenu', // <- not from core ui.bootstrap developers
];

const app = module('app', [
  ...angularUiBootstrapDeps,
  convertedNgComponentsModuleName,
  'ui.router',
  UI_ROUTER_REACT_HYBRID,
  'ngAnimate',
  'ngMessages',
  'ngSanitize',
  'ngResource',
  'ui.grid',
  'ui.grid.autoResize',
  'ui.grid.cellNav',
  'ui.grid.edit',
  'ui.grid.expandable',
  'ui.grid.exporter',
  'ui.grid.pagination',
  'ui.grid.selection',
  'ui.select',
  'angular-cron-jobs',
  'angularjs-dropdown-multiselect',
  'bcherny/ngimport',
  'focus-if',
  'LocalStorageModule',
  'ngFileUpload',
  'pascalprecht.translate',
  'rzModule',
]);

app.constant('$appConfig', CONFIG);
