import template from './importDsConnectionsModal.component.html';
import { module } from 'angular';
const app = module('app');

app.component('importDsConnectionsModal', {
  template,
  controllerAs: '$ctrl',
  controller: [
    'dataSourceConnectionsService',
    'notificationService',
    function (dataSourceConnectionsService, notificationService) {
      let importModel = this;
      importModel.filenameToImport = '';
      importModel.isNeedToBeEncrypted = false;
      importModel.importPayload = {
        fileToImport: '',
      };

      importModel.fileSelected = file => {
        importModel.importPayload.fileToImport = file;
        importModel.filenameToImport = file.name;
      };

      importModel.fileRemoved = () => {
        importModel.filenameToImport = '';
        importModel.importing = false;
        importModel.importPayload = {
          isIncludeHeaders: false,
          fileToImport: '',
        };
      };

      importModel.setIsNeedToBeEncrypted = obj => {
        importModel.isNeedToBeEncrypted = obj.importModel.isNeedToBeEncrypted;
      };

      importModel.import = () => {
        importModel.importing = true;

        let formData = new FormData();

        formData.append('fileToImport', importModel.importPayload.fileToImport);
        dataSourceConnectionsService.importDSConnectionsFile(formData, importModel.isNeedToBeEncrypted).then(
          response => {
            importModel.importing = false;
            if (response) {
              notificationService.success('File Imported Successfully');
              importModel.$close({ isImported: true });
            }
          },
          err => {
            importModel.importing = false;
            importModel.$close({ isImported: false });
            notificationService.error('An error has occurred! ' + err.data.message);
          },
        );
      };
    },
  ],
  bindings: {
    $close: '&',
    $dismiss: '&',
  },
});
