import React, { FC, useContext, useMemo } from 'react';
import { CreateScanWizardContext } from '../../createScanContext';
import { BigidFilter } from '@bigid-ui/components';
import {
  getInitialScanTemplateGridColumns,
  getInitialScanTemplatesFilters,
} from '../../../ScanTemplates/ScanTemplatesGridConfiguration';
import { BigidGridWithToolbar, BigidGridWithToolbarProps, CardSize, ViewType } from '@bigid-ui/grid';
import { DEFAULT_SCAN_TEMPLATES_SORTING, ScanTemplateGridRow } from '../../../ScanTemplates/scanTemplateTypes';
import { getTemplatesForGrid } from '../../../ScanTemplates/scanTemplatesService';
import { isEqual } from 'lodash';
import { CardFooter, CardHeader } from './SelectTemplateCard';
import { BigidLayoutEmptyState } from '@bigid-ui/layout';
import { CardContent } from '../../../ScanTemplates/ScanTemplateCard';
import { BigidScanTemplateIllustration } from '@bigid-ui/icons';
import styled from '@emotion/styled';

const EmptyStateContainer = styled('div')`
  width: 100%;
`;

export const SelectTemplateStep: FC = () => {
  const { scanWizardState, setScanWizardState } = useContext(CreateScanWizardContext);
  const initialScanTemplatesGridColumns = useMemo(getInitialScanTemplateGridColumns, []);
  const scanSupportedTypes = scanWizardState.allEnabledDs ? [] : scanWizardState.supportedScanTypes;
  const getInitialFilterToolbarConfig = useMemo(() => getInitialScanTemplatesFilters(scanSupportedTypes), []);
  const templatesEmptyState = useMemo(
    () => (
      <EmptyStateContainer>
        <BigidLayoutEmptyState
          illustration={BigidScanTemplateIllustration}
          description="No suitable templates were found."
        />
      </EmptyStateContainer>
    ),
    [],
  );
  const config: BigidGridWithToolbarProps<ScanTemplateGridRow> = useMemo(
    () => ({
      pageSize: 100,
      noDataContent: templatesEmptyState,
      customRowIdName: '_id',
      entityName: 'Scan Templates',
      defaultSorting: DEFAULT_SCAN_TEMPLATES_SORTING,
      isSingleSelection: true,
      shouldKeepSelectionAfterFilter: true,
      onGridStateChange: nextGridState => {
        const selectedTemplates = nextGridState.selectedRowIds as string[];
        if (!isEqual(selectedTemplates, scanWizardState.templateIds)) {
          setScanWizardState({ ...scanWizardState, templateIds: selectedTemplates });
        }
      },
      filterToolbarConfig: getInitialFilterToolbarConfig,
      preSelectedIds: scanWizardState.templateIds,
      fetchData: async queryComponents => {
        try {
          const supportedScanTypeFilter: BigidFilter =
            scanWizardState.allEnabledDs || queryComponents.filter.find(filterItem => filterItem.field === 'scanType')
              ? []
              : [
                  {
                    field: 'scanType',
                    value: [...scanWizardState.supportedScanTypes],
                    operator: 'in',
                  },
                ];
          const correlationSetsRequired = !scanWizardState.dataSourcesIds?.length && !scanWizardState.allEnabledDs;

          const queryComponentsWithSelectTemplateFilters = {
            ...queryComponents,
            requireTotalCount: true,
            sort: queryComponents.sort,
            filter: [...queryComponents.filter, ...(correlationSetsRequired ? [] : supportedScanTypeFilter)],
          };

          const templates = getTemplatesForGrid(queryComponentsWithSelectTemplateFilters, correlationSetsRequired);
          return templates;
        } catch (e) {
          return {
            totalCount: 0,
            data: [],
          };
        }
      },
      columns: initialScanTemplatesGridColumns,
      showSelectAll: false,
      customViewConfiguration: {
        [ViewType.CARD]: {
          cardSize: CardSize.LARGE,
          renderHeader: (row: ScanTemplateGridRow) => <CardHeader row={row} />,
          renderContent: (row: ScanTemplateGridRow) => <CardContent row={row} />,
          renderFooter: (row: ScanTemplateGridRow) => <CardFooter row={row} />,
          withClickableCards: true,
        },
      },
      defaultView: ViewType.CARD,
    }),
    [
      getInitialFilterToolbarConfig,
      initialScanTemplatesGridColumns,
      scanWizardState,
      setScanWizardState,
      templatesEmptyState,
    ],
  );

  return <BigidGridWithToolbar {...config} />;
};
