import { ClassifiersEventsEnum, EnabledType } from '../types/Events';

export const getEnabledDisabledEventName = (enabled: boolean, enabledType: EnabledType) => {
  if (enabledType === EnabledType.inline) {
    return enabled ? ClassifiersEventsEnum.ENABLED_INLINE_ACTION : ClassifiersEventsEnum.DISABLED_INLINE_ACTION;
  }

  if (enabledType === EnabledType.sidePanel) {
    return enabled ? ClassifiersEventsEnum.ENABLED_SIDE_PANEL_ACTION : ClassifiersEventsEnum.DISABLED_SIDE_PANEL_ACTION;
  }

  if (enabledType === EnabledType.bulk) {
    return enabled ? ClassifiersEventsEnum.ENABLED_BULK_ACTION : ClassifiersEventsEnum.DISABLED_BULK_ACTION;
  }
};
