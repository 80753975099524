import { DashboardCommonContainer, BigidBody1, BigidHeading6, BigidLoader, BigidTooltip } from '@bigid-ui/components';
import { $state } from '../../../services/angularServices';
import React, { useEffect, useState } from 'react';
import { httpService } from '../../../services/httpService';
import makeStyles from '@mui/styles/makeStyles';
import { notificationService } from '../../../services/notificationService';
import { CONFIG } from '../../../../config/common';
import { generateDataAid } from '@bigid-ui/utils';
import { ViewType } from '../../ActionableInsights/actionableInsightsService';
import { formatNumberCompact } from '../../../utilities/numericDataConverter';
import {
  ActionableInsightsTrackingEvents,
  trackActionableInsightsEvent,
} from '../../ActionableInsights/actionableInsightsTrackingUtil';
import { CaseSeverityLevel } from '../../../types/actionableInsights';
import { Theme } from '@emotion/react';
import styled from '@emotion/styled';

const getColorBySeverity = (severity: string, theme: Theme) => {
  switch (severity) {
    case CaseSeverityLevel.CRITICAL:
      return theme.vars.palette.bigid.red500;
    case CaseSeverityLevel.HIGH:
      return theme.vars.palette.bigid.orange400;
    case CaseSeverityLevel.LOW:
      return theme.vars.palette.bigid.green200;
    default:
      return theme.vars.palette.bigid.yellow500;
  }
};

const Dot = styled('div')<{ severity: string }>`
  border-radius: 5px;
  width: 10px;
  height: 10px;
  background-color: ${({ severity, theme }) => getColorBySeverity(severity, theme)};
`;

const ContentWrapper = styled('div')({
  display: 'grid',
  alignItems: 'center',
  rowGap: 6,
  justifyItems: 'stretch',
});

const CaseWrapper = styled('div')({
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'row',
  cursor: 'pointer',
  columnGap: 10,
  height: 20,
});

const useStyles = makeStyles(theme => ({
  main: {
    minHeight: 245,
    padding: 16,
    display: 'grid',
    gridTemplateRows: '24px 1fr',
    rowGap: 8,
  },
  noData: {
    justifySelf: 'center',
    paddingBottom: 20,
  },
  caseDescription: {
    fontSize: 13,
    display: 'grid',
    gridTemplateColumns: 'auto 100px',
    width: '100%',
    justifyContent: 'space-between',
  },
  label: {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    paddingRight: 16,
    color: theme.vars.palette.bigid.gray900,
  },
}));

export const TopCriticalCasesWidget = () => {
  const [allCriticalCases, setAllCriticalCases] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const classes = useStyles();

  const redirectHandler = () => {
    $state.go(CONFIG.states.ACTIONABLE_INSIGHTS, { viewType: ViewType.FLAT });
    trackActionableInsightsEvent(ActionableInsightsTrackingEvents.SMALLID_DASHBOARD_TOP_CRITICAL_CASES_WIDGET_CLICK, {
      data: allCriticalCases,
    });
  };

  const requestCriticalCases = async () => {
    setIsLoading(true);
    try {
      const {
        data: { data },
      } = await httpService.fetch('actionable-insights/top-critical-cases');
      setAllCriticalCases(data);
      setIsLoading(false);
    } catch (error) {
      notificationService.error('Error in fetching top critical cases');
      console.log('Error in fetching top critical cases');
      setIsLoading(false);
    }
  };

  const MainContent =
    allCriticalCases.length !== 0 ? (
      <>
        {allCriticalCases.map(element => (
          <CaseWrapper
            data-aid={generateDataAid('TopCriticalCasesWidget', [element.severityLevel, element.label])}
            onClick={redirectHandler}
            key={element.caseId}
          >
            <Dot severity={element.severityLevel} />
            <div className={classes.caseDescription}>
              <BigidTooltip title={element.caseLabel} placement="top">
                <div className={classes.label}>{element.caseLabel}</div>
              </BigidTooltip>
              <div>{`${formatNumberCompact(element.amount)} Objects`}</div>
            </div>
          </CaseWrapper>
        ))}
      </>
    ) : (
      <BigidBody1 className={classes.noData}>No open cases available</BigidBody1>
    );

  useEffect(() => {
    requestCriticalCases();
  }, []);

  return (
    <DashboardCommonContainer
      dataAid={generateDataAid('BigidDashboardWidget', ['top-critical-cases'])}
      className={classes.main}
    >
      <BigidHeading6>Top Data Risks</BigidHeading6>
      <ContentWrapper>{isLoading ? <BigidLoader position="relative" /> : MainContent}</ContentWrapper>
    </DashboardCommonContainer>
  );
};
