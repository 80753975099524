import { Dispatch, useEffect } from 'react';
import { ContextActions } from '../dataCatalogSearchResultsContext';

export const useSelectionReset = (isSelectAll: boolean, dispatchCatalogSearchAction: Dispatch<ContextActions>) => {
  useEffect(() => {
    if (isSelectAll) {
      dispatchCatalogSearchAction({
        type: 'UPDATE_GRID_CONFIG',
        payload: {
          selectedRowsIds: [],
        },
      });
    } else {
      dispatchCatalogSearchAction({
        type: 'UPDATE_GRID_CONFIG',
        payload: {
          unselectedRowsIds: [],
        },
      });
    }
  }, [isSelectAll, dispatchCatalogSearchAction]);
};
