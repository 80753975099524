import {
  BigidFormFieldSideNumber,
  BigidFormFieldSideRadio,
  BigidFormFieldSideSelect,
  BigidFormFieldSideText,
  BigidFormFieldSideCheckbox,
  BigidFormFieldSideDatePicker,
} from '@bigid-ui/components';

import { FormCustomParametersField } from '../fields/FormCustomParametersField';
import { FormPasswordWithMaskField } from '../fields/FormPasswordWithMaskField';
import { FormArrayField } from '../fields/FormArrayField';
import { FormObjectField } from '../fields/FormObjectField';
import { FormFieldAction } from '../fields/FormFieldAction';
import { FormJsonField } from '../fields/FormJsonField';
import { FormTagsField } from '../fields/FormTagsField';
import { FormVerticalObjectField } from '../fields/FormVerticalObjectField';

import { DsTypesEnum } from '../types';

export const TYPE_TO_COMPONENT_MAP: Record<DsTypesEnum, React.FC<any>> = {
  [DsTypesEnum.string]: BigidFormFieldSideText,
  [DsTypesEnum.date]: BigidFormFieldSideDatePicker,
  [DsTypesEnum.options]: BigidFormFieldSideRadio,
  [DsTypesEnum.number]: BigidFormFieldSideNumber,
  [DsTypesEnum.password]: FormPasswordWithMaskField,
  [DsTypesEnum.boolean]: BigidFormFieldSideCheckbox,
  [DsTypesEnum.stringBoolean]: BigidFormFieldSideCheckbox,
  [DsTypesEnum.multiStringBoolean]: BigidFormFieldSideCheckbox,
  [DsTypesEnum.object]: FormObjectField,
  [DsTypesEnum.verticalObject]: FormVerticalObjectField,
  [DsTypesEnum.array]: FormArrayField,
  [DsTypesEnum.email]: BigidFormFieldSideText,
  [DsTypesEnum.stringSelect]: BigidFormFieldSideSelect,
  [DsTypesEnum.stringLong]: BigidFormFieldSideText,
  [DsTypesEnum.customFieldsArray]: FormCustomParametersField,
  [DsTypesEnum.objectSelect]: BigidFormFieldSideSelect,
  [DsTypesEnum.action]: FormFieldAction,
  [DsTypesEnum.json]: FormJsonField,
  [DsTypesEnum.tags]: FormTagsField,
};
