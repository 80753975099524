import { httpService } from './httpService';

/**
 * @deprecated - use {@link httpService#downloadFile} instead
 */
export const downloadFile = function (data: any, mimeType: string, filename: string): void {
  const blob = new Blob([data], { type: mimeType });

  const downLoadLink = document.createElement('a');
  downLoadLink.href = URL.createObjectURL(blob);
  downLoadLink.download = filename;

  document.body.appendChild(downLoadLink);
  setTimeout(() => {
    downLoadLink.click();
    document.body.removeChild(downLoadLink);
  });
};
