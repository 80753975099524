import React, { FC, lazy, Suspense } from 'react';
import { BigidLoader } from '@bigid-ui/components';

const AutoDiscoverWizardAside = lazy(
  () => import(/* webpackChunkName: "AutoDiscoverWizardAsideGuide" */ './AutoDiscoverWizardAsideGuide'),
);

export const AutoDiscoverWizardAsideGuideLazy: FC = () => {
  return (
    <Suspense fallback={<BigidLoader />}>
      <AutoDiscoverWizardAside />
    </Suspense>
  );
};
