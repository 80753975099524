import { ACIDashboardData, DataElement, TopDataSources } from './ACIDashboardWrapper';
import {
  BigidHorizontalBarWithActionsItem,
  RowWithActions,
} from './components/BigidHorizontalBarWithActions/BigidHorizontalBarWithActions';
import { BigidMenuItemProps } from '@bigid-ui/components';
import { $state } from '../../../services/angularServices';
import { CONFIG } from '../../../../config/common';
import { $window } from 'ngimport';
import { getTagsAllPairs } from '../../TagsManagement/TagsManagementService';
import { attachTagsBulk, createAndAttachTagsBulk } from '../../DataCatalog/DataCatalogService';
import { notificationService } from '../../../services/notificationService';
import { DataManagerTabFilters } from '../Files/OpenAccessFiles';
import { DIRECT_USER_ACCESS_TAB_EMPTY_FILTERS, DirectUserAccessTabFilters } from '../UsersPermissions';

const REMEDIATION_TAG_NAME = 'aci_dashboard_remediation';

const openInDirectUserAccess = (filters: DirectUserAccessTabFilters): BigidMenuItemProps => {
  return {
    label: 'Go to Users View',
    onClick: () => {
      $state.go(
        CONFIG.states.ACCESS_GOVERNANCE,
        {
          selectedTab: 'users',
          filters: {
            ...DIRECT_USER_ACCESS_TAB_EMPTY_FILTERS,
            ...filters,
          },
        },
        {
          reload: true,
        },
      );
    },
  };
};

const goToPolicies = (): BigidMenuItemProps => {
  return {
    label: 'Go to policies',
    onClick: () => {
      const url = $state.href(CONFIG.states.POLICIES);
      $window.open(url, '_blank');
    },
  };
};

const goToCases = (): BigidMenuItemProps => {
  return {
    label: 'Go to Security Posture',
    onClick: () => {
      const url = $state.href(CONFIG.states.ACTIONABLE_INSIGHTS);
      $window.open(url, '_blank');
    },
  };
};

const getFilterForCatalogAndRemediationTag = (
  labels: string[],
  selectedDatasources: string[],
  whatWeAreLookingFor: string,
  activeTab?: string,
  whatWeAreLookingForInActiveTab?: string,
): string => {
  const filter: string[] = [];

  if (labels.length > 0) {
    filter.push(`${whatWeAreLookingFor} IN (${labels.map(label => '"' + label + '"').join(',')})`);
  }

  if (activeTab) {
    filter.push(`${whatWeAreLookingForInActiveTab} IN ("${activeTab}")`);
  }

  if (selectedDatasources?.length > 0) {
    filter.push(`system IN (${selectedDatasources.map(ds => '"' + ds + '"').join(',')})`);
  }

  return filter.join(' AND ');
};
const showInCatalog = (
  labels: string[],
  selectedDatasources: string[],
  whatWeAreLookingFor: string,
  activeTab?: string,
  whatWeAreLookingForInActiveTab?: string,
): BigidMenuItemProps => {
  return {
    label: 'Show in Catalog',
    onClick: () => {
      const filter = getFilterForCatalogAndRemediationTag(
        labels,
        selectedDatasources,
        whatWeAreLookingFor,
        activeTab,
        whatWeAreLookingForInActiveTab,
      );

      const url = $state.href(CONFIG.states.CATALOG, {
        filter,
      });
      $window.open(url, '_blank');
    },
  };
};

const sendToRemediation = (
  labels: string[],
  selectedDatasources: string[],
  whatWeAreLookingFor: string,
  activeTab?: string,
  whatWeAreLookingForInActiveTab?: string,
): BigidMenuItemProps => {
  return {
    label: 'Send to Remediation',
    onClick: async () => {
      try {
        const filter = getFilterForCatalogAndRemediationTag(
          labels,
          selectedDatasources,
          whatWeAreLookingFor,
          activeTab,
          whatWeAreLookingForInActiveTab,
        );

        const allTags = await getTagsAllPairs();
        const remediationTag = allTags.find(v => v.tagName === REMEDIATION_TAG_NAME);
        if (remediationTag) {
          await attachTagsBulk(allTags, [{ name: REMEDIATION_TAG_NAME, value: 'true' }], filter);
        } else {
          await createAndAttachTagsBulk(allTags, [{ name: REMEDIATION_TAG_NAME, value: 'true', isNew: true }], filter);
        }
        notificationService.success('Sent to remediation');
        return { shouldGridReload: true };
      } catch (err) {
        console.error(err);
        notificationService.error('Sending to remediation failed');
      }
    },
  };
};

const openInDataManager = (filters: DataManagerTabFilters): BigidMenuItemProps => {
  return {
    label: 'Open in Data View',
    onClick: () => {
      $state.go(
        CONFIG.states.ACCESS_GOVERNANCE,
        {
          selectedTab: 'dataManager',
          filters,
        },
        {
          reload: true,
        },
      );
    },
  };
};

const dataElementToRow = (dataElement: DataElement): BigidHorizontalBarWithActionsItem => {
  return {
    name: dataElement.label,
    value: dataElement.count,
    valueLabel: dataElement.count + '',
  };
};
export const makeRowsWithActions = {
  topCasesByAccessType: (selectedDatasources: string[], dataElements: DataElement[]): RowWithActions[] => {
    return dataElements.map(dataElement => {
      const row: BigidHorizontalBarWithActionsItem = {
        name: dataElement.label,
        value: dataElement.count,
        valueLabel: dataElement.count + '',
      };
      const actions: BigidMenuItemProps[] = [];

      return {
        row,
        actions,
      };
    });
  },
  topAccessTypesByFileCount: (selectedDatasources: string[], dataElements: DataElement[]): RowWithActions[] => {
    return dataElements.map(dataElement => {
      const row = dataElementToRow(dataElement);

      const actions: BigidMenuItemProps[] = [
        showInCatalog([dataElement.label], selectedDatasources, 'catalog_tag.Access Type'),
        sendToRemediation([dataElement.label], selectedDatasources, 'catalog_tag.Access Type'),
        openInDataManager({ accessTypes: [dataElement.label], sources: selectedDatasources }),
      ];

      return {
        row,
        actions,
      };
    });
  },
  topDataSources(data: TopDataSources) {
    const result: { [accessType: string]: RowWithActions[] } = {};

    Object.keys(data).forEach(accessType => {
      result[accessType] = data[accessType].map(dataElement => {
        const row = dataElementToRow(dataElement);

        const actions: BigidMenuItemProps[] = [
          showInCatalog([dataElement.label], [], 'system', accessType, 'catalog_tag.Access Type'),
          sendToRemediation([dataElement.label], [], 'system', accessType, 'catalog_tag.Access Type'),
          openInDataManager({ sources: [dataElement.label], accessTypes: [accessType] }),
        ];

        return {
          row,
          actions,
        };
      });
    });
    return result;
  },
  topUsersAccessWithFileCount(
    selectedDatasources: string[],
    topUsersAccessWithFileCount: DataElement[],
  ): RowWithActions[] {
    return topUsersAccessWithFileCount.map(dataElement => {
      const row = dataElementToRow(dataElement);

      const actions: BigidMenuItemProps[] = [
        openInDirectUserAccess({ searchString: dataElement.label, dataSource: selectedDatasources }),
      ];

      return {
        row,
        actions,
      };
    });
  },
  topExternalUsers(selectedDatasources: string[], topExternalUsers: DataElement[]) {
    return topExternalUsers.map(dataElement => {
      const row = dataElementToRow(dataElement);

      const actions: BigidMenuItemProps[] = [
        openInDirectUserAccess({
          searchString: dataElement.label,
          dataSource: selectedDatasources,
          visibility: ['External'],
        }),
      ];

      return {
        row,
        actions,
      };
    });
  },
  topExternalDomains(topExternalDomains: DataElement[]) {
    return topExternalDomains.map(dataElement => {
      const row = dataElementToRow(dataElement);

      const actions: BigidMenuItemProps[] = [];

      return {
        row,
        actions,
      };
    });
  },
};

export const makeTitleActions = {
  topAccessTypesByFileCount: (
    selectedDatasources: string[],
    data: ACIDashboardData['topAccessTypesByFileCount'],
  ): BigidMenuItemProps[] =>
    data?.length > 0
      ? [
          showInCatalog([], selectedDatasources, 'catalog_tag.Access Type'),
          sendToRemediation(
            data.map(barchartElement => barchartElement.label),
            selectedDatasources,
            'catalog_tag.Access Type',
          ),
          openInDataManager({
            accessTypes: data.map(barchartElement => barchartElement.label),
            sources: selectedDatasources,
          }),
        ]
      : [],
  topCasesByAccessType: (): BigidMenuItemProps[] => [goToCases()],
  objectsFoundByAccessPolicies: (
    selectedDatasources: string[],
    triggeredPoliciesWithAccessType: string[],
    objectsFoundByAccessPolicies: number,
  ): BigidMenuItemProps[] =>
    objectsFoundByAccessPolicies > 0
      ? [showInCatalog(triggeredPoliciesWithAccessType, selectedDatasources, 'policy')]
      : [],
  topDataSources: (selectedDatasources: string[], data: TopDataSources): { [key: string]: BigidMenuItemProps[] } => {
    return Object.keys(data).reduce((acc, accessType) => {
      acc[accessType] = [
        showInCatalog([], selectedDatasources, 'system', accessType, 'catalog_tag.Access Type'),
        sendToRemediation([], selectedDatasources, 'system', accessType, 'catalog_tag.Access Type'),
        openInDataManager({ sources: selectedDatasources, accessTypes: [accessType] }),
      ];
      return acc;
    }, {} as { [key: string]: BigidMenuItemProps[] });
  },
  topUsersAccessWithFileCount(
    selectedDatasources: string[],
    data: ACIDashboardData['topUsersAccessWithFileCount'],
  ): BigidMenuItemProps[] {
    return data?.length > 0 ? [openInDirectUserAccess({ dataSource: selectedDatasources, searchString: '' })] : [];
  },
  accessPoliciesTriggered() {
    return [goToPolicies()];
  },
};
