import { BigidTagBaseProps, BigidTags, getTagsDictionary } from '@bigid-ui/components';
import React from 'react';
import {
  getTagFormattedName,
  getTagIcon,
  isUserDefinedTag,
  validateTagNameWithMessage,
  validateTagValueWithMessage,
} from '../../../../../../TagsManagement/TagsManagementUtils';
import {
  attachTag,
  createAndAttachTag,
  DataCatalogObjectDetails,
  detachTag,
} from '../../../../../../DataCatalog/DataCatalogDetails/DataCatalogDetailsService';
import { useMutation, useQuery } from 'react-query';
import { getTagsAllPairs } from '../../../../../../TagsManagement/TagsManagementService';
import { notificationService } from '../../../../../../../services/notificationService';
import { getQueryClient } from '../../../../../../../config/query';
import { OverviewSection } from './OverviewSection';
import { useLocalTranslation } from '../../../../../translations';
import { ShowMore } from './ShowMore';

type OverviewTagsProps = {
  objectDetails: DataCatalogObjectDetails;
  isReadTagsPermitted: boolean;
  isCreateTagsPermitted: boolean;
  isObjectTagsAssignmentPermitted: boolean;
  refetchDetails: () => void;
};

export const OverviewTags = ({
  objectDetails,
  isReadTagsPermitted,
  isObjectTagsAssignmentPermitted,
  isCreateTagsPermitted,
  refetchDetails,
}: OverviewTagsProps) => {
  const { fullyQualifiedName, dataSourceName } = objectDetails;
  const [isExpanded, setIsExpanded] = React.useState(false);
  const { t } = useLocalTranslation('sidePanel.overview');
  const {
    data: { systemTags, tagsDictionary },
    refetch: refetchTagsDictionary,
  } = useQuery(
    'tagsDictionary',
    async () => {
      if (isObjectTagsAssignmentPermitted) {
        const tags = await getTagsAllPairs();
        // setSystemTags(tags);
        const dictionary = getTagsDictionary(
          tags
            .filter(tag => {
              return !tag.properties?.isExplicit && isUserDefinedTag(tag);
            })
            .map(({ tagName, tagValue }) => ({
              name: tagName,
              value: tagValue,
            })),
        );
        // setSystemTagsDictionary(dictionary);
        return {
          systemTags: tags,
          tagsDictionary: dictionary,
        };
      }
      return {
        systemTags: [],
        tagsDictionary: new Map(),
      };
    },
    {
      placeholderData: {
        systemTags: [],
        tagsDictionary: new Map(),
      },
    },
  );

  const { mutate: handleTagCreate } = useMutation(
    'handleTagCreate',
    async (tag: BigidTagBaseProps) => {
      try {
        await createAndAttachTag(systemTags, tag, fullyQualifiedName, dataSourceName);
        refetchDetails();
        refetchTagsDictionary();
      } catch ({ message, response }) {
        const notificationMessage =
          response?.data?.message === 'this tag-name already exist'
            ? 'A tag with this name already exists'
            : 'An error has occurred';
        notificationService.error(notificationMessage);
        console.error(`An error has occurred: ${message}`);
      }
    },
    {
      onMutate: async newData => {
        const dataKey = ['getObjectDetails', fullyQualifiedName];

        // Snapshot the previous value
        const previousData = queryClient.getQueryData(dataKey);

        // Optimistically update the cache with the new value
        queryClient.setQueryData<{ data: DataCatalogObjectDetails }>(dataKey, old => {
          return {
            ...old,
            data: {
              ...old.data,
              tags: [
                ...old.data.tags,
                {
                  tagName: newData.name,
                  tagValue: newData.value,
                  tagId: newData.name,
                  valueId: newData.value,
                },
              ],
            },
          };
        });

        // Return a context object with the snapshotted value to use in case of rollback
        return { previousData };
      },
    },
  );

  const queryClient = getQueryClient();

  const { mutate: handleTagAttach } = useMutation(
    'handleTagAttach',
    async (tag: BigidTagBaseProps) => {
      try {
        await attachTag(
          systemTags.filter(({ properties }) => !properties?.isExplicit),
          tag,
          fullyQualifiedName,
          dataSourceName,
        );

        refetchDetails();
      } catch ({ message }) {
        notificationService.error('An error has occurred');
        console.error(`An error has occurred: ${message}`);
      }
    },
    {
      onMutate: async newData => {
        const dataKey = ['getObjectDetails', fullyQualifiedName];

        // Snapshot the previous value
        const previousData = queryClient.getQueryData(dataKey);

        // Optimistically update the cache with the new value
        queryClient.setQueryData<{ data: DataCatalogObjectDetails }>(dataKey, old => {
          return {
            ...old,
            data: {
              ...old.data,
              tags: [
                ...old.data.tags,
                {
                  tagName: newData.name,
                  tagValue: newData.value,
                  tagId: newData.name,
                  valueId: newData.value,
                },
              ],
            },
          };
        });

        // Return a context object with the snapshotted value to use in case of rollback
        return { previousData };
      },
    },
  );
  const { mutate: handleTagDetach } = useMutation(
    'handleTagDetach',
    async (tag: BigidTagBaseProps) => {
      try {
        await detachTag(systemTags, tag, fullyQualifiedName, dataSourceName);
        refetchDetails();
      } catch ({ message }) {
        notificationService.error('An error has occurred');
        console.error(`An error has occurred: ${message}`);
      }
    },
    {
      onMutate: async newData => {
        const dataKey = ['getObjectDetails', fullyQualifiedName];

        // Snapshot the previous value
        const previousData = queryClient.getQueryData(dataKey);

        // Optimistically update the cache with the new value
        queryClient.setQueryData<{ data: DataCatalogObjectDetails }>(dataKey, old => {
          return {
            ...old,
            data: {
              ...old.data,
              tags: old.data.tags.filter(tag => tag.tagName !== newData.name || tag.tagValue !== newData.value),
            },
          };
        });

        // Return a context object with the snapshotted value to use in case of rollback
        return { previousData };
      },
    },
  );

  const getTagsFromDetails = (objectDetails: DataCatalogObjectDetails) => {
    if (isReadTagsPermitted) {
      return (
        objectDetails.tags
          ?.filter(({ properties }) => !properties?.hidden)
          .map(tag => {
            const { tagName, tagValue, properties } = tag;

            return {
              name: getTagFormattedName(tagName),
              value: tagValue,
              icon: getTagIcon(properties),
              isDeleteUnavailable: !isUserDefinedTag(tag),
            };
          }) || []
      );
    }
  };

  const TAGS_TO_DISPLAY_LIMIT = 8;
  const tags = getTagsFromDetails(objectDetails);
  const tagsToDisplay = isExpanded ? tags : tags.slice(0, TAGS_TO_DISPLAY_LIMIT);
  const numOfExceededItems = tags.length - tagsToDisplay.length;

  return (
    <OverviewSection title={t('tags.title')}>
      <BigidTags
        tags={tagsToDisplay}
        tagWizardMenuPosition="absolute"
        tagsDictionary={tagsDictionary}
        onCreate={handleTagCreate}
        onAdd={handleTagAttach}
        onDelete={handleTagDetach}
        isAbleToCreate={isCreateTagsPermitted}
        isAbleToEdit={isObjectTagsAssignmentPermitted && isReadTagsPermitted}
        isAbleToDelete={isObjectTagsAssignmentPermitted}
        placeholder="Click the + to add tag"
        useMinHeight
        validateName={validateTagNameWithMessage}
        validateValue={validateTagValueWithMessage}
      />
      {tags.length > TAGS_TO_DISPLAY_LIMIT && (
        <ShowMore isExpanded={isExpanded} setIsExpanded={setIsExpanded} numOfExceededItems={numOfExceededItems} />
      )}
    </OverviewSection>
  );
};
