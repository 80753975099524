import { httpService } from '../../services/httpService';
import { BigidGridQueryComponents } from '@bigid-ui/grid';
import { queryService } from '../../services/queryService';
import { LegalEntitiesInterface } from './LegalEntitiesTypes';
import { getSearchQuery } from './utils/common';

export const getLegalEntities = async (bigidGridQuery: BigidGridQueryComponents) => {
  const gridConfigQuery = queryService.getGridConfigQuery(bigidGridQuery);
  const {
    data: { data },
  } = await httpService.fetch(`data-mapping/legal-entities?${gridConfigQuery}&checkHasLegalEntities=true`);

  return data;
};

export const getLegalEntity = async (id: string) => {
  const {
    data: { data },
  } = await httpService.fetch(`data-mapping/legal-entities/${id}`);
  return data;
};

export const createLegalEntity = async (legalEntity: Omit<LegalEntitiesInterface, 'id' | 'status'>) => {
  const {
    data: { data },
  } = await httpService.post('data-mapping/legal-entities', legalEntity);
  return data;
};

export const updateLegalEntity = async (id: string, payload: Partial<LegalEntitiesInterface>) => {
  const {
    data: { data },
  } = await httpService.patch(`data-mapping/legal-entities/${id}`, payload);
  return data;
};

export async function deleteLegalEntity(id: string) {
  const {
    data: { data },
  } = await httpService.delete(`data-mapping/legal-entities/${id}`);

  return data;
}

export const getLevels = async (searchText?: string) => {
  const {
    data: { data },
  } = await httpService.fetch(`data-mapping/legal-entities/level?${getSearchQuery(searchText)}`);
  return data;
};

export const getParents = async (searchText?: string) => {
  const {
    data: { data },
  } = await httpService.fetch(`data-mapping/legal-entities?${getSearchQuery(searchText, true)}`);
  return data;
};

export const getParentsByLevel = async (levelId: string, searchText?: string) => {
  const {
    data: { data },
  } = await httpService.fetch(
    `data-mapping/legal-entities/level/${levelId}/parent-entities?${getSearchQuery(searchText)}`,
  );
  return data;
};

export const getHierarchyById = async (entityId: string) => {
  const {
    data: { data },
  } = await httpService.fetch(`data-mapping/legal-entities/${entityId}/hierarchy`);
  return data;
};

export async function createAsset(payload: { name: string }): Promise<{ _id: string }> {
  const {
    data: { _id },
  } = await httpService.post('applications?exposeSystemId=true', payload);

  return { _id };
}

export async function getActivityLogs(id: string) {
  const {
    data: { data },
  } = await httpService.fetch(`data-mapping/legal-entities/${id}/audit-logs`);

  return data;
}
