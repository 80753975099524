import React, { useMemo, FC, useCallback, memo, useRef } from 'react';
import { debounce } from 'lodash';
import { BigidForm, BigidFormRenderProps } from '@bigid-ui/components';
import { styled } from '@mui/material';
import { AutoDiscoveryWizardContextState } from '../../autoDiscoveryWizardContext';
import {
  generateAutoDiscoveryFieldsData,
  getDiscoverAuthTypeString,
  resetStateOnAuthTypeChanged,
} from '../../autoDiscoveryWizardFormUtils';
import { AutoDiscoveryWizardAuthSection } from './AutoDiscoveryWizardAuthSection';
import { AutoDiscoveryPresetDetailsTab } from '../../AutoDiscoveryWizardAdvanced/tabs/AutoDiscoveryPresetDetailsTab';
import { AutoDiscoveryPresetConnectionsTab } from '../../AutoDiscoveryWizardAdvanced/tabs/AutoDiscoveryPresetConnectionsTab';
import {
  AUTH_SECTION_NAME,
  CONNECTION_SECTION_NAME,
  PREREQUISITES_PLACEMENT_BY_TYPE,
  PrerequisitesPlacement,
} from '../../../constants';
import { useChangesMonitoring } from '../../../hooks/useChangesMonitoring';
import { savePreset } from '../../../hooks/useActionHandlers';

const AutoDiscoverWizardFormWrapper = styled('div')`
  padding: 24px 32px;
  max-width: 600px;
  min-width: 400px;
`;

export interface AutoDiscoverWizardFormProps {
  discoveryConfigDataRef: React.MutableRefObject<AutoDiscoveryWizardContextState>;
  setDiscoveryConfigData: React.Dispatch<React.SetStateAction<AutoDiscoveryWizardContextState>>;
  isAllFieldsMode?: boolean;
}

export const AutoDiscoverWizardForm: FC<AutoDiscoverWizardFormProps> = memo(
  ({ setDiscoveryConfigData, discoveryConfigDataRef, isAllFieldsMode }) => {
    const containerRef = useRef(null);
    const onChange = useCallback(
      debounce((values: Record<string, any>) => {
        setDiscoveryConfigData(current => {
          const formData = {
            ...current.formData,
            ...values,
          };
          return {
            ...current,
            formData,
            ...resetStateOnAuthTypeChanged(values, current),
          };
        });
      }, 500),
      [],
    );

    const formProps = useMemo(() => {
      const { initialValues, mainFields, sideScanFields, fields, fieldsBySection } = generateAutoDiscoveryFieldsData(
        discoveryConfigDataRef.current,
      );
      setDiscoveryConfigData(current => ({
        ...current,
        initialValues,
      }));
      const {
        [AUTH_SECTION_NAME]: authConditionalFields,
        [CONNECTION_SECTION_NAME]: connectionSection,
        ...restSections
      } = fieldsBySection;
      const authTypeValue = getDiscoverAuthTypeString(discoveryConfigDataRef.current?.formData?.authType);
      const showPrerequisites =
        PREREQUISITES_PLACEMENT_BY_TYPE[discoveryConfigDataRef?.current?.type][authTypeValue] ===
        PrerequisitesPlacement.MAIN;
      const detailsSections = showPrerequisites
        ? { [CONNECTION_SECTION_NAME]: connectionSection, ...restSections }
        : restSections;

      const renderForm = ({
        renderField,
        getValues,
        getFieldProps,
        validateAndSubmit,
        setErrors,
        errors,
        formTouched,
      }: BigidFormRenderProps) => {
        const values = getValues();
        discoveryConfigDataRef.current = {
          ...discoveryConfigDataRef.current,
          validateAndSubmit,
          setErrors,
          formTouched,
          getFieldProps,
        };

        if (isAllFieldsMode) {
          return (
            <>
              <AutoDiscoveryPresetConnectionsTab
                values={values}
                authConditionalFields={authConditionalFields}
                renderField={renderField}
                sideScanFields={sideScanFields}
                errors={errors}
                connectionSection={connectionSection}
              />
              <AutoDiscoveryPresetDetailsTab
                errors={errors}
                renderField={renderField}
                values={values}
                fieldsBySection={detailsSections}
              />
            </>
          );
        }

        return (
          <>
            {mainFields.map(({ name }) => renderField(name))}
            <AutoDiscoveryWizardAuthSection
              errors={errors}
              renderField={renderField}
              getFieldProps={getFieldProps}
              fields={authConditionalFields}
              values={values}
              sideScanFields={sideScanFields}
            />
          </>
        );
      };
      return {
        initialValues,
        onChange,
        controlButtons: false,
        fields: [...mainFields, ...sideScanFields, ...fields],
        renderForm,
      };
    }, []);

    useChangesMonitoring(savePreset, discoveryConfigDataRef, setDiscoveryConfigData, !isAllFieldsMode);

    return (
      <AutoDiscoverWizardFormWrapper ref={containerRef} data-aid={'AutoDiscoverWizardForm'}>
        <BigidForm {...formProps} />
      </AutoDiscoverWizardFormWrapper>
    );
  },
  () => true,
);
