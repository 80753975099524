import React, { FC } from 'react';
import { BigidList, BigidListProps } from '@bigid-ui/components';
import { useLocalTranslation } from './translations';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles({
  root: {
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fill, minmax(28rem, 1fr))',
  },
});

export interface LabelingLabelDetailsProps {
  labelId: string;
  labelDescription: string;
  displayName: string;
  name: string;
}

export const LabelingLabelDetails: FC<LabelingLabelDetailsProps> = ({
  labelId,
  labelDescription,
  displayName,
  name,
}) => {
  const { t } = useLocalTranslation('LabelingLabelDetails');

  const classes = useStyles();
  const bigidListProps: BigidListProps = {
    listItems: [
      { id: 'name', name: t('name'), value: name },
      { id: 'labelDescription', name: t('labelDescription'), value: labelDescription },
      { id: 'displayName', name: t('displayName'), value: displayName },
      { id: 'labelId', name: t('labelId'), value: labelId },
    ],
  };
  return (
    <div className={classes.root}>
      <BigidList {...bigidListProps} />
    </div>
  );
};
