import React, { FC, useEffect } from 'react';
import { styled } from '@mui/material';
import { BigidGridColumn, BigidGridWithToolbar, BigidGridWithToolbarProps } from '@bigid-ui/grid';
import {
  BigidFieldFilter,
  BigidPaper as BigidPaperOrg,
  BigidPaperProps,
  EntityEvents,
  entityEventsEmitter,
} from '@bigid-ui/components';

import { PageGridPreferences, UserPreference } from '../../../services/userPreferencesService';
import { getApplicationPreference } from '../../../services/appPreferencesService';

import { useLocalTranslation } from '../translations';
import { fetchGridData } from './helpers';
import { DataSourceDeletionModel } from './types';
import { actions } from './constants';
import { omit } from 'lodash';

const BigidPaper = styled((props: BigidPaperProps & { className?: string }) => (
  <BigidPaperOrg {...props} paperProps={{ className: props.className }} />
))`
  height: 300px !important;
`;

type GridProps = {
  columns: BigidGridColumn<DataSourceDeletionModel>[];
  filters: BigidFieldFilter[];
  preferences?: UserPreference<unknown>;
  onUpdatePreferences: (pref: PageGridPreferences) => void;
};

export const Grid: FC<GridProps> = ({ filters, preferences, onUpdatePreferences, columns }) => {
  const fetchTimeout = (getApplicationPreference('DATA_MINIMIZATION_DS_GRID_REFRESH_TIMEOUT') || 30) * 1000;
  const { t } = useLocalTranslation('DataSource');

  useEffect(() => {
    const intervalId = setInterval(() => {
      entityEventsEmitter.emit(EntityEvents.RELOAD);
    }, fetchTimeout);

    return () => clearInterval(intervalId);
  }, [fetchTimeout]);

  const gridConfig: BigidGridWithToolbarProps<DataSourceDeletionModel> = {
    entityName: t('dataSource'),
    showSortingControls: true,
    columns: columns,
    customRowIdName: 'name',
    defaultSorting: preferences?.grid?.sort || [{ field: 'last_execution_date', order: 'desc' }],
    fetchData: fetchGridData,
    externalFilter: filters,
    toolbarActions: actions,
    onGridStateChange: config => onUpdatePreferences(omit(config, 'filter')),
  };

  return (
    <BigidPaper data-bypass="bypass-grid" aria-label={t('deletionDataSources')}>
      <BigidGridWithToolbar {...gridConfig} />
    </BigidPaper>
  );
};
