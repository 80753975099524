import './consentSourceConnections.component.scss';
import template from './consentSourceConnections.component.html';
import { module } from 'angular';
import { isPermitted } from '../../react/services/userPermissionsService';
import { CONSENT_GOVERNANCE_PERMISSIONS } from '@bigid/permissions';
const app = module('app');

app.component('consentSourceConnections', {
  template,
  controller: function () {
    this.$onInit = () => {
      this.isCreatePermitted = isPermitted(CONSENT_GOVERNANCE_PERMISSIONS.CREATE_CONSENT_SOURCES.name);
    };
  },
});
