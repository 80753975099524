import './exportButton.component.scss';
import template from './exportButton.component.html';
import { module } from 'angular';
const app = module('app');

app.component('exportButton', {
  template,
  controller: function () {
    this.items = [];

    this.$onChanges = () => {
      if (typeof this.items == 'undefined' || this.items.constructor !== Array) {
        this.items = [];
      }
    };

    this.onClick = item => {
      this.onItemClick({ item });
    };
  },
  bindings: {
    /**
     *  OBJECT
     *  name: <STRING> item name
     *  action: <STRING> parent component function name, click handler
     *  isEnabled: <BOOLEAN> is item enabled flag, true: enabled, false: disabled
     *  isVisible: <BOOLEAN> is item visible flag, true: visible, false: hidden
     */
    items: '<',
    /**
     *  EVENT
     *  item: {name: ITEM_NAME_HERE, action: ITEM_ACTION_HERE}
     */
    onItemClick: '&',
    isIndicatorShown: '<',
  },
});
