import { noop } from 'lodash';
import { generateDataAid } from '@bigid-ui/utils';
import { openSystemDialog } from '../../../../../../services/systemDialogService';
import {
  CuratedFieldValuePreviewProps,
  CuratedFieldValuePreview,
} from '../../../CuratedFieldValuePreview/CuratedFieldValuePreview';
import { httpService } from '../../../../../../services/httpService';
import { getApplicationPreference } from '../../../../../../services/appPreferencesService';

export type OpenFieldValuePreviewDialogProps = Omit<CuratedFieldValuePreviewProps, 'dataAid'> & { fieldName: string };

export async function openFieldValuePreviewDialog({
  fieldName,
  valueChunks,
  onFindingCurate,
  isPermittedToExclude,
}: OpenFieldValuePreviewDialogProps) {
  openSystemDialog({
    borderTop: true,
    borderBottom: true,
    withCloseButton: true,
    title: `Curate findings for '${fieldName}' field`,
    onClose: noop,
    content: CuratedFieldValuePreview,
    contentProps: {
      dataAid: generateDataAid('CuratedFieldValuePreview', ['dialog', fieldName]),
      valueChunks,
      onFindingCurate,
      isPermittedToExclude,
    },
    maxWidth: 'lg',
  });
}

export const clearFromCache = (fullyQualifiedName: string) => {
  if (getApplicationPreference('ENABLE_LEGACY_STRUCTURED_PREVIEW')) {
    return httpService
      .delete(`data-catalog/table-preview/${encodeURIComponent(fullyQualifiedName)}`)
      .then(({ data }) => data);
  }

  return httpService
    .delete(`data-catalog/structured-preview/${encodeURIComponent(fullyQualifiedName)}`)
    .then(({ data }) => data);
};
