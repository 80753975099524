import React, { FC } from 'react';
import classnames from 'classnames';
import makeStyles from '@mui/styles/makeStyles';
import { BigidColors } from '@bigid-ui/components';

interface CheckDisabled {
  all: boolean;
  none: boolean;
}

interface CheckToolProps {
  onCheckAll: () => void;
  onCheckNone: () => void;
  disabled?: CheckDisabled;
  dataAid?: string;
}

const useStyles = makeStyles({
  wrapper: { display: 'flex', alignItems: 'center' },
  check: { cursor: 'pointer', fontSize: '0.8125rem', color: '#3D43D3', marginRight: 12 },
  disabled: { color: BigidColors.gray[500], cursor: 'not-allowed' },
});

export const BigidCheckTool: FC<CheckToolProps> = ({ onCheckAll, onCheckNone, disabled = {}, dataAid = '' }) => {
  const classes = useStyles({});
  return (
    <div className={classes.wrapper}>
      <div
        className={classnames(classes.check, disabled.all && classes.disabled)}
        onClick={!disabled.all ? onCheckAll : null}
        data-aid={`${dataAid}checkAll`}
      >
        Check all
      </div>
      <div
        className={classnames(classes.check, disabled.none && classes.disabled)}
        onClick={!disabled.none ? onCheckNone : null}
        data-aid={`${dataAid}checkNone`}
      >
        Check none
      </div>
    </div>
  );
};
