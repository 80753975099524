import React, { FC } from 'react';
import { makeStyles } from '@mui/styles';
import { generateDataAid } from '@bigid-ui/utils';
import { BigidFlexibleListCardContentProps, BigidBody1, BigidTooltip } from '@bigid-ui/components';
import { CuratedDataSourcesGridRecord } from './useCuratedDataSourcesConfig';
import classNames from 'classnames';
import { useLocalTranslation } from '../translations';
import { formatNumberCompact } from '../../../utilities/numericDataConverter';
import { getSamplingStatusText, getDataSourceStatusBadge } from '../curationUtils';
import { BigidHelpIcon } from '@bigid-ui/icons';
const useStyles = makeStyles({
  root: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    height: '100%',
  },
  column: {
    height: '100%',
    display: 'flex',
    width: '50%',
  },
  verContent: {
    flexDirection: 'column',
    justifyContent: 'center',
  },
  horContent: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  totalAttributes: {
    marginBottom: '4px',
  },
  totalFindings: {
    marginTop: '4px',
  },
  reviewed: {
    display: 'flex',
    marginLeft: '4px',
  },
  helpIconWrapper: {
    display: 'flex',
    marginLeft: '4px',
  },
  statusBadge: {
    justifyContent: 'flex-end',
  },
});

type CuratedDataSourceContentProps = BigidFlexibleListCardContentProps<unknown, CuratedDataSourcesGridRecord>;

export const CuratedDataSourceContent: FC<CuratedDataSourceContentProps> = ({ cardData }) => {
  const classes = useStyles();
  const { t } = useLocalTranslation('CuratedDataSourceContent');

  const { id, originalData } = cardData;
  const { totalFindings } = originalData;
  const reviewedText = getSamplingStatusText(originalData);

  return (
    <div className={classes.root} data-aid={generateDataAid('CuratedDataSourceContent', [id])}>
      <BigidBody1 className={classNames(classes.column, classes.horContent)}>
        {formatNumberCompact(totalFindings, 2)} {t('totalFindings')}
      </BigidBody1>
      {reviewedText && (
        <div className={classNames(classes.column, classes.horContent)}>
          <BigidBody1
            data-aid={generateDataAid('CuratedDataSourceContent', [id, 'reviewed-attributes-count'])}
            className={classes.reviewed}
          >
            {reviewedText}
            <BigidTooltip title={t('reviewedToolTip')} placement="top">
              <span className={classes.helpIconWrapper}>
                <BigidHelpIcon />
              </span>
            </BigidTooltip>
          </BigidBody1>
        </div>
      )}
      <div className={classNames(classes.column, classes.horContent, classes.statusBadge)}>
        <BigidBody1 data-aid={generateDataAid('CuratedDataSourceContent', [id, 'status-badge-datasource'])}>
          {getDataSourceStatusBadge(originalData)}
        </BigidBody1>
      </div>
    </div>
  );
};
