import { useState } from 'react';
import { createContainer } from 'unstated-next';
import { BigidHotspotsChartFieldSettings } from '@bigid-ui/layout';
import { CHART_INITIAL_FIELD_SETTINGS } from './consts';

const useHotspots = () => {
  const [savedChartFieldSettings, setSavedChartFieldSettings] =
    useState<BigidHotspotsChartFieldSettings>(CHART_INITIAL_FIELD_SETTINGS);

  return {
    savedChartFieldSettings,
    setSavedChartFieldSettings,
  };
};

export const HotspotsContainer = createContainer(useHotspots);
