import React, { useCallback, useState, useEffect } from 'react';
import { BigidDialog, PrimaryButton, SecondaryButton } from '@bigid-ui/components';
import { DsarSettingsTrackingEvents } from '../analyticsUtils';
import { deleteDictionary, getDictionary } from '../dsarService';
import { notificationService } from '../../../services/notificationService';

export interface DsarDeleteDictionaryDialogProps {
  dictionaryId: string;
  isOpen: boolean;
  onSave?: () => void;
  onClose?: () => void;
}

export const DsarDeleteDictionaryDialog = ({
  dictionaryId,
  isOpen,
  onSave,
  onClose,
}: DsarDeleteDictionaryDialogProps) => {
  const [isLoading, setIsLoading] = useState(true);
  const [dictionary, setDictionary] = useState(null);

  const handleOnClose = useCallback(() => {
    onClose?.();
    setIsLoading(true);
    setDictionary(null);
  }, [onClose]);

  useEffect(() => {
    if (!isOpen) {
      return;
    }

    (() => {
      getDictionary(dictionaryId)
        .then(dictionary => {
          setDictionary(dictionary);
          setIsLoading(false);
        })
        .catch(err => {
          const errorMessage = `Failed to fetch dictionary: ${dictionaryId}`;
          notificationService.error(errorMessage);
          console.error(`${errorMessage}: ${JSON.stringify(err)}`);
          handleOnClose();
        });
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  if (!isOpen) {
    return null;
  }

  const handleOnSave = () => {
    setIsLoading(true);
    deleteDictionary(dictionaryId)
      .then(onSave)
      .catch(err => {
        const errorMessage = `Failed to delete dictionary`;
        notificationService.error(errorMessage);
        console.error(`${errorMessage}: ${JSON.stringify(err)}`);
      })
      .finally(handleOnClose);
  };

  return (
    <BigidDialog
      title={'Delete Dictionary'}
      isOpen={isOpen}
      onClose={handleOnClose}
      borderTop={true}
      maxWidth="xs"
      buttons={[
        {
          component: props => (
            <SecondaryButton
              {...props}
              bi={{
                eventType: DsarSettingsTrackingEvents.DICTIONARY_DELETE_DIALOG_CANCEL_ACTION,
              }}
            />
          ),
          onClick: handleOnClose,
          text: 'Back',
        },
        {
          component: props => (
            <PrimaryButton
              {...props}
              bi={{
                eventType: DsarSettingsTrackingEvents.DICTIONARY_DELETE_DIALOG_SAVE_ACTION,
              }}
            />
          ),
          onClick: handleOnSave,
          text: 'Delete',
        },
      ]}
      isLoading={isLoading}
    >
      <div style={{ visibility: isLoading ? 'hidden' : 'visible' }}>
        {dictionary?.inUse ? (
          <>
            <p>{'This dictionary is referred to in one or more profiles.'}</p>
            <p>{'Deleting it will remove the references.'}</p>
          </>
        ) : (
          <p>{`Are you sure you wish to delete "${dictionary?.name}"`}</p>
        )}
      </div>
    </BigidDialog>
  );
};

export interface DsarDeleteDictionaryDialog {
  dialogProps: DsarDeleteDictionaryDialogProps;
  openDialog: (dictionaryId?: string) => Promise<boolean>;
}

const initialState: DsarDeleteDictionaryDialogProps = {
  dictionaryId: null,
  isOpen: false,
};

export const useDsarDeleteDictionaryDialog = (): DsarDeleteDictionaryDialog => {
  const [dialogProps, setDialogProps] = useState<DsarDeleteDictionaryDialogProps>(initialState);
  const onClose = useCallback(() => setDialogProps(initialState), []);

  const openDialog = useCallback<DsarDeleteDictionaryDialog['openDialog']>(
    dictionaryId => {
      return new Promise<boolean>(resolve => {
        setDialogProps({
          dictionaryId,
          isOpen: true,
          onSave: () => {
            setDialogProps(initialState);
            resolve(true);
          },
          onClose,
        });
      });
    },
    [onClose],
  );

  return {
    openDialog,
    dialogProps,
  };
};
