import { DataSource, Attribute } from '../Clustering/Clustering';
import { httpService } from '../../services/httpService';

export interface Cluster {
  id: string;
  cluster_id: string;
  name: string;
  size: number;
  keywords: string[];
  dataSources: string[];
  density: string;
  keywordsToDisplay?: string;
  dataSourcesToDisplay?: string;
  description?: string;
  attributes: string[];
  duplicates?: number;
}

export interface ClusterResponse {
  clusters: Cluster[];
  estimatedCount: number;
}

export interface ClusterEditedData {
  name: string;
  description: string;
}

export interface ClusterProximityResponse {
  coordinates: ProximityCluster[];
}

export interface ProximityCluster {
  cluster_id: string;
  name: string;
  attributes: string[];
  coordinate: ProximityPoint;
}

interface ProximityPoint {
  x: number;
  y: number;
  radius: number;
}

export interface ClusterEditMetadataRequest {
  userMetadata: ClusterEditedData;
}

interface ClusterResponseModel {
  isModified: boolean;
  id: string;
}

export interface DataSourceResponse {
  data_sources: DataSource[];
}

export interface AttributeResponse {
  attributes: Attribute[];
}

export const clusteringService = {
  getClusters: (query?: string) =>
    httpService.fetch<ClusterResponse>(`clusters?format=json${query || ''}`).then(response => response.data),
  getVisualizationClusters: (query?: string) =>
    httpService
      .fetch<ClusterProximityResponse>(`clusters/proximity?format=json&${query || ''}`)
      .then(response => response.data.coordinates),
  updateCluster: (cluster: ClusterEditedData, clusterId: string) =>
    httpService
      .put<ClusterResponseModel, ClusterEditMetadataRequest>(`clusters/${clusterId}/updateUserMetadata`, {
        userMetadata: cluster,
      })
      .then(response => response.data),
  downloadReport: (clusterId: string) =>
    httpService.fetch(`clusters/${clusterId}/report`).then(response => response.data),
  getDataSources: (query?: string) => {
    return httpService
      .fetch<{ data_sources: DataSourceResponse[] }>(`clusters/data-sources${query || ''}`)
      .then(response => response.data.data_sources);
  },
  getClustersAttributes: (query?: string) => {
    return httpService
      .fetch<{ attributes: AttributeResponse[] }>(`clusters/attributes${query || ''}`)
      .then(response => response.data.attributes);
  },
  getClustersData: (query?: string) =>
    httpService.fetch<ClusterResponse>(`clusters?format=json${query || ''}`).then(response => response.data.clusters),
  getClusterById: (clusterId: string) => httpService.fetch(`clusters/${clusterId}`).then(response => response.data),
};
