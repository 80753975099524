import React, { FC } from 'react';
import { DataCatalogAuditTrailGrid } from './DataCatalogAuditTrailGrid';
import { BigidContentItem } from '@bigid-ui/components';
import { DataCatalogRecord } from '../DataCatalogService';
import { DataCatalogGridContextProvider } from './dataCatalogAuditContext';
import { DataCatalogAuditTrailServiceRow } from './DataCatalogAuditTrailServiceRow';
import { styled } from '@mui/material';
import { DataCatalogAuditTrailToolbar } from './DataCatalogAuditTrailToolbar';

const AuditTrailContainer = styled('div')`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
`;

export const DataCatalogAuditTrail: FC<BigidContentItem & DataCatalogRecord> = props => {
  return (
    <DataCatalogGridContextProvider>
      <AuditTrailContainer>
        <DataCatalogAuditTrailToolbar />
        <DataCatalogAuditTrailServiceRow />
        <DataCatalogAuditTrailGrid {...props} />
      </AuditTrailContainer>
    </DataCatalogGridContextProvider>
  );
};
