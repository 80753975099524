import React, { FC } from 'react';
import { BigidLink } from '@bigid-ui/components';
import { Trans } from 'react-i18next';
import { useLocalTranslation } from './translations';
import { docsUrls } from '../../../../config/publicUrls';

export const SensitivityPieChartTooltip: FC = () => {
  const { t } = useLocalTranslation('tooltip');

  const handleLinkClick = () => {
    window.open(docsUrls.SENSITIVITY_CLASSIFICATION, '_blank');
  };

  return (
    <Trans
      t={t}
      i18nKey="text"
      components={{ link: <BigidLink text={t('linkText')} onClick={handleLinkClick} underline="none" /> }}
    />
  );
};
