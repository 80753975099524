import { BigidColorsV2 } from '@bigid-ui/components';
import makeStyles from '@mui/styles/makeStyles';

type StatusColors = {
  background: string;
  fill: string;
};

export const statusColorMap: Record<string, StatusColors> = {
  valid: {
    background: BigidColorsV2.green[700],
    fill: BigidColorsV2.white,
  },
  cantEvaluate: {
    background: BigidColorsV2.white,
    fill: BigidColorsV2.yellow[900],
  },
  wrongCreds: {
    background: BigidColorsV2.red[600],
    fill: BigidColorsV2.white,
  },
};

export const useStyles = makeStyles({
  statusIcon: ({ mode }: { mode: string }) => {
    return {
      padding: 0,
      height: mode === 'cantEvaluate' ? 20 : 16,
      width: mode === 'cantEvaluate' ? 20 : 16,
      backgroundColor: statusColorMap[mode].background,
      marginRight: 8,
    };
  },
  card: {
    width: 124,
    height: 160,
    padding: '16px 0',
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    boxShadow: '0px 0px 5px rgba(0, 0, 0, 0.15);',
    borderRadius: '4px',
    position: 'relative',
  },
  cardLabel: {
    lineHeight: '14px',
    fontSize: 12,
    color: BigidColorsV2.gray[700],
  },
  iconContainer: {
    width: 88,
    height: 52,
  },
  cardTitleContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    padding: '8px 0 16px 0',
  },
  signInButtonWrapper: {
    width: 82,
  },
});
