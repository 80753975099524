import { useLayoutEffect, useState } from 'react';
import { CredentialTypes } from '../types';
import { ActionItemResponse } from '../../CustomApp/types';
import { customAppService } from '../../../services/customAppService';
import { notificationService } from '../../../services/notificationService';
import { useLocalTranslation } from '../translations';
import { getApplicationPreference } from '../../../services/appPreferencesService';

interface UseActions {
  credentialType: CredentialTypes;
  selectedAppId: string;
  selectedInstanceId: string;
}

export const useActions = ({ credentialType, selectedAppId, selectedInstanceId }: UseActions) => {
  const [actions, setActions] = useState<ActionItemResponse[]>([]);
  const { t } = useLocalTranslation();

  useLayoutEffect(() => {
    const fetchActions = async () => {
      try {
        const { data } = await customAppService.getCustomAppActions(
          getApplicationPreference('TPA_MULTIPLE_DEPLOYMENTS_ENABLED') ? selectedInstanceId : selectedAppId,
        );
        const actionsData = (data as ActionItemResponse[]).filter(
          ({ execution_type }) => execution_type === 'OneTimeRun',
        );
        setActions(actionsData);
      } catch (error) {
        notificationService.error(t('message.commonError'));
      }
    };

    if (
      credentialType === CredentialTypes.CustomApplicationVault &&
      getApplicationPreference('TPA_MULTIPLE_DEPLOYMENTS_ENABLED')
        ? selectedInstanceId
        : selectedAppId
    ) {
      fetchActions();
    }
  }, [credentialType, selectedAppId, selectedInstanceId, t]);

  return actions;
};
