import { $state } from '../../../../services/angularServices';
import { httpService } from '../../../../services/httpService';
import { getApplicationPreference } from '../../../../services/appPreferencesService';
import { CONFIG } from '../../../../../config/common';

type DataSourceScanConfig = {
  onSuccess?: () => void;
  onError?: (error?: Error) => void;
};

const DEFAULT_SCAN_TEMPLATE_NAME = 'Data source scan';

export const runDefaultScanProfile = async (
  dataSourceName: string,
  dataSourceType: string,
  { onError, onSuccess }: DataSourceScanConfig = {},
) => {
  const additionalFilters = dataSourceType === 's3' ? '' : '&isSampleScan=true';
  try {
    const response = await httpService.post(
      `ds-connections/scan?scanType=dsScan${additionalFilters}&scanTemplateName=${DEFAULT_SCAN_TEMPLATE_NAME}`,
      {
        dsConnectionsNames: [dataSourceName],
      },
    );
    if (response?.status !== 200) {
      onError?.();
    }
    onSuccess?.();
  } catch (e) {
    onError?.(e);
  }
};

export const runCustomScanProfile = (dataSourceName: string, dataSourceType: string) => {
  const isCreateScanWizardEnabled = getApplicationPreference('ENABLE_SCAN_TEMPLATES');

  isCreateScanWizardEnabled
    ? $state.go(CONFIG.states.CREATE_SCAN, {
        prefill: {
          name: `Custom Scan For ${dataSourceName}`,
          dataSourcesPreselected: [{ name: dataSourceName, type: dataSourceType }],
          isPrefillDsStep: true,
        },
      })
    : $state.go(CONFIG.states.CREATE_SCAN_PROFILE, {
        prefill: { name: dataSourceName },
      });
}
