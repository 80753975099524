import { module } from 'angular';
const app = module('app');
import { httpService } from '../../react/services/httpService';

app.factory('objectsService', [
  '$http',
  'appSettings',
  function ($http, appSettings) {
    return {
      getObjects: filterValue => {
        filterValue = filterValue ? 'filter=' + filterValue : '';
        filterValue = '?limit=20&' + filterValue;
        return $http
          .get(appSettings.serverPath + '/' + appSettings.version + '/piiRecords/objects' + filterValue)
          .then(response => {
            return response.data;
          });
      },
      getObjectsExportFromCatalog: (query = '') => {
        query = query.length > 0 ? `&${query}` : query;

        return $http
          .get(`${appSettings.serverPath}/${appSettings.version}/data-catalog/?format=csv${query}`)
          .then(response => response.data);
      },
      getObjectsExportFromInventory: filterValue => {
        filterValue = filterValue ? '?filter=' + filterValue : '';
        httpService.downloadFile(`piiRecords/objects/file-download/export` + filterValue);
      },
    };
  },
]);
