import { BigidFormFieldTypes, BigidSelectOption } from '@bigid-ui/components';
import { BigidGridRow } from '@bigid-ui/grid';

export enum FieldType {
  TEXT = 'TEXT',
  SELECT = 'SELECT',
  CHECKBOX = 'CHECKBOX',
  TEXTAREA = 'TEXTAREA',
  NUMBER = 'NUMBER',
  PASSWORD = 'PASSWORD',
  DATEPICKER = 'DATEPICKER',
  USER = 'USER',
  MULTISELECT = 'MULTISELECT',
  AUTOCOMPLETE = 'AUTOCOMPLETE',
  DROP_DOWN = 'DROP_DOWN',
}

export const fieldTypeToFormFieldType: Partial<Record<FieldType, BigidFormFieldTypes>> = {
  [FieldType.TEXT]: BigidFormFieldTypes.TEXT,
  [FieldType.SELECT]: BigidFormFieldTypes.SELECT,
  [FieldType.CHECKBOX]: BigidFormFieldTypes.CHECKBOX,
  [FieldType.TEXTAREA]: BigidFormFieldTypes.AUTOCOMPLETE,
  [FieldType.NUMBER]: BigidFormFieldTypes.NUMBER,
  [FieldType.DATEPICKER]: BigidFormFieldTypes.DATEPICKER,
  [FieldType.PASSWORD]: BigidFormFieldTypes.PASSWORD,
  [FieldType.AUTOCOMPLETE]: BigidFormFieldTypes.AUTOCOMPLETE,
  [FieldType.USER]: BigidFormFieldTypes.SELECT,
  [FieldType.MULTISELECT]: BigidFormFieldTypes.SELECT,
  [FieldType.DROP_DOWN]: BigidFormFieldTypes.DROP_DOWN,
};

export interface ConfigurationFormField {
  name: string;
  displayName: string;
  type: FieldType;
  required?: boolean;
  uneditable?: boolean;
  description?: string;
  options?: BigidSelectOption[];
  dropDownOptionsName?: string;
}

export interface ConfigurationMetadata {
  type: string;
  displayName: string;
  fields: ConfigurationFormField[];
}

export type ConfigurationsMetadata = ConfigurationMetadata[];

export interface ConfigurationsMetadataResponse {
  configurationsMetadata: ConfigurationsMetadata;
}

export type ConfigurationObject = Record<string, any>;

export interface Configuration extends BigidGridRow {
  name: string;
  type: string;
  created_at: string;
  updated_at: string;
  configuration: ConfigurationObject;
  isOffline?: boolean;
}

export type ConfigurationFormFields = Pick<Configuration, 'name' | 'type' | 'configuration'>;

export interface EditConfigurationData {
  id: string;
  configuration: ConfigurationFormFields;
}

export interface TestConnectionResponse {
  success: boolean;
  error?: string;
}
