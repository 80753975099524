import React, { FC, useRef, useState, useEffect, useCallback, MutableRefObject } from 'react';
import { styled } from '@mui/material';
import { BigidForm, BigidFormStateAndHandlers, BigidFormValidateOnTypes, BigidFormValues } from '@bigid-ui/components';
import { generateDataAid } from '@bigid-ui/utils';
import { useLocalTranslation } from './translations';
import { UseCatalogDiscoveryResponse } from '../../../useCatalogDiscovery';
import { notificationService } from '../../../../../services/notificationService';
import { useSaveQueryFormConfig } from './useSaveQueryFormConfig';
import { getDefaultSavedFilterFormState, getCreateSavedFilterPayload } from './utils';
import { SavedFilter, createSavedFilter, updateSavedFilter } from './saveQueryService';

export type DialogFormControl = {
  callback: () => void;
};

export type SaveQueryFormStateAndControls = {
  isBusy?: boolean;
  onSave?: DialogFormControl;
};

export interface SaveQueryFormProps
  extends Pick<
    UseCatalogDiscoveryResponse,
    'query' | 'filter' | 'savedQueries' | 'currentSavedQuery' | 'onSavedQueryCreate' | 'onSavedQueryUpdate'
  > {
  dataAid?: string;
  dialogControls: MutableRefObject<SaveQueryFormStateAndControls>;
  onClose: () => void;
  onDialogControlsChange: (dialogControls: SaveQueryFormStateAndControls) => void;
}

const Root = styled('div')`
  height: 70vh;
  flex: 1;
  position: relative;
`;

export const SaveQueryForm: FC<SaveQueryFormProps> = ({
  dataAid = 'SaveQueryForm',
  dialogControls,
  onDialogControlsChange,
  onClose,
  currentSavedQuery,
  filter,
  query,
  savedQueries,
  onSavedQueryCreate,
  onSavedQueryUpdate,
}) => {
  const { t } = useLocalTranslation('actions.saveQuery');
  const formControls = useRef<BigidFormStateAndHandlers>();
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const { fields } = useSaveQueryFormConfig({
    isNewFilter: !Boolean(currentSavedQuery),
    savedQueries,
  });

  const submitForm = useCallback(
    async (formData: BigidFormValues) => {
      const shouldSaveAsCurrent = formData.saveAsNew === 'false';
      const payload: SavedFilter = getCreateSavedFilterPayload(
        formData,
        filter,
        shouldSaveAsCurrent ? currentSavedQuery?.id : undefined,
      );

      try {
        setIsSubmitting(true);

        if (shouldSaveAsCurrent) {
          await updateSavedFilter(payload);
          onSavedQueryUpdate(payload);
        } else {
          await createSavedFilter(payload);
          onSavedQueryCreate(payload);
        }

        onClose();
        notificationService.success(t('dialog.notifications.successSaving', { name: payload.name }));
      } catch ({ message }) {
        console.error(`An error has occurred: ${message}`);
        notificationService.error(t('dialog.notifications.failureSaving', { name: payload.name }));
      } finally {
        setIsSubmitting(false);
      }
    },
    [currentSavedQuery?.id, filter, onClose, onSavedQueryCreate, onSavedQueryUpdate, t],
  );

  const dialogFormControls: SaveQueryFormStateAndControls = {
    isBusy: isSubmitting,
    onSave: {
      callback: async () => {
        await formControls.current.validateAndSubmit(values => {
          submitForm(values);
        });
      },
    },
  };

  useEffect(() => {
    if (dialogControls) {
      onDialogControlsChange(dialogFormControls);
      dialogControls.current = dialogFormControls;
    }
  });

  return (
    <Root data-aid={generateDataAid(dataAid, ['form'])}>
      <BigidForm
        stateAndHandlersRef={formControls}
        controlButtons={false}
        fields={fields}
        initialValues={getDefaultSavedFilterFormState(query, currentSavedQuery)}
        validateOn={BigidFormValidateOnTypes.SUBMIT}
      />
    </Root>
  );
};
