import React, { useCallback, useEffect } from 'react';
import styled from '@emotion/styled';
import { BigidErrorBoundary, BigidPageLayout, BigidPaper, PrimaryButton, SecondaryButton } from '@bigid-ui/components';
import { BigidAddIcon, BigidDownloadIcon } from '@bigid-ui/icons';
import { VendorsImportDialog, useVendorsImportDialog } from './VendorsImportDialog';
import { pageHeaderService } from '../../../common/services/pageHeaderService';
import { VendorsGrid } from './VendorsGrid';
import { VendorsEditDialog, useVendorsEditDialog } from './VendorsEditDialog';
import { VendorsTrackingEvents, trackVendors } from './utils/analytics';
import { VendorProject } from './VendorsTypes';
import { VENDORS_PERMISSIONS } from '@bigid/permissions';
import { isPermitted } from '../../services/userPermissionsService';
import { VendorDeleteDetails, VendorsDeleteDialog, useVendorsDeleteDialog } from './VendorsDeleteDialog';
import { BigidPageFavoriteToggler } from '../../components/BigidHeader/BigidPageTitle/BigidPageFavoriteToggler';

export const ContentWrapper = styled.div`
  height: calc(100% - 92px);
  display: flex;
  max-width: 100vw;
`;

export const Vendors = () => {
  const { dialogProps, openDialog, key } = useVendorsEditDialog();
  const { importDialogProps, openImportDialog } = useVendorsImportDialog();
  const { deleteDialogProps, openDeleteDialog } = useVendorsDeleteDialog();

  useEffect(() => {
    pageHeaderService.setIsHidden(true);
    return () => {
      pageHeaderService.setIsHidden(false);
    };
  }, []);

  const openCreateEditVendorDialog = useCallback(
    async (vendorProject?: VendorProject) => {
      await openDialog(vendorProject);
    },
    [openDialog],
  );

  const openDeleteVendorDialog = useCallback(
    async (selectedVendor: VendorDeleteDetails) => {
      await openDeleteDialog(selectedVendor);
    },
    [openDeleteDialog],
  );

  return (
    <BigidErrorBoundary>
      <BigidPageLayout
        actions={[
          isPermitted(VENDORS_PERMISSIONS.VENDOR_CREATE.name) && (
            <PrimaryButton
              key="1"
              size="medium"
              text="Add Vendor"
              startIcon={<BigidAddIcon />}
              onClick={() => {
                trackVendors(VendorsTrackingEvents.CREATE_VENDOR_DIALOG_OPEN_CLICK);
                openCreateEditVendorDialog();
              }}
              bi={{ disabled: true }}
            />
          ),
          isPermitted(VENDORS_PERMISSIONS.VENDOR_CREATE.name) && (
            <SecondaryButton
              key="2"
              onClick={() => {
                trackVendors(VendorsTrackingEvents.IMPORT_VENDORS_DIALOG_OPEN_CLICK);
                openImportDialog();
              }}
              size="medium"
              text="Import"
              startIcon={<BigidDownloadIcon />}
              bi={{ disabled: true }}
            />
          ),
        ].filter(Boolean)}
        dataAid="vendors"
        titleIcons={[<BigidPageFavoriteToggler key="BigidPageFavoriteToggler" />]}
        title="Vendors"
        pageBackgroundColor="transparent"
        showHeaderBackground={false}
      >
        <ContentWrapper>
          <BigidPaper>
            <VendorsGrid
              openCreateEditVendorDialog={openCreateEditVendorDialog}
              openDeleteVendorDialog={openDeleteVendorDialog}
              forceUpdateKeys={{
                forceUpdateVendorsKey: key.vendorsKey,
                ForceUpdateDataActivityKey: key.dataActivityKey,
              }}
            />
            <VendorsEditDialog {...dialogProps} />
            <VendorsImportDialog {...importDialogProps} />
            <VendorsDeleteDialog {...deleteDialogProps} />
          </BigidPaper>
        </ContentWrapper>
      </BigidPageLayout>
    </BigidErrorBoundary>
  );
};
