import { Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { MetadataSearchProps, MetadataSearchVariant } from './MetadataSearch';

type MetadataSearchStyle = Pick<MetadataSearchProps, 'width' | 'variant'> & {
  maxPopperHeight: number;
  popperWidth: number;
};

export const useStyles = makeStyles<Theme, MetadataSearchStyle>(theme => ({
  root: ({ width }) => ({
    border: `1px solid ${theme.palette.bigid.gray300}`,
    borderRadius: '4px',
    height: 32,
    width: `${width}px`,
    '&.enlarged': {
      height: '100%',
      width: '100%',
      boxShadow: theme.vars.tokens.bigid.shadow10,
      border: `1px solid ${theme.palette.bigid.gray200}`,
    },
    '@media only screen and (max-width: 1440px)': {
      width: '448px',
    },
  }),
  searchBarContainer: {
    width: '100%',
    height: '100%',
    display: 'flex',
  },
  searchBar: {
    width: '100%',
  },
  popper: ({ width, popperWidth, maxPopperHeight, variant }: MetadataSearchStyle) => ({
    display: 'flex',
    width: `${popperWidth || width - 31}px`, // minus indicator width and its offest, fallback value
    maxHeight: `${maxPopperHeight}px`,
    position: 'relative',
    marginTop: `${variant === MetadataSearchVariant.ENLARGED ? 4 : 0}px`,
  }),
}));
