import { BigidBody1, BigidColorsV2 } from '@bigid-ui/components';
import styled from '@emotion/styled';
import { FormLabel } from '@mui/material';

/**
 * Main
 */
export const VendorSummaryMainWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: stretch;
  overflow: auto;
  padding: 8px 24px;
  width: calc(100% - 365px);
  border-right: 1px solid ${BigidColorsV2.gray[200]};
  margin-bottom: 16px;
`;

export const VendorDetailsWrapper = styled('div')<{ fullWidth?: boolean }>(({ theme }) => ({
  display: 'flex',
  flexFlow: 'row wrap',
  marginBottom: theme.spacing(3),
  padding: `${theme.spacing(2)} ${theme.spacing(4)}`,
  gap: theme.spacing(1.5),
  backgroundColor: BigidColorsV2.gray[125],
}));

export const VendorDetailsSectionWrapper = styled.div`
  display: inline-flex;
  flex-direction: column;
  width: calc((100% / 3) - (32px / 3));
  padding: 8px 0;
`;

export const VendorDetailsSectionWrapperFullWidth = styled(VendorDetailsSectionWrapper)`
  width: 100%;
`;

export const DetailsBold = styled.span`
  font-size: 0.875rem;
  font-weight: 600;
  line-height: 1.5;
`;

export const VendorDetailsSectionDescriptionWrapper = styled(DetailsBold)`
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  text-overflow: ellipsis;
`;

/**
 * Aside
 */
export const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  min-width: 24px;
`;

export const FlexWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const FlexWrapperHidden = styled(FlexWrapper)`
  overflow: hidden;
`;

export const LabelWithEllipsis = styled(BigidBody1)`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const ContactDetailsEmptyStateWrapper = styled(FormLabel)`
  padding-left: 8px;
`;

export const DetailsWrapper = styled(FlexWrapper)`
  gap: 8px;
`;

export const VendorSummaryAsideItemWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  min-height: 30px;
  overflow: hidden;
`;

export const VendorSummaryAsideLogItemWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  min-height: 64px;
  border-bottom: 1px solid ${BigidColorsV2.gray[200]};
  padding: 0 8px 8px;

  &:last-of-type {
    border-bottom: 0;
  }

  label {
    margin-bottom: 0;
  }
`;

export const VendorSummaryAsideWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: stretch;
  overflow: auto;
  padding: 8px 24px;
  width: 365px;
  gap: 32px;
`;

export const NotesBold = styled.span`
  font-size: 0.75rem;
  font-weight: 600;
  line-height: 0.875rem;
`;

export const NotesBoldEllipsis = styled(NotesBold)`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const NotesBoldGray = styled(NotesBoldEllipsis)`
  color: ${BigidColorsV2.gray[500]};
`;

/**
 * Content (Main + Aside)
 */

export const Wrapper = styled.div`
  height: 100%;
`;

export const ContentWrapper = styled.div`
  display: flex;
  border-top: 1px solid ${BigidColorsV2.gray[200]};
  height: calc(100% - 32px);
  padding: 16px 0;

  & * {
    -ms-overflow-style: none !important;
  }

  & *::-webkit-scrollbar {
    width: 0 !important;
  }
`;

export const TabsWrapper = styled.div`
  padding-left: 24px;
  min-height: 48px;
  display: flex;
  align-items: end;
`;

/**
 * No data - empty state wrapper
 */

export const EmptyStateWrapper = styled('td')`
  width: 100%;
  height: calc(100% - 42px);
  position: absolute;
`;

/**
 * Assessments
 */
export const AssessmentsWrapper = styled('div')`
  display: flex;
  flex-wrap: wrap;
  gap: 32px;
`;

export const AssessmentWrapper = styled('div')`
  width: calc(50% - 16px);
  flex-grow: 1;
`;

export const AssessmentListItemTitleWrapper = styled('div')`
  display: flex;
  flex-flow: column nowrap;
  width: calc(100% - 120px);

  & {
    a {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
`;

export const AssessmentEmptyStateWrapper = styled('div')`
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  font-weight: 600;
  width: 100%;
  padding: 52px 0px;
  gap: 16px;
  height: 100%;
`;

export const AssessmentSectionContainer = styled(FlexWrapper)`
  gap: 12px;
  height: 100%;
`;

export const SpacedBetweenFlex = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const AssessmentSectionLabelWrapper = styled(SpacedBetweenFlex)`
  padding-left: 8px;
  fontsize: 14px;
  line-height: 22px;
  min-height: 32px;
`;

export const AssessmentListItemWrapper = styled(SpacedBetweenFlex)`
  width: 100%;
  padding: 10px 12px;
  border-bottom: 1px solid ${BigidColorsV2.gray[200]};
  min-height: 56px;
  &:last-child {
    border-bottom: none;
  }
`;

export const AssessmentListWrapper = styled.div`
  padding: 8px 12px;
  border-radius: 8px;
  background-color: ${BigidColorsV2.gray[125]};
  height: 100%;
`;

export const CountLabelWrapper = styled.strong`
  color: ${BigidColorsV2.gray[500]};
`;

export const ErrorAssessmentWrapper = styled.div`
  display: flex;
  flex-flow: column;
  gap: 12px;
  align-items: center;
  padding: 24px;
`;

export const ErrorAssessmentTextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  gap: 4px;
  justify-content: center;
  align-items: center;
`;
