import React, { FC, useEffect, useMemo, useState } from 'react';
import {
  ActionsFormatterAlignment,
  BigidGridColumn,
  BigidGridColumnTypes,
  BigidGridQueryComponents,
  BigidGridWithToolbar,
  BigidGridWithToolbarProps,
  FormatterOnClickHandlerPayloadData,
} from '@bigid-ui/grid';
import { BigidLoader, BigidPaper, EntityEvents, entityEventsEmitter } from '@bigid-ui/components';
import angular from 'angular';
import { convertToAngular } from '../../../../common/services/convertToAngular';
import { localSortAndFilterGridData } from '../ServicesConfiguration/servicesConfigurationService';
import { HealthCheckGridRow, HealthCheckStatusGridProps } from './types';
import {
  clearHealthCheckServices,
  clearTenantScannerHealthCheckServices,
  fetchHealthCheckServices,
  fetchTenantScannerHealthCheckServices,
  restartService,
} from './healthCheckStatusService';
import styled from '@emotion/styled';
import { BigidRefreshIcon } from '@bigid-ui/icons';
import { ADVANCE_TOOLS_PERMISSIONS } from '@bigid/permissions';
import { isPermitted } from '../../../services/userPermissionsService';
import { showConfirmationDialog } from '../../../services/confirmationDialogService';
import { notificationService } from '../../../services/notificationService';
import { isMultiTenantModeEnabled } from '../../../utilities/multiTenantUtils';

const GridWrapper = styled.div({
  width: '100%',
  height: '100%',
  display: 'flex',
  flex: '1 1 auto',
  flexFlow: 'column nowrap',
  overflow: 'hidden',
});

const columns: BigidGridColumn<HealthCheckGridRow>[] = [
  {
    name: 'id',
    title: 'ID',
    width: 350,
    getCellValue: row => row.id,
    type: BigidGridColumnTypes.TEXT,
  },
  {
    name: 'type',
    title: 'Service Type',
    width: 200,
    getCellValue: row => row.type,
    type: BigidGridColumnTypes.TEXT,
  },
  {
    name: 'name',
    title: 'Service Name',
    width: 200,
    getCellValue: row => row.name,
    type: BigidGridColumnTypes.TEXT,
  },
  {
    name: 'message',
    title: 'Message, Version',
    width: 200,
    getCellValue: row => row.message,
    type: BigidGridColumnTypes.TEXT,
  },
  {
    name: 'status',
    title: 'Status',
    width: 200,
    getCellValue: row => row.status,
    type: BigidGridColumnTypes.TEXT,
  },
  {
    name: 'updateDate',
    title: 'Updated at',
    width: 200,
    getCellValue: row => row.updateDate,
    type: BigidGridColumnTypes.TEXT,
  },
  {
    name: 'createDate',
    title: 'Created at',
    width: 200,
    getCellValue: row => row.createDate,
    type: BigidGridColumnTypes.TEXT,
  },
  {
    name: 'actions',
    title: 'Actions',
    sortingEnabled: false,
    isNotDraggable: true,
    width: 100,
    getCellValue: row => {
      if (row.type === 'scanner' && row.status === 'ACTIVE' && !row.isRefreshDisabled) {
        return {
          alignment: ActionsFormatterAlignment.CENTER,
          actions: [
            {
              iconConfig: {
                icon: BigidRefreshIcon,
                isVisible: isPermitted(ADVANCE_TOOLS_PERMISSIONS.RESTART_SCANNER_SYSTEM_HEALTH.name),
              },
              execute: async ({ row }: FormatterOnClickHandlerPayloadData) => {
                const isRestartScanner = await showConfirmationDialog({
                  entityNameSingular: 'Service',
                  entityNamePlural: 'Services',
                  actionButtonName: 'Restart',
                  actionName: 'Restart',
                  customDescription: `Are you sure you want to restart ${row.type} service with ID: ${row.id}? Running scans will be aborted.`,
                });
                if (isRestartScanner) {
                  notificationService.success('Request to restart the service received');
                  restartService({ id: row.id, type: row.type });
                  entityEventsEmitter.emit(EntityEvents.UPDATE_BY_ID, row.id, {
                    ...row,
                    isRefreshDisabled: true,
                  });
                }
                return {
                  shouldGridReload: false,
                  row,
                };
              },
            },
          ],
        };
      }
      return null;
    },
    type: BigidGridColumnTypes.ACTIONS,
  } as BigidGridColumn<HealthCheckGridRow>,
];

export const HealthCheckStatusGrid: FC<HealthCheckStatusGridProps> = ({ mtScannerHealth }) => {
  const [rows, setRows] = useState<HealthCheckGridRow[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    (async () => {
      let results = null;
      if (mtScannerHealth) {
        results = await fetchTenantScannerHealthCheckServices();
      } else {
        results = await fetchHealthCheckServices();
      }
      setRows(results);
      setIsLoading(false);
    })();
  }, []);

  const gridWithToolbarConfig: BigidGridWithToolbarProps<HealthCheckGridRow> = useMemo(() => {
    return {
      pageSize: 100,
      columns,
      entityName: 'configurations',
      showSelectionCheckboxes: false,
      showSortingControls: true,
      hideColumnChooser: true,
      defaultSorting: [{ field: 'name', order: 'asc' }],
      filterToolbarConfig: {
        searchConfig: {
          searchFilterKeys: ['name'],
          placeholder: 'Service Name',
          initialValue: '',
          operator: 'contains',
          autoSubmit: true,
        },
      },
      toolbarActions: [
        {
          label: 'Refresh',
          show: () => true,
          isGlobal: true,
          execute: async () => {
            setIsLoading(true);
            let refreshedData;
            if (isMultiTenantModeEnabled()) {
              refreshedData = await fetchTenantScannerHealthCheckServices();
            } else {
              refreshedData = await fetchHealthCheckServices();
            }
            setRows(refreshedData);
            setIsLoading(false);
            return { shouldGridReload: true };
          },
        },
        {
          label: 'Clear Status',
          show: () => true,
          isGlobal: true,
          execute: async () => {
            setIsLoading(true);
            if (isMultiTenantModeEnabled()) {
              await clearTenantScannerHealthCheckServices();
            } else {
              await clearHealthCheckServices();
            }
            setRows([]);
            setIsLoading(false);
            return { shouldGridReload: true };
          },
        },
      ],
      fetchData: async (queryComponents: BigidGridQueryComponents) => {
        return localSortAndFilterGridData(rows, queryComponents);
      },
    };
  }, [rows]);

  return (
    <GridWrapper>
      <BigidPaper>
        {isLoading ? <BigidLoader label={'Loading Services'} /> : <BigidGridWithToolbar {...gridWithToolbarConfig} />}
      </BigidPaper>
    </GridWrapper>
  );
};

angular.module('app').component('healthCheckStatusGrid', convertToAngular(HealthCheckStatusGrid, ['mtScannerHealth']));
