import React, { FC, useEffect, Fragment, useReducer, useState, useMemo, useCallback, useRef } from 'react';
import { last } from 'lodash';
import Postmate, { ParentAPI } from 'postmate';
import { BigidLoader } from '@bigid-ui/components';
import makeStyles from '@mui/styles/makeStyles';

import {
  ChildDataInterface,
  sdkHandler,
  ChildResponse,
  sdkResponse,
  initSdkSupportedEvents,
  callChild,
} from './sdk/sdkHandler';
import { CustomAppDialog, CustomAppDialogProps } from './sdk/CustomAppDialog';
import { SystemEvents, systemEventsEmitter } from '../../../../services/systemEvents';
import { tpaStatusService } from '../../../../services/tpaStatusService';
import { leaveFormInterceptor, ParentTopic } from './sdk/sdkTopicHandler';
import { pageHeaderService } from '../../../../../common/services/pageHeaderService';
import { AppInfo } from '../../utils/CustomAppTypes';
import { CustomAppMenu } from '../../components/CustomAppMenu';
import { handleMenuItemSelect } from '../../CustomAppHeader';
import { headerEventEmitter, HeaderEvents } from '../../../../services/eventEmitters/headerEvents';

interface CustomAppUiProps {
  appInfo: AppInfo;
  isWidget?: boolean;
  appRoute?: string;
  dynamicAppRoute?: string;
}

interface ReducerState {
  dialogProps: CustomAppDialogProps;
}

export interface Action {
  type: string;
  payload: Record<string, any>;
}

const TIME_TO_REMOVE_LOADER = 5000;

const useStyles = makeStyles({
  iframeWrapper: {
    height: '100%',
    overflow: 'hidden',
  },
  loader: {
    position: 'relative',
    width: '100%',
    height: '100%',
  },
  customUiIframe: {
    width: '100%',
    height: '100%',
    border: 'none',
    margin: '0',
    padding: '0',
    borderRadius: '5px',
  },
  disableIFrame: {
    pointerEvents: 'none',
    opacity: '0.2',
  },
  menu: {
    float: 'right',
    backgroundColor: '#f1f3f7',
    position: 'absolute',
    right: '10px',
    bottom: '10px',
  },
});

const getProxyUiUrl = (customAppId: string) => `/proxy/tpa/ui/${customAppId}`;

const CustomAppUIComponent: FC<CustomAppUiProps> = ({ appInfo, isWidget, appRoute, dynamicAppRoute }) => {
  const classes = useStyles({});
  const [isApplicationUILoading, setIsApplicationUILoading] = useState<boolean>(false);
  const [showFloatingMenu, setShowFloatingMenu] = useState<boolean>(false);

  const uniqueId: string = useMemo(() => {
    const widgetName = last(appRoute.split('/'));
    return `${appInfo.id}_${widgetName}`;
  }, [appInfo.id, appRoute]);

  const IFRAME_ID = `IFRAME_ID_${uniqueId}`;
  const initialState: ReducerState = {
    dialogProps: {
      isOpen: false,
    },
  };

  const [state, dispatch] = useReducer((state: ReducerState, action: Action) => {
    const { type, payload } = action;
    switch (type) {
      case 'openDialog':
        return { ...state, dialogProps: { ...state.dialogProps, ...payload } };
      default:
        return state;
    }
  }, initialState);

  const childRef = useRef<ParentAPI | null>(null);

  useEffect(() => {
    setIsApplicationUILoading(true);
    let _child: ParentAPI | null = null;
    let isComponentDestroyed = false;
    let deregisterLeaveFormInterceptor: () => void;
    const classListArray = [classes.customUiIframe];
    const { uiUrl, isUseProxy } = appInfo;
    let url = isUseProxy ? getProxyUiUrl(appInfo.id) : uiUrl;
    url = appRoute ? `${url}/${appRoute}` : url;
    tpaStatusService.isTpaOffline(appInfo.status) && classListArray.push(classes.disableIFrame);
    new Postmate({
      container: document.getElementById(IFRAME_ID),
      url,
      classListArray,
    }).then((child: ParentAPI) => {
      _child = child;
      childRef.current = child;
      child.on('sdk-action', (data: ChildDataInterface) => sdkHandler(child, data, appInfo, dispatch));
      child.on('sdk-response', (data: ChildResponse) => sdkResponse(data));
      !isWidget && child.on('iframe-interaction', () => systemEventsEmitter.emit(SystemEvents.IFRAME_HEARTBEAT));
      child.get('sdkSupportedTopics').then(supported => initSdkSupportedEvents(supported));
      !isComponentDestroyed && (deregisterLeaveFormInterceptor = leaveFormInterceptor(_child));
      !isComponentDestroyed && setIsApplicationUILoading(false);
    });
    const loaderRemovalTimer = setTimeout(() => setIsApplicationUILoading(false), TIME_TO_REMOVE_LOADER);
    const headerHideEventListener = headerEventEmitter.addEventListener(HeaderEvents.HEADER_HIDE, msg => {
      setShowFloatingMenu(msg.data.isHidden);
    });

    return () => {
      headerHideEventListener();
      pageHeaderService.setIsHidden(false);
      isComponentDestroyed = true;
      loaderRemovalTimer && clearTimeout(loaderRemovalTimer);
      deregisterLeaveFormInterceptor && deregisterLeaveFormInterceptor();
      if (_child) {
        _child.destroy();
        _child.call('removeInteractionEvent');
      }
    };
  }, [IFRAME_ID, appInfo, appRoute, classes.customUiIframe, classes.disableIFrame, isWidget]);

  const updateIframeRoute = useCallback(
    (dynamicAppRoute: string) => {
      if (childRef.current) {
        callChild(childRef.current, ParentTopic.UPDATE_APP_ROUTE, { appRoute: dynamicAppRoute });
      }
    },
    [childRef],
  );

  useEffect(() => {
    updateIframeRoute(dynamicAppRoute);
  }, [dynamicAppRoute, updateIframeRoute]);

  const { dialogProps } = state;
  return (
    <Fragment>
      {isApplicationUILoading && (
        <div className={classes.loader}>
          <BigidLoader />
        </div>
      )}
      <div id={IFRAME_ID} className={classes.iframeWrapper}>
        {showFloatingMenu && (
          <CustomAppMenu
            className={classes.menu}
            onItemSelect={handleMenuItemSelect(appInfo)}
            isInteractive={appInfo.isInteractive && !appInfo.hideUI}
            uiTabName={appInfo.uiTabName}
            popMenuDown={false}
          />
        )}
      </div>
      <CustomAppDialog {...dialogProps} />
    </Fragment>
  );
};

export const CustomAppUI = React.memo(CustomAppUIComponent);
