import template from './newBreachResponse.component.html';
import { module } from 'angular';
const app = module('app');

app.component('newBreachResponse', {
  template,
  controllerAs: 'newBreachResponseModel',
  controller: [
    '$http',
    'appSettings',
    '$scope',
    'notificationService',
    '$state',
    '$stateParams',
    'breachResponseService',
    '$rootScope',
    function (
      $http,
      appSettings,
      $scope,
      notificationService,
      $state,
      $stateParams,
      breachResponseService,
      $rootScope,
    ) {
      var newBreachResponseModel = this;

      $scope.data = {};

      newBreachResponseModel.patterns = {
        digitsOnly: /^\d+$/,
      };

      newBreachResponseModel.originPassword = '';
      newBreachResponseModel.availableEnabledOptions = ['yes', 'no'];

      var getBreachResponseById = function (id) {
        breachResponseService.getBreachResponseById(id).then(
          function (result) {
            newBreachResponseModel.originPassword = result.data.dsConnection.password.slice(0, 8);
            $scope.data = angular.copy(result.data);
            $scope.data.dsConnection.password = $scope.data.dsConnection.password.slice(0, 8);
          },
          function () {
            notificationService.success('An error has occurred!');
          },
        );
      };

      var createBreachResponse = function (data) {
        breachResponseService.createBreachResponse(data).then(
          function () {
            notificationService.success('A new breach response has been created successfully!');
            $state.go('breachResponse');
          },
          function () {
            notificationService.error('An error has occurred!');
          },
        );
      };

      var updateBreachResponse = function (data) {
        breachResponseService.updateBreachResponse(data).then(
          function () {
            notificationService.success('Updated Successfully!');
            $state.go('breachResponse');
          },
          function () {
            notificationService.error('An error has occurred!');
          },
        );
      };

      if ($stateParams.id) {
        $rootScope.$broadcast('changePage', 'Edit Breach Response', false);
        getBreachResponseById($stateParams.id);
      } else {
        newBreachResponseModel.password = '';
        $rootScope.$broadcast('changePage', 'New Breach Response', false);
      }

      newBreachResponseModel.submit = function (isValid) {
        if (isValid) {
          var formData = angular.copy($scope.data);
          if ($stateParams.id) {
            if ($scope.data.dsConnection.password == newBreachResponseModel.originPassword) {
              delete formData['dsConnection']['password'];
            }
            updateBreachResponse(formData, $scope.data._id);
          } else {
            createBreachResponse(formData);
          }
        }
      };
    },
  ],
});
