import './systemUsers.component.scss';
import systemUsersTemplate from './systemUsers.component.html';
import '../../../react/views/AccessManagement/components/RefreshTokenDialog/RefreshTokenDialog';
import { isEmpty } from 'lodash';
import { module } from 'angular';
import { ACCESS_MANAGEMENT_PERMISSIONS } from '@bigid/permissions';
import { dateTimeService } from '@bigid-ui/i18n';
import '../../../react/views/AccessManagement/components/SystemUsers/RolePicker';
import { isPermitted, updateUserPermissions } from '../../../react/services/userPermissionsService';
import { $transitions } from '../../../react/services/angularServices';
import { getUsersQuery } from '../../../react/utilities/systemUsersUtils';
import { isStrongPasswordSecurityEnabled, passwordRegex } from '../../../react/services/passwordService';
import { showDeleteACEntityDialog } from '../../../react/views/ActionCenter/DeleteEntityDialog/DeleteEntityDialogContent';
import { getApplicationPreference } from '../../../react/services/appPreferencesService';
import { EntityType } from '../../../react/views/ActionCenter/ActionWorkflow/actionWorkflowTypes';
const app = module('app');

app.component('systemUsers', {
  template: systemUsersTemplate,
  controller: function (
    $scope,
    $translate,
    notificationService,
    $filter,
    $timeout,
    rolesService,
    systemUsersService,
    $document,
    DeleteConfirmation,
    $rootScope,
    $q,
  ) {
    'ngInject';
    const GRID_COMMON_CONFIG = {
      enableSorting: true,
      enableFiltering: true,
      enablePaginationControls: false,
      appScopeProvider: this,
    };

    const GRID_COLUMN_REVOKE = {
      name: 'revoke',
      headerCellTemplate: '<div class="ui-grid-cell-contents">Revoke</div>',
      cellTemplate:
        '<div class="trash-cell" ng-if="grid.appScope.isManagePermitted"><span class="fa fa-trash-o"' +
        ' ng-click="grid.appScope.onRevokeToken(row.entity)"></span></div>',
      width: '10%',
      enableFiltering: false,
      enableSorting: false,
    };

    const GRID_COLUMN_DELETE = {
      name: 'delete',
      headerCellTemplate:
        '<div class="trash-cell" ng-if="grid.appScope.isManagePermitted"><span class="fa fa-trash-o" ng-click="grid.appScope.onDeleteAllRows()" title="Delete all rows" data-aid="delete-all-rows"></span></div>',
      cellTemplate:
        '<div class="trash-cell" ng-if="grid.appScope.isManagePermitted"><span class="fa fa-trash-o" ng-click="grid.appScope.onDeleteRow(row.entity)" title="Delete row"></span></div>',
      width: '5%',
      enableFiltering: false,
      enableSorting: false,
    };

    const TRANSLATION_REQUIRED = [
      'SYSTEM_USERS:GRID:ROLES',
      'SYSTEM_USERS:GRID:DESCRIPTION',
      'SYSTEM_USERS:GRID:TOKEN_NAME',
      'SYSTEM_USERS:GRID:CREATION_DATE',
      'SYSTEM_USERS:GRID:EXPIRATION_DATE',
    ];

    const SYSTEM_USERS_DATA_LIST_ID = 'systemUsersDataList';
    const SYSTEM_USERS_CONTENT_BODY_ID = 'systemUsersContentBody';
    const SYSTEM_USERS_DATA_LIST_ITEM_ID = 'user';

    const dataListContainer = $document[0].getElementById(SYSTEM_USERS_DATA_LIST_ID);
    const contentBodyContainer = $document[0].getElementById(SYSTEM_USERS_CONTENT_BODY_ID);

    let tokensGridConfig = {};
    let rolesGridConfig = {};

    let isFormDirty = false;

    this.users = [];
    this.user = {};
    this.tokens = [];
    this.roles = [];
    this.authUser = {};
    this.userQuery = '';
    this.passwordSectionVisible = false;
    this.editModeOn = false;
    this.createModeOn = false;
    this.deleteBtnEnabled = false;
    this.userName = '';
    this.systemUserForm = {};
    this.tokensGridConfig = {};
    this.rolesGridConfig = {};
    this.tokensGridApi = null;
    this.rolesGridApi = null;
    this.oktaMaxUsers = getApplicationPreference('OKTA_MAX_USERS');
    this.useSaas = getApplicationPreference('USE_SAAS');
    this.shouldDisableAddUser = false;
    this.selectedRoles = [];
    this.isStrongPasswordEnabled = isStrongPasswordSecurityEnabled();
    this.passwordRegex = this.isStrongPasswordEnabled ? passwordRegex : '.*';

    this.refreshTokenDialog = {
      isOpen: false,
      translations: null,
      refreshTokenData: null,
      onClose: () => {
        closeRefreshTokenDialog();
      },
      onGenerate: expiresInDays => {
        generateRefreshToken(expiresInDays);
      },
      onCopy: () => {
        copyToClipboard();
      },
    };
    const columnSorting = (a, b, dateA, dateB) => {
      return new Intl.Collator().compare(dateA.entity.expirationDate, dateB.entity.expirationDate);
    };

    const initializeTokensGridConfig = tokensGridColumnsNames => {
      const TOKENS_GRID_CONFIG = {
        columnDefs: [
          {
            name: tokensGridColumnsNames.tokenName,
            field: 'tokenName',
            width: '40%',
            enableFiltering: false,
          },
          {
            name: tokensGridColumnsNames.creationDate,
            field: 'creationDateDisplay',
            width: '25%',
            enableFiltering: false,
            sortingAlgorithm: columnSorting,
          },
          {
            name: tokensGridColumnsNames.expirationDate,
            field: 'expirationDateDisplay',
            width: '25%',
            enableFiltering: false,
            sortingAlgorithm: columnSorting,
          },
        ],
        data: [],
      };

      tokensGridConfig = TOKENS_GRID_CONFIG;
      this.tokensGridConfig = { ...tokensGridConfig, ...GRID_COMMON_CONFIG };
      this.tokensGridConfig.columnDefs = [...this.tokensGridConfig.columnDefs, GRID_COLUMN_REVOKE];
      this.tokensGridConfig.onRegisterApi = gridApi => {
        this.tokensGridApi = gridApi;
      };
    };

    const initializeRolesGridConfig = rolesGridColumnsNames => {
      const ROLES_GRID_CONFIG = {
        columnDefs: [
          {
            name: rolesGridColumnsNames.name,
            field: 'displayName',
            width: '20%',
            enableFiltering: false,
          },
          {
            name: rolesGridColumnsNames.description,
            field: 'description',
            width: '75%',
            enableFiltering: false,
          },
        ],
        data: [],
      };

      rolesGridConfig = ROLES_GRID_CONFIG;
      this.rolesGridConfig = { ...rolesGridConfig, ...GRID_COMMON_CONFIG };
      this.rolesGridConfig.columnDefs = [...this.rolesGridConfig.columnDefs, GRID_COLUMN_DELETE];
      this.rolesGridConfig.onRegisterApi = gridApi => {
        this.rolesGridApi = gridApi;
      };
    };

    // API calls -- start
    const getRBACRolesUsers = async () => {
      const gridConfigQuery = getUsersQuery({});
      return await Promise.all([rolesService.getRBACUsers(gridConfigQuery), rolesService.getRBACRoles()]);
    };

    const getAuthUser = () => systemUsersService.getAuthUser();

    const initializeData = async callback => {
      try {
        const [
          {
            data: { users },
          },
          {
            data: { roles },
          },
        ] = await getRBACRolesUsers();

        const authUserResponse = await getAuthUser();
        const authUser = authUserResponse.data;

        return callback({ roles, users, authUser });
      } catch (err) {
        $translate('API:MESSAGE:COMMON_ERROR').then(translation => {
          notificationService.error(translation);
        });

        return callback({ roles: [], users: [], authUser: {} });
      }
    };

    const fetchUsers = ({ scrollTo = '', entityToProceed = null, fromDelete = false }) => {
      this.onDataLoading();

      Promise.all([
        rolesService.getRBACUsers(getUsersQuery({ searchString: this.userQuery })),
        ...(this.userName
          ? [
              rolesService.getRBACUsers(
                getUsersQuery({ filter: [{ field: 'name', operator: 'equal', value: this.userName }] }),
              ),
            ]
          : []),
      ]).then(
        res => {
          const [result, resultFromUpdate] = res;
          const { users } = result.data;
          const currentAmountOfOktaUsers = this.users.filter(user => user.origin === 'Okta').length;
          const newAmountOfOktaUsers = users.filter(user => user.origin === 'Okta').length;
          const isNewOktaUserAdded =
            currentAmountOfOktaUsers === this.oktaMaxUsers - 1 && newAmountOfOktaUsers === this.oktaMaxUsers;

          if (isNewOktaUserAdded) {
            notificationService.warning($translate.instant('SYSTEM_USERS:MAX_OKTA_ERROR_MESSAGE'));
          }

          this.users = users.map(user => {
            const date = user.last_successful_login_at;

            user.readableDate =
              typeof date != 'undefined' ? $filter('date')(dateTimeService.formatDate(date), 'medium') : '';
            user.rolesNames = setRolesNames(user.roleIds);
            if (user.refreshTokens) {
              user.refreshTokens.forEach(refreshToken => fillTokenDisplayFields(refreshToken));
            }
            return user;
          });

          setShouldDisableAddUser();
          this.onDataLoaded();

          if (entityToProceed !== null) {
            if (Object.keys(entityToProceed).length === 0 && entityToProceed.constructor === Object) {
              onCreateUser();
            } else {
              onEditUser(entityToProceed);
            }
          } else {
            if (this.editModeOn) {
              resetListSelection();
              setListSelection(this.userName);

              $timeout(() => {
                const index = this.users.findIndex(user => user.name === this.userName),
                  dataListItem = $document[0].getElementById(`${SYSTEM_USERS_DATA_LIST_ITEM_ID}-${index}`);

                if (dataListItem !== null && index !== -1) {
                  dataListContainer.scrollTop = index * dataListItem.offsetHeight;
                }
              }, 0);
            }
            if (fromDelete && this.users?.length > 0) {
              onEditUser(this.users[0]);
            } else {
              const {
                data: { users = [] },
              } = resultFromUpdate;
              const user = users.find(({ name }) => name === this.userName);
              if (user) {
                onEditUser(user);
              }
            }
          }

          $timeout(() => {
            if (!dataListContainer) return;
            switch (scrollTo) {
              case 'top':
                dataListContainer.scrollTop = 0;
                break;
              case 'bottom':
                dataListContainer.scrollTop = dataListContainer.scrollHeight;
                break;
              default:
                if (entityToProceed !== null) {
                  if (Object.keys(entityToProceed).length === 0 && entityToProceed.constructor === Object) {
                    dataListContainer.scrollTop = 0;
                  } else {
                    const index = this.users.findIndex(user => user.name === entityToProceed.name);
                    const dataListItem = $document[0].getElementById(`${SYSTEM_USERS_DATA_LIST_ITEM_ID}-${index}`);

                    if (dataListItem !== null && index !== -1) {
                      dataListContainer.scrollTop = index * dataListItem.offsetHeight;
                    }
                  }
                }
            }
          }, 0);
        },
        () => {
          this.onDataLoaded();

          $translate('API:MESSAGE:COMMON_ERROR').then(translation => {
            notificationService.error(translation);
          });
        },
      );
    };

    const updateUser = async (id, data, entityToProceed = null) => {
      try {
        await systemUsersService.updateSystemUserById(id, data);
        const translation = await $translate('API:MESSAGE:COMMON_PUT_SUCCESS');
        notificationService.success(translation);

        this.userName = this.user.name;

        if (contentBodyContainer !== null) contentBodyContainer.scrollTop = 0;

        isFormDirty = false;

        this.systemUserForm.$submitted = false;

        fetchUsers({ entityToProceed });
        await updateUserPermissions();
        return true;
      } catch (err) {
        const { message } = err?.data ?? {};
        if (isEmpty(message)) {
          $translate('API:MESSAGE:COMMON_ERROR').then(translation => {
            notificationService.error(translation);
          });
        } else {
          notificationService.error(message);
        }
        return false;
      }
    };

    const createUser = (data, entityToProceed = null) => {
      return systemUsersService
        .createSystemUser(data)
        .then(() => {
          $translate('API:MESSAGE:COMMON_POST_SUCCESS').then(translation => {
            notificationService.success(translation);
          });

          this.passwordSectionVisible = false;
          this.editModeOn = true;
          this.createModeOn = false;

          this.userName = this.user.name;

          this.deleteBtnEnabled = true;

          isFormDirty = false;

          this.systemUserForm.$submitted = false;

          if (contentBodyContainer !== null) contentBodyContainer.scrollTop = 0;

          fetchUsers({ entityToProceed });
          return true;
        })
        .catch(response => {
          const { message } = response.data;
          if (isEmpty(message)) {
            $translate('API:MESSAGE:COMMON_ERROR').then(translation => {
              notificationService.error(translation);
            });
          } else {
            notificationService.error(message);
          }
          return false;
        });
    };

    // API calls -- end

    // obtaining role name by role ID from user.roleIds
    const setRolesNames = (rolesIdx = []) => {
      let rolesNames = [];

      if (rolesIdx === null) return rolesNames;

      const instanceCondition = rolesIdx instanceof Array;
      const lengthCondition = rolesIdx.length > 0;

      if (instanceCondition && lengthCondition) {
        for (let i = 0, len = this.roles.length; i < len; i++) {
          if (rolesIdx.indexOf(this.roles[i]['_id']) > -1) {
            rolesNames = [...rolesNames, this.roles[i]['name']];
          }
        }
      }

      return rolesNames;
    };

    const setDeleteBtnEnabled = name => {
      if (!this.authUser) {
        return false;
      } else {
        if (this.authUser.name === name) {
          return false;
        } else {
          return true;
        }
      }
    };

    const alphaSort = (a, b) => {
      const aName = a.name.toLowerCase(),
        bName = b.name.toLowerCase();

      if (aName < bName) {
        return -1;
      } else if (aName > bName) {
        return 1;
      } else {
        return 0;
      }
    };

    const resetFormState = () => {
      if (
        typeof this.systemUserForm != 'undefined' &&
        typeof this.systemUserForm.$setPristine == 'function' &&
        typeof this.systemUserForm.$setUntouched == 'function'
      ) {
        this.systemUserForm.$setPristine();
        this.systemUserForm.$setUntouched();
      } else {
        this.systemUserForm = {};
      }

      this.systemUserForm.$submitted = false;

      isFormDirty = false;
    };

    const resetListSelection = (except = '') => {
      for (let i = 0, len = this.users.length; i < len; i++) {
        if (this.users[i].name === except) {
          continue;
        }
        this.users[i]['selected'] = false;
      }
    };

    const setListSelection = name => {
      for (let i = 0, len = this.users.length; i < len; i++) {
        if (this.users[i].name === name) {
          this.users[i]['selected'] = true;
          break;
        }
      }
    };

    const deleteGridRow = (source, data) => {
      const index = source.findIndex(item => item._id === data);

      source = [...source.slice(0, index), ...source.slice(index + 1)];

      return source;
    };

    const deleteUserRole = (source, data) => {
      const index = source.findIndex(item => item === data);

      source = [...source.slice(0, index), ...source.slice(index + 1)];

      return source;
    };

    const onRolesAdded = newSelection => {
      newSelection.forEach(row => {
        this.user.roleIds = [...(this.user.roleIds || []), row._id];
        this.user.rolesNames = setRolesNames(this.user.roleIds);
        this.rolesGridConfig.data = [...this.rolesGridConfig.data, row];
      });

      isFormDirty = true;
    };

    const deleteTokenGridRow = (source, tokenName) => {
      const index = source.findIndex(item => item.tokenName === tokenName);

      source = [...source.slice(0, index), ...source.slice(index + 1)];

      return source;
    };

    const onTokenAdded = token => {
      this.user.refreshTokens = [...(this.user.refreshTokens || []), token];
      this.tokensGridConfig.data = [...(this.tokensGridConfig.data || []), token];
      fillTokenDisplayFields(token);
      isFormDirty = true;
    };

    const deleteUserToken = (source, tokenName) => {
      const index = source.findIndex(item => item.tokenName === tokenName);

      source = [...source.slice(0, index), ...source.slice(index + 1)];

      return source;
    };

    const fillTokenDisplayFields = token => {
      token.creationDateDisplay = $filter('date')(dateTimeService.formatDate(token.creationDate), 'medium');
      token.expirationDateDisplay = $filter('date')(dateTimeService.formatDate(token.expirationDate), 'medium');
    };

    const toggleTokenDialog = () => {
      const translations = {
        headerText: '',
        cancelButton: '',
        generateButton: '',
      };

      const TRANSLATION_REQUIRED = [
        'SYSTEM_USERS:GENERATE_TOKEN_MODAL:HEADER',
        'SYSTEM_USERS:GENERATE_TOKEN_MODAL:TEXT',
        'SYSTEM_USERS:GRID:TOKEN_NAME',
        'SYSTEM_USERS:GRID:TOKEN_VALUE',
        'SYSTEM_USERS:BUTTONS:GENERATE',
        'BUTTONS:CANCEL',
        'BUTTONS:CLOSE',
      ];

      $translate(TRANSLATION_REQUIRED).then(translation => {
        translations.headerText = translation['SYSTEM_USERS:GENERATE_TOKEN_MODAL:HEADER'];
        translations.modalText = translation['SYSTEM_USERS:GENERATE_TOKEN_MODAL:TEXT'];
        translations.tokenName = translation['SYSTEM_USERS:GRID:TOKEN_NAME'];
        translations.tokenValue = translation['SYSTEM_USERS:GRID:TOKEN_VALUE'];
        translations.generateButton = translation['SYSTEM_USERS:BUTTONS:GENERATE'];
        translations.cancelButton = translation['BUTTONS:CANCEL'];
        translations.closeButton = translation['BUTTONS:CLOSE'];

        this.refreshTokenDialog.isOpen = true;
        this.refreshTokenDialog.translations = translations;
      });
    };

    const closeRefreshTokenDialog = () => {
      $scope.$evalAsync(() => {
        this.refreshTokenDialog.isOpen = false;
        this.refreshTokenDialog.refreshTokenData = null;
      });
    };

    const generateRefreshToken = expiresInDays => {
      systemUsersService.generateRefreshToken(userId, expiresInDays).then(response => {
        if (typeof response.data.tokenName == 'undefined') {
          $translate('API:MESSAGE:COMMON_ERROR').then(translation => {
            notificationService.error(translation);
          });
        } else {
          this.tokenData = response.data;
          this.refreshTokenDialog.refreshTokenData = response.data;
          this.refreshTokenDialog.refreshTokenData.expiresInDays = expiresInDays;
          onTokenAdded(this.tokenData);
        }
      });
    };

    const copyToClipboard = () => {
      navigator.clipboard.writeText(this.tokenData.value).catch(err => {
        console.log('Something went wrong', err);
      });
    };

    const onUserLeavesForm = async (resolveCallback, rejectCallback) => {
      const closeButtonText = await $translate('BUTTONS:NO');
      const actionButtonText = await $translate('BUTTONS:YES');
      const headerText = await $translate('APP:COMMON:LEAVE_CONFIRMATION_HEADER');
      const bodyText = await $translate('APP:COMMON:LEAVE_CONFIRMATION_TEXT');

      const modalOptions = { closeButtonText, actionButtonText, headerText, bodyText };

      DeleteConfirmation.showModal({}, modalOptions).then(resolveCallback, rejectCallback);
    };

    const onCreateUser = () => {
      resetListSelection();

      this.user = {};

      this.createModeOn = true;
      this.editModeOn = false;
      this.deleteBtnEnabled = false;

      this.userName = '';

      this.tokensGridConfig.data = [];
      this.rolesGridConfig.data = [];

      this.passwordSectionVisible = !this.useSaas;

      resetFormState();
    };

    const onEditUser = user => {
      resetListSelection();
      setListSelection(user.name);

      this.user = angular.copy(user);
      this.userName = angular.copy(this.user.name);

      this.editModeOn = true;
      this.createModeOn = false;

      this.deleteBtnEnabled = setDeleteBtnEnabled(this.user.name);

      this.passwordSectionVisible = false;

      this.tokensGridConfig.data = this.user.refreshTokens || [];

      this.rolesGridConfig.data =
        typeof this.user.roleIds != 'undefined' && this.user.roleIds !== null
          ? this.roles.filter(role => this.user.roleIds.includes(role._id))
          : [];
      resetFormState();
    };

    const onSubmitUser = (entityToProceed = null) => {
      if (!this.systemUserForm.$valid) return;

      const payload = angular.copy(this.user);

      if (typeof payload.firstName == 'undefined') payload.firstName = '';

      if (typeof payload.lastName == 'undefined') payload.lastName = '';

      if (typeof payload.confirmPassword != 'undefined') delete payload.confirmPassword;

      if (typeof payload.rolesNames != 'undefined') delete payload.rolesNames;

      if (typeof payload.selected != 'undefined') delete payload.selected;

      if (payload.refreshTokens) {
        for (let i = 0; i < payload.refreshTokens.length; i++) {
          const refreshToken = payload.refreshTokens[i];
          delete refreshToken.expiresInDays;
          delete refreshToken.creationDateDisplay;
          delete refreshToken.expirationDateDisplay;
        }
      }

      switch (true) {
        case this.createModeOn:
          return createUser(payload, entityToProceed);
        case this.editModeOn:
          if (!this.passwordSectionVisible) {
            payload.password = null;
          }
          return updateUser(this.userName, payload, entityToProceed);
      }
    };

    this.togglePasswordSection = () => {
      this.passwordSectionVisible = !this.passwordSectionVisible;
    };

    this.onFormChanged = () => {
      isFormDirty = true;
    };

    this.onEdit = user => {
      if (this.user.name !== user.name) {
        if (this.isManagePermitted && isFormDirty) {
          onUserLeavesForm(
            () => {
              this.systemUserForm.$submitted = true;

              onSubmitUser(user);
            },
            () => {
              onEditUser(user);
            },
            true,
          );
        } else {
          onEditUser(user);
        }
      }
    };

    this.onCreate = () => {
      if (isFormDirty) {
        onUserLeavesForm(
          () => {
            this.systemUserForm.$submitted = true;

            onSubmitUser({});
          },
          () => {
            onCreateUser();
          },
        );
      } else {
        onCreateUser();
      }
    };

    this.onDelete = async user => {
      const { id, name } = user;

      showDeleteACEntityDialog({
        ids: [id],
        entityNameSingular: 'user',
        entityType: EntityType.RECEIVERS,
      })
        .then(shouldDelete => {
          if (shouldDelete) {
            systemUsersService.deleteSystemUserById(name).then(
              () => {
                $translate('API:MESSAGE:COMMON_DELETE_SUCCESS').then(translation => {
                  notificationService.success(translation);
                });

                this.editModeOn = false;
                this.createModeOn = false;

                this.user = {};

                fetchUsers({ scrollTo: 'top', fromDelete: true });
              },
              response => {
                if (typeof response.data.message == 'undefined' && typeof response.data.details == 'undefined') {
                  $translate('API:MESSAGE:COMMON_ERROR').then(translation => {
                    notificationService.error(translation);
                  });
                } else {
                  const { message = '', details = '' } = response.data;
                  notificationService.error(`${message} ${details}`);
                }
              },
            );
          }
        })
        .catch(() =>
          $translate('API:MESSAGE:COMMON_ERROR').then(translation => {
            notificationService.error(translation);
          }),
        );
    };

    this.onSubmit = () => {
      onSubmitUser();
    };

    this.onCancel = () => {
      this.user = {};

      this.userName = '';

      this.tokensGridConfig.data = [];
      this.rolesGridConfig.data = [];

      resetFormState();
    };

    this.onAddRoles = () => {
      this.isOpenRolesModal = true;
      this.selectedRoles = this.rolesGridConfig.data.map(row => row._id);
    };

    this.onCloseRolesModal = () => {
      this.isOpenRolesModal = false;
      $rootScope.$apply();
    };

    this.onConnectRoles = selectedRowIds => {
      const selectedRoles = this.roles.filter(({ _id }) => selectedRowIds.includes(_id));
      onRolesAdded(selectedRoles);
    };

    let userId;
    this.onAddTokens = () => {
      userId = this.user.name;
      toggleTokenDialog();
    };

    this.onDeleteRow = row => {
      if (this.user.origin !== 'Active Directory' && this.user.origin !== 'Saml') {
        this.user.roleIds = deleteUserRole(this.user.roleIds, row._id);
        this.rolesGridConfig.data = deleteGridRow(this.rolesGridConfig.data, row._id);

        isFormDirty = true;
      }
    };

    this.onDeleteAllRows = () => {
      if (this.user.origin !== 'Active Directory' && this.user.origin !== 'Saml') {
        this.rolesGridConfig.data = [];
        this.user.roleIds = [];

        isFormDirty = true;
      }
    };

    this.onRevokeToken = row => {
      this.user.refreshTokens = deleteUserToken(this.user.refreshTokens, row.tokenName);
      this.tokensGridConfig.data = deleteTokenGridRow(this.tokensGridConfig.data, row.tokenName);

      isFormDirty = true;
    };

    this.usersFilter = criteria => {
      const searchQuery = criteria.toLocaleLowerCase();

      return user => {
        return [user.name, user.firstName, user.lastName]
          .filter(str => typeof str === 'string' && str.length)
          .some(str => str.toLocaleLowerCase().includes(searchQuery));
      };
    };

    this.onSearchQueryChange = () => {
      requestAnimationFrame(() => {
        fetchUsers({});
      });
    };

    const setShouldDisableAddUser = () => {
      const oktaUsers = this.users.filter(user => user.origin === 'Okta');
      this.shouldDisableAddUser = this.useSaas && oktaUsers.length >= this.oktaMaxUsers;
    };

    this.$onInit = () => {
      this.isManagePermitted = isPermitted(ACCESS_MANAGEMENT_PERMISSIONS.MANAGE.name);

      this.onStartTransitionListener = $transitions.onBefore({}, () => {
        return new $q(resolve => {
          if (!isFormDirty) {
            resolve();
            return;
          }

          onUserLeavesForm(
            () => {
              if (this.systemUserForm.$valid) {
                this.systemUserForm.$submitted = true;
                onSubmitUser({})
                  .then(result => {
                    resolve(result);
                    isFormDirty = !result;
                  })
                  .catch(() => {
                    resolve(false);
                    isFormDirty = false;
                  });
              } else {
                notificationService.warning('In order to save your changes, please fill out all the mandatory fields');
                resolve(false);
              }
            },
            () => {
              isFormDirty = false;
              resolve();
            },
          );
        });
      });

      $translate(TRANSLATION_REQUIRED).then(translation => {
        const tokensGridColumnsNames = {};
        tokensGridColumnsNames.tokenName = translation['SYSTEM_USERS:GRID:TOKEN_NAME'];
        tokensGridColumnsNames.creationDate = translation['SYSTEM_USERS:GRID:CREATION_DATE'];
        tokensGridColumnsNames.expirationDate = translation['SYSTEM_USERS:GRID:EXPIRATION_DATE'];
        initializeTokensGridConfig(tokensGridColumnsNames);

        const rolesGridColumnsNames = {};
        rolesGridColumnsNames.name = translation['SCOPES:GRID:IDENTITY_SOURCES'];
        rolesGridColumnsNames.description = translation['SCOPES:GRID:TYPE'];
        initializeRolesGridConfig(rolesGridColumnsNames);

        this.onDataLoading();

        initializeData(result => {
          const { roles, users, authUser } = result;
          this.roles = roles;
          this.authUser = authUser;

          this.users = users.map(user => {
            const date = user.last_successful_login_at;

            user.readableDate =
              typeof date != 'undefined' ? $filter('date')(dateTimeService.formatDate(date), 'medium') : '';
            user.rolesNames = setRolesNames(user.roleIds);
            if (user.refreshTokens) {
              user.refreshTokens.forEach(refreshToken => fillTokenDisplayFields(refreshToken));
            }
            return user;
          });

          if (this.users.length > 0) {
            onEditUser(this.users[0]);
          }

          setShouldDisableAddUser();
          this.onDataLoaded();
        });
      });
    };

    this.$onDestory = () => {
      this.onStartTransitionListener();
    };

    this.onRoleSelected = selected => {
      this.user.roleIds = selected.length == 0 ? undefined : selected;
    };
  },
  bindings: {
    onDataLoading: '&',
    onDataLoaded: '&',
  },
});
