import React, { useState, useEffect, ReactNode } from 'react';
import { $state } from '../../../services/angularServices';
import { convertToAngular } from '../../../../common/services/convertToAngular';
import angular from 'angular';
import styled from '@emotion/styled';
import { BigidTabs, PrimaryButton } from '@bigid-ui/components';
import { generateDataAid } from '@bigid-ui/utils';
import { CONFIG } from '../../../../config/common';
import { SarProfileTabNames, SarMainTabsProps, TabsProps } from './SarProfileSettingsTypes';
import { useLocationChange } from './useLocationChange';
import { DsarEvents, dsarEventEmitter } from './dsarEvents';
import { BigidAddIcon, BigidImportIcon } from '@bigid-ui/icons';
import { DsarTabPaths } from '../DsarTypes';
import { DsarSettingsTrackingEvents, trackDsar } from '../analyticsUtils';
import { isPermitted } from '../../../services/userPermissionsService';
import { DSAR_PERMISSIONS } from '@bigid/permissions';

const TabsContainer = styled.div({
  padding: '8px 0 12px',
});

const Wrapper = styled.div({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: '0 4px;',
});

const Tabs = ({ tabs, selectedTabIndex, dataAid, onTabChange }: TabsProps) => {
  return (
    <TabsContainer data-aid={generateDataAid(dataAid, ['container'])}>
      <BigidTabs
        tabs={tabs.map(tab => ({ label: tab.title }))}
        orientation="horizontal"
        selectedIndex={selectedTabIndex}
        onChange={onTabChange}
      />
    </TabsContainer>
  );
};

export const PROFILE_DETAILS_TABS = [
  {
    title: 'Correlation Sets',
    path: SarProfileTabNames.ES,
    selected: false,
  },
  {
    title: 'Data Sources',
    path: SarProfileTabNames.DS,
    selected: false,
  },
  {
    title: 'Objects',
    path: SarProfileTabNames.OBJECTS,
    selected: false,
  },
  {
    title: 'Fields',
    path: SarProfileTabNames.FIELDS,
    selected: false,
    content: (
      <PrimaryButton
        size="medium"
        onClick={() => {
          dsarEventEmitter.emit(DsarEvents.OPEN_IMPORT_FIELDS_DIALOG);
        }}
      >
        {'Import'}
      </PrimaryButton>
    ),
  },
  {
    title: 'Manual Records',
    path: SarProfileTabNames.MANUAL_RECORDS,
    selected: false,
    content: (
      <PrimaryButton
        size="medium"
        startIcon={<BigidAddIcon />}
        onClick={() => {
          dsarEventEmitter.emit(DsarEvents.CREATE_STATIC_RECORD);
        }}
      >
        {'Add record'}
      </PrimaryButton>
    ),
  },
];

const TAB_NAMES = PROFILE_DETAILS_TABS.map(({ path }) => path);

const getCurrentTabIndex = (currentTab: SarProfileTabNames) => TAB_NAMES.indexOf(currentTab) || 0;

export const DsarProfileSettingsDetailsTabs = ({ currentTab }: { currentTab: SarProfileTabNames | null }) => {
  const [selectedTabIndex, setSelectedTabIndex] = useState<number>(getCurrentTabIndex(currentTab));

  useEffect(() => {
    setSelectedTabIndex(getCurrentTabIndex(currentTab));
  }, [currentTab]);

  const onTabChange = (tabIndex: number) => {
    $state.go(
      CONFIG.states.ACCESS_REQUEST_MANAGEMENT_PROFILE,
      { ...$state.params, tab: TAB_NAMES[tabIndex] },
      { reload: false, notify: false, inherit: true },
    );
    setSelectedTabIndex(tabIndex);
  };
  const tabContent = PROFILE_DETAILS_TABS[selectedTabIndex]?.content;

  return (
    <Wrapper>
      <Tabs
        tabs={PROFILE_DETAILS_TABS}
        selectedTabIndex={selectedTabIndex}
        dataAid="DsarProfileSettingsDetailsTabs"
        onTabChange={onTabChange}
      />
      {tabContent}
    </Wrapper>
  );
};

const TABS_CONTENT: Partial<
  Record<
    DsarTabPaths | SarProfileTabNames,
    {
      render: () => ReactNode;
    }
  >
> = {
  [DsarTabPaths.DICTIONARY_PATH]: {
    render: () =>
      isPermitted(DSAR_PERMISSIONS.CREATE_DICTIONARY.name) && (
        <PrimaryButton
          size="medium"
          startIcon={<BigidAddIcon />}
          onClick={() => {
            dsarEventEmitter.emit(DsarEvents.UPLOAD_FILE);
            trackDsar(DsarSettingsTrackingEvents.DICTIONARY_UPLOAD_DICTIONARY_ACTION);
          }}
        >
          {'Upload a new dictionary'}
        </PrimaryButton>
      ),
  },
};

export const SarMainTabs = ({ tabs, onTabChange }: SarMainTabsProps) => {
  const { stateParams } = useLocationChange();
  if (stateParams.profileId) {
    return <DsarProfileSettingsDetailsTabs currentTab={stateParams.tab} />;
  }

  const selectedTab = tabs.find(tab => tab.selected)?.path;
  const tabContent = TABS_CONTENT[selectedTab];

  return (
    <Wrapper>
      <Tabs
        tabs={tabs}
        selectedTabIndex={tabs.findIndex(tab => tab.selected)}
        dataAid="SarMainTabs"
        onTabChange={onTabChange}
      />
      {tabContent?.render()}
    </Wrapper>
  );
};

angular.module('app').component('sarMainTabs', convertToAngular(SarMainTabs, ['tabs', 'onTabChange']));
