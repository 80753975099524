import React, { FC } from 'react';
import { BigidDialog, PrimaryButton, SecondaryButton } from '@bigid-ui/components';

export interface AttributesDeleteConfirmationDialogProps {
  isOpen: boolean;
  onClose?: () => void;
  onSubmit?: () => void;
}

export const AttributesDeleteConfirmationDialog: FC<AttributesDeleteConfirmationDialogProps> = ({
  isOpen,
  onClose,
  onSubmit,
}) => {
  const handleOnDeleteConfirmation = () => {
    onSubmit();
  };

  return (
    <BigidDialog
      buttons={[
        {
          component: SecondaryButton,
          onClick: onClose,
          text: 'Cancel',
        },
        {
          component: PrimaryButton,
          onClick: handleOnDeleteConfirmation,
          text: 'Delete',
        },
      ]}
      isOpen={isOpen}
      maxWidth="sm"
      onClose={onClose}
      title="Delete Attributes"
      borderTop
    >
      Are you sure you want to delete the selected attributes?
    </BigidDialog>
  );
};
