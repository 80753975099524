import React, { FC } from 'react';
import { styled } from '@mui/material';
import { BigidBody1, BigidColorsV2 } from '@bigid-ui/components';
import { BigidConvertIcon } from '@bigid-ui/icons';
import { generateDataAid } from '@bigid-ui/utils';
import { useLocalTranslation } from '../../translations';
import BigidCheckboxCheckedSVG from '../../../../assets/icons/BigidCheckboxChecked.svg';
import { Trans } from 'react-i18next';

const ConvertOldContentContainer = styled('div')``;
const BigidCheckboxChecked = styled(BigidCheckboxCheckedSVG)`
  margin-right: 5px;
  position: relative;
  top: 3px;
`;
const ContainerImages = styled('div')`
  display: flex;
  align-items: center;
  gap: 6px;
  margin-bottom: 12px;
  background: ${BigidColorsV2.gray[150]};
  width: max-content;
  border-radius: 5px;
  padding: 8px;
`;

interface ConvertOldContentProps {
  dataAid?: string;
}

export const ConvertOldContent: FC<ConvertOldContentProps> = ({ dataAid = 'ConvertOldContent' }) => {
  const { t } = useLocalTranslation('guidedTour.plannedScans.convert');
  return (
    <ConvertOldContentContainer data-aid={generateDataAid(dataAid, ['container'])}>
      <ContainerImages>
        <BigidConvertIcon />
        <BigidBody1 fontWeight={600}>{t('convert')}</BigidBody1>
      </ContainerImages>
      <BigidBody1>
        <BigidCheckboxChecked />
        <Trans t={t} i18nKey="content" components={{ bold: <BigidBody1 fontWeight={700} display="inline-block" /> }} />
      </BigidBody1>
    </ConvertOldContentContainer>
  );
};
