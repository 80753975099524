import React, { FC } from 'react';
import { useLocalTranslation } from './translations';
import { generateDataAid } from '@bigid-ui/utils';
import ClassifierModelThumbUp from '../../../../../assets/icons/classifierModelThumbUp.svg';
import ClassifierModelThumbDown from '../../../../../assets/icons/ClassifierModelThumbDown.svg';
import { styled } from '@mui/material';
import { BigidBody1, BigidHeading3, BigidHeading4 } from '@bigid-ui/components';
import { TuningData } from './classifierModelUtils';

const SummarySection = styled('div')`
  display: flex;
  padding: 24px 28px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 16px;
  align-self: stretch;
  border-radius: 4px;
  border-top: 1px solid ${({ theme }) => theme.vars.tokens.bigid.borderDefault};
  background: ${({ theme }) => theme.vars.tokens.bigid.foregroundInverse};
`;

const SummaryStats = styled('div')`
  display: flex;
  justify-content: center;
`;

const StatsData = styled('div')`
  display: flex;
  align-items: flex-end;
  & > * {
    margin-right: 4px;
    margin-left: 4px;
  }
`;

const VerticalBar = styled('div')`
  margin-right: 30px;
  margin-left: 30px;
  width: 1px;
  background: ${({ theme }) => theme.vars.palette.bigid.gray300};
`;

const ExplanationText = styled(BigidBody1)`
  padding: 24px 24px 0 24px;
`;

const InputCount = styled(BigidHeading3)`
  margin-left: 4px;
  margin-right: 4px;
  font-size: 20px;
  font-weight: 700;
`;

const InputActionName = styled(BigidBody1)`
  margin-left: 4px;
  margin-right: 4px;
`;

const Header = styled(BigidBody1)`
  font-weight: 700;
`;

const SecondaryTitle = styled(BigidHeading4)`
  font-weight: 700;
`;

export interface ClassifierModelSummaryProps {
  tuningData: TuningData;
  dataAid?: string;
}

export const ClassifierModelSummary: FC<ClassifierModelSummaryProps> = ({
  tuningData,
  dataAid = 'ClassifierModelSummary',
}) => {
  const { t } = useLocalTranslation('main.summary');

  return (
    <>
      <SummarySection>
        <Header data-aid={generateDataAid(dataAid, ['summary'])}>{t('header')}</Header>
        <SummaryStats data-aid={generateDataAid(dataAid, ['summary', 'stats'])}>
          <StatsData data-aid={generateDataAid(dataAid, ['summary', 'stats', 'approved'])}>
            <ClassifierModelThumbUp />
            <InputCount>{tuningData.totalApproves}</InputCount>
            <InputActionName>{t('approved')}</InputActionName>
          </StatsData>
          <VerticalBar />
          <StatsData data-aid={generateDataAid(dataAid, ['summary', 'stats', 'rejected'])}>
            <ClassifierModelThumbDown />
            <InputCount>{tuningData.totalRejects}</InputCount>
            <InputActionName>{t('rejected')}</InputActionName>
          </StatsData>
        </SummaryStats>
      </SummarySection>
      <ExplanationText data-aid={generateDataAid(dataAid, ['summary', 'explanation-text'])}>
        <SecondaryTitle>{t('title')}</SecondaryTitle>
        <BigidBody1>{t('explanation.firstPart')}</BigidBody1>
        <BigidBody1>{t('explanation.secondPart')}</BigidBody1>
      </ExplanationText>
    </>
  );
};
