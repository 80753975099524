import { module } from 'angular';
const app = module('app');

app.factory('mentionsTextareaService', [
  '$http',
  'appSettings',
  function ($http, appSettings) {
    return {
      getUsers: () => {
        return $http
          .get(appSettings.serverPath + '/' + appSettings.version + '/reassignable_users')
          .then(response => response);
      },
      getPurposes: () => {
        return $http
          .get(appSettings.serverPath + '/' + appSettings.version + '/dataMapping/purposes')
          .then(response => response);
      },
      getAttributes: () => {
        return $http
          .get(appSettings.serverPath + '/' + appSettings.version + '/attributeRisks')
          .then(response => response);
      },
    };
  },
]);
