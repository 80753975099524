export enum VALIDATION_TYPE {
  WeightedModulo = 'WeightedModulo',
  NonWeightedModulo = 'NonWeightedModulo',
}

export interface ChecksumValidation {
  _id?: string;
  validation: string;
  algorithm?: VALIDATION_TYPE;
  weights?: number[];
  nonValidCharsRegEx?: string;
  validRegEx?: string;
  checkDigitConversionTable?: Map<number, string>;
  idNumberConversionTable?: Map<string, number>;
  moduloValue?: number;
  checkDigitLocation?: number;
  numberOfCheckDigits?: number;
  sumDigits?: boolean;
  paddingToLength?: number;
  calculationConstant?: number;
  idNumberMapDimension?: number;
}

export interface OOTBChecksumValidation {
  id: string;
  displayValue: string;
  value: string;
}

export enum CHECKSUM_VALIDATION_TYPE {
  NONE = 'none',
  OOTB = 'ootb',
  CUSTOM = 'custom',
}

export enum FIELDS_NAMES {
  VALIDATION_NAME = 'validation',
  ALGORITHM = 'algorithm',
  WEIGHTS = 'weights',
  NON_VALID_REGEX = 'nonValidCharsRegEx',
  VALID_REGEX = 'validRegEx',
  CHECK_DIGIT_TABLE = 'checkDigitConversionTable',
  ID_NUMBER_TABLE = 'idNumberConversionTable',
  MODULO_VALUE = 'moduloValue',
  CHECK_DIGIT_LOCATION = 'checkDigitLocation',
  NUMBER_OF_CHECK_DIGITS = 'numberOfCheckDigits',
  SUM_DIGITS = 'sumDigits',
  PADDING_TO_LENGTH = 'paddingToLength',
  CALCULATION_CONSTANT = 'calculationConstant',
  ID_NUMBER_DIMENSION = 'idNumberMapDimension',
}
