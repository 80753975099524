import React, { FC, Fragment, useMemo, useState } from 'react';
import { Add as AddIcon, Remove as RemoveIcon, EditOutlined } from '@mui/icons-material';
import { NormalizedSarProfile } from '../SarProfileSettings';
import { BigidLink, ActionData } from '@bigid-ui/components';
import { DataSource } from '../ProfileSettingsTypes';
import { BigidGridColumnTypes, BigidGridWithToolbar, BigidGridWithToolbarProps, BigidGridColumn } from '@bigid-ui/grid';
import { sarConfigService } from '../sarConfigService';
import { notificationService } from '../../../../services/notificationService';
import { $state } from '../../../../services/angularServices';
import { getCellValueForConnectionStatus, getCellValueForIncludeExclude } from '../gridCellValues';
import { EditDataSourceDialog, updateSources } from './EditDataSourcesDialog';
import { useEditDataSourceDialog } from './useEditDataSourceDialog';
import { EnableAll } from './EnableAll';
import { DSAR_PERMISSIONS } from '@bigid/permissions';
import { isPermitted } from '../../../../services/userPermissionsService';
import { goToDataSource } from '../../../DataSources/DataSourcesService';
import { useUserPreferences } from '../../../../components/hooks/useUserPrefrences';
import { SarProfileSourceTypes, SarProfileTabNames } from '../SarProfileSettingsTypes';
import { BigidWarningIcon } from '@bigid-ui/icons';
import makeStyles from '@mui/styles/makeStyles';

const columns: BigidGridColumn<DataSource>[] = [
  {
    title: 'Name',
    name: 'name',
    type: BigidGridColumnTypes.TEXT,
    filteringEnabled: true,
    sortingEnabled: true,
    getCellValue: ({ name, type }) =>
      (<BigidLink onClick={() => goToDataSource(name, type)} text={name} />) as React.ReactNode,
  },
  {
    title: 'DS Type',
    name: 'type',
    width: 150,
    type: BigidGridColumnTypes.TEXT,
    filteringEnabled: true,
    sortingEnabled: true,
    getCellValue: ({ type }) => type,
  },
  {
    title: 'Last Test',
    name: 'connectionStatusTest.is_success',
    width: 200,
    type: BigidGridColumnTypes.STATUS,
    getCellValue: ({ connectionStatusTest }) => getCellValueForConnectionStatus(connectionStatusTest),
  },
  {
    title: 'Last Scan',
    name: 'connectionStatusScan.is_success',
    width: 200,
    type: BigidGridColumnTypes.STATUS,
    getCellValue: ({ connectionStatusScan }) => getCellValueForConnectionStatus(connectionStatusScan),
  },
  {
    title: 'Enabled by Admin',
    name: 'enabledByAdmin',
    width: 150,
    type: BigidGridColumnTypes.STATUS,
    getCellValue: ({ sourceEnabled }) => {
      return {
        text: sourceEnabled ? 'Enabled' : 'Disabled',
        status: sourceEnabled === false ? 'warning' : undefined,
      };
    },
  },
  {
    title: 'Case Sensitive',
    name: 'isCaseInSensitive',
    width: 170,
    type: BigidGridColumnTypes.TEXT,
    getCellValue: ({ isCaseInSensitiveSupported, isCaseInSensitive }) =>
      isCaseInSensitiveSupported ? (isCaseInSensitive ? 'Off' : 'On') : 'N/A',
  },
  {
    title: 'Linked Tables',
    name: 'isReferentialIntegrity',
    width: 170,
    type: BigidGridColumnTypes.TEXT,
    getCellValue: ({ isReferentialIntegritySupported, isReferentialIntegrity }) =>
      isReferentialIntegritySupported ? (isReferentialIntegrity ? 'On' : 'Off') : 'N/A',
  },
  {
    title: 'Exact Match',
    name: 'isExactMatch',
    width: 170,
    type: BigidGridColumnTypes.TEXT,
    getCellValue: ({ isExactMatchSupported, isExactMatch }) =>
      isExactMatchSupported ? (isExactMatch ? 'On' : 'Off') : 'N/A',
  },
  {
    title: 'Include in Data Scan',
    name: 'profileEnabled',
    type: BigidGridColumnTypes.CHIP,
    getCellValue: ({ profileEnabled }) => getCellValueForIncludeExclude(profileEnabled),
  },
];

const useStyles = makeStyles({
  warning: {
    padding: 20,
    fontSize: 14,
    display: 'flex',
    gap: 8,
    alignItems: 'center',
    paddingTop: 10,
    paddingBottom: 0,
  },
});

export const DataSources: FC<NormalizedSarProfile> = ({ id: profileId, allEnabledDs }) => {
  const [isDsIncluded, setIsDsIncluded] = useState<boolean>(true);
  const classes = useStyles({});

  if (profileId && $state.params.profileId !== profileId) {
    $state.go('.', { profileId }, { reload: false, notify: false });
  }

  const editDataSourceDialogHook = useEditDataSourceDialog();

  const userPrefStateName = `${$state.$current.name}.${SarProfileTabNames.DS}`;
  const { isReady, preferences, gridColumns, updatePreferences } = useUserPreferences({
    stateName: userPrefStateName,
    initialGridColumns: columns,
  });

  const gridWithToolbarConfig: BigidGridWithToolbarProps<DataSource> = useMemo(() => {
    const includeExcludeExecutor = async (enabled: boolean, { selectedRows }: ActionData) => {
      if (!selectedRows.length) return {};
      await updateSources(profileId, { enabled }, selectedRows);
      return { shouldGridReload: true, shouldClearSelection: true };
    };

    const gridWithToolbarConfig: BigidGridWithToolbarProps<DataSource> = {
      columns: gridColumns,
      entityName: 'Data Sources',
      showSortingControls: true,
      showFilteringControls: true,
      defaultSorting: preferences?.grid?.sort || [{ field: 'name', order: 'asc' }],
      onGridStateChange: nextGridState => updatePreferences({ gridState: { ...nextGridState, filter: undefined } }),
      toolbarActions: [
        {
          label: 'Include',
          icon: AddIcon,
          execute: actionData => includeExcludeExecutor(true, actionData),
          disable: ({ selectedRows }) => !selectedRows.length,
          show: ({ totalRows }) => !!totalRows && isPermitted(DSAR_PERMISSIONS.EDIT_PROFILE_SETTINGS.name),
        },
        {
          label: 'Exclude',
          icon: RemoveIcon,
          execute: actionData => includeExcludeExecutor(false, actionData),
          disable: ({ selectedRows }) => !selectedRows.length,
          show: ({ totalRows }) => !!totalRows && isPermitted(DSAR_PERMISSIONS.EDIT_PROFILE_SETTINGS.name),
        },
        {
          label: 'Edit',
          icon: EditOutlined,
          execute: async ({ selectedRows }) => {
            if (!selectedRows.length) return {};
            const actionResult = await editDataSourceDialogHook.openDialog({
              profileId,
              sources: selectedRows,
            });
            return { shouldGridReload: actionResult, shouldClearSelection: actionResult };
          },
          disable: ({ selectedRows }) => !selectedRows.length,
          show: ({ totalRows }) => !!totalRows && isPermitted(DSAR_PERMISSIONS.EDIT_PROFILE_SETTINGS.name),
        },
      ],
      pageSize: 5000,
      fetchData: async gridQueryParams => {
        let dsConnections: DataSource[] = [];
        try {
          const profile = await sarConfigService.getProfileById(profileId, gridQueryParams, {
            excludeEsConnections: true,
          });
          dsConnections = profile.dsConnections;
          setIsDsIncluded(dsConnections.some(({ profileEnabled }) => profileEnabled));
        } catch (err) {
          notificationService.error(`Failed to get Data Sources.`);
          console.error(`Failed to get SAR Request profile by id '${profileId}'`, JSON.stringify(err));
        }

        return {
          data: dsConnections,
          totalCount: dsConnections.length,
        };
      },
    };

    return gridWithToolbarConfig;
  }, [gridColumns, preferences, updatePreferences, editDataSourceDialogHook, profileId]);

  return (
    <>
      <EditDataSourceDialog sourceType={SarProfileSourceTypes.DS} {...editDataSourceDialogHook.dialogProps} />
      {isPermitted(DSAR_PERMISSIONS.MANAGE_ALL_ENABLED_PROFILE_SETTINGS.name) || allEnabledDs ? (
        <EnableAll
          key={`EnableAll_${profileId}`}
          sourceType={SarProfileSourceTypes.DS}
          profileId={profileId}
          isAllEnabled={allEnabledDs}
        />
      ) : null}
      {isReady && !allEnabledDs && !isDsIncluded && (
        <div className={classes.warning}>
          <BigidWarningIcon />
          <span>At least 1 Data Source should be selected in order to perform a DSAR scan.</span>
        </div>
      )}
      {!allEnabledDs && isReady ? (
        <BigidGridWithToolbar key={`BigidGridWithToolbar_${profileId}`} {...gridWithToolbarConfig} />
      ) : null}
    </>
  );
};
