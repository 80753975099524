import { CustomAppStatus } from '../../CustomApp/views/ActivityLog/ActivityLog';
import { httpService } from '../../../services/httpService';
import { DiscoveryMenuOption } from './FmsdDiscover';
import { startCase } from 'lodash';
import { EntityEvents, entityEventsEmitter } from '@bigid-ui/components';
import { trackFmsdEvent, FmsdTrackingEvents } from '../fmsdEventsTrackerUtils';
import { autoDiscoveryErrorNotification, getLastRunInfo } from '../../../services/autoDiscoveryService';
import { CloudProvider, SaaSProvider, SaasConnectors } from '../../AutoDiscoveryWizard/autoDiscoveryWizardServices';
import { getFixedT } from '../translations';
import { notificationService } from '../../../services/notificationService';

const t = getFixedT('General');

export const AWS_ALLOWED_TYPES = ['s3', 'athena', 'dynamodb', 'kinesis'];

export interface DsTypeItem {
  label: string;
  value: string;
  category: CloudProvider | SaaSProvider;
}

export interface CustomAppActionData {
  status_enum: CustomAppStatus;
  message: string;
  tpa_action_id: string;
}

export enum DsScanActions {
  PAUSE = 'pause',
  RESUME = 'resume',
  STOP = 'stop',
  START = 'start',
}

export type LastAppRunInfo = Pick<CustomAppActionData, 'status_enum' | 'message'>;

export const permissionsToDsTypes = new Map<string, Pick<DsTypeItem, 'label' | 'value'>>([
  ['s3', { label: t('dsTypes.s3'), value: 's3' }],
  ['athena', { label: t('dsTypes.athena'), value: 'athena' }],
  ['dynamodb', { label: t('dsTypes.dynamodb'), value: 'dynamodb' }],
  ['kinesis', { label: t('dsTypes.kinesis'), value: 'kinesis' }],
]);

export const dsTypes: Record<CloudProvider | SaaSProvider, DsTypeItem[]> = {
  [CloudProvider.AWS]: [
    { label: t('dsTypes.s3'), value: 's3', category: CloudProvider.AWS },
    { label: t('dsTypes.athena'), value: 'athena', category: CloudProvider.AWS },
    { label: t('dsTypes.dynamodb'), value: 'dynamodb', category: CloudProvider.AWS },
    { label: t('dsTypes.kinesis'), value: 'kinesis', category: CloudProvider.AWS },
  ],
  [CloudProvider.AZURE]: [
    { label: t('dsTypes.blob'), value: 'azure-blob', category: CloudProvider.AZURE },
    { label: t('dsTypes.storage'), value: 'azure-file', category: CloudProvider.AZURE },
    { label: t('dsTypes.cassandra'), value: 'cassandra', category: CloudProvider.AZURE },
    { label: t('dsTypes.mongodb'), value: 'mongodb', category: CloudProvider.AZURE },
    { label: t('dsTypes.cosmosdb'), value: 'cosmosdb', category: CloudProvider.AZURE },
    { label: t('dsTypes.databricks'), value: 'databricks', category: CloudProvider.AZURE },
  ],
  [SaaSProvider.SAAS_CONNECTORS]: [
    { label: t('dsTypes.salesforce'), value: SaasConnectors.SALESFORCE, category: SaaSProvider.SAAS_CONNECTORS },
  ],
};

const parseDsData = (type: CloudProvider, dsData: string): Partial<DiscoveryMenuOption> | false => {
  const info = dsData.trim().split(' ');
  const dsType = info[0].toLowerCase();
  if (dsTypes[type].map(ds => ds.value).includes(dsType)) {
    const status = info[1].toLowerCase();
    const items = info[2]?.match(/\((.*?)\)/);
    let progress = status === 'completed' ? false : startCase(status);
    if (progress && Array.isArray(items) && items[1]) {
      const itemAmounts = items[1].split('/');
      const partial = parseInt(itemAmounts[0]);
      const total = parseInt(itemAmounts[1]);
      if (partial && total) {
        progress = `${Math.round(100 * (partial / total))}% ${startCase(status)}`;
      }
    }
    return { value: dsType, progress };
  } else {
    return false;
  }
};

export const messageToOptions = (type: CloudProvider, message: string): Partial<DiscoveryMenuOption>[] => {
  const progressData = message?.match(/\[(.*?)\]/);
  const progressOptions: Partial<DiscoveryMenuOption>[] = [];
  if (Array.isArray(progressData) && progressData[1]) {
    progressData[1].split(',').forEach(dsData => {
      const optionItem = parseDsData(type, dsData);
      if (optionItem) progressOptions.push(optionItem);
    });
  }

  return progressOptions;
};

export const updateOptionsWithProgress = async (
  type: CloudProvider,
  options: DiscoveryMenuOption[],
): Promise<DiscoveryMenuOption[]> => {
  const { status_enum, message } = await getLastRunInfo(type);
  if (status_enum !== CustomAppStatus.IN_PROGRESS) {
    options.forEach(option => (option.progress = false));
  } else {
    const updatedOptions = messageToOptions(type, message);
    updatedOptions.forEach(item => {
      const matchedIndex = options.findIndex(o => o.value == item.value);
      if (matchedIndex >= 0) options[matchedIndex] = { ...options[matchedIndex], ...item };
    });
  }

  return options;
};

export const startDsDcan = async (dsNames: string[], dsType: string) => {
  const errorMessage = t('apiErrors.startDSscan');
  const additionalFilters = dsType === 's3' ? '' : '&isSampleScan=true';
  try {
    const response = await httpService.post(`ds-connections/scan?scanType=dsScan${additionalFilters}`, {
      dsConnectionsNames: dsNames,
    });
    if (response?.status !== 200) {
      throw new Error(errorMessage);
    }
    notificationService.success(t('scanInProgressNotification.message'), {
      title: t('scanInProgressNotification.title'),
    });
  } catch (e) {
    autoDiscoveryErrorNotification(e, errorMessage);
  }
};

export const applyDsScanAction = async (dsName: string, dsType: string, action: DsScanActions) => {
  const errorMessage = t('apiErrors.actionApply', { action });
  if (dsName) {
    try {
      const response = await httpService.post(`ds-connections/${dsName}/scan-${action}`);
      trackFmsdEvent(FmsdTrackingEvents.FMSD_DISCOVER_SCAN_CLICK, {
        [FmsdTrackingEvents.SELECTED_DS]: dsType,
        [FmsdTrackingEvents.DS_NAMES]: dsName,
      });
      if (response?.status !== 200) {
        throw new Error(errorMessage);
      }
      updateGridRowsWithScanProgress(dsType);
    } catch (e) {
      autoDiscoveryErrorNotification(e, errorMessage);
    }
  }
};

export const getActiveScans = async (dsType: string, activeScansOnly = false) => {
  try {
    const typeFilter = JSON.stringify([{ field: 'type', value: dsType, operator: 'equal' }]);
    const response = await httpService.fetch(
      `ds-metadata?includeScans=true&activeScansOnly=${activeScansOnly}&dsWithScansOnly=true&filter=${typeFilter}`,
    );
    if (response?.status !== 200) {
      throw new Error(t('apiErrors.fetchActiveScans'));
    }
    return response;
  } catch (e) {
    autoDiscoveryErrorNotification(e);
  }
};

export const updateGridRowsWithScanProgress = async (dsType: string) => {
  try {
    const {
      data: {
        data: { dsConnections },
      },
    } = await getActiveScans(dsType, true);
    entityEventsEmitter.emit(EntityEvents.UPDATE, dsConnections, false);
  } catch (e) {
    autoDiscoveryErrorNotification(e);
  }
};

export const getIsScanResultAvailable = async () => {
  try {
    const {
      data: {
        overview: { numberOfDataSourceWithPi },
        inventory: {
          objectsInfo: { objectsWithPiRecords },
        },
      },
    } = await httpService.fetch('scan-activity-summary');
    return Boolean(numberOfDataSourceWithPi || objectsWithPiRecords);
  } catch (e) {
    return false;
  }
};

export const getCloudDataSourceLabel = (dsType: string) => {
  for (const [k, category] of Object.entries(dsTypes)) {
    for (const ds of category) {
      if (ds?.value === dsType) {
        return ds.label;
      }
    }
  }
  return dsType;
};
