import { CustomDashboardConfig, OPERATORS } from '@bigid-ui/custom-dashboard';
export const securityTemplate: CustomDashboardConfig = {
  data: {},
  endpoints: [
    {
      id: 'sensitivityClassificationRatio',
      api: {
        url: 'api/v1/aci/sc/ratio',
        method: 'get',
      },
    },
    {
      id: 'defaultGroupSensitivityClassificationRatio',
      api: {
        url: 'api/v1/aci/sc/ratio/name/Sensitivity',
        method: 'get',
      },
    },
    {
      id: 'usersByType',
      api: {
        url: 'api/v1/aci/users/insights/byType',
        method: 'get',
      },
    },
    {
      id: 'attributeByDs',
      api: {
        url: '/api/v1/getAttributesByDs?len=4',
        method: 'get',
      },
    },
    {
      id: 'entity_summaries_scoped',
      api: {
        url: 'api/v1/entitySummariesScoped',
        method: 'get',
      },
    },
    {
      id: 'trends',
      api: {
        url: 'api/v1/object-summaries/trend',
        method: 'get',
      },
    },
    {
      id: 'byDataSource',
      api: {
        url: '/api/v1/sorted-object-summaries-by-ds?len=4',
        method: 'get',
      },
    },
    {
      id: 'objectSummariesSortedByPii',
      api: {
        url: '/api/v1/sorted-object-summaries-by-ds?len=3&makeOther=true',
        method: 'get',
      },
    },
    {
      id: 'objectSummariesByType',
      api: {
        url: 'api/v1/object-summaries/by-type',
        method: 'get',
      },
    },
    {
      id: 'objectSummariesByDataSource',
      api: {
        url: 'api/v1/object-summaries/by-data-source?sort=totalObjectsWithPiiAndOpenAccess:-1',
        method: 'get',
      },
    },
    {
      id: 'classificationsByObject',
      api: {
        url: '/api/v1//getAttributesCountByObject?len=4',
        method: 'get',
      },
    },
    {
      id: 'attributeRisks',
      api: {
        url: '/api/v1/sorted-and-limited-attributes-risks?len=4',
        method: 'get',
      },
    },
    {
      id: 'insights_ByDataSource',
      api: {
        url: 'api/v1/aci/users/insights/byDataSource',
        method: 'get',
      },
    },
  ],
  dataManipulations: [
    {
      id: 'objectsSummaryTotalObjectsWithPii',
      sumFromArray: {
        pathToArray: 'data.objectSummariesByType.summaryByTypes',
        pathToAttribute: 'totalObjectsWithPii',
      },
    },
    {
      id: 'entitySummariesSourceItem',
      findItemInArray: {
        pathToArray: 'data.entity_summaries_scoped.entity_summaries',
        operation: {
          pathToAttribute: 'entity',
          operator: OPERATORS.EQUAL_TO,
          value: 'source',
        },
      },
    },
    {
      id: 'filteredDataSources',
      filterArray: {
        pathToArray: 'data.byDataSource.summaries',
        operation: {
          pathToAttribute: 'totalObjectsWithPii',
          operator: OPERATORS.GREATER_THAN,
          value: 0,
        },
      },
    },
    {
      id: 'filteredAttributeByDs',
      filterArray: {
        pathToArray: 'data.attributeByDs.attributeByDs',
        operation: {
          pathToAttribute: 'numberOfAttributes',
          operator: OPERATORS.GREATER_THAN,
          value: 0,
        },
      },
    },
    {
      id: 'DataArrayForHorizontalBar',
      createArray: {
        array: [
          {
            label: 'External users',
            value: 'data.usersByType.data.external',
          },
          {
            label: 'Internal users',
            value: 'data.usersByType.data.internal',
          },
          {
            label: 'Other',
            value: 'data.usersByType.data.na',
          },
        ],
      },
    },
    {
      id: 'totalObjectsWithPii_byType',
      sumFromArray: {
        pathToArray: 'data.objectSummariesByType.summaryByTypes',
        pathToAttribute: 'totalObjectsWithPii',
      },
    },
    {
      id: 'totalObjectsWithPiiAndOpenAccess_byType',
      sumFromArray: {
        pathToArray: 'data.objectSummariesByType.summaryByTypes',
        pathToAttribute: 'totalObjectsWithPiiAndOpenAccess',
      },
    },
    {
      id: 'totalObjectsWithPiiAndOpenAccess_byDataType',
      sumFromArray: {
        pathToArray: 'data.objectSummariesByDataSource.summaries',
        pathToAttribute: 'totalObjectsWithPiiAndOpenAccess',
      },
    },
    {
      id: 'totalObjectsWithPii_byDataType',
      sumFromArray: {
        pathToArray: 'data.objectSummariesByDataSource.summaries',
        pathToAttribute: 'totalObjectsWithPii',
      },
    },
    {
      id: 'totalObjects_byDataType',
      sumFromArray: {
        pathToArray: 'data.objectSummariesByDataSource.summaries',
        pathToAttribute: 'totalObjects',
      },
    },
    {
      id: 'pieChartDataByType',
      createArray: {
        array: [
          {
            label: 'Sensitive files with open access',
            value: 'data.totalObjectsWithPiiAndOpenAccess_byType',
          },
          {
            label: 'Sensitive files without open access',
            value: 'data.totalObjectsWithPii_byType',
          },
        ],
      },
    },
    {
      id: 'pieChartDataBySensitivityClassificationRatio',
      createArray: {
        array: [
          {
            label: 'Classified Sensitive',
            value: 'data.sensitivityClassificationRatio.data.classifiedItemsAmount',
          },
          {
            label: 'Undefined',
            value: 'data.sensitivityClassificationRatio.data.unclassifiedItemsAmount',
          },
        ],
      },
    },
    {
      id: 'totalFindingsCountByDs',
      filterArray: {
        pathToArray: 'data.objectSummariesSortedByPii.summaries',
        operation: {
          pathToAttribute: 'totalObjectsWithPii',
          operator: OPERATORS.GREATER_THAN,
          value: 0,
        },
      },
    },
  ],
  widgets: [
    {
      id: 'totalObjectsHighlightBox',
      header: {
        title: ' ',
      },
      widget: {
        highlightBox: {
          title: '{{data.totalObjects_byDataType}}',
          subtitle: 'Total objects count',
          svgString:
            "<svg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='31.015' height='32.555' viewBox='0 0 31.015 32.555'><defs><style>.bigIdPoliciesGradient{fill:url(#bigIdPoliciesGradient);}</style><linearGradient id='bigIdPoliciesGradient' x2='1.156' y2='1' gradientUnits='objectBoundingBox'><stop offset='0' stop-color='#61b6f4'/><stop offset='1' stop-color='#0725a2'/></linearGradient></defs><g transform='translate(-58.633 -179.287)'><path class='bigIdPoliciesGradient' d='M62.937,211.842a4.389,4.389,0,0,1-4.3-3.975L58.633,186.4a4.416,4.416,0,0,1,4.705-4.051l3.549,0a1,1,0,0,1,0,2H63.26a2.406,2.406,0,0,0-2.631,2.147l0,21.286a2.371,2.371,0,0,0,2.55,2.058l21.838,0a2.415,2.415,0,0,0,2.631-2.147l0-21.287a2.372,2.372,0,0,0-2.549-2.057l-3.7,0a1,1,0,0,1,0-2h3.626a4.374,4.374,0,0,1,4.623,3.961l0,21.472a4.412,4.412,0,0,1-4.705,4.052l-21.683,0C63.152,211.838,63.044,211.842,62.937,211.842Z'/><path class='bigIdPoliciesGradient' d='M79.867,187.4H68.414a2.53,2.53,0,0,1-2.527-2.527v-3.054a2.53,2.53,0,0,1,2.527-2.527H79.867a2.53,2.53,0,0,1,2.528,2.527v3.054A2.53,2.53,0,0,1,79.867,187.4Zm-11.453-6.108a.527.527,0,0,0-.527.527v3.054a.527.527,0,0,0,.527.527H79.867a.528.528,0,0,0,.528-.527v-3.054a.528.528,0,0,0-.528-.527Z'/></g></svg>",
          onClickRedirectUrl: {
            state: 'dataCatalog',
          },
        },
      },
    },
    {
      id: 'sensitiveOverTimeLineChart',
      header: {
        title: 'Sensitive files over time',
      },
      showFrame: true,
      widget: {
        lineChart: {
          height: '320px',
          data: {
            pathToArray: 'data.trends.trend',
            pathToDate: 'snapshotDate',
            keys: [{ keyName: 'totalObjectsWithPiiAndOpenAccess', label: 'Total number of Sensitive files' }],
          },
        },
      },
    },
    {
      id: 'topDataSourcesBySensitiveFilesBarChart',
      header: {
        title: 'Top data sources with sensitive files',
      },
      showFrame: true,
      widget: {
        barChart: {
          height: '320px',
          disableColumnsShadow: true,
          data: {
            pathToArray: 'data.filteredDataSources',
            pathToColumnCategory: 'source',
            keys: [{ keyName: 'totalObjectsWithPii', label: 'Objects with Pii' }],
          },
        },
      },
    },
    {
      id: 'attributeCountByFindings',
      header: {
        title: 'Top attributes count by findings',
      },
      showFrame: true,
      widget: {
        barChart: {
          height: '320px',
          disableColumnsShadow: true,
          data: {
            pathToArray: 'data.attributeRisks.attribute_risks',
            pathToColumnCategory: 'short_name',
            keys: [{ keyName: 'count', label: 'Attributes' }],
          },
        },
      },
    },
    {
      id: 'attributeCountByObjects',
      header: {
        title: 'Top attributes count by objects',
      },
      showFrame: true,
      widget: {
        barChart: {
          height: '320px',
          disableColumnsShadow: true,
          data: {
            pathToArray: 'data.classificationsByObject.attributesByObjects',
            pathToColumnCategory: 'attribute',
            keys: [{ keyName: 'count', label: 'Objects' }],
          },
        },
      },
    },
    {
      id: 'byTypeHorizontalBar',
      header: {
        title: 'Users with direct access by type',
      },
      showFrame: true,
      widget: {
        horizontalBar: {
          height: '160px',
          data: {
            pathToArray: 'data.DataArrayForHorizontalBar',
            pathToKey: 'label',
            pathToValue: 'value',
          },
        },
      },
    },
    {
      id: 'sensitive_files_with_open_access_pie_chart',
      header: {
        title: 'Sensitive files and open access',
      },
      showFrame: true,
      widget: {
        pieChart: {
          minimized: false,
          data: {
            pathToArray: 'data.pieChartDataByType',
            pathToKey: 'label',
            pathToValue: 'value',
          },
          onLegendClickRedirectUrl: [
            {
              category: 'In proggress',
              onClickRedirectUrl: {
                url: 'exploration',
              },
            },
          ],
        },
      },
    },
    {
      id: 'sensitivity_classification_ratio_pie_chart',
      header: {
        title: 'Classified Sensitive vs Undefined in the Catalog',
      },
      showFrame: true,
      widget: {
        pieChart: {
          minimized: false,
          data: {
            pathToArray: 'data.pieChartDataBySensitivityClassificationRatio',
            pathToKey: 'label',
            pathToValue: 'value',
          },
          colorsArr: ['#952CD5', '#A0A0A0'],
        },
      },
    },
    {
      id: 'default_group_sensitivity_classification_ratio_pie_chart',
      header: {
        title: 'Sensitivity Levels Distribution in the Catalog',
      },
      showFrame: true,
      widget: {
        pieChart: {
          minimized: false,
          data: {
            pathToArray: 'data.defaultGroupSensitivityClassificationRatio.data.counters',
            pathToKey: 'name',
            pathToValue: 'count',
          },
        },
      },
    },
    {
      id: 'total_findings_count_pie_chart',
      header: {
        title: 'Total findings count by data source',
      },
      showFrame: true,
      widget: {
        pieChart: {
          minimized: false,
          data: {
            pathToArray: 'data.totalFindingsCountByDs',
            pathToKey: 'source',
            pathToValue: 'totalObjectsWithPii',
          },
        },
      },
    },
    {
      id: 'highlight_box_dataSources',
      header: {
        title: ' ',
      },
      widget: {
        highlightBox: {
          title: {
            if: {
              argument1: '{{data.entitySummariesSourceItem.counter}}',
              operator: OPERATORS.GREATER_THAN,
              argument2: 0,
            },
            then: {
              return: '{{data.entitySummariesSourceItem.sub_counter}} / {{data.entitySummariesSourceItem.counter}}',
            },
            else: {
              return: '',
            },
          },
          subtitle: {
            if: {
              argument1: '{{data.entitySummariesSourceItem.counter}}',
              operator: OPERATORS.GREATER_THAN,
              argument2: 0,
            },
            then: {
              return: 'Sources with Findings',
            },
            else: {
              return: 'Configure Data Sources',
            },
          },
          svgString:
            "<svg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='33' height='36.419' viewBox='0 0 33 36.419'><defs><style>.BigIdSourcesGradient{fill:url(#BigIdSourcesGradient);}</style><linearGradient id='BigIdSourcesGradient' x2='1' y2='3.149' gradientUnits='objectBoundingBox'><stop offset='0' stop-color='#ee80ee'/><stop offset='1' stop-color='#7f269f'/></linearGradient></defs><g transform='translate(-128.999 -290.887)'><path class='BigIdSourcesGradient' d='M145.5,301.937c-7.947,0-16.5-1.729-16.5-5.525s8.552-5.525,16.5-5.525,16.5,1.729,16.5,5.525S153.445,301.937,145.5,301.937Zm0-8.681c-9.285,0-14.205,2.212-14.261,3.156.048.944,4.96,3.156,14.261,3.156s14.213-2.212,14.262-3.156C159.7,295.468,154.784,293.256,145.5,293.256Z' transform='translate(0 0)'/><path class='BigIdSourcesGradient' d='M145.5,313.406c-6.128,0-16.5-1.163-16.5-5.525a1.121,1.121,0,1,1,2.238,0c0,.917,5,3.156,14.261,3.156s14.262-2.24,14.262-3.156a1.121,1.121,0,1,1,2.238,0C162,312.243,151.627,313.406,145.5,313.406Z' transform='translate(0 1.666)'/><path class='BigIdSourcesGradient' d='M145.5,326.63c-8.2,0-16.5-2.192-16.5-6.38V295.663a1.119,1.119,0,0,1,2.238,0v24.588c0,1.7,5.555,4.158,14.261,4.158s14.262-2.463,14.262-4.158V295.663a1.119,1.119,0,0,1,2.238,0v24.588C162,324.439,153.7,326.63,145.5,326.63Z' transform='translate(0 0.676)'/></g></svg>",
          onClickRedirectUrl: {
            state: {
              if: {
                argument1: '{{data.entitySummariesSourceItem.counter}}',
                operator: OPERATORS.GREATER_THAN,
                argument2: 0,
              },
              then: {
                return: 'dataCatalog',
              },
              else: {
                return: 'dataSourcesInit',
              },
            },
          },
        },
      },
    },
    {
      id: 'highlight_box_objects',
      header: {
        title: ' ',
      },
      widget: {
        highlightBox: {
          title: {
            if: {
              argument1: '{{data.objectsSummaryTotalObjectsWithPii}}',
              operator: OPERATORS.GREATER_THAN,
              argument2: 0,
            },
            then: {
              return: '{{data.objectsSummaryTotalObjectsWithPii}}',
            },
            else: {
              return: '',
            },
          },
          subtitle: {
            if: {
              argument1: '{{data.objectsSummaryTotalObjectsWithPii}}',
              operator: OPERATORS.GREATER_THAN,
              argument2: 0,
            },
            then: {
              return: 'Objects with Findings',
            },
            else: {
              return: 'Configure Scan',
            },
          },
          svgString:
            "<svg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='27.961' height='32.546' viewBox='0 0 27.961 32.546'><defs><style>.BigidObjectsGradient{fill:url(#BigidObjectsGradient);}</style><linearGradient id='BigidObjectsGradient' x2='1' y2='1' gradientUnits='objectBoundingBox'><stop offset='0' stop-color='#80eecc'/><stop offset='1' stop-color='#00987b'/></linearGradient></defs><g transform='translate(-60.136 -53.279)'><path class='BigidObjectsGradient' d='M64.259,85.824A4.155,4.155,0,0,1,60.136,81.8V57.334a4.163,4.163,0,0,1,4.275-4.053H75.738a1,1,0,0,1,.685.272l11.359,10.69a1,1,0,0,1,.315.728v16.8a4.158,4.158,0,0,1-4.275,4.054H64.381Zm.054-30.545a2.163,2.163,0,0,0-2.178,2.087v24.4a2.17,2.17,0,0,0,2.216,2.055h19.5A2.145,2.145,0,0,0,86.1,81.737V65.4L75.341,55.28H64.313Z'/><path class='BigidObjectsGradient' d='M87.1,65.97H75.738a1,1,0,0,1-1-1V54.28a1,1,0,1,1,2,0v9.69H87.1a1,1,0,0,1,0,2Z'/></g></svg>",
          onClickRedirectUrl: {
            state: {
              if: {
                argument1: '{{data.objectsSummaryTotalObjectsWithPii}}',
                operator: OPERATORS.GREATER_THAN,
                argument2: 0,
              },
              then: {
                return: 'dataCatalog',
              },
              else: {
                return: 'scans',
              },
            },
          },
        },
      },
    },
    {
      id: 'AttributeCountByDsHorizontalBar',
      header: {
        title: 'Top Attribute count by data source',
      },
      showFrame: true,
      widget: {
        horizontalBar: {
          height: '160px',
          data: {
            pathToArray: 'data.filteredAttributeByDs',
            pathToKey: 'source',
            pathToValue: 'numberOfAttributes',
          },
        },
      },
    },
  ],
  layout: {
    direction: 'row',
    justifyContent: 'center',
    alignContent: 'center',
    alignItems: 'center',
    spacing: 1,
    layoutSlots: [
      {
        size: 10,
        layoutGrid: {
          justifyContent: 'center',
          alignItems: 'center',
          spacing: 2,
          layoutSlots: [
            {
              widgetId: 'highlight_box_dataSources',
              size: 2,
            },
            {
              widgetId: 'totalObjectsHighlightBox',
              size: 2,
            },
            {
              widgetId: 'highlight_box_objects',
              size: 2,
            },
          ],
        },
      },
      {
        size: 10,
        layoutGrid: {
          justifyContent: 'space-between',
          alignItems: 'flex-start',
          spacing: 2,
          layoutSlots: [
            {
              widgetId: 'sensitiveOverTimeLineChart',
              size: 12,
            },
          ],
        },
      },
      {
        size: 10,
        layoutGrid: {
          justifyContent: 'space-between',
          alignItems: 'center',
          spacing: 2,
          layoutSlots: [
            {
              widgetId: 'sensitive_files_with_open_access_pie_chart',
              size: 6,
            },
            {
              widgetId: 'total_findings_count_pie_chart',
              size: 6,
            },
            {
              widgetId: 'sensitivity_classification_ratio_pie_chart',
              size: 6,
            },
            {
              widgetId: 'default_group_sensitivity_classification_ratio_pie_chart',
              size: 6,
            },
            {
              widgetId: 'byTypeHorizontalBar',
              size: 6,
            },
            {
              widgetId: 'AttributeCountByDsHorizontalBar',
              size: 6,
            },
          ],
        },
      },
    ],
  },
};
