import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { debounce, words, intersectionWith, includes } from 'lodash';
import {
  BigidBody1,
  BigidColors,
  BigidColorsV2,
  BigidLoader,
  BigidSearch,
  BigidTabs,
  BigidTabsItem,
  PrimaryButton,
} from '@bigid-ui/components';
import { pageHeaderService } from '../../../common/services/pageHeaderService';
import { InstalledApps } from './Tabs/InstalledApps';
import { PreferenceTab } from './Tabs/PreferenceTab';
import { $state, $stateParams } from '../../services/angularServices';
import {
  APP_MANAGEMENT_USER_PREFERENCE_NAME,
  ApplicationManagementPreferences,
  applicationManagementService,
  Apps,
  CategoriesEnum,
  CustomApps,
  MarketplaceApp,
  TabUrls,
} from './applicationManagementService';
import { CategoryMenu } from './Components/CategoryMenu';
import { CustomAppEvents, customAppEventsEmitter } from '../../services/customAppEvents';
import { customAppService } from '../../services/customAppService';
import { UserPreference, userPreferencesService } from '../../services/userPreferencesService';
import { userPreferencesEventEmitter, UserPreferencesEvents } from '../../services/eventEmitters/userPreferencesEvents';
import { EventEmitterDeregistrator } from '@bigid-ui/utils';
import { APPLICATIONS_PERMISSIONS } from '@bigid/permissions';
import { isPermitted } from '../../services/userPermissionsService';
import { smallIdLicenseService } from '../../services/smallIdLicenseService';
import { getApplicationPreference } from '../../services/appPreferencesService';
import {
  BigidApplicationsIcon,
  BigidAppsIllustration,
  BigidHistoryIcon,
  BigidInstalledApplicationsIcon,
  BigidStarIcon,
} from '@bigid-ui/icons';
import LockIcon from '../../assets/icons/Lock.svg';
import { MarketplaceConnectivity } from './Tabs/MarketplaceConnectivity/MarketplaceConnectivity';

export const APPS_PAGE_TITLE = 'Apps Center';
const MAX_SEARCH_WIDTH = 415;
const TAB_WRAPPER_WIDTH = 300;
const ADD_APP_BUTTON_TEXT = 'Add App via URL';

const useStyles = makeStyles(theme => ({
  root: {
    height: 'calc(100% - 10px)',
    width: '100%',
    background: BigidColors.white,
    borderRadius: 6,
    paddingLeft: '16px',
    paddingRight: '24px',
    display: 'flex',
    boxShadow: theme.vars.tokens.bigid.shadow10,
    border: theme.vars.tokens.bigid.borderDefault,
  },
  tabWrapper: {
    width: TAB_WRAPPER_WIDTH,
  },
  contentWrapper: {
    marginLeft: 16,
    paddingTop: '24px',
    '@media (max-width: 1200px)': {
      marginLeft: 0,
    },
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  header: {
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
    marginBottom: 12,
    paddingLeft: 8,
  },
  search: {
    width: '100%',
    maxWidth: MAX_SEARCH_WIDTH,
    marginRight: 16,
  },
  selectedCategoryWrapper: {
    display: 'flex',
    alignItems: 'center',
    paddingBottom: 8,
    cursor: 'pointer',
  },
  selectedCategoryText: { fontSize: '1.125rem', color: BigidColors.gray[700], marginRight: 8 },
  selectedCategoryIcon: { fill: BigidColors.gray[700], height: 18, width: 18 },
  lock: { width: 12, height: 12, marginRight: 8 },
  addAppSection: {
    display: 'flex',
    height: 'calc(100% - 230px)',
    margin: '24px 0px',
    flexDirection: 'column',
    alignItems: 'center',
    boxShadow: '0px 0px 5px 0px #00000026',
    backgroundColor: BigidColorsV2.gray[100],
    justifyContent: 'center',
  },
  addAppButton: {
    paddingTop: '8px',
  },
}));

const isSearchFound = (toFind: string, searchWords: string[]) =>
  intersectionWith(words(toFind.toLowerCase()), searchWords, includes).length > 0;

export const ApplicationsManagement: FC = () => {
  const [selectedCategory, setSelectedCategory] = useState<string>(CategoriesEnum.ALL);
  const [customApps, setCustomApps] = useState<CustomApps[]>([]);
  const [marketplaceApps, setMarketplaceApps] = useState<MarketplaceApp[]>([]);
  const [isCustomAppsLoading, setIsCustomAppsLoading] = useState(false);
  const [isMarketplaceAppsLoading, setIsMarketplaceAppsLoading] = useState(false);
  const [customAppsToDisplay, setCustomAppsToDisplay] = useState<CustomApps[]>([]);
  const [marketplaceAppsToDisplay, setMarketplaceAppsToDisplay] = useState<MarketplaceApp[]>([]);
  const [appManagementPreferences, setAppsManagementPreferences] =
    useState<UserPreference<ApplicationManagementPreferences>>(null);

  const apps = applicationManagementService.getApps();
  const [appsToDisplay, setAppsToDisplay] = useState<Apps[]>(apps);

  const shouldShowCustomApps = getApplicationPreference('SHOW_CUSTOM_APPS');
  const isSmallID = smallIdLicenseService.isSmallIDLicense();
  const classes = useStyles({});

  const tabs = useMemo(() => {
    return [
      {
        label: 'Installed Apps',
        icon: { Component: BigidInstalledApplicationsIcon },
        data: TabUrls.INSTALLED_APPS,
        component: InstalledApps,
      },
      {
        label: 'My Favorites',
        icon: { Component: BigidStarIcon },
        data: TabUrls.FAVORITES,
        component: PreferenceTab,
      },
      {
        label: 'Recently Used',
        icon: { Component: BigidHistoryIcon, stroke: true },
        data: TabUrls.RECENTLY_USED,
        component: PreferenceTab,
      },
      {
        label: 'Marketplace',
        icon: { Component: BigidApplicationsIcon },
        data: TabUrls.MARKETPLACE,
        component: MarketplaceConnectivity,
      },
    ];
  }, []);

  const [selectedTabIndex, setSelectedTabIndex] = useState(
    tabs.findIndex(({ data }) => data === $stateParams.currentTab),
  );

  const isAllAppsTab = selectedTabIndex === 3;
  const onTabChange = (value: number, tab: BigidTabsItem) => {
    setAppsToDisplay(apps);
    setCustomAppsToDisplay(customApps);
    setSelectedCategory(CategoriesEnum.ALL);
    $state.go('.', { currentTab: tab.data });
    setSelectedTabIndex(tabs.findIndex(({ data }) => data === tab.data));
  };

  const TabContent = tabs[selectedTabIndex].component;

  const fetchUserPreferences = useCallback(async () => {
    setAppsManagementPreferences(
      await userPreferencesService.get<ApplicationManagementPreferences>(APP_MANAGEMENT_USER_PREFERENCE_NAME),
    );
  }, []);

  const updateCustomApplications = (): void => {
    setIsCustomAppsLoading(true);
    setIsMarketplaceAppsLoading(true);
    customAppService
      .fetchApplicationsFromMarketplace()
      .then(({ data }) => {
        setMarketplaceApps(data);
        setMarketplaceAppsToDisplay(data);
      })
      .finally(() => {
        setIsMarketplaceAppsLoading(false);
      });
    customAppService.getCustomApps().then(({ data }) => {
      const customAppsRes = data.map(({ category, ...item }: any) => {
        if (!category) {
          return { ...item, category: CategoriesEnum.UTILITIES };
        }
        const categoryMatchIndex = Object.values(CategoriesEnum)
          .map(c => c.toLowerCase())
          .findIndex(c => c.includes(category.toLowerCase()));
        return {
          ...item,
          category:
            categoryMatchIndex > -1 ? Object.values(CategoriesEnum)[categoryMatchIndex] : CategoriesEnum.UTILITIES,
        };
      });
      setCustomApps(customAppsRes);
      setCustomAppsToDisplay(customAppsRes);
      setIsCustomAppsLoading(false);
    });
  };

  useEffect(() => {
    pageHeaderService.setTitle({ pageTitle: APPS_PAGE_TITLE });

    fetchUserPreferences();
    const unregisterFetchRecentsListener = userPreferencesEventEmitter.addEventListener(
      UserPreferencesEvents.UPDATE_FAVORITES_MANAGEMENT_PREFERENCES,
      fetchUserPreferences,
    );

    let customAppEventListener: EventEmitterDeregistrator = null;
    if (shouldShowCustomApps) {
      updateCustomApplications();
      customAppEventListener = customAppEventsEmitter.addEventListener(
        CustomAppEvents.UPDATE_APP_LIST,
        updateCustomApplications,
      );
    }

    return () => {
      customAppEventListener?.();
      unregisterFetchRecentsListener();
    };
  }, [fetchUserPreferences, shouldShowCustomApps]);

  const onSearchChange = useCallback(
    debounce((value: string) => {
      if (!value || value === '') {
        setAppsToDisplay(apps);
        setCustomAppsToDisplay(customApps);
        setMarketplaceAppsToDisplay(marketplaceApps);
        return;
      }
      const searchWords = words(value.toLowerCase());
      setAppsToDisplay(
        apps
          .filter(({ name, category }) => name && category)
          .filter(({ name, category }) => isSearchFound(name, searchWords) || isSearchFound(category, searchWords)),
      );
      setCustomAppsToDisplay(
        customApps
          .filter(({ tpa_name: name, category }) => name && category)
          .filter(
            ({ tpa_name: name, category }) => isSearchFound(name, searchWords) || isSearchFound(category, searchWords),
          ),
      );
      setMarketplaceAppsToDisplay(
        marketplaceApps
          .filter(({ appName: name, appSpecs: { category } }) => name && category)
          .filter(
            ({ appName: name, appSpecs: { category } }) =>
              isSearchFound(name, searchWords) || isSearchFound(category, searchWords),
          ),
      );
    }, 150),
    [customApps, marketplaceApps],
  );

  const shouldShowAddAppButton =
    shouldShowCustomApps && isPermitted(APPLICATIONS_PERMISSIONS.DELETE_AND_ADD_CUSTOM_APPS.name);

  const goToCreateAppPage = () => $state.go('createCustomApp', { prevState: $state.current.name });
  const shouldShowLoader = useMemo(
    () => (isAllAppsTab ? isMarketplaceAppsLoading : isCustomAppsLoading),
    [isAllAppsTab, isCustomAppsLoading, isMarketplaceAppsLoading],
  );
  return (
    <div className={classes.root}>
      <div className={classes.tabWrapper}>
        <BigidTabs
          size={'large'}
          variant={'fullWidth'}
          tabs={tabs}
          orientation="vertical"
          onChange={onTabChange}
          selectedIndex={selectedTabIndex}
        />

        {shouldShowAddAppButton && (
          <div className={classes.addAppSection}>
            <BigidAppsIllustration size="small" />
            <BigidBody1>Have a link to install an App?</BigidBody1>
            <span className={classes.addAppButton}>
              <PrimaryButton
                onClick={goToCreateAppPage}
                size="small"
                disabled={isSmallID}
                dataAid="custom-app-add-button"
                startIcon={isSmallID && <LockIcon />}
                text={ADD_APP_BUTTON_TEXT}
              />
            </span>
          </div>
        )}
      </div>
      <div className={classes.contentWrapper}>
        <div className={classes.header}>
          <CategoryMenu selected={selectedCategory} setSelected={setSelectedCategory} />
          <div className={classes.search}>
            <BigidSearch onChange={onSearchChange} onSubmit={onSearchChange} placeholder="Search" />
          </div>
        </div>
        {shouldShowLoader ? (
          <BigidLoader />
        ) : (
          <TabContent
            apps={appsToDisplay}
            preference={tabs[selectedTabIndex].data}
            selectedCategory={selectedCategory}
            customApps={customAppsToDisplay}
            marketplaceApps={marketplaceAppsToDisplay}
            fetchUserPreferences={fetchUserPreferences}
            {...appManagementPreferences?.data}
          />
        )}
      </div>
    </div>
  );
};
