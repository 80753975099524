import React, { FunctionComponent } from 'react';
import { BigidTabsAndContent, BigidTabsAndContentProps } from '@bigid-ui/components';
import { Paper } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { DataCatalogDetails } from '../DataCatalogDetails';
import { DataCatalogAttributes } from '../DataCatalogAttributes';
import { DataCatalogFileDuplicates } from '../DataCatalogFileDuplicates/DataCatalogFileDuplicates';
import { PreviewFileContent } from './PreviewFileContent';
import { CATALOG_PERMISSIONS } from '@bigid/permissions';
import { isPermitted } from '../../../services/userPermissionsService';

const FILE = 'file';

export interface FileDetailsProps {
  fullyQualifiedName: string;
  fullObjectName: string;
  scannerType: string;
  dsName: string;
  containerName: string;
  objectType: string;
}

const useStyles = makeStyles({
  paper: {
    margin: '16px 32px',
    width: 'calc(100% - 64px)',
    height: 'calc(100% - 32px)',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
  },
  content: {
    padding: '8px 24px 16px 24px',
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  },
  contentContainer: {
    display: 'flex',
    overflow: 'hidden',
    flexFlow: 'row nowrap',
    flex: '1 1 auto',
    padding: '5px',
  },
});
export const FileDetails: FunctionComponent<FileDetailsProps> = ({
  fullyQualifiedName,
  fullObjectName,
  dsName,
  containerName,
  objectType,
  scannerType,
}) => {
  const classes = useStyles({});

  const isPreviewFileAvailable = isPermitted(CATALOG_PERMISSIONS.PREVIEW_FILE_INVESTIGATION.name);
  const tabsAndContentConfig: BigidTabsAndContentProps = {
    classes: {
      contentContainer: classes.contentContainer,
    },
    tabProps: {
      selectedIndex: 0,
      tabs: [
        {
          label: 'Details',
          data: { component: DataCatalogDetails, customProps: { fullyQualifiedName, source: dsName } },
        },
        {
          label: 'Attributes',
          data: { component: DataCatalogAttributes, customProps: { fullyQualifiedName } },
        },
        {
          label: 'Preview',
          data: {
            component: PreviewFileContent,
            customProps: { fullObjectName, source: dsName, containerName, fullyQualifiedName, scannerType, objectType },
          },
          getIsAvailable: (type = objectType) => type.toLowerCase() === FILE && isPreviewFileAvailable,
        },
        {
          label: 'Duplicates',
          data: {
            component: DataCatalogFileDuplicates,
            customProps: { fullObjectName, source: dsName, containerName, fullyQualifiedName },
          },
          getIsAvailable: (type = objectType) => type.toLowerCase() === FILE,
        },
      ],
    },
  };

  return (
    <Paper elevation={0} className={classes.paper}>
      <div className={classes.content}>
        <BigidTabsAndContent {...tabsAndContentConfig} />
      </div>
    </Paper>
  );
};
