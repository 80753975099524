import React, { FC } from 'react';
import styled from '@emotion/styled';
import { ApplicationActions, ApplicationActionsProps } from './ApplicationActions';
import { BigidHeading5 } from '@bigid-ui/components';

export type ApplicationHeaderProps = ApplicationActionsProps & {
  title: string;
};

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 24px;
  gap: 16px;
  height: 100%;
`;

const styles = {
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  fontWeight: 700,
};

export const ApplicationHeader: FC<ApplicationHeaderProps> = ({ title, row, handleSubmit, handleDelete }) => (
  <Header>
    <BigidHeading5 sx={styles}>{title}</BigidHeading5>
    <ApplicationActions row={row} handleSubmit={handleSubmit} handleDelete={handleDelete} />
  </Header>
);
