import { ViewType, ActionableInsightsTabs, JiraConfiguration } from '../actionableInsightsService';
import { ConfigurationsMetadata } from '../../ActionCenter/ConfigurationManagement/configurationManagementTypes';
import { BigidDropdownOption } from '@bigid-ui/components';
import { getFixedT } from '../translations';
import { CaseState } from '../../../types/actionableInsights';

export enum ReducerActions {
  'UPDATE_LATEST_TICKETING_SERVICE',
  'UPDATE_CONFIGURATIONS',
  'UPDATE_ACTIONABLE_INSIGHTS_DATA',
  'INIT',
}

export interface ActionableInsightsReducerAction {
  type: ReducerActions;
  payload: Partial<ActionableInsightsReducerState>;
}

export interface ActionableInsightsReducerState {
  caseState: CaseState;
  jiraConfigurationsMetadata: ConfigurationsMetadata;
  jiraConfigurations: JiraConfiguration[];
  viewType: ViewType;
  systemUsers: BigidDropdownOption[];
  tabs: ActionableInsightsTabs;
  scMapping: Map<string, number>;
  selectedTicketingService: undefined | JiraConfiguration;
  listUnsupportedDs: string[];
}

const tDefaultClassification = getFixedT('CaseReport.defaultSensitivity');

const defaultScMapper = new Map([
  [tDefaultClassification('restricted'), 0],
  [tDefaultClassification('confidential'), 1],
  [tDefaultClassification('public'), 2],
]);

export const actionableInsightsReducer: React.Reducer<
  ActionableInsightsReducerState,
  ActionableInsightsReducerAction
> = (state, { type, payload }) => {
  switch (type) {
    case ReducerActions.UPDATE_ACTIONABLE_INSIGHTS_DATA: {
      return { ...state, ...payload };
    }

    case ReducerActions.INIT: {
      return getInitialReducerState(payload);
    }

    case ReducerActions.UPDATE_LATEST_TICKETING_SERVICE: {
      return { ...state, ...payload };
    }

    case ReducerActions.UPDATE_CONFIGURATIONS: {
      return { ...state, ...payload };
    }

    default:
      return state;
  }
};

export const getInitialReducerState = ({
  caseState = CaseState.OPEN,
  viewType = ViewType.GROUPED,
  tabs = [],
  jiraConfigurationsMetadata = [],
  jiraConfigurations = [],
  systemUsers = [],
  scMapping = defaultScMapper,
  selectedTicketingService = undefined,
  listUnsupportedDs = [],
}: Partial<ActionableInsightsReducerState>): ActionableInsightsReducerState => {
  return {
    caseState,
    jiraConfigurationsMetadata,
    jiraConfigurations,
    viewType,
    systemUsers,
    tabs,
    scMapping,
    selectedTicketingService,
    listUnsupportedDs,
  };
};
