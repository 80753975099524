import { BigidHeading6, PrimaryButton } from '@bigid-ui/components';
import { generateDataAid } from '@bigid-ui/utils';
import { styled } from '@mui/material';
import React, { FC, useEffect } from 'react';
import { CONFIG } from '../../../../config/common';
import { $state } from '../../../services/angularServices';
import { DEFAULT_NUMBER_OF_ITEMS_TO_DISPLAY } from '../constants';
import { fetchDataSourcesByScanDate, mapDataSourcesToDataExplorerItems } from '../dataExplorerService';
import { useLocalTranslation } from '../translations';
import { EntityList } from './components/EntityList';
import { NoData } from './components/NoData';
import { BaseFooterSectionContainer } from './components/shared';
import { useQuery } from 'react-query';

type RecentlyDiscoveredProps = {
  dataAid?: string;
};

const Container = styled(BaseFooterSectionContainer)`
  ${({ theme }) => theme.breakpoints.only('lg')} {
    width: 340px;
  }
  ${({ theme }) => theme.breakpoints.down('lg')} {
    width: 320px;
  }
`;

export const RecentlyDiscovered: FC<RecentlyDiscoveredProps> = ({ dataAid = 'RecentlyDiscovered' }) => {
  const { t } = useLocalTranslation('RecentlyDiscovered');

  const { data, isLoading, refetch } = useQuery(
    'recentlyDiscoveredDS',
    async () => {
      return await fetchDataSourcesByScanDate({
        limit: DEFAULT_NUMBER_OF_ITEMS_TO_DISPLAY,
        skip: 0,
      });
    },
    { initialData: [] },
  );

  useEffect(() => {
    refetch();
  }, [data, refetch]);

  const handleAddNewScanClick = () => {
    $state.go(CONFIG.states.SCANS_NEW_SCANS);
  };

  const entities = mapDataSourcesToDataExplorerItems(data.slice(0, DEFAULT_NUMBER_OF_ITEMS_TO_DISPLAY));
  const isEmptyState = entities.length === 0;

  return (
    <Container data-aid={generateDataAid(dataAid, ['footer-section'])}>
      <BigidHeading6 textAlign={isEmptyState ? 'center' : 'start'}>{t('title')}</BigidHeading6>
      <EntityList
        listItemMaxWidth="230px"
        dataAid={generateDataAid(dataAid, ['list'])}
        isLoaderShown={isLoading}
        entities={entities}
        hasSeparator
        noDataComponent={
          <NoData
            dataAid={generateDataAid(dataAid, ['no-data'])}
            description={t('noDataDescription')}
            actionComponent={<PrimaryButton onClick={handleAddNewScanClick} size="small" text={t('action')} />}
          />
        }
      />
    </Container>
  );
};
