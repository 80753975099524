import {
  SuggestedActionItemActions,
  SuggestedActionItemContent,
  SuggestedActionItemWrapper,
  SuggestedActionItemSeparator,
  SuggestedActionsWrapper,
} from '../DataSourceSuggestedActionsStyles';

export const SuggestedActionItem = {
  Wrapper: SuggestedActionItemWrapper,
  Actions: SuggestedActionItemActions,
  Content: SuggestedActionItemContent,
  Separator: SuggestedActionItemSeparator,
};

export const SuggestedActions = {
  Wrapper: SuggestedActionsWrapper,
};
