import {
  getMetadataSearchIndexingStatus,
  startMetadataSearchIndexing,
} from '../../components/MetadataSearch/MetadataSearchService';
import { httpService, QueryParamsAndDataObject } from '../../services/httpService';
import { queryService } from '../../services/queryService';
import { userPreferencesService } from '../../services/userPreferencesService';
import {
  Aggregation,
  AggregationItemBase,
  AggregationType,
  CatalogDiscoveryPreferences,
} from './catalogDiscoveryTypes';
import { CONFIG } from '../../../config/common';
import { MetadataSearchTypeIndexingStatus } from '../../components/MetadataSearch/MetadataSearchTypes';

export type Paging = {
  limit: number;
  skip: number;
};

export type Sorting = {
  field: string;
  order: 'DESC' | 'ASC';
};

export type GetAggredatedDataRequestObject = {
  aggName: AggregationType;
  sorting?: Sorting[];
  paging?: Paging;
  isGrid?: boolean;
  isTotalRequired?: boolean;
};

export type GetAggregatedDataPayload = {
  filter?: string;
  aggregations: Partial<GetAggredatedDataRequestObject>[];
};

export type GetAggregatedDataResponse<T = AggregationItemBase> = {
  aggregations: Aggregation<T>[];
};

//TODO @alext get rid of the filter query param and fix the e2e tests
export function getAggregatedData<T = AggregationItemBase>(payload: GetAggregatedDataPayload) {
  const query = payload.filter ? `?filter=${encodeURIComponent(payload.filter)}` : '';

  return httpService
    .post<GetAggregatedDataResponse<T>>(`inventory${query}`, payload as unknown as QueryParamsAndDataObject)
    .then(({ data }) => data);
}

//TODO @alext get rid of the filter query param and fix the e2e tests
export const getAggregatedDataCountCancellable = (payload: GetAggregatedDataPayload) => {
  const query = payload.filter ? `?filter=${encodeURIComponent(payload.filter)}` : '';

  return httpService.cancelablePost<GetAggregatedDataResponse>(
    `inventory${query}`,
    payload as unknown as QueryParamsAndDataObject,
  );
};

export function getCompletedScansCount() {
  const query = queryService.getGridConfigQuery({
    limit: 1,
    skip: 0,
    requireTotalCount: true,
    filter: [
      { field: 'state', operator: 'in', value: ['Completed', 'CompletedWithFailures', 'Failed', 'Aborted', 'Stopped'] },
    ],
  });

  return httpService
    .fetch<{ data: { totalCount: number } }>(`scans/parent-scans?${query}`)
    .then(({ data }) => data.data.totalCount);
}

export function getConfiguredDataSourcesCount() {
  const query = queryService.getGridConfigQuery({
    skip: 0,
    limit: 1,
    requireTotalCount: true,
  });

  return httpService
    .fetch<{ data: { totalCount: number } }>(`ds-connections?${query}`)
    .then(({ data }) => data.data.totalCount);
}

export function startCatalogEntityIndexing() {
  return startMetadataSearchIndexing('catalog');
}

export function getCatalogEntityIndexingStatusObject() {
  return getMetadataSearchIndexingStatus().then(({ statusPerType }) =>
    statusPerType.find(({ entityName }) => entityName === 'catalog'),
  );
}

export async function setUserPreferences(newPreferences: Partial<CatalogDiscoveryPreferences>) {
  try {
    const preferences = await userPreferencesService.get<CatalogDiscoveryPreferences>(CONFIG.states.CATALOG_DISCOVERY);

    if (preferences) {
      await userPreferencesService.update({
        preference: CONFIG.states.CATALOG_DISCOVERY,
        data: {
          ...preferences.data,
          ...newPreferences,
        },
      });
    } else {
      await userPreferencesService.add({
        preference: CONFIG.states.CATALOG_DISCOVERY,
        data: newPreferences,
      });
    }
  } catch ({ message }) {
    console.error(`An error has occurred: ${message}`);
  }
}

export async function getUserPreferences(): Promise<CatalogDiscoveryPreferences> {
  try {
    const preference = await userPreferencesService.get<CatalogDiscoveryPreferences>(CONFIG.states.CATALOG_DISCOVERY);
    return preference?.data || {};
  } catch ({ message }) {
    console.error(`An error has occurred: ${message}`);
    return {};
  }
}

export async function getIsAtLeastOneDataSourceConfigured(): Promise<boolean> {
  try {
    const dataSourcesCount = await getConfiguredDataSourcesCount();

    return dataSourcesCount > 0;
  } catch ({ message }) {
    console.error(`An error has occurred: ${message}`);
    return false;
  }
}

export async function getIsAtLeastOneScanCompleted(): Promise<boolean> {
  try {
    const completedScansCount = await getCompletedScansCount();

    return completedScansCount > 0;
  } catch ({ message }) {
    console.error(`An error has occurred: ${message}`);
    return false;
  }
}

export async function getCatalogMDSearchEntityStatus(): Promise<MetadataSearchTypeIndexingStatus> {
  try {
    const catalogEntityStatus: MetadataSearchTypeIndexingStatus = await getCatalogEntityIndexingStatusObject();

    return catalogEntityStatus;
  } catch ({ message }) {
    console.error(`An error has occurred: ${message}`);
    return;
  }
}

export async function getIsCatalogDiscoveryAvailable(isMetadataSearchEnabled: boolean): Promise<boolean> {
  const isAtLeastOneDataSourceConfigured = await getIsAtLeastOneDataSourceConfigured();
  const isAtLeastOneScanCompleted = await getIsAtLeastOneScanCompleted();
  const catalogMDSearchEntityStatus = await getCatalogMDSearchEntityStatus();

  return (
    isMetadataSearchEnabled &&
    isAtLeastOneScanCompleted &&
    isMetadataSearchEnabled &&
    Boolean(catalogMDSearchEntityStatus?.finishedIndexingAt)
  );
}
