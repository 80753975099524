import React, { useCallback, useEffect, useState } from 'react';
import { styled } from '@mui/material';
import {
  BigidBody1,
  BigidColorsV2,
  BigidConfidenceIndicator,
  BigidConfidenceLevel,
  BigidDialog,
  BigidSwitch,
  PrimaryButton,
  SecondaryButton,
} from '@bigid-ui/components';
import { BigidGridColumnTypes, BigidSimpleGrid } from '@bigid-ui/grid';
import { DsarObjectsColumnsAttributeList } from '../ProfileSettingsTypes';
import { updateObjectsColumnsAttributes } from '../../dsarService';
import { DsarSettingsTrackingEvents } from '../../analyticsUtils';

const AttributeWrapper = styled('div')`
  display: flex;
  gap: 4px;
`;

const IconWrapper = styled('div')`
  padding-top: 3px;
`;

const DescriptionWrapper = styled('div')`
  margin-bottom: 16px;
`;

const GreyWrapper = styled('span')`
  color: ${BigidColorsV2.gray[500]};
`;

export interface ObjectsColumnsAttributesDialogProps {
  attributeList: DsarObjectsColumnsAttributeList[];
  columnName: string;
  fullyQualifiedObjectName: string;
  isLoading: boolean;
  isOpen: boolean;
  profileId: string;
  onClose?: () => void;
  onSave?: () => void;
}

export const ObjectsColumnsAttributesDialog = ({
  attributeList: attributeListParent,
  columnName,
  fullyQualifiedObjectName,
  isLoading,
  isOpen,
  profileId,
  onClose,
  onSave,
}: ObjectsColumnsAttributesDialogProps) => {
  const [attributeList, setAttributeList] = useState(attributeListParent);

  useEffect(() => {
    setAttributeList(attributeListParent);
  }, [attributeListParent]);

  const onCloseDialog = () => {
    onClose?.();
  };

  const onSaveDialog = () => {
    const payload = attributeList.map(({ attributeOriginalName, isIncluded }) => ({
      attributeName: attributeOriginalName,
      isIncluded,
    }));
    updateObjectsColumnsAttributes(profileId, fullyQualifiedObjectName, columnName, payload)
      .then(onSave)
      .catch(onClose);
  };

  const onSwitchToggle = (attributeName: string, isIncluded: boolean) => {
    setAttributeList(attributeList =>
      attributeList.reduce((list, attr) => {
        if (attr.attributeName === attributeName) {
          attr.isIncluded = isIncluded;
        }
        return [...list, attr];
      }, []),
    );
  };

  return (
    <BigidDialog
      isOpen={isOpen}
      onClose={onCloseDialog}
      title={`Manage attributes for column "${columnName}"`}
      buttons={[
        {
          component: props => (
            <SecondaryButton
              {...props}
              bi={{
                eventType: DsarSettingsTrackingEvents.PROFILE_OBJECTS_COLUMNS_ATTRIBUTE_MANAGEMENT_CANCEL_ACTION,
              }}
            />
          ),
          onClick: onCloseDialog,
          text: 'Cancel',
        },
        {
          component: props => (
            <PrimaryButton
              {...props}
              bi={{
                eventType: DsarSettingsTrackingEvents.PROFILE_OBJECTS_COLUMNS_ATTRIBUTE_MANAGEMENT_SAVE_ACTION,
              }}
            />
          ),
          onClick: onSaveDialog,
          text: 'Save',
        },
      ]}
      isLoading={isLoading}
    >
      <DescriptionWrapper>
        <BigidBody1>{' Select which attributes to use to search for data related to the data subject.'}</BigidBody1>
      </DescriptionWrapper>
      <div>
        <BigidSimpleGrid
          columns={[
            {
              width: 100,
              title: 'Search by',
              name: 'isIncluded',
              type: BigidGridColumnTypes.TEXT,
              getCellValue: ({ isIncluded, attributeName }) => (
                <BigidSwitch
                  dataAid={`BigidSwitch-${attributeName}`}
                  checked={!!isIncluded}
                  onChange={e => onSwitchToggle(attributeName as string, e.target.checked)}
                />
              ),
              filteringEnabled: false,
              sortingEnabled: false,
              isNotDraggable: false,
              disableTooltip: true,
            },
            {
              width: 468,
              name: 'attributeName',
              title: 'Attribute name (Original name - Type',
              type: BigidGridColumnTypes.TEXT,
              getCellValue: ({ attributeName, rank, attributeOriginalName, attributeType }) => {
                return (
                  <AttributeWrapper>
                    <IconWrapper>
                      <BigidConfidenceIndicator level={(rank as string).toLowerCase() as BigidConfidenceLevel} />
                    </IconWrapper>
                    <span>
                      {attributeName}
                      <GreyWrapper>{` (${attributeOriginalName} - ${attributeType})`}</GreyWrapper>
                    </span>
                  </AttributeWrapper>
                );
              },
              filteringEnabled: false,
              sortingEnabled: false,
              isNotDraggable: false,
            },
          ]}
          rows={attributeList.map(attr => ({
            ...attr,
            id: attr.attributeId,
            isIncluded: Number(attr.isIncluded),
          }))}
        />
      </div>
    </BigidDialog>
  );
};

interface UseObjectsColumnsAttributesDialog {
  dialogProps: ObjectsColumnsAttributesDialogProps;
  openDialog: (
    dialogProps: Pick<
      ObjectsColumnsAttributesDialogProps,
      'attributeList' | 'columnName' | 'profileId' | 'fullyQualifiedObjectName'
    >,
  ) => Promise<boolean>;
}

export const useObjectsColumnsAttributesDialog = (): UseObjectsColumnsAttributesDialog => {
  const [dialogProps, setDialogProps] = useState<ObjectsColumnsAttributesDialogProps>(getClearDialogState());
  const resetState = () => setDialogProps(getClearDialogState());

  const openDialog = useCallback<UseObjectsColumnsAttributesDialog['openDialog']>(
    ({ attributeList = [], columnName, profileId, fullyQualifiedObjectName }) => {
      return new Promise<boolean>(resolve => {
        setDialogProps({
          attributeList,
          columnName,
          fullyQualifiedObjectName,
          isLoading: false,
          isOpen: true,
          profileId,
          onClose: () => {
            resetState();
            resolve(false);
          },
          onSave: () => {
            resetState();
            resolve(true);
          },
        });
      });
    },
    [],
  );

  return {
    openDialog,
    dialogProps,
  };
};

const getClearDialogState = (): ObjectsColumnsAttributesDialogProps => {
  return {
    attributeList: [],
    columnName: '',
    fullyQualifiedObjectName: '',
    isOpen: false,
    isLoading: true,
    profileId: '',
  };
};
