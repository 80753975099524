export enum ClassifiersEventsEnum {
  CLASSIFIER_PAGE_LOADED = 'CLASSIFIER_PAGE_LOADED',

  CREATE_REGEX_CLASSIFIER_DROPDOWN_CLICK = 'CREATE_REGEX_CLASSIFIER_DROPDOWN_CLICK',
  CREATE_NER_CLASSIFIER_DROPDOWN_CLICK = 'CREATE_NER_CLASSIFIER_DROPDOWN_CLICK',
  CREATE_DOC_CLASSIFIER_DROPDOWN_CLICK = 'CREATE_DOC_CLASSIFIER_DROPDOWN_CLICK',
  EDIT_DOC_CLASSIFIER_INLINE_ACTION = 'EDIT_DOC_CLASSIFIER_INLINE_ACTION',
  EDIT_DOC_CLASSIFIER_SIDE_PANEL_ACTION = 'EDIT_DOC_CLASSIFIER_SIDE_PANEL_ACTION',

  REGEX_CLASSIFIER_CREATED_SUCCESSFULLY = 'REGEX_CLASSIFIER_CREATED_SUCCESSFULLY',
  NER_CLASSIFIER_CREATED_SUCCESSFULLY = 'NER_CLASSIFIER_CREATED_SUCCESSFULLY',
  REGEX_CLASSIFIER_UPDATED_SUCCESSFULLY = 'REGEX_CLASSIFIER_UPDATED_SUCCESSFULLY',
  NER_CLASSIFIER_UPDATED_SUCCESSFULLY = 'NER_CLASSIFIER_UPDATED_SUCCESSFULLY',
  DOC_CLASSIFIER_UPDATED_SUCCESSFULLY = 'DOC_CLASSIFIER_UPDATED_SUCCESSFULLY',

  EXCLUDE_LIST_INLINE_ACTION = 'EXCLUDE_LIST_INLINE_ACTION',
  EXCLUDE_LIST_SIDE_PANEL_ACTION = 'EXCLUDE_LIST_SIDE_PANEL_ACTION',

  IMPORT_CLASSIFIERS = 'IMPORT_CLASSIFIERS',
  EXPORT_CLASSIFIERS = 'EXPORT_CLASSIFIERS',

  ASSIGN_CATEGORIES_INLINE_ACTION = 'ASSIGN_CATEGORIES_INLINE_ACTION',
  ASSIGN_CATEGORIES_BULK_ACTION = 'ASSIGN_CATEGORIES_BULK_ACTION',

  ENABLED_INLINE_ACTION = `ENABLED_INLINE_ACTION`,
  DISABLED_INLINE_ACTION = 'DISABLED_INLINE_ACTION',

  ENABLED_BULK_ACTION = 'ENABLED_BULK_ACTION',
  DISABLED_BULK_ACTION = 'DISABLED_BULK_ACTION',

  ENABLED_SIDE_PANEL_ACTION = 'ENABLED_SIDE_PANEL_ACTION',
  DISABLED_SIDE_PANEL_ACTION = 'DISABLED_SIDE_PANEL_ACTION',

  DELETE_CLASSIFIER_INLINE_ACTION = 'DELETE_CLASSIFIER_INLINE_ACTION',
  DELETE_CLASSIFIER_SIDE_PANEL_ACTION = 'DELETE_CLASSIFIER_SIDE_PANEL_ACTION',

  SIDE_PANEL_OPENED = 'SIDE_PANEL_OPENED',
  TESTED_CLASSIFIER_SAVED = 'TESTED_CLASSIFIER_SAVED',
  DUPLICATE_CLASSIFIER = 'DUPLICATE_CLASSIFIER',
  NER_VIEW_DOCUMENTATION = 'NER_VIEW_DOCUMENTATION',
  REGEX_VIEW_DOCUMENTATION = 'REGEX_VIEW_DOCUMENTATION',
}

export enum EnabledType {
  bulk = 'bulk',
  inline = 'inline',
  sidePanel = 'sidePanel',
}
