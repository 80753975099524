import React, { FC } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import classnames from 'classnames';
import { PrimaryButton, SecondaryButton, BigidLink } from '@bigid-ui/components';
import { useLocalTranslation } from '../translations';
import {
  FmsdPreDiscoverCustomizeTypes,
  FmsdPreDiscoverViewsIds,
} from '../FmsdPreDiscover/FmsdPreDiscoverViews/FmsdPreDiscoverViews';
import { FmsdTrackingEvents } from '../fmsdEventsTrackerUtils';

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    width: '100%',
    position: 'relative',
    flexDirection: 'row',
    height: 70,
    paddingTop: 21,
    borderTop: `1px solid ${theme.vars.palette.bigid.gray400}`,
    justifyContent: 'space-between',
  },
  alighRight: {
    justifyContent: 'flex-end',
  },
  skipButton: {
    marginRight: 32,
    fontSize: 14,
    color: theme.vars.palette.bigid.primary900,
  },
}));

export interface NavigationButtonsProps {
  goToNext: () => void;
  goToPrev: () => void;
  noNext: boolean;
  noPrev: boolean;
  shouldDisplaySkip: boolean;
  classifiersMethodology: FmsdPreDiscoverCustomizeTypes;
  activeStepId: string;
  onSkipClick: () => void;
  isConnectedToDiscoveryApp: boolean;
}

export const NavigationButtons: FC<NavigationButtonsProps> = ({
  goToNext,
  goToPrev,
  noNext,
  noPrev,
  shouldDisplaySkip,
  classifiersMethodology,
  activeStepId,
  onSkipClick,
  isConnectedToDiscoveryApp,
}) => {
  const { container, alighRight, skipButton } = useStyles({});
  const { t } = useLocalTranslation('Navigation');
  const isPrevButtonDisabled =
    activeStepId === FmsdPreDiscoverViewsIds.CUSTOMIZE &&
    classifiersMethodology === FmsdPreDiscoverCustomizeTypes.REGULATIONS
      ? false
      : noPrev;
  const showPrevButton = !(
    activeStepId === FmsdPreDiscoverViewsIds.CUSTOMIZE &&
    classifiersMethodology === FmsdPreDiscoverCustomizeTypes.CATEGORIES
  );

  return (
    <div className={classnames(container, { [alighRight]: !showPrevButton })}>
      {showPrevButton && (
        <SecondaryButton
          dataAid="FmsdPreDiscoverWizard-back-button"
          disabled={isPrevButtonDisabled}
          text={t('backButtonText')}
          size="large"
          onClick={goToPrev}
          bi={{ disabled: true }}
        />
      )}
      <div>
        {shouldDisplaySkip && (
          <BigidLink
            classNames={skipButton}
            dataAid="FmsdTestConnection-skip-link"
            text={t(`${isConnectedToDiscoveryApp ? 'skipButtonText' : 'laterButtonText'}`)}
            onClick={onSkipClick}
          />
        )}
        <PrimaryButton
          dataAid="FmsdPreDiscoverWizard-next-button"
          disabled={noNext}
          text={t('nextButtonText')}
          size="large"
          onClick={goToNext}
          bi={{ eventType: FmsdTrackingEvents.NEXT_CLICK }}
        />
      </div>
    </div>
  );
};
