import React, { FC } from 'react';
import { BigidMenuItemProps } from '@bigid-ui/components';
import { InteractiveDashboardCard } from './InteractiveDashboardCard';
import { BigidHorizontalBarWithActions } from './components/BigidHorizontalBarWithActions/BigidHorizontalBarWithActions';
import { InteractiveDashboardHeadingWithDropdown } from './InteractiveDashboardWidgetHeader';
import { useSelectedRowsWithActions } from './useSelectedRowsWithActions';
import { InteractiveDashboardNoData } from './InteractiveDashboardNoData';

export interface InteractiveDashboardBarChartRow {
  name: string;
  value: number;
  valueLabel?: string;
  color?: string;
  tooltip?: {
    left?: string;
    right?: string;
    bottom?: string;
  };
}

export interface RowWithActions {
  row: InteractiveDashboardBarChartRow;
  actions: BigidMenuItemProps[];
}

export interface InteractiveDashboardBarChartWidgetProps {
  title: string;
  rowsWithActions: RowWithActions[] | { [key: string]: RowWithActions[] };
  titleActions?: BigidMenuItemProps[] | { [key: string]: BigidMenuItemProps[] };
}

export const InteractiveDashboardBarChartWidget: FC<InteractiveDashboardBarChartWidgetProps> = ({
  title,
  rowsWithActions,
  titleActions,
}) => {
  const { selectedTab, setSelectedTab, selectedRowsWithActions } =
    useSelectedRowsWithActions<RowWithActions>(rowsWithActions);

  return (
    <InteractiveDashboardCard>
      <InteractiveDashboardHeadingWithDropdown
        rowsWithActions={rowsWithActions}
        setSelectedTab={setSelectedTab}
        selectedTab={selectedTab}
        titleActions={titleActions}
        title={title}
      />
      {selectedRowsWithActions?.length === 0 && <InteractiveDashboardNoData />}
      {selectedRowsWithActions?.length > 0 && (
        <BigidHorizontalBarWithActions rowsWithActions={selectedRowsWithActions} />
      )}
    </InteractiveDashboardCard>
  );
};
