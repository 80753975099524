import React, { Fragment, FC, useEffect, useState } from 'react';
import { Divider, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import {
  BigidColors,
  BigidHorizontalBar,
  BigidHorizontalBarItem,
  BigidHorizontalBarChart,
  BarChartDataItemType,
} from '@bigid-ui/components';
import { AciEntityType } from './AccessIntelligenceInsightsService';
import { capitalize } from 'lodash';

export interface Visibility {
  external?: number;
  internal?: number;
  na?: number;
}

export interface VisibilityChartProps {
  visibilities: Visibility;
  minimized?: boolean;
  aciEntityType: AciEntityType;
}

const useStyles = makeStyles(() => ({
  wrapper: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around',
    maxHeight: 100,
  },
  chartContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  chipsWrapper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    marginLeft: 32,
  },
  divider: {
    background: BigidColors.borderLight,
    width: '100%',
    margin: '6px 0 24px 0',
  },
}));

const useMinimizedStyles = makeStyles(() => ({
  layout: {
    display: 'inline-flex',
    height: '100%',
  },
}));

const visibilityColors = ['#A599F4', '#00CCFF', '#B86FC6'];

export const VisibilityChart: FC<VisibilityChartProps> = ({
  visibilities: { external, internal, na },
  minimized = false,
  aciEntityType,
}) => {
  const classes = useStyles({});
  const minimizedClasses = useMinimizedStyles({});

  const [verticalChartData, setVerticalChartData] = useState<BigidHorizontalBarItem[]>([]);
  const [horizontalChartData, setHorizontalChartData] = useState<BarChartDataItemType[]>([]);
  const [total, setTotal] = useState<number>(0);

  useEffect(() => {
    const total = (external || 0) + (internal || 0) + (na || 0);

    const verticalVisibilities = [
      { type: `External ${capitalize(aciEntityType)}`, value: external, color: '#A599F4' },
      { type: `Internal ${capitalize(aciEntityType)}`, value: internal, color: '#00CCFF' },
      { type: 'Other', value: na, color: '#B86FC6' },
    ];

    const horizontalVisibilities = [
      {
        category: `Visibilities`,
        ...(external && { [`External ${capitalize(aciEntityType)}`]: external }),
        ...(internal && { [`Internal ${capitalize(aciEntityType)}`]: internal }),
        ...(na && { Other: na }),
      },
    ];

    setVerticalChartData(verticalVisibilities);
    setHorizontalChartData(horizontalVisibilities);
    setTotal(total);
  }, [aciEntityType, external, internal, na]);

  return minimized ? (
    <div className={minimizedClasses.layout}>
      <BigidHorizontalBarChart
        data={horizontalChartData}
        barColors={visibilityColors}
        enableClickableLegend={false}
        hideCategoryLabel={false}
        withLegend={false}
        displayFieldsOnHover
      />
    </div>
  ) : (
    <Fragment>
      <Typography variant={'body2'}>Visibilities</Typography>
      <Divider variant="middle" className={classes.divider} />
      <div className={classes.wrapper}>
        <BigidHorizontalBar values={verticalChartData} totalLabel="" />
      </div>
    </Fragment>
  );
};
