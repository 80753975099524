import styled from '@emotion/styled';

type ContentProps = {
  isEmpty: boolean;
};

export const Content = styled.div`
  display: flex;
  justify-content: center;
  height: ${(props: ContentProps) => (props.isEmpty ? 'calc(100% - 4rem)' : 'inherit')};
  margin: 26px;
  margin-top: 6px;
  max-width: ${(props: ContentProps) => (props.isEmpty ? '100%' : '50%')};
  & > form {
    flex: 1;
  }

  @media (max-width: 992px) {
    max-width: 100%;
  }
`;

export const Wrapper = styled.div`
  position: relative;
  box-sizing: border-box;
  margin-left: 1rem;
  height: calc(100% - 2rem);
`;
