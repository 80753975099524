import { ModifyAdditionalAttributes, ModifyAdditionalAttributesProps } from './ModifyAdditionalAttributes';
import { openSystemDialog } from '../../../../../../services/systemDialogService';
import { notificationService } from '../../../../../../services/notificationService';
import { noop } from 'lodash';
import { getFixedT } from '../../../../translations';
import { AdditionalAttributesObjectIdentifier } from '../../../useCurationFieldsConfig';

type ModifyAdditionalAttributesDialogProps = Pick<ModifyAdditionalAttributesProps, 'onSave'> & {
  dataAid: string;
  orderAfterSave?: boolean;
  objectsSelected: AdditionalAttributesObjectIdentifier[];
  isBulkMode?: boolean;
};

export const openModifyAdditionalAttributesDialog = (props: ModifyAdditionalAttributesDialogProps) => {
  const t = getFixedT('CuratedFields.common.AdditionalAttributes');

  try {
    openSystemDialog({
      title: t('ModifyAttributesDialog.dialog.title'),
      onClose: noop,
      content: ModifyAdditionalAttributes,
      contentProps: props,
      maxWidth: 'xs',
    });
  } catch ({ message }) {
    const error = t('ModifyAttributesDialog.errors.generic');
    console.error(`${error}: ${message}`);
    notificationService.error(error);
  }
};
