import React, { FC, useMemo, useState } from 'react';
import { BigidFilterToolbar, BigidToolbar, ColumnInfo } from '@bigid-ui/components';
import { BigidGrid } from '@bigid-ui/grid';
import { getColumnsConfig, getFilterTollbarConfig, getGridInlineActions } from './usersGridWithToolbarConfig';
import { useLocalTranslation } from '../translations';
import { UsersGridEmptyState } from './UsersGridEmptyState';
import { SystemUsersData, SystemUser, DeleteUserCallback, SystemRole } from '../../../types';
import { queryService } from '../../../../../services/queryService';
import { ToolbarWrapper } from '../styles';

interface UsersGridWithToolbarProps {
  roles: SystemRole[];
  onFiltersChange: (query: string) => void;
  systemUsers: SystemUsersData;
  isLoading: boolean;
  onRowClick: (row: SystemUser) => void;
  onClickDelete: DeleteUserCallback;
  onOpenCreateUserDialog: () => void;
  showEmptyFilterState: boolean;
  isManagePermitted: boolean;
  authUser: SystemUser;
}

const PAGE_SIZE = 100;

export const UsersGridWithToolbar: FC<UsersGridWithToolbarProps> = ({
  roles,
  systemUsers,
  onFiltersChange,
  isLoading,
  onRowClick,
  onClickDelete,
  onOpenCreateUserDialog,
  showEmptyFilterState,
  isManagePermitted,
  authUser,
}) => {
  const [hiddenColumnNames, setHiddenColumnNames] = useState([]);
  const [rowsSkip, setSkip] = useState(0);
  const { t } = useLocalTranslation('grid');
  const toolBarConfig = useMemo(
    () =>
      getFilterTollbarConfig(roles, filter => {
        const query = queryService.getGridConfigQuery({
          filter,
          skip: rowsSkip,
          limit: PAGE_SIZE,
          requireTotalCount: true,
        });

        onFiltersChange(query);
      }),
    [roles, rowsSkip, onFiltersChange],
  );

  const { columns } = getColumnsConfig(roles);

  const handleColumnChange = (columns: ColumnInfo[]) => {
    const hiddenColumnNames = columns.reduce((acc, column) => (column.checked ? acc : [...acc, column.name]), []);

    setHiddenColumnNames(hiddenColumnNames);
  };

  const handlePagingChange = (skip: number) => {
    setSkip(skip);
  };

  return (
    <>
      <BigidFilterToolbar {...toolBarConfig} key={toolBarConfig.filters.length} />
      <ToolbarWrapper>
        <BigidToolbar
          entityName={t('entityName')}
          totalRows={systemUsers.totalCount}
          columns={columns.map(({ name, title }) => ({
            name,
            title,
            checked: !hiddenColumnNames.includes(name),
          }))}
          onColumnChange={handleColumnChange}
          shouldShowColumnChooser
        />
      </ToolbarWrapper>
      <BigidGrid
        key={authUser?.name}
        columns={columns}
        hiddenColumnNames={hiddenColumnNames}
        pageSize={PAGE_SIZE}
        rows={systemUsers.data}
        onPagingChanged={handlePagingChange}
        loading={isLoading}
        totalRowsCount={systemUsers.totalCount}
        skip={rowsSkip}
        showSelectionColumn={false}
        showSortingControls={false}
        forceSelectAll={false}
        onRowClick={onRowClick}
        inlineActions={getGridInlineActions({ onClickDelete, isManagePermitted, authUser })}
        noDataContent={
          <UsersGridEmptyState
            onNewUser={onOpenCreateUserDialog}
            shouldShowActions={isManagePermitted}
            showEmptyFilterState={showEmptyFilterState}
          />
        }
      />
    </>
  );
};
