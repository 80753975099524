import React, { useState, FC, useRef, useEffect } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { BigidBody2, BigidButtonIcon, BigidLoader, BigidPopper } from '@bigid-ui/components';
import { updateUserDefaultDashboard, getUserDefaultDashboard } from '../../services/customDashboardService';
import { $state } from '../../services/angularServices';
import { BigidMenuIcon } from '@bigid-ui/icons';
import { DashboardItem, CustomDashboardList } from '@bigid-ui/custom-dashboard';
import { CONFIG } from '../../../config/common';
import { CUSTOM_DASHBOARD_PERMISSIONS } from '@bigid/permissions';
import { isPermitted } from '../../services/userPermissionsService';
import { notificationService } from '../../services/notificationService';

export interface DashboardsMenuProps {
  dashboards: DashboardItem[];
  onMenuItemClick: (dashboardId: string) => void;
}

const useStyles = makeStyles({
  buttonWrapper: {
    padding: '8px 0px 8px 16px',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  menuButton: {
    paddingLeft: '8px',
  },
});

const defaultDashboard: DashboardItem = {
  _id: 'Default',
  name: 'BigID Default Dashboard',
  description: 'Default dashboard for BigID users',
  isFavorite: false,
};

export const CustomDashboardMenu: FC<DashboardsMenuProps> = ({ dashboards, onMenuItemClick }) => {
  const [isScrollLoading, setIsScrollLoading] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [dashboardList, setDashboardList] = useState<DashboardItem[]>([]);
  const [favoriteDashboardId, setFavoriteDashboardId] = useState<string>('');
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const anchorRef = useRef(null);
  const classes = useStyles({});
  const isDashboardManagerPermitted = isPermitted(CUSTOM_DASHBOARD_PERMISSIONS.ACCESS.name);

  useEffect(() => {
    setIsLoading(true);
    dashboards.unshift(defaultDashboard);
    const initFavoriteDashboard = async () => {
      try {
        const favDashboard = (await getUserDefaultDashboard()) || defaultDashboard._id;
        setFavoriteDashboard(dashboards, favDashboard);
      } catch (err) {
        console.error(err);
        notificationService.error(`Can't set favorite dashboard`);
        setDashboardList(dashboards);
      }
      setIsLoading(false);
    };
    initFavoriteDashboard();
  }, [dashboards]);

  const onScroll = () => {
    setIsScrollLoading(false);
  };

  const handleClose = (event: MouseEvent | TouchEvent) => {
    if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
      return;
    }

    setAnchorEl(null);
  };

  const handleIconClick = () => setAnchorEl(anchorEl => (anchorEl ? null : anchorRef.current));

  const setFavoriteDashboard = (dashboards: DashboardItem[], favoriteId: string) => {
    dashboards.forEach(dashboard => (dashboard.isFavorite = dashboard._id == favoriteId));
    setFavoriteDashboardId(favoriteId);
    setDashboardList(dashboards);
  };

  const updateFavoriteDashboard = async (newFavoriteDashboardId: string) => {
    if (favoriteDashboardId != newFavoriteDashboardId) {
      setFavoriteDashboard([...dashboardList], newFavoriteDashboardId);
      await updateUserDefaultDashboard(newFavoriteDashboardId);
      onMenuItemClick(newFavoriteDashboardId);
    }
  };

  const navigateToDashboardsManager = () => $state.go(CONFIG.states.CUSTOM_DASHBOARD);
  const open = Boolean(anchorEl);
  const onItemClicked = (dashboardId: string) => {
    setAnchorEl(null);
    onMenuItemClick(dashboardId);
  };
  return (
    <>
      <span ref={anchorRef} className={classes.menuButton}>
        <BigidButtonIcon icon={BigidMenuIcon} onClick={handleIconClick} dataAid={'custom-dashboard-menu-switcher'} />
      </span>
      <BigidPopper
        title={'Switch Dashboard'}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        onScroll={onScroll}
        isLoading={isLoading}
        isScrollLoading={isScrollLoading}
      >
        <>
          <CustomDashboardList
            dashboards={dashboardList}
            onItemClick={onItemClicked}
            onFavoriteClick={updateFavoriteDashboard}
          >
            {isDashboardManagerPermitted && (
              <BigidBody2
                className={classes.buttonWrapper}
                onClick={navigateToDashboardsManager}
                data-aid="icon dashboard manager link"
              >
                Dashboard Manager
              </BigidBody2>
            )}
          </CustomDashboardList>
          {isLoading && <BigidLoader position="static" zIndex={0} />}
        </>
      </BigidPopper>
    </>
  );
};
