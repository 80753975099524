import React from 'react';
import { isEmpty, size } from 'lodash';
import { ScanProfileFields } from '../../ScanProfileConverter/scanProfileConversionTypes';
import { BigidGridData, EntityTable } from '../ConvertScanProfileTypes';
import { BigidGridColumnTypes } from '@bigid-ui/grid';
import { BigidClassificationsIllustration, BigidCorrelationIllustration, BigidHelpIcon } from '@bigid-ui/icons';
import { NoDataContent } from './NoDataContent';
import { getFixedT } from '../../translations';
import { ScanTypes } from '../../ScanTemplates/scanTemplateTypes';
import { generateDataAid } from '@bigid-ui/utils';

const GLOBAL_CLASSIFIERS_ROW = [{ id: 1, name: 'Global classifiers enabled', isGlobalOrAllEnabled: true }];
const GLOBAL_CORRELATION_SET_ROW = [{ id: 1, name: 'All enabled correlation sets', isGlobalOrAllEnabled: true }];
const GLOBAL_DATA_SOURCES_ROW = [{ id: 1, name: 'All enabled data sources', isGlobalOrAllEnabled: true }];

const t = getFixedT('convertScanProfile.content');

export const getInitialScanProfileOverviewGridData = (scanProfile: ScanProfileFields) => {
  const isAllEnabled = (entityTable: EntityTable) => {
    if (entityTable === EntityTable.dataSourceList && scanProfile.allEnabledDs) {
      return true;
    }

    if (entityTable === EntityTable.idsorList && scanProfile.allEnabledIdSor) {
      return true;
    }

    if (entityTable === EntityTable.classifiers && isEmpty(scanProfile.classifiers)) {
      return true;
    }
  };
  const getRequiredRowsByEntity = (entityTable: EntityTable) => {
    if (
      scanProfile.scanType === ScanTypes.LABELING &&
      (entityTable === EntityTable.idsorList || entityTable === EntityTable.classifiers)
    ) {
      return [];
    }

    const rows = scanProfile[entityTable];
    if (size(rows) > 0 && !isAllEnabled(entityTable)) {
      const updatedRows = rows.map((row, index) => ({
        name: typeof row === 'string' ? row : row.name,
        id: index,
        isGlobalOrAllEnabled: false,
      }));
      return updatedRows;
    }

    switch (entityTable) {
      case EntityTable.classifiers:
        return GLOBAL_CLASSIFIERS_ROW;
      case EntityTable.idsorList:
        return scanProfile.allEnabledIdSor ? GLOBAL_CORRELATION_SET_ROW : [];
      case EntityTable.dataSourceList:
        return scanProfile.allEnabledDs ? GLOBAL_DATA_SOURCES_ROW : [];
    }
  };

  const getRequiredColumnTypeByEntity = (entityTable: EntityTable) => {
    const isGlobalClassifiers = entityTable === EntityTable.classifiers && isEmpty(scanProfile.classifiers);
    const isAllEnabledIdSor = entityTable === EntityTable.idsorList && scanProfile.allEnabledIdSor;
    const isAllEnabledDs = entityTable === EntityTable.dataSourceList && scanProfile.allEnabledDs;
    if (isGlobalClassifiers || isAllEnabledIdSor || isAllEnabledDs) {
      return BigidGridColumnTypes.TEXTIFIED_ICON;
    }

    return BigidGridColumnTypes.TEXT;
  };

  const getRequiredCellValue = (name: string, isGlobalOrAllEnabled: boolean, tooltip?: string): any => {
    if (!isGlobalOrAllEnabled) {
      return name;
    }

    return {
      text: {
        displayValue: name,
      },
      icon: {
        icon: BigidHelpIcon,
        tooltip,
        placement: 'right',
      },
    };
  };

  const getRequiredNoDataText = (entityTable?: EntityTable) => {
    if (scanProfile.scanType === ScanTypes.LABELING) {
      if (entityTable === EntityTable.classifiers) {
        return t('noDataTextLabelingClassifiers');
      }
      if (entityTable === EntityTable.idsorList) {
        return t('noDataTextLabelingCorrelations');
      }
    }

    if (entityTable === EntityTable.classifiers) {
      return t('noDataTextClassifiers');
    }
    if (entityTable === EntityTable.idsorList) {
      return t('noDataTextCorrelationSets');
    }
  };

  const classifierRows = getRequiredRowsByEntity(EntityTable.classifiers);
  const correlationSetsRows = getRequiredRowsByEntity(EntityTable.idsorList);
  const dataSourceRows = getRequiredRowsByEntity(EntityTable.dataSourceList);

  return [
    {
      title: t('classifiers'),
      columns: [
        {
          name: '',
          title: t('classifierName'),
          type: getRequiredColumnTypeByEntity(EntityTable.classifiers),
          getCellValue: ({ name, isGlobalOrAllEnabled }) =>
            getRequiredCellValue(name, isGlobalOrAllEnabled, t('classifiersTooltip')),
        },
      ],
      rows: classifierRows,
      totalRowsCount: classifierRows.length,
      hasDivider: true,
      noDataContent: (
        <NoDataContent
          icon={BigidClassificationsIllustration}
          message={getRequiredNoDataText(EntityTable.classifiers)}
          dataAid={generateDataAid('NoDataContent', ['classifiers'])}
        />
      ),
      showResizingControls: false,
      showSortingControls: false,
    },
    {
      title: t('correlations'),
      columns: [
        {
          name: '',
          title: t('correlationSetName'),
          type: getRequiredColumnTypeByEntity(EntityTable.idsorList),
          getCellValue: ({ name, isGlobalOrAllEnabled }) =>
            getRequiredCellValue(name, isGlobalOrAllEnabled, t('correlationSetsTooltip')),
        },
      ],
      rows: correlationSetsRows,
      totalRowsCount: correlationSetsRows.length,
      hasDivider: true,
      noDataContent: (
        <NoDataContent
          icon={BigidCorrelationIllustration}
          message={getRequiredNoDataText(EntityTable.idsorList)}
          dataAid={generateDataAid('NoDataContent', ['correlations'])}
        />
      ),
      showResizingControls: false,
      showSortingControls: false,
    },
    {
      title: t('dataSources'),
      columns: [
        {
          name: '',
          title: t('dataSourceName'),
          type: getRequiredColumnTypeByEntity(EntityTable.dataSourceList),
          getCellValue: ({ name, isGlobalOrAllEnabled }) =>
            getRequiredCellValue(name, isGlobalOrAllEnabled, t('dataSourcesTooltip')),
        },
      ],
      rows: dataSourceRows,
      totalRowsCount: dataSourceRows.length,
      hasDivider: false,
      noDataContent: (
        <NoDataContent
          icon={BigidCorrelationIllustration}
          message={t('noDataHasNoDS')}
          dataAid={generateDataAid('NoDataContent', ['dataSources'])}
        />
      ),
      showResizingControls: false,
      showSortingControls: false,
    },
  ] as BigidGridData[];
};
