import React, { FC, memo } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { BigidHeading3, BigidBody1 } from '@bigid-ui/components';
import { BigidSearchIllustration } from '@bigid-ui/icons';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '&:not(:last-child)': {
      marginBottom: '8px',
    },
  },
  row: {
    '&:not(:last-child)': {
      marginRight: '8px',
    },
  },
  header: {
    color: theme.vars.tokens.bigid.foregroundPrimary,
  },
}));

const dataAid = 'MetadataSearchQuickNoResultsHandler';

export const MetadataSearchNoResultsHandler: FC = memo(() => {
  const classes = useStyles();

  return (
    <div className={classes.root} data-aid={dataAid}>
      <div className={classes.row}>
        <BigidSearchIllustration size="small" />
      </div>
      <div className={classes.row}>
        <div className={classes.row}>
          <BigidHeading3 classes={{ root: classes.header }} data-aid={`${dataAid}-title`}>
            No results found
          </BigidHeading3>
        </div>
        <div className={classes.row}>
          <BigidBody1 data-aid={`${dataAid}-description`}>
            Try another search, or click the filter icon in the search box and use the predefined filters.
          </BigidBody1>
        </div>
      </div>
    </div>
  );
});

MetadataSearchNoResultsHandler.displayName = 'MetadataSearchNoResultsHandler';
