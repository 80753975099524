import React from 'react';
import { httpService } from '../../../services/httpService';
import { notificationService } from '../../../services/notificationService';
import { showConfirmationDialog } from '../../../services/confirmationDialogService';
import { ActionData } from '@bigid-ui/components';

export const cancelRequests = async (actionData: ActionData, getFilterForDeletion: any) => {
  let shouldExecuteAction = false;
  try {
    const isMoreThenOneRowSelected = actionData?.selectedRows?.length > 1;
    const title = 'Cancel Request' + (isMoreThenOneRowSelected ? 's' : '');
    shouldExecuteAction = await showConfirmationDialog({
      actionName: '',
      actionButtonName: title,
      entityNameSingular: title,
      customDescription: `The selected request${
        isMoreThenOneRowSelected ? 's' : ''
      } will be removed from Data Deletion. Would you like to continue?`,
    });
    if (shouldExecuteAction) {
      const { filter: deletionFilter } = getFilterForDeletion({ actionData });
      await httpService.delete(`data-minimization/objects`, {
        query: { filter: deletionFilter },
      });
      notificationService.success(`Requests are canceled successfully`);
    }
  } catch (e) {
    console.error(e);
    notificationService.error('Could not cancel requests. See logs for more information');
  }
  return { shouldGridReload: shouldExecuteAction, shouldClearSelection: shouldExecuteAction };
};
