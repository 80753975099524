import React, { FC, useState, memo, useEffect } from 'react';
import { TertiaryButton } from '@bigid-ui/components';
import { BigidUploadIcon } from '@bigid-ui/icons';
import { useLocalTranslation } from './translations';
import { UseCatalogDiscoveryResponse } from '../../../useCatalogDiscovery';
import { getDataCatalogRecordsAsCSV } from '../../../../DataCatalog/DataCatalogService';
import { notificationService } from '../../../../../services/notificationService';
import { showConfirmationDialog } from '../../../../../services/confirmationDialogService';
import { analyticsService } from '../../../../../services/analyticsService';
import { BIGID_BI_EVENTS } from '../../../../../config/BigIdBiEvents';
import { useFetchDataCancelable } from '../../../config/useFetchDataCancelable';
import {
  getAggregatedData,
  GetAggregatedDataPayload,
  GetAggregatedDataResponse,
} from '../../../catalogDiscoveryService';
import { AggregationType } from '../../../catalogDiscoveryTypes';
import { formatNumberCompact } from '../../../../../utilities/numericDataConverter';
import { objectsListExportConfirmationThreshold } from '../../../config/toolbar';
import { CatalogDiscoveryWidget } from '../../../config/widgets';

export interface ExportReportProps extends Pick<UseCatalogDiscoveryResponse, 'query' | 'onDataFetchStatusChange'> {
  dataAid?: string;
  isPageInitialised: boolean;
}

export const ExportReport: FC<ExportReportProps> = memo(
  ({ dataAid = 'ExportReport', query, onDataFetchStatusChange, isPageInitialised }) => {
    const { t } = useLocalTranslation();
    const { fetchObjectStatusCancelable } = useFetchDataCancelable();
    const [objectsCount, setObjectsCount] = useState<number>(0);
    const [isFetchingObjectsCount, setIsFetchingObjectsCount] = useState<boolean>(false);

    const handleExportReportButtonClick = async () => {
      try {
        const isConfirmed =
          objectsCount >= objectsListExportConfirmationThreshold
            ? await showConfirmationDialog({
                actionName: t('dialog.actionName'),
                entityNameSingular: t('dialog.entityNameSingular', {
                  objectsCount: formatNumberCompact(objectsCount, 2),
                }),
                actionButtonName: t('dialog.actionButtonName'),
                cancelButtonName: t('dialog.cancelButtonName'),
                customDescription: t('dialog.customDescription'),
              })
            : true;

        if (isConfirmed) {
          analyticsService.trackManualEvent(BIGID_BI_EVENTS.DATAINSIGHTS_ACTIONS_EXPORT_REPORT_CLICK);
          notificationService.info(t('notifications.info'));
          await getDataCatalogRecordsAsCSV(query);
        }
      } catch ({ message }) {
        notificationService.error(t('notifications.error'));
        console.error(`An error has occurred: ${message}`);
      }
    };

    useEffect(() => {
      async function fetchObjectsCount() {
        try {
          setIsFetchingObjectsCount(true);

          const payload: GetAggregatedDataPayload = {
            filter: query,
            aggregations: [
              {
                aggName: AggregationType.OBJECT_STATUS,
                isTotalRequired: true,
              },
            ],
          };

          onDataFetchStatusChange(CatalogDiscoveryWidget.TOOLBAR_OBJECTS_LIST_REPORT, true);

          const { aggregations = [] } = await fetchObjectStatusCancelable(
            getAggregatedData(payload) as Promise<GetAggregatedDataResponse>,
          );

          setObjectsCount(aggregations?.[0]?.aggTotal);
        } catch ({ isCanceled, message }) {
          if (!isCanceled) {
            console.error(`An error has occurred: ${message}`);
            setObjectsCount(0);
          }
        } finally {
          setIsFetchingObjectsCount(false);
          onDataFetchStatusChange(CatalogDiscoveryWidget.TOOLBAR_OBJECTS_LIST_REPORT, false);
        }
      }

      if (isPageInitialised) {
        fetchObjectsCount();
      }
    }, [fetchObjectStatusCancelable, isPageInitialised, onDataFetchStatusChange, query, t]);

    return (
      <TertiaryButton
        size="medium"
        startIcon={<BigidUploadIcon />}
        dataAid={dataAid}
        disabled={isFetchingObjectsCount}
        onClick={handleExportReportButtonClick}
        text={t('text')}
      />
    );
  },
  (prevProps, newProps) =>
    prevProps.query === newProps.query && prevProps.isPageInitialised === newProps.isPageInitialised,
);
