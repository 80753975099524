import React, { useState } from 'react';
import { BigidDropdown, BigidDropdownOption, BigidDropdownProps, BigidDropdownValue } from '@bigid-ui/components';

type BigidDropdownWithActionsProps = {
  defaultValue?: BigidDropdownValue;
  onAction?: (options: BigidDropdownOption[]) => void;
  actions?: BigidDropdownOption[];
} & Partial<BigidDropdownProps>;

type DataSourceConnectionCollaborationTypeFieldProps = BigidDropdownWithActionsProps;

export const DataSourceConnectionCollaborationTypeField = ({
  dataAid = 'DataSourceConnectionCollaborationTypeField',
  options,
  onSelect,
  onAction,
  defaultValue,
  actions = [],
  value: controlledValue,
  ...rest
}: DataSourceConnectionCollaborationTypeFieldProps): JSX.Element => {
  const [value, setValue] = useState<BigidDropdownValue>(defaultValue);

  const handleSelect = async (options: BigidDropdownOption[]) => {
    const actionIds = actions.map(({ id }) => id);
    const shouldTriggerChange = !options.some(({ id }) => actionIds.includes(id));
    shouldTriggerChange ? onSelect?.(options) : onAction?.(options);
    shouldTriggerChange && setValue(options);
  };
  return (
    <fieldset>
      <BigidDropdown
        dataAid={dataAid}
        isMulti={false}
        onSelect={handleSelect}
        options={[...options, ...actions]}
        value={controlledValue ?? value}
        {...rest}
      />
    </fieldset>
  );
};
