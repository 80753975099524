import { BigidGridColumn, BigidGridColumnTypes, BigidGridWithToolbar, BigidGridWithToolbarProps } from '@bigid-ui/grid';
import React, { FC, useMemo } from 'react';
import { httpService } from '../../../services/httpService';
import { notificationService } from '../../../services/notificationService';
import { CONSENT_GOVERNANCE_PERMISSIONS } from '@bigid/permissions';
import { isPermitted } from '../../../services/userPermissionsService';
import { ConsentReportOptions } from './ConsentReport';

interface ConsentItem {
  id: number;
  lawfulness: string;
  agreements: string;
  personalInformationAttributes: string;
  categories: string;
  purpose: string;
}

const getColumns = (id: string): BigidGridColumn<ConsentItem>[] => {
  const columns: BigidGridColumn<ConsentItem>[] = [
    {
      title: 'Lawfulness of processing',
      name: 'lawfulness',
      type: BigidGridColumnTypes.TEXT,
      getCellValue: ({ lawfulness }) => lawfulness,
      width: 220,
    },
    {
      title: 'Agreements',
      name: 'agreements',
      type: BigidGridColumnTypes.TEXT,
      width: 170,
      getCellValue: ({ agreements }) => agreements,
    },
    {
      title: 'Personal information Attributes',
      name: 'personalInformationAttributes',
      type: BigidGridColumnTypes.TEXT,
      getCellValue: ({ personalInformationAttributes }) => personalInformationAttributes,
      width: 250,
    },
    {
      title: 'Categories',
      name: 'categories',
      type: BigidGridColumnTypes.TEXT,
      getCellValue: ({ categories }) => categories,
      width: 170,
    },
    {
      title: 'Purpose of processing',
      name: 'purposeOfProcessing',
      type: BigidGridColumnTypes.TEXT,
      getCellValue: ({ purpose }) => purpose,
      width: 200,
    },
  ];
  if (id === 'purposes types') {
    [columns[0], columns[columns.length - 1]] = [columns[columns.length - 1], columns[0]];
  }
  return columns;
};

export const ConsentReportGrid: FC<ConsentReportOptions> = ({ id }) => {
  const columns = useMemo(() => {
    return getColumns(id);
  }, [id]);

  const gridWithToolbarConfig: BigidGridWithToolbarProps<ConsentItem> = {
    columns,
    entityName: id,
    hideColumnChooser: true,
    toolbarActions: [
      {
        isGlobal: true,
        label: 'Export',
        execute: async () => {
          const pathToDownload =
            id === 'lawfulness'
              ? 'consent/report/file-download/by-lawfulness'
              : 'consent/report/file-download/by-purpose';
          try {
            await httpService.downloadFile(pathToDownload);
          } catch (err) {
            const errMsg = `Error - could not download consent report`;
            notificationService.error(errMsg);
            console.error(`${errMsg} ${err}`);
          } finally {
            return { shouldGridReload: false };
          }
        },
        disable: () => false,
        show: () => isPermitted(CONSENT_GOVERNANCE_PERMISSIONS.EXPORT_REPORT.name),
      },
    ],
    showSortingControls: false,
    fetchData: async () => {
      const data: ConsentItem[] =
        id === 'lawfulness' ? await getConsentReportByLawfulness() : await getConsentReportByPurpose();
      return {
        data,
        totalCount: data.length,
      };
    },
  };
  return <BigidGridWithToolbar {...gridWithToolbarConfig} key={id} />;
};

const getConsentReportByPurpose = () => {
  return httpService.fetch<{ data: ConsentItem[] }>(`consent/report/by-purpose`).then(({ data: { data } }) => data);
};

const getConsentReportByLawfulness = () => {
  return httpService.fetch<{ data: ConsentItem[] }>(`consent/report/by-lawfulness`).then(({ data: { data } }) => data);
};
