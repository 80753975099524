import { IconComponentProps } from '@bigid-ui/icons';
import { ComponentType, ReactText } from 'react';
import {
  MetadataSearchFieldFilter,
  MetadataSearchFilterConfig,
} from './MetadataSearchFilters/MetadataSearchFiltersTypes';

export type MetadataSearchQuery = string;

export enum MetadataSearchEntityType {
  TABLE = 'rdb',
  FILE = 'file',
  DATA_SOURCE = 'datasource',
  POLICY = 'policy',
  OTHER_CATALOG = 'othercatalog',
  CLASSIFICATION_FINDINGS = 'classification_findings',
  // COLUMN = 'column', //TODO: next phases
  // ATTRIBUTE = 'attribute', //TODO: next phases
  // TAG = 'tag', //TODO: next phases
}

export type MetadataSearchResultEntityAsset = {
  name: string;
  value: string | number | boolean;
};

export enum MetadataSearchResultEntityFieldName {
  ID = 'id',
  ENTITY_ID = 'entityId',
  NAME = 'name',
  CONTAINER = 'container',
  OWNER = 'owner',
  UDPATE_DATE = 'updateDate',
  TYPE = 'type',
  TAGS = 'tags',
  ATTRIBUTES = 'attributes',
}

export type MetadataSearchResultEntityFieldValue = ReactText | ReactText[];

export type MetadataSearchResultEntityField = {
  name: MetadataSearchResultEntityFieldName;
  value: MetadataSearchResultEntityFieldValue;
  highlightedValue?: string;
  originalField: string;
};

export interface MetadataSearchResultEntity {
  id: ReactText;
  type: MetadataSearchEntityType;
  primary: MetadataSearchResultEntityField[];
  assets?: MetadataSearchResultEntityAsset[];
  templateUrl?: string;
}

export enum MetadataSearchIndexingStatus {
  UPDATED = 'UPDATED',
  INDEXING = 'INDEXING',
  FAILED = 'FAILED',
  CANCELLED = 'CANCELLED',
}

export type MetadataSearchStatus = {
  statusId: MetadataSearchIndexingStatus;
  message?: string;
};

export interface MetadataSearchResultsPerType {
  type: MetadataSearchEntityType;
  status?: MetadataSearchStatus;
  count: number;
  results: MetadataSearchResultEntity[];
}

export type MetadataSearchUserPreferences = {
  isOnboardingAcknowledged?: boolean;
  recentObjects: MetadataSearchResultEntity[];
};

export type MetadataSearchQuantity = 'singular' | 'plural';

export type MetadataSearchEntityIconSizeGrid = 'small' | 'medium' | 'large';

export type MetadataSearchEntityPreviewRouteConfig = {
  route?: string;
  url?: string;
  params?: Record<string, ReactText>;
};

export type MetadataSearchTypeIndexingStatus = {
  status: MetadataSearchIndexingStatus;
  entityName: string;
  startedIndexingAt: string;
  finishedIndexingAt: string;
  nextIndexingAt: string;
};

export type MetadataSearchTransitionPayload = {
  text: MetadataSearchQuery;
  filter: MetadataSearchFieldFilter[];
};

export enum MetadataSearchEvents {
  ONBOARDING_INITIATE = 'MetadataSearch.Onboarding.Initiate',
  SEARCH_RESULT_ENTITY_CLICK = 'MetadataSearch.ResultEntity.Click',
  FIELD_FILTER_DELETE = 'MetadataSearch.Filter.Delete',
  FIELD_FILTER_UPDATE = 'MetadataSearch.Filter.Update',
  FIELD_FILTER_DELETE_ALL = 'MetadataSearch.Filter.DeleteAll',
}

export type MetadataSearchError = {
  title?: string;
  description?: string;
};

export enum MetadataSearchOnboardingHint {
  FILTER = 'filter',
}

export type MetadataSearchEntityTypeConfigDataSource<DataSourceProps = object> = {
  type: string;
  properties: DataSourceProps;
  detailsUrlTemplate: string;
};

export type MetadataSearchEntityTypeConfig<DataSourceProps = object> = {
  appId?: string;
  entityTypeId: string;
  friendlyName: string;
  friendlyNamePlural: string;
  dataSource: MetadataSearchEntityTypeConfigDataSource<DataSourceProps>;
  permissions: string[];
  primaryFields: Array<Pick<MetadataSearchResultEntityField, 'name' | 'originalField'>>;
  filterFields: MetadataSearchFilterConfig[];
  enabled: boolean;
};

export type MetadataSearchIcon = {
  icon: ComponentType<IconComponentProps>;
  name: string;
};

export type MetadataSearchSupportedEntityTypes = Record<MetadataSearchEntityType, boolean>;
export type MetadataSearchEntityTypeIcons = Record<MetadataSearchEntityType, MetadataSearchIcon>;
