import React, { FC, useEffect } from 'react';
import { pageHeaderService } from '../../../common/services/pageHeaderService';
import { DataSourcesInit } from './DataSourcesInit/DataSourcesInit';
import { DataSourcesInitSmallId } from './DataSourcesInit/DataSourcesInitSmallId';
import { SelectDataSourceType } from './SelectDataSourceType/SelectDataSourceType';
import { ImportDataSources } from './ImportDataSources/ImportDataSources';
import { sessionStorageService } from '../../../common/services/sessionStorageService';
import { AutoDiscoveryWizard } from '../AutoDiscoveryWizard/AutoDiscoveryWizard';
import { History } from '../../components/BigidHeader/HeaderService';
import { CONFIG } from '../../../config/common';
import { NEW_CONNECTION_EXCLUDED_DATA_SOURCES } from './constants/newConnectionFlowConstants';
import { $state } from '../../services/angularServices';
import { getApplicationPreference } from '../../services/appPreferencesService';

const INIT_PATH = 'init';

interface DataSourcesInitUrlParams {
  path: 'init' | 'selectDataType' | 'importDataSources' | 'smallIdInit' | 'autoDiscovery';
}

interface DataSourcesInitProps {
  $stateParams: DataSourcesInitUrlParams;
}

interface DataSourceViews {
  path: string;
  component: React.ComponentType<any>;
  showBackButton: boolean;
  pageTitle?: string;
}

export const DATA_SOURCES_INIT_VIEWS: Record<string, DataSourceViews> = {
  init: {
    path: INIT_PATH,
    component: DataSourcesInit,
    showBackButton: false,
  },
  selectDataType: {
    path: 'selectDataType',
    component: () => (
      <SelectDataSourceType
        onSelect={({ name, displayName, nameInDocs, ...rest }, { onSelectHandler }) => {
          const dataSourceType = name.toLowerCase();
          const supportsNewConnectionFlow = !NEW_CONNECTION_EXCLUDED_DATA_SOURCES.includes(dataSourceType as any);
          const isNewConnectivityEnabled = getApplicationPreference('CONNECTIVITY_EXPERIENCE_ENABLED');
          const isNewConnectionFlow = isNewConnectivityEnabled && supportsNewConnectionFlow;

          isNewConnectionFlow
            ? $state.go(CONFIG.states.DATA_SOURCE_CONNECTIONS, {
                shouldDisplayNewConnectionModal: true,
                sourceType: dataSourceType,
                dsLabel: displayName,
                nameInDocs: nameInDocs,
              })
            : onSelectHandler({ name, ...rest });
        }}
      />
    ),
    showBackButton: true,
  },
  importDataSources: {
    path: 'importDataSources',
    component: ImportDataSources,
    showBackButton: true,
  },
  smallIdInit: {
    path: 'smallIdInit',
    component: DataSourcesInitSmallId,
    showBackButton: false,
    pageTitle: 'Welcome to Small ID',
  },
  autoDiscovery: {
    path: 'autoDiscovery',
    component: AutoDiscoveryWizard,
    showBackButton: false,
    pageTitle: 'Discover Data Sources',
  },
};

export const DataSources: FC<DataSourcesInitProps> = ({ $stateParams }) => {
  const currentView = DATA_SOURCES_INIT_VIEWS[$stateParams.path] || DATA_SOURCES_INIT_VIEWS.init;

  useEffect(() => {
    setTimeout(() => {
      const isInit = currentView.path === INIT_PATH;
      const history: History[] | null = sessionStorageService.get('history');
      const goToDashboard = history && history[history?.length - 1 || 0]?.from?.state === CONFIG.states.DASHBOARD;
      pageHeaderService.setTitle({
        ...(!isInit && { pageTitle: currentView.pageTitle ? currentView.pageTitle : 'Data Sources' }),
        showBackButton: currentView.showBackButton,
        defaultFrom: goToDashboard
          ? {
              state: CONFIG.states.DASHBOARD,
              params: {},
            }
          : {
              state: CONFIG.states.DATA_SOURCE_ROUTER,
              params: {},
            },
      });
    }, 50);
  }, [$stateParams.path, currentView.pageTitle, currentView.path, currentView.showBackButton]);

  const Component = currentView?.component;

  return Component && <Component />;
};
