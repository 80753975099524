import { ExtraFieldItemData } from '@bigid-ui/components';
import { CredentialField } from '../Credentials/credentialsFormUtils';
import { CredentialTypes } from '../Credentials/types';

export interface ForwardFormRef {
  formElement: HTMLDivElement;
}
export interface CredentialProvidersModel {
  id: string;
  _id: string;
  name?: string;
  type: CredentialTypes;
  appId: string;
  url: string;
  certPassword: string;
  cert: string;
  trustSelfSigned: boolean;
  useCustomConnectivityURI?: boolean;
}

export type CredentialProviderRow = Omit<CredentialProviderResponse, 'updated_at' | 'created_at'> & {
  id: string;
  isPending?: boolean;
};

export interface CredentialProviderResponse {
  name: string;
  type: ProviderType;
  _id?: string;
  appId?: string;
  url?: string;
  certPassword?: string;
  cert?: File & { originalname: string };
  trustSelfSigned?: boolean;
  useCustomConnectivityURI?: boolean;
  auth_method?: HashiCorpAuthenticationType;
  bind_secret_id?: boolean;
  secret_id?: string;
  role_id?: string;
  client_cert?: string;
  client_cert_key?: string;
  certificate_role_name?: string;
  header_fields?: ExtraFieldItemData[];
  updated_at?: string;
  created_at?: string;
}

export enum ProviderType {
  HASHICORP = 'HashiCorp',
  CYBERARK = 'CyberArk',
  THYCOTIC = 'Thycotic',
}

export interface CredentialsTypesMetadata {
  type: ProviderType;
  displayName: string;
  fields: CredentialField[];
}

export enum ConversionURIContentType {
  ENCODED = 'ENCODED',
  DECODED = 'DECODED',
}

export enum HashiCorpAuthenticationType {
  APP_ROLE = 'appRole',
  TLS = 'tls',
}
