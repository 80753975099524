import { BigidColors } from '@bigid-ui/components';
import { BigidLineageDiagramLink, BigidLineageDiagramNode, BigidLineageDiagramNodeType } from '@bigid-ui/visualisation';
import { getAttributesForCorrelationSet } from '../../attributesEnrichmentService';
import {
  AttributesEnrichmentDiagramNode,
  AttributesEnrichmentFlowItem,
  DiagramData,
  SystemAttribute,
  SystemAttributeDiagramPort,
} from '../../Types';
import { attributeNameToPortId, getLinkId, sourceToNodeId } from './idsMapper';
import { isReadOnly } from './permissions';

export const getLinks = (flows: AttributesEnrichmentFlowItem[]): BigidLineageDiagramLink[] => {
  const isRemovable = !isReadOnly();

  return flows.flatMap(({ fieldsMapping, name, source }) => {
    if (!fieldsMapping.length) {
      return [];
    }
    const toNodeId = sourceToNodeId(source);
    return fieldsMapping.map(({ origin, from: fromAttributeName, to: toAttributeName }) => {
      const originName = origin === 'input' ? name : origin;
      const fromNodeId = sourceToNodeId(originName);
      const toAttributeId = attributeNameToPortId(source, toAttributeName);
      const fromAttributeId = attributeNameToPortId(originName, fromAttributeName);
      const link: BigidLineageDiagramLink = {
        id: getLinkId(source, fromAttributeName, toAttributeName),
        from: {
          id: fromAttributeId,
          nodeId: fromNodeId,
          displayName: fromAttributeName,
        },
        to: {
          id: toAttributeId,
          nodeId: toNodeId,
          displayName: toAttributeName,
        },
        isRemovable,
        type: 'Correlation Set',
        color: BigidColors.purple[500],
        hoverColor: BigidColors.purple[600],
      };
      return link;
    });
  });
};

export const getAllSourcesFromFlows = (flows: AttributesEnrichmentFlowItem[], sourcesToFilter?: string[]) => {
  const allEntitySourcesNames = new Set(
    flows.flatMap(flow => [flow.source, ...flow.fieldsMapping.map(field => field.origin)]),
  );
  sourcesToFilter.forEach(sourceName => allEntitySourcesNames.delete(sourceName));
  return allEntitySourcesNames;
};

export const getSourceToAttributesMap = async (flows: AttributesEnrichmentFlowItem[]) => {
  const allEsNames = getAllSourcesFromFlows(flows, ['input']);
  const allEsSystemAttributes = await getAttributesForCorrelationSet([...allEsNames]);

  const esToAttrMap = new Map<string, SystemAttribute[]>();
  allEsSystemAttributes.forEach(attr => {
    // for some reason there is Array of entity sources, so we fill per one
    attr.idSource?.forEach(esName => {
      if (!esToAttrMap.has(esName)) {
        esToAttrMap.set(esName, []);
      }
      esToAttrMap.get(esName).push(attr);
    });
  });
  return esToAttrMap;
};

export const systemAttributesToDiagramPorts = (
  source: string,
  systemAttributes: SystemAttribute[],
): SystemAttributeDiagramPort[] => {
  const nodeId = sourceToNodeId(source);
  return systemAttributes
    .filter(({ attributeOriginalName }) => !attributeOriginalName?.match(/^classifier\./))
    .map(systemAttribute => {
      const port = createPortFromAttribute({ nodeId, source, systemAttribute });
      return port;
    });
};

export const createPortFromAttribute = (params: {
  nodeId: string;
  source: string;
  attributeOriginalName?: string;
  isLinked?: boolean;
  systemAttribute?: SystemAttribute;
}): SystemAttributeDiagramPort => {
  const isAbleToConnectManually = !isReadOnly();
  const { nodeId, source, systemAttribute, attributeOriginalName: _attributeOriginalName, isLinked } = params;
  const attributeOriginalName = systemAttribute ? systemAttribute.attributeOriginalName : _attributeOriginalName;
  const displayName = systemAttribute ? systemAttribute.attributeName : attributeOriginalName;
  const attributeId = attributeNameToPortId(source, attributeOriginalName);
  return {
    nodeId,
    id: attributeId,
    name: attributeOriginalName,
    displayName,
    isLinked,
    isVisibleByDefault: isLinked,
    isAbleToConnectManually,
    origData: { systemAttribute },
  };
};

export const getNodes = async (flows: AttributesEnrichmentFlowItem[]): Promise<AttributesEnrichmentDiagramNode[]> => {
  const esToAttributesMap = await getSourceToAttributesMap(flows);

  const nodes: AttributesEnrichmentDiagramNode[] = flows.flatMap(flowItem => {
    const { source } = flowItem;
    const nodeName = source;
    const nodeId = sourceToNodeId(source);
    const ports = systemAttributesToDiagramPorts(source, esToAttributesMap.get(source) || []);
    const node: AttributesEnrichmentDiagramNode = {
      id: nodeId,
      name: nodeName,
      displayName: nodeName,
      type: BigidLineageDiagramNodeType.regular,
      isStatic: true,
      ports,
      origData: {
        flowItem,
      },
    };
    return node;
  });

  const rootItems = flows.filter(({ fieldsMapping }) => fieldsMapping.every(field => field.origin === 'input'));
  const rootName = rootItems.length ? rootItems[0]?.name : 'unknown input';
  const rootNode: AttributesEnrichmentDiagramNode = {
    id: rootName,
    name: rootName,
    displayName: 'Input',
    type: BigidLineageDiagramNodeType.regular,
    isStatic: true,
    ports: [],
    origData: {
      isRoot: true,
      flowItem: rootItems?.[0],
    },
  };

  nodes.unshift(rootNode);

  function updatePort(source: string, attributeOriginalName: string) {
    const nodeId = sourceToNodeId(source);
    const attributeId = attributeNameToPortId(source, attributeOriginalName);
    const node = nodes.find(node => node.id === nodeId);
    let port = node.ports.find(port => port.id === attributeId);
    if (!port) {
      port = createPortFromAttribute({ source, nodeId, attributeOriginalName });
      node.ports.push(port);
    }
    port.isLinked = true;
    port.isVisibleByDefault = true;
  }

  flows.forEach(({ source, fieldsMapping }) => {
    fieldsMapping.forEach(({ to: toAttributeName, from: fromAttributeName, origin }) => {
      const fromSource = origin === 'input' ? rootName : origin;
      updatePort(fromSource, fromAttributeName);

      const toSource = source;
      updatePort(toSource, toAttributeName);
    });
  });

  return nodes;
};

export const flowsToDiagram = async (flows: AttributesEnrichmentFlowItem[]): Promise<DiagramData> => {
  const nodes: BigidLineageDiagramNode[] = await getNodes(flows);
  const links: BigidLineageDiagramLink[] = getLinks(flows);

  return {
    nodes,
    links,
  };
};

export const getNewFlowToDiagram = async (flowName: string): Promise<DiagramData> => {
  const nodeId = sourceToNodeId(flowName);
  const rootNode: AttributesEnrichmentDiagramNode = {
    id: flowName,
    name: flowName,
    displayName: 'Input',
    type: BigidLineageDiagramNodeType.regular,
    isStatic: true,
    ports: [
      createPortFromAttribute({
        source: flowName,
        attributeOriginalName: flowName,
        nodeId,
        isLinked: false,
      }),
    ],
    origData: {
      isRoot: true,
    },
  };

  return {
    nodes: [rootNode],
    links: [],
  };
};
