import React, { useEffect, useState } from 'react';
import { pageHeaderService } from '../../../../common/services/pageHeaderService';
import { $state, $stateParams } from '../../../services/angularServices';
import { CONFIG } from '../../../../config/common';
import styled from '@emotion/styled';
import {
  BigidBody1,
  BigidBreadcrumbs,
  BigidHeading3,
  BigidSearch,
  BigidStatusBadge,
  BigidStatusBadgeSize,
  BigidStatusBadgeType,
  BigidLoader,
} from '@bigid-ui/components';
import { fetchFrameworksControls, FrameworkProps, FrameworkUpdatedProps } from '../FrameworksServices';
import { filterFrameworkControls, formatFrameworkControls, frameworkIconGenerator } from '../utils';
import { AccordeonControls } from './AccordeonControls';
import { notificationService } from '../../../services/notificationService';
import { useLocalTranslation } from '../translations';

const Main = styled.div({
  display: 'flex',
  flexDirection: 'column',
  columnGap: '24px',
  rowGap: '24px',
});

const InfoSection = styled.div({
  display: 'flex',
  columnGap: '8px',
  alignItems: 'center',
});

const ListSection = styled.div(({ theme }) => ({
  backgroundColor: 'white',
  padding: '40px 36px',
  border: `1px solid ${theme.palette.bigid.gray200}`,
  display: 'flex',
  flexDirection: 'column',
  columnGap: '8px',
  rowGap: '8px',
}));

const SerchSection = styled.div(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  border: `1px solid ${theme.palette.bigid.gray200}`,
  height: '50px',
  padding: '0 12px',
  alignItems: 'center',
}));

const SearchWrapper = styled.div({
  width: '200px',
});

const AccordeonWrapper = styled.div({
  display: 'flex',
  flexDirection: 'column',
  columnGap: '8px',
  rowGap: '8px',
});

const NoDataWrapper = styled.div({
  height: '128px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
});

export const FrameworkControls = () => {
  const [currentName, setCurrentName] = useState<string>('');
  const [currentState, setCurrentState] = useState<boolean>(false);
  const [controlsList, setControlsList] = useState<FrameworkUpdatedProps>({ data: [], amountOfControls: 0 });
  const [filteredList, setFilteredList] = useState<FrameworkUpdatedProps>({ data: [], amountOfControls: 0 });
  const [isLoasding, setIsLoading] = useState<boolean>(false);

  const { t } = useLocalTranslation();

  const handleSearch = (value: string) => {
    const filteredResult = filterFrameworkControls(controlsList.data, value);
    setFilteredList(filteredResult);
  };

  const Icon = frameworkIconGenerator(currentName);

  useEffect(() => {
    const { name, state } = $stateParams;
    setCurrentName(name);
    setCurrentState(state);
    const breadcrumbs = [
      { label: t('Labels.breadcrumbs.frameworks'), onClick: () => $state.go(CONFIG.states.FRAMEWORKS) },
      { label: name },
    ];

    pageHeaderService.setTitle({
      pageTitle: '',
      titleHelperComponent: (
        <>
          <BigidBreadcrumbs breadcrumbs={breadcrumbs} />
        </>
      ),
    });

    const controlsRequest = async () => {
      try {
        setIsLoading(true);
        const frameworkControls = await fetchFrameworksControls(name);
        const data = formatFrameworkControls(frameworkControls);
        setControlsList(data);
        setFilteredList(data);
      } catch {
        notificationService.error(t('Error.requests.fetchControls', { frameworkName: name }));
      } finally {
        setIsLoading(false);
      }
    };
    controlsRequest();
  }, []);

  return (
    <Main>
      <InfoSection>
        <Icon width={32} height={32} />
        <BigidHeading3>{currentName}</BigidHeading3>
        {currentState ? (
          <BigidStatusBadge
            label={t('Labels.badges.enabled')}
            size={BigidStatusBadgeSize.SMALL}
            type={BigidStatusBadgeType.SUCCESS}
          />
        ) : (
          <BigidStatusBadge
            label={t('Labels.badges.disabled')}
            size={BigidStatusBadgeSize.SMALL}
            type={BigidStatusBadgeType.DISABLED}
          />
        )}
      </InfoSection>
      <ListSection>
        <SerchSection>
          <BigidBody1>{`${filteredList.amountOfControls} controls`}</BigidBody1>
          <SearchWrapper>
            <BigidSearch onSubmit={handleSearch} placeholder={t('Labels.search.placeholder')} />
          </SearchWrapper>
        </SerchSection>
        {isLoasding ? (
          <BigidLoader />
        ) : (
          <AccordeonWrapper>
            {filteredList.data.length > 0 ? (
              filteredList.data.map((mainControl: FrameworkProps) => (
                <AccordeonControls key={mainControl._id} {...mainControl} />
              ))
            ) : (
              <NoDataWrapper>
                <BigidBody1>No Data</BigidBody1>
              </NoDataWrapper>
            )}
          </AccordeonWrapper>
        )}
      </ListSection>
    </Main>
  );
};
