import { useState, useCallback, useEffect } from 'react';
import {
  DataCatalogAsyncOperationStatus,
  DataCatalogAsyncOperation,
  DataCatalogAsyncOperationType,
  DataCatalogAsyncOperationNameMapping,
  DataCatalogAsyncOperationRoutingKey,
  DataCatalogAsyncOperationListenerParams,
  DataCatalogAsyncOperationListenerResponse,
} from '../DataCatalogAsyncOpsTypes';
import { ColumnBusinessAttributePopulatedBy } from '../../DataCatalogColumns/DataCatalogColumnsService';
import { subscribeToRepeatedSSEEventById, SSEDataMessage } from '../../../../services/sseService';
import { AsyncOperation } from '../../../../components/AsyncOperationProcessingWidget/AsyncOperationProcessingWidget';

type ClusterBusinessAttrAssignmentSsePayload = {
  set: {
    business_attribute_glossary_id: string;
    business_attribute_populated_by: ColumnBusinessAttributePopulatedBy;
  };
};

type ClusterBusinessAttrAssignmentQueryObject = {
  clustering_cluster_id: string;
};

type ClusterBusinessAttrAssignmentSseData = {
  friendly_name: string;
};

export type ClusterBusinessAttrAssignmentPayload = {
  clusterId: string;
  friendlyName: string;
};

type ClusterBusinessAttrAssignmentSseResults = DataCatalogAsyncOperation<
  ClusterBusinessAttrAssignmentSsePayload,
  ClusterBusinessAttrAssignmentQueryObject,
  ClusterBusinessAttrAssignmentSseData
>;

export type ClusterBusinessAttrAssignmentOperationsSorted = Record<
  DataCatalogAsyncOperationStatus,
  ClusterBusinessAttrAssignmentSseResults[]
>;

export const clusterBusinessAttrAssignmentNameMapping: DataCatalogAsyncOperationNameMapping = {
  [DataCatalogAsyncOperationType.UPDATE_BY_QUERY]: 'Assigning',
};

export type UseClusterBusinessAttrAssignmentParams = DataCatalogAsyncOperationListenerParams;
export type UseClusterBusinessAttrAssignmentResponse = DataCatalogAsyncOperationListenerResponse<AsyncOperation>;

export const useCusterBusinessAttrAssignment = ({
  onOperationRun,
}: DataCatalogAsyncOperationListenerParams): UseClusterBusinessAttrAssignmentResponse => {
  const [operations, setOperations] = useState<AsyncOperation[]>([]);
  const [isAssignmentInProcess, setIsAssignmentInProcess] = useState<boolean>(false);

  useEffect(() => {
    onOperationRun(DataCatalogAsyncOperationRoutingKey.CLUSTER_BULK_ASSIGNMENT, isAssignmentInProcess);
  }, [isAssignmentInProcess, onOperationRun]);

  const handleColumnBusinessAttrAssignmentBroadcastEventReceived = useCallback(
    ({ results = [] }: SSEDataMessage<ClusterBusinessAttrAssignmentSseResults>) => {
      const operationsSortedSkeleton = Object.keys(DataCatalogAsyncOperationStatus).reduce(
        (operationsSortedBase, status) => ({ ...operationsSortedBase, [status]: [] }),
        {},
      ) as ClusterBusinessAttrAssignmentOperationsSorted;
      const operationsSorted = results.reduce((operationsSortedAggr, operation) => {
        const { status } = operation;
        return { ...operationsSortedAggr, [status]: [...(operationsSortedAggr[status] || []), operation] };
      }, operationsSortedSkeleton);

      const operationsUpdated = [
        ...operationsSorted.ERROR,
        ...operationsSorted.COMPLETED,
        ...operationsSorted.RUNNING,
      ].reduce((operationsAggr, operation) => {
        const { name, payload, status, find, percentage, sseData } = operation;

        const clusterId = find?.clustering_cluster_id;
        const attributeName = sseData?.friendly_name;

        if (payload?.set?.business_attribute_glossary_id && clusterId) {
          const { business_attribute_populated_by: populatedBy } = payload.set;

          if (populatedBy === ColumnBusinessAttributePopulatedBy.USER) {
            if (attributeName) {
              return [
                ...operationsAggr,
                {
                  status,
                  percentage:
                    status === DataCatalogAsyncOperationStatus.RUNNING && typeof percentage === 'number'
                      ? Math.round(percentage)
                      : undefined,
                  name: clusterBusinessAttrAssignmentNameMapping[name],
                  description: `to "${clusterId}"`,
                  entityName: attributeName,
                },
              ];
            } else {
              return [
                ...operationsAggr,
                {
                  status,
                  percentage:
                    status === DataCatalogAsyncOperationStatus.RUNNING && typeof percentage === 'number'
                      ? Math.round(percentage)
                      : undefined,
                  name: 'Unassigning',
                  description: `from "${clusterId}"`,
                  entityName: 'all attributes',
                },
              ];
            }
          } else if (populatedBy === ColumnBusinessAttributePopulatedBy.AUTO) {
            //TBD:
          }
        } else {
          return operationsAggr;
        }
      }, []);

      setOperations(operationsUpdated);
      setIsAssignmentInProcess(!!results.length);
    },
    [],
  );

  useEffect(() => {
    const unsubscribe = subscribeToRepeatedSSEEventById(
      DataCatalogAsyncOperationRoutingKey.CLUSTER_BULK_ASSIGNMENT,
      handleColumnBusinessAttrAssignmentBroadcastEventReceived,
    );

    return () => {
      unsubscribe();
    };
  }, [handleColumnBusinessAttrAssignmentBroadcastEventReceived]);

  return { operations };
};
