import React, { FC } from 'react';
import { sortBy, difference, startCase, isEmpty, pickBy } from 'lodash';
import { ModulePermissions, PermissionsCollapsable } from './PermissionsCollapsable';
import { MODULES_WITH_CATEGORY_SEARCH, PermissionsHierarchy } from '../RolePermissions';
import makeStyles from '@mui/styles/makeStyles';
import { SystemRole } from '../../../types';

interface PermissionsPickerProps {
  selectedPermissions: string[];
  setSelectedPermissions: (granularPermissions: string[]) => void;
  updateRoleInfo: (roleProps: Partial<SystemRole>) => void;
  isSystemRole: boolean;
  roleName: string;
  modulesToDisplay: string[];
  permissionsHierarchy: PermissionsHierarchy;
  privacyPortalDepartments?: string;
  privacyPortalPermissions?: string;
}

const useStyles = makeStyles({
  root: { padding: '0 25px', marginBottom: 10 },
});

export const PermissionsPicker: FC<PermissionsPickerProps> = ({
  selectedPermissions,
  setSelectedPermissions,
  updateRoleInfo,
  isSystemRole,
  roleName,
  modulesToDisplay,
  permissionsHierarchy,
  privacyPortalDepartments,
  privacyPortalPermissions,
}) => {
  const classes = useStyles({});

  const onCheckPermission =
    (permissions: string[]) => (event: React.ChangeEvent<HTMLInputElement>, isChecked: boolean) => {
      if (isChecked) {
        setSelectedPermissions([...selectedPermissions, ...permissions]);
      } else {
        setSelectedPermissions(difference(selectedPermissions, permissions));
      }
    };

  const innerModulesSearchPermissions: PermissionsHierarchy = {};
  const isPermissionSelected = (permission: string) => selectedPermissions.includes(permission);
  const modules = sortBy(
    Object.keys(permissionsHierarchy).filter(module => modulesToDisplay.includes(startCase(module))),
  );

  const filterCategories = (categoryName: string) =>
    pickBy(permissionsHierarchy[categoryName], (value, key) => modulesToDisplay.includes(startCase(key)));

  const insertCategoriesToModules = (categoryName: string, filteredCategory: Partial<ModulePermissions>) => {
    if (!isEmpty(filteredCategory) && !modules.includes(categoryName)) {
      innerModulesSearchPermissions[categoryName] = { modulePermissions: [], ...filteredCategory };
      modules.push(categoryName);
    }
  };

  const addFilteredCategoriesToModules = () => {
    MODULES_WITH_CATEGORY_SEARCH.forEach((moduleName: string) => {
      if (!permissionsHierarchy[moduleName]) return;
      insertCategoriesToModules(moduleName, filterCategories(moduleName));
    });
  };

  addFilteredCategoriesToModules();

  return (
    <div className={classes.root} data-aid="permissions-picker">
      {modules.map(module => {
        const modulePermissions = innerModulesSearchPermissions[module] || permissionsHierarchy[module];
        return (
          <PermissionsCollapsable
            key={module}
            module={module}
            permissions={modulePermissions}
            onCheckPermission={onCheckPermission}
            isPermissionSelected={isPermissionSelected}
            isSystemRole={isSystemRole}
            roleName={roleName}
            hideCheckTool={!!innerModulesSearchPermissions[module]}
            privacyPortalDepartments={privacyPortalDepartments}
            privacyPortalPermissions={privacyPortalPermissions}
            updateRoleInfo={updateRoleInfo}
          />
        );
      })}
    </div>
  );
};
