import { BigidBody1 } from '@bigid-ui/components';
import { styled } from '@mui/material';
import React, { ReactNode } from 'react';

const Root = styled('div')<Pick<GridRowComponentProps, 'isSelected'>>`
  display: flex;
  gap: 24px;
  justify-content: space-between;
  height: 100%;
  align-items: center;
  padding: 12px;
  background-color: ${({ isSelected, theme }) =>
    isSelected ? theme.vars.tokens.bigid.backgroundHoverSubtle : 'inherit'};
`;

type GridRowComponentProps = {
  name: ReactNode;
  count: number;
  isSelected: boolean;
};
export const GridRowComponent = ({ name, count, isSelected }: GridRowComponentProps) => {
  return (
    <Root isSelected={isSelected}>
      <BigidBody1 overflow="hidden" textOverflow="ellipsis">
        {name}
      </BigidBody1>
      <BigidBody1>{count}</BigidBody1>
    </Root>
  );
};
