import { findIndex } from 'lodash';

export interface CountryDefinition {
  name: string;
  /** country alpha 2 code in UPPERCASE */
  'alpha-2': string;
  /** country alpha 3 code in UPPERCASE */
  'alpha-3': string;
  points: Array<{
    latitude: number;
    longitude: number;
  }>;
  isState?: boolean;
  displayName?: string;
}

export const countries: Readonly<CountryDefinition>[] = [
  {
    name: 'Afghanistan',
    'alpha-2': 'AF',
    'alpha-3': 'AFG',
    points: [
      {
        latitude: 33.93911,
        longitude: 67.709953,
      },
      {
        latitude: 33.93911,
        longitude: 67.709953,
      },
      {
        latitude: 33.93911,
        longitude: 67.709953,
      },
    ],
  },
  {
    name: 'Albania',
    'alpha-2': 'AL',
    'alpha-3': 'ALB',
    points: [
      {
        latitude: 41.153332,
        longitude: 20.168331,
      },
      {
        latitude: 41.153332,
        longitude: 20.168331,
      },
      {
        latitude: 41.153332,
        longitude: 20.168331,
      },
    ],
  },
  {
    name: 'Algeria',
    'alpha-2': 'DZ',
    'alpha-3': 'DZA',
    points: [
      {
        latitude: 28.033886,
        longitude: 1.659626,
      },
      {
        latitude: 28.033886,
        longitude: 1.659626,
      },
      {
        latitude: 28.033886,
        longitude: 1.659626,
      },
    ],
  },
  {
    name: 'Andorra',
    'alpha-2': 'AD',
    'alpha-3': 'AND',
    points: [
      {
        latitude: 42.546245,
        longitude: 1.601554,
      },
      {
        latitude: 42.546245,
        longitude: 1.601554,
      },
      {
        latitude: 42.546245,
        longitude: 1.501554,
      },
    ],
  },
  {
    name: 'Angola',
    'alpha-2': 'AO',
    'alpha-3': 'AGO',
    points: [
      {
        latitude: -11.202692,
        longitude: 17.873887,
      },
      {
        latitude: -11.202692,
        longitude: 17.873887,
      },
      {
        latitude: -11.202692,
        longitude: 17.873887,
      },
    ],
  },
  {
    name: 'Anguilla',
    'alpha-2': 'AI',
    'alpha-3': 'AIA',
    points: [
      {
        latitude: 18.220554,
        longitude: -63.068615,
      },
      {
        latitude: 18.220554,
        longitude: -63.068615,
      },
      {
        latitude: 18.220554,
        longitude: -63.068615,
      },
    ],
  },
  {
    name: 'Antigua and Barbuda',
    'alpha-2': 'AG',
    'alpha-3': 'ATG',
    points: [
      {
        latitude: 17.060816,
        longitude: -61.796428,
      },
      {
        latitude: 17.060816,
        longitude: -61.796428,
      },
      {
        latitude: 17.060816,
        longitude: -61.796428,
      },
    ],
  },
  {
    name: 'Argentina',
    'alpha-2': 'AR',
    'alpha-3': 'ARG',
    points: [
      {
        latitude: -37.520619,
        longitude: -65.961914,
      },
      {
        latitude: -36.520619,
        longitude: -64.961914,
      },
      {
        latitude: -35.520619,
        longitude: -63.961914,
      },
    ],
  },
  {
    name: 'Armenia',
    'alpha-2': 'AM',
    'alpha-3': 'ARM',
    points: [
      {
        latitude: 40.069099,
        longitude: 45.038189,
      },
      {
        latitude: 40.069099,
        longitude: 45.038189,
      },
      {
        latitude: 40.069099,
        longitude: 45.038189,
      },
    ],
  },
  {
    name: 'Aruba',
    'alpha-2': 'AW',
    'alpha-3': 'ABW',
    points: [
      {
        latitude: 12.52111,
        longitude: -69.968338,
      },
      {
        latitude: 12.52111,
        longitude: -69.968338,
      },
      {
        latitude: 12.52111,
        longitude: -69.968338,
      },
    ],
  },
  {
    name: 'Australia',
    'alpha-2': 'AU',
    'alpha-3': 'AUS',
    points: [
      {
        latitude: -25.274398,
        longitude: 133.775136,
      },
      {
        latitude: -24.274398,
        longitude: 131.775136,
      },
      {
        latitude: -23.274398,
        longitude: 132.775136,
      },
    ],
  },
  {
    name: 'Austria',
    'alpha-2': 'AT',
    'alpha-3': 'AUT',
    points: [
      {
        latitude: 47.516231,
        longitude: 14.550072,
      },
      {
        latitude: 47.516231,
        longitude: 14.550072,
      },
      {
        latitude: 46.516231,
        longitude: 13.550072,
      },
    ],
  },
  {
    name: 'Azerbaijan',
    'alpha-2': 'AZ',
    'alpha-3': 'AZE',
    points: [
      {
        latitude: 40.143105,
        longitude: 47.576927,
      },
      {
        latitude: 40.143105,
        longitude: 47.576927,
      },
      {
        latitude: 40.143105,
        longitude: 47.576927,
      },
    ],
  },
  {
    name: 'Bahamas',
    'alpha-2': 'BS',
    'alpha-3': 'BHS',
    points: [
      {
        latitude: 25.03428,
        longitude: -77.39628,
      },
      {
        latitude: 25.03428,
        longitude: -77.39628,
      },
      {
        latitude: 25.03428,
        longitude: -77.39628,
      },
    ],
  },
  {
    name: 'Bahrain',
    'alpha-2': 'BH',
    'alpha-3': 'BHR',
    points: [
      {
        latitude: 25.930414,
        longitude: 50.637772,
      },
      {
        latitude: 25.930414,
        longitude: 50.637772,
      },
      {
        latitude: 25.930414,
        longitude: 50.637772,
      },
    ],
  },
  {
    name: 'Bangladesh',
    'alpha-2': 'BD',
    'alpha-3': 'BGD',
    points: [
      {
        latitude: 23.684994,
        longitude: 90.356331,
      },
      {
        latitude: 23.684994,
        longitude: 90.356331,
      },
      {
        latitude: 23.684994,
        longitude: 90.356331,
      },
    ],
  },
  {
    name: 'Barbados',
    'alpha-2': 'BB',
    'alpha-3': 'BRB',
    points: [
      {
        latitude: 13.193887,
        longitude: -59.543198,
      },
      {
        latitude: 13.193887,
        longitude: -59.543198,
      },
      {
        latitude: 13.193887,
        longitude: -59.543198,
      },
    ],
  },
  {
    name: 'Belarus',
    'alpha-2': 'BY',
    'alpha-3': 'BLR',
    points: [
      {
        latitude: 53.709807,
        longitude: 27.953389,
      },
      {
        latitude: 53.709807,
        longitude: 27.953389,
      },
      {
        latitude: 53.709807,
        longitude: 27.953389,
      },
    ],
  },
  {
    name: 'Belgium',
    'alpha-2': 'BE',
    'alpha-3': 'BEL',
    points: [
      {
        latitude: 50.503887,
        longitude: 4.469936,
      },
      {
        latitude: 51.503887,
        longitude: 3.469936,
      },
      {
        latitude: 50.503887,
        longitude: 3.969936,
      },
    ],
  },
  {
    name: 'Belize',
    'alpha-2': 'BZ',
    'alpha-3': 'BLZ',
    points: [
      {
        latitude: 17.189877,
        longitude: -88.49765,
      },
      {
        latitude: 17.189877,
        longitude: -88.49765,
      },
      {
        latitude: 17.189877,
        longitude: -88.49765,
      },
    ],
  },
  {
    name: 'Benin',
    'alpha-2': 'BJ',
    'alpha-3': 'BEN',
    points: [
      {
        latitude: 9.30769,
        longitude: 2.315834,
      },
      {
        latitude: 9.30769,
        longitude: 2.315834,
      },
      {
        latitude: 9.30769,
        longitude: 2.315834,
      },
    ],
  },
  {
    name: 'Bermuda',
    'alpha-2': 'BM',
    'alpha-3': 'BMU',
    points: [
      {
        latitude: 32.321384,
        longitude: -64.75737,
      },
      {
        latitude: 32.321384,
        longitude: -64.75737,
      },
      {
        latitude: 32.321384,
        longitude: -64.75737,
      },
    ],
  },
  {
    name: 'Bhutan',
    'alpha-2': 'BT',
    'alpha-3': 'BTN',
    points: [
      {
        latitude: 27.514162,
        longitude: 90.433601,
      },
      {
        latitude: 27.514162,
        longitude: 90.433601,
      },
      {
        latitude: 27.514162,
        longitude: 90.433601,
      },
    ],
  },
  {
    name: 'Bolivia',
    'alpha-2': 'BO',
    'alpha-3': 'BOL',
    points: [
      {
        latitude: -16.290154,
        longitude: -63.588653,
      },
      {
        latitude: -16.290154,
        longitude: -63.588653,
      },
      {
        latitude: -16.290154,
        longitude: -63.588653,
      },
    ],
  },
  {
    name: 'Bosnia and Herzegovina',
    'alpha-2': 'BA',
    'alpha-3': 'BIH',
    points: [
      {
        latitude: 43.915886,
        longitude: 17.679076,
      },
      {
        latitude: 42.915886,
        longitude: 16.679076,
      },
      {
        latitude: 41.915886,
        longitude: 16.679076,
      },
    ],
  },
  {
    name: 'Botswana',
    'alpha-2': 'BW',
    'alpha-3': 'BWA',
    points: [
      {
        latitude: -22.328474,
        longitude: 24.684866,
      },
      {
        latitude: -22.328474,
        longitude: 24.684866,
      },
      {
        latitude: -22.328474,
        longitude: 24.684866,
      },
    ],
  },
  {
    name: 'Bouvet Island',
    'alpha-2': 'BV',
    'alpha-3': 'BVT',
    points: [
      {
        latitude: -54.423199,
        longitude: 3.413194,
      },
      {
        latitude: -54.423199,
        longitude: 3.413194,
      },
      {
        latitude: -54.423199,
        longitude: 3.413194,
      },
    ],
  },
  {
    name: 'Brazil',
    'alpha-2': 'BR',
    'alpha-3': 'BRA',
    points: [
      {
        latitude: -14.235004,
        longitude: -51.92528,
      },
      {
        latitude: -13.235004,
        longitude: -52.92528,
      },
      {
        latitude: -12.235004,
        longitude: -50.92528,
      },
    ],
  },
  {
    name: 'British Indian Ocean Territory',
    'alpha-2': 'IO',
    'alpha-3': 'IOT',
    points: [
      {
        latitude: -6.343194,
        longitude: 71.876519,
      },
      {
        latitude: -6.343194,
        longitude: 71.876519,
      },
      {
        latitude: -6.343194,
        longitude: 71.876519,
      },
    ],
  },
  {
    name: 'Brunei',
    'alpha-2': 'BN',
    'alpha-3': 'BRN',
    points: [
      {
        latitude: 4.535277,
        longitude: 114.727669,
      },
      {
        latitude: 4.535277,
        longitude: 114.727669,
      },
      {
        latitude: 4.535277,
        longitude: 114.727669,
      },
    ],
  },
  {
    name: 'Bulgaria',
    'alpha-2': 'BG',
    'alpha-3': 'BGR',
    points: [
      {
        latitude: 42.733883,
        longitude: 25.48583,
      },
      {
        latitude: 43.733883,
        longitude: 24.48583,
      },
      {
        latitude: 41.733883,
        longitude: 24.48583,
      },
    ],
  },
  {
    name: 'Burundi',
    'alpha-2': 'BI',
    'alpha-3': 'BDI',
    points: [
      {
        latitude: -3.373056,
        longitude: 29.918886,
      },
      {
        latitude: -3.373056,
        longitude: 29.918886,
      },
      {
        latitude: -3.373056,
        longitude: 29.918886,
      },
    ],
  },
  {
    name: 'Cambodia',
    'alpha-2': 'KH',
    'alpha-3': 'KHM',
    points: [
      {
        latitude: 12.565679,
        longitude: 104.990963,
      },
      {
        latitude: 12.565679,
        longitude: 104.990963,
      },
      {
        latitude: 12.565679,
        longitude: 104.990963,
      },
    ],
  },
  {
    name: 'Cameroon',
    'alpha-2': 'CM',
    'alpha-3': 'CMR',
    points: [
      {
        latitude: 7.369722,
        longitude: 12.354722,
      },
      {
        latitude: 7.369722,
        longitude: 12.354722,
      },
      {
        latitude: 7.369722,
        longitude: 12.354722,
      },
    ],
  },
  {
    name: 'Canada',
    'alpha-2': 'CA',
    'alpha-3': 'CAN',
    points: [
      {
        latitude: 56.130366,
        longitude: -106.346771,
      },
      {
        latitude: 56.130366,
        longitude: -110.346771,
      },
      {
        latitude: 54.130366,
        longitude: -104.346771,
      },
    ],
  },
  {
    name: 'Cape Verde',
    'alpha-2': 'CV',
    'alpha-3': 'CPV',
    points: [
      {
        latitude: 16.002082,
        longitude: -24.013197,
      },
      {
        latitude: 16.002082,
        longitude: -24.013197,
      },
      {
        latitude: 16.002082,
        longitude: -24.013197,
      },
    ],
  },
  {
    name: 'Cayman Islands',
    'alpha-2': 'KY',
    'alpha-3': 'CYM',
    points: [
      {
        latitude: 19.513469,
        longitude: -80.566956,
      },
      {
        latitude: 19.513469,
        longitude: -80.566956,
      },
      {
        latitude: 19.513469,
        longitude: -80.566956,
      },
    ],
  },
  {
    name: 'Central African Republic',
    'alpha-2': 'CF',
    'alpha-3': 'CAF',
    points: [
      {
        latitude: 6.611111,
        longitude: 20.939444,
      },
      {
        latitude: 6.611111,
        longitude: 20.939444,
      },
      {
        latitude: 6.611111,
        longitude: 20.939444,
      },
    ],
  },
  {
    name: 'Chad',
    'alpha-2': 'TD',
    'alpha-3': 'TCD',
    points: [
      {
        latitude: 15.454166,
        longitude: 18.732207,
      },
      {
        latitude: 15.454166,
        longitude: 18.732207,
      },
      {
        latitude: 15.454166,
        longitude: 18.732207,
      },
    ],
  },
  {
    name: 'Chile',
    'alpha-2': 'CL',
    'alpha-3': 'CHL',
    points: [
      {
        latitude: -35.675147,
        longitude: -71.542969,
      },
      {
        latitude: -35.675147,
        longitude: -71.542969,
      },
      {
        latitude: -35.675147,
        longitude: -71.542969,
      },
    ],
  },
  {
    name: 'China',
    'alpha-2': 'CN',
    'alpha-3': 'CHN',
    points: [
      {
        latitude: 35.86166,
        longitude: 104.195397,
      },
      {
        latitude: 35.86166,
        longitude: 104.195397,
      },
      {
        latitude: 33.86166,
        longitude: 103.195397,
      },
    ],
  },
  {
    name: 'Christmas Island',
    'alpha-2': 'CX',
    'alpha-3': 'CXR',
    points: [
      {
        latitude: -10.447525,
        longitude: 105.690449,
      },
      {
        latitude: -10.447525,
        longitude: 105.690449,
      },
      {
        latitude: -10.447525,
        longitude: 105.690449,
      },
    ],
  },
  {
    name: 'Cocos [Keeling] Islands',
    'alpha-2': 'CC',
    'alpha-3': 'CCK',
    points: [
      {
        latitude: -12.164165,
        longitude: 96.870956,
      },
      {
        latitude: -12.164165,
        longitude: 96.870956,
      },
      {
        latitude: -12.164165,
        longitude: 96.870956,
      },
    ],
  },
  {
    name: 'Colombia',
    'alpha-2': 'CO',
    'alpha-3': 'COL',
    points: [
      {
        latitude: 4.570868,
        longitude: -74.297333,
      },
      {
        latitude: 4.570868,
        longitude: -74.297333,
      },
      {
        latitude: 4.570868,
        longitude: -74.297333,
      },
    ],
  },
  {
    name: 'Comoros',
    'alpha-2': 'KM',
    'alpha-3': 'COM',
    points: [
      {
        latitude: -11.875001,
        longitude: 43.872219,
      },
      {
        latitude: -11.875001,
        longitude: 43.872219,
      },
      {
        latitude: -11.875001,
        longitude: 43.872219,
      },
    ],
  },
  {
    name: 'Congo [DRC]',
    'alpha-2': 'CG',
    'alpha-3': 'COG',
    points: [
      {
        latitude: -4.038333,
        longitude: 21.758664,
      },
      {
        latitude: -4.038333,
        longitude: 21.758664,
      },
      {
        latitude: -4.038333,
        longitude: 21.758664,
      },
    ],
  },
  {
    name: 'Congo [Republic]',
    'alpha-2': 'CD',
    'alpha-3': 'COD',
    points: [
      {
        latitude: -0.228021,
        longitude: 15.827659,
      },
      {
        latitude: -0.228021,
        longitude: 15.827659,
      },
      {
        latitude: -0.228021,
        longitude: 15.827659,
      },
    ],
  },
  {
    name: 'Cook Islands',
    'alpha-2': 'CK',
    'alpha-3': 'COK',
    points: [
      {
        latitude: -21.236736,
        longitude: -159.777671,
      },
      {
        latitude: -21.236736,
        longitude: -159.777671,
      },
      {
        latitude: -21.236736,
        longitude: -159.777671,
      },
    ],
  },
  {
    name: 'Costa Rica',
    'alpha-2': 'CR',
    'alpha-3': 'CRI',
    points: [
      {
        latitude: 9.748917,
        longitude: -83.753428,
      },
      {
        latitude: 9.748917,
        longitude: -83.753428,
      },
      {
        latitude: 9.748917,
        longitude: -83.753428,
      },
    ],
  },
  {
    name: 'Croatia',
    'alpha-2': 'HR',
    'alpha-3': 'HRV',
    points: [
      {
        latitude: 45.1,
        longitude: 15.2,
      },
      {
        latitude: 45.1,
        longitude: 15.2,
      },
      {
        latitude: 43.1,
        longitude: 13.2,
      },
    ],
  },
  {
    name: 'Cuba',
    'alpha-2': 'CU',
    'alpha-3': 'CUB',
    points: [
      {
        latitude: 21.521757,
        longitude: -77.781167,
      },
      {
        latitude: 21.521757,
        longitude: -77.781167,
      },
      {
        latitude: 21.521757,
        longitude: -77.781167,
      },
    ],
  },
  {
    name: 'Cyprus',
    'alpha-2': 'CY',
    'alpha-3': 'CYP',
    points: [
      {
        latitude: 35.126413,
        longitude: 33.429859,
      },
      {
        latitude: 35.126413,
        longitude: 33.429859,
      },
      {
        latitude: 33.126413,
        longitude: 31.429859,
      },
    ],
  },
  {
    name: 'Czech Republic',
    'alpha-2': 'CZ',
    'alpha-3': 'CZE',
    points: [
      {
        latitude: 49.817492,
        longitude: 15.472962,
      },
      {
        latitude: 49.817492,
        longitude: 15.472962,
      },
      {
        latitude: 47.817492,
        longitude: 14.472962,
      },
    ],
  },
  {
    name: 'Denmark',
    'alpha-2': 'DK',
    'alpha-3': 'DNK',
    points: [
      {
        latitude: 56.26392,
        longitude: 9.501785,
      },
      {
        latitude: 56.26392,
        longitude: 9.501785,
      },
      {
        latitude: 54.26392,
        longitude: 8.501785,
      },
    ],
  },
  {
    name: 'Djibouti',
    'alpha-2': 'DJ',
    'alpha-3': 'DJI',
    points: [
      {
        latitude: 11.825138,
        longitude: 42.590275,
      },
      {
        latitude: 11.825138,
        longitude: 42.590275,
      },
      {
        latitude: 11.825138,
        longitude: 42.590275,
      },
    ],
  },
  {
    name: 'Dominica',
    'alpha-2': 'DM',
    'alpha-3': 'DMA',
    points: [
      {
        latitude: 15.414999,
        longitude: -61.370976,
      },
      {
        latitude: 15.414999,
        longitude: -61.370976,
      },
      {
        latitude: 15.414999,
        longitude: -61.370976,
      },
    ],
  },
  {
    name: 'Dominican Republic',
    'alpha-2': 'DO',
    'alpha-3': 'DOM',
    points: [
      {
        latitude: 18.735693,
        longitude: -70.162651,
      },
      {
        latitude: 18.735693,
        longitude: -70.162651,
      },
      {
        latitude: 18.735693,
        longitude: -70.162651,
      },
    ],
  },
  {
    name: 'Ecuador',
    'alpha-2': 'EC',
    'alpha-3': 'ECU',
    points: [
      {
        latitude: -1.831239,
        longitude: -78.183406,
      },
      {
        latitude: -1.831239,
        longitude: -78.183406,
      },
      {
        latitude: -1.831239,
        longitude: -78.183406,
      },
    ],
  },
  {
    name: 'Egypt',
    'alpha-2': 'EG',
    'alpha-3': 'EGY',
    points: [
      {
        latitude: 26.820553,
        longitude: 30.802498,
      },
      {
        latitude: 26.820553,
        longitude: 30.802498,
      },
      {
        latitude: 26.820553,
        longitude: 30.802498,
      },
    ],
  },
  {
    name: 'El Salvador',
    'alpha-2': 'SV',
    'alpha-3': 'SLV',
    points: [
      {
        latitude: 13.794185,
        longitude: -88.89653,
      },
      {
        latitude: 13.794185,
        longitude: -88.89653,
      },
      {
        latitude: 13.794185,
        longitude: -88.89653,
      },
    ],
  },
  {
    name: 'Equatorial Guinea',
    'alpha-2': 'GQ',
    'alpha-3': 'GNQ',
    points: [
      {
        latitude: 1.650801,
        longitude: 10.267895,
      },
      {
        latitude: 1.650801,
        longitude: 10.267895,
      },
      {
        latitude: 1.650801,
        longitude: 10.267895,
      },
    ],
  },
  {
    name: 'Eritrea',
    'alpha-2': 'ER',
    'alpha-3': 'ERI',
    points: [
      {
        latitude: 15.179384,
        longitude: 39.782334,
      },
      {
        latitude: 15.179384,
        longitude: 39.782334,
      },
      {
        latitude: 15.179384,
        longitude: 39.782334,
      },
    ],
  },
  {
    name: 'Estonia',
    'alpha-2': 'EE',
    'alpha-3': 'EST',
    points: [
      {
        latitude: 58.595272,
        longitude: 25.013607,
      },
      {
        latitude: 58.595272,
        longitude: 25.013607,
      },
      {
        latitude: 58.595272,
        longitude: 25.013607,
      },
    ],
  },
  {
    name: 'Ethiopia',
    'alpha-2': 'ET',
    'alpha-3': 'ETH',
    points: [
      {
        latitude: 9.145,
        longitude: 40.489673,
      },
      {
        latitude: 9.145,
        longitude: 40.489673,
      },
      {
        latitude: 9.145,
        longitude: 40.489673,
      },
    ],
  },
  {
    name: 'Falkland Islands',
    'alpha-2': 'FK',
    'alpha-3': 'FLK',
    points: [
      {
        latitude: -51.796253,
        longitude: -59.523613,
      },
      {
        latitude: -51.796253,
        longitude: -59.523613,
      },
      {
        latitude: -51.796253,
        longitude: -59.523613,
      },
    ],
  },
  {
    name: 'Faroe Islands',
    'alpha-2': 'FO',
    'alpha-3': 'FRO',
    points: [
      {
        latitude: 61.892635,
        longitude: -6.911806,
      },
      {
        latitude: 61.892635,
        longitude: -6.911806,
      },
      {
        latitude: 61.892635,
        longitude: -6.911806,
      },
    ],
  },
  {
    name: 'Fiji',
    'alpha-2': 'FJ',
    'alpha-3': 'FJI',
    points: [
      {
        latitude: -16.578193,
        longitude: 179.414413,
      },
      {
        latitude: -16.578193,
        longitude: 179.414413,
      },
      {
        latitude: -16.578193,
        longitude: 179.414413,
      },
    ],
  },
  {
    name: 'Finland',
    'alpha-2': 'FI',
    'alpha-3': 'FIN',
    points: [
      {
        latitude: 61.92411,
        longitude: 25.748151,
      },
      {
        latitude: 61.92411,
        longitude: 25.748151,
      },
      {
        latitude: 61.92411,
        longitude: 25.748151,
      },
    ],
  },
  {
    name: 'France',
    'alpha-2': 'FR',
    'alpha-3': 'FRA',
    points: [
      {
        latitude: 46.227638,
        longitude: 2.213749,
      },
      {
        latitude: 46.227638,
        longitude: 2.213749,
      },
      {
        latitude: 44.227638,
        longitude: 2.013749,
      },
    ],
  },
  {
    name: 'French Guiana',
    'alpha-2': 'GF',
    'alpha-3': 'GUF',
    points: [
      {
        latitude: 3.933889,
        longitude: -53.125782,
      },
      {
        latitude: 3.933889,
        longitude: -53.125782,
      },
      {
        latitude: 3.933889,
        longitude: -53.125782,
      },
    ],
  },
  {
    name: 'French Polynesia',
    'alpha-2': 'PF',
    'alpha-3': 'PYF',
    points: [
      {
        latitude: -17.679742,
        longitude: -149.406843,
      },
      {
        latitude: -17.679742,
        longitude: -149.406843,
      },
      {
        latitude: -17.679742,
        longitude: -149.406843,
      },
    ],
  },
  {
    name: 'French Southern Territories',
    'alpha-2': 'TF',
    'alpha-3': 'ATF',
    points: [
      {
        latitude: -49.280366,
        longitude: 69.348557,
      },
      {
        latitude: -49.280366,
        longitude: 69.348557,
      },
      {
        latitude: -49.280366,
        longitude: 69.348557,
      },
    ],
  },
  {
    name: 'Gabon',
    'alpha-2': 'GA',
    'alpha-3': 'GAB',
    points: [
      {
        latitude: -0.803689,
        longitude: 11.609444,
      },
      {
        latitude: -0.803689,
        longitude: 11.609444,
      },
      {
        latitude: -0.803689,
        longitude: 11.609444,
      },
    ],
  },
  {
    name: 'Gambia',
    'alpha-2': 'GM',
    'alpha-3': 'GMB',
    points: [
      {
        latitude: 13.443182,
        longitude: -15.310139,
      },
      {
        latitude: 13.443182,
        longitude: -15.310139,
      },
      {
        latitude: 13.443182,
        longitude: -15.310139,
      },
    ],
  },
  {
    name: 'Georgia',
    'alpha-2': 'GE',
    'alpha-3': 'GEO',
    points: [
      {
        latitude: 42.315407,
        longitude: 43.356892,
      },
      {
        latitude: 42.315407,
        longitude: 43.356892,
      },
      {
        latitude: 42.315407,
        longitude: 43.356892,
      },
    ],
  },
  {
    name: 'Germany',
    'alpha-2': 'DE',
    'alpha-3': 'DEU',
    points: [
      {
        latitude: 51.165691,
        longitude: 10.451526,
      },
      {
        latitude: 53.165691,
        longitude: 10.451526,
      },
      {
        latitude: 49.065691,
        longitude: 10.051526,
      },
    ],
  },
  {
    name: 'Ghana',
    'alpha-2': 'GH',
    'alpha-3': 'GHA',
    points: [
      {
        latitude: 7.946527,
        longitude: -1.023194,
      },
      {
        latitude: 7.946527,
        longitude: -1.023194,
      },
      {
        latitude: 7.946527,
        longitude: -1.023194,
      },
    ],
  },
  {
    name: 'Gibraltar',
    'alpha-2': 'GI',
    'alpha-3': 'GIB',
    points: [
      {
        latitude: 36.137741,
        longitude: -5.345374,
      },
      {
        latitude: 36.137741,
        longitude: -5.345374,
      },
      {
        latitude: 36.137741,
        longitude: -5.345374,
      },
    ],
  },
  {
    name: 'Greece',
    'alpha-2': 'GR',
    'alpha-3': 'GRC',
    points: [
      {
        latitude: 39.074208,
        longitude: 21.824312,
      },
      {
        latitude: 39.074208,
        longitude: 21.824312,
      },
      {
        latitude: 38.074208,
        longitude: 20.824312,
      },
    ],
  },
  {
    name: 'Greenland',
    'alpha-2': 'GL',
    'alpha-3': 'GRL',
    points: [
      {
        latitude: 71.706936,
        longitude: -42.604303,
      },
      {
        latitude: 71.706936,
        longitude: -42.604303,
      },
      {
        latitude: 71.706936,
        longitude: -42.604303,
      },
    ],
  },
  {
    name: 'Grenada',
    'alpha-2': 'GD',
    'alpha-3': 'GRD',
    points: [
      {
        latitude: 12.262776,
        longitude: -61.604171,
      },
      {
        latitude: 12.262776,
        longitude: -61.604171,
      },
      {
        latitude: 12.262776,
        longitude: -61.604171,
      },
    ],
  },
  {
    name: 'Guadeloupe',
    'alpha-2': 'GP',
    'alpha-3': 'GLP',
    points: [
      {
        latitude: 16.995971,
        longitude: -62.067641,
      },
      {
        latitude: 16.995971,
        longitude: -62.067641,
      },
      {
        latitude: 16.995971,
        longitude: -62.067641,
      },
    ],
  },
  {
    name: 'Guam',
    'alpha-2': 'GU',
    'alpha-3': 'GUM',
    points: [
      {
        latitude: 13.444304,
        longitude: 144.793731,
      },
      {
        latitude: 13.444304,
        longitude: 144.793731,
      },
      {
        latitude: 13.444304,
        longitude: 144.793731,
      },
    ],
  },
  {
    name: 'Guatemala',
    'alpha-2': 'GT',
    'alpha-3': 'GTM',
    points: [
      {
        latitude: 15.783471,
        longitude: -90.230759,
      },
      {
        latitude: 15.783471,
        longitude: -90.230759,
      },
      {
        latitude: 15.783471,
        longitude: -90.230759,
      },
    ],
  },
  {
    name: 'Guernsey',
    'alpha-2': 'GG',
    'alpha-3': 'GGY',
    points: [
      {
        latitude: 49.465691,
        longitude: -2.585278,
      },
      {
        latitude: 49.465691,
        longitude: -2.585278,
      },
      {
        latitude: 49.465691,
        longitude: -2.585278,
      },
    ],
  },
  {
    name: 'Guinea',
    'alpha-2': 'GN',
    'alpha-3': 'GIN',
    points: [
      {
        latitude: 9.945587,
        longitude: -9.696645,
      },
      {
        latitude: 9.945587,
        longitude: -9.696645,
      },
      {
        latitude: 9.945587,
        longitude: -9.696645,
      },
    ],
  },
  {
    name: 'Guinea-Bissau',
    'alpha-2': 'GW',
    'alpha-3': 'GNB',
    points: [
      {
        latitude: 11.803749,
        longitude: -15.180413,
      },
      {
        latitude: 11.803749,
        longitude: -15.180413,
      },
      {
        latitude: 11.803749,
        longitude: -15.180413,
      },
    ],
  },
  {
    name: 'Guyana',
    'alpha-2': 'GY',
    'alpha-3': 'GUY',
    points: [
      {
        latitude: 4.860416,
        longitude: -58.93018,
      },
      {
        latitude: 4.860416,
        longitude: -58.93018,
      },
      {
        latitude: 4.860416,
        longitude: -58.93018,
      },
    ],
  },
  {
    name: 'Haiti',
    'alpha-2': 'HT',
    'alpha-3': 'HTI',
    points: [
      {
        latitude: 18.971187,
        longitude: -72.285215,
      },
      {
        latitude: 18.971187,
        longitude: -72.285215,
      },
      {
        latitude: 18.971187,
        longitude: -72.285215,
      },
    ],
  },
  {
    name: 'Heard Island and McDonald Islands',
    'alpha-2': 'HM',
    'alpha-3': 'HMD',
    points: [
      {
        latitude: -53.08181,
        longitude: 73.504158,
      },
      {
        latitude: -53.08181,
        longitude: 73.504158,
      },
      {
        latitude: -53.08181,
        longitude: 73.504158,
      },
    ],
  },
  {
    name: 'Vatican City',
    'alpha-2': 'VA',
    'alpha-3': 'VAT',
    points: [
      {
        latitude: 41.902916,
        longitude: 12.453389,
      },
      {
        latitude: 41.902916,
        longitude: 12.453389,
      },
      {
        latitude: 41.902916,
        longitude: 12.453389,
      },
    ],
  },
  {
    name: 'Honduras',
    'alpha-2': 'HN',
    'alpha-3': 'HND',
    points: [
      {
        latitude: 15.199999,
        longitude: -86.241905,
      },
      {
        latitude: 15.199999,
        longitude: -86.241905,
      },
      {
        latitude: 15.199999,
        longitude: -86.241905,
      },
    ],
  },
  {
    name: 'Hong Kong',
    'alpha-2': 'HK',
    'alpha-3': 'HKG',
    points: [
      {
        latitude: 22.396428,
        longitude: 114.109497,
      },
      {
        latitude: 22.396428,
        longitude: 114.109497,
      },
      {
        latitude: 21.396428,
        longitude: 111.109497,
      },
    ],
  },
  {
    name: 'Hungary',
    'alpha-2': 'HU',
    'alpha-3': 'HUN',
    points: [
      {
        latitude: 47.162494,
        longitude: 19.503304,
      },
      {
        latitude: 47.162494,
        longitude: 19.503304,
      },
      {
        latitude: 45.162494,
        longitude: 17.503304,
      },
    ],
  },
  {
    name: 'Iceland',
    'alpha-2': 'IS',
    'alpha-3': 'ISL',
    points: [
      {
        latitude: 64.963051,
        longitude: -19.020835,
      },
      {
        latitude: 64.963051,
        longitude: -19.020835,
      },
      {
        latitude: 62.963051,
        longitude: -17.020835,
      },
    ],
  },
  {
    name: 'India',
    'alpha-2': 'IN',
    'alpha-3': 'IND',
    points: [
      {
        latitude: 20.593684,
        longitude: 78.96288,
      },
      {
        latitude: 20.593684,
        longitude: 78.96288,
      },
      {
        latitude: 22.593684,
        longitude: 76.96288,
      },
    ],
  },
  {
    name: 'Indonesia',
    'alpha-2': 'ID',
    'alpha-3': 'IDN',
    points: [
      {
        latitude: -0.789275,
        longitude: 113.921327,
      },
      {
        latitude: -0.789275,
        longitude: 113.921327,
      },
      {
        latitude: -0.789275,
        longitude: 113.921327,
      },
    ],
  },
  {
    name: 'Iran',
    'alpha-2': 'IR',
    'alpha-3': 'IRN',
    points: [
      {
        latitude: 32.427908,
        longitude: 53.688046,
      },
      {
        latitude: 32.427908,
        longitude: 53.688046,
      },
      {
        latitude: 32.427908,
        longitude: 53.688046,
      },
    ],
  },
  {
    name: 'Iraq',
    'alpha-2': 'IQ',
    'alpha-3': 'IRQ',
    points: [
      {
        latitude: 33.223191,
        longitude: 43.679291,
      },
      {
        latitude: 33.223191,
        longitude: 43.679291,
      },
      {
        latitude: 33.223191,
        longitude: 43.679291,
      },
    ],
  },
  {
    name: 'Ireland',
    'alpha-2': 'IE',
    'alpha-3': 'IRL',
    points: [
      {
        latitude: 53.41291,
        longitude: -8.24389,
      },
      {
        latitude: 53.41291,
        longitude: -8.24389,
      },
      {
        latitude: 51.41291,
        longitude: -7.24389,
      },
    ],
  },
  {
    name: 'Isle of Man',
    'alpha-2': 'IM',
    'alpha-3': 'IMN',
    points: [
      {
        latitude: 54.236107,
        longitude: -4.548056,
      },
      {
        latitude: 54.236107,
        longitude: -4.548056,
      },
      {
        latitude: 54.236107,
        longitude: -4.548056,
      },
    ],
  },
  {
    name: 'Israel',
    'alpha-2': 'IL',
    'alpha-3': 'ISR',
    points: [
      {
        latitude: 31.046051,
        longitude: 34.851612,
      },
      {
        latitude: 30.046051,
        longitude: 34.851612,
      },
      {
        latitude: 31.546051,
        longitude: 34.851612,
      },
    ],
  },
  {
    name: 'Italy',
    'alpha-2': 'IT',
    'alpha-3': 'ITA',
    points: [
      {
        latitude: 41.87194,
        longitude: 12.56738,
      },
      {
        latitude: 41.87194,
        longitude: 12.56738,
      },
      {
        latitude: 40.87194,
        longitude: 10.56738,
      },
    ],
  },
  {
    name: 'Jamaica',
    'alpha-2': 'JM',
    'alpha-3': 'JAM',
    points: [
      {
        latitude: 18.109581,
        longitude: -77.297508,
      },
      {
        latitude: 18.109581,
        longitude: -77.297508,
      },
      {
        latitude: 18.109581,
        longitude: -77.297508,
      },
    ],
  },
  {
    name: 'Japan',
    'alpha-2': 'JP',
    'alpha-3': 'JPN',
    points: [
      {
        latitude: 36.204824,
        longitude: 138.252924,
      },
      {
        latitude: 36.204824,
        longitude: 138.252924,
      },
      {
        latitude: 31.204824,
        longitude: 136.252924,
      },
    ],
  },
  {
    name: 'Jersey',
    'alpha-2': 'JE',
    'alpha-3': 'JEY',
    points: [
      {
        latitude: 49.214439,
        longitude: -2.13125,
      },
      {
        latitude: 49.214439,
        longitude: -2.13125,
      },
      {
        latitude: 49.214439,
        longitude: -2.13125,
      },
    ],
  },
  {
    name: 'Jordan',
    'alpha-2': 'JO',
    'alpha-3': 'JOR',
    points: [
      {
        latitude: 30.585164,
        longitude: 36.238414,
      },
      {
        latitude: 30.585164,
        longitude: 36.238414,
      },
      {
        latitude: 30.585164,
        longitude: 36.238414,
      },
    ],
  },
  {
    name: 'Kazakhstan',
    'alpha-2': 'KZ',
    'alpha-3': 'KAZ',
    points: [
      {
        latitude: 48.019573,
        longitude: 66.923684,
      },
      {
        latitude: 48.019573,
        longitude: 66.923684,
      },
      {
        latitude: 48.019573,
        longitude: 66.923684,
      },
    ],
  },
  {
    name: 'Kenya',
    'alpha-2': 'KE',
    'alpha-3': 'KEN',
    points: [
      {
        latitude: -0.023559,
        longitude: 37.906193,
      },
      {
        latitude: -0.023559,
        longitude: 37.906193,
      },
      {
        latitude: -0.023559,
        longitude: 37.906193,
      },
    ],
  },
  {
    name: 'Kiribati',
    'alpha-2': 'KI',
    'alpha-3': 'KIR',
    points: [
      {
        latitude: -3.370417,
        longitude: -168.734039,
      },
      {
        latitude: -3.370417,
        longitude: -168.734039,
      },
      {
        latitude: -3.370417,
        longitude: -168.734039,
      },
    ],
  },
  {
    name: 'South Korea',
    'alpha-2': 'KP',
    'alpha-3': 'PRK',
    points: [
      {
        latitude: 35.907757,
        longitude: 127.766922,
      },
      {
        latitude: 35.907757,
        longitude: 127.766922,
      },
      {
        latitude: 35.907757,
        longitude: 127.766922,
      },
    ],
  },
  {
    name: 'North Korea',
    'alpha-2': 'KR',
    'alpha-3': 'KOR',
    points: [
      {
        latitude: 40.339852,
        longitude: 127.510093,
      },
      {
        latitude: 40.339852,
        longitude: 127.510093,
      },
      {
        latitude: 40.339852,
        longitude: 127.510093,
      },
    ],
  },
  {
    name: 'Kuwait',
    'alpha-2': 'KW',
    'alpha-3': 'KWT',
    points: [
      {
        latitude: 29.31166,
        longitude: 47.481766,
      },
      {
        latitude: 29.31166,
        longitude: 47.481766,
      },
      {
        latitude: 29.31166,
        longitude: 47.481766,
      },
    ],
  },
  {
    name: 'Kyrgyzstan',
    'alpha-2': 'KG',
    'alpha-3': 'KGZ',
    points: [
      {
        latitude: 41.20438,
        longitude: 74.766098,
      },
      {
        latitude: 41.20438,
        longitude: 74.766098,
      },
      {
        latitude: 41.20438,
        longitude: 74.766098,
      },
    ],
  },
  {
    name: 'Latvia',
    'alpha-2': 'LV',
    'alpha-3': 'LVA',
    points: [
      {
        latitude: 56.879635,
        longitude: 24.603189,
      },
      {
        latitude: 56.879635,
        longitude: 24.603189,
      },
      {
        latitude: 56.879635,
        longitude: 24.603189,
      },
    ],
  },
  {
    name: 'Lebanon',
    'alpha-2': 'LB',
    'alpha-3': 'LBN',
    points: [
      {
        latitude: 33.854721,
        longitude: 35.862285,
      },
      {
        latitude: 33.854721,
        longitude: 35.862285,
      },
      {
        latitude: 33.854721,
        longitude: 35.862285,
      },
    ],
  },
  {
    name: 'Lesotho',
    'alpha-2': 'LS',
    'alpha-3': 'LSO',
    points: [
      {
        latitude: -29.609988,
        longitude: 28.233608,
      },
      {
        latitude: -29.609988,
        longitude: 28.233608,
      },
      {
        latitude: -29.609988,
        longitude: 28.233608,
      },
    ],
  },
  {
    name: 'Liberia',
    'alpha-2': 'LR',
    'alpha-3': 'LBR',
    points: [
      {
        latitude: 6.428055,
        longitude: -9.429499,
      },
      {
        latitude: 6.428055,
        longitude: -9.429499,
      },
      {
        latitude: 6.428055,
        longitude: -9.429499,
      },
    ],
  },
  {
    name: 'Libya',
    'alpha-2': 'LY',
    'alpha-3': 'LBY',
    points: [
      {
        latitude: 26.3351,
        longitude: 17.228331,
      },
      {
        latitude: 26.3351,
        longitude: 17.228331,
      },
      {
        latitude: 26.3351,
        longitude: 17.228331,
      },
    ],
  },
  {
    name: 'Liechtenstein',
    'alpha-2': 'LI',
    'alpha-3': 'LIE',
    points: [
      {
        latitude: 47.166,
        longitude: 9.555373,
      },
      {
        latitude: 47.166,
        longitude: 9.555373,
      },
      {
        latitude: 47.166,
        longitude: 9.555373,
      },
    ],
  },
  {
    name: 'Lithuania',
    'alpha-2': 'LT',
    'alpha-3': 'LTU',
    points: [
      {
        latitude: 55.169438,
        longitude: 23.881275,
      },
      {
        latitude: 55.169438,
        longitude: 23.881275,
      },
      {
        latitude: 55.169438,
        longitude: 23.881275,
      },
    ],
  },
  {
    name: 'Luxembourg',
    'alpha-2': 'LU',
    'alpha-3': 'LUX',
    points: [
      {
        latitude: 49.815273,
        longitude: 6.129583,
      },
      {
        latitude: 49.815273,
        longitude: 6.129583,
      },
      {
        latitude: 49.815273,
        longitude: 6.129583,
      },
    ],
  },
  {
    name: 'Macedonia',
    'alpha-2': 'MK',
    'alpha-3': 'MKD',
    points: [
      {
        latitude: 41.608635,
        longitude: 21.745275,
      },
      {
        latitude: 41.608635,
        longitude: 21.745275,
      },
      {
        latitude: 41.608635,
        longitude: 21.745275,
      },
    ],
  },
  {
    name: 'Madagascar',
    'alpha-2': 'MG',
    'alpha-3': 'MDG',
    points: [
      {
        latitude: -18.766947,
        longitude: 46.869107,
      },
      {
        latitude: -18.766947,
        longitude: 46.869107,
      },
      {
        latitude: -18.766947,
        longitude: 46.869107,
      },
    ],
  },
  {
    name: 'Malawi',
    'alpha-2': 'MW',
    'alpha-3': 'MWI',
    points: [
      {
        latitude: -13.254308,
        longitude: 34.301525,
      },
      {
        latitude: -13.254308,
        longitude: 34.301525,
      },
      {
        latitude: -13.254308,
        longitude: 34.301525,
      },
    ],
  },
  {
    name: 'Malaysia',
    'alpha-2': 'MY',
    'alpha-3': 'MYS',
    points: [
      {
        latitude: 4.210484,
        longitude: 101.975766,
      },
      {
        latitude: 4.210484,
        longitude: 101.975766,
      },
      {
        latitude: 4.210484,
        longitude: 101.975766,
      },
    ],
  },
  {
    name: 'Maldives',
    'alpha-2': 'MV',
    'alpha-3': 'MDV',
    points: [
      {
        latitude: 3.202778,
        longitude: 73.22068,
      },
      {
        latitude: 3.202778,
        longitude: 73.22068,
      },
      {
        latitude: 3.202778,
        longitude: 73.22068,
      },
    ],
  },
  {
    name: 'Mali',
    'alpha-2': 'ML',
    'alpha-3': 'MLI',
    points: [
      {
        latitude: 17.570692,
        longitude: -3.996166,
      },
      {
        latitude: 17.570692,
        longitude: -3.996166,
      },
      {
        latitude: 17.570692,
        longitude: -3.996166,
      },
    ],
  },
  {
    name: 'Malta',
    'alpha-2': 'MT',
    'alpha-3': 'MLT',
    points: [
      {
        latitude: 35.937496,
        longitude: 14.375416,
      },
      {
        latitude: 35.937496,
        longitude: 14.375416,
      },
      {
        latitude: 35.937496,
        longitude: 14.375416,
      },
    ],
  },
  {
    name: 'Marshall Islands',
    'alpha-2': 'MH',
    'alpha-3': 'MHL',
    points: [
      {
        latitude: 7.131474,
        longitude: 171.184478,
      },
      {
        latitude: 7.131474,
        longitude: 171.184478,
      },
      {
        latitude: 7.131474,
        longitude: 171.184478,
      },
    ],
  },
  {
    name: 'Martinique',
    'alpha-2': 'MQ',
    'alpha-3': 'MTQ',
    points: [
      {
        latitude: 14.641528,
        longitude: -61.024174,
      },
      {
        latitude: 14.641528,
        longitude: -61.024174,
      },
      {
        latitude: 14.641528,
        longitude: -61.024174,
      },
    ],
  },
  {
    name: 'Mauritania',
    'alpha-2': 'MR',
    'alpha-3': 'MRT',
    points: [
      {
        latitude: 21.00789,
        longitude: -10.940835,
      },
      {
        latitude: 21.00789,
        longitude: -10.940835,
      },
      {
        latitude: 21.00789,
        longitude: -10.940835,
      },
    ],
  },
  {
    name: 'Mauritius',
    'alpha-2': 'MU',
    'alpha-3': 'MUS',
    points: [
      {
        latitude: -20.348404,
        longitude: 57.552152,
      },
      {
        latitude: -20.348404,
        longitude: 57.552152,
      },
      {
        latitude: -20.348404,
        longitude: 57.552152,
      },
    ],
  },
  {
    name: 'Mayotte',
    'alpha-2': 'YT',
    'alpha-3': 'MYT',
    points: [
      {
        latitude: -12.8275,
        longitude: 45.166244,
      },
      {
        latitude: -12.8275,
        longitude: 45.166244,
      },
      {
        latitude: -12.8275,
        longitude: 45.166244,
      },
    ],
  },
  {
    name: 'Mexico',
    'alpha-2': 'MX',
    'alpha-3': 'MEX',
    points: [
      {
        latitude: 23.634501,
        longitude: -102.552784,
      },
      {
        latitude: 23.634501,
        longitude: -102.552784,
      },
      {
        latitude: 22.634501,
        longitude: -101.552784,
      },
    ],
  },
  {
    name: 'Micronesia',
    'alpha-2': 'FM',
    'alpha-3': 'FSM',
    points: [
      {
        latitude: 7.425554,
        longitude: 150.550812,
      },
      {
        latitude: 7.425554,
        longitude: 150.550812,
      },
      {
        latitude: 7.425554,
        longitude: 150.550812,
      },
    ],
  },
  {
    name: 'Moldova',
    'alpha-2': 'MD',
    'alpha-3': 'MDA',
    points: [
      {
        latitude: 47.411631,
        longitude: 28.369885,
      },
      {
        latitude: 47.411631,
        longitude: 28.369885,
      },
      {
        latitude: 45.411631,
        longitude: 26.369885,
      },
    ],
  },
  {
    name: 'Monaco',
    'alpha-2': 'MC',
    'alpha-3': 'MCO',
    points: [
      {
        latitude: 43.750298,
        longitude: 7.412841,
      },
      {
        latitude: 43.750298,
        longitude: 7.412841,
      },
      {
        latitude: 43.750298,
        longitude: 7.412841,
      },
    ],
  },
  {
    name: 'Mongolia',
    'alpha-2': 'MN',
    'alpha-3': 'MNG',
    points: [
      {
        latitude: 46.862496,
        longitude: 103.846656,
      },
      {
        latitude: 46.862496,
        longitude: 103.846656,
      },
      {
        latitude: 46.862496,
        longitude: 103.846656,
      },
    ],
  },
  {
    name: 'Montserrat',
    'alpha-2': 'MS',
    'alpha-3': 'MSR',
    points: [
      {
        latitude: 16.742498,
        longitude: -62.187366,
      },
      {
        latitude: 16.742498,
        longitude: -62.187366,
      },
      {
        latitude: 16.742498,
        longitude: -62.187366,
      },
    ],
  },
  {
    name: 'Morocco',
    'alpha-2': 'MA',
    'alpha-3': 'MAR',
    points: [
      {
        latitude: 31.791702,
        longitude: -7.09262,
      },
      {
        latitude: 31.791702,
        longitude: -7.09262,
      },
      {
        latitude: 31.791702,
        longitude: -7.09262,
      },
    ],
  },
  {
    name: 'Mozambique',
    'alpha-2': 'MZ',
    'alpha-3': 'MOZ',
    points: [
      {
        latitude: -18.665695,
        longitude: 35.529562,
      },
      {
        latitude: -18.665695,
        longitude: 35.529562,
      },
      {
        latitude: -18.665695,
        longitude: 35.529562,
      },
    ],
  },
  {
    name: 'Myanmar',
    'alpha-2': 'MM',
    'alpha-3': 'MMR',
    points: [
      {
        latitude: 21.913965,
        longitude: 95.956223,
      },
      {
        latitude: 21.913965,
        longitude: 95.956223,
      },
      {
        latitude: 21.913965,
        longitude: 95.956223,
      },
    ],
  },
  {
    name: 'Namibia',
    'alpha-2': 'NA',
    'alpha-3': 'NAM',
    points: [
      {
        latitude: -22.95764,
        longitude: 18.49041,
      },
      {
        latitude: -22.95764,
        longitude: 18.49041,
      },
      {
        latitude: -22.95764,
        longitude: 18.49041,
      },
    ],
  },
  {
    name: 'Nauru',
    'alpha-2': 'NR',
    'alpha-3': 'NRU',
    points: [
      {
        latitude: -0.522778,
        longitude: 166.931503,
      },
      {
        latitude: -0.522778,
        longitude: 166.931503,
      },
      {
        latitude: -0.522778,
        longitude: 166.931503,
      },
    ],
  },
  {
    name: 'Nepal',
    'alpha-2': 'NP',
    'alpha-3': 'NPL',
    points: [
      {
        latitude: 28.394857,
        longitude: 84.124008,
      },
      {
        latitude: 28.394857,
        longitude: 84.124008,
      },
      {
        latitude: 28.394857,
        longitude: 84.124008,
      },
    ],
  },
  {
    name: 'Netherlands',
    'alpha-2': 'NL',
    'alpha-3': 'NLD',
    points: [
      {
        latitude: 52.132633,
        longitude: 5.291266,
      },
      {
        latitude: 52.132633,
        longitude: 5.291266,
      },
      {
        latitude: 51.132633,
        longitude: 4.291266,
      },
    ],
  },
  {
    name: 'New Caledonia',
    'alpha-2': 'NC',
    'alpha-3': 'NCL',
    points: [
      {
        latitude: -20.904305,
        longitude: 165.618042,
      },
      {
        latitude: -20.904305,
        longitude: 165.618042,
      },
      {
        latitude: -20.904305,
        longitude: 165.618042,
      },
    ],
  },
  {
    name: 'New Zealand',
    'alpha-2': 'NZ',
    'alpha-3': 'NZL',
    points: [
      {
        latitude: -40.900557,
        longitude: 174.885971,
      },
      {
        latitude: -40.900557,
        longitude: 174.885971,
      },
      {
        latitude: -39.900557,
        longitude: 172.885971,
      },
    ],
  },
  {
    name: 'Nicaragua',
    'alpha-2': 'NI',
    'alpha-3': 'NIC',
    points: [
      {
        latitude: 12.865416,
        longitude: -85.207229,
      },
      {
        latitude: 12.865416,
        longitude: -85.207229,
      },
      {
        latitude: 12.865416,
        longitude: -85.207229,
      },
    ],
  },
  {
    name: 'Niger',
    'alpha-2': 'NE',
    'alpha-3': 'NER',
    points: [
      {
        latitude: 17.607789,
        longitude: 8.081666,
      },
      {
        latitude: 17.607789,
        longitude: 8.081666,
      },
      {
        latitude: 17.607789,
        longitude: 8.081666,
      },
    ],
  },
  {
    name: 'Nigeria',
    'alpha-2': 'NG',
    'alpha-3': 'NGA',
    points: [
      {
        latitude: 9.081999,
        longitude: 8.675277,
      },
      {
        latitude: 9.081999,
        longitude: 8.675277,
      },
      {
        latitude: 9.081999,
        longitude: 8.675277,
      },
    ],
  },
  {
    name: 'Niue',
    'alpha-2': 'NU',
    'alpha-3': 'NIU',
    points: [
      {
        latitude: -19.054445,
        longitude: -169.867233,
      },
      {
        latitude: -19.054445,
        longitude: -169.867233,
      },
      {
        latitude: -19.054445,
        longitude: -169.867233,
      },
    ],
  },
  {
    name: 'Norfolk Island',
    'alpha-2': 'NF',
    'alpha-3': 'NFK',
    points: [
      {
        latitude: -29.040835,
        longitude: 167.954712,
      },
      {
        latitude: -29.040835,
        longitude: 167.954712,
      },
      {
        latitude: -29.040835,
        longitude: 167.954712,
      },
    ],
  },
  {
    name: 'Northern Mariana Islands',
    'alpha-2': 'MP',
    'alpha-3': 'MNP',
    points: [
      {
        latitude: 17.33083,
        longitude: 145.38469,
      },
      {
        latitude: 17.33083,
        longitude: 145.38469,
      },
      {
        latitude: 17.33083,
        longitude: 145.38469,
      },
    ],
  },
  {
    name: 'Norway',
    'alpha-2': 'NO',
    'alpha-3': 'NOR',
    points: [
      {
        latitude: 60.472024,
        longitude: 8.468946,
      },
      {
        latitude: 60.472024,
        longitude: 8.468946,
      },
      {
        latitude: 59.472024,
        longitude: 7.468946,
      },
    ],
  },
  {
    name: 'Oman',
    'alpha-2': 'OM',
    'alpha-3': 'OMN',
    points: [
      {
        latitude: 21.512583,
        longitude: 55.923255,
      },
      {
        latitude: 21.512583,
        longitude: 55.923255,
      },
      {
        latitude: 21.512583,
        longitude: 55.923255,
      },
    ],
  },
  {
    name: 'Pakistan',
    'alpha-2': 'PK',
    'alpha-3': 'PAK',
    points: [
      {
        latitude: 30.375321,
        longitude: 69.345116,
      },
      {
        latitude: 30.375321,
        longitude: 69.345116,
      },
      {
        latitude: 30.375321,
        longitude: 69.345116,
      },
    ],
  },
  {
    name: 'Palau',
    'alpha-2': 'PW',
    'alpha-3': 'PLW',
    points: [
      {
        latitude: 7.51498,
        longitude: 134.58252,
      },
      {
        latitude: 7.51498,
        longitude: 134.58252,
      },
      {
        latitude: 7.51498,
        longitude: 134.58252,
      },
    ],
  },
  {
    name: 'Panama',
    'alpha-2': 'PA',
    'alpha-3': 'PAN',
    points: [
      {
        latitude: 8.537981,
        longitude: -80.782127,
      },
      {
        latitude: 8.537981,
        longitude: -80.782127,
      },
      {
        latitude: 8.537981,
        longitude: -80.782127,
      },
    ],
  },
  {
    name: 'Papua New Guinea',
    'alpha-2': 'PG',
    'alpha-3': 'PNG',
    points: [
      {
        latitude: -6.314993,
        longitude: 143.95555,
      },
      {
        latitude: -6.314993,
        longitude: 143.95555,
      },
      {
        latitude: -6.314993,
        longitude: 143.95555,
      },
    ],
  },
  {
    name: 'Paraguay',
    'alpha-2': 'PY',
    'alpha-3': 'PRY',
    points: [
      {
        latitude: -23.442503,
        longitude: -58.443832,
      },
      {
        latitude: -23.442503,
        longitude: -58.443832,
      },
      {
        latitude: -23.442503,
        longitude: -58.443832,
      },
    ],
  },
  {
    name: 'Peru',
    'alpha-2': 'PE',
    'alpha-3': 'PER',
    points: [
      {
        latitude: -9.189967,
        longitude: -75.015152,
      },
      {
        latitude: -9.189967,
        longitude: -75.015152,
      },
      {
        latitude: -9.189967,
        longitude: -75.015152,
      },
    ],
  },
  {
    name: 'Philippines',
    'alpha-2': 'PH',
    'alpha-3': 'PHL',
    points: [
      {
        latitude: 12.879721,
        longitude: 121.774017,
      },
      {
        latitude: 12.879721,
        longitude: 121.774017,
      },
      {
        latitude: 12.879721,
        longitude: 121.774017,
      },
    ],
  },
  {
    name: 'Pitcairn Islands',
    'alpha-2': 'PN',
    'alpha-3': 'PCN',
    points: [
      {
        latitude: -24.703615,
        longitude: -127.439308,
      },
      {
        latitude: -24.703615,
        longitude: -127.439308,
      },
      {
        latitude: -24.703615,
        longitude: -127.439308,
      },
    ],
  },
  {
    name: 'Poland',
    'alpha-2': 'PL',
    'alpha-3': 'POL',
    points: [
      {
        latitude: 51.919438,
        longitude: 19.145136,
      },
      {
        latitude: 51.919438,
        longitude: 19.145136,
      },
      {
        latitude: 49.919438,
        longitude: 18.145136,
      },
    ],
  },
  {
    name: 'Portugal',
    'alpha-2': 'PT',
    'alpha-3': 'PRT',
    points: [
      {
        latitude: 39.399872,
        longitude: -8.224454,
      },
      {
        latitude: 39.399872,
        longitude: -8.224454,
      },
      {
        latitude: 37.399872,
        longitude: -6.224454,
      },
    ],
  },
  {
    name: 'Qatar',
    'alpha-2': 'QA',
    'alpha-3': 'QAT',
    points: [
      {
        latitude: 25.354826,
        longitude: 51.183884,
      },
      {
        latitude: 25.354826,
        longitude: 51.183884,
      },
      {
        latitude: 25.354826,
        longitude: 51.183884,
      },
    ],
  },
  {
    name: 'Romania',
    'alpha-2': 'RO',
    'alpha-3': 'ROU',
    points: [
      {
        latitude: 45.943161,
        longitude: 24.96676,
      },
      {
        latitude: 45.943161,
        longitude: 24.96676,
      },
      {
        latitude: 43.943161,
        longitude: 22.96676,
      },
    ],
  },
  {
    name: 'Russia',
    'alpha-2': 'RU',
    'alpha-3': 'RUS',
    points: [
      {
        latitude: 61.52401,
        longitude: 105.318756,
      },
      {
        latitude: 63.52401,
        longitude: 105.318756,
      },
      {
        latitude: 59.52401,
        longitude: 103.318756,
      },
    ],
  },
  {
    name: 'Rwanda',
    'alpha-2': 'RW',
    'alpha-3': 'RWA',
    points: [
      {
        latitude: -1.940278,
        longitude: 29.873888,
      },
      {
        latitude: -1.940278,
        longitude: 29.873888,
      },
      {
        latitude: -1.940278,
        longitude: 29.873888,
      },
    ],
  },
  {
    name: 'Saint Helena',
    'alpha-2': 'SH',
    'alpha-3': 'SHN',
    points: [
      {
        latitude: -24.143474,
        longitude: -10.030696,
      },
      {
        latitude: -24.143474,
        longitude: -10.030696,
      },
      {
        latitude: -24.143474,
        longitude: -10.030696,
      },
    ],
  },
  {
    name: 'Saint Kitts and Nevis',
    'alpha-2': 'KN',
    'alpha-3': 'KNA',
    points: [
      {
        latitude: 17.357822,
        longitude: -62.782998,
      },
      {
        latitude: 17.357822,
        longitude: -62.782998,
      },
      {
        latitude: 17.357822,
        longitude: -62.782998,
      },
    ],
  },
  {
    name: 'Saint Lucia',
    'alpha-2': 'LC',
    'alpha-3': 'LCA',
    points: [
      {
        latitude: 13.909444,
        longitude: -60.978893,
      },
      {
        latitude: 13.909444,
        longitude: -60.978893,
      },
      {
        latitude: 13.909444,
        longitude: -60.978893,
      },
    ],
  },
  {
    name: 'Saint Pierre and Miquelon',
    'alpha-2': 'PM',
    'alpha-3': 'SPM',
    points: [
      {
        latitude: 46.941936,
        longitude: -56.27111,
      },
      {
        latitude: 46.941936,
        longitude: -56.27111,
      },
      {
        latitude: 46.941936,
        longitude: -56.27111,
      },
    ],
  },
  {
    name: 'Saint Vincent and the Grenadines',
    'alpha-2': 'VC',
    'alpha-3': 'VCT',
    points: [
      {
        latitude: 12.984305,
        longitude: -61.287228,
      },
      {
        latitude: 12.984305,
        longitude: -61.287228,
      },
      {
        latitude: 12.984305,
        longitude: -61.287228,
      },
    ],
  },
  {
    name: 'Samoa',
    'alpha-2': 'WS',
    'alpha-3': 'WSM',
    points: [
      {
        latitude: -13.759029,
        longitude: -172.104629,
      },
      {
        latitude: -13.759029,
        longitude: -172.104629,
      },
      {
        latitude: -13.759029,
        longitude: -172.104629,
      },
    ],
  },
  {
    name: 'San Marino',
    'alpha-2': 'SM',
    'alpha-3': 'SMR',
    points: [
      {
        latitude: 43.94236,
        longitude: 12.457777,
      },
      {
        latitude: 43.94236,
        longitude: 12.457777,
      },
      {
        latitude: 43.94236,
        longitude: 12.457777,
      },
    ],
  },
  {
    name: 'Saudi Arabia',
    'alpha-2': 'SA',
    'alpha-3': 'SAU',
    points: [
      {
        latitude: 23.885942,
        longitude: 45.079162,
      },
      {
        latitude: 23.885942,
        longitude: 45.079162,
      },
      {
        latitude: 23.885942,
        longitude: 45.079162,
      },
    ],
  },
  {
    name: 'Senegal',
    'alpha-2': 'SN',
    'alpha-3': 'SEN',
    points: [
      {
        latitude: 14.497401,
        longitude: -14.452362,
      },
      {
        latitude: 14.497401,
        longitude: -14.452362,
      },
      {
        latitude: 14.497401,
        longitude: -14.452362,
      },
    ],
  },
  {
    name: 'Seychelles',
    'alpha-2': 'SC',
    'alpha-3': 'SYC',
    points: [
      {
        latitude: -4.679574,
        longitude: 55.491977,
      },
      {
        latitude: -4.679574,
        longitude: 55.491977,
      },
      {
        latitude: -4.679574,
        longitude: 55.491977,
      },
    ],
  },
  {
    name: 'Sierra Leone',
    'alpha-2': 'SL',
    'alpha-3': 'SLE',
    points: [
      {
        latitude: 8.460555,
        longitude: -11.779889,
      },
      {
        latitude: 8.460555,
        longitude: -11.779889,
      },
      {
        latitude: 8.460555,
        longitude: -11.779889,
      },
    ],
  },
  {
    name: 'Singapore',
    'alpha-2': 'SG',
    'alpha-3': 'SGP',
    points: [
      {
        latitude: 1.352083,
        longitude: 103.819836,
      },
      {
        latitude: 1.352083,
        longitude: 103.819836,
      },
      {
        latitude: 0.952083,
        longitude: 101.819836,
      },
    ],
  },
  {
    name: 'Slovakia',
    'alpha-2': 'SK',
    'alpha-3': 'SVK',
    points: [
      {
        latitude: 48.669026,
        longitude: 19.699024,
      },
      {
        latitude: 48.669026,
        longitude: 19.699024,
      },
      {
        latitude: 46.669026,
        longitude: 17.699024,
      },
    ],
  },
  {
    name: 'Slovenia',
    'alpha-2': 'SI',
    'alpha-3': 'SVN',
    points: [
      {
        latitude: 46.151241,
        longitude: 14.995463,
      },
      {
        latitude: 46.151241,
        longitude: 14.995463,
      },
      {
        latitude: 44.151241,
        longitude: 12.995463,
      },
    ],
  },
  {
    name: 'Solomon Islands',
    'alpha-2': 'SB',
    'alpha-3': 'SLB',
    points: [
      {
        latitude: -9.64571,
        longitude: 160.156194,
      },
      {
        latitude: -9.64571,
        longitude: 160.156194,
      },
      {
        latitude: -9.64571,
        longitude: 160.156194,
      },
    ],
  },
  {
    name: 'Somalia',
    'alpha-2': 'SO',
    'alpha-3': 'SOM',
    points: [
      {
        latitude: 5.152149,
        longitude: 46.199616,
      },
      {
        latitude: 5.152149,
        longitude: 46.199616,
      },
      {
        latitude: 5.152149,
        longitude: 46.199616,
      },
    ],
  },
  {
    name: 'South Africa',
    'alpha-2': 'ZA',
    'alpha-3': 'ZAF',
    points: [
      {
        latitude: -30.559482,
        longitude: 22.937506,
      },
      {
        latitude: -30.559482,
        longitude: 22.937506,
      },
      {
        latitude: -28.559482,
        longitude: 21.937506,
      },
    ],
  },
  {
    name: 'South Georgia and the South Sandwich Islands',
    'alpha-2': 'GS',
    'alpha-3': 'SGS',
    points: [
      {
        latitude: -54.429579,
        longitude: -36.587909,
      },
      {
        latitude: -54.429579,
        longitude: -36.587909,
      },
      {
        latitude: -54.429579,
        longitude: -36.587909,
      },
    ],
  },
  {
    name: 'Spain',
    'alpha-2': 'ES',
    'alpha-3': 'ESP',
    points: [
      {
        latitude: 40.463667,
        longitude: -3.74922,
      },
      {
        latitude: 40.463667,
        longitude: -3.74922,
      },
      {
        latitude: 39.463667,
        longitude: -2.74922,
      },
    ],
  },
  {
    name: 'Sri Lanka',
    'alpha-2': 'LK',
    'alpha-3': 'LKA',
    points: [
      {
        latitude: 7.873054,
        longitude: 80.771797,
      },
      {
        latitude: 7.873054,
        longitude: 80.771797,
      },
      {
        latitude: 7.873054,
        longitude: 80.771797,
      },
    ],
  },
  {
    name: 'Sudan',
    'alpha-2': 'SD',
    'alpha-3': 'SDN',
    points: [
      {
        latitude: 12.862807,
        longitude: 30.217636,
      },
      {
        latitude: 12.862807,
        longitude: 30.217636,
      },
      {
        latitude: 12.862807,
        longitude: 30.217636,
      },
    ],
  },
  {
    name: 'Suriname',
    'alpha-2': 'SR',
    'alpha-3': 'SUR',
    points: [
      {
        latitude: 3.919305,
        longitude: -56.027783,
      },
      {
        latitude: 3.919305,
        longitude: -56.027783,
      },
      {
        latitude: 3.919305,
        longitude: -56.027783,
      },
    ],
  },
  {
    name: 'Svalbard and Jan Mayen',
    'alpha-2': 'SJ',
    'alpha-3': 'SJM',
    points: [
      {
        latitude: 77.553604,
        longitude: 23.670272,
      },
      {
        latitude: 77.553604,
        longitude: 23.670272,
      },
      {
        latitude: 77.553604,
        longitude: 23.670272,
      },
    ],
  },
  {
    name: 'Swaziland',
    'alpha-2': 'SZ',
    'alpha-3': 'SWZ',
    points: [
      {
        latitude: -26.522503,
        longitude: 31.465866,
      },
      {
        latitude: -26.522503,
        longitude: 31.465866,
      },
      {
        latitude: -24.522503,
        longitude: 29.465866,
      },
    ],
  },
  {
    name: 'Sweden',
    'alpha-2': 'SE',
    'alpha-3': 'SWE',
    points: [
      {
        latitude: 60.128161,
        longitude: 18.643501,
      },
      {
        latitude: 60.128161,
        longitude: 18.643501,
      },
      {
        latitude: 58.128161,
        longitude: 16.643501,
      },
    ],
  },
  {
    name: 'Switzerland',
    'alpha-2': 'CH',
    'alpha-3': 'CHE',
    points: [
      {
        latitude: 46.818188,
        longitude: 8.227512,
      },
      {
        latitude: 46.818188,
        longitude: 8.227512,
      },
      {
        latitude: 44.818188,
        longitude: 7.227512,
      },
    ],
  },
  {
    name: 'Syria',
    'alpha-2': 'SY',
    'alpha-3': 'SYR',
    points: [
      {
        latitude: 34.802075,
        longitude: 38.996815,
      },
      {
        latitude: 34.802075,
        longitude: 38.996815,
      },
      {
        latitude: 34.802075,
        longitude: 38.996815,
      },
    ],
  },
  {
    name: 'Taiwan',
    'alpha-2': 'TW',
    'alpha-3': 'TWN',
    points: [
      {
        latitude: 23.69781,
        longitude: 120.960515,
      },
      {
        latitude: 23.69781,
        longitude: 120.960515,
      },
      {
        latitude: 21.69781,
        longitude: 118.960515,
      },
    ],
  },
  {
    name: 'Tajikistan',
    'alpha-2': 'TJ',
    'alpha-3': 'TJK',
    points: [
      {
        latitude: 38.861034,
        longitude: 71.276093,
      },
      {
        latitude: 38.861034,
        longitude: 71.276093,
      },
      {
        latitude: 38.861034,
        longitude: 71.276093,
      },
    ],
  },
  {
    name: 'Tanzania',
    'alpha-2': 'TZ',
    'alpha-3': 'TZA',
    points: [
      {
        latitude: -6.369028,
        longitude: 34.888822,
      },
      {
        latitude: -6.369028,
        longitude: 34.888822,
      },
      {
        latitude: -6.369028,
        longitude: 34.888822,
      },
    ],
  },
  {
    name: 'Thailand',
    'alpha-2': 'TH',
    'alpha-3': 'THA',
    points: [
      {
        latitude: 15.870032,
        longitude: 100.992541,
      },
      {
        latitude: 15.870032,
        longitude: 100.992541,
      },
      {
        latitude: 15.870032,
        longitude: 100.992541,
      },
    ],
  },
  {
    name: 'Timor-Leste',
    'alpha-2': 'TL',
    'alpha-3': 'TLS',
    points: [
      {
        latitude: -8.874217,
        longitude: 125.727539,
      },
      {
        latitude: -8.874217,
        longitude: 125.727539,
      },
      {
        latitude: -8.874217,
        longitude: 125.727539,
      },
    ],
  },
  {
    name: 'Togo',
    'alpha-2': 'TG',
    'alpha-3': 'TGO',
    points: [
      {
        latitude: 8.619543,
        longitude: 0.824782,
      },
      {
        latitude: 8.619543,
        longitude: 0.824782,
      },
      {
        latitude: 8.619543,
        longitude: 0.824782,
      },
    ],
  },
  {
    name: 'Tokelau',
    'alpha-2': 'TK',
    'alpha-3': 'TKL',
    points: [
      {
        latitude: -8.967363,
        longitude: -171.855881,
      },
      {
        latitude: -8.967363,
        longitude: -171.855881,
      },
      {
        latitude: -8.967363,
        longitude: -171.855881,
      },
    ],
  },
  {
    name: 'Tonga',
    'alpha-2': 'TO',
    'alpha-3': 'TON',
    points: [
      {
        latitude: -21.178986,
        longitude: -175.198242,
      },
      {
        latitude: -21.178986,
        longitude: -175.198242,
      },
      {
        latitude: -21.178986,
        longitude: -175.198242,
      },
    ],
  },
  {
    name: 'Trinidad and Tobago',
    'alpha-2': 'TT',
    'alpha-3': 'TTO',
    points: [
      {
        latitude: 10.691803,
        longitude: -61.222503,
      },
      {
        latitude: 10.691803,
        longitude: -61.222503,
      },
      {
        latitude: 10.691803,
        longitude: -61.222503,
      },
    ],
  },
  {
    name: 'Tunisia',
    'alpha-2': 'TN',
    'alpha-3': 'TUN',
    points: [
      {
        latitude: 33.886917,
        longitude: 9.537499,
      },
      {
        latitude: 33.886917,
        longitude: 9.537499,
      },
      {
        latitude: 33.886917,
        longitude: 9.537499,
      },
    ],
  },
  {
    name: 'Turkey',
    'alpha-2': 'TR',
    'alpha-3': 'TUR',
    points: [
      {
        latitude: 38.963745,
        longitude: 35.243322,
      },
      {
        latitude: 38.963745,
        longitude: 35.243322,
      },
      {
        latitude: 36.963745,
        longitude: 33.243322,
      },
    ],
  },
  {
    name: 'Turkmenistan',
    'alpha-2': 'TM',
    'alpha-3': 'TKM',
    points: [
      {
        latitude: 38.969719,
        longitude: 59.556278,
      },
      {
        latitude: 38.969719,
        longitude: 59.556278,
      },
      {
        latitude: 38.969719,
        longitude: 59.556278,
      },
    ],
  },
  {
    name: 'Turks and Caicos Islands',
    'alpha-2': 'TC',
    'alpha-3': 'TCA',
    points: [
      {
        latitude: 21.694025,
        longitude: -71.797928,
      },
      {
        latitude: 21.694025,
        longitude: -71.797928,
      },
      {
        latitude: 21.694025,
        longitude: -71.797928,
      },
    ],
  },
  {
    name: 'Tuvalu',
    'alpha-2': 'TV',
    'alpha-3': 'TUV',
    points: [
      {
        latitude: -7.109535,
        longitude: 177.64933,
      },
      {
        latitude: -7.109535,
        longitude: 177.64933,
      },
      {
        latitude: -7.109535,
        longitude: 177.64933,
      },
    ],
  },
  {
    name: 'Uganda',
    'alpha-2': 'UG',
    'alpha-3': 'UGA',
    points: [
      {
        latitude: 1.373333,
        longitude: 32.290275,
      },
      {
        latitude: 1.373333,
        longitude: 32.290275,
      },
      {
        latitude: 1.373333,
        longitude: 32.290275,
      },
    ],
  },
  {
    name: 'Ukraine',
    'alpha-2': 'UA',
    'alpha-3': 'UKR',
    points: [
      {
        latitude: 48.379433,
        longitude: 31.16558,
      },
      {
        latitude: 48.379433,
        longitude: 31.16558,
      },
      {
        latitude: 46.379433,
        longitude: 30.16558,
      },
    ],
  },
  {
    name: 'United Arab Emirates',
    'alpha-2': 'AE',
    'alpha-3': 'ARE',
    points: [
      {
        latitude: 23.424076,
        longitude: 53.847818,
      },
      {
        latitude: 23.424076,
        longitude: 53.847818,
      },
      {
        latitude: 23.424076,
        longitude: 53.847818,
      },
    ],
  },
  {
    name: 'United Kingdom',
    'alpha-2': 'GB',
    'alpha-3': 'GBR',
    points: [
      {
        latitude: 55.378051,
        longitude: -3.435973,
      },
      {
        latitude: 55.378051,
        longitude: -3.435973,
      },
      {
        latitude: 53.378051,
        longitude: -2.435973,
      },
    ],
  },
  {
    name: 'United States of America',
    'alpha-2': 'US',
    'alpha-3': 'USA',
    points: [
      {
        latitude: 37.09024,
        longitude: -95.712891,
      },
      {
        latitude: 37.09024,
        longitude: -99.712891,
      },
      {
        latitude: 40.09024,
        longitude: -106.712891,
      },
    ],
  },
  {
    name: 'Uruguay',
    'alpha-2': 'UY',
    'alpha-3': 'URY',
    points: [
      {
        latitude: -32.522779,
        longitude: -55.765835,
      },
      {
        latitude: -32.522779,
        longitude: -55.765835,
      },
      {
        latitude: -32.522779,
        longitude: -55.765835,
      },
    ],
  },
  {
    name: 'Uzbekistan',
    'alpha-2': 'UZ',
    'alpha-3': 'UZB',
    points: [
      {
        latitude: 41.377491,
        longitude: 64.585262,
      },
      {
        latitude: 41.377491,
        longitude: 64.585262,
      },
      {
        latitude: 41.377491,
        longitude: 64.585262,
      },
    ],
  },
  {
    name: 'Vanuatu',
    'alpha-2': 'VU',
    'alpha-3': 'VUT',
    points: [
      {
        latitude: -15.376706,
        longitude: 166.959158,
      },
      {
        latitude: -15.376706,
        longitude: 166.959158,
      },
      {
        latitude: -15.376706,
        longitude: 166.959158,
      },
    ],
  },
  {
    name: 'Venezuela',
    'alpha-2': 'VE',
    'alpha-3': 'VEN',
    points: [
      {
        latitude: 6.42375,
        longitude: -66.58973,
      },
      {
        latitude: 6.42375,
        longitude: -66.58973,
      },
      {
        latitude: 6.42375,
        longitude: -66.58973,
      },
    ],
  },
  {
    name: 'Vietnam',
    'alpha-2': 'VN',
    'alpha-3': 'VNM',
    points: [
      {
        latitude: 14.058324,
        longitude: 108.277199,
      },
      {
        latitude: 14.058324,
        longitude: 108.277199,
      },
      {
        latitude: 14.058324,
        longitude: 108.277199,
      },
    ],
  },
  {
    name: 'British Virgin Islands',
    'alpha-2': 'VG',
    'alpha-3': 'VGB',
    points: [
      {
        latitude: 18.420695,
        longitude: -64.639968,
      },
      {
        latitude: 18.420695,
        longitude: -64.639968,
      },
      {
        latitude: 18.420695,
        longitude: -64.639968,
      },
    ],
  },
  {
    name: 'U.S. Virgin Islands',
    'alpha-2': 'VI',
    'alpha-3': 'VIR',
    points: [
      {
        latitude: 18.335765,
        longitude: -64.896335,
      },
      {
        latitude: 18.335765,
        longitude: -64.896335,
      },
      {
        latitude: 18.335765,
        longitude: -64.896335,
      },
    ],
  },
  {
    name: 'Wallis and Futuna',
    'alpha-2': 'WF',
    'alpha-3': 'WLF',
    points: [
      {
        latitude: -13.768752,
        longitude: -177.156097,
      },
      {
        latitude: -13.768752,
        longitude: -177.156097,
      },
      {
        latitude: -13.768752,
        longitude: -177.156097,
      },
    ],
  },
  {
    name: 'Western Sahara',
    'alpha-2': 'EH',
    'alpha-3': 'ESH',
    points: [
      {
        latitude: 24.215527,
        longitude: -12.885834,
      },
      {
        latitude: 24.215527,
        longitude: -12.885834,
      },
      {
        latitude: 24.215527,
        longitude: -12.885834,
      },
    ],
  },
  {
    name: 'Yemen',
    'alpha-2': 'YE',
    'alpha-3': 'YEM',
    points: [
      {
        latitude: 15.552727,
        longitude: 48.516388,
      },
      {
        latitude: 15.552727,
        longitude: 48.516388,
      },
      {
        latitude: 15.552727,
        longitude: 48.516388,
      },
    ],
  },
  {
    name: 'Zambia',
    'alpha-2': 'ZM',
    'alpha-3': 'ZMB',
    points: [
      {
        latitude: -13.133897,
        longitude: 27.849332,
      },
      {
        latitude: -13.133897,
        longitude: 27.849332,
      },
      {
        latitude: -13.133897,
        longitude: 27.849332,
      },
    ],
  },
  {
    name: 'Zimbabwe',
    'alpha-2': 'ZW',
    'alpha-3': 'ZWE',
    points: [
      {
        latitude: -19.015438,
        longitude: 29.154857,
      },
      {
        latitude: -19.015438,
        longitude: 29.154857,
      },
      {
        latitude: -19.015438,
        longitude: 29.154857,
      },
    ],
  },
  {
    name: 'Alabama',
    'alpha-2': 'AL',
    'alpha-3': '',
    points: [
      {
        latitude: 32.806671,
        longitude: -86.79113,
      },
      {
        latitude: 32.806671,
        longitude: -86.79113,
      },
      {
        latitude: 32.806671,
        longitude: -86.79113,
      },
    ],
    isState: true,
  },
  {
    name: 'Alaska',
    'alpha-2': 'AK',
    'alpha-3': '',
    points: [
      {
        latitude: 61.370716,
        longitude: -152.404419,
      },
      {
        latitude: 61.370716,
        longitude: -152.404419,
      },
      {
        latitude: 61.370716,
        longitude: -152.404419,
      },
    ],
    isState: true,
  },
  {
    name: 'Arizona',
    'alpha-2': 'AZ',
    'alpha-3': '',
    points: [
      {
        latitude: 33.729759,
        longitude: -111.431221,
      },
      {
        latitude: 33.729759,
        longitude: -111.431221,
      },
      {
        latitude: 33.729759,
        longitude: -111.431221,
      },
    ],
    isState: true,
  },
  {
    name: 'Arkansas',
    'alpha-2': 'AR',
    'alpha-3': '',
    points: [
      {
        latitude: 34.969704,
        longitude: -92.373123,
      },
      {
        latitude: 34.969704,
        longitude: -92.373123,
      },
      {
        latitude: 34.969704,
        longitude: -92.373123,
      },
    ],
    isState: true,
  },
  {
    name: 'California',
    'alpha-2': 'CA',
    'alpha-3': '',
    points: [
      {
        latitude: 36.116203,
        longitude: -119.681564,
      },
      {
        latitude: 36.116203,
        longitude: -119.681564,
      },
      {
        latitude: 36.116203,
        longitude: -119.681564,
      },
    ],
    isState: true,
  },
  {
    name: 'Colorado',
    'alpha-2': 'CO',
    'alpha-3': '',
    points: [
      {
        latitude: 39.059811,
        longitude: -105.311104,
      },
      {
        latitude: 39.059811,
        longitude: -105.311104,
      },
      {
        latitude: 39.059811,
        longitude: -105.311104,
      },
    ],
    isState: true,
  },
  {
    name: 'Connecticut',
    'alpha-2': 'CT',
    'alpha-3': '',
    points: [
      {
        latitude: 41.597782,
        longitude: -72.755371,
      },
      {
        latitude: 41.597782,
        longitude: -72.755371,
      },
      {
        latitude: 41.597782,
        longitude: -72.755371,
      },
    ],
    isState: true,
  },
  {
    name: 'Delaware',
    'alpha-2': 'DE',
    'alpha-3': '',
    points: [
      {
        latitude: 39.318523,
        longitude: -75.507141,
      },
      {
        latitude: 39.318523,
        longitude: -75.507141,
      },
      {
        latitude: 39.318523,
        longitude: -75.507141,
      },
    ],
    isState: true,
  },
  {
    name: 'District of Columbia',
    'alpha-2': 'DC',
    'alpha-3': '',
    points: [
      {
        latitude: 38.897438,
        longitude: -77.026817,
      },
      {
        latitude: 38.897438,
        longitude: -77.026817,
      },
      {
        latitude: 38.897438,
        longitude: -77.026817,
      },
    ],
    isState: true,
  },
  {
    name: 'Florida',
    'alpha-2': 'FL',
    'alpha-3': '',
    points: [
      {
        latitude: 27.766279,
        longitude: -81.686783,
      },
      {
        latitude: 27.766279,
        longitude: -81.686783,
      },
      {
        latitude: 27.766279,
        longitude: -81.686783,
      },
    ],
    isState: true,
  },
  {
    name: 'Georgia US',
    'alpha-2': 'GA',
    'alpha-3': '',
    points: [
      {
        latitude: 33.040619,
        longitude: -83.643074,
      },
      {
        latitude: 33.040619,
        longitude: -83.643074,
      },
      {
        latitude: 33.040619,
        longitude: -83.643074,
      },
    ],
    isState: true,
  },
  {
    name: 'Hawaii',
    'alpha-2': 'HI',
    'alpha-3': '',
    points: [
      {
        latitude: 21.094318,
        longitude: -157.498337,
      },
      {
        latitude: 21.094318,
        longitude: -157.498337,
      },
      {
        latitude: 21.094318,
        longitude: -157.498337,
      },
    ],
    isState: true,
  },
  {
    name: 'Idaho',
    'alpha-2': 'ID',
    'alpha-3': '',
    points: [
      {
        latitude: 44.240459,
        longitude: -114.478828,
      },
      {
        latitude: 44.240459,
        longitude: -114.478828,
      },
      {
        latitude: 44.240459,
        longitude: -114.478828,
      },
    ],
    isState: true,
  },
  {
    name: 'Illinois',
    'alpha-2': 'IL',
    'alpha-3': '',
    points: [
      {
        latitude: 40.349457,
        longitude: -88.986137,
      },
      {
        latitude: 40.349457,
        longitude: -88.986137,
      },
      {
        latitude: 40.349457,
        longitude: -88.986137,
      },
    ],
    isState: true,
  },
  {
    name: 'Indiana',
    'alpha-2': 'IN',
    'alpha-3': '',
    points: [
      {
        latitude: 39.849426,
        longitude: -86.258278,
      },
      {
        latitude: 39.849426,
        longitude: -86.258278,
      },
      {
        latitude: 39.849426,
        longitude: -86.258278,
      },
    ],
    isState: true,
  },
  {
    name: 'Iowa',
    'alpha-2': 'IA',
    'alpha-3': '',
    points: [
      {
        latitude: 42.011539,
        longitude: -93.210526,
      },
      {
        latitude: 42.011539,
        longitude: -93.210526,
      },
      {
        latitude: 42.011539,
        longitude: -93.210526,
      },
    ],
    isState: true,
  },
  {
    name: 'Kansas',
    'alpha-2': 'KS',
    'alpha-3': '',
    points: [
      {
        latitude: 38.5266,
        longitude: -96.726486,
      },
      {
        latitude: 38.5266,
        longitude: -96.726486,
      },
      {
        latitude: 38.5266,
        longitude: -96.726486,
      },
    ],
    isState: true,
  },
  {
    name: 'Kentucky',
    'alpha-2': 'KY',
    'alpha-3': '',
    points: [
      {
        latitude: 37.66814,
        longitude: -84.670067,
      },
      {
        latitude: 37.66814,
        longitude: -84.670067,
      },
      {
        latitude: 37.66814,
        longitude: -84.670067,
      },
    ],
    isState: true,
  },
  {
    name: 'Louisiana',
    'alpha-2': 'LA',
    'alpha-3': '',
    points: [
      {
        latitude: 31.169546,
        longitude: -91.867805,
      },
      {
        latitude: 31.169546,
        longitude: -91.867805,
      },
      {
        latitude: 31.169546,
        longitude: -91.867805,
      },
    ],
    isState: true,
  },
  {
    name: 'Maine',
    'alpha-2': 'ME',
    'alpha-3': '',
    points: [
      {
        latitude: 44.693947,
        longitude: -69.381927,
      },
      {
        latitude: 44.693947,
        longitude: -69.381927,
      },
      {
        latitude: 44.693947,
        longitude: -69.381927,
      },
    ],
    isState: true,
  },
  {
    name: 'Maryland',
    'alpha-2': 'MD',
    'alpha-3': '',
    points: [
      {
        latitude: 39.063946,
        longitude: -76.802101,
      },
      {
        latitude: 39.063946,
        longitude: -76.802101,
      },
      {
        latitude: 39.063946,
        longitude: -76.802101,
      },
    ],
    isState: true,
  },
  {
    name: 'Massachusetts',
    'alpha-2': 'MA',
    'alpha-3': '',
    points: [
      {
        latitude: 42.230171,
        longitude: -71.530106,
      },
      {
        latitude: 42.230171,
        longitude: -71.530106,
      },
      {
        latitude: 42.230171,
        longitude: -71.530106,
      },
    ],
    isState: true,
  },
  {
    name: 'Michigan',
    'alpha-2': 'MI',
    'alpha-3': '',
    points: [
      {
        latitude: 43.326618,
        longitude: -84.536095,
      },
      {
        latitude: 43.326618,
        longitude: -84.536095,
      },
      {
        latitude: 43.326618,
        longitude: -84.536095,
      },
    ],
    isState: true,
  },
  {
    name: 'Minnesota',
    'alpha-2': 'MN',
    'alpha-3': '',
    points: [
      {
        latitude: 45.694454,
        longitude: -93.900192,
      },
      {
        latitude: 45.694454,
        longitude: -93.900192,
      },
      {
        latitude: 45.694454,
        longitude: -93.900192,
      },
    ],
    isState: true,
  },
  {
    name: 'Mississippi',
    'alpha-2': 'MS',
    'alpha-3': '',
    points: [
      {
        latitude: 32.741646,
        longitude: -89.678696,
      },
      {
        latitude: 32.741646,
        longitude: -89.678696,
      },
      {
        latitude: 32.741646,
        longitude: -89.678696,
      },
    ],
    isState: true,
  },
  {
    name: 'Missouri',
    'alpha-2': 'MO',
    'alpha-3': '',
    points: [
      {
        latitude: 38.456085,
        longitude: -92.288368,
      },
      {
        latitude: 38.456085,
        longitude: -92.288368,
      },
      {
        latitude: 38.456085,
        longitude: -92.288368,
      },
    ],
    isState: true,
  },
  {
    name: 'Montana',
    'alpha-2': 'MT',
    'alpha-3': '',
    points: [
      {
        latitude: 46.921925,
        longitude: -110.454353,
      },
      {
        latitude: 46.921925,
        longitude: -110.454353,
      },
      {
        latitude: 46.921925,
        longitude: -110.454353,
      },
    ],
    isState: true,
  },
  {
    name: 'Nebraska',
    'alpha-2': 'NE',
    'alpha-3': '',
    points: [
      {
        latitude: 41.12537,
        longitude: -98.268082,
      },
      {
        latitude: 41.12537,
        longitude: -98.268082,
      },
      {
        latitude: 41.12537,
        longitude: -98.268082,
      },
    ],
    isState: true,
  },
  {
    name: 'Nevada',
    'alpha-2': 'NV',
    'alpha-3': '',
    points: [
      {
        latitude: 38.313515,
        longitude: -117.055374,
      },
      {
        latitude: 38.313515,
        longitude: -117.055374,
      },
      {
        latitude: 38.313515,
        longitude: -117.055374,
      },
    ],
    isState: true,
  },
  {
    name: 'New Hampshire',
    'alpha-2': 'NH',
    'alpha-3': '',
    points: [
      {
        latitude: 43.452492,
        longitude: -71.563896,
      },
      {
        latitude: 43.452492,
        longitude: -71.563896,
      },
      {
        latitude: 43.452492,
        longitude: -71.563896,
      },
    ],
    isState: true,
  },
  {
    name: 'New Jersey',
    'alpha-2': 'NJ',
    'alpha-3': '',
    points: [
      {
        latitude: 40.298904,
        longitude: -74.521011,
      },
      {
        latitude: 40.298904,
        longitude: -74.521011,
      },
      {
        latitude: 40.298904,
        longitude: -74.521011,
      },
    ],
    isState: true,
  },
  {
    name: 'New Mexico',
    'alpha-2': 'NM',
    'alpha-3': '',
    points: [
      {
        latitude: 34.840515,
        longitude: -106.24848,
      },
      {
        latitude: 34.840515,
        longitude: -106.24848,
      },
      {
        latitude: 34.840515,
        longitude: -106.24848,
      },
    ],
    isState: true,
  },
  {
    name: 'New York',
    'alpha-2': 'NY',
    'alpha-3': '',
    points: [
      {
        latitude: 42.165726,
        longitude: -74.948051,
      },
      {
        latitude: 42.165726,
        longitude: -74.948051,
      },
      {
        latitude: 42.165726,
        longitude: -74.948051,
      },
    ],
    isState: true,
  },
  {
    name: 'North Carolina',
    'alpha-2': 'NC',
    'alpha-3': '',
    points: [
      {
        latitude: 35.630066,
        longitude: -79.806419,
      },
      {
        latitude: 35.630066,
        longitude: -79.806419,
      },
      {
        latitude: 35.630066,
        longitude: -79.806419,
      },
    ],
    isState: true,
  },
  {
    name: 'North Dakota',
    'alpha-2': 'ND',
    'alpha-3': '',
    points: [
      {
        latitude: 47.528912,
        longitude: -99.784012,
      },
      {
        latitude: 47.528912,
        longitude: -99.784012,
      },
      {
        latitude: 47.528912,
        longitude: -99.784012,
      },
    ],
    isState: true,
  },
  {
    name: 'Ohio',
    'alpha-2': 'OH',
    'alpha-3': '',
    points: [
      {
        latitude: 40.388783,
        longitude: -82.764915,
      },
      {
        latitude: 40.388783,
        longitude: -82.764915,
      },
      {
        latitude: 40.388783,
        longitude: -82.764915,
      },
    ],
    isState: true,
  },
  {
    name: 'Oklahoma',
    'alpha-2': 'OK',
    'alpha-3': '',
    points: [
      {
        latitude: 35.565342,
        longitude: -96.928917,
      },
      {
        latitude: 35.565342,
        longitude: -96.928917,
      },
      {
        latitude: 35.565342,
        longitude: -96.928917,
      },
    ],
    isState: true,
  },
  {
    name: 'Oregon',
    'alpha-2': 'OR',
    'alpha-3': '',
    points: [
      {
        latitude: 44.572021,
        longitude: -122.070938,
      },
      {
        latitude: 44.572021,
        longitude: -122.070938,
      },
      {
        latitude: 44.572021,
        longitude: -122.070938,
      },
    ],
    isState: true,
  },
  {
    name: 'Pennsylvania',
    'alpha-2': 'PA',
    'alpha-3': '',
    points: [
      {
        latitude: 40.590752,
        longitude: -77.209755,
      },
      {
        latitude: 40.590752,
        longitude: -77.209755,
      },
      {
        latitude: 40.590752,
        longitude: -77.209755,
      },
    ],
    isState: true,
  },
  {
    name: 'Rhode Island',
    'alpha-2': 'RI',
    'alpha-3': '',
    points: [
      {
        latitude: 41.680893,
        longitude: -71.51178,
      },
      {
        latitude: 41.680893,
        longitude: -71.51178,
      },
      {
        latitude: 41.680893,
        longitude: -71.51178,
      },
    ],
    isState: true,
  },
  {
    name: 'South Carolina',
    'alpha-2': 'SC',
    'alpha-3': '',
    points: [
      {
        latitude: 33.856892,
        longitude: -80.945007,
      },
      {
        latitude: 33.856892,
        longitude: -80.945007,
      },
      {
        latitude: 33.856892,
        longitude: -80.945007,
      },
    ],
    isState: true,
  },
  {
    name: 'South Dakota',
    'alpha-2': 'SD',
    'alpha-3': '',
    points: [
      {
        latitude: 44.299782,
        longitude: -99.438828,
      },
      {
        latitude: 44.299782,
        longitude: -99.438828,
      },
      {
        latitude: 44.299782,
        longitude: -99.438828,
      },
    ],
    isState: true,
  },
  {
    name: 'Tennessee',
    'alpha-2': 'TN',
    'alpha-3': '',
    points: [
      {
        latitude: 35.747845,
        longitude: -86.692345,
      },
      {
        latitude: 35.747845,
        longitude: -86.692345,
      },
      {
        latitude: 35.747845,
        longitude: -86.692345,
      },
    ],
    isState: true,
  },
  {
    name: 'Texas',
    'alpha-2': 'TX',
    'alpha-3': '',
    points: [
      {
        latitude: 31.054487,
        longitude: -97.563461,
      },
      {
        latitude: 31.054487,
        longitude: -97.563461,
      },
      {
        latitude: 31.054487,
        longitude: -97.563461,
      },
    ],
    isState: true,
  },
  {
    name: 'Utah',
    'alpha-2': 'UT',
    'alpha-3': '',
    points: [
      {
        latitude: 40.150032,
        longitude: -111.862434,
      },
      {
        latitude: 40.150032,
        longitude: -111.862434,
      },
      {
        latitude: 40.150032,
        longitude: -111.862434,
      },
    ],
    isState: true,
  },
  {
    name: 'Vermont',
    'alpha-2': 'VT',
    'alpha-3': '',
    points: [
      {
        latitude: 44.045876,
        longitude: -72.710686,
      },
      {
        latitude: 44.045876,
        longitude: -72.710686,
      },
      {
        latitude: 44.045876,
        longitude: -72.710686,
      },
    ],
    isState: true,
  },
  {
    name: 'Virginia',
    'alpha-2': 'VA',
    'alpha-3': '',
    points: [
      {
        latitude: 37.769337,
        longitude: -78.169968,
      },
      {
        latitude: 37.769337,
        longitude: -78.169968,
      },
      {
        latitude: 37.769337,
        longitude: -78.169968,
      },
    ],
    isState: true,
  },
  {
    name: 'Washington',
    'alpha-2': 'WA',
    'alpha-3': '',
    points: [
      {
        latitude: 47.400902,
        longitude: -121.490494,
      },
      {
        latitude: 47.400902,
        longitude: -121.490494,
      },
      {
        latitude: 47.400902,
        longitude: -121.490494,
      },
    ],
    isState: true,
  },
  {
    name: 'West Virginia',
    'alpha-2': 'WV',
    'alpha-3': '',
    points: [
      {
        latitude: 38.491226,
        longitude: -80.954453,
      },
      {
        latitude: 38.491226,
        longitude: -80.954453,
      },
      {
        latitude: 38.491226,
        longitude: -80.954453,
      },
    ],
    isState: true,
  },
  {
    name: 'Wisconsin',
    'alpha-2': 'WI',
    'alpha-3': '',
    points: [
      {
        latitude: 44.268543,
        longitude: -89.616508,
      },
      {
        latitude: 44.268543,
        longitude: -89.616508,
      },
      {
        latitude: 44.268543,
        longitude: -89.616508,
      },
    ],
    isState: true,
  },
  {
    name: 'Wyoming',
    'alpha-2': 'WY',
    'alpha-3': '',
    points: [
      {
        latitude: 42.755966,
        longitude: -107.30249,
      },
      {
        latitude: 42.755966,
        longitude: -107.30249,
      },
      {
        latitude: 42.755966,
        longitude: -107.30249,
      },
    ],
    isState: true,
  },
];

export const getCopyOfCountries = (displayChinaRegulations: boolean): CountryDefinition[] => {
  const copyOfCountries = countries.map(country => ({ ...country, displayName: country.name }));
  if (displayChinaRegulations) {
    const taiwanIndex = findIndex(copyOfCountries, { name: 'Taiwan' });
    const hongKongIndex = findIndex(copyOfCountries, { name: 'Hong Kong' });

    copyOfCountries[taiwanIndex] = {
      ...copyOfCountries[taiwanIndex],
      displayName: 'Taiwan, China',
      'alpha-2': 'CN-TW',
      'alpha-3': 'CHN-TWN',
    };
    copyOfCountries[hongKongIndex] = {
      ...copyOfCountries[hongKongIndex],
      displayName: 'Hong Kong, China',
      'alpha-2': 'CN-HK',
      'alpha-3': 'CHN-HKG',
    };
  }
  return copyOfCountries;
};
