import React, { FC, useEffect } from 'react';
import { BigidErrorPage } from '@bigid-ui/components';
import { $state } from '../../services/angularServices';
import { CONFIG } from '../../../config/common';
import { pageHeaderService } from '../../../common/services/pageHeaderService';

export const NotFound: FC = () => {
  useEffect(() => {
    pageHeaderService.setIsHidden(true);
    return () => pageHeaderService.setIsHidden(false);
  }, []);

  return <BigidErrorPage type="notFound" onLinkClick={() => $state.go(CONFIG.states.DASHBOARD)} />;
};
