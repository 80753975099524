import { CountryData } from '../Fmsd/fmsdServices';
import { CONFIG } from '../../../config/common';
export interface LegalEntitiesInterface {
  id: string;
  name: string;
  levelId?: string;
  level?: LegalEntityLevel;
  description?: string;
  status: `${LegalEntitiesStatus}`;
  parentId?: string;
  assetIds?: string[];
  assets?: MultiValueEntity[];
  parentEntity?: MultiValueEntity;
  operatingLocations?: string[];
  locations?: LocationWithCode[];
  subEntities?: LegalEntitiesInterface[];
  createdAt?: string;
  mainContactName?: string;
  mainContactEmail?: string;
}

export type LegalEntitiesCreateUpdateType = Omit<LegalEntitiesInterface, 'id' | 'status'>;

export interface MultiValueEntity {
  id: string;
  name: string;
}

export interface HierarchyType {
  id: string;
  name: string;
  level: LegalEntityLevel;
}

export type CountryCodeMapperType = Record<CountryData['name'], CountryData['groupedName']>;

export interface LegalEntityLevel {
  id: string;
  name: string;
  level: number;
}

export interface LocationWithCode {
  code: string;
  name: string;
}

export type LegalEntitiesDeleteDetails = {
  id: string;
  name: string;
};

export interface LegalEntityActivityLog {
  id: string;
  status: `${LegalEntitiesStatus}`;
  action: EntityAction;
  description: string;
  user: string;
  timestamp: Date;
}

export enum EntityAction {
  CREATE = 'Create',
  UPDATE = 'Update',
  IMPORT = 'Import',
  DELETE = 'Delete',
}

export enum LegalEntitiesStatus {
  DRAFT = 'Draft',
  UNDER_REVIEW = 'UnderReview',
  ACTIVE = 'Active',
  INACTIVE = 'Inactive',
}

export enum LegalEntityPageTabs {
  OVERVIEW = 'Overview',
  //Commenting out branding and logs (out of scope)
  // BRANDING = 'Branding',
  // LOGS = 'Activity Logs',
}

export enum LegalEntityPageTabsPath {
  OVERVIEW = 'overview',
  // Commenting out branding and logs (out of scope)
  // BRANDING = 'branding',
  // LOGS = 'logs',
}
export type NewlyAddedOptionsType = {
  updatedParent: boolean;
  addedNewAssets: boolean;
};

export interface LegalEntitiesEditDialogProps {
  isEditDialogOpen: boolean;
  isNewLegalEntity: boolean;
  isNewSubEntity: boolean;
  legalEntityDetails: LegalEntitiesInterface;
  onLegalEntitiesEditDialogClose?: (newOptionsCreated: NewlyAddedOptionsType) => void;
  onLegalEntitiesEditDialogSave?: () => void;
}

type LegalEntityStateNamesType = {
  ENTITY_ACTIVITY_LOGS: string;
  ENTITIES_LISTING: string;
};

export const LegalEntityStateNames: LegalEntityStateNamesType = {
  ENTITY_ACTIVITY_LOGS: 'legalEntities.overview.activity_logs',
  ENTITIES_LISTING: CONFIG.states.LEGAL_ENTITIES,
};
