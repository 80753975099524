import {
  BigidAdvancedToolbarFilterUnion,
  AdvancedToolbarOverrideValue,
  BigidDropdownOption,
} from '@bigid-ui/components';
import { SavedFilter } from './toolbar/actions/SaveQuery/saveQueryService';
import { DataCatalogObjectType } from '../DataCatalog/DataCatalogService';

export enum AggregationType {
  DATA_SOURCE_NAME = 'source',
  DATA_SOURCE_EXTENDED = 'sourceExtended',
  DATA_SOURCE_TYPE = 'source.type',
  DATA_SOURCE_LOCATION = 'source.location',
  DATA_SOURCE_CARDINALITY = 'sourceCardinality',
  OBJECT_OWNER = 'owner',
  OBJECT_STATUS = 'objectStatus',
  OBJECT_SCAN_STATUS = 'objectScanStatus',
  FILE_TYPE = 'fileType',
  DATA_FORMAT = 'dataFormat',
  APPLICATION = 'application',
  TAGS = 'tags',
  ATTRIBUTE_NAME = 'attribute',
  ATTRIBUTE_CATEGORY = 'category',
  ATTRIBUTE_CATEGORY_EXTENDED = 'categoryExtended',
  ATTRIBUTE_TYPE = 'attributeType',
  ATTRIBUTE_CARDINALITY = 'attributeCardinality',
  HAS_DUPLICATES = 'duplicateFiles',
  HAS_FINDINGS = 'findings',
  HAS_OPEN_ACCESS = 'openAccess',
  VIOLATED_POLICY = 'violatedPolicies',
  SENSITIVITY = 'sensitivity',
  SENSITIVITY_FILTER = 'sensitivityFilter',
  CLOUD_TYPE = 'cloudType',
  SCANNED_VOLUME = 'scannedVolume',
  TOTAL_VOLUME = 'totalSizeInBytes',
  VIOLATED_POLICY_CARDINALITY = 'violatedPoliciesCardinality',
  CONTAINER_NAME = 'containerName',
  RISK = 'riskFilter',
  ACCESS_TYPE_FILTER = 'accessTypeFilter',
}

export enum NonAggregationType {
  SCAN_DATE = 'scanDate',
  LAST_ACCESS_DATE = 'last_opened',
  MODIFIED_DATE = 'modified_date',
}

export enum AggregationFilterOperand {
  DATA_SOURCE_NAME = 'system',
  DATA_SOURCE_TYPE = 'source.type',
  DATA_SOURCE_LOCATION = 'source.location',
  SCAN_DATE = 'scanDate',
  OBJECT_OWNER = 'owner',
  CLOUD_TYPE = 'source.type',
  FILE_TYPE = 'fileType',
  DATA_FORMAT = 'scanner_type_group',
  APPLICATION = 'application',
  TAGS = 'catalog_tag',
  ATTRIBUTE_CATEGORY = 'field',
  ATTRIBUTE_NAME = 'field',
  ATTRIBUTE_TYPE = 'attribute_details.type',
  HAS_DUPLICATES = 'has_duplicates',
  HAS_OPEN_ACCESS = 'metadata.access_rights',
  SENSITIVITY_FILTER = 'catalog_tag.system.sensitivityClassification',
  POLICY = 'policyName',
  OBJECT_STATUS = 'contains_pi',
  OBJECT_SCAN_STATUS = 'scanStatus',
  CONTAINER_NAME = 'containerName',
  LAST_ACCESS_DATE = 'last_opened',
  MODIFIED_DATE = 'modified_date',
  RISK = 'catalog_tag.system.risk.riskGroup',
  ACCESS_TYPE_FILTER = 'catalog_tag',
}

export type AggregationFilterConfig = Omit<BigidAdvancedToolbarFilterUnion, 'asyncOptionsFetch' | 'title' | 'value'> & {
  hasNestedItems?: boolean;
  isBoolean?: boolean;
  getUserSearchQueryPreprocessed?: (value: string) => string;
  getFilterOptionsPreprocessed?: (options: BigidDropdownOption[]) => BigidDropdownOption[];
  leftOperandComposerFunc?: (filter: BigidAdvancedToolbarFilterUnion) => string;
  parseFilterToQueryFunc?: (filter: BigidAdvancedToolbarFilterUnion[]) => string;
};

export enum RiskAggregationItem {
  LOW = 'low',
  MEDIUM = 'medium',
  HIGH = 'high',
}

export enum SensitivityClassificationAggregationItem {
  CONFIDENTIAL = 'Confidential',
  RESTRICTED = 'Restricted',
  INTERNAL_USE = 'Internal Use',
  PUBLIC = 'Public',
}

export enum DataFormatAggregationItem {
  STRUCTURED = 'structured',
  UNSTRUCTURED = 'unstructured',
  EMAIL = 'email',
  OTHER = 'other',
}

export enum ObjectStatusAggregationItem {
  COMPLETED = 'Completed',
  FAILED = 'Failed',
  METADATA_COMPLETED = 'MetadataCompleted',
  IN_PROGRESS = 'InProgress',
}

export enum AggregationPossesionIdentifier {
  WITH = 'with',
  WITHOUT = 'without',
}

export type AggregationItemName =
  | RiskAggregationItem
  | SensitivityClassificationAggregationItem
  | AggregationPossesionIdentifier
  | DataFormatAggregationItem
  | string;

export type AggregationItemBase = {
  aggItemName?: AggregationItemName;
  aggItemGroup?: string;
  findings?: number;
  docCount?: number;
  groupDocCount?: number;
};

export type SourcesGridAggregationItem = AggregationItemBase & {
  sourceType: string;
  application: string;
  attributeCount: number;
  size: number;
  sourceLocation: string;
};

export type SourceCategory = {
  name: string;
  color: string;
};

export type SourcesExtendedAggregationItem = SourcesGridAggregationItem & {
  attributeNames: string[];
  tagNames: string[];
  applicationNames: string[];
  categoryNames: string[];
  categories?: SourceCategory[]; //NOTE: temporal solution to mimic colors
};

export type AttributesGridAggregationItemOtherCategory = 'Other';

export type AttributesGridAggregationItem = AggregationItemBase & {
  friendlyName: string;
  dataSourceCount: number;
  categories: (string | AttributesGridAggregationItemOtherCategory)[];
};

export const aggregationIrrelevantNumericFieldIdentifier = -1;

export type Aggregation<AggrItem = AggregationItemBase> = {
  aggName: AggregationType;
  aggData: AggrItem[];
  aggTotal: number;
};

export type AggregationItemLookupParams = {
  name?: AggregationItemName | string;
  value: keyof Pick<AggregationItemBase, 'docCount' | 'findings'>;
};

export type CatalogDiscoveryPreferences = {
  savedFilters?: SavedFilter[]; //NOTE: a temporal solution to mimic SavedQuery until BIQL parser is ready
  filter?: AdvancedToolbarOverrideValue[];
  isOnboardingAcknowledged?: boolean;
};

export enum CatalogDiscoveryGuidedTourStatus {
  NOT_STARTED = 'NOT_STARTED',
  STARTED_AUTOMATICALLY = 'STARTED_AUTOMATICALLY',
  STARTED_MANUALLY = 'STARTED_MANUALLY',
}

export enum ObjectPreviewEntityPossesionModified {
  YES = 'Yes',
  NO = 'No',
}

export enum ObjectPreviewEntityType {
  TABLE = 'rdb',
  TABLE_VIEW = 'rdb-view',
  FILE = 'file',
  STRUCTURED_LEAF = 'STRUCTURED-LEAF_DATA_OBJECT',
  STRUCTURED_FILE_LEAF = 'STRUCTURED_FILE-LEAF_DATA_OBJECT',
  UNSTRUCTURED_LEAF = 'UNSTRUCTURED-LEAF_DATA_OBJECT',
  PARTITIONED_TABLE_LEAF = 'PARTITIONED_TABLE-LEAF_DATA_OBJECT',
  APP_LEAF = 'APP-LEAF_DATA_OBJECT',
}

export interface ObjectPreviewEntity {
  id: string | number;
  type: string;
  owner?: string;
  objectName: string;
  containerName?: string;
  objectType?: ObjectPreviewEntityType;
  fullObjectName: string;
  open_access: ObjectPreviewEntityPossesionModified;
  has_duplicates: ObjectPreviewEntityPossesionModified;
  extendedObjectType?: DataCatalogObjectType;
}
