import React, { FC, PropsWithChildren } from 'react';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles({
  wrapper: {
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
    maxWidth: '1050px',
    margin: '0 auto',
  },
});

export const DashboardCommon: FC<PropsWithChildren> = ({ children }) => {
  const { wrapper } = useStyles({});

  return <div className={wrapper}>{children}</div>;
};
