import React, { FC, useCallback, useState, useEffect, memo } from 'react';
import { BigidSkeletonGenerator } from '@bigid-ui/components';
import { generateDataAid, generateGuidedTourId } from '@bigid-ui/utils';
import { UseCatalogDiscoveryResponse } from '../../useCatalogDiscovery';
import { PieChartWidget, PieChartWidgetSeries, PieChartWidgetProps } from '../../widgets/PieChartWidget/PieChartWidget';
import { getAggregatedData, GetAggregatedDataPayload, GetAggregatedDataResponse } from '../../catalogDiscoveryService';
import {
  AggregationItemBase,
  AggregationType,
  SensitivityClassificationAggregationItem,
} from '../../catalogDiscoveryTypes';
import { getPieChartWidgetTotal, getSensitivityPieChartData } from '../../utils/widgets';
import { PieChartWidgetWrapper } from '../../utils/PieChartWidgetWrapper';
import { useLocalTranslation } from './translations';
import { useFetchDataCancelable } from '../../config/useFetchDataCancelable';
import { pieChartWidgetSkeletonConfig } from '../../config/skeleton';
import { SensitivityPieChartTooltip } from './SensitivityPieChartTooltip';
import { CatalogDiscoveryWidget } from '../../config/widgets';

export interface SensitivityPieChartProps
  extends Pick<UseCatalogDiscoveryResponse, 'query' | 'filter'>,
    Pick<PieChartWidgetProps, 'size' | 'width' | 'legendOffset'> {
  dataAid?: string;
  dataTourId?: string;
  isPageInitialised: boolean;
  onWidgetFilterChange?: UseCatalogDiscoveryResponse['onWidgetFilterChange'];
  onDataFetchStatusChange?: UseCatalogDiscoveryResponse['onDataFetchStatusChange'];
}

export const SensitivityPieChart: FC<SensitivityPieChartProps> = memo(
  ({
    dataAid = 'SensitivityPieChart',
    dataTourId = 'SensitivityPieChart',
    query,
    filter,
    onWidgetFilterChange,
    onDataFetchStatusChange,
    isPageInitialised,
    size = 'xl',
    width = '100%',
  }) => {
    const { fetchSensitivityCancelable } = useFetchDataCancelable();
    const { t } = useLocalTranslation();
    const [chartData, setChartData] = useState<PieChartWidgetSeries[]>(
      getSensitivityPieChartData({ aggregationData: [], aggregationType: AggregationType.SENSITIVITY }),
    );
    const [chartTotal, setChartTotal] = useState<number>(0);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const fetchAggregationData = useCallback(async () => {
      let aggregationsWithNoFilter: AggregationItemBase[] = [];
      try {
        const payload: GetAggregatedDataPayload = {
          filter: query,
          aggregations: [
            {
              aggName: AggregationType.SENSITIVITY,
              isTotalRequired: true,
            },
          ],
        };

        setIsLoading(true);
        onDataFetchStatusChange?.(CatalogDiscoveryWidget.SENSITIVITY_PIE_CHART, true);

        const { aggregations } = await fetchSensitivityCancelable(
          getAggregatedData(payload) as Promise<GetAggregatedDataResponse>,
        );

        if (query) {
          const payload: GetAggregatedDataPayload = {
            filter: null,
            aggregations: [
              {
                aggName: AggregationType.SENSITIVITY,
                isTotalRequired: true,
              },
            ],
          };
          const { aggregations } = await fetchSensitivityCancelable(
            getAggregatedData(payload) as Promise<GetAggregatedDataResponse>,
          );
          aggregationsWithNoFilter = aggregations?.[0]?.aggData;
        }

        const mergedAggregations = mergeAggregations(aggregations?.[0]?.aggData, aggregationsWithNoFilter);
        const aggItemNames = mergedAggregations.map(item => item.aggItemName);
        const isDefaultOptions = computeIsDefaultOptions(aggItemNames);

        const data = getSensitivityPieChartData({
          aggregationData: mergedAggregations,
          aggregationType: AggregationType.SENSITIVITY,
          filters: filter,
          associatedFilterType: AggregationType.SENSITIVITY_FILTER,
          isDefaultOptions,
        });
        const total = getPieChartWidgetTotal(aggregations?.[0]);

        setChartData(data);
        setChartTotal(total);
      } catch ({ isCanceled, message }) {
        if (!isCanceled) {
          console.error(`An error has occurred: ${message}`);
        }
      } finally {
        setIsLoading(false);
        onDataFetchStatusChange?.(CatalogDiscoveryWidget.SENSITIVITY_PIE_CHART, false);
      }
    }, [filter, onDataFetchStatusChange, query]);

    const handleSectorClick = useCallback(
      (type: string): void => {
        if (type.length > 0) {
          const { aggItem, active } = chartData.find(({ category }) => category === type);
          onWidgetFilterChange?.(AggregationType.SENSITIVITY_FILTER, [aggItem], active);
        }
      },
      [chartData, onWidgetFilterChange],
    );

    const handleLegendItemClick = ({ aggItem, active }: PieChartWidgetSeries): void => {
      onWidgetFilterChange?.(AggregationType.SENSITIVITY_FILTER, [aggItem], active);
    };

    useEffect(() => {
      if (isPageInitialised) {
        fetchAggregationData();
      }
    }, [fetchAggregationData, isPageInitialised]);

    return (
      <PieChartWidgetWrapper dataAid={dataAid}>
        {isPageInitialised ? (
          <PieChartWidget
            dataAid={generateDataAid(dataAid, ['widget'])}
            dataTourId={generateGuidedTourId(dataTourId, ['widget'])}
            entityName={t('title')}
            tooltipText={<SensitivityPieChartTooltip />}
            data={chartData}
            total={chartTotal}
            onSectorClick={onWidgetFilterChange ? handleSectorClick : undefined}
            onLegendItemClick={onWidgetFilterChange ? handleLegendItemClick : undefined}
            isBusy={isLoading}
            size={size}
            width={width}
            legendOffset={chartData.length === 5 ? 15 : chartData.length === 6 ? 30 : 0} // Give more margin top if we have more than 4 options
          />
        ) : (
          <BigidSkeletonGenerator dataAid={generateDataAid(dataAid, ['skeleton'])} {...pieChartWidgetSkeletonConfig} />
        )}
      </PieChartWidgetWrapper>
    );
  },
  (prevProps, newProps) =>
    prevProps.query === newProps.query && prevProps.isPageInitialised === newProps.isPageInitialised,
);

function mergeAggregations(a: AggregationItemBase[], b: AggregationItemBase[]) {
  const result = [...a];
  const aggItemNames: string[] = [];
  a.forEach(aggData => {
    aggItemNames.push(aggData.aggItemName);
  });

  b.forEach(aggData => {
    if (!aggItemNames.includes(aggData.aggItemName)) {
      result.push({ ...aggData, docCount: 0 });
    }
  });

  return result;
}

function computeIsDefaultOptions(strings: string[]): boolean {
  const sensitivityDefaultValues = Object.values(SensitivityClassificationAggregationItem) as string[];

  for (const str of strings) {
    if (!sensitivityDefaultValues.includes(str)) {
      return false;
    }
  }
  return true;
}
