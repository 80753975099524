import React, { FC, memo, useCallback, useState, useEffect } from 'react';
import {
  BigidFieldRenderProps,
  compareObjectsExceptFunctions,
  BigidLoader,
  BigidFormFieldSideSelect,
  BigidSelectOption,
  BigidBody1,
  BigidLink,
} from '@bigid-ui/components';
import { PROXIES_PERMISSIONS } from '@bigid/permissions';
import { isPermitted } from '../../../../services/userPermissionsService';
import { $state } from '../../../../services/angularServices';
import makeStyles from '@mui/styles/makeStyles';
import { getProxies } from '../../../../services/proxiesService';
import { getApplicationPreference } from '../../../../services/appPreferencesService';
export interface UseProxyOptions {
  options: BigidSelectOption[];
  isLoading: boolean;
  value: BigidSelectOption[];
}
const DEFAULT_PROXY_OPTIONS_STATE: UseProxyOptions = {
  options: [],
  isLoading: true,
  value: [],
};
export const useProxyOptions = (canViewProxy: boolean, selectedProxy: BigidSelectOption[] = []): UseProxyOptions => {
  const [{ options, value, isLoading }, setState] = useState<UseProxyOptions>(DEFAULT_PROXY_OPTIONS_STATE);
  const updateOptions = useCallback(async () => {
    try {
      const proxies = canViewProxy ? (await getProxies()).data : [];
      const selectedProxyId = selectedProxy[0]?.value || '';
      const [value, options] = proxies.reduce(
        ([values, options], { name: label, _id: value }) => {
          const option = {
            label,
            value,
          };
          return [
            [...values, ...(selectedProxyId === value ? [option] : [])],
            [...options, option],
          ];
        },
        [
          [],
          [
            {
              value: '',
              label: '',
            },
          ],
        ],
      );
      setState({
        options,
        value,
        isLoading: false,
      });
    } catch (e) {
      setState({
        ...DEFAULT_PROXY_OPTIONS_STATE,
        isLoading: false,
      });
      console.error(e);
      throw new Error('Error getting proxies');
    }
  }, [canViewProxy, selectedProxy]);
  useEffect(() => {
    updateOptions();
  }, [updateOptions]);
  return { options, isLoading, value };
};
const useStyles = makeStyles({ noProxyText: { marginTop: 8, marginLeft: 8 } });
export const FormProxyField: FC<BigidFieldRenderProps> = memo(props => {
  const { noProxyText } = useStyles();
  const { value: selectedProxy, misc } = props;
  const isHidden = !getApplicationPreference('ENABLE_PROXIES') || Boolean(misc?.hidden);
  const canViewProxies = isPermitted(PROXIES_PERMISSIONS.READ.name);
  const { isLoading, options, value } = useProxyOptions(canViewProxies, selectedProxy);
  const tooltipText = canViewProxies
    ? props.tooltipText
    : `You don't have the required permissions to view this field, please contact the administrator to gain access`;
  if (isLoading) {
    return <BigidLoader />;
  }
  return isHidden ? null : canViewProxies && options.length <= 1 ? (
    <BigidBody1 className={noProxyText}>
      {`No Proxies defined, use the `}
      <BigidLink onClick={() => $state.go(`proxies`)} text={'Proxies'} />
      {' page to create one'}
    </BigidBody1>
  ) : (
    <BigidFormFieldSideSelect
      {...props}
      misc={{ ...props.misc, isMulti: false }}
      options={options}
      value={value}
      disabled={props.disabled || !canViewProxies}
      tooltipText={tooltipText}
      isClearable={true}
    />
  );
}, compareObjectsExceptFunctions);
FormProxyField.displayName = 'FormProxyField';
