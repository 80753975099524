import './cyberArk.scss';
import { CredentialTypes } from '../credentials/credentials.component.js';
import './cyberArkService.js';
import template from './cyberArkModal.html';
import { module } from 'angular';
import { appsLicenseService } from '../../react/services/appsLicenseService';
import { APPLICATIONS_PERMISSIONS } from '@bigid/permissions';
import { isPermitted } from '../../react/services/userPermissionsService';
import { getApplicationPreference } from '../../react/services/appPreferencesService';
import { encodeDecodeURIContent } from '../../react/views/CredentialProviders/utils';
import { ConversionURIContentType } from '../../react/views/CredentialProviders/types';
const app = module('app');

export const CERT_MIME_TYPE = 'application/x-pkcs12';
export const CERT_FILE_FIELD_NAME = 'cert';
export const MULTI_PROVIDERS_FLAG = 'ENABLE_UNIFIED_VAULTS';

function cyberArkConfigController($scope, $timeout, cyberArkService, notificationService, $translate) {
  'ngInject';
  const CYBERARK_TYPE = CredentialTypes.CyberArk;
  const ID_FIELD_NAME = '_id';
  const TYPE_FIELD_NAME = 'type';
  const CERT_PASS_FIELD_NAME = 'certPassword';
  const SELF_SIGNED_FIELD_NAME = 'trustSelfSigned';
  const CUSTOM_CONNECTIVITY_URI = 'useCustomConnectivityURI';
  const getPreferenceFlag = flag => {
    return getApplicationPreference(flag) === true || getApplicationPreference(flag) === 'true';
  };

  /**
   * @ngdoc component
   * @name cyberArkConfig
   */
  class CyberArkConfigComponent {
    constructor() {
      this.data = undefined;
      this.file = undefined;
      this.fileUploaderConfig = undefined;
      this.formData = undefined;
      this.cert = undefined;
      this.subTitleText = undefined;
      this.testConnectionStatus = undefined;
      this.customConnectivityUriChecked = false;

      this.isLoading = false;
    }

    $onInit() {
      appsLicenseService.showAppExpirationNotification('cyberArk');
      this.setSubTitle();
      this.initFormData();
      if (getPreferenceFlag(MULTI_PROVIDERS_FLAG)) {
        if (this.provider) {
          this.data = encodeDecodeURIContent(ConversionURIContentType.DECODED, this.provider);
          this.updateFormData();
        }
      } else {
        this.loadData()
          .then(data => {
            this.data = data;
            this.updateFormData();
          })
          .catch(() => {
            this.isLoading = true;
            // if we can't load data on init, we should close the dialog cause we must have
            // API response, to decide whether it is a create-new or edit mode
            $timeout(() => this.onCloseClick(), 500);
          });
      }
      this.isSavePermitted = this.getValueFromData(ID_FIELD_NAME)
        ? isPermitted(APPLICATIONS_PERMISSIONS.EDIT_CYBERARK.name)
        : isPermitted(APPLICATIONS_PERMISSIONS.CREATE_CYBERARK.name);
      this.isTestConnectionPermitted = isPermitted(APPLICATIONS_PERMISSIONS.TEST_CONNECTION_CYBERARK.name);
    }

    loadData() {
      this.isLoading = true;
      return cyberArkService
        .getCyberArkConfigItem()
        .then(data => {
          if (data.results && data.results.length) {
            return data.results[0];
          }
          return {};
        })
        .catch(err => {
          const errMsg = `Failed to load CyberArk config.`;
          window.console.error(errMsg, err);
          notificationService.error(errMsg);
          throw err;
        })
        .finally(() => (this.isLoading = false));
    }

    initFormData() {
      this.fileUploaderConfig = {
        formData: true,
        errorsList: true,
        replace: true,
        filesList: true,
        multiple: false,
        limit: 1,
        mode: 'drop',
        maxSize: '256KB',
        accept: CERT_MIME_TYPE,
        permitted: '.p12,.pfx',
        onFileAdded: files => this.onFileSelected(files),
        onFileRemoved: () => this.onFileRemoved(),
        data: undefined,
      };

      this.formData = [
        { type: 'hidden', name: ID_FIELD_NAME, apiField: true, isRequired: false },
        { type: 'text', name: 'name', label: 'Connection Name', apiField: true },
        { type: 'text', name: 'appId', label: 'Application ID', apiField: true },
        {
          type: 'checkbox',
          name: CUSTOM_CONNECTIVITY_URI,
          label: 'Custom Connectivity URI',
          apiField: true,
          isRequired: false,
          value: false,
        },
        { type: 'text', name: 'url', label: 'API URL', apiField: true },
        {
          type: 'password',
          name: CERT_PASS_FIELD_NAME,
          label: 'Certificate Password',
          apiField: true,
          isRequired: false,
        },
        {
          type: 'checkbox',
          name: SELF_SIGNED_FIELD_NAME,
          label: 'Trust Self Signed',
          apiField: true,
          isRequired: false,
          value: false,
        },
      ].map(field => ({
        value: undefined,
        isRequired: true,
        ...field,
      }));

      this.certInput = {
        value: undefined,
        isRequired: true,
        name: CERT_FILE_FIELD_NAME,
      };
    }

    updateFormData() {
      this.formData
        .filter(({ apiField }) => apiField)
        .forEach(field => {
          field.value = this.getValueFromData(field.name);
        });

      const fileName = this.getValueFromData(CERT_FILE_FIELD_NAME);
      if (fileName) {
        this.certInput.value = fileName;
        this.updateFileUploader(fileName);
      }
    }

    getValueFromData(field) {
      return this.data && this.data[field] !== undefined ? this.data[field] : undefined;
    }

    setSubTitle() {
      this.subTitleText = '';
    }

    getApiDataObject() {
      return this.formData
        .filter(({ apiField, value }) => apiField && value !== undefined)
        .reduce((acc, { name, value }) => Object.assign(acc, { [name]: value }), {
          // always set type
          [TYPE_FIELD_NAME]: CYBERARK_TYPE,
        });
    }

    onSaveClick() {
      if (getPreferenceFlag(MULTI_PROVIDERS_FLAG)) {
        if (this.provider) {
          this.update();
        } else {
          this.create();
        }
      } else {
        if (!this.getValueFromData(ID_FIELD_NAME)) {
          this.create();
        } else {
          this.update();
        }
      }
    }
    onTestConnectionClicked() {
      this.isLoading = true;

      const data = this.getApiDataObject();
      const formData = new FormData();
      formData.append('data', JSON.stringify(data));
      if ($scope.formCtrl[CERT_FILE_FIELD_NAME].$dirty && this.file) {
        formData.append(CERT_FILE_FIELD_NAME, this.file);
      }

      cyberArkService
        .testCyberArkConnection(formData)
        .then(res => {
          this.testConnectionStatus = Boolean(res && res.operationStatus === 'SUCCESS');
          notificationService.success($translate.instant('CREDENTIALS:TEST_CONNECTION:SUCCESS'));
        })
        .catch(err => {
          this.testConnectionStatus = false;
          window.console.error(err);
          notificationService.error(`${$translate.instant('CREDENTIALS:TEST_CONNECTION:FAILURE')}`);
        })
        .finally(() => (this.isLoading = false));
    }

    create() {
      const data = this.getApiDataObject();

      const formData = new FormData();
      formData.append('data', JSON.stringify(data));
      formData.append(CERT_FILE_FIELD_NAME, this.file);

      this.isLoading = true;
      cyberArkService
        .createCyberArkConfigItem(formData)
        .then(result => {
          const { id } = result;
          if (id) {
            notificationService.success(`Successfully Saved.`);
          }
          this.data = { ...data, ...{ [ID_FIELD_NAME]: id } };
          this.updateFormData();
          this.resetFormCtrlState();
          this.isSavePermitted = isPermitted(APPLICATIONS_PERMISSIONS.EDIT_CYBERARK.name);
        })
        .catch(err => {
          notificationService.error(`Failed to save changes`);
          window.console.error(`Failed to save CyberArk config item`, err);
          throw err;
        })
        .finally(() => (this.isLoading = false));
    }

    update() {
      const id = this.getValueFromData(ID_FIELD_NAME);
      const removeFields = [ID_FIELD_NAME, TYPE_FIELD_NAME];
      const data = this.getApiDataObject();
      const filteredObject = Object.entries(data)
        .filter(([fieldName]) => !removeFields.includes(fieldName))
        .filter(([fieldName]) => $scope.formCtrl[fieldName].$dirty)
        .reduce((acc, [key, val]) => Object.assign(acc, { [key]: val }), {});

      const formData = new FormData();
      formData.append('data', JSON.stringify(filteredObject));

      if ($scope.formCtrl[CERT_FILE_FIELD_NAME].$dirty && this.file) {
        formData.append(CERT_FILE_FIELD_NAME, this.file);
      }

      this.isLoading = true;
      cyberArkService
        .updateCyberArkConfigItem(id, formData)
        .then(result => {
          const { ok } = result;
          if (!ok) {
            throw new Error(`server error, 'ok' value is '${ok}'`);
          }
          notificationService.success(`Successfully Saved.`);
          this.resetFormCtrlState();
        })
        .catch(err => {
          notificationService.error(`Failed to save changes`);
          window.console.error(`Failed to save CyberArk config item`, err);
          throw err;
        })
        .finally(() => (this.isLoading = false));
    }

    resetFormCtrlState() {
      $scope.formCtrl.$setPristine();
      $scope.formCtrl.$setUntouched();
    }

    isSaveEnabled() {
      return $scope.formCtrl.$valid && !$scope.formCtrl.$pristine;
    }

    isTestConnectionEnabled() {
      return $scope.formCtrl.$valid;
    }

    onFileSelected(file) {
      this.file = file;
      this.certInput.value = this.file;
      $scope.formCtrl[CERT_FILE_FIELD_NAME].$setDirty();
    }

    onFileRemoved() {
      this.file = undefined;
      this.certInput.value = undefined;
      $scope.formCtrl[CERT_FILE_FIELD_NAME].$setDirty();
    }

    updateFileUploader(name) {
      const dummyFile = {
        name,
        isDummyFile: true,
        type: CERT_MIME_TYPE,
        attachmentId: `file__placeholder__for__${name}`,
      };
      this.fileUploaderConfig.data = [dummyFile];
    }
  }

  return new CyberArkConfigComponent();
}

function dialogService($uibModal, BIGID_MODAL_CLASSES) {
  'ngInject';

  this.showDialog = () => {
    $uibModal.open({
      animation: true,
      backdrop: 'static',
      windowClass: `${BIGID_MODAL_CLASSES.centred} cyberark-config__modal`,
      template: '<cyber-ark-config on-close-click="$dismiss()"/>',
    });
  };
}

app.component('cyberArkConfig', {
  template,
  controller: cyberArkConfigController,
  bindings: {
    onCloseClick: '&',
    provider: '<',
  },
});

app.service('cyberArkConfigDialogService', dialogService);
