import { BigidGridRow } from '@bigid-ui/grid';
import { DetailedObjectType } from '../DataCatalogService';

export enum ObjectDiffType {
  SCAN = 'SCAN',
  ATTRIBUTES = 'ATTRIBUTES',
  OBJECT_OWNER = 'OBJECT_OWNER',
  OBJECT_ISSUE = 'OBJECT_ISSUE',
  OBJECT_DELETED = 'OBJECT_DELETED',
}

export interface ObjectDiffEntity {
  objectName: string;
  objectDiffType: ObjectDiffType;
  subject: string;
  fullyQualifiedName: string;
  detailedObjectType: DetailedObjectType;
}

export interface ObjectDiffModel extends BigidGridRow {
  objectName: string;
  objectDiffType: ObjectDiffType;
  subject: string;
  fullyQualifiedName: string;
  detailedObjectType: DetailedObjectType;
  createdAt: string;
}
