import { module } from 'angular';
import { DEFAULT_TOGGLE_VALUE } from '../../react/views/DSAR/SarInfo/ExtendedInfo/ExtendedInfo';
import { extendedInfoService } from '../../react/views/DSAR/SarInfo/ExtendedInfo/extendedInfoService';
import { getApplicationPreference } from '../../react/services/appPreferencesService';
import { format } from 'date-fns-tz';

const REPORT_CONFIG_TYPES = {
  attributes: 'personal_info_attributes',
  personalInfo: 'personal_info',
};

const app = module('app');

app.factory(
  'personalInfoReportMakerService',
  function (
    $translate,
    notificationService,
    personalInfoService,
    generalInfoService,
    lazyLoadService,
    subjectAccessRequestService,
  ) {
    'ngInject';

    const DSAR_PDF_REPORT_FILTER_PERSONAL_INFO = getApplicationPreference('DSAR_PDF_REPORT_FILTER_PERSONAL_INFO');
    const REGULAR_REPORT = 'REGULAR_REPORT';
    const DATA_SUBJECT_REPORT = 'DATA_SUBJECT_REPORT';
    const APP_INFORMATION_REPORT = 'APP_INFORMATION_REPORT';
    const isDsarReportsGrouped = () => {
      return getApplicationPreference('IS_DSAR_REPORTS_GROUPED');
    };

    const styles = {
      header: {
        bold: true,
        color: '#471c56',
        fontSize: 26,
        margin: [0, 0, 0, 15],
        width: 300,
      },
      subHeader: {
        color: '#717d89',
        fontSize: 12,
        margin: [0, 6, 0, 0],
      },
      selectedSubHeader: {
        color: '#471c56',
        fontSize: 12,
        background: '#ECE8EE',
        margin: [0, 6, 0, 0],
      },
      pageSubTitle: {
        bold: true,
        color: '#2e2d2d',
        fontSize: 14,
        margin: [20, 25, 0, 5],
      },
      propertyRowRelatedData: {
        fontSize: 10,
        color: '#232424',
        margin: [20, 0, 0, 20],
      },
      dateRowData: {
        fontSize: 10,
        color: '#232424',
        margin: [20, 0, 0, 25],
      },
      propertyAddressRowData: {
        fontSize: 10,
        color: '#232424',
        margin: [20, 0, 0, 15],
      },
      propertyCompanyRowData: {
        fontSize: 10,
        color: '#232424',
        margin: [20, 0, 0, 25],
      },
      propertySubNameRowData: {
        fontSize: 10,
        color: '#232424',
        margin: [20, 0, 25, 10],
      },
      bold: {
        bold: true,
      },
      propertyCompanySubRowData: {
        fontSize: 10,
        color: '#232424',
        margin: [20, 0, 0, 10],
      },
      propertyPersonalInfoRowData: {
        fontSize: 10,
        color: '#232424',
        margin: [20, 0, 0, 5],
      },
      tableExample: {
        fontSize: 9,
        color: '#232424',
      },
      tableHeader: {
        bold: true,
        color: '#471c56',
      },
      margin: {
        margin: [20, 20, 20, 20],
      },
      generalInfoSectionHeader: {
        bold: true,
        fontSize: 10,
        color: '#232424',
        margin: [20, 10, 0, 10],
      },
    };

    function getPageTitle(userName) {
      const userNameWithHyphen = userName ? `- ${userName}` : '';
      return {
        text: `Personal Info Report ${userNameWithHyphen}`,
        style: 'header',
      };
    }

    function isValidAttrWithValues(infoObj, attName) {
      return (
        infoObj[attName] &&
        Array.isArray(infoObj[attName].values) &&
        // for some reason we also need to check that this array is not empty
        // otherwise we'll have Purposes without Values in the report
        infoObj[attName].values.length
      );
    }

    function getValues(attrValues) {
      if (Array.isArray(attrValues)) {
        return attrValues
          .filter(val => val)
          .map(val => val.trim())
          .join('\n');
      }
      return '';
    }

    function getPurposes(personalInfoAttr) {
      if (personalInfoAttr && Array.isArray(personalInfoAttr.purposes)) {
        return personalInfoAttr.purposes.filter(val => val).join('\n');
      }
      return '';
    }

    function isFieldConfigurationEnabled(report_configuration, name) {
      const config =
        Array.isArray(report_configuration) && report_configuration.find(config => config && config.name === name);
      if (config && typeof config.is_enabled === 'boolean') {
        return config.is_enabled;
      }
      return DEFAULT_TOGGLE_VALUE;
    }

    function getAttrSectionData(attrName, personalInfo, reportConfig) {
      // const attrName = Object.keys(personalInfo).find(prop => prop.toLowerCase().includes('name'));
      if (!attrName || !isValidAttrWithValues(personalInfo, attrName)) {
        return '';
      }

      const attributeData = personalInfo[attrName];
      if (!isFieldConfigurationEnabled(reportConfig, attributeData.friendlyName)) {
        return '';
      }

      const friendlyName = attributeData.friendlyName || attrName;
      const attrValues = getValues(attributeData.values);
      const attrPurposes = getPurposes(personalInfo[attrName]);
      return getAttrSectionDocDef(friendlyName, attrValues, attrPurposes);
    }

    /**
     * @param name
     * @param values {string[]|[]} - can be string or string[]
     * @param purposes {string[]|[]} - can be string or string[]
     */
    function getAttrSectionDocDef(name, values, purposes) {
      const hidePurpose = getApplicationPreference('HIDE_ATTRIBUTE_PURPOSE_OF_USE');
      const valuesLines = []
        .concat(values)
        .map(a => [a, '\n'])
        .flat();
      const purposesLines = []
        .concat(purposes)
        .map(a => [a, '\n'])
        .flat();
      const displayAttrSectionDoc = [];
      displayAttrSectionDoc.push({
        columns: [
          {
            width: 120,
            text: name,
            style: 'propertyPersonalInfoRowData',
          },
          {
            width: '*',
            text: valuesLines,
            style: 'propertyPersonalInfoRowData',
          },
        ],
      });
      if (!hidePurpose) {
        displayAttrSectionDoc.push({
          columns: [
            {
              width: 120,
              text: 'Purpose',
              style: 'propertyRowRelatedData',
            },
            {
              width: '*',
              text: purposesLines,
              style: 'propertyCompanyRowData',
            },
          ],
        });
      }
      return displayAttrSectionDoc;
    }

    const getSubTitleSpace = () => {
      return {
        text: '',
        style: 'pageSubTitle',
      };
    };

    const validateCompanyAttribute = (generalInfoProperty, attrName) => {
      if (generalInfoProperty && generalInfoProperty[attrName]) {
        return true;
      }
      return false;
    };

    const getCompanyName = generalInfo => {
      return {
        text: validateCompanyAttribute(generalInfo.companyInfo, 'name') ? generalInfo.companyInfo.name : '',
        style: 'pageSubTitle',
      };
    };

    const getCurrentDate = () => {
      const curDate = format(new Date(), 'hh:mm d/M/yyyy');
      return {
        text: `Up Date for: ${curDate}`,
        style: 'dateRowData',
      };
    };

    const getUserConsentData = (consentData, report_configuration) => {
      if (!Array.isArray(consentData) || !consentData.length) {
        return '';
      }
      if (!isFieldConfigurationEnabled(report_configuration, 'consent')) {
        return '';
      }
      return [
        {
          columns: [
            {
              width: 70,
              text: '',
              style: ['propertyCompanyRowData', 'bold', 'margin'],
            },
          ],
        },
        {
          columns: [
            {
              width: 70,
              text: 'Consent:',
              style: ['propertyCompanyRowData', 'bold'],
            },
          ],
        },
        {
          columns: [
            {
              style: 'tableExample',
              table: {
                body: getConsentDataRows(consentData),
              },
              layout: 'lightHorizontalLines',
            },
          ],
        },
      ];
    };

    const getConsentDataRows = consentData => {
      const consentRecordsArr = [
        // table headers
        [
          { text: 'Source Name', style: 'tableHeader' },
          { text: 'Timestamp', style: 'tableHeader' },
          { text: 'Type of Consent', style: 'tableHeader' },
          { text: 'Status', style: 'tableHeader' },
          { text: 'Agreement Version', style: 'tableHeader' },
          { text: 'Agreement', style: 'tableHeader' },
          { text: 'Application ID', style: 'tableHeader' },
        ],
        // table rows
        ...consentData.map(item => {
          return [
            'name',
            'timestamp',
            'type_of_consent',
            'status',
            'agreement_version',
            'agreement',
            'application_id',
          ].map(prop => {
            const value = item[prop];
            return value === undefined || value === null ? '' : value;
          });
        }),
      ];

      return consentRecordsArr;
    };

    const getUserDataSourcesData = (dataSourcesData, report_configuration) => {
      if (!Array.isArray(dataSourcesData) || !dataSourcesData.length) {
        return '';
      }
      if (!isFieldConfigurationEnabled(report_configuration, 'data_sources')) {
        return '';
      }
      return [
        {
          columns: [
            {
              width: 120,
              text: 'Data Sources:',
              style: ['propertyCompanyRowData', 'bold', 'margin'],
            },
          ],
        },
        {
          columns: [
            {
              style: 'tableExample',
              text: 'Data Sources:',
              table: {
                widths: [200, 150, 150],
                body: getUserDataSourcesRows(dataSourcesData),
              },
              layout: 'lightHorizontalLines',
            },
          ],
        },
      ];
    };

    const getUserDataSourcesRows = dataSourcesData => {
      const dataSourcesRecordsArr = [];
      dataSourcesRecordsArr.push([
        { text: 'Data Source', style: 'tableHeader' },
        {
          text: 'Type',
          style: 'tableHeader',
        },
        { text: 'Location', style: 'tableHeader' },
      ]);
      dataSourcesData.forEach(item => dataSourcesRecordsArr.push([item.name, item.type, item.location]));
      return dataSourcesRecordsArr;
    };

    const getUserSectionData = (personalInfo, reportConfig) => {
      const attrName = Object.keys(personalInfo).find(prop => prop.toLowerCase().includes('name'));
      return getAttrSectionData(attrName, personalInfo, reportConfig);
    };

    const getPhoneNumber = (personalInfo, reportConfig) => {
      const phoneAttrName = Object.keys(personalInfo).find(prop => prop.toLowerCase().includes('phone'));
      return getAttrSectionData(phoneAttrName, personalInfo, reportConfig);
    };

    const getEmailSectionData = (personalInfo, reportConfig) => {
      const emailAttrName = Object.keys(personalInfo).find(prop => prop.toLowerCase().includes('mail'));
      return getAttrSectionData(emailAttrName, personalInfo, reportConfig);
    };

    const getDisplayedArrData = arrData => {
      let dataToDisplay = '';
      if (Array.isArray(arrData) && arrData.length) {
        arrData.forEach((item, index) => {
          dataToDisplay += `${index + 1}.   ${item} \n`;
        });
      }
      return dataToDisplay.trim();
    };

    const getCompanyInfoSectionData = generalInfo => {
      if (generalInfo.reportConfig?.companyInfo?.isEnabled === false) {
        return [];
      }
      return [
        {
          text: 'Company Information',
          style: 'generalInfoSectionHeader',
        },
        {
          columns: [
            {
              width: 140,
              text: 'Company Address',
              style: 'propertyCompanySubRowData',
            },
            {
              width: '*',
              text: validateCompanyAttribute(generalInfo.companyInfo, 'address') ? generalInfo.companyInfo.address : '',
              style: 'propertyCompanySubRowData',
            },
          ],
        },
        {
          columns: [
            {
              width: 140,
              text: 'Phone Number',
              style: 'propertyCompanyRowData',
            },
            {
              width: '*',
              text: validateCompanyAttribute(generalInfo.companyInfo, 'phoneNumber')
                ? generalInfo.companyInfo.phoneNumber
                : '',
              style: 'propertyCompanyRowData',
            },
          ],
        },
      ];
    };

    const getContactInformation = generalInfo => {
      if (generalInfo.reportConfig?.contactInfo?.isEnabled === false) {
        return [];
      }
      const companyName = validateCompanyAttribute(generalInfo.companyInfo, 'name') ? generalInfo.companyInfo.name : '';
      return [
        {
          text: `Contact Information in ${companyName}`,
          style: 'generalInfoSectionHeader',
        },
        {
          columns: [
            {
              width: 140,
              text: 'Name',
              style: 'propertyCompanySubRowData',
            },
            {
              width: '*',
              text: validateCompanyAttribute(generalInfo.contactInfo, 'name') ? generalInfo.contactInfo.name : '',
              style: 'propertyCompanySubRowData',
            },
          ],
        },
        {
          columns: [
            {
              width: 140,
              text: 'Position',
              style: 'propertyAddressRowData',
            },
            {
              width: '*',
              text: validateCompanyAttribute(generalInfo.contactInfo, 'position')
                ? generalInfo.contactInfo.position
                : '',
              style: 'propertyCompanyRowData',
            },
          ],
        },
      ];
    };

    const getInformationCollection = generalInfo => {
      if (generalInfo.reportConfig?.infoCollection?.isEnabled === false) {
        return '';
      }
      const purposes = generalInfo.infoCollection ? generalInfo.infoCollection.purposes : [];
      const parties = generalInfo.infoCollection ? generalInfo.infoCollection.parties : [];
      const mechanisms = generalInfo.infoCollection ? generalInfo.infoCollection.mechanisms : [];
      return [
        {
          text: 'Information Collection',
          style: 'generalInfoSectionHeader',
        },
        {
          columns: [
            {
              width: 140,
              text: 'Why we collect the data',
              style: 'propertySubNameRowData',
            },
            {
              width: '*',
              text: getDisplayedArrData(purposes),
              style: 'propertyCompanyRowData',
            },
          ],
        },
        {
          columns: [
            {
              width: 140,
              text: 'To which third parties we transfer the data',
              style: 'propertySubNameRowData',
            },
            {
              width: '*',
              text: getDisplayedArrData(parties),
              style: 'propertyCompanyRowData',
            },
          ],
        },
        {
          columns: [
            {
              width: 140,
              text: 'By which mechanisms',
              style: 'propertySubNameRowData',
            },
            {
              width: '*',
              text: getDisplayedArrData(mechanisms),
              style: 'propertyCompanyRowData',
            },
          ],
        },
      ];
    };

    const getKnowYourRights = generalInfo => {
      if (generalInfo.reportConfig?.rights?.isEnabled === false) {
        return [];
      }
      return [
        {
          text: 'Know Your Rights',
          style: 'generalInfoSectionHeader',
        },
        {
          columns: [
            {
              width: '*',
              text: getRightsCompanyAttribute(generalInfo),
              style: 'propertyCompanyRowData',
            },
          ],
        },
      ];
    };

    const getGeoDataTransfer = generalInfo => {
      if (generalInfo.reportConfig?.geoDataTransfer?.isEnabled === false) {
        return '';
      }
      const countries = generalInfo.geoDataTransfer ? generalInfo.geoDataTransfer.countries : [];
      return [
        {
          text: 'Geographical Data Transfer',
          style: 'generalInfoSectionHeader',
        },
        {
          columns: [
            {
              width: 140,
              text: 'Countries outside the EU to which we transfer the data',
              style: 'propertySubNameRowData',
            },
            {
              width: '*',
              text: getDisplayedArrData(countries),
              style: 'propertyCompanyRowData',
            },
          ],
        },
      ];
    };

    const getRequestInfo = (requestId, userName, profileName) => {
      return [
        {
          columns: [
            {
              width: 150,
              text: 'Request ID',
              style: ['propertyCompanyRowData', 'bold'],
            },
            {
              width: 200,
              text: requestId,
              style: 'propertySubNameRowData',
            },
          ],
        },
        {
          columns: [
            {
              width: 150,
              text: 'Display Name',
              style: ['propertyCompanyRowData', 'bold'],
            },
            {
              width: 200,
              text: userName,
              style: 'propertySubNameRowData',
            },
          ],
        },
        {
          columns: [
            {
              width: 150,
              text: 'Profile Name',
              style: ['propertyCompanyRowData', 'bold'],
            },
            {
              width: 200,
              text: profileName,
              style: 'propertySubNameRowData',
            },
          ],
        },
      ];
    };

    const getRightsCompanyAttribute = generalInfo => {
      //The generalInfo.infoCollection.rights is for supporting the old structure
      if (
        generalInfo &&
        ((generalInfo.rights && generalInfo.rights.data_subject_rights) ||
          (generalInfo.infoCollection && generalInfo.infoCollection.rights))
      ) {
        return generalInfo.rights && generalInfo.rights.data_subject_rights
          ? generalInfo.rights.data_subject_rights
          : generalInfo.infoCollection.rights;
      }
      return '';
    };

    const getPersonalInfoData = personalInfo => {
      const personalAndGeneralInfo = {};
      const data = personalInfo && personalInfo.data ? personalInfo.data : {};
      personalAndGeneralInfo.personalInfoAttributes = personalInfoService.aggregateUserPersonalInfo(
        data ? data.attributes : {},
      );
      personalAndGeneralInfo.consent = Array.isArray(data.consent)
        ? personalInfoService.getTop5ConsentsForeachName(data.consent)
        : [];
      personalAndGeneralInfo.data_sources = Array.isArray(data.data_sources) ? data.data_sources : [];
      personalAndGeneralInfo.report_config = Array.isArray(data.report_config) ? data.report_config : {};
      personalAndGeneralInfo.profileName = data.profileName;
      personalAndGeneralInfo.userName = data.userName;
      return personalAndGeneralInfo;
    };

    const getPersonalAndGeneralInfo = async requestId => {
      const personalInfo = await personalInfoService.getUserPersonalInfo(requestId);
      const personalAndGeneralInfo = getPersonalInfoData(personalInfo);
      const generalInfo = await generalInfoService.getUserGeneralInfo(requestId);
      personalAndGeneralInfo.generalInfo = generalInfo && generalInfo.data ? generalInfo.data : {};
      personalAndGeneralInfo.extendedAttributes = await extendedInfoService
        .getExtendedInfo(requestId)
        .then(extendedInfoService.aggregateExtendedAttributes);

      return { personalAndGeneralInfo, personalInfo: personalInfo.data };
    };

    const getPersonalInfoNameEmailPhoneAttributesData = (personalInfoAttributes, reportConfig) => {
      if (DSAR_PDF_REPORT_FILTER_PERSONAL_INFO) {
        return [
          getUserSectionData(personalInfoAttributes, reportConfig),
          getPhoneNumber(personalInfoAttributes, reportConfig),
          getEmailSectionData(personalInfoAttributes, reportConfig),
        ];
      }
      return '';
    };

    const getPersonalInfoAttributesData = (personalInfo, reportConfig) => {
      if (!personalInfo) {
        return [];
      }

      // FEATURE FLAG BDT-7866 :: if DSAR_PDF_REPORT_FILTER_PERSONAL_INFO is true, provide legacy behavior
      const filterAttributes = DSAR_PDF_REPORT_FILTER_PERSONAL_INFO
        ? ([attrName]) => {
            // user name, email & phone should always appear, and in specific order, they are handled separately.
            // so we filter them here (in this function)
            return ['phone', 'name', 'email'].every(val => !attrName.toLowerCase().includes(val));
          }
        : () => true;

      return (
        Object.entries(personalInfo)
          .filter(filterAttributes)
          // filter attributes with empty `values`
          .filter(([, attrData]) => attrData)
          .map(([attrName]) => {
            return getAttrSectionData(attrName, personalInfo, reportConfig);
          })
          // filter out empty results, may happen when disabled in reportConfig
          .filter(result => result)
      );
    };

    const getExtendedInfoDocDefs = attributes => {
      return (attributes || [])
        .filter(attr => attr && attr.isEnabled)
        .map(attr => {
          const { name, values, purposes } = attr;
          return getAttrSectionDocDef(name, values, purposes);
        });
    };

    const getHeader = cellSelected => {
      const fillColors = ['#FFFFFF', '#FFFFFF', '#FFFFFF'];
      fillColors[cellSelected] = '#ECE8EE';
      const elementStyles = ['subHeader', 'subHeader', 'subHeader'];
      elementStyles[cellSelected] = 'selectedSubHeader';

      return {
        table: {
          widths: [75, 75, 75],
          heights: [20, 20, 20],
          alignment: 'center',
          body: [
            [
              {
                border: [false, false, false, false],
                fillColor: fillColors[0],
                text: 'General Info',
                style: elementStyles[0],
              },
              {
                border: [false, false, false, false],
                fillColor: fillColors[1],
                text: 'Personal Info',
                style: elementStyles[1],
              },
              {
                border: [false, false, false, false],
                fillColor: fillColors[2],
                text: 'Extended Info',
                style: elementStyles[2],
              },
            ],
          ],
        },
      };
    };

    const getLine = () => {
      return {
        canvas: [
          {
            type: 'line',
            x1: 0,
            y1: 0,
            x2: 520,
            y2: 0,
            lineWidth: 1,
            lineColor: '#471c56',
          },
        ],
      };
    };

    const getDocDefinition = content => {
      const docDefinition = {
        defaultStyle: lazyLoadService.setReportFont(),
        content,
        styles,
        footer: (currentPage, pageCount) => {
          return {
            text: `Page ${currentPage} of ${pageCount}`,
            alignment: 'right',
            margin: [0, 0, 20, 0],
            color: '#232424',
            fontSize: 10,
          };
        },
      };
      return docDefinition;
    };

    const createContent = (personalAndGeneralInfo, type) => {
      const report_configuration = personalAndGeneralInfo.report_config;
      const report_config_attributes =
        report_configuration.find(({ type }) => type === REPORT_CONFIG_TYPES.attributes) || {};

      const { generalInfo, personalInfoAttributes } = personalAndGeneralInfo;
      let content;
      if (type === REGULAR_REPORT) {
        content = [
          getPageTitle(personalAndGeneralInfo.userName),
          getHeader(0),
          getLine(),
          getCompanyName(generalInfo),
          getCurrentDate(),
          getCompanyInfoSectionData(generalInfo),
          getContactInformation(generalInfo),
          getInformationCollection(generalInfo),
          getKnowYourRights(generalInfo),
          getGeoDataTransfer(generalInfo),
          { text: '', pageBreak: 'after' },
          getPageTitle(personalAndGeneralInfo.userName),
          getHeader(1),
          getLine(),
          getSubTitleSpace(),
          getCurrentDate(),
          getPersonalInfoNameEmailPhoneAttributesData(personalInfoAttributes, report_config_attributes.attributes),
          getPersonalInfoAttributesData(personalInfoAttributes, report_config_attributes.attributes),
          getUserDataSourcesData(personalAndGeneralInfo.data_sources, report_configuration),
          getUserConsentData(personalAndGeneralInfo.consent, report_configuration),
          { text: '', pageBreak: 'after' },
          getPageTitle(personalAndGeneralInfo.userName),
          getHeader(2),
          getLine(),
          getSubTitleSpace(),
          getCurrentDate(),
          getExtendedInfoDocDefs(personalAndGeneralInfo.extendedAttributes),
        ];
      } else if (type === DATA_SUBJECT_REPORT) {
        content = [
          getPageTitle(personalAndGeneralInfo.userName),
          getHeader(0),
          getLine(),
          getCompanyName(generalInfo),
          getCurrentDate(),
          getCompanyInfoSectionData(generalInfo),
          getContactInformation(generalInfo),
          { text: '', pageBreak: 'after' },
          getPageTitle(personalAndGeneralInfo.userName),
          getHeader(1),
          getLine(),
          getSubTitleSpace(),
          getCurrentDate(),
          getPersonalInfoNameEmailPhoneAttributesData(personalInfoAttributes, report_config_attributes.attributes),
          getPersonalInfoAttributesData(personalInfoAttributes, report_config_attributes.attributes),
          { text: '', pageBreak: 'after' },
          getPageTitle(personalAndGeneralInfo.userName),
          getHeader(2),
          getLine(),
          getSubTitleSpace(),
          getCurrentDate(),
          getExtendedInfoDocDefs(personalAndGeneralInfo.extendedAttributes),
        ];
      } else if (type === APP_INFORMATION_REPORT) {
        content = [
          getPageTitle(personalAndGeneralInfo.userName),
          getHeader(0),
          getLine(),
          getCompanyName(generalInfo),
          getCurrentDate(),
          getRequestInfo(
            report_config_attributes.requestId,
            personalAndGeneralInfo.userName,
            personalAndGeneralInfo.profileName,
          ),
          { text: '', pageBreak: 'after' },
          getPageTitle(personalAndGeneralInfo.userName),
          getHeader(1),
          getLine(),
          getSubTitleSpace(),
          getCurrentDate(),
          getPersonalInfoNameEmailPhoneAttributesData(personalInfoAttributes, report_config_attributes.attributes),
          getPersonalInfoAttributesData(personalInfoAttributes, report_config_attributes.attributes),
          getUserDataSourcesData(personalAndGeneralInfo.data_sources, report_configuration),
          { text: '', pageBreak: 'after' },
          getPageTitle(personalAndGeneralInfo.userName),
          getHeader(2),
          getLine(),
          getSubTitleSpace(),
          getCurrentDate(),
          getExtendedInfoDocDefs(personalAndGeneralInfo.extendedAttributes),
        ];
      }

      return content;
    };

    const downloadPdfReport = (docDefinition, fileName) => {
      return lazyLoadService.loadPdfMake().then(
        pdfMake =>
          new Promise(resolve => {
            pdfMake.createPdf(docDefinition).download(fileName, resolve, {});
          }),
      );
    };

    return {
      createPDFReport: async requestId => {
        const { personalInfo, personalAndGeneralInfo } = await getPersonalAndGeneralInfo(requestId);
        const { userId: uniqueId } = personalInfo;
        const fileNameDateString = format(new Date(), 'yyyy-MM-dd');

        if (isDsarReportsGrouped()) {
          // don't remove the idle `timeouts` breaks between the file downloads -> it required by some browsers
          Promise.resolve()
            .then(() => subjectAccessRequestService.getJITCsvFile(requestId))
            .then(() => new Promise(resolve => setTimeout(() => resolve(), 100)))
            .then(() => subjectAccessRequestService.getJITCsvShortReportFile(requestId))
            .then(() => new Promise(resolve => setTimeout(() => resolve(), 100)))
            .then(() => {
              const content = createContent(personalAndGeneralInfo, DATA_SUBJECT_REPORT);
              const docDefinition = getDocDefinition(content);
              return downloadPdfReport(docDefinition, `Data_Subject_Report_${fileNameDateString}.pdf`);
            })
            .then(() => new Promise(resolve => setTimeout(() => resolve(), 50)))
            .then(() => {
              const content = createContent(personalAndGeneralInfo, APP_INFORMATION_REPORT);
              const docDefinition = getDocDefinition(content);
              return downloadPdfReport(
                docDefinition,
                `DSAR_Report_With_Application_Information_${fileNameDateString}.pdf`,
              );
            })
            .catch(err => {
              $translate('REPORT_MAKER:ERROR:FAILED').then(translation => {
                notificationService.error(translation);
              });
              window.console.error(`Failed to create PDF report`, err);
              throw err;
            });
        } else {
          Promise.resolve(createContent(personalAndGeneralInfo, REGULAR_REPORT))
            .then(content => getDocDefinition(content))
            .then(docDefinition =>
              downloadPdfReport(
                docDefinition,
                `Subject Access Report - ${fileNameDateString} - ${uniqueId} - ${requestId}.pdf`,
              ),
            )
            .catch(err => {
              $translate('REPORT_MAKER:ERROR:FAILED').then(translation => {
                notificationService.error(translation);
              });
              window.console.error(`Failed to create PDF report`, err);
              throw err;
            });
        }
      },
    };
  },
);
