import React, { FC, useEffect, useState } from 'react';
import { styled } from '@mui/material';
import {
  AUTO_DISCOVER_PREREQUISITES_CONTENT_ID,
  CloudProvider,
  DiscoveryAuthTypes,
  PREREQUISITES_PLACEMENT_BY_TYPE,
  PrerequisitesPlacement,
} from '../../constants';
import { BigidBody1, BigidHeading6, BigidLink, BigidLoader } from '@bigid-ui/components';
import {
  AutoDiscoveryInfoList,
  AutoDiscoveryInfoListItem,
  AutoDiscoveryInfoListItemSpecial,
  AutoDiscoveryInfoListItemText,
  AutoDiscoveryInfoListOrderedSpecial,
} from '../autoDiscoveryComponents/autoDiscoverInfoStyledComponents';
import { PrerequisitesData, TYPE_TO_PREREQUISITES_CONTENT } from '../../constants/prerequisites';

export const AutoDiscoverWizardPrerequisitesMain = styled('div')<{ isV1Prerequisite?: boolean }>`
  ${({ isV1Prerequisite, theme }) =>
    isV1Prerequisite
      ? `
     background-color: ${theme.palette.bigid.gray150};
  max-width: 536px;
  min-width: 432px;
  padding: 8px 24px 0 16px;
  border-radius: 6px;
  gap: 4px;
    `
      : ''};
  display: flex;
  flex-direction: column;
  animation: start 0.3s forwards;

  @keyframes start {
  0% {
      opacity: 0;
  }
  50% {
      opacity: 0.5;
  }
  100% {
      opacity: 1;
  }
`;

export const AutoDiscoverPrerequisitesIntroduction = styled(BigidBody1)`
  white-space: break-spaces;
`;

export interface AutoDiscoverPrerequisitesContentProps {
  authType: DiscoveryAuthTypes;
  type?: CloudProvider;
}

export const AutoDiscoverPrerequisitesContentSimple: FC<PrerequisitesData> = ({ introduction, content }) => {
  return (
    <>
      {introduction && <AutoDiscoverPrerequisitesIntroduction>{introduction}</AutoDiscoverPrerequisitesIntroduction>}
      <AutoDiscoveryInfoList>
        {content?.map((items, index) => (
          <AutoDiscoveryInfoListItem key={index}>
            {items?.map((item, i) => {
              return typeof item === 'string' ? (
                <AutoDiscoveryInfoListItemText key={i}>{item}</AutoDiscoveryInfoListItemText>
              ) : (
                <BigidLink key={i} underline="none" href={item.href} text={item.text} shouldOpenNewTab={true} />
              );
            })}
          </AutoDiscoveryInfoListItem>
        ))}
      </AutoDiscoveryInfoList>
    </>
  );
};

export const AutoDiscoverPrerequisitesContentSpecial: FC<PrerequisitesData> = ({ introduction, content }) => {
  return (
    <>
      {introduction && <AutoDiscoverPrerequisitesIntroduction>{introduction}</AutoDiscoverPrerequisitesIntroduction>}
      <AutoDiscoveryInfoListOrderedSpecial>
        {content?.map(([header, ...items], index) => (
          <AutoDiscoveryInfoListItemSpecial key={index}>
            <div>
              <BigidHeading6 fontWeight={600} sx={{ marginBottom: '4px' }}>
                {header as string}
              </BigidHeading6>
              {items?.map((item, i) => {
                return typeof item === 'string' ? (
                  <AutoDiscoveryInfoListItemText key={i}>{item}</AutoDiscoveryInfoListItemText>
                ) : (
                  <BigidLink key={i} underline="none" href={item.href} text={item.text} shouldOpenNewTab={true} />
                );
              })}
            </div>
          </AutoDiscoveryInfoListItemSpecial>
        ))}
      </AutoDiscoveryInfoListOrderedSpecial>
    </>
  );
};

const MAP_PLACE_TO_COMPONENT = {
  [PrerequisitesPlacement.MAIN]: AutoDiscoverPrerequisitesContentSpecial,
  [PrerequisitesPlacement.SIDE]: AutoDiscoverPrerequisitesContentSimple,
};

export const AutoDiscoveryPrerequisitesContent: FC<AutoDiscoverPrerequisitesContentProps> = ({ authType, type }) => {
  const [data, setData] = useState<PrerequisitesData>();
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    const updateData = async () => {
      const dataOfFn = TYPE_TO_PREREQUISITES_CONTENT[type][authType];
      if (typeof dataOfFn === 'function') {
        const data = await dataOfFn({ authType, type });
        setData(data);
      } else {
        setData(dataOfFn);
      }
      setIsLoading(false);
    };
    updateData();
  }, [authType, type]);
  const placement = PREREQUISITES_PLACEMENT_BY_TYPE[type][authType];
  const Component = MAP_PLACE_TO_COMPONENT[placement];

  return (
    <AutoDiscoverWizardPrerequisitesMain
      isV1Prerequisite={placement === PrerequisitesPlacement.SIDE}
      data-aid="AutoDiscoveryPrerequisitesContent"
      id={AUTO_DISCOVER_PREREQUISITES_CONTENT_ID}
    >
      {data ? <Component {...data} /> : isLoading ? <BigidLoader position={'relative'} size={20} /> : 'TBD'}
    </AutoDiscoverWizardPrerequisitesMain>
  );
};
