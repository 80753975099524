import React, { ChangeEvent, FC, ReactText, useState } from 'react';
import { httpService } from '../../services/httpService';
import { BigidRadioGroup, PrimaryButton } from '@bigid-ui/components';
import { closeSystemDialog, openSystemDialog } from '../../services/systemDialogService';
import { notificationService } from '../../services/notificationService';
import { noop } from 'lodash';
import { DataSourceSelectionGrid } from '../DataSourceSelectionGrid/DataSourceSelectionGrid';
import { Typography } from '@mui/material';
import styled from '@emotion/styled';

enum DataSourceFetchMode {
  ALL = 'all',
  SELECTED = 'selected',
}

interface RiskCalculateDialogProps {
  getDataSourceFetchMode: () => DataSourceFetchMode;
  setDataSourceFetchMode: (val: DataSourceFetchMode) => void;
  setSelectedDs: (val: ReactText[]) => void;
}

interface RiskCalculatorDto {
  all: boolean;
  dsNames: string[];
}

const getCompState = () => {
  let dataSourceFetchMode = DataSourceFetchMode.ALL;
  let selectedDs: ReactText[] = [];
  return {
    getDataSourceFetchMode: () => dataSourceFetchMode,
    setDataSourceFetchMode: (val: DataSourceFetchMode) => (dataSourceFetchMode = val),
    getSelectedDs: () => selectedDs.map(ds => `${ds}`),
    setSelectedDs: (val: ReactText[]) => (selectedDs = val),
  };
};

const GridContainer = styled('div')`
  display: flex;
  flex-direction: column;
  height: 400px;
  overflow: hidden;
`;

const RiskCalculateDialog: FC<RiskCalculateDialogProps> = ({
  setDataSourceFetchMode,
  setSelectedDs,
  getDataSourceFetchMode,
}) => {
  const [dataSourceFetchModeState, setDataSourceFetchModeState] = useState<DataSourceFetchMode>(
    getDataSourceFetchMode(),
  );
  return (
    <div>
      <Typography variant={'body1'}>
        select data sources on which to recalculate risk (this action may take a while...)
      </Typography>
      <BigidRadioGroup
        defaultValue={DataSourceFetchMode.ALL}
        onChange={(event: ChangeEvent<HTMLInputElement>) => {
          setDataSourceFetchMode(event.target.value as DataSourceFetchMode);
          setDataSourceFetchModeState(event.target.value as DataSourceFetchMode);
        }}
        options={[
          {
            label: 'All data sources',
            value: DataSourceFetchMode.ALL,
          },
          {
            label: 'Select data sources',
            value: DataSourceFetchMode.SELECTED,
          },
        ]}
        horizontal={true}
      />

      {dataSourceFetchModeState !== DataSourceFetchMode.ALL && (
        <GridContainer>
          <DataSourceSelectionGrid setSelected={setSelectedDs} />
        </GridContainer>
      )}
    </div>
  );
};

export function openRiskCalculatorDialog() {
  const { getDataSourceFetchMode, setDataSourceFetchMode, getSelectedDs, setSelectedDs } = getCompState();
  openSystemDialog<RiskCalculateDialogProps>({
    title: `Risk Calculation`,
    onClose: noop,
    content: RiskCalculateDialog,
    contentProps: { getDataSourceFetchMode, setDataSourceFetchMode, setSelectedDs },
    borderTop: true,
    maxWidth: 'sm',
    withCloseButton: true,
    isLoading: false,
    buttons: [
      {
        component: PrimaryButton,
        onClick: async () => {
          let body: RiskCalculatorDto;
          if (getDataSourceFetchMode() === DataSourceFetchMode.ALL) {
            body = {
              all: true,
              dsNames: [],
            };
          } else {
            body = {
              all: false,
              dsNames: getSelectedDs(),
            };
          }
          await httpService.put('/risk/calculate', body);
          notificationService.success('Risk calculation was triggered successfully');
          closeSystemDialog();
        },
        text: 'calculate',
      },
    ],
  });
}
