import { useQuery, UseQueryOptions, QueryFunction, UseQueryResult, QueryKey } from 'react-query';
import { ErrorType } from '../../../../config/request';
import type { DsCollaboratorParams, AsyncReturnType } from '../types';
import { getDsCollaborators, API_VERSION, DS_COLLABORATOR_ENDPOINT } from '../services/collaboration';

export type GetDsCollaboratorsQueryResult = NonNullable<AsyncReturnType<typeof getDsCollaborators>>;

export type GetDsCollaboratorsQueryError = ErrorType<unknown>;

export const getDsCollaboratorsQueryKey = (dataSource: string, params: DsCollaboratorParams): Array<unknown> => [
  'get',
  `${API_VERSION}/${DS_COLLABORATOR_ENDPOINT}/${dataSource}`,
  ...(params ? [params] : []),
];

export const useGetDsCollaborators = <
  TData = AsyncReturnType<typeof getDsCollaborators>,
  TError = GetDsCollaboratorsQueryError,
>(
  dataSource: string,
  params?: DsCollaboratorParams,
  options?: { query?: UseQueryOptions<AsyncReturnType<typeof getDsCollaborators>, TError, TData> },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options || {};

  const queryKey = queryOptions?.queryKey ?? getDsCollaboratorsQueryKey(dataSource, params);
  const queryFn: QueryFunction<AsyncReturnType<typeof getDsCollaborators>> = async () =>
    getDsCollaborators(dataSource, params);
  const query = useQuery<AsyncReturnType<typeof getDsCollaborators>, TError, TData>(queryKey, queryFn, {
    enabled: !!dataSource,
    ...queryOptions,
  });

  return {
    queryKey,
    ...query,
  };
};
