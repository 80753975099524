import { module } from 'angular';
const app = module('app');
let ropaUrl;
let tpa;
app.factory('ropaService', [
  '$http',
  'appSettings',
  function ($http, appSettings) {
    async function getRopaTpaApp() {
      if (tpa) {
        return tpa;
      }
      const response = await $http.get(`${appSettings.serverPath}/${appSettings.version}/tpa/RoPA`);
      tpa = response.data;
      return response.data;
    }
    async function getRopaUrl() {
      if (ropaUrl) {
        return ropaUrl;
      }
      const tpa = await getRopaTpaApp();
      let url;
      if (tpa && tpa[0]?.is_use_proxy) {
        url = `${appSettings.serverPath}/proxy/tpa/api/${tpa[0]._id}/v1`;
      } else {
        url = `${tpa[0]?.base_url}/v1`;
      }
      ropaUrl = url;
      return ropaUrl;
    }

    return {
      async getRopaBusinessProcess(id) {
        const url = await getRopaUrl();
        const response = await $http.get(`${url}/business-processes/${id}`);
        return response.data;
      },
      async getRopaTpaId() {
        const tpa = await getRopaTpaApp();
        return tpa && tpa[0] && tpa[0]._id;
      },
    };
  },
]);
