import { persistFmsdState, FmsdConfigKeys, activateRegulations, deactivateRegulations } from '../../fmsdServices';
import { CredsStatus } from '../../FmsdComponents/ConnectionsStatus/ConnectionsStatus';
import { FmsdPreDiscoverCustomizeTypes } from '../FmsdPreDiscoverViews';

export enum ReducerActions {
  'UPDATE_PERMISSIONS',
  'SWITCH_CUSTOMIZE_VIEW',
  'UPDATE_SELECTED_REGULATIONS',
  'UPDATE_COUNTRIES',
  'PERSIST_SELECTED_REGULATIONS',
  'UPDATE_SELECTED_CATEGORIES',
  'IS_SELECTION_CHANGED',
  'PERSIST_SELECTED_CATEGORIES',
  'INIT',
}

export interface ConnectionInfo {
  credsStatus: CredsStatus;
  permissions: string[];
}

export interface ConnectionsState {
  [key: string]: ConnectionInfo;
}

export interface SelectionData {
  categories: string[];
  classifiersMethodology: FmsdPreDiscoverCustomizeTypes;
  regulations: string[];
  uniqueSelectedRegulations: string[];
  firstWizardUsage?: boolean;
  // TODO: check this if Countries step will be included to the wizard
  countries?: string[];
  // TODO: check this if Countries step will be included to the wizard
  countriesTouched?: boolean;
}

export interface PreDiscoverReducerState {
  selectionData: Partial<SelectionData>;
  connections: ConnectionsState;
  isSelectionChanged: boolean;
}

export interface PreDiscoverReducerAction {
  type: ReducerActions;
  payload: Partial<PreDiscoverReducerState>;
}

export const preDiscoverReducer: React.Reducer<PreDiscoverReducerState, PreDiscoverReducerAction> = (
  state,
  { type, payload },
) => {
  switch (type) {
    case ReducerActions.UPDATE_COUNTRIES: {
      const { countries } = payload.selectionData;
      const newSelectionData = {
        ...state.selectionData,
        countries,
        countriesTouched: true,
      };
      const newState = { ...state, selectionData: newSelectionData };
      persistFmsdState({ [FmsdConfigKeys.PRE_DISCOVER_SELECTION_DATA]: newSelectionData });
      return newState;
    }

    case ReducerActions.UPDATE_PERMISSIONS: {
      const newConnectionsState = { ...state.connections, ...payload.connections };
      const newState = { ...state, connections: newConnectionsState };
      persistFmsdState({ [FmsdConfigKeys.PRE_DISCOVER_CONNECTIONS_STATE]: newConnectionsState });
      return newState;
    }

    case ReducerActions.UPDATE_SELECTED_CATEGORIES: {
      const { categories, classifiersMethodology } = payload.selectionData;
      const newSelectionData = {
        ...state.selectionData,
        categories: [...categories],
        classifiersMethodology,
        uniqueSelectedRegulations: categories,
      };
      return { ...state, selectionData: newSelectionData, isSelectionChanged: true };
    }

    case ReducerActions.PERSIST_SELECTED_CATEGORIES: {
      const { categories, classifiersMethodology } = payload.selectionData;
      const newSelectionData = {
        ...state.selectionData,
        categories: [...categories],
        classifiersMethodology,
        uniqueSelectedRegulations: categories,
      };
      persistFmsdState({ [FmsdConfigKeys.PRE_DISCOVER_SELECTION_DATA]: newSelectionData });
      activateRegulations(categories);
      deactivateRegulations(state.selectionData?.regulations);
      return { ...state, selectionData: newSelectionData, isSelectionChanged: false };
    }

    case ReducerActions.PERSIST_SELECTED_REGULATIONS: {
      const { regulations, classifiersMethodology } = payload.selectionData;
      const newSelectionData = {
        ...state.selectionData,
        regulations: [...regulations],
        uniqueSelectedRegulations: regulations,
        classifiersMethodology,
      };
      persistFmsdState({ [FmsdConfigKeys.PRE_DISCOVER_SELECTION_DATA]: newSelectionData });
      activateRegulations(regulations);
      deactivateRegulations(state.selectionData?.categories);
      return { ...state, selectionData: newSelectionData, isSelectionChanged: false };
    }

    case ReducerActions.IS_SELECTION_CHANGED: {
      return { ...state, isSelectionChanged: payload?.isSelectionChanged };
    }

    case ReducerActions.SWITCH_CUSTOMIZE_VIEW: {
      const { classifiersMethodology } = payload.selectionData;

      const newSelectionData = {
        ...state.selectionData,
        classifiersMethodology,
        uniqueSelectedRegulations: [] as string[],
      };
      const newState = { ...state, selectionData: newSelectionData, isSelectionChanged: true };
      persistFmsdState({ [FmsdConfigKeys.PRE_DISCOVER_SELECTION_DATA]: newSelectionData });
      return newState;
    }

    case ReducerActions.UPDATE_SELECTED_REGULATIONS: {
      const { regulations, classifiersMethodology } = payload.selectionData;
      const newSelectionData = {
        ...state.selectionData,
        regulations: [...regulations],
        uniqueSelectedRegulations: regulations,
        classifiersMethodology,
      };
      return { ...state, selectionData: newSelectionData, isSelectionChanged: true };
    }

    case ReducerActions.INIT: {
      return getInitialReducerState(payload);
    }

    default:
      return state;
  }
};

export const getInitialReducerState = ({
  selectionData,
  connections = {},
}: Partial<PreDiscoverReducerState>): PreDiscoverReducerState => {
  return {
    selectionData: selectionData || {
      categories: [],
      classifiersMethodology: FmsdPreDiscoverCustomizeTypes.CATEGORIES,
      regulations: [],
      uniqueSelectedRegulations: [],
    },
    connections,
    isSelectionChanged: false,
  };
};
