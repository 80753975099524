import React, { FC } from 'react';
import styled from '@emotion/styled';
import { BigidTooltip } from '@bigid-ui/components';
import { BigidChevronRightIcon } from '@bigid-ui/icons';

interface ObjectPreviewBreadcrumbsProps {
  handlerBreadcrumb: () => void;
  previousCase: string;
  currentCase: string;
}

interface ItemWrapperProps {
  isCurrent: boolean;
}

const Main = styled.div({
  display: 'flex',
  alignItems: 'center',
  rowGap: 8,
  height: 24,
  width: '100%',
});

const ItemWrapper = styled.div(({ isCurrent }: ItemWrapperProps) => ({
  width: 'fit-content',
  maxWidth: '35%',
  fontSize: isCurrent ? 16 : 14,
  fontWeight: isCurrent ? 700 : 400,
  cursor: isCurrent ? 'unset' : 'pointer',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
}));

export const ObjectPreviewBreadcrumbs: FC<ObjectPreviewBreadcrumbsProps> = ({
  handlerBreadcrumb,
  previousCase,
  currentCase,
}) => {
  const objectName = currentCase.split('/').pop();
  return (
    <Main>
      <BigidTooltip title={previousCase} fontSize="small">
        <ItemWrapper onClick={handlerBreadcrumb} isCurrent={false}>
          {previousCase}
        </ItemWrapper>
      </BigidTooltip>

      <BigidChevronRightIcon />

      <BigidTooltip title={objectName} fontSize="small">
        <ItemWrapper isCurrent={true}>{objectName}</ItemWrapper>
      </BigidTooltip>
    </Main>
  );
};
