import React from 'react';
import { BigidLink, SecondaryButton } from '@bigid-ui/components';
import makeStyles from '@mui/styles/makeStyles';
import { generateDataAid } from '@bigid-ui/utils';

const useStyles = makeStyles({
  link: {
    textDecoration: 'none',
  },
});

interface ScanOverviewBottomSectionProps {
  viewInCatalog?: () => any;
  viewDetails?: () => any;
  bi?: Record<string, any>;
  buttonText?: string;
  dataAid?: string;
}

export const ScanOverviewBottomSection = ({
  viewInCatalog,
  viewDetails,
  bi = {},
  buttonText,
  dataAid = 'ScanOverviewBottomSection',
}: ScanOverviewBottomSectionProps) => {
  const styles = useStyles();
  return (
    <>
      {viewInCatalog && (
        <SecondaryButton
          size="medium"
          text={buttonText}
          onClick={viewInCatalog}
          bi={bi}
          dataAid={generateDataAid(dataAid, ['ViewInCatalog'])}
        />
      )}
      <BigidLink classNames={styles.link} text="View Details" onClick={viewDetails}></BigidLink>
    </>
  );
};
