import React, { FC, memo } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { BigidDropdown, BigidDropdownOption, BigidHeading6 } from '@bigid-ui/components';
import { MetadataSearchFilterComponentBaseProps } from '../../MetadataSearchFiltersTypes';
import { getTagNameAndValueSplitted } from '../../../../../utilities/tags';

export type MetadataSearchFilterTagsProps = MetadataSearchFilterComponentBaseProps;

const useStyles = makeStyles({
  root: {
    width: '100%',
  },
  control: {
    padding: '8px',
    width: '100%',
  },
  label: {
    marginBottom: '8px',
  },
});

export const MetadataSearchFilterTags: FC<MetadataSearchFilterTagsProps> = memo(
  ({ dataAid, config, value: initialValue, onFilterChange, onFilterSearch, isFiltersFetching }) => {
    const classes = useStyles();
    const { fieldName, fieldType, displayName, values: options } = config;
    const label = displayName || fieldName;
    const isDisabled = options?.length === 0 && !initialValue;
    const isSearchable = options?.length > 0;

    const selectedValue = Array.isArray(initialValue?.value)
      ? initialValue.value.map(tag => {
          const [tagName, tagValue] = getTagNameAndValueSplitted(String(tag));

          return {
            id: String(tag),
            parentId: tagName,
            value: tag,
            displayValue: tagValue,
          };
        })
      : [];

    const handleOnSelect = (selectedOptions: BigidDropdownOption[]): void => {
      onFilterChange({
        field: fieldName,
        value: selectedOptions.map(({ value }) => value),
        operator: 'in',
        fieldType,
        fieldDisplayName: label,
      });
    };

    const handleFetchOptions = (query: string) => {
      return onFilterSearch(query, config);
    };

    return (
      <div className={classes.root}>
        <div className={classes.control}>
          <BigidHeading6 data-aid={`${dataAid}-label`} className={classes.label}>
            {label}
          </BigidHeading6>
          <BigidDropdown
            isAsync
            isSearchable={isSearchable}
            isMulti
            isDisabled={isDisabled || isFiltersFetching}
            dataAid={`${dataAid}-field`}
            options={options}
            value={selectedValue}
            onSelect={handleOnSelect}
            fetchOptions={handleFetchOptions}
            placeholder={isDisabled ? 'No value' : `Select ${label}`}
            entityNamePlural="Tags"
            entityNameSingular="Tag"
            noOptionsMessage="Current selection does not match other filters"
            displayLimit={100}
          />
        </div>
      </div>
    );
  },
);

MetadataSearchFilterTags.displayName = 'MetadataSearchFilterTags';
