import type { ValidationRule } from '../../../../utilities/validation';

const rules = (t: (key: string, ...args: any[]) => string): ValidationRule<string>[] => [
  {
    id: 'name',
    message: t('collaboration.invalidDsName'),
    validate: (value: string) => {
      try {
        const regex = '^[\\w\\-\\s\\(\\):]+$'; // @info taken from orchestrator rule
        return new RegExp(regex).test(value);
      } catch {
        return false;
      }
    },
  },
  {
    id: 'noTrailingSpaces',
    message: t('collaboration.invalidDsName'),
    validate: (value: string) => {
      try {
        const regex = '^[^\\s].+[^\\s]$'; //
        return new RegExp(regex).test(value);
      } catch {
        return false;
      }
    },
  },
];

export const getValidationRules = (t: (key: string, ...args: any[]) => string): ValidationRule<string>[] => rules(t);
