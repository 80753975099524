import React, { FC, useState, ReactText } from 'react';
import angular from 'angular';
import { orderBy } from 'lodash';
import { BigidDialog, PrimaryButton, TertiaryButton } from '@bigid-ui/components';
import {
  BigidGrid,
  BigidGridColumn,
  BigidGridColumnTypes,
  BigidGridProps,
  BigidGridSorting,
  useFetch,
} from '@bigid-ui/grid';
import { convertToAngular } from '../../../../../common/services/convertToAngular';
import { httpService } from '../../../../services/httpService';
import { notificationService } from '../../../../services/notificationService';
import { RolePickerSelectedRoles, SystemRole } from '../../types';

interface RolePickerProps {
  isOpen: boolean;
  onClose: () => void;
  onConnectRoles: (selectedRowIds: RolePickerSelectedRoles) => void;
  selectedRoles?: string[];
  returnOnlyIds?: boolean;
}

interface GridSystemRole extends SystemRole {
  id: string;
}

const columns: BigidGridColumn<GridSystemRole>[] = [
  {
    name: 'displayName',
    title: 'Name',
    getCellValue: row => row.displayName,
    type: BigidGridColumnTypes.TEXT,
  },
  {
    name: 'description',
    title: 'Description',
    getCellValue: row => row.description,
    type: BigidGridColumnTypes.TEXT,
  },
];

const sortRoles = (sort: BigidGridSorting[], roles: SystemRole[]) => {
  const fields = sort.map(({ field }) => (role: SystemRole) => {
    const value = role[field as keyof SystemRole];

    return typeof value === 'string' ? value.toLowerCase() : value;
  });
  const orders = sort.map(({ order }) => order);
  return orderBy(roles, fields, orders);
};

export const RolePicker: FC<RolePickerProps> = ({
  isOpen = false,
  onClose,
  onConnectRoles,
  selectedRoles = [],
  returnOnlyIds = true,
}) => {
  const [selectedRowIds, setSelectedRowIds] = useState<ReactText[]>([]);
  const [selectedRows, setSelectedRows] = useState<SystemRole[]>([]);
  const onCloseDialog = () => {
    setSelectedRowIds([]);
    onClose();
  };

  const onConnectClick = () => {
    onConnectRoles(returnOnlyIds ? selectedRowIds : selectedRows);
    onCloseDialog();
  };

  // TODO @mbezghin get rid of fetching roles after angular page will be removed, roles should come from props
  const useFetchState = useFetch({
    fetchDataFunction: async ({ sort }) => {
      try {
        const {
          data: {
            data: { roles },
          },
        } = await httpService.fetch<{ data: { roles: SystemRole[] } }>('access-management/roles');

        const sortedData = sortRoles(
          sort,
          roles.map(role => ({
            ...role,
            id: role._id,
          })),
        );

        return Promise.resolve({
          data: sortedData as GridSystemRole[],
          totalCount: sortedData.length,
        });
      } catch (err) {
        notificationService.error(`Failed to get roles.`);
      }
    },
  });

  const gridConfig: BigidGridProps<GridSystemRole> = {
    columns,
    showFilteringControls: false,
    showSortingControls: true,
    showSelectionColumn: true,
    rows: useFetchState.rows,
    skip: useFetchState.skip,
    onPagingChanged: useFetchState.onPagingChanged,
    onSortingChanged: useFetchState.onSortingChanged,
    totalRowsCount: useFetchState.totalRowsCount,
    onFiltersChange: useFetchState.onFiltersChanged,
    apiRef: useFetchState.apiRef,
    loading: useFetchState.isLoading,
    selectedRowIds,
    onSelectedRowIdsChanged: setSelectedRowIds,
    onSelectedRowsChanged: setSelectedRows,
    isRowDisabled: (row: SystemRole) => selectedRoles.includes(row._id),
    showSelectAll: false,
  };

  return (
    <BigidDialog
      isOpen={isOpen}
      onClose={onCloseDialog}
      title="Connect Role to User"
      showCloseIcon
      buttons={[
        { text: 'Cancel', onClick: onCloseDialog, component: TertiaryButton },
        { text: 'Connect Role', onClick: onConnectClick, component: PrimaryButton },
      ]}
      isContentScrollable
    >
      <BigidGrid {...gridConfig} />
    </BigidDialog>
  );
};

angular
  .module('app')
  .component('rolePicker', convertToAngular(RolePicker, ['isOpen', 'onClose', 'onConnectRoles', 'selectedRoles']));
