import React, { useState } from 'react';
import { noop } from 'lodash';
import { generateDataAid } from '@bigid-ui/utils';
import { getFixedT, useLocalTranslation } from '../../../../translations';
import { BigidBody1, BigidTextField, PrimaryButton, TertiaryButton } from '@bigid-ui/components';
import { notificationService } from '../../../../../../services/notificationService';
import { openSystemDialog, SystemDialogContentProps } from '../../../../../../services/systemDialogService';
import { patchFieldNote, AddNoteModalType, deleteFieldNote } from '../../../../curationService';
import styled from '@emotion/styled';

const Description = styled(BigidBody1)`
  margin-bottom: 16px;
`;

const ButtonContainer = styled('div')`
  display: flex;
  gap: 8px;
  justify-content: ${(props: { withDeleteBtn: boolean }) => (props.withDeleteBtn ? `space-between` : 'flex-end')};
  padding-top: 30px;
`;

const RightButtonContainer = styled('div')`
  align-self: flex-end;
`;

type AddNoteDialogContentProps = SystemDialogContentProps<{
  ids: string[];
  note: string;
  modalType: AddNoteModalType;
  onResolve: ({ ids, note }: { ids: string[]; note: string }) => void;
  fieldName?: string;
  filter?: string;
  totalFieldsCount?: number;
}>;

export interface resolveResponse {
  isNoteSaved: boolean;
  note: string;
  ids: string[];
}

interface AddNoteDialogProps {
  ids: string[];
  note: string;
  modalType: AddNoteModalType;
  fieldName?: string;
  filter?: string;
  totalFieldsCount?: number;
}

const getDescription = (
  modalType: AddNoteModalType,
  fieldName: string,
  note: string,
  numberOfRowsSelected?: number,
) => {
  const t = getFixedT('CuratedFields.common.AddNoteDialog');

  switch (modalType) {
    case AddNoteModalType.ADD:
      return t('addDescription', { fieldName });
    case AddNoteModalType.EDIT:
      return t('editDescription', { fieldName });
    case AddNoteModalType.REJECT:
      return note ? t('rejectDescriptionEdit', { fieldName }) : t('rejectDescriptionAdd', { fieldName });
    case AddNoteModalType.BULK:
      return t('bulkDescription', { numberOfRowsSelected });
    case AddNoteModalType.BULK_REJECT:
      return t('bulkDescription', { numberOfRowsSelected });
    default:
      return t('addDescription', { fieldName });
  }
};

const AddNoteDialogContent = ({
  ids,
  fieldName,
  modalType,
  note,
  onClose,
  onResolve,
  filter,
  totalFieldsCount,
}: AddNoteDialogContentProps) => {
  const { t } = useLocalTranslation('CuratedFields.common.AddNoteDialog');
  const [value, setValue] = useState(note || '');
  const [error, setError] = useState('');

  const handleOnChange = (event: any) => {
    const value = event.target.value;
    setValue(value);

    if (value.length > 400) {
      setError(t('validationMessages.noteMaxLength'));
    } else if (modalType === AddNoteModalType.REJECT && !value?.trim()) {
      setError(t('validationMessages.noteIsRequired'));
    } else {
      setError('');
    }
  };

  const handleOnSaveClick = async () => {
    try {
      const response = await patchFieldNote({
        note: value,
        ...(ids?.length && !filter ? { ids } : {}),
        ...(filter ? { filter } : {}),
      });

      if (response.status === 'success') {
        onResolve({
          ids,
          note: value,
        });
      }
    } catch ({ message }) {
      const error = t('errors.modifyingNote');
      console.error(`${error}: ${message}`);
      notificationService.error(error);
    } finally {
      onClose();
    }
  };

  const handleDeleteNote = async () => {
    try {
      const response = await deleteFieldNote({ ids });

      if (response.status === 'success') {
        onResolve({
          ids,
          note: '',
        });
      }
    } catch ({ message }) {
      const error = t('errors.modifyingNote');
      console.error(`${error}: ${message}`);
      notificationService.error(error);
    } finally {
      onClose();
    }
  };

  const handleOnCloseClick = () => {
    onClose();
  };

  const isSaveDisabled = !!error.length || !value?.trim();
  const isDeleteVisible = modalType === AddNoteModalType.ADD || modalType === AddNoteModalType.EDIT;

  return (
    <>
      <Description>{getDescription(modalType, fieldName, note, totalFieldsCount)}</Description>
      <BigidTextField
        dataAid={generateDataAid('AddNoteDialogContent', ['note-field'])}
        value={value}
        rows={5}
        onChange={handleOnChange}
        errorMessage={error || ''}
        required
        multiline
      />
      <ButtonContainer withDeleteBtn={isDeleteVisible}>
        {isDeleteVisible && (
          <TertiaryButton
            dataAid={generateDataAid('AddNoteDialogContent', ['delete'])}
            size="medium"
            onClick={handleDeleteNote}
            text={t('delete')}
          />
        )}

        <RightButtonContainer>
          <TertiaryButton
            dataAid={generateDataAid('AddNoteDialogContent', ['cancel'])}
            size="medium"
            onClick={handleOnCloseClick}
            text={t('cancel')}
          />
          <PrimaryButton
            dataAid={generateDataAid('AddNoteDialogContent', ['save'])}
            size="medium"
            onClick={handleOnSaveClick}
            disabled={isSaveDisabled}
            text={t('save')}
          />
        </RightButtonContainer>
      </ButtonContainer>
    </>
  );
};

export const addNoteDialog = (props: AddNoteDialogProps) => {
  const t = getFixedT('CuratedFields.common.AddNoteDialog');

  return new Promise<resolveResponse>(resolve => {
    const onResolve = ({ note, ids }: { ids: string[]; note: string }) => {
      resolve({
        isNoteSaved: true,
        note,
        ids,
      });
    };

    const dilogContentProps = {
      ...props,
      onResolve,
    };

    try {
      openSystemDialog({
        title: props.note ? t('editTitle') : t('addTitle'),
        onClose: noop,
        content: AddNoteDialogContent,
        contentProps: dilogContentProps,
        maxWidth: 'xs',
      });
    } catch ({ message }) {
      const error = t('errors.generic');
      console.error(`${error}: ${message}`);
      notificationService.error(error);
    }
  });
};
