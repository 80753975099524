import React, { FC, MouseEvent } from 'react';
import { BigidListIcon, BigidTreeViewIcon } from '@bigid-ui/icons';
import { BigidBody1, BigidToggleButton } from '@bigid-ui/components';
import { ViewType, upperFirstLetter } from '../actionableInsightsService';
import styled from '@emotion/styled';
import { ActionableInsightsTrackingEvents, trackActionableInsightsEvent } from '../actionableInsightsTrackingUtil';

export interface ToolbarViewToggleProps {
  currentView: ViewType;
  onToggle: (view: ViewType) => void;
}

const Main = styled.div({
  display: 'flex',
  alignItems: 'center',
  gap: 8,
});

const Text = styled(BigidBody1)(({ theme }) => ({
  color: theme.palette.bigid.gray700,
}));

export const BigidGridWithToolbarViewToggle: FC<ToolbarViewToggleProps> = ({ onToggle, currentView }) => {
  const change = (_event: MouseEvent<HTMLElement, globalThis.MouseEvent>, value: string) => {
    const viewType = value as ViewType;
    onToggle(viewType);
    trackActionableInsightsEvent(ActionableInsightsTrackingEvents.DATA_RISK_MANAGEMENT_VIEW_CLICK, {
      viewType,
    });
  };

  return (
    <Main data-aid="BigidToolbarViewToggle">
      <Text>View</Text>
      <BigidToggleButton
        enforceValue
        onChange={change}
        initialSelected={currentView}
        toggleButtons={[
          {
            dataAid: 'BigidToolbarViewToggleGrouped',
            icon: BigidTreeViewIcon,
            tooltip: upperFirstLetter(ViewType.GROUPED),
            value: ViewType.GROUPED,
          },
          {
            dataAid: 'BigidToolbarViewToggleFlat',
            icon: BigidListIcon,
            tooltip: upperFirstLetter(ViewType.FLAT),
            value: ViewType.FLAT,
          },
        ]}
      />
    </Main>
  );
};
