import './createSarPane.scss';
import template from './createSarPane.html';
import { EVENTS, REQUEST_TYPES } from './../sarConsts.js';

import { module } from 'angular';
const app = module('app');

const DEFAULT_REQUEST_TYPE = REQUEST_TYPES.single;

class CreateSarPaneComponent {
  constructor($rootScope, $element) {
    'ngInject';

    this.$element = $element;
    this.$rootScope = $rootScope;

    this.onRequestTypeChange = this.onRequestTypeChange.bind(this);

    // used in HTML template
    this.REQUEST_TYPES = REQUEST_TYPES;

    this.requestTypes = [
      {
        name: 'Search Individual Identity',
        type: REQUEST_TYPES.single,
      },
      {
        name: 'Bulk Request',
        type: REQUEST_TYPES.bulk,
      },
      {
        name: 'Enter Attributes Manually',
        type: REQUEST_TYPES.manual,
      },
    ];

    this.isSubmitButtonEnabled = false;
  }

  $onInit = () => {
    this.onRequestTypeChange(this.requestTypes.find(({ type }) => type === DEFAULT_REQUEST_TYPE));
  };

  isBulkRequest() {
    return this.selected && this.selected.type === REQUEST_TYPES.bulk;
  }

  isSingleRequest() {
    return this.selected && this.selected.type === REQUEST_TYPES.single;
  }

  isManualRequest() {
    return this.selected && this.selected.type === REQUEST_TYPES.manual;
  }

  onSubmitIsEnabledChange({ isEnabled }) {
    this.isSubmitButtonEnabled = isEnabled;
  }

  onRequestTypeChange(requestType) {
    this.selected = requestType;
    this.isSubmitButtonEnabled = false;
    this.onRequestTypeChangeOutput({ type: requestType.type });

    // toggle class that applies bulk-request styles
    this.$element[0].classList[this.isBulkRequest() ? 'add' : 'remove']('bulk-request');
    this.$element[0].classList[this.isManualRequest() ? 'add' : 'remove']('scrollable-container');
  }

  /**
   * This method is a helper to make drop-down navigation programmatically,
   * basically it simulates a click on specific option from the dropdown of all request types,
   * so if any change happens in the `onRequestTypeChange` method, this method might has to be updated as well.
   *
   * How to use:
   *  - `require` this controller in its child and directly call to this method with required arguments.
   *
   * This method should not exist ! Why:
   *  - unfortunately, we don't have $state for SAR types and all related navigation under DSAR section
   *  - But we do have a need to be able to navigate between the SAR types, and I decided to encapsulate this
   *      functionality here in this controller
   *
   * @param requestType {string} - use one of `REQUEST_TYPES` from sarConsts.js file to navigate to specific page
   *
   *  TODO: this function should be removed once we have $state (routing) navigation for these states, and all
   *  TODO: after that, all usages of this function should be converted to ui-sref(s)
   */
  navigateToRequestType(requestTypeName) {
    if (!(requestTypeName in this.REQUEST_TYPES)) {
      throw new Error(`Unknown requestType: '${requestTypeName}'`);
    }
    this.onRequestTypeChange(this.requestTypes.find(({ type }) => type === requestTypeName));
  }

  onSubmitClick() {
    if (this.isBulkRequest() || this.isManualRequest()) {
      this.$rootScope.$emit(EVENTS.callToSubmitSarRequest, this.selected);
    }
  }

  showSubmitButton() {
    return this.isBulkRequest() || this.isManualRequest();
  }
}

app.component('createSarPane', {
  template,
  controller: CreateSarPaneComponent,
  bindings: {
    proxyToNewSarUserQuery: '<',
    proxyToNewSarOnSubmit: '&',
    onRequestTypeChangeOutput: '&',
    profiles: '<',
  },
});
