import React, { FC, PropsWithChildren } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { BigidColorsV2 } from '@bigid-ui/components';

const useStyles = makeStyles({
  root: {
    height: '100%',
    width: '100%',
    position: 'fixed',
    padding: '24px 0 0 116px',
    marginTop: -7,
    left: 0,
    zIndex: 2,
  },
  mainContainer: {
    width: '97%',
    height: 'calc(100% - 74px)',
    background: BigidColorsV2.white,
    borderRadius: 8,
    overflow: 'hidden',
    display: 'flex',
    position: 'relative',
    flexDirection: 'column',
    zIndex: 10,
    boxShadow: '0px 8px 28px -6px rgba(24, 39, 75, 0.12), 0px 18px 88px -4px rgba(24, 39, 75, 0.14)',
  },
});

export const FmsdBase: FC<PropsWithChildren> = ({ children }) => {
  const { root, mainContainer } = useStyles({});

  return (
    <div className={root}>
      <div className={mainContainer}>{children}</div>
    </div>
  );
};
