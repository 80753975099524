import { GettingStartedItem, GettingStartedItems, gettingStartedItemsObject } from './GettingStartedItems';
import { userPreferencesService } from '../../services/userPreferencesService';
import { dataSourceConnectionsService, rolesService } from '../../services/angularServices';
import { getScansData } from '../Scans/ScanService';
import { fetchFmsdState } from '../Fmsd/fmsdServices';
import { GettingStartedUITrackingEvent, trackEventGettingStarted } from './GettingStartedEventTrackerUtils';
import { activityHighlightsService } from '../../services/activityHighlightsService';
import { httpService } from '../../services/httpService';

const fetchFmsdActivatedRegulations = async (): Promise<number> => {
  const result = await fetchFmsdState();
  return result?.fmsdPreDiscoverSelectionData?.uniqueSelectedRegulations?.length;
};

const fetchDataSourcesCount = async (): Promise<number> => {
  const { count } = await dataSourceConnectionsService.getDataSourceConnectionsDataCount();
  return count;
};

const fetchScansCount = async (): Promise<number> => {
  const { totalCount } = await getScansData('requireTotalCount=true');
  return totalCount;
};

const fetchUsersCount = async (): Promise<number> => {
  const {
    data: { totalCount },
  } = await rolesService.getRBACUsers('');
  return totalCount;
};

const checkIfCatalogWasUsed = async (): Promise<boolean> => {
  const data = await userPreferencesService.get(
    userPreferencesService.SHARED_PREFERENCES.CATALOG_WAS_USED_AT_LEAST_ONCE,
  );
  return data?.data?.value;
};

interface ActivityHighlightsCard {
  description: string;
  header: string;
}

export const openSlackAppInstallPage = async (): Promise<void> => {
  try {
    const response = await httpService.fetch('cloud-portal/slack/install');
    const url = response.data?.data?.url;
    window.open(url);
  } catch (e) {
    console.error('failed fetching install link', e);
  }
};

const checkIfSlackIntegrationWasCompleted = async (): Promise<boolean> => {
  try {
    const SLACK_INTEGRATION_ENABLED = sessionStorage.getItem('SLACK_INTEGRATION_ENABLED');
    if (SLACK_INTEGRATION_ENABLED === 'true') {
      const response = await httpService.fetch('cloud-portal/slack/install/status');
      if (response) {
        return response.data?.data?.is_installed;
      }
    }
  } catch (e) {
    console.error('failed while fetching slack install status ', e);
  }
  return false;
};

const wasReportVisited = async (): Promise<boolean> => {
  if (userPreferencesService.get('RISK_ASSESSMENT_ENABLED')) {
    return wasRiskAssessmentReportVisited();
  } else {
    return wasPopulatedActivityHighlightsReportVisited();
  }
};

const wasRiskAssessmentReportVisited = async (): Promise<boolean> => {
  const { data: { value = null } = {} } =
    (await userPreferencesService.get(userPreferencesService.SHARED_PREFERENCES.RISK_ASSESSMENT_REPORT_WAS_VISITED)) ||
    {};
  return !!value;
};

const wasPopulatedActivityHighlightsReportVisited = async (): Promise<boolean> => {
  const data = await userPreferencesService.get(
    userPreferencesService.SHARED_PREFERENCES.ACTIVITY_HIGHLIGHTS_REPORT_WAS_VISITED,
  );
  if (data?.data?.value) {
    const {
      data: {
        data: [activityHighlights],
      },
    } = await activityHighlightsService.getActivityHighlights();
    const { highlights } = activityHighlights;
    const piRecordsCard = highlights?.overview?.cards?.find(
      (card: ActivityHighlightsCard) => card.description == 'PI records',
    );
    if (0 !== Number(piRecordsCard?.header)) {
      return true;
    }
  }
  return false;
};

export const evaluateGettingStartedItemsStatus = async (): Promise<GettingStartedItems> => {
  const SLACK_INTEGRATION_ENABLED = sessionStorage.getItem('SLACK_INTEGRATION_ENABLED');
  const [
    activeClassifiersCount,
    dsCount,
    scansCount,
    usersCount,
    wasCatalogUsed,
    wasReportVisitedResult,
    wasSlackIntegrationCompleted,
  ] = await Promise.all([
    fetchFmsdActivatedRegulations(),
    fetchDataSourcesCount(),
    fetchScansCount(),
    fetchUsersCount(),
    checkIfCatalogWasUsed(),
    wasReportVisited(),
    checkIfSlackIntegrationWasCompleted(),
  ]);
  const COMPLETION_CRITERIA_REGULATIONS = 0;
  const COMPLETION_CRITERIA_SCANS_COUNT = 0;
  const COMPLETION_CRITERIA_DS_COUNT = 0;
  const COMPLETION_CRITERIA_USERS_COUNT = 4;

  gettingStartedItemsObject.defineSensitivity.isCompleted = activeClassifiersCount > COMPLETION_CRITERIA_REGULATIONS;
  gettingStartedItemsObject.connectAndScan.isCompleted =
    dsCount > COMPLETION_CRITERIA_DS_COUNT && scansCount > COMPLETION_CRITERIA_SCANS_COUNT;
  gettingStartedItemsObject.reduceRisks.isCompleted = wasCatalogUsed;
  gettingStartedItemsObject.addTeammates.isCompleted = usersCount > COMPLETION_CRITERIA_USERS_COUNT;
  gettingStartedItemsObject.shareFindings.isCompleted = wasReportVisitedResult;
  if (SLACK_INTEGRATION_ENABLED === 'true') {
    gettingStartedItemsObject.integrateWithSlack.isCompleted = wasSlackIntegrationCompleted;
  }
  trackEventGettingStarted(GettingStartedUITrackingEvent.GETTING_STARTED_STATUS, {
    defineSensitivity: gettingStartedItemsObject.defineSensitivity?.isCompleted,
    connectAndScan: gettingStartedItemsObject.connectAndScan?.isCompleted,
    reduceRisks: gettingStartedItemsObject.reduceRisks?.isCompleted,
    addTeammates: gettingStartedItemsObject.addTeammates?.isCompleted,
    shareFindings: gettingStartedItemsObject.shareFindings?.isCompleted,
    addSlack: SLACK_INTEGRATION_ENABLED === 'true' ? gettingStartedItemsObject.integrateWithSlack?.isCompleted : false,
  });
  if (!SLACK_INTEGRATION_ENABLED || SLACK_INTEGRATION_ENABLED === 'false') {
    delete gettingStartedItemsObject.integrateWithSlack;
  }
  return gettingStartedItemsObject;
};

export const evaluatePreselectedItem = (items: GettingStartedItems): GettingStartedItem => {
  const firstNonCompletedItem = Object.values(items).find(item => {
    return !item?.isCompleted;
  });
  return firstNonCompletedItem ?? Object.values(items)[0];
};
