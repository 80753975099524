import React, { FC, MouseEvent } from 'react';
import { PrimaryButton, BigidSelect, BigidSelectOption } from '@bigid-ui/components';
import { Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { BigidFormBox } from '../../components/BigidFormBox/BigidFormBox';
import { BigidSelectDockerImagesOptions } from '../CustomApp/utils/CustomAppTypes';

interface Props {
  onDeploy: (event?: MouseEvent) => void;
  onChange: (options: BigidSelectDockerImagesOptions[]) => void;
  appOptions: BigidSelectDockerImagesOptions[];
  selectedApp: BigidSelectDockerImagesOptions[];
  enableInstallButton: boolean;
}

const useStyles = makeStyles({
  appDeployFromStore: {
    display: 'flex',
    alignItems: 'center',
    paddingRight: 8,
    width: '70%',
  },
  buttonWrapper: {
    marginLeft: 8,
  },
  formMarginBottom: {
    marginBottom: 30,
  },
});

export const DropdownDockerhubForm: FC<Props> = ({
  onDeploy,
  onChange,
  appOptions,
  selectedApp = [],
  enableInstallButton,
}) => {
  const classes = useStyles({});
  const handleOnChange = (option: BigidSelectOption[]) => {
    onChange(option as BigidSelectDockerImagesOptions[]);
  };

  const isAppSelected = !!selectedApp[0];
  return (
    <div className={classes.formMarginBottom}>
      <BigidFormBox title={'Deploy From Hub'} description={'Select a custom application to be deployed'}>
        <div className={classes.appDeployFromStore}>
          <BigidSelect
            menuPosition="fixed"
            placeholder="Choose your app!"
            options={appOptions}
            onChange={handleOnChange}
          />
          <div className={classes.buttonWrapper}>
            <PrimaryButton
              size="medium"
              onClick={onDeploy}
              disabled={!isAppSelected || !enableInstallButton}
              text="Install"
            />
          </div>
        </div>
        {isAppSelected && (
          <div>
            <Typography variant="body2" component="h1">
              {selectedApp[0].description ? selectedApp[0].description : 'No description available'}
            </Typography>
          </div>
        )}
      </BigidFormBox>
    </div>
  );
};
