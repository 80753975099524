import React, { FC, useCallback, useEffect, useRef, useState } from 'react';
import angular from 'angular';
import {
  BigidBody1,
  BigidForm,
  BigidFormField,
  BigidFormFieldTypes,
  BigidFormRenderProps,
  BigidFormValues,
} from '@bigid-ui/components';
import makeStyles from '@mui/styles/makeStyles';
import classnames from 'classnames';
import { debounce, isEqual } from 'lodash';
import { v4 as uuid } from 'uuid';
import { convertToAngular } from '../../../../common/services/convertToAngular';
import { httpService } from '../../../services/httpService';
import { FilesRetrievalDataSource, FilesRetrievalFormValues, FilesRetrievalSavedConfiguration } from './Types';
import { notificationService } from '../../../services/notificationService';

const useStyles = makeStyles({
  description: {
    padding: '1px 0px',
    width: '669px',
    height: '65px',
    marginBottom: '8px',
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
  },
  formField: {
    width: '400px',
  },
  toggle: {
    marginTop: '11px',
  },
  sharepointOnlineSection: {
    display: 'flex',
    flexDirection: 'row',
    gap: '24px',
  },
  sharepointOnlineSectionField: {
    width: '188px',
  },
});

const defaultConfiguration: FilesRetrievalFormValues = {
  shouldAutoRetrieveFiles: false,
  dataSourcesListToDisplay: [],
  savedDataSource: {},
  //pathToSave: '', //TODO: to be added later
  siteName: '',
  driveName: '',
};

export const FilesRetrieveLayout: FC = () => {
  const classes = useStyles();
  const [configFieldsValues, setConfigFieldsValues] = useState<FilesRetrievalFormValues>(defaultConfiguration);
  const [shouldRenderForm, setShouldRenderForm] = useState(false);
  const lastSavedFieldsValues = useRef(configFieldsValues);
  const [formKey, setFormKey] = useState<string>(uuid());

  const fields: BigidFormField[] = [
    {
      name: 'dataSourcesListToDisplay',
      type: BigidFormFieldTypes.SELECT,
      isRequired: true,
      label: 'Select a destination data repository',
      options: configFieldsValues.dataSourcesListToDisplay,
      description: 'This is the data repository where the files will be saved.',
      fieldProps: {
        placeholder: `For example: Sharepoint-Online`,
      },
    },
    //TODO: to be added later
    // {
    //   name: 'pathToSave',
    //   type: BigidFormFieldTypes.TEXT,
    //   label: 'Enter destination Path',
    //   tooltipText: 'This is the location where the files will be saved',
    //   disabled: !(configFieldsValues.savedDataSource as FilesRetrievalDataSource)?.id,
    //   fieldProps: {
    //     placeholder: `For example: C:\\Documents\\Newsletters`,
    //   },
    // },
    {
      name: 'siteName',
      type: BigidFormFieldTypes.TEXT,
      //isRequired: true, //TODO: to be added later
      label: 'Enter Site',
      disabled: !(configFieldsValues.savedDataSource as FilesRetrievalDataSource)?.id,
      fieldProps: {
        placeholder: `Ex.: /Sites/retrieveFiles`,
      },
    },
    {
      name: 'driveName',
      type: BigidFormFieldTypes.TEXT,
      //isRequired: true, //TODO: to be added later
      label: 'Enter Drive',
      disabled: !(configFieldsValues.savedDataSource as FilesRetrievalDataSource)?.id,
      fieldProps: {
        placeholder: `Ex.: dsarFiles`,
      },
    },
    {
      name: 'shouldAutoRetrieveFiles',
      type: BigidFormFieldTypes.SWITCH,
      label: 'Automatically retrieve the files that were found on the requested Data Subject',
      disabled: !(configFieldsValues.savedDataSource as FilesRetrievalDataSource)?.id,
    },
  ];

  const saveInitialValuesAndUpdateState = ({ dataSourcesListToDisplay, ...rest }: FilesRetrievalFormValues) => {
    lastSavedFieldsValues.current = rest;
    setConfigFieldsValues({ ...rest, dataSourcesListToDisplay });
    setShouldRenderForm(true);
  };

  const fetchFilesRetrievalSavedSettings = useCallback(async () => {
    try {
      const {
        data: { data },
      } = await httpService.fetch(`sar/files-retrieval-config`);
      saveInitialValuesAndUpdateState(data as FilesRetrievalFormValues);
      setFormKey(uuid());
    } catch (err) {
      notificationService.error(`Failed to fetch settings. ${err}`);
    }
  }, []);

  useEffect(() => {
    fetchFilesRetrievalSavedSettings();
  }, [fetchFilesRetrievalSavedSettings]);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const renderSharePointOnline = (renderField: any) => {
    return (
      <div className={classnames(classes.sharepointOnlineSection, classes.formField)}>
        {<div className={classes.sharepointOnlineSectionField}>{renderField('siteName')}</div>}
        {<div className={classes.sharepointOnlineSectionField}>{renderField('driveName')}</div>}
      </div>
    );
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const selectSectionToRender = (dataSourceType: string, renderField: any) => {
    switch (dataSourceType) {
      case 'sharepoint-online':
        return renderSharePointOnline(renderField);
    }
  };

  const renderForm = ({ renderField }: BigidFormRenderProps) => {
    return (
      <div className={classes.form}>
        <div className={classes.formField}>{renderField('dataSourcesListToDisplay')}</div>
        {/* <div className={classes.formField}>{renderField('pathToSave')}</div> */}
        {selectSectionToRender(configFieldsValues?.savedDataSource?.type, renderField)}
        <div className={classes.toggle}>{renderField('shouldAutoRetrieveFiles')}</div>
      </div>
    );
  };

  const callUpdateFormApi = debounce(async (newFormValues: FilesRetrievalSavedConfiguration) => {
    try {
      const {
        data: { data },
      } = await httpService.post(`sar/files-retrieval-config`, { ...newFormValues });
      setConfigFieldsValues({
        ...data,
        dataSourcesListToDisplay: configFieldsValues.dataSourcesListToDisplay,
      });
      notificationService.success(`Changes saved.`);
    } catch (err) {
      notificationService.error(`Failed to save changes. ${err}`);
    }
  }, 1000);

  const onFormChange = async ({ dataSourcesListToDisplay, ...rest }: BigidFormValues) => {
    const selectedDataSource: FilesRetrievalDataSource = dataSourcesListToDisplay?.[0];
    const newFormValues = { ...rest, savedDataSource: selectedDataSource } as FilesRetrievalSavedConfiguration;
    if (selectedDataSource && !isEqual(newFormValues, lastSavedFieldsValues.current)) {
      lastSavedFieldsValues.current = newFormValues;
      callUpdateFormApi(newFormValues);
    }
  };

  const dataSourcesListToDisplayInitialValue = Object.keys(configFieldsValues.savedDataSource).length
    ? [configFieldsValues.savedDataSource]
    : [];

  return (
    <>
      {shouldRenderForm && (
        <div data-aid="files-retrieval-form" className="files-retrieval-form-wrapper">
          <BigidBody1 className={classes.description}>
            Define the target data repository where the files of the DSAR request will be saved (including personal
            information). Also indicate if you want the downloads to start automatically with every request, or
            manually.
          </BigidBody1>
          <BigidForm
            key={formKey}
            fields={fields}
            initialValues={{ ...configFieldsValues, dataSourcesListToDisplay: dataSourcesListToDisplayInitialValue }}
            controlButtons={false}
            onChange={onFormChange}
            renderForm={renderForm}
          />
        </div>
      )}
    </>
  );
};

angular.module('app').component('filesRetrievalLayout', convertToAngular(FilesRetrieveLayout));
