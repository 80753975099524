import { analyticsService } from '../../services/analyticsService';

export const trackManualEvent = (eventType: RisksAndControlsTrackingEvents, eventData?: Record<string, unknown>) => {
  analyticsService.trackManualEvent(eventType, eventData);
};

export enum RisksAndControlsTrackingEvents {
  RISK_CONTROL_ADD_CONTROL = 'RISK_CONTROL_ADD_CONTROL',
  RISK_CONTROL_UPDATE_CONTROL = 'RISK_CONTROL_UPDATE_CONTROL',
  RISK_CONTROL_DELETE_CONTROL = 'RISK_CONTROL_DELETE_CONTROL',
  RISK_CONTROL_DUPLICATE_CONTROL = 'RISK_CONTROL_DUPLICATE_CONTROL',
  RISK_LIBRARY_ADD_RISK = 'RISK_LIBRARY_ADD_RISK',
  RISK_LIBRARY_UPDATE_RISK = 'RISK_LIBRARY_UPDATE_RISK',
  RISK_LIBRARY_DELETE_RISK = 'RISK_LIBRARY_DELETE_RISK',
  RISK_LIBRARY_DUPLICATE_RISK = 'RISK_LIBRARY_DUPLICATE_RISK',
}
