import React, { FC } from 'react';
import { BigidLayoutEmptyState, BigidLayoutEmptyStateAction } from '@bigid-ui/layout';
import { BigidEmailTemplateIllustration, BigidAddIcon } from '@bigid-ui/icons';
import { useLocalTranslation } from '../translations';
import { EMAIL_TEMPLATES_PERMISSIONS } from '@bigid/permissions';
import { isPermitted } from '../../../services/userPermissionsService';

interface BrandsEmptyStateProps {
  handleBrandAdd: () => void;
}

export const BrandsEmptyState: FC<BrandsEmptyStateProps> = ({ handleBrandAdd }) => {
  const { t } = useLocalTranslation();

  const emptyStateActions: BigidLayoutEmptyStateAction[] = [
    {
      execute: async () => {
        handleBrandAdd();
        return { shouldGridReload: false };
      },
      icon: BigidAddIcon,
      label: t('buttons.addBrand'),
      show: () => isPermitted(EMAIL_TEMPLATES_PERMISSIONS.CREATE.name),
    },
  ];

  return (
    <BigidLayoutEmptyState
      actions={emptyStateActions}
      illustration={BigidEmailTemplateIllustration}
      description={t('message.manageBrands')}
    />
  );
};
