import React, { useCallback, useMemo, useRef, useState } from 'react';
import { BigidDeleteIcon, BigidEditIcon, BigidFiltersIllustration, BigidNoDataIllustration } from '@bigid-ui/icons';
import {
  BigidDropdownOption,
  BigidStatusBadge,
  BigidStatusBadgeSize,
  BigidStatusBadgeType,
  usePrevious,
} from '@bigid-ui/components';
import { BigidGridColumnTypes, BigidGridWithToolbar, BigidGridWithToolbarProps, BigidGridColumn } from '@bigid-ui/grid';
import { notificationService } from '../../services/notificationService';
import { useUserPreferences } from '../../components/hooks/useUserPrefrences';
import { DataExchangeType, Vendor, VendorProject, VendorsStatus, VendorStateNames } from './VendorsTypes';
import { CONFIG } from '../../../config/common';
import { getVendorProjects, getVendors } from './vendorsService';
import { DataSourceLayoutNameCell } from '../DataSources/DataSourceConnections/DataSourceLayoutNameCell';
import { DataSourceModel } from '../DataSources/DataSourceConnections/DataSourceConnectionTypes';
import { BigidLayoutEmptyState } from '@bigid-ui/layout';
import { CountryData } from '../Fmsd/fmsdServices';
import { useFetchEntity } from '../DSAR/SarProfileSettings/GridHelpers/useFetchEntity';
import { getCountries } from '../DSAR/dsarService';
import { fetchDataActivityOptions } from './VendorsEditDialog';
import { $state } from '../../services/angularServices';

import { EmptyStateWrapper } from './VendorsSummaryStyles';
import { isPermitted } from '../../services/userPermissionsService';
import { VENDORS_PERMISSIONS } from '@bigid/permissions';
import { VendorDeleteDetails } from './VendorsDeleteDialog';
import { VendorsTrackingEvents, trackVendors } from './utils/analytics';

type Grid = BigidGridWithToolbarProps<VendorProject>;

const statusToBadgeMapper = {
  [VendorsStatus.ACTIVE]: BigidStatusBadgeType.SUCCESS,
  [VendorsStatus.INACTIVE]: BigidStatusBadgeType.PENDING,
  [VendorsStatus.CANDIDATE]: BigidStatusBadgeType.INFO,
};

const columns: BigidGridColumn<VendorProject>[] = [
  {
    width: 170,
    title: 'Vendor',
    name: 'vendor.name',
    type: BigidGridColumnTypes.CUSTOM,
    getCellValue: ({ vendorName, vendorType }) => {
      return (
        <DataSourceLayoutNameCell
          row={{ id: vendorName, type: vendorType, displayType: vendorName } as DataSourceModel}
          iconWidth={24}
          isPlainText
        />
      );
    },
    filteringEnabled: false,
    sortingEnabled: false,
  },
  {
    width: 180,
    title: 'Vendor Engagement',
    name: 'name',
    type: BigidGridColumnTypes.TEXT,
    getCellValue: ({ name }) => name,
    filteringEnabled: false,
    sortingEnabled: true,
  },
  {
    width: 170,
    title: 'Status',
    name: 'status',
    type: BigidGridColumnTypes.CUSTOM,
    getCellValue: ({ status }) => {
      const type = statusToBadgeMapper[status] || BigidStatusBadgeType.INFO;
      return <BigidStatusBadge label={status} type={type} size={BigidStatusBadgeSize.SMALL} />;
    },
    filteringEnabled: false,
    sortingEnabled: false,
  },
  {
    width: 280,
    title: 'Location',
    name: 'location',
    type: BigidGridColumnTypes.CHIPS,
    getCellValue: ({ locations }) => ({
      chips: {
        value: locations?.map(location => ({ label: location })),
        isDisabled: true,
      },
    }),
    filteringEnabled: false,
    sortingEnabled: false,
  },
  {
    width: 200,
    title: 'Business Owner',
    name: 'business_owner',
    type: BigidGridColumnTypes.TEXT,
    getCellValue: ({ businessOwner }) => businessOwner,
    filteringEnabled: false,
    sortingEnabled: false,
  },
  {
    width: 280,
    title: 'Assigned Assets',
    name: 'assigned_assets',
    type: BigidGridColumnTypes.CHIPS,
    getCellValue: ({ assets }) => {
      return {
        chips: {
          value: assets?.map(asset => ({
            label: asset.name,
          })),
          isDisabled: true,
        },
      };
    },
    filteringEnabled: false,
    sortingEnabled: false,
  },
  {
    width: 200,
    title: 'Created At',
    name: 'created_at',
    type: BigidGridColumnTypes.DATE,
    getCellValue: ({ createdAt }) => createdAt,
    filteringEnabled: false,
    sortingEnabled: false,
  },
  {
    width: 220,
    title: 'Data Exchange Type',
    name: 'dataExchangeTypes',
    type: BigidGridColumnTypes.TEXT,
    getCellValue: ({ dataExchangeTypes }) => dataExchangeTypes?.join(', '),
    filteringEnabled: false,
    sortingEnabled: false,
    isHiddenByDefault: true,
  },
  {
    width: 200,
    title: 'Data Activities',
    name: 'dataActivities',
    type: BigidGridColumnTypes.TEXT,
    getCellValue: ({ dataActivities }) => dataActivities?.map(({ name }) => name).join(', '),
    filteringEnabled: false,
    sortingEnabled: false,
    isHiddenByDefault: true,
  },
  {
    width: 200,
    title: 'Purpose of Data Collecting',
    name: 'purposeOfDataCollecting',
    type: BigidGridColumnTypes.TEXT,
    getCellValue: ({ purposeOfDataCollecting }) => purposeOfDataCollecting?.map(({ name }) => name).join(', '),
    filteringEnabled: false,
    sortingEnabled: false,
    isHiddenByDefault: true,
  },
  {
    width: 200,
    title: 'Name',
    name: 'contactPerson.name',
    type: BigidGridColumnTypes.TEXT,
    getCellValue: ({ contactPerson }) => contactPerson?.name,
    filteringEnabled: false,
    sortingEnabled: false,
    isHiddenByDefault: true,
  },
  {
    width: 200,
    title: 'Role',
    name: 'contactPerson.role',
    type: BigidGridColumnTypes.TEXT,
    getCellValue: ({ contactPerson }) => contactPerson?.role,
    filteringEnabled: false,
    sortingEnabled: false,
    isHiddenByDefault: true,
  },
  {
    width: 200,
    title: 'Phone',
    name: 'contactPerson.phone',
    type: BigidGridColumnTypes.TEXT,
    getCellValue: ({ contactPerson }) => contactPerson?.phone,
    filteringEnabled: false,
    sortingEnabled: false,
    isHiddenByDefault: true,
  },
  {
    width: 200,
    title: 'Email',
    name: 'contactPerson.email',
    type: BigidGridColumnTypes.TEXT,
    getCellValue: ({ contactPerson }) => contactPerson?.email,
    filteringEnabled: false,
    sortingEnabled: false,
    isHiddenByDefault: true,
  },
  {
    width: 200,
    title: 'Group Email',
    name: 'contactPerson.groupEmail',
    type: BigidGridColumnTypes.TEXT,
    getCellValue: ({ contactPerson }) => contactPerson?.groupEmail,
    filteringEnabled: false,
    sortingEnabled: false,
    isHiddenByDefault: true,
  },
  {
    width: 200,
    title: 'Company Address',
    name: 'contactPerson.companyAddress',
    type: BigidGridColumnTypes.TEXT,
    getCellValue: ({ contactPerson }) => contactPerson?.companyAddress,
    filteringEnabled: false,
    sortingEnabled: false,
    isHiddenByDefault: true,
  },
  {
    width: 200,
    title: 'Company Website',
    name: 'contactPerson.companyWebsite',
    type: BigidGridColumnTypes.TEXT,
    getCellValue: ({ contactPerson }) => contactPerson?.companyWebsite,
    filteringEnabled: false,
    sortingEnabled: false,
    isHiddenByDefault: true,
  },
];

export const VendorsGrid = ({
  openCreateEditVendorDialog,
  openDeleteVendorDialog,
  forceUpdateKeys: { forceUpdateVendorsKey, ForceUpdateDataActivityKey },
}: {
  openCreateEditVendorDialog: (vendorProject?: VendorProject) => void;
  openDeleteVendorDialog: (VendorDeleteDetails: VendorDeleteDetails) => void;
  forceUpdateKeys: {
    forceUpdateVendorsKey: number;
    ForceUpdateDataActivityKey: number;
  };
}) => {
  const gridFilter = useRef(null);
  const prevVendorsKey = usePrevious(forceUpdateVendorsKey) ?? 0;
  const shouldUpdateVendors = forceUpdateVendorsKey !== prevVendorsKey;
  const prevDataExchangeTypeKey = usePrevious(ForceUpdateDataActivityKey) ?? 0;
  const shouldUpdateDataExchangeType = ForceUpdateDataActivityKey !== prevDataExchangeTypeKey;
  const [showEmptyState, setShowEmptyState] = useState(false);
  const locations = useFetchEntity(fetchLocations);
  const vendorNames = useFetchEntity(fetchVendorNames, { skipFetching: !locations, forceUpdate: shouldUpdateVendors });
  const dataActivities = useFetchEntity(fetchDataActivityOptions, {
    skipFetching: !locations,
    forceUpdate: shouldUpdateDataExchangeType,
  });

  const getInitialFilterToolbarConfig = useCallback(
    () => getFilterConfig(locations, dataActivities, vendorNames),
    [locations, dataActivities, vendorNames, forceUpdateVendorsKey, ForceUpdateDataActivityKey],
  );

  const {
    isReady,
    key: gridKey,
    preferences,
    gridColumns,
    filterToolbarConfig,
    updatePreferences,
  } = useUserPreferences({
    stateName: VendorStateNames.VENDORS_LISTING,
    initialGridColumns: columns,
    getInitialFilterToolbarConfig,
  });

  const gridWithToolbarConfig: BigidGridWithToolbarProps<VendorProject> = useMemo(() => {
    const gridWithToolbarConfig: BigidGridWithToolbarProps<VendorProject> = {
      showFilteringControls: true,
      columns: gridColumns,
      entityName: 'Records',
      defaultSorting: preferences?.grid?.sort || [{ field: 'name', order: 'asc' }],
      onGridStateChange: ({ filter, ...gridState }) => updatePreferences({ filterState: { filter }, gridState }),
      toolbarActions: [
        {
          label: 'Edit',
          icon: BigidEditIcon,
          execute: async ({ selectedRows: [vendorProject] }) => {
            trackVendors(VendorsTrackingEvents.EDIT_VENDOR_DIALOG_OPEN_INLINE_ACTION);
            await openCreateEditVendorDialog(vendorProject);
            return { shouldClearSelection: true, shouldGridReload: true };
          },
          disable: () => false,
          show: () => isPermitted(VENDORS_PERMISSIONS.VENDOR_EDIT.name),
          isInline: true,
          hideActionInToolBar: true,
        },
        {
          label: 'Delete',
          icon: BigidDeleteIcon,
          execute: async ({ selectedRows: [VendorDeleteDetails] }) => {
            trackVendors(VendorsTrackingEvents.DELETE_VENDOR_INLINE_ACTION);
            openDeleteVendorDialog({ id: VendorDeleteDetails.id, name: VendorDeleteDetails.name });
            return { shouldClearSelection: true, shouldGridReload: true };
          },
          show: () => isPermitted(VENDORS_PERMISSIONS.VENDOR_DELETE.name),
          isInline: true,
          hideActionInToolBar: true,
        },
      ],
      filterToolbarConfig,
      onRowClick: project => {
        $state.go(
          CONFIG.states.VENDOR_SUMMARY,
          { projectId: project.id, tab: 'summary' },
          { reload: false, notify: false, inherit: true },
        );
      },
      fetchData: async gridQueryParams => {
        try {
          gridFilter.current = gridQueryParams.filter;
          const { vendorProjects, totalCount, hasProjects } = await getVendorProjects(gridQueryParams);
          if (totalCount === 0) {
            setShowEmptyState(hasProjects);
          }
          return {
            data: vendorProjects,
            totalCount,
          };
        } catch (err) {
          notificationService.error(`Failed to fetch Vendors.`);
          console.error(`Failed to fetch Vendors': ${JSON.stringify(err)}`);
          return { data: [], totalCount: 0 };
        }
      },
      noDataContent: (
        <EmptyStateWrapper>
          <BigidLayoutEmptyState
            actions={
              showEmptyState
                ? []
                : [
                    {
                      label: 'Create new vendor',
                      execute: async () => {
                        trackVendors(VendorsTrackingEvents.CREATE_VENDOR_DIALOG_OPEN_ACTION);
                        openCreateEditVendorDialog();
                        return { shouldGridReload: false };
                      },
                      show: () => isPermitted(VENDORS_PERMISSIONS.VENDOR_CREATE.name),
                    },
                  ]
            }
            illustration={showEmptyState ? BigidFiltersIllustration : BigidNoDataIllustration}
            description={showEmptyState ? 'There are no results for this filter' : 'Get started with Vendor Management'}
          />
        </EmptyStateWrapper>
      ),
    };

    return gridWithToolbarConfig;
  }, [
    gridColumns,
    preferences?.grid?.sort,
    filterToolbarConfig,
    showEmptyState,
    updatePreferences,
    openCreateEditVendorDialog,
    openDeleteVendorDialog,
  ]);

  return <>{isReady && <BigidGridWithToolbar key={gridKey} {...gridWithToolbarConfig} />}</>;
};

const getFilterConfig = async (
  locations: CountryData[] = [],
  dataActivities: BigidDropdownOption[] = [],
  vendorNames: Vendor[] = [],
): Promise<Grid['filterToolbarConfig']> => {
  return {
    filters: [
      {
        title: 'Status',
        field: 'status',
        operator: 'in',
        single: true,
        value: [],
        options: [
          { value: VendorsStatus.ACTIVE, label: VendorsStatus.ACTIVE, isSelected: false },
          { value: VendorsStatus.INACTIVE, label: VendorsStatus.INACTIVE, isSelected: false },
          { value: VendorsStatus.CANDIDATE, label: VendorsStatus.CANDIDATE, isSelected: false },
        ],
      },
      {
        title: 'Vendors',
        field: 'vendor.id',
        operator: 'in',
        listWidth: 400,
        value: [],
        options: vendorNames.map(vendor => ({
          value: vendor.id,
          label: vendor.name,
          isSelected: false,
        })),
      },
      {
        title: 'Locations',
        field: 'locations',
        operator: 'in',
        listWidth: 400,
        displayLimit: 300,
        disabled: true,
        value: [],
        options: locations.map(location => ({
          id: location.groupedCode,
          value: location.name,
          label: location.displayName,
          isSelected: false,
        })),
      },
      {
        title: 'Data Exchange Type',
        field: 'dataExchangeTypes',
        operator: 'in',
        single: false,
        disabled: true,
        value: [],
        options: [
          { value: DataExchangeType.DATA_PROCESSOR, label: DataExchangeType.DATA_PROCESSOR, isSelected: false },
          { value: DataExchangeType.DATA_CONTROLLER, label: DataExchangeType.DATA_CONTROLLER, isSelected: false },
        ],
      },
      {
        title: 'Data Activity',
        field: 'dataActivities',
        operator: 'in',
        disabled: true,
        value: [],
        options: dataActivities.map(da => ({ value: da.id, label: da.displayValue, isSelected: false })),
      },
    ],
    searchConfig: {
      searchFilterKeys: ['name'],
      initialValue: '',
      operator: 'textSearch',
    },
  };
};

const fetchLocations = async () => {
  try {
    const countries = await getCountries();
    return countries;
  } catch (err) {
    notificationService.error(`Failed to fetch Locations`);
    console.error(`Failed to fetch Locations: ${JSON.stringify(err?.response)}`);
    return [];
  }
};

const fetchVendorNames = async () => {
  try {
    const vendorNames = (await getVendors()).vendors;
    return vendorNames;
  } catch (err) {
    notificationService.error(`Failed to fetch Vendors`);
    console.error(`Failed to fetch Vendors: ${JSON.stringify(err?.response)}`);
    return [];
  }
};
