import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles({
  firstStepWrapper: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
  },
  heading6: { fontWeight: 400 },
  heading4: { paddingBottom: 64, paddingTop: 12, textAlign: 'center' },
  body1: { paddingTop: 48, maxWidth: 624, textAlign: 'center' },
});
