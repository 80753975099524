import { module } from 'angular';
const app = module('app');

app.factory('consentConnectionsService', function ($http, appSettings, notificationService) {
  'ngInject';
  return {
    getConsentConnectionsData: () => {
      return $http
        .get(appSettings.serverPath + '/' + appSettings.version + '/consent/connections')
        .then(response => response.data);
    },

    getConsentConnectionsDataByID: consentId => {
      return $http.get(appSettings.serverPath + '/' + appSettings.version + `/consent/connections/${consentId}`).then(
        response => response.data,
        err => {
          window.console.error(err);
          notificationService.error(`Can't find consent`);
          throw err;
        },
      );
    },

    deleteConsentConnectionsDataByID: consentId => {
      return $http
        .delete(appSettings.serverPath + '/' + appSettings.version + `/consent/connections/${consentId}`)
        .then(response => response.data);
    },

    updateConsentConnection: (consentId, consentConnection) => {
      return $http.put(
        appSettings.serverPath + '/' + appSettings.version + '/consent/connections/' + consentId,
        consentConnection,
      );
    },

    addConsentConnection: consentConnection => {
      return $http.post(appSettings.serverPath + '/' + appSettings.version + '/consent/connections', consentConnection);
    },

    copyConsent: consent => {
      return $http.post(appSettings.serverPath + '/' + appSettings.version + '/consent/connections', {
        consent_connection: consent,
        clone: true,
      });
    },

    testConnection: consentConnection => {
      return $http.post(
        appSettings.serverPath + '/' + appSettings.version + '/consent/connections/test/',
        consentConnection,
      );
    },
  };
});
