import { DateISO8601 } from '../../../types/types';
import { CONFIG } from '../../../../config/common';
import { DsarTabPaths } from '../DsarTypes';

export enum SarProfileSourceTypes {
  ES = 'es',
  DS = 'ds',
}

export enum SarProfileTabNames {
  ES = 'es',
  DS = 'ds',
  FIELDS = 'fields',
  OBJECTS = 'objects',
  MANUAL_RECORDS = 'manual_records',
}

export interface SarTab {
  title: string;
  selected: boolean;
  path: DsarTabPaths | SarProfileTabNames;
}

export interface SarMainTabsProps {
  tabs: SarTab[];
  onTabChange: (i: number) => void;
}

export interface TabsProps {
  tabs: SarTab[];
  selectedTabIndex: number;
  dataAid: string;
  onTabChange: (i: number) => void;
}

export interface Scope {
  id: string;
  name: string;
  updated_at?: DateISO8601;
  created_at?: DateISO8601;
  dataSourceNames?: string[];
  idSourceNames?: string[];
  description?: string;
}

export enum OnOffCellValue {
  ON = 'On',
  OFF = 'Off',
  NA = 'N/A',
}

export enum FieldType {
  ENTITY_SOURCE = 'Entity source',
  PROXIMITY = 'Proximity',
  CLASSIFICATION = 'Classification',
  CORRELATION = 'Correlation',
}

const newDsarSettingsUserPreferencesSuffix = 'v2';

export const NEW_DSAR_SETTINGS_STATE_NAMES = {
  LISTING: `${CONFIG.states.ACCESS_REQUEST_MANAGEMENT_PROFILE}.${newDsarSettingsUserPreferencesSuffix}`,
  ES: `${CONFIG.states.ACCESS_REQUEST_MANAGEMENT_PROFILE}.${SarProfileTabNames.ES}.${newDsarSettingsUserPreferencesSuffix}`,
  DS: `${CONFIG.states.ACCESS_REQUEST_MANAGEMENT_PROFILE}.${SarProfileTabNames.DS}.${newDsarSettingsUserPreferencesSuffix}`,
  FIELDS: `${CONFIG.states.ACCESS_REQUEST_MANAGEMENT_PROFILE}.${SarProfileTabNames.FIELDS}.${newDsarSettingsUserPreferencesSuffix}`,
  OBJECTS: `${CONFIG.states.ACCESS_REQUEST_MANAGEMENT_PROFILE}.${SarProfileTabNames.OBJECTS}`,
  MANUAL_RECORDS: `${CONFIG.states.ACCESS_REQUEST_MANAGEMENT_PROFILE}.${SarProfileTabNames.MANUAL_RECORDS}`,
  DICTIONARY: `${CONFIG.states.ACCESS_REQUEST_MANAGEMENT_DICTIONARY}`,
};
