import React, { FC } from 'react';
import styled from '@emotion/styled';
import { useLocalTranslation } from '../translations';
import { BigidLayoutEmptyState } from '@bigid-ui/layout';
import { BigidScanIllustration } from '@bigid-ui/icons';

const Container = styled('div')`
  width: 100%;
  height: 100%;
  position: absolute;
`;

export const NoDataContentCompletedScans: FC = () => {
  const { t } = useLocalTranslation('noDataContentCompletedScans');

  return (
    <Container>
      <BigidLayoutEmptyState description={t('noCompletedScans')} illustration={BigidScanIllustration} />
    </Container>
  );
};
