import React, { useContext, useEffect, useState } from 'react';
import { BigidBody1, BigidHeading5, BigidLoader, BigidSensitivityBadge, BigidTag } from '@bigid-ui/components';
import styled from '@emotion/styled';
import { CaseSidePanelContext } from '../../hooks/CaseSidePanelContext';
import { BigidDataSourceOnlyIcon, BigidLocationIcon, BigidUserIcon } from '@bigid-ui/icons';
import { TagEntity, getTagsAllPairs } from '../../../../TagsManagement/TagsManagementService';
import { ReducerActions } from '../../hooks/CaseSidePanelReducer';
import { generateDataAid } from '@bigid-ui/utils';
import {
  getDsOwners,
  getInfoForUser,
  mapTags,
  getSensitivityLevel,
  getDsConnectionsInfo,
  SENSITIVITY_CLASSIFICATION_TAG_NAME,
} from '../caseReportService';
import { User } from '../../../../../utilities/systemUsersUtils';

const Main = styled.div<{
  isVisible?: boolean;
}>(({ isVisible = true }) => ({
  display: isVisible ? 'flex' : 'none',
  flexDirection: 'column',
  rowGap: 16,
  padding: '16px 0',
  position: 'relative',
  minHeight: '100px',
}));

const MainInfo = styled.div({
  display: 'flex',
  columnGap: 32,
  flexWrap: 'wrap',
  rowGap: 16,
});

const Badge = styled.div<{
  isVisible?: boolean;
}>(({ isVisible = true }) => ({
  display: isVisible ? 'flex' : 'none',
  columnGap: '4px',
  alignItems: 'center',
}));

const Tags = styled.div<{
  isVisible?: boolean;
}>(({ isVisible = true }) => ({
  display: isVisible ? 'flex' : 'none',
  columnGap: '6px',
  rowGap: '6px',
  alignItems: 'center',
  flexWrap: 'wrap',
}));

export const DsInfoWidget = () => {
  const { caseSidePanelData, dispatch } = useContext(CaseSidePanelContext);
  const { dataSourceName } = caseSidePanelData;
  const [isLoading, setIsLoading] = useState(true);
  const [dsInfo, setDsInfo] = useState<any>();
  const [allTags, setAllTags] = useState<TagEntity[]>([]);
  const [owners, setOwners] = useState<User[]>([]);
  const [sensitivityTags, setSensitivityTags] = useState([]);
  const dataAid = 'CaseWidgetDsInfo';

  useEffect(() => {
    const getDsInfo = async () => {
      try {
        const [
          {
            data: { ds_connection },
          },
          allPairTags,
        ] = await Promise.all([getDsConnectionsInfo(dataSourceName), getTagsAllPairs()]);
        setDsInfo(ds_connection);
        const allOwners: User[] = await getDsOwners(ds_connection);
        setOwners(allOwners);
        const mapperTags = mapTags(allPairTags, ds_connection?.tags);
        const sensTags = mapperTags.filter((tag: TagEntity) => tag.tagName === SENSITIVITY_CLASSIFICATION_TAG_NAME);
        setAllTags(mapperTags);
        setSensitivityTags(sensTags);
      } catch {
        dispatch({
          type: ReducerActions.UPDATE_WIDGETS_ERROR_STATUS,
          payload: { hasWidgetsErrors: true },
        });
      } finally {
        setIsLoading(false);
      }
    };

    getDsInfo();
  }, [dataSourceName, dispatch]);

  return (
    <Main data-aid={dataAid}>
      {isLoading ? (
        <BigidLoader />
      ) : (
        <>
          <BigidHeading5>Data source info</BigidHeading5>
          <MainInfo>
            {sensitivityTags?.map((tag: TagEntity) => (
              <BigidSensitivityBadge level={getSensitivityLevel(tag?.tagValue)} key={tag?.tagId} />
            ))}

            <Badge data-aid={generateDataAid(dataAid, ['DsName'])}>
              <BigidDataSourceOnlyIcon />
              <BigidBody1>{dataSourceName}</BigidBody1>
            </Badge>
            <Badge isVisible={Boolean(dsInfo?.location)} data-aid={generateDataAid(dataAid, ['DsLocation'])}>
              <BigidLocationIcon />
              <BigidBody1>{dsInfo?.location}</BigidBody1>
            </Badge>

            {owners.map((owner: User, index: number) => (
              <Badge data-aid={generateDataAid(dataAid, ['DsOwner', index + 1])} key={owner?.id}>
                <BigidUserIcon />
                <BigidBody1>{getInfoForUser(owner)}</BigidBody1>
              </Badge>
            ))}
          </MainInfo>
          <Tags>
            {allTags.map((tag: TagEntity) => (
              <BigidTag name={tag?.tagName} value={tag?.tagValue} key={tag?.tagId} />
            ))}
          </Tags>
        </>
      )}
    </Main>
  );
};
