import React, { FC, useEffect, useRef, useContext, useState, useCallback } from 'react';
import { CustomAppStatus } from '../../../../../../../CustomApp/views/ActivityLog/ActivityLog';
import makeStyles from '@mui/styles/makeStyles';
import {
  BigidBody1,
  PrimaryButton,
  BigidForm,
  BigidFormProps,
  BigidFormValues,
  BigidFormStateAndHandlers,
  BigidFormFieldTypes,
  BigidFormValidateOnTypes,
  BigidLoader,
} from '@bigid-ui/components';
import AzureLogo from '../../../../../../../../assets/icons/AzureLogo.svg';
import { PreDiscoverContext, ReducerActions, ConnectionInfo } from '../../../../../hooks';
import {
  getDiscoveryAppPermisions,
  getDiscoveryAppPermisionsWithoutCreds,
  getAutoDiscoveryAppIds,
  getLastRunInfo,
} from '../../../../../../../../services/autoDiscoveryService';
import { CloudProvider } from '../../../../../../../AutoDiscoveryWizard/autoDiscoveryWizardServices';
import { notificationService } from '../../../../../../../../services/notificationService';
import { CredsStatus, ConnectionsStatus } from '../../../../../../FmsdComponents';
import { trackFmsdEvent, FmsdTrackingEvents } from '../../../../../../fmsdEventsTrackerUtils';
import { useLocalTranslation } from '../../../../../../translations';

export interface AutoDiscoveryAppIdsType {
  id: string;
  tpaId: string;
}

const MASKED_INPUT_PLACEHOLDER = '•••••••••••••••••••';

const useStyles = makeStyles(theme => ({
  headerContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    paddingBottom: 8,
  },
  logoContainer: {
    height: 72,
    width: 72,
    border: `1px solid ${theme.vars.palette.bigid.gray600}`,
    borderRadius: 50,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '& svg': {
      marginTop: -6,
    },
  },
  titleContainer: {
    display: 'flex',
    flexDirection: 'column',
    color: theme.vars.palette.bigid.gray700,
    paddingLeft: 16,
  },
  title: {
    color: theme.vars.palette.bigid.gray700,
    fontWeight: 600,
    fontSize: 18,
    lineHeight: '25px',
    paddingBottom: 4,
  },
  mainContainer: {
    padding: '0 16px',
    display: 'flex',
    flexDirection: 'column',
  },
  buttonContainer: {
    paddingTop: 14,
    alignItems: 'center',
    flexDirection: 'column',
    display: 'flex',
    '& button': {
      maxWidth: 'unset',
    },
  },
  formContainer: {
    '& *': {
      boxSizing: 'content-box',
    },
  },
  iamModalLink: {
    color: theme.vars.palette.bigid.blue700,
    textDecoration: 'underline',
    fontWeight: 700,
    cursor: 'pointer',
    fontSize: 14,
    lineHeight: '21px',
  },
}));

export interface AzureConnectionProps {
  onGeneratePolicyClick: () => void;
}

export const AzureConnection: FC<AzureConnectionProps> = ({ onGeneratePolicyClick }) => {
  const classes = useStyles({});
  const {
    connections,
    wizardActions: { setStepAsActive, setIsNextButtonDisabled },
    dispatch,
  } = useContext(PreDiscoverContext);

  const formControls = useRef<BigidFormStateAndHandlers>();
  const setStepAsActiveRef = useRef(setStepAsActive);
  const [isLoading, setIsLoading] = useState(true);
  const [isPermissionsLoading, setIsPermissionsLoading] = useState(false);
  const [appIds, setAppIds] = useState<AutoDiscoveryAppIdsType>();
  const [clientIdInitialValue, setClientIdInitialValue] = useState('');
  const [clientSecretInitialValue, setClientSecretInitialValue] = useState('');
  const [tenantIdInitialValue, setTenantIdInitialValue] = useState('');
  const [subscriptionIdInitialValue, setSubscriptionIdInitialValue] = useState('');
  const labelWidth = '150px';
  const dataAid = 'FmsdConnect';
  const { t } = useLocalTranslation('Connect.Dialog');

  const removeLoaders = () => {
    setIsLoading(false);
    setIsPermissionsLoading(false);
  };

  const onSubmit = useCallback(() => {
    try {
      formControls.current.validateAndSubmit(async (values: BigidFormValues) => {
        setIsPermissionsLoading(true);
        setIsNextButtonDisabled(true);
        const { status_enum } = await getLastRunInfo(CloudProvider.AZURE);
        if (status_enum === CustomAppStatus.IN_PROGRESS) {
          trackFmsdEvent(FmsdTrackingEvents.FMSD_CONNECT_TEST_CONNECTION_CLICK, {
            [FmsdTrackingEvents.DS_TYPE]: CloudProvider.AZURE,
            [FmsdTrackingEvents.TEST_CONNECTION_STATUS]: CredsStatus.CANT_RUN,
          });
          dispatch({
            type: ReducerActions.UPDATE_PERMISSIONS,
            payload: { connections: { azure: { credsStatus: CredsStatus.CANT_RUN, permissions: [] } } },
          });
          removeLoaders();
        } else {
          const { clientId, clientSecret, tenantId, subscriptionId } = values;
          let connectedPermissions: ConnectionInfo;
          if (formControls.current?.touchedFields && Object.keys(formControls.current.touchedFields).length) {
            connectedPermissions = await getDiscoveryAppPermisions(
              CloudProvider.AZURE,
              { clientId, clientSecret, tenantId, subscriptionId },
              removeLoaders,
            );
          } else {
            connectedPermissions = await getDiscoveryAppPermisionsWithoutCreds(appIds, removeLoaders);
          }
          trackFmsdEvent(FmsdTrackingEvents.FMSD_CONNECT_TEST_CONNECTION_CLICK, {
            [FmsdTrackingEvents.DS_TYPE]: CloudProvider.AZURE,
            [FmsdTrackingEvents.TEST_CONNECTION_STATUS]: connectedPermissions.credsStatus,
          });
          dispatch({
            type: ReducerActions.UPDATE_PERMISSIONS,
            payload: { connections: { azure: connectedPermissions } },
          });
        }
      });
    } catch (e) {
      trackFmsdEvent(FmsdTrackingEvents.FMSD_CONNECT_TEST_CONNECTION_CLICK, {
        [FmsdTrackingEvents.DS_TYPE]: CloudProvider.AZURE,
        [FmsdTrackingEvents.TEST_CONNECTION_STATUS]: e.message,
      });
      notificationService.error(e.message);
      removeLoaders();
    }
  }, [appIds, dispatch, setIsNextButtonDisabled]);

  useEffect(() => {
    setStepAsActiveRef.current();

    async function fetchData() {
      try {
        const appIds = await getAutoDiscoveryAppIds(CloudProvider.AZURE, removeLoaders);
        if (appIds) {
          setClientIdInitialValue(MASKED_INPUT_PLACEHOLDER);
          setClientSecretInitialValue(MASKED_INPUT_PLACEHOLDER);
          setTenantIdInitialValue(MASKED_INPUT_PLACEHOLDER);
          setSubscriptionIdInitialValue(MASKED_INPUT_PLACEHOLDER);
          setAppIds(appIds);
        }
        setIsLoading(false);
      } catch (e) {
        notificationService.error(e.message);
      }
    }
    fetchData();
  }, []);

  const formProps: BigidFormProps = {
    initialValues: {
      clientId: clientIdInitialValue,
      clientSecret: clientSecretInitialValue,
      tenantId: tenantIdInitialValue,
      subscriptionId: subscriptionIdInitialValue,
    },
    validateOn: BigidFormValidateOnTypes.SUBMIT,
    stateAndHandlersRef: formControls,
    onSubmit,
    controlButtons: false,
    fields: [
      {
        name: 'clientId',
        label: t('azure.form.clientId.label'),
        isRequired: true,
        labelWidth,
        type: BigidFormFieldTypes.PASSWORD,
        misc: {
          showValueOnlyIfDirty: true,
        },
        validate: (clientId: string) => {
          if (!clientId) {
            return t('azure.form.clientId.validationMessage');
          }
          return false;
        },
      },
      {
        name: 'clientSecret',
        label: t('azure.form.clientSecret.label'),
        isRequired: true,
        labelWidth,
        type: BigidFormFieldTypes.PASSWORD,
        misc: {
          showValueOnlyIfDirty: true,
        },
        validate: (clientSecret: string) => {
          if (!clientSecret) {
            return t('azure.form.clientSecret.validationMessage');
          }
          return false;
        },
      },
      {
        name: 'tenantId',
        label: t('azure.form.tenantId.label'),
        isRequired: true,
        labelWidth,
        type: BigidFormFieldTypes.PASSWORD,
        misc: {
          showValueOnlyIfDirty: true,
        },
        validate: (tenantId: string) => {
          if (!tenantId) {
            return t('azure.form.tenantId.validationMessage');
          }
          return false;
        },
      },
      {
        name: 'subscriptionId',
        label: t('azure.form.subscriptionId.label'),
        isRequired: true,
        labelWidth,
        type: BigidFormFieldTypes.PASSWORD,
        misc: {
          showValueOnlyIfDirty: true,
        },
        validate: (subscriptionId: string) => {
          if (!subscriptionId) {
            return t('azure.form.subscriptionId.validationMessage');
          }
          return false;
        },
      },
    ],
  };

  return (
    <div className={classes.mainContainer}>
      <div className={classes.headerContainer}>
        <div className={classes.logoContainer}>
          <AzureLogo />
        </div>
        <div className={classes.titleContainer}>
          <div className={classes.title}>{t('azure.title')}</div>
          <BigidBody1>{t('body')}</BigidBody1>
          <div
            data-aid="fmsdTestConnection-open-azure-roles-modal"
            onClick={onGeneratePolicyClick}
            className={classes.iamModalLink}
          >
            {t('rolesModalOpenerText')}
          </div>
        </div>
      </div>
      {isLoading ? (
        <BigidLoader />
      ) : (
        <>
          <div className={classes.formContainer}>
            <BigidForm {...formProps} />
            <div className={classes.buttonContainer}>
              <PrimaryButton
                dataAid="fmsdTestConnection-azure-test-connection-button"
                text={t('testConnectionButton')}
                color="purple"
                size="medium"
                width="fill"
                disabled={isPermissionsLoading}
                onClick={formProps.onSubmit}
                bi={{ disabled: true }}
              />
            </div>
          </div>
          <ConnectionsStatus
            type={CloudProvider.AZURE}
            dataAid={dataAid}
            credsStatus={connections?.azure?.credsStatus || CredsStatus.INITIAL}
            permissions={connections?.azure?.permissions}
            isLoading={isPermissionsLoading}
          />
        </>
      )}
    </div>
  );
};
