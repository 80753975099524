import { module } from 'angular';
import { notificationService } from '../../react/services/notificationService';
import { httpService } from '../../react/services/httpService';

const app = module('app');

app.factory('locationService', [
  '$rootScope',
  function ($rootScope) {
    return {
      getCountriesList: async function getCountriesList() {
        try {
          const {
            data: { data: options },
          } = await httpService.fetch('countries');

          return options;
        } catch (e) {
          notificationService.error('Get Countries List Error');
          console.error(e);
        } finally {
          $rootScope.$applyAsync();
        }
      },
    };
  },
]);
