import React, { useEffect, useState } from 'react';
import { BigidXIcon } from '@bigid-ui/icons';
import { BigidInlineNotification, BigidLink, LinkColor, BigidInlineNotificationProps } from '@bigid-ui/components';
import { docsUrls } from '../../config/publicUrls';
import { userPreferencesService } from '../../services/userPreferencesService';
import { getApplicationPreference } from '../../services/appPreferencesService';
import { styled } from '@mui/material';
import { generateDataAid } from '@bigid-ui/utils';
import { useLocalTranslation } from './translations';

const NotificationWrapper = styled('div')`
  margin-bottom: 20px;
`;

const USER_PREFERENCES_IDENTIFIER = 'classifiersInfoNotification';
const DISABLE_WIDE_CLASSIFIERS = 'DISABLE_WIDE_CLASSIFIERS';

export const ClassifiersInfoNotification = () => {
  const { t } = useLocalTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const handleUserPreferenceManualStart = async () => {
    const userPreference = await userPreferencesService.get(USER_PREFERENCES_IDENTIFIER);
    const isShowNotification = getApplicationPreference(DISABLE_WIDE_CLASSIFIERS);

    if (!userPreference?.data?.isClassifiersNotificationClosed && isShowNotification) {
      setIsOpen(true);
    }
  };

  const handleClose = async () => {
    setIsOpen(false);
    const userPreference = await userPreferencesService.get(USER_PREFERENCES_IDENTIFIER);
    if (!userPreference?.data?.isClassifiersNotificationClosed) {
      await userPreferencesService.update({
        preference: USER_PREFERENCES_IDENTIFIER,
        data: { ...(userPreference?.data || {}), isClassifiersNotificationClosed: true },
      });
    }
  };

  useEffect(() => {
    handleUserPreferenceManualStart();
  }, []);

  const notificationContent = (
    <>
      {t('description')}
      <BigidLink
        color={LinkColor.PRIMARY}
        href={docsUrls.CLASSIFICATION_DOC}
        text={t('learnMore')}
        underline="hover"
        shouldOpenNewTab
      />
    </>
  );

  const props: BigidInlineNotificationProps = {
    type: 'info',
    title: t('title'),
    dataAid: 'classifiers-info',
    actionButton: {
      icon: BigidXIcon,
      onClick: handleClose,
    },
    text: [
      {
        subText: notificationContent,
      },
    ],
  };

  return (
    isOpen && (
      <NotificationWrapper data-aid={generateDataAid('NotificationWrapper', ['info'])}>
        <BigidInlineNotification {...props} open={true} />
      </NotificationWrapper>
    )
  );
};
