import React, { useEffect } from 'react';
import { BigidLoader } from '@bigid-ui/components';
import { navigateToScansPage } from './ScanUtils';

export const ScansPageRouter: React.FC = () => {
  useEffect(() => {
    navigateToScansPage();
  }, []);

  return <BigidLoader />;
};
