import { BigidBody2, BigidHeading6, BigidLink } from '@bigid-ui/components';
import { generateDataAid } from '@bigid-ui/utils';
import { styled } from '@mui/material';
import { take } from 'lodash';
import React, { FC, useState } from 'react';
import { useQuery } from 'react-query';
import { CONFIG } from '../../../../config/common';
import { $state } from '../../../services/angularServices';
import { getFollowedObjects } from '../../DataCatalog/DataCatalogService';
import { DEFAULT_NUMBER_OF_ITEMS_TO_DISPLAY } from '../constants';
import { useLocalTranslation } from '../translations';
import { ListEntityItem } from '../types';
import { EntityList } from './components/EntityList';
import { NoData } from './components/NoData';
import { BaseFooterSectionContainer } from './components/shared';
import { mapFollowedObjectsToListItems } from '../dataExplorerService';

const Container = styled(BaseFooterSectionContainer)`
  ${({ theme }) => theme.breakpoints.only('lg')} {
    width: 340px;
  }
  ${({ theme }) => theme.breakpoints.down('lg')} {
    width: 320px;
  }
`;

const Header = styled('div')<{ isEmptyState: boolean }>`
  display: flex;
  gap: 16px;
  align-items: center;
  justify-content: ${({ isEmptyState }) => (isEmptyState ? 'center' : 'flex-start')};
`;

type FollowedItemsProps = {
  dataAid?: string;
};

export const FollowedItems: FC<FollowedItemsProps> = ({ dataAid = 'FollowedItems' }) => {
  const [followedItems, setFollowedItems] = useState<ListEntityItem[]>([]);
  const { t } = useLocalTranslation('FollowedItems');

  const { isLoading } = useQuery('followedObjects', getFollowedObjects, {
    onSuccess: followedObjects => {
      const mapped = mapFollowedObjectsToListItems(followedObjects);
      setFollowedItems(mapped);
    },
    onError: () => {
      setFollowedItems([]);
    },
  });
  const handleShowAllClick = () => {
    $state.go(CONFIG.states.CATALOG_FOLLOWED_OBJECTS, {
      selectedTab: 'objects',
    });
  };

  const followedItemsToShow = take(followedItems, DEFAULT_NUMBER_OF_ITEMS_TO_DISPLAY);
  const shouldShowLink = followedItems.length > DEFAULT_NUMBER_OF_ITEMS_TO_DISPLAY;

  const isEmptyState = followedItems.length === 0;

  return (
    <Container data-aid={generateDataAid(dataAid, ['footer-section'])}>
      <Header isEmptyState={isEmptyState}>
        <BigidHeading6>{t('title')}</BigidHeading6>
        {shouldShowLink && (
          <BigidBody2>
            <BigidLink
              dataAid={generateDataAid(dataAid, ['link'])}
              text={t('showAll')}
              onClick={handleShowAllClick}
              underline="hover"
            />
          </BigidBody2>
        )}
      </Header>
      <EntityList
        entities={followedItemsToShow}
        noDataComponent={
          <NoData dataAid={generateDataAid(dataAid, ['no-data'])} description={t('noDataDescription')} />
        }
        dataAid={generateDataAid(dataAid, ['list'])}
        isLoaderShown={isLoading}
      />
    </Container>
  );
};
