import { useState } from 'react';
import { useGetDsConnectionDocs } from './useGetDsConnectionDocs';
import { useTimeout } from './useTimeout';
import { DsConnectionDocType, DsConnectionDocDataSourceAuthType, DsConnectionDocParams } from '../types';

type UseGetDsConnectionDocsOptions = typeof useGetDsConnectionDocs extends (
  dataSource: string,
  params?: DsConnectionDocParams,
  options?: infer T,
) => unknown
  ? T
  : never;

const CONNECTION_IDLE_TIMEOUT = 2000;

export const useGetConnectionGuide = (
  dataSourceType: string,
  dataSourceAuthType: DsConnectionDocDataSourceAuthType,
  options?: UseGetDsConnectionDocsOptions,
) => {
  const [isIdleTimeout, setIdleTimeout] = useState(false);
  const result = useGetDsConnectionDocs(
    dataSourceType,
    {
      dsConnectionDocType: DsConnectionDocType.PRE_CONNECTION,
      dataSourceAuthType: dataSourceAuthType,
    },
    { query: { enabled: options?.query?.enabled ?? (!!dataSourceType && !!dataSourceAuthType) } },
  );

  useTimeout(() => {
    setIdleTimeout(result.isIdle);
  }, CONNECTION_IDLE_TIMEOUT);

  return { ...result, isIdleTimeout };
};
