import { useCallback } from 'react';

export type AfterValueChangeFunction = (values: Record<string, any>) => void;

export const useAfterValuesChange = (functionsList: AfterValueChangeFunction[]) =>
  useCallback(
    async (values: Record<string, any>) => {
      window?.requestAnimationFrame(() => {
        functionsList.map(afterValuesChangeFunction => {
          afterValuesChangeFunction(values);
        });
      });
    },
    [functionsList],
  );
