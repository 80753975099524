import './activities.component.scss';
import template from './activities.component.html';
import { getCopyOfCountries } from '../../../config/countries';
import { module } from 'angular';
import { getApplicationPreference } from '../../../react/services/appPreferencesService';
const app = module('app');

app.component('activitiesCom', {
  template,
  controllerAs: 'activitiesModel',
  controller: [
    '$http',
    'appSettings',
    'activitiesService',
    '$scope',
    '$rootScope',
    'localStorageService',
    'queryStringService',
    '$attrs',
    function ($http, appSettings, activitiesService, $scope, $rootScope, localStorageService, queryStringService) {
      const activitiesModel = this;
      activitiesModel.countries = [];

      const getLocationToDisplay = systemLocation => {
        if (!systemLocation) {
          return '';
        }
        const country = find(activitiesModel.countries, { name: systemLocation });
        return country?.displayName || systemLocation;
      };

      $scope.activitiesModel = {
        enableSorting: true,
        enableColumnMenus: false,
        enablePaginationControls: false,
        columnDefs: [
          { name: 'Date', field: 'date', enableHiding: false },
          { name: 'Location', field: 'location', enableHiding: false },
          { name: 'Attribute', field: 'attribute', enableHiding: false },
          { name: 'Account', field: 'account', enableHiding: false },
          { name: 'Action', field: 'action', enableHiding: false },
          { name: 'System', field: 'system', enableHiding: false },
          { name: 'Risk', field: 'risk', enableHiding: false },
          { name: 'Application', field: 'application', enableHiding: false },
          { name: 'Resource', field: 'resource', enableHiding: false },
          { name: 'Events count', field: 'event_count', enableHiding: false },
        ],
      };

      $scope.gridError = false;

      //$scope.rowColor = function(row, grid) {
      //	if(row['selected']){
      //		return 'selected-row';
      //	}else{
      //		return (grid.renderContainers.body.visibleRowCache.indexOf(row) % 2) === 0 ? 'even-row' : 'odd-row';
      //	}
      //};
      //
      ///* options start*/
      //if(activitiesModel.rowTemplate)
      //	$scope.activitiesModel['rowTemplate'] = activitiesModel.rowTemplate;
      //
      //if(activitiesModel.rowSelection){
      //	$scope.activitiesModel['enableRowSelection'] = true;
      //	$scope.gridRowClick = function(row, grid) {
      //		row['selected'] = !row['selected'] ? true : false;
      //		for (let i in grid.rows){
      //			if(grid.rows[i].uid == row.uid) continue;
      //			grid.rows[i]['selected'] = false;
      //		}
      //	};
      //}
      ///* options end*/

      activitiesModel.$onInit = () => {
        activitiesModel.countries = getCopyOfCountries(getApplicationPreference('DISPLAY_CHINA_REGULATIONS'));
      };

      activitiesModel.$onChanges = function () {
        const filterValue = localStorageService.get('filterValue');
        const userNameListChecked = localStorageService.get('userNameListChecked');

        if (activitiesModel.filteruser) {
          const queryString = queryStringService.getQueryStringFilter(null, [activitiesModel.filteruser]);
          getActivitiesData(queryString);
        }

        if (
          activitiesModel.filter === 'all' &&
          (filterValue === null || filterValue == undefined) &&
          (userNameListChecked === null || userNameListChecked == undefined)
        ) {
          getActivitiesData();
          return;
        } else {
          if (!activitiesModel.urlfilter) {
            const queryString = queryStringService.getQueryStringFilter(filterValue, userNameListChecked);
            getActivitiesData(queryString);
          }
        }
      };

      function getActivitiesData(queryString) {
        $scope.loading = true;
        $scope.gridError = false;
        activitiesService.getActivitiesData(queryString).then(
          function (result) {
            $scope.activitiesModel.data = getTableData(result.activity_violations);
            $scope.loading = false;
          },
          function () {
            $scope.loading = false;
            $scope.gridError = true;
          },
        );
      }

      function getActivitiesByAppName(queryString) {
        $scope.loading = true;
        $scope.gridError = false;
        activitiesService.getActivitiesByAppName(queryString).then(
          function (result) {
            $scope.activitiesModel.data = getTableData(result.activities);
            $scope.loading = false;
          },
          function () {
            $scope.loading = false;
            $scope.gridError = true;
          },
        );
      }

      const getTableData = rows => rows.map(row => ({ ...row, location: getLocationToDisplay(row.location) }));

      //Listen to header search
      const listenerCallFilter = $rootScope.$on('callFilter', function (event, filterValue, userNameListChecked) {
        callToGetActivitiesData(filterValue, userNameListChecked);
      });

      function callToGetActivitiesData(filterValue, ids) {
        const queryString = queryStringService.getQueryStringFilter(filterValue, ids);
        getActivitiesData(queryString);
      }

      const listenerCleanFilter = $rootScope.$on('cleanFilter', function (event) {
        getActivitiesData(null);
      });

      //for url loading
      if (activitiesModel.urlfilter) {
        getActivitiesData(activitiesModel.urlfilter);
      }

      //Unregister listeners
      $scope.$on('$destroy', function () {
        listenerCallFilter();
        listenerCleanFilter();
      });
    },
  ],
  bindings: {
    filter: '@',
    filterU: '<',
    urlfilter: '=',
  },
});
