import React, { FC, useState, useEffect, useMemo } from 'react';
import { BigidInlineNotification, BigidInlineNotificationProps, BigidLoader } from '@bigid-ui/components';
import { BigidConnectionIcon } from '@bigid-ui/icons';
import { getClassifierPerformance } from '../../services/classifiersService';
import { $translate } from '../../services/angularServices';
import { notificationService } from '../../services/notificationService';
import { ClassifierTesterResultsDto } from './ClassifierTesterTypes';

enum PERFORMANCE_RESULT_TYPE {
  SLOW = 'Slow',
  FAST = 'Fast',
}

enum PERFORMANCE_RESULT_TEXT {
  SLOW = 'The classifier might slow down the scan process.',
  FAST = 'The rating refers to the expected performance of the classifier only. The scan is also affected by other factors, such as the number of enabled classifiers, the number of findings, and the nature of the data.',
}

export interface ClassifierTesterPerformanceProps {
  handlePerformanceResult: (newValues: ClassifierTesterResultsDto | any) => void;
  regexPattern: string;
  onIsLoadingChange: React.Dispatch<React.SetStateAction<boolean>>;

  regexMatches: IterableIterator<RegExpMatchArray>;
}

export const ClassifierTesterPerformance: FC<ClassifierTesterPerformanceProps> = ({
  handlePerformanceResult,
  regexPattern,
  onIsLoadingChange,
  regexMatches,
}) => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [performanceResult, setPerformanceResult] = useState<string>('');

  const inlineNotificationProps: BigidInlineNotificationProps = useMemo(() => {
    return {
      title: `Overall Performance: ${performanceResult}`,
      icon: BigidConnectionIcon,
      type: performanceResult === PERFORMANCE_RESULT_TYPE.FAST ? 'success' : 'error',
      showMoreButton: false,
      actionButton: { onClick: () => null },
      text: [
        {
          heading: '',
          subText:
            performanceResult === PERFORMANCE_RESULT_TYPE.FAST
              ? `${PERFORMANCE_RESULT_TEXT.FAST}`
              : `${PERFORMANCE_RESULT_TEXT.SLOW}`,
        },
      ],
    };
  }, [performanceResult]);

  useEffect(() => {
    if (regexMatches) {
      setIsLoading(true);
      getClassifierPerformance({
        classificationRegex: regexPattern,
      })
        .then(result => {
          let performanceNumericResult = 5;
          if (result.isPerformanceAboveThreshold) {
            setPerformanceResult(PERFORMANCE_RESULT_TYPE.SLOW);
            performanceNumericResult = 30;
          } else {
            setPerformanceResult(PERFORMANCE_RESULT_TYPE.FAST);
          }
          onIsLoadingChange(false);
          handlePerformanceResult((prevState: ClassifierTesterResultsDto) => {
            return {
              ...prevState,
              performance: performanceNumericResult,
            };
          });
        })
        .catch(error => {
          const errorMsg = $translate.instant('API:MESSAGE:COMMON_ERROR');
          console.error(error);
          notificationService.error(errorMsg);
          onIsLoadingChange(true);
        })
        .finally(() => {
          setTimeout(() => {
            setIsLoading(false);
          }, 500);
        });
    }
    // TODO: this line was disabled on global icon change, the owner of this code should take a look at why there are missing dependencies here.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [regexPattern, regexMatches, onIsLoadingChange]);

  return (
    <>
      {isLoading ? (
        <BigidLoader
          position={'relative'}
          label={'Calculating performance (long regex pattern might take a while)'}
          labelPosition={'top'}
        />
      ) : (
        <BigidInlineNotification {...inlineNotificationProps}></BigidInlineNotification>
      )}
    </>
  );
};
