import React from 'react';
import { Guide, Aside } from '../mappers/styles';
import { BigidHeading6, BigidLink } from '@bigid-ui/components';
import { BigidLayoutEmptyState } from '@bigid-ui/layout';
import { BigidExploreDocsIllustration } from '@bigid-ui/icons';
import { useLocalTranslation } from '../translations';
import { useDataSourceModalContext } from '../hooks/useDataSourceModalContext';

type DataSourceConnectionGuideNoContentProps = {
  dataAid?: string;
  onShowDocumentation: () => void;
};

export const DataSourceConnectionGuideNoContent = ({
  dataAid = 'DataSourceConnectionGuideNoContent',
  onShowDocumentation,
}: DataSourceConnectionGuideNoContentProps) => {
  const { t } = useLocalTranslation();
  const { isDsCollaborator } = useDataSourceModalContext();

  return (
    <Guide.TabNoContent data-aid={dataAid}>
      <BigidLayoutEmptyState
        title={(<BigidHeading6>{t('connectionGuide.error')}</BigidHeading6>) as unknown as string}
        illustration={BigidExploreDocsIllustration}
        description={
          !isDsCollaborator &&
          ((
            <Aside.Description>
              <BigidLink onClick={onShowDocumentation} underline="none" text={t('connectionGuide.link')} />
            </Aside.Description>
          ) as unknown as string)
        }
      />
    </Guide.TabNoContent>
  );
};
