import { $transitions } from '../../../../../services/angularServices';
import { callChild } from './sdkHandler';
import { ParentAPI } from 'postmate';

export enum ParentTopic {
  NAVIGATE_OUT = 'onUserNavigateOut',
  UPDATE_APP_ROUTE = 'updateAppRoute',
}
export const topicsDefaultResponses = new Map<ParentTopic, any>();

const initDefaultResponses = () => {
  topicsDefaultResponses.set(ParentTopic.NAVIGATE_OUT, true);
};
initDefaultResponses();

export const leaveFormInterceptor = (child: ParentAPI) =>
  $transitions.onBefore(
    {},
    () =>
      new Promise(async resolve => {
        try {
          const res = Boolean(await callChild(child, ParentTopic.NAVIGATE_OUT, null));
          resolve(res);
        } catch (err) {
          resolve(topicsDefaultResponses.get(ParentTopic.NAVIGATE_OUT));
          console.log(`Child failed process the call: ${err}`);
        }
      }) as Promise<boolean>,
  ) as () => void;
